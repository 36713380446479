[
  {
    "id": "1101012001",
    "district_id": "110101",
    "label": "Keude Bakongan",
    "value": "Keude Bakongan"
  },
  {
    "id": "1101012002",
    "district_id": "110101",
    "label": "Ujong Mangki",
    "value": "Ujong Mangki"
  },
  {
    "id": "1101012003",
    "district_id": "110101",
    "label": "Ujong Padang",
    "value": "Ujong Padang"
  },
  {
    "id": "1101012004",
    "district_id": "110101",
    "label": "Gampong Drien",
    "value": "Gampong Drien"
  },
  {
    "id": "1101012015",
    "district_id": "110101",
    "label": "Darul Ikhsan",
    "value": "Darul Ikhsan"
  },
  {
    "id": "1101012016",
    "district_id": "110101",
    "label": "Padang Beurahan",
    "value": "Padang Beurahan"
  },
  {
    "id": "1101012017",
    "district_id": "110101",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1101022001",
    "district_id": "110102",
    "label": "Fajar Harapan",
    "value": "Fajar Harapan"
  },
  {
    "id": "1101022002",
    "district_id": "110102",
    "label": "Krueng Batee",
    "value": "Krueng Batee"
  },
  {
    "id": "1101022003",
    "district_id": "110102",
    "label": "Pasi Kuala Asahan",
    "value": "Pasi Kuala Asahan"
  },
  {
    "id": "1101022004",
    "district_id": "110102",
    "label": "Gunung Pulo",
    "value": "Gunung Pulo"
  },
  {
    "id": "1101022005",
    "district_id": "110102",
    "label": "Pulo Ie I",
    "value": "Pulo Ie I"
  },
  {
    "id": "1101022006",
    "district_id": "110102",
    "label": "Jambo Manyang",
    "value": "Jambo Manyang"
  },
  {
    "id": "1101022007",
    "district_id": "110102",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1101022008",
    "district_id": "110102",
    "label": "Limau Purut",
    "value": "Limau Purut"
  },
  {
    "id": "1101022009",
    "district_id": "110102",
    "label": "Pulo Kambing",
    "value": "Pulo Kambing"
  },
  {
    "id": "1101022010",
    "district_id": "110102",
    "label": "Kampung Paya",
    "value": "Kampung Paya"
  },
  {
    "id": "1101022011",
    "district_id": "110102",
    "label": "Krueng Batu",
    "value": "Krueng Batu"
  },
  {
    "id": "1101022012",
    "district_id": "110102",
    "label": "Krueng Kluet",
    "value": "Krueng Kluet"
  },
  {
    "id": "1101022013",
    "district_id": "110102",
    "label": "Alur Mas",
    "value": "Alur Mas"
  },
  {
    "id": "1101022014",
    "district_id": "110102",
    "label": "Kampung Tinggi",
    "value": "Kampung Tinggi"
  },
  {
    "id": "1101022015",
    "district_id": "110102",
    "label": "Ruak",
    "value": "Ruak"
  },
  {
    "id": "1101022016",
    "district_id": "110102",
    "label": "Simpang Lhee",
    "value": "Simpang Lhee"
  },
  {
    "id": "1101022017",
    "district_id": "110102",
    "label": "Suaq Geuringgeng",
    "value": "Suaq Geuringgeng"
  },
  {
    "id": "1101022018",
    "district_id": "110102",
    "label": "Pasi Kuala Ba'u",
    "value": "Pasi Kuala Ba'u"
  },
  {
    "id": "1101022019",
    "district_id": "110102",
    "label": "Kedai Padang",
    "value": "Kedai Padang"
  },
  {
    "id": "1101022020",
    "district_id": "110102",
    "label": "Kotafajar",
    "value": "Kotafajar"
  },
  {
    "id": "1101022021",
    "district_id": "110102",
    "label": "Gunung Pudung",
    "value": "Gunung Pudung"
  },
  {
    "id": "1101032001",
    "district_id": "110103",
    "label": "Suaq Bakung",
    "value": "Suaq Bakung"
  },
  {
    "id": "1101032002",
    "district_id": "110103",
    "label": "Rantau Binuang",
    "value": "Rantau Binuang"
  },
  {
    "id": "1101032003",
    "district_id": "110103",
    "label": "Barat Daya",
    "value": "Barat Daya"
  },
  {
    "id": "1101032004",
    "district_id": "110103",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1101032005",
    "district_id": "110103",
    "label": "Kapeh",
    "value": "Kapeh"
  },
  {
    "id": "1101032006",
    "district_id": "110103",
    "label": "Pulo Ie",
    "value": "Pulo Ie"
  },
  {
    "id": "1101032007",
    "district_id": "110103",
    "label": "Kedai Runding",
    "value": "Kedai Runding"
  },
  {
    "id": "1101032008",
    "district_id": "110103",
    "label": "Kedai Kandang",
    "value": "Kedai Kandang"
  },
  {
    "id": "1101032009",
    "district_id": "110103",
    "label": "Gampong Luar",
    "value": "Gampong Luar"
  },
  {
    "id": "1101032010",
    "district_id": "110103",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "1101032011",
    "district_id": "110103",
    "label": "Jua",
    "value": "Jua"
  },
  {
    "id": "1101032012",
    "district_id": "110103",
    "label": "Pasi Meurapat",
    "value": "Pasi Meurapat"
  },
  {
    "id": "1101032013",
    "district_id": "110103",
    "label": "Ujung Pasir",
    "value": "Ujung Pasir"
  },
  {
    "id": "1101032014",
    "district_id": "110103",
    "label": "Geulumbuk",
    "value": "Geulumbuk"
  },
  {
    "id": "1101032015",
    "district_id": "110103",
    "label": "Pasie Lembang",
    "value": "Pasie Lembang"
  },
  {
    "id": "1101032016",
    "district_id": "110103",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1101032017",
    "district_id": "110103",
    "label": "Indra Damai",
    "value": "Indra Damai"
  },
  {
    "id": "1101042001",
    "district_id": "110104",
    "label": "Bakau Hulu",
    "value": "Bakau Hulu"
  },
  {
    "id": "1101042002",
    "district_id": "110104",
    "label": "Padang Bakau",
    "value": "Padang Bakau"
  },
  {
    "id": "1101042003",
    "district_id": "110104",
    "label": "Manggis Harapan",
    "value": "Manggis Harapan"
  },
  {
    "id": "1101042004",
    "district_id": "110104",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "1101042005",
    "district_id": "110104",
    "label": "Apha",
    "value": "Apha"
  },
  {
    "id": "1101042006",
    "district_id": "110104",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "1101042007",
    "district_id": "110104",
    "label": "Pawoh",
    "value": "Pawoh"
  },
  {
    "id": "1101042008",
    "district_id": "110104",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "1101042009",
    "district_id": "110104",
    "label": "Kota Palak",
    "value": "Kota Palak"
  },
  {
    "id": "1101042010",
    "district_id": "110104",
    "label": "Cacang",
    "value": "Cacang"
  },
  {
    "id": "1101042011",
    "district_id": "110104",
    "label": "Tengah Pisang",
    "value": "Tengah Pisang"
  },
  {
    "id": "1101042012",
    "district_id": "110104",
    "label": "Pisang",
    "value": "Pisang"
  },
  {
    "id": "1101042013",
    "district_id": "110104",
    "label": "Hulu Pisang",
    "value": "Hulu Pisang"
  },
  {
    "id": "1101042014",
    "district_id": "110104",
    "label": "Tengah Baru",
    "value": "Tengah Baru"
  },
  {
    "id": "1101042015",
    "district_id": "110104",
    "label": "Lembah Baru",
    "value": "Lembah Baru"
  },
  {
    "id": "1101042016",
    "district_id": "110104",
    "label": "Padang Baru",
    "value": "Padang Baru"
  },
  {
    "id": "1101052001",
    "district_id": "110105",
    "label": "Kuta Buloh II",
    "value": "Kuta Buloh II"
  },
  {
    "id": "1101052002",
    "district_id": "110105",
    "label": "Kuta Buloh I",
    "value": "Kuta Buloh I"
  },
  {
    "id": "1101052003",
    "district_id": "110105",
    "label": "Ie Dingen",
    "value": "Ie Dingen"
  },
  {
    "id": "1101052004",
    "district_id": "110105",
    "label": "Drien Jalo",
    "value": "Drien Jalo"
  },
  {
    "id": "1101052005",
    "district_id": "110105",
    "label": "Jambo Papeun",
    "value": "Jambo Papeun"
  },
  {
    "id": "1101052006",
    "district_id": "110105",
    "label": "Buket Meuh",
    "value": "Buket Meuh"
  },
  {
    "id": "1101052007",
    "district_id": "110105",
    "label": "Blang Kuala",
    "value": "Blang Kuala"
  },
  {
    "id": "1101052008",
    "district_id": "110105",
    "label": "Rot Teungoh",
    "value": "Rot Teungoh"
  },
  {
    "id": "1101052009",
    "district_id": "110105",
    "label": "Alue Baro",
    "value": "Alue Baro"
  },
  {
    "id": "1101052010",
    "district_id": "110105",
    "label": "Ladang Tuha",
    "value": "Ladang Tuha"
  },
  {
    "id": "1101052011",
    "district_id": "110105",
    "label": "Lhok Mamplam",
    "value": "Lhok Mamplam"
  },
  {
    "id": "1101052012",
    "district_id": "110105",
    "label": "Arun Tunggai",
    "value": "Arun Tunggai"
  },
  {
    "id": "1101052013",
    "district_id": "110105",
    "label": "Blang Teungoh",
    "value": "Blang Teungoh"
  },
  {
    "id": "1101052014",
    "district_id": "110105",
    "label": "Blang Bladeh",
    "value": "Blang Bladeh"
  },
  {
    "id": "1101052015",
    "district_id": "110105",
    "label": "Ie Buboh",
    "value": "Ie Buboh"
  },
  {
    "id": "1101052016",
    "district_id": "110105",
    "label": "Keude Meukek",
    "value": "Keude Meukek"
  },
  {
    "id": "1101052017",
    "district_id": "110105",
    "label": "Kuta Baro",
    "value": "Kuta Baro"
  },
  {
    "id": "1101052018",
    "district_id": "110105",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1101052019",
    "district_id": "110105",
    "label": "Labuhan Tarok",
    "value": "Labuhan Tarok"
  },
  {
    "id": "1101052020",
    "district_id": "110105",
    "label": "Lhok Aman",
    "value": "Lhok Aman"
  },
  {
    "id": "1101052021",
    "district_id": "110105",
    "label": "Alue Meutuah",
    "value": "Alue Meutuah"
  },
  {
    "id": "1101052022",
    "district_id": "110105",
    "label": "Ladang Baro",
    "value": "Ladang Baro"
  },
  {
    "id": "1101052023",
    "district_id": "110105",
    "label": "Labuhan Tarok II",
    "value": "Labuhan Tarok II"
  },
  {
    "id": "1101062001",
    "district_id": "110106",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "1101062002",
    "district_id": "110106",
    "label": "Subarang",
    "value": "Subarang"
  },
  {
    "id": "1101062003",
    "district_id": "110106",
    "label": "Kuta Baru",
    "value": "Kuta Baru"
  },
  {
    "id": "1101062004",
    "district_id": "110106",
    "label": "Gunung Ketek",
    "value": "Gunung Ketek"
  },
  {
    "id": "1101062005",
    "district_id": "110106",
    "label": "Ladang Panton Luas",
    "value": "Ladang Panton Luas"
  },
  {
    "id": "1101062006",
    "district_id": "110106",
    "label": "Madat",
    "value": "Madat"
  },
  {
    "id": "1101062007",
    "district_id": "110106",
    "label": "Alur Seumerah",
    "value": "Alur Seumerah"
  },
  {
    "id": "1101062008",
    "district_id": "110106",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "1101062009",
    "district_id": "110106",
    "label": "Gunung Cut",
    "value": "Gunung Cut"
  },
  {
    "id": "1101062010",
    "district_id": "110106",
    "label": "Alur Pinang",
    "value": "Alur Pinang"
  },
  {
    "id": "1101062011",
    "district_id": "110106",
    "label": "Kuta Blang",
    "value": "Kuta Blang"
  },
  {
    "id": "1101062012",
    "district_id": "110106",
    "label": "Batee Tunggai",
    "value": "Batee Tunggai"
  },
  {
    "id": "1101062013",
    "district_id": "110106",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "1101062014",
    "district_id": "110106",
    "label": "Ujung Kampung",
    "value": "Ujung Kampung"
  },
  {
    "id": "1101062015",
    "district_id": "110106",
    "label": "Jilatang",
    "value": "Jilatang"
  },
  {
    "id": "1101062016",
    "district_id": "110106",
    "label": "Luar",
    "value": "Luar"
  },
  {
    "id": "1101062017",
    "district_id": "110106",
    "label": "Lubuk Layu",
    "value": "Lubuk Layu"
  },
  {
    "id": "1101062018",
    "district_id": "110106",
    "label": "Suaq Hulu",
    "value": "Suaq Hulu"
  },
  {
    "id": "1101062019",
    "district_id": "110106",
    "label": "Tampang",
    "value": "Tampang"
  },
  {
    "id": "1101062020",
    "district_id": "110106",
    "label": "Payonan Gadang",
    "value": "Payonan Gadang"
  },
  {
    "id": "1101062021",
    "district_id": "110106",
    "label": "Gadang",
    "value": "Gadang"
  },
  {
    "id": "1101062022",
    "district_id": "110106",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1101062023",
    "district_id": "110106",
    "label": "Ladang Kasik Putih",
    "value": "Ladang Kasik Putih"
  },
  {
    "id": "1101062024",
    "district_id": "110106",
    "label": "Air Sialang Hilir.",
    "value": "Air Sialang Hilir."
  },
  {
    "id": "1101062025",
    "district_id": "110106",
    "label": "Air Sialang Hulu",
    "value": "Air Sialang Hulu"
  },
  {
    "id": "1101062026",
    "district_id": "110106",
    "label": "Air Sialang Tengah",
    "value": "Air Sialang Tengah"
  },
  {
    "id": "1101062027",
    "district_id": "110106",
    "label": "Balai",
    "value": "Balai"
  },
  {
    "id": "1101062028",
    "district_id": "110106",
    "label": "Arafah",
    "value": "Arafah"
  },
  {
    "id": "1101072001",
    "district_id": "110107",
    "label": "Sikulat",
    "value": "Sikulat"
  },
  {
    "id": "1101072002",
    "district_id": "110107",
    "label": "Sawang I",
    "value": "Sawang I"
  },
  {
    "id": "1101072003",
    "district_id": "110107",
    "label": "Meuligo",
    "value": "Meuligo"
  },
  {
    "id": "1101072004",
    "district_id": "110107",
    "label": "Lhok Pawoh",
    "value": "Lhok Pawoh"
  },
  {
    "id": "1101072005",
    "district_id": "110107",
    "label": "Sawang II",
    "value": "Sawang II"
  },
  {
    "id": "1101072006",
    "district_id": "110107",
    "label": "Ujung Karang",
    "value": "Ujung Karang"
  },
  {
    "id": "1101072007",
    "district_id": "110107",
    "label": "Trieng Meuduro Baroh",
    "value": "Trieng Meuduro Baroh"
  },
  {
    "id": "1101072008",
    "district_id": "110107",
    "label": "Trieng Meuduro Tunong",
    "value": "Trieng Meuduro Tunong"
  },
  {
    "id": "1101072009",
    "district_id": "110107",
    "label": "Panton Luas",
    "value": "Panton Luas"
  },
  {
    "id": "1101072010",
    "district_id": "110107",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1101072011",
    "district_id": "110107",
    "label": "Kuta Baro",
    "value": "Kuta Baro"
  },
  {
    "id": "1101072012",
    "district_id": "110107",
    "label": "Blang Geulinggang",
    "value": "Blang Geulinggang"
  },
  {
    "id": "1101072013",
    "district_id": "110107",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "1101072014",
    "district_id": "110107",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1101072015",
    "district_id": "110107",
    "label": "Sawang Ba'u",
    "value": "Sawang Ba'u"
  },
  {
    "id": "1101082001",
    "district_id": "110108",
    "label": "Lhok Keutapang",
    "value": "Lhok Keutapang"
  },
  {
    "id": "1101082002",
    "district_id": "110108",
    "label": "Hilir",
    "value": "Hilir"
  },
  {
    "id": "1101082003",
    "district_id": "110108",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1101082004",
    "district_id": "110108",
    "label": "Hulu",
    "value": "Hulu"
  },
  {
    "id": "1101082005",
    "district_id": "110108",
    "label": "Jambo Apha",
    "value": "Jambo Apha"
  },
  {
    "id": "1101082006",
    "district_id": "110108",
    "label": "Tepi Air",
    "value": "Tepi Air"
  },
  {
    "id": "1101082007",
    "district_id": "110108",
    "label": "Pasar",
    "value": "Pasar"
  },
  {
    "id": "1101082008",
    "district_id": "110108",
    "label": "Lhok Bengkuang",
    "value": "Lhok Bengkuang"
  },
  {
    "id": "1101082009",
    "district_id": "110108",
    "label": "Panton Luas",
    "value": "Panton Luas"
  },
  {
    "id": "1101082010",
    "district_id": "110108",
    "label": "Gunung Kerambil",
    "value": "Gunung Kerambil"
  },
  {
    "id": "1101082011",
    "district_id": "110108",
    "label": "Air Berudang",
    "value": "Air Berudang"
  },
  {
    "id": "1101082012",
    "district_id": "110108",
    "label": "Batu Itam",
    "value": "Batu Itam"
  },
  {
    "id": "1101082013",
    "district_id": "110108",
    "label": "Panjupian",
    "value": "Panjupian"
  },
  {
    "id": "1101082014",
    "district_id": "110108",
    "label": "Lhok Rukam",
    "value": "Lhok Rukam"
  },
  {
    "id": "1101082015",
    "district_id": "110108",
    "label": "Air Pinang",
    "value": "Air Pinang"
  },
  {
    "id": "1101082016",
    "district_id": "110108",
    "label": "Lhok Bengkuang Timur",
    "value": "Lhok Bengkuang Timur"
  },
  {
    "id": "1101092001",
    "district_id": "110109",
    "label": "Keude Trumon",
    "value": "Keude Trumon"
  },
  {
    "id": "1101092002",
    "district_id": "110109",
    "label": "Ujong Tanoh",
    "value": "Ujong Tanoh"
  },
  {
    "id": "1101092003",
    "district_id": "110109",
    "label": "Sigleng",
    "value": "Sigleng"
  },
  {
    "id": "1101092004",
    "district_id": "110109",
    "label": "Ie Meudama",
    "value": "Ie Meudama"
  },
  {
    "id": "1101092005",
    "district_id": "110109",
    "label": "Teupin Tinggi",
    "value": "Teupin Tinggi"
  },
  {
    "id": "1101092006",
    "district_id": "110109",
    "label": "Kuta Baro",
    "value": "Kuta Baro"
  },
  {
    "id": "1101092007",
    "district_id": "110109",
    "label": "Panton Bilie",
    "value": "Panton Bilie"
  },
  {
    "id": "1101092012",
    "district_id": "110109",
    "label": "Kuta Padang",
    "value": "Kuta Padang"
  },
  {
    "id": "1101092013",
    "district_id": "110109",
    "label": "Raket",
    "value": "Raket"
  },
  {
    "id": "1101092014",
    "district_id": "110109",
    "label": "Gampong Teungoh",
    "value": "Gampong Teungoh"
  },
  {
    "id": "1101092015",
    "district_id": "110109",
    "label": "Seuneubok Jaya",
    "value": "Seuneubok Jaya"
  },
  {
    "id": "1101092016",
    "district_id": "110109",
    "label": "Padang Harapan",
    "value": "Padang Harapan"
  },
  {
    "id": "1101102001",
    "district_id": "110110",
    "label": "Ladang Teungoh",
    "value": "Ladang Teungoh"
  },
  {
    "id": "1101102002",
    "district_id": "110110",
    "label": "Pucok Krueng",
    "value": "Pucok Krueng"
  },
  {
    "id": "1101102003",
    "district_id": "110110",
    "label": "Silolo",
    "value": "Silolo"
  },
  {
    "id": "1101102004",
    "district_id": "110110",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1101102005",
    "district_id": "110110",
    "label": "Panton Bili",
    "value": "Panton Bili"
  },
  {
    "id": "1101102006",
    "district_id": "110110",
    "label": "Paya Ateuk",
    "value": "Paya Ateuk"
  },
  {
    "id": "1101102007",
    "district_id": "110110",
    "label": "Ladang Tuha",
    "value": "Ladang Tuha"
  },
  {
    "id": "1101102008",
    "district_id": "110110",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1101102009",
    "district_id": "110110",
    "label": "Ujong Batee",
    "value": "Ujong Batee"
  },
  {
    "id": "1101102010",
    "district_id": "110110",
    "label": "Lhok Sialang Cut",
    "value": "Lhok Sialang Cut"
  },
  {
    "id": "1101102011",
    "district_id": "110110",
    "label": "Seuneubok",
    "value": "Seuneubok"
  },
  {
    "id": "1101102012",
    "district_id": "110110",
    "label": "Ie Mirah",
    "value": "Ie Mirah"
  },
  {
    "id": "1101102013",
    "district_id": "110110",
    "label": "Pasie Rasian",
    "value": "Pasie Rasian"
  },
  {
    "id": "1101102014",
    "district_id": "110110",
    "label": "Ujung Padang Rasian",
    "value": "Ujung Padang Rasian"
  },
  {
    "id": "1101102015",
    "district_id": "110110",
    "label": "Krueng Kalee",
    "value": "Krueng Kalee"
  },
  {
    "id": "1101102016",
    "district_id": "110110",
    "label": "Lhok Sialang Rayeuk",
    "value": "Lhok Sialang Rayeuk"
  },
  {
    "id": "1101102017",
    "district_id": "110110",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1101102018",
    "district_id": "110110",
    "label": "Teupin Gajah",
    "value": "Teupin Gajah"
  },
  {
    "id": "1101102019",
    "district_id": "110110",
    "label": "Ujung Padang Asahan",
    "value": "Ujung Padang Asahan"
  },
  {
    "id": "1101102020",
    "district_id": "110110",
    "label": "Pulo Ie II",
    "value": "Pulo Ie II"
  },
  {
    "id": "1101102021",
    "district_id": "110110",
    "label": "Pante Raja",
    "value": "Pante Raja"
  },
  {
    "id": "1101112001",
    "district_id": "110111",
    "label": "Tengah Peulumat",
    "value": "Tengah Peulumat"
  },
  {
    "id": "1101112002",
    "district_id": "110111",
    "label": "Paya Peulumat",
    "value": "Paya Peulumat"
  },
  {
    "id": "1101112003",
    "district_id": "110111",
    "label": "Padang Peulumat",
    "value": "Padang Peulumat"
  },
  {
    "id": "1101112004",
    "district_id": "110111",
    "label": "Aur Peulumat",
    "value": "Aur Peulumat"
  },
  {
    "id": "1101112005",
    "district_id": "110111",
    "label": "Limau Saring",
    "value": "Limau Saring"
  },
  {
    "id": "1101112006",
    "district_id": "110111",
    "label": "Beutong",
    "value": "Beutong"
  },
  {
    "id": "1101112007",
    "district_id": "110111",
    "label": "Peuneulop",
    "value": "Peuneulop"
  },
  {
    "id": "1101112008",
    "district_id": "110111",
    "label": "Gunung Rotan",
    "value": "Gunung Rotan"
  },
  {
    "id": "1101112009",
    "district_id": "110111",
    "label": "Keumumu Hilir",
    "value": "Keumumu Hilir"
  },
  {
    "id": "1101112010",
    "district_id": "110111",
    "label": "Keumumu Hulu",
    "value": "Keumumu Hulu"
  },
  {
    "id": "1101112011",
    "district_id": "110111",
    "label": "Keumumu Seberang",
    "value": "Keumumu Seberang"
  },
  {
    "id": "1101112012",
    "district_id": "110111",
    "label": "Sawang Indah",
    "value": "Sawang Indah"
  },
  {
    "id": "1101122001",
    "district_id": "110112",
    "label": "Tutong",
    "value": "Tutong"
  },
  {
    "id": "1101122002",
    "district_id": "110112",
    "label": "Teungoh Iboh",
    "value": "Teungoh Iboh"
  },
  {
    "id": "1101122003",
    "district_id": "110112",
    "label": "Peulokan",
    "value": "Peulokan"
  },
  {
    "id": "1101122004",
    "district_id": "110112",
    "label": "Kuta Iboh",
    "value": "Kuta Iboh"
  },
  {
    "id": "1101122005",
    "district_id": "110112",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1101122006",
    "district_id": "110112",
    "label": "Blang Baru",
    "value": "Blang Baru"
  },
  {
    "id": "1101122007",
    "district_id": "110112",
    "label": "Blang Poroh",
    "value": "Blang Poroh"
  },
  {
    "id": "1101122008",
    "district_id": "110112",
    "label": "Pulo Ie",
    "value": "Pulo Ie"
  },
  {
    "id": "1101122009",
    "district_id": "110112",
    "label": "Suak Lokan",
    "value": "Suak Lokan"
  },
  {
    "id": "1101122010",
    "district_id": "110112",
    "label": "Iku Lhueng",
    "value": "Iku Lhueng"
  },
  {
    "id": "1101122011",
    "district_id": "110112",
    "label": "Kuta Trieng",
    "value": "Kuta Trieng"
  },
  {
    "id": "1101122012",
    "district_id": "110112",
    "label": "Pante Geulima",
    "value": "Pante Geulima"
  },
  {
    "id": "1101122013",
    "district_id": "110112",
    "label": "Panton Pawoh",
    "value": "Panton Pawoh"
  },
  {
    "id": "1101122014",
    "district_id": "110112",
    "label": "Batee Meucanang",
    "value": "Batee Meucanang"
  },
  {
    "id": "1101122015",
    "district_id": "110112",
    "label": "Panton Rubek",
    "value": "Panton Rubek"
  },
  {
    "id": "1101132001",
    "district_id": "110113",
    "label": "Koto",
    "value": "Koto"
  },
  {
    "id": "1101132002",
    "district_id": "110113",
    "label": "Alur Keujrun",
    "value": "Alur Keujrun"
  },
  {
    "id": "1101132003",
    "district_id": "110113",
    "label": "Jambo Papan",
    "value": "Jambo Papan"
  },
  {
    "id": "1101132004",
    "district_id": "110113",
    "label": "Malaka",
    "value": "Malaka"
  },
  {
    "id": "1101132005",
    "district_id": "110113",
    "label": "Koto Indarung",
    "value": "Koto Indarung"
  },
  {
    "id": "1101132006",
    "district_id": "110113",
    "label": "Siurai-Urai",
    "value": "Siurai-Urai"
  },
  {
    "id": "1101132007",
    "district_id": "110113",
    "label": "Kampung Sawah",
    "value": "Kampung Sawah"
  },
  {
    "id": "1101132008",
    "district_id": "110113",
    "label": "Kampung Padang",
    "value": "Kampung Padang"
  },
  {
    "id": "1101132009",
    "district_id": "110113",
    "label": "Pulo Air",
    "value": "Pulo Air"
  },
  {
    "id": "1101132010",
    "district_id": "110113",
    "label": "Lawe Melang",
    "value": "Lawe Melang"
  },
  {
    "id": "1101132011",
    "district_id": "110113",
    "label": "Mersak",
    "value": "Mersak"
  },
  {
    "id": "1101132012",
    "district_id": "110113",
    "label": "Simpang Dua",
    "value": "Simpang Dua"
  },
  {
    "id": "1101132013",
    "district_id": "110113",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1101142001",
    "district_id": "110114",
    "label": "Paya Dapur",
    "value": "Paya Dapur"
  },
  {
    "id": "1101142002",
    "district_id": "110114",
    "label": "Sapik",
    "value": "Sapik"
  },
  {
    "id": "1101142003",
    "district_id": "110114",
    "label": "Alai",
    "value": "Alai"
  },
  {
    "id": "1101142004",
    "district_id": "110114",
    "label": "Durian Kawan",
    "value": "Durian Kawan"
  },
  {
    "id": "1101142005",
    "district_id": "110114",
    "label": "Lawe Sawah",
    "value": "Lawe Sawah"
  },
  {
    "id": "1101142006",
    "district_id": "110114",
    "label": "Lawe Buluh Didi",
    "value": "Lawe Buluh Didi"
  },
  {
    "id": "1101142007",
    "district_id": "110114",
    "label": "Pucuk Lembang",
    "value": "Pucuk Lembang"
  },
  {
    "id": "1101142008",
    "district_id": "110114",
    "label": "Paya Laba",
    "value": "Paya Laba"
  },
  {
    "id": "1101142009",
    "district_id": "110114",
    "label": "Lawe Cimanok",
    "value": "Lawe Cimanok"
  },
  {
    "id": "1101152001",
    "district_id": "110115",
    "label": "Pasie Seubadeh",
    "value": "Pasie Seubadeh"
  },
  {
    "id": "1101152002",
    "district_id": "110115",
    "label": "Ladang Rimba",
    "value": "Ladang Rimba"
  },
  {
    "id": "1101152003",
    "district_id": "110115",
    "label": "Ujong Pulo Rayeuk",
    "value": "Ujong Pulo Rayeuk"
  },
  {
    "id": "1101152004",
    "district_id": "110115",
    "label": "Sawah Tingkeum",
    "value": "Sawah Tingkeum"
  },
  {
    "id": "1101152005",
    "district_id": "110115",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1101152006",
    "district_id": "110115",
    "label": "Ujong Pulo Cut",
    "value": "Ujong Pulo Cut"
  },
  {
    "id": "1101152008",
    "district_id": "110115",
    "label": "Seuleukat",
    "value": "Seuleukat"
  },
  {
    "id": "1101162005",
    "district_id": "110116",
    "label": "Pinto Rimba",
    "value": "Pinto Rimba"
  },
  {
    "id": "1101162006",
    "district_id": "110116",
    "label": "Krueng Luas",
    "value": "Krueng Luas"
  },
  {
    "id": "1101162007",
    "district_id": "110116",
    "label": "Jambo Dalem",
    "value": "Jambo Dalem"
  },
  {
    "id": "1101162008",
    "district_id": "110116",
    "label": "Kapa Sesak",
    "value": "Kapa Sesak"
  },
  {
    "id": "1101162009",
    "district_id": "110116",
    "label": "Seuneubok Punto",
    "value": "Seuneubok Punto"
  },
  {
    "id": "1101162010",
    "district_id": "110116",
    "label": "Seuneubok Pusaka",
    "value": "Seuneubok Pusaka"
  },
  {
    "id": "1101162011",
    "district_id": "110116",
    "label": "Alur Bujok",
    "value": "Alur Bujok"
  },
  {
    "id": "1101162012",
    "district_id": "110116",
    "label": "Titi Poben",
    "value": "Titi Poben"
  },
  {
    "id": "1101172001",
    "district_id": "110117",
    "label": "Buket Gadeng",
    "value": "Buket Gadeng"
  },
  {
    "id": "1101172002",
    "district_id": "110117",
    "label": "Rambong",
    "value": "Rambong"
  },
  {
    "id": "1101172003",
    "district_id": "110117",
    "label": "Seuneubok Keuranji",
    "value": "Seuneubok Keuranji"
  },
  {
    "id": "1101172004",
    "district_id": "110117",
    "label": "Seuneubok Alur Buloh",
    "value": "Seuneubok Alur Buloh"
  },
  {
    "id": "1101172005",
    "district_id": "110117",
    "label": "Ujong Tanoh",
    "value": "Ujong Tanoh"
  },
  {
    "id": "1101172006",
    "district_id": "110117",
    "label": "Ujong Gunong Cut",
    "value": "Ujong Gunong Cut"
  },
  {
    "id": "1101172007",
    "district_id": "110117",
    "label": "Ujong Gunung Rayeuk",
    "value": "Ujong Gunung Rayeuk"
  },
  {
    "id": "1101172008",
    "district_id": "110117",
    "label": "Beutong",
    "value": "Beutong"
  },
  {
    "id": "1101172009",
    "district_id": "110117",
    "label": "Jambo Keupok",
    "value": "Jambo Keupok"
  },
  {
    "id": "1101172010",
    "district_id": "110117",
    "label": "Alurduamas",
    "value": "Alurduamas"
  },
  {
    "id": "1101182001",
    "district_id": "110118",
    "label": "Ladang Rimba",
    "value": "Ladang Rimba"
  },
  {
    "id": "1101182002",
    "district_id": "110118",
    "label": "Gunong Kapho",
    "value": "Gunong Kapho"
  },
  {
    "id": "1101182003",
    "district_id": "110118",
    "label": "Kampong Teungoh",
    "value": "Kampong Teungoh"
  },
  {
    "id": "1101182004",
    "district_id": "110118",
    "label": "Krueng Batee",
    "value": "Krueng Batee"
  },
  {
    "id": "1101182005",
    "district_id": "110118",
    "label": "Pulo Paya",
    "value": "Pulo Paya"
  },
  {
    "id": "1101182006",
    "district_id": "110118",
    "label": "Jambo Papeun",
    "value": "Jambo Papeun"
  },
  {
    "id": "1101182007",
    "district_id": "110118",
    "label": "Cot Bayu",
    "value": "Cot Bayu"
  },
  {
    "id": "1101182008",
    "district_id": "110118",
    "label": "Naca",
    "value": "Naca"
  },
  {
    "id": "1101182009",
    "district_id": "110118",
    "label": "Ie Jeureuneh",
    "value": "Ie Jeureuneh"
  },
  {
    "id": "1101182010",
    "district_id": "110118",
    "label": "Lhok Raya",
    "value": "Lhok Raya"
  },
  {
    "id": "1102012001",
    "district_id": "110201",
    "label": "Engkeran",
    "value": "Engkeran"
  },
  {
    "id": "1102012002",
    "district_id": "110201",
    "label": "Rumah Kampung",
    "value": "Rumah Kampung"
  },
  {
    "id": "1102012004",
    "district_id": "110201",
    "label": "Pasir Bangun",
    "value": "Pasir Bangun"
  },
  {
    "id": "1102012006",
    "district_id": "110201",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "1102012007",
    "district_id": "110201",
    "label": "Lawe Kongker",
    "value": "Lawe Kongker"
  },
  {
    "id": "1102012008",
    "district_id": "110201",
    "label": "Kuta Cingkam II",
    "value": "Kuta Cingkam II"
  },
  {
    "id": "1102012009",
    "district_id": "110201",
    "label": "Muara Baru",
    "value": "Muara Baru"
  },
  {
    "id": "1102012011",
    "district_id": "110201",
    "label": "Kuta Batu I",
    "value": "Kuta Batu I"
  },
  {
    "id": "1102012012",
    "district_id": "110201",
    "label": "Kuta Cingkam I",
    "value": "Kuta Cingkam I"
  },
  {
    "id": "1102012013",
    "district_id": "110201",
    "label": "Kuta Batu II",
    "value": "Kuta Batu II"
  },
  {
    "id": "1102012014",
    "district_id": "110201",
    "label": "Lawe Sempilang",
    "value": "Lawe Sempilang"
  },
  {
    "id": "1102012015",
    "district_id": "110201",
    "label": "Prapat Batu Nunggul",
    "value": "Prapat Batu Nunggul"
  },
  {
    "id": "1102012016",
    "district_id": "110201",
    "label": "Pulo Sepang",
    "value": "Pulo Sepang"
  },
  {
    "id": "1102012017",
    "district_id": "110201",
    "label": "Rih Mbelang",
    "value": "Rih Mbelang"
  },
  {
    "id": "1102012018",
    "district_id": "110201",
    "label": "Kute Batu Baru",
    "value": "Kute Batu Baru"
  },
  {
    "id": "1102012019",
    "district_id": "110201",
    "label": "Darul Amin",
    "value": "Darul Amin"
  },
  {
    "id": "1102012020",
    "district_id": "110201",
    "label": "Lawe Lubang Indah",
    "value": "Lawe Lubang Indah"
  },
  {
    "id": "1102012021",
    "district_id": "110201",
    "label": "Batu Hamparan",
    "value": "Batu Hamparan"
  },
  {
    "id": "1102012022",
    "district_id": "110201",
    "label": "Paye Munje",
    "value": "Paye Munje"
  },
  {
    "id": "1102012023",
    "district_id": "110201",
    "label": "Pulo Ndapdap",
    "value": "Pulo Ndapdap"
  },
  {
    "id": "1102012024",
    "district_id": "110201",
    "label": "Pasir Nunggul",
    "value": "Pasir Nunggul"
  },
  {
    "id": "1102012025",
    "district_id": "110201",
    "label": "Cingkham Mekhanggun",
    "value": "Cingkham Mekhanggun"
  },
  {
    "id": "1102012026",
    "district_id": "110201",
    "label": "Pintu Khimbe",
    "value": "Pintu Khimbe"
  },
  {
    "id": "1102012027",
    "district_id": "110201",
    "label": "Lawe Kongker Hilir",
    "value": "Lawe Kongker Hilir"
  },
  {
    "id": "1102012028",
    "district_id": "110201",
    "label": "Pulo Gadung",
    "value": "Pulo Gadung"
  },
  {
    "id": "1102012029",
    "district_id": "110201",
    "label": "Pasikh Pehkmate",
    "value": "Pasikh Pehkmate"
  },
  {
    "id": "1102012030",
    "district_id": "110201",
    "label": "Pasikh Nunang",
    "value": "Pasikh Nunang"
  },
  {
    "id": "1102012031",
    "district_id": "110201",
    "label": "Deleng Kukusen",
    "value": "Deleng Kukusen"
  },
  {
    "id": "1102022001",
    "district_id": "110202",
    "label": "Bukit Merdeka",
    "value": "Bukit Merdeka"
  },
  {
    "id": "1102022002",
    "district_id": "110202",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1102022003",
    "district_id": "110202",
    "label": "Lawe Kesumpat",
    "value": "Lawe Kesumpat"
  },
  {
    "id": "1102022004",
    "district_id": "110202",
    "label": "Lawe Tua Gabungan",
    "value": "Lawe Tua Gabungan"
  },
  {
    "id": "1102022005",
    "district_id": "110202",
    "label": "Lawe Sigala II",
    "value": "Lawe Sigala II"
  },
  {
    "id": "1102022006",
    "district_id": "110202",
    "label": "Lawe Sigala Timur",
    "value": "Lawe Sigala Timur"
  },
  {
    "id": "1102022007",
    "district_id": "110202",
    "label": "Lawe Sigala Barat",
    "value": "Lawe Sigala Barat"
  },
  {
    "id": "1102022008",
    "district_id": "110202",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1102022009",
    "district_id": "110202",
    "label": "Kute Tengah",
    "value": "Kute Tengah"
  },
  {
    "id": "1102022010",
    "district_id": "110202",
    "label": "Tanah Baru",
    "value": "Tanah Baru"
  },
  {
    "id": "1102022011",
    "district_id": "110202",
    "label": "Kedataran Gabungan",
    "value": "Kedataran Gabungan"
  },
  {
    "id": "1102022012",
    "district_id": "110202",
    "label": "Gabungan Parsaroan",
    "value": "Gabungan Parsaroan"
  },
  {
    "id": "1102022013",
    "district_id": "110202",
    "label": "Lawe Loning Gabungan",
    "value": "Lawe Loning Gabungan"
  },
  {
    "id": "1102022014",
    "district_id": "110202",
    "label": "Lawe Loning Aman",
    "value": "Lawe Loning Aman"
  },
  {
    "id": "1102022015",
    "district_id": "110202",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1102022016",
    "district_id": "110202",
    "label": "Lawe Tua Makmur",
    "value": "Lawe Tua Makmur"
  },
  {
    "id": "1102022017",
    "district_id": "110202",
    "label": "Lawe Tua Persatuan",
    "value": "Lawe Tua Persatuan"
  },
  {
    "id": "1102022018",
    "district_id": "110202",
    "label": "Lawe Serke",
    "value": "Lawe Serke"
  },
  {
    "id": "1102022019",
    "district_id": "110202",
    "label": "Lawe Rakat",
    "value": "Lawe Rakat"
  },
  {
    "id": "1102022020",
    "district_id": "110202",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1102022021",
    "district_id": "110202",
    "label": "Enmiya Batu Dua Ratus",
    "value": "Enmiya Batu Dua Ratus"
  },
  {
    "id": "1102022022",
    "district_id": "110202",
    "label": "Sebungke",
    "value": "Sebungke"
  },
  {
    "id": "1102022023",
    "district_id": "110202",
    "label": "Bukit Sepakat",
    "value": "Bukit Sepakat"
  },
  {
    "id": "1102022024",
    "district_id": "110202",
    "label": "Lawe Loning Sepakat",
    "value": "Lawe Loning Sepakat"
  },
  {
    "id": "1102022025",
    "district_id": "110202",
    "label": "Lawe Loning I",
    "value": "Lawe Loning I"
  },
  {
    "id": "1102022026",
    "district_id": "110202",
    "label": "Gaya Jaya",
    "value": "Gaya Jaya"
  },
  {
    "id": "1102022027",
    "district_id": "110202",
    "label": "Gelah Musara",
    "value": "Gelah Musara"
  },
  {
    "id": "1102022028",
    "district_id": "110202",
    "label": "Mulie Dame",
    "value": "Mulie Dame"
  },
  {
    "id": "1102022029",
    "district_id": "110202",
    "label": "Lawe Loning Hakhapen",
    "value": "Lawe Loning Hakhapen"
  },
  {
    "id": "1102022030",
    "district_id": "110202",
    "label": "Karya Indah",
    "value": "Karya Indah"
  },
  {
    "id": "1102022031",
    "district_id": "110202",
    "label": "Kayu Mbelin",
    "value": "Kayu Mbelin"
  },
  {
    "id": "1102022032",
    "district_id": "110202",
    "label": "Lawe Sigala Barat Jaya",
    "value": "Lawe Sigala Barat Jaya"
  },
  {
    "id": "1102022033",
    "district_id": "110202",
    "label": "Lawe Pekhidinen",
    "value": "Lawe Pekhidinen"
  },
  {
    "id": "1102022034",
    "district_id": "110202",
    "label": "Kertimbang",
    "value": "Kertimbang"
  },
  {
    "id": "1102022035",
    "district_id": "110202",
    "label": "Ndauh Nitenggo",
    "value": "Ndauh Nitenggo"
  },
  {
    "id": "1102032001",
    "district_id": "110203",
    "label": "Bambel Gabungan",
    "value": "Bambel Gabungan"
  },
  {
    "id": "1102032002",
    "district_id": "110203",
    "label": "Kisam",
    "value": "Kisam"
  },
  {
    "id": "1102032005",
    "district_id": "110203",
    "label": "Lawe Hijo",
    "value": "Lawe Hijo"
  },
  {
    "id": "1102032006",
    "district_id": "110203",
    "label": "Pinding",
    "value": "Pinding"
  },
  {
    "id": "1102032009",
    "district_id": "110203",
    "label": "Terutung Megara Asli",
    "value": "Terutung Megara Asli"
  },
  {
    "id": "1102032011",
    "district_id": "110203",
    "label": "Biak Muli",
    "value": "Biak Muli"
  },
  {
    "id": "1102032012",
    "district_id": "110203",
    "label": "Kute Lang-Lang",
    "value": "Kute Lang-Lang"
  },
  {
    "id": "1102032013",
    "district_id": "110203",
    "label": "Pedesi",
    "value": "Pedesi"
  },
  {
    "id": "1102032014",
    "district_id": "110203",
    "label": "Terutung Payung Hulu",
    "value": "Terutung Payung Hulu"
  },
  {
    "id": "1102032015",
    "district_id": "110203",
    "label": "Terutung Payung Hilir",
    "value": "Terutung Payung Hilir"
  },
  {
    "id": "1102032016",
    "district_id": "110203",
    "label": "Tualang Sembilar",
    "value": "Tualang Sembilar"
  },
  {
    "id": "1102032017",
    "district_id": "110203",
    "label": "Kuning I",
    "value": "Kuning I"
  },
  {
    "id": "1102032018",
    "district_id": "110203",
    "label": "Kuning II",
    "value": "Kuning II"
  },
  {
    "id": "1102032019",
    "district_id": "110203",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1102032020",
    "district_id": "110203",
    "label": "Likat",
    "value": "Likat"
  },
  {
    "id": "1102032021",
    "district_id": "110203",
    "label": "Rikit",
    "value": "Rikit"
  },
  {
    "id": "1102032023",
    "district_id": "110203",
    "label": "Bambel",
    "value": "Bambel"
  },
  {
    "id": "1102032025",
    "district_id": "110203",
    "label": "Lawe Kihing",
    "value": "Lawe Kihing"
  },
  {
    "id": "1102032026",
    "district_id": "110203",
    "label": "Terutung Seperai",
    "value": "Terutung Seperai"
  },
  {
    "id": "1102032027",
    "district_id": "110203",
    "label": "Kute Seri",
    "value": "Kute Seri"
  },
  {
    "id": "1102032028",
    "district_id": "110203",
    "label": "Kuta Antara",
    "value": "Kuta Antara"
  },
  {
    "id": "1102032029",
    "district_id": "110203",
    "label": "Pulo Perengge",
    "value": "Pulo Perengge"
  },
  {
    "id": "1102032031",
    "district_id": "110203",
    "label": "Pulo Kendondong",
    "value": "Pulo Kendondong"
  },
  {
    "id": "1102032033",
    "district_id": "110203",
    "label": "Pancakh Iman",
    "value": "Pancakh Iman"
  },
  {
    "id": "1102032034",
    "district_id": "110203",
    "label": "Biak Muli Baru",
    "value": "Biak Muli Baru"
  },
  {
    "id": "1102032035",
    "district_id": "110203",
    "label": "Biak Muli Pantai Raja",
    "value": "Biak Muli Pantai Raja"
  },
  {
    "id": "1102032036",
    "district_id": "110203",
    "label": "Biak Muli Sejahtera",
    "value": "Biak Muli Sejahtera"
  },
  {
    "id": "1102032037",
    "district_id": "110203",
    "label": "Lembah Haji",
    "value": "Lembah Haji"
  },
  {
    "id": "1102032038",
    "district_id": "110203",
    "label": "Terutung Payung Gabungan",
    "value": "Terutung Payung Gabungan"
  },
  {
    "id": "1102032039",
    "district_id": "110203",
    "label": "Lawe Hijo Metuah",
    "value": "Lawe Hijo Metuah"
  },
  {
    "id": "1102032040",
    "district_id": "110203",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "1102032041",
    "district_id": "110203",
    "label": "Tembilakh Mbakhu",
    "value": "Tembilakh Mbakhu"
  },
  {
    "id": "1102032042",
    "district_id": "110203",
    "label": "Kute Lang-Lang Bakhu",
    "value": "Kute Lang-Lang Bakhu"
  },
  {
    "id": "1102042001",
    "district_id": "110204",
    "label": "Kota Kutacane",
    "value": "Kota Kutacane"
  },
  {
    "id": "1102042002",
    "district_id": "110204",
    "label": "Perapat Hilir",
    "value": "Perapat Hilir"
  },
  {
    "id": "1102042003",
    "district_id": "110204",
    "label": "Perapat Hulu",
    "value": "Perapat Hulu"
  },
  {
    "id": "1102042004",
    "district_id": "110204",
    "label": "Muara Lawe Bulan",
    "value": "Muara Lawe Bulan"
  },
  {
    "id": "1102042005",
    "district_id": "110204",
    "label": "Mbarung",
    "value": "Mbarung"
  },
  {
    "id": "1102042006",
    "district_id": "110204",
    "label": "Batu Mbulan Asli",
    "value": "Batu Mbulan Asli"
  },
  {
    "id": "1102042007",
    "district_id": "110204",
    "label": "Batu Mbulan I",
    "value": "Batu Mbulan I"
  },
  {
    "id": "1102042008",
    "district_id": "110204",
    "label": "Batu Mbulan II",
    "value": "Batu Mbulan II"
  },
  {
    "id": "1102042009",
    "district_id": "110204",
    "label": "Kampung Raja",
    "value": "Kampung Raja"
  },
  {
    "id": "1102042010",
    "district_id": "110204",
    "label": "Pulonas",
    "value": "Pulonas"
  },
  {
    "id": "1102042011",
    "district_id": "110204",
    "label": "Kampung Melayu Gabungan",
    "value": "Kampung Melayu Gabungan"
  },
  {
    "id": "1102042012",
    "district_id": "110204",
    "label": "Terutung Pedi",
    "value": "Terutung Pedi"
  },
  {
    "id": "1102042013",
    "district_id": "110204",
    "label": "Kute Rih",
    "value": "Kute Rih"
  },
  {
    "id": "1102042014",
    "district_id": "110204",
    "label": "Gumpang Jaya",
    "value": "Gumpang Jaya"
  },
  {
    "id": "1102042015",
    "district_id": "110204",
    "label": "Pulo Latong",
    "value": "Pulo Latong"
  },
  {
    "id": "1102042016",
    "district_id": "110204",
    "label": "Kutacane Lama",
    "value": "Kutacane Lama"
  },
  {
    "id": "1102042017",
    "district_id": "110204",
    "label": "Batu Mbulan Mbaru",
    "value": "Batu Mbulan Mbaru"
  },
  {
    "id": "1102042018",
    "district_id": "110204",
    "label": "Pulo Peding",
    "value": "Pulo Peding"
  },
  {
    "id": "1102042019",
    "district_id": "110204",
    "label": "Mendabe",
    "value": "Mendabe"
  },
  {
    "id": "1102042020",
    "district_id": "110204",
    "label": "Ujung Barat",
    "value": "Ujung Barat"
  },
  {
    "id": "1102042021",
    "district_id": "110204",
    "label": "Pekhapat Titi Panjang",
    "value": "Pekhapat Titi Panjang"
  },
  {
    "id": "1102042022",
    "district_id": "110204",
    "label": "Pekhapat Sepakat",
    "value": "Pekhapat Sepakat"
  },
  {
    "id": "1102042023",
    "district_id": "110204",
    "label": "Alas Marantcar",
    "value": "Alas Marantcar"
  },
  {
    "id": "1102042024",
    "district_id": "110204",
    "label": "Batu Mbulan Sepakat",
    "value": "Batu Mbulan Sepakat"
  },
  {
    "id": "1102042025",
    "district_id": "110204",
    "label": "Pulo Sanggakh",
    "value": "Pulo Sanggakh"
  },
  {
    "id": "1102042026",
    "district_id": "110204",
    "label": "Melayu I",
    "value": "Melayu I"
  },
  {
    "id": "1102042027",
    "district_id": "110204",
    "label": "Mbarung Datuk Saudane",
    "value": "Mbarung Datuk Saudane"
  },
  {
    "id": "1102052005",
    "district_id": "110205",
    "label": "Salang Alas",
    "value": "Salang Alas"
  },
  {
    "id": "1102052009",
    "district_id": "110205",
    "label": "Kuta Pasir",
    "value": "Kuta Pasir"
  },
  {
    "id": "1102052011",
    "district_id": "110205",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1102052012",
    "district_id": "110205",
    "label": "Kuta Tinggi",
    "value": "Kuta Tinggi"
  },
  {
    "id": "1102052013",
    "district_id": "110205",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1102052014",
    "district_id": "110205",
    "label": "Peranginan",
    "value": "Peranginan"
  },
  {
    "id": "1102052015",
    "district_id": "110205",
    "label": "Natam",
    "value": "Natam"
  },
  {
    "id": "1102052025",
    "district_id": "110205",
    "label": "Kumbang Jaya",
    "value": "Kumbang Jaya"
  },
  {
    "id": "1102052026",
    "district_id": "110205",
    "label": "Kumbang Indah",
    "value": "Kumbang Indah"
  },
  {
    "id": "1102052027",
    "district_id": "110205",
    "label": "Natam Baru",
    "value": "Natam Baru"
  },
  {
    "id": "1102052029",
    "district_id": "110205",
    "label": "Deleng Megakhe",
    "value": "Deleng Megakhe"
  },
  {
    "id": "1102052030",
    "district_id": "110205",
    "label": "Lawe Bekung",
    "value": "Lawe Bekung"
  },
  {
    "id": "1102052031",
    "district_id": "110205",
    "label": "Lawe Bekung Tampahan",
    "value": "Lawe Bekung Tampahan"
  },
  {
    "id": "1102052032",
    "district_id": "110205",
    "label": "Badar Indah",
    "value": "Badar Indah"
  },
  {
    "id": "1102052033",
    "district_id": "110205",
    "label": "Batu Mbekhong",
    "value": "Batu Mbekhong"
  },
  {
    "id": "1102052034",
    "district_id": "110205",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1102052035",
    "district_id": "110205",
    "label": "Tanoh Megakhe",
    "value": "Tanoh Megakhe"
  },
  {
    "id": "1102052036",
    "district_id": "110205",
    "label": "Lawe Sekerah",
    "value": "Lawe Sekerah"
  },
  {
    "id": "1102062001",
    "district_id": "110206",
    "label": "Pardomuan II",
    "value": "Pardomuan II"
  },
  {
    "id": "1102062002",
    "district_id": "110206",
    "label": "Lawe Desky I",
    "value": "Lawe Desky I"
  },
  {
    "id": "1102062003",
    "district_id": "110206",
    "label": "Lawe Desky Sabas",
    "value": "Lawe Desky Sabas"
  },
  {
    "id": "1102062004",
    "district_id": "110206",
    "label": "Pardomuan I",
    "value": "Pardomuan I"
  },
  {
    "id": "1102062005",
    "district_id": "110206",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1102062006",
    "district_id": "110206",
    "label": "Pardamean",
    "value": "Pardamean"
  },
  {
    "id": "1102062007",
    "district_id": "110206",
    "label": "Lawe Desky Tongah",
    "value": "Lawe Desky Tongah"
  },
  {
    "id": "1102062008",
    "district_id": "110206",
    "label": "Kute Bakti",
    "value": "Kute Bakti"
  },
  {
    "id": "1102062010",
    "district_id": "110206",
    "label": "Muara Situlen",
    "value": "Muara Situlen"
  },
  {
    "id": "1102062011",
    "district_id": "110206",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "1102062012",
    "district_id": "110206",
    "label": "Kute Makmur",
    "value": "Kute Makmur"
  },
  {
    "id": "1102062013",
    "district_id": "110206",
    "label": "Pardamean",
    "value": "Pardamean"
  },
  {
    "id": "1102062014",
    "district_id": "110206",
    "label": "Lawe Mantik",
    "value": "Lawe Mantik"
  },
  {
    "id": "1102062015",
    "district_id": "110206",
    "label": "Cinta Makmur",
    "value": "Cinta Makmur"
  },
  {
    "id": "1102062016",
    "district_id": "110206",
    "label": "Muara Setia",
    "value": "Muara Setia"
  },
  {
    "id": "1102062017",
    "district_id": "110206",
    "label": "Tanoh Alas",
    "value": "Tanoh Alas"
  },
  {
    "id": "1102062018",
    "district_id": "110206",
    "label": "Sejahtera Baru",
    "value": "Sejahtera Baru"
  },
  {
    "id": "1102062024",
    "district_id": "110206",
    "label": "Lawe Tawakh",
    "value": "Lawe Tawakh"
  },
  {
    "id": "1102062025",
    "district_id": "110206",
    "label": "Sabilussalam",
    "value": "Sabilussalam"
  },
  {
    "id": "1102062026",
    "district_id": "110206",
    "label": "Lawe Desky Jaya",
    "value": "Lawe Desky Jaya"
  },
  {
    "id": "1102062027",
    "district_id": "110206",
    "label": "Pintu Alas",
    "value": "Pintu Alas"
  },
  {
    "id": "1102072001",
    "district_id": "110207",
    "label": "Gulo",
    "value": "Gulo"
  },
  {
    "id": "1102072002",
    "district_id": "110207",
    "label": "Pulo Piku",
    "value": "Pulo Piku"
  },
  {
    "id": "1102072003",
    "district_id": "110207",
    "label": "Terutung Kute",
    "value": "Terutung Kute"
  },
  {
    "id": "1102072004",
    "district_id": "110207",
    "label": "Kute Ujung",
    "value": "Kute Ujung"
  },
  {
    "id": "1102072005",
    "district_id": "110207",
    "label": "Lawe Stul",
    "value": "Lawe Stul"
  },
  {
    "id": "1102072006",
    "district_id": "110207",
    "label": "Kite Merangun",
    "value": "Kite Merangun"
  },
  {
    "id": "1102072007",
    "district_id": "110207",
    "label": "Mamas",
    "value": "Mamas"
  },
  {
    "id": "1102072008",
    "district_id": "110207",
    "label": "Tanjung Lama",
    "value": "Tanjung Lama"
  },
  {
    "id": "1102072009",
    "district_id": "110207",
    "label": "Tanjung Muda",
    "value": "Tanjung Muda"
  },
  {
    "id": "1102072010",
    "district_id": "110207",
    "label": "Rambung Teldak",
    "value": "Rambung Teldak"
  },
  {
    "id": "1102072011",
    "district_id": "110207",
    "label": "Tanjung Aman",
    "value": "Tanjung Aman"
  },
  {
    "id": "1102072012",
    "district_id": "110207",
    "label": "Sri Muda",
    "value": "Sri Muda"
  },
  {
    "id": "1102072013",
    "district_id": "110207",
    "label": "Ujung Baru",
    "value": "Ujung Baru"
  },
  {
    "id": "1102072014",
    "district_id": "110207",
    "label": "Kute Rambe",
    "value": "Kute Rambe"
  },
  {
    "id": "1102072015",
    "district_id": "110207",
    "label": "Mamas Baru",
    "value": "Mamas Baru"
  },
  {
    "id": "1102072016",
    "district_id": "110207",
    "label": "Rambung Jaya",
    "value": "Rambung Jaya"
  },
  {
    "id": "1102072017",
    "district_id": "110207",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1102072018",
    "district_id": "110207",
    "label": "Darul Makmur",
    "value": "Darul Makmur"
  },
  {
    "id": "1102072019",
    "district_id": "110207",
    "label": "Tanjung Bakhu",
    "value": "Tanjung Bakhu"
  },
  {
    "id": "1102072020",
    "district_id": "110207",
    "label": "Pulo Gabung",
    "value": "Pulo Gabung"
  },
  {
    "id": "1102072021",
    "district_id": "110207",
    "label": "Kokhtan Jaya",
    "value": "Kokhtan Jaya"
  },
  {
    "id": "1102072022",
    "district_id": "110207",
    "label": "Istiqamah",
    "value": "Istiqamah"
  },
  {
    "id": "1102072023",
    "district_id": "110207",
    "label": "Lawe Mamas Indah",
    "value": "Lawe Mamas Indah"
  },
  {
    "id": "1102072024",
    "district_id": "110207",
    "label": "Tanjung Leuser",
    "value": "Tanjung Leuser"
  },
  {
    "id": "1102072025",
    "district_id": "110207",
    "label": "Simpang IV Tanjung",
    "value": "Simpang IV Tanjung"
  },
  {
    "id": "1102072026",
    "district_id": "110207",
    "label": "Lawe Pinis",
    "value": "Lawe Pinis"
  },
  {
    "id": "1102072027",
    "district_id": "110207",
    "label": "Buntul Kendawi",
    "value": "Buntul Kendawi"
  },
  {
    "id": "1102072028",
    "district_id": "110207",
    "label": "Kuning Abadi",
    "value": "Kuning Abadi"
  },
  {
    "id": "1102082001",
    "district_id": "110208",
    "label": "Pasir Gala",
    "value": "Pasir Gala"
  },
  {
    "id": "1102082002",
    "district_id": "110208",
    "label": "Pasir Penjengakan",
    "value": "Pasir Penjengakan"
  },
  {
    "id": "1102082003",
    "district_id": "110208",
    "label": "Kutambaru",
    "value": "Kutambaru"
  },
  {
    "id": "1102082004",
    "district_id": "110208",
    "label": "Lawe Sagu Hulu",
    "value": "Lawe Sagu Hulu"
  },
  {
    "id": "1102082005",
    "district_id": "110208",
    "label": "Lawe Sagu Hilir",
    "value": "Lawe Sagu Hilir"
  },
  {
    "id": "1102082006",
    "district_id": "110208",
    "label": "Kute Bantil",
    "value": "Kute Bantil"
  },
  {
    "id": "1102082011",
    "district_id": "110208",
    "label": "Lawe Kolok",
    "value": "Lawe Kolok"
  },
  {
    "id": "1102082012",
    "district_id": "110208",
    "label": "Lawe Rutung",
    "value": "Lawe Rutung"
  },
  {
    "id": "1102082013",
    "district_id": "110208",
    "label": "Kampung Nangka",
    "value": "Kampung Nangka"
  },
  {
    "id": "1102082014",
    "district_id": "110208",
    "label": "Tenembak Juhar",
    "value": "Tenembak Juhar"
  },
  {
    "id": "1102082015",
    "district_id": "110208",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1102082016",
    "district_id": "110208",
    "label": "Kuta Buluh Botong",
    "value": "Kuta Buluh Botong"
  },
  {
    "id": "1102082017",
    "district_id": "110208",
    "label": "Katambaru Becawan",
    "value": "Katambaru Becawan"
  },
  {
    "id": "1102082018",
    "district_id": "110208",
    "label": "Lawe Kinge",
    "value": "Lawe Kinge"
  },
  {
    "id": "1102082019",
    "district_id": "110208",
    "label": "Kandang Mbelang Mandiri",
    "value": "Kandang Mbelang Mandiri"
  },
  {
    "id": "1102082020",
    "district_id": "110208",
    "label": "Mbacang Racun",
    "value": "Mbacang Racun"
  },
  {
    "id": "1102082021",
    "district_id": "110208",
    "label": "Telaga Mekar",
    "value": "Telaga Mekar"
  },
  {
    "id": "1102082023",
    "district_id": "110208",
    "label": "Pulonas Baru",
    "value": "Pulonas Baru"
  },
  {
    "id": "1102082025",
    "district_id": "110208",
    "label": "Kuta Genting",
    "value": "Kuta Genting"
  },
  {
    "id": "1102082026",
    "district_id": "110208",
    "label": "Lawe Sagu Baru",
    "value": "Lawe Sagu Baru"
  },
  {
    "id": "1102082027",
    "district_id": "110208",
    "label": "Lawe Sagu",
    "value": "Lawe Sagu"
  },
  {
    "id": "1102082028",
    "district_id": "110208",
    "label": "Pekhapet Timur",
    "value": "Pekhapet Timur"
  },
  {
    "id": "1102082029",
    "district_id": "110208",
    "label": "Bahagia",
    "value": "Bahagia"
  },
  {
    "id": "1102082030",
    "district_id": "110208",
    "label": "Kute Galuh Asli",
    "value": "Kute Galuh Asli"
  },
  {
    "id": "1102092001",
    "district_id": "110209",
    "label": "Kute Lengat Pangan",
    "value": "Kute Lengat Pangan"
  },
  {
    "id": "1102092002",
    "district_id": "110209",
    "label": "Kute Lengat Selian",
    "value": "Kute Lengat Selian"
  },
  {
    "id": "1102092003",
    "district_id": "110209",
    "label": "Tualang Baru",
    "value": "Tualang Baru"
  },
  {
    "id": "1102092004",
    "district_id": "110209",
    "label": "Alur Buluh",
    "value": "Alur Buluh"
  },
  {
    "id": "1102092005",
    "district_id": "110209",
    "label": "Maha Singkil",
    "value": "Maha Singkil"
  },
  {
    "id": "1102092006",
    "district_id": "110209",
    "label": "Tenembak Bintang",
    "value": "Tenembak Bintang"
  },
  {
    "id": "1102092007",
    "district_id": "110209",
    "label": "Kute Lingga",
    "value": "Kute Lingga"
  },
  {
    "id": "1102092008",
    "district_id": "110209",
    "label": "Rikit Bur",
    "value": "Rikit Bur"
  },
  {
    "id": "1102092010",
    "district_id": "110209",
    "label": "Kerukunan",
    "value": "Kerukunan"
  },
  {
    "id": "1102092011",
    "district_id": "110209",
    "label": "Lawe Dua",
    "value": "Lawe Dua"
  },
  {
    "id": "1102092012",
    "district_id": "110209",
    "label": "Rema",
    "value": "Rema"
  },
  {
    "id": "1102092013",
    "district_id": "110209",
    "label": "Bambel Baru",
    "value": "Bambel Baru"
  },
  {
    "id": "1102092014",
    "district_id": "110209",
    "label": "Pejuang",
    "value": "Pejuang"
  },
  {
    "id": "1102092015",
    "district_id": "110209",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1102092016",
    "district_id": "110209",
    "label": "Amaliah",
    "value": "Amaliah"
  },
  {
    "id": "1102092017",
    "district_id": "110209",
    "label": "Sebudi Jaya",
    "value": "Sebudi Jaya"
  },
  {
    "id": "1102092018",
    "district_id": "110209",
    "label": "Gumpang",
    "value": "Gumpang"
  },
  {
    "id": "1102092019",
    "district_id": "110209",
    "label": "Kute Gekhat",
    "value": "Kute Gekhat"
  },
  {
    "id": "1102092020",
    "district_id": "110209",
    "label": "Paye Khambung",
    "value": "Paye Khambung"
  },
  {
    "id": "1102092021",
    "district_id": "110209",
    "label": "Mbak Sako",
    "value": "Mbak Sako"
  },
  {
    "id": "1102092022",
    "district_id": "110209",
    "label": "Darul Imami",
    "value": "Darul Imami"
  },
  {
    "id": "1102092023",
    "district_id": "110209",
    "label": "Rikit Bur II",
    "value": "Rikit Bur II"
  },
  {
    "id": "1102092024",
    "district_id": "110209",
    "label": "Kute Empat Lima",
    "value": "Kute Empat Lima"
  },
  {
    "id": "1102102001",
    "district_id": "110210",
    "label": "Simpang Semadam",
    "value": "Simpang Semadam"
  },
  {
    "id": "1102102002",
    "district_id": "110210",
    "label": "Semadam Awal",
    "value": "Semadam Awal"
  },
  {
    "id": "1102102003",
    "district_id": "110210",
    "label": "Semadam Asal",
    "value": "Semadam Asal"
  },
  {
    "id": "1102102004",
    "district_id": "110210",
    "label": "Lawe Kinga Gabungan",
    "value": "Lawe Kinga Gabungan"
  },
  {
    "id": "1102102005",
    "district_id": "110210",
    "label": "Lawe Kinga Tebing Tinggi",
    "value": "Lawe Kinga Tebing Tinggi"
  },
  {
    "id": "1102102006",
    "district_id": "110210",
    "label": "Kebun Sere",
    "value": "Kebun Sere"
  },
  {
    "id": "1102102007",
    "district_id": "110210",
    "label": "Lawe Beringin Horas",
    "value": "Lawe Beringin Horas"
  },
  {
    "id": "1102102008",
    "district_id": "110210",
    "label": "Lawe Beringin Gayo",
    "value": "Lawe Beringin Gayo"
  },
  {
    "id": "1102102009",
    "district_id": "110210",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1102102010",
    "district_id": "110210",
    "label": "Pasar Puntung",
    "value": "Pasar Puntung"
  },
  {
    "id": "1102102011",
    "district_id": "110210",
    "label": "Titi Pasir",
    "value": "Titi Pasir"
  },
  {
    "id": "1102102012",
    "district_id": "110210",
    "label": "Lawe Petanduk I",
    "value": "Lawe Petanduk I"
  },
  {
    "id": "1102102013",
    "district_id": "110210",
    "label": "Ngkeran Alur Buluh",
    "value": "Ngkeran Alur Buluh"
  },
  {
    "id": "1102102014",
    "district_id": "110210",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1102102015",
    "district_id": "110210",
    "label": "Sepakat Segenap",
    "value": "Sepakat Segenap"
  },
  {
    "id": "1102102016",
    "district_id": "110210",
    "label": "Selamat Indah",
    "value": "Selamat Indah"
  },
  {
    "id": "1102102017",
    "district_id": "110210",
    "label": "Lawe Kinge Lapter",
    "value": "Lawe Kinge Lapter"
  },
  {
    "id": "1102102018",
    "district_id": "110210",
    "label": "Lawe Mejile",
    "value": "Lawe Mejile"
  },
  {
    "id": "1102102019",
    "district_id": "110210",
    "label": "Lawe Petanduk-II",
    "value": "Lawe Petanduk-II"
  },
  {
    "id": "1102112004",
    "district_id": "110211",
    "label": "Titi Mas",
    "value": "Titi Mas"
  },
  {
    "id": "1102112005",
    "district_id": "110211",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "1102112006",
    "district_id": "110211",
    "label": "Kute Lang-Lang",
    "value": "Kute Lang-Lang"
  },
  {
    "id": "1102112007",
    "district_id": "110211",
    "label": "Lawe Sumur",
    "value": "Lawe Sumur"
  },
  {
    "id": "1102112008",
    "district_id": "110211",
    "label": "Pardamaian",
    "value": "Pardamaian"
  },
  {
    "id": "1102112009",
    "district_id": "110211",
    "label": "Salim Pipit",
    "value": "Salim Pipit"
  },
  {
    "id": "1102112010",
    "district_id": "110211",
    "label": "Penguhapan",
    "value": "Penguhapan"
  },
  {
    "id": "1102112011",
    "district_id": "110211",
    "label": "Lumban Tua",
    "value": "Lumban Tua"
  },
  {
    "id": "1102112012",
    "district_id": "110211",
    "label": "Mutiara Damai",
    "value": "Mutiara Damai"
  },
  {
    "id": "1102112013",
    "district_id": "110211",
    "label": "Dolok Nauli",
    "value": "Dolok Nauli"
  },
  {
    "id": "1102112014",
    "district_id": "110211",
    "label": "Tuhi Jongkat",
    "value": "Tuhi Jongkat"
  },
  {
    "id": "1102112015",
    "district_id": "110211",
    "label": "Uning Sigugur",
    "value": "Uning Sigugur"
  },
  {
    "id": "1102112016",
    "district_id": "110211",
    "label": "Lumban Sitio-tio",
    "value": "Lumban Sitio-tio"
  },
  {
    "id": "1102112017",
    "district_id": "110211",
    "label": "Lawe Malum",
    "value": "Lawe Malum"
  },
  {
    "id": "1102112018",
    "district_id": "110211",
    "label": "Alur Baning",
    "value": "Alur Baning"
  },
  {
    "id": "1102112019",
    "district_id": "110211",
    "label": "Lingga Alas",
    "value": "Lingga Alas"
  },
  {
    "id": "1102112020",
    "district_id": "110211",
    "label": "Khambung Tumbung",
    "value": "Khambung Tumbung"
  },
  {
    "id": "1102112021",
    "district_id": "110211",
    "label": "Tuah Mesade",
    "value": "Tuah Mesade"
  },
  {
    "id": "1102112022",
    "district_id": "110211",
    "label": "Sumukh Alas",
    "value": "Sumukh Alas"
  },
  {
    "id": "1102112023",
    "district_id": "110211",
    "label": "Alukh Bakhu",
    "value": "Alukh Bakhu"
  },
  {
    "id": "1102112024",
    "district_id": "110211",
    "label": "Sepekhinding",
    "value": "Sepekhinding"
  },
  {
    "id": "1102112025",
    "district_id": "110211",
    "label": "Tanoh Subukh",
    "value": "Tanoh Subukh"
  },
  {
    "id": "1102112026",
    "district_id": "110211",
    "label": "Pinggan Mbelang",
    "value": "Pinggan Mbelang"
  },
  {
    "id": "1102112027",
    "district_id": "110211",
    "label": "Sigai Indah",
    "value": "Sigai Indah"
  },
  {
    "id": "1102112028",
    "district_id": "110211",
    "label": "Ukekh Deleng",
    "value": "Ukekh Deleng"
  },
  {
    "id": "1102112029",
    "district_id": "110211",
    "label": "Alas Mesikhat",
    "value": "Alas Mesikhat"
  },
  {
    "id": "1102112030",
    "district_id": "110211",
    "label": "Titi Hakhapen",
    "value": "Titi Hakhapen"
  },
  {
    "id": "1102122001",
    "district_id": "110212",
    "label": "Aunan Sepakat",
    "value": "Aunan Sepakat"
  },
  {
    "id": "1102122002",
    "district_id": "110212",
    "label": "Lawe Penanggalan",
    "value": "Lawe Penanggalan"
  },
  {
    "id": "1102122003",
    "district_id": "110212",
    "label": "Lawe Mengkudu",
    "value": "Lawe Mengkudu"
  },
  {
    "id": "1102122004",
    "district_id": "110212",
    "label": "Jambur Lak-lak",
    "value": "Jambur Lak-lak"
  },
  {
    "id": "1102122005",
    "district_id": "110212",
    "label": "Simpang Tiga Jongar",
    "value": "Simpang Tiga Jongar"
  },
  {
    "id": "1102122006",
    "district_id": "110212",
    "label": "Jongar Asli",
    "value": "Jongar Asli"
  },
  {
    "id": "1102122007",
    "district_id": "110212",
    "label": "Ketambe",
    "value": "Ketambe"
  },
  {
    "id": "1102122008",
    "district_id": "110212",
    "label": "Rumah Bundar",
    "value": "Rumah Bundar"
  },
  {
    "id": "1102122009",
    "district_id": "110212",
    "label": "Penyeberangan",
    "value": "Penyeberangan"
  },
  {
    "id": "1102122010",
    "district_id": "110212",
    "label": "Deleng Damar",
    "value": "Deleng Damar"
  },
  {
    "id": "1102122011",
    "district_id": "110212",
    "label": "Bintang Bener",
    "value": "Bintang Bener"
  },
  {
    "id": "1102122012",
    "district_id": "110212",
    "label": "Suka Rimbun",
    "value": "Suka Rimbun"
  },
  {
    "id": "1102122013",
    "district_id": "110212",
    "label": "Lawe Aunan",
    "value": "Lawe Aunan"
  },
  {
    "id": "1102122014",
    "district_id": "110212",
    "label": "Bener Bepapah",
    "value": "Bener Bepapah"
  },
  {
    "id": "1102122015",
    "district_id": "110212",
    "label": "Penungkunen",
    "value": "Penungkunen"
  },
  {
    "id": "1102122016",
    "district_id": "110212",
    "label": "Datok Pining",
    "value": "Datok Pining"
  },
  {
    "id": "1102122017",
    "district_id": "110212",
    "label": "Kati Maju",
    "value": "Kati Maju"
  },
  {
    "id": "1102122018",
    "district_id": "110212",
    "label": "Leuser",
    "value": "Leuser"
  },
  {
    "id": "1102122019",
    "district_id": "110212",
    "label": "Jati Sara",
    "value": "Jati Sara"
  },
  {
    "id": "1102122020",
    "district_id": "110212",
    "label": "Bukit Mbakhu",
    "value": "Bukit Mbakhu"
  },
  {
    "id": "1102122021",
    "district_id": "110212",
    "label": "Kayu Metangur",
    "value": "Kayu Metangur"
  },
  {
    "id": "1102122022",
    "district_id": "110212",
    "label": "Lawe Sembekan",
    "value": "Lawe Sembekan"
  },
  {
    "id": "1102122023",
    "district_id": "110212",
    "label": "Lawe Beringin",
    "value": "Lawe Beringin"
  },
  {
    "id": "1102122024",
    "district_id": "110212",
    "label": "Lawe Ger-Ger",
    "value": "Lawe Ger-Ger"
  },
  {
    "id": "1102122025",
    "district_id": "110212",
    "label": "Simpur Jaya",
    "value": "Simpur Jaya"
  },
  {
    "id": "1102132001",
    "district_id": "110213",
    "label": "Salang Baru",
    "value": "Salang Baru"
  },
  {
    "id": "1102132002",
    "district_id": "110213",
    "label": "Ranto Dior",
    "value": "Ranto Dior"
  },
  {
    "id": "1102132003",
    "district_id": "110213",
    "label": "Lembah Alas",
    "value": "Lembah Alas"
  },
  {
    "id": "1102132004",
    "district_id": "110213",
    "label": "Tenembak Lang-Lang",
    "value": "Tenembak Lang-Lang"
  },
  {
    "id": "1102132005",
    "district_id": "110213",
    "label": "Salang Sigotom",
    "value": "Salang Sigotom"
  },
  {
    "id": "1102132006",
    "district_id": "110213",
    "label": "Tualang Lama",
    "value": "Tualang Lama"
  },
  {
    "id": "1102132007",
    "district_id": "110213",
    "label": "Gusung Batu",
    "value": "Gusung Batu"
  },
  {
    "id": "1102132008",
    "district_id": "110213",
    "label": "Kaya Pangur",
    "value": "Kaya Pangur"
  },
  {
    "id": "1102132009",
    "district_id": "110213",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "1102132010",
    "district_id": "110213",
    "label": "Lawe Pangkat",
    "value": "Lawe Pangkat"
  },
  {
    "id": "1102132011",
    "district_id": "110213",
    "label": "Bunga Melur",
    "value": "Bunga Melur"
  },
  {
    "id": "1102132012",
    "district_id": "110213",
    "label": "Penampaan",
    "value": "Penampaan"
  },
  {
    "id": "1102132013",
    "district_id": "110213",
    "label": "Terutung Mbelang",
    "value": "Terutung Mbelang"
  },
  {
    "id": "1102132014",
    "district_id": "110213",
    "label": "Salang Muara",
    "value": "Salang Muara"
  },
  {
    "id": "1102132015",
    "district_id": "110213",
    "label": "Lawe Hakhum",
    "value": "Lawe Hakhum"
  },
  {
    "id": "1102132016",
    "district_id": "110213",
    "label": "Tanoh Khukahen",
    "value": "Tanoh Khukahen"
  },
  {
    "id": "1102132017",
    "district_id": "110213",
    "label": "Tading Ni Ulihi",
    "value": "Tading Ni Ulihi"
  },
  {
    "id": "1102132018",
    "district_id": "110213",
    "label": "Peseluk Pesimbe",
    "value": "Peseluk Pesimbe"
  },
  {
    "id": "1102132019",
    "district_id": "110213",
    "label": "Kane Lot",
    "value": "Kane Lot"
  },
  {
    "id": "1102132020",
    "district_id": "110213",
    "label": "Muhajirin",
    "value": "Muhajirin"
  },
  {
    "id": "1102132021",
    "district_id": "110213",
    "label": "Beriring Naru",
    "value": "Beriring Naru"
  },
  {
    "id": "1102132022",
    "district_id": "110213",
    "label": "Kati Jeroh",
    "value": "Kati Jeroh"
  },
  {
    "id": "1102142001",
    "district_id": "110214",
    "label": "Terutung Megara Lawe Pasaran",
    "value": "Terutung Megara Lawe Pasaran"
  },
  {
    "id": "1102142002",
    "district_id": "110214",
    "label": "Kisam Gabungan",
    "value": "Kisam Gabungan"
  },
  {
    "id": "1102142003",
    "district_id": "110214",
    "label": "Kisam Kute Pasir",
    "value": "Kisam Kute Pasir"
  },
  {
    "id": "1102142004",
    "district_id": "110214",
    "label": "Lawe Sumur",
    "value": "Lawe Sumur"
  },
  {
    "id": "1102142005",
    "district_id": "110214",
    "label": "Lawe Sumur Baru",
    "value": "Lawe Sumur Baru"
  },
  {
    "id": "1102142006",
    "district_id": "110214",
    "label": "Kute Lesung",
    "value": "Kute Lesung"
  },
  {
    "id": "1102142007",
    "district_id": "110214",
    "label": "Berandang",
    "value": "Berandang"
  },
  {
    "id": "1102142008",
    "district_id": "110214",
    "label": "Kute Bunin",
    "value": "Kute Bunin"
  },
  {
    "id": "1102142009",
    "district_id": "110214",
    "label": "Buah Pala",
    "value": "Buah Pala"
  },
  {
    "id": "1102142010",
    "district_id": "110214",
    "label": "Panosan",
    "value": "Panosan"
  },
  {
    "id": "1102142011",
    "district_id": "110214",
    "label": "Lawe Pasaran Tengku Mbelin",
    "value": "Lawe Pasaran Tengku Mbelin"
  },
  {
    "id": "1102142012",
    "district_id": "110214",
    "label": "Terutung Megahke MBakhu",
    "value": "Terutung Megahke MBakhu"
  },
  {
    "id": "1102142013",
    "district_id": "110214",
    "label": "Kisam Lestari",
    "value": "Kisam Lestari"
  },
  {
    "id": "1102142014",
    "district_id": "110214",
    "label": "Kisam Kute Rambe",
    "value": "Kisam Kute Rambe"
  },
  {
    "id": "1102142015",
    "district_id": "110214",
    "label": "Lawe Polak",
    "value": "Lawe Polak"
  },
  {
    "id": "1102142016",
    "district_id": "110214",
    "label": "Lawes Sumur Sepakat",
    "value": "Lawes Sumur Sepakat"
  },
  {
    "id": "1102142017",
    "district_id": "110214",
    "label": "Setia Baru",
    "value": "Setia Baru"
  },
  {
    "id": "1102142018",
    "district_id": "110214",
    "label": "Teger Miko",
    "value": "Teger Miko"
  },
  {
    "id": "1102152001",
    "district_id": "110215",
    "label": "Salim Pinim I",
    "value": "Salim Pinim I"
  },
  {
    "id": "1102152002",
    "district_id": "110215",
    "label": "Jambur Damar",
    "value": "Jambur Damar"
  },
  {
    "id": "1102152003",
    "district_id": "110215",
    "label": "Alur Langsat",
    "value": "Alur Langsat"
  },
  {
    "id": "1102152004",
    "district_id": "110215",
    "label": "Rambah Sayang",
    "value": "Rambah Sayang"
  },
  {
    "id": "1102152006",
    "district_id": "110215",
    "label": "Rumah Luar",
    "value": "Rumah Luar"
  },
  {
    "id": "1102152007",
    "district_id": "110215",
    "label": "Tenembak Alas",
    "value": "Tenembak Alas"
  },
  {
    "id": "1102152008",
    "district_id": "110215",
    "label": "Lawe Tungkal",
    "value": "Lawe Tungkal"
  },
  {
    "id": "1102152009",
    "district_id": "110215",
    "label": "Salim Pinim II",
    "value": "Salim Pinim II"
  },
  {
    "id": "1102152010",
    "district_id": "110215",
    "label": "Jambukh Pekhmate",
    "value": "Jambukh Pekhmate"
  },
  {
    "id": "1102152011",
    "district_id": "110215",
    "label": "Khutung Mbelang",
    "value": "Khutung Mbelang"
  },
  {
    "id": "1102152012",
    "district_id": "110215",
    "label": "Timang Rasa",
    "value": "Timang Rasa"
  },
  {
    "id": "1102152013",
    "district_id": "110215",
    "label": "Stambul Jaya",
    "value": "Stambul Jaya"
  },
  {
    "id": "1102152014",
    "district_id": "110215",
    "label": "Alur Nangke",
    "value": "Alur Nangke"
  },
  {
    "id": "1102152015",
    "district_id": "110215",
    "label": "Kute Mejile",
    "value": "Kute Mejile"
  },
  {
    "id": "1102162001",
    "district_id": "110216",
    "label": "Bintang Alga Musara",
    "value": "Bintang Alga Musara"
  },
  {
    "id": "1102162002",
    "district_id": "110216",
    "label": "Bintang Bukit Indah",
    "value": "Bintang Bukit Indah"
  },
  {
    "id": "1102162003",
    "district_id": "110216",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1102162004",
    "district_id": "110216",
    "label": "Bun-bun Alas",
    "value": "Bun-bun Alas"
  },
  {
    "id": "1102162005",
    "district_id": "110216",
    "label": "Naga Timbul",
    "value": "Naga Timbul"
  },
  {
    "id": "1102162006",
    "district_id": "110216",
    "label": "Gunung Pak-Pak",
    "value": "Gunung Pak-Pak"
  },
  {
    "id": "1102162007",
    "district_id": "110216",
    "label": "Bukit Meriah",
    "value": "Bukit Meriah"
  },
  {
    "id": "1102162008",
    "district_id": "110216",
    "label": "Permata Musara",
    "value": "Permata Musara"
  },
  {
    "id": "1102162009",
    "district_id": "110216",
    "label": "Laut Tawar",
    "value": "Laut Tawar"
  },
  {
    "id": "1102162010",
    "district_id": "110216",
    "label": "Kane Mende",
    "value": "Kane Mende"
  },
  {
    "id": "1102162011",
    "district_id": "110216",
    "label": "Lawe Sekhakut",
    "value": "Lawe Sekhakut"
  },
  {
    "id": "1102162012",
    "district_id": "110216",
    "label": "Bunbun Indah",
    "value": "Bunbun Indah"
  },
  {
    "id": "1102162013",
    "district_id": "110216",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1102162014",
    "district_id": "110216",
    "label": "Tuah Kekhine",
    "value": "Tuah Kekhine"
  },
  {
    "id": "1102162015",
    "district_id": "110216",
    "label": "Punce Nali",
    "value": "Punce Nali"
  },
  {
    "id": "1102162016",
    "district_id": "110216",
    "label": "Sade Ate",
    "value": "Sade Ate"
  },
  {
    "id": "1102162017",
    "district_id": "110216",
    "label": "Akhih Majile",
    "value": "Akhih Majile"
  },
  {
    "id": "1102162018",
    "district_id": "110216",
    "label": "Ukhat Peseluk",
    "value": "Ukhat Peseluk"
  },
  {
    "id": "1102162019",
    "district_id": "110216",
    "label": "Tunas Mude",
    "value": "Tunas Mude"
  },
  {
    "id": "1102162020",
    "district_id": "110216",
    "label": "Gaye Sendah",
    "value": "Gaye Sendah"
  },
  {
    "id": "1102162021",
    "district_id": "110216",
    "label": "Kute Hakhapen",
    "value": "Kute Hakhapen"
  },
  {
    "id": "1102162022",
    "district_id": "110216",
    "label": "Kompas",
    "value": "Kompas"
  },
  {
    "id": "1102162023",
    "district_id": "110216",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "1103012001",
    "district_id": "110301",
    "label": "Alue Luddin Sa",
    "value": "Alue Luddin Sa"
  },
  {
    "id": "1103012002",
    "district_id": "110301",
    "label": "Alue Luddin Dua",
    "value": "Alue Luddin Dua"
  },
  {
    "id": "1103012003",
    "district_id": "110301",
    "label": "Alue Dalam",
    "value": "Alue Dalam"
  },
  {
    "id": "1103012004",
    "district_id": "110301",
    "label": "Dama Pulo Sa",
    "value": "Dama Pulo Sa"
  },
  {
    "id": "1103012005",
    "district_id": "110301",
    "label": "Dama Pulo Dua",
    "value": "Dama Pulo Dua"
  },
  {
    "id": "1103012006",
    "district_id": "110301",
    "label": "Buket Kulam",
    "value": "Buket Kulam"
  },
  {
    "id": "1103012007",
    "district_id": "110301",
    "label": "Seuneubok Teungoh",
    "value": "Seuneubok Teungoh"
  },
  {
    "id": "1103012008",
    "district_id": "110301",
    "label": "Jungka Gajah",
    "value": "Jungka Gajah"
  },
  {
    "id": "1103012009",
    "district_id": "110301",
    "label": "Seuneubok Simpang",
    "value": "Seuneubok Simpang"
  },
  {
    "id": "1103012010",
    "district_id": "110301",
    "label": "Matang Pineung",
    "value": "Matang Pineung"
  },
  {
    "id": "1103012011",
    "district_id": "110301",
    "label": "Lhok Geulumpang",
    "value": "Lhok Geulumpang"
  },
  {
    "id": "1103012012",
    "district_id": "110301",
    "label": "Blang Buket",
    "value": "Blang Buket"
  },
  {
    "id": "1103012013",
    "district_id": "110301",
    "label": "Meunasah Keutapang",
    "value": "Meunasah Keutapang"
  },
  {
    "id": "1103012014",
    "district_id": "110301",
    "label": "Buket Rumia",
    "value": "Buket Rumia"
  },
  {
    "id": "1103012015",
    "district_id": "110301",
    "label": "Alue Gadeng",
    "value": "Alue Gadeng"
  },
  {
    "id": "1103012016",
    "district_id": "110301",
    "label": "Kapai Baro",
    "value": "Kapai Baro"
  },
  {
    "id": "1103012017",
    "district_id": "110301",
    "label": "Seuneubok Tuha Sa",
    "value": "Seuneubok Tuha Sa"
  },
  {
    "id": "1103012018",
    "district_id": "110301",
    "label": "Seuneubok Tuha Dua",
    "value": "Seuneubok Tuha Dua"
  },
  {
    "id": "1103012019",
    "district_id": "110301",
    "label": "Seuneubok Buloh",
    "value": "Seuneubok Buloh"
  },
  {
    "id": "1103012020",
    "district_id": "110301",
    "label": "Gaseh Sayang",
    "value": "Gaseh Sayang"
  },
  {
    "id": "1103012021",
    "district_id": "110301",
    "label": "Alue Lhok",
    "value": "Alue Lhok"
  },
  {
    "id": "1103012022",
    "district_id": "110301",
    "label": "Trieng Gadeng",
    "value": "Trieng Gadeng"
  },
  {
    "id": "1103012023",
    "district_id": "110301",
    "label": "Grong-Grong",
    "value": "Grong-Grong"
  },
  {
    "id": "1103012024",
    "district_id": "110301",
    "label": "Matang Geuto",
    "value": "Matang Geuto"
  },
  {
    "id": "1103012025",
    "district_id": "110301",
    "label": "Alue Merbo",
    "value": "Alue Merbo"
  },
  {
    "id": "1103012026",
    "district_id": "110301",
    "label": "Buket Raya",
    "value": "Buket Raya"
  },
  {
    "id": "1103012027",
    "district_id": "110301",
    "label": "Gampong Keude",
    "value": "Gampong Keude"
  },
  {
    "id": "1103012028",
    "district_id": "110301",
    "label": "Peukan Idi Cut",
    "value": "Peukan Idi Cut"
  },
  {
    "id": "1103012029",
    "district_id": "110301",
    "label": "Gampong Beunot",
    "value": "Gampong Beunot"
  },
  {
    "id": "1103012030",
    "district_id": "110301",
    "label": "Seuneubok Baroh",
    "value": "Seuneubok Baroh"
  },
  {
    "id": "1103012031",
    "district_id": "110301",
    "label": "Teupin Drum",
    "value": "Teupin Drum"
  },
  {
    "id": "1103012032",
    "district_id": "110301",
    "label": "Kuala Idi Cut",
    "value": "Kuala Idi Cut"
  },
  {
    "id": "1103012034",
    "district_id": "110301",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1103012035",
    "district_id": "110301",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1103012036",
    "district_id": "110301",
    "label": "Bagok Panah Sa",
    "value": "Bagok Panah Sa"
  },
  {
    "id": "1103012037",
    "district_id": "110301",
    "label": "Bagok Panah Dua",
    "value": "Bagok Panah Dua"
  },
  {
    "id": "1103012038",
    "district_id": "110301",
    "label": "Bagok Panah Lhee",
    "value": "Bagok Panah Lhee"
  },
  {
    "id": "1103012039",
    "district_id": "110301",
    "label": "Bagok Panah Peut",
    "value": "Bagok Panah Peut"
  },
  {
    "id": "1103012040",
    "district_id": "110301",
    "label": "Buket Tualang",
    "value": "Buket Tualang"
  },
  {
    "id": "1103012041",
    "district_id": "110301",
    "label": "Keumuneng Sa",
    "value": "Keumuneng Sa"
  },
  {
    "id": "1103012042",
    "district_id": "110301",
    "label": "Keumuneng Dua",
    "value": "Keumuneng Dua"
  },
  {
    "id": "1103012043",
    "district_id": "110301",
    "label": "Keumuneng Lhee",
    "value": "Keumuneng Lhee"
  },
  {
    "id": "1103012044",
    "district_id": "110301",
    "label": "Keumuneng Peut",
    "value": "Keumuneng Peut"
  },
  {
    "id": "1103012045",
    "district_id": "110301",
    "label": "Keumuneng Limong",
    "value": "Keumuneng Limong"
  },
  {
    "id": "1103012046",
    "district_id": "110301",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1103022001",
    "district_id": "110302",
    "label": "Blang Keumahang",
    "value": "Blang Keumahang"
  },
  {
    "id": "1103022002",
    "district_id": "110302",
    "label": "Blang Jambee",
    "value": "Blang Jambee"
  },
  {
    "id": "1103022003",
    "district_id": "110302",
    "label": "Keumuneng",
    "value": "Keumuneng"
  },
  {
    "id": "1103022004",
    "district_id": "110302",
    "label": "Ladang Baro",
    "value": "Ladang Baro"
  },
  {
    "id": "1103022005",
    "district_id": "110302",
    "label": "Buket Panyang",
    "value": "Buket Panyang"
  },
  {
    "id": "1103022006",
    "district_id": "110302",
    "label": "Seuneubok Rambong",
    "value": "Seuneubok Rambong"
  },
  {
    "id": "1103022007",
    "district_id": "110302",
    "label": "Seuneubok Baro",
    "value": "Seuneubok Baro"
  },
  {
    "id": "1103022008",
    "district_id": "110302",
    "label": "Blang Mideun",
    "value": "Blang Mideun"
  },
  {
    "id": "1103022009",
    "district_id": "110302",
    "label": "Paya Bakong",
    "value": "Paya Bakong"
  },
  {
    "id": "1103022010",
    "district_id": "110302",
    "label": "Teupin Raya",
    "value": "Teupin Raya"
  },
  {
    "id": "1103022011",
    "district_id": "110302",
    "label": "Lhok Rambong",
    "value": "Lhok Rambong"
  },
  {
    "id": "1103022012",
    "district_id": "110302",
    "label": "Julok Tunong",
    "value": "Julok Tunong"
  },
  {
    "id": "1103022013",
    "district_id": "110302",
    "label": "Ulee Ateung",
    "value": "Ulee Ateung"
  },
  {
    "id": "1103022014",
    "district_id": "110302",
    "label": "Ulee Blang",
    "value": "Ulee Blang"
  },
  {
    "id": "1103022015",
    "district_id": "110302",
    "label": "Labuhan",
    "value": "Labuhan"
  },
  {
    "id": "1103022016",
    "district_id": "110302",
    "label": "Naleung",
    "value": "Naleung"
  },
  {
    "id": "1103022017",
    "district_id": "110302",
    "label": "Mane Rampak",
    "value": "Mane Rampak"
  },
  {
    "id": "1103022018",
    "district_id": "110302",
    "label": "Matang",
    "value": "Matang"
  },
  {
    "id": "1103022019",
    "district_id": "110302",
    "label": "Blang Uyok",
    "value": "Blang Uyok"
  },
  {
    "id": "1103022020",
    "district_id": "110302",
    "label": "Blang Pauh Sa",
    "value": "Blang Pauh Sa"
  },
  {
    "id": "1103022021",
    "district_id": "110302",
    "label": "Blang Pauh Dua",
    "value": "Blang Pauh Dua"
  },
  {
    "id": "1103022022",
    "district_id": "110302",
    "label": "Ulee Tanoh",
    "value": "Ulee Tanoh"
  },
  {
    "id": "1103022023",
    "district_id": "110302",
    "label": "Keude Kuta Binjei",
    "value": "Keude Kuta Binjei"
  },
  {
    "id": "1103022024",
    "district_id": "110302",
    "label": "Bukit Seroja",
    "value": "Bukit Seroja"
  },
  {
    "id": "1103022025",
    "district_id": "110302",
    "label": "Kuala Geulumpang",
    "value": "Kuala Geulumpang"
  },
  {
    "id": "1103022026",
    "district_id": "110302",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1103022027",
    "district_id": "110302",
    "label": "Tumpok Teungoh",
    "value": "Tumpok Teungoh"
  },
  {
    "id": "1103022028",
    "district_id": "110302",
    "label": "Seumatang",
    "value": "Seumatang"
  },
  {
    "id": "1103022029",
    "district_id": "110302",
    "label": "Ujong Tunong",
    "value": "Ujong Tunong"
  },
  {
    "id": "1103022030",
    "district_id": "110302",
    "label": "Bukit Dindeng",
    "value": "Bukit Dindeng"
  },
  {
    "id": "1103022031",
    "district_id": "110302",
    "label": "Paya Pasi",
    "value": "Paya Pasi"
  },
  {
    "id": "1103022032",
    "district_id": "110302",
    "label": "Alue Cek Doi",
    "value": "Alue Cek Doi"
  },
  {
    "id": "1103022033",
    "district_id": "110302",
    "label": "Blang Gleum",
    "value": "Blang Gleum"
  },
  {
    "id": "1103022034",
    "district_id": "110302",
    "label": "Tanjong Tok Blang",
    "value": "Tanjong Tok Blang"
  },
  {
    "id": "1103022035",
    "district_id": "110302",
    "label": "Buket Makmu",
    "value": "Buket Makmu"
  },
  {
    "id": "1103022036",
    "district_id": "110302",
    "label": "Lhok Seuntang",
    "value": "Lhok Seuntang"
  },
  {
    "id": "1103022037",
    "district_id": "110302",
    "label": "Simpang Lhee",
    "value": "Simpang Lhee"
  },
  {
    "id": "1103032001",
    "district_id": "110303",
    "label": "Gampong Jawa",
    "value": "Gampong Jawa"
  },
  {
    "id": "1103032002",
    "district_id": "110303",
    "label": "Keude Blang",
    "value": "Keude Blang"
  },
  {
    "id": "1103032003",
    "district_id": "110303",
    "label": "Tanoh Anoe",
    "value": "Tanoh Anoe"
  },
  {
    "id": "1103032004",
    "district_id": "110303",
    "label": "Kuala Peudawa Puntong",
    "value": "Kuala Peudawa Puntong"
  },
  {
    "id": "1103032005",
    "district_id": "110303",
    "label": "Gampong Aceh",
    "value": "Gampong Aceh"
  },
  {
    "id": "1103032006",
    "district_id": "110303",
    "label": "Kuta Blang",
    "value": "Kuta Blang"
  },
  {
    "id": "1103032007",
    "district_id": "110303",
    "label": "Gampong Tanjong",
    "value": "Gampong Tanjong"
  },
  {
    "id": "1103032008",
    "district_id": "110303",
    "label": "Blang Geulumpang",
    "value": "Blang Geulumpang"
  },
  {
    "id": "1103032009",
    "district_id": "110303",
    "label": "Meunasah Pu'uk",
    "value": "Meunasah Pu'uk"
  },
  {
    "id": "1103032010",
    "district_id": "110303",
    "label": "Bantayan Timu",
    "value": "Bantayan Timu"
  },
  {
    "id": "1103032011",
    "district_id": "110303",
    "label": "Keutapang Mameh",
    "value": "Keutapang Mameh"
  },
  {
    "id": "1103032012",
    "district_id": "110303",
    "label": "Seuneubok Rambong",
    "value": "Seuneubok Rambong"
  },
  {
    "id": "1103032013",
    "district_id": "110303",
    "label": "Kuala Idi",
    "value": "Kuala Idi"
  },
  {
    "id": "1103032014",
    "district_id": "110303",
    "label": "Keude Aceh",
    "value": "Keude Aceh"
  },
  {
    "id": "1103032023",
    "district_id": "110303",
    "label": "Seuneubok Bacee",
    "value": "Seuneubok Bacee"
  },
  {
    "id": "1103032029",
    "district_id": "110303",
    "label": "Titi Baro",
    "value": "Titi Baro"
  },
  {
    "id": "1103032047",
    "district_id": "110303",
    "label": "Tanjong Kapai",
    "value": "Tanjong Kapai"
  },
  {
    "id": "1103032048",
    "district_id": "110303",
    "label": "Alue Dua Muka O",
    "value": "Alue Dua Muka O"
  },
  {
    "id": "1103032049",
    "district_id": "110303",
    "label": "Alue Dua Muka S",
    "value": "Alue Dua Muka S"
  },
  {
    "id": "1103032050",
    "district_id": "110303",
    "label": "Ulee Blang",
    "value": "Ulee Blang"
  },
  {
    "id": "1103032051",
    "district_id": "110303",
    "label": "Buket Jok",
    "value": "Buket Jok"
  },
  {
    "id": "1103032052",
    "district_id": "110303",
    "label": "Buket Meulinteung",
    "value": "Buket Meulinteung"
  },
  {
    "id": "1103032053",
    "district_id": "110303",
    "label": "Gampong Jalan",
    "value": "Gampong Jalan"
  },
  {
    "id": "1103032054",
    "district_id": "110303",
    "label": "Teupin Batee",
    "value": "Teupin Batee"
  },
  {
    "id": "1103032055",
    "district_id": "110303",
    "label": "Buket Langa",
    "value": "Buket Langa"
  },
  {
    "id": "1103032056",
    "district_id": "110303",
    "label": "Buket Pala",
    "value": "Buket Pala"
  },
  {
    "id": "1103032057",
    "district_id": "110303",
    "label": "Buket Juara",
    "value": "Buket Juara"
  },
  {
    "id": "1103032058",
    "district_id": "110303",
    "label": "Seuneubok Tutong",
    "value": "Seuneubok Tutong"
  },
  {
    "id": "1103032059",
    "district_id": "110303",
    "label": "Seuneubok Tuha",
    "value": "Seuneubok Tuha"
  },
  {
    "id": "1103032060",
    "district_id": "110303",
    "label": "Sampoimah",
    "value": "Sampoimah"
  },
  {
    "id": "1103032061",
    "district_id": "110303",
    "label": "Dama pulo",
    "value": "Dama pulo"
  },
  {
    "id": "1103032062",
    "district_id": "110303",
    "label": "Gureb Blang",
    "value": "Gureb Blang"
  },
  {
    "id": "1103032064",
    "district_id": "110303",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1103032065",
    "district_id": "110303",
    "label": "SeuneubokTeungoh P.Puntong",
    "value": "SeuneubokTeungoh P.Puntong"
  },
  {
    "id": "1103032067",
    "district_id": "110303",
    "label": "Kuta Lawah",
    "value": "Kuta Lawah"
  },
  {
    "id": "1103042001",
    "district_id": "110304",
    "label": "Jambo Labu",
    "value": "Jambo Labu"
  },
  {
    "id": "1103042002",
    "district_id": "110304",
    "label": "Alue Canang",
    "value": "Alue Canang"
  },
  {
    "id": "1103042003",
    "district_id": "110304",
    "label": "Alue Drien",
    "value": "Alue Drien"
  },
  {
    "id": "1103042004",
    "district_id": "110304",
    "label": "Buket Tiga",
    "value": "Buket Tiga"
  },
  {
    "id": "1103042005",
    "district_id": "110304",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1103042006",
    "district_id": "110304",
    "label": "Afeling II Bukit",
    "value": "Afeling II Bukit"
  },
  {
    "id": "1103042007",
    "district_id": "110304",
    "label": "Keumuneng Hulu",
    "value": "Keumuneng Hulu"
  },
  {
    "id": "1103042008",
    "district_id": "110304",
    "label": "Peutow",
    "value": "Peutow"
  },
  {
    "id": "1103042009",
    "district_id": "110304",
    "label": "Alue Buloh",
    "value": "Alue Buloh"
  },
  {
    "id": "1103042010",
    "district_id": "110304",
    "label": "Alue Teh",
    "value": "Alue Teh"
  },
  {
    "id": "1103042011",
    "district_id": "110304",
    "label": "Paya Rambong",
    "value": "Paya Rambong"
  },
  {
    "id": "1103042012",
    "district_id": "110304",
    "label": "Alue Gadeng SA",
    "value": "Alue Gadeng SA"
  },
  {
    "id": "1103042013",
    "district_id": "110304",
    "label": "Paya Tampah",
    "value": "Paya Tampah"
  },
  {
    "id": "1103042014",
    "district_id": "110304",
    "label": "Paya Bili Dua",
    "value": "Paya Bili Dua"
  },
  {
    "id": "1103042015",
    "district_id": "110304",
    "label": "Paya Bili Sa",
    "value": "Paya Bili Sa"
  },
  {
    "id": "1103042016",
    "district_id": "110304",
    "label": "Paya Peulawi",
    "value": "Paya Peulawi"
  },
  {
    "id": "1103042017",
    "district_id": "110304",
    "label": "Keude Birem",
    "value": "Keude Birem"
  },
  {
    "id": "1103042018",
    "district_id": "110304",
    "label": "Bayeun",
    "value": "Bayeun"
  },
  {
    "id": "1103042019",
    "district_id": "110304",
    "label": "Aramiyah",
    "value": "Aramiyah"
  },
  {
    "id": "1103042020",
    "district_id": "110304",
    "label": "Blang Tualang",
    "value": "Blang Tualang"
  },
  {
    "id": "1103042021",
    "district_id": "110304",
    "label": "Buket Seuleumak",
    "value": "Buket Seuleumak"
  },
  {
    "id": "1103042022",
    "district_id": "110304",
    "label": "Birem Rayeuk",
    "value": "Birem Rayeuk"
  },
  {
    "id": "1103042023",
    "district_id": "110304",
    "label": "Alue Gadeng",
    "value": "Alue Gadeng"
  },
  {
    "id": "1103042024",
    "district_id": "110304",
    "label": "Alue Gadeng Dua",
    "value": "Alue Gadeng Dua"
  },
  {
    "id": "1103042025",
    "district_id": "110304",
    "label": "Alue Sentang",
    "value": "Alue Sentang"
  },
  {
    "id": "1103042026",
    "district_id": "110304",
    "label": "Merbou Dua",
    "value": "Merbou Dua"
  },
  {
    "id": "1103042027",
    "district_id": "110304",
    "label": "Alue Nyamoek",
    "value": "Alue Nyamoek"
  },
  {
    "id": "1103052001",
    "district_id": "110305",
    "label": "Lokop",
    "value": "Lokop"
  },
  {
    "id": "1103052002",
    "district_id": "110305",
    "label": "Terujak",
    "value": "Terujak"
  },
  {
    "id": "1103052003",
    "district_id": "110305",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1103052004",
    "district_id": "110305",
    "label": "Leles",
    "value": "Leles"
  },
  {
    "id": "1103052005",
    "district_id": "110305",
    "label": "Ujung Karang",
    "value": "Ujung Karang"
  },
  {
    "id": "1103052006",
    "district_id": "110305",
    "label": "Nalon",
    "value": "Nalon"
  },
  {
    "id": "1103052007",
    "district_id": "110305",
    "label": "Jering",
    "value": "Jering"
  },
  {
    "id": "1103052008",
    "district_id": "110305",
    "label": "Loot",
    "value": "Loot"
  },
  {
    "id": "1103052009",
    "district_id": "110305",
    "label": "Seku Alan",
    "value": "Seku Alan"
  },
  {
    "id": "1103052010",
    "district_id": "110305",
    "label": "Sunti",
    "value": "Sunti"
  },
  {
    "id": "1103052011",
    "district_id": "110305",
    "label": "Umah Taring",
    "value": "Umah Taring"
  },
  {
    "id": "1103052012",
    "district_id": "110305",
    "label": "Bunin",
    "value": "Bunin"
  },
  {
    "id": "1103052013",
    "district_id": "110305",
    "label": "Sembuang",
    "value": "Sembuang"
  },
  {
    "id": "1103052014",
    "district_id": "110305",
    "label": "Seuleumak",
    "value": "Seuleumak"
  },
  {
    "id": "1103052015",
    "district_id": "110305",
    "label": "Mesir",
    "value": "Mesir"
  },
  {
    "id": "1103052016",
    "district_id": "110305",
    "label": "Rampah",
    "value": "Rampah"
  },
  {
    "id": "1103052022",
    "district_id": "110305",
    "label": "Arul Durin",
    "value": "Arul Durin"
  },
  {
    "id": "1103062001",
    "district_id": "110306",
    "label": "Keude Bagok Sa",
    "value": "Keude Bagok Sa"
  },
  {
    "id": "1103062002",
    "district_id": "110306",
    "label": "Keude Bagok Dua",
    "value": "Keude Bagok Dua"
  },
  {
    "id": "1103062003",
    "district_id": "110306",
    "label": "Asan Tanjong",
    "value": "Asan Tanjong"
  },
  {
    "id": "1103062004",
    "district_id": "110306",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "1103062005",
    "district_id": "110306",
    "label": "Matang Seuleumak",
    "value": "Matang Seuleumak"
  },
  {
    "id": "1103062006",
    "district_id": "110306",
    "label": "Teupin Pukat",
    "value": "Teupin Pukat"
  },
  {
    "id": "1103062007",
    "district_id": "110306",
    "label": "Matang Neuheun",
    "value": "Matang Neuheun"
  },
  {
    "id": "1103062008",
    "district_id": "110306",
    "label": "Kuala Bagok",
    "value": "Kuala Bagok"
  },
  {
    "id": "1103062009",
    "district_id": "110306",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1103062010",
    "district_id": "110306",
    "label": "Medang Ara",
    "value": "Medang Ara"
  },
  {
    "id": "1103062011",
    "district_id": "110306",
    "label": "Peulawi",
    "value": "Peulawi"
  },
  {
    "id": "1103062012",
    "district_id": "110306",
    "label": "Baroh Bugeng",
    "value": "Baroh Bugeng"
  },
  {
    "id": "1103062013",
    "district_id": "110306",
    "label": "Seumatang Aron",
    "value": "Seumatang Aron"
  },
  {
    "id": "1103062014",
    "district_id": "110306",
    "label": "Blang Panjou",
    "value": "Blang Panjou"
  },
  {
    "id": "1103062025",
    "district_id": "110306",
    "label": "Pulo U",
    "value": "Pulo U"
  },
  {
    "id": "1103062026",
    "district_id": "110306",
    "label": "Cot Asan",
    "value": "Cot Asan"
  },
  {
    "id": "1103062027",
    "district_id": "110306",
    "label": "Paya Enjee",
    "value": "Paya Enjee"
  },
  {
    "id": "1103062028",
    "district_id": "110306",
    "label": "Seuleumak Muda",
    "value": "Seuleumak Muda"
  },
  {
    "id": "1103062029",
    "district_id": "110306",
    "label": "Matang Panyang",
    "value": "Matang Panyang"
  },
  {
    "id": "1103062030",
    "district_id": "110306",
    "label": "Meunasah Hagu",
    "value": "Meunasah Hagu"
  },
  {
    "id": "1103062031",
    "district_id": "110306",
    "label": "Matang Kunyet",
    "value": "Matang Kunyet"
  },
  {
    "id": "1103062032",
    "district_id": "110306",
    "label": "Buket Panjou",
    "value": "Buket Panjou"
  },
  {
    "id": "1103062033",
    "district_id": "110306",
    "label": "Buket Meurak",
    "value": "Buket Meurak"
  },
  {
    "id": "1103062034",
    "district_id": "110306",
    "label": "Buket Panyang",
    "value": "Buket Panyang"
  },
  {
    "id": "1103062035",
    "district_id": "110306",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1103062036",
    "district_id": "110306",
    "label": "Gampong Mesjid",
    "value": "Gampong Mesjid"
  },
  {
    "id": "1103062037",
    "district_id": "110306",
    "label": "Alue Siwah Serdang",
    "value": "Alue Siwah Serdang"
  },
  {
    "id": "1103062038",
    "district_id": "110306",
    "label": "Gampong Lhee",
    "value": "Gampong Lhee"
  },
  {
    "id": "1103062039",
    "district_id": "110306",
    "label": "Beurandang",
    "value": "Beurandang"
  },
  {
    "id": "1103062040",
    "district_id": "110306",
    "label": "Gampong Jalan",
    "value": "Gampong Jalan"
  },
  {
    "id": "1103062041",
    "district_id": "110306",
    "label": "Seuneubok Rambong",
    "value": "Seuneubok Rambong"
  },
  {
    "id": "1103072001",
    "district_id": "110307",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1103072002",
    "district_id": "110307",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1103072003",
    "district_id": "110307",
    "label": "Keude Peureulak",
    "value": "Keude Peureulak"
  },
  {
    "id": "1103072004",
    "district_id": "110307",
    "label": "Leuge",
    "value": "Leuge"
  },
  {
    "id": "1103072005",
    "district_id": "110307",
    "label": "Uteun Dama",
    "value": "Uteun Dama"
  },
  {
    "id": "1103072006",
    "district_id": "110307",
    "label": "Punti",
    "value": "Punti"
  },
  {
    "id": "1103072007",
    "district_id": "110307",
    "label": "Alue Dua Paya Gajah",
    "value": "Alue Dua Paya Gajah"
  },
  {
    "id": "1103072008",
    "district_id": "110307",
    "label": "Blang Bitra",
    "value": "Blang Bitra"
  },
  {
    "id": "1103072009",
    "district_id": "110307",
    "label": "Kuala Bugak",
    "value": "Kuala Bugak"
  },
  {
    "id": "1103072010",
    "district_id": "110307",
    "label": "Paya Lipah",
    "value": "Paya Lipah"
  },
  {
    "id": "1103072011",
    "district_id": "110307",
    "label": "Seumatang Muda Itam",
    "value": "Seumatang Muda Itam"
  },
  {
    "id": "1103072012",
    "district_id": "110307",
    "label": "Lhok Dalam",
    "value": "Lhok Dalam"
  },
  {
    "id": "1103072013",
    "district_id": "110307",
    "label": "Beusa Meuranoe",
    "value": "Beusa Meuranoe"
  },
  {
    "id": "1103072014",
    "district_id": "110307",
    "label": "Bangka Rimung",
    "value": "Bangka Rimung"
  },
  {
    "id": "1103072015",
    "district_id": "110307",
    "label": "Tanoh Rata",
    "value": "Tanoh Rata"
  },
  {
    "id": "1103072016",
    "district_id": "110307",
    "label": "Bandrong",
    "value": "Bandrong"
  },
  {
    "id": "1103072017",
    "district_id": "110307",
    "label": "Blang Balok",
    "value": "Blang Balok"
  },
  {
    "id": "1103072018",
    "district_id": "110307",
    "label": "Cot Muda Itam",
    "value": "Cot Muda Itam"
  },
  {
    "id": "1103072019",
    "district_id": "110307",
    "label": "Keumuneng",
    "value": "Keumuneng"
  },
  {
    "id": "1103072020",
    "district_id": "110307",
    "label": "Kuala Leuge",
    "value": "Kuala Leuge"
  },
  {
    "id": "1103072021",
    "district_id": "110307",
    "label": "Balee Buya",
    "value": "Balee Buya"
  },
  {
    "id": "1103072022",
    "district_id": "110307",
    "label": "Alue Nibong",
    "value": "Alue Nibong"
  },
  {
    "id": "1103072023",
    "district_id": "110307",
    "label": "Seuneubok Peusangan",
    "value": "Seuneubok Peusangan"
  },
  {
    "id": "1103072024",
    "district_id": "110307",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1103072025",
    "district_id": "110307",
    "label": "Seuneubok Pidie",
    "value": "Seuneubok Pidie"
  },
  {
    "id": "1103072026",
    "district_id": "110307",
    "label": "Dama Tutong",
    "value": "Dama Tutong"
  },
  {
    "id": "1103072027",
    "district_id": "110307",
    "label": "Matang Gleum",
    "value": "Matang Gleum"
  },
  {
    "id": "1103072028",
    "district_id": "110307",
    "label": "Lubok Pempeng",
    "value": "Lubok Pempeng"
  },
  {
    "id": "1103072029",
    "district_id": "110307",
    "label": "Buket Pala",
    "value": "Buket Pala"
  },
  {
    "id": "1103072030",
    "district_id": "110307",
    "label": "Paya Kalui",
    "value": "Paya Kalui"
  },
  {
    "id": "1103072031",
    "district_id": "110307",
    "label": "Cek Mbon",
    "value": "Cek Mbon"
  },
  {
    "id": "1103072032",
    "district_id": "110307",
    "label": "Paya Meuligou",
    "value": "Paya Meuligou"
  },
  {
    "id": "1103072033",
    "district_id": "110307",
    "label": "Blang Simpo",
    "value": "Blang Simpo"
  },
  {
    "id": "1103072034",
    "district_id": "110307",
    "label": "Blang Batee",
    "value": "Blang Batee"
  },
  {
    "id": "1103072035",
    "district_id": "110307",
    "label": "Matang Peulawi",
    "value": "Matang Peulawi"
  },
  {
    "id": "1103072036",
    "district_id": "110307",
    "label": "Cot Geulumpang",
    "value": "Cot Geulumpang"
  },
  {
    "id": "1103072037",
    "district_id": "110307",
    "label": "Alue Rambong",
    "value": "Alue Rambong"
  },
  {
    "id": "1103072038",
    "district_id": "110307",
    "label": "Cot Keh",
    "value": "Cot Keh"
  },
  {
    "id": "1103082001",
    "district_id": "110308",
    "label": "Bayeun",
    "value": "Bayeun"
  },
  {
    "id": "1103082002",
    "district_id": "110308",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1103082003",
    "district_id": "110308",
    "label": "Alue Raya",
    "value": "Alue Raya"
  },
  {
    "id": "1103082004",
    "district_id": "110308",
    "label": "Alue Kumba",
    "value": "Alue Kumba"
  },
  {
    "id": "1103082005",
    "district_id": "110308",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1103082006",
    "district_id": "110308",
    "label": "Alue Seuleumak",
    "value": "Alue Seuleumak"
  },
  {
    "id": "1103082007",
    "district_id": "110308",
    "label": "Sarah Kayee",
    "value": "Sarah Kayee"
  },
  {
    "id": "1103082008",
    "district_id": "110308",
    "label": "Damar Siput",
    "value": "Damar Siput"
  },
  {
    "id": "1103082009",
    "district_id": "110308",
    "label": "Simpang Peut",
    "value": "Simpang Peut"
  },
  {
    "id": "1103082010",
    "district_id": "110308",
    "label": "Simpang Aneuh",
    "value": "Simpang Aneuh"
  },
  {
    "id": "1103082011",
    "district_id": "110308",
    "label": "Alue Punti",
    "value": "Alue Punti"
  },
  {
    "id": "1103082012",
    "district_id": "110308",
    "label": "Alue Kaul",
    "value": "Alue Kaul"
  },
  {
    "id": "1103082013",
    "district_id": "110308",
    "label": "Sarah Teube",
    "value": "Sarah Teube"
  },
  {
    "id": "1103082014",
    "district_id": "110308",
    "label": "Alue Tuwi",
    "value": "Alue Tuwi"
  },
  {
    "id": "1103092001",
    "district_id": "110309",
    "label": "Peulalu",
    "value": "Peulalu"
  },
  {
    "id": "1103092002",
    "district_id": "110309",
    "label": "Nicah Awe",
    "value": "Nicah Awe"
  },
  {
    "id": "1103092003",
    "district_id": "110309",
    "label": "Alue Buloh Sa",
    "value": "Alue Buloh Sa"
  },
  {
    "id": "1103092004",
    "district_id": "110309",
    "label": "Alue Buloh Dua",
    "value": "Alue Buloh Dua"
  },
  {
    "id": "1103092005",
    "district_id": "110309",
    "label": "Teupin Breuh",
    "value": "Teupin Breuh"
  },
  {
    "id": "1103092006",
    "district_id": "110309",
    "label": "Matang Rayeuk",
    "value": "Matang Rayeuk"
  },
  {
    "id": "1103092007",
    "district_id": "110309",
    "label": "Matang Kumbang",
    "value": "Matang Kumbang"
  },
  {
    "id": "1103092008",
    "district_id": "110309",
    "label": "Blang Nie",
    "value": "Blang Nie"
  },
  {
    "id": "1103092010",
    "district_id": "110309",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "1103092011",
    "district_id": "110309",
    "label": "Matang Weng",
    "value": "Matang Weng"
  },
  {
    "id": "1103092012",
    "district_id": "110309",
    "label": "Alue Mulieng",
    "value": "Alue Mulieng"
  },
  {
    "id": "1103092013",
    "district_id": "110309",
    "label": "Pucok Alue Dua",
    "value": "Pucok Alue Dua"
  },
  {
    "id": "1103092014",
    "district_id": "110309",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1103092015",
    "district_id": "110309",
    "label": "Keude Tuha",
    "value": "Keude Tuha"
  },
  {
    "id": "1103092016",
    "district_id": "110309",
    "label": "Kuala Simpang Ulim",
    "value": "Kuala Simpang Ulim"
  },
  {
    "id": "1103092017",
    "district_id": "110309",
    "label": "Lampoh Rayeuk",
    "value": "Lampoh Rayeuk"
  },
  {
    "id": "1103092018",
    "district_id": "110309",
    "label": "Teupin Mamplam",
    "value": "Teupin Mamplam"
  },
  {
    "id": "1103092019",
    "district_id": "110309",
    "label": "Titi Baroh",
    "value": "Titi Baroh"
  },
  {
    "id": "1103092020",
    "district_id": "110309",
    "label": "Pucok Alue Sa",
    "value": "Pucok Alue Sa"
  },
  {
    "id": "1103092021",
    "district_id": "110309",
    "label": "Pucok Alue Barat",
    "value": "Pucok Alue Barat"
  },
  {
    "id": "1103092022",
    "district_id": "110309",
    "label": "Arakundo",
    "value": "Arakundo"
  },
  {
    "id": "1103092023",
    "district_id": "110309",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1103092024",
    "district_id": "110309",
    "label": "Matang Seupeng",
    "value": "Matang Seupeng"
  },
  {
    "id": "1103102001",
    "district_id": "110310",
    "label": "Paya Unou",
    "value": "Paya Unou"
  },
  {
    "id": "1103102002",
    "district_id": "110310",
    "label": "Seuneubok Johan",
    "value": "Seuneubok Johan"
  },
  {
    "id": "1103102003",
    "district_id": "110310",
    "label": "Seuneubok Baro",
    "value": "Seuneubok Baro"
  },
  {
    "id": "1103102004",
    "district_id": "110310",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1103102005",
    "district_id": "110310",
    "label": "Blang Barom",
    "value": "Blang Barom"
  },
  {
    "id": "1103102006",
    "district_id": "110310",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1103102007",
    "district_id": "110310",
    "label": "Seuleumak Muda",
    "value": "Seuleumak Muda"
  },
  {
    "id": "1103102008",
    "district_id": "110310",
    "label": "Bhom Lama",
    "value": "Bhom Lama"
  },
  {
    "id": "1103102009",
    "district_id": "110310",
    "label": "Pasi Puteh",
    "value": "Pasi Puteh"
  },
  {
    "id": "1103102010",
    "district_id": "110310",
    "label": "Alue Dua",
    "value": "Alue Dua"
  },
  {
    "id": "1103102011",
    "district_id": "110310",
    "label": "Buket Pala",
    "value": "Buket Pala"
  },
  {
    "id": "1103102012",
    "district_id": "110310",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1103102013",
    "district_id": "110310",
    "label": "Alue Udep",
    "value": "Alue Udep"
  },
  {
    "id": "1103102014",
    "district_id": "110310",
    "label": "Seumali",
    "value": "Seumali"
  },
  {
    "id": "1103102015",
    "district_id": "110310",
    "label": "Punti Payong",
    "value": "Punti Payong"
  },
  {
    "id": "1103102016",
    "district_id": "110310",
    "label": "Paya Palas",
    "value": "Paya Palas"
  },
  {
    "id": "1103102017",
    "district_id": "110310",
    "label": "Tampak",
    "value": "Tampak"
  },
  {
    "id": "1103102018",
    "district_id": "110310",
    "label": "Kliet",
    "value": "Kliet"
  },
  {
    "id": "1103102019",
    "district_id": "110310",
    "label": "Alue Geunteng",
    "value": "Alue Geunteng"
  },
  {
    "id": "1103102020",
    "district_id": "110310",
    "label": "Pertamina",
    "value": "Pertamina"
  },
  {
    "id": "1103102021",
    "district_id": "110310",
    "label": "Alue Batee",
    "value": "Alue Batee"
  },
  {
    "id": "1103102022",
    "district_id": "110310",
    "label": "Beurandang",
    "value": "Beurandang"
  },
  {
    "id": "1103102024",
    "district_id": "110310",
    "label": "Seumanah Jaya",
    "value": "Seumanah Jaya"
  },
  {
    "id": "1103112001",
    "district_id": "110311",
    "label": "Blang Seunong",
    "value": "Blang Seunong"
  },
  {
    "id": "1103112002",
    "district_id": "110311",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1103112003",
    "district_id": "110311",
    "label": "Pante Labu",
    "value": "Pante Labu"
  },
  {
    "id": "1103112004",
    "district_id": "110311",
    "label": "Pante Rambong",
    "value": "Pante Rambong"
  },
  {
    "id": "1103112005",
    "district_id": "110311",
    "label": "Alue Ie Mirah",
    "value": "Alue Ie Mirah"
  },
  {
    "id": "1103112006",
    "district_id": "110311",
    "label": "Seuneubok Tuha",
    "value": "Seuneubok Tuha"
  },
  {
    "id": "1103112007",
    "district_id": "110311",
    "label": "Seuneubok Saboh",
    "value": "Seuneubok Saboh"
  },
  {
    "id": "1103112008",
    "district_id": "110311",
    "label": "Buket Kareung",
    "value": "Buket Kareung"
  },
  {
    "id": "1103112009",
    "district_id": "110311",
    "label": "Buket Bata",
    "value": "Buket Bata"
  },
  {
    "id": "1103112010",
    "district_id": "110311",
    "label": "Meunasah Tunong",
    "value": "Meunasah Tunong"
  },
  {
    "id": "1103112011",
    "district_id": "110311",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1103112012",
    "district_id": "110311",
    "label": "Keude Baro",
    "value": "Keude Baro"
  },
  {
    "id": "1103112013",
    "district_id": "110311",
    "label": "Matang Perlak",
    "value": "Matang Perlak"
  },
  {
    "id": "1103112014",
    "district_id": "110311",
    "label": "Grong-grong",
    "value": "Grong-grong"
  },
  {
    "id": "1103112015",
    "district_id": "110311",
    "label": "Matang Kruet",
    "value": "Matang Kruet"
  },
  {
    "id": "1103112016",
    "district_id": "110311",
    "label": "Meunasah Leubok",
    "value": "Meunasah Leubok"
  },
  {
    "id": "1103112017",
    "district_id": "110311",
    "label": "Pante Panah",
    "value": "Pante Panah"
  },
  {
    "id": "1103112018",
    "district_id": "110311",
    "label": "Paya Demam Sa",
    "value": "Paya Demam Sa"
  },
  {
    "id": "1103112019",
    "district_id": "110311",
    "label": "Paya Demam Dua",
    "value": "Paya Demam Dua"
  },
  {
    "id": "1103112020",
    "district_id": "110311",
    "label": "Paya Demam Lhee",
    "value": "Paya Demam Lhee"
  },
  {
    "id": "1103112021",
    "district_id": "110311",
    "label": "Matang Pudeng",
    "value": "Matang Pudeng"
  },
  {
    "id": "1103112022",
    "district_id": "110311",
    "label": "Putoh Sa",
    "value": "Putoh Sa"
  },
  {
    "id": "1103112023",
    "district_id": "110311",
    "label": "Putoh Dua",
    "value": "Putoh Dua"
  },
  {
    "id": "1103112024",
    "district_id": "110311",
    "label": "Sah Raja",
    "value": "Sah Raja"
  },
  {
    "id": "1103112025",
    "district_id": "110311",
    "label": "Sijudo",
    "value": "Sijudo"
  },
  {
    "id": "1103122001",
    "district_id": "110312",
    "label": "Madat",
    "value": "Madat"
  },
  {
    "id": "1103122002",
    "district_id": "110312",
    "label": "Matang Keupula Sa",
    "value": "Matang Keupula Sa"
  },
  {
    "id": "1103122003",
    "district_id": "110312",
    "label": "Matang Keupula Dua",
    "value": "Matang Keupula Dua"
  },
  {
    "id": "1103122004",
    "district_id": "110312",
    "label": "Matang Keupula Lhee",
    "value": "Matang Keupula Lhee"
  },
  {
    "id": "1103122005",
    "district_id": "110312",
    "label": "Ulee Ateung",
    "value": "Ulee Ateung"
  },
  {
    "id": "1103122006",
    "district_id": "110312",
    "label": "Blang Ubit",
    "value": "Blang Ubit"
  },
  {
    "id": "1103122007",
    "district_id": "110312",
    "label": "Matang Guru",
    "value": "Matang Guru"
  },
  {
    "id": "1103122008",
    "district_id": "110312",
    "label": "Pantee Bayam",
    "value": "Pantee Bayam"
  },
  {
    "id": "1103122009",
    "district_id": "110312",
    "label": "Meunasah Asan",
    "value": "Meunasah Asan"
  },
  {
    "id": "1103122010",
    "district_id": "110312",
    "label": "Meunasah Tingkeum",
    "value": "Meunasah Tingkeum"
  },
  {
    "id": "1103122011",
    "district_id": "110312",
    "label": "Lueng Dua",
    "value": "Lueng Dua"
  },
  {
    "id": "1103122012",
    "district_id": "110312",
    "label": "Lueng Peut",
    "value": "Lueng Peut"
  },
  {
    "id": "1103122013",
    "district_id": "110312",
    "label": "Paya Demam Peut",
    "value": "Paya Demam Peut"
  },
  {
    "id": "1103122014",
    "district_id": "110312",
    "label": "Abeuk Geulanteu",
    "value": "Abeuk Geulanteu"
  },
  {
    "id": "1103122015",
    "district_id": "110312",
    "label": "Rambong Lop",
    "value": "Rambong Lop"
  },
  {
    "id": "1103122016",
    "district_id": "110312",
    "label": "Tanjong Ara",
    "value": "Tanjong Ara"
  },
  {
    "id": "1103122017",
    "district_id": "110312",
    "label": "Matang Jrok",
    "value": "Matang Jrok"
  },
  {
    "id": "1103122018",
    "district_id": "110312",
    "label": "Paya Naden",
    "value": "Paya Naden"
  },
  {
    "id": "1103122019",
    "district_id": "110312",
    "label": "Tanjong Minjei",
    "value": "Tanjong Minjei"
  },
  {
    "id": "1103122020",
    "district_id": "110312",
    "label": "Pante Merbo",
    "value": "Pante Merbo"
  },
  {
    "id": "1103122021",
    "district_id": "110312",
    "label": "Blang Awe",
    "value": "Blang Awe"
  },
  {
    "id": "1103122022",
    "district_id": "110312",
    "label": "Bintah",
    "value": "Bintah"
  },
  {
    "id": "1103122023",
    "district_id": "110312",
    "label": "Seuneubok Pidie",
    "value": "Seuneubok Pidie"
  },
  {
    "id": "1103122024",
    "district_id": "110312",
    "label": "Blang Andam",
    "value": "Blang Andam"
  },
  {
    "id": "1103122025",
    "district_id": "110312",
    "label": "Matang Nibong",
    "value": "Matang Nibong"
  },
  {
    "id": "1103122026",
    "district_id": "110312",
    "label": "Lueng Sa",
    "value": "Lueng Sa"
  },
  {
    "id": "1103132001",
    "district_id": "110313",
    "label": "Seuneubok Bayu",
    "value": "Seuneubok Bayu"
  },
  {
    "id": "1103132002",
    "district_id": "110313",
    "label": "Alue Ie Itam",
    "value": "Alue Ie Itam"
  },
  {
    "id": "1103132003",
    "district_id": "110313",
    "label": "Blang Nisan",
    "value": "Blang Nisan"
  },
  {
    "id": "1103132004",
    "district_id": "110313",
    "label": "Bandar Baro",
    "value": "Bandar Baro"
  },
  {
    "id": "1103132005",
    "district_id": "110313",
    "label": "Alue Patong",
    "value": "Alue Patong"
  },
  {
    "id": "1103132006",
    "district_id": "110313",
    "label": "Alue Ie Mirah",
    "value": "Alue Ie Mirah"
  },
  {
    "id": "1103132007",
    "district_id": "110313",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1103132008",
    "district_id": "110313",
    "label": "Jambo Balee",
    "value": "Jambo Balee"
  },
  {
    "id": "1103132009",
    "district_id": "110313",
    "label": "Jambo Lubok",
    "value": "Jambo Lubok"
  },
  {
    "id": "1103132010",
    "district_id": "110313",
    "label": "Seuneubok Cina",
    "value": "Seuneubok Cina"
  },
  {
    "id": "1103132011",
    "district_id": "110313",
    "label": "Pelita Sagop Jaya",
    "value": "Pelita Sagop Jaya"
  },
  {
    "id": "1103132012",
    "district_id": "110313",
    "label": "Perkebunan Julok Rayeuk Selatan",
    "value": "Perkebunan Julok Rayeuk Selatan"
  },
  {
    "id": "1103132013",
    "district_id": "110313",
    "label": "Perkebunan Julok Rayeuk Utara",
    "value": "Perkebunan Julok Rayeuk Utara"
  },
  {
    "id": "1103142001",
    "district_id": "110314",
    "label": "Seuneubok Buloh",
    "value": "Seuneubok Buloh"
  },
  {
    "id": "1103142002",
    "district_id": "110314",
    "label": "Seuneubok Baro",
    "value": "Seuneubok Baro"
  },
  {
    "id": "1103142003",
    "district_id": "110314",
    "label": "Seuneubok Meureudu",
    "value": "Seuneubok Meureudu"
  },
  {
    "id": "1103142004",
    "district_id": "110314",
    "label": "Seuneubok Teupin Panah",
    "value": "Seuneubok Teupin Panah"
  },
  {
    "id": "1103142005",
    "district_id": "110314",
    "label": "Teupin Panah",
    "value": "Teupin Panah"
  },
  {
    "id": "1103142006",
    "district_id": "110314",
    "label": "Gampong Keumuneng",
    "value": "Gampong Keumuneng"
  },
  {
    "id": "1103142007",
    "district_id": "110314",
    "label": "Keude Keumuneng",
    "value": "Keude Keumuneng"
  },
  {
    "id": "1103142008",
    "district_id": "110314",
    "label": "Keumuneng Lhok",
    "value": "Keumuneng Lhok"
  },
  {
    "id": "1103142009",
    "district_id": "110314",
    "label": "Paya Awee",
    "value": "Paya Awee"
  },
  {
    "id": "1103142010",
    "district_id": "110314",
    "label": "Paya Gaboh",
    "value": "Paya Gaboh"
  },
  {
    "id": "1103142011",
    "district_id": "110314",
    "label": "Padang Kasah",
    "value": "Padang Kasah"
  },
  {
    "id": "1103142012",
    "district_id": "110314",
    "label": "Buket Teukuh",
    "value": "Buket Teukuh"
  },
  {
    "id": "1103142013",
    "district_id": "110314",
    "label": "Buket Pu'uk",
    "value": "Buket Pu'uk"
  },
  {
    "id": "1103142014",
    "district_id": "110314",
    "label": "Blang Minjei",
    "value": "Blang Minjei"
  },
  {
    "id": "1103142015",
    "district_id": "110314",
    "label": "Alue Kumbang A",
    "value": "Alue Kumbang A"
  },
  {
    "id": "1103142016",
    "district_id": "110314",
    "label": "Alue Kumbang M",
    "value": "Alue Kumbang M"
  },
  {
    "id": "1103142017",
    "district_id": "110314",
    "label": "Alue Lhok",
    "value": "Alue Lhok"
  },
  {
    "id": "1103142018",
    "district_id": "110314",
    "label": "Seuneubok Buya",
    "value": "Seuneubok Buya"
  },
  {
    "id": "1103142019",
    "district_id": "110314",
    "label": "Seuneubok Drien",
    "value": "Seuneubok Drien"
  },
  {
    "id": "1103142020",
    "district_id": "110314",
    "label": "Bantayan Barat",
    "value": "Bantayan Barat"
  },
  {
    "id": "1103142021",
    "district_id": "110314",
    "label": "Blang Guci",
    "value": "Blang Guci"
  },
  {
    "id": "1103142022",
    "district_id": "110314",
    "label": "Buket Rumiya",
    "value": "Buket Rumiya"
  },
  {
    "id": "1103142023",
    "district_id": "110314",
    "label": "Seuneubok Punti",
    "value": "Seuneubok Punti"
  },
  {
    "id": "1103142024",
    "district_id": "110314",
    "label": "Seuneubok Jalan",
    "value": "Seuneubok Jalan"
  },
  {
    "id": "1103142025",
    "district_id": "110314",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1103152001",
    "district_id": "110315",
    "label": "Jalan Dua",
    "value": "Jalan Dua"
  },
  {
    "id": "1103152002",
    "district_id": "110315",
    "label": "Jambo Reuhat",
    "value": "Jambo Reuhat"
  },
  {
    "id": "1103152003",
    "district_id": "110315",
    "label": "Seuneubok Benteng",
    "value": "Seuneubok Benteng"
  },
  {
    "id": "1103152004",
    "district_id": "110315",
    "label": "Seuneubok Pango",
    "value": "Seuneubok Pango"
  },
  {
    "id": "1103152005",
    "district_id": "110315",
    "label": "Seuneubok Simpang",
    "value": "Seuneubok Simpang"
  },
  {
    "id": "1103152006",
    "district_id": "110315",
    "label": "Seuneubok Kandang",
    "value": "Seuneubok Kandang"
  },
  {
    "id": "1103152007",
    "district_id": "110315",
    "label": "Ulee Jalan",
    "value": "Ulee Jalan"
  },
  {
    "id": "1103152008",
    "district_id": "110315",
    "label": "Uram Jalan",
    "value": "Uram Jalan"
  },
  {
    "id": "1103152009",
    "district_id": "110315",
    "label": "Seuneubok Bayu",
    "value": "Seuneubok Bayu"
  },
  {
    "id": "1103152010",
    "district_id": "110315",
    "label": "Blang Rambong",
    "value": "Blang Rambong"
  },
  {
    "id": "1103152011",
    "district_id": "110315",
    "label": "Buket Drien",
    "value": "Buket Drien"
  },
  {
    "id": "1103152012",
    "district_id": "110315",
    "label": "Panton Rayeuk A",
    "value": "Panton Rayeuk A"
  },
  {
    "id": "1103152013",
    "district_id": "110315",
    "label": "Panton Rayeuk B",
    "value": "Panton Rayeuk B"
  },
  {
    "id": "1103152014",
    "district_id": "110315",
    "label": "Panton Rayeuk M",
    "value": "Panton Rayeuk M"
  },
  {
    "id": "1103152015",
    "district_id": "110315",
    "label": "Panton Rayeuk T",
    "value": "Panton Rayeuk T"
  },
  {
    "id": "1103152016",
    "district_id": "110315",
    "label": "Paya Laman",
    "value": "Paya Laman"
  },
  {
    "id": "1103162001",
    "district_id": "110316",
    "label": "Gampong Keude",
    "value": "Gampong Keude"
  },
  {
    "id": "1103162002",
    "district_id": "110316",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1103162003",
    "district_id": "110316",
    "label": "Kuta Baro",
    "value": "Kuta Baro"
  },
  {
    "id": "1103162004",
    "district_id": "110316",
    "label": "Alue Batee",
    "value": "Alue Batee"
  },
  {
    "id": "1103162005",
    "district_id": "110316",
    "label": "Matang Rayeuk",
    "value": "Matang Rayeuk"
  },
  {
    "id": "1103162006",
    "district_id": "110316",
    "label": "Paya Bili Dua",
    "value": "Paya Bili Dua"
  },
  {
    "id": "1103162007",
    "district_id": "110316",
    "label": "Alue Ie Itam",
    "value": "Alue Ie Itam"
  },
  {
    "id": "1103162008",
    "district_id": "110316",
    "label": "Asan Rampak",
    "value": "Asan Rampak"
  },
  {
    "id": "1103162009",
    "district_id": "110316",
    "label": "Gampong Kuala",
    "value": "Gampong Kuala"
  },
  {
    "id": "1103162010",
    "district_id": "110316",
    "label": "Paya Dua",
    "value": "Paya Dua"
  },
  {
    "id": "1103162011",
    "district_id": "110316",
    "label": "Seuneubok Teungoh",
    "value": "Seuneubok Teungoh"
  },
  {
    "id": "1103162012",
    "district_id": "110316",
    "label": "Buket Kuta",
    "value": "Buket Kuta"
  },
  {
    "id": "1103162013",
    "district_id": "110316",
    "label": "Sama Dua",
    "value": "Sama Dua"
  },
  {
    "id": "1103162014",
    "district_id": "110316",
    "label": "Blang Kuta",
    "value": "Blang Kuta"
  },
  {
    "id": "1103162015",
    "district_id": "110316",
    "label": "Blang Buket",
    "value": "Blang Buket"
  },
  {
    "id": "1103162016",
    "district_id": "110316",
    "label": "Seuneubok Peunteut",
    "value": "Seuneubok Peunteut"
  },
  {
    "id": "1103162017",
    "district_id": "110316",
    "label": "Paya Bili Sa",
    "value": "Paya Bili Sa"
  },
  {
    "id": "1103172001",
    "district_id": "110317",
    "label": "Babah Krueng",
    "value": "Babah Krueng"
  },
  {
    "id": "1103172002",
    "district_id": "110317",
    "label": "Kruet Lintang",
    "value": "Kruet Lintang"
  },
  {
    "id": "1103172003",
    "district_id": "110317",
    "label": "Jengki",
    "value": "Jengki"
  },
  {
    "id": "1103172004",
    "district_id": "110317",
    "label": "Buket Meriam",
    "value": "Buket Meriam"
  },
  {
    "id": "1103172005",
    "district_id": "110317",
    "label": "Alue Gureb",
    "value": "Alue Gureb"
  },
  {
    "id": "1103172006",
    "district_id": "110317",
    "label": "Seuneubok Teupin",
    "value": "Seuneubok Teupin"
  },
  {
    "id": "1103172007",
    "district_id": "110317",
    "label": "Seuneubok Teungoh",
    "value": "Seuneubok Teungoh"
  },
  {
    "id": "1103172008",
    "district_id": "110317",
    "label": "Alue Bugeng",
    "value": "Alue Bugeng"
  },
  {
    "id": "1103172009",
    "district_id": "110317",
    "label": "Seuneubok Punti",
    "value": "Seuneubok Punti"
  },
  {
    "id": "1103172010",
    "district_id": "110317",
    "label": "Seumatang Keude",
    "value": "Seumatang Keude"
  },
  {
    "id": "1103172011",
    "district_id": "110317",
    "label": "Seuneubok Jalan",
    "value": "Seuneubok Jalan"
  },
  {
    "id": "1103172012",
    "district_id": "110317",
    "label": "Seuneubok Paya",
    "value": "Seuneubok Paya"
  },
  {
    "id": "1103172013",
    "district_id": "110317",
    "label": "Alue Tho",
    "value": "Alue Tho"
  },
  {
    "id": "1103172014",
    "district_id": "110317",
    "label": "Seuneubok Rawang",
    "value": "Seuneubok Rawang"
  },
  {
    "id": "1103172015",
    "district_id": "110317",
    "label": "Alue Bu Alue Nireh",
    "value": "Alue Bu Alue Nireh"
  },
  {
    "id": "1103172016",
    "district_id": "110317",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1103172017",
    "district_id": "110317",
    "label": "Seuneubok Lapang",
    "value": "Seuneubok Lapang"
  },
  {
    "id": "1103172018",
    "district_id": "110317",
    "label": "Gelanggang Meurak",
    "value": "Gelanggang Meurak"
  },
  {
    "id": "1103172019",
    "district_id": "110317",
    "label": "Alue Bu Alue Lhok",
    "value": "Alue Bu Alue Lhok"
  },
  {
    "id": "1103172020",
    "district_id": "110317",
    "label": "Tualang Pateng",
    "value": "Tualang Pateng"
  },
  {
    "id": "1103182001",
    "district_id": "110318",
    "label": "Beusa Seberang",
    "value": "Beusa Seberang"
  },
  {
    "id": "1103182002",
    "district_id": "110318",
    "label": "Mon Geudong",
    "value": "Mon Geudong"
  },
  {
    "id": "1103182003",
    "district_id": "110318",
    "label": "Paya Seungat",
    "value": "Paya Seungat"
  },
  {
    "id": "1103182004",
    "district_id": "110318",
    "label": "Teumpeun",
    "value": "Teumpeun"
  },
  {
    "id": "1103182005",
    "district_id": "110318",
    "label": "Alue Bu Tuha",
    "value": "Alue Bu Tuha"
  },
  {
    "id": "1103182006",
    "district_id": "110318",
    "label": "Alue Bu Jalan",
    "value": "Alue Bu Jalan"
  },
  {
    "id": "1103182007",
    "district_id": "110318",
    "label": "Tanjong Tualang",
    "value": "Tanjong Tualang"
  },
  {
    "id": "1103182008",
    "district_id": "110318",
    "label": "Beringen",
    "value": "Beringen"
  },
  {
    "id": "1103182009",
    "district_id": "110318",
    "label": "Kebon Tempeun",
    "value": "Kebon Tempeun"
  },
  {
    "id": "1103182010",
    "district_id": "110318",
    "label": "Paya Gajah",
    "value": "Paya Gajah"
  },
  {
    "id": "1103182011",
    "district_id": "110318",
    "label": "Kabu",
    "value": "Kabu"
  },
  {
    "id": "1103182012",
    "district_id": "110318",
    "label": "Paya Biek",
    "value": "Paya Biek"
  },
  {
    "id": "1103182013",
    "district_id": "110318",
    "label": "Beusa Baroh",
    "value": "Beusa Baroh"
  },
  {
    "id": "1103182014",
    "district_id": "110318",
    "label": "Alue Bu Jalan Baroh",
    "value": "Alue Bu Jalan Baroh"
  },
  {
    "id": "1103182015",
    "district_id": "110318",
    "label": "Alue Bu Tunong",
    "value": "Alue Bu Tunong"
  },
  {
    "id": "1103192001",
    "district_id": "110319",
    "label": "Alue Rangan",
    "value": "Alue Rangan"
  },
  {
    "id": "1103192002",
    "district_id": "110319",
    "label": "Geulumpang Payong",
    "value": "Geulumpang Payong"
  },
  {
    "id": "1103192003",
    "district_id": "110319",
    "label": "Labuhan Keudee",
    "value": "Labuhan Keudee"
  },
  {
    "id": "1103192004",
    "district_id": "110319",
    "label": "Buket Seulamat",
    "value": "Buket Seulamat"
  },
  {
    "id": "1103192005",
    "district_id": "110319",
    "label": "Buket Drien",
    "value": "Buket Drien"
  },
  {
    "id": "1103192006",
    "district_id": "110319",
    "label": "Alue Itam",
    "value": "Alue Itam"
  },
  {
    "id": "1103192007",
    "district_id": "110319",
    "label": "Paya Keutapang",
    "value": "Paya Keutapang"
  },
  {
    "id": "1103192008",
    "district_id": "110319",
    "label": "Gajah Mentah",
    "value": "Gajah Mentah"
  },
  {
    "id": "1103192009",
    "district_id": "110319",
    "label": "Seuneubok Pase",
    "value": "Seuneubok Pase"
  },
  {
    "id": "1103192010",
    "district_id": "110319",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1103192011",
    "district_id": "110319",
    "label": "Krueng Lingka",
    "value": "Krueng Lingka"
  },
  {
    "id": "1103192012",
    "district_id": "110319",
    "label": "Sungai Simpang",
    "value": "Sungai Simpang"
  },
  {
    "id": "1103192013",
    "district_id": "110319",
    "label": "Kuala Parek",
    "value": "Kuala Parek"
  },
  {
    "id": "1103202001",
    "district_id": "110320",
    "label": "Simpang Jernih",
    "value": "Simpang Jernih"
  },
  {
    "id": "1103202002",
    "district_id": "110320",
    "label": "Batu Sumbang",
    "value": "Batu Sumbang"
  },
  {
    "id": "1103202003",
    "district_id": "110320",
    "label": "Pante Kera",
    "value": "Pante Kera"
  },
  {
    "id": "1103202004",
    "district_id": "110320",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1103202005",
    "district_id": "110320",
    "label": "Melidi",
    "value": "Melidi"
  },
  {
    "id": "1103202006",
    "district_id": "110320",
    "label": "Tampur Paloh",
    "value": "Tampur Paloh"
  },
  {
    "id": "1103202007",
    "district_id": "110320",
    "label": "Tampur Boor",
    "value": "Tampur Boor"
  },
  {
    "id": "1103202008",
    "district_id": "110320",
    "label": "HTI Rantau Naro",
    "value": "HTI Rantau Naro"
  },
  {
    "id": "1103212001",
    "district_id": "110321",
    "label": "Meudang Ara",
    "value": "Meudang Ara"
  },
  {
    "id": "1103212002",
    "district_id": "110321",
    "label": "Gunong Putoh",
    "value": "Gunong Putoh"
  },
  {
    "id": "1103212003",
    "district_id": "110321",
    "label": "Meunasah Aroen",
    "value": "Meunasah Aroen"
  },
  {
    "id": "1103212004",
    "district_id": "110321",
    "label": "Keude Dua",
    "value": "Keude Dua"
  },
  {
    "id": "1103212005",
    "district_id": "110321",
    "label": "Seuneubok Lapang",
    "value": "Seuneubok Lapang"
  },
  {
    "id": "1103212006",
    "district_id": "110321",
    "label": "Alue Jangat",
    "value": "Alue Jangat"
  },
  {
    "id": "1103212007",
    "district_id": "110321",
    "label": "Buket Peulawi",
    "value": "Buket Peulawi"
  },
  {
    "id": "1103212008",
    "district_id": "110321",
    "label": "Lhok Panjoe",
    "value": "Lhok Panjoe"
  },
  {
    "id": "1103212009",
    "district_id": "110321",
    "label": "Seuneubok Aceh Baro",
    "value": "Seuneubok Aceh Baro"
  },
  {
    "id": "1103212010",
    "district_id": "110321",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1103212011",
    "district_id": "110321",
    "label": "Lhok Dalam",
    "value": "Lhok Dalam"
  },
  {
    "id": "1103212012",
    "district_id": "110321",
    "label": "Lhok Leumak",
    "value": "Lhok Leumak"
  },
  {
    "id": "1103212013",
    "district_id": "110321",
    "label": "Seuneubok Kulam",
    "value": "Seuneubok Kulam"
  },
  {
    "id": "1103212014",
    "district_id": "110321",
    "label": "Panton Meurebo",
    "value": "Panton Meurebo"
  },
  {
    "id": "1103212015",
    "district_id": "110321",
    "label": "Seuneubok Teungoh",
    "value": "Seuneubok Teungoh"
  },
  {
    "id": "1103212016",
    "district_id": "110321",
    "label": "Lhok Meureu",
    "value": "Lhok Meureu"
  },
  {
    "id": "1103222001",
    "district_id": "110322",
    "label": "Tunong Bugeng",
    "value": "Tunong Bugeng"
  },
  {
    "id": "1103222002",
    "district_id": "110322",
    "label": "Cempeudak",
    "value": "Cempeudak"
  },
  {
    "id": "1103222003",
    "district_id": "110322",
    "label": "Buket Tufah",
    "value": "Buket Tufah"
  },
  {
    "id": "1103222004",
    "district_id": "110322",
    "label": "Tunong Ulee Gajah",
    "value": "Tunong Ulee Gajah"
  },
  {
    "id": "1103222005",
    "district_id": "110322",
    "label": "Keudondong",
    "value": "Keudondong"
  },
  {
    "id": "1103222006",
    "district_id": "110322",
    "label": "Seuneubok Panton",
    "value": "Seuneubok Panton"
  },
  {
    "id": "1103222007",
    "district_id": "110322",
    "label": "Buket Teumpeun",
    "value": "Buket Teumpeun"
  },
  {
    "id": "1103222008",
    "district_id": "110322",
    "label": "Keude Blang",
    "value": "Keude Blang"
  },
  {
    "id": "1103222009",
    "district_id": "110322",
    "label": "Paya Kruep",
    "value": "Paya Kruep"
  },
  {
    "id": "1103222010",
    "district_id": "110322",
    "label": "Tunong Paya Kruep",
    "value": "Tunong Paya Kruep"
  },
  {
    "id": "1103222011",
    "district_id": "110322",
    "label": "Alue Siwah",
    "value": "Alue Siwah"
  },
  {
    "id": "1103232001",
    "district_id": "110323",
    "label": "Keutapang Dua",
    "value": "Keutapang Dua"
  },
  {
    "id": "1103232002",
    "district_id": "110323",
    "label": "Matang Rayeuk PP",
    "value": "Matang Rayeuk PP"
  },
  {
    "id": "1103232003",
    "district_id": "110323",
    "label": "Meunasah Jeumpa",
    "value": "Meunasah Jeumpa"
  },
  {
    "id": "1103232004",
    "district_id": "110323",
    "label": "Lhok Asahan",
    "value": "Lhok Asahan"
  },
  {
    "id": "1103232005",
    "district_id": "110323",
    "label": "Tualang Dalam",
    "value": "Tualang Dalam"
  },
  {
    "id": "1103232006",
    "district_id": "110323",
    "label": "Seuneubok Kuyun",
    "value": "Seuneubok Kuyun"
  },
  {
    "id": "1103232007",
    "district_id": "110323",
    "label": "Ulee Glee",
    "value": "Ulee Glee"
  },
  {
    "id": "1103232008",
    "district_id": "110323",
    "label": "Seuneubok Barat",
    "value": "Seuneubok Barat"
  },
  {
    "id": "1103232009",
    "district_id": "110323",
    "label": "Seuneubok Teungoh",
    "value": "Seuneubok Teungoh"
  },
  {
    "id": "1103232010",
    "district_id": "110323",
    "label": "Seuneubok Timu",
    "value": "Seuneubok Timu"
  },
  {
    "id": "1103232011",
    "district_id": "110323",
    "label": "Matang Bungong",
    "value": "Matang Bungong"
  },
  {
    "id": "1103232012",
    "district_id": "110323",
    "label": "Matang Rayeuk SMK",
    "value": "Matang Rayeuk SMK"
  },
  {
    "id": "1103232013",
    "district_id": "110323",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1103242001",
    "district_id": "110324",
    "label": "Arul Pinang",
    "value": "Arul Pinang"
  },
  {
    "id": "1103242002",
    "district_id": "110324",
    "label": "Peunaron Baru",
    "value": "Peunaron Baru"
  },
  {
    "id": "1103242003",
    "district_id": "110324",
    "label": "Peunaron Lama",
    "value": "Peunaron Lama"
  },
  {
    "id": "1103242004",
    "district_id": "110324",
    "label": "Bukit Tiga",
    "value": "Bukit Tiga"
  },
  {
    "id": "1103242006",
    "district_id": "110324",
    "label": "Sri Mulya",
    "value": "Sri Mulya"
  },
  {
    "id": "1104012003",
    "district_id": "110401",
    "label": "Delung Sekinel",
    "value": "Delung Sekinel"
  },
  {
    "id": "1104012005",
    "district_id": "110401",
    "label": "Gelampang Gading",
    "value": "Gelampang Gading"
  },
  {
    "id": "1104012006",
    "district_id": "110401",
    "label": "Gewat",
    "value": "Gewat"
  },
  {
    "id": "1104012008",
    "district_id": "110401",
    "label": "Jamat",
    "value": "Jamat"
  },
  {
    "id": "1104012009",
    "district_id": "110401",
    "label": "Kemerleng",
    "value": "Kemerleng"
  },
  {
    "id": "1104012010",
    "district_id": "110401",
    "label": "Kute Baru",
    "value": "Kute Baru"
  },
  {
    "id": "1104012011",
    "district_id": "110401",
    "label": "Kute Keramil",
    "value": "Kute Keramil"
  },
  {
    "id": "1104012012",
    "district_id": "110401",
    "label": "Kute Rayang",
    "value": "Kute Rayang"
  },
  {
    "id": "1104012013",
    "district_id": "110401",
    "label": "Kute Riyem",
    "value": "Kute Riyem"
  },
  {
    "id": "1104012014",
    "district_id": "110401",
    "label": "Kute Robel",
    "value": "Kute Robel"
  },
  {
    "id": "1104012015",
    "district_id": "110401",
    "label": "Linge",
    "value": "Linge"
  },
  {
    "id": "1104012016",
    "district_id": "110401",
    "label": "Lumut",
    "value": "Lumut"
  },
  {
    "id": "1104012018",
    "district_id": "110401",
    "label": "Mungkur",
    "value": "Mungkur"
  },
  {
    "id": "1104012019",
    "district_id": "110401",
    "label": "Owaq",
    "value": "Owaq"
  },
  {
    "id": "1104012020",
    "district_id": "110401",
    "label": "Pantan Nangka",
    "value": "Pantan Nangka"
  },
  {
    "id": "1104012021",
    "district_id": "110401",
    "label": "Penarun",
    "value": "Penarun"
  },
  {
    "id": "1104012022",
    "district_id": "110401",
    "label": "Simpang Tige Uning",
    "value": "Simpang Tige Uning"
  },
  {
    "id": "1104012023",
    "district_id": "110401",
    "label": "Umang",
    "value": "Umang"
  },
  {
    "id": "1104012024",
    "district_id": "110401",
    "label": "Despot Linge",
    "value": "Despot Linge"
  },
  {
    "id": "1104012025",
    "district_id": "110401",
    "label": "Gemboyah",
    "value": "Gemboyah"
  },
  {
    "id": "1104012031",
    "district_id": "110401",
    "label": "Arul Item",
    "value": "Arul Item"
  },
  {
    "id": "1104012032",
    "district_id": "110401",
    "label": "Ise-Ise",
    "value": "Ise-Ise"
  },
  {
    "id": "1104012033",
    "district_id": "110401",
    "label": "Kute Reje",
    "value": "Kute Reje"
  },
  {
    "id": "1104012034",
    "district_id": "110401",
    "label": "Reje Payung",
    "value": "Reje Payung"
  },
  {
    "id": "1104012035",
    "district_id": "110401",
    "label": "Pantan Reduk",
    "value": "Pantan Reduk"
  },
  {
    "id": "1104012036",
    "district_id": "110401",
    "label": "Antara",
    "value": "Antara"
  },
  {
    "id": "1104022001",
    "district_id": "110402",
    "label": "Arul Gele",
    "value": "Arul Gele"
  },
  {
    "id": "1104022002",
    "district_id": "110402",
    "label": "Arul Kumer",
    "value": "Arul Kumer"
  },
  {
    "id": "1104022003",
    "district_id": "110402",
    "label": "Burni Bius",
    "value": "Burni Bius"
  },
  {
    "id": "1104022004",
    "district_id": "110402",
    "label": "Genting Gerbang",
    "value": "Genting Gerbang"
  },
  {
    "id": "1104022005",
    "district_id": "110402",
    "label": "Gunung Singit",
    "value": "Gunung Singit"
  },
  {
    "id": "1104022007",
    "district_id": "110402",
    "label": "Pepayungan Angkup",
    "value": "Pepayungan Angkup"
  },
  {
    "id": "1104022008",
    "district_id": "110402",
    "label": "Remesen",
    "value": "Remesen"
  },
  {
    "id": "1104022009",
    "district_id": "110402",
    "label": "Rutih",
    "value": "Rutih"
  },
  {
    "id": "1104022010",
    "district_id": "110402",
    "label": "Semelit Mutiara",
    "value": "Semelit Mutiara"
  },
  {
    "id": "1104022012",
    "district_id": "110402",
    "label": "Wih Porak",
    "value": "Wih Porak"
  },
  {
    "id": "1104022013",
    "district_id": "110402",
    "label": "Wih Bakong",
    "value": "Wih Bakong"
  },
  {
    "id": "1104022014",
    "district_id": "110402",
    "label": "Wihni Durin",
    "value": "Wihni Durin"
  },
  {
    "id": "1104022016",
    "district_id": "110402",
    "label": "Arul Putih",
    "value": "Arul Putih"
  },
  {
    "id": "1104022017",
    "district_id": "110402",
    "label": "Arul Relem",
    "value": "Arul Relem"
  },
  {
    "id": "1104022018",
    "district_id": "110402",
    "label": "Burni Bius Baru",
    "value": "Burni Bius Baru"
  },
  {
    "id": "1104022019",
    "district_id": "110402",
    "label": "Jerata",
    "value": "Jerata"
  },
  {
    "id": "1104022022",
    "district_id": "110402",
    "label": "Paya Beke",
    "value": "Paya Beke"
  },
  {
    "id": "1104022023",
    "district_id": "110402",
    "label": "Paya Pelu",
    "value": "Paya Pelu"
  },
  {
    "id": "1104022026",
    "district_id": "110402",
    "label": "Rebe Gedung",
    "value": "Rebe Gedung"
  },
  {
    "id": "1104022027",
    "district_id": "110402",
    "label": "Reremal",
    "value": "Reremal"
  },
  {
    "id": "1104022029",
    "district_id": "110402",
    "label": "Senehen",
    "value": "Senehen"
  },
  {
    "id": "1104022030",
    "district_id": "110402",
    "label": "Simpang Kemili",
    "value": "Simpang Kemili"
  },
  {
    "id": "1104022031",
    "district_id": "110402",
    "label": "Tenebuk Kampung",
    "value": "Tenebuk Kampung"
  },
  {
    "id": "1104022032",
    "district_id": "110402",
    "label": "Terang Engon",
    "value": "Terang Engon"
  },
  {
    "id": "1104022033",
    "district_id": "110402",
    "label": "Wih Pesam",
    "value": "Wih Pesam"
  },
  {
    "id": "1104022034",
    "district_id": "110402",
    "label": "Bius Utama",
    "value": "Bius Utama"
  },
  {
    "id": "1104022035",
    "district_id": "110402",
    "label": "Meker Indah",
    "value": "Meker Indah"
  },
  {
    "id": "1104022036",
    "district_id": "110402",
    "label": "Mulie Jadi",
    "value": "Mulie Jadi"
  },
  {
    "id": "1104022037",
    "district_id": "110402",
    "label": "Wih Bersih",
    "value": "Wih Bersih"
  },
  {
    "id": "1104022038",
    "district_id": "110402",
    "label": "Wih Sagi Indah",
    "value": "Wih Sagi Indah"
  },
  {
    "id": "1104022039",
    "district_id": "110402",
    "label": "Arul Kumer Barat",
    "value": "Arul Kumer Barat"
  },
  {
    "id": "1104022040",
    "district_id": "110402",
    "label": "Arul Kumer Timur",
    "value": "Arul Kumer Timur"
  },
  {
    "id": "1104022041",
    "district_id": "110402",
    "label": "Arul Kumer Selatan",
    "value": "Arul Kumer Selatan"
  },
  {
    "id": "1104032001",
    "district_id": "110403",
    "label": "Blang Kolak I",
    "value": "Blang Kolak I"
  },
  {
    "id": "1104032002",
    "district_id": "110403",
    "label": "Bebesan",
    "value": "Bebesan"
  },
  {
    "id": "1104032003",
    "district_id": "110403",
    "label": "Mongal",
    "value": "Mongal"
  },
  {
    "id": "1104032004",
    "district_id": "110403",
    "label": "Daling",
    "value": "Daling"
  },
  {
    "id": "1104032005",
    "district_id": "110403",
    "label": "Tensaran",
    "value": "Tensaran"
  },
  {
    "id": "1104032006",
    "district_id": "110403",
    "label": "Lelabu",
    "value": "Lelabu"
  },
  {
    "id": "1104032007",
    "district_id": "110403",
    "label": "Blang Gele",
    "value": "Blang Gele"
  },
  {
    "id": "1104032008",
    "district_id": "110403",
    "label": "Kemili",
    "value": "Kemili"
  },
  {
    "id": "1104032009",
    "district_id": "110403",
    "label": "Tan Saril",
    "value": "Tan Saril"
  },
  {
    "id": "1104032010",
    "district_id": "110403",
    "label": "Blang Kolak II",
    "value": "Blang Kolak II"
  },
  {
    "id": "1104032011",
    "district_id": "110403",
    "label": "Umang",
    "value": "Umang"
  },
  {
    "id": "1104032012",
    "district_id": "110403",
    "label": "Simpang IV",
    "value": "Simpang IV"
  },
  {
    "id": "1104032013",
    "district_id": "110403",
    "label": "Bahgie",
    "value": "Bahgie"
  },
  {
    "id": "1104032014",
    "district_id": "110403",
    "label": "Keramat Mupakat",
    "value": "Keramat Mupakat"
  },
  {
    "id": "1104032015",
    "district_id": "110403",
    "label": "Atu Gajah Reje Guru",
    "value": "Atu Gajah Reje Guru"
  },
  {
    "id": "1104032016",
    "district_id": "110403",
    "label": "Atu Tulu",
    "value": "Atu Tulu"
  },
  {
    "id": "1104032017",
    "district_id": "110403",
    "label": "Burbiah",
    "value": "Burbiah"
  },
  {
    "id": "1104032018",
    "district_id": "110403",
    "label": "Empus Talu",
    "value": "Empus Talu"
  },
  {
    "id": "1104032019",
    "district_id": "110403",
    "label": "Gele Lah",
    "value": "Gele Lah"
  },
  {
    "id": "1104032020",
    "district_id": "110403",
    "label": "Kebet",
    "value": "Kebet"
  },
  {
    "id": "1104032021",
    "district_id": "110403",
    "label": "Lemah Burbana",
    "value": "Lemah Burbana"
  },
  {
    "id": "1104032022",
    "district_id": "110403",
    "label": "Mah Bengi",
    "value": "Mah Bengi"
  },
  {
    "id": "1104032023",
    "district_id": "110403",
    "label": "Nunang Antara",
    "value": "Nunang Antara"
  },
  {
    "id": "1104032024",
    "district_id": "110403",
    "label": "Pendere Saril",
    "value": "Pendere Saril"
  },
  {
    "id": "1104032025",
    "district_id": "110403",
    "label": "Sadong Juru Mudi",
    "value": "Sadong Juru Mudi"
  },
  {
    "id": "1104032026",
    "district_id": "110403",
    "label": "Ulu Nuwih",
    "value": "Ulu Nuwih"
  },
  {
    "id": "1104032027",
    "district_id": "110403",
    "label": "Colo Blang Gele",
    "value": "Colo Blang Gele"
  },
  {
    "id": "1104032028",
    "district_id": "110403",
    "label": "Kala Kemili",
    "value": "Kala Kemili"
  },
  {
    "id": "1104072001",
    "district_id": "110407",
    "label": "Arul Badak",
    "value": "Arul Badak"
  },
  {
    "id": "1104072004",
    "district_id": "110407",
    "label": "Berawang Baro",
    "value": "Berawang Baro"
  },
  {
    "id": "1104072008",
    "district_id": "110407",
    "label": "Gelelungi",
    "value": "Gelelungi"
  },
  {
    "id": "1104072009",
    "district_id": "110407",
    "label": "Ie Reulop",
    "value": "Ie Reulop"
  },
  {
    "id": "1104072010",
    "district_id": "110407",
    "label": "Kayu Kul",
    "value": "Kayu Kul"
  },
  {
    "id": "1104072011",
    "district_id": "110407",
    "label": "Kedelah",
    "value": "Kedelah"
  },
  {
    "id": "1104072012",
    "district_id": "110407",
    "label": "Kung",
    "value": "Kung"
  },
  {
    "id": "1104072013",
    "district_id": "110407",
    "label": "Kute Lintang",
    "value": "Kute Lintang"
  },
  {
    "id": "1104072014",
    "district_id": "110407",
    "label": "Lelumu",
    "value": "Lelumu"
  },
  {
    "id": "1104072016",
    "district_id": "110407",
    "label": "Paya Jeget",
    "value": "Paya Jeget"
  },
  {
    "id": "1104072017",
    "district_id": "110407",
    "label": "Pedekok",
    "value": "Pedekok"
  },
  {
    "id": "1104072018",
    "district_id": "110407",
    "label": "Pegasing",
    "value": "Pegasing"
  },
  {
    "id": "1104072019",
    "district_id": "110407",
    "label": "Pepalang",
    "value": "Pepalang"
  },
  {
    "id": "1104072021",
    "district_id": "110407",
    "label": "Simpang Kelaping",
    "value": "Simpang Kelaping"
  },
  {
    "id": "1104072024",
    "district_id": "110407",
    "label": "Tebuk",
    "value": "Tebuk"
  },
  {
    "id": "1104072025",
    "district_id": "110407",
    "label": "Terang Ulen",
    "value": "Terang Ulen"
  },
  {
    "id": "1104072026",
    "district_id": "110407",
    "label": "Ujung Gele",
    "value": "Ujung Gele"
  },
  {
    "id": "1104072029",
    "district_id": "110407",
    "label": "Wih Ilang",
    "value": "Wih Ilang"
  },
  {
    "id": "1104072030",
    "district_id": "110407",
    "label": "Wih Nareh",
    "value": "Wih Nareh"
  },
  {
    "id": "1104072031",
    "district_id": "110407",
    "label": "Wih Lah",
    "value": "Wih Lah"
  },
  {
    "id": "1104072032",
    "district_id": "110407",
    "label": "Jejem",
    "value": "Jejem"
  },
  {
    "id": "1104072033",
    "district_id": "110407",
    "label": "Jurusen",
    "value": "Jurusen"
  },
  {
    "id": "1104072034",
    "district_id": "110407",
    "label": "Kala Pegasing",
    "value": "Kala Pegasing"
  },
  {
    "id": "1104072035",
    "district_id": "110407",
    "label": "Linung Ayu",
    "value": "Linung Ayu"
  },
  {
    "id": "1104072036",
    "district_id": "110407",
    "label": "Panangan Mata",
    "value": "Panangan Mata"
  },
  {
    "id": "1104072037",
    "district_id": "110407",
    "label": "Pantan Musara",
    "value": "Pantan Musara"
  },
  {
    "id": "1104072038",
    "district_id": "110407",
    "label": "Wih Terjun",
    "value": "Wih Terjun"
  },
  {
    "id": "1104072039",
    "district_id": "110407",
    "label": "Belang Bebangka",
    "value": "Belang Bebangka"
  },
  {
    "id": "1104072040",
    "district_id": "110407",
    "label": "Uning",
    "value": "Uning"
  },
  {
    "id": "1104072041",
    "district_id": "110407",
    "label": "Uring",
    "value": "Uring"
  },
  {
    "id": "1104072042",
    "district_id": "110407",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1104082001",
    "district_id": "110408",
    "label": "Atu Payung",
    "value": "Atu Payung"
  },
  {
    "id": "1104082002",
    "district_id": "110408",
    "label": "Bale Nosar",
    "value": "Bale Nosar"
  },
  {
    "id": "1104082003",
    "district_id": "110408",
    "label": "Bamil Nosar",
    "value": "Bamil Nosar"
  },
  {
    "id": "1104082004",
    "district_id": "110408",
    "label": "Bewang",
    "value": "Bewang"
  },
  {
    "id": "1104082005",
    "district_id": "110408",
    "label": "Dedamar",
    "value": "Dedamar"
  },
  {
    "id": "1104082006",
    "district_id": "110408",
    "label": "Genuren",
    "value": "Genuren"
  },
  {
    "id": "1104082007",
    "district_id": "110408",
    "label": "Kala Bintang",
    "value": "Kala Bintang"
  },
  {
    "id": "1104082008",
    "district_id": "110408",
    "label": "Kala Segi",
    "value": "Kala Segi"
  },
  {
    "id": "1104082009",
    "district_id": "110408",
    "label": "Kejurun Syiah Utama",
    "value": "Kejurun Syiah Utama"
  },
  {
    "id": "1104082010",
    "district_id": "110408",
    "label": "Kelitu Sintep",
    "value": "Kelitu Sintep"
  },
  {
    "id": "1104082011",
    "district_id": "110408",
    "label": "Kuala I",
    "value": "Kuala I"
  },
  {
    "id": "1104082012",
    "district_id": "110408",
    "label": "Kuala II",
    "value": "Kuala II"
  },
  {
    "id": "1104082013",
    "district_id": "110408",
    "label": "Linung Bulen I",
    "value": "Linung Bulen I"
  },
  {
    "id": "1104082014",
    "district_id": "110408",
    "label": "Linung Bulen II",
    "value": "Linung Bulen II"
  },
  {
    "id": "1104082015",
    "district_id": "110408",
    "label": "Mengaya",
    "value": "Mengaya"
  },
  {
    "id": "1104082016",
    "district_id": "110408",
    "label": "Mude Nosar",
    "value": "Mude Nosar"
  },
  {
    "id": "1104082017",
    "district_id": "110408",
    "label": "Serule",
    "value": "Serule"
  },
  {
    "id": "1104082018",
    "district_id": "110408",
    "label": "Wakil Jalil",
    "value": "Wakil Jalil"
  },
  {
    "id": "1104082019",
    "district_id": "110408",
    "label": "Wihlah Setie",
    "value": "Wihlah Setie"
  },
  {
    "id": "1104082020",
    "district_id": "110408",
    "label": "Gegarang",
    "value": "Gegarang"
  },
  {
    "id": "1104082021",
    "district_id": "110408",
    "label": "Jamur Konyel",
    "value": "Jamur Konyel"
  },
  {
    "id": "1104082022",
    "district_id": "110408",
    "label": "Merodot",
    "value": "Merodot"
  },
  {
    "id": "1104082023",
    "district_id": "110408",
    "label": "Sintep",
    "value": "Sintep"
  },
  {
    "id": "1104082024",
    "district_id": "110408",
    "label": "Gele Pulo",
    "value": "Gele Pulo"
  },
  {
    "id": "1104102001",
    "district_id": "110410",
    "label": "Bah",
    "value": "Bah"
  },
  {
    "id": "1104102002",
    "district_id": "110410",
    "label": "Blang Mancung",
    "value": "Blang Mancung"
  },
  {
    "id": "1104102003",
    "district_id": "110410",
    "label": "Bintang Pepara",
    "value": "Bintang Pepara"
  },
  {
    "id": "1104102004",
    "district_id": "110410",
    "label": "Burlah",
    "value": "Burlah"
  },
  {
    "id": "1104102005",
    "district_id": "110410",
    "label": "Buter",
    "value": "Buter"
  },
  {
    "id": "1104102006",
    "district_id": "110410",
    "label": "Cang Duri",
    "value": "Cang Duri"
  },
  {
    "id": "1104102007",
    "district_id": "110410",
    "label": "Geulumpang Payung",
    "value": "Geulumpang Payung"
  },
  {
    "id": "1104102008",
    "district_id": "110410",
    "label": "Jaluk",
    "value": "Jaluk"
  },
  {
    "id": "1104102009",
    "district_id": "110410",
    "label": "Kala Ketol",
    "value": "Kala Ketol"
  },
  {
    "id": "1104102010",
    "district_id": "110410",
    "label": "Karang Ampar",
    "value": "Karang Ampar"
  },
  {
    "id": "1104102011",
    "district_id": "110410",
    "label": "Kekuyang",
    "value": "Kekuyang"
  },
  {
    "id": "1104102012",
    "district_id": "110410",
    "label": "Kute Gelime",
    "value": "Kute Gelime"
  },
  {
    "id": "1104102013",
    "district_id": "110410",
    "label": "Pantan Penyo",
    "value": "Pantan Penyo"
  },
  {
    "id": "1104102014",
    "district_id": "110410",
    "label": "Pantan Reduk",
    "value": "Pantan Reduk"
  },
  {
    "id": "1104102015",
    "district_id": "110410",
    "label": "Pondok Balik",
    "value": "Pondok Balik"
  },
  {
    "id": "1104102016",
    "district_id": "110410",
    "label": "Rejewali",
    "value": "Rejewali"
  },
  {
    "id": "1104102017",
    "district_id": "110410",
    "label": "Serempah",
    "value": "Serempah"
  },
  {
    "id": "1104102018",
    "district_id": "110410",
    "label": "Bergang",
    "value": "Bergang"
  },
  {
    "id": "1104102019",
    "district_id": "110410",
    "label": "Blang Mancung Bawah",
    "value": "Blang Mancung Bawah"
  },
  {
    "id": "1104102020",
    "district_id": "110410",
    "label": "Buge Ara",
    "value": "Buge Ara"
  },
  {
    "id": "1104102021",
    "district_id": "110410",
    "label": "Genting Bulen",
    "value": "Genting Bulen"
  },
  {
    "id": "1104102022",
    "district_id": "110410",
    "label": "Jalan Tengah",
    "value": "Jalan Tengah"
  },
  {
    "id": "1104102023",
    "district_id": "110410",
    "label": "Jerata",
    "value": "Jerata"
  },
  {
    "id": "1104102024",
    "district_id": "110410",
    "label": "Simpang Juli",
    "value": "Simpang Juli"
  },
  {
    "id": "1104102025",
    "district_id": "110410",
    "label": "Selon",
    "value": "Selon"
  },
  {
    "id": "1104112001",
    "district_id": "110411",
    "label": "Bukit Sama",
    "value": "Bukit Sama"
  },
  {
    "id": "1104112002",
    "district_id": "110411",
    "label": "Gunung Bukit",
    "value": "Gunung Bukit"
  },
  {
    "id": "1104112003",
    "district_id": "110411",
    "label": "Jongok Meluem",
    "value": "Jongok Meluem"
  },
  {
    "id": "1104112004",
    "district_id": "110411",
    "label": "Kelupak Mata",
    "value": "Kelupak Mata"
  },
  {
    "id": "1104112005",
    "district_id": "110411",
    "label": "Lot Kala",
    "value": "Lot Kala"
  },
  {
    "id": "1104112006",
    "district_id": "110411",
    "label": "Mendale",
    "value": "Mendale"
  },
  {
    "id": "1104112007",
    "district_id": "110411",
    "label": "Paya Reje Tami Dalem",
    "value": "Paya Reje Tami Dalem"
  },
  {
    "id": "1104112008",
    "district_id": "110411",
    "label": "Paya Tumpi",
    "value": "Paya Tumpi"
  },
  {
    "id": "1104112009",
    "district_id": "110411",
    "label": "Pinangan",
    "value": "Pinangan"
  },
  {
    "id": "1104112010",
    "district_id": "110411",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "1104112011",
    "district_id": "110411",
    "label": "Bukit Ewih Tami Delem",
    "value": "Bukit Ewih Tami Delem"
  },
  {
    "id": "1104112012",
    "district_id": "110411",
    "label": "Gunung Bahgie",
    "value": "Gunung Bahgie"
  },
  {
    "id": "1104112013",
    "district_id": "110411",
    "label": "Gunung Balohen",
    "value": "Gunung Balohen"
  },
  {
    "id": "1104112014",
    "district_id": "110411",
    "label": "Jongkok Bathin",
    "value": "Jongkok Bathin"
  },
  {
    "id": "1104112015",
    "district_id": "110411",
    "label": "Kala Lengkio",
    "value": "Kala Lengkio"
  },
  {
    "id": "1104112016",
    "district_id": "110411",
    "label": "Kute Lot",
    "value": "Kute Lot"
  },
  {
    "id": "1104112017",
    "district_id": "110411",
    "label": "Paya Tumpi Baru",
    "value": "Paya Tumpi Baru"
  },
  {
    "id": "1104112018",
    "district_id": "110411",
    "label": "Paya Tumpi I",
    "value": "Paya Tumpi I"
  },
  {
    "id": "1104112019",
    "district_id": "110411",
    "label": "Timangan Gading",
    "value": "Timangan Gading"
  },
  {
    "id": "1104112020",
    "district_id": "110411",
    "label": "Telege Atu",
    "value": "Telege Atu"
  },
  {
    "id": "1104122001",
    "district_id": "110412",
    "label": "Atu Gogop",
    "value": "Atu Gogop"
  },
  {
    "id": "1104122002",
    "district_id": "110412",
    "label": "Balik",
    "value": "Balik"
  },
  {
    "id": "1104122003",
    "district_id": "110412",
    "label": "Buter Balik",
    "value": "Buter Balik"
  },
  {
    "id": "1104122004",
    "district_id": "110412",
    "label": "Dedingin",
    "value": "Dedingin"
  },
  {
    "id": "1104122005",
    "district_id": "110412",
    "label": "Kute Panang",
    "value": "Kute Panang"
  },
  {
    "id": "1104122006",
    "district_id": "110412",
    "label": "Lukub Sabun",
    "value": "Lukub Sabun"
  },
  {
    "id": "1104122007",
    "district_id": "110412",
    "label": "Pantan Sile",
    "value": "Pantan Sile"
  },
  {
    "id": "1104122008",
    "district_id": "110412",
    "label": "Ratawali",
    "value": "Ratawali"
  },
  {
    "id": "1104122009",
    "district_id": "110412",
    "label": "Segene Balik",
    "value": "Segene Balik"
  },
  {
    "id": "1104122010",
    "district_id": "110412",
    "label": "Tapak Moge",
    "value": "Tapak Moge"
  },
  {
    "id": "1104122011",
    "district_id": "110412",
    "label": "Tawar Miko",
    "value": "Tawar Miko"
  },
  {
    "id": "1104122012",
    "district_id": "110412",
    "label": "Tawardi",
    "value": "Tawardi"
  },
  {
    "id": "1104122013",
    "district_id": "110412",
    "label": "Timang Rasa",
    "value": "Timang Rasa"
  },
  {
    "id": "1104122014",
    "district_id": "110412",
    "label": "Wih Nongkal",
    "value": "Wih Nongkal"
  },
  {
    "id": "1104122015",
    "district_id": "110412",
    "label": "Bukit Rata",
    "value": "Bukit Rata"
  },
  {
    "id": "1104122016",
    "district_id": "110412",
    "label": "Lukub Sabun Barat",
    "value": "Lukub Sabun Barat"
  },
  {
    "id": "1104122017",
    "district_id": "110412",
    "label": "Lukub Sabun Tengah",
    "value": "Lukub Sabun Tengah"
  },
  {
    "id": "1104122018",
    "district_id": "110412",
    "label": "Lukub Sabun Timur",
    "value": "Lukub Sabun Timur"
  },
  {
    "id": "1104122019",
    "district_id": "110412",
    "label": "Wih Nongkal Toa",
    "value": "Wih Nongkal Toa"
  },
  {
    "id": "1104122020",
    "district_id": "110412",
    "label": "Tapak Moge Timur",
    "value": "Tapak Moge Timur"
  },
  {
    "id": "1104122021",
    "district_id": "110412",
    "label": "Empu Balik",
    "value": "Empu Balik"
  },
  {
    "id": "1104122022",
    "district_id": "110412",
    "label": "Blang Balik",
    "value": "Blang Balik"
  },
  {
    "id": "1104122023",
    "district_id": "110412",
    "label": "Kala Nongkal",
    "value": "Kala Nongkal"
  },
  {
    "id": "1104122024",
    "district_id": "110412",
    "label": "Pantan Jerik",
    "value": "Pantan Jerik"
  },
  {
    "id": "1104132001",
    "district_id": "110413",
    "label": "Arul Gading",
    "value": "Arul Gading"
  },
  {
    "id": "1104132002",
    "district_id": "110413",
    "label": "Belang Kekumur",
    "value": "Belang Kekumur"
  },
  {
    "id": "1104132003",
    "district_id": "110413",
    "label": "Berawang Gading",
    "value": "Berawang Gading"
  },
  {
    "id": "1104132004",
    "district_id": "110413",
    "label": "Celala",
    "value": "Celala"
  },
  {
    "id": "1104132005",
    "district_id": "110413",
    "label": "Cibro",
    "value": "Cibro"
  },
  {
    "id": "1104132006",
    "district_id": "110413",
    "label": "Kuyun",
    "value": "Kuyun"
  },
  {
    "id": "1104132007",
    "district_id": "110413",
    "label": "Kuyun Toa",
    "value": "Kuyun Toa"
  },
  {
    "id": "1104132008",
    "district_id": "110413",
    "label": "Kuyun Uken",
    "value": "Kuyun Uken"
  },
  {
    "id": "1104132009",
    "district_id": "110413",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "1104132010",
    "district_id": "110413",
    "label": "Melala",
    "value": "Melala"
  },
  {
    "id": "1104132011",
    "district_id": "110413",
    "label": "Paya Kolak",
    "value": "Paya Kolak"
  },
  {
    "id": "1104132012",
    "district_id": "110413",
    "label": "Ramung Ara",
    "value": "Ramung Ara"
  },
  {
    "id": "1104132013",
    "district_id": "110413",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "1104132014",
    "district_id": "110413",
    "label": "Tanoh Depet",
    "value": "Tanoh Depet"
  },
  {
    "id": "1104132015",
    "district_id": "110413",
    "label": "Uning Berawang Ramung",
    "value": "Uning Berawang Ramung"
  },
  {
    "id": "1104132016",
    "district_id": "110413",
    "label": "Blang Delem",
    "value": "Blang Delem"
  },
  {
    "id": "1104132017",
    "district_id": "110413",
    "label": "Depet Indah",
    "value": "Depet Indah"
  },
  {
    "id": "1104172001",
    "district_id": "110417",
    "label": "Takengon Timur",
    "value": "Takengon Timur"
  },
  {
    "id": "1104172002",
    "district_id": "110417",
    "label": "Asir Asir",
    "value": "Asir Asir"
  },
  {
    "id": "1104172003",
    "district_id": "110417",
    "label": "Asir Asir Asia",
    "value": "Asir Asir Asia"
  },
  {
    "id": "1104172004",
    "district_id": "110417",
    "label": "Bale Atu",
    "value": "Bale Atu"
  },
  {
    "id": "1104172005",
    "district_id": "110417",
    "label": "Bujang",
    "value": "Bujang"
  },
  {
    "id": "1104172006",
    "district_id": "110417",
    "label": "Gunung Suku",
    "value": "Gunung Suku"
  },
  {
    "id": "1104172007",
    "district_id": "110417",
    "label": "Hakim Bale Bujang",
    "value": "Hakim Bale Bujang"
  },
  {
    "id": "1104172008",
    "district_id": "110417",
    "label": "Kenawat",
    "value": "Kenawat"
  },
  {
    "id": "1104172009",
    "district_id": "110417",
    "label": "Kuteni Reje",
    "value": "Kuteni Reje"
  },
  {
    "id": "1104172010",
    "district_id": "110417",
    "label": "Pedemun One One",
    "value": "Pedemun One One"
  },
  {
    "id": "1104172011",
    "district_id": "110417",
    "label": "Rawe",
    "value": "Rawe"
  },
  {
    "id": "1104172012",
    "district_id": "110417",
    "label": "Takengon Barat",
    "value": "Takengon Barat"
  },
  {
    "id": "1104172013",
    "district_id": "110417",
    "label": "Toweren Antara",
    "value": "Toweren Antara"
  },
  {
    "id": "1104172014",
    "district_id": "110417",
    "label": "Toweran Toa",
    "value": "Toweran Toa"
  },
  {
    "id": "1104172015",
    "district_id": "110417",
    "label": "Toweren Uken",
    "value": "Toweren Uken"
  },
  {
    "id": "1104172016",
    "district_id": "110417",
    "label": "Teluk One-One",
    "value": "Teluk One-One"
  },
  {
    "id": "1104172017",
    "district_id": "110417",
    "label": "Merah Mersa",
    "value": "Merah Mersa"
  },
  {
    "id": "1104172018",
    "district_id": "110417",
    "label": "Waq Toweren",
    "value": "Waq Toweren"
  },
  {
    "id": "1104182001",
    "district_id": "110418",
    "label": "Atu Lintang",
    "value": "Atu Lintang"
  },
  {
    "id": "1104182002",
    "district_id": "110418",
    "label": "Merah Mege",
    "value": "Merah Mege"
  },
  {
    "id": "1104182003",
    "district_id": "110418",
    "label": "Merah Pupuk",
    "value": "Merah Pupuk"
  },
  {
    "id": "1104182004",
    "district_id": "110418",
    "label": "Merah Muyang",
    "value": "Merah Muyang"
  },
  {
    "id": "1104182005",
    "district_id": "110418",
    "label": "Gayo Murni",
    "value": "Gayo Murni"
  },
  {
    "id": "1104182006",
    "district_id": "110418",
    "label": "Kepala Akal",
    "value": "Kepala Akal"
  },
  {
    "id": "1104182007",
    "district_id": "110418",
    "label": "Tanoh Abu",
    "value": "Tanoh Abu"
  },
  {
    "id": "1104182008",
    "district_id": "110418",
    "label": "Bintang Kekelip",
    "value": "Bintang Kekelip"
  },
  {
    "id": "1104182009",
    "district_id": "110418",
    "label": "Merah Jernang",
    "value": "Merah Jernang"
  },
  {
    "id": "1104182010",
    "district_id": "110418",
    "label": "Damar Mulyo",
    "value": "Damar Mulyo"
  },
  {
    "id": "1104182011",
    "district_id": "110418",
    "label": "Pantan Damar",
    "value": "Pantan Damar"
  },
  {
    "id": "1104192001",
    "district_id": "110419",
    "label": "Merah Said",
    "value": "Merah Said"
  },
  {
    "id": "1104192002",
    "district_id": "110419",
    "label": "Berawang Dewal",
    "value": "Berawang Dewal"
  },
  {
    "id": "1104192003",
    "district_id": "110419",
    "label": "Gegarang",
    "value": "Gegarang"
  },
  {
    "id": "1104192004",
    "district_id": "110419",
    "label": "Telege Sari",
    "value": "Telege Sari"
  },
  {
    "id": "1104192005",
    "district_id": "110419",
    "label": "Jeget Ayu",
    "value": "Jeget Ayu"
  },
  {
    "id": "1104192006",
    "district_id": "110419",
    "label": "Paya Tungel",
    "value": "Paya Tungel"
  },
  {
    "id": "1104192007",
    "district_id": "110419",
    "label": "Jagong Jeget",
    "value": "Jagong Jeget"
  },
  {
    "id": "1104192008",
    "district_id": "110419",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1104192009",
    "district_id": "110419",
    "label": "Bukit Kemuning",
    "value": "Bukit Kemuning"
  },
  {
    "id": "1104192010",
    "district_id": "110419",
    "label": "Paya Dedep",
    "value": "Paya Dedep"
  },
  {
    "id": "1104202001",
    "district_id": "110420",
    "label": "Bies Penentanan",
    "value": "Bies Penentanan"
  },
  {
    "id": "1104202002",
    "district_id": "110420",
    "label": "Bies Baru",
    "value": "Bies Baru"
  },
  {
    "id": "1104202003",
    "district_id": "110420",
    "label": "Bies Mulie",
    "value": "Bies Mulie"
  },
  {
    "id": "1104202004",
    "district_id": "110420",
    "label": "Aru Latong",
    "value": "Aru Latong"
  },
  {
    "id": "1104202005",
    "district_id": "110420",
    "label": "Simpang Lukup Badak",
    "value": "Simpang Lukup Badak"
  },
  {
    "id": "1104202006",
    "district_id": "110420",
    "label": "Atang Jungket",
    "value": "Atang Jungket"
  },
  {
    "id": "1104202007",
    "district_id": "110420",
    "label": "Tebes Lues",
    "value": "Tebes Lues"
  },
  {
    "id": "1104202008",
    "district_id": "110420",
    "label": "Pucuk Deku",
    "value": "Pucuk Deku"
  },
  {
    "id": "1104202009",
    "district_id": "110420",
    "label": "Lenga",
    "value": "Lenga"
  },
  {
    "id": "1104202010",
    "district_id": "110420",
    "label": "Uning Pegantungen",
    "value": "Uning Pegantungen"
  },
  {
    "id": "1104202011",
    "district_id": "110420",
    "label": "Simpang Uning Niken",
    "value": "Simpang Uning Niken"
  },
  {
    "id": "1104202012",
    "district_id": "110420",
    "label": "Karang Bayur",
    "value": "Karang Bayur"
  },
  {
    "id": "1104212001",
    "district_id": "110421",
    "label": "Pantan Tengah",
    "value": "Pantan Tengah"
  },
  {
    "id": "1104212002",
    "district_id": "110421",
    "label": "Rusip",
    "value": "Rusip"
  },
  {
    "id": "1104212003",
    "district_id": "110421",
    "label": "Pilar",
    "value": "Pilar"
  },
  {
    "id": "1104212004",
    "district_id": "110421",
    "label": "Arul Pertik",
    "value": "Arul Pertik"
  },
  {
    "id": "1104212005",
    "district_id": "110421",
    "label": "Pilar Jaya",
    "value": "Pilar Jaya"
  },
  {
    "id": "1104212006",
    "district_id": "110421",
    "label": "Tirmi Ara",
    "value": "Tirmi Ara"
  },
  {
    "id": "1104212007",
    "district_id": "110421",
    "label": "Atu Singkih",
    "value": "Atu Singkih"
  },
  {
    "id": "1104212008",
    "district_id": "110421",
    "label": "Pantan Bener",
    "value": "Pantan Bener"
  },
  {
    "id": "1104212009",
    "district_id": "110421",
    "label": "Kerawang",
    "value": "Kerawang"
  },
  {
    "id": "1104212010",
    "district_id": "110421",
    "label": "Mekar Maju",
    "value": "Mekar Maju"
  },
  {
    "id": "1104212011",
    "district_id": "110421",
    "label": "Paya Tampu",
    "value": "Paya Tampu"
  },
  {
    "id": "1104212012",
    "district_id": "110421",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1104212013",
    "district_id": "110421",
    "label": "Merandeh Paya",
    "value": "Merandeh Paya"
  },
  {
    "id": "1104212014",
    "district_id": "110421",
    "label": "Kuala Rawa",
    "value": "Kuala Rawa"
  },
  {
    "id": "1104212015",
    "district_id": "110421",
    "label": "Pilar Wih Kiri",
    "value": "Pilar Wih Kiri"
  },
  {
    "id": "1104212016",
    "district_id": "110421",
    "label": "Lut Jaya",
    "value": "Lut Jaya"
  },
  {
    "id": "1105012001",
    "district_id": "110501",
    "label": "Suak Indrapuri",
    "value": "Suak Indrapuri"
  },
  {
    "id": "1105012002",
    "district_id": "110501",
    "label": "Kampung Belakang",
    "value": "Kampung Belakang"
  },
  {
    "id": "1105012003",
    "district_id": "110501",
    "label": "Ujung Kalak",
    "value": "Ujung Kalak"
  },
  {
    "id": "1105012004",
    "district_id": "110501",
    "label": "Kuta Padang",
    "value": "Kuta Padang"
  },
  {
    "id": "1105012005",
    "district_id": "110501",
    "label": "Ujong Baroh",
    "value": "Ujong Baroh"
  },
  {
    "id": "1105012006",
    "district_id": "110501",
    "label": "Rundeng",
    "value": "Rundeng"
  },
  {
    "id": "1105012007",
    "district_id": "110501",
    "label": "Drien Rampak",
    "value": "Drien Rampak"
  },
  {
    "id": "1105012008",
    "district_id": "110501",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "1105012009",
    "district_id": "110501",
    "label": "Pasar Aceh",
    "value": "Pasar Aceh"
  },
  {
    "id": "1105012010",
    "district_id": "110501",
    "label": "Padang Seurahet",
    "value": "Padang Seurahet"
  },
  {
    "id": "1105012011",
    "district_id": "110501",
    "label": "Panggong",
    "value": "Panggong"
  },
  {
    "id": "1105012012",
    "district_id": "110501",
    "label": "Seuneugok",
    "value": "Seuneugok"
  },
  {
    "id": "1105012013",
    "district_id": "110501",
    "label": "Gampong Darat",
    "value": "Gampong Darat"
  },
  {
    "id": "1105012014",
    "district_id": "110501",
    "label": "Gampa",
    "value": "Gampa"
  },
  {
    "id": "1105012015",
    "district_id": "110501",
    "label": "Lapang",
    "value": "Lapang"
  },
  {
    "id": "1105012016",
    "district_id": "110501",
    "label": "Blang Beurandang",
    "value": "Blang Beurandang"
  },
  {
    "id": "1105012017",
    "district_id": "110501",
    "label": "Suak Ribee",
    "value": "Suak Ribee"
  },
  {
    "id": "1105012018",
    "district_id": "110501",
    "label": "Suak Raya",
    "value": "Suak Raya"
  },
  {
    "id": "1105012019",
    "district_id": "110501",
    "label": "Suak Nie",
    "value": "Suak Nie"
  },
  {
    "id": "1105012020",
    "district_id": "110501",
    "label": "Leuhan",
    "value": "Leuhan"
  },
  {
    "id": "1105012021",
    "district_id": "110501",
    "label": "Suak Sigadeng",
    "value": "Suak Sigadeng"
  },
  {
    "id": "1105022001",
    "district_id": "110502",
    "label": "Keude Aron",
    "value": "Keude Aron"
  },
  {
    "id": "1105022002",
    "district_id": "110502",
    "label": "Kampung Masjid",
    "value": "Kampung Masjid"
  },
  {
    "id": "1105022003",
    "district_id": "110502",
    "label": "Padang Mancang",
    "value": "Padang Mancang"
  },
  {
    "id": "1105022004",
    "district_id": "110502",
    "label": "Meunasah Buloh",
    "value": "Meunasah Buloh"
  },
  {
    "id": "1105022005",
    "district_id": "110502",
    "label": "Alue Tampak",
    "value": "Alue Tampak"
  },
  {
    "id": "1105022006",
    "district_id": "110502",
    "label": "Beureugang",
    "value": "Beureugang"
  },
  {
    "id": "1105022007",
    "district_id": "110502",
    "label": "Marek",
    "value": "Marek"
  },
  {
    "id": "1105022008",
    "district_id": "110502",
    "label": "Pasi Jambu",
    "value": "Pasi Jambu"
  },
  {
    "id": "1105022009",
    "district_id": "110502",
    "label": "Meunasah Ara",
    "value": "Meunasah Ara"
  },
  {
    "id": "1105022010",
    "district_id": "110502",
    "label": "Meunasah Rayeuk",
    "value": "Meunasah Rayeuk"
  },
  {
    "id": "1105022011",
    "district_id": "110502",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1105022012",
    "district_id": "110502",
    "label": "Peunia",
    "value": "Peunia"
  },
  {
    "id": "1105022013",
    "district_id": "110502",
    "label": "Tumpok Ladang",
    "value": "Tumpok Ladang"
  },
  {
    "id": "1105022014",
    "district_id": "110502",
    "label": "Pasi Teungoh",
    "value": "Pasi Teungoh"
  },
  {
    "id": "1105022015",
    "district_id": "110502",
    "label": "Putim",
    "value": "Putim"
  },
  {
    "id": "1105022016",
    "district_id": "110502",
    "label": "Meunasah Rambot",
    "value": "Meunasah Rambot"
  },
  {
    "id": "1105022017",
    "district_id": "110502",
    "label": "Pasi Jeumpa",
    "value": "Pasi Jeumpa"
  },
  {
    "id": "1105022018",
    "district_id": "110502",
    "label": "Palimbungan",
    "value": "Palimbungan"
  },
  {
    "id": "1105022019",
    "district_id": "110502",
    "label": "Muko",
    "value": "Muko"
  },
  {
    "id": "1105022020",
    "district_id": "110502",
    "label": "Meuna. Gantung",
    "value": "Meuna. Gantung"
  },
  {
    "id": "1105022021",
    "district_id": "110502",
    "label": "Blang Geunang",
    "value": "Blang Geunang"
  },
  {
    "id": "1105022022",
    "district_id": "110502",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1105022023",
    "district_id": "110502",
    "label": "Tanjong Bungong",
    "value": "Tanjong Bungong"
  },
  {
    "id": "1105022024",
    "district_id": "110502",
    "label": "Alue On",
    "value": "Alue On"
  },
  {
    "id": "1105022036",
    "district_id": "110502",
    "label": "Tanjong Meulaboh",
    "value": "Tanjong Meulaboh"
  },
  {
    "id": "1105022037",
    "district_id": "110502",
    "label": "Keude Tanjong",
    "value": "Keude Tanjong"
  },
  {
    "id": "1105022038",
    "district_id": "110502",
    "label": "Pungkie",
    "value": "Pungkie"
  },
  {
    "id": "1105022039",
    "district_id": "110502",
    "label": "Pucok Pungkie",
    "value": "Pucok Pungkie"
  },
  {
    "id": "1105022040",
    "district_id": "110502",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1105022041",
    "district_id": "110502",
    "label": "Pasi Ara",
    "value": "Pasi Ara"
  },
  {
    "id": "1105022042",
    "district_id": "110502",
    "label": "Meunuang Tanjong",
    "value": "Meunuang Tanjong"
  },
  {
    "id": "1105022043",
    "district_id": "110502",
    "label": "Alue Lhee",
    "value": "Alue Lhee"
  },
  {
    "id": "1105022044",
    "district_id": "110502",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1105022045",
    "district_id": "110502",
    "label": "Teupin Panah",
    "value": "Teupin Panah"
  },
  {
    "id": "1105022046",
    "district_id": "110502",
    "label": "Drien Caleue",
    "value": "Drien Caleue"
  },
  {
    "id": "1105022047",
    "district_id": "110502",
    "label": "Alue Peudeung",
    "value": "Alue Peudeung"
  },
  {
    "id": "1105022048",
    "district_id": "110502",
    "label": "Pasi Meugat",
    "value": "Pasi Meugat"
  },
  {
    "id": "1105022049",
    "district_id": "110502",
    "label": "Babah Meulaboh",
    "value": "Babah Meulaboh"
  },
  {
    "id": "1105022050",
    "district_id": "110502",
    "label": "Pasi Kumbang",
    "value": "Pasi Kumbang"
  },
  {
    "id": "1105022051",
    "district_id": "110502",
    "label": "Padang Sikabu",
    "value": "Padang Sikabu"
  },
  {
    "id": "1105022052",
    "district_id": "110502",
    "label": "Sawang Teubei",
    "value": "Sawang Teubei"
  },
  {
    "id": "1105022053",
    "district_id": "110502",
    "label": "Alue Lhok",
    "value": "Alue Lhok"
  },
  {
    "id": "1105022062",
    "district_id": "110502",
    "label": "Butu Jaya",
    "value": "Butu Jaya"
  },
  {
    "id": "1105022063",
    "district_id": "110502",
    "label": "Keuramat",
    "value": "Keuramat"
  },
  {
    "id": "1105032001",
    "district_id": "110503",
    "label": "Tungkop",
    "value": "Tungkop"
  },
  {
    "id": "1105032002",
    "district_id": "110503",
    "label": "Sakuy",
    "value": "Sakuy"
  },
  {
    "id": "1105032003",
    "district_id": "110503",
    "label": "Kajeung",
    "value": "Kajeung"
  },
  {
    "id": "1105032004",
    "district_id": "110503",
    "label": "Geudong",
    "value": "Geudong"
  },
  {
    "id": "1105032005",
    "district_id": "110503",
    "label": "Sarah Perlak",
    "value": "Sarah Perlak"
  },
  {
    "id": "1105032006",
    "district_id": "110503",
    "label": "Tutut",
    "value": "Tutut"
  },
  {
    "id": "1105032007",
    "district_id": "110503",
    "label": "Drien Sibak",
    "value": "Drien Sibak"
  },
  {
    "id": "1105032008",
    "district_id": "110503",
    "label": "Tuwi Saya",
    "value": "Tuwi Saya"
  },
  {
    "id": "1105032009",
    "district_id": "110503",
    "label": "Lancong",
    "value": "Lancong"
  },
  {
    "id": "1105032010",
    "district_id": "110503",
    "label": "Sipot",
    "value": "Sipot"
  },
  {
    "id": "1105032011",
    "district_id": "110503",
    "label": "Pungki",
    "value": "Pungki"
  },
  {
    "id": "1105032012",
    "district_id": "110503",
    "label": "Gunong Buloh",
    "value": "Gunong Buloh"
  },
  {
    "id": "1105032013",
    "district_id": "110503",
    "label": "Ramitie",
    "value": "Ramitie"
  },
  {
    "id": "1105032014",
    "district_id": "110503",
    "label": "Lubok Beutong",
    "value": "Lubok Beutong"
  },
  {
    "id": "1105032015",
    "district_id": "110503",
    "label": "Gleng",
    "value": "Gleng"
  },
  {
    "id": "1105032016",
    "district_id": "110503",
    "label": "Tanoh Mirah",
    "value": "Tanoh Mirah"
  },
  {
    "id": "1105032017",
    "district_id": "110503",
    "label": "Gaseu",
    "value": "Gaseu"
  },
  {
    "id": "1105032018",
    "district_id": "110503",
    "label": "Lueng Baro",
    "value": "Lueng Baro"
  },
  {
    "id": "1105042001",
    "district_id": "110504",
    "label": "Kuala Bhee",
    "value": "Kuala Bhee"
  },
  {
    "id": "1105042002",
    "district_id": "110504",
    "label": "Pasi Lunak",
    "value": "Pasi Lunak"
  },
  {
    "id": "1105042003",
    "district_id": "110504",
    "label": "Pasi Aceh",
    "value": "Pasi Aceh"
  },
  {
    "id": "1105042004",
    "district_id": "110504",
    "label": "Aron Tunong",
    "value": "Aron Tunong"
  },
  {
    "id": "1105042005",
    "district_id": "110504",
    "label": "Aron Baroh",
    "value": "Aron Baroh"
  },
  {
    "id": "1105042006",
    "district_id": "110504",
    "label": "Pasi Pandan",
    "value": "Pasi Pandan"
  },
  {
    "id": "1105042007",
    "district_id": "110504",
    "label": "Ranto Panyang",
    "value": "Ranto Panyang"
  },
  {
    "id": "1105042008",
    "district_id": "110504",
    "label": "Gempa Raya",
    "value": "Gempa Raya"
  },
  {
    "id": "1105042009",
    "district_id": "110504",
    "label": "Padang Jawa",
    "value": "Padang Jawa"
  },
  {
    "id": "1105042010",
    "district_id": "110504",
    "label": "Ie Itam Tunong",
    "value": "Ie Itam Tunong"
  },
  {
    "id": "1105042011",
    "district_id": "110504",
    "label": "Ie Itam Baroh",
    "value": "Ie Itam Baroh"
  },
  {
    "id": "1105042012",
    "district_id": "110504",
    "label": "Tingkeum Panyang",
    "value": "Tingkeum Panyang"
  },
  {
    "id": "1105042013",
    "district_id": "110504",
    "label": "Lueng Teungku Yah",
    "value": "Lueng Teungku Yah"
  },
  {
    "id": "1105042014",
    "district_id": "110504",
    "label": "Lueng Tanoh Tho",
    "value": "Lueng Tanoh Tho"
  },
  {
    "id": "1105042015",
    "district_id": "110504",
    "label": "Gunong Rambong",
    "value": "Gunong Rambong"
  },
  {
    "id": "1105042016",
    "district_id": "110504",
    "label": "Drien Mangko",
    "value": "Drien Mangko"
  },
  {
    "id": "1105042017",
    "district_id": "110504",
    "label": "Gunung Hampa",
    "value": "Gunung Hampa"
  },
  {
    "id": "1105042018",
    "district_id": "110504",
    "label": "Lung Jawa",
    "value": "Lung Jawa"
  },
  {
    "id": "1105042019",
    "district_id": "110504",
    "label": "Blang Mee",
    "value": "Blang Mee"
  },
  {
    "id": "1105042020",
    "district_id": "110504",
    "label": "Alue Blang",
    "value": "Alue Blang"
  },
  {
    "id": "1105042021",
    "district_id": "110504",
    "label": "Paya Dua",
    "value": "Paya Dua"
  },
  {
    "id": "1105042022",
    "district_id": "110504",
    "label": "Paya Luah",
    "value": "Paya Luah"
  },
  {
    "id": "1105042023",
    "district_id": "110504",
    "label": "Pasi Ara Kuala Batee",
    "value": "Pasi Ara Kuala Batee"
  },
  {
    "id": "1105042024",
    "district_id": "110504",
    "label": "Alue Sikaya",
    "value": "Alue Sikaya"
  },
  {
    "id": "1105042025",
    "district_id": "110504",
    "label": "Darul Huda",
    "value": "Darul Huda"
  },
  {
    "id": "1105042026",
    "district_id": "110504",
    "label": "Keuleumbah",
    "value": "Keuleumbah"
  },
  {
    "id": "1105042027",
    "district_id": "110504",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "1105042028",
    "district_id": "110504",
    "label": "Pulo Ie",
    "value": "Pulo Ie"
  },
  {
    "id": "1105042029",
    "district_id": "110504",
    "label": "Alue Panyang",
    "value": "Alue Panyang"
  },
  {
    "id": "1105042030",
    "district_id": "110504",
    "label": "Cot Keumudai",
    "value": "Cot Keumudai"
  },
  {
    "id": "1105042031",
    "district_id": "110504",
    "label": "Seumantok",
    "value": "Seumantok"
  },
  {
    "id": "1105042032",
    "district_id": "110504",
    "label": "Cot Situah",
    "value": "Cot Situah"
  },
  {
    "id": "1105042033",
    "district_id": "110504",
    "label": "Jawi",
    "value": "Jawi"
  },
  {
    "id": "1105042034",
    "district_id": "110504",
    "label": "Panton",
    "value": "Panton"
  },
  {
    "id": "1105042035",
    "district_id": "110504",
    "label": "Pasi Birah",
    "value": "Pasi Birah"
  },
  {
    "id": "1105042036",
    "district_id": "110504",
    "label": "Alue Sundak",
    "value": "Alue Sundak"
  },
  {
    "id": "1105042037",
    "district_id": "110504",
    "label": "Teumarom",
    "value": "Teumarom"
  },
  {
    "id": "1105042038",
    "district_id": "110504",
    "label": "Cot Murong",
    "value": "Cot Murong"
  },
  {
    "id": "1105042039",
    "district_id": "110504",
    "label": "Glee Siblah",
    "value": "Glee Siblah"
  },
  {
    "id": "1105042040",
    "district_id": "110504",
    "label": "Suak Trieng",
    "value": "Suak Trieng"
  },
  {
    "id": "1105042041",
    "district_id": "110504",
    "label": "Cot Lagan Bubon",
    "value": "Cot Lagan Bubon"
  },
  {
    "id": "1105042042",
    "district_id": "110504",
    "label": "Bakat",
    "value": "Bakat"
  },
  {
    "id": "1105042043",
    "district_id": "110504",
    "label": "Lueng Buloh",
    "value": "Lueng Buloh"
  },
  {
    "id": "1105052001",
    "district_id": "110505",
    "label": "Alue Raya",
    "value": "Alue Raya"
  },
  {
    "id": "1105052002",
    "district_id": "110505",
    "label": "Cot Darat",
    "value": "Cot Darat"
  },
  {
    "id": "1105052003",
    "district_id": "110505",
    "label": "Cot Pluh",
    "value": "Cot Pluh"
  },
  {
    "id": "1105052004",
    "district_id": "110505",
    "label": "Cot Seumeurung",
    "value": "Cot Seumeurung"
  },
  {
    "id": "1105052005",
    "district_id": "110505",
    "label": "Cot Masjid",
    "value": "Cot Masjid"
  },
  {
    "id": "1105052006",
    "district_id": "110505",
    "label": "Cot Lampise",
    "value": "Cot Lampise"
  },
  {
    "id": "1105052007",
    "district_id": "110505",
    "label": "Cot Seulamat",
    "value": "Cot Seulamat"
  },
  {
    "id": "1105052008",
    "district_id": "110505",
    "label": "Cot Amun",
    "value": "Cot Amun"
  },
  {
    "id": "1105052009",
    "district_id": "110505",
    "label": "Deuah",
    "value": "Deuah"
  },
  {
    "id": "1105052010",
    "district_id": "110505",
    "label": "Gampong Teungoh",
    "value": "Gampong Teungoh"
  },
  {
    "id": "1105052011",
    "district_id": "110505",
    "label": "Gampong Ladang",
    "value": "Gampong Ladang"
  },
  {
    "id": "1105052012",
    "district_id": "110505",
    "label": "Gampong Cot",
    "value": "Gampong Cot"
  },
  {
    "id": "1105052013",
    "district_id": "110505",
    "label": "Keureuseng",
    "value": "Keureuseng"
  },
  {
    "id": "1105052014",
    "district_id": "110505",
    "label": "Krueng Tinggai",
    "value": "Krueng Tinggai"
  },
  {
    "id": "1105052015",
    "district_id": "110505",
    "label": "Kuala Bubon",
    "value": "Kuala Bubon"
  },
  {
    "id": "1105052016",
    "district_id": "110505",
    "label": "Leubok",
    "value": "Leubok"
  },
  {
    "id": "1105052017",
    "district_id": "110505",
    "label": "Lhok Bubon",
    "value": "Lhok Bubon"
  },
  {
    "id": "1105052018",
    "district_id": "110505",
    "label": "Leuken",
    "value": "Leuken"
  },
  {
    "id": "1105052019",
    "district_id": "110505",
    "label": "Mesjid baro",
    "value": "Mesjid baro"
  },
  {
    "id": "1105052020",
    "district_id": "110505",
    "label": "Paya Lumpat",
    "value": "Paya Lumpat"
  },
  {
    "id": "1105052021",
    "district_id": "110505",
    "label": "Pinem",
    "value": "Pinem"
  },
  {
    "id": "1105052022",
    "district_id": "110505",
    "label": "Pange",
    "value": "Pange"
  },
  {
    "id": "1105052023",
    "district_id": "110505",
    "label": "Pucok Lueng",
    "value": "Pucok Lueng"
  },
  {
    "id": "1105052024",
    "district_id": "110505",
    "label": "Rangkileh",
    "value": "Rangkileh"
  },
  {
    "id": "1105052025",
    "district_id": "110505",
    "label": "Reusak",
    "value": "Reusak"
  },
  {
    "id": "1105052026",
    "district_id": "110505",
    "label": "Suak Pandan",
    "value": "Suak Pandan"
  },
  {
    "id": "1105052027",
    "district_id": "110505",
    "label": "Suak Seuke",
    "value": "Suak Seuke"
  },
  {
    "id": "1105052028",
    "district_id": "110505",
    "label": "Suak Pante Breh",
    "value": "Suak Pante Breh"
  },
  {
    "id": "1105052029",
    "district_id": "110505",
    "label": "Suak Geudeubang",
    "value": "Suak Geudeubang"
  },
  {
    "id": "1105052030",
    "district_id": "110505",
    "label": "Suak Seumaseh",
    "value": "Suak Seumaseh"
  },
  {
    "id": "1105052031",
    "district_id": "110505",
    "label": "Suak Timah",
    "value": "Suak Timah"
  },
  {
    "id": "1105052032",
    "district_id": "110505",
    "label": "Ujong Nga",
    "value": "Ujong Nga"
  },
  {
    "id": "1105062001",
    "district_id": "110506",
    "label": "Alue Lhok",
    "value": "Alue Lhok"
  },
  {
    "id": "1105062002",
    "district_id": "110506",
    "label": "Alue Bakong",
    "value": "Alue Bakong"
  },
  {
    "id": "1105062003",
    "district_id": "110506",
    "label": "Berawang",
    "value": "Berawang"
  },
  {
    "id": "1105062004",
    "district_id": "110506",
    "label": "Blang Sibeutong",
    "value": "Blang Sibeutong"
  },
  {
    "id": "1105062005",
    "district_id": "110506",
    "label": "Cot Keumuneng",
    "value": "Cot Keumuneng"
  },
  {
    "id": "1105062006",
    "district_id": "110506",
    "label": "Cot Lada",
    "value": "Cot Lada"
  },
  {
    "id": "1105062007",
    "district_id": "110506",
    "label": "Gunong Panah",
    "value": "Gunong Panah"
  },
  {
    "id": "1105062008",
    "district_id": "110506",
    "label": "Kuta Padang",
    "value": "Kuta Padang"
  },
  {
    "id": "1105062009",
    "district_id": "110506",
    "label": "Kuala Pling",
    "value": "Kuala Pling"
  },
  {
    "id": "1105062010",
    "district_id": "110506",
    "label": "Layung",
    "value": "Layung"
  },
  {
    "id": "1105062011",
    "district_id": "110506",
    "label": "Liceh",
    "value": "Liceh"
  },
  {
    "id": "1105062012",
    "district_id": "110506",
    "label": "Peulanteu SP",
    "value": "Peulanteu SP"
  },
  {
    "id": "1105062013",
    "district_id": "110506",
    "label": "Rambong",
    "value": "Rambong"
  },
  {
    "id": "1105062014",
    "district_id": "110506",
    "label": "Seumuleng",
    "value": "Seumuleng"
  },
  {
    "id": "1105062015",
    "district_id": "110506",
    "label": "Suak Pangkat",
    "value": "Suak Pangkat"
  },
  {
    "id": "1105062016",
    "district_id": "110506",
    "label": "Seuneubok Trap",
    "value": "Seuneubok Trap"
  },
  {
    "id": "1105062017",
    "district_id": "110506",
    "label": "Ulee Blang",
    "value": "Ulee Blang"
  },
  {
    "id": "1105072001",
    "district_id": "110507",
    "label": "Arongan",
    "value": "Arongan"
  },
  {
    "id": "1105072002",
    "district_id": "110507",
    "label": "Alue Bagok",
    "value": "Alue Bagok"
  },
  {
    "id": "1105072003",
    "district_id": "110507",
    "label": "Alue Sundak",
    "value": "Alue Sundak"
  },
  {
    "id": "1105072004",
    "district_id": "110507",
    "label": "Alue Batee",
    "value": "Alue Batee"
  },
  {
    "id": "1105072005",
    "district_id": "110507",
    "label": "Cot Buloh",
    "value": "Cot Buloh"
  },
  {
    "id": "1105072006",
    "district_id": "110507",
    "label": "Cot Kumbang",
    "value": "Cot Kumbang"
  },
  {
    "id": "1105072007",
    "district_id": "110507",
    "label": "Cot Juru Mudi",
    "value": "Cot Juru Mudi"
  },
  {
    "id": "1105072008",
    "district_id": "110507",
    "label": "Drien Rampak",
    "value": "Drien Rampak"
  },
  {
    "id": "1105072009",
    "district_id": "110507",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "1105072010",
    "district_id": "110507",
    "label": "Keub",
    "value": "Keub"
  },
  {
    "id": "1105072011",
    "district_id": "110507",
    "label": "Pante Mutia",
    "value": "Pante Mutia"
  },
  {
    "id": "1105072012",
    "district_id": "110507",
    "label": "Peribu",
    "value": "Peribu"
  },
  {
    "id": "1105072013",
    "district_id": "110507",
    "label": "Panton Makmu",
    "value": "Panton Makmu"
  },
  {
    "id": "1105072014",
    "district_id": "110507",
    "label": "Panton Bahagia",
    "value": "Panton Bahagia"
  },
  {
    "id": "1105072015",
    "district_id": "110507",
    "label": "Peulanteu LB.",
    "value": "Peulanteu LB."
  },
  {
    "id": "1105072016",
    "district_id": "110507",
    "label": "Rimba Langgeh",
    "value": "Rimba Langgeh"
  },
  {
    "id": "1105072017",
    "district_id": "110507",
    "label": "Suak Keumudee",
    "value": "Suak Keumudee"
  },
  {
    "id": "1105072018",
    "district_id": "110507",
    "label": "Seuneubok Lhong",
    "value": "Seuneubok Lhong"
  },
  {
    "id": "1105072019",
    "district_id": "110507",
    "label": "Suak Ie Beuso",
    "value": "Suak Ie Beuso"
  },
  {
    "id": "1105072020",
    "district_id": "110507",
    "label": "Suak Bidok",
    "value": "Suak Bidok"
  },
  {
    "id": "1105072021",
    "district_id": "110507",
    "label": "Simpang peut",
    "value": "Simpang peut"
  },
  {
    "id": "1105072022",
    "district_id": "110507",
    "label": "Seuneubok Teungoh",
    "value": "Seuneubok Teungoh"
  },
  {
    "id": "1105072023",
    "district_id": "110507",
    "label": "Teupin Peraho",
    "value": "Teupin Peraho"
  },
  {
    "id": "1105072024",
    "district_id": "110507",
    "label": "Ujong Beusa",
    "value": "Ujong Beusa"
  },
  {
    "id": "1105072025",
    "district_id": "110507",
    "label": "Ujong Simpang",
    "value": "Ujong Simpang"
  },
  {
    "id": "1105072026",
    "district_id": "110507",
    "label": "Gunong Pulo",
    "value": "Gunong Pulo"
  },
  {
    "id": "1105072027",
    "district_id": "110507",
    "label": "Karang Hampa",
    "value": "Karang Hampa"
  },
  {
    "id": "1105082001",
    "district_id": "110508",
    "label": "Seumara",
    "value": "Seumara"
  },
  {
    "id": "1105082002",
    "district_id": "110508",
    "label": "Babah Leung",
    "value": "Babah Leung"
  },
  {
    "id": "1105082003",
    "district_id": "110508",
    "label": "Babah Iseung",
    "value": "Babah Iseung"
  },
  {
    "id": "1105082004",
    "district_id": "110508",
    "label": "Seumantok",
    "value": "Seumantok"
  },
  {
    "id": "1105082005",
    "district_id": "110508",
    "label": "Alue Keumang",
    "value": "Alue Keumang"
  },
  {
    "id": "1105082006",
    "district_id": "110508",
    "label": "Meunuang Kinco",
    "value": "Meunuang Kinco"
  },
  {
    "id": "1105082007",
    "district_id": "110508",
    "label": "Lango",
    "value": "Lango"
  },
  {
    "id": "1105082008",
    "district_id": "110508",
    "label": "Sikundo",
    "value": "Sikundo"
  },
  {
    "id": "1105082009",
    "district_id": "110508",
    "label": "Lawet",
    "value": "Lawet"
  },
  {
    "id": "1105082010",
    "district_id": "110508",
    "label": "Canggai",
    "value": "Canggai"
  },
  {
    "id": "1105082011",
    "district_id": "110508",
    "label": "Manjeng",
    "value": "Manjeng"
  },
  {
    "id": "1105082012",
    "district_id": "110508",
    "label": "Keutambang",
    "value": "Keutambang"
  },
  {
    "id": "1105082013",
    "district_id": "110508",
    "label": "Pante Ceuremen",
    "value": "Pante Ceuremen"
  },
  {
    "id": "1105082014",
    "district_id": "110508",
    "label": "Pulo Teungoh Manjeng",
    "value": "Pulo Teungoh Manjeng"
  },
  {
    "id": "1105082015",
    "district_id": "110508",
    "label": "Jambak",
    "value": "Jambak"
  },
  {
    "id": "1105082016",
    "district_id": "110508",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1105082017",
    "district_id": "110508",
    "label": "Gunong Tarok",
    "value": "Gunong Tarok"
  },
  {
    "id": "1105082018",
    "district_id": "110508",
    "label": "Suak Awe",
    "value": "Suak Awe"
  },
  {
    "id": "1105082019",
    "district_id": "110508",
    "label": "Kreung Beukah",
    "value": "Kreung Beukah"
  },
  {
    "id": "1105082020",
    "district_id": "110508",
    "label": "Lhok Sari",
    "value": "Lhok Sari"
  },
  {
    "id": "1105082021",
    "district_id": "110508",
    "label": "Babah Krueng Tep Lep",
    "value": "Babah Krueng Tep Lep"
  },
  {
    "id": "1105082022",
    "district_id": "110508",
    "label": "Berdikari",
    "value": "Berdikari"
  },
  {
    "id": "1105082023",
    "district_id": "110508",
    "label": "Lhok Guci",
    "value": "Lhok Guci"
  },
  {
    "id": "1105082024",
    "district_id": "110508",
    "label": "Keude Suak Awe",
    "value": "Keude Suak Awe"
  },
  {
    "id": "1105082025",
    "district_id": "110508",
    "label": "Sawang Rambot",
    "value": "Sawang Rambot"
  },
  {
    "id": "1105092001",
    "district_id": "110509",
    "label": "Ranto Panyang Barat",
    "value": "Ranto Panyang Barat"
  },
  {
    "id": "1105092002",
    "district_id": "110509",
    "label": "Ranto Panyang Timur",
    "value": "Ranto Panyang Timur"
  },
  {
    "id": "1105092003",
    "district_id": "110509",
    "label": "Mesjid Tuha",
    "value": "Mesjid Tuha"
  },
  {
    "id": "1105092004",
    "district_id": "110509",
    "label": "Ujong Tanoh Darat",
    "value": "Ujong Tanoh Darat"
  },
  {
    "id": "1105092005",
    "district_id": "110509",
    "label": "Pasi Aceh. Tunong",
    "value": "Pasi Aceh. Tunong"
  },
  {
    "id": "1105092006",
    "district_id": "110509",
    "label": "Pasi Aceh Baroh",
    "value": "Pasi Aceh Baroh"
  },
  {
    "id": "1105092007",
    "district_id": "110509",
    "label": "Buloh",
    "value": "Buloh"
  },
  {
    "id": "1105092008",
    "district_id": "110509",
    "label": "PuloTeungoh Ranto P.",
    "value": "PuloTeungoh Ranto P."
  },
  {
    "id": "1105092009",
    "district_id": "110509",
    "label": "Paya Baro Ranto P",
    "value": "Paya Baro Ranto P"
  },
  {
    "id": "1105092010",
    "district_id": "110509",
    "label": "Ranub Dong",
    "value": "Ranub Dong"
  },
  {
    "id": "1105092011",
    "district_id": "110509",
    "label": "Pasi Pinang",
    "value": "Pasi Pinang"
  },
  {
    "id": "1105092012",
    "district_id": "110509",
    "label": "Pasi Mesjid",
    "value": "Pasi Mesjid"
  },
  {
    "id": "1105092013",
    "district_id": "110509",
    "label": "Meureubo",
    "value": "Meureubo"
  },
  {
    "id": "1105092014",
    "district_id": "110509",
    "label": "Langung",
    "value": "Langung"
  },
  {
    "id": "1105092015",
    "district_id": "110509",
    "label": "Gunong Kleng",
    "value": "Gunong Kleng"
  },
  {
    "id": "1105092016",
    "district_id": "110509",
    "label": "Ujong Drien",
    "value": "Ujong Drien"
  },
  {
    "id": "1105092017",
    "district_id": "110509",
    "label": "Peunaga Rayeuk",
    "value": "Peunaga Rayeuk"
  },
  {
    "id": "1105092018",
    "district_id": "110509",
    "label": "Peunaga Pasi",
    "value": "Peunaga Pasi"
  },
  {
    "id": "1105092019",
    "district_id": "110509",
    "label": "Peunaga Cut Ujong",
    "value": "Peunaga Cut Ujong"
  },
  {
    "id": "1105092020",
    "district_id": "110509",
    "label": "Reudeup",
    "value": "Reudeup"
  },
  {
    "id": "1105092021",
    "district_id": "110509",
    "label": "Balee",
    "value": "Balee"
  },
  {
    "id": "1105092022",
    "district_id": "110509",
    "label": "Paya Peunaga",
    "value": "Paya Peunaga"
  },
  {
    "id": "1105092023",
    "district_id": "110509",
    "label": "Ujong Tanjung",
    "value": "Ujong Tanjung"
  },
  {
    "id": "1105092024",
    "district_id": "110509",
    "label": "Pucok Reudeup",
    "value": "Pucok Reudeup"
  },
  {
    "id": "1105092025",
    "district_id": "110509",
    "label": "Sumber Batu",
    "value": "Sumber Batu"
  },
  {
    "id": "1105092026",
    "district_id": "110509",
    "label": "Bakit Jaya",
    "value": "Bakit Jaya"
  },
  {
    "id": "1105102001",
    "district_id": "110510",
    "label": "Blang Luah LM",
    "value": "Blang Luah LM"
  },
  {
    "id": "1105102002",
    "district_id": "110510",
    "label": "Cot Rambong",
    "value": "Cot Rambong"
  },
  {
    "id": "1105102003",
    "district_id": "110510",
    "label": "Lhok Malee",
    "value": "Lhok Malee"
  },
  {
    "id": "1105102004",
    "district_id": "110510",
    "label": "Pasi Malee",
    "value": "Pasi Malee"
  },
  {
    "id": "1105102005",
    "district_id": "110510",
    "label": "Napai",
    "value": "Napai"
  },
  {
    "id": "1105102006",
    "district_id": "110510",
    "label": "Blang Cot Mameh",
    "value": "Blang Cot Mameh"
  },
  {
    "id": "1105102007",
    "district_id": "110510",
    "label": "Blang Cot Rubek",
    "value": "Blang Cot Rubek"
  },
  {
    "id": "1105102008",
    "district_id": "110510",
    "label": "Alue Leuhop",
    "value": "Alue Leuhop"
  },
  {
    "id": "1105102009",
    "district_id": "110510",
    "label": "Lubuk Pasi Ara",
    "value": "Lubuk Pasi Ara"
  },
  {
    "id": "1105102010",
    "district_id": "110510",
    "label": "Ulei Pasi Ara",
    "value": "Ulei Pasi Ara"
  },
  {
    "id": "1105102011",
    "district_id": "110510",
    "label": "Cot Lagan LM",
    "value": "Cot Lagan LM"
  },
  {
    "id": "1105102012",
    "district_id": "110510",
    "label": "Lueng Baro",
    "value": "Lueng Baro"
  },
  {
    "id": "1105102013",
    "district_id": "110510",
    "label": "Kulam Kaju",
    "value": "Kulam Kaju"
  },
  {
    "id": "1105102014",
    "district_id": "110510",
    "label": "Alue Permen",
    "value": "Alue Permen"
  },
  {
    "id": "1105102015",
    "district_id": "110510",
    "label": "Peuleukueng",
    "value": "Peuleukueng"
  },
  {
    "id": "1105102016",
    "district_id": "110510",
    "label": "Pasi Jeut",
    "value": "Pasi Jeut"
  },
  {
    "id": "1105102017",
    "district_id": "110510",
    "label": "Mon Pasong",
    "value": "Mon Pasong"
  },
  {
    "id": "1105102018",
    "district_id": "110510",
    "label": "Pasi Mali",
    "value": "Pasi Mali"
  },
  {
    "id": "1105102019",
    "district_id": "110510",
    "label": "Karak",
    "value": "Karak"
  },
  {
    "id": "1105102020",
    "district_id": "110510",
    "label": "Ie Sayang",
    "value": "Ie Sayang"
  },
  {
    "id": "1105102021",
    "district_id": "110510",
    "label": "Ule Pulo",
    "value": "Ule Pulo"
  },
  {
    "id": "1105102022",
    "district_id": "110510",
    "label": "Pasi Panyang",
    "value": "Pasi Panyang"
  },
  {
    "id": "1105102023",
    "district_id": "110510",
    "label": "Alue Keumuning",
    "value": "Alue Keumuning"
  },
  {
    "id": "1105102024",
    "district_id": "110510",
    "label": "Simpang Keumaron",
    "value": "Simpang Keumaron"
  },
  {
    "id": "1105112001",
    "district_id": "110511",
    "label": "Seuradek",
    "value": "Seuradek"
  },
  {
    "id": "1105112002",
    "district_id": "110511",
    "label": "Lubuk Panyang",
    "value": "Lubuk Panyang"
  },
  {
    "id": "1105112003",
    "district_id": "110511",
    "label": "Paya Baro",
    "value": "Paya Baro"
  },
  {
    "id": "1105112004",
    "district_id": "110511",
    "label": "Kubu Capang",
    "value": "Kubu Capang"
  },
  {
    "id": "1105112005",
    "district_id": "110511",
    "label": "Pasi Ara WT",
    "value": "Pasi Ara WT"
  },
  {
    "id": "1105112006",
    "district_id": "110511",
    "label": "Gampong Baro WT",
    "value": "Gampong Baro WT"
  },
  {
    "id": "1105112007",
    "district_id": "110511",
    "label": "Rambong",
    "value": "Rambong"
  },
  {
    "id": "1105112008",
    "district_id": "110511",
    "label": "Cot Punti",
    "value": "Cot Punti"
  },
  {
    "id": "1105112009",
    "district_id": "110511",
    "label": "Alue Eumpeuk",
    "value": "Alue Eumpeuk"
  },
  {
    "id": "1105112010",
    "district_id": "110511",
    "label": "Alue Bilie",
    "value": "Alue Bilie"
  },
  {
    "id": "1105112011",
    "district_id": "110511",
    "label": "Pasi Janeng",
    "value": "Pasi Janeng"
  },
  {
    "id": "1105112012",
    "district_id": "110511",
    "label": "Tangkeh",
    "value": "Tangkeh"
  },
  {
    "id": "1105112013",
    "district_id": "110511",
    "label": "Alue Kuyun",
    "value": "Alue Kuyun"
  },
  {
    "id": "1105112014",
    "district_id": "110511",
    "label": "Bukit Meugajah",
    "value": "Bukit Meugajah"
  },
  {
    "id": "1105112015",
    "district_id": "110511",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1105112016",
    "district_id": "110511",
    "label": "Teumiket Ranom",
    "value": "Teumiket Ranom"
  },
  {
    "id": "1105112017",
    "district_id": "110511",
    "label": "Blang Makmue",
    "value": "Blang Makmue"
  },
  {
    "id": "1105112018",
    "district_id": "110511",
    "label": "Gampong Baro KB",
    "value": "Gampong Baro KB"
  },
  {
    "id": "1105112019",
    "district_id": "110511",
    "label": "Alue Meuganda",
    "value": "Alue Meuganda"
  },
  {
    "id": "1105112020",
    "district_id": "110511",
    "label": "Alue Seuralen",
    "value": "Alue Seuralen"
  },
  {
    "id": "1105112021",
    "district_id": "110511",
    "label": "Rambong Pinto",
    "value": "Rambong Pinto"
  },
  {
    "id": "1105112022",
    "district_id": "110511",
    "label": "Tuwi Eumpeuk",
    "value": "Tuwi Eumpeuk"
  },
  {
    "id": "1105112023",
    "district_id": "110511",
    "label": "Blang Luah KB",
    "value": "Blang Luah KB"
  },
  {
    "id": "1105112024",
    "district_id": "110511",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1105112025",
    "district_id": "110511",
    "label": "Paya Meugenderang",
    "value": "Paya Meugenderang"
  },
  {
    "id": "1105112026",
    "district_id": "110511",
    "label": "Gunong Payang",
    "value": "Gunong Payang"
  },
  {
    "id": "1105122001",
    "district_id": "110512",
    "label": "Mentulang",
    "value": "Mentulang"
  },
  {
    "id": "1105122002",
    "district_id": "110512",
    "label": "Mugo Cut",
    "value": "Mugo Cut"
  },
  {
    "id": "1105122003",
    "district_id": "110512",
    "label": "Mugo Rayeuk",
    "value": "Mugo Rayeuk"
  },
  {
    "id": "1105122004",
    "district_id": "110512",
    "label": "Baro Paya",
    "value": "Baro Paya"
  },
  {
    "id": "1105122005",
    "district_id": "110512",
    "label": "Sibintang",
    "value": "Sibintang"
  },
  {
    "id": "1105122006",
    "district_id": "110512",
    "label": "Blang Teungoh",
    "value": "Blang Teungoh"
  },
  {
    "id": "1105122007",
    "district_id": "110512",
    "label": "Antong",
    "value": "Antong"
  },
  {
    "id": "1105122008",
    "district_id": "110512",
    "label": "Ujong Raja",
    "value": "Ujong Raja"
  },
  {
    "id": "1105122009",
    "district_id": "110512",
    "label": "Paya Baro Meuko",
    "value": "Paya Baro Meuko"
  },
  {
    "id": "1105122010",
    "district_id": "110512",
    "label": "Tuwi Buya",
    "value": "Tuwi Buya"
  },
  {
    "id": "1105122011",
    "district_id": "110512",
    "label": "Kuala Manyeue",
    "value": "Kuala Manyeue"
  },
  {
    "id": "1105122012",
    "district_id": "110512",
    "label": "Manggie",
    "value": "Manggie"
  },
  {
    "id": "1105122013",
    "district_id": "110512",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1105122014",
    "district_id": "110512",
    "label": "Tamping",
    "value": "Tamping"
  },
  {
    "id": "1105122015",
    "district_id": "110512",
    "label": "Cot Manggie",
    "value": "Cot Manggie"
  },
  {
    "id": "1105122016",
    "district_id": "110512",
    "label": "Blang Balee",
    "value": "Blang Balee"
  },
  {
    "id": "1105122017",
    "district_id": "110512",
    "label": "Babah Krueng Manggie",
    "value": "Babah Krueng Manggie"
  },
  {
    "id": "1105122018",
    "district_id": "110512",
    "label": "Gunong Mata Ie",
    "value": "Gunong Mata Ie"
  },
  {
    "id": "1105122019",
    "district_id": "110512",
    "label": "Lek-Lek",
    "value": "Lek-Lek"
  },
  {
    "id": "1106012001",
    "district_id": "110601",
    "label": "Meunasah Krueng Kala",
    "value": "Meunasah Krueng Kala"
  },
  {
    "id": "1106012002",
    "district_id": "110601",
    "label": "Jantang",
    "value": "Jantang"
  },
  {
    "id": "1106012003",
    "district_id": "110601",
    "label": "Baroh Krueng Kala",
    "value": "Baroh Krueng Kala"
  },
  {
    "id": "1106012004",
    "district_id": "110601",
    "label": "Tunong Krueng Kala",
    "value": "Tunong Krueng Kala"
  },
  {
    "id": "1106012005",
    "district_id": "110601",
    "label": "Sungko Mulat",
    "value": "Sungko Mulat"
  },
  {
    "id": "1106012006",
    "district_id": "110601",
    "label": "Birek",
    "value": "Birek"
  },
  {
    "id": "1106012007",
    "district_id": "110601",
    "label": "Kareung",
    "value": "Kareung"
  },
  {
    "id": "1106012008",
    "district_id": "110601",
    "label": "Paroy",
    "value": "Paroy"
  },
  {
    "id": "1106012009",
    "district_id": "110601",
    "label": "Teungoh Blang Mee",
    "value": "Teungoh Blang Mee"
  },
  {
    "id": "1106012010",
    "district_id": "110601",
    "label": "Lamkuta Blang Mee",
    "value": "Lamkuta Blang Mee"
  },
  {
    "id": "1106012011",
    "district_id": "110601",
    "label": "Baroh Blang Mee",
    "value": "Baroh Blang Mee"
  },
  {
    "id": "1106012012",
    "district_id": "110601",
    "label": "Umong Seuribee",
    "value": "Umong Seuribee"
  },
  {
    "id": "1106012013",
    "district_id": "110601",
    "label": "Teungoh Geunteut",
    "value": "Teungoh Geunteut"
  },
  {
    "id": "1106012014",
    "district_id": "110601",
    "label": "Baroh Geunteut",
    "value": "Baroh Geunteut"
  },
  {
    "id": "1106012015",
    "district_id": "110601",
    "label": "Lam Juhang",
    "value": "Lam Juhang"
  },
  {
    "id": "1106012016",
    "district_id": "110601",
    "label": "Utamong",
    "value": "Utamong"
  },
  {
    "id": "1106012017",
    "district_id": "110601",
    "label": "Gapuy",
    "value": "Gapuy"
  },
  {
    "id": "1106012018",
    "district_id": "110601",
    "label": "Lamsujen",
    "value": "Lamsujen"
  },
  {
    "id": "1106012019",
    "district_id": "110601",
    "label": "Mon Mata",
    "value": "Mon Mata"
  },
  {
    "id": "1106012020",
    "district_id": "110601",
    "label": "Lamgeuriheu",
    "value": "Lamgeuriheu"
  },
  {
    "id": "1106012021",
    "district_id": "110601",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1106012022",
    "district_id": "110601",
    "label": "Saney",
    "value": "Saney"
  },
  {
    "id": "1106012023",
    "district_id": "110601",
    "label": "Cundien",
    "value": "Cundien"
  },
  {
    "id": "1106012024",
    "district_id": "110601",
    "label": "Glee Bruek",
    "value": "Glee Bruek"
  },
  {
    "id": "1106012025",
    "district_id": "110601",
    "label": "Pasi",
    "value": "Pasi"
  },
  {
    "id": "1106012026",
    "district_id": "110601",
    "label": "Pudeng",
    "value": "Pudeng"
  },
  {
    "id": "1106012027",
    "district_id": "110601",
    "label": "Meunasah Cot",
    "value": "Meunasah Cot"
  },
  {
    "id": "1106012028",
    "district_id": "110601",
    "label": "Meunasah Lhok",
    "value": "Meunasah Lhok"
  },
  {
    "id": "1106022001",
    "district_id": "110602",
    "label": "Mon Ikeun",
    "value": "Mon Ikeun"
  },
  {
    "id": "1106022002",
    "district_id": "110602",
    "label": "Nusa",
    "value": "Nusa"
  },
  {
    "id": "1106022003",
    "district_id": "110602",
    "label": "Weu Raya",
    "value": "Weu Raya"
  },
  {
    "id": "1106022004",
    "district_id": "110602",
    "label": "Lamkrut",
    "value": "Lamkrut"
  },
  {
    "id": "1106022005",
    "district_id": "110602",
    "label": "Lamgaboh",
    "value": "Lamgaboh"
  },
  {
    "id": "1106022006",
    "district_id": "110602",
    "label": "Lambaro Kueh",
    "value": "Lambaro Kueh"
  },
  {
    "id": "1106022007",
    "district_id": "110602",
    "label": "Lam Ateuk",
    "value": "Lam Ateuk"
  },
  {
    "id": "1106022008",
    "district_id": "110602",
    "label": "Kueh",
    "value": "Kueh"
  },
  {
    "id": "1106022009",
    "district_id": "110602",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "1106022010",
    "district_id": "110602",
    "label": "Aneuk Paya",
    "value": "Aneuk Paya"
  },
  {
    "id": "1106022011",
    "district_id": "110602",
    "label": "Seubun Keutapang",
    "value": "Seubun Keutapang"
  },
  {
    "id": "1106022012",
    "district_id": "110602",
    "label": "Seubun Ayon",
    "value": "Seubun Ayon"
  },
  {
    "id": "1106022013",
    "district_id": "110602",
    "label": "Lambaro Seubun",
    "value": "Lambaro Seubun"
  },
  {
    "id": "1106022014",
    "district_id": "110602",
    "label": "Naga Umbang",
    "value": "Naga Umbang"
  },
  {
    "id": "1106022015",
    "district_id": "110602",
    "label": "Lampaya",
    "value": "Lampaya"
  },
  {
    "id": "1106022016",
    "district_id": "110602",
    "label": "Meunasah Mesjid Lamlhom",
    "value": "Meunasah Mesjid Lamlhom"
  },
  {
    "id": "1106022017",
    "district_id": "110602",
    "label": "Meunasah Mon Cut",
    "value": "Meunasah Mon Cut"
  },
  {
    "id": "1106022018",
    "district_id": "110602",
    "label": "Meunasah Manyang",
    "value": "Meunasah Manyang"
  },
  {
    "id": "1106022019",
    "district_id": "110602",
    "label": "Meunasah Lam Girek",
    "value": "Meunasah Lam Girek"
  },
  {
    "id": "1106022020",
    "district_id": "110602",
    "label": "Meunasah Lambaro",
    "value": "Meunasah Lambaro"
  },
  {
    "id": "1106022021",
    "district_id": "110602",
    "label": "Meunasah Baro Lamlhom",
    "value": "Meunasah Baro Lamlhom"
  },
  {
    "id": "1106022022",
    "district_id": "110602",
    "label": "Meunasah Beutong",
    "value": "Meunasah Beutong"
  },
  {
    "id": "1106022023",
    "district_id": "110602",
    "label": "Mns. Mesjid Lampuuk",
    "value": "Mns. Mesjid Lampuuk"
  },
  {
    "id": "1106022024",
    "district_id": "110602",
    "label": "Meunasah Balee",
    "value": "Meunasah Balee"
  },
  {
    "id": "1106022025",
    "district_id": "110602",
    "label": "Meunasah Karieng",
    "value": "Meunasah Karieng"
  },
  {
    "id": "1106022026",
    "district_id": "110602",
    "label": "Lamcok",
    "value": "Lamcok"
  },
  {
    "id": "1106022027",
    "district_id": "110602",
    "label": "Meunasah Cut",
    "value": "Meunasah Cut"
  },
  {
    "id": "1106022028",
    "district_id": "110602",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1106032001",
    "district_id": "110603",
    "label": "Reukih Dayah",
    "value": "Reukih Dayah"
  },
  {
    "id": "1106032002",
    "district_id": "110603",
    "label": "Indrapuri",
    "value": "Indrapuri"
  },
  {
    "id": "1106032003",
    "district_id": "110603",
    "label": "Lam Leubok",
    "value": "Lam Leubok"
  },
  {
    "id": "1106032004",
    "district_id": "110603",
    "label": "Empee Ara",
    "value": "Empee Ara"
  },
  {
    "id": "1106032005",
    "district_id": "110603",
    "label": "Limo Blang",
    "value": "Limo Blang"
  },
  {
    "id": "1106032006",
    "district_id": "110603",
    "label": "Limo Mesjid",
    "value": "Limo Mesjid"
  },
  {
    "id": "1106032007",
    "district_id": "110603",
    "label": "Limo Lamleuweung",
    "value": "Limo Lamleuweung"
  },
  {
    "id": "1106032008",
    "district_id": "110603",
    "label": "Lheue",
    "value": "Lheue"
  },
  {
    "id": "1106032009",
    "district_id": "110603",
    "label": "Meusale",
    "value": "Meusale"
  },
  {
    "id": "1106032010",
    "district_id": "110603",
    "label": "Cureh",
    "value": "Cureh"
  },
  {
    "id": "1106032011",
    "district_id": "110603",
    "label": "Cot Kareung",
    "value": "Cot Kareung"
  },
  {
    "id": "1106032012",
    "district_id": "110603",
    "label": "Lamlung",
    "value": "Lamlung"
  },
  {
    "id": "1106032013",
    "district_id": "110603",
    "label": "Meureu Ulee Titie",
    "value": "Meureu Ulee Titie"
  },
  {
    "id": "1106032014",
    "district_id": "110603",
    "label": "Meureu Bueng-U",
    "value": "Meureu Bueng-U"
  },
  {
    "id": "1106032015",
    "district_id": "110603",
    "label": "Mureu Baro",
    "value": "Mureu Baro"
  },
  {
    "id": "1106032016",
    "district_id": "110603",
    "label": "Meureu Lamglumpang",
    "value": "Meureu Lamglumpang"
  },
  {
    "id": "1106032017",
    "district_id": "110603",
    "label": "Pasar Indrapuri",
    "value": "Pasar Indrapuri"
  },
  {
    "id": "1106032018",
    "district_id": "110603",
    "label": "Manggra",
    "value": "Manggra"
  },
  {
    "id": "1106032019",
    "district_id": "110603",
    "label": "Reukih Kupula",
    "value": "Reukih Kupula"
  },
  {
    "id": "1106032020",
    "district_id": "110603",
    "label": "Lam Ilie Mesjid",
    "value": "Lam Ilie Mesjid"
  },
  {
    "id": "1106032021",
    "district_id": "110603",
    "label": "Lam Ilie Tengoh",
    "value": "Lam Ilie Tengoh"
  },
  {
    "id": "1106032022",
    "district_id": "110603",
    "label": "Lam Ilie Ganto",
    "value": "Lam Ilie Ganto"
  },
  {
    "id": "1106032023",
    "district_id": "110603",
    "label": "Meunara",
    "value": "Meunara"
  },
  {
    "id": "1106032024",
    "district_id": "110603",
    "label": "Aneuk Glee",
    "value": "Aneuk Glee"
  },
  {
    "id": "1106032025",
    "district_id": "110603",
    "label": "Krueng Lamkareung",
    "value": "Krueng Lamkareung"
  },
  {
    "id": "1106032026",
    "district_id": "110603",
    "label": "Sinyeu",
    "value": "Sinyeu"
  },
  {
    "id": "1106032027",
    "district_id": "110603",
    "label": "Seureumo",
    "value": "Seureumo"
  },
  {
    "id": "1106032028",
    "district_id": "110603",
    "label": "Ulee Kareung",
    "value": "Ulee Kareung"
  },
  {
    "id": "1106032029",
    "district_id": "110603",
    "label": "Lambeutong",
    "value": "Lambeutong"
  },
  {
    "id": "1106032030",
    "district_id": "110603",
    "label": "Lampupok Baro",
    "value": "Lampupok Baro"
  },
  {
    "id": "1106032031",
    "district_id": "110603",
    "label": "Lampupok Raya",
    "value": "Lampupok Raya"
  },
  {
    "id": "1106032032",
    "district_id": "110603",
    "label": "Seulangai",
    "value": "Seulangai"
  },
  {
    "id": "1106032033",
    "district_id": "110603",
    "label": "Riting",
    "value": "Riting"
  },
  {
    "id": "1106032034",
    "district_id": "110603",
    "label": "Sihom Lhok",
    "value": "Sihom Lhok"
  },
  {
    "id": "1106032035",
    "district_id": "110603",
    "label": "Sihom Cot",
    "value": "Sihom Cot"
  },
  {
    "id": "1106032036",
    "district_id": "110603",
    "label": "Seuot Baroh",
    "value": "Seuot Baroh"
  },
  {
    "id": "1106032037",
    "district_id": "110603",
    "label": "Seuot Tunong",
    "value": "Seuot Tunong"
  },
  {
    "id": "1106032038",
    "district_id": "110603",
    "label": "Lampanah Ranjo",
    "value": "Lampanah Ranjo"
  },
  {
    "id": "1106032039",
    "district_id": "110603",
    "label": "Lampanah Teungoh",
    "value": "Lampanah Teungoh"
  },
  {
    "id": "1106032040",
    "district_id": "110603",
    "label": "Lampanah Dayah",
    "value": "Lampanah Dayah"
  },
  {
    "id": "1106032041",
    "district_id": "110603",
    "label": "Lampanah Baro",
    "value": "Lampanah Baro"
  },
  {
    "id": "1106032042",
    "district_id": "110603",
    "label": "Lampanah Tunong",
    "value": "Lampanah Tunong"
  },
  {
    "id": "1106032043",
    "district_id": "110603",
    "label": "Groet Blang",
    "value": "Groet Blang"
  },
  {
    "id": "1106032044",
    "district_id": "110603",
    "label": "Groet Manyang",
    "value": "Groet Manyang"
  },
  {
    "id": "1106032045",
    "district_id": "110603",
    "label": "Groet Baro",
    "value": "Groet Baro"
  },
  {
    "id": "1106032046",
    "district_id": "110603",
    "label": "Jruek Bak Kreh",
    "value": "Jruek Bak Kreh"
  },
  {
    "id": "1106032047",
    "district_id": "110603",
    "label": "Jruek Balee",
    "value": "Jruek Balee"
  },
  {
    "id": "1106032049",
    "district_id": "110603",
    "label": "Mon Alue",
    "value": "Mon Alue"
  },
  {
    "id": "1106032050",
    "district_id": "110603",
    "label": "Lambeunot",
    "value": "Lambeunot"
  },
  {
    "id": "1106032051",
    "district_id": "110603",
    "label": "Ulee Ue",
    "value": "Ulee Ue"
  },
  {
    "id": "1106032052",
    "district_id": "110603",
    "label": "Lamsiot",
    "value": "Lamsiot"
  },
  {
    "id": "1106032053",
    "district_id": "110603",
    "label": "Lingom",
    "value": "Lingom"
  },
  {
    "id": "1106042001",
    "district_id": "110604",
    "label": "Peukan Seulimeum",
    "value": "Peukan Seulimeum"
  },
  {
    "id": "1106042002",
    "district_id": "110604",
    "label": "Keunaloi",
    "value": "Keunaloi"
  },
  {
    "id": "1106042003",
    "district_id": "110604",
    "label": "Lhieb",
    "value": "Lhieb"
  },
  {
    "id": "1106042004",
    "district_id": "110604",
    "label": "Alue Gintong",
    "value": "Alue Gintong"
  },
  {
    "id": "1106042005",
    "district_id": "110604",
    "label": "Gampong Seulimeum",
    "value": "Gampong Seulimeum"
  },
  {
    "id": "1106042006",
    "district_id": "110604",
    "label": "Data Gaseu",
    "value": "Data Gaseu"
  },
  {
    "id": "1106042007",
    "district_id": "110604",
    "label": "Rabo",
    "value": "Rabo"
  },
  {
    "id": "1106042008",
    "district_id": "110604",
    "label": "Gampong Raya",
    "value": "Gampong Raya"
  },
  {
    "id": "1106042009",
    "district_id": "110604",
    "label": "Lamjruen",
    "value": "Lamjruen"
  },
  {
    "id": "1106042010",
    "district_id": "110604",
    "label": "Buga",
    "value": "Buga"
  },
  {
    "id": "1106042011",
    "district_id": "110604",
    "label": "Jawie",
    "value": "Jawie"
  },
  {
    "id": "1106042012",
    "district_id": "110604",
    "label": "Alue Rindang",
    "value": "Alue Rindang"
  },
  {
    "id": "1106042013",
    "district_id": "110604",
    "label": "Iboh Tunong",
    "value": "Iboh Tunong"
  },
  {
    "id": "1106042014",
    "district_id": "110604",
    "label": "Iboh Tanjong",
    "value": "Iboh Tanjong"
  },
  {
    "id": "1106042015",
    "district_id": "110604",
    "label": "Seuneubok",
    "value": "Seuneubok"
  },
  {
    "id": "1106042016",
    "district_id": "110604",
    "label": "Meunasah Baro",
    "value": "Meunasah Baro"
  },
  {
    "id": "1106042017",
    "district_id": "110604",
    "label": "Meunasah Tunong",
    "value": "Meunasah Tunong"
  },
  {
    "id": "1106042018",
    "district_id": "110604",
    "label": "Batee Lhee",
    "value": "Batee Lhee"
  },
  {
    "id": "1106042019",
    "district_id": "110604",
    "label": "Mangeu",
    "value": "Mangeu"
  },
  {
    "id": "1106042020",
    "district_id": "110604",
    "label": "Bayu",
    "value": "Bayu"
  },
  {
    "id": "1106042021",
    "district_id": "110604",
    "label": "Ayon",
    "value": "Ayon"
  },
  {
    "id": "1106042022",
    "district_id": "110604",
    "label": "Lamteuba Droe",
    "value": "Lamteuba Droe"
  },
  {
    "id": "1106042023",
    "district_id": "110604",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "1106042024",
    "district_id": "110604",
    "label": "Lampantee",
    "value": "Lampantee"
  },
  {
    "id": "1106042025",
    "district_id": "110604",
    "label": "Lambada",
    "value": "Lambada"
  },
  {
    "id": "1106042026",
    "district_id": "110604",
    "label": "Blang Tingkeum",
    "value": "Blang Tingkeum"
  },
  {
    "id": "1106042027",
    "district_id": "110604",
    "label": "Ateuk",
    "value": "Ateuk"
  },
  {
    "id": "1106042028",
    "district_id": "110604",
    "label": "Lam Apeng",
    "value": "Lam Apeng"
  },
  {
    "id": "1106042029",
    "district_id": "110604",
    "label": "Meurah",
    "value": "Meurah"
  },
  {
    "id": "1106042030",
    "district_id": "110604",
    "label": "Lampanah",
    "value": "Lampanah"
  },
  {
    "id": "1106042031",
    "district_id": "110604",
    "label": "Ujong Mesjid",
    "value": "Ujong Mesjid"
  },
  {
    "id": "1106042032",
    "district_id": "110604",
    "label": "Ujong Keupula",
    "value": "Ujong Keupula"
  },
  {
    "id": "1106042033",
    "district_id": "110604",
    "label": "Leungah",
    "value": "Leungah"
  },
  {
    "id": "1106042034",
    "district_id": "110604",
    "label": "Beureunut",
    "value": "Beureunut"
  },
  {
    "id": "1106042035",
    "district_id": "110604",
    "label": "Meunasah Jeumpa",
    "value": "Meunasah Jeumpa"
  },
  {
    "id": "1106042036",
    "district_id": "110604",
    "label": "Pinto Khop",
    "value": "Pinto Khop"
  },
  {
    "id": "1106042037",
    "district_id": "110604",
    "label": "Bak Seutui",
    "value": "Bak Seutui"
  },
  {
    "id": "1106042038",
    "district_id": "110604",
    "label": "Bak Aghu",
    "value": "Bak Aghu"
  },
  {
    "id": "1106042039",
    "district_id": "110604",
    "label": "Kayee Adang",
    "value": "Kayee Adang"
  },
  {
    "id": "1106042040",
    "district_id": "110604",
    "label": "Lamkuk",
    "value": "Lamkuk"
  },
  {
    "id": "1106042041",
    "district_id": "110604",
    "label": "Ujong Mesjid Tanoh Abee",
    "value": "Ujong Mesjid Tanoh Abee"
  },
  {
    "id": "1106042042",
    "district_id": "110604",
    "label": "Lamcarak",
    "value": "Lamcarak"
  },
  {
    "id": "1106042043",
    "district_id": "110604",
    "label": "Capeung Dayah",
    "value": "Capeung Dayah"
  },
  {
    "id": "1106042044",
    "district_id": "110604",
    "label": "Capeung Baroh",
    "value": "Capeung Baroh"
  },
  {
    "id": "1106042045",
    "district_id": "110604",
    "label": "Lampisang Dayah",
    "value": "Lampisang Dayah"
  },
  {
    "id": "1106042046",
    "district_id": "110604",
    "label": "Lampisang Tunong",
    "value": "Lampisang Tunong"
  },
  {
    "id": "1106042047",
    "district_id": "110604",
    "label": "Lampisang Teungoh",
    "value": "Lampisang Teungoh"
  },
  {
    "id": "1106052001",
    "district_id": "110605",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1106052002",
    "district_id": "110605",
    "label": "Gampong Baroh",
    "value": "Gampong Baroh"
  },
  {
    "id": "1106052003",
    "district_id": "110605",
    "label": "Lampaseh Lhok",
    "value": "Lampaseh Lhok"
  },
  {
    "id": "1106052004",
    "district_id": "110605",
    "label": "Lampaseh Krueng",
    "value": "Lampaseh Krueng"
  },
  {
    "id": "1106052005",
    "district_id": "110605",
    "label": "Lamnga",
    "value": "Lamnga"
  },
  {
    "id": "1106052006",
    "district_id": "110605",
    "label": "Dayah Daboh",
    "value": "Dayah Daboh"
  },
  {
    "id": "1106052007",
    "district_id": "110605",
    "label": "Weulhok",
    "value": "Weulhok"
  },
  {
    "id": "1106052008",
    "district_id": "110605",
    "label": "Kp. Alue",
    "value": "Kp. Alue"
  },
  {
    "id": "1106052009",
    "district_id": "110605",
    "label": "Weu Krueng",
    "value": "Weu Krueng"
  },
  {
    "id": "1106052010",
    "district_id": "110605",
    "label": "Seuban Cot",
    "value": "Seuban Cot"
  },
  {
    "id": "1106052011",
    "district_id": "110605",
    "label": "Bak Dilib",
    "value": "Bak Dilib"
  },
  {
    "id": "1106052012",
    "district_id": "110605",
    "label": "Ulee Lhat",
    "value": "Ulee Lhat"
  },
  {
    "id": "1106052015",
    "district_id": "110605",
    "label": "Lamraya",
    "value": "Lamraya"
  },
  {
    "id": "1106052016",
    "district_id": "110605",
    "label": "Lamme Garot",
    "value": "Lamme Garot"
  },
  {
    "id": "1106052017",
    "district_id": "110605",
    "label": "Weu Bada",
    "value": "Weu Bada"
  },
  {
    "id": "1106052018",
    "district_id": "110605",
    "label": "Meunasah Tutong",
    "value": "Meunasah Tutong"
  },
  {
    "id": "1106052019",
    "district_id": "110605",
    "label": "Empee Tanong",
    "value": "Empee Tanong"
  },
  {
    "id": "1106052020",
    "district_id": "110605",
    "label": "Bak Cirih",
    "value": "Bak Cirih"
  },
  {
    "id": "1106052021",
    "district_id": "110605",
    "label": "Warabo",
    "value": "Warabo"
  },
  {
    "id": "1106052022",
    "district_id": "110605",
    "label": "Teubang Phui Mesjid",
    "value": "Teubang Phui Mesjid"
  },
  {
    "id": "1106052023",
    "district_id": "110605",
    "label": "Teubang Phui Baro",
    "value": "Teubang Phui Baro"
  },
  {
    "id": "1106052024",
    "district_id": "110605",
    "label": "Bira Cot",
    "value": "Bira Cot"
  },
  {
    "id": "1106052025",
    "district_id": "110605",
    "label": "Bira Lhok",
    "value": "Bira Lhok"
  },
  {
    "id": "1106052026",
    "district_id": "110605",
    "label": "Bung Tujoh",
    "value": "Bung Tujoh"
  },
  {
    "id": "1106052027",
    "district_id": "110605",
    "label": "Peurumping",
    "value": "Peurumping"
  },
  {
    "id": "1106052028",
    "district_id": "110605",
    "label": "Atong",
    "value": "Atong"
  },
  {
    "id": "1106052029",
    "district_id": "110605",
    "label": "Reudeup",
    "value": "Reudeup"
  },
  {
    "id": "1106052042",
    "district_id": "110605",
    "label": "Seubam Lhok",
    "value": "Seubam Lhok"
  },
  {
    "id": "1106052043",
    "district_id": "110605",
    "label": "Seumet",
    "value": "Seumet"
  },
  {
    "id": "1106052044",
    "district_id": "110605",
    "label": "Cot Seunong",
    "value": "Cot Seunong"
  },
  {
    "id": "1106052045",
    "district_id": "110605",
    "label": "Mon Ara",
    "value": "Mon Ara"
  },
  {
    "id": "1106052046",
    "district_id": "110605",
    "label": "Cot Lhok",
    "value": "Cot Lhok"
  },
  {
    "id": "1106052047",
    "district_id": "110605",
    "label": "Cot Lampoh Soh",
    "value": "Cot Lampoh Soh"
  },
  {
    "id": "1106052048",
    "district_id": "110605",
    "label": "Bueng Raya",
    "value": "Bueng Raya"
  },
  {
    "id": "1106052049",
    "district_id": "110605",
    "label": "Piyeung Lhang",
    "value": "Piyeung Lhang"
  },
  {
    "id": "1106052050",
    "district_id": "110605",
    "label": "Piyeung Manee",
    "value": "Piyeung Manee"
  },
  {
    "id": "1106052051",
    "district_id": "110605",
    "label": "Bueng Daroh",
    "value": "Bueng Daroh"
  },
  {
    "id": "1106052052",
    "district_id": "110605",
    "label": "Kuweu",
    "value": "Kuweu"
  },
  {
    "id": "1106052053",
    "district_id": "110605",
    "label": "Piyeung Datu",
    "value": "Piyeung Datu"
  },
  {
    "id": "1106062001",
    "district_id": "110606",
    "label": "Reuhat Tuha",
    "value": "Reuhat Tuha"
  },
  {
    "id": "1106062002",
    "district_id": "110606",
    "label": "Weusiteh",
    "value": "Weusiteh"
  },
  {
    "id": "1106062003",
    "district_id": "110606",
    "label": "Dilib Lamteungoh",
    "value": "Dilib Lamteungoh"
  },
  {
    "id": "1106062004",
    "district_id": "110606",
    "label": "Baet Lampuot",
    "value": "Baet Lampuot"
  },
  {
    "id": "1106062005",
    "district_id": "110606",
    "label": "Dilib Bukit",
    "value": "Dilib Bukit"
  },
  {
    "id": "1106062006",
    "district_id": "110606",
    "label": "Seumeureung",
    "value": "Seumeureung"
  },
  {
    "id": "1106062007",
    "district_id": "110606",
    "label": "Sibreh Keumudee",
    "value": "Sibreh Keumudee"
  },
  {
    "id": "1106062008",
    "district_id": "110606",
    "label": "Baet Mesago",
    "value": "Baet Mesago"
  },
  {
    "id": "1106062009",
    "district_id": "110606",
    "label": "Baet Mesjid",
    "value": "Baet Mesjid"
  },
  {
    "id": "1106062010",
    "district_id": "110606",
    "label": "Lambaro Sibreh",
    "value": "Lambaro Sibreh"
  },
  {
    "id": "1106062011",
    "district_id": "110606",
    "label": "Lamgeu Baro",
    "value": "Lamgeu Baro"
  },
  {
    "id": "1106062012",
    "district_id": "110606",
    "label": "Lamgeu Tuha",
    "value": "Lamgeu Tuha"
  },
  {
    "id": "1106062013",
    "district_id": "110606",
    "label": "Lampanah Ineu",
    "value": "Lampanah Ineu"
  },
  {
    "id": "1106062014",
    "district_id": "110606",
    "label": "Tampok Jeurat Raya",
    "value": "Tampok Jeurat Raya"
  },
  {
    "id": "1106062015",
    "district_id": "110606",
    "label": "Tampok Blang",
    "value": "Tampok Blang"
  },
  {
    "id": "1106062016",
    "district_id": "110606",
    "label": "Lamteh Dayah",
    "value": "Lamteh Dayah"
  },
  {
    "id": "1106062017",
    "district_id": "110606",
    "label": "Lam Lheu",
    "value": "Lam Lheu"
  },
  {
    "id": "1106062018",
    "district_id": "110606",
    "label": "Lambarih Jurong Raya",
    "value": "Lambarih Jurong Raya"
  },
  {
    "id": "1106062019",
    "district_id": "110606",
    "label": "Bukloh",
    "value": "Bukloh"
  },
  {
    "id": "1106062020",
    "district_id": "110606",
    "label": "Niron",
    "value": "Niron"
  },
  {
    "id": "1106062021",
    "district_id": "110606",
    "label": "Aneuk Galong Baro",
    "value": "Aneuk Galong Baro"
  },
  {
    "id": "1106062022",
    "district_id": "110606",
    "label": "Aneuk Galong Titi",
    "value": "Aneuk Galong Titi"
  },
  {
    "id": "1106062023",
    "district_id": "110606",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1106062024",
    "district_id": "110606",
    "label": "Klieng Mayang",
    "value": "Klieng Mayang"
  },
  {
    "id": "1106062025",
    "district_id": "110606",
    "label": "Aneuk Batee",
    "value": "Aneuk Batee"
  },
  {
    "id": "1106062026",
    "district_id": "110606",
    "label": "Meunasah Tuha",
    "value": "Meunasah Tuha"
  },
  {
    "id": "1106062027",
    "district_id": "110606",
    "label": "Lampisang",
    "value": "Lampisang"
  },
  {
    "id": "1106062028",
    "district_id": "110606",
    "label": "Meunasah Bakthu",
    "value": "Meunasah Bakthu"
  },
  {
    "id": "1106062029",
    "district_id": "110606",
    "label": "Lambarih Bak Mee",
    "value": "Lambarih Bak Mee"
  },
  {
    "id": "1106062030",
    "district_id": "110606",
    "label": "Luthu Dayah Krueng",
    "value": "Luthu Dayah Krueng"
  },
  {
    "id": "1106062031",
    "district_id": "110606",
    "label": "Luthu Lamweu",
    "value": "Luthu Lamweu"
  },
  {
    "id": "1106062032",
    "district_id": "110606",
    "label": "Panterawa",
    "value": "Panterawa"
  },
  {
    "id": "1106062033",
    "district_id": "110606",
    "label": "Lambirah",
    "value": "Lambirah"
  },
  {
    "id": "1106062034",
    "district_id": "110606",
    "label": "Kayee Adang",
    "value": "Kayee Adang"
  },
  {
    "id": "1106062035",
    "district_id": "110606",
    "label": "Lam Tanjong",
    "value": "Lam Tanjong"
  },
  {
    "id": "1106072001",
    "district_id": "110607",
    "label": "Lampeuneurut Ujong Blang",
    "value": "Lampeuneurut Ujong Blang"
  },
  {
    "id": "1106072002",
    "district_id": "110607",
    "label": "Lampeuneurut Gampong",
    "value": "Lampeuneurut Gampong"
  },
  {
    "id": "1106072003",
    "district_id": "110607",
    "label": "Lamreung",
    "value": "Lamreung"
  },
  {
    "id": "1106072004",
    "district_id": "110607",
    "label": "Lamcot",
    "value": "Lamcot"
  },
  {
    "id": "1106072005",
    "district_id": "110607",
    "label": "Lam Blang Trieng",
    "value": "Lam Blang Trieng"
  },
  {
    "id": "1106072006",
    "district_id": "110607",
    "label": "Lam Blang Mayang",
    "value": "Lam Blang Mayang"
  },
  {
    "id": "1106072007",
    "district_id": "110607",
    "label": "Bayu",
    "value": "Bayu"
  },
  {
    "id": "1106072008",
    "district_id": "110607",
    "label": "Lagang",
    "value": "Lagang"
  },
  {
    "id": "1106072009",
    "district_id": "110607",
    "label": "Lamsiteh",
    "value": "Lamsiteh"
  },
  {
    "id": "1106072010",
    "district_id": "110607",
    "label": "Lamkawe",
    "value": "Lamkawe"
  },
  {
    "id": "1106072011",
    "district_id": "110607",
    "label": "Lheu Blang",
    "value": "Lheu Blang"
  },
  {
    "id": "1106072012",
    "district_id": "110607",
    "label": "Payaroh",
    "value": "Payaroh"
  },
  {
    "id": "1106072013",
    "district_id": "110607",
    "label": "Lamsidaya",
    "value": "Lamsidaya"
  },
  {
    "id": "1106072014",
    "district_id": "110607",
    "label": "Kuta Karang",
    "value": "Kuta Karang"
  },
  {
    "id": "1106072015",
    "district_id": "110607",
    "label": "Leu Geu",
    "value": "Leu Geu"
  },
  {
    "id": "1106072016",
    "district_id": "110607",
    "label": "Lampeuneun",
    "value": "Lampeuneun"
  },
  {
    "id": "1106072017",
    "district_id": "110607",
    "label": "Lamtheun",
    "value": "Lamtheun"
  },
  {
    "id": "1106072018",
    "district_id": "110607",
    "label": "Deunong",
    "value": "Deunong"
  },
  {
    "id": "1106072019",
    "district_id": "110607",
    "label": "Tingkuem",
    "value": "Tingkuem"
  },
  {
    "id": "1106072020",
    "district_id": "110607",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1106072021",
    "district_id": "110607",
    "label": "Lam Bheu",
    "value": "Lam Bheu"
  },
  {
    "id": "1106072022",
    "district_id": "110607",
    "label": "Daroy Kameu",
    "value": "Daroy Kameu"
  },
  {
    "id": "1106072023",
    "district_id": "110607",
    "label": "Geundrieng",
    "value": "Geundrieng"
  },
  {
    "id": "1106072024",
    "district_id": "110607",
    "label": "Garot",
    "value": "Garot"
  },
  {
    "id": "1106072025",
    "district_id": "110607",
    "label": "Pasheu Beutong",
    "value": "Pasheu Beutong"
  },
  {
    "id": "1106072026",
    "district_id": "110607",
    "label": "Lampasi Engking",
    "value": "Lampasi Engking"
  },
  {
    "id": "1106072027",
    "district_id": "110607",
    "label": "Jeumpet Ajun",
    "value": "Jeumpet Ajun"
  },
  {
    "id": "1106072028",
    "district_id": "110607",
    "label": "Gue Gajah",
    "value": "Gue Gajah"
  },
  {
    "id": "1106072029",
    "district_id": "110607",
    "label": "Leu Ue",
    "value": "Leu Ue"
  },
  {
    "id": "1106072030",
    "district_id": "110607",
    "label": "Ulee Lueng",
    "value": "Ulee Lueng"
  },
  {
    "id": "1106072031",
    "district_id": "110607",
    "label": "Punie",
    "value": "Punie"
  },
  {
    "id": "1106072032",
    "district_id": "110607",
    "label": "Ulee Tuy",
    "value": "Ulee Tuy"
  },
  {
    "id": "1106082001",
    "district_id": "110608",
    "label": "Lambadeuk",
    "value": "Lambadeuk"
  },
  {
    "id": "1106082002",
    "district_id": "110608",
    "label": "Lamguron",
    "value": "Lamguron"
  },
  {
    "id": "1106082003",
    "district_id": "110608",
    "label": "Lambaro Nejid",
    "value": "Lambaro Nejid"
  },
  {
    "id": "1106082004",
    "district_id": "110608",
    "label": "Lampageu",
    "value": "Lampageu"
  },
  {
    "id": "1106082005",
    "district_id": "110608",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1106082006",
    "district_id": "110608",
    "label": "Lam Manyang",
    "value": "Lam Manyang"
  },
  {
    "id": "1106082007",
    "district_id": "110608",
    "label": "Lamteh",
    "value": "Lamteh"
  },
  {
    "id": "1106082008",
    "district_id": "110608",
    "label": "Lam Lumpu",
    "value": "Lam Lumpu"
  },
  {
    "id": "1106082009",
    "district_id": "110608",
    "label": "Lamteungoh",
    "value": "Lamteungoh"
  },
  {
    "id": "1106082010",
    "district_id": "110608",
    "label": "Lamtutui",
    "value": "Lamtutui"
  },
  {
    "id": "1106082011",
    "district_id": "110608",
    "label": "Meunasah Tuha",
    "value": "Meunasah Tuha"
  },
  {
    "id": "1106082012",
    "district_id": "110608",
    "label": "Lam Awe",
    "value": "Lam Awe"
  },
  {
    "id": "1106082013",
    "district_id": "110608",
    "label": "Pulo Bunta",
    "value": "Pulo Bunta"
  },
  {
    "id": "1106082014",
    "district_id": "110608",
    "label": "Beuradeun",
    "value": "Beuradeun"
  },
  {
    "id": "1106082015",
    "district_id": "110608",
    "label": "Lampisang",
    "value": "Lampisang"
  },
  {
    "id": "1106082016",
    "district_id": "110608",
    "label": "Keuneu Eu",
    "value": "Keuneu Eu"
  },
  {
    "id": "1106082017",
    "district_id": "110608",
    "label": "Lam Isek",
    "value": "Lam Isek"
  },
  {
    "id": "1106082018",
    "district_id": "110608",
    "label": "Lamkeumok",
    "value": "Lamkeumok"
  },
  {
    "id": "1106082019",
    "district_id": "110608",
    "label": "Lam Rukam",
    "value": "Lam Rukam"
  },
  {
    "id": "1106082020",
    "district_id": "110608",
    "label": "Gurah",
    "value": "Gurah"
  },
  {
    "id": "1106082021",
    "district_id": "110608",
    "label": "Rima Jeuneu",
    "value": "Rima Jeuneu"
  },
  {
    "id": "1106082022",
    "district_id": "110608",
    "label": "Lam Geu Eu",
    "value": "Lam Geu Eu"
  },
  {
    "id": "1106082023",
    "district_id": "110608",
    "label": "Lam Hasan",
    "value": "Lam Hasan"
  },
  {
    "id": "1106082024",
    "district_id": "110608",
    "label": "Paya Tieng",
    "value": "Paya Tieng"
  },
  {
    "id": "1106082025",
    "district_id": "110608",
    "label": "Ajuen",
    "value": "Ajuen"
  },
  {
    "id": "1106082026",
    "district_id": "110608",
    "label": "Rima Keuneurom",
    "value": "Rima Keuneurom"
  },
  {
    "id": "1106092001",
    "district_id": "110609",
    "label": "Meunasah Kulam",
    "value": "Meunasah Kulam"
  },
  {
    "id": "1106092002",
    "district_id": "110609",
    "label": "Beurandeh",
    "value": "Beurandeh"
  },
  {
    "id": "1106092003",
    "district_id": "110609",
    "label": "Ie Seum",
    "value": "Ie Seum"
  },
  {
    "id": "1106092004",
    "district_id": "110609",
    "label": "Meunasah Keudee",
    "value": "Meunasah Keudee"
  },
  {
    "id": "1106092005",
    "district_id": "110609",
    "label": "Lamreh",
    "value": "Lamreh"
  },
  {
    "id": "1106092006",
    "district_id": "110609",
    "label": "Meunasah Mon",
    "value": "Meunasah Mon"
  },
  {
    "id": "1106092007",
    "district_id": "110609",
    "label": "Paya Kameng",
    "value": "Paya Kameng"
  },
  {
    "id": "1106092008",
    "district_id": "110609",
    "label": "Ruyung",
    "value": "Ruyung"
  },
  {
    "id": "1106092009",
    "district_id": "110609",
    "label": "Ladong",
    "value": "Ladong"
  },
  {
    "id": "1106092010",
    "district_id": "110609",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1106092011",
    "district_id": "110609",
    "label": "Durung",
    "value": "Durung"
  },
  {
    "id": "1106092012",
    "district_id": "110609",
    "label": "Neuheun",
    "value": "Neuheun"
  },
  {
    "id": "1106092013",
    "district_id": "110609",
    "label": "Lamnga",
    "value": "Lamnga"
  },
  {
    "id": "1106102001",
    "district_id": "110610",
    "label": "Lambaro",
    "value": "Lambaro"
  },
  {
    "id": "1106102002",
    "district_id": "110610",
    "label": "Meunasah Manyang Lam Garot",
    "value": "Meunasah Manyang Lam Garot"
  },
  {
    "id": "1106102003",
    "district_id": "110610",
    "label": "Bakoy",
    "value": "Bakoy"
  },
  {
    "id": "1106102004",
    "district_id": "110610",
    "label": "Meunasah Baro",
    "value": "Meunasah Baro"
  },
  {
    "id": "1106102005",
    "district_id": "110610",
    "label": "Meunasah Deyah",
    "value": "Meunasah Deyah"
  },
  {
    "id": "1106102006",
    "district_id": "110610",
    "label": "Meunasah Tutong",
    "value": "Meunasah Tutong"
  },
  {
    "id": "1106102007",
    "district_id": "110610",
    "label": "Siron",
    "value": "Siron"
  },
  {
    "id": "1106102008",
    "district_id": "110610",
    "label": "Pasie Lamgarot",
    "value": "Pasie Lamgarot"
  },
  {
    "id": "1106102009",
    "district_id": "110610",
    "label": "Dham Ceukok",
    "value": "Dham Ceukok"
  },
  {
    "id": "1106102010",
    "district_id": "110610",
    "label": "Dham Pulo",
    "value": "Dham Pulo"
  },
  {
    "id": "1106102011",
    "district_id": "110610",
    "label": "Leubok Sukon",
    "value": "Leubok Sukon"
  },
  {
    "id": "1106102012",
    "district_id": "110610",
    "label": "Lubok Gapuy",
    "value": "Lubok Gapuy"
  },
  {
    "id": "1106102013",
    "district_id": "110610",
    "label": "Pasie Lubuk",
    "value": "Pasie Lubuk"
  },
  {
    "id": "1106102014",
    "district_id": "110610",
    "label": "Bueng Ceukok",
    "value": "Bueng Ceukok"
  },
  {
    "id": "1106102015",
    "district_id": "110610",
    "label": "Teubang Phuy",
    "value": "Teubang Phuy"
  },
  {
    "id": "1106102016",
    "district_id": "110610",
    "label": "Ateuk Anggok",
    "value": "Ateuk Anggok"
  },
  {
    "id": "1106102017",
    "district_id": "110610",
    "label": "Ajee Rayeuk",
    "value": "Ajee Rayeuk"
  },
  {
    "id": "1106102018",
    "district_id": "110610",
    "label": "Ajee Cut",
    "value": "Ajee Cut"
  },
  {
    "id": "1106102019",
    "district_id": "110610",
    "label": "Gani",
    "value": "Gani"
  },
  {
    "id": "1106102020",
    "district_id": "110610",
    "label": "Ateuk Lueng Ie",
    "value": "Ateuk Lueng Ie"
  },
  {
    "id": "1106102021",
    "district_id": "110610",
    "label": "Cot Alue",
    "value": "Cot Alue"
  },
  {
    "id": "1106102022",
    "district_id": "110610",
    "label": "Cot Suruy",
    "value": "Cot Suruy"
  },
  {
    "id": "1106102023",
    "district_id": "110610",
    "label": "Cot Bada",
    "value": "Cot Bada"
  },
  {
    "id": "1106102024",
    "district_id": "110610",
    "label": "Ajee Pagar Air",
    "value": "Ajee Pagar Air"
  },
  {
    "id": "1106102025",
    "district_id": "110610",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1106102026",
    "district_id": "110610",
    "label": "Jurong Peujera",
    "value": "Jurong Peujera"
  },
  {
    "id": "1106102027",
    "district_id": "110610",
    "label": "Lubok Batee",
    "value": "Lubok Batee"
  },
  {
    "id": "1106102028",
    "district_id": "110610",
    "label": "Bineh Blang",
    "value": "Bineh Blang"
  },
  {
    "id": "1106102029",
    "district_id": "110610",
    "label": "Pantee",
    "value": "Pantee"
  },
  {
    "id": "1106102030",
    "district_id": "110610",
    "label": "Kalut",
    "value": "Kalut"
  },
  {
    "id": "1106102031",
    "district_id": "110610",
    "label": "Meunasah Manyang Pagar Air",
    "value": "Meunasah Manyang Pagar Air"
  },
  {
    "id": "1106102032",
    "district_id": "110610",
    "label": "Meunasah Manyet",
    "value": "Meunasah Manyet"
  },
  {
    "id": "1106102033",
    "district_id": "110610",
    "label": "Santan",
    "value": "Santan"
  },
  {
    "id": "1106102034",
    "district_id": "110610",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "1106102035",
    "district_id": "110610",
    "label": "Reuloh",
    "value": "Reuloh"
  },
  {
    "id": "1106102041",
    "district_id": "110610",
    "label": "Paleuh Blang",
    "value": "Paleuh Blang"
  },
  {
    "id": "1106102042",
    "district_id": "110610",
    "label": "Cot Mentiwan",
    "value": "Cot Mentiwan"
  },
  {
    "id": "1106102043",
    "district_id": "110610",
    "label": "Lampreh Lam Jampok",
    "value": "Lampreh Lam Jampok"
  },
  {
    "id": "1106102044",
    "district_id": "110610",
    "label": "Paleuh Pulo",
    "value": "Paleuh Pulo"
  },
  {
    "id": "1106102045",
    "district_id": "110610",
    "label": "Lamcot",
    "value": "Lamcot"
  },
  {
    "id": "1106102046",
    "district_id": "110610",
    "label": "Lamsinyeu",
    "value": "Lamsinyeu"
  },
  {
    "id": "1106102047",
    "district_id": "110610",
    "label": "Lam Ue",
    "value": "Lam Ue"
  },
  {
    "id": "1106102048",
    "district_id": "110610",
    "label": "Cot Gud",
    "value": "Cot Gud"
  },
  {
    "id": "1106102049",
    "district_id": "110610",
    "label": "Lamdaya",
    "value": "Lamdaya"
  },
  {
    "id": "1106102050",
    "district_id": "110610",
    "label": "Lambada",
    "value": "Lambada"
  },
  {
    "id": "1106102051",
    "district_id": "110610",
    "label": "Ujong XII",
    "value": "Ujong XII"
  },
  {
    "id": "1106102052",
    "district_id": "110610",
    "label": "Bada",
    "value": "Bada"
  },
  {
    "id": "1106102053",
    "district_id": "110610",
    "label": "Lamteungoh",
    "value": "Lamteungoh"
  },
  {
    "id": "1106102054",
    "district_id": "110610",
    "label": "Kayee Lee",
    "value": "Kayee Lee"
  },
  {
    "id": "1106102056",
    "district_id": "110610",
    "label": "Lampreh Lamteungoh",
    "value": "Lampreh Lamteungoh"
  },
  {
    "id": "1106112001",
    "district_id": "110611",
    "label": "Krueng Anoi",
    "value": "Krueng Anoi"
  },
  {
    "id": "1106112002",
    "district_id": "110611",
    "label": "Lam Puuk",
    "value": "Lam Puuk"
  },
  {
    "id": "1106112003",
    "district_id": "110611",
    "label": "Cot Beut",
    "value": "Cot Beut"
  },
  {
    "id": "1106112004",
    "district_id": "110611",
    "label": "Bueng Bakjok",
    "value": "Bueng Bakjok"
  },
  {
    "id": "1106112005",
    "district_id": "110611",
    "label": "Lam Neuheun",
    "value": "Lam Neuheun"
  },
  {
    "id": "1106112006",
    "district_id": "110611",
    "label": "Seupeu",
    "value": "Seupeu"
  },
  {
    "id": "1106112007",
    "district_id": "110611",
    "label": "Cot Mancang",
    "value": "Cot Mancang"
  },
  {
    "id": "1106112008",
    "district_id": "110611",
    "label": "Lam Baed",
    "value": "Lam Baed"
  },
  {
    "id": "1106112009",
    "district_id": "110611",
    "label": "Cot Masam",
    "value": "Cot Masam"
  },
  {
    "id": "1106112016",
    "district_id": "110611",
    "label": "Gue",
    "value": "Gue"
  },
  {
    "id": "1106112018",
    "district_id": "110611",
    "label": "Lam Sabang",
    "value": "Lam Sabang"
  },
  {
    "id": "1106112019",
    "district_id": "110611",
    "label": "Lam Alue Raya",
    "value": "Lam Alue Raya"
  },
  {
    "id": "1106112020",
    "district_id": "110611",
    "label": "Lam Alue Cut",
    "value": "Lam Alue Cut"
  },
  {
    "id": "1106112021",
    "district_id": "110611",
    "label": "Leupung Mesjid",
    "value": "Leupung Mesjid"
  },
  {
    "id": "1106112022",
    "district_id": "110611",
    "label": "Cot Lamee",
    "value": "Cot Lamee"
  },
  {
    "id": "1106112023",
    "district_id": "110611",
    "label": "Leupung Ule Alue",
    "value": "Leupung Ule Alue"
  },
  {
    "id": "1106112024",
    "district_id": "110611",
    "label": "Lampoh Tarom",
    "value": "Lampoh Tarom"
  },
  {
    "id": "1106112025",
    "district_id": "110611",
    "label": "Lamteube Geupula",
    "value": "Lamteube Geupula"
  },
  {
    "id": "1106112026",
    "district_id": "110611",
    "label": "Lamteube Mon Ara",
    "value": "Lamteube Mon Ara"
  },
  {
    "id": "1106112027",
    "district_id": "110611",
    "label": "Aron",
    "value": "Aron"
  },
  {
    "id": "1106112028",
    "district_id": "110611",
    "label": "Bak Buloh",
    "value": "Bak Buloh"
  },
  {
    "id": "1106112029",
    "district_id": "110611",
    "label": "Lam Seunong",
    "value": "Lam Seunong"
  },
  {
    "id": "1106112030",
    "district_id": "110611",
    "label": "Lam Raya",
    "value": "Lam Raya"
  },
  {
    "id": "1106112031",
    "district_id": "110611",
    "label": "Lam Roh",
    "value": "Lam Roh"
  },
  {
    "id": "1106112032",
    "district_id": "110611",
    "label": "Lambunot Tanoh",
    "value": "Lambunot Tanoh"
  },
  {
    "id": "1106112033",
    "district_id": "110611",
    "label": "Tumpok Lampoh",
    "value": "Tumpok Lampoh"
  },
  {
    "id": "1106112034",
    "district_id": "110611",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1106112035",
    "district_id": "110611",
    "label": "Lambunot Paya",
    "value": "Lambunot Paya"
  },
  {
    "id": "1106112036",
    "district_id": "110611",
    "label": "Lampoh Keude",
    "value": "Lampoh Keude"
  },
  {
    "id": "1106112037",
    "district_id": "110611",
    "label": "Lam Asan",
    "value": "Lam Asan"
  },
  {
    "id": "1106112038",
    "district_id": "110611",
    "label": "Lambro Deyah",
    "value": "Lambro Deyah"
  },
  {
    "id": "1106112039",
    "district_id": "110611",
    "label": "Lambro Bileu",
    "value": "Lambro Bileu"
  },
  {
    "id": "1106112040",
    "district_id": "110611",
    "label": "Cot Peutano",
    "value": "Cot Peutano"
  },
  {
    "id": "1106112041",
    "district_id": "110611",
    "label": "Cot Cut",
    "value": "Cot Cut"
  },
  {
    "id": "1106112042",
    "district_id": "110611",
    "label": "Meunasah Baktrieng",
    "value": "Meunasah Baktrieng"
  },
  {
    "id": "1106112043",
    "district_id": "110611",
    "label": "Babah Jurong",
    "value": "Babah Jurong"
  },
  {
    "id": "1106112044",
    "district_id": "110611",
    "label": "Lam Glumpang",
    "value": "Lam Glumpang"
  },
  {
    "id": "1106112045",
    "district_id": "110611",
    "label": "Rabeu",
    "value": "Rabeu"
  },
  {
    "id": "1106112046",
    "district_id": "110611",
    "label": "Cucum",
    "value": "Cucum"
  },
  {
    "id": "1106112047",
    "district_id": "110611",
    "label": "Deyah",
    "value": "Deyah"
  },
  {
    "id": "1106112048",
    "district_id": "110611",
    "label": "Lam Trieng",
    "value": "Lam Trieng"
  },
  {
    "id": "1106112049",
    "district_id": "110611",
    "label": "Beurangong",
    "value": "Beurangong"
  },
  {
    "id": "1106112050",
    "district_id": "110611",
    "label": "Cot Preh",
    "value": "Cot Preh"
  },
  {
    "id": "1106112051",
    "district_id": "110611",
    "label": "Cot Raya",
    "value": "Cot Raya"
  },
  {
    "id": "1106112052",
    "district_id": "110611",
    "label": "Cot Yang",
    "value": "Cot Yang"
  },
  {
    "id": "1106112053",
    "district_id": "110611",
    "label": "Ujong Blang",
    "value": "Ujong Blang"
  },
  {
    "id": "1106112054",
    "district_id": "110611",
    "label": "Lamceu",
    "value": "Lamceu"
  },
  {
    "id": "1106122001",
    "district_id": "110612",
    "label": "Angan",
    "value": "Angan"
  },
  {
    "id": "1106122002",
    "district_id": "110612",
    "label": "Lam Peudaya",
    "value": "Lam Peudaya"
  },
  {
    "id": "1106122003",
    "district_id": "110612",
    "label": "Lambada Peukan",
    "value": "Lambada Peukan"
  },
  {
    "id": "1106122004",
    "district_id": "110612",
    "label": "Lambiheu La",
    "value": "Lambiheu La"
  },
  {
    "id": "1106122005",
    "district_id": "110612",
    "label": "Lambaro Sukon",
    "value": "Lambaro Sukon"
  },
  {
    "id": "1106122006",
    "district_id": "110612",
    "label": "Miruek Taman",
    "value": "Miruek Taman"
  },
  {
    "id": "1106122007",
    "district_id": "110612",
    "label": "Suleue",
    "value": "Suleue"
  },
  {
    "id": "1106122008",
    "district_id": "110612",
    "label": "Gampong Cot",
    "value": "Gampong Cot"
  },
  {
    "id": "1106122009",
    "district_id": "110612",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1106122010",
    "district_id": "110612",
    "label": "Lampuja",
    "value": "Lampuja"
  },
  {
    "id": "1106122011",
    "district_id": "110612",
    "label": "Tanjung Deah",
    "value": "Tanjung Deah"
  },
  {
    "id": "1106122012",
    "district_id": "110612",
    "label": "Limpok",
    "value": "Limpok"
  },
  {
    "id": "1106122013",
    "district_id": "110612",
    "label": "Lampuuk",
    "value": "Lampuuk"
  },
  {
    "id": "1106122014",
    "district_id": "110612",
    "label": "Lam Ujong",
    "value": "Lam Ujong"
  },
  {
    "id": "1106122015",
    "district_id": "110612",
    "label": "Lam Gawe",
    "value": "Lam Gawe"
  },
  {
    "id": "1106122016",
    "district_id": "110612",
    "label": "Berabung",
    "value": "Berabung"
  },
  {
    "id": "1106122017",
    "district_id": "110612",
    "label": "Lamtimpeung",
    "value": "Lamtimpeung"
  },
  {
    "id": "1106122018",
    "district_id": "110612",
    "label": "Tungkop",
    "value": "Tungkop"
  },
  {
    "id": "1106122019",
    "district_id": "110612",
    "label": "Tanjong Seulamat",
    "value": "Tanjong Seulamat"
  },
  {
    "id": "1106122020",
    "district_id": "110612",
    "label": "Lamkeunung",
    "value": "Lamkeunung"
  },
  {
    "id": "1106122021",
    "district_id": "110612",
    "label": "Lam Duroy",
    "value": "Lam Duroy"
  },
  {
    "id": "1106122022",
    "district_id": "110612",
    "label": "Lam Klat",
    "value": "Lam Klat"
  },
  {
    "id": "1106122023",
    "district_id": "110612",
    "label": "Lambiheu Siem",
    "value": "Lambiheu Siem"
  },
  {
    "id": "1106122024",
    "district_id": "110612",
    "label": "Lambitra",
    "value": "Lambitra"
  },
  {
    "id": "1106122025",
    "district_id": "110612",
    "label": "Lie Eue",
    "value": "Lie Eue"
  },
  {
    "id": "1106122026",
    "district_id": "110612",
    "label": "Krueng Kalee",
    "value": "Krueng Kalee"
  },
  {
    "id": "1106122027",
    "district_id": "110612",
    "label": "Lam Asan",
    "value": "Lam Asan"
  },
  {
    "id": "1106122028",
    "district_id": "110612",
    "label": "Lam Reh",
    "value": "Lam Reh"
  },
  {
    "id": "1106122029",
    "district_id": "110612",
    "label": "Siem",
    "value": "Siem"
  },
  {
    "id": "1106132001",
    "district_id": "110613",
    "label": "Rinon",
    "value": "Rinon"
  },
  {
    "id": "1106132002",
    "district_id": "110613",
    "label": "Alue Raya",
    "value": "Alue Raya"
  },
  {
    "id": "1106132003",
    "district_id": "110613",
    "label": "Melingge",
    "value": "Melingge"
  },
  {
    "id": "1106132004",
    "district_id": "110613",
    "label": "Lapeng",
    "value": "Lapeng"
  },
  {
    "id": "1106132005",
    "district_id": "110613",
    "label": "Ulee Paya",
    "value": "Ulee Paya"
  },
  {
    "id": "1106132006",
    "district_id": "110613",
    "label": "Gugop",
    "value": "Gugop"
  },
  {
    "id": "1106132007",
    "district_id": "110613",
    "label": "Seurapong",
    "value": "Seurapong"
  },
  {
    "id": "1106132008",
    "district_id": "110613",
    "label": "Blang Situngkoh",
    "value": "Blang Situngkoh"
  },
  {
    "id": "1106132009",
    "district_id": "110613",
    "label": "Paloh",
    "value": "Paloh"
  },
  {
    "id": "1106132010",
    "district_id": "110613",
    "label": "Lampuyang",
    "value": "Lampuyang"
  },
  {
    "id": "1106132011",
    "district_id": "110613",
    "label": "Lhok",
    "value": "Lhok"
  },
  {
    "id": "1106132012",
    "district_id": "110613",
    "label": "Teunom",
    "value": "Teunom"
  },
  {
    "id": "1106132013",
    "district_id": "110613",
    "label": "Lamteng",
    "value": "Lamteng"
  },
  {
    "id": "1106132014",
    "district_id": "110613",
    "label": "Pasi Janeng",
    "value": "Pasi Janeng"
  },
  {
    "id": "1106132015",
    "district_id": "110613",
    "label": "Rabo",
    "value": "Rabo"
  },
  {
    "id": "1106132016",
    "district_id": "110613",
    "label": "Alue Reuyeueng",
    "value": "Alue Reuyeueng"
  },
  {
    "id": "1106132017",
    "district_id": "110613",
    "label": "Deudap",
    "value": "Deudap"
  },
  {
    "id": "1106142001",
    "district_id": "110614",
    "label": "Lamtamot",
    "value": "Lamtamot"
  },
  {
    "id": "1106142002",
    "district_id": "110614",
    "label": "Lambaro Tunong",
    "value": "Lambaro Tunong"
  },
  {
    "id": "1106142003",
    "district_id": "110614",
    "label": "Lonbaroh",
    "value": "Lonbaroh"
  },
  {
    "id": "1106142004",
    "district_id": "110614",
    "label": "Lon Asan",
    "value": "Lon Asan"
  },
  {
    "id": "1106142005",
    "district_id": "110614",
    "label": "Paya Keureuleh",
    "value": "Paya Keureuleh"
  },
  {
    "id": "1106142006",
    "district_id": "110614",
    "label": "Panca",
    "value": "Panca"
  },
  {
    "id": "1106142007",
    "district_id": "110614",
    "label": "Lam Kubu",
    "value": "Lam Kubu"
  },
  {
    "id": "1106142008",
    "district_id": "110614",
    "label": "Panca Kubu",
    "value": "Panca Kubu"
  },
  {
    "id": "1106142009",
    "district_id": "110614",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1106142010",
    "district_id": "110614",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1106142011",
    "district_id": "110614",
    "label": "Saree Aceh",
    "value": "Saree Aceh"
  },
  {
    "id": "1106142012",
    "district_id": "110614",
    "label": "Teuladan",
    "value": "Teuladan"
  },
  {
    "id": "1106152001",
    "district_id": "110615",
    "label": "Teureubeh",
    "value": "Teureubeh"
  },
  {
    "id": "1106152002",
    "district_id": "110615",
    "label": "Barueh",
    "value": "Barueh"
  },
  {
    "id": "1106152003",
    "district_id": "110615",
    "label": "Jantho",
    "value": "Jantho"
  },
  {
    "id": "1106152004",
    "district_id": "110615",
    "label": "Awek",
    "value": "Awek"
  },
  {
    "id": "1106152005",
    "district_id": "110615",
    "label": "Data Cut",
    "value": "Data Cut"
  },
  {
    "id": "1106152006",
    "district_id": "110615",
    "label": "Bueng",
    "value": "Bueng"
  },
  {
    "id": "1106152007",
    "district_id": "110615",
    "label": "Weu",
    "value": "Weu"
  },
  {
    "id": "1106152008",
    "district_id": "110615",
    "label": "Jalin",
    "value": "Jalin"
  },
  {
    "id": "1106152009",
    "district_id": "110615",
    "label": "Suka Tani",
    "value": "Suka Tani"
  },
  {
    "id": "1106152010",
    "district_id": "110615",
    "label": "Cucum",
    "value": "Cucum"
  },
  {
    "id": "1106152011",
    "district_id": "110615",
    "label": "Jantho Baru",
    "value": "Jantho Baru"
  },
  {
    "id": "1106152012",
    "district_id": "110615",
    "label": "Jantho Makmur",
    "value": "Jantho Makmur"
  },
  {
    "id": "1106152013",
    "district_id": "110615",
    "label": "Bukit Meusara",
    "value": "Bukit Meusara"
  },
  {
    "id": "1106162001",
    "district_id": "110616",
    "label": "Lampakuk",
    "value": "Lampakuk"
  },
  {
    "id": "1106162002",
    "district_id": "110616",
    "label": "Lampoh Raja",
    "value": "Lampoh Raja"
  },
  {
    "id": "1106162003",
    "district_id": "110616",
    "label": "Lam Aling",
    "value": "Lam Aling"
  },
  {
    "id": "1106162004",
    "district_id": "110616",
    "label": "Lamleupung",
    "value": "Lamleupung"
  },
  {
    "id": "1106162005",
    "district_id": "110616",
    "label": "Lamkleng",
    "value": "Lamkleng"
  },
  {
    "id": "1106162006",
    "district_id": "110616",
    "label": "Ie Alang Lamkreumeuh",
    "value": "Ie Alang Lamkreumeuh"
  },
  {
    "id": "1106162007",
    "district_id": "110616",
    "label": "Ie Alang Lamghui",
    "value": "Ie Alang Lamghui"
  },
  {
    "id": "1106162008",
    "district_id": "110616",
    "label": "Ie Alang Mesjid",
    "value": "Ie Alang Mesjid"
  },
  {
    "id": "1106162009",
    "district_id": "110616",
    "label": "Ie Alang Dayah",
    "value": "Ie Alang Dayah"
  },
  {
    "id": "1106162010",
    "district_id": "110616",
    "label": "Lamtui",
    "value": "Lamtui"
  },
  {
    "id": "1106162011",
    "district_id": "110616",
    "label": "Barih Lhok",
    "value": "Barih Lhok"
  },
  {
    "id": "1106162012",
    "district_id": "110616",
    "label": "Bueng Simek",
    "value": "Bueng Simek"
  },
  {
    "id": "1106162013",
    "district_id": "110616",
    "label": "Banda Safa",
    "value": "Banda Safa"
  },
  {
    "id": "1106162014",
    "district_id": "110616",
    "label": "Bithak",
    "value": "Bithak"
  },
  {
    "id": "1106162015",
    "district_id": "110616",
    "label": "Lambeugak",
    "value": "Lambeugak"
  },
  {
    "id": "1106162016",
    "district_id": "110616",
    "label": "Pakuk",
    "value": "Pakuk"
  },
  {
    "id": "1106162017",
    "district_id": "110616",
    "label": "Lamsie",
    "value": "Lamsie"
  },
  {
    "id": "1106162018",
    "district_id": "110616",
    "label": "Sigapang",
    "value": "Sigapang"
  },
  {
    "id": "1106162019",
    "district_id": "110616",
    "label": "Tutui",
    "value": "Tutui"
  },
  {
    "id": "1106162020",
    "district_id": "110616",
    "label": "Pasar Lampaku",
    "value": "Pasar Lampaku"
  },
  {
    "id": "1106162021",
    "district_id": "110616",
    "label": "Maheng",
    "value": "Maheng"
  },
  {
    "id": "1106162022",
    "district_id": "110616",
    "label": "Leupung Bruek",
    "value": "Leupung Bruek"
  },
  {
    "id": "1106162023",
    "district_id": "110616",
    "label": "Keumireu",
    "value": "Keumireu"
  },
  {
    "id": "1106162024",
    "district_id": "110616",
    "label": "Lamleuot",
    "value": "Lamleuot"
  },
  {
    "id": "1106162025",
    "district_id": "110616",
    "label": "Leupung Balue",
    "value": "Leupung Balue"
  },
  {
    "id": "1106162026",
    "district_id": "110616",
    "label": "Bak Sukon",
    "value": "Bak Sukon"
  },
  {
    "id": "1106162027",
    "district_id": "110616",
    "label": "Cot Bayu",
    "value": "Cot Bayu"
  },
  {
    "id": "1106162028",
    "district_id": "110616",
    "label": "Glee Jai",
    "value": "Glee Jai"
  },
  {
    "id": "1106162029",
    "district_id": "110616",
    "label": "Keuruweung Blang",
    "value": "Keuruweung Blang"
  },
  {
    "id": "1106162030",
    "district_id": "110616",
    "label": "Keuruweung Krueng",
    "value": "Keuruweung Krueng"
  },
  {
    "id": "1106162031",
    "district_id": "110616",
    "label": "Siron Blang",
    "value": "Siron Blang"
  },
  {
    "id": "1106162032",
    "district_id": "110616",
    "label": "Siron Krueng",
    "value": "Siron Krueng"
  },
  {
    "id": "1106172001",
    "district_id": "110617",
    "label": "Lambaro Samahani",
    "value": "Lambaro Samahani"
  },
  {
    "id": "1106172002",
    "district_id": "110617",
    "label": "Lam Ara Cut",
    "value": "Lam Ara Cut"
  },
  {
    "id": "1106172003",
    "district_id": "110617",
    "label": "Lam Ara Engkit",
    "value": "Lam Ara Engkit"
  },
  {
    "id": "1106172004",
    "district_id": "110617",
    "label": "Lam Ara Tunong",
    "value": "Lam Ara Tunong"
  },
  {
    "id": "1106172005",
    "district_id": "110617",
    "label": "Leupung Riwat",
    "value": "Leupung Riwat"
  },
  {
    "id": "1106172006",
    "district_id": "110617",
    "label": "Tumbo Baro",
    "value": "Tumbo Baro"
  },
  {
    "id": "1106172007",
    "district_id": "110617",
    "label": "Leupung Cut",
    "value": "Leupung Cut"
  },
  {
    "id": "1106172008",
    "district_id": "110617",
    "label": "Lamsiteh Cot",
    "value": "Lamsiteh Cot"
  },
  {
    "id": "1106172009",
    "district_id": "110617",
    "label": "Reuleung Karieng",
    "value": "Reuleung Karieng"
  },
  {
    "id": "1106172010",
    "district_id": "110617",
    "label": "Leubok Buni",
    "value": "Leubok Buni"
  },
  {
    "id": "1106172011",
    "district_id": "110617",
    "label": "Leubok Batee",
    "value": "Leubok Batee"
  },
  {
    "id": "1106172012",
    "district_id": "110617",
    "label": "Bunghu",
    "value": "Bunghu"
  },
  {
    "id": "1106172013",
    "district_id": "110617",
    "label": "Teu Dayah",
    "value": "Teu Dayah"
  },
  {
    "id": "1106172014",
    "district_id": "110617",
    "label": "Leupung Rayeuk",
    "value": "Leupung Rayeuk"
  },
  {
    "id": "1106172015",
    "district_id": "110617",
    "label": "Reuleung Glumpang",
    "value": "Reuleung Glumpang"
  },
  {
    "id": "1106182001",
    "district_id": "110618",
    "label": "Ateuk Lam Phang",
    "value": "Ateuk Lam Phang"
  },
  {
    "id": "1106182002",
    "district_id": "110618",
    "label": "Ateuk Lampuot",
    "value": "Ateuk Lampuot"
  },
  {
    "id": "1106182003",
    "district_id": "110618",
    "label": "Ateuk Mon Panah",
    "value": "Ateuk Mon Panah"
  },
  {
    "id": "1106182004",
    "district_id": "110618",
    "label": "Ateuk Lam Ura",
    "value": "Ateuk Lam Ura"
  },
  {
    "id": "1106182005",
    "district_id": "110618",
    "label": "Ateuk Cut",
    "value": "Ateuk Cut"
  },
  {
    "id": "1106182006",
    "district_id": "110618",
    "label": "Ateuk Blang Asan",
    "value": "Ateuk Blang Asan"
  },
  {
    "id": "1106182007",
    "district_id": "110618",
    "label": "Lamjamee Dayah",
    "value": "Lamjamee Dayah"
  },
  {
    "id": "1106182008",
    "district_id": "110618",
    "label": "Lam Batee",
    "value": "Lam Batee"
  },
  {
    "id": "1106182009",
    "district_id": "110618",
    "label": "Nya",
    "value": "Nya"
  },
  {
    "id": "1106182010",
    "district_id": "110618",
    "label": "Batee Linteung",
    "value": "Batee Linteung"
  },
  {
    "id": "1106182011",
    "district_id": "110618",
    "label": "Krueng Mak",
    "value": "Krueng Mak"
  },
  {
    "id": "1106182012",
    "district_id": "110618",
    "label": "Lambunot",
    "value": "Lambunot"
  },
  {
    "id": "1106182013",
    "district_id": "110618",
    "label": "Lamjamee Lamkrak",
    "value": "Lamjamee Lamkrak"
  },
  {
    "id": "1106182014",
    "district_id": "110618",
    "label": "Blang Miro",
    "value": "Blang Miro"
  },
  {
    "id": "1106182015",
    "district_id": "110618",
    "label": "Blang Preh",
    "value": "Blang Preh"
  },
  {
    "id": "1106182016",
    "district_id": "110618",
    "label": "Bha Ulee Tutu",
    "value": "Bha Ulee Tutu"
  },
  {
    "id": "1106182017",
    "district_id": "110618",
    "label": "Lam Urit",
    "value": "Lam Urit"
  },
  {
    "id": "1106182018",
    "district_id": "110618",
    "label": "Tantuha",
    "value": "Tantuha"
  },
  {
    "id": "1106192001",
    "district_id": "110619",
    "label": "Lhang",
    "value": "Lhang"
  },
  {
    "id": "1106192002",
    "district_id": "110619",
    "label": "Biluy",
    "value": "Biluy"
  },
  {
    "id": "1106192003",
    "district_id": "110619",
    "label": "Lambatee",
    "value": "Lambatee"
  },
  {
    "id": "1106192004",
    "district_id": "110619",
    "label": "Lamkunyet",
    "value": "Lamkunyet"
  },
  {
    "id": "1106192005",
    "district_id": "110619",
    "label": "Lamtadok",
    "value": "Lamtadok"
  },
  {
    "id": "1106192006",
    "district_id": "110619",
    "label": "Neusok",
    "value": "Neusok"
  },
  {
    "id": "1106192007",
    "district_id": "110619",
    "label": "Turam",
    "value": "Turam"
  },
  {
    "id": "1106192008",
    "district_id": "110619",
    "label": "Tubaluy",
    "value": "Tubaluy"
  },
  {
    "id": "1106192009",
    "district_id": "110619",
    "label": "Blang Kiree",
    "value": "Blang Kiree"
  },
  {
    "id": "1106192010",
    "district_id": "110619",
    "label": "Manee Dayah",
    "value": "Manee Dayah"
  },
  {
    "id": "1106192011",
    "district_id": "110619",
    "label": "Lamsod",
    "value": "Lamsod"
  },
  {
    "id": "1106192012",
    "district_id": "110619",
    "label": "Lambleut",
    "value": "Lambleut"
  },
  {
    "id": "1106192013",
    "district_id": "110619",
    "label": "Empee Trieng",
    "value": "Empee Trieng"
  },
  {
    "id": "1106192014",
    "district_id": "110619",
    "label": "Lambaro Biluy",
    "value": "Lambaro Biluy"
  },
  {
    "id": "1106202001",
    "district_id": "110620",
    "label": "Cadek",
    "value": "Cadek"
  },
  {
    "id": "1106202002",
    "district_id": "110620",
    "label": "Kajhu",
    "value": "Kajhu"
  },
  {
    "id": "1106202003",
    "district_id": "110620",
    "label": "Baet",
    "value": "Baet"
  },
  {
    "id": "1106202004",
    "district_id": "110620",
    "label": "Blang Krueng",
    "value": "Blang Krueng"
  },
  {
    "id": "1106202005",
    "district_id": "110620",
    "label": "Klieng Muria",
    "value": "Klieng Muria"
  },
  {
    "id": "1106202006",
    "district_id": "110620",
    "label": "Lam Bada Lhok",
    "value": "Lam Bada Lhok"
  },
  {
    "id": "1106202007",
    "district_id": "110620",
    "label": "Lampineung",
    "value": "Lampineung"
  },
  {
    "id": "1106202008",
    "district_id": "110620",
    "label": "Lam Asan",
    "value": "Lam Asan"
  },
  {
    "id": "1106202009",
    "district_id": "110620",
    "label": "Labuy",
    "value": "Labuy"
  },
  {
    "id": "1106202010",
    "district_id": "110620",
    "label": "Lam Ujong",
    "value": "Lam Ujong"
  },
  {
    "id": "1106202011",
    "district_id": "110620",
    "label": "Klieng Cot Aron",
    "value": "Klieng Cot Aron"
  },
  {
    "id": "1106202012",
    "district_id": "110620",
    "label": "Cot Paya",
    "value": "Cot Paya"
  },
  {
    "id": "1106202013",
    "district_id": "110620",
    "label": "Miruk Lam Reudep",
    "value": "Miruk Lam Reudep"
  },
  {
    "id": "1106212001",
    "district_id": "110621",
    "label": "Meunasah Papeun",
    "value": "Meunasah Papeun"
  },
  {
    "id": "1106212002",
    "district_id": "110621",
    "label": "Meunasah Baktrieng",
    "value": "Meunasah Baktrieng"
  },
  {
    "id": "1106212003",
    "district_id": "110621",
    "label": "Leung Ie",
    "value": "Leung Ie"
  },
  {
    "id": "1106212004",
    "district_id": "110621",
    "label": "Lampermai",
    "value": "Lampermai"
  },
  {
    "id": "1106212005",
    "district_id": "110621",
    "label": "Gle Deyah",
    "value": "Gle Deyah"
  },
  {
    "id": "1106212006",
    "district_id": "110621",
    "label": "Miruk",
    "value": "Miruk"
  },
  {
    "id": "1106212007",
    "district_id": "110621",
    "label": "Lam Gapang",
    "value": "Lam Gapang"
  },
  {
    "id": "1106212008",
    "district_id": "110621",
    "label": "Meunasah Manyang",
    "value": "Meunasah Manyang"
  },
  {
    "id": "1106212009",
    "district_id": "110621",
    "label": "Meunasah Baet",
    "value": "Meunasah Baet"
  },
  {
    "id": "1106212010",
    "district_id": "110621",
    "label": "Meunasah Intan",
    "value": "Meunasah Intan"
  },
  {
    "id": "1106212011",
    "district_id": "110621",
    "label": "Rumpet",
    "value": "Rumpet"
  },
  {
    "id": "1106212012",
    "district_id": "110621",
    "label": "Gla Meunasah Baro",
    "value": "Gla Meunasah Baro"
  },
  {
    "id": "1106222001",
    "district_id": "110622",
    "label": "Layeun",
    "value": "Layeun"
  },
  {
    "id": "1106222002",
    "district_id": "110622",
    "label": "Pulot",
    "value": "Pulot"
  },
  {
    "id": "1106222003",
    "district_id": "110622",
    "label": "Lamseunia",
    "value": "Lamseunia"
  },
  {
    "id": "1106222004",
    "district_id": "110622",
    "label": "Mns. Mesjid",
    "value": "Mns. Mesjid"
  },
  {
    "id": "1106222005",
    "district_id": "110622",
    "label": "Meunasah Bak U",
    "value": "Meunasah Bak U"
  },
  {
    "id": "1106222006",
    "district_id": "110622",
    "label": "Dayah Mamplam",
    "value": "Dayah Mamplam"
  },
  {
    "id": "1106232001",
    "district_id": "110623",
    "label": "Cot Puklat",
    "value": "Cot Puklat"
  },
  {
    "id": "1106232002",
    "district_id": "110623",
    "label": "Lamme",
    "value": "Lamme"
  },
  {
    "id": "1106232003",
    "district_id": "110623",
    "label": "Meulayo",
    "value": "Meulayo"
  },
  {
    "id": "1106232004",
    "district_id": "110623",
    "label": "Lam Siem",
    "value": "Lam Siem"
  },
  {
    "id": "1106232005",
    "district_id": "110623",
    "label": "Cot Geundreut",
    "value": "Cot Geundreut"
  },
  {
    "id": "1106232006",
    "district_id": "110623",
    "label": "Paya Ue",
    "value": "Paya Ue"
  },
  {
    "id": "1106232007",
    "district_id": "110623",
    "label": "Cot Monraya",
    "value": "Cot Monraya"
  },
  {
    "id": "1106232008",
    "district_id": "110623",
    "label": "Cot Meulangeun",
    "value": "Cot Meulangeun"
  },
  {
    "id": "1106232009",
    "district_id": "110623",
    "label": "Cot Madhi",
    "value": "Cot Madhi"
  },
  {
    "id": "1106232010",
    "district_id": "110623",
    "label": "Bueng Sidom",
    "value": "Bueng Sidom"
  },
  {
    "id": "1106232011",
    "district_id": "110623",
    "label": "Cot Karieng",
    "value": "Cot Karieng"
  },
  {
    "id": "1106232012",
    "district_id": "110623",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1106232013",
    "district_id": "110623",
    "label": "Cot Malem",
    "value": "Cot Malem"
  },
  {
    "id": "1106232014",
    "district_id": "110623",
    "label": "Cot Rumpun",
    "value": "Cot Rumpun"
  },
  {
    "id": "1106232015",
    "district_id": "110623",
    "label": "Cot Hoho",
    "value": "Cot Hoho"
  },
  {
    "id": "1106232016",
    "district_id": "110623",
    "label": "Cot Jambo",
    "value": "Cot Jambo"
  },
  {
    "id": "1106232017",
    "district_id": "110623",
    "label": "Cot Nambak",
    "value": "Cot Nambak"
  },
  {
    "id": "1106232018",
    "district_id": "110623",
    "label": "Bueng Pageu",
    "value": "Bueng Pageu"
  },
  {
    "id": "1106232019",
    "district_id": "110623",
    "label": "Cot Sayun",
    "value": "Cot Sayun"
  },
  {
    "id": "1106232020",
    "district_id": "110623",
    "label": "Teupin Batee",
    "value": "Teupin Batee"
  },
  {
    "id": "1106232021",
    "district_id": "110623",
    "label": "Cot Leuot",
    "value": "Cot Leuot"
  },
  {
    "id": "1106232022",
    "district_id": "110623",
    "label": "Cot Bagie",
    "value": "Cot Bagie"
  },
  {
    "id": "1106232023",
    "district_id": "110623",
    "label": "Kayee Kunyet",
    "value": "Kayee Kunyet"
  },
  {
    "id": "1106232024",
    "district_id": "110623",
    "label": "Cot Mancang",
    "value": "Cot Mancang"
  },
  {
    "id": "1106232025",
    "district_id": "110623",
    "label": "Empee Bata",
    "value": "Empee Bata"
  },
  {
    "id": "1106232026",
    "district_id": "110623",
    "label": "Data Makmur",
    "value": "Data Makmur"
  },
  {
    "id": "1107032001",
    "district_id": "110703",
    "label": "Dayah Tuha",
    "value": "Dayah Tuha"
  },
  {
    "id": "1107032002",
    "district_id": "110703",
    "label": "Dayah Baroh",
    "value": "Dayah Baroh"
  },
  {
    "id": "1107032003",
    "district_id": "110703",
    "label": "Bintang Hu",
    "value": "Bintang Hu"
  },
  {
    "id": "1107032004",
    "district_id": "110703",
    "label": "Pulo Tukok",
    "value": "Pulo Tukok"
  },
  {
    "id": "1107032005",
    "district_id": "110703",
    "label": "Pulo Bungong",
    "value": "Pulo Bungong"
  },
  {
    "id": "1107032006",
    "district_id": "110703",
    "label": "Geunteng Barat",
    "value": "Geunteng Barat"
  },
  {
    "id": "1107032007",
    "district_id": "110703",
    "label": "Geunteng Timur",
    "value": "Geunteng Timur"
  },
  {
    "id": "1107032008",
    "district_id": "110703",
    "label": "Calong Cut",
    "value": "Calong Cut"
  },
  {
    "id": "1107032009",
    "district_id": "110703",
    "label": "Glumpang Lhee",
    "value": "Glumpang Lhee"
  },
  {
    "id": "1107032010",
    "district_id": "110703",
    "label": "Aron",
    "value": "Aron"
  },
  {
    "id": "1107032011",
    "district_id": "110703",
    "label": "Tuha",
    "value": "Tuha"
  },
  {
    "id": "1107032012",
    "district_id": "110703",
    "label": "Dayah",
    "value": "Dayah"
  },
  {
    "id": "1107032013",
    "district_id": "110703",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1107032014",
    "district_id": "110703",
    "label": "Meucat",
    "value": "Meucat"
  },
  {
    "id": "1107032015",
    "district_id": "110703",
    "label": "Teupin Raya",
    "value": "Teupin Raya"
  },
  {
    "id": "1107032016",
    "district_id": "110703",
    "label": "Rungkom",
    "value": "Rungkom"
  },
  {
    "id": "1107032017",
    "district_id": "110703",
    "label": "Mee",
    "value": "Mee"
  },
  {
    "id": "1107032018",
    "district_id": "110703",
    "label": "Pulo Pande",
    "value": "Pulo Pande"
  },
  {
    "id": "1107032019",
    "district_id": "110703",
    "label": "Seulatan",
    "value": "Seulatan"
  },
  {
    "id": "1107032020",
    "district_id": "110703",
    "label": "Alue Lada",
    "value": "Alue Lada"
  },
  {
    "id": "1107032021",
    "district_id": "110703",
    "label": "Teupin Jeue",
    "value": "Teupin Jeue"
  },
  {
    "id": "1107032022",
    "district_id": "110703",
    "label": "Neuheun",
    "value": "Neuheun"
  },
  {
    "id": "1107032023",
    "district_id": "110703",
    "label": "Crueng",
    "value": "Crueng"
  },
  {
    "id": "1107032024",
    "district_id": "110703",
    "label": "Kareung",
    "value": "Kareung"
  },
  {
    "id": "1107032025",
    "district_id": "110703",
    "label": "Kulee",
    "value": "Kulee"
  },
  {
    "id": "1107032026",
    "district_id": "110703",
    "label": "Kulam",
    "value": "Kulam"
  },
  {
    "id": "1107032027",
    "district_id": "110703",
    "label": "Awe",
    "value": "Awe"
  },
  {
    "id": "1107032028",
    "district_id": "110703",
    "label": "Pasi Beurandeh",
    "value": "Pasi Beurandeh"
  },
  {
    "id": "1107042001",
    "district_id": "110704",
    "label": "Tunong",
    "value": "Tunong"
  },
  {
    "id": "1107042002",
    "district_id": "110704",
    "label": "Raya",
    "value": "Raya"
  },
  {
    "id": "1107042003",
    "district_id": "110704",
    "label": "Daboh",
    "value": "Daboh"
  },
  {
    "id": "1107042004",
    "district_id": "110704",
    "label": "Cut",
    "value": "Cut"
  },
  {
    "id": "1107042005",
    "district_id": "110704",
    "label": "Mesjid Reubee",
    "value": "Mesjid Reubee"
  },
  {
    "id": "1107042006",
    "district_id": "110704",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "1107042007",
    "district_id": "110704",
    "label": "Geudong",
    "value": "Geudong"
  },
  {
    "id": "1107042008",
    "district_id": "110704",
    "label": "Reuntoh",
    "value": "Reuntoh"
  },
  {
    "id": "1107042009",
    "district_id": "110704",
    "label": "Reuba",
    "value": "Reuba"
  },
  {
    "id": "1107042010",
    "district_id": "110704",
    "label": "Mns. Krueng",
    "value": "Mns. Krueng"
  },
  {
    "id": "1107042011",
    "district_id": "110704",
    "label": "Neulop",
    "value": "Neulop"
  },
  {
    "id": "1107042012",
    "district_id": "110704",
    "label": "Glee",
    "value": "Glee"
  },
  {
    "id": "1107042013",
    "district_id": "110704",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1107042014",
    "district_id": "110704",
    "label": "Buloh",
    "value": "Buloh"
  },
  {
    "id": "1107042015",
    "district_id": "110704",
    "label": "Bungo",
    "value": "Bungo"
  },
  {
    "id": "1107042016",
    "district_id": "110704",
    "label": "Dayah Reubee",
    "value": "Dayah Reubee"
  },
  {
    "id": "1107042017",
    "district_id": "110704",
    "label": "Jambee",
    "value": "Jambee"
  },
  {
    "id": "1107042018",
    "district_id": "110704",
    "label": "Dayah Baro",
    "value": "Dayah Baro"
  },
  {
    "id": "1107042019",
    "district_id": "110704",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1107042020",
    "district_id": "110704",
    "label": "Lhee Meunasah",
    "value": "Lhee Meunasah"
  },
  {
    "id": "1107042021",
    "district_id": "110704",
    "label": "Ulee Tutue Raya",
    "value": "Ulee Tutue Raya"
  },
  {
    "id": "1107042022",
    "district_id": "110704",
    "label": "Mesjid Aree",
    "value": "Mesjid Aree"
  },
  {
    "id": "1107042023",
    "district_id": "110704",
    "label": "Keutapang Aree",
    "value": "Keutapang Aree"
  },
  {
    "id": "1107042024",
    "district_id": "110704",
    "label": "Pantee",
    "value": "Pantee"
  },
  {
    "id": "1107042025",
    "district_id": "110704",
    "label": "Pulo Tunong",
    "value": "Pulo Tunong"
  },
  {
    "id": "1107042026",
    "district_id": "110704",
    "label": "Pulo Baroh Aree",
    "value": "Pulo Baroh Aree"
  },
  {
    "id": "1107042027",
    "district_id": "110704",
    "label": "Keurumbok",
    "value": "Keurumbok"
  },
  {
    "id": "1107042028",
    "district_id": "110704",
    "label": "Ceurih Blang Mee",
    "value": "Ceurih Blang Mee"
  },
  {
    "id": "1107042029",
    "district_id": "110704",
    "label": "Ceurih Kupula",
    "value": "Ceurih Kupula"
  },
  {
    "id": "1107042030",
    "district_id": "110704",
    "label": "Ceurih Alue",
    "value": "Ceurih Alue"
  },
  {
    "id": "1107042031",
    "district_id": "110704",
    "label": "Ceurih Cot",
    "value": "Ceurih Cot"
  },
  {
    "id": "1107042032",
    "district_id": "110704",
    "label": "Seupeung",
    "value": "Seupeung"
  },
  {
    "id": "1107042033",
    "district_id": "110704",
    "label": "Sukon Lhong",
    "value": "Sukon Lhong"
  },
  {
    "id": "1107042034",
    "district_id": "110704",
    "label": "Dayah Beuah",
    "value": "Dayah Beuah"
  },
  {
    "id": "1107042035",
    "district_id": "110704",
    "label": "Krueng Cot",
    "value": "Krueng Cot"
  },
  {
    "id": "1107042036",
    "district_id": "110704",
    "label": "Mesjid Beuah",
    "value": "Mesjid Beuah"
  },
  {
    "id": "1107042037",
    "district_id": "110704",
    "label": "Lueng Dama",
    "value": "Lueng Dama"
  },
  {
    "id": "1107042038",
    "district_id": "110704",
    "label": "Mesjid Bambong",
    "value": "Mesjid Bambong"
  },
  {
    "id": "1107042039",
    "district_id": "110704",
    "label": "Reuseb",
    "value": "Reuseb"
  },
  {
    "id": "1107042040",
    "district_id": "110704",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1107042041",
    "district_id": "110704",
    "label": "Seukeum",
    "value": "Seukeum"
  },
  {
    "id": "1107042042",
    "district_id": "110704",
    "label": "Pengoe",
    "value": "Pengoe"
  },
  {
    "id": "1107042043",
    "district_id": "110704",
    "label": "Keutapang Bambong",
    "value": "Keutapang Bambong"
  },
  {
    "id": "1107042044",
    "district_id": "110704",
    "label": "Metareum",
    "value": "Metareum"
  },
  {
    "id": "1107052001",
    "district_id": "110705",
    "label": "Bangkeh",
    "value": "Bangkeh"
  },
  {
    "id": "1107052002",
    "district_id": "110705",
    "label": "Pucok",
    "value": "Pucok"
  },
  {
    "id": "1107052004",
    "district_id": "110705",
    "label": "Leupu",
    "value": "Leupu"
  },
  {
    "id": "1107052005",
    "district_id": "110705",
    "label": "Pulo Lhoih",
    "value": "Pulo Lhoih"
  },
  {
    "id": "1107052006",
    "district_id": "110705",
    "label": "Keune",
    "value": "Keune"
  },
  {
    "id": "1107062001",
    "district_id": "110706",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1107062002",
    "district_id": "110706",
    "label": "Krueng Jangko",
    "value": "Krueng Jangko"
  },
  {
    "id": "1107062003",
    "district_id": "110706",
    "label": "Krueng Nyong",
    "value": "Krueng Nyong"
  },
  {
    "id": "1107062004",
    "district_id": "110706",
    "label": "Cot Baroh",
    "value": "Cot Baroh"
  },
  {
    "id": "1107062005",
    "district_id": "110706",
    "label": "Cot Tunong",
    "value": "Cot Tunong"
  },
  {
    "id": "1107062006",
    "district_id": "110706",
    "label": "Bili",
    "value": "Bili"
  },
  {
    "id": "1107062007",
    "district_id": "110706",
    "label": "Panjoe",
    "value": "Panjoe"
  },
  {
    "id": "1107062008",
    "district_id": "110706",
    "label": "Amud Mesjid",
    "value": "Amud Mesjid"
  },
  {
    "id": "1107062009",
    "district_id": "110706",
    "label": "Reudeup Meulayu",
    "value": "Reudeup Meulayu"
  },
  {
    "id": "1107062010",
    "district_id": "110706",
    "label": "Dayah Tanoh",
    "value": "Dayah Tanoh"
  },
  {
    "id": "1107062011",
    "district_id": "110706",
    "label": "Mamplam",
    "value": "Mamplam"
  },
  {
    "id": "1107062012",
    "district_id": "110706",
    "label": "Balee",
    "value": "Balee"
  },
  {
    "id": "1107062013",
    "district_id": "110706",
    "label": "Kupula",
    "value": "Kupula"
  },
  {
    "id": "1107062014",
    "district_id": "110706",
    "label": "Pulo Lueng Teuga",
    "value": "Pulo Lueng Teuga"
  },
  {
    "id": "1107062015",
    "district_id": "110706",
    "label": "Sukon Mesjid",
    "value": "Sukon Mesjid"
  },
  {
    "id": "1107062016",
    "district_id": "110706",
    "label": "Sukon Baroh",
    "value": "Sukon Baroh"
  },
  {
    "id": "1107062017",
    "district_id": "110706",
    "label": "Kruet Teumpeun",
    "value": "Kruet Teumpeun"
  },
  {
    "id": "1107062018",
    "district_id": "110706",
    "label": "Blang Drang",
    "value": "Blang Drang"
  },
  {
    "id": "1107062019",
    "district_id": "110706",
    "label": "Ude Gampong",
    "value": "Ude Gampong"
  },
  {
    "id": "1107062020",
    "district_id": "110706",
    "label": "Kayee Jatoe",
    "value": "Kayee Jatoe"
  },
  {
    "id": "1107062021",
    "district_id": "110706",
    "label": "Kampong Jeumpa",
    "value": "Kampong Jeumpa"
  },
  {
    "id": "1107062022",
    "district_id": "110706",
    "label": "Pulo Lon / Pulo Dayah",
    "value": "Pulo Lon / Pulo Dayah"
  },
  {
    "id": "1107062023",
    "district_id": "110706",
    "label": "Jurong Pande",
    "value": "Jurong Pande"
  },
  {
    "id": "1107062024",
    "district_id": "110706",
    "label": "Pulo Gajah Mate",
    "value": "Pulo Gajah Mate"
  },
  {
    "id": "1107062025",
    "district_id": "110706",
    "label": "Tufah Jeulatang",
    "value": "Tufah Jeulatang"
  },
  {
    "id": "1107062026",
    "district_id": "110706",
    "label": "Kumbang Keupula",
    "value": "Kumbang Keupula"
  },
  {
    "id": "1107062027",
    "district_id": "110706",
    "label": "Pulo Batee",
    "value": "Pulo Batee"
  },
  {
    "id": "1107062028",
    "district_id": "110706",
    "label": "Meunjee",
    "value": "Meunjee"
  },
  {
    "id": "1107062029",
    "district_id": "110706",
    "label": "Keutapang Mesjid",
    "value": "Keutapang Mesjid"
  },
  {
    "id": "1107062030",
    "district_id": "110706",
    "label": "Dayah Kp. Pisang",
    "value": "Dayah Kp. Pisang"
  },
  {
    "id": "1107062031",
    "district_id": "110706",
    "label": "Blang Tunong",
    "value": "Blang Tunong"
  },
  {
    "id": "1107062032",
    "district_id": "110706",
    "label": "Blang Pueb",
    "value": "Blang Pueb"
  },
  {
    "id": "1107062033",
    "district_id": "110706",
    "label": "Lambaro",
    "value": "Lambaro"
  },
  {
    "id": "1107062034",
    "district_id": "110706",
    "label": "Neurok",
    "value": "Neurok"
  },
  {
    "id": "1107072001",
    "district_id": "110707",
    "label": "Tampieng Tunong",
    "value": "Tampieng Tunong"
  },
  {
    "id": "1107072002",
    "district_id": "110707",
    "label": "Tampieng Baroh",
    "value": "Tampieng Baroh"
  },
  {
    "id": "1107072003",
    "district_id": "110707",
    "label": "Dayah Caleue",
    "value": "Dayah Caleue"
  },
  {
    "id": "1107072004",
    "district_id": "110707",
    "label": "Jurong",
    "value": "Jurong"
  },
  {
    "id": "1107072005",
    "district_id": "110707",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1107072006",
    "district_id": "110707",
    "label": "Dayah Keurako",
    "value": "Dayah Keurako"
  },
  {
    "id": "1107072007",
    "district_id": "110707",
    "label": "Mesjid Lam Ujong",
    "value": "Mesjid Lam Ujong"
  },
  {
    "id": "1107072008",
    "district_id": "110707",
    "label": "Baro Jruek",
    "value": "Baro Jruek"
  },
  {
    "id": "1107072009",
    "district_id": "110707",
    "label": "Yub Mee",
    "value": "Yub Mee"
  },
  {
    "id": "1107072010",
    "district_id": "110707",
    "label": "Raya",
    "value": "Raya"
  },
  {
    "id": "1107072011",
    "district_id": "110707",
    "label": "Pulo Gampong U",
    "value": "Pulo Gampong U"
  },
  {
    "id": "1107072012",
    "district_id": "110707",
    "label": "Pante Lhok Kaju",
    "value": "Pante Lhok Kaju"
  },
  {
    "id": "1107072014",
    "district_id": "110707",
    "label": "Sukon Tungkop",
    "value": "Sukon Tungkop"
  },
  {
    "id": "1107072015",
    "district_id": "110707",
    "label": "Rawa Tungkop",
    "value": "Rawa Tungkop"
  },
  {
    "id": "1107072016",
    "district_id": "110707",
    "label": "Mesjid Tungkop",
    "value": "Mesjid Tungkop"
  },
  {
    "id": "1107072017",
    "district_id": "110707",
    "label": "Dayah Muara",
    "value": "Dayah Muara"
  },
  {
    "id": "1107072018",
    "district_id": "110707",
    "label": "Pante Garot",
    "value": "Pante Garot"
  },
  {
    "id": "1107072019",
    "district_id": "110707",
    "label": "Garot Cut",
    "value": "Garot Cut"
  },
  {
    "id": "1107072020",
    "district_id": "110707",
    "label": "Keubang",
    "value": "Keubang"
  },
  {
    "id": "1107072021",
    "district_id": "110707",
    "label": "Blang Garot",
    "value": "Blang Garot"
  },
  {
    "id": "1107072022",
    "district_id": "110707",
    "label": "Meulayu",
    "value": "Meulayu"
  },
  {
    "id": "1107072023",
    "district_id": "110707",
    "label": "Tungkop Cut",
    "value": "Tungkop Cut"
  },
  {
    "id": "1107072024",
    "district_id": "110707",
    "label": "Guci",
    "value": "Guci"
  },
  {
    "id": "1107072025",
    "district_id": "110707",
    "label": "Teungoh Blang",
    "value": "Teungoh Blang"
  },
  {
    "id": "1107072026",
    "district_id": "110707",
    "label": "Mesjid Dijiem",
    "value": "Mesjid Dijiem"
  },
  {
    "id": "1107072027",
    "district_id": "110707",
    "label": "Sukon Ulee Gampong",
    "value": "Sukon Ulee Gampong"
  },
  {
    "id": "1107072028",
    "district_id": "110707",
    "label": "Lamkabu",
    "value": "Lamkabu"
  },
  {
    "id": "1107072029",
    "district_id": "110707",
    "label": "Ulee Birah",
    "value": "Ulee Birah"
  },
  {
    "id": "1107072030",
    "district_id": "110707",
    "label": "Mesjid Baro",
    "value": "Mesjid Baro"
  },
  {
    "id": "1107072031",
    "district_id": "110707",
    "label": "Lamreuneung",
    "value": "Lamreuneung"
  },
  {
    "id": "1107072032",
    "district_id": "110707",
    "label": "Cot Seuke",
    "value": "Cot Seuke"
  },
  {
    "id": "1107072033",
    "district_id": "110707",
    "label": "Drien",
    "value": "Drien"
  },
  {
    "id": "1107072034",
    "district_id": "110707",
    "label": "Rumia",
    "value": "Rumia"
  },
  {
    "id": "1107072035",
    "district_id": "110707",
    "label": "Wakheuh",
    "value": "Wakheuh"
  },
  {
    "id": "1107072036",
    "district_id": "110707",
    "label": "Baro Bluek",
    "value": "Baro Bluek"
  },
  {
    "id": "1107072037",
    "district_id": "110707",
    "label": "Mesjid Ulee Gampong",
    "value": "Mesjid Ulee Gampong"
  },
  {
    "id": "1107072038",
    "district_id": "110707",
    "label": "Dayah Bie",
    "value": "Dayah Bie"
  },
  {
    "id": "1107072039",
    "district_id": "110707",
    "label": "Bale Baro Bluek",
    "value": "Bale Baro Bluek"
  },
  {
    "id": "1107072040",
    "district_id": "110707",
    "label": "Putoe Gapui",
    "value": "Putoe Gapui"
  },
  {
    "id": "1107072041",
    "district_id": "110707",
    "label": "Blang Rapai",
    "value": "Blang Rapai"
  },
  {
    "id": "1107072042",
    "district_id": "110707",
    "label": "Neulop II",
    "value": "Neulop II"
  },
  {
    "id": "1107072043",
    "district_id": "110707",
    "label": "Baro Gapui",
    "value": "Baro Gapui"
  },
  {
    "id": "1107072044",
    "district_id": "110707",
    "label": "Balee Baroh Gapui",
    "value": "Balee Baroh Gapui"
  },
  {
    "id": "1107072045",
    "district_id": "110707",
    "label": "Glee Gapu",
    "value": "Glee Gapu"
  },
  {
    "id": "1107072046",
    "district_id": "110707",
    "label": "Teungoh Suwiek",
    "value": "Teungoh Suwiek"
  },
  {
    "id": "1107072047",
    "district_id": "110707",
    "label": "Mesjid Suwiek",
    "value": "Mesjid Suwiek"
  },
  {
    "id": "1107072048",
    "district_id": "110707",
    "label": "Tuha Suwiek",
    "value": "Tuha Suwiek"
  },
  {
    "id": "1107072049",
    "district_id": "110707",
    "label": "Beureudep",
    "value": "Beureudep"
  },
  {
    "id": "1107072050",
    "district_id": "110707",
    "label": "Blang Lhok Kaju",
    "value": "Blang Lhok Kaju"
  },
  {
    "id": "1107082001",
    "district_id": "110708",
    "label": "Jurong Balee",
    "value": "Jurong Balee"
  },
  {
    "id": "1107082002",
    "district_id": "110708",
    "label": "Aron Asan Kumbang",
    "value": "Aron Asan Kumbang"
  },
  {
    "id": "1107082003",
    "district_id": "110708",
    "label": "Cebrek",
    "value": "Cebrek"
  },
  {
    "id": "1107082004",
    "district_id": "110708",
    "label": "Jurong Mesjid",
    "value": "Jurong Mesjid"
  },
  {
    "id": "1107082005",
    "district_id": "110708",
    "label": "Meunasah Gantung",
    "value": "Meunasah Gantung"
  },
  {
    "id": "1107082006",
    "district_id": "110708",
    "label": "Babah Jurong",
    "value": "Babah Jurong"
  },
  {
    "id": "1107082007",
    "district_id": "110708",
    "label": "Keupula",
    "value": "Keupula"
  },
  {
    "id": "1107082008",
    "district_id": "110708",
    "label": "Jareng",
    "value": "Jareng"
  },
  {
    "id": "1107082009",
    "district_id": "110708",
    "label": "Arusan",
    "value": "Arusan"
  },
  {
    "id": "1107082010",
    "district_id": "110708",
    "label": "Tanjong Krueng",
    "value": "Tanjong Krueng"
  },
  {
    "id": "1107082011",
    "district_id": "110708",
    "label": "Lancang",
    "value": "Lancang"
  },
  {
    "id": "1107082012",
    "district_id": "110708",
    "label": "Pasi Lhok",
    "value": "Pasi Lhok"
  },
  {
    "id": "1107082013",
    "district_id": "110708",
    "label": "Jeumeurang",
    "value": "Jeumeurang"
  },
  {
    "id": "1107082014",
    "district_id": "110708",
    "label": "Pusong",
    "value": "Pusong"
  },
  {
    "id": "1107082015",
    "district_id": "110708",
    "label": "Meuraksa",
    "value": "Meuraksa"
  },
  {
    "id": "1107082016",
    "district_id": "110708",
    "label": "Kayee Panyang",
    "value": "Kayee Panyang"
  },
  {
    "id": "1107082017",
    "district_id": "110708",
    "label": "Kampong Panjoe",
    "value": "Kampong Panjoe"
  },
  {
    "id": "1107082018",
    "district_id": "110708",
    "label": "Kampong Asan",
    "value": "Kampong Asan"
  },
  {
    "id": "1107082019",
    "district_id": "110708",
    "label": "Meunasah Mesjid",
    "value": "Meunasah Mesjid"
  },
  {
    "id": "1107082020",
    "district_id": "110708",
    "label": "Meunasah Mee",
    "value": "Meunasah Mee"
  },
  {
    "id": "1107082021",
    "district_id": "110708",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1107082022",
    "district_id": "110708",
    "label": "Pasi ie Leubeue",
    "value": "Pasi ie Leubeue"
  },
  {
    "id": "1107082023",
    "district_id": "110708",
    "label": "Keude ie Leubeue",
    "value": "Keude ie Leubeue"
  },
  {
    "id": "1107082024",
    "district_id": "110708",
    "label": "Sukon",
    "value": "Sukon"
  },
  {
    "id": "1107082025",
    "district_id": "110708",
    "label": "Ara",
    "value": "Ara"
  },
  {
    "id": "1107082026",
    "district_id": "110708",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1107082027",
    "district_id": "110708",
    "label": "Krueng Dhoe",
    "value": "Krueng Dhoe"
  },
  {
    "id": "1107082028",
    "district_id": "110708",
    "label": "Meunasah Baro",
    "value": "Meunasah Baro"
  },
  {
    "id": "1107082029",
    "district_id": "110708",
    "label": "Bentayan",
    "value": "Bentayan"
  },
  {
    "id": "1107082030",
    "district_id": "110708",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1107082031",
    "district_id": "110708",
    "label": "Lamkawe",
    "value": "Lamkawe"
  },
  {
    "id": "1107082032",
    "district_id": "110708",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1107082033",
    "district_id": "110708",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "1107082034",
    "district_id": "110708",
    "label": "Dayah Blang",
    "value": "Dayah Blang"
  },
  {
    "id": "1107082035",
    "district_id": "110708",
    "label": "Dayah Mon Ara",
    "value": "Dayah Mon Ara"
  },
  {
    "id": "1107082036",
    "district_id": "110708",
    "label": "Keureumbok",
    "value": "Keureumbok"
  },
  {
    "id": "1107082037",
    "district_id": "110708",
    "label": "Manyang",
    "value": "Manyang"
  },
  {
    "id": "1107082038",
    "district_id": "110708",
    "label": "Matang Kuli",
    "value": "Matang Kuli"
  },
  {
    "id": "1107082039",
    "district_id": "110708",
    "label": "Reung-Reung",
    "value": "Reung-Reung"
  },
  {
    "id": "1107082040",
    "district_id": "110708",
    "label": "Teumpeun",
    "value": "Teumpeun"
  },
  {
    "id": "1107082041",
    "district_id": "110708",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1107082042",
    "district_id": "110708",
    "label": "Geulumpang",
    "value": "Geulumpang"
  },
  {
    "id": "1107082043",
    "district_id": "110708",
    "label": "Aron Kuta Baro",
    "value": "Aron Kuta Baro"
  },
  {
    "id": "1107082044",
    "district_id": "110708",
    "label": "Kampong Barat",
    "value": "Kampong Barat"
  },
  {
    "id": "1107082045",
    "district_id": "110708",
    "label": "Kampong Araih",
    "value": "Kampong Araih"
  },
  {
    "id": "1107092001",
    "district_id": "110709",
    "label": "Blang Paseh",
    "value": "Blang Paseh"
  },
  {
    "id": "1107092002",
    "district_id": "110709",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1107092003",
    "district_id": "110709",
    "label": "Blok Bengkel",
    "value": "Blok Bengkel"
  },
  {
    "id": "1107092004",
    "district_id": "110709",
    "label": "Pante Teungoh",
    "value": "Pante Teungoh"
  },
  {
    "id": "1107092005",
    "district_id": "110709",
    "label": "Kuala Pidie",
    "value": "Kuala Pidie"
  },
  {
    "id": "1107092006",
    "district_id": "110709",
    "label": "Keramat Luar",
    "value": "Keramat Luar"
  },
  {
    "id": "1107092007",
    "district_id": "110709",
    "label": "Keramat Dalam",
    "value": "Keramat Dalam"
  },
  {
    "id": "1107092008",
    "district_id": "110709",
    "label": "Blok Sawah",
    "value": "Blok Sawah"
  },
  {
    "id": "1107092009",
    "district_id": "110709",
    "label": "Blang Asan",
    "value": "Blang Asan"
  },
  {
    "id": "1107092010",
    "district_id": "110709",
    "label": "Lampoh Krueng",
    "value": "Lampoh Krueng"
  },
  {
    "id": "1107092011",
    "district_id": "110709",
    "label": "Kampong Asan",
    "value": "Kampong Asan"
  },
  {
    "id": "1107092012",
    "district_id": "110709",
    "label": "Meunasah Peukan",
    "value": "Meunasah Peukan"
  },
  {
    "id": "1107092013",
    "district_id": "110709",
    "label": "Pasi Rawa",
    "value": "Pasi Rawa"
  },
  {
    "id": "1107092014",
    "district_id": "110709",
    "label": "Pasi Peukan Baro",
    "value": "Pasi Peukan Baro"
  },
  {
    "id": "1107092015",
    "district_id": "110709",
    "label": "Tanjong Krueng",
    "value": "Tanjong Krueng"
  },
  {
    "id": "1107112001",
    "district_id": "110711",
    "label": "Blang",
    "value": "Blang"
  },
  {
    "id": "1107112002",
    "district_id": "110711",
    "label": "Teumeucet",
    "value": "Teumeucet"
  },
  {
    "id": "1107112003",
    "district_id": "110711",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1107112004",
    "district_id": "110711",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1107112005",
    "district_id": "110711",
    "label": "Pulo Tanjong",
    "value": "Pulo Tanjong"
  },
  {
    "id": "1107112006",
    "district_id": "110711",
    "label": "Ara Bungkok",
    "value": "Ara Bungkok"
  },
  {
    "id": "1107112007",
    "district_id": "110711",
    "label": "Lagang",
    "value": "Lagang"
  },
  {
    "id": "1107112008",
    "district_id": "110711",
    "label": "Mesjid Ilot",
    "value": "Mesjid Ilot"
  },
  {
    "id": "1107112009",
    "district_id": "110711",
    "label": "Baroh Ilot",
    "value": "Baroh Ilot"
  },
  {
    "id": "1107112010",
    "district_id": "110711",
    "label": "Tunong Ilot",
    "value": "Tunong Ilot"
  },
  {
    "id": "1107112011",
    "district_id": "110711",
    "label": "Teungoh Ilot",
    "value": "Teungoh Ilot"
  },
  {
    "id": "1107112012",
    "district_id": "110711",
    "label": "Dayah Andeue",
    "value": "Dayah Andeue"
  },
  {
    "id": "1107112013",
    "district_id": "110711",
    "label": "Dayah Sinthop",
    "value": "Dayah Sinthop"
  },
  {
    "id": "1107112014",
    "district_id": "110711",
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "id": "1107112015",
    "district_id": "110711",
    "label": "Mesjid Andeue",
    "value": "Mesjid Andeue"
  },
  {
    "id": "1107112016",
    "district_id": "110711",
    "label": "Meuyub Lala",
    "value": "Meuyub Lala"
  },
  {
    "id": "1107112017",
    "district_id": "110711",
    "label": "Tuha Lada",
    "value": "Tuha Lada"
  },
  {
    "id": "1107112018",
    "district_id": "110711",
    "label": "Lhok Lubu",
    "value": "Lhok Lubu"
  },
  {
    "id": "1107112019",
    "district_id": "110711",
    "label": "Babah Jurong",
    "value": "Babah Jurong"
  },
  {
    "id": "1107112020",
    "district_id": "110711",
    "label": "Krueng Lala",
    "value": "Krueng Lala"
  },
  {
    "id": "1107122001",
    "district_id": "110712",
    "label": "Cot",
    "value": "Cot"
  },
  {
    "id": "1107122002",
    "district_id": "110712",
    "label": "Tgk Di Laweung",
    "value": "Tgk Di Laweung"
  },
  {
    "id": "1107122003",
    "district_id": "110712",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1107122004",
    "district_id": "110712",
    "label": "Keupula",
    "value": "Keupula"
  },
  {
    "id": "1107122005",
    "district_id": "110712",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1107122006",
    "district_id": "110712",
    "label": "Pawod",
    "value": "Pawod"
  },
  {
    "id": "1107122007",
    "district_id": "110712",
    "label": "Deyah",
    "value": "Deyah"
  },
  {
    "id": "1107122008",
    "district_id": "110712",
    "label": "Krueng",
    "value": "Krueng"
  },
  {
    "id": "1107122009",
    "district_id": "110712",
    "label": "Gle Cut",
    "value": "Gle Cut"
  },
  {
    "id": "1107122010",
    "district_id": "110712",
    "label": "IE Masen",
    "value": "IE Masen"
  },
  {
    "id": "1107122011",
    "district_id": "110712",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1107122012",
    "district_id": "110712",
    "label": "Ujong Pie",
    "value": "Ujong Pie"
  },
  {
    "id": "1107122013",
    "district_id": "110712",
    "label": "Blang Raya",
    "value": "Blang Raya"
  },
  {
    "id": "1107122014",
    "district_id": "110712",
    "label": "Batee",
    "value": "Batee"
  },
  {
    "id": "1107122015",
    "district_id": "110712",
    "label": "Ingin Jaya",
    "value": "Ingin Jaya"
  },
  {
    "id": "1107122016",
    "district_id": "110712",
    "label": "Papeun",
    "value": "Papeun"
  },
  {
    "id": "1107122017",
    "district_id": "110712",
    "label": "Tuha Biheue",
    "value": "Tuha Biheue"
  },
  {
    "id": "1107122018",
    "district_id": "110712",
    "label": "Simpang Beutong",
    "value": "Simpang Beutong"
  },
  {
    "id": "1107132001",
    "district_id": "110713",
    "label": "Mesjid Yaman",
    "value": "Mesjid Yaman"
  },
  {
    "id": "1107132002",
    "district_id": "110713",
    "label": "Baro Yaman",
    "value": "Baro Yaman"
  },
  {
    "id": "1107132003",
    "district_id": "110713",
    "label": "Mee Teungoh",
    "value": "Mee Teungoh"
  },
  {
    "id": "1107132004",
    "district_id": "110713",
    "label": "Sentosa",
    "value": "Sentosa"
  },
  {
    "id": "1107132005",
    "district_id": "110713",
    "label": "Baroh Barat Yaman",
    "value": "Baroh Barat Yaman"
  },
  {
    "id": "1107132006",
    "district_id": "110713",
    "label": "Tuha Gampong Gajah",
    "value": "Tuha Gampong Gajah"
  },
  {
    "id": "1107132007",
    "district_id": "110713",
    "label": "Jadan",
    "value": "Jadan"
  },
  {
    "id": "1107132008",
    "district_id": "110713",
    "label": "Balee Keumangan",
    "value": "Balee Keumangan"
  },
  {
    "id": "1107132009",
    "district_id": "110713",
    "label": "Blang Tidiek",
    "value": "Blang Tidiek"
  },
  {
    "id": "1107132010",
    "district_id": "110713",
    "label": "Blang Lilue",
    "value": "Blang Lilue"
  },
  {
    "id": "1107132011",
    "district_id": "110713",
    "label": "Keumangan Cut",
    "value": "Keumangan Cut"
  },
  {
    "id": "1107132012",
    "district_id": "110713",
    "label": "Mesjid Keumangan",
    "value": "Mesjid Keumangan"
  },
  {
    "id": "1107132013",
    "district_id": "110713",
    "label": "Dayah Tidiek",
    "value": "Dayah Tidiek"
  },
  {
    "id": "1107132014",
    "district_id": "110713",
    "label": "Lueng Sagoe",
    "value": "Lueng Sagoe"
  },
  {
    "id": "1107132015",
    "district_id": "110713",
    "label": "Blang Beureueh",
    "value": "Blang Beureueh"
  },
  {
    "id": "1107132016",
    "district_id": "110713",
    "label": "Dayah Beureueh",
    "value": "Dayah Beureueh"
  },
  {
    "id": "1107132017",
    "district_id": "110713",
    "label": "Pante Beureueh",
    "value": "Pante Beureueh"
  },
  {
    "id": "1107132018",
    "district_id": "110713",
    "label": "Rapana",
    "value": "Rapana"
  },
  {
    "id": "1107132019",
    "district_id": "110713",
    "label": "Paya Tiba",
    "value": "Paya Tiba"
  },
  {
    "id": "1107132020",
    "district_id": "110713",
    "label": "Paloh Kambuek",
    "value": "Paloh Kambuek"
  },
  {
    "id": "1107132021",
    "district_id": "110713",
    "label": "Lampoh Sirong",
    "value": "Lampoh Sirong"
  },
  {
    "id": "1107132022",
    "district_id": "110713",
    "label": "Mee Panyang",
    "value": "Mee Panyang"
  },
  {
    "id": "1107132023",
    "district_id": "110713",
    "label": "Reului",
    "value": "Reului"
  },
  {
    "id": "1107132024",
    "district_id": "110713",
    "label": "Dayah Syarief",
    "value": "Dayah Syarief"
  },
  {
    "id": "1107132025",
    "district_id": "110713",
    "label": "Peureulak",
    "value": "Peureulak"
  },
  {
    "id": "1107132026",
    "district_id": "110713",
    "label": "Ribeun",
    "value": "Ribeun"
  },
  {
    "id": "1107132027",
    "district_id": "110713",
    "label": "Balee Busu",
    "value": "Balee Busu"
  },
  {
    "id": "1107132028",
    "district_id": "110713",
    "label": "Lingkok Busu",
    "value": "Lingkok Busu"
  },
  {
    "id": "1107132029",
    "district_id": "110713",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1107142001",
    "district_id": "110714",
    "label": "Mesjid Kunyet",
    "value": "Mesjid Kunyet"
  },
  {
    "id": "1107142002",
    "district_id": "110714",
    "label": "Piala Kunyet",
    "value": "Piala Kunyet"
  },
  {
    "id": "1107142003",
    "district_id": "110714",
    "label": "Hagu Kunyet",
    "value": "Hagu Kunyet"
  },
  {
    "id": "1107142004",
    "district_id": "110714",
    "label": "Baro Kunyet",
    "value": "Baro Kunyet"
  },
  {
    "id": "1107142005",
    "district_id": "110714",
    "label": "Dayah Tanoh Kunyet",
    "value": "Dayah Tanoh Kunyet"
  },
  {
    "id": "1107142006",
    "district_id": "110714",
    "label": "Kambuk Nicah Kunyet",
    "value": "Kambuk Nicah Kunyet"
  },
  {
    "id": "1107142007",
    "district_id": "110714",
    "label": "Kambuk Payapi Kunyet",
    "value": "Kambuk Payapi Kunyet"
  },
  {
    "id": "1107142008",
    "district_id": "110714",
    "label": "Seunadeu Kunyet",
    "value": "Seunadeu Kunyet"
  },
  {
    "id": "1107142009",
    "district_id": "110714",
    "label": "Blang Guci Kunyet",
    "value": "Blang Guci Kunyet"
  },
  {
    "id": "1107142010",
    "district_id": "110714",
    "label": "Dayah Baroh Kunyet",
    "value": "Dayah Baroh Kunyet"
  },
  {
    "id": "1107142011",
    "district_id": "110714",
    "label": "Blang Geuleudieng",
    "value": "Blang Geuleudieng"
  },
  {
    "id": "1107142012",
    "district_id": "110714",
    "label": "Geulumpang Geuleudieng",
    "value": "Geulumpang Geuleudieng"
  },
  {
    "id": "1107142013",
    "district_id": "110714",
    "label": "Mesjid Geuleudieng",
    "value": "Mesjid Geuleudieng"
  },
  {
    "id": "1107142014",
    "district_id": "110714",
    "label": "Sukon Peudaya",
    "value": "Sukon Peudaya"
  },
  {
    "id": "1107142015",
    "district_id": "110714",
    "label": "Mesjid Peudaya",
    "value": "Mesjid Peudaya"
  },
  {
    "id": "1107142016",
    "district_id": "110714",
    "label": "Cut Peudaya",
    "value": "Cut Peudaya"
  },
  {
    "id": "1107142017",
    "district_id": "110714",
    "label": "Buloh Peudaya",
    "value": "Buloh Peudaya"
  },
  {
    "id": "1107142018",
    "district_id": "110714",
    "label": "Perlak Peudaya",
    "value": "Perlak Peudaya"
  },
  {
    "id": "1107142019",
    "district_id": "110714",
    "label": "Dayah Baroh Peudaya",
    "value": "Dayah Baroh Peudaya"
  },
  {
    "id": "1107142020",
    "district_id": "110714",
    "label": "Teungoh Peudaya",
    "value": "Teungoh Peudaya"
  },
  {
    "id": "1107142021",
    "district_id": "110714",
    "label": "Tuha Peudaya",
    "value": "Tuha Peudaya"
  },
  {
    "id": "1107142022",
    "district_id": "110714",
    "label": "Tunong Peudaya",
    "value": "Tunong Peudaya"
  },
  {
    "id": "1107142023",
    "district_id": "110714",
    "label": "Buni Reulieng Peudaya",
    "value": "Buni Reulieng Peudaya"
  },
  {
    "id": "1107142024",
    "district_id": "110714",
    "label": "Trieng Paloh",
    "value": "Trieng Paloh"
  },
  {
    "id": "1107142025",
    "district_id": "110714",
    "label": "Leuhob Paloh",
    "value": "Leuhob Paloh"
  },
  {
    "id": "1107142026",
    "district_id": "110714",
    "label": "Cut Paloh",
    "value": "Cut Paloh"
  },
  {
    "id": "1107142027",
    "district_id": "110714",
    "label": "Suyo Paloh",
    "value": "Suyo Paloh"
  },
  {
    "id": "1107142028",
    "district_id": "110714",
    "label": "Kreb Paloh",
    "value": "Kreb Paloh"
  },
  {
    "id": "1107142029",
    "district_id": "110714",
    "label": "Capa Paloh",
    "value": "Capa Paloh"
  },
  {
    "id": "1107142030",
    "district_id": "110714",
    "label": "Jurong Anoe Paloh",
    "value": "Jurong Anoe Paloh"
  },
  {
    "id": "1107142031",
    "district_id": "110714",
    "label": "Balee Paloh",
    "value": "Balee Paloh"
  },
  {
    "id": "1107142032",
    "district_id": "110714",
    "label": "Siron Paloh",
    "value": "Siron Paloh"
  },
  {
    "id": "1107142033",
    "district_id": "110714",
    "label": "Jurong Gampong Cut Paloh",
    "value": "Jurong Gampong Cut Paloh"
  },
  {
    "id": "1107142034",
    "district_id": "110714",
    "label": "Pante Cermen Paloh",
    "value": "Pante Cermen Paloh"
  },
  {
    "id": "1107142035",
    "district_id": "110714",
    "label": "Pasar Paloh",
    "value": "Pasar Paloh"
  },
  {
    "id": "1107142036",
    "district_id": "110714",
    "label": "Mesjid Paloh",
    "value": "Mesjid Paloh"
  },
  {
    "id": "1107142037",
    "district_id": "110714",
    "label": "Meuke Beurabo",
    "value": "Meuke Beurabo"
  },
  {
    "id": "1107142038",
    "district_id": "110714",
    "label": "Mesjid Beurabo",
    "value": "Mesjid Beurabo"
  },
  {
    "id": "1107142039",
    "district_id": "110714",
    "label": "Seukeumbrok Beurabo",
    "value": "Seukeumbrok Beurabo"
  },
  {
    "id": "1107142040",
    "district_id": "110714",
    "label": "Baro Beurabo",
    "value": "Baro Beurabo"
  },
  {
    "id": "1107142041",
    "district_id": "110714",
    "label": "Adang Beurabo",
    "value": "Adang Beurabo"
  },
  {
    "id": "1107142042",
    "district_id": "110714",
    "label": "Paloh Jeurat Beurabo",
    "value": "Paloh Jeurat Beurabo"
  },
  {
    "id": "1107142043",
    "district_id": "110714",
    "label": "Seuleunggieng Beurabo",
    "value": "Seuleunggieng Beurabo"
  },
  {
    "id": "1107142044",
    "district_id": "110714",
    "label": "Teungoh Drien Gogo",
    "value": "Teungoh Drien Gogo"
  },
  {
    "id": "1107142045",
    "district_id": "110714",
    "label": "Kumbang Gogo",
    "value": "Kumbang Gogo"
  },
  {
    "id": "1107142046",
    "district_id": "110714",
    "label": "Mesjid Gogo",
    "value": "Mesjid Gogo"
  },
  {
    "id": "1107142047",
    "district_id": "110714",
    "label": "Tuha Gogo",
    "value": "Tuha Gogo"
  },
  {
    "id": "1107142048",
    "district_id": "110714",
    "label": "Glee Gogo",
    "value": "Glee Gogo"
  },
  {
    "id": "1107142049",
    "district_id": "110714",
    "label": "Meuke Gogo",
    "value": "Meuke Gogo"
  },
  {
    "id": "1107142050",
    "district_id": "110714",
    "label": "Raya Gogo",
    "value": "Raya Gogo"
  },
  {
    "id": "1107142051",
    "district_id": "110714",
    "label": "Grong-Grong Gogo",
    "value": "Grong-Grong Gogo"
  },
  {
    "id": "1107142052",
    "district_id": "110714",
    "label": "Aron Bunot Gogo",
    "value": "Aron Bunot Gogo"
  },
  {
    "id": "1107142053",
    "district_id": "110714",
    "label": "Buloh Gogo",
    "value": "Buloh Gogo"
  },
  {
    "id": "1107142054",
    "district_id": "110714",
    "label": "Tonoeng Tanjong",
    "value": "Tonoeng Tanjong"
  },
  {
    "id": "1107142055",
    "district_id": "110714",
    "label": "Keupula Tanjong",
    "value": "Keupula Tanjong"
  },
  {
    "id": "1107142056",
    "district_id": "110714",
    "label": "Jok Tanjong",
    "value": "Jok Tanjong"
  },
  {
    "id": "1107142057",
    "district_id": "110714",
    "label": "Leun Tanjong",
    "value": "Leun Tanjong"
  },
  {
    "id": "1107142058",
    "district_id": "110714",
    "label": "Pante Crung Tanjong",
    "value": "Pante Crung Tanjong"
  },
  {
    "id": "1107142059",
    "district_id": "110714",
    "label": "Cot Keutapang Tanjong",
    "value": "Cot Keutapang Tanjong"
  },
  {
    "id": "1107142060",
    "district_id": "110714",
    "label": "Siron Tanjong",
    "value": "Siron Tanjong"
  },
  {
    "id": "1107142061",
    "district_id": "110714",
    "label": "Mesjid Tanjong",
    "value": "Mesjid Tanjong"
  },
  {
    "id": "1107142062",
    "district_id": "110714",
    "label": "Khang Tanjong",
    "value": "Khang Tanjong"
  },
  {
    "id": "1107142063",
    "district_id": "110714",
    "label": "Meuriya Tanjong",
    "value": "Meuriya Tanjong"
  },
  {
    "id": "1107142064",
    "district_id": "110714",
    "label": "Pulo Hagu Tanjong",
    "value": "Pulo Hagu Tanjong"
  },
  {
    "id": "1107152001",
    "district_id": "110715",
    "label": "Dua Paya",
    "value": "Dua Paya"
  },
  {
    "id": "1107152002",
    "district_id": "110715",
    "label": "Cot Mulu",
    "value": "Cot Mulu"
  },
  {
    "id": "1107152003",
    "district_id": "110715",
    "label": "Dayah Bubue",
    "value": "Dayah Bubue"
  },
  {
    "id": "1107152004",
    "district_id": "110715",
    "label": "Tanjong Hagu",
    "value": "Tanjong Hagu"
  },
  {
    "id": "1107152005",
    "district_id": "110715",
    "label": "Sumboe Buga",
    "value": "Sumboe Buga"
  },
  {
    "id": "1107152006",
    "district_id": "110715",
    "label": "Rambayan Kupula",
    "value": "Rambayan Kupula"
  },
  {
    "id": "1107152007",
    "district_id": "110715",
    "label": "Rambayan Lueng",
    "value": "Rambayan Lueng"
  },
  {
    "id": "1107152008",
    "district_id": "110715",
    "label": "Cempala Kuneng",
    "value": "Cempala Kuneng"
  },
  {
    "id": "1107152009",
    "district_id": "110715",
    "label": "Peunayong",
    "value": "Peunayong"
  },
  {
    "id": "1107152010",
    "district_id": "110715",
    "label": "Leuhop",
    "value": "Leuhop"
  },
  {
    "id": "1107152011",
    "district_id": "110715",
    "label": "Kumbang waido",
    "value": "Kumbang waido"
  },
  {
    "id": "1107152012",
    "district_id": "110715",
    "label": "Gampong waido",
    "value": "Gampong waido"
  },
  {
    "id": "1107152013",
    "district_id": "110715",
    "label": "Mee Krukon",
    "value": "Mee Krukon"
  },
  {
    "id": "1107152014",
    "district_id": "110715",
    "label": "Mee Lampoih Saka",
    "value": "Mee Lampoih Saka"
  },
  {
    "id": "1107152015",
    "district_id": "110715",
    "label": "Lueng Guci Rumpong",
    "value": "Lueng Guci Rumpong"
  },
  {
    "id": "1107152016",
    "district_id": "110715",
    "label": "Dayah Seumideun",
    "value": "Dayah Seumideun"
  },
  {
    "id": "1107152017",
    "district_id": "110715",
    "label": "Mesjid Guci Rumpong",
    "value": "Mesjid Guci Rumpong"
  },
  {
    "id": "1107152018",
    "district_id": "110715",
    "label": "Reuba Waido",
    "value": "Reuba Waido"
  },
  {
    "id": "1107152019",
    "district_id": "110715",
    "label": "Dayah Tungku",
    "value": "Dayah Tungku"
  },
  {
    "id": "1107152020",
    "district_id": "110715",
    "label": "Jurong Raya",
    "value": "Jurong Raya"
  },
  {
    "id": "1107152021",
    "district_id": "110715",
    "label": "Balee Rastong",
    "value": "Balee Rastong"
  },
  {
    "id": "1107152022",
    "district_id": "110715",
    "label": "Lueng Mesjid",
    "value": "Lueng Mesjid"
  },
  {
    "id": "1107152023",
    "district_id": "110715",
    "label": "Teungoh Baroh",
    "value": "Teungoh Baroh"
  },
  {
    "id": "1107152024",
    "district_id": "110715",
    "label": "Mee Hagu",
    "value": "Mee Hagu"
  },
  {
    "id": "1107152025",
    "district_id": "110715",
    "label": "Dayah Sukon",
    "value": "Dayah Sukon"
  },
  {
    "id": "1107152026",
    "district_id": "110715",
    "label": "Blang Raya",
    "value": "Blang Raya"
  },
  {
    "id": "1107152027",
    "district_id": "110715",
    "label": "Balee Pineung",
    "value": "Balee Pineung"
  },
  {
    "id": "1107152028",
    "district_id": "110715",
    "label": "Meunjee Mesjid",
    "value": "Meunjee Mesjid"
  },
  {
    "id": "1107152029",
    "district_id": "110715",
    "label": "Mee Tanoh",
    "value": "Mee Tanoh"
  },
  {
    "id": "1107152030",
    "district_id": "110715",
    "label": "Sialet-Alet",
    "value": "Sialet-Alet"
  },
  {
    "id": "1107152031",
    "district_id": "110715",
    "label": "Bluek Arab",
    "value": "Bluek Arab"
  },
  {
    "id": "1107152032",
    "district_id": "110715",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1107152033",
    "district_id": "110715",
    "label": "Dayah Muara",
    "value": "Dayah Muara"
  },
  {
    "id": "1107152034",
    "district_id": "110715",
    "label": "Sawiet",
    "value": "Sawiet"
  },
  {
    "id": "1107152035",
    "district_id": "110715",
    "label": "Ulee Tutue",
    "value": "Ulee Tutue"
  },
  {
    "id": "1107152036",
    "district_id": "110715",
    "label": "Jim",
    "value": "Jim"
  },
  {
    "id": "1107152037",
    "district_id": "110715",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1107152038",
    "district_id": "110715",
    "label": "Meuleuweuk",
    "value": "Meuleuweuk"
  },
  {
    "id": "1107152039",
    "district_id": "110715",
    "label": "Jurong",
    "value": "Jurong"
  },
  {
    "id": "1107152040",
    "district_id": "110715",
    "label": "Raya Krueng Seumideun",
    "value": "Raya Krueng Seumideun"
  },
  {
    "id": "1107152041",
    "district_id": "110715",
    "label": "Sukon Krueng Seumideun",
    "value": "Sukon Krueng Seumideun"
  },
  {
    "id": "1107152042",
    "district_id": "110715",
    "label": "Mee Krueng Seumideun",
    "value": "Mee Krueng Seumideun"
  },
  {
    "id": "1107152043",
    "district_id": "110715",
    "label": "Blang Krueng Seumideun",
    "value": "Blang Krueng Seumideun"
  },
  {
    "id": "1107152044",
    "district_id": "110715",
    "label": "Keurumbok",
    "value": "Keurumbok"
  },
  {
    "id": "1107152045",
    "district_id": "110715",
    "label": "Ulee Cot Seupeng",
    "value": "Ulee Cot Seupeng"
  },
  {
    "id": "1107152046",
    "district_id": "110715",
    "label": "Kabat",
    "value": "Kabat"
  },
  {
    "id": "1107152049",
    "district_id": "110715",
    "label": "Blang Seupeng",
    "value": "Blang Seupeng"
  },
  {
    "id": "1107152050",
    "district_id": "110715",
    "label": "Gampong",
    "value": "Gampong"
  },
  {
    "id": "1107162001",
    "district_id": "110716",
    "label": "Tijue",
    "value": "Tijue"
  },
  {
    "id": "1107162002",
    "district_id": "110716",
    "label": "Lampeudue Tunong",
    "value": "Lampeudue Tunong"
  },
  {
    "id": "1107162003",
    "district_id": "110716",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1107162004",
    "district_id": "110716",
    "label": "Lampeudue Baroh",
    "value": "Lampeudue Baroh"
  },
  {
    "id": "1107162005",
    "district_id": "110716",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1107162006",
    "district_id": "110716",
    "label": "Mesjid Runtoh",
    "value": "Mesjid Runtoh"
  },
  {
    "id": "1107162007",
    "district_id": "110716",
    "label": "Dayah Teungoh",
    "value": "Dayah Teungoh"
  },
  {
    "id": "1107162008",
    "district_id": "110716",
    "label": "Baroh",
    "value": "Baroh"
  },
  {
    "id": "1107162009",
    "district_id": "110716",
    "label": "Dayah Tanoh",
    "value": "Dayah Tanoh"
  },
  {
    "id": "1107162010",
    "district_id": "110716",
    "label": "Cot Geunduk",
    "value": "Cot Geunduk"
  },
  {
    "id": "1107162011",
    "district_id": "110716",
    "label": "Tumpok Laweung",
    "value": "Tumpok Laweung"
  },
  {
    "id": "1107162012",
    "district_id": "110716",
    "label": "Ulee Ceue",
    "value": "Ulee Ceue"
  },
  {
    "id": "1107162013",
    "district_id": "110716",
    "label": "Keude Keulibeut",
    "value": "Keude Keulibeut"
  },
  {
    "id": "1107162014",
    "district_id": "110716",
    "label": "Ulee Tutue",
    "value": "Ulee Tutue"
  },
  {
    "id": "1107162015",
    "district_id": "110716",
    "label": "Dayah Tutong",
    "value": "Dayah Tutong"
  },
  {
    "id": "1107162016",
    "district_id": "110716",
    "label": "Lampoh Lada",
    "value": "Lampoh Lada"
  },
  {
    "id": "1107162017",
    "district_id": "110716",
    "label": "Keuniree",
    "value": "Keuniree"
  },
  {
    "id": "1107162018",
    "district_id": "110716",
    "label": "Tumpok 40",
    "value": "Tumpok 40"
  },
  {
    "id": "1107162019",
    "district_id": "110716",
    "label": "Cot Teungoh",
    "value": "Cot Teungoh"
  },
  {
    "id": "1107162020",
    "district_id": "110716",
    "label": "Cot Rheng",
    "value": "Cot Rheng"
  },
  {
    "id": "1107162021",
    "district_id": "110716",
    "label": "Labui",
    "value": "Labui"
  },
  {
    "id": "1107162022",
    "district_id": "110716",
    "label": "Meunasah Gampong",
    "value": "Meunasah Gampong"
  },
  {
    "id": "1107162023",
    "district_id": "110716",
    "label": "Pulo Pisang",
    "value": "Pulo Pisang"
  },
  {
    "id": "1107162024",
    "district_id": "110716",
    "label": "Lam Kuta",
    "value": "Lam Kuta"
  },
  {
    "id": "1107162025",
    "district_id": "110716",
    "label": "Kampong Pukat",
    "value": "Kampong Pukat"
  },
  {
    "id": "1107162026",
    "district_id": "110716",
    "label": "Kampong Barat",
    "value": "Kampong Barat"
  },
  {
    "id": "1107162027",
    "district_id": "110716",
    "label": "Paloh",
    "value": "Paloh"
  },
  {
    "id": "1107162028",
    "district_id": "110716",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1107162029",
    "district_id": "110716",
    "label": "Pulo Bubee",
    "value": "Pulo Bubee"
  },
  {
    "id": "1107162030",
    "district_id": "110716",
    "label": "Tibang",
    "value": "Tibang"
  },
  {
    "id": "1107162031",
    "district_id": "110716",
    "label": "Rawa",
    "value": "Rawa"
  },
  {
    "id": "1107162032",
    "district_id": "110716",
    "label": "Peukan Baro",
    "value": "Peukan Baro"
  },
  {
    "id": "1107162033",
    "district_id": "110716",
    "label": "Mancang",
    "value": "Mancang"
  },
  {
    "id": "1107162034",
    "district_id": "110716",
    "label": "Gajah Ayee",
    "value": "Gajah Ayee"
  },
  {
    "id": "1107162035",
    "district_id": "110716",
    "label": "Lhok Keutapang",
    "value": "Lhok Keutapang"
  },
  {
    "id": "1107162036",
    "district_id": "110716",
    "label": "Dalueng",
    "value": "Dalueng"
  },
  {
    "id": "1107162037",
    "district_id": "110716",
    "label": "Ulee Ceue",
    "value": "Ulee Ceue"
  },
  {
    "id": "1107162038",
    "district_id": "110716",
    "label": "Meucat",
    "value": "Meucat"
  },
  {
    "id": "1107162039",
    "district_id": "110716",
    "label": "Dayah Teubeng",
    "value": "Dayah Teubeng"
  },
  {
    "id": "1107162040",
    "district_id": "110716",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "1107162041",
    "district_id": "110716",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "1107162042",
    "district_id": "110716",
    "label": "Bayu",
    "value": "Bayu"
  },
  {
    "id": "1107162043",
    "district_id": "110716",
    "label": "Abo",
    "value": "Abo"
  },
  {
    "id": "1107162044",
    "district_id": "110716",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1107162045",
    "district_id": "110716",
    "label": "Krueng Dhoe",
    "value": "Krueng Dhoe"
  },
  {
    "id": "1107162046",
    "district_id": "110716",
    "label": "Sirong",
    "value": "Sirong"
  },
  {
    "id": "1107162047",
    "district_id": "110716",
    "label": "Keutumbu",
    "value": "Keutumbu"
  },
  {
    "id": "1107162048",
    "district_id": "110716",
    "label": "Jeumpa",
    "value": "Jeumpa"
  },
  {
    "id": "1107162049",
    "district_id": "110716",
    "label": "Raya sanggeue",
    "value": "Raya sanggeue"
  },
  {
    "id": "1107162050",
    "district_id": "110716",
    "label": "Jeuleupee",
    "value": "Jeuleupee"
  },
  {
    "id": "1107162051",
    "district_id": "110716",
    "label": "Ujong Langgo",
    "value": "Ujong Langgo"
  },
  {
    "id": "1107162052",
    "district_id": "110716",
    "label": "Cot Glumpang",
    "value": "Cot Glumpang"
  },
  {
    "id": "1107162053",
    "district_id": "110716",
    "label": "Seriweuk",
    "value": "Seriweuk"
  },
  {
    "id": "1107162054",
    "district_id": "110716",
    "label": "Mesjid Utue",
    "value": "Mesjid Utue"
  },
  {
    "id": "1107162055",
    "district_id": "110716",
    "label": "Leubue",
    "value": "Leubue"
  },
  {
    "id": "1107162056",
    "district_id": "110716",
    "label": "Batee",
    "value": "Batee"
  },
  {
    "id": "1107162057",
    "district_id": "110716",
    "label": "Raya Uteu",
    "value": "Raya Uteu"
  },
  {
    "id": "1107162058",
    "district_id": "110716",
    "label": "Seukembroek",
    "value": "Seukembroek"
  },
  {
    "id": "1107162059",
    "district_id": "110716",
    "label": "Bie",
    "value": "Bie"
  },
  {
    "id": "1107162060",
    "district_id": "110716",
    "label": "Blang Kula",
    "value": "Blang Kula"
  },
  {
    "id": "1107162061",
    "district_id": "110716",
    "label": "ALue",
    "value": "ALue"
  },
  {
    "id": "1107162062",
    "district_id": "110716",
    "label": "Seukee",
    "value": "Seukee"
  },
  {
    "id": "1107162063",
    "district_id": "110716",
    "label": "Paya Linteung",
    "value": "Paya Linteung"
  },
  {
    "id": "1107162064",
    "district_id": "110716",
    "label": "Puli",
    "value": "Puli"
  },
  {
    "id": "1107172001",
    "district_id": "110717",
    "label": "Balue Tanoh",
    "value": "Balue Tanoh"
  },
  {
    "id": "1107172002",
    "district_id": "110717",
    "label": "Balue Kulu",
    "value": "Balue Kulu"
  },
  {
    "id": "1107172003",
    "district_id": "110717",
    "label": "Meunasah Empeh",
    "value": "Meunasah Empeh"
  },
  {
    "id": "1107172004",
    "district_id": "110717",
    "label": "Cot Sukon",
    "value": "Cot Sukon"
  },
  {
    "id": "1107172005",
    "district_id": "110717",
    "label": "Mns Dayah Tuha",
    "value": "Mns Dayah Tuha"
  },
  {
    "id": "1107172006",
    "district_id": "110717",
    "label": "Kampong Cot",
    "value": "Kampong Cot"
  },
  {
    "id": "1107172007",
    "district_id": "110717",
    "label": "Paloh Jeureula",
    "value": "Paloh Jeureula"
  },
  {
    "id": "1107172008",
    "district_id": "110717",
    "label": "Barieh",
    "value": "Barieh"
  },
  {
    "id": "1107172009",
    "district_id": "110717",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1107172010",
    "district_id": "110717",
    "label": "Riweuek",
    "value": "Riweuek"
  },
  {
    "id": "1107172011",
    "district_id": "110717",
    "label": "Mancang",
    "value": "Mancang"
  },
  {
    "id": "1107172012",
    "district_id": "110717",
    "label": "Kampong Pisang Bucue",
    "value": "Kampong Pisang Bucue"
  },
  {
    "id": "1107172013",
    "district_id": "110717",
    "label": "Bucue",
    "value": "Bucue"
  },
  {
    "id": "1107172014",
    "district_id": "110717",
    "label": "Lhok Panah",
    "value": "Lhok Panah"
  },
  {
    "id": "1107172015",
    "district_id": "110717",
    "label": "Murong Cot",
    "value": "Murong Cot"
  },
  {
    "id": "1107172016",
    "district_id": "110717",
    "label": "Cumbok Lie",
    "value": "Cumbok Lie"
  },
  {
    "id": "1107172017",
    "district_id": "110717",
    "label": "Lam Ujong",
    "value": "Lam Ujong"
  },
  {
    "id": "1107172018",
    "district_id": "110717",
    "label": "Murong Lhok",
    "value": "Murong Lhok"
  },
  {
    "id": "1107172019",
    "district_id": "110717",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1107172020",
    "district_id": "110717",
    "label": "Cot Cantek",
    "value": "Cot Cantek"
  },
  {
    "id": "1107172021",
    "district_id": "110717",
    "label": "Blang Kumot Baroh",
    "value": "Blang Kumot Baroh"
  },
  {
    "id": "1107172022",
    "district_id": "110717",
    "label": "B. Kumot Tunong",
    "value": "B. Kumot Tunong"
  },
  {
    "id": "1107172023",
    "district_id": "110717",
    "label": "Cumbok Niwa",
    "value": "Cumbok Niwa"
  },
  {
    "id": "1107172024",
    "district_id": "110717",
    "label": "Leupeuem Mesjid",
    "value": "Leupeuem Mesjid"
  },
  {
    "id": "1107172025",
    "district_id": "110717",
    "label": "Pulo Jeumpa",
    "value": "Pulo Jeumpa"
  },
  {
    "id": "1107172026",
    "district_id": "110717",
    "label": "Beutong Pocut",
    "value": "Beutong Pocut"
  },
  {
    "id": "1107172027",
    "district_id": "110717",
    "label": "Beutong Perlak",
    "value": "Beutong Perlak"
  },
  {
    "id": "1107172028",
    "district_id": "110717",
    "label": "Kampong Jeumpa",
    "value": "Kampong Jeumpa"
  },
  {
    "id": "1107172029",
    "district_id": "110717",
    "label": "Pante Krueng",
    "value": "Pante Krueng"
  },
  {
    "id": "1107172030",
    "district_id": "110717",
    "label": "Jurong Pante",
    "value": "Jurong Pante"
  },
  {
    "id": "1107172032",
    "district_id": "110717",
    "label": "Mali Lamkuta",
    "value": "Mali Lamkuta"
  },
  {
    "id": "1107172033",
    "district_id": "110717",
    "label": "Mali Uke",
    "value": "Mali Uke"
  },
  {
    "id": "1107172034",
    "district_id": "110717",
    "label": "Mali Mesjid",
    "value": "Mali Mesjid"
  },
  {
    "id": "1107172035",
    "district_id": "110717",
    "label": "Mali Cot",
    "value": "Mali Cot"
  },
  {
    "id": "1107172036",
    "district_id": "110717",
    "label": "Tangkueng",
    "value": "Tangkueng"
  },
  {
    "id": "1107172037",
    "district_id": "110717",
    "label": "Lhok Mee",
    "value": "Lhok Mee"
  },
  {
    "id": "1107172038",
    "district_id": "110717",
    "label": "Lameue Mns. Raya",
    "value": "Lameue Mns. Raya"
  },
  {
    "id": "1107172039",
    "district_id": "110717",
    "label": "Lameue Mns. Baro",
    "value": "Lameue Mns. Baro"
  },
  {
    "id": "1107172040",
    "district_id": "110717",
    "label": "Lameue Mns. Lueng",
    "value": "Lameue Mns. Lueng"
  },
  {
    "id": "1107172041",
    "district_id": "110717",
    "label": "Pulo Keureumbok",
    "value": "Pulo Keureumbok"
  },
  {
    "id": "1107172042",
    "district_id": "110717",
    "label": "Mns Raya Kp Pisang",
    "value": "Mns Raya Kp Pisang"
  },
  {
    "id": "1107172043",
    "district_id": "110717",
    "label": "Mns Blang Sakti",
    "value": "Mns Blang Sakti"
  },
  {
    "id": "1107172044",
    "district_id": "110717",
    "label": "Perlak Baroh",
    "value": "Perlak Baroh"
  },
  {
    "id": "1107172045",
    "district_id": "110717",
    "label": "Lingkok",
    "value": "Lingkok"
  },
  {
    "id": "1107172046",
    "district_id": "110717",
    "label": "Pasar Kota Bakti",
    "value": "Pasar Kota Bakti"
  },
  {
    "id": "1107172047",
    "district_id": "110717",
    "label": "Mns Balee",
    "value": "Mns Balee"
  },
  {
    "id": "1107172048",
    "district_id": "110717",
    "label": "Perlak Asan",
    "value": "Perlak Asan"
  },
  {
    "id": "1107172049",
    "district_id": "110717",
    "label": "Kampong Baro",
    "value": "Kampong Baro"
  },
  {
    "id": "1107172050",
    "district_id": "110717",
    "label": "Mali Guyui",
    "value": "Mali Guyui"
  },
  {
    "id": "1107182001",
    "district_id": "110718",
    "label": "Mns Raya Paya",
    "value": "Mns Raya Paya"
  },
  {
    "id": "1107182002",
    "district_id": "110718",
    "label": "Mesjid Bungie",
    "value": "Mesjid Bungie"
  },
  {
    "id": "1107182003",
    "district_id": "110718",
    "label": "Liliep",
    "value": "Liliep"
  },
  {
    "id": "1107182004",
    "district_id": "110718",
    "label": "Ujong Baroh",
    "value": "Ujong Baroh"
  },
  {
    "id": "1107182005",
    "district_id": "110718",
    "label": "Peukan Sot",
    "value": "Peukan Sot"
  },
  {
    "id": "1107182006",
    "district_id": "110718",
    "label": "Sukon",
    "value": "Sukon"
  },
  {
    "id": "1107182007",
    "district_id": "110718",
    "label": "Raya Paleue",
    "value": "Raya Paleue"
  },
  {
    "id": "1107182008",
    "district_id": "110718",
    "label": "Lambideng",
    "value": "Lambideng"
  },
  {
    "id": "1107182009",
    "district_id": "110718",
    "label": "Cot Paleue",
    "value": "Cot Paleue"
  },
  {
    "id": "1107182010",
    "district_id": "110718",
    "label": "Seukee",
    "value": "Seukee"
  },
  {
    "id": "1107182011",
    "district_id": "110718",
    "label": "Cebrek",
    "value": "Cebrek"
  },
  {
    "id": "1107182012",
    "district_id": "110718",
    "label": "Linggong Sagoe",
    "value": "Linggong Sagoe"
  },
  {
    "id": "1107182013",
    "district_id": "110718",
    "label": "Kulam Baro",
    "value": "Kulam Baro"
  },
  {
    "id": "1107182014",
    "district_id": "110718",
    "label": "Empeh",
    "value": "Empeh"
  },
  {
    "id": "1107182015",
    "district_id": "110718",
    "label": "Nien",
    "value": "Nien"
  },
  {
    "id": "1107182016",
    "district_id": "110718",
    "label": "Ceureucok Timur",
    "value": "Ceureucok Timur"
  },
  {
    "id": "1107182017",
    "district_id": "110718",
    "label": "Ceureukcok Barat",
    "value": "Ceureukcok Barat"
  },
  {
    "id": "1107182018",
    "district_id": "110718",
    "label": "Jaja Baroh",
    "value": "Jaja Baroh"
  },
  {
    "id": "1107182019",
    "district_id": "110718",
    "label": "Jaja Tunong",
    "value": "Jaja Tunong"
  },
  {
    "id": "1107182020",
    "district_id": "110718",
    "label": "Mesjid Gigieng",
    "value": "Mesjid Gigieng"
  },
  {
    "id": "1107182021",
    "district_id": "110718",
    "label": "Gong",
    "value": "Gong"
  },
  {
    "id": "1107182022",
    "district_id": "110718",
    "label": "Pulo Raya",
    "value": "Pulo Raya"
  },
  {
    "id": "1107182023",
    "district_id": "110718",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1107182024",
    "district_id": "110718",
    "label": "Mamplam",
    "value": "Mamplam"
  },
  {
    "id": "1107182025",
    "district_id": "110718",
    "label": "Meunasah Lhee",
    "value": "Meunasah Lhee"
  },
  {
    "id": "1107182026",
    "district_id": "110718",
    "label": "Pante",
    "value": "Pante"
  },
  {
    "id": "1107182027",
    "district_id": "110718",
    "label": "Kupula",
    "value": "Kupula"
  },
  {
    "id": "1107182028",
    "district_id": "110718",
    "label": "Mantak Raya",
    "value": "Mantak Raya"
  },
  {
    "id": "1107182029",
    "district_id": "110718",
    "label": "Peukan Tuha",
    "value": "Peukan Tuha"
  },
  {
    "id": "1107182030",
    "district_id": "110718",
    "label": "Dayah Lampoh Awe",
    "value": "Dayah Lampoh Awe"
  },
  {
    "id": "1107182031",
    "district_id": "110718",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1107182032",
    "district_id": "110718",
    "label": "Pulo Gajah Mate",
    "value": "Pulo Gajah Mate"
  },
  {
    "id": "1107182033",
    "district_id": "110718",
    "label": "Seuk Ceukok",
    "value": "Seuk Ceukok"
  },
  {
    "id": "1107182034",
    "district_id": "110718",
    "label": "Pulo Tu",
    "value": "Pulo Tu"
  },
  {
    "id": "1107182035",
    "district_id": "110718",
    "label": "Jurong",
    "value": "Jurong"
  },
  {
    "id": "1107182036",
    "district_id": "110718",
    "label": "Kampong Blang",
    "value": "Kampong Blang"
  },
  {
    "id": "1107182037",
    "district_id": "110718",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1107182038",
    "district_id": "110718",
    "label": "Cot Ara",
    "value": "Cot Ara"
  },
  {
    "id": "1107182039",
    "district_id": "110718",
    "label": "Dayah Blang Cut",
    "value": "Dayah Blang Cut"
  },
  {
    "id": "1107182040",
    "district_id": "110718",
    "label": "Teungoh Mangki",
    "value": "Teungoh Mangki"
  },
  {
    "id": "1107182041",
    "district_id": "110718",
    "label": "Dayah Blang",
    "value": "Dayah Blang"
  },
  {
    "id": "1107182042",
    "district_id": "110718",
    "label": "Madika",
    "value": "Madika"
  },
  {
    "id": "1107182043",
    "district_id": "110718",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1107182044",
    "district_id": "110718",
    "label": "Cereucok sagoe",
    "value": "Cereucok sagoe"
  },
  {
    "id": "1107182045",
    "district_id": "110718",
    "label": "Bunien",
    "value": "Bunien"
  },
  {
    "id": "1107182046",
    "district_id": "110718",
    "label": "Paloh Tok Due",
    "value": "Paloh Tok Due"
  },
  {
    "id": "1107182047",
    "district_id": "110718",
    "label": "Mesjid Tungoe",
    "value": "Mesjid Tungoe"
  },
  {
    "id": "1107182048",
    "district_id": "110718",
    "label": "Blang Leuen",
    "value": "Blang Leuen"
  },
  {
    "id": "1107182049",
    "district_id": "110718",
    "label": "Ujong Gampong",
    "value": "Ujong Gampong"
  },
  {
    "id": "1107182050",
    "district_id": "110718",
    "label": "Cot Jaja",
    "value": "Cot Jaja"
  },
  {
    "id": "1107182051",
    "district_id": "110718",
    "label": "Lheue",
    "value": "Lheue"
  },
  {
    "id": "1107182052",
    "district_id": "110718",
    "label": "Ulee Barat",
    "value": "Ulee Barat"
  },
  {
    "id": "1107192001",
    "district_id": "110719",
    "label": "Keude Tangse",
    "value": "Keude Tangse"
  },
  {
    "id": "1107192002",
    "district_id": "110719",
    "label": "Pulo Mesjid I",
    "value": "Pulo Mesjid I"
  },
  {
    "id": "1107192003",
    "district_id": "110719",
    "label": "Pulo Mesjid II",
    "value": "Pulo Mesjid II"
  },
  {
    "id": "1107192004",
    "district_id": "110719",
    "label": "Pulo Sejahtera",
    "value": "Pulo Sejahtera"
  },
  {
    "id": "1107192005",
    "district_id": "110719",
    "label": "Pulo Kawa",
    "value": "Pulo Kawa"
  },
  {
    "id": "1107192006",
    "district_id": "110719",
    "label": "Pulo Seunong",
    "value": "Pulo Seunong"
  },
  {
    "id": "1107192007",
    "district_id": "110719",
    "label": "Nubok Badeuk",
    "value": "Nubok Badeuk"
  },
  {
    "id": "1107192008",
    "district_id": "110719",
    "label": "Blang Jeurat",
    "value": "Blang Jeurat"
  },
  {
    "id": "1107192009",
    "district_id": "110719",
    "label": "Pulo Baro",
    "value": "Pulo Baro"
  },
  {
    "id": "1107192010",
    "district_id": "110719",
    "label": "Layan",
    "value": "Layan"
  },
  {
    "id": "1107192011",
    "district_id": "110719",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1107192012",
    "district_id": "110719",
    "label": "Peunalom I",
    "value": "Peunalom I"
  },
  {
    "id": "1107192013",
    "district_id": "110719",
    "label": "Peunalom II",
    "value": "Peunalom II"
  },
  {
    "id": "1107192014",
    "district_id": "110719",
    "label": "Krueng Meuriam",
    "value": "Krueng Meuriam"
  },
  {
    "id": "1107192015",
    "district_id": "110719",
    "label": "Tuha Blang Beungoh",
    "value": "Tuha Blang Beungoh"
  },
  {
    "id": "1107192016",
    "district_id": "110719",
    "label": "Blang Dhot",
    "value": "Blang Dhot"
  },
  {
    "id": "1107192017",
    "district_id": "110719",
    "label": "Blang Bungong",
    "value": "Blang Bungong"
  },
  {
    "id": "1107192018",
    "district_id": "110719",
    "label": "Ulee Gunong",
    "value": "Ulee Gunong"
  },
  {
    "id": "1107192019",
    "district_id": "110719",
    "label": "Keubon Nilam",
    "value": "Keubon Nilam"
  },
  {
    "id": "1107192020",
    "district_id": "110719",
    "label": "Paya Guci",
    "value": "Paya Guci"
  },
  {
    "id": "1107192021",
    "district_id": "110719",
    "label": "Rantau Panyang",
    "value": "Rantau Panyang"
  },
  {
    "id": "1107192022",
    "district_id": "110719",
    "label": "Blang Malo",
    "value": "Blang Malo"
  },
  {
    "id": "1107192023",
    "district_id": "110719",
    "label": "Krueng Seukeuek",
    "value": "Krueng Seukeuek"
  },
  {
    "id": "1107192024",
    "district_id": "110719",
    "label": "Beungga",
    "value": "Beungga"
  },
  {
    "id": "1107192025",
    "district_id": "110719",
    "label": "Alue Calong",
    "value": "Alue Calong"
  },
  {
    "id": "1107192026",
    "district_id": "110719",
    "label": "Pulo Ie",
    "value": "Pulo Ie"
  },
  {
    "id": "1107192027",
    "district_id": "110719",
    "label": "Lhok Keutapang",
    "value": "Lhok Keutapang"
  },
  {
    "id": "1107192028",
    "district_id": "110719",
    "label": "Blang Pandak",
    "value": "Blang Pandak"
  },
  {
    "id": "1107212001",
    "district_id": "110721",
    "label": "Rabo",
    "value": "Rabo"
  },
  {
    "id": "1107212002",
    "district_id": "110721",
    "label": "Pulo Siblah",
    "value": "Pulo Siblah"
  },
  {
    "id": "1107212003",
    "district_id": "110721",
    "label": "Lhok Igeuh",
    "value": "Lhok Igeuh"
  },
  {
    "id": "1107212004",
    "district_id": "110721",
    "label": "Pulo Geulumpang",
    "value": "Pulo Geulumpang"
  },
  {
    "id": "1107212005",
    "district_id": "110721",
    "label": "Meunasah Mancang",
    "value": "Meunasah Mancang"
  },
  {
    "id": "1107212006",
    "district_id": "110721",
    "label": "Pulo Tambo",
    "value": "Pulo Tambo"
  },
  {
    "id": "1107212007",
    "district_id": "110721",
    "label": "Pulo Kenari",
    "value": "Pulo Kenari"
  },
  {
    "id": "1107212008",
    "district_id": "110721",
    "label": "Peunadok",
    "value": "Peunadok"
  },
  {
    "id": "1107212009",
    "district_id": "110721",
    "label": "Pulo Mesjid",
    "value": "Pulo Mesjid"
  },
  {
    "id": "1107212010",
    "district_id": "110721",
    "label": "Meunasah Dayah",
    "value": "Meunasah Dayah"
  },
  {
    "id": "1107212011",
    "district_id": "110721",
    "label": "Trieng Cudo Baroh",
    "value": "Trieng Cudo Baroh"
  },
  {
    "id": "1107212012",
    "district_id": "110721",
    "label": "Trieng Cudo Tunong",
    "value": "Trieng Cudo Tunong"
  },
  {
    "id": "1107212013",
    "district_id": "110721",
    "label": "Mampree",
    "value": "Mampree"
  },
  {
    "id": "1107212014",
    "district_id": "110721",
    "label": "Daya Baroh",
    "value": "Daya Baroh"
  },
  {
    "id": "1107212015",
    "district_id": "110721",
    "label": "Daya Teungoh",
    "value": "Daya Teungoh"
  },
  {
    "id": "1107212016",
    "district_id": "110721",
    "label": "Dayah KP Baro",
    "value": "Dayah KP Baro"
  },
  {
    "id": "1107212017",
    "district_id": "110721",
    "label": "Dayah Cot",
    "value": "Dayah Cot"
  },
  {
    "id": "1107212018",
    "district_id": "110721",
    "label": "Blang Rikui",
    "value": "Blang Rikui"
  },
  {
    "id": "1107212019",
    "district_id": "110721",
    "label": "Panton Bunot",
    "value": "Panton Bunot"
  },
  {
    "id": "1107222001",
    "district_id": "110722",
    "label": "U Gadeng",
    "value": "U Gadeng"
  },
  {
    "id": "1107222002",
    "district_id": "110722",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1107222003",
    "district_id": "110722",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1107222004",
    "district_id": "110722",
    "label": "Dayah Keumala",
    "value": "Dayah Keumala"
  },
  {
    "id": "1107222005",
    "district_id": "110722",
    "label": "Jujiem",
    "value": "Jujiem"
  },
  {
    "id": "1107222006",
    "district_id": "110722",
    "label": "Rheng",
    "value": "Rheng"
  },
  {
    "id": "1107222007",
    "district_id": "110722",
    "label": "Cot Nuran",
    "value": "Cot Nuran"
  },
  {
    "id": "1107222008",
    "district_id": "110722",
    "label": "Pulo Pantee",
    "value": "Pulo Pantee"
  },
  {
    "id": "1107222009",
    "district_id": "110722",
    "label": "Paloh Teungoh",
    "value": "Paloh Teungoh"
  },
  {
    "id": "1107222010",
    "district_id": "110722",
    "label": "Asan Nicah",
    "value": "Asan Nicah"
  },
  {
    "id": "1107222011",
    "district_id": "110722",
    "label": "Papeun",
    "value": "Papeun"
  },
  {
    "id": "1107222012",
    "district_id": "110722",
    "label": "Pulo Seupeng",
    "value": "Pulo Seupeng"
  },
  {
    "id": "1107222013",
    "district_id": "110722",
    "label": "Mesjid Nicah",
    "value": "Mesjid Nicah"
  },
  {
    "id": "1107222014",
    "district_id": "110722",
    "label": "Tunong",
    "value": "Tunong"
  },
  {
    "id": "1107222015",
    "district_id": "110722",
    "label": "Pako",
    "value": "Pako"
  },
  {
    "id": "1107222016",
    "district_id": "110722",
    "label": "Pulo Baro",
    "value": "Pulo Baro"
  },
  {
    "id": "1107222017",
    "district_id": "110722",
    "label": "Cot Kreh",
    "value": "Cot Kreh"
  },
  {
    "id": "1107222031",
    "district_id": "110722",
    "label": "Pulo Cahi",
    "value": "Pulo Cahi"
  },
  {
    "id": "1107242001",
    "district_id": "110724",
    "label": "Baroh",
    "value": "Baroh"
  },
  {
    "id": "1107242002",
    "district_id": "110724",
    "label": "Alue Jojo",
    "value": "Alue Jojo"
  },
  {
    "id": "1107242003",
    "district_id": "110724",
    "label": "Gampong Cot Usi",
    "value": "Gampong Cot Usi"
  },
  {
    "id": "1107242004",
    "district_id": "110724",
    "label": "Dayah Usi",
    "value": "Dayah Usi"
  },
  {
    "id": "1107242005",
    "district_id": "110724",
    "label": "Paloh lhok Usi",
    "value": "Paloh lhok Usi"
  },
  {
    "id": "1107242006",
    "district_id": "110724",
    "label": "Campli Usi",
    "value": "Campli Usi"
  },
  {
    "id": "1107242007",
    "district_id": "110724",
    "label": "Mee Tanjong",
    "value": "Mee Tanjong"
  },
  {
    "id": "1107242008",
    "district_id": "110724",
    "label": "Mesjid Usi",
    "value": "Mesjid Usi"
  },
  {
    "id": "1107242009",
    "district_id": "110724",
    "label": "Paloh Raya",
    "value": "Paloh Raya"
  },
  {
    "id": "1107242010",
    "district_id": "110724",
    "label": "Nibong",
    "value": "Nibong"
  },
  {
    "id": "1107242011",
    "district_id": "110724",
    "label": "Baro Ujong Rimba",
    "value": "Baro Ujong Rimba"
  },
  {
    "id": "1107242012",
    "district_id": "110724",
    "label": "Rinti",
    "value": "Rinti"
  },
  {
    "id": "1107242013",
    "district_id": "110724",
    "label": "Didoh",
    "value": "Didoh"
  },
  {
    "id": "1107242014",
    "district_id": "110724",
    "label": "Meugit",
    "value": "Meugit"
  },
  {
    "id": "1107242015",
    "district_id": "110724",
    "label": "Empeh",
    "value": "Empeh"
  },
  {
    "id": "1107242016",
    "district_id": "110724",
    "label": "Blang Riek",
    "value": "Blang Riek"
  },
  {
    "id": "1107242017",
    "district_id": "110724",
    "label": "Balee Ujong Rimba",
    "value": "Balee Ujong Rimba"
  },
  {
    "id": "1107242018",
    "district_id": "110724",
    "label": "Mon Ara",
    "value": "Mon Ara"
  },
  {
    "id": "1107242019",
    "district_id": "110724",
    "label": "Kulam Ara",
    "value": "Kulam Ara"
  },
  {
    "id": "1107242020",
    "district_id": "110724",
    "label": "Blangon Basah",
    "value": "Blangon Basah"
  },
  {
    "id": "1107242021",
    "district_id": "110724",
    "label": "Rambong",
    "value": "Rambong"
  },
  {
    "id": "1107242022",
    "district_id": "110724",
    "label": "Gampong Lada",
    "value": "Gampong Lada"
  },
  {
    "id": "1107242023",
    "district_id": "110724",
    "label": "Dayah Kumba",
    "value": "Dayah Kumba"
  },
  {
    "id": "1107242024",
    "district_id": "110724",
    "label": "Cot Khutang",
    "value": "Cot Khutang"
  },
  {
    "id": "1107242025",
    "district_id": "110724",
    "label": "Dayah Tanoh",
    "value": "Dayah Tanoh"
  },
  {
    "id": "1107242026",
    "district_id": "110724",
    "label": "Ulee Tutue",
    "value": "Ulee Tutue"
  },
  {
    "id": "1107242027",
    "district_id": "110724",
    "label": "Beureueh II",
    "value": "Beureueh II"
  },
  {
    "id": "1107242028",
    "district_id": "110724",
    "label": "Mesjid Jeurat Manyang",
    "value": "Mesjid Jeurat Manyang"
  },
  {
    "id": "1107242029",
    "district_id": "110724",
    "label": "Paloh Tinggi",
    "value": "Paloh Tinggi"
  },
  {
    "id": "1107242030",
    "district_id": "110724",
    "label": "Karieng",
    "value": "Karieng"
  },
  {
    "id": "1107242031",
    "district_id": "110724",
    "label": "Sagoe Tumpeun",
    "value": "Sagoe Tumpeun"
  },
  {
    "id": "1107242032",
    "district_id": "110724",
    "label": "Tong Peria",
    "value": "Tong Peria"
  },
  {
    "id": "1107242033",
    "district_id": "110724",
    "label": "Tong Weng",
    "value": "Tong Weng"
  },
  {
    "id": "1107242034",
    "district_id": "110724",
    "label": "Pulo Drien",
    "value": "Pulo Drien"
  },
  {
    "id": "1107242035",
    "district_id": "110724",
    "label": "Mesjid Gumpueng",
    "value": "Mesjid Gumpueng"
  },
  {
    "id": "1107242036",
    "district_id": "110724",
    "label": "Ulee Gampong",
    "value": "Ulee Gampong"
  },
  {
    "id": "1107242037",
    "district_id": "110724",
    "label": "Barieh",
    "value": "Barieh"
  },
  {
    "id": "1107242038",
    "district_id": "110724",
    "label": "Jumphoih Adan",
    "value": "Jumphoih Adan"
  },
  {
    "id": "1107242039",
    "district_id": "110724",
    "label": "Mee Adan",
    "value": "Mee Adan"
  },
  {
    "id": "1107242040",
    "district_id": "110724",
    "label": "Dayah Adan",
    "value": "Dayah Adan"
  },
  {
    "id": "1107242041",
    "district_id": "110724",
    "label": "Alue Adan",
    "value": "Alue Adan"
  },
  {
    "id": "1107242042",
    "district_id": "110724",
    "label": "Rambot Adan",
    "value": "Rambot Adan"
  },
  {
    "id": "1107242043",
    "district_id": "110724",
    "label": "Meucat Adan",
    "value": "Meucat Adan"
  },
  {
    "id": "1107242044",
    "district_id": "110724",
    "label": "Tiba Mesjid",
    "value": "Tiba Mesjid"
  },
  {
    "id": "1107242045",
    "district_id": "110724",
    "label": "Tiba Raya",
    "value": "Tiba Raya"
  },
  {
    "id": "1107242046",
    "district_id": "110724",
    "label": "Reubat",
    "value": "Reubat"
  },
  {
    "id": "1107242047",
    "district_id": "110724",
    "label": "Simbe",
    "value": "Simbe"
  },
  {
    "id": "1107242048",
    "district_id": "110724",
    "label": "Jiem",
    "value": "Jiem"
  },
  {
    "id": "1107252001",
    "district_id": "110725",
    "label": "Gintong",
    "value": "Gintong"
  },
  {
    "id": "1107252002",
    "district_id": "110725",
    "label": "Mesjid Beureuleung",
    "value": "Mesjid Beureuleung"
  },
  {
    "id": "1107252003",
    "district_id": "110725",
    "label": "Beureuleung",
    "value": "Beureuleung"
  },
  {
    "id": "1107252004",
    "district_id": "110725",
    "label": "Baroh",
    "value": "Baroh"
  },
  {
    "id": "1107252005",
    "district_id": "110725",
    "label": "Meunasah Mee",
    "value": "Meunasah Mee"
  },
  {
    "id": "1107252006",
    "district_id": "110725",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1107252007",
    "district_id": "110725",
    "label": "Teungoh",
    "value": "Teungoh"
  },
  {
    "id": "1107252008",
    "district_id": "110725",
    "label": "Pangge Pilok",
    "value": "Pangge Pilok"
  },
  {
    "id": "1107252009",
    "district_id": "110725",
    "label": "Karieng",
    "value": "Karieng"
  },
  {
    "id": "1107252010",
    "district_id": "110725",
    "label": "Grong-Grong",
    "value": "Grong-Grong"
  },
  {
    "id": "1107252011",
    "district_id": "110725",
    "label": "Nicah",
    "value": "Nicah"
  },
  {
    "id": "1107252012",
    "district_id": "110725",
    "label": "Mesj. Memeuaneuk",
    "value": "Mesj. Memeuaneuk"
  },
  {
    "id": "1107252013",
    "district_id": "110725",
    "label": "Daka",
    "value": "Daka"
  },
  {
    "id": "1107252014",
    "district_id": "110725",
    "label": "Sentosa",
    "value": "Sentosa"
  },
  {
    "id": "1107252015",
    "district_id": "110725",
    "label": "Sukon",
    "value": "Sukon"
  },
  {
    "id": "1107272001",
    "district_id": "110727",
    "label": "Lutueng",
    "value": "Lutueng"
  },
  {
    "id": "1107272002",
    "district_id": "110727",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1107272003",
    "district_id": "110727",
    "label": "Mane",
    "value": "Mane"
  },
  {
    "id": "1107272004",
    "district_id": "110727",
    "label": "Turue Cut",
    "value": "Turue Cut"
  },
  {
    "id": "1107292001",
    "district_id": "110729",
    "label": "U. Bungkok",
    "value": "U. Bungkok"
  },
  {
    "id": "1107292002",
    "district_id": "110729",
    "label": "Mee Teumpeun",
    "value": "Mee Teumpeun"
  },
  {
    "id": "1107292003",
    "district_id": "110729",
    "label": "Kumbang Unoe",
    "value": "Kumbang Unoe"
  },
  {
    "id": "1107292004",
    "district_id": "110729",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1107292005",
    "district_id": "110729",
    "label": "Geunteng",
    "value": "Geunteng"
  },
  {
    "id": "1107292006",
    "district_id": "110729",
    "label": "Jurong Baro",
    "value": "Jurong Baro"
  },
  {
    "id": "1107292007",
    "district_id": "110729",
    "label": "Sukon Paku",
    "value": "Sukon Paku"
  },
  {
    "id": "1107292008",
    "district_id": "110729",
    "label": "Pulo Iboih",
    "value": "Pulo Iboih"
  },
  {
    "id": "1107292009",
    "district_id": "110729",
    "label": "Sangget",
    "value": "Sangget"
  },
  {
    "id": "1107292010",
    "district_id": "110729",
    "label": "Blang Baroh",
    "value": "Blang Baroh"
  },
  {
    "id": "1107292011",
    "district_id": "110729",
    "label": "Manyang",
    "value": "Manyang"
  },
  {
    "id": "1107292012",
    "district_id": "110729",
    "label": "Pulo Panjoe",
    "value": "Pulo Panjoe"
  },
  {
    "id": "1107292013",
    "district_id": "110729",
    "label": "Bale Gantung",
    "value": "Bale Gantung"
  },
  {
    "id": "1107292014",
    "district_id": "110729",
    "label": "Daboih",
    "value": "Daboih"
  },
  {
    "id": "1107292015",
    "district_id": "110729",
    "label": "Seureukui Cebrek",
    "value": "Seureukui Cebrek"
  },
  {
    "id": "1107292016",
    "district_id": "110729",
    "label": "Glumpang Bungkok",
    "value": "Glumpang Bungkok"
  },
  {
    "id": "1107292017",
    "district_id": "110729",
    "label": "Ukee",
    "value": "Ukee"
  },
  {
    "id": "1107292018",
    "district_id": "110729",
    "label": "Kumbang Trueng Campli",
    "value": "Kumbang Trueng Campli"
  },
  {
    "id": "1107292019",
    "district_id": "110729",
    "label": "Palong",
    "value": "Palong"
  },
  {
    "id": "1107292020",
    "district_id": "110729",
    "label": "Seuke",
    "value": "Seuke"
  },
  {
    "id": "1107292021",
    "district_id": "110729",
    "label": "Bungong",
    "value": "Bungong"
  },
  {
    "id": "1107312001",
    "district_id": "110731",
    "label": "Lingkok",
    "value": "Lingkok"
  },
  {
    "id": "1107312002",
    "district_id": "110731",
    "label": "Pante Kulu",
    "value": "Pante Kulu"
  },
  {
    "id": "1107312003",
    "district_id": "110731",
    "label": "Uke",
    "value": "Uke"
  },
  {
    "id": "1107312004",
    "district_id": "110731",
    "label": "Pante Siren",
    "value": "Pante Siren"
  },
  {
    "id": "1107312005",
    "district_id": "110731",
    "label": "Pulo Raya",
    "value": "Pulo Raya"
  },
  {
    "id": "1107312006",
    "district_id": "110731",
    "label": "Cut",
    "value": "Cut"
  },
  {
    "id": "1107312007",
    "district_id": "110731",
    "label": "Dayah Meunara",
    "value": "Dayah Meunara"
  },
  {
    "id": "1107312008",
    "district_id": "110731",
    "label": "Paloh Naleueng",
    "value": "Paloh Naleueng"
  },
  {
    "id": "1107312009",
    "district_id": "110731",
    "label": "Pulo Lhoih",
    "value": "Pulo Lhoih"
  },
  {
    "id": "1107312010",
    "district_id": "110731",
    "label": "Mesjid Tongpudeng",
    "value": "Mesjid Tongpudeng"
  },
  {
    "id": "1107312011",
    "district_id": "110731",
    "label": "Blang Tho",
    "value": "Blang Tho"
  },
  {
    "id": "1107312012",
    "district_id": "110731",
    "label": "Asan Tongpudeng",
    "value": "Asan Tongpudeng"
  },
  {
    "id": "1107312013",
    "district_id": "110731",
    "label": "Alue",
    "value": "Alue"
  },
  {
    "id": "1108012001",
    "district_id": "110801",
    "label": "Keude Alue Ie Puteh",
    "value": "Keude Alue Ie Puteh"
  },
  {
    "id": "1108012002",
    "district_id": "110801",
    "label": "Meunasah Alue Ie Puteh",
    "value": "Meunasah Alue Ie Puteh"
  },
  {
    "id": "1108012003",
    "district_id": "110801",
    "label": "Pucok Alue",
    "value": "Pucok Alue"
  },
  {
    "id": "1108012004",
    "district_id": "110801",
    "label": "Alue Anoe Timu",
    "value": "Alue Anoe Timu"
  },
  {
    "id": "1108012005",
    "district_id": "110801",
    "label": "Alue Anoe Barat",
    "value": "Alue Anoe Barat"
  },
  {
    "id": "1108012006",
    "district_id": "110801",
    "label": "Pulo Seukee",
    "value": "Pulo Seukee"
  },
  {
    "id": "1108012007",
    "district_id": "110801",
    "label": "Alue Serdang",
    "value": "Alue Serdang"
  },
  {
    "id": "1108012008",
    "district_id": "110801",
    "label": "Cot Mane",
    "value": "Cot Mane"
  },
  {
    "id": "1108012009",
    "district_id": "110801",
    "label": "Cot Kumbang",
    "value": "Cot Kumbang"
  },
  {
    "id": "1108012010",
    "district_id": "110801",
    "label": "Matang Kumbang",
    "value": "Matang Kumbang"
  },
  {
    "id": "1108012011",
    "district_id": "110801",
    "label": "Matang Kareung",
    "value": "Matang Kareung"
  },
  {
    "id": "1108012012",
    "district_id": "110801",
    "label": "Cot Ulaya",
    "value": "Cot Ulaya"
  },
  {
    "id": "1108012013",
    "district_id": "110801",
    "label": "Alue Geudong",
    "value": "Alue Geudong"
  },
  {
    "id": "1108012014",
    "district_id": "110801",
    "label": "Tanjong Glumpang",
    "value": "Tanjong Glumpang"
  },
  {
    "id": "1108012015",
    "district_id": "110801",
    "label": "Matang Raya Timu",
    "value": "Matang Raya Timu"
  },
  {
    "id": "1108012016",
    "district_id": "110801",
    "label": "Matang Raya Barat",
    "value": "Matang Raya Barat"
  },
  {
    "id": "1108012017",
    "district_id": "110801",
    "label": "Matang Kelayu",
    "value": "Matang Kelayu"
  },
  {
    "id": "1108012018",
    "district_id": "110801",
    "label": "Alue Buya",
    "value": "Alue Buya"
  },
  {
    "id": "1108012019",
    "district_id": "110801",
    "label": "Matang Cut",
    "value": "Matang Cut"
  },
  {
    "id": "1108012020",
    "district_id": "110801",
    "label": "Keude Panteu Breuh",
    "value": "Keude Panteu Breuh"
  },
  {
    "id": "1108012021",
    "district_id": "110801",
    "label": "Meudang Ara",
    "value": "Meudang Ara"
  },
  {
    "id": "1108012022",
    "district_id": "110801",
    "label": "Meunasah Bujok",
    "value": "Meunasah Bujok"
  },
  {
    "id": "1108012023",
    "district_id": "110801",
    "label": "Alue Dama",
    "value": "Alue Dama"
  },
  {
    "id": "1108012024",
    "district_id": "110801",
    "label": "Cot Ara",
    "value": "Cot Ara"
  },
  {
    "id": "1108012025",
    "district_id": "110801",
    "label": "Rambong Dalam",
    "value": "Rambong Dalam"
  },
  {
    "id": "1108012026",
    "district_id": "110801",
    "label": "Meunasah Geudong",
    "value": "Meunasah Geudong"
  },
  {
    "id": "1108012027",
    "district_id": "110801",
    "label": "Alue Keutapang",
    "value": "Alue Keutapang"
  },
  {
    "id": "1108012028",
    "district_id": "110801",
    "label": "Krueng Lingka Barat",
    "value": "Krueng Lingka Barat"
  },
  {
    "id": "1108012029",
    "district_id": "110801",
    "label": "Krueng Lingka Timu",
    "value": "Krueng Lingka Timu"
  },
  {
    "id": "1108012030",
    "district_id": "110801",
    "label": "Alue Jamok",
    "value": "Alue Jamok"
  },
  {
    "id": "1108012031",
    "district_id": "110801",
    "label": "Alue Bili Geulumpang",
    "value": "Alue Bili Geulumpang"
  },
  {
    "id": "1108012032",
    "district_id": "110801",
    "label": "Lhok Seutuy",
    "value": "Lhok Seutuy"
  },
  {
    "id": "1108012033",
    "district_id": "110801",
    "label": "Alue Bili Rayeuk",
    "value": "Alue Bili Rayeuk"
  },
  {
    "id": "1108012034",
    "district_id": "110801",
    "label": "Keude Menjee IV",
    "value": "Keude Menjee IV"
  },
  {
    "id": "1108012035",
    "district_id": "110801",
    "label": "Glumpang Bungkok",
    "value": "Glumpang Bungkok"
  },
  {
    "id": "1108012036",
    "district_id": "110801",
    "label": "Matang Rawa",
    "value": "Matang Rawa"
  },
  {
    "id": "1108012037",
    "district_id": "110801",
    "label": "Glumpang Samlakoe",
    "value": "Glumpang Samlakoe"
  },
  {
    "id": "1108012038",
    "district_id": "110801",
    "label": "Arongan Lise",
    "value": "Arongan Lise"
  },
  {
    "id": "1108012039",
    "district_id": "110801",
    "label": "Matang Reudeup",
    "value": "Matang Reudeup"
  },
  {
    "id": "1108012040",
    "district_id": "110801",
    "label": "Matang Manyam",
    "value": "Matang Manyam"
  },
  {
    "id": "1108012041",
    "district_id": "110801",
    "label": "Matang Ulim",
    "value": "Matang Ulim"
  },
  {
    "id": "1108012042",
    "district_id": "110801",
    "label": "Matang Beuringen",
    "value": "Matang Beuringen"
  },
  {
    "id": "1108012043",
    "district_id": "110801",
    "label": "Cot Manyang",
    "value": "Cot Manyang"
  },
  {
    "id": "1108012044",
    "district_id": "110801",
    "label": "Alue Ie Tarek",
    "value": "Alue Ie Tarek"
  },
  {
    "id": "1108012045",
    "district_id": "110801",
    "label": "Alue Rambong",
    "value": "Alue Rambong"
  },
  {
    "id": "1108012046",
    "district_id": "110801",
    "label": "Matang Linya",
    "value": "Matang Linya"
  },
  {
    "id": "1108012047",
    "district_id": "110801",
    "label": "Matang Lawang",
    "value": "Matang Lawang"
  },
  {
    "id": "1108012048",
    "district_id": "110801",
    "label": "Glumpang payong",
    "value": "Glumpang payong"
  },
  {
    "id": "1108012049",
    "district_id": "110801",
    "label": "Mon Sukon",
    "value": "Mon Sukon"
  },
  {
    "id": "1108012050",
    "district_id": "110801",
    "label": "Lueng Bata",
    "value": "Lueng Bata"
  },
  {
    "id": "1108012051",
    "district_id": "110801",
    "label": "Ceumpeudak",
    "value": "Ceumpeudak"
  },
  {
    "id": "1108012052",
    "district_id": "110801",
    "label": "Buket Dara Baro",
    "value": "Buket Dara Baro"
  },
  {
    "id": "1108012053",
    "district_id": "110801",
    "label": "Matang Pineung",
    "value": "Matang Pineung"
  },
  {
    "id": "1108012054",
    "district_id": "110801",
    "label": "Matang Baro",
    "value": "Matang Baro"
  },
  {
    "id": "1108012055",
    "district_id": "110801",
    "label": "Ujong Dama",
    "value": "Ujong Dama"
  },
  {
    "id": "1108012056",
    "district_id": "110801",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "1108012057",
    "district_id": "110801",
    "label": "Cinta Makmur",
    "value": "Cinta Makmur"
  },
  {
    "id": "1108022001",
    "district_id": "110802",
    "label": "Keude Krueng Geukueh",
    "value": "Keude Krueng Geukueh"
  },
  {
    "id": "1108022002",
    "district_id": "110802",
    "label": "Tambon Baroh",
    "value": "Tambon Baroh"
  },
  {
    "id": "1108022003",
    "district_id": "110802",
    "label": "Tambon Tunong",
    "value": "Tambon Tunong"
  },
  {
    "id": "1108022004",
    "district_id": "110802",
    "label": "Paloh Gadeng",
    "value": "Paloh Gadeng"
  },
  {
    "id": "1108022005",
    "district_id": "110802",
    "label": "Paloh Lada",
    "value": "Paloh Lada"
  },
  {
    "id": "1108022006",
    "district_id": "110802",
    "label": "Uteun Geulinggang",
    "value": "Uteun Geulinggang"
  },
  {
    "id": "1108022007",
    "district_id": "110802",
    "label": "Pulo Rungkom",
    "value": "Pulo Rungkom"
  },
  {
    "id": "1108022008",
    "district_id": "110802",
    "label": "Paloh Igeuh",
    "value": "Paloh Igeuh"
  },
  {
    "id": "1108022009",
    "district_id": "110802",
    "label": "Bangka Jaya",
    "value": "Bangka Jaya"
  },
  {
    "id": "1108022010",
    "district_id": "110802",
    "label": "Ulee Pulo",
    "value": "Ulee Pulo"
  },
  {
    "id": "1108022011",
    "district_id": "110802",
    "label": "Ulee Reuleung",
    "value": "Ulee Reuleung"
  },
  {
    "id": "1108022012",
    "district_id": "110802",
    "label": "Geulumpang Sulu Timu",
    "value": "Geulumpang Sulu Timu"
  },
  {
    "id": "1108022013",
    "district_id": "110802",
    "label": "Geulumpang Sulu Barat",
    "value": "Geulumpang Sulu Barat"
  },
  {
    "id": "1108022014",
    "district_id": "110802",
    "label": "Bluka Teubai",
    "value": "Bluka Teubai"
  },
  {
    "id": "1108022015",
    "district_id": "110802",
    "label": "Lancang Barat",
    "value": "Lancang Barat"
  },
  {
    "id": "1108032001",
    "district_id": "110803",
    "label": "Meunasah Blang Ara",
    "value": "Meunasah Blang Ara"
  },
  {
    "id": "1108032002",
    "district_id": "110803",
    "label": "Meunasah Buket",
    "value": "Meunasah Buket"
  },
  {
    "id": "1108032003",
    "district_id": "110803",
    "label": "Blang Talon",
    "value": "Blang Talon"
  },
  {
    "id": "1108032004",
    "district_id": "110803",
    "label": "Cot Rheu",
    "value": "Cot Rheu"
  },
  {
    "id": "1108032005",
    "district_id": "110803",
    "label": "Krueng Manyang",
    "value": "Krueng Manyang"
  },
  {
    "id": "1108032006",
    "district_id": "110803",
    "label": "Bayu",
    "value": "Bayu"
  },
  {
    "id": "1108032007",
    "district_id": "110803",
    "label": "Blang Ado",
    "value": "Blang Ado"
  },
  {
    "id": "1108032008",
    "district_id": "110803",
    "label": "Dayah Meunara",
    "value": "Dayah Meunara"
  },
  {
    "id": "1108032009",
    "district_id": "110803",
    "label": "Meunasah Kumbang",
    "value": "Meunasah Kumbang"
  },
  {
    "id": "1108032010",
    "district_id": "110803",
    "label": "Meunye Cut Bahagia",
    "value": "Meunye Cut Bahagia"
  },
  {
    "id": "1108032011",
    "district_id": "110803",
    "label": "Lhok Jok",
    "value": "Lhok Jok"
  },
  {
    "id": "1108032012",
    "district_id": "110803",
    "label": "Alue Rambee",
    "value": "Alue Rambee"
  },
  {
    "id": "1108032013",
    "district_id": "110803",
    "label": "Blang Riek",
    "value": "Blang Riek"
  },
  {
    "id": "1108032014",
    "district_id": "110803",
    "label": "Cempeudak",
    "value": "Cempeudak"
  },
  {
    "id": "1108032015",
    "district_id": "110803",
    "label": "Seuneubok Drien",
    "value": "Seuneubok Drien"
  },
  {
    "id": "1108032016",
    "district_id": "110803",
    "label": "Cot Merbo",
    "value": "Cot Merbo"
  },
  {
    "id": "1108032017",
    "district_id": "110803",
    "label": "Keude Blang Ara",
    "value": "Keude Blang Ara"
  },
  {
    "id": "1108032018",
    "district_id": "110803",
    "label": "Keude Krueng",
    "value": "Keude Krueng"
  },
  {
    "id": "1108032019",
    "district_id": "110803",
    "label": "Krueng Seunong",
    "value": "Krueng Seunong"
  },
  {
    "id": "1108032020",
    "district_id": "110803",
    "label": "Pulo Iboih",
    "value": "Pulo Iboih"
  },
  {
    "id": "1108032021",
    "district_id": "110803",
    "label": "Blang Gurah",
    "value": "Blang Gurah"
  },
  {
    "id": "1108032022",
    "district_id": "110803",
    "label": "Guha Ulue",
    "value": "Guha Ulue"
  },
  {
    "id": "1108032023",
    "district_id": "110803",
    "label": "Ceumeucet",
    "value": "Ceumeucet"
  },
  {
    "id": "1108032024",
    "district_id": "110803",
    "label": "Pulo Barat",
    "value": "Pulo Barat"
  },
  {
    "id": "1108032025",
    "district_id": "110803",
    "label": "Pulo Rayeuk",
    "value": "Pulo Rayeuk"
  },
  {
    "id": "1108032026",
    "district_id": "110803",
    "label": "Cot Seutui",
    "value": "Cot Seutui"
  },
  {
    "id": "1108032027",
    "district_id": "110803",
    "label": "Langkuta",
    "value": "Langkuta"
  },
  {
    "id": "1108032028",
    "district_id": "110803",
    "label": "Saweuk",
    "value": "Saweuk"
  },
  {
    "id": "1108032029",
    "district_id": "110803",
    "label": "Meuria",
    "value": "Meuria"
  },
  {
    "id": "1108032030",
    "district_id": "110803",
    "label": "Meunasah Kulam",
    "value": "Meunasah Kulam"
  },
  {
    "id": "1108032031",
    "district_id": "110803",
    "label": "Cot Seumiyong",
    "value": "Cot Seumiyong"
  },
  {
    "id": "1108032032",
    "district_id": "110803",
    "label": "Keureusek",
    "value": "Keureusek"
  },
  {
    "id": "1108032033",
    "district_id": "110803",
    "label": "Krueng Seupeng",
    "value": "Krueng Seupeng"
  },
  {
    "id": "1108032034",
    "district_id": "110803",
    "label": "Muling Manyang",
    "value": "Muling Manyang"
  },
  {
    "id": "1108032035",
    "district_id": "110803",
    "label": "Muling Meucat",
    "value": "Muling Meucat"
  },
  {
    "id": "1108032036",
    "district_id": "110803",
    "label": "Babah Lueng",
    "value": "Babah Lueng"
  },
  {
    "id": "1108032037",
    "district_id": "110803",
    "label": "Panton Rayeuk I",
    "value": "Panton Rayeuk I"
  },
  {
    "id": "1108032038",
    "district_id": "110803",
    "label": "Panton Rayeuk II",
    "value": "Panton Rayeuk II"
  },
  {
    "id": "1108032039",
    "district_id": "110803",
    "label": "Sido Muliyo",
    "value": "Sido Muliyo"
  },
  {
    "id": "1108042001",
    "district_id": "110804",
    "label": "Kuta Lhoksukon",
    "value": "Kuta Lhoksukon"
  },
  {
    "id": "1108042002",
    "district_id": "110804",
    "label": "Cot U Sibak",
    "value": "Cot U Sibak"
  },
  {
    "id": "1108042003",
    "district_id": "110804",
    "label": "Bintang Hu",
    "value": "Bintang Hu"
  },
  {
    "id": "1108042004",
    "district_id": "110804",
    "label": "Blang Aman",
    "value": "Blang Aman"
  },
  {
    "id": "1108042005",
    "district_id": "110804",
    "label": "Cot Ara",
    "value": "Cot Ara"
  },
  {
    "id": "1108042006",
    "district_id": "110804",
    "label": "Blang Rubek",
    "value": "Blang Rubek"
  },
  {
    "id": "1108042007",
    "district_id": "110804",
    "label": "Nga Matang Ubi",
    "value": "Nga Matang Ubi"
  },
  {
    "id": "1108042008",
    "district_id": "110804",
    "label": "Meunasah Reudeup",
    "value": "Meunasah Reudeup"
  },
  {
    "id": "1108042009",
    "district_id": "110804",
    "label": "Alue Buket",
    "value": "Alue Buket"
  },
  {
    "id": "1108042010",
    "district_id": "110804",
    "label": "Meunye Matang Ubi",
    "value": "Meunye Matang Ubi"
  },
  {
    "id": "1108042011",
    "district_id": "110804",
    "label": "Trieng Matang Ubi",
    "value": "Trieng Matang Ubi"
  },
  {
    "id": "1108042012",
    "district_id": "110804",
    "label": "Ceubrek",
    "value": "Ceubrek"
  },
  {
    "id": "1108042013",
    "district_id": "110804",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1108042014",
    "district_id": "110804",
    "label": "Buket Seuntang",
    "value": "Buket Seuntang"
  },
  {
    "id": "1108042015",
    "district_id": "110804",
    "label": "Meunasah Arongan AB",
    "value": "Meunasah Arongan AB"
  },
  {
    "id": "1108042016",
    "district_id": "110804",
    "label": "Trieng Pantang",
    "value": "Trieng Pantang"
  },
  {
    "id": "1108042017",
    "district_id": "110804",
    "label": "Meunasah Leubok AB",
    "value": "Meunasah Leubok AB"
  },
  {
    "id": "1108042018",
    "district_id": "110804",
    "label": "Cot Glumpang AB",
    "value": "Cot Glumpang AB"
  },
  {
    "id": "1108042019",
    "district_id": "110804",
    "label": "Abeuk Leupon",
    "value": "Abeuk Leupon"
  },
  {
    "id": "1108042020",
    "district_id": "110804",
    "label": "Ara AB",
    "value": "Ara AB"
  },
  {
    "id": "1108042021",
    "district_id": "110804",
    "label": "Mancang",
    "value": "Mancang"
  },
  {
    "id": "1108042022",
    "district_id": "110804",
    "label": "Matang Pupanji AB",
    "value": "Matang Pupanji AB"
  },
  {
    "id": "1108042023",
    "district_id": "110804",
    "label": "Meunasah Asan AB",
    "value": "Meunasah Asan AB"
  },
  {
    "id": "1108042024",
    "district_id": "110804",
    "label": "Matang Teungoh AB",
    "value": "Matang Teungoh AB"
  },
  {
    "id": "1108042025",
    "district_id": "110804",
    "label": "Pante",
    "value": "Pante"
  },
  {
    "id": "1108042026",
    "district_id": "110804",
    "label": "Rambot",
    "value": "Rambot"
  },
  {
    "id": "1108042027",
    "district_id": "110804",
    "label": "Meunasah Alue Drien LB",
    "value": "Meunasah Alue Drien LB"
  },
  {
    "id": "1108042028",
    "district_id": "110804",
    "label": "Alue Mudem",
    "value": "Alue Mudem"
  },
  {
    "id": "1108042029",
    "district_id": "110804",
    "label": "Meunasah Geulinggang",
    "value": "Meunasah Geulinggang"
  },
  {
    "id": "1108042030",
    "district_id": "110804",
    "label": "Meunasah Asan LB",
    "value": "Meunasah Asan LB"
  },
  {
    "id": "1108042031",
    "district_id": "110804",
    "label": "Beringin LB",
    "value": "Beringin LB"
  },
  {
    "id": "1108042032",
    "district_id": "110804",
    "label": "Meunasah Teungoh LB",
    "value": "Meunasah Teungoh LB"
  },
  {
    "id": "1108042033",
    "district_id": "110804",
    "label": "Meunasah Rayeuk LB",
    "value": "Meunasah Rayeuk LB"
  },
  {
    "id": "1108042034",
    "district_id": "110804",
    "label": "Meunasah Ulee Barat",
    "value": "Meunasah Ulee Barat"
  },
  {
    "id": "1108042035",
    "district_id": "110804",
    "label": "Meunasah Ranto",
    "value": "Meunasah Ranto"
  },
  {
    "id": "1108042036",
    "district_id": "110804",
    "label": "Meunasah Tutong",
    "value": "Meunasah Tutong"
  },
  {
    "id": "1108042037",
    "district_id": "110804",
    "label": "Meunasah Nga LB",
    "value": "Meunasah Nga LB"
  },
  {
    "id": "1108042038",
    "district_id": "110804",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1108042039",
    "district_id": "110804",
    "label": "Matang Munjee",
    "value": "Matang Munjee"
  },
  {
    "id": "1108042040",
    "district_id": "110804",
    "label": "Pulo Dulang",
    "value": "Pulo Dulang"
  },
  {
    "id": "1108042041",
    "district_id": "110804",
    "label": "Dayah LB",
    "value": "Dayah LB"
  },
  {
    "id": "1108042042",
    "district_id": "110804",
    "label": "Meunasah Geumata",
    "value": "Meunasah Geumata"
  },
  {
    "id": "1108042043",
    "district_id": "110804",
    "label": "Meunasah Meureubo",
    "value": "Meunasah Meureubo"
  },
  {
    "id": "1108042044",
    "district_id": "110804",
    "label": "Meunasah Dayah LT",
    "value": "Meunasah Dayah LT"
  },
  {
    "id": "1108042045",
    "district_id": "110804",
    "label": "Arongan LT",
    "value": "Arongan LT"
  },
  {
    "id": "1108042046",
    "district_id": "110804",
    "label": "Meunasah Tuha",
    "value": "Meunasah Tuha"
  },
  {
    "id": "1108042047",
    "district_id": "110804",
    "label": "Meunasah Krueng LT",
    "value": "Meunasah Krueng LT"
  },
  {
    "id": "1108042048",
    "district_id": "110804",
    "label": "Babah Geudeubang",
    "value": "Babah Geudeubang"
  },
  {
    "id": "1108042049",
    "district_id": "110804",
    "label": "Grong-grong",
    "value": "Grong-grong"
  },
  {
    "id": "1108042050",
    "district_id": "110804",
    "label": "Alue Itam Reudeup",
    "value": "Alue Itam Reudeup"
  },
  {
    "id": "1108042051",
    "district_id": "110804",
    "label": "Teupin Keubeu",
    "value": "Teupin Keubeu"
  },
  {
    "id": "1108042052",
    "district_id": "110804",
    "label": "Meunasah Nga LT",
    "value": "Meunasah Nga LT"
  },
  {
    "id": "1108042053",
    "district_id": "110804",
    "label": "Meunasah Meuria",
    "value": "Meunasah Meuria"
  },
  {
    "id": "1108042054",
    "district_id": "110804",
    "label": "Kumbang LT",
    "value": "Kumbang LT"
  },
  {
    "id": "1108042055",
    "district_id": "110804",
    "label": "Seneubok Dalam",
    "value": "Seneubok Dalam"
  },
  {
    "id": "1108042056",
    "district_id": "110804",
    "label": "Alue Eumpok",
    "value": "Alue Eumpok"
  },
  {
    "id": "1108042057",
    "district_id": "110804",
    "label": "Meunasah Jok",
    "value": "Meunasah Jok"
  },
  {
    "id": "1108042058",
    "district_id": "110804",
    "label": "Manyang",
    "value": "Manyang"
  },
  {
    "id": "1108042059",
    "district_id": "110804",
    "label": "Lhok Kareung",
    "value": "Lhok Kareung"
  },
  {
    "id": "1108042060",
    "district_id": "110804",
    "label": "Cot Asan",
    "value": "Cot Asan"
  },
  {
    "id": "1108042061",
    "district_id": "110804",
    "label": "Alue Abee",
    "value": "Alue Abee"
  },
  {
    "id": "1108042062",
    "district_id": "110804",
    "label": "Buloh LT",
    "value": "Buloh LT"
  },
  {
    "id": "1108042063",
    "district_id": "110804",
    "label": "Geulumpang",
    "value": "Geulumpang"
  },
  {
    "id": "1108042064",
    "district_id": "110804",
    "label": "Meunasah Teungoh LT",
    "value": "Meunasah Teungoh LT"
  },
  {
    "id": "1108042065",
    "district_id": "110804",
    "label": "Mata U",
    "value": "Mata U"
  },
  {
    "id": "1108042066",
    "district_id": "110804",
    "label": "Meucat",
    "value": "Meucat"
  },
  {
    "id": "1108042067",
    "district_id": "110804",
    "label": "Buket Mee LT",
    "value": "Buket Mee LT"
  },
  {
    "id": "1108042068",
    "district_id": "110804",
    "label": "Ulee Tanoh",
    "value": "Ulee Tanoh"
  },
  {
    "id": "1108042069",
    "district_id": "110804",
    "label": "Buket Krueng",
    "value": "Buket Krueng"
  },
  {
    "id": "1108042070",
    "district_id": "110804",
    "label": "Ulee Gunong",
    "value": "Ulee Gunong"
  },
  {
    "id": "1108042071",
    "district_id": "110804",
    "label": "Lhok Seuntang",
    "value": "Lhok Seuntang"
  },
  {
    "id": "1108042072",
    "district_id": "110804",
    "label": "Alue Itam Baroh",
    "value": "Alue Itam Baroh"
  },
  {
    "id": "1108042073",
    "district_id": "110804",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1108042074",
    "district_id": "110804",
    "label": "Rawa",
    "value": "Rawa"
  },
  {
    "id": "1108042075",
    "district_id": "110804",
    "label": "Buket Hagu",
    "value": "Buket Hagu"
  },
  {
    "id": "1108052001",
    "district_id": "110805",
    "label": "Keude Matangkuli",
    "value": "Keude Matangkuli"
  },
  {
    "id": "1108052002",
    "district_id": "110805",
    "label": "Ude",
    "value": "Ude"
  },
  {
    "id": "1108052003",
    "district_id": "110805",
    "label": "Blang Matangkuli",
    "value": "Blang Matangkuli"
  },
  {
    "id": "1108052004",
    "district_id": "110805",
    "label": "Rayeuk Matangkuli",
    "value": "Rayeuk Matangkuli"
  },
  {
    "id": "1108052005",
    "district_id": "110805",
    "label": "Tumpok Perlak",
    "value": "Tumpok Perlak"
  },
  {
    "id": "1108052006",
    "district_id": "110805",
    "label": "Ujong Kulam",
    "value": "Ujong Kulam"
  },
  {
    "id": "1108052007",
    "district_id": "110805",
    "label": "Tanjong Teungku Ali",
    "value": "Tanjong Teungku Ali"
  },
  {
    "id": "1108052008",
    "district_id": "110805",
    "label": "Rayeuk Glang Glong",
    "value": "Rayeuk Glang Glong"
  },
  {
    "id": "1108052009",
    "district_id": "110805",
    "label": "Punti Geulumpang VII",
    "value": "Punti Geulumpang VII"
  },
  {
    "id": "1108052010",
    "district_id": "110805",
    "label": "Geulumpang VII",
    "value": "Geulumpang VII"
  },
  {
    "id": "1108052011",
    "district_id": "110805",
    "label": "Teungoh Glumpang VII",
    "value": "Teungoh Glumpang VII"
  },
  {
    "id": "1108052012",
    "district_id": "110805",
    "label": "Aron Geulumpang VII",
    "value": "Aron Geulumpang VII"
  },
  {
    "id": "1108052013",
    "district_id": "110805",
    "label": "Matang Munye",
    "value": "Matang Munye"
  },
  {
    "id": "1108052014",
    "district_id": "110805",
    "label": "Parang Sikureung",
    "value": "Parang Sikureung"
  },
  {
    "id": "1108052015",
    "district_id": "110805",
    "label": "Baro",
    "value": "Baro"
  },
  {
    "id": "1108052016",
    "district_id": "110805",
    "label": "Mee",
    "value": "Mee"
  },
  {
    "id": "1108052017",
    "district_id": "110805",
    "label": "Tanjong Babah Krueng",
    "value": "Tanjong Babah Krueng"
  },
  {
    "id": "1108052018",
    "district_id": "110805",
    "label": "Punti Matangkuli",
    "value": "Punti Matangkuli"
  },
  {
    "id": "1108052019",
    "district_id": "110805",
    "label": "Teupin Keubeu",
    "value": "Teupin Keubeu"
  },
  {
    "id": "1108052020",
    "district_id": "110805",
    "label": "Trieng Teupin Keubeu",
    "value": "Trieng Teupin Keubeu"
  },
  {
    "id": "1108052021",
    "district_id": "110805",
    "label": "Matang Mee",
    "value": "Matang Mee"
  },
  {
    "id": "1108052022",
    "district_id": "110805",
    "label": "Jeumpa Geulumpang VII",
    "value": "Jeumpa Geulumpang VII"
  },
  {
    "id": "1108052034",
    "district_id": "110805",
    "label": "Tumpok Barat",
    "value": "Tumpok Barat"
  },
  {
    "id": "1108052035",
    "district_id": "110805",
    "label": "Meuria Matangkuli",
    "value": "Meuria Matangkuli"
  },
  {
    "id": "1108052036",
    "district_id": "110805",
    "label": "Hagu",
    "value": "Hagu"
  },
  {
    "id": "1108052037",
    "district_id": "110805",
    "label": "Alue Tho",
    "value": "Alue Tho"
  },
  {
    "id": "1108052039",
    "district_id": "110805",
    "label": "Ceubrek Pirak",
    "value": "Ceubrek Pirak"
  },
  {
    "id": "1108052040",
    "district_id": "110805",
    "label": "Rayeuk Pirak",
    "value": "Rayeuk Pirak"
  },
  {
    "id": "1108052041",
    "district_id": "110805",
    "label": "Aron Pirak",
    "value": "Aron Pirak"
  },
  {
    "id": "1108052042",
    "district_id": "110805",
    "label": "Lawang",
    "value": "Lawang"
  },
  {
    "id": "1108052043",
    "district_id": "110805",
    "label": "Tanjong Haji Muda",
    "value": "Tanjong Haji Muda"
  },
  {
    "id": "1108052044",
    "district_id": "110805",
    "label": "Siren",
    "value": "Siren"
  },
  {
    "id": "1108052045",
    "district_id": "110805",
    "label": "Meunye Pirak",
    "value": "Meunye Pirak"
  },
  {
    "id": "1108052046",
    "district_id": "110805",
    "label": "Beuringen Pirak",
    "value": "Beuringen Pirak"
  },
  {
    "id": "1108052047",
    "district_id": "110805",
    "label": "Teungoh Pirak",
    "value": "Teungoh Pirak"
  },
  {
    "id": "1108052048",
    "district_id": "110805",
    "label": "Mesjid Pirak",
    "value": "Mesjid Pirak"
  },
  {
    "id": "1108052049",
    "district_id": "110805",
    "label": "Matang Peusangan",
    "value": "Matang Peusangan"
  },
  {
    "id": "1108052050",
    "district_id": "110805",
    "label": "Pante Pirak",
    "value": "Pante Pirak"
  },
  {
    "id": "1108052052",
    "district_id": "110805",
    "label": "Leubok Pirak",
    "value": "Leubok Pirak"
  },
  {
    "id": "1108052053",
    "district_id": "110805",
    "label": "Blang Kuta",
    "value": "Blang Kuta"
  },
  {
    "id": "1108052054",
    "district_id": "110805",
    "label": "Blang Supeng",
    "value": "Blang Supeng"
  },
  {
    "id": "1108052055",
    "district_id": "110805",
    "label": "Beurancan Pirak",
    "value": "Beurancan Pirak"
  },
  {
    "id": "1108052066",
    "district_id": "110805",
    "label": "Alue Entok",
    "value": "Alue Entok"
  },
  {
    "id": "1108052067",
    "district_id": "110805",
    "label": "Tanjong Teungku Kari",
    "value": "Tanjong Teungku Kari"
  },
  {
    "id": "1108052068",
    "district_id": "110805",
    "label": "Teungoh Seuleumak",
    "value": "Teungoh Seuleumak"
  },
  {
    "id": "1108052069",
    "district_id": "110805",
    "label": "Seuriweuk",
    "value": "Seuriweuk"
  },
  {
    "id": "1108052070",
    "district_id": "110805",
    "label": "Dayah Baro",
    "value": "Dayah Baro"
  },
  {
    "id": "1108052071",
    "district_id": "110805",
    "label": "Kunyet Mulee",
    "value": "Kunyet Mulee"
  },
  {
    "id": "1108052072",
    "district_id": "110805",
    "label": "Tutong",
    "value": "Tutong"
  },
  {
    "id": "1108062001",
    "district_id": "110806",
    "label": "Keude Mane",
    "value": "Keude Mane"
  },
  {
    "id": "1108062002",
    "district_id": "110806",
    "label": "Meunasah Drang",
    "value": "Meunasah Drang"
  },
  {
    "id": "1108062003",
    "district_id": "110806",
    "label": "Meunasah Baro",
    "value": "Meunasah Baro"
  },
  {
    "id": "1108062004",
    "district_id": "110806",
    "label": "Meunasah Lhok",
    "value": "Meunasah Lhok"
  },
  {
    "id": "1108062005",
    "district_id": "110806",
    "label": "Cot Seurani",
    "value": "Cot Seurani"
  },
  {
    "id": "1108062006",
    "district_id": "110806",
    "label": "Mane Tunong",
    "value": "Mane Tunong"
  },
  {
    "id": "1108062007",
    "district_id": "110806",
    "label": "Kuala Dua",
    "value": "Kuala Dua"
  },
  {
    "id": "1108062008",
    "district_id": "110806",
    "label": "Meunasah Pinto",
    "value": "Meunasah Pinto"
  },
  {
    "id": "1108062009",
    "district_id": "110806",
    "label": "Keude Bungkaih",
    "value": "Keude Bungkaih"
  },
  {
    "id": "1108062010",
    "district_id": "110806",
    "label": "Kambam",
    "value": "Kambam"
  },
  {
    "id": "1108062011",
    "district_id": "110806",
    "label": "Meunasah Aron",
    "value": "Meunasah Aron"
  },
  {
    "id": "1108062012",
    "district_id": "110806",
    "label": "Cot Trueng",
    "value": "Cot Trueng"
  },
  {
    "id": "1108062013",
    "district_id": "110806",
    "label": "Dakuta",
    "value": "Dakuta"
  },
  {
    "id": "1108062014",
    "district_id": "110806",
    "label": "Paloh Awe",
    "value": "Paloh Awe"
  },
  {
    "id": "1108062015",
    "district_id": "110806",
    "label": "Tanoh Anoe",
    "value": "Tanoh Anoe"
  },
  {
    "id": "1108062016",
    "district_id": "110806",
    "label": "Pante Gurah",
    "value": "Pante Gurah"
  },
  {
    "id": "1108062017",
    "district_id": "110806",
    "label": "Teupin Banja",
    "value": "Teupin Banja"
  },
  {
    "id": "1108062018",
    "district_id": "110806",
    "label": "Teumpok Beurandang",
    "value": "Teumpok Beurandang"
  },
  {
    "id": "1108062019",
    "district_id": "110806",
    "label": "Paloh Raya",
    "value": "Paloh Raya"
  },
  {
    "id": "1108062020",
    "district_id": "110806",
    "label": "Panigah",
    "value": "Panigah"
  },
  {
    "id": "1108062021",
    "district_id": "110806",
    "label": "Ulee Madon",
    "value": "Ulee Madon"
  },
  {
    "id": "1108062022",
    "district_id": "110806",
    "label": "Reuleut Timu",
    "value": "Reuleut Timu"
  },
  {
    "id": "1108062023",
    "district_id": "110806",
    "label": "Reuleut Barat",
    "value": "Reuleut Barat"
  },
  {
    "id": "1108062024",
    "district_id": "110806",
    "label": "Pinto Makmur",
    "value": "Pinto Makmur"
  },
  {
    "id": "1108072001",
    "district_id": "110807",
    "label": "Dayah Bluek",
    "value": "Dayah Bluek"
  },
  {
    "id": "1108072002",
    "district_id": "110807",
    "label": "Mesjid Bluek",
    "value": "Mesjid Bluek"
  },
  {
    "id": "1108072003",
    "district_id": "110807",
    "label": "Meuria Bluek",
    "value": "Meuria Bluek"
  },
  {
    "id": "1108072004",
    "district_id": "110807",
    "label": "Rheng Bluek",
    "value": "Rheng Bluek"
  },
  {
    "id": "1108072005",
    "district_id": "110807",
    "label": "Pulo Bluek",
    "value": "Pulo Bluek"
  },
  {
    "id": "1108072006",
    "district_id": "110807",
    "label": "Rangkileh",
    "value": "Rangkileh"
  },
  {
    "id": "1108072007",
    "district_id": "110807",
    "label": "Rayeuk Matang",
    "value": "Rayeuk Matang"
  },
  {
    "id": "1108072008",
    "district_id": "110807",
    "label": "Ulee Meuria",
    "value": "Ulee Meuria"
  },
  {
    "id": "1108072009",
    "district_id": "110807",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "1108072010",
    "district_id": "110807",
    "label": "Keude Karieng",
    "value": "Keude Karieng"
  },
  {
    "id": "1108072011",
    "district_id": "110807",
    "label": "Manyang",
    "value": "Manyang"
  },
  {
    "id": "1108072012",
    "district_id": "110807",
    "label": "Ulee Ceubrek",
    "value": "Ulee Ceubrek"
  },
  {
    "id": "1108072013",
    "district_id": "110807",
    "label": "Teumpok Teungku",
    "value": "Teumpok Teungku"
  },
  {
    "id": "1108072014",
    "district_id": "110807",
    "label": "Geulumpang",
    "value": "Geulumpang"
  },
  {
    "id": "1108072015",
    "district_id": "110807",
    "label": "Reudeup",
    "value": "Reudeup"
  },
  {
    "id": "1108072016",
    "district_id": "110807",
    "label": "Barat Paya Itek",
    "value": "Barat Paya Itek"
  },
  {
    "id": "1108072017",
    "district_id": "110807",
    "label": "Paya Kambuk",
    "value": "Paya Kambuk"
  },
  {
    "id": "1108072018",
    "district_id": "110807",
    "label": "Paya Bili",
    "value": "Paya Bili"
  },
  {
    "id": "1108072019",
    "district_id": "110807",
    "label": "Meunasah Mee",
    "value": "Meunasah Mee"
  },
  {
    "id": "1108072020",
    "district_id": "110807",
    "label": "Meunasah Nga",
    "value": "Meunasah Nga"
  },
  {
    "id": "1108072021",
    "district_id": "110807",
    "label": "Ubit Paya Itek",
    "value": "Ubit Paya Itek"
  },
  {
    "id": "1108072022",
    "district_id": "110807",
    "label": "Rayeuk Paya Itek",
    "value": "Rayeuk Paya Itek"
  },
  {
    "id": "1108072023",
    "district_id": "110807",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1108072024",
    "district_id": "110807",
    "label": "Keeh",
    "value": "Keeh"
  },
  {
    "id": "1108072025",
    "district_id": "110807",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1108072026",
    "district_id": "110807",
    "label": "Pri Keutapang",
    "value": "Pri Keutapang"
  },
  {
    "id": "1108072027",
    "district_id": "110807",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1108072028",
    "district_id": "110807",
    "label": "Teungoh Reuba",
    "value": "Teungoh Reuba"
  },
  {
    "id": "1108072029",
    "district_id": "110807",
    "label": "Blang Reuma",
    "value": "Blang Reuma"
  },
  {
    "id": "1108072030",
    "district_id": "110807",
    "label": "Drien Puntong",
    "value": "Drien Puntong"
  },
  {
    "id": "1108072031",
    "district_id": "110807",
    "label": "Pulo Kitou",
    "value": "Pulo Kitou"
  },
  {
    "id": "1108072032",
    "district_id": "110807",
    "label": "Ceubrek",
    "value": "Ceubrek"
  },
  {
    "id": "1108072033",
    "district_id": "110807",
    "label": "Meunye Peut",
    "value": "Meunye Peut"
  },
  {
    "id": "1108072034",
    "district_id": "110807",
    "label": "Beuringen",
    "value": "Beuringen"
  },
  {
    "id": "1108072035",
    "district_id": "110807",
    "label": "Nibong",
    "value": "Nibong"
  },
  {
    "id": "1108072036",
    "district_id": "110807",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1108072037",
    "district_id": "110807",
    "label": "Pulo Drien Beukah",
    "value": "Pulo Drien Beukah"
  },
  {
    "id": "1108072038",
    "district_id": "110807",
    "label": "Teungoh Kuta Batee",
    "value": "Teungoh Kuta Batee"
  },
  {
    "id": "1108072039",
    "district_id": "110807",
    "label": "Ujung Kuta Batee",
    "value": "Ujung Kuta Batee"
  },
  {
    "id": "1108072040",
    "district_id": "110807",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1108072041",
    "district_id": "110807",
    "label": "Leubok Tuwe",
    "value": "Leubok Tuwe"
  },
  {
    "id": "1108072042",
    "district_id": "110807",
    "label": "Baroh Kuta Batee",
    "value": "Baroh Kuta Batee"
  },
  {
    "id": "1108072043",
    "district_id": "110807",
    "label": "Gampong Teungoh",
    "value": "Gampong Teungoh"
  },
  {
    "id": "1108072044",
    "district_id": "110807",
    "label": "Ranto",
    "value": "Ranto"
  },
  {
    "id": "1108072045",
    "district_id": "110807",
    "label": "Meunye Payong",
    "value": "Meunye Payong"
  },
  {
    "id": "1108072046",
    "district_id": "110807",
    "label": "Ujong Reuba",
    "value": "Ujong Reuba"
  },
  {
    "id": "1108072047",
    "district_id": "110807",
    "label": "Paya Sutra",
    "value": "Paya Sutra"
  },
  {
    "id": "1108072048",
    "district_id": "110807",
    "label": "Baree Blang",
    "value": "Baree Blang"
  },
  {
    "id": "1108072049",
    "district_id": "110807",
    "label": "Keude Jungka Gajah",
    "value": "Keude Jungka Gajah"
  },
  {
    "id": "1108072050",
    "district_id": "110807",
    "label": "Saramaba",
    "value": "Saramaba"
  },
  {
    "id": "1108082001",
    "district_id": "110808",
    "label": "Pie",
    "value": "Pie"
  },
  {
    "id": "1108082002",
    "district_id": "110808",
    "label": "Asan",
    "value": "Asan"
  },
  {
    "id": "1108082003",
    "district_id": "110808",
    "label": "Murong",
    "value": "Murong"
  },
  {
    "id": "1108082004",
    "district_id": "110808",
    "label": "Blang Kabu",
    "value": "Blang Kabu"
  },
  {
    "id": "1108082005",
    "district_id": "110808",
    "label": "Mancang",
    "value": "Mancang"
  },
  {
    "id": "1108082006",
    "district_id": "110808",
    "label": "Keude Geudong",
    "value": "Keude Geudong"
  },
  {
    "id": "1108082007",
    "district_id": "110808",
    "label": "Blang Peuria",
    "value": "Blang Peuria"
  },
  {
    "id": "1108082008",
    "district_id": "110808",
    "label": "Teupin Ara",
    "value": "Teupin Ara"
  },
  {
    "id": "1108082009",
    "district_id": "110808",
    "label": "Teupin Beulangan",
    "value": "Teupin Beulangan"
  },
  {
    "id": "1108082010",
    "district_id": "110808",
    "label": "Krueng Baro Langgahan",
    "value": "Krueng Baro Langgahan"
  },
  {
    "id": "1108082011",
    "district_id": "110808",
    "label": "Pusong",
    "value": "Pusong"
  },
  {
    "id": "1108082012",
    "district_id": "110808",
    "label": "Tanjong Kleng",
    "value": "Tanjong Kleng"
  },
  {
    "id": "1108082013",
    "district_id": "110808",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1108082014",
    "district_id": "110808",
    "label": "Beuringen",
    "value": "Beuringen"
  },
  {
    "id": "1108082015",
    "district_id": "110808",
    "label": "Kuta Krueng",
    "value": "Kuta Krueng"
  },
  {
    "id": "1108082016",
    "district_id": "110808",
    "label": "Kuta Glumpang",
    "value": "Kuta Glumpang"
  },
  {
    "id": "1108082017",
    "district_id": "110808",
    "label": "Meucat",
    "value": "Meucat"
  },
  {
    "id": "1108082018",
    "district_id": "110808",
    "label": "Krueng Baro Blang Mee",
    "value": "Krueng Baro Blang Mee"
  },
  {
    "id": "1108082019",
    "district_id": "110808",
    "label": "Ujong",
    "value": "Ujong"
  },
  {
    "id": "1108082020",
    "district_id": "110808",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1108082021",
    "district_id": "110808",
    "label": "Teungoh",
    "value": "Teungoh"
  },
  {
    "id": "1108082022",
    "district_id": "110808",
    "label": "Keude Blang Mee Pulo Klat",
    "value": "Keude Blang Mee Pulo Klat"
  },
  {
    "id": "1108082023",
    "district_id": "110808",
    "label": "Puuk",
    "value": "Puuk"
  },
  {
    "id": "1108082024",
    "district_id": "110808",
    "label": "Lancang",
    "value": "Lancang"
  },
  {
    "id": "1108082025",
    "district_id": "110808",
    "label": "Matang Ulim",
    "value": "Matang Ulim"
  },
  {
    "id": "1108082026",
    "district_id": "110808",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "1108082027",
    "district_id": "110808",
    "label": "Laga Baro",
    "value": "Laga Baro"
  },
  {
    "id": "1108082028",
    "district_id": "110808",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "1108082029",
    "district_id": "110808",
    "label": "Matang Tunong",
    "value": "Matang Tunong"
  },
  {
    "id": "1108082030",
    "district_id": "110808",
    "label": "Matang Puntong",
    "value": "Matang Puntong"
  },
  {
    "id": "1108082031",
    "district_id": "110808",
    "label": "Blang Nibong",
    "value": "Blang Nibong"
  },
  {
    "id": "1108082032",
    "district_id": "110808",
    "label": "Tanjong Baroh",
    "value": "Tanjong Baroh"
  },
  {
    "id": "1108082033",
    "district_id": "110808",
    "label": "Tanjong Hagu",
    "value": "Tanjong Hagu"
  },
  {
    "id": "1108082034",
    "district_id": "110808",
    "label": "Tanjong Reungkam",
    "value": "Tanjong Reungkam"
  },
  {
    "id": "1108082035",
    "district_id": "110808",
    "label": "Tanjong Mesjid",
    "value": "Tanjong Mesjid"
  },
  {
    "id": "1108082036",
    "district_id": "110808",
    "label": "Paya Terbang",
    "value": "Paya Terbang"
  },
  {
    "id": "1108082037",
    "district_id": "110808",
    "label": "Madan",
    "value": "Madan"
  },
  {
    "id": "1108082038",
    "district_id": "110808",
    "label": "Tanjong Awe",
    "value": "Tanjong Awe"
  },
  {
    "id": "1108082039",
    "district_id": "110808",
    "label": "Kitou",
    "value": "Kitou"
  },
  {
    "id": "1108082040",
    "district_id": "110808",
    "label": "Krueng Matee",
    "value": "Krueng Matee"
  },
  {
    "id": "1108092001",
    "district_id": "110809",
    "label": "Tanjong Pineng",
    "value": "Tanjong Pineng"
  },
  {
    "id": "1108092002",
    "district_id": "110809",
    "label": "Meurubo Puntong",
    "value": "Meurubo Puntong"
  },
  {
    "id": "1108092003",
    "district_id": "110809",
    "label": "Mane Kawan",
    "value": "Mane Kawan"
  },
  {
    "id": "1108092004",
    "district_id": "110809",
    "label": "Alue Barueh",
    "value": "Alue Barueh"
  },
  {
    "id": "1108092005",
    "district_id": "110809",
    "label": "Keude Simpang Jalan",
    "value": "Keude Simpang Jalan"
  },
  {
    "id": "1108092006",
    "district_id": "110809",
    "label": "Blang Pha",
    "value": "Blang Pha"
  },
  {
    "id": "1108092007",
    "district_id": "110809",
    "label": "Alue Kiran",
    "value": "Alue Kiran"
  },
  {
    "id": "1108092008",
    "district_id": "110809",
    "label": "Blang Tue",
    "value": "Blang Tue"
  },
  {
    "id": "1108092009",
    "district_id": "110809",
    "label": "Cot Kafiraton",
    "value": "Cot Kafiraton"
  },
  {
    "id": "1108092010",
    "district_id": "110809",
    "label": "Tanjong Dama",
    "value": "Tanjong Dama"
  },
  {
    "id": "1108092011",
    "district_id": "110809",
    "label": "Paya Dua Uram",
    "value": "Paya Dua Uram"
  },
  {
    "id": "1108092012",
    "district_id": "110809",
    "label": "Paya Dua Ujong",
    "value": "Paya Dua Ujong"
  },
  {
    "id": "1108092013",
    "district_id": "110809",
    "label": "Alue Capli",
    "value": "Alue Capli"
  },
  {
    "id": "1108092014",
    "district_id": "110809",
    "label": "Simpang Peut",
    "value": "Simpang Peut"
  },
  {
    "id": "1108092015",
    "district_id": "110809",
    "label": "Meunasah Sagoe",
    "value": "Meunasah Sagoe"
  },
  {
    "id": "1108092016",
    "district_id": "110809",
    "label": "Matang Puntong",
    "value": "Matang Puntong"
  },
  {
    "id": "1108092017",
    "district_id": "110809",
    "label": "Matang Panyang",
    "value": "Matang Panyang"
  },
  {
    "id": "1108092018",
    "district_id": "110809",
    "label": "Cot Patisah",
    "value": "Cot Patisah"
  },
  {
    "id": "1108092019",
    "district_id": "110809",
    "label": "Cot Trueng",
    "value": "Cot Trueng"
  },
  {
    "id": "1108092020",
    "district_id": "110809",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1108092021",
    "district_id": "110809",
    "label": "Matang Jeulikat",
    "value": "Matang Jeulikat"
  },
  {
    "id": "1108092022",
    "district_id": "110809",
    "label": "Lhok Puuk",
    "value": "Lhok Puuk"
  },
  {
    "id": "1108092023",
    "district_id": "110809",
    "label": "Ulee Rubek Barat",
    "value": "Ulee Rubek Barat"
  },
  {
    "id": "1108092024",
    "district_id": "110809",
    "label": "Ulee Rubek Timu",
    "value": "Ulee Rubek Timu"
  },
  {
    "id": "1108092025",
    "district_id": "110809",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "1108092026",
    "district_id": "110809",
    "label": "Teupin Kuyuen",
    "value": "Teupin Kuyuen"
  },
  {
    "id": "1108092027",
    "district_id": "110809",
    "label": "Matang Lada",
    "value": "Matang Lada"
  },
  {
    "id": "1108092028",
    "district_id": "110809",
    "label": "Ulee Matang",
    "value": "Ulee Matang"
  },
  {
    "id": "1108092029",
    "district_id": "110809",
    "label": "Matang Karieng",
    "value": "Matang Karieng"
  },
  {
    "id": "1108092030",
    "district_id": "110809",
    "label": "Lhok Rambideng",
    "value": "Lhok Rambideng"
  },
  {
    "id": "1108092031",
    "district_id": "110809",
    "label": "Matang Anoe",
    "value": "Matang Anoe"
  },
  {
    "id": "1108092032",
    "district_id": "110809",
    "label": "Lhok Geulituet",
    "value": "Lhok Geulituet"
  },
  {
    "id": "1108092033",
    "district_id": "110809",
    "label": "Ulee Titi",
    "value": "Ulee Titi"
  },
  {
    "id": "1108102001",
    "district_id": "110810",
    "label": "Keude Aron",
    "value": "Keude Aron"
  },
  {
    "id": "1108102002",
    "district_id": "110810",
    "label": "Dayah Aron",
    "value": "Dayah Aron"
  },
  {
    "id": "1108102003",
    "district_id": "110810",
    "label": "Moncrang",
    "value": "Moncrang"
  },
  {
    "id": "1108102004",
    "district_id": "110810",
    "label": "Kanot",
    "value": "Kanot"
  },
  {
    "id": "1108102005",
    "district_id": "110810",
    "label": "Meuria Aron",
    "value": "Meuria Aron"
  },
  {
    "id": "1108102006",
    "district_id": "110810",
    "label": "Mee Aron",
    "value": "Mee Aron"
  },
  {
    "id": "1108102007",
    "district_id": "110810",
    "label": "Glok",
    "value": "Glok"
  },
  {
    "id": "1108102008",
    "district_id": "110810",
    "label": "Cibrek Baroh",
    "value": "Cibrek Baroh"
  },
  {
    "id": "1108102009",
    "district_id": "110810",
    "label": "Cibrek Tunong",
    "value": "Cibrek Tunong"
  },
  {
    "id": "1108102010",
    "district_id": "110810",
    "label": "Matang Munye",
    "value": "Matang Munye"
  },
  {
    "id": "1108102011",
    "district_id": "110810",
    "label": "Pante",
    "value": "Pante"
  },
  {
    "id": "1108102012",
    "district_id": "110810",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1108102013",
    "district_id": "110810",
    "label": "Blang",
    "value": "Blang"
  },
  {
    "id": "1108102014",
    "district_id": "110810",
    "label": "Teungoh",
    "value": "Teungoh"
  },
  {
    "id": "1108102015",
    "district_id": "110810",
    "label": "Tanjong Krueng Pase",
    "value": "Tanjong Krueng Pase"
  },
  {
    "id": "1108102016",
    "district_id": "110810",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1108102017",
    "district_id": "110810",
    "label": "Keude Teupin Punti",
    "value": "Keude Teupin Punti"
  },
  {
    "id": "1108102018",
    "district_id": "110810",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1108102019",
    "district_id": "110810",
    "label": "Gampong U",
    "value": "Gampong U"
  },
  {
    "id": "1108102020",
    "district_id": "110810",
    "label": "Calong",
    "value": "Calong"
  },
  {
    "id": "1108102021",
    "district_id": "110810",
    "label": "Manyang Baroh",
    "value": "Manyang Baroh"
  },
  {
    "id": "1108102022",
    "district_id": "110810",
    "label": "Peurupok",
    "value": "Peurupok"
  },
  {
    "id": "1108102023",
    "district_id": "110810",
    "label": "Alue Gunto",
    "value": "Alue Gunto"
  },
  {
    "id": "1108102024",
    "district_id": "110810",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "1108102025",
    "district_id": "110810",
    "label": "Kulam",
    "value": "Kulam"
  },
  {
    "id": "1108102026",
    "district_id": "110810",
    "label": "Ampeh",
    "value": "Ampeh"
  },
  {
    "id": "1108102027",
    "district_id": "110810",
    "label": "Dayah Meuria",
    "value": "Dayah Meuria"
  },
  {
    "id": "1108102028",
    "district_id": "110810",
    "label": "Mampree",
    "value": "Mampree"
  },
  {
    "id": "1108102029",
    "district_id": "110810",
    "label": "Meucat",
    "value": "Meucat"
  },
  {
    "id": "1108102030",
    "district_id": "110810",
    "label": "Ara",
    "value": "Ara"
  },
  {
    "id": "1108102031",
    "district_id": "110810",
    "label": "Hagu",
    "value": "Hagu"
  },
  {
    "id": "1108102032",
    "district_id": "110810",
    "label": "Awe",
    "value": "Awe"
  },
  {
    "id": "1108102033",
    "district_id": "110810",
    "label": "Tanjong Mulieng",
    "value": "Tanjong Mulieng"
  },
  {
    "id": "1108102034",
    "district_id": "110810",
    "label": "Daya Teungku",
    "value": "Daya Teungku"
  },
  {
    "id": "1108112001",
    "district_id": "110811",
    "label": "Bale",
    "value": "Bale"
  },
  {
    "id": "1108112002",
    "district_id": "110811",
    "label": "Bie",
    "value": "Bie"
  },
  {
    "id": "1108112003",
    "district_id": "110811",
    "label": "Beurandang",
    "value": "Beurandang"
  },
  {
    "id": "1108112004",
    "district_id": "110811",
    "label": "Baro Kulam Gajah",
    "value": "Baro Kulam Gajah"
  },
  {
    "id": "1108112005",
    "district_id": "110811",
    "label": "Glong",
    "value": "Glong"
  },
  {
    "id": "1108112006",
    "district_id": "110811",
    "label": "Nibong",
    "value": "Nibong"
  },
  {
    "id": "1108112007",
    "district_id": "110811",
    "label": "Langa",
    "value": "Langa"
  },
  {
    "id": "1108112008",
    "district_id": "110811",
    "label": "Beunot",
    "value": "Beunot"
  },
  {
    "id": "1108112009",
    "district_id": "110811",
    "label": "Keude Bayu",
    "value": "Keude Bayu"
  },
  {
    "id": "1108112010",
    "district_id": "110811",
    "label": "Blang Bayu",
    "value": "Blang Bayu"
  },
  {
    "id": "1108112011",
    "district_id": "110811",
    "label": "Dayah Tuha",
    "value": "Dayah Tuha"
  },
  {
    "id": "1108112012",
    "district_id": "110811",
    "label": "Dayah Baro",
    "value": "Dayah Baro"
  },
  {
    "id": "1108112013",
    "district_id": "110811",
    "label": "Lancok",
    "value": "Lancok"
  },
  {
    "id": "1108112014",
    "district_id": "110811",
    "label": "Punti",
    "value": "Punti"
  },
  {
    "id": "1108112015",
    "district_id": "110811",
    "label": "Bungong",
    "value": "Bungong"
  },
  {
    "id": "1108112016",
    "district_id": "110811",
    "label": "Baroh Blang Rimueng",
    "value": "Baroh Blang Rimueng"
  },
  {
    "id": "1108112017",
    "district_id": "110811",
    "label": "Ulee Meuria",
    "value": "Ulee Meuria"
  },
  {
    "id": "1108112018",
    "district_id": "110811",
    "label": "Kayee Panyang",
    "value": "Kayee Panyang"
  },
  {
    "id": "1108112019",
    "district_id": "110811",
    "label": "Rheng",
    "value": "Rheng"
  },
  {
    "id": "1108112020",
    "district_id": "110811",
    "label": "Trieng Meudurou",
    "value": "Trieng Meudurou"
  },
  {
    "id": "1108112021",
    "district_id": "110811",
    "label": "Ulee Gampong",
    "value": "Ulee Gampong"
  },
  {
    "id": "1108112022",
    "district_id": "110811",
    "label": "Garut",
    "value": "Garut"
  },
  {
    "id": "1108112023",
    "district_id": "110811",
    "label": "Blang Awe",
    "value": "Blang Awe"
  },
  {
    "id": "1108112024",
    "district_id": "110811",
    "label": "Pulo Blang Mangat",
    "value": "Pulo Blang Mangat"
  },
  {
    "id": "1108112025",
    "district_id": "110811",
    "label": "Cibrek",
    "value": "Cibrek"
  },
  {
    "id": "1108112026",
    "district_id": "110811",
    "label": "Pulo Blang Trieng",
    "value": "Pulo Blang Trieng"
  },
  {
    "id": "1108112027",
    "district_id": "110811",
    "label": "Meudang Ara",
    "value": "Meudang Ara"
  },
  {
    "id": "1108112028",
    "district_id": "110811",
    "label": "Cot Neuheun",
    "value": "Cot Neuheun"
  },
  {
    "id": "1108112029",
    "district_id": "110811",
    "label": "Tumpeun",
    "value": "Tumpeun"
  },
  {
    "id": "1108112030",
    "district_id": "110811",
    "label": "Alen",
    "value": "Alen"
  },
  {
    "id": "1108112031",
    "district_id": "110811",
    "label": "Dayah Blang Seureukuy",
    "value": "Dayah Blang Seureukuy"
  },
  {
    "id": "1108112032",
    "district_id": "110811",
    "label": "Blang Patra",
    "value": "Blang Patra"
  },
  {
    "id": "1108112033",
    "district_id": "110811",
    "label": "Blang Majron",
    "value": "Blang Majron"
  },
  {
    "id": "1108112034",
    "district_id": "110811",
    "label": "Seuneubok Baro",
    "value": "Seuneubok Baro"
  },
  {
    "id": "1108112035",
    "district_id": "110811",
    "label": "Buket Glumpang",
    "value": "Buket Glumpang"
  },
  {
    "id": "1108112037",
    "district_id": "110811",
    "label": "Alue Majron",
    "value": "Alue Majron"
  },
  {
    "id": "1108112038",
    "district_id": "110811",
    "label": "Siren Tujoh",
    "value": "Siren Tujoh"
  },
  {
    "id": "1108112047",
    "district_id": "110811",
    "label": "Blang Seureukuy",
    "value": "Blang Seureukuy"
  },
  {
    "id": "1108122001",
    "district_id": "110812",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1108122002",
    "district_id": "110812",
    "label": "Matang Ben",
    "value": "Matang Ben"
  },
  {
    "id": "1108122003",
    "district_id": "110812",
    "label": "Matang Cibrek",
    "value": "Matang Cibrek"
  },
  {
    "id": "1108122004",
    "district_id": "110812",
    "label": "Deng",
    "value": "Deng"
  },
  {
    "id": "1108122005",
    "district_id": "110812",
    "label": "Rawa",
    "value": "Rawa"
  },
  {
    "id": "1108122006",
    "district_id": "110812",
    "label": "Punti Pulo Agam",
    "value": "Punti Pulo Agam"
  },
  {
    "id": "1108122007",
    "district_id": "110812",
    "label": "Alue Pangkat",
    "value": "Alue Pangkat"
  },
  {
    "id": "1108122008",
    "district_id": "110812",
    "label": "Teungoh Pulo Agam",
    "value": "Teungoh Pulo Agam"
  },
  {
    "id": "1108122009",
    "district_id": "110812",
    "label": "Hueng",
    "value": "Hueng"
  },
  {
    "id": "1108122010",
    "district_id": "110812",
    "label": "Pulo U",
    "value": "Pulo U"
  },
  {
    "id": "1108122011",
    "district_id": "110812",
    "label": "Alue Keujruen",
    "value": "Alue Keujruen"
  },
  {
    "id": "1108122012",
    "district_id": "110812",
    "label": "Paya Beurandang",
    "value": "Paya Beurandang"
  },
  {
    "id": "1108122013",
    "district_id": "110812",
    "label": "Manyang Tunong",
    "value": "Manyang Tunong"
  },
  {
    "id": "1108122014",
    "district_id": "110812",
    "label": "Meunasah Tutong",
    "value": "Meunasah Tutong"
  },
  {
    "id": "1108122015",
    "district_id": "110812",
    "label": "Ampeh",
    "value": "Ampeh"
  },
  {
    "id": "1108122016",
    "district_id": "110812",
    "label": "Alue",
    "value": "Alue"
  },
  {
    "id": "1108122017",
    "district_id": "110812",
    "label": "Teungku Dibalee",
    "value": "Teungku Dibalee"
  },
  {
    "id": "1108122018",
    "district_id": "110812",
    "label": "Keude Blang Jruen",
    "value": "Keude Blang Jruen"
  },
  {
    "id": "1108122019",
    "district_id": "110812",
    "label": "Blang Jruen",
    "value": "Blang Jruen"
  },
  {
    "id": "1108122020",
    "district_id": "110812",
    "label": "Ujong Baroh Berghang",
    "value": "Ujong Baroh Berghang"
  },
  {
    "id": "1108122021",
    "district_id": "110812",
    "label": "Jeumpa Berghang",
    "value": "Jeumpa Berghang"
  },
  {
    "id": "1108122022",
    "district_id": "110812",
    "label": "Teungoh Berghang",
    "value": "Teungoh Berghang"
  },
  {
    "id": "1108122023",
    "district_id": "110812",
    "label": "Pante Berghang",
    "value": "Pante Berghang"
  },
  {
    "id": "1108122024",
    "district_id": "110812",
    "label": "Serba Jaman",
    "value": "Serba Jaman"
  },
  {
    "id": "1108122025",
    "district_id": "110812",
    "label": "Rayeuk Naleung",
    "value": "Rayeuk Naleung"
  },
  {
    "id": "1108122026",
    "district_id": "110812",
    "label": "Rangkaya",
    "value": "Rangkaya"
  },
  {
    "id": "1108122027",
    "district_id": "110812",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1108122028",
    "district_id": "110812",
    "label": "Blang Bidok",
    "value": "Blang Bidok"
  },
  {
    "id": "1108122029",
    "district_id": "110812",
    "label": "Rayeuk Kuta",
    "value": "Rayeuk Kuta"
  },
  {
    "id": "1108122030",
    "district_id": "110812",
    "label": "Rayeuk Munye",
    "value": "Rayeuk Munye"
  },
  {
    "id": "1108122031",
    "district_id": "110812",
    "label": "Ujong Baroh SB",
    "value": "Ujong Baroh SB"
  },
  {
    "id": "1108122032",
    "district_id": "110812",
    "label": "Trieng",
    "value": "Trieng"
  },
  {
    "id": "1108122033",
    "district_id": "110812",
    "label": "Manyang SB",
    "value": "Manyang SB"
  },
  {
    "id": "1108122034",
    "district_id": "110812",
    "label": "Cibrek",
    "value": "Cibrek"
  },
  {
    "id": "1108122035",
    "district_id": "110812",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1108122036",
    "district_id": "110812",
    "label": "Matang Mane",
    "value": "Matang Mane"
  },
  {
    "id": "1108122037",
    "district_id": "110812",
    "label": "Alue Gampong",
    "value": "Alue Gampong"
  },
  {
    "id": "1108122038",
    "district_id": "110812",
    "label": "Bayi",
    "value": "Bayi"
  },
  {
    "id": "1108122039",
    "district_id": "110812",
    "label": "Tumpok Aceh",
    "value": "Tumpok Aceh"
  },
  {
    "id": "1108122040",
    "district_id": "110812",
    "label": "Ulee Buket",
    "value": "Ulee Buket"
  },
  {
    "id": "1108122041",
    "district_id": "110812",
    "label": "Cot Dah",
    "value": "Cot Dah"
  },
  {
    "id": "1108122042",
    "district_id": "110812",
    "label": "Leupon Siren",
    "value": "Leupon Siren"
  },
  {
    "id": "1108122043",
    "district_id": "110812",
    "label": "Blang Trieng",
    "value": "Blang Trieng"
  },
  {
    "id": "1108122044",
    "district_id": "110812",
    "label": "Alue Sijuek",
    "value": "Alue Sijuek"
  },
  {
    "id": "1108122045",
    "district_id": "110812",
    "label": "Hagu",
    "value": "Hagu"
  },
  {
    "id": "1108122046",
    "district_id": "110812",
    "label": "Teupin Mee",
    "value": "Teupin Mee"
  },
  {
    "id": "1108122047",
    "district_id": "110812",
    "label": "Plu Pakam",
    "value": "Plu Pakam"
  },
  {
    "id": "1108122048",
    "district_id": "110812",
    "label": "Blang Pie",
    "value": "Blang Pie"
  },
  {
    "id": "1108122049",
    "district_id": "110812",
    "label": "Punti Seuleumak Barat",
    "value": "Punti Seuleumak Barat"
  },
  {
    "id": "1108122050",
    "district_id": "110812",
    "label": "Serba Jaman Tunong",
    "value": "Serba Jaman Tunong"
  },
  {
    "id": "1108122051",
    "district_id": "110812",
    "label": "Leuhong",
    "value": "Leuhong"
  },
  {
    "id": "1108122052",
    "district_id": "110812",
    "label": "Blang",
    "value": "Blang"
  },
  {
    "id": "1108122053",
    "district_id": "110812",
    "label": "Tanjong Mesjid",
    "value": "Tanjong Mesjid"
  },
  {
    "id": "1108122054",
    "district_id": "110812",
    "label": "Serba Jaman Baroh",
    "value": "Serba Jaman Baroh"
  },
  {
    "id": "1108122055",
    "district_id": "110812",
    "label": "Cot Barat",
    "value": "Cot Barat"
  },
  {
    "id": "1108122056",
    "district_id": "110812",
    "label": "Matang Baloy",
    "value": "Matang Baloy"
  },
  {
    "id": "1108122057",
    "district_id": "110812",
    "label": "Buket Makarti",
    "value": "Buket Makarti"
  },
  {
    "id": "1108132001",
    "district_id": "110813",
    "label": "Alue",
    "value": "Alue"
  },
  {
    "id": "1108132002",
    "district_id": "110813",
    "label": "Mee Matang Panyang",
    "value": "Mee Matang Panyang"
  },
  {
    "id": "1108132003",
    "district_id": "110813",
    "label": "Blang",
    "value": "Blang"
  },
  {
    "id": "1108132004",
    "district_id": "110813",
    "label": "Paloh",
    "value": "Paloh"
  },
  {
    "id": "1108132005",
    "district_id": "110813",
    "label": "Keude Matang Payang",
    "value": "Keude Matang Payang"
  },
  {
    "id": "1108132006",
    "district_id": "110813",
    "label": "Gampong Pande",
    "value": "Gampong Pande"
  },
  {
    "id": "1108132007",
    "district_id": "110813",
    "label": "Matang Ranup Laseh",
    "value": "Matang Ranup Laseh"
  },
  {
    "id": "1108132008",
    "district_id": "110813",
    "label": "Cangguek",
    "value": "Cangguek"
  },
  {
    "id": "1108132009",
    "district_id": "110813",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "1108132010",
    "district_id": "110813",
    "label": "Prie",
    "value": "Prie"
  },
  {
    "id": "1108132011",
    "district_id": "110813",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1108132012",
    "district_id": "110813",
    "label": "Keude Jrat Manyang",
    "value": "Keude Jrat Manyang"
  },
  {
    "id": "1108132013",
    "district_id": "110813",
    "label": "Teupin Gapeuh",
    "value": "Teupin Gapeuh"
  },
  {
    "id": "1108132014",
    "district_id": "110813",
    "label": "Cibrek",
    "value": "Cibrek"
  },
  {
    "id": "1108132015",
    "district_id": "110813",
    "label": "Me Merbo",
    "value": "Me Merbo"
  },
  {
    "id": "1108132016",
    "district_id": "110813",
    "label": "Ulee Tanoh",
    "value": "Ulee Tanoh"
  },
  {
    "id": "1108132017",
    "district_id": "110813",
    "label": "Matang Janeng",
    "value": "Matang Janeng"
  },
  {
    "id": "1108132018",
    "district_id": "110813",
    "label": "Kuala Keureutou Barat",
    "value": "Kuala Keureutou Barat"
  },
  {
    "id": "1108142001",
    "district_id": "110814",
    "label": "Teupin Gajah",
    "value": "Teupin Gajah"
  },
  {
    "id": "1108142002",
    "district_id": "110814",
    "label": "Geulumpang Umpung Unou",
    "value": "Geulumpang Umpung Unou"
  },
  {
    "id": "1108142003",
    "district_id": "110814",
    "label": "Ranto Panyang",
    "value": "Ranto Panyang"
  },
  {
    "id": "1108142004",
    "district_id": "110814",
    "label": "Seuneubok Doe",
    "value": "Seuneubok Doe"
  },
  {
    "id": "1108142005",
    "district_id": "110814",
    "label": "Meunasah Geudong",
    "value": "Meunasah Geudong"
  },
  {
    "id": "1108142006",
    "district_id": "110814",
    "label": "Meunasah Merbo",
    "value": "Meunasah Merbo"
  },
  {
    "id": "1108142007",
    "district_id": "110814",
    "label": "Tanjong Meunye",
    "value": "Tanjong Meunye"
  },
  {
    "id": "1108142008",
    "district_id": "110814",
    "label": "Matang Jurong",
    "value": "Matang Jurong"
  },
  {
    "id": "1108142009",
    "district_id": "110814",
    "label": "Tanjong Dalam Utara",
    "value": "Tanjong Dalam Utara"
  },
  {
    "id": "1108142010",
    "district_id": "110814",
    "label": "Meunasah Dayah",
    "value": "Meunasah Dayah"
  },
  {
    "id": "1108142011",
    "district_id": "110814",
    "label": "Seuneubok Pidie",
    "value": "Seuneubok Pidie"
  },
  {
    "id": "1108142012",
    "district_id": "110814",
    "label": "Tanjong Menuang",
    "value": "Tanjong Menuang"
  },
  {
    "id": "1108142013",
    "district_id": "110814",
    "label": "Matang Teungoh-Teungoh",
    "value": "Matang Teungoh-Teungoh"
  },
  {
    "id": "1108142014",
    "district_id": "110814",
    "label": "Lhok Reudeup",
    "value": "Lhok Reudeup"
  },
  {
    "id": "1108142015",
    "district_id": "110814",
    "label": "Lhok Merbo",
    "value": "Lhok Merbo"
  },
  {
    "id": "1108142016",
    "district_id": "110814",
    "label": "Tanjong Ara",
    "value": "Tanjong Ara"
  },
  {
    "id": "1108142017",
    "district_id": "110814",
    "label": "Alue Ie Mirah",
    "value": "Alue Ie Mirah"
  },
  {
    "id": "1108142018",
    "district_id": "110814",
    "label": "Tanjong Punti",
    "value": "Tanjong Punti"
  },
  {
    "id": "1108142019",
    "district_id": "110814",
    "label": "Bukit Alue Puteh",
    "value": "Bukit Alue Puteh"
  },
  {
    "id": "1108142020",
    "district_id": "110814",
    "label": "Buket Jrat Manyang",
    "value": "Buket Jrat Manyang"
  },
  {
    "id": "1108142021",
    "district_id": "110814",
    "label": "Matang Maneh",
    "value": "Matang Maneh"
  },
  {
    "id": "1108142022",
    "district_id": "110814",
    "label": "Pucok Alue",
    "value": "Pucok Alue"
  },
  {
    "id": "1108142023",
    "district_id": "110814",
    "label": "Matang Serdang",
    "value": "Matang Serdang"
  },
  {
    "id": "1108142024",
    "district_id": "110814",
    "label": "Matang Santot",
    "value": "Matang Santot"
  },
  {
    "id": "1108142025",
    "district_id": "110814",
    "label": "Buket Padang",
    "value": "Buket Padang"
  },
  {
    "id": "1108142026",
    "district_id": "110814",
    "label": "Matang Seuke Pulot",
    "value": "Matang Seuke Pulot"
  },
  {
    "id": "1108142027",
    "district_id": "110814",
    "label": "Lhok Beuringen",
    "value": "Lhok Beuringen"
  },
  {
    "id": "1108142028",
    "district_id": "110814",
    "label": "Lueng Tuha",
    "value": "Lueng Tuha"
  },
  {
    "id": "1108142029",
    "district_id": "110814",
    "label": "Matang Raya",
    "value": "Matang Raya"
  },
  {
    "id": "1108142030",
    "district_id": "110814",
    "label": "Tanjong Ceungai",
    "value": "Tanjong Ceungai"
  },
  {
    "id": "1108142031",
    "district_id": "110814",
    "label": "Teupin Bayu",
    "value": "Teupin Bayu"
  },
  {
    "id": "1108142032",
    "district_id": "110814",
    "label": "Meunasah Panton Labu",
    "value": "Meunasah Panton Labu"
  },
  {
    "id": "1108142033",
    "district_id": "110814",
    "label": "Kota Panton Labu",
    "value": "Kota Panton Labu"
  },
  {
    "id": "1108142034",
    "district_id": "110814",
    "label": "Rawang Itek",
    "value": "Rawang Itek"
  },
  {
    "id": "1108142035",
    "district_id": "110814",
    "label": "Samakurok",
    "value": "Samakurok"
  },
  {
    "id": "1108142036",
    "district_id": "110814",
    "label": "Biara Barat",
    "value": "Biara Barat"
  },
  {
    "id": "1108142037",
    "district_id": "110814",
    "label": "Biara Timur",
    "value": "Biara Timur"
  },
  {
    "id": "1108142038",
    "district_id": "110814",
    "label": "Matang Drien",
    "value": "Matang Drien"
  },
  {
    "id": "1108142039",
    "district_id": "110814",
    "label": "Ceumpeudak",
    "value": "Ceumpeudak"
  },
  {
    "id": "1108142040",
    "district_id": "110814",
    "label": "Alue Papeun",
    "value": "Alue Papeun"
  },
  {
    "id": "1108142041",
    "district_id": "110814",
    "label": "Lhok Bintang Hu",
    "value": "Lhok Bintang Hu"
  },
  {
    "id": "1108142042",
    "district_id": "110814",
    "label": "Biram Rayeuk",
    "value": "Biram Rayeuk"
  },
  {
    "id": "1108142043",
    "district_id": "110814",
    "label": "Cot Biek",
    "value": "Cot Biek"
  },
  {
    "id": "1108142044",
    "district_id": "110814",
    "label": "Matang Arongan",
    "value": "Matang Arongan"
  },
  {
    "id": "1108142045",
    "district_id": "110814",
    "label": "Biram Cut",
    "value": "Biram Cut"
  },
  {
    "id": "1108142046",
    "district_id": "110814",
    "label": "Buket Batee Badan",
    "value": "Buket Batee Badan"
  },
  {
    "id": "1108142047",
    "district_id": "110814",
    "label": "Ulee Glee",
    "value": "Ulee Glee"
  },
  {
    "id": "1108152001",
    "district_id": "110815",
    "label": "Lagang",
    "value": "Lagang"
  },
  {
    "id": "1108152002",
    "district_id": "110815",
    "label": "Abeuk Reuling",
    "value": "Abeuk Reuling"
  },
  {
    "id": "1108152003",
    "district_id": "110815",
    "label": "Lhok Krek",
    "value": "Lhok Krek"
  },
  {
    "id": "1108152004",
    "district_id": "110815",
    "label": "Lhok Merbo",
    "value": "Lhok Merbo"
  },
  {
    "id": "1108152005",
    "district_id": "110815",
    "label": "Gle Dagang",
    "value": "Gle Dagang"
  },
  {
    "id": "1108152006",
    "district_id": "110815",
    "label": "Kuta Meuligoe",
    "value": "Kuta Meuligoe"
  },
  {
    "id": "1108152007",
    "district_id": "110815",
    "label": "Gampong Teungoh",
    "value": "Gampong Teungoh"
  },
  {
    "id": "1108152008",
    "district_id": "110815",
    "label": "Babah Buloh",
    "value": "Babah Buloh"
  },
  {
    "id": "1108152009",
    "district_id": "110815",
    "label": "Meunasah Pulo",
    "value": "Meunasah Pulo"
  },
  {
    "id": "1108152010",
    "district_id": "110815",
    "label": "Punteuet",
    "value": "Punteuet"
  },
  {
    "id": "1108152011",
    "district_id": "110815",
    "label": "Pante Jaloh",
    "value": "Pante Jaloh"
  },
  {
    "id": "1108152012",
    "district_id": "110815",
    "label": "Lhok Kuyun",
    "value": "Lhok Kuyun"
  },
  {
    "id": "1108152013",
    "district_id": "110815",
    "label": "Blang Reuling",
    "value": "Blang Reuling"
  },
  {
    "id": "1108152014",
    "district_id": "110815",
    "label": "Lhok Gajah",
    "value": "Lhok Gajah"
  },
  {
    "id": "1108152015",
    "district_id": "110815",
    "label": "Teupin Rusep",
    "value": "Teupin Rusep"
  },
  {
    "id": "1108152016",
    "district_id": "110815",
    "label": "Ulee Geudong",
    "value": "Ulee Geudong"
  },
  {
    "id": "1108152017",
    "district_id": "110815",
    "label": "Tanjong Keumala",
    "value": "Tanjong Keumala"
  },
  {
    "id": "1108152018",
    "district_id": "110815",
    "label": "Cot Kumuneng",
    "value": "Cot Kumuneng"
  },
  {
    "id": "1108152019",
    "district_id": "110815",
    "label": "Cot Lambideng",
    "value": "Cot Lambideng"
  },
  {
    "id": "1108152020",
    "district_id": "110815",
    "label": "Paya Gaboh",
    "value": "Paya Gaboh"
  },
  {
    "id": "1108152021",
    "district_id": "110815",
    "label": "Paya Rabo Lhok",
    "value": "Paya Rabo Lhok"
  },
  {
    "id": "1108152022",
    "district_id": "110815",
    "label": "Paya Rabo Timu",
    "value": "Paya Rabo Timu"
  },
  {
    "id": "1108152023",
    "district_id": "110815",
    "label": "Lancok",
    "value": "Lancok"
  },
  {
    "id": "1108152024",
    "district_id": "110815",
    "label": "Rambong Payong",
    "value": "Rambong Payong"
  },
  {
    "id": "1108152025",
    "district_id": "110815",
    "label": "Lhok Bayu",
    "value": "Lhok Bayu"
  },
  {
    "id": "1108152026",
    "district_id": "110815",
    "label": "Krueng Baro",
    "value": "Krueng Baro"
  },
  {
    "id": "1108152027",
    "district_id": "110815",
    "label": "Babah Krueng",
    "value": "Babah Krueng"
  },
  {
    "id": "1108152028",
    "district_id": "110815",
    "label": "Jurong",
    "value": "Jurong"
  },
  {
    "id": "1108152029",
    "district_id": "110815",
    "label": "Blang Manyak",
    "value": "Blang Manyak"
  },
  {
    "id": "1108152030",
    "district_id": "110815",
    "label": "Lhok Jok",
    "value": "Lhok Jok"
  },
  {
    "id": "1108152031",
    "district_id": "110815",
    "label": "Blang Teurakan",
    "value": "Blang Teurakan"
  },
  {
    "id": "1108152032",
    "district_id": "110815",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "1108152033",
    "district_id": "110815",
    "label": "Riseh Baroh",
    "value": "Riseh Baroh"
  },
  {
    "id": "1108152034",
    "district_id": "110815",
    "label": "Riseh Teungoh",
    "value": "Riseh Teungoh"
  },
  {
    "id": "1108152035",
    "district_id": "110815",
    "label": "Riseh Tunong",
    "value": "Riseh Tunong"
  },
  {
    "id": "1108152036",
    "district_id": "110815",
    "label": "Gunci",
    "value": "Gunci"
  },
  {
    "id": "1108152037",
    "district_id": "110815",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "1108152038",
    "district_id": "110815",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1108152039",
    "district_id": "110815",
    "label": "Lhok Cut",
    "value": "Lhok Cut"
  },
  {
    "id": "1108162015",
    "district_id": "110816",
    "label": "Paloh Mambu",
    "value": "Paloh Mambu"
  },
  {
    "id": "1108162017",
    "district_id": "110816",
    "label": "Blang Karieng",
    "value": "Blang Karieng"
  },
  {
    "id": "1108162018",
    "district_id": "110816",
    "label": "Paloh Kayee Kunyet",
    "value": "Paloh Kayee Kunyet"
  },
  {
    "id": "1108162019",
    "district_id": "110816",
    "label": "Gampong Barat",
    "value": "Gampong Barat"
  },
  {
    "id": "1108162020",
    "district_id": "110816",
    "label": "Meunasah Beunot",
    "value": "Meunasah Beunot"
  },
  {
    "id": "1108162021",
    "district_id": "110816",
    "label": "Seuneubok",
    "value": "Seuneubok"
  },
  {
    "id": "1108162022",
    "district_id": "110816",
    "label": "Cot Leupee",
    "value": "Cot Leupee"
  },
  {
    "id": "1108162023",
    "district_id": "110816",
    "label": "Gampong Teungoh",
    "value": "Gampong Teungoh"
  },
  {
    "id": "1108162024",
    "district_id": "110816",
    "label": "Meunasah Meucat",
    "value": "Meunasah Meucat"
  },
  {
    "id": "1108162025",
    "district_id": "110816",
    "label": "Meunasah Alue",
    "value": "Meunasah Alue"
  },
  {
    "id": "1108162026",
    "district_id": "110816",
    "label": "Panton",
    "value": "Panton"
  },
  {
    "id": "1108162027",
    "district_id": "110816",
    "label": "Paloh Mampree",
    "value": "Paloh Mampree"
  },
  {
    "id": "1108162028",
    "district_id": "110816",
    "label": "Peunayan",
    "value": "Peunayan"
  },
  {
    "id": "1108162029",
    "district_id": "110816",
    "label": "Tingkeum",
    "value": "Tingkeum"
  },
  {
    "id": "1108162030",
    "district_id": "110816",
    "label": "Meunasah Rayeuk",
    "value": "Meunasah Rayeuk"
  },
  {
    "id": "1108162031",
    "district_id": "110816",
    "label": "Meunasah Cut",
    "value": "Meunasah Cut"
  },
  {
    "id": "1108162032",
    "district_id": "110816",
    "label": "Jeulikat",
    "value": "Jeulikat"
  },
  {
    "id": "1108162033",
    "district_id": "110816",
    "label": "Alue Bili",
    "value": "Alue Bili"
  },
  {
    "id": "1108162034",
    "district_id": "110816",
    "label": "Cot Mambong",
    "value": "Cot Mambong"
  },
  {
    "id": "1108162035",
    "district_id": "110816",
    "label": "Blang Dalam Geunteng",
    "value": "Blang Dalam Geunteng"
  },
  {
    "id": "1108162036",
    "district_id": "110816",
    "label": "Blang Dalam Tunong",
    "value": "Blang Dalam Tunong"
  },
  {
    "id": "1108162037",
    "district_id": "110816",
    "label": "Blang Dalam Baroh",
    "value": "Blang Dalam Baroh"
  },
  {
    "id": "1108162038",
    "district_id": "110816",
    "label": "Cot Mee",
    "value": "Cot Mee"
  },
  {
    "id": "1108162039",
    "district_id": "110816",
    "label": "Cot Euntung",
    "value": "Cot Euntung"
  },
  {
    "id": "1108162040",
    "district_id": "110816",
    "label": "Blang Crok",
    "value": "Blang Crok"
  },
  {
    "id": "1108162041",
    "district_id": "110816",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1108162042",
    "district_id": "110816",
    "label": "Binjee",
    "value": "Binjee"
  },
  {
    "id": "1108162043",
    "district_id": "110816",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1108162044",
    "district_id": "110816",
    "label": "Ulee Blang",
    "value": "Ulee Blang"
  },
  {
    "id": "1108172001",
    "district_id": "110817",
    "label": "Cot Girek",
    "value": "Cot Girek"
  },
  {
    "id": "1108172002",
    "district_id": "110817",
    "label": "Lhok Meurbo",
    "value": "Lhok Meurbo"
  },
  {
    "id": "1108172003",
    "district_id": "110817",
    "label": "Kp. Bantan",
    "value": "Kp. Bantan"
  },
  {
    "id": "1108172004",
    "district_id": "110817",
    "label": "Alue Seumambu",
    "value": "Alue Seumambu"
  },
  {
    "id": "1108172005",
    "district_id": "110817",
    "label": "Ulee Gampong",
    "value": "Ulee Gampong"
  },
  {
    "id": "1108172006",
    "district_id": "110817",
    "label": "Batu XII",
    "value": "Batu XII"
  },
  {
    "id": "1108172007",
    "district_id": "110817",
    "label": "Kp. Tempel",
    "value": "Kp. Tempel"
  },
  {
    "id": "1108172008",
    "district_id": "110817",
    "label": "Alue Leuhob",
    "value": "Alue Leuhob"
  },
  {
    "id": "1108172009",
    "district_id": "110817",
    "label": "Seuneubok Baro",
    "value": "Seuneubok Baro"
  },
  {
    "id": "1108172010",
    "district_id": "110817",
    "label": "Alue Drien",
    "value": "Alue Drien"
  },
  {
    "id": "1108172011",
    "district_id": "110817",
    "label": "Cempeudak",
    "value": "Cempeudak"
  },
  {
    "id": "1108172012",
    "district_id": "110817",
    "label": "Matang Teungoh",
    "value": "Matang Teungoh"
  },
  {
    "id": "1108172013",
    "district_id": "110817",
    "label": "Pucok Alue",
    "value": "Pucok Alue"
  },
  {
    "id": "1108172014",
    "district_id": "110817",
    "label": "U Baro.",
    "value": "U Baro."
  },
  {
    "id": "1108172015",
    "district_id": "110817",
    "label": "Trieng",
    "value": "Trieng"
  },
  {
    "id": "1108172016",
    "district_id": "110817",
    "label": "Lhok Reuhat",
    "value": "Lhok Reuhat"
  },
  {
    "id": "1108172017",
    "district_id": "110817",
    "label": "Lueng Baro",
    "value": "Lueng Baro"
  },
  {
    "id": "1108172018",
    "district_id": "110817",
    "label": "Jeulikat",
    "value": "Jeulikat"
  },
  {
    "id": "1108172019",
    "district_id": "110817",
    "label": "Gampong Ara",
    "value": "Gampong Ara"
  },
  {
    "id": "1108172020",
    "district_id": "110817",
    "label": "Beurandang Dayah",
    "value": "Beurandang Dayah"
  },
  {
    "id": "1108172021",
    "district_id": "110817",
    "label": "Beurandang Krueng",
    "value": "Beurandang Krueng"
  },
  {
    "id": "1108172022",
    "district_id": "110817",
    "label": "Seupeng",
    "value": "Seupeng"
  },
  {
    "id": "1108172023",
    "district_id": "110817",
    "label": "Beurandang Asan",
    "value": "Beurandang Asan"
  },
  {
    "id": "1108172024",
    "district_id": "110817",
    "label": "Drien II",
    "value": "Drien II"
  },
  {
    "id": "1108182001",
    "district_id": "110818",
    "label": "Cot Bada",
    "value": "Cot Bada"
  },
  {
    "id": "1108182002",
    "district_id": "110818",
    "label": "Alue Dua",
    "value": "Alue Dua"
  },
  {
    "id": "1108182003",
    "district_id": "110818",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "1108182004",
    "district_id": "110818",
    "label": "Kampong Blang",
    "value": "Kampong Blang"
  },
  {
    "id": "1108182005",
    "district_id": "110818",
    "label": "Paya Tukai",
    "value": "Paya Tukai"
  },
  {
    "id": "1108182006",
    "district_id": "110818",
    "label": "Matang Keutapang",
    "value": "Matang Keutapang"
  },
  {
    "id": "1108182007",
    "district_id": "110818",
    "label": "Krueng Lingka",
    "value": "Krueng Lingka"
  },
  {
    "id": "1108182008",
    "district_id": "110818",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1108182009",
    "district_id": "110818",
    "label": "Matang Rubek",
    "value": "Matang Rubek"
  },
  {
    "id": "1108182010",
    "district_id": "110818",
    "label": "Leubok Mane",
    "value": "Leubok Mane"
  },
  {
    "id": "1108182011",
    "district_id": "110818",
    "label": "Matang Teungoh Selatan",
    "value": "Matang Teungoh Selatan"
  },
  {
    "id": "1108182012",
    "district_id": "110818",
    "label": "Padang Meuria",
    "value": "Padang Meuria"
  },
  {
    "id": "1108182013",
    "district_id": "110818",
    "label": "Alue Krak Kayee",
    "value": "Alue Krak Kayee"
  },
  {
    "id": "1108182014",
    "district_id": "110818",
    "label": "Tanjong Jawa",
    "value": "Tanjong Jawa"
  },
  {
    "id": "1108182015",
    "district_id": "110818",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1108182016",
    "district_id": "110818",
    "label": "Tanjong Dalam Selatan",
    "value": "Tanjong Dalam Selatan"
  },
  {
    "id": "1108182017",
    "district_id": "110818",
    "label": "Geudumbak",
    "value": "Geudumbak"
  },
  {
    "id": "1108182018",
    "district_id": "110818",
    "label": "Langkahan",
    "value": "Langkahan"
  },
  {
    "id": "1108182019",
    "district_id": "110818",
    "label": "Rumoh Rayeuk",
    "value": "Rumoh Rayeuk"
  },
  {
    "id": "1108182020",
    "district_id": "110818",
    "label": "Buket Linteung",
    "value": "Buket Linteung"
  },
  {
    "id": "1108182021",
    "district_id": "110818",
    "label": "Lubok Pusaka",
    "value": "Lubok Pusaka"
  },
  {
    "id": "1108182022",
    "district_id": "110818",
    "label": "Seureuke",
    "value": "Seureuke"
  },
  {
    "id": "1108182023",
    "district_id": "110818",
    "label": "Pante Gaki Bale",
    "value": "Pante Gaki Bale"
  },
  {
    "id": "1108192001",
    "district_id": "110819",
    "label": "Keude Sampoiniet",
    "value": "Keude Sampoiniet"
  },
  {
    "id": "1108192002",
    "district_id": "110819",
    "label": "Lhok Iboh",
    "value": "Lhok Iboh"
  },
  {
    "id": "1108192003",
    "district_id": "110819",
    "label": "Matang Bayu",
    "value": "Matang Bayu"
  },
  {
    "id": "1108192004",
    "district_id": "110819",
    "label": "Lang Nibong",
    "value": "Lang Nibong"
  },
  {
    "id": "1108192005",
    "district_id": "110819",
    "label": "Blang Seunong",
    "value": "Blang Seunong"
  },
  {
    "id": "1108192006",
    "district_id": "110819",
    "label": "Singgah Mata",
    "value": "Singgah Mata"
  },
  {
    "id": "1108192007",
    "district_id": "110819",
    "label": "Matang Panyang",
    "value": "Matang Panyang"
  },
  {
    "id": "1108192008",
    "district_id": "110819",
    "label": "Matang Ceubrek",
    "value": "Matang Ceubrek"
  },
  {
    "id": "1108192009",
    "district_id": "110819",
    "label": "Matang Teungoh",
    "value": "Matang Teungoh"
  },
  {
    "id": "1108192010",
    "district_id": "110819",
    "label": "Cot Paya",
    "value": "Cot Paya"
  },
  {
    "id": "1108192011",
    "district_id": "110819",
    "label": "Cot Kupok",
    "value": "Cot Kupok"
  },
  {
    "id": "1108192012",
    "district_id": "110819",
    "label": "Matang Raya Blang Sialet",
    "value": "Matang Raya Blang Sialet"
  },
  {
    "id": "1108192013",
    "district_id": "110819",
    "label": "Pucok Alue Buket",
    "value": "Pucok Alue Buket"
  },
  {
    "id": "1108192014",
    "district_id": "110819",
    "label": "Matang Sijuek Timu",
    "value": "Matang Sijuek Timu"
  },
  {
    "id": "1108192015",
    "district_id": "110819",
    "label": "Matang Sijuek Teungoh",
    "value": "Matang Sijuek Teungoh"
  },
  {
    "id": "1108192016",
    "district_id": "110819",
    "label": "Matang Sijuek Barat",
    "value": "Matang Sijuek Barat"
  },
  {
    "id": "1108192017",
    "district_id": "110819",
    "label": "Cot Laba",
    "value": "Cot Laba"
  },
  {
    "id": "1108192018",
    "district_id": "110819",
    "label": "Meunasah Pante",
    "value": "Meunasah Pante"
  },
  {
    "id": "1108192019",
    "district_id": "110819",
    "label": "Meurandeh Paya",
    "value": "Meurandeh Paya"
  },
  {
    "id": "1108192020",
    "district_id": "110819",
    "label": "Cot Murong",
    "value": "Cot Murong"
  },
  {
    "id": "1108192021",
    "district_id": "110819",
    "label": "Matang Paya",
    "value": "Matang Paya"
  },
  {
    "id": "1108192022",
    "district_id": "110819",
    "label": "Lhok Euncien",
    "value": "Lhok Euncien"
  },
  {
    "id": "1108192023",
    "district_id": "110819",
    "label": "Cot Usen",
    "value": "Cot Usen"
  },
  {
    "id": "1108192024",
    "district_id": "110819",
    "label": "Meunasah Hagu",
    "value": "Meunasah Hagu"
  },
  {
    "id": "1108192025",
    "district_id": "110819",
    "label": "Blang Rheue",
    "value": "Blang Rheue"
  },
  {
    "id": "1108192026",
    "district_id": "110819",
    "label": "Paya Bateung",
    "value": "Paya Bateung"
  },
  {
    "id": "1108202001",
    "district_id": "110820",
    "label": "Blang Ara",
    "value": "Blang Ara"
  },
  {
    "id": "1108202002",
    "district_id": "110820",
    "label": "Tgk.Dibanda Pirak",
    "value": "Tgk.Dibanda Pirak"
  },
  {
    "id": "1108202003",
    "district_id": "110820",
    "label": "Paya Meudru",
    "value": "Paya Meudru"
  },
  {
    "id": "1108202004",
    "district_id": "110820",
    "label": "Blang Sialet",
    "value": "Blang Sialet"
  },
  {
    "id": "1108202005",
    "district_id": "110820",
    "label": "Buket Guru",
    "value": "Buket Guru"
  },
  {
    "id": "1108202006",
    "district_id": "110820",
    "label": "Geulumpang Pirak",
    "value": "Geulumpang Pirak"
  },
  {
    "id": "1108202007",
    "district_id": "110820",
    "label": "Alue Lhok",
    "value": "Alue Lhok"
  },
  {
    "id": "1108202008",
    "district_id": "110820",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1108202009",
    "district_id": "110820",
    "label": "Buket Pidie",
    "value": "Buket Pidie"
  },
  {
    "id": "1108202010",
    "district_id": "110820",
    "label": "Kebon Pirak",
    "value": "Kebon Pirak"
  },
  {
    "id": "1108202011",
    "district_id": "110820",
    "label": "Alue Leukot",
    "value": "Alue Leukot"
  },
  {
    "id": "1108202012",
    "district_id": "110820",
    "label": "Blang Mane",
    "value": "Blang Mane"
  },
  {
    "id": "1108202013",
    "district_id": "110820",
    "label": "Peureupok",
    "value": "Peureupok"
  },
  {
    "id": "1108202014",
    "district_id": "110820",
    "label": "Ulue Bieng",
    "value": "Ulue Bieng"
  },
  {
    "id": "1108202015",
    "district_id": "110820",
    "label": "Pante Seuleumak",
    "value": "Pante Seuleumak"
  },
  {
    "id": "1108202016",
    "district_id": "110820",
    "label": "Meuria Seuleumak",
    "value": "Meuria Seuleumak"
  },
  {
    "id": "1108202017",
    "district_id": "110820",
    "label": "Tunong Krueng",
    "value": "Tunong Krueng"
  },
  {
    "id": "1108202018",
    "district_id": "110820",
    "label": "Geureughek",
    "value": "Geureughek"
  },
  {
    "id": "1108202019",
    "district_id": "110820",
    "label": "Meunye Seuleumak",
    "value": "Meunye Seuleumak"
  },
  {
    "id": "1108202020",
    "district_id": "110820",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1108202021",
    "district_id": "110820",
    "label": "Tumpok Mesjid",
    "value": "Tumpok Mesjid"
  },
  {
    "id": "1108202022",
    "district_id": "110820",
    "label": "Jok",
    "value": "Jok"
  },
  {
    "id": "1108202023",
    "district_id": "110820",
    "label": "Leuhong",
    "value": "Leuhong"
  },
  {
    "id": "1108202024",
    "district_id": "110820",
    "label": "Tanjong Burunyong",
    "value": "Tanjong Burunyong"
  },
  {
    "id": "1108202025",
    "district_id": "110820",
    "label": "Keude Paya Bakong",
    "value": "Keude Paya Bakong"
  },
  {
    "id": "1108202026",
    "district_id": "110820",
    "label": "Blang Gunci",
    "value": "Blang Gunci"
  },
  {
    "id": "1108202027",
    "district_id": "110820",
    "label": "Gampong Nga",
    "value": "Gampong Nga"
  },
  {
    "id": "1108202028",
    "district_id": "110820",
    "label": "Mampree",
    "value": "Mampree"
  },
  {
    "id": "1108202029",
    "district_id": "110820",
    "label": "Matang Panyang",
    "value": "Matang Panyang"
  },
  {
    "id": "1108202030",
    "district_id": "110820",
    "label": "Tanjung Drien",
    "value": "Tanjung Drien"
  },
  {
    "id": "1108202031",
    "district_id": "110820",
    "label": "Asan Seuleumak",
    "value": "Asan Seuleumak"
  },
  {
    "id": "1108202032",
    "district_id": "110820",
    "label": "Tgk. Dibanda Tek-Tek",
    "value": "Tgk. Dibanda Tek-Tek"
  },
  {
    "id": "1108202033",
    "district_id": "110820",
    "label": "Cot Teufah",
    "value": "Cot Teufah"
  },
  {
    "id": "1108202034",
    "district_id": "110820",
    "label": "Pucok Alue Seuleumak",
    "value": "Pucok Alue Seuleumak"
  },
  {
    "id": "1108202035",
    "district_id": "110820",
    "label": "Lueng",
    "value": "Lueng"
  },
  {
    "id": "1108202036",
    "district_id": "110820",
    "label": "Cempeudak",
    "value": "Cempeudak"
  },
  {
    "id": "1108202037",
    "district_id": "110820",
    "label": "Blang Paku",
    "value": "Blang Paku"
  },
  {
    "id": "1108202038",
    "district_id": "110820",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1108202039",
    "district_id": "110820",
    "label": "Blang Pante",
    "value": "Blang Pante"
  },
  {
    "id": "1108212001",
    "district_id": "110821",
    "label": "Nibong Baroh",
    "value": "Nibong Baroh"
  },
  {
    "id": "1108212002",
    "district_id": "110821",
    "label": "Nibong Wakheuh",
    "value": "Nibong Wakheuh"
  },
  {
    "id": "1108212003",
    "district_id": "110821",
    "label": "Keude Nibong",
    "value": "Keude Nibong"
  },
  {
    "id": "1108212004",
    "district_id": "110821",
    "label": "Keupok Nibong",
    "value": "Keupok Nibong"
  },
  {
    "id": "1108212005",
    "district_id": "110821",
    "label": "Dayah Nibong",
    "value": "Dayah Nibong"
  },
  {
    "id": "1108212006",
    "district_id": "110821",
    "label": "Sumbok Rayeuk",
    "value": "Sumbok Rayeuk"
  },
  {
    "id": "1108212007",
    "district_id": "110821",
    "label": "Paya Terbang",
    "value": "Paya Terbang"
  },
  {
    "id": "1108212008",
    "district_id": "110821",
    "label": "Teupin Jok",
    "value": "Teupin Jok"
  },
  {
    "id": "1108212009",
    "district_id": "110821",
    "label": "Mamplam",
    "value": "Mamplam"
  },
  {
    "id": "1108212010",
    "district_id": "110821",
    "label": "Keulilee",
    "value": "Keulilee"
  },
  {
    "id": "1108212011",
    "district_id": "110821",
    "label": "Meunye Lhee",
    "value": "Meunye Lhee"
  },
  {
    "id": "1108212012",
    "district_id": "110821",
    "label": "Ranto",
    "value": "Ranto"
  },
  {
    "id": "1108212013",
    "district_id": "110821",
    "label": "Seulunyok",
    "value": "Seulunyok"
  },
  {
    "id": "1108212014",
    "district_id": "110821",
    "label": "Keh Nibong",
    "value": "Keh Nibong"
  },
  {
    "id": "1108212015",
    "district_id": "110821",
    "label": "Alue Ie Mirah",
    "value": "Alue Ie Mirah"
  },
  {
    "id": "1108212016",
    "district_id": "110821",
    "label": "Maddi",
    "value": "Maddi"
  },
  {
    "id": "1108212017",
    "district_id": "110821",
    "label": "Alue Ngom",
    "value": "Alue Ngom"
  },
  {
    "id": "1108212018",
    "district_id": "110821",
    "label": "Tanjong Putoh",
    "value": "Tanjong Putoh"
  },
  {
    "id": "1108212019",
    "district_id": "110821",
    "label": "Bumban",
    "value": "Bumban"
  },
  {
    "id": "1108212020",
    "district_id": "110821",
    "label": "Alue Panah",
    "value": "Alue Panah"
  },
  {
    "id": "1108222001",
    "district_id": "110822",
    "label": "Keude Simpang Empat",
    "value": "Keude Simpang Empat"
  },
  {
    "id": "1108222002",
    "district_id": "110822",
    "label": "Seuneubok Punti",
    "value": "Seuneubok Punti"
  },
  {
    "id": "1108222003",
    "district_id": "110822",
    "label": "Paya Leupah",
    "value": "Paya Leupah"
  },
  {
    "id": "1108222004",
    "district_id": "110822",
    "label": "Paya Teungoh",
    "value": "Paya Teungoh"
  },
  {
    "id": "1108222005",
    "district_id": "110822",
    "label": "Keubon Baro",
    "value": "Keubon Baro"
  },
  {
    "id": "1108222006",
    "district_id": "110822",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1108222007",
    "district_id": "110822",
    "label": "Meunasah Baroh",
    "value": "Meunasah Baroh"
  },
  {
    "id": "1108222008",
    "district_id": "110822",
    "label": "Mancang",
    "value": "Mancang"
  },
  {
    "id": "1108222009",
    "district_id": "110822",
    "label": "Alue Bade",
    "value": "Alue Bade"
  },
  {
    "id": "1108222010",
    "district_id": "110822",
    "label": "Meunasah Dayah SPK",
    "value": "Meunasah Dayah SPK"
  },
  {
    "id": "1108222011",
    "district_id": "110822",
    "label": "Kilometer VI",
    "value": "Kilometer VI"
  },
  {
    "id": "1108222012",
    "district_id": "110822",
    "label": "Kilometer VIII",
    "value": "Kilometer VIII"
  },
  {
    "id": "1108222013",
    "district_id": "110822",
    "label": "Ie Tarek I",
    "value": "Ie Tarek I"
  },
  {
    "id": "1108222014",
    "district_id": "110822",
    "label": "Ie Tarek II",
    "value": "Ie Tarek II"
  },
  {
    "id": "1108222015",
    "district_id": "110822",
    "label": "Pase Sentosa",
    "value": "Pase Sentosa"
  },
  {
    "id": "1108222016",
    "district_id": "110822",
    "label": "Blang Raleue",
    "value": "Blang Raleue"
  },
  {
    "id": "1108232001",
    "district_id": "110823",
    "label": "Keureutou",
    "value": "Keureutou"
  },
  {
    "id": "1108232002",
    "district_id": "110823",
    "label": "Lueng Baro",
    "value": "Lueng Baro"
  },
  {
    "id": "1108232003",
    "district_id": "110823",
    "label": "Merbo Jurong",
    "value": "Merbo Jurong"
  },
  {
    "id": "1108232004",
    "district_id": "110823",
    "label": "Merbo Lama",
    "value": "Merbo Lama"
  },
  {
    "id": "1108232005",
    "district_id": "110823",
    "label": "Tanjong Dama",
    "value": "Tanjong Dama"
  },
  {
    "id": "1108232006",
    "district_id": "110823",
    "label": "Geulanggang Baro",
    "value": "Geulanggang Baro"
  },
  {
    "id": "1108232007",
    "district_id": "110823",
    "label": "Matang Tunong",
    "value": "Matang Tunong"
  },
  {
    "id": "1108232008",
    "district_id": "110823",
    "label": "Keude Lapang",
    "value": "Keude Lapang"
  },
  {
    "id": "1108232009",
    "district_id": "110823",
    "label": "Matang Baroh",
    "value": "Matang Baroh"
  },
  {
    "id": "1108232010",
    "district_id": "110823",
    "label": "Kuala Kereuto",
    "value": "Kuala Kereuto"
  },
  {
    "id": "1108232011",
    "district_id": "110823",
    "label": "Kuala Cangkoy",
    "value": "Kuala Cangkoy"
  },
  {
    "id": "1108242001",
    "district_id": "110824",
    "label": "Rayeuk Pange",
    "value": "Rayeuk Pange"
  },
  {
    "id": "1108242002",
    "district_id": "110824",
    "label": "Bungong",
    "value": "Bungong"
  },
  {
    "id": "1108242003",
    "district_id": "110824",
    "label": "Geulumpang",
    "value": "Geulumpang"
  },
  {
    "id": "1108242004",
    "district_id": "110824",
    "label": "Asan Krueng Kreh",
    "value": "Asan Krueng Kreh"
  },
  {
    "id": "1108242005",
    "district_id": "110824",
    "label": "Beuracan Rata",
    "value": "Beuracan Rata"
  },
  {
    "id": "1108242006",
    "district_id": "110824",
    "label": "Reungkam",
    "value": "Reungkam"
  },
  {
    "id": "1108242007",
    "district_id": "110824",
    "label": "Trieng Krueng Kreh",
    "value": "Trieng Krueng Kreh"
  },
  {
    "id": "1108242008",
    "district_id": "110824",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1108242009",
    "district_id": "110824",
    "label": "Bili Baro",
    "value": "Bili Baro"
  },
  {
    "id": "1108242010",
    "district_id": "110824",
    "label": "Paya Lueng Jalo",
    "value": "Paya Lueng Jalo"
  },
  {
    "id": "1108242011",
    "district_id": "110824",
    "label": "Alue Rimee",
    "value": "Alue Rimee"
  },
  {
    "id": "1108242012",
    "district_id": "110824",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1108242013",
    "district_id": "110824",
    "label": "Ara Ton-Ton Moncrang",
    "value": "Ara Ton-Ton Moncrang"
  },
  {
    "id": "1108242014",
    "district_id": "110824",
    "label": "Tanjong Seureukuy",
    "value": "Tanjong Seureukuy"
  },
  {
    "id": "1108242015",
    "district_id": "110824",
    "label": "Ulee Blang",
    "value": "Ulee Blang"
  },
  {
    "id": "1108242016",
    "district_id": "110824",
    "label": "Krueng Pirak",
    "value": "Krueng Pirak"
  },
  {
    "id": "1108242017",
    "district_id": "110824",
    "label": "Matang Keh",
    "value": "Matang Keh"
  },
  {
    "id": "1108242018",
    "district_id": "110824",
    "label": "Leupe",
    "value": "Leupe"
  },
  {
    "id": "1108242019",
    "district_id": "110824",
    "label": "Ceumeucet",
    "value": "Ceumeucet"
  },
  {
    "id": "1108242020",
    "district_id": "110824",
    "label": "Teupin U",
    "value": "Teupin U"
  },
  {
    "id": "1108242021",
    "district_id": "110824",
    "label": "Alue Bungkoh",
    "value": "Alue Bungkoh"
  },
  {
    "id": "1108242022",
    "district_id": "110824",
    "label": "Pucok Alue",
    "value": "Pucok Alue"
  },
  {
    "id": "1108242023",
    "district_id": "110824",
    "label": "Meunye Tujoh",
    "value": "Meunye Tujoh"
  },
  {
    "id": "1108252001",
    "district_id": "110825",
    "label": "Lhok Asan",
    "value": "Lhok Asan"
  },
  {
    "id": "1108252002",
    "district_id": "110825",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1108252003",
    "district_id": "110825",
    "label": "Alue Awe",
    "value": "Alue Awe"
  },
  {
    "id": "1108252004",
    "district_id": "110825",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1108252005",
    "district_id": "110825",
    "label": "Rayeuk Jawa",
    "value": "Rayeuk Jawa"
  },
  {
    "id": "1108252006",
    "district_id": "110825",
    "label": "Dayah Seupeng",
    "value": "Dayah Seupeng"
  },
  {
    "id": "1108252007",
    "district_id": "110825",
    "label": "Uram Jalan",
    "value": "Uram Jalan"
  },
  {
    "id": "1108252008",
    "district_id": "110825",
    "label": "Peudari",
    "value": "Peudari"
  },
  {
    "id": "1108252009",
    "district_id": "110825",
    "label": "Krueng Mbang",
    "value": "Krueng Mbang"
  },
  {
    "id": "1108252010",
    "district_id": "110825",
    "label": "Pulo Meuria",
    "value": "Pulo Meuria"
  },
  {
    "id": "1108252011",
    "district_id": "110825",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1108262001",
    "district_id": "110826",
    "label": "Paya Dua",
    "value": "Paya Dua"
  },
  {
    "id": "1108262002",
    "district_id": "110826",
    "label": "Cot Jabet",
    "value": "Cot Jabet"
  },
  {
    "id": "1108262003",
    "district_id": "110826",
    "label": "Ulee Nyeue",
    "value": "Ulee Nyeue"
  },
  {
    "id": "1108262004",
    "district_id": "110826",
    "label": "Paya Uleue",
    "value": "Paya Uleue"
  },
  {
    "id": "1108262005",
    "district_id": "110826",
    "label": "Jamuan",
    "value": "Jamuan"
  },
  {
    "id": "1108262006",
    "district_id": "110826",
    "label": "Alue Keurinyai",
    "value": "Alue Keurinyai"
  },
  {
    "id": "1108262007",
    "district_id": "110826",
    "label": "Sangkelan",
    "value": "Sangkelan"
  },
  {
    "id": "1108262008",
    "district_id": "110826",
    "label": "Blang Pala",
    "value": "Blang Pala"
  },
  {
    "id": "1108262009",
    "district_id": "110826",
    "label": "Paya Beunyet",
    "value": "Paya Beunyet"
  },
  {
    "id": "1108272001",
    "district_id": "110827",
    "label": "Alue Dua",
    "value": "Alue Dua"
  },
  {
    "id": "1108272002",
    "district_id": "110827",
    "label": "Seumirah",
    "value": "Seumirah"
  },
  {
    "id": "1108272003",
    "district_id": "110827",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1108272004",
    "district_id": "110827",
    "label": "Alue Papeun",
    "value": "Alue Papeun"
  },
  {
    "id": "1108272005",
    "district_id": "110827",
    "label": "Blang Jrat",
    "value": "Blang Jrat"
  },
  {
    "id": "1108272006",
    "district_id": "110827",
    "label": "Blang Pohroh",
    "value": "Blang Pohroh"
  },
  {
    "id": "1109012007",
    "district_id": "110901",
    "label": "Wel Wel",
    "value": "Wel Wel"
  },
  {
    "id": "1109012008",
    "district_id": "110901",
    "label": "Kampung Aie",
    "value": "Kampung Aie"
  },
  {
    "id": "1109012009",
    "district_id": "110901",
    "label": "Lauree",
    "value": "Lauree"
  },
  {
    "id": "1109012010",
    "district_id": "110901",
    "label": "Lamayang",
    "value": "Lamayang"
  },
  {
    "id": "1109012011",
    "district_id": "110901",
    "label": "Lakubang",
    "value": "Lakubang"
  },
  {
    "id": "1109012012",
    "district_id": "110901",
    "label": "Lambaya",
    "value": "Lambaya"
  },
  {
    "id": "1109012013",
    "district_id": "110901",
    "label": "Dihit",
    "value": "Dihit"
  },
  {
    "id": "1109012016",
    "district_id": "110901",
    "label": "Latitik",
    "value": "Latitik"
  },
  {
    "id": "1109012017",
    "district_id": "110901",
    "label": "Wellangkum",
    "value": "Wellangkum"
  },
  {
    "id": "1109012018",
    "district_id": "110901",
    "label": "Kuta Baru",
    "value": "Kuta Baru"
  },
  {
    "id": "1109012019",
    "district_id": "110901",
    "label": "Sebbe",
    "value": "Sebbe"
  },
  {
    "id": "1109012020",
    "district_id": "110901",
    "label": "Suak Baru",
    "value": "Suak Baru"
  },
  {
    "id": "1109012021",
    "district_id": "110901",
    "label": "Luan Sorep",
    "value": "Luan Sorep"
  },
  {
    "id": "1109012022",
    "district_id": "110901",
    "label": "Putra Jaya",
    "value": "Putra Jaya"
  },
  {
    "id": "1109012023",
    "district_id": "110901",
    "label": "Lauke",
    "value": "Lauke"
  },
  {
    "id": "1109012024",
    "district_id": "110901",
    "label": "Situfa Jaya",
    "value": "Situfa Jaya"
  },
  {
    "id": "1109022001",
    "district_id": "110902",
    "label": "Ujung Salang",
    "value": "Ujung Salang"
  },
  {
    "id": "1109022002",
    "district_id": "110902",
    "label": "Padang Unoi",
    "value": "Padang Unoi"
  },
  {
    "id": "1109022003",
    "district_id": "110902",
    "label": "Nasreuhe",
    "value": "Nasreuhe"
  },
  {
    "id": "1109022004",
    "district_id": "110902",
    "label": "Meunafa",
    "value": "Meunafa"
  },
  {
    "id": "1109022005",
    "district_id": "110902",
    "label": "Along",
    "value": "Along"
  },
  {
    "id": "1109022006",
    "district_id": "110902",
    "label": "Panton Lawe",
    "value": "Panton Lawe"
  },
  {
    "id": "1109022007",
    "district_id": "110902",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "1109022008",
    "district_id": "110902",
    "label": "Tamon Jaya",
    "value": "Tamon Jaya"
  },
  {
    "id": "1109022009",
    "district_id": "110902",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1109022010",
    "district_id": "110902",
    "label": "Jaya Baru",
    "value": "Jaya Baru"
  },
  {
    "id": "1109022011",
    "district_id": "110902",
    "label": "Tameng",
    "value": "Tameng"
  },
  {
    "id": "1109022012",
    "district_id": "110902",
    "label": "Lalla Bahagia",
    "value": "Lalla Bahagia"
  },
  {
    "id": "1109022013",
    "district_id": "110902",
    "label": "Suak Manang",
    "value": "Suak Manang"
  },
  {
    "id": "1109022014",
    "district_id": "110902",
    "label": "Ganang Pusako",
    "value": "Ganang Pusako"
  },
  {
    "id": "1109022015",
    "district_id": "110902",
    "label": "Kenangan Jaya",
    "value": "Kenangan Jaya"
  },
  {
    "id": "1109022016",
    "district_id": "110902",
    "label": "Bunga",
    "value": "Bunga"
  },
  {
    "id": "1109032001",
    "district_id": "110903",
    "label": "Silengas",
    "value": "Silengas"
  },
  {
    "id": "1109032002",
    "district_id": "110903",
    "label": "Bunon",
    "value": "Bunon"
  },
  {
    "id": "1109032003",
    "district_id": "110903",
    "label": "Angkeo",
    "value": "Angkeo"
  },
  {
    "id": "1109032004",
    "district_id": "110903",
    "label": "Awe Seubal",
    "value": "Awe Seubal"
  },
  {
    "id": "1109032005",
    "district_id": "110903",
    "label": "Laayon",
    "value": "Laayon"
  },
  {
    "id": "1109032006",
    "district_id": "110903",
    "label": "Inor",
    "value": "Inor"
  },
  {
    "id": "1109032007",
    "district_id": "110903",
    "label": "Salur Lasengalu",
    "value": "Salur Lasengalu"
  },
  {
    "id": "1109032008",
    "district_id": "110903",
    "label": "Salur Latun",
    "value": "Salur Latun"
  },
  {
    "id": "1109032009",
    "district_id": "110903",
    "label": "Salur",
    "value": "Salur"
  },
  {
    "id": "1109032010",
    "district_id": "110903",
    "label": "Pulau Teupah",
    "value": "Pulau Teupah"
  },
  {
    "id": "1109032011",
    "district_id": "110903",
    "label": "Awe Kecil",
    "value": "Awe Kecil"
  },
  {
    "id": "1109032012",
    "district_id": "110903",
    "label": "Naibos",
    "value": "Naibos"
  },
  {
    "id": "1109032013",
    "district_id": "110903",
    "label": "Lantik",
    "value": "Lantik"
  },
  {
    "id": "1109032014",
    "district_id": "110903",
    "label": "Leubang Hulu",
    "value": "Leubang Hulu"
  },
  {
    "id": "1109032015",
    "district_id": "110903",
    "label": "Leubang",
    "value": "Leubang"
  },
  {
    "id": "1109032016",
    "district_id": "110903",
    "label": "Sital",
    "value": "Sital"
  },
  {
    "id": "1109032017",
    "district_id": "110903",
    "label": "Maudil",
    "value": "Maudil"
  },
  {
    "id": "1109032018",
    "district_id": "110903",
    "label": "Nancala",
    "value": "Nancala"
  },
  {
    "id": "1109042009",
    "district_id": "110904",
    "label": "Suak Buluh",
    "value": "Suak Buluh"
  },
  {
    "id": "1109042012",
    "district_id": "110904",
    "label": "Air Dingin",
    "value": "Air Dingin"
  },
  {
    "id": "1109042013",
    "district_id": "110904",
    "label": "Ameria Bahagia",
    "value": "Ameria Bahagia"
  },
  {
    "id": "1109042014",
    "district_id": "110904",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1109042015",
    "district_id": "110904",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1109042016",
    "district_id": "110904",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "1109042017",
    "district_id": "110904",
    "label": "Sinabang",
    "value": "Sinabang"
  },
  {
    "id": "1109042018",
    "district_id": "110904",
    "label": "Amaiteng Mulia",
    "value": "Amaiteng Mulia"
  },
  {
    "id": "1109042019",
    "district_id": "110904",
    "label": "Lugu",
    "value": "Lugu"
  },
  {
    "id": "1109042020",
    "district_id": "110904",
    "label": "Sefoyan",
    "value": "Sefoyan"
  },
  {
    "id": "1109042021",
    "district_id": "110904",
    "label": "Ganting",
    "value": "Ganting"
  },
  {
    "id": "1109042022",
    "district_id": "110904",
    "label": "Kuala Makmur",
    "value": "Kuala Makmur"
  },
  {
    "id": "1109042023",
    "district_id": "110904",
    "label": "Ujung Tinggi",
    "value": "Ujung Tinggi"
  },
  {
    "id": "1109042024",
    "district_id": "110904",
    "label": "Air Pinang",
    "value": "Air Pinang"
  },
  {
    "id": "1109042025",
    "district_id": "110904",
    "label": "Pulau Siumat",
    "value": "Pulau Siumat"
  },
  {
    "id": "1109042026",
    "district_id": "110904",
    "label": "Kota Batu",
    "value": "Kota Batu"
  },
  {
    "id": "1109042028",
    "district_id": "110904",
    "label": "Linggi",
    "value": "Linggi"
  },
  {
    "id": "1109052001",
    "district_id": "110905",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "1109052002",
    "district_id": "110905",
    "label": "Muara Aman",
    "value": "Muara Aman"
  },
  {
    "id": "1109052003",
    "district_id": "110905",
    "label": "Gunung Putih",
    "value": "Gunung Putih"
  },
  {
    "id": "1109052004",
    "district_id": "110905",
    "label": "Lugu Sebahak",
    "value": "Lugu Sebahak"
  },
  {
    "id": "1109052005",
    "district_id": "110905",
    "label": "Kuala Bakti",
    "value": "Kuala Bakti"
  },
  {
    "id": "1109052006",
    "district_id": "110905",
    "label": "Bulu Hadik",
    "value": "Bulu Hadik"
  },
  {
    "id": "1109052007",
    "district_id": "110905",
    "label": "Sambay",
    "value": "Sambay"
  },
  {
    "id": "1109052008",
    "district_id": "110905",
    "label": "Luan Balu",
    "value": "Luan Balu"
  },
  {
    "id": "1109052009",
    "district_id": "110905",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1109052010",
    "district_id": "110905",
    "label": "Kuala Baru",
    "value": "Kuala Baru"
  },
  {
    "id": "1109062001",
    "district_id": "110906",
    "label": "Layabaung",
    "value": "Layabaung"
  },
  {
    "id": "1109062002",
    "district_id": "110906",
    "label": "Sembilan",
    "value": "Sembilan"
  },
  {
    "id": "1109062003",
    "district_id": "110906",
    "label": "Sigulai",
    "value": "Sigulai"
  },
  {
    "id": "1109062004",
    "district_id": "110906",
    "label": "Sinar Bahagia",
    "value": "Sinar Bahagia"
  },
  {
    "id": "1109062005",
    "district_id": "110906",
    "label": "Lamamek",
    "value": "Lamamek"
  },
  {
    "id": "1109062006",
    "district_id": "110906",
    "label": "Batu Ragi",
    "value": "Batu Ragi"
  },
  {
    "id": "1109062007",
    "district_id": "110906",
    "label": "Malasin",
    "value": "Malasin"
  },
  {
    "id": "1109062008",
    "district_id": "110906",
    "label": "Babul Makmur",
    "value": "Babul Makmur"
  },
  {
    "id": "1109062009",
    "district_id": "110906",
    "label": "Amabaan",
    "value": "Amabaan"
  },
  {
    "id": "1109062010",
    "district_id": "110906",
    "label": "Miteum",
    "value": "Miteum"
  },
  {
    "id": "1109062011",
    "district_id": "110906",
    "label": "Lhok Bikhau",
    "value": "Lhok Bikhau"
  },
  {
    "id": "1109062012",
    "district_id": "110906",
    "label": "Ujung Harapan",
    "value": "Ujung Harapan"
  },
  {
    "id": "1109062013",
    "district_id": "110906",
    "label": "Sanggiran",
    "value": "Sanggiran"
  },
  {
    "id": "1109062014",
    "district_id": "110906",
    "label": "Lhok Makmur",
    "value": "Lhok Makmur"
  },
  {
    "id": "1109072001",
    "district_id": "110907",
    "label": "Seneubuk",
    "value": "Seneubuk"
  },
  {
    "id": "1109072002",
    "district_id": "110907",
    "label": "Suak Lamatan",
    "value": "Suak Lamatan"
  },
  {
    "id": "1109072003",
    "district_id": "110907",
    "label": "Alus Alus",
    "value": "Alus Alus"
  },
  {
    "id": "1109072004",
    "district_id": "110907",
    "label": "Batu Ralang",
    "value": "Batu Ralang"
  },
  {
    "id": "1109072005",
    "district_id": "110907",
    "label": "Ulul Mayang",
    "value": "Ulul Mayang"
  },
  {
    "id": "1109072006",
    "district_id": "110907",
    "label": "Kebun Baru",
    "value": "Kebun Baru"
  },
  {
    "id": "1109072007",
    "district_id": "110907",
    "label": "Badegong",
    "value": "Badegong"
  },
  {
    "id": "1109072008",
    "district_id": "110907",
    "label": "Latiung",
    "value": "Latiung"
  },
  {
    "id": "1109072009",
    "district_id": "110907",
    "label": "Pasir Tinggi",
    "value": "Pasir Tinggi"
  },
  {
    "id": "1109072010",
    "district_id": "110907",
    "label": "Labuhan Jaya",
    "value": "Labuhan Jaya"
  },
  {
    "id": "1109072011",
    "district_id": "110907",
    "label": "Labuhan Bajau",
    "value": "Labuhan Bajau"
  },
  {
    "id": "1109072012",
    "district_id": "110907",
    "label": "Blang Sebel",
    "value": "Blang Sebel"
  },
  {
    "id": "1109072013",
    "district_id": "110907",
    "label": "Ana Ao",
    "value": "Ana Ao"
  },
  {
    "id": "1109072014",
    "district_id": "110907",
    "label": "Lataling",
    "value": "Lataling"
  },
  {
    "id": "1109072015",
    "district_id": "110907",
    "label": "Pulau Bangkalak",
    "value": "Pulau Bangkalak"
  },
  {
    "id": "1109072016",
    "district_id": "110907",
    "label": "Labuhan Bakti",
    "value": "Labuhan Bakti"
  },
  {
    "id": "1109072017",
    "district_id": "110907",
    "label": "Trans Jernge",
    "value": "Trans Jernge"
  },
  {
    "id": "1109072018",
    "district_id": "110907",
    "label": "Trans Meranti",
    "value": "Trans Meranti"
  },
  {
    "id": "1109072019",
    "district_id": "110907",
    "label": "Trans Baru",
    "value": "Trans Baru"
  },
  {
    "id": "1109082001",
    "district_id": "110908",
    "label": "Lewak",
    "value": "Lewak"
  },
  {
    "id": "1109082002",
    "district_id": "110908",
    "label": "Lamerem",
    "value": "Lamerem"
  },
  {
    "id": "1109082003",
    "district_id": "110908",
    "label": "Lhok Pauh",
    "value": "Lhok Pauh"
  },
  {
    "id": "1109082004",
    "district_id": "110908",
    "label": "Serafon",
    "value": "Serafon"
  },
  {
    "id": "1109082005",
    "district_id": "110908",
    "label": "Langi",
    "value": "Langi"
  },
  {
    "id": "1109082006",
    "district_id": "110908",
    "label": "Lubuk Baik",
    "value": "Lubuk Baik"
  },
  {
    "id": "1109082007",
    "district_id": "110908",
    "label": "Lhok Dalam",
    "value": "Lhok Dalam"
  },
  {
    "id": "1109082008",
    "district_id": "110908",
    "label": "Lafakha",
    "value": "Lafakha"
  },
  {
    "id": "1109092001",
    "district_id": "110909",
    "label": "Matanurung",
    "value": "Matanurung"
  },
  {
    "id": "1109092002",
    "district_id": "110909",
    "label": "Lasikin",
    "value": "Lasikin"
  },
  {
    "id": "1109092003",
    "district_id": "110909",
    "label": "Lanting",
    "value": "Lanting"
  },
  {
    "id": "1109092004",
    "district_id": "110909",
    "label": "Busung Indah",
    "value": "Busung Indah"
  },
  {
    "id": "1109092005",
    "district_id": "110909",
    "label": "Kahad",
    "value": "Kahad"
  },
  {
    "id": "1109092006",
    "district_id": "110909",
    "label": "Simpang Abail",
    "value": "Simpang Abail"
  },
  {
    "id": "1109092007",
    "district_id": "110909",
    "label": "Abail",
    "value": "Abail"
  },
  {
    "id": "1109092008",
    "district_id": "110909",
    "label": "Nancawa",
    "value": "Nancawa"
  },
  {
    "id": "1109092009",
    "district_id": "110909",
    "label": "Labuah",
    "value": "Labuah"
  },
  {
    "id": "1109092010",
    "district_id": "110909",
    "label": "Sua-Sua",
    "value": "Sua-Sua"
  },
  {
    "id": "1109092011",
    "district_id": "110909",
    "label": "Batu-Batu",
    "value": "Batu-Batu"
  },
  {
    "id": "1109092012",
    "district_id": "110909",
    "label": "Situbuk",
    "value": "Situbuk"
  },
  {
    "id": "1109102001",
    "district_id": "110910",
    "label": "Bubuhan",
    "value": "Bubuhan"
  },
  {
    "id": "1109102002",
    "district_id": "110910",
    "label": "Amarabu",
    "value": "Amarabu"
  },
  {
    "id": "1109102003",
    "district_id": "110910",
    "label": "Kuta Inang",
    "value": "Kuta Inang"
  },
  {
    "id": "1109102004",
    "district_id": "110910",
    "label": "Kuta Padang",
    "value": "Kuta Padang"
  },
  {
    "id": "1109102005",
    "district_id": "110910",
    "label": "Sibuluh",
    "value": "Sibuluh"
  },
  {
    "id": "1109102006",
    "district_id": "110910",
    "label": "Latak Ayah",
    "value": "Latak Ayah"
  },
  {
    "id": "1109102007",
    "district_id": "110910",
    "label": "Borengan",
    "value": "Borengan"
  },
  {
    "id": "1109102008",
    "district_id": "110910",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1110012001",
    "district_id": "111001",
    "label": "Pulau Balai",
    "value": "Pulau Balai"
  },
  {
    "id": "1110012002",
    "district_id": "111001",
    "label": "Pulau Baguk",
    "value": "Pulau Baguk"
  },
  {
    "id": "1110012004",
    "district_id": "111001",
    "label": "Teluk Nibung",
    "value": "Teluk Nibung"
  },
  {
    "id": "1110022001",
    "district_id": "111002",
    "label": "Lipat Kajang",
    "value": "Lipat Kajang"
  },
  {
    "id": "1110022002",
    "district_id": "111002",
    "label": "Kain Golong",
    "value": "Kain Golong"
  },
  {
    "id": "1110022003",
    "district_id": "111002",
    "label": "Kuta Kerangan",
    "value": "Kuta Kerangan"
  },
  {
    "id": "1110022004",
    "district_id": "111002",
    "label": "Siatas",
    "value": "Siatas"
  },
  {
    "id": "1110022005",
    "district_id": "111002",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "1110022006",
    "district_id": "111002",
    "label": "Sukarejo",
    "value": "Sukarejo"
  },
  {
    "id": "1110022007",
    "district_id": "111002",
    "label": "Ujung Limus",
    "value": "Ujung Limus"
  },
  {
    "id": "1110022008",
    "district_id": "111002",
    "label": "Silatong",
    "value": "Silatong"
  },
  {
    "id": "1110022009",
    "district_id": "111002",
    "label": "Pertabas",
    "value": "Pertabas"
  },
  {
    "id": "1110022010",
    "district_id": "111002",
    "label": "Lae Riman",
    "value": "Lae Riman"
  },
  {
    "id": "1110022011",
    "district_id": "111002",
    "label": "Pakiraman",
    "value": "Pakiraman"
  },
  {
    "id": "1110022012",
    "district_id": "111002",
    "label": "Kuta Batu",
    "value": "Kuta Batu"
  },
  {
    "id": "1110022013",
    "district_id": "111002",
    "label": "Lae Nipe",
    "value": "Lae Nipe"
  },
  {
    "id": "1110022014",
    "district_id": "111002",
    "label": "Kuta Tinggi",
    "value": "Kuta Tinggi"
  },
  {
    "id": "1110022015",
    "district_id": "111002",
    "label": "Tuhtuhan",
    "value": "Tuhtuhan"
  },
  {
    "id": "1110022016",
    "district_id": "111002",
    "label": "Lae Gecih",
    "value": "Lae Gecih"
  },
  {
    "id": "1110022017",
    "district_id": "111002",
    "label": "Guha",
    "value": "Guha"
  },
  {
    "id": "1110022018",
    "district_id": "111002",
    "label": "Lae Gambir",
    "value": "Lae Gambir"
  },
  {
    "id": "1110022019",
    "district_id": "111002",
    "label": "Cibubukan",
    "value": "Cibubukan"
  },
  {
    "id": "1110022020",
    "district_id": "111002",
    "label": "Serasah",
    "value": "Serasah"
  },
  {
    "id": "1110022021",
    "district_id": "111002",
    "label": "Tugan",
    "value": "Tugan"
  },
  {
    "id": "1110022022",
    "district_id": "111002",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "1110022023",
    "district_id": "111002",
    "label": "Lipat Kajang Atas",
    "value": "Lipat Kajang Atas"
  },
  {
    "id": "1110022024",
    "district_id": "111002",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1110022025",
    "district_id": "111002",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "1110042001",
    "district_id": "111004",
    "label": "Pasar",
    "value": "Pasar"
  },
  {
    "id": "1110042002",
    "district_id": "111004",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "1110042003",
    "district_id": "111004",
    "label": "Pulo Sarok",
    "value": "Pulo Sarok"
  },
  {
    "id": "1110042004",
    "district_id": "111004",
    "label": "Kilangan",
    "value": "Kilangan"
  },
  {
    "id": "1110042005",
    "district_id": "111004",
    "label": "Kuta Simboling",
    "value": "Kuta Simboling"
  },
  {
    "id": "1110042007",
    "district_id": "111004",
    "label": "Teluk Ambun",
    "value": "Teluk Ambun"
  },
  {
    "id": "1110042008",
    "district_id": "111004",
    "label": "Rantau Gedang",
    "value": "Rantau Gedang"
  },
  {
    "id": "1110042009",
    "district_id": "111004",
    "label": "Teluk Rumbia",
    "value": "Teluk Rumbia"
  },
  {
    "id": "1110042010",
    "district_id": "111004",
    "label": "Pea Bumbung",
    "value": "Pea Bumbung"
  },
  {
    "id": "1110042011",
    "district_id": "111004",
    "label": "Pemuka",
    "value": "Pemuka"
  },
  {
    "id": "1110042012",
    "district_id": "111004",
    "label": "Takal pasir",
    "value": "Takal pasir"
  },
  {
    "id": "1110042014",
    "district_id": "111004",
    "label": "Selok Aceh",
    "value": "Selok Aceh"
  },
  {
    "id": "1110042016",
    "district_id": "111004",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1110042017",
    "district_id": "111004",
    "label": "Ujung Bawang",
    "value": "Ujung Bawang"
  },
  {
    "id": "1110042019",
    "district_id": "111004",
    "label": "Siti Ambia",
    "value": "Siti Ambia"
  },
  {
    "id": "1110042020",
    "district_id": "111004",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1110062001",
    "district_id": "111006",
    "label": "Tanjung Betik",
    "value": "Tanjung Betik"
  },
  {
    "id": "1110062002",
    "district_id": "111006",
    "label": "Penjahitan",
    "value": "Penjahitan"
  },
  {
    "id": "1110062003",
    "district_id": "111006",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "1110062004",
    "district_id": "111006",
    "label": "Cingkam",
    "value": "Cingkam"
  },
  {
    "id": "1110062005",
    "district_id": "111006",
    "label": "Labuhan Kera",
    "value": "Labuhan Kera"
  },
  {
    "id": "1110062006",
    "district_id": "111006",
    "label": "Tanah Bara",
    "value": "Tanah Bara"
  },
  {
    "id": "1110062007",
    "district_id": "111006",
    "label": "Rimo",
    "value": "Rimo"
  },
  {
    "id": "1110062008",
    "district_id": "111006",
    "label": "Blok VI Baru",
    "value": "Blok VI Baru"
  },
  {
    "id": "1110062009",
    "district_id": "111006",
    "label": "Blok 15",
    "value": "Blok 15"
  },
  {
    "id": "1110062010",
    "district_id": "111006",
    "label": "Blok 18",
    "value": "Blok 18"
  },
  {
    "id": "1110062011",
    "district_id": "111006",
    "label": "Blok 31",
    "value": "Blok 31"
  },
  {
    "id": "1110062012",
    "district_id": "111006",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1110062013",
    "district_id": "111006",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1110062014",
    "district_id": "111006",
    "label": "Gunung Lagan",
    "value": "Gunung Lagan"
  },
  {
    "id": "1110062015",
    "district_id": "111006",
    "label": "Perangusan",
    "value": "Perangusan"
  },
  {
    "id": "1110062016",
    "district_id": "111006",
    "label": "Seping Baru",
    "value": "Seping Baru"
  },
  {
    "id": "1110062017",
    "district_id": "111006",
    "label": "Sebatang",
    "value": "Sebatang"
  },
  {
    "id": "1110062018",
    "district_id": "111006",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1110062019",
    "district_id": "111006",
    "label": "Pertampakan",
    "value": "Pertampakan"
  },
  {
    "id": "1110062020",
    "district_id": "111006",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "1110062021",
    "district_id": "111006",
    "label": "Sanggaberu Silulusan",
    "value": "Sanggaberu Silulusan"
  },
  {
    "id": "1110062022",
    "district_id": "111006",
    "label": "Tulaan",
    "value": "Tulaan"
  },
  {
    "id": "1110062023",
    "district_id": "111006",
    "label": "Tunas Harapan",
    "value": "Tunas Harapan"
  },
  {
    "id": "1110062024",
    "district_id": "111006",
    "label": "Lae Butar",
    "value": "Lae Butar"
  },
  {
    "id": "1110062025",
    "district_id": "111006",
    "label": "Sianjo-Anjo Meriah",
    "value": "Sianjo-Anjo Meriah"
  },
  {
    "id": "1110092001",
    "district_id": "111009",
    "label": "Lentong",
    "value": "Lentong"
  },
  {
    "id": "1110092002",
    "district_id": "111009",
    "label": "Lapahan Buaya",
    "value": "Lapahan Buaya"
  },
  {
    "id": "1110092003",
    "district_id": "111009",
    "label": "Butar",
    "value": "Butar"
  },
  {
    "id": "1110092004",
    "district_id": "111009",
    "label": "Samar Dua",
    "value": "Samar Dua"
  },
  {
    "id": "1110092005",
    "district_id": "111009",
    "label": "Ladang Bisik",
    "value": "Ladang Bisik"
  },
  {
    "id": "1110092006",
    "district_id": "111009",
    "label": "Muara Pea",
    "value": "Muara Pea"
  },
  {
    "id": "1110092008",
    "district_id": "111009",
    "label": "Sumber Mukti",
    "value": "Sumber Mukti"
  },
  {
    "id": "1110092009",
    "district_id": "111009",
    "label": "Pea Jambu",
    "value": "Pea Jambu"
  },
  {
    "id": "1110092010",
    "district_id": "111009",
    "label": "Danau Bungara",
    "value": "Danau Bungara"
  },
  {
    "id": "1110102001",
    "district_id": "111010",
    "label": "Gosong Telaga Utara",
    "value": "Gosong Telaga Utara"
  },
  {
    "id": "1110102002",
    "district_id": "111010",
    "label": "Gosong Telaga Selatan",
    "value": "Gosong Telaga Selatan"
  },
  {
    "id": "1110102003",
    "district_id": "111010",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1110102004",
    "district_id": "111010",
    "label": "Ketapang Indah",
    "value": "Ketapang Indah"
  },
  {
    "id": "1110102005",
    "district_id": "111010",
    "label": "Gosong Telaga Timur",
    "value": "Gosong Telaga Timur"
  },
  {
    "id": "1110102006",
    "district_id": "111010",
    "label": "Gosong Telaga Barat",
    "value": "Gosong Telaga Barat"
  },
  {
    "id": "1110102007",
    "district_id": "111010",
    "label": "Telaga Bakti",
    "value": "Telaga Bakti"
  },
  {
    "id": "1110112001",
    "district_id": "111011",
    "label": "Lae Balno",
    "value": "Lae Balno"
  },
  {
    "id": "1110112002",
    "district_id": "111011",
    "label": "Napagaluh",
    "value": "Napagaluh"
  },
  {
    "id": "1110112003",
    "district_id": "111011",
    "label": "Sikoran",
    "value": "Sikoran"
  },
  {
    "id": "1110112004",
    "district_id": "111011",
    "label": "Situbuh-tubuh",
    "value": "Situbuh-tubuh"
  },
  {
    "id": "1110112005",
    "district_id": "111011",
    "label": "Biskang",
    "value": "Biskang"
  },
  {
    "id": "1110112006",
    "district_id": "111011",
    "label": "Sintuban Makmur",
    "value": "Sintuban Makmur"
  },
  {
    "id": "1110122001",
    "district_id": "111012",
    "label": "Suro",
    "value": "Suro"
  },
  {
    "id": "1110122002",
    "district_id": "111012",
    "label": "Pangkalan Sulampi",
    "value": "Pangkalan Sulampi"
  },
  {
    "id": "1110122003",
    "district_id": "111012",
    "label": "Bulu Sema",
    "value": "Bulu Sema"
  },
  {
    "id": "1110122004",
    "district_id": "111012",
    "label": "Lae Bangun",
    "value": "Lae Bangun"
  },
  {
    "id": "1110122005",
    "district_id": "111012",
    "label": "Alur Linci",
    "value": "Alur Linci"
  },
  {
    "id": "1110122006",
    "district_id": "111012",
    "label": "Siompin",
    "value": "Siompin"
  },
  {
    "id": "1110122007",
    "district_id": "111012",
    "label": "Ketangkuhan",
    "value": "Ketangkuhan"
  },
  {
    "id": "1110122008",
    "district_id": "111012",
    "label": "Keras",
    "value": "Keras"
  },
  {
    "id": "1110122009",
    "district_id": "111012",
    "label": "Mandumpang",
    "value": "Mandumpang"
  },
  {
    "id": "1110122010",
    "district_id": "111012",
    "label": "Sirimo Mungkur",
    "value": "Sirimo Mungkur"
  },
  {
    "id": "1110122011",
    "district_id": "111012",
    "label": "Bulu Ara",
    "value": "Bulu Ara"
  },
  {
    "id": "1110132001",
    "district_id": "111013",
    "label": "Singkohor",
    "value": "Singkohor"
  },
  {
    "id": "1110132002",
    "district_id": "111013",
    "label": "Lae Sipola",
    "value": "Lae Sipola"
  },
  {
    "id": "1110132003",
    "district_id": "111013",
    "label": "Lae Pinang",
    "value": "Lae Pinang"
  },
  {
    "id": "1110132004",
    "district_id": "111013",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "1110132006",
    "district_id": "111013",
    "label": "Sri Kayu",
    "value": "Sri Kayu"
  },
  {
    "id": "1110132007",
    "district_id": "111013",
    "label": "Pea Jambu",
    "value": "Pea Jambu"
  },
  {
    "id": "1110142001",
    "district_id": "111014",
    "label": "Kuala Baru Laut",
    "value": "Kuala Baru Laut"
  },
  {
    "id": "1110142002",
    "district_id": "111014",
    "label": "Kuala Baru Sungai",
    "value": "Kuala Baru Sungai"
  },
  {
    "id": "1110142003",
    "district_id": "111014",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1110142004",
    "district_id": "111014",
    "label": "Kayu Menang",
    "value": "Kayu Menang"
  },
  {
    "id": "1110162001",
    "district_id": "111016",
    "label": "Haloban",
    "value": "Haloban"
  },
  {
    "id": "1110162002",
    "district_id": "111016",
    "label": "Asantola",
    "value": "Asantola"
  },
  {
    "id": "1110162003",
    "district_id": "111016",
    "label": "Ujung Sialit",
    "value": "Ujung Sialit"
  },
  {
    "id": "1110162004",
    "district_id": "111016",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1111012001",
    "district_id": "111101",
    "label": "Keude Aceh",
    "value": "Keude Aceh"
  },
  {
    "id": "1111012002",
    "district_id": "111101",
    "label": "Sangso",
    "value": "Sangso"
  },
  {
    "id": "1111012003",
    "district_id": "111101",
    "label": "Pante Rheng",
    "value": "Pante Rheng"
  },
  {
    "id": "1111012004",
    "district_id": "111101",
    "label": "Meuliek",
    "value": "Meuliek"
  },
  {
    "id": "1111012005",
    "district_id": "111101",
    "label": "Namploh Baro",
    "value": "Namploh Baro"
  },
  {
    "id": "1111012006",
    "district_id": "111101",
    "label": "Namploh Manyang",
    "value": "Namploh Manyang"
  },
  {
    "id": "1111012007",
    "district_id": "111101",
    "label": "Namploh Blanggarang",
    "value": "Namploh Blanggarang"
  },
  {
    "id": "1111012008",
    "district_id": "111101",
    "label": "Namploh Krueng",
    "value": "Namploh Krueng"
  },
  {
    "id": "1111012009",
    "district_id": "111101",
    "label": "Namploh Papeun",
    "value": "Namploh Papeun"
  },
  {
    "id": "1111012010",
    "district_id": "111101",
    "label": "Meunasah Lueng",
    "value": "Meunasah Lueng"
  },
  {
    "id": "1111012011",
    "district_id": "111101",
    "label": "Lhok Seumira",
    "value": "Lhok Seumira"
  },
  {
    "id": "1111012012",
    "district_id": "111101",
    "label": "Ulee Jeumatan",
    "value": "Ulee Jeumatan"
  },
  {
    "id": "1111012013",
    "district_id": "111101",
    "label": "Lancok",
    "value": "Lancok"
  },
  {
    "id": "1111012014",
    "district_id": "111101",
    "label": "Paloh",
    "value": "Paloh"
  },
  {
    "id": "1111012015",
    "district_id": "111101",
    "label": "Mesjid Baro",
    "value": "Mesjid Baro"
  },
  {
    "id": "1111012016",
    "district_id": "111101",
    "label": "Batee Iliek",
    "value": "Batee Iliek"
  },
  {
    "id": "1111012017",
    "district_id": "111101",
    "label": "Pulo Baroh",
    "value": "Pulo Baroh"
  },
  {
    "id": "1111012018",
    "district_id": "111101",
    "label": "Matang Wakeuh",
    "value": "Matang Wakeuh"
  },
  {
    "id": "1111012019",
    "district_id": "111101",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1111012020",
    "district_id": "111101",
    "label": "Matang Jereung",
    "value": "Matang Jereung"
  },
  {
    "id": "1111012021",
    "district_id": "111101",
    "label": "Matang",
    "value": "Matang"
  },
  {
    "id": "1111012022",
    "district_id": "111101",
    "label": "Cot Mane",
    "value": "Cot Mane"
  },
  {
    "id": "1111012023",
    "district_id": "111101",
    "label": "Alue Barat",
    "value": "Alue Barat"
  },
  {
    "id": "1111012024",
    "district_id": "111101",
    "label": "Cot Siren",
    "value": "Cot Siren"
  },
  {
    "id": "1111012025",
    "district_id": "111101",
    "label": "Ulee Alue",
    "value": "Ulee Alue"
  },
  {
    "id": "1111012026",
    "district_id": "111101",
    "label": "Pineung Siribee",
    "value": "Pineung Siribee"
  },
  {
    "id": "1111012027",
    "district_id": "111101",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1111012028",
    "district_id": "111101",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1111012029",
    "district_id": "111101",
    "label": "Gampong Putoh",
    "value": "Gampong Putoh"
  },
  {
    "id": "1111012030",
    "district_id": "111101",
    "label": "Mideun Jok",
    "value": "Mideun Jok"
  },
  {
    "id": "1111012031",
    "district_id": "111101",
    "label": "Mideun Geudong",
    "value": "Mideun Geudong"
  },
  {
    "id": "1111012032",
    "district_id": "111101",
    "label": "Lueng Keubeu",
    "value": "Lueng Keubeu"
  },
  {
    "id": "1111012033",
    "district_id": "111101",
    "label": "Glumpang Bungkok",
    "value": "Glumpang Bungkok"
  },
  {
    "id": "1111012034",
    "district_id": "111101",
    "label": "Gampong Meulum",
    "value": "Gampong Meulum"
  },
  {
    "id": "1111012035",
    "district_id": "111101",
    "label": "Cot Meurak Baroh",
    "value": "Cot Meurak Baroh"
  },
  {
    "id": "1111012036",
    "district_id": "111101",
    "label": "Cot Meurak Blang",
    "value": "Cot Meurak Blang"
  },
  {
    "id": "1111012037",
    "district_id": "111101",
    "label": "Meurah",
    "value": "Meurah"
  },
  {
    "id": "1111012038",
    "district_id": "111101",
    "label": "Ulee U",
    "value": "Ulee U"
  },
  {
    "id": "1111012039",
    "district_id": "111101",
    "label": "Matang Teungoh",
    "value": "Matang Teungoh"
  },
  {
    "id": "1111012040",
    "district_id": "111101",
    "label": "Tanjong Baro",
    "value": "Tanjong Baro"
  },
  {
    "id": "1111012041",
    "district_id": "111101",
    "label": "Angkieng Barat",
    "value": "Angkieng Barat"
  },
  {
    "id": "1111012042",
    "district_id": "111101",
    "label": "Meunasah Lincah",
    "value": "Meunasah Lincah"
  },
  {
    "id": "1111012043",
    "district_id": "111101",
    "label": "Meunasah Lancok",
    "value": "Meunasah Lancok"
  },
  {
    "id": "1111012044",
    "district_id": "111101",
    "label": "Meunasah Puuk",
    "value": "Meunasah Puuk"
  },
  {
    "id": "1111012045",
    "district_id": "111101",
    "label": "Tonjongan Idem",
    "value": "Tonjongan Idem"
  },
  {
    "id": "1111012084",
    "district_id": "111101",
    "label": "Geulumpang Payong",
    "value": "Geulumpang Payong"
  },
  {
    "id": "1111022018",
    "district_id": "111102",
    "label": "Blang Me Timu",
    "value": "Blang Me Timu"
  },
  {
    "id": "1111022019",
    "district_id": "111102",
    "label": "Lancang",
    "value": "Lancang"
  },
  {
    "id": "1111022020",
    "district_id": "111102",
    "label": "Blang Lancang",
    "value": "Blang Lancang"
  },
  {
    "id": "1111022021",
    "district_id": "111102",
    "label": "Teupin Kupula",
    "value": "Teupin Kupula"
  },
  {
    "id": "1111022022",
    "district_id": "111102",
    "label": "Tanjong Bungong",
    "value": "Tanjong Bungong"
  },
  {
    "id": "1111022023",
    "district_id": "111102",
    "label": "Dayah Baro",
    "value": "Dayah Baro"
  },
  {
    "id": "1111022024",
    "district_id": "111102",
    "label": "Ulee Rabo",
    "value": "Ulee Rabo"
  },
  {
    "id": "1111022025",
    "district_id": "111102",
    "label": "Lueng Teungoh",
    "value": "Lueng Teungoh"
  },
  {
    "id": "1111022026",
    "district_id": "111102",
    "label": "Lampoh U",
    "value": "Lampoh U"
  },
  {
    "id": "1111022027",
    "district_id": "111102",
    "label": "Pulo Rangkileh",
    "value": "Pulo Rangkileh"
  },
  {
    "id": "1111022028",
    "district_id": "111102",
    "label": "Tufah",
    "value": "Tufah"
  },
  {
    "id": "1111022029",
    "district_id": "111102",
    "label": "Sampo Ajad",
    "value": "Sampo Ajad"
  },
  {
    "id": "1111022030",
    "district_id": "111102",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1111022031",
    "district_id": "111102",
    "label": "Lhok Kulam",
    "value": "Lhok Kulam"
  },
  {
    "id": "1111022032",
    "district_id": "111102",
    "label": "Ulee Blang",
    "value": "Ulee Blang"
  },
  {
    "id": "1111022033",
    "district_id": "111102",
    "label": "Uteun Peupaleh",
    "value": "Uteun Peupaleh"
  },
  {
    "id": "1111022034",
    "district_id": "111102",
    "label": "Ulee Gajah",
    "value": "Ulee Gajah"
  },
  {
    "id": "1111022035",
    "district_id": "111102",
    "label": "Alue Seutui",
    "value": "Alue Seutui"
  },
  {
    "id": "1111022036",
    "district_id": "111102",
    "label": "Blang Neubok",
    "value": "Blang Neubok"
  },
  {
    "id": "1111022037",
    "district_id": "111102",
    "label": "Matang Bangka",
    "value": "Matang Bangka"
  },
  {
    "id": "1111022038",
    "district_id": "111102",
    "label": "Matang Teungoh",
    "value": "Matang Teungoh"
  },
  {
    "id": "1111022039",
    "district_id": "111102",
    "label": "Matang Nibong",
    "value": "Matang Nibong"
  },
  {
    "id": "1111022040",
    "district_id": "111102",
    "label": "Blang Me Barat",
    "value": "Blang Me Barat"
  },
  {
    "id": "1111022041",
    "district_id": "111102",
    "label": "Keude Jeunieb",
    "value": "Keude Jeunieb"
  },
  {
    "id": "1111022042",
    "district_id": "111102",
    "label": "Cot Glumpang Baroh",
    "value": "Cot Glumpang Baroh"
  },
  {
    "id": "1111022043",
    "district_id": "111102",
    "label": "Cot Glumpang Tunong",
    "value": "Cot Glumpang Tunong"
  },
  {
    "id": "1111022044",
    "district_id": "111102",
    "label": "Meunasah Keutapang",
    "value": "Meunasah Keutapang"
  },
  {
    "id": "1111022045",
    "district_id": "111102",
    "label": "Meunasah Dayah",
    "value": "Meunasah Dayah"
  },
  {
    "id": "1111022046",
    "district_id": "111102",
    "label": "Lheu Simpang",
    "value": "Lheu Simpang"
  },
  {
    "id": "1111022047",
    "district_id": "111102",
    "label": "Lheu Barat",
    "value": "Lheu Barat"
  },
  {
    "id": "1111022048",
    "district_id": "111102",
    "label": "Jeumpa Sikureng",
    "value": "Jeumpa Sikureng"
  },
  {
    "id": "1111022049",
    "district_id": "111102",
    "label": "Janggot Seungko",
    "value": "Janggot Seungko"
  },
  {
    "id": "1111022050",
    "district_id": "111102",
    "label": "Meunasah Tambo",
    "value": "Meunasah Tambo"
  },
  {
    "id": "1111022051",
    "district_id": "111102",
    "label": "Meunasah Keupula",
    "value": "Meunasah Keupula"
  },
  {
    "id": "1111022052",
    "district_id": "111102",
    "label": "Meunasah Tunong Lueng",
    "value": "Meunasah Tunong Lueng"
  },
  {
    "id": "1111022053",
    "district_id": "111102",
    "label": "Meunasah Alue",
    "value": "Meunasah Alue"
  },
  {
    "id": "1111022054",
    "district_id": "111102",
    "label": "Blang Pohroh",
    "value": "Blang Pohroh"
  },
  {
    "id": "1111022057",
    "district_id": "111102",
    "label": "Meunasah Lueng",
    "value": "Meunasah Lueng"
  },
  {
    "id": "1111022058",
    "district_id": "111102",
    "label": "Dayah Blang Raleu",
    "value": "Dayah Blang Raleu"
  },
  {
    "id": "1111022059",
    "district_id": "111102",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1111022060",
    "district_id": "111102",
    "label": "Alue Lamsaba",
    "value": "Alue Lamsaba"
  },
  {
    "id": "1111022061",
    "district_id": "111102",
    "label": "Meunasah Kota",
    "value": "Meunasah Kota"
  },
  {
    "id": "1111022062",
    "district_id": "111102",
    "label": "Paya Bili",
    "value": "Paya Bili"
  },
  {
    "id": "1111032001",
    "district_id": "111103",
    "label": "Lawang",
    "value": "Lawang"
  },
  {
    "id": "1111032002",
    "district_id": "111103",
    "label": "Matang Reuleut",
    "value": "Matang Reuleut"
  },
  {
    "id": "1111032003",
    "district_id": "111103",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1111032004",
    "district_id": "111103",
    "label": "Cot Keutapang",
    "value": "Cot Keutapang"
  },
  {
    "id": "1111032005",
    "district_id": "111103",
    "label": "Meunasah Rabo",
    "value": "Meunasah Rabo"
  },
  {
    "id": "1111032006",
    "district_id": "111103",
    "label": "Meunasah Tambo",
    "value": "Meunasah Tambo"
  },
  {
    "id": "1111032007",
    "district_id": "111103",
    "label": "Meunasah Baroh",
    "value": "Meunasah Baroh"
  },
  {
    "id": "1111032008",
    "district_id": "111103",
    "label": "Meunasah Pulo",
    "value": "Meunasah Pulo"
  },
  {
    "id": "1111032009",
    "district_id": "111103",
    "label": "Matang Pasi",
    "value": "Matang Pasi"
  },
  {
    "id": "1111032010",
    "district_id": "111103",
    "label": "Blang Kubu",
    "value": "Blang Kubu"
  },
  {
    "id": "1111032011",
    "district_id": "111103",
    "label": "Garot",
    "value": "Garot"
  },
  {
    "id": "1111032012",
    "district_id": "111103",
    "label": "Ara Bungong",
    "value": "Ara Bungong"
  },
  {
    "id": "1111032013",
    "district_id": "111103",
    "label": "Jabet",
    "value": "Jabet"
  },
  {
    "id": "1111032014",
    "district_id": "111103",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "1111032015",
    "district_id": "111103",
    "label": "Blang Beururu",
    "value": "Blang Beururu"
  },
  {
    "id": "1111032016",
    "district_id": "111103",
    "label": "Hagu",
    "value": "Hagu"
  },
  {
    "id": "1111032017",
    "district_id": "111103",
    "label": "Blang Rangkuluh",
    "value": "Blang Rangkuluh"
  },
  {
    "id": "1111032018",
    "district_id": "111103",
    "label": "Meunasah Bungo",
    "value": "Meunasah Bungo"
  },
  {
    "id": "1111032019",
    "district_id": "111103",
    "label": "Paya Beunot",
    "value": "Paya Beunot"
  },
  {
    "id": "1111032020",
    "district_id": "111103",
    "label": "Meunasah Tunong",
    "value": "Meunasah Tunong"
  },
  {
    "id": "1111032021",
    "district_id": "111103",
    "label": "Meunasah Alue",
    "value": "Meunasah Alue"
  },
  {
    "id": "1111032022",
    "district_id": "111103",
    "label": "Meunasah Cut",
    "value": "Meunasah Cut"
  },
  {
    "id": "1111032023",
    "district_id": "111103",
    "label": "Gampong Mesjid",
    "value": "Gampong Mesjid"
  },
  {
    "id": "1111032024",
    "district_id": "111103",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1111032025",
    "district_id": "111103",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1111032026",
    "district_id": "111103",
    "label": "Kukue",
    "value": "Kukue"
  },
  {
    "id": "1111032027",
    "district_id": "111103",
    "label": "Blang Matang",
    "value": "Blang Matang"
  },
  {
    "id": "1111032028",
    "district_id": "111103",
    "label": "Blang Glumpang",
    "value": "Blang Glumpang"
  },
  {
    "id": "1111032029",
    "district_id": "111103",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1111032030",
    "district_id": "111103",
    "label": "Gampong Paya",
    "value": "Gampong Paya"
  },
  {
    "id": "1111032031",
    "district_id": "111103",
    "label": "Seuneubok Paya",
    "value": "Seuneubok Paya"
  },
  {
    "id": "1111032032",
    "district_id": "111103",
    "label": "Pulo Lawang",
    "value": "Pulo Lawang"
  },
  {
    "id": "1111032033",
    "district_id": "111103",
    "label": "Keude Alue Rheng",
    "value": "Keude Alue Rheng"
  },
  {
    "id": "1111032034",
    "district_id": "111103",
    "label": "Karieng",
    "value": "Karieng"
  },
  {
    "id": "1111032035",
    "district_id": "111103",
    "label": "Pulo Ara",
    "value": "Pulo Ara"
  },
  {
    "id": "1111032036",
    "district_id": "111103",
    "label": "Cot Laot",
    "value": "Cot Laot"
  },
  {
    "id": "1111032037",
    "district_id": "111103",
    "label": "Alue Sijuek",
    "value": "Alue Sijuek"
  },
  {
    "id": "1111032038",
    "district_id": "111103",
    "label": "Blang Bati",
    "value": "Blang Bati"
  },
  {
    "id": "1111032039",
    "district_id": "111103",
    "label": "Buket Paya",
    "value": "Buket Paya"
  },
  {
    "id": "1111032040",
    "district_id": "111103",
    "label": "Dayah Mon Ara",
    "value": "Dayah Mon Ara"
  },
  {
    "id": "1111032041",
    "district_id": "111103",
    "label": "Calok",
    "value": "Calok"
  },
  {
    "id": "1111032042",
    "district_id": "111103",
    "label": "Cot Kruet",
    "value": "Cot Kruet"
  },
  {
    "id": "1111032043",
    "district_id": "111103",
    "label": "Tanjong Seulamat",
    "value": "Tanjong Seulamat"
  },
  {
    "id": "1111032044",
    "district_id": "111103",
    "label": "Jaba",
    "value": "Jaba"
  },
  {
    "id": "1111032045",
    "district_id": "111103",
    "label": "Alue Gandai",
    "value": "Alue Gandai"
  },
  {
    "id": "1111032046",
    "district_id": "111103",
    "label": "Neubok Naleung",
    "value": "Neubok Naleung"
  },
  {
    "id": "1111032047",
    "district_id": "111103",
    "label": "Paya Timu",
    "value": "Paya Timu"
  },
  {
    "id": "1111032048",
    "district_id": "111103",
    "label": "Gampong Mulia",
    "value": "Gampong Mulia"
  },
  {
    "id": "1111032049",
    "district_id": "111103",
    "label": "Pinto Rimba",
    "value": "Pinto Rimba"
  },
  {
    "id": "1111032050",
    "district_id": "111103",
    "label": "Alue Keutapang",
    "value": "Alue Keutapang"
  },
  {
    "id": "1111032051",
    "district_id": "111103",
    "label": "Tgk. Di Bathon",
    "value": "Tgk. Di Bathon"
  },
  {
    "id": "1111032052",
    "district_id": "111103",
    "label": "Paya Barat",
    "value": "Paya Barat"
  },
  {
    "id": "1111042032",
    "district_id": "111104",
    "label": "Gleumpang Payong",
    "value": "Gleumpang Payong"
  },
  {
    "id": "1111042033",
    "district_id": "111104",
    "label": "Blang Seunong",
    "value": "Blang Seunong"
  },
  {
    "id": "1111042034",
    "district_id": "111104",
    "label": "Cot Leusong",
    "value": "Cot Leusong"
  },
  {
    "id": "1111042035",
    "district_id": "111104",
    "label": "Batee Timoh",
    "value": "Batee Timoh"
  },
  {
    "id": "1111042036",
    "district_id": "111104",
    "label": "Lipah Rayeuk",
    "value": "Lipah Rayeuk"
  },
  {
    "id": "1111042037",
    "district_id": "111104",
    "label": "Cot Geureundong",
    "value": "Cot Geureundong"
  },
  {
    "id": "1111042038",
    "district_id": "111104",
    "label": "Lipah Cut",
    "value": "Lipah Cut"
  },
  {
    "id": "1111042039",
    "district_id": "111104",
    "label": "Beurawang",
    "value": "Beurawang"
  },
  {
    "id": "1111042042",
    "district_id": "111104",
    "label": "Geudong Tampu",
    "value": "Geudong Tampu"
  },
  {
    "id": "1111042043",
    "district_id": "111104",
    "label": "Cot Tarom Baroh",
    "value": "Cot Tarom Baroh"
  },
  {
    "id": "1111042044",
    "district_id": "111104",
    "label": "Blang Cot Baroh",
    "value": "Blang Cot Baroh"
  },
  {
    "id": "1111042045",
    "district_id": "111104",
    "label": "Blang Cot Tunong",
    "value": "Blang Cot Tunong"
  },
  {
    "id": "1111042046",
    "district_id": "111104",
    "label": "Seuleumbah",
    "value": "Seuleumbah"
  },
  {
    "id": "1111042047",
    "district_id": "111104",
    "label": "Cot Keutapang",
    "value": "Cot Keutapang"
  },
  {
    "id": "1111042048",
    "district_id": "111104",
    "label": "Cot Tarom Tunong",
    "value": "Cot Tarom Tunong"
  },
  {
    "id": "1111042049",
    "district_id": "111104",
    "label": "Blang Bladeh",
    "value": "Blang Bladeh"
  },
  {
    "id": "1111042050",
    "district_id": "111104",
    "label": "Mon Jambee",
    "value": "Mon Jambee"
  },
  {
    "id": "1111042051",
    "district_id": "111104",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1111042052",
    "district_id": "111104",
    "label": "Kuala Jeumpa",
    "value": "Kuala Jeumpa"
  },
  {
    "id": "1111042053",
    "district_id": "111104",
    "label": "Cot Gadong",
    "value": "Cot Gadong"
  },
  {
    "id": "1111042054",
    "district_id": "111104",
    "label": "Cot Bada",
    "value": "Cot Bada"
  },
  {
    "id": "1111042055",
    "district_id": "111104",
    "label": "Teupok Baroh",
    "value": "Teupok Baroh"
  },
  {
    "id": "1111042056",
    "district_id": "111104",
    "label": "Teupok Tunong",
    "value": "Teupok Tunong"
  },
  {
    "id": "1111042057",
    "district_id": "111104",
    "label": "Pulo Lawang",
    "value": "Pulo Lawang"
  },
  {
    "id": "1111042058",
    "district_id": "111104",
    "label": "Abeuk Usong",
    "value": "Abeuk Usong"
  },
  {
    "id": "1111042059",
    "district_id": "111104",
    "label": "Paloh Seulimeng",
    "value": "Paloh Seulimeng"
  },
  {
    "id": "1111042060",
    "district_id": "111104",
    "label": "Abeuk Tingkeum",
    "value": "Abeuk Tingkeum"
  },
  {
    "id": "1111042061",
    "district_id": "111104",
    "label": "Blang Mee",
    "value": "Blang Mee"
  },
  {
    "id": "1111042062",
    "district_id": "111104",
    "label": "Blang Seupeung",
    "value": "Blang Seupeung"
  },
  {
    "id": "1111042063",
    "district_id": "111104",
    "label": "Blang Gandai",
    "value": "Blang Gandai"
  },
  {
    "id": "1111042064",
    "district_id": "111104",
    "label": "Cot Ulim",
    "value": "Cot Ulim"
  },
  {
    "id": "1111042065",
    "district_id": "111104",
    "label": "Cot Iboih",
    "value": "Cot Iboih"
  },
  {
    "id": "1111042071",
    "district_id": "111104",
    "label": "Blang Rheum",
    "value": "Blang Rheum"
  },
  {
    "id": "1111042072",
    "district_id": "111104",
    "label": "Paloh Panyang",
    "value": "Paloh Panyang"
  },
  {
    "id": "1111042073",
    "district_id": "111104",
    "label": "Seuneubok Lhong",
    "value": "Seuneubok Lhong"
  },
  {
    "id": "1111042075",
    "district_id": "111104",
    "label": "Salah Sirong Jaya",
    "value": "Salah Sirong Jaya"
  },
  {
    "id": "1111042076",
    "district_id": "111104",
    "label": "Mon Mane",
    "value": "Mon Mane"
  },
  {
    "id": "1111042077",
    "district_id": "111104",
    "label": "Alue Limeng",
    "value": "Alue Limeng"
  },
  {
    "id": "1111042078",
    "district_id": "111104",
    "label": "Cot Meugoe",
    "value": "Cot Meugoe"
  },
  {
    "id": "1111042079",
    "district_id": "111104",
    "label": "Cot Iboeh Timu",
    "value": "Cot Iboeh Timu"
  },
  {
    "id": "1111042080",
    "district_id": "111104",
    "label": "Kuta Meuligoe",
    "value": "Kuta Meuligoe"
  },
  {
    "id": "1111042081",
    "district_id": "111104",
    "label": "Laksamana",
    "value": "Laksamana"
  },
  {
    "id": "1111052003",
    "district_id": "111105",
    "label": "Meunasah Timu",
    "value": "Meunasah Timu"
  },
  {
    "id": "1111052004",
    "district_id": "111105",
    "label": "Keude Matang Glumpang Dua",
    "value": "Keude Matang Glumpang Dua"
  },
  {
    "id": "1111052005",
    "district_id": "111105",
    "label": "Matang Glumpang Dua Meunasah Dayah",
    "value": "Matang Glumpang Dua Meunasah Dayah"
  },
  {
    "id": "1111052006",
    "district_id": "111105",
    "label": "Pante Gajah",
    "value": "Pante Gajah"
  },
  {
    "id": "1111052007",
    "district_id": "111105",
    "label": "Blang Asan",
    "value": "Blang Asan"
  },
  {
    "id": "1111052008",
    "district_id": "111105",
    "label": "Paya Cut",
    "value": "Paya Cut"
  },
  {
    "id": "1111052009",
    "district_id": "111105",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1111052010",
    "district_id": "111105",
    "label": "Panton Geulima",
    "value": "Panton Geulima"
  },
  {
    "id": "1111052011",
    "district_id": "111105",
    "label": "Neuheun",
    "value": "Neuheun"
  },
  {
    "id": "1111052012",
    "district_id": "111105",
    "label": "Matang Sagoe",
    "value": "Matang Sagoe"
  },
  {
    "id": "1111052013",
    "district_id": "111105",
    "label": "Matang Mesjid",
    "value": "Matang Mesjid"
  },
  {
    "id": "1111052014",
    "district_id": "111105",
    "label": "Matang Cot Paseh",
    "value": "Matang Cot Paseh"
  },
  {
    "id": "1111052015",
    "district_id": "111105",
    "label": "Cot Panjoe",
    "value": "Cot Panjoe"
  },
  {
    "id": "1111052016",
    "district_id": "111105",
    "label": "Paya Lipah",
    "value": "Paya Lipah"
  },
  {
    "id": "1111052017",
    "district_id": "111105",
    "label": "Paya Meuneng",
    "value": "Paya Meuneng"
  },
  {
    "id": "1111052018",
    "district_id": "111105",
    "label": "Keude Tanjong",
    "value": "Keude Tanjong"
  },
  {
    "id": "1111052019",
    "district_id": "111105",
    "label": "Tanjong Paya",
    "value": "Tanjong Paya"
  },
  {
    "id": "1111052020",
    "district_id": "111105",
    "label": "Tanjong Nie",
    "value": "Tanjong Nie"
  },
  {
    "id": "1111052021",
    "district_id": "111105",
    "label": "Tanjong Mesjid",
    "value": "Tanjong Mesjid"
  },
  {
    "id": "1111052022",
    "district_id": "111105",
    "label": "Pante Cut",
    "value": "Pante Cut"
  },
  {
    "id": "1111052023",
    "district_id": "111105",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1111052024",
    "district_id": "111105",
    "label": "Pulo Pisang",
    "value": "Pulo Pisang"
  },
  {
    "id": "1111052025",
    "district_id": "111105",
    "label": "Pante Piyeu",
    "value": "Pante Piyeu"
  },
  {
    "id": "1111052026",
    "district_id": "111105",
    "label": "Meunasah Nibong",
    "value": "Meunasah Nibong"
  },
  {
    "id": "1111052027",
    "district_id": "111105",
    "label": "Krueng Baro Babah Krueng",
    "value": "Krueng Baro Babah Krueng"
  },
  {
    "id": "1111052028",
    "district_id": "111105",
    "label": "Krueng Baro Mesjid",
    "value": "Krueng Baro Mesjid"
  },
  {
    "id": "1111052029",
    "district_id": "111105",
    "label": "Bayu",
    "value": "Bayu"
  },
  {
    "id": "1111052030",
    "district_id": "111105",
    "label": "Gampong Putoh",
    "value": "Gampong Putoh"
  },
  {
    "id": "1111052031",
    "district_id": "111105",
    "label": "Meunasah Meucap",
    "value": "Meunasah Meucap"
  },
  {
    "id": "1111052032",
    "district_id": "111105",
    "label": "Pante Ara",
    "value": "Pante Ara"
  },
  {
    "id": "1111052033",
    "district_id": "111105",
    "label": "Gampong Raya Dagang",
    "value": "Gampong Raya Dagang"
  },
  {
    "id": "1111052034",
    "district_id": "111105",
    "label": "Gampong Raya Tambo",
    "value": "Gampong Raya Tambo"
  },
  {
    "id": "1111052035",
    "district_id": "111105",
    "label": "Kapa",
    "value": "Kapa"
  },
  {
    "id": "1111052036",
    "district_id": "111105",
    "label": "Blang Panjoe",
    "value": "Blang Panjoe"
  },
  {
    "id": "1111052037",
    "district_id": "111105",
    "label": "Pantee Lhong",
    "value": "Pantee Lhong"
  },
  {
    "id": "1111052038",
    "district_id": "111105",
    "label": "Asan Bideun",
    "value": "Asan Bideun"
  },
  {
    "id": "1111052040",
    "district_id": "111105",
    "label": "Cot Rabo Baroh",
    "value": "Cot Rabo Baroh"
  },
  {
    "id": "1111052041",
    "district_id": "111105",
    "label": "Cot Rabo Tunong",
    "value": "Cot Rabo Tunong"
  },
  {
    "id": "1111052042",
    "district_id": "111105",
    "label": "Cot Nga",
    "value": "Cot Nga"
  },
  {
    "id": "1111052043",
    "district_id": "111105",
    "label": "Pulo Naleung",
    "value": "Pulo Naleung"
  },
  {
    "id": "1111052044",
    "district_id": "111105",
    "label": "Mata Mamplam",
    "value": "Mata Mamplam"
  },
  {
    "id": "1111052045",
    "district_id": "111105",
    "label": "Alue Glumpang",
    "value": "Alue Glumpang"
  },
  {
    "id": "1111052046",
    "district_id": "111105",
    "label": "Karieng",
    "value": "Karieng"
  },
  {
    "id": "1111052047",
    "district_id": "111105",
    "label": "Krueng Deue",
    "value": "Krueng Deue"
  },
  {
    "id": "1111052048",
    "district_id": "111105",
    "label": "Cot Ijue",
    "value": "Cot Ijue"
  },
  {
    "id": "1111052049",
    "district_id": "111105",
    "label": "Cot Buket",
    "value": "Cot Buket"
  },
  {
    "id": "1111052050",
    "district_id": "111105",
    "label": "Nicah",
    "value": "Nicah"
  },
  {
    "id": "1111052051",
    "district_id": "111105",
    "label": "Cot Keuranji",
    "value": "Cot Keuranji"
  },
  {
    "id": "1111052052",
    "district_id": "111105",
    "label": "Paloh",
    "value": "Paloh"
  },
  {
    "id": "1111052054",
    "district_id": "111105",
    "label": "Cot Bada Tunong",
    "value": "Cot Bada Tunong"
  },
  {
    "id": "1111052055",
    "district_id": "111105",
    "label": "Cot Bada Baroh",
    "value": "Cot Bada Baroh"
  },
  {
    "id": "1111052056",
    "district_id": "111105",
    "label": "Cot Girek",
    "value": "Cot Girek"
  },
  {
    "id": "1111052057",
    "district_id": "111105",
    "label": "Cot Keumude",
    "value": "Cot Keumude"
  },
  {
    "id": "1111052058",
    "district_id": "111105",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1111052059",
    "district_id": "111105",
    "label": "Pulo Ue Baroe",
    "value": "Pulo Ue Baroe"
  },
  {
    "id": "1111052060",
    "district_id": "111105",
    "label": "Cot Bada Barat",
    "value": "Cot Bada Barat"
  },
  {
    "id": "1111052061",
    "district_id": "111105",
    "label": "Tanoh Mirah",
    "value": "Tanoh Mirah"
  },
  {
    "id": "1111052062",
    "district_id": "111105",
    "label": "Seuneubok Rawa",
    "value": "Seuneubok Rawa"
  },
  {
    "id": "1111052063",
    "district_id": "111105",
    "label": "Paya Aboe",
    "value": "Paya Aboe"
  },
  {
    "id": "1111052064",
    "district_id": "111105",
    "label": "Blang Rambong",
    "value": "Blang Rambong"
  },
  {
    "id": "1111052065",
    "district_id": "111105",
    "label": "Blang Geulanggang",
    "value": "Blang Geulanggang"
  },
  {
    "id": "1111052066",
    "district_id": "111105",
    "label": "Paya Reuhat",
    "value": "Paya Reuhat"
  },
  {
    "id": "1111052067",
    "district_id": "111105",
    "label": "Uteun Bunta",
    "value": "Uteun Bunta"
  },
  {
    "id": "1111052068",
    "district_id": "111105",
    "label": "Alue Udeng",
    "value": "Alue Udeng"
  },
  {
    "id": "1111052069",
    "district_id": "111105",
    "label": "Alue Peuno",
    "value": "Alue Peuno"
  },
  {
    "id": "1111052105",
    "district_id": "111105",
    "label": "Cot Puuk",
    "value": "Cot Puuk"
  },
  {
    "id": "1111052106",
    "district_id": "111105",
    "label": "Pante Pisang",
    "value": "Pante Pisang"
  },
  {
    "id": "1111052107",
    "district_id": "111105",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1111052108",
    "district_id": "111105",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1111062001",
    "district_id": "111106",
    "label": "Leubu Me",
    "value": "Leubu Me"
  },
  {
    "id": "1111062002",
    "district_id": "111106",
    "label": "Paya Dua",
    "value": "Paya Dua"
  },
  {
    "id": "1111062003",
    "district_id": "111106",
    "label": "Leubu Cot",
    "value": "Leubu Cot"
  },
  {
    "id": "1111062004",
    "district_id": "111106",
    "label": "Leubu Mesjid",
    "value": "Leubu Mesjid"
  },
  {
    "id": "1111062005",
    "district_id": "111106",
    "label": "Kuta Barat",
    "value": "Kuta Barat"
  },
  {
    "id": "1111062006",
    "district_id": "111106",
    "label": "Trienggadeng",
    "value": "Trienggadeng"
  },
  {
    "id": "1111062007",
    "district_id": "111106",
    "label": "Cot Kruet",
    "value": "Cot Kruet"
  },
  {
    "id": "1111062008",
    "district_id": "111106",
    "label": "Blang Khutang",
    "value": "Blang Khutang"
  },
  {
    "id": "1111062009",
    "district_id": "111106",
    "label": "Buket Seulamat",
    "value": "Buket Seulamat"
  },
  {
    "id": "1111062010",
    "district_id": "111106",
    "label": "Lapehan Mesjid",
    "value": "Lapehan Mesjid"
  },
  {
    "id": "1111062011",
    "district_id": "111106",
    "label": "Ulee Glee",
    "value": "Ulee Glee"
  },
  {
    "id": "1111062012",
    "district_id": "111106",
    "label": "Mona Ara",
    "value": "Mona Ara"
  },
  {
    "id": "1111062013",
    "district_id": "111106",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "1111062014",
    "district_id": "111106",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1111062015",
    "district_id": "111106",
    "label": "Alue Dua",
    "value": "Alue Dua"
  },
  {
    "id": "1111062016",
    "district_id": "111106",
    "label": "Seuneubok Baro",
    "value": "Seuneubok Baro"
  },
  {
    "id": "1111062017",
    "district_id": "111106",
    "label": "Panteu Breuh",
    "value": "Panteu Breuh"
  },
  {
    "id": "1111062018",
    "district_id": "111106",
    "label": "Tanjong Mulia",
    "value": "Tanjong Mulia"
  },
  {
    "id": "1111062019",
    "district_id": "111106",
    "label": "Blang Mane",
    "value": "Blang Mane"
  },
  {
    "id": "1111062020",
    "district_id": "111106",
    "label": "Meureubo",
    "value": "Meureubo"
  },
  {
    "id": "1111062021",
    "district_id": "111106",
    "label": "Blang Perlak",
    "value": "Blang Perlak"
  },
  {
    "id": "1111062022",
    "district_id": "111106",
    "label": "Panton Mesjid",
    "value": "Panton Mesjid"
  },
  {
    "id": "1111062023",
    "district_id": "111106",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1111062024",
    "district_id": "111106",
    "label": "Matang Kumbang",
    "value": "Matang Kumbang"
  },
  {
    "id": "1111062025",
    "district_id": "111106",
    "label": "Pulo Teungoh",
    "value": "Pulo Teungoh"
  },
  {
    "id": "1111062026",
    "district_id": "111106",
    "label": "Batee Dabai",
    "value": "Batee Dabai"
  },
  {
    "id": "1111062027",
    "district_id": "111106",
    "label": "Ara Lipeh",
    "value": "Ara Lipeh"
  },
  {
    "id": "1111072001",
    "district_id": "111107",
    "label": "Keude Lapang",
    "value": "Keude Lapang"
  },
  {
    "id": "1111072002",
    "district_id": "111107",
    "label": "Lapang Barat",
    "value": "Lapang Barat"
  },
  {
    "id": "1111072003",
    "district_id": "111107",
    "label": "Lingka Kuta",
    "value": "Lingka Kuta"
  },
  {
    "id": "1111072004",
    "district_id": "111107",
    "label": "Lapang Timu",
    "value": "Lapang Timu"
  },
  {
    "id": "1111072005",
    "district_id": "111107",
    "label": "Teupin Siron",
    "value": "Teupin Siron"
  },
  {
    "id": "1111072006",
    "district_id": "111107",
    "label": "Ie Rhop",
    "value": "Ie Rhop"
  },
  {
    "id": "1111072007",
    "district_id": "111107",
    "label": "Ceubo",
    "value": "Ceubo"
  },
  {
    "id": "1111072008",
    "district_id": "111107",
    "label": "Paloh Me",
    "value": "Paloh Me"
  },
  {
    "id": "1111072009",
    "district_id": "111107",
    "label": "Lhok Mambang",
    "value": "Lhok Mambang"
  },
  {
    "id": "1111072010",
    "district_id": "111107",
    "label": "Samuti Krueng",
    "value": "Samuti Krueng"
  },
  {
    "id": "1111072011",
    "district_id": "111107",
    "label": "Cot Mane",
    "value": "Cot Mane"
  },
  {
    "id": "1111072012",
    "district_id": "111107",
    "label": "Samuti Makmur",
    "value": "Samuti Makmur"
  },
  {
    "id": "1111072013",
    "district_id": "111107",
    "label": "Samuti Rayeuk",
    "value": "Samuti Rayeuk"
  },
  {
    "id": "1111072014",
    "district_id": "111107",
    "label": "Cot Puuk",
    "value": "Cot Puuk"
  },
  {
    "id": "1111072015",
    "district_id": "111107",
    "label": "Samuti Aman",
    "value": "Samuti Aman"
  },
  {
    "id": "1111072016",
    "district_id": "111107",
    "label": "Alue Mangki",
    "value": "Alue Mangki"
  },
  {
    "id": "1111072017",
    "district_id": "111107",
    "label": "Mon Keulayu",
    "value": "Mon Keulayu"
  },
  {
    "id": "1111072018",
    "district_id": "111107",
    "label": "Geurugok",
    "value": "Geurugok"
  },
  {
    "id": "1111072019",
    "district_id": "111107",
    "label": "Blang Keude",
    "value": "Blang Keude"
  },
  {
    "id": "1111072020",
    "district_id": "111107",
    "label": "Cot Tufah",
    "value": "Cot Tufah"
  },
  {
    "id": "1111072021",
    "district_id": "111107",
    "label": "Cot Tunong",
    "value": "Cot Tunong"
  },
  {
    "id": "1111072022",
    "district_id": "111107",
    "label": "Paya Baro",
    "value": "Paya Baro"
  },
  {
    "id": "1111072023",
    "district_id": "111107",
    "label": "Paloh Kaye Kunyet",
    "value": "Paloh Kaye Kunyet"
  },
  {
    "id": "1111072024",
    "district_id": "111107",
    "label": "Cot Jabet",
    "value": "Cot Jabet"
  },
  {
    "id": "1111072025",
    "district_id": "111107",
    "label": "Cot Tube",
    "value": "Cot Tube"
  },
  {
    "id": "1111072026",
    "district_id": "111107",
    "label": "Pante Sikumbong",
    "value": "Pante Sikumbong"
  },
  {
    "id": "1111072027",
    "district_id": "111107",
    "label": "Blang Guron",
    "value": "Blang Guron"
  },
  {
    "id": "1111072028",
    "district_id": "111107",
    "label": "Dama Kawan",
    "value": "Dama Kawan"
  },
  {
    "id": "1111072029",
    "district_id": "111107",
    "label": "Paya Seupat",
    "value": "Paya Seupat"
  },
  {
    "id": "1111072030",
    "district_id": "111107",
    "label": "Cot Rambat",
    "value": "Cot Rambat"
  },
  {
    "id": "1111072031",
    "district_id": "111107",
    "label": "Paya Kareung",
    "value": "Paya Kareung"
  },
  {
    "id": "1111072032",
    "district_id": "111107",
    "label": "Mon Jeurujak",
    "value": "Mon Jeurujak"
  },
  {
    "id": "1111072033",
    "district_id": "111107",
    "label": "Tanjong Raya",
    "value": "Tanjong Raya"
  },
  {
    "id": "1111072034",
    "district_id": "111107",
    "label": "Tanjong Mesjid",
    "value": "Tanjong Mesjid"
  },
  {
    "id": "1111072035",
    "district_id": "111107",
    "label": "Blang Kubu",
    "value": "Blang Kubu"
  },
  {
    "id": "1111072036",
    "district_id": "111107",
    "label": "Tanjong Bungong",
    "value": "Tanjong Bungong"
  },
  {
    "id": "1111072037",
    "district_id": "111107",
    "label": "Pulo Gisa",
    "value": "Pulo Gisa"
  },
  {
    "id": "1111072038",
    "district_id": "111107",
    "label": "Ujong Bayu",
    "value": "Ujong Bayu"
  },
  {
    "id": "1111072078",
    "district_id": "111107",
    "label": "Mon Jambe",
    "value": "Mon Jambe"
  },
  {
    "id": "1111072079",
    "district_id": "111107",
    "label": "Blang Rheue",
    "value": "Blang Rheue"
  },
  {
    "id": "1111082001",
    "district_id": "111108",
    "label": "Lancok Ulim",
    "value": "Lancok Ulim"
  },
  {
    "id": "1111082002",
    "district_id": "111108",
    "label": "Nase Mee",
    "value": "Nase Mee"
  },
  {
    "id": "1111082003",
    "district_id": "111108",
    "label": "Pandrah Kandeh",
    "value": "Pandrah Kandeh"
  },
  {
    "id": "1111082004",
    "district_id": "111108",
    "label": "Panton",
    "value": "Panton"
  },
  {
    "id": "1111082005",
    "district_id": "111108",
    "label": "Lhok Dagang",
    "value": "Lhok Dagang"
  },
  {
    "id": "1111082006",
    "district_id": "111108",
    "label": "Pandrah Janeng",
    "value": "Pandrah Janeng"
  },
  {
    "id": "1111082007",
    "district_id": "111108",
    "label": "Garot",
    "value": "Garot"
  },
  {
    "id": "1111082008",
    "district_id": "111108",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1111082009",
    "district_id": "111108",
    "label": "Kuta Rusep",
    "value": "Kuta Rusep"
  },
  {
    "id": "1111082010",
    "district_id": "111108",
    "label": "Blang Sama Gadeng",
    "value": "Blang Sama Gadeng"
  },
  {
    "id": "1111082011",
    "district_id": "111108",
    "label": "Panton Bili",
    "value": "Panton Bili"
  },
  {
    "id": "1111082012",
    "district_id": "111108",
    "label": "Alue Igeuh",
    "value": "Alue Igeuh"
  },
  {
    "id": "1111082013",
    "district_id": "111108",
    "label": "Meunasah Reudeup",
    "value": "Meunasah Reudeup"
  },
  {
    "id": "1111082014",
    "district_id": "111108",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1111082015",
    "district_id": "111108",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "1111082016",
    "district_id": "111108",
    "label": "Cot Leubeng",
    "value": "Cot Leubeng"
  },
  {
    "id": "1111082017",
    "district_id": "111108",
    "label": "Seunebok Baro",
    "value": "Seunebok Baro"
  },
  {
    "id": "1111082018",
    "district_id": "111108",
    "label": "Uteuen Kruet",
    "value": "Uteuen Kruet"
  },
  {
    "id": "1111082019",
    "district_id": "111108",
    "label": "Nase Barat",
    "value": "Nase Barat"
  },
  {
    "id": "1111092001",
    "district_id": "111109",
    "label": "Juli Cot Mesjid",
    "value": "Juli Cot Mesjid"
  },
  {
    "id": "1111092002",
    "district_id": "111109",
    "label": "Juli Seutuy",
    "value": "Juli Seutuy"
  },
  {
    "id": "1111092003",
    "district_id": "111109",
    "label": "Juli Tambo Tanjong",
    "value": "Juli Tambo Tanjong"
  },
  {
    "id": "1111092004",
    "district_id": "111109",
    "label": "Juli Keude dua",
    "value": "Juli Keude dua"
  },
  {
    "id": "1111092005",
    "district_id": "111109",
    "label": "Juli Tgk Dilampoh",
    "value": "Juli Tgk Dilampoh"
  },
  {
    "id": "1111092006",
    "district_id": "111109",
    "label": "Glumpang Meu Jim Jim",
    "value": "Glumpang Meu Jim Jim"
  },
  {
    "id": "1111092007",
    "district_id": "111109",
    "label": "Alue Unoe",
    "value": "Alue Unoe"
  },
  {
    "id": "1111092008",
    "district_id": "111109",
    "label": "Batee Raya",
    "value": "Batee Raya"
  },
  {
    "id": "1111092009",
    "district_id": "111109",
    "label": "Seuneubok Peureraden",
    "value": "Seuneubok Peureraden"
  },
  {
    "id": "1111092010",
    "district_id": "111109",
    "label": "Juli Mee Teungoh",
    "value": "Juli Mee Teungoh"
  },
  {
    "id": "1111092011",
    "district_id": "111109",
    "label": "Seuneubok Gunci",
    "value": "Seuneubok Gunci"
  },
  {
    "id": "1111092012",
    "district_id": "111109",
    "label": "Mane Meujingki",
    "value": "Mane Meujingki"
  },
  {
    "id": "1111092013",
    "district_id": "111109",
    "label": "Abeuk Budi",
    "value": "Abeuk Budi"
  },
  {
    "id": "1111092014",
    "district_id": "111109",
    "label": "Blang Ketumba",
    "value": "Blang Ketumba"
  },
  {
    "id": "1111092015",
    "district_id": "111109",
    "label": "Pante Baro",
    "value": "Pante Baro"
  },
  {
    "id": "1111092016",
    "district_id": "111109",
    "label": "Beunyot",
    "value": "Beunyot"
  },
  {
    "id": "1111092017",
    "district_id": "111109",
    "label": "Paya Cut",
    "value": "Paya Cut"
  },
  {
    "id": "1111092018",
    "district_id": "111109",
    "label": "Teupin Mane",
    "value": "Teupin Mane"
  },
  {
    "id": "1111092019",
    "district_id": "111109",
    "label": "Simpang Jaya",
    "value": "Simpang Jaya"
  },
  {
    "id": "1111092020",
    "district_id": "111109",
    "label": "Krueng Simpo",
    "value": "Krueng Simpo"
  },
  {
    "id": "1111092021",
    "district_id": "111109",
    "label": "Suka Tani",
    "value": "Suka Tani"
  },
  {
    "id": "1111092022",
    "district_id": "111109",
    "label": "Juli Seupeng",
    "value": "Juli Seupeng"
  },
  {
    "id": "1111092023",
    "district_id": "111109",
    "label": "Bukit Mulia",
    "value": "Bukit Mulia"
  },
  {
    "id": "1111092024",
    "district_id": "111109",
    "label": "Juli Meunasah Tambo",
    "value": "Juli Meunasah Tambo"
  },
  {
    "id": "1111092025",
    "district_id": "111109",
    "label": "Alue Rambong",
    "value": "Alue Rambong"
  },
  {
    "id": "1111092026",
    "district_id": "111109",
    "label": "Juli Meunasah Teungoh",
    "value": "Juli Meunasah Teungoh"
  },
  {
    "id": "1111092027",
    "district_id": "111109",
    "label": "Juli Payaru",
    "value": "Juli Payaru"
  },
  {
    "id": "1111092028",
    "district_id": "111109",
    "label": "Paseh",
    "value": "Paseh"
  },
  {
    "id": "1111092029",
    "district_id": "111109",
    "label": "Juli Cot Meurak",
    "value": "Juli Cot Meurak"
  },
  {
    "id": "1111092030",
    "district_id": "111109",
    "label": "Pante Peusangan",
    "value": "Pante Peusangan"
  },
  {
    "id": "1111092031",
    "district_id": "111109",
    "label": "Bale Panah",
    "value": "Bale Panah"
  },
  {
    "id": "1111092032",
    "district_id": "111109",
    "label": "Ranto Panyang",
    "value": "Ranto Panyang"
  },
  {
    "id": "1111092033",
    "district_id": "111109",
    "label": "Simpang Mulia",
    "value": "Simpang Mulia"
  },
  {
    "id": "1111092034",
    "district_id": "111109",
    "label": "Seuneubok Dalam",
    "value": "Seuneubok Dalam"
  },
  {
    "id": "1111092035",
    "district_id": "111109",
    "label": "Juli Meunasah Jok",
    "value": "Juli Meunasah Jok"
  },
  {
    "id": "1111092036",
    "district_id": "111109",
    "label": "Juli Uruek Anoe",
    "value": "Juli Uruek Anoe"
  },
  {
    "id": "1111102001",
    "district_id": "111110",
    "label": "Jangka Mesjid",
    "value": "Jangka Mesjid"
  },
  {
    "id": "1111102002",
    "district_id": "111110",
    "label": "Jangka Keutapang",
    "value": "Jangka Keutapang"
  },
  {
    "id": "1111102003",
    "district_id": "111110",
    "label": "Jangka Alue U",
    "value": "Jangka Alue U"
  },
  {
    "id": "1111102004",
    "district_id": "111110",
    "label": "Jangka Alue",
    "value": "Jangka Alue"
  },
  {
    "id": "1111102005",
    "district_id": "111110",
    "label": "Jangka Alue Bie",
    "value": "Jangka Alue Bie"
  },
  {
    "id": "1111102006",
    "district_id": "111110",
    "label": "Gampong Meulinteung",
    "value": "Gampong Meulinteung"
  },
  {
    "id": "1111102007",
    "district_id": "111110",
    "label": "Paya Bieng",
    "value": "Paya Bieng"
  },
  {
    "id": "1111102008",
    "district_id": "111110",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1111102009",
    "district_id": "111110",
    "label": "Barat Lanyan",
    "value": "Barat Lanyan"
  },
  {
    "id": "1111102010",
    "district_id": "111110",
    "label": "Lamkuta",
    "value": "Lamkuta"
  },
  {
    "id": "1111102011",
    "district_id": "111110",
    "label": "Geundot",
    "value": "Geundot"
  },
  {
    "id": "1111102012",
    "district_id": "111110",
    "label": "Lueng",
    "value": "Lueng"
  },
  {
    "id": "1111102013",
    "district_id": "111110",
    "label": "Kambuek",
    "value": "Kambuek"
  },
  {
    "id": "1111102014",
    "district_id": "111110",
    "label": "Ruseb Ara",
    "value": "Ruseb Ara"
  },
  {
    "id": "1111102015",
    "district_id": "111110",
    "label": "Ruseb Dayah",
    "value": "Ruseb Dayah"
  },
  {
    "id": "1111102016",
    "district_id": "111110",
    "label": "Bada Barat",
    "value": "Bada Barat"
  },
  {
    "id": "1111102017",
    "district_id": "111110",
    "label": "Bada Timu",
    "value": "Bada Timu"
  },
  {
    "id": "1111102018",
    "district_id": "111110",
    "label": "Tanjongan",
    "value": "Tanjongan"
  },
  {
    "id": "1111102019",
    "district_id": "111110",
    "label": "Tanah Anoe",
    "value": "Tanah Anoe"
  },
  {
    "id": "1111102020",
    "district_id": "111110",
    "label": "Lhok Bugeng",
    "value": "Lhok Bugeng"
  },
  {
    "id": "1111102021",
    "district_id": "111110",
    "label": "Lampoh Rayeuk",
    "value": "Lampoh Rayeuk"
  },
  {
    "id": "1111102022",
    "district_id": "111110",
    "label": "Pulo Reudeup",
    "value": "Pulo Reudeup"
  },
  {
    "id": "1111102023",
    "district_id": "111110",
    "label": "Pulo Seuna",
    "value": "Pulo Seuna"
  },
  {
    "id": "1111102024",
    "district_id": "111110",
    "label": "Linggong",
    "value": "Linggong"
  },
  {
    "id": "1111102025",
    "district_id": "111110",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1111102026",
    "district_id": "111110",
    "label": "Pulo Iboih",
    "value": "Pulo Iboih"
  },
  {
    "id": "1111102027",
    "district_id": "111110",
    "label": "Abeuk Jaloh",
    "value": "Abeuk Jaloh"
  },
  {
    "id": "1111102028",
    "district_id": "111110",
    "label": "Pulo U",
    "value": "Pulo U"
  },
  {
    "id": "1111102029",
    "district_id": "111110",
    "label": "Alue Buya",
    "value": "Alue Buya"
  },
  {
    "id": "1111102030",
    "district_id": "111110",
    "label": "Alue Buya Pasi",
    "value": "Alue Buya Pasi"
  },
  {
    "id": "1111102031",
    "district_id": "111110",
    "label": "Pante Sukon",
    "value": "Pante Sukon"
  },
  {
    "id": "1111102032",
    "district_id": "111110",
    "label": "Pante Paku",
    "value": "Pante Paku"
  },
  {
    "id": "1111102033",
    "district_id": "111110",
    "label": "Bugak Blang",
    "value": "Bugak Blang"
  },
  {
    "id": "1111102034",
    "district_id": "111110",
    "label": "Bugak Mesjid",
    "value": "Bugak Mesjid"
  },
  {
    "id": "1111102035",
    "district_id": "111110",
    "label": "Bugak Krueng Matee",
    "value": "Bugak Krueng Matee"
  },
  {
    "id": "1111102036",
    "district_id": "111110",
    "label": "Bugak Krueng",
    "value": "Bugak Krueng"
  },
  {
    "id": "1111102037",
    "district_id": "111110",
    "label": "Pante Ranub",
    "value": "Pante Ranub"
  },
  {
    "id": "1111102038",
    "district_id": "111110",
    "label": "Pulo Pineung Meunasah Dua",
    "value": "Pulo Pineung Meunasah Dua"
  },
  {
    "id": "1111102039",
    "district_id": "111110",
    "label": "Punjot",
    "value": "Punjot"
  },
  {
    "id": "1111102040",
    "district_id": "111110",
    "label": "Bugeng",
    "value": "Bugeng"
  },
  {
    "id": "1111102041",
    "district_id": "111110",
    "label": "Alue Kuta",
    "value": "Alue Kuta"
  },
  {
    "id": "1111102042",
    "district_id": "111110",
    "label": "Alue Bayeu Utang",
    "value": "Alue Bayeu Utang"
  },
  {
    "id": "1111102043",
    "district_id": "111110",
    "label": "Ulee Ceu",
    "value": "Ulee Ceu"
  },
  {
    "id": "1111102044",
    "district_id": "111110",
    "label": "Kuala Cerape",
    "value": "Kuala Cerape"
  },
  {
    "id": "1111102045",
    "district_id": "111110",
    "label": "Pante Peusangan",
    "value": "Pante Peusangan"
  },
  {
    "id": "1111102046",
    "district_id": "111110",
    "label": "Alue Bie Pusong",
    "value": "Alue Bie Pusong"
  },
  {
    "id": "1111112001",
    "district_id": "111111",
    "label": "Rheum Barat",
    "value": "Rheum Barat"
  },
  {
    "id": "1111112002",
    "district_id": "111111",
    "label": "Rheum Baroh",
    "value": "Rheum Baroh"
  },
  {
    "id": "1111112003",
    "district_id": "111111",
    "label": "Rheum Timu",
    "value": "Rheum Timu"
  },
  {
    "id": "1111112004",
    "district_id": "111111",
    "label": "Blang Kuta Coh",
    "value": "Blang Kuta Coh"
  },
  {
    "id": "1111112005",
    "district_id": "111111",
    "label": "Blang Kuta Dua Meunasah",
    "value": "Blang Kuta Dua Meunasah"
  },
  {
    "id": "1111112006",
    "district_id": "111111",
    "label": "Lancang",
    "value": "Lancang"
  },
  {
    "id": "1111112007",
    "district_id": "111111",
    "label": "Blang Teumulek",
    "value": "Blang Teumulek"
  },
  {
    "id": "1111112008",
    "district_id": "111111",
    "label": "Meunasah Mesjid",
    "value": "Meunasah Mesjid"
  },
  {
    "id": "1111112009",
    "district_id": "111111",
    "label": "Jurong Binjee",
    "value": "Jurong Binjee"
  },
  {
    "id": "1111112010",
    "district_id": "111111",
    "label": "Blang Mane Dua Meunasah",
    "value": "Blang Mane Dua Meunasah"
  },
  {
    "id": "1111112011",
    "district_id": "111111",
    "label": "Blang Mane Barat",
    "value": "Blang Mane Barat"
  },
  {
    "id": "1111112012",
    "district_id": "111111",
    "label": "Balee",
    "value": "Balee"
  },
  {
    "id": "1111112013",
    "district_id": "111111",
    "label": "Arongan",
    "value": "Arongan"
  },
  {
    "id": "1111112014",
    "district_id": "111111",
    "label": "Meunasah Mamplam",
    "value": "Meunasah Mamplam"
  },
  {
    "id": "1111112015",
    "district_id": "111111",
    "label": "Pulo Dapong",
    "value": "Pulo Dapong"
  },
  {
    "id": "1111112016",
    "district_id": "111111",
    "label": "Ceurucok",
    "value": "Ceurucok"
  },
  {
    "id": "1111112017",
    "district_id": "111111",
    "label": "Ie Rhob Glumpang",
    "value": "Ie Rhob Glumpang"
  },
  {
    "id": "1111112018",
    "district_id": "111111",
    "label": "Ie Rhob Barat",
    "value": "Ie Rhob Barat"
  },
  {
    "id": "1111112019",
    "district_id": "111111",
    "label": "Ie Rhob Timu",
    "value": "Ie Rhob Timu"
  },
  {
    "id": "1111112020",
    "district_id": "111111",
    "label": "Ie Rhob Babah Lueng",
    "value": "Ie Rhob Babah Lueng"
  },
  {
    "id": "1111112021",
    "district_id": "111111",
    "label": "Glee Meudong",
    "value": "Glee Meudong"
  },
  {
    "id": "1111112022",
    "district_id": "111111",
    "label": "Pulo Drien",
    "value": "Pulo Drien"
  },
  {
    "id": "1111112023",
    "district_id": "111111",
    "label": "Meunasah Dayah",
    "value": "Meunasah Dayah"
  },
  {
    "id": "1111112024",
    "district_id": "111111",
    "label": "Cot Trieng",
    "value": "Cot Trieng"
  },
  {
    "id": "1111112025",
    "district_id": "111111",
    "label": "Krueng Meuseugob",
    "value": "Krueng Meuseugob"
  },
  {
    "id": "1111112026",
    "district_id": "111111",
    "label": "Lhok Tanoh",
    "value": "Lhok Tanoh"
  },
  {
    "id": "1111112027",
    "district_id": "111111",
    "label": "Blang Tambue",
    "value": "Blang Tambue"
  },
  {
    "id": "1111112028",
    "district_id": "111111",
    "label": "Keude Tambue",
    "value": "Keude Tambue"
  },
  {
    "id": "1111112029",
    "district_id": "111111",
    "label": "Meunasah Asan",
    "value": "Meunasah Asan"
  },
  {
    "id": "1111112030",
    "district_id": "111111",
    "label": "Peuneulet Tunong",
    "value": "Peuneulet Tunong"
  },
  {
    "id": "1111112031",
    "district_id": "111111",
    "label": "Cure Tunong",
    "value": "Cure Tunong"
  },
  {
    "id": "1111112032",
    "district_id": "111111",
    "label": "Cure Baroh",
    "value": "Cure Baroh"
  },
  {
    "id": "1111112033",
    "district_id": "111111",
    "label": "Peuneulet Baroh",
    "value": "Peuneulet Baroh"
  },
  {
    "id": "1111112034",
    "district_id": "111111",
    "label": "Ulee Kareung",
    "value": "Ulee Kareung"
  },
  {
    "id": "1111112035",
    "district_id": "111111",
    "label": "Blang Payang",
    "value": "Blang Payang"
  },
  {
    "id": "1111112036",
    "district_id": "111111",
    "label": "Calok",
    "value": "Calok"
  },
  {
    "id": "1111112037",
    "district_id": "111111",
    "label": "Alue Leuhop",
    "value": "Alue Leuhop"
  },
  {
    "id": "1111112038",
    "district_id": "111111",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "1111112039",
    "district_id": "111111",
    "label": "Lhok Mane",
    "value": "Lhok Mane"
  },
  {
    "id": "1111112040",
    "district_id": "111111",
    "label": "Tambue Barat",
    "value": "Tambue Barat"
  },
  {
    "id": "1111112041",
    "district_id": "111111",
    "label": "Meunasah Barat",
    "value": "Meunasah Barat"
  },
  {
    "id": "1111122001",
    "district_id": "111112",
    "label": "Keude Plimbang",
    "value": "Keude Plimbang"
  },
  {
    "id": "1111122002",
    "district_id": "111112",
    "label": "Krueng Baro",
    "value": "Krueng Baro"
  },
  {
    "id": "1111122003",
    "district_id": "111112",
    "label": "Seuneubok Nalan",
    "value": "Seuneubok Nalan"
  },
  {
    "id": "1111122004",
    "district_id": "111112",
    "label": "Rambong Payong",
    "value": "Rambong Payong"
  },
  {
    "id": "1111122005",
    "district_id": "111112",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1111122006",
    "district_id": "111112",
    "label": "Seuneubok Plimbang",
    "value": "Seuneubok Plimbang"
  },
  {
    "id": "1111122007",
    "district_id": "111112",
    "label": "Seuneubok Punti",
    "value": "Seuneubok Punti"
  },
  {
    "id": "1111122008",
    "district_id": "111112",
    "label": "Uteun Sikumbong",
    "value": "Uteun Sikumbong"
  },
  {
    "id": "1111122009",
    "district_id": "111112",
    "label": "Teupin Panah",
    "value": "Teupin Panah"
  },
  {
    "id": "1111122010",
    "district_id": "111112",
    "label": "Matang Kulee",
    "value": "Matang Kulee"
  },
  {
    "id": "1111122011",
    "district_id": "111112",
    "label": "Lancok Bungo",
    "value": "Lancok Bungo"
  },
  {
    "id": "1111122012",
    "district_id": "111112",
    "label": "Uteun Rungkom",
    "value": "Uteun Rungkom"
  },
  {
    "id": "1111122013",
    "district_id": "111112",
    "label": "Padang Kasab",
    "value": "Padang Kasab"
  },
  {
    "id": "1111122014",
    "district_id": "111112",
    "label": "Seuneubok Seumawe",
    "value": "Seuneubok Seumawe"
  },
  {
    "id": "1111122015",
    "district_id": "111112",
    "label": "Jambo Dalam",
    "value": "Jambo Dalam"
  },
  {
    "id": "1111122016",
    "district_id": "111112",
    "label": "Cot Gleumpang",
    "value": "Cot Gleumpang"
  },
  {
    "id": "1111122017",
    "district_id": "111112",
    "label": "Puuek",
    "value": "Puuek"
  },
  {
    "id": "1111122018",
    "district_id": "111112",
    "label": "Balee Daka",
    "value": "Balee Daka"
  },
  {
    "id": "1111122019",
    "district_id": "111112",
    "label": "Paloh Pupu",
    "value": "Paloh Pupu"
  },
  {
    "id": "1111122020",
    "district_id": "111112",
    "label": "Seunebok Teungoh",
    "value": "Seunebok Teungoh"
  },
  {
    "id": "1111122021",
    "district_id": "111112",
    "label": "Garab",
    "value": "Garab"
  },
  {
    "id": "1111122022",
    "district_id": "111112",
    "label": "Kuta Trieng",
    "value": "Kuta Trieng"
  },
  {
    "id": "1111132001",
    "district_id": "111113",
    "label": "Bandar Bireuen",
    "value": "Bandar Bireuen"
  },
  {
    "id": "1111132002",
    "district_id": "111113",
    "label": "Bireuen Meunasah Reulet",
    "value": "Bireuen Meunasah Reulet"
  },
  {
    "id": "1111132003",
    "district_id": "111113",
    "label": "Bireuen Meunasah Blang",
    "value": "Bireuen Meunasah Blang"
  },
  {
    "id": "1111132004",
    "district_id": "111113",
    "label": "Bireuen Meunasah Capa",
    "value": "Bireuen Meunasah Capa"
  },
  {
    "id": "1111132005",
    "district_id": "111113",
    "label": "Bireuen Meunasah Dayah",
    "value": "Bireuen Meunasah Dayah"
  },
  {
    "id": "1111132006",
    "district_id": "111113",
    "label": "Bireuen Meunasah Tgk Digadong",
    "value": "Bireuen Meunasah Tgk Digadong"
  },
  {
    "id": "1111132007",
    "district_id": "111113",
    "label": "Geudong-Geudong",
    "value": "Geudong-Geudong"
  },
  {
    "id": "1111132008",
    "district_id": "111113",
    "label": "Pulo Ara Geudong Teungoh",
    "value": "Pulo Ara Geudong Teungoh"
  },
  {
    "id": "1111132009",
    "district_id": "111113",
    "label": "Geudong Alue",
    "value": "Geudong Alue"
  },
  {
    "id": "1111132010",
    "district_id": "111113",
    "label": "Pulo Kiton",
    "value": "Pulo Kiton"
  },
  {
    "id": "1111132011",
    "district_id": "111113",
    "label": "Lhok Awe Teungoh",
    "value": "Lhok Awe Teungoh"
  },
  {
    "id": "1111132012",
    "district_id": "111113",
    "label": "Geulanggang Teungoh",
    "value": "Geulanggang Teungoh"
  },
  {
    "id": "1111132013",
    "district_id": "111113",
    "label": "Cot Gapu",
    "value": "Cot Gapu"
  },
  {
    "id": "1111132014",
    "district_id": "111113",
    "label": "Geulanggang Kulam",
    "value": "Geulanggang Kulam"
  },
  {
    "id": "1111132015",
    "district_id": "111113",
    "label": "Geulanggang Gampong",
    "value": "Geulanggang Gampong"
  },
  {
    "id": "1111132016",
    "district_id": "111113",
    "label": "Blang Tingkeum",
    "value": "Blang Tingkeum"
  },
  {
    "id": "1111132017",
    "district_id": "111113",
    "label": "Buket Teukueh",
    "value": "Buket Teukueh"
  },
  {
    "id": "1111132018",
    "district_id": "111113",
    "label": "Blang Reuling",
    "value": "Blang Reuling"
  },
  {
    "id": "1111132019",
    "district_id": "111113",
    "label": "Cot Jrat",
    "value": "Cot Jrat"
  },
  {
    "id": "1111132020",
    "district_id": "111113",
    "label": "Cot Peutek",
    "value": "Cot Peutek"
  },
  {
    "id": "1111132021",
    "district_id": "111113",
    "label": "Uteun Reutoh",
    "value": "Uteun Reutoh"
  },
  {
    "id": "1111132022",
    "district_id": "111113",
    "label": "Geulanggang Baro",
    "value": "Geulanggang Baro"
  },
  {
    "id": "1111132023",
    "district_id": "111113",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1111142001",
    "district_id": "111114",
    "label": "Cot Batee",
    "value": "Cot Batee"
  },
  {
    "id": "1111142002",
    "district_id": "111114",
    "label": "Cot Unou",
    "value": "Cot Unou"
  },
  {
    "id": "1111142003",
    "district_id": "111114",
    "label": "Kuta Baro",
    "value": "Kuta Baro"
  },
  {
    "id": "1111142004",
    "district_id": "111114",
    "label": "Cot Gleumpang",
    "value": "Cot Gleumpang"
  },
  {
    "id": "1111142005",
    "district_id": "111114",
    "label": "Cot Kuta",
    "value": "Cot Kuta"
  },
  {
    "id": "1111142006",
    "district_id": "111114",
    "label": "Glumpang Baroh",
    "value": "Glumpang Baroh"
  },
  {
    "id": "1111142007",
    "district_id": "111114",
    "label": "Krueng Juli Timu",
    "value": "Krueng Juli Timu"
  },
  {
    "id": "1111142008",
    "district_id": "111114",
    "label": "Krueng Juli Barat",
    "value": "Krueng Juli Barat"
  },
  {
    "id": "1111142009",
    "district_id": "111114",
    "label": "Cot Trieng",
    "value": "Cot Trieng"
  },
  {
    "id": "1111142010",
    "district_id": "111114",
    "label": "Lhok Awe Awe",
    "value": "Lhok Awe Awe"
  },
  {
    "id": "1111142011",
    "district_id": "111114",
    "label": "Cot Laga Sawa",
    "value": "Cot Laga Sawa"
  },
  {
    "id": "1111142012",
    "district_id": "111114",
    "label": "Cot U Sibak",
    "value": "Cot U Sibak"
  },
  {
    "id": "1111142013",
    "district_id": "111114",
    "label": "Lancok Pante Ara",
    "value": "Lancok Pante Ara"
  },
  {
    "id": "1111142014",
    "district_id": "111114",
    "label": "Lancok-Lancok",
    "value": "Lancok-Lancok"
  },
  {
    "id": "1111142015",
    "district_id": "111114",
    "label": "Kareung",
    "value": "Kareung"
  },
  {
    "id": "1111142016",
    "district_id": "111114",
    "label": "Bale Kunyuen",
    "value": "Bale Kunyuen"
  },
  {
    "id": "1111142017",
    "district_id": "111114",
    "label": "Ujong Blang",
    "value": "Ujong Blang"
  },
  {
    "id": "1111142018",
    "district_id": "111114",
    "label": "Ujong Blang Mesjid",
    "value": "Ujong Blang Mesjid"
  },
  {
    "id": "1111142019",
    "district_id": "111114",
    "label": "Weu Jangka",
    "value": "Weu Jangka"
  },
  {
    "id": "1111142020",
    "district_id": "111114",
    "label": "Kuala Raja",
    "value": "Kuala Raja"
  },
  {
    "id": "1111152001",
    "district_id": "111115",
    "label": "Lueng Daneun",
    "value": "Lueng Daneun"
  },
  {
    "id": "1111152002",
    "district_id": "111115",
    "label": "Rambong Payong",
    "value": "Rambong Payong"
  },
  {
    "id": "1111152003",
    "district_id": "111115",
    "label": "Alue Kupula",
    "value": "Alue Kupula"
  },
  {
    "id": "1111152004",
    "district_id": "111115",
    "label": "Blang Cirih",
    "value": "Blang Cirih"
  },
  {
    "id": "1111152005",
    "district_id": "111115",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "1111152006",
    "district_id": "111115",
    "label": "Teupin Raya",
    "value": "Teupin Raya"
  },
  {
    "id": "1111152007",
    "district_id": "111115",
    "label": "Cot Aneuk Batee",
    "value": "Cot Aneuk Batee"
  },
  {
    "id": "1111152008",
    "district_id": "111115",
    "label": "Kubu Raya",
    "value": "Kubu Raya"
  },
  {
    "id": "1111152009",
    "district_id": "111115",
    "label": "Pante Baro Gle Siblah",
    "value": "Pante Baro Gle Siblah"
  },
  {
    "id": "1111152010",
    "district_id": "111115",
    "label": "Pante Baro Kumbang",
    "value": "Pante Baro Kumbang"
  },
  {
    "id": "1111152011",
    "district_id": "111115",
    "label": "Pante Baro Buket Panyang",
    "value": "Pante Baro Buket Panyang"
  },
  {
    "id": "1111152012",
    "district_id": "111115",
    "label": "Alue Krueb",
    "value": "Alue Krueb"
  },
  {
    "id": "1111152013",
    "district_id": "111115",
    "label": "Alue Iet",
    "value": "Alue Iet"
  },
  {
    "id": "1111152014",
    "district_id": "111115",
    "label": "Paloh Mampre",
    "value": "Paloh Mampre"
  },
  {
    "id": "1111152015",
    "district_id": "111115",
    "label": "Buket Sudan",
    "value": "Buket Sudan"
  },
  {
    "id": "1111152016",
    "district_id": "111115",
    "label": "Awe Geutah",
    "value": "Awe Geutah"
  },
  {
    "id": "1111152017",
    "district_id": "111115",
    "label": "Cot Saleuet",
    "value": "Cot Saleuet"
  },
  {
    "id": "1111152018",
    "district_id": "111115",
    "label": "Alue Glumpang",
    "value": "Alue Glumpang"
  },
  {
    "id": "1111152019",
    "district_id": "111115",
    "label": "Pante Karya",
    "value": "Pante Karya"
  },
  {
    "id": "1111152020",
    "district_id": "111115",
    "label": "Awe Geutah Paya",
    "value": "Awe Geutah Paya"
  },
  {
    "id": "1111152021",
    "district_id": "111115",
    "label": "Dayah Baro",
    "value": "Dayah Baro"
  },
  {
    "id": "1111162001",
    "district_id": "111116",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1111162002",
    "district_id": "111116",
    "label": "Blang Mane",
    "value": "Blang Mane"
  },
  {
    "id": "1111162003",
    "district_id": "111116",
    "label": "Tanjong Beuridi",
    "value": "Tanjong Beuridi"
  },
  {
    "id": "1111162004",
    "district_id": "111116",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1111162005",
    "district_id": "111116",
    "label": "Geulanggang Labu",
    "value": "Geulanggang Labu"
  },
  {
    "id": "1111162006",
    "district_id": "111116",
    "label": "Teupin Reudeup",
    "value": "Teupin Reudeup"
  },
  {
    "id": "1111162007",
    "district_id": "111116",
    "label": "Ulee Jalan",
    "value": "Ulee Jalan"
  },
  {
    "id": "1111162008",
    "district_id": "111116",
    "label": "Paya Crot",
    "value": "Paya Crot"
  },
  {
    "id": "1111162009",
    "district_id": "111116",
    "label": "Pulo Panyang",
    "value": "Pulo Panyang"
  },
  {
    "id": "1111162010",
    "district_id": "111116",
    "label": "Me Rayeuk",
    "value": "Me Rayeuk"
  },
  {
    "id": "1111162011",
    "district_id": "111116",
    "label": "Uteun Gathom",
    "value": "Uteun Gathom"
  },
  {
    "id": "1111162012",
    "district_id": "111116",
    "label": "Lung Kuli",
    "value": "Lung Kuli"
  },
  {
    "id": "1111162013",
    "district_id": "111116",
    "label": "Lueng Baro",
    "value": "Lueng Baro"
  },
  {
    "id": "1111162014",
    "district_id": "111116",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1111162015",
    "district_id": "111116",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1111162016",
    "district_id": "111116",
    "label": "Blang Pala",
    "value": "Blang Pala"
  },
  {
    "id": "1111162017",
    "district_id": "111116",
    "label": "Krueng Beukah",
    "value": "Krueng Beukah"
  },
  {
    "id": "1111162018",
    "district_id": "111116",
    "label": "Ceubrek",
    "value": "Ceubrek"
  },
  {
    "id": "1111162019",
    "district_id": "111116",
    "label": "Uteun Raya",
    "value": "Uteun Raya"
  },
  {
    "id": "1111162020",
    "district_id": "111116",
    "label": "Pulo Harapan",
    "value": "Pulo Harapan"
  },
  {
    "id": "1111162021",
    "district_id": "111116",
    "label": "Suwak",
    "value": "Suwak"
  },
  {
    "id": "1111172001",
    "district_id": "111117",
    "label": "Paloh Dama",
    "value": "Paloh Dama"
  },
  {
    "id": "1111172002",
    "district_id": "111117",
    "label": "Paya Rangkuluh",
    "value": "Paya Rangkuluh"
  },
  {
    "id": "1111172003",
    "district_id": "111117",
    "label": "Paloh Raya",
    "value": "Paloh Raya"
  },
  {
    "id": "1111172004",
    "district_id": "111117",
    "label": "Crueng Kumbang",
    "value": "Crueng Kumbang"
  },
  {
    "id": "1111172005",
    "district_id": "111117",
    "label": "Tanjong Siron",
    "value": "Tanjong Siron"
  },
  {
    "id": "1111172006",
    "district_id": "111117",
    "label": "Balee Mee",
    "value": "Balee Mee"
  },
  {
    "id": "1111172007",
    "district_id": "111117",
    "label": "Buket Dalam",
    "value": "Buket Dalam"
  },
  {
    "id": "1111172008",
    "district_id": "111117",
    "label": "Parang Sikureung",
    "value": "Parang Sikureung"
  },
  {
    "id": "1111172009",
    "district_id": "111117",
    "label": "Cot Baroh",
    "value": "Cot Baroh"
  },
  {
    "id": "1111172010",
    "district_id": "111117",
    "label": "Paloh Peuradi",
    "value": "Paloh Peuradi"
  },
  {
    "id": "1111172011",
    "district_id": "111117",
    "label": "Cot Mee",
    "value": "Cot Mee"
  },
  {
    "id": "1111172012",
    "district_id": "111117",
    "label": "Blang Mee",
    "value": "Blang Mee"
  },
  {
    "id": "1111172013",
    "district_id": "111117",
    "label": "Glee Putoh",
    "value": "Glee Putoh"
  },
  {
    "id": "1111172014",
    "district_id": "111117",
    "label": "Tingkeum Baro",
    "value": "Tingkeum Baro"
  },
  {
    "id": "1111172015",
    "district_id": "111117",
    "label": "Tingkeum Manyang",
    "value": "Tingkeum Manyang"
  },
  {
    "id": "1111172016",
    "district_id": "111117",
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "id": "1111172017",
    "district_id": "111117",
    "label": "Babah Suak",
    "value": "Babah Suak"
  },
  {
    "id": "1111172018",
    "district_id": "111117",
    "label": "Ulee Pusong",
    "value": "Ulee Pusong"
  },
  {
    "id": "1111172019",
    "district_id": "111117",
    "label": "Pulo Nga",
    "value": "Pulo Nga"
  },
  {
    "id": "1111172020",
    "district_id": "111117",
    "label": "Meusee",
    "value": "Meusee"
  },
  {
    "id": "1111172021",
    "district_id": "111117",
    "label": "Blang Panjo",
    "value": "Blang Panjo"
  },
  {
    "id": "1111172022",
    "district_id": "111117",
    "label": "Babah Jurong",
    "value": "Babah Jurong"
  },
  {
    "id": "1111172023",
    "district_id": "111117",
    "label": "Dayah Panjoe",
    "value": "Dayah Panjoe"
  },
  {
    "id": "1111172024",
    "district_id": "111117",
    "label": "Pulo Blang",
    "value": "Pulo Blang"
  },
  {
    "id": "1111172025",
    "district_id": "111117",
    "label": "Imbudee",
    "value": "Imbudee"
  },
  {
    "id": "1111172026",
    "district_id": "111117",
    "label": "Pulo Siren",
    "value": "Pulo Siren"
  },
  {
    "id": "1111172027",
    "district_id": "111117",
    "label": "Jarommah Me",
    "value": "Jarommah Me"
  },
  {
    "id": "1111172028",
    "district_id": "111117",
    "label": "Jaromah Baroh",
    "value": "Jaromah Baroh"
  },
  {
    "id": "1111172029",
    "district_id": "111117",
    "label": "Lhok Nga",
    "value": "Lhok Nga"
  },
  {
    "id": "1111172030",
    "district_id": "111117",
    "label": "Pulo Reudeup",
    "value": "Pulo Reudeup"
  },
  {
    "id": "1111172031",
    "district_id": "111117",
    "label": "Geulanggang Meunjee",
    "value": "Geulanggang Meunjee"
  },
  {
    "id": "1111172032",
    "district_id": "111117",
    "label": "Geulanggang Panah",
    "value": "Geulanggang Panah"
  },
  {
    "id": "1111172033",
    "district_id": "111117",
    "label": "Ujong Blang",
    "value": "Ujong Blang"
  },
  {
    "id": "1111172034",
    "district_id": "111117",
    "label": "Geulanggang Rayeuk",
    "value": "Geulanggang Rayeuk"
  },
  {
    "id": "1111172035",
    "district_id": "111117",
    "label": "Dayah Mesjid",
    "value": "Dayah Mesjid"
  },
  {
    "id": "1111172036",
    "district_id": "111117",
    "label": "Keurumbok",
    "value": "Keurumbok"
  },
  {
    "id": "1111172037",
    "district_id": "111117",
    "label": "Cot Ara",
    "value": "Cot Ara"
  },
  {
    "id": "1111172038",
    "district_id": "111117",
    "label": "Rancong",
    "value": "Rancong"
  },
  {
    "id": "1111172039",
    "district_id": "111117",
    "label": "Jambo Kajeung",
    "value": "Jambo Kajeung"
  },
  {
    "id": "1111172040",
    "district_id": "111117",
    "label": "Kulu Kuta",
    "value": "Kulu Kuta"
  },
  {
    "id": "1111172041",
    "district_id": "111117",
    "label": "Pulo Awe",
    "value": "Pulo Awe"
  },
  {
    "id": "1112012006",
    "district_id": "111201",
    "label": "Guhang",
    "value": "Guhang"
  },
  {
    "id": "1112012007",
    "district_id": "111201",
    "label": "Seunaloh",
    "value": "Seunaloh"
  },
  {
    "id": "1112012008",
    "district_id": "111201",
    "label": "Lhung Asan",
    "value": "Lhung Asan"
  },
  {
    "id": "1112012010",
    "district_id": "111201",
    "label": "Lhung Tarok",
    "value": "Lhung Tarok"
  },
  {
    "id": "1112012014",
    "district_id": "111201",
    "label": "Cot Jeurat",
    "value": "Cot Jeurat"
  },
  {
    "id": "1112012015",
    "district_id": "111201",
    "label": "Meudang Ara",
    "value": "Meudang Ara"
  },
  {
    "id": "1112012016",
    "district_id": "111201",
    "label": "Keude Siblah",
    "value": "Keude Siblah"
  },
  {
    "id": "1112012017",
    "district_id": "111201",
    "label": "Kuta Bahagia",
    "value": "Kuta Bahagia"
  },
  {
    "id": "1112012018",
    "district_id": "111201",
    "label": "Kuta Tinggi",
    "value": "Kuta Tinggi"
  },
  {
    "id": "1112012019",
    "district_id": "111201",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1112012020",
    "district_id": "111201",
    "label": "Geulumpang Payong",
    "value": "Geulumpang Payong"
  },
  {
    "id": "1112012021",
    "district_id": "111201",
    "label": "Panton Raya",
    "value": "Panton Raya"
  },
  {
    "id": "1112012022",
    "district_id": "111201",
    "label": "Alue Manggota",
    "value": "Alue Manggota"
  },
  {
    "id": "1112012023",
    "district_id": "111201",
    "label": "Lam Kuta",
    "value": "Lam Kuta"
  },
  {
    "id": "1112012024",
    "district_id": "111201",
    "label": "Baharu",
    "value": "Baharu"
  },
  {
    "id": "1112012025",
    "district_id": "111201",
    "label": "Keude Paya",
    "value": "Keude Paya"
  },
  {
    "id": "1112012026",
    "district_id": "111201",
    "label": "Pasar Blangpidie",
    "value": "Pasar Blangpidie"
  },
  {
    "id": "1112012028",
    "district_id": "111201",
    "label": "Kuta Tuha",
    "value": "Kuta Tuha"
  },
  {
    "id": "1112012029",
    "district_id": "111201",
    "label": "Gudang",
    "value": "Gudang"
  },
  {
    "id": "1112012030",
    "district_id": "111201",
    "label": "Babah Lhueng",
    "value": "Babah Lhueng"
  },
  {
    "id": "1112022001",
    "district_id": "111202",
    "label": "Adan",
    "value": "Adan"
  },
  {
    "id": "1112022002",
    "district_id": "111202",
    "label": "Pante Geulumpang",
    "value": "Pante Geulumpang"
  },
  {
    "id": "1112022003",
    "district_id": "111202",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1112022004",
    "district_id": "111202",
    "label": "Gunong Cut",
    "value": "Gunong Cut"
  },
  {
    "id": "1112022005",
    "district_id": "111202",
    "label": "Padang Kawa",
    "value": "Padang Kawa"
  },
  {
    "id": "1112022006",
    "district_id": "111202",
    "label": "Blang Padang",
    "value": "Blang Padang"
  },
  {
    "id": "1112022007",
    "district_id": "111202",
    "label": "Padang Bak Jeumpa",
    "value": "Padang Bak Jeumpa"
  },
  {
    "id": "1112022008",
    "district_id": "111202",
    "label": "Suak Labu",
    "value": "Suak Labu"
  },
  {
    "id": "1112022014",
    "district_id": "111202",
    "label": "Bineh Krueng",
    "value": "Bineh Krueng"
  },
  {
    "id": "1112022015",
    "district_id": "111202",
    "label": "Drien Kipah",
    "value": "Drien Kipah"
  },
  {
    "id": "1112022016",
    "district_id": "111202",
    "label": "Drien Jalo",
    "value": "Drien Jalo"
  },
  {
    "id": "1112022017",
    "district_id": "111202",
    "label": "Padang Bak Jok",
    "value": "Padang Bak Jok"
  },
  {
    "id": "1112022018",
    "district_id": "111202",
    "label": "Suak Nibong",
    "value": "Suak Nibong"
  },
  {
    "id": "1112022019",
    "district_id": "111202",
    "label": "Kuta Bak Drien",
    "value": "Kuta Bak Drien"
  },
  {
    "id": "1112022020",
    "district_id": "111202",
    "label": "Ie Lhob",
    "value": "Ie Lhob"
  },
  {
    "id": "1112032001",
    "district_id": "111203",
    "label": "Kedai",
    "value": "Kedai"
  },
  {
    "id": "1112032002",
    "district_id": "111203",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1112032003",
    "district_id": "111203",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "1112032005",
    "district_id": "111203",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1112032006",
    "district_id": "111203",
    "label": "Seuneulop",
    "value": "Seuneulop"
  },
  {
    "id": "1112032007",
    "district_id": "111203",
    "label": "Lhok Puntoy",
    "value": "Lhok Puntoy"
  },
  {
    "id": "1112032009",
    "district_id": "111203",
    "label": "Ladang Panah",
    "value": "Ladang Panah"
  },
  {
    "id": "1112032010",
    "district_id": "111203",
    "label": "Pusu Ingin Jaya",
    "value": "Pusu Ingin Jaya"
  },
  {
    "id": "1112032011",
    "district_id": "111203",
    "label": "Panton Makmur",
    "value": "Panton Makmur"
  },
  {
    "id": "1112032022",
    "district_id": "111203",
    "label": "Lhueng Baro",
    "value": "Lhueng Baro"
  },
  {
    "id": "1112032023",
    "district_id": "111203",
    "label": "Tokoh",
    "value": "Tokoh"
  },
  {
    "id": "1112032024",
    "district_id": "111203",
    "label": "Blang Manggeng",
    "value": "Blang Manggeng"
  },
  {
    "id": "1112032025",
    "district_id": "111203",
    "label": "Pante Raja",
    "value": "Pante Raja"
  },
  {
    "id": "1112032026",
    "district_id": "111203",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "1112032027",
    "district_id": "111203",
    "label": "Lhok Pawoh",
    "value": "Lhok Pawoh"
  },
  {
    "id": "1112032029",
    "district_id": "111203",
    "label": "Pante Pirak",
    "value": "Pante Pirak"
  },
  {
    "id": "1112032030",
    "district_id": "111203",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "1112032031",
    "district_id": "111203",
    "label": "Pante Cermin",
    "value": "Pante Cermin"
  },
  {
    "id": "1112042001",
    "district_id": "111204",
    "label": "Pantai Perak",
    "value": "Pantai Perak"
  },
  {
    "id": "1112042002",
    "district_id": "111204",
    "label": "Durian Rampak",
    "value": "Durian Rampak"
  },
  {
    "id": "1112042003",
    "district_id": "111204",
    "label": "Barat",
    "value": "Barat"
  },
  {
    "id": "1112042004",
    "district_id": "111204",
    "label": "Palak Hulu",
    "value": "Palak Hulu"
  },
  {
    "id": "1112042005",
    "district_id": "111204",
    "label": "Palak Hilir",
    "value": "Palak Hilir"
  },
  {
    "id": "1112042006",
    "district_id": "111204",
    "label": "Durian Jangek",
    "value": "Durian Jangek"
  },
  {
    "id": "1112042007",
    "district_id": "111204",
    "label": "Rumah Dua Lapis",
    "value": "Rumah Dua Lapis"
  },
  {
    "id": "1112042008",
    "district_id": "111204",
    "label": "Pinang",
    "value": "Pinang"
  },
  {
    "id": "1112042009",
    "district_id": "111204",
    "label": "Rumah Panjang",
    "value": "Rumah Panjang"
  },
  {
    "id": "1112042010",
    "district_id": "111204",
    "label": "Baharu",
    "value": "Baharu"
  },
  {
    "id": "1112042011",
    "district_id": "111204",
    "label": "Pawoh",
    "value": "Pawoh"
  },
  {
    "id": "1112042012",
    "district_id": "111204",
    "label": "Padang Baru",
    "value": "Padang Baru"
  },
  {
    "id": "1112042013",
    "district_id": "111204",
    "label": "Pulau Kayu",
    "value": "Pulau Kayu"
  },
  {
    "id": "1112042014",
    "district_id": "111204",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1112042015",
    "district_id": "111204",
    "label": "Kedai Susoh",
    "value": "Kedai Susoh"
  },
  {
    "id": "1112042016",
    "district_id": "111204",
    "label": "Panjang Baru",
    "value": "Panjang Baru"
  },
  {
    "id": "1112042017",
    "district_id": "111204",
    "label": "Kedai Palak Kerambil",
    "value": "Kedai Palak Kerambil"
  },
  {
    "id": "1112042018",
    "district_id": "111204",
    "label": "Ladang",
    "value": "Ladang"
  },
  {
    "id": "1112042019",
    "district_id": "111204",
    "label": "Kepala Bandar",
    "value": "Kepala Bandar"
  },
  {
    "id": "1112042020",
    "district_id": "111204",
    "label": "Tangah",
    "value": "Tangah"
  },
  {
    "id": "1112042021",
    "district_id": "111204",
    "label": "Gadang",
    "value": "Gadang"
  },
  {
    "id": "1112042022",
    "district_id": "111204",
    "label": "Padang Hilir",
    "value": "Padang Hilir"
  },
  {
    "id": "1112042023",
    "district_id": "111204",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1112042024",
    "district_id": "111204",
    "label": "Meunasah",
    "value": "Meunasah"
  },
  {
    "id": "1112042025",
    "district_id": "111204",
    "label": "Lampoh Drien",
    "value": "Lampoh Drien"
  },
  {
    "id": "1112042026",
    "district_id": "111204",
    "label": "Padang Panjang",
    "value": "Padang Panjang"
  },
  {
    "id": "1112042027",
    "district_id": "111204",
    "label": "Rubek Meupayong",
    "value": "Rubek Meupayong"
  },
  {
    "id": "1112042028",
    "district_id": "111204",
    "label": "Cot Mancang",
    "value": "Cot Mancang"
  },
  {
    "id": "1112042029",
    "district_id": "111204",
    "label": "Geulima Jaya",
    "value": "Geulima Jaya"
  },
  {
    "id": "1112052001",
    "district_id": "111205",
    "label": "Kota Bahagia",
    "value": "Kota Bahagia"
  },
  {
    "id": "1112052002",
    "district_id": "111205",
    "label": "Pasar Kota Bahagia",
    "value": "Pasar Kota Bahagia"
  },
  {
    "id": "1112052003",
    "district_id": "111205",
    "label": "Geulanggang Gajah",
    "value": "Geulanggang Gajah"
  },
  {
    "id": "1112052004",
    "district_id": "111205",
    "label": "Kuala Terubue",
    "value": "Kuala Terubue"
  },
  {
    "id": "1112052005",
    "district_id": "111205",
    "label": "Blang Makmur",
    "value": "Blang Makmur"
  },
  {
    "id": "1112052006",
    "district_id": "111205",
    "label": "Panto Cut",
    "value": "Panto Cut"
  },
  {
    "id": "1112052007",
    "district_id": "111205",
    "label": "Alue Padee",
    "value": "Alue Padee"
  },
  {
    "id": "1112052008",
    "district_id": "111205",
    "label": "Muka Blang",
    "value": "Muka Blang"
  },
  {
    "id": "1112052009",
    "district_id": "111205",
    "label": "Lhung Geulumpang",
    "value": "Lhung Geulumpang"
  },
  {
    "id": "1112052010",
    "district_id": "111205",
    "label": "Padang Sikabu",
    "value": "Padang Sikabu"
  },
  {
    "id": "1112052011",
    "district_id": "111205",
    "label": "Kampung Tengah",
    "value": "Kampung Tengah"
  },
  {
    "id": "1112052012",
    "district_id": "111205",
    "label": "Blang Panyang",
    "value": "Blang Panyang"
  },
  {
    "id": "1112052013",
    "district_id": "111205",
    "label": "Krueng Batee",
    "value": "Krueng Batee"
  },
  {
    "id": "1112052014",
    "district_id": "111205",
    "label": "Alue Pisang",
    "value": "Alue Pisang"
  },
  {
    "id": "1112052015",
    "district_id": "111205",
    "label": "Lhok Gajah",
    "value": "Lhok Gajah"
  },
  {
    "id": "1112052016",
    "district_id": "111205",
    "label": "Ie Mameh",
    "value": "Ie Mameh"
  },
  {
    "id": "1112052017",
    "district_id": "111205",
    "label": "Keude Baro",
    "value": "Keude Baro"
  },
  {
    "id": "1112052018",
    "district_id": "111205",
    "label": "Lama Tuha",
    "value": "Lama Tuha"
  },
  {
    "id": "1112052019",
    "district_id": "111205",
    "label": "Drien Beurumbang",
    "value": "Drien Beurumbang"
  },
  {
    "id": "1112052020",
    "district_id": "111205",
    "label": "Rumoh Panyang",
    "value": "Rumoh Panyang"
  },
  {
    "id": "1112052021",
    "district_id": "111205",
    "label": "Krueng Panto",
    "value": "Krueng Panto"
  },
  {
    "id": "1112062001",
    "district_id": "111206",
    "label": "Pante Rakyat",
    "value": "Pante Rakyat"
  },
  {
    "id": "1112062002",
    "district_id": "111206",
    "label": "Ie Mirah",
    "value": "Ie Mirah"
  },
  {
    "id": "1112062003",
    "district_id": "111206",
    "label": "Gunung Samarinda",
    "value": "Gunung Samarinda"
  },
  {
    "id": "1112062004",
    "district_id": "111206",
    "label": "Alue Jeurejak",
    "value": "Alue Jeurejak"
  },
  {
    "id": "1112062005",
    "district_id": "111206",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1112062006",
    "district_id": "111206",
    "label": "Alue Peunawa",
    "value": "Alue Peunawa"
  },
  {
    "id": "1112062007",
    "district_id": "111206",
    "label": "Pante Cermin",
    "value": "Pante Cermin"
  },
  {
    "id": "1112062008",
    "district_id": "111206",
    "label": "Blang Raja",
    "value": "Blang Raja"
  },
  {
    "id": "1112062009",
    "district_id": "111206",
    "label": "Rukoen Dame",
    "value": "Rukoen Dame"
  },
  {
    "id": "1112062010",
    "district_id": "111206",
    "label": "Simpang Gadeng",
    "value": "Simpang Gadeng"
  },
  {
    "id": "1112062011",
    "district_id": "111206",
    "label": "Teladan Jaya",
    "value": "Teladan Jaya"
  },
  {
    "id": "1112062012",
    "district_id": "111206",
    "label": "Lhok Gayo",
    "value": "Lhok Gayo"
  },
  {
    "id": "1112062013",
    "district_id": "111206",
    "label": "Alue Dawah",
    "value": "Alue Dawah"
  },
  {
    "id": "1112062014",
    "district_id": "111206",
    "label": "Cot Seumantok",
    "value": "Cot Seumantok"
  },
  {
    "id": "1112072001",
    "district_id": "111207",
    "label": "Lhang",
    "value": "Lhang"
  },
  {
    "id": "1112072002",
    "district_id": "111207",
    "label": "Rambong",
    "value": "Rambong"
  },
  {
    "id": "1112072003",
    "district_id": "111207",
    "label": "Pisang",
    "value": "Pisang"
  },
  {
    "id": "1112072004",
    "district_id": "111207",
    "label": "Ujong Tanoh",
    "value": "Ujong Tanoh"
  },
  {
    "id": "1112072005",
    "district_id": "111207",
    "label": "Alue Dama",
    "value": "Alue Dama"
  },
  {
    "id": "1112072007",
    "district_id": "111207",
    "label": "Tangan-Tangan Cut",
    "value": "Tangan-Tangan Cut"
  },
  {
    "id": "1112072008",
    "district_id": "111207",
    "label": "Mon Mameh",
    "value": "Mon Mameh"
  },
  {
    "id": "1112072009",
    "district_id": "111207",
    "label": "Cinta Makmur",
    "value": "Cinta Makmur"
  },
  {
    "id": "1112072010",
    "district_id": "111207",
    "label": "Kuta Murni",
    "value": "Kuta Murni"
  },
  {
    "id": "1112082001",
    "district_id": "111208",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1112082002",
    "district_id": "111208",
    "label": "Alue Rambot",
    "value": "Alue Rambot"
  },
  {
    "id": "1112082003",
    "district_id": "111208",
    "label": "Kuta Jeumpa",
    "value": "Kuta Jeumpa"
  },
  {
    "id": "1112082004",
    "district_id": "111208",
    "label": "Alue Sungai Pinang",
    "value": "Alue Sungai Pinang"
  },
  {
    "id": "1112082005",
    "district_id": "111208",
    "label": "Cot Mane",
    "value": "Cot Mane"
  },
  {
    "id": "1112082006",
    "district_id": "111208",
    "label": "Ladang Neubok",
    "value": "Ladang Neubok"
  },
  {
    "id": "1112082007",
    "district_id": "111208",
    "label": "Asoe Nanggroe",
    "value": "Asoe Nanggroe"
  },
  {
    "id": "1112082008",
    "district_id": "111208",
    "label": "Padang Geulumpang",
    "value": "Padang Geulumpang"
  },
  {
    "id": "1112082009",
    "district_id": "111208",
    "label": "Ikue Lhung",
    "value": "Ikue Lhung"
  },
  {
    "id": "1112082010",
    "district_id": "111208",
    "label": "Kuta Makmur",
    "value": "Kuta Makmur"
  },
  {
    "id": "1112082011",
    "district_id": "111208",
    "label": "Jeumpa Barat",
    "value": "Jeumpa Barat"
  },
  {
    "id": "1112082012",
    "district_id": "111208",
    "label": "Alue Seulaseh",
    "value": "Alue Seulaseh"
  },
  {
    "id": "1112092001",
    "district_id": "111209",
    "label": "Meurandeh",
    "value": "Meurandeh"
  },
  {
    "id": "1112092002",
    "district_id": "111209",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "1112092003",
    "district_id": "111209",
    "label": "Cot Bak-U",
    "value": "Cot Bak-U"
  },
  {
    "id": "1112092004",
    "district_id": "111209",
    "label": "Meunasah Sukon",
    "value": "Meunasah Sukon"
  },
  {
    "id": "1112092005",
    "district_id": "111209",
    "label": "Meunasah Tengah",
    "value": "Meunasah Tengah"
  },
  {
    "id": "1112092006",
    "district_id": "111209",
    "label": "Geulanggang Batee",
    "value": "Geulanggang Batee"
  },
  {
    "id": "1112092007",
    "district_id": "111209",
    "label": "Kuta Paya",
    "value": "Kuta Paya"
  },
  {
    "id": "1112092008",
    "district_id": "111209",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "1112092009",
    "district_id": "111209",
    "label": "Padang Keulele",
    "value": "Padang Keulele"
  },
  {
    "id": "1112092010",
    "district_id": "111209",
    "label": "Alue Rambot",
    "value": "Alue Rambot"
  },
  {
    "id": "1112092011",
    "district_id": "111209",
    "label": "Ladang Tuha I",
    "value": "Ladang Tuha I"
  },
  {
    "id": "1112092012",
    "district_id": "111209",
    "label": "Ladang Tuha II",
    "value": "Ladang Tuha II"
  },
  {
    "id": "1112092013",
    "district_id": "111209",
    "label": "Tokoh II",
    "value": "Tokoh II"
  },
  {
    "id": "1112092014",
    "district_id": "111209",
    "label": "Kayee Aceh",
    "value": "Kayee Aceh"
  },
  {
    "id": "1113012001",
    "district_id": "111301",
    "label": "Kota Blangkejeren",
    "value": "Kota Blangkejeren"
  },
  {
    "id": "1113012002",
    "district_id": "111301",
    "label": "Penampaan",
    "value": "Penampaan"
  },
  {
    "id": "1113012003",
    "district_id": "111301",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "1113012004",
    "district_id": "111301",
    "label": "Bacang",
    "value": "Bacang"
  },
  {
    "id": "1113012005",
    "district_id": "111301",
    "label": "Durin",
    "value": "Durin"
  },
  {
    "id": "1113012007",
    "district_id": "111301",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "1113012008",
    "district_id": "111301",
    "label": "Porang",
    "value": "Porang"
  },
  {
    "id": "1113012011",
    "district_id": "111301",
    "label": "Kute Lintang",
    "value": "Kute Lintang"
  },
  {
    "id": "1113012014",
    "district_id": "111301",
    "label": "Leme",
    "value": "Leme"
  },
  {
    "id": "1113012016",
    "district_id": "111301",
    "label": "Gele",
    "value": "Gele"
  },
  {
    "id": "1113012017",
    "district_id": "111301",
    "label": "Sere",
    "value": "Sere"
  },
  {
    "id": "1113012018",
    "district_id": "111301",
    "label": "Cempa",
    "value": "Cempa"
  },
  {
    "id": "1113012019",
    "district_id": "111301",
    "label": "Lempuh",
    "value": "Lempuh"
  },
  {
    "id": "1113012021",
    "district_id": "111301",
    "label": "Palok",
    "value": "Palok"
  },
  {
    "id": "1113012022",
    "district_id": "111301",
    "label": "Penggalangan",
    "value": "Penggalangan"
  },
  {
    "id": "1113012027",
    "district_id": "111301",
    "label": "Agusan",
    "value": "Agusan"
  },
  {
    "id": "1113012031",
    "district_id": "111301",
    "label": "Bustanussalam",
    "value": "Bustanussalam"
  },
  {
    "id": "1113012032",
    "district_id": "111301",
    "label": "Penampaan Uken",
    "value": "Penampaan Uken"
  },
  {
    "id": "1113012033",
    "district_id": "111301",
    "label": "Raklunung",
    "value": "Raklunung"
  },
  {
    "id": "1113012034",
    "district_id": "111301",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "1113022002",
    "district_id": "111302",
    "label": "Tampeng",
    "value": "Tampeng"
  },
  {
    "id": "1113022004",
    "district_id": "111302",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "1113022005",
    "district_id": "111302",
    "label": "Ulun Tanoh",
    "value": "Ulun Tanoh"
  },
  {
    "id": "1113022006",
    "district_id": "111302",
    "label": "Kuta Ujung",
    "value": "Kuta Ujung"
  },
  {
    "id": "1113022008",
    "district_id": "111302",
    "label": "Beranang",
    "value": "Beranang"
  },
  {
    "id": "1113022010",
    "district_id": "111302",
    "label": "Rikit Dekat",
    "value": "Rikit Dekat"
  },
  {
    "id": "1113022011",
    "district_id": "111302",
    "label": "Rema",
    "value": "Rema"
  },
  {
    "id": "1113022014",
    "district_id": "111302",
    "label": "Rema Baru",
    "value": "Rema Baru"
  },
  {
    "id": "1113022015",
    "district_id": "111302",
    "label": "Cike",
    "value": "Cike"
  },
  {
    "id": "1113022016",
    "district_id": "111302",
    "label": "Kerukunan Kuta Panjang",
    "value": "Kerukunan Kuta Panjang"
  },
  {
    "id": "1113022020",
    "district_id": "111302",
    "label": "Tampeng Musara",
    "value": "Tampeng Musara"
  },
  {
    "id": "1113022021",
    "district_id": "111302",
    "label": "Kong Paluh",
    "value": "Kong Paluh"
  },
  {
    "id": "1113032001",
    "district_id": "111303",
    "label": "Ampa Kolak",
    "value": "Ampa Kolak"
  },
  {
    "id": "1113032002",
    "district_id": "111303",
    "label": "Cane Toa",
    "value": "Cane Toa"
  },
  {
    "id": "1113032003",
    "district_id": "111303",
    "label": "Padang Pasir",
    "value": "Padang Pasir"
  },
  {
    "id": "1113032005",
    "district_id": "111303",
    "label": "Pinang Rugub",
    "value": "Pinang Rugub"
  },
  {
    "id": "1113032006",
    "district_id": "111303",
    "label": "Kuning",
    "value": "Kuning"
  },
  {
    "id": "1113032008",
    "district_id": "111303",
    "label": "Mangang",
    "value": "Mangang"
  },
  {
    "id": "1113032011",
    "district_id": "111303",
    "label": "Rempelam",
    "value": "Rempelam"
  },
  {
    "id": "1113032012",
    "district_id": "111303",
    "label": "Cane Uken",
    "value": "Cane Uken"
  },
  {
    "id": "1113032013",
    "district_id": "111303",
    "label": "Tungel",
    "value": "Tungel"
  },
  {
    "id": "1113032014",
    "district_id": "111303",
    "label": "Kota Rikit Gaib",
    "value": "Kota Rikit Gaib"
  },
  {
    "id": "1113032016",
    "district_id": "111303",
    "label": "Lukup Baru",
    "value": "Lukup Baru"
  },
  {
    "id": "1113032017",
    "district_id": "111303",
    "label": "Penomon Jaya",
    "value": "Penomon Jaya"
  },
  {
    "id": "1113032018",
    "district_id": "111303",
    "label": "Tungel Baru",
    "value": "Tungel Baru"
  },
  {
    "id": "1113042001",
    "district_id": "111304",
    "label": "Jabo",
    "value": "Jabo"
  },
  {
    "id": "1113042002",
    "district_id": "111304",
    "label": "Kutesange",
    "value": "Kutesange"
  },
  {
    "id": "1113042003",
    "district_id": "111304",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1113042006",
    "district_id": "111304",
    "label": "Rumpi",
    "value": "Rumpi"
  },
  {
    "id": "1113042007",
    "district_id": "111304",
    "label": "Bukut",
    "value": "Bukut"
  },
  {
    "id": "1113042008",
    "district_id": "111304",
    "label": "Terangun",
    "value": "Terangun"
  },
  {
    "id": "1113042010",
    "district_id": "111304",
    "label": "Rempelam Pinang",
    "value": "Rempelam Pinang"
  },
  {
    "id": "1113042011",
    "district_id": "111304",
    "label": "Soyo",
    "value": "Soyo"
  },
  {
    "id": "1113042012",
    "district_id": "111304",
    "label": "Terlis",
    "value": "Terlis"
  },
  {
    "id": "1113042013",
    "district_id": "111304",
    "label": "Kutereje",
    "value": "Kutereje"
  },
  {
    "id": "1113042014",
    "district_id": "111304",
    "label": "Persada Tongra",
    "value": "Persada Tongra"
  },
  {
    "id": "1113042015",
    "district_id": "111304",
    "label": "Berhut",
    "value": "Berhut"
  },
  {
    "id": "1113042016",
    "district_id": "111304",
    "label": "Gewat",
    "value": "Gewat"
  },
  {
    "id": "1113042017",
    "district_id": "111304",
    "label": "Pantan Lues",
    "value": "Pantan Lues"
  },
  {
    "id": "1113042018",
    "district_id": "111304",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1113042020",
    "district_id": "111304",
    "label": "Blang Kuncir",
    "value": "Blang Kuncir"
  },
  {
    "id": "1113042023",
    "district_id": "111304",
    "label": "Reje Pudung",
    "value": "Reje Pudung"
  },
  {
    "id": "1113042024",
    "district_id": "111304",
    "label": "Garut",
    "value": "Garut"
  },
  {
    "id": "1113042025",
    "district_id": "111304",
    "label": "Telege Jernih",
    "value": "Telege Jernih"
  },
  {
    "id": "1113042026",
    "district_id": "111304",
    "label": "Rime Raya",
    "value": "Rime Raya"
  },
  {
    "id": "1113042027",
    "district_id": "111304",
    "label": "Lestari",
    "value": "Lestari"
  },
  {
    "id": "1113042028",
    "district_id": "111304",
    "label": "Gawar Belangi",
    "value": "Gawar Belangi"
  },
  {
    "id": "1113042029",
    "district_id": "111304",
    "label": "Blang Kala",
    "value": "Blang Kala"
  },
  {
    "id": "1113052001",
    "district_id": "111305",
    "label": "Pining",
    "value": "Pining"
  },
  {
    "id": "1113052002",
    "district_id": "111305",
    "label": "Pertik",
    "value": "Pertik"
  },
  {
    "id": "1113052003",
    "district_id": "111305",
    "label": "Ekan",
    "value": "Ekan"
  },
  {
    "id": "1113052004",
    "district_id": "111305",
    "label": "Uring",
    "value": "Uring"
  },
  {
    "id": "1113052005",
    "district_id": "111305",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "1113052006",
    "district_id": "111305",
    "label": "Pintu Rime",
    "value": "Pintu Rime"
  },
  {
    "id": "1113052007",
    "district_id": "111305",
    "label": "Pepelah",
    "value": "Pepelah"
  },
  {
    "id": "1113052008",
    "district_id": "111305",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1113052009",
    "district_id": "111305",
    "label": "Lesten",
    "value": "Lesten"
  },
  {
    "id": "1113062001",
    "district_id": "111306",
    "label": "Gantung Geluni",
    "value": "Gantung Geluni"
  },
  {
    "id": "1113062002",
    "district_id": "111306",
    "label": "Kutebukit",
    "value": "Kutebukit"
  },
  {
    "id": "1113062003",
    "district_id": "111306",
    "label": "Bener Baru",
    "value": "Bener Baru"
  },
  {
    "id": "1113062004",
    "district_id": "111306",
    "label": "Blang Bengkik",
    "value": "Blang Bengkik"
  },
  {
    "id": "1113062005",
    "district_id": "111306",
    "label": "Kong",
    "value": "Kong"
  },
  {
    "id": "1113062006",
    "district_id": "111306",
    "label": "Ume Lah",
    "value": "Ume Lah"
  },
  {
    "id": "1113062007",
    "district_id": "111306",
    "label": "Cinta Maju",
    "value": "Cinta Maju"
  },
  {
    "id": "1113062008",
    "district_id": "111306",
    "label": "Porang Ayu",
    "value": "Porang Ayu"
  },
  {
    "id": "1113062009",
    "district_id": "111306",
    "label": "Tetingi",
    "value": "Tetingi"
  },
  {
    "id": "1113062010",
    "district_id": "111306",
    "label": "Anak Reje",
    "value": "Anak Reje"
  },
  {
    "id": "1113062011",
    "district_id": "111306",
    "label": "Rak Lintang",
    "value": "Rak Lintang"
  },
  {
    "id": "1113062012",
    "district_id": "111306",
    "label": "Akang Siwah",
    "value": "Akang Siwah"
  },
  {
    "id": "1113072001",
    "district_id": "111307",
    "label": "Gumpang",
    "value": "Gumpang"
  },
  {
    "id": "1113072002",
    "district_id": "111307",
    "label": "Marpunge Gabungan",
    "value": "Marpunge Gabungan"
  },
  {
    "id": "1113072003",
    "district_id": "111307",
    "label": "Ramung Musara",
    "value": "Ramung Musara"
  },
  {
    "id": "1113072004",
    "district_id": "111307",
    "label": "Meloak Sepakat",
    "value": "Meloak Sepakat"
  },
  {
    "id": "1113072005",
    "district_id": "111307",
    "label": "Kutelengat Sepakat",
    "value": "Kutelengat Sepakat"
  },
  {
    "id": "1113072006",
    "district_id": "111307",
    "label": "Singah Mulo",
    "value": "Singah Mulo"
  },
  {
    "id": "1113072007",
    "district_id": "111307",
    "label": "Uning Pune",
    "value": "Uning Pune"
  },
  {
    "id": "1113072008",
    "district_id": "111307",
    "label": "Gumpang Lempuh",
    "value": "Gumpang Lempuh"
  },
  {
    "id": "1113072009",
    "district_id": "111307",
    "label": "Putri Betung",
    "value": "Putri Betung"
  },
  {
    "id": "1113082001",
    "district_id": "111308",
    "label": "Pangur",
    "value": "Pangur"
  },
  {
    "id": "1113082002",
    "district_id": "111308",
    "label": "Badak",
    "value": "Badak"
  },
  {
    "id": "1113082003",
    "district_id": "111308",
    "label": "Kendawi",
    "value": "Kendawi"
  },
  {
    "id": "1113082004",
    "district_id": "111308",
    "label": "Sangir",
    "value": "Sangir"
  },
  {
    "id": "1113082005",
    "district_id": "111308",
    "label": "Panglima Linting",
    "value": "Panglima Linting"
  },
  {
    "id": "1113082006",
    "district_id": "111308",
    "label": "Blangtemung",
    "value": "Blangtemung"
  },
  {
    "id": "1113082007",
    "district_id": "111308",
    "label": "Rerebe",
    "value": "Rerebe"
  },
  {
    "id": "1113082008",
    "district_id": "111308",
    "label": "Uning Gelung",
    "value": "Uning Gelung"
  },
  {
    "id": "1113082009",
    "district_id": "111308",
    "label": "Uning Sepakat",
    "value": "Uning Sepakat"
  },
  {
    "id": "1113092001",
    "district_id": "111309",
    "label": "Penosan",
    "value": "Penosan"
  },
  {
    "id": "1113092002",
    "district_id": "111309",
    "label": "Sekuelen",
    "value": "Sekuelen"
  },
  {
    "id": "1113092003",
    "district_id": "111309",
    "label": "Akul",
    "value": "Akul"
  },
  {
    "id": "1113092004",
    "district_id": "111309",
    "label": "Gegarang",
    "value": "Gegarang"
  },
  {
    "id": "1113092005",
    "district_id": "111309",
    "label": "Peparik Gaib",
    "value": "Peparik Gaib"
  },
  {
    "id": "1113092006",
    "district_id": "111309",
    "label": "Penosan Sepakat",
    "value": "Penosan Sepakat"
  },
  {
    "id": "1113092007",
    "district_id": "111309",
    "label": "Tingkem",
    "value": "Tingkem"
  },
  {
    "id": "1113092008",
    "district_id": "111309",
    "label": "Ketukah",
    "value": "Ketukah"
  },
  {
    "id": "1113092009",
    "district_id": "111309",
    "label": "Peparik Dekat",
    "value": "Peparik Dekat"
  },
  {
    "id": "1113092010",
    "district_id": "111309",
    "label": "Blang Jerango",
    "value": "Blang Jerango"
  },
  {
    "id": "1113102001",
    "district_id": "111310",
    "label": "Perlak",
    "value": "Perlak"
  },
  {
    "id": "1113102002",
    "district_id": "111310",
    "label": "Rerebe",
    "value": "Rerebe"
  },
  {
    "id": "1113102003",
    "district_id": "111310",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "1113102004",
    "district_id": "111310",
    "label": "Setul",
    "value": "Setul"
  },
  {
    "id": "1113102005",
    "district_id": "111310",
    "label": "UPT. Paya Kumer",
    "value": "UPT. Paya Kumer"
  },
  {
    "id": "1113102006",
    "district_id": "111310",
    "label": "UPT. Pantan Kela",
    "value": "UPT. Pantan Kela"
  },
  {
    "id": "1113102007",
    "district_id": "111310",
    "label": "Buntul Musara",
    "value": "Buntul Musara"
  },
  {
    "id": "1113102008",
    "district_id": "111310",
    "label": "Kuala Jernih",
    "value": "Kuala Jernih"
  },
  {
    "id": "1113102009",
    "district_id": "111310",
    "label": "Uyem Beriring",
    "value": "Uyem Beriring"
  },
  {
    "id": "1113102010",
    "district_id": "111310",
    "label": "Pulo Gelime",
    "value": "Pulo Gelime"
  },
  {
    "id": "1113112001",
    "district_id": "111311",
    "label": "Seneren",
    "value": "Seneren"
  },
  {
    "id": "1113112002",
    "district_id": "111311",
    "label": "Remukut",
    "value": "Remukut"
  },
  {
    "id": "1113112003",
    "district_id": "111311",
    "label": "Kenyaran",
    "value": "Kenyaran"
  },
  {
    "id": "1113112004",
    "district_id": "111311",
    "label": "Tetingi",
    "value": "Tetingi"
  },
  {
    "id": "1113112005",
    "district_id": "111311",
    "label": "Atu Kapur",
    "value": "Atu Kapur"
  },
  {
    "id": "1113112006",
    "district_id": "111311",
    "label": "Suri Musara",
    "value": "Suri Musara"
  },
  {
    "id": "1113112007",
    "district_id": "111311",
    "label": "UPT. Aih Selah",
    "value": "UPT. Aih Selah"
  },
  {
    "id": "1113112008",
    "district_id": "111311",
    "label": "Cane Baru",
    "value": "Cane Baru"
  },
  {
    "id": "1113112009",
    "district_id": "111311",
    "label": "Kuning Kurnia",
    "value": "Kuning Kurnia"
  },
  {
    "id": "1114012001",
    "district_id": "111401",
    "label": "Keude Teunom",
    "value": "Keude Teunom"
  },
  {
    "id": "1114012002",
    "district_id": "111401",
    "label": "Alue Ambang",
    "value": "Alue Ambang"
  },
  {
    "id": "1114012003",
    "district_id": "111401",
    "label": "Padang Kleng",
    "value": "Padang Kleng"
  },
  {
    "id": "1114012004",
    "district_id": "111401",
    "label": "Panton",
    "value": "Panton"
  },
  {
    "id": "1114012005",
    "district_id": "111401",
    "label": "Pasi Tulak Bala",
    "value": "Pasi Tulak Bala"
  },
  {
    "id": "1114012006",
    "district_id": "111401",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1114012007",
    "district_id": "111401",
    "label": "Rambong Payong",
    "value": "Rambong Payong"
  },
  {
    "id": "1114012008",
    "district_id": "111401",
    "label": "Pasi Pawang",
    "value": "Pasi Pawang"
  },
  {
    "id": "1114012009",
    "district_id": "111401",
    "label": "Blang Baro",
    "value": "Blang Baro"
  },
  {
    "id": "1114012010",
    "district_id": "111401",
    "label": "Tanoh Anoe",
    "value": "Tanoh Anoe"
  },
  {
    "id": "1114012011",
    "district_id": "111401",
    "label": "Tanoh Manyang",
    "value": "Tanoh Manyang"
  },
  {
    "id": "1114012012",
    "district_id": "111401",
    "label": "Batee Roo",
    "value": "Batee Roo"
  },
  {
    "id": "1114012013",
    "district_id": "111401",
    "label": "Seuneubok Padang",
    "value": "Seuneubok Padang"
  },
  {
    "id": "1114012014",
    "district_id": "111401",
    "label": "Seumira",
    "value": "Seumira"
  },
  {
    "id": "1114012015",
    "district_id": "111401",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "1114012016",
    "district_id": "111401",
    "label": "Alue Meuraksa",
    "value": "Alue Meuraksa"
  },
  {
    "id": "1114012017",
    "district_id": "111401",
    "label": "Paya Baro",
    "value": "Paya Baro"
  },
  {
    "id": "1114012018",
    "district_id": "111401",
    "label": "Teupin Ara",
    "value": "Teupin Ara"
  },
  {
    "id": "1114012019",
    "district_id": "111401",
    "label": "Cot Trap",
    "value": "Cot Trap"
  },
  {
    "id": "1114012020",
    "district_id": "111401",
    "label": "Leung Gayo",
    "value": "Leung Gayo"
  },
  {
    "id": "1114012021",
    "district_id": "111401",
    "label": "Pasi Geulima",
    "value": "Pasi Geulima"
  },
  {
    "id": "1114012022",
    "district_id": "111401",
    "label": "Pasi Timon",
    "value": "Pasi Timon"
  },
  {
    "id": "1114022001",
    "district_id": "111402",
    "label": "Bahagia",
    "value": "Bahagia"
  },
  {
    "id": "1114022002",
    "district_id": "111402",
    "label": "Sentosa",
    "value": "Sentosa"
  },
  {
    "id": "1114022003",
    "district_id": "111402",
    "label": "Dayah Baro",
    "value": "Dayah Baro"
  },
  {
    "id": "1114022004",
    "district_id": "111402",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1114022005",
    "district_id": "111402",
    "label": "Keutapang",
    "value": "Keutapang"
  },
  {
    "id": "1114022006",
    "district_id": "111402",
    "label": "Panton Makmur",
    "value": "Panton Makmur"
  },
  {
    "id": "1114022007",
    "district_id": "111402",
    "label": "Keude Krueng Sabee",
    "value": "Keude Krueng Sabee"
  },
  {
    "id": "1114022008",
    "district_id": "111402",
    "label": "Kabong",
    "value": "Kabong"
  },
  {
    "id": "1114022009",
    "district_id": "111402",
    "label": "Padang Datar",
    "value": "Padang Datar"
  },
  {
    "id": "1114022010",
    "district_id": "111402",
    "label": "Datar Luas",
    "value": "Datar Luas"
  },
  {
    "id": "1114022011",
    "district_id": "111402",
    "label": "Ranto Panyang",
    "value": "Ranto Panyang"
  },
  {
    "id": "1114022012",
    "district_id": "111402",
    "label": "Buntha",
    "value": "Buntha"
  },
  {
    "id": "1114022013",
    "district_id": "111402",
    "label": "Panggong",
    "value": "Panggong"
  },
  {
    "id": "1114022014",
    "district_id": "111402",
    "label": "Curek",
    "value": "Curek"
  },
  {
    "id": "1114022015",
    "district_id": "111402",
    "label": "Alue Tho",
    "value": "Alue Tho"
  },
  {
    "id": "1114022016",
    "district_id": "111402",
    "label": "Mon Mata",
    "value": "Mon Mata"
  },
  {
    "id": "1114022017",
    "district_id": "111402",
    "label": "Paya Seumantok",
    "value": "Paya Seumantok"
  },
  {
    "id": "1114032001",
    "district_id": "111403",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "1114032002",
    "district_id": "111403",
    "label": "Lhok Geulumpang",
    "value": "Lhok Geulumpang"
  },
  {
    "id": "1114032003",
    "district_id": "111403",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1114032004",
    "district_id": "111403",
    "label": "Gunong Meunasah",
    "value": "Gunong Meunasah"
  },
  {
    "id": "1114032005",
    "district_id": "111403",
    "label": "Sapek",
    "value": "Sapek"
  },
  {
    "id": "1114032006",
    "district_id": "111403",
    "label": "Pante Kuyun",
    "value": "Pante Kuyun"
  },
  {
    "id": "1114032007",
    "district_id": "111403",
    "label": "Glee Seubak",
    "value": "Glee Seubak"
  },
  {
    "id": "1114032008",
    "district_id": "111403",
    "label": "Gampong Baroh",
    "value": "Gampong Baroh"
  },
  {
    "id": "1114032009",
    "district_id": "111403",
    "label": "Lhok Timon",
    "value": "Lhok Timon"
  },
  {
    "id": "1114032010",
    "district_id": "111403",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1114032011",
    "district_id": "111403",
    "label": "Lhok Buya",
    "value": "Lhok Buya"
  },
  {
    "id": "1114032012",
    "district_id": "111403",
    "label": "Lhok Bot",
    "value": "Lhok Bot"
  },
  {
    "id": "1114032013",
    "district_id": "111403",
    "label": "Paya Laot",
    "value": "Paya Laot"
  },
  {
    "id": "1114042001",
    "district_id": "111404",
    "label": "Lhok Kruet",
    "value": "Lhok Kruet"
  },
  {
    "id": "1114042002",
    "district_id": "111404",
    "label": "Pulo Raya",
    "value": "Pulo Raya"
  },
  {
    "id": "1114042003",
    "district_id": "111404",
    "label": "Meunasah Kulam",
    "value": "Meunasah Kulam"
  },
  {
    "id": "1114042004",
    "district_id": "111404",
    "label": "Crak Mong",
    "value": "Crak Mong"
  },
  {
    "id": "1114042005",
    "district_id": "111404",
    "label": "Alue Gro",
    "value": "Alue Gro"
  },
  {
    "id": "1114042006",
    "district_id": "111404",
    "label": "Kuala Ligan",
    "value": "Kuala Ligan"
  },
  {
    "id": "1114042007",
    "district_id": "111404",
    "label": "Babah Nipah",
    "value": "Babah Nipah"
  },
  {
    "id": "1114042008",
    "district_id": "111404",
    "label": "Jeumpheuk",
    "value": "Jeumpheuk"
  },
  {
    "id": "1114042009",
    "district_id": "111404",
    "label": "Cot Langsat",
    "value": "Cot Langsat"
  },
  {
    "id": "1114042010",
    "district_id": "111404",
    "label": "Mata Ie",
    "value": "Mata Ie"
  },
  {
    "id": "1114042011",
    "district_id": "111404",
    "label": "Blang Monlung",
    "value": "Blang Monlung"
  },
  {
    "id": "1114042012",
    "district_id": "111404",
    "label": "Krueng No",
    "value": "Krueng No"
  },
  {
    "id": "1114042013",
    "district_id": "111404",
    "label": "Seumantok",
    "value": "Seumantok"
  },
  {
    "id": "1114042014",
    "district_id": "111404",
    "label": "Ranto Sabon",
    "value": "Ranto Sabon"
  },
  {
    "id": "1114042015",
    "district_id": "111404",
    "label": "Ligan",
    "value": "Ligan"
  },
  {
    "id": "1114042016",
    "district_id": "111404",
    "label": "Ie Jeureungeh",
    "value": "Ie Jeureungeh"
  },
  {
    "id": "1114042020",
    "district_id": "111404",
    "label": "Kuala Bakong",
    "value": "Kuala Bakong"
  },
  {
    "id": "1114042036",
    "district_id": "111404",
    "label": "Cot Punti",
    "value": "Cot Punti"
  },
  {
    "id": "1114042037",
    "district_id": "111404",
    "label": "Krueng Ayon",
    "value": "Krueng Ayon"
  },
  {
    "id": "1114052011",
    "district_id": "111405",
    "label": "Pante Cermin",
    "value": "Pante Cermin"
  },
  {
    "id": "1114052012",
    "district_id": "111405",
    "label": "Sabet",
    "value": "Sabet"
  },
  {
    "id": "1114052013",
    "district_id": "111405",
    "label": "Sango",
    "value": "Sango"
  },
  {
    "id": "1114052014",
    "district_id": "111405",
    "label": "Mareu",
    "value": "Mareu"
  },
  {
    "id": "1114052015",
    "district_id": "111405",
    "label": "Lam Asan",
    "value": "Lam Asan"
  },
  {
    "id": "1114052016",
    "district_id": "111405",
    "label": "Alue Rayeuk",
    "value": "Alue Rayeuk"
  },
  {
    "id": "1114052017",
    "district_id": "111405",
    "label": "Sapek",
    "value": "Sapek"
  },
  {
    "id": "1114052018",
    "district_id": "111405",
    "label": "Lambaroh",
    "value": "Lambaroh"
  },
  {
    "id": "1114052019",
    "district_id": "111405",
    "label": "Meudheun",
    "value": "Meudheun"
  },
  {
    "id": "1114052020",
    "district_id": "111405",
    "label": "Babah Ie",
    "value": "Babah Ie"
  },
  {
    "id": "1114052021",
    "district_id": "111405",
    "label": "Ujong Sudheun",
    "value": "Ujong Sudheun"
  },
  {
    "id": "1114052022",
    "district_id": "111405",
    "label": "Krueng Tunong",
    "value": "Krueng Tunong"
  },
  {
    "id": "1114052023",
    "district_id": "111405",
    "label": "Jambo Masi",
    "value": "Jambo Masi"
  },
  {
    "id": "1114052024",
    "district_id": "111405",
    "label": "Lamtui",
    "value": "Lamtui"
  },
  {
    "id": "1114052025",
    "district_id": "111405",
    "label": "Pasar Lamno",
    "value": "Pasar Lamno"
  },
  {
    "id": "1114052026",
    "district_id": "111405",
    "label": "Meunasah Weh",
    "value": "Meunasah Weh"
  },
  {
    "id": "1114052027",
    "district_id": "111405",
    "label": "Bak Paoh",
    "value": "Bak Paoh"
  },
  {
    "id": "1114052028",
    "district_id": "111405",
    "label": "Babah Krueng",
    "value": "Babah Krueng"
  },
  {
    "id": "1114052029",
    "district_id": "111405",
    "label": "Cot Dulang",
    "value": "Cot Dulang"
  },
  {
    "id": "1114052030",
    "district_id": "111405",
    "label": "Lamdurian",
    "value": "Lamdurian"
  },
  {
    "id": "1114052031",
    "district_id": "111405",
    "label": "Putue",
    "value": "Putue"
  },
  {
    "id": "1114052032",
    "district_id": "111405",
    "label": "Gle Putoh",
    "value": "Gle Putoh"
  },
  {
    "id": "1114052033",
    "district_id": "111405",
    "label": "Pante Keutapang",
    "value": "Pante Keutapang"
  },
  {
    "id": "1114052034",
    "district_id": "111405",
    "label": "Lamme",
    "value": "Lamme"
  },
  {
    "id": "1114052035",
    "district_id": "111405",
    "label": "Meunasah Serba",
    "value": "Meunasah Serba"
  },
  {
    "id": "1114052036",
    "district_id": "111405",
    "label": "Leupe",
    "value": "Leupe"
  },
  {
    "id": "1114052037",
    "district_id": "111405",
    "label": "Meutara",
    "value": "Meutara"
  },
  {
    "id": "1114052038",
    "district_id": "111405",
    "label": "Lhuet",
    "value": "Lhuet"
  },
  {
    "id": "1114052039",
    "district_id": "111405",
    "label": "Gle Jong",
    "value": "Gle Jong"
  },
  {
    "id": "1114052040",
    "district_id": "111405",
    "label": "Darat",
    "value": "Darat"
  },
  {
    "id": "1114052041",
    "district_id": "111405",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1114052042",
    "district_id": "111405",
    "label": "Panton Makmur",
    "value": "Panton Makmur"
  },
  {
    "id": "1114052043",
    "district_id": "111405",
    "label": "Rumpet",
    "value": "Rumpet"
  },
  {
    "id": "1114052044",
    "district_id": "111405",
    "label": "Nusa",
    "value": "Nusa"
  },
  {
    "id": "1114062001",
    "district_id": "111406",
    "label": "Keude Panga",
    "value": "Keude Panga"
  },
  {
    "id": "1114062002",
    "district_id": "111406",
    "label": "Kuta Tuha",
    "value": "Kuta Tuha"
  },
  {
    "id": "1114062003",
    "district_id": "111406",
    "label": "Tuwi Kareung",
    "value": "Tuwi Kareung"
  },
  {
    "id": "1114062004",
    "district_id": "111406",
    "label": "Ladang Baro",
    "value": "Ladang Baro"
  },
  {
    "id": "1114062005",
    "district_id": "111406",
    "label": "Panton Krueng",
    "value": "Panton Krueng"
  },
  {
    "id": "1114062006",
    "district_id": "111406",
    "label": "Gle Putoh",
    "value": "Gle Putoh"
  },
  {
    "id": "1114062007",
    "district_id": "111406",
    "label": "Alue Pande",
    "value": "Alue Pande"
  },
  {
    "id": "1114062008",
    "district_id": "111406",
    "label": "Batee Meutudong",
    "value": "Batee Meutudong"
  },
  {
    "id": "1114062009",
    "district_id": "111406",
    "label": "Gampong Harapan",
    "value": "Gampong Harapan"
  },
  {
    "id": "1114062010",
    "district_id": "111406",
    "label": "Gunong Buloh",
    "value": "Gunong Buloh"
  },
  {
    "id": "1114062011",
    "district_id": "111406",
    "label": "Gunong Meulinteung",
    "value": "Gunong Meulinteung"
  },
  {
    "id": "1114062013",
    "district_id": "111406",
    "label": "Alue Abed",
    "value": "Alue Abed"
  },
  {
    "id": "1114062014",
    "district_id": "111406",
    "label": "Gunong Mantok",
    "value": "Gunong Mantok"
  },
  {
    "id": "1114062015",
    "district_id": "111406",
    "label": "Alue Raya",
    "value": "Alue Raya"
  },
  {
    "id": "1114062016",
    "district_id": "111406",
    "label": "Alue Teungoh",
    "value": "Alue Teungoh"
  },
  {
    "id": "1114062017",
    "district_id": "111406",
    "label": "Babah Ceupan",
    "value": "Babah Ceupan"
  },
  {
    "id": "1114062018",
    "district_id": "111406",
    "label": "Tuwi Eumpeuk",
    "value": "Tuwi Eumpeuk"
  },
  {
    "id": "1114062019",
    "district_id": "111406",
    "label": "Panton Kabu",
    "value": "Panton Kabu"
  },
  {
    "id": "1114062020",
    "district_id": "111406",
    "label": "Tuwi Kayee",
    "value": "Tuwi Kayee"
  },
  {
    "id": "1114062021",
    "district_id": "111406",
    "label": "Alue Piet",
    "value": "Alue Piet"
  },
  {
    "id": "1114072001",
    "district_id": "111407",
    "label": "Ujong Muloh",
    "value": "Ujong Muloh"
  },
  {
    "id": "1114072002",
    "district_id": "111407",
    "label": "Janguet",
    "value": "Janguet"
  },
  {
    "id": "1114072003",
    "district_id": "111407",
    "label": "Meunasah Rayeuk",
    "value": "Meunasah Rayeuk"
  },
  {
    "id": "1114072004",
    "district_id": "111407",
    "label": "Mukhan",
    "value": "Mukhan"
  },
  {
    "id": "1114072005",
    "district_id": "111407",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1114072006",
    "district_id": "111407",
    "label": "Meunasah Tutong",
    "value": "Meunasah Tutong"
  },
  {
    "id": "1114072007",
    "district_id": "111407",
    "label": "Babah Dua",
    "value": "Babah Dua"
  },
  {
    "id": "1114072008",
    "district_id": "111407",
    "label": "Alue Mie",
    "value": "Alue Mie"
  },
  {
    "id": "1114072009",
    "district_id": "111407",
    "label": "Teumareum",
    "value": "Teumareum"
  },
  {
    "id": "1114072010",
    "district_id": "111407",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "1114072011",
    "district_id": "111407",
    "label": "Meudhang Ghon",
    "value": "Meudhang Ghon"
  },
  {
    "id": "1114072012",
    "district_id": "111407",
    "label": "Kareung Ateuh",
    "value": "Kareung Ateuh"
  },
  {
    "id": "1114072013",
    "district_id": "111407",
    "label": "Keude Unga",
    "value": "Keude Unga"
  },
  {
    "id": "1114072014",
    "district_id": "111407",
    "label": "Ceunamprong",
    "value": "Ceunamprong"
  },
  {
    "id": "1114082001",
    "district_id": "111408",
    "label": "Patek",
    "value": "Patek"
  },
  {
    "id": "1114082002",
    "district_id": "111408",
    "label": "Gampong Baro Patek",
    "value": "Gampong Baro Patek"
  },
  {
    "id": "1114082003",
    "district_id": "111408",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1114082004",
    "district_id": "111408",
    "label": "Pajar",
    "value": "Pajar"
  },
  {
    "id": "1114082005",
    "district_id": "111408",
    "label": "Reuntang",
    "value": "Reuntang"
  },
  {
    "id": "1114082006",
    "district_id": "111408",
    "label": "Cot Pange",
    "value": "Cot Pange"
  },
  {
    "id": "1114082007",
    "district_id": "111408",
    "label": "Arongan",
    "value": "Arongan"
  },
  {
    "id": "1114082008",
    "district_id": "111408",
    "label": "Paya Santeut",
    "value": "Paya Santeut"
  },
  {
    "id": "1114082009",
    "district_id": "111408",
    "label": "Krueng Tho",
    "value": "Krueng Tho"
  },
  {
    "id": "1114082010",
    "district_id": "111408",
    "label": "Panton Krueng",
    "value": "Panton Krueng"
  },
  {
    "id": "1114082011",
    "district_id": "111408",
    "label": "Alue Gajah",
    "value": "Alue Gajah"
  },
  {
    "id": "1114082012",
    "district_id": "111408",
    "label": "Sayeung",
    "value": "Sayeung"
  },
  {
    "id": "1114082013",
    "district_id": "111408",
    "label": "Gunong Cut",
    "value": "Gunong Cut"
  },
  {
    "id": "1114082014",
    "district_id": "111408",
    "label": "Ujong Rimba",
    "value": "Ujong Rimba"
  },
  {
    "id": "1114082015",
    "district_id": "111408",
    "label": "Lamteungoh",
    "value": "Lamteungoh"
  },
  {
    "id": "1114082016",
    "district_id": "111408",
    "label": "Gampong Baro Lamteungoh",
    "value": "Gampong Baro Lamteungoh"
  },
  {
    "id": "1114082017",
    "district_id": "111408",
    "label": "Babah Dua",
    "value": "Babah Dua"
  },
  {
    "id": "1114082018",
    "district_id": "111408",
    "label": "Masen",
    "value": "Masen"
  },
  {
    "id": "1114082019",
    "district_id": "111408",
    "label": "Teupin Asan",
    "value": "Teupin Asan"
  },
  {
    "id": "1114092001",
    "district_id": "111409",
    "label": "Pulo Tinggi",
    "value": "Pulo Tinggi"
  },
  {
    "id": "1114092002",
    "district_id": "111409",
    "label": "Alue Krueng",
    "value": "Alue Krueng"
  },
  {
    "id": "1114092003",
    "district_id": "111409",
    "label": "Pasi Teubee",
    "value": "Pasi Teubee"
  },
  {
    "id": "1114092004",
    "district_id": "111409",
    "label": "Timpleung",
    "value": "Timpleung"
  },
  {
    "id": "1114092005",
    "district_id": "111409",
    "label": "Krueng Beukah",
    "value": "Krueng Beukah"
  },
  {
    "id": "1114092006",
    "district_id": "111409",
    "label": "Tuwi Kareung",
    "value": "Tuwi Kareung"
  },
  {
    "id": "1114092007",
    "district_id": "111409",
    "label": "Lhok Guci",
    "value": "Lhok Guci"
  },
  {
    "id": "1114092008",
    "district_id": "111409",
    "label": "Tuwi Peuriya",
    "value": "Tuwi Peuriya"
  },
  {
    "id": "1114092009",
    "district_id": "111409",
    "label": "Sarah Raya",
    "value": "Sarah Raya"
  },
  {
    "id": "1114092010",
    "district_id": "111409",
    "label": "Alue Jang",
    "value": "Alue Jang"
  },
  {
    "id": "1114092011",
    "district_id": "111409",
    "label": "Ceuraceu",
    "value": "Ceuraceu"
  },
  {
    "id": "1114092012",
    "district_id": "111409",
    "label": "Alue Punti",
    "value": "Alue Punti"
  },
  {
    "id": "1114092013",
    "district_id": "111409",
    "label": "Bintah",
    "value": "Bintah"
  },
  {
    "id": "1114092014",
    "district_id": "111409",
    "label": "Buket Keumuneng",
    "value": "Buket Keumuneng"
  },
  {
    "id": "1115012003",
    "district_id": "111501",
    "label": "Lawa Batu",
    "value": "Lawa Batu"
  },
  {
    "id": "1115012004",
    "district_id": "111501",
    "label": "Jogja",
    "value": "Jogja"
  },
  {
    "id": "1115012019",
    "district_id": "111501",
    "label": "Ujong Sikuneng",
    "value": "Ujong Sikuneng"
  },
  {
    "id": "1115012020",
    "district_id": "111501",
    "label": "Blang Muko",
    "value": "Blang Muko"
  },
  {
    "id": "1115012021",
    "district_id": "111501",
    "label": "Pulo Ie",
    "value": "Pulo Ie"
  },
  {
    "id": "1115012022",
    "district_id": "111501",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1115012023",
    "district_id": "111501",
    "label": "Blang Baro",
    "value": "Blang Baro"
  },
  {
    "id": "1115012024",
    "district_id": "111501",
    "label": "Alue Ie Mameh",
    "value": "Alue Ie Mameh"
  },
  {
    "id": "1115012025",
    "district_id": "111501",
    "label": "Ujong Pasi",
    "value": "Ujong Pasi"
  },
  {
    "id": "1115012026",
    "district_id": "111501",
    "label": "Simpang Peut",
    "value": "Simpang Peut"
  },
  {
    "id": "1115012027",
    "district_id": "111501",
    "label": "Blang Teungoh",
    "value": "Blang Teungoh"
  },
  {
    "id": "1115012028",
    "district_id": "111501",
    "label": "Ujong Padang",
    "value": "Ujong Padang"
  },
  {
    "id": "1115012029",
    "district_id": "111501",
    "label": "Cot Kumbang",
    "value": "Cot Kumbang"
  },
  {
    "id": "1115012030",
    "district_id": "111501",
    "label": "Blang Bintang",
    "value": "Blang Bintang"
  },
  {
    "id": "1115012031",
    "district_id": "111501",
    "label": "Ujong Patihah",
    "value": "Ujong Patihah"
  },
  {
    "id": "1115012032",
    "district_id": "111501",
    "label": "Kuta Makmue",
    "value": "Kuta Makmue"
  },
  {
    "id": "1115012033",
    "district_id": "111501",
    "label": "Gunong Reubo",
    "value": "Gunong Reubo"
  },
  {
    "id": "1115022001",
    "district_id": "111502",
    "label": "Jeuram",
    "value": "Jeuram"
  },
  {
    "id": "1115022002",
    "district_id": "111502",
    "label": "Pante Ceureumen",
    "value": "Pante Ceureumen"
  },
  {
    "id": "1115022003",
    "district_id": "111502",
    "label": "Kuta Baro Jeuram",
    "value": "Kuta Baro Jeuram"
  },
  {
    "id": "1115022004",
    "district_id": "111502",
    "label": "Parom",
    "value": "Parom"
  },
  {
    "id": "1115022005",
    "district_id": "111502",
    "label": "Peureulak",
    "value": "Peureulak"
  },
  {
    "id": "1115022006",
    "district_id": "111502",
    "label": "Kuta Sayeh",
    "value": "Kuta Sayeh"
  },
  {
    "id": "1115022007",
    "district_id": "111502",
    "label": "Blang Baro",
    "value": "Blang Baro"
  },
  {
    "id": "1115022008",
    "district_id": "111502",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1115022009",
    "district_id": "111502",
    "label": "Alue Tho",
    "value": "Alue Tho"
  },
  {
    "id": "1115022010",
    "district_id": "111502",
    "label": "Lhok Parom",
    "value": "Lhok Parom"
  },
  {
    "id": "1115022011",
    "district_id": "111502",
    "label": "Sapek",
    "value": "Sapek"
  },
  {
    "id": "1115022012",
    "district_id": "111502",
    "label": "Alue Dodok",
    "value": "Alue Dodok"
  },
  {
    "id": "1115022013",
    "district_id": "111502",
    "label": "Cot Kumbang",
    "value": "Cot Kumbang"
  },
  {
    "id": "1115022014",
    "district_id": "111502",
    "label": "Kuta Kumbang",
    "value": "Kuta Kumbang"
  },
  {
    "id": "1115022015",
    "district_id": "111502",
    "label": "Nigan",
    "value": "Nigan"
  },
  {
    "id": "1115022016",
    "district_id": "111502",
    "label": "Gampong Cot",
    "value": "Gampong Cot"
  },
  {
    "id": "1115022017",
    "district_id": "111502",
    "label": "Blang Pateuk",
    "value": "Blang Pateuk"
  },
  {
    "id": "1115022018",
    "district_id": "111502",
    "label": "Blang Puuk Nigan",
    "value": "Blang Puuk Nigan"
  },
  {
    "id": "1115022019",
    "district_id": "111502",
    "label": "Krueng Ceh",
    "value": "Krueng Ceh"
  },
  {
    "id": "1115022020",
    "district_id": "111502",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "1115022021",
    "district_id": "111502",
    "label": "Cot Lhe-Lhe",
    "value": "Cot Lhe-Lhe"
  },
  {
    "id": "1115022022",
    "district_id": "111502",
    "label": "Rambong Rayeuk",
    "value": "Rambong Rayeuk"
  },
  {
    "id": "1115022023",
    "district_id": "111502",
    "label": "Paya Udeung",
    "value": "Paya Udeung"
  },
  {
    "id": "1115022024",
    "district_id": "111502",
    "label": "Alue Buloh",
    "value": "Alue Buloh"
  },
  {
    "id": "1115022025",
    "district_id": "111502",
    "label": "Kuta Paya",
    "value": "Kuta Paya"
  },
  {
    "id": "1115022026",
    "district_id": "111502",
    "label": "Krueng Ceuko",
    "value": "Krueng Ceuko"
  },
  {
    "id": "1115022027",
    "district_id": "111502",
    "label": "Lhok Padang",
    "value": "Lhok Padang"
  },
  {
    "id": "1115022028",
    "district_id": "111502",
    "label": "Kuta Aceh",
    "value": "Kuta Aceh"
  },
  {
    "id": "1115022029",
    "district_id": "111502",
    "label": "Krueng Mangkom",
    "value": "Krueng Mangkom"
  },
  {
    "id": "1115022030",
    "district_id": "111502",
    "label": "Latong",
    "value": "Latong"
  },
  {
    "id": "1115022031",
    "district_id": "111502",
    "label": "Paya Undan",
    "value": "Paya Undan"
  },
  {
    "id": "1115022032",
    "district_id": "111502",
    "label": "Blang Puuk Kulu",
    "value": "Blang Puuk Kulu"
  },
  {
    "id": "1115022033",
    "district_id": "111502",
    "label": "Blang Murong",
    "value": "Blang Murong"
  },
  {
    "id": "1115022034",
    "district_id": "111502",
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "id": "1115022035",
    "district_id": "111502",
    "label": "Rambong Cut",
    "value": "Rambong Cut"
  },
  {
    "id": "1115032001",
    "district_id": "111503",
    "label": "Blang Panyang",
    "value": "Blang Panyang"
  },
  {
    "id": "1115032002",
    "district_id": "111503",
    "label": "Meugat Meh",
    "value": "Meugat Meh"
  },
  {
    "id": "1115032003",
    "district_id": "111503",
    "label": "Lhok Pange",
    "value": "Lhok Pange"
  },
  {
    "id": "1115032004",
    "district_id": "111503",
    "label": "Blang Lango",
    "value": "Blang Lango"
  },
  {
    "id": "1115032005",
    "district_id": "111503",
    "label": "Tuwi Meuleusong",
    "value": "Tuwi Meuleusong"
  },
  {
    "id": "1115032006",
    "district_id": "111503",
    "label": "Blang Geudong",
    "value": "Blang Geudong"
  },
  {
    "id": "1115032007",
    "district_id": "111503",
    "label": "Blang Teungku",
    "value": "Blang Teungku"
  },
  {
    "id": "1115032008",
    "district_id": "111503",
    "label": "Sawang Mane",
    "value": "Sawang Mane"
  },
  {
    "id": "1115032009",
    "district_id": "111503",
    "label": "Keude Neulop",
    "value": "Keude Neulop"
  },
  {
    "id": "1115032010",
    "district_id": "111503",
    "label": "Kila",
    "value": "Kila"
  },
  {
    "id": "1115032011",
    "district_id": "111503",
    "label": "Kandeh",
    "value": "Kandeh"
  },
  {
    "id": "1115032012",
    "district_id": "111503",
    "label": "Cot Gud",
    "value": "Cot Gud"
  },
  {
    "id": "1115032013",
    "district_id": "111503",
    "label": "Kabu Baroh",
    "value": "Kabu Baroh"
  },
  {
    "id": "1115032014",
    "district_id": "111503",
    "label": "Kabu Tunong",
    "value": "Kabu Tunong"
  },
  {
    "id": "1115032015",
    "district_id": "111503",
    "label": "Keude Linteung",
    "value": "Keude Linteung"
  },
  {
    "id": "1115032016",
    "district_id": "111503",
    "label": "Meurandeh Suak",
    "value": "Meurandeh Suak"
  },
  {
    "id": "1115032017",
    "district_id": "111503",
    "label": "Uteun Pulo",
    "value": "Uteun Pulo"
  },
  {
    "id": "1115032018",
    "district_id": "111503",
    "label": "Cot Manyang",
    "value": "Cot Manyang"
  },
  {
    "id": "1115032019",
    "district_id": "111503",
    "label": "Pulo Teungoh",
    "value": "Pulo Teungoh"
  },
  {
    "id": "1115032020",
    "district_id": "111503",
    "label": "Cot Dirui",
    "value": "Cot Dirui"
  },
  {
    "id": "1115032021",
    "district_id": "111503",
    "label": "Cot Teuku Dek",
    "value": "Cot Teuku Dek"
  },
  {
    "id": "1115032022",
    "district_id": "111503",
    "label": "Sapeng",
    "value": "Sapeng"
  },
  {
    "id": "1115032023",
    "district_id": "111503",
    "label": "Peuleukung",
    "value": "Peuleukung"
  },
  {
    "id": "1115032024",
    "district_id": "111503",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1115032025",
    "district_id": "111503",
    "label": "Blang Preh",
    "value": "Blang Preh"
  },
  {
    "id": "1115032026",
    "district_id": "111503",
    "label": "Blang Ara Keude",
    "value": "Blang Ara Keude"
  },
  {
    "id": "1115032027",
    "district_id": "111503",
    "label": "Blang Ara Gampong",
    "value": "Blang Ara Gampong"
  },
  {
    "id": "1115032028",
    "district_id": "111503",
    "label": "Ie Beudoh",
    "value": "Ie Beudoh"
  },
  {
    "id": "1115032029",
    "district_id": "111503",
    "label": "Blang Bayu",
    "value": "Blang Bayu"
  },
  {
    "id": "1115032030",
    "district_id": "111503",
    "label": "Mon Bateung",
    "value": "Mon Bateung"
  },
  {
    "id": "1115032031",
    "district_id": "111503",
    "label": "Suak Peureubong",
    "value": "Suak Peureubong"
  },
  {
    "id": "1115032032",
    "district_id": "111503",
    "label": "Krueng Kulu",
    "value": "Krueng Kulu"
  },
  {
    "id": "1115032033",
    "district_id": "111503",
    "label": "Cot Punti",
    "value": "Cot Punti"
  },
  {
    "id": "1115032034",
    "district_id": "111503",
    "label": "Lhok Mesjid",
    "value": "Lhok Mesjid"
  },
  {
    "id": "1115042001",
    "district_id": "111504",
    "label": "Blang Seunong",
    "value": "Blang Seunong"
  },
  {
    "id": "1115042002",
    "district_id": "111504",
    "label": "Pante Ara",
    "value": "Pante Ara"
  },
  {
    "id": "1115042003",
    "district_id": "111504",
    "label": "Gunong Nagan",
    "value": "Gunong Nagan"
  },
  {
    "id": "1115042004",
    "district_id": "111504",
    "label": "Tuwi Bunta",
    "value": "Tuwi Bunta"
  },
  {
    "id": "1115042005",
    "district_id": "111504",
    "label": "Blang Mesjid",
    "value": "Blang Mesjid"
  },
  {
    "id": "1115042006",
    "district_id": "111504",
    "label": "Blang Neuang",
    "value": "Blang Neuang"
  },
  {
    "id": "1115042007",
    "district_id": "111504",
    "label": "Panton Bayam",
    "value": "Panton Bayam"
  },
  {
    "id": "1115042008",
    "district_id": "111504",
    "label": "Blang Baro Pulo Raga",
    "value": "Blang Baro Pulo Raga"
  },
  {
    "id": "1115042009",
    "district_id": "111504",
    "label": "Krueng Cut",
    "value": "Krueng Cut"
  },
  {
    "id": "1115042010",
    "district_id": "111504",
    "label": "Blang Leumak",
    "value": "Blang Leumak"
  },
  {
    "id": "1115042011",
    "district_id": "111504",
    "label": "Babah Krueng",
    "value": "Babah Krueng"
  },
  {
    "id": "1115042012",
    "district_id": "111504",
    "label": "Blang Seumot",
    "value": "Blang Seumot"
  },
  {
    "id": "1115042013",
    "district_id": "111504",
    "label": "Lhok Seumot",
    "value": "Lhok Seumot"
  },
  {
    "id": "1115042014",
    "district_id": "111504",
    "label": "Meunasah Pante",
    "value": "Meunasah Pante"
  },
  {
    "id": "1115042015",
    "district_id": "111504",
    "label": "Kuta Jeumpa",
    "value": "Kuta Jeumpa"
  },
  {
    "id": "1115042016",
    "district_id": "111504",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1115042017",
    "district_id": "111504",
    "label": "Keude Seumot",
    "value": "Keude Seumot"
  },
  {
    "id": "1115042018",
    "district_id": "111504",
    "label": "Blang Baro Rambong",
    "value": "Blang Baro Rambong"
  },
  {
    "id": "1115042019",
    "district_id": "111504",
    "label": "Ujong Blang",
    "value": "Ujong Blang"
  },
  {
    "id": "1115042020",
    "district_id": "111504",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1115042021",
    "district_id": "111504",
    "label": "Meunasah Dayah",
    "value": "Meunasah Dayah"
  },
  {
    "id": "1115042022",
    "district_id": "111504",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1115042023",
    "district_id": "111504",
    "label": "Kulam Jeureuneh",
    "value": "Kulam Jeureuneh"
  },
  {
    "id": "1115042028",
    "district_id": "111504",
    "label": "Bumi Sari",
    "value": "Bumi Sari"
  },
  {
    "id": "1115052012",
    "district_id": "111505",
    "label": "Lamie",
    "value": "Lamie"
  },
  {
    "id": "1115052013",
    "district_id": "111505",
    "label": "Kuta Trieng",
    "value": "Kuta Trieng"
  },
  {
    "id": "1115052014",
    "district_id": "111505",
    "label": "Geulanggang Gajah",
    "value": "Geulanggang Gajah"
  },
  {
    "id": "1115052015",
    "district_id": "111505",
    "label": "Kayee Unoe",
    "value": "Kayee Unoe"
  },
  {
    "id": "1115052016",
    "district_id": "111505",
    "label": "Blang Baro",
    "value": "Blang Baro"
  },
  {
    "id": "1115052017",
    "district_id": "111505",
    "label": "Alue Bilie",
    "value": "Alue Bilie"
  },
  {
    "id": "1115052018",
    "district_id": "111505",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1115052019",
    "district_id": "111505",
    "label": "Gunong Cut",
    "value": "Gunong Cut"
  },
  {
    "id": "1115052020",
    "district_id": "111505",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1115052021",
    "district_id": "111505",
    "label": "Tuwi Buya",
    "value": "Tuwi Buya"
  },
  {
    "id": "1115052022",
    "district_id": "111505",
    "label": "Ujong Lamie",
    "value": "Ujong Lamie"
  },
  {
    "id": "1115052023",
    "district_id": "111505",
    "label": "Suak Palembang",
    "value": "Suak Palembang"
  },
  {
    "id": "1115052024",
    "district_id": "111505",
    "label": "Alue Waki",
    "value": "Alue Waki"
  },
  {
    "id": "1115052025",
    "district_id": "111505",
    "label": "Alue Geutah",
    "value": "Alue Geutah"
  },
  {
    "id": "1115052026",
    "district_id": "111505",
    "label": "Krueng Alem",
    "value": "Krueng Alem"
  },
  {
    "id": "1115052027",
    "district_id": "111505",
    "label": "Alue Rambot",
    "value": "Alue Rambot"
  },
  {
    "id": "1115052028",
    "district_id": "111505",
    "label": "Krueng Seumayam",
    "value": "Krueng Seumayam"
  },
  {
    "id": "1115052029",
    "district_id": "111505",
    "label": "Pulo Teungoh",
    "value": "Pulo Teungoh"
  },
  {
    "id": "1115052030",
    "district_id": "111505",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1115052031",
    "district_id": "111505",
    "label": "Kuala Seumayam",
    "value": "Kuala Seumayam"
  },
  {
    "id": "1115052032",
    "district_id": "111505",
    "label": "Pulo Ie",
    "value": "Pulo Ie"
  },
  {
    "id": "1115052033",
    "district_id": "111505",
    "label": "Pulo Kruet",
    "value": "Pulo Kruet"
  },
  {
    "id": "1115052034",
    "district_id": "111505",
    "label": "Alue Kuyun",
    "value": "Alue Kuyun"
  },
  {
    "id": "1115052035",
    "district_id": "111505",
    "label": "Alue Raya",
    "value": "Alue Raya"
  },
  {
    "id": "1115052036",
    "district_id": "111505",
    "label": "Alue Jampak",
    "value": "Alue Jampak"
  },
  {
    "id": "1115052037",
    "district_id": "111505",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "1115052038",
    "district_id": "111505",
    "label": "Simpang Dua",
    "value": "Simpang Dua"
  },
  {
    "id": "1115052039",
    "district_id": "111505",
    "label": "Sidojadi",
    "value": "Sidojadi"
  },
  {
    "id": "1115052040",
    "district_id": "111505",
    "label": "Serba Jadi",
    "value": "Serba Jadi"
  },
  {
    "id": "1115052041",
    "district_id": "111505",
    "label": "Serba Guna",
    "value": "Serba Guna"
  },
  {
    "id": "1115052042",
    "district_id": "111505",
    "label": "Simpang Deli Kilang",
    "value": "Simpang Deli Kilang"
  },
  {
    "id": "1115052043",
    "district_id": "111505",
    "label": "Panton Bayu",
    "value": "Panton Bayu"
  },
  {
    "id": "1115052044",
    "district_id": "111505",
    "label": "Ujong Tanjong",
    "value": "Ujong Tanjong"
  },
  {
    "id": "1115052045",
    "district_id": "111505",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "1115052046",
    "district_id": "111505",
    "label": "Ladang Baro",
    "value": "Ladang Baro"
  },
  {
    "id": "1115052047",
    "district_id": "111505",
    "label": "Alue Bateung Brok",
    "value": "Alue Bateung Brok"
  },
  {
    "id": "1115052048",
    "district_id": "111505",
    "label": "Simpang Deli Kampong",
    "value": "Simpang Deli Kampong"
  },
  {
    "id": "1115052049",
    "district_id": "111505",
    "label": "Blang Luah",
    "value": "Blang Luah"
  },
  {
    "id": "1115052050",
    "district_id": "111505",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1115052051",
    "district_id": "111505",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "1115062001",
    "district_id": "111506",
    "label": "Lueng Baro",
    "value": "Lueng Baro"
  },
  {
    "id": "1115062002",
    "district_id": "111506",
    "label": "Alue Kambuk",
    "value": "Alue Kambuk"
  },
  {
    "id": "1115062003",
    "district_id": "111506",
    "label": "Kuta Padang",
    "value": "Kuta Padang"
  },
  {
    "id": "1115062004",
    "district_id": "111506",
    "label": "Suak Bilie",
    "value": "Suak Bilie"
  },
  {
    "id": "1115062005",
    "district_id": "111506",
    "label": "Macah",
    "value": "Macah"
  },
  {
    "id": "1115062006",
    "district_id": "111506",
    "label": "Seumambek",
    "value": "Seumambek"
  },
  {
    "id": "1115062007",
    "district_id": "111506",
    "label": "Cot Kuta",
    "value": "Cot Kuta"
  },
  {
    "id": "1115062008",
    "district_id": "111506",
    "label": "Kabu Blang Sapek",
    "value": "Kabu Blang Sapek"
  },
  {
    "id": "1115062009",
    "district_id": "111506",
    "label": "Cot Peuradi",
    "value": "Cot Peuradi"
  },
  {
    "id": "1115062010",
    "district_id": "111506",
    "label": "Blang Sapek",
    "value": "Blang Sapek"
  },
  {
    "id": "1115062011",
    "district_id": "111506",
    "label": "Lhok Beutong",
    "value": "Lhok Beutong"
  },
  {
    "id": "1115062012",
    "district_id": "111506",
    "label": "Gampong Teungoh",
    "value": "Gampong Teungoh"
  },
  {
    "id": "1115062013",
    "district_id": "111506",
    "label": "Lhok Rameuan",
    "value": "Lhok Rameuan"
  },
  {
    "id": "1115062014",
    "district_id": "111506",
    "label": "Blang Muling",
    "value": "Blang Muling"
  },
  {
    "id": "1115062015",
    "district_id": "111506",
    "label": "Krak Tampai",
    "value": "Krak Tampai"
  },
  {
    "id": "1115062016",
    "district_id": "111506",
    "label": "Meureubo",
    "value": "Meureubo"
  },
  {
    "id": "1115062017",
    "district_id": "111506",
    "label": "Kuta Baro Blang Muling",
    "value": "Kuta Baro Blang Muling"
  },
  {
    "id": "1115062018",
    "district_id": "111506",
    "label": "Alue Peusaja",
    "value": "Alue Peusaja"
  },
  {
    "id": "1115062019",
    "district_id": "111506",
    "label": "Alue Gajah",
    "value": "Alue Gajah"
  },
  {
    "id": "1115072001",
    "district_id": "111507",
    "label": "Suak Puntong",
    "value": "Suak Puntong"
  },
  {
    "id": "1115072002",
    "district_id": "111507",
    "label": "Gampong Lhok",
    "value": "Gampong Lhok"
  },
  {
    "id": "1115072003",
    "district_id": "111507",
    "label": "Kuala Baro",
    "value": "Kuala Baro"
  },
  {
    "id": "1115072004",
    "district_id": "111507",
    "label": "Padang Rubek",
    "value": "Padang Rubek"
  },
  {
    "id": "1115072005",
    "district_id": "111507",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "1115072006",
    "district_id": "111507",
    "label": "Langkak",
    "value": "Langkak"
  },
  {
    "id": "1115072007",
    "district_id": "111507",
    "label": "Kuala Tuha",
    "value": "Kuala Tuha"
  },
  {
    "id": "1115072008",
    "district_id": "111507",
    "label": "Kubang Gajah",
    "value": "Kubang Gajah"
  },
  {
    "id": "1115072009",
    "district_id": "111507",
    "label": "Kuala Trang",
    "value": "Kuala Trang"
  },
  {
    "id": "1115072010",
    "district_id": "111507",
    "label": "Cot Rambong",
    "value": "Cot Rambong"
  },
  {
    "id": "1115072011",
    "district_id": "111507",
    "label": "Padang Panyang",
    "value": "Padang Panyang"
  },
  {
    "id": "1115072012",
    "district_id": "111507",
    "label": "Arongan",
    "value": "Arongan"
  },
  {
    "id": "1115072013",
    "district_id": "111507",
    "label": "Jati Rejo",
    "value": "Jati Rejo"
  },
  {
    "id": "1115072014",
    "district_id": "111507",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1115072015",
    "district_id": "111507",
    "label": "Lueng Teuku Ben",
    "value": "Lueng Teuku Ben"
  },
  {
    "id": "1115072016",
    "district_id": "111507",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1115082001",
    "district_id": "111508",
    "label": "Alue Bata",
    "value": "Alue Bata"
  },
  {
    "id": "1115082002",
    "district_id": "111508",
    "label": "Gunong Sapek",
    "value": "Gunong Sapek"
  },
  {
    "id": "1115082003",
    "district_id": "111508",
    "label": "Sarah Mantok",
    "value": "Sarah Mantok"
  },
  {
    "id": "1115082004",
    "district_id": "111508",
    "label": "Pasie Luah",
    "value": "Pasie Luah"
  },
  {
    "id": "1115082005",
    "district_id": "111508",
    "label": "Gunong Geulugo",
    "value": "Gunong Geulugo"
  },
  {
    "id": "1115082006",
    "district_id": "111508",
    "label": "Gunong Kupok",
    "value": "Gunong Kupok"
  },
  {
    "id": "1115082007",
    "district_id": "111508",
    "label": "Gunong Pungki",
    "value": "Gunong Pungki"
  },
  {
    "id": "1115082008",
    "district_id": "111508",
    "label": "Alue Siron",
    "value": "Alue Siron"
  },
  {
    "id": "1115082009",
    "district_id": "111508",
    "label": "Cot Mue",
    "value": "Cot Mue"
  },
  {
    "id": "1115082010",
    "district_id": "111508",
    "label": "Babah Rot",
    "value": "Babah Rot"
  },
  {
    "id": "1115082011",
    "district_id": "111508",
    "label": "Babah Dua",
    "value": "Babah Dua"
  },
  {
    "id": "1115082012",
    "district_id": "111508",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "1115082013",
    "district_id": "111508",
    "label": "Simpang Jaya",
    "value": "Simpang Jaya"
  },
  {
    "id": "1115082014",
    "district_id": "111508",
    "label": "Sumber Daya",
    "value": "Sumber Daya"
  },
  {
    "id": "1115082015",
    "district_id": "111508",
    "label": "Rantau Selamat",
    "value": "Rantau Selamat"
  },
  {
    "id": "1115082016",
    "district_id": "111508",
    "label": "Alue Seupeung",
    "value": "Alue Seupeung"
  },
  {
    "id": "1115082017",
    "district_id": "111508",
    "label": "Krueng Itam",
    "value": "Krueng Itam"
  },
  {
    "id": "1115082018",
    "district_id": "111508",
    "label": "Alue Gajah",
    "value": "Alue Gajah"
  },
  {
    "id": "1115082019",
    "district_id": "111508",
    "label": "Alue Labu",
    "value": "Alue Labu"
  },
  {
    "id": "1115082020",
    "district_id": "111508",
    "label": "Gapa Garu",
    "value": "Gapa Garu"
  },
  {
    "id": "1115082021",
    "district_id": "111508",
    "label": "Kuala Tadu",
    "value": "Kuala Tadu"
  },
  {
    "id": "1115082022",
    "district_id": "111508",
    "label": "Cot Mee",
    "value": "Cot Mee"
  },
  {
    "id": "1115092001",
    "district_id": "111509",
    "label": "Kuala Tripa",
    "value": "Kuala Tripa"
  },
  {
    "id": "1115092002",
    "district_id": "111509",
    "label": "Babah Lueng",
    "value": "Babah Lueng"
  },
  {
    "id": "1115092003",
    "district_id": "111509",
    "label": "Drien Tujoh",
    "value": "Drien Tujoh"
  },
  {
    "id": "1115092004",
    "district_id": "111509",
    "label": "Lueng Keubeu Jagat",
    "value": "Lueng Keubeu Jagat"
  },
  {
    "id": "1115092005",
    "district_id": "111509",
    "label": "Kabu",
    "value": "Kabu"
  },
  {
    "id": "1115092006",
    "district_id": "111509",
    "label": "Pasie Keube Dom",
    "value": "Pasie Keube Dom"
  },
  {
    "id": "1115092007",
    "district_id": "111509",
    "label": "Neubok Yee PK",
    "value": "Neubok Yee PK"
  },
  {
    "id": "1115092008",
    "district_id": "111509",
    "label": "Neubok Yee PP",
    "value": "Neubok Yee PP"
  },
  {
    "id": "1115092009",
    "district_id": "111509",
    "label": "Mon Dua",
    "value": "Mon Dua"
  },
  {
    "id": "1115092010",
    "district_id": "111509",
    "label": "Ujong Krueng",
    "value": "Ujong Krueng"
  },
  {
    "id": "1115092011",
    "district_id": "111509",
    "label": "Panton Pange",
    "value": "Panton Pange"
  },
  {
    "id": "1115102001",
    "district_id": "111510",
    "label": "Blang Puuk",
    "value": "Blang Puuk"
  },
  {
    "id": "1115102002",
    "district_id": "111510",
    "label": "Babah Suak",
    "value": "Babah Suak"
  },
  {
    "id": "1115102003",
    "district_id": "111510",
    "label": "Kuta Teungoh",
    "value": "Kuta Teungoh"
  },
  {
    "id": "1115102004",
    "district_id": "111510",
    "label": "Blang Meurandeh",
    "value": "Blang Meurandeh"
  },
  {
    "id": "1116012001",
    "district_id": "111601",
    "label": "Simpang Lhee",
    "value": "Simpang Lhee"
  },
  {
    "id": "1116012002",
    "district_id": "111601",
    "label": "Buket Panyang Sa",
    "value": "Buket Panyang Sa"
  },
  {
    "id": "1116012003",
    "district_id": "111601",
    "label": "Buket Panyang Dua",
    "value": "Buket Panyang Dua"
  },
  {
    "id": "1116012004",
    "district_id": "111601",
    "label": "Seuneubok Baru",
    "value": "Seuneubok Baru"
  },
  {
    "id": "1116012005",
    "district_id": "111601",
    "label": "Sampaimah",
    "value": "Sampaimah"
  },
  {
    "id": "1116012006",
    "district_id": "111601",
    "label": "Krueng Sikajang",
    "value": "Krueng Sikajang"
  },
  {
    "id": "1116012007",
    "district_id": "111601",
    "label": "Seuneubok Punti",
    "value": "Seuneubok Punti"
  },
  {
    "id": "1116012008",
    "district_id": "111601",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1116012009",
    "district_id": "111601",
    "label": "Paya Ketenggar",
    "value": "Paya Ketenggar"
  },
  {
    "id": "1116012010",
    "district_id": "111601",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1116012011",
    "district_id": "111601",
    "label": "Matang Ara Aceh",
    "value": "Matang Ara Aceh"
  },
  {
    "id": "1116012012",
    "district_id": "111601",
    "label": "Ie Bintah",
    "value": "Ie Bintah"
  },
  {
    "id": "1116012013",
    "district_id": "111601",
    "label": "Geudham",
    "value": "Geudham"
  },
  {
    "id": "1116012014",
    "district_id": "111601",
    "label": "Geulanggang Merak",
    "value": "Geulanggang Merak"
  },
  {
    "id": "1116012015",
    "district_id": "111601",
    "label": "Bukit Paya",
    "value": "Bukit Paya"
  },
  {
    "id": "1116012016",
    "district_id": "111601",
    "label": "Dagang Setia",
    "value": "Dagang Setia"
  },
  {
    "id": "1116012017",
    "district_id": "111601",
    "label": "Meunasah Paya",
    "value": "Meunasah Paya"
  },
  {
    "id": "1116012018",
    "district_id": "111601",
    "label": "Benteng Anyer",
    "value": "Benteng Anyer"
  },
  {
    "id": "1116012019",
    "district_id": "111601",
    "label": "Tanjung Neraca",
    "value": "Tanjung Neraca"
  },
  {
    "id": "1116012020",
    "district_id": "111601",
    "label": "Lueng Manyo",
    "value": "Lueng Manyo"
  },
  {
    "id": "1116012021",
    "district_id": "111601",
    "label": "Tualang Baro",
    "value": "Tualang Baro"
  },
  {
    "id": "1116012022",
    "district_id": "111601",
    "label": "Lhok Medang Ara",
    "value": "Lhok Medang Ara"
  },
  {
    "id": "1116012023",
    "district_id": "111601",
    "label": "Kaseh Sayang",
    "value": "Kaseh Sayang"
  },
  {
    "id": "1116012024",
    "district_id": "111601",
    "label": "Seuneubok Pidie",
    "value": "Seuneubok Pidie"
  },
  {
    "id": "1116012025",
    "district_id": "111601",
    "label": "Matang Cincin",
    "value": "Matang Cincin"
  },
  {
    "id": "1116012026",
    "district_id": "111601",
    "label": "Matang Ara Jawa",
    "value": "Matang Ara Jawa"
  },
  {
    "id": "1116012027",
    "district_id": "111601",
    "label": "Meurandeh",
    "value": "Meurandeh"
  },
  {
    "id": "1116012028",
    "district_id": "111601",
    "label": "Raja Tuha",
    "value": "Raja Tuha"
  },
  {
    "id": "1116012029",
    "district_id": "111601",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1116012030",
    "district_id": "111601",
    "label": "Alue Ie Puteh",
    "value": "Alue Ie Puteh"
  },
  {
    "id": "1116012031",
    "district_id": "111601",
    "label": "Alue Sentang",
    "value": "Alue Sentang"
  },
  {
    "id": "1116012032",
    "district_id": "111601",
    "label": "Seuneubok Cantek",
    "value": "Seuneubok Cantek"
  },
  {
    "id": "1116012033",
    "district_id": "111601",
    "label": "Sapta Marga",
    "value": "Sapta Marga"
  },
  {
    "id": "1116012034",
    "district_id": "111601",
    "label": "Paya Baru",
    "value": "Paya Baru"
  },
  {
    "id": "1116012035",
    "district_id": "111601",
    "label": "Bandung Jaya",
    "value": "Bandung Jaya"
  },
  {
    "id": "1116012036",
    "district_id": "111601",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "1116022001",
    "district_id": "111602",
    "label": "Mesjid Sungai Iyu",
    "value": "Mesjid Sungai Iyu"
  },
  {
    "id": "1116022002",
    "district_id": "111602",
    "label": "Alur Cantik",
    "value": "Alur Cantik"
  },
  {
    "id": "1116022003",
    "district_id": "111602",
    "label": "Tanjung Lipat I",
    "value": "Tanjung Lipat I"
  },
  {
    "id": "1116022004",
    "district_id": "111602",
    "label": "Tanjung Lipat II",
    "value": "Tanjung Lipat II"
  },
  {
    "id": "1116022005",
    "district_id": "111602",
    "label": "Seuneubok Dalam Mesjid",
    "value": "Seuneubok Dalam Mesjid"
  },
  {
    "id": "1116022006",
    "district_id": "111602",
    "label": "Bandar Baru",
    "value": "Bandar Baru"
  },
  {
    "id": "1116022007",
    "district_id": "111602",
    "label": "Cinta Raja",
    "value": "Cinta Raja"
  },
  {
    "id": "1116022008",
    "district_id": "111602",
    "label": "Kuala Genting",
    "value": "Kuala Genting"
  },
  {
    "id": "1116022009",
    "district_id": "111602",
    "label": "Bandar Khalifah",
    "value": "Bandar Khalifah"
  },
  {
    "id": "1116022010",
    "district_id": "111602",
    "label": "Suka Mulia Bendahara",
    "value": "Suka Mulia Bendahara"
  },
  {
    "id": "1116022011",
    "district_id": "111602",
    "label": "Seuneubok Aceh",
    "value": "Seuneubok Aceh"
  },
  {
    "id": "1116022012",
    "district_id": "111602",
    "label": "Tengku Tinggi",
    "value": "Tengku Tinggi"
  },
  {
    "id": "1116022013",
    "district_id": "111602",
    "label": "Perkebunan Sungai Iyu",
    "value": "Perkebunan Sungai Iyu"
  },
  {
    "id": "1116022014",
    "district_id": "111602",
    "label": "Marlempang",
    "value": "Marlempang"
  },
  {
    "id": "1116022015",
    "district_id": "111602",
    "label": "Balai",
    "value": "Balai"
  },
  {
    "id": "1116022016",
    "district_id": "111602",
    "label": "Lubuk Batil",
    "value": "Lubuk Batil"
  },
  {
    "id": "1116022017",
    "district_id": "111602",
    "label": "Lambung Blang",
    "value": "Lambung Blang"
  },
  {
    "id": "1116022018",
    "district_id": "111602",
    "label": "Tanjung Parit",
    "value": "Tanjung Parit"
  },
  {
    "id": "1116022019",
    "district_id": "111602",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1116022020",
    "district_id": "111602",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "1116022021",
    "district_id": "111602",
    "label": "Tumpok Tengoh",
    "value": "Tumpok Tengoh"
  },
  {
    "id": "1116022029",
    "district_id": "111602",
    "label": "Upah",
    "value": "Upah"
  },
  {
    "id": "1116022030",
    "district_id": "111602",
    "label": "Perkebunan Upah",
    "value": "Perkebunan Upah"
  },
  {
    "id": "1116022031",
    "district_id": "111602",
    "label": "Matang Tepah",
    "value": "Matang Tepah"
  },
  {
    "id": "1116022035",
    "district_id": "111602",
    "label": "Seuneubok Dalam Upah",
    "value": "Seuneubok Dalam Upah"
  },
  {
    "id": "1116022036",
    "district_id": "111602",
    "label": "Mesjid Bendahara",
    "value": "Mesjid Bendahara"
  },
  {
    "id": "1116022037",
    "district_id": "111602",
    "label": "Teluk Kemiri",
    "value": "Teluk Kemiri"
  },
  {
    "id": "1116022038",
    "district_id": "111602",
    "label": "Teluk Kepayang",
    "value": "Teluk Kepayang"
  },
  {
    "id": "1116022039",
    "district_id": "111602",
    "label": "Teluk Halban",
    "value": "Teluk Halban"
  },
  {
    "id": "1116022040",
    "district_id": "111602",
    "label": "Rantau Pakam",
    "value": "Rantau Pakam"
  },
  {
    "id": "1116022041",
    "district_id": "111602",
    "label": "Tanjung Binjai",
    "value": "Tanjung Binjai"
  },
  {
    "id": "1116022042",
    "district_id": "111602",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1116022043",
    "district_id": "111602",
    "label": "Kuala Penaga",
    "value": "Kuala Penaga"
  },
  {
    "id": "1116032001",
    "district_id": "111603",
    "label": "Kesehatan",
    "value": "Kesehatan"
  },
  {
    "id": "1116032002",
    "district_id": "111603",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1116032006",
    "district_id": "111603",
    "label": "Menanggini",
    "value": "Menanggini"
  },
  {
    "id": "1116032007",
    "district_id": "111603",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "1116032009",
    "district_id": "111603",
    "label": "Bundar",
    "value": "Bundar"
  },
  {
    "id": "1116032011",
    "district_id": "111603",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "1116032012",
    "district_id": "111603",
    "label": "Tanah Terban",
    "value": "Tanah Terban"
  },
  {
    "id": "1116032013",
    "district_id": "111603",
    "label": "Air Tenang",
    "value": "Air Tenang"
  },
  {
    "id": "1116032014",
    "district_id": "111603",
    "label": "Johar",
    "value": "Johar"
  },
  {
    "id": "1116032015",
    "district_id": "111603",
    "label": "Tupah",
    "value": "Tupah"
  },
  {
    "id": "1116032016",
    "district_id": "111603",
    "label": "Banai",
    "value": "Banai"
  },
  {
    "id": "1116032017",
    "district_id": "111603",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1116032018",
    "district_id": "111603",
    "label": "Medang Ara",
    "value": "Medang Ara"
  },
  {
    "id": "1116032019",
    "district_id": "111603",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1116032020",
    "district_id": "111603",
    "label": "Alur Selalas",
    "value": "Alur Selalas"
  },
  {
    "id": "1116032021",
    "district_id": "111603",
    "label": "Paya Tampah",
    "value": "Paya Tampah"
  },
  {
    "id": "1116032022",
    "district_id": "111603",
    "label": "Alur Baung",
    "value": "Alur Baung"
  },
  {
    "id": "1116032023",
    "district_id": "111603",
    "label": "Kebun Tanah Terban",
    "value": "Kebun Tanah Terban"
  },
  {
    "id": "1116032024",
    "district_id": "111603",
    "label": "Kebun Medang Ara",
    "value": "Kebun Medang Ara"
  },
  {
    "id": "1116032025",
    "district_id": "111603",
    "label": "Kebun Afdeling Seleleh",
    "value": "Kebun Afdeling Seleleh"
  },
  {
    "id": "1116032026",
    "district_id": "111603",
    "label": "Paya Awe",
    "value": "Paya Awe"
  },
  {
    "id": "1116032027",
    "district_id": "111603",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1116032028",
    "district_id": "111603",
    "label": "Paya Kulbi",
    "value": "Paya Kulbi"
  },
  {
    "id": "1116032029",
    "district_id": "111603",
    "label": "Alur Bemban",
    "value": "Alur Bemban"
  },
  {
    "id": "1116032030",
    "district_id": "111603",
    "label": "Paya Meta",
    "value": "Paya Meta"
  },
  {
    "id": "1116032031",
    "district_id": "111603",
    "label": "Tanjung Seumantoh",
    "value": "Tanjung Seumantoh"
  },
  {
    "id": "1116032032",
    "district_id": "111603",
    "label": "Bukit Keranji",
    "value": "Bukit Keranji"
  },
  {
    "id": "1116032033",
    "district_id": "111603",
    "label": "Kebun Tanjung Seumantoh",
    "value": "Kebun Tanjung Seumantoh"
  },
  {
    "id": "1116032034",
    "district_id": "111603",
    "label": "Bukit Panjang",
    "value": "Bukit Panjang"
  },
  {
    "id": "1116032035",
    "district_id": "111603",
    "label": "Alue Lhok",
    "value": "Alue Lhok"
  },
  {
    "id": "1116032036",
    "district_id": "111603",
    "label": "Sukajadi Paya Bujok",
    "value": "Sukajadi Paya Bujok"
  },
  {
    "id": "1116042002",
    "district_id": "111604",
    "label": "Pekan Seruway",
    "value": "Pekan Seruway"
  },
  {
    "id": "1116042003",
    "district_id": "111604",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1116042004",
    "district_id": "111604",
    "label": "Pantai Balai",
    "value": "Pantai Balai"
  },
  {
    "id": "1116042005",
    "district_id": "111604",
    "label": "Padang Langgis",
    "value": "Padang Langgis"
  },
  {
    "id": "1116042006",
    "district_id": "111604",
    "label": "Sukaramai Satu",
    "value": "Sukaramai Satu"
  },
  {
    "id": "1116042007",
    "district_id": "111604",
    "label": "Sukaramai Dua",
    "value": "Sukaramai Dua"
  },
  {
    "id": "1116042008",
    "district_id": "111604",
    "label": "Perkebunan Seruway",
    "value": "Perkebunan Seruway"
  },
  {
    "id": "1116042009",
    "district_id": "111604",
    "label": "Gedung Biara",
    "value": "Gedung Biara"
  },
  {
    "id": "1116042010",
    "district_id": "111604",
    "label": "Alur Alim",
    "value": "Alur Alim"
  },
  {
    "id": "1116042011",
    "district_id": "111604",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1116042012",
    "district_id": "111604",
    "label": "Matang Sentang",
    "value": "Matang Sentang"
  },
  {
    "id": "1116042013",
    "district_id": "111604",
    "label": "Air Masin",
    "value": "Air Masin"
  },
  {
    "id": "1116042014",
    "district_id": "111604",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1116042015",
    "district_id": "111604",
    "label": "Perk. Gedung Biara",
    "value": "Perk. Gedung Biara"
  },
  {
    "id": "1116042016",
    "district_id": "111604",
    "label": "Muka Sungai Kuruk",
    "value": "Muka Sungai Kuruk"
  },
  {
    "id": "1116042017",
    "district_id": "111604",
    "label": "Paya Udang",
    "value": "Paya Udang"
  },
  {
    "id": "1116042018",
    "district_id": "111604",
    "label": "Gelung",
    "value": "Gelung"
  },
  {
    "id": "1116042019",
    "district_id": "111604",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1116042020",
    "district_id": "111604",
    "label": "Kuala Pusung Kapal",
    "value": "Kuala Pusung Kapal"
  },
  {
    "id": "1116042021",
    "district_id": "111604",
    "label": "Sungai Kuruk I",
    "value": "Sungai Kuruk I"
  },
  {
    "id": "1116042022",
    "district_id": "111604",
    "label": "Sungai Kuruk II",
    "value": "Sungai Kuruk II"
  },
  {
    "id": "1116042023",
    "district_id": "111604",
    "label": "Sungai Kuruk III",
    "value": "Sungai Kuruk III"
  },
  {
    "id": "1116042024",
    "district_id": "111604",
    "label": "Tangsi Lama",
    "value": "Tangsi Lama"
  },
  {
    "id": "1116042025",
    "district_id": "111604",
    "label": "Lubuk Damar",
    "value": "Lubuk Damar"
  },
  {
    "id": "1116052001",
    "district_id": "111605",
    "label": "Kota Kualasimpang",
    "value": "Kota Kualasimpang"
  },
  {
    "id": "1116052002",
    "district_id": "111605",
    "label": "Perdamaian",
    "value": "Perdamaian"
  },
  {
    "id": "1116052003",
    "district_id": "111605",
    "label": "Sriwijaya",
    "value": "Sriwijaya"
  },
  {
    "id": "1116052004",
    "district_id": "111605",
    "label": "Bukit Tempurung",
    "value": "Bukit Tempurung"
  },
  {
    "id": "1116052005",
    "district_id": "111605",
    "label": "Kotalintang",
    "value": "Kotalintang"
  },
  {
    "id": "1116062001",
    "district_id": "111606",
    "label": "Bukit Rata",
    "value": "Bukit Rata"
  },
  {
    "id": "1116062002",
    "district_id": "111606",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1116062003",
    "district_id": "111606",
    "label": "Tanjung Mancang",
    "value": "Tanjung Mancang"
  },
  {
    "id": "1116062004",
    "district_id": "111606",
    "label": "Simpang Kanan",
    "value": "Simpang Kanan"
  },
  {
    "id": "1116062005",
    "district_id": "111606",
    "label": "Tanjung Genteng",
    "value": "Tanjung Genteng"
  },
  {
    "id": "1116062006",
    "district_id": "111606",
    "label": "Gerenggam",
    "value": "Gerenggam"
  },
  {
    "id": "1116062007",
    "district_id": "111606",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1116062008",
    "district_id": "111606",
    "label": "Alur Selebu",
    "value": "Alur Selebu"
  },
  {
    "id": "1116062010",
    "district_id": "111606",
    "label": "Sungai Liput",
    "value": "Sungai Liput"
  },
  {
    "id": "1116062011",
    "district_id": "111606",
    "label": "Kebun Sungai Liput",
    "value": "Kebun Sungai Liput"
  },
  {
    "id": "1116062012",
    "district_id": "111606",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1116062013",
    "district_id": "111606",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "1116062014",
    "district_id": "111606",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1116062015",
    "district_id": "111606",
    "label": "Karang Jadi",
    "value": "Karang Jadi"
  },
  {
    "id": "1116062016",
    "district_id": "111606",
    "label": "Seumadam",
    "value": "Seumadam"
  },
  {
    "id": "1116072001",
    "district_id": "111607",
    "label": "Alur Tani Satu",
    "value": "Alur Tani Satu"
  },
  {
    "id": "1116072002",
    "district_id": "111607",
    "label": "Alur Tani Dua",
    "value": "Alur Tani Dua"
  },
  {
    "id": "1116072003",
    "district_id": "111607",
    "label": "Bandar Khalifah",
    "value": "Bandar Khalifah"
  },
  {
    "id": "1116072004",
    "district_id": "111607",
    "label": "Kaloy",
    "value": "Kaloy"
  },
  {
    "id": "1116072005",
    "district_id": "111607",
    "label": "Perkebunan Pulau Tiga",
    "value": "Perkebunan Pulau Tiga"
  },
  {
    "id": "1116072006",
    "district_id": "111607",
    "label": "Harum Sari",
    "value": "Harum Sari"
  },
  {
    "id": "1116072007",
    "district_id": "111607",
    "label": "Bandar Setia",
    "value": "Bandar Setia"
  },
  {
    "id": "1116072008",
    "district_id": "111607",
    "label": "Rongoh",
    "value": "Rongoh"
  },
  {
    "id": "1116072010",
    "district_id": "111607",
    "label": "Wono Sari",
    "value": "Wono Sari"
  },
  {
    "id": "1116082001",
    "district_id": "111608",
    "label": "Benua Raja",
    "value": "Benua Raja"
  },
  {
    "id": "1116082002",
    "district_id": "111608",
    "label": "Landuh",
    "value": "Landuh"
  },
  {
    "id": "1116082003",
    "district_id": "111608",
    "label": "Paya Bedi",
    "value": "Paya Bedi"
  },
  {
    "id": "1116082004",
    "district_id": "111608",
    "label": "Ingin Jaya",
    "value": "Ingin Jaya"
  },
  {
    "id": "1116082005",
    "district_id": "111608",
    "label": "Suka Rahmat",
    "value": "Suka Rahmat"
  },
  {
    "id": "1116082006",
    "district_id": "111608",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1116082007",
    "district_id": "111608",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1116082008",
    "district_id": "111608",
    "label": "Rantau Pauh",
    "value": "Rantau Pauh"
  },
  {
    "id": "1116082009",
    "district_id": "111608",
    "label": "Alur Cucur",
    "value": "Alur Cucur"
  },
  {
    "id": "1116082010",
    "district_id": "111608",
    "label": "Pertamina",
    "value": "Pertamina"
  },
  {
    "id": "1116082011",
    "district_id": "111608",
    "label": "Kebun Rantau",
    "value": "Kebun Rantau"
  },
  {
    "id": "1116082012",
    "district_id": "111608",
    "label": "Alur Manis",
    "value": "Alur Manis"
  },
  {
    "id": "1116082013",
    "district_id": "111608",
    "label": "Jamur Jelatang",
    "value": "Jamur Jelatang"
  },
  {
    "id": "1116082014",
    "district_id": "111608",
    "label": "Suka Rakyat",
    "value": "Suka Rakyat"
  },
  {
    "id": "1116082015",
    "district_id": "111608",
    "label": "Jamur Labu",
    "value": "Jamur Labu"
  },
  {
    "id": "1116082016",
    "district_id": "111608",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "1116092001",
    "district_id": "111609",
    "label": "Telaga Meuku Sa",
    "value": "Telaga Meuku Sa"
  },
  {
    "id": "1116092002",
    "district_id": "111609",
    "label": "Telaga Meuku Dua",
    "value": "Telaga Meuku Dua"
  },
  {
    "id": "1116092003",
    "district_id": "111609",
    "label": "Alur Nunang",
    "value": "Alur Nunang"
  },
  {
    "id": "1116092004",
    "district_id": "111609",
    "label": "Tanjung Keramat",
    "value": "Tanjung Keramat"
  },
  {
    "id": "1116092005",
    "district_id": "111609",
    "label": "Matang Seping",
    "value": "Matang Seping"
  },
  {
    "id": "1116092006",
    "district_id": "111609",
    "label": "Besar",
    "value": "Besar"
  },
  {
    "id": "1116092007",
    "district_id": "111609",
    "label": "Paya Rahat",
    "value": "Paya Rahat"
  },
  {
    "id": "1116092008",
    "district_id": "111609",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1116092009",
    "district_id": "111609",
    "label": "Suka Mulia Upah",
    "value": "Suka Mulia Upah"
  },
  {
    "id": "1116092010",
    "district_id": "111609",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1116102001",
    "district_id": "111610",
    "label": "Aras Sembilan",
    "value": "Aras Sembilan"
  },
  {
    "id": "1116102002",
    "district_id": "111610",
    "label": "Jambo Rambong",
    "value": "Jambo Rambong"
  },
  {
    "id": "1116102003",
    "district_id": "111610",
    "label": "Blang Kandis",
    "value": "Blang Kandis"
  },
  {
    "id": "1116102004",
    "district_id": "111610",
    "label": "Alur Jambu",
    "value": "Alur Jambu"
  },
  {
    "id": "1116102005",
    "district_id": "111610",
    "label": "Perkebunan Alur Jambu",
    "value": "Perkebunan Alur Jambu"
  },
  {
    "id": "1116102006",
    "district_id": "111610",
    "label": "Batang Ara",
    "value": "Batang Ara"
  },
  {
    "id": "1116102007",
    "district_id": "111610",
    "label": "Perupuk",
    "value": "Perupuk"
  },
  {
    "id": "1116102008",
    "district_id": "111610",
    "label": "Serba",
    "value": "Serba"
  },
  {
    "id": "1116102009",
    "district_id": "111610",
    "label": "Sunting",
    "value": "Sunting"
  },
  {
    "id": "1116102010",
    "district_id": "111610",
    "label": "Rantau Bintang",
    "value": "Rantau Bintang"
  },
  {
    "id": "1116102011",
    "district_id": "111610",
    "label": "Babo",
    "value": "Babo"
  },
  {
    "id": "1116102012",
    "district_id": "111610",
    "label": "Pantai Cempa",
    "value": "Pantai Cempa"
  },
  {
    "id": "1116102013",
    "district_id": "111610",
    "label": "Pengidam",
    "value": "Pengidam"
  },
  {
    "id": "1116102014",
    "district_id": "111610",
    "label": "Bengkelang",
    "value": "Bengkelang"
  },
  {
    "id": "1116102015",
    "district_id": "111610",
    "label": "Batu Bedulang",
    "value": "Batu Bedulang"
  },
  {
    "id": "1116112001",
    "district_id": "111611",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1116112002",
    "district_id": "111611",
    "label": "Rimba Sawang",
    "value": "Rimba Sawang"
  },
  {
    "id": "1116112003",
    "district_id": "111611",
    "label": "Tenggulun",
    "value": "Tenggulun"
  },
  {
    "id": "1116112004",
    "district_id": "111611",
    "label": "Selamat",
    "value": "Selamat"
  },
  {
    "id": "1116112005",
    "district_id": "111611",
    "label": "Simpang Kiri",
    "value": "Simpang Kiri"
  },
  {
    "id": "1116122001",
    "district_id": "111612",
    "label": "Bandar Mahligai",
    "value": "Bandar Mahligai"
  },
  {
    "id": "1116122002",
    "district_id": "111612",
    "label": "Sekerak Kiri",
    "value": "Sekerak Kiri"
  },
  {
    "id": "1116122003",
    "district_id": "111612",
    "label": "Pantai Tinjau",
    "value": "Pantai Tinjau"
  },
  {
    "id": "1116122004",
    "district_id": "111612",
    "label": "Pantai Perlak",
    "value": "Pantai Perlak"
  },
  {
    "id": "1116122005",
    "district_id": "111612",
    "label": "Kebun Batang Ara",
    "value": "Kebun Batang Ara"
  },
  {
    "id": "1116122006",
    "district_id": "111612",
    "label": "Sekerak Kanan",
    "value": "Sekerak Kanan"
  },
  {
    "id": "1116122007",
    "district_id": "111612",
    "label": "Lubuk Sidup",
    "value": "Lubuk Sidup"
  },
  {
    "id": "1116122008",
    "district_id": "111612",
    "label": "Sekumur",
    "value": "Sekumur"
  },
  {
    "id": "1116122009",
    "district_id": "111612",
    "label": "Tanjung Gelumpang",
    "value": "Tanjung Gelumpang"
  },
  {
    "id": "1116122010",
    "district_id": "111612",
    "label": "Pematang Durian",
    "value": "Pematang Durian"
  },
  {
    "id": "1116122011",
    "district_id": "111612",
    "label": "Juar",
    "value": "Juar"
  },
  {
    "id": "1116122012",
    "district_id": "111612",
    "label": "Sulum",
    "value": "Sulum"
  },
  {
    "id": "1116122013",
    "district_id": "111612",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1116122014",
    "district_id": "111612",
    "label": "Baling Karang",
    "value": "Baling Karang"
  },
  {
    "id": "1117012001",
    "district_id": "111701",
    "label": "Blang Rakal",
    "value": "Blang Rakal"
  },
  {
    "id": "1117012002",
    "district_id": "111701",
    "label": "Negeri Antara",
    "value": "Negeri Antara"
  },
  {
    "id": "1117012003",
    "district_id": "111701",
    "label": "Alur Cincin",
    "value": "Alur Cincin"
  },
  {
    "id": "1117012004",
    "district_id": "111701",
    "label": "Pantan Sinaku",
    "value": "Pantan Sinaku"
  },
  {
    "id": "1117012005",
    "district_id": "111701",
    "label": "Bintang Berangun",
    "value": "Bintang Berangun"
  },
  {
    "id": "1117012006",
    "district_id": "111701",
    "label": "Taman Firdaus",
    "value": "Taman Firdaus"
  },
  {
    "id": "1117012007",
    "district_id": "111701",
    "label": "Uning Mas",
    "value": "Uning Mas"
  },
  {
    "id": "1117012008",
    "district_id": "111701",
    "label": "Perdamaian",
    "value": "Perdamaian"
  },
  {
    "id": "1117012009",
    "district_id": "111701",
    "label": "Ulu Naron",
    "value": "Ulu Naron"
  },
  {
    "id": "1117012010",
    "district_id": "111701",
    "label": "Pantan Lah",
    "value": "Pantan Lah"
  },
  {
    "id": "1117012011",
    "district_id": "111701",
    "label": "Pancar Jelobok",
    "value": "Pancar Jelobok"
  },
  {
    "id": "1117012012",
    "district_id": "111701",
    "label": "Alur Gading",
    "value": "Alur Gading"
  },
  {
    "id": "1117012013",
    "district_id": "111701",
    "label": "Rime Raya",
    "value": "Rime Raya"
  },
  {
    "id": "1117012014",
    "district_id": "111701",
    "label": "Singah Mulo",
    "value": "Singah Mulo"
  },
  {
    "id": "1117012015",
    "district_id": "111701",
    "label": "Simpang Lancang",
    "value": "Simpang Lancang"
  },
  {
    "id": "1117012016",
    "district_id": "111701",
    "label": "Wih Porak",
    "value": "Wih Porak"
  },
  {
    "id": "1117012017",
    "district_id": "111701",
    "label": "Bener Meriah",
    "value": "Bener Meriah"
  },
  {
    "id": "1117012018",
    "district_id": "111701",
    "label": "Musara 58",
    "value": "Musara 58"
  },
  {
    "id": "1117012019",
    "district_id": "111701",
    "label": "Musara Pakat",
    "value": "Musara Pakat"
  },
  {
    "id": "1117012020",
    "district_id": "111701",
    "label": "Rata Ara",
    "value": "Rata Ara"
  },
  {
    "id": "1117012021",
    "district_id": "111701",
    "label": "Gemasih",
    "value": "Gemasih"
  },
  {
    "id": "1117012022",
    "district_id": "111701",
    "label": "Pulo Intan",
    "value": "Pulo Intan"
  },
  {
    "id": "1117012023",
    "district_id": "111701",
    "label": "Blang Ara",
    "value": "Blang Ara"
  },
  {
    "id": "1117022001",
    "district_id": "111702",
    "label": "Bener Pepanyi",
    "value": "Bener Pepanyi"
  },
  {
    "id": "1117022002",
    "district_id": "111702",
    "label": "Bintang Bener",
    "value": "Bintang Bener"
  },
  {
    "id": "1117022003",
    "district_id": "111702",
    "label": "Bintang Permata",
    "value": "Bintang Permata"
  },
  {
    "id": "1117022004",
    "district_id": "111702",
    "label": "Buntul Peteri",
    "value": "Buntul Peteri"
  },
  {
    "id": "1117022005",
    "district_id": "111702",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1117022006",
    "district_id": "111702",
    "label": "GLP. Wih Tenang Uken",
    "value": "GLP. Wih Tenang Uken"
  },
  {
    "id": "1117022007",
    "district_id": "111702",
    "label": "Pantan Tengah Jaya",
    "value": "Pantan Tengah Jaya"
  },
  {
    "id": "1117022008",
    "district_id": "111702",
    "label": "Penosan Jaya",
    "value": "Penosan Jaya"
  },
  {
    "id": "1117022009",
    "district_id": "111702",
    "label": "Ramung Jaya",
    "value": "Ramung Jaya"
  },
  {
    "id": "1117022010",
    "district_id": "111702",
    "label": "Seni Antara",
    "value": "Seni Antara"
  },
  {
    "id": "1117022011",
    "district_id": "111702",
    "label": "Temas Mumanang",
    "value": "Temas Mumanang"
  },
  {
    "id": "1117022012",
    "district_id": "111702",
    "label": "Wih Tenang Toa",
    "value": "Wih Tenang Toa"
  },
  {
    "id": "1117022013",
    "district_id": "111702",
    "label": "Wih Tenang Uken",
    "value": "Wih Tenang Uken"
  },
  {
    "id": "1117022014",
    "district_id": "111702",
    "label": "Ayu Ara",
    "value": "Ayu Ara"
  },
  {
    "id": "1117022015",
    "district_id": "111702",
    "label": "Ceding Ayu",
    "value": "Ceding Ayu"
  },
  {
    "id": "1117022016",
    "district_id": "111702",
    "label": "Uning Sejuk",
    "value": "Uning Sejuk"
  },
  {
    "id": "1117022017",
    "district_id": "111702",
    "label": "Suku Sara Tangke",
    "value": "Suku Sara Tangke"
  },
  {
    "id": "1117022018",
    "district_id": "111702",
    "label": "Rikit Musara",
    "value": "Rikit Musara"
  },
  {
    "id": "1117022019",
    "district_id": "111702",
    "label": "Jungke",
    "value": "Jungke"
  },
  {
    "id": "1117022020",
    "district_id": "111702",
    "label": "Burni Pase",
    "value": "Burni Pase"
  },
  {
    "id": "1117022021",
    "district_id": "111702",
    "label": "Bale Musara",
    "value": "Bale Musara"
  },
  {
    "id": "1117022022",
    "district_id": "111702",
    "label": "Pemango",
    "value": "Pemango"
  },
  {
    "id": "1117022023",
    "district_id": "111702",
    "label": "Jelobok",
    "value": "Jelobok"
  },
  {
    "id": "1117022024",
    "district_id": "111702",
    "label": "Kepies",
    "value": "Kepies"
  },
  {
    "id": "1117022025",
    "district_id": "111702",
    "label": "Timur Jaya",
    "value": "Timur Jaya"
  },
  {
    "id": "1117022026",
    "district_id": "111702",
    "label": "Bale Purnama",
    "value": "Bale Purnama"
  },
  {
    "id": "1117022027",
    "district_id": "111702",
    "label": "Tawar Bengi",
    "value": "Tawar Bengi"
  },
  {
    "id": "1117032001",
    "district_id": "111703",
    "label": "Blang Panu",
    "value": "Blang Panu"
  },
  {
    "id": "1117032003",
    "district_id": "111703",
    "label": "Gerpa",
    "value": "Gerpa"
  },
  {
    "id": "1117032004",
    "district_id": "111703",
    "label": "Goneng",
    "value": "Goneng"
  },
  {
    "id": "1117032007",
    "district_id": "111703",
    "label": "Kerlang",
    "value": "Kerlang"
  },
  {
    "id": "1117032008",
    "district_id": "111703",
    "label": "Kutelah Lane",
    "value": "Kutelah Lane"
  },
  {
    "id": "1117032009",
    "district_id": "111703",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1117032010",
    "district_id": "111703",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "1117032012",
    "district_id": "111703",
    "label": "Rusip",
    "value": "Rusip"
  },
  {
    "id": "1117032014",
    "district_id": "111703",
    "label": "Tembolon",
    "value": "Tembolon"
  },
  {
    "id": "1117032015",
    "district_id": "111703",
    "label": "Uning",
    "value": "Uning"
  },
  {
    "id": "1117032016",
    "district_id": "111703",
    "label": "Wihni Durin",
    "value": "Wihni Durin"
  },
  {
    "id": "1117032017",
    "district_id": "111703",
    "label": "Rata Mulie",
    "value": "Rata Mulie"
  },
  {
    "id": "1117032018",
    "district_id": "111703",
    "label": "Tempen Baru",
    "value": "Tempen Baru"
  },
  {
    "id": "1117032019",
    "district_id": "111703",
    "label": "Geruti Jaya",
    "value": "Geruti Jaya"
  },
  {
    "id": "1117042001",
    "district_id": "111704",
    "label": "Bahgie Bertona",
    "value": "Bahgie Bertona"
  },
  {
    "id": "1117042003",
    "district_id": "111704",
    "label": "Bathin Baru",
    "value": "Bathin Baru"
  },
  {
    "id": "1117042004",
    "district_id": "111704",
    "label": "Blang Pulo",
    "value": "Blang Pulo"
  },
  {
    "id": "1117042009",
    "district_id": "111704",
    "label": "Bukit Wih Ilang",
    "value": "Bukit Wih Ilang"
  },
  {
    "id": "1117042010",
    "district_id": "111704",
    "label": "Hakim Wih Ilang",
    "value": "Hakim Wih Ilang"
  },
  {
    "id": "1117042011",
    "district_id": "111704",
    "label": "Janarata",
    "value": "Janarata"
  },
  {
    "id": "1117042012",
    "district_id": "111704",
    "label": "Keramat Jaya",
    "value": "Keramat Jaya"
  },
  {
    "id": "1117042013",
    "district_id": "111704",
    "label": "Lewa Jadi",
    "value": "Lewa Jadi"
  },
  {
    "id": "1117042014",
    "district_id": "111704",
    "label": "Muyang Kute Mangku",
    "value": "Muyang Kute Mangku"
  },
  {
    "id": "1117042016",
    "district_id": "111704",
    "label": "Pakat Jeroh",
    "value": "Pakat Jeroh"
  },
  {
    "id": "1117042017",
    "district_id": "111704",
    "label": "Paya Baning",
    "value": "Paya Baning"
  },
  {
    "id": "1117042018",
    "district_id": "111704",
    "label": "Blang Jorong",
    "value": "Blang Jorong"
  },
  {
    "id": "1117042019",
    "district_id": "111704",
    "label": "Pondok Baru",
    "value": "Pondok Baru"
  },
  {
    "id": "1117042020",
    "district_id": "111704",
    "label": "Pondok Gajah",
    "value": "Pondok Gajah"
  },
  {
    "id": "1117042021",
    "district_id": "111704",
    "label": "Pondok Ulung",
    "value": "Pondok Ulung"
  },
  {
    "id": "1117042022",
    "district_id": "111704",
    "label": "Puja Mulia",
    "value": "Puja Mulia"
  },
  {
    "id": "1117042023",
    "district_id": "111704",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1117042024",
    "district_id": "111704",
    "label": "Selamat Rejo",
    "value": "Selamat Rejo"
  },
  {
    "id": "1117042025",
    "district_id": "111704",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1117042026",
    "district_id": "111704",
    "label": "Simpang Utama",
    "value": "Simpang Utama"
  },
  {
    "id": "1117042027",
    "district_id": "111704",
    "label": "Suku Wih Ilang",
    "value": "Suku Wih Ilang"
  },
  {
    "id": "1117042028",
    "district_id": "111704",
    "label": "Tanjung Pura",
    "value": "Tanjung Pura"
  },
  {
    "id": "1117042029",
    "district_id": "111704",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1117042030",
    "district_id": "111704",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "1117042032",
    "district_id": "111704",
    "label": "Tansaran Bidin",
    "value": "Tansaran Bidin"
  },
  {
    "id": "1117042033",
    "district_id": "111704",
    "label": "Jadi Sepakat",
    "value": "Jadi Sepakat"
  },
  {
    "id": "1117042034",
    "district_id": "111704",
    "label": "Selisih Mara",
    "value": "Selisih Mara"
  },
  {
    "id": "1117042035",
    "district_id": "111704",
    "label": "Kala Nempan",
    "value": "Kala Nempan"
  },
  {
    "id": "1117042036",
    "district_id": "111704",
    "label": "Gunung Antara",
    "value": "Gunung Antara"
  },
  {
    "id": "1117042037",
    "district_id": "111704",
    "label": "Gele Semayang",
    "value": "Gele Semayang"
  },
  {
    "id": "1117042042",
    "district_id": "111704",
    "label": "Tawar Sedenge",
    "value": "Tawar Sedenge"
  },
  {
    "id": "1117042043",
    "district_id": "111704",
    "label": "Beranun Teleden",
    "value": "Beranun Teleden"
  },
  {
    "id": "1117042044",
    "district_id": "111704",
    "label": "Remang Ketike Jaya",
    "value": "Remang Ketike Jaya"
  },
  {
    "id": "1117042045",
    "district_id": "111704",
    "label": "Makmur Sentosa",
    "value": "Makmur Sentosa"
  },
  {
    "id": "1117042047",
    "district_id": "111704",
    "label": "Sinar Jaya Paya Ringkel",
    "value": "Sinar Jaya Paya Ringkel"
  },
  {
    "id": "1117052001",
    "district_id": "111705",
    "label": "Bale Atu",
    "value": "Bale Atu"
  },
  {
    "id": "1117052002",
    "district_id": "111705",
    "label": "Bale Redelong",
    "value": "Bale Redelong"
  },
  {
    "id": "1117052003",
    "district_id": "111705",
    "label": "Batin Wih Pongas",
    "value": "Batin Wih Pongas"
  },
  {
    "id": "1117052004",
    "district_id": "111705",
    "label": "Blang Ara",
    "value": "Blang Ara"
  },
  {
    "id": "1117052005",
    "district_id": "111705",
    "label": "Blang Sentang",
    "value": "Blang Sentang"
  },
  {
    "id": "1117052006",
    "district_id": "111705",
    "label": "Blang Tampu",
    "value": "Blang Tampu"
  },
  {
    "id": "1117052007",
    "district_id": "111705",
    "label": "Bujang",
    "value": "Bujang"
  },
  {
    "id": "1117052009",
    "district_id": "111705",
    "label": "Delung Tue",
    "value": "Delung Tue"
  },
  {
    "id": "1117052010",
    "district_id": "111705",
    "label": "Hakim Tungul Naru",
    "value": "Hakim Tungul Naru"
  },
  {
    "id": "1117052011",
    "district_id": "111705",
    "label": "Isaq Busur",
    "value": "Isaq Busur"
  },
  {
    "id": "1117052012",
    "district_id": "111705",
    "label": "Kenawat Redelong",
    "value": "Kenawat Redelong"
  },
  {
    "id": "1117052013",
    "district_id": "111705",
    "label": "Kute Lintang",
    "value": "Kute Lintang"
  },
  {
    "id": "1117052014",
    "district_id": "111705",
    "label": "Panji Mulia I",
    "value": "Panji Mulia I"
  },
  {
    "id": "1117052015",
    "district_id": "111705",
    "label": "Panji Mulia II",
    "value": "Panji Mulia II"
  },
  {
    "id": "1117052016",
    "district_id": "111705",
    "label": "Pasar Simpang Tiga",
    "value": "Pasar Simpang Tiga"
  },
  {
    "id": "1117052017",
    "district_id": "111705",
    "label": "Reje Guru",
    "value": "Reje Guru"
  },
  {
    "id": "1117052018",
    "district_id": "111705",
    "label": "Rembele",
    "value": "Rembele"
  },
  {
    "id": "1117052019",
    "district_id": "111705",
    "label": "Sedie Jadi",
    "value": "Sedie Jadi"
  },
  {
    "id": "1117052020",
    "district_id": "111705",
    "label": "Tingkem Asli",
    "value": "Tingkem Asli"
  },
  {
    "id": "1117052021",
    "district_id": "111705",
    "label": "Tingkem Benyer",
    "value": "Tingkem Benyer"
  },
  {
    "id": "1117052022",
    "district_id": "111705",
    "label": "Tingkem Bersatu",
    "value": "Tingkem Bersatu"
  },
  {
    "id": "1117052023",
    "district_id": "111705",
    "label": "Ujung Gele",
    "value": "Ujung Gele"
  },
  {
    "id": "1117052024",
    "district_id": "111705",
    "label": "Uning Bersah",
    "value": "Uning Bersah"
  },
  {
    "id": "1117052025",
    "district_id": "111705",
    "label": "Uning Teritit",
    "value": "Uning Teritit"
  },
  {
    "id": "1117052026",
    "district_id": "111705",
    "label": "Waq Pondok Sayur",
    "value": "Waq Pondok Sayur"
  },
  {
    "id": "1117052027",
    "district_id": "111705",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "1117052028",
    "district_id": "111705",
    "label": "Paya Gajah",
    "value": "Paya Gajah"
  },
  {
    "id": "1117052029",
    "district_id": "111705",
    "label": "Kute Tanyung",
    "value": "Kute Tanyung"
  },
  {
    "id": "1117052030",
    "district_id": "111705",
    "label": "Meluem",
    "value": "Meluem"
  },
  {
    "id": "1117052031",
    "district_id": "111705",
    "label": "Delung Asli",
    "value": "Delung Asli"
  },
  {
    "id": "1117052032",
    "district_id": "111705",
    "label": "Pilar Jaya",
    "value": "Pilar Jaya"
  },
  {
    "id": "1117052033",
    "district_id": "111705",
    "label": "Serule Kayu",
    "value": "Serule Kayu"
  },
  {
    "id": "1117052034",
    "district_id": "111705",
    "label": "Uring",
    "value": "Uring"
  },
  {
    "id": "1117052035",
    "district_id": "111705",
    "label": "Mupakat Jadi",
    "value": "Mupakat Jadi"
  },
  {
    "id": "1117052036",
    "district_id": "111705",
    "label": "Kute Kering",
    "value": "Kute Kering"
  },
  {
    "id": "1117052037",
    "district_id": "111705",
    "label": "Blang Panas",
    "value": "Blang Panas"
  },
  {
    "id": "1117052038",
    "district_id": "111705",
    "label": "Mutiara Baru",
    "value": "Mutiara Baru"
  },
  {
    "id": "1117052039",
    "district_id": "111705",
    "label": "Bukit Bersatu",
    "value": "Bukit Bersatu"
  },
  {
    "id": "1117052040",
    "district_id": "111705",
    "label": "Godang",
    "value": "Godang"
  },
  {
    "id": "1117052041",
    "district_id": "111705",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1117062001",
    "district_id": "111706",
    "label": "Blang Paku",
    "value": "Blang Paku"
  },
  {
    "id": "1117062004",
    "district_id": "111706",
    "label": "Pante Raya",
    "value": "Pante Raya"
  },
  {
    "id": "1117062005",
    "district_id": "111706",
    "label": "Simpang Balek",
    "value": "Simpang Balek"
  },
  {
    "id": "1117062006",
    "district_id": "111706",
    "label": "Simpang Teritit",
    "value": "Simpang Teritit"
  },
  {
    "id": "1117062007",
    "district_id": "111706",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1117062008",
    "district_id": "111706",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1117062009",
    "district_id": "111706",
    "label": "Suka Ramai Atas",
    "value": "Suka Ramai Atas"
  },
  {
    "id": "1117062010",
    "district_id": "111706",
    "label": "Suka Ramai Bawah",
    "value": "Suka Ramai Bawah"
  },
  {
    "id": "1117062011",
    "district_id": "111706",
    "label": "Syura Jadi",
    "value": "Syura Jadi"
  },
  {
    "id": "1117062012",
    "district_id": "111706",
    "label": "Jamur Uluh",
    "value": "Jamur Uluh"
  },
  {
    "id": "1117062013",
    "district_id": "111706",
    "label": "Gegerung",
    "value": "Gegerung"
  },
  {
    "id": "1117062014",
    "district_id": "111706",
    "label": "Merie Satu",
    "value": "Merie Satu"
  },
  {
    "id": "1117062015",
    "district_id": "111706",
    "label": "Blang Benara",
    "value": "Blang Benara"
  },
  {
    "id": "1117062016",
    "district_id": "111706",
    "label": "Bukit Pepanyi",
    "value": "Bukit Pepanyi"
  },
  {
    "id": "1117062017",
    "district_id": "111706",
    "label": "Wih Pesam",
    "value": "Wih Pesam"
  },
  {
    "id": "1117062018",
    "district_id": "111706",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1117062019",
    "district_id": "111706",
    "label": "Bener Ayu",
    "value": "Bener Ayu"
  },
  {
    "id": "1117062020",
    "district_id": "111706",
    "label": "Bener Mulie",
    "value": "Bener Mulie"
  },
  {
    "id": "1117062021",
    "district_id": "111706",
    "label": "Blang Kucak",
    "value": "Blang Kucak"
  },
  {
    "id": "1117062022",
    "district_id": "111706",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "1117062023",
    "district_id": "111706",
    "label": "Lut Kucak",
    "value": "Lut Kucak"
  },
  {
    "id": "1117062024",
    "district_id": "111706",
    "label": "Kebun Baru",
    "value": "Kebun Baru"
  },
  {
    "id": "1117062025",
    "district_id": "111706",
    "label": "Simpang Antara",
    "value": "Simpang Antara"
  },
  {
    "id": "1117062026",
    "district_id": "111706",
    "label": "Jamur Ujung",
    "value": "Jamur Ujung"
  },
  {
    "id": "1117062027",
    "district_id": "111706",
    "label": "Suka Makmur Timur",
    "value": "Suka Makmur Timur"
  },
  {
    "id": "1117062028",
    "district_id": "111706",
    "label": "Burni Telong",
    "value": "Burni Telong"
  },
  {
    "id": "1117072001",
    "district_id": "111707",
    "label": "Bandar Lampahan",
    "value": "Bandar Lampahan"
  },
  {
    "id": "1117072002",
    "district_id": "111707",
    "label": "Blang Rongka",
    "value": "Blang Rongka"
  },
  {
    "id": "1117072003",
    "district_id": "111707",
    "label": "Cekal Baru",
    "value": "Cekal Baru"
  },
  {
    "id": "1117072004",
    "district_id": "111707",
    "label": "Gegur Sepakat",
    "value": "Gegur Sepakat"
  },
  {
    "id": "1117072005",
    "district_id": "111707",
    "label": "Karang Jadi",
    "value": "Karang Jadi"
  },
  {
    "id": "1117072006",
    "district_id": "111707",
    "label": "Lampahan",
    "value": "Lampahan"
  },
  {
    "id": "1117072010",
    "district_id": "111707",
    "label": "Setie",
    "value": "Setie"
  },
  {
    "id": "1117072011",
    "district_id": "111707",
    "label": "Simpang Layang",
    "value": "Simpang Layang"
  },
  {
    "id": "1117072012",
    "district_id": "111707",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1117072014",
    "district_id": "111707",
    "label": "Tunyang",
    "value": "Tunyang"
  },
  {
    "id": "1117072016",
    "district_id": "111707",
    "label": "Datu Beru",
    "value": "Datu Beru"
  },
  {
    "id": "1117072017",
    "district_id": "111707",
    "label": "Kenine",
    "value": "Kenine"
  },
  {
    "id": "1117072018",
    "district_id": "111707",
    "label": "Rembune",
    "value": "Rembune"
  },
  {
    "id": "1117072019",
    "district_id": "111707",
    "label": "Damaran Baru",
    "value": "Damaran Baru"
  },
  {
    "id": "1117072020",
    "district_id": "111707",
    "label": "Fajar Harapan",
    "value": "Fajar Harapan"
  },
  {
    "id": "1117072021",
    "district_id": "111707",
    "label": "Mude Benara",
    "value": "Mude Benara"
  },
  {
    "id": "1117072022",
    "district_id": "111707",
    "label": "Kampung Baru 76",
    "value": "Kampung Baru 76"
  },
  {
    "id": "1117072023",
    "district_id": "111707",
    "label": "Pantan Pediangan",
    "value": "Pantan Pediangan"
  },
  {
    "id": "1117072024",
    "district_id": "111707",
    "label": "Bumi Ayu",
    "value": "Bumi Ayu"
  },
  {
    "id": "1117072025",
    "district_id": "111707",
    "label": "Kulem Para Kanis",
    "value": "Kulem Para Kanis"
  },
  {
    "id": "1117072026",
    "district_id": "111707",
    "label": "Mekar Ayu",
    "value": "Mekar Ayu"
  },
  {
    "id": "1117072027",
    "district_id": "111707",
    "label": "Lampahan Barat",
    "value": "Lampahan Barat"
  },
  {
    "id": "1117072028",
    "district_id": "111707",
    "label": "Lampahan Timur",
    "value": "Lampahan Timur"
  },
  {
    "id": "1117072029",
    "district_id": "111707",
    "label": "Timang Rasa",
    "value": "Timang Rasa"
  },
  {
    "id": "1117072030",
    "district_id": "111707",
    "label": "Gunung Tunyang",
    "value": "Gunung Tunyang"
  },
  {
    "id": "1117072031",
    "district_id": "111707",
    "label": "Bukit Tunyang",
    "value": "Bukit Tunyang"
  },
  {
    "id": "1117072032",
    "district_id": "111707",
    "label": "Linung Bale",
    "value": "Linung Bale"
  },
  {
    "id": "1117072033",
    "district_id": "111707",
    "label": "Pantan Kemuning",
    "value": "Pantan Kemuning"
  },
  {
    "id": "1117072034",
    "district_id": "111707",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1117072035",
    "district_id": "111707",
    "label": "Bukit Mulie",
    "value": "Bukit Mulie"
  },
  {
    "id": "1117082001",
    "district_id": "111708",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1117082002",
    "district_id": "111708",
    "label": "Jongok Meluem",
    "value": "Jongok Meluem"
  },
  {
    "id": "1117082003",
    "district_id": "111708",
    "label": "Suku Bener",
    "value": "Suku Bener"
  },
  {
    "id": "1117082004",
    "district_id": "111708",
    "label": "Bener Lukup II",
    "value": "Bener Lukup II"
  },
  {
    "id": "1117082005",
    "district_id": "111708",
    "label": "Gunung Musara",
    "value": "Gunung Musara"
  },
  {
    "id": "1117082006",
    "district_id": "111708",
    "label": "Bener Kelipah Selatan",
    "value": "Bener Kelipah Selatan"
  },
  {
    "id": "1117082007",
    "district_id": "111708",
    "label": "Bintang Musara",
    "value": "Bintang Musara"
  },
  {
    "id": "1117082008",
    "district_id": "111708",
    "label": "Kala Tenang",
    "value": "Kala Tenang"
  },
  {
    "id": "1117082009",
    "district_id": "111708",
    "label": "Bener Kelipah Utara",
    "value": "Bener Kelipah Utara"
  },
  {
    "id": "1117082010",
    "district_id": "111708",
    "label": "Lot Bener Kelipah",
    "value": "Lot Bener Kelipah"
  },
  {
    "id": "1117082011",
    "district_id": "111708",
    "label": "Nosar Tawar Jaya",
    "value": "Nosar Tawar Jaya"
  },
  {
    "id": "1117082012",
    "district_id": "111708",
    "label": "Nosar Baru",
    "value": "Nosar Baru"
  },
  {
    "id": "1117092001",
    "district_id": "111709",
    "label": "Cemparam Lama",
    "value": "Cemparam Lama"
  },
  {
    "id": "1117092002",
    "district_id": "111709",
    "label": "Cemparam Pakat Jeroh",
    "value": "Cemparam Pakat Jeroh"
  },
  {
    "id": "1117092003",
    "district_id": "111709",
    "label": "Cemparam Jaya",
    "value": "Cemparam Jaya"
  },
  {
    "id": "1117092004",
    "district_id": "111709",
    "label": "Pantan Kuli",
    "value": "Pantan Kuli"
  },
  {
    "id": "1117092005",
    "district_id": "111709",
    "label": "Simpang Renggali",
    "value": "Simpang Renggali"
  },
  {
    "id": "1117092006",
    "district_id": "111709",
    "label": "Jamur Atu Jaya",
    "value": "Jamur Atu Jaya"
  },
  {
    "id": "1117092007",
    "district_id": "111709",
    "label": "Sosial",
    "value": "Sosial"
  },
  {
    "id": "1117092008",
    "district_id": "111709",
    "label": "Gunung Sayang",
    "value": "Gunung Sayang"
  },
  {
    "id": "1117092009",
    "district_id": "111709",
    "label": "Amor",
    "value": "Amor"
  },
  {
    "id": "1117092010",
    "district_id": "111709",
    "label": "Buntul Gayo",
    "value": "Buntul Gayo"
  },
  {
    "id": "1117092011",
    "district_id": "111709",
    "label": "Wih Resap",
    "value": "Wih Resap"
  },
  {
    "id": "1117092012",
    "district_id": "111709",
    "label": "Wer Tingkem",
    "value": "Wer Tingkem"
  },
  {
    "id": "1117092013",
    "district_id": "111709",
    "label": "Perumpaken Benjadi",
    "value": "Perumpaken Benjadi"
  },
  {
    "id": "1117092014",
    "district_id": "111709",
    "label": "Hakim Peteri Pintu",
    "value": "Hakim Peteri Pintu"
  },
  {
    "id": "1117092015",
    "district_id": "111709",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "1117102001",
    "district_id": "111710",
    "label": "Simpang Rahmat",
    "value": "Simpang Rahmat"
  },
  {
    "id": "1117102002",
    "district_id": "111710",
    "label": "Gajah Putih",
    "value": "Gajah Putih"
  },
  {
    "id": "1117102003",
    "district_id": "111710",
    "label": "Gayo Setie",
    "value": "Gayo Setie"
  },
  {
    "id": "1117102004",
    "district_id": "111710",
    "label": "Umah Besi",
    "value": "Umah Besi"
  },
  {
    "id": "1117102005",
    "district_id": "111710",
    "label": "Pante Karya",
    "value": "Pante Karya"
  },
  {
    "id": "1117102006",
    "district_id": "111710",
    "label": "Meriah Jaya",
    "value": "Meriah Jaya"
  },
  {
    "id": "1117102007",
    "district_id": "111710",
    "label": "Timang Gajah",
    "value": "Timang Gajah"
  },
  {
    "id": "1117102008",
    "district_id": "111710",
    "label": "Pantan Lues",
    "value": "Pantan Lues"
  },
  {
    "id": "1117102009",
    "district_id": "111710",
    "label": "Alam Jaya",
    "value": "Alam Jaya"
  },
  {
    "id": "1117102010",
    "district_id": "111710",
    "label": "Reronga",
    "value": "Reronga"
  },
  {
    "id": "1118012001",
    "district_id": "111801",
    "label": "Meunasah Balek",
    "value": "Meunasah Balek"
  },
  {
    "id": "1118012002",
    "district_id": "111801",
    "label": "Kota Meureudu",
    "value": "Kota Meureudu"
  },
  {
    "id": "1118012003",
    "district_id": "111801",
    "label": "Mesjid Tuha",
    "value": "Mesjid Tuha"
  },
  {
    "id": "1118012004",
    "district_id": "111801",
    "label": "Rungkom",
    "value": "Rungkom"
  },
  {
    "id": "1118012005",
    "district_id": "111801",
    "label": "Blang Awe",
    "value": "Blang Awe"
  },
  {
    "id": "1118012006",
    "district_id": "111801",
    "label": "Manyang Lancok",
    "value": "Manyang Lancok"
  },
  {
    "id": "1118012007",
    "district_id": "111801",
    "label": "Manyang Cut",
    "value": "Manyang Cut"
  },
  {
    "id": "1118012008",
    "district_id": "111801",
    "label": "Beurawang",
    "value": "Beurawang"
  },
  {
    "id": "1118012009",
    "district_id": "111801",
    "label": "Rhing Blang",
    "value": "Rhing Blang"
  },
  {
    "id": "1118012010",
    "district_id": "111801",
    "label": "Rhing Krueng",
    "value": "Rhing Krueng"
  },
  {
    "id": "1118012011",
    "district_id": "111801",
    "label": "Rhing Mancang",
    "value": "Rhing Mancang"
  },
  {
    "id": "1118012012",
    "district_id": "111801",
    "label": "Meunasah Lhok",
    "value": "Meunasah Lhok"
  },
  {
    "id": "1118012013",
    "district_id": "111801",
    "label": "Meuraksa",
    "value": "Meuraksa"
  },
  {
    "id": "1118012014",
    "district_id": "111801",
    "label": "Pulo U",
    "value": "Pulo U"
  },
  {
    "id": "1118012015",
    "district_id": "111801",
    "label": "Geuleudah",
    "value": "Geuleudah"
  },
  {
    "id": "1118012016",
    "district_id": "111801",
    "label": "Dayah Timu",
    "value": "Dayah Timu"
  },
  {
    "id": "1118012017",
    "district_id": "111801",
    "label": "Bunot",
    "value": "Bunot"
  },
  {
    "id": "1118012018",
    "district_id": "111801",
    "label": "Glumpang Tutong",
    "value": "Glumpang Tutong"
  },
  {
    "id": "1118012019",
    "district_id": "111801",
    "label": "Meunasah Hagu",
    "value": "Meunasah Hagu"
  },
  {
    "id": "1118012020",
    "district_id": "111801",
    "label": "Pohroh",
    "value": "Pohroh"
  },
  {
    "id": "1118012021",
    "district_id": "111801",
    "label": "Kudrang",
    "value": "Kudrang"
  },
  {
    "id": "1118012022",
    "district_id": "111801",
    "label": "Lampoh Lada",
    "value": "Lampoh Lada"
  },
  {
    "id": "1118012023",
    "district_id": "111801",
    "label": "Mulieng",
    "value": "Mulieng"
  },
  {
    "id": "1118012024",
    "district_id": "111801",
    "label": "Dayah Tuha",
    "value": "Dayah Tuha"
  },
  {
    "id": "1118012025",
    "district_id": "111801",
    "label": "Rumpuen",
    "value": "Rumpuen"
  },
  {
    "id": "1118012026",
    "district_id": "111801",
    "label": "Rambong",
    "value": "Rambong"
  },
  {
    "id": "1118012027",
    "district_id": "111801",
    "label": "Meunasah Kulam",
    "value": "Meunasah Kulam"
  },
  {
    "id": "1118012028",
    "district_id": "111801",
    "label": "Grong-Grong",
    "value": "Grong-Grong"
  },
  {
    "id": "1118012029",
    "district_id": "111801",
    "label": "Kuta Trieng",
    "value": "Kuta Trieng"
  },
  {
    "id": "1118012030",
    "district_id": "111801",
    "label": "Teupin Peuraho",
    "value": "Teupin Peuraho"
  },
  {
    "id": "1118022001",
    "district_id": "111802",
    "label": "Dayah Baroh",
    "value": "Dayah Baroh"
  },
  {
    "id": "1118022002",
    "district_id": "111802",
    "label": "Reuleut",
    "value": "Reuleut"
  },
  {
    "id": "1118022003",
    "district_id": "111802",
    "label": "Sambungan Baro",
    "value": "Sambungan Baro"
  },
  {
    "id": "1118022004",
    "district_id": "111802",
    "label": "Tijien Husen",
    "value": "Tijien Husen"
  },
  {
    "id": "1118022005",
    "district_id": "111802",
    "label": "Meunasah Krueng",
    "value": "Meunasah Krueng"
  },
  {
    "id": "1118022006",
    "district_id": "111802",
    "label": "Geulanggang",
    "value": "Geulanggang"
  },
  {
    "id": "1118022007",
    "district_id": "111802",
    "label": "Mesjid Ulim Baroh",
    "value": "Mesjid Ulim Baroh"
  },
  {
    "id": "1118022008",
    "district_id": "111802",
    "label": "Pulo Lhok",
    "value": "Pulo Lhok"
  },
  {
    "id": "1118022009",
    "district_id": "111802",
    "label": "Dayah Leubue",
    "value": "Dayah Leubue"
  },
  {
    "id": "1118022010",
    "district_id": "111802",
    "label": "Tijien Daboh",
    "value": "Tijien Daboh"
  },
  {
    "id": "1118022011",
    "district_id": "111802",
    "label": "Keude Ulim",
    "value": "Keude Ulim"
  },
  {
    "id": "1118022012",
    "district_id": "111802",
    "label": "Meunasah Bueng",
    "value": "Meunasah Bueng"
  },
  {
    "id": "1118022013",
    "district_id": "111802",
    "label": "Balee Ulim",
    "value": "Balee Ulim"
  },
  {
    "id": "1118022014",
    "district_id": "111802",
    "label": "Grong Grong Capa",
    "value": "Grong Grong Capa"
  },
  {
    "id": "1118022015",
    "district_id": "111802",
    "label": "Meunasah Kumbang",
    "value": "Meunasah Kumbang"
  },
  {
    "id": "1118022016",
    "district_id": "111802",
    "label": "Tanjong Ulim",
    "value": "Tanjong Ulim"
  },
  {
    "id": "1118022017",
    "district_id": "111802",
    "label": "Mesjid Ulim Tunong",
    "value": "Mesjid Ulim Tunong"
  },
  {
    "id": "1118022018",
    "district_id": "111802",
    "label": "Pulo Ulim",
    "value": "Pulo Ulim"
  },
  {
    "id": "1118022019",
    "district_id": "111802",
    "label": "Siblah Coh",
    "value": "Siblah Coh"
  },
  {
    "id": "1118022020",
    "district_id": "111802",
    "label": "Bidok",
    "value": "Bidok"
  },
  {
    "id": "1118022021",
    "district_id": "111802",
    "label": "Pantang Cot Baloi",
    "value": "Pantang Cot Baloi"
  },
  {
    "id": "1118022022",
    "district_id": "111802",
    "label": "Meunasah Pupu",
    "value": "Meunasah Pupu"
  },
  {
    "id": "1118022023",
    "district_id": "111802",
    "label": "Nangrhoe Barat",
    "value": "Nangrhoe Barat"
  },
  {
    "id": "1118022024",
    "district_id": "111802",
    "label": "Nangrhoe Timu",
    "value": "Nangrhoe Timu"
  },
  {
    "id": "1118022025",
    "district_id": "111802",
    "label": "Meunasah Mesjid",
    "value": "Meunasah Mesjid"
  },
  {
    "id": "1118022026",
    "district_id": "111802",
    "label": "Blang Rheue",
    "value": "Blang Rheue"
  },
  {
    "id": "1118022027",
    "district_id": "111802",
    "label": "Cot Seutui",
    "value": "Cot Seutui"
  },
  {
    "id": "1118022028",
    "district_id": "111802",
    "label": "Lhok Gajah",
    "value": "Lhok Gajah"
  },
  {
    "id": "1118022029",
    "district_id": "111802",
    "label": "Alue Keumiki",
    "value": "Alue Keumiki"
  },
  {
    "id": "1118022030",
    "district_id": "111802",
    "label": "Blang Cari",
    "value": "Blang Cari"
  },
  {
    "id": "1118032001",
    "district_id": "111803",
    "label": "Meuko Jurong",
    "value": "Meuko Jurong"
  },
  {
    "id": "1118032002",
    "district_id": "111803",
    "label": "Kuta Baroh",
    "value": "Kuta Baroh"
  },
  {
    "id": "1118032003",
    "district_id": "111803",
    "label": "Meuko Meugit",
    "value": "Meuko Meugit"
  },
  {
    "id": "1118032004",
    "district_id": "111803",
    "label": "Kiran Dayah",
    "value": "Kiran Dayah"
  },
  {
    "id": "1118032005",
    "district_id": "111803",
    "label": "Kiran Baroh",
    "value": "Kiran Baroh"
  },
  {
    "id": "1118032006",
    "district_id": "111803",
    "label": "Keurisi Meunasah Raya",
    "value": "Keurisi Meunasah Raya"
  },
  {
    "id": "1118032007",
    "district_id": "111803",
    "label": "Keurisi Meunasah Lueng",
    "value": "Keurisi Meunasah Lueng"
  },
  {
    "id": "1118032008",
    "district_id": "111803",
    "label": "Keurisi Meunasah Beurembang",
    "value": "Keurisi Meunasah Beurembang"
  },
  {
    "id": "1118032009",
    "district_id": "111803",
    "label": "Meunasah Mee",
    "value": "Meunasah Mee"
  },
  {
    "id": "1118032010",
    "district_id": "111803",
    "label": "Meunasah Kumbang",
    "value": "Meunasah Kumbang"
  },
  {
    "id": "1118032011",
    "district_id": "111803",
    "label": "Kiran Krueng",
    "value": "Kiran Krueng"
  },
  {
    "id": "1118032012",
    "district_id": "111803",
    "label": "Buket Teungoh",
    "value": "Buket Teungoh"
  },
  {
    "id": "1118032013",
    "district_id": "111803",
    "label": "Reului Mangat",
    "value": "Reului Mangat"
  },
  {
    "id": "1118032014",
    "district_id": "111803",
    "label": "Gampong Cot",
    "value": "Gampong Cot"
  },
  {
    "id": "1118032015",
    "district_id": "111803",
    "label": "Jurong Binje",
    "value": "Jurong Binje"
  },
  {
    "id": "1118032016",
    "district_id": "111803",
    "label": "Jurong Teungoh",
    "value": "Jurong Teungoh"
  },
  {
    "id": "1118032017",
    "district_id": "111803",
    "label": "Jurong Ara",
    "value": "Jurong Ara"
  },
  {
    "id": "1118032018",
    "district_id": "111803",
    "label": "Keude Jangka Buya",
    "value": "Keude Jangka Buya"
  },
  {
    "id": "1118042001",
    "district_id": "111804",
    "label": "Keude Ulee Gle",
    "value": "Keude Ulee Gle"
  },
  {
    "id": "1118042002",
    "district_id": "111804",
    "label": "Gampong Ulee Gle",
    "value": "Gampong Ulee Gle"
  },
  {
    "id": "1118042003",
    "district_id": "111804",
    "label": "Blang Dalam",
    "value": "Blang Dalam"
  },
  {
    "id": "1118042004",
    "district_id": "111804",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "1118042005",
    "district_id": "111804",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1118042006",
    "district_id": "111804",
    "label": "Meuko Kuthang",
    "value": "Meuko Kuthang"
  },
  {
    "id": "1118042007",
    "district_id": "111804",
    "label": "Uteun Bayu",
    "value": "Uteun Bayu"
  },
  {
    "id": "1118042008",
    "district_id": "111804",
    "label": "Alue Ketapang",
    "value": "Alue Ketapang"
  },
  {
    "id": "1118042009",
    "district_id": "111804",
    "label": "Jaulanga Barat",
    "value": "Jaulanga Barat"
  },
  {
    "id": "1118042010",
    "district_id": "111804",
    "label": "Kumba",
    "value": "Kumba"
  },
  {
    "id": "1118042011",
    "district_id": "111804",
    "label": "Babah Krueng",
    "value": "Babah Krueng"
  },
  {
    "id": "1118042012",
    "district_id": "111804",
    "label": "Pohroh",
    "value": "Pohroh"
  },
  {
    "id": "1118042013",
    "district_id": "111804",
    "label": "Seunong",
    "value": "Seunong"
  },
  {
    "id": "1118042014",
    "district_id": "111804",
    "label": "Alue Mee",
    "value": "Alue Mee"
  },
  {
    "id": "1118042015",
    "district_id": "111804",
    "label": "Blang Kuta",
    "value": "Blang Kuta"
  },
  {
    "id": "1118042016",
    "district_id": "111804",
    "label": "Drien Tujoh",
    "value": "Drien Tujoh"
  },
  {
    "id": "1118042017",
    "district_id": "111804",
    "label": "Alue Sane",
    "value": "Alue Sane"
  },
  {
    "id": "1118042018",
    "district_id": "111804",
    "label": "Gahru",
    "value": "Gahru"
  },
  {
    "id": "1118042019",
    "district_id": "111804",
    "label": "Lhok Pusong",
    "value": "Lhok Pusong"
  },
  {
    "id": "1118042020",
    "district_id": "111804",
    "label": "Paya Pisang Klat",
    "value": "Paya Pisang Klat"
  },
  {
    "id": "1118042021",
    "district_id": "111804",
    "label": "Cot Geurefai",
    "value": "Cot Geurefai"
  },
  {
    "id": "1118042022",
    "district_id": "111804",
    "label": "Muko Dayah",
    "value": "Muko Dayah"
  },
  {
    "id": "1118042023",
    "district_id": "111804",
    "label": "Meuko Buloh",
    "value": "Meuko Buloh"
  },
  {
    "id": "1118042024",
    "district_id": "111804",
    "label": "Peulakan Tunong",
    "value": "Peulakan Tunong"
  },
  {
    "id": "1118042025",
    "district_id": "111804",
    "label": "Adan",
    "value": "Adan"
  },
  {
    "id": "1118042026",
    "district_id": "111804",
    "label": "Meugit Kayee Panyang",
    "value": "Meugit Kayee Panyang"
  },
  {
    "id": "1118042027",
    "district_id": "111804",
    "label": "Meugit Sagoe",
    "value": "Meugit Sagoe"
  },
  {
    "id": "1118042028",
    "district_id": "111804",
    "label": "Meurandeh Alue",
    "value": "Meurandeh Alue"
  },
  {
    "id": "1118042029",
    "district_id": "111804",
    "label": "Reudeup Melayu",
    "value": "Reudeup Melayu"
  },
  {
    "id": "1118042030",
    "district_id": "111804",
    "label": "Meunasah Paku",
    "value": "Meunasah Paku"
  },
  {
    "id": "1118042031",
    "district_id": "111804",
    "label": "Jeulanga Mata Ie",
    "value": "Jeulanga Mata Ie"
  },
  {
    "id": "1118042032",
    "district_id": "111804",
    "label": "Jeulanga Mesjid",
    "value": "Jeulanga Mesjid"
  },
  {
    "id": "1118042033",
    "district_id": "111804",
    "label": "Pulo Gapu",
    "value": "Pulo Gapu"
  },
  {
    "id": "1118042034",
    "district_id": "111804",
    "label": "Blang Miroe",
    "value": "Blang Miroe"
  },
  {
    "id": "1118042035",
    "district_id": "111804",
    "label": "Beurasan",
    "value": "Beurasan"
  },
  {
    "id": "1118042036",
    "district_id": "111804",
    "label": "Cot Keng",
    "value": "Cot Keng"
  },
  {
    "id": "1118042037",
    "district_id": "111804",
    "label": "Krueng Kiran",
    "value": "Krueng Kiran"
  },
  {
    "id": "1118042038",
    "district_id": "111804",
    "label": "Asan Kumbang",
    "value": "Asan Kumbang"
  },
  {
    "id": "1118042039",
    "district_id": "111804",
    "label": "Drien Bungong",
    "value": "Drien Bungong"
  },
  {
    "id": "1118042040",
    "district_id": "111804",
    "label": "Peulakan Cebrek",
    "value": "Peulakan Cebrek"
  },
  {
    "id": "1118042041",
    "district_id": "111804",
    "label": "Paya Tunong",
    "value": "Paya Tunong"
  },
  {
    "id": "1118042042",
    "district_id": "111804",
    "label": "Peulakan Tambo",
    "value": "Peulakan Tambo"
  },
  {
    "id": "1118042043",
    "district_id": "111804",
    "label": "Paya Baroh",
    "value": "Paya Baroh"
  },
  {
    "id": "1118042044",
    "district_id": "111804",
    "label": "Meuko Baroh",
    "value": "Meuko Baroh"
  },
  {
    "id": "1118042045",
    "district_id": "111804",
    "label": "Kuta Krueng",
    "value": "Kuta Krueng"
  },
  {
    "id": "1118052001",
    "district_id": "111805",
    "label": "Lhok Sandeng",
    "value": "Lhok Sandeng"
  },
  {
    "id": "1118052002",
    "district_id": "111805",
    "label": "Sarah Mane",
    "value": "Sarah Mane"
  },
  {
    "id": "1118052003",
    "district_id": "111805",
    "label": "Seunong",
    "value": "Seunong"
  },
  {
    "id": "1118052004",
    "district_id": "111805",
    "label": "Lancok",
    "value": "Lancok"
  },
  {
    "id": "1118052005",
    "district_id": "111805",
    "label": "Meunasah Kulam",
    "value": "Meunasah Kulam"
  },
  {
    "id": "1118052006",
    "district_id": "111805",
    "label": "Meunasah Teungoh",
    "value": "Meunasah Teungoh"
  },
  {
    "id": "1118052007",
    "district_id": "111805",
    "label": "Meunasah Bie",
    "value": "Meunasah Bie"
  },
  {
    "id": "1118052008",
    "district_id": "111805",
    "label": "Meunasah Raya",
    "value": "Meunasah Raya"
  },
  {
    "id": "1118052009",
    "district_id": "111805",
    "label": "Geunteng",
    "value": "Geunteng"
  },
  {
    "id": "1118052010",
    "district_id": "111805",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1118052011",
    "district_id": "111805",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1118052012",
    "district_id": "111805",
    "label": "Dayah Kruet",
    "value": "Dayah Kruet"
  },
  {
    "id": "1118052013",
    "district_id": "111805",
    "label": "Meunasah Mancang",
    "value": "Meunasah Mancang"
  },
  {
    "id": "1118052014",
    "district_id": "111805",
    "label": "Dayah Usen",
    "value": "Dayah Usen"
  },
  {
    "id": "1118052015",
    "district_id": "111805",
    "label": "Lueng Bimba",
    "value": "Lueng Bimba"
  },
  {
    "id": "1118052016",
    "district_id": "111805",
    "label": "Beuringen",
    "value": "Beuringen"
  },
  {
    "id": "1118052017",
    "district_id": "111805",
    "label": "Buangan",
    "value": "Buangan"
  },
  {
    "id": "1118052018",
    "district_id": "111805",
    "label": "Meunasah Jurong",
    "value": "Meunasah Jurong"
  },
  {
    "id": "1118052019",
    "district_id": "111805",
    "label": "Pante Beureune",
    "value": "Pante Beureune"
  },
  {
    "id": "1118062001",
    "district_id": "111806",
    "label": "Cut Nyong",
    "value": "Cut Nyong"
  },
  {
    "id": "1118062002",
    "district_id": "111806",
    "label": "Meunasah Gampong",
    "value": "Meunasah Gampong"
  },
  {
    "id": "1118062003",
    "district_id": "111806",
    "label": "Baro",
    "value": "Baro"
  },
  {
    "id": "1118062004",
    "district_id": "111806",
    "label": "Pulo Rheng",
    "value": "Pulo Rheng"
  },
  {
    "id": "1118062005",
    "district_id": "111806",
    "label": "Dayah Nyong",
    "value": "Dayah Nyong"
  },
  {
    "id": "1118062006",
    "district_id": "111806",
    "label": "Daboih",
    "value": "Daboih"
  },
  {
    "id": "1118062007",
    "district_id": "111806",
    "label": "Beurandeh",
    "value": "Beurandeh"
  },
  {
    "id": "1118062008",
    "district_id": "111806",
    "label": "Kaye Raya",
    "value": "Kaye Raya"
  },
  {
    "id": "1118062009",
    "district_id": "111806",
    "label": "Blang Glong",
    "value": "Blang Glong"
  },
  {
    "id": "1118062010",
    "district_id": "111806",
    "label": "Tutong",
    "value": "Tutong"
  },
  {
    "id": "1118062011",
    "district_id": "111806",
    "label": "Siren",
    "value": "Siren"
  },
  {
    "id": "1118062012",
    "district_id": "111806",
    "label": "Keude Lueng Putu",
    "value": "Keude Lueng Putu"
  },
  {
    "id": "1118062013",
    "district_id": "111806",
    "label": "Peub Lueng Nibong",
    "value": "Peub Lueng Nibong"
  },
  {
    "id": "1118062014",
    "district_id": "111806",
    "label": "Baroh Musa",
    "value": "Baroh Musa"
  },
  {
    "id": "1118062015",
    "district_id": "111806",
    "label": "Musa Teungoh",
    "value": "Musa Teungoh"
  },
  {
    "id": "1118062016",
    "district_id": "111806",
    "label": "Balee",
    "value": "Balee"
  },
  {
    "id": "1118062017",
    "district_id": "111806",
    "label": "Udeung",
    "value": "Udeung"
  },
  {
    "id": "1118062018",
    "district_id": "111806",
    "label": "Ara",
    "value": "Ara"
  },
  {
    "id": "1118062019",
    "district_id": "111806",
    "label": "Baroh Lancok",
    "value": "Baroh Lancok"
  },
  {
    "id": "1118062020",
    "district_id": "111806",
    "label": "Manyang",
    "value": "Manyang"
  },
  {
    "id": "1118062021",
    "district_id": "111806",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1118062022",
    "district_id": "111806",
    "label": "Pulo Pueb",
    "value": "Pulo Pueb"
  },
  {
    "id": "1118062023",
    "district_id": "111806",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "1118062024",
    "district_id": "111806",
    "label": "Tuha Lada",
    "value": "Tuha Lada"
  },
  {
    "id": "1118062025",
    "district_id": "111806",
    "label": "Cut Langgien",
    "value": "Cut Langgien"
  },
  {
    "id": "1118062026",
    "district_id": "111806",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1118062027",
    "district_id": "111806",
    "label": "Dayah Langgien",
    "value": "Dayah Langgien"
  },
  {
    "id": "1118062028",
    "district_id": "111806",
    "label": "Baroh Cot",
    "value": "Baroh Cot"
  },
  {
    "id": "1118062029",
    "district_id": "111806",
    "label": "Blang Iboh",
    "value": "Blang Iboh"
  },
  {
    "id": "1118062030",
    "district_id": "111806",
    "label": "Blang Sukon",
    "value": "Blang Sukon"
  },
  {
    "id": "1118062031",
    "district_id": "111806",
    "label": "Kayee Jatoe",
    "value": "Kayee Jatoe"
  },
  {
    "id": "1118062032",
    "district_id": "111806",
    "label": "Blang Baro",
    "value": "Blang Baro"
  },
  {
    "id": "1118062033",
    "district_id": "111806",
    "label": "Lancang",
    "value": "Lancang"
  },
  {
    "id": "1118062034",
    "district_id": "111806",
    "label": "Keude Paru",
    "value": "Keude Paru"
  },
  {
    "id": "1118062035",
    "district_id": "111806",
    "label": "Blang Krueng",
    "value": "Blang Krueng"
  },
  {
    "id": "1118062036",
    "district_id": "111806",
    "label": "Jijiem",
    "value": "Jijiem"
  },
  {
    "id": "1118062037",
    "district_id": "111806",
    "label": "Sarah Panyang",
    "value": "Sarah Panyang"
  },
  {
    "id": "1118062038",
    "district_id": "111806",
    "label": "Abah Lueng",
    "value": "Abah Lueng"
  },
  {
    "id": "1118062039",
    "district_id": "111806",
    "label": "Aki Neungoh",
    "value": "Aki Neungoh"
  },
  {
    "id": "1118062040",
    "district_id": "111806",
    "label": "Ujong Lebat",
    "value": "Ujong Lebat"
  },
  {
    "id": "1118062041",
    "district_id": "111806",
    "label": "Alue",
    "value": "Alue"
  },
  {
    "id": "1118062042",
    "district_id": "111806",
    "label": "Tanoh Mirah",
    "value": "Tanoh Mirah"
  },
  {
    "id": "1118062043",
    "district_id": "111806",
    "label": "Paru Cot",
    "value": "Paru Cot"
  },
  {
    "id": "1118072001",
    "district_id": "111807",
    "label": "Keude Panteraja",
    "value": "Keude Panteraja"
  },
  { "id": "1118072002", "district_id": "111807", "label": "Tu", "value": "Tu" },
  {
    "id": "1118072003",
    "district_id": "111807",
    "label": "Hagu",
    "value": "Hagu"
  },
  {
    "id": "1118072004",
    "district_id": "111807",
    "label": "Muka Blang",
    "value": "Muka Blang"
  },
  {
    "id": "1118072005",
    "district_id": "111807",
    "label": "Lhok Puuk",
    "value": "Lhok Puuk"
  },
  {
    "id": "1118072006",
    "district_id": "111807",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1118072007",
    "district_id": "111807",
    "label": "Teungoh",
    "value": "Teungoh"
  },
  {
    "id": "1118072008",
    "district_id": "111807",
    "label": "Tunong",
    "value": "Tunong"
  },
  {
    "id": "1118072009",
    "district_id": "111807",
    "label": "Peurade",
    "value": "Peurade"
  },
  {
    "id": "1118072010",
    "district_id": "111807",
    "label": "Reudeup",
    "value": "Reudeup"
  },
  {
    "id": "1118082001",
    "district_id": "111808",
    "label": "Sagoe",
    "value": "Sagoe"
  },
  {
    "id": "1118082002",
    "district_id": "111808",
    "label": "Mee Peuduek",
    "value": "Mee Peuduek"
  },
  {
    "id": "1118082003",
    "district_id": "111808",
    "label": "Mesjid Peuduek",
    "value": "Mesjid Peuduek"
  },
  {
    "id": "1118082004",
    "district_id": "111808",
    "label": "Tuha",
    "value": "Tuha"
  },
  {
    "id": "1118082005",
    "district_id": "111808",
    "label": "Reuseb",
    "value": "Reuseb"
  },
  {
    "id": "1118082006",
    "district_id": "111808",
    "label": "Dee",
    "value": "Dee"
  },
  {
    "id": "1118082007",
    "district_id": "111808",
    "label": "Panton Raya",
    "value": "Panton Raya"
  },
  {
    "id": "1118082008",
    "district_id": "111808",
    "label": "Deah Teumanah",
    "value": "Deah Teumanah"
  },
  {
    "id": "1118082009",
    "district_id": "111808",
    "label": "Tampui",
    "value": "Tampui"
  },
  {
    "id": "1118082010",
    "district_id": "111808",
    "label": "Keude Trienggadeng",
    "value": "Keude Trienggadeng"
  },
  {
    "id": "1118082011",
    "district_id": "111808",
    "label": "Raya",
    "value": "Raya"
  },
  {
    "id": "1118082012",
    "district_id": "111808",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1118082013",
    "district_id": "111808",
    "label": "Meue",
    "value": "Meue"
  },
  {
    "id": "1118082014",
    "district_id": "111808",
    "label": "Mesjid Trienggadeng",
    "value": "Mesjid Trienggadeng"
  },
  {
    "id": "1118082015",
    "district_id": "111808",
    "label": "Teungkluet",
    "value": "Teungkluet"
  },
  {
    "id": "1118082016",
    "district_id": "111808",
    "label": "Matang",
    "value": "Matang"
  },
  {
    "id": "1118082017",
    "district_id": "111808",
    "label": "Deah Ujong Baroh",
    "value": "Deah Ujong Baroh"
  },
  {
    "id": "1118082018",
    "district_id": "111808",
    "label": "Peulandok Teungoh",
    "value": "Peulandok Teungoh"
  },
  {
    "id": "1118082019",
    "district_id": "111808",
    "label": "Peulandok Tunong",
    "value": "Peulandok Tunong"
  },
  {
    "id": "1118082020",
    "district_id": "111808",
    "label": "Deah Pangwa",
    "value": "Deah Pangwa"
  },
  {
    "id": "1118082021",
    "district_id": "111808",
    "label": "Meucat",
    "value": "Meucat"
  },
  {
    "id": "1118082022",
    "district_id": "111808",
    "label": "Cot Makaso",
    "value": "Cot Makaso"
  },
  {
    "id": "1118082023",
    "district_id": "111808",
    "label": "Buloh",
    "value": "Buloh"
  },
  {
    "id": "1118082024",
    "district_id": "111808",
    "label": "Mee Pangwa",
    "value": "Mee Pangwa"
  },
  {
    "id": "1118082025",
    "district_id": "111808",
    "label": "Kuta Pangwa",
    "value": "Kuta Pangwa"
  },
  {
    "id": "1118082026",
    "district_id": "111808",
    "label": "Cot Lheue Rheng",
    "value": "Cot Lheue Rheng"
  },
  {
    "id": "1118082027",
    "district_id": "111808",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "1171012001",
    "district_id": "117101",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1171012002",
    "district_id": "117101",
    "label": "Neusu Jaya",
    "value": "Neusu Jaya"
  },
  {
    "id": "1171012003",
    "district_id": "117101",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "1171012004",
    "district_id": "117101",
    "label": "Seutui",
    "value": "Seutui"
  },
  {
    "id": "1171012005",
    "district_id": "117101",
    "label": "Peuniti",
    "value": "Peuniti"
  },
  {
    "id": "1171012006",
    "district_id": "117101",
    "label": "Neusu Aceh",
    "value": "Neusu Aceh"
  },
  {
    "id": "1171012007",
    "district_id": "117101",
    "label": "Ateuk Pahlawan",
    "value": "Ateuk Pahlawan"
  },
  {
    "id": "1171012008",
    "district_id": "117101",
    "label": "Ateuk Jawo",
    "value": "Ateuk Jawo"
  },
  {
    "id": "1171012009",
    "district_id": "117101",
    "label": "Ateuk Deah Tanoh",
    "value": "Ateuk Deah Tanoh"
  },
  {
    "id": "1171012010",
    "district_id": "117101",
    "label": "Ateuk Munjeng",
    "value": "Ateuk Munjeng"
  },
  {
    "id": "1171022001",
    "district_id": "117102",
    "label": "Mulia",
    "value": "Mulia"
  },
  {
    "id": "1171022002",
    "district_id": "117102",
    "label": "Peunayong",
    "value": "Peunayong"
  },
  {
    "id": "1171022003",
    "district_id": "117102",
    "label": "Laksana",
    "value": "Laksana"
  },
  {
    "id": "1171022004",
    "district_id": "117102",
    "label": "Keuramat",
    "value": "Keuramat"
  },
  {
    "id": "1171022005",
    "district_id": "117102",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1171022006",
    "district_id": "117102",
    "label": "Beurawe",
    "value": "Beurawe"
  },
  {
    "id": "1171022007",
    "district_id": "117102",
    "label": "Kuta Alam",
    "value": "Kuta Alam"
  },
  {
    "id": "1171022008",
    "district_id": "117102",
    "label": "Bandar Baru",
    "value": "Bandar Baru"
  },
  {
    "id": "1171022009",
    "district_id": "117102",
    "label": "Lamdingin",
    "value": "Lamdingin"
  },
  {
    "id": "1171022010",
    "district_id": "117102",
    "label": "Lampulo",
    "value": "Lampulo"
  },
  {
    "id": "1171022011",
    "district_id": "117102",
    "label": "Lambaro Skep",
    "value": "Lambaro Skep"
  },
  {
    "id": "1171032001",
    "district_id": "117103",
    "label": "Punge Jurong",
    "value": "Punge Jurong"
  },
  {
    "id": "1171032002",
    "district_id": "117103",
    "label": "Ulee Lheue",
    "value": "Ulee Lheue"
  },
  {
    "id": "1171032003",
    "district_id": "117103",
    "label": "Lambung",
    "value": "Lambung"
  },
  {
    "id": "1171032004",
    "district_id": "117103",
    "label": "Deah Glumpang",
    "value": "Deah Glumpang"
  },
  {
    "id": "1171032005",
    "district_id": "117103",
    "label": "Deah Baro",
    "value": "Deah Baro"
  },
  {
    "id": "1171032006",
    "district_id": "117103",
    "label": "Alue Deah Teungoh",
    "value": "Alue Deah Teungoh"
  },
  {
    "id": "1171032007",
    "district_id": "117103",
    "label": "Lampaseh Aceh",
    "value": "Lampaseh Aceh"
  },
  {
    "id": "1171032008",
    "district_id": "117103",
    "label": "Blang Oi",
    "value": "Blang Oi"
  },
  {
    "id": "1171032009",
    "district_id": "117103",
    "label": "Asoe Nanggroe",
    "value": "Asoe Nanggroe"
  },
  {
    "id": "1171032010",
    "district_id": "117103",
    "label": "Gampong Blang",
    "value": "Gampong Blang"
  },
  {
    "id": "1171032011",
    "district_id": "117103",
    "label": "Gampong Pie",
    "value": "Gampong Pie"
  },
  {
    "id": "1171032012",
    "district_id": "117103",
    "label": "Lamjabat",
    "value": "Lamjabat"
  },
  {
    "id": "1171032013",
    "district_id": "117103",
    "label": "Cot Lamkuweuh",
    "value": "Cot Lamkuweuh"
  },
  {
    "id": "1171032014",
    "district_id": "117103",
    "label": "Surien",
    "value": "Surien"
  },
  {
    "id": "1171032015",
    "district_id": "117103",
    "label": "Gampong Baro",
    "value": "Gampong Baro"
  },
  {
    "id": "1171032016",
    "district_id": "117103",
    "label": "Punge Ujong",
    "value": "Punge Ujong"
  },
  {
    "id": "1171042001",
    "district_id": "117104",
    "label": "Jeulingke",
    "value": "Jeulingke"
  },
  {
    "id": "1171042002",
    "district_id": "117104",
    "label": "Tibang",
    "value": "Tibang"
  },
  {
    "id": "1171042003",
    "district_id": "117104",
    "label": "Deah Raya",
    "value": "Deah Raya"
  },
  {
    "id": "1171042004",
    "district_id": "117104",
    "label": "Alue Naga",
    "value": "Alue Naga"
  },
  {
    "id": "1171042005",
    "district_id": "117104",
    "label": "Ie Masen Kayee Adang",
    "value": "Ie Masen Kayee Adang"
  },
  {
    "id": "1171042006",
    "district_id": "117104",
    "label": "Pineung",
    "value": "Pineung"
  },
  {
    "id": "1171042007",
    "district_id": "117104",
    "label": "Lamgugob",
    "value": "Lamgugob"
  },
  {
    "id": "1171042008",
    "district_id": "117104",
    "label": "Kopelma Darussalam",
    "value": "Kopelma Darussalam"
  },
  {
    "id": "1171042009",
    "district_id": "117104",
    "label": "Rukoh",
    "value": "Rukoh"
  },
  {
    "id": "1171042010",
    "district_id": "117104",
    "label": "Peurada",
    "value": "Peurada"
  },
  {
    "id": "1171052001",
    "district_id": "117105",
    "label": "Lueng Bata",
    "value": "Lueng Bata"
  },
  {
    "id": "1171052002",
    "district_id": "117105",
    "label": "Cot Mesjid",
    "value": "Cot Mesjid"
  },
  {
    "id": "1171052003",
    "district_id": "117105",
    "label": "Panteriek",
    "value": "Panteriek"
  },
  {
    "id": "1171052004",
    "district_id": "117105",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1171052005",
    "district_id": "117105",
    "label": "Lamseupeung",
    "value": "Lamseupeung"
  },
  {
    "id": "1171052006",
    "district_id": "117105",
    "label": "Batoh",
    "value": "Batoh"
  },
  {
    "id": "1171052007",
    "district_id": "117105",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "1171052008",
    "district_id": "117105",
    "label": "Lamdom",
    "value": "Lamdom"
  },
  {
    "id": "1171052009",
    "district_id": "117105",
    "label": "Lampaloh",
    "value": "Lampaloh"
  },
  {
    "id": "1171062001",
    "district_id": "117106",
    "label": "Keudah",
    "value": "Keudah"
  },
  {
    "id": "1171062002",
    "district_id": "117106",
    "label": "Peulanggahan",
    "value": "Peulanggahan"
  },
  {
    "id": "1171062003",
    "district_id": "117106",
    "label": "Merduati",
    "value": "Merduati"
  },
  {
    "id": "1171062004",
    "district_id": "117106",
    "label": "Lampaseh Kota",
    "value": "Lampaseh Kota"
  },
  {
    "id": "1171062005",
    "district_id": "117106",
    "label": "Gampong Pande",
    "value": "Gampong Pande"
  },
  {
    "id": "1171062006",
    "district_id": "117106",
    "label": "Gampong Jawa",
    "value": "Gampong Jawa"
  },
  {
    "id": "1171072001",
    "district_id": "117107",
    "label": "Geuceu Komplek",
    "value": "Geuceu Komplek"
  },
  {
    "id": "1171072002",
    "district_id": "117107",
    "label": "Lamlagang",
    "value": "Lamlagang"
  },
  {
    "id": "1171072003",
    "district_id": "117107",
    "label": "Geuceu Iniem",
    "value": "Geuceu Iniem"
  },
  {
    "id": "1171072004",
    "district_id": "117107",
    "label": "Geuceu Kayee Jato",
    "value": "Geuceu Kayee Jato"
  },
  {
    "id": "1171072005",
    "district_id": "117107",
    "label": "Lam Ara",
    "value": "Lam Ara"
  },
  {
    "id": "1171072006",
    "district_id": "117107",
    "label": "Lhong Raya",
    "value": "Lhong Raya"
  },
  {
    "id": "1171072007",
    "district_id": "117107",
    "label": "Mibo",
    "value": "Mibo"
  },
  {
    "id": "1171072008",
    "district_id": "117107",
    "label": "Peunyerat",
    "value": "Peunyerat"
  },
  {
    "id": "1171072009",
    "district_id": "117107",
    "label": "Lhong Cut",
    "value": "Lhong Cut"
  },
  {
    "id": "1171072010",
    "district_id": "117107",
    "label": "Lampeout",
    "value": "Lampeout"
  },
  {
    "id": "1171082001",
    "district_id": "117108",
    "label": "Punge Blang Cut",
    "value": "Punge Blang Cut"
  },
  {
    "id": "1171082002",
    "district_id": "117108",
    "label": "Bitai",
    "value": "Bitai"
  },
  {
    "id": "1171082003",
    "district_id": "117108",
    "label": "Lamjamee",
    "value": "Lamjamee"
  },
  {
    "id": "1171082004",
    "district_id": "117108",
    "label": "Ulee Pata",
    "value": "Ulee Pata"
  },
  {
    "id": "1171082005",
    "district_id": "117108",
    "label": "Lampoh Daya",
    "value": "Lampoh Daya"
  },
  {
    "id": "1171082006",
    "district_id": "117108",
    "label": "Emperom",
    "value": "Emperom"
  },
  {
    "id": "1171082007",
    "district_id": "117108",
    "label": "Geuceu Meunara",
    "value": "Geuceu Meunara"
  },
  {
    "id": "1171082008",
    "district_id": "117108",
    "label": "Lamteumen Timur",
    "value": "Lamteumen Timur"
  },
  {
    "id": "1171082009",
    "district_id": "117108",
    "label": "Lamteumen Barat",
    "value": "Lamteumen Barat"
  },
  {
    "id": "1171092001",
    "district_id": "117109",
    "label": "Lambhuk",
    "value": "Lambhuk"
  },
  {
    "id": "1171092002",
    "district_id": "117109",
    "label": "Lamteh",
    "value": "Lamteh"
  },
  {
    "id": "1171092003",
    "district_id": "117109",
    "label": "Ilie",
    "value": "Ilie"
  },
  {
    "id": "1171092004",
    "district_id": "117109",
    "label": "Pango Raya",
    "value": "Pango Raya"
  },
  {
    "id": "1171092005",
    "district_id": "117109",
    "label": "Pango Deah",
    "value": "Pango Deah"
  },
  {
    "id": "1171092006",
    "district_id": "117109",
    "label": "Ceurih",
    "value": "Ceurih"
  },
  {
    "id": "1171092007",
    "district_id": "117109",
    "label": "Doy",
    "value": "Doy"
  },
  {
    "id": "1171092008",
    "district_id": "117109",
    "label": "Lamglumpang",
    "value": "Lamglumpang"
  },
  {
    "id": "1171092009",
    "district_id": "117109",
    "label": "Ie Masen Ulee Kareng",
    "value": "Ie Masen Ulee Kareng"
  },
  {
    "id": "1172012001",
    "district_id": "117201",
    "label": "Iboih",
    "value": "Iboih"
  },
  {
    "id": "1172012002",
    "district_id": "117201",
    "label": "Batee Shoek",
    "value": "Batee Shoek"
  },
  {
    "id": "1172012003",
    "district_id": "117201",
    "label": "Paya Seunara",
    "value": "Paya Seunara"
  },
  {
    "id": "1172012004",
    "district_id": "117201",
    "label": "Krueng Raya",
    "value": "Krueng Raya"
  },
  {
    "id": "1172012005",
    "district_id": "117201",
    "label": "Aneuk Laot",
    "value": "Aneuk Laot"
  },
  {
    "id": "1172012006",
    "district_id": "117201",
    "label": "Kuta Timu",
    "value": "Kuta Timu"
  },
  {
    "id": "1172012007",
    "district_id": "117201",
    "label": "Kuta Barat",
    "value": "Kuta Barat"
  },
  {
    "id": "1172012008",
    "district_id": "117201",
    "label": "Kuta Ateuh",
    "value": "Kuta Ateuh"
  },
  {
    "id": "1172022001",
    "district_id": "117202",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1172022002",
    "district_id": "117202",
    "label": "Keuneukai",
    "value": "Keuneukai"
  },
  {
    "id": "1172022003",
    "district_id": "117202",
    "label": "Beurawang",
    "value": "Beurawang"
  },
  {
    "id": "1172022004",
    "district_id": "117202",
    "label": "Jaboi",
    "value": "Jaboi"
  },
  {
    "id": "1172022005",
    "district_id": "117202",
    "label": "Balohan",
    "value": "Balohan"
  },
  {
    "id": "1172022006",
    "district_id": "117202",
    "label": "Cot Abeuk",
    "value": "Cot Abeuk"
  },
  {
    "id": "1172022007",
    "district_id": "117202",
    "label": "Cot Ba U",
    "value": "Cot Ba U"
  },
  {
    "id": "1172022008",
    "district_id": "117202",
    "label": "Ie Meulee",
    "value": "Ie Meulee"
  },
  {
    "id": "1172022009",
    "district_id": "117202",
    "label": "Ujoeng Kareung",
    "value": "Ujoeng Kareung"
  },
  {
    "id": "1172022010",
    "district_id": "117202",
    "label": "Anoe Itam",
    "value": "Anoe Itam"
  },
  {
    "id": "1173012002",
    "district_id": "117301",
    "label": "Alue Awe",
    "value": "Alue Awe"
  },
  {
    "id": "1173012003",
    "district_id": "117301",
    "label": "Blang Crum",
    "value": "Blang Crum"
  },
  {
    "id": "1173012004",
    "district_id": "117301",
    "label": "Cut Mamplam",
    "value": "Cut Mamplam"
  },
  {
    "id": "1173012005",
    "district_id": "117301",
    "label": "Meunasah Mee",
    "value": "Meunasah Mee"
  },
  {
    "id": "1173012006",
    "district_id": "117301",
    "label": "Cot Girek Kandang",
    "value": "Cot Girek Kandang"
  },
  {
    "id": "1173012007",
    "district_id": "117301",
    "label": "Keude Cunda",
    "value": "Keude Cunda"
  },
  {
    "id": "1173012008",
    "district_id": "117301",
    "label": "Uteunkot",
    "value": "Uteunkot"
  },
  {
    "id": "1173012009",
    "district_id": "117301",
    "label": "Lhok Mon Puteh",
    "value": "Lhok Mon Puteh"
  },
  {
    "id": "1173012010",
    "district_id": "117301",
    "label": "Meunasah Mesjid",
    "value": "Meunasah Mesjid"
  },
  {
    "id": "1173012011",
    "district_id": "117301",
    "label": "Mns. Panggoi",
    "value": "Mns. Panggoi"
  },
  {
    "id": "1173012013",
    "district_id": "117301",
    "label": "Paya Bili",
    "value": "Paya Bili"
  },
  {
    "id": "1173012014",
    "district_id": "117301",
    "label": "Meunasah Alue",
    "value": "Meunasah Alue"
  },
  {
    "id": "1173012015",
    "district_id": "117301",
    "label": "Paya Punteuet",
    "value": "Paya Punteuet"
  },
  {
    "id": "1173012016",
    "district_id": "117301",
    "label": "Blang Poroh",
    "value": "Blang Poroh"
  },
  {
    "id": "1173012024",
    "district_id": "117301",
    "label": "Meunasah Manyang",
    "value": "Meunasah Manyang"
  },
  {
    "id": "1173012025",
    "district_id": "117301",
    "label": "Meunasah Blang",
    "value": "Meunasah Blang"
  },
  {
    "id": "1173012026",
    "district_id": "117301",
    "label": "Paloh Batee",
    "value": "Paloh Batee"
  },
  {
    "id": "1173022001",
    "district_id": "117302",
    "label": "Kuta Blang",
    "value": "Kuta Blang"
  },
  {
    "id": "1173022002",
    "district_id": "117302",
    "label": "Tumpok Teungoh",
    "value": "Tumpok Teungoh"
  },
  {
    "id": "1173022003",
    "district_id": "117302",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1173022004",
    "district_id": "117302",
    "label": "Kampung Jawa Lhokseumawe",
    "value": "Kampung Jawa Lhokseumawe"
  },
  {
    "id": "1173022005",
    "district_id": "117302",
    "label": "Kota Lhokseumawe",
    "value": "Kota Lhokseumawe"
  },
  {
    "id": "1173022006",
    "district_id": "117302",
    "label": "Mon Geudong",
    "value": "Mon Geudong"
  },
  {
    "id": "1173022007",
    "district_id": "117302",
    "label": "Keude Aceh",
    "value": "Keude Aceh"
  },
  {
    "id": "1173022008",
    "district_id": "117302",
    "label": "Pusong Lhokseumawe",
    "value": "Pusong Lhokseumawe"
  },
  {
    "id": "1173022009",
    "district_id": "117302",
    "label": "Hagu Teungoh",
    "value": "Hagu Teungoh"
  },
  {
    "id": "1173022010",
    "district_id": "117302",
    "label": "Uteun Bayi",
    "value": "Uteun Bayi"
  },
  {
    "id": "1173022011",
    "district_id": "117302",
    "label": "Ujong Blang",
    "value": "Ujong Blang"
  },
  {
    "id": "1173022012",
    "district_id": "117302",
    "label": "Hagu Selatan",
    "value": "Hagu Selatan"
  },
  {
    "id": "1173022013",
    "district_id": "117302",
    "label": "Pusong Baru",
    "value": "Pusong Baru"
  },
  {
    "id": "1173022014",
    "district_id": "117302",
    "label": "Ulee Jalan",
    "value": "Ulee Jalan"
  },
  {
    "id": "1173022015",
    "district_id": "117302",
    "label": "Banda Masen",
    "value": "Banda Masen"
  },
  {
    "id": "1173022016",
    "district_id": "117302",
    "label": "Lancang Garam",
    "value": "Lancang Garam"
  },
  {
    "id": "1173022017",
    "district_id": "117302",
    "label": "Jawa Baroe",
    "value": "Jawa Baroe"
  },
  {
    "id": "1173022018",
    "district_id": "117302",
    "label": "Hagu Barat Laut",
    "value": "Hagu Barat Laut"
  },
  {
    "id": "1173032001",
    "district_id": "117303",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "1173032002",
    "district_id": "117303",
    "label": "Blang Cut",
    "value": "Blang Cut"
  },
  {
    "id": "1173032003",
    "district_id": "117303",
    "label": "Jambo Mesjid",
    "value": "Jambo Mesjid"
  },
  {
    "id": "1173032004",
    "district_id": "117303",
    "label": "Jambo Timu",
    "value": "Jambo Timu"
  },
  {
    "id": "1173032005",
    "district_id": "117303",
    "label": "Tunong",
    "value": "Tunong"
  },
  {
    "id": "1173032006",
    "district_id": "117303",
    "label": "Blang Teue",
    "value": "Blang Teue"
  },
  {
    "id": "1173032007",
    "district_id": "117303",
    "label": "Teungoh",
    "value": "Teungoh"
  },
  {
    "id": "1173032008",
    "district_id": "117303",
    "label": "Baloi",
    "value": "Baloi"
  },
  {
    "id": "1173032009",
    "district_id": "117303",
    "label": "Mane Kareung",
    "value": "Mane Kareung"
  },
  {
    "id": "1173032010",
    "district_id": "117303",
    "label": "Asan Kareung",
    "value": "Asan Kareung"
  },
  {
    "id": "1173032011",
    "district_id": "117303",
    "label": "Rayeuk Kareung",
    "value": "Rayeuk Kareung"
  },
  {
    "id": "1173032012",
    "district_id": "117303",
    "label": "Blang Punteuet",
    "value": "Blang Punteuet"
  },
  {
    "id": "1173032013",
    "district_id": "117303",
    "label": "Kumbang Punteuet",
    "value": "Kumbang Punteuet"
  },
  {
    "id": "1173032014",
    "district_id": "117303",
    "label": "Mesjid Punteuet",
    "value": "Mesjid Punteuet"
  },
  {
    "id": "1173032015",
    "district_id": "117303",
    "label": "Ulee Blang Mane",
    "value": "Ulee Blang Mane"
  },
  {
    "id": "1173032016",
    "district_id": "117303",
    "label": "Keude Punteuet",
    "value": "Keude Punteuet"
  },
  {
    "id": "1173032017",
    "district_id": "117303",
    "label": "Alue Lim",
    "value": "Alue Lim"
  },
  {
    "id": "1173032018",
    "district_id": "117303",
    "label": "Blang Buloh",
    "value": "Blang Buloh"
  },
  {
    "id": "1173032019",
    "district_id": "117303",
    "label": "Blang Weu Panjoe",
    "value": "Blang Weu Panjoe"
  },
  {
    "id": "1173032020",
    "district_id": "117303",
    "label": "Jeulikat",
    "value": "Jeulikat"
  },
  {
    "id": "1173032021",
    "district_id": "117303",
    "label": "Blang Weu Baroh",
    "value": "Blang Weu Baroh"
  },
  {
    "id": "1173032022",
    "district_id": "117303",
    "label": "Seuneubok",
    "value": "Seuneubok"
  },
  {
    "id": "1173042001",
    "district_id": "117304",
    "label": "Batuphat Barat",
    "value": "Batuphat Barat"
  },
  {
    "id": "1173042002",
    "district_id": "117304",
    "label": "Blang Panyang",
    "value": "Blang Panyang"
  },
  {
    "id": "1173042003",
    "district_id": "117304",
    "label": "Meunasah Dayah",
    "value": "Meunasah Dayah"
  },
  {
    "id": "1173042004",
    "district_id": "117304",
    "label": "Paloh Punti",
    "value": "Paloh Punti"
  },
  {
    "id": "1173042005",
    "district_id": "117304",
    "label": "Blang Pulo",
    "value": "Blang Pulo"
  },
  {
    "id": "1173042006",
    "district_id": "117304",
    "label": "Batuphat Timur",
    "value": "Batuphat Timur"
  },
  {
    "id": "1173042007",
    "district_id": "117304",
    "label": "Blang Naleung Mameh",
    "value": "Blang Naleung Mameh"
  },
  {
    "id": "1173042008",
    "district_id": "117304",
    "label": "Cot Trieng",
    "value": "Cot Trieng"
  },
  {
    "id": "1173042009",
    "district_id": "117304",
    "label": "Padang Sakti",
    "value": "Padang Sakti"
  },
  {
    "id": "1173042010",
    "district_id": "117304",
    "label": "Meuria Paloh",
    "value": "Meuria Paloh"
  },
  {
    "id": "1173042011",
    "district_id": "117304",
    "label": "Ujong Pacu",
    "value": "Ujong Pacu"
  },
  {
    "id": "1174012007",
    "district_id": "117401",
    "label": "Buket Medang Ara",
    "value": "Buket Medang Ara"
  },
  {
    "id": "1174012009",
    "district_id": "117401",
    "label": "Matang Seutui",
    "value": "Matang Seutui"
  },
  {
    "id": "1174012010",
    "district_id": "117401",
    "label": "Buket Pulo",
    "value": "Buket Pulo"
  },
  {
    "id": "1174012011",
    "district_id": "117401",
    "label": "Matang Panyang",
    "value": "Matang Panyang"
  },
  {
    "id": "1174012012",
    "district_id": "117401",
    "label": "Simpang Wie",
    "value": "Simpang Wie"
  },
  {
    "id": "1174012013",
    "district_id": "117401",
    "label": "Buket Rata",
    "value": "Buket Rata"
  },
  {
    "id": "1174012014",
    "district_id": "117401",
    "label": "Buket Meutuah",
    "value": "Buket Meutuah"
  },
  {
    "id": "1174012015",
    "district_id": "117401",
    "label": "Alue Merbau",
    "value": "Alue Merbau"
  },
  {
    "id": "1174012016",
    "district_id": "117401",
    "label": "Matang Cengai",
    "value": "Matang Cengai"
  },
  {
    "id": "1174012017",
    "district_id": "117401",
    "label": "Seunebok Antara",
    "value": "Seunebok Antara"
  },
  {
    "id": "1174012018",
    "district_id": "117401",
    "label": "Alue Pineung",
    "value": "Alue Pineung"
  },
  {
    "id": "1174012019",
    "district_id": "117401",
    "label": "Sukarejo",
    "value": "Sukarejo"
  },
  {
    "id": "1174012020",
    "district_id": "117401",
    "label": "Cinta Raja",
    "value": "Cinta Raja"
  },
  {
    "id": "1174012021",
    "district_id": "117401",
    "label": "Sungai Lueng",
    "value": "Sungai Lueng"
  },
  {
    "id": "1174012025",
    "district_id": "117401",
    "label": "Alue Pineung Timue",
    "value": "Alue Pineung Timue"
  },
  {
    "id": "1174012026",
    "district_id": "117401",
    "label": "Kapa",
    "value": "Kapa"
  },
  {
    "id": "1174022005",
    "district_id": "117402",
    "label": "Lhok Banie",
    "value": "Lhok Banie"
  },
  {
    "id": "1174022006",
    "district_id": "117402",
    "label": "PB. Teungoh",
    "value": "PB. Teungoh"
  },
  {
    "id": "1174022007",
    "district_id": "117402",
    "label": "PB. Beuramo",
    "value": "PB. Beuramo"
  },
  {
    "id": "1174022008",
    "district_id": "117402",
    "label": "Simpang Lhee",
    "value": "Simpang Lhee"
  },
  {
    "id": "1174022009",
    "district_id": "117402",
    "label": "Seuriget",
    "value": "Seuriget"
  },
  {
    "id": "1174022015",
    "district_id": "117402",
    "label": "Matang Seulimeng",
    "value": "Matang Seulimeng"
  },
  {
    "id": "1174022016",
    "district_id": "117402",
    "label": "Sungai Pauh",
    "value": "Sungai Pauh"
  },
  {
    "id": "1174022017",
    "district_id": "117402",
    "label": "Kuala Langsa",
    "value": "Kuala Langsa"
  },
  {
    "id": "1174022018",
    "district_id": "117402",
    "label": "Teulaga Tujuh",
    "value": "Teulaga Tujuh"
  },
  {
    "id": "1174022019",
    "district_id": "117402",
    "label": "Serambi Indah",
    "value": "Serambi Indah"
  },
  {
    "id": "1174022020",
    "district_id": "117402",
    "label": "Sungai Pauh Pusaka",
    "value": "Sungai Pauh Pusaka"
  },
  {
    "id": "1174022021",
    "district_id": "117402",
    "label": "Sungai Pauh Tanjong",
    "value": "Sungai Pauh Tanjong"
  },
  {
    "id": "1174022022",
    "district_id": "117402",
    "label": "Sungai Pauh Firdaus",
    "value": "Sungai Pauh Firdaus"
  },
  {
    "id": "1174032001",
    "district_id": "117403",
    "label": "Teungoh",
    "value": "Teungoh"
  },
  {
    "id": "1174032002",
    "district_id": "117403",
    "label": "Peukan Langsa",
    "value": "Peukan Langsa"
  },
  {
    "id": "1174032004",
    "district_id": "117403",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "1174032005",
    "district_id": "117403",
    "label": "Paya Bujok Blang Pase",
    "value": "Paya Bujok Blang Pase"
  },
  {
    "id": "1174032006",
    "district_id": "117403",
    "label": "Blang",
    "value": "Blang"
  },
  {
    "id": "1174032007",
    "district_id": "117403",
    "label": "Alue Beurawe",
    "value": "Alue Beurawe"
  },
  {
    "id": "1174032009",
    "district_id": "117403",
    "label": "Daulat",
    "value": "Daulat"
  },
  {
    "id": "1174032010",
    "district_id": "117403",
    "label": "Meutia",
    "value": "Meutia"
  },
  {
    "id": "1174032011",
    "district_id": "117403",
    "label": "Blang Seunibong",
    "value": "Blang Seunibong"
  },
  {
    "id": "1174032013",
    "district_id": "117403",
    "label": "Tualang Teungoh",
    "value": "Tualang Teungoh"
  },
  {
    "id": "1174042001",
    "district_id": "117404",
    "label": "Pondok Kemuning",
    "value": "Pondok Kemuning"
  },
  {
    "id": "1174042002",
    "district_id": "117404",
    "label": "Seulalah",
    "value": "Seulalah"
  },
  {
    "id": "1174042003",
    "district_id": "117404",
    "label": "Pondok Pabrik",
    "value": "Pondok Pabrik"
  },
  {
    "id": "1174042004",
    "district_id": "117404",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1174042005",
    "district_id": "117404",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1174042006",
    "district_id": "117404",
    "label": "Baro",
    "value": "Baro"
  },
  {
    "id": "1174042007",
    "district_id": "117404",
    "label": "Meurandeh",
    "value": "Meurandeh"
  },
  {
    "id": "1174042008",
    "district_id": "117404",
    "label": "Asam Peutik",
    "value": "Asam Peutik"
  },
  {
    "id": "1174042009",
    "district_id": "117404",
    "label": "Baroh Langsa Lama",
    "value": "Baroh Langsa Lama"
  },
  {
    "id": "1174042010",
    "district_id": "117404",
    "label": "Seulalah Baru",
    "value": "Seulalah Baru"
  },
  {
    "id": "1174042011",
    "district_id": "117404",
    "label": "Sukajadi Kebun Ireng",
    "value": "Sukajadi Kebun Ireng"
  },
  {
    "id": "1174042012",
    "district_id": "117404",
    "label": "Meurandeh Tengah",
    "value": "Meurandeh Tengah"
  },
  {
    "id": "1174042013",
    "district_id": "117404",
    "label": "Meurandeh Dayah",
    "value": "Meurandeh Dayah"
  },
  {
    "id": "1174042014",
    "district_id": "117404",
    "label": "Meurandeh Aceh",
    "value": "Meurandeh Aceh"
  },
  {
    "id": "1174042015",
    "district_id": "117404",
    "label": "Batee Puteh",
    "value": "Batee Puteh"
  },
  {
    "id": "1174052001",
    "district_id": "117405",
    "label": "Timbang Langsa",
    "value": "Timbang Langsa"
  },
  {
    "id": "1174052002",
    "district_id": "117405",
    "label": "Alue Dua",
    "value": "Alue Dua"
  },
  {
    "id": "1174052003",
    "district_id": "117405",
    "label": "Birem Puntong",
    "value": "Birem Puntong"
  },
  {
    "id": "1174052004",
    "district_id": "117405",
    "label": "Paya Bujok Seuleumak",
    "value": "Paya Bujok Seuleumak"
  },
  {
    "id": "1174052005",
    "district_id": "117405",
    "label": "Pondok Kelapa",
    "value": "Pondok Kelapa"
  },
  {
    "id": "1174052006",
    "district_id": "117405",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1174052007",
    "district_id": "117405",
    "label": "Paya Bujok Tunong",
    "value": "Paya Bujok Tunong"
  },
  {
    "id": "1174052008",
    "district_id": "117405",
    "label": "Geudubang Jawa",
    "value": "Geudubang Jawa"
  },
  {
    "id": "1174052009",
    "district_id": "117405",
    "label": "Geudubang Aceh",
    "value": "Geudubang Aceh"
  },
  {
    "id": "1174052010",
    "district_id": "117405",
    "label": "Alue Dua Bakaran Batee",
    "value": "Alue Dua Bakaran Batee"
  },
  {
    "id": "1174052011",
    "district_id": "117405",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "1174052012",
    "district_id": "117405",
    "label": "Sukajadi Makmur",
    "value": "Sukajadi Makmur"
  },
  {
    "id": "1175012001",
    "district_id": "117501",
    "label": "Subulussalam",
    "value": "Subulussalam"
  },
  {
    "id": "1175012002",
    "district_id": "117501",
    "label": "Pegayo",
    "value": "Pegayo"
  },
  {
    "id": "1175012003",
    "district_id": "117501",
    "label": "Sikalondang",
    "value": "Sikalondang"
  },
  {
    "id": "1175012004",
    "district_id": "117501",
    "label": "Buluh Dori",
    "value": "Buluh Dori"
  },
  {
    "id": "1175012005",
    "district_id": "117501",
    "label": "Subulussalam Utara",
    "value": "Subulussalam Utara"
  },
  {
    "id": "1175012006",
    "district_id": "117501",
    "label": "Subulussalam Selatan",
    "value": "Subulussalam Selatan"
  },
  {
    "id": "1175012007",
    "district_id": "117501",
    "label": "Subulussalam Barat",
    "value": "Subulussalam Barat"
  },
  {
    "id": "1175012008",
    "district_id": "117501",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1175012009",
    "district_id": "117501",
    "label": "Tangga Besi",
    "value": "Tangga Besi"
  },
  {
    "id": "1175012010",
    "district_id": "117501",
    "label": "Kuta Cepu",
    "value": "Kuta Cepu"
  },
  {
    "id": "1175012011",
    "district_id": "117501",
    "label": "Pasar Panjang",
    "value": "Pasar Panjang"
  },
  {
    "id": "1175012012",
    "district_id": "117501",
    "label": "Mukti Makmur",
    "value": "Mukti Makmur"
  },
  {
    "id": "1175012013",
    "district_id": "117501",
    "label": "Lae Oram",
    "value": "Lae Oram"
  },
  {
    "id": "1175012014",
    "district_id": "117501",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1175012015",
    "district_id": "117501",
    "label": "Belegen Mulia",
    "value": "Belegen Mulia"
  },
  {
    "id": "1175012016",
    "district_id": "117501",
    "label": "Danau Tras",
    "value": "Danau Tras"
  },
  {
    "id": "1175012017",
    "district_id": "117501",
    "label": "Subulussalam Timur",
    "value": "Subulussalam Timur"
  },
  {
    "id": "1175022001",
    "district_id": "117502",
    "label": "Penanggalan",
    "value": "Penanggalan"
  },
  {
    "id": "1175022002",
    "district_id": "117502",
    "label": "Cepu",
    "value": "Cepu"
  },
  {
    "id": "1175022003",
    "district_id": "117502",
    "label": "Kuta Tengah",
    "value": "Kuta Tengah"
  },
  {
    "id": "1175022004",
    "district_id": "117502",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1175022005",
    "district_id": "117502",
    "label": "Sikelang",
    "value": "Sikelang"
  },
  {
    "id": "1175022006",
    "district_id": "117502",
    "label": "Penuntungan",
    "value": "Penuntungan"
  },
  {
    "id": "1175022007",
    "district_id": "117502",
    "label": "Jontor",
    "value": "Jontor"
  },
  {
    "id": "1175022008",
    "district_id": "117502",
    "label": "Lae Motong",
    "value": "Lae Motong"
  },
  {
    "id": "1175022009",
    "district_id": "117502",
    "label": "Lae Ikan",
    "value": "Lae Ikan"
  },
  {
    "id": "1175022010",
    "district_id": "117502",
    "label": "Lae Bersih",
    "value": "Lae Bersih"
  },
  {
    "id": "1175022011",
    "district_id": "117502",
    "label": "Dasan Raja",
    "value": "Dasan Raja"
  },
  {
    "id": "1175022012",
    "district_id": "117502",
    "label": "Penanggalan Timur",
    "value": "Penanggalan Timur"
  },
  {
    "id": "1175022013",
    "district_id": "117502",
    "label": "Penanggalan Barat",
    "value": "Penanggalan Barat"
  },
  {
    "id": "1175032001",
    "district_id": "117503",
    "label": "Pasar Rundeng",
    "value": "Pasar Rundeng"
  },
  {
    "id": "1175032002",
    "district_id": "117503",
    "label": "Dah",
    "value": "Dah"
  },
  {
    "id": "1175032003",
    "district_id": "117503",
    "label": "Oboh",
    "value": "Oboh"
  },
  {
    "id": "1175032004",
    "district_id": "117503",
    "label": "Sepadan",
    "value": "Sepadan"
  },
  {
    "id": "1175032005",
    "district_id": "117503",
    "label": "Sibuasan",
    "value": "Sibuasan"
  },
  {
    "id": "1175032006",
    "district_id": "117503",
    "label": "Binanga",
    "value": "Binanga"
  },
  {
    "id": "1175032007",
    "district_id": "117503",
    "label": "Kuta Beringin",
    "value": "Kuta Beringin"
  },
  {
    "id": "1175032008",
    "district_id": "117503",
    "label": "Muara Batu-batu",
    "value": "Muara Batu-batu"
  },
  {
    "id": "1175032009",
    "district_id": "117503",
    "label": "Siperkas",
    "value": "Siperkas"
  },
  {
    "id": "1175032010",
    "district_id": "117503",
    "label": "Lae Mate",
    "value": "Lae Mate"
  },
  {
    "id": "1175032011",
    "district_id": "117503",
    "label": "Sibungke",
    "value": "Sibungke"
  },
  {
    "id": "1175032012",
    "district_id": "117503",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "1175032013",
    "district_id": "117503",
    "label": "Teladan Baru",
    "value": "Teladan Baru"
  },
  {
    "id": "1175032014",
    "district_id": "117503",
    "label": "Bulukur Makmur",
    "value": "Bulukur Makmur"
  },
  {
    "id": "1175032015",
    "district_id": "117503",
    "label": "Badar",
    "value": "Badar"
  },
  {
    "id": "1175032016",
    "district_id": "117503",
    "label": "Lae Pemulaan",
    "value": "Lae Pemulaan"
  },
  {
    "id": "1175032017",
    "district_id": "117503",
    "label": "Panglima Saman",
    "value": "Panglima Saman"
  },
  {
    "id": "1175032018",
    "district_id": "117503",
    "label": "Suak Jampak",
    "value": "Suak Jampak"
  },
  {
    "id": "1175032019",
    "district_id": "117503",
    "label": "Geruguh",
    "value": "Geruguh"
  },
  {
    "id": "1175032020",
    "district_id": "117503",
    "label": "Mandilam",
    "value": "Mandilam"
  },
  {
    "id": "1175032021",
    "district_id": "117503",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1175032022",
    "district_id": "117503",
    "label": "Kuala Kepeng",
    "value": "Kuala Kepeng"
  },
  {
    "id": "1175032023",
    "district_id": "117503",
    "label": "Tanah Tumbuh",
    "value": "Tanah Tumbuh"
  },
  {
    "id": "1175042001",
    "district_id": "117504",
    "label": "Pasir Belo",
    "value": "Pasir Belo"
  },
  {
    "id": "1175042002",
    "district_id": "117504",
    "label": "Bawan",
    "value": "Bawan"
  },
  {
    "id": "1175042003",
    "district_id": "117504",
    "label": "Sigrun",
    "value": "Sigrun"
  },
  {
    "id": "1175042004",
    "district_id": "117504",
    "label": "Jabi-jabi",
    "value": "Jabi-jabi"
  },
  {
    "id": "1175042005",
    "district_id": "117504",
    "label": "Lae Simolap",
    "value": "Lae Simolap"
  },
  {
    "id": "1175042006",
    "district_id": "117504",
    "label": "Lae Langge",
    "value": "Lae Langge"
  },
  {
    "id": "1175042007",
    "district_id": "117504",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1175042008",
    "district_id": "117504",
    "label": "Gunung Bakti",
    "value": "Gunung Bakti"
  },
  {
    "id": "1175042009",
    "district_id": "117504",
    "label": "Jambi Baru",
    "value": "Jambi Baru"
  },
  {
    "id": "1175042010",
    "district_id": "117504",
    "label": "Pulo Kedep",
    "value": "Pulo Kedep"
  },
  {
    "id": "1175042011",
    "district_id": "117504",
    "label": "Pulo Belen",
    "value": "Pulo Belen"
  },
  {
    "id": "1175042012",
    "district_id": "117504",
    "label": "Singgersing",
    "value": "Singgersing"
  },
  {
    "id": "1175042013",
    "district_id": "117504",
    "label": "Namo Buaya",
    "value": "Namo Buaya"
  },
  {
    "id": "1175042014",
    "district_id": "117504",
    "label": "Cipare Pare",
    "value": "Cipare Pare"
  },
  {
    "id": "1175042015",
    "district_id": "117504",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1175042016",
    "district_id": "117504",
    "label": "Cipar-Pari Timur",
    "value": "Cipar-Pari Timur"
  },
  {
    "id": "1175042018",
    "district_id": "117504",
    "label": "Darul Makmur",
    "value": "Darul Makmur"
  },
  {
    "id": "1175042019",
    "district_id": "117504",
    "label": "Jabi-Jabi Barat",
    "value": "Jabi-Jabi Barat"
  },
  {
    "id": "1175042020",
    "district_id": "117504",
    "label": "Batu Napal",
    "value": "Batu Napal"
  },
  {
    "id": "1175052001",
    "district_id": "117505",
    "label": "Longkib",
    "value": "Longkib"
  },
  {
    "id": "1175052002",
    "district_id": "117505",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "1175052003",
    "district_id": "117505",
    "label": "Panji",
    "value": "Panji"
  },
  {
    "id": "1175052004",
    "district_id": "117505",
    "label": "Lae Saga",
    "value": "Lae Saga"
  },
  {
    "id": "1175052005",
    "district_id": "117505",
    "label": "Sikerabang",
    "value": "Sikerabang"
  },
  {
    "id": "1175052006",
    "district_id": "117505",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1175052007",
    "district_id": "117505",
    "label": "Bukit Panjang",
    "value": "Bukit Panjang"
  },
  {
    "id": "1175052008",
    "district_id": "117505",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1175052009",
    "district_id": "117505",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1175052010",
    "district_id": "117505",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1201011001",
    "district_id": "120101",
    "label": "Pasar Batu Gerigis",
    "value": "Pasar Batu Gerigis"
  },
  {
    "id": "1201011002",
    "district_id": "120101",
    "label": "Padang Masiang",
    "value": "Padang Masiang"
  },
  {
    "id": "1201012003",
    "district_id": "120101",
    "label": "Kampung Solok",
    "value": "Kampung Solok"
  },
  {
    "id": "1201012004",
    "district_id": "120101",
    "label": "Pasar Terandam",
    "value": "Pasar Terandam"
  },
  {
    "id": "1201012005",
    "district_id": "120101",
    "label": "Sigambo Gambo",
    "value": "Sigambo Gambo"
  },
  {
    "id": "1201012006",
    "district_id": "120101",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "1201012007",
    "district_id": "120101",
    "label": "Kinali",
    "value": "Kinali"
  },
  {
    "id": "1201012008",
    "district_id": "120101",
    "label": "Kampung Mudik",
    "value": "Kampung Mudik"
  },
  {
    "id": "1201012009",
    "district_id": "120101",
    "label": "Aek Dakka",
    "value": "Aek Dakka"
  },
  {
    "id": "1201012010",
    "district_id": "120101",
    "label": "Patupangan",
    "value": "Patupangan"
  },
  {
    "id": "1201012011",
    "district_id": "120101",
    "label": "Gabungan Hasang",
    "value": "Gabungan Hasang"
  },
  {
    "id": "1201012017",
    "district_id": "120101",
    "label": "Bungo Tanjung",
    "value": "Bungo Tanjung"
  },
  {
    "id": "1201012018",
    "district_id": "120101",
    "label": "Kedai Gedang",
    "value": "Kedai Gedang"
  },
  {
    "id": "1201021001",
    "district_id": "120102",
    "label": "Sorkam",
    "value": "Sorkam"
  },
  {
    "id": "1201021002",
    "district_id": "120102",
    "label": "Pargarutan",
    "value": "Pargarutan"
  },
  {
    "id": "1201021004",
    "district_id": "120102",
    "label": "Tarutung Bolak",
    "value": "Tarutung Bolak"
  },
  {
    "id": "1201021009",
    "district_id": "120102",
    "label": "Naipospos Barat",
    "value": "Naipospos Barat"
  },
  {
    "id": "1201022003",
    "district_id": "120102",
    "label": "Teluk Roban",
    "value": "Teluk Roban"
  },
  {
    "id": "1201022005",
    "district_id": "120102",
    "label": "Fajar",
    "value": "Fajar"
  },
  {
    "id": "1201022006",
    "district_id": "120102",
    "label": "Pardamean",
    "value": "Pardamean"
  },
  {
    "id": "1201022007",
    "district_id": "120102",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "1201022008",
    "district_id": "120102",
    "label": "Rianiate",
    "value": "Rianiate"
  },
  {
    "id": "1201022010",
    "district_id": "120102",
    "label": "Gonting Mahe",
    "value": "Gonting Mahe"
  },
  {
    "id": "1201022011",
    "district_id": "120102",
    "label": "Dolok Pantis",
    "value": "Dolok Pantis"
  },
  {
    "id": "1201022014",
    "district_id": "120102",
    "label": "Pea Raja",
    "value": "Pea Raja"
  },
  {
    "id": "1201022015",
    "district_id": "120102",
    "label": "Bottot",
    "value": "Bottot"
  },
  {
    "id": "1201022016",
    "district_id": "120102",
    "label": "Sorkam Tengah",
    "value": "Sorkam Tengah"
  },
  {
    "id": "1201022017",
    "district_id": "120102",
    "label": "Sorkam Kiri",
    "value": "Sorkam Kiri"
  },
  {
    "id": "1201022018",
    "district_id": "120102",
    "label": "Pananggahan",
    "value": "Pananggahan"
  },
  {
    "id": "1201022019",
    "district_id": "120102",
    "label": "Pagaran Julu",
    "value": "Pagaran Julu"
  },
  {
    "id": "1201022020",
    "district_id": "120102",
    "label": "Hite Urat",
    "value": "Hite Urat"
  },
  {
    "id": "1201022021",
    "district_id": "120102",
    "label": "Sihapas",
    "value": "Sihapas"
  },
  {
    "id": "1201022022",
    "district_id": "120102",
    "label": "Nauli",
    "value": "Nauli"
  },
  {
    "id": "1201022023",
    "district_id": "120102",
    "label": "Muara Nauli",
    "value": "Muara Nauli"
  },
  {
    "id": "1201031001",
    "district_id": "120103",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "1201031002",
    "district_id": "120103",
    "label": "Hajoran",
    "value": "Hajoran"
  },
  {
    "id": "1201031003",
    "district_id": "120103",
    "label": "Aek Tolang",
    "value": "Aek Tolang"
  },
  {
    "id": "1201031004",
    "district_id": "120103",
    "label": "Lubuk Tukko",
    "value": "Lubuk Tukko"
  },
  {
    "id": "1201031005",
    "district_id": "120103",
    "label": "Sibuluan Indah",
    "value": "Sibuluan Indah"
  },
  {
    "id": "1201031006",
    "district_id": "120103",
    "label": "Sibuluan Nauli",
    "value": "Sibuluan Nauli"
  },
  {
    "id": "1201031011",
    "district_id": "120103",
    "label": "Aek Sitio-tio",
    "value": "Aek Sitio-tio"
  },
  {
    "id": "1201031012",
    "district_id": "120103",
    "label": "Sibuluan Raya",
    "value": "Sibuluan Raya"
  },
  {
    "id": "1201031013",
    "district_id": "120103",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "1201031016",
    "district_id": "120103",
    "label": "Pandan Wangi",
    "value": "Pandan Wangi"
  },
  {
    "id": "1201031017",
    "district_id": "120103",
    "label": "Lubuk Tukko Baru",
    "value": "Lubuk Tukko Baru"
  },
  {
    "id": "1201031018",
    "district_id": "120103",
    "label": "Sibuluan Baru",
    "value": "Sibuluan Baru"
  },
  {
    "id": "1201031019",
    "district_id": "120103",
    "label": "Sibuluan Terpadu",
    "value": "Sibuluan Terpadu"
  },
  {
    "id": "1201031020",
    "district_id": "120103",
    "label": "Sihaporas Nauli",
    "value": "Sihaporas Nauli"
  },
  {
    "id": "1201031021",
    "district_id": "120103",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1201031022",
    "district_id": "120103",
    "label": "Budi Luhur",
    "value": "Budi Luhur"
  },
  {
    "id": "1201031023",
    "district_id": "120103",
    "label": "Kalangan Indah",
    "value": "Kalangan Indah"
  },
  {
    "id": "1201031024",
    "district_id": "120103",
    "label": "Mangga Dua",
    "value": "Mangga Dua"
  },
  {
    "id": "1201031025",
    "district_id": "120103",
    "label": "Muara Nibung",
    "value": "Muara Nibung"
  },
  {
    "id": "1201031026",
    "district_id": "120103",
    "label": "Hajoran Indah",
    "value": "Hajoran Indah"
  },
  {
    "id": "1201032014",
    "district_id": "120103",
    "label": "Aek Garut",
    "value": "Aek Garut"
  },
  {
    "id": "1201032015",
    "district_id": "120103",
    "label": "Sitio-Tio Hilir",
    "value": "Sitio-Tio Hilir"
  },
  {
    "id": "1201041001",
    "district_id": "120104",
    "label": "Pinangsori",
    "value": "Pinangsori"
  },
  {
    "id": "1201041004",
    "district_id": "120104",
    "label": "Sitonong Bangun",
    "value": "Sitonong Bangun"
  },
  {
    "id": "1201041007",
    "district_id": "120104",
    "label": "Pinangbaru",
    "value": "Pinangbaru"
  },
  {
    "id": "1201041009",
    "district_id": "120104",
    "label": "Albion Prancis",
    "value": "Albion Prancis"
  },
  {
    "id": "1201041010",
    "district_id": "120104",
    "label": "Sori Nauli",
    "value": "Sori Nauli"
  },
  {
    "id": "1201042002",
    "district_id": "120104",
    "label": "Gunung Marijo",
    "value": "Gunung Marijo"
  },
  {
    "id": "1201042003",
    "district_id": "120104",
    "label": "Sihaporas",
    "value": "Sihaporas"
  },
  {
    "id": "1201042005",
    "district_id": "120104",
    "label": "Toga Basir",
    "value": "Toga Basir"
  },
  {
    "id": "1201042006",
    "district_id": "120104",
    "label": "Parjalihotan Baru",
    "value": "Parjalihotan Baru"
  },
  {
    "id": "1201042008",
    "district_id": "120104",
    "label": "Danau Pandan",
    "value": "Danau Pandan"
  },
  {
    "id": "1201051001",
    "district_id": "120105",
    "label": "P.O. Manduamas",
    "value": "P.O. Manduamas"
  },
  {
    "id": "1201051003",
    "district_id": "120105",
    "label": "Binjohara",
    "value": "Binjohara"
  },
  {
    "id": "1201051020",
    "district_id": "120105",
    "label": "Perluasan",
    "value": "Perluasan"
  },
  {
    "id": "1201052002",
    "district_id": "120105",
    "label": "Saragih",
    "value": "Saragih"
  },
  {
    "id": "1201052004",
    "district_id": "120105",
    "label": "Pagaran Nauli",
    "value": "Pagaran Nauli"
  },
  {
    "id": "1201052005",
    "district_id": "120105",
    "label": "Sarma Nauli",
    "value": "Sarma Nauli"
  },
  {
    "id": "1201052006",
    "district_id": "120105",
    "label": "Manduamas Lama",
    "value": "Manduamas Lama"
  },
  {
    "id": "1201052007",
    "district_id": "120105",
    "label": "Tumba",
    "value": "Tumba"
  },
  {
    "id": "1201052008",
    "district_id": "120105",
    "label": "Tumba Jae",
    "value": "Tumba Jae"
  },
  {
    "id": "1201052009",
    "district_id": "120105",
    "label": "Lae Monong",
    "value": "Lae Monong"
  },
  {
    "id": "1201052010",
    "district_id": "120105",
    "label": "Saragih Barat",
    "value": "Saragih Barat"
  },
  {
    "id": "1201052011",
    "district_id": "120105",
    "label": "Saragih Timur",
    "value": "Saragih Timur"
  },
  {
    "id": "1201052012",
    "district_id": "120105",
    "label": "Binjohara Uruk",
    "value": "Binjohara Uruk"
  },
  {
    "id": "1201052013",
    "district_id": "120105",
    "label": "Binjohara Baru",
    "value": "Binjohara Baru"
  },
  {
    "id": "1201052014",
    "district_id": "120105",
    "label": "Tumba Nauli",
    "value": "Tumba Nauli"
  },
  {
    "id": "1201052015",
    "district_id": "120105",
    "label": "Manduamas Baru",
    "value": "Manduamas Baru"
  },
  {
    "id": "1201052016",
    "district_id": "120105",
    "label": "Muara Tapus",
    "value": "Muara Tapus"
  },
  {
    "id": "1201052017",
    "district_id": "120105",
    "label": "Pagaran Baru",
    "value": "Pagaran Baru"
  },
  {
    "id": "1201052018",
    "district_id": "120105",
    "label": "Parsihotangan",
    "value": "Parsihotangan"
  },
  {
    "id": "1201052019",
    "district_id": "120105",
    "label": "Tambahan Nanjur",
    "value": "Tambahan Nanjur"
  },
  {
    "id": "1201061006",
    "district_id": "120106",
    "label": "P.O. Hurlang",
    "value": "P.O. Hurlang"
  },
  {
    "id": "1201061007",
    "district_id": "120106",
    "label": "Kolang Nauli",
    "value": "Kolang Nauli"
  },
  {
    "id": "1201062001",
    "district_id": "120106",
    "label": "Unte Mungkur IV",
    "value": "Unte Mungkur IV"
  },
  {
    "id": "1201062002",
    "district_id": "120106",
    "label": "Unte Mungkur III",
    "value": "Unte Mungkur III"
  },
  {
    "id": "1201062003",
    "district_id": "120106",
    "label": "Unte Mungkur II",
    "value": "Unte Mungkur II"
  },
  {
    "id": "1201062004",
    "district_id": "120106",
    "label": "Unte Mungkur I",
    "value": "Unte Mungkur I"
  },
  {
    "id": "1201062005",
    "district_id": "120106",
    "label": "Satahi Nauli",
    "value": "Satahi Nauli"
  },
  {
    "id": "1201062008",
    "district_id": "120106",
    "label": "Hurlang Muara Nauli",
    "value": "Hurlang Muara Nauli"
  },
  {
    "id": "1201062009",
    "district_id": "120106",
    "label": "Sipakpahi Aek Lobu",
    "value": "Sipakpahi Aek Lobu"
  },
  {
    "id": "1201062010",
    "district_id": "120106",
    "label": "Hodopa Nauli",
    "value": "Hodopa Nauli"
  },
  {
    "id": "1201062011",
    "district_id": "120106",
    "label": "Makarti Nauli",
    "value": "Makarti Nauli"
  },
  {
    "id": "1201062012",
    "district_id": "120106",
    "label": "Rawa Makmur",
    "value": "Rawa Makmur"
  },
  {
    "id": "1201062013",
    "district_id": "120106",
    "label": "Simarpinggang",
    "value": "Simarpinggang"
  },
  {
    "id": "1201062014",
    "district_id": "120106",
    "label": "Pargaringan",
    "value": "Pargaringan"
  },
  {
    "id": "1201071001",
    "district_id": "120107",
    "label": "Tapian Nauli II",
    "value": "Tapian Nauli II"
  },
  {
    "id": "1201072002",
    "district_id": "120107",
    "label": "Tapian Nauli I",
    "value": "Tapian Nauli I"
  },
  {
    "id": "1201072003",
    "district_id": "120107",
    "label": "Tapian Nauli III",
    "value": "Tapian Nauli III"
  },
  {
    "id": "1201072004",
    "district_id": "120107",
    "label": "Tapian Nauli IV",
    "value": "Tapian Nauli IV"
  },
  {
    "id": "1201072005",
    "district_id": "120107",
    "label": "Aloban Bair",
    "value": "Aloban Bair"
  },
  {
    "id": "1201072006",
    "district_id": "120107",
    "label": "Mela Dolok",
    "value": "Mela Dolok"
  },
  {
    "id": "1201072007",
    "district_id": "120107",
    "label": "Mela I",
    "value": "Mela I"
  },
  {
    "id": "1201072008",
    "district_id": "120107",
    "label": "Mela II",
    "value": "Mela II"
  },
  {
    "id": "1201072009",
    "district_id": "120107",
    "label": "Bair",
    "value": "Bair"
  },
  {
    "id": "1201081002",
    "district_id": "120108",
    "label": "Sibabangun",
    "value": "Sibabangun"
  },
  {
    "id": "1201082005",
    "district_id": "120108",
    "label": "Mombang Boru",
    "value": "Mombang Boru"
  },
  {
    "id": "1201082006",
    "district_id": "120108",
    "label": "Anggoli",
    "value": "Anggoli"
  },
  {
    "id": "1201082007",
    "district_id": "120108",
    "label": "Muara Sibuntuon",
    "value": "Muara Sibuntuon"
  },
  {
    "id": "1201082008",
    "district_id": "120108",
    "label": "Sibio bio",
    "value": "Sibio bio"
  },
  {
    "id": "1201082010",
    "district_id": "120108",
    "label": "Simanosor",
    "value": "Simanosor"
  },
  {
    "id": "1201082011",
    "district_id": "120108",
    "label": "Hutagur-gur",
    "value": "Hutagur-gur"
  },
  {
    "id": "1201091001",
    "district_id": "120109",
    "label": "Sosor Gadong",
    "value": "Sosor Gadong"
  },
  {
    "id": "1201092002",
    "district_id": "120109",
    "label": "Unte Boang",
    "value": "Unte Boang"
  },
  {
    "id": "1201092003",
    "district_id": "120109",
    "label": "Sibintang",
    "value": "Sibintang"
  },
  {
    "id": "1201092004",
    "district_id": "120109",
    "label": "Barambang",
    "value": "Barambang"
  },
  {
    "id": "1201092005",
    "district_id": "120109",
    "label": "Siantar Ca.",
    "value": "Siantar Ca."
  },
  {
    "id": "1201092006",
    "district_id": "120109",
    "label": "Muara Bolak",
    "value": "Muara Bolak"
  },
  {
    "id": "1201092007",
    "district_id": "120109",
    "label": "Huta Tombak",
    "value": "Huta Tombak"
  },
  {
    "id": "1201092008",
    "district_id": "120109",
    "label": "Siantar Dolok",
    "value": "Siantar Dolok"
  },
  {
    "id": "1201092009",
    "district_id": "120109",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "1201101001",
    "district_id": "120110",
    "label": "Sorkam Kanan",
    "value": "Sorkam Kanan"
  },
  {
    "id": "1201101019",
    "district_id": "120110",
    "label": "Binasi",
    "value": "Binasi"
  },
  {
    "id": "1201102002",
    "district_id": "120110",
    "label": "Sipea Pea",
    "value": "Sipea Pea"
  },
  {
    "id": "1201102003",
    "district_id": "120110",
    "label": "Pahieme I",
    "value": "Pahieme I"
  },
  {
    "id": "1201102004",
    "district_id": "120110",
    "label": "Pasar Sorkam",
    "value": "Pasar Sorkam"
  },
  {
    "id": "1201102005",
    "district_id": "120110",
    "label": "Aek Raso",
    "value": "Aek Raso"
  },
  {
    "id": "1201102006",
    "district_id": "120110",
    "label": "Pasaribu Tobing Jae",
    "value": "Pasaribu Tobing Jae"
  },
  {
    "id": "1201102014",
    "district_id": "120110",
    "label": "Maduma",
    "value": "Maduma"
  },
  {
    "id": "1201102016",
    "district_id": "120110",
    "label": "Sidikalang",
    "value": "Sidikalang"
  },
  {
    "id": "1201102017",
    "district_id": "120110",
    "label": "Pahieme II",
    "value": "Pahieme II"
  },
  {
    "id": "1201102018",
    "district_id": "120110",
    "label": "Sorkam Kanan",
    "value": "Sorkam Kanan"
  },
  {
    "id": "1201102020",
    "district_id": "120110",
    "label": "Madani",
    "value": "Madani"
  },
  {
    "id": "1201111001",
    "district_id": "120111",
    "label": "Bajamas",
    "value": "Bajamas"
  },
  {
    "id": "1201112002",
    "district_id": "120111",
    "label": "Sampang Maruhur",
    "value": "Sampang Maruhur"
  },
  {
    "id": "1201112003",
    "district_id": "120111",
    "label": "Siordang",
    "value": "Siordang"
  },
  {
    "id": "1201112004",
    "district_id": "120111",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1201112005",
    "district_id": "120111",
    "label": "Simpang III Lae Bingke",
    "value": "Simpang III Lae Bingke"
  },
  {
    "id": "1201112006",
    "district_id": "120111",
    "label": "Sigodung",
    "value": "Sigodung"
  },
  {
    "id": "1201112007",
    "district_id": "120111",
    "label": "Masnauli",
    "value": "Masnauli"
  },
  {
    "id": "1201112008",
    "district_id": "120111",
    "label": "Muara Ode",
    "value": "Muara Ode"
  },
  {
    "id": "1201121007",
    "district_id": "120112",
    "label": "Rina Bolak",
    "value": "Rina Bolak"
  },
  {
    "id": "1201122001",
    "district_id": "120112",
    "label": "Sijungkang",
    "value": "Sijungkang"
  },
  {
    "id": "1201122002",
    "district_id": "120112",
    "label": "Pangaribuan",
    "value": "Pangaribuan"
  },
  {
    "id": "1201122003",
    "district_id": "120112",
    "label": "Sogar",
    "value": "Sogar"
  },
  {
    "id": "1201122004",
    "district_id": "120112",
    "label": "Sirami Ramian",
    "value": "Sirami Ramian"
  },
  {
    "id": "1201122005",
    "district_id": "120112",
    "label": "Sigolang",
    "value": "Sigolang"
  },
  {
    "id": "1201122006",
    "district_id": "120112",
    "label": "Sosor Gonting",
    "value": "Sosor Gonting"
  },
  {
    "id": "1201122008",
    "district_id": "120112",
    "label": "Bondar Sihudon I",
    "value": "Bondar Sihudon I"
  },
  {
    "id": "1201122009",
    "district_id": "120112",
    "label": "Uratan",
    "value": "Uratan"
  },
  {
    "id": "1201122010",
    "district_id": "120112",
    "label": "Ladang Tengah",
    "value": "Ladang Tengah"
  },
  {
    "id": "1201122011",
    "district_id": "120112",
    "label": "Lobu Tua",
    "value": "Lobu Tua"
  },
  {
    "id": "1201122012",
    "district_id": "120112",
    "label": "Bondar Sihudon II",
    "value": "Bondar Sihudon II"
  },
  {
    "id": "1201122013",
    "district_id": "120112",
    "label": "Sitiris-Tiris",
    "value": "Sitiris-Tiris"
  },
  {
    "id": "1201122014",
    "district_id": "120112",
    "label": "Sawah Lamo",
    "value": "Sawah Lamo"
  },
  {
    "id": "1201131003",
    "district_id": "120113",
    "label": "Nauli",
    "value": "Nauli"
  },
  {
    "id": "1201132001",
    "district_id": "120113",
    "label": "Bonandolok",
    "value": "Bonandolok"
  },
  {
    "id": "1201132002",
    "district_id": "120113",
    "label": "Rampa",
    "value": "Rampa"
  },
  {
    "id": "1201132004",
    "district_id": "120113",
    "label": "Mardame",
    "value": "Mardame"
  },
  {
    "id": "1201132005",
    "district_id": "120113",
    "label": "Naga Timbul",
    "value": "Naga Timbul"
  },
  {
    "id": "1201132006",
    "district_id": "120113",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1201141001",
    "district_id": "120114",
    "label": "Tukka",
    "value": "Tukka"
  },
  {
    "id": "1201141002",
    "district_id": "120114",
    "label": "Bona Lumban",
    "value": "Bona Lumban"
  },
  {
    "id": "1201141003",
    "district_id": "120114",
    "label": "Huta Nabolon",
    "value": "Huta Nabolon"
  },
  {
    "id": "1201141007",
    "district_id": "120114",
    "label": "Sipange",
    "value": "Sipange"
  },
  {
    "id": "1201141009",
    "district_id": "120114",
    "label": "Aek Tolang Induk",
    "value": "Aek Tolang Induk"
  },
  {
    "id": "1201142004",
    "district_id": "120114",
    "label": "Sigiring Giring",
    "value": "Sigiring Giring"
  },
  {
    "id": "1201142005",
    "district_id": "120114",
    "label": "Tapian Nauli / Saurmanggita",
    "value": "Tapian Nauli / Saurmanggita"
  },
  {
    "id": "1201142006",
    "district_id": "120114",
    "label": "Sait Kalangan II",
    "value": "Sait Kalangan II"
  },
  {
    "id": "1201142008",
    "district_id": "120114",
    "label": "Aek Bottar",
    "value": "Aek Bottar"
  },
  {
    "id": "1201151001",
    "district_id": "120115",
    "label": "Hutabalang",
    "value": "Hutabalang"
  },
  {
    "id": "1201151002",
    "district_id": "120115",
    "label": "Lopian",
    "value": "Lopian"
  },
  {
    "id": "1201152003",
    "district_id": "120115",
    "label": "Jago Jago",
    "value": "Jago Jago"
  },
  {
    "id": "1201152004",
    "district_id": "120115",
    "label": "Aek Horsik",
    "value": "Aek Horsik"
  },
  {
    "id": "1201152005",
    "district_id": "120115",
    "label": "Gunung Kelambu",
    "value": "Gunung Kelambu"
  },
  {
    "id": "1201152006",
    "district_id": "120115",
    "label": "Kebun Pisang",
    "value": "Kebun Pisang"
  },
  {
    "id": "1201152007",
    "district_id": "120115",
    "label": "Lubuk Ampolu",
    "value": "Lubuk Ampolu"
  },
  {
    "id": "1201152008",
    "district_id": "120115",
    "label": "Pagaran Honas",
    "value": "Pagaran Honas"
  },
  {
    "id": "1201152009",
    "district_id": "120115",
    "label": "Sitardas",
    "value": "Sitardas"
  },
  {
    "id": "1201162001",
    "district_id": "120116",
    "label": "Suga Suga Hutagodang",
    "value": "Suga Suga Hutagodang"
  },
  {
    "id": "1201162002",
    "district_id": "120116",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1201162003",
    "district_id": "120116",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "1201162004",
    "district_id": "120116",
    "label": "Pasaribu Tobing",
    "value": "Pasaribu Tobing"
  },
  {
    "id": "1201162005",
    "district_id": "120116",
    "label": "Aek Nadua",
    "value": "Aek Nadua"
  },
  {
    "id": "1201162006",
    "district_id": "120116",
    "label": "Simargarap",
    "value": "Simargarap"
  },
  {
    "id": "1201162007",
    "district_id": "120116",
    "label": "Sidaling",
    "value": "Sidaling"
  },
  {
    "id": "1201162008",
    "district_id": "120116",
    "label": "Sipakpahi",
    "value": "Sipakpahi"
  },
  {
    "id": "1201162009",
    "district_id": "120116",
    "label": "PO Simargarap",
    "value": "PO Simargarap"
  },
  {
    "id": "1201172001",
    "district_id": "120117",
    "label": "Hutaginjang",
    "value": "Hutaginjang"
  },
  {
    "id": "1201172002",
    "district_id": "120117",
    "label": "Sihorbo",
    "value": "Sihorbo"
  },
  {
    "id": "1201172003",
    "district_id": "120117",
    "label": "Parik Sinomba",
    "value": "Parik Sinomba"
  },
  {
    "id": "1201172004",
    "district_id": "120117",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1201172005",
    "district_id": "120117",
    "label": "Pananggahan",
    "value": "Pananggahan"
  },
  {
    "id": "1201172006",
    "district_id": "120117",
    "label": "Siharbangan",
    "value": "Siharbangan"
  },
  {
    "id": "1201182001",
    "district_id": "120118",
    "label": "Pulo Pakkat",
    "value": "Pulo Pakkat"
  },
  {
    "id": "1201182002",
    "district_id": "120118",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1201182003",
    "district_id": "120118",
    "label": "Pulo Pakkat II",
    "value": "Pulo Pakkat II"
  },
  {
    "id": "1201182004",
    "district_id": "120118",
    "label": "Janji Maria",
    "value": "Janji Maria"
  },
  {
    "id": "1201182005",
    "district_id": "120118",
    "label": "Sihadatuon",
    "value": "Sihadatuon"
  },
  {
    "id": "1201182006",
    "district_id": "120118",
    "label": "Sihapas",
    "value": "Sihapas"
  },
  {
    "id": "1201191001",
    "district_id": "120119",
    "label": "Lumut",
    "value": "Lumut"
  },
  {
    "id": "1201192002",
    "district_id": "120119",
    "label": "Aek Gambir",
    "value": "Aek Gambir"
  },
  {
    "id": "1201192003",
    "district_id": "120119",
    "label": "Masundung",
    "value": "Masundung"
  },
  {
    "id": "1201192005",
    "district_id": "120119",
    "label": "Lumut Nauli",
    "value": "Lumut Nauli"
  },
  {
    "id": "1201192006",
    "district_id": "120119",
    "label": "Lumut Maju",
    "value": "Lumut Maju"
  },
  {
    "id": "1201192007",
    "district_id": "120119",
    "label": "Sialogo",
    "value": "Sialogo"
  },
  {
    "id": "1201201001",
    "district_id": "120120",
    "label": "Sibuluan Nalambok",
    "value": "Sibuluan Nalambok"
  },
  {
    "id": "1201201002",
    "district_id": "120120",
    "label": "Sarudik",
    "value": "Sarudik"
  },
  {
    "id": "1201201004",
    "district_id": "120120",
    "label": "Pasir Bidang",
    "value": "Pasir Bidang"
  },
  {
    "id": "1201201005",
    "district_id": "120120",
    "label": "Pondok Batu",
    "value": "Pondok Batu"
  },
  {
    "id": "1201202003",
    "district_id": "120120",
    "label": "Sipan",
    "value": "Sipan"
  },
  {
    "id": "1202011001",
    "district_id": "120201",
    "label": "Partoli Toruan",
    "value": "Partoli Toruan"
  },
  {
    "id": "1202011002",
    "district_id": "120201",
    "label": "Hutatoruan V",
    "value": "Hutatoruan V"
  },
  {
    "id": "1202011003",
    "district_id": "120201",
    "label": "Hutatoruan VI",
    "value": "Hutatoruan VI"
  },
  {
    "id": "1202011004",
    "district_id": "120201",
    "label": "Hutatoruan VII",
    "value": "Hutatoruan VII"
  },
  {
    "id": "1202011005",
    "district_id": "120201",
    "label": "Hutatoruan IX",
    "value": "Hutatoruan IX"
  },
  {
    "id": "1202011006",
    "district_id": "120201",
    "label": "Hutatoruan X",
    "value": "Hutatoruan X"
  },
  {
    "id": "1202011007",
    "district_id": "120201",
    "label": "Hutatoruan XI",
    "value": "Hutatoruan XI"
  },
  {
    "id": "1202012008",
    "district_id": "120201",
    "label": "Parbubu I",
    "value": "Parbubu I"
  },
  {
    "id": "1202012009",
    "district_id": "120201",
    "label": "Parbubu II",
    "value": "Parbubu II"
  },
  {
    "id": "1202012010",
    "district_id": "120201",
    "label": "Hutapea Banuarea",
    "value": "Hutapea Banuarea"
  },
  {
    "id": "1202012011",
    "district_id": "120201",
    "label": "Siandor-andor",
    "value": "Siandor-andor"
  },
  {
    "id": "1202012012",
    "district_id": "120201",
    "label": "Hutauruk",
    "value": "Hutauruk"
  },
  {
    "id": "1202012013",
    "district_id": "120201",
    "label": "Hapoltahan",
    "value": "Hapoltahan"
  },
  {
    "id": "1202012014",
    "district_id": "120201",
    "label": "Parbubu Dolok",
    "value": "Parbubu Dolok"
  },
  {
    "id": "1202012015",
    "district_id": "120201",
    "label": "Aek Siansimun",
    "value": "Aek Siansimun"
  },
  {
    "id": "1202012016",
    "district_id": "120201",
    "label": "Htgalung Siwaluompo",
    "value": "Htgalung Siwaluompo"
  },
  {
    "id": "1202012017",
    "district_id": "120201",
    "label": "Parbubu Pea",
    "value": "Parbubu Pea"
  },
  {
    "id": "1202012018",
    "district_id": "120201",
    "label": "Hutatoruan IV",
    "value": "Hutatoruan IV"
  },
  {
    "id": "1202012019",
    "district_id": "120201",
    "label": "Sosunggulon",
    "value": "Sosunggulon"
  },
  {
    "id": "1202012020",
    "district_id": "120201",
    "label": "Hutatoruan VIII",
    "value": "Hutatoruan VIII"
  },
  {
    "id": "1202012021",
    "district_id": "120201",
    "label": "Simamora",
    "value": "Simamora"
  },
  {
    "id": "1202012022",
    "district_id": "120201",
    "label": "Parbaju Toruan",
    "value": "Parbaju Toruan"
  },
  {
    "id": "1202012023",
    "district_id": "120201",
    "label": "Parbaju Julu",
    "value": "Parbaju Julu"
  },
  {
    "id": "1202012024",
    "district_id": "120201",
    "label": "Parbaju Tonga",
    "value": "Parbaju Tonga"
  },
  {
    "id": "1202012025",
    "district_id": "120201",
    "label": "Sitampurung",
    "value": "Sitampurung"
  },
  {
    "id": "1202012026",
    "district_id": "120201",
    "label": "Siraja Oloan",
    "value": "Siraja Oloan"
  },
  {
    "id": "1202012027",
    "district_id": "120201",
    "label": "Jambur Nauli",
    "value": "Jambur Nauli"
  },
  {
    "id": "1202012028",
    "district_id": "120201",
    "label": "Sihujur",
    "value": "Sihujur"
  },
  {
    "id": "1202012029",
    "district_id": "120201",
    "label": "Partali Julu",
    "value": "Partali Julu"
  },
  {
    "id": "1202012030",
    "district_id": "120201",
    "label": "Hutatoruan I",
    "value": "Hutatoruan I"
  },
  {
    "id": "1202012031",
    "district_id": "120201",
    "label": "Hutatoruan III",
    "value": "Hutatoruan III"
  },
  {
    "id": "1202022001",
    "district_id": "120202",
    "label": "Siraja Hutagalung",
    "value": "Siraja Hutagalung"
  },
  {
    "id": "1202022002",
    "district_id": "120202",
    "label": "Pansur Napitu",
    "value": "Pansur Napitu"
  },
  {
    "id": "1202022003",
    "district_id": "120202",
    "label": "Simanampang",
    "value": "Simanampang"
  },
  {
    "id": "1202022004",
    "district_id": "120202",
    "label": "Sidagal",
    "value": "Sidagal"
  },
  {
    "id": "1202022005",
    "district_id": "120202",
    "label": "Simarakir Julu",
    "value": "Simarakir Julu"
  },
  {
    "id": "1202022006",
    "district_id": "120202",
    "label": "Enda Portibi",
    "value": "Enda Portibi"
  },
  {
    "id": "1202022007",
    "district_id": "120202",
    "label": "Simorangkir Habinsaran",
    "value": "Simorangkir Habinsaran"
  },
  {
    "id": "1202022008",
    "district_id": "120202",
    "label": "Sangkaran",
    "value": "Sangkaran"
  },
  {
    "id": "1202022009",
    "district_id": "120202",
    "label": "Lumban Siagian Jae",
    "value": "Lumban Siagian Jae"
  },
  {
    "id": "1202022010",
    "district_id": "120202",
    "label": "Lumban Siagian Julu",
    "value": "Lumban Siagian Julu"
  },
  {
    "id": "1202022011",
    "district_id": "120202",
    "label": "Sitompul",
    "value": "Sitompul"
  },
  {
    "id": "1202022012",
    "district_id": "120202",
    "label": "Lobu Hole",
    "value": "Lobu Hole"
  },
  {
    "id": "1202032001",
    "district_id": "120203",
    "label": "Dolok Nauli",
    "value": "Dolok Nauli"
  },
  {
    "id": "1202032002",
    "district_id": "120203",
    "label": "Pansurbatu",
    "value": "Pansurbatu"
  },
  {
    "id": "1202032003",
    "district_id": "120203",
    "label": "Pardomuan Nauli",
    "value": "Pardomuan Nauli"
  },
  {
    "id": "1202032004",
    "district_id": "120203",
    "label": "Siantar Naipospos",
    "value": "Siantar Naipospos"
  },
  {
    "id": "1202032005",
    "district_id": "120203",
    "label": "Sibalanga",
    "value": "Sibalanga"
  },
  {
    "id": "1202032006",
    "district_id": "120203",
    "label": "Pagaran Lambung III",
    "value": "Pagaran Lambung III"
  },
  {
    "id": "1202032007",
    "district_id": "120203",
    "label": "Pagaran Lambung IV",
    "value": "Pagaran Lambung IV"
  },
  {
    "id": "1202032008",
    "district_id": "120203",
    "label": "Adian Koting",
    "value": "Adian Koting"
  },
  {
    "id": "1202032009",
    "district_id": "120203",
    "label": "Pangaran Pisang",
    "value": "Pangaran Pisang"
  },
  {
    "id": "1202032010",
    "district_id": "120203",
    "label": "Banuaji IV",
    "value": "Banuaji IV"
  },
  {
    "id": "1202032011",
    "district_id": "120203",
    "label": "Banuaji I",
    "value": "Banuaji I"
  },
  {
    "id": "1202032012",
    "district_id": "120203",
    "label": "Banuaji II",
    "value": "Banuaji II"
  },
  {
    "id": "1202032013",
    "district_id": "120203",
    "label": "Pangaran Lambung I",
    "value": "Pangaran Lambung I"
  },
  {
    "id": "1202032014",
    "district_id": "120203",
    "label": "Pangaran Lambung II",
    "value": "Pangaran Lambung II"
  },
  {
    "id": "1202032015",
    "district_id": "120203",
    "label": "Pansurbatu I",
    "value": "Pansurbatu I"
  },
  {
    "id": "1202032016",
    "district_id": "120203",
    "label": "Pansurbatu II",
    "value": "Pansurbatu II"
  },
  {
    "id": "1202041001",
    "district_id": "120204",
    "label": "Situmeang Habinsaran",
    "value": "Situmeang Habinsaran"
  },
  {
    "id": "1202042002",
    "district_id": "120204",
    "label": "Hutauruk",
    "value": "Hutauruk"
  },
  {
    "id": "1202042003",
    "district_id": "120204",
    "label": "Simanungkalit",
    "value": "Simanungkalit"
  },
  {
    "id": "1202042004",
    "district_id": "120204",
    "label": "Situmeang Hasundutan",
    "value": "Situmeang Hasundutan"
  },
  {
    "id": "1202042005",
    "district_id": "120204",
    "label": "Sipahutar",
    "value": "Sipahutar"
  },
  {
    "id": "1202042007",
    "district_id": "120204",
    "label": "Tapian Nauli",
    "value": "Tapian Nauli"
  },
  {
    "id": "1202042008",
    "district_id": "120204",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1202042009",
    "district_id": "120204",
    "label": "Lobu Singkam",
    "value": "Lobu Singkam"
  },
  {
    "id": "1202042010",
    "district_id": "120204",
    "label": "Rura Julu Dolok",
    "value": "Rura Julu Dolok"
  },
  {
    "id": "1202042011",
    "district_id": "120204",
    "label": "Rura Julu Toruan",
    "value": "Rura Julu Toruan"
  },
  {
    "id": "1202042012",
    "district_id": "120204",
    "label": "Hutaraja Hasundutan",
    "value": "Hutaraja Hasundutan"
  },
  {
    "id": "1202042013",
    "district_id": "120204",
    "label": "Pagarbatu",
    "value": "Pagarbatu"
  },
  {
    "id": "1202042014",
    "district_id": "120204",
    "label": "Hutaraja Simanungkalit",
    "value": "Hutaraja Simanungkalit"
  },
  {
    "id": "1202042015",
    "district_id": "120204",
    "label": "Hutauruk Hasundutan",
    "value": "Hutauruk Hasundutan"
  },
  {
    "id": "1202051001",
    "district_id": "120205",
    "label": "Onan Hasang",
    "value": "Onan Hasang"
  },
  {
    "id": "1202052002",
    "district_id": "120205",
    "label": "Simasom",
    "value": "Simasom"
  },
  {
    "id": "1202052003",
    "district_id": "120205",
    "label": "Janji Natogu",
    "value": "Janji Natogu"
  },
  {
    "id": "1202052004",
    "district_id": "120205",
    "label": "Pagur Dotan",
    "value": "Pagur Dotan"
  },
  {
    "id": "1202052005",
    "district_id": "120205",
    "label": "Sibaganding",
    "value": "Sibaganding"
  },
  {
    "id": "1202052006",
    "district_id": "120205",
    "label": "Lumban Garaga",
    "value": "Lumban Garaga"
  },
  {
    "id": "1202052007",
    "district_id": "120205",
    "label": "Sitolu Ama",
    "value": "Sitolu Ama"
  },
  {
    "id": "1202052008",
    "district_id": "120205",
    "label": "Lumban Tonga",
    "value": "Lumban Tonga"
  },
  {
    "id": "1202052009",
    "district_id": "120205",
    "label": "Lumban Dolok",
    "value": "Lumban Dolok"
  },
  {
    "id": "1202052010",
    "district_id": "120205",
    "label": "Simataniari",
    "value": "Simataniari"
  },
  {
    "id": "1202052011",
    "district_id": "120205",
    "label": "Lumban Jaean",
    "value": "Lumban Jaean"
  },
  {
    "id": "1202052012",
    "district_id": "120205",
    "label": "Huta Barat",
    "value": "Huta Barat"
  },
  {
    "id": "1202052013",
    "district_id": "120205",
    "label": "Lontung Dolok",
    "value": "Lontung Dolok"
  },
  {
    "id": "1202052014",
    "district_id": "120205",
    "label": "Simanampang",
    "value": "Simanampang"
  },
  {
    "id": "1202052015",
    "district_id": "120205",
    "label": "Lobu Pining",
    "value": "Lobu Pining"
  },
  {
    "id": "1202052016",
    "district_id": "120205",
    "label": "Pantis",
    "value": "Pantis"
  },
  {
    "id": "1202052017",
    "district_id": "120205",
    "label": "Lumban Gaol",
    "value": "Lumban Gaol"
  },
  {
    "id": "1202052018",
    "district_id": "120205",
    "label": "Simardangiang",
    "value": "Simardangiang"
  },
  {
    "id": "1202052019",
    "district_id": "120205",
    "label": "Simasom Toruan",
    "value": "Simasom Toruan"
  },
  {
    "id": "1202061001",
    "district_id": "120206",
    "label": "Sarulla",
    "value": "Sarulla"
  },
  {
    "id": "1202062002",
    "district_id": "120206",
    "label": "Tordolok Nauli",
    "value": "Tordolok Nauli"
  },
  {
    "id": "1202062003",
    "district_id": "120206",
    "label": "Sigurung-gurung",
    "value": "Sigurung-gurung"
  },
  {
    "id": "1202062004",
    "district_id": "120206",
    "label": "Silangkitang",
    "value": "Silangkitang"
  },
  {
    "id": "1202062005",
    "district_id": "120206",
    "label": "Sitoluompu",
    "value": "Sitoluompu"
  },
  {
    "id": "1202062006",
    "district_id": "120206",
    "label": "Siopat Bahal",
    "value": "Siopat Bahal"
  },
  {
    "id": "1202062007",
    "district_id": "120206",
    "label": "Pardomuan Nainggolan",
    "value": "Pardomuan Nainggolan"
  },
  {
    "id": "1202062008",
    "district_id": "120206",
    "label": "Nahornop Marsada",
    "value": "Nahornop Marsada"
  },
  {
    "id": "1202062009",
    "district_id": "120206",
    "label": "Parsaoran Samosir",
    "value": "Parsaoran Samosir"
  },
  {
    "id": "1202062010",
    "district_id": "120206",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1202062011",
    "district_id": "120206",
    "label": "Setia",
    "value": "Setia"
  },
  {
    "id": "1202062012",
    "district_id": "120206",
    "label": "Parsaoran Nainggolan",
    "value": "Parsaoran Nainggolan"
  },
  {
    "id": "1202062013",
    "district_id": "120206",
    "label": "Pardamean Nainggolan",
    "value": "Pardamean Nainggolan"
  },
  {
    "id": "1202072001",
    "district_id": "120207",
    "label": "Silosung",
    "value": "Silosung"
  },
  {
    "id": "1202072002",
    "district_id": "120207",
    "label": "Dolok Sanggul",
    "value": "Dolok Sanggul"
  },
  {
    "id": "1202072003",
    "district_id": "120207",
    "label": "Simangumban Jae",
    "value": "Simangumban Jae"
  },
  {
    "id": "1202072004",
    "district_id": "120207",
    "label": "Aek Nabara",
    "value": "Aek Nabara"
  },
  {
    "id": "1202072005",
    "district_id": "120207",
    "label": "Dolok Saut",
    "value": "Dolok Saut"
  },
  {
    "id": "1202072006",
    "district_id": "120207",
    "label": "Simangumban Julu",
    "value": "Simangumban Julu"
  },
  {
    "id": "1202072007",
    "district_id": "120207",
    "label": "Lobu Sihim",
    "value": "Lobu Sihim"
  },
  {
    "id": "1202072008",
    "district_id": "120207",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1202082001",
    "district_id": "120208",
    "label": "Sidua Bahal",
    "value": "Sidua Bahal"
  },
  {
    "id": "1202082002",
    "district_id": "120208",
    "label": "Sibulan Bulan",
    "value": "Sibulan Bulan"
  },
  {
    "id": "1202082003",
    "district_id": "120208",
    "label": "Bonani Dolok",
    "value": "Bonani Dolok"
  },
  {
    "id": "1202082004",
    "district_id": "120208",
    "label": "Pardomuan J. Angkola",
    "value": "Pardomuan J. Angkola"
  },
  {
    "id": "1202082005",
    "district_id": "120208",
    "label": "Hutanagodang",
    "value": "Hutanagodang"
  },
  {
    "id": "1202082006",
    "district_id": "120208",
    "label": "Robean",
    "value": "Robean"
  },
  {
    "id": "1202082007",
    "district_id": "120208",
    "label": "Janji Nauli",
    "value": "Janji Nauli"
  },
  {
    "id": "1202082008",
    "district_id": "120208",
    "label": "Selamat",
    "value": "Selamat"
  },
  {
    "id": "1202082009",
    "district_id": "120208",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1202082010",
    "district_id": "120208",
    "label": "Sitolu Bahal",
    "value": "Sitolu Bahal"
  },
  {
    "id": "1202082011",
    "district_id": "120208",
    "label": "Parsaoran J. Angkola",
    "value": "Parsaoran J. Angkola"
  },
  {
    "id": "1202091001",
    "district_id": "120209",
    "label": "Psr Siborong Borong",
    "value": "Psr Siborong Borong"
  },
  {
    "id": "1202092002",
    "district_id": "120209",
    "label": "Siborong Borong I",
    "value": "Siborong Borong I"
  },
  {
    "id": "1202092003",
    "district_id": "120209",
    "label": "Siborong Borong II",
    "value": "Siborong Borong II"
  },
  {
    "id": "1202092004",
    "district_id": "120209",
    "label": "Sitampurung",
    "value": "Sitampurung"
  },
  {
    "id": "1202092005",
    "district_id": "120209",
    "label": "Sitabotabo",
    "value": "Sitabotabo"
  },
  {
    "id": "1202092006",
    "district_id": "120209",
    "label": "Bahal Batu I",
    "value": "Bahal Batu I"
  },
  {
    "id": "1202092007",
    "district_id": "120209",
    "label": "Bahal Batu II",
    "value": "Bahal Batu II"
  },
  {
    "id": "1202092008",
    "district_id": "120209",
    "label": "Bahal Batu III",
    "value": "Bahal Batu III"
  },
  {
    "id": "1202092009",
    "district_id": "120209",
    "label": "Lobu Siregar I",
    "value": "Lobu Siregar I"
  },
  {
    "id": "1202092010",
    "district_id": "120209",
    "label": "Lobu Siregar II",
    "value": "Lobu Siregar II"
  },
  {
    "id": "1202092011",
    "district_id": "120209",
    "label": "Pohan Julu",
    "value": "Pohan Julu"
  },
  {
    "id": "1202092012",
    "district_id": "120209",
    "label": "Pohan Tonga",
    "value": "Pohan Tonga"
  },
  {
    "id": "1202092013",
    "district_id": "120209",
    "label": "Pohan Jae",
    "value": "Pohan Jae"
  },
  {
    "id": "1202092014",
    "district_id": "120209",
    "label": "Paniaran",
    "value": "Paniaran"
  },
  {
    "id": "1202092015",
    "district_id": "120209",
    "label": "Parik Sabungan",
    "value": "Parik Sabungan"
  },
  {
    "id": "1202092016",
    "district_id": "120209",
    "label": "Hutabulu",
    "value": "Hutabulu"
  },
  {
    "id": "1202092017",
    "district_id": "120209",
    "label": "Lumban Tonga Tonga",
    "value": "Lumban Tonga Tonga"
  },
  {
    "id": "1202092018",
    "district_id": "120209",
    "label": "Sigumbang",
    "value": "Sigumbang"
  },
  {
    "id": "1202092019",
    "district_id": "120209",
    "label": "Siaro",
    "value": "Siaro"
  },
  {
    "id": "1202092020",
    "district_id": "120209",
    "label": "Silait-Lait",
    "value": "Silait-Lait"
  },
  {
    "id": "1202092021",
    "district_id": "120209",
    "label": "Sitabotabo Toruan",
    "value": "Sitabotabo Toruan"
  },
  {
    "id": "1202102001",
    "district_id": "120210",
    "label": "Simamora Hasibuan",
    "value": "Simamora Hasibuan"
  },
  {
    "id": "1202102002",
    "district_id": "120210",
    "label": "Lumban Julu",
    "value": "Lumban Julu"
  },
  {
    "id": "1202102003",
    "district_id": "120210",
    "label": "Pagaran",
    "value": "Pagaran"
  },
  {
    "id": "1202102004",
    "district_id": "120210",
    "label": "Sibaragas",
    "value": "Sibaragas"
  },
  {
    "id": "1202102005",
    "district_id": "120210",
    "label": "Sipultak",
    "value": "Sipultak"
  },
  {
    "id": "1202102006",
    "district_id": "120210",
    "label": "Lubis",
    "value": "Lubis"
  },
  {
    "id": "1202102007",
    "district_id": "120210",
    "label": "Banualuhu",
    "value": "Banualuhu"
  },
  {
    "id": "1202102008",
    "district_id": "120210",
    "label": "Parhorboan",
    "value": "Parhorboan"
  },
  {
    "id": "1202102009",
    "district_id": "120210",
    "label": "Lumban Motung",
    "value": "Lumban Motung"
  },
  {
    "id": "1202102010",
    "district_id": "120210",
    "label": "Lumban Ina Ina",
    "value": "Lumban Ina Ina"
  },
  {
    "id": "1202102011",
    "district_id": "120210",
    "label": "Doloksaribu",
    "value": "Doloksaribu"
  },
  {
    "id": "1202102012",
    "district_id": "120210",
    "label": "Lumban Silintong",
    "value": "Lumban Silintong"
  },
  {
    "id": "1202102013",
    "district_id": "120210",
    "label": "Sipultak Dolok",
    "value": "Sipultak Dolok"
  },
  {
    "id": "1202102014",
    "district_id": "120210",
    "label": "Hasibuan",
    "value": "Hasibuan"
  },
  {
    "id": "1202112001",
    "district_id": "120211",
    "label": "Manalu",
    "value": "Manalu"
  },
  {
    "id": "1202112002",
    "district_id": "120211",
    "label": "Manalu Purba",
    "value": "Manalu Purba"
  },
  {
    "id": "1202112003",
    "district_id": "120211",
    "label": "Manalu Dolok",
    "value": "Manalu Dolok"
  },
  {
    "id": "1202112004",
    "district_id": "120211",
    "label": "Hutajulu",
    "value": "Hutajulu"
  },
  {
    "id": "1202112005",
    "district_id": "120211",
    "label": "Aek Raja",
    "value": "Aek Raja"
  },
  {
    "id": "1202112006",
    "district_id": "120211",
    "label": "Hutatinggi",
    "value": "Hutatinggi"
  },
  {
    "id": "1202112007",
    "district_id": "120211",
    "label": "Lobusunut",
    "value": "Lobusunut"
  },
  {
    "id": "1202112008",
    "district_id": "120211",
    "label": "Sisordak",
    "value": "Sisordak"
  },
  {
    "id": "1202112009",
    "district_id": "120211",
    "label": "Pertengahan",
    "value": "Pertengahan"
  },
  {
    "id": "1202112010",
    "district_id": "120211",
    "label": "Hutatua",
    "value": "Hutatua"
  },
  {
    "id": "1202112011",
    "district_id": "120211",
    "label": "Hutajulu Parbalik",
    "value": "Hutajulu Parbalik"
  },
  {
    "id": "1202112012",
    "district_id": "120211",
    "label": "Horisan Ranggitgit",
    "value": "Horisan Ranggitgit"
  },
  {
    "id": "1202112013",
    "district_id": "120211",
    "label": "Purba Dolok",
    "value": "Purba Dolok"
  },
  {
    "id": "1202112014",
    "district_id": "120211",
    "label": "Batuarimo",
    "value": "Batuarimo"
  },
  {
    "id": "1202122001",
    "district_id": "120212",
    "label": "Sabungannihuta IV",
    "value": "Sabungannihuta IV"
  },
  {
    "id": "1202122002",
    "district_id": "120212",
    "label": "Sabungannihuta I",
    "value": "Sabungannihuta I"
  },
  {
    "id": "1202122003",
    "district_id": "120212",
    "label": "Sipahutar I",
    "value": "Sipahutar I"
  },
  {
    "id": "1202122004",
    "district_id": "120212",
    "label": "Sipahutar II",
    "value": "Sipahutar II"
  },
  {
    "id": "1202122005",
    "district_id": "120212",
    "label": "Sipahutar III",
    "value": "Sipahutar III"
  },
  {
    "id": "1202122006",
    "district_id": "120212",
    "label": "Onan Runggu I",
    "value": "Onan Runggu I"
  },
  {
    "id": "1202122007",
    "district_id": "120212",
    "label": "Tapian Nauli III",
    "value": "Tapian Nauli III"
  },
  {
    "id": "1202122008",
    "district_id": "120212",
    "label": "Onan Runggu III",
    "value": "Onan Runggu III"
  },
  {
    "id": "1202122009",
    "district_id": "120212",
    "label": "Siabal-abal III",
    "value": "Siabal-abal III"
  },
  {
    "id": "1202122010",
    "district_id": "120212",
    "label": "Siabal-abal I",
    "value": "Siabal-abal I"
  },
  {
    "id": "1202122011",
    "district_id": "120212",
    "label": "Aek Nauli II",
    "value": "Aek Nauli II"
  },
  {
    "id": "1202122012",
    "district_id": "120212",
    "label": "Tapian Nauli II",
    "value": "Tapian Nauli II"
  },
  {
    "id": "1202122013",
    "district_id": "120212",
    "label": "Sabungannihuta III",
    "value": "Sabungannihuta III"
  },
  {
    "id": "1202122014",
    "district_id": "120212",
    "label": "Onan Runggu IV",
    "value": "Onan Runggu IV"
  },
  {
    "id": "1202122015",
    "district_id": "120212",
    "label": "Siabal-abal II",
    "value": "Siabal-abal II"
  },
  {
    "id": "1202122016",
    "district_id": "120212",
    "label": "Aek Nauli I",
    "value": "Aek Nauli I"
  },
  {
    "id": "1202122017",
    "district_id": "120212",
    "label": "Aek Nauli III",
    "value": "Aek Nauli III"
  },
  {
    "id": "1202122018",
    "district_id": "120212",
    "label": "Onan Runggu II",
    "value": "Onan Runggu II"
  },
  {
    "id": "1202122019",
    "district_id": "120212",
    "label": "Tapian Nauli I",
    "value": "Tapian Nauli I"
  },
  {
    "id": "1202122020",
    "district_id": "120212",
    "label": "Sabungannihuta II",
    "value": "Sabungannihuta II"
  },
  {
    "id": "1202122021",
    "district_id": "120212",
    "label": "Sabungannihuta V",
    "value": "Sabungannihuta V"
  },
  {
    "id": "1202122022",
    "district_id": "120212",
    "label": "Siabal-abal IV",
    "value": "Siabal-abal IV"
  },
  {
    "id": "1202122023",
    "district_id": "120212",
    "label": "Aek Nauli IV",
    "value": "Aek Nauli IV"
  },
  {
    "id": "1202122024",
    "district_id": "120212",
    "label": "Siabal-abal V",
    "value": "Siabal-abal V"
  },
  {
    "id": "1202122025",
    "district_id": "120212",
    "label": "Siabal-abal VI",
    "value": "Siabal-abal VI"
  },
  {
    "id": "1202132001",
    "district_id": "120213",
    "label": "Pakpahan",
    "value": "Pakpahan"
  },
  {
    "id": "1202132002",
    "district_id": "120213",
    "label": "Sampagul",
    "value": "Sampagul"
  },
  {
    "id": "1202132003",
    "district_id": "120213",
    "label": "Godung Borotan",
    "value": "Godung Borotan"
  },
  {
    "id": "1202132004",
    "district_id": "120213",
    "label": "Parratusan",
    "value": "Parratusan"
  },
  {
    "id": "1202132005",
    "district_id": "120213",
    "label": "Lumban Siregar",
    "value": "Lumban Siregar"
  },
  {
    "id": "1202132006",
    "district_id": "120213",
    "label": "Sigotom Julu",
    "value": "Sigotom Julu"
  },
  {
    "id": "1202132007",
    "district_id": "120213",
    "label": "Rahut Bosi",
    "value": "Rahut Bosi"
  },
  {
    "id": "1202132008",
    "district_id": "120213",
    "label": "Batu Nadua",
    "value": "Batu Nadua"
  },
  {
    "id": "1202132009",
    "district_id": "120213",
    "label": "Silantom Tonga",
    "value": "Silantom Tonga"
  },
  {
    "id": "1202132010",
    "district_id": "120213",
    "label": "Silantom Julu",
    "value": "Silantom Julu"
  },
  {
    "id": "1202132011",
    "district_id": "120213",
    "label": "Pansurnatolu",
    "value": "Pansurnatolu"
  },
  {
    "id": "1202132012",
    "district_id": "120213",
    "label": "Parlombuan",
    "value": "Parlombuan"
  },
  {
    "id": "1202132013",
    "district_id": "120213",
    "label": "Purbatua",
    "value": "Purbatua"
  },
  {
    "id": "1202132014",
    "district_id": "120213",
    "label": "Lumban Sinaga Simatupang",
    "value": "Lumban Sinaga Simatupang"
  },
  {
    "id": "1202132015",
    "district_id": "120213",
    "label": "Batumanumpak",
    "value": "Batumanumpak"
  },
  {
    "id": "1202132016",
    "district_id": "120213",
    "label": "Sibingke",
    "value": "Sibingke"
  },
  {
    "id": "1202132017",
    "district_id": "120213",
    "label": "Najumambe",
    "value": "Najumambe"
  },
  {
    "id": "1202132018",
    "district_id": "120213",
    "label": "Parsibarungan",
    "value": "Parsibarungan"
  },
  {
    "id": "1202132019",
    "district_id": "120213",
    "label": "Harianja",
    "value": "Harianja"
  },
  {
    "id": "1202132020",
    "district_id": "120213",
    "label": "Silantom Jae",
    "value": "Silantom Jae"
  },
  {
    "id": "1202132021",
    "district_id": "120213",
    "label": "Padang Parsadaan",
    "value": "Padang Parsadaan"
  },
  {
    "id": "1202132022",
    "district_id": "120213",
    "label": "Parsorminan I",
    "value": "Parsorminan I"
  },
  {
    "id": "1202132023",
    "district_id": "120213",
    "label": "Hutaraja",
    "value": "Hutaraja"
  },
  {
    "id": "1202132024",
    "district_id": "120213",
    "label": "Rahut Bosi Onan",
    "value": "Rahut Bosi Onan"
  },
  {
    "id": "1202132025",
    "district_id": "120213",
    "label": "Sigotom Timur",
    "value": "Sigotom Timur"
  },
  {
    "id": "1202132026",
    "district_id": "120213",
    "label": "Sigotom Dolok Nauli",
    "value": "Sigotom Dolok Nauli"
  },
  {
    "id": "1202142001",
    "district_id": "120214",
    "label": "Garoga Sibargot",
    "value": "Garoga Sibargot"
  },
  {
    "id": "1202142002",
    "district_id": "120214",
    "label": "Lontung Jae I",
    "value": "Lontung Jae I"
  },
  {
    "id": "1202142003",
    "district_id": "120214",
    "label": "Parsosoran",
    "value": "Parsosoran"
  },
  {
    "id": "1202142004",
    "district_id": "120214",
    "label": "Padang Siandomang",
    "value": "Padang Siandomang"
  },
  {
    "id": "1202142005",
    "district_id": "120214",
    "label": "Sibaganding",
    "value": "Sibaganding"
  },
  {
    "id": "1202142006",
    "district_id": "120214",
    "label": "Sirmpang Bolon",
    "value": "Sirmpang Bolon"
  },
  {
    "id": "1202142007",
    "district_id": "120214",
    "label": "Gonting Garoga",
    "value": "Gonting Garoga"
  },
  {
    "id": "1202142008",
    "district_id": "120214",
    "label": "Lontung Jae II",
    "value": "Lontung Jae II"
  },
  {
    "id": "1202142009",
    "district_id": "120214",
    "label": "Gonting Salak",
    "value": "Gonting Salak"
  },
  {
    "id": "1202142010",
    "district_id": "120214",
    "label": "Sibalanga",
    "value": "Sibalanga"
  },
  {
    "id": "1202142011",
    "district_id": "120214",
    "label": "Aek Tangga",
    "value": "Aek Tangga"
  },
  {
    "id": "1202142012",
    "district_id": "120214",
    "label": "Parinsoran",
    "value": "Parinsoran"
  },
  {
    "id": "1202142013",
    "district_id": "120214",
    "label": "Parmanuhan",
    "value": "Parmanuhan"
  },
  {
    "id": "1202152001",
    "district_id": "120215",
    "label": "Hutanagodang",
    "value": "Hutanagodang"
  },
  {
    "id": "1202152002",
    "district_id": "120215",
    "label": "Unte Mungkur",
    "value": "Unte Mungkur"
  },
  {
    "id": "1202152003",
    "district_id": "120215",
    "label": "Hutalontung",
    "value": "Hutalontung"
  },
  {
    "id": "1202152004",
    "district_id": "120215",
    "label": "Hutaginjang",
    "value": "Hutaginjang"
  },
  {
    "id": "1202152005",
    "district_id": "120215",
    "label": "Dolok Matumbur",
    "value": "Dolok Matumbur"
  },
  {
    "id": "1202152006",
    "district_id": "120215",
    "label": "Mariba Ni Aek",
    "value": "Mariba Ni Aek"
  },
  {
    "id": "1202152007",
    "district_id": "120215",
    "label": "Sampuran",
    "value": "Sampuran"
  },
  {
    "id": "1202152008",
    "district_id": "120215",
    "label": "Silando",
    "value": "Silando"
  },
  {
    "id": "1202152009",
    "district_id": "120215",
    "label": "Sibandang",
    "value": "Sibandang"
  },
  {
    "id": "1202152010",
    "district_id": "120215",
    "label": "Simatupang",
    "value": "Simatupang"
  },
  {
    "id": "1202152011",
    "district_id": "120215",
    "label": "Silali Toruan",
    "value": "Silali Toruan"
  },
  {
    "id": "1202152012",
    "district_id": "120215",
    "label": "Batubinumbun",
    "value": "Batubinumbun"
  },
  {
    "id": "1202152013",
    "district_id": "120215",
    "label": "Sitanggor",
    "value": "Sitanggor"
  },
  {
    "id": "1202152014",
    "district_id": "120215",
    "label": "Papande",
    "value": "Papande"
  },
  {
    "id": "1202152015",
    "district_id": "120215",
    "label": "Aritonang",
    "value": "Aritonang"
  },
  {
    "id": "1203011001",
    "district_id": "120301",
    "label": "Sitinjak",
    "value": "Sitinjak"
  },
  {
    "id": "1203011003",
    "district_id": "120301",
    "label": "Simatorkis Sisoma",
    "value": "Simatorkis Sisoma"
  },
  {
    "id": "1203012002",
    "district_id": "120301",
    "label": "Panobasan",
    "value": "Panobasan"
  },
  {
    "id": "1203012004",
    "district_id": "120301",
    "label": "Siuhom",
    "value": "Siuhom"
  },
  {
    "id": "1203012005",
    "district_id": "120301",
    "label": "Sigumuru",
    "value": "Sigumuru"
  },
  {
    "id": "1203012006",
    "district_id": "120301",
    "label": "Sibangkua",
    "value": "Sibangkua"
  },
  {
    "id": "1203012007",
    "district_id": "120301",
    "label": "Sisundung",
    "value": "Sisundung"
  },
  {
    "id": "1203012008",
    "district_id": "120301",
    "label": "Aek Nabara",
    "value": "Aek Nabara"
  },
  {
    "id": "1203012011",
    "district_id": "120301",
    "label": "Parsalakan",
    "value": "Parsalakan"
  },
  {
    "id": "1203012015",
    "district_id": "120301",
    "label": "Sitaratoit",
    "value": "Sitaratoit"
  },
  {
    "id": "1203012016",
    "district_id": "120301",
    "label": "Sialogo",
    "value": "Sialogo"
  },
  {
    "id": "1203012024",
    "district_id": "120301",
    "label": "Lembah Lubuk Raya",
    "value": "Lembah Lubuk Raya"
  },
  {
    "id": "1203012031",
    "district_id": "120301",
    "label": "Lobu Layan Sigordang",
    "value": "Lobu Layan Sigordang"
  },
  {
    "id": "1203012035",
    "district_id": "120301",
    "label": "Panobasan Lombang",
    "value": "Panobasan Lombang"
  },
  {
    "id": "1203021001",
    "district_id": "120302",
    "label": "Wek I",
    "value": "Wek I"
  },
  {
    "id": "1203021002",
    "district_id": "120302",
    "label": "Wek II",
    "value": "Wek II"
  },
  {
    "id": "1203021011",
    "district_id": "120302",
    "label": "Perkebunan Batang Toru",
    "value": "Perkebunan Batang Toru"
  },
  {
    "id": "1203021030",
    "district_id": "120302",
    "label": "Padang Lancat",
    "value": "Padang Lancat"
  },
  {
    "id": "1203021032",
    "district_id": "120302",
    "label": "Aek Pining",
    "value": "Aek Pining"
  },
  {
    "id": "1203022003",
    "district_id": "120302",
    "label": "Garoga",
    "value": "Garoga"
  },
  {
    "id": "1203022004",
    "district_id": "120302",
    "label": "Huta Godang",
    "value": "Huta Godang"
  },
  {
    "id": "1203022005",
    "district_id": "120302",
    "label": "Batu Horing",
    "value": "Batu Horing"
  },
  {
    "id": "1203022008",
    "district_id": "120302",
    "label": "Batu Hula",
    "value": "Batu Hula"
  },
  {
    "id": "1203022009",
    "district_id": "120302",
    "label": "Sumuran",
    "value": "Sumuran"
  },
  {
    "id": "1203022015",
    "district_id": "120302",
    "label": "Napa",
    "value": "Napa"
  },
  {
    "id": "1203022016",
    "district_id": "120302",
    "label": "Wek IV",
    "value": "Wek IV"
  },
  {
    "id": "1203022017",
    "district_id": "120302",
    "label": "Hapesong Baru",
    "value": "Hapesong Baru"
  },
  {
    "id": "1203022018",
    "district_id": "120302",
    "label": "Telo",
    "value": "Telo"
  },
  {
    "id": "1203022019",
    "district_id": "120302",
    "label": "Wek III",
    "value": "Wek III"
  },
  {
    "id": "1203022020",
    "district_id": "120302",
    "label": "Perkebunan Sigala-Gala",
    "value": "Perkebunan Sigala-Gala"
  },
  {
    "id": "1203022021",
    "district_id": "120302",
    "label": "Perkebunan Hapesong",
    "value": "Perkebunan Hapesong"
  },
  {
    "id": "1203022022",
    "district_id": "120302",
    "label": "Hapesong Lama",
    "value": "Hapesong Lama"
  },
  {
    "id": "1203022023",
    "district_id": "120302",
    "label": "Sipenggeng",
    "value": "Sipenggeng"
  },
  {
    "id": "1203022025",
    "district_id": "120302",
    "label": "Sianggunan",
    "value": "Sianggunan"
  },
  {
    "id": "1203022026",
    "district_id": "120302",
    "label": "Huta Baru",
    "value": "Huta Baru"
  },
  {
    "id": "1203022036",
    "district_id": "120302",
    "label": "Aek Ngadol Sitinjak",
    "value": "Aek Ngadol Sitinjak"
  },
  {
    "id": "1203022037",
    "district_id": "120302",
    "label": "Sisipa",
    "value": "Sisipa"
  },
  {
    "id": "1203031098",
    "district_id": "120303",
    "label": "Pasar Pargarutan",
    "value": "Pasar Pargarutan"
  },
  {
    "id": "1203031099",
    "district_id": "120303",
    "label": "Batang Tura Sirumambe",
    "value": "Batang Tura Sirumambe"
  },
  {
    "id": "1203032003",
    "district_id": "120303",
    "label": "Panompuan Jae",
    "value": "Panompuan Jae"
  },
  {
    "id": "1203032004",
    "district_id": "120303",
    "label": "Pargarutan Tonga",
    "value": "Pargarutan Tonga"
  },
  {
    "id": "1203032008",
    "district_id": "120303",
    "label": "Huraba",
    "value": "Huraba"
  },
  {
    "id": "1203032012",
    "district_id": "120303",
    "label": "Marisi",
    "value": "Marisi"
  },
  {
    "id": "1203032013",
    "district_id": "120303",
    "label": "Pargarutan Julu",
    "value": "Pargarutan Julu"
  },
  {
    "id": "1203032014",
    "district_id": "120303",
    "label": "Huta Ginjang",
    "value": "Huta Ginjang"
  },
  {
    "id": "1203032069",
    "district_id": "120303",
    "label": "Lantosan Rogas",
    "value": "Lantosan Rogas"
  },
  {
    "id": "1203032079",
    "district_id": "120303",
    "label": "Sijungkang",
    "value": "Sijungkang"
  },
  {
    "id": "1203032083",
    "district_id": "120303",
    "label": "Pargarutan Jae",
    "value": "Pargarutan Jae"
  },
  {
    "id": "1203032094",
    "district_id": "120303",
    "label": "Panompuan",
    "value": "Panompuan"
  },
  {
    "id": "1203032095",
    "district_id": "120303",
    "label": "Pargarutan Dolok",
    "value": "Pargarutan Dolok"
  },
  {
    "id": "1203032096",
    "district_id": "120303",
    "label": "Sanggapati",
    "value": "Sanggapati"
  },
  {
    "id": "1203032097",
    "district_id": "120303",
    "label": "Pall XI",
    "value": "Pall XI"
  },
  {
    "id": "1203041001",
    "district_id": "120304",
    "label": "Pasar Sipirok",
    "value": "Pasar Sipirok"
  },
  {
    "id": "1203041003",
    "district_id": "120304",
    "label": "Hutasuhut",
    "value": "Hutasuhut"
  },
  {
    "id": "1203041004",
    "district_id": "120304",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "1203041005",
    "district_id": "120304",
    "label": "Bunga Bondar",
    "value": "Bunga Bondar"
  },
  {
    "id": "1203041061",
    "district_id": "120304",
    "label": "Parau Sorat",
    "value": "Parau Sorat"
  },
  {
    "id": "1203041114",
    "district_id": "120304",
    "label": "Sipirok Godang",
    "value": "Sipirok Godang"
  },
  {
    "id": "1203042010",
    "district_id": "120304",
    "label": "Bagas Lombang",
    "value": "Bagas Lombang"
  },
  {
    "id": "1203042017",
    "district_id": "120304",
    "label": "Batu Satail",
    "value": "Batu Satail"
  },
  {
    "id": "1203042025",
    "district_id": "120304",
    "label": "Hasang Marsada",
    "value": "Hasang Marsada"
  },
  {
    "id": "1203042034",
    "district_id": "120304",
    "label": "Janji Mauli",
    "value": "Janji Mauli"
  },
  {
    "id": "1203042048",
    "district_id": "120304",
    "label": "Paran Julu",
    "value": "Paran Julu"
  },
  {
    "id": "1203042051",
    "district_id": "120304",
    "label": "Pangaribuan",
    "value": "Pangaribuan"
  },
  {
    "id": "1203042052",
    "district_id": "120304",
    "label": "Pargarutan",
    "value": "Pargarutan"
  },
  {
    "id": "1203042054",
    "district_id": "120304",
    "label": "Panaungan",
    "value": "Panaungan"
  },
  {
    "id": "1203042059",
    "district_id": "120304",
    "label": "Padang Bujur",
    "value": "Padang Bujur"
  },
  {
    "id": "1203042060",
    "district_id": "120304",
    "label": "Paran Padang",
    "value": "Paran Padang"
  },
  {
    "id": "1203042064",
    "district_id": "120304",
    "label": "Pangurabaan",
    "value": "Pangurabaan"
  },
  {
    "id": "1203042068",
    "district_id": "120304",
    "label": "Ramba Sihasur",
    "value": "Ramba Sihasur"
  },
  {
    "id": "1203042069",
    "district_id": "120304",
    "label": "Tolang",
    "value": "Tolang"
  },
  {
    "id": "1203042072",
    "district_id": "120304",
    "label": "Sampean",
    "value": "Sampean"
  },
  {
    "id": "1203042075",
    "district_id": "120304",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1203042076",
    "district_id": "120304",
    "label": "Sibadoar",
    "value": "Sibadoar"
  },
  {
    "id": "1203042085",
    "district_id": "120304",
    "label": "Sialagundi",
    "value": "Sialagundi"
  },
  {
    "id": "1203042096",
    "district_id": "120304",
    "label": "Bulu Mario",
    "value": "Bulu Mario"
  },
  {
    "id": "1203042097",
    "district_id": "120304",
    "label": "Barnang Koling",
    "value": "Barnang Koling"
  },
  {
    "id": "1203042101",
    "district_id": "120304",
    "label": "Dolok Sordang Julu",
    "value": "Dolok Sordang Julu"
  },
  {
    "id": "1203042102",
    "district_id": "120304",
    "label": "Situmba Julu",
    "value": "Situmba Julu"
  },
  {
    "id": "1203042103",
    "district_id": "120304",
    "label": "Situmba",
    "value": "Situmba"
  },
  {
    "id": "1203042104",
    "district_id": "120304",
    "label": "Kilang Papan",
    "value": "Kilang Papan"
  },
  {
    "id": "1203042105",
    "district_id": "120304",
    "label": "Sialaman",
    "value": "Sialaman"
  },
  {
    "id": "1203042106",
    "district_id": "120304",
    "label": "Paran Dolok Mardomu",
    "value": "Paran Dolok Mardomu"
  },
  {
    "id": "1203042107",
    "district_id": "120304",
    "label": "Batang Tura Julu",
    "value": "Batang Tura Julu"
  },
  {
    "id": "1203042108",
    "district_id": "120304",
    "label": "Batang Tura",
    "value": "Batang Tura"
  },
  {
    "id": "1203042109",
    "district_id": "120304",
    "label": "Dolok Sordang",
    "value": "Dolok Sordang"
  },
  {
    "id": "1203042110",
    "district_id": "120304",
    "label": "Pahae Aek Sagala",
    "value": "Pahae Aek Sagala"
  },
  {
    "id": "1203042111",
    "district_id": "120304",
    "label": "Marsada",
    "value": "Marsada"
  },
  {
    "id": "1203042112",
    "district_id": "120304",
    "label": "Luat Lombang",
    "value": "Luat Lombang"
  },
  {
    "id": "1203042113",
    "district_id": "120304",
    "label": "Aek Batang Paya",
    "value": "Aek Batang Paya"
  },
  {
    "id": "1203042115",
    "district_id": "120304",
    "label": "Saba Batang Miha",
    "value": "Saba Batang Miha"
  },
  {
    "id": "1203042116",
    "district_id": "120304",
    "label": "Sarogodung",
    "value": "Sarogodung"
  },
  {
    "id": "1203051001",
    "district_id": "120305",
    "label": "Pasar Sipagimbar",
    "value": "Pasar Sipagimbar"
  },
  {
    "id": "1203051080",
    "district_id": "120305",
    "label": "Aek Simotung",
    "value": "Aek Simotung"
  },
  {
    "id": "1203052018",
    "district_id": "120305",
    "label": "Simangambat",
    "value": "Simangambat"
  },
  {
    "id": "1203052069",
    "district_id": "120305",
    "label": "Sidapdap Simanosor",
    "value": "Sidapdap Simanosor"
  },
  {
    "id": "1203052070",
    "district_id": "120305",
    "label": "Saut Banua Simanosor",
    "value": "Saut Banua Simanosor"
  },
  {
    "id": "1203052071",
    "district_id": "120305",
    "label": "Damparan Haunatas",
    "value": "Damparan Haunatas"
  },
  {
    "id": "1203052072",
    "district_id": "120305",
    "label": "Somba Debata Purba",
    "value": "Somba Debata Purba"
  },
  {
    "id": "1203052073",
    "district_id": "120305",
    "label": "Padang Mandailing Garugur",
    "value": "Padang Mandailing Garugur"
  },
  {
    "id": "1203052074",
    "district_id": "120305",
    "label": "Ulu Mamis Situnggaling",
    "value": "Ulu Mamis Situnggaling"
  },
  {
    "id": "1203052075",
    "district_id": "120305",
    "label": "Parau Sorat Sitabo-tabo",
    "value": "Parau Sorat Sitabo-tabo"
  },
  {
    "id": "1203052076",
    "district_id": "120305",
    "label": "Pintu Padang Mandalasena",
    "value": "Pintu Padang Mandalasena"
  },
  {
    "id": "1203052077",
    "district_id": "120305",
    "label": "Sunge Sigiring-giring",
    "value": "Sunge Sigiring-giring"
  },
  {
    "id": "1203052078",
    "district_id": "120305",
    "label": "Silangkitang Tambiski",
    "value": "Silangkitang Tambiski"
  },
  {
    "id": "1203052079",
    "district_id": "120305",
    "label": "Batang Parsuluman",
    "value": "Batang Parsuluman"
  },
  {
    "id": "1203061001",
    "district_id": "120306",
    "label": "Simarpinggan",
    "value": "Simarpinggan"
  },
  {
    "id": "1203061007",
    "district_id": "120306",
    "label": "Napa",
    "value": "Napa"
  },
  {
    "id": "1203061014",
    "district_id": "120306",
    "label": "Tapian Nauli",
    "value": "Tapian Nauli"
  },
  {
    "id": "1203061019",
    "district_id": "120306",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1203062004",
    "district_id": "120306",
    "label": "Situmbaga",
    "value": "Situmbaga"
  },
  {
    "id": "1203062005",
    "district_id": "120306",
    "label": "Sinyior",
    "value": "Sinyior"
  },
  {
    "id": "1203062006",
    "district_id": "120306",
    "label": "Pintu Padang",
    "value": "Pintu Padang"
  },
  {
    "id": "1203062008",
    "district_id": "120306",
    "label": "Sibong-bong",
    "value": "Sibong-bong"
  },
  {
    "id": "1203062009",
    "district_id": "120306",
    "label": "Siamporik Dolok",
    "value": "Siamporik Dolok"
  },
  {
    "id": "1203062010",
    "district_id": "120306",
    "label": "Siamporik Lombang",
    "value": "Siamporik Lombang"
  },
  {
    "id": "1203062011",
    "district_id": "120306",
    "label": "Sihopur",
    "value": "Sihopur"
  },
  {
    "id": "1203062012",
    "district_id": "120306",
    "label": "Tandihat",
    "value": "Tandihat"
  },
  {
    "id": "1203062013",
    "district_id": "120306",
    "label": "Perkebunan Marpinggan",
    "value": "Perkebunan Marpinggan"
  },
  {
    "id": "1203062015",
    "district_id": "120306",
    "label": "Aek Natas",
    "value": "Aek Natas"
  },
  {
    "id": "1203062016",
    "district_id": "120306",
    "label": "Dolok Godang",
    "value": "Dolok Godang"
  },
  {
    "id": "1203062017",
    "district_id": "120306",
    "label": "Sihuik-huik",
    "value": "Sihuik-huik"
  },
  {
    "id": "1203062018",
    "district_id": "120306",
    "label": "Gunung Baringin",
    "value": "Gunung Baringin"
  },
  {
    "id": "1203071001",
    "district_id": "120307",
    "label": "Sigalangan",
    "value": "Sigalangan"
  },
  {
    "id": "1203071060",
    "district_id": "120307",
    "label": "Pintu Padang I",
    "value": "Pintu Padang I"
  },
  {
    "id": "1203071061",
    "district_id": "120307",
    "label": "Pintu Padang II",
    "value": "Pintu Padang II"
  },
  {
    "id": "1203071068",
    "district_id": "120307",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "1203072005",
    "district_id": "120307",
    "label": "Aek Nauli",
    "value": "Aek Nauli"
  },
  {
    "id": "1203072016",
    "district_id": "120307",
    "label": "Sidadi I",
    "value": "Sidadi I"
  },
  {
    "id": "1203072018",
    "district_id": "120307",
    "label": "Janji Manaon",
    "value": "Janji Manaon"
  },
  {
    "id": "1203072020",
    "district_id": "120307",
    "label": "Sitampa Simatoras",
    "value": "Sitampa Simatoras"
  },
  {
    "id": "1203072021",
    "district_id": "120307",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "1203072027",
    "district_id": "120307",
    "label": "Benteng Huraba",
    "value": "Benteng Huraba"
  },
  {
    "id": "1203072029",
    "district_id": "120307",
    "label": "Padang Kahombu",
    "value": "Padang Kahombu"
  },
  {
    "id": "1203072030",
    "district_id": "120307",
    "label": "Sorik",
    "value": "Sorik"
  },
  {
    "id": "1203072045",
    "district_id": "120307",
    "label": "Sibulele Muara",
    "value": "Sibulele Muara"
  },
  {
    "id": "1203072047",
    "district_id": "120307",
    "label": "Bargot Topong",
    "value": "Bargot Topong"
  },
  {
    "id": "1203072053",
    "district_id": "120307",
    "label": "Sigulang Losung",
    "value": "Sigulang Losung"
  },
  {
    "id": "1203072055",
    "district_id": "120307",
    "label": "Hurase",
    "value": "Hurase"
  },
  {
    "id": "1203072057",
    "district_id": "120307",
    "label": "Sorimadingin PP",
    "value": "Sorimadingin PP"
  },
  {
    "id": "1203072058",
    "district_id": "120307",
    "label": "Huta Padang",
    "value": "Huta Padang"
  },
  {
    "id": "1203072062",
    "district_id": "120307",
    "label": "Tahalak Ujung Gading",
    "value": "Tahalak Ujung Gading"
  },
  {
    "id": "1203072063",
    "district_id": "120307",
    "label": "Sidadi II",
    "value": "Sidadi II"
  },
  {
    "id": "1203072067",
    "district_id": "120307",
    "label": "Aek Gunung",
    "value": "Aek Gunung"
  },
  {
    "id": "1203141039",
    "district_id": "120314",
    "label": "Arse",
    "value": "Arse"
  },
  {
    "id": "1203141040",
    "district_id": "120314",
    "label": "Lancat",
    "value": "Lancat"
  },
  {
    "id": "1203142028",
    "district_id": "120314",
    "label": "Sipogu",
    "value": "Sipogu"
  },
  {
    "id": "1203142032",
    "district_id": "120314",
    "label": "Pinagar",
    "value": "Pinagar"
  },
  {
    "id": "1203142033",
    "district_id": "120314",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1203142034",
    "district_id": "120314",
    "label": "Aek Haminjon",
    "value": "Aek Haminjon"
  },
  {
    "id": "1203142035",
    "district_id": "120314",
    "label": "Nanggar Jati Huta Padang",
    "value": "Nanggar Jati Huta Padang"
  },
  {
    "id": "1203142036",
    "district_id": "120314",
    "label": "Nanggar Jati",
    "value": "Nanggar Jati"
  },
  {
    "id": "1203142037",
    "district_id": "120314",
    "label": "Natambang Roncitan",
    "value": "Natambang Roncitan"
  },
  {
    "id": "1203142038",
    "district_id": "120314",
    "label": "Dalihan Natolu",
    "value": "Dalihan Natolu"
  },
  {
    "id": "1203201004",
    "district_id": "120320",
    "label": "Pasar Sempurna",
    "value": "Pasar Sempurna"
  },
  {
    "id": "1203202002",
    "district_id": "120320",
    "label": "Huraba",
    "value": "Huraba"
  },
  {
    "id": "1203202014",
    "district_id": "120320",
    "label": "Gunung Binanga",
    "value": "Gunung Binanga"
  },
  {
    "id": "1203202015",
    "district_id": "120320",
    "label": "Marancar Godang",
    "value": "Marancar Godang"
  },
  {
    "id": "1203202016",
    "district_id": "120320",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1203202023",
    "district_id": "120320",
    "label": "Sugi",
    "value": "Sugi"
  },
  {
    "id": "1203202028",
    "district_id": "120320",
    "label": "Haunatas",
    "value": "Haunatas"
  },
  {
    "id": "1203202032",
    "district_id": "120320",
    "label": "Aek Nabara",
    "value": "Aek Nabara"
  },
  {
    "id": "1203202033",
    "district_id": "120320",
    "label": "Gapuk Tua",
    "value": "Gapuk Tua"
  },
  {
    "id": "1203202034",
    "district_id": "120320",
    "label": "Marancar Julu",
    "value": "Marancar Julu"
  },
  {
    "id": "1203202035",
    "district_id": "120320",
    "label": "Aek Sabaon",
    "value": "Aek Sabaon"
  },
  {
    "id": "1203202036",
    "district_id": "120320",
    "label": "Tanjung Dolok",
    "value": "Tanjung Dolok"
  },
  {
    "id": "1203211063",
    "district_id": "120321",
    "label": "Sayur Matinggi",
    "value": "Sayur Matinggi"
  },
  {
    "id": "1203212003",
    "district_id": "120321",
    "label": "Janji Mauli Baringin",
    "value": "Janji Mauli Baringin"
  },
  {
    "id": "1203212004",
    "district_id": "120321",
    "label": "Mondang",
    "value": "Mondang"
  },
  {
    "id": "1203212005",
    "district_id": "120321",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1203212006",
    "district_id": "120321",
    "label": "Sipange Julu",
    "value": "Sipange Julu"
  },
  {
    "id": "1203212007",
    "district_id": "120321",
    "label": "Sipange Godang",
    "value": "Sipange Godang"
  },
  {
    "id": "1203212008",
    "district_id": "120321",
    "label": "Tolang Julu",
    "value": "Tolang Julu"
  },
  {
    "id": "1203212009",
    "district_id": "120321",
    "label": "Tolang Jae",
    "value": "Tolang Jae"
  },
  {
    "id": "1203212012",
    "district_id": "120321",
    "label": "Bange",
    "value": "Bange"
  },
  {
    "id": "1203212013",
    "district_id": "120321",
    "label": "Bulu Gading",
    "value": "Bulu Gading"
  },
  {
    "id": "1203212018",
    "district_id": "120321",
    "label": "Aek Libung",
    "value": "Aek Libung"
  },
  {
    "id": "1203212023",
    "district_id": "120321",
    "label": "Aek Badak Jae",
    "value": "Aek Badak Jae"
  },
  {
    "id": "1203212024",
    "district_id": "120321",
    "label": "Aek Badak Julu",
    "value": "Aek Badak Julu"
  },
  {
    "id": "1203212026",
    "district_id": "120321",
    "label": "Huta Pardomuan",
    "value": "Huta Pardomuan"
  },
  {
    "id": "1203212056",
    "district_id": "120321",
    "label": "Sipange Siunjam",
    "value": "Sipange Siunjam"
  },
  {
    "id": "1203212057",
    "district_id": "120321",
    "label": "Silaiya",
    "value": "Silaiya"
  },
  {
    "id": "1203212058",
    "district_id": "120321",
    "label": "Somanggal Parmonangan",
    "value": "Somanggal Parmonangan"
  },
  {
    "id": "1203212059",
    "district_id": "120321",
    "label": "Lumban Huayan",
    "value": "Lumban Huayan"
  },
  {
    "id": "1203212062",
    "district_id": "120321",
    "label": "Silaiya Tanjung Leuk",
    "value": "Silaiya Tanjung Leuk"
  },
  {
    "id": "1203222003",
    "district_id": "120322",
    "label": "Sigolang",
    "value": "Sigolang"
  },
  {
    "id": "1203222005",
    "district_id": "120322",
    "label": "Biru",
    "value": "Biru"
  },
  {
    "id": "1203222008",
    "district_id": "120322",
    "label": "Tapus Godang",
    "value": "Tapus Godang"
  },
  {
    "id": "1203222017",
    "district_id": "120322",
    "label": "Lobu Tayas",
    "value": "Lobu Tayas"
  },
  {
    "id": "1203222020",
    "district_id": "120322",
    "label": "Silangkitang",
    "value": "Silangkitang"
  },
  {
    "id": "1203222033",
    "district_id": "120322",
    "label": "Sihulambu",
    "value": "Sihulambu"
  },
  {
    "id": "1203222043",
    "district_id": "120322",
    "label": "Tolang",
    "value": "Tolang"
  },
  {
    "id": "1203222044",
    "district_id": "120322",
    "label": "Tapus Sipagabu",
    "value": "Tapus Sipagabu"
  },
  {
    "id": "1203222045",
    "district_id": "120322",
    "label": "Huta baru",
    "value": "Huta baru"
  },
  {
    "id": "1203222046",
    "district_id": "120322",
    "label": "Aek Latong",
    "value": "Aek Latong"
  },
  {
    "id": "1203222047",
    "district_id": "120322",
    "label": "Aek Urat",
    "value": "Aek Urat"
  },
  {
    "id": "1203222048",
    "district_id": "120322",
    "label": "Gorahut",
    "value": "Gorahut"
  },
  {
    "id": "1203291002",
    "district_id": "120329",
    "label": "Hutaraja",
    "value": "Hutaraja"
  },
  {
    "id": "1203291008",
    "district_id": "120329",
    "label": "Muara Manompas",
    "value": "Muara Manompas"
  },
  {
    "id": "1203291009",
    "district_id": "120329",
    "label": "Muara Ampolu",
    "value": "Muara Ampolu"
  },
  {
    "id": "1203292001",
    "district_id": "120329",
    "label": "Bandar Hapinis",
    "value": "Bandar Hapinis"
  },
  {
    "id": "1203292003",
    "district_id": "120329",
    "label": "Pardamean",
    "value": "Pardamean"
  },
  {
    "id": "1203292004",
    "district_id": "120329",
    "label": "Tarapung Raya",
    "value": "Tarapung Raya"
  },
  {
    "id": "1203292007",
    "district_id": "120329",
    "label": "Muara Opu",
    "value": "Muara Opu"
  },
  {
    "id": "1203292010",
    "district_id": "120329",
    "label": "Simarlelan",
    "value": "Simarlelan"
  },
  {
    "id": "1203292011",
    "district_id": "120329",
    "label": "Muara Huta Raja",
    "value": "Muara Huta Raja"
  },
  {
    "id": "1203301001",
    "district_id": "120330",
    "label": "Panabari Hutatonga",
    "value": "Panabari Hutatonga"
  },
  {
    "id": "1203302002",
    "district_id": "120330",
    "label": "Aek Uncim",
    "value": "Aek Uncim"
  },
  {
    "id": "1203302003",
    "district_id": "120330",
    "label": "Panindoan",
    "value": "Panindoan"
  },
  {
    "id": "1203302004",
    "district_id": "120330",
    "label": "Aek Kahombu",
    "value": "Aek Kahombu"
  },
  {
    "id": "1203302005",
    "district_id": "120330",
    "label": "Batu Horpak",
    "value": "Batu Horpak"
  },
  {
    "id": "1203302006",
    "district_id": "120330",
    "label": "Situmba",
    "value": "Situmba"
  },
  {
    "id": "1203302007",
    "district_id": "120330",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1203302008",
    "district_id": "120330",
    "label": "Lumban Jabi-jabi",
    "value": "Lumban Jabi-jabi"
  },
  {
    "id": "1203302009",
    "district_id": "120330",
    "label": "Ingul Jae",
    "value": "Ingul Jae"
  },
  {
    "id": "1203302010",
    "district_id": "120330",
    "label": "Sisoma",
    "value": "Sisoma"
  },
  {
    "id": "1203302011",
    "district_id": "120330",
    "label": "Lumban Ratus",
    "value": "Lumban Ratus"
  },
  {
    "id": "1203302012",
    "district_id": "120330",
    "label": "Kota Tua",
    "value": "Kota Tua"
  },
  {
    "id": "1203302013",
    "district_id": "120330",
    "label": "Simaninggir Tano Tombangan",
    "value": "Simaninggir Tano Tombangan"
  },
  {
    "id": "1203302014",
    "district_id": "120330",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1203302015",
    "district_id": "120330",
    "label": "Harean",
    "value": "Harean"
  },
  {
    "id": "1203302016",
    "district_id": "120330",
    "label": "Aek Parupuk",
    "value": "Aek Parupuk"
  },
  {
    "id": "1203302017",
    "district_id": "120330",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1203311001",
    "district_id": "120331",
    "label": "Sangkunur",
    "value": "Sangkunur"
  },
  {
    "id": "1203311002",
    "district_id": "120331",
    "label": "Rianiate",
    "value": "Rianiate"
  },
  {
    "id": "1203312003",
    "district_id": "120331",
    "label": "Simatohir",
    "value": "Simatohir"
  },
  {
    "id": "1203312004",
    "district_id": "120331",
    "label": "Perkebunan",
    "value": "Perkebunan"
  },
  {
    "id": "1203312005",
    "district_id": "120331",
    "label": "Bandar Tarutung",
    "value": "Bandar Tarutung"
  },
  {
    "id": "1203312006",
    "district_id": "120331",
    "label": "Aek Pardomuan",
    "value": "Aek Pardomuan"
  },
  {
    "id": "1203312007",
    "district_id": "120331",
    "label": "Tindoan Laut",
    "value": "Tindoan Laut"
  },
  {
    "id": "1203312008",
    "district_id": "120331",
    "label": "Simataniari",
    "value": "Simataniari"
  },
  {
    "id": "1203312009",
    "district_id": "120331",
    "label": "Batu Godang",
    "value": "Batu Godang"
  },
  {
    "id": "1203312010",
    "district_id": "120331",
    "label": "Malombu",
    "value": "Malombu"
  },
  {
    "id": "1203321001",
    "district_id": "120332",
    "label": "Bintuju",
    "value": "Bintuju"
  },
  {
    "id": "1203321002",
    "district_id": "120332",
    "label": "Huta Tonga",
    "value": "Huta Tonga"
  },
  {
    "id": "1203322003",
    "district_id": "120332",
    "label": "Basilam Baru",
    "value": "Basilam Baru"
  },
  {
    "id": "1203322004",
    "district_id": "120332",
    "label": "Sipangko",
    "value": "Sipangko"
  },
  {
    "id": "1203322005",
    "district_id": "120332",
    "label": "Huta Holbung",
    "value": "Huta Holbung"
  },
  {
    "id": "1203322006",
    "district_id": "120332",
    "label": "Muara Tais I",
    "value": "Muara Tais I"
  },
  {
    "id": "1203322007",
    "district_id": "120332",
    "label": "Muara Tais II",
    "value": "Muara Tais II"
  },
  {
    "id": "1203322008",
    "district_id": "120332",
    "label": "Muara Tais III",
    "value": "Muara Tais III"
  },
  {
    "id": "1203322009",
    "district_id": "120332",
    "label": "Janji Mauli MT",
    "value": "Janji Mauli MT"
  },
  {
    "id": "1203322010",
    "district_id": "120332",
    "label": "Pargumbangan",
    "value": "Pargumbangan"
  },
  {
    "id": "1203322011",
    "district_id": "120332",
    "label": "Pangaribuan",
    "value": "Pangaribuan"
  },
  {
    "id": "1203322012",
    "district_id": "120332",
    "label": "Pasir Matogu",
    "value": "Pasir Matogu"
  },
  {
    "id": "1203322013",
    "district_id": "120332",
    "label": "Sori Manaon",
    "value": "Sori Manaon"
  },
  {
    "id": "1203322014",
    "district_id": "120332",
    "label": "Muara Purba Nauli",
    "value": "Muara Purba Nauli"
  },
  {
    "id": "1203322015",
    "district_id": "120332",
    "label": "Tatengger",
    "value": "Tatengger"
  },
  {
    "id": "1204052001",
    "district_id": "120405",
    "label": "Ononamolo I Bot",
    "value": "Ononamolo I Bot"
  },
  {
    "id": "1204052021",
    "district_id": "120405",
    "label": "Dima",
    "value": "Dima"
  },
  {
    "id": "1204052022",
    "district_id": "120405",
    "label": "Sinarikhi",
    "value": "Sinarikhi"
  },
  {
    "id": "1204052023",
    "district_id": "120405",
    "label": "Ombolata Salo'o",
    "value": "Ombolata Salo'o"
  },
  {
    "id": "1204052024",
    "district_id": "120405",
    "label": "Sisobalauru",
    "value": "Sisobalauru"
  },
  {
    "id": "1204052025",
    "district_id": "120405",
    "label": "Tuhegafoa II",
    "value": "Tuhegafoa II"
  },
  {
    "id": "1204052027",
    "district_id": "120405",
    "label": "FadoroLauru",
    "value": "FadoroLauru"
  },
  {
    "id": "1204052028",
    "district_id": "120405",
    "label": "Lasara Tanose'o",
    "value": "Lasara Tanose'o"
  },
  {
    "id": "1204052030",
    "district_id": "120405",
    "label": "Hiliduho",
    "value": "Hiliduho"
  },
  {
    "id": "1204052031",
    "district_id": "120405",
    "label": "Hiligodu Tanoseo",
    "value": "Hiligodu Tanoseo"
  },
  {
    "id": "1204052032",
    "district_id": "120405",
    "label": "Sisobahili I Tanoseo",
    "value": "Sisobahili I Tanoseo"
  },
  {
    "id": "1204052033",
    "district_id": "120405",
    "label": "Onozitolidulu",
    "value": "Onozitolidulu"
  },
  {
    "id": "1204052034",
    "district_id": "120405",
    "label": "Mazingo Tanose'o",
    "value": "Mazingo Tanose'o"
  },
  {
    "id": "1204052040",
    "district_id": "120405",
    "label": "Onowaembo Hiligara",
    "value": "Onowaembo Hiligara"
  },
  {
    "id": "1204052041",
    "district_id": "120405",
    "label": "Silimabanua",
    "value": "Silimabanua"
  },
  {
    "id": "1204052042",
    "district_id": "120405",
    "label": "Ombolata Sisarahili",
    "value": "Ombolata Sisarahili"
  },
  {
    "id": "1204062006",
    "district_id": "120406",
    "label": "Hiliotalua",
    "value": "Hiliotalua"
  },
  {
    "id": "1204062008",
    "district_id": "120406",
    "label": "Akhelauwe",
    "value": "Akhelauwe"
  },
  {
    "id": "1204062016",
    "district_id": "120406",
    "label": "Hilisebua",
    "value": "Hilisebua"
  },
  {
    "id": "1204062017",
    "district_id": "120406",
    "label": "Somi",
    "value": "Somi"
  },
  {
    "id": "1204062018",
    "district_id": "120406",
    "label": "Sirete",
    "value": "Sirete"
  },
  {
    "id": "1204062019",
    "district_id": "120406",
    "label": "Heliweto Gido",
    "value": "Heliweto Gido"
  },
  {
    "id": "1204062020",
    "district_id": "120406",
    "label": "Loloana'a Gido",
    "value": "Loloana'a Gido"
  },
  {
    "id": "1204062022",
    "district_id": "120406",
    "label": "Sisobahili",
    "value": "Sisobahili"
  },
  {
    "id": "1204062023",
    "district_id": "120406",
    "label": "Lahemo",
    "value": "Lahemo"
  },
  {
    "id": "1204062024",
    "district_id": "120406",
    "label": "Ladea",
    "value": "Ladea"
  },
  {
    "id": "1204062027",
    "district_id": "120406",
    "label": "Umbu",
    "value": "Umbu"
  },
  {
    "id": "1204062028",
    "district_id": "120406",
    "label": "Soewe",
    "value": "Soewe"
  },
  {
    "id": "1204062029",
    "district_id": "120406",
    "label": "Lolozasai",
    "value": "Lolozasai"
  },
  {
    "id": "1204062030",
    "district_id": "120406",
    "label": "Lazara Idanoi",
    "value": "Lazara Idanoi"
  },
  {
    "id": "1204062050",
    "district_id": "120406",
    "label": "Lasela",
    "value": "Lasela"
  },
  {
    "id": "1204062051",
    "district_id": "120406",
    "label": "Ladea Orahua",
    "value": "Ladea Orahua"
  },
  {
    "id": "1204062052",
    "district_id": "120406",
    "label": "Tulumbaho Salo'o",
    "value": "Tulumbaho Salo'o"
  },
  {
    "id": "1204062053",
    "district_id": "120406",
    "label": "Nifolo'o Lauru",
    "value": "Nifolo'o Lauru"
  },
  {
    "id": "1204062054",
    "district_id": "120406",
    "label": "Hilizoi",
    "value": "Hilizoi"
  },
  {
    "id": "1204062055",
    "district_id": "120406",
    "label": "Somi Botogo'o",
    "value": "Somi Botogo'o"
  },
  {
    "id": "1204062056",
    "district_id": "120406",
    "label": "Olindrawa Sisarahili",
    "value": "Olindrawa Sisarahili"
  },
  {
    "id": "1204102005",
    "district_id": "120410",
    "label": "Hilimoasio",
    "value": "Hilimoasio"
  },
  {
    "id": "1204102006",
    "district_id": "120410",
    "label": "Tetegeona'ai",
    "value": "Tetegeona'ai"
  },
  {
    "id": "1204102007",
    "district_id": "120410",
    "label": "Laowo Hilimbaruzo",
    "value": "Laowo Hilimbaruzo"
  },
  {
    "id": "1204102008",
    "district_id": "120410",
    "label": "Hililawae",
    "value": "Hililawae"
  },
  {
    "id": "1204102009",
    "district_id": "120410",
    "label": "Tuhewaebu",
    "value": "Tuhewaebu"
  },
  {
    "id": "1204102014",
    "district_id": "120410",
    "label": "Sisobahili Iraono Hura",
    "value": "Sisobahili Iraono Hura"
  },
  {
    "id": "1204102015",
    "district_id": "120410",
    "label": "Bozihona",
    "value": "Bozihona"
  },
  {
    "id": "1204102016",
    "district_id": "120410",
    "label": "Oladano",
    "value": "Oladano"
  },
  {
    "id": "1204102017",
    "district_id": "120410",
    "label": "Ahedano",
    "value": "Ahedano"
  },
  {
    "id": "1204102018",
    "district_id": "120410",
    "label": "Hilina'a Tafuo",
    "value": "Hilina'a Tafuo"
  },
  {
    "id": "1204102019",
    "district_id": "120410",
    "label": "Hiliono Zega",
    "value": "Hiliono Zega"
  },
  {
    "id": "1204102020",
    "district_id": "120410",
    "label": "Mondrali",
    "value": "Mondrali"
  },
  {
    "id": "1204102022",
    "district_id": "120410",
    "label": "Tetehosi",
    "value": "Tetehosi"
  },
  {
    "id": "1204102023",
    "district_id": "120410",
    "label": "Saiwahili Hili'adulo",
    "value": "Saiwahili Hili'adulo"
  },
  {
    "id": "1204102024",
    "district_id": "120410",
    "label": "Maliwa'a",
    "value": "Maliwa'a"
  },
  {
    "id": "1204102025",
    "district_id": "120410",
    "label": "Biouti",
    "value": "Biouti"
  },
  {
    "id": "1204102026",
    "district_id": "120410",
    "label": "Awoni Lauso",
    "value": "Awoni Lauso"
  },
  {
    "id": "1204102027",
    "district_id": "120410",
    "label": "Bobozioli Loloana'a",
    "value": "Bobozioli Loloana'a"
  },
  {
    "id": "1204102028",
    "district_id": "120410",
    "label": "Sandruta",
    "value": "Sandruta"
  },
  {
    "id": "1204102029",
    "district_id": "120410",
    "label": "Laira",
    "value": "Laira"
  },
  {
    "id": "1204102030",
    "district_id": "120410",
    "label": "Otalua",
    "value": "Otalua"
  },
  {
    "id": "1204102031",
    "district_id": "120410",
    "label": "Baruzo",
    "value": "Baruzo"
  },
  {
    "id": "1204102032",
    "district_id": "120410",
    "label": "Orahili Zuzundrao",
    "value": "Orahili Zuzundrao"
  },
  {
    "id": "1204102033",
    "district_id": "120410",
    "label": "Tiga Serangkai Maliwa'a",
    "value": "Tiga Serangkai Maliwa'a"
  },
  {
    "id": "1204102034",
    "district_id": "120410",
    "label": "Hiligogowaya Maliwa'a",
    "value": "Hiligogowaya Maliwa'a"
  },
  {
    "id": "1204102035",
    "district_id": "120410",
    "label": "Hili'adulo",
    "value": "Hili'adulo"
  },
  {
    "id": "1204102036",
    "district_id": "120410",
    "label": "Hilimoasio Dua",
    "value": "Hilimoasio Dua"
  },
  {
    "id": "1204102037",
    "district_id": "120410",
    "label": "Biouti Timur",
    "value": "Biouti Timur"
  },
  {
    "id": "1204112002",
    "district_id": "120411",
    "label": "Sisarahili Bawolato",
    "value": "Sisarahili Bawolato"
  },
  {
    "id": "1204112003",
    "district_id": "120411",
    "label": "Hiliganoita",
    "value": "Hiliganoita"
  },
  {
    "id": "1204112004",
    "district_id": "120411",
    "label": "Gazamanu",
    "value": "Gazamanu"
  },
  {
    "id": "1204112005",
    "district_id": "120411",
    "label": "Hilihoru",
    "value": "Hilihoru"
  },
  {
    "id": "1204112006",
    "district_id": "120411",
    "label": "Hilialawa",
    "value": "Hilialawa"
  },
  {
    "id": "1204112007",
    "district_id": "120411",
    "label": "Hilifaosi",
    "value": "Hilifaosi"
  },
  {
    "id": "1204112008",
    "district_id": "120411",
    "label": "Hiliwarokha",
    "value": "Hiliwarokha"
  },
  {
    "id": "1204112009",
    "district_id": "120411",
    "label": "Sitolu Banua",
    "value": "Sitolu Banua"
  },
  {
    "id": "1204112010",
    "district_id": "120411",
    "label": "Siofaewali",
    "value": "Siofaewali"
  },
  {
    "id": "1204112011",
    "district_id": "120411",
    "label": "Sohoya",
    "value": "Sohoya"
  },
  {
    "id": "1204112012",
    "district_id": "120411",
    "label": "Botohaenga",
    "value": "Botohaenga"
  },
  {
    "id": "1204112013",
    "district_id": "120411",
    "label": "Orahili",
    "value": "Orahili"
  },
  {
    "id": "1204112014",
    "district_id": "120411",
    "label": "Tagaule",
    "value": "Tagaule"
  },
  {
    "id": "1204112015",
    "district_id": "120411",
    "label": "Siofabanua",
    "value": "Siofabanua"
  },
  {
    "id": "1204112016",
    "district_id": "120411",
    "label": "Sifaoroasi Ulu Hou",
    "value": "Sifaoroasi Ulu Hou"
  },
  {
    "id": "1204112017",
    "district_id": "120411",
    "label": "Dahana",
    "value": "Dahana"
  },
  {
    "id": "1204112018",
    "district_id": "120411",
    "label": "Orahua",
    "value": "Orahua"
  },
  {
    "id": "1204112019",
    "district_id": "120411",
    "label": "Banua Sibohou Silima Ewali",
    "value": "Banua Sibohou Silima Ewali"
  },
  {
    "id": "1204112020",
    "district_id": "120411",
    "label": "Si'ofaewali Selatan",
    "value": "Si'ofaewali Selatan"
  },
  {
    "id": "1204112021",
    "district_id": "120411",
    "label": "Lagasimahe",
    "value": "Lagasimahe"
  },
  {
    "id": "1204112022",
    "district_id": "120411",
    "label": "Hilihao Cugala",
    "value": "Hilihao Cugala"
  },
  {
    "id": "1204112023",
    "district_id": "120411",
    "label": "Orahua Faondrato",
    "value": "Orahua Faondrato"
  },
  {
    "id": "1204112024",
    "district_id": "120411",
    "label": "Hou",
    "value": "Hou"
  },
  {
    "id": "1204112025",
    "district_id": "120411",
    "label": "Sindrondro",
    "value": "Sindrondro"
  },
  {
    "id": "1204112026",
    "district_id": "120411",
    "label": "Balale Toba'a",
    "value": "Balale Toba'a"
  },
  {
    "id": "1204202001",
    "district_id": "120420",
    "label": "Fadoro Hunogoa",
    "value": "Fadoro Hunogoa"
  },
  {
    "id": "1204202002",
    "district_id": "120420",
    "label": "Lolofaoso Lalai",
    "value": "Lolofaoso Lalai"
  },
  {
    "id": "1204202003",
    "district_id": "120420",
    "label": "Fulolo Lalai",
    "value": "Fulolo Lalai"
  },
  {
    "id": "1204202004",
    "district_id": "120420",
    "label": "Lawa-Lawa",
    "value": "Lawa-Lawa"
  },
  {
    "id": "1204202005",
    "district_id": "120420",
    "label": "Lalai I/II",
    "value": "Lalai I/II"
  },
  {
    "id": "1204202006",
    "district_id": "120420",
    "label": "Lolowua",
    "value": "Lolowua"
  },
  {
    "id": "1204202007",
    "district_id": "120420",
    "label": "Dahadano Botombawo",
    "value": "Dahadano Botombawo"
  },
  {
    "id": "1204202008",
    "district_id": "120420",
    "label": "Fadoro Lalai",
    "value": "Fadoro Lalai"
  },
  {
    "id": "1204202009",
    "district_id": "120420",
    "label": "Lolowua Hiliwarasi",
    "value": "Lolowua Hiliwarasi"
  },
  {
    "id": "1204202010",
    "district_id": "120420",
    "label": "Hilizia Lauru",
    "value": "Hilizia Lauru"
  },
  {
    "id": "1204202011",
    "district_id": "120420",
    "label": "Ehosakhozi",
    "value": "Ehosakhozi"
  },
  {
    "id": "1204202012",
    "district_id": "120420",
    "label": "Awela",
    "value": "Awela"
  },
  {
    "id": "1204202013",
    "district_id": "120420",
    "label": "Onombongi",
    "value": "Onombongi"
  },
  {
    "id": "1204202014",
    "district_id": "120420",
    "label": "Orahili Idanoi",
    "value": "Orahili Idanoi"
  },
  {
    "id": "1204202015",
    "district_id": "120420",
    "label": "Lolofaoso",
    "value": "Lolofaoso"
  },
  {
    "id": "1204212001",
    "district_id": "120421",
    "label": "Hiligodu Botomuzoi",
    "value": "Hiligodu Botomuzoi"
  },
  {
    "id": "1204212002",
    "district_id": "120421",
    "label": "Lasara Botomuzoi",
    "value": "Lasara Botomuzoi"
  },
  {
    "id": "1204212003",
    "district_id": "120421",
    "label": "Hiliwaele I",
    "value": "Hiliwaele I"
  },
  {
    "id": "1204212004",
    "district_id": "120421",
    "label": "Hilimbowo Botomuzoi",
    "value": "Hilimbowo Botomuzoi"
  },
  {
    "id": "1204212005",
    "district_id": "120421",
    "label": "Simanaere Botomuzoi",
    "value": "Simanaere Botomuzoi"
  },
  {
    "id": "1204212006",
    "district_id": "120421",
    "label": "Hiliwaele II",
    "value": "Hiliwaele II"
  },
  {
    "id": "1204212007",
    "district_id": "120421",
    "label": "Tuhegafoa I",
    "value": "Tuhegafoa I"
  },
  {
    "id": "1204212008",
    "district_id": "120421",
    "label": "Balohili Botomuzoi",
    "value": "Balohili Botomuzoi"
  },
  {
    "id": "1204212009",
    "district_id": "120421",
    "label": "Hilihambawa Botomuzoi",
    "value": "Hilihambawa Botomuzoi"
  },
  {
    "id": "1204212010",
    "district_id": "120421",
    "label": "Fulolo Botomuzoi",
    "value": "Fulolo Botomuzoi"
  },
  {
    "id": "1204212011",
    "district_id": "120421",
    "label": "Loloana'a",
    "value": "Loloana'a"
  },
  {
    "id": "1204212012",
    "district_id": "120421",
    "label": "Ononamolo Talafu",
    "value": "Ononamolo Talafu"
  },
  {
    "id": "1204212013",
    "district_id": "120421",
    "label": "Sisobahili Dola",
    "value": "Sisobahili Dola"
  },
  {
    "id": "1204212014",
    "district_id": "120421",
    "label": "Banua Sibohou Botomuzoi",
    "value": "Banua Sibohou Botomuzoi"
  },
  {
    "id": "1204212015",
    "district_id": "120421",
    "label": "Mohili Berua Botomuzoi",
    "value": "Mohili Berua Botomuzoi"
  },
  {
    "id": "1204212016",
    "district_id": "120421",
    "label": "Tetehosi Botomuzoi",
    "value": "Tetehosi Botomuzoi"
  },
  {
    "id": "1204212017",
    "district_id": "120421",
    "label": "Talafu",
    "value": "Talafu"
  },
  {
    "id": "1204212018",
    "district_id": "120421",
    "label": "Ola Nori",
    "value": "Ola Nori"
  },
  {
    "id": "1204272001",
    "district_id": "120427",
    "label": "Orahili",
    "value": "Orahili"
  },
  {
    "id": "1204272002",
    "district_id": "120427",
    "label": "Hiliwetogela",
    "value": "Hiliwetogela"
  },
  {
    "id": "1204272003",
    "district_id": "120427",
    "label": "Lawalawa Luo",
    "value": "Lawalawa Luo"
  },
  {
    "id": "1204272004",
    "district_id": "120427",
    "label": "Hilimbowo",
    "value": "Hilimbowo"
  },
  {
    "id": "1204272005",
    "district_id": "120427",
    "label": "Hilibadalu",
    "value": "Hilibadalu"
  },
  {
    "id": "1204272006",
    "district_id": "120427",
    "label": "Fatodano",
    "value": "Fatodano"
  },
  {
    "id": "1204272007",
    "district_id": "120427",
    "label": "Fahandrona",
    "value": "Fahandrona"
  },
  {
    "id": "1204272008",
    "district_id": "120427",
    "label": "Onodalinga",
    "value": "Onodalinga"
  },
  {
    "id": "1204272009",
    "district_id": "120427",
    "label": "Holi",
    "value": "Holi"
  },
  {
    "id": "1204272010",
    "district_id": "120427",
    "label": "Sisobahili Ulu Gawo",
    "value": "Sisobahili Ulu Gawo"
  },
  {
    "id": "1204272011",
    "district_id": "120427",
    "label": "Sifaro'asi Ulu Gawo",
    "value": "Sifaro'asi Ulu Gawo"
  },
  {
    "id": "1204272012",
    "district_id": "120427",
    "label": "Mohili",
    "value": "Mohili"
  },
  {
    "id": "1204272013",
    "district_id": "120427",
    "label": "Hiligafoa",
    "value": "Hiligafoa"
  },
  {
    "id": "1204272014",
    "district_id": "120427",
    "label": "Sisarahili Soroma'asi",
    "value": "Sisarahili Soroma'asi"
  },
  {
    "id": "1204282001",
    "district_id": "120428",
    "label": "Balodano",
    "value": "Balodano"
  },
  {
    "id": "1204282002",
    "district_id": "120428",
    "label": "Lewuoguru II",
    "value": "Lewuoguru II"
  },
  {
    "id": "1204282003",
    "district_id": "120428",
    "label": "Sihare'o III",
    "value": "Sihare'o III"
  },
  {
    "id": "1204282004",
    "district_id": "120428",
    "label": "Sisarahilima'u",
    "value": "Sisarahilima'u"
  },
  {
    "id": "1204282005",
    "district_id": "120428",
    "label": "Lasara Siwalu Banua",
    "value": "Lasara Siwalu Banua"
  },
  {
    "id": "1204282006",
    "district_id": "120428",
    "label": "Tuhemberua",
    "value": "Tuhemberua"
  },
  {
    "id": "1204282007",
    "district_id": "120428",
    "label": "Atualuo",
    "value": "Atualuo"
  },
  {
    "id": "1204282008",
    "district_id": "120428",
    "label": "Lewa Lewa",
    "value": "Lewa Lewa"
  },
  {
    "id": "1204282009",
    "district_id": "120428",
    "label": "Dekha",
    "value": "Dekha"
  },
  {
    "id": "1204282010",
    "district_id": "120428",
    "label": "Sihare'o III Bawosalo'o Berua",
    "value": "Sihare'o III Bawosalo'o Berua"
  },
  {
    "id": "1204282011",
    "district_id": "120428",
    "label": "Sihare'o III Hilibadalu",
    "value": "Sihare'o III Hilibadalu"
  },
  {
    "id": "1204292001",
    "district_id": "120429",
    "label": "Sisobawino I",
    "value": "Sisobawino I"
  },
  {
    "id": "1204292002",
    "district_id": "120429",
    "label": "I'odano",
    "value": "I'odano"
  },
  {
    "id": "1204292003",
    "district_id": "120429",
    "label": "Huno",
    "value": "Huno"
  },
  {
    "id": "1204292004",
    "district_id": "120429",
    "label": "Sisaratandrawa",
    "value": "Sisaratandrawa"
  },
  {
    "id": "1204292005",
    "district_id": "120429",
    "label": "Somolo Molo",
    "value": "Somolo Molo"
  },
  {
    "id": "1204292006",
    "district_id": "120429",
    "label": "Lewuoguru I",
    "value": "Lewuoguru I"
  },
  {
    "id": "1204292007",
    "district_id": "120429",
    "label": "Sifaoro Asi",
    "value": "Sifaoro Asi"
  },
  {
    "id": "1204292008",
    "district_id": "120429",
    "label": "Lewuombanua",
    "value": "Lewuombanua"
  },
  {
    "id": "1204292009",
    "district_id": "120429",
    "label": "Hiligodu Somolo-Molo",
    "value": "Hiligodu Somolo-Molo"
  },
  {
    "id": "1204292010",
    "district_id": "120429",
    "label": "Hiliborodano",
    "value": "Hiliborodano"
  },
  {
    "id": "1204292011",
    "district_id": "120429",
    "label": "So'ewali",
    "value": "So'ewali"
  },
  {
    "id": "1204352001",
    "district_id": "120435",
    "label": "Tuhembuasi",
    "value": "Tuhembuasi"
  },
  {
    "id": "1204352002",
    "district_id": "120435",
    "label": "Hilibadalu",
    "value": "Hilibadalu"
  },
  {
    "id": "1204352003",
    "district_id": "120435",
    "label": "La'uri",
    "value": "La'uri"
  },
  {
    "id": "1204352004",
    "district_id": "120435",
    "label": "Hilimbana",
    "value": "Hilimbana"
  },
  {
    "id": "1204352005",
    "district_id": "120435",
    "label": "Saitagaramba",
    "value": "Saitagaramba"
  },
  {
    "id": "1204352006",
    "district_id": "120435",
    "label": "Sogae'adu",
    "value": "Sogae'adu"
  },
  {
    "id": "1204352007",
    "district_id": "120435",
    "label": "Baruzo",
    "value": "Baruzo"
  },
  {
    "id": "1204352008",
    "district_id": "120435",
    "label": "Tulumbaho",
    "value": "Tulumbaho"
  },
  {
    "id": "1204352009",
    "district_id": "120435",
    "label": "Sisarahili Sogae'adu",
    "value": "Sisarahili Sogae'adu"
  },
  {
    "id": "1204352010",
    "district_id": "120435",
    "label": "We'a-we'a",
    "value": "We'a-we'a"
  },
  {
    "id": "1204352011",
    "district_id": "120435",
    "label": "Sihare'o Sogae'adu",
    "value": "Sihare'o Sogae'adu"
  },
  {
    "id": "1205011001",
    "district_id": "120501",
    "label": "Pekan Bahorok",
    "value": "Pekan Bahorok"
  },
  {
    "id": "1205012002",
    "district_id": "120501",
    "label": "Lau Damak",
    "value": "Lau Damak"
  },
  {
    "id": "1205012003",
    "district_id": "120501",
    "label": "Timbang Lawan",
    "value": "Timbang Lawan"
  },
  {
    "id": "1205012004",
    "district_id": "120501",
    "label": "Sampe Raya",
    "value": "Sampe Raya"
  },
  {
    "id": "1205012005",
    "district_id": "120501",
    "label": "Tanjung Lenggang",
    "value": "Tanjung Lenggang"
  },
  {
    "id": "1205012006",
    "district_id": "120501",
    "label": "Suka Rakyat",
    "value": "Suka Rakyat"
  },
  {
    "id": "1205012007",
    "district_id": "120501",
    "label": "Perkebunan Turangi",
    "value": "Perkebunan Turangi"
  },
  {
    "id": "1205012008",
    "district_id": "120501",
    "label": "Perkebunan Bungara",
    "value": "Perkebunan Bungara"
  },
  {
    "id": "1205012009",
    "district_id": "120501",
    "label": "Perkebunan B. Lawang",
    "value": "Perkebunan B. Lawang"
  },
  {
    "id": "1205012011",
    "district_id": "120501",
    "label": "Empus",
    "value": "Empus"
  },
  {
    "id": "1205012012",
    "district_id": "120501",
    "label": "Simpang Pulo Rambung",
    "value": "Simpang Pulo Rambung"
  },
  {
    "id": "1205012013",
    "district_id": "120501",
    "label": "Perkebunan Sei Musam",
    "value": "Perkebunan Sei Musam"
  },
  {
    "id": "1205012014",
    "district_id": "120501",
    "label": "Batu Jong - Jong",
    "value": "Batu Jong - Jong"
  },
  {
    "id": "1205012015",
    "district_id": "120501",
    "label": "Sematar",
    "value": "Sematar"
  },
  {
    "id": "1205012016",
    "district_id": "120501",
    "label": "Perkebunan Pulo Rambung",
    "value": "Perkebunan Pulo Rambung"
  },
  {
    "id": "1205012017",
    "district_id": "120501",
    "label": "Sei Musam Kendit",
    "value": "Sei Musam Kendit"
  },
  {
    "id": "1205012020",
    "district_id": "120501",
    "label": "Timbang Jaya",
    "value": "Timbang Jaya"
  },
  {
    "id": "1205012021",
    "district_id": "120501",
    "label": "Musam Pembangunan",
    "value": "Musam Pembangunan"
  },
  {
    "id": "1205012022",
    "district_id": "120501",
    "label": "Ujung Bandar",
    "value": "Ujung Bandar"
  },
  {
    "id": "1205021007",
    "district_id": "120502",
    "label": "Tanjung Langkat",
    "value": "Tanjung Langkat"
  },
  {
    "id": "1205022002",
    "district_id": "120502",
    "label": "Perkebunan Tanjung Keliling",
    "value": "Perkebunan Tanjung Keliling"
  },
  {
    "id": "1205022004",
    "district_id": "120502",
    "label": "Perkebunan Gelugur Langkat",
    "value": "Perkebunan Gelugur Langkat"
  },
  {
    "id": "1205022005",
    "district_id": "120502",
    "label": "Perkebunan Tambunan",
    "value": "Perkebunan Tambunan"
  },
  {
    "id": "1205022006",
    "district_id": "120502",
    "label": "Perkebunan Bandar Telu",
    "value": "Perkebunan Bandar Telu"
  },
  {
    "id": "1205022008",
    "district_id": "120502",
    "label": "Naman Jahe",
    "value": "Naman Jahe"
  },
  {
    "id": "1205022009",
    "district_id": "120502",
    "label": "Turangi",
    "value": "Turangi"
  },
  {
    "id": "1205022010",
    "district_id": "120502",
    "label": "Ujung Teran",
    "value": "Ujung Teran"
  },
  {
    "id": "1205022011",
    "district_id": "120502",
    "label": "Pamah Tambunan",
    "value": "Pamah Tambunan"
  },
  {
    "id": "1205022014",
    "district_id": "120502",
    "label": "Minta Kasih",
    "value": "Minta Kasih"
  },
  {
    "id": "1205022019",
    "district_id": "120502",
    "label": "Ujung Bandar",
    "value": "Ujung Bandar"
  },
  {
    "id": "1205022020",
    "district_id": "120502",
    "label": "Lau Tepu",
    "value": "Lau Tepu"
  },
  {
    "id": "1205022021",
    "district_id": "120502",
    "label": "Parangguam",
    "value": "Parangguam"
  },
  {
    "id": "1205022023",
    "district_id": "120502",
    "label": "Ponco Warno",
    "value": "Ponco Warno"
  },
  {
    "id": "1205022024",
    "district_id": "120502",
    "label": "Adin Tengah",
    "value": "Adin Tengah"
  },
  {
    "id": "1205022025",
    "district_id": "120502",
    "label": "Lau Lugur",
    "value": "Lau Lugur"
  },
  {
    "id": "1205022026",
    "district_id": "120502",
    "label": "Pancur Ido",
    "value": "Pancur Ido"
  },
  {
    "id": "1205031001",
    "district_id": "120503",
    "label": "Pekan Kuala",
    "value": "Pekan Kuala"
  },
  {
    "id": "1205031019",
    "district_id": "120503",
    "label": "Bela Rakyat",
    "value": "Bela Rakyat"
  },
  {
    "id": "1205032002",
    "district_id": "120503",
    "label": "Dalan Naman",
    "value": "Dalan Naman"
  },
  {
    "id": "1205032003",
    "district_id": "120503",
    "label": "Balai Kasih",
    "value": "Balai Kasih"
  },
  {
    "id": "1205032004",
    "district_id": "120503",
    "label": "Bekiung",
    "value": "Bekiung"
  },
  {
    "id": "1205032005",
    "district_id": "120503",
    "label": "Blankahan",
    "value": "Blankahan"
  },
  {
    "id": "1205032006",
    "district_id": "120503",
    "label": "Besadi",
    "value": "Besadi"
  },
  {
    "id": "1205032007",
    "district_id": "120503",
    "label": "Beruam",
    "value": "Beruam"
  },
  {
    "id": "1205032009",
    "district_id": "120503",
    "label": "Raja Tengah",
    "value": "Raja Tengah"
  },
  {
    "id": "1205032011",
    "district_id": "120503",
    "label": "Namo Mbelin",
    "value": "Namo Mbelin"
  },
  {
    "id": "1205032012",
    "district_id": "120503",
    "label": "Parit Bindu",
    "value": "Parit Bindu"
  },
  {
    "id": "1205032013",
    "district_id": "120503",
    "label": "Sei Penjara",
    "value": "Sei Penjara"
  },
  {
    "id": "1205032015",
    "district_id": "120503",
    "label": "Perkebunan Bekiun",
    "value": "Perkebunan Bekiun"
  },
  {
    "id": "1205032016",
    "district_id": "120503",
    "label": "Garunggang",
    "value": "Garunggang"
  },
  {
    "id": "1205032017",
    "district_id": "120503",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1205032018",
    "district_id": "120503",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1205041005",
    "district_id": "120504",
    "label": "Namu Ukur Selatan",
    "value": "Namu Ukur Selatan"
  },
  {
    "id": "1205042001",
    "district_id": "120504",
    "label": "Telagah",
    "value": "Telagah"
  },
  {
    "id": "1205042002",
    "district_id": "120504",
    "label": "Rumah Galuh",
    "value": "Rumah Galuh"
  },
  {
    "id": "1205042003",
    "district_id": "120504",
    "label": "Simpang Kuta Buluh",
    "value": "Simpang Kuta Buluh"
  },
  {
    "id": "1205042004",
    "district_id": "120504",
    "label": "Gunung Ambat",
    "value": "Gunung Ambat"
  },
  {
    "id": "1205042006",
    "district_id": "120504",
    "label": "Belinteng",
    "value": "Belinteng"
  },
  {
    "id": "1205042007",
    "district_id": "120504",
    "label": "Namu Ukur Utara",
    "value": "Namu Ukur Utara"
  },
  {
    "id": "1205042008",
    "district_id": "120504",
    "label": "Pasar VI Kwala Mencirim",
    "value": "Pasar VI Kwala Mencirim"
  },
  {
    "id": "1205042009",
    "district_id": "120504",
    "label": "Emplasmen Kwala Mencirim",
    "value": "Emplasmen Kwala Mencirim"
  },
  {
    "id": "1205042010",
    "district_id": "120504",
    "label": "Purwobinangun",
    "value": "Purwobinangun"
  },
  {
    "id": "1205042011",
    "district_id": "120504",
    "label": "Pasar IV.Namo Terasi",
    "value": "Pasar IV.Namo Terasi"
  },
  {
    "id": "1205042012",
    "district_id": "120504",
    "label": "Pasar VIII.Namo Terasi",
    "value": "Pasar VIII.Namo Terasi"
  },
  {
    "id": "1205042013",
    "district_id": "120504",
    "label": "Durian Lingga",
    "value": "Durian Lingga"
  },
  {
    "id": "1205042014",
    "district_id": "120504",
    "label": "Pekan Sawah",
    "value": "Pekan Sawah"
  },
  {
    "id": "1205042015",
    "district_id": "120504",
    "label": "Tanjung Gunung",
    "value": "Tanjung Gunung"
  },
  {
    "id": "1205042016",
    "district_id": "120504",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1205051001",
    "district_id": "120505",
    "label": "Kwala Begumit",
    "value": "Kwala Begumit"
  },
  {
    "id": "1205052002",
    "district_id": "120505",
    "label": "Sambi Rejo",
    "value": "Sambi Rejo"
  },
  {
    "id": "1205052003",
    "district_id": "120505",
    "label": "Sendang Rejo",
    "value": "Sendang Rejo"
  },
  {
    "id": "1205052004",
    "district_id": "120505",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1205052005",
    "district_id": "120505",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1205052006",
    "district_id": "120505",
    "label": "Perdamaian",
    "value": "Perdamaian"
  },
  {
    "id": "1205052007",
    "district_id": "120505",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1205061001",
    "district_id": "120506",
    "label": "Pekan Selesai",
    "value": "Pekan Selesai"
  },
  {
    "id": "1205062002",
    "district_id": "120506",
    "label": "Sei Limbat",
    "value": "Sei Limbat"
  },
  {
    "id": "1205062003",
    "district_id": "120506",
    "label": "Padang Brahrang",
    "value": "Padang Brahrang"
  },
  {
    "id": "1205062004",
    "district_id": "120506",
    "label": "Tanjung Merahe",
    "value": "Tanjung Merahe"
  },
  {
    "id": "1205062005",
    "district_id": "120506",
    "label": "Nambiki",
    "value": "Nambiki"
  },
  {
    "id": "1205062006",
    "district_id": "120506",
    "label": "Lau Mulgap",
    "value": "Lau Mulgap"
  },
  {
    "id": "1205062007",
    "district_id": "120506",
    "label": "Kuta Parit",
    "value": "Kuta Parit"
  },
  {
    "id": "1205062008",
    "district_id": "120506",
    "label": "Bekulap",
    "value": "Bekulap"
  },
  {
    "id": "1205062009",
    "district_id": "120506",
    "label": "Perhiasan",
    "value": "Perhiasan"
  },
  {
    "id": "1205062010",
    "district_id": "120506",
    "label": "Selayang",
    "value": "Selayang"
  },
  {
    "id": "1205062011",
    "district_id": "120506",
    "label": "Mancang",
    "value": "Mancang"
  },
  {
    "id": "1205062012",
    "district_id": "120506",
    "label": "Kwala Air Hitam",
    "value": "Kwala Air Hitam"
  },
  {
    "id": "1205062013",
    "district_id": "120506",
    "label": "Padang Cermin",
    "value": "Padang Cermin"
  },
  {
    "id": "1205062014",
    "district_id": "120506",
    "label": "Selayang Baru",
    "value": "Selayang Baru"
  },
  {
    "id": "1205071004",
    "district_id": "120507",
    "label": "Perdamaian",
    "value": "Perdamaian"
  },
  {
    "id": "1205071005",
    "district_id": "120507",
    "label": "Kwala Bingai",
    "value": "Kwala Bingai"
  },
  {
    "id": "1205071006",
    "district_id": "120507",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1205071010",
    "district_id": "120507",
    "label": "Stabat Baru",
    "value": "Stabat Baru"
  },
  {
    "id": "1205071011",
    "district_id": "120507",
    "label": "Dendang",
    "value": "Dendang"
  },
  {
    "id": "1205071012",
    "district_id": "120507",
    "label": "Paya Mabar",
    "value": "Paya Mabar"
  },
  {
    "id": "1205072001",
    "district_id": "120507",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1205072002",
    "district_id": "120507",
    "label": "Kwala Begumit",
    "value": "Kwala Begumit"
  },
  {
    "id": "1205072003",
    "district_id": "120507",
    "label": "Mangga",
    "value": "Mangga"
  },
  {
    "id": "1205072007",
    "district_id": "120507",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "1205072008",
    "district_id": "120507",
    "label": "Pantai Gemi",
    "value": "Pantai Gemi"
  },
  {
    "id": "1205072009",
    "district_id": "120507",
    "label": "Ara Condong",
    "value": "Ara Condong"
  },
  {
    "id": "1205081001",
    "district_id": "120508",
    "label": "Bingai",
    "value": "Bingai"
  },
  {
    "id": "1205082002",
    "district_id": "120508",
    "label": "Gohor Lama",
    "value": "Gohor Lama"
  },
  {
    "id": "1205082003",
    "district_id": "120508",
    "label": "Stabat Lama",
    "value": "Stabat Lama"
  },
  {
    "id": "1205082004",
    "district_id": "120508",
    "label": "Besilam Bukit Lembasa",
    "value": "Besilam Bukit Lembasa"
  },
  {
    "id": "1205082005",
    "district_id": "120508",
    "label": "Kebun Balok",
    "value": "Kebun Balok"
  },
  {
    "id": "1205082006",
    "district_id": "120508",
    "label": "Bukit Melintang",
    "value": "Bukit Melintang"
  },
  {
    "id": "1205082007",
    "district_id": "120508",
    "label": "Gergas",
    "value": "Gergas"
  },
  {
    "id": "1205082008",
    "district_id": "120508",
    "label": "Stabat Lama Barat",
    "value": "Stabat Lama Barat"
  },
  {
    "id": "1205082009",
    "district_id": "120508",
    "label": "Sumber Mulyo",
    "value": "Sumber Mulyo"
  },
  {
    "id": "1205082010",
    "district_id": "120508",
    "label": "Pertumbukan",
    "value": "Pertumbukan"
  },
  {
    "id": "1205082011",
    "district_id": "120508",
    "label": "Paya Tusam",
    "value": "Paya Tusam"
  },
  {
    "id": "1205082012",
    "district_id": "120508",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1205082013",
    "district_id": "120508",
    "label": "Jentera Stabat",
    "value": "Jentera Stabat"
  },
  {
    "id": "1205082014",
    "district_id": "120508",
    "label": "Stungkit",
    "value": "Stungkit"
  },
  {
    "id": "1205091004",
    "district_id": "120509",
    "label": "Hinai Kiri",
    "value": "Hinai Kiri"
  },
  {
    "id": "1205092001",
    "district_id": "120509",
    "label": "Kepala Sungai",
    "value": "Kepala Sungai"
  },
  {
    "id": "1205092002",
    "district_id": "120509",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "1205092003",
    "district_id": "120509",
    "label": "Cinta Raja",
    "value": "Cinta Raja"
  },
  {
    "id": "1205092005",
    "district_id": "120509",
    "label": "Kebun Kelapa",
    "value": "Kebun Kelapa"
  },
  {
    "id": "1205092006",
    "district_id": "120509",
    "label": "Sungai Ular",
    "value": "Sungai Ular"
  },
  {
    "id": "1205092007",
    "district_id": "120509",
    "label": "Tanjung Ibus",
    "value": "Tanjung Ibus"
  },
  {
    "id": "1205092008",
    "district_id": "120509",
    "label": "Secanggang",
    "value": "Secanggang"
  },
  {
    "id": "1205092009",
    "district_id": "120509",
    "label": "Selotong",
    "value": "Selotong"
  },
  {
    "id": "1205092010",
    "district_id": "120509",
    "label": "Karang Gading",
    "value": "Karang Gading"
  },
  {
    "id": "1205092011",
    "district_id": "120509",
    "label": "Jaring Halus",
    "value": "Jaring Halus"
  },
  {
    "id": "1205092012",
    "district_id": "120509",
    "label": "Telaga Jernih",
    "value": "Telaga Jernih"
  },
  {
    "id": "1205092013",
    "district_id": "120509",
    "label": "Perkotaan",
    "value": "Perkotaan"
  },
  {
    "id": "1205092014",
    "district_id": "120509",
    "label": "Kwala Besar",
    "value": "Kwala Besar"
  },
  {
    "id": "1205092015",
    "district_id": "120509",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1205092016",
    "district_id": "120509",
    "label": "Pantai Gading",
    "value": "Pantai Gading"
  },
  {
    "id": "1205092017",
    "district_id": "120509",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "1205101008",
    "district_id": "120510",
    "label": "Kebun Lada",
    "value": "Kebun Lada"
  },
  {
    "id": "1205102001",
    "district_id": "120510",
    "label": "Cempa",
    "value": "Cempa"
  },
  {
    "id": "1205102002",
    "district_id": "120510",
    "label": "Batu Malenggang",
    "value": "Batu Malenggang"
  },
  {
    "id": "1205102003",
    "district_id": "120510",
    "label": "Tamaran",
    "value": "Tamaran"
  },
  {
    "id": "1205102004",
    "district_id": "120510",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1205102005",
    "district_id": "120510",
    "label": "Muka Paya",
    "value": "Muka Paya"
  },
  {
    "id": "1205102006",
    "district_id": "120510",
    "label": "Hinai Kanan",
    "value": "Hinai Kanan"
  },
  {
    "id": "1205102007",
    "district_id": "120510",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1205102009",
    "district_id": "120510",
    "label": "Baru Pasar VIII",
    "value": "Baru Pasar VIII"
  },
  {
    "id": "1205102010",
    "district_id": "120510",
    "label": "Perkebunan Tanjung Beringin",
    "value": "Perkebunan Tanjung Beringin"
  },
  {
    "id": "1205102011",
    "district_id": "120510",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1205102012",
    "district_id": "120510",
    "label": "Paya Rengas",
    "value": "Paya Rengas"
  },
  {
    "id": "1205102013",
    "district_id": "120510",
    "label": "Suka Damai Timur",
    "value": "Suka Damai Timur"
  },
  {
    "id": "1205111005",
    "district_id": "120511",
    "label": "Pekan Tanjung Pura",
    "value": "Pekan Tanjung Pura"
  },
  {
    "id": "1205112001",
    "district_id": "120511",
    "label": "Serapuh Asli",
    "value": "Serapuh Asli"
  },
  {
    "id": "1205112002",
    "district_id": "120511",
    "label": "Pematang Tengah",
    "value": "Pematang Tengah"
  },
  {
    "id": "1205112003",
    "district_id": "120511",
    "label": "Paya Perupuk",
    "value": "Paya Perupuk"
  },
  {
    "id": "1205112004",
    "district_id": "120511",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1205112006",
    "district_id": "120511",
    "label": "Pekubuan",
    "value": "Pekubuan"
  },
  {
    "id": "1205112007",
    "district_id": "120511",
    "label": "Teluk Bakung",
    "value": "Teluk Bakung"
  },
  {
    "id": "1205112008",
    "district_id": "120511",
    "label": "Pantai Cermin",
    "value": "Pantai Cermin"
  },
  {
    "id": "1205112009",
    "district_id": "120511",
    "label": "Pematang Serai",
    "value": "Pematang Serai"
  },
  {
    "id": "1205112010",
    "district_id": "120511",
    "label": "Baja Kuning",
    "value": "Baja Kuning"
  },
  {
    "id": "1205112011",
    "district_id": "120511",
    "label": "Pulau Banyak",
    "value": "Pulau Banyak"
  },
  {
    "id": "1205112012",
    "district_id": "120511",
    "label": "Pematang Cengal",
    "value": "Pematang Cengal"
  },
  {
    "id": "1205112013",
    "district_id": "120511",
    "label": "Kwala Serapuh",
    "value": "Kwala Serapuh"
  },
  {
    "id": "1205112014",
    "district_id": "120511",
    "label": "Kwala Langkat",
    "value": "Kwala Langkat"
  },
  {
    "id": "1205112015",
    "district_id": "120511",
    "label": "Bubun",
    "value": "Bubun"
  },
  {
    "id": "1205112016",
    "district_id": "120511",
    "label": "Tapak Kuda",
    "value": "Tapak Kuda"
  },
  {
    "id": "1205112017",
    "district_id": "120511",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1205112018",
    "district_id": "120511",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "1205112019",
    "district_id": "120511",
    "label": "Pematang Cengal Barat",
    "value": "Pematang Cengal Barat"
  },
  {
    "id": "1205121006",
    "district_id": "120512",
    "label": "Tanjung Selamat",
    "value": "Tanjung Selamat"
  },
  {
    "id": "1205122001",
    "district_id": "120512",
    "label": "Serapuh ABC",
    "value": "Serapuh ABC"
  },
  {
    "id": "1205122002",
    "district_id": "120512",
    "label": "Padang Tualang",
    "value": "Padang Tualang"
  },
  {
    "id": "1205122003",
    "district_id": "120512",
    "label": "Kwala Besilam",
    "value": "Kwala Besilam"
  },
  {
    "id": "1205122004",
    "district_id": "120512",
    "label": "Buluh Telang",
    "value": "Buluh Telang"
  },
  {
    "id": "1205122005",
    "district_id": "120512",
    "label": "Besilam",
    "value": "Besilam"
  },
  {
    "id": "1205122007",
    "district_id": "120512",
    "label": "Tebing Tanjung Selamat",
    "value": "Tebing Tanjung Selamat"
  },
  {
    "id": "1205122008",
    "district_id": "120512",
    "label": "Tanjung Putus",
    "value": "Tanjung Putus"
  },
  {
    "id": "1205122009",
    "district_id": "120512",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "1205122010",
    "district_id": "120512",
    "label": "Jati Sari",
    "value": "Jati Sari"
  },
  {
    "id": "1205122011",
    "district_id": "120512",
    "label": "Banjaran Raya",
    "value": "Banjaran Raya"
  },
  {
    "id": "1205122012",
    "district_id": "120512",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1205131001",
    "district_id": "120513",
    "label": "Pekan Gebang",
    "value": "Pekan Gebang"
  },
  {
    "id": "1205132002",
    "district_id": "120513",
    "label": "Paluh Manis",
    "value": "Paluh Manis"
  },
  {
    "id": "1205132003",
    "district_id": "120513",
    "label": "Pasar Rawa",
    "value": "Pasar Rawa"
  },
  {
    "id": "1205132004",
    "district_id": "120513",
    "label": "Padang Langkat",
    "value": "Padang Langkat"
  },
  {
    "id": "1205132005",
    "district_id": "120513",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1205132006",
    "district_id": "120513",
    "label": "Paya Bengkuang",
    "value": "Paya Bengkuang"
  },
  {
    "id": "1205132007",
    "district_id": "120513",
    "label": "Dogang",
    "value": "Dogang"
  },
  {
    "id": "1205132008",
    "district_id": "120513",
    "label": "Sangga Lima",
    "value": "Sangga Lima"
  },
  {
    "id": "1205132009",
    "district_id": "120513",
    "label": "Kwala Gebang",
    "value": "Kwala Gebang"
  },
  {
    "id": "1205132010",
    "district_id": "120513",
    "label": "Bukit Mengkirai",
    "value": "Bukit Mengkirai"
  },
  {
    "id": "1205132011",
    "district_id": "120513",
    "label": "Pasiran",
    "value": "Pasiran"
  },
  {
    "id": "1205141001",
    "district_id": "120514",
    "label": "Pelawi Utara",
    "value": "Pelawi Utara"
  },
  {
    "id": "1205141006",
    "district_id": "120514",
    "label": "Berandan Barat",
    "value": "Berandan Barat"
  },
  {
    "id": "1205141007",
    "district_id": "120514",
    "label": "Berandan Timur Baru",
    "value": "Berandan Timur Baru"
  },
  {
    "id": "1205141008",
    "district_id": "120514",
    "label": "Berandan Timur",
    "value": "Berandan Timur"
  },
  {
    "id": "1205142002",
    "district_id": "120514",
    "label": "Securai Utara",
    "value": "Securai Utara"
  },
  {
    "id": "1205142003",
    "district_id": "120514",
    "label": "Teluk Meku",
    "value": "Teluk Meku"
  },
  {
    "id": "1205142004",
    "district_id": "120514",
    "label": "Pelawi Selatan",
    "value": "Pelawi Selatan"
  },
  {
    "id": "1205142005",
    "district_id": "120514",
    "label": "Securai Selatan",
    "value": "Securai Selatan"
  },
  {
    "id": "1205151009",
    "district_id": "120515",
    "label": "Beras Basah",
    "value": "Beras Basah"
  },
  {
    "id": "1205151010",
    "district_id": "120515",
    "label": "Bukit Jengkol",
    "value": "Bukit Jengkol"
  },
  {
    "id": "1205152001",
    "district_id": "120515",
    "label": "Alur Cempedak",
    "value": "Alur Cempedak"
  },
  {
    "id": "1205152002",
    "district_id": "120515",
    "label": "Sei Siur",
    "value": "Sei Siur"
  },
  {
    "id": "1205152003",
    "district_id": "120515",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "1205152004",
    "district_id": "120515",
    "label": "Paya Tampak",
    "value": "Paya Tampak"
  },
  {
    "id": "1205152005",
    "district_id": "120515",
    "label": "Pintu Air",
    "value": "Pintu Air"
  },
  {
    "id": "1205152006",
    "district_id": "120515",
    "label": "Sei Meran",
    "value": "Sei Meran"
  },
  {
    "id": "1205152007",
    "district_id": "120515",
    "label": "Pulau Sembilan",
    "value": "Pulau Sembilan"
  },
  {
    "id": "1205152008",
    "district_id": "120515",
    "label": "Pulau Kampai",
    "value": "Pulau Kampai"
  },
  {
    "id": "1205152011",
    "district_id": "120515",
    "label": "Pangkalan Siata",
    "value": "Pangkalan Siata"
  },
  {
    "id": "1205161009",
    "district_id": "120516",
    "label": "Pekan Besitang",
    "value": "Pekan Besitang"
  },
  {
    "id": "1205161010",
    "district_id": "120516",
    "label": "Bukit Kubu",
    "value": "Bukit Kubu"
  },
  {
    "id": "1205161011",
    "district_id": "120516",
    "label": "Kampung Lama",
    "value": "Kampung Lama"
  },
  {
    "id": "1205162001",
    "district_id": "120516",
    "label": "Halaban",
    "value": "Halaban"
  },
  {
    "id": "1205162002",
    "district_id": "120516",
    "label": "Bukit Mas",
    "value": "Bukit Mas"
  },
  {
    "id": "1205162005",
    "district_id": "120516",
    "label": "Pir Adb Besitang",
    "value": "Pir Adb Besitang"
  },
  {
    "id": "1205162006",
    "district_id": "120516",
    "label": "Sekoci",
    "value": "Sekoci"
  },
  {
    "id": "1205162007",
    "district_id": "120516",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1205162008",
    "district_id": "120516",
    "label": "Bukit Selamat",
    "value": "Bukit Selamat"
  },
  {
    "id": "1205171001",
    "district_id": "120517",
    "label": "Sei Bilah",
    "value": "Sei Bilah"
  },
  {
    "id": "1205171002",
    "district_id": "120517",
    "label": "Alur Dua",
    "value": "Alur Dua"
  },
  {
    "id": "1205171008",
    "district_id": "120517",
    "label": "Alur Dua Baru",
    "value": "Alur Dua Baru"
  },
  {
    "id": "1205171009",
    "district_id": "120517",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1205171010",
    "district_id": "120517",
    "label": "Sei Bilah Timur",
    "value": "Sei Bilah Timur"
  },
  {
    "id": "1205172003",
    "district_id": "120517",
    "label": "Puraka I",
    "value": "Puraka I"
  },
  {
    "id": "1205172004",
    "district_id": "120517",
    "label": "Puraka II",
    "value": "Puraka II"
  },
  {
    "id": "1205172005",
    "district_id": "120517",
    "label": "Lama",
    "value": "Lama"
  },
  {
    "id": "1205172006",
    "district_id": "120517",
    "label": "Telaga Said",
    "value": "Telaga Said"
  },
  {
    "id": "1205172007",
    "district_id": "120517",
    "label": "Lama Baru",
    "value": "Lama Baru"
  },
  {
    "id": "1205172011",
    "district_id": "120517",
    "label": "Mekar Makmur",
    "value": "Mekar Makmur"
  },
  {
    "id": "1205172012",
    "district_id": "120517",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "1205172013",
    "district_id": "120517",
    "label": "Harapan Maju",
    "value": "Harapan Maju"
  },
  {
    "id": "1205172014",
    "district_id": "120517",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "1205181001",
    "district_id": "120518",
    "label": "Tangkahan Durian",
    "value": "Tangkahan Durian"
  },
  {
    "id": "1205181004",
    "district_id": "120518",
    "label": "Pangkalan Batu",
    "value": "Pangkalan Batu"
  },
  {
    "id": "1205182002",
    "district_id": "120518",
    "label": "Sei Tualang",
    "value": "Sei Tualang"
  },
  {
    "id": "1205182003",
    "district_id": "120518",
    "label": "Lubuk Kasih",
    "value": "Lubuk Kasih"
  },
  {
    "id": "1205182005",
    "district_id": "120518",
    "label": "Lubuk Kertang",
    "value": "Lubuk Kertang"
  },
  {
    "id": "1205182006",
    "district_id": "120518",
    "label": "Perlis",
    "value": "Perlis"
  },
  {
    "id": "1205182007",
    "district_id": "120518",
    "label": "Kelantan",
    "value": "Kelantan"
  },
  {
    "id": "1205191001",
    "district_id": "120519",
    "label": "Batang Serangan",
    "value": "Batang Serangan"
  },
  {
    "id": "1205192002",
    "district_id": "120519",
    "label": "Sei Bamban",
    "value": "Sei Bamban"
  },
  {
    "id": "1205192003",
    "district_id": "120519",
    "label": "Sei Musam",
    "value": "Sei Musam"
  },
  {
    "id": "1205192004",
    "district_id": "120519",
    "label": "Sei Serdang",
    "value": "Sei Serdang"
  },
  {
    "id": "1205192005",
    "district_id": "120519",
    "label": "Namo Sialang",
    "value": "Namo Sialang"
  },
  {
    "id": "1205192006",
    "district_id": "120519",
    "label": "Kwala Musam",
    "value": "Kwala Musam"
  },
  {
    "id": "1205192007",
    "district_id": "120519",
    "label": "Karya Jadi",
    "value": "Karya Jadi"
  },
  {
    "id": "1205192008",
    "district_id": "120519",
    "label": "Paluh Pakeh Babussalam",
    "value": "Paluh Pakeh Babussalam"
  },
  {
    "id": "1205201001",
    "district_id": "120520",
    "label": "Sawit Seberang",
    "value": "Sawit Seberang"
  },
  {
    "id": "1205202002",
    "district_id": "120520",
    "label": "Alur Gadung",
    "value": "Alur Gadung"
  },
  {
    "id": "1205202003",
    "district_id": "120520",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1205202004",
    "district_id": "120520",
    "label": "Sei Litur Tasik",
    "value": "Sei Litur Tasik"
  },
  {
    "id": "1205202005",
    "district_id": "120520",
    "label": "Sawit Hulu",
    "value": "Sawit Hulu"
  },
  {
    "id": "1205202006",
    "district_id": "120520",
    "label": "Mekar Sawit",
    "value": "Mekar Sawit"
  },
  {
    "id": "1205202007",
    "district_id": "120520",
    "label": "Alur Melati",
    "value": "Alur Melati"
  },
  {
    "id": "1205212001",
    "district_id": "120521",
    "label": "Serapit",
    "value": "Serapit"
  },
  {
    "id": "1205212002",
    "district_id": "120521",
    "label": "Gunung Tinggi",
    "value": "Gunung Tinggi"
  },
  {
    "id": "1205212003",
    "district_id": "120521",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1205212004",
    "district_id": "120521",
    "label": "Suka Pulung",
    "value": "Suka Pulung"
  },
  {
    "id": "1205212005",
    "district_id": "120521",
    "label": "Aman Damai",
    "value": "Aman Damai"
  },
  {
    "id": "1205212006",
    "district_id": "120521",
    "label": "Tanjung Kariahan",
    "value": "Tanjung Kariahan"
  },
  {
    "id": "1205212007",
    "district_id": "120521",
    "label": "Sebertung",
    "value": "Sebertung"
  },
  {
    "id": "1205212008",
    "district_id": "120521",
    "label": "Pulau Semikat",
    "value": "Pulau Semikat"
  },
  {
    "id": "1205212009",
    "district_id": "120521",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1205212010",
    "district_id": "120521",
    "label": "Perkebunan Amal Tani",
    "value": "Perkebunan Amal Tani"
  },
  {
    "id": "1205222001",
    "district_id": "120522",
    "label": "Namotogan",
    "value": "Namotogan"
  },
  {
    "id": "1205222002",
    "district_id": "120522",
    "label": "Kuta Gajah",
    "value": "Kuta Gajah"
  },
  {
    "id": "1205222003",
    "district_id": "120522",
    "label": "Perkebunan Marike",
    "value": "Perkebunan Marike"
  },
  {
    "id": "1205222004",
    "district_id": "120522",
    "label": "Kaperas",
    "value": "Kaperas"
  },
  {
    "id": "1205222005",
    "district_id": "120522",
    "label": "Namoteras",
    "value": "Namoteras"
  },
  {
    "id": "1205222006",
    "district_id": "120522",
    "label": "Rampah",
    "value": "Rampah"
  },
  {
    "id": "1205222007",
    "district_id": "120522",
    "label": "Sulkam",
    "value": "Sulkam"
  },
  {
    "id": "1205222008",
    "district_id": "120522",
    "label": "Kutambaru",
    "value": "Kutambaru"
  },
  {
    "id": "1205232001",
    "district_id": "120523",
    "label": "Pematang Tengah",
    "value": "Pematang Tengah"
  },
  {
    "id": "1205232002",
    "district_id": "120523",
    "label": "Damar Condong",
    "value": "Damar Condong"
  },
  {
    "id": "1205232003",
    "district_id": "120523",
    "label": "Perkebunan Damar Condong",
    "value": "Perkebunan Damar Condong"
  },
  {
    "id": "1205232004",
    "district_id": "120523",
    "label": "Perkebunan Perapen",
    "value": "Perkebunan Perapen"
  },
  {
    "id": "1205232005",
    "district_id": "120523",
    "label": "Limau Mungkur",
    "value": "Limau Mungkur"
  },
  {
    "id": "1205232006",
    "district_id": "120523",
    "label": "Serang Jaya Hilir",
    "value": "Serang Jaya Hilir"
  },
  {
    "id": "1205232007",
    "district_id": "120523",
    "label": "Serang Jaya",
    "value": "Serang Jaya"
  },
  {
    "id": "1205232008",
    "district_id": "120523",
    "label": "Sala Haji",
    "value": "Sala Haji"
  },
  {
    "id": "1206011009",
    "district_id": "120601",
    "label": "Padang Mas",
    "value": "Padang Mas"
  },
  {
    "id": "1206011010",
    "district_id": "120601",
    "label": "Lau Cimba",
    "value": "Lau Cimba"
  },
  {
    "id": "1206011011",
    "district_id": "120601",
    "label": "Gung Leto",
    "value": "Gung Leto"
  },
  {
    "id": "1206011012",
    "district_id": "120601",
    "label": "Gung Negeri",
    "value": "Gung Negeri"
  },
  {
    "id": "1206011013",
    "district_id": "120601",
    "label": "KampungDalam",
    "value": "KampungDalam"
  },
  {
    "id": "1206012001",
    "district_id": "120601",
    "label": "Rumah Kabanjahe",
    "value": "Rumah Kabanjahe"
  },
  {
    "id": "1206012002",
    "district_id": "120601",
    "label": "Kaban",
    "value": "Kaban"
  },
  {
    "id": "1206012003",
    "district_id": "120601",
    "label": "Ketaren",
    "value": "Ketaren"
  },
  {
    "id": "1206012004",
    "district_id": "120601",
    "label": "Samura",
    "value": "Samura"
  },
  {
    "id": "1206012005",
    "district_id": "120601",
    "label": "Lau Simomo",
    "value": "Lau Simomo"
  },
  {
    "id": "1206012006",
    "district_id": "120601",
    "label": "Sumber Mufakat",
    "value": "Sumber Mufakat"
  },
  {
    "id": "1206012007",
    "district_id": "120601",
    "label": "Kandibata",
    "value": "Kandibata"
  },
  {
    "id": "1206012008",
    "district_id": "120601",
    "label": "Kacaribu",
    "value": "Kacaribu"
  },
  {
    "id": "1206021006",
    "district_id": "120602",
    "label": "Tambak Lau Mulgab I",
    "value": "Tambak Lau Mulgab I"
  },
  {
    "id": "1206021007",
    "district_id": "120602",
    "label": "Tambak Lau Mulgab II",
    "value": "Tambak Lau Mulgab II"
  },
  {
    "id": "1206021008",
    "district_id": "120602",
    "label": "Gundaling I",
    "value": "Gundaling I"
  },
  {
    "id": "1206021009",
    "district_id": "120602",
    "label": "Gundaling II",
    "value": "Gundaling II"
  },
  {
    "id": "1206022001",
    "district_id": "120602",
    "label": "Daulu",
    "value": "Daulu"
  },
  {
    "id": "1206022002",
    "district_id": "120602",
    "label": "Gurusinga",
    "value": "Gurusinga"
  },
  {
    "id": "1206022003",
    "district_id": "120602",
    "label": "Raya",
    "value": "Raya"
  },
  {
    "id": "1206022004",
    "district_id": "120602",
    "label": "Rumah Berastagi",
    "value": "Rumah Berastagi"
  },
  {
    "id": "1206022005",
    "district_id": "120602",
    "label": "Sempajaya",
    "value": "Sempajaya"
  },
  {
    "id": "1206022010",
    "district_id": "120602",
    "label": "Lau Gumba",
    "value": "Lau Gumba"
  },
  {
    "id": "1206032001",
    "district_id": "120603",
    "label": "Paribun",
    "value": "Paribun"
  },
  {
    "id": "1206032002",
    "district_id": "120603",
    "label": "Rumamis",
    "value": "Rumamis"
  },
  {
    "id": "1206032003",
    "district_id": "120603",
    "label": "Sinaman",
    "value": "Sinaman"
  },
  {
    "id": "1206032004",
    "district_id": "120603",
    "label": "Sukanalu",
    "value": "Sukanalu"
  },
  {
    "id": "1206032005",
    "district_id": "120603",
    "label": "Barusjulu",
    "value": "Barusjulu"
  },
  {
    "id": "1206032006",
    "district_id": "120603",
    "label": "Tangkidik",
    "value": "Tangkidik"
  },
  {
    "id": "1206032007",
    "district_id": "120603",
    "label": "Sukajulu",
    "value": "Sukajulu"
  },
  {
    "id": "1206032008",
    "district_id": "120603",
    "label": "Barusjahe",
    "value": "Barusjahe"
  },
  {
    "id": "1206032009",
    "district_id": "120603",
    "label": "Bulanjahe",
    "value": "Bulanjahe"
  },
  {
    "id": "1206032010",
    "district_id": "120603",
    "label": "Pertumbuken",
    "value": "Pertumbuken"
  },
  {
    "id": "1206032011",
    "district_id": "120603",
    "label": "Talimbaru",
    "value": "Talimbaru"
  },
  {
    "id": "1206032012",
    "district_id": "120603",
    "label": "Sikab",
    "value": "Sikab"
  },
  {
    "id": "1206032013",
    "district_id": "120603",
    "label": "Persadanta",
    "value": "Persadanta"
  },
  {
    "id": "1206032014",
    "district_id": "120603",
    "label": "Bulanjulu",
    "value": "Bulanjulu"
  },
  {
    "id": "1206032015",
    "district_id": "120603",
    "label": "Semangat",
    "value": "Semangat"
  },
  {
    "id": "1206032016",
    "district_id": "120603",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1206032017",
    "district_id": "120603",
    "label": "Tanjung Barus",
    "value": "Tanjung Barus"
  },
  {
    "id": "1206032018",
    "district_id": "120603",
    "label": "Sarimanis",
    "value": "Sarimanis"
  },
  {
    "id": "1206032019",
    "district_id": "120603",
    "label": "Penampen",
    "value": "Penampen"
  },
  {
    "id": "1206042001",
    "district_id": "120604",
    "label": "Kutakepar",
    "value": "Kutakepar"
  },
  {
    "id": "1206042002",
    "district_id": "120604",
    "label": "Ajijulu",
    "value": "Ajijulu"
  },
  {
    "id": "1206042004",
    "district_id": "120604",
    "label": "Singa",
    "value": "Singa"
  },
  {
    "id": "1206042007",
    "district_id": "120604",
    "label": "Ajijahe",
    "value": "Ajijahe"
  },
  {
    "id": "1206042008",
    "district_id": "120604",
    "label": "Bunuraya",
    "value": "Bunuraya"
  },
  {
    "id": "1206042010",
    "district_id": "120604",
    "label": "Kubusimbelang",
    "value": "Kubusimbelang"
  },
  {
    "id": "1206042011",
    "district_id": "120604",
    "label": "Tigapanah",
    "value": "Tigapanah"
  },
  {
    "id": "1206042012",
    "district_id": "120604",
    "label": "Suka",
    "value": "Suka"
  },
  {
    "id": "1206042014",
    "district_id": "120604",
    "label": "Kacinambun",
    "value": "Kacinambun"
  },
  {
    "id": "1206042015",
    "district_id": "120604",
    "label": "Kutajulu",
    "value": "Kutajulu"
  },
  {
    "id": "1206042016",
    "district_id": "120604",
    "label": "Bertah",
    "value": "Bertah"
  },
  {
    "id": "1206042017",
    "district_id": "120604",
    "label": "Manukmulia",
    "value": "Manukmulia"
  },
  {
    "id": "1206042018",
    "district_id": "120604",
    "label": "Lauriman",
    "value": "Lauriman"
  },
  {
    "id": "1206042019",
    "district_id": "120604",
    "label": "Sukadame",
    "value": "Sukadame"
  },
  {
    "id": "1206042020",
    "district_id": "120604",
    "label": "Mulawari",
    "value": "Mulawari"
  },
  {
    "id": "1206042023",
    "district_id": "120604",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1206042024",
    "district_id": "120604",
    "label": "Kutambelin",
    "value": "Kutambelin"
  },
  {
    "id": "1206042025",
    "district_id": "120604",
    "label": "Kutabale",
    "value": "Kutabale"
  },
  {
    "id": "1206042026",
    "district_id": "120604",
    "label": "Leparsamura",
    "value": "Leparsamura"
  },
  {
    "id": "1206042027",
    "district_id": "120604",
    "label": "Seberaya",
    "value": "Seberaya"
  },
  {
    "id": "1206042028",
    "district_id": "120604",
    "label": "Ajimbelang",
    "value": "Ajimbelang"
  },
  {
    "id": "1206042029",
    "district_id": "120604",
    "label": "Ajibuhara",
    "value": "Ajibuhara"
  },
  {
    "id": "1206042030",
    "district_id": "120604",
    "label": "Lambar",
    "value": "Lambar"
  },
  {
    "id": "1206042031",
    "district_id": "120604",
    "label": "Salit",
    "value": "Salit"
  },
  {
    "id": "1206042032",
    "district_id": "120604",
    "label": "Suka Mbayak",
    "value": "Suka Mbayak"
  },
  {
    "id": "1206042033",
    "district_id": "120604",
    "label": "Suka Sipilihen",
    "value": "Suka Sipilihen"
  },
  {
    "id": "1206052001",
    "district_id": "120605",
    "label": "Partibi Tembe",
    "value": "Partibi Tembe"
  },
  {
    "id": "1206052002",
    "district_id": "120605",
    "label": "Negeri Tongging",
    "value": "Negeri Tongging"
  },
  {
    "id": "1206052003",
    "district_id": "120605",
    "label": "Kodon-Kodon",
    "value": "Kodon-Kodon"
  },
  {
    "id": "1206052004",
    "district_id": "120605",
    "label": "Dokan",
    "value": "Dokan"
  },
  {
    "id": "1206052005",
    "district_id": "120605",
    "label": "Nagara",
    "value": "Nagara"
  },
  {
    "id": "1206052006",
    "district_id": "120605",
    "label": "Sibolangit",
    "value": "Sibolangit"
  },
  {
    "id": "1206052007",
    "district_id": "120605",
    "label": "Sukamandi",
    "value": "Sukamandi"
  },
  {
    "id": "1206052008",
    "district_id": "120605",
    "label": "Nagalingga",
    "value": "Nagalingga"
  },
  {
    "id": "1206052009",
    "district_id": "120605",
    "label": "Pangambatan",
    "value": "Pangambatan"
  },
  {
    "id": "1206052010",
    "district_id": "120605",
    "label": "Tongging",
    "value": "Tongging"
  },
  {
    "id": "1206052011",
    "district_id": "120605",
    "label": "Partibi Lama",
    "value": "Partibi Lama"
  },
  {
    "id": "1206052012",
    "district_id": "120605",
    "label": "Regaji",
    "value": "Regaji"
  },
  {
    "id": "1206052013",
    "district_id": "120605",
    "label": "Ajinembah",
    "value": "Ajinembah"
  },
  {
    "id": "1206052014",
    "district_id": "120605",
    "label": "Situnggaling",
    "value": "Situnggaling"
  },
  {
    "id": "1206052015",
    "district_id": "120605",
    "label": "Garingging",
    "value": "Garingging"
  },
  {
    "id": "1206052016",
    "district_id": "120605",
    "label": "Mulia Rayat",
    "value": "Mulia Rayat"
  },
  {
    "id": "1206052017",
    "district_id": "120605",
    "label": "Bandar Tongging",
    "value": "Bandar Tongging"
  },
  {
    "id": "1206052018",
    "district_id": "120605",
    "label": "Pancur Batu",
    "value": "Pancur Batu"
  },
  {
    "id": "1206052019",
    "district_id": "120605",
    "label": "Merek",
    "value": "Merek"
  },
  {
    "id": "1206062001",
    "district_id": "120606",
    "label": "Nageri",
    "value": "Nageri"
  },
  {
    "id": "1206062002",
    "district_id": "120606",
    "label": "Munte",
    "value": "Munte"
  },
  {
    "id": "1206062003",
    "district_id": "120606",
    "label": "Kineppen",
    "value": "Kineppen"
  },
  {
    "id": "1206062004",
    "district_id": "120606",
    "label": "Bandar Meriah",
    "value": "Bandar Meriah"
  },
  {
    "id": "1206062005",
    "district_id": "120606",
    "label": "Barung Kersap",
    "value": "Barung Kersap"
  },
  {
    "id": "1206062006",
    "district_id": "120606",
    "label": "Kutambaru",
    "value": "Kutambaru"
  },
  {
    "id": "1206062007",
    "district_id": "120606",
    "label": "Buluhnaman",
    "value": "Buluhnaman"
  },
  {
    "id": "1206062008",
    "district_id": "120606",
    "label": "Singgamanik",
    "value": "Singgamanik"
  },
  {
    "id": "1206062009",
    "district_id": "120606",
    "label": "Kuta Gerat",
    "value": "Kuta Gerat"
  },
  {
    "id": "1206062010",
    "district_id": "120606",
    "label": "Pertumbungen",
    "value": "Pertumbungen"
  },
  {
    "id": "1206062011",
    "district_id": "120606",
    "label": "Sarimunte",
    "value": "Sarimunte"
  },
  {
    "id": "1206062012",
    "district_id": "120606",
    "label": "Kaban Tua",
    "value": "Kaban Tua"
  },
  {
    "id": "1206062013",
    "district_id": "120606",
    "label": "Gunung Manumpak",
    "value": "Gunung Manumpak"
  },
  {
    "id": "1206062014",
    "district_id": "120606",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1206062015",
    "district_id": "120606",
    "label": "Kuta Suah",
    "value": "Kuta Suah"
  },
  {
    "id": "1206062016",
    "district_id": "120606",
    "label": "Sarinembah",
    "value": "Sarinembah"
  },
  {
    "id": "1206062017",
    "district_id": "120606",
    "label": "Selakkar",
    "value": "Selakkar"
  },
  {
    "id": "1206062018",
    "district_id": "120606",
    "label": "Parimbalang",
    "value": "Parimbalang"
  },
  {
    "id": "1206062019",
    "district_id": "120606",
    "label": "Gunung Saribu",
    "value": "Gunung Saribu"
  },
  {
    "id": "1206062020",
    "district_id": "120606",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1206062021",
    "district_id": "120606",
    "label": "Gurubenua",
    "value": "Gurubenua"
  },
  {
    "id": "1206062022",
    "district_id": "120606",
    "label": "Biaknampe",
    "value": "Biaknampe"
  },
  {
    "id": "1206072001",
    "district_id": "120607",
    "label": "Juhar Perangin-angin",
    "value": "Juhar Perangin-angin"
  },
  {
    "id": "1206072002",
    "district_id": "120607",
    "label": "Lau Lingga",
    "value": "Lau Lingga"
  },
  {
    "id": "1206072003",
    "district_id": "120607",
    "label": "Kidupen",
    "value": "Kidupen"
  },
  {
    "id": "1206072004",
    "district_id": "120607",
    "label": "Namosuro",
    "value": "Namosuro"
  },
  {
    "id": "1206072005",
    "district_id": "120607",
    "label": "Bekilang",
    "value": "Bekilang"
  },
  {
    "id": "1206072006",
    "district_id": "120607",
    "label": "Sugihen",
    "value": "Sugihen"
  },
  {
    "id": "1206072007",
    "district_id": "120607",
    "label": "Pernantin",
    "value": "Pernantin"
  },
  {
    "id": "1206072008",
    "district_id": "120607",
    "label": "Nageri",
    "value": "Nageri"
  },
  {
    "id": "1206072009",
    "district_id": "120607",
    "label": "Juhar Ginting",
    "value": "Juhar Ginting"
  },
  {
    "id": "1206072010",
    "district_id": "120607",
    "label": "Juhar Tarigan",
    "value": "Juhar Tarigan"
  },
  {
    "id": "1206072011",
    "district_id": "120607",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1206072012",
    "district_id": "120607",
    "label": "Batu Mamak",
    "value": "Batu Mamak"
  },
  {
    "id": "1206072013",
    "district_id": "120607",
    "label": "Naga",
    "value": "Naga"
  },
  {
    "id": "1206072014",
    "district_id": "120607",
    "label": "Kutambelin",
    "value": "Kutambelin"
  },
  {
    "id": "1206072015",
    "district_id": "120607",
    "label": "Mbetong",
    "value": "Mbetong"
  },
  {
    "id": "1206072016",
    "district_id": "120607",
    "label": "Keriahen",
    "value": "Keriahen"
  },
  {
    "id": "1206072017",
    "district_id": "120607",
    "label": "Gunung Juhar",
    "value": "Gunung Juhar"
  },
  {
    "id": "1206072018",
    "district_id": "120607",
    "label": "Ketawaren",
    "value": "Ketawaren"
  },
  {
    "id": "1206072019",
    "district_id": "120607",
    "label": "Jandi",
    "value": "Jandi"
  },
  {
    "id": "1206072020",
    "district_id": "120607",
    "label": "Buluh Pancur",
    "value": "Buluh Pancur"
  },
  {
    "id": "1206072021",
    "district_id": "120607",
    "label": "Kutagugung",
    "value": "Kutagugung"
  },
  {
    "id": "1206072022",
    "district_id": "120607",
    "label": "Sukababo",
    "value": "Sukababo"
  },
  {
    "id": "1206072023",
    "district_id": "120607",
    "label": "Lau Kidupen",
    "value": "Lau Kidupen"
  },
  {
    "id": "1206072024",
    "district_id": "120607",
    "label": "Sigenderang",
    "value": "Sigenderang"
  },
  {
    "id": "1206072025",
    "district_id": "120607",
    "label": "Juhar Ginting Sadanioga",
    "value": "Juhar Ginting Sadanioga"
  },
  {
    "id": "1206081019",
    "district_id": "120608",
    "label": "Tiga Binanga",
    "value": "Tiga Binanga"
  },
  {
    "id": "1206082001",
    "district_id": "120608",
    "label": "Gunung",
    "value": "Gunung"
  },
  {
    "id": "1206082002",
    "district_id": "120608",
    "label": "Kem Kem",
    "value": "Kem Kem"
  },
  {
    "id": "1206082003",
    "district_id": "120608",
    "label": "Pertumbuken",
    "value": "Pertumbuken"
  },
  {
    "id": "1206082004",
    "district_id": "120608",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "1206082005",
    "district_id": "120608",
    "label": "Limang",
    "value": "Limang"
  },
  {
    "id": "1206082006",
    "district_id": "120608",
    "label": "Perbesi",
    "value": "Perbesi"
  },
  {
    "id": "1206082007",
    "district_id": "120608",
    "label": "Lau Kapur",
    "value": "Lau Kapur"
  },
  {
    "id": "1206082008",
    "district_id": "120608",
    "label": "Kuta Bangun",
    "value": "Kuta Bangun"
  },
  {
    "id": "1206082009",
    "district_id": "120608",
    "label": "Kuta Raya",
    "value": "Kuta Raya"
  },
  {
    "id": "1206082010",
    "district_id": "120608",
    "label": "Pergendangen",
    "value": "Pergendangen"
  },
  {
    "id": "1206082011",
    "district_id": "120608",
    "label": "Simolap",
    "value": "Simolap"
  },
  {
    "id": "1206082012",
    "district_id": "120608",
    "label": "Simpang Pergendangen",
    "value": "Simpang Pergendangen"
  },
  {
    "id": "1206082013",
    "district_id": "120608",
    "label": "Kuta Gerat",
    "value": "Kuta Gerat"
  },
  {
    "id": "1206082014",
    "district_id": "120608",
    "label": "Suka Julu",
    "value": "Suka Julu"
  },
  {
    "id": "1206082015",
    "district_id": "120608",
    "label": "Kuta Galoh",
    "value": "Kuta Galoh"
  },
  {
    "id": "1206082016",
    "district_id": "120608",
    "label": "Bunga Baru",
    "value": "Bunga Baru"
  },
  {
    "id": "1206082017",
    "district_id": "120608",
    "label": "Kutambaru Punti",
    "value": "Kutambaru Punti"
  },
  {
    "id": "1206082018",
    "district_id": "120608",
    "label": "Kuta Buara",
    "value": "Kuta Buara"
  },
  {
    "id": "1206082020",
    "district_id": "120608",
    "label": "Batumamak",
    "value": "Batumamak"
  },
  {
    "id": "1206092001",
    "district_id": "120609",
    "label": "Martelu",
    "value": "Martelu"
  },
  {
    "id": "1206092002",
    "district_id": "120609",
    "label": "Mbal-Mbal Petarum",
    "value": "Mbal-Mbal Petarum"
  },
  {
    "id": "1206092003",
    "district_id": "120609",
    "label": "Lau Peranggunen",
    "value": "Lau Peranggunen"
  },
  {
    "id": "1206092004",
    "district_id": "120609",
    "label": "Tanjung Gunung",
    "value": "Tanjung Gunung"
  },
  {
    "id": "1206092005",
    "district_id": "120609",
    "label": "Lau Baleng",
    "value": "Lau Baleng"
  },
  {
    "id": "1206092006",
    "district_id": "120609",
    "label": "Durin Rugun",
    "value": "Durin Rugun"
  },
  {
    "id": "1206092007",
    "district_id": "120609",
    "label": "Perbulan",
    "value": "Perbulan"
  },
  {
    "id": "1206092008",
    "district_id": "120609",
    "label": "Kutambelin",
    "value": "Kutambelin"
  },
  {
    "id": "1206092009",
    "district_id": "120609",
    "label": "Lingga Muda",
    "value": "Lingga Muda"
  },
  {
    "id": "1206092010",
    "district_id": "120609",
    "label": "Kinangkong",
    "value": "Kinangkong"
  },
  {
    "id": "1206092011",
    "district_id": "120609",
    "label": "Rambah Tampu",
    "value": "Rambah Tampu"
  },
  {
    "id": "1206092012",
    "district_id": "120609",
    "label": "Lau Peradep",
    "value": "Lau Peradep"
  },
  {
    "id": "1206092013",
    "district_id": "120609",
    "label": "Buluh Pancur",
    "value": "Buluh Pancur"
  },
  {
    "id": "1206092014",
    "district_id": "120609",
    "label": "Batu Rongkam",
    "value": "Batu Rongkam"
  },
  {
    "id": "1206092015",
    "district_id": "120609",
    "label": "Pintu Angin",
    "value": "Pintu Angin"
  },
  {
    "id": "1206102001",
    "district_id": "120610",
    "label": "Lau Pengulu",
    "value": "Lau Pengulu"
  },
  {
    "id": "1206102002",
    "district_id": "120610",
    "label": "Lau Mulgap",
    "value": "Lau Mulgap"
  },
  {
    "id": "1206102003",
    "district_id": "120610",
    "label": "Lau Kesumpat",
    "value": "Lau Kesumpat"
  },
  {
    "id": "1206102004",
    "district_id": "120610",
    "label": "Rimo Bunga",
    "value": "Rimo Bunga"
  },
  {
    "id": "1206102005",
    "district_id": "120610",
    "label": "Tanjung Pamah",
    "value": "Tanjung Pamah"
  },
  {
    "id": "1206102006",
    "district_id": "120610",
    "label": "Kuta Pengkih",
    "value": "Kuta Pengkih"
  },
  {
    "id": "1206102007",
    "district_id": "120610",
    "label": "Bandar Purba",
    "value": "Bandar Purba"
  },
  {
    "id": "1206102008",
    "district_id": "120610",
    "label": "Mardingding",
    "value": "Mardingding"
  },
  {
    "id": "1206102009",
    "district_id": "120610",
    "label": "Lau Pakam",
    "value": "Lau Pakam"
  },
  {
    "id": "1206102010",
    "district_id": "120610",
    "label": "Lau Solu",
    "value": "Lau Solu"
  },
  {
    "id": "1206102011",
    "district_id": "120610",
    "label": "Lau Garut",
    "value": "Lau Garut"
  },
  {
    "id": "1206102012",
    "district_id": "120610",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "1206112001",
    "district_id": "120611",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "1206112004",
    "district_id": "120611",
    "label": "Rimo Kayu",
    "value": "Rimo Kayu"
  },
  {
    "id": "1206112005",
    "district_id": "120611",
    "label": "Sukameriah",
    "value": "Sukameriah"
  },
  {
    "id": "1206112007",
    "district_id": "120611",
    "label": "Selandi",
    "value": "Selandi"
  },
  {
    "id": "1206112012",
    "district_id": "120611",
    "label": "Batukarang",
    "value": "Batukarang"
  },
  {
    "id": "1206112015",
    "district_id": "120611",
    "label": "Gurukinayan",
    "value": "Gurukinayan"
  },
  {
    "id": "1206112017",
    "district_id": "120611",
    "label": "Cimbang",
    "value": "Cimbang"
  },
  {
    "id": "1206112025",
    "district_id": "120611",
    "label": "Ujung Payung",
    "value": "Ujung Payung"
  },
  {
    "id": "1206122005",
    "district_id": "120612",
    "label": "Kutatengah",
    "value": "Kutatengah"
  },
  {
    "id": "1206122006",
    "district_id": "120612",
    "label": "Tigapancur",
    "value": "Tigapancur"
  },
  {
    "id": "1206122007",
    "district_id": "120612",
    "label": "Pintu Besi",
    "value": "Pintu Besi"
  },
  {
    "id": "1206122009",
    "district_id": "120612",
    "label": "Gamber",
    "value": "Gamber"
  },
  {
    "id": "1206122012",
    "district_id": "120612",
    "label": "Sirumbia",
    "value": "Sirumbia"
  },
  {
    "id": "1206122014",
    "district_id": "120612",
    "label": "Ndokumsiroga",
    "value": "Ndokumsiroga"
  },
  {
    "id": "1206122016",
    "district_id": "120612",
    "label": "Linggajulu",
    "value": "Linggajulu"
  },
  {
    "id": "1206122017",
    "district_id": "120612",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "1206122018",
    "district_id": "120612",
    "label": "Lingga",
    "value": "Lingga"
  },
  {
    "id": "1206122019",
    "district_id": "120612",
    "label": "Nangbelawan",
    "value": "Nangbelawan"
  },
  {
    "id": "1206122022",
    "district_id": "120612",
    "label": "Beganding",
    "value": "Beganding"
  },
  {
    "id": "1206122024",
    "district_id": "120612",
    "label": "Torong",
    "value": "Torong"
  },
  {
    "id": "1206122025",
    "district_id": "120612",
    "label": "Berastepu",
    "value": "Berastepu"
  },
  {
    "id": "1206122026",
    "district_id": "120612",
    "label": "Surbakti",
    "value": "Surbakti"
  },
  {
    "id": "1206122028",
    "district_id": "120612",
    "label": "Jeraya",
    "value": "Jeraya"
  },
  {
    "id": "1206122030",
    "district_id": "120612",
    "label": "Perteguhen",
    "value": "Perteguhen"
  },
  {
    "id": "1206122035",
    "district_id": "120612",
    "label": "Bulanbaru",
    "value": "Bulanbaru"
  },
  {
    "id": "1206132001",
    "district_id": "120613",
    "label": "Negeri Jahe",
    "value": "Negeri Jahe"
  },
  {
    "id": "1206132002",
    "district_id": "120613",
    "label": "Kutabuluh Gugung",
    "value": "Kutabuluh Gugung"
  },
  {
    "id": "1206132003",
    "district_id": "120613",
    "label": "Siabang-abang",
    "value": "Siabang-abang"
  },
  {
    "id": "1206132004",
    "district_id": "120613",
    "label": "Lau Buluh",
    "value": "Lau Buluh"
  },
  {
    "id": "1206132005",
    "district_id": "120613",
    "label": "Bintang Meriah",
    "value": "Bintang Meriah"
  },
  {
    "id": "1206132006",
    "district_id": "120613",
    "label": "Jinabun",
    "value": "Jinabun"
  },
  {
    "id": "1206132007",
    "district_id": "120613",
    "label": "Kuta Male",
    "value": "Kuta Male"
  },
  {
    "id": "1206132008",
    "district_id": "120613",
    "label": "Ujung Deleng",
    "value": "Ujung Deleng"
  },
  {
    "id": "1206132009",
    "district_id": "120613",
    "label": "Gunung Meriah",
    "value": "Gunung Meriah"
  },
  {
    "id": "1206132010",
    "district_id": "120613",
    "label": "Buah Raya",
    "value": "Buah Raya"
  },
  {
    "id": "1206132011",
    "district_id": "120613",
    "label": "Tanjung Merahe",
    "value": "Tanjung Merahe"
  },
  {
    "id": "1206132012",
    "district_id": "120613",
    "label": "Rih Tengah",
    "value": "Rih Tengah"
  },
  {
    "id": "1206132013",
    "district_id": "120613",
    "label": "Kutabuluh",
    "value": "Kutabuluh"
  },
  {
    "id": "1206132014",
    "district_id": "120613",
    "label": "Liang Merdeka",
    "value": "Liang Merdeka"
  },
  {
    "id": "1206132015",
    "district_id": "120613",
    "label": "Pola Tebu",
    "value": "Pola Tebu"
  },
  {
    "id": "1206132016",
    "district_id": "120613",
    "label": "Mburidi",
    "value": "Mburidi"
  },
  {
    "id": "1206142001",
    "district_id": "120614",
    "label": "Kubu Colia",
    "value": "Kubu Colia"
  },
  {
    "id": "1206142002",
    "district_id": "120614",
    "label": "Ujung Sampun",
    "value": "Ujung Sampun"
  },
  {
    "id": "1206142003",
    "district_id": "120614",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "1206142004",
    "district_id": "120614",
    "label": "Melas",
    "value": "Melas"
  },
  {
    "id": "1206142005",
    "district_id": "120614",
    "label": "Sampun",
    "value": "Sampun"
  },
  {
    "id": "1206142006",
    "district_id": "120614",
    "label": "Sugihen",
    "value": "Sugihen"
  },
  {
    "id": "1206142007",
    "district_id": "120614",
    "label": "Dolat Rayat",
    "value": "Dolat Rayat"
  },
  {
    "id": "1206152001",
    "district_id": "120615",
    "label": "Cinta Rakyat",
    "value": "Cinta Rakyat"
  },
  {
    "id": "1206152002",
    "district_id": "120615",
    "label": "Semangat",
    "value": "Semangat"
  },
  {
    "id": "1206152003",
    "district_id": "120615",
    "label": "Ujung Teran",
    "value": "Ujung Teran"
  },
  {
    "id": "1206152004",
    "district_id": "120615",
    "label": "Gongsol",
    "value": "Gongsol"
  },
  {
    "id": "1206152005",
    "district_id": "120615",
    "label": "Jaranguda",
    "value": "Jaranguda"
  },
  {
    "id": "1206152006",
    "district_id": "120615",
    "label": "Semangat Gunung",
    "value": "Semangat Gunung"
  },
  {
    "id": "1206152007",
    "district_id": "120615",
    "label": "Deram",
    "value": "Deram"
  },
  {
    "id": "1206152008",
    "district_id": "120615",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "1206152009",
    "district_id": "120615",
    "label": "Sada Perarih",
    "value": "Sada Perarih"
  },
  {
    "id": "1206162001",
    "district_id": "120616",
    "label": "Simacem",
    "value": "Simacem"
  },
  {
    "id": "1206162002",
    "district_id": "120616",
    "label": "Kutagugung",
    "value": "Kutagugung"
  },
  {
    "id": "1206162003",
    "district_id": "120616",
    "label": "Kuta Tonggal",
    "value": "Kuta Tonggal"
  },
  {
    "id": "1206162004",
    "district_id": "120616",
    "label": "Naman",
    "value": "Naman"
  },
  {
    "id": "1206162005",
    "district_id": "120616",
    "label": "Sukanalu",
    "value": "Sukanalu"
  },
  {
    "id": "1206162006",
    "district_id": "120616",
    "label": "Kebayaken",
    "value": "Kebayaken"
  },
  {
    "id": "1206162007",
    "district_id": "120616",
    "label": "Kutarayat",
    "value": "Kutarayat"
  },
  {
    "id": "1206162008",
    "district_id": "120616",
    "label": "Bekerah",
    "value": "Bekerah"
  },
  {
    "id": "1206162009",
    "district_id": "120616",
    "label": "Sukandebi",
    "value": "Sukandebi"
  },
  {
    "id": "1206162010",
    "district_id": "120616",
    "label": "Gung Pinto",
    "value": "Gung Pinto"
  },
  {
    "id": "1206162011",
    "district_id": "120616",
    "label": "Kutambelin",
    "value": "Kutambelin"
  },
  {
    "id": "1206162012",
    "district_id": "120616",
    "label": "Ndeskati",
    "value": "Ndeskati"
  },
  {
    "id": "1206162013",
    "district_id": "120616",
    "label": "Sukatepu",
    "value": "Sukatepu"
  },
  {
    "id": "1206162014",
    "district_id": "120616",
    "label": "Sigarang-garang",
    "value": "Sigarang-garang"
  },
  {
    "id": "1206172001",
    "district_id": "120617",
    "label": "Sukatendel",
    "value": "Sukatendel"
  },
  {
    "id": "1206172002",
    "district_id": "120617",
    "label": "Mardingding",
    "value": "Mardingding"
  },
  {
    "id": "1206172003",
    "district_id": "120617",
    "label": "Kutagaluh",
    "value": "Kutagaluh"
  },
  {
    "id": "1206172004",
    "district_id": "120617",
    "label": "Kutambaru",
    "value": "Kutambaru"
  },
  {
    "id": "1206172005",
    "district_id": "120617",
    "label": "Jandimeriah",
    "value": "Jandimeriah"
  },
  {
    "id": "1206172006",
    "district_id": "120617",
    "label": "Tiganderket",
    "value": "Tiganderket"
  },
  {
    "id": "1206172007",
    "district_id": "120617",
    "label": "Tanjung Pulo",
    "value": "Tanjung Pulo"
  },
  {
    "id": "1206172008",
    "district_id": "120617",
    "label": "Tanjung Merawa",
    "value": "Tanjung Merawa"
  },
  {
    "id": "1206172009",
    "district_id": "120617",
    "label": "Perbaji",
    "value": "Perbaji"
  },
  {
    "id": "1206172010",
    "district_id": "120617",
    "label": "Temburun",
    "value": "Temburun"
  },
  {
    "id": "1206172011",
    "district_id": "120617",
    "label": "Kutakepar",
    "value": "Kutakepar"
  },
  {
    "id": "1206172012",
    "district_id": "120617",
    "label": "Penampen",
    "value": "Penampen"
  },
  {
    "id": "1206172013",
    "district_id": "120617",
    "label": "Tanjung Mbelang",
    "value": "Tanjung Mbelang"
  },
  {
    "id": "1206172014",
    "district_id": "120617",
    "label": "Narigunung I",
    "value": "Narigunung I"
  },
  {
    "id": "1206172015",
    "district_id": "120617",
    "label": "Narigunung II",
    "value": "Narigunung II"
  },
  {
    "id": "1206172016",
    "district_id": "120617",
    "label": "Gunung Merlawan",
    "value": "Gunung Merlawan"
  },
  {
    "id": "1206172017",
    "district_id": "120617",
    "label": "Susuk",
    "value": "Susuk"
  },
  {
    "id": "1207012001",
    "district_id": "120701",
    "label": "Kuta Bayu",
    "value": "Kuta Bayu"
  },
  {
    "id": "1207012002",
    "district_id": "120701",
    "label": "Gunung Paribuan",
    "value": "Gunung Paribuan"
  },
  {
    "id": "1207012003",
    "district_id": "120701",
    "label": "Simempar",
    "value": "Simempar"
  },
  {
    "id": "1207012004",
    "district_id": "120701",
    "label": "Gunung Seribu",
    "value": "Gunung Seribu"
  },
  {
    "id": "1207012005",
    "district_id": "120701",
    "label": "Gunung Sinembah",
    "value": "Gunung Sinembah"
  },
  {
    "id": "1207012006",
    "district_id": "120701",
    "label": "Bintang Meriah",
    "value": "Bintang Meriah"
  },
  {
    "id": "1207012007",
    "district_id": "120701",
    "label": "Kuta Tengah",
    "value": "Kuta Tengah"
  },
  {
    "id": "1207012008",
    "district_id": "120701",
    "label": "Pekan Gunung Mariah",
    "value": "Pekan Gunung Mariah"
  },
  {
    "id": "1207012009",
    "district_id": "120701",
    "label": "Gunung Meriah",
    "value": "Gunung Meriah"
  },
  {
    "id": "1207012010",
    "district_id": "120701",
    "label": "Ujung Meriah",
    "value": "Ujung Meriah"
  },
  {
    "id": "1207012011",
    "district_id": "120701",
    "label": "Marjandi Pematang",
    "value": "Marjandi Pematang"
  },
  {
    "id": "1207012012",
    "district_id": "120701",
    "label": "Marjandi Tongah",
    "value": "Marjandi Tongah"
  },
  {
    "id": "1207021026",
    "district_id": "120702",
    "label": "Pekan Tg Morawa",
    "value": "Pekan Tg Morawa"
  },
  {
    "id": "1207022001",
    "district_id": "120702",
    "label": "Medan Sinembah",
    "value": "Medan Sinembah"
  },
  {
    "id": "1207022002",
    "district_id": "120702",
    "label": "Ujung Serdang",
    "value": "Ujung Serdang"
  },
  {
    "id": "1207022003",
    "district_id": "120702",
    "label": "Limau Manis",
    "value": "Limau Manis"
  },
  {
    "id": "1207022004",
    "district_id": "120702",
    "label": "Bandar Labuhan",
    "value": "Bandar Labuhan"
  },
  {
    "id": "1207022005",
    "district_id": "120702",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1207022006",
    "district_id": "120702",
    "label": "Aek Pancur",
    "value": "Aek Pancur"
  },
  {
    "id": "1207022007",
    "district_id": "120702",
    "label": "Sei Merah",
    "value": "Sei Merah"
  },
  {
    "id": "1207022008",
    "district_id": "120702",
    "label": "Naga Timbul",
    "value": "Naga Timbul"
  },
  {
    "id": "1207022009",
    "district_id": "120702",
    "label": "Langau Seprang",
    "value": "Langau Seprang"
  },
  {
    "id": "1207022010",
    "district_id": "120702",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1207022011",
    "district_id": "120702",
    "label": "Punden Rejo",
    "value": "Punden Rejo"
  },
  {
    "id": "1207022012",
    "district_id": "120702",
    "label": "Tanjung Morawa B",
    "value": "Tanjung Morawa B"
  },
  {
    "id": "1207022013",
    "district_id": "120702",
    "label": "Dagang Kerawan",
    "value": "Dagang Kerawan"
  },
  {
    "id": "1207022014",
    "district_id": "120702",
    "label": "Tanjung Morawa A",
    "value": "Tanjung Morawa A"
  },
  {
    "id": "1207022015",
    "district_id": "120702",
    "label": "Buntu Badimbar",
    "value": "Buntu Badimbar"
  },
  {
    "id": "1207022016",
    "district_id": "120702",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1207022017",
    "district_id": "120702",
    "label": "Dagang Kelambir",
    "value": "Dagang Kelambir"
  },
  {
    "id": "1207022018",
    "district_id": "120702",
    "label": "Dalu Sepuluh-A",
    "value": "Dalu Sepuluh-A"
  },
  {
    "id": "1207022019",
    "district_id": "120702",
    "label": "Dalu Sepuluh-B",
    "value": "Dalu Sepuluh-B"
  },
  {
    "id": "1207022020",
    "district_id": "120702",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1207022021",
    "district_id": "120702",
    "label": "Perdamean",
    "value": "Perdamean"
  },
  {
    "id": "1207022022",
    "district_id": "120702",
    "label": "Penara Kebun",
    "value": "Penara Kebun"
  },
  {
    "id": "1207022023",
    "district_id": "120702",
    "label": "Bangun Sari Baru",
    "value": "Bangun Sari Baru"
  },
  {
    "id": "1207022024",
    "district_id": "120702",
    "label": "Telaga Sari",
    "value": "Telaga Sari"
  },
  {
    "id": "1207022025",
    "district_id": "120702",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1207032001",
    "district_id": "120703",
    "label": "Bandar Baru",
    "value": "Bandar Baru"
  },
  {
    "id": "1207032002",
    "district_id": "120703",
    "label": "Batu Layang",
    "value": "Batu Layang"
  },
  {
    "id": "1207032003",
    "district_id": "120703",
    "label": "Batu Mbelin",
    "value": "Batu Mbelin"
  },
  {
    "id": "1207032004",
    "district_id": "120703",
    "label": "Betimus Mbaru",
    "value": "Betimus Mbaru"
  },
  {
    "id": "1207032005",
    "district_id": "120703",
    "label": "Bengkurung",
    "value": "Bengkurung"
  },
  {
    "id": "1207032006",
    "district_id": "120703",
    "label": "Bingkawan",
    "value": "Bingkawan"
  },
  {
    "id": "1207032007",
    "district_id": "120703",
    "label": "Buah Nabar",
    "value": "Buah Nabar"
  },
  {
    "id": "1207032008",
    "district_id": "120703",
    "label": "Bukum",
    "value": "Bukum"
  },
  {
    "id": "1207032009",
    "district_id": "120703",
    "label": "Buluh Awar",
    "value": "Buluh Awar"
  },
  {
    "id": "1207032010",
    "district_id": "120703",
    "label": "Cinta Rakyat",
    "value": "Cinta Rakyat"
  },
  {
    "id": "1207032011",
    "district_id": "120703",
    "label": "Durin Serugun",
    "value": "Durin Serugun"
  },
  {
    "id": "1207032012",
    "district_id": "120703",
    "label": "Ketangkuhen",
    "value": "Ketangkuhen"
  },
  {
    "id": "1207032013",
    "district_id": "120703",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "1207032014",
    "district_id": "120703",
    "label": "Martelu",
    "value": "Martelu"
  },
  {
    "id": "1207032015",
    "district_id": "120703",
    "label": "Negeri Gugung",
    "value": "Negeri Gugung"
  },
  {
    "id": "1207032016",
    "district_id": "120703",
    "label": "Puangaja",
    "value": "Puangaja"
  },
  {
    "id": "1207032017",
    "district_id": "120703",
    "label": "Rumah Kinangkung SP",
    "value": "Rumah Kinangkung SP"
  },
  {
    "id": "1207032018",
    "district_id": "120703",
    "label": "Rambung Baru",
    "value": "Rambung Baru"
  },
  {
    "id": "1207032019",
    "district_id": "120703",
    "label": "Rumah Pil Pil",
    "value": "Rumah Pil Pil"
  },
  {
    "id": "1207032020",
    "district_id": "120703",
    "label": "Rumah Sumbul",
    "value": "Rumah Sumbul"
  },
  {
    "id": "1207032021",
    "district_id": "120703",
    "label": "Sala Bulan",
    "value": "Sala Bulan"
  },
  {
    "id": "1207032022",
    "district_id": "120703",
    "label": "Sayum Sabah",
    "value": "Sayum Sabah"
  },
  {
    "id": "1207032023",
    "district_id": "120703",
    "label": "Sembahe",
    "value": "Sembahe"
  },
  {
    "id": "1207032024",
    "district_id": "120703",
    "label": "Sibolangit",
    "value": "Sibolangit"
  },
  {
    "id": "1207032025",
    "district_id": "120703",
    "label": "Sikeben",
    "value": "Sikeben"
  },
  {
    "id": "1207032026",
    "district_id": "120703",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1207032027",
    "district_id": "120703",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1207032028",
    "district_id": "120703",
    "label": "Tambunen",
    "value": "Tambunen"
  },
  {
    "id": "1207032029",
    "district_id": "120703",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1207032030",
    "district_id": "120703",
    "label": "Ujung Deleng",
    "value": "Ujung Deleng"
  },
  {
    "id": "1207042001",
    "district_id": "120704",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1207042002",
    "district_id": "120704",
    "label": "Namo Mirik",
    "value": "Namo Mirik"
  },
  {
    "id": "1207042003",
    "district_id": "120704",
    "label": "Suka Dame",
    "value": "Suka Dame"
  },
  {
    "id": "1207042004",
    "district_id": "120704",
    "label": "Pasar X",
    "value": "Pasar X"
  },
  {
    "id": "1207042005",
    "district_id": "120704",
    "label": "Perpanden",
    "value": "Perpanden"
  },
  {
    "id": "1207042006",
    "district_id": "120704",
    "label": "Kutalimbaru",
    "value": "Kutalimbaru"
  },
  {
    "id": "1207042007",
    "district_id": "120704",
    "label": "Sampe Cita",
    "value": "Sampe Cita"
  },
  {
    "id": "1207042008",
    "district_id": "120704",
    "label": "Suka Rende",
    "value": "Suka Rende"
  },
  {
    "id": "1207042009",
    "district_id": "120704",
    "label": "Kwala Lau Bicik",
    "value": "Kwala Lau Bicik"
  },
  {
    "id": "1207042010",
    "district_id": "120704",
    "label": "Lau Bakeni",
    "value": "Lau Bakeni"
  },
  {
    "id": "1207042011",
    "district_id": "120704",
    "label": "Silebo Lebo",
    "value": "Silebo Lebo"
  },
  {
    "id": "1207042012",
    "district_id": "120704",
    "label": "Sawit Rejo",
    "value": "Sawit Rejo"
  },
  {
    "id": "1207042013",
    "district_id": "120704",
    "label": "Namo Rube Julu",
    "value": "Namo Rube Julu"
  },
  {
    "id": "1207042014",
    "district_id": "120704",
    "label": "Sei Mencirim",
    "value": "Sei Mencirim"
  },
  {
    "id": "1207052001",
    "district_id": "120705",
    "label": "Bintang Meriah",
    "value": "Bintang Meriah"
  },
  {
    "id": "1207052002",
    "district_id": "120705",
    "label": "Sugau",
    "value": "Sugau"
  },
  {
    "id": "1207052003",
    "district_id": "120705",
    "label": "Tiang Layar",
    "value": "Tiang Layar"
  },
  {
    "id": "1207052004",
    "district_id": "120705",
    "label": "Durin Simbelang",
    "value": "Durin Simbelang"
  },
  {
    "id": "1207052005",
    "district_id": "120705",
    "label": "Namo Riam",
    "value": "Namo Riam"
  },
  {
    "id": "1207052006",
    "district_id": "120705",
    "label": "Durin Tonggal",
    "value": "Durin Tonggal"
  },
  {
    "id": "1207052007",
    "district_id": "120705",
    "label": "Hulu",
    "value": "Hulu"
  },
  {
    "id": "1207052008",
    "district_id": "120705",
    "label": "Pertampilen",
    "value": "Pertampilen"
  },
  {
    "id": "1207052009",
    "district_id": "120705",
    "label": "Salam Tani",
    "value": "Salam Tani"
  },
  {
    "id": "1207052010",
    "district_id": "120705",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "1207052011",
    "district_id": "120705",
    "label": "Namo Simpur",
    "value": "Namo Simpur"
  },
  {
    "id": "1207052012",
    "district_id": "120705",
    "label": "Simalingkar A",
    "value": "Simalingkar A"
  },
  {
    "id": "1207052013",
    "district_id": "120705",
    "label": "Namo Bintang",
    "value": "Namo Bintang"
  },
  {
    "id": "1207052014",
    "district_id": "120705",
    "label": "Lama",
    "value": "Lama"
  },
  {
    "id": "1207052015",
    "district_id": "120705",
    "label": "Namorih",
    "value": "Namorih"
  },
  {
    "id": "1207052016",
    "district_id": "120705",
    "label": "Tuntungan I",
    "value": "Tuntungan I"
  },
  {
    "id": "1207052017",
    "district_id": "120705",
    "label": "Gunung Tinggi",
    "value": "Gunung Tinggi"
  },
  {
    "id": "1207052018",
    "district_id": "120705",
    "label": "Tuntungan II",
    "value": "Tuntungan II"
  },
  {
    "id": "1207052019",
    "district_id": "120705",
    "label": "Durin Jangak",
    "value": "Durin Jangak"
  },
  {
    "id": "1207052020",
    "district_id": "120705",
    "label": "Sembahe Baru",
    "value": "Sembahe Baru"
  },
  {
    "id": "1207052021",
    "district_id": "120705",
    "label": "Tanjung Anom",
    "value": "Tanjung Anom"
  },
  {
    "id": "1207052022",
    "district_id": "120705",
    "label": "Sei Glugur",
    "value": "Sei Glugur"
  },
  {
    "id": "1207052023",
    "district_id": "120705",
    "label": "Suka Raya",
    "value": "Suka Raya"
  },
  {
    "id": "1207052024",
    "district_id": "120705",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1207052025",
    "district_id": "120705",
    "label": "Perumnas Simalingkar",
    "value": "Perumnas Simalingkar"
  },
  {
    "id": "1207062001",
    "district_id": "120706",
    "label": "Deli Tua",
    "value": "Deli Tua"
  },
  {
    "id": "1207062002",
    "district_id": "120706",
    "label": "Jati Kesuma",
    "value": "Jati Kesuma"
  },
  {
    "id": "1207062003",
    "district_id": "120706",
    "label": "Namo Rambe",
    "value": "Namo Rambe"
  },
  {
    "id": "1207062004",
    "district_id": "120706",
    "label": "Gunung Kelawas",
    "value": "Gunung Kelawas"
  },
  {
    "id": "1207062005",
    "district_id": "120706",
    "label": "Ujung Labuhan",
    "value": "Ujung Labuhan"
  },
  {
    "id": "1207062006",
    "district_id": "120706",
    "label": "Lubang Ido",
    "value": "Lubang Ido"
  },
  {
    "id": "1207062007",
    "district_id": "120706",
    "label": "Siluelue",
    "value": "Siluelue"
  },
  {
    "id": "1207062008",
    "district_id": "120706",
    "label": "Timbang Lawan",
    "value": "Timbang Lawan"
  },
  {
    "id": "1207062009",
    "district_id": "120706",
    "label": "Batu Mbelin",
    "value": "Batu Mbelin"
  },
  {
    "id": "1207062010",
    "district_id": "120706",
    "label": "Namo Batang",
    "value": "Namo Batang"
  },
  {
    "id": "1207062011",
    "district_id": "120706",
    "label": "Tanjung Selamat",
    "value": "Tanjung Selamat"
  },
  {
    "id": "1207062012",
    "district_id": "120706",
    "label": "Bekukul",
    "value": "Bekukul"
  },
  {
    "id": "1207062013",
    "district_id": "120706",
    "label": "Namo Pakam",
    "value": "Namo Pakam"
  },
  {
    "id": "1207062014",
    "district_id": "120706",
    "label": "Sukamulia Hilir",
    "value": "Sukamulia Hilir"
  },
  {
    "id": "1207062015",
    "district_id": "120706",
    "label": "Sukamulia Hulu",
    "value": "Sukamulia Hulu"
  },
  {
    "id": "1207062016",
    "district_id": "120706",
    "label": "Rumah Mbacang",
    "value": "Rumah Mbacang"
  },
  {
    "id": "1207062017",
    "district_id": "120706",
    "label": "Cinta Rakyat",
    "value": "Cinta Rakyat"
  },
  {
    "id": "1207062018",
    "district_id": "120706",
    "label": "Batu Penjemuran",
    "value": "Batu Penjemuran"
  },
  {
    "id": "1207062019",
    "district_id": "120706",
    "label": "Kuta Tengah",
    "value": "Kuta Tengah"
  },
  {
    "id": "1207062020",
    "district_id": "120706",
    "label": "Namo Landur",
    "value": "Namo Landur"
  },
  {
    "id": "1207062021",
    "district_id": "120706",
    "label": "Gunung Berita",
    "value": "Gunung Berita"
  },
  {
    "id": "1207062022",
    "district_id": "120706",
    "label": "Tangkahan",
    "value": "Tangkahan"
  },
  {
    "id": "1207062023",
    "district_id": "120706",
    "label": "Uruk Gedang",
    "value": "Uruk Gedang"
  },
  {
    "id": "1207062024",
    "district_id": "120706",
    "label": "Rumah Keben",
    "value": "Rumah Keben"
  },
  {
    "id": "1207062025",
    "district_id": "120706",
    "label": "Namo Pinang",
    "value": "Namo Pinang"
  },
  {
    "id": "1207062026",
    "district_id": "120706",
    "label": "Sudirejo",
    "value": "Sudirejo"
  },
  {
    "id": "1207062027",
    "district_id": "120706",
    "label": "Jaba",
    "value": "Jaba"
  },
  {
    "id": "1207062028",
    "district_id": "120706",
    "label": "Kuta Tualah",
    "value": "Kuta Tualah"
  },
  {
    "id": "1207062029",
    "district_id": "120706",
    "label": "Kwala Simeme",
    "value": "Kwala Simeme"
  },
  {
    "id": "1207062030",
    "district_id": "120706",
    "label": "Batu Gemuk",
    "value": "Batu Gemuk"
  },
  {
    "id": "1207062031",
    "district_id": "120706",
    "label": "Lau Mulgab",
    "value": "Lau Mulgab"
  },
  {
    "id": "1207062032",
    "district_id": "120706",
    "label": "Batu Rejo",
    "value": "Batu Rejo"
  },
  {
    "id": "1207062033",
    "district_id": "120706",
    "label": "Salang Tungir",
    "value": "Salang Tungir"
  },
  {
    "id": "1207062034",
    "district_id": "120706",
    "label": "Namo Mbaru",
    "value": "Namo Mbaru"
  },
  {
    "id": "1207062035",
    "district_id": "120706",
    "label": "Rimo Mungkur",
    "value": "Rimo Mungkur"
  },
  {
    "id": "1207062036",
    "district_id": "120706",
    "label": "Namo Mbelin",
    "value": "Namo Mbelin"
  },
  {
    "id": "1207072001",
    "district_id": "120707",
    "label": "Aji Baho",
    "value": "Aji Baho"
  },
  {
    "id": "1207072002",
    "district_id": "120707",
    "label": "Biru-biru",
    "value": "Biru-biru"
  },
  {
    "id": "1207072003",
    "district_id": "120707",
    "label": "Candi Rejo",
    "value": "Candi Rejo"
  },
  {
    "id": "1207072004",
    "district_id": "120707",
    "label": "Kuta Mulyo",
    "value": "Kuta Mulyo"
  },
  {
    "id": "1207072005",
    "district_id": "120707",
    "label": "Kuala Dekah",
    "value": "Kuala Dekah"
  },
  {
    "id": "1207072006",
    "district_id": "120707",
    "label": "Mbaruai",
    "value": "Mbaruai"
  },
  {
    "id": "1207072007",
    "district_id": "120707",
    "label": "Mardinding Julu",
    "value": "Mardinding Julu"
  },
  {
    "id": "1207072008",
    "district_id": "120707",
    "label": "Namo Suro Baru",
    "value": "Namo Suro Baru"
  },
  {
    "id": "1207072009",
    "district_id": "120707",
    "label": "Peria-ria",
    "value": "Peria-ria"
  },
  {
    "id": "1207072010",
    "district_id": "120707",
    "label": "Penen",
    "value": "Penen"
  },
  {
    "id": "1207072011",
    "district_id": "120707",
    "label": "Rumah Gerat",
    "value": "Rumah Gerat"
  },
  {
    "id": "1207072012",
    "district_id": "120707",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1207072013",
    "district_id": "120707",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1207072014",
    "district_id": "120707",
    "label": "Selamat",
    "value": "Selamat"
  },
  {
    "id": "1207072015",
    "district_id": "120707",
    "label": "Sarilaba Jahe",
    "value": "Sarilaba Jahe"
  },
  {
    "id": "1207072016",
    "district_id": "120707",
    "label": "Tanjung Sena",
    "value": "Tanjung Sena"
  },
  {
    "id": "1207072017",
    "district_id": "120707",
    "label": "Namo Tualang",
    "value": "Namo Tualang"
  },
  {
    "id": "1207082001",
    "district_id": "120708",
    "label": "Talun Kenas",
    "value": "Talun Kenas"
  },
  {
    "id": "1207082002",
    "district_id": "120708",
    "label": "Gunung Rintih",
    "value": "Gunung Rintih"
  },
  {
    "id": "1207082003",
    "district_id": "120708",
    "label": "Sumbul",
    "value": "Sumbul"
  },
  {
    "id": "1207082004",
    "district_id": "120708",
    "label": "Tandukan Raga",
    "value": "Tandukan Raga"
  },
  {
    "id": "1207082005",
    "district_id": "120708",
    "label": "Limau Mungkur",
    "value": "Limau Mungkur"
  },
  {
    "id": "1207082006",
    "district_id": "120708",
    "label": "Negara Beringin",
    "value": "Negara Beringin"
  },
  {
    "id": "1207082007",
    "district_id": "120708",
    "label": "Lau Barus Baru",
    "value": "Lau Barus Baru"
  },
  {
    "id": "1207082008",
    "district_id": "120708",
    "label": "Juma Tombak",
    "value": "Juma Tombak"
  },
  {
    "id": "1207082009",
    "district_id": "120708",
    "label": "Siguci",
    "value": "Siguci"
  },
  {
    "id": "1207082010",
    "district_id": "120708",
    "label": "Kuta Jurung",
    "value": "Kuta Jurung"
  },
  {
    "id": "1207082011",
    "district_id": "120708",
    "label": "Tala Peta",
    "value": "Tala Peta"
  },
  {
    "id": "1207082012",
    "district_id": "120708",
    "label": "Lau Rakit",
    "value": "Lau Rakit"
  },
  {
    "id": "1207082013",
    "district_id": "120708",
    "label": "Penungkiren",
    "value": "Penungkiren"
  },
  {
    "id": "1207082014",
    "district_id": "120708",
    "label": "Rambai",
    "value": "Rambai"
  },
  {
    "id": "1207082015",
    "district_id": "120708",
    "label": "Lau Rempak",
    "value": "Lau Rempak"
  },
  {
    "id": "1207092001",
    "district_id": "120709",
    "label": "Bah Perak",
    "value": "Bah Perak"
  },
  {
    "id": "1207092002",
    "district_id": "120709",
    "label": "Bandar Kuala",
    "value": "Bandar Kuala"
  },
  {
    "id": "1207092003",
    "district_id": "120709",
    "label": "Bah Balua",
    "value": "Bah Balua"
  },
  {
    "id": "1207092005",
    "district_id": "120709",
    "label": "Bandar Gugung",
    "value": "Bandar Gugung"
  },
  {
    "id": "1207092006",
    "district_id": "120709",
    "label": "Bandar Meriah",
    "value": "Bandar Meriah"
  },
  {
    "id": "1207092007",
    "district_id": "120709",
    "label": "Batu Gingging",
    "value": "Batu Gingging"
  },
  {
    "id": "1207092008",
    "district_id": "120709",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "1207092009",
    "district_id": "120709",
    "label": "Bangun Purba Tengah",
    "value": "Bangun Purba Tengah"
  },
  {
    "id": "1207092010",
    "district_id": "120709",
    "label": "Begerpang",
    "value": "Begerpang"
  },
  {
    "id": "1207092011",
    "district_id": "120709",
    "label": "Batu Rata",
    "value": "Batu Rata"
  },
  {
    "id": "1207092012",
    "district_id": "120709",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "1207092014",
    "district_id": "120709",
    "label": "Damak Maliho",
    "value": "Damak Maliho"
  },
  {
    "id": "1207092015",
    "district_id": "120709",
    "label": "Greahan",
    "value": "Greahan"
  },
  {
    "id": "1207092017",
    "district_id": "120709",
    "label": "Marombun Ujung Jawi",
    "value": "Marombun Ujung Jawi"
  },
  {
    "id": "1207092018",
    "district_id": "120709",
    "label": "Marombun Barat",
    "value": "Marombun Barat"
  },
  {
    "id": "1207092019",
    "district_id": "120709",
    "label": "Mabar",
    "value": "Mabar"
  },
  {
    "id": "1207092020",
    "district_id": "120709",
    "label": "Perguroan",
    "value": "Perguroan"
  },
  {
    "id": "1207092023",
    "district_id": "120709",
    "label": "Rumah Deleng",
    "value": "Rumah Deleng"
  },
  {
    "id": "1207092024",
    "district_id": "120709",
    "label": "Sibaganding",
    "value": "Sibaganding"
  },
  {
    "id": "1207092025",
    "district_id": "120709",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1207092027",
    "district_id": "120709",
    "label": "Sukaluwei",
    "value": "Sukaluwei"
  },
  {
    "id": "1207092030",
    "district_id": "120709",
    "label": "Tanjung Purba",
    "value": "Tanjung Purba"
  },
  {
    "id": "1207092032",
    "district_id": "120709",
    "label": "Urung Ganjang",
    "value": "Urung Ganjang"
  },
  {
    "id": "1207092033",
    "district_id": "120709",
    "label": "Ujung Rambe",
    "value": "Ujung Rambe"
  },
  {
    "id": "1207191038",
    "district_id": "120719",
    "label": "Galang Kota",
    "value": "Galang Kota"
  },
  {
    "id": "1207192001",
    "district_id": "120719",
    "label": "Tanjung Gusti",
    "value": "Tanjung Gusti"
  },
  {
    "id": "1207192002",
    "district_id": "120719",
    "label": "Baru Titi Besi",
    "value": "Baru Titi Besi"
  },
  {
    "id": "1207192003",
    "district_id": "120719",
    "label": "Bandar Kuala",
    "value": "Bandar Kuala"
  },
  {
    "id": "1207192004",
    "district_id": "120719",
    "label": "Batu Lokong",
    "value": "Batu Lokong"
  },
  {
    "id": "1207192005",
    "district_id": "120719",
    "label": "Galang Suka",
    "value": "Galang Suka"
  },
  {
    "id": "1207192006",
    "district_id": "120719",
    "label": "Galang Barat",
    "value": "Galang Barat"
  },
  {
    "id": "1207192007",
    "district_id": "120719",
    "label": "Jaharum A",
    "value": "Jaharum A"
  },
  {
    "id": "1207192008",
    "district_id": "120719",
    "label": "Jaharum B",
    "value": "Jaharum B"
  },
  {
    "id": "1207192009",
    "district_id": "120719",
    "label": "Juhar Baru",
    "value": "Juhar Baru"
  },
  {
    "id": "1207192012",
    "district_id": "120719",
    "label": "Keramat Gajah",
    "value": "Keramat Gajah"
  },
  {
    "id": "1207192013",
    "district_id": "120719",
    "label": "Kotasan",
    "value": "Kotasan"
  },
  {
    "id": "1207192015",
    "district_id": "120719",
    "label": "Kotangan",
    "value": "Kotangan"
  },
  {
    "id": "1207192016",
    "district_id": "120719",
    "label": "Kelapa Satu",
    "value": "Kelapa Satu"
  },
  {
    "id": "1207192019",
    "district_id": "120719",
    "label": "Nogorejo",
    "value": "Nogorejo"
  },
  {
    "id": "1207192022",
    "district_id": "120719",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "1207192023",
    "district_id": "120719",
    "label": "Petumbukan",
    "value": "Petumbukan"
  },
  {
    "id": "1207192024",
    "district_id": "120719",
    "label": "Petangguhan",
    "value": "Petangguhan"
  },
  {
    "id": "1207192025",
    "district_id": "120719",
    "label": "Pisang Pala",
    "value": "Pisang Pala"
  },
  {
    "id": "1207192026",
    "district_id": "120719",
    "label": "Paya Itik",
    "value": "Paya Itik"
  },
  {
    "id": "1207192027",
    "district_id": "120719",
    "label": "Pulau Tagor Batu",
    "value": "Pulau Tagor Batu"
  },
  {
    "id": "1207192028",
    "district_id": "120719",
    "label": "Paya Kuda",
    "value": "Paya Kuda"
  },
  {
    "id": "1207192029",
    "district_id": "120719",
    "label": "Paya Sampir",
    "value": "Paya Sampir"
  },
  {
    "id": "1207192030",
    "district_id": "120719",
    "label": "Sei Putih",
    "value": "Sei Putih"
  },
  {
    "id": "1207192031",
    "district_id": "120719",
    "label": "Sei Karang",
    "value": "Sei Karang"
  },
  {
    "id": "1207192034",
    "district_id": "120719",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "1207192035",
    "district_id": "120719",
    "label": "Timbang Deli",
    "value": "Timbang Deli"
  },
  {
    "id": "1207192036",
    "district_id": "120719",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1207192037",
    "district_id": "120719",
    "label": "Tanjung Siporkis",
    "value": "Tanjung Siporkis"
  },
  {
    "id": "1207202001",
    "district_id": "120720",
    "label": "Tiga Juhar",
    "value": "Tiga Juhar"
  },
  {
    "id": "1207202002",
    "district_id": "120720",
    "label": "Tanjung Bampu",
    "value": "Tanjung Bampu"
  },
  {
    "id": "1207202003",
    "district_id": "120720",
    "label": "Rumah Rih",
    "value": "Rumah Rih"
  },
  {
    "id": "1207202004",
    "district_id": "120720",
    "label": "Liang Muda",
    "value": "Liang Muda"
  },
  {
    "id": "1207202005",
    "district_id": "120720",
    "label": "Durian IV Mbelang",
    "value": "Durian IV Mbelang"
  },
  {
    "id": "1207202006",
    "district_id": "120720",
    "label": "Tanah Gara Hulu",
    "value": "Tanah Gara Hulu"
  },
  {
    "id": "1207202007",
    "district_id": "120720",
    "label": "Gunung Manupak A",
    "value": "Gunung Manupak A"
  },
  {
    "id": "1207202008",
    "district_id": "120720",
    "label": "Bah Bah Buntu",
    "value": "Bah Bah Buntu"
  },
  {
    "id": "1207202009",
    "district_id": "120720",
    "label": "Tanjung Muda",
    "value": "Tanjung Muda"
  },
  {
    "id": "1207202010",
    "district_id": "120720",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1207202011",
    "district_id": "120720",
    "label": "Kuta Mbelin",
    "value": "Kuta Mbelin"
  },
  {
    "id": "1207202012",
    "district_id": "120720",
    "label": "Rumah Sumbul",
    "value": "Rumah Sumbul"
  },
  {
    "id": "1207202013",
    "district_id": "120720",
    "label": "Ranggit-git",
    "value": "Ranggit-git"
  },
  {
    "id": "1207202014",
    "district_id": "120720",
    "label": "Liang Pematang",
    "value": "Liang Pematang"
  },
  {
    "id": "1207202015",
    "district_id": "120720",
    "label": "Gunung Manupak B",
    "value": "Gunung Manupak B"
  },
  {
    "id": "1207202016",
    "district_id": "120720",
    "label": "Rumah Lengo",
    "value": "Rumah Lengo"
  },
  {
    "id": "1207202017",
    "district_id": "120720",
    "label": "Sipinggan",
    "value": "Sipinggan"
  },
  {
    "id": "1207202018",
    "district_id": "120720",
    "label": "Tanjung Timur",
    "value": "Tanjung Timur"
  },
  {
    "id": "1207202019",
    "district_id": "120720",
    "label": "Durian Tinggung",
    "value": "Durian Tinggung"
  },
  {
    "id": "1207202020",
    "district_id": "120720",
    "label": "Sibunga Bunga Hilir",
    "value": "Sibunga Bunga Hilir"
  },
  {
    "id": "1207212001",
    "district_id": "120721",
    "label": "Lantasan Baru",
    "value": "Lantasan Baru"
  },
  {
    "id": "1207212002",
    "district_id": "120721",
    "label": "Patumbak I",
    "value": "Patumbak I"
  },
  {
    "id": "1207212003",
    "district_id": "120721",
    "label": "Patumbak II",
    "value": "Patumbak II"
  },
  {
    "id": "1207212004",
    "district_id": "120721",
    "label": "Lantasan Lama",
    "value": "Lantasan Lama"
  },
  {
    "id": "1207212005",
    "district_id": "120721",
    "label": "Sigara-gara",
    "value": "Sigara-gara"
  },
  {
    "id": "1207212006",
    "district_id": "120721",
    "label": "Marendal I",
    "value": "Marendal I"
  },
  {
    "id": "1207212007",
    "district_id": "120721",
    "label": "Marindal II",
    "value": "Marindal II"
  },
  {
    "id": "1207212008",
    "district_id": "120721",
    "label": "Patumbak Kampung",
    "value": "Patumbak Kampung"
  },
  {
    "id": "1207221004",
    "district_id": "120722",
    "label": "Deli Tua",
    "value": "Deli Tua"
  },
  {
    "id": "1207221005",
    "district_id": "120722",
    "label": "Deli Tua Timur",
    "value": "Deli Tua Timur"
  },
  {
    "id": "1207221006",
    "district_id": "120722",
    "label": "Deli Tua Barat",
    "value": "Deli Tua Barat"
  },
  {
    "id": "1207222001",
    "district_id": "120722",
    "label": "Kedai Durian",
    "value": "Kedai Durian"
  },
  {
    "id": "1207222002",
    "district_id": "120722",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1207222003",
    "district_id": "120722",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1207232001",
    "district_id": "120723",
    "label": "Sei Semayang",
    "value": "Sei Semayang"
  },
  {
    "id": "1207232002",
    "district_id": "120723",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1207232003",
    "district_id": "120723",
    "label": "Puji Mulyo",
    "value": "Puji Mulyo"
  },
  {
    "id": "1207232004",
    "district_id": "120723",
    "label": "Paya Geli",
    "value": "Paya Geli"
  },
  {
    "id": "1207232005",
    "district_id": "120723",
    "label": "Mulio Rejo",
    "value": "Mulio Rejo"
  },
  {
    "id": "1207232006",
    "district_id": "120723",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1207232007",
    "district_id": "120723",
    "label": "Sumber Melati Diski",
    "value": "Sumber Melati Diski"
  },
  {
    "id": "1207232008",
    "district_id": "120723",
    "label": "Tanjung Gusta",
    "value": "Tanjung Gusta"
  },
  {
    "id": "1207232009",
    "district_id": "120723",
    "label": "Helvetia",
    "value": "Helvetia"
  },
  {
    "id": "1207232010",
    "district_id": "120723",
    "label": "Medan Krio",
    "value": "Medan Krio"
  },
  {
    "id": "1207232011",
    "district_id": "120723",
    "label": "Sei Mencirim",
    "value": "Sei Mencirim"
  },
  {
    "id": "1207232012",
    "district_id": "120723",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1207232013",
    "district_id": "120723",
    "label": "Sunggal Kanan",
    "value": "Sunggal Kanan"
  },
  {
    "id": "1207232014",
    "district_id": "120723",
    "label": "Serbajadi",
    "value": "Serbajadi"
  },
  {
    "id": "1207232015",
    "district_id": "120723",
    "label": "Telaga Sari",
    "value": "Telaga Sari"
  },
  {
    "id": "1207232016",
    "district_id": "120723",
    "label": "Tanjung Selamat",
    "value": "Tanjung Selamat"
  },
  {
    "id": "1207232017",
    "district_id": "120723",
    "label": "Sei Beras Sekata",
    "value": "Sei Beras Sekata"
  },
  {
    "id": "1207242001",
    "district_id": "120724",
    "label": "Hamparan Perak",
    "value": "Hamparan Perak"
  },
  {
    "id": "1207242002",
    "district_id": "120724",
    "label": "Sei Baharu",
    "value": "Sei Baharu"
  },
  {
    "id": "1207242003",
    "district_id": "120724",
    "label": "Klumpang Kebun",
    "value": "Klumpang Kebun"
  },
  {
    "id": "1207242004",
    "district_id": "120724",
    "label": "Klumpang Kampung",
    "value": "Klumpang Kampung"
  },
  {
    "id": "1207242005",
    "district_id": "120724",
    "label": "Klambir Lima Kampung",
    "value": "Klambir Lima Kampung"
  },
  {
    "id": "1207242006",
    "district_id": "120724",
    "label": "Tandem Hulu II",
    "value": "Tandem Hulu II"
  },
  {
    "id": "1207242007",
    "district_id": "120724",
    "label": "Klambir",
    "value": "Klambir"
  },
  {
    "id": "1207242008",
    "district_id": "120724",
    "label": "Selemak",
    "value": "Selemak"
  },
  {
    "id": "1207242009",
    "district_id": "120724",
    "label": "Kelambir Lima Kebun",
    "value": "Kelambir Lima Kebun"
  },
  {
    "id": "1207242010",
    "district_id": "120724",
    "label": "Sialang Muda",
    "value": "Sialang Muda"
  },
  {
    "id": "1207242011",
    "district_id": "120724",
    "label": "Paya Bakung",
    "value": "Paya Bakung"
  },
  {
    "id": "1207242012",
    "district_id": "120724",
    "label": "Tandem Hulu I",
    "value": "Tandem Hulu I"
  },
  {
    "id": "1207242013",
    "district_id": "120724",
    "label": "Tandem Hilir I",
    "value": "Tandem Hilir I"
  },
  {
    "id": "1207242014",
    "district_id": "120724",
    "label": "Tandem Hilir II",
    "value": "Tandem Hilir II"
  },
  {
    "id": "1207242015",
    "district_id": "120724",
    "label": "Kota Datar",
    "value": "Kota Datar"
  },
  {
    "id": "1207242016",
    "district_id": "120724",
    "label": "Bulu Cina",
    "value": "Bulu Cina"
  },
  {
    "id": "1207242017",
    "district_id": "120724",
    "label": "Kota Rantang",
    "value": "Kota Rantang"
  },
  {
    "id": "1207242018",
    "district_id": "120724",
    "label": "Paluh Manan",
    "value": "Paluh Manan"
  },
  {
    "id": "1207242019",
    "district_id": "120724",
    "label": "Lama",
    "value": "Lama"
  },
  {
    "id": "1207242020",
    "district_id": "120724",
    "label": "Paluh Kurau",
    "value": "Paluh Kurau"
  },
  {
    "id": "1207252001",
    "district_id": "120725",
    "label": "Helvetia",
    "value": "Helvetia"
  },
  {
    "id": "1207252002",
    "district_id": "120725",
    "label": "Manunggal",
    "value": "Manunggal"
  },
  {
    "id": "1207252003",
    "district_id": "120725",
    "label": "Pematang Johar",
    "value": "Pematang Johar"
  },
  {
    "id": "1207252004",
    "district_id": "120725",
    "label": "Karang Gading",
    "value": "Karang Gading"
  },
  {
    "id": "1207252005",
    "district_id": "120725",
    "label": "Telaga Tujuh",
    "value": "Telaga Tujuh"
  },
  {
    "id": "1207261019",
    "district_id": "120726",
    "label": "Kenangan",
    "value": "Kenangan"
  },
  {
    "id": "1207261020",
    "district_id": "120726",
    "label": "Kenangan Baru",
    "value": "Kenangan Baru"
  },
  {
    "id": "1207262001",
    "district_id": "120726",
    "label": "Pematang Lalang",
    "value": "Pematang Lalang"
  },
  {
    "id": "1207262002",
    "district_id": "120726",
    "label": "Sambirejo Timur",
    "value": "Sambirejo Timur"
  },
  {
    "id": "1207262003",
    "district_id": "120726",
    "label": "Kolam",
    "value": "Kolam"
  },
  {
    "id": "1207262004",
    "district_id": "120726",
    "label": "Percut",
    "value": "Percut"
  },
  {
    "id": "1207262005",
    "district_id": "120726",
    "label": "Laut Dendang",
    "value": "Laut Dendang"
  },
  {
    "id": "1207262006",
    "district_id": "120726",
    "label": "Tembung",
    "value": "Tembung"
  },
  {
    "id": "1207262007",
    "district_id": "120726",
    "label": "Cinta Rakyat",
    "value": "Cinta Rakyat"
  },
  {
    "id": "1207262008",
    "district_id": "120726",
    "label": "Amplas",
    "value": "Amplas"
  },
  {
    "id": "1207262009",
    "district_id": "120726",
    "label": "Saentis",
    "value": "Saentis"
  },
  {
    "id": "1207262010",
    "district_id": "120726",
    "label": "Sampali",
    "value": "Sampali"
  },
  {
    "id": "1207262011",
    "district_id": "120726",
    "label": "Bandar Khalipah",
    "value": "Bandar Khalipah"
  },
  {
    "id": "1207262012",
    "district_id": "120726",
    "label": "Bandar Klippa",
    "value": "Bandar Klippa"
  },
  {
    "id": "1207262013",
    "district_id": "120726",
    "label": "Tanjung Selamat",
    "value": "Tanjung Selamat"
  },
  {
    "id": "1207262014",
    "district_id": "120726",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1207262015",
    "district_id": "120726",
    "label": "Medan Estate",
    "value": "Medan Estate"
  },
  {
    "id": "1207262016",
    "district_id": "120726",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1207262017",
    "district_id": "120726",
    "label": "Bandar Setia",
    "value": "Bandar Setia"
  },
  {
    "id": "1207262018",
    "district_id": "120726",
    "label": "Sei Rotan",
    "value": "Sei Rotan"
  },
  {
    "id": "1207272001",
    "district_id": "120727",
    "label": "Sena",
    "value": "Sena"
  },
  {
    "id": "1207272002",
    "district_id": "120727",
    "label": "Tumpatan Nibung",
    "value": "Tumpatan Nibung"
  },
  {
    "id": "1207272003",
    "district_id": "120727",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1207272004",
    "district_id": "120727",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1207272005",
    "district_id": "120727",
    "label": "Batangkuis Pekan",
    "value": "Batangkuis Pekan"
  },
  {
    "id": "1207272006",
    "district_id": "120727",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1207272007",
    "district_id": "120727",
    "label": "Bintang Meriah",
    "value": "Bintang Meriah"
  },
  {
    "id": "1207272008",
    "district_id": "120727",
    "label": "Bakaran Batu",
    "value": "Bakaran Batu"
  },
  {
    "id": "1207272009",
    "district_id": "120727",
    "label": "Paya Gambar",
    "value": "Paya Gambar"
  },
  {
    "id": "1207272010",
    "district_id": "120727",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1207272011",
    "district_id": "120727",
    "label": "Sugiharjo",
    "value": "Sugiharjo"
  },
  {
    "id": "1207281007",
    "district_id": "120728",
    "label": "Lubuk Pakam I,II",
    "value": "Lubuk Pakam I,II"
  },
  {
    "id": "1207281008",
    "district_id": "120728",
    "label": "Lubuk Pakam III",
    "value": "Lubuk Pakam III"
  },
  {
    "id": "1207281009",
    "district_id": "120728",
    "label": "Paluh Kemiri",
    "value": "Paluh Kemiri"
  },
  {
    "id": "1207281010",
    "district_id": "120728",
    "label": "Cemara",
    "value": "Cemara"
  },
  {
    "id": "1207281011",
    "district_id": "120728",
    "label": "Syahmad",
    "value": "Syahmad"
  },
  {
    "id": "1207281012",
    "district_id": "120728",
    "label": "Petapahan",
    "value": "Petapahan"
  },
  {
    "id": "1207281013",
    "district_id": "120728",
    "label": "Lubuk Pakam Pekan",
    "value": "Lubuk Pakam Pekan"
  },
  {
    "id": "1207282001",
    "district_id": "120728",
    "label": "Sekip",
    "value": "Sekip"
  },
  {
    "id": "1207282002",
    "district_id": "120728",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1207282003",
    "district_id": "120728",
    "label": "Tanjung Garbus I",
    "value": "Tanjung Garbus I"
  },
  {
    "id": "1207282004",
    "district_id": "120728",
    "label": "Pasar Melintang",
    "value": "Pasar Melintang"
  },
  {
    "id": "1207282005",
    "district_id": "120728",
    "label": "Pagar Merbau III",
    "value": "Pagar Merbau III"
  },
  {
    "id": "1207282006",
    "district_id": "120728",
    "label": "Bakaran Batu",
    "value": "Bakaran Batu"
  },
  {
    "id": "1207312001",
    "district_id": "120731",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1207312002",
    "district_id": "120731",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1207312003",
    "district_id": "120731",
    "label": "Sidodadi Batu B",
    "value": "Sidodadi Batu B"
  },
  {
    "id": "1207312004",
    "district_id": "120731",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "1207312005",
    "district_id": "120731",
    "label": "Jati Rejo",
    "value": "Jati Rejo"
  },
  {
    "id": "1207312006",
    "district_id": "120731",
    "label": "Pagar Merbau I",
    "value": "Pagar Merbau I"
  },
  {
    "id": "1207312007",
    "district_id": "120731",
    "label": "Pagar Merbau II",
    "value": "Pagar Merbau II"
  },
  {
    "id": "1207312008",
    "district_id": "120731",
    "label": "Sidoharjo-I Pasar Miring",
    "value": "Sidoharjo-I Pasar Miring"
  },
  {
    "id": "1207312009",
    "district_id": "120731",
    "label": "Jati Baru",
    "value": "Jati Baru"
  },
  {
    "id": "1207312010",
    "district_id": "120731",
    "label": "Bandar Dolok",
    "value": "Bandar Dolok"
  },
  {
    "id": "1207312011",
    "district_id": "120731",
    "label": "Tanjung Garbus II",
    "value": "Tanjung Garbus II"
  },
  {
    "id": "1207312012",
    "district_id": "120731",
    "label": "Tanjung Garbus Kampung",
    "value": "Tanjung Garbus Kampung"
  },
  {
    "id": "1207312013",
    "district_id": "120731",
    "label": "Perbarakan",
    "value": "Perbarakan"
  },
  {
    "id": "1207312014",
    "district_id": "120731",
    "label": "Sukamandi Hilir",
    "value": "Sukamandi Hilir"
  },
  {
    "id": "1207312015",
    "district_id": "120731",
    "label": "Sukamandi Hulu",
    "value": "Sukamandi Hulu"
  },
  {
    "id": "1207312016",
    "district_id": "120731",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "1207322001",
    "district_id": "120732",
    "label": "Sei Tuan",
    "value": "Sei Tuan"
  },
  {
    "id": "1207322002",
    "district_id": "120732",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "1207322003",
    "district_id": "120732",
    "label": "Bagan Serdang",
    "value": "Bagan Serdang"
  },
  {
    "id": "1207322004",
    "district_id": "120732",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1207322005",
    "district_id": "120732",
    "label": "Regemuk",
    "value": "Regemuk"
  },
  {
    "id": "1207322006",
    "district_id": "120732",
    "label": "Kubah Sentang",
    "value": "Kubah Sentang"
  },
  {
    "id": "1207322007",
    "district_id": "120732",
    "label": "Pantai Labu Pekan",
    "value": "Pantai Labu Pekan"
  },
  {
    "id": "1207322008",
    "district_id": "120732",
    "label": "Pantai Labu Baru",
    "value": "Pantai Labu Baru"
  },
  {
    "id": "1207322009",
    "district_id": "120732",
    "label": "Ramunia 1",
    "value": "Ramunia 1"
  },
  {
    "id": "1207322010",
    "district_id": "120732",
    "label": "Ramunia 2",
    "value": "Ramunia 2"
  },
  {
    "id": "1207322011",
    "district_id": "120732",
    "label": "Perkebunan Ramunia",
    "value": "Perkebunan Ramunia"
  },
  {
    "id": "1207322012",
    "district_id": "120732",
    "label": "Denai Lama",
    "value": "Denai Lama"
  },
  {
    "id": "1207322013",
    "district_id": "120732",
    "label": "Denai Kuala",
    "value": "Denai Kuala"
  },
  {
    "id": "1207322014",
    "district_id": "120732",
    "label": "Denai Sarang Burung",
    "value": "Denai Sarang Burung"
  },
  {
    "id": "1207322015",
    "district_id": "120732",
    "label": "Binjai Bakung",
    "value": "Binjai Bakung"
  },
  {
    "id": "1207322016",
    "district_id": "120732",
    "label": "Paluh Sibaji",
    "value": "Paluh Sibaji"
  },
  {
    "id": "1207322017",
    "district_id": "120732",
    "label": "Kelambir",
    "value": "Kelambir"
  },
  {
    "id": "1207322018",
    "district_id": "120732",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1207322019",
    "district_id": "120732",
    "label": "Pematang Biara",
    "value": "Pematang Biara"
  },
  {
    "id": "1207332001",
    "district_id": "120733",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1207332002",
    "district_id": "120733",
    "label": "Sidourip",
    "value": "Sidourip"
  },
  {
    "id": "1207332003",
    "district_id": "120733",
    "label": "Aras Kabu",
    "value": "Aras Kabu"
  },
  {
    "id": "1207332004",
    "district_id": "120733",
    "label": "Pasar VI Kuala Namu",
    "value": "Pasar VI Kuala Namu"
  },
  {
    "id": "1207332005",
    "district_id": "120733",
    "label": "Eplasmen Kuala Namu",
    "value": "Eplasmen Kuala Namu"
  },
  {
    "id": "1207332006",
    "district_id": "120733",
    "label": "Pasar V Kebun Kelapa",
    "value": "Pasar V Kebun Kelapa"
  },
  {
    "id": "1207332007",
    "district_id": "120733",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1207332008",
    "district_id": "120733",
    "label": "Sidoarjo II Ramunia",
    "value": "Sidoarjo II Ramunia"
  },
  {
    "id": "1207332009",
    "district_id": "120733",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1207332010",
    "district_id": "120733",
    "label": "Sidodadi Ramunia",
    "value": "Sidodadi Ramunia"
  },
  {
    "id": "1207332011",
    "district_id": "120733",
    "label": "Tumpatan",
    "value": "Tumpatan"
  },
  {
    "id": "1208012001",
    "district_id": "120801",
    "label": "Silampuyang",
    "value": "Silampuyang"
  },
  {
    "id": "1208012002",
    "district_id": "120801",
    "label": "Marihat Baris",
    "value": "Marihat Baris"
  },
  {
    "id": "1208012003",
    "district_id": "120801",
    "label": "Dolok Marlawan",
    "value": "Dolok Marlawan"
  },
  {
    "id": "1208012004",
    "district_id": "120801",
    "label": "Rambung Merah",
    "value": "Rambung Merah"
  },
  {
    "id": "1208012005",
    "district_id": "120801",
    "label": "Silou Manik",
    "value": "Silou Manik"
  },
  {
    "id": "1208012006",
    "district_id": "120801",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "1208012007",
    "district_id": "120801",
    "label": "Siantar Estate",
    "value": "Siantar Estate"
  },
  {
    "id": "1208012008",
    "district_id": "120801",
    "label": "Karang Bangun",
    "value": "Karang Bangun"
  },
  {
    "id": "1208012009",
    "district_id": "120801",
    "label": "Nusa Harapan",
    "value": "Nusa Harapan"
  },
  {
    "id": "1208012010",
    "district_id": "120801",
    "label": "Sitalasari",
    "value": "Sitalasari"
  },
  {
    "id": "1208012011",
    "district_id": "120801",
    "label": "Lestari Indah",
    "value": "Lestari Indah"
  },
  {
    "id": "1208012012",
    "district_id": "120801",
    "label": "Laras Dua",
    "value": "Laras Dua"
  },
  {
    "id": "1208012013",
    "district_id": "120801",
    "label": "Dolok Hataran",
    "value": "Dolok Hataran"
  },
  {
    "id": "1208012014",
    "district_id": "120801",
    "label": "Pantoan Maju",
    "value": "Pantoan Maju"
  },
  {
    "id": "1208012015",
    "district_id": "120801",
    "label": "Pematang Simalungun",
    "value": "Pematang Simalungun"
  },
  {
    "id": "1208012016",
    "district_id": "120801",
    "label": "Silau Malaha",
    "value": "Silau Malaha"
  },
  {
    "id": "1208012017",
    "district_id": "120801",
    "label": "Pematang Silampuyang",
    "value": "Pematang Silampuyang"
  },
  {
    "id": "1208022001",
    "district_id": "120802",
    "label": "Dolok Malela",
    "value": "Dolok Malela"
  },
  {
    "id": "1208022002",
    "district_id": "120802",
    "label": "Senio",
    "value": "Senio"
  },
  {
    "id": "1208022003",
    "district_id": "120802",
    "label": "Pematang Asilum",
    "value": "Pematang Asilum"
  },
  {
    "id": "1208022004",
    "district_id": "120802",
    "label": "Serapuh",
    "value": "Serapuh"
  },
  {
    "id": "1208022005",
    "district_id": "120802",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1208022006",
    "district_id": "120802",
    "label": "Silulu",
    "value": "Silulu"
  },
  {
    "id": "1208022007",
    "district_id": "120802",
    "label": "Silau Malela",
    "value": "Silau Malela"
  },
  {
    "id": "1208022008",
    "district_id": "120802",
    "label": "Bandar Siantar",
    "value": "Bandar Siantar"
  },
  {
    "id": "1208022009",
    "district_id": "120802",
    "label": "Pamatang Gajing",
    "value": "Pamatang Gajing"
  },
  {
    "id": "1208022010",
    "district_id": "120802",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "1208022011",
    "district_id": "120802",
    "label": "Nagori Malela",
    "value": "Nagori Malela"
  },
  {
    "id": "1208022012",
    "district_id": "120802",
    "label": "Bukit Maraja",
    "value": "Bukit Maraja"
  },
  {
    "id": "1208022013",
    "district_id": "120802",
    "label": "Pamatang Syahkuda",
    "value": "Pamatang Syahkuda"
  },
  {
    "id": "1208022014",
    "district_id": "120802",
    "label": "Marihat Bukit",
    "value": "Marihat Bukit"
  },
  {
    "id": "1208022015",
    "district_id": "120802",
    "label": "Syahkuda Bayu",
    "value": "Syahkuda Bayu"
  },
  {
    "id": "1208022016",
    "district_id": "120802",
    "label": "Lingga",
    "value": "Lingga"
  },
  {
    "id": "1208032001",
    "district_id": "120803",
    "label": "Silau Bayu",
    "value": "Silau Bayu"
  },
  {
    "id": "1208032002",
    "district_id": "120803",
    "label": "Karang Anyer",
    "value": "Karang Anyer"
  },
  {
    "id": "1208032003",
    "district_id": "120803",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1208032004",
    "district_id": "120803",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1208032005",
    "district_id": "120803",
    "label": "Tumorang",
    "value": "Tumorang"
  },
  {
    "id": "1208032006",
    "district_id": "120803",
    "label": "Rabuhit",
    "value": "Rabuhit"
  },
  {
    "id": "1208032007",
    "district_id": "120803",
    "label": "Hutadipar",
    "value": "Hutadipar"
  },
  {
    "id": "1208032008",
    "district_id": "120803",
    "label": "Ganjing",
    "value": "Ganjing"
  },
  {
    "id": "1208032009",
    "district_id": "120803",
    "label": "Bandar Malela",
    "value": "Bandar Malela"
  },
  {
    "id": "1208041011",
    "district_id": "120804",
    "label": "Panei Tongah",
    "value": "Panei Tongah"
  },
  {
    "id": "1208042001",
    "district_id": "120804",
    "label": "Janggir Leto",
    "value": "Janggir Leto"
  },
  {
    "id": "1208042002",
    "district_id": "120804",
    "label": "Simpang Pane Raya",
    "value": "Simpang Pane Raya"
  },
  {
    "id": "1208042003",
    "district_id": "120804",
    "label": "Siborna",
    "value": "Siborna"
  },
  {
    "id": "1208042004",
    "district_id": "120804",
    "label": "Sipoldas",
    "value": "Sipoldas"
  },
  {
    "id": "1208042005",
    "district_id": "120804",
    "label": "Bangun Rakyat",
    "value": "Bangun Rakyat"
  },
  {
    "id": "1208042006",
    "district_id": "120804",
    "label": "Sigodang",
    "value": "Sigodang"
  },
  {
    "id": "1208042007",
    "district_id": "120804",
    "label": "Sigodang Barat",
    "value": "Sigodang Barat"
  },
  {
    "id": "1208042008",
    "district_id": "120804",
    "label": "Bah Bolon Tonga",
    "value": "Bah Bolon Tonga"
  },
  {
    "id": "1208042009",
    "district_id": "120804",
    "label": "Mekar Sari Raya",
    "value": "Mekar Sari Raya"
  },
  {
    "id": "1208042010",
    "district_id": "120804",
    "label": "Simantin Pane Dame",
    "value": "Simantin Pane Dame"
  },
  {
    "id": "1208042012",
    "district_id": "120804",
    "label": "Simpang Raya Dasma",
    "value": "Simpang Raya Dasma"
  },
  {
    "id": "1208042013",
    "district_id": "120804",
    "label": "Bangun Das Mariah",
    "value": "Bangun Das Mariah"
  },
  {
    "id": "1208042014",
    "district_id": "120804",
    "label": "Bangun Sitolu Bah",
    "value": "Bangun Sitolu Bah"
  },
  {
    "id": "1208042015",
    "district_id": "120804",
    "label": "Bah Liran Siborna",
    "value": "Bah Liran Siborna"
  },
  {
    "id": "1208042016",
    "district_id": "120804",
    "label": "Nauli Baru",
    "value": "Nauli Baru"
  },
  {
    "id": "1208042017",
    "district_id": "120804",
    "label": "Rawang Pardomuan Nauli",
    "value": "Rawang Pardomuan Nauli"
  },
  {
    "id": "1208052001",
    "district_id": "120805",
    "label": "Panombeian",
    "value": "Panombeian"
  },
  {
    "id": "1208052002",
    "district_id": "120805",
    "label": "Pematang Panombean",
    "value": "Pematang Panombean"
  },
  {
    "id": "1208052003",
    "district_id": "120805",
    "label": "Nagori Bosar",
    "value": "Nagori Bosar"
  },
  {
    "id": "1208052004",
    "district_id": "120805",
    "label": "Marjandi",
    "value": "Marjandi"
  },
  {
    "id": "1208052005",
    "district_id": "120805",
    "label": "Simpang Panei",
    "value": "Simpang Panei"
  },
  {
    "id": "1208052006",
    "district_id": "120805",
    "label": "Talun Kondot",
    "value": "Talun Kondot"
  },
  {
    "id": "1208052007",
    "district_id": "120805",
    "label": "Simbolon Tengkoh",
    "value": "Simbolon Tengkoh"
  },
  {
    "id": "1208052008",
    "district_id": "120805",
    "label": "Pematang Pane",
    "value": "Pematang Pane"
  },
  {
    "id": "1208052009",
    "district_id": "120805",
    "label": "Marjandi Pisang",
    "value": "Marjandi Pisang"
  },
  {
    "id": "1208052010",
    "district_id": "120805",
    "label": "Banuh Raya",
    "value": "Banuh Raya"
  },
  {
    "id": "1208052011",
    "district_id": "120805",
    "label": "Rukun Mulyo",
    "value": "Rukun Mulyo"
  },
  {
    "id": "1208061008",
    "district_id": "120806",
    "label": "Tiga Balata",
    "value": "Tiga Balata"
  },
  {
    "id": "1208062001",
    "district_id": "120806",
    "label": "Kasindir",
    "value": "Kasindir"
  },
  {
    "id": "1208062002",
    "district_id": "120806",
    "label": "Sibunga Bunga",
    "value": "Sibunga Bunga"
  },
  {
    "id": "1208062003",
    "district_id": "120806",
    "label": "Jorlang Hataran",
    "value": "Jorlang Hataran"
  },
  {
    "id": "1208062004",
    "district_id": "120806",
    "label": "Bah Sampuran",
    "value": "Bah Sampuran"
  },
  {
    "id": "1208062005",
    "district_id": "120806",
    "label": "Bah Birong Ulu",
    "value": "Bah Birong Ulu"
  },
  {
    "id": "1208062006",
    "district_id": "120806",
    "label": "Pinang Ratus",
    "value": "Pinang Ratus"
  },
  {
    "id": "1208062007",
    "district_id": "120806",
    "label": "Dolok Marlawan",
    "value": "Dolok Marlawan"
  },
  {
    "id": "1208062009",
    "district_id": "120806",
    "label": "Panombean Hutaurung",
    "value": "Panombean Hutaurung"
  },
  {
    "id": "1208062010",
    "district_id": "120806",
    "label": "Dipar Hataran",
    "value": "Dipar Hataran"
  },
  {
    "id": "1208062011",
    "district_id": "120806",
    "label": "Pagar Pinang",
    "value": "Pagar Pinang"
  },
  {
    "id": "1208062012",
    "district_id": "120806",
    "label": "Dolok Parriasan",
    "value": "Dolok Parriasan"
  },
  {
    "id": "1208062013",
    "district_id": "120806",
    "label": "Parmonangan",
    "value": "Parmonangan"
  },
  {
    "id": "1208071008",
    "district_id": "120807",
    "label": "Sindar Raya",
    "value": "Sindar Raya"
  },
  {
    "id": "1208072001",
    "district_id": "120807",
    "label": "Sambosar Raya",
    "value": "Sambosar Raya"
  },
  {
    "id": "1208072002",
    "district_id": "120807",
    "label": "Panduman",
    "value": "Panduman"
  },
  {
    "id": "1208072003",
    "district_id": "120807",
    "label": "Ambarokan Pane Raya",
    "value": "Ambarokan Pane Raya"
  },
  {
    "id": "1208072004",
    "district_id": "120807",
    "label": "Bah Tonang",
    "value": "Bah Tonang"
  },
  {
    "id": "1208072005",
    "district_id": "120807",
    "label": "Bah Bulian",
    "value": "Bah Bulian"
  },
  {
    "id": "1208072006",
    "district_id": "120807",
    "label": "Bangun Raya",
    "value": "Bangun Raya"
  },
  {
    "id": "1208072007",
    "district_id": "120807",
    "label": "Durian Banggal",
    "value": "Durian Banggal"
  },
  {
    "id": "1208072008",
    "district_id": "120807",
    "label": "Sorba Dolog",
    "value": "Sorba Dolog"
  },
  {
    "id": "1208072009",
    "district_id": "120807",
    "label": "Gunung Datas",
    "value": "Gunung Datas"
  },
  {
    "id": "1208072010",
    "district_id": "120807",
    "label": "Puli Buah",
    "value": "Puli Buah"
  },
  {
    "id": "1208072011",
    "district_id": "120807",
    "label": "Banu Raya",
    "value": "Banu Raya"
  },
  {
    "id": "1208072012",
    "district_id": "120807",
    "label": "Marubun Siboras",
    "value": "Marubun Siboras"
  },
  {
    "id": "1208072013",
    "district_id": "120807",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "1208081013",
    "district_id": "120808",
    "label": "Bosar Maligas",
    "value": "Bosar Maligas"
  },
  {
    "id": "1208082001",
    "district_id": "120808",
    "label": "Parbutaran",
    "value": "Parbutaran"
  },
  {
    "id": "1208082002",
    "district_id": "120808",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "1208082003",
    "district_id": "120808",
    "label": "Boluk",
    "value": "Boluk"
  },
  {
    "id": "1208082004",
    "district_id": "120808",
    "label": "Sei Mangkei",
    "value": "Sei Mangkei"
  },
  {
    "id": "1208082005",
    "district_id": "120808",
    "label": "Gunung Bayu",
    "value": "Gunung Bayu"
  },
  {
    "id": "1208082006",
    "district_id": "120808",
    "label": "Talun Saragih",
    "value": "Talun Saragih"
  },
  {
    "id": "1208082007",
    "district_id": "120808",
    "label": "Dusun Pengkolan",
    "value": "Dusun Pengkolan"
  },
  {
    "id": "1208082008",
    "district_id": "120808",
    "label": "Sei Torop",
    "value": "Sei Torop"
  },
  {
    "id": "1208082009",
    "district_id": "120808",
    "label": "Marihat Tanjung",
    "value": "Marihat Tanjung"
  },
  {
    "id": "1208082010",
    "district_id": "120808",
    "label": "Marihat Butar",
    "value": "Marihat Butar"
  },
  {
    "id": "1208082011",
    "district_id": "120808",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1208082012",
    "district_id": "120808",
    "label": "Adil Makmur",
    "value": "Adil Makmur"
  },
  {
    "id": "1208082014",
    "district_id": "120808",
    "label": "Tempel Jaya",
    "value": "Tempel Jaya"
  },
  {
    "id": "1208082015",
    "district_id": "120808",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1208082016",
    "district_id": "120808",
    "label": "Mekar Rejo",
    "value": "Mekar Rejo"
  },
  {
    "id": "1208082017",
    "district_id": "120808",
    "label": "Nanggar Bayu",
    "value": "Nanggar Bayu"
  },
  {
    "id": "1208091013",
    "district_id": "120809",
    "label": "Sarimatondang",
    "value": "Sarimatondang"
  },
  {
    "id": "1208092001",
    "district_id": "120809",
    "label": "Bah Butong I",
    "value": "Bah Butong I"
  },
  {
    "id": "1208092002",
    "district_id": "120809",
    "label": "Sidamanik",
    "value": "Sidamanik"
  },
  {
    "id": "1208092003",
    "district_id": "120809",
    "label": "Ambarisan",
    "value": "Ambarisan"
  },
  {
    "id": "1208092004",
    "district_id": "120809",
    "label": "Tiga Bolon",
    "value": "Tiga Bolon"
  },
  {
    "id": "1208092005",
    "district_id": "120809",
    "label": "Bah Butong II",
    "value": "Bah Butong II"
  },
  {
    "id": "1208092006",
    "district_id": "120809",
    "label": "Bahal Gajah",
    "value": "Bahal Gajah"
  },
  {
    "id": "1208092007",
    "district_id": "120809",
    "label": "Manik Hataran",
    "value": "Manik Hataran"
  },
  {
    "id": "1208092008",
    "district_id": "120809",
    "label": "Mekar Sidamanik",
    "value": "Mekar Sidamanik"
  },
  {
    "id": "1208092009",
    "district_id": "120809",
    "label": "Manik Maraja",
    "value": "Manik Maraja"
  },
  {
    "id": "1208092010",
    "district_id": "120809",
    "label": "Bah Biak",
    "value": "Bah Biak"
  },
  {
    "id": "1208092011",
    "district_id": "120809",
    "label": "Birong Ulu Manriah",
    "value": "Birong Ulu Manriah"
  },
  {
    "id": "1208092012",
    "district_id": "120809",
    "label": "Kebun Sayur Bah Butong",
    "value": "Kebun Sayur Bah Butong"
  },
  {
    "id": "1208092014",
    "district_id": "120809",
    "label": "Manik Rambung",
    "value": "Manik Rambung"
  },
  {
    "id": "1208092015",
    "district_id": "120809",
    "label": "Bukit Rejo",
    "value": "Bukit Rejo"
  },
  {
    "id": "1208101009",
    "district_id": "120810",
    "label": "Sipolha Horison",
    "value": "Sipolha Horison"
  },
  {
    "id": "1208102001",
    "district_id": "120810",
    "label": "Sait Buttu Saribu",
    "value": "Sait Buttu Saribu"
  },
  {
    "id": "1208102002",
    "district_id": "120810",
    "label": "Pematang Sidamanik",
    "value": "Pematang Sidamanik"
  },
  {
    "id": "1208102003",
    "district_id": "120810",
    "label": "Sarimattin",
    "value": "Sarimattin"
  },
  {
    "id": "1208102004",
    "district_id": "120810",
    "label": "Bandar Manik",
    "value": "Bandar Manik"
  },
  {
    "id": "1208102005",
    "district_id": "120810",
    "label": "Jorlang Huluan",
    "value": "Jorlang Huluan"
  },
  {
    "id": "1208102006",
    "district_id": "120810",
    "label": "Sihaporas",
    "value": "Sihaporas"
  },
  {
    "id": "1208102007",
    "district_id": "120810",
    "label": "Pematang Tambun Raya",
    "value": "Pematang Tambun Raya"
  },
  {
    "id": "1208102008",
    "district_id": "120810",
    "label": "Simantin",
    "value": "Simantin"
  },
  {
    "id": "1208102010",
    "district_id": "120810",
    "label": "Gorak",
    "value": "Gorak"
  },
  {
    "id": "1208111018",
    "district_id": "120811",
    "label": "Pematang Tanah Jawa",
    "value": "Pematang Tanah Jawa"
  },
  {
    "id": "1208112001",
    "district_id": "120811",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "1208112002",
    "district_id": "120811",
    "label": "Totap Majawa",
    "value": "Totap Majawa"
  },
  {
    "id": "1208112003",
    "district_id": "120811",
    "label": "Balimbingan",
    "value": "Balimbingan"
  },
  {
    "id": "1208112004",
    "district_id": "120811",
    "label": "Baja Dolok",
    "value": "Baja Dolok"
  },
  {
    "id": "1208112005",
    "district_id": "120811",
    "label": "Bosar Galugur",
    "value": "Bosar Galugur"
  },
  {
    "id": "1208112006",
    "district_id": "120811",
    "label": "Bah Jambi II",
    "value": "Bah Jambi II"
  },
  {
    "id": "1208112007",
    "district_id": "120811",
    "label": "Bah Kisat",
    "value": "Bah Kisat"
  },
  {
    "id": "1208112008",
    "district_id": "120811",
    "label": "Panembean Marjanji",
    "value": "Panembean Marjanji"
  },
  {
    "id": "1208112009",
    "district_id": "120811",
    "label": "Muara Mulia",
    "value": "Muara Mulia"
  },
  {
    "id": "1208112010",
    "district_id": "120811",
    "label": "Pardamean Asih",
    "value": "Pardamean Asih"
  },
  {
    "id": "1208112011",
    "district_id": "120811",
    "label": "Mekar Mulia",
    "value": "Mekar Mulia"
  },
  {
    "id": "1208112012",
    "district_id": "120811",
    "label": "Maligas Tonga",
    "value": "Maligas Tonga"
  },
  {
    "id": "1208112013",
    "district_id": "120811",
    "label": "Marubun Jaya",
    "value": "Marubun Jaya"
  },
  {
    "id": "1208112014",
    "district_id": "120811",
    "label": "Pagar Jambi",
    "value": "Pagar Jambi"
  },
  {
    "id": "1208112015",
    "district_id": "120811",
    "label": "Baliju",
    "value": "Baliju"
  },
  {
    "id": "1208112016",
    "district_id": "120811",
    "label": "Bayu Bagasan",
    "value": "Bayu Bagasan"
  },
  {
    "id": "1208112017",
    "district_id": "120811",
    "label": "Bah Jambi III",
    "value": "Bah Jambi III"
  },
  {
    "id": "1208112019",
    "district_id": "120811",
    "label": "Parbalogan",
    "value": "Parbalogan"
  },
  {
    "id": "1208112020",
    "district_id": "120811",
    "label": "Marubun Bayu",
    "value": "Marubun Bayu"
  },
  {
    "id": "1208122001",
    "district_id": "120812",
    "label": "Buttu Bayu",
    "value": "Buttu Bayu"
  },
  {
    "id": "1208122002",
    "district_id": "120812",
    "label": "Bosar Nauli",
    "value": "Bosar Nauli"
  },
  {
    "id": "1208122003",
    "district_id": "120812",
    "label": "Tangga Batu",
    "value": "Tangga Batu"
  },
  {
    "id": "1208122004",
    "district_id": "120812",
    "label": "Buntu Turunan",
    "value": "Buntu Turunan"
  },
  {
    "id": "1208122005",
    "district_id": "120812",
    "label": "Saribu Asih",
    "value": "Saribu Asih"
  },
  {
    "id": "1208122006",
    "district_id": "120812",
    "label": "Jawa Tonga",
    "value": "Jawa Tonga"
  },
  {
    "id": "1208122007",
    "district_id": "120812",
    "label": "Tonduhan",
    "value": "Tonduhan"
  },
  {
    "id": "1208122008",
    "district_id": "120812",
    "label": "Parhundalian Jw.Dipar",
    "value": "Parhundalian Jw.Dipar"
  },
  {
    "id": "1208122009",
    "district_id": "120812",
    "label": "Jawa Tongah II",
    "value": "Jawa Tongah II"
  },
  {
    "id": "1208132001",
    "district_id": "120813",
    "label": "Negeri Dolok",
    "value": "Negeri Dolok"
  },
  {
    "id": "1208132002",
    "district_id": "120813",
    "label": "Dolok Parmonangan",
    "value": "Dolok Parmonangan"
  },
  {
    "id": "1208132003",
    "district_id": "120813",
    "label": "Dolok Tomuan",
    "value": "Dolok Tomuan"
  },
  {
    "id": "1208132004",
    "district_id": "120813",
    "label": "Marihat Dolok",
    "value": "Marihat Dolok"
  },
  {
    "id": "1208132005",
    "district_id": "120813",
    "label": "Tiga Dolok",
    "value": "Tiga Dolok"
  },
  {
    "id": "1208132006",
    "district_id": "120813",
    "label": "Ujung Bondar",
    "value": "Ujung Bondar"
  },
  {
    "id": "1208132007",
    "district_id": "120813",
    "label": "Marihat Raja",
    "value": "Marihat Raja"
  },
  {
    "id": "1208132008",
    "district_id": "120813",
    "label": "Bandar Dolok",
    "value": "Bandar Dolok"
  },
  {
    "id": "1208132009",
    "district_id": "120813",
    "label": "Gunung Mariah",
    "value": "Gunung Mariah"
  },
  {
    "id": "1208132010",
    "district_id": "120813",
    "label": "Siatasan",
    "value": "Siatasan"
  },
  {
    "id": "1208132011",
    "district_id": "120813",
    "label": "Marihat Marsada",
    "value": "Marihat Marsada"
  },
  {
    "id": "1208132012",
    "district_id": "120813",
    "label": "Pondok Buluh",
    "value": "Pondok Buluh"
  },
  {
    "id": "1208132013",
    "district_id": "120813",
    "label": "Marihat Pondok",
    "value": "Marihat Pondok"
  },
  {
    "id": "1208132014",
    "district_id": "120813",
    "label": "Lumban Gorat",
    "value": "Lumban Gorat"
  },
  {
    "id": "1208132015",
    "district_id": "120813",
    "label": "Palianopat",
    "value": "Palianopat"
  },
  {
    "id": "1208141008",
    "district_id": "120814",
    "label": "Tiga Runggu",
    "value": "Tiga Runggu"
  },
  {
    "id": "1208142001",
    "district_id": "120814",
    "label": "Purba Tongah",
    "value": "Purba Tongah"
  },
  {
    "id": "1208142002",
    "district_id": "120814",
    "label": "Pematang Purba",
    "value": "Pematang Purba"
  },
  {
    "id": "1208142003",
    "district_id": "120814",
    "label": "Urung Purba",
    "value": "Urung Purba"
  },
  {
    "id": "1208142004",
    "district_id": "120814",
    "label": "Purba Sipinggan",
    "value": "Purba Sipinggan"
  },
  {
    "id": "1208142005",
    "district_id": "120814",
    "label": "Hinalang",
    "value": "Hinalang"
  },
  {
    "id": "1208142006",
    "district_id": "120814",
    "label": "Purba Dolok",
    "value": "Purba Dolok"
  },
  {
    "id": "1208142007",
    "district_id": "120814",
    "label": "Tano Tinggir",
    "value": "Tano Tinggir"
  },
  {
    "id": "1208142009",
    "district_id": "120814",
    "label": "Seribu Jandi",
    "value": "Seribu Jandi"
  },
  {
    "id": "1208142010",
    "district_id": "120814",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1208142011",
    "district_id": "120814",
    "label": "Bandar Sauhur",
    "value": "Bandar Sauhur"
  },
  {
    "id": "1208142012",
    "district_id": "120814",
    "label": "Bunga Sampang",
    "value": "Bunga Sampang"
  },
  {
    "id": "1208142013",
    "district_id": "120814",
    "label": "Nagori Tongah",
    "value": "Nagori Tongah"
  },
  {
    "id": "1208142014",
    "district_id": "120814",
    "label": "Urung Pane",
    "value": "Urung Pane"
  },
  {
    "id": "1208151005",
    "district_id": "120815",
    "label": "Haranggaol",
    "value": "Haranggaol"
  },
  {
    "id": "1208152001",
    "district_id": "120815",
    "label": "Nagori Purba",
    "value": "Nagori Purba"
  },
  {
    "id": "1208152002",
    "district_id": "120815",
    "label": "Nagori Sihalpe",
    "value": "Nagori Sihalpe"
  },
  {
    "id": "1208152003",
    "district_id": "120815",
    "label": "Nagori Purba Horison",
    "value": "Nagori Purba Horison"
  },
  {
    "id": "1208152004",
    "district_id": "120815",
    "label": "Nagori Purba Pasir",
    "value": "Nagori Purba Pasir"
  },
  {
    "id": "1208161003",
    "district_id": "120816",
    "label": "Girsang",
    "value": "Girsang"
  },
  {
    "id": "1208161004",
    "district_id": "120816",
    "label": "Parapat",
    "value": "Parapat"
  },
  {
    "id": "1208161005",
    "district_id": "120816",
    "label": "Tiga Raja",
    "value": "Tiga Raja"
  },
  {
    "id": "1208162001",
    "district_id": "120816",
    "label": "Sipangan Bolon",
    "value": "Sipangan Bolon"
  },
  {
    "id": "1208162002",
    "district_id": "120816",
    "label": "Sabaganding",
    "value": "Sabaganding"
  },
  {
    "id": "1208162006",
    "district_id": "120816",
    "label": "Sipangan Bolon Mekar",
    "value": "Sipangan Bolon Mekar"
  },
  {
    "id": "1208171015",
    "district_id": "120817",
    "label": "Serbelawan",
    "value": "Serbelawan"
  },
  {
    "id": "1208171016",
    "district_id": "120817",
    "label": "Aman Sari",
    "value": "Aman Sari"
  },
  {
    "id": "1208172001",
    "district_id": "120817",
    "label": "Silenduk",
    "value": "Silenduk"
  },
  {
    "id": "1208172002",
    "district_id": "120817",
    "label": "Bah Tobu",
    "value": "Bah Tobu"
  },
  {
    "id": "1208172003",
    "district_id": "120817",
    "label": "Bandar Selamat",
    "value": "Bandar Selamat"
  },
  {
    "id": "1208172004",
    "district_id": "120817",
    "label": "Dolok Ilir I",
    "value": "Dolok Ilir I"
  },
  {
    "id": "1208172005",
    "district_id": "120817",
    "label": "Dolok Ilir II",
    "value": "Dolok Ilir II"
  },
  {
    "id": "1208172006",
    "district_id": "120817",
    "label": "Bahung Kahean",
    "value": "Bahung Kahean"
  },
  {
    "id": "1208172007",
    "district_id": "120817",
    "label": "Dolok Mainu",
    "value": "Dolok Mainu"
  },
  {
    "id": "1208172008",
    "district_id": "120817",
    "label": "Dolok Merangir I",
    "value": "Dolok Merangir I"
  },
  {
    "id": "1208172009",
    "district_id": "120817",
    "label": "Dolok Merangir II",
    "value": "Dolok Merangir II"
  },
  {
    "id": "1208172010",
    "district_id": "120817",
    "label": "Kahean",
    "value": "Kahean"
  },
  {
    "id": "1208172011",
    "district_id": "120817",
    "label": "Dolok Tenera",
    "value": "Dolok Tenera"
  },
  {
    "id": "1208172012",
    "district_id": "120817",
    "label": "Dolok Kataran",
    "value": "Dolok Kataran"
  },
  {
    "id": "1208172013",
    "district_id": "120817",
    "label": "Padang Mainu",
    "value": "Padang Mainu"
  },
  {
    "id": "1208172014",
    "district_id": "120817",
    "label": "Bahung Huluan",
    "value": "Bahung Huluan"
  },
  {
    "id": "1208181013",
    "district_id": "120818",
    "label": "Huta Bayu",
    "value": "Huta Bayu"
  },
  {
    "id": "1208182001",
    "district_id": "120818",
    "label": "Mariah Hombang",
    "value": "Mariah Hombang"
  },
  {
    "id": "1208182002",
    "district_id": "120818",
    "label": "Pulo Bayu",
    "value": "Pulo Bayu"
  },
  {
    "id": "1208182003",
    "district_id": "120818",
    "label": "Silakkidir",
    "value": "Silakkidir"
  },
  {
    "id": "1208182004",
    "district_id": "120818",
    "label": "Raja Maligas",
    "value": "Raja Maligas"
  },
  {
    "id": "1208182005",
    "district_id": "120818",
    "label": "Dolok Sinumbah",
    "value": "Dolok Sinumbah"
  },
  {
    "id": "1208182006",
    "district_id": "120818",
    "label": "Maligas Bayu",
    "value": "Maligas Bayu"
  },
  {
    "id": "1208182007",
    "district_id": "120818",
    "label": "Jawa Baru",
    "value": "Jawa Baru"
  },
  {
    "id": "1208182008",
    "district_id": "120818",
    "label": "Mancuk",
    "value": "Mancuk"
  },
  {
    "id": "1208182009",
    "district_id": "120818",
    "label": "Marihat Mayang",
    "value": "Marihat Mayang"
  },
  {
    "id": "1208182010",
    "district_id": "120818",
    "label": "Bosar Bayu",
    "value": "Bosar Bayu"
  },
  {
    "id": "1208182011",
    "district_id": "120818",
    "label": "Raja Maligas I",
    "value": "Raja Maligas I"
  },
  {
    "id": "1208182012",
    "district_id": "120818",
    "label": "Bahal Batu",
    "value": "Bahal Batu"
  },
  {
    "id": "1208182014",
    "district_id": "120818",
    "label": "Pokan Baru",
    "value": "Pokan Baru"
  },
  {
    "id": "1208182015",
    "district_id": "120818",
    "label": "Manrayap Baru",
    "value": "Manrayap Baru"
  },
  {
    "id": "1208182016",
    "district_id": "120818",
    "label": "Talang Bayu",
    "value": "Talang Bayu"
  },
  {
    "id": "1208192001",
    "district_id": "120819",
    "label": "Jawa Maraja",
    "value": "Jawa Maraja"
  },
  {
    "id": "1208192002",
    "district_id": "120819",
    "label": "Moho",
    "value": "Moho"
  },
  {
    "id": "1208192003",
    "district_id": "120819",
    "label": "Bahalat Bayu",
    "value": "Bahalat Bayu"
  },
  {
    "id": "1208192004",
    "district_id": "120819",
    "label": "Mekar Bahalat",
    "value": "Mekar Bahalat"
  },
  {
    "id": "1208192005",
    "district_id": "120819",
    "label": "Tanjung Maraja",
    "value": "Tanjung Maraja"
  },
  {
    "id": "1208192006",
    "district_id": "120819",
    "label": "Mariah Jambi",
    "value": "Mariah Jambi"
  },
  {
    "id": "1208192007",
    "district_id": "120819",
    "label": "Bah Joga",
    "value": "Bah Joga"
  },
  {
    "id": "1208192008",
    "district_id": "120819",
    "label": "Bah Jambi",
    "value": "Bah Jambi"
  },
  {
    "id": "1208202001",
    "district_id": "120820",
    "label": "Tigaras",
    "value": "Tigaras"
  },
  {
    "id": "1208202002",
    "district_id": "120820",
    "label": "Parik Sabungan",
    "value": "Parik Sabungan"
  },
  {
    "id": "1208202003",
    "district_id": "120820",
    "label": "Sibuntuon",
    "value": "Sibuntuon"
  },
  {
    "id": "1208202004",
    "district_id": "120820",
    "label": "Dolok Saribu",
    "value": "Dolok Saribu"
  },
  {
    "id": "1208202007",
    "district_id": "120820",
    "label": "Butu Bayu Pane Raja",
    "value": "Butu Bayu Pane Raja"
  },
  {
    "id": "1208202009",
    "district_id": "120820",
    "label": "Silabah Jaya",
    "value": "Silabah Jaya"
  },
  {
    "id": "1208202010",
    "district_id": "120820",
    "label": "Togu Domu Nauli",
    "value": "Togu Domu Nauli"
  },
  {
    "id": "1208202011",
    "district_id": "120820",
    "label": "Sirube-rube Gunung Purba",
    "value": "Sirube-rube Gunung Purba"
  },
  {
    "id": "1208202014",
    "district_id": "120820",
    "label": "Tanjung Saribu",
    "value": "Tanjung Saribu"
  },
  {
    "id": "1208202015",
    "district_id": "120820",
    "label": "Sihemum Baru",
    "value": "Sihemum Baru"
  },
  {
    "id": "1208202016",
    "district_id": "120820",
    "label": "Nagori Bayu",
    "value": "Nagori Bayu"
  },
  {
    "id": "1208211009",
    "district_id": "120821",
    "label": "Kerasaan I",
    "value": "Kerasaan I"
  },
  {
    "id": "1208211010",
    "district_id": "120821",
    "label": "Pematang Bandar",
    "value": "Pematang Bandar"
  },
  {
    "id": "1208212001",
    "district_id": "120821",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1208212002",
    "district_id": "120821",
    "label": "Talun Madear",
    "value": "Talun Madear"
  },
  {
    "id": "1208212003",
    "district_id": "120821",
    "label": "Mariah Bandar",
    "value": "Mariah Bandar"
  },
  {
    "id": "1208212004",
    "district_id": "120821",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "1208212005",
    "district_id": "120821",
    "label": "Pardomuan Nauli",
    "value": "Pardomuan Nauli"
  },
  {
    "id": "1208212006",
    "district_id": "120821",
    "label": "Purba Ganda",
    "value": "Purba Ganda"
  },
  {
    "id": "1208212007",
    "district_id": "120821",
    "label": "Kerasaan II",
    "value": "Kerasaan II"
  },
  {
    "id": "1208212008",
    "district_id": "120821",
    "label": "Bandar Manis",
    "value": "Bandar Manis"
  },
  {
    "id": "1208212011",
    "district_id": "120821",
    "label": "Wana Rejo",
    "value": "Wana Rejo"
  },
  {
    "id": "1208212012",
    "district_id": "120821",
    "label": "Talun Rejo",
    "value": "Talun Rejo"
  },
  {
    "id": "1208212013",
    "district_id": "120821",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1208222001",
    "district_id": "120822",
    "label": "Naga Jaya I",
    "value": "Naga Jaya I"
  },
  {
    "id": "1208222002",
    "district_id": "120822",
    "label": "Bah Gunung",
    "value": "Bah Gunung"
  },
  {
    "id": "1208222003",
    "district_id": "120822",
    "label": "Bandar Tonga",
    "value": "Bandar Tonga"
  },
  {
    "id": "1208222004",
    "district_id": "120822",
    "label": "Dolok Parmonangan",
    "value": "Dolok Parmonangan"
  },
  {
    "id": "1208222005",
    "district_id": "120822",
    "label": "Laras",
    "value": "Laras"
  },
  {
    "id": "1208222006",
    "district_id": "120822",
    "label": "Naga Jaya II",
    "value": "Naga Jaya II"
  },
  {
    "id": "1208222007",
    "district_id": "120822",
    "label": "Tanjung Hataran",
    "value": "Tanjung Hataran"
  },
  {
    "id": "1208222008",
    "district_id": "120822",
    "label": "Bandar Betsy I",
    "value": "Bandar Betsy I"
  },
  {
    "id": "1208222009",
    "district_id": "120822",
    "label": "Bandar Betsy II",
    "value": "Bandar Betsy II"
  },
  {
    "id": "1208222010",
    "district_id": "120822",
    "label": "Naga Soppa",
    "value": "Naga Soppa"
  },
  {
    "id": "1208231014",
    "district_id": "120823",
    "label": "Perdagangan I",
    "value": "Perdagangan I"
  },
  {
    "id": "1208231015",
    "district_id": "120823",
    "label": "Perdagangan III",
    "value": "Perdagangan III"
  },
  {
    "id": "1208232001",
    "district_id": "120823",
    "label": "Pematang Kerasaan",
    "value": "Pematang Kerasaan"
  },
  {
    "id": "1208232002",
    "district_id": "120823",
    "label": "Marihat Bandar",
    "value": "Marihat Bandar"
  },
  {
    "id": "1208232003",
    "district_id": "120823",
    "label": "Nagori Bandar",
    "value": "Nagori Bandar"
  },
  {
    "id": "1208232004",
    "district_id": "120823",
    "label": "Bandar Rakyat",
    "value": "Bandar Rakyat"
  },
  {
    "id": "1208232005",
    "district_id": "120823",
    "label": "Bandar Pulo",
    "value": "Bandar Pulo"
  },
  {
    "id": "1208232006",
    "district_id": "120823",
    "label": "Bah Lias",
    "value": "Bah Lias"
  },
  {
    "id": "1208232007",
    "district_id": "120823",
    "label": "Pardagangan II",
    "value": "Pardagangan II"
  },
  {
    "id": "1208232008",
    "district_id": "120823",
    "label": "Perlanaan",
    "value": "Perlanaan"
  },
  {
    "id": "1208232009",
    "district_id": "120823",
    "label": "Sugarang Bayu",
    "value": "Sugarang Bayu"
  },
  {
    "id": "1208232010",
    "district_id": "120823",
    "label": "Pem.Kerasaan Rejo",
    "value": "Pem.Kerasaan Rejo"
  },
  {
    "id": "1208232011",
    "district_id": "120823",
    "label": "Timbaan",
    "value": "Timbaan"
  },
  {
    "id": "1208232012",
    "district_id": "120823",
    "label": "Bandar Jawa",
    "value": "Bandar Jawa"
  },
  {
    "id": "1208232013",
    "district_id": "120823",
    "label": "Sidotani",
    "value": "Sidotani"
  },
  {
    "id": "1208232016",
    "district_id": "120823",
    "label": "Landbouw",
    "value": "Landbouw"
  },
  {
    "id": "1208242001",
    "district_id": "120824",
    "label": "Bandar Masilam",
    "value": "Bandar Masilam"
  },
  {
    "id": "1208242002",
    "district_id": "120824",
    "label": "Lias Baru",
    "value": "Lias Baru"
  },
  {
    "id": "1208242003",
    "district_id": "120824",
    "label": "Bandar Silou",
    "value": "Bandar Silou"
  },
  {
    "id": "1208242004",
    "district_id": "120824",
    "label": "Panombean Baru",
    "value": "Panombean Baru"
  },
  {
    "id": "1208242005",
    "district_id": "120824",
    "label": "Gunung Serawan",
    "value": "Gunung Serawan"
  },
  {
    "id": "1208242006",
    "district_id": "120824",
    "label": "Bandar Masilam II",
    "value": "Bandar Masilam II"
  },
  {
    "id": "1208242007",
    "district_id": "120824",
    "label": "Patimbalan",
    "value": "Patimbalan"
  },
  {
    "id": "1208242008",
    "district_id": "120824",
    "label": "Bandar Tinggi",
    "value": "Bandar Tinggi"
  },
  {
    "id": "1208242009",
    "district_id": "120824",
    "label": "Bandar Rejo",
    "value": "Bandar Rejo"
  },
  {
    "id": "1208242010",
    "district_id": "120824",
    "label": "Bandar Gunung",
    "value": "Bandar Gunung"
  },
  {
    "id": "1208251012",
    "district_id": "120825",
    "label": "Seribu Dolok",
    "value": "Seribu Dolok"
  },
  {
    "id": "1208252003",
    "district_id": "120825",
    "label": "Bangun Mariah",
    "value": "Bangun Mariah"
  },
  {
    "id": "1208252004",
    "district_id": "120825",
    "label": "Purba Sinombah",
    "value": "Purba Sinombah"
  },
  {
    "id": "1208252006",
    "district_id": "120825",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1208252009",
    "district_id": "120825",
    "label": "Purba Tua Baru",
    "value": "Purba Tua Baru"
  },
  {
    "id": "1208252014",
    "district_id": "120825",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "1208252016",
    "district_id": "120825",
    "label": "Purbatua Etek",
    "value": "Purbatua Etek"
  },
  {
    "id": "1208262001",
    "district_id": "120826",
    "label": "Cingkes",
    "value": "Cingkes"
  },
  {
    "id": "1208262002",
    "district_id": "120826",
    "label": "Paribuan",
    "value": "Paribuan"
  },
  {
    "id": "1208262003",
    "district_id": "120826",
    "label": "Saran Padang",
    "value": "Saran Padang"
  },
  {
    "id": "1208262004",
    "district_id": "120826",
    "label": "Mariah Dolok",
    "value": "Mariah Dolok"
  },
  {
    "id": "1208262005",
    "district_id": "120826",
    "label": "Marubun Lokkung",
    "value": "Marubun Lokkung"
  },
  {
    "id": "1208262006",
    "district_id": "120826",
    "label": "Dolok Mariah",
    "value": "Dolok Mariah"
  },
  {
    "id": "1208262007",
    "district_id": "120826",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "1208262008",
    "district_id": "120826",
    "label": "Huta Saing",
    "value": "Huta Saing"
  },
  {
    "id": "1208262009",
    "district_id": "120826",
    "label": "Togur",
    "value": "Togur"
  },
  {
    "id": "1208262010",
    "district_id": "120826",
    "label": "Perasmian",
    "value": "Perasmian"
  },
  {
    "id": "1208262011",
    "district_id": "120826",
    "label": "Tanjung Purba",
    "value": "Tanjung Purba"
  },
  {
    "id": "1208262012",
    "district_id": "120826",
    "label": "Silau Marawan",
    "value": "Silau Marawan"
  },
  {
    "id": "1208262013",
    "district_id": "120826",
    "label": "Bosi Sinumbah",
    "value": "Bosi Sinumbah"
  },
  {
    "id": "1208262014",
    "district_id": "120826",
    "label": "Ujung Bawang",
    "value": "Ujung Bawang"
  },
  {
    "id": "1208272001",
    "district_id": "120827",
    "label": "Simanabun",
    "value": "Simanabun"
  },
  {
    "id": "1208272002",
    "district_id": "120827",
    "label": "Pardomuan Tongah",
    "value": "Pardomuan Tongah"
  },
  {
    "id": "1208272003",
    "district_id": "120827",
    "label": "Negeri Dolok",
    "value": "Negeri Dolok"
  },
  {
    "id": "1208272004",
    "district_id": "120827",
    "label": "Bandar Maruhur",
    "value": "Bandar Maruhur"
  },
  {
    "id": "1208272005",
    "district_id": "120827",
    "label": "Silou Dunia",
    "value": "Silou Dunia"
  },
  {
    "id": "1208272006",
    "district_id": "120827",
    "label": "Dolok Marawa",
    "value": "Dolok Marawa"
  },
  {
    "id": "1208272007",
    "district_id": "120827",
    "label": "Maria Buttu",
    "value": "Maria Buttu"
  },
  {
    "id": "1208272008",
    "district_id": "120827",
    "label": "Dolok Saribu Bangun",
    "value": "Dolok Saribu Bangun"
  },
  {
    "id": "1208272009",
    "district_id": "120827",
    "label": "Bandar Nagori",
    "value": "Bandar Nagori"
  },
  {
    "id": "1208272010",
    "district_id": "120827",
    "label": "Silou Paribuan",
    "value": "Silou Paribuan"
  },
  {
    "id": "1208272011",
    "district_id": "120827",
    "label": "Buttu Bayu",
    "value": "Buttu Bayu"
  },
  {
    "id": "1208272012",
    "district_id": "120827",
    "label": "Nagori Tani",
    "value": "Nagori Tani"
  },
  {
    "id": "1208272013",
    "district_id": "120827",
    "label": "Bah Sarimah",
    "value": "Bah Sarimah"
  },
  {
    "id": "1208272014",
    "district_id": "120827",
    "label": "Damaritang",
    "value": "Damaritang"
  },
  {
    "id": "1208272015",
    "district_id": "120827",
    "label": "Sinasih",
    "value": "Sinasih"
  },
  {
    "id": "1208272016",
    "district_id": "120827",
    "label": "Pardomuan Bandar",
    "value": "Pardomuan Bandar"
  },
  {
    "id": "1208281009",
    "district_id": "120828",
    "label": "Sinaksak",
    "value": "Sinaksak"
  },
  {
    "id": "1208282001",
    "district_id": "120828",
    "label": "Batu Silangit",
    "value": "Batu Silangit"
  },
  {
    "id": "1208282002",
    "district_id": "120828",
    "label": "Dolok Maraja",
    "value": "Dolok Maraja"
  },
  {
    "id": "1208282003",
    "district_id": "120828",
    "label": "Dolok Ulu",
    "value": "Dolok Ulu"
  },
  {
    "id": "1208282004",
    "district_id": "120828",
    "label": "Nagur Usang",
    "value": "Nagur Usang"
  },
  {
    "id": "1208282005",
    "district_id": "120828",
    "label": "Dolok Kahean",
    "value": "Dolok Kahean"
  },
  {
    "id": "1208282006",
    "district_id": "120828",
    "label": "Naga Dolok",
    "value": "Naga Dolok"
  },
  {
    "id": "1208282007",
    "district_id": "120828",
    "label": "Purbasari",
    "value": "Purbasari"
  },
  {
    "id": "1208282008",
    "district_id": "120828",
    "label": "Pematang Dolok Kahean",
    "value": "Pematang Dolok Kahean"
  },
  {
    "id": "1208282010",
    "district_id": "120828",
    "label": "Negri Bayu Muslimin",
    "value": "Negri Bayu Muslimin"
  },
  {
    "id": "1208282011",
    "district_id": "120828",
    "label": "Dolok Simbolon",
    "value": "Dolok Simbolon"
  },
  {
    "id": "1208291001",
    "district_id": "120829",
    "label": "Merek Raya",
    "value": "Merek Raya"
  },
  {
    "id": "1208291002",
    "district_id": "120829",
    "label": "Dalig Raya",
    "value": "Dalig Raya"
  },
  {
    "id": "1208291005",
    "district_id": "120829",
    "label": "Sondi Raya",
    "value": "Sondi Raya"
  },
  {
    "id": "1208291011",
    "district_id": "120829",
    "label": "Pematang Raya",
    "value": "Pematang Raya"
  },
  {
    "id": "1208291018",
    "district_id": "120829",
    "label": "Baringin Raya",
    "value": "Baringin Raya"
  },
  {
    "id": "1208292004",
    "district_id": "120829",
    "label": "Raya Bayu",
    "value": "Raya Bayu"
  },
  {
    "id": "1208292007",
    "district_id": "120829",
    "label": "Bahapal Raya",
    "value": "Bahapal Raya"
  },
  {
    "id": "1208292010",
    "district_id": "120829",
    "label": "Siporkas",
    "value": "Siporkas"
  },
  {
    "id": "1208292012",
    "district_id": "120829",
    "label": "Sihubu Raya",
    "value": "Sihubu Raya"
  },
  {
    "id": "1208292013",
    "district_id": "120829",
    "label": "Raya Bosi",
    "value": "Raya Bosi"
  },
  {
    "id": "1208292014",
    "district_id": "120829",
    "label": "Silou Huluan",
    "value": "Silou Huluan"
  },
  {
    "id": "1208292015",
    "district_id": "120829",
    "label": "Silou Buttu",
    "value": "Silou Buttu"
  },
  {
    "id": "1208292016",
    "district_id": "120829",
    "label": "Bongguran Kariahan",
    "value": "Bongguran Kariahan"
  },
  {
    "id": "1208292017",
    "district_id": "120829",
    "label": "Simbou Baru",
    "value": "Simbou Baru"
  },
  {
    "id": "1208292020",
    "district_id": "120829",
    "label": "Dame Raya",
    "value": "Dame Raya"
  },
  {
    "id": "1208292021",
    "district_id": "120829",
    "label": "Limag Raya",
    "value": "Limag Raya"
  },
  {
    "id": "1208292022",
    "district_id": "120829",
    "label": "Lokkung Raya",
    "value": "Lokkung Raya"
  },
  {
    "id": "1208301001",
    "district_id": "120830",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1208302002",
    "district_id": "120830",
    "label": "Huta Parik",
    "value": "Huta Parik"
  },
  {
    "id": "1208302003",
    "district_id": "120830",
    "label": "Dusun Ulu",
    "value": "Dusun Ulu"
  },
  {
    "id": "1208302004",
    "district_id": "120830",
    "label": "Tinjoan",
    "value": "Tinjoan"
  },
  {
    "id": "1208302005",
    "district_id": "120830",
    "label": "Sayur Matinggi",
    "value": "Sayur Matinggi"
  },
  {
    "id": "1208302006",
    "district_id": "120830",
    "label": "Taratak Nagodang",
    "value": "Taratak Nagodang"
  },
  {
    "id": "1208302007",
    "district_id": "120830",
    "label": "Sordang Bolon",
    "value": "Sordang Bolon"
  },
  {
    "id": "1208302008",
    "district_id": "120830",
    "label": "Teluk Lapian",
    "value": "Teluk Lapian"
  },
  {
    "id": "1208302009",
    "district_id": "120830",
    "label": "Tanjung Rapuan",
    "value": "Tanjung Rapuan"
  },
  {
    "id": "1208302010",
    "district_id": "120830",
    "label": "Pagar Bosi",
    "value": "Pagar Bosi"
  },
  {
    "id": "1208302011",
    "district_id": "120830",
    "label": "Bangun Sordang",
    "value": "Bangun Sordang"
  },
  {
    "id": "1208302012",
    "district_id": "120830",
    "label": "Aek Gerger Sidodadi",
    "value": "Aek Gerger Sidodadi"
  },
  {
    "id": "1208302013",
    "district_id": "120830",
    "label": "Pulo Pitu Marihat",
    "value": "Pulo Pitu Marihat"
  },
  {
    "id": "1208302014",
    "district_id": "120830",
    "label": "Siringan Ringan",
    "value": "Siringan Ringan"
  },
  {
    "id": "1208302015",
    "district_id": "120830",
    "label": "Riah Na Poso",
    "value": "Riah Na Poso"
  },
  {
    "id": "1208302016",
    "district_id": "120830",
    "label": "Sei Merbau",
    "value": "Sei Merbau"
  },
  {
    "id": "1208302017",
    "district_id": "120830",
    "label": "Kampung Lalang",
    "value": "Kampung Lalang"
  },
  {
    "id": "1208302018",
    "district_id": "120830",
    "label": "Sordang Baru",
    "value": "Sordang Baru"
  },
  {
    "id": "1208302019",
    "district_id": "120830",
    "label": "Banjar Hulu",
    "value": "Banjar Hulu"
  },
  {
    "id": "1208302020",
    "district_id": "120830",
    "label": "Rawa Masin",
    "value": "Rawa Masin"
  },
  {
    "id": "1208312001",
    "district_id": "120831",
    "label": "Ujung Saribu",
    "value": "Ujung Saribu"
  },
  {
    "id": "1208312002",
    "district_id": "120831",
    "label": "Silimakuta Barat",
    "value": "Silimakuta Barat"
  },
  {
    "id": "1208312003",
    "district_id": "120831",
    "label": "Siboras",
    "value": "Siboras"
  },
  {
    "id": "1208312004",
    "district_id": "120831",
    "label": "Mardingding",
    "value": "Mardingding"
  },
  {
    "id": "1208312005",
    "district_id": "120831",
    "label": "Naga Saribu",
    "value": "Naga Saribu"
  },
  {
    "id": "1208312007",
    "district_id": "120831",
    "label": "Saribu Jandi",
    "value": "Saribu Jandi"
  },
  {
    "id": "1208312008",
    "district_id": "120831",
    "label": "Sinar Naga Mariah",
    "value": "Sinar Naga Mariah"
  },
  {
    "id": "1208312009",
    "district_id": "120831",
    "label": "Ujung Mariah",
    "value": "Ujung Mariah"
  },
  {
    "id": "1208312010",
    "district_id": "120831",
    "label": "Bandar Saribu",
    "value": "Bandar Saribu"
  },
  {
    "id": "1208312011",
    "district_id": "120831",
    "label": "Naga Bosar",
    "value": "Naga Bosar"
  },
  {
    "id": "1208322001",
    "district_id": "120832",
    "label": "Raya Huluan",
    "value": "Raya Huluan"
  },
  {
    "id": "1208322002",
    "district_id": "120832",
    "label": "Dolog Huluan",
    "value": "Dolog Huluan"
  },
  {
    "id": "1208322003",
    "district_id": "120832",
    "label": "Bintang Mariah",
    "value": "Bintang Mariah"
  },
  {
    "id": "1208322004",
    "district_id": "120832",
    "label": "Bah Bolon",
    "value": "Bah Bolon"
  },
  {
    "id": "1208322005",
    "district_id": "120832",
    "label": "Raya Usang",
    "value": "Raya Usang"
  },
  {
    "id": "1208322006",
    "district_id": "120832",
    "label": "Bangun Pane",
    "value": "Bangun Pane"
  },
  {
    "id": "1208322007",
    "district_id": "120832",
    "label": "Parjalangan",
    "value": "Parjalangan"
  },
  {
    "id": "1208322008",
    "district_id": "120832",
    "label": "Partuahan",
    "value": "Partuahan"
  },
  {
    "id": "1208322009",
    "district_id": "120832",
    "label": "Sinaman Labah",
    "value": "Sinaman Labah"
  },
  {
    "id": "1208322010",
    "district_id": "120832",
    "label": "Pamatang Sinaman",
    "value": "Pamatang Sinaman"
  },
  {
    "id": "1209082002",
    "district_id": "120908",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "1209082004",
    "district_id": "120908",
    "label": "Perkebunan Sei Balai",
    "value": "Perkebunan Sei Balai"
  },
  {
    "id": "1209082011",
    "district_id": "120908",
    "label": "Sei Beluru",
    "value": "Sei Beluru"
  },
  {
    "id": "1209082012",
    "district_id": "120908",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1209082019",
    "district_id": "120908",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "1209082021",
    "district_id": "120908",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "1209082022",
    "district_id": "120908",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1209091001",
    "district_id": "120909",
    "label": "Binjai Serbangan",
    "value": "Binjai Serbangan"
  },
  {
    "id": "1209092002",
    "district_id": "120909",
    "label": "Air Joman",
    "value": "Air Joman"
  },
  {
    "id": "1209092003",
    "district_id": "120909",
    "label": "Air Joman Baru",
    "value": "Air Joman Baru"
  },
  {
    "id": "1209092005",
    "district_id": "120909",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "1209092007",
    "district_id": "120909",
    "label": "Pasar Lembu",
    "value": "Pasar Lembu"
  },
  {
    "id": "1209092008",
    "district_id": "120909",
    "label": "Punggulan",
    "value": "Punggulan"
  },
  {
    "id": "1209092012",
    "district_id": "120909",
    "label": "Subur",
    "value": "Subur"
  },
  {
    "id": "1209102001",
    "district_id": "120910",
    "label": "Asahan Mati",
    "value": "Asahan Mati"
  },
  {
    "id": "1209102002",
    "district_id": "120910",
    "label": "Bagan Asahan",
    "value": "Bagan Asahan"
  },
  {
    "id": "1209102003",
    "district_id": "120910",
    "label": "Bagan Asahan Baru",
    "value": "Bagan Asahan Baru"
  },
  {
    "id": "1209102004",
    "district_id": "120910",
    "label": "Bagan Asahan Pekan",
    "value": "Bagan Asahan Pekan"
  },
  {
    "id": "1209102005",
    "district_id": "120910",
    "label": "Kapias Batu VIII",
    "value": "Kapias Batu VIII"
  },
  {
    "id": "1209102006",
    "district_id": "120910",
    "label": "Pematang Sei Baru",
    "value": "Pematang Sei Baru"
  },
  {
    "id": "1209102007",
    "district_id": "120910",
    "label": "Sei Apung",
    "value": "Sei Apung"
  },
  {
    "id": "1209102008",
    "district_id": "120910",
    "label": "Sei Apung Jaya",
    "value": "Sei Apung Jaya"
  },
  {
    "id": "1209112008",
    "district_id": "120911",
    "label": "Sei Kepayang Tengah",
    "value": "Sei Kepayang Tengah"
  },
  {
    "id": "1209112010",
    "district_id": "120911",
    "label": "Sei Kepayang Kanan",
    "value": "Sei Kepayang Kanan"
  },
  {
    "id": "1209112011",
    "district_id": "120911",
    "label": "Sei Paham",
    "value": "Sei Paham"
  },
  {
    "id": "1209112014",
    "district_id": "120911",
    "label": "Pertahanan",
    "value": "Pertahanan"
  },
  {
    "id": "1209112015",
    "district_id": "120911",
    "label": "Perbangunan",
    "value": "Perbangunan"
  },
  {
    "id": "1209112016",
    "district_id": "120911",
    "label": "Bangun Baru",
    "value": "Bangun Baru"
  },
  {
    "id": "1209122002",
    "district_id": "120912",
    "label": "Perkebunan Suka Raja",
    "value": "Perkebunan Suka Raja"
  },
  {
    "id": "1209122004",
    "district_id": "120912",
    "label": "Perkebunan Hessa",
    "value": "Perkebunan Hessa"
  },
  {
    "id": "1209122006",
    "district_id": "120912",
    "label": "Sei Dua Hulu",
    "value": "Sei Dua Hulu"
  },
  {
    "id": "1209122007",
    "district_id": "120912",
    "label": "Sei Lama",
    "value": "Sei Lama"
  },
  {
    "id": "1209122008",
    "district_id": "120912",
    "label": "Silomlom",
    "value": "Silomlom"
  },
  {
    "id": "1209122009",
    "district_id": "120912",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1209122012",
    "district_id": "120912",
    "label": "Sipaku Area",
    "value": "Sipaku Area"
  },
  {
    "id": "1209122013",
    "district_id": "120912",
    "label": "Anjung Ganjang",
    "value": "Anjung Ganjang"
  },
  {
    "id": "1209132001",
    "district_id": "120913",
    "label": "Sei Alim Ulu",
    "value": "Sei Alim Ulu"
  },
  {
    "id": "1209132003",
    "district_id": "120913",
    "label": "Pinanggiripan",
    "value": "Pinanggiripan"
  },
  {
    "id": "1209132006",
    "district_id": "120913",
    "label": "Perkebunan Pulahan",
    "value": "Perkebunan Pulahan"
  },
  {
    "id": "1209132007",
    "district_id": "120913",
    "label": "Hessa Perlompongan",
    "value": "Hessa Perlompongan"
  },
  {
    "id": "1209132008",
    "district_id": "120913",
    "label": "Perkebunan Air Batu I/II",
    "value": "Perkebunan Air Batu I/II"
  },
  {
    "id": "1209132009",
    "district_id": "120913",
    "label": "Perkebunan Air Batu III/IX",
    "value": "Perkebunan Air Batu III/IX"
  },
  {
    "id": "1209132010",
    "district_id": "120913",
    "label": "Danau Sijabut",
    "value": "Danau Sijabut"
  },
  {
    "id": "1209132012",
    "district_id": "120913",
    "label": "Air Teluk Hessa",
    "value": "Air Teluk Hessa"
  },
  {
    "id": "1209132013",
    "district_id": "120913",
    "label": "Air Genting",
    "value": "Air Genting"
  },
  {
    "id": "1209132014",
    "district_id": "120913",
    "label": "Hessa Air Genting",
    "value": "Hessa Air Genting"
  },
  {
    "id": "1209132018",
    "district_id": "120913",
    "label": "Sijabut Teratai",
    "value": "Sijabut Teratai"
  },
  {
    "id": "1209132019",
    "district_id": "120913",
    "label": "Pulau Pule",
    "value": "Pulau Pule"
  },
  {
    "id": "1209142001",
    "district_id": "120914",
    "label": "Sei Piring",
    "value": "Sei Piring"
  },
  {
    "id": "1209142002",
    "district_id": "120914",
    "label": "Pulau Rakyat Tua",
    "value": "Pulau Rakyat Tua"
  },
  {
    "id": "1209142003",
    "district_id": "120914",
    "label": "Pulau Rakyat Pekan",
    "value": "Pulau Rakyat Pekan"
  },
  {
    "id": "1209142004",
    "district_id": "120914",
    "label": "Orika",
    "value": "Orika"
  },
  {
    "id": "1209142005",
    "district_id": "120914",
    "label": "Manis",
    "value": "Manis"
  },
  {
    "id": "1209142006",
    "district_id": "120914",
    "label": "Tunggul 45",
    "value": "Tunggul 45"
  },
  {
    "id": "1209142007",
    "district_id": "120914",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "1209142008",
    "district_id": "120914",
    "label": "Persatuan",
    "value": "Persatuan"
  },
  {
    "id": "1209142009",
    "district_id": "120914",
    "label": "Padang Mahondang",
    "value": "Padang Mahondang"
  },
  {
    "id": "1209142010",
    "district_id": "120914",
    "label": "Ofa Padang Mahondang",
    "value": "Ofa Padang Mahondang"
  },
  {
    "id": "1209142011",
    "district_id": "120914",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1209142012",
    "district_id": "120914",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1209152003",
    "district_id": "120915",
    "label": "Bandar Pulau Pekan",
    "value": "Bandar Pulau Pekan"
  },
  {
    "id": "1209152005",
    "district_id": "120915",
    "label": "Buntu Maraja",
    "value": "Buntu Maraja"
  },
  {
    "id": "1209152006",
    "district_id": "120915",
    "label": "Gonting Malaha",
    "value": "Gonting Malaha"
  },
  {
    "id": "1209152010",
    "district_id": "120915",
    "label": "Padang Pulau",
    "value": "Padang Pulau"
  },
  {
    "id": "1209152012",
    "district_id": "120915",
    "label": "Perkebunan Aek Tarum",
    "value": "Perkebunan Aek Tarum"
  },
  {
    "id": "1209152016",
    "district_id": "120915",
    "label": "Perkebunan Padang Pulau",
    "value": "Perkebunan Padang Pulau"
  },
  {
    "id": "1209152019",
    "district_id": "120915",
    "label": "Huta Rao",
    "value": "Huta Rao"
  },
  {
    "id": "1209152020",
    "district_id": "120915",
    "label": "Aek Nagali",
    "value": "Aek Nagali"
  },
  {
    "id": "1209152021",
    "district_id": "120915",
    "label": "Gajah Sakti",
    "value": "Gajah Sakti"
  },
  {
    "id": "1209152022",
    "district_id": "120915",
    "label": "Gunung Berkat",
    "value": "Gunung Berkat"
  },
  {
    "id": "1209162001",
    "district_id": "120916",
    "label": "Ambalutu",
    "value": "Ambalutu"
  },
  {
    "id": "1209162002",
    "district_id": "120916",
    "label": "Buntu Pane",
    "value": "Buntu Pane"
  },
  {
    "id": "1209162003",
    "district_id": "120916",
    "label": "Lestari",
    "value": "Lestari"
  },
  {
    "id": "1209162005",
    "district_id": "120916",
    "label": "Prapat Janji",
    "value": "Prapat Janji"
  },
  {
    "id": "1209162007",
    "district_id": "120916",
    "label": "Sei Silau Timur",
    "value": "Sei Silau Timur"
  },
  {
    "id": "1209162016",
    "district_id": "120916",
    "label": "Sionggang",
    "value": "Sionggang"
  },
  {
    "id": "1209162017",
    "district_id": "120916",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1209162018",
    "district_id": "120916",
    "label": "Perkebunan Sei Silau",
    "value": "Perkebunan Sei Silau"
  },
  {
    "id": "1209162019",
    "district_id": "120916",
    "label": "Karya Ambalutu",
    "value": "Karya Ambalutu"
  },
  {
    "id": "1209172001",
    "district_id": "120917",
    "label": "Bandar Pasir Mandoge",
    "value": "Bandar Pasir Mandoge"
  },
  {
    "id": "1209172002",
    "district_id": "120917",
    "label": "Huta Bagasan",
    "value": "Huta Bagasan"
  },
  {
    "id": "1209172003",
    "district_id": "120917",
    "label": "Sei Nadoras",
    "value": "Sei Nadoras"
  },
  {
    "id": "1209172004",
    "district_id": "120917",
    "label": "Silau Jawa",
    "value": "Silau Jawa"
  },
  {
    "id": "1209172005",
    "district_id": "120917",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1209172006",
    "district_id": "120917",
    "label": "Sei Kopas",
    "value": "Sei Kopas"
  },
  {
    "id": "1209172007",
    "district_id": "120917",
    "label": "Huta Padang",
    "value": "Huta Padang"
  },
  {
    "id": "1209172008",
    "district_id": "120917",
    "label": "Tomuan Holbung",
    "value": "Tomuan Holbung"
  },
  {
    "id": "1209172009",
    "district_id": "120917",
    "label": "Gotting Sidodadi",
    "value": "Gotting Sidodadi"
  },
  {
    "id": "1209181001",
    "district_id": "120918",
    "label": "Aek Loba Pekan",
    "value": "Aek Loba Pekan"
  },
  {
    "id": "1209182002",
    "district_id": "120918",
    "label": "Aek Loba Afd I",
    "value": "Aek Loba Afd I"
  },
  {
    "id": "1209182003",
    "district_id": "120918",
    "label": "Alang Bonbon",
    "value": "Alang Bonbon"
  },
  {
    "id": "1209182009",
    "district_id": "120918",
    "label": "Sengon Sari",
    "value": "Sengon Sari"
  },
  {
    "id": "1209182010",
    "district_id": "120918",
    "label": "Lobu Jiur",
    "value": "Lobu Jiur"
  },
  {
    "id": "1209182011",
    "district_id": "120918",
    "label": "Rawa Sari",
    "value": "Rawa Sari"
  },
  {
    "id": "1209182013",
    "district_id": "120918",
    "label": "Aek Loba",
    "value": "Aek Loba"
  },
  {
    "id": "1209191001",
    "district_id": "120919",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "1209191002",
    "district_id": "120919",
    "label": "Bunut Barat",
    "value": "Bunut Barat"
  },
  {
    "id": "1209191003",
    "district_id": "120919",
    "label": "Dadimulyo",
    "value": "Dadimulyo"
  },
  {
    "id": "1209191004",
    "district_id": "120919",
    "label": "Kisaran Barat",
    "value": "Kisaran Barat"
  },
  {
    "id": "1209191005",
    "district_id": "120919",
    "label": "Kisaran Baru",
    "value": "Kisaran Baru"
  },
  {
    "id": "1209191006",
    "district_id": "120919",
    "label": "Kisaran Kota",
    "value": "Kisaran Kota"
  },
  {
    "id": "1209191007",
    "district_id": "120919",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "1209191008",
    "district_id": "120919",
    "label": "Sei Renggas",
    "value": "Sei Renggas"
  },
  {
    "id": "1209191009",
    "district_id": "120919",
    "label": "Sendang Sari",
    "value": "Sendang Sari"
  },
  {
    "id": "1209191010",
    "district_id": "120919",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1209191011",
    "district_id": "120919",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "1209191012",
    "district_id": "120919",
    "label": "Tebing Kisaran",
    "value": "Tebing Kisaran"
  },
  {
    "id": "1209191013",
    "district_id": "120919",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1209201001",
    "district_id": "120920",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1209201002",
    "district_id": "120920",
    "label": "Siumbut Umbut",
    "value": "Siumbut Umbut"
  },
  {
    "id": "1209201003",
    "district_id": "120920",
    "label": "Siumbut Baru",
    "value": "Siumbut Baru"
  },
  {
    "id": "1209201004",
    "district_id": "120920",
    "label": "Sentang",
    "value": "Sentang"
  },
  {
    "id": "1209201005",
    "district_id": "120920",
    "label": "Selawan",
    "value": "Selawan"
  },
  {
    "id": "1209201006",
    "district_id": "120920",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "1209201007",
    "district_id": "120920",
    "label": "Lestari",
    "value": "Lestari"
  },
  {
    "id": "1209201008",
    "district_id": "120920",
    "label": "Kisaran Timur",
    "value": "Kisaran Timur"
  },
  {
    "id": "1209201009",
    "district_id": "120920",
    "label": "Kisaran Naga",
    "value": "Kisaran Naga"
  },
  {
    "id": "1209201010",
    "district_id": "120920",
    "label": "Kedai Ledang",
    "value": "Kedai Ledang"
  },
  {
    "id": "1209201011",
    "district_id": "120920",
    "label": "Karang Anyer",
    "value": "Karang Anyer"
  },
  {
    "id": "1209201012",
    "district_id": "120920",
    "label": "Gambir Baru",
    "value": "Gambir Baru"
  },
  {
    "id": "1209212001",
    "district_id": "120921",
    "label": "Aek Songsongan",
    "value": "Aek Songsongan"
  },
  {
    "id": "1209212002",
    "district_id": "120921",
    "label": "Perkebunan Bandar Selamat",
    "value": "Perkebunan Bandar Selamat"
  },
  {
    "id": "1209212003",
    "district_id": "120921",
    "label": "Perkebunan Bandar Pulau",
    "value": "Perkebunan Bandar Pulau"
  },
  {
    "id": "1209212004",
    "district_id": "120921",
    "label": "Aek Bamban",
    "value": "Aek Bamban"
  },
  {
    "id": "1209212005",
    "district_id": "120921",
    "label": "Marjanji Aceh",
    "value": "Marjanji Aceh"
  },
  {
    "id": "1209212006",
    "district_id": "120921",
    "label": "Lobu Rappa",
    "value": "Lobu Rappa"
  },
  {
    "id": "1209212007",
    "district_id": "120921",
    "label": "Tangga",
    "value": "Tangga"
  },
  {
    "id": "1209212008",
    "district_id": "120921",
    "label": "Situnjak",
    "value": "Situnjak"
  },
  {
    "id": "1209212009",
    "district_id": "120921",
    "label": "Mekar Marjanji",
    "value": "Mekar Marjanji"
  },
  {
    "id": "1209222001",
    "district_id": "120922",
    "label": "Rahuning",
    "value": "Rahuning"
  },
  {
    "id": "1209222002",
    "district_id": "120922",
    "label": "Perkebunan Gunung Melayu",
    "value": "Perkebunan Gunung Melayu"
  },
  {
    "id": "1209222003",
    "district_id": "120922",
    "label": "Gunung Melayu",
    "value": "Gunung Melayu"
  },
  {
    "id": "1209222004",
    "district_id": "120922",
    "label": "Batu Anam",
    "value": "Batu Anam"
  },
  {
    "id": "1209222005",
    "district_id": "120922",
    "label": "Perkebunan Aek Nagaga",
    "value": "Perkebunan Aek Nagaga"
  },
  {
    "id": "1209222006",
    "district_id": "120922",
    "label": "Rahuning I",
    "value": "Rahuning I"
  },
  {
    "id": "1209222007",
    "district_id": "120922",
    "label": "Rahuning II",
    "value": "Rahuning II"
  },
  {
    "id": "1209232001",
    "district_id": "120923",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1209232002",
    "district_id": "120923",
    "label": "Perkebunan Sei Dadap I/II",
    "value": "Perkebunan Sei Dadap I/II"
  },
  {
    "id": "1209232003",
    "district_id": "120923",
    "label": "Perkebunan Sei Dadap III/IV",
    "value": "Perkebunan Sei Dadap III/IV"
  },
  {
    "id": "1209232004",
    "district_id": "120923",
    "label": "Sei Kamah I",
    "value": "Sei Kamah I"
  },
  {
    "id": "1209232005",
    "district_id": "120923",
    "label": "Sei Kamah II",
    "value": "Sei Kamah II"
  },
  {
    "id": "1209232006",
    "district_id": "120923",
    "label": "Bahung Sibatu-batu",
    "value": "Bahung Sibatu-batu"
  },
  {
    "id": "1209232007",
    "district_id": "120923",
    "label": "Sei Alim Hasak",
    "value": "Sei Alim Hasak"
  },
  {
    "id": "1209232008",
    "district_id": "120923",
    "label": "Tanjung Asri",
    "value": "Tanjung Asri"
  },
  {
    "id": "1209232009",
    "district_id": "120923",
    "label": "Sei Kamah Baru",
    "value": "Sei Kamah Baru"
  },
  {
    "id": "1209232010",
    "district_id": "120923",
    "label": "Pasiran",
    "value": "Pasiran"
  },
  {
    "id": "1209242001",
    "district_id": "120924",
    "label": "Sei Nangka",
    "value": "Sei Nangka"
  },
  {
    "id": "1209242002",
    "district_id": "120924",
    "label": "Sei Jawi-Jawi",
    "value": "Sei Jawi-Jawi"
  },
  {
    "id": "1209242003",
    "district_id": "120924",
    "label": "Sei Serindan",
    "value": "Sei Serindan"
  },
  {
    "id": "1209242004",
    "district_id": "120924",
    "label": "Sei Tualang Pandau",
    "value": "Sei Tualang Pandau"
  },
  {
    "id": "1209242005",
    "district_id": "120924",
    "label": "Sei Lendir",
    "value": "Sei Lendir"
  },
  {
    "id": "1209242006",
    "district_id": "120924",
    "label": "Sei Kepayang Kiri",
    "value": "Sei Kepayang Kiri"
  },
  {
    "id": "1209252001",
    "district_id": "120925",
    "label": "Sei Lunang",
    "value": "Sei Lunang"
  },
  {
    "id": "1209252002",
    "district_id": "120925",
    "label": "Sei Pasir",
    "value": "Sei Pasir"
  },
  {
    "id": "1209252003",
    "district_id": "120925",
    "label": "Sei Tempurung",
    "value": "Sei Tempurung"
  },
  {
    "id": "1209252004",
    "district_id": "120925",
    "label": "Sarang Helang",
    "value": "Sarang Helang"
  },
  {
    "id": "1209252005",
    "district_id": "120925",
    "label": "Sei Sembilang",
    "value": "Sei Sembilang"
  },
  {
    "id": "1209262001",
    "district_id": "120926",
    "label": "Tinggi Raja",
    "value": "Tinggi Raja"
  },
  {
    "id": "1209262002",
    "district_id": "120926",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "1209262003",
    "district_id": "120926",
    "label": "Terusan Tengah",
    "value": "Terusan Tengah"
  },
  {
    "id": "1209262004",
    "district_id": "120926",
    "label": "Piasa Ulu",
    "value": "Piasa Ulu"
  },
  {
    "id": "1209262005",
    "district_id": "120926",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1209262006",
    "district_id": "120926",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1209262007",
    "district_id": "120926",
    "label": "Padang Sari",
    "value": "Padang Sari"
  },
  {
    "id": "1209272001",
    "district_id": "120927",
    "label": "Urung Pane",
    "value": "Urung Pane"
  },
  {
    "id": "1209272002",
    "district_id": "120927",
    "label": "Sei Silau Tua",
    "value": "Sei Silau Tua"
  },
  {
    "id": "1209272003",
    "district_id": "120927",
    "label": "Sei Silau Barat",
    "value": "Sei Silau Barat"
  },
  {
    "id": "1209272004",
    "district_id": "120927",
    "label": "Silau Maraja",
    "value": "Silau Maraja"
  },
  {
    "id": "1209272005",
    "district_id": "120927",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1209282001",
    "district_id": "120928",
    "label": "Silo Baru",
    "value": "Silo Baru"
  },
  {
    "id": "1209282002",
    "district_id": "120928",
    "label": "Silo Lama",
    "value": "Silo Lama"
  },
  {
    "id": "1209282003",
    "district_id": "120928",
    "label": "Silo Bonto",
    "value": "Silo Bonto"
  },
  {
    "id": "1209282004",
    "district_id": "120928",
    "label": "Lubuk Palas",
    "value": "Lubuk Palas"
  },
  {
    "id": "1209282005",
    "district_id": "120928",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1209292001",
    "district_id": "120929",
    "label": "Rawang Lama",
    "value": "Rawang Lama"
  },
  {
    "id": "1209292002",
    "district_id": "120929",
    "label": "Rawang Baru",
    "value": "Rawang Baru"
  },
  {
    "id": "1209292003",
    "district_id": "120929",
    "label": "Rawang Pasar IV",
    "value": "Rawang Pasar IV"
  },
  {
    "id": "1209292004",
    "district_id": "120929",
    "label": "Rawang Pasar V",
    "value": "Rawang Pasar V"
  },
  {
    "id": "1209292005",
    "district_id": "120929",
    "label": "Pondok Bungur",
    "value": "Pondok Bungur"
  },
  {
    "id": "1209292006",
    "district_id": "120929",
    "label": "Panca Arga",
    "value": "Panca Arga"
  },
  {
    "id": "1209292007",
    "district_id": "120929",
    "label": "Rawang Pasar VI",
    "value": "Rawang Pasar VI"
  },
  {
    "id": "1209302001",
    "district_id": "120930",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1209302002",
    "district_id": "120930",
    "label": "Perhutaan Silau",
    "value": "Perhutaan Silau"
  },
  {
    "id": "1209302003",
    "district_id": "120930",
    "label": "Tanah Rakyat",
    "value": "Tanah Rakyat"
  },
  {
    "id": "1209302004",
    "district_id": "120930",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "1209302005",
    "district_id": "120930",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "1209302006",
    "district_id": "120930",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1209302007",
    "district_id": "120930",
    "label": "Pulo Bandring",
    "value": "Pulo Bandring"
  },
  {
    "id": "1209302008",
    "district_id": "120930",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1209302009",
    "district_id": "120930",
    "label": "Suka Damai Barat",
    "value": "Suka Damai Barat"
  },
  {
    "id": "1209302010",
    "district_id": "120930",
    "label": "Bunut Seberang",
    "value": "Bunut Seberang"
  },
  {
    "id": "1209312001",
    "district_id": "120931",
    "label": "Air Teluk Kiri",
    "value": "Air Teluk Kiri"
  },
  {
    "id": "1209312002",
    "district_id": "120931",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "1209312003",
    "district_id": "120931",
    "label": "Perkebunan Teluk Dalam",
    "value": "Perkebunan Teluk Dalam"
  },
  {
    "id": "1209312004",
    "district_id": "120931",
    "label": "Pulau Maria",
    "value": "Pulau Maria"
  },
  {
    "id": "1209312005",
    "district_id": "120931",
    "label": "Pulau Tanjung",
    "value": "Pulau Tanjung"
  },
  {
    "id": "1209312006",
    "district_id": "120931",
    "label": "Mekar Tanjung",
    "value": "Mekar Tanjung"
  },
  {
    "id": "1209322001",
    "district_id": "120932",
    "label": "Aek Bange",
    "value": "Aek Bange"
  },
  {
    "id": "1209322002",
    "district_id": "120932",
    "label": "Aek Korsik",
    "value": "Aek Korsik"
  },
  {
    "id": "1209322003",
    "district_id": "120932",
    "label": "Aek Ledong",
    "value": "Aek Ledong"
  },
  {
    "id": "1209322004",
    "district_id": "120932",
    "label": "Ledong Barat",
    "value": "Ledong Barat"
  },
  {
    "id": "1209322005",
    "district_id": "120932",
    "label": "Aek Nabuntu",
    "value": "Aek Nabuntu"
  },
  {
    "id": "1209322006",
    "district_id": "120932",
    "label": "Ledong Timur",
    "value": "Ledong Timur"
  },
  {
    "id": "1209322007",
    "district_id": "120932",
    "label": "Padang Sipirok",
    "value": "Padang Sipirok"
  },
  {
    "id": "1210011001",
    "district_id": "121001",
    "label": "Padang Matinggi",
    "value": "Padang Matinggi"
  },
  {
    "id": "1210011002",
    "district_id": "121001",
    "label": "Siringo-Ringo",
    "value": "Siringo-Ringo"
  },
  {
    "id": "1210011003",
    "district_id": "121001",
    "label": "Sirandorung",
    "value": "Sirandorung"
  },
  {
    "id": "1210011004",
    "district_id": "121001",
    "label": "Rantauprapat",
    "value": "Rantauprapat"
  },
  {
    "id": "1210011005",
    "district_id": "121001",
    "label": "Kartini",
    "value": "Kartini"
  },
  {
    "id": "1210011006",
    "district_id": "121001",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "1210011007",
    "district_id": "121001",
    "label": "Binaraga",
    "value": "Binaraga"
  },
  {
    "id": "1210011008",
    "district_id": "121001",
    "label": "Pulo Padang",
    "value": "Pulo Padang"
  },
  {
    "id": "1210011009",
    "district_id": "121001",
    "label": "Padang Bulan",
    "value": "Padang Bulan"
  },
  {
    "id": "1210011010",
    "district_id": "121001",
    "label": "Aek Paing",
    "value": "Aek Paing"
  },
  {
    "id": "1210021001",
    "district_id": "121002",
    "label": "Bakaran Batu",
    "value": "Bakaran Batu"
  },
  {
    "id": "1210021002",
    "district_id": "121002",
    "label": "Sioldengan",
    "value": "Sioldengan"
  },
  {
    "id": "1210021003",
    "district_id": "121002",
    "label": "Urung Kompas",
    "value": "Urung Kompas"
  },
  {
    "id": "1210021004",
    "district_id": "121002",
    "label": "Ujung Bandar",
    "value": "Ujung Bandar"
  },
  {
    "id": "1210021005",
    "district_id": "121002",
    "label": "Sigambal",
    "value": "Sigambal"
  },
  {
    "id": "1210021006",
    "district_id": "121002",
    "label": "Lobusona",
    "value": "Lobusona"
  },
  {
    "id": "1210021007",
    "district_id": "121002",
    "label": "Pardamean",
    "value": "Pardamean"
  },
  {
    "id": "1210021008",
    "district_id": "121002",
    "label": "Danau Bale",
    "value": "Danau Bale"
  },
  {
    "id": "1210021009",
    "district_id": "121002",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1210072001",
    "district_id": "121007",
    "label": "Sibargot",
    "value": "Sibargot"
  },
  {
    "id": "1210072002",
    "district_id": "121007",
    "label": "Bandar Kumbul",
    "value": "Bandar Kumbul"
  },
  {
    "id": "1210072003",
    "district_id": "121007",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1210072004",
    "district_id": "121007",
    "label": "Janji",
    "value": "Janji"
  },
  {
    "id": "1210072005",
    "district_id": "121007",
    "label": "Tebing Linggahara",
    "value": "Tebing Linggahara"
  },
  {
    "id": "1210072006",
    "district_id": "121007",
    "label": "AFD.I Rantauprapat",
    "value": "AFD.I Rantauprapat"
  },
  {
    "id": "1210072007",
    "district_id": "121007",
    "label": "AFD.II Rantauprapat",
    "value": "AFD.II Rantauprapat"
  },
  {
    "id": "1210072008",
    "district_id": "121007",
    "label": "Aek Buru Selatan",
    "value": "Aek Buru Selatan"
  },
  {
    "id": "1210072009",
    "district_id": "121007",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1210072010",
    "district_id": "121007",
    "label": "Tebing Linggahara Baru",
    "value": "Tebing Linggahara Baru"
  },
  {
    "id": "1210081001",
    "district_id": "121008",
    "label": "Negeri Lama",
    "value": "Negeri Lama"
  },
  {
    "id": "1210081002",
    "district_id": "121008",
    "label": "Negeri Baru",
    "value": "Negeri Baru"
  },
  {
    "id": "1210082003",
    "district_id": "121008",
    "label": "Perkebunan Sennah",
    "value": "Perkebunan Sennah"
  },
  {
    "id": "1210082004",
    "district_id": "121008",
    "label": "Perkebunan Negeri Lama",
    "value": "Perkebunan Negeri Lama"
  },
  {
    "id": "1210082005",
    "district_id": "121008",
    "label": "Perkebunan Bilah",
    "value": "Perkebunan Bilah"
  },
  {
    "id": "1210082006",
    "district_id": "121008",
    "label": "Kampung Bilah",
    "value": "Kampung Bilah"
  },
  {
    "id": "1210082007",
    "district_id": "121008",
    "label": "Negeri Lama Seberang",
    "value": "Negeri Lama Seberang"
  },
  {
    "id": "1210082008",
    "district_id": "121008",
    "label": "Sei Tampang",
    "value": "Sei Tampang"
  },
  {
    "id": "1210082009",
    "district_id": "121008",
    "label": "Selat Besar",
    "value": "Selat Besar"
  },
  {
    "id": "1210082010",
    "district_id": "121008",
    "label": "Tanjung Haloban",
    "value": "Tanjung Haloban"
  },
  {
    "id": "1210082011",
    "district_id": "121008",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1210082012",
    "district_id": "121008",
    "label": "Sei Tarolat",
    "value": "Sei Tarolat"
  },
  {
    "id": "1210082013",
    "district_id": "121008",
    "label": "Sei Kasih",
    "value": "Sei Kasih"
  },
  {
    "id": "1210092001",
    "district_id": "121009",
    "label": "Lingga Tiga",
    "value": "Lingga Tiga"
  },
  {
    "id": "1210092002",
    "district_id": "121009",
    "label": "Tanjung Siram",
    "value": "Tanjung Siram"
  },
  {
    "id": "1210092003",
    "district_id": "121009",
    "label": "Pematang Seleng",
    "value": "Pematang Seleng"
  },
  {
    "id": "1210092004",
    "district_id": "121009",
    "label": "Perbaungan",
    "value": "Perbaungan"
  },
  {
    "id": "1210092005",
    "district_id": "121009",
    "label": "Gunung Selamat",
    "value": "Gunung Selamat"
  },
  {
    "id": "1210092006",
    "district_id": "121009",
    "label": "Emplasemen Aek Nabara",
    "value": "Emplasemen Aek Nabara"
  },
  {
    "id": "1210092007",
    "district_id": "121009",
    "label": "Bandar Tinggi",
    "value": "Bandar Tinggi"
  },
  {
    "id": "1210092008",
    "district_id": "121009",
    "label": "Kampung Dalam",
    "value": "Kampung Dalam"
  },
  {
    "id": "1210092009",
    "district_id": "121009",
    "label": "Pondok Batu",
    "value": "Pondok Batu"
  },
  {
    "id": "1210092010",
    "district_id": "121009",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "1210092011",
    "district_id": "121009",
    "label": "N-1 Aek Nabara",
    "value": "N-1 Aek Nabara"
  },
  {
    "id": "1210092012",
    "district_id": "121009",
    "label": "N-2 Aek Nabara",
    "value": "N-2 Aek Nabara"
  },
  {
    "id": "1210092013",
    "district_id": "121009",
    "label": "N-3 Aek Nabara",
    "value": "N-3 Aek Nabara"
  },
  {
    "id": "1210092014",
    "district_id": "121009",
    "label": "N-4 Aek Nabara",
    "value": "N-4 Aek Nabara"
  },
  {
    "id": "1210092015",
    "district_id": "121009",
    "label": "N-5 Aek Nabara",
    "value": "N-5 Aek Nabara"
  },
  {
    "id": "1210092016",
    "district_id": "121009",
    "label": "N-6 Aek Nabara",
    "value": "N-6 Aek Nabara"
  },
  {
    "id": "1210092017",
    "district_id": "121009",
    "label": "N-7 Aek Nabara",
    "value": "N-7 Aek Nabara"
  },
  {
    "id": "1210092018",
    "district_id": "121009",
    "label": "N-8 Aek Nabara",
    "value": "N-8 Aek Nabara"
  },
  {
    "id": "1210092019",
    "district_id": "121009",
    "label": "S-1 Aek Nabara",
    "value": "S-1 Aek Nabara"
  },
  {
    "id": "1210092020",
    "district_id": "121009",
    "label": "S-2 Aek Nabara",
    "value": "S-2 Aek Nabara"
  },
  {
    "id": "1210092021",
    "district_id": "121009",
    "label": "S-3 Aek Nabara",
    "value": "S-3 Aek Nabara"
  },
  {
    "id": "1210092022",
    "district_id": "121009",
    "label": "S-4 Aek Nabara",
    "value": "S-4 Aek Nabara"
  },
  {
    "id": "1210092023",
    "district_id": "121009",
    "label": "S-5 Aek Nabara",
    "value": "S-5 Aek Nabara"
  },
  {
    "id": "1210092024",
    "district_id": "121009",
    "label": "S-6 Aek Nabara",
    "value": "S-6 Aek Nabara"
  },
  {
    "id": "1210142001",
    "district_id": "121014",
    "label": "Sidorukun",
    "value": "Sidorukun"
  },
  {
    "id": "1210142002",
    "district_id": "121014",
    "label": "Tebing Tinggi Pangkatan",
    "value": "Tebing Tinggi Pangkatan"
  },
  {
    "id": "1210142003",
    "district_id": "121014",
    "label": "Perkebunan Pangkatan",
    "value": "Perkebunan Pangkatan"
  },
  {
    "id": "1210142004",
    "district_id": "121014",
    "label": "Kampung Padang",
    "value": "Kampung Padang"
  },
  {
    "id": "1210142005",
    "district_id": "121014",
    "label": "Pangkatan",
    "value": "Pangkatan"
  },
  {
    "id": "1210142006",
    "district_id": "121014",
    "label": "Sennah",
    "value": "Sennah"
  },
  {
    "id": "1210142007",
    "district_id": "121014",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1210181001",
    "district_id": "121018",
    "label": "Labuhan Bilik",
    "value": "Labuhan Bilik"
  },
  {
    "id": "1210182002",
    "district_id": "121018",
    "label": "Sei Pelancang",
    "value": "Sei Pelancang"
  },
  {
    "id": "1210182003",
    "district_id": "121018",
    "label": "Sei Siarti",
    "value": "Sei Siarti"
  },
  {
    "id": "1210182004",
    "district_id": "121018",
    "label": "Sei Nahodaris",
    "value": "Sei Nahodaris"
  },
  {
    "id": "1210182005",
    "district_id": "121018",
    "label": "Sei Merdeka",
    "value": "Sei Merdeka"
  },
  {
    "id": "1210182006",
    "district_id": "121018",
    "label": "Sei Rakyat",
    "value": "Sei Rakyat"
  },
  {
    "id": "1210182007",
    "district_id": "121018",
    "label": "Selat Beting",
    "value": "Selat Beting"
  },
  {
    "id": "1210182008",
    "district_id": "121018",
    "label": "Bagan Bilah",
    "value": "Bagan Bilah"
  },
  {
    "id": "1210182009",
    "district_id": "121018",
    "label": "Telaga Suka",
    "value": "Telaga Suka"
  },
  {
    "id": "1210182010",
    "district_id": "121018",
    "label": "Pasar Tiga",
    "value": "Pasar Tiga"
  },
  {
    "id": "1210191001",
    "district_id": "121019",
    "label": "Sei Berombang",
    "value": "Sei Berombang"
  },
  {
    "id": "1210192002",
    "district_id": "121019",
    "label": "Sei Penggantungan",
    "value": "Sei Penggantungan"
  },
  {
    "id": "1210192003",
    "district_id": "121019",
    "label": "Sei Lumut",
    "value": "Sei Lumut"
  },
  {
    "id": "1210192004",
    "district_id": "121019",
    "label": "Sei Tawar",
    "value": "Sei Tawar"
  },
  {
    "id": "1210192005",
    "district_id": "121019",
    "label": "Sei Sanggul",
    "value": "Sei Sanggul"
  },
  {
    "id": "1210192006",
    "district_id": "121019",
    "label": "Sei Sakat",
    "value": "Sei Sakat"
  },
  {
    "id": "1210192007",
    "district_id": "121019",
    "label": "Sei Baru",
    "value": "Sei Baru"
  },
  {
    "id": "1210192008",
    "district_id": "121019",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1210202001",
    "district_id": "121020",
    "label": "Tanjung Sarang Elang",
    "value": "Tanjung Sarang Elang"
  },
  {
    "id": "1210202002",
    "district_id": "121020",
    "label": "Perkebunan Ajamu",
    "value": "Perkebunan Ajamu"
  },
  {
    "id": "1210202003",
    "district_id": "121020",
    "label": "Teluk Sentosa",
    "value": "Teluk Sentosa"
  },
  {
    "id": "1210202004",
    "district_id": "121020",
    "label": "Cinta Makmur",
    "value": "Cinta Makmur"
  },
  {
    "id": "1210202005",
    "district_id": "121020",
    "label": "Sei Sentosa",
    "value": "Sei Sentosa"
  },
  {
    "id": "1210202006",
    "district_id": "121020",
    "label": "Meranti Paham",
    "value": "Meranti Paham"
  },
  {
    "id": "1210202007",
    "district_id": "121020",
    "label": "Sei Jawi-Jawi",
    "value": "Sei Jawi-Jawi"
  },
  {
    "id": "1211011005",
    "district_id": "121101",
    "label": "Kuta Gambir",
    "value": "Kuta Gambir"
  },
  {
    "id": "1211011007",
    "district_id": "121101",
    "label": "Sidiangkat",
    "value": "Sidiangkat"
  },
  {
    "id": "1211011009",
    "district_id": "121101",
    "label": "Batang Beruh",
    "value": "Batang Beruh"
  },
  {
    "id": "1211011010",
    "district_id": "121101",
    "label": "Sidikalang",
    "value": "Sidikalang"
  },
  {
    "id": "1211011015",
    "district_id": "121101",
    "label": "Bintang Hulu",
    "value": "Bintang Hulu"
  },
  {
    "id": "1211012008",
    "district_id": "121101",
    "label": "Belang Malum",
    "value": "Belang Malum"
  },
  {
    "id": "1211012011",
    "district_id": "121101",
    "label": "Huta Rakyat",
    "value": "Huta Rakyat"
  },
  {
    "id": "1211012012",
    "district_id": "121101",
    "label": "Kalang",
    "value": "Kalang"
  },
  {
    "id": "1211012013",
    "district_id": "121101",
    "label": "Bintang",
    "value": "Bintang"
  },
  {
    "id": "1211012014",
    "district_id": "121101",
    "label": "Kalang Simbara",
    "value": "Kalang Simbara"
  },
  {
    "id": "1211012017",
    "district_id": "121101",
    "label": "Bintang Mersada",
    "value": "Bintang Mersada"
  },
  {
    "id": "1211021007",
    "district_id": "121102",
    "label": "Pagagan JuluI",
    "value": "Pagagan JuluI"
  },
  {
    "id": "1211022001",
    "district_id": "121102",
    "label": "Pagagan Julu X",
    "value": "Pagagan Julu X"
  },
  {
    "id": "1211022002",
    "district_id": "121102",
    "label": "Pagagan Julu IX",
    "value": "Pagagan Julu IX"
  },
  {
    "id": "1211022003",
    "district_id": "121102",
    "label": "Pagagan Julu VIII",
    "value": "Pagagan Julu VIII"
  },
  {
    "id": "1211022004",
    "district_id": "121102",
    "label": "Pagagan Julu VII",
    "value": "Pagagan Julu VII"
  },
  {
    "id": "1211022005",
    "district_id": "121102",
    "label": "Pagagan Julu VI",
    "value": "Pagagan Julu VI"
  },
  {
    "id": "1211022006",
    "district_id": "121102",
    "label": "Pagagan Julu V",
    "value": "Pagagan Julu V"
  },
  {
    "id": "1211022008",
    "district_id": "121102",
    "label": "Pagagan Julu IV",
    "value": "Pagagan Julu IV"
  },
  {
    "id": "1211022009",
    "district_id": "121102",
    "label": "Pagagan Julu III",
    "value": "Pagagan Julu III"
  },
  {
    "id": "1211022010",
    "district_id": "121102",
    "label": "Pagagan Julu II",
    "value": "Pagagan Julu II"
  },
  {
    "id": "1211022014",
    "district_id": "121102",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1211022015",
    "district_id": "121102",
    "label": "Dolok Tolong",
    "value": "Dolok Tolong"
  },
  {
    "id": "1211022017",
    "district_id": "121102",
    "label": "Pargambiran",
    "value": "Pargambiran"
  },
  {
    "id": "1211022018",
    "district_id": "121102",
    "label": "Perjuangan",
    "value": "Perjuangan"
  },
  {
    "id": "1211022020",
    "district_id": "121102",
    "label": "Kuta Gugung",
    "value": "Kuta Gugung"
  },
  {
    "id": "1211022024",
    "district_id": "121102",
    "label": "Sileuh-leuh Parsaoran",
    "value": "Sileuh-leuh Parsaoran"
  },
  {
    "id": "1211022025",
    "district_id": "121102",
    "label": "Barisan Nauli",
    "value": "Barisan Nauli"
  },
  {
    "id": "1211022026",
    "district_id": "121102",
    "label": "Pangguruan",
    "value": "Pangguruan"
  },
  {
    "id": "1211022027",
    "district_id": "121102",
    "label": "Tanjung Beringin I",
    "value": "Tanjung Beringin I"
  },
  {
    "id": "1211032001",
    "district_id": "121103",
    "label": "Sumbul Tengah",
    "value": "Sumbul Tengah"
  },
  {
    "id": "1211032002",
    "district_id": "121103",
    "label": "Ujung Teran",
    "value": "Ujung Teran"
  },
  {
    "id": "1211032003",
    "district_id": "121103",
    "label": "Juma Gerat",
    "value": "Juma Gerat"
  },
  {
    "id": "1211032004",
    "district_id": "121103",
    "label": "Sarintonu",
    "value": "Sarintonu"
  },
  {
    "id": "1211032005",
    "district_id": "121103",
    "label": "Tigalingga",
    "value": "Tigalingga"
  },
  {
    "id": "1211032006",
    "district_id": "121103",
    "label": "Palding",
    "value": "Palding"
  },
  {
    "id": "1211032007",
    "district_id": "121103",
    "label": "Lau Bagot",
    "value": "Lau Bagot"
  },
  {
    "id": "1211032008",
    "district_id": "121103",
    "label": "Bertungen Julu",
    "value": "Bertungen Julu"
  },
  {
    "id": "1211032009",
    "district_id": "121103",
    "label": "Lau Molgap",
    "value": "Lau Molgap"
  },
  {
    "id": "1211032010",
    "district_id": "121103",
    "label": "Lau Pak-Pak",
    "value": "Lau Pak-Pak"
  },
  {
    "id": "1211032012",
    "district_id": "121103",
    "label": "Sukandebi",
    "value": "Sukandebi"
  },
  {
    "id": "1211032013",
    "district_id": "121103",
    "label": "Lau Mil",
    "value": "Lau Mil"
  },
  {
    "id": "1211032014",
    "district_id": "121103",
    "label": "Lau Sireme",
    "value": "Lau Sireme"
  },
  {
    "id": "1211032019",
    "district_id": "121103",
    "label": "Polding Jaya Sumbul",
    "value": "Polding Jaya Sumbul"
  },
  {
    "id": "1211042001",
    "district_id": "121104",
    "label": "Sinampang",
    "value": "Sinampang"
  },
  {
    "id": "1211042002",
    "district_id": "121104",
    "label": "Sihorbo",
    "value": "Sihorbo"
  },
  {
    "id": "1211042003",
    "district_id": "121104",
    "label": "Soban",
    "value": "Soban"
  },
  {
    "id": "1211042004",
    "district_id": "121104",
    "label": "Adian Nangka",
    "value": "Adian Nangka"
  },
  {
    "id": "1211042005",
    "district_id": "121104",
    "label": "Bunturaja",
    "value": "Bunturaja"
  },
  {
    "id": "1211042006",
    "district_id": "121104",
    "label": "Juma Siulok",
    "value": "Juma Siulok"
  },
  {
    "id": "1211042007",
    "district_id": "121104",
    "label": "Juma Teguh",
    "value": "Juma Teguh"
  },
  {
    "id": "1211042008",
    "district_id": "121104",
    "label": "Sosor Lontung",
    "value": "Sosor Lontung"
  },
  {
    "id": "1211042009",
    "district_id": "121104",
    "label": "Huta Imbaru",
    "value": "Huta Imbaru"
  },
  {
    "id": "1211042010",
    "district_id": "121104",
    "label": "Jumantuang",
    "value": "Jumantuang"
  },
  {
    "id": "1211042011",
    "district_id": "121104",
    "label": "Gomit",
    "value": "Gomit"
  },
  {
    "id": "1211042012",
    "district_id": "121104",
    "label": "Adian Gupa",
    "value": "Adian Gupa"
  },
  {
    "id": "1211042013",
    "district_id": "121104",
    "label": "Maju",
    "value": "Maju"
  },
  {
    "id": "1211051014",
    "district_id": "121105",
    "label": "Parongil",
    "value": "Parongil"
  },
  {
    "id": "1211052008",
    "district_id": "121105",
    "label": "Palipi",
    "value": "Palipi"
  },
  {
    "id": "1211052010",
    "district_id": "121105",
    "label": "Longkotan",
    "value": "Longkotan"
  },
  {
    "id": "1211052011",
    "district_id": "121105",
    "label": "Bongkaras",
    "value": "Bongkaras"
  },
  {
    "id": "1211052012",
    "district_id": "121105",
    "label": "Tungtung Batu",
    "value": "Tungtung Batu"
  },
  {
    "id": "1211052013",
    "district_id": "121105",
    "label": "Polling Anak-Anak",
    "value": "Polling Anak-Anak"
  },
  {
    "id": "1211052015",
    "district_id": "121105",
    "label": "Siratah",
    "value": "Siratah"
  },
  {
    "id": "1211052016",
    "district_id": "121105",
    "label": "Uruk Mblin",
    "value": "Uruk Mblin"
  },
  {
    "id": "1211052017",
    "district_id": "121105",
    "label": "Siboras",
    "value": "Siboras"
  },
  {
    "id": "1211052018",
    "district_id": "121105",
    "label": "Bonian",
    "value": "Bonian"
  },
  {
    "id": "1211052019",
    "district_id": "121105",
    "label": "Sumbari",
    "value": "Sumbari"
  },
  {
    "id": "1211052020",
    "district_id": "121105",
    "label": "Bakal Gajah",
    "value": "Bakal Gajah"
  },
  {
    "id": "1211052021",
    "district_id": "121105",
    "label": "Lae Panginuman",
    "value": "Lae Panginuman"
  },
  {
    "id": "1211052022",
    "district_id": "121105",
    "label": "Lae Ambat",
    "value": "Lae Ambat"
  },
  {
    "id": "1211052023",
    "district_id": "121105",
    "label": "Lae Rambong",
    "value": "Lae Rambong"
  },
  {
    "id": "1211052024",
    "district_id": "121105",
    "label": "Lae Pangaroan",
    "value": "Lae Pangaroan"
  },
  {
    "id": "1211062001",
    "district_id": "121106",
    "label": "Sukadame",
    "value": "Sukadame"
  },
  {
    "id": "1211062002",
    "district_id": "121106",
    "label": "Gunung Tua",
    "value": "Gunung Tua"
  },
  {
    "id": "1211062003",
    "district_id": "121106",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "1211062004",
    "district_id": "121106",
    "label": "Lau Primbon",
    "value": "Lau Primbon"
  },
  {
    "id": "1211062005",
    "district_id": "121106",
    "label": "Kuta Gamber",
    "value": "Kuta Gamber"
  },
  {
    "id": "1211062006",
    "district_id": "121106",
    "label": "Kempawa",
    "value": "Kempawa"
  },
  {
    "id": "1211062007",
    "district_id": "121106",
    "label": "Tanah Pinem",
    "value": "Tanah Pinem"
  },
  {
    "id": "1211062008",
    "district_id": "121106",
    "label": "Pamah",
    "value": "Pamah"
  },
  {
    "id": "1211062009",
    "district_id": "121106",
    "label": "Kuta Buluh",
    "value": "Kuta Buluh"
  },
  {
    "id": "1211062010",
    "district_id": "121106",
    "label": "Pasir Tengah",
    "value": "Pasir Tengah"
  },
  {
    "id": "1211062011",
    "district_id": "121106",
    "label": "Renun",
    "value": "Renun"
  },
  {
    "id": "1211062012",
    "district_id": "121106",
    "label": "Lau Tawar",
    "value": "Lau Tawar"
  },
  {
    "id": "1211062013",
    "district_id": "121106",
    "label": "Balandua",
    "value": "Balandua"
  },
  {
    "id": "1211062014",
    "district_id": "121106",
    "label": "Mangan Molih",
    "value": "Mangan Molih"
  },
  {
    "id": "1211062015",
    "district_id": "121106",
    "label": "Liang Jering",
    "value": "Liang Jering"
  },
  {
    "id": "1211062016",
    "district_id": "121106",
    "label": "Alur Subur",
    "value": "Alur Subur"
  },
  {
    "id": "1211062017",
    "district_id": "121106",
    "label": "Lau Njuhar I",
    "value": "Lau Njuhar I"
  },
  {
    "id": "1211062018",
    "district_id": "121106",
    "label": "Pasir Mbelang",
    "value": "Pasir Mbelang"
  },
  {
    "id": "1211062019",
    "district_id": "121106",
    "label": "Sinar Pagi",
    "value": "Sinar Pagi"
  },
  {
    "id": "1211072001",
    "district_id": "121107",
    "label": "Tambahan",
    "value": "Tambahan"
  },
  {
    "id": "1211072002",
    "district_id": "121107",
    "label": "Kuta Tengah",
    "value": "Kuta Tengah"
  },
  {
    "id": "1211072003",
    "district_id": "121107",
    "label": "Lae Nuaha",
    "value": "Lae Nuaha"
  },
  {
    "id": "1211072004",
    "district_id": "121107",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "1211072005",
    "district_id": "121107",
    "label": "Gunung Meriah",
    "value": "Gunung Meriah"
  },
  {
    "id": "1211072006",
    "district_id": "121107",
    "label": "Silumboyah",
    "value": "Silumboyah"
  },
  {
    "id": "1211072007",
    "district_id": "121107",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "1211072008",
    "district_id": "121107",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1211072009",
    "district_id": "121107",
    "label": "Pangaribuan",
    "value": "Pangaribuan"
  },
  {
    "id": "1211072010",
    "district_id": "121107",
    "label": "Sigambir-gambir",
    "value": "Sigambir-gambir"
  },
  {
    "id": "1211072011",
    "district_id": "121107",
    "label": "Bakal Julu",
    "value": "Bakal Julu"
  },
  {
    "id": "1211072012",
    "district_id": "121107",
    "label": "Sipoltong",
    "value": "Sipoltong"
  },
  {
    "id": "1211082001",
    "district_id": "121108",
    "label": "Lae Itam",
    "value": "Lae Itam"
  },
  {
    "id": "1211082002",
    "district_id": "121108",
    "label": "Lae Luhung",
    "value": "Lae Luhung"
  },
  {
    "id": "1211082003",
    "district_id": "121108",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1211082004",
    "district_id": "121108",
    "label": "Lae Markelang",
    "value": "Lae Markelang"
  },
  {
    "id": "1211082005",
    "district_id": "121108",
    "label": "Jambur Indonesia",
    "value": "Jambur Indonesia"
  },
  {
    "id": "1211082006",
    "district_id": "121108",
    "label": "Simungun",
    "value": "Simungun"
  },
  {
    "id": "1211082007",
    "district_id": "121108",
    "label": "Janji",
    "value": "Janji"
  },
  {
    "id": "1211082008",
    "district_id": "121108",
    "label": "Sopo Butar",
    "value": "Sopo Butar"
  },
  {
    "id": "1211082009",
    "district_id": "121108",
    "label": "Lae Sering",
    "value": "Lae Sering"
  },
  {
    "id": "1211082010",
    "district_id": "121108",
    "label": "Lae Haporas",
    "value": "Lae Haporas"
  },
  {
    "id": "1211092001",
    "district_id": "121109",
    "label": "Simanduma",
    "value": "Simanduma"
  },
  {
    "id": "1211092002",
    "district_id": "121109",
    "label": "Bandar Huta Usang",
    "value": "Bandar Huta Usang"
  },
  {
    "id": "1211092003",
    "district_id": "121109",
    "label": "Onan Lama",
    "value": "Onan Lama"
  },
  {
    "id": "1211092004",
    "district_id": "121109",
    "label": "Lingga Raja",
    "value": "Lingga Raja"
  },
  {
    "id": "1211092005",
    "district_id": "121109",
    "label": "Laksa",
    "value": "Laksa"
  },
  {
    "id": "1211092006",
    "district_id": "121109",
    "label": "Tanjung Saluksuk",
    "value": "Tanjung Saluksuk"
  },
  {
    "id": "1211092007",
    "district_id": "121109",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "1211092008",
    "district_id": "121109",
    "label": "Simartugan",
    "value": "Simartugan"
  },
  {
    "id": "1211092009",
    "district_id": "121109",
    "label": "Kuta Usang",
    "value": "Kuta Usang"
  },
  {
    "id": "1211092010",
    "district_id": "121109",
    "label": "Lingga Raja II",
    "value": "Lingga Raja II"
  },
  {
    "id": "1211092011",
    "district_id": "121109",
    "label": "Mbinanga",
    "value": "Mbinanga"
  },
  {
    "id": "1211092012",
    "district_id": "121109",
    "label": "Bukit Baru",
    "value": "Bukit Baru"
  },
  {
    "id": "1211092013",
    "district_id": "121109",
    "label": "Perrik Mbue",
    "value": "Perrik Mbue"
  },
  {
    "id": "1211102001",
    "district_id": "121110",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "1211102002",
    "district_id": "121110",
    "label": "Lae Hole",
    "value": "Lae Hole"
  },
  {
    "id": "1211102003",
    "district_id": "121110",
    "label": "Parbuluan IV",
    "value": "Parbuluan IV"
  },
  {
    "id": "1211102004",
    "district_id": "121110",
    "label": "Parbuluan III",
    "value": "Parbuluan III"
  },
  {
    "id": "1211102005",
    "district_id": "121110",
    "label": "Parbuluan II",
    "value": "Parbuluan II"
  },
  {
    "id": "1211102006",
    "district_id": "121110",
    "label": "Parbuluan I",
    "value": "Parbuluan I"
  },
  {
    "id": "1211102007",
    "district_id": "121110",
    "label": "Parbuluan V",
    "value": "Parbuluan V"
  },
  {
    "id": "1211102008",
    "district_id": "121110",
    "label": "Parbuluan VI",
    "value": "Parbuluan VI"
  },
  {
    "id": "1211102009",
    "district_id": "121110",
    "label": "Lae Hole I",
    "value": "Lae Hole I"
  },
  {
    "id": "1211102010",
    "district_id": "121110",
    "label": "Lae Hole II",
    "value": "Lae Hole II"
  },
  {
    "id": "1211102011",
    "district_id": "121110",
    "label": "Bangun I",
    "value": "Bangun I"
  },
  {
    "id": "1211112001",
    "district_id": "121111",
    "label": "Sumbul",
    "value": "Sumbul"
  },
  {
    "id": "1211112002",
    "district_id": "121111",
    "label": "Kentara",
    "value": "Kentara"
  },
  {
    "id": "1211112003",
    "district_id": "121111",
    "label": "Lae Parira",
    "value": "Lae Parira"
  },
  {
    "id": "1211112004",
    "district_id": "121111",
    "label": "Buluduri",
    "value": "Buluduri"
  },
  {
    "id": "1211112005",
    "district_id": "121111",
    "label": "Sempung Polling",
    "value": "Sempung Polling"
  },
  {
    "id": "1211112006",
    "district_id": "121111",
    "label": "Lumban Sihite",
    "value": "Lumban Sihite"
  },
  {
    "id": "1211112007",
    "district_id": "121111",
    "label": "Lumban Toruan",
    "value": "Lumban Toruan"
  },
  {
    "id": "1211112008",
    "district_id": "121111",
    "label": "Pandiangan",
    "value": "Pandiangan"
  },
  {
    "id": "1211112009",
    "district_id": "121111",
    "label": "Kaban Julu",
    "value": "Kaban Julu"
  },
  {
    "id": "1211122001",
    "district_id": "121112",
    "label": "Bukit Lau Kersik",
    "value": "Bukit Lau Kersik"
  },
  {
    "id": "1211122002",
    "district_id": "121112",
    "label": "Gunung Sitember",
    "value": "Gunung Sitember"
  },
  {
    "id": "1211122003",
    "district_id": "121112",
    "label": "Batu Gun Gun",
    "value": "Batu Gun Gun"
  },
  {
    "id": "1211122004",
    "district_id": "121112",
    "label": "Rante Besi",
    "value": "Rante Besi"
  },
  {
    "id": "1211122005",
    "district_id": "121112",
    "label": "Gundaling",
    "value": "Gundaling"
  },
  {
    "id": "1211122006",
    "district_id": "121112",
    "label": "Kendit Liang",
    "value": "Kendit Liang"
  },
  {
    "id": "1211122007",
    "district_id": "121112",
    "label": "Lau Lebah",
    "value": "Lau Lebah"
  },
  {
    "id": "1211122008",
    "district_id": "121112",
    "label": "Tupak Raja",
    "value": "Tupak Raja"
  },
  {
    "id": "1211132001",
    "district_id": "121113",
    "label": "Sambaliang",
    "value": "Sambaliang"
  },
  {
    "id": "1211132002",
    "district_id": "121113",
    "label": "Pasi",
    "value": "Pasi"
  },
  {
    "id": "1211132003",
    "district_id": "121113",
    "label": "Banjar Toba",
    "value": "Banjar Toba"
  },
  {
    "id": "1211132004",
    "district_id": "121113",
    "label": "Berampu",
    "value": "Berampu"
  },
  {
    "id": "1211132005",
    "district_id": "121113",
    "label": "Karing",
    "value": "Karing"
  },
  {
    "id": "1211142001",
    "district_id": "121114",
    "label": "Silalahi II",
    "value": "Silalahi II"
  },
  {
    "id": "1211142002",
    "district_id": "121114",
    "label": "Silalahi I",
    "value": "Silalahi I"
  },
  {
    "id": "1211142003",
    "district_id": "121114",
    "label": "Paropo",
    "value": "Paropo"
  },
  {
    "id": "1211142004",
    "district_id": "121114",
    "label": "Silalahi III",
    "value": "Silalahi III"
  },
  {
    "id": "1211142005",
    "district_id": "121114",
    "label": "Paropo I",
    "value": "Paropo I"
  },
  {
    "id": "1211151004",
    "district_id": "121115",
    "label": "Panji Dabutar",
    "value": "Panji Dabutar"
  },
  {
    "id": "1211152001",
    "district_id": "121115",
    "label": "Sitinjo",
    "value": "Sitinjo"
  },
  {
    "id": "1211152002",
    "district_id": "121115",
    "label": "Sitinjo I",
    "value": "Sitinjo I"
  },
  {
    "id": "1211152003",
    "district_id": "121115",
    "label": "Sitinjo II",
    "value": "Sitinjo II"
  },
  {
    "id": "1212011034",
    "district_id": "121201",
    "label": "Balige I",
    "value": "Balige I"
  },
  {
    "id": "1212011035",
    "district_id": "121201",
    "label": "Balige III",
    "value": "Balige III"
  },
  {
    "id": "1212011036",
    "district_id": "121201",
    "label": "Pardede Onan",
    "value": "Pardede Onan"
  },
  {
    "id": "1212011037",
    "district_id": "121201",
    "label": "Sangkar Nihuta",
    "value": "Sangkar Nihuta"
  },
  {
    "id": "1212011038",
    "district_id": "121201",
    "label": "Lumban Dolok Hauma Bange",
    "value": "Lumban Dolok Hauma Bange"
  },
  {
    "id": "1212011039",
    "district_id": "121201",
    "label": "Napitupulu Bagasan",
    "value": "Napitupulu Bagasan"
  },
  {
    "id": "1212012003",
    "district_id": "121201",
    "label": "Hutanamora",
    "value": "Hutanamora"
  },
  {
    "id": "1212012004",
    "district_id": "121201",
    "label": "Hutagaol Peatalun",
    "value": "Hutagaol Peatalun"
  },
  {
    "id": "1212012005",
    "district_id": "121201",
    "label": "Hinalang Bagasan",
    "value": "Hinalang Bagasan"
  },
  {
    "id": "1212012006",
    "district_id": "121201",
    "label": "Matio",
    "value": "Matio"
  },
  {
    "id": "1212012007",
    "district_id": "121201",
    "label": "Lumban Pea",
    "value": "Lumban Pea"
  },
  {
    "id": "1212012008",
    "district_id": "121201",
    "label": "Hutabulu Mejan",
    "value": "Hutabulu Mejan"
  },
  {
    "id": "1212012010",
    "district_id": "121201",
    "label": "Lumban Gaol",
    "value": "Lumban Gaol"
  },
  {
    "id": "1212012011",
    "district_id": "121201",
    "label": "Parsuratan",
    "value": "Parsuratan"
  },
  {
    "id": "1212012012",
    "district_id": "121201",
    "label": "Baruara",
    "value": "Baruara"
  },
  {
    "id": "1212012013",
    "district_id": "121201",
    "label": "Aek Bolon Julu",
    "value": "Aek Bolon Julu"
  },
  {
    "id": "1212012014",
    "district_id": "121201",
    "label": "Sibolahotang SAS",
    "value": "Sibolahotang SAS"
  },
  {
    "id": "1212012015",
    "district_id": "121201",
    "label": "Lumban Bulbul",
    "value": "Lumban Bulbul"
  },
  {
    "id": "1212012016",
    "district_id": "121201",
    "label": "Sianipar Sihailhail",
    "value": "Sianipar Sihailhail"
  },
  {
    "id": "1212012017",
    "district_id": "121201",
    "label": "Silalahi Pagar Batu",
    "value": "Silalahi Pagar Batu"
  },
  {
    "id": "1212012018",
    "district_id": "121201",
    "label": "Lumban Silintong",
    "value": "Lumban Silintong"
  },
  {
    "id": "1212012019",
    "district_id": "121201",
    "label": "Saribu Raja Janji Maria",
    "value": "Saribu Raja Janji Maria"
  },
  {
    "id": "1212012022",
    "district_id": "121201",
    "label": "Longat",
    "value": "Longat"
  },
  {
    "id": "1212012023",
    "district_id": "121201",
    "label": "Balige II",
    "value": "Balige II"
  },
  {
    "id": "1212012024",
    "district_id": "121201",
    "label": "Aek Bolon Jae",
    "value": "Aek Bolon Jae"
  },
  {
    "id": "1212012025",
    "district_id": "121201",
    "label": "Lumban Gorat",
    "value": "Lumban Gorat"
  },
  {
    "id": "1212012026",
    "district_id": "121201",
    "label": "Sibuntuon",
    "value": "Sibuntuon"
  },
  {
    "id": "1212012027",
    "district_id": "121201",
    "label": "Siboruon",
    "value": "Siboruon"
  },
  {
    "id": "1212012028",
    "district_id": "121201",
    "label": "Paindoan",
    "value": "Paindoan"
  },
  {
    "id": "1212012029",
    "district_id": "121201",
    "label": "Bonan Dolok I",
    "value": "Bonan Dolok I"
  },
  {
    "id": "1212012030",
    "district_id": "121201",
    "label": "Bonan Dolok II",
    "value": "Bonan Dolok II"
  },
  {
    "id": "1212012031",
    "district_id": "121201",
    "label": "Bonan Dolok III",
    "value": "Bonan Dolok III"
  },
  {
    "id": "1212012032",
    "district_id": "121201",
    "label": "Huta Dame",
    "value": "Huta Dame"
  },
  {
    "id": "1212012040",
    "district_id": "121201",
    "label": "Lumban Pea Timur",
    "value": "Lumban Pea Timur"
  },
  {
    "id": "1212012041",
    "district_id": "121201",
    "label": "Tambunan Sunge",
    "value": "Tambunan Sunge"
  },
  {
    "id": "1212021001",
    "district_id": "121202",
    "label": "Pasar Laguboti",
    "value": "Pasar Laguboti"
  },
  {
    "id": "1212022002",
    "district_id": "121202",
    "label": "Sibuea",
    "value": "Sibuea"
  },
  {
    "id": "1212022003",
    "district_id": "121202",
    "label": "Sintong Marnipi",
    "value": "Sintong Marnipi"
  },
  {
    "id": "1212022004",
    "district_id": "121202",
    "label": "Sibarani Nasampulu/Namungk",
    "value": "Sibarani Nasampulu/Namungk"
  },
  {
    "id": "1212022005",
    "district_id": "121202",
    "label": "Aruan",
    "value": "Aruan"
  },
  {
    "id": "1212022006",
    "district_id": "121202",
    "label": "Sidulang",
    "value": "Sidulang"
  },
  {
    "id": "1212022007",
    "district_id": "121202",
    "label": "Ujung Tanduk",
    "value": "Ujung Tanduk"
  },
  {
    "id": "1212022008",
    "district_id": "121202",
    "label": "Haunatas II",
    "value": "Haunatas II"
  },
  {
    "id": "1212022009",
    "district_id": "121202",
    "label": "Pardomuan Nauli",
    "value": "Pardomuan Nauli"
  },
  {
    "id": "1212022010",
    "district_id": "121202",
    "label": "Sitangkola",
    "value": "Sitangkola"
  },
  {
    "id": "1212022011",
    "district_id": "121202",
    "label": "Tinggir Nipasir",
    "value": "Tinggir Nipasir"
  },
  {
    "id": "1212022012",
    "district_id": "121202",
    "label": "Haunatas I",
    "value": "Haunatas I"
  },
  {
    "id": "1212022013",
    "district_id": "121202",
    "label": "Pintu Bosi",
    "value": "Pintu Bosi"
  },
  {
    "id": "1212022014",
    "district_id": "121202",
    "label": "Simatibung",
    "value": "Simatibung"
  },
  {
    "id": "1212022015",
    "district_id": "121202",
    "label": "Lumban Binanga",
    "value": "Lumban Binanga"
  },
  {
    "id": "1212022016",
    "district_id": "121202",
    "label": "Ompu Raja Hutapea",
    "value": "Ompu Raja Hutapea"
  },
  {
    "id": "1212022017",
    "district_id": "121202",
    "label": "Gasaribu",
    "value": "Gasaribu"
  },
  {
    "id": "1212022018",
    "district_id": "121202",
    "label": "Pardinggaran",
    "value": "Pardinggaran"
  },
  {
    "id": "1212022019",
    "district_id": "121202",
    "label": "Sitoluama",
    "value": "Sitoluama"
  },
  {
    "id": "1212022020",
    "district_id": "121202",
    "label": "Lumban Bagasan",
    "value": "Lumban Bagasan"
  },
  {
    "id": "1212022021",
    "district_id": "121202",
    "label": "Siraja Gorat",
    "value": "Siraja Gorat"
  },
  {
    "id": "1212022022",
    "district_id": "121202",
    "label": "Ompu Raja Hutapea Timur",
    "value": "Ompu Raja Hutapea Timur"
  },
  {
    "id": "1212022023",
    "district_id": "121202",
    "label": "Ompu Raja Hatulian",
    "value": "Ompu Raja Hatulian"
  },
  {
    "id": "1212032001",
    "district_id": "121203",
    "label": "Pintu Batu",
    "value": "Pintu Batu"
  },
  {
    "id": "1212032002",
    "district_id": "121203",
    "label": "Sitorang",
    "value": "Sitorang"
  },
  {
    "id": "1212032003",
    "district_id": "121203",
    "label": "Parsambilan",
    "value": "Parsambilan"
  },
  {
    "id": "1212032004",
    "district_id": "121203",
    "label": "Huta Gur- Gur II",
    "value": "Huta Gur- Gur II"
  },
  {
    "id": "1212032005",
    "district_id": "121203",
    "label": "Silaen",
    "value": "Silaen"
  },
  {
    "id": "1212032006",
    "district_id": "121203",
    "label": "Ombur",
    "value": "Ombur"
  },
  {
    "id": "1212032008",
    "district_id": "121203",
    "label": "Natolutali",
    "value": "Natolutali"
  },
  {
    "id": "1212032009",
    "district_id": "121203",
    "label": "Dalihan Natolu",
    "value": "Dalihan Natolu"
  },
  {
    "id": "1212032010",
    "district_id": "121203",
    "label": "Sigodang Tua",
    "value": "Sigodang Tua"
  },
  {
    "id": "1212032014",
    "district_id": "121203",
    "label": "Sinta Dame",
    "value": "Sinta Dame"
  },
  {
    "id": "1212032015",
    "district_id": "121203",
    "label": "Hutanamora",
    "value": "Hutanamora"
  },
  {
    "id": "1212032017",
    "district_id": "121203",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1212032018",
    "district_id": "121203",
    "label": "Huta Gur-Gur I",
    "value": "Huta Gur-Gur I"
  },
  {
    "id": "1212032022",
    "district_id": "121203",
    "label": "Lumban Dolok",
    "value": "Lumban Dolok"
  },
  {
    "id": "1212032023",
    "district_id": "121203",
    "label": "Napitupulu",
    "value": "Napitupulu"
  },
  {
    "id": "1212032024",
    "district_id": "121203",
    "label": "Hutagaol Sihujur",
    "value": "Hutagaol Sihujur"
  },
  {
    "id": "1212032025",
    "district_id": "121203",
    "label": "Meranti Barat",
    "value": "Meranti Barat"
  },
  {
    "id": "1212032026",
    "district_id": "121203",
    "label": "Sibide Barat",
    "value": "Sibide Barat"
  },
  {
    "id": "1212032027",
    "district_id": "121203",
    "label": "Sibide",
    "value": "Sibide"
  },
  {
    "id": "1212032028",
    "district_id": "121203",
    "label": "Simanobak",
    "value": "Simanobak"
  },
  {
    "id": "1212032029",
    "district_id": "121203",
    "label": "Panindi",
    "value": "Panindi"
  },
  {
    "id": "1212032030",
    "district_id": "121203",
    "label": "Siringkiron",
    "value": "Siringkiron"
  },
  {
    "id": "1212032031",
    "district_id": "121203",
    "label": "Marbulang",
    "value": "Marbulang"
  },
  {
    "id": "1212041009",
    "district_id": "121204",
    "label": "Parsoburan Tengah",
    "value": "Parsoburan Tengah"
  },
  {
    "id": "1212042002",
    "district_id": "121204",
    "label": "Lumban Rau Selatan",
    "value": "Lumban Rau Selatan"
  },
  {
    "id": "1212042004",
    "district_id": "121204",
    "label": "Lumban Rau Barat",
    "value": "Lumban Rau Barat"
  },
  {
    "id": "1212042006",
    "district_id": "121204",
    "label": "Lumban Balik",
    "value": "Lumban Balik"
  },
  {
    "id": "1212042007",
    "district_id": "121204",
    "label": "Panamparan",
    "value": "Panamparan"
  },
  {
    "id": "1212042008",
    "district_id": "121204",
    "label": "Lumban Pinasa",
    "value": "Lumban Pinasa"
  },
  {
    "id": "1212042010",
    "district_id": "121204",
    "label": "Parsoburan Barat",
    "value": "Parsoburan Barat"
  },
  {
    "id": "1212042011",
    "district_id": "121204",
    "label": "Lumban Ruhap",
    "value": "Lumban Ruhap"
  },
  {
    "id": "1212042012",
    "district_id": "121204",
    "label": "Lumban Pea",
    "value": "Lumban Pea"
  },
  {
    "id": "1212042014",
    "district_id": "121204",
    "label": "Hitetano",
    "value": "Hitetano"
  },
  {
    "id": "1212042017",
    "district_id": "121204",
    "label": "Lobu Hole",
    "value": "Lobu Hole"
  },
  {
    "id": "1212042018",
    "district_id": "121204",
    "label": "Batu Nabolon",
    "value": "Batu Nabolon"
  },
  {
    "id": "1212042019",
    "district_id": "121204",
    "label": "Lumban Gaol",
    "value": "Lumban Gaol"
  },
  {
    "id": "1212042020",
    "district_id": "121204",
    "label": "Tornagodang",
    "value": "Tornagodang"
  },
  {
    "id": "1212042021",
    "district_id": "121204",
    "label": "Taon Marisi",
    "value": "Taon Marisi"
  },
  {
    "id": "1212042022",
    "district_id": "121204",
    "label": "Pagar Batu",
    "value": "Pagar Batu"
  },
  {
    "id": "1212042023",
    "district_id": "121204",
    "label": "Sibuntuon",
    "value": "Sibuntuon"
  },
  {
    "id": "1212042024",
    "district_id": "121204",
    "label": "Pararungan",
    "value": "Pararungan"
  },
  {
    "id": "1212042025",
    "district_id": "121204",
    "label": "Lumban Lintong",
    "value": "Lumban Lintong"
  },
  {
    "id": "1212042026",
    "district_id": "121204",
    "label": "Pangunjungan",
    "value": "Pangunjungan"
  },
  {
    "id": "1212042027",
    "district_id": "121204",
    "label": "Lumban Pinasa Saroba",
    "value": "Lumban Pinasa Saroba"
  },
  {
    "id": "1212042028",
    "district_id": "121204",
    "label": "Aek Ulok",
    "value": "Aek Ulok"
  },
  {
    "id": "1212052001",
    "district_id": "121205",
    "label": "Meranti Utara",
    "value": "Meranti Utara"
  },
  {
    "id": "1212052002",
    "district_id": "121205",
    "label": "Meranti Timur",
    "value": "Meranti Timur"
  },
  {
    "id": "1212052003",
    "district_id": "121205",
    "label": "Meranti Tengah",
    "value": "Meranti Tengah"
  },
  {
    "id": "1212052007",
    "district_id": "121205",
    "label": "Pintu Pohan",
    "value": "Pintu Pohan"
  },
  {
    "id": "1212052008",
    "district_id": "121205",
    "label": "Ambar Halim",
    "value": "Ambar Halim"
  },
  {
    "id": "1212052009",
    "district_id": "121205",
    "label": "Pintu Pohan Dolok",
    "value": "Pintu Pohan Dolok"
  },
  {
    "id": "1212052010",
    "district_id": "121205",
    "label": "Halado",
    "value": "Halado"
  },
  {
    "id": "1212062001",
    "district_id": "121206",
    "label": "Natumingka",
    "value": "Natumingka"
  },
  {
    "id": "1212062002",
    "district_id": "121206",
    "label": "Pangururan",
    "value": "Pangururan"
  },
  {
    "id": "1212062003",
    "district_id": "121206",
    "label": "Pardomuan Nauli",
    "value": "Pardomuan Nauli"
  },
  {
    "id": "1212062004",
    "district_id": "121206",
    "label": "Lintong",
    "value": "Lintong"
  },
  {
    "id": "1212062005",
    "district_id": "121206",
    "label": "Rianiate",
    "value": "Rianiate"
  },
  {
    "id": "1212062006",
    "district_id": "121206",
    "label": "Aek Unsim",
    "value": "Aek Unsim"
  },
  {
    "id": "1212062007",
    "district_id": "121206",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1212062008",
    "district_id": "121206",
    "label": "Janji Maria",
    "value": "Janji Maria"
  },
  {
    "id": "1212062009",
    "district_id": "121206",
    "label": "Pangururan II",
    "value": "Pangururan II"
  },
  {
    "id": "1212062010",
    "district_id": "121206",
    "label": "Pangururan III",
    "value": "Pangururan III"
  },
  {
    "id": "1212062011",
    "district_id": "121206",
    "label": "Hutagurgur",
    "value": "Hutagurgur"
  },
  {
    "id": "1212062012",
    "district_id": "121206",
    "label": "Simare",
    "value": "Simare"
  },
  {
    "id": "1212062013",
    "district_id": "121206",
    "label": "Lumban Sewa",
    "value": "Lumban Sewa"
  },
  {
    "id": "1212062014",
    "district_id": "121206",
    "label": "Riganjang",
    "value": "Riganjang"
  },
  {
    "id": "1212062015",
    "district_id": "121206",
    "label": "Pasar Borbor",
    "value": "Pasar Borbor"
  },
  {
    "id": "1212071037",
    "district_id": "121207",
    "label": "Pasar Porsea",
    "value": "Pasar Porsea"
  },
  {
    "id": "1212071038",
    "district_id": "121207",
    "label": "Patane III",
    "value": "Patane III"
  },
  {
    "id": "1212071039",
    "district_id": "121207",
    "label": "Parparean III",
    "value": "Parparean III"
  },
  {
    "id": "1212072001",
    "district_id": "121207",
    "label": "Patane IV",
    "value": "Patane IV"
  },
  {
    "id": "1212072003",
    "district_id": "121207",
    "label": "Lumban Gurning",
    "value": "Lumban Gurning"
  },
  {
    "id": "1212072004",
    "district_id": "121207",
    "label": "Amborgang",
    "value": "Amborgang"
  },
  {
    "id": "1212072005",
    "district_id": "121207",
    "label": "Nalela",
    "value": "Nalela"
  },
  {
    "id": "1212072007",
    "district_id": "121207",
    "label": "Patane II",
    "value": "Patane II"
  },
  {
    "id": "1212072022",
    "district_id": "121207",
    "label": "Gala Gala Pangkailan",
    "value": "Gala Gala Pangkailan"
  },
  {
    "id": "1212072023",
    "district_id": "121207",
    "label": "Silamosik I",
    "value": "Silamosik I"
  },
  {
    "id": "1212072030",
    "district_id": "121207",
    "label": "Parparean IV",
    "value": "Parparean IV"
  },
  {
    "id": "1212072031",
    "district_id": "121207",
    "label": "Parparean I",
    "value": "Parparean I"
  },
  {
    "id": "1212072033",
    "district_id": "121207",
    "label": "Parparean II",
    "value": "Parparean II"
  },
  {
    "id": "1212072036",
    "district_id": "121207",
    "label": "Patane I",
    "value": "Patane I"
  },
  {
    "id": "1212072040",
    "district_id": "121207",
    "label": "Patane V",
    "value": "Patane V"
  },
  {
    "id": "1212072041",
    "district_id": "121207",
    "label": "Simpang Sigura-Gura",
    "value": "Simpang Sigura-Gura"
  },
  {
    "id": "1212072042",
    "district_id": "121207",
    "label": "Raut Bosi",
    "value": "Raut Bosi"
  },
  {
    "id": "1212081009",
    "district_id": "121208",
    "label": "Parsaoran Ajibata",
    "value": "Parsaoran Ajibata"
  },
  {
    "id": "1212082001",
    "district_id": "121208",
    "label": "Sigapiton",
    "value": "Sigapiton"
  },
  {
    "id": "1212082002",
    "district_id": "121208",
    "label": "Pardomuan Ajibata",
    "value": "Pardomuan Ajibata"
  },
  {
    "id": "1212082003",
    "district_id": "121208",
    "label": "Pardamean Ajibata",
    "value": "Pardamean Ajibata"
  },
  {
    "id": "1212082004",
    "district_id": "121208",
    "label": "Motung",
    "value": "Motung"
  },
  {
    "id": "1212082005",
    "district_id": "121208",
    "label": "Horsik",
    "value": "Horsik"
  },
  {
    "id": "1212082006",
    "district_id": "121208",
    "label": "Sirungkungon",
    "value": "Sirungkungon"
  },
  {
    "id": "1212082007",
    "district_id": "121208",
    "label": "Parsaoran Sibisa",
    "value": "Parsaoran Sibisa"
  },
  {
    "id": "1212082008",
    "district_id": "121208",
    "label": "Pardamean Sibisa",
    "value": "Pardamean Sibisa"
  },
  {
    "id": "1212082010",
    "district_id": "121208",
    "label": "Pardomuan Motung",
    "value": "Pardomuan Motung"
  },
  {
    "id": "1212092002",
    "district_id": "121209",
    "label": "Lintong Julu",
    "value": "Lintong Julu"
  },
  {
    "id": "1212092003",
    "district_id": "121209",
    "label": "Hatinggian",
    "value": "Hatinggian"
  },
  {
    "id": "1212092004",
    "district_id": "121209",
    "label": "Sibaruang",
    "value": "Sibaruang"
  },
  {
    "id": "1212092005",
    "district_id": "121209",
    "label": "Jangga Toruan",
    "value": "Jangga Toruan"
  },
  {
    "id": "1212092006",
    "district_id": "121209",
    "label": "Sionggang Selatan",
    "value": "Sionggang Selatan"
  },
  {
    "id": "1212092007",
    "district_id": "121209",
    "label": "Pasar Lumban Julu",
    "value": "Pasar Lumban Julu"
  },
  {
    "id": "1212092008",
    "district_id": "121209",
    "label": "Sionggang Utara",
    "value": "Sionggang Utara"
  },
  {
    "id": "1212092014",
    "district_id": "121209",
    "label": "Sionggang Tengah",
    "value": "Sionggang Tengah"
  },
  {
    "id": "1212092015",
    "district_id": "121209",
    "label": "Jangga Dolok",
    "value": "Jangga Dolok"
  },
  {
    "id": "1212092016",
    "district_id": "121209",
    "label": "Hutanamora",
    "value": "Hutanamora"
  },
  {
    "id": "1212092017",
    "district_id": "121209",
    "label": "Jonggi Nihuta",
    "value": "Jonggi Nihuta"
  },
  {
    "id": "1212092018",
    "district_id": "121209",
    "label": "Aek Natolu Jaya",
    "value": "Aek Natolu Jaya"
  },
  {
    "id": "1212102001",
    "district_id": "121210",
    "label": "Dolok Saribu Janji Matogu",
    "value": "Dolok Saribu Janji Matogu"
  },
  {
    "id": "1212102002",
    "district_id": "121210",
    "label": "Dolok Nagodang",
    "value": "Dolok Nagodang"
  },
  {
    "id": "1212102003",
    "district_id": "121210",
    "label": "Lumban Binanga",
    "value": "Lumban Binanga"
  },
  {
    "id": "1212102004",
    "district_id": "121210",
    "label": "Partor Janji Matogu",
    "value": "Partor Janji Matogu"
  },
  {
    "id": "1212102005",
    "district_id": "121210",
    "label": "Parhabinsaran Janji Matogu",
    "value": "Parhabinsaran Janji Matogu"
  },
  {
    "id": "1212102006",
    "district_id": "121210",
    "label": "Parik",
    "value": "Parik"
  },
  {
    "id": "1212102007",
    "district_id": "121210",
    "label": "Sibuntuon",
    "value": "Sibuntuon"
  },
  {
    "id": "1212102008",
    "district_id": "121210",
    "label": "Sigaol Barat",
    "value": "Sigaol Barat"
  },
  {
    "id": "1212102009",
    "district_id": "121210",
    "label": "Marom",
    "value": "Marom"
  },
  {
    "id": "1212102010",
    "district_id": "121210",
    "label": "Sigaol Timur",
    "value": "Sigaol Timur"
  },
  {
    "id": "1212102012",
    "district_id": "121210",
    "label": "Siregar Aek Nalas",
    "value": "Siregar Aek Nalas"
  },
  {
    "id": "1212102014",
    "district_id": "121210",
    "label": "Lumban Holbung",
    "value": "Lumban Holbung"
  },
  {
    "id": "1212102015",
    "district_id": "121210",
    "label": "Parbagasan Janji Matogu",
    "value": "Parbagasan Janji Matogu"
  },
  {
    "id": "1212102016",
    "district_id": "121210",
    "label": "Partoruan Janji Matogu",
    "value": "Partoruan Janji Matogu"
  },
  {
    "id": "1212102017",
    "district_id": "121210",
    "label": "Lumban Nabolon",
    "value": "Lumban Nabolon"
  },
  {
    "id": "1212102018",
    "district_id": "121210",
    "label": "Sampuara",
    "value": "Sampuara"
  },
  {
    "id": "1212102019",
    "district_id": "121210",
    "label": "Dolok Saribu Lumban Nabolon",
    "value": "Dolok Saribu Lumban Nabolon"
  },
  {
    "id": "1212191007",
    "district_id": "121219",
    "label": "Sigumpar Dangsina",
    "value": "Sigumpar Dangsina"
  },
  {
    "id": "1212192001",
    "district_id": "121219",
    "label": "Marsangap",
    "value": "Marsangap"
  },
  {
    "id": "1212192002",
    "district_id": "121219",
    "label": "Nauli",
    "value": "Nauli"
  },
  {
    "id": "1212192003",
    "district_id": "121219",
    "label": "Situa-tua",
    "value": "Situa-tua"
  },
  {
    "id": "1212192004",
    "district_id": "121219",
    "label": "Sigumpar",
    "value": "Sigumpar"
  },
  {
    "id": "1212192005",
    "district_id": "121219",
    "label": "Maju",
    "value": "Maju"
  },
  {
    "id": "1212192006",
    "district_id": "121219",
    "label": "Dolok Jior",
    "value": "Dolok Jior"
  },
  {
    "id": "1212192008",
    "district_id": "121219",
    "label": "Sigumpar Julu",
    "value": "Sigumpar Julu"
  },
  {
    "id": "1212192009",
    "district_id": "121219",
    "label": "Sigumpar Barat",
    "value": "Sigumpar Barat"
  },
  {
    "id": "1212192010",
    "district_id": "121219",
    "label": "Banua Huta",
    "value": "Banua Huta"
  },
  {
    "id": "1212202001",
    "district_id": "121220",
    "label": "Narumonda I",
    "value": "Narumonda I"
  },
  {
    "id": "1212202002",
    "district_id": "121220",
    "label": "Narumonda II",
    "value": "Narumonda II"
  },
  {
    "id": "1212202003",
    "district_id": "121220",
    "label": "Narumonda III",
    "value": "Narumonda III"
  },
  {
    "id": "1212202004",
    "district_id": "121220",
    "label": "Narumonda IV",
    "value": "Narumonda IV"
  },
  {
    "id": "1212202005",
    "district_id": "121220",
    "label": "Narumonda V",
    "value": "Narumonda V"
  },
  {
    "id": "1212202006",
    "district_id": "121220",
    "label": "Narumonda VI",
    "value": "Narumonda VI"
  },
  {
    "id": "1212202007",
    "district_id": "121220",
    "label": "Narumonda VII",
    "value": "Narumonda VII"
  },
  {
    "id": "1212202008",
    "district_id": "121220",
    "label": "Narumonda VIII",
    "value": "Narumonda VIII"
  },
  {
    "id": "1212202009",
    "district_id": "121220",
    "label": "Siantar Sitio Tio",
    "value": "Siantar Sitio Tio"
  },
  {
    "id": "1212202010",
    "district_id": "121220",
    "label": "Siantar Dangsina",
    "value": "Siantar Dangsina"
  },
  {
    "id": "1212202011",
    "district_id": "121220",
    "label": "Siantar Tonga-Tonga I",
    "value": "Siantar Tonga-Tonga I"
  },
  {
    "id": "1212202012",
    "district_id": "121220",
    "label": "Siantar Tonga-Tonga II",
    "value": "Siantar Tonga-Tonga II"
  },
  {
    "id": "1212202013",
    "district_id": "121220",
    "label": "Siantar Sigordang",
    "value": "Siantar Sigordang"
  },
  {
    "id": "1212202014",
    "district_id": "121220",
    "label": "Siantar Tonga-Tonga III",
    "value": "Siantar Tonga-Tonga III"
  },
  {
    "id": "1212212001",
    "district_id": "121221",
    "label": "Lumban Rau Tengah",
    "value": "Lumban Rau Tengah"
  },
  {
    "id": "1212212002",
    "district_id": "121221",
    "label": "Lumban Rau Timur",
    "value": "Lumban Rau Timur"
  },
  {
    "id": "1212212003",
    "district_id": "121221",
    "label": "Lumban Rau Utara",
    "value": "Lumban Rau Utara"
  },
  {
    "id": "1212212004",
    "district_id": "121221",
    "label": "Sipagabu",
    "value": "Sipagabu"
  },
  {
    "id": "1212212005",
    "district_id": "121221",
    "label": "Napajoring",
    "value": "Napajoring"
  },
  {
    "id": "1212212006",
    "district_id": "121221",
    "label": "Liat Tondung",
    "value": "Liat Tondung"
  },
  {
    "id": "1212212007",
    "district_id": "121221",
    "label": "Batu Manumpak",
    "value": "Batu Manumpak"
  },
  {
    "id": "1212212008",
    "district_id": "121221",
    "label": "Lumban Rau Tenggara",
    "value": "Lumban Rau Tenggara"
  },
  {
    "id": "1212212009",
    "district_id": "121221",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1212212010",
    "district_id": "121221",
    "label": "Siantarasa",
    "value": "Siantarasa"
  },
  {
    "id": "1212222001",
    "district_id": "121222",
    "label": "Lintong Nihuta",
    "value": "Lintong Nihuta"
  },
  {
    "id": "1212222002",
    "district_id": "121222",
    "label": "Tarabunga",
    "value": "Tarabunga"
  },
  {
    "id": "1212222003",
    "district_id": "121222",
    "label": "Meat",
    "value": "Meat"
  },
  {
    "id": "1212222004",
    "district_id": "121222",
    "label": "Gurgur Aek Raja",
    "value": "Gurgur Aek Raja"
  },
  {
    "id": "1212222005",
    "district_id": "121222",
    "label": "Tangga Batu Barat",
    "value": "Tangga Batu Barat"
  },
  {
    "id": "1212222006",
    "district_id": "121222",
    "label": "Tangga Batu Timur",
    "value": "Tangga Batu Timur"
  },
  {
    "id": "1212232001",
    "district_id": "121223",
    "label": "Sihiong",
    "value": "Sihiong"
  },
  {
    "id": "1212232002",
    "district_id": "121223",
    "label": "Sinar Sabungan",
    "value": "Sinar Sabungan"
  },
  {
    "id": "1212232003",
    "district_id": "121223",
    "label": "Lumban Lobu",
    "value": "Lumban Lobu"
  },
  {
    "id": "1212232004",
    "district_id": "121223",
    "label": "Harungguan",
    "value": "Harungguan"
  },
  {
    "id": "1212232005",
    "district_id": "121223",
    "label": "Naga Timbul",
    "value": "Naga Timbul"
  },
  {
    "id": "1212232006",
    "district_id": "121223",
    "label": "Sibadihon",
    "value": "Sibadihon"
  },
  {
    "id": "1212232007",
    "district_id": "121223",
    "label": "Silamosik II",
    "value": "Silamosik II"
  },
  {
    "id": "1212232011",
    "district_id": "121223",
    "label": "Partoruan Lumban Lobu",
    "value": "Partoruan Lumban Lobu"
  },
  {
    "id": "1212232012",
    "district_id": "121223",
    "label": "Pardolok Lumban Lobu",
    "value": "Pardolok Lumban Lobu"
  },
  {
    "id": "1212232013",
    "district_id": "121223",
    "label": "Silombu",
    "value": "Silombu"
  },
  {
    "id": "1212232014",
    "district_id": "121223",
    "label": "Lumban Sangkalan",
    "value": "Lumban Sangkalan"
  },
  {
    "id": "1212232015",
    "district_id": "121223",
    "label": "Nagatimbul Timur",
    "value": "Nagatimbul Timur"
  },
  {
    "id": "1212242001",
    "district_id": "121224",
    "label": "Siantar Utara",
    "value": "Siantar Utara"
  },
  {
    "id": "1212242002",
    "district_id": "121224",
    "label": "Lumban Sitorus",
    "value": "Lumban Sitorus"
  },
  {
    "id": "1212242003",
    "district_id": "121224",
    "label": "Banjar Ganjang",
    "value": "Banjar Ganjang"
  },
  {
    "id": "1212242004",
    "district_id": "121224",
    "label": "Tangga Batu I",
    "value": "Tangga Batu I"
  },
  {
    "id": "1212242005",
    "district_id": "121224",
    "label": "Tangga Batu II",
    "value": "Tangga Batu II"
  },
  {
    "id": "1212242006",
    "district_id": "121224",
    "label": "Pangombusan",
    "value": "Pangombusan"
  },
  {
    "id": "1212242007",
    "district_id": "121224",
    "label": "Jonggi Manulus",
    "value": "Jonggi Manulus"
  },
  {
    "id": "1212242008",
    "district_id": "121224",
    "label": "Lumban Huala",
    "value": "Lumban Huala"
  },
  {
    "id": "1212242009",
    "district_id": "121224",
    "label": "Dolok Nauli",
    "value": "Dolok Nauli"
  },
  {
    "id": "1212242010",
    "district_id": "121224",
    "label": "Bius Gu Barat",
    "value": "Bius Gu Barat"
  },
  {
    "id": "1212242011",
    "district_id": "121224",
    "label": "Lumban Manurung",
    "value": "Lumban Manurung"
  },
  {
    "id": "1213011011",
    "district_id": "121301",
    "label": "Kota Siantar",
    "value": "Kota Siantar"
  },
  {
    "id": "1213011014",
    "district_id": "121301",
    "label": "Kayu Jati",
    "value": "Kayu Jati"
  },
  {
    "id": "1213011022",
    "district_id": "121301",
    "label": "Pasar Hilir",
    "value": "Pasar Hilir"
  },
  {
    "id": "1213011023",
    "district_id": "121301",
    "label": "Pidoli Dolok",
    "value": "Pidoli Dolok"
  },
  {
    "id": "1213011031",
    "district_id": "121301",
    "label": "Panyabungan II",
    "value": "Panyabungan II"
  },
  {
    "id": "1213011032",
    "district_id": "121301",
    "label": "Panyabungan III",
    "value": "Panyabungan III"
  },
  {
    "id": "1213011033",
    "district_id": "121301",
    "label": "Panyabungan I",
    "value": "Panyabungan I"
  },
  {
    "id": "1213011035",
    "district_id": "121301",
    "label": "Sipolu-Polu",
    "value": "Sipolu-Polu"
  },
  {
    "id": "1213011036",
    "district_id": "121301",
    "label": "Dalan Lidang",
    "value": "Dalan Lidang"
  },
  {
    "id": "1213012001",
    "district_id": "121301",
    "label": "Adian Jior",
    "value": "Adian Jior"
  },
  {
    "id": "1213012002",
    "district_id": "121301",
    "label": "Aek Banir",
    "value": "Aek Banir"
  },
  {
    "id": "1213012003",
    "district_id": "121301",
    "label": "Aek Mata",
    "value": "Aek Mata"
  },
  {
    "id": "1213012004",
    "district_id": "121301",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1213012005",
    "district_id": "121301",
    "label": "Gunung Barani",
    "value": "Gunung Barani"
  },
  {
    "id": "1213012006",
    "district_id": "121301",
    "label": "Gunung Manaon",
    "value": "Gunung Manaon"
  },
  {
    "id": "1213012007",
    "district_id": "121301",
    "label": "Gunung Tua Jae",
    "value": "Gunung Tua Jae"
  },
  {
    "id": "1213012008",
    "district_id": "121301",
    "label": "Gunung Tua Julu",
    "value": "Gunung Tua Julu"
  },
  {
    "id": "1213012009",
    "district_id": "121301",
    "label": "Gunung Tua Tonga",
    "value": "Gunung Tua Tonga"
  },
  {
    "id": "1213012010",
    "district_id": "121301",
    "label": "Huta Lombang Lubis",
    "value": "Huta Lombang Lubis"
  },
  {
    "id": "1213012012",
    "district_id": "121301",
    "label": "Ipar Bondar",
    "value": "Ipar Bondar"
  },
  {
    "id": "1213012013",
    "district_id": "121301",
    "label": "Kampung Padang",
    "value": "Kampung Padang"
  },
  {
    "id": "1213012015",
    "district_id": "121301",
    "label": "Lumban Pasir",
    "value": "Lumban Pasir"
  },
  {
    "id": "1213012016",
    "district_id": "121301",
    "label": "Manyabar",
    "value": "Manyabar"
  },
  {
    "id": "1213012017",
    "district_id": "121301",
    "label": "Pagaran Tonga",
    "value": "Pagaran Tonga"
  },
  {
    "id": "1213012018",
    "district_id": "121301",
    "label": "Panyabungan Jae",
    "value": "Panyabungan Jae"
  },
  {
    "id": "1213012019",
    "district_id": "121301",
    "label": "Panyabungan Julu",
    "value": "Panyabungan Julu"
  },
  {
    "id": "1213012020",
    "district_id": "121301",
    "label": "Panyabungan Tonga",
    "value": "Panyabungan Tonga"
  },
  {
    "id": "1213012021",
    "district_id": "121301",
    "label": "Perbangunan",
    "value": "Perbangunan"
  },
  {
    "id": "1213012024",
    "district_id": "121301",
    "label": "Pidoli Lombang",
    "value": "Pidoli Lombang"
  },
  {
    "id": "1213012025",
    "district_id": "121301",
    "label": "Salam Bue",
    "value": "Salam Bue"
  },
  {
    "id": "1213012026",
    "district_id": "121301",
    "label": "Sarak Matua",
    "value": "Sarak Matua"
  },
  {
    "id": "1213012027",
    "district_id": "121301",
    "label": "Sigalapang Julu",
    "value": "Sigalapang Julu"
  },
  {
    "id": "1213012028",
    "district_id": "121301",
    "label": "Siobon Julu",
    "value": "Siobon Julu"
  },
  {
    "id": "1213012029",
    "district_id": "121301",
    "label": "Sipapaga",
    "value": "Sipapaga"
  },
  {
    "id": "1213012030",
    "district_id": "121301",
    "label": "Sopobatu",
    "value": "Sopobatu"
  },
  {
    "id": "1213012037",
    "district_id": "121301",
    "label": "Siobon Jae",
    "value": "Siobon Jae"
  },
  {
    "id": "1213012038",
    "district_id": "121301",
    "label": "Panggorengan",
    "value": "Panggorengan"
  },
  {
    "id": "1213012039",
    "district_id": "121301",
    "label": "Saba Jambu",
    "value": "Saba Jambu"
  },
  {
    "id": "1213012040",
    "district_id": "121301",
    "label": "Manyabar Jae",
    "value": "Manyabar Jae"
  },
  {
    "id": "1213021012",
    "district_id": "121302",
    "label": "Mompang Jae",
    "value": "Mompang Jae"
  },
  {
    "id": "1213022002",
    "district_id": "121302",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1213022007",
    "district_id": "121302",
    "label": "Huta damai",
    "value": "Huta damai"
  },
  {
    "id": "1213022008",
    "district_id": "121302",
    "label": "Jambur Padang Matinggi",
    "value": "Jambur Padang Matinggi"
  },
  {
    "id": "1213022009",
    "district_id": "121302",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1213022010",
    "district_id": "121302",
    "label": "Mompang Julu",
    "value": "Mompang Julu"
  },
  {
    "id": "1213022014",
    "district_id": "121302",
    "label": "Rumbio",
    "value": "Rumbio"
  },
  {
    "id": "1213022015",
    "district_id": "121302",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "1213022017",
    "district_id": "121302",
    "label": "Sopo Sorik",
    "value": "Sopo Sorik"
  },
  {
    "id": "1213022018",
    "district_id": "121302",
    "label": "Simanondong",
    "value": "Simanondong"
  },
  {
    "id": "1213022019",
    "district_id": "121302",
    "label": "Torbanua Raja",
    "value": "Torbanua Raja"
  },
  {
    "id": "1213022020",
    "district_id": "121302",
    "label": "Tanjung Mompang",
    "value": "Tanjung Mompang"
  },
  {
    "id": "1213031002",
    "district_id": "121303",
    "label": "Gunung Baringin",
    "value": "Gunung Baringin"
  },
  {
    "id": "1213032001",
    "district_id": "121303",
    "label": "Aek Nabara",
    "value": "Aek Nabara"
  },
  {
    "id": "1213032003",
    "district_id": "121303",
    "label": "Hutaimbaru",
    "value": "Hutaimbaru"
  },
  {
    "id": "1213032004",
    "district_id": "121303",
    "label": "Huta Bangun",
    "value": "Huta Bangun"
  },
  {
    "id": "1213032005",
    "district_id": "121303",
    "label": "Huta Tinggi",
    "value": "Huta Tinggi"
  },
  {
    "id": "1213032006",
    "district_id": "121303",
    "label": "Pagur",
    "value": "Pagur"
  },
  {
    "id": "1213032007",
    "district_id": "121303",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1213032008",
    "district_id": "121303",
    "label": "Parmompang",
    "value": "Parmompang"
  },
  {
    "id": "1213032009",
    "district_id": "121303",
    "label": "Ranto Natas",
    "value": "Ranto Natas"
  },
  {
    "id": "1213032010",
    "district_id": "121303",
    "label": "Sirangkap",
    "value": "Sirangkap"
  },
  {
    "id": "1213032011",
    "district_id": "121303",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1213032012",
    "district_id": "121303",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1213032013",
    "district_id": "121303",
    "label": "Tanjung Julu",
    "value": "Tanjung Julu"
  },
  {
    "id": "1213032014",
    "district_id": "121303",
    "label": "Banjar Lancat",
    "value": "Banjar Lancat"
  },
  {
    "id": "1213032015",
    "district_id": "121303",
    "label": "Padang Laru",
    "value": "Padang Laru"
  },
  {
    "id": "1213041009",
    "district_id": "121304",
    "label": "Tano Bato",
    "value": "Tano Bato"
  },
  {
    "id": "1213042001",
    "district_id": "121304",
    "label": "Aek Ngali",
    "value": "Aek Ngali"
  },
  {
    "id": "1213042002",
    "district_id": "121304",
    "label": "Hutarimbaru",
    "value": "Hutarimbaru"
  },
  {
    "id": "1213042003",
    "district_id": "121304",
    "label": "Hayuraja",
    "value": "Hayuraja"
  },
  {
    "id": "1213042004",
    "district_id": "121304",
    "label": "Huta Julu",
    "value": "Huta Julu"
  },
  {
    "id": "1213042005",
    "district_id": "121304",
    "label": "Kayu Laut",
    "value": "Kayu Laut"
  },
  {
    "id": "1213042006",
    "district_id": "121304",
    "label": "Lumban Dolok",
    "value": "Lumban Dolok"
  },
  {
    "id": "1213042007",
    "district_id": "121304",
    "label": "Roburan Dolok",
    "value": "Roburan Dolok"
  },
  {
    "id": "1213042008",
    "district_id": "121304",
    "label": "Roburan Lombang",
    "value": "Roburan Lombang"
  },
  {
    "id": "1213042010",
    "district_id": "121304",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1213042011",
    "district_id": "121304",
    "label": "Pagaran Gala-Gala",
    "value": "Pagaran Gala-Gala"
  },
  {
    "id": "1213051006",
    "district_id": "121305",
    "label": "Longat",
    "value": "Longat"
  },
  {
    "id": "1213052001",
    "district_id": "121305",
    "label": "Barbaran",
    "value": "Barbaran"
  },
  {
    "id": "1213052002",
    "district_id": "121305",
    "label": "Barbaran Jae",
    "value": "Barbaran Jae"
  },
  {
    "id": "1213052003",
    "district_id": "121305",
    "label": "Batang Gadis",
    "value": "Batang Gadis"
  },
  {
    "id": "1213052004",
    "district_id": "121305",
    "label": "Huta Baringin",
    "value": "Huta Baringin"
  },
  {
    "id": "1213052005",
    "district_id": "121305",
    "label": "Huta Tonga BB",
    "value": "Huta Tonga BB"
  },
  {
    "id": "1213052007",
    "district_id": "121305",
    "label": "Runding",
    "value": "Runding"
  },
  {
    "id": "1213052008",
    "district_id": "121305",
    "label": "Sirambas",
    "value": "Sirambas"
  },
  {
    "id": "1213052009",
    "district_id": "121305",
    "label": "Saba Jior",
    "value": "Saba Jior"
  },
  {
    "id": "1213052010",
    "district_id": "121305",
    "label": "Batang Gadis Jae",
    "value": "Batang Gadis Jae"
  },
  {
    "id": "1213061014",
    "district_id": "121306",
    "label": "Siabu",
    "value": "Siabu"
  },
  {
    "id": "1213061023",
    "district_id": "121306",
    "label": "Simangambat",
    "value": "Simangambat"
  },
  {
    "id": "1213062001",
    "district_id": "121306",
    "label": "Aek Mual",
    "value": "Aek Mual"
  },
  {
    "id": "1213062002",
    "district_id": "121306",
    "label": "Bonan Dolok",
    "value": "Bonan Dolok"
  },
  {
    "id": "1213062003",
    "district_id": "121306",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1213062004",
    "district_id": "121306",
    "label": "Huta Puli",
    "value": "Huta Puli"
  },
  {
    "id": "1213062005",
    "district_id": "121306",
    "label": "Huta Baringin",
    "value": "Huta Baringin"
  },
  {
    "id": "1213062006",
    "district_id": "121306",
    "label": "Huta Godang Muda",
    "value": "Huta Godang Muda"
  },
  {
    "id": "1213062007",
    "district_id": "121306",
    "label": "Huraba I",
    "value": "Huraba I"
  },
  {
    "id": "1213062008",
    "district_id": "121306",
    "label": "Huraba II",
    "value": "Huraba II"
  },
  {
    "id": "1213062009",
    "district_id": "121306",
    "label": "Lumban Pinasa",
    "value": "Lumban Pinasa"
  },
  {
    "id": "1213062010",
    "district_id": "121306",
    "label": "Lumban Dolok",
    "value": "Lumban Dolok"
  },
  {
    "id": "1213062011",
    "district_id": "121306",
    "label": "Muara Btg. Angkola",
    "value": "Muara Btg. Angkola"
  },
  {
    "id": "1213062012",
    "district_id": "121306",
    "label": "Pintu Padang Jae",
    "value": "Pintu Padang Jae"
  },
  {
    "id": "1213062013",
    "district_id": "121306",
    "label": "Pintu Padang Julu",
    "value": "Pintu Padang Julu"
  },
  {
    "id": "1213062015",
    "district_id": "121306",
    "label": "Sibaruang",
    "value": "Sibaruang"
  },
  {
    "id": "1213062016",
    "district_id": "121306",
    "label": "Sihepeng",
    "value": "Sihepeng"
  },
  {
    "id": "1213062017",
    "district_id": "121306",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1213062018",
    "district_id": "121306",
    "label": "Sinonoan",
    "value": "Sinonoan"
  },
  {
    "id": "1213062019",
    "district_id": "121306",
    "label": "Tangga Bosi II",
    "value": "Tangga Bosi II"
  },
  {
    "id": "1213062020",
    "district_id": "121306",
    "label": "Tanjung Sialang",
    "value": "Tanjung Sialang"
  },
  {
    "id": "1213062021",
    "district_id": "121306",
    "label": "Tangga Bosi I",
    "value": "Tangga Bosi I"
  },
  {
    "id": "1213062022",
    "district_id": "121306",
    "label": "Tangga Bosi III",
    "value": "Tangga Bosi III"
  },
  {
    "id": "1213062024",
    "district_id": "121306",
    "label": "Sihepeng Sada",
    "value": "Sihepeng Sada"
  },
  {
    "id": "1213062025",
    "district_id": "121306",
    "label": "Sihepeng Dua",
    "value": "Sihepeng Dua"
  },
  {
    "id": "1213062026",
    "district_id": "121306",
    "label": "Sihepeng Tolu",
    "value": "Sihepeng Tolu"
  },
  {
    "id": "1213062027",
    "district_id": "121306",
    "label": "Sihepeng Opat",
    "value": "Sihepeng Opat"
  },
  {
    "id": "1213062028",
    "district_id": "121306",
    "label": "Sihepeng Lima",
    "value": "Sihepeng Lima"
  },
  {
    "id": "1213072001",
    "district_id": "121307",
    "label": "Bange",
    "value": "Bange"
  },
  {
    "id": "1213072003",
    "district_id": "121307",
    "label": "Huta Bangun",
    "value": "Huta Bangun"
  },
  {
    "id": "1213072005",
    "district_id": "121307",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1213072006",
    "district_id": "121307",
    "label": "Malintang Jae",
    "value": "Malintang Jae"
  },
  {
    "id": "1213072007",
    "district_id": "121307",
    "label": "Malintang Julu",
    "value": "Malintang Julu"
  },
  {
    "id": "1213072008",
    "district_id": "121307",
    "label": "Pasar Baru Malintang",
    "value": "Pasar Baru Malintang"
  },
  {
    "id": "1213072010",
    "district_id": "121307",
    "label": "Sidojadi",
    "value": "Sidojadi"
  },
  {
    "id": "1213072013",
    "district_id": "121307",
    "label": "Lambou Darul Ihsan",
    "value": "Lambou Darul Ihsan"
  },
  {
    "id": "1213072014",
    "district_id": "121307",
    "label": "Huta Bangun Jae",
    "value": "Huta Bangun Jae"
  },
  {
    "id": "1213072015",
    "district_id": "121307",
    "label": "Bange Nauli",
    "value": "Bange Nauli"
  },
  {
    "id": "1213072016",
    "district_id": "121307",
    "label": "Malintang",
    "value": "Malintang"
  },
  {
    "id": "1213081032",
    "district_id": "121308",
    "label": "Tamiang",
    "value": "Tamiang"
  },
  {
    "id": "1213081036",
    "district_id": "121308",
    "label": "Pasar Kotanopan",
    "value": "Pasar Kotanopan"
  },
  {
    "id": "1213082001",
    "district_id": "121308",
    "label": "Batahan",
    "value": "Batahan"
  },
  {
    "id": "1213082002",
    "district_id": "121308",
    "label": "Botung",
    "value": "Botung"
  },
  {
    "id": "1213082003",
    "district_id": "121308",
    "label": "Gading Bain",
    "value": "Gading Bain"
  },
  {
    "id": "1213082004",
    "district_id": "121308",
    "label": "Gunung Tua MS",
    "value": "Gunung Tua MS"
  },
  {
    "id": "1213082005",
    "district_id": "121308",
    "label": "Gunung Tua SM",
    "value": "Gunung Tua SM"
  },
  {
    "id": "1213082006",
    "district_id": "121308",
    "label": "Hutapuli",
    "value": "Hutapuli"
  },
  {
    "id": "1213082007",
    "district_id": "121308",
    "label": "Huta Padang MS",
    "value": "Huta Padang MS"
  },
  {
    "id": "1213082008",
    "district_id": "121308",
    "label": "Hutarimbaru SM",
    "value": "Hutarimbaru SM"
  },
  {
    "id": "1213082009",
    "district_id": "121308",
    "label": "Huta Baringin TB",
    "value": "Huta Baringin TB"
  },
  {
    "id": "1213082010",
    "district_id": "121308",
    "label": "Huta Pungkut Jae",
    "value": "Huta Pungkut Jae"
  },
  {
    "id": "1213082011",
    "district_id": "121308",
    "label": "Huta Pungkut Tonga",
    "value": "Huta Pungkut Tonga"
  },
  {
    "id": "1213082012",
    "district_id": "121308",
    "label": "Huta Pungkut Julu",
    "value": "Huta Pungkut Julu"
  },
  {
    "id": "1213082014",
    "district_id": "121308",
    "label": "Muara Potan",
    "value": "Muara Potan"
  },
  {
    "id": "1213082015",
    "district_id": "121308",
    "label": "Muara Siambak",
    "value": "Muara Siambak"
  },
  {
    "id": "1213082016",
    "district_id": "121308",
    "label": "Manambin",
    "value": "Manambin"
  },
  {
    "id": "1213082017",
    "district_id": "121308",
    "label": "Muara Pungkut",
    "value": "Muara Pungkut"
  },
  {
    "id": "1213082018",
    "district_id": "121308",
    "label": "Muara Botung",
    "value": "Muara Botung"
  },
  {
    "id": "1213082019",
    "district_id": "121308",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1213082020",
    "district_id": "121308",
    "label": "Padang Bulan",
    "value": "Padang Bulan"
  },
  {
    "id": "1213082021",
    "district_id": "121308",
    "label": "Patialo",
    "value": "Patialo"
  },
  {
    "id": "1213082022",
    "district_id": "121308",
    "label": "Saba Dolok",
    "value": "Saba Dolok"
  },
  {
    "id": "1213082023",
    "district_id": "121308",
    "label": "Singengu Jae",
    "value": "Singengu Jae"
  },
  {
    "id": "1213082024",
    "district_id": "121308",
    "label": "Singengu Julu",
    "value": "Singengu Julu"
  },
  {
    "id": "1213082025",
    "district_id": "121308",
    "label": "Sayur Maincat",
    "value": "Sayur Maincat"
  },
  {
    "id": "1213082026",
    "district_id": "121308",
    "label": "Simpang Tolang Jae",
    "value": "Simpang Tolang Jae"
  },
  {
    "id": "1213082027",
    "district_id": "121308",
    "label": "Simpang Tolang Julu",
    "value": "Simpang Tolang Julu"
  },
  {
    "id": "1213082028",
    "district_id": "121308",
    "label": "Sibio Bio",
    "value": "Sibio Bio"
  },
  {
    "id": "1213082029",
    "district_id": "121308",
    "label": "Sopo Sorik",
    "value": "Sopo Sorik"
  },
  {
    "id": "1213082030",
    "district_id": "121308",
    "label": "Simandolam",
    "value": "Simandolam"
  },
  {
    "id": "1213082031",
    "district_id": "121308",
    "label": "Tombang Bustak",
    "value": "Tombang Bustak"
  },
  {
    "id": "1213082033",
    "district_id": "121308",
    "label": "Tobang",
    "value": "Tobang"
  },
  {
    "id": "1213082034",
    "district_id": "121308",
    "label": "Ujung Marisi",
    "value": "Ujung Marisi"
  },
  {
    "id": "1213082035",
    "district_id": "121308",
    "label": "Usor Tolang",
    "value": "Usor Tolang"
  },
  {
    "id": "1213091008",
    "district_id": "121309",
    "label": "Pasar Maga",
    "value": "Pasar Maga"
  },
  {
    "id": "1213092001",
    "district_id": "121309",
    "label": "Aek Marian MG",
    "value": "Aek Marian MG"
  },
  {
    "id": "1213092002",
    "district_id": "121309",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "1213092003",
    "district_id": "121309",
    "label": "Maga Lombang",
    "value": "Maga Lombang"
  },
  {
    "id": "1213092004",
    "district_id": "121309",
    "label": "Maga Dolok",
    "value": "Maga Dolok"
  },
  {
    "id": "1213092005",
    "district_id": "121309",
    "label": "Purba Baru",
    "value": "Purba Baru"
  },
  {
    "id": "1213092006",
    "district_id": "121309",
    "label": "Purba Lamo",
    "value": "Purba Lamo"
  },
  {
    "id": "1213092007",
    "district_id": "121309",
    "label": "Pangkat",
    "value": "Pangkat"
  },
  {
    "id": "1213092009",
    "district_id": "121309",
    "label": "Siantona",
    "value": "Siantona"
  },
  {
    "id": "1213101010",
    "district_id": "121310",
    "label": "Laru Lombang",
    "value": "Laru Lombang"
  },
  {
    "id": "1213102001",
    "district_id": "121310",
    "label": "Angin Barat",
    "value": "Angin Barat"
  },
  {
    "id": "1213102006",
    "district_id": "121310",
    "label": "Huta Tonga AB",
    "value": "Huta Tonga AB"
  },
  {
    "id": "1213102007",
    "district_id": "121310",
    "label": "Laru Baringin",
    "value": "Laru Baringin"
  },
  {
    "id": "1213102008",
    "district_id": "121310",
    "label": "Laru Bolak",
    "value": "Laru Bolak"
  },
  {
    "id": "1213102009",
    "district_id": "121310",
    "label": "Laru Dolok",
    "value": "Laru Dolok"
  },
  {
    "id": "1213102011",
    "district_id": "121310",
    "label": "Lumban Pasir",
    "value": "Lumban Pasir"
  },
  {
    "id": "1213102012",
    "district_id": "121310",
    "label": "Muara Mais",
    "value": "Muara Mais"
  },
  {
    "id": "1213102013",
    "district_id": "121310",
    "label": "Muara Mais Jambur",
    "value": "Muara Mais Jambur"
  },
  {
    "id": "1213102014",
    "district_id": "121310",
    "label": "Padang Sanggar",
    "value": "Padang Sanggar"
  },
  {
    "id": "1213102015",
    "district_id": "121310",
    "label": "Panjaringan",
    "value": "Panjaringan"
  },
  {
    "id": "1213102016",
    "district_id": "121310",
    "label": "Pasar Laru",
    "value": "Pasar Laru"
  },
  {
    "id": "1213102017",
    "district_id": "121310",
    "label": "Pastap",
    "value": "Pastap"
  },
  {
    "id": "1213102018",
    "district_id": "121310",
    "label": "Pastap Julu",
    "value": "Pastap Julu"
  },
  {
    "id": "1213102020",
    "district_id": "121310",
    "label": "Rao Rao Dolok",
    "value": "Rao Rao Dolok"
  },
  {
    "id": "1213102021",
    "district_id": "121310",
    "label": "Rao Rao Lombang",
    "value": "Rao Rao Lombang"
  },
  {
    "id": "1213102025",
    "district_id": "121310",
    "label": "Simangambat TB",
    "value": "Simangambat TB"
  },
  {
    "id": "1213102026",
    "district_id": "121310",
    "label": "Tambangan Jae",
    "value": "Tambangan Jae"
  },
  {
    "id": "1213102027",
    "district_id": "121310",
    "label": "Tambangan Pasoman",
    "value": "Tambangan Pasoman"
  },
  {
    "id": "1213102028",
    "district_id": "121310",
    "label": "Tambangan Tonga",
    "value": "Tambangan Tonga"
  },
  {
    "id": "1213111003",
    "district_id": "121311",
    "label": "Huta Godang",
    "value": "Huta Godang"
  },
  {
    "id": "1213112001",
    "district_id": "121311",
    "label": "Alahan Kae",
    "value": "Alahan Kae"
  },
  {
    "id": "1213112002",
    "district_id": "121311",
    "label": "Habincaran",
    "value": "Habincaran"
  },
  {
    "id": "1213112004",
    "district_id": "121311",
    "label": "Huta Padang UP",
    "value": "Huta Padang UP"
  },
  {
    "id": "1213112005",
    "district_id": "121311",
    "label": "Hutaimbaru UP",
    "value": "Hutaimbaru UP"
  },
  {
    "id": "1213112006",
    "district_id": "121311",
    "label": "Muara Saladi",
    "value": "Muara Saladi"
  },
  {
    "id": "1213112007",
    "district_id": "121311",
    "label": "Patahajang",
    "value": "Patahajang"
  },
  {
    "id": "1213112008",
    "district_id": "121311",
    "label": "Simpang Banyak Jae",
    "value": "Simpang Banyak Jae"
  },
  {
    "id": "1213112009",
    "district_id": "121311",
    "label": "Simpang Banyak Julu",
    "value": "Simpang Banyak Julu"
  },
  {
    "id": "1213112010",
    "district_id": "121311",
    "label": "Simpang Duhu Dolok",
    "value": "Simpang Duhu Dolok"
  },
  {
    "id": "1213112011",
    "district_id": "121311",
    "label": "Simpang Duhu Lombang",
    "value": "Simpang Duhu Lombang"
  },
  {
    "id": "1213112012",
    "district_id": "121311",
    "label": "Simpang Pining",
    "value": "Simpang Pining"
  },
  {
    "id": "1213112013",
    "district_id": "121311",
    "label": "Tolang",
    "value": "Tolang"
  },
  {
    "id": "1213121019",
    "district_id": "121312",
    "label": "Pasar Muara Sipongi",
    "value": "Pasar Muara Sipongi"
  },
  {
    "id": "1213122001",
    "district_id": "121312",
    "label": "Bandar Panjang",
    "value": "Bandar Panjang"
  },
  {
    "id": "1213122002",
    "district_id": "121312",
    "label": "Bandar Panjang Tuo",
    "value": "Bandar Panjang Tuo"
  },
  {
    "id": "1213122007",
    "district_id": "121312",
    "label": "Kampung Pinang",
    "value": "Kampung Pinang"
  },
  {
    "id": "1213122008",
    "district_id": "121312",
    "label": "Koto Baringin",
    "value": "Koto Baringin"
  },
  {
    "id": "1213122009",
    "district_id": "121312",
    "label": "Limau Manis",
    "value": "Limau Manis"
  },
  {
    "id": "1213122012",
    "district_id": "121312",
    "label": "Ranjo Batu",
    "value": "Ranjo Batu"
  },
  {
    "id": "1213122013",
    "district_id": "121312",
    "label": "Sibinail",
    "value": "Sibinail"
  },
  {
    "id": "1213122015",
    "district_id": "121312",
    "label": "Simpang Mandepo",
    "value": "Simpang Mandepo"
  },
  {
    "id": "1213122016",
    "district_id": "121312",
    "label": "Tamiang Mudo",
    "value": "Tamiang Mudo"
  },
  {
    "id": "1213122017",
    "district_id": "121312",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1213122020",
    "district_id": "121312",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1213122021",
    "district_id": "121312",
    "label": "Muara Kumpulan",
    "value": "Muara Kumpulan"
  },
  {
    "id": "1213122022",
    "district_id": "121312",
    "label": "Aek Botung",
    "value": "Aek Botung"
  },
  {
    "id": "1213122023",
    "district_id": "121312",
    "label": "Koto Boru",
    "value": "Koto Boru"
  },
  {
    "id": "1213122024",
    "district_id": "121312",
    "label": "Tanjung Larangan",
    "value": "Tanjung Larangan"
  },
  {
    "id": "1213131017",
    "district_id": "121313",
    "label": "Muara Soma",
    "value": "Muara Soma"
  },
  {
    "id": "1213132001",
    "district_id": "121313",
    "label": "Aek Guo",
    "value": "Aek Guo"
  },
  {
    "id": "1213132002",
    "district_id": "121313",
    "label": "Aek Holbung",
    "value": "Aek Holbung"
  },
  {
    "id": "1213132003",
    "district_id": "121313",
    "label": "Aek Manggis",
    "value": "Aek Manggis"
  },
  {
    "id": "1213132004",
    "district_id": "121313",
    "label": "Aek Nabara",
    "value": "Aek Nabara"
  },
  {
    "id": "1213132005",
    "district_id": "121313",
    "label": "Aek Nangali",
    "value": "Aek Nangali"
  },
  {
    "id": "1213132006",
    "district_id": "121313",
    "label": "Ampung Julu",
    "value": "Ampung Julu"
  },
  {
    "id": "1213132007",
    "district_id": "121313",
    "label": "Ampung Padang",
    "value": "Ampung Padang"
  },
  {
    "id": "1213132008",
    "district_id": "121313",
    "label": "Ampung Siala",
    "value": "Ampung Siala"
  },
  {
    "id": "1213132009",
    "district_id": "121313",
    "label": "Bangkelang",
    "value": "Bangkelang"
  },
  {
    "id": "1213132010",
    "district_id": "121313",
    "label": "Banjar Malayu",
    "value": "Banjar Malayu"
  },
  {
    "id": "1213132011",
    "district_id": "121313",
    "label": "Batu Madinding",
    "value": "Batu Madinding"
  },
  {
    "id": "1213132012",
    "district_id": "121313",
    "label": "Bulu Soma",
    "value": "Bulu Soma"
  },
  {
    "id": "1213132013",
    "district_id": "121313",
    "label": "Guo Batu",
    "value": "Guo Batu"
  },
  {
    "id": "1213132014",
    "district_id": "121313",
    "label": "Hadangkahan",
    "value": "Hadangkahan"
  },
  {
    "id": "1213132015",
    "district_id": "121313",
    "label": "Hatupangan",
    "value": "Hatupangan"
  },
  {
    "id": "1213132016",
    "district_id": "121313",
    "label": "Muara Parlampungan",
    "value": "Muara Parlampungan"
  },
  {
    "id": "1213132018",
    "district_id": "121313",
    "label": "Rantobi",
    "value": "Rantobi"
  },
  {
    "id": "1213132019",
    "district_id": "121313",
    "label": "Rao Rao",
    "value": "Rao Rao"
  },
  {
    "id": "1213132020",
    "district_id": "121313",
    "label": "Simanguntong",
    "value": "Simanguntong"
  },
  {
    "id": "1213132021",
    "district_id": "121313",
    "label": "Sipogu",
    "value": "Sipogu"
  },
  {
    "id": "1213132022",
    "district_id": "121313",
    "label": "Sopo Tinjak",
    "value": "Sopo Tinjak"
  },
  {
    "id": "1213132023",
    "district_id": "121313",
    "label": "Tarlola",
    "value": "Tarlola"
  },
  {
    "id": "1213132024",
    "district_id": "121313",
    "label": "Tombang Kaluang",
    "value": "Tombang Kaluang"
  },
  {
    "id": "1213132025",
    "district_id": "121313",
    "label": "Huta Lobu",
    "value": "Huta Lobu"
  },
  {
    "id": "1213132026",
    "district_id": "121313",
    "label": "Lubuk Samboa",
    "value": "Lubuk Samboa"
  },
  {
    "id": "1213132027",
    "district_id": "121313",
    "label": "Jambur Baru",
    "value": "Jambur Baru"
  },
  {
    "id": "1213132028",
    "district_id": "121313",
    "label": "Aek Baru Jae",
    "value": "Aek Baru Jae"
  },
  {
    "id": "1213132029",
    "district_id": "121313",
    "label": "Aek Baru Julu",
    "value": "Aek Baru Julu"
  },
  {
    "id": "1213132030",
    "district_id": "121313",
    "label": "Lubuk Bondar Panjang",
    "value": "Lubuk Bondar Panjang"
  },
  {
    "id": "1213132031",
    "district_id": "121313",
    "label": "Tor Naincat",
    "value": "Tor Naincat"
  },
  {
    "id": "1213141024",
    "district_id": "121314",
    "label": "Simpang Gambir",
    "value": "Simpang Gambir"
  },
  {
    "id": "1213141028",
    "district_id": "121314",
    "label": "Tapus",
    "value": "Tapus"
  },
  {
    "id": "1213142001",
    "district_id": "121314",
    "label": "Aek Garingging",
    "value": "Aek Garingging"
  },
  {
    "id": "1213142002",
    "district_id": "121314",
    "label": "Aek Manyuruk",
    "value": "Aek Manyuruk"
  },
  {
    "id": "1213142003",
    "district_id": "121314",
    "label": "Bandar Limabung",
    "value": "Bandar Limabung"
  },
  {
    "id": "1213142006",
    "district_id": "121314",
    "label": "Lancat",
    "value": "Lancat"
  },
  {
    "id": "1213142010",
    "district_id": "121314",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1213142011",
    "district_id": "121314",
    "label": "Lobung",
    "value": "Lobung"
  },
  {
    "id": "1213142014",
    "district_id": "121314",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1213142015",
    "district_id": "121314",
    "label": "Perk. Simpang Gambir",
    "value": "Perk. Simpang Gambir"
  },
  {
    "id": "1213142019",
    "district_id": "121314",
    "label": "Sikumbu",
    "value": "Sikumbu"
  },
  {
    "id": "1213142021",
    "district_id": "121314",
    "label": "Simpang Bajole",
    "value": "Simpang Bajole"
  },
  {
    "id": "1213142022",
    "district_id": "121314",
    "label": "Simpang Duku",
    "value": "Simpang Duku"
  },
  {
    "id": "1213142023",
    "district_id": "121314",
    "label": "Simpang Durian",
    "value": "Simpang Durian"
  },
  {
    "id": "1213142025",
    "district_id": "121314",
    "label": "Simpang Koje",
    "value": "Simpang Koje"
  },
  {
    "id": "1213142029",
    "district_id": "121314",
    "label": "Dalan Lidang",
    "value": "Dalan Lidang"
  },
  {
    "id": "1213142031",
    "district_id": "121314",
    "label": "Bonca Bayuon",
    "value": "Bonca Bayuon"
  },
  {
    "id": "1213142034",
    "district_id": "121314",
    "label": "Perbatasan",
    "value": "Perbatasan"
  },
  {
    "id": "1213142035",
    "district_id": "121314",
    "label": "Tangsi Atas",
    "value": "Tangsi Atas"
  },
  {
    "id": "1213151011",
    "district_id": "121315",
    "label": "Pasar Baru Batahan",
    "value": "Pasar Baru Batahan"
  },
  {
    "id": "1213152001",
    "district_id": "121315",
    "label": "Banjar Aur",
    "value": "Banjar Aur"
  },
  {
    "id": "1213152003",
    "district_id": "121315",
    "label": "Batahan I",
    "value": "Batahan I"
  },
  {
    "id": "1213152004",
    "district_id": "121315",
    "label": "Batu Sondat",
    "value": "Batu Sondat"
  },
  {
    "id": "1213152005",
    "district_id": "121315",
    "label": "Bintungan Bejangkar",
    "value": "Bintungan Bejangkar"
  },
  {
    "id": "1213152007",
    "district_id": "121315",
    "label": "Kampung Kapas",
    "value": "Kampung Kapas"
  },
  {
    "id": "1213152008",
    "district_id": "121315",
    "label": "Kuala Batahan",
    "value": "Kuala Batahan"
  },
  {
    "id": "1213152009",
    "district_id": "121315",
    "label": "Kubangan Tompek",
    "value": "Kubangan Tompek"
  },
  {
    "id": "1213152010",
    "district_id": "121315",
    "label": "Muara Pertemuan",
    "value": "Muara Pertemuan"
  },
  {
    "id": "1213152012",
    "district_id": "121315",
    "label": "Pasar Batahan",
    "value": "Pasar Batahan"
  },
  {
    "id": "1213152013",
    "district_id": "121315",
    "label": "Pulau Tamang",
    "value": "Pulau Tamang"
  },
  {
    "id": "1213152014",
    "district_id": "121315",
    "label": "Sari Kenanga Batahan",
    "value": "Sari Kenanga Batahan"
  },
  {
    "id": "1213152019",
    "district_id": "121315",
    "label": "Batahan II",
    "value": "Batahan II"
  },
  {
    "id": "1213152020",
    "district_id": "121315",
    "label": "Batahan III",
    "value": "Batahan III"
  },
  {
    "id": "1213152021",
    "district_id": "121315",
    "label": "Batahan IV",
    "value": "Batahan IV"
  },
  {
    "id": "1213152022",
    "district_id": "121315",
    "label": "Sinunukan VI",
    "value": "Sinunukan VI"
  },
  {
    "id": "1213152023",
    "district_id": "121315",
    "label": "Kampung Kapas I",
    "value": "Kampung Kapas I"
  },
  {
    "id": "1213152024",
    "district_id": "121315",
    "label": "Kubangan Pandan Sari",
    "value": "Kubangan Pandan Sari"
  },
  {
    "id": "1213161008",
    "district_id": "121316",
    "label": "Pasar II Natal",
    "value": "Pasar II Natal"
  },
  {
    "id": "1213161025",
    "district_id": "121316",
    "label": "Pasar I Natal",
    "value": "Pasar I Natal"
  },
  {
    "id": "1213162001",
    "district_id": "121316",
    "label": "Balimbing",
    "value": "Balimbing"
  },
  {
    "id": "1213162002",
    "district_id": "121316",
    "label": "Bintuas",
    "value": "Bintuas"
  },
  {
    "id": "1213162003",
    "district_id": "121316",
    "label": "Bonda Kase",
    "value": "Bonda Kase"
  },
  {
    "id": "1213162004",
    "district_id": "121316",
    "label": "Buburan",
    "value": "Buburan"
  },
  {
    "id": "1213162005",
    "district_id": "121316",
    "label": "Kampung Sawah",
    "value": "Kampung Sawah"
  },
  {
    "id": "1213162006",
    "district_id": "121316",
    "label": "Panggautan",
    "value": "Panggautan"
  },
  {
    "id": "1213162007",
    "district_id": "121316",
    "label": "Pardamean Baru",
    "value": "Pardamean Baru"
  },
  {
    "id": "1213162009",
    "district_id": "121316",
    "label": "Pasar III Natal",
    "value": "Pasar III Natal"
  },
  {
    "id": "1213162010",
    "district_id": "121316",
    "label": "Pasar V Natal",
    "value": "Pasar V Natal"
  },
  {
    "id": "1213162011",
    "district_id": "121316",
    "label": "Patiluban Hilir",
    "value": "Patiluban Hilir"
  },
  {
    "id": "1213162012",
    "district_id": "121316",
    "label": "Patiluban Mudik",
    "value": "Patiluban Mudik"
  },
  {
    "id": "1213162013",
    "district_id": "121316",
    "label": "Perkebunan Patiluban",
    "value": "Perkebunan Patiluban"
  },
  {
    "id": "1213162014",
    "district_id": "121316",
    "label": "Rukun Jaya",
    "value": "Rukun Jaya"
  },
  {
    "id": "1213162015",
    "district_id": "121316",
    "label": "Setia Karya",
    "value": "Setia Karya"
  },
  {
    "id": "1213162016",
    "district_id": "121316",
    "label": "Sikara Kara",
    "value": "Sikara Kara"
  },
  {
    "id": "1213162017",
    "district_id": "121316",
    "label": "Sikara Kara I",
    "value": "Sikara Kara I"
  },
  {
    "id": "1213162018",
    "district_id": "121316",
    "label": "Sikara Kara II",
    "value": "Sikara Kara II"
  },
  {
    "id": "1213162019",
    "district_id": "121316",
    "label": "Sikara Kara III",
    "value": "Sikara Kara III"
  },
  {
    "id": "1213162020",
    "district_id": "121316",
    "label": "Sikara Kara IV",
    "value": "Sikara Kara IV"
  },
  {
    "id": "1213162021",
    "district_id": "121316",
    "label": "Sundutan Tigo",
    "value": "Sundutan Tigo"
  },
  {
    "id": "1213162022",
    "district_id": "121316",
    "label": "Taluk",
    "value": "Taluk"
  },
  {
    "id": "1213162023",
    "district_id": "121316",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1213162024",
    "district_id": "121316",
    "label": "Tunas Karya",
    "value": "Tunas Karya"
  },
  {
    "id": "1213162026",
    "district_id": "121316",
    "label": "Pasar VI Natal",
    "value": "Pasar VI Natal"
  },
  {
    "id": "1213162027",
    "district_id": "121316",
    "label": "Sinunukan V",
    "value": "Sinunukan V"
  },
  {
    "id": "1213162028",
    "district_id": "121316",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1213162029",
    "district_id": "121316",
    "label": "Sasaran",
    "value": "Sasaran"
  },
  {
    "id": "1213162030",
    "district_id": "121316",
    "label": "Kun-Kun",
    "value": "Kun-Kun"
  },
  {
    "id": "1213172001",
    "district_id": "121317",
    "label": "Batu Mundom",
    "value": "Batu Mundom"
  },
  {
    "id": "1213172002",
    "district_id": "121317",
    "label": "Hutaimbaru",
    "value": "Hutaimbaru"
  },
  {
    "id": "1213172003",
    "district_id": "121317",
    "label": "Lubuk Kapundung",
    "value": "Lubuk Kapundung"
  },
  {
    "id": "1213172004",
    "district_id": "121317",
    "label": "Manuncang",
    "value": "Manuncang"
  },
  {
    "id": "1213172005",
    "district_id": "121317",
    "label": "Panunggulan",
    "value": "Panunggulan"
  },
  {
    "id": "1213172006",
    "district_id": "121317",
    "label": "Pasar Singkuang I",
    "value": "Pasar Singkuang I"
  },
  {
    "id": "1213172007",
    "district_id": "121317",
    "label": "Pasar Singkuang II",
    "value": "Pasar Singkuang II"
  },
  {
    "id": "1213172008",
    "district_id": "121317",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1213172009",
    "district_id": "121317",
    "label": "Sikapas",
    "value": "Sikapas"
  },
  {
    "id": "1213172010",
    "district_id": "121317",
    "label": "Tabuyung",
    "value": "Tabuyung"
  },
  {
    "id": "1213172011",
    "district_id": "121317",
    "label": "Tagilang Julu",
    "value": "Tagilang Julu"
  },
  {
    "id": "1213172012",
    "district_id": "121317",
    "label": "Sali Baru",
    "value": "Sali Baru"
  },
  {
    "id": "1213172013",
    "district_id": "121317",
    "label": "Lubuk Kapundung II",
    "value": "Lubuk Kapundung II"
  },
  {
    "id": "1213172014",
    "district_id": "121317",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1213182001",
    "district_id": "121318",
    "label": "Manisak",
    "value": "Manisak"
  },
  {
    "id": "1213182002",
    "district_id": "121318",
    "label": "Simpang Talap",
    "value": "Simpang Talap"
  },
  {
    "id": "1213182003",
    "district_id": "121318",
    "label": "Sampuran",
    "value": "Sampuran"
  },
  {
    "id": "1213182004",
    "district_id": "121318",
    "label": "Tandikek",
    "value": "Tandikek"
  },
  {
    "id": "1213182005",
    "district_id": "121318",
    "label": "Gonting",
    "value": "Gonting"
  },
  {
    "id": "1213182006",
    "district_id": "121318",
    "label": "Bangun Saroha",
    "value": "Bangun Saroha"
  },
  {
    "id": "1213182007",
    "district_id": "121318",
    "label": "Huta Baringin",
    "value": "Huta Baringin"
  },
  {
    "id": "1213182008",
    "district_id": "121318",
    "label": "Muara Bangko",
    "value": "Muara Bangko"
  },
  {
    "id": "1213182009",
    "district_id": "121318",
    "label": "Ranto Nalinjang",
    "value": "Ranto Nalinjang"
  },
  {
    "id": "1213182010",
    "district_id": "121318",
    "label": "Banjar Maga",
    "value": "Banjar Maga"
  },
  {
    "id": "1213182011",
    "district_id": "121318",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1213182012",
    "district_id": "121318",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1213182013",
    "district_id": "121318",
    "label": "Ranto Panjang",
    "value": "Ranto Panjang"
  },
  {
    "id": "1213182014",
    "district_id": "121318",
    "label": "Huta Nauli",
    "value": "Huta Nauli"
  },
  {
    "id": "1213182015",
    "district_id": "121318",
    "label": "Lubuk Kancah",
    "value": "Lubuk Kancah"
  },
  {
    "id": "1213182016",
    "district_id": "121318",
    "label": "Dua Sepakat",
    "value": "Dua Sepakat"
  },
  {
    "id": "1213182017",
    "district_id": "121318",
    "label": "Gunung Godang",
    "value": "Gunung Godang"
  },
  {
    "id": "1213182018",
    "district_id": "121318",
    "label": "Padang Silojongan",
    "value": "Padang Silojongan"
  },
  {
    "id": "1213192001",
    "district_id": "121319",
    "label": "Bangun Sejati",
    "value": "Bangun Sejati"
  },
  {
    "id": "1213192002",
    "district_id": "121319",
    "label": "Hutarimbaru",
    "value": "Hutarimbaru"
  },
  {
    "id": "1213192003",
    "district_id": "121319",
    "label": "Pasar Huta Bargot",
    "value": "Pasar Huta Bargot"
  },
  {
    "id": "1213192004",
    "district_id": "121319",
    "label": "Huta Bargot Lombang",
    "value": "Huta Bargot Lombang"
  },
  {
    "id": "1213192005",
    "district_id": "121319",
    "label": "Huta Bargot Dolok",
    "value": "Huta Bargot Dolok"
  },
  {
    "id": "1213192006",
    "district_id": "121319",
    "label": "Huta Bargot Setia",
    "value": "Huta Bargot Setia"
  },
  {
    "id": "1213192007",
    "district_id": "121319",
    "label": "Huta Bargot Nauli",
    "value": "Huta Bargot Nauli"
  },
  {
    "id": "1213192008",
    "district_id": "121319",
    "label": "Mondan",
    "value": "Mondan"
  },
  {
    "id": "1213192009",
    "district_id": "121319",
    "label": "Sayur Maincat",
    "value": "Sayur Maincat"
  },
  {
    "id": "1213192010",
    "district_id": "121319",
    "label": "Kumpulan Setia",
    "value": "Kumpulan Setia"
  },
  {
    "id": "1213192011",
    "district_id": "121319",
    "label": "Simalagi",
    "value": "Simalagi"
  },
  {
    "id": "1213192012",
    "district_id": "121319",
    "label": "Saba Padang",
    "value": "Saba Padang"
  },
  {
    "id": "1213192013",
    "district_id": "121319",
    "label": "Binanga",
    "value": "Binanga"
  },
  {
    "id": "1213192014",
    "district_id": "121319",
    "label": "Huta Naingkan",
    "value": "Huta Naingkan"
  },
  {
    "id": "1213192015",
    "district_id": "121319",
    "label": "Huta Dangka",
    "value": "Huta Dangka"
  },
  {
    "id": "1213202001",
    "district_id": "121320",
    "label": "Huta Lombang",
    "value": "Huta Lombang"
  },
  {
    "id": "1213202002",
    "district_id": "121320",
    "label": "Huta Baru",
    "value": "Huta Baru"
  },
  {
    "id": "1213202003",
    "district_id": "121320",
    "label": "Handel",
    "value": "Handel"
  },
  {
    "id": "1213202004",
    "district_id": "121320",
    "label": "Hutatinggi",
    "value": "Hutatinggi"
  },
  {
    "id": "1213202005",
    "district_id": "121320",
    "label": "Hutanamale",
    "value": "Hutanamale"
  },
  {
    "id": "1213202006",
    "district_id": "121320",
    "label": "Hutabaringin Julu",
    "value": "Hutabaringin Julu"
  },
  {
    "id": "1213202007",
    "district_id": "121320",
    "label": "Sibanggor Jae",
    "value": "Sibanggor Jae"
  },
  {
    "id": "1213202008",
    "district_id": "121320",
    "label": "Sibanggor Tonga",
    "value": "Sibanggor Tonga"
  },
  {
    "id": "1213202009",
    "district_id": "121320",
    "label": "Sibanggor Julu",
    "value": "Sibanggor Julu"
  },
  {
    "id": "1213202010",
    "district_id": "121320",
    "label": "Purba Julu",
    "value": "Purba Julu"
  },
  {
    "id": "1213202011",
    "district_id": "121320",
    "label": "Huta Baringin",
    "value": "Huta Baringin"
  },
  {
    "id": "1213212001",
    "district_id": "121321",
    "label": "Pakantan Dolok",
    "value": "Pakantan Dolok"
  },
  {
    "id": "1213212002",
    "district_id": "121321",
    "label": "Pakantan Lombang",
    "value": "Pakantan Lombang"
  },
  {
    "id": "1213212003",
    "district_id": "121321",
    "label": "Huta Gambir",
    "value": "Huta Gambir"
  },
  {
    "id": "1213212004",
    "district_id": "121321",
    "label": "Huta Lancat",
    "value": "Huta Lancat"
  },
  {
    "id": "1213212005",
    "district_id": "121321",
    "label": "Huta Julu",
    "value": "Huta Julu"
  },
  {
    "id": "1213212006",
    "district_id": "121321",
    "label": "Huta Toras",
    "value": "Huta Toras"
  },
  {
    "id": "1213212007",
    "district_id": "121321",
    "label": "Huta Padang",
    "value": "Huta Padang"
  },
  {
    "id": "1213212008",
    "district_id": "121321",
    "label": "Silogun",
    "value": "Silogun"
  },
  {
    "id": "1213222001",
    "district_id": "121322",
    "label": "Sinunukan I",
    "value": "Sinunukan I"
  },
  {
    "id": "1213222002",
    "district_id": "121322",
    "label": "Sinunukan II",
    "value": "Sinunukan II"
  },
  {
    "id": "1213222003",
    "district_id": "121322",
    "label": "Sinunukan III",
    "value": "Sinunukan III"
  },
  {
    "id": "1213222004",
    "district_id": "121322",
    "label": "Sinunukan IV",
    "value": "Sinunukan IV"
  },
  {
    "id": "1213222005",
    "district_id": "121322",
    "label": "Bintungan Bejangkar Baru",
    "value": "Bintungan Bejangkar Baru"
  },
  {
    "id": "1213222006",
    "district_id": "121322",
    "label": "Banjar Aur Utara",
    "value": "Banjar Aur Utara"
  },
  {
    "id": "1213222007",
    "district_id": "121322",
    "label": "Kampung Kapas II",
    "value": "Kampung Kapas II"
  },
  {
    "id": "1213222008",
    "district_id": "121322",
    "label": "Airapa",
    "value": "Airapa"
  },
  {
    "id": "1213222009",
    "district_id": "121322",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1213222010",
    "district_id": "121322",
    "label": "Wido Daren",
    "value": "Wido Daren"
  },
  {
    "id": "1213222011",
    "district_id": "121322",
    "label": "Wono Sari",
    "value": "Wono Sari"
  },
  {
    "id": "1213222012",
    "district_id": "121322",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1213222013",
    "district_id": "121322",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1213222014",
    "district_id": "121322",
    "label": "Sinunukan I Central",
    "value": "Sinunukan I Central"
  },
  {
    "id": "1213232001",
    "district_id": "121323",
    "label": "Tambiski",
    "value": "Tambiski"
  },
  {
    "id": "1213232002",
    "district_id": "121323",
    "label": "Tarutung Panjang",
    "value": "Tarutung Panjang"
  },
  {
    "id": "1213232003",
    "district_id": "121323",
    "label": "Humbang I",
    "value": "Humbang I"
  },
  {
    "id": "1213232004",
    "district_id": "121323",
    "label": "Banua Rakyat",
    "value": "Banua Rakyat"
  },
  {
    "id": "1213232005",
    "district_id": "121323",
    "label": "Sayur Matua",
    "value": "Sayur Matua"
  },
  {
    "id": "1213232006",
    "district_id": "121323",
    "label": "Banua Simanosor",
    "value": "Banua Simanosor"
  },
  {
    "id": "1213232007",
    "district_id": "121323",
    "label": "Tambiski Nauli",
    "value": "Tambiski Nauli"
  },
  {
    "id": "1214012002",
    "district_id": "121401",
    "label": "Hilisangowola",
    "value": "Hilisangowola"
  },
  {
    "id": "1214012005",
    "district_id": "121401",
    "label": "Hiliotalua",
    "value": "Hiliotalua"
  },
  {
    "id": "1214012006",
    "district_id": "121401",
    "label": "Koendrafo",
    "value": "Koendrafo"
  },
  {
    "id": "1214012008",
    "district_id": "121401",
    "label": "Lawa-lawa Luo",
    "value": "Lawa-lawa Luo"
  },
  {
    "id": "1214012016",
    "district_id": "121401",
    "label": "Tuhemberua",
    "value": "Tuhemberua"
  },
  {
    "id": "1214012017",
    "district_id": "121401",
    "label": "Tesikhori",
    "value": "Tesikhori"
  },
  {
    "id": "1214012021",
    "district_id": "121401",
    "label": "Tumari",
    "value": "Tumari"
  },
  {
    "id": "1214012023",
    "district_id": "121401",
    "label": "Ewo",
    "value": "Ewo"
  },
  {
    "id": "1214012024",
    "district_id": "121401",
    "label": "Ko'olotano",
    "value": "Ko'olotano"
  },
  {
    "id": "1214012026",
    "district_id": "121401",
    "label": "Botohilindruria",
    "value": "Botohilindruria"
  },
  {
    "id": "1214012027",
    "district_id": "121401",
    "label": "Caritas Sogawunasi",
    "value": "Caritas Sogawunasi"
  },
  {
    "id": "1214012028",
    "district_id": "121401",
    "label": "Orudua Lawa-lawa Luo",
    "value": "Orudua Lawa-lawa Luo"
  },
  {
    "id": "1214012029",
    "district_id": "121401",
    "label": "Hilifaondrato",
    "value": "Hilifaondrato"
  },
  {
    "id": "1214022005",
    "district_id": "121402",
    "label": "Doli-doli Idanotae",
    "value": "Doli-doli Idanotae"
  },
  {
    "id": "1214022007",
    "district_id": "121402",
    "label": "Hiliana'a Gomo",
    "value": "Hiliana'a Gomo"
  },
  {
    "id": "1214022015",
    "district_id": "121402",
    "label": "Lawa-lawa Luo Gomo",
    "value": "Lawa-lawa Luo Gomo"
  },
  {
    "id": "1214022017",
    "district_id": "121402",
    "label": "Lolosoni",
    "value": "Lolosoni"
  },
  {
    "id": "1214022023",
    "district_id": "121402",
    "label": "Orahili Gomo",
    "value": "Orahili Gomo"
  },
  {
    "id": "1214022024",
    "district_id": "121402",
    "label": "Sifaoro'asi Gomo",
    "value": "Sifaoro'asi Gomo"
  },
  {
    "id": "1214022035",
    "district_id": "121402",
    "label": "Tanoniko'o",
    "value": "Tanoniko'o"
  },
  {
    "id": "1214022040",
    "district_id": "121402",
    "label": "Suka Maju Mohili",
    "value": "Suka Maju Mohili"
  },
  {
    "id": "1214022041",
    "district_id": "121402",
    "label": "Orahili Sibohou",
    "value": "Orahili Sibohou"
  },
  {
    "id": "1214022042",
    "district_id": "121402",
    "label": "Gunung Gabungan",
    "value": "Gunung Gabungan"
  },
  {
    "id": "1214022043",
    "district_id": "121402",
    "label": "Umbu Orahua",
    "value": "Umbu Orahua"
  },
  {
    "id": "1214032001",
    "district_id": "121403",
    "label": "Bawolato",
    "value": "Bawolato"
  },
  {
    "id": "1214032002",
    "district_id": "121403",
    "label": "Bawozihono",
    "value": "Bawozihono"
  },
  {
    "id": "1214032003",
    "district_id": "121403",
    "label": "Bawo'otalua",
    "value": "Bawo'otalua"
  },
  {
    "id": "1214032004",
    "district_id": "121403",
    "label": "Golambanua I",
    "value": "Golambanua I"
  },
  {
    "id": "1214032007",
    "district_id": "121403",
    "label": "Hilisimaetano Balaekha",
    "value": "Hilisimaetano Balaekha"
  },
  {
    "id": "1214032009",
    "district_id": "121403",
    "label": "Lahusa",
    "value": "Lahusa"
  },
  {
    "id": "1214032014",
    "district_id": "121403",
    "label": "Tetezou",
    "value": "Tetezou"
  },
  {
    "id": "1214032016",
    "district_id": "121403",
    "label": "Orahili Balaekha",
    "value": "Orahili Balaekha"
  },
  {
    "id": "1214032017",
    "district_id": "121403",
    "label": "Harenoro",
    "value": "Harenoro"
  },
  {
    "id": "1214032018",
    "district_id": "121403",
    "label": "Sarahililaza",
    "value": "Sarahililaza"
  },
  {
    "id": "1214032024",
    "district_id": "121403",
    "label": "Hiliabolata",
    "value": "Hiliabolata"
  },
  {
    "id": "1214032025",
    "district_id": "121403",
    "label": "Hiligambukha",
    "value": "Hiligambukha"
  },
  {
    "id": "1214032026",
    "district_id": "121403",
    "label": "Sinar Baru Darodaro",
    "value": "Sinar Baru Darodaro"
  },
  {
    "id": "1214032027",
    "district_id": "121403",
    "label": "Hiliorudua",
    "value": "Hiliorudua"
  },
  {
    "id": "1214032032",
    "district_id": "121403",
    "label": "Hilizomboi",
    "value": "Hilizomboi"
  },
  {
    "id": "1214032036",
    "district_id": "121403",
    "label": "Oikhoda Balaekha",
    "value": "Oikhoda Balaekha"
  },
  {
    "id": "1214032037",
    "district_id": "121403",
    "label": "Angorudua Balaekha",
    "value": "Angorudua Balaekha"
  },
  {
    "id": "1214032038",
    "district_id": "121403",
    "label": "Hilinawalo Balaekha",
    "value": "Hilinawalo Balaekha"
  },
  {
    "id": "1214032039",
    "district_id": "121403",
    "label": "Sobawagoli",
    "value": "Sobawagoli"
  },
  {
    "id": "1214032040",
    "district_id": "121403",
    "label": "Lahusa I",
    "value": "Lahusa I"
  },
  {
    "id": "1214032041",
    "district_id": "121403",
    "label": "Hiliwatema",
    "value": "Hiliwatema"
  },
  {
    "id": "1214032042",
    "district_id": "121403",
    "label": "Mogae",
    "value": "Mogae"
  },
  {
    "id": "1214032043",
    "district_id": "121403",
    "label": "Sinar Baho",
    "value": "Sinar Baho"
  },
  {
    "id": "1214042001",
    "district_id": "121404",
    "label": "Duru",
    "value": "Duru"
  },
  {
    "id": "1214042002",
    "district_id": "121404",
    "label": "Tuwaso",
    "value": "Tuwaso"
  },
  {
    "id": "1214042003",
    "district_id": "121404",
    "label": "Hilioromao",
    "value": "Hilioromao"
  },
  {
    "id": "1214042004",
    "district_id": "121404",
    "label": "Eho",
    "value": "Eho"
  },
  {
    "id": "1214042005",
    "district_id": "121404",
    "label": "Sialema",
    "value": "Sialema"
  },
  {
    "id": "1214042006",
    "district_id": "121404",
    "label": "Bowonifaoso",
    "value": "Bowonifaoso"
  },
  {
    "id": "1214042007",
    "district_id": "121404",
    "label": "Hilianombasela",
    "value": "Hilianombasela"
  },
  {
    "id": "1214042008",
    "district_id": "121404",
    "label": "Lumbui Melayu",
    "value": "Lumbui Melayu"
  },
  {
    "id": "1214042009",
    "district_id": "121404",
    "label": "Hiliorodua Tebolo",
    "value": "Hiliorodua Tebolo"
  },
  {
    "id": "1214042010",
    "district_id": "121404",
    "label": "Tebolo Melayu",
    "value": "Tebolo Melayu"
  },
  {
    "id": "1214042021",
    "district_id": "121404",
    "label": "Lumbui Nias",
    "value": "Lumbui Nias"
  },
  {
    "id": "1214042022",
    "district_id": "121404",
    "label": "Baruyu Sibohou",
    "value": "Baruyu Sibohou"
  },
  {
    "id": "1214042023",
    "district_id": "121404",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "1214042024",
    "district_id": "121404",
    "label": "Tano Mokinu",
    "value": "Tano Mokinu"
  },
  {
    "id": "1214042025",
    "district_id": "121404",
    "label": "Hilikana",
    "value": "Hilikana"
  },
  {
    "id": "1214042026",
    "district_id": "121404",
    "label": "Hilinifaese",
    "value": "Hilinifaese"
  },
  {
    "id": "1214042027",
    "district_id": "121404",
    "label": "Omega",
    "value": "Omega"
  },
  {
    "id": "1214051046",
    "district_id": "121405",
    "label": "Pasar Pulau Tello",
    "value": "Pasar Pulau Tello"
  },
  {
    "id": "1214052001",
    "district_id": "121405",
    "label": "Hiliotalua",
    "value": "Hiliotalua"
  },
  {
    "id": "1214052002",
    "district_id": "121405",
    "label": "Bawodobara",
    "value": "Bawodobara"
  },
  {
    "id": "1214052003",
    "district_id": "121405",
    "label": "Bawo'amahelato",
    "value": "Bawo'amahelato"
  },
  {
    "id": "1214052004",
    "district_id": "121405",
    "label": "Bawo'omasio",
    "value": "Bawo'omasio"
  },
  {
    "id": "1214052005",
    "district_id": "121405",
    "label": "Loboi",
    "value": "Loboi"
  },
  {
    "id": "1214052006",
    "district_id": "121405",
    "label": "Hiliamaodula",
    "value": "Hiliamaodula"
  },
  {
    "id": "1214052007",
    "district_id": "121405",
    "label": "Baruyu Lasara",
    "value": "Baruyu Lasara"
  },
  {
    "id": "1214052008",
    "district_id": "121405",
    "label": "Sifitu Ewali",
    "value": "Sifitu Ewali"
  },
  {
    "id": "1214052009",
    "district_id": "121405",
    "label": "Rapa-rapa Melayu",
    "value": "Rapa-rapa Melayu"
  },
  {
    "id": "1214052010",
    "district_id": "121405",
    "label": "Sinauru",
    "value": "Sinauru"
  },
  {
    "id": "1214052011",
    "district_id": "121405",
    "label": "Siofa Ewali",
    "value": "Siofa Ewali"
  },
  {
    "id": "1214052012",
    "district_id": "121405",
    "label": "Simaluaya",
    "value": "Simaluaya"
  },
  {
    "id": "1214052013",
    "district_id": "121405",
    "label": "Silima Ewali",
    "value": "Silima Ewali"
  },
  {
    "id": "1214052014",
    "district_id": "121405",
    "label": "Sidua Ewali",
    "value": "Sidua Ewali"
  },
  {
    "id": "1214052027",
    "district_id": "121405",
    "label": "Balogia",
    "value": "Balogia"
  },
  {
    "id": "1214052029",
    "district_id": "121405",
    "label": "Lasonde",
    "value": "Lasonde"
  },
  {
    "id": "1214052041",
    "district_id": "121405",
    "label": "Koto",
    "value": "Koto"
  },
  {
    "id": "1214052048",
    "district_id": "121405",
    "label": "Onaya",
    "value": "Onaya"
  },
  {
    "id": "1214052049",
    "district_id": "121405",
    "label": "Sisarahili",
    "value": "Sisarahili"
  },
  {
    "id": "1214052051",
    "district_id": "121405",
    "label": "Sebuasi",
    "value": "Sebuasi"
  },
  {
    "id": "1214052054",
    "district_id": "121405",
    "label": "Orahili",
    "value": "Orahili"
  },
  {
    "id": "1214061008",
    "district_id": "121406",
    "label": "Pasar Teluk Dalam",
    "value": "Pasar Teluk Dalam"
  },
  {
    "id": "1214062006",
    "district_id": "121406",
    "label": "Hiligeho",
    "value": "Hiligeho"
  },
  {
    "id": "1214062007",
    "district_id": "121406",
    "label": "Hilitobara",
    "value": "Hilitobara"
  },
  {
    "id": "1214062009",
    "district_id": "121406",
    "label": "Bawonifaoso",
    "value": "Bawonifaoso"
  },
  {
    "id": "1214062010",
    "district_id": "121406",
    "label": "Bawozaua",
    "value": "Bawozaua"
  },
  {
    "id": "1214062011",
    "district_id": "121406",
    "label": "Hiliganowo",
    "value": "Hiliganowo"
  },
  {
    "id": "1214062023",
    "district_id": "121406",
    "label": "Bawodobara",
    "value": "Bawodobara"
  },
  {
    "id": "1214062024",
    "district_id": "121406",
    "label": "Bawolowalani",
    "value": "Bawolowalani"
  },
  {
    "id": "1214062025",
    "district_id": "121406",
    "label": "Hilisao'otoniha",
    "value": "Hilisao'otoniha"
  },
  {
    "id": "1214062026",
    "district_id": "121406",
    "label": "Hilisondrekha",
    "value": "Hilisondrekha"
  },
  {
    "id": "1214062040",
    "district_id": "121406",
    "label": "Hiliamuri",
    "value": "Hiliamuri"
  },
  {
    "id": "1214062042",
    "district_id": "121406",
    "label": "Hiliana'a",
    "value": "Hiliana'a"
  },
  {
    "id": "1214062043",
    "district_id": "121406",
    "label": "Hiliganowo Salo'o",
    "value": "Hiliganowo Salo'o"
  },
  {
    "id": "1214062044",
    "district_id": "121406",
    "label": "Ganowo Saua",
    "value": "Ganowo Saua"
  },
  {
    "id": "1214062045",
    "district_id": "121406",
    "label": "Nanowa",
    "value": "Nanowa"
  },
  {
    "id": "1214062046",
    "district_id": "121406",
    "label": "Hililaza",
    "value": "Hililaza"
  },
  {
    "id": "1214072004",
    "district_id": "121407",
    "label": "Sifaoroasi",
    "value": "Sifaoroasi"
  },
  {
    "id": "1214072005",
    "district_id": "121407",
    "label": "Hilihoru",
    "value": "Hilihoru"
  },
  {
    "id": "1214072006",
    "district_id": "121407",
    "label": "Hilimbulawa",
    "value": "Hilimbulawa"
  },
  {
    "id": "1214072008",
    "district_id": "121407",
    "label": "Tuindrao",
    "value": "Tuindrao"
  },
  {
    "id": "1214072009",
    "district_id": "121407",
    "label": "Hilimbowo",
    "value": "Hilimbowo"
  },
  {
    "id": "1214072011",
    "district_id": "121407",
    "label": "Mohili",
    "value": "Mohili"
  },
  {
    "id": "1214072012",
    "district_id": "121407",
    "label": "Lolozaria",
    "value": "Lolozaria"
  },
  {
    "id": "1214072013",
    "district_id": "121407",
    "label": "Lolomoyo",
    "value": "Lolomoyo"
  },
  {
    "id": "1214072014",
    "district_id": "121407",
    "label": "Hilifadolo",
    "value": "Hilifadolo"
  },
  {
    "id": "1214072015",
    "district_id": "121407",
    "label": "Orahili Eho",
    "value": "Orahili Eho"
  },
  {
    "id": "1214072016",
    "district_id": "121407",
    "label": "Sisobahili",
    "value": "Sisobahili"
  },
  {
    "id": "1214072023",
    "district_id": "121407",
    "label": "Loloabolo",
    "value": "Loloabolo"
  },
  {
    "id": "1214072024",
    "district_id": "121407",
    "label": "Hilisalo'o",
    "value": "Hilisalo'o"
  },
  {
    "id": "1214072026",
    "district_id": "121407",
    "label": "Sirofi",
    "value": "Sirofi"
  },
  {
    "id": "1214072027",
    "district_id": "121407",
    "label": "Hilindraso",
    "value": "Hilindraso"
  },
  {
    "id": "1214072028",
    "district_id": "121407",
    "label": "Hilimaera",
    "value": "Hilimaera"
  },
  {
    "id": "1214072029",
    "district_id": "121407",
    "label": "Boholu",
    "value": "Boholu"
  },
  {
    "id": "1214072030",
    "district_id": "121407",
    "label": "Amandraya",
    "value": "Amandraya"
  },
  {
    "id": "1214072031",
    "district_id": "121407",
    "label": "Sinar Ino'o",
    "value": "Sinar Ino'o"
  },
  {
    "id": "1214072032",
    "district_id": "121407",
    "label": "Tuindrao I",
    "value": "Tuindrao I"
  },
  {
    "id": "1214072033",
    "district_id": "121407",
    "label": "Tuhemberua Amandraya",
    "value": "Tuhemberua Amandraya"
  },
  {
    "id": "1214082001",
    "district_id": "121408",
    "label": "Amuri",
    "value": "Amuri"
  },
  {
    "id": "1214082004",
    "district_id": "121408",
    "label": "Bawosalo'o Siwalawa",
    "value": "Bawosalo'o Siwalawa"
  },
  {
    "id": "1214082009",
    "district_id": "121408",
    "label": "Hilifadolo",
    "value": "Hilifadolo"
  },
  {
    "id": "1214082014",
    "district_id": "121408",
    "label": "Lolofaoso",
    "value": "Lolofaoso"
  },
  {
    "id": "1214082015",
    "district_id": "121408",
    "label": "Lolomoyo",
    "value": "Lolomoyo"
  },
  {
    "id": "1214082016",
    "district_id": "121408",
    "label": "Lolowau",
    "value": "Lolowau"
  },
  {
    "id": "1214082017",
    "district_id": "121408",
    "label": "Lolohowa",
    "value": "Lolohowa"
  },
  {
    "id": "1214082022",
    "district_id": "121408",
    "label": "Sisarahili Ekholo",
    "value": "Sisarahili Ekholo"
  },
  {
    "id": "1214082030",
    "district_id": "121408",
    "label": "Tuhegafoa",
    "value": "Tuhegafoa"
  },
  {
    "id": "1214082033",
    "district_id": "121408",
    "label": "Botohili",
    "value": "Botohili"
  },
  {
    "id": "1214082035",
    "district_id": "121408",
    "label": "Hilikara",
    "value": "Hilikara"
  },
  {
    "id": "1214082044",
    "district_id": "121408",
    "label": "Samiri",
    "value": "Samiri"
  },
  {
    "id": "1214082047",
    "district_id": "121408",
    "label": "Hilimbowo Siwalawa",
    "value": "Hilimbowo Siwalawa"
  },
  {
    "id": "1214082048",
    "district_id": "121408",
    "label": "Nituwu Boho",
    "value": "Nituwu Boho"
  },
  {
    "id": "1214092001",
    "district_id": "121409",
    "label": "Sifalago Susua",
    "value": "Sifalago Susua"
  },
  {
    "id": "1214092002",
    "district_id": "121409",
    "label": "Orahili Susua",
    "value": "Orahili Susua"
  },
  {
    "id": "1214092003",
    "district_id": "121409",
    "label": "Hiliana'a Susua",
    "value": "Hiliana'a Susua"
  },
  {
    "id": "1214092004",
    "district_id": "121409",
    "label": "Hiliorahua",
    "value": "Hiliorahua"
  },
  {
    "id": "1214092005",
    "district_id": "121409",
    "label": "Hilimboe",
    "value": "Hilimboe"
  },
  {
    "id": "1214092006",
    "district_id": "121409",
    "label": "Hilimboho",
    "value": "Hilimboho"
  },
  {
    "id": "1214092008",
    "district_id": "121409",
    "label": "Hiliwaebu",
    "value": "Hiliwaebu"
  },
  {
    "id": "1214092009",
    "district_id": "121409",
    "label": "Hilidanayao",
    "value": "Hilidanayao"
  },
  {
    "id": "1214092010",
    "district_id": "121409",
    "label": "Hilizamurugo",
    "value": "Hilizamurugo"
  },
  {
    "id": "1214092011",
    "district_id": "121409",
    "label": "Sisobahili",
    "value": "Sisobahili"
  },
  {
    "id": "1214092012",
    "district_id": "121409",
    "label": "Dao-dao Zanuwo",
    "value": "Dao-dao Zanuwo"
  },
  {
    "id": "1214092013",
    "district_id": "121409",
    "label": "Hiliorudua",
    "value": "Hiliorudua"
  },
  {
    "id": "1214092014",
    "district_id": "121409",
    "label": "Bintang Baru",
    "value": "Bintang Baru"
  },
  {
    "id": "1214092015",
    "district_id": "121409",
    "label": "Hilitobara Susua",
    "value": "Hilitobara Susua"
  },
  {
    "id": "1214092016",
    "district_id": "121409",
    "label": "Orahua Uluzoi",
    "value": "Orahua Uluzoi"
  },
  {
    "id": "1214092017",
    "district_id": "121409",
    "label": "Hilisibohou",
    "value": "Hilisibohou"
  },
  {
    "id": "1214092018",
    "district_id": "121409",
    "label": "Hiliadulosoi",
    "value": "Hiliadulosoi"
  },
  {
    "id": "1214092019",
    "district_id": "121409",
    "label": "Orahili Bo'e",
    "value": "Orahili Bo'e"
  },
  {
    "id": "1214102001",
    "district_id": "121410",
    "label": "Hilisimaetano",
    "value": "Hilisimaetano"
  },
  {
    "id": "1214102002",
    "district_id": "121410",
    "label": "Bawogosali",
    "value": "Bawogosali"
  },
  {
    "id": "1214102003",
    "district_id": "121410",
    "label": "Hilifalawu",
    "value": "Hilifalawu"
  },
  {
    "id": "1214102005",
    "district_id": "121410",
    "label": "Samadaya Hilisimaetano",
    "value": "Samadaya Hilisimaetano"
  },
  {
    "id": "1214102006",
    "district_id": "121410",
    "label": "Idala Jaya Hilisimaetano",
    "value": "Idala Jaya Hilisimaetano"
  },
  {
    "id": "1214102007",
    "district_id": "121410",
    "label": "Pekan Hilisimaetano",
    "value": "Pekan Hilisimaetano"
  },
  {
    "id": "1214102008",
    "district_id": "121410",
    "label": "Bonia Hilisimaetano",
    "value": "Bonia Hilisimaetano"
  },
  {
    "id": "1214102009",
    "district_id": "121410",
    "label": "Ndraso Hilisimaetano",
    "value": "Ndraso Hilisimaetano"
  },
  {
    "id": "1214102010",
    "district_id": "121410",
    "label": "Faomasi Hilisimaetano",
    "value": "Faomasi Hilisimaetano"
  },
  {
    "id": "1214102011",
    "district_id": "121410",
    "label": "Eho Hilisimaetano",
    "value": "Eho Hilisimaetano"
  },
  {
    "id": "1214102012",
    "district_id": "121410",
    "label": "Soto'o Hilisimaetano",
    "value": "Soto'o Hilisimaetano"
  },
  {
    "id": "1214102013",
    "district_id": "121410",
    "label": "Hiliaurifa Hilisimaetano",
    "value": "Hiliaurifa Hilisimaetano"
  },
  {
    "id": "1214102015",
    "district_id": "121410",
    "label": "Bawohosi Maniamolo",
    "value": "Bawohosi Maniamolo"
  },
  {
    "id": "1214102016",
    "district_id": "121410",
    "label": "Bawosaodano",
    "value": "Bawosaodano"
  },
  {
    "id": "1214112001",
    "district_id": "121411",
    "label": "Togizita",
    "value": "Togizita"
  },
  {
    "id": "1214112002",
    "district_id": "121411",
    "label": "Soledua",
    "value": "Soledua"
  },
  {
    "id": "1214112003",
    "district_id": "121411",
    "label": "Bawosalo'o Dao-dao",
    "value": "Bawosalo'o Dao-dao"
  },
  {
    "id": "1214112004",
    "district_id": "121411",
    "label": "Tuho'owo",
    "value": "Tuho'owo"
  },
  {
    "id": "1214112005",
    "district_id": "121411",
    "label": "Togizita I",
    "value": "Togizita I"
  },
  {
    "id": "1214112007",
    "district_id": "121411",
    "label": "Hilitoese",
    "value": "Hilitoese"
  },
  {
    "id": "1214112008",
    "district_id": "121411",
    "label": "Dao-dao Sowo",
    "value": "Dao-dao Sowo"
  },
  {
    "id": "1214112009",
    "district_id": "121411",
    "label": "Hiliadulo",
    "value": "Hiliadulo"
  },
  {
    "id": "1214112010",
    "district_id": "121411",
    "label": "Soledua I",
    "value": "Soledua I"
  },
  {
    "id": "1214112011",
    "district_id": "121411",
    "label": "Soledua II",
    "value": "Soledua II"
  },
  {
    "id": "1214122001",
    "district_id": "121412",
    "label": "Hilisataro",
    "value": "Hilisataro"
  },
  {
    "id": "1214122002",
    "district_id": "121412",
    "label": "Hilinamoniha",
    "value": "Hilinamoniha"
  },
  {
    "id": "1214122003",
    "district_id": "121412",
    "label": "Hiliamaetaluo",
    "value": "Hiliamaetaluo"
  },
  {
    "id": "1214122004",
    "district_id": "121412",
    "label": "Bawoganowo",
    "value": "Bawoganowo"
  },
  {
    "id": "1214122005",
    "district_id": "121412",
    "label": "Hili'alawa",
    "value": "Hili'alawa"
  },
  {
    "id": "1214122006",
    "district_id": "121412",
    "label": "Hilindrasoniha",
    "value": "Hilindrasoniha"
  },
  {
    "id": "1214122007",
    "district_id": "121412",
    "label": "Hilimagari",
    "value": "Hilimagari"
  },
  {
    "id": "1214122008",
    "district_id": "121412",
    "label": "Hilisataro Raya",
    "value": "Hilisataro Raya"
  },
  {
    "id": "1214122009",
    "district_id": "121412",
    "label": "Hilisataro Gewa",
    "value": "Hilisataro Gewa"
  },
  {
    "id": "1214122010",
    "district_id": "121412",
    "label": "Hilisoromi",
    "value": "Hilisoromi"
  },
  {
    "id": "1214122011",
    "district_id": "121412",
    "label": "Hiliasi",
    "value": "Hiliasi"
  },
  {
    "id": "1214122012",
    "district_id": "121412",
    "label": "Hilindraso Raya",
    "value": "Hilindraso Raya"
  },
  {
    "id": "1214122013",
    "district_id": "121412",
    "label": "Hilisataro Nandisa",
    "value": "Hilisataro Nandisa"
  },
  {
    "id": "1214122014",
    "district_id": "121412",
    "label": "Hilisataro Eho Sofayo",
    "value": "Hilisataro Eho Sofayo"
  },
  {
    "id": "1214132001",
    "district_id": "121413",
    "label": "Hilizalo'otano",
    "value": "Hilizalo'otano"
  },
  {
    "id": "1214132002",
    "district_id": "121413",
    "label": "Hilinawalo Mazino",
    "value": "Hilinawalo Mazino"
  },
  {
    "id": "1214132003",
    "district_id": "121413",
    "label": "Lawindra",
    "value": "Lawindra"
  },
  {
    "id": "1214132004",
    "district_id": "121413",
    "label": "Bawolahusa",
    "value": "Bawolahusa"
  },
  {
    "id": "1214132005",
    "district_id": "121413",
    "label": "Hilizoroi Lawa",
    "value": "Hilizoroi Lawa"
  },
  {
    "id": "1214132006",
    "district_id": "121413",
    "label": "Hilizalo'otano Laowo",
    "value": "Hilizalo'otano Laowo"
  },
  {
    "id": "1214132007",
    "district_id": "121413",
    "label": "Hilizalo'otano Larono",
    "value": "Hilizalo'otano Larono"
  },
  {
    "id": "1214132008",
    "district_id": "121413",
    "label": "Hililaza Hilinawalo Mazino",
    "value": "Hililaza Hilinawalo Mazino"
  },
  {
    "id": "1214132009",
    "district_id": "121413",
    "label": "Hilifondege Hilizoroilawa",
    "value": "Hilifondege Hilizoroilawa"
  },
  {
    "id": "1214132010",
    "district_id": "121413",
    "label": "Lolomboli",
    "value": "Lolomboli"
  },
  {
    "id": "1214132011",
    "district_id": "121413",
    "label": "Bawolahusa Doli-doli",
    "value": "Bawolahusa Doli-doli"
  },
  {
    "id": "1214142001",
    "district_id": "121414",
    "label": "Lawindra",
    "value": "Lawindra"
  },
  {
    "id": "1214142003",
    "district_id": "121414",
    "label": "Sifaoro'asi Mola",
    "value": "Sifaoro'asi Mola"
  },
  {
    "id": "1214142004",
    "district_id": "121414",
    "label": "Hiliuso",
    "value": "Hiliuso"
  },
  {
    "id": "1214142005",
    "district_id": "121414",
    "label": "Balohili Mola",
    "value": "Balohili Mola"
  },
  {
    "id": "1214142006",
    "district_id": "121414",
    "label": "Ambukha",
    "value": "Ambukha"
  },
  {
    "id": "1214142007",
    "district_id": "121414",
    "label": "Tobualo",
    "value": "Tobualo"
  },
  {
    "id": "1214142008",
    "district_id": "121414",
    "label": "Hilibadalu",
    "value": "Hilibadalu"
  },
  {
    "id": "1214142009",
    "district_id": "121414",
    "label": "Foikhugaga",
    "value": "Foikhugaga"
  },
  {
    "id": "1214142010",
    "district_id": "121414",
    "label": "Orlin",
    "value": "Orlin"
  },
  {
    "id": "1214142011",
    "district_id": "121414",
    "label": "Orahili Mola",
    "value": "Orahili Mola"
  },
  {
    "id": "1214142012",
    "district_id": "121414",
    "label": "Umbunasi",
    "value": "Umbunasi"
  },
  {
    "id": "1214152001",
    "district_id": "121415",
    "label": "Hilitotao",
    "value": "Hilitotao"
  },
  {
    "id": "1214152002",
    "district_id": "121415",
    "label": "Hiliorudua",
    "value": "Hiliorudua"
  },
  {
    "id": "1214152003",
    "district_id": "121415",
    "label": "Sisobambowo",
    "value": "Sisobambowo"
  },
  {
    "id": "1214152004",
    "district_id": "121415",
    "label": "Hiliamauzula",
    "value": "Hiliamauzula"
  },
  {
    "id": "1214152005",
    "district_id": "121415",
    "label": "Aramo",
    "value": "Aramo"
  },
  {
    "id": "1214152006",
    "district_id": "121415",
    "label": "Sikhorilafau",
    "value": "Sikhorilafau"
  },
  {
    "id": "1214152007",
    "district_id": "121415",
    "label": "Hilifadolo",
    "value": "Hilifadolo"
  },
  {
    "id": "1214152008",
    "district_id": "121415",
    "label": "Hume",
    "value": "Hume"
  },
  {
    "id": "1214152009",
    "district_id": "121415",
    "label": "Dao-dao",
    "value": "Dao-dao"
  },
  {
    "id": "1214152010",
    "district_id": "121415",
    "label": "Hilimagiao",
    "value": "Hilimagiao"
  },
  {
    "id": "1214152011",
    "district_id": "121415",
    "label": "Hilisawato",
    "value": "Hilisawato"
  },
  {
    "id": "1214152012",
    "district_id": "121415",
    "label": "Balohao",
    "value": "Balohao"
  },
  {
    "id": "1214152013",
    "district_id": "121415",
    "label": "Hiligafoa",
    "value": "Hiligafoa"
  },
  {
    "id": "1214152014",
    "district_id": "121415",
    "label": "Hilimbowo",
    "value": "Hilimbowo"
  },
  {
    "id": "1214152015",
    "district_id": "121415",
    "label": "Hilimezaya",
    "value": "Hilimezaya"
  },
  {
    "id": "1214152016",
    "district_id": "121415",
    "label": "Hiliadolowa",
    "value": "Hiliadolowa"
  },
  {
    "id": "1214152017",
    "district_id": "121415",
    "label": "Hiligumbu",
    "value": "Hiligumbu"
  },
  {
    "id": "1214152018",
    "district_id": "121415",
    "label": "Bagoa",
    "value": "Bagoa"
  },
  {
    "id": "1214162001",
    "district_id": "121416",
    "label": "Labuhan Hiu",
    "value": "Labuhan Hiu"
  },
  {
    "id": "1214162002",
    "district_id": "121416",
    "label": "Labuhan Bazau",
    "value": "Labuhan Bazau"
  },
  {
    "id": "1214162003",
    "district_id": "121416",
    "label": "Labuan Rima",
    "value": "Labuan Rima"
  },
  {
    "id": "1214162004",
    "district_id": "121416",
    "label": "Lambak",
    "value": "Lambak"
  },
  {
    "id": "1214162005",
    "district_id": "121416",
    "label": "Bais",
    "value": "Bais"
  },
  {
    "id": "1214162006",
    "district_id": "121416",
    "label": "Mahang Labara",
    "value": "Mahang Labara"
  },
  {
    "id": "1214162007",
    "district_id": "121416",
    "label": "Adam",
    "value": "Adam"
  },
  {
    "id": "1214162008",
    "district_id": "121416",
    "label": "Labara",
    "value": "Labara"
  },
  {
    "id": "1214162009",
    "district_id": "121416",
    "label": "Bais Baru",
    "value": "Bais Baru"
  },
  {
    "id": "1214162010",
    "district_id": "121416",
    "label": "Labuan Rima Baru",
    "value": "Labuan Rima Baru"
  },
  {
    "id": "1214172001",
    "district_id": "121417",
    "label": "Tetegawa'ai",
    "value": "Tetegawa'ai"
  },
  {
    "id": "1214172002",
    "district_id": "121417",
    "label": "Hilimbaruzo",
    "value": "Hilimbaruzo"
  },
  {
    "id": "1214172003",
    "district_id": "121417",
    "label": "Luahandroito",
    "value": "Luahandroito"
  },
  {
    "id": "1214172005",
    "district_id": "121417",
    "label": "Orahuahili",
    "value": "Orahuahili"
  },
  {
    "id": "1214172006",
    "district_id": "121417",
    "label": "Ulu Mazo",
    "value": "Ulu Mazo"
  },
  {
    "id": "1214172007",
    "district_id": "121417",
    "label": "Guigui",
    "value": "Guigui"
  },
  {
    "id": "1214172009",
    "district_id": "121417",
    "label": "Hilimaufa",
    "value": "Hilimaufa"
  },
  {
    "id": "1214172010",
    "district_id": "121417",
    "label": "Siofabanua",
    "value": "Siofabanua"
  },
  {
    "id": "1214172011",
    "district_id": "121417",
    "label": "Tetegawa'ai Ehomo",
    "value": "Tetegawa'ai Ehomo"
  },
  {
    "id": "1214172013",
    "district_id": "121417",
    "label": "Tafulu",
    "value": "Tafulu"
  },
  {
    "id": "1214182001",
    "district_id": "121418",
    "label": "Bawomataluo",
    "value": "Bawomataluo"
  },
  {
    "id": "1214182002",
    "district_id": "121418",
    "label": "Orahili Fau",
    "value": "Orahili Fau"
  },
  {
    "id": "1214182003",
    "district_id": "121418",
    "label": "Bawonahono",
    "value": "Bawonahono"
  },
  {
    "id": "1214182005",
    "district_id": "121418",
    "label": "Siwalawa",
    "value": "Siwalawa"
  },
  {
    "id": "1214182006",
    "district_id": "121418",
    "label": "Onohondro",
    "value": "Onohondro"
  },
  {
    "id": "1214182007",
    "district_id": "121418",
    "label": "Hilinawalo Fau",
    "value": "Hilinawalo Fau"
  },
  {
    "id": "1214182008",
    "district_id": "121418",
    "label": "Lahusa Fau",
    "value": "Lahusa Fau"
  },
  {
    "id": "1214182011",
    "district_id": "121418",
    "label": "Hilizihono",
    "value": "Hilizihono"
  },
  {
    "id": "1214182012",
    "district_id": "121418",
    "label": "Hiliofonaluo",
    "value": "Hiliofonaluo"
  },
  {
    "id": "1214182015",
    "district_id": "121418",
    "label": "Bawofanayama",
    "value": "Bawofanayama"
  },
  {
    "id": "1214182017",
    "district_id": "121418",
    "label": "Hilikara Maha",
    "value": "Hilikara Maha"
  },
  {
    "id": "1214182018",
    "district_id": "121418",
    "label": "Hilisalawa",
    "value": "Hilisalawa"
  },
  {
    "id": "1214182019",
    "district_id": "121418",
    "label": "Hiligito",
    "value": "Hiligito"
  },
  {
    "id": "1214182020",
    "district_id": "121418",
    "label": "Siliwulawa",
    "value": "Siliwulawa"
  },
  {
    "id": "1214182021",
    "district_id": "121418",
    "label": "Hilifarokha Lawa",
    "value": "Hilifarokha Lawa"
  },
  {
    "id": "1214182022",
    "district_id": "121418",
    "label": "Eho Orahili",
    "value": "Eho Orahili"
  },
  {
    "id": "1214182023",
    "district_id": "121418",
    "label": "Ete Batu",
    "value": "Ete Batu"
  },
  {
    "id": "1214192001",
    "district_id": "121419",
    "label": "Sambulu",
    "value": "Sambulu"
  },
  {
    "id": "1214192002",
    "district_id": "121419",
    "label": "Orahili Ulunoyo",
    "value": "Orahili Ulunoyo"
  },
  {
    "id": "1214192003",
    "district_id": "121419",
    "label": "Hiliwaebu",
    "value": "Hiliwaebu"
  },
  {
    "id": "1214192004",
    "district_id": "121419",
    "label": "Amorosa",
    "value": "Amorosa"
  },
  {
    "id": "1214192005",
    "district_id": "121419",
    "label": "Marao",
    "value": "Marao"
  },
  {
    "id": "1214192006",
    "district_id": "121419",
    "label": "Borowosi",
    "value": "Borowosi"
  },
  {
    "id": "1214192007",
    "district_id": "121419",
    "label": "Hilimaera",
    "value": "Hilimaera"
  },
  {
    "id": "1214192008",
    "district_id": "121419",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1214192009",
    "district_id": "121419",
    "label": "Loloana'a",
    "value": "Loloana'a"
  },
  {
    "id": "1214192010",
    "district_id": "121419",
    "label": "Hilifakhe",
    "value": "Hilifakhe"
  },
  {
    "id": "1214192011",
    "district_id": "121419",
    "label": "Bawo Lolomatua",
    "value": "Bawo Lolomatua"
  },
  {
    "id": "1214192012",
    "district_id": "121419",
    "label": "Ambukha I",
    "value": "Ambukha I"
  },
  {
    "id": "1214192013",
    "district_id": "121419",
    "label": "Puncak Lolomatua",
    "value": "Puncak Lolomatua"
  },
  {
    "id": "1214202001",
    "district_id": "121420",
    "label": "Ehosakhozi",
    "value": "Ehosakhozi"
  },
  {
    "id": "1214202002",
    "district_id": "121420",
    "label": "Bawohosi",
    "value": "Bawohosi"
  },
  {
    "id": "1214202003",
    "district_id": "121420",
    "label": "Hilizoliga",
    "value": "Hilizoliga"
  },
  {
    "id": "1214202004",
    "district_id": "121420",
    "label": "Olayama",
    "value": "Olayama"
  },
  {
    "id": "1214202005",
    "district_id": "121420",
    "label": "Sifaoroasi",
    "value": "Sifaoroasi"
  },
  {
    "id": "1214202006",
    "district_id": "121420",
    "label": "Sifalago",
    "value": "Sifalago"
  },
  {
    "id": "1214202007",
    "district_id": "121420",
    "label": "Lalimanawa",
    "value": "Lalimanawa"
  },
  {
    "id": "1214202008",
    "district_id": "121420",
    "label": "Tundrumbaho",
    "value": "Tundrumbaho"
  },
  {
    "id": "1214202009",
    "district_id": "121420",
    "label": "Hiliuso",
    "value": "Hiliuso"
  },
  {
    "id": "1214202010",
    "district_id": "121420",
    "label": "Sisarahili Huruna",
    "value": "Sisarahili Huruna"
  },
  {
    "id": "1214202011",
    "district_id": "121420",
    "label": "Tarewe",
    "value": "Tarewe"
  },
  {
    "id": "1214202012",
    "district_id": "121420",
    "label": "Fadoro Tuhemberua",
    "value": "Fadoro Tuhemberua"
  },
  {
    "id": "1214202013",
    "district_id": "121420",
    "label": "Hilifalawu",
    "value": "Hilifalawu"
  },
  {
    "id": "1214202014",
    "district_id": "121420",
    "label": "Luahamofakhe",
    "value": "Luahamofakhe"
  },
  {
    "id": "1214202015",
    "district_id": "121420",
    "label": "Sifaoroasi Huruna",
    "value": "Sifaoroasi Huruna"
  },
  {
    "id": "1214202016",
    "district_id": "121420",
    "label": "Hilimanawa",
    "value": "Hilimanawa"
  },
  {
    "id": "1214202017",
    "district_id": "121420",
    "label": "Mombawa Oladano",
    "value": "Mombawa Oladano"
  },
  {
    "id": "1214212001",
    "district_id": "121421",
    "label": "Hilinamazihono",
    "value": "Hilinamazihono"
  },
  {
    "id": "1214212002",
    "district_id": "121421",
    "label": "Hilimbowo",
    "value": "Hilimbowo"
  },
  {
    "id": "1214212003",
    "district_id": "121421",
    "label": "Simandraolo",
    "value": "Simandraolo"
  },
  {
    "id": "1214212004",
    "district_id": "121421",
    "label": "Bawosalo'o Bawoluo",
    "value": "Bawosalo'o Bawoluo"
  },
  {
    "id": "1214212005",
    "district_id": "121421",
    "label": "Hilimbuasi",
    "value": "Hilimbuasi"
  },
  {
    "id": "1214212006",
    "district_id": "121421",
    "label": "Simandraolo O'o'u",
    "value": "Simandraolo O'o'u"
  },
  {
    "id": "1214212007",
    "district_id": "121421",
    "label": "Lolomaya",
    "value": "Lolomaya"
  },
  {
    "id": "1214212008",
    "district_id": "121421",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1214212009",
    "district_id": "121421",
    "label": "Hiliorudua",
    "value": "Hiliorudua"
  },
  {
    "id": "1214212010",
    "district_id": "121421",
    "label": "Hilinamazihono Moale",
    "value": "Hilinamazihono Moale"
  },
  {
    "id": "1214212011",
    "district_id": "121421",
    "label": "Balohili O'o'u",
    "value": "Balohili O'o'u"
  },
  {
    "id": "1214222001",
    "district_id": "121422",
    "label": "Fadoro Ewo",
    "value": "Fadoro Ewo"
  },
  {
    "id": "1214222002",
    "district_id": "121422",
    "label": "Soroma'asi",
    "value": "Soroma'asi"
  },
  {
    "id": "1214222003",
    "district_id": "121422",
    "label": "Lauso",
    "value": "Lauso"
  },
  {
    "id": "1214222004",
    "district_id": "121422",
    "label": "Sisarahili Oyo",
    "value": "Sisarahili Oyo"
  },
  {
    "id": "1214222005",
    "district_id": "121422",
    "label": "Sisobahili Huruna",
    "value": "Sisobahili Huruna"
  },
  {
    "id": "1214222006",
    "district_id": "121422",
    "label": "Onohazumba",
    "value": "Onohazumba"
  },
  {
    "id": "1214222007",
    "district_id": "121422",
    "label": "Hiliweto",
    "value": "Hiliweto"
  },
  {
    "id": "1214222008",
    "district_id": "121422",
    "label": "Orahili Huruna",
    "value": "Orahili Huruna"
  },
  {
    "id": "1214222009",
    "district_id": "121422",
    "label": "Tetehosi",
    "value": "Tetehosi"
  },
  {
    "id": "1214222010",
    "district_id": "121422",
    "label": "Helefanikha",
    "value": "Helefanikha"
  },
  {
    "id": "1214232001",
    "district_id": "121423",
    "label": "Maluo",
    "value": "Maluo"
  },
  {
    "id": "1214232002",
    "district_id": "121423",
    "label": "Hiliadulo",
    "value": "Hiliadulo"
  },
  {
    "id": "1214232003",
    "district_id": "121423",
    "label": "Umbuasi",
    "value": "Umbuasi"
  },
  {
    "id": "1214232004",
    "district_id": "121423",
    "label": "Sisobahili Siwalawa",
    "value": "Sisobahili Siwalawa"
  },
  {
    "id": "1214232005",
    "district_id": "121423",
    "label": "Manawadano",
    "value": "Manawadano"
  },
  {
    "id": "1214232006",
    "district_id": "121423",
    "label": "Talio",
    "value": "Talio"
  },
  {
    "id": "1214232007",
    "district_id": "121423",
    "label": "Anaoma",
    "value": "Anaoma"
  },
  {
    "id": "1214232008",
    "district_id": "121423",
    "label": "Berua Siwalawa",
    "value": "Berua Siwalawa"
  },
  {
    "id": "1214232009",
    "district_id": "121423",
    "label": "Umbuasi Barat",
    "value": "Umbuasi Barat"
  },
  {
    "id": "1214232010",
    "district_id": "121423",
    "label": "Hiligodu",
    "value": "Hiligodu"
  },
  {
    "id": "1214232011",
    "district_id": "121423",
    "label": "Bukit Burasi",
    "value": "Bukit Burasi"
  },
  {
    "id": "1214242001",
    "district_id": "121424",
    "label": "Fondrakoraya",
    "value": "Fondrakoraya"
  },
  {
    "id": "1214242002",
    "district_id": "121424",
    "label": "Orudua Sibohou",
    "value": "Orudua Sibohou"
  },
  {
    "id": "1214242003",
    "district_id": "121424",
    "label": "Sisarahili Susua",
    "value": "Sisarahili Susua"
  },
  {
    "id": "1214242004",
    "district_id": "121424",
    "label": "Susua",
    "value": "Susua"
  },
  {
    "id": "1214242005",
    "district_id": "121424",
    "label": "Lahusa Susua",
    "value": "Lahusa Susua"
  },
  {
    "id": "1214242006",
    "district_id": "121424",
    "label": "Ramba-ramba",
    "value": "Ramba-ramba"
  },
  {
    "id": "1214242007",
    "district_id": "121424",
    "label": "Hilinifaoso",
    "value": "Hilinifaoso"
  },
  {
    "id": "1214242008",
    "district_id": "121424",
    "label": "Sifaoroasi",
    "value": "Sifaoroasi"
  },
  {
    "id": "1214242009",
    "district_id": "121424",
    "label": "Amandraya",
    "value": "Amandraya"
  },
  {
    "id": "1214242010",
    "district_id": "121424",
    "label": "Foikhu Fondrako",
    "value": "Foikhu Fondrako"
  },
  {
    "id": "1214242011",
    "district_id": "121424",
    "label": "Hiliwosi",
    "value": "Hiliwosi"
  },
  {
    "id": "1214242012",
    "district_id": "121424",
    "label": "Orahili Fondrako",
    "value": "Orahili Fondrako"
  },
  {
    "id": "1214252001",
    "district_id": "121425",
    "label": "Mondrowe",
    "value": "Mondrowe"
  },
  {
    "id": "1214252002",
    "district_id": "121425",
    "label": "Hilidohona",
    "value": "Hilidohona"
  },
  {
    "id": "1214252003",
    "district_id": "121425",
    "label": "Hililaora",
    "value": "Hililaora"
  },
  {
    "id": "1214252004",
    "district_id": "121425",
    "label": "Uluidanoduo",
    "value": "Uluidanoduo"
  },
  {
    "id": "1214252005",
    "district_id": "121425",
    "label": "Umbu Sohahau",
    "value": "Umbu Sohahau"
  },
  {
    "id": "1214252006",
    "district_id": "121425",
    "label": "Hilisao'oto",
    "value": "Hilisao'oto"
  },
  {
    "id": "1214252007",
    "district_id": "121425",
    "label": "Taluzusua",
    "value": "Taluzusua"
  },
  {
    "id": "1214252008",
    "district_id": "121425",
    "label": "Hilizanuwo",
    "value": "Hilizanuwo"
  },
  {
    "id": "1214252009",
    "district_id": "121425",
    "label": "Hoya",
    "value": "Hoya"
  },
  {
    "id": "1214252010",
    "district_id": "121425",
    "label": "Na'ai",
    "value": "Na'ai"
  },
  {
    "id": "1214252011",
    "district_id": "121425",
    "label": "Olanori",
    "value": "Olanori"
  },
  {
    "id": "1214262001",
    "district_id": "121426",
    "label": "Sifitubanua",
    "value": "Sifitubanua"
  },
  {
    "id": "1214262002",
    "district_id": "121426",
    "label": "Siwalu Banua",
    "value": "Siwalu Banua"
  },
  {
    "id": "1214262003",
    "district_id": "121426",
    "label": "Sitolu Banua",
    "value": "Sitolu Banua"
  },
  {
    "id": "1214262004",
    "district_id": "121426",
    "label": "Silima Banua",
    "value": "Silima Banua"
  },
  {
    "id": "1214262005",
    "district_id": "121426",
    "label": "Mehaga",
    "value": "Mehaga"
  },
  {
    "id": "1214262006",
    "district_id": "121426",
    "label": "Sihare'o",
    "value": "Sihare'o"
  },
  {
    "id": "1214262007",
    "district_id": "121426",
    "label": "Golambanua II",
    "value": "Golambanua II"
  },
  {
    "id": "1214262008",
    "district_id": "121426",
    "label": "Oladano",
    "value": "Oladano"
  },
  {
    "id": "1214262009",
    "district_id": "121426",
    "label": "Fanedanu",
    "value": "Fanedanu"
  },
  {
    "id": "1214262010",
    "district_id": "121426",
    "label": "Hiliorahua Tasua",
    "value": "Hiliorahua Tasua"
  },
  {
    "id": "1214262011",
    "district_id": "121426",
    "label": "Somambawa",
    "value": "Somambawa"
  },
  {
    "id": "1214262012",
    "district_id": "121426",
    "label": "Gabungan Tasua",
    "value": "Gabungan Tasua"
  },
  {
    "id": "1214262013",
    "district_id": "121426",
    "label": "Hilialawa",
    "value": "Hilialawa"
  },
  {
    "id": "1214262014",
    "district_id": "121426",
    "label": "Sinar Susua",
    "value": "Sinar Susua"
  },
  {
    "id": "1214272001",
    "district_id": "121427",
    "label": "Sifalago Gomo",
    "value": "Sifalago Gomo"
  },
  {
    "id": "1214272002",
    "district_id": "121427",
    "label": "Balohili Gomo",
    "value": "Balohili Gomo"
  },
  {
    "id": "1214272003",
    "district_id": "121427",
    "label": "Bohalu",
    "value": "Bohalu"
  },
  {
    "id": "1214272004",
    "district_id": "121427",
    "label": "Orsedes",
    "value": "Orsedes"
  },
  {
    "id": "1214272005",
    "district_id": "121427",
    "label": "Sinar Helaowo",
    "value": "Sinar Helaowo"
  },
  {
    "id": "1214272006",
    "district_id": "121427",
    "label": "Siholi",
    "value": "Siholi"
  },
  {
    "id": "1214272007",
    "district_id": "121427",
    "label": "Tuhegafoa",
    "value": "Tuhegafoa"
  },
  {
    "id": "1214272008",
    "district_id": "121427",
    "label": "Perjuangan",
    "value": "Perjuangan"
  },
  {
    "id": "1214272009",
    "district_id": "121427",
    "label": "Lewa-lewa",
    "value": "Lewa-lewa"
  },
  {
    "id": "1214272010",
    "district_id": "121427",
    "label": "Siraha",
    "value": "Siraha"
  },
  {
    "id": "1214282001",
    "district_id": "121428",
    "label": "Gobo",
    "value": "Gobo"
  },
  {
    "id": "1214282002",
    "district_id": "121428",
    "label": "Silina",
    "value": "Silina"
  },
  {
    "id": "1214282003",
    "district_id": "121428",
    "label": "Maufa",
    "value": "Maufa"
  },
  {
    "id": "1214282004",
    "district_id": "121428",
    "label": "Gondia",
    "value": "Gondia"
  },
  {
    "id": "1214282005",
    "district_id": "121428",
    "label": "Gobo Baru",
    "value": "Gobo Baru"
  },
  {
    "id": "1214282006",
    "district_id": "121428",
    "label": "Silina Baru",
    "value": "Silina Baru"
  },
  {
    "id": "1214292001",
    "district_id": "121429",
    "label": "Fuge",
    "value": "Fuge"
  },
  {
    "id": "1214292002",
    "district_id": "121429",
    "label": "Luaha Idano Pono",
    "value": "Luaha Idano Pono"
  },
  {
    "id": "1214292003",
    "district_id": "121429",
    "label": "Sigese",
    "value": "Sigese"
  },
  {
    "id": "1214292004",
    "district_id": "121429",
    "label": "Bawositora",
    "value": "Bawositora"
  },
  {
    "id": "1214292005",
    "district_id": "121429",
    "label": "Bintuang",
    "value": "Bintuang"
  },
  {
    "id": "1214292006",
    "district_id": "121429",
    "label": "Sibaranun",
    "value": "Sibaranun"
  },
  {
    "id": "1214292007",
    "district_id": "121429",
    "label": "Bawolawindra",
    "value": "Bawolawindra"
  },
  {
    "id": "1214292008",
    "district_id": "121429",
    "label": "Hayo",
    "value": "Hayo"
  },
  {
    "id": "1214292009",
    "district_id": "121429",
    "label": "Hilizamorogotano",
    "value": "Hilizamorogotano"
  },
  {
    "id": "1214302001",
    "district_id": "121430",
    "label": "Siofa Banua Lorang",
    "value": "Siofa Banua Lorang"
  },
  {
    "id": "1214302002",
    "district_id": "121430",
    "label": "Silima Banua Marit",
    "value": "Silima Banua Marit"
  },
  {
    "id": "1214302003",
    "district_id": "121430",
    "label": "Zia Biang",
    "value": "Zia Biang"
  },
  {
    "id": "1214302004",
    "district_id": "121430",
    "label": "Limo Biang",
    "value": "Limo Biang"
  },
  {
    "id": "1214302005",
    "district_id": "121430",
    "label": "Memong",
    "value": "Memong"
  },
  {
    "id": "1214302006",
    "district_id": "121430",
    "label": "Bale-bale",
    "value": "Bale-bale"
  },
  {
    "id": "1214302007",
    "district_id": "121430",
    "label": "Teluk Limo",
    "value": "Teluk Limo"
  },
  {
    "id": "1214302008",
    "district_id": "121430",
    "label": "Wawa",
    "value": "Wawa"
  },
  {
    "id": "1214302009",
    "district_id": "121430",
    "label": "Marit Baru",
    "value": "Marit Baru"
  },
  {
    "id": "1214302010",
    "district_id": "121430",
    "label": "Majino Lorang",
    "value": "Majino Lorang"
  },
  {
    "id": "1214302011",
    "district_id": "121430",
    "label": "Afore Gobo",
    "value": "Afore Gobo"
  },
  {
    "id": "1214302012",
    "district_id": "121430",
    "label": "Bale-bale Sibohou",
    "value": "Bale-bale Sibohou"
  },
  {
    "id": "1214312001",
    "district_id": "121431",
    "label": "Baluta",
    "value": "Baluta"
  },
  {
    "id": "1214312002",
    "district_id": "121431",
    "label": "Eho Baluta",
    "value": "Eho Baluta"
  },
  {
    "id": "1214312003",
    "district_id": "121431",
    "label": "Bawo Ofuloa",
    "value": "Bawo Ofuloa"
  },
  {
    "id": "1214312004",
    "district_id": "121431",
    "label": "Hale Baluta",
    "value": "Hale Baluta"
  },
  {
    "id": "1214312005",
    "district_id": "121431",
    "label": "Bawo Orudua",
    "value": "Bawo Orudua"
  },
  {
    "id": "1214312006",
    "district_id": "121431",
    "label": "Jeke",
    "value": "Jeke"
  },
  {
    "id": "1214312007",
    "district_id": "121431",
    "label": "Makole",
    "value": "Makole"
  },
  {
    "id": "1214312008",
    "district_id": "121431",
    "label": "Sifauruasi",
    "value": "Sifauruasi"
  },
  {
    "id": "1214312009",
    "district_id": "121431",
    "label": "Bawo Analita Saeru",
    "value": "Bawo Analita Saeru"
  },
  {
    "id": "1214312010",
    "district_id": "121431",
    "label": "Saeru Melayu",
    "value": "Saeru Melayu"
  },
  {
    "id": "1214312011",
    "district_id": "121431",
    "label": "Hiligeho Sogawu",
    "value": "Hiligeho Sogawu"
  },
  {
    "id": "1214312012",
    "district_id": "121431",
    "label": "Hiliomasio",
    "value": "Hiliomasio"
  },
  {
    "id": "1214322001",
    "district_id": "121432",
    "label": "Botohilitano",
    "value": "Botohilitano"
  },
  {
    "id": "1214322002",
    "district_id": "121432",
    "label": "Botohilisalo'o",
    "value": "Botohilisalo'o"
  },
  {
    "id": "1214322003",
    "district_id": "121432",
    "label": "Lagundri",
    "value": "Lagundri"
  },
  {
    "id": "1214322004",
    "district_id": "121432",
    "label": "Orahili Faomasi",
    "value": "Orahili Faomasi"
  },
  {
    "id": "1214322005",
    "district_id": "121432",
    "label": "Hiliamaetaniha",
    "value": "Hiliamaetaniha"
  },
  {
    "id": "1214322006",
    "district_id": "121432",
    "label": "Sondregeasi",
    "value": "Sondregeasi"
  },
  {
    "id": "1214322007",
    "district_id": "121432",
    "label": "Botohili Silambo",
    "value": "Botohili Silambo"
  },
  {
    "id": "1214322008",
    "district_id": "121432",
    "label": "Botohili Sorake",
    "value": "Botohili Sorake"
  },
  {
    "id": "1214322009",
    "district_id": "121432",
    "label": "Hilimaenamolo",
    "value": "Hilimaenamolo"
  },
  {
    "id": "1214322010",
    "district_id": "121432",
    "label": "Bawomaenamolo",
    "value": "Bawomaenamolo"
  },
  {
    "id": "1214332001",
    "district_id": "121433",
    "label": "Hilifalago",
    "value": "Hilifalago"
  },
  {
    "id": "1214332002",
    "district_id": "121433",
    "label": "Hilimondregeraya",
    "value": "Hilimondregeraya"
  },
  {
    "id": "1214332003",
    "district_id": "121433",
    "label": "Hilinamozaua",
    "value": "Hilinamozaua"
  },
  {
    "id": "1214332004",
    "district_id": "121433",
    "label": "Hilifarono",
    "value": "Hilifarono"
  },
  {
    "id": "1214332005",
    "district_id": "121433",
    "label": "Hilionaha",
    "value": "Hilionaha"
  },
  {
    "id": "1214332006",
    "district_id": "121433",
    "label": "Hilikara",
    "value": "Hilikara"
  },
  {
    "id": "1214332007",
    "district_id": "121433",
    "label": "Hilialito Saua",
    "value": "Hilialito Saua"
  },
  {
    "id": "1214332008",
    "district_id": "121433",
    "label": "Hilinamozaua Raya",
    "value": "Hilinamozaua Raya"
  },
  {
    "id": "1214332009",
    "district_id": "121433",
    "label": "Hilifalago Raya",
    "value": "Hilifalago Raya"
  },
  {
    "id": "1214332010",
    "district_id": "121433",
    "label": "Hilisanekhehosi",
    "value": "Hilisanekhehosi"
  },
  {
    "id": "1214342001",
    "district_id": "121434",
    "label": "Fanedanu",
    "value": "Fanedanu"
  },
  {
    "id": "1214342002",
    "district_id": "121434",
    "label": "Fanedanu Sibohou",
    "value": "Fanedanu Sibohou"
  },
  {
    "id": "1214342003",
    "district_id": "121434",
    "label": "Lawa-lawa Luo Idanotae",
    "value": "Lawa-lawa Luo Idanotae"
  },
  {
    "id": "1214342004",
    "district_id": "121434",
    "label": "Dao-dao Zanuwo Idano Tae",
    "value": "Dao-dao Zanuwo Idano Tae"
  },
  {
    "id": "1214342005",
    "district_id": "121434",
    "label": "Sisiwa Ewali",
    "value": "Sisiwa Ewali"
  },
  {
    "id": "1214342006",
    "district_id": "121434",
    "label": "Sisarahili Ewo",
    "value": "Sisarahili Ewo"
  },
  {
    "id": "1214342007",
    "district_id": "121434",
    "label": "Hilialo'oa",
    "value": "Hilialo'oa"
  },
  {
    "id": "1214342008",
    "district_id": "121434",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "1214342009",
    "district_id": "121434",
    "label": "Lolozukhu",
    "value": "Lolozukhu"
  },
  {
    "id": "1214342010",
    "district_id": "121434",
    "label": "Silima Banua Umbunasi",
    "value": "Silima Banua Umbunasi"
  },
  {
    "id": "1214342011",
    "district_id": "121434",
    "label": "Sindrolo",
    "value": "Sindrolo"
  },
  {
    "id": "1214352001",
    "district_id": "121435",
    "label": "Lahusa Idanotae",
    "value": "Lahusa Idanotae"
  },
  {
    "id": "1214352002",
    "district_id": "121435",
    "label": "Hiliserangkai",
    "value": "Hiliserangkai"
  },
  {
    "id": "1214352003",
    "district_id": "121435",
    "label": "Orahua",
    "value": "Orahua"
  },
  {
    "id": "1214352004",
    "district_id": "121435",
    "label": "Hilimbowo Idanotae",
    "value": "Hilimbowo Idanotae"
  },
  {
    "id": "1214352005",
    "district_id": "121435",
    "label": "Sirahia",
    "value": "Sirahia"
  },
  {
    "id": "1214352006",
    "district_id": "121435",
    "label": "Umbu Idanotae",
    "value": "Umbu Idanotae"
  },
  {
    "id": "1214352007",
    "district_id": "121435",
    "label": "Awoni",
    "value": "Awoni"
  },
  {
    "id": "1214352008",
    "district_id": "121435",
    "label": "Hilisalo'o",
    "value": "Hilisalo'o"
  },
  {
    "id": "1214352009",
    "district_id": "121435",
    "label": "Balombaruzo Orahua",
    "value": "Balombaruzo Orahua"
  },
  {
    "id": "1214352010",
    "district_id": "121435",
    "label": "Harefa Orahua",
    "value": "Harefa Orahua"
  },
  {
    "id": "1214352011",
    "district_id": "121435",
    "label": "Hiligabungan",
    "value": "Hiligabungan"
  },
  {
    "id": "1214352012",
    "district_id": "121435",
    "label": "Buhawa",
    "value": "Buhawa"
  },
  {
    "id": "1215012001",
    "district_id": "121501",
    "label": "Tanjung Meriah",
    "value": "Tanjung Meriah"
  },
  {
    "id": "1215012002",
    "district_id": "121501",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1215012003",
    "district_id": "121501",
    "label": "Kaban Tengah",
    "value": "Kaban Tengah"
  },
  {
    "id": "1215012004",
    "district_id": "121501",
    "label": "Bandar Baru",
    "value": "Bandar Baru"
  },
  {
    "id": "1215012005",
    "district_id": "121501",
    "label": "Simberruna",
    "value": "Simberruna"
  },
  {
    "id": "1215012006",
    "district_id": "121501",
    "label": "Perolihen",
    "value": "Perolihen"
  },
  {
    "id": "1215012007",
    "district_id": "121501",
    "label": "Maholida",
    "value": "Maholida"
  },
  {
    "id": "1215012008",
    "district_id": "121501",
    "label": "Perjaga",
    "value": "Perjaga"
  },
  {
    "id": "1215012009",
    "district_id": "121501",
    "label": "Malum",
    "value": "Malum"
  },
  {
    "id": "1215012010",
    "district_id": "121501",
    "label": "Mbinalun",
    "value": "Mbinalun"
  },
  {
    "id": "1215022001",
    "district_id": "121502",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "1215022002",
    "district_id": "121502",
    "label": "Kuta Saga",
    "value": "Kuta Saga"
  },
  {
    "id": "1215022003",
    "district_id": "121502",
    "label": "Kuta Meriah",
    "value": "Kuta Meriah"
  },
  {
    "id": "1215022004",
    "district_id": "121502",
    "label": "Kuta Dame",
    "value": "Kuta Dame"
  },
  {
    "id": "1215022005",
    "district_id": "121502",
    "label": "Perpulungen",
    "value": "Perpulungen"
  },
  {
    "id": "1215022006",
    "district_id": "121502",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1215022014",
    "district_id": "121502",
    "label": "Majanggut I",
    "value": "Majanggut I"
  },
  {
    "id": "1215022015",
    "district_id": "121502",
    "label": "Majanggut II",
    "value": "Majanggut II"
  },
  {
    "id": "1215022016",
    "district_id": "121502",
    "label": "Surung Mersada",
    "value": "Surung Mersada"
  },
  {
    "id": "1215022021",
    "district_id": "121502",
    "label": "Perduhapen",
    "value": "Perduhapen"
  },
  {
    "id": "1215032001",
    "district_id": "121503",
    "label": "Salak I",
    "value": "Salak I"
  },
  {
    "id": "1215032002",
    "district_id": "121503",
    "label": "Salak II",
    "value": "Salak II"
  },
  {
    "id": "1215032003",
    "district_id": "121503",
    "label": "Boangmanalu",
    "value": "Boangmanalu"
  },
  {
    "id": "1215032009",
    "district_id": "121503",
    "label": "Sibongkaras",
    "value": "Sibongkaras"
  },
  {
    "id": "1215032013",
    "district_id": "121503",
    "label": "Kuta Tinggi",
    "value": "Kuta Tinggi"
  },
  {
    "id": "1215032014",
    "district_id": "121503",
    "label": "Penanggalan Binanga Boang",
    "value": "Penanggalan Binanga Boang"
  },
  {
    "id": "1215042001",
    "district_id": "121504",
    "label": "Ulumerah",
    "value": "Ulumerah"
  },
  {
    "id": "1215042002",
    "district_id": "121504",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1215042003",
    "district_id": "121504",
    "label": "Silima Kuta",
    "value": "Silima Kuta"
  },
  {
    "id": "1215042004",
    "district_id": "121504",
    "label": "Lae Langge Namuseng",
    "value": "Lae Langge Namuseng"
  },
  {
    "id": "1215042005",
    "district_id": "121504",
    "label": "Cikaok",
    "value": "Cikaok"
  },
  {
    "id": "1215052001",
    "district_id": "121505",
    "label": "Kecupak I",
    "value": "Kecupak I"
  },
  {
    "id": "1215052002",
    "district_id": "121505",
    "label": "Kecupak II",
    "value": "Kecupak II"
  },
  {
    "id": "1215052003",
    "district_id": "121505",
    "label": "Aornakan I",
    "value": "Aornakan I"
  },
  {
    "id": "1215052004",
    "district_id": "121505",
    "label": "Simerpara",
    "value": "Simerpara"
  },
  {
    "id": "1215052005",
    "district_id": "121505",
    "label": "Aornakan II",
    "value": "Aornakan II"
  },
  {
    "id": "1215062001",
    "district_id": "121506",
    "label": "Sibagindar",
    "value": "Sibagindar"
  },
  {
    "id": "1215062002",
    "district_id": "121506",
    "label": "Napatalun Perlambuken",
    "value": "Napatalun Perlambuken"
  },
  {
    "id": "1215062003",
    "district_id": "121506",
    "label": "Lae Mbentar",
    "value": "Lae Mbentar"
  },
  {
    "id": "1215062004",
    "district_id": "121506",
    "label": "Pagindar",
    "value": "Pagindar"
  },
  {
    "id": "1215072001",
    "district_id": "121507",
    "label": "Kuta Babo",
    "value": "Kuta Babo"
  },
  {
    "id": "1215072002",
    "district_id": "121507",
    "label": "Tinada",
    "value": "Tinada"
  },
  {
    "id": "1215072003",
    "district_id": "121507",
    "label": "Silima Kuta",
    "value": "Silima Kuta"
  },
  {
    "id": "1215072004",
    "district_id": "121507",
    "label": "Mahala",
    "value": "Mahala"
  },
  {
    "id": "1215072005",
    "district_id": "121507",
    "label": "Prongil",
    "value": "Prongil"
  },
  {
    "id": "1215072006",
    "district_id": "121507",
    "label": "Buluh Tellang",
    "value": "Buluh Tellang"
  },
  {
    "id": "1215082001",
    "district_id": "121508",
    "label": "Siempat Rube II",
    "value": "Siempat Rube II"
  },
  {
    "id": "1215082002",
    "district_id": "121508",
    "label": "Siempat Rube I",
    "value": "Siempat Rube I"
  },
  {
    "id": "1215082003",
    "district_id": "121508",
    "label": "Mungkur",
    "value": "Mungkur"
  },
  {
    "id": "1215082004",
    "district_id": "121508",
    "label": "Siempat Rube IV",
    "value": "Siempat Rube IV"
  },
  {
    "id": "1215082005",
    "district_id": "121508",
    "label": "Kuta Jungak",
    "value": "Kuta Jungak"
  },
  {
    "id": "1215082006",
    "district_id": "121508",
    "label": "Traju",
    "value": "Traju"
  },
  {
    "id": "1216012002",
    "district_id": "121601",
    "label": "Pusuk II Simaninggir",
    "value": "Pusuk II Simaninggir"
  },
  {
    "id": "1216012003",
    "district_id": "121601",
    "label": "Sionom Hudon VII",
    "value": "Sionom Hudon VII"
  },
  {
    "id": "1216012004",
    "district_id": "121601",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "1216012005",
    "district_id": "121601",
    "label": "Sionom Hudon Julu",
    "value": "Sionom Hudon Julu"
  },
  {
    "id": "1216012006",
    "district_id": "121601",
    "label": "Sionom Hudon Tonga",
    "value": "Sionom Hudon Tonga"
  },
  {
    "id": "1216012007",
    "district_id": "121601",
    "label": "Sionom Hudon Utara",
    "value": "Sionom Hudon Utara"
  },
  {
    "id": "1216012008",
    "district_id": "121601",
    "label": "Sionom Hudon Selatan",
    "value": "Sionom Hudon Selatan"
  },
  {
    "id": "1216012009",
    "district_id": "121601",
    "label": "Sionom Hudon Timur",
    "value": "Sionom Hudon Timur"
  },
  {
    "id": "1216012010",
    "district_id": "121601",
    "label": "Sihotang Hasugian Tonga",
    "value": "Sihotang Hasugian Tonga"
  },
  {
    "id": "1216012011",
    "district_id": "121601",
    "label": "Pusuk I",
    "value": "Pusuk I"
  },
  {
    "id": "1216012012",
    "district_id": "121601",
    "label": "Sionom Hudon Toruan",
    "value": "Sionom Hudon Toruan"
  },
  {
    "id": "1216012013",
    "district_id": "121601",
    "label": "Simataniari",
    "value": "Simataniari"
  },
  {
    "id": "1216012014",
    "district_id": "121601",
    "label": "Sionom Hudon Timur II",
    "value": "Sionom Hudon Timur II"
  },
  {
    "id": "1216012015",
    "district_id": "121601",
    "label": "Sihotang Hasugian Dolok II",
    "value": "Sihotang Hasugian Dolok II"
  },
  {
    "id": "1216012016",
    "district_id": "121601",
    "label": "Sihotang Hasugian Habinsaran",
    "value": "Sihotang Hasugian Habinsaran"
  },
  {
    "id": "1216012017",
    "district_id": "121601",
    "label": "Sionom Hudon Sibulbulon",
    "value": "Sionom Hudon Sibulbulon"
  },
  {
    "id": "1216012018",
    "district_id": "121601",
    "label": "Sionom Hudon Runggu",
    "value": "Sionom Hudon Runggu"
  },
  {
    "id": "1216012019",
    "district_id": "121601",
    "label": "Janji Hutanapa",
    "value": "Janji Hutanapa"
  },
  {
    "id": "1216012020",
    "district_id": "121601",
    "label": "Baringin Natam",
    "value": "Baringin Natam"
  },
  {
    "id": "1216012021",
    "district_id": "121601",
    "label": "Sihotang Hasugian Dolok I",
    "value": "Sihotang Hasugian Dolok I"
  },
  {
    "id": "1216022001",
    "district_id": "121602",
    "label": "Parsingguran I",
    "value": "Parsingguran I"
  },
  {
    "id": "1216022002",
    "district_id": "121602",
    "label": "Hutapaung",
    "value": "Hutapaung"
  },
  {
    "id": "1216022003",
    "district_id": "121602",
    "label": "Pollung",
    "value": "Pollung"
  },
  {
    "id": "1216022004",
    "district_id": "121602",
    "label": "Hutajulu",
    "value": "Hutajulu"
  },
  {
    "id": "1216022005",
    "district_id": "121602",
    "label": "Ria Ria",
    "value": "Ria Ria"
  },
  {
    "id": "1216022006",
    "district_id": "121602",
    "label": "Parsingguran II",
    "value": "Parsingguran II"
  },
  {
    "id": "1216022007",
    "district_id": "121602",
    "label": "Pansurbatu",
    "value": "Pansurbatu"
  },
  {
    "id": "1216022008",
    "district_id": "121602",
    "label": "Aek Nauli I",
    "value": "Aek Nauli I"
  },
  {
    "id": "1216022009",
    "district_id": "121602",
    "label": "Aek Nauli II",
    "value": "Aek Nauli II"
  },
  {
    "id": "1216022010",
    "district_id": "121602",
    "label": "Pandumaan",
    "value": "Pandumaan"
  },
  {
    "id": "1216022011",
    "district_id": "121602",
    "label": "Sipituhuta",
    "value": "Sipituhuta"
  },
  {
    "id": "1216022012",
    "district_id": "121602",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1216022013",
    "district_id": "121602",
    "label": "Hutapaung Utara",
    "value": "Hutapaung Utara"
  },
  {
    "id": "1216032001",
    "district_id": "121603",
    "label": "Marbun Toruan",
    "value": "Marbun Toruan"
  },
  {
    "id": "1216032002",
    "district_id": "121603",
    "label": "Simamora",
    "value": "Simamora"
  },
  {
    "id": "1216032003",
    "district_id": "121603",
    "label": "Tipang",
    "value": "Tipang"
  },
  {
    "id": "1216032004",
    "district_id": "121603",
    "label": "Sinambela",
    "value": "Sinambela"
  },
  {
    "id": "1216032005",
    "district_id": "121603",
    "label": "Simangulampe",
    "value": "Simangulampe"
  },
  {
    "id": "1216032006",
    "district_id": "121603",
    "label": "Siunong Unong Julu",
    "value": "Siunong Unong Julu"
  },
  {
    "id": "1216032007",
    "district_id": "121603",
    "label": "Marbun Tonga Marbun Dolok",
    "value": "Marbun Tonga Marbun Dolok"
  },
  {
    "id": "1216042001",
    "district_id": "121604",
    "label": "Sihonongan",
    "value": "Sihonongan"
  },
  {
    "id": "1216042002",
    "district_id": "121604",
    "label": "Paranginan Selatan",
    "value": "Paranginan Selatan"
  },
  {
    "id": "1216042003",
    "district_id": "121604",
    "label": "Paranginan Utara",
    "value": "Paranginan Utara"
  },
  {
    "id": "1216042004",
    "district_id": "121604",
    "label": "Lumban Sialaman",
    "value": "Lumban Sialaman"
  },
  {
    "id": "1216042005",
    "district_id": "121604",
    "label": "Pearung",
    "value": "Pearung"
  },
  {
    "id": "1216042006",
    "district_id": "121604",
    "label": "Lobutolong",
    "value": "Lobutolong"
  },
  {
    "id": "1216042007",
    "district_id": "121604",
    "label": "Lumban Barat",
    "value": "Lumban Barat"
  },
  {
    "id": "1216042008",
    "district_id": "121604",
    "label": "Siboru Torop",
    "value": "Siboru Torop"
  },
  {
    "id": "1216042009",
    "district_id": "121604",
    "label": "Pearung Silali",
    "value": "Pearung Silali"
  },
  {
    "id": "1216042010",
    "district_id": "121604",
    "label": "Lumban Sianturi",
    "value": "Lumban Sianturi"
  },
  {
    "id": "1216042011",
    "district_id": "121604",
    "label": "Lobutolong Habinsaran",
    "value": "Lobutolong Habinsaran"
  },
  {
    "id": "1216052001",
    "district_id": "121605",
    "label": "Sibuntuon Partur",
    "value": "Sibuntuon Partur"
  },
  {
    "id": "1216052002",
    "district_id": "121605",
    "label": "Siharjulu",
    "value": "Siharjulu"
  },
  {
    "id": "1216052003",
    "district_id": "121605",
    "label": "Sitolu Bahal",
    "value": "Sitolu Bahal"
  },
  {
    "id": "1216052004",
    "district_id": "121605",
    "label": "Tapian Nauli",
    "value": "Tapian Nauli"
  },
  {
    "id": "1216052005",
    "district_id": "121605",
    "label": "Pargaulan",
    "value": "Pargaulan"
  },
  {
    "id": "1216052006",
    "district_id": "121605",
    "label": "Lobutua",
    "value": "Lobutua"
  },
  {
    "id": "1216052007",
    "district_id": "121605",
    "label": "Dolok Margu",
    "value": "Dolok Margu"
  },
  {
    "id": "1216052008",
    "district_id": "121605",
    "label": "Hutasoit I",
    "value": "Hutasoit I"
  },
  {
    "id": "1216052009",
    "district_id": "121605",
    "label": "Nagasaribu I",
    "value": "Nagasaribu I"
  },
  {
    "id": "1216052010",
    "district_id": "121605",
    "label": "Nagasaribu II",
    "value": "Nagasaribu II"
  },
  {
    "id": "1216052011",
    "district_id": "121605",
    "label": "Sibuntoan Parpea",
    "value": "Sibuntoan Parpea"
  },
  {
    "id": "1216052012",
    "district_id": "121605",
    "label": "Siponjot",
    "value": "Siponjot"
  },
  {
    "id": "1216052013",
    "district_id": "121605",
    "label": "Habeahan",
    "value": "Habeahan"
  },
  {
    "id": "1216052014",
    "district_id": "121605",
    "label": "Sigompul",
    "value": "Sigompul"
  },
  {
    "id": "1216052015",
    "district_id": "121605",
    "label": "Bonan Dolok",
    "value": "Bonan Dolok"
  },
  {
    "id": "1216052016",
    "district_id": "121605",
    "label": "Sigumpar",
    "value": "Sigumpar"
  },
  {
    "id": "1216052017",
    "district_id": "121605",
    "label": "Parulohan",
    "value": "Parulohan"
  },
  {
    "id": "1216052018",
    "district_id": "121605",
    "label": "Sitio II",
    "value": "Sitio II"
  },
  {
    "id": "1216052019",
    "district_id": "121605",
    "label": "Hutasoit II",
    "value": "Hutasoit II"
  },
  {
    "id": "1216052020",
    "district_id": "121605",
    "label": "Nagasaribu III",
    "value": "Nagasaribu III"
  },
  {
    "id": "1216052021",
    "district_id": "121605",
    "label": "Nagasaribu IV",
    "value": "Nagasaribu IV"
  },
  {
    "id": "1216052022",
    "district_id": "121605",
    "label": "Nagasaribu V",
    "value": "Nagasaribu V"
  },
  {
    "id": "1216061001",
    "district_id": "121606",
    "label": "Pasar Dolok Sanggul",
    "value": "Pasar Dolok Sanggul"
  },
  {
    "id": "1216062002",
    "district_id": "121606",
    "label": "Sileang",
    "value": "Sileang"
  },
  {
    "id": "1216062003",
    "district_id": "121606",
    "label": "Purba Manalu",
    "value": "Purba Manalu"
  },
  {
    "id": "1216062004",
    "district_id": "121606",
    "label": "Pasaribu",
    "value": "Pasaribu"
  },
  {
    "id": "1216062005",
    "district_id": "121606",
    "label": "Simarigung",
    "value": "Simarigung"
  },
  {
    "id": "1216062006",
    "district_id": "121606",
    "label": "Sampean",
    "value": "Sampean"
  },
  {
    "id": "1216062007",
    "district_id": "121606",
    "label": "Silaga Laga",
    "value": "Silaga Laga"
  },
  {
    "id": "1216062008",
    "district_id": "121606",
    "label": "Sosor Gonting",
    "value": "Sosor Gonting"
  },
  {
    "id": "1216062009",
    "district_id": "121606",
    "label": "Sosor Tambok",
    "value": "Sosor Tambok"
  },
  {
    "id": "1216062010",
    "district_id": "121606",
    "label": "Purba Dolok",
    "value": "Purba Dolok"
  },
  {
    "id": "1216062011",
    "district_id": "121606",
    "label": "Sihite I",
    "value": "Sihite I"
  },
  {
    "id": "1216062012",
    "district_id": "121606",
    "label": "Lumban Tobing",
    "value": "Lumban Tobing"
  },
  {
    "id": "1216062013",
    "district_id": "121606",
    "label": "Hutabagasan",
    "value": "Hutabagasan"
  },
  {
    "id": "1216062014",
    "district_id": "121606",
    "label": "Matiti II",
    "value": "Matiti II"
  },
  {
    "id": "1216062015",
    "district_id": "121606",
    "label": "Saitnihuta",
    "value": "Saitnihuta"
  },
  {
    "id": "1216062016",
    "district_id": "121606",
    "label": "Hutaraja",
    "value": "Hutaraja"
  },
  {
    "id": "1216062017",
    "district_id": "121606",
    "label": "Lumban Purba",
    "value": "Lumban Purba"
  },
  {
    "id": "1216062018",
    "district_id": "121606",
    "label": "Bonanionan",
    "value": "Bonanionan"
  },
  {
    "id": "1216062019",
    "district_id": "121606",
    "label": "Parik Sinomba",
    "value": "Parik Sinomba"
  },
  {
    "id": "1216062020",
    "district_id": "121606",
    "label": "Sihite II",
    "value": "Sihite II"
  },
  {
    "id": "1216062021",
    "district_id": "121606",
    "label": "Janji",
    "value": "Janji"
  },
  {
    "id": "1216062022",
    "district_id": "121606",
    "label": "Pakkat",
    "value": "Pakkat"
  },
  {
    "id": "1216062023",
    "district_id": "121606",
    "label": "Sirisirisi",
    "value": "Sirisirisi"
  },
  {
    "id": "1216062024",
    "district_id": "121606",
    "label": "Hutagurgur",
    "value": "Hutagurgur"
  },
  {
    "id": "1216062025",
    "district_id": "121606",
    "label": "Matiti",
    "value": "Matiti"
  },
  {
    "id": "1216062026",
    "district_id": "121606",
    "label": "Aek Lung",
    "value": "Aek Lung"
  },
  {
    "id": "1216062027",
    "district_id": "121606",
    "label": "Simangaronsang",
    "value": "Simangaronsang"
  },
  {
    "id": "1216062028",
    "district_id": "121606",
    "label": "Sosortolong Sihite III",
    "value": "Sosortolong Sihite III"
  },
  {
    "id": "1216072001",
    "district_id": "121607",
    "label": "Batunajagar",
    "value": "Batunajagar"
  },
  {
    "id": "1216072002",
    "district_id": "121607",
    "label": "Sanggaran I",
    "value": "Sanggaran I"
  },
  {
    "id": "1216072003",
    "district_id": "121607",
    "label": "Sitapongan",
    "value": "Sitapongan"
  },
  {
    "id": "1216072004",
    "district_id": "121607",
    "label": "Sigulok",
    "value": "Sigulok"
  },
  {
    "id": "1216072005",
    "district_id": "121607",
    "label": "Hutaginjang",
    "value": "Hutaginjang"
  },
  {
    "id": "1216072006",
    "district_id": "121607",
    "label": "Siborboron",
    "value": "Siborboron"
  },
  {
    "id": "1216072007",
    "district_id": "121607",
    "label": "Bonan Dolok I",
    "value": "Bonan Dolok I"
  },
  {
    "id": "1216072008",
    "district_id": "121607",
    "label": "Bonan Dolok II",
    "value": "Bonan Dolok II"
  },
  {
    "id": "1216072009",
    "district_id": "121607",
    "label": "Sibuntuon",
    "value": "Sibuntuon"
  },
  {
    "id": "1216072010",
    "district_id": "121607",
    "label": "Nagurguran",
    "value": "Nagurguran"
  },
  {
    "id": "1216082001",
    "district_id": "121608",
    "label": "Batu Nagodang Siatas",
    "value": "Batu Nagodang Siatas"
  },
  {
    "id": "1216082002",
    "district_id": "121608",
    "label": "Onan Ganjang",
    "value": "Onan Ganjang"
  },
  {
    "id": "1216082003",
    "district_id": "121608",
    "label": "Parbotihan",
    "value": "Parbotihan"
  },
  {
    "id": "1216082004",
    "district_id": "121608",
    "label": "Sihikit",
    "value": "Sihikit"
  },
  {
    "id": "1216082005",
    "district_id": "121608",
    "label": "Parnapa",
    "value": "Parnapa"
  },
  {
    "id": "1216082006",
    "district_id": "121608",
    "label": "Janji Nagodang",
    "value": "Janji Nagodang"
  },
  {
    "id": "1216082007",
    "district_id": "121608",
    "label": "Sampe Tua",
    "value": "Sampe Tua"
  },
  {
    "id": "1216082008",
    "district_id": "121608",
    "label": "Hutajulu",
    "value": "Hutajulu"
  },
  {
    "id": "1216082009",
    "district_id": "121608",
    "label": "Sibuluan",
    "value": "Sibuluan"
  },
  {
    "id": "1216082010",
    "district_id": "121608",
    "label": "Sigalogo",
    "value": "Sigalogo"
  },
  {
    "id": "1216082011",
    "district_id": "121608",
    "label": "Aek Godang Arbaan",
    "value": "Aek Godang Arbaan"
  },
  {
    "id": "1216082012",
    "district_id": "121608",
    "label": "Sanggaran II",
    "value": "Sanggaran II"
  },
  {
    "id": "1216092001",
    "district_id": "121609",
    "label": "Purba Bersatu",
    "value": "Purba Bersatu"
  },
  {
    "id": "1216092002",
    "district_id": "121609",
    "label": "Purba Baringin",
    "value": "Purba Baringin"
  },
  {
    "id": "1216092003",
    "district_id": "121609",
    "label": "Karya",
    "value": "Karya"
  },
  {
    "id": "1216092004",
    "district_id": "121609",
    "label": "Manalu",
    "value": "Manalu"
  },
  {
    "id": "1216092005",
    "district_id": "121609",
    "label": "Sijarango",
    "value": "Sijarango"
  },
  {
    "id": "1216092006",
    "district_id": "121609",
    "label": "Tukka Dolok",
    "value": "Tukka Dolok"
  },
  {
    "id": "1216092007",
    "district_id": "121609",
    "label": "Siambaton",
    "value": "Siambaton"
  },
  {
    "id": "1216092008",
    "district_id": "121609",
    "label": "Parmonangan",
    "value": "Parmonangan"
  },
  {
    "id": "1216092009",
    "district_id": "121609",
    "label": "Sipagabu",
    "value": "Sipagabu"
  },
  {
    "id": "1216092010",
    "district_id": "121609",
    "label": "Banuarea",
    "value": "Banuarea"
  },
  {
    "id": "1216092011",
    "district_id": "121609",
    "label": "Rura Tanjung",
    "value": "Rura Tanjung"
  },
  {
    "id": "1216092012",
    "district_id": "121609",
    "label": "Rura Aek Sopang",
    "value": "Rura Aek Sopang"
  },
  {
    "id": "1216092013",
    "district_id": "121609",
    "label": "Lumban Tonga-Tonga",
    "value": "Lumban Tonga-Tonga"
  },
  {
    "id": "1216092014",
    "district_id": "121609",
    "label": "Pulo Godang",
    "value": "Pulo Godang"
  },
  {
    "id": "1216092015",
    "district_id": "121609",
    "label": "Pakkat Hauagong",
    "value": "Pakkat Hauagong"
  },
  {
    "id": "1216092016",
    "district_id": "121609",
    "label": "Peadungdung",
    "value": "Peadungdung"
  },
  {
    "id": "1216092017",
    "district_id": "121609",
    "label": "Sijarango I",
    "value": "Sijarango I"
  },
  {
    "id": "1216092018",
    "district_id": "121609",
    "label": "Ambobi Paranginan",
    "value": "Ambobi Paranginan"
  },
  {
    "id": "1216092019",
    "district_id": "121609",
    "label": "Purba Sianjur",
    "value": "Purba Sianjur"
  },
  {
    "id": "1216092020",
    "district_id": "121609",
    "label": "Siambaton Pahae",
    "value": "Siambaton Pahae"
  },
  {
    "id": "1216092021",
    "district_id": "121609",
    "label": "Panggugunan",
    "value": "Panggugunan"
  },
  {
    "id": "1216092022",
    "district_id": "121609",
    "label": "Hauagong",
    "value": "Hauagong"
  },
  {
    "id": "1216102001",
    "district_id": "121610",
    "label": "Tarabintang",
    "value": "Tarabintang"
  },
  {
    "id": "1216102002",
    "district_id": "121610",
    "label": "Sihotang Hasugian Toruan",
    "value": "Sihotang Hasugian Toruan"
  },
  {
    "id": "1216102003",
    "district_id": "121610",
    "label": "Sibongkare",
    "value": "Sibongkare"
  },
  {
    "id": "1216102004",
    "district_id": "121610",
    "label": "Sitanduk",
    "value": "Sitanduk"
  },
  {
    "id": "1216102005",
    "district_id": "121610",
    "label": "Sihombu",
    "value": "Sihombu"
  },
  {
    "id": "1216102006",
    "district_id": "121610",
    "label": "Simbara",
    "value": "Simbara"
  },
  {
    "id": "1216102007",
    "district_id": "121610",
    "label": "Marpadan",
    "value": "Marpadan"
  },
  {
    "id": "1216102008",
    "district_id": "121610",
    "label": "Mungkur",
    "value": "Mungkur"
  },
  {
    "id": "1216102009",
    "district_id": "121610",
    "label": "Sibongkare Sianju",
    "value": "Sibongkare Sianju"
  },
  {
    "id": "1217011016",
    "district_id": "121701",
    "label": "Tuktuk Siadong",
    "value": "Tuktuk Siadong"
  },
  {
    "id": "1217012001",
    "district_id": "121701",
    "label": "Tomok",
    "value": "Tomok"
  },
  {
    "id": "1217012002",
    "district_id": "121701",
    "label": "Sihusapi",
    "value": "Sihusapi"
  },
  {
    "id": "1217012003",
    "district_id": "121701",
    "label": "Maduma",
    "value": "Maduma"
  },
  {
    "id": "1217012004",
    "district_id": "121701",
    "label": "Dosroha",
    "value": "Dosroha"
  },
  {
    "id": "1217012005",
    "district_id": "121701",
    "label": "Ambarita",
    "value": "Ambarita"
  },
  {
    "id": "1217012006",
    "district_id": "121701",
    "label": "Martoba",
    "value": "Martoba"
  },
  {
    "id": "1217012007",
    "district_id": "121701",
    "label": "Garoga",
    "value": "Garoga"
  },
  {
    "id": "1217012008",
    "district_id": "121701",
    "label": "Simanindo Sangkal",
    "value": "Simanindo Sangkal"
  },
  {
    "id": "1217012009",
    "district_id": "121701",
    "label": "Cinta Dame",
    "value": "Cinta Dame"
  },
  {
    "id": "1217012010",
    "district_id": "121701",
    "label": "Huta Ginjang",
    "value": "Huta Ginjang"
  },
  {
    "id": "1217012011",
    "district_id": "121701",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1217012012",
    "district_id": "121701",
    "label": "Parmonangan",
    "value": "Parmonangan"
  },
  {
    "id": "1217012013",
    "district_id": "121701",
    "label": "Parbalohan",
    "value": "Parbalohan"
  },
  {
    "id": "1217012014",
    "district_id": "121701",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "1217012015",
    "district_id": "121701",
    "label": "Simarmata",
    "value": "Simarmata"
  },
  {
    "id": "1217012017",
    "district_id": "121701",
    "label": "Tomok Parsaoran",
    "value": "Tomok Parsaoran"
  },
  {
    "id": "1217012018",
    "district_id": "121701",
    "label": "Marlumba",
    "value": "Marlumba"
  },
  {
    "id": "1217012019",
    "district_id": "121701",
    "label": "Simanindo",
    "value": "Simanindo"
  },
  {
    "id": "1217012020",
    "district_id": "121701",
    "label": "Unjur",
    "value": "Unjur"
  },
  {
    "id": "1217012021",
    "district_id": "121701",
    "label": "Siallagan Pindaraya",
    "value": "Siallagan Pindaraya"
  },
  {
    "id": "1217022001",
    "district_id": "121702",
    "label": "Pakpahan",
    "value": "Pakpahan"
  },
  {
    "id": "1217022002",
    "district_id": "121702",
    "label": "Huta Hotang",
    "value": "Huta Hotang"
  },
  {
    "id": "1217022003",
    "district_id": "121702",
    "label": "Sitinjak",
    "value": "Sitinjak"
  },
  {
    "id": "1217022004",
    "district_id": "121702",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1217022005",
    "district_id": "121702",
    "label": "Tambun Sungkean",
    "value": "Tambun Sungkean"
  },
  {
    "id": "1217022006",
    "district_id": "121702",
    "label": "Rina Bolak",
    "value": "Rina Bolak"
  },
  {
    "id": "1217022007",
    "district_id": "121702",
    "label": "Onan Runggu",
    "value": "Onan Runggu"
  },
  {
    "id": "1217022008",
    "district_id": "121702",
    "label": "Sitamiang",
    "value": "Sitamiang"
  },
  {
    "id": "1217022009",
    "district_id": "121702",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1217022010",
    "district_id": "121702",
    "label": "Silima Lombu",
    "value": "Silima Lombu"
  },
  {
    "id": "1217022011",
    "district_id": "121702",
    "label": "Sipira",
    "value": "Sipira"
  },
  {
    "id": "1217022012",
    "district_id": "121702",
    "label": "Harian",
    "value": "Harian"
  },
  {
    "id": "1217031011",
    "district_id": "121703",
    "label": "Parhusip III",
    "value": "Parhusip III"
  },
  {
    "id": "1217031012",
    "district_id": "121703",
    "label": "Sirumahombar",
    "value": "Sirumahombar"
  },
  {
    "id": "1217032001",
    "district_id": "121703",
    "label": "Pangaloan",
    "value": "Pangaloan"
  },
  {
    "id": "1217032002",
    "district_id": "121703",
    "label": "Hutarihit",
    "value": "Hutarihit"
  },
  {
    "id": "1217032003",
    "district_id": "121703",
    "label": "Sinaga Uruk Pandiangan",
    "value": "Sinaga Uruk Pandiangan"
  },
  {
    "id": "1217032004",
    "district_id": "121703",
    "label": "Toguan Galung",
    "value": "Toguan Galung"
  },
  {
    "id": "1217032005",
    "district_id": "121703",
    "label": "Pananggangan",
    "value": "Pananggangan"
  },
  {
    "id": "1217032006",
    "district_id": "121703",
    "label": "Pasaran Parsaoran",
    "value": "Pasaran Parsaoran"
  },
  {
    "id": "1217032007",
    "district_id": "121703",
    "label": "Sipinggan Lumban Siantar",
    "value": "Sipinggan Lumban Siantar"
  },
  {
    "id": "1217032008",
    "district_id": "121703",
    "label": "Sibonor Ompu Ratus",
    "value": "Sibonor Ompu Ratus"
  },
  {
    "id": "1217032009",
    "district_id": "121703",
    "label": "Nainggolan",
    "value": "Nainggolan"
  },
  {
    "id": "1217032010",
    "district_id": "121703",
    "label": "Pasaran I",
    "value": "Pasaran I"
  },
  {
    "id": "1217032013",
    "district_id": "121703",
    "label": "Pananggangan II",
    "value": "Pananggangan II"
  },
  {
    "id": "1217032014",
    "district_id": "121703",
    "label": "Sipinggan",
    "value": "Sipinggan"
  },
  {
    "id": "1217032015",
    "district_id": "121703",
    "label": "Janji Marapot",
    "value": "Janji Marapot"
  },
  {
    "id": "1217042001",
    "district_id": "121704",
    "label": "Suhutnihuta Pardomuan",
    "value": "Suhutnihuta Pardomuan"
  },
  {
    "id": "1217042002",
    "district_id": "121704",
    "label": "Gorat Pallombuan",
    "value": "Gorat Pallombuan"
  },
  {
    "id": "1217042003",
    "district_id": "121704",
    "label": "Urat II",
    "value": "Urat II"
  },
  {
    "id": "1217042004",
    "district_id": "121704",
    "label": "Palipi",
    "value": "Palipi"
  },
  {
    "id": "1217042005",
    "district_id": "121704",
    "label": "Pardomuan Nauli",
    "value": "Pardomuan Nauli"
  },
  {
    "id": "1217042006",
    "district_id": "121704",
    "label": "Hatoguan",
    "value": "Hatoguan"
  },
  {
    "id": "1217042007",
    "district_id": "121704",
    "label": "Simbolon Purba",
    "value": "Simbolon Purba"
  },
  {
    "id": "1217042008",
    "district_id": "121704",
    "label": "Sigaol Simbolon",
    "value": "Sigaol Simbolon"
  },
  {
    "id": "1217042009",
    "district_id": "121704",
    "label": "Urat Timur",
    "value": "Urat Timur"
  },
  {
    "id": "1217042010",
    "district_id": "121704",
    "label": "Saor Nauli Hatoguan",
    "value": "Saor Nauli Hatoguan"
  },
  {
    "id": "1217042011",
    "district_id": "121704",
    "label": "Parsaoran Urat",
    "value": "Parsaoran Urat"
  },
  {
    "id": "1217042012",
    "district_id": "121704",
    "label": "Hutaginjang",
    "value": "Hutaginjang"
  },
  {
    "id": "1217042014",
    "district_id": "121704",
    "label": "Sigaol Marbun",
    "value": "Sigaol Marbun"
  },
  {
    "id": "1217042018",
    "district_id": "121704",
    "label": "Hutadame",
    "value": "Hutadame"
  },
  {
    "id": "1217042019",
    "district_id": "121704",
    "label": "Pallombuan",
    "value": "Pallombuan"
  },
  {
    "id": "1217042020",
    "district_id": "121704",
    "label": "Pamutaran",
    "value": "Pamutaran"
  },
  {
    "id": "1217042021",
    "district_id": "121704",
    "label": "Sideak",
    "value": "Sideak"
  },
  {
    "id": "1217052001",
    "district_id": "121705",
    "label": "Sosor Dolok",
    "value": "Sosor Dolok"
  },
  {
    "id": "1217052002",
    "district_id": "121705",
    "label": "Partungkonaginjang",
    "value": "Partungkonaginjang"
  },
  {
    "id": "1217052003",
    "district_id": "121705",
    "label": "Hariara Pohan",
    "value": "Hariara Pohan"
  },
  {
    "id": "1217052004",
    "district_id": "121705",
    "label": "Dolok Raja",
    "value": "Dolok Raja"
  },
  {
    "id": "1217052006",
    "district_id": "121705",
    "label": "Sampur Toba",
    "value": "Sampur Toba"
  },
  {
    "id": "1217052007",
    "district_id": "121705",
    "label": "Siparmahan",
    "value": "Siparmahan"
  },
  {
    "id": "1217052008",
    "district_id": "121705",
    "label": "Turpuk Limbong",
    "value": "Turpuk Limbong"
  },
  {
    "id": "1217052009",
    "district_id": "121705",
    "label": "Turpuk Sagala",
    "value": "Turpuk Sagala"
  },
  {
    "id": "1217052010",
    "district_id": "121705",
    "label": "Turpuk Malau",
    "value": "Turpuk Malau"
  },
  {
    "id": "1217052011",
    "district_id": "121705",
    "label": "Turpuk Sihotang",
    "value": "Turpuk Sihotang"
  },
  {
    "id": "1217052012",
    "district_id": "121705",
    "label": "Janji Martahan",
    "value": "Janji Martahan"
  },
  {
    "id": "1217052014",
    "district_id": "121705",
    "label": "Hutagalung",
    "value": "Hutagalung"
  },
  {
    "id": "1217052015",
    "district_id": "121705",
    "label": "Hariara Pintu",
    "value": "Hariara Pintu"
  },
  {
    "id": "1217062001",
    "district_id": "121706",
    "label": "Huta Ginjang",
    "value": "Huta Ginjang"
  },
  {
    "id": "1217062002",
    "district_id": "121706",
    "label": "Singkam",
    "value": "Singkam"
  },
  {
    "id": "1217062003",
    "district_id": "121706",
    "label": "Hasinggaan",
    "value": "Hasinggaan"
  },
  {
    "id": "1217062004",
    "district_id": "121706",
    "label": "Aek Sipitudai",
    "value": "Aek Sipitudai"
  },
  {
    "id": "1217062005",
    "district_id": "121706",
    "label": "Huta Gurgur",
    "value": "Huta Gurgur"
  },
  {
    "id": "1217062006",
    "district_id": "121706",
    "label": "Sianjur Mula Mula",
    "value": "Sianjur Mula Mula"
  },
  {
    "id": "1217062007",
    "district_id": "121706",
    "label": "Boho",
    "value": "Boho"
  },
  {
    "id": "1217062008",
    "district_id": "121706",
    "label": "Siboro",
    "value": "Siboro"
  },
  {
    "id": "1217062009",
    "district_id": "121706",
    "label": "Simarihit",
    "value": "Simarihit"
  },
  {
    "id": "1217062010",
    "district_id": "121706",
    "label": "Ginolat",
    "value": "Ginolat"
  },
  {
    "id": "1217062011",
    "district_id": "121706",
    "label": "Bonan Dolok",
    "value": "Bonan Dolok"
  },
  {
    "id": "1217062012",
    "district_id": "121706",
    "label": "Habeahan Naburahan",
    "value": "Habeahan Naburahan"
  },
  {
    "id": "1217072001",
    "district_id": "121707",
    "label": "Salaon Toba",
    "value": "Salaon Toba"
  },
  {
    "id": "1217072002",
    "district_id": "121707",
    "label": "Lintong Nihuta",
    "value": "Lintong Nihuta"
  },
  {
    "id": "1217072003",
    "district_id": "121707",
    "label": "Salaon Tonga Tonga",
    "value": "Salaon Tonga Tonga"
  },
  {
    "id": "1217072004",
    "district_id": "121707",
    "label": "Salaon Dolok",
    "value": "Salaon Dolok"
  },
  {
    "id": "1217072005",
    "district_id": "121707",
    "label": "Ronggur Nihuta",
    "value": "Ronggur Nihuta"
  },
  {
    "id": "1217072006",
    "district_id": "121707",
    "label": "Sabungan Nihuta",
    "value": "Sabungan Nihuta"
  },
  {
    "id": "1217072007",
    "district_id": "121707",
    "label": "Paraduan",
    "value": "Paraduan"
  },
  {
    "id": "1217072008",
    "district_id": "121707",
    "label": "Sijambur",
    "value": "Sijambur"
  },
  {
    "id": "1217081026",
    "district_id": "121708",
    "label": "Pasar Pangururan",
    "value": "Pasar Pangururan"
  },
  {
    "id": "1217081027",
    "district_id": "121708",
    "label": "Pintu Sona",
    "value": "Pintu Sona"
  },
  {
    "id": "1217081028",
    "district_id": "121708",
    "label": "Siogung-Ogung",
    "value": "Siogung-Ogung"
  },
  {
    "id": "1217082001",
    "district_id": "121708",
    "label": "Lumban SS Dolok",
    "value": "Lumban SS Dolok"
  },
  {
    "id": "1217082002",
    "district_id": "121708",
    "label": "Lumban SS Torua",
    "value": "Lumban SS Torua"
  },
  {
    "id": "1217082003",
    "district_id": "121708",
    "label": "Parbaba Dolok",
    "value": "Parbaba Dolok"
  },
  {
    "id": "1217082004",
    "district_id": "121708",
    "label": "Huta Tinggi",
    "value": "Huta Tinggi"
  },
  {
    "id": "1217082005",
    "district_id": "121708",
    "label": "Parhorasan",
    "value": "Parhorasan"
  },
  {
    "id": "1217082006",
    "district_id": "121708",
    "label": "Pardomuan Nauli",
    "value": "Pardomuan Nauli"
  },
  {
    "id": "1217082007",
    "district_id": "121708",
    "label": "Parsaoran I",
    "value": "Parsaoran I"
  },
  {
    "id": "1217082008",
    "district_id": "121708",
    "label": "Rianiate",
    "value": "Rianiate"
  },
  {
    "id": "1217082009",
    "district_id": "121708",
    "label": "Huta Bolon",
    "value": "Huta Bolon"
  },
  {
    "id": "1217082010",
    "district_id": "121708",
    "label": "Panampangan",
    "value": "Panampangan"
  },
  {
    "id": "1217082011",
    "district_id": "121708",
    "label": "Pardugul",
    "value": "Pardugul"
  },
  {
    "id": "1217082012",
    "district_id": "121708",
    "label": "Huta Namora",
    "value": "Huta Namora"
  },
  {
    "id": "1217082013",
    "district_id": "121708",
    "label": "Parlondut",
    "value": "Parlondut"
  },
  {
    "id": "1217082014",
    "district_id": "121708",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "1217082015",
    "district_id": "121708",
    "label": "Parmonangan",
    "value": "Parmonangan"
  },
  {
    "id": "1217082016",
    "district_id": "121708",
    "label": "Sait Nihuta",
    "value": "Sait Nihuta"
  },
  {
    "id": "1217082017",
    "district_id": "121708",
    "label": "Situngkir",
    "value": "Situngkir"
  },
  {
    "id": "1217082018",
    "district_id": "121708",
    "label": "Sianting-Anting",
    "value": "Sianting-Anting"
  },
  {
    "id": "1217082019",
    "district_id": "121708",
    "label": "Sialanguan",
    "value": "Sialanguan"
  },
  {
    "id": "1217082020",
    "district_id": "121708",
    "label": "Lumban Pinggol",
    "value": "Lumban Pinggol"
  },
  {
    "id": "1217082021",
    "district_id": "121708",
    "label": "Pardomuan I",
    "value": "Pardomuan I"
  },
  {
    "id": "1217082022",
    "district_id": "121708",
    "label": "Sitolu Huta",
    "value": "Sitolu Huta"
  },
  {
    "id": "1217082023",
    "district_id": "121708",
    "label": "Sinabulan",
    "value": "Sinabulan"
  },
  {
    "id": "1217082024",
    "district_id": "121708",
    "label": "Aek Nauli",
    "value": "Aek Nauli"
  },
  {
    "id": "1217082025",
    "district_id": "121708",
    "label": "Siopat Sosor",
    "value": "Siopat Sosor"
  },
  {
    "id": "1217092001",
    "district_id": "121709",
    "label": "Holbung",
    "value": "Holbung"
  },
  {
    "id": "1217092002",
    "district_id": "121709",
    "label": "Janji Raja",
    "value": "Janji Raja"
  },
  {
    "id": "1217092003",
    "district_id": "121709",
    "label": "Sabulan",
    "value": "Sabulan"
  },
  {
    "id": "1217092004",
    "district_id": "121709",
    "label": "Buntu Mauli",
    "value": "Buntu Mauli"
  },
  {
    "id": "1217092005",
    "district_id": "121709",
    "label": "Tamba Dolok",
    "value": "Tamba Dolok"
  },
  {
    "id": "1217092006",
    "district_id": "121709",
    "label": "Cinta Maju",
    "value": "Cinta Maju"
  },
  {
    "id": "1217092007",
    "district_id": "121709",
    "label": "Parsaoran",
    "value": "Parsaoran"
  },
  {
    "id": "1217092008",
    "district_id": "121709",
    "label": "Janji Maria",
    "value": "Janji Maria"
  },
  {
    "id": "1218012001",
    "district_id": "121801",
    "label": "Pantai Cermin Kanan",
    "value": "Pantai Cermin Kanan"
  },
  {
    "id": "1218012002",
    "district_id": "121801",
    "label": "Pantai Cermin Kiri",
    "value": "Pantai Cermin Kiri"
  },
  {
    "id": "1218012003",
    "district_id": "121801",
    "label": "Kota Pari",
    "value": "Kota Pari"
  },
  {
    "id": "1218012004",
    "district_id": "121801",
    "label": "Celawan",
    "value": "Celawan"
  },
  {
    "id": "1218012005",
    "district_id": "121801",
    "label": "Ujung Rambung",
    "value": "Ujung Rambung"
  },
  {
    "id": "1218012006",
    "district_id": "121801",
    "label": "Kuala Lama",
    "value": "Kuala Lama"
  },
  {
    "id": "1218012007",
    "district_id": "121801",
    "label": "Besar II Terjun",
    "value": "Besar II Terjun"
  },
  {
    "id": "1218012008",
    "district_id": "121801",
    "label": "Sementara",
    "value": "Sementara"
  },
  {
    "id": "1218012009",
    "district_id": "121801",
    "label": "Ara Payung",
    "value": "Ara Payung"
  },
  {
    "id": "1218012010",
    "district_id": "121801",
    "label": "Pematang Kasih",
    "value": "Pematang Kasih"
  },
  {
    "id": "1218012011",
    "district_id": "121801",
    "label": "Lubuk Saban",
    "value": "Lubuk Saban"
  },
  {
    "id": "1218012012",
    "district_id": "121801",
    "label": "Naga Kisar",
    "value": "Naga Kisar"
  },
  {
    "id": "1218021038",
    "district_id": "121802",
    "label": "Melati I",
    "value": "Melati I"
  },
  {
    "id": "1218021039",
    "district_id": "121802",
    "label": "Simpang Tiga Pekan",
    "value": "Simpang Tiga Pekan"
  },
  {
    "id": "1218021040",
    "district_id": "121802",
    "label": "Batang Terap",
    "value": "Batang Terap"
  },
  {
    "id": "1218021041",
    "district_id": "121802",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1218022001",
    "district_id": "121802",
    "label": "Adolina",
    "value": "Adolina"
  },
  {
    "id": "1218022003",
    "district_id": "121802",
    "label": "Bengkel",
    "value": "Bengkel"
  },
  {
    "id": "1218022005",
    "district_id": "121802",
    "label": "Cinta Air",
    "value": "Cinta Air"
  },
  {
    "id": "1218022006",
    "district_id": "121802",
    "label": "Citaman Jernih",
    "value": "Citaman Jernih"
  },
  {
    "id": "1218022007",
    "district_id": "121802",
    "label": "Deli Muda Hulu",
    "value": "Deli Muda Hulu"
  },
  {
    "id": "1218022008",
    "district_id": "121802",
    "label": "Deli Muda Hilir",
    "value": "Deli Muda Hilir"
  },
  {
    "id": "1218022009",
    "district_id": "121802",
    "label": "Jambur Pulau",
    "value": "Jambur Pulau"
  },
  {
    "id": "1218022012",
    "district_id": "121802",
    "label": "Kesatuan",
    "value": "Kesatuan"
  },
  {
    "id": "1218022013",
    "district_id": "121802",
    "label": "Kota Galuh",
    "value": "Kota Galuh"
  },
  {
    "id": "1218022015",
    "district_id": "121802",
    "label": "Lidah Tanah",
    "value": "Lidah Tanah"
  },
  {
    "id": "1218022016",
    "district_id": "121802",
    "label": "Lubuk Bayas",
    "value": "Lubuk Bayas"
  },
  {
    "id": "1218022017",
    "district_id": "121802",
    "label": "Lubuk Cemara",
    "value": "Lubuk Cemara"
  },
  {
    "id": "1218022018",
    "district_id": "121802",
    "label": "Lubuk Dendang",
    "value": "Lubuk Dendang"
  },
  {
    "id": "1218022019",
    "district_id": "121802",
    "label": "Lubuk Rotan",
    "value": "Lubuk Rotan"
  },
  {
    "id": "1218022020",
    "district_id": "121802",
    "label": "Melati II",
    "value": "Melati II"
  },
  {
    "id": "1218022024",
    "district_id": "121802",
    "label": "Pematang Sijonam",
    "value": "Pematang Sijonam"
  },
  {
    "id": "1218022025",
    "district_id": "121802",
    "label": "Pematang Tatal",
    "value": "Pematang Tatal"
  },
  {
    "id": "1218022028",
    "district_id": "121802",
    "label": "Suka Beras",
    "value": "Suka Beras"
  },
  {
    "id": "1218022029",
    "district_id": "121802",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1218022031",
    "district_id": "121802",
    "label": "Sei Buluh",
    "value": "Sei Buluh"
  },
  {
    "id": "1218022032",
    "district_id": "121802",
    "label": "Sei Nagalawan",
    "value": "Sei Nagalawan"
  },
  {
    "id": "1218022033",
    "district_id": "121802",
    "label": "Sei Sijenggi",
    "value": "Sei Sijenggi"
  },
  {
    "id": "1218022034",
    "district_id": "121802",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1218022035",
    "district_id": "121802",
    "label": "Tanjung Buluh",
    "value": "Tanjung Buluh"
  },
  {
    "id": "1218032001",
    "district_id": "121803",
    "label": "Sei Buluh",
    "value": "Sei Buluh"
  },
  {
    "id": "1218032002",
    "district_id": "121803",
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "id": "1218032003",
    "district_id": "121803",
    "label": "Mata Pao",
    "value": "Mata Pao"
  },
  {
    "id": "1218032004",
    "district_id": "121803",
    "label": "Pematang Setrak",
    "value": "Pematang Setrak"
  },
  {
    "id": "1218032005",
    "district_id": "121803",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "1218032006",
    "district_id": "121803",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1218032007",
    "district_id": "121803",
    "label": "Pekan Sialang Buah",
    "value": "Pekan Sialang Buah"
  },
  {
    "id": "1218032008",
    "district_id": "121803",
    "label": "Pematang Guntung",
    "value": "Pematang Guntung"
  },
  {
    "id": "1218032009",
    "district_id": "121803",
    "label": "Sialang Buah",
    "value": "Sialang Buah"
  },
  {
    "id": "1218032010",
    "district_id": "121803",
    "label": "Sentang",
    "value": "Sentang"
  },
  {
    "id": "1218032011",
    "district_id": "121803",
    "label": "Bogak Besar",
    "value": "Bogak Besar"
  },
  {
    "id": "1218032012",
    "district_id": "121803",
    "label": "Pematang Kuala",
    "value": "Pematang Kuala"
  },
  {
    "id": "1218042001",
    "district_id": "121804",
    "label": "Cempedak Lobang",
    "value": "Cempedak Lobang"
  },
  {
    "id": "1218042002",
    "district_id": "121804",
    "label": "Firdaus",
    "value": "Firdaus"
  },
  {
    "id": "1218042004",
    "district_id": "121804",
    "label": "Pergulaan",
    "value": "Pergulaan"
  },
  {
    "id": "1218042006",
    "district_id": "121804",
    "label": "Sei Rampah",
    "value": "Sei Rampah"
  },
  {
    "id": "1218042008",
    "district_id": "121804",
    "label": "Sei Rejo",
    "value": "Sei Rejo"
  },
  {
    "id": "1218042009",
    "district_id": "121804",
    "label": "Sei Parit",
    "value": "Sei Parit"
  },
  {
    "id": "1218042010",
    "district_id": "121804",
    "label": "Silau Rakyat",
    "value": "Silau Rakyat"
  },
  {
    "id": "1218042012",
    "district_id": "121804",
    "label": "Firdaus Estate",
    "value": "Firdaus Estate"
  },
  {
    "id": "1218042014",
    "district_id": "121804",
    "label": "Pematang Ganjang",
    "value": "Pematang Ganjang"
  },
  {
    "id": "1218042015",
    "district_id": "121804",
    "label": "Pematang Pelintahan",
    "value": "Pematang Pelintahan"
  },
  {
    "id": "1218042016",
    "district_id": "121804",
    "label": "Rambung Estate",
    "value": "Rambung Estate"
  },
  {
    "id": "1218042017",
    "district_id": "121804",
    "label": "Rambung Sialang Hulu",
    "value": "Rambung Sialang Hulu"
  },
  {
    "id": "1218042018",
    "district_id": "121804",
    "label": "Rambung Sialang Tengah",
    "value": "Rambung Sialang Tengah"
  },
  {
    "id": "1218042019",
    "district_id": "121804",
    "label": "Rambung Sialang Hilir",
    "value": "Rambung Sialang Hilir"
  },
  {
    "id": "1218042024",
    "district_id": "121804",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1218042026",
    "district_id": "121804",
    "label": "Sinah Kasih",
    "value": "Sinah Kasih"
  },
  {
    "id": "1218042027",
    "district_id": "121804",
    "label": "Tanah Raja",
    "value": "Tanah Raja"
  },
  {
    "id": "1218052001",
    "district_id": "121805",
    "label": "Pekan Tanjung Beringin",
    "value": "Pekan Tanjung Beringin"
  },
  {
    "id": "1218052002",
    "district_id": "121805",
    "label": "Nagur",
    "value": "Nagur"
  },
  {
    "id": "1218052003",
    "district_id": "121805",
    "label": "Mangga Dua",
    "value": "Mangga Dua"
  },
  {
    "id": "1218052004",
    "district_id": "121805",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1218052005",
    "district_id": "121805",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1218052006",
    "district_id": "121805",
    "label": "Pematang Cermai",
    "value": "Pematang Cermai"
  },
  {
    "id": "1218052007",
    "district_id": "121805",
    "label": "Pematang Terang",
    "value": "Pematang Terang"
  },
  {
    "id": "1218052008",
    "district_id": "121805",
    "label": "Bagan Kuala",
    "value": "Bagan Kuala"
  },
  {
    "id": "1218062001",
    "district_id": "121806",
    "label": "Bandar Tengah",
    "value": "Bandar Tengah"
  },
  {
    "id": "1218062002",
    "district_id": "121806",
    "label": "Juhar",
    "value": "Juhar"
  },
  {
    "id": "1218062003",
    "district_id": "121806",
    "label": "Gelam Sei Serimah",
    "value": "Gelam Sei Serimah"
  },
  {
    "id": "1218062004",
    "district_id": "121806",
    "label": "Pekan Bandar Khalipah",
    "value": "Pekan Bandar Khalipah"
  },
  {
    "id": "1218062005",
    "district_id": "121806",
    "label": "Kayu Besar",
    "value": "Kayu Besar"
  },
  {
    "id": "1218072001",
    "district_id": "121807",
    "label": "Nagaraja I",
    "value": "Nagaraja I"
  },
  {
    "id": "1218072002",
    "district_id": "121807",
    "label": "Panglong",
    "value": "Panglong"
  },
  {
    "id": "1218072003",
    "district_id": "121807",
    "label": "Bandarawan",
    "value": "Bandarawan"
  },
  {
    "id": "1218072004",
    "district_id": "121807",
    "label": "Gunung Para II",
    "value": "Gunung Para II"
  },
  {
    "id": "1218072005",
    "district_id": "121807",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "1218072006",
    "district_id": "121807",
    "label": "Dolok Merawan",
    "value": "Dolok Merawan"
  },
  {
    "id": "1218072007",
    "district_id": "121807",
    "label": "Paritokan",
    "value": "Paritokan"
  },
  {
    "id": "1218072008",
    "district_id": "121807",
    "label": "Kalembak",
    "value": "Kalembak"
  },
  {
    "id": "1218072009",
    "district_id": "121807",
    "label": "Afd. VI Dolok Ilir",
    "value": "Afd. VI Dolok Ilir"
  },
  {
    "id": "1218072010",
    "district_id": "121807",
    "label": "Pabatu I",
    "value": "Pabatu I"
  },
  {
    "id": "1218072011",
    "district_id": "121807",
    "label": "Afd. VII Dolok Ilir",
    "value": "Afd. VII Dolok Ilir"
  },
  {
    "id": "1218072012",
    "district_id": "121807",
    "label": "Pabatu II",
    "value": "Pabatu II"
  },
  {
    "id": "1218072013",
    "district_id": "121807",
    "label": "Mainu Tengah",
    "value": "Mainu Tengah"
  },
  {
    "id": "1218072014",
    "district_id": "121807",
    "label": "Bahdamar",
    "value": "Bahdamar"
  },
  {
    "id": "1218072015",
    "district_id": "121807",
    "label": "Pabatu III",
    "value": "Pabatu III"
  },
  {
    "id": "1218072016",
    "district_id": "121807",
    "label": "Pabatu VI",
    "value": "Pabatu VI"
  },
  {
    "id": "1218072017",
    "district_id": "121807",
    "label": "Korajim",
    "value": "Korajim"
  },
  {
    "id": "1218082001",
    "district_id": "121808",
    "label": "Sipispis",
    "value": "Sipispis"
  },
  {
    "id": "1218082002",
    "district_id": "121808",
    "label": "Serbananti",
    "value": "Serbananti"
  },
  {
    "id": "1218082003",
    "district_id": "121808",
    "label": "Bartong",
    "value": "Bartong"
  },
  {
    "id": "1218082004",
    "district_id": "121808",
    "label": "Marubun",
    "value": "Marubun"
  },
  {
    "id": "1218082005",
    "district_id": "121808",
    "label": "Pispis",
    "value": "Pispis"
  },
  {
    "id": "1218082006",
    "district_id": "121808",
    "label": "Baja Dolok",
    "value": "Baja Dolok"
  },
  {
    "id": "1218082007",
    "district_id": "121808",
    "label": "Tinokkah",
    "value": "Tinokkah"
  },
  {
    "id": "1218082008",
    "district_id": "121808",
    "label": "Nagur Pane",
    "value": "Nagur Pane"
  },
  {
    "id": "1218082009",
    "district_id": "121808",
    "label": "Parlambean",
    "value": "Parlambean"
  },
  {
    "id": "1218082010",
    "district_id": "121808",
    "label": "Naga Raja",
    "value": "Naga Raja"
  },
  {
    "id": "1218082011",
    "district_id": "121808",
    "label": "Mariah Nagur",
    "value": "Mariah Nagur"
  },
  {
    "id": "1218082012",
    "district_id": "121808",
    "label": "Rimbun",
    "value": "Rimbun"
  },
  {
    "id": "1218082013",
    "district_id": "121808",
    "label": "Marjanji",
    "value": "Marjanji"
  },
  {
    "id": "1218082014",
    "district_id": "121808",
    "label": "Silau Padang",
    "value": "Silau Padang"
  },
  {
    "id": "1218082015",
    "district_id": "121808",
    "label": "Buluh Duri",
    "value": "Buluh Duri"
  },
  {
    "id": "1218082016",
    "district_id": "121808",
    "label": "Gunung Monako",
    "value": "Gunung Monako"
  },
  {
    "id": "1218082017",
    "district_id": "121808",
    "label": "Damak Urat",
    "value": "Damak Urat"
  },
  {
    "id": "1218082018",
    "district_id": "121808",
    "label": "Simalas",
    "value": "Simalas"
  },
  {
    "id": "1218082019",
    "district_id": "121808",
    "label": "Gunung Pane",
    "value": "Gunung Pane"
  },
  {
    "id": "1218082020",
    "district_id": "121808",
    "label": "Sibarau",
    "value": "Sibarau"
  },
  {
    "id": "1218091029",
    "district_id": "121809",
    "label": "Pekan Dolok Masihul",
    "value": "Pekan Dolok Masihul"
  },
  {
    "id": "1218092001",
    "district_id": "121809",
    "label": "Dame",
    "value": "Dame"
  },
  {
    "id": "1218092002",
    "district_id": "121809",
    "label": "Blok 10",
    "value": "Blok 10"
  },
  {
    "id": "1218092003",
    "district_id": "121809",
    "label": "Kota Tengah",
    "value": "Kota Tengah"
  },
  {
    "id": "1218092004",
    "district_id": "121809",
    "label": "Silau Merawan",
    "value": "Silau Merawan"
  },
  {
    "id": "1218092005",
    "district_id": "121809",
    "label": "Pekan Kamis",
    "value": "Pekan Kamis"
  },
  {
    "id": "1218092006",
    "district_id": "121809",
    "label": "Batu 12",
    "value": "Batu 12"
  },
  {
    "id": "1218092007",
    "district_id": "121809",
    "label": "Batu 13",
    "value": "Batu 13"
  },
  {
    "id": "1218092008",
    "district_id": "121809",
    "label": "Baja Ronggi",
    "value": "Baja Ronggi"
  },
  {
    "id": "1218092009",
    "district_id": "121809",
    "label": "Dolok Sagala",
    "value": "Dolok Sagala"
  },
  {
    "id": "1218092010",
    "district_id": "121809",
    "label": "Dolok Manampang",
    "value": "Dolok Manampang"
  },
  {
    "id": "1218092011",
    "district_id": "121809",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1218092012",
    "district_id": "121809",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1218092013",
    "district_id": "121809",
    "label": "Pertambatan",
    "value": "Pertambatan"
  },
  {
    "id": "1218092015",
    "district_id": "121809",
    "label": "Huta Nauli",
    "value": "Huta Nauli"
  },
  {
    "id": "1218092016",
    "district_id": "121809",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "1218092017",
    "district_id": "121809",
    "label": "Bukit Cermin Hilir",
    "value": "Bukit Cermin Hilir"
  },
  {
    "id": "1218092018",
    "district_id": "121809",
    "label": "Malasori",
    "value": "Malasori"
  },
  {
    "id": "1218092019",
    "district_id": "121809",
    "label": "Sarang Giting",
    "value": "Sarang Giting"
  },
  {
    "id": "1218092020",
    "district_id": "121809",
    "label": "Durian Puloan",
    "value": "Durian Puloan"
  },
  {
    "id": "1218092021",
    "district_id": "121809",
    "label": "Sarang Torop",
    "value": "Sarang Torop"
  },
  {
    "id": "1218092022",
    "district_id": "121809",
    "label": "Havea",
    "value": "Havea"
  },
  {
    "id": "1218092023",
    "district_id": "121809",
    "label": "Aras Panjang",
    "value": "Aras Panjang"
  },
  {
    "id": "1218092024",
    "district_id": "121809",
    "label": "Martebing",
    "value": "Martebing"
  },
  {
    "id": "1218092025",
    "district_id": "121809",
    "label": "Kerapuh",
    "value": "Kerapuh"
  },
  {
    "id": "1218092026",
    "district_id": "121809",
    "label": "Tanjung Maria",
    "value": "Tanjung Maria"
  },
  {
    "id": "1218092027",
    "district_id": "121809",
    "label": "Bah Kerapuh",
    "value": "Bah Kerapuh"
  },
  {
    "id": "1218092028",
    "district_id": "121809",
    "label": "Ujung Silau",
    "value": "Ujung Silau"
  },
  {
    "id": "1218102001",
    "district_id": "121810",
    "label": "Kotarih Pekan",
    "value": "Kotarih Pekan"
  },
  {
    "id": "1218102002",
    "district_id": "121810",
    "label": "Kotarih Baru",
    "value": "Kotarih Baru"
  },
  {
    "id": "1218102003",
    "district_id": "121810",
    "label": "Banjaran Godang",
    "value": "Banjaran Godang"
  },
  {
    "id": "1218102004",
    "district_id": "121810",
    "label": "Si Ujan Ujan",
    "value": "Si Ujan Ujan"
  },
  {
    "id": "1218102010",
    "district_id": "121810",
    "label": "Sei Kari",
    "value": "Sei Kari"
  },
  {
    "id": "1218102013",
    "district_id": "121810",
    "label": "Bandar Bayu",
    "value": "Bandar Bayu"
  },
  {
    "id": "1218102014",
    "district_id": "121810",
    "label": "Durian Kondot",
    "value": "Durian Kondot"
  },
  {
    "id": "1218102015",
    "district_id": "121810",
    "label": "Perbahingan",
    "value": "Perbahingan"
  },
  {
    "id": "1218102022",
    "district_id": "121810",
    "label": "Sialtong",
    "value": "Sialtong"
  },
  {
    "id": "1218102023",
    "district_id": "121810",
    "label": "Huta Galuh",
    "value": "Huta Galuh"
  },
  {
    "id": "1218102024",
    "district_id": "121810",
    "label": "Rubun Dunia",
    "value": "Rubun Dunia"
  },
  {
    "id": "1218112004",
    "district_id": "121811",
    "label": "Batu Masagi",
    "value": "Batu Masagi"
  },
  {
    "id": "1218112013",
    "district_id": "121811",
    "label": "Damak Gelugur",
    "value": "Damak Gelugur"
  },
  {
    "id": "1218112016",
    "district_id": "121811",
    "label": "Kulasar",
    "value": "Kulasar"
  },
  {
    "id": "1218112021",
    "district_id": "121811",
    "label": "Pagar Manik",
    "value": "Pagar Manik"
  },
  {
    "id": "1218112022",
    "district_id": "121811",
    "label": "Pamah",
    "value": "Pamah"
  },
  {
    "id": "1218112026",
    "district_id": "121811",
    "label": "Silinda",
    "value": "Silinda"
  },
  {
    "id": "1218112028",
    "district_id": "121811",
    "label": "Sungai Buaya",
    "value": "Sungai Buaya"
  },
  {
    "id": "1218112029",
    "district_id": "121811",
    "label": "Tarean",
    "value": "Tarean"
  },
  {
    "id": "1218112031",
    "district_id": "121811",
    "label": "Tapak Meriah",
    "value": "Tapak Meriah"
  },
  {
    "id": "1218122010",
    "district_id": "121812",
    "label": "Tanjung Harap",
    "value": "Tanjung Harap"
  },
  {
    "id": "1218122011",
    "district_id": "121812",
    "label": "Kuala Bali",
    "value": "Kuala Bali"
  },
  {
    "id": "1218122014",
    "district_id": "121812",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "1218122015",
    "district_id": "121812",
    "label": "Kelapa Bajohom",
    "value": "Kelapa Bajohom"
  },
  {
    "id": "1218122018",
    "district_id": "121812",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "1218122020",
    "district_id": "121812",
    "label": "Pulau Gambar",
    "value": "Pulau Gambar"
  },
  {
    "id": "1218122021",
    "district_id": "121812",
    "label": "Pulau Tagor",
    "value": "Pulau Tagor"
  },
  {
    "id": "1218122032",
    "district_id": "121812",
    "label": "Serba Jadi",
    "value": "Serba Jadi"
  },
  {
    "id": "1218122033",
    "district_id": "121812",
    "label": "Tambak Cekur",
    "value": "Tambak Cekur"
  },
  {
    "id": "1218122038",
    "district_id": "121812",
    "label": "Bah Sidua-dua",
    "value": "Bah Sidua-dua"
  },
  {
    "id": "1218132001",
    "district_id": "121813",
    "label": "Bah Sumbu",
    "value": "Bah Sumbu"
  },
  {
    "id": "1218132002",
    "district_id": "121813",
    "label": "Naga Kesiangan",
    "value": "Naga Kesiangan"
  },
  {
    "id": "1218132003",
    "district_id": "121813",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "1218132004",
    "district_id": "121813",
    "label": "Mariah Padang",
    "value": "Mariah Padang"
  },
  {
    "id": "1218132005",
    "district_id": "121813",
    "label": "Pertapaan",
    "value": "Pertapaan"
  },
  {
    "id": "1218132006",
    "district_id": "121813",
    "label": "Paya Lombang",
    "value": "Paya Lombang"
  },
  {
    "id": "1218132007",
    "district_id": "121813",
    "label": "Sei Serimah",
    "value": "Sei Serimah"
  },
  {
    "id": "1218132008",
    "district_id": "121813",
    "label": "Paya Bagas",
    "value": "Paya Bagas"
  },
  {
    "id": "1218132009",
    "district_id": "121813",
    "label": "Paya Mabar",
    "value": "Paya Mabar"
  },
  {
    "id": "1218132010",
    "district_id": "121813",
    "label": "Sei. Priok",
    "value": "Sei. Priok"
  },
  {
    "id": "1218132011",
    "district_id": "121813",
    "label": "Kuta Baru",
    "value": "Kuta Baru"
  },
  {
    "id": "1218132018",
    "district_id": "121813",
    "label": "Kedai Damar",
    "value": "Kedai Damar"
  },
  {
    "id": "1218132019",
    "district_id": "121813",
    "label": "Penonggol",
    "value": "Penonggol"
  },
  {
    "id": "1218132020",
    "district_id": "121813",
    "label": "Gunung Kataran",
    "value": "Gunung Kataran"
  },
  {
    "id": "1218141012",
    "district_id": "121814",
    "label": "Melati Kebun",
    "value": "Melati Kebun"
  },
  {
    "id": "1218142001",
    "district_id": "121814",
    "label": "Bengabing",
    "value": "Bengabing"
  },
  {
    "id": "1218142002",
    "district_id": "121814",
    "label": "Bingkat",
    "value": "Bingkat"
  },
  {
    "id": "1218142003",
    "district_id": "121814",
    "label": "Jati Mulyo",
    "value": "Jati Mulyo"
  },
  {
    "id": "1218142004",
    "district_id": "121814",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1218142005",
    "district_id": "121814",
    "label": "Lestari Dadi",
    "value": "Lestari Dadi"
  },
  {
    "id": "1218142006",
    "district_id": "121814",
    "label": "Pegajahan",
    "value": "Pegajahan"
  },
  {
    "id": "1218142007",
    "district_id": "121814",
    "label": "Petuaran Hulu",
    "value": "Petuaran Hulu"
  },
  {
    "id": "1218142008",
    "district_id": "121814",
    "label": "Petuaran Hilir",
    "value": "Petuaran Hilir"
  },
  {
    "id": "1218142009",
    "district_id": "121814",
    "label": "Pondok Tengah",
    "value": "Pondok Tengah"
  },
  {
    "id": "1218142010",
    "district_id": "121814",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "1218142011",
    "district_id": "121814",
    "label": "Tanjung Putus",
    "value": "Tanjung Putus"
  },
  {
    "id": "1218142013",
    "district_id": "121814",
    "label": "Sennah",
    "value": "Sennah"
  },
  {
    "id": "1218152001",
    "district_id": "121815",
    "label": "Pon",
    "value": "Pon"
  },
  {
    "id": "1218152002",
    "district_id": "121815",
    "label": "Sei Bamban",
    "value": "Sei Bamban"
  },
  {
    "id": "1218152003",
    "district_id": "121815",
    "label": "Bakaran Batu",
    "value": "Bakaran Batu"
  },
  {
    "id": "1218152004",
    "district_id": "121815",
    "label": "Gempolan",
    "value": "Gempolan"
  },
  {
    "id": "1218152005",
    "district_id": "121815",
    "label": "Rampah Estate",
    "value": "Rampah Estate"
  },
  {
    "id": "1218152006",
    "district_id": "121815",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1218152007",
    "district_id": "121815",
    "label": "Sei Bamban Estate",
    "value": "Sei Bamban Estate"
  },
  {
    "id": "1218152008",
    "district_id": "121815",
    "label": "Sei Belutu",
    "value": "Sei Belutu"
  },
  {
    "id": "1218152009",
    "district_id": "121815",
    "label": "Sei Buluh",
    "value": "Sei Buluh"
  },
  {
    "id": "1218152010",
    "district_id": "121815",
    "label": "Penggalangan",
    "value": "Penggalangan"
  },
  {
    "id": "1218162001",
    "district_id": "121816",
    "label": "Penggalian",
    "value": "Penggalian"
  },
  {
    "id": "1218162002",
    "district_id": "121816",
    "label": "Laut Tador",
    "value": "Laut Tador"
  },
  {
    "id": "1218162003",
    "district_id": "121816",
    "label": "Tanah Besih",
    "value": "Tanah Besih"
  },
  {
    "id": "1218162004",
    "district_id": "121816",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1218162005",
    "district_id": "121816",
    "label": "Paya Pasir",
    "value": "Paya Pasir"
  },
  {
    "id": "1218162006",
    "district_id": "121816",
    "label": "Bahilang",
    "value": "Bahilang"
  },
  {
    "id": "1218162007",
    "district_id": "121816",
    "label": "Penggalangan",
    "value": "Penggalangan"
  },
  {
    "id": "1218162008",
    "district_id": "121816",
    "label": "Paya Pinang",
    "value": "Paya Pinang"
  },
  {
    "id": "1218162009",
    "district_id": "121816",
    "label": "Kuta Pinang",
    "value": "Kuta Pinang"
  },
  {
    "id": "1218162010",
    "district_id": "121816",
    "label": "Sibulan",
    "value": "Sibulan"
  },
  {
    "id": "1218172001",
    "district_id": "121817",
    "label": "Gudang Garam",
    "value": "Gudang Garam"
  },
  {
    "id": "1218172002",
    "district_id": "121817",
    "label": "Dolok Masango",
    "value": "Dolok Masango"
  },
  {
    "id": "1218172003",
    "district_id": "121817",
    "label": "Panombean",
    "value": "Panombean"
  },
  {
    "id": "1218172004",
    "district_id": "121817",
    "label": "Bandar Negeri",
    "value": "Bandar Negeri"
  },
  {
    "id": "1218172005",
    "district_id": "121817",
    "label": "Ujung Negeri Kahan",
    "value": "Ujung Negeri Kahan"
  },
  {
    "id": "1218172006",
    "district_id": "121817",
    "label": "Bandar Magodang",
    "value": "Bandar Magodang"
  },
  {
    "id": "1218172007",
    "district_id": "121817",
    "label": "Ujung Negeri Hulu",
    "value": "Ujung Negeri Hulu"
  },
  {
    "id": "1218172008",
    "district_id": "121817",
    "label": "Pegajahan Hulu",
    "value": "Pegajahan Hulu"
  },
  {
    "id": "1218172009",
    "district_id": "121817",
    "label": "Marihat Dolok",
    "value": "Marihat Dolok"
  },
  {
    "id": "1218172010",
    "district_id": "121817",
    "label": "Bintang Bayu",
    "value": "Bintang Bayu"
  },
  {
    "id": "1218172011",
    "district_id": "121817",
    "label": "Bandar Pinang Kebun",
    "value": "Bandar Pinang Kebun"
  },
  {
    "id": "1218172012",
    "district_id": "121817",
    "label": "Damak Tolong Buho",
    "value": "Damak Tolong Buho"
  },
  {
    "id": "1218172013",
    "district_id": "121817",
    "label": "Bandar Pinang Rambe",
    "value": "Bandar Pinang Rambe"
  },
  {
    "id": "1218172014",
    "district_id": "121817",
    "label": "Pegajahan Kahan",
    "value": "Pegajahan Kahan"
  },
  {
    "id": "1218172015",
    "district_id": "121817",
    "label": "Huta Durian",
    "value": "Huta Durian"
  },
  {
    "id": "1218172016",
    "district_id": "121817",
    "label": "Kampung Kristen",
    "value": "Kampung Kristen"
  },
  {
    "id": "1218172017",
    "district_id": "121817",
    "label": "Sarang Ginting Kahan",
    "value": "Sarang Ginting Kahan"
  },
  {
    "id": "1218172018",
    "district_id": "121817",
    "label": "Sarang Ginting Hulu",
    "value": "Sarang Ginting Hulu"
  },
  {
    "id": "1218172019",
    "district_id": "121817",
    "label": "Siahap",
    "value": "Siahap"
  },
  {
    "id": "1219011001",
    "district_id": "121901",
    "label": "Pangkalan Dodek",
    "value": "Pangkalan Dodek"
  },
  {
    "id": "1219011002",
    "district_id": "121901",
    "label": "Pangkalan Dodek Baru",
    "value": "Pangkalan Dodek Baru"
  },
  {
    "id": "1219011021",
    "district_id": "121901",
    "label": "Pagurawan",
    "value": "Pagurawan"
  },
  {
    "id": "1219012003",
    "district_id": "121901",
    "label": "Aek Nauli",
    "value": "Aek Nauli"
  },
  {
    "id": "1219012004",
    "district_id": "121901",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1219012005",
    "district_id": "121901",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1219012006",
    "district_id": "121901",
    "label": "Medang",
    "value": "Medang"
  },
  {
    "id": "1219012007",
    "district_id": "121901",
    "label": "Pakam",
    "value": "Pakam"
  },
  {
    "id": "1219012008",
    "district_id": "121901",
    "label": "Pakam Raya",
    "value": "Pakam Raya"
  },
  {
    "id": "1219012009",
    "district_id": "121901",
    "label": "Pematang Cengkering",
    "value": "Pematang Cengkering"
  },
  {
    "id": "1219012010",
    "district_id": "121901",
    "label": "Sei Buah Keras",
    "value": "Sei Buah Keras"
  },
  {
    "id": "1219012011",
    "district_id": "121901",
    "label": "Sei Rakyat",
    "value": "Sei Rakyat"
  },
  {
    "id": "1219012012",
    "district_id": "121901",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1219012013",
    "district_id": "121901",
    "label": "Tanjung Sigoni",
    "value": "Tanjung Sigoni"
  },
  {
    "id": "1219012014",
    "district_id": "121901",
    "label": "Nenas Siam",
    "value": "Nenas Siam"
  },
  {
    "id": "1219012015",
    "district_id": "121901",
    "label": "Pakam Raya Selatan",
    "value": "Pakam Raya Selatan"
  },
  {
    "id": "1219012016",
    "district_id": "121901",
    "label": "Mandarsah",
    "value": "Mandarsah"
  },
  {
    "id": "1219012017",
    "district_id": "121901",
    "label": "Medang Baru",
    "value": "Medang Baru"
  },
  {
    "id": "1219012018",
    "district_id": "121901",
    "label": "Pematang Nibung",
    "value": "Pematang Nibung"
  },
  {
    "id": "1219012019",
    "district_id": "121901",
    "label": "Cengkering Pekan",
    "value": "Cengkering Pekan"
  },
  {
    "id": "1219012020",
    "district_id": "121901",
    "label": "Sei Raja",
    "value": "Sei Raja"
  },
  {
    "id": "1219021001",
    "district_id": "121902",
    "label": "Perkebunan Sipare-pare",
    "value": "Perkebunan Sipare-pare"
  },
  {
    "id": "1219022007",
    "district_id": "121902",
    "label": "Sei Suka Deras",
    "value": "Sei Suka Deras"
  },
  {
    "id": "1219022008",
    "district_id": "121902",
    "label": "Simodong",
    "value": "Simodong"
  },
  {
    "id": "1219022009",
    "district_id": "121902",
    "label": "Pematang Jering",
    "value": "Pematang Jering"
  },
  {
    "id": "1219022010",
    "district_id": "121902",
    "label": "Pematang Kuing",
    "value": "Pematang Kuing"
  },
  {
    "id": "1219022011",
    "district_id": "121902",
    "label": "Kuala Indah",
    "value": "Kuala Indah"
  },
  {
    "id": "1219022012",
    "district_id": "121902",
    "label": "Kuala Tanjung",
    "value": "Kuala Tanjung"
  },
  {
    "id": "1219022018",
    "district_id": "121902",
    "label": "Tanjung Gading",
    "value": "Tanjung Gading"
  },
  {
    "id": "1219022019",
    "district_id": "121902",
    "label": "Simpang Kopi",
    "value": "Simpang Kopi"
  },
  {
    "id": "1219022020",
    "district_id": "121902",
    "label": "Brohol",
    "value": "Brohol"
  },
  {
    "id": "1219031001",
    "district_id": "121903",
    "label": "Indrapura",
    "value": "Indrapura"
  },
  {
    "id": "1219031019",
    "district_id": "121903",
    "label": "Indrasakti",
    "value": "Indrasakti"
  },
  {
    "id": "1219032002",
    "district_id": "121903",
    "label": "Limau Sundai",
    "value": "Limau Sundai"
  },
  {
    "id": "1219032003",
    "district_id": "121903",
    "label": "Pematang Panjang",
    "value": "Pematang Panjang"
  },
  {
    "id": "1219032004",
    "district_id": "121903",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1219032005",
    "district_id": "121903",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "1219032006",
    "district_id": "121903",
    "label": "Tanjungmuda",
    "value": "Tanjungmuda"
  },
  {
    "id": "1219032007",
    "district_id": "121903",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1219032008",
    "district_id": "121903",
    "label": "Aras",
    "value": "Aras"
  },
  {
    "id": "1219032009",
    "district_id": "121903",
    "label": "Pasar Lapan",
    "value": "Pasar Lapan"
  },
  {
    "id": "1219032010",
    "district_id": "121903",
    "label": "Sipare-pare",
    "value": "Sipare-pare"
  },
  {
    "id": "1219032011",
    "district_id": "121903",
    "label": "Tanjungkubah",
    "value": "Tanjungkubah"
  },
  {
    "id": "1219032012",
    "district_id": "121903",
    "label": "Tanjungharapan",
    "value": "Tanjungharapan"
  },
  {
    "id": "1219032013",
    "district_id": "121903",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "1219032014",
    "district_id": "121903",
    "label": "Tanah Rendah",
    "value": "Tanah Rendah"
  },
  {
    "id": "1219032015",
    "district_id": "121903",
    "label": "Tanjungmulya",
    "value": "Tanjungmulya"
  },
  {
    "id": "1219032016",
    "district_id": "121903",
    "label": "Titi Payung",
    "value": "Titi Payung"
  },
  {
    "id": "1219032017",
    "district_id": "121903",
    "label": "Perkotaan",
    "value": "Perkotaan"
  },
  {
    "id": "1219032018",
    "district_id": "121903",
    "label": "Kampung Kelapa",
    "value": "Kampung Kelapa"
  },
  {
    "id": "1219041001",
    "district_id": "121904",
    "label": "Lima Puluh Kota",
    "value": "Lima Puluh Kota"
  },
  {
    "id": "1219042011",
    "district_id": "121904",
    "label": "Mangkai Baru",
    "value": "Mangkai Baru"
  },
  {
    "id": "1219042012",
    "district_id": "121904",
    "label": "Mangkai Lama",
    "value": "Mangkai Lama"
  },
  {
    "id": "1219042014",
    "district_id": "121904",
    "label": "Perkebunan Dolok",
    "value": "Perkebunan Dolok"
  },
  {
    "id": "1219042015",
    "district_id": "121904",
    "label": "Perkebunan Kwala Gunung",
    "value": "Perkebunan Kwala Gunung"
  },
  {
    "id": "1219042016",
    "district_id": "121904",
    "label": "Antara",
    "value": "Antara"
  },
  {
    "id": "1219042017",
    "district_id": "121904",
    "label": "Perkebunan Lima Puluh",
    "value": "Perkebunan Lima Puluh"
  },
  {
    "id": "1219042018",
    "district_id": "121904",
    "label": "Perkebunan Limau Manis",
    "value": "Perkebunan Limau Manis"
  },
  {
    "id": "1219042019",
    "district_id": "121904",
    "label": "Perkebunan Tanah Gambus",
    "value": "Perkebunan Tanah Gambus"
  },
  {
    "id": "1219042025",
    "district_id": "121904",
    "label": "Simpang Gambus",
    "value": "Simpang Gambus"
  },
  {
    "id": "1219042026",
    "district_id": "121904",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1219042027",
    "district_id": "121904",
    "label": "Sumber Padi",
    "value": "Sumber Padi"
  },
  {
    "id": "1219051001",
    "district_id": "121905",
    "label": "Labuhan Ruku",
    "value": "Labuhan Ruku"
  },
  {
    "id": "1219052004",
    "district_id": "121905",
    "label": "Dahari Selebar",
    "value": "Dahari Selebar"
  },
  {
    "id": "1219052006",
    "district_id": "121905",
    "label": "Mesjid Lama",
    "value": "Mesjid Lama"
  },
  {
    "id": "1219052007",
    "district_id": "121905",
    "label": "Padang Genting",
    "value": "Padang Genting"
  },
  {
    "id": "1219052008",
    "district_id": "121905",
    "label": "Pahang",
    "value": "Pahang"
  },
  {
    "id": "1219052009",
    "district_id": "121905",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "1219052014",
    "district_id": "121905",
    "label": "Gunung Rante",
    "value": "Gunung Rante"
  },
  {
    "id": "1219052015",
    "district_id": "121905",
    "label": "Dahari Indah",
    "value": "Dahari Indah"
  },
  {
    "id": "1219052016",
    "district_id": "121905",
    "label": "Indra Yaman",
    "value": "Indra Yaman"
  },
  {
    "id": "1219052017",
    "district_id": "121905",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1219061001",
    "district_id": "121906",
    "label": "Tanjung Tiram",
    "value": "Tanjung Tiram"
  },
  {
    "id": "1219061022",
    "district_id": "121906",
    "label": "Bagan Arya",
    "value": "Bagan Arya"
  },
  {
    "id": "1219062002",
    "district_id": "121906",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "1219062004",
    "district_id": "121906",
    "label": "Bagan Dalam",
    "value": "Bagan Dalam"
  },
  {
    "id": "1219062005",
    "district_id": "121906",
    "label": "Bogak",
    "value": "Bogak"
  },
  {
    "id": "1219062006",
    "district_id": "121906",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1219062016",
    "district_id": "121906",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1219062017",
    "district_id": "121906",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1219062018",
    "district_id": "121906",
    "label": "Bandar Rahmat",
    "value": "Bandar Rahmat"
  },
  {
    "id": "1219062019",
    "district_id": "121906",
    "label": "Kampung Lalang",
    "value": "Kampung Lalang"
  },
  {
    "id": "1219072001",
    "district_id": "121907",
    "label": "Kwala Sikasim",
    "value": "Kwala Sikasim"
  },
  {
    "id": "1219072002",
    "district_id": "121907",
    "label": "Sei Balai",
    "value": "Sei Balai"
  },
  {
    "id": "1219072004",
    "district_id": "121907",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1219072005",
    "district_id": "121907",
    "label": "Perkebunan Sei Balai",
    "value": "Perkebunan Sei Balai"
  },
  {
    "id": "1219072007",
    "district_id": "121907",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "1219072008",
    "district_id": "121907",
    "label": "Sei Bejangkar",
    "value": "Sei Bejangkar"
  },
  {
    "id": "1219072009",
    "district_id": "121907",
    "label": "Mekar Mulio",
    "value": "Mekar Mulio"
  },
  {
    "id": "1219072010",
    "district_id": "121907",
    "label": "Perkebunan Sei Bejangkar",
    "value": "Perkebunan Sei Bejangkar"
  },
  {
    "id": "1219072012",
    "district_id": "121907",
    "label": "Benteng Jaya",
    "value": "Benteng Jaya"
  },
  {
    "id": "1219072013",
    "district_id": "121907",
    "label": "Tanah Timbul",
    "value": "Tanah Timbul"
  },
  {
    "id": "1219072014",
    "district_id": "121907",
    "label": "Perjuangan",
    "value": "Perjuangan"
  },
  {
    "id": "1219072015",
    "district_id": "121907",
    "label": "Sidomulio",
    "value": "Sidomulio"
  },
  {
    "id": "1219072016",
    "district_id": "121907",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "1219072017",
    "district_id": "121907",
    "label": "Suko Rejo",
    "value": "Suko Rejo"
  },
  {
    "id": "1219082001",
    "district_id": "121908",
    "label": "Tanjung Prapat",
    "value": "Tanjung Prapat"
  },
  {
    "id": "1219082002",
    "district_id": "121908",
    "label": "Laut Tador",
    "value": "Laut Tador"
  },
  {
    "id": "1219082003",
    "district_id": "121908",
    "label": "Pelanggiran Laut Tador",
    "value": "Pelanggiran Laut Tador"
  },
  {
    "id": "1219082004",
    "district_id": "121908",
    "label": "Perkebunan Tanjung Kasau",
    "value": "Perkebunan Tanjung Kasau"
  },
  {
    "id": "1219082005",
    "district_id": "121908",
    "label": "Dewi Sri",
    "value": "Dewi Sri"
  },
  {
    "id": "1219082006",
    "district_id": "121908",
    "label": "Tanjung Kasau",
    "value": "Tanjung Kasau"
  },
  {
    "id": "1219082007",
    "district_id": "121908",
    "label": "Tanjung Seri",
    "value": "Tanjung Seri"
  },
  {
    "id": "1219082008",
    "district_id": "121908",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1219082009",
    "district_id": "121908",
    "label": "Sei Simujur",
    "value": "Sei Simujur"
  },
  {
    "id": "1219082010",
    "district_id": "121908",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "1219092001",
    "district_id": "121909",
    "label": "Gambus Laut",
    "value": "Gambus Laut"
  },
  {
    "id": "1219092002",
    "district_id": "121909",
    "label": "Perupuk",
    "value": "Perupuk"
  },
  {
    "id": "1219092003",
    "district_id": "121909",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "1219092004",
    "district_id": "121909",
    "label": "Barung-Barung",
    "value": "Barung-Barung"
  },
  {
    "id": "1219092005",
    "district_id": "121909",
    "label": "Titi Putih",
    "value": "Titi Putih"
  },
  {
    "id": "1219092006",
    "district_id": "121909",
    "label": "Pasir Permit",
    "value": "Pasir Permit"
  },
  {
    "id": "1219092007",
    "district_id": "121909",
    "label": "Titi Merah",
    "value": "Titi Merah"
  },
  {
    "id": "1219092008",
    "district_id": "121909",
    "label": "Pematang Panjang",
    "value": "Pematang Panjang"
  },
  {
    "id": "1219092009",
    "district_id": "121909",
    "label": "Bulan-Bulan",
    "value": "Bulan-Bulan"
  },
  {
    "id": "1219092010",
    "district_id": "121909",
    "label": "Gunung Bandung",
    "value": "Gunung Bandung"
  },
  {
    "id": "1219092011",
    "district_id": "121909",
    "label": "Lubuk Cuik",
    "value": "Lubuk Cuik"
  },
  {
    "id": "1219092012",
    "district_id": "121909",
    "label": "Tanah Itam Ilir",
    "value": "Tanah Itam Ilir"
  },
  {
    "id": "1219092013",
    "district_id": "121909",
    "label": "Pematang Tengah",
    "value": "Pematang Tengah"
  },
  {
    "id": "1219102001",
    "district_id": "121910",
    "label": "Perkebunan Tanah Itam Ulu",
    "value": "Perkebunan Tanah Itam Ulu"
  },
  {
    "id": "1219102002",
    "district_id": "121910",
    "label": "Empat Negeri",
    "value": "Empat Negeri"
  },
  {
    "id": "1219102003",
    "district_id": "121910",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1219102004",
    "district_id": "121910",
    "label": "Lubuk Hulu",
    "value": "Lubuk Hulu"
  },
  {
    "id": "1219102005",
    "district_id": "121910",
    "label": "Lubuk Besar",
    "value": "Lubuk Besar"
  },
  {
    "id": "1219102006",
    "district_id": "121910",
    "label": "Pulau Sejuk",
    "value": "Pulau Sejuk"
  },
  {
    "id": "1219102007",
    "district_id": "121910",
    "label": "Simpang Dolok",
    "value": "Simpang Dolok"
  },
  {
    "id": "1219102008",
    "district_id": "121910",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1219102009",
    "district_id": "121910",
    "label": "Kwala Gunung",
    "value": "Kwala Gunung"
  },
  {
    "id": "1219102010",
    "district_id": "121910",
    "label": "Cahaya Pardomuan",
    "value": "Cahaya Pardomuan"
  },
  {
    "id": "1219112001",
    "district_id": "121911",
    "label": "Sumber Tani",
    "value": "Sumber Tani"
  },
  {
    "id": "1219112002",
    "district_id": "121911",
    "label": "Sei Muka",
    "value": "Sei Muka"
  },
  {
    "id": "1219112003",
    "district_id": "121911",
    "label": "Perkebunan Tanah Datar",
    "value": "Perkebunan Tanah Datar"
  },
  {
    "id": "1219112004",
    "district_id": "121911",
    "label": "Binjai Baru",
    "value": "Binjai Baru"
  },
  {
    "id": "1219112005",
    "district_id": "121911",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1219112006",
    "district_id": "121911",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "1219112007",
    "district_id": "121911",
    "label": "Perkebunan Petatal",
    "value": "Perkebunan Petatal"
  },
  {
    "id": "1219112008",
    "district_id": "121911",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "1219112009",
    "district_id": "121911",
    "label": "Petatal",
    "value": "Petatal"
  },
  {
    "id": "1219112010",
    "district_id": "121911",
    "label": "Glugur Makmur",
    "value": "Glugur Makmur"
  },
  {
    "id": "1219122001",
    "district_id": "121912",
    "label": "Sentang",
    "value": "Sentang"
  },
  {
    "id": "1219122002",
    "district_id": "121912",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1219122003",
    "district_id": "121912",
    "label": "Jati Mulia",
    "value": "Jati Mulia"
  },
  {
    "id": "1219122004",
    "district_id": "121912",
    "label": "Lima Laras",
    "value": "Lima Laras"
  },
  {
    "id": "1219122005",
    "district_id": "121912",
    "label": "Mekar Laras",
    "value": "Mekar Laras"
  },
  {
    "id": "1219122006",
    "district_id": "121912",
    "label": "Bandar Sono",
    "value": "Bandar Sono"
  },
  {
    "id": "1219122007",
    "district_id": "121912",
    "label": "Ujung Kubu",
    "value": "Ujung Kubu"
  },
  {
    "id": "1219122008",
    "district_id": "121912",
    "label": "Sei Mentaram",
    "value": "Sei Mentaram"
  },
  {
    "id": "1219122009",
    "district_id": "121912",
    "label": "Pematang Rambai",
    "value": "Pematang Rambai"
  },
  {
    "id": "1219122010",
    "district_id": "121912",
    "label": "Tali Air Permai",
    "value": "Tali Air Permai"
  },
  {
    "id": "1219122011",
    "district_id": "121912",
    "label": "Bagan Baru",
    "value": "Bagan Baru"
  },
  {
    "id": "1219122012",
    "district_id": "121912",
    "label": "Kapal Merah",
    "value": "Kapal Merah"
  },
  {
    "id": "1220012001",
    "district_id": "122001",
    "label": "Janji Manahan GNT",
    "value": "Janji Manahan GNT"
  },
  {
    "id": "1220012002",
    "district_id": "122001",
    "label": "Pasang Lela",
    "value": "Pasang Lela"
  },
  {
    "id": "1220012003",
    "district_id": "122001",
    "label": "Aek Jabut",
    "value": "Aek Jabut"
  },
  {
    "id": "1220012004",
    "district_id": "122001",
    "label": "Nabundong",
    "value": "Nabundong"
  },
  {
    "id": "1220012005",
    "district_id": "122001",
    "label": "Gunung Sormin",
    "value": "Gunung Sormin"
  },
  {
    "id": "1220012006",
    "district_id": "122001",
    "label": "Sihalo Halo",
    "value": "Sihalo Halo"
  },
  {
    "id": "1220012007",
    "district_id": "122001",
    "label": "Kuala Simpang",
    "value": "Kuala Simpang"
  },
  {
    "id": "1220012008",
    "district_id": "122001",
    "label": "Simundol",
    "value": "Simundol"
  },
  {
    "id": "1220012009",
    "district_id": "122001",
    "label": "Nahula Julu",
    "value": "Nahula Julu"
  },
  {
    "id": "1220012010",
    "district_id": "122001",
    "label": "Simaninggir Simund",
    "value": "Simaninggir Simund"
  },
  {
    "id": "1220012011",
    "district_id": "122001",
    "label": "Simangambat",
    "value": "Simangambat"
  },
  {
    "id": "1220012012",
    "district_id": "122001",
    "label": "Saba Bangun",
    "value": "Saba Bangun"
  },
  {
    "id": "1220012013",
    "district_id": "122001",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "1220012014",
    "district_id": "122001",
    "label": "Gonting Bange",
    "value": "Gonting Bange"
  },
  {
    "id": "1220012015",
    "district_id": "122001",
    "label": "Hutaimbaru Simundol",
    "value": "Hutaimbaru Simundol"
  },
  {
    "id": "1220012016",
    "district_id": "122001",
    "label": "Padang Matinggi Simundol",
    "value": "Padang Matinggi Simundol"
  },
  {
    "id": "1220012017",
    "district_id": "122001",
    "label": "Sunut",
    "value": "Sunut"
  },
  {
    "id": "1220012018",
    "district_id": "122001",
    "label": "Batu Hibul",
    "value": "Batu Hibul"
  },
  {
    "id": "1220012019",
    "district_id": "122001",
    "label": "Sigordang",
    "value": "Sigordang"
  },
  {
    "id": "1220012020",
    "district_id": "122001",
    "label": "Pamarai",
    "value": "Pamarai"
  },
  {
    "id": "1220012021",
    "district_id": "122001",
    "label": "Padang Matinggi GNT",
    "value": "Padang Matinggi GNT"
  },
  {
    "id": "1220012022",
    "district_id": "122001",
    "label": "Gadung Holbung",
    "value": "Gadung Holbung"
  },
  {
    "id": "1220012023",
    "district_id": "122001",
    "label": "Salusuhan",
    "value": "Salusuhan"
  },
  {
    "id": "1220012024",
    "district_id": "122001",
    "label": "Panyabungan",
    "value": "Panyabungan"
  },
  {
    "id": "1220012025",
    "district_id": "122001",
    "label": "Sayur Matinggi",
    "value": "Sayur Matinggi"
  },
  {
    "id": "1220012026",
    "district_id": "122001",
    "label": "Hasahatan",
    "value": "Hasahatan"
  },
  {
    "id": "1220012027",
    "district_id": "122001",
    "label": "Pinarik",
    "value": "Pinarik"
  },
  {
    "id": "1220012028",
    "district_id": "122001",
    "label": "Unte Manis",
    "value": "Unte Manis"
  },
  {
    "id": "1220012029",
    "district_id": "122001",
    "label": "Sipogas",
    "value": "Sipogas"
  },
  {
    "id": "1220012030",
    "district_id": "122001",
    "label": "Sipogas A",
    "value": "Sipogas A"
  },
  {
    "id": "1220012031",
    "district_id": "122001",
    "label": "Padang Malakka",
    "value": "Padang Malakka"
  },
  {
    "id": "1220012032",
    "district_id": "122001",
    "label": "Aek Simanap",
    "value": "Aek Simanap"
  },
  {
    "id": "1220012033",
    "district_id": "122001",
    "label": "Pulo Liman",
    "value": "Pulo Liman"
  },
  {
    "id": "1220012034",
    "district_id": "122001",
    "label": "Aek Kundur",
    "value": "Aek Kundur"
  },
  {
    "id": "1220012035",
    "district_id": "122001",
    "label": "Hatiran",
    "value": "Hatiran"
  },
  {
    "id": "1220012036",
    "district_id": "122001",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1220012037",
    "district_id": "122001",
    "label": "Aek Kanan",
    "value": "Aek Kanan"
  },
  {
    "id": "1220012038",
    "district_id": "122001",
    "label": "Nahula Jae",
    "value": "Nahula Jae"
  },
  {
    "id": "1220012039",
    "district_id": "122001",
    "label": "Simadihon",
    "value": "Simadihon"
  },
  {
    "id": "1220012040",
    "district_id": "122001",
    "label": "Pasar Sayur Matinggi",
    "value": "Pasar Sayur Matinggi"
  },
  {
    "id": "1220012041",
    "district_id": "122001",
    "label": "Tj. Baru Silaiya",
    "value": "Tj. Baru Silaiya"
  },
  {
    "id": "1220012042",
    "district_id": "122001",
    "label": "Sitonun",
    "value": "Sitonun"
  },
  {
    "id": "1220012043",
    "district_id": "122001",
    "label": "Pasar Simundol",
    "value": "Pasar Simundol"
  },
  {
    "id": "1220012044",
    "district_id": "122001",
    "label": "Pamonoran",
    "value": "Pamonoran"
  },
  {
    "id": "1220022001",
    "district_id": "122002",
    "label": "Siguga",
    "value": "Siguga"
  },
  {
    "id": "1220022002",
    "district_id": "122002",
    "label": "Singanyal",
    "value": "Singanyal"
  },
  {
    "id": "1220022003",
    "district_id": "122002",
    "label": "Binanga Panasahan",
    "value": "Binanga Panasahan"
  },
  {
    "id": "1220022004",
    "district_id": "122002",
    "label": "Aek Haruaya",
    "value": "Aek Haruaya"
  },
  {
    "id": "1220022005",
    "district_id": "122002",
    "label": "Tanjung Baru B",
    "value": "Tanjung Baru B"
  },
  {
    "id": "1220022006",
    "district_id": "122002",
    "label": "Pasar Sipiongot",
    "value": "Pasar Sipiongot"
  },
  {
    "id": "1220022007",
    "district_id": "122002",
    "label": "Bintais Julu",
    "value": "Bintais Julu"
  },
  {
    "id": "1220022008",
    "district_id": "122002",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "1220022009",
    "district_id": "122002",
    "label": "Paya Ombik",
    "value": "Paya Ombik"
  },
  {
    "id": "1220022010",
    "district_id": "122002",
    "label": "Aek Suhat Jae",
    "value": "Aek Suhat Jae"
  },
  {
    "id": "1220022011",
    "district_id": "122002",
    "label": "Huta Baru Sil",
    "value": "Huta Baru Sil"
  },
  {
    "id": "1220022012",
    "district_id": "122002",
    "label": "Sijorang",
    "value": "Sijorang"
  },
  {
    "id": "1220022013",
    "district_id": "122002",
    "label": "Simaninggir Sip",
    "value": "Simaninggir Sip"
  },
  {
    "id": "1220022014",
    "district_id": "122002",
    "label": "Pangaran Julu I",
    "value": "Pangaran Julu I"
  },
  {
    "id": "1220022015",
    "district_id": "122002",
    "label": "Mompang Lombang",
    "value": "Mompang Lombang"
  },
  {
    "id": "1220022016",
    "district_id": "122002",
    "label": "Janji Manahan Sil",
    "value": "Janji Manahan Sil"
  },
  {
    "id": "1220022017",
    "district_id": "122002",
    "label": "Baringin Sip",
    "value": "Baringin Sip"
  },
  {
    "id": "1220022018",
    "district_id": "122002",
    "label": "Gunung Maria",
    "value": "Gunung Maria"
  },
  {
    "id": "1220022019",
    "district_id": "122002",
    "label": "Mompang Dolok",
    "value": "Mompang Dolok"
  },
  {
    "id": "1220022020",
    "district_id": "122002",
    "label": "Aek Rao TN",
    "value": "Aek Rao TN"
  },
  {
    "id": "1220022021",
    "district_id": "122002",
    "label": "Sijantung Julu",
    "value": "Sijantung Julu"
  },
  {
    "id": "1220022022",
    "district_id": "122002",
    "label": "Batu Runding",
    "value": "Batu Runding"
  },
  {
    "id": "1220022023",
    "district_id": "122002",
    "label": "Lubuk Godang",
    "value": "Lubuk Godang"
  },
  {
    "id": "1220022024",
    "district_id": "122002",
    "label": "Bahap",
    "value": "Bahap"
  },
  {
    "id": "1220022025",
    "district_id": "122002",
    "label": "Aek Ilung",
    "value": "Aek Ilung"
  },
  {
    "id": "1220022026",
    "district_id": "122002",
    "label": "Tarutung Bolak",
    "value": "Tarutung Bolak"
  },
  {
    "id": "1220022027",
    "district_id": "122002",
    "label": "Sungai Pining",
    "value": "Sungai Pining"
  },
  {
    "id": "1220022028",
    "district_id": "122002",
    "label": "Siloung",
    "value": "Siloung"
  },
  {
    "id": "1220022029",
    "district_id": "122002",
    "label": "Hutaimbaru Gul",
    "value": "Hutaimbaru Gul"
  },
  {
    "id": "1220022030",
    "district_id": "122002",
    "label": "Sipiongot",
    "value": "Sipiongot"
  },
  {
    "id": "1220022031",
    "district_id": "122002",
    "label": "Gumbot",
    "value": "Gumbot"
  },
  {
    "id": "1220022032",
    "district_id": "122002",
    "label": "Gunung Selamat",
    "value": "Gunung Selamat"
  },
  {
    "id": "1220022033",
    "district_id": "122002",
    "label": "Silogo Logo",
    "value": "Silogo Logo"
  },
  {
    "id": "1220022034",
    "district_id": "122002",
    "label": "Lubuk Kundur",
    "value": "Lubuk Kundur"
  },
  {
    "id": "1220022035",
    "district_id": "122002",
    "label": "Jambur Batu",
    "value": "Jambur Batu"
  },
  {
    "id": "1220022036",
    "district_id": "122002",
    "label": "Simatorkis",
    "value": "Simatorkis"
  },
  {
    "id": "1220022037",
    "district_id": "122002",
    "label": "Rancaran",
    "value": "Rancaran"
  },
  {
    "id": "1220022038",
    "district_id": "122002",
    "label": "Naga Saribu",
    "value": "Naga Saribu"
  },
  {
    "id": "1220022039",
    "district_id": "122002",
    "label": "Simataniari Jae",
    "value": "Simataniari Jae"
  },
  {
    "id": "1220022040",
    "district_id": "122002",
    "label": "Janji Manahan Gul",
    "value": "Janji Manahan Gul"
  },
  {
    "id": "1220022041",
    "district_id": "122002",
    "label": "Simataniari",
    "value": "Simataniari"
  },
  {
    "id": "1220022042",
    "district_id": "122002",
    "label": "Parmeraan",
    "value": "Parmeraan"
  },
  {
    "id": "1220022043",
    "district_id": "122002",
    "label": "Panca",
    "value": "Panca"
  },
  {
    "id": "1220022044",
    "district_id": "122002",
    "label": "Dalihan Natolu",
    "value": "Dalihan Natolu"
  },
  {
    "id": "1220022045",
    "district_id": "122002",
    "label": "Sungai Datar",
    "value": "Sungai Datar"
  },
  {
    "id": "1220022046",
    "district_id": "122002",
    "label": "Lubuk Lanjang",
    "value": "Lubuk Lanjang"
  },
  {
    "id": "1220022047",
    "district_id": "122002",
    "label": "Arse",
    "value": "Arse"
  },
  {
    "id": "1220022048",
    "district_id": "122002",
    "label": "Simangambat Tua",
    "value": "Simangambat Tua"
  },
  {
    "id": "1220022049",
    "district_id": "122002",
    "label": "Sigala Gala",
    "value": "Sigala Gala"
  },
  {
    "id": "1220022050",
    "district_id": "122002",
    "label": "Sibayo Jae",
    "value": "Sibayo Jae"
  },
  {
    "id": "1220022051",
    "district_id": "122002",
    "label": "Dolok Sanggul",
    "value": "Dolok Sanggul"
  },
  {
    "id": "1220022052",
    "district_id": "122002",
    "label": "Silangge",
    "value": "Silangge"
  },
  {
    "id": "1220022053",
    "district_id": "122002",
    "label": "Pijor Koling",
    "value": "Pijor Koling"
  },
  {
    "id": "1220022054",
    "district_id": "122002",
    "label": "Pagaran Julu II",
    "value": "Pagaran Julu II"
  },
  {
    "id": "1220022055",
    "district_id": "122002",
    "label": "Siala Gundi",
    "value": "Siala Gundi"
  },
  {
    "id": "1220022056",
    "district_id": "122002",
    "label": "Siraga HP",
    "value": "Siraga HP"
  },
  {
    "id": "1220022057",
    "district_id": "122002",
    "label": "Aek Sundur",
    "value": "Aek Sundur"
  },
  {
    "id": "1220022058",
    "district_id": "122002",
    "label": "Baringin Sil",
    "value": "Baringin Sil"
  },
  {
    "id": "1220022059",
    "district_id": "122002",
    "label": "Hula Baringin",
    "value": "Hula Baringin"
  },
  {
    "id": "1220022060",
    "district_id": "122002",
    "label": "Nabonggal",
    "value": "Nabonggal"
  },
  {
    "id": "1220022061",
    "district_id": "122002",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1220022062",
    "district_id": "122002",
    "label": "Tanjung Longat",
    "value": "Tanjung Longat"
  },
  {
    "id": "1220022063",
    "district_id": "122002",
    "label": "Sibio Bio",
    "value": "Sibio Bio"
  },
  {
    "id": "1220022064",
    "district_id": "122002",
    "label": "Situmbaga",
    "value": "Situmbaga"
  },
  {
    "id": "1220022065",
    "district_id": "122002",
    "label": "Sijara Jara",
    "value": "Sijara Jara"
  },
  {
    "id": "1220022066",
    "district_id": "122002",
    "label": "Huta Baru Sip",
    "value": "Huta Baru Sip"
  },
  {
    "id": "1220022067",
    "district_id": "122002",
    "label": "Pagaran Siregar",
    "value": "Pagaran Siregar"
  },
  {
    "id": "1220022068",
    "district_id": "122002",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "1220022069",
    "district_id": "122002",
    "label": "Napasundali",
    "value": "Napasundali"
  },
  {
    "id": "1220022070",
    "district_id": "122002",
    "label": "Aek Suhat TR",
    "value": "Aek Suhat TR"
  },
  {
    "id": "1220022071",
    "district_id": "122002",
    "label": "Gumaruntar",
    "value": "Gumaruntar"
  },
  {
    "id": "1220022072",
    "district_id": "122002",
    "label": "Sijantung Jae",
    "value": "Sijantung Jae"
  },
  {
    "id": "1220022073",
    "district_id": "122002",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1220022074",
    "district_id": "122002",
    "label": "Sialang Dolok",
    "value": "Sialang Dolok"
  },
  {
    "id": "1220022075",
    "district_id": "122002",
    "label": "Binanga Gumbot",
    "value": "Binanga Gumbot"
  },
  {
    "id": "1220022076",
    "district_id": "122002",
    "label": "Simanosor",
    "value": "Simanosor"
  },
  {
    "id": "1220022077",
    "district_id": "122002",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "1220022078",
    "district_id": "122002",
    "label": "Simambal",
    "value": "Simambal"
  },
  {
    "id": "1220022079",
    "district_id": "122002",
    "label": "Bandar Nauli",
    "value": "Bandar Nauli"
  },
  {
    "id": "1220022080",
    "district_id": "122002",
    "label": "Sibayo",
    "value": "Sibayo"
  },
  {
    "id": "1220022081",
    "district_id": "122002",
    "label": "Sinabongan",
    "value": "Sinabongan"
  },
  {
    "id": "1220022082",
    "district_id": "122002",
    "label": "Siranap",
    "value": "Siranap"
  },
  {
    "id": "1220022083",
    "district_id": "122002",
    "label": "Aek Tangga",
    "value": "Aek Tangga"
  },
  {
    "id": "1220022084",
    "district_id": "122002",
    "label": "Rongkare",
    "value": "Rongkare"
  },
  {
    "id": "1220022085",
    "district_id": "122002",
    "label": "Siburbur",
    "value": "Siburbur"
  },
  {
    "id": "1220022086",
    "district_id": "122002",
    "label": "Pintu Padang Merdeka",
    "value": "Pintu Padang Merdeka"
  },
  {
    "id": "1220032001",
    "district_id": "122003",
    "label": "Hutaimbaru",
    "value": "Hutaimbaru"
  },
  {
    "id": "1220032002",
    "district_id": "122003",
    "label": "Hambulo",
    "value": "Hambulo"
  },
  {
    "id": "1220032003",
    "district_id": "122003",
    "label": "Paolan",
    "value": "Paolan"
  },
  {
    "id": "1220032004",
    "district_id": "122003",
    "label": "Pangirkiran",
    "value": "Pangirkiran"
  },
  {
    "id": "1220032005",
    "district_id": "122003",
    "label": "Balimbing",
    "value": "Balimbing"
  },
  {
    "id": "1220032006",
    "district_id": "122003",
    "label": "Rondaman Siburegar",
    "value": "Rondaman Siburegar"
  },
  {
    "id": "1220032007",
    "district_id": "122003",
    "label": "Sipaho",
    "value": "Sipaho"
  },
  {
    "id": "1220032008",
    "district_id": "122003",
    "label": "Silantoyung",
    "value": "Silantoyung"
  },
  {
    "id": "1220032009",
    "district_id": "122003",
    "label": "Hiteurat",
    "value": "Hiteurat"
  },
  {
    "id": "1220032010",
    "district_id": "122003",
    "label": "Siboru Angin",
    "value": "Siboru Angin"
  },
  {
    "id": "1220032011",
    "district_id": "122003",
    "label": "Sandean Jae",
    "value": "Sandean Jae"
  },
  {
    "id": "1220032012",
    "district_id": "122003",
    "label": "Sandean Tonga",
    "value": "Sandean Tonga"
  },
  {
    "id": "1220032013",
    "district_id": "122003",
    "label": "Sandean Julu",
    "value": "Sandean Julu"
  },
  {
    "id": "1220032014",
    "district_id": "122003",
    "label": "Hasahatan",
    "value": "Hasahatan"
  },
  {
    "id": "1220032015",
    "district_id": "122003",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1220032016",
    "district_id": "122003",
    "label": "Pangarambangan",
    "value": "Pangarambangan"
  },
  {
    "id": "1220032017",
    "district_id": "122003",
    "label": "Napa Lancat",
    "value": "Napa Lancat"
  },
  {
    "id": "1220032018",
    "district_id": "122003",
    "label": "Halongonan",
    "value": "Halongonan"
  },
  {
    "id": "1220032019",
    "district_id": "122003",
    "label": "Bargot Topong Julu",
    "value": "Bargot Topong Julu"
  },
  {
    "id": "1220032020",
    "district_id": "122003",
    "label": "Bargot Topong Jae",
    "value": "Bargot Topong Jae"
  },
  {
    "id": "1220032021",
    "district_id": "122003",
    "label": "Siringki Jae",
    "value": "Siringki Jae"
  },
  {
    "id": "1220032022",
    "district_id": "122003",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1220032023",
    "district_id": "122003",
    "label": "Sigala Gala",
    "value": "Sigala Gala"
  },
  {
    "id": "1220032032",
    "district_id": "122003",
    "label": "Hutanopan",
    "value": "Hutanopan"
  },
  {
    "id": "1220032036",
    "district_id": "122003",
    "label": "Sipenggeng",
    "value": "Sipenggeng"
  },
  {
    "id": "1220032037",
    "district_id": "122003",
    "label": "Saba",
    "value": "Saba"
  },
  {
    "id": "1220032038",
    "district_id": "122003",
    "label": "Sitabola",
    "value": "Sitabola"
  },
  {
    "id": "1220032039",
    "district_id": "122003",
    "label": "Sitonun",
    "value": "Sitonun"
  },
  {
    "id": "1220032040",
    "district_id": "122003",
    "label": "Tapus Jae",
    "value": "Tapus Jae"
  },
  {
    "id": "1220032041",
    "district_id": "122003",
    "label": "Japinulik",
    "value": "Japinulik"
  },
  {
    "id": "1220032042",
    "district_id": "122003",
    "label": "Paran Honas",
    "value": "Paran Honas"
  },
  {
    "id": "1220032043",
    "district_id": "122003",
    "label": "Siringki Julu",
    "value": "Siringki Julu"
  },
  {
    "id": "1220032044",
    "district_id": "122003",
    "label": "Batu Tunggal",
    "value": "Batu Tunggal"
  },
  {
    "id": "1220041001",
    "district_id": "122004",
    "label": "Pasar Gunung Tua",
    "value": "Pasar Gunung Tua"
  },
  {
    "id": "1220042008",
    "district_id": "122004",
    "label": "Lubuk Torop",
    "value": "Lubuk Torop"
  },
  {
    "id": "1220042012",
    "district_id": "122004",
    "label": "Tanjung Marulak",
    "value": "Tanjung Marulak"
  },
  {
    "id": "1220042013",
    "district_id": "122004",
    "label": "Ambasang Natigor",
    "value": "Ambasang Natigor"
  },
  {
    "id": "1220042014",
    "district_id": "122004",
    "label": "Napagadung Laut",
    "value": "Napagadung Laut"
  },
  {
    "id": "1220042015",
    "district_id": "122004",
    "label": "Rampa Jae",
    "value": "Rampa Jae"
  },
  {
    "id": "1220042016",
    "district_id": "122004",
    "label": "Rampa Julu",
    "value": "Rampa Julu"
  },
  {
    "id": "1220042017",
    "district_id": "122004",
    "label": "Mananti",
    "value": "Mananti"
  },
  {
    "id": "1220042018",
    "district_id": "122004",
    "label": "Paran Padang",
    "value": "Paran Padang"
  },
  {
    "id": "1220042019",
    "district_id": "122004",
    "label": "Rahuning Jae",
    "value": "Rahuning Jae"
  },
  {
    "id": "1220042020",
    "district_id": "122004",
    "label": "Botung",
    "value": "Botung"
  },
  {
    "id": "1220042021",
    "district_id": "122004",
    "label": "Sigimbal",
    "value": "Sigimbal"
  },
  {
    "id": "1220042022",
    "district_id": "122004",
    "label": "Dolok Sae",
    "value": "Dolok Sae"
  },
  {
    "id": "1220042023",
    "district_id": "122004",
    "label": "Simasi",
    "value": "Simasi"
  },
  {
    "id": "1220042024",
    "district_id": "122004",
    "label": "Sihapas Hapas",
    "value": "Sihapas Hapas"
  },
  {
    "id": "1220042025",
    "district_id": "122004",
    "label": "Sungai Tolang",
    "value": "Sungai Tolang"
  },
  {
    "id": "1220042026",
    "district_id": "122004",
    "label": "Losung Batu",
    "value": "Losung Batu"
  },
  {
    "id": "1220042027",
    "district_id": "122004",
    "label": "Batu Mamak",
    "value": "Batu Mamak"
  },
  {
    "id": "1220042028",
    "district_id": "122004",
    "label": "Sampuran",
    "value": "Sampuran"
  },
  {
    "id": "1220042029",
    "district_id": "122004",
    "label": "Gunung Tua Baru",
    "value": "Gunung Tua Baru"
  },
  {
    "id": "1220042030",
    "district_id": "122004",
    "label": "Sibatang Kayu",
    "value": "Sibatang Kayu"
  },
  {
    "id": "1220042031",
    "district_id": "122004",
    "label": "Aek Suhat",
    "value": "Aek Suhat"
  },
  {
    "id": "1220042038",
    "district_id": "122004",
    "label": "Gunung Tua Tonga",
    "value": "Gunung Tua Tonga"
  },
  {
    "id": "1220042039",
    "district_id": "122004",
    "label": "Gunung Tua Jae",
    "value": "Gunung Tua Jae"
  },
  {
    "id": "1220042040",
    "district_id": "122004",
    "label": "Gunung Tua Julu",
    "value": "Gunung Tua Julu"
  },
  {
    "id": "1220042041",
    "district_id": "122004",
    "label": "Hutalombang",
    "value": "Hutalombang"
  },
  {
    "id": "1220042042",
    "district_id": "122004",
    "label": "Batang Baruhar Julu",
    "value": "Batang Baruhar Julu"
  },
  {
    "id": "1220042043",
    "district_id": "122004",
    "label": "Batang Baruhar Jae",
    "value": "Batang Baruhar Jae"
  },
  {
    "id": "1220042044",
    "district_id": "122004",
    "label": "Purba Sinomba",
    "value": "Purba Sinomba"
  },
  {
    "id": "1220042045",
    "district_id": "122004",
    "label": "Hambiri",
    "value": "Hambiri"
  },
  {
    "id": "1220042046",
    "district_id": "122004",
    "label": "Sosopan",
    "value": "Sosopan"
  },
  {
    "id": "1220042047",
    "district_id": "122004",
    "label": "Pagaran Singkam",
    "value": "Pagaran Singkam"
  },
  {
    "id": "1220042048",
    "district_id": "122004",
    "label": "Pagaran Tonga",
    "value": "Pagaran Tonga"
  },
  {
    "id": "1220042049",
    "district_id": "122004",
    "label": "Sibagasi",
    "value": "Sibagasi"
  },
  {
    "id": "1220042050",
    "district_id": "122004",
    "label": "Batu Tambun",
    "value": "Batu Tambun"
  },
  {
    "id": "1220042051",
    "district_id": "122004",
    "label": "Tanjung Tiram",
    "value": "Tanjung Tiram"
  },
  {
    "id": "1220042052",
    "district_id": "122004",
    "label": "Garoga",
    "value": "Garoga"
  },
  {
    "id": "1220042053",
    "district_id": "122004",
    "label": "Hajoran",
    "value": "Hajoran"
  },
  {
    "id": "1220042054",
    "district_id": "122004",
    "label": "Batu Sundung",
    "value": "Batu Sundung"
  },
  {
    "id": "1220042055",
    "district_id": "122004",
    "label": "Garonggang",
    "value": "Garonggang"
  },
  {
    "id": "1220042056",
    "district_id": "122004",
    "label": "Nabonggal",
    "value": "Nabonggal"
  },
  {
    "id": "1220042057",
    "district_id": "122004",
    "label": "Liang Hasona",
    "value": "Liang Hasona"
  },
  {
    "id": "1220042058",
    "district_id": "122004",
    "label": "Simandiangin Dolok",
    "value": "Simandiangin Dolok"
  },
  {
    "id": "1220042059",
    "district_id": "122004",
    "label": "Simandiangin Lombang",
    "value": "Simandiangin Lombang"
  },
  {
    "id": "1220042060",
    "district_id": "122004",
    "label": "Padang Garugur",
    "value": "Padang Garugur"
  },
  {
    "id": "1220042061",
    "district_id": "122004",
    "label": "Simanosor",
    "value": "Simanosor"
  },
  {
    "id": "1220042062",
    "district_id": "122004",
    "label": "Sigama",
    "value": "Sigama"
  },
  {
    "id": "1220042063",
    "district_id": "122004",
    "label": "Sigama Ujung Gading",
    "value": "Sigama Ujung Gading"
  },
  {
    "id": "1220042064",
    "district_id": "122004",
    "label": "Parlimbatan",
    "value": "Parlimbatan"
  },
  {
    "id": "1220042065",
    "district_id": "122004",
    "label": "Saba Sitahul tahul",
    "value": "Saba Sitahul tahul"
  },
  {
    "id": "1220042066",
    "district_id": "122004",
    "label": "Saba Bangunan",
    "value": "Saba Bangunan"
  },
  {
    "id": "1220042067",
    "district_id": "122004",
    "label": "Sidingkat",
    "value": "Sidingkat"
  },
  {
    "id": "1220042069",
    "district_id": "122004",
    "label": "Gunung Manaon II",
    "value": "Gunung Manaon II"
  },
  {
    "id": "1220042070",
    "district_id": "122004",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1220042071",
    "district_id": "122004",
    "label": "Sungai Durian",
    "value": "Sungai Durian"
  },
  {
    "id": "1220042072",
    "district_id": "122004",
    "label": "Sungai Orosan",
    "value": "Sungai Orosan"
  },
  {
    "id": "1220042073",
    "district_id": "122004",
    "label": "Hutaimbaru II",
    "value": "Hutaimbaru II"
  },
  {
    "id": "1220042074",
    "district_id": "122004",
    "label": "Siombob",
    "value": "Siombob"
  },
  {
    "id": "1220042075",
    "district_id": "122004",
    "label": "Aek Gambir",
    "value": "Aek Gambir"
  },
  {
    "id": "1220042076",
    "district_id": "122004",
    "label": "BukitRaya Sordang",
    "value": "BukitRaya Sordang"
  },
  {
    "id": "1220042077",
    "district_id": "122004",
    "label": "Aek Jangkang",
    "value": "Aek Jangkang"
  },
  {
    "id": "1220042078",
    "district_id": "122004",
    "label": "Simbolon",
    "value": "Simbolon"
  },
  {
    "id": "1220052001",
    "district_id": "122005",
    "label": "Pancur Pangko",
    "value": "Pancur Pangko"
  },
  {
    "id": "1220052002",
    "district_id": "122005",
    "label": "Hasambi",
    "value": "Hasambi"
  },
  {
    "id": "1220052003",
    "district_id": "122005",
    "label": "Paran Nangka",
    "value": "Paran Nangka"
  },
  {
    "id": "1220052004",
    "district_id": "122005",
    "label": "Batu Rancang",
    "value": "Batu Rancang"
  },
  {
    "id": "1220052005",
    "district_id": "122005",
    "label": "Sitanggoru",
    "value": "Sitanggoru"
  },
  {
    "id": "1220052006",
    "district_id": "122005",
    "label": "Siunggam Dolok",
    "value": "Siunggam Dolok"
  },
  {
    "id": "1220052007",
    "district_id": "122005",
    "label": "Balimbing Jae",
    "value": "Balimbing Jae"
  },
  {
    "id": "1220052008",
    "district_id": "122005",
    "label": "Sipupus Lombang",
    "value": "Sipupus Lombang"
  },
  {
    "id": "1220052009",
    "district_id": "122005",
    "label": "Balimbing Julu",
    "value": "Balimbing Julu"
  },
  {
    "id": "1220052010",
    "district_id": "122005",
    "label": "Sobar",
    "value": "Sobar"
  },
  {
    "id": "1220052011",
    "district_id": "122005",
    "label": "Pamuntaran",
    "value": "Pamuntaran"
  },
  {
    "id": "1220052012",
    "district_id": "122005",
    "label": "Paran Gadung",
    "value": "Paran Gadung"
  },
  {
    "id": "1220052013",
    "district_id": "122005",
    "label": "Padang Bujur",
    "value": "Padang Bujur"
  },
  {
    "id": "1220052014",
    "district_id": "122005",
    "label": "Ubar",
    "value": "Ubar"
  },
  {
    "id": "1220052015",
    "district_id": "122005",
    "label": "Lantosan II",
    "value": "Lantosan II"
  },
  {
    "id": "1220052016",
    "district_id": "122005",
    "label": "Batu Gana",
    "value": "Batu Gana"
  },
  {
    "id": "1220052017",
    "district_id": "122005",
    "label": "Padang Baruas",
    "value": "Padang Baruas"
  },
  {
    "id": "1220052018",
    "district_id": "122005",
    "label": "Aek Bargot",
    "value": "Aek Bargot"
  },
  {
    "id": "1220052019",
    "district_id": "122005",
    "label": "Gariang",
    "value": "Gariang"
  },
  {
    "id": "1220052020",
    "district_id": "122005",
    "label": "Balakka",
    "value": "Balakka"
  },
  {
    "id": "1220052021",
    "district_id": "122005",
    "label": "Parupuk Jae",
    "value": "Parupuk Jae"
  },
  {
    "id": "1220052022",
    "district_id": "122005",
    "label": "Parupuk Julu",
    "value": "Parupuk Julu"
  },
  {
    "id": "1220052023",
    "district_id": "122005",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1220062002",
    "district_id": "122006",
    "label": "Sitopayan",
    "value": "Sitopayan"
  },
  {
    "id": "1220062003",
    "district_id": "122006",
    "label": "Lantosan I",
    "value": "Lantosan I"
  },
  {
    "id": "1220062004",
    "district_id": "122006",
    "label": "Aek Haruaya",
    "value": "Aek Haruaya"
  },
  {
    "id": "1220062005",
    "district_id": "122006",
    "label": "Gunung Baringin",
    "value": "Gunung Baringin"
  },
  {
    "id": "1220062006",
    "district_id": "122006",
    "label": "Hadungdung",
    "value": "Hadungdung"
  },
  {
    "id": "1220062007",
    "district_id": "122006",
    "label": "Rondaman Dolok",
    "value": "Rondaman Dolok"
  },
  {
    "id": "1220062008",
    "district_id": "122006",
    "label": "Sipirok",
    "value": "Sipirok"
  },
  {
    "id": "1220062009",
    "district_id": "122006",
    "label": "Tanjung Salamat",
    "value": "Tanjung Salamat"
  },
  {
    "id": "1220062010",
    "district_id": "122006",
    "label": "Rondaman Lombang",
    "value": "Rondaman Lombang"
  },
  {
    "id": "1220062011",
    "district_id": "122006",
    "label": "Parsarmaan",
    "value": "Parsarmaan"
  },
  {
    "id": "1220062012",
    "district_id": "122006",
    "label": "Aloban",
    "value": "Aloban"
  },
  {
    "id": "1220062013",
    "district_id": "122006",
    "label": "Portibi Julu",
    "value": "Portibi Julu"
  },
  {
    "id": "1220062014",
    "district_id": "122006",
    "label": "Portibi Jae",
    "value": "Portibi Jae"
  },
  {
    "id": "1220062015",
    "district_id": "122006",
    "label": "Gunung Manaon I",
    "value": "Gunung Manaon I"
  },
  {
    "id": "1220062016",
    "district_id": "122006",
    "label": "Pasir Pinang",
    "value": "Pasir Pinang"
  },
  {
    "id": "1220062017",
    "district_id": "122006",
    "label": "Bahal",
    "value": "Bahal"
  },
  {
    "id": "1220062018",
    "district_id": "122006",
    "label": "Bara",
    "value": "Bara"
  },
  {
    "id": "1220062019",
    "district_id": "122006",
    "label": "Simandiangin",
    "value": "Simandiangin"
  },
  {
    "id": "1220062020",
    "district_id": "122006",
    "label": "Hotang Sasa",
    "value": "Hotang Sasa"
  },
  {
    "id": "1220062021",
    "district_id": "122006",
    "label": "Aek Siala",
    "value": "Aek Siala"
  },
  {
    "id": "1220062022",
    "district_id": "122006",
    "label": "Gumarupu Baru",
    "value": "Gumarupu Baru"
  },
  {
    "id": "1220062023",
    "district_id": "122006",
    "label": "Padang Manjoir",
    "value": "Padang Manjoir"
  },
  {
    "id": "1220062024",
    "district_id": "122006",
    "label": "Gumarupu Lama",
    "value": "Gumarupu Lama"
  },
  {
    "id": "1220062025",
    "district_id": "122006",
    "label": "Gunung Martua",
    "value": "Gunung Martua"
  },
  {
    "id": "1220062026",
    "district_id": "122006",
    "label": "Sihambeng",
    "value": "Sihambeng"
  },
  {
    "id": "1220062027",
    "district_id": "122006",
    "label": "Aek Torop",
    "value": "Aek Torop"
  },
  {
    "id": "1220062028",
    "district_id": "122006",
    "label": "Napa Lombang",
    "value": "Napa Lombang"
  },
  {
    "id": "1220062029",
    "district_id": "122006",
    "label": "Mangaledang Lama",
    "value": "Mangaledang Lama"
  },
  {
    "id": "1220062030",
    "district_id": "122006",
    "label": "Mangaledang",
    "value": "Mangaledang"
  },
  {
    "id": "1220062031",
    "district_id": "122006",
    "label": "Bangkudu",
    "value": "Bangkudu"
  },
  {
    "id": "1220062032",
    "district_id": "122006",
    "label": "Balakka Torop",
    "value": "Balakka Torop"
  },
  {
    "id": "1220062033",
    "district_id": "122006",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1220062034",
    "district_id": "122006",
    "label": "Muara Sigama",
    "value": "Muara Sigama"
  },
  {
    "id": "1220062035",
    "district_id": "122006",
    "label": "Napahalas",
    "value": "Napahalas"
  },
  {
    "id": "1220062036",
    "district_id": "122006",
    "label": "Sigama Napahalas",
    "value": "Sigama Napahalas"
  },
  {
    "id": "1220062039",
    "district_id": "122006",
    "label": "Torluk Muara Dolok",
    "value": "Torluk Muara Dolok"
  },
  {
    "id": "1220071001",
    "district_id": "122007",
    "label": "Sayur MatinggiPe",
    "value": "Sayur MatinggiPe"
  },
  {
    "id": "1220072002",
    "district_id": "122007",
    "label": "Pangkal Dolok Lama",
    "value": "Pangkal Dolok Lama"
  },
  {
    "id": "1220072003",
    "district_id": "122007",
    "label": "Pangkal Dolok Julu",
    "value": "Pangkal Dolok Julu"
  },
  {
    "id": "1220072004",
    "district_id": "122007",
    "label": "Pintu Padang",
    "value": "Pintu Padang"
  },
  {
    "id": "1220072005",
    "district_id": "122007",
    "label": "Batang Onang Lama",
    "value": "Batang Onang Lama"
  },
  {
    "id": "1220072006",
    "district_id": "122007",
    "label": "Bonan Dolok",
    "value": "Bonan Dolok"
  },
  {
    "id": "1220072007",
    "district_id": "122007",
    "label": "Simardona",
    "value": "Simardona"
  },
  {
    "id": "1220072008",
    "district_id": "122007",
    "label": "Batang Onang Baru",
    "value": "Batang Onang Baru"
  },
  {
    "id": "1220072009",
    "district_id": "122007",
    "label": "Parau Sorat",
    "value": "Parau Sorat"
  },
  {
    "id": "1220072010",
    "district_id": "122007",
    "label": "Pasar Matanggor",
    "value": "Pasar Matanggor"
  },
  {
    "id": "1220072011",
    "district_id": "122007",
    "label": "Batu Pulut",
    "value": "Batu Pulut"
  },
  {
    "id": "1220072012",
    "district_id": "122007",
    "label": "Huta Lambung",
    "value": "Huta Lambung"
  },
  {
    "id": "1220072013",
    "district_id": "122007",
    "label": "Galanggang",
    "value": "Galanggang"
  },
  {
    "id": "1220072014",
    "district_id": "122007",
    "label": "Gunungtua Tumbu Jati",
    "value": "Gunungtua Tumbu Jati"
  },
  {
    "id": "1220072015",
    "district_id": "122007",
    "label": "Gunungtua Batang Onang",
    "value": "Gunungtua Batang Onang"
  },
  {
    "id": "1220072016",
    "district_id": "122007",
    "label": "Simanapang",
    "value": "Simanapang"
  },
  {
    "id": "1220072017",
    "district_id": "122007",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1220072018",
    "district_id": "122007",
    "label": "Batu Mamak",
    "value": "Batu Mamak"
  },
  {
    "id": "1220072019",
    "district_id": "122007",
    "label": "Padang Garugur",
    "value": "Padang Garugur"
  },
  {
    "id": "1220072020",
    "district_id": "122007",
    "label": "Padang Matinggi",
    "value": "Padang Matinggi"
  },
  {
    "id": "1220072021",
    "district_id": "122007",
    "label": "Purba Tua",
    "value": "Purba Tua"
  },
  {
    "id": "1220072022",
    "district_id": "122007",
    "label": "Janji Mauli",
    "value": "Janji Mauli"
  },
  {
    "id": "1220072023",
    "district_id": "122007",
    "label": "Sayur Matinggi Julu",
    "value": "Sayur Matinggi Julu"
  },
  {
    "id": "1220072024",
    "district_id": "122007",
    "label": "Gunung Tua Julu",
    "value": "Gunung Tua Julu"
  },
  {
    "id": "1220072025",
    "district_id": "122007",
    "label": "Pasir Ampolu Hopong",
    "value": "Pasir Ampolu Hopong"
  },
  {
    "id": "1220072026",
    "district_id": "122007",
    "label": "Pagaran Batu",
    "value": "Pagaran Batu"
  },
  {
    "id": "1220072027",
    "district_id": "122007",
    "label": "Tamosu",
    "value": "Tamosu"
  },
  {
    "id": "1220072028",
    "district_id": "122007",
    "label": "Simangambat Dolok",
    "value": "Simangambat Dolok"
  },
  {
    "id": "1220072029",
    "district_id": "122007",
    "label": "Janji Manahan",
    "value": "Janji Manahan"
  },
  {
    "id": "1220072030",
    "district_id": "122007",
    "label": "Padang Bujur Baru",
    "value": "Padang Bujur Baru"
  },
  {
    "id": "1220072031",
    "district_id": "122007",
    "label": "Batu Nanggar",
    "value": "Batu Nanggar"
  },
  {
    "id": "1220072032",
    "district_id": "122007",
    "label": "Morang",
    "value": "Morang"
  },
  {
    "id": "1220082014",
    "district_id": "122008",
    "label": "Ulak Tano",
    "value": "Ulak Tano"
  },
  {
    "id": "1220082015",
    "district_id": "122008",
    "label": "Aekraru",
    "value": "Aekraru"
  },
  {
    "id": "1220082016",
    "district_id": "122008",
    "label": "Tanjung Maria",
    "value": "Tanjung Maria"
  },
  {
    "id": "1220082017",
    "district_id": "122008",
    "label": "Mandasip",
    "value": "Mandasip"
  },
  {
    "id": "1220082018",
    "district_id": "122008",
    "label": "Huta Baru",
    "value": "Huta Baru"
  },
  {
    "id": "1220082019",
    "district_id": "122008",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1220082020",
    "district_id": "122008",
    "label": "Paran Padang",
    "value": "Paran Padang"
  },
  {
    "id": "1220082021",
    "district_id": "122008",
    "label": "Tanjung Botung",
    "value": "Tanjung Botung"
  },
  {
    "id": "1220082022",
    "district_id": "122008",
    "label": "Huta Pasir",
    "value": "Huta Pasir"
  },
  {
    "id": "1220082023",
    "district_id": "122008",
    "label": "Langkimat",
    "value": "Langkimat"
  },
  {
    "id": "1220082024",
    "district_id": "122008",
    "label": "Jabi Jabi",
    "value": "Jabi Jabi"
  },
  {
    "id": "1220082025",
    "district_id": "122008",
    "label": "Simangambat Jae",
    "value": "Simangambat Jae"
  },
  {
    "id": "1220082026",
    "district_id": "122008",
    "label": "Ujung Gading Jae",
    "value": "Ujung Gading Jae"
  },
  {
    "id": "1220082027",
    "district_id": "122008",
    "label": "Huta Baringin",
    "value": "Huta Baringin"
  },
  {
    "id": "1220082028",
    "district_id": "122008",
    "label": "Gunung Manaon Sim",
    "value": "Gunung Manaon Sim"
  },
  {
    "id": "1220082029",
    "district_id": "122008",
    "label": "Sionggoton",
    "value": "Sionggoton"
  },
  {
    "id": "1220082030",
    "district_id": "122008",
    "label": "Paran Tonga Sim",
    "value": "Paran Tonga Sim"
  },
  {
    "id": "1220082031",
    "district_id": "122008",
    "label": "Ujung Gading Julu",
    "value": "Ujung Gading Julu"
  },
  {
    "id": "1220082032",
    "district_id": "122008",
    "label": "Simangambat Julu",
    "value": "Simangambat Julu"
  },
  {
    "id": "1220082033",
    "district_id": "122008",
    "label": "Sigagan",
    "value": "Sigagan"
  },
  {
    "id": "1220082034",
    "district_id": "122008",
    "label": "Kosik Putih",
    "value": "Kosik Putih"
  },
  {
    "id": "1220092001",
    "district_id": "122009",
    "label": "Aek Godang",
    "value": "Aek Godang"
  },
  {
    "id": "1220092002",
    "district_id": "122009",
    "label": "Aek Nauli",
    "value": "Aek Nauli"
  },
  {
    "id": "1220092003",
    "district_id": "122009",
    "label": "Sampuran Simarloting",
    "value": "Sampuran Simarloting"
  },
  {
    "id": "1220092004",
    "district_id": "122009",
    "label": "Pangirkiran",
    "value": "Pangirkiran"
  },
  {
    "id": "1220092005",
    "district_id": "122009",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1220092006",
    "district_id": "122009",
    "label": "Suka Dame",
    "value": "Suka Dame"
  },
  {
    "id": "1220092007",
    "district_id": "122009",
    "label": "Pintu Bosi",
    "value": "Pintu Bosi"
  },
  {
    "id": "1220092008",
    "district_id": "122009",
    "label": "Sidong-dong",
    "value": "Sidong-dong"
  },
  {
    "id": "1220092009",
    "district_id": "122009",
    "label": "Sitabar",
    "value": "Sitabar"
  },
  {
    "id": "1220092010",
    "district_id": "122009",
    "label": "Parmeraan",
    "value": "Parmeraan"
  },
  {
    "id": "1220102001",
    "district_id": "122010",
    "label": "Siunggam Julu",
    "value": "Siunggam Julu"
  },
  {
    "id": "1220102002",
    "district_id": "122010",
    "label": "Siunggam Tonga",
    "value": "Siunggam Tonga"
  },
  {
    "id": "1220102003",
    "district_id": "122010",
    "label": "Siunggam Jae",
    "value": "Siunggam Jae"
  },
  {
    "id": "1220102004",
    "district_id": "122010",
    "label": "Aek Bayur",
    "value": "Aek Bayur"
  },
  {
    "id": "1220102005",
    "district_id": "122010",
    "label": "Aek Tolong",
    "value": "Aek Tolong"
  },
  {
    "id": "1220102006",
    "district_id": "122010",
    "label": "Tangga-Tangga Hambeng",
    "value": "Tangga-Tangga Hambeng"
  },
  {
    "id": "1220102007",
    "district_id": "122010",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1220102008",
    "district_id": "122010",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "1220102009",
    "district_id": "122010",
    "label": "Naga Saribu",
    "value": "Naga Saribu"
  },
  {
    "id": "1220102010",
    "district_id": "122010",
    "label": "Sihoda-Hoda",
    "value": "Sihoda-Hoda"
  },
  {
    "id": "1220102011",
    "district_id": "122010",
    "label": "Mompang II",
    "value": "Mompang II"
  },
  {
    "id": "1220102012",
    "district_id": "122010",
    "label": "Gulangan",
    "value": "Gulangan"
  },
  {
    "id": "1220102013",
    "district_id": "122010",
    "label": "Pijor Koling",
    "value": "Pijor Koling"
  },
  {
    "id": "1220102014",
    "district_id": "122010",
    "label": "Purba Tua Dolok",
    "value": "Purba Tua Dolok"
  },
  {
    "id": "1220112001",
    "district_id": "122011",
    "label": "Siancimun",
    "value": "Siancimun"
  },
  {
    "id": "1220112002",
    "district_id": "122011",
    "label": "Bolatan",
    "value": "Bolatan"
  },
  {
    "id": "1220112003",
    "district_id": "122011",
    "label": "Pasir Bara",
    "value": "Pasir Bara"
  },
  {
    "id": "1220112004",
    "district_id": "122011",
    "label": "Gunung Intan",
    "value": "Gunung Intan"
  },
  {
    "id": "1220112005",
    "district_id": "122011",
    "label": "Mompang I",
    "value": "Mompang I"
  },
  {
    "id": "1220112006",
    "district_id": "122011",
    "label": "Gunung Manaon III",
    "value": "Gunung Manaon III"
  },
  {
    "id": "1220112007",
    "district_id": "122011",
    "label": "Sihopuk Baru",
    "value": "Sihopuk Baru"
  },
  {
    "id": "1220112008",
    "district_id": "122011",
    "label": "Sihopuk Lama",
    "value": "Sihopuk Lama"
  },
  {
    "id": "1220112009",
    "district_id": "122011",
    "label": "Rondaman",
    "value": "Rondaman"
  },
  {
    "id": "1220112010",
    "district_id": "122011",
    "label": "Hutabaru Nangka",
    "value": "Hutabaru Nangka"
  },
  {
    "id": "1220112011",
    "district_id": "122011",
    "label": "Situmbaga",
    "value": "Situmbaga"
  },
  {
    "id": "1220112012",
    "district_id": "122011",
    "label": "Batang Pane I",
    "value": "Batang Pane I"
  },
  {
    "id": "1220112013",
    "district_id": "122011",
    "label": "Batang Pane II",
    "value": "Batang Pane II"
  },
  {
    "id": "1220112014",
    "district_id": "122011",
    "label": "Batang Pane III",
    "value": "Batang Pane III"
  },
  {
    "id": "1220122001",
    "district_id": "122012",
    "label": "Huta Raja",
    "value": "Huta Raja"
  },
  {
    "id": "1220122002",
    "district_id": "122012",
    "label": "Ujung Batu Julu",
    "value": "Ujung Batu Julu"
  },
  {
    "id": "1220122003",
    "district_id": "122012",
    "label": "Gunung Manaon UB",
    "value": "Gunung Manaon UB"
  },
  {
    "id": "1220122004",
    "district_id": "122012",
    "label": "Labuhan Jurung",
    "value": "Labuhan Jurung"
  },
  {
    "id": "1220122005",
    "district_id": "122012",
    "label": "Martujuan",
    "value": "Martujuan"
  },
  {
    "id": "1220122006",
    "district_id": "122012",
    "label": "Paya Bahung UB",
    "value": "Paya Bahung UB"
  },
  {
    "id": "1220122007",
    "district_id": "122012",
    "label": "Tobing Tinggi UB",
    "value": "Tobing Tinggi UB"
  },
  {
    "id": "1220122008",
    "district_id": "122012",
    "label": "Ujung Batu Jae",
    "value": "Ujung Batu Jae"
  },
  {
    "id": "1220122009",
    "district_id": "122012",
    "label": "Marlaung",
    "value": "Marlaung"
  },
  {
    "id": "1220122010",
    "district_id": "122012",
    "label": "Mananti",
    "value": "Mananti"
  },
  {
    "id": "1220122011",
    "district_id": "122012",
    "label": "Jambu Tonang",
    "value": "Jambu Tonang"
  },
  {
    "id": "1220122012",
    "district_id": "122012",
    "label": "Manare Tua",
    "value": "Manare Tua"
  },
  {
    "id": "1220122013",
    "district_id": "122012",
    "label": "Pasir Lancat UB",
    "value": "Pasir Lancat UB"
  },
  {
    "id": "1221012001",
    "district_id": "122101",
    "label": "Pagaranbira Jae",
    "value": "Pagaranbira Jae"
  },
  {
    "id": "1221012002",
    "district_id": "122101",
    "label": "Pagaran Bira Julu",
    "value": "Pagaran Bira Julu"
  },
  {
    "id": "1221012003",
    "district_id": "122101",
    "label": "Huta Bargot",
    "value": "Huta Bargot"
  },
  {
    "id": "1221012004",
    "district_id": "122101",
    "label": "Siundol Jae",
    "value": "Siundol Jae"
  },
  {
    "id": "1221012005",
    "district_id": "122101",
    "label": "Siundol Julu",
    "value": "Siundol Julu"
  },
  {
    "id": "1221012006",
    "district_id": "122101",
    "label": "Siundol Dolok",
    "value": "Siundol Dolok"
  },
  {
    "id": "1221012007",
    "district_id": "122101",
    "label": "Hutabaru Siundol",
    "value": "Hutabaru Siundol"
  },
  {
    "id": "1221012008",
    "district_id": "122101",
    "label": "Binanga Tolu",
    "value": "Binanga Tolu"
  },
  {
    "id": "1221012009",
    "district_id": "122101",
    "label": "Aek Bargot",
    "value": "Aek Bargot"
  },
  {
    "id": "1221012010",
    "district_id": "122101",
    "label": "Ulu Aer",
    "value": "Ulu Aer"
  },
  {
    "id": "1221012011",
    "district_id": "122101",
    "label": "Hulim",
    "value": "Hulim"
  },
  {
    "id": "1221012012",
    "district_id": "122101",
    "label": "Sianggunan",
    "value": "Sianggunan"
  },
  {
    "id": "1221012013",
    "district_id": "122101",
    "label": "Simaninggir Sosopan",
    "value": "Simaninggir Sosopan"
  },
  {
    "id": "1221012014",
    "district_id": "122101",
    "label": "Sibualbuali",
    "value": "Sibualbuali"
  },
  {
    "id": "1221012015",
    "district_id": "122101",
    "label": "Sosopan",
    "value": "Sosopan"
  },
  {
    "id": "1221012016",
    "district_id": "122101",
    "label": "Sigala-Gala",
    "value": "Sigala-Gala"
  },
  {
    "id": "1221012017",
    "district_id": "122101",
    "label": "Sihaporas",
    "value": "Sihaporas"
  },
  {
    "id": "1221012018",
    "district_id": "122101",
    "label": "Banua Tonga",
    "value": "Banua Tonga"
  },
  {
    "id": "1221012019",
    "district_id": "122101",
    "label": "Sosopan Julu",
    "value": "Sosopan Julu"
  },
  {
    "id": "1221012020",
    "district_id": "122101",
    "label": "Huta Bara",
    "value": "Huta Bara"
  },
  {
    "id": "1221012021",
    "district_id": "122101",
    "label": "Hutabaru Sosopan",
    "value": "Hutabaru Sosopan"
  },
  {
    "id": "1221012022",
    "district_id": "122101",
    "label": "Simartolu",
    "value": "Simartolu"
  },
  {
    "id": "1221022001",
    "district_id": "122102",
    "label": "Gunung Baringin",
    "value": "Gunung Baringin"
  },
  {
    "id": "1221022002",
    "district_id": "122102",
    "label": "Pasar Binanga",
    "value": "Pasar Binanga"
  },
  {
    "id": "1221022003",
    "district_id": "122102",
    "label": "Sihaborgoan Dalan",
    "value": "Sihaborgoan Dalan"
  },
  {
    "id": "1221022004",
    "district_id": "122102",
    "label": "Aek Tanduk",
    "value": "Aek Tanduk"
  },
  {
    "id": "1221022005",
    "district_id": "122102",
    "label": "PP. Makmur",
    "value": "PP. Makmur"
  },
  {
    "id": "1221022006",
    "district_id": "122102",
    "label": "Siboris Bahal",
    "value": "Siboris Bahal"
  },
  {
    "id": "1221022007",
    "district_id": "122102",
    "label": "Bangkudu",
    "value": "Bangkudu"
  },
  {
    "id": "1221022008",
    "district_id": "122102",
    "label": "Siolip",
    "value": "Siolip"
  },
  {
    "id": "1221022009",
    "district_id": "122102",
    "label": "Aek Tunjang",
    "value": "Aek Tunjang"
  },
  {
    "id": "1221022010",
    "district_id": "122102",
    "label": "Binanga",
    "value": "Binanga"
  },
  {
    "id": "1221022011",
    "district_id": "122102",
    "label": "Manombo",
    "value": "Manombo"
  },
  {
    "id": "1221022012",
    "district_id": "122102",
    "label": "Janji Raja",
    "value": "Janji Raja"
  },
  {
    "id": "1221022013",
    "district_id": "122102",
    "label": "Pangirkiran Dolok",
    "value": "Pangirkiran Dolok"
  },
  {
    "id": "1221022014",
    "district_id": "122102",
    "label": "Sihaborgoan Barumun",
    "value": "Sihaborgoan Barumun"
  },
  {
    "id": "1221022015",
    "district_id": "122102",
    "label": "Siboris Dolok",
    "value": "Siboris Dolok"
  },
  {
    "id": "1221022016",
    "district_id": "122102",
    "label": "Padang Matinggi",
    "value": "Padang Matinggi"
  },
  {
    "id": "1221022017",
    "district_id": "122102",
    "label": "Tandihat",
    "value": "Tandihat"
  },
  {
    "id": "1221022018",
    "district_id": "122102",
    "label": "Bara Batu",
    "value": "Bara Batu"
  },
  {
    "id": "1221022019",
    "district_id": "122102",
    "label": "Bahal Batu",
    "value": "Bahal Batu"
  },
  {
    "id": "1221022020",
    "district_id": "122102",
    "label": "Ginduang Batu",
    "value": "Ginduang Batu"
  },
  {
    "id": "1221022021",
    "district_id": "122102",
    "label": "Padang Garugur",
    "value": "Padang Garugur"
  },
  {
    "id": "1221022022",
    "district_id": "122102",
    "label": "Gunung Manaon",
    "value": "Gunung Manaon"
  },
  {
    "id": "1221022023",
    "district_id": "122102",
    "label": "Siboris Lombang",
    "value": "Siboris Lombang"
  },
  {
    "id": "1221022024",
    "district_id": "122102",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1221022025",
    "district_id": "122102",
    "label": "Gunung Malintang",
    "value": "Gunung Malintang"
  },
  {
    "id": "1221022026",
    "district_id": "122102",
    "label": "Sibatu Loting",
    "value": "Sibatu Loting"
  },
  {
    "id": "1221022027",
    "district_id": "122102",
    "label": "Sisalean",
    "value": "Sisalean"
  },
  {
    "id": "1221022028",
    "district_id": "122102",
    "label": "Janji Manahan",
    "value": "Janji Manahan"
  },
  {
    "id": "1221022029",
    "district_id": "122102",
    "label": "Siparau",
    "value": "Siparau"
  },
  {
    "id": "1221022030",
    "district_id": "122102",
    "label": "Unterudang",
    "value": "Unterudang"
  },
  {
    "id": "1221022053",
    "district_id": "122102",
    "label": "Huta Ruhom",
    "value": "Huta Ruhom"
  },
  {
    "id": "1221022056",
    "district_id": "122102",
    "label": "Paran Napa Dolok",
    "value": "Paran Napa Dolok"
  },
  {
    "id": "1221022061",
    "district_id": "122102",
    "label": "Bire",
    "value": "Bire"
  },
  {
    "id": "1221022065",
    "district_id": "122102",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "1221022067",
    "district_id": "122102",
    "label": "Paran Napa Jae",
    "value": "Paran Napa Jae"
  },
  {
    "id": "1221022068",
    "district_id": "122102",
    "label": "Sibontar",
    "value": "Sibontar"
  },
  {
    "id": "1221022070",
    "district_id": "122102",
    "label": "Aek Siala",
    "value": "Aek Siala"
  },
  {
    "id": "1221022071",
    "district_id": "122102",
    "label": "Batusundung",
    "value": "Batusundung"
  },
  {
    "id": "1221022072",
    "district_id": "122102",
    "label": "Sidongdong",
    "value": "Sidongdong"
  },
  {
    "id": "1221032001",
    "district_id": "122103",
    "label": "Binanga Tolu",
    "value": "Binanga Tolu"
  },
  {
    "id": "1221032002",
    "district_id": "122103",
    "label": "Tanjung Baringin",
    "value": "Tanjung Baringin"
  },
  {
    "id": "1221032003",
    "district_id": "122103",
    "label": "Huristak",
    "value": "Huristak"
  },
  {
    "id": "1221032004",
    "district_id": "122103",
    "label": "Paran Tonga",
    "value": "Paran Tonga"
  },
  {
    "id": "1221032005",
    "district_id": "122103",
    "label": "Gonting Julu",
    "value": "Gonting Julu"
  },
  {
    "id": "1221032006",
    "district_id": "122103",
    "label": "Bulu Cina",
    "value": "Bulu Cina"
  },
  {
    "id": "1221032007",
    "district_id": "122103",
    "label": "Sigading",
    "value": "Sigading"
  },
  {
    "id": "1221032008",
    "district_id": "122103",
    "label": "Pasir Lancat Lama",
    "value": "Pasir Lancat Lama"
  },
  {
    "id": "1221032009",
    "district_id": "122103",
    "label": "Siala Gundi",
    "value": "Siala Gundi"
  },
  {
    "id": "1221032010",
    "district_id": "122103",
    "label": "Tobing Julu",
    "value": "Tobing Julu"
  },
  {
    "id": "1221032011",
    "district_id": "122103",
    "label": "Tar Sihoda-Hoda",
    "value": "Tar Sihoda-Hoda"
  },
  {
    "id": "1221032012",
    "district_id": "122103",
    "label": "Huta Pasir Ulak Tano",
    "value": "Huta Pasir Ulak Tano"
  },
  {
    "id": "1221032013",
    "district_id": "122103",
    "label": "Tobing Jae",
    "value": "Tobing Jae"
  },
  {
    "id": "1221032014",
    "district_id": "122103",
    "label": "Pasar Huristak",
    "value": "Pasar Huristak"
  },
  {
    "id": "1221032015",
    "district_id": "122103",
    "label": "Sipirok Baru",
    "value": "Sipirok Baru"
  },
  {
    "id": "1221032016",
    "district_id": "122103",
    "label": "Pasir Lancat Baru",
    "value": "Pasir Lancat Baru"
  },
  {
    "id": "1221032017",
    "district_id": "122103",
    "label": "Tobing Tinggi",
    "value": "Tobing Tinggi"
  },
  {
    "id": "1221032018",
    "district_id": "122103",
    "label": "Ganal",
    "value": "Ganal"
  },
  {
    "id": "1221032019",
    "district_id": "122103",
    "label": "Paya Bujing",
    "value": "Paya Bujing"
  },
  {
    "id": "1221032020",
    "district_id": "122103",
    "label": "Pulo Bariang",
    "value": "Pulo Bariang"
  },
  {
    "id": "1221032021",
    "district_id": "122103",
    "label": "Ramba",
    "value": "Ramba"
  },
  {
    "id": "1221032022",
    "district_id": "122103",
    "label": "Gonting Jae",
    "value": "Gonting Jae"
  },
  {
    "id": "1221032023",
    "district_id": "122103",
    "label": "Gala Bonang",
    "value": "Gala Bonang"
  },
  {
    "id": "1221032024",
    "district_id": "122103",
    "label": "Tanjung Morang",
    "value": "Tanjung Morang"
  },
  {
    "id": "1221032025",
    "district_id": "122103",
    "label": "Gunung Manaon",
    "value": "Gunung Manaon"
  },
  {
    "id": "1221032026",
    "district_id": "122103",
    "label": "Gunung Matinggi",
    "value": "Gunung Matinggi"
  },
  {
    "id": "1221032027",
    "district_id": "122103",
    "label": "Pasir Pinang",
    "value": "Pasir Pinang"
  },
  {
    "id": "1221042001",
    "district_id": "122104",
    "label": "Janji Matogu",
    "value": "Janji Matogu"
  },
  {
    "id": "1221042002",
    "district_id": "122104",
    "label": "Pagaran Silindung",
    "value": "Pagaran Silindung"
  },
  {
    "id": "1221042003",
    "district_id": "122104",
    "label": "Tangga Bosi",
    "value": "Tangga Bosi"
  },
  {
    "id": "1221042004",
    "district_id": "122104",
    "label": "Hutaibus",
    "value": "Hutaibus"
  },
  {
    "id": "1221042005",
    "district_id": "122104",
    "label": "Pagaran Jalu-Jalu",
    "value": "Pagaran Jalu-Jalu"
  },
  {
    "id": "1221042006",
    "district_id": "122104",
    "label": "Hutanopan",
    "value": "Hutanopan"
  },
  {
    "id": "1221042007",
    "district_id": "122104",
    "label": "Pasar Latong",
    "value": "Pasar Latong"
  },
  {
    "id": "1221042008",
    "district_id": "122104",
    "label": "Pagaran Jae Batu",
    "value": "Pagaran Jae Batu"
  },
  {
    "id": "1221042009",
    "district_id": "122104",
    "label": "Aek Lancat",
    "value": "Aek Lancat"
  },
  {
    "id": "1221042010",
    "district_id": "122104",
    "label": "Batang Tanggal Baru",
    "value": "Batang Tanggal Baru"
  },
  {
    "id": "1221042011",
    "district_id": "122104",
    "label": "Bonal",
    "value": "Bonal"
  },
  {
    "id": "1221042012",
    "district_id": "122104",
    "label": "Pagaran Malaka",
    "value": "Pagaran Malaka"
  },
  {
    "id": "1221042013",
    "district_id": "122104",
    "label": "Huta Dolok",
    "value": "Huta Dolok"
  },
  {
    "id": "1221042014",
    "district_id": "122104",
    "label": "Gunung Manobot",
    "value": "Gunung Manobot"
  },
  {
    "id": "1221042015",
    "district_id": "122104",
    "label": "Batang Bulu Jae",
    "value": "Batang Bulu Jae"
  },
  {
    "id": "1221042016",
    "district_id": "122104",
    "label": "Suro Dingin",
    "value": "Suro Dingin"
  },
  {
    "id": "1221042017",
    "district_id": "122104",
    "label": "Janji Lobi Lima",
    "value": "Janji Lobi Lima"
  },
  {
    "id": "1221042018",
    "district_id": "122104",
    "label": "Sangkilon",
    "value": "Sangkilon"
  },
  {
    "id": "1221042019",
    "district_id": "122104",
    "label": "Siali-Ali",
    "value": "Siali-Ali"
  },
  {
    "id": "1221042020",
    "district_id": "122104",
    "label": "Huta Lombang",
    "value": "Huta Lombang"
  },
  {
    "id": "1221042021",
    "district_id": "122104",
    "label": "Pagaran Mompang",
    "value": "Pagaran Mompang"
  },
  {
    "id": "1221042022",
    "district_id": "122104",
    "label": "Parsombaan",
    "value": "Parsombaan"
  },
  {
    "id": "1221042023",
    "district_id": "122104",
    "label": "Sihiuk",
    "value": "Sihiuk"
  },
  {
    "id": "1221042024",
    "district_id": "122104",
    "label": "Batang Bulu Tanggal",
    "value": "Batang Bulu Tanggal"
  },
  {
    "id": "1221052001",
    "district_id": "122105",
    "label": "Simangambat",
    "value": "Simangambat"
  },
  {
    "id": "1221052002",
    "district_id": "122105",
    "label": "Sigala-Gala",
    "value": "Sigala-Gala"
  },
  {
    "id": "1221052003",
    "district_id": "122105",
    "label": "Tanjung Ale",
    "value": "Tanjung Ale"
  },
  {
    "id": "1221052004",
    "district_id": "122105",
    "label": "Lubuk Bunut",
    "value": "Lubuk Bunut"
  },
  {
    "id": "1221052005",
    "district_id": "122105",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1221052006",
    "district_id": "122105",
    "label": "Tanjung Baringin",
    "value": "Tanjung Baringin"
  },
  {
    "id": "1221052007",
    "district_id": "122105",
    "label": "Sibodak Sosa Jae",
    "value": "Sibodak Sosa Jae"
  },
  {
    "id": "1221052008",
    "district_id": "122105",
    "label": "Parmainan",
    "value": "Parmainan"
  },
  {
    "id": "1221052009",
    "district_id": "122105",
    "label": "Pagaran Dolok Sosa Jae",
    "value": "Pagaran Dolok Sosa Jae"
  },
  {
    "id": "1221052010",
    "district_id": "122105",
    "label": "Aliaga",
    "value": "Aliaga"
  },
  {
    "id": "1221052011",
    "district_id": "122105",
    "label": "Mananti Sosa Jae",
    "value": "Mananti Sosa Jae"
  },
  {
    "id": "1221052012",
    "district_id": "122105",
    "label": "Panyabungan",
    "value": "Panyabungan"
  },
  {
    "id": "1221052013",
    "district_id": "122105",
    "label": "Pasar Panyabungan",
    "value": "Pasar Panyabungan"
  },
  {
    "id": "1221052014",
    "district_id": "122105",
    "label": "Sigalapung",
    "value": "Sigalapung"
  },
  {
    "id": "1221052015",
    "district_id": "122105",
    "label": "Siabu",
    "value": "Siabu"
  },
  {
    "id": "1221052016",
    "district_id": "122105",
    "label": "Paya Ombur",
    "value": "Paya Ombur"
  },
  {
    "id": "1221052017",
    "district_id": "122105",
    "label": "Hutaraja Tinggi",
    "value": "Hutaraja Tinggi"
  },
  {
    "id": "1221052018",
    "district_id": "122105",
    "label": "Sungai Korang",
    "value": "Sungai Korang"
  },
  {
    "id": "1221052019",
    "district_id": "122105",
    "label": "Ujung Batu I",
    "value": "Ujung Batu I"
  },
  {
    "id": "1221052020",
    "district_id": "122105",
    "label": "Ujung Batu II",
    "value": "Ujung Batu II"
  },
  {
    "id": "1221052021",
    "district_id": "122105",
    "label": "Ujung Batu III",
    "value": "Ujung Batu III"
  },
  {
    "id": "1221052022",
    "district_id": "122105",
    "label": "Ujung Batu IV",
    "value": "Ujung Batu IV"
  },
  {
    "id": "1221052023",
    "district_id": "122105",
    "label": "Ujung Batu V",
    "value": "Ujung Batu V"
  },
  {
    "id": "1221052024",
    "district_id": "122105",
    "label": "Pir Trans Sosa I A",
    "value": "Pir Trans Sosa I A"
  },
  {
    "id": "1221052025",
    "district_id": "122105",
    "label": "Pir Trans Sosa I B",
    "value": "Pir Trans Sosa I B"
  },
  {
    "id": "1221052026",
    "district_id": "122105",
    "label": "Pir Trans Sosa III A",
    "value": "Pir Trans Sosa III A"
  },
  {
    "id": "1221052027",
    "district_id": "122105",
    "label": "Pir Trans Sosa III B",
    "value": "Pir Trans Sosa III B"
  },
  {
    "id": "1221052028",
    "district_id": "122105",
    "label": "Pir Trans Sosa II",
    "value": "Pir Trans Sosa II"
  },
  {
    "id": "1221052029",
    "district_id": "122105",
    "label": "Pir Trans Sosa IV",
    "value": "Pir Trans Sosa IV"
  },
  {
    "id": "1221052030",
    "district_id": "122105",
    "label": "Pir Trans Sosa V",
    "value": "Pir Trans Sosa V"
  },
  {
    "id": "1221052031",
    "district_id": "122105",
    "label": "Pir Trans Sosa VI",
    "value": "Pir Trans Sosa VI"
  },
  {
    "id": "1221062001",
    "district_id": "122106",
    "label": "Simanuldang Jae",
    "value": "Simanuldang Jae"
  },
  {
    "id": "1221062002",
    "district_id": "122106",
    "label": "Simanuldang Julu",
    "value": "Simanuldang Julu"
  },
  {
    "id": "1221062003",
    "district_id": "122106",
    "label": "Tapian Nauli",
    "value": "Tapian Nauli"
  },
  {
    "id": "1221062004",
    "district_id": "122106",
    "label": "Handang Kopo",
    "value": "Handang Kopo"
  },
  {
    "id": "1221062005",
    "district_id": "122106",
    "label": "Subulussalam",
    "value": "Subulussalam"
  },
  {
    "id": "1221062006",
    "district_id": "122106",
    "label": "Pintu Padang",
    "value": "Pintu Padang"
  },
  {
    "id": "1221062007",
    "district_id": "122106",
    "label": "Matondang",
    "value": "Matondang"
  },
  {
    "id": "1221062008",
    "district_id": "122106",
    "label": "Pasar Ipuh",
    "value": "Pasar Ipuh"
  },
  {
    "id": "1221062009",
    "district_id": "122106",
    "label": "Paringgonan",
    "value": "Paringgonan"
  },
  {
    "id": "1221062010",
    "district_id": "122106",
    "label": "Paringgonan Julu",
    "value": "Paringgonan Julu"
  },
  {
    "id": "1221062011",
    "district_id": "122106",
    "label": "Paran Batu",
    "value": "Paran Batu"
  },
  {
    "id": "1221062012",
    "district_id": "122106",
    "label": "Sibual Buali",
    "value": "Sibual Buali"
  },
  {
    "id": "1221062013",
    "district_id": "122106",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1221062014",
    "district_id": "122106",
    "label": "Siraisan",
    "value": "Siraisan"
  },
  {
    "id": "1221062015",
    "district_id": "122106",
    "label": "Aek Haruaya",
    "value": "Aek Haruaya"
  },
  {
    "id": "1221071001",
    "district_id": "122107",
    "label": "Pasar Sibuhuan",
    "value": "Pasar Sibuhuan"
  },
  {
    "id": "1221072002",
    "district_id": "122107",
    "label": "Sibuhuan Julu",
    "value": "Sibuhuan Julu"
  },
  {
    "id": "1221072003",
    "district_id": "122107",
    "label": "Bangun Raya",
    "value": "Bangun Raya"
  },
  {
    "id": "1221072004",
    "district_id": "122107",
    "label": "PurbaTua",
    "value": "PurbaTua"
  },
  {
    "id": "1221072005",
    "district_id": "122107",
    "label": "Handis Julu",
    "value": "Handis Julu"
  },
  {
    "id": "1221072006",
    "district_id": "122107",
    "label": "Sialambue",
    "value": "Sialambue"
  },
  {
    "id": "1221072007",
    "district_id": "122107",
    "label": "Sibuhuan Jae",
    "value": "Sibuhuan Jae"
  },
  {
    "id": "1221072008",
    "district_id": "122107",
    "label": "Janjilobi",
    "value": "Janjilobi"
  },
  {
    "id": "1221072009",
    "district_id": "122107",
    "label": "Hasahatan Jae",
    "value": "Hasahatan Jae"
  },
  {
    "id": "1221072010",
    "district_id": "122107",
    "label": "Siolip",
    "value": "Siolip"
  },
  {
    "id": "1221072011",
    "district_id": "122107",
    "label": "Saba Rimba",
    "value": "Saba Rimba"
  },
  {
    "id": "1221072012",
    "district_id": "122107",
    "label": "Binabo Julu",
    "value": "Binabo Julu"
  },
  {
    "id": "1221072013",
    "district_id": "122107",
    "label": "Binabo Jae",
    "value": "Binabo Jae"
  },
  {
    "id": "1221072014",
    "district_id": "122107",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "1221072015",
    "district_id": "122107",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1221072016",
    "district_id": "122107",
    "label": "Hasahatan Julu",
    "value": "Hasahatan Julu"
  },
  {
    "id": "1221072017",
    "district_id": "122107",
    "label": "Sitarolo Julu",
    "value": "Sitarolo Julu"
  },
  {
    "id": "1221072018",
    "district_id": "122107",
    "label": "Mompang",
    "value": "Mompang"
  },
  {
    "id": "1221072019",
    "district_id": "122107",
    "label": "Sigorbus Jae",
    "value": "Sigorbus Jae"
  },
  {
    "id": "1221072020",
    "district_id": "122107",
    "label": "Sabahotang",
    "value": "Sabahotang"
  },
  {
    "id": "1221072021",
    "district_id": "122107",
    "label": "Tanjung Durian",
    "value": "Tanjung Durian"
  },
  {
    "id": "1221072022",
    "district_id": "122107",
    "label": "Tanjung Botung",
    "value": "Tanjung Botung"
  },
  {
    "id": "1221072023",
    "district_id": "122107",
    "label": "Tanobato",
    "value": "Tanobato"
  },
  {
    "id": "1221072024",
    "district_id": "122107",
    "label": "Hutarimbaru",
    "value": "Hutarimbaru"
  },
  {
    "id": "1221072025",
    "district_id": "122107",
    "label": "Sayur Matua",
    "value": "Sayur Matua"
  },
  {
    "id": "1221072037",
    "district_id": "122107",
    "label": "Bulusonik",
    "value": "Bulusonik"
  },
  {
    "id": "1221072038",
    "district_id": "122107",
    "label": "Arsesimatorkis",
    "value": "Arsesimatorkis"
  },
  {
    "id": "1221072039",
    "district_id": "122107",
    "label": "Pancaukan",
    "value": "Pancaukan"
  },
  {
    "id": "1221072040",
    "district_id": "122107",
    "label": "Pagaran Baringin",
    "value": "Pagaran Baringin"
  },
  {
    "id": "1221072041",
    "district_id": "122107",
    "label": "Sigorbus Julu",
    "value": "Sigorbus Julu"
  },
  {
    "id": "1221082001",
    "district_id": "122108",
    "label": "Siborna Bunut",
    "value": "Siborna Bunut"
  },
  {
    "id": "1221082002",
    "district_id": "122108",
    "label": "Hurung Jilok",
    "value": "Hurung Jilok"
  },
  {
    "id": "1221082003",
    "district_id": "122108",
    "label": "Pasir Jae",
    "value": "Pasir Jae"
  },
  {
    "id": "1221082004",
    "district_id": "122108",
    "label": "Harang Julu",
    "value": "Harang Julu"
  },
  {
    "id": "1221082005",
    "district_id": "122108",
    "label": "Parapat",
    "value": "Parapat"
  },
  {
    "id": "1221082006",
    "district_id": "122108",
    "label": "Pasar Ujung Batu",
    "value": "Pasar Ujung Batu"
  },
  {
    "id": "1221082007",
    "district_id": "122108",
    "label": "Hapung",
    "value": "Hapung"
  },
  {
    "id": "1221082008",
    "district_id": "122108",
    "label": "Plasma Mondang",
    "value": "Plasma Mondang"
  },
  {
    "id": "1221082009",
    "district_id": "122108",
    "label": "Mandian",
    "value": "Mandian"
  },
  {
    "id": "1221082010",
    "district_id": "122108",
    "label": "Bonan Dolok",
    "value": "Bonan Dolok"
  },
  {
    "id": "1221082011",
    "district_id": "122108",
    "label": "Hutaimbaru",
    "value": "Hutaimbaru"
  },
  {
    "id": "1221082012",
    "district_id": "122108",
    "label": "Mondang",
    "value": "Mondang"
  },
  {
    "id": "1221082013",
    "district_id": "122108",
    "label": "Sisoma",
    "value": "Sisoma"
  },
  {
    "id": "1221082014",
    "district_id": "122108",
    "label": "Aek Tinga",
    "value": "Aek Tinga"
  },
  {
    "id": "1221082015",
    "district_id": "122108",
    "label": "Gunung Baringin",
    "value": "Gunung Baringin"
  },
  {
    "id": "1221082016",
    "district_id": "122108",
    "label": "Simarancar",
    "value": "Simarancar"
  },
  {
    "id": "1221082017",
    "district_id": "122108",
    "label": "Huta Raja Lamo",
    "value": "Huta Raja Lamo"
  },
  {
    "id": "1221082018",
    "district_id": "122108",
    "label": "Tanjung Botung Sosa Jae",
    "value": "Tanjung Botung Sosa Jae"
  },
  {
    "id": "1221082019",
    "district_id": "122108",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "1221082020",
    "district_id": "122108",
    "label": "Janjiraja",
    "value": "Janjiraja"
  },
  {
    "id": "1221082021",
    "district_id": "122108",
    "label": "Rao-Rao Dolok",
    "value": "Rao-Rao Dolok"
  },
  {
    "id": "1221082022",
    "district_id": "122108",
    "label": "Parau Sorat",
    "value": "Parau Sorat"
  },
  {
    "id": "1221082023",
    "district_id": "122108",
    "label": "Aer Bale",
    "value": "Aer Bale"
  },
  {
    "id": "1221082024",
    "district_id": "122108",
    "label": "Tanjung Bale",
    "value": "Tanjung Bale"
  },
  {
    "id": "1221082025",
    "district_id": "122108",
    "label": "Ampolu",
    "value": "Ampolu"
  },
  {
    "id": "1221082026",
    "district_id": "122108",
    "label": "Mananti Sosa Julu",
    "value": "Mananti Sosa Julu"
  },
  {
    "id": "1221082027",
    "district_id": "122108",
    "label": "Siginduang",
    "value": "Siginduang"
  },
  {
    "id": "1221082028",
    "district_id": "122108",
    "label": "Ramba",
    "value": "Ramba"
  },
  {
    "id": "1221082029",
    "district_id": "122108",
    "label": "Roburan",
    "value": "Roburan"
  },
  {
    "id": "1221082030",
    "district_id": "122108",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1221082031",
    "district_id": "122108",
    "label": "Sungai Jior",
    "value": "Sungai Jior"
  },
  {
    "id": "1221082032",
    "district_id": "122108",
    "label": "Lumban Huayan",
    "value": "Lumban Huayan"
  },
  {
    "id": "1221082033",
    "district_id": "122108",
    "label": "Pasir Julu",
    "value": "Pasir Julu"
  },
  {
    "id": "1221082034",
    "district_id": "122108",
    "label": "Hapung Torop",
    "value": "Hapung Torop"
  },
  {
    "id": "1221082035",
    "district_id": "122108",
    "label": "Batu Gajah",
    "value": "Batu Gajah"
  },
  {
    "id": "1221082036",
    "district_id": "122108",
    "label": "Harang Jae",
    "value": "Harang Jae"
  },
  {
    "id": "1221082037",
    "district_id": "122108",
    "label": "Horuon",
    "value": "Horuon"
  },
  {
    "id": "1221082038",
    "district_id": "122108",
    "label": "Gunung Tua",
    "value": "Gunung Tua"
  },
  {
    "id": "1221082039",
    "district_id": "122108",
    "label": "Handio",
    "value": "Handio"
  },
  {
    "id": "1221092001",
    "district_id": "122109",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1221092002",
    "district_id": "122109",
    "label": "Tamiang",
    "value": "Tamiang"
  },
  {
    "id": "1221092003",
    "district_id": "122109",
    "label": "Tanjung Barani",
    "value": "Tanjung Barani"
  },
  {
    "id": "1221092004",
    "district_id": "122109",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "1221092005",
    "district_id": "122109",
    "label": "Pagaran Tayas",
    "value": "Pagaran Tayas"
  },
  {
    "id": "1221092006",
    "district_id": "122109",
    "label": "Hatongga",
    "value": "Hatongga"
  },
  {
    "id": "1221092007",
    "district_id": "122109",
    "label": "Botung",
    "value": "Botung"
  },
  {
    "id": "1221092008",
    "district_id": "122109",
    "label": "Siadam",
    "value": "Siadam"
  },
  {
    "id": "1221092009",
    "district_id": "122109",
    "label": "Siojo",
    "value": "Siojo"
  },
  {
    "id": "1221092010",
    "district_id": "122109",
    "label": "Pagaran Manggis",
    "value": "Pagaran Manggis"
  },
  {
    "id": "1221092011",
    "district_id": "122109",
    "label": "Muara Malinto Lama",
    "value": "Muara Malinto Lama"
  },
  {
    "id": "1221092012",
    "district_id": "122109",
    "label": "Hutabaru",
    "value": "Hutabaru"
  },
  {
    "id": "1221092013",
    "district_id": "122109",
    "label": "Huta Nopan",
    "value": "Huta Nopan"
  },
  {
    "id": "1221092014",
    "district_id": "122109",
    "label": "Tandolan",
    "value": "Tandolan"
  },
  {
    "id": "1221092015",
    "district_id": "122109",
    "label": "Tangga Batu",
    "value": "Tangga Batu"
  },
  {
    "id": "1221092016",
    "district_id": "122109",
    "label": "Pagaran Dolok Pinarik",
    "value": "Pagaran Dolok Pinarik"
  },
  {
    "id": "1221092017",
    "district_id": "122109",
    "label": "Muara Malinto Baru",
    "value": "Muara Malinto Baru"
  },
  {
    "id": "1221092018",
    "district_id": "122109",
    "label": "Aek Sorik",
    "value": "Aek Sorik"
  },
  {
    "id": "1221092019",
    "district_id": "122109",
    "label": "Tanjung Botung Pinarik",
    "value": "Tanjung Botung Pinarik"
  },
  {
    "id": "1221092020",
    "district_id": "122109",
    "label": "Pinarik",
    "value": "Pinarik"
  },
  {
    "id": "1221092021",
    "district_id": "122109",
    "label": "Rombayan",
    "value": "Rombayan"
  },
  {
    "id": "1221092022",
    "district_id": "122109",
    "label": "Salambue",
    "value": "Salambue"
  },
  {
    "id": "1221092023",
    "district_id": "122109",
    "label": "Papaso",
    "value": "Papaso"
  },
  {
    "id": "1221092024",
    "district_id": "122109",
    "label": "Muara Tige",
    "value": "Muara Tige"
  },
  {
    "id": "1221092025",
    "district_id": "122109",
    "label": "Sibodak Papaso",
    "value": "Sibodak Papaso"
  },
  {
    "id": "1221092026",
    "district_id": "122109",
    "label": "Pagaran Baringin",
    "value": "Pagaran Baringin"
  },
  {
    "id": "1221092027",
    "district_id": "122109",
    "label": "Gunung Inten",
    "value": "Gunung Inten"
  },
  {
    "id": "1221092028",
    "district_id": "122109",
    "label": "Gunung Manaon",
    "value": "Gunung Manaon"
  },
  {
    "id": "1221102001",
    "district_id": "122110",
    "label": "Batang Bulu Lama",
    "value": "Batang Bulu Lama"
  },
  {
    "id": "1221102002",
    "district_id": "122110",
    "label": "Pagur Satio",
    "value": "Pagur Satio"
  },
  {
    "id": "1221102003",
    "district_id": "122110",
    "label": "Batang Bulu Baru",
    "value": "Batang Bulu Baru"
  },
  {
    "id": "1221102004",
    "district_id": "122110",
    "label": "Gunung Intan",
    "value": "Gunung Intan"
  },
  {
    "id": "1221102005",
    "district_id": "122110",
    "label": "Tanjung Purba Tua",
    "value": "Tanjung Purba Tua"
  },
  {
    "id": "1221102006",
    "district_id": "122110",
    "label": "Banua Tonga",
    "value": "Banua Tonga"
  },
  {
    "id": "1221102007",
    "district_id": "122110",
    "label": "Gunung Barani",
    "value": "Gunung Barani"
  },
  {
    "id": "1221102008",
    "district_id": "122110",
    "label": "Tanjung Baringin Simarulak",
    "value": "Tanjung Baringin Simarulak"
  },
  {
    "id": "1221102009",
    "district_id": "122110",
    "label": "Sayur Mahincat",
    "value": "Sayur Mahincat"
  },
  {
    "id": "1221102010",
    "district_id": "122110",
    "label": "Sidomulio",
    "value": "Sidomulio"
  },
  {
    "id": "1221102011",
    "district_id": "122110",
    "label": "Panarian",
    "value": "Panarian"
  },
  {
    "id": "1221112001",
    "district_id": "122111",
    "label": "Aek Nabara Tonga",
    "value": "Aek Nabara Tonga"
  },
  {
    "id": "1221112002",
    "district_id": "122111",
    "label": "Aek Nabara Jae",
    "value": "Aek Nabara Jae"
  },
  {
    "id": "1221112003",
    "district_id": "122111",
    "label": "Padang Garugur Jae",
    "value": "Padang Garugur Jae"
  },
  {
    "id": "1221112004",
    "district_id": "122111",
    "label": "Huta Bargot",
    "value": "Huta Bargot"
  },
  {
    "id": "1221112005",
    "district_id": "122111",
    "label": "Janji Maria",
    "value": "Janji Maria"
  },
  {
    "id": "1221112006",
    "district_id": "122111",
    "label": "Padang Garugur Julu",
    "value": "Padang Garugur Julu"
  },
  {
    "id": "1221112007",
    "district_id": "122111",
    "label": "Sidokan",
    "value": "Sidokan"
  },
  {
    "id": "1221112008",
    "district_id": "122111",
    "label": "Tobing",
    "value": "Tobing"
  },
  {
    "id": "1221112009",
    "district_id": "122111",
    "label": "Hadungdung Aek Rampah",
    "value": "Hadungdung Aek Rampah"
  },
  {
    "id": "1221112010",
    "district_id": "122111",
    "label": "Tobing Tinggi",
    "value": "Tobing Tinggi"
  },
  {
    "id": "1221112011",
    "district_id": "122111",
    "label": "Sipagabu",
    "value": "Sipagabu"
  },
  {
    "id": "1221112012",
    "district_id": "122111",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1221112013",
    "district_id": "122111",
    "label": "Paran Tonga",
    "value": "Paran Tonga"
  },
  {
    "id": "1221112014",
    "district_id": "122111",
    "label": "Paran Julu",
    "value": "Paran Julu"
  },
  {
    "id": "1221112015",
    "district_id": "122111",
    "label": "Hadungdung Pintu Padang",
    "value": "Hadungdung Pintu Padang"
  },
  {
    "id": "1221112016",
    "district_id": "122111",
    "label": "Tanjung Rokan",
    "value": "Tanjung Rokan"
  },
  {
    "id": "1221112017",
    "district_id": "122111",
    "label": "Aek Bonban",
    "value": "Aek Bonban"
  },
  {
    "id": "1221112018",
    "district_id": "122111",
    "label": "Marenu",
    "value": "Marenu"
  },
  {
    "id": "1221112019",
    "district_id": "122111",
    "label": "Aek Nabara Julu",
    "value": "Aek Nabara Julu"
  },
  {
    "id": "1221112020",
    "district_id": "122111",
    "label": "Padang Garugur Tonga",
    "value": "Padang Garugur Tonga"
  },
  {
    "id": "1221112021",
    "district_id": "122111",
    "label": "Bangkuang",
    "value": "Bangkuang"
  },
  {
    "id": "1221112022",
    "district_id": "122111",
    "label": "Paya bahung",
    "value": "Paya bahung"
  },
  {
    "id": "1221112023",
    "district_id": "122111",
    "label": "Aek Buaton",
    "value": "Aek Buaton"
  },
  {
    "id": "1221112024",
    "district_id": "122111",
    "label": "Sayur Matua",
    "value": "Sayur Matua"
  },
  {
    "id": "1221112025",
    "district_id": "122111",
    "label": "Sayur Mahincat",
    "value": "Sayur Mahincat"
  },
  {
    "id": "1221122001",
    "district_id": "122112",
    "label": "Lubuk Gonting",
    "value": "Lubuk Gonting"
  },
  {
    "id": "1221122002",
    "district_id": "122112",
    "label": "Padang Hasior Dolok",
    "value": "Padang Hasior Dolok"
  },
  {
    "id": "1221122003",
    "district_id": "122112",
    "label": "Padang Hasior Lombang",
    "value": "Padang Hasior Lombang"
  },
  {
    "id": "1221122004",
    "district_id": "122112",
    "label": "Sitada-Tada",
    "value": "Sitada-Tada"
  },
  {
    "id": "1221122005",
    "district_id": "122112",
    "label": "Paran Dolok",
    "value": "Paran Dolok"
  },
  {
    "id": "1221122006",
    "district_id": "122112",
    "label": "Ujung Gading",
    "value": "Ujung Gading"
  },
  {
    "id": "1221122007",
    "district_id": "122112",
    "label": "Simaninggir",
    "value": "Simaninggir"
  },
  {
    "id": "1221122008",
    "district_id": "122112",
    "label": "Tanjung Morang",
    "value": "Tanjung Morang"
  },
  {
    "id": "1221122009",
    "district_id": "122112",
    "label": "Silenjeng",
    "value": "Silenjeng"
  },
  {
    "id": "1221122010",
    "district_id": "122112",
    "label": "Aek Goti",
    "value": "Aek Goti"
  },
  {
    "id": "1221122011",
    "district_id": "122112",
    "label": "Gulangan",
    "value": "Gulangan"
  },
  {
    "id": "1221122012",
    "district_id": "122112",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1221122013",
    "district_id": "122112",
    "label": "Balangka",
    "value": "Balangka"
  },
  {
    "id": "1222011001",
    "district_id": "122201",
    "label": "Kotapinang",
    "value": "Kotapinang"
  },
  {
    "id": "1222012002",
    "district_id": "122201",
    "label": "Simatahari",
    "value": "Simatahari"
  },
  {
    "id": "1222012003",
    "district_id": "122201",
    "label": "Mampang",
    "value": "Mampang"
  },
  {
    "id": "1222012004",
    "district_id": "122201",
    "label": "Pasir Tuntung",
    "value": "Pasir Tuntung"
  },
  {
    "id": "1222012005",
    "district_id": "122201",
    "label": "Sisumut",
    "value": "Sisumut"
  },
  {
    "id": "1222012006",
    "district_id": "122201",
    "label": "Hadundung",
    "value": "Hadundung"
  },
  {
    "id": "1222012007",
    "district_id": "122201",
    "label": "Sosopan",
    "value": "Sosopan"
  },
  {
    "id": "1222012008",
    "district_id": "122201",
    "label": "Perkebunan Nomark",
    "value": "Perkebunan Nomark"
  },
  {
    "id": "1222012009",
    "district_id": "122201",
    "label": "Perkebunan Nagodang",
    "value": "Perkebunan Nagodang"
  },
  {
    "id": "1222012010",
    "district_id": "122201",
    "label": "Perk. Sei Rumbia",
    "value": "Perk. Sei Rumbia"
  },
  {
    "id": "1222022001",
    "district_id": "122202",
    "label": "Perk. Perlabian",
    "value": "Perk. Perlabian"
  },
  {
    "id": "1222022002",
    "district_id": "122202",
    "label": "Perk. Teluk Panji",
    "value": "Perk. Teluk Panji"
  },
  {
    "id": "1222022003",
    "district_id": "122202",
    "label": "Perk. Tolan I/II",
    "value": "Perk. Tolan I/II"
  },
  {
    "id": "1222022004",
    "district_id": "122202",
    "label": "Perk. Batang Saponggol",
    "value": "Perk. Batang Saponggol"
  },
  {
    "id": "1222022005",
    "district_id": "122202",
    "label": "Perk. Tolan",
    "value": "Perk. Tolan"
  },
  {
    "id": "1222022006",
    "district_id": "122202",
    "label": "Air Merah",
    "value": "Air Merah"
  },
  {
    "id": "1222022007",
    "district_id": "122202",
    "label": "Teluk Panji",
    "value": "Teluk Panji"
  },
  {
    "id": "1222022008",
    "district_id": "122202",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1222022009",
    "district_id": "122202",
    "label": "Kampung Perlabian",
    "value": "Kampung Perlabian"
  },
  {
    "id": "1222022010",
    "district_id": "122202",
    "label": "Tanjung Selamat",
    "value": "Tanjung Selamat"
  },
  {
    "id": "1222022011",
    "district_id": "122202",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1222022012",
    "district_id": "122202",
    "label": "Teluk Panji I",
    "value": "Teluk Panji I"
  },
  {
    "id": "1222022013",
    "district_id": "122202",
    "label": "Teluk Panji II",
    "value": "Teluk Panji II"
  },
  {
    "id": "1222022014",
    "district_id": "122202",
    "label": "Teluk Panji III",
    "value": "Teluk Panji III"
  },
  {
    "id": "1222022015",
    "district_id": "122202",
    "label": "Teluk Panji IV",
    "value": "Teluk Panji IV"
  },
  {
    "id": "1222032001",
    "district_id": "122203",
    "label": "Bangai",
    "value": "Bangai"
  },
  {
    "id": "1222032002",
    "district_id": "122203",
    "label": "Rasau",
    "value": "Rasau"
  },
  {
    "id": "1222032003",
    "district_id": "122203",
    "label": "Teluk Rampah",
    "value": "Teluk Rampah"
  },
  {
    "id": "1222032004",
    "district_id": "122203",
    "label": "Pangarungan",
    "value": "Pangarungan"
  },
  {
    "id": "1222032005",
    "district_id": "122203",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "1222032006",
    "district_id": "122203",
    "label": "Aek Batu",
    "value": "Aek Batu"
  },
  {
    "id": "1222032007",
    "district_id": "122203",
    "label": "Pinang Damai",
    "value": "Pinang Damai"
  },
  {
    "id": "1222032008",
    "district_id": "122203",
    "label": "Aek Raso",
    "value": "Aek Raso"
  },
  {
    "id": "1222032009",
    "district_id": "122203",
    "label": "Torgamba",
    "value": "Torgamba"
  },
  {
    "id": "1222032010",
    "district_id": "122203",
    "label": "Asam Jawa",
    "value": "Asam Jawa"
  },
  {
    "id": "1222032011",
    "district_id": "122203",
    "label": "Torganda",
    "value": "Torganda"
  },
  {
    "id": "1222032012",
    "district_id": "122203",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1222032013",
    "district_id": "122203",
    "label": "Bukit Tujuh",
    "value": "Bukit Tujuh"
  },
  {
    "id": "1222032014",
    "district_id": "122203",
    "label": "Sei Meranti",
    "value": "Sei Meranti"
  },
  {
    "id": "1222041001",
    "district_id": "122204",
    "label": "Langga Payung",
    "value": "Langga Payung"
  },
  {
    "id": "1222042002",
    "district_id": "122204",
    "label": "Batang Nadenggan",
    "value": "Batang Nadenggan"
  },
  {
    "id": "1222042003",
    "district_id": "122204",
    "label": "Sabungan",
    "value": "Sabungan"
  },
  {
    "id": "1222042004",
    "district_id": "122204",
    "label": "Hajoran",
    "value": "Hajoran"
  },
  {
    "id": "1222042005",
    "district_id": "122204",
    "label": "Ujung Gading",
    "value": "Ujung Gading"
  },
  {
    "id": "1222042006",
    "district_id": "122204",
    "label": "Huta Godang",
    "value": "Huta Godang"
  },
  {
    "id": "1222042007",
    "district_id": "122204",
    "label": "Parimburan",
    "value": "Parimburan"
  },
  {
    "id": "1222042008",
    "district_id": "122204",
    "label": "Sampean",
    "value": "Sampean"
  },
  {
    "id": "1222042009",
    "district_id": "122204",
    "label": "Marsonja",
    "value": "Marsonja"
  },
  {
    "id": "1222052001",
    "district_id": "122205",
    "label": "Mandalasena",
    "value": "Mandalasena"
  },
  {
    "id": "1222052002",
    "district_id": "122205",
    "label": "Binangadua",
    "value": "Binangadua"
  },
  {
    "id": "1222052003",
    "district_id": "122205",
    "label": "Aek Goti",
    "value": "Aek Goti"
  },
  {
    "id": "1222052004",
    "district_id": "122205",
    "label": "Ulu Mahuam",
    "value": "Ulu Mahuam"
  },
  {
    "id": "1222052005",
    "district_id": "122205",
    "label": "Rintis",
    "value": "Rintis"
  },
  {
    "id": "1222052006",
    "district_id": "122205",
    "label": "Sukadame",
    "value": "Sukadame"
  },
  {
    "id": "1223011001",
    "district_id": "122301",
    "label": "Aek Kanopan",
    "value": "Aek Kanopan"
  },
  {
    "id": "1223011002",
    "district_id": "122301",
    "label": "Aek Kanopan Timur",
    "value": "Aek Kanopan Timur"
  },
  {
    "id": "1223012003",
    "district_id": "122301",
    "label": "Kuala Beringin",
    "value": "Kuala Beringin"
  },
  {
    "id": "1223012004",
    "district_id": "122301",
    "label": "Parpaudangan",
    "value": "Parpaudangan"
  },
  {
    "id": "1223012005",
    "district_id": "122301",
    "label": "Pulo Dogom",
    "value": "Pulo Dogom"
  },
  {
    "id": "1223012006",
    "district_id": "122301",
    "label": "Londut",
    "value": "Londut"
  },
  {
    "id": "1223012007",
    "district_id": "122301",
    "label": "Perkebunan Kanopan Ulu",
    "value": "Perkebunan Kanopan Ulu"
  },
  {
    "id": "1223012008",
    "district_id": "122301",
    "label": "Perkebunan Membang Muda",
    "value": "Perkebunan Membang Muda"
  },
  {
    "id": "1223012009",
    "district_id": "122301",
    "label": "Perkebunan Labuhan Haji",
    "value": "Perkebunan Labuhan Haji"
  },
  {
    "id": "1223012010",
    "district_id": "122301",
    "label": "Perkebunan Hanna",
    "value": "Perkebunan Hanna"
  },
  {
    "id": "1223012011",
    "district_id": "122301",
    "label": "Sonomartani",
    "value": "Sonomartani"
  },
  {
    "id": "1223012012",
    "district_id": "122301",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1223012013",
    "district_id": "122301",
    "label": "Sukarame Baru",
    "value": "Sukarame Baru"
  },
  {
    "id": "1223021001",
    "district_id": "122302",
    "label": "Tanjung Leidong",
    "value": "Tanjung Leidong"
  },
  {
    "id": "1223022002",
    "district_id": "122302",
    "label": "Teluk Pulai Dalam",
    "value": "Teluk Pulai Dalam"
  },
  {
    "id": "1223022003",
    "district_id": "122302",
    "label": "Teluk Pulai Luar",
    "value": "Teluk Pulai Luar"
  },
  {
    "id": "1223022004",
    "district_id": "122302",
    "label": "Kelapa Sebatang",
    "value": "Kelapa Sebatang"
  },
  {
    "id": "1223022005",
    "district_id": "122302",
    "label": "Simandulang",
    "value": "Simandulang"
  },
  {
    "id": "1223022006",
    "district_id": "122302",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1223022007",
    "district_id": "122302",
    "label": "Pangkalan Lunang",
    "value": "Pangkalan Lunang"
  },
  {
    "id": "1223031001",
    "district_id": "122303",
    "label": "Kampung Mesjid",
    "value": "Kampung Mesjid"
  },
  {
    "id": "1223032002",
    "district_id": "122303",
    "label": "Kuala Bangka",
    "value": "Kuala Bangka"
  },
  {
    "id": "1223032003",
    "district_id": "122303",
    "label": "Sei Sentang",
    "value": "Sei Sentang"
  },
  {
    "id": "1223032004",
    "district_id": "122303",
    "label": "Teluk Piai",
    "value": "Teluk Piai"
  },
  {
    "id": "1223032005",
    "district_id": "122303",
    "label": "Tanjung Mangedar",
    "value": "Tanjung Mangedar"
  },
  {
    "id": "1223032006",
    "district_id": "122303",
    "label": "Teluk Binjai",
    "value": "Teluk Binjai"
  },
  {
    "id": "1223032007",
    "district_id": "122303",
    "label": "Sei Apung",
    "value": "Sei Apung"
  },
  {
    "id": "1223042001",
    "district_id": "122304",
    "label": "Aek Korsik",
    "value": "Aek Korsik"
  },
  {
    "id": "1223042002",
    "district_id": "122304",
    "label": "Bandar Selamat",
    "value": "Bandar Selamat"
  },
  {
    "id": "1223042003",
    "district_id": "122304",
    "label": "Perk. Padang Halaban",
    "value": "Perk. Padang Halaban"
  },
  {
    "id": "1223042004",
    "district_id": "122304",
    "label": "Perkebunan Panigoran",
    "value": "Perkebunan Panigoran"
  },
  {
    "id": "1223042005",
    "district_id": "122304",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1223042006",
    "district_id": "122304",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1223042007",
    "district_id": "122304",
    "label": "Padang Maninjau",
    "value": "Padang Maninjau"
  },
  {
    "id": "1223042008",
    "district_id": "122304",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1223051001",
    "district_id": "122305",
    "label": "Marbau",
    "value": "Marbau"
  },
  {
    "id": "1223052002",
    "district_id": "122305",
    "label": "Perkebunan Pernantian",
    "value": "Perkebunan Pernantian"
  },
  {
    "id": "1223052003",
    "district_id": "122305",
    "label": "Perk. Marbau Selatan",
    "value": "Perk. Marbau Selatan"
  },
  {
    "id": "1223052004",
    "district_id": "122305",
    "label": "Perkebunan Milano",
    "value": "Perkebunan Milano"
  },
  {
    "id": "1223052005",
    "district_id": "122305",
    "label": "Perkebunan Brussel",
    "value": "Perkebunan Brussel"
  },
  {
    "id": "1223052006",
    "district_id": "122305",
    "label": "Pulo Bargot",
    "value": "Pulo Bargot"
  },
  {
    "id": "1223052007",
    "district_id": "122305",
    "label": "Sipare Pare Tengah",
    "value": "Sipare Pare Tengah"
  },
  {
    "id": "1223052008",
    "district_id": "122305",
    "label": "Sipare Pare Hilir",
    "value": "Sipare Pare Hilir"
  },
  {
    "id": "1223052009",
    "district_id": "122305",
    "label": "Tubiran",
    "value": "Tubiran"
  },
  {
    "id": "1223052010",
    "district_id": "122305",
    "label": "Belongkut",
    "value": "Belongkut"
  },
  {
    "id": "1223052011",
    "district_id": "122305",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1223052012",
    "district_id": "122305",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "1223052013",
    "district_id": "122305",
    "label": "Marbau Selatan",
    "value": "Marbau Selatan"
  },
  {
    "id": "1223052014",
    "district_id": "122305",
    "label": "Aek Tapa",
    "value": "Aek Tapa"
  },
  {
    "id": "1223052015",
    "district_id": "122305",
    "label": "Lobu Rampah",
    "value": "Lobu Rampah"
  },
  {
    "id": "1223052016",
    "district_id": "122305",
    "label": "Bulungihit",
    "value": "Bulungihit"
  },
  {
    "id": "1223052017",
    "district_id": "122305",
    "label": "Aek Hitetoras",
    "value": "Aek Hitetoras"
  },
  {
    "id": "1223052018",
    "district_id": "122305",
    "label": "Sumber Mulyo",
    "value": "Sumber Mulyo"
  },
  {
    "id": "1223061001",
    "district_id": "122306",
    "label": "Aek Kota Batu",
    "value": "Aek Kota Batu"
  },
  {
    "id": "1223062002",
    "district_id": "122306",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "1223062003",
    "district_id": "122306",
    "label": "Batu Tunggal",
    "value": "Batu Tunggal"
  },
  {
    "id": "1223062004",
    "district_id": "122306",
    "label": "Sungai Raja",
    "value": "Sungai Raja"
  },
  {
    "id": "1223062005",
    "district_id": "122306",
    "label": "Perkebunan Berangir",
    "value": "Perkebunan Berangir"
  },
  {
    "id": "1223062006",
    "district_id": "122306",
    "label": "Silumajang",
    "value": "Silumajang"
  },
  {
    "id": "1223062007",
    "district_id": "122306",
    "label": "Pulo Jantan",
    "value": "Pulo Jantan"
  },
  {
    "id": "1223062008",
    "district_id": "122306",
    "label": "Kampung Pajak",
    "value": "Kampung Pajak"
  },
  {
    "id": "1223062009",
    "district_id": "122306",
    "label": "Meranti Omas",
    "value": "Meranti Omas"
  },
  {
    "id": "1223062010",
    "district_id": "122306",
    "label": "Hatapang",
    "value": "Hatapang"
  },
  {
    "id": "1223062011",
    "district_id": "122306",
    "label": "Pasang Lela",
    "value": "Pasang Lela"
  },
  {
    "id": "1223062012",
    "district_id": "122306",
    "label": "Simpang Marbau",
    "value": "Simpang Marbau"
  },
  {
    "id": "1223062013",
    "district_id": "122306",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1223071001",
    "district_id": "122307",
    "label": "Bandar Durian",
    "value": "Bandar Durian"
  },
  {
    "id": "1223072002",
    "district_id": "122307",
    "label": "Poldung",
    "value": "Poldung"
  },
  {
    "id": "1223072003",
    "district_id": "122307",
    "label": "Rombisan",
    "value": "Rombisan"
  },
  {
    "id": "1223072004",
    "district_id": "122307",
    "label": "Sibito",
    "value": "Sibito"
  },
  {
    "id": "1223072005",
    "district_id": "122307",
    "label": "Simonis",
    "value": "Simonis"
  },
  {
    "id": "1223072006",
    "district_id": "122307",
    "label": "Perk. Aek Pamienke",
    "value": "Perk. Aek Pamienke"
  },
  {
    "id": "1223072007",
    "district_id": "122307",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1223072008",
    "district_id": "122307",
    "label": "Adian Torop",
    "value": "Adian Torop"
  },
  {
    "id": "1223072009",
    "district_id": "122307",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1223072010",
    "district_id": "122307",
    "label": "Kampung Yaman",
    "value": "Kampung Yaman"
  },
  {
    "id": "1223072011",
    "district_id": "122307",
    "label": "Terang Bulan",
    "value": "Terang Bulan"
  },
  {
    "id": "1223072012",
    "district_id": "122307",
    "label": "Perkebunan Halimbe",
    "value": "Perkebunan Halimbe"
  },
  {
    "id": "1223081001",
    "district_id": "122308",
    "label": "Gunting Saga",
    "value": "Gunting Saga"
  },
  {
    "id": "1223082002",
    "district_id": "122308",
    "label": "Damuli Pekan",
    "value": "Damuli Pekan"
  },
  {
    "id": "1223082003",
    "district_id": "122308",
    "label": "Siamporik",
    "value": "Siamporik"
  },
  {
    "id": "1223082004",
    "district_id": "122308",
    "label": "Lobu Huala",
    "value": "Lobu Huala"
  },
  {
    "id": "1223082005",
    "district_id": "122308",
    "label": "Gunung Melayu",
    "value": "Gunung Melayu"
  },
  {
    "id": "1223082006",
    "district_id": "122308",
    "label": "Damuli Kebun",
    "value": "Damuli Kebun"
  },
  {
    "id": "1223082007",
    "district_id": "122308",
    "label": "Hasang",
    "value": "Hasang"
  },
  {
    "id": "1223082008",
    "district_id": "122308",
    "label": "Bandar Lama",
    "value": "Bandar Lama"
  },
  {
    "id": "1223082009",
    "district_id": "122308",
    "label": "Sidua Dua",
    "value": "Sidua Dua"
  },
  {
    "id": "1223082010",
    "district_id": "122308",
    "label": "Simangalam",
    "value": "Simangalam"
  },
  {
    "id": "1223082011",
    "district_id": "122308",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "1223082012",
    "district_id": "122308",
    "label": "Sialang Taji",
    "value": "Sialang Taji"
  },
  {
    "id": "1224012001",
    "district_id": "122401",
    "label": "Hilidundra",
    "value": "Hilidundra"
  },
  {
    "id": "1224012002",
    "district_id": "122401",
    "label": "Lawira Satua",
    "value": "Lawira Satua"
  },
  {
    "id": "1224012003",
    "district_id": "122401",
    "label": "Fadoro Fulolo",
    "value": "Fadoro Fulolo"
  },
  {
    "id": "1224012004",
    "district_id": "122401",
    "label": "Lawira I",
    "value": "Lawira I"
  },
  {
    "id": "1224012005",
    "district_id": "122401",
    "label": "Hiligodu",
    "value": "Hiligodu"
  },
  {
    "id": "1224012006",
    "district_id": "122401",
    "label": "Lombuza'ua",
    "value": "Lombuza'ua"
  },
  {
    "id": "1224012007",
    "district_id": "122401",
    "label": "Maziaya",
    "value": "Maziaya"
  },
  {
    "id": "1224012008",
    "district_id": "122401",
    "label": "Baho",
    "value": "Baho"
  },
  {
    "id": "1224012009",
    "district_id": "122401",
    "label": "Lolofaoso",
    "value": "Lolofaoso"
  },
  {
    "id": "1224012010",
    "district_id": "122401",
    "label": "Lawira II",
    "value": "Lawira II"
  },
  {
    "id": "1224012011",
    "district_id": "122401",
    "label": "Dahadano",
    "value": "Dahadano"
  },
  {
    "id": "1224012012",
    "district_id": "122401",
    "label": "Lolomboli",
    "value": "Lolomboli"
  },
  {
    "id": "1224012013",
    "district_id": "122401",
    "label": "Hiligeo Afia",
    "value": "Hiligeo Afia"
  },
  {
    "id": "1224022001",
    "district_id": "122402",
    "label": "Seriwa'u",
    "value": "Seriwa'u"
  },
  {
    "id": "1224022002",
    "district_id": "122402",
    "label": "Ombolata Sawo",
    "value": "Ombolata Sawo"
  },
  {
    "id": "1224022003",
    "district_id": "122402",
    "label": "Sanawuyu",
    "value": "Sanawuyu"
  },
  {
    "id": "1224022004",
    "district_id": "122402",
    "label": "Onozitoli Sawo",
    "value": "Onozitoli Sawo"
  },
  {
    "id": "1224022005",
    "district_id": "122402",
    "label": "Sifahandro",
    "value": "Sifahandro"
  },
  {
    "id": "1224022006",
    "district_id": "122402",
    "label": "Sisarahili Teluk Siabang",
    "value": "Sisarahili Teluk Siabang"
  },
  {
    "id": "1224022007",
    "district_id": "122402",
    "label": "Lasara Sawo",
    "value": "Lasara Sawo"
  },
  {
    "id": "1224022008",
    "district_id": "122402",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "1224022009",
    "district_id": "122402",
    "label": "Hiliduruwa",
    "value": "Hiliduruwa"
  },
  {
    "id": "1224022010",
    "district_id": "122402",
    "label": "Teluk Bengkuang",
    "value": "Teluk Bengkuang"
  },
  {
    "id": "1224032001",
    "district_id": "122403",
    "label": "Botolakha",
    "value": "Botolakha"
  },
  {
    "id": "1224032002",
    "district_id": "122403",
    "label": "Siofa Banua",
    "value": "Siofa Banua"
  },
  {
    "id": "1224032003",
    "district_id": "122403",
    "label": "La'aya",
    "value": "La'aya"
  },
  {
    "id": "1224032004",
    "district_id": "122403",
    "label": "Alo'oa",
    "value": "Alo'oa"
  },
  {
    "id": "1224032005",
    "district_id": "122403",
    "label": "Ladara",
    "value": "Ladara"
  },
  {
    "id": "1224032006",
    "district_id": "122403",
    "label": "Banua Gea",
    "value": "Banua Gea"
  },
  {
    "id": "1224032007",
    "district_id": "122403",
    "label": "Silima Banua",
    "value": "Silima Banua"
  },
  {
    "id": "1224032008",
    "district_id": "122403",
    "label": "Fino",
    "value": "Fino"
  },
  {
    "id": "1224042001",
    "district_id": "122404",
    "label": "Tetehosi Maziaya",
    "value": "Tetehosi Maziaya"
  },
  {
    "id": "1224042002",
    "district_id": "122404",
    "label": "Umbubalodano",
    "value": "Umbubalodano"
  },
  {
    "id": "1224042003",
    "district_id": "122404",
    "label": "Hilimbosi",
    "value": "Hilimbosi"
  },
  {
    "id": "1224042004",
    "district_id": "122404",
    "label": "Hilisalo'o",
    "value": "Hilisalo'o"
  },
  {
    "id": "1224042005",
    "district_id": "122404",
    "label": "Fulolo Salo'o",
    "value": "Fulolo Salo'o"
  },
  {
    "id": "1224042006",
    "district_id": "122404",
    "label": "Botombawo",
    "value": "Botombawo"
  },
  {
    "id": "1224052001",
    "district_id": "122405",
    "label": "Berua",
    "value": "Berua"
  },
  {
    "id": "1224052002",
    "district_id": "122405",
    "label": "Esiwa",
    "value": "Esiwa"
  },
  {
    "id": "1224052003",
    "district_id": "122405",
    "label": "Dahana Hiligodu",
    "value": "Dahana Hiligodu"
  },
  {
    "id": "1224052004",
    "district_id": "122405",
    "label": "Hilibanua",
    "value": "Hilibanua"
  },
  {
    "id": "1224052005",
    "district_id": "122405",
    "label": "Sisarahili",
    "value": "Sisarahili"
  },
  {
    "id": "1224052006",
    "district_id": "122405",
    "label": "Sisobahili",
    "value": "Sisobahili"
  },
  {
    "id": "1224052007",
    "district_id": "122405",
    "label": "Lasara",
    "value": "Lasara"
  },
  {
    "id": "1224052008",
    "district_id": "122405",
    "label": "Namohalu",
    "value": "Namohalu"
  },
  {
    "id": "1224052009",
    "district_id": "122405",
    "label": "Tuhenakhe I",
    "value": "Tuhenakhe I"
  },
  {
    "id": "1224052010",
    "district_id": "122405",
    "label": "Orahili",
    "value": "Orahili"
  },
  {
    "id": "1224052011",
    "district_id": "122405",
    "label": "Banua Sibohou",
    "value": "Banua Sibohou"
  },
  {
    "id": "1224062001",
    "district_id": "122406",
    "label": "Banua Sibohou III",
    "value": "Banua Sibohou III"
  },
  {
    "id": "1224062002",
    "district_id": "122406",
    "label": "Harefanaese",
    "value": "Harefanaese"
  },
  {
    "id": "1224062003",
    "district_id": "122406",
    "label": "Hilimbowo Kare",
    "value": "Hilimbowo Kare"
  },
  {
    "id": "1224062004",
    "district_id": "122406",
    "label": "Laehuwa",
    "value": "Laehuwa"
  },
  {
    "id": "1224062005",
    "district_id": "122406",
    "label": "Mazingo",
    "value": "Mazingo"
  },
  {
    "id": "1224062006",
    "district_id": "122406",
    "label": "Hilina'a",
    "value": "Hilina'a"
  },
  {
    "id": "1224072001",
    "district_id": "122407",
    "label": "Bitaya",
    "value": "Bitaya"
  },
  {
    "id": "1224072002",
    "district_id": "122407",
    "label": "Lahemboho",
    "value": "Lahemboho"
  },
  {
    "id": "1224072003",
    "district_id": "122407",
    "label": "Anaoma",
    "value": "Anaoma"
  },
  {
    "id": "1224072004",
    "district_id": "122407",
    "label": "Dahana Tugala Oyo",
    "value": "Dahana Tugala Oyo"
  },
  {
    "id": "1224072005",
    "district_id": "122407",
    "label": "Fulolo",
    "value": "Fulolo"
  },
  {
    "id": "1224072006",
    "district_id": "122407",
    "label": "Hiligawoni",
    "value": "Hiligawoni"
  },
  {
    "id": "1224072007",
    "district_id": "122407",
    "label": "Ononamolo Tumula",
    "value": "Ononamolo Tumula"
  },
  {
    "id": "1224072008",
    "district_id": "122407",
    "label": "Banua Sibohou I",
    "value": "Banua Sibohou I"
  },
  {
    "id": "1224072009",
    "district_id": "122407",
    "label": "Ombolata",
    "value": "Ombolata"
  },
  {
    "id": "1224072010",
    "district_id": "122407",
    "label": "Banua Sibohou II",
    "value": "Banua Sibohou II"
  },
  {
    "id": "1224072011",
    "district_id": "122407",
    "label": "Dahana Alasa",
    "value": "Dahana Alasa"
  },
  {
    "id": "1224072012",
    "district_id": "122407",
    "label": "Ononamolo Alasa",
    "value": "Ononamolo Alasa"
  },
  {
    "id": "1224072013",
    "district_id": "122407",
    "label": "Loloana'a",
    "value": "Loloana'a"
  },
  {
    "id": "1224072014",
    "district_id": "122407",
    "label": "Hilisebua Siwalubanua",
    "value": "Hilisebua Siwalubanua"
  },
  {
    "id": "1224082001",
    "district_id": "122408",
    "label": "Te'olo",
    "value": "Te'olo"
  },
  {
    "id": "1224082002",
    "district_id": "122408",
    "label": "Siwawo",
    "value": "Siwawo"
  },
  {
    "id": "1224082003",
    "district_id": "122408",
    "label": "Fabaliwa Oyo",
    "value": "Fabaliwa Oyo"
  },
  {
    "id": "1224082004",
    "district_id": "122408",
    "label": "Ononazara",
    "value": "Ononazara"
  },
  {
    "id": "1224082005",
    "district_id": "122408",
    "label": "Humene Siheneasi",
    "value": "Humene Siheneasi"
  },
  {
    "id": "1224082006",
    "district_id": "122408",
    "label": "Gunung Tua",
    "value": "Gunung Tua"
  },
  {
    "id": "1224082007",
    "district_id": "122408",
    "label": "Botona'ai",
    "value": "Botona'ai"
  },
  {
    "id": "1224082008",
    "district_id": "122408",
    "label": "Harefa",
    "value": "Harefa"
  },
  {
    "id": "1224092001",
    "district_id": "122409",
    "label": "Afulu",
    "value": "Afulu"
  },
  {
    "id": "1224092002",
    "district_id": "122409",
    "label": "Lauru Fadoro",
    "value": "Lauru Fadoro"
  },
  {
    "id": "1224092003",
    "district_id": "122409",
    "label": "Lauru I",
    "value": "Lauru I"
  },
  {
    "id": "1224092004",
    "district_id": "122409",
    "label": "Ombolata Afulu",
    "value": "Ombolata Afulu"
  },
  {
    "id": "1224092005",
    "district_id": "122409",
    "label": "Harewakhe",
    "value": "Harewakhe"
  },
  {
    "id": "1224092006",
    "district_id": "122409",
    "label": "Sisobahili",
    "value": "Sisobahili"
  },
  {
    "id": "1224092007",
    "district_id": "122409",
    "label": "Lauru Lahewa",
    "value": "Lauru Lahewa"
  },
  {
    "id": "1224092008",
    "district_id": "122409",
    "label": "Sifaoro'asi",
    "value": "Sifaoro'asi"
  },
  {
    "id": "1224092009",
    "district_id": "122409",
    "label": "Faekhuna'a",
    "value": "Faekhuna'a"
  },
  {
    "id": "1224101001",
    "district_id": "122410",
    "label": "Pasar Lahewa",
    "value": "Pasar Lahewa"
  },
  {
    "id": "1224102002",
    "district_id": "122410",
    "label": "Iraono Lase",
    "value": "Iraono Lase"
  },
  {
    "id": "1224102003",
    "district_id": "122410",
    "label": "Holi",
    "value": "Holi"
  },
  {
    "id": "1224102004",
    "district_id": "122410",
    "label": "Sifaoro'asi",
    "value": "Sifaoro'asi"
  },
  {
    "id": "1224102005",
    "district_id": "122410",
    "label": "Hilizukhu",
    "value": "Hilizukhu"
  },
  {
    "id": "1224102006",
    "district_id": "122410",
    "label": "Onozalukhu",
    "value": "Onozalukhu"
  },
  {
    "id": "1224102007",
    "district_id": "122410",
    "label": "Hilina'a",
    "value": "Hilina'a"
  },
  {
    "id": "1224102008",
    "district_id": "122410",
    "label": "Hiligodu Hoya",
    "value": "Hiligodu Hoya"
  },
  {
    "id": "1224102009",
    "district_id": "122410",
    "label": "Sitolubanua",
    "value": "Sitolubanua"
  },
  {
    "id": "1224102010",
    "district_id": "122410",
    "label": "Hilihati",
    "value": "Hilihati"
  },
  {
    "id": "1224102011",
    "district_id": "122410",
    "label": "Fadoro Sitolu Hili",
    "value": "Fadoro Sitolu Hili"
  },
  {
    "id": "1224102012",
    "district_id": "122410",
    "label": "Marafala",
    "value": "Marafala"
  },
  {
    "id": "1224102013",
    "district_id": "122410",
    "label": "Hiligawolo",
    "value": "Hiligawolo"
  },
  {
    "id": "1224102014",
    "district_id": "122410",
    "label": "Balefadoro Tuho",
    "value": "Balefadoro Tuho"
  },
  {
    "id": "1224102015",
    "district_id": "122410",
    "label": "Ombolata",
    "value": "Ombolata"
  },
  {
    "id": "1224102016",
    "district_id": "122410",
    "label": "Afia",
    "value": "Afia"
  },
  {
    "id": "1224102017",
    "district_id": "122410",
    "label": "Moawo",
    "value": "Moawo"
  },
  {
    "id": "1224102018",
    "district_id": "122410",
    "label": "Lasara",
    "value": "Lasara"
  },
  {
    "id": "1224102019",
    "district_id": "122410",
    "label": "Sihene'asi",
    "value": "Sihene'asi"
  },
  {
    "id": "1224102020",
    "district_id": "122410",
    "label": "Fadoro Hilimbowo",
    "value": "Fadoro Hilimbowo"
  },
  {
    "id": "1224102021",
    "district_id": "122410",
    "label": "Fadoro Hilihambawa",
    "value": "Fadoro Hilihambawa"
  },
  {
    "id": "1224112001",
    "district_id": "122411",
    "label": "Laowowaga",
    "value": "Laowowaga"
  },
  {
    "id": "1224112002",
    "district_id": "122411",
    "label": "Lukhu Lase",
    "value": "Lukhu Lase"
  },
  {
    "id": "1224112003",
    "district_id": "122411",
    "label": "Tetehosi Sorowi",
    "value": "Tetehosi Sorowi"
  },
  {
    "id": "1224112004",
    "district_id": "122411",
    "label": "Tefa'o",
    "value": "Tefa'o"
  },
  {
    "id": "1224112005",
    "district_id": "122411",
    "label": "Tugala Lauru",
    "value": "Tugala Lauru"
  },
  {
    "id": "1224112006",
    "district_id": "122411",
    "label": "Muzoi",
    "value": "Muzoi"
  },
  {
    "id": "1224112007",
    "district_id": "122411",
    "label": "Meafu",
    "value": "Meafu"
  },
  {
    "id": "1225012001",
    "district_id": "122501",
    "label": "Sisobaoho",
    "value": "Sisobaoho"
  },
  {
    "id": "1225012002",
    "district_id": "122501",
    "label": "Bawozamaiwo",
    "value": "Bawozamaiwo"
  },
  {
    "id": "1225012003",
    "district_id": "122501",
    "label": "Sitolubanua",
    "value": "Sitolubanua"
  },
  {
    "id": "1225012004",
    "district_id": "122501",
    "label": "Lologundre",
    "value": "Lologundre"
  },
  {
    "id": "1225012005",
    "district_id": "122501",
    "label": "Iraonogaila",
    "value": "Iraonogaila"
  },
  {
    "id": "1225012006",
    "district_id": "122501",
    "label": "Tigaserangkai",
    "value": "Tigaserangkai"
  },
  {
    "id": "1225012007",
    "district_id": "122501",
    "label": "Onowaembo",
    "value": "Onowaembo"
  },
  {
    "id": "1225012008",
    "district_id": "122501",
    "label": "Onolimbu",
    "value": "Onolimbu"
  },
  {
    "id": "1225012009",
    "district_id": "122501",
    "label": "Haliadulo",
    "value": "Haliadulo"
  },
  {
    "id": "1225012010",
    "district_id": "122501",
    "label": "Sisobambowo",
    "value": "Sisobambowo"
  },
  {
    "id": "1225012011",
    "district_id": "122501",
    "label": "Lolowau",
    "value": "Lolowau"
  },
  {
    "id": "1225022001",
    "district_id": "122502",
    "label": "Bawosaloo",
    "value": "Bawosaloo"
  },
  {
    "id": "1225022002",
    "district_id": "122502",
    "label": "Imana",
    "value": "Imana"
  },
  {
    "id": "1225022003",
    "district_id": "122502",
    "label": "Tuwa Tuwa",
    "value": "Tuwa Tuwa"
  },
  {
    "id": "1225022004",
    "district_id": "122502",
    "label": "Kafo Kafo",
    "value": "Kafo Kafo"
  },
  {
    "id": "1225022005",
    "district_id": "122502",
    "label": "Bawasawa",
    "value": "Bawasawa"
  },
  {
    "id": "1225022006",
    "district_id": "122502",
    "label": "Pulau Bogi",
    "value": "Pulau Bogi"
  },
  {
    "id": "1225022007",
    "district_id": "122502",
    "label": "Halamona",
    "value": "Halamona"
  },
  {
    "id": "1225022008",
    "district_id": "122502",
    "label": "Hanofa",
    "value": "Hanofa"
  },
  {
    "id": "1225022009",
    "district_id": "122502",
    "label": "Lahawa",
    "value": "Lahawa"
  },
  {
    "id": "1225022010",
    "district_id": "122502",
    "label": "Hinako",
    "value": "Hinako"
  },
  {
    "id": "1225022011",
    "district_id": "122502",
    "label": "Sineneeto",
    "value": "Sineneeto"
  },
  {
    "id": "1225022012",
    "district_id": "122502",
    "label": "Balowondrate",
    "value": "Balowondrate"
  },
  {
    "id": "1225022013",
    "district_id": "122502",
    "label": "Hilimberuanaa",
    "value": "Hilimberuanaa"
  },
  {
    "id": "1225022014",
    "district_id": "122502",
    "label": "Lahusa",
    "value": "Lahusa"
  },
  {
    "id": "1225022015",
    "district_id": "122502",
    "label": "Ombolata",
    "value": "Ombolata"
  },
  {
    "id": "1225022016",
    "district_id": "122502",
    "label": "Tetehosi",
    "value": "Tetehosi"
  },
  {
    "id": "1225022017",
    "district_id": "122502",
    "label": "Togimbogi",
    "value": "Togimbogi"
  },
  {
    "id": "1225022018",
    "district_id": "122502",
    "label": "Sisobandrao",
    "value": "Sisobandrao"
  },
  {
    "id": "1225022019",
    "district_id": "122502",
    "label": "Fadoro",
    "value": "Fadoro"
  },
  {
    "id": "1225022020",
    "district_id": "122502",
    "label": "Tugala Gawu",
    "value": "Tugala Gawu"
  },
  {
    "id": "1225022021",
    "district_id": "122502",
    "label": "Sirombu",
    "value": "Sirombu"
  },
  {
    "id": "1225022022",
    "district_id": "122502",
    "label": "Togideu",
    "value": "Togideu"
  },
  {
    "id": "1225022023",
    "district_id": "122502",
    "label": "Tugala",
    "value": "Tugala"
  },
  {
    "id": "1225022024",
    "district_id": "122502",
    "label": "Orahili",
    "value": "Orahili"
  },
  {
    "id": "1225022025",
    "district_id": "122502",
    "label": "Gunungcahaya",
    "value": "Gunungcahaya"
  },
  {
    "id": "1225032001",
    "district_id": "122503",
    "label": "Onolimbu Raya",
    "value": "Onolimbu Raya"
  },
  {
    "id": "1225032002",
    "district_id": "122503",
    "label": "Ononamolo III",
    "value": "Ononamolo III"
  },
  {
    "id": "1225032003",
    "district_id": "122503",
    "label": "Sisobaoho",
    "value": "Sisobaoho"
  },
  {
    "id": "1225032004",
    "district_id": "122503",
    "label": "Iraonogeba",
    "value": "Iraonogeba"
  },
  {
    "id": "1225032005",
    "district_id": "122503",
    "label": "Lolohia",
    "value": "Lolohia"
  },
  {
    "id": "1225032006",
    "district_id": "122503",
    "label": "Fadorosifulubanua",
    "value": "Fadorosifulubanua"
  },
  {
    "id": "1225032007",
    "district_id": "122503",
    "label": "Hillidaura",
    "value": "Hillidaura"
  },
  {
    "id": "1225032008",
    "district_id": "122503",
    "label": "Sisarahili II",
    "value": "Sisarahili II"
  },
  {
    "id": "1225032009",
    "district_id": "122503",
    "label": "Lasara Bagawu",
    "value": "Lasara Bagawu"
  },
  {
    "id": "1225032010",
    "district_id": "122503",
    "label": "Sisobandrao",
    "value": "Sisobandrao"
  },
  {
    "id": "1225032011",
    "district_id": "122503",
    "label": "Mazingo",
    "value": "Mazingo"
  },
  {
    "id": "1225032012",
    "district_id": "122503",
    "label": "Onolimbu You",
    "value": "Onolimbu You"
  },
  {
    "id": "1225032013",
    "district_id": "122503",
    "label": "Lasarafaga",
    "value": "Lasarafaga"
  },
  {
    "id": "1225032014",
    "district_id": "122503",
    "label": "Orahili Badalu",
    "value": "Orahili Badalu"
  },
  {
    "id": "1225042001",
    "district_id": "122504",
    "label": "Hiliwaloo II",
    "value": "Hiliwaloo II"
  },
  {
    "id": "1225042002",
    "district_id": "122504",
    "label": "Gunungbaru",
    "value": "Gunungbaru"
  },
  {
    "id": "1225042003",
    "district_id": "122504",
    "label": "Hilisoromi",
    "value": "Hilisoromi"
  },
  {
    "id": "1225042004",
    "district_id": "122504",
    "label": "Siduahili",
    "value": "Siduahili"
  },
  {
    "id": "1225042005",
    "district_id": "122504",
    "label": "Sitolubanua Fadoro",
    "value": "Sitolubanua Fadoro"
  },
  {
    "id": "1225042006",
    "district_id": "122504",
    "label": "Halifadolo",
    "value": "Halifadolo"
  },
  {
    "id": "1225042007",
    "district_id": "122504",
    "label": "Lasara Bahili",
    "value": "Lasara Bahili"
  },
  {
    "id": "1225042008",
    "district_id": "122504",
    "label": "Hiliwaele",
    "value": "Hiliwaele"
  },
  {
    "id": "1225042009",
    "district_id": "122504",
    "label": "Sitoluewali",
    "value": "Sitoluewali"
  },
  {
    "id": "1225042010",
    "district_id": "122504",
    "label": "Onozalukhu You",
    "value": "Onozalukhu You"
  },
  {
    "id": "1225052001",
    "district_id": "122505",
    "label": "Simaeasi",
    "value": "Simaeasi"
  },
  {
    "id": "1225052002",
    "district_id": "122505",
    "label": "Lologolu",
    "value": "Lologolu"
  },
  {
    "id": "1225052003",
    "district_id": "122505",
    "label": "Tuwuna",
    "value": "Tuwuna"
  },
  {
    "id": "1225052004",
    "district_id": "122505",
    "label": "Tuhemberua",
    "value": "Tuhemberua"
  },
  {
    "id": "1225052005",
    "district_id": "122505",
    "label": "Lolozirugi",
    "value": "Lolozirugi"
  },
  {
    "id": "1225052006",
    "district_id": "122505",
    "label": "Lakhene",
    "value": "Lakhene"
  },
  {
    "id": "1225052007",
    "district_id": "122505",
    "label": "Hiliwaloo I",
    "value": "Hiliwaloo I"
  },
  {
    "id": "1225052008",
    "district_id": "122505",
    "label": "Sianaa",
    "value": "Sianaa"
  },
  {
    "id": "1225052009",
    "district_id": "122505",
    "label": "Fadoro",
    "value": "Fadoro"
  },
  {
    "id": "1225052010",
    "district_id": "122505",
    "label": "Iraonogambo",
    "value": "Iraonogambo"
  },
  {
    "id": "1225052011",
    "district_id": "122505",
    "label": "Tuhoowo",
    "value": "Tuhoowo"
  },
  {
    "id": "1225052012",
    "district_id": "122505",
    "label": "Fadoro Bahili",
    "value": "Fadoro Bahili"
  },
  {
    "id": "1225052013",
    "district_id": "122505",
    "label": "Tumori",
    "value": "Tumori"
  },
  {
    "id": "1225052014",
    "district_id": "122505",
    "label": "Lasara Baene",
    "value": "Lasara Baene"
  },
  {
    "id": "1225052015",
    "district_id": "122505",
    "label": "Tetehosi",
    "value": "Tetehosi"
  },
  {
    "id": "1225052016",
    "district_id": "122505",
    "label": "Sisarahili I",
    "value": "Sisarahili I"
  },
  {
    "id": "1225052017",
    "district_id": "122505",
    "label": "Doli-Doli",
    "value": "Doli-Doli"
  },
  {
    "id": "1225052018",
    "district_id": "122505",
    "label": "Hayo",
    "value": "Hayo"
  },
  {
    "id": "1225052019",
    "district_id": "122505",
    "label": "Zuzundrao",
    "value": "Zuzundrao"
  },
  {
    "id": "1225052020",
    "district_id": "122505",
    "label": "Sisobambowo",
    "value": "Sisobambowo"
  },
  {
    "id": "1225062001",
    "district_id": "122506",
    "label": "Balodano",
    "value": "Balodano"
  },
  {
    "id": "1225062002",
    "district_id": "122506",
    "label": "Hiambanua",
    "value": "Hiambanua"
  },
  {
    "id": "1225062003",
    "district_id": "122506",
    "label": "Hilimayo",
    "value": "Hilimayo"
  },
  {
    "id": "1225062004",
    "district_id": "122506",
    "label": "Sihareo",
    "value": "Sihareo"
  },
  {
    "id": "1225062005",
    "district_id": "122506",
    "label": "Lolomboli",
    "value": "Lolomboli"
  },
  {
    "id": "1225062006",
    "district_id": "122506",
    "label": "Ononamolo I",
    "value": "Ononamolo I"
  },
  {
    "id": "1225062007",
    "district_id": "122506",
    "label": "Hilimbaruzo",
    "value": "Hilimbaruzo"
  },
  {
    "id": "1225062008",
    "district_id": "122506",
    "label": "Tarahoso",
    "value": "Tarahoso"
  },
  {
    "id": "1225062009",
    "district_id": "122506",
    "label": "Ononamolo II",
    "value": "Ononamolo II"
  },
  {
    "id": "1225062010",
    "district_id": "122506",
    "label": "Taraha",
    "value": "Taraha"
  },
  {
    "id": "1225062011",
    "district_id": "122506",
    "label": "Lahagu",
    "value": "Lahagu"
  },
  {
    "id": "1225062012",
    "district_id": "122506",
    "label": "Hilimbowo",
    "value": "Hilimbowo"
  },
  {
    "id": "1225072001",
    "district_id": "122507",
    "label": "Sisobawino II",
    "value": "Sisobawino II"
  },
  {
    "id": "1225072002",
    "district_id": "122507",
    "label": "Duria",
    "value": "Duria"
  },
  {
    "id": "1225072003",
    "district_id": "122507",
    "label": "Ambukha",
    "value": "Ambukha"
  },
  {
    "id": "1225072004",
    "district_id": "122507",
    "label": "Hilimbowo Mau",
    "value": "Hilimbowo Mau"
  },
  {
    "id": "1225072005",
    "district_id": "122507",
    "label": "Hilimbuasi",
    "value": "Hilimbuasi"
  },
  {
    "id": "1225072006",
    "district_id": "122507",
    "label": "Lolofitu",
    "value": "Lolofitu"
  },
  {
    "id": "1225072007",
    "district_id": "122507",
    "label": "Hiliuso",
    "value": "Hiliuso"
  },
  {
    "id": "1225072008",
    "district_id": "122507",
    "label": "Wango",
    "value": "Wango"
  },
  {
    "id": "1225082001",
    "district_id": "122508",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "1225082002",
    "district_id": "122508",
    "label": "Lawelu",
    "value": "Lawelu"
  },
  {
    "id": "1225082003",
    "district_id": "122508",
    "label": "Saloo",
    "value": "Saloo"
  },
  {
    "id": "1225082004",
    "district_id": "122508",
    "label": "Hilisangawola",
    "value": "Hilisangawola"
  },
  {
    "id": "1225082005",
    "district_id": "122508",
    "label": "Hilibadalu",
    "value": "Hilibadalu"
  },
  {
    "id": "1271011001",
    "district_id": "127101",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1271011002",
    "district_id": "127101",
    "label": "Pusat Pasar",
    "value": "Pusat Pasar"
  },
  {
    "id": "1271011003",
    "district_id": "127101",
    "label": "Sei Rengas I",
    "value": "Sei Rengas I"
  },
  {
    "id": "1271011004",
    "district_id": "127101",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "1271011005",
    "district_id": "127101",
    "label": "Pandau Hulu I",
    "value": "Pandau Hulu I"
  },
  {
    "id": "1271011006",
    "district_id": "127101",
    "label": "Kotamatsum III",
    "value": "Kotamatsum III"
  },
  {
    "id": "1271011007",
    "district_id": "127101",
    "label": "Pasar Merah Barat",
    "value": "Pasar Merah Barat"
  },
  {
    "id": "1271011008",
    "district_id": "127101",
    "label": "Teladan Timur",
    "value": "Teladan Timur"
  },
  {
    "id": "1271011009",
    "district_id": "127101",
    "label": "Teladan Barat",
    "value": "Teladan Barat"
  },
  {
    "id": "1271011010",
    "district_id": "127101",
    "label": "Sudirejo 1",
    "value": "Sudirejo 1"
  },
  {
    "id": "1271011011",
    "district_id": "127101",
    "label": "Sudirejo II",
    "value": "Sudirejo II"
  },
  {
    "id": "1271011012",
    "district_id": "127101",
    "label": "Sitirejo I",
    "value": "Sitirejo I"
  },
  {
    "id": "1271021001",
    "district_id": "127102",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1271021002",
    "district_id": "127102",
    "label": "Sei Sikambing B",
    "value": "Sei Sikambing B"
  },
  {
    "id": "1271021003",
    "district_id": "127102",
    "label": "Sunggal",
    "value": "Sunggal"
  },
  {
    "id": "1271021004",
    "district_id": "127102",
    "label": "Babura Sunggal",
    "value": "Babura Sunggal"
  },
  {
    "id": "1271021005",
    "district_id": "127102",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1271021006",
    "district_id": "127102",
    "label": "Simpang Tanjung",
    "value": "Simpang Tanjung"
  },
  {
    "id": "1271031001",
    "district_id": "127103",
    "label": "Helvetia",
    "value": "Helvetia"
  },
  {
    "id": "1271031002",
    "district_id": "127103",
    "label": "Helvetia Tengah",
    "value": "Helvetia Tengah"
  },
  {
    "id": "1271031003",
    "district_id": "127103",
    "label": "Helvetia Timur",
    "value": "Helvetia Timur"
  },
  {
    "id": "1271031004",
    "district_id": "127103",
    "label": "Tanjung Gusta",
    "value": "Tanjung Gusta"
  },
  {
    "id": "1271031005",
    "district_id": "127103",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1271031006",
    "district_id": "127103",
    "label": "Dwi Kora",
    "value": "Dwi Kora"
  },
  {
    "id": "1271031007",
    "district_id": "127103",
    "label": "Sei Sikambing C II",
    "value": "Sei Sikambing C II"
  },
  {
    "id": "1271041001",
    "district_id": "127104",
    "label": "Tegal S Mandala I",
    "value": "Tegal S Mandala I"
  },
  {
    "id": "1271041002",
    "district_id": "127104",
    "label": "Tegal S Mandala II",
    "value": "Tegal S Mandala II"
  },
  {
    "id": "1271041003",
    "district_id": "127104",
    "label": "Tegal S Mandala III",
    "value": "Tegal S Mandala III"
  },
  {
    "id": "1271041004",
    "district_id": "127104",
    "label": "Denai",
    "value": "Denai"
  },
  {
    "id": "1271041005",
    "district_id": "127104",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1271041006",
    "district_id": "127104",
    "label": "Medan Tenggara",
    "value": "Medan Tenggara"
  },
  {
    "id": "1271051001",
    "district_id": "127105",
    "label": "Kesawan",
    "value": "Kesawan"
  },
  {
    "id": "1271051002",
    "district_id": "127105",
    "label": "Silalas",
    "value": "Silalas"
  },
  {
    "id": "1271051003",
    "district_id": "127105",
    "label": "Glugur Kota",
    "value": "Glugur Kota"
  },
  {
    "id": "1271051004",
    "district_id": "127105",
    "label": "Pulo Berayan Kota",
    "value": "Pulo Berayan Kota"
  },
  {
    "id": "1271051005",
    "district_id": "127105",
    "label": "Sei Agul",
    "value": "Sei Agul"
  },
  {
    "id": "1271051006",
    "district_id": "127105",
    "label": "Karang Berombak",
    "value": "Karang Berombak"
  },
  {
    "id": "1271061001",
    "district_id": "127106",
    "label": "Titipapan",
    "value": "Titipapan"
  },
  {
    "id": "1271061002",
    "district_id": "127106",
    "label": "Tanjung Mulia Hilir",
    "value": "Tanjung Mulia Hilir"
  },
  {
    "id": "1271061003",
    "district_id": "127106",
    "label": "Tanjung Mulya",
    "value": "Tanjung Mulya"
  },
  {
    "id": "1271061004",
    "district_id": "127106",
    "label": "Kota Bangun",
    "value": "Kota Bangun"
  },
  {
    "id": "1271061005",
    "district_id": "127106",
    "label": "Mabar",
    "value": "Mabar"
  },
  {
    "id": "1271061006",
    "district_id": "127106",
    "label": "Mabar Hilir",
    "value": "Mabar Hilir"
  },
  {
    "id": "1271071001",
    "district_id": "127107",
    "label": "Tanjung Selamat",
    "value": "Tanjung Selamat"
  },
  {
    "id": "1271071002",
    "district_id": "127107",
    "label": "Simpang Selayang",
    "value": "Simpang Selayang"
  },
  {
    "id": "1271071003",
    "district_id": "127107",
    "label": "Namo Gajah",
    "value": "Namo Gajah"
  },
  {
    "id": "1271071004",
    "district_id": "127107",
    "label": "Kemenangan Tani",
    "value": "Kemenangan Tani"
  },
  {
    "id": "1271071005",
    "district_id": "127107",
    "label": "Laucih",
    "value": "Laucih"
  },
  {
    "id": "1271071006",
    "district_id": "127107",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1271071007",
    "district_id": "127107",
    "label": "Ladang Bambu",
    "value": "Ladang Bambu"
  },
  {
    "id": "1271071008",
    "district_id": "127107",
    "label": "Simalingkar B",
    "value": "Simalingkar B"
  },
  {
    "id": "1271071009",
    "district_id": "127107",
    "label": "Mangga",
    "value": "Mangga"
  },
  {
    "id": "1271081001",
    "district_id": "127108",
    "label": "Belawan I",
    "value": "Belawan I"
  },
  {
    "id": "1271081002",
    "district_id": "127108",
    "label": "Belawan II",
    "value": "Belawan II"
  },
  {
    "id": "1271081003",
    "district_id": "127108",
    "label": "Belawan Bahagia",
    "value": "Belawan Bahagia"
  },
  {
    "id": "1271081004",
    "district_id": "127108",
    "label": "Belawan Bahari",
    "value": "Belawan Bahari"
  },
  {
    "id": "1271081005",
    "district_id": "127108",
    "label": "Belawan Sicanang",
    "value": "Belawan Sicanang"
  },
  {
    "id": "1271081006",
    "district_id": "127108",
    "label": "Bagan Deli",
    "value": "Bagan Deli"
  },
  {
    "id": "1271091001",
    "district_id": "127109",
    "label": "Amplas",
    "value": "Amplas"
  },
  {
    "id": "1271091002",
    "district_id": "127109",
    "label": "Sitirejo II",
    "value": "Sitirejo II"
  },
  {
    "id": "1271091003",
    "district_id": "127109",
    "label": "Sitirejo III",
    "value": "Sitirejo III"
  },
  {
    "id": "1271091004",
    "district_id": "127109",
    "label": "Harjosari I",
    "value": "Harjosari I"
  },
  {
    "id": "1271091005",
    "district_id": "127109",
    "label": "Harjosari II",
    "value": "Harjosari II"
  },
  {
    "id": "1271091006",
    "district_id": "127109",
    "label": "Timbang Deli",
    "value": "Timbang Deli"
  },
  {
    "id": "1271091007",
    "district_id": "127109",
    "label": "Bangun Mulia",
    "value": "Bangun Mulia"
  },
  {
    "id": "1271101001",
    "district_id": "127110",
    "label": "Sukaramai I",
    "value": "Sukaramai I"
  },
  {
    "id": "1271101002",
    "district_id": "127110",
    "label": "Sukaramai II",
    "value": "Sukaramai II"
  },
  {
    "id": "1271101003",
    "district_id": "127110",
    "label": "Sei Rengas II",
    "value": "Sei Rengas II"
  },
  {
    "id": "1271101004",
    "district_id": "127110",
    "label": "S. Rengas Permata",
    "value": "S. Rengas Permata"
  },
  {
    "id": "1271101005",
    "district_id": "127110",
    "label": "Pandau Hulu II",
    "value": "Pandau Hulu II"
  },
  {
    "id": "1271101006",
    "district_id": "127110",
    "label": "Kota Matsum I",
    "value": "Kota Matsum I"
  },
  {
    "id": "1271101007",
    "district_id": "127110",
    "label": "Kota Matsum II",
    "value": "Kota Matsum II"
  },
  {
    "id": "1271101008",
    "district_id": "127110",
    "label": "Kota Matsum IV",
    "value": "Kota Matsum IV"
  },
  {
    "id": "1271101009",
    "district_id": "127110",
    "label": "Pasar Merah Timur",
    "value": "Pasar Merah Timur"
  },
  {
    "id": "1271101010",
    "district_id": "127110",
    "label": "Tegal Sari I",
    "value": "Tegal Sari I"
  },
  {
    "id": "1271101011",
    "district_id": "127110",
    "label": "Tegal Sari II",
    "value": "Tegal Sari II"
  },
  {
    "id": "1271101012",
    "district_id": "127110",
    "label": "Tegal Sari III",
    "value": "Tegal Sari III"
  },
  {
    "id": "1271111001",
    "district_id": "127111",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1271111002",
    "district_id": "127111",
    "label": "Titi Kuning",
    "value": "Titi Kuning"
  },
  {
    "id": "1271111003",
    "district_id": "127111",
    "label": "Kedai Durian",
    "value": "Kedai Durian"
  },
  {
    "id": "1271111004",
    "district_id": "127111",
    "label": "Pangkalan Mansur",
    "value": "Pangkalan Mansur"
  },
  {
    "id": "1271111005",
    "district_id": "127111",
    "label": "Gedung Johor",
    "value": "Gedung Johor"
  },
  {
    "id": "1271111006",
    "district_id": "127111",
    "label": "Kwala Bekala",
    "value": "Kwala Bekala"
  },
  {
    "id": "1271121001",
    "district_id": "127112",
    "label": "Labuhan Deli",
    "value": "Labuhan Deli"
  },
  {
    "id": "1271121002",
    "district_id": "127112",
    "label": "Rengas Pulau",
    "value": "Rengas Pulau"
  },
  {
    "id": "1271121003",
    "district_id": "127112",
    "label": "Terjun",
    "value": "Terjun"
  },
  {
    "id": "1271121004",
    "district_id": "127112",
    "label": "Tanah Enam Ratus",
    "value": "Tanah Enam Ratus"
  },
  {
    "id": "1271121005",
    "district_id": "127112",
    "label": "Paya Pasir",
    "value": "Paya Pasir"
  },
  {
    "id": "1271131001",
    "district_id": "127113",
    "label": "Pekan Labuhan",
    "value": "Pekan Labuhan"
  },
  {
    "id": "1271131002",
    "district_id": "127113",
    "label": "Sei Mati",
    "value": "Sei Mati"
  },
  {
    "id": "1271131003",
    "district_id": "127113",
    "label": "Besar",
    "value": "Besar"
  },
  {
    "id": "1271131004",
    "district_id": "127113",
    "label": "Martubung",
    "value": "Martubung"
  },
  {
    "id": "1271131005",
    "district_id": "127113",
    "label": "Nelayan Indah",
    "value": "Nelayan Indah"
  },
  {
    "id": "1271131006",
    "district_id": "127113",
    "label": "Tangkahan",
    "value": "Tangkahan"
  },
  {
    "id": "1271141001",
    "district_id": "127114",
    "label": "Indra Kasih",
    "value": "Indra Kasih"
  },
  {
    "id": "1271141002",
    "district_id": "127114",
    "label": "Sidorejo Hilir",
    "value": "Sidorejo Hilir"
  },
  {
    "id": "1271141003",
    "district_id": "127114",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1271141004",
    "district_id": "127114",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "1271141005",
    "district_id": "127114",
    "label": "Tembung",
    "value": "Tembung"
  },
  {
    "id": "1271141006",
    "district_id": "127114",
    "label": "Bandar Selamat",
    "value": "Bandar Selamat"
  },
  {
    "id": "1271141007",
    "district_id": "127114",
    "label": "Bantan Timur",
    "value": "Bantan Timur"
  },
  {
    "id": "1271151001",
    "district_id": "127115",
    "label": "Aur",
    "value": "Aur"
  },
  {
    "id": "1271151002",
    "district_id": "127115",
    "label": "Hamdan",
    "value": "Hamdan"
  },
  {
    "id": "1271151003",
    "district_id": "127115",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "1271151004",
    "district_id": "127115",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1271151005",
    "district_id": "127115",
    "label": "Sei Mati",
    "value": "Sei Mati"
  },
  {
    "id": "1271151006",
    "district_id": "127115",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1271161001",
    "district_id": "127116",
    "label": "Madras Hulu",
    "value": "Madras Hulu"
  },
  {
    "id": "1271161002",
    "district_id": "127116",
    "label": "Polonia",
    "value": "Polonia"
  },
  {
    "id": "1271161003",
    "district_id": "127116",
    "label": "Sari Rejo",
    "value": "Sari Rejo"
  },
  {
    "id": "1271161004",
    "district_id": "127116",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1271161005",
    "district_id": "127116",
    "label": "Anggrung",
    "value": "Anggrung"
  },
  {
    "id": "1271171001",
    "district_id": "127117",
    "label": "Petisah Hulu",
    "value": "Petisah Hulu"
  },
  {
    "id": "1271171002",
    "district_id": "127117",
    "label": "Babura",
    "value": "Babura"
  },
  {
    "id": "1271171003",
    "district_id": "127117",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "1271171004",
    "district_id": "127117",
    "label": "Darat",
    "value": "Darat"
  },
  {
    "id": "1271171005",
    "district_id": "127117",
    "label": "Padang Bulan",
    "value": "Padang Bulan"
  },
  {
    "id": "1271171006",
    "district_id": "127117",
    "label": "Titi Rante",
    "value": "Titi Rante"
  },
  {
    "id": "1271181001",
    "district_id": "127118",
    "label": "Pandau Hilir",
    "value": "Pandau Hilir"
  },
  {
    "id": "1271181002",
    "district_id": "127118",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1271181003",
    "district_id": "127118",
    "label": "Sei Kera Hilir I",
    "value": "Sei Kera Hilir I"
  },
  {
    "id": "1271181004",
    "district_id": "127118",
    "label": "Sei Kera Hilir II",
    "value": "Sei Kera Hilir II"
  },
  {
    "id": "1271181005",
    "district_id": "127118",
    "label": "Sei Kera Hulu",
    "value": "Sei Kera Hulu"
  },
  {
    "id": "1271181006",
    "district_id": "127118",
    "label": "Sidorame Timur",
    "value": "Sidorame Timur"
  },
  {
    "id": "1271181007",
    "district_id": "127118",
    "label": "Sidorame Barat I",
    "value": "Sidorame Barat I"
  },
  {
    "id": "1271181008",
    "district_id": "127118",
    "label": "Sidorame Barat II",
    "value": "Sidorame Barat II"
  },
  {
    "id": "1271181009",
    "district_id": "127118",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "1271191001",
    "district_id": "127119",
    "label": "Petisah Tengah",
    "value": "Petisah Tengah"
  },
  {
    "id": "1271191002",
    "district_id": "127119",
    "label": "Sekip",
    "value": "Sekip"
  },
  {
    "id": "1271191003",
    "district_id": "127119",
    "label": "Sei Sikambing D",
    "value": "Sei Sikambing D"
  },
  {
    "id": "1271191004",
    "district_id": "127119",
    "label": "Sei Putih Barat",
    "value": "Sei Putih Barat"
  },
  {
    "id": "1271191005",
    "district_id": "127119",
    "label": "Sei Putih Tengah",
    "value": "Sei Putih Tengah"
  },
  {
    "id": "1271191006",
    "district_id": "127119",
    "label": "Sei Putih Timur I",
    "value": "Sei Putih Timur I"
  },
  {
    "id": "1271191007",
    "district_id": "127119",
    "label": "Sei Putih Timur II",
    "value": "Sei Putih Timur II"
  },
  {
    "id": "1271201001",
    "district_id": "127120",
    "label": "Gang Buntu",
    "value": "Gang Buntu"
  },
  {
    "id": "1271201002",
    "district_id": "127120",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1271201003",
    "district_id": "127120",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1271201004",
    "district_id": "127120",
    "label": "Glugur Darat I",
    "value": "Glugur Darat I"
  },
  {
    "id": "1271201005",
    "district_id": "127120",
    "label": "Glugur Darat II",
    "value": "Glugur Darat II"
  },
  {
    "id": "1271201006",
    "district_id": "127120",
    "label": "P Berayan Darat I",
    "value": "P Berayan Darat I"
  },
  {
    "id": "1271201007",
    "district_id": "127120",
    "label": "P Berayan Darat II",
    "value": "P Berayan Darat II"
  },
  {
    "id": "1271201008",
    "district_id": "127120",
    "label": "P Berayan Bengkel",
    "value": "P Berayan Bengkel"
  },
  {
    "id": "1271201009",
    "district_id": "127120",
    "label": "PB Bengkel Baru",
    "value": "PB Bengkel Baru"
  },
  {
    "id": "1271201010",
    "district_id": "127120",
    "label": "Perintis",
    "value": "Perintis"
  },
  {
    "id": "1271201011",
    "district_id": "127120",
    "label": "Gaharu",
    "value": "Gaharu"
  },
  {
    "id": "1271211001",
    "district_id": "127121",
    "label": "Asam Kumbang",
    "value": "Asam Kumbang"
  },
  {
    "id": "1271211002",
    "district_id": "127121",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "1271211003",
    "district_id": "127121",
    "label": "P Bulan Selayang II",
    "value": "P Bulan Selayang II"
  },
  {
    "id": "1271211004",
    "district_id": "127121",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1271211005",
    "district_id": "127121",
    "label": "P Bulan Selayang I",
    "value": "P Bulan Selayang I"
  },
  {
    "id": "1271211006",
    "district_id": "127121",
    "label": "Sempakata",
    "value": "Sempakata"
  },
  {
    "id": "1272011001",
    "district_id": "127201",
    "label": "Kebun Sayur",
    "value": "Kebun Sayur"
  },
  {
    "id": "1272011002",
    "district_id": "127201",
    "label": "Tomuan",
    "value": "Tomuan"
  },
  {
    "id": "1272011003",
    "district_id": "127201",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1272011004",
    "district_id": "127201",
    "label": "Asuhan",
    "value": "Asuhan"
  },
  {
    "id": "1272011005",
    "district_id": "127201",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "1272011006",
    "district_id": "127201",
    "label": "Pardomuan",
    "value": "Pardomuan"
  },
  {
    "id": "1272011007",
    "district_id": "127201",
    "label": "Siopat Suhu",
    "value": "Siopat Suhu"
  },
  {
    "id": "1272021001",
    "district_id": "127202",
    "label": "Dwikora",
    "value": "Dwikora"
  },
  {
    "id": "1272021002",
    "district_id": "127202",
    "label": "Proklamasi",
    "value": "Proklamasi"
  },
  {
    "id": "1272021003",
    "district_id": "127202",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "1272021004",
    "district_id": "127202",
    "label": "Timbang Galung",
    "value": "Timbang Galung"
  },
  {
    "id": "1272021005",
    "district_id": "127202",
    "label": "Simarito",
    "value": "Simarito"
  },
  {
    "id": "1272021006",
    "district_id": "127202",
    "label": "Sipinggolpinggol",
    "value": "Sipinggolpinggol"
  },
  {
    "id": "1272021007",
    "district_id": "127202",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "1272021008",
    "district_id": "127202",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1272031001",
    "district_id": "127203",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "1272031002",
    "district_id": "127203",
    "label": "Martoba",
    "value": "Martoba"
  },
  {
    "id": "1272031003",
    "district_id": "127203",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1272031004",
    "district_id": "127203",
    "label": "Sukadame",
    "value": "Sukadame"
  },
  {
    "id": "1272031005",
    "district_id": "127203",
    "label": "Kahean",
    "value": "Kahean"
  },
  {
    "id": "1272031006",
    "district_id": "127203",
    "label": "Sigulanggulang",
    "value": "Sigulanggulang"
  },
  {
    "id": "1272031007",
    "district_id": "127203",
    "label": "Bane",
    "value": "Bane"
  },
  {
    "id": "1272041001",
    "district_id": "127204",
    "label": "Toba",
    "value": "Toba"
  },
  {
    "id": "1272041002",
    "district_id": "127204",
    "label": "Karo",
    "value": "Karo"
  },
  {
    "id": "1272041003",
    "district_id": "127204",
    "label": "Simalungun",
    "value": "Simalungun"
  },
  {
    "id": "1272041004",
    "district_id": "127204",
    "label": "Martimbang",
    "value": "Martimbang"
  },
  {
    "id": "1272041005",
    "district_id": "127204",
    "label": "Kristen",
    "value": "Kristen"
  },
  {
    "id": "1272041006",
    "district_id": "127204",
    "label": "Aek Nauli",
    "value": "Aek Nauli"
  },
  {
    "id": "1272051002",
    "district_id": "127205",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1272051005",
    "district_id": "127205",
    "label": "Baringin Pancur Nauli",
    "value": "Baringin Pancur Nauli"
  },
  {
    "id": "1272051006",
    "district_id": "127205",
    "label": "Pardamean",
    "value": "Pardamean"
  },
  {
    "id": "1272051007",
    "district_id": "127205",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1272051008",
    "district_id": "127205",
    "label": "Parhorasan Nauli",
    "value": "Parhorasan Nauli"
  },
  {
    "id": "1272051009",
    "district_id": "127205",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "1272051010",
    "district_id": "127205",
    "label": "Mekar Nauli",
    "value": "Mekar Nauli"
  },
  {
    "id": "1272061001",
    "district_id": "127206",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1272061003",
    "district_id": "127206",
    "label": "Tambun Nabolon",
    "value": "Tambun Nabolon"
  },
  {
    "id": "1272061007",
    "district_id": "127206",
    "label": "Naga Pita",
    "value": "Naga Pita"
  },
  {
    "id": "1272061008",
    "district_id": "127206",
    "label": "Pondok Sayur",
    "value": "Pondok Sayur"
  },
  {
    "id": "1272061009",
    "district_id": "127206",
    "label": "Tanjung Tongah",
    "value": "Tanjung Tongah"
  },
  {
    "id": "1272061010",
    "district_id": "127206",
    "label": "Naga Pitu",
    "value": "Naga Pitu"
  },
  {
    "id": "1272061011",
    "district_id": "127206",
    "label": "Tanjung Pinggir",
    "value": "Tanjung Pinggir"
  },
  {
    "id": "1272071001",
    "district_id": "127207",
    "label": "Gurilla",
    "value": "Gurilla"
  },
  {
    "id": "1272071002",
    "district_id": "127207",
    "label": "Bah Kapul",
    "value": "Bah Kapul"
  },
  {
    "id": "1272071003",
    "district_id": "127207",
    "label": "Setia Negara",
    "value": "Setia Negara"
  },
  {
    "id": "1272071004",
    "district_id": "127207",
    "label": "Bukit Sofa",
    "value": "Bukit Sofa"
  },
  {
    "id": "1272071005",
    "district_id": "127207",
    "label": "Bah Sorma",
    "value": "Bah Sorma"
  },
  {
    "id": "1272081001",
    "district_id": "127208",
    "label": "Simarimbun",
    "value": "Simarimbun"
  },
  {
    "id": "1272081002",
    "district_id": "127208",
    "label": "Tong Marimbun",
    "value": "Tong Marimbun"
  },
  {
    "id": "1272081003",
    "district_id": "127208",
    "label": "Naga Huta",
    "value": "Naga Huta"
  },
  {
    "id": "1272081004",
    "district_id": "127208",
    "label": "Naga Huta Timur",
    "value": "Naga Huta Timur"
  },
  {
    "id": "1272081005",
    "district_id": "127208",
    "label": "Pematang Marihat",
    "value": "Pematang Marihat"
  },
  {
    "id": "1272081006",
    "district_id": "127208",
    "label": "Marihat Jaya",
    "value": "Marihat Jaya"
  },
  {
    "id": "1273011001",
    "district_id": "127301",
    "label": "Huta Barangan",
    "value": "Huta Barangan"
  },
  {
    "id": "1273011002",
    "district_id": "127301",
    "label": "Angin Nauli",
    "value": "Angin Nauli"
  },
  {
    "id": "1273011003",
    "district_id": "127301",
    "label": "Huta Tonga-tonga",
    "value": "Huta Tonga-tonga"
  },
  {
    "id": "1273011004",
    "district_id": "127301",
    "label": "Sibolga Ilir",
    "value": "Sibolga Ilir"
  },
  {
    "id": "1273011005",
    "district_id": "127301",
    "label": "Simare-Mare",
    "value": "Simare-Mare"
  },
  {
    "id": "1273021001",
    "district_id": "127302",
    "label": "Kota Baringin",
    "value": "Kota Baringin"
  },
  {
    "id": "1273021002",
    "district_id": "127302",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1273021003",
    "district_id": "127302",
    "label": "Pasar Belakang",
    "value": "Pasar Belakang"
  },
  {
    "id": "1273021004",
    "district_id": "127302",
    "label": "Pancuran Gerobak",
    "value": "Pancuran Gerobak"
  },
  {
    "id": "1273031001",
    "district_id": "127303",
    "label": "Aek Habil",
    "value": "Aek Habil"
  },
  {
    "id": "1273031002",
    "district_id": "127303",
    "label": "Aek Manis",
    "value": "Aek Manis"
  },
  {
    "id": "1273031003",
    "district_id": "127303",
    "label": "Aek Muara Pinang",
    "value": "Aek Muara Pinang"
  },
  {
    "id": "1273031004",
    "district_id": "127303",
    "label": "Aek Parombunan",
    "value": "Aek Parombunan"
  },
  {
    "id": "1273041001",
    "district_id": "127304",
    "label": "Pancuran Kerambil",
    "value": "Pancuran Kerambil"
  },
  {
    "id": "1273041002",
    "district_id": "127304",
    "label": "Pancuran Dewa",
    "value": "Pancuran Dewa"
  },
  {
    "id": "1273041003",
    "district_id": "127304",
    "label": "Pancuran Pinang",
    "value": "Pancuran Pinang"
  },
  {
    "id": "1273041004",
    "district_id": "127304",
    "label": "Pancuran Bambu",
    "value": "Pancuran Bambu"
  },
  {
    "id": "1274011001",
    "district_id": "127401",
    "label": "Tanjung Balai Kota I",
    "value": "Tanjung Balai Kota I"
  },
  {
    "id": "1274011002",
    "district_id": "127401",
    "label": "Tanjung Balai Kota II",
    "value": "Tanjung Balai Kota II"
  },
  {
    "id": "1274011003",
    "district_id": "127401",
    "label": "Perwira",
    "value": "Perwira"
  },
  {
    "id": "1274011004",
    "district_id": "127401",
    "label": "Karya",
    "value": "Karya"
  },
  {
    "id": "1274011005",
    "district_id": "127401",
    "label": "Pantai Burung",
    "value": "Pantai Burung"
  },
  {
    "id": "1274011006",
    "district_id": "127401",
    "label": "Indra Sakti",
    "value": "Indra Sakti"
  },
  {
    "id": "1274021001",
    "district_id": "127402",
    "label": "Tanjung Balai Kota III",
    "value": "Tanjung Balai Kota III"
  },
  {
    "id": "1274021002",
    "district_id": "127402",
    "label": "Tanjung Balai Kota IV",
    "value": "Tanjung Balai Kota IV"
  },
  {
    "id": "1274021003",
    "district_id": "127402",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "1274021004",
    "district_id": "127402",
    "label": "Kuala Silo Bestari",
    "value": "Kuala Silo Bestari"
  },
  {
    "id": "1274021005",
    "district_id": "127402",
    "label": "Matahalasan",
    "value": "Matahalasan"
  },
  {
    "id": "1274031001",
    "district_id": "127403",
    "label": "Keramat Kubah",
    "value": "Keramat Kubah"
  },
  {
    "id": "1274031002",
    "district_id": "127403",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1274031003",
    "district_id": "127403",
    "label": "Muara Sentosa",
    "value": "Muara Sentosa"
  },
  {
    "id": "1274031004",
    "district_id": "127403",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1274031005",
    "district_id": "127403",
    "label": "Sei Raja",
    "value": "Sei Raja"
  },
  {
    "id": "1274041001",
    "district_id": "127404",
    "label": "Sei Merbau",
    "value": "Sei Merbau"
  },
  {
    "id": "1274041002",
    "district_id": "127404",
    "label": "Pematang Pasir",
    "value": "Pematang Pasir"
  },
  {
    "id": "1274041003",
    "district_id": "127404",
    "label": "Perjuangan",
    "value": "Perjuangan"
  },
  {
    "id": "1274041004",
    "district_id": "127404",
    "label": "Kapias Pulau Buaya",
    "value": "Kapias Pulau Buaya"
  },
  {
    "id": "1274041005",
    "district_id": "127404",
    "label": "Beting Kuala Kapias",
    "value": "Beting Kuala Kapias"
  },
  {
    "id": "1274051003",
    "district_id": "127405",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "1274051004",
    "district_id": "127405",
    "label": "Sirantau",
    "value": "Sirantau"
  },
  {
    "id": "1274051005",
    "district_id": "127405",
    "label": "Sijambi",
    "value": "Sijambi"
  },
  {
    "id": "1274051006",
    "district_id": "127405",
    "label": "Pahang",
    "value": "Pahang"
  },
  {
    "id": "1274051010",
    "district_id": "127405",
    "label": "Pantai Johor",
    "value": "Pantai Johor"
  },
  {
    "id": "1274061001",
    "district_id": "127406",
    "label": "Pulau Simardan",
    "value": "Pulau Simardan"
  },
  {
    "id": "1274061002",
    "district_id": "127406",
    "label": "Semula Jadi",
    "value": "Semula Jadi"
  },
  {
    "id": "1274061003",
    "district_id": "127406",
    "label": "Selat Lancang",
    "value": "Selat Lancang"
  },
  {
    "id": "1274061004",
    "district_id": "127406",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1274061005",
    "district_id": "127406",
    "label": "Selat Tanjung Medan",
    "value": "Selat Tanjung Medan"
  },
  {
    "id": "1275011001",
    "district_id": "127501",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1275011002",
    "district_id": "127501",
    "label": "Jati Negara",
    "value": "Jati Negara"
  },
  {
    "id": "1275011003",
    "district_id": "127501",
    "label": "Nangka",
    "value": "Nangka"
  },
  {
    "id": "1275011004",
    "district_id": "127501",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "1275011005",
    "district_id": "127501",
    "label": "Kebun Lada",
    "value": "Kebun Lada"
  },
  {
    "id": "1275011006",
    "district_id": "127501",
    "label": "Cengkeh Turi",
    "value": "Cengkeh Turi"
  },
  {
    "id": "1275011007",
    "district_id": "127501",
    "label": "Jati Utomo",
    "value": "Jati Utomo"
  },
  {
    "id": "1275011008",
    "district_id": "127501",
    "label": "Jati Makmur",
    "value": "Jati Makmur"
  },
  {
    "id": "1275011009",
    "district_id": "127501",
    "label": "Jati Karya",
    "value": "Jati Karya"
  },
  {
    "id": "1275021001",
    "district_id": "127502",
    "label": "Berngam",
    "value": "Berngam"
  },
  {
    "id": "1275021002",
    "district_id": "127502",
    "label": "Satria",
    "value": "Satria"
  },
  {
    "id": "1275021003",
    "district_id": "127502",
    "label": "Setia",
    "value": "Setia"
  },
  {
    "id": "1275021004",
    "district_id": "127502",
    "label": "Kartini",
    "value": "Kartini"
  },
  {
    "id": "1275021005",
    "district_id": "127502",
    "label": "Tangsi",
    "value": "Tangsi"
  },
  {
    "id": "1275021006",
    "district_id": "127502",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1275021007",
    "district_id": "127502",
    "label": "Pekan Binjai",
    "value": "Pekan Binjai"
  },
  {
    "id": "1275031001",
    "district_id": "127503",
    "label": "Bandar Senembah",
    "value": "Bandar Senembah"
  },
  {
    "id": "1275031002",
    "district_id": "127503",
    "label": "Limau Mungkur",
    "value": "Limau Mungkur"
  },
  {
    "id": "1275031003",
    "district_id": "127503",
    "label": "Limau Sundai",
    "value": "Limau Sundai"
  },
  {
    "id": "1275031004",
    "district_id": "127503",
    "label": "Paya Roba",
    "value": "Paya Roba"
  },
  {
    "id": "1275031005",
    "district_id": "127503",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1275031006",
    "district_id": "127503",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "1275041001",
    "district_id": "127504",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "1275041002",
    "district_id": "127504",
    "label": "Dataran Tinggi",
    "value": "Dataran Tinggi"
  },
  {
    "id": "1275041003",
    "district_id": "127504",
    "label": "Timbang Langkat",
    "value": "Timbang Langkat"
  },
  {
    "id": "1275041004",
    "district_id": "127504",
    "label": "Mencirim",
    "value": "Mencirim"
  },
  {
    "id": "1275041005",
    "district_id": "127504",
    "label": "Tunggurono",
    "value": "Tunggurono"
  },
  {
    "id": "1275041006",
    "district_id": "127504",
    "label": "Sumber Mulyorejo",
    "value": "Sumber Mulyorejo"
  },
  {
    "id": "1275041007",
    "district_id": "127504",
    "label": "Sumber Karya",
    "value": "Sumber Karya"
  },
  {
    "id": "1275051001",
    "district_id": "127505",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1275051002",
    "district_id": "127505",
    "label": "Binjai Estate",
    "value": "Binjai Estate"
  },
  {
    "id": "1275051003",
    "district_id": "127505",
    "label": "Bhakti Karya",
    "value": "Bhakti Karya"
  },
  {
    "id": "1275051004",
    "district_id": "127505",
    "label": "Rambung Dalam",
    "value": "Rambung Dalam"
  },
  {
    "id": "1275051005",
    "district_id": "127505",
    "label": "Pujidadi",
    "value": "Pujidadi"
  },
  {
    "id": "1275051006",
    "district_id": "127505",
    "label": "Tanah Seribu",
    "value": "Tanah Seribu"
  },
  {
    "id": "1275051007",
    "district_id": "127505",
    "label": "Rambung Barat",
    "value": "Rambung Barat"
  },
  {
    "id": "1275051008",
    "district_id": "127505",
    "label": "Rambung Timur",
    "value": "Rambung Timur"
  },
  {
    "id": "1276011001",
    "district_id": "127601",
    "label": "Pabatu",
    "value": "Pabatu"
  },
  {
    "id": "1276011002",
    "district_id": "127601",
    "label": "Lubuk Baru",
    "value": "Lubuk Baru"
  },
  {
    "id": "1276011006",
    "district_id": "127601",
    "label": "Persiakan",
    "value": "Persiakan"
  },
  {
    "id": "1276011007",
    "district_id": "127601",
    "label": "Bandar Sono",
    "value": "Bandar Sono"
  },
  {
    "id": "1276011010",
    "district_id": "127601",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1276011011",
    "district_id": "127601",
    "label": "Padang Merbau",
    "value": "Padang Merbau"
  },
  {
    "id": "1276011012",
    "district_id": "127601",
    "label": "Lubuk Raya",
    "value": "Lubuk Raya"
  },
  {
    "id": "1276021004",
    "district_id": "127602",
    "label": "Rantau Laban",
    "value": "Rantau Laban"
  },
  {
    "id": "1276021007",
    "district_id": "127602",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "1276021009",
    "district_id": "127602",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1276021010",
    "district_id": "127602",
    "label": "Tanjung Marulak",
    "value": "Tanjung Marulak"
  },
  {
    "id": "1276021011",
    "district_id": "127602",
    "label": "Sri Padang",
    "value": "Sri Padang"
  },
  {
    "id": "1276021012",
    "district_id": "127602",
    "label": "Tanjung Marulak Hilir",
    "value": "Tanjung Marulak Hilir"
  },
  {
    "id": "1276021013",
    "district_id": "127602",
    "label": "Mekar Sentosa",
    "value": "Mekar Sentosa"
  },
  {
    "id": "1276031001",
    "district_id": "127603",
    "label": "Bagelen",
    "value": "Bagelen"
  },
  {
    "id": "1276031002",
    "district_id": "127603",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "1276031004",
    "district_id": "127603",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1276031006",
    "district_id": "127603",
    "label": "Satria",
    "value": "Satria"
  },
  {
    "id": "1276031007",
    "district_id": "127603",
    "label": "Damar Sari",
    "value": "Damar Sari"
  },
  {
    "id": "1276031008",
    "district_id": "127603",
    "label": "Tambangan Hulu",
    "value": "Tambangan Hulu"
  },
  {
    "id": "1276031009",
    "district_id": "127603",
    "label": "Deblod Sundoro",
    "value": "Deblod Sundoro"
  },
  {
    "id": "1276041001",
    "district_id": "127604",
    "label": "Durian Semu",
    "value": "Durian Semu"
  },
  {
    "id": "1276041002",
    "district_id": "127604",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "1276041003",
    "district_id": "127604",
    "label": "Bulian",
    "value": "Bulian"
  },
  {
    "id": "1276041004",
    "district_id": "127604",
    "label": "Berohol",
    "value": "Berohol"
  },
  {
    "id": "1276041005",
    "district_id": "127604",
    "label": "Bandar Sakti",
    "value": "Bandar Sakti"
  },
  {
    "id": "1276041006",
    "district_id": "127604",
    "label": "Pinang Mancung",
    "value": "Pinang Mancung"
  },
  {
    "id": "1276041007",
    "district_id": "127604",
    "label": "Teluk Karang",
    "value": "Teluk Karang"
  },
  {
    "id": "1276051001",
    "district_id": "127605",
    "label": "Mandailing",
    "value": "Mandailing"
  },
  {
    "id": "1276051002",
    "district_id": "127605",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1276051003",
    "district_id": "127605",
    "label": "Pasar Gambir",
    "value": "Pasar Gambir"
  },
  {
    "id": "1276051004",
    "district_id": "127605",
    "label": "Badak Bejuang",
    "value": "Badak Bejuang"
  },
  {
    "id": "1276051005",
    "district_id": "127605",
    "label": "Bandar Utama",
    "value": "Bandar Utama"
  },
  {
    "id": "1276051006",
    "district_id": "127605",
    "label": "Rambung",
    "value": "Rambung"
  },
  {
    "id": "1276051007",
    "district_id": "127605",
    "label": "Tebing Tinggi Lama",
    "value": "Tebing Tinggi Lama"
  },
  {
    "id": "1277011001",
    "district_id": "127701",
    "label": "Wek I",
    "value": "Wek I"
  },
  {
    "id": "1277011002",
    "district_id": "127701",
    "label": "Wek II",
    "value": "Wek II"
  },
  {
    "id": "1277011003",
    "district_id": "127701",
    "label": "Wek III",
    "value": "Wek III"
  },
  {
    "id": "1277011004",
    "district_id": "127701",
    "label": "Wek IV",
    "value": "Wek IV"
  },
  {
    "id": "1277011005",
    "district_id": "127701",
    "label": "Tanobato",
    "value": "Tanobato"
  },
  {
    "id": "1277011006",
    "district_id": "127701",
    "label": "Tobat",
    "value": "Tobat"
  },
  {
    "id": "1277011007",
    "district_id": "127701",
    "label": "Batang Ayumi Jae",
    "value": "Batang Ayumi Jae"
  },
  {
    "id": "1277011008",
    "district_id": "127701",
    "label": "Batang Ayumi Julu",
    "value": "Batang Ayumi Julu"
  },
  {
    "id": "1277011009",
    "district_id": "127701",
    "label": "Sadabuan",
    "value": "Sadabuan"
  },
  {
    "id": "1277011010",
    "district_id": "127701",
    "label": "Panyanggar",
    "value": "Panyanggar"
  },
  {
    "id": "1277011011",
    "district_id": "127701",
    "label": "Bonan Dolok",
    "value": "Bonan Dolok"
  },
  {
    "id": "1277011012",
    "district_id": "127701",
    "label": "Losung Batu.",
    "value": "Losung Batu."
  },
  {
    "id": "1277011013",
    "district_id": "127701",
    "label": "Timbangan",
    "value": "Timbangan"
  },
  {
    "id": "1277011014",
    "district_id": "127701",
    "label": "Bincar",
    "value": "Bincar"
  },
  {
    "id": "1277011015",
    "district_id": "127701",
    "label": "Kantin",
    "value": "Kantin"
  },
  {
    "id": "1277011016",
    "district_id": "127701",
    "label": "Kayu Ombun",
    "value": "Kayu Ombun"
  },
  {
    "id": "1277021001",
    "district_id": "127702",
    "label": "Wek V",
    "value": "Wek V"
  },
  {
    "id": "1277021002",
    "district_id": "127702",
    "label": "Wek VI",
    "value": "Wek VI"
  },
  {
    "id": "1277021003",
    "district_id": "127702",
    "label": "Losung",
    "value": "Losung"
  },
  {
    "id": "1277021004",
    "district_id": "127702",
    "label": "Sitamiang",
    "value": "Sitamiang"
  },
  {
    "id": "1277021005",
    "district_id": "127702",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1277021006",
    "district_id": "127702",
    "label": "Aek Tampang",
    "value": "Aek Tampang"
  },
  {
    "id": "1277021007",
    "district_id": "127702",
    "label": "Padang Matinggi",
    "value": "Padang Matinggi"
  },
  {
    "id": "1277021008",
    "district_id": "127702",
    "label": "Silandit",
    "value": "Silandit"
  },
  {
    "id": "1277021009",
    "district_id": "127702",
    "label": "Sidangkal",
    "value": "Sidangkal"
  },
  {
    "id": "1277021010",
    "district_id": "127702",
    "label": "Hanopan",
    "value": "Hanopan"
  },
  {
    "id": "1277021011",
    "district_id": "127702",
    "label": "Padang Matinggi Lestari",
    "value": "Padang Matinggi Lestari"
  },
  {
    "id": "1277021012",
    "district_id": "127702",
    "label": "Sitamiang Baru",
    "value": "Sitamiang Baru"
  },
  {
    "id": "1277031001",
    "district_id": "127703",
    "label": "Batunadua Jae",
    "value": "Batunadua Jae"
  },
  {
    "id": "1277031002",
    "district_id": "127703",
    "label": "Batunadua Julu",
    "value": "Batunadua Julu"
  },
  {
    "id": "1277032003",
    "district_id": "127703",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1277032004",
    "district_id": "127703",
    "label": "Gunung Hasahatan",
    "value": "Gunung Hasahatan"
  },
  {
    "id": "1277032005",
    "district_id": "127703",
    "label": "Ujunggurap",
    "value": "Ujunggurap"
  },
  {
    "id": "1277032006",
    "district_id": "127703",
    "label": "Aek Tuhul",
    "value": "Aek Tuhul"
  },
  {
    "id": "1277032007",
    "district_id": "127703",
    "label": "Baruas",
    "value": "Baruas"
  },
  {
    "id": "1277032008",
    "district_id": "127703",
    "label": "Siloting",
    "value": "Siloting"
  },
  {
    "id": "1277032009",
    "district_id": "127703",
    "label": "Aek Bayur",
    "value": "Aek Bayur"
  },
  {
    "id": "1277032010",
    "district_id": "127703",
    "label": "Pudun Jae",
    "value": "Pudun Jae"
  },
  {
    "id": "1277032011",
    "district_id": "127703",
    "label": "Pudun Julu",
    "value": "Pudun Julu"
  },
  {
    "id": "1277032012",
    "district_id": "127703",
    "label": "Batang Bahal",
    "value": "Batang Bahal"
  },
  {
    "id": "1277032013",
    "district_id": "127703",
    "label": "Aek Najaji",
    "value": "Aek Najaji"
  },
  {
    "id": "1277032014",
    "district_id": "127703",
    "label": "Simirik",
    "value": "Simirik"
  },
  {
    "id": "1277032015",
    "district_id": "127703",
    "label": "Bargottopong",
    "value": "Bargottopong"
  },
  {
    "id": "1277041001",
    "district_id": "127704",
    "label": "Hutaimbaru",
    "value": "Hutaimbaru"
  },
  {
    "id": "1277041002",
    "district_id": "127704",
    "label": "Lubuk Raya",
    "value": "Lubuk Raya"
  },
  {
    "id": "1277041003",
    "district_id": "127704",
    "label": "Sabungan Jae",
    "value": "Sabungan Jae"
  },
  {
    "id": "1277041004",
    "district_id": "127704",
    "label": "Lembah Lubuk Manik",
    "value": "Lembah Lubuk Manik"
  },
  {
    "id": "1277041005",
    "district_id": "127704",
    "label": "Palopat Maria",
    "value": "Palopat Maria"
  },
  {
    "id": "1277042006",
    "district_id": "127704",
    "label": "Sabungan Sipabangun",
    "value": "Sabungan Sipabangun"
  },
  {
    "id": "1277042007",
    "district_id": "127704",
    "label": "Singali",
    "value": "Singali"
  },
  {
    "id": "1277042008",
    "district_id": "127704",
    "label": "Huta Padang",
    "value": "Huta Padang"
  },
  {
    "id": "1277042009",
    "district_id": "127704",
    "label": "Partihaman Saroha",
    "value": "Partihaman Saroha"
  },
  {
    "id": "1277042010",
    "district_id": "127704",
    "label": "Tinjoman Lama",
    "value": "Tinjoman Lama"
  },
  {
    "id": "1277051001",
    "district_id": "127705",
    "label": "Sihitang",
    "value": "Sihitang"
  },
  {
    "id": "1277051002",
    "district_id": "127705",
    "label": "Pijor Koling",
    "value": "Pijor Koling"
  },
  {
    "id": "1277052003",
    "district_id": "127705",
    "label": "Palopat Pijor Koling",
    "value": "Palopat Pijor Koling"
  },
  {
    "id": "1277052004",
    "district_id": "127705",
    "label": "Salambue",
    "value": "Salambue"
  },
  {
    "id": "1277052005",
    "district_id": "127705",
    "label": "Purbatua Pijor Koling",
    "value": "Purbatua Pijor Koling"
  },
  {
    "id": "1277052006",
    "district_id": "127705",
    "label": "Sigulang",
    "value": "Sigulang"
  },
  {
    "id": "1277052007",
    "district_id": "127705",
    "label": "Manunggang Julu",
    "value": "Manunggang Julu"
  },
  {
    "id": "1277052008",
    "district_id": "127705",
    "label": "Goti",
    "value": "Goti"
  },
  {
    "id": "1277052009",
    "district_id": "127705",
    "label": "Manegen",
    "value": "Manegen"
  },
  {
    "id": "1277052010",
    "district_id": "127705",
    "label": "Huta Koje",
    "value": "Huta Koje"
  },
  {
    "id": "1277052011",
    "district_id": "127705",
    "label": "Hutalimbong",
    "value": "Hutalimbong"
  },
  {
    "id": "1277052012",
    "district_id": "127705",
    "label": "Huta Padang",
    "value": "Huta Padang"
  },
  {
    "id": "1277052013",
    "district_id": "127705",
    "label": "Perkebunan Pijor Koling",
    "value": "Perkebunan Pijor Koling"
  },
  {
    "id": "1277052014",
    "district_id": "127705",
    "label": "Labuhan Labo",
    "value": "Labuhan Labo"
  },
  {
    "id": "1277052015",
    "district_id": "127705",
    "label": "Huta Lombang",
    "value": "Huta Lombang"
  },
  {
    "id": "1277052016",
    "district_id": "127705",
    "label": "Manunggang Jae",
    "value": "Manunggang Jae"
  },
  {
    "id": "1277052017",
    "district_id": "127705",
    "label": "Labuhan Rasoki",
    "value": "Labuhan Rasoki"
  },
  {
    "id": "1277052018",
    "district_id": "127705",
    "label": "Tarutung Baru",
    "value": "Tarutung Baru"
  },
  {
    "id": "1277062001",
    "district_id": "127706",
    "label": "Joring Lombang",
    "value": "Joring Lombang"
  },
  {
    "id": "1277062002",
    "district_id": "127706",
    "label": "Batu Layan",
    "value": "Batu Layan"
  },
  {
    "id": "1277062003",
    "district_id": "127706",
    "label": "Simasom",
    "value": "Simasom"
  },
  {
    "id": "1277062004",
    "district_id": "127706",
    "label": "Pintu Langit Jae",
    "value": "Pintu Langit Jae"
  },
  {
    "id": "1277062005",
    "district_id": "127706",
    "label": "Mompang",
    "value": "Mompang"
  },
  {
    "id": "1277062006",
    "district_id": "127706",
    "label": "Rimba Soping",
    "value": "Rimba Soping"
  },
  {
    "id": "1277062007",
    "district_id": "127706",
    "label": "Simatohir",
    "value": "Simatohir"
  },
  {
    "id": "1277062008",
    "district_id": "127706",
    "label": "Joring Natobang",
    "value": "Joring Natobang"
  },
  {
    "id": "1278011030",
    "district_id": "127801",
    "label": "Pasar Gunungsitoli",
    "value": "Pasar Gunungsitoli"
  },
  {
    "id": "1278011031",
    "district_id": "127801",
    "label": "Saombo",
    "value": "Saombo"
  },
  {
    "id": "1278011032",
    "district_id": "127801",
    "label": "Ilir",
    "value": "Ilir"
  },
  {
    "id": "1278012001",
    "district_id": "127801",
    "label": "Bawodesolo",
    "value": "Bawodesolo"
  },
  {
    "id": "1278012002",
    "district_id": "127801",
    "label": "Boyo",
    "value": "Boyo"
  },
  {
    "id": "1278012003",
    "district_id": "127801",
    "label": "Dahadano Gawu-Gawu",
    "value": "Dahadano Gawu-Gawu"
  },
  {
    "id": "1278012004",
    "district_id": "127801",
    "label": "Dahana Tabaloho",
    "value": "Dahana Tabaloho"
  },
  {
    "id": "1278012005",
    "district_id": "127801",
    "label": "Fadoro Lasara",
    "value": "Fadoro Lasara"
  },
  {
    "id": "1278012006",
    "district_id": "127801",
    "label": "Hilihao",
    "value": "Hilihao"
  },
  {
    "id": "1278012007",
    "district_id": "127801",
    "label": "Hilimbaruzo",
    "value": "Hilimbaruzo"
  },
  {
    "id": "1278012008",
    "district_id": "127801",
    "label": "Hilina'a",
    "value": "Hilina'a"
  },
  {
    "id": "1278012009",
    "district_id": "127801",
    "label": "Iraonogeba",
    "value": "Iraonogeba"
  },
  {
    "id": "1278012010",
    "district_id": "127801",
    "label": "Lasara Bahili",
    "value": "Lasara Bahili"
  },
  {
    "id": "1278012011",
    "district_id": "127801",
    "label": "Lolowonu Niko'otano",
    "value": "Lolowonu Niko'otano"
  },
  {
    "id": "1278012012",
    "district_id": "127801",
    "label": "Madula",
    "value": "Madula"
  },
  {
    "id": "1278012013",
    "district_id": "127801",
    "label": "Madolaoli",
    "value": "Madolaoli"
  },
  {
    "id": "1278012014",
    "district_id": "127801",
    "label": "Mudik",
    "value": "Mudik"
  },
  {
    "id": "1278012015",
    "district_id": "127801",
    "label": "Miga",
    "value": "Miga"
  },
  {
    "id": "1278012016",
    "district_id": "127801",
    "label": "Mo'awo",
    "value": "Mo'awo"
  },
  {
    "id": "1278012017",
    "district_id": "127801",
    "label": "Ombolata Ulu",
    "value": "Ombolata Ulu"
  },
  {
    "id": "1278012018",
    "district_id": "127801",
    "label": "Onowaembo",
    "value": "Onowaembo"
  },
  {
    "id": "1278012019",
    "district_id": "127801",
    "label": "Onozitoli Olora",
    "value": "Onozitoli Olora"
  },
  {
    "id": "1278012020",
    "district_id": "127801",
    "label": "Onozitoli Sifaoroasi",
    "value": "Onozitoli Sifaoroasi"
  },
  {
    "id": "1278012021",
    "district_id": "127801",
    "label": "Saewe",
    "value": "Saewe"
  },
  {
    "id": "1278012022",
    "district_id": "127801",
    "label": "Sifalaete Tabaloho",
    "value": "Sifalaete Tabaloho"
  },
  {
    "id": "1278012023",
    "district_id": "127801",
    "label": "Sifalaete Ulu",
    "value": "Sifalaete Ulu"
  },
  {
    "id": "1278012024",
    "district_id": "127801",
    "label": "Sihare'o II Tabaloho",
    "value": "Sihare'o II Tabaloho"
  },
  {
    "id": "1278012025",
    "district_id": "127801",
    "label": "Simandraolo",
    "value": "Simandraolo"
  },
  {
    "id": "1278012026",
    "district_id": "127801",
    "label": "Sisarahiligamo",
    "value": "Sisarahiligamo"
  },
  {
    "id": "1278012027",
    "district_id": "127801",
    "label": "Sisarahili Sisambualahe",
    "value": "Sisarahili Sisambualahe"
  },
  {
    "id": "1278012028",
    "district_id": "127801",
    "label": "Sisobahili Tabaloho",
    "value": "Sisobahili Tabaloho"
  },
  {
    "id": "1278012029",
    "district_id": "127801",
    "label": "Tuhemberua Ulu",
    "value": "Tuhemberua Ulu"
  },
  {
    "id": "1278022001",
    "district_id": "127802",
    "label": "Ononamolo I Lot",
    "value": "Ononamolo I Lot"
  },
  {
    "id": "1278022002",
    "district_id": "127802",
    "label": "Fodo",
    "value": "Fodo"
  },
  {
    "id": "1278022003",
    "district_id": "127802",
    "label": "Faekhu",
    "value": "Faekhu"
  },
  {
    "id": "1278022004",
    "district_id": "127802",
    "label": "Lolofaoso Tabaloho",
    "value": "Lolofaoso Tabaloho"
  },
  {
    "id": "1278022005",
    "district_id": "127802",
    "label": "Lolomboli",
    "value": "Lolomboli"
  },
  {
    "id": "1278022006",
    "district_id": "127802",
    "label": "Mazingo Tabaloho",
    "value": "Mazingo Tabaloho"
  },
  {
    "id": "1278022007",
    "district_id": "127802",
    "label": "Onozitoli Tabaloho",
    "value": "Onozitoli Tabaloho"
  },
  {
    "id": "1278022008",
    "district_id": "127802",
    "label": "Lololakha",
    "value": "Lololakha"
  },
  {
    "id": "1278022009",
    "district_id": "127802",
    "label": "Sihare'o I Tabaloho",
    "value": "Sihare'o I Tabaloho"
  },
  {
    "id": "1278022010",
    "district_id": "127802",
    "label": "Ombolata Simenari",
    "value": "Ombolata Simenari"
  },
  {
    "id": "1278022011",
    "district_id": "127802",
    "label": "Hiligara",
    "value": "Hiligara"
  },
  {
    "id": "1278022012",
    "district_id": "127802",
    "label": "Luahalaraga",
    "value": "Luahalaraga"
  },
  {
    "id": "1278022013",
    "district_id": "127802",
    "label": "Sisobahili II Tanose'o",
    "value": "Sisobahili II Tanose'o"
  },
  {
    "id": "1278022014",
    "district_id": "127802",
    "label": "Hiligodu Ombolata",
    "value": "Hiligodu Ombolata"
  },
  {
    "id": "1278022015",
    "district_id": "127802",
    "label": "Tetehosi Ombolata",
    "value": "Tetehosi Ombolata"
  },
  {
    "id": "1278032001",
    "district_id": "127803",
    "label": "Afia",
    "value": "Afia"
  },
  {
    "id": "1278032002",
    "district_id": "127803",
    "label": "Lolo'ana'a Lolomoyo",
    "value": "Lolo'ana'a Lolomoyo"
  },
  {
    "id": "1278032003",
    "district_id": "127803",
    "label": "Lasara Sowu",
    "value": "Lasara Sowu"
  },
  {
    "id": "1278032004",
    "district_id": "127803",
    "label": "Telukbelukar",
    "value": "Telukbelukar"
  },
  {
    "id": "1278032005",
    "district_id": "127803",
    "label": "Tetehosi Afia",
    "value": "Tetehosi Afia"
  },
  {
    "id": "1278032006",
    "district_id": "127803",
    "label": "Hambawa",
    "value": "Hambawa"
  },
  {
    "id": "1278032007",
    "district_id": "127803",
    "label": "Gawu-Gawu Bo'uso",
    "value": "Gawu-Gawu Bo'uso"
  },
  {
    "id": "1278032008",
    "district_id": "127803",
    "label": "Olora",
    "value": "Olora"
  },
  {
    "id": "1278032009",
    "district_id": "127803",
    "label": "Hilimbowo Olora",
    "value": "Hilimbowo Olora"
  },
  {
    "id": "1278032010",
    "district_id": "127803",
    "label": "Hiligodu Ulu",
    "value": "Hiligodu Ulu"
  },
  {
    "id": "1278042001",
    "district_id": "127804",
    "label": "Sifalaete",
    "value": "Sifalaete"
  },
  {
    "id": "1278042002",
    "district_id": "127804",
    "label": "Hilimbawodesolo",
    "value": "Hilimbawodesolo"
  },
  {
    "id": "1278042003",
    "district_id": "127804",
    "label": "Dahana",
    "value": "Dahana"
  },
  {
    "id": "1278042004",
    "district_id": "127804",
    "label": "Bawodesolo",
    "value": "Bawodesolo"
  },
  {
    "id": "1278042005",
    "district_id": "127804",
    "label": "Tuhegeo I",
    "value": "Tuhegeo I"
  },
  {
    "id": "1278042006",
    "district_id": "127804",
    "label": "Simanaere",
    "value": "Simanaere"
  },
  {
    "id": "1278042007",
    "district_id": "127804",
    "label": "Humene",
    "value": "Humene"
  },
  {
    "id": "1278042008",
    "district_id": "127804",
    "label": "Siwalubanua II",
    "value": "Siwalubanua II"
  },
  {
    "id": "1278042009",
    "district_id": "127804",
    "label": "Helefanikha",
    "value": "Helefanikha"
  },
  {
    "id": "1278042010",
    "district_id": "127804",
    "label": "Tetehosi I",
    "value": "Tetehosi I"
  },
  {
    "id": "1278042011",
    "district_id": "127804",
    "label": "Fowa",
    "value": "Fowa"
  },
  {
    "id": "1278042012",
    "district_id": "127804",
    "label": "Binaka",
    "value": "Binaka"
  },
  {
    "id": "1278042013",
    "district_id": "127804",
    "label": "Idanotae",
    "value": "Idanotae"
  },
  {
    "id": "1278042014",
    "district_id": "127804",
    "label": "Tetehosi II",
    "value": "Tetehosi II"
  },
  {
    "id": "1278042015",
    "district_id": "127804",
    "label": "Lolo'ana'a Idanoi",
    "value": "Lolo'ana'a Idanoi"
  },
  {
    "id": "1278042016",
    "district_id": "127804",
    "label": "Siwalubanua I",
    "value": "Siwalubanua I"
  },
  {
    "id": "1278042017",
    "district_id": "127804",
    "label": "Hilihambawa",
    "value": "Hilihambawa"
  },
  {
    "id": "1278042018",
    "district_id": "127804",
    "label": "Awa'ai",
    "value": "Awa'ai"
  },
  {
    "id": "1278042019",
    "district_id": "127804",
    "label": "Lewuoguru Idanoi",
    "value": "Lewuoguru Idanoi"
  },
  {
    "id": "1278042020",
    "district_id": "127804",
    "label": "Fadoro",
    "value": "Fadoro"
  },
  {
    "id": "1278042021",
    "district_id": "127804",
    "label": "Hiliweto Idanoi",
    "value": "Hiliweto Idanoi"
  },
  {
    "id": "1278042022",
    "district_id": "127804",
    "label": "Hilimbowo Idanoi",
    "value": "Hilimbowo Idanoi"
  },
  {
    "id": "1278042023",
    "district_id": "127804",
    "label": "Onowaembo",
    "value": "Onowaembo"
  },
  {
    "id": "1278042024",
    "district_id": "127804",
    "label": "Ombolata",
    "value": "Ombolata"
  },
  {
    "id": "1278042025",
    "district_id": "127804",
    "label": "Samasi",
    "value": "Samasi"
  },
  {
    "id": "1278042026",
    "district_id": "127804",
    "label": "Tuhegeo II",
    "value": "Tuhegeo II"
  },
  {
    "id": "1278052001",
    "district_id": "127805",
    "label": "Nazalou Alo'oa",
    "value": "Nazalou Alo'oa"
  },
  {
    "id": "1278052002",
    "district_id": "127805",
    "label": "Niko'otano Dao",
    "value": "Niko'otano Dao"
  },
  {
    "id": "1278052003",
    "district_id": "127805",
    "label": "Iraonolase",
    "value": "Iraonolase"
  },
  {
    "id": "1278052004",
    "district_id": "127805",
    "label": "Orahili Tanose'o",
    "value": "Orahili Tanose'o"
  },
  {
    "id": "1278052005",
    "district_id": "127805",
    "label": "Tarakhaini",
    "value": "Tarakhaini"
  },
  {
    "id": "1278052006",
    "district_id": "127805",
    "label": "Fadoro Hilimbowo",
    "value": "Fadoro Hilimbowo"
  },
  {
    "id": "1278052007",
    "district_id": "127805",
    "label": "Fadoro You",
    "value": "Fadoro You"
  },
  {
    "id": "1278052008",
    "district_id": "127805",
    "label": "Lololawa",
    "value": "Lololawa"
  },
  {
    "id": "1278052009",
    "district_id": "127805",
    "label": "Nazalou Lolowua",
    "value": "Nazalou Lolowua"
  },
  {
    "id": "1278062001",
    "district_id": "127806",
    "label": "Tumori",
    "value": "Tumori"
  },
  {
    "id": "1278062002",
    "district_id": "127806",
    "label": "Tumori Balohili",
    "value": "Tumori Balohili"
  },
  {
    "id": "1278062003",
    "district_id": "127806",
    "label": "Orahili Tumori",
    "value": "Orahili Tumori"
  },
  {
    "id": "1278062004",
    "district_id": "127806",
    "label": "Gada",
    "value": "Gada"
  },
  {
    "id": "1278062005",
    "district_id": "127806",
    "label": "Lolomoyo Tuhemberua",
    "value": "Lolomoyo Tuhemberua"
  },
  {
    "id": "1278062006",
    "district_id": "127806",
    "label": "Sihare'o Siwahili",
    "value": "Sihare'o Siwahili"
  },
  {
    "id": "1278062007",
    "district_id": "127806",
    "label": "Hilinakhe",
    "value": "Hilinakhe"
  },
  {
    "id": "1278062008",
    "district_id": "127806",
    "label": "Onozikho",
    "value": "Onozikho"
  },
  {
    "id": "1278062009",
    "district_id": "127806",
    "label": "Ononamolo II Lot",
    "value": "Ononamolo II Lot"
  },
  {
    "id": "1301012001",
    "district_id": "130101",
    "label": "Inderapura",
    "value": "Inderapura"
  },
  {
    "id": "1301012002",
    "district_id": "130101",
    "label": "Muaro Sakai Inderapura",
    "value": "Muaro Sakai Inderapura"
  },
  {
    "id": "1301012003",
    "district_id": "130101",
    "label": "Tiga Sepakat Inderapura",
    "value": "Tiga Sepakat Inderapura"
  },
  {
    "id": "1301012004",
    "district_id": "130101",
    "label": "Inderapura Barat",
    "value": "Inderapura Barat"
  },
  {
    "id": "1301012005",
    "district_id": "130101",
    "label": "Kudo-Kudo Inderapura",
    "value": "Kudo-Kudo Inderapura"
  },
  {
    "id": "1301012006",
    "district_id": "130101",
    "label": "Inderapura Selatan",
    "value": "Inderapura Selatan"
  },
  {
    "id": "1301012009",
    "district_id": "130101",
    "label": "Simpang Lama Inderapura",
    "value": "Simpang Lama Inderapura"
  },
  {
    "id": "1301012010",
    "district_id": "130101",
    "label": "Tigo Sungai Inderapura",
    "value": "Tigo Sungai Inderapura"
  },
  {
    "id": "1301012011",
    "district_id": "130101",
    "label": "Tluk Amplu Inderapura",
    "value": "Tluk Amplu Inderapura"
  },
  {
    "id": "1301012012",
    "district_id": "130101",
    "label": "Inderapura Tengah",
    "value": "Inderapura Tengah"
  },
  {
    "id": "1301022001",
    "district_id": "130102",
    "label": "Pelangai",
    "value": "Pelangai"
  },
  {
    "id": "1301022002",
    "district_id": "130102",
    "label": "Sungai Tunu",
    "value": "Sungai Tunu"
  },
  {
    "id": "1301022003",
    "district_id": "130102",
    "label": "Sungai Tunu Utara",
    "value": "Sungai Tunu Utara"
  },
  {
    "id": "1301022004",
    "district_id": "130102",
    "label": "Sungai Tunu Barat",
    "value": "Sungai Tunu Barat"
  },
  {
    "id": "1301022005",
    "district_id": "130102",
    "label": "Nyiur Melambai Pelangai",
    "value": "Nyiur Melambai Pelangai"
  },
  {
    "id": "1301022006",
    "district_id": "130102",
    "label": "Sungai Liku Pelangai",
    "value": "Sungai Liku Pelangai"
  },
  {
    "id": "1301022007",
    "district_id": "130102",
    "label": "Koto VIII Pelangai",
    "value": "Koto VIII Pelangai"
  },
  {
    "id": "1301022008",
    "district_id": "130102",
    "label": "Pelangai Gadang",
    "value": "Pelangai Gadang"
  },
  {
    "id": "1301022009",
    "district_id": "130102",
    "label": "Pelangai Kaciak",
    "value": "Pelangai Kaciak"
  },
  {
    "id": "1301022010",
    "district_id": "130102",
    "label": "Pasia Pelangai",
    "value": "Pasia Pelangai"
  },
  {
    "id": "1301032001",
    "district_id": "130103",
    "label": "Kambang",
    "value": "Kambang"
  },
  {
    "id": "1301032002",
    "district_id": "130103",
    "label": "Lakitan",
    "value": "Lakitan"
  },
  {
    "id": "1301032003",
    "district_id": "130103",
    "label": "Kambang Utara",
    "value": "Kambang Utara"
  },
  {
    "id": "1301032004",
    "district_id": "130103",
    "label": "Kambang Timur",
    "value": "Kambang Timur"
  },
  {
    "id": "1301032005",
    "district_id": "130103",
    "label": "Kambang Barat",
    "value": "Kambang Barat"
  },
  {
    "id": "1301032006",
    "district_id": "130103",
    "label": "Lakitan Utara",
    "value": "Lakitan Utara"
  },
  {
    "id": "1301032007",
    "district_id": "130103",
    "label": "Lakitan Selatan",
    "value": "Lakitan Selatan"
  },
  {
    "id": "1301032008",
    "district_id": "130103",
    "label": "Lakitan Timur",
    "value": "Lakitan Timur"
  },
  {
    "id": "1301032009",
    "district_id": "130103",
    "label": "Lakitan Tengah",
    "value": "Lakitan Tengah"
  },
  {
    "id": "1301042001",
    "district_id": "130104",
    "label": "IV Koto Hilie",
    "value": "IV Koto Hilie"
  },
  {
    "id": "1301042002",
    "district_id": "130104",
    "label": "IV Koto Mudiek",
    "value": "IV Koto Mudiek"
  },
  {
    "id": "1301042003",
    "district_id": "130104",
    "label": "Taluak",
    "value": "Taluak"
  },
  {
    "id": "1301042004",
    "district_id": "130104",
    "label": "Koto Nan Duo IV Koto Hilie",
    "value": "Koto Nan Duo IV Koto Hilie"
  },
  {
    "id": "1301042005",
    "district_id": "130104",
    "label": "Koto Nan Tigo IV Koto Hilie",
    "value": "Koto Nan Tigo IV Koto Hilie"
  },
  {
    "id": "1301042006",
    "district_id": "130104",
    "label": "Teratak Tempatih IV Koto Mudiek",
    "value": "Teratak Tempatih IV Koto Mudiek"
  },
  {
    "id": "1301042007",
    "district_id": "130104",
    "label": "Tuik IV Koto Mudiek",
    "value": "Tuik IV Koto Mudiek"
  },
  {
    "id": "1301042008",
    "district_id": "130104",
    "label": "Sungai Nyalo IV Koto Mudiek",
    "value": "Sungai Nyalo IV Koto Mudiek"
  },
  {
    "id": "1301042009",
    "district_id": "130104",
    "label": "Taluk Tigo Sakato",
    "value": "Taluk Tigo Sakato"
  },
  {
    "id": "1301052001",
    "district_id": "130105",
    "label": "Painan",
    "value": "Painan"
  },
  {
    "id": "1301052002",
    "district_id": "130105",
    "label": "Tambang",
    "value": "Tambang"
  },
  {
    "id": "1301052003",
    "district_id": "130105",
    "label": "Salido",
    "value": "Salido"
  },
  {
    "id": "1301052004",
    "district_id": "130105",
    "label": "Lumpo",
    "value": "Lumpo"
  },
  {
    "id": "1301052005",
    "district_id": "130105",
    "label": "Bunga Pasang Salido",
    "value": "Bunga Pasang Salido"
  },
  {
    "id": "1301052006",
    "district_id": "130105",
    "label": "Sago Salido",
    "value": "Sago Salido"
  },
  {
    "id": "1301052007",
    "district_id": "130105",
    "label": "Salido Sari Bulan",
    "value": "Salido Sari Bulan"
  },
  {
    "id": "1301052008",
    "district_id": "130105",
    "label": "Koto Rawang",
    "value": "Koto Rawang"
  },
  {
    "id": "1301052009",
    "district_id": "130105",
    "label": "Limau Gadang Lumpo",
    "value": "Limau Gadang Lumpo"
  },
  {
    "id": "1301052010",
    "district_id": "130105",
    "label": "Batu Kunik Lumpo",
    "value": "Batu Kunik Lumpo"
  },
  {
    "id": "1301052011",
    "district_id": "130105",
    "label": "Taratak Tangah Lumpo",
    "value": "Taratak Tangah Lumpo"
  },
  {
    "id": "1301052012",
    "district_id": "130105",
    "label": "Ampuan Lumpo",
    "value": "Ampuan Lumpo"
  },
  {
    "id": "1301052013",
    "district_id": "130105",
    "label": "Ampang Tareh Lumpo",
    "value": "Ampang Tareh Lumpo"
  },
  {
    "id": "1301052014",
    "district_id": "130105",
    "label": "Balai Sinayan Lumpo",
    "value": "Balai Sinayan Lumpo"
  },
  {
    "id": "1301052015",
    "district_id": "130105",
    "label": "Sungai Gayo Lumpo",
    "value": "Sungai Gayo Lumpo"
  },
  {
    "id": "1301052016",
    "district_id": "130105",
    "label": "Sungai Sariak Lumpo",
    "value": "Sungai Sariak Lumpo"
  },
  {
    "id": "1301052017",
    "district_id": "130105",
    "label": "Bukik Kaciak Lumpo",
    "value": "Bukik Kaciak Lumpo"
  },
  {
    "id": "1301052018",
    "district_id": "130105",
    "label": "Gunung Bungkuak Lumpo",
    "value": "Gunung Bungkuak Lumpo"
  },
  {
    "id": "1301052019",
    "district_id": "130105",
    "label": "Painan Selatan Painan",
    "value": "Painan Selatan Painan"
  },
  {
    "id": "1301052020",
    "district_id": "130105",
    "label": "Painan Timur Painan",
    "value": "Painan Timur Painan"
  },
  {
    "id": "1301062005",
    "district_id": "130106",
    "label": "Koto Berapak",
    "value": "Koto Berapak"
  },
  {
    "id": "1301062006",
    "district_id": "130106",
    "label": "Talaok",
    "value": "Talaok"
  },
  {
    "id": "1301062007",
    "district_id": "130106",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1301062008",
    "district_id": "130106",
    "label": "Gurun Panjang",
    "value": "Gurun Panjang"
  },
  {
    "id": "1301062009",
    "district_id": "130106",
    "label": "Api-api Pasar Baru",
    "value": "Api-api Pasar Baru"
  },
  {
    "id": "1301062010",
    "district_id": "130106",
    "label": "Tanjung Durian Pasar Baru",
    "value": "Tanjung Durian Pasar Baru"
  },
  {
    "id": "1301062011",
    "district_id": "130106",
    "label": "Asam Kamba Pasar Baru",
    "value": "Asam Kamba Pasar Baru"
  },
  {
    "id": "1301062012",
    "district_id": "130106",
    "label": "Sawah Laweh Pasar Baru",
    "value": "Sawah Laweh Pasar Baru"
  },
  {
    "id": "1301062013",
    "district_id": "130106",
    "label": "Kapeh Panji Jaya Talaok",
    "value": "Kapeh Panji Jaya Talaok"
  },
  {
    "id": "1301062014",
    "district_id": "130106",
    "label": "Aur Begalung Talaok",
    "value": "Aur Begalung Talaok"
  },
  {
    "id": "1301062015",
    "district_id": "130106",
    "label": "Kapelgam Koto Berapak",
    "value": "Kapelgam Koto Berapak"
  },
  {
    "id": "1301062016",
    "district_id": "130106",
    "label": "Koto Baru Koto Berapak",
    "value": "Koto Baru Koto Berapak"
  },
  {
    "id": "1301062017",
    "district_id": "130106",
    "label": "Kubang Koto Berapak",
    "value": "Kubang Koto Berapak"
  },
  {
    "id": "1301062018",
    "district_id": "130106",
    "label": "Kapujan Koto Berapak",
    "value": "Kapujan Koto Berapak"
  },
  {
    "id": "1301062019",
    "district_id": "130106",
    "label": "Gurun Panjang Utara",
    "value": "Gurun Panjang Utara"
  },
  {
    "id": "1301062020",
    "district_id": "130106",
    "label": "Gurun Panjang Barat",
    "value": "Gurun Panjang Barat"
  },
  {
    "id": "1301062021",
    "district_id": "130106",
    "label": "Gurun Panjang Selatan",
    "value": "Gurun Panjang Selatan"
  },
  {
    "id": "1301072001",
    "district_id": "130107",
    "label": "Siguntur",
    "value": "Siguntur"
  },
  {
    "id": "1301072002",
    "district_id": "130107",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1301072003",
    "district_id": "130107",
    "label": "Duku",
    "value": "Duku"
  },
  {
    "id": "1301072004",
    "district_id": "130107",
    "label": "Batu Hampa",
    "value": "Batu Hampa"
  },
  {
    "id": "1301072005",
    "district_id": "130107",
    "label": "Nanggalo",
    "value": "Nanggalo"
  },
  {
    "id": "1301072006",
    "district_id": "130107",
    "label": "Ampang Pulai",
    "value": "Ampang Pulai"
  },
  {
    "id": "1301072007",
    "district_id": "130107",
    "label": "Kapuh",
    "value": "Kapuh"
  },
  {
    "id": "1301072008",
    "district_id": "130107",
    "label": "Barung-Barung Balantai",
    "value": "Barung-Barung Balantai"
  },
  {
    "id": "1301072009",
    "district_id": "130107",
    "label": "Barung-Barung Balantai Selatan",
    "value": "Barung-Barung Balantai Selatan"
  },
  {
    "id": "1301072010",
    "district_id": "130107",
    "label": "Mandeh",
    "value": "Mandeh"
  },
  {
    "id": "1301072011",
    "district_id": "130107",
    "label": "Kapuah Utara",
    "value": "Kapuah Utara"
  },
  {
    "id": "1301072012",
    "district_id": "130107",
    "label": "Taratak Sungai Lundang",
    "value": "Taratak Sungai Lundang"
  },
  {
    "id": "1301072013",
    "district_id": "130107",
    "label": "Siguntur Tua",
    "value": "Siguntur Tua"
  },
  {
    "id": "1301072014",
    "district_id": "130107",
    "label": "Kampung Baru Korong Nan Ampek",
    "value": "Kampung Baru Korong Nan Ampek"
  },
  {
    "id": "1301072015",
    "district_id": "130107",
    "label": "Duku Utara",
    "value": "Duku Utara"
  },
  {
    "id": "1301072016",
    "district_id": "130107",
    "label": "Pulau Karam Ampang Pulai",
    "value": "Pulau Karam Ampang Pulai"
  },
  {
    "id": "1301072017",
    "district_id": "130107",
    "label": "Jinang Kampung Pansur Ampang Pulai",
    "value": "Jinang Kampung Pansur Ampang Pulai"
  },
  {
    "id": "1301072018",
    "district_id": "130107",
    "label": "Cerocok Anau Ampang Pulai",
    "value": "Cerocok Anau Ampang Pulai"
  },
  {
    "id": "1301072019",
    "district_id": "130107",
    "label": "Sungai Nyalo Mudiak Aia",
    "value": "Sungai Nyalo Mudiak Aia"
  },
  {
    "id": "1301072020",
    "district_id": "130107",
    "label": "Batu Hampar Selatan",
    "value": "Batu Hampar Selatan"
  },
  {
    "id": "1301072021",
    "district_id": "130107",
    "label": "Barung-Barung Balantai Timur",
    "value": "Barung-Barung Balantai Timur"
  },
  {
    "id": "1301072022",
    "district_id": "130107",
    "label": "Barung-Barung Balantai Tengah",
    "value": "Barung-Barung Balantai Tengah"
  },
  {
    "id": "1301072023",
    "district_id": "130107",
    "label": "Setara Nanggalo",
    "value": "Setara Nanggalo"
  },
  {
    "id": "1301082001",
    "district_id": "130108",
    "label": "Taratak",
    "value": "Taratak"
  },
  {
    "id": "1301082002",
    "district_id": "130108",
    "label": "Surantih",
    "value": "Surantih"
  },
  {
    "id": "1301082003",
    "district_id": "130108",
    "label": "Amping Parak",
    "value": "Amping Parak"
  },
  {
    "id": "1301082004",
    "district_id": "130108",
    "label": "Amping Parak Timur",
    "value": "Amping Parak Timur"
  },
  {
    "id": "1301082005",
    "district_id": "130108",
    "label": "Koto Taratak",
    "value": "Koto Taratak"
  },
  {
    "id": "1301082006",
    "district_id": "130108",
    "label": "Lansano Taratak",
    "value": "Lansano Taratak"
  },
  {
    "id": "1301082007",
    "district_id": "130108",
    "label": "Aur Duri Surantih",
    "value": "Aur Duri Surantih"
  },
  {
    "id": "1301082008",
    "district_id": "130108",
    "label": "Rawang Gunung Malelo Surantih",
    "value": "Rawang Gunung Malelo Surantih"
  },
  {
    "id": "1301082009",
    "district_id": "130108",
    "label": "Koto Nan Tigo Selatan Surantih",
    "value": "Koto Nan Tigo Selatan Surantih"
  },
  {
    "id": "1301082010",
    "district_id": "130108",
    "label": "Koto Nan Tigo Utara Surantih",
    "value": "Koto Nan Tigo Utara Surantih"
  },
  {
    "id": "1301082011",
    "district_id": "130108",
    "label": "Ganting Mudiak Selatan Surantih",
    "value": "Ganting Mudiak Selatan Surantih"
  },
  {
    "id": "1301082012",
    "district_id": "130108",
    "label": "Ganting Mudiak Utara Surantih",
    "value": "Ganting Mudiak Utara Surantih"
  },
  {
    "id": "1301092001",
    "district_id": "130109",
    "label": "Punggasan",
    "value": "Punggasan"
  },
  {
    "id": "1301092002",
    "district_id": "130109",
    "label": "Air Haji",
    "value": "Air Haji"
  },
  {
    "id": "1301092003",
    "district_id": "130109",
    "label": "Punggasan Utara",
    "value": "Punggasan Utara"
  },
  {
    "id": "1301092004",
    "district_id": "130109",
    "label": "Punggasan Timur",
    "value": "Punggasan Timur"
  },
  {
    "id": "1301092005",
    "district_id": "130109",
    "label": "Padang XI Punggasan",
    "value": "Padang XI Punggasan"
  },
  {
    "id": "1301092006",
    "district_id": "130109",
    "label": "Lagan Mudik Punggasan",
    "value": "Lagan Mudik Punggasan"
  },
  {
    "id": "1301092007",
    "district_id": "130109",
    "label": "Lagan Hilir Punggasan",
    "value": "Lagan Hilir Punggasan"
  },
  {
    "id": "1301092008",
    "district_id": "130109",
    "label": "Air Haji Tenggara",
    "value": "Air Haji Tenggara"
  },
  {
    "id": "1301092009",
    "district_id": "130109",
    "label": "Pasar Lama Muara Air Haji",
    "value": "Pasar Lama Muara Air Haji"
  },
  {
    "id": "1301092010",
    "district_id": "130109",
    "label": "Pasar Bukit Air Haji",
    "value": "Pasar Bukit Air Haji"
  },
  {
    "id": "1301092011",
    "district_id": "130109",
    "label": "Air Haji Barat",
    "value": "Air Haji Barat"
  },
  {
    "id": "1301092012",
    "district_id": "130109",
    "label": "Air Haji Tengah",
    "value": "Air Haji Tengah"
  },
  {
    "id": "1301092013",
    "district_id": "130109",
    "label": "Rantau Simalenang Air Haji",
    "value": "Rantau Simalenang Air Haji"
  },
  {
    "id": "1301092014",
    "district_id": "130109",
    "label": "Muara Gadang Air Haji",
    "value": "Muara Gadang Air Haji"
  },
  {
    "id": "1301092015",
    "district_id": "130109",
    "label": "Sungai Sirah Air Haji",
    "value": "Sungai Sirah Air Haji"
  },
  {
    "id": "1301092016",
    "district_id": "130109",
    "label": "Muara Kandis Punggasan",
    "value": "Muara Kandis Punggasan"
  },
  {
    "id": "1301102001",
    "district_id": "130110",
    "label": "Lunang",
    "value": "Lunang"
  },
  {
    "id": "1301102003",
    "district_id": "130110",
    "label": "Lunang Utara",
    "value": "Lunang Utara"
  },
  {
    "id": "1301102004",
    "district_id": "130110",
    "label": "Lunang Selatan",
    "value": "Lunang Selatan"
  },
  {
    "id": "1301102005",
    "district_id": "130110",
    "label": "Lunang Barat",
    "value": "Lunang Barat"
  },
  {
    "id": "1301102015",
    "district_id": "130110",
    "label": "Sindang Lunang",
    "value": "Sindang Lunang"
  },
  {
    "id": "1301102016",
    "district_id": "130110",
    "label": "Pondok Parian Lunang",
    "value": "Pondok Parian Lunang"
  },
  {
    "id": "1301102017",
    "district_id": "130110",
    "label": "Lunang Tengah",
    "value": "Lunang Tengah"
  },
  {
    "id": "1301102018",
    "district_id": "130110",
    "label": "Lunang Satu",
    "value": "Lunang Satu"
  },
  {
    "id": "1301102019",
    "district_id": "130110",
    "label": "Lunang Dua",
    "value": "Lunang Dua"
  },
  {
    "id": "1301102020",
    "district_id": "130110",
    "label": "Lunang Tiga",
    "value": "Lunang Tiga"
  },
  {
    "id": "1301112001",
    "district_id": "130111",
    "label": "Tapan",
    "value": "Tapan"
  },
  {
    "id": "1301112002",
    "district_id": "130111",
    "label": "Pasar Tapan",
    "value": "Pasar Tapan"
  },
  {
    "id": "1301112007",
    "district_id": "130111",
    "label": "Batang Arah Tapan",
    "value": "Batang Arah Tapan"
  },
  {
    "id": "1301112008",
    "district_id": "130111",
    "label": "Ampang Tulak Tapan",
    "value": "Ampang Tulak Tapan"
  },
  {
    "id": "1301112009",
    "district_id": "130111",
    "label": "Tanjung Pondok Tapan",
    "value": "Tanjung Pondok Tapan"
  },
  {
    "id": "1301112010",
    "district_id": "130111",
    "label": "Batang Betung Tapan",
    "value": "Batang Betung Tapan"
  },
  {
    "id": "1301112016",
    "district_id": "130111",
    "label": "Bukit Buai Tapan",
    "value": "Bukit Buai Tapan"
  },
  {
    "id": "1301112017",
    "district_id": "130111",
    "label": "Riak Danau Tapan",
    "value": "Riak Danau Tapan"
  },
  {
    "id": "1301112018",
    "district_id": "130111",
    "label": "Koto Anau Tapan",
    "value": "Koto Anau Tapan"
  },
  {
    "id": "1301112019",
    "district_id": "130111",
    "label": "Dusun Baru Tapan",
    "value": "Dusun Baru Tapan"
  },
  {
    "id": "1301122001",
    "district_id": "130112",
    "label": "Puluik-Puluik",
    "value": "Puluik-Puluik"
  },
  {
    "id": "1301122002",
    "district_id": "130112",
    "label": "Koto Ranah",
    "value": "Koto Ranah"
  },
  {
    "id": "1301122003",
    "district_id": "130112",
    "label": "Muaro Aie",
    "value": "Muaro Aie"
  },
  {
    "id": "1301122004",
    "district_id": "130112",
    "label": "Pancuang Taba",
    "value": "Pancuang Taba"
  },
  {
    "id": "1301122005",
    "district_id": "130112",
    "label": "Puluik-Puluik Selatan",
    "value": "Puluik-Puluik Selatan"
  },
  {
    "id": "1301122006",
    "district_id": "130112",
    "label": "Limau Gadang Pancung Taba",
    "value": "Limau Gadang Pancung Taba"
  },
  {
    "id": "1301132001",
    "district_id": "130113",
    "label": "Inderapura Utara",
    "value": "Inderapura Utara"
  },
  {
    "id": "1301132002",
    "district_id": "130113",
    "label": "Muara Inderapura",
    "value": "Muara Inderapura"
  },
  {
    "id": "1301132003",
    "district_id": "130113",
    "label": "Damar Lapan Batang Inderapura",
    "value": "Damar Lapan Batang Inderapura"
  },
  {
    "id": "1301132004",
    "district_id": "130113",
    "label": "Lalang Panjang Inderapura",
    "value": "Lalang Panjang Inderapura"
  },
  {
    "id": "1301132005",
    "district_id": "130113",
    "label": "Palokan Inderapura",
    "value": "Palokan Inderapura"
  },
  {
    "id": "1301132006",
    "district_id": "130113",
    "label": "Lubuk Betung Inderapura",
    "value": "Lubuk Betung Inderapura"
  },
  {
    "id": "1301132007",
    "district_id": "130113",
    "label": "Tluk Kualo Inderapura",
    "value": "Tluk Kualo Inderapura"
  },
  {
    "id": "1301132008",
    "district_id": "130113",
    "label": "Tanah Bakali Inderapura",
    "value": "Tanah Bakali Inderapura"
  },
  {
    "id": "1301132009",
    "district_id": "130113",
    "label": "Inderapura Timur",
    "value": "Inderapura Timur"
  },
  {
    "id": "1301132010",
    "district_id": "130113",
    "label": "Pulau Rajo Inderapura",
    "value": "Pulau Rajo Inderapura"
  },
  {
    "id": "1301142001",
    "district_id": "130114",
    "label": "Sungai Gambir Sako Tapan",
    "value": "Sungai Gambir Sako Tapan"
  },
  {
    "id": "1301142002",
    "district_id": "130114",
    "label": "Limau Purut Tapan",
    "value": "Limau Purut Tapan"
  },
  {
    "id": "1301142003",
    "district_id": "130114",
    "label": "Talang Balarik Tapan",
    "value": "Talang Balarik Tapan"
  },
  {
    "id": "1301142004",
    "district_id": "130114",
    "label": "Tebing Tinggi Tapan",
    "value": "Tebing Tinggi Tapan"
  },
  {
    "id": "1301142005",
    "district_id": "130114",
    "label": "Binjai Tapan",
    "value": "Binjai Tapan"
  },
  {
    "id": "1301142006",
    "district_id": "130114",
    "label": "Sungai Pinang Tapan",
    "value": "Sungai Pinang Tapan"
  },
  {
    "id": "1301142007",
    "district_id": "130114",
    "label": "Talang Koto Pulai",
    "value": "Talang Koto Pulai"
  },
  {
    "id": "1301142008",
    "district_id": "130114",
    "label": "Kampung Tengah Tapan",
    "value": "Kampung Tengah Tapan"
  },
  {
    "id": "1301142009",
    "district_id": "130114",
    "label": "Kubu Tapan",
    "value": "Kubu Tapan"
  },
  {
    "id": "1301142010",
    "district_id": "130114",
    "label": "Simpang Gunung Tapan",
    "value": "Simpang Gunung Tapan"
  },
  {
    "id": "1301152001",
    "district_id": "130115",
    "label": "Silaut",
    "value": "Silaut"
  },
  {
    "id": "1301152002",
    "district_id": "130115",
    "label": "Sungai Sirah",
    "value": "Sungai Sirah"
  },
  {
    "id": "1301152003",
    "district_id": "130115",
    "label": "Sungai Sarik",
    "value": "Sungai Sarik"
  },
  {
    "id": "1301152004",
    "district_id": "130115",
    "label": "Sungai Pulai",
    "value": "Sungai Pulai"
  },
  {
    "id": "1301152005",
    "district_id": "130115",
    "label": "Pasir Binjai",
    "value": "Pasir Binjai"
  },
  {
    "id": "1301152006",
    "district_id": "130115",
    "label": "Talang Binjai",
    "value": "Talang Binjai"
  },
  {
    "id": "1301152007",
    "district_id": "130115",
    "label": "Durian Seribu",
    "value": "Durian Seribu"
  },
  {
    "id": "1301152008",
    "district_id": "130115",
    "label": "Lubuk Bunta",
    "value": "Lubuk Bunta"
  },
  {
    "id": "1301152009",
    "district_id": "130115",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1301152010",
    "district_id": "130115",
    "label": "Sambungo",
    "value": "Sambungo"
  },
  {
    "id": "1302032001",
    "district_id": "130203",
    "label": "Lolo",
    "value": "Lolo"
  },
  {
    "id": "1302032002",
    "district_id": "130203",
    "label": "Surian",
    "value": "Surian"
  },
  {
    "id": "1302042001",
    "district_id": "130204",
    "label": "Alahan Panjang",
    "value": "Alahan Panjang"
  },
  {
    "id": "1302042002",
    "district_id": "130204",
    "label": "Sungai Nanam",
    "value": "Sungai Nanam"
  },
  {
    "id": "1302042003",
    "district_id": "130204",
    "label": "Salimpat",
    "value": "Salimpat"
  },
  {
    "id": "1302042004",
    "district_id": "130204",
    "label": "Aie Dingin",
    "value": "Aie Dingin"
  },
  {
    "id": "1302052001",
    "district_id": "130205",
    "label": "Supayang",
    "value": "Supayang"
  },
  {
    "id": "1302052002",
    "district_id": "130205",
    "label": "Sirukam",
    "value": "Sirukam"
  },
  {
    "id": "1302052003",
    "district_id": "130205",
    "label": "Aie Luo",
    "value": "Aie Luo"
  },
  {
    "id": "1302062001",
    "district_id": "130206",
    "label": "Batu Banyak",
    "value": "Batu Banyak"
  },
  {
    "id": "1302062002",
    "district_id": "130206",
    "label": "Batu Bajanjang",
    "value": "Batu Bajanjang"
  },
  {
    "id": "1302062003",
    "district_id": "130206",
    "label": "Koto Laweh",
    "value": "Koto Laweh"
  },
  {
    "id": "1302062004",
    "district_id": "130206",
    "label": "Limau Lunggo",
    "value": "Limau Lunggo"
  },
  {
    "id": "1302062005",
    "district_id": "130206",
    "label": "Koto Gadang Koto Anau",
    "value": "Koto Gadang Koto Anau"
  },
  {
    "id": "1302062006",
    "district_id": "130206",
    "label": "Selayo Tanang B. Sileh",
    "value": "Selayo Tanang B. Sileh"
  },
  {
    "id": "1302072001",
    "district_id": "130207",
    "label": "Cupak",
    "value": "Cupak"
  },
  {
    "id": "1302072002",
    "district_id": "130207",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "1302072003",
    "district_id": "130207",
    "label": "Koto Gadang Guguak",
    "value": "Koto Gadang Guguak"
  },
  {
    "id": "1302072004",
    "district_id": "130207",
    "label": "Jawi Jawi",
    "value": "Jawi Jawi"
  },
  {
    "id": "1302072005",
    "district_id": "130207",
    "label": "Sungai Janiah",
    "value": "Sungai Janiah"
  },
  {
    "id": "1302072006",
    "district_id": "130207",
    "label": "Batang Barus",
    "value": "Batang Barus"
  },
  {
    "id": "1302072007",
    "district_id": "130207",
    "label": "Aie Batumbuak",
    "value": "Aie Batumbuak"
  },
  {
    "id": "1302072008",
    "district_id": "130207",
    "label": "Koto Gaek Guguk",
    "value": "Koto Gaek Guguk"
  },
  {
    "id": "1302082001",
    "district_id": "130208",
    "label": "Muaro Paneh",
    "value": "Muaro Paneh"
  },
  {
    "id": "1302082002",
    "district_id": "130208",
    "label": "Kinari",
    "value": "Kinari"
  },
  {
    "id": "1302082003",
    "district_id": "130208",
    "label": "Bukit Tandang",
    "value": "Bukit Tandang"
  },
  {
    "id": "1302082004",
    "district_id": "130208",
    "label": "Parambahan",
    "value": "Parambahan"
  },
  {
    "id": "1302082005",
    "district_id": "130208",
    "label": "Dilam",
    "value": "Dilam"
  },
  {
    "id": "1302092001",
    "district_id": "130209",
    "label": "Taruang-Taruang",
    "value": "Taruang-Taruang"
  },
  {
    "id": "1302092002",
    "district_id": "130209",
    "label": "Siaro-Aro",
    "value": "Siaro-Aro"
  },
  {
    "id": "1302092003",
    "district_id": "130209",
    "label": "Pianggu",
    "value": "Pianggu"
  },
  {
    "id": "1302092004",
    "district_id": "130209",
    "label": "Sungai Durian",
    "value": "Sungai Durian"
  },
  {
    "id": "1302092005",
    "district_id": "130209",
    "label": "Bukik Bais",
    "value": "Bukik Bais"
  },
  {
    "id": "1302092006",
    "district_id": "130209",
    "label": "Sungai Jambur",
    "value": "Sungai Jambur"
  },
  {
    "id": "1302092007",
    "district_id": "130209",
    "label": "Guguak Sarai",
    "value": "Guguak Sarai"
  },
  {
    "id": "1302092008",
    "district_id": "130209",
    "label": "Koto Laweh",
    "value": "Koto Laweh"
  },
  {
    "id": "1302092009",
    "district_id": "130209",
    "label": "Indudur",
    "value": "Indudur"
  },
  {
    "id": "1302102001",
    "district_id": "130210",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1302102002",
    "district_id": "130210",
    "label": "Selayo",
    "value": "Selayo"
  },
  {
    "id": "1302102003",
    "district_id": "130210",
    "label": "Panyakalan",
    "value": "Panyakalan"
  },
  {
    "id": "1302102004",
    "district_id": "130210",
    "label": "Gaung",
    "value": "Gaung"
  },
  {
    "id": "1302102005",
    "district_id": "130210",
    "label": "Gantuang Ciri",
    "value": "Gantuang Ciri"
  },
  {
    "id": "1302102006",
    "district_id": "130210",
    "label": "Saok Laweh",
    "value": "Saok Laweh"
  },
  {
    "id": "1302102007",
    "district_id": "130210",
    "label": "Koto Hilalang",
    "value": "Koto Hilalang"
  },
  {
    "id": "1302102008",
    "district_id": "130210",
    "label": "Tanjung Bingkung",
    "value": "Tanjung Bingkung"
  },
  {
    "id": "1302112001",
    "district_id": "130211",
    "label": "Sumani",
    "value": "Sumani"
  },
  {
    "id": "1302112002",
    "district_id": "130211",
    "label": "Tikalak",
    "value": "Tikalak"
  },
  {
    "id": "1302112003",
    "district_id": "130211",
    "label": "Koto Sani",
    "value": "Koto Sani"
  },
  {
    "id": "1302112004",
    "district_id": "130211",
    "label": "Singkarak",
    "value": "Singkarak"
  },
  {
    "id": "1302112005",
    "district_id": "130211",
    "label": "Aripan",
    "value": "Aripan"
  },
  {
    "id": "1302112006",
    "district_id": "130211",
    "label": "Kacang",
    "value": "Kacang"
  },
  {
    "id": "1302112007",
    "district_id": "130211",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1302112008",
    "district_id": "130211",
    "label": "Saniangbaka",
    "value": "Saniangbaka"
  },
  {
    "id": "1302122001",
    "district_id": "130212",
    "label": "Sulit Air",
    "value": "Sulit Air"
  },
  {
    "id": "1302122002",
    "district_id": "130212",
    "label": "Tanjung Balik",
    "value": "Tanjung Balik"
  },
  {
    "id": "1302122003",
    "district_id": "130212",
    "label": "Paninjauan",
    "value": "Paninjauan"
  },
  {
    "id": "1302122004",
    "district_id": "130212",
    "label": "Kuncir",
    "value": "Kuncir"
  },
  {
    "id": "1302122005",
    "district_id": "130212",
    "label": "Katialo",
    "value": "Katialo"
  },
  {
    "id": "1302122006",
    "district_id": "130212",
    "label": "Pasilihan",
    "value": "Pasilihan"
  },
  {
    "id": "1302122007",
    "district_id": "130212",
    "label": "Bukit Kanduang",
    "value": "Bukit Kanduang"
  },
  {
    "id": "1302122008",
    "district_id": "130212",
    "label": "Sibarambang",
    "value": "Sibarambang"
  },
  {
    "id": "1302122009",
    "district_id": "130212",
    "label": "Labuh Panjang",
    "value": "Labuh Panjang"
  },
  {
    "id": "1302132001",
    "district_id": "130213",
    "label": "Paninggahan",
    "value": "Paninggahan"
  },
  {
    "id": "1302132002",
    "district_id": "130213",
    "label": "Muaro Pingai",
    "value": "Muaro Pingai"
  },
  {
    "id": "1302172001",
    "district_id": "130217",
    "label": "Talang Babungo",
    "value": "Talang Babungo"
  },
  {
    "id": "1302172002",
    "district_id": "130217",
    "label": "Sariak Alahan Tigo",
    "value": "Sariak Alahan Tigo"
  },
  {
    "id": "1302172003",
    "district_id": "130217",
    "label": "Sungai Abu",
    "value": "Sungai Abu"
  },
  {
    "id": "1302182001",
    "district_id": "130218",
    "label": "Rangkiang Luluih",
    "value": "Rangkiang Luluih"
  },
  {
    "id": "1302182002",
    "district_id": "130218",
    "label": "Batu Bajanjang",
    "value": "Batu Bajanjang"
  },
  {
    "id": "1302182003",
    "district_id": "130218",
    "label": "Garabak Data",
    "value": "Garabak Data"
  },
  {
    "id": "1302182004",
    "district_id": "130218",
    "label": "Tanjung Balik Sumiso",
    "value": "Tanjung Balik Sumiso"
  },
  {
    "id": "1302182005",
    "district_id": "130218",
    "label": "Simanau",
    "value": "Simanau"
  },
  {
    "id": "1302192001",
    "district_id": "130219",
    "label": "Simpang Tj. Nan IV",
    "value": "Simpang Tj. Nan IV"
  },
  {
    "id": "1302192002",
    "district_id": "130219",
    "label": "Kampung Batu Dalam",
    "value": "Kampung Batu Dalam"
  },
  {
    "id": "1303032001",
    "district_id": "130303",
    "label": "Timbulun",
    "value": "Timbulun"
  },
  {
    "id": "1303032002",
    "district_id": "130303",
    "label": "Tanjung Gadang",
    "value": "Tanjung Gadang"
  },
  {
    "id": "1303032003",
    "district_id": "130303",
    "label": "Taratak Baru",
    "value": "Taratak Baru"
  },
  {
    "id": "1303032004",
    "district_id": "130303",
    "label": "Pulasan",
    "value": "Pulasan"
  },
  {
    "id": "1303032005",
    "district_id": "130303",
    "label": "Langki",
    "value": "Langki"
  },
  {
    "id": "1303032006",
    "district_id": "130303",
    "label": "Sibakur",
    "value": "Sibakur"
  },
  {
    "id": "1303032007",
    "district_id": "130303",
    "label": "Tanjung Lolo",
    "value": "Tanjung Lolo"
  },
  {
    "id": "1303032008",
    "district_id": "130303",
    "label": "Taratak Baru Utara",
    "value": "Taratak Baru Utara"
  },
  {
    "id": "1303032009",
    "district_id": "130303",
    "label": "Sinyamu",
    "value": "Sinyamu"
  },
  {
    "id": "1303042001",
    "district_id": "130304",
    "label": "Muaro",
    "value": "Muaro"
  },
  {
    "id": "1303042002",
    "district_id": "130304",
    "label": "Kandang Baru",
    "value": "Kandang Baru"
  },
  {
    "id": "1303042003",
    "district_id": "130304",
    "label": "Silokek",
    "value": "Silokek"
  },
  {
    "id": "1303042004",
    "district_id": "130304",
    "label": "Pematang Panjang",
    "value": "Pematang Panjang"
  },
  {
    "id": "1303042005",
    "district_id": "130304",
    "label": "Solok Ambah",
    "value": "Solok Ambah"
  },
  {
    "id": "1303042006",
    "district_id": "130304",
    "label": "Paru",
    "value": "Paru"
  },
  {
    "id": "1303042007",
    "district_id": "130304",
    "label": "Durian Gadang",
    "value": "Durian Gadang"
  },
  {
    "id": "1303042008",
    "district_id": "130304",
    "label": "Aie Angek",
    "value": "Aie Angek"
  },
  {
    "id": "1303042009",
    "district_id": "130304",
    "label": "Sijunjung",
    "value": "Sijunjung"
  },
  {
    "id": "1303052001",
    "district_id": "130305",
    "label": "Palangki",
    "value": "Palangki"
  },
  {
    "id": "1303052002",
    "district_id": "130305",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1303052003",
    "district_id": "130305",
    "label": "Muaro Bodi",
    "value": "Muaro Bodi"
  },
  {
    "id": "1303052004",
    "district_id": "130305",
    "label": "Mundam Sakti",
    "value": "Mundam Sakti"
  },
  {
    "id": "1303052005",
    "district_id": "130305",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1303062001",
    "district_id": "130306",
    "label": "Sungai Lansek",
    "value": "Sungai Lansek"
  },
  {
    "id": "1303062002",
    "district_id": "130306",
    "label": "Kamang",
    "value": "Kamang"
  },
  {
    "id": "1303062003",
    "district_id": "130306",
    "label": "Muaro Takuang",
    "value": "Muaro Takuang"
  },
  {
    "id": "1303062004",
    "district_id": "130306",
    "label": "Aie Amo",
    "value": "Aie Amo"
  },
  {
    "id": "1303062005",
    "district_id": "130306",
    "label": "Sungai Batuang",
    "value": "Sungai Batuang"
  },
  {
    "id": "1303062006",
    "district_id": "130306",
    "label": "Kunangan Parik Rantang",
    "value": "Kunangan Parik Rantang"
  },
  {
    "id": "1303062007",
    "district_id": "130306",
    "label": "Tanjung Kaliang",
    "value": "Tanjung Kaliang"
  },
  {
    "id": "1303062008",
    "district_id": "130306",
    "label": "Padang Tarok",
    "value": "Padang Tarok"
  },
  {
    "id": "1303062009",
    "district_id": "130306",
    "label": "Siaur",
    "value": "Siaur"
  },
  {
    "id": "1303062010",
    "district_id": "130306",
    "label": "Lubuk Tarantang",
    "value": "Lubuk Tarantang"
  },
  {
    "id": "1303062011",
    "district_id": "130306",
    "label": "Maloro",
    "value": "Maloro"
  },
  {
    "id": "1303072001",
    "district_id": "130307",
    "label": "Lubuak Tarok",
    "value": "Lubuak Tarok"
  },
  {
    "id": "1303072002",
    "district_id": "130307",
    "label": "Lalan",
    "value": "Lalan"
  },
  {
    "id": "1303072003",
    "district_id": "130307",
    "label": "Buluah Kasok",
    "value": "Buluah Kasok"
  },
  {
    "id": "1303072004",
    "district_id": "130307",
    "label": "Kampung Dalam",
    "value": "Kampung Dalam"
  },
  {
    "id": "1303072005",
    "district_id": "130307",
    "label": "Silongo",
    "value": "Silongo"
  },
  {
    "id": "1303072006",
    "district_id": "130307",
    "label": "Latang",
    "value": "Latang"
  },
  {
    "id": "1303082001",
    "district_id": "130308",
    "label": "Limo Koto",
    "value": "Limo Koto"
  },
  {
    "id": "1303082002",
    "district_id": "130308",
    "label": "Palaluar",
    "value": "Palaluar"
  },
  {
    "id": "1303082003",
    "district_id": "130308",
    "label": "Guguak",
    "value": "Guguak"
  },
  {
    "id": "1303082004",
    "district_id": "130308",
    "label": "Padang Laweh",
    "value": "Padang Laweh"
  },
  {
    "id": "1303082005",
    "district_id": "130308",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1303082006",
    "district_id": "130308",
    "label": "Bukit Bual",
    "value": "Bukit Bual"
  },
  {
    "id": "1303092001",
    "district_id": "130309",
    "label": "Silantai",
    "value": "Silantai"
  },
  {
    "id": "1303092002",
    "district_id": "130309",
    "label": "Sisawah",
    "value": "Sisawah"
  },
  {
    "id": "1303092003",
    "district_id": "130309",
    "label": "Unggan",
    "value": "Unggan"
  },
  {
    "id": "1303092004",
    "district_id": "130309",
    "label": "Tanjung Bonai Aur",
    "value": "Tanjung Bonai Aur"
  },
  {
    "id": "1303092005",
    "district_id": "130309",
    "label": "Sumpur Kudus",
    "value": "Sumpur Kudus"
  },
  {
    "id": "1303092006",
    "district_id": "130309",
    "label": "Tamparungo",
    "value": "Tamparungo"
  },
  {
    "id": "1303092007",
    "district_id": "130309",
    "label": "Kumanis",
    "value": "Kumanis"
  },
  {
    "id": "1303092008",
    "district_id": "130309",
    "label": "Manganti",
    "value": "Manganti"
  },
  {
    "id": "1303092009",
    "district_id": "130309",
    "label": "Sumpur Kudus Selatan",
    "value": "Sumpur Kudus Selatan"
  },
  {
    "id": "1303092010",
    "district_id": "130309",
    "label": "Tanjung Labuah",
    "value": "Tanjung Labuah"
  },
  {
    "id": "1303092011",
    "district_id": "130309",
    "label": "Tanjung Bonai Aur Selatan",
    "value": "Tanjung Bonai Aur Selatan"
  },
  {
    "id": "1303102001",
    "district_id": "130310",
    "label": "Batu Manjulur",
    "value": "Batu Manjulur"
  },
  {
    "id": "1303102002",
    "district_id": "130310",
    "label": "Pamuatan",
    "value": "Pamuatan"
  },
  {
    "id": "1303102003",
    "district_id": "130310",
    "label": "Padang Sibusuk",
    "value": "Padang Sibusuk"
  },
  {
    "id": "1303102004",
    "district_id": "130310",
    "label": "Desa Kampung Baru",
    "value": "Desa Kampung Baru"
  },
  {
    "id": "1304012001",
    "district_id": "130401",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "1304012002",
    "district_id": "130401",
    "label": "Jaho",
    "value": "Jaho"
  },
  {
    "id": "1304012003",
    "district_id": "130401",
    "label": "Singgalang",
    "value": "Singgalang"
  },
  {
    "id": "1304012004",
    "district_id": "130401",
    "label": "Paninjauan",
    "value": "Paninjauan"
  },
  {
    "id": "1304012005",
    "district_id": "130401",
    "label": "Panyalaian",
    "value": "Panyalaian"
  },
  {
    "id": "1304012006",
    "district_id": "130401",
    "label": "Koto Laweh",
    "value": "Koto Laweh"
  },
  {
    "id": "1304012007",
    "district_id": "130401",
    "label": "Aie Angek",
    "value": "Aie Angek"
  },
  {
    "id": "1304012008",
    "district_id": "130401",
    "label": "Pandai Sikek",
    "value": "Pandai Sikek"
  },
  {
    "id": "1304012009",
    "district_id": "130401",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1304022001",
    "district_id": "130402",
    "label": "Andaleh",
    "value": "Andaleh"
  },
  {
    "id": "1304022002",
    "district_id": "130402",
    "label": "Sabu",
    "value": "Sabu"
  },
  {
    "id": "1304022003",
    "district_id": "130402",
    "label": "Batipuah Ateh",
    "value": "Batipuah Ateh"
  },
  {
    "id": "1304022004",
    "district_id": "130402",
    "label": "Batipuah Baruah",
    "value": "Batipuah Baruah"
  },
  {
    "id": "1304022005",
    "district_id": "130402",
    "label": "Gunuang Rajo",
    "value": "Gunuang Rajo"
  },
  {
    "id": "1304022006",
    "district_id": "130402",
    "label": "Pitalah",
    "value": "Pitalah"
  },
  {
    "id": "1304022007",
    "district_id": "130402",
    "label": "Tanjuang Barulak",
    "value": "Tanjuang Barulak"
  },
  {
    "id": "1304022008",
    "district_id": "130402",
    "label": "Bungo Tanjuang",
    "value": "Bungo Tanjuang"
  },
  {
    "id": "1304032001",
    "district_id": "130403",
    "label": "Simawang",
    "value": "Simawang"
  },
  {
    "id": "1304032002",
    "district_id": "130403",
    "label": "Balimbiang",
    "value": "Balimbiang"
  },
  {
    "id": "1304032003",
    "district_id": "130403",
    "label": "III Koto",
    "value": "III Koto"
  },
  {
    "id": "1304032004",
    "district_id": "130403",
    "label": "Padang Magek",
    "value": "Padang Magek"
  },
  {
    "id": "1304032005",
    "district_id": "130403",
    "label": "Rambatan",
    "value": "Rambatan"
  },
  {
    "id": "1304042001",
    "district_id": "130404",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "1304042002",
    "district_id": "130404",
    "label": "Limo Kaum",
    "value": "Limo Kaum"
  },
  {
    "id": "1304042003",
    "district_id": "130404",
    "label": "Parambahan",
    "value": "Parambahan"
  },
  {
    "id": "1304042004",
    "district_id": "130404",
    "label": "Cubadak",
    "value": "Cubadak"
  },
  {
    "id": "1304042005",
    "district_id": "130404",
    "label": "Labuh",
    "value": "Labuh"
  },
  {
    "id": "1304052001",
    "district_id": "130405",
    "label": "Tanjung Barulak",
    "value": "Tanjung Barulak"
  },
  {
    "id": "1304052002",
    "district_id": "130405",
    "label": "Saruaso",
    "value": "Saruaso"
  },
  {
    "id": "1304052003",
    "district_id": "130405",
    "label": "Koto Tangah",
    "value": "Koto Tangah"
  },
  {
    "id": "1304052004",
    "district_id": "130405",
    "label": "Pagaruyung",
    "value": "Pagaruyung"
  },
  {
    "id": "1304062001",
    "district_id": "130406",
    "label": "Taluak",
    "value": "Taluak"
  },
  {
    "id": "1304062002",
    "district_id": "130406",
    "label": "Buo",
    "value": "Buo"
  },
  {
    "id": "1304062003",
    "district_id": "130406",
    "label": "Tigo Jangko",
    "value": "Tigo Jangko"
  },
  {
    "id": "1304062004",
    "district_id": "130406",
    "label": "Pangian",
    "value": "Pangian"
  },
  {
    "id": "1304072001",
    "district_id": "130407",
    "label": "Sungai Patai",
    "value": "Sungai Patai"
  },
  {
    "id": "1304072002",
    "district_id": "130407",
    "label": "Andaleh Baruh Bukik",
    "value": "Andaleh Baruh Bukik"
  },
  {
    "id": "1304072003",
    "district_id": "130407",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1304072004",
    "district_id": "130407",
    "label": "Sungayang",
    "value": "Sungayang"
  },
  {
    "id": "1304072005",
    "district_id": "130407",
    "label": "Minang Kabau",
    "value": "Minang Kabau"
  },
  {
    "id": "1304082001",
    "district_id": "130408",
    "label": "Simpuruik",
    "value": "Simpuruik"
  },
  {
    "id": "1304082002",
    "district_id": "130408",
    "label": "Sungai Tarab",
    "value": "Sungai Tarab"
  },
  {
    "id": "1304082003",
    "district_id": "130408",
    "label": "Gurun",
    "value": "Gurun"
  },
  {
    "id": "1304082004",
    "district_id": "130408",
    "label": "Talang Tangah",
    "value": "Talang Tangah"
  },
  {
    "id": "1304082005",
    "district_id": "130408",
    "label": "Padang Laweh",
    "value": "Padang Laweh"
  },
  {
    "id": "1304082006",
    "district_id": "130408",
    "label": "Pasie Laweh",
    "value": "Pasie Laweh"
  },
  {
    "id": "1304082007",
    "district_id": "130408",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1304082008",
    "district_id": "130408",
    "label": "Rao Rao",
    "value": "Rao Rao"
  },
  {
    "id": "1304082009",
    "district_id": "130408",
    "label": "Kumango",
    "value": "Kumango"
  },
  {
    "id": "1304082010",
    "district_id": "130408",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1304092001",
    "district_id": "130409",
    "label": "Batu Basa",
    "value": "Batu Basa"
  },
  {
    "id": "1304092002",
    "district_id": "130409",
    "label": "Tabek",
    "value": "Tabek"
  },
  {
    "id": "1304092003",
    "district_id": "130409",
    "label": "Sawah Tangah",
    "value": "Sawah Tangah"
  },
  {
    "id": "1304092004",
    "district_id": "130409",
    "label": "Simabur",
    "value": "Simabur"
  },
  {
    "id": "1304092005",
    "district_id": "130409",
    "label": "Pariangan",
    "value": "Pariangan"
  },
  {
    "id": "1304092006",
    "district_id": "130409",
    "label": "Sungai Jambu",
    "value": "Sungai Jambu"
  },
  {
    "id": "1304102001",
    "district_id": "130410",
    "label": "Sumanik",
    "value": "Sumanik"
  },
  {
    "id": "1304102002",
    "district_id": "130410",
    "label": "Situmbuk",
    "value": "Situmbuk"
  },
  {
    "id": "1304102003",
    "district_id": "130410",
    "label": "Lawang Mandahiling",
    "value": "Lawang Mandahiling"
  },
  {
    "id": "1304102004",
    "district_id": "130410",
    "label": "Supayang",
    "value": "Supayang"
  },
  {
    "id": "1304102005",
    "district_id": "130410",
    "label": "Salimpaung",
    "value": "Salimpaung"
  },
  {
    "id": "1304102006",
    "district_id": "130410",
    "label": "Tabek Patah",
    "value": "Tabek Patah"
  },
  {
    "id": "1304112001",
    "district_id": "130411",
    "label": "Atar",
    "value": "Atar"
  },
  {
    "id": "1304112002",
    "district_id": "130411",
    "label": "Padang Ganting",
    "value": "Padang Ganting"
  },
  {
    "id": "1304122001",
    "district_id": "130412",
    "label": "Tanjuang Alam",
    "value": "Tanjuang Alam"
  },
  {
    "id": "1304122002",
    "district_id": "130412",
    "label": "Barulak",
    "value": "Barulak"
  },
  {
    "id": "1304132001",
    "district_id": "130413",
    "label": "Batu Bulek",
    "value": "Batu Bulek"
  },
  {
    "id": "1304132002",
    "district_id": "130413",
    "label": "Balai Tangah",
    "value": "Balai Tangah"
  },
  {
    "id": "1304132003",
    "district_id": "130413",
    "label": "Tanjuang Bonai",
    "value": "Tanjuang Bonai"
  },
  {
    "id": "1304132004",
    "district_id": "130413",
    "label": "Lubuak Jantan",
    "value": "Lubuak Jantan"
  },
  {
    "id": "1304132005",
    "district_id": "130413",
    "label": "Tapi Selo",
    "value": "Tapi Selo"
  },
  {
    "id": "1304142001",
    "district_id": "130414",
    "label": "Batu Taba",
    "value": "Batu Taba"
  },
  {
    "id": "1304142002",
    "district_id": "130414",
    "label": "Sumpur",
    "value": "Sumpur"
  },
  {
    "id": "1304142003",
    "district_id": "130414",
    "label": "Padang Laweh Malalo",
    "value": "Padang Laweh Malalo"
  },
  {
    "id": "1304142004",
    "district_id": "130414",
    "label": "Guguak Malalo",
    "value": "Guguak Malalo"
  },
  {
    "id": "1305012001",
    "district_id": "130501",
    "label": "Lubuk Alung",
    "value": "Lubuk Alung"
  },
  {
    "id": "1305012002",
    "district_id": "130501",
    "label": "Pungguang Kasiak Lubuk Alung",
    "value": "Pungguang Kasiak Lubuk Alung"
  },
  {
    "id": "1305012003",
    "district_id": "130501",
    "label": "Pasie Laweh Lubuk Alung",
    "value": "Pasie Laweh Lubuk Alung"
  },
  {
    "id": "1305012004",
    "district_id": "130501",
    "label": "Aie Tajun Lubuk Alung",
    "value": "Aie Tajun Lubuk Alung"
  },
  {
    "id": "1305012005",
    "district_id": "130501",
    "label": "Sikabu Lubuk Alung",
    "value": "Sikabu Lubuk Alung"
  },
  {
    "id": "1305012006",
    "district_id": "130501",
    "label": "Sungai Abang Lubuk Alung",
    "value": "Sungai Abang Lubuk Alung"
  },
  {
    "id": "1305012007",
    "district_id": "130501",
    "label": "Singguliang Lubuk Alung",
    "value": "Singguliang Lubuk Alung"
  },
  {
    "id": "1305012008",
    "district_id": "130501",
    "label": "Salibutan Lubuk Alung",
    "value": "Salibutan Lubuk Alung"
  },
  {
    "id": "1305012009",
    "district_id": "130501",
    "label": "Nagari Balah Hilia Lubuk Alung",
    "value": "Nagari Balah Hilia Lubuk Alung"
  },
  {
    "id": "1305022001",
    "district_id": "130502",
    "label": "Katapiang",
    "value": "Katapiang"
  },
  {
    "id": "1305022002",
    "district_id": "130502",
    "label": "Kasang",
    "value": "Kasang"
  },
  {
    "id": "1305022003",
    "district_id": "130502",
    "label": "Sungai Buluah",
    "value": "Sungai Buluah"
  },
  {
    "id": "1305022004",
    "district_id": "130502",
    "label": "Buayan Lubuk Alung",
    "value": "Buayan Lubuk Alung"
  },
  {
    "id": "1305022005",
    "district_id": "130502",
    "label": "Sungai Buluah Timur",
    "value": "Sungai Buluah Timur"
  },
  {
    "id": "1305022006",
    "district_id": "130502",
    "label": "Sungai Buluah Barat",
    "value": "Sungai Buluah Barat"
  },
  {
    "id": "1305022007",
    "district_id": "130502",
    "label": "Sungai Buluah Utara",
    "value": "Sungai Buluah Utara"
  },
  {
    "id": "1305022008",
    "district_id": "130502",
    "label": "Sungai Buluah Selatan",
    "value": "Sungai Buluah Selatan"
  },
  {
    "id": "1305032001",
    "district_id": "130503",
    "label": "Kapalo Koto",
    "value": "Kapalo Koto"
  },
  {
    "id": "1305032002",
    "district_id": "130503",
    "label": "Pauh Kambar",
    "value": "Pauh Kambar"
  },
  {
    "id": "1305032003",
    "district_id": "130503",
    "label": "Padang Bintungan",
    "value": "Padang Bintungan"
  },
  {
    "id": "1305032004",
    "district_id": "130503",
    "label": "Kurai Taji",
    "value": "Kurai Taji"
  },
  {
    "id": "1305032005",
    "district_id": "130503",
    "label": "Sunua",
    "value": "Sunua"
  },
  {
    "id": "1305032006",
    "district_id": "130503",
    "label": "Padang Kandang Pulau Air Padang",
    "value": "Padang Kandang Pulau Air Padang"
  },
  {
    "id": "1305032007",
    "district_id": "130503",
    "label": "Sunua Tengah",
    "value": "Sunua Tengah"
  },
  {
    "id": "1305032008",
    "district_id": "130503",
    "label": "Sunua Barat",
    "value": "Sunua Barat"
  },
  {
    "id": "1305032009",
    "district_id": "130503",
    "label": "Kurai Taji Timur",
    "value": "Kurai Taji Timur"
  },
  {
    "id": "1305042002",
    "district_id": "130504",
    "label": "Sicincin",
    "value": "Sicincin"
  },
  {
    "id": "1305042003",
    "district_id": "130504",
    "label": "Lubuk Pandan",
    "value": "Lubuk Pandan"
  },
  {
    "id": "1305042004",
    "district_id": "130504",
    "label": "Sungai Asam",
    "value": "Sungai Asam"
  },
  {
    "id": "1305052001",
    "district_id": "130505",
    "label": "Balah Aie",
    "value": "Balah Aie"
  },
  {
    "id": "1305052002",
    "district_id": "130505",
    "label": "Sungai Sariak",
    "value": "Sungai Sariak"
  },
  {
    "id": "1305052003",
    "district_id": "130505",
    "label": "Lurah Ampalu",
    "value": "Lurah Ampalu"
  },
  {
    "id": "1305052004",
    "district_id": "130505",
    "label": "Lareh Nan Panjang",
    "value": "Lareh Nan Panjang"
  },
  {
    "id": "1305052005",
    "district_id": "130505",
    "label": "Lareh Nan Panjang Selatan",
    "value": "Lareh Nan Panjang Selatan"
  },
  {
    "id": "1305052006",
    "district_id": "130505",
    "label": "Lareh Nan Panjang Barat",
    "value": "Lareh Nan Panjang Barat"
  },
  {
    "id": "1305052007",
    "district_id": "130505",
    "label": "Bisati Sungai Sariak",
    "value": "Bisati Sungai Sariak"
  },
  {
    "id": "1305052008",
    "district_id": "130505",
    "label": "Ambuang Kapua Sungai Sariak",
    "value": "Ambuang Kapua Sungai Sariak"
  },
  {
    "id": "1305052009",
    "district_id": "130505",
    "label": "Lareh Nan Panjang Sungai Sariak",
    "value": "Lareh Nan Panjang Sungai Sariak"
  },
  {
    "id": "1305052010",
    "district_id": "130505",
    "label": "Limpato Sungai Sariak",
    "value": "Limpato Sungai Sariak"
  },
  {
    "id": "1305052011",
    "district_id": "130505",
    "label": "Balah Aie Utara",
    "value": "Balah Aie Utara"
  },
  {
    "id": "1305052012",
    "district_id": "130505",
    "label": "Balah Aie Timur",
    "value": "Balah Aie Timur"
  },
  {
    "id": "1305062001",
    "district_id": "130506",
    "label": "Campago",
    "value": "Campago"
  },
  {
    "id": "1305062002",
    "district_id": "130506",
    "label": "Sikucua",
    "value": "Sikucua"
  },
  {
    "id": "1305062003",
    "district_id": "130506",
    "label": "Campago Barat",
    "value": "Campago Barat"
  },
  {
    "id": "1305062004",
    "district_id": "130506",
    "label": "Campago Selatan",
    "value": "Campago Selatan"
  },
  {
    "id": "1305062005",
    "district_id": "130506",
    "label": "Sikucua Utara",
    "value": "Sikucua Utara"
  },
  {
    "id": "1305062006",
    "district_id": "130506",
    "label": "Sikucua Timur",
    "value": "Sikucua Timur"
  },
  {
    "id": "1305062007",
    "district_id": "130506",
    "label": "Sikucua Tengah",
    "value": "Sikucua Tengah"
  },
  {
    "id": "1305062008",
    "district_id": "130506",
    "label": "Sikucua Barat",
    "value": "Sikucua Barat"
  },
  {
    "id": "1305072001",
    "district_id": "130507",
    "label": "Kuranji Hulu",
    "value": "Kuranji Hulu"
  },
  {
    "id": "1305072002",
    "district_id": "130507",
    "label": "Malai III Koto",
    "value": "Malai III Koto"
  },
  {
    "id": "1305072003",
    "district_id": "130507",
    "label": "Batu Gadang Kuranji Hulu",
    "value": "Batu Gadang Kuranji Hulu"
  },
  {
    "id": "1305072004",
    "district_id": "130507",
    "label": "Sungai Sirah Kuranji Hulu",
    "value": "Sungai Sirah Kuranji Hulu"
  },
  {
    "id": "1305082001",
    "district_id": "130508",
    "label": "Kuranji Hilir",
    "value": "Kuranji Hilir"
  },
  {
    "id": "1305082002",
    "district_id": "130508",
    "label": "Pilubang",
    "value": "Pilubang"
  },
  {
    "id": "1305082003",
    "district_id": "130508",
    "label": "Guguak Kuranji Hilir",
    "value": "Guguak Kuranji Hilir"
  },
  {
    "id": "1305082004",
    "district_id": "130508",
    "label": "Koto Tinggi Kuranji Hilir",
    "value": "Koto Tinggi Kuranji Hilir"
  },
  {
    "id": "1305092001",
    "district_id": "130509",
    "label": "III Koto Aur Malintang",
    "value": "III Koto Aur Malintang"
  },
  {
    "id": "1305092002",
    "district_id": "130509",
    "label": "III Koto Aur Malintang Utara",
    "value": "III Koto Aur Malintang Utara"
  },
  {
    "id": "1305092003",
    "district_id": "130509",
    "label": "III Koto Aur Malintang Timur",
    "value": "III Koto Aur Malintang Timur"
  },
  {
    "id": "1305092004",
    "district_id": "130509",
    "label": "III Koto Aur Malintang Selatan",
    "value": "III Koto Aur Malintang Selatan"
  },
  {
    "id": "1305092005",
    "district_id": "130509",
    "label": "Balai Baiak Malai III Koto",
    "value": "Balai Baiak Malai III Koto"
  },
  {
    "id": "1305102001",
    "district_id": "130510",
    "label": "Tapakih",
    "value": "Tapakih"
  },
  {
    "id": "1305102002",
    "district_id": "130510",
    "label": "Ulakan",
    "value": "Ulakan"
  },
  {
    "id": "1305102003",
    "district_id": "130510",
    "label": "Padang Toboh Ulakan",
    "value": "Padang Toboh Ulakan"
  },
  {
    "id": "1305102004",
    "district_id": "130510",
    "label": "Sungai Gimba Ulakan",
    "value": "Sungai Gimba Ulakan"
  },
  {
    "id": "1305102005",
    "district_id": "130510",
    "label": "Seulayat Ulakan",
    "value": "Seulayat Ulakan"
  },
  {
    "id": "1305102006",
    "district_id": "130510",
    "label": "Manggopoh Palak Gadang Ulakan",
    "value": "Manggopoh Palak Gadang Ulakan"
  },
  {
    "id": "1305102007",
    "district_id": "130510",
    "label": "Sandi Ulakan",
    "value": "Sandi Ulakan"
  },
  {
    "id": "1305102008",
    "district_id": "130510",
    "label": "Kampuang Gelapuang Ulakan",
    "value": "Kampuang Gelapuang Ulakan"
  },
  {
    "id": "1305112001",
    "district_id": "130511",
    "label": "Sintuak",
    "value": "Sintuak"
  },
  {
    "id": "1305112002",
    "district_id": "130511",
    "label": "Toboh Gadang",
    "value": "Toboh Gadang"
  },
  {
    "id": "1305112003",
    "district_id": "130511",
    "label": "Toboh Gadang Selatan",
    "value": "Toboh Gadang Selatan"
  },
  {
    "id": "1305112004",
    "district_id": "130511",
    "label": "Toboh Gadang Barat",
    "value": "Toboh Gadang Barat"
  },
  {
    "id": "1305112005",
    "district_id": "130511",
    "label": "Toboh Gadang Timur",
    "value": "Toboh Gadang Timur"
  },
  {
    "id": "1305122001",
    "district_id": "130512",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1305122002",
    "district_id": "130512",
    "label": "Koto Dalam",
    "value": "Koto Dalam"
  },
  {
    "id": "1305122003",
    "district_id": "130512",
    "label": "Batu Kalang",
    "value": "Batu Kalang"
  },
  {
    "id": "1305122004",
    "district_id": "130512",
    "label": "Koto Dalam Barat",
    "value": "Koto Dalam Barat"
  },
  {
    "id": "1305122005",
    "district_id": "130512",
    "label": "Koto Dalam Selatan",
    "value": "Koto Dalam Selatan"
  },
  {
    "id": "1305122006",
    "district_id": "130512",
    "label": "Batu Kalang Utara",
    "value": "Batu Kalang Utara"
  },
  {
    "id": "1305132001",
    "district_id": "130513",
    "label": "Malai V Suku",
    "value": "Malai V Suku"
  },
  {
    "id": "1305132002",
    "district_id": "130513",
    "label": "Gasan Gadang",
    "value": "Gasan Gadang"
  },
  {
    "id": "1305132003",
    "district_id": "130513",
    "label": "Malai V Suku Timur",
    "value": "Malai V Suku Timur"
  },
  {
    "id": "1305142001",
    "district_id": "130514",
    "label": "Kudu Gantiang",
    "value": "Kudu Gantiang"
  },
  {
    "id": "1305142002",
    "district_id": "130514",
    "label": "Limau Puruik",
    "value": "Limau Puruik"
  },
  {
    "id": "1305142003",
    "district_id": "130514",
    "label": "Gunung Padang Alai",
    "value": "Gunung Padang Alai"
  },
  {
    "id": "1305142004",
    "district_id": "130514",
    "label": "Kudu Gantiang Barat",
    "value": "Kudu Gantiang Barat"
  },
  {
    "id": "1305152001",
    "district_id": "130515",
    "label": "Kayu Tanam",
    "value": "Kayu Tanam"
  },
  {
    "id": "1305152002",
    "district_id": "130515",
    "label": "Guguak",
    "value": "Guguak"
  },
  {
    "id": "1305152003",
    "district_id": "130515",
    "label": "Anduriang",
    "value": "Anduriang"
  },
  {
    "id": "1305152004",
    "district_id": "130515",
    "label": "Kapalo Hilalang",
    "value": "Kapalo Hilalang"
  },
  {
    "id": "1305162001",
    "district_id": "130516",
    "label": "Sungai Durian",
    "value": "Sungai Durian"
  },
  {
    "id": "1305162002",
    "district_id": "130516",
    "label": "Tandikek",
    "value": "Tandikek"
  },
  {
    "id": "1305162003",
    "district_id": "130516",
    "label": "Tandikek Utara",
    "value": "Tandikek Utara"
  },
  {
    "id": "1305162004",
    "district_id": "130516",
    "label": "Tandikek Selatan",
    "value": "Tandikek Selatan"
  },
  {
    "id": "1305162005",
    "district_id": "130516",
    "label": "Tandikek Barat",
    "value": "Tandikek Barat"
  },
  {
    "id": "1305162006",
    "district_id": "130516",
    "label": "Kampuang Tanjuang Koto Mambang Sungai",
    "value": "Kampuang Tanjuang Koto Mambang Sungai"
  },
  {
    "id": "1305172001",
    "district_id": "130517",
    "label": "Pakandangan",
    "value": "Pakandangan"
  },
  {
    "id": "1305172002",
    "district_id": "130517",
    "label": "Koto Tinggi",
    "value": "Koto Tinggi"
  },
  {
    "id": "1305172003",
    "district_id": "130517",
    "label": "Toboh Ketek",
    "value": "Toboh Ketek"
  },
  {
    "id": "1305172004",
    "district_id": "130517",
    "label": "Parit Malintang",
    "value": "Parit Malintang"
  },
  {
    "id": "1305172005",
    "district_id": "130517",
    "label": "Gadur",
    "value": "Gadur"
  },
  {
    "id": "1306012001",
    "district_id": "130601",
    "label": "Tiku Selatan",
    "value": "Tiku Selatan"
  },
  {
    "id": "1306012002",
    "district_id": "130601",
    "label": "Tiku Utara",
    "value": "Tiku Utara"
  },
  {
    "id": "1306012003",
    "district_id": "130601",
    "label": "Tiku Limo Jorong",
    "value": "Tiku Limo Jorong"
  },
  {
    "id": "1306022001",
    "district_id": "130602",
    "label": "Lubuk Basung",
    "value": "Lubuk Basung"
  },
  {
    "id": "1306022002",
    "district_id": "130602",
    "label": "Geragahan",
    "value": "Geragahan"
  },
  {
    "id": "1306022003",
    "district_id": "130602",
    "label": "Kampung Pinang",
    "value": "Kampung Pinang"
  },
  {
    "id": "1306022004",
    "district_id": "130602",
    "label": "Kampung Tangah",
    "value": "Kampung Tangah"
  },
  {
    "id": "1306022005",
    "district_id": "130602",
    "label": "Manggopoh",
    "value": "Manggopoh"
  },
  {
    "id": "1306032001",
    "district_id": "130603",
    "label": "Tanjung Sani",
    "value": "Tanjung Sani"
  },
  {
    "id": "1306032002",
    "district_id": "130603",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  {
    "id": "1306032003",
    "district_id": "130603",
    "label": "Maninjau",
    "value": "Maninjau"
  },
  {
    "id": "1306032004",
    "district_id": "130603",
    "label": "Bayua",
    "value": "Bayua"
  },
  {
    "id": "1306032006",
    "district_id": "130603",
    "label": "Koto Kaciak",
    "value": "Koto Kaciak"
  },
  {
    "id": "1306032007",
    "district_id": "130603",
    "label": "Duo Koto",
    "value": "Duo Koto"
  },
  {
    "id": "1306032008",
    "district_id": "130603",
    "label": "Koto Gadang",
    "value": "Koto Gadang"
  },
  {
    "id": "1306032009",
    "district_id": "130603",
    "label": "Koto Malintang",
    "value": "Koto Malintang"
  },
  {
    "id": "1306032010",
    "district_id": "130603",
    "label": "Paninjauan",
    "value": "Paninjauan"
  },
  {
    "id": "1306042001",
    "district_id": "130604",
    "label": "Matua Hilia",
    "value": "Matua Hilia"
  },
  {
    "id": "1306042002",
    "district_id": "130604",
    "label": "Matua Mudiak",
    "value": "Matua Mudiak"
  },
  {
    "id": "1306042003",
    "district_id": "130604",
    "label": "Tigo Balai",
    "value": "Tigo Balai"
  },
  {
    "id": "1306042004",
    "district_id": "130604",
    "label": "Lawang",
    "value": "Lawang"
  },
  {
    "id": "1306042005",
    "district_id": "130604",
    "label": "Parik Panjang",
    "value": "Parik Panjang"
  },
  {
    "id": "1306042006",
    "district_id": "130604",
    "label": "Panta Pauh",
    "value": "Panta Pauh"
  },
  {
    "id": "1306052002",
    "district_id": "130605",
    "label": "Sungai Landia",
    "value": "Sungai Landia"
  },
  {
    "id": "1306052003",
    "district_id": "130605",
    "label": "Balingka",
    "value": "Balingka"
  },
  {
    "id": "1306052004",
    "district_id": "130605",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1306052005",
    "district_id": "130605",
    "label": "Guguk Tabek Sarajo",
    "value": "Guguk Tabek Sarajo"
  },
  {
    "id": "1306052006",
    "district_id": "130605",
    "label": "Koto Panjang",
    "value": "Koto Panjang"
  },
  {
    "id": "1306052007",
    "district_id": "130605",
    "label": "Sianok Anam Suku",
    "value": "Sianok Anam Suku"
  },
  {
    "id": "1306052008",
    "district_id": "130605",
    "label": "Koto Gadang",
    "value": "Koto Gadang"
  },
  {
    "id": "1306062001",
    "district_id": "130606",
    "label": "Padang Lua",
    "value": "Padang Lua"
  },
  {
    "id": "1306062002",
    "district_id": "130606",
    "label": "Taluak Ampek Suku",
    "value": "Taluak Ampek Suku"
  },
  {
    "id": "1306062003",
    "district_id": "130606",
    "label": "Pakan Sinayan",
    "value": "Pakan Sinayan"
  },
  {
    "id": "1306062004",
    "district_id": "130606",
    "label": "Kubang Putiah",
    "value": "Kubang Putiah"
  },
  {
    "id": "1306062005",
    "district_id": "130606",
    "label": "Ladang Laweh",
    "value": "Ladang Laweh"
  },
  {
    "id": "1306062006",
    "district_id": "130606",
    "label": "Cingkariang",
    "value": "Cingkariang"
  },
  {
    "id": "1306062007",
    "district_id": "130606",
    "label": "Sungai Tanang",
    "value": "Sungai Tanang"
  },
  {
    "id": "1306072001",
    "district_id": "130607",
    "label": "Batu Taba",
    "value": "Batu Taba"
  },
  {
    "id": "1306072002",
    "district_id": "130607",
    "label": "Biaro Gadang",
    "value": "Biaro Gadang"
  },
  {
    "id": "1306072003",
    "district_id": "130607",
    "label": "Lambah",
    "value": "Lambah"
  },
  {
    "id": "1306072004",
    "district_id": "130607",
    "label": "Panampung",
    "value": "Panampung"
  },
  {
    "id": "1306072005",
    "district_id": "130607",
    "label": "Ampang Gadang",
    "value": "Ampang Gadang"
  },
  {
    "id": "1306072006",
    "district_id": "130607",
    "label": "Pasia",
    "value": "Pasia"
  },
  {
    "id": "1306072008",
    "district_id": "130607",
    "label": "Balai Gurah",
    "value": "Balai Gurah"
  },
  {
    "id": "1306082001",
    "district_id": "130608",
    "label": "Koto Tinggi",
    "value": "Koto Tinggi"
  },
  {
    "id": "1306082002",
    "district_id": "130608",
    "label": "Tabek Panjang",
    "value": "Tabek Panjang"
  },
  {
    "id": "1306082004",
    "district_id": "130608",
    "label": "Simarasok",
    "value": "Simarasok"
  },
  {
    "id": "1306082005",
    "district_id": "130608",
    "label": "Padang Tarok",
    "value": "Padang Tarok"
  },
  {
    "id": "1306082006",
    "district_id": "130608",
    "label": "Salo",
    "value": "Salo"
  },
  {
    "id": "1306082007",
    "district_id": "130608",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1306092001",
    "district_id": "130609",
    "label": "Koto Tangah",
    "value": "Koto Tangah"
  },
  {
    "id": "1306092003",
    "district_id": "130609",
    "label": "Gadut",
    "value": "Gadut"
  },
  {
    "id": "1306092004",
    "district_id": "130609",
    "label": "Kapau",
    "value": "Kapau"
  },
  {
    "id": "1306102001",
    "district_id": "130610",
    "label": "Koto Rantang",
    "value": "Koto Rantang"
  },
  {
    "id": "1306102002",
    "district_id": "130610",
    "label": "Pasia Laweh",
    "value": "Pasia Laweh"
  },
  {
    "id": "1306102003",
    "district_id": "130610",
    "label": "Pagadih",
    "value": "Pagadih"
  },
  {
    "id": "1306102004",
    "district_id": "130610",
    "label": "Nan Tujuah",
    "value": "Nan Tujuah"
  },
  {
    "id": "1306112001",
    "district_id": "130611",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "1306112002",
    "district_id": "130611",
    "label": "Sipinang",
    "value": "Sipinang"
  },
  {
    "id": "1306112003",
    "district_id": "130611",
    "label": "Sungai Puar",
    "value": "Sungai Puar"
  },
  {
    "id": "1306112004",
    "district_id": "130611",
    "label": "Ampek Koto Palembayan",
    "value": "Ampek Koto Palembayan"
  },
  {
    "id": "1306112005",
    "district_id": "130611",
    "label": "Tigo Koto Silungkang",
    "value": "Tigo Koto Silungkang"
  },
  {
    "id": "1306112006",
    "district_id": "130611",
    "label": "Salareh Aia",
    "value": "Salareh Aia"
  },
  {
    "id": "1306122001",
    "district_id": "130612",
    "label": "Padang Laweh",
    "value": "Padang Laweh"
  },
  {
    "id": "1306122002",
    "district_id": "130612",
    "label": "Sungai Pua",
    "value": "Sungai Pua"
  },
  {
    "id": "1306122003",
    "district_id": "130612",
    "label": "Batagak",
    "value": "Batagak"
  },
  {
    "id": "1306122004",
    "district_id": "130612",
    "label": "Batu Palano",
    "value": "Batu Palano"
  },
  {
    "id": "1306122005",
    "district_id": "130612",
    "label": "Sariak",
    "value": "Sariak"
  },
  {
    "id": "1306132001",
    "district_id": "130613",
    "label": "Batu Kambing",
    "value": "Batu Kambing"
  },
  {
    "id": "1306132002",
    "district_id": "130613",
    "label": "Sitalang",
    "value": "Sitalang"
  },
  {
    "id": "1306132003",
    "district_id": "130613",
    "label": "Bawan",
    "value": "Bawan"
  },
  {
    "id": "1306132004",
    "district_id": "130613",
    "label": "Sitanang",
    "value": "Sitanang"
  },
  {
    "id": "1306142001",
    "district_id": "130614",
    "label": "Canduang Koto Laweh",
    "value": "Canduang Koto Laweh"
  },
  {
    "id": "1306142002",
    "district_id": "130614",
    "label": "Lasi",
    "value": "Lasi"
  },
  {
    "id": "1306142003",
    "district_id": "130614",
    "label": "Bukik Batabuah",
    "value": "Bukik Batabuah"
  },
  {
    "id": "1306152001",
    "district_id": "130615",
    "label": "Magek",
    "value": "Magek"
  },
  {
    "id": "1306152002",
    "district_id": "130615",
    "label": "Kamang Hilia",
    "value": "Kamang Hilia"
  },
  {
    "id": "1306152003",
    "district_id": "130615",
    "label": "Kamang Mudiak",
    "value": "Kamang Mudiak"
  },
  {
    "id": "1306162001",
    "district_id": "130616",
    "label": "Malalak Utara",
    "value": "Malalak Utara"
  },
  {
    "id": "1306162002",
    "district_id": "130616",
    "label": "Malalak Timur",
    "value": "Malalak Timur"
  },
  {
    "id": "1306162003",
    "district_id": "130616",
    "label": "Malalak Selatan",
    "value": "Malalak Selatan"
  },
  {
    "id": "1306162004",
    "district_id": "130616",
    "label": "Malalak Barat",
    "value": "Malalak Barat"
  },
  {
    "id": "1307012001",
    "district_id": "130701",
    "label": "Kurai",
    "value": "Kurai"
  },
  {
    "id": "1307012002",
    "district_id": "130701",
    "label": "Sungai Rimbang",
    "value": "Sungai Rimbang"
  },
  {
    "id": "1307012003",
    "district_id": "130701",
    "label": "Tanjuang Bungo",
    "value": "Tanjuang Bungo"
  },
  {
    "id": "1307012004",
    "district_id": "130701",
    "label": "Suliki",
    "value": "Suliki"
  },
  {
    "id": "1307012005",
    "district_id": "130701",
    "label": "Limbanang",
    "value": "Limbanang"
  },
  {
    "id": "1307012006",
    "district_id": "130701",
    "label": "Andiang",
    "value": "Andiang"
  },
  {
    "id": "1307022001",
    "district_id": "130702",
    "label": "Guguak VIII Koto",
    "value": "Guguak VIII Koto"
  },
  {
    "id": "1307022002",
    "district_id": "130702",
    "label": "VII Koto Talago",
    "value": "VII Koto Talago"
  },
  {
    "id": "1307022003",
    "district_id": "130702",
    "label": "Sungai Talang",
    "value": "Sungai Talang"
  },
  {
    "id": "1307022004",
    "district_id": "130702",
    "label": "Kubang",
    "value": "Kubang"
  },
  {
    "id": "1307022005",
    "district_id": "130702",
    "label": "Simpang Sugiran",
    "value": "Simpang Sugiran"
  },
  {
    "id": "1307032001",
    "district_id": "130703",
    "label": "Koto Baru Simalanggang",
    "value": "Koto Baru Simalanggang"
  },
  {
    "id": "1307032002",
    "district_id": "130703",
    "label": "Taeh Bukik",
    "value": "Taeh Bukik"
  },
  {
    "id": "1307032003",
    "district_id": "130703",
    "label": "Simalanggang",
    "value": "Simalanggang"
  },
  {
    "id": "1307032004",
    "district_id": "130703",
    "label": "Sungai Beringin",
    "value": "Sungai Beringin"
  },
  {
    "id": "1307032005",
    "district_id": "130703",
    "label": "Piobang",
    "value": "Piobang"
  },
  {
    "id": "1307032006",
    "district_id": "130703",
    "label": "Taeh Baruah",
    "value": "Taeh Baruah"
  },
  {
    "id": "1307032007",
    "district_id": "130703",
    "label": "Koto Tangah Simalanggang",
    "value": "Koto Tangah Simalanggang"
  },
  {
    "id": "1307042001",
    "district_id": "130704",
    "label": "Tj. Haro Sikabu-kabu Pd. Panjang",
    "value": "Tj. Haro Sikabu-kabu Pd. Panjang"
  },
  {
    "id": "1307042002",
    "district_id": "130704",
    "label": "Mungo",
    "value": "Mungo"
  },
  {
    "id": "1307042003",
    "district_id": "130704",
    "label": "Sungai Kamuyang",
    "value": "Sungai Kamuyang"
  },
  {
    "id": "1307042004",
    "district_id": "130704",
    "label": "Andaleh",
    "value": "Andaleh"
  },
  {
    "id": "1307052001",
    "district_id": "130705",
    "label": "Taram",
    "value": "Taram"
  },
  {
    "id": "1307052002",
    "district_id": "130705",
    "label": "Solok Bio Bio",
    "value": "Solok Bio Bio"
  },
  {
    "id": "1307052003",
    "district_id": "130705",
    "label": "Tarantang",
    "value": "Tarantang"
  },
  {
    "id": "1307052004",
    "district_id": "130705",
    "label": "Bukik Limbuku",
    "value": "Bukik Limbuku"
  },
  {
    "id": "1307052005",
    "district_id": "130705",
    "label": "Harau",
    "value": "Harau"
  },
  {
    "id": "1307052006",
    "district_id": "130705",
    "label": "Batu Balang",
    "value": "Batu Balang"
  },
  {
    "id": "1307052007",
    "district_id": "130705",
    "label": "Sarilamak",
    "value": "Sarilamak"
  },
  {
    "id": "1307052008",
    "district_id": "130705",
    "label": "Gurun",
    "value": "Gurun"
  },
  {
    "id": "1307052009",
    "district_id": "130705",
    "label": "Lubuak Batingkok",
    "value": "Lubuak Batingkok"
  },
  {
    "id": "1307052010",
    "district_id": "130705",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1307052011",
    "district_id": "130705",
    "label": "Pilubang",
    "value": "Pilubang"
  },
  {
    "id": "1307062001",
    "district_id": "130706",
    "label": "Manggilang",
    "value": "Manggilang"
  },
  {
    "id": "1307062002",
    "district_id": "130706",
    "label": "Tanjuang Balik",
    "value": "Tanjuang Balik"
  },
  {
    "id": "1307062003",
    "district_id": "130706",
    "label": "Gunuang Malintang",
    "value": "Gunuang Malintang"
  },
  {
    "id": "1307062004",
    "district_id": "130706",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1307062005",
    "district_id": "130706",
    "label": "Koto Alam",
    "value": "Koto Alam"
  },
  {
    "id": "1307062006",
    "district_id": "130706",
    "label": "Tanjung Pauh",
    "value": "Tanjung Pauh"
  },
  {
    "id": "1307072001",
    "district_id": "130707",
    "label": "Muaro Paiti",
    "value": "Muaro Paiti"
  },
  {
    "id": "1307072002",
    "district_id": "130707",
    "label": "Koto Bangun",
    "value": "Koto Bangun"
  },
  {
    "id": "1307072003",
    "district_id": "130707",
    "label": "Lubuak Alai",
    "value": "Lubuak Alai"
  },
  {
    "id": "1307072004",
    "district_id": "130707",
    "label": "Koto Lamo",
    "value": "Koto Lamo"
  },
  {
    "id": "1307072005",
    "district_id": "130707",
    "label": "Durian Tinggi",
    "value": "Durian Tinggi"
  },
  {
    "id": "1307072006",
    "district_id": "130707",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1307072007",
    "district_id": "130707",
    "label": "Galugua",
    "value": "Galugua"
  },
  {
    "id": "1307082001",
    "district_id": "130708",
    "label": "Pandam Gadang",
    "value": "Pandam Gadang"
  },
  {
    "id": "1307082002",
    "district_id": "130708",
    "label": "Koto Tinggi",
    "value": "Koto Tinggi"
  },
  {
    "id": "1307082003",
    "district_id": "130708",
    "label": "Talang Anau",
    "value": "Talang Anau"
  },
  {
    "id": "1307092001",
    "district_id": "130709",
    "label": "Labuah Gunuang",
    "value": "Labuah Gunuang"
  },
  {
    "id": "1307092002",
    "district_id": "130709",
    "label": "Batu Payuang",
    "value": "Batu Payuang"
  },
  {
    "id": "1307092003",
    "district_id": "130709",
    "label": "Ampalu",
    "value": "Ampalu"
  },
  {
    "id": "1307092004",
    "district_id": "130709",
    "label": "Bukik Sikumpa",
    "value": "Bukik Sikumpa"
  },
  {
    "id": "1307092005",
    "district_id": "130709",
    "label": "Balai Panjang",
    "value": "Balai Panjang"
  },
  {
    "id": "1307092006",
    "district_id": "130709",
    "label": "Halaban",
    "value": "Halaban"
  },
  {
    "id": "1307092007",
    "district_id": "130709",
    "label": "Tanjuang Gadang",
    "value": "Tanjuang Gadang"
  },
  {
    "id": "1307092008",
    "district_id": "130709",
    "label": "Sitanang",
    "value": "Sitanang"
  },
  {
    "id": "1307102001",
    "district_id": "130710",
    "label": "Situjuah Batua",
    "value": "Situjuah Batua"
  },
  {
    "id": "1307102002",
    "district_id": "130710",
    "label": "Situjuah Ladang Laweh",
    "value": "Situjuah Ladang Laweh"
  },
  {
    "id": "1307102003",
    "district_id": "130710",
    "label": "Tungka",
    "value": "Tungka"
  },
  {
    "id": "1307102004",
    "district_id": "130710",
    "label": "Situjuah Banda Dalam",
    "value": "Situjuah Banda Dalam"
  },
  {
    "id": "1307102005",
    "district_id": "130710",
    "label": "Situjuah Gadang",
    "value": "Situjuah Gadang"
  },
  {
    "id": "1307112001",
    "district_id": "130711",
    "label": "Jopang Manganti",
    "value": "Jopang Manganti"
  },
  {
    "id": "1307112002",
    "district_id": "130711",
    "label": "Simpang Kapuak",
    "value": "Simpang Kapuak"
  },
  {
    "id": "1307112003",
    "district_id": "130711",
    "label": "Mungka",
    "value": "Mungka"
  },
  {
    "id": "1307112004",
    "district_id": "130711",
    "label": "Talang Maur",
    "value": "Talang Maur"
  },
  {
    "id": "1307112005",
    "district_id": "130711",
    "label": "Sungai Antuan",
    "value": "Sungai Antuan"
  },
  {
    "id": "1307122001",
    "district_id": "130712",
    "label": "Maek",
    "value": "Maek"
  },
  {
    "id": "1307122002",
    "district_id": "130712",
    "label": "Banja Loweh",
    "value": "Banja Loweh"
  },
  {
    "id": "1307122003",
    "district_id": "130712",
    "label": "Baruah Gunuang",
    "value": "Baruah Gunuang"
  },
  {
    "id": "1307122004",
    "district_id": "130712",
    "label": "Sungai Naniang",
    "value": "Sungai Naniang"
  },
  {
    "id": "1307122005",
    "district_id": "130712",
    "label": "Koto Tangah",
    "value": "Koto Tangah"
  },
  {
    "id": "1307132001",
    "district_id": "130713",
    "label": "Sariak Laweh",
    "value": "Sariak Laweh"
  },
  {
    "id": "1307132002",
    "district_id": "130713",
    "label": "Koto Tangah Batu Ampa",
    "value": "Koto Tangah Batu Ampa"
  },
  {
    "id": "1307132003",
    "district_id": "130713",
    "label": "Batuhampar",
    "value": "Batuhampar"
  },
  {
    "id": "1307132004",
    "district_id": "130713",
    "label": "Suayan",
    "value": "Suayan"
  },
  {
    "id": "1307132005",
    "district_id": "130713",
    "label": "Sungai Balantiak",
    "value": "Sungai Balantiak"
  },
  {
    "id": "1307132006",
    "district_id": "130713",
    "label": "Pauh Sangik",
    "value": "Pauh Sangik"
  },
  {
    "id": "1307132007",
    "district_id": "130713",
    "label": "Durian Gadang",
    "value": "Durian Gadang"
  },
  {
    "id": "1308042001",
    "district_id": "130804",
    "label": "Limo Koto",
    "value": "Limo Koto"
  },
  {
    "id": "1308042002",
    "district_id": "130804",
    "label": "Koto Kaciak",
    "value": "Koto Kaciak"
  },
  {
    "id": "1308042003",
    "district_id": "130804",
    "label": "Ganggo Mudiak",
    "value": "Ganggo Mudiak"
  },
  {
    "id": "1308042004",
    "district_id": "130804",
    "label": "Ganggo Hilia",
    "value": "Ganggo Hilia"
  },
  {
    "id": "1308052001",
    "district_id": "130805",
    "label": "Tanjuang Baringin",
    "value": "Tanjuang Baringin"
  },
  {
    "id": "1308052002",
    "district_id": "130805",
    "label": "Jambak",
    "value": "Jambak"
  },
  {
    "id": "1308052003",
    "district_id": "130805",
    "label": "Durian Tinggi",
    "value": "Durian Tinggi"
  },
  {
    "id": "1308052004",
    "district_id": "130805",
    "label": "Pauah",
    "value": "Pauah"
  },
  {
    "id": "1308052005",
    "district_id": "130805",
    "label": "Aia Manggih",
    "value": "Aia Manggih"
  },
  {
    "id": "1308052006",
    "district_id": "130805",
    "label": "Sundata",
    "value": "Sundata"
  },
  {
    "id": "1308072001",
    "district_id": "130807",
    "label": "Panti",
    "value": "Panti"
  },
  {
    "id": "1308072003",
    "district_id": "130807",
    "label": "Panti Timur",
    "value": "Panti Timur"
  },
  {
    "id": "1308072004",
    "district_id": "130807",
    "label": "Panti Selatan",
    "value": "Panti Selatan"
  },
  {
    "id": "1308082001",
    "district_id": "130808",
    "label": "Muara Tais",
    "value": "Muara Tais"
  },
  {
    "id": "1308082002",
    "district_id": "130808",
    "label": "Lubuak Gadang",
    "value": "Lubuak Gadang"
  },
  {
    "id": "1308082005",
    "district_id": "130808",
    "label": "Pintu Padang",
    "value": "Pintu Padang"
  },
  {
    "id": "1308122001",
    "district_id": "130812",
    "label": "Simpang Tonang",
    "value": "Simpang Tonang"
  },
  {
    "id": "1308122002",
    "district_id": "130812",
    "label": "Cubadak",
    "value": "Cubadak"
  },
  {
    "id": "1308132001",
    "district_id": "130813",
    "label": "Malampah",
    "value": "Malampah"
  },
  {
    "id": "1308132002",
    "district_id": "130813",
    "label": "Ladang Panjang",
    "value": "Ladang Panjang"
  },
  {
    "id": "1308132003",
    "district_id": "130813",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1308142002",
    "district_id": "130814",
    "label": "Taruang Taruang",
    "value": "Taruang Taruang"
  },
  {
    "id": "1308142005",
    "district_id": "130814",
    "label": "Padang Mantinggi",
    "value": "Padang Mantinggi"
  },
  {
    "id": "1308152001",
    "district_id": "130815",
    "label": "Silayang",
    "value": "Silayang"
  },
  {
    "id": "1308152002",
    "district_id": "130815",
    "label": "Muaro Sungai Lolo",
    "value": "Muaro Sungai Lolo"
  },
  {
    "id": "1308162001",
    "district_id": "130816",
    "label": "Alahan Mati",
    "value": "Alahan Mati"
  },
  {
    "id": "1308162002",
    "district_id": "130816",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1308172001",
    "district_id": "130817",
    "label": "Padang Gelugur",
    "value": "Padang Gelugur"
  },
  {
    "id": "1308172002",
    "district_id": "130817",
    "label": "Sontang Cubadak",
    "value": "Sontang Cubadak"
  },
  {
    "id": "1308172003",
    "district_id": "130817",
    "label": "Sitombol Padang Gelugua",
    "value": "Sitombol Padang Gelugua"
  },
  {
    "id": "1308172004",
    "district_id": "130817",
    "label": "Bahagia Padang Gelugua",
    "value": "Bahagia Padang Gelugua"
  },
  {
    "id": "1308182001",
    "district_id": "130818",
    "label": "Koto Nopan",
    "value": "Koto Nopan"
  },
  {
    "id": "1308182002",
    "district_id": "130818",
    "label": "Koto Rajo",
    "value": "Koto Rajo"
  },
  {
    "id": "1308182003",
    "district_id": "130818",
    "label": "Languang",
    "value": "Languang"
  },
  {
    "id": "1308192001",
    "district_id": "130819",
    "label": "Lansek Kadok",
    "value": "Lansek Kadok"
  },
  {
    "id": "1308192002",
    "district_id": "130819",
    "label": "Lubuak Layang",
    "value": "Lubuak Layang"
  },
  {
    "id": "1308192003",
    "district_id": "130819",
    "label": "Tanjung Betung",
    "value": "Tanjung Betung"
  },
  {
    "id": "1309012007",
    "district_id": "130901",
    "label": "Betumonga",
    "value": "Betumonga"
  },
  {
    "id": "1309012008",
    "district_id": "130901",
    "label": "Silabu",
    "value": "Silabu"
  },
  {
    "id": "1309012009",
    "district_id": "130901",
    "label": "Saumanganya",
    "value": "Saumanganya"
  },
  {
    "id": "1309022001",
    "district_id": "130902",
    "label": "Bosua",
    "value": "Bosua"
  },
  {
    "id": "1309022002",
    "district_id": "130902",
    "label": "Beriulou",
    "value": "Beriulou"
  },
  {
    "id": "1309022003",
    "district_id": "130902",
    "label": "Nemnemleleu",
    "value": "Nemnemleleu"
  },
  {
    "id": "1309022004",
    "district_id": "130902",
    "label": "Mara",
    "value": "Mara"
  },
  {
    "id": "1309022006",
    "district_id": "130902",
    "label": "Sioban",
    "value": "Sioban"
  },
  {
    "id": "1309022007",
    "district_id": "130902",
    "label": "Matobe",
    "value": "Matobe"
  },
  {
    "id": "1309022008",
    "district_id": "130902",
    "label": "Saureinu",
    "value": "Saureinu"
  },
  {
    "id": "1309032002",
    "district_id": "130903",
    "label": "Muara Siberut",
    "value": "Muara Siberut"
  },
  {
    "id": "1309032003",
    "district_id": "130903",
    "label": "Maileppet",
    "value": "Maileppet"
  },
  {
    "id": "1309032004",
    "district_id": "130903",
    "label": "Muntei",
    "value": "Muntei"
  },
  {
    "id": "1309032005",
    "district_id": "130903",
    "label": "Matotonan",
    "value": "Matotonan"
  },
  {
    "id": "1309032007",
    "district_id": "130903",
    "label": "Madobag",
    "value": "Madobag"
  },
  {
    "id": "1309042003",
    "district_id": "130904",
    "label": "Bojakan",
    "value": "Bojakan"
  },
  {
    "id": "1309042004",
    "district_id": "130904",
    "label": "Sotboyak",
    "value": "Sotboyak"
  },
  {
    "id": "1309042005",
    "district_id": "130904",
    "label": "Mongan Poula",
    "value": "Mongan Poula"
  },
  {
    "id": "1309042006",
    "district_id": "130904",
    "label": "Muara Sikabaluan",
    "value": "Muara Sikabaluan"
  },
  {
    "id": "1309042007",
    "district_id": "130904",
    "label": "Sirilogui",
    "value": "Sirilogui"
  },
  {
    "id": "1309042008",
    "district_id": "130904",
    "label": "Malancan",
    "value": "Malancan"
  },
  {
    "id": "1309052001",
    "district_id": "130905",
    "label": "Simatalu",
    "value": "Simatalu"
  },
  {
    "id": "1309052002",
    "district_id": "130905",
    "label": "Simalegi",
    "value": "Simalegi"
  },
  {
    "id": "1309052003",
    "district_id": "130905",
    "label": "Sigapokna",
    "value": "Sigapokna"
  },
  {
    "id": "1309062001",
    "district_id": "130906",
    "label": "Katurei",
    "value": "Katurei"
  },
  {
    "id": "1309062002",
    "district_id": "130906",
    "label": "Sagulubbeg",
    "value": "Sagulubbeg"
  },
  {
    "id": "1309062003",
    "district_id": "130906",
    "label": "Pasakiat Taileleu",
    "value": "Pasakiat Taileleu"
  },
  {
    "id": "1309072001",
    "district_id": "130907",
    "label": "Saibi Samukop",
    "value": "Saibi Samukop"
  },
  {
    "id": "1309072002",
    "district_id": "130907",
    "label": "Cimpungan",
    "value": "Cimpungan"
  },
  {
    "id": "1309072003",
    "district_id": "130907",
    "label": "Saliguma",
    "value": "Saliguma"
  },
  {
    "id": "1309082001",
    "district_id": "130908",
    "label": "Betumonga",
    "value": "Betumonga"
  },
  {
    "id": "1309082002",
    "district_id": "130908",
    "label": "Goisooinan",
    "value": "Goisooinan"
  },
  {
    "id": "1309082003",
    "district_id": "130908",
    "label": "Tuapejat",
    "value": "Tuapejat"
  },
  {
    "id": "1309082004",
    "district_id": "130908",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1309082005",
    "district_id": "130908",
    "label": "Bukit Pamewa",
    "value": "Bukit Pamewa"
  },
  {
    "id": "1309082006",
    "district_id": "130908",
    "label": "Sipora Jaya",
    "value": "Sipora Jaya"
  },
  {
    "id": "1309092001",
    "district_id": "130909",
    "label": "Sikakap",
    "value": "Sikakap"
  },
  {
    "id": "1309092002",
    "district_id": "130909",
    "label": "Taikako",
    "value": "Taikako"
  },
  {
    "id": "1309092003",
    "district_id": "130909",
    "label": "Matobe",
    "value": "Matobe"
  },
  {
    "id": "1309102001",
    "district_id": "130910",
    "label": "Sinaka",
    "value": "Sinaka"
  },
  {
    "id": "1309102002",
    "district_id": "130910",
    "label": "Bulasat",
    "value": "Bulasat"
  },
  {
    "id": "1309102003",
    "district_id": "130910",
    "label": "Malakopa",
    "value": "Malakopa"
  },
  {
    "id": "1309102004",
    "district_id": "130910",
    "label": "Makalo",
    "value": "Makalo"
  },
  {
    "id": "1310012002",
    "district_id": "131001",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1310012003",
    "district_id": "131001",
    "label": "Sialang Gaung",
    "value": "Sialang Gaung"
  },
  {
    "id": "1310012005",
    "district_id": "131001",
    "label": "Ampang Kuranji",
    "value": "Ampang Kuranji"
  },
  {
    "id": "1310012008",
    "district_id": "131001",
    "label": "Koto Padang",
    "value": "Koto Padang"
  },
  {
    "id": "1310022001",
    "district_id": "131002",
    "label": "Sungai Dareh",
    "value": "Sungai Dareh"
  },
  {
    "id": "1310022002",
    "district_id": "131002",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1310022006",
    "district_id": "131002",
    "label": "Empat Koto Pulau Punjung",
    "value": "Empat Koto Pulau Punjung"
  },
  {
    "id": "1310022007",
    "district_id": "131002",
    "label": "Sungai Kambut",
    "value": "Sungai Kambut"
  },
  {
    "id": "1310022008",
    "district_id": "131002",
    "label": "Gunung Selasih",
    "value": "Gunung Selasih"
  },
  {
    "id": "1310022009",
    "district_id": "131002",
    "label": "Sikabau",
    "value": "Sikabau"
  },
  {
    "id": "1310032003",
    "district_id": "131003",
    "label": "Sungai Rumbai",
    "value": "Sungai Rumbai"
  },
  {
    "id": "1310032004",
    "district_id": "131003",
    "label": "Kurnia Koto Salak",
    "value": "Kurnia Koto Salak"
  },
  {
    "id": "1310032007",
    "district_id": "131003",
    "label": "Sungai Rumbai Timur",
    "value": "Sungai Rumbai Timur"
  },
  {
    "id": "1310032008",
    "district_id": "131003",
    "label": "Kurnia Selatan",
    "value": "Kurnia Selatan"
  },
  {
    "id": "1310042001",
    "district_id": "131004",
    "label": "Sitiung",
    "value": "Sitiung"
  },
  {
    "id": "1310042002",
    "district_id": "131004",
    "label": "Siguntur",
    "value": "Siguntur"
  },
  {
    "id": "1310042004",
    "district_id": "131004",
    "label": "Gunung Medan",
    "value": "Gunung Medan"
  },
  {
    "id": "1310042005",
    "district_id": "131004",
    "label": "Sungai Duo",
    "value": "Sungai Duo"
  },
  {
    "id": "1310052001",
    "district_id": "131005",
    "label": "Silago",
    "value": "Silago"
  },
  {
    "id": "1310052002",
    "district_id": "131005",
    "label": "Lubuak Karak",
    "value": "Lubuak Karak"
  },
  {
    "id": "1310052003",
    "district_id": "131005",
    "label": "Koto Nan Empat Dibawuh",
    "value": "Koto Nan Empat Dibawuh"
  },
  {
    "id": "1310052004",
    "district_id": "131005",
    "label": "Banai",
    "value": "Banai"
  },
  {
    "id": "1310062001",
    "district_id": "131006",
    "label": "Timpeh",
    "value": "Timpeh"
  },
  {
    "id": "1310062002",
    "district_id": "131006",
    "label": "Taratak Tinggi",
    "value": "Taratak Tinggi"
  },
  {
    "id": "1310062003",
    "district_id": "131006",
    "label": "Tabek",
    "value": "Tabek"
  },
  {
    "id": "1310062004",
    "district_id": "131006",
    "label": "Panyubarangan",
    "value": "Panyubarangan"
  },
  {
    "id": "1310062005",
    "district_id": "131006",
    "label": "Ranah Palabi",
    "value": "Ranah Palabi"
  },
  {
    "id": "1310072001",
    "district_id": "131007",
    "label": "Koto Salak",
    "value": "Koto Salak"
  },
  {
    "id": "1310072002",
    "district_id": "131007",
    "label": "Ampalu",
    "value": "Ampalu"
  },
  {
    "id": "1310072003",
    "district_id": "131007",
    "label": "Padukuan",
    "value": "Padukuan"
  },
  {
    "id": "1310072004",
    "district_id": "131007",
    "label": "Pulau Mainan",
    "value": "Pulau Mainan"
  },
  {
    "id": "1310072005",
    "district_id": "131007",
    "label": "Simalidu",
    "value": "Simalidu"
  },
  {
    "id": "1310082001",
    "district_id": "131008",
    "label": "Tiumang",
    "value": "Tiumang"
  },
  {
    "id": "1310082002",
    "district_id": "131008",
    "label": "Sungai Langkok",
    "value": "Sungai Langkok"
  },
  {
    "id": "1310082003",
    "district_id": "131008",
    "label": "Koto Beringin",
    "value": "Koto Beringin"
  },
  {
    "id": "1310082004",
    "district_id": "131008",
    "label": "Sipangkur",
    "value": "Sipangkur"
  },
  {
    "id": "1310092001",
    "district_id": "131009",
    "label": "Padang Laweh",
    "value": "Padang Laweh"
  },
  {
    "id": "1310092002",
    "district_id": "131009",
    "label": "Batu Rijal",
    "value": "Batu Rijal"
  },
  {
    "id": "1310092003",
    "district_id": "131009",
    "label": "Muaro Sopan",
    "value": "Muaro Sopan"
  },
  {
    "id": "1310092004",
    "district_id": "131009",
    "label": "Sopan Jaya",
    "value": "Sopan Jaya"
  },
  {
    "id": "1310102001",
    "district_id": "131010",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "1310102002",
    "district_id": "131010",
    "label": "Alahan Nan Tigo",
    "value": "Alahan Nan Tigo"
  },
  {
    "id": "1310102003",
    "district_id": "131010",
    "label": "Lubuk Besar",
    "value": "Lubuk Besar"
  },
  {
    "id": "1310102004",
    "district_id": "131010",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1310102005",
    "district_id": "131010",
    "label": "Sinamar",
    "value": "Sinamar"
  },
  {
    "id": "1310112001",
    "district_id": "131011",
    "label": "Koto Besar",
    "value": "Koto Besar"
  },
  {
    "id": "1310112002",
    "district_id": "131011",
    "label": "Koto Tinggi",
    "value": "Koto Tinggi"
  },
  {
    "id": "1310112003",
    "district_id": "131011",
    "label": "Koto Gadang",
    "value": "Koto Gadang"
  },
  {
    "id": "1310112004",
    "district_id": "131011",
    "label": "Bonjol",
    "value": "Bonjol"
  },
  {
    "id": "1310112005",
    "district_id": "131011",
    "label": "Abai Siat",
    "value": "Abai Siat"
  },
  {
    "id": "1310112006",
    "district_id": "131011",
    "label": "Koto Laweh",
    "value": "Koto Laweh"
  },
  {
    "id": "1310112007",
    "district_id": "131011",
    "label": "Koto Ranah",
    "value": "Koto Ranah"
  },
  {
    "id": "1311012001",
    "district_id": "131101",
    "label": "Lubuk Gadang",
    "value": "Lubuk Gadang"
  },
  {
    "id": "1311012002",
    "district_id": "131101",
    "label": "Lubuk Gadang Timur",
    "value": "Lubuk Gadang Timur"
  },
  {
    "id": "1311012003",
    "district_id": "131101",
    "label": "Lubuk Gadang Selatan",
    "value": "Lubuk Gadang Selatan"
  },
  {
    "id": "1311012004",
    "district_id": "131101",
    "label": "Lubuk Gadang Utara",
    "value": "Lubuk Gadang Utara"
  },
  {
    "id": "1311022001",
    "district_id": "131102",
    "label": "Pasir Talang",
    "value": "Pasir Talang"
  },
  {
    "id": "1311022002",
    "district_id": "131102",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1311022004",
    "district_id": "131102",
    "label": "Sako Pasia Talang",
    "value": "Sako Pasia Talang"
  },
  {
    "id": "1311022005",
    "district_id": "131102",
    "label": "Pasar Muara Labuh",
    "value": "Pasar Muara Labuh"
  },
  {
    "id": "1311022006",
    "district_id": "131102",
    "label": "Pulakek Koto Baru",
    "value": "Pulakek Koto Baru"
  },
  {
    "id": "1311022007",
    "district_id": "131102",
    "label": "Bomas",
    "value": "Bomas"
  },
  {
    "id": "1311022008",
    "district_id": "131102",
    "label": "Sako Utara Pasia Talang",
    "value": "Sako Utara Pasia Talang"
  },
  {
    "id": "1311022009",
    "district_id": "131102",
    "label": "Sako Selatan Pasia Talang",
    "value": "Sako Selatan Pasia Talang"
  },
  {
    "id": "1311022010",
    "district_id": "131102",
    "label": "Pasir Talang Barat",
    "value": "Pasir Talang Barat"
  },
  {
    "id": "1311022011",
    "district_id": "131102",
    "label": "Pasir Talang Timur",
    "value": "Pasir Talang Timur"
  },
  {
    "id": "1311022012",
    "district_id": "131102",
    "label": "Pasir Talang Selatan",
    "value": "Pasir Talang Selatan"
  },
  {
    "id": "1311032001",
    "district_id": "131103",
    "label": "Pakan Rabaa",
    "value": "Pakan Rabaa"
  },
  {
    "id": "1311032002",
    "district_id": "131103",
    "label": "Pakan Rabaa Timur",
    "value": "Pakan Rabaa Timur"
  },
  {
    "id": "1311032003",
    "district_id": "131103",
    "label": "Pakan Rabaa Utara",
    "value": "Pakan Rabaa Utara"
  },
  {
    "id": "1311032004",
    "district_id": "131103",
    "label": "Pakan Rabaa Tengah",
    "value": "Pakan Rabaa Tengah"
  },
  {
    "id": "1311042001",
    "district_id": "131104",
    "label": "Lubuk Malako",
    "value": "Lubuk Malako"
  },
  {
    "id": "1311042002",
    "district_id": "131104",
    "label": "Bidar Alam",
    "value": "Bidar Alam"
  },
  {
    "id": "1311042004",
    "district_id": "131104",
    "label": "Padang Air Dingin",
    "value": "Padang Air Dingin"
  },
  {
    "id": "1311042005",
    "district_id": "131104",
    "label": "Padang Limau Sundai",
    "value": "Padang Limau Sundai"
  },
  {
    "id": "1311042006",
    "district_id": "131104",
    "label": "Padang Gantiang",
    "value": "Padang Gantiang"
  },
  {
    "id": "1311052001",
    "district_id": "131105",
    "label": "Abai",
    "value": "Abai"
  },
  {
    "id": "1311052002",
    "district_id": "131105",
    "label": "Dusun Tangah",
    "value": "Dusun Tangah"
  },
  {
    "id": "1311052003",
    "district_id": "131105",
    "label": "Lubuk Ulang Aling",
    "value": "Lubuk Ulang Aling"
  },
  {
    "id": "1311052004",
    "district_id": "131105",
    "label": "Ranah Pantai Cermin",
    "value": "Ranah Pantai Cermin"
  },
  {
    "id": "1311052005",
    "district_id": "131105",
    "label": "Sitapus",
    "value": "Sitapus"
  },
  {
    "id": "1311052006",
    "district_id": "131105",
    "label": "Lubuk Ulang Aling Selatan",
    "value": "Lubuk Ulang Aling Selatan"
  },
  {
    "id": "1311052007",
    "district_id": "131105",
    "label": "Lubuk Ulang Aling Tengah",
    "value": "Lubuk Ulang Aling Tengah"
  },
  {
    "id": "1311062001",
    "district_id": "131106",
    "label": "Alam Pauh Duo",
    "value": "Alam Pauh Duo"
  },
  {
    "id": "1311062002",
    "district_id": "131106",
    "label": "Kapau Alam Pauh Duo",
    "value": "Kapau Alam Pauh Duo"
  },
  {
    "id": "1311062003",
    "district_id": "131106",
    "label": "Luak Kapau Alam Pauh Duo",
    "value": "Luak Kapau Alam Pauh Duo"
  },
  {
    "id": "1311062004",
    "district_id": "131106",
    "label": "Pauh Duo Nan Batigo",
    "value": "Pauh Duo Nan Batigo"
  },
  {
    "id": "1311072001",
    "district_id": "131107",
    "label": "Sungai Kunyit",
    "value": "Sungai Kunyit"
  },
  {
    "id": "1311072002",
    "district_id": "131107",
    "label": "Talao Sungai Kunyit",
    "value": "Talao Sungai Kunyit"
  },
  {
    "id": "1311072003",
    "district_id": "131107",
    "label": "Sungai Kunyit Barat",
    "value": "Sungai Kunyit Barat"
  },
  {
    "id": "1311072004",
    "district_id": "131107",
    "label": "Talunan Maju",
    "value": "Talunan Maju"
  },
  {
    "id": "1312012001",
    "district_id": "131201",
    "label": "Aia Bangih",
    "value": "Aia Bangih"
  },
  {
    "id": "1312022001",
    "district_id": "131202",
    "label": "Ujung Gading",
    "value": "Ujung Gading"
  },
  {
    "id": "1312032003",
    "district_id": "131203",
    "label": "Lingkuang Aua",
    "value": "Lingkuang Aua"
  },
  {
    "id": "1312032004",
    "district_id": "131203",
    "label": "Aua Kuniang",
    "value": "Aua Kuniang"
  },
  {
    "id": "1312032006",
    "district_id": "131203",
    "label": "Aia Gadang",
    "value": "Aia Gadang"
  },
  {
    "id": "1312042001",
    "district_id": "131204",
    "label": "Kajai",
    "value": "Kajai"
  },
  {
    "id": "1312042002",
    "district_id": "131204",
    "label": "Talu",
    "value": "Talu"
  },
  {
    "id": "1312042003",
    "district_id": "131204",
    "label": "Sinuruik",
    "value": "Sinuruik"
  },
  {
    "id": "1312052001",
    "district_id": "131205",
    "label": "Kinali",
    "value": "Kinali"
  },
  {
    "id": "1312052002",
    "district_id": "131205",
    "label": "Katiagan",
    "value": "Katiagan"
  },
  {
    "id": "1312062001",
    "district_id": "131206",
    "label": "Muaro Kiawai",
    "value": "Muaro Kiawai"
  },
  {
    "id": "1312062002",
    "district_id": "131206",
    "label": "Rabi Jonggor",
    "value": "Rabi Jonggor"
  },
  {
    "id": "1312072001",
    "district_id": "131207",
    "label": "Batahan",
    "value": "Batahan"
  },
  {
    "id": "1312072002",
    "district_id": "131207",
    "label": "Desa Baru",
    "value": "Desa Baru"
  },
  {
    "id": "1312082001",
    "district_id": "131208",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "1312092001",
    "district_id": "131209",
    "label": "Sungai Aua",
    "value": "Sungai Aua"
  },
  {
    "id": "1312102001",
    "district_id": "131210",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1312102002",
    "district_id": "131210",
    "label": "Kapa",
    "value": "Kapa"
  },
  {
    "id": "1312112001",
    "district_id": "131211",
    "label": "Sasak",
    "value": "Sasak"
  },
  {
    "id": "1371011001",
    "district_id": "137101",
    "label": "Belakang Pondok",
    "value": "Belakang Pondok"
  },
  {
    "id": "1371011002",
    "district_id": "137101",
    "label": "Alang Laweh",
    "value": "Alang Laweh"
  },
  {
    "id": "1371011003",
    "district_id": "137101",
    "label": "Ranah Parak Rumbio",
    "value": "Ranah Parak Rumbio"
  },
  {
    "id": "1371011004",
    "district_id": "137101",
    "label": "Pasa Gadang",
    "value": "Pasa Gadang"
  },
  {
    "id": "1371011005",
    "district_id": "137101",
    "label": "Batang Arau",
    "value": "Batang Arau"
  },
  {
    "id": "1371011006",
    "district_id": "137101",
    "label": "Seberang Palinggam",
    "value": "Seberang Palinggam"
  },
  {
    "id": "1371011007",
    "district_id": "137101",
    "label": "Seberang Padang",
    "value": "Seberang Padang"
  },
  {
    "id": "1371011008",
    "district_id": "137101",
    "label": "Mata Air",
    "value": "Mata Air"
  },
  {
    "id": "1371011009",
    "district_id": "137101",
    "label": "Rawang",
    "value": "Rawang"
  },
  {
    "id": "1371011010",
    "district_id": "137101",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "1371011011",
    "district_id": "137101",
    "label": "Air Manis",
    "value": "Air Manis"
  },
  {
    "id": "1371011012",
    "district_id": "137101",
    "label": "Bukit Gado-gado",
    "value": "Bukit Gado-gado"
  },
  {
    "id": "1371021001",
    "district_id": "137102",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "1371021002",
    "district_id": "137102",
    "label": "Jati Baru",
    "value": "Jati Baru"
  },
  {
    "id": "1371021003",
    "district_id": "137102",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "1371021004",
    "district_id": "137102",
    "label": "Sawahan Timur",
    "value": "Sawahan Timur"
  },
  {
    "id": "1371021005",
    "district_id": "137102",
    "label": "Simpang Haru",
    "value": "Simpang Haru"
  },
  {
    "id": "1371021006",
    "district_id": "137102",
    "label": "Kubu Marapalam",
    "value": "Kubu Marapalam"
  },
  {
    "id": "1371021007",
    "district_id": "137102",
    "label": "Andalas",
    "value": "Andalas"
  },
  {
    "id": "1371021008",
    "district_id": "137102",
    "label": "Kubu Dalam Parak Karakah",
    "value": "Kubu Dalam Parak Karakah"
  },
  {
    "id": "1371021009",
    "district_id": "137102",
    "label": "Parak Gadang Timur",
    "value": "Parak Gadang Timur"
  },
  {
    "id": "1371021010",
    "district_id": "137102",
    "label": "Ganting Parak Gadang",
    "value": "Ganting Parak Gadang"
  },
  {
    "id": "1371031001",
    "district_id": "137103",
    "label": "Flamboyan Baru",
    "value": "Flamboyan Baru"
  },
  {
    "id": "1371031002",
    "district_id": "137103",
    "label": "Rimbo Kaluang",
    "value": "Rimbo Kaluang"
  },
  {
    "id": "1371031003",
    "district_id": "137103",
    "label": "Ujung Gurun",
    "value": "Ujung Gurun"
  },
  {
    "id": "1371031004",
    "district_id": "137103",
    "label": "Purus",
    "value": "Purus"
  },
  {
    "id": "1371031005",
    "district_id": "137103",
    "label": "Padang Pasir",
    "value": "Padang Pasir"
  },
  {
    "id": "1371031006",
    "district_id": "137103",
    "label": "Olo",
    "value": "Olo"
  },
  {
    "id": "1371031007",
    "district_id": "137103",
    "label": "Kampung Jao",
    "value": "Kampung Jao"
  },
  {
    "id": "1371031008",
    "district_id": "137103",
    "label": "Belakang Tangsi",
    "value": "Belakang Tangsi"
  },
  {
    "id": "1371031009",
    "district_id": "137103",
    "label": "Kampung Pondok",
    "value": "Kampung Pondok"
  },
  {
    "id": "1371031010",
    "district_id": "137103",
    "label": "Berok Nipah",
    "value": "Berok Nipah"
  },
  {
    "id": "1371041001",
    "district_id": "137104",
    "label": "Air Tawar Timur",
    "value": "Air Tawar Timur"
  },
  {
    "id": "1371041002",
    "district_id": "137104",
    "label": "Air Tawar Barat",
    "value": "Air Tawar Barat"
  },
  {
    "id": "1371041003",
    "district_id": "137104",
    "label": "Ulak Karang Utara",
    "value": "Ulak Karang Utara"
  },
  {
    "id": "1371041004",
    "district_id": "137104",
    "label": "Ulak Karang Selatan",
    "value": "Ulak Karang Selatan"
  },
  {
    "id": "1371041005",
    "district_id": "137104",
    "label": "Lolong Belanti",
    "value": "Lolong Belanti"
  },
  {
    "id": "1371041006",
    "district_id": "137104",
    "label": "Alai Parak Kopi",
    "value": "Alai Parak Kopi"
  },
  {
    "id": "1371041007",
    "district_id": "137104",
    "label": "Gunung Pangilun",
    "value": "Gunung Pangilun"
  },
  {
    "id": "1371051001",
    "district_id": "137105",
    "label": "Bungus Timur",
    "value": "Bungus Timur"
  },
  {
    "id": "1371051002",
    "district_id": "137105",
    "label": "Bungus Barat",
    "value": "Bungus Barat"
  },
  {
    "id": "1371051003",
    "district_id": "137105",
    "label": "Bungus Selatan",
    "value": "Bungus Selatan"
  },
  {
    "id": "1371051004",
    "district_id": "137105",
    "label": "Teluk Kabung Utara",
    "value": "Teluk Kabung Utara"
  },
  {
    "id": "1371051005",
    "district_id": "137105",
    "label": "Teluk Kabung Tengah",
    "value": "Teluk Kabung Tengah"
  },
  {
    "id": "1371051006",
    "district_id": "137105",
    "label": "Teluk Kabung Selatan",
    "value": "Teluk Kabung Selatan"
  },
  {
    "id": "1371061001",
    "district_id": "137106",
    "label": "Cangkeh Nan XX",
    "value": "Cangkeh Nan XX"
  },
  {
    "id": "1371061002",
    "district_id": "137106",
    "label": "Kampung Baru Nan XX",
    "value": "Kampung Baru Nan XX"
  },
  {
    "id": "1371061003",
    "district_id": "137106",
    "label": "Tanah Sirah Piai Nan XX",
    "value": "Tanah Sirah Piai Nan XX"
  },
  {
    "id": "1371061004",
    "district_id": "137106",
    "label": "Tanjung Saba Pitamen Nan XX",
    "value": "Tanjung Saba Pitamen Nan XX"
  },
  {
    "id": "1371061005",
    "district_id": "137106",
    "label": "Lubuk Begalung Nan XX",
    "value": "Lubuk Begalung Nan XX"
  },
  {
    "id": "1371061006",
    "district_id": "137106",
    "label": "Gurun Laweh Nan XX",
    "value": "Gurun Laweh Nan XX"
  },
  {
    "id": "1371061007",
    "district_id": "137106",
    "label": "Tanjung Aua Nan XX",
    "value": "Tanjung Aua Nan XX"
  },
  {
    "id": "1371061008",
    "district_id": "137106",
    "label": "Koto Baru Nan XX",
    "value": "Koto Baru Nan XX"
  },
  {
    "id": "1371061009",
    "district_id": "137106",
    "label": "Banuaran Nan XX",
    "value": "Banuaran Nan XX"
  },
  {
    "id": "1371061010",
    "district_id": "137106",
    "label": "Parak Laweh Pulau Aia Nan XX",
    "value": "Parak Laweh Pulau Aia Nan XX"
  },
  {
    "id": "1371061011",
    "district_id": "137106",
    "label": "Batung Taba Nan XX",
    "value": "Batung Taba Nan XX"
  },
  {
    "id": "1371061012",
    "district_id": "137106",
    "label": "Pagambiran Ampalu Nan XX",
    "value": "Pagambiran Ampalu Nan XX"
  },
  {
    "id": "1371061013",
    "district_id": "137106",
    "label": "Pampangan Nan XX",
    "value": "Pampangan Nan XX"
  },
  {
    "id": "1371061014",
    "district_id": "137106",
    "label": "Gates Nan XX",
    "value": "Gates Nan XX"
  },
  {
    "id": "1371061015",
    "district_id": "137106",
    "label": "Kampung Jua Nan XX",
    "value": "Kampung Jua Nan XX"
  },
  {
    "id": "1371071001",
    "district_id": "137107",
    "label": "Indarung",
    "value": "Indarung"
  },
  {
    "id": "1371071002",
    "district_id": "137107",
    "label": "Padang Besi",
    "value": "Padang Besi"
  },
  {
    "id": "1371071003",
    "district_id": "137107",
    "label": "Batu Gadang",
    "value": "Batu Gadang"
  },
  {
    "id": "1371071004",
    "district_id": "137107",
    "label": "Bandar Buat",
    "value": "Bandar Buat"
  },
  {
    "id": "1371071005",
    "district_id": "137107",
    "label": "Koto Lalang",
    "value": "Koto Lalang"
  },
  {
    "id": "1371071006",
    "district_id": "137107",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "1371071007",
    "district_id": "137107",
    "label": "Tarantang",
    "value": "Tarantang"
  },
  {
    "id": "1371081001",
    "district_id": "137108",
    "label": "Limau Manis",
    "value": "Limau Manis"
  },
  {
    "id": "1371081002",
    "district_id": "137108",
    "label": "Koto Lua",
    "value": "Koto Lua"
  },
  {
    "id": "1371081003",
    "district_id": "137108",
    "label": "Limau Manis Selatan",
    "value": "Limau Manis Selatan"
  },
  {
    "id": "1371081004",
    "district_id": "137108",
    "label": "Piai Tangah",
    "value": "Piai Tangah"
  },
  {
    "id": "1371081005",
    "district_id": "137108",
    "label": "Cupak Tangah",
    "value": "Cupak Tangah"
  },
  {
    "id": "1371081006",
    "district_id": "137108",
    "label": "Pisang",
    "value": "Pisang"
  },
  {
    "id": "1371081007",
    "district_id": "137108",
    "label": "Binuang Kampuang Dalam",
    "value": "Binuang Kampuang Dalam"
  },
  {
    "id": "1371081008",
    "district_id": "137108",
    "label": "Kapalo Koto",
    "value": "Kapalo Koto"
  },
  {
    "id": "1371081009",
    "district_id": "137108",
    "label": "Lambung Bukik",
    "value": "Lambung Bukik"
  },
  {
    "id": "1371091001",
    "district_id": "137109",
    "label": "Pasar Ambacang",
    "value": "Pasar Ambacang"
  },
  {
    "id": "1371091002",
    "district_id": "137109",
    "label": "Anduring",
    "value": "Anduring"
  },
  {
    "id": "1371091003",
    "district_id": "137109",
    "label": "Lubuk Lintah",
    "value": "Lubuk Lintah"
  },
  {
    "id": "1371091004",
    "district_id": "137109",
    "label": "Ampang",
    "value": "Ampang"
  },
  {
    "id": "1371091005",
    "district_id": "137109",
    "label": "Kalumbuk",
    "value": "Kalumbuk"
  },
  {
    "id": "1371091006",
    "district_id": "137109",
    "label": "Korong Gadang",
    "value": "Korong Gadang"
  },
  {
    "id": "1371091007",
    "district_id": "137109",
    "label": "Kuranji",
    "value": "Kuranji"
  },
  {
    "id": "1371091008",
    "district_id": "137109",
    "label": "Gunung Sarik",
    "value": "Gunung Sarik"
  },
  {
    "id": "1371091009",
    "district_id": "137109",
    "label": "Sungai Sapih",
    "value": "Sungai Sapih"
  },
  {
    "id": "1371101001",
    "district_id": "137110",
    "label": "Surau Gadang",
    "value": "Surau Gadang"
  },
  {
    "id": "1371101002",
    "district_id": "137110",
    "label": "Kampung Olo",
    "value": "Kampung Olo"
  },
  {
    "id": "1371101003",
    "district_id": "137110",
    "label": "Kurao Pagang",
    "value": "Kurao Pagang"
  },
  {
    "id": "1371101004",
    "district_id": "137110",
    "label": "Gurun Laweh",
    "value": "Gurun Laweh"
  },
  {
    "id": "1371101005",
    "district_id": "137110",
    "label": "Tabing Banda Gadang",
    "value": "Tabing Banda Gadang"
  },
  {
    "id": "1371101006",
    "district_id": "137110",
    "label": "Kampung Lapai",
    "value": "Kampung Lapai"
  },
  {
    "id": "1371111001",
    "district_id": "137111",
    "label": "Balai Gadang",
    "value": "Balai Gadang"
  },
  {
    "id": "1371111002",
    "district_id": "137111",
    "label": "Lubuk Minturun",
    "value": "Lubuk Minturun"
  },
  {
    "id": "1371111003",
    "district_id": "137111",
    "label": "Aie Pacah",
    "value": "Aie Pacah"
  },
  {
    "id": "1371111004",
    "district_id": "137111",
    "label": "Dadok Tunggul Hitam",
    "value": "Dadok Tunggul Hitam"
  },
  {
    "id": "1371111005",
    "district_id": "137111",
    "label": "Koto Panjang Ikua Koto",
    "value": "Koto Panjang Ikua Koto"
  },
  {
    "id": "1371111006",
    "district_id": "137111",
    "label": "Koto Pulai",
    "value": "Koto Pulai"
  },
  {
    "id": "1371111007",
    "district_id": "137111",
    "label": "Batipuh Panjang",
    "value": "Batipuh Panjang"
  },
  {
    "id": "1371111008",
    "district_id": "137111",
    "label": "Padang Sarai",
    "value": "Padang Sarai"
  },
  {
    "id": "1371111009",
    "district_id": "137111",
    "label": "Lubuk Buaya",
    "value": "Lubuk Buaya"
  },
  {
    "id": "1371111010",
    "district_id": "137111",
    "label": "Batang Kabung Ganting",
    "value": "Batang Kabung Ganting"
  },
  {
    "id": "1371111011",
    "district_id": "137111",
    "label": "Bungo Pasang",
    "value": "Bungo Pasang"
  },
  {
    "id": "1371111012",
    "district_id": "137111",
    "label": "Parupuk Tabing",
    "value": "Parupuk Tabing"
  },
  {
    "id": "1371111013",
    "district_id": "137111",
    "label": "Pasie Nan Tigo",
    "value": "Pasie Nan Tigo"
  },
  {
    "id": "1372011001",
    "district_id": "137201",
    "label": "Tanah Garam",
    "value": "Tanah Garam"
  },
  {
    "id": "1372011002",
    "district_id": "137201",
    "label": "VI Suku",
    "value": "VI Suku"
  },
  {
    "id": "1372011003",
    "district_id": "137201",
    "label": "Sinapa Piliang",
    "value": "Sinapa Piliang"
  },
  {
    "id": "1372011004",
    "district_id": "137201",
    "label": "IX Korong",
    "value": "IX Korong"
  },
  {
    "id": "1372011005",
    "district_id": "137201",
    "label": "Kampai Tabu Karambia",
    "value": "Kampai Tabu Karambia"
  },
  {
    "id": "1372011006",
    "district_id": "137201",
    "label": "Aro IV Korong",
    "value": "Aro IV Korong"
  },
  {
    "id": "1372011007",
    "district_id": "137201",
    "label": "Simpang Rumbio",
    "value": "Simpang Rumbio"
  },
  {
    "id": "1372021001",
    "district_id": "137202",
    "label": "Koto Panjang",
    "value": "Koto Panjang"
  },
  {
    "id": "1372021002",
    "district_id": "137202",
    "label": "Pasar Pandan Air Mati",
    "value": "Pasar Pandan Air Mati"
  },
  {
    "id": "1372021003",
    "district_id": "137202",
    "label": "Tanjung Paku",
    "value": "Tanjung Paku"
  },
  {
    "id": "1372021004",
    "district_id": "137202",
    "label": "Nan Balimo",
    "value": "Nan Balimo"
  },
  {
    "id": "1372021005",
    "district_id": "137202",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "1372021006",
    "district_id": "137202",
    "label": "Laing",
    "value": "Laing"
  },
  {
    "id": "1373011001",
    "district_id": "137301",
    "label": "Aur Mulio",
    "value": "Aur Mulio"
  },
  {
    "id": "1373011002",
    "district_id": "137301",
    "label": "Kubang Sirakuk Utara",
    "value": "Kubang Sirakuk Utara"
  },
  {
    "id": "1373011003",
    "district_id": "137301",
    "label": "Kubang S. Selatan",
    "value": "Kubang S. Selatan"
  },
  {
    "id": "1373011004",
    "district_id": "137301",
    "label": "Pasar",
    "value": "Pasar"
  },
  {
    "id": "1373011005",
    "district_id": "137301",
    "label": "Tanah Lapang",
    "value": "Tanah Lapang"
  },
  {
    "id": "1373011006",
    "district_id": "137301",
    "label": "Aia Dingin",
    "value": "Aia Dingin"
  },
  {
    "id": "1373012007",
    "district_id": "137301",
    "label": "Lunto Timur",
    "value": "Lunto Timur"
  },
  {
    "id": "1373012008",
    "district_id": "137301",
    "label": "Lunto Barat",
    "value": "Lunto Barat"
  },
  {
    "id": "1373012009",
    "district_id": "137301",
    "label": "Kubang Tangah",
    "value": "Kubang Tangah"
  },
  {
    "id": "1373012010",
    "district_id": "137301",
    "label": "Kubang Utara Sikabu",
    "value": "Kubang Utara Sikabu"
  },
  {
    "id": "1373012011",
    "district_id": "137301",
    "label": "Pasa Kubang",
    "value": "Pasa Kubang"
  },
  {
    "id": "1373021001",
    "district_id": "137302",
    "label": "Saringan",
    "value": "Saringan"
  },
  {
    "id": "1373021002",
    "district_id": "137302",
    "label": "Lubang Panjang",
    "value": "Lubang Panjang"
  },
  {
    "id": "1373021003",
    "district_id": "137302",
    "label": "Durian I",
    "value": "Durian I"
  },
  {
    "id": "1373021004",
    "district_id": "137302",
    "label": "Durian II",
    "value": "Durian II"
  },
  {
    "id": "1373022005",
    "district_id": "137302",
    "label": "Santua",
    "value": "Santua"
  },
  {
    "id": "1373022006",
    "district_id": "137302",
    "label": "Kolok Mudiak",
    "value": "Kolok Mudiak"
  },
  {
    "id": "1373022007",
    "district_id": "137302",
    "label": "Kolok Nan Tuo",
    "value": "Kolok Nan Tuo"
  },
  {
    "id": "1373022008",
    "district_id": "137302",
    "label": "Talago Gunung",
    "value": "Talago Gunung"
  },
  {
    "id": "1373022009",
    "district_id": "137302",
    "label": "Lumindai",
    "value": "Lumindai"
  },
  {
    "id": "1373022010",
    "district_id": "137302",
    "label": "Balai Batu Sadara",
    "value": "Balai Batu Sadara"
  },
  {
    "id": "1373032001",
    "district_id": "137303",
    "label": "Silungkang Duo",
    "value": "Silungkang Duo"
  },
  {
    "id": "1373032002",
    "district_id": "137303",
    "label": "Silungkang Oso",
    "value": "Silungkang Oso"
  },
  {
    "id": "1373032003",
    "district_id": "137303",
    "label": "Silungkang Tigo",
    "value": "Silungkang Tigo"
  },
  {
    "id": "1373032004",
    "district_id": "137303",
    "label": "Muaro Kalaban",
    "value": "Muaro Kalaban"
  },
  {
    "id": "1373032005",
    "district_id": "137303",
    "label": "Taratak Boncah",
    "value": "Taratak Boncah"
  },
  {
    "id": "1373042001",
    "district_id": "137304",
    "label": "Talawi Hilie",
    "value": "Talawi Hilie"
  },
  {
    "id": "1373042002",
    "district_id": "137304",
    "label": "Talawi Mudiak",
    "value": "Talawi Mudiak"
  },
  {
    "id": "1373042003",
    "district_id": "137304",
    "label": "Bukit Gadang",
    "value": "Bukit Gadang"
  },
  {
    "id": "1373042004",
    "district_id": "137304",
    "label": "Batu Tanjung",
    "value": "Batu Tanjung"
  },
  {
    "id": "1373042005",
    "district_id": "137304",
    "label": "Kumbayau",
    "value": "Kumbayau"
  },
  {
    "id": "1373042006",
    "district_id": "137304",
    "label": "Tumpuk Tangah",
    "value": "Tumpuk Tangah"
  },
  {
    "id": "1373042007",
    "district_id": "137304",
    "label": "Data Mansiang",
    "value": "Data Mansiang"
  },
  {
    "id": "1373042008",
    "district_id": "137304",
    "label": "Sijantang Koto",
    "value": "Sijantang Koto"
  },
  {
    "id": "1373042009",
    "district_id": "137304",
    "label": "Salak",
    "value": "Salak"
  },
  {
    "id": "1373042010",
    "district_id": "137304",
    "label": "Sikalang",
    "value": "Sikalang"
  },
  {
    "id": "1373042011",
    "district_id": "137304",
    "label": "Rantih",
    "value": "Rantih"
  },
  {
    "id": "1374011001",
    "district_id": "137401",
    "label": "Ganting",
    "value": "Ganting"
  },
  {
    "id": "1374011002",
    "district_id": "137401",
    "label": "Sigando",
    "value": "Sigando"
  },
  {
    "id": "1374011003",
    "district_id": "137401",
    "label": "Ekor Lubuk",
    "value": "Ekor Lubuk"
  },
  {
    "id": "1374011004",
    "district_id": "137401",
    "label": "Ngalau",
    "value": "Ngalau"
  },
  {
    "id": "1374011005",
    "district_id": "137401",
    "label": "Guguk Malintang",
    "value": "Guguk Malintang"
  },
  {
    "id": "1374011006",
    "district_id": "137401",
    "label": "Koto Panjang",
    "value": "Koto Panjang"
  },
  {
    "id": "1374011007",
    "district_id": "137401",
    "label": "Koto Katik",
    "value": "Koto Katik"
  },
  {
    "id": "1374011008",
    "district_id": "137401",
    "label": "Tanah Pak Lambik",
    "value": "Tanah Pak Lambik"
  },
  {
    "id": "1374021001",
    "district_id": "137402",
    "label": "Bukit Surungan",
    "value": "Bukit Surungan"
  },
  {
    "id": "1374021002",
    "district_id": "137402",
    "label": "Pasar Usang",
    "value": "Pasar Usang"
  },
  {
    "id": "1374021003",
    "district_id": "137402",
    "label": "Kampung Manggis",
    "value": "Kampung Manggis"
  },
  {
    "id": "1374021004",
    "district_id": "137402",
    "label": "Silaing Bawah",
    "value": "Silaing Bawah"
  },
  {
    "id": "1374021005",
    "district_id": "137402",
    "label": "Silaing Atas",
    "value": "Silaing Atas"
  },
  {
    "id": "1374021006",
    "district_id": "137402",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1374021007",
    "district_id": "137402",
    "label": "Tanah Hitam",
    "value": "Tanah Hitam"
  },
  {
    "id": "1374021008",
    "district_id": "137402",
    "label": "Balai-balai",
    "value": "Balai-balai"
  },
  {
    "id": "1375011001",
    "district_id": "137501",
    "label": "Tarok Dipo",
    "value": "Tarok Dipo"
  },
  {
    "id": "1375011002",
    "district_id": "137501",
    "label": "Bukik Cangang/Kayu Rmg.",
    "value": "Bukik Cangang/Kayu Rmg."
  },
  {
    "id": "1375011003",
    "district_id": "137501",
    "label": "Pakan Kurai",
    "value": "Pakan Kurai"
  },
  {
    "id": "1375011004",
    "district_id": "137501",
    "label": "Aur Tajungkang/Tgh. Sawah",
    "value": "Aur Tajungkang/Tgh. Sawah"
  },
  {
    "id": "1375011005",
    "district_id": "137501",
    "label": "Benteng Pasar Atas",
    "value": "Benteng Pasar Atas"
  },
  {
    "id": "1375011006",
    "district_id": "137501",
    "label": "Kayu Kubu",
    "value": "Kayu Kubu"
  },
  {
    "id": "1375011007",
    "district_id": "137501",
    "label": "Bukik Apik Puhun",
    "value": "Bukik Apik Puhun"
  },
  {
    "id": "1375021001",
    "district_id": "137502",
    "label": "Campago Guguk Bulek",
    "value": "Campago Guguk Bulek"
  },
  {
    "id": "1375021002",
    "district_id": "137502",
    "label": "Campago Ipuh",
    "value": "Campago Ipuh"
  },
  {
    "id": "1375021003",
    "district_id": "137502",
    "label": "Puhun Tembok",
    "value": "Puhun Tembok"
  },
  {
    "id": "1375021004",
    "district_id": "137502",
    "label": "Kubu Gulai Bancah",
    "value": "Kubu Gulai Bancah"
  },
  {
    "id": "1375021005",
    "district_id": "137502",
    "label": "Puhun Pintu Kabun",
    "value": "Puhun Pintu Kabun"
  },
  {
    "id": "1375021006",
    "district_id": "137502",
    "label": "Pulai Anak Air",
    "value": "Pulai Anak Air"
  },
  {
    "id": "1375021007",
    "district_id": "137502",
    "label": "Koto Selayan",
    "value": "Koto Selayan"
  },
  {
    "id": "1375021008",
    "district_id": "137502",
    "label": "Garegeh",
    "value": "Garegeh"
  },
  {
    "id": "1375021009",
    "district_id": "137502",
    "label": "Manggis/Ganting",
    "value": "Manggis/Ganting"
  },
  {
    "id": "1375031001",
    "district_id": "137503",
    "label": "Aur Kuning",
    "value": "Aur Kuning"
  },
  {
    "id": "1375031002",
    "district_id": "137503",
    "label": "Birugo",
    "value": "Birugo"
  },
  {
    "id": "1375031003",
    "district_id": "137503",
    "label": "Belakang Balok",
    "value": "Belakang Balok"
  },
  {
    "id": "1375031004",
    "district_id": "137503",
    "label": "Sapiran",
    "value": "Sapiran"
  },
  {
    "id": "1375031005",
    "district_id": "137503",
    "label": "Kubu Tanjung",
    "value": "Kubu Tanjung"
  },
  {
    "id": "1375031006",
    "district_id": "137503",
    "label": "Pakan Labuh",
    "value": "Pakan Labuh"
  },
  {
    "id": "1375031007",
    "district_id": "137503",
    "label": "Parit Rantang",
    "value": "Parit Rantang"
  },
  {
    "id": "1375031008",
    "district_id": "137503",
    "label": "Ladang Cakiah",
    "value": "Ladang Cakiah"
  },
  {
    "id": "1376011002",
    "district_id": "137601",
    "label": "Pakan Sinayan",
    "value": "Pakan Sinayan"
  },
  {
    "id": "1376011003",
    "district_id": "137601",
    "label": "Kubu Gadang",
    "value": "Kubu Gadang"
  },
  {
    "id": "1376011004",
    "district_id": "137601",
    "label": "Bulakan Balai Kandi",
    "value": "Bulakan Balai Kandi"
  },
  {
    "id": "1376011005",
    "district_id": "137601",
    "label": "Payolansek",
    "value": "Payolansek"
  },
  {
    "id": "1376011007",
    "district_id": "137601",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "1376011009",
    "district_id": "137601",
    "label": "Padang Tinggi Piliang",
    "value": "Padang Tinggi Piliang"
  },
  {
    "id": "1376011011",
    "district_id": "137601",
    "label": "Koto Tangah",
    "value": "Koto Tangah"
  },
  {
    "id": "1376011013",
    "district_id": "137601",
    "label": "Subarang Batuang",
    "value": "Subarang Batuang"
  },
  {
    "id": "1376011016",
    "district_id": "137601",
    "label": "Parik Rantang",
    "value": "Parik Rantang"
  },
  {
    "id": "1376011017",
    "district_id": "137601",
    "label": "Parak Batuang",
    "value": "Parak Batuang"
  },
  {
    "id": "1376011018",
    "district_id": "137601",
    "label": "Nunang Daya Bangun",
    "value": "Nunang Daya Bangun"
  },
  {
    "id": "1376011020",
    "district_id": "137601",
    "label": "Labuah Basilang",
    "value": "Labuah Basilang"
  },
  {
    "id": "1376011021",
    "district_id": "137601",
    "label": "Ibuah",
    "value": "Ibuah"
  },
  {
    "id": "1376011022",
    "district_id": "137601",
    "label": "Tanjung Pauh",
    "value": "Tanjung Pauh"
  },
  {
    "id": "1376011032",
    "district_id": "137601",
    "label": "Tanjuanggodang Sungaipinago",
    "value": "Tanjuanggodang Sungaipinago"
  },
  {
    "id": "1376011033",
    "district_id": "137601",
    "label": "Padangtongah Balainanduo",
    "value": "Padangtongah Balainanduo"
  },
  {
    "id": "1376011034",
    "district_id": "137601",
    "label": "Padangdata Tanahmati",
    "value": "Padangdata Tanahmati"
  },
  {
    "id": "1376021002",
    "district_id": "137602",
    "label": "Napar",
    "value": "Napar"
  },
  {
    "id": "1376021032",
    "district_id": "137602",
    "label": "Ompang Tanah Sirah",
    "value": "Ompang Tanah Sirah"
  },
  {
    "id": "1376021033",
    "district_id": "137602",
    "label": "Taratak Padang Kampuang",
    "value": "Taratak Padang Kampuang"
  },
  {
    "id": "1376021034",
    "district_id": "137602",
    "label": "Tigo Koto Dibaruah",
    "value": "Tigo Koto Dibaruah"
  },
  {
    "id": "1376021035",
    "district_id": "137602",
    "label": "Tigo Koto Diate",
    "value": "Tigo Koto Diate"
  },
  {
    "id": "1376021036",
    "district_id": "137602",
    "label": "Ikua Koto Dibalai",
    "value": "Ikua Koto Dibalai"
  },
  {
    "id": "1376021037",
    "district_id": "137602",
    "label": "Balai Tongah Koto",
    "value": "Balai Tongah Koto"
  },
  {
    "id": "1376021038",
    "district_id": "137602",
    "label": "Kapalo Koto Dibalai",
    "value": "Kapalo Koto Dibalai"
  },
  {
    "id": "1376021039",
    "district_id": "137602",
    "label": "Kotokociak Kubu Tapakrajo",
    "value": "Kotokociak Kubu Tapakrajo"
  },
  {
    "id": "1376031001",
    "district_id": "137603",
    "label": "Balai Jaring",
    "value": "Balai Jaring"
  },
  {
    "id": "1376031002",
    "district_id": "137603",
    "label": "Padang Alai Bodi",
    "value": "Padang Alai Bodi"
  },
  {
    "id": "1376031003",
    "district_id": "137603",
    "label": "Padang Tangah Payobadar",
    "value": "Padang Tangah Payobadar"
  },
  {
    "id": "1376031005",
    "district_id": "137603",
    "label": "Sicincin",
    "value": "Sicincin"
  },
  {
    "id": "1376031007",
    "district_id": "137603",
    "label": "Padang Tiakar",
    "value": "Padang Tiakar"
  },
  {
    "id": "1376031009",
    "district_id": "137603",
    "label": "Payobasung",
    "value": "Payobasung"
  },
  {
    "id": "1376031010",
    "district_id": "137603",
    "label": "Koto Panjang",
    "value": "Koto Panjang"
  },
  {
    "id": "1376031011",
    "district_id": "137603",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1376031015",
    "district_id": "137603",
    "label": "Tiakar",
    "value": "Tiakar"
  },
  {
    "id": "1376041001",
    "district_id": "137604",
    "label": "Sungai Durian",
    "value": "Sungai Durian"
  },
  {
    "id": "1376041002",
    "district_id": "137604",
    "label": "Parit Muko Aie",
    "value": "Parit Muko Aie"
  },
  {
    "id": "1376041003",
    "district_id": "137604",
    "label": "Parambahan",
    "value": "Parambahan"
  },
  {
    "id": "1376041004",
    "district_id": "137604",
    "label": "Padang Sikabu",
    "value": "Padang Sikabu"
  },
  {
    "id": "1376041005",
    "district_id": "137604",
    "label": "Koto Panjang Padang",
    "value": "Koto Panjang Padang"
  },
  {
    "id": "1376041006",
    "district_id": "137604",
    "label": "Koto Panjang Dalam",
    "value": "Koto Panjang Dalam"
  },
  {
    "id": "1376051001",
    "district_id": "137605",
    "label": "Padang Karambia",
    "value": "Padang Karambia"
  },
  {
    "id": "1376051002",
    "district_id": "137605",
    "label": "Kototuo Limokampuang",
    "value": "Kototuo Limokampuang"
  },
  {
    "id": "1376051003",
    "district_id": "137605",
    "label": "Limbukan",
    "value": "Limbukan"
  },
  {
    "id": "1376051005",
    "district_id": "137605",
    "label": "Balai Panjang",
    "value": "Balai Panjang"
  },
  {
    "id": "1376051006",
    "district_id": "137605",
    "label": "Kapalo Koto Ampangan",
    "value": "Kapalo Koto Ampangan"
  },
  {
    "id": "1376051010",
    "district_id": "137605",
    "label": "Sawahpadang Aua Kuniang",
    "value": "Sawahpadang Aua Kuniang"
  },
  {
    "id": "1377011001",
    "district_id": "137701",
    "label": "Karan Aur",
    "value": "Karan Aur"
  },
  {
    "id": "1377011002",
    "district_id": "137701",
    "label": "Kampung Perak",
    "value": "Kampung Perak"
  },
  {
    "id": "1377011003",
    "district_id": "137701",
    "label": "Lohong",
    "value": "Lohong"
  },
  {
    "id": "1377011004",
    "district_id": "137701",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "1377011005",
    "district_id": "137701",
    "label": "Kampung Pondok",
    "value": "Kampung Pondok"
  },
  {
    "id": "1377011006",
    "district_id": "137701",
    "label": "Pondok II",
    "value": "Pondok II"
  },
  {
    "id": "1377011007",
    "district_id": "137701",
    "label": "Kampung Jawa I",
    "value": "Kampung Jawa I"
  },
  {
    "id": "1377011008",
    "district_id": "137701",
    "label": "Kampung Jawa II",
    "value": "Kampung Jawa II"
  },
  {
    "id": "1377011009",
    "district_id": "137701",
    "label": "Alai Gelombang",
    "value": "Alai Gelombang"
  },
  {
    "id": "1377011010",
    "district_id": "137701",
    "label": "Jawi Jawi I",
    "value": "Jawi Jawi I"
  },
  {
    "id": "1377011011",
    "district_id": "137701",
    "label": "Jawi Jawi II",
    "value": "Jawi Jawi II"
  },
  {
    "id": "1377011012",
    "district_id": "137701",
    "label": "Jalan Baru",
    "value": "Jalan Baru"
  },
  {
    "id": "1377011013",
    "district_id": "137701",
    "label": "Taratak",
    "value": "Taratak"
  },
  {
    "id": "1377011014",
    "district_id": "137701",
    "label": "Jalan Kereta Api",
    "value": "Jalan Kereta Api"
  },
  {
    "id": "1377011015",
    "district_id": "137701",
    "label": "Ujung Batung",
    "value": "Ujung Batung"
  },
  {
    "id": "1377011016",
    "district_id": "137701",
    "label": "Jati Hilir",
    "value": "Jati Hilir"
  },
  {
    "id": "1377012017",
    "district_id": "137701",
    "label": "Pauh Barat",
    "value": "Pauh Barat"
  },
  {
    "id": "1377012018",
    "district_id": "137701",
    "label": "Pauh Timur",
    "value": "Pauh Timur"
  },
  {
    "id": "1377012019",
    "district_id": "137701",
    "label": "Rawang",
    "value": "Rawang"
  },
  {
    "id": "1377012020",
    "district_id": "137701",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1377012021",
    "district_id": "137701",
    "label": "Cimparuh",
    "value": "Cimparuh"
  },
  {
    "id": "1377012025",
    "district_id": "137701",
    "label": "Jati Mudik",
    "value": "Jati Mudik"
  },
  {
    "id": "1377022005",
    "district_id": "137702",
    "label": "Ampalu",
    "value": "Ampalu"
  },
  {
    "id": "1377022006",
    "district_id": "137702",
    "label": "Apar",
    "value": "Apar"
  },
  {
    "id": "1377022007",
    "district_id": "137702",
    "label": "Tanjung Sabar",
    "value": "Tanjung Sabar"
  },
  {
    "id": "1377022008",
    "district_id": "137702",
    "label": "Cubadak Air Selatan",
    "value": "Cubadak Air Selatan"
  },
  {
    "id": "1377022009",
    "district_id": "137702",
    "label": "Sikapak Barat",
    "value": "Sikapak Barat"
  },
  {
    "id": "1377022010",
    "district_id": "137702",
    "label": "Sikapak Timur",
    "value": "Sikapak Timur"
  },
  {
    "id": "1377022011",
    "district_id": "137702",
    "label": "Manggung",
    "value": "Manggung"
  },
  {
    "id": "1377022012",
    "district_id": "137702",
    "label": "Cubadak Air Utara",
    "value": "Cubadak Air Utara"
  },
  {
    "id": "1377022013",
    "district_id": "137702",
    "label": "Tungkal Selatan",
    "value": "Tungkal Selatan"
  },
  {
    "id": "1377022014",
    "district_id": "137702",
    "label": "Naras Hilir",
    "value": "Naras Hilir"
  },
  {
    "id": "1377022015",
    "district_id": "137702",
    "label": "Naras I",
    "value": "Naras I"
  },
  {
    "id": "1377022016",
    "district_id": "137702",
    "label": "Balai Naras",
    "value": "Balai Naras"
  },
  {
    "id": "1377022017",
    "district_id": "137702",
    "label": "Padang Birik Birik",
    "value": "Padang Birik Birik"
  },
  {
    "id": "1377022018",
    "district_id": "137702",
    "label": "Sintuk",
    "value": "Sintuk"
  },
  {
    "id": "1377022019",
    "district_id": "137702",
    "label": "Sungai Rambai",
    "value": "Sungai Rambai"
  },
  {
    "id": "1377022020",
    "district_id": "137702",
    "label": "Tungkal Utara",
    "value": "Tungkal Utara"
  },
  {
    "id": "1377022021",
    "district_id": "137702",
    "label": "Cubadak Air",
    "value": "Cubadak Air"
  },
  {
    "id": "1377032006",
    "district_id": "137703",
    "label": "Pasir Sunur",
    "value": "Pasir Sunur"
  },
  {
    "id": "1377032007",
    "district_id": "137703",
    "label": "Balai Kurai Taji",
    "value": "Balai Kurai Taji"
  },
  {
    "id": "1377032008",
    "district_id": "137703",
    "label": "Pauh Kurai Taji",
    "value": "Pauh Kurai Taji"
  },
  {
    "id": "1377032009",
    "district_id": "137703",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1377032010",
    "district_id": "137703",
    "label": "Toboh Palabah",
    "value": "Toboh Palabah"
  },
  {
    "id": "1377032011",
    "district_id": "137703",
    "label": "Marabau",
    "value": "Marabau"
  },
  {
    "id": "1377032012",
    "district_id": "137703",
    "label": "Batang Tajongkek",
    "value": "Batang Tajongkek"
  },
  {
    "id": "1377032013",
    "district_id": "137703",
    "label": "Sikabu",
    "value": "Sikabu"
  },
  {
    "id": "1377032014",
    "district_id": "137703",
    "label": "Sungai Kasai",
    "value": "Sungai Kasai"
  },
  {
    "id": "1377032015",
    "district_id": "137703",
    "label": "Palak Aneh",
    "value": "Palak Aneh"
  },
  {
    "id": "1377032016",
    "district_id": "137703",
    "label": "Kampung Apar",
    "value": "Kampung Apar"
  },
  {
    "id": "1377032017",
    "district_id": "137703",
    "label": "Marunggi",
    "value": "Marunggi"
  },
  {
    "id": "1377032018",
    "district_id": "137703",
    "label": "Taluk",
    "value": "Taluk"
  },
  {
    "id": "1377032019",
    "district_id": "137703",
    "label": "Padang Cakur",
    "value": "Padang Cakur"
  },
  {
    "id": "1377032020",
    "district_id": "137703",
    "label": "Punggung Lading",
    "value": "Punggung Lading"
  },
  {
    "id": "1377032021",
    "district_id": "137703",
    "label": "Rambai",
    "value": "Rambai"
  },
  {
    "id": "1377042001",
    "district_id": "137704",
    "label": "Talago Sarik",
    "value": "Talago Sarik"
  },
  {
    "id": "1377042002",
    "district_id": "137704",
    "label": "Pakasai",
    "value": "Pakasai"
  },
  {
    "id": "1377042003",
    "district_id": "137704",
    "label": "Kampung Baru Padusunan",
    "value": "Kampung Baru Padusunan"
  },
  {
    "id": "1377042004",
    "district_id": "137704",
    "label": "Kampung Gadang",
    "value": "Kampung Gadang"
  },
  {
    "id": "1377042005",
    "district_id": "137704",
    "label": "Koto Marapak",
    "value": "Koto Marapak"
  },
  {
    "id": "1377042006",
    "district_id": "137704",
    "label": "Batang Kabung",
    "value": "Batang Kabung"
  },
  {
    "id": "1377042007",
    "district_id": "137704",
    "label": "Bato",
    "value": "Bato"
  },
  {
    "id": "1377042008",
    "district_id": "137704",
    "label": "Air Santok",
    "value": "Air Santok"
  },
  {
    "id": "1377042009",
    "district_id": "137704",
    "label": "Cubadak Mentawai",
    "value": "Cubadak Mentawai"
  },
  {
    "id": "1377042010",
    "district_id": "137704",
    "label": "Sungai Sirah",
    "value": "Sungai Sirah"
  },
  {
    "id": "1377042011",
    "district_id": "137704",
    "label": "Sungai Pasak",
    "value": "Sungai Pasak"
  },
  {
    "id": "1377042012",
    "district_id": "137704",
    "label": "Bungo Tanjung",
    "value": "Bungo Tanjung"
  },
  {
    "id": "1377042013",
    "district_id": "137704",
    "label": "Kampung Tangah",
    "value": "Kampung Tangah"
  },
  {
    "id": "1377042014",
    "district_id": "137704",
    "label": "Kampung Kandang",
    "value": "Kampung Kandang"
  },
  {
    "id": "1377042015",
    "district_id": "137704",
    "label": "Kaluat",
    "value": "Kaluat"
  },
  {
    "id": "1377042016",
    "district_id": "137704",
    "label": "Kajai",
    "value": "Kajai"
  },
  {
    "id": "1401011011",
    "district_id": "140101",
    "label": "Bangkinang",
    "value": "Bangkinang"
  },
  {
    "id": "1401011013",
    "district_id": "140101",
    "label": "Langgini",
    "value": "Langgini"
  },
  {
    "id": "1401012009",
    "district_id": "140101",
    "label": "Kumantan",
    "value": "Kumantan"
  },
  {
    "id": "1401012010",
    "district_id": "140101",
    "label": "Ridan Permai",
    "value": "Ridan Permai"
  },
  {
    "id": "1401021027",
    "district_id": "140102",
    "label": "Air Tiris",
    "value": "Air Tiris"
  },
  {
    "id": "1401022001",
    "district_id": "140102",
    "label": "Batu Belah",
    "value": "Batu Belah"
  },
  {
    "id": "1401022003",
    "district_id": "140102",
    "label": "Tanjung Berulak",
    "value": "Tanjung Berulak"
  },
  {
    "id": "1401022006",
    "district_id": "140102",
    "label": "Ranah",
    "value": "Ranah"
  },
  {
    "id": "1401022007",
    "district_id": "140102",
    "label": "Penyasawan",
    "value": "Penyasawan"
  },
  {
    "id": "1401022008",
    "district_id": "140102",
    "label": "Rumbio",
    "value": "Rumbio"
  },
  {
    "id": "1401022013",
    "district_id": "140102",
    "label": "Padang Mutung",
    "value": "Padang Mutung"
  },
  {
    "id": "1401022023",
    "district_id": "140102",
    "label": "Pulau Jambu",
    "value": "Pulau Jambu"
  },
  {
    "id": "1401022024",
    "district_id": "140102",
    "label": "Tanjung Rambutan",
    "value": "Tanjung Rambutan"
  },
  {
    "id": "1401022025",
    "district_id": "140102",
    "label": "Simpang Kubu",
    "value": "Simpang Kubu"
  },
  {
    "id": "1401022028",
    "district_id": "140102",
    "label": "Limau Manis",
    "value": "Limau Manis"
  },
  {
    "id": "1401022030",
    "district_id": "140102",
    "label": "Naumbai",
    "value": "Naumbai"
  },
  {
    "id": "1401022031",
    "district_id": "140102",
    "label": "Pulau Tinggi",
    "value": "Pulau Tinggi"
  },
  {
    "id": "1401022032",
    "district_id": "140102",
    "label": "Ranah Baru",
    "value": "Ranah Baru"
  },
  {
    "id": "1401022033",
    "district_id": "140102",
    "label": "Bukit Ranah",
    "value": "Bukit Ranah"
  },
  {
    "id": "1401022034",
    "district_id": "140102",
    "label": "Pulau Sarak",
    "value": "Pulau Sarak"
  },
  {
    "id": "1401022035",
    "district_id": "140102",
    "label": "Koto Tibun",
    "value": "Koto Tibun"
  },
  {
    "id": "1401022036",
    "district_id": "140102",
    "label": "Ranah Singkuang",
    "value": "Ranah Singkuang"
  },
  {
    "id": "1401032001",
    "district_id": "140103",
    "label": "Tambang",
    "value": "Tambang"
  },
  {
    "id": "1401032002",
    "district_id": "140103",
    "label": "Kuapan",
    "value": "Kuapan"
  },
  {
    "id": "1401032003",
    "district_id": "140103",
    "label": "Gobah",
    "value": "Gobah"
  },
  {
    "id": "1401032004",
    "district_id": "140103",
    "label": "Teluk Kenidai",
    "value": "Teluk Kenidai"
  },
  {
    "id": "1401032005",
    "district_id": "140103",
    "label": "Aursati",
    "value": "Aursati"
  },
  {
    "id": "1401032006",
    "district_id": "140103",
    "label": "Padang Luas",
    "value": "Padang Luas"
  },
  {
    "id": "1401032007",
    "district_id": "140103",
    "label": "Terantang",
    "value": "Terantang"
  },
  {
    "id": "1401032008",
    "district_id": "140103",
    "label": "Kualu",
    "value": "Kualu"
  },
  {
    "id": "1401032009",
    "district_id": "140103",
    "label": "Rimbo Panjang",
    "value": "Rimbo Panjang"
  },
  {
    "id": "1401032010",
    "district_id": "140103",
    "label": "Parit Baru",
    "value": "Parit Baru"
  },
  {
    "id": "1401032011",
    "district_id": "140103",
    "label": "Kemang Indah",
    "value": "Kemang Indah"
  },
  {
    "id": "1401032012",
    "district_id": "140103",
    "label": "Kualu Nenas",
    "value": "Kualu Nenas"
  },
  {
    "id": "1401032013",
    "district_id": "140103",
    "label": "Tarai Bangun",
    "value": "Tarai Bangun"
  },
  {
    "id": "1401032014",
    "district_id": "140103",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1401032015",
    "district_id": "140103",
    "label": "Balam Jaya",
    "value": "Balam Jaya"
  },
  {
    "id": "1401032016",
    "district_id": "140103",
    "label": "Pulau Permai",
    "value": "Pulau Permai"
  },
  {
    "id": "1401032017",
    "district_id": "140103",
    "label": "Palung Raya",
    "value": "Palung Raya"
  },
  {
    "id": "1401041018",
    "district_id": "140104",
    "label": "Batu Bersurat",
    "value": "Batu Bersurat"
  },
  {
    "id": "1401042004",
    "district_id": "140104",
    "label": "Gunung Bungsu",
    "value": "Gunung Bungsu"
  },
  {
    "id": "1401042005",
    "district_id": "140104",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1401042006",
    "district_id": "140104",
    "label": "Balung",
    "value": "Balung"
  },
  {
    "id": "1401042007",
    "district_id": "140104",
    "label": "Muara Takus",
    "value": "Muara Takus"
  },
  {
    "id": "1401042010",
    "district_id": "140104",
    "label": "Pulau Gadang",
    "value": "Pulau Gadang"
  },
  {
    "id": "1401042011",
    "district_id": "140104",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1401042012",
    "district_id": "140104",
    "label": "Ranah Sungkai",
    "value": "Ranah Sungkai"
  },
  {
    "id": "1401042013",
    "district_id": "140104",
    "label": "Lubuk Agung",
    "value": "Lubuk Agung"
  },
  {
    "id": "1401042014",
    "district_id": "140104",
    "label": "Koto Mesjid",
    "value": "Koto Mesjid"
  },
  {
    "id": "1401042016",
    "district_id": "140104",
    "label": "Pongkai Istiqomah",
    "value": "Pongkai Istiqomah"
  },
  {
    "id": "1401042017",
    "district_id": "140104",
    "label": "Binamang",
    "value": "Binamang"
  },
  {
    "id": "1401042019",
    "district_id": "140104",
    "label": "Koto Tuo Barat",
    "value": "Koto Tuo Barat"
  },
  {
    "id": "1401052001",
    "district_id": "140105",
    "label": "Kuok",
    "value": "Kuok"
  },
  {
    "id": "1401052005",
    "district_id": "140105",
    "label": "Merangin",
    "value": "Merangin"
  },
  {
    "id": "1401052006",
    "district_id": "140105",
    "label": "Empat Balai",
    "value": "Empat Balai"
  },
  {
    "id": "1401052007",
    "district_id": "140105",
    "label": "Pulau Jambu",
    "value": "Pulau Jambu"
  },
  {
    "id": "1401052008",
    "district_id": "140105",
    "label": "Silam",
    "value": "Silam"
  },
  {
    "id": "1401052010",
    "district_id": "140105",
    "label": "Bukit Melintang",
    "value": "Bukit Melintang"
  },
  {
    "id": "1401052011",
    "district_id": "140105",
    "label": "Pulau Terap",
    "value": "Pulau Terap"
  },
  {
    "id": "1401052012",
    "district_id": "140105",
    "label": "Lereng",
    "value": "Lereng"
  },
  {
    "id": "1401052013",
    "district_id": "140105",
    "label": "Batu Langka Kecil",
    "value": "Batu Langka Kecil"
  },
  {
    "id": "1401062001",
    "district_id": "140106",
    "label": "Pangkalan Baru",
    "value": "Pangkalan Baru"
  },
  {
    "id": "1401062002",
    "district_id": "140106",
    "label": "Desa Baru",
    "value": "Desa Baru"
  },
  {
    "id": "1401062003",
    "district_id": "140106",
    "label": "Teratak Buluh",
    "value": "Teratak Buluh"
  },
  {
    "id": "1401062005",
    "district_id": "140106",
    "label": "Lubuk Siam",
    "value": "Lubuk Siam"
  },
  {
    "id": "1401062006",
    "district_id": "140106",
    "label": "Buluh Cina",
    "value": "Buluh Cina"
  },
  {
    "id": "1401062007",
    "district_id": "140106",
    "label": "Buluh Nipis",
    "value": "Buluh Nipis"
  },
  {
    "id": "1401062011",
    "district_id": "140106",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1401062012",
    "district_id": "140106",
    "label": "Pandau Jaya",
    "value": "Pandau Jaya"
  },
  {
    "id": "1401062013",
    "district_id": "140106",
    "label": "Tanjung Balam",
    "value": "Tanjung Balam"
  },
  {
    "id": "1401062014",
    "district_id": "140106",
    "label": "Kepau Jaya",
    "value": "Kepau Jaya"
  },
  {
    "id": "1401062015",
    "district_id": "140106",
    "label": "Pangkalan Serik",
    "value": "Pangkalan Serik"
  },
  {
    "id": "1401062016",
    "district_id": "140106",
    "label": "Kubang Jaya",
    "value": "Kubang Jaya"
  },
  {
    "id": "1401071027",
    "district_id": "140107",
    "label": "Lipat Kain",
    "value": "Lipat Kain"
  },
  {
    "id": "1401072003",
    "district_id": "140107",
    "label": "Kuntu",
    "value": "Kuntu"
  },
  {
    "id": "1401072004",
    "district_id": "140107",
    "label": "Padang Sawah",
    "value": "Padang Sawah"
  },
  {
    "id": "1401072005",
    "district_id": "140107",
    "label": "Domo",
    "value": "Domo"
  },
  {
    "id": "1401072006",
    "district_id": "140107",
    "label": "IV Koto Setingkai",
    "value": "IV Koto Setingkai"
  },
  {
    "id": "1401072008",
    "district_id": "140107",
    "label": "Teluk Paman",
    "value": "Teluk Paman"
  },
  {
    "id": "1401072013",
    "district_id": "140107",
    "label": "Sungai Geringging",
    "value": "Sungai Geringging"
  },
  {
    "id": "1401072016",
    "district_id": "140107",
    "label": "Sungai Paku",
    "value": "Sungai Paku"
  },
  {
    "id": "1401072019",
    "district_id": "140107",
    "label": "Muara Selayah",
    "value": "Muara Selayah"
  },
  {
    "id": "1401072020",
    "district_id": "140107",
    "label": "Sungai Rambai",
    "value": "Sungai Rambai"
  },
  {
    "id": "1401072021",
    "district_id": "140107",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1401072023",
    "district_id": "140107",
    "label": "Sungai Raja",
    "value": "Sungai Raja"
  },
  {
    "id": "1401072026",
    "district_id": "140107",
    "label": "Sungai Sarik",
    "value": "Sungai Sarik"
  },
  {
    "id": "1401072028",
    "district_id": "140107",
    "label": "Lipat Kain Utara",
    "value": "Lipat Kain Utara"
  },
  {
    "id": "1401072029",
    "district_id": "140107",
    "label": "Lipat Kain Selatan",
    "value": "Lipat Kain Selatan"
  },
  {
    "id": "1401072030",
    "district_id": "140107",
    "label": "Kuntu Darussalam",
    "value": "Kuntu Darussalam"
  },
  {
    "id": "1401072031",
    "district_id": "140107",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "1401072032",
    "district_id": "140107",
    "label": "Sungai Harapan",
    "value": "Sungai Harapan"
  },
  {
    "id": "1401072033",
    "district_id": "140107",
    "label": "Sungai Liti",
    "value": "Sungai Liti"
  },
  {
    "id": "1401072034",
    "district_id": "140107",
    "label": "Teluk Paman Timur",
    "value": "Teluk Paman Timur"
  },
  {
    "id": "1401081001",
    "district_id": "140108",
    "label": "Sungai Pagar",
    "value": "Sungai Pagar"
  },
  {
    "id": "1401082002",
    "district_id": "140108",
    "label": "Mentulik",
    "value": "Mentulik"
  },
  {
    "id": "1401082003",
    "district_id": "140108",
    "label": "Sungai Simpang Dua",
    "value": "Sungai Simpang Dua"
  },
  {
    "id": "1401082004",
    "district_id": "140108",
    "label": "Sungai Bungo",
    "value": "Sungai Bungo"
  },
  {
    "id": "1401082005",
    "district_id": "140108",
    "label": "Rantau Kasih",
    "value": "Rantau Kasih"
  },
  {
    "id": "1401082006",
    "district_id": "140108",
    "label": "Sungai Petai",
    "value": "Sungai Petai"
  },
  {
    "id": "1401082007",
    "district_id": "140108",
    "label": "Gading Permai",
    "value": "Gading Permai"
  },
  {
    "id": "1401082008",
    "district_id": "140108",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1401092001",
    "district_id": "140109",
    "label": "Gema",
    "value": "Gema"
  },
  {
    "id": "1401092002",
    "district_id": "140109",
    "label": "Tanjung Belit",
    "value": "Tanjung Belit"
  },
  {
    "id": "1401092003",
    "district_id": "140109",
    "label": "Tanjung Belit Selatan",
    "value": "Tanjung Belit Selatan"
  },
  {
    "id": "1401092004",
    "district_id": "140109",
    "label": "Koto Lama",
    "value": "Koto Lama"
  },
  {
    "id": "1401092005",
    "district_id": "140109",
    "label": "Batu Sanggan",
    "value": "Batu Sanggan"
  },
  {
    "id": "1401092006",
    "district_id": "140109",
    "label": "Aur Kuning",
    "value": "Aur Kuning"
  },
  {
    "id": "1401092007",
    "district_id": "140109",
    "label": "Ludai",
    "value": "Ludai"
  },
  {
    "id": "1401092008",
    "district_id": "140109",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "1401092009",
    "district_id": "140109",
    "label": "Batu Sasak",
    "value": "Batu Sasak"
  },
  {
    "id": "1401092010",
    "district_id": "140109",
    "label": "Pangkalan Kapas",
    "value": "Pangkalan Kapas"
  },
  {
    "id": "1401092011",
    "district_id": "140109",
    "label": "Kebun Tinggi",
    "value": "Kebun Tinggi"
  },
  {
    "id": "1401092012",
    "district_id": "140109",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1401092013",
    "district_id": "140109",
    "label": "Gajah Bertalut",
    "value": "Gajah Bertalut"
  },
  {
    "id": "1401092014",
    "district_id": "140109",
    "label": "Danau Sontul",
    "value": "Danau Sontul"
  },
  {
    "id": "1401092015",
    "district_id": "140109",
    "label": "Pangkalan Serai",
    "value": "Pangkalan Serai"
  },
  {
    "id": "1401092016",
    "district_id": "140109",
    "label": "Dua Sepakat",
    "value": "Dua Sepakat"
  },
  {
    "id": "1401092017",
    "district_id": "140109",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "1401092018",
    "district_id": "140109",
    "label": "Deras Tajak",
    "value": "Deras Tajak"
  },
  {
    "id": "1401092019",
    "district_id": "140109",
    "label": "Sungai Santi",
    "value": "Sungai Santi"
  },
  {
    "id": "1401092020",
    "district_id": "140109",
    "label": "Subayang Jaya",
    "value": "Subayang Jaya"
  },
  {
    "id": "1401092021",
    "district_id": "140109",
    "label": "Tanjung Permai",
    "value": "Tanjung Permai"
  },
  {
    "id": "1401092022",
    "district_id": "140109",
    "label": "Bukit Betung",
    "value": "Bukit Betung"
  },
  {
    "id": "1401092023",
    "district_id": "140109",
    "label": "Muaro Bio",
    "value": "Muaro Bio"
  },
  {
    "id": "1401092024",
    "district_id": "140109",
    "label": "Lubuk Bigau",
    "value": "Lubuk Bigau"
  },
  {
    "id": "1401102001",
    "district_id": "140110",
    "label": "Petapahan",
    "value": "Petapahan"
  },
  {
    "id": "1401102002",
    "district_id": "140110",
    "label": "Pantai Cermin",
    "value": "Pantai Cermin"
  },
  {
    "id": "1401102003",
    "district_id": "140110",
    "label": "Patahapan Jaya",
    "value": "Patahapan Jaya"
  },
  {
    "id": "1401102004",
    "district_id": "140110",
    "label": "Mukti Sari",
    "value": "Mukti Sari"
  },
  {
    "id": "1401102005",
    "district_id": "140110",
    "label": "Sungai Putih",
    "value": "Sungai Putih"
  },
  {
    "id": "1401102006",
    "district_id": "140110",
    "label": "Indra Sakti",
    "value": "Indra Sakti"
  },
  {
    "id": "1401102007",
    "district_id": "140110",
    "label": "Gading Sari",
    "value": "Gading Sari"
  },
  {
    "id": "1401102008",
    "district_id": "140110",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1401102009",
    "district_id": "140110",
    "label": "Pancuran Gading",
    "value": "Pancuran Gading"
  },
  {
    "id": "1401102010",
    "district_id": "140110",
    "label": "Sari Galuh",
    "value": "Sari Galuh"
  },
  {
    "id": "1401102011",
    "district_id": "140110",
    "label": "Tri Manunggal",
    "value": "Tri Manunggal"
  },
  {
    "id": "1401102012",
    "district_id": "140110",
    "label": "Air Terbit",
    "value": "Air Terbit"
  },
  {
    "id": "1401102013",
    "district_id": "140110",
    "label": "Tanjung Sawit",
    "value": "Tanjung Sawit"
  },
  {
    "id": "1401102014",
    "district_id": "140110",
    "label": "Pagaruyung",
    "value": "Pagaruyung"
  },
  {
    "id": "1401102015",
    "district_id": "140110",
    "label": "Sibuak",
    "value": "Sibuak"
  },
  {
    "id": "1401102016",
    "district_id": "140110",
    "label": "Pelambaian",
    "value": "Pelambaian"
  },
  {
    "id": "1401102017",
    "district_id": "140110",
    "label": "Kenantan",
    "value": "Kenantan"
  },
  {
    "id": "1401102018",
    "district_id": "140110",
    "label": "Indrapuri",
    "value": "Indrapuri"
  },
  {
    "id": "1401102019",
    "district_id": "140110",
    "label": "Sungai Lambu Makmur",
    "value": "Sungai Lambu Makmur"
  },
  {
    "id": "1401102020",
    "district_id": "140110",
    "label": "Muara Mahat Baru",
    "value": "Muara Mahat Baru"
  },
  {
    "id": "1401102021",
    "district_id": "140110",
    "label": "Karya Indah",
    "value": "Karya Indah"
  },
  {
    "id": "1401102022",
    "district_id": "140110",
    "label": "Kijang Rejo",
    "value": "Kijang Rejo"
  },
  {
    "id": "1401102023",
    "district_id": "140110",
    "label": "Sungai Agung",
    "value": "Sungai Agung"
  },
  {
    "id": "1401102024",
    "district_id": "140110",
    "label": "Bencah Kelubi",
    "value": "Bencah Kelubi"
  },
  {
    "id": "1401102025",
    "district_id": "140110",
    "label": "Batu Gajah",
    "value": "Batu Gajah"
  },
  {
    "id": "1401112001",
    "district_id": "140111",
    "label": "Koto Garo",
    "value": "Koto Garo"
  },
  {
    "id": "1401112002",
    "district_id": "140111",
    "label": "Sekijang",
    "value": "Sekijang"
  },
  {
    "id": "1401112003",
    "district_id": "140111",
    "label": "Beringin Lestari",
    "value": "Beringin Lestari"
  },
  {
    "id": "1401112004",
    "district_id": "140111",
    "label": "Kota Bangun",
    "value": "Kota Bangun"
  },
  {
    "id": "1401112005",
    "district_id": "140111",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1401112006",
    "district_id": "140111",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1401112007",
    "district_id": "140111",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1401112008",
    "district_id": "140111",
    "label": "Tebing Lestari",
    "value": "Tebing Lestari"
  },
  {
    "id": "1401112009",
    "district_id": "140111",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "1401112010",
    "district_id": "140111",
    "label": "Koto Aman",
    "value": "Koto Aman"
  },
  {
    "id": "1401112011",
    "district_id": "140111",
    "label": "Tapung Lestari",
    "value": "Tapung Lestari"
  },
  {
    "id": "1401112012",
    "district_id": "140111",
    "label": "Tapung Makmur",
    "value": "Tapung Makmur"
  },
  {
    "id": "1401112013",
    "district_id": "140111",
    "label": "Tandan Sari",
    "value": "Tandan Sari"
  },
  {
    "id": "1401112014",
    "district_id": "140111",
    "label": "Gerbang Sari",
    "value": "Gerbang Sari"
  },
  {
    "id": "1401112015",
    "district_id": "140111",
    "label": "Kijang Jaya",
    "value": "Kijang Jaya"
  },
  {
    "id": "1401112016",
    "district_id": "140111",
    "label": "Kijang Makmur",
    "value": "Kijang Makmur"
  },
  {
    "id": "1401122001",
    "district_id": "140112",
    "label": "Senama Nenek",
    "value": "Senama Nenek"
  },
  {
    "id": "1401122002",
    "district_id": "140112",
    "label": "Kasikan",
    "value": "Kasikan"
  },
  {
    "id": "1401122003",
    "district_id": "140112",
    "label": "Bukit Kemuning",
    "value": "Bukit Kemuning"
  },
  {
    "id": "1401122004",
    "district_id": "140112",
    "label": "Danau Lancang",
    "value": "Danau Lancang"
  },
  {
    "id": "1401122005",
    "district_id": "140112",
    "label": "Muara Intan",
    "value": "Muara Intan"
  },
  {
    "id": "1401122006",
    "district_id": "140112",
    "label": "Intan Jaya",
    "value": "Intan Jaya"
  },
  {
    "id": "1401122007",
    "district_id": "140112",
    "label": "Tanah Datar",
    "value": "Tanah Datar"
  },
  {
    "id": "1401122008",
    "district_id": "140112",
    "label": "Rimba Jaya",
    "value": "Rimba Jaya"
  },
  {
    "id": "1401122009",
    "district_id": "140112",
    "label": "Rimba Makmur",
    "value": "Rimba Makmur"
  },
  {
    "id": "1401122010",
    "district_id": "140112",
    "label": "Rimba Beringin",
    "value": "Rimba Beringin"
  },
  {
    "id": "1401122011",
    "district_id": "140112",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "1401122012",
    "district_id": "140112",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1401122013",
    "district_id": "140112",
    "label": "Kusau Makmur",
    "value": "Kusau Makmur"
  },
  {
    "id": "1401122014",
    "district_id": "140112",
    "label": "Talang Danto",
    "value": "Talang Danto"
  },
  {
    "id": "1401132001",
    "district_id": "140113",
    "label": "Salo",
    "value": "Salo"
  },
  {
    "id": "1401132002",
    "district_id": "140113",
    "label": "Siabu",
    "value": "Siabu"
  },
  {
    "id": "1401132003",
    "district_id": "140113",
    "label": "Salo Timur",
    "value": "Salo Timur"
  },
  {
    "id": "1401132004",
    "district_id": "140113",
    "label": "Sipungguk",
    "value": "Sipungguk"
  },
  {
    "id": "1401132005",
    "district_id": "140113",
    "label": "Ganting Damai",
    "value": "Ganting Damai"
  },
  {
    "id": "1401132006",
    "district_id": "140113",
    "label": "Ganting",
    "value": "Ganting"
  },
  {
    "id": "1401142001",
    "district_id": "140114",
    "label": "Pulau Payung",
    "value": "Pulau Payung"
  },
  {
    "id": "1401142002",
    "district_id": "140114",
    "label": "Teratak",
    "value": "Teratak"
  },
  {
    "id": "1401142003",
    "district_id": "140114",
    "label": "Alam Panjang",
    "value": "Alam Panjang"
  },
  {
    "id": "1401142004",
    "district_id": "140114",
    "label": "Bukit Kratai",
    "value": "Bukit Kratai"
  },
  {
    "id": "1401142005",
    "district_id": "140114",
    "label": "Batang Batindih",
    "value": "Batang Batindih"
  },
  {
    "id": "1401142006",
    "district_id": "140114",
    "label": "Tambusai",
    "value": "Tambusai"
  },
  {
    "id": "1401142007",
    "district_id": "140114",
    "label": "Simpang Petai",
    "value": "Simpang Petai"
  },
  {
    "id": "1401151007",
    "district_id": "140115",
    "label": "Pulau",
    "value": "Pulau"
  },
  {
    "id": "1401151008",
    "district_id": "140115",
    "label": "Pasir Sialang",
    "value": "Pasir Sialang"
  },
  {
    "id": "1401152001",
    "district_id": "140115",
    "label": "Pulau Lawas",
    "value": "Pulau Lawas"
  },
  {
    "id": "1401152002",
    "district_id": "140115",
    "label": "Muara Uwai",
    "value": "Muara Uwai"
  },
  {
    "id": "1401152003",
    "district_id": "140115",
    "label": "Laboy Jaya",
    "value": "Laboy Jaya"
  },
  {
    "id": "1401152004",
    "district_id": "140115",
    "label": "Bukit Payung",
    "value": "Bukit Payung"
  },
  {
    "id": "1401152005",
    "district_id": "140115",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1401152006",
    "district_id": "140115",
    "label": "Bukit Sembilan",
    "value": "Bukit Sembilan"
  },
  {
    "id": "1401152009",
    "district_id": "140115",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "1401162001",
    "district_id": "140116",
    "label": "Kampung Pinang",
    "value": "Kampung Pinang"
  },
  {
    "id": "1401162002",
    "district_id": "140116",
    "label": "Pantai Raja",
    "value": "Pantai Raja"
  },
  {
    "id": "1401162003",
    "district_id": "140116",
    "label": "Hangtuah",
    "value": "Hangtuah"
  },
  {
    "id": "1401162004",
    "district_id": "140116",
    "label": "Sialang Kubang",
    "value": "Sialang Kubang"
  },
  {
    "id": "1401162005",
    "district_id": "140116",
    "label": "Lubuk Sakat",
    "value": "Lubuk Sakat"
  },
  {
    "id": "1401172001",
    "district_id": "140117",
    "label": "Pulau Birandang",
    "value": "Pulau Birandang"
  },
  {
    "id": "1401172002",
    "district_id": "140117",
    "label": "Pulau Rambai",
    "value": "Pulau Rambai"
  },
  {
    "id": "1401172003",
    "district_id": "140117",
    "label": "Kampar",
    "value": "Kampar"
  },
  {
    "id": "1401172004",
    "district_id": "140117",
    "label": "Koto Perambahan",
    "value": "Koto Perambahan"
  },
  {
    "id": "1401172005",
    "district_id": "140117",
    "label": "Sungai Putih",
    "value": "Sungai Putih"
  },
  {
    "id": "1401172006",
    "district_id": "140117",
    "label": "Deli Makmur",
    "value": "Deli Makmur"
  },
  {
    "id": "1401172007",
    "district_id": "140117",
    "label": "Sungai Tarap",
    "value": "Sungai Tarap"
  },
  {
    "id": "1401172008",
    "district_id": "140117",
    "label": "Tanjung Bungo",
    "value": "Tanjung Bungo"
  },
  {
    "id": "1401172009",
    "district_id": "140117",
    "label": "Sawah Baru",
    "value": "Sawah Baru"
  },
  {
    "id": "1401182001",
    "district_id": "140118",
    "label": "Muara Jalai",
    "value": "Muara Jalai"
  },
  {
    "id": "1401182002",
    "district_id": "140118",
    "label": "Sawah",
    "value": "Sawah"
  },
  {
    "id": "1401182003",
    "district_id": "140118",
    "label": "Kampung Panjang",
    "value": "Kampung Panjang"
  },
  {
    "id": "1401182004",
    "district_id": "140118",
    "label": "Kayu Aro",
    "value": "Kayu Aro"
  },
  {
    "id": "1401182005",
    "district_id": "140118",
    "label": "Sungai Tonang",
    "value": "Sungai Tonang"
  },
  {
    "id": "1401182006",
    "district_id": "140118",
    "label": "Sungai Jalau",
    "value": "Sungai Jalau"
  },
  {
    "id": "1401182007",
    "district_id": "140118",
    "label": "Sendayan",
    "value": "Sendayan"
  },
  {
    "id": "1401182008",
    "district_id": "140118",
    "label": "Naga Beralih",
    "value": "Naga Beralih"
  },
  {
    "id": "1401192001",
    "district_id": "140119",
    "label": "Simalinyang",
    "value": "Simalinyang"
  },
  {
    "id": "1401192002",
    "district_id": "140119",
    "label": "Bina Baru",
    "value": "Bina Baru"
  },
  {
    "id": "1401192003",
    "district_id": "140119",
    "label": "Hidup Baru",
    "value": "Hidup Baru"
  },
  {
    "id": "1401192004",
    "district_id": "140119",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1401192005",
    "district_id": "140119",
    "label": "Lubuk Sakai",
    "value": "Lubuk Sakai"
  },
  {
    "id": "1401192006",
    "district_id": "140119",
    "label": "Penghidupan",
    "value": "Penghidupan"
  },
  {
    "id": "1401192007",
    "district_id": "140119",
    "label": "Mayang Pongkai",
    "value": "Mayang Pongkai"
  },
  {
    "id": "1401192008",
    "district_id": "140119",
    "label": "Koto Damai",
    "value": "Koto Damai"
  },
  {
    "id": "1401192009",
    "district_id": "140119",
    "label": "Utama Karya",
    "value": "Utama Karya"
  },
  {
    "id": "1401192010",
    "district_id": "140119",
    "label": "Bukit Sakai",
    "value": "Bukit Sakai"
  },
  {
    "id": "1401192011",
    "district_id": "140119",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1401202001",
    "district_id": "140120",
    "label": "Kebun Durian",
    "value": "Kebun Durian"
  },
  {
    "id": "1401202002",
    "district_id": "140120",
    "label": "Gunung Sahilan",
    "value": "Gunung Sahilan"
  },
  {
    "id": "1401202003",
    "district_id": "140120",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1401202004",
    "district_id": "140120",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1401202005",
    "district_id": "140120",
    "label": "Subarak",
    "value": "Subarak"
  },
  {
    "id": "1401202006",
    "district_id": "140120",
    "label": "Sungai Lipai",
    "value": "Sungai Lipai"
  },
  {
    "id": "1401202007",
    "district_id": "140120",
    "label": "Sahilan Darussalam",
    "value": "Sahilan Darussalam"
  },
  {
    "id": "1401202008",
    "district_id": "140120",
    "label": "Gunung Mulya",
    "value": "Gunung Mulya"
  },
  {
    "id": "1401202009",
    "district_id": "140120",
    "label": "Makmur Sejahtera",
    "value": "Makmur Sejahtera"
  },
  {
    "id": "1401212001",
    "district_id": "140121",
    "label": "Siberuang",
    "value": "Siberuang"
  },
  {
    "id": "1401212002",
    "district_id": "140121",
    "label": "Bandur Picak",
    "value": "Bandur Picak"
  },
  {
    "id": "1401212003",
    "district_id": "140121",
    "label": "Gunung Malelo",
    "value": "Gunung Malelo"
  },
  {
    "id": "1401212004",
    "district_id": "140121",
    "label": "Tabing",
    "value": "Tabing"
  },
  {
    "id": "1401212005",
    "district_id": "140121",
    "label": "Pongkai",
    "value": "Pongkai"
  },
  {
    "id": "1401212006",
    "district_id": "140121",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1402011004",
    "district_id": "140201",
    "label": "Kampung Dagang",
    "value": "Kampung Dagang"
  },
  {
    "id": "1402011006",
    "district_id": "140201",
    "label": "Pasar Kota",
    "value": "Pasar Kota"
  },
  {
    "id": "1402011007",
    "district_id": "140201",
    "label": "Kampung Besar Kota",
    "value": "Kampung Besar Kota"
  },
  {
    "id": "1402011008",
    "district_id": "140201",
    "label": "Sekip Hulu",
    "value": "Sekip Hulu"
  },
  {
    "id": "1402011011",
    "district_id": "140201",
    "label": "Kmp Besar Seberang",
    "value": "Kmp Besar Seberang"
  },
  {
    "id": "1402011018",
    "district_id": "140201",
    "label": "Sekip Hilir",
    "value": "Sekip Hilir"
  },
  {
    "id": "1402012001",
    "district_id": "140201",
    "label": "Sungai Guntung Tengah",
    "value": "Sungai Guntung Tengah"
  },
  {
    "id": "1402012002",
    "district_id": "140201",
    "label": "Sungai Guntung Hilir",
    "value": "Sungai Guntung Hilir"
  },
  {
    "id": "1402012003",
    "district_id": "140201",
    "label": "Kuantan Babu",
    "value": "Kuantan Babu"
  },
  {
    "id": "1402012005",
    "district_id": "140201",
    "label": "Kampung Pulau",
    "value": "Kampung Pulau"
  },
  {
    "id": "1402012009",
    "district_id": "140201",
    "label": "Sungai Beringin",
    "value": "Sungai Beringin"
  },
  {
    "id": "1402012010",
    "district_id": "140201",
    "label": "Pasir Kemilu",
    "value": "Pasir Kemilu"
  },
  {
    "id": "1402012012",
    "district_id": "140201",
    "label": "Rantau Mapesai",
    "value": "Rantau Mapesai"
  },
  {
    "id": "1402012013",
    "district_id": "140201",
    "label": "Pulau Gajah",
    "value": "Pulau Gajah"
  },
  {
    "id": "1402012014",
    "district_id": "140201",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "1402012023",
    "district_id": "140201",
    "label": "Rawa Bangun",
    "value": "Rawa Bangun"
  },
  {
    "id": "1402021009",
    "district_id": "140202",
    "label": "Pematang Reba",
    "value": "Pematang Reba"
  },
  {
    "id": "1402022001",
    "district_id": "140202",
    "label": "Barangan",
    "value": "Barangan"
  },
  {
    "id": "1402022002",
    "district_id": "140202",
    "label": "Alang Kepayang",
    "value": "Alang Kepayang"
  },
  {
    "id": "1402022003",
    "district_id": "140202",
    "label": "Kota Lama",
    "value": "Kota Lama"
  },
  {
    "id": "1402022004",
    "district_id": "140202",
    "label": "Redang",
    "value": "Redang"
  },
  {
    "id": "1402022005",
    "district_id": "140202",
    "label": "Pekan Heran",
    "value": "Pekan Heran"
  },
  {
    "id": "1402022006",
    "district_id": "140202",
    "label": "Rantau Bakung",
    "value": "Rantau Bakung"
  },
  {
    "id": "1402022007",
    "district_id": "140202",
    "label": "Talang Jerinjing",
    "value": "Talang Jerinjing"
  },
  {
    "id": "1402022008",
    "district_id": "140202",
    "label": "Sialang Dua Dahan",
    "value": "Sialang Dua Dahan"
  },
  {
    "id": "1402022010",
    "district_id": "140202",
    "label": "Sungai Dawu",
    "value": "Sungai Dawu"
  },
  {
    "id": "1402022011",
    "district_id": "140202",
    "label": "Bukit Petaling",
    "value": "Bukit Petaling"
  },
  {
    "id": "1402022012",
    "district_id": "140202",
    "label": "Pematang Jaya",
    "value": "Pematang Jaya"
  },
  {
    "id": "1402022013",
    "district_id": "140202",
    "label": "Danau Baru",
    "value": "Danau Baru"
  },
  {
    "id": "1402022014",
    "district_id": "140202",
    "label": "Tanah Datar",
    "value": "Tanah Datar"
  },
  {
    "id": "1402022015",
    "district_id": "140202",
    "label": "Tani Makmur",
    "value": "Tani Makmur"
  },
  {
    "id": "1402022016",
    "district_id": "140202",
    "label": "Sungai Baung",
    "value": "Sungai Baung"
  },
  {
    "id": "1402022017",
    "district_id": "140202",
    "label": "Danau Tiga",
    "value": "Danau Tiga"
  },
  {
    "id": "1402022018",
    "district_id": "140202",
    "label": "Air Jernih",
    "value": "Air Jernih"
  },
  {
    "id": "1402031019",
    "district_id": "140203",
    "label": "Simpang Kelayang",
    "value": "Simpang Kelayang"
  },
  {
    "id": "1402032007",
    "district_id": "140203",
    "label": "Pulau Sengkilo",
    "value": "Pulau Sengkilo"
  },
  {
    "id": "1402032008",
    "district_id": "140203",
    "label": "Kota Medan",
    "value": "Kota Medan"
  },
  {
    "id": "1402032009",
    "district_id": "140203",
    "label": "Simpang Kota Medan",
    "value": "Simpang Kota Medan"
  },
  {
    "id": "1402032010",
    "district_id": "140203",
    "label": "Polak Pisang",
    "value": "Polak Pisang"
  },
  {
    "id": "1402032018",
    "district_id": "140203",
    "label": "Pelangko",
    "value": "Pelangko"
  },
  {
    "id": "1402032020",
    "district_id": "140203",
    "label": "Teluk Sejuah",
    "value": "Teluk Sejuah"
  },
  {
    "id": "1402032021",
    "district_id": "140203",
    "label": "Bongkal Malang",
    "value": "Bongkal Malang"
  },
  {
    "id": "1402032022",
    "district_id": "140203",
    "label": "Dusun Tua",
    "value": "Dusun Tua"
  },
  {
    "id": "1402032024",
    "district_id": "140203",
    "label": "Sungai Banyak Ikan",
    "value": "Sungai Banyak Ikan"
  },
  {
    "id": "1402032025",
    "district_id": "140203",
    "label": "Tanjung Beludu",
    "value": "Tanjung Beludu"
  },
  {
    "id": "1402032026",
    "district_id": "140203",
    "label": "Sungai Pasir Putih",
    "value": "Sungai Pasir Putih"
  },
  {
    "id": "1402032027",
    "district_id": "140203",
    "label": "Sungai Golang",
    "value": "Sungai Golang"
  },
  {
    "id": "1402032028",
    "district_id": "140203",
    "label": "Pasir Beringin",
    "value": "Pasir Beringin"
  },
  {
    "id": "1402032029",
    "district_id": "140203",
    "label": "Sungai Kuning Benio",
    "value": "Sungai Kuning Benio"
  },
  {
    "id": "1402032030",
    "district_id": "140203",
    "label": "Bukit Selanjut",
    "value": "Bukit Selanjut"
  },
  {
    "id": "1402032031",
    "district_id": "140203",
    "label": "Dusun Tua Pelang",
    "value": "Dusun Tua Pelang"
  },
  {
    "id": "1402041007",
    "district_id": "140204",
    "label": "Air Molek",
    "value": "Air Molek"
  },
  {
    "id": "1402041020",
    "district_id": "140204",
    "label": "Kembang Harum",
    "value": "Kembang Harum"
  },
  {
    "id": "1402041031",
    "district_id": "140204",
    "label": "Tanjung Gading",
    "value": "Tanjung Gading"
  },
  {
    "id": "1402041032",
    "district_id": "140204",
    "label": "Sekar Mawar",
    "value": "Sekar Mawar"
  },
  {
    "id": "1402041033",
    "district_id": "140204",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1402042006",
    "district_id": "140204",
    "label": "Petalongan",
    "value": "Petalongan"
  },
  {
    "id": "1402042008",
    "district_id": "140204",
    "label": "Air Molek II",
    "value": "Air Molek II"
  },
  {
    "id": "1402042009",
    "district_id": "140204",
    "label": "Pasir Keranji",
    "value": "Pasir Keranji"
  },
  {
    "id": "1402042016",
    "district_id": "140204",
    "label": "Jati Rejo",
    "value": "Jati Rejo"
  },
  {
    "id": "1402042017",
    "district_id": "140204",
    "label": "Batu Gajah",
    "value": "Batu Gajah"
  },
  {
    "id": "1402042018",
    "district_id": "140204",
    "label": "Candi Rejo",
    "value": "Candi Rejo"
  },
  {
    "id": "1402042019",
    "district_id": "140204",
    "label": "Lembah Dusun Gading",
    "value": "Lembah Dusun Gading"
  },
  {
    "id": "1402042030",
    "district_id": "140204",
    "label": "Serumpun Jaya",
    "value": "Serumpun Jaya"
  },
  {
    "id": "1402051005",
    "district_id": "140205",
    "label": "Peranap",
    "value": "Peranap"
  },
  {
    "id": "1402051008",
    "district_id": "140205",
    "label": "Batu Rijal Hilir",
    "value": "Batu Rijal Hilir"
  },
  {
    "id": "1402052003",
    "district_id": "140205",
    "label": "Semelinang Tebing",
    "value": "Semelinang Tebing"
  },
  {
    "id": "1402052004",
    "district_id": "140205",
    "label": "Gumanti",
    "value": "Gumanti"
  },
  {
    "id": "1402052007",
    "district_id": "140205",
    "label": "Batu Rijal Hulu",
    "value": "Batu Rijal Hulu"
  },
  {
    "id": "1402052009",
    "district_id": "140205",
    "label": "Semelinang Darat",
    "value": "Semelinang Darat"
  },
  {
    "id": "1402052010",
    "district_id": "140205",
    "label": "Pauh Ranap",
    "value": "Pauh Ranap"
  },
  {
    "id": "1402052012",
    "district_id": "140205",
    "label": "Serai Wangi",
    "value": "Serai Wangi"
  },
  {
    "id": "1402052013",
    "district_id": "140205",
    "label": "Pandan Wangi",
    "value": "Pandan Wangi"
  },
  {
    "id": "1402052017",
    "district_id": "140205",
    "label": "Katipo Pura",
    "value": "Katipo Pura"
  },
  {
    "id": "1402052019",
    "district_id": "140205",
    "label": "Batu Rijal Barat",
    "value": "Batu Rijal Barat"
  },
  {
    "id": "1402052020",
    "district_id": "140205",
    "label": "Setako Raya",
    "value": "Setako Raya"
  },
  {
    "id": "1402061001",
    "district_id": "140206",
    "label": "Pangkalan Kasai",
    "value": "Pangkalan Kasai"
  },
  {
    "id": "1402062002",
    "district_id": "140206",
    "label": "Kelesa",
    "value": "Kelesa"
  },
  {
    "id": "1402062003",
    "district_id": "140206",
    "label": "Beligan",
    "value": "Beligan"
  },
  {
    "id": "1402062004",
    "district_id": "140206",
    "label": "Seresam",
    "value": "Seresam"
  },
  {
    "id": "1402062005",
    "district_id": "140206",
    "label": "Paya Rumbai",
    "value": "Paya Rumbai"
  },
  {
    "id": "1402062006",
    "district_id": "140206",
    "label": "Bukit Meranti",
    "value": "Bukit Meranti"
  },
  {
    "id": "1402062007",
    "district_id": "140206",
    "label": "Buluh Rampai",
    "value": "Buluh Rampai"
  },
  {
    "id": "1402062008",
    "district_id": "140206",
    "label": "Titian Resak",
    "value": "Titian Resak"
  },
  {
    "id": "1402062009",
    "district_id": "140206",
    "label": "Petala Bumi",
    "value": "Petala Bumi"
  },
  {
    "id": "1402062010",
    "district_id": "140206",
    "label": "Sibabat",
    "value": "Sibabat"
  },
  {
    "id": "1402062011",
    "district_id": "140206",
    "label": "Bandar Padang",
    "value": "Bandar Padang"
  },
  {
    "id": "1402072002",
    "district_id": "140207",
    "label": "Anak Talang",
    "value": "Anak Talang"
  },
  {
    "id": "1402072004",
    "district_id": "140207",
    "label": "Bukit Lingkar",
    "value": "Bukit Lingkar"
  },
  {
    "id": "1402072005",
    "district_id": "140207",
    "label": "Bukit Lipai",
    "value": "Bukit Lipai"
  },
  {
    "id": "1402072006",
    "district_id": "140207",
    "label": "Kuala Gading",
    "value": "Kuala Gading"
  },
  {
    "id": "1402072007",
    "district_id": "140207",
    "label": "Kuala Kilan",
    "value": "Kuala Kilan"
  },
  {
    "id": "1402072008",
    "district_id": "140207",
    "label": "Kerubung Jaya",
    "value": "Kerubung Jaya"
  },
  {
    "id": "1402072009",
    "district_id": "140207",
    "label": "Pejangki",
    "value": "Pejangki"
  },
  {
    "id": "1402072010",
    "district_id": "140207",
    "label": "Pematang Manggis",
    "value": "Pematang Manggis"
  },
  {
    "id": "1402072011",
    "district_id": "140207",
    "label": "Sipang",
    "value": "Sipang"
  },
  {
    "id": "1402072012",
    "district_id": "140207",
    "label": "Talang Bersemi",
    "value": "Talang Bersemi"
  },
  {
    "id": "1402072013",
    "district_id": "140207",
    "label": "Talang Mulya",
    "value": "Talang Mulya"
  },
  {
    "id": "1402072014",
    "district_id": "140207",
    "label": "Punti Anai",
    "value": "Punti Anai"
  },
  {
    "id": "1402072015",
    "district_id": "140207",
    "label": "Petaling Jaya",
    "value": "Petaling Jaya"
  },
  {
    "id": "1402072017",
    "district_id": "140207",
    "label": "Cenaku Kecil",
    "value": "Cenaku Kecil"
  },
  {
    "id": "1402072018",
    "district_id": "140207",
    "label": "Alim",
    "value": "Alim"
  },
  {
    "id": "1402072019",
    "district_id": "140207",
    "label": "Aur Cina",
    "value": "Aur Cina"
  },
  {
    "id": "1402072020",
    "district_id": "140207",
    "label": "Lahai Kemuning",
    "value": "Lahai Kemuning"
  },
  {
    "id": "1402072021",
    "district_id": "140207",
    "label": "Kepayang Sari",
    "value": "Kepayang Sari"
  },
  {
    "id": "1402072022",
    "district_id": "140207",
    "label": "Batu Papan",
    "value": "Batu Papan"
  },
  {
    "id": "1402072023",
    "district_id": "140207",
    "label": "Sanglap",
    "value": "Sanglap"
  },
  {
    "id": "1402082001",
    "district_id": "140208",
    "label": "Siambul",
    "value": "Siambul"
  },
  {
    "id": "1402082002",
    "district_id": "140208",
    "label": "Rantai Langsat",
    "value": "Rantai Langsat"
  },
  {
    "id": "1402082003",
    "district_id": "140208",
    "label": "Sungai Akar",
    "value": "Sungai Akar"
  },
  {
    "id": "1402082004",
    "district_id": "140208",
    "label": "Usul",
    "value": "Usul"
  },
  {
    "id": "1402082005",
    "district_id": "140208",
    "label": "Seberida",
    "value": "Seberida"
  },
  {
    "id": "1402082006",
    "district_id": "140208",
    "label": "Talang Lakat",
    "value": "Talang Lakat"
  },
  {
    "id": "1402082007",
    "district_id": "140208",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "1402082008",
    "district_id": "140208",
    "label": "Ringin",
    "value": "Ringin"
  },
  {
    "id": "1402082010",
    "district_id": "140208",
    "label": "Penyaguan",
    "value": "Penyaguan"
  },
  {
    "id": "1402082011",
    "district_id": "140208",
    "label": "Danau Rambai",
    "value": "Danau Rambai"
  },
  {
    "id": "1402092001",
    "district_id": "140209",
    "label": "Japura",
    "value": "Japura"
  },
  {
    "id": "1402092002",
    "district_id": "140209",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1402092003",
    "district_id": "140209",
    "label": "Pasir Ringgit",
    "value": "Pasir Ringgit"
  },
  {
    "id": "1402092004",
    "district_id": "140209",
    "label": "Gudang Batu",
    "value": "Gudang Batu"
  },
  {
    "id": "1402092005",
    "district_id": "140209",
    "label": "Sungai Sagu",
    "value": "Sungai Sagu"
  },
  {
    "id": "1402092006",
    "district_id": "140209",
    "label": "Lirik Area",
    "value": "Lirik Area"
  },
  {
    "id": "1402092007",
    "district_id": "140209",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "1402092008",
    "district_id": "140209",
    "label": "Lambang Sari V",
    "value": "Lambang Sari V"
  },
  {
    "id": "1402092009",
    "district_id": "140209",
    "label": "Lambang Sari IV",
    "value": "Lambang Sari IV"
  },
  {
    "id": "1402092010",
    "district_id": "140209",
    "label": "Lambang Sari I, II, III",
    "value": "Lambang Sari I, II, III"
  },
  {
    "id": "1402092011",
    "district_id": "140209",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1402092012",
    "district_id": "140209",
    "label": "Seko Lubuk Tigo",
    "value": "Seko Lubuk Tigo"
  },
  {
    "id": "1402092013",
    "district_id": "140209",
    "label": "Banjar Balam",
    "value": "Banjar Balam"
  },
  {
    "id": "1402092014",
    "district_id": "140209",
    "label": "Redang Seko",
    "value": "Redang Seko"
  },
  {
    "id": "1402092015",
    "district_id": "140209",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1402092016",
    "district_id": "140209",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1402092017",
    "district_id": "140209",
    "label": "Pasir Sialang Jaya",
    "value": "Pasir Sialang Jaya"
  },
  {
    "id": "1402102001",
    "district_id": "140210",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "1402102002",
    "district_id": "140210",
    "label": "Kuala Cenaku",
    "value": "Kuala Cenaku"
  },
  {
    "id": "1402102003",
    "district_id": "140210",
    "label": "Pulau Gelang",
    "value": "Pulau Gelang"
  },
  {
    "id": "1402102004",
    "district_id": "140210",
    "label": "Kuala Mulya",
    "value": "Kuala Mulya"
  },
  {
    "id": "1402102005",
    "district_id": "140210",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1402102006",
    "district_id": "140210",
    "label": "Rawa Sekip",
    "value": "Rawa Sekip"
  },
  {
    "id": "1402102007",
    "district_id": "140210",
    "label": "Rawa Asri",
    "value": "Rawa Asri"
  },
  {
    "id": "1402102008",
    "district_id": "140210",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1402102009",
    "district_id": "140210",
    "label": "Teluk Sungkai",
    "value": "Teluk Sungkai"
  },
  {
    "id": "1402102010",
    "district_id": "140210",
    "label": "Pulau Jum'at",
    "value": "Pulau Jum'at"
  },
  {
    "id": "1402112001",
    "district_id": "140211",
    "label": "Morong",
    "value": "Morong"
  },
  {
    "id": "1402112002",
    "district_id": "140211",
    "label": "Pasir Bongkal",
    "value": "Pasir Bongkal"
  },
  {
    "id": "1402112003",
    "district_id": "140211",
    "label": "Pasir Selabau",
    "value": "Pasir Selabau"
  },
  {
    "id": "1402112004",
    "district_id": "140211",
    "label": "Pasir Batu Mandi",
    "value": "Pasir Batu Mandi"
  },
  {
    "id": "1402112005",
    "district_id": "140211",
    "label": "Pasir Kelampaian",
    "value": "Pasir Kelampaian"
  },
  {
    "id": "1402112006",
    "district_id": "140211",
    "label": "Kuala Lala",
    "value": "Kuala Lala"
  },
  {
    "id": "1402112007",
    "district_id": "140211",
    "label": "Sungai Lala",
    "value": "Sungai Lala"
  },
  {
    "id": "1402112008",
    "district_id": "140211",
    "label": "Kelawat",
    "value": "Kelawat"
  },
  {
    "id": "1402112009",
    "district_id": "140211",
    "label": "Perkebunan Sungai Parit",
    "value": "Perkebunan Sungai Parit"
  },
  {
    "id": "1402112010",
    "district_id": "140211",
    "label": "Perkebunan Sungai Lala",
    "value": "Perkebunan Sungai Lala"
  },
  {
    "id": "1402112011",
    "district_id": "140211",
    "label": "Tanjung Danau",
    "value": "Tanjung Danau"
  },
  {
    "id": "1402112012",
    "district_id": "140211",
    "label": "Sungai Air Putih",
    "value": "Sungai Air Putih"
  },
  {
    "id": "1402122001",
    "district_id": "140212",
    "label": "Rimpian",
    "value": "Rimpian"
  },
  {
    "id": "1402122002",
    "district_id": "140212",
    "label": "Pondok Gelugur",
    "value": "Pondok Gelugur"
  },
  {
    "id": "1402122003",
    "district_id": "140212",
    "label": "Lubuk Batu Tinggal",
    "value": "Lubuk Batu Tinggal"
  },
  {
    "id": "1402122004",
    "district_id": "140212",
    "label": "Sungai Beras Hilir",
    "value": "Sungai Beras Hilir"
  },
  {
    "id": "1402122005",
    "district_id": "140212",
    "label": "Kulim Jaya",
    "value": "Kulim Jaya"
  },
  {
    "id": "1402122006",
    "district_id": "140212",
    "label": "Sungai Beras Beras",
    "value": "Sungai Beras Beras"
  },
  {
    "id": "1402122007",
    "district_id": "140212",
    "label": "Tasik Juang",
    "value": "Tasik Juang"
  },
  {
    "id": "1402122008",
    "district_id": "140212",
    "label": "Pontian Mekar",
    "value": "Pontian Mekar"
  },
  {
    "id": "1402122009",
    "district_id": "140212",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "1402132001",
    "district_id": "140213",
    "label": "Tujuh Buah Tangga",
    "value": "Tujuh Buah Tangga"
  },
  {
    "id": "1402132002",
    "district_id": "140213",
    "label": "Talang Durian Cacar",
    "value": "Talang Durian Cacar"
  },
  {
    "id": "1402132003",
    "district_id": "140213",
    "label": "Talang Selantai",
    "value": "Talang Selantai"
  },
  {
    "id": "1402132004",
    "district_id": "140213",
    "label": "Talang Perigi",
    "value": "Talang Perigi"
  },
  {
    "id": "1402132005",
    "district_id": "140213",
    "label": "Kuantan Tenang",
    "value": "Kuantan Tenang"
  },
  {
    "id": "1402132006",
    "district_id": "140213",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1402132007",
    "district_id": "140213",
    "label": "Rimba Seminai",
    "value": "Rimba Seminai"
  },
  {
    "id": "1402132008",
    "district_id": "140213",
    "label": "Kelayang",
    "value": "Kelayang"
  },
  {
    "id": "1402132009",
    "district_id": "140213",
    "label": "Talang Gedabu",
    "value": "Talang Gedabu"
  },
  {
    "id": "1402132010",
    "district_id": "140213",
    "label": "Talang Sungai Limau",
    "value": "Talang Sungai Limau"
  },
  {
    "id": "1402132011",
    "district_id": "140213",
    "label": "Talang Sungai Parit",
    "value": "Talang Sungai Parit"
  },
  {
    "id": "1402132012",
    "district_id": "140213",
    "label": "Batu Sawar",
    "value": "Batu Sawar"
  },
  {
    "id": "1402132013",
    "district_id": "140213",
    "label": "Petonggan",
    "value": "Petonggan"
  },
  {
    "id": "1402132014",
    "district_id": "140213",
    "label": "Lubuk Sitarak",
    "value": "Lubuk Sitarak"
  },
  {
    "id": "1402132015",
    "district_id": "140213",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "1402132016",
    "district_id": "140213",
    "label": "Sungai Ekok",
    "value": "Sungai Ekok"
  },
  {
    "id": "1402132017",
    "district_id": "140213",
    "label": "Talang Suka Maju",
    "value": "Talang Suka Maju"
  },
  {
    "id": "1402132018",
    "district_id": "140213",
    "label": "Kampung Bunga",
    "value": "Kampung Bunga"
  },
  {
    "id": "1402132019",
    "district_id": "140213",
    "label": "Talang Pring Jaya",
    "value": "Talang Pring Jaya"
  },
  {
    "id": "1402142001",
    "district_id": "140214",
    "label": "Pesajian",
    "value": "Pesajian"
  },
  {
    "id": "1402142002",
    "district_id": "140214",
    "label": "Punti Kayu",
    "value": "Punti Kayu"
  },
  {
    "id": "1402142003",
    "district_id": "140214",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "1402142004",
    "district_id": "140214",
    "label": "Selunak",
    "value": "Selunak"
  },
  {
    "id": "1402142005",
    "district_id": "140214",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1402142006",
    "district_id": "140214",
    "label": "Pematang Benteng",
    "value": "Pematang Benteng"
  },
  {
    "id": "1402142007",
    "district_id": "140214",
    "label": "Sencano Jaya",
    "value": "Sencano Jaya"
  },
  {
    "id": "1402142008",
    "district_id": "140214",
    "label": "Sungai Aur",
    "value": "Sungai Aur"
  },
  {
    "id": "1402142009",
    "district_id": "140214",
    "label": "Peladangan",
    "value": "Peladangan"
  },
  {
    "id": "1402142010",
    "district_id": "140214",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1403011001",
    "district_id": "140301",
    "label": "Kota Bengkalis",
    "value": "Kota Bengkalis"
  },
  {
    "id": "1403011002",
    "district_id": "140301",
    "label": "Damon",
    "value": "Damon"
  },
  {
    "id": "1403011003",
    "district_id": "140301",
    "label": "Rimba Sekampung",
    "value": "Rimba Sekampung"
  },
  {
    "id": "1403012004",
    "district_id": "140301",
    "label": "Kelapapati",
    "value": "Kelapapati"
  },
  {
    "id": "1403012005",
    "district_id": "140301",
    "label": "Pedekik",
    "value": "Pedekik"
  },
  {
    "id": "1403012006",
    "district_id": "140301",
    "label": "Pangkalan Batang",
    "value": "Pangkalan Batang"
  },
  {
    "id": "1403012007",
    "district_id": "140301",
    "label": "Sebauk",
    "value": "Sebauk"
  },
  {
    "id": "1403012008",
    "district_id": "140301",
    "label": "Teluklatak",
    "value": "Teluklatak"
  },
  {
    "id": "1403012009",
    "district_id": "140301",
    "label": "Meskom",
    "value": "Meskom"
  },
  {
    "id": "1403012010",
    "district_id": "140301",
    "label": "Senggoro",
    "value": "Senggoro"
  },
  {
    "id": "1403012011",
    "district_id": "140301",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "1403012012",
    "district_id": "140301",
    "label": "Sei Alam",
    "value": "Sei Alam"
  },
  {
    "id": "1403012013",
    "district_id": "140301",
    "label": "Penampi",
    "value": "Penampi"
  },
  {
    "id": "1403012014",
    "district_id": "140301",
    "label": "Temeran",
    "value": "Temeran"
  },
  {
    "id": "1403012015",
    "district_id": "140301",
    "label": "Penebal",
    "value": "Penebal"
  },
  {
    "id": "1403012016",
    "district_id": "140301",
    "label": "Pematang Duku",
    "value": "Pematang Duku"
  },
  {
    "id": "1403012017",
    "district_id": "140301",
    "label": "Ketam Putih",
    "value": "Ketam Putih"
  },
  {
    "id": "1403012018",
    "district_id": "140301",
    "label": "Kelemantan",
    "value": "Kelemantan"
  },
  {
    "id": "1403012019",
    "district_id": "140301",
    "label": "Sekodi",
    "value": "Sekodi"
  },
  {
    "id": "1403012020",
    "district_id": "140301",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1403012021",
    "district_id": "140301",
    "label": "Kuala Alam",
    "value": "Kuala Alam"
  },
  {
    "id": "1403012022",
    "district_id": "140301",
    "label": "Kelebuk",
    "value": "Kelebuk"
  },
  {
    "id": "1403012023",
    "district_id": "140301",
    "label": "Palkun",
    "value": "Palkun"
  },
  {
    "id": "1403012024",
    "district_id": "140301",
    "label": "Sungaibatang",
    "value": "Sungaibatang"
  },
  {
    "id": "1403012025",
    "district_id": "140301",
    "label": "Prapat Tunggal",
    "value": "Prapat Tunggal"
  },
  {
    "id": "1403012026",
    "district_id": "140301",
    "label": "Simpang Ayam",
    "value": "Simpang Ayam"
  },
  {
    "id": "1403012027",
    "district_id": "140301",
    "label": "Senderek",
    "value": "Senderek"
  },
  {
    "id": "1403012028",
    "district_id": "140301",
    "label": "Kelemantan Barat",
    "value": "Kelemantan Barat"
  },
  {
    "id": "1403012029",
    "district_id": "140301",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "1403012030",
    "district_id": "140301",
    "label": "Pangkalan Batang Barat",
    "value": "Pangkalan Batang Barat"
  },
  {
    "id": "1403012031",
    "district_id": "140301",
    "label": "Pematang Duku Timur",
    "value": "Pematang Duku Timur"
  },
  {
    "id": "1403022001",
    "district_id": "140302",
    "label": "Bantan Tengah",
    "value": "Bantan Tengah"
  },
  {
    "id": "1403022002",
    "district_id": "140302",
    "label": "Bantan Air",
    "value": "Bantan Air"
  },
  {
    "id": "1403022003",
    "district_id": "140302",
    "label": "Bantan Tua",
    "value": "Bantan Tua"
  },
  {
    "id": "1403022004",
    "district_id": "140302",
    "label": "Telukpambang",
    "value": "Telukpambang"
  },
  {
    "id": "1403022005",
    "district_id": "140302",
    "label": "Selat Baru",
    "value": "Selat Baru"
  },
  {
    "id": "1403022006",
    "district_id": "140302",
    "label": "Teluklancar",
    "value": "Teluklancar"
  },
  {
    "id": "1403022007",
    "district_id": "140302",
    "label": "Kembung Luar",
    "value": "Kembung Luar"
  },
  {
    "id": "1403022008",
    "district_id": "140302",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  {
    "id": "1403022009",
    "district_id": "140302",
    "label": "Muntai",
    "value": "Muntai"
  },
  {
    "id": "1403022010",
    "district_id": "140302",
    "label": "Resam Lapis",
    "value": "Resam Lapis"
  },
  {
    "id": "1403022011",
    "district_id": "140302",
    "label": "Berancah",
    "value": "Berancah"
  },
  {
    "id": "1403022012",
    "district_id": "140302",
    "label": "Ulu Pulau",
    "value": "Ulu Pulau"
  },
  {
    "id": "1403022013",
    "district_id": "140302",
    "label": "Mentayan",
    "value": "Mentayan"
  },
  {
    "id": "1403022014",
    "district_id": "140302",
    "label": "Pampang Pesisir",
    "value": "Pampang Pesisir"
  },
  {
    "id": "1403022015",
    "district_id": "140302",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1403022016",
    "district_id": "140302",
    "label": "Pampang Baru",
    "value": "Pampang Baru"
  },
  {
    "id": "1403022017",
    "district_id": "140302",
    "label": "Kembung Baru",
    "value": "Kembung Baru"
  },
  {
    "id": "1403022018",
    "district_id": "140302",
    "label": "Pasiran",
    "value": "Pasiran"
  },
  {
    "id": "1403022019",
    "district_id": "140302",
    "label": "Bantan Sari",
    "value": "Bantan Sari"
  },
  {
    "id": "1403022020",
    "district_id": "140302",
    "label": "Bantan Timur",
    "value": "Bantan Timur"
  },
  {
    "id": "1403022021",
    "district_id": "140302",
    "label": "Telukpapal",
    "value": "Telukpapal"
  },
  {
    "id": "1403022022",
    "district_id": "140302",
    "label": "Muntai Barat",
    "value": "Muntai Barat"
  },
  {
    "id": "1403022023",
    "district_id": "140302",
    "label": "Deluk",
    "value": "Deluk"
  },
  {
    "id": "1403031010",
    "district_id": "140303",
    "label": "Sungaipakning",
    "value": "Sungaipakning"
  },
  {
    "id": "1403032001",
    "district_id": "140303",
    "label": "Sejangat",
    "value": "Sejangat"
  },
  {
    "id": "1403032002",
    "district_id": "140303",
    "label": "Dompas",
    "value": "Dompas"
  },
  {
    "id": "1403032003",
    "district_id": "140303",
    "label": "Pangkalan Jambi",
    "value": "Pangkalan Jambi"
  },
  {
    "id": "1403032004",
    "district_id": "140303",
    "label": "Sungaiselari",
    "value": "Sungaiselari"
  },
  {
    "id": "1403032005",
    "district_id": "140303",
    "label": "Buruk Bakul",
    "value": "Buruk Bakul"
  },
  {
    "id": "1403032006",
    "district_id": "140303",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "1403032007",
    "district_id": "140303",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1403032016",
    "district_id": "140303",
    "label": "Batang Duku",
    "value": "Batang Duku"
  },
  {
    "id": "1403032017",
    "district_id": "140303",
    "label": "Pakning Asal",
    "value": "Pakning Asal"
  },
  {
    "id": "1403091001",
    "district_id": "140309",
    "label": "Air Jamban",
    "value": "Air Jamban"
  },
  {
    "id": "1403091002",
    "district_id": "140309",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "1403091003",
    "district_id": "140309",
    "label": "Batang Serosa",
    "value": "Batang Serosa"
  },
  {
    "id": "1403091004",
    "district_id": "140309",
    "label": "Balik Alam",
    "value": "Balik Alam"
  },
  {
    "id": "1403091006",
    "district_id": "140309",
    "label": "Duri Barat",
    "value": "Duri Barat"
  },
  {
    "id": "1403091007",
    "district_id": "140309",
    "label": "Duri Timur",
    "value": "Duri Timur"
  },
  {
    "id": "1403091008",
    "district_id": "140309",
    "label": "Gajah Sakti",
    "value": "Gajah Sakti"
  },
  {
    "id": "1403091009",
    "district_id": "140309",
    "label": "Talang Mandi",
    "value": "Talang Mandi"
  },
  {
    "id": "1403091010",
    "district_id": "140309",
    "label": "Pematang Pudu",
    "value": "Pematang Pudu"
  },
  {
    "id": "1403092011",
    "district_id": "140309",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "1403092021",
    "district_id": "140309",
    "label": "Bathin Betuah",
    "value": "Bathin Betuah"
  },
  {
    "id": "1403101001",
    "district_id": "140310",
    "label": "Batu Panjang",
    "value": "Batu Panjang"
  },
  {
    "id": "1403101002",
    "district_id": "140310",
    "label": "Pergam",
    "value": "Pergam"
  },
  {
    "id": "1403101003",
    "district_id": "140310",
    "label": "Terkul",
    "value": "Terkul"
  },
  {
    "id": "1403101004",
    "district_id": "140310",
    "label": "Tanjungkapal",
    "value": "Tanjungkapal"
  },
  {
    "id": "1403102005",
    "district_id": "140310",
    "label": "Sungaicingam",
    "value": "Sungaicingam"
  },
  {
    "id": "1403102006",
    "district_id": "140310",
    "label": "Teluklecah",
    "value": "Teluklecah"
  },
  {
    "id": "1403102007",
    "district_id": "140310",
    "label": "Makeruh",
    "value": "Makeruh"
  },
  {
    "id": "1403102008",
    "district_id": "140310",
    "label": "Hutan Panjang",
    "value": "Hutan Panjang"
  },
  {
    "id": "1403102009",
    "district_id": "140310",
    "label": "Pangkalan Nyirih",
    "value": "Pangkalan Nyirih"
  },
  {
    "id": "1403102010",
    "district_id": "140310",
    "label": "Sukarjo Mesin",
    "value": "Sukarjo Mesin"
  },
  {
    "id": "1403102011",
    "district_id": "140310",
    "label": "Darul Aman",
    "value": "Darul Aman"
  },
  {
    "id": "1403102012",
    "district_id": "140310",
    "label": "Parit Kebumen",
    "value": "Parit Kebumen"
  },
  {
    "id": "1403102013",
    "district_id": "140310",
    "label": "Sri Tanjung",
    "value": "Sri Tanjung"
  },
  {
    "id": "1403102014",
    "district_id": "140310",
    "label": "Pancur Jaya",
    "value": "Pancur Jaya"
  },
  {
    "id": "1403102015",
    "district_id": "140310",
    "label": "Pangkalan Pinang",
    "value": "Pangkalan Pinang"
  },
  {
    "id": "1403102016",
    "district_id": "140310",
    "label": "Dungun Baru",
    "value": "Dungun Baru"
  },
  {
    "id": "1403112001",
    "district_id": "140311",
    "label": "Tanjungmedang",
    "value": "Tanjungmedang"
  },
  {
    "id": "1403112002",
    "district_id": "140311",
    "label": "Telukrhu",
    "value": "Telukrhu"
  },
  {
    "id": "1403112003",
    "district_id": "140311",
    "label": "Tanjungpunak",
    "value": "Tanjungpunak"
  },
  {
    "id": "1403112004",
    "district_id": "140311",
    "label": "Kadur",
    "value": "Kadur"
  },
  {
    "id": "1403112005",
    "district_id": "140311",
    "label": "Titi Akar",
    "value": "Titi Akar"
  },
  {
    "id": "1403112006",
    "district_id": "140311",
    "label": "Hutan Ayu",
    "value": "Hutan Ayu"
  },
  {
    "id": "1403112007",
    "district_id": "140311",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1403112008",
    "district_id": "140311",
    "label": "Puteri Sembilan",
    "value": "Puteri Sembilan"
  },
  {
    "id": "1403122001",
    "district_id": "140312",
    "label": "Lubuk Muda",
    "value": "Lubuk Muda"
  },
  {
    "id": "1403122002",
    "district_id": "140312",
    "label": "Tanjungbelit",
    "value": "Tanjungbelit"
  },
  {
    "id": "1403122003",
    "district_id": "140312",
    "label": "Sungaisiput",
    "value": "Sungaisiput"
  },
  {
    "id": "1403122004",
    "district_id": "140312",
    "label": "Sepotong",
    "value": "Sepotong"
  },
  {
    "id": "1403122005",
    "district_id": "140312",
    "label": "Lubuk Gaung",
    "value": "Lubuk Gaung"
  },
  {
    "id": "1403122006",
    "district_id": "140312",
    "label": "Langkat",
    "value": "Langkat"
  },
  {
    "id": "1403122007",
    "district_id": "140312",
    "label": "Sadar Jaya",
    "value": "Sadar Jaya"
  },
  {
    "id": "1403122008",
    "district_id": "140312",
    "label": "Sungailinau",
    "value": "Sungailinau"
  },
  {
    "id": "1403122009",
    "district_id": "140312",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1403122010",
    "district_id": "140312",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1403122011",
    "district_id": "140312",
    "label": "Tanjungdamai",
    "value": "Tanjungdamai"
  },
  {
    "id": "1403122012",
    "district_id": "140312",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1403122013",
    "district_id": "140312",
    "label": "Lubuk Garam",
    "value": "Lubuk Garam"
  },
  {
    "id": "1403122014",
    "district_id": "140312",
    "label": "Liang Banir",
    "value": "Liang Banir"
  },
  {
    "id": "1403122015",
    "district_id": "140312",
    "label": "Tanjungdatuk",
    "value": "Tanjungdatuk"
  },
  {
    "id": "1403122016",
    "district_id": "140312",
    "label": "Koto Raja",
    "value": "Koto Raja"
  },
  {
    "id": "1403122017",
    "district_id": "140312",
    "label": "Sungainibung",
    "value": "Sungainibung"
  },
  {
    "id": "1403131001",
    "district_id": "140313",
    "label": "Titian Antui",
    "value": "Titian Antui"
  },
  {
    "id": "1403131012",
    "district_id": "140313",
    "label": "Balai Raja",
    "value": "Balai Raja"
  },
  {
    "id": "1403132003",
    "district_id": "140313",
    "label": "Balai Pungut",
    "value": "Balai Pungut"
  },
  {
    "id": "1403132005",
    "district_id": "140313",
    "label": "Muara Basung",
    "value": "Muara Basung"
  },
  {
    "id": "1403132007",
    "district_id": "140313",
    "label": "Pinggir",
    "value": "Pinggir"
  },
  {
    "id": "1403132008",
    "district_id": "140313",
    "label": "Semunai",
    "value": "Semunai"
  },
  {
    "id": "1403132011",
    "district_id": "140313",
    "label": "Tengganau",
    "value": "Tengganau"
  },
  {
    "id": "1403132014",
    "district_id": "140313",
    "label": "Sungaimeranti",
    "value": "Sungaimeranti"
  },
  {
    "id": "1403132015",
    "district_id": "140313",
    "label": "Pangkalan Libut",
    "value": "Pangkalan Libut"
  },
  {
    "id": "1403132016",
    "district_id": "140313",
    "label": "Buluh Apo",
    "value": "Buluh Apo"
  },
  {
    "id": "1403142001",
    "district_id": "140314",
    "label": "Paritsatuapi-api",
    "value": "Paritsatuapi-api"
  },
  {
    "id": "1403142002",
    "district_id": "140314",
    "label": "Temiang",
    "value": "Temiang"
  },
  {
    "id": "1403142003",
    "district_id": "140314",
    "label": "Api-Api",
    "value": "Api-Api"
  },
  {
    "id": "1403142004",
    "district_id": "140314",
    "label": "Tenggayun",
    "value": "Tenggayun"
  },
  {
    "id": "1403142005",
    "district_id": "140314",
    "label": "Sepahat",
    "value": "Sepahat"
  },
  {
    "id": "1403142006",
    "district_id": "140314",
    "label": "Bukitkerikil",
    "value": "Bukitkerikil"
  },
  {
    "id": "1403142007",
    "district_id": "140314",
    "label": "Tanjungleban",
    "value": "Tanjungleban"
  },
  {
    "id": "1403152001",
    "district_id": "140315",
    "label": "Tasikserai",
    "value": "Tasikserai"
  },
  {
    "id": "1403152002",
    "district_id": "140315",
    "label": "Tasikserai Barat",
    "value": "Tasikserai Barat"
  },
  {
    "id": "1403152003",
    "district_id": "140315",
    "label": "Kualapenaso",
    "value": "Kualapenaso"
  },
  {
    "id": "1403152004",
    "district_id": "140315",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1403152005",
    "district_id": "140315",
    "label": "Kotopaitberingin",
    "value": "Kotopaitberingin"
  },
  {
    "id": "1403152006",
    "district_id": "140315",
    "label": "Melibur",
    "value": "Melibur"
  },
  {
    "id": "1403152007",
    "district_id": "140315",
    "label": "Tasikserai Timur",
    "value": "Tasikserai Timur"
  },
  {
    "id": "1403152008",
    "district_id": "140315",
    "label": "Tasiktebingserai",
    "value": "Tasiktebingserai"
  },
  {
    "id": "1403152009",
    "district_id": "140315",
    "label": "Seraiwangi",
    "value": "Seraiwangi"
  },
  {
    "id": "1403162001",
    "district_id": "140316",
    "label": "Petani",
    "value": "Petani"
  },
  {
    "id": "1403162002",
    "district_id": "140316",
    "label": "Airkulim",
    "value": "Airkulim"
  },
  {
    "id": "1403162003",
    "district_id": "140316",
    "label": "Buluhmanis",
    "value": "Buluhmanis"
  },
  {
    "id": "1403162004",
    "district_id": "140316",
    "label": "Bumbung",
    "value": "Bumbung"
  },
  {
    "id": "1403162005",
    "district_id": "140316",
    "label": "Pamesi",
    "value": "Pamesi"
  },
  {
    "id": "1403162006",
    "district_id": "140316",
    "label": "Kesumboampai",
    "value": "Kesumboampai"
  },
  {
    "id": "1403162007",
    "district_id": "140316",
    "label": "Bathinsobanga",
    "value": "Bathinsobanga"
  },
  {
    "id": "1403162008",
    "district_id": "140316",
    "label": "Sebangar",
    "value": "Sebangar"
  },
  {
    "id": "1403162009",
    "district_id": "140316",
    "label": "Boncahmahang",
    "value": "Boncahmahang"
  },
  {
    "id": "1403162010",
    "district_id": "140316",
    "label": "Balaimakam",
    "value": "Balaimakam"
  },
  {
    "id": "1403162011",
    "district_id": "140316",
    "label": "Tambusaibatangdui",
    "value": "Tambusaibatangdui"
  },
  {
    "id": "1403162012",
    "district_id": "140316",
    "label": "Simpangpadang",
    "value": "Simpangpadang"
  },
  {
    "id": "1403162013",
    "district_id": "140316",
    "label": "Pematangobo",
    "value": "Pematangobo"
  },
  {
    "id": "1404011001",
    "district_id": "140401",
    "label": "Pulaukijang",
    "value": "Pulaukijang"
  },
  {
    "id": "1404011017",
    "district_id": "140401",
    "label": "Metro",
    "value": "Metro"
  },
  {
    "id": "1404011018",
    "district_id": "140401",
    "label": "Madani",
    "value": "Madani"
  },
  {
    "id": "1404012002",
    "district_id": "140401",
    "label": "Sanglar",
    "value": "Sanglar"
  },
  {
    "id": "1404012003",
    "district_id": "140401",
    "label": "Pulaukecil",
    "value": "Pulaukecil"
  },
  {
    "id": "1404012004",
    "district_id": "140401",
    "label": "Sungaiterap",
    "value": "Sungaiterap"
  },
  {
    "id": "1404012009",
    "district_id": "140401",
    "label": "Sungaiundan",
    "value": "Sungaiundan"
  },
  {
    "id": "1404012010",
    "district_id": "140401",
    "label": "Seberang Sanglar",
    "value": "Seberang Sanglar"
  },
  {
    "id": "1404012011",
    "district_id": "140401",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1404012012",
    "district_id": "140401",
    "label": "Seberang Pulau Kijang",
    "value": "Seberang Pulau Kijang"
  },
  {
    "id": "1404012013",
    "district_id": "140401",
    "label": "Sungaiasam",
    "value": "Sungaiasam"
  },
  {
    "id": "1404012014",
    "district_id": "140401",
    "label": "Pulauruku",
    "value": "Pulauruku"
  },
  {
    "id": "1404012019",
    "district_id": "140401",
    "label": "Tanjunglabuh",
    "value": "Tanjunglabuh"
  },
  {
    "id": "1404012020",
    "district_id": "140401",
    "label": "Sungaimahang",
    "value": "Sungaimahang"
  },
  {
    "id": "1404021001",
    "district_id": "140402",
    "label": "Enok",
    "value": "Enok"
  },
  {
    "id": "1404021002",
    "district_id": "140402",
    "label": "Pusaran",
    "value": "Pusaran"
  },
  {
    "id": "1404021003",
    "district_id": "140402",
    "label": "Telukmedan",
    "value": "Telukmedan"
  },
  {
    "id": "1404021013",
    "district_id": "140402",
    "label": "Pantaiseberang Makmur",
    "value": "Pantaiseberang Makmur"
  },
  {
    "id": "1404022004",
    "district_id": "140402",
    "label": "Pengalihan",
    "value": "Pengalihan"
  },
  {
    "id": "1404022005",
    "district_id": "140402",
    "label": "Sungaiambat",
    "value": "Sungaiambat"
  },
  {
    "id": "1404022006",
    "district_id": "140402",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1404022007",
    "district_id": "140402",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1404022008",
    "district_id": "140402",
    "label": "Sungairukam",
    "value": "Sungairukam"
  },
  {
    "id": "1404022009",
    "district_id": "140402",
    "label": "Bagan Jaya",
    "value": "Bagan Jaya"
  },
  {
    "id": "1404022010",
    "district_id": "140402",
    "label": "Suhada",
    "value": "Suhada"
  },
  {
    "id": "1404022011",
    "district_id": "140402",
    "label": "Jaya Bhakti",
    "value": "Jaya Bhakti"
  },
  {
    "id": "1404022012",
    "district_id": "140402",
    "label": "Sungailokan",
    "value": "Sungailokan"
  },
  {
    "id": "1404022014",
    "district_id": "140402",
    "label": "Simpang Tiga Daratan",
    "value": "Simpang Tiga Daratan"
  },
  {
    "id": "1404031007",
    "district_id": "140403",
    "label": "Sapat",
    "value": "Sapat"
  },
  {
    "id": "1404032001",
    "district_id": "140403",
    "label": "Sungaibuluh",
    "value": "Sungaibuluh"
  },
  {
    "id": "1404032002",
    "district_id": "140403",
    "label": "Tanjunglajau",
    "value": "Tanjunglajau"
  },
  {
    "id": "1404032003",
    "district_id": "140403",
    "label": "Sungaibela",
    "value": "Sungaibela"
  },
  {
    "id": "1404032006",
    "district_id": "140403",
    "label": "Telukdalam",
    "value": "Telukdalam"
  },
  {
    "id": "1404032008",
    "district_id": "140403",
    "label": "Sungaipiyai",
    "value": "Sungaipiyai"
  },
  {
    "id": "1404032009",
    "district_id": "140403",
    "label": "Perigi Raja",
    "value": "Perigi Raja"
  },
  {
    "id": "1404032010",
    "district_id": "140403",
    "label": "Tanjungmelayu",
    "value": "Tanjungmelayu"
  },
  {
    "id": "1404041001",
    "district_id": "140404",
    "label": "Tembilahan Kota",
    "value": "Tembilahan Kota"
  },
  {
    "id": "1404041002",
    "district_id": "140404",
    "label": "Tembilahan Hilir",
    "value": "Tembilahan Hilir"
  },
  {
    "id": "1404041003",
    "district_id": "140404",
    "label": "Seberang Tembilahan",
    "value": "Seberang Tembilahan"
  },
  {
    "id": "1404041004",
    "district_id": "140404",
    "label": "Sungaiperak",
    "value": "Sungaiperak"
  },
  {
    "id": "1404041005",
    "district_id": "140404",
    "label": "Sungaiberingin",
    "value": "Sungaiberingin"
  },
  {
    "id": "1404041006",
    "district_id": "140404",
    "label": "Pekan Arba",
    "value": "Pekan Arba"
  },
  {
    "id": "1404041007",
    "district_id": "140404",
    "label": "Seberang Tembilahan Barat",
    "value": "Seberang Tembilahan Barat"
  },
  {
    "id": "1404041008",
    "district_id": "140404",
    "label": "Seberang Tembilahan Selatan",
    "value": "Seberang Tembilahan Selatan"
  },
  {
    "id": "1404051004",
    "district_id": "140405",
    "label": "Sungaisalak",
    "value": "Sungaisalak"
  },
  {
    "id": "1404051005",
    "district_id": "140405",
    "label": "Tempuling",
    "value": "Tempuling"
  },
  {
    "id": "1404051016",
    "district_id": "140405",
    "label": "Tanjungpidada",
    "value": "Tanjungpidada"
  },
  {
    "id": "1404051017",
    "district_id": "140405",
    "label": "Pangkalan Tujuh",
    "value": "Pangkalan Tujuh"
  },
  {
    "id": "1404052002",
    "district_id": "140405",
    "label": "Telukkiambang",
    "value": "Telukkiambang"
  },
  {
    "id": "1404052003",
    "district_id": "140405",
    "label": "Mumpa",
    "value": "Mumpa"
  },
  {
    "id": "1404052007",
    "district_id": "140405",
    "label": "Telukjira",
    "value": "Telukjira"
  },
  {
    "id": "1404052009",
    "district_id": "140405",
    "label": "Karya Tunas Jaya",
    "value": "Karya Tunas Jaya"
  },
  {
    "id": "1404052011",
    "district_id": "140405",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1404061004",
    "district_id": "140406",
    "label": "Sungaiempat",
    "value": "Sungaiempat"
  },
  {
    "id": "1404061005",
    "district_id": "140406",
    "label": "Telukpinang",
    "value": "Telukpinang"
  },
  {
    "id": "1404061009",
    "district_id": "140406",
    "label": "Tanjungharapan",
    "value": "Tanjungharapan"
  },
  {
    "id": "1404062001",
    "district_id": "140406",
    "label": "Kuala Gaung",
    "value": "Kuala Gaung"
  },
  {
    "id": "1404062002",
    "district_id": "140406",
    "label": "Telukpantaian",
    "value": "Telukpantaian"
  },
  {
    "id": "1404062003",
    "district_id": "140406",
    "label": "Teluksungka",
    "value": "Teluksungka"
  },
  {
    "id": "1404062006",
    "district_id": "140406",
    "label": "Sungaiiliran",
    "value": "Sungaiiliran"
  },
  {
    "id": "1404062007",
    "district_id": "140406",
    "label": "Teluktuasan",
    "value": "Teluktuasan"
  },
  {
    "id": "1404062008",
    "district_id": "140406",
    "label": "Rambaian",
    "value": "Rambaian"
  },
  {
    "id": "1404062010",
    "district_id": "140406",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "1404062011",
    "district_id": "140406",
    "label": "Kelumpang",
    "value": "Kelumpang"
  },
  {
    "id": "1404062012",
    "district_id": "140406",
    "label": "Idaman",
    "value": "Idaman"
  },
  {
    "id": "1404071004",
    "district_id": "140407",
    "label": "Khairiah Mandah",
    "value": "Khairiah Mandah"
  },
  {
    "id": "1404072001",
    "district_id": "140407",
    "label": "Pulaucawan",
    "value": "Pulaucawan"
  },
  {
    "id": "1404072002",
    "district_id": "140407",
    "label": "Belaras",
    "value": "Belaras"
  },
  {
    "id": "1404072003",
    "district_id": "140407",
    "label": "Bente",
    "value": "Bente"
  },
  {
    "id": "1404072005",
    "district_id": "140407",
    "label": "Igal",
    "value": "Igal"
  },
  {
    "id": "1404072006",
    "district_id": "140407",
    "label": "Pelanduk",
    "value": "Pelanduk"
  },
  {
    "id": "1404072007",
    "district_id": "140407",
    "label": "Bakau Aceh",
    "value": "Bakau Aceh"
  },
  {
    "id": "1404072008",
    "district_id": "140407",
    "label": "Batang Tumu",
    "value": "Batang Tumu"
  },
  {
    "id": "1404072009",
    "district_id": "140407",
    "label": "Bekawan",
    "value": "Bekawan"
  },
  {
    "id": "1404072010",
    "district_id": "140407",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "1404072011",
    "district_id": "140407",
    "label": "Batang Sari",
    "value": "Batang Sari"
  },
  {
    "id": "1404072012",
    "district_id": "140407",
    "label": "Bolak Raya",
    "value": "Bolak Raya"
  },
  {
    "id": "1404072013",
    "district_id": "140407",
    "label": "Cahaya Baru",
    "value": "Cahaya Baru"
  },
  {
    "id": "1404072014",
    "district_id": "140407",
    "label": "Belaras Barat",
    "value": "Belaras Barat"
  },
  {
    "id": "1404072015",
    "district_id": "140407",
    "label": "Sepakat Jaya",
    "value": "Sepakat Jaya"
  },
  {
    "id": "1404072016",
    "district_id": "140407",
    "label": "Suraya Mandiri",
    "value": "Suraya Mandiri"
  },
  {
    "id": "1404072017",
    "district_id": "140407",
    "label": "Bidari Tanjung Datuk",
    "value": "Bidari Tanjung Datuk"
  },
  {
    "id": "1404081001",
    "district_id": "140408",
    "label": "Tegaraja",
    "value": "Tegaraja"
  },
  {
    "id": "1404081009",
    "district_id": "140408",
    "label": "Amal Bakti",
    "value": "Amal Bakti"
  },
  {
    "id": "1404081010",
    "district_id": "140408",
    "label": "Bandar Sri Gemilang",
    "value": "Bandar Sri Gemilang"
  },
  {
    "id": "1404082002",
    "district_id": "140408",
    "label": "Kuala Selat",
    "value": "Kuala Selat"
  },
  {
    "id": "1404082003",
    "district_id": "140408",
    "label": "Sungaisimbar",
    "value": "Sungaisimbar"
  },
  {
    "id": "1404082004",
    "district_id": "140408",
    "label": "Penjuru",
    "value": "Penjuru"
  },
  {
    "id": "1404082005",
    "district_id": "140408",
    "label": "Sari Mulia",
    "value": "Sari Mulia"
  },
  {
    "id": "1404082006",
    "district_id": "140408",
    "label": "Air Tawar",
    "value": "Air Tawar"
  },
  {
    "id": "1404082007",
    "district_id": "140408",
    "label": "Tanjungraja",
    "value": "Tanjungraja"
  },
  {
    "id": "1404082008",
    "district_id": "140408",
    "label": "Sungaiteritip",
    "value": "Sungaiteritip"
  },
  {
    "id": "1404082011",
    "district_id": "140408",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1404091003",
    "district_id": "140409",
    "label": "Kota Baru Reteh",
    "value": "Kota Baru Reteh"
  },
  {
    "id": "1404092001",
    "district_id": "140409",
    "label": "Pebenaan",
    "value": "Pebenaan"
  },
  {
    "id": "1404092002",
    "district_id": "140409",
    "label": "Seberang Pebenaan",
    "value": "Seberang Pebenaan"
  },
  {
    "id": "1404092004",
    "district_id": "140409",
    "label": "Nusantara Jaya",
    "value": "Nusantara Jaya"
  },
  {
    "id": "1404092005",
    "district_id": "140409",
    "label": "Kota Baru Seberida",
    "value": "Kota Baru Seberida"
  },
  {
    "id": "1404092006",
    "district_id": "140409",
    "label": "Kembang Mekarsari",
    "value": "Kembang Mekarsari"
  },
  {
    "id": "1404092007",
    "district_id": "140409",
    "label": "Pasar Kembang",
    "value": "Pasar Kembang"
  },
  {
    "id": "1404092008",
    "district_id": "140409",
    "label": "Kuala Keritang",
    "value": "Kuala Keritang"
  },
  {
    "id": "1404092009",
    "district_id": "140409",
    "label": "Kuala Lemang",
    "value": "Kuala Lemang"
  },
  {
    "id": "1404092010",
    "district_id": "140409",
    "label": "Telukkelasa",
    "value": "Telukkelasa"
  },
  {
    "id": "1404092011",
    "district_id": "140409",
    "label": "Pengalihan",
    "value": "Pengalihan"
  },
  {
    "id": "1404092012",
    "district_id": "140409",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "1404092013",
    "district_id": "140409",
    "label": "Sencalang",
    "value": "Sencalang"
  },
  {
    "id": "1404092014",
    "district_id": "140409",
    "label": "Petalongan",
    "value": "Petalongan"
  },
  {
    "id": "1404092015",
    "district_id": "140409",
    "label": "Nyiur Permai",
    "value": "Nyiur Permai"
  },
  {
    "id": "1404092016",
    "district_id": "140409",
    "label": "Lintas Utara",
    "value": "Lintas Utara"
  },
  {
    "id": "1404092017",
    "district_id": "140409",
    "label": "Kayu Raja",
    "value": "Kayu Raja"
  },
  {
    "id": "1404101001",
    "district_id": "140410",
    "label": "Kuala Enok",
    "value": "Kuala Enok"
  },
  {
    "id": "1404102002",
    "district_id": "140410",
    "label": "Selatnama",
    "value": "Selatnama"
  },
  {
    "id": "1404102003",
    "district_id": "140410",
    "label": "Sungainyiur",
    "value": "Sungainyiur"
  },
  {
    "id": "1404102004",
    "district_id": "140410",
    "label": "Tanjungbaru",
    "value": "Tanjungbaru"
  },
  {
    "id": "1404102005",
    "district_id": "140410",
    "label": "Tekulai Hilir",
    "value": "Tekulai Hilir"
  },
  {
    "id": "1404102006",
    "district_id": "140410",
    "label": "Tekulai Hulu",
    "value": "Tekulai Hulu"
  },
  {
    "id": "1404102007",
    "district_id": "140410",
    "label": "Tekulai Bugis",
    "value": "Tekulai Bugis"
  },
  {
    "id": "1404102008",
    "district_id": "140410",
    "label": "Tanjungpasir",
    "value": "Tanjungpasir"
  },
  {
    "id": "1404102009",
    "district_id": "140410",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1404102010",
    "district_id": "140410",
    "label": "Sungailaut",
    "value": "Sungailaut"
  },
  {
    "id": "1404111001",
    "district_id": "140411",
    "label": "Sungaipiring",
    "value": "Sungaipiring"
  },
  {
    "id": "1404112002",
    "district_id": "140411",
    "label": "Sungailuar",
    "value": "Sungailuar"
  },
  {
    "id": "1404112003",
    "district_id": "140411",
    "label": "Sungaidusun",
    "value": "Sungaidusun"
  },
  {
    "id": "1404112004",
    "district_id": "140411",
    "label": "Sungaijunjangan",
    "value": "Sungaijunjangan"
  },
  {
    "id": "1404112005",
    "district_id": "140411",
    "label": "Sungairaya",
    "value": "Sungairaya"
  },
  {
    "id": "1404112006",
    "district_id": "140411",
    "label": "Kuala Sebatu",
    "value": "Kuala Sebatu"
  },
  {
    "id": "1404112007",
    "district_id": "140411",
    "label": "Tanjungsiantar",
    "value": "Tanjungsiantar"
  },
  {
    "id": "1404112008",
    "district_id": "140411",
    "label": "Sungairawa",
    "value": "Sungairawa"
  },
  {
    "id": "1404112009",
    "district_id": "140411",
    "label": "Sialang Jaya",
    "value": "Sialang Jaya"
  },
  {
    "id": "1404112010",
    "district_id": "140411",
    "label": "Gemilang Jaya",
    "value": "Gemilang Jaya"
  },
  {
    "id": "1404112011",
    "district_id": "140411",
    "label": "Tasik Raya",
    "value": "Tasik Raya"
  },
  {
    "id": "1404112012",
    "district_id": "140411",
    "label": "Pasir Emas",
    "value": "Pasir Emas"
  },
  {
    "id": "1404112013",
    "district_id": "140411",
    "label": "Simpang Jaya",
    "value": "Simpang Jaya"
  },
  {
    "id": "1404121006",
    "district_id": "140412",
    "label": "Kuala Lahang",
    "value": "Kuala Lahang"
  },
  {
    "id": "1404122001",
    "district_id": "140412",
    "label": "Terusan Kempas",
    "value": "Terusan Kempas"
  },
  {
    "id": "1404122002",
    "district_id": "140412",
    "label": "Lahang Baru",
    "value": "Lahang Baru"
  },
  {
    "id": "1404122003",
    "district_id": "140412",
    "label": "Simpang Gaung",
    "value": "Simpang Gaung"
  },
  {
    "id": "1404122004",
    "district_id": "140412",
    "label": "Belantaraya",
    "value": "Belantaraya"
  },
  {
    "id": "1404122005",
    "district_id": "140412",
    "label": "Sungaibaru",
    "value": "Sungaibaru"
  },
  {
    "id": "1404122007",
    "district_id": "140412",
    "label": "Lahang Tengah",
    "value": "Lahang Tengah"
  },
  {
    "id": "1404122008",
    "district_id": "140412",
    "label": "Lahang Hulu",
    "value": "Lahang Hulu"
  },
  {
    "id": "1404122009",
    "district_id": "140412",
    "label": "Pungkat",
    "value": "Pungkat"
  },
  {
    "id": "1404122010",
    "district_id": "140412",
    "label": "Jerambang",
    "value": "Jerambang"
  },
  {
    "id": "1404122011",
    "district_id": "140412",
    "label": "Telukkabung",
    "value": "Telukkabung"
  },
  {
    "id": "1404122012",
    "district_id": "140412",
    "label": "Gembira",
    "value": "Gembira"
  },
  {
    "id": "1404122013",
    "district_id": "140412",
    "label": "Semambu Kuning",
    "value": "Semambu Kuning"
  },
  {
    "id": "1404122014",
    "district_id": "140412",
    "label": "Telukmerbau",
    "value": "Telukmerbau"
  },
  {
    "id": "1404122015",
    "district_id": "140412",
    "label": "Soren",
    "value": "Soren"
  },
  {
    "id": "1404122016",
    "district_id": "140412",
    "label": "Pintasan",
    "value": "Pintasan"
  },
  {
    "id": "1404131001",
    "district_id": "140413",
    "label": "Tembilahan Hulu",
    "value": "Tembilahan Hulu"
  },
  {
    "id": "1404131005",
    "district_id": "140413",
    "label": "Tembilahan Barat",
    "value": "Tembilahan Barat"
  },
  {
    "id": "1404132002",
    "district_id": "140413",
    "label": "Pekan Kamis",
    "value": "Pekan Kamis"
  },
  {
    "id": "1404132003",
    "district_id": "140413",
    "label": "Pulaupalas",
    "value": "Pulaupalas"
  },
  {
    "id": "1404132004",
    "district_id": "140413",
    "label": "Sialang Panjang",
    "value": "Sialang Panjang"
  },
  {
    "id": "1404132006",
    "district_id": "140413",
    "label": "Sungaiintan",
    "value": "Sungaiintan"
  },
  {
    "id": "1404141001",
    "district_id": "140414",
    "label": "Selensen",
    "value": "Selensen"
  },
  {
    "id": "1404142002",
    "district_id": "140414",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1404142003",
    "district_id": "140414",
    "label": "Keritang",
    "value": "Keritang"
  },
  {
    "id": "1404142004",
    "district_id": "140414",
    "label": "Air Balui",
    "value": "Air Balui"
  },
  {
    "id": "1404142005",
    "district_id": "140414",
    "label": "Tuk Jimun",
    "value": "Tuk Jimun"
  },
  {
    "id": "1404142006",
    "district_id": "140414",
    "label": "Kemuning Tua",
    "value": "Kemuning Tua"
  },
  {
    "id": "1404142007",
    "district_id": "140414",
    "label": "Kemuning Muda",
    "value": "Kemuning Muda"
  },
  {
    "id": "1404142008",
    "district_id": "140414",
    "label": "Limau Manis",
    "value": "Limau Manis"
  },
  {
    "id": "1404142009",
    "district_id": "140414",
    "label": "Lubuk Besar",
    "value": "Lubuk Besar"
  },
  {
    "id": "1404142010",
    "district_id": "140414",
    "label": "Sekara",
    "value": "Sekara"
  },
  {
    "id": "1404142011",
    "district_id": "140414",
    "label": "Talang Jangkang",
    "value": "Talang Jangkang"
  },
  {
    "id": "1404142012",
    "district_id": "140414",
    "label": "Sekayan",
    "value": "Sekayan"
  },
  {
    "id": "1404151002",
    "district_id": "140415",
    "label": "Pelangiran",
    "value": "Pelangiran"
  },
  {
    "id": "1404152001",
    "district_id": "140415",
    "label": "Rotan Semelur",
    "value": "Rotan Semelur"
  },
  {
    "id": "1404152003",
    "district_id": "140415",
    "label": "Simpang Kateman",
    "value": "Simpang Kateman"
  },
  {
    "id": "1404152004",
    "district_id": "140415",
    "label": "Tanjungsimpang",
    "value": "Tanjungsimpang"
  },
  {
    "id": "1404152005",
    "district_id": "140415",
    "label": "Baung Rejo Jaya",
    "value": "Baung Rejo Jaya"
  },
  {
    "id": "1404152006",
    "district_id": "140415",
    "label": "Tagagiri Tama Jaya",
    "value": "Tagagiri Tama Jaya"
  },
  {
    "id": "1404152007",
    "district_id": "140415",
    "label": "Pinang Jaya",
    "value": "Pinang Jaya"
  },
  {
    "id": "1404152008",
    "district_id": "140415",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1404152009",
    "district_id": "140415",
    "label": "Tegal Rejo",
    "value": "Tegal Rejo"
  },
  {
    "id": "1404152010",
    "district_id": "140415",
    "label": "Intan Mulia Jaya",
    "value": "Intan Mulia Jaya"
  },
  {
    "id": "1404152011",
    "district_id": "140415",
    "label": "Saka Palas Jaya",
    "value": "Saka Palas Jaya"
  },
  {
    "id": "1404152012",
    "district_id": "140415",
    "label": "Catur Karya",
    "value": "Catur Karya"
  },
  {
    "id": "1404152013",
    "district_id": "140415",
    "label": "Bagan Jaya",
    "value": "Bagan Jaya"
  },
  {
    "id": "1404152014",
    "district_id": "140415",
    "label": "Telukbunian",
    "value": "Telukbunian"
  },
  {
    "id": "1404152015",
    "district_id": "140415",
    "label": "Terusan Beringin Jaya",
    "value": "Terusan Beringin Jaya"
  },
  {
    "id": "1404152016",
    "district_id": "140415",
    "label": "Hidayah",
    "value": "Hidayah"
  },
  {
    "id": "1404162001",
    "district_id": "140416",
    "label": "Hibrida Mulia",
    "value": "Hibrida Mulia"
  },
  {
    "id": "1404162002",
    "district_id": "140416",
    "label": "Indra Sari Jaya",
    "value": "Indra Sari Jaya"
  },
  {
    "id": "1404162003",
    "district_id": "140416",
    "label": "Tunggal Rahayu Jaya",
    "value": "Tunggal Rahayu Jaya"
  },
  {
    "id": "1404162004",
    "district_id": "140416",
    "label": "Griya Mukti Jaya",
    "value": "Griya Mukti Jaya"
  },
  {
    "id": "1404162005",
    "district_id": "140416",
    "label": "Beringin Mulia",
    "value": "Beringin Mulia"
  },
  {
    "id": "1404162006",
    "district_id": "140416",
    "label": "Hibrida Jaya",
    "value": "Hibrida Jaya"
  },
  {
    "id": "1404162007",
    "district_id": "140416",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1404162008",
    "district_id": "140416",
    "label": "Sumber Makmur Jaya",
    "value": "Sumber Makmur Jaya"
  },
  {
    "id": "1404162009",
    "district_id": "140416",
    "label": "Sumber Sari Jaya",
    "value": "Sumber Sari Jaya"
  },
  {
    "id": "1404162010",
    "district_id": "140416",
    "label": "Kelapa Patih Jaya",
    "value": "Kelapa Patih Jaya"
  },
  {
    "id": "1404162011",
    "district_id": "140416",
    "label": "Sapta Mulia Jaya",
    "value": "Sapta Mulia Jaya"
  },
  {
    "id": "1404162012",
    "district_id": "140416",
    "label": "Saka Rotan",
    "value": "Saka Rotan"
  },
  {
    "id": "1404162013",
    "district_id": "140416",
    "label": "Gembaran",
    "value": "Gembaran"
  },
  {
    "id": "1404172001",
    "district_id": "140417",
    "label": "Pulauburung",
    "value": "Pulauburung"
  },
  {
    "id": "1404172002",
    "district_id": "140417",
    "label": "Teluknibung",
    "value": "Teluknibung"
  },
  {
    "id": "1404172003",
    "district_id": "140417",
    "label": "Sungaidanai",
    "value": "Sungaidanai"
  },
  {
    "id": "1404172004",
    "district_id": "140417",
    "label": "Mayang Sari Jaya",
    "value": "Mayang Sari Jaya"
  },
  {
    "id": "1404172005",
    "district_id": "140417",
    "label": "Bukit Sari Intan Jaya",
    "value": "Bukit Sari Intan Jaya"
  },
  {
    "id": "1404172006",
    "district_id": "140417",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "1404172007",
    "district_id": "140417",
    "label": "Bangun Harjo Jaya",
    "value": "Bangun Harjo Jaya"
  },
  {
    "id": "1404172008",
    "district_id": "140417",
    "label": "Ringin Jaya",
    "value": "Ringin Jaya"
  },
  {
    "id": "1404172009",
    "district_id": "140417",
    "label": "Sri Damai",
    "value": "Sri Damai"
  },
  {
    "id": "1404172010",
    "district_id": "140417",
    "label": "Sapta Jaya",
    "value": "Sapta Jaya"
  },
  {
    "id": "1404172011",
    "district_id": "140417",
    "label": "Keramat Jaya",
    "value": "Keramat Jaya"
  },
  {
    "id": "1404172012",
    "district_id": "140417",
    "label": "Binagun Jaya",
    "value": "Binagun Jaya"
  },
  {
    "id": "1404172013",
    "district_id": "140417",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1404172014",
    "district_id": "140417",
    "label": "Sukaharjo Jaya",
    "value": "Sukaharjo Jaya"
  },
  {
    "id": "1404181001",
    "district_id": "140418",
    "label": "Concong Luar",
    "value": "Concong Luar"
  },
  {
    "id": "1404182002",
    "district_id": "140418",
    "label": "Concong Dalam",
    "value": "Concong Dalam"
  },
  {
    "id": "1404182003",
    "district_id": "140418",
    "label": "Concong Tengah",
    "value": "Concong Tengah"
  },
  {
    "id": "1404182004",
    "district_id": "140418",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1404182005",
    "district_id": "140418",
    "label": "Panglima Raja",
    "value": "Panglima Raja"
  },
  {
    "id": "1404182006",
    "district_id": "140418",
    "label": "Sungaiberapit",
    "value": "Sungaiberapit"
  },
  {
    "id": "1404191002",
    "district_id": "140419",
    "label": "Kempas Jaya",
    "value": "Kempas Jaya"
  },
  {
    "id": "1404191007",
    "district_id": "140419",
    "label": "Harapan Tani",
    "value": "Harapan Tani"
  },
  {
    "id": "1404192001",
    "district_id": "140419",
    "label": "Pekan Tua",
    "value": "Pekan Tua"
  },
  {
    "id": "1404192003",
    "district_id": "140419",
    "label": "Rumbai Jaya",
    "value": "Rumbai Jaya"
  },
  {
    "id": "1404192004",
    "district_id": "140419",
    "label": "Bayas Jaya",
    "value": "Bayas Jaya"
  },
  {
    "id": "1404192005",
    "district_id": "140419",
    "label": "Sungaiara",
    "value": "Sungaiara"
  },
  {
    "id": "1404192006",
    "district_id": "140419",
    "label": "Sungaigantang",
    "value": "Sungaigantang"
  },
  {
    "id": "1404192008",
    "district_id": "140419",
    "label": "Karya Tani",
    "value": "Karya Tani"
  },
  {
    "id": "1404192009",
    "district_id": "140419",
    "label": "Kerta Jaya",
    "value": "Kerta Jaya"
  },
  {
    "id": "1404192010",
    "district_id": "140419",
    "label": "Kulim Jaya",
    "value": "Kulim Jaya"
  },
  {
    "id": "1404192011",
    "district_id": "140419",
    "label": "Danaupulai Indah",
    "value": "Danaupulai Indah"
  },
  {
    "id": "1404192012",
    "district_id": "140419",
    "label": "Sungairabit",
    "value": "Sungairabit"
  },
  {
    "id": "1404201001",
    "district_id": "140420",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1404202002",
    "district_id": "140420",
    "label": "Benteng Utara",
    "value": "Benteng Utara"
  },
  {
    "id": "1404202003",
    "district_id": "140420",
    "label": "Pasenggerahan",
    "value": "Pasenggerahan"
  },
  {
    "id": "1404202004",
    "district_id": "140420",
    "label": "Kuala Sungai Batang",
    "value": "Kuala Sungai Batang"
  },
  {
    "id": "1404202005",
    "district_id": "140420",
    "label": "Kuala Patah Parang",
    "value": "Kuala Patah Parang"
  },
  {
    "id": "1404202006",
    "district_id": "140420",
    "label": "Benteng Barat",
    "value": "Benteng Barat"
  },
  {
    "id": "1404202007",
    "district_id": "140420",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "1404202008",
    "district_id": "140420",
    "label": "Mugo Mulyo",
    "value": "Mugo Mulyo"
  },
  {
    "id": "1405011010",
    "district_id": "140501",
    "label": "Ukui I",
    "value": "Ukui I"
  },
  {
    "id": "1405012001",
    "district_id": "140501",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1405012002",
    "district_id": "140501",
    "label": "Air Emas",
    "value": "Air Emas"
  },
  {
    "id": "1405012003",
    "district_id": "140501",
    "label": "Bukit Gajah",
    "value": "Bukit Gajah"
  },
  {
    "id": "1405012004",
    "district_id": "140501",
    "label": "Bukit Jaya",
    "value": "Bukit Jaya"
  },
  {
    "id": "1405012005",
    "district_id": "140501",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1405012006",
    "district_id": "140501",
    "label": "Lubuk Kembang Bunga",
    "value": "Lubuk Kembang Bunga"
  },
  {
    "id": "1405012007",
    "district_id": "140501",
    "label": "Lubuk Kembang Sari",
    "value": "Lubuk Kembang Sari"
  },
  {
    "id": "1405012008",
    "district_id": "140501",
    "label": "Silikuan Hulu",
    "value": "Silikuan Hulu"
  },
  {
    "id": "1405012009",
    "district_id": "140501",
    "label": "Tri Mulya Jaya",
    "value": "Tri Mulya Jaya"
  },
  {
    "id": "1405012011",
    "district_id": "140501",
    "label": "Ukui Dua",
    "value": "Ukui Dua"
  },
  {
    "id": "1405012012",
    "district_id": "140501",
    "label": "Bagan Limau",
    "value": "Bagan Limau"
  },
  {
    "id": "1405021007",
    "district_id": "140502",
    "label": "Pangkalan Kerinci Barat",
    "value": "Pangkalan Kerinci Barat"
  },
  {
    "id": "1405021008",
    "district_id": "140502",
    "label": "Pangkalan Kerinci Kota",
    "value": "Pangkalan Kerinci Kota"
  },
  {
    "id": "1405021009",
    "district_id": "140502",
    "label": "Pangkalan Kerinci Timur",
    "value": "Pangkalan Kerinci Timur"
  },
  {
    "id": "1405022001",
    "district_id": "140502",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1405022002",
    "district_id": "140502",
    "label": "Kuala Terusan",
    "value": "Kuala Terusan"
  },
  {
    "id": "1405022003",
    "district_id": "140502",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "1405022005",
    "district_id": "140502",
    "label": "Rantau Baru",
    "value": "Rantau Baru"
  },
  {
    "id": "1405031001",
    "district_id": "140503",
    "label": "Sorek I",
    "value": "Sorek I"
  },
  {
    "id": "1405032002",
    "district_id": "140503",
    "label": "Sorek II",
    "value": "Sorek II"
  },
  {
    "id": "1405032003",
    "district_id": "140503",
    "label": "Dundangan",
    "value": "Dundangan"
  },
  {
    "id": "1405032004",
    "district_id": "140503",
    "label": "Palas",
    "value": "Palas"
  },
  {
    "id": "1405032005",
    "district_id": "140503",
    "label": "Terantang Manuk",
    "value": "Terantang Manuk"
  },
  {
    "id": "1405032006",
    "district_id": "140503",
    "label": "Kemang",
    "value": "Kemang"
  },
  {
    "id": "1405032007",
    "district_id": "140503",
    "label": "Talau",
    "value": "Talau"
  },
  {
    "id": "1405032008",
    "district_id": "140503",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1405032009",
    "district_id": "140503",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1405032010",
    "district_id": "140503",
    "label": "Kesuma",
    "value": "Kesuma"
  },
  {
    "id": "1405032011",
    "district_id": "140503",
    "label": "Surya Indah",
    "value": "Surya Indah"
  },
  {
    "id": "1405032012",
    "district_id": "140503",
    "label": "Sialang Indah",
    "value": "Sialang Indah"
  },
  {
    "id": "1405032013",
    "district_id": "140503",
    "label": "Beringin Indah",
    "value": "Beringin Indah"
  },
  {
    "id": "1405032014",
    "district_id": "140503",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "1405032015",
    "district_id": "140503",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1405032016",
    "district_id": "140503",
    "label": "Batang Kulim",
    "value": "Batang Kulim"
  },
  {
    "id": "1405032017",
    "district_id": "140503",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "1405041001",
    "district_id": "140504",
    "label": "Pangkalan Lesung",
    "value": "Pangkalan Lesung"
  },
  {
    "id": "1405042002",
    "district_id": "140504",
    "label": "Dusun Tua",
    "value": "Dusun Tua"
  },
  {
    "id": "1405042003",
    "district_id": "140504",
    "label": "Genduang",
    "value": "Genduang"
  },
  {
    "id": "1405042004",
    "district_id": "140504",
    "label": "Mayang Sari",
    "value": "Mayang Sari"
  },
  {
    "id": "1405042005",
    "district_id": "140504",
    "label": "Rawang Sari",
    "value": "Rawang Sari"
  },
  {
    "id": "1405042006",
    "district_id": "140504",
    "label": "Mulya Subur",
    "value": "Mulya Subur"
  },
  {
    "id": "1405042007",
    "district_id": "140504",
    "label": "Sari Mulya",
    "value": "Sari Mulya"
  },
  {
    "id": "1405042008",
    "district_id": "140504",
    "label": "Sari Makmur",
    "value": "Sari Makmur"
  },
  {
    "id": "1405042009",
    "district_id": "140504",
    "label": "Tanjung Kuyo",
    "value": "Tanjung Kuyo"
  },
  {
    "id": "1405042010",
    "district_id": "140504",
    "label": "Pesaguan",
    "value": "Pesaguan"
  },
  {
    "id": "1405051001",
    "district_id": "140505",
    "label": "Langgam",
    "value": "Langgam"
  },
  {
    "id": "1405052002",
    "district_id": "140505",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "1405052003",
    "district_id": "140505",
    "label": "Penarikan",
    "value": "Penarikan"
  },
  {
    "id": "1405052004",
    "district_id": "140505",
    "label": "Segati",
    "value": "Segati"
  },
  {
    "id": "1405052005",
    "district_id": "140505",
    "label": "Sotol",
    "value": "Sotol"
  },
  {
    "id": "1405052006",
    "district_id": "140505",
    "label": "Pangkalan Gondai",
    "value": "Pangkalan Gondai"
  },
  {
    "id": "1405052007",
    "district_id": "140505",
    "label": "Langkan",
    "value": "Langkan"
  },
  {
    "id": "1405052008",
    "district_id": "140505",
    "label": "Padang Luas",
    "value": "Padang Luas"
  },
  {
    "id": "1405061001",
    "district_id": "140506",
    "label": "Pelalawan",
    "value": "Pelalawan"
  },
  {
    "id": "1405062002",
    "district_id": "140506",
    "label": "Sungai Ara",
    "value": "Sungai Ara"
  },
  {
    "id": "1405062003",
    "district_id": "140506",
    "label": "Ransang",
    "value": "Ransang"
  },
  {
    "id": "1405062004",
    "district_id": "140506",
    "label": "Kuala Tolam",
    "value": "Kuala Tolam"
  },
  {
    "id": "1405062005",
    "district_id": "140506",
    "label": "Sering",
    "value": "Sering"
  },
  {
    "id": "1405062006",
    "district_id": "140506",
    "label": "Telayap",
    "value": "Telayap"
  },
  {
    "id": "1405062007",
    "district_id": "140506",
    "label": "Batang Nilo Kecil",
    "value": "Batang Nilo Kecil"
  },
  {
    "id": "1405062008",
    "district_id": "140506",
    "label": "Delik",
    "value": "Delik"
  },
  {
    "id": "1405062010",
    "district_id": "140506",
    "label": "Lalang Kabung",
    "value": "Lalang Kabung"
  },
  {
    "id": "1405071001",
    "district_id": "140507",
    "label": "Kerumutan",
    "value": "Kerumutan"
  },
  {
    "id": "1405072002",
    "district_id": "140507",
    "label": "Pangkalan Panduk",
    "value": "Pangkalan Panduk"
  },
  {
    "id": "1405072003",
    "district_id": "140507",
    "label": "Pangkalan Tampui",
    "value": "Pangkalan Tampui"
  },
  {
    "id": "1405072004",
    "district_id": "140507",
    "label": "Bukit Lembah Subur",
    "value": "Bukit Lembah Subur"
  },
  {
    "id": "1405072005",
    "district_id": "140507",
    "label": "Banjar Panjang",
    "value": "Banjar Panjang"
  },
  {
    "id": "1405072006",
    "district_id": "140507",
    "label": "Beringin Makmur",
    "value": "Beringin Makmur"
  },
  {
    "id": "1405072007",
    "district_id": "140507",
    "label": "Pematang Tinggi",
    "value": "Pematang Tinggi"
  },
  {
    "id": "1405072008",
    "district_id": "140507",
    "label": "Tanjung Air Hitam",
    "value": "Tanjung Air Hitam"
  },
  {
    "id": "1405072009",
    "district_id": "140507",
    "label": "Mak Teduh",
    "value": "Mak Teduh"
  },
  {
    "id": "1405072010",
    "district_id": "140507",
    "label": "Lipai Bulan",
    "value": "Lipai Bulan"
  },
  {
    "id": "1405081011",
    "district_id": "140508",
    "label": "Pangkalan Bunut",
    "value": "Pangkalan Bunut"
  },
  {
    "id": "1405082001",
    "district_id": "140508",
    "label": "Petani",
    "value": "Petani"
  },
  {
    "id": "1405082002",
    "district_id": "140508",
    "label": "Merbau",
    "value": "Merbau"
  },
  {
    "id": "1405082003",
    "district_id": "140508",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "1405082008",
    "district_id": "140508",
    "label": "Lubuk Mas",
    "value": "Lubuk Mas"
  },
  {
    "id": "1405082009",
    "district_id": "140508",
    "label": "Balam Merah",
    "value": "Balam Merah"
  },
  {
    "id": "1405082010",
    "district_id": "140508",
    "label": "Bagan Laguh",
    "value": "Bagan Laguh"
  },
  {
    "id": "1405082013",
    "district_id": "140508",
    "label": "Lubuk Mandian Gajah",
    "value": "Lubuk Mandian Gajah"
  },
  {
    "id": "1405082017",
    "district_id": "140508",
    "label": "Keriung",
    "value": "Keriung"
  },
  {
    "id": "1405082018",
    "district_id": "140508",
    "label": "Sialang Kayu Batu",
    "value": "Sialang Kayu Batu"
  },
  {
    "id": "1405091001",
    "district_id": "140509",
    "label": "Teluk Meranti",
    "value": "Teluk Meranti"
  },
  {
    "id": "1405092002",
    "district_id": "140509",
    "label": "Kuala Panduk",
    "value": "Kuala Panduk"
  },
  {
    "id": "1405092003",
    "district_id": "140509",
    "label": "Petodaan",
    "value": "Petodaan"
  },
  {
    "id": "1405092004",
    "district_id": "140509",
    "label": "Teluk Binjai",
    "value": "Teluk Binjai"
  },
  {
    "id": "1405092005",
    "district_id": "140509",
    "label": "Pulau Muda",
    "value": "Pulau Muda"
  },
  {
    "id": "1405092006",
    "district_id": "140509",
    "label": "Gambut Mutiara",
    "value": "Gambut Mutiara"
  },
  {
    "id": "1405092007",
    "district_id": "140509",
    "label": "Labuhan Bilik",
    "value": "Labuhan Bilik"
  },
  {
    "id": "1405092008",
    "district_id": "140509",
    "label": "Pangkalan Terap",
    "value": "Pangkalan Terap"
  },
  {
    "id": "1405092009",
    "district_id": "140509",
    "label": "Segamai",
    "value": "Segamai"
  },
  {
    "id": "1405101001",
    "district_id": "140510",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "1405102002",
    "district_id": "140510",
    "label": "Tanjung Sum",
    "value": "Tanjung Sum"
  },
  {
    "id": "1405102003",
    "district_id": "140510",
    "label": "Sungai Solok",
    "value": "Sungai Solok"
  },
  {
    "id": "1405102004",
    "district_id": "140510",
    "label": "Sungai Upih",
    "value": "Sungai Upih"
  },
  {
    "id": "1405102005",
    "district_id": "140510",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "1405102006",
    "district_id": "140510",
    "label": "Serapung",
    "value": "Serapung"
  },
  {
    "id": "1405102007",
    "district_id": "140510",
    "label": "Teluk Beringin",
    "value": "Teluk Beringin"
  },
  {
    "id": "1405102008",
    "district_id": "140510",
    "label": "Sokoi",
    "value": "Sokoi"
  },
  {
    "id": "1405102009",
    "district_id": "140510",
    "label": "Sungai Mas",
    "value": "Sungai Mas"
  },
  {
    "id": "1405102010",
    "district_id": "140510",
    "label": "Teluk Bakau",
    "value": "Teluk Bakau"
  },
  {
    "id": "1405111001",
    "district_id": "140511",
    "label": "Sei Kijang",
    "value": "Sei Kijang"
  },
  {
    "id": "1405112002",
    "district_id": "140511",
    "label": "Lubuk Ogung",
    "value": "Lubuk Ogung"
  },
  {
    "id": "1405112003",
    "district_id": "140511",
    "label": "Simpang Beringin",
    "value": "Simpang Beringin"
  },
  {
    "id": "1405112004",
    "district_id": "140511",
    "label": "Muda Setia",
    "value": "Muda Setia"
  },
  {
    "id": "1405112005",
    "district_id": "140511",
    "label": "Kiab Jaya",
    "value": "Kiab Jaya"
  },
  {
    "id": "1405121002",
    "district_id": "140512",
    "label": "Rawang Empat",
    "value": "Rawang Empat"
  },
  {
    "id": "1405122001",
    "district_id": "140512",
    "label": "Kuala Semundam",
    "value": "Kuala Semundam"
  },
  {
    "id": "1405122003",
    "district_id": "140512",
    "label": "Tambun",
    "value": "Tambun"
  },
  {
    "id": "1405122004",
    "district_id": "140512",
    "label": "Terbangiang",
    "value": "Terbangiang"
  },
  {
    "id": "1405122005",
    "district_id": "140512",
    "label": "Lubuk Terap",
    "value": "Lubuk Terap"
  },
  {
    "id": "1405122006",
    "district_id": "140512",
    "label": "Angkasa",
    "value": "Angkasa"
  },
  {
    "id": "1405122007",
    "district_id": "140512",
    "label": "Sialang Godang",
    "value": "Sialang Godang"
  },
  {
    "id": "1405122008",
    "district_id": "140512",
    "label": "Lubuk Raja",
    "value": "Lubuk Raja"
  },
  {
    "id": "1405122009",
    "district_id": "140512",
    "label": "Lubuk Keranji Timur",
    "value": "Lubuk Keranji Timur"
  },
  {
    "id": "1405122010",
    "district_id": "140512",
    "label": "Airterjun",
    "value": "Airterjun"
  },
  {
    "id": "1405122011",
    "district_id": "140512",
    "label": "Sialang Bungkuk",
    "value": "Sialang Bungkuk"
  },
  {
    "id": "1406011001",
    "district_id": "140601",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "1406012002",
    "district_id": "140601",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1406012003",
    "district_id": "140601",
    "label": "Ngaso",
    "value": "Ngaso"
  },
  {
    "id": "1406012004",
    "district_id": "140601",
    "label": "Ujung Batu Timur",
    "value": "Ujung Batu Timur"
  },
  {
    "id": "1406012005",
    "district_id": "140601",
    "label": "Pematang Tebih",
    "value": "Pematang Tebih"
  },
  {
    "id": "1406021001",
    "district_id": "140602",
    "label": "Rokan",
    "value": "Rokan"
  },
  {
    "id": "1406022002",
    "district_id": "140602",
    "label": "Cipang Kanan",
    "value": "Cipang Kanan"
  },
  {
    "id": "1406022003",
    "district_id": "140602",
    "label": "Cipang Kiri Hulu",
    "value": "Cipang Kiri Hulu"
  },
  {
    "id": "1406022004",
    "district_id": "140602",
    "label": "Cipang Kiri Hilir",
    "value": "Cipang Kiri Hilir"
  },
  {
    "id": "1406022006",
    "district_id": "140602",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1406022007",
    "district_id": "140602",
    "label": "Lubuk Bendahara Timur",
    "value": "Lubuk Bendahara Timur"
  },
  {
    "id": "1406022008",
    "district_id": "140602",
    "label": "Lubuk Bendahara",
    "value": "Lubuk Bendahara"
  },
  {
    "id": "1406022010",
    "district_id": "140602",
    "label": "Sikebau Jaya",
    "value": "Sikebau Jaya"
  },
  {
    "id": "1406022013",
    "district_id": "140602",
    "label": "Rokan Koto Ruang",
    "value": "Rokan Koto Ruang"
  },
  {
    "id": "1406022014",
    "district_id": "140602",
    "label": "Rokan Timur",
    "value": "Rokan Timur"
  },
  {
    "id": "1406022016",
    "district_id": "140602",
    "label": "Lubuk Betung",
    "value": "Lubuk Betung"
  },
  {
    "id": "1406022017",
    "district_id": "140602",
    "label": "Pemandang",
    "value": "Pemandang"
  },
  {
    "id": "1406022018",
    "district_id": "140602",
    "label": "Alahan",
    "value": "Alahan"
  },
  {
    "id": "1406022019",
    "district_id": "140602",
    "label": "Tibawan",
    "value": "Tibawan"
  },
  {
    "id": "1406031001",
    "district_id": "140603",
    "label": "Pasir Pengarayan",
    "value": "Pasir Pengarayan"
  },
  {
    "id": "1406032002",
    "district_id": "140603",
    "label": "Rambah Tengah Utara",
    "value": "Rambah Tengah Utara"
  },
  {
    "id": "1406032003",
    "district_id": "140603",
    "label": "Rambah Tengah Hilir",
    "value": "Rambah Tengah Hilir"
  },
  {
    "id": "1406032004",
    "district_id": "140603",
    "label": "Rambah Tengah Hulu",
    "value": "Rambah Tengah Hulu"
  },
  {
    "id": "1406032005",
    "district_id": "140603",
    "label": "Rambah Tengah Barat",
    "value": "Rambah Tengah Barat"
  },
  {
    "id": "1406032006",
    "district_id": "140603",
    "label": "Menaming",
    "value": "Menaming"
  },
  {
    "id": "1406032007",
    "district_id": "140603",
    "label": "Pasir Baru",
    "value": "Pasir Baru"
  },
  {
    "id": "1406032008",
    "district_id": "140603",
    "label": "Sialang Jaya",
    "value": "Sialang Jaya"
  },
  {
    "id": "1406032009",
    "district_id": "140603",
    "label": "Tanjung Belit",
    "value": "Tanjung Belit"
  },
  {
    "id": "1406032010",
    "district_id": "140603",
    "label": "Koto Tinggi",
    "value": "Koto Tinggi"
  },
  {
    "id": "1406032011",
    "district_id": "140603",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1406032012",
    "district_id": "140603",
    "label": "Pematang Berangan",
    "value": "Pematang Berangan"
  },
  {
    "id": "1406032013",
    "district_id": "140603",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "1406032014",
    "district_id": "140603",
    "label": "Pasir Maju",
    "value": "Pasir Maju"
  },
  {
    "id": "1406041001",
    "district_id": "140604",
    "label": "Tambusai Tengah",
    "value": "Tambusai Tengah"
  },
  {
    "id": "1406042002",
    "district_id": "140604",
    "label": "Tambusai Barat",
    "value": "Tambusai Barat"
  },
  {
    "id": "1406042003",
    "district_id": "140604",
    "label": "Tambusai Timur",
    "value": "Tambusai Timur"
  },
  {
    "id": "1406042004",
    "district_id": "140604",
    "label": "Batas",
    "value": "Batas"
  },
  {
    "id": "1406042005",
    "district_id": "140604",
    "label": "Talikumain",
    "value": "Talikumain"
  },
  {
    "id": "1406042006",
    "district_id": "140604",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1406042007",
    "district_id": "140604",
    "label": "Sungai Kumango",
    "value": "Sungai Kumango"
  },
  {
    "id": "1406042008",
    "district_id": "140604",
    "label": "Batang Kumu",
    "value": "Batang Kumu"
  },
  {
    "id": "1406042009",
    "district_id": "140604",
    "label": "Sialang Rindang",
    "value": "Sialang Rindang"
  },
  {
    "id": "1406042010",
    "district_id": "140604",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1406042011",
    "district_id": "140604",
    "label": "Lubuk Soting",
    "value": "Lubuk Soting"
  },
  {
    "id": "1406042012",
    "district_id": "140604",
    "label": "Tingkok",
    "value": "Tingkok"
  },
  {
    "id": "1406051001",
    "district_id": "140605",
    "label": "Kepenuhan Tengah",
    "value": "Kepenuhan Tengah"
  },
  {
    "id": "1406052003",
    "district_id": "140605",
    "label": "Kepenuhan Barat",
    "value": "Kepenuhan Barat"
  },
  {
    "id": "1406052004",
    "district_id": "140605",
    "label": "Kepenuhan Hilir",
    "value": "Kepenuhan Hilir"
  },
  {
    "id": "1406052005",
    "district_id": "140605",
    "label": "Kepenuhan Timur",
    "value": "Kepenuhan Timur"
  },
  {
    "id": "1406052006",
    "district_id": "140605",
    "label": "Kepenuhan Barat Sei Rokan Jaya",
    "value": "Kepenuhan Barat Sei Rokan Jaya"
  },
  {
    "id": "1406052007",
    "district_id": "140605",
    "label": "Kepenuhan Raya",
    "value": "Kepenuhan Raya"
  },
  {
    "id": "1406052008",
    "district_id": "140605",
    "label": "Kepenuhan Baru",
    "value": "Kepenuhan Baru"
  },
  {
    "id": "1406052009",
    "district_id": "140605",
    "label": "Kepenuhan Barat Mulya",
    "value": "Kepenuhan Barat Mulya"
  },
  {
    "id": "1406052010",
    "district_id": "140605",
    "label": "Ulak Patian",
    "value": "Ulak Patian"
  },
  {
    "id": "1406052012",
    "district_id": "140605",
    "label": "Rantau Binuang Sakti",
    "value": "Rantau Binuang Sakti"
  },
  {
    "id": "1406061001",
    "district_id": "140606",
    "label": "Kota Lama",
    "value": "Kota Lama"
  },
  {
    "id": "1406062003",
    "district_id": "140606",
    "label": "Kota Intan",
    "value": "Kota Intan"
  },
  {
    "id": "1406062004",
    "district_id": "140606",
    "label": "Muara Dilam",
    "value": "Muara Dilam"
  },
  {
    "id": "1406062008",
    "district_id": "140606",
    "label": "Kota Raya",
    "value": "Kota Raya"
  },
  {
    "id": "1406062009",
    "district_id": "140606",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1406062010",
    "district_id": "140606",
    "label": "Sungai Kuti",
    "value": "Sungai Kuti"
  },
  {
    "id": "1406062011",
    "district_id": "140606",
    "label": "Pasir Indah",
    "value": "Pasir Indah"
  },
  {
    "id": "1406062012",
    "district_id": "140606",
    "label": "Pasir Luhur",
    "value": "Pasir Luhur"
  },
  {
    "id": "1406062017",
    "district_id": "140606",
    "label": "Bukit Intan Makmur",
    "value": "Bukit Intan Makmur"
  },
  {
    "id": "1406062018",
    "district_id": "140606",
    "label": "Bagan Tujuh",
    "value": "Bagan Tujuh"
  },
  {
    "id": "1406072001",
    "district_id": "140607",
    "label": "Rambah Samo",
    "value": "Rambah Samo"
  },
  {
    "id": "1406072002",
    "district_id": "140607",
    "label": "Rambah Samo Barat",
    "value": "Rambah Samo Barat"
  },
  {
    "id": "1406072003",
    "district_id": "140607",
    "label": "Rambah Baru",
    "value": "Rambah Baru"
  },
  {
    "id": "1406072004",
    "district_id": "140607",
    "label": "Rambah Utama",
    "value": "Rambah Utama"
  },
  {
    "id": "1406072005",
    "district_id": "140607",
    "label": "Pasir Makmur",
    "value": "Pasir Makmur"
  },
  {
    "id": "1406072006",
    "district_id": "140607",
    "label": "Karya Mulya",
    "value": "Karya Mulya"
  },
  {
    "id": "1406072007",
    "district_id": "140607",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1406072008",
    "district_id": "140607",
    "label": "Langkitin",
    "value": "Langkitin"
  },
  {
    "id": "1406072009",
    "district_id": "140607",
    "label": "Masda Makmur",
    "value": "Masda Makmur"
  },
  {
    "id": "1406072010",
    "district_id": "140607",
    "label": "Lubuk Napal",
    "value": "Lubuk Napal"
  },
  {
    "id": "1406072011",
    "district_id": "140607",
    "label": "Teluk Aur",
    "value": "Teluk Aur"
  },
  {
    "id": "1406072012",
    "district_id": "140607",
    "label": "Sei Salak",
    "value": "Sei Salak"
  },
  {
    "id": "1406072013",
    "district_id": "140607",
    "label": "Sei Kuning",
    "value": "Sei Kuning"
  },
  {
    "id": "1406072014",
    "district_id": "140607",
    "label": "Lubuk Bilang",
    "value": "Lubuk Bilang"
  },
  {
    "id": "1406082001",
    "district_id": "140608",
    "label": "Rambah Hilir",
    "value": "Rambah Hilir"
  },
  {
    "id": "1406082002",
    "district_id": "140608",
    "label": "Rambah Hilir Tengah",
    "value": "Rambah Hilir Tengah"
  },
  {
    "id": "1406082003",
    "district_id": "140608",
    "label": "Rambah Hilir Timur",
    "value": "Rambah Hilir Timur"
  },
  {
    "id": "1406082004",
    "district_id": "140608",
    "label": "Pasir Utama",
    "value": "Pasir Utama"
  },
  {
    "id": "1406082005",
    "district_id": "140608",
    "label": "Pasir Jaya",
    "value": "Pasir Jaya"
  },
  {
    "id": "1406082006",
    "district_id": "140608",
    "label": "Rambah Muda",
    "value": "Rambah Muda"
  },
  {
    "id": "1406082007",
    "district_id": "140608",
    "label": "Sungai Sitolang",
    "value": "Sungai Sitolang"
  },
  {
    "id": "1406082008",
    "district_id": "140608",
    "label": "Lubuk Kerapat",
    "value": "Lubuk Kerapat"
  },
  {
    "id": "1406082009",
    "district_id": "140608",
    "label": "Rambah",
    "value": "Rambah"
  },
  {
    "id": "1406082010",
    "district_id": "140608",
    "label": "Serombou Indah",
    "value": "Serombou Indah"
  },
  {
    "id": "1406082011",
    "district_id": "140608",
    "label": "Sungai Dua Indah",
    "value": "Sungai Dua Indah"
  },
  {
    "id": "1406082012",
    "district_id": "140608",
    "label": "Muara Musu",
    "value": "Muara Musu"
  },
  {
    "id": "1406082013",
    "district_id": "140608",
    "label": "Sejati",
    "value": "Sejati"
  },
  {
    "id": "1406092001",
    "district_id": "140609",
    "label": "Tambusai Utara",
    "value": "Tambusai Utara"
  },
  {
    "id": "1406092002",
    "district_id": "140609",
    "label": "Mahato",
    "value": "Mahato"
  },
  {
    "id": "1406092003",
    "district_id": "140609",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1406092004",
    "district_id": "140609",
    "label": "Simpang Harapan",
    "value": "Simpang Harapan"
  },
  {
    "id": "1406092005",
    "district_id": "140609",
    "label": "Pagar Mayang",
    "value": "Pagar Mayang"
  },
  {
    "id": "1406092006",
    "district_id": "140609",
    "label": "Payung Sekaki",
    "value": "Payung Sekaki"
  },
  {
    "id": "1406092007",
    "district_id": "140609",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1406092008",
    "district_id": "140609",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1406092009",
    "district_id": "140609",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1406092010",
    "district_id": "140609",
    "label": "Rantau Sakti",
    "value": "Rantau Sakti"
  },
  {
    "id": "1406092011",
    "district_id": "140609",
    "label": "Mahato Sakti",
    "value": "Mahato Sakti"
  },
  {
    "id": "1406102001",
    "district_id": "140610",
    "label": "Pasir Agung",
    "value": "Pasir Agung"
  },
  {
    "id": "1406102002",
    "district_id": "140610",
    "label": "Pasir Intan",
    "value": "Pasir Intan"
  },
  {
    "id": "1406102003",
    "district_id": "140610",
    "label": "Rambah Jaya",
    "value": "Rambah Jaya"
  },
  {
    "id": "1406102004",
    "district_id": "140610",
    "label": "Bangun Purba",
    "value": "Bangun Purba"
  },
  {
    "id": "1406102005",
    "district_id": "140610",
    "label": "Bangun Purba Timur Jaya",
    "value": "Bangun Purba Timur Jaya"
  },
  {
    "id": "1406102006",
    "district_id": "140610",
    "label": "Bangun Purba Barat",
    "value": "Bangun Purba Barat"
  },
  {
    "id": "1406102007",
    "district_id": "140610",
    "label": "Tangun",
    "value": "Tangun"
  },
  {
    "id": "1406112001",
    "district_id": "140611",
    "label": "Tandun",
    "value": "Tandun"
  },
  {
    "id": "1406112002",
    "district_id": "140611",
    "label": "Kumain",
    "value": "Kumain"
  },
  {
    "id": "1406112003",
    "district_id": "140611",
    "label": "Bono Tapung",
    "value": "Bono Tapung"
  },
  {
    "id": "1406112004",
    "district_id": "140611",
    "label": "Dayo",
    "value": "Dayo"
  },
  {
    "id": "1406112005",
    "district_id": "140611",
    "label": "Tapung Jaya",
    "value": "Tapung Jaya"
  },
  {
    "id": "1406112006",
    "district_id": "140611",
    "label": "Puo Raya",
    "value": "Puo Raya"
  },
  {
    "id": "1406112007",
    "district_id": "140611",
    "label": "Sei Kuning",
    "value": "Sei Kuning"
  },
  {
    "id": "1406112008",
    "district_id": "140611",
    "label": "Koto Tandun",
    "value": "Koto Tandun"
  },
  {
    "id": "1406112009",
    "district_id": "140611",
    "label": "Tandun Barat",
    "value": "Tandun Barat"
  },
  {
    "id": "1406122001",
    "district_id": "140612",
    "label": "Kabun",
    "value": "Kabun"
  },
  {
    "id": "1406122002",
    "district_id": "140612",
    "label": "Aliantan",
    "value": "Aliantan"
  },
  {
    "id": "1406122003",
    "district_id": "140612",
    "label": "Koto Ranah",
    "value": "Koto Ranah"
  },
  {
    "id": "1406122004",
    "district_id": "140612",
    "label": "Boncah Kesuma",
    "value": "Boncah Kesuma"
  },
  {
    "id": "1406122005",
    "district_id": "140612",
    "label": "Batu Langkah Besar",
    "value": "Batu Langkah Besar"
  },
  {
    "id": "1406122006",
    "district_id": "140612",
    "label": "Giti",
    "value": "Giti"
  },
  {
    "id": "1406132001",
    "district_id": "140613",
    "label": "Teluk Sono",
    "value": "Teluk Sono"
  },
  {
    "id": "1406132002",
    "district_id": "140613",
    "label": "Sontang",
    "value": "Sontang"
  },
  {
    "id": "1406132003",
    "district_id": "140613",
    "label": "Bonai",
    "value": "Bonai"
  },
  {
    "id": "1406132004",
    "district_id": "140613",
    "label": "Rawa Makmur",
    "value": "Rawa Makmur"
  },
  {
    "id": "1406132005",
    "district_id": "140613",
    "label": "Pauh",
    "value": "Pauh"
  },
  {
    "id": "1406132006",
    "district_id": "140613",
    "label": "Kasang Padang",
    "value": "Kasang Padang"
  },
  {
    "id": "1406132007",
    "district_id": "140613",
    "label": "Kasang Mungkal",
    "value": "Kasang Mungkal"
  },
  {
    "id": "1406142001",
    "district_id": "140614",
    "label": "Pagaran Tapah",
    "value": "Pagaran Tapah"
  },
  {
    "id": "1406142002",
    "district_id": "140614",
    "label": "Kembang Damai",
    "value": "Kembang Damai"
  },
  {
    "id": "1406142005",
    "district_id": "140614",
    "label": "Sangkir Indah",
    "value": "Sangkir Indah"
  },
  {
    "id": "1406152001",
    "district_id": "140615",
    "label": "Kepenuhan Hulu",
    "value": "Kepenuhan Hulu"
  },
  {
    "id": "1406152002",
    "district_id": "140615",
    "label": "Pekan Tebih",
    "value": "Pekan Tebih"
  },
  {
    "id": "1406152003",
    "district_id": "140615",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "1406152004",
    "district_id": "140615",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "1406152005",
    "district_id": "140615",
    "label": "Kepenuhan Jaya",
    "value": "Kepenuhan Jaya"
  },
  {
    "id": "1406162001",
    "district_id": "140616",
    "label": "Pendalian",
    "value": "Pendalian"
  },
  {
    "id": "1406162002",
    "district_id": "140616",
    "label": "Bengkolan Salak",
    "value": "Bengkolan Salak"
  },
  {
    "id": "1406162003",
    "district_id": "140616",
    "label": "Suligi",
    "value": "Suligi"
  },
  {
    "id": "1406162004",
    "district_id": "140616",
    "label": "Air Panas",
    "value": "Air Panas"
  },
  {
    "id": "1406162005",
    "district_id": "140616",
    "label": "Sei Kandis",
    "value": "Sei Kandis"
  },
  {
    "id": "1407011010",
    "district_id": "140701",
    "label": "Telukmerbau",
    "value": "Telukmerbau"
  },
  {
    "id": "1407012002",
    "district_id": "140701",
    "label": "Tanjungleban",
    "value": "Tanjungleban"
  },
  {
    "id": "1407012003",
    "district_id": "140701",
    "label": "Sungaikubu",
    "value": "Sungaikubu"
  },
  {
    "id": "1407012004",
    "district_id": "140701",
    "label": "Rantau Panjang Kanan",
    "value": "Rantau Panjang Kanan"
  },
  {
    "id": "1407012012",
    "district_id": "140701",
    "label": "Telukpiyai",
    "value": "Telukpiyai"
  },
  {
    "id": "1407012013",
    "district_id": "140701",
    "label": "Sungaisegajah",
    "value": "Sungaisegajah"
  },
  {
    "id": "1407012015",
    "district_id": "140701",
    "label": "Sei Segajah Makmur",
    "value": "Sei Segajah Makmur"
  },
  {
    "id": "1407012020",
    "district_id": "140701",
    "label": "Telukpiyai Pesisir",
    "value": "Telukpiyai Pesisir"
  },
  {
    "id": "1407012021",
    "district_id": "140701",
    "label": "Sungaikubu Hulu",
    "value": "Sungaikubu Hulu"
  },
  {
    "id": "1407012022",
    "district_id": "140701",
    "label": "Sungai Segajah Jaya",
    "value": "Sungai Segajah Jaya"
  },
  {
    "id": "1407021011",
    "district_id": "140702",
    "label": "Bagan Punak",
    "value": "Bagan Punak"
  },
  {
    "id": "1407021012",
    "district_id": "140702",
    "label": "Bagan Kota",
    "value": "Bagan Kota"
  },
  {
    "id": "1407021015",
    "district_id": "140702",
    "label": "Bagan Hulu",
    "value": "Bagan Hulu"
  },
  {
    "id": "1407021016",
    "district_id": "140702",
    "label": "Bagan Barat",
    "value": "Bagan Barat"
  },
  {
    "id": "1407021017",
    "district_id": "140702",
    "label": "Bagan Timur",
    "value": "Bagan Timur"
  },
  {
    "id": "1407022009",
    "district_id": "140702",
    "label": "Labuhan Tangga Kecil",
    "value": "Labuhan Tangga Kecil"
  },
  {
    "id": "1407022010",
    "district_id": "140702",
    "label": "Labuhan Tangga Besar",
    "value": "Labuhan Tangga Besar"
  },
  {
    "id": "1407022013",
    "district_id": "140702",
    "label": "Bagan Jawa",
    "value": "Bagan Jawa"
  },
  {
    "id": "1407022014",
    "district_id": "140702",
    "label": "Parit Aman",
    "value": "Parit Aman"
  },
  {
    "id": "1407022019",
    "district_id": "140702",
    "label": "Labuhan Tangga Baru",
    "value": "Labuhan Tangga Baru"
  },
  {
    "id": "1407022021",
    "district_id": "140702",
    "label": "Bagan Jawa Pesisir",
    "value": "Bagan Jawa Pesisir"
  },
  {
    "id": "1407022022",
    "district_id": "140702",
    "label": "Serusa",
    "value": "Serusa"
  },
  {
    "id": "1407022023",
    "district_id": "140702",
    "label": "Labuhan Tangga Hilir",
    "value": "Labuhan Tangga Hilir"
  },
  {
    "id": "1407022024",
    "district_id": "140702",
    "label": "Bagan Punak Meranti",
    "value": "Bagan Punak Meranti"
  },
  {
    "id": "1407022025",
    "district_id": "140702",
    "label": "Bagan Punak Pesisir",
    "value": "Bagan Punak Pesisir"
  },
  {
    "id": "1407031007",
    "district_id": "140703",
    "label": "Sedinginan",
    "value": "Sedinginan"
  },
  {
    "id": "1407031010",
    "district_id": "140703",
    "label": "Banjar XII",
    "value": "Banjar XII"
  },
  {
    "id": "1407031019",
    "district_id": "140703",
    "label": "Cempedak Rahuk",
    "value": "Cempedak Rahuk"
  },
  {
    "id": "1407032001",
    "district_id": "140703",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1407032002",
    "district_id": "140703",
    "label": "Rantau Bais",
    "value": "Rantau Bais"
  },
  {
    "id": "1407032005",
    "district_id": "140703",
    "label": "Sekeladi",
    "value": "Sekeladi"
  },
  {
    "id": "1407032006",
    "district_id": "140703",
    "label": "Teluk Mega",
    "value": "Teluk Mega"
  },
  {
    "id": "1407032008",
    "district_id": "140703",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "1407032009",
    "district_id": "140703",
    "label": "Sintong",
    "value": "Sintong"
  },
  {
    "id": "1407032011",
    "district_id": "140703",
    "label": "Mumugo",
    "value": "Mumugo"
  },
  {
    "id": "1407032012",
    "district_id": "140703",
    "label": "Teluk Berembun",
    "value": "Teluk Berembun"
  },
  {
    "id": "1407032013",
    "district_id": "140703",
    "label": "Menggala Sakti",
    "value": "Menggala Sakti"
  },
  {
    "id": "1407032014",
    "district_id": "140703",
    "label": "Sekeladi Hilir",
    "value": "Sekeladi Hilir"
  },
  {
    "id": "1407032015",
    "district_id": "140703",
    "label": "Menggala Sempurna",
    "value": "Menggala Sempurna"
  },
  {
    "id": "1407032016",
    "district_id": "140703",
    "label": "Sintong Pusaka",
    "value": "Sintong Pusaka"
  },
  {
    "id": "1407032017",
    "district_id": "140703",
    "label": "Sintong Bakti",
    "value": "Sintong Bakti"
  },
  {
    "id": "1407032018",
    "district_id": "140703",
    "label": "Sintong Makmur",
    "value": "Sintong Makmur"
  },
  {
    "id": "1407041001",
    "district_id": "140704",
    "label": "Rimba Melintang",
    "value": "Rimba Melintang"
  },
  {
    "id": "1407042002",
    "district_id": "140704",
    "label": "Jumrah",
    "value": "Jumrah"
  },
  {
    "id": "1407042003",
    "district_id": "140704",
    "label": "Telukpulau Hilir",
    "value": "Telukpulau Hilir"
  },
  {
    "id": "1407042004",
    "district_id": "140704",
    "label": "Telukpulau Hulu",
    "value": "Telukpulau Hulu"
  },
  {
    "id": "1407042005",
    "district_id": "140704",
    "label": "Langgadai Hulu",
    "value": "Langgadai Hulu"
  },
  {
    "id": "1407042006",
    "district_id": "140704",
    "label": "Lenggadai Hilir",
    "value": "Lenggadai Hilir"
  },
  {
    "id": "1407042007",
    "district_id": "140704",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "1407042008",
    "district_id": "140704",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1407042009",
    "district_id": "140704",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1407042010",
    "district_id": "140704",
    "label": "Seremban Jaya",
    "value": "Seremban Jaya"
  },
  {
    "id": "1407042011",
    "district_id": "140704",
    "label": "Pematang Botam",
    "value": "Pematang Botam"
  },
  {
    "id": "1407042012",
    "district_id": "140704",
    "label": "Pematang Sikek",
    "value": "Pematang Sikek"
  },
  {
    "id": "1407051018",
    "district_id": "140705",
    "label": "Bagan Batu Kota",
    "value": "Bagan Batu Kota"
  },
  {
    "id": "1407051019",
    "district_id": "140705",
    "label": "Bahtera Makmur Kota",
    "value": "Bahtera Makmur Kota"
  },
  {
    "id": "1407052002",
    "district_id": "140705",
    "label": "Bagan Batu",
    "value": "Bagan Batu"
  },
  {
    "id": "1407052003",
    "district_id": "140705",
    "label": "Bahtera Makmur",
    "value": "Bahtera Makmur"
  },
  {
    "id": "1407052007",
    "district_id": "140705",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "1407052008",
    "district_id": "140705",
    "label": "Gelora",
    "value": "Gelora"
  },
  {
    "id": "1407052015",
    "district_id": "140705",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1407052016",
    "district_id": "140705",
    "label": "Bagan Manunggal",
    "value": "Bagan Manunggal"
  },
  {
    "id": "1407052017",
    "district_id": "140705",
    "label": "Bagan Sapta Permai",
    "value": "Bagan Sapta Permai"
  },
  {
    "id": "1407052025",
    "district_id": "140705",
    "label": "Bakti Makmur",
    "value": "Bakti Makmur"
  },
  {
    "id": "1407052028",
    "district_id": "140705",
    "label": "Jaya Agung",
    "value": "Jaya Agung"
  },
  {
    "id": "1407052030",
    "district_id": "140705",
    "label": "Meranti Makmur",
    "value": "Meranti Makmur"
  },
  {
    "id": "1407052031",
    "district_id": "140705",
    "label": "Bhayangkara Jaya",
    "value": "Bhayangkara Jaya"
  },
  {
    "id": "1407061007",
    "district_id": "140706",
    "label": "Panipahan",
    "value": "Panipahan"
  },
  {
    "id": "1407062001",
    "district_id": "140706",
    "label": "Panipahan",
    "value": "Panipahan"
  },
  {
    "id": "1407062002",
    "district_id": "140706",
    "label": "Telukpulai",
    "value": "Telukpulai"
  },
  {
    "id": "1407062003",
    "district_id": "140706",
    "label": "Pasir Limau Kapas",
    "value": "Pasir Limau Kapas"
  },
  {
    "id": "1407062004",
    "district_id": "140706",
    "label": "Sungai Daun",
    "value": "Sungai Daun"
  },
  {
    "id": "1407062005",
    "district_id": "140706",
    "label": "Panipahan Darat",
    "value": "Panipahan Darat"
  },
  {
    "id": "1407062006",
    "district_id": "140706",
    "label": "Panipahan Laut",
    "value": "Panipahan Laut"
  },
  {
    "id": "1407062008",
    "district_id": "140706",
    "label": "Pulaujemur",
    "value": "Pulaujemur"
  },
  {
    "id": "1407071005",
    "district_id": "140707",
    "label": "Sinaboi Kota",
    "value": "Sinaboi Kota"
  },
  {
    "id": "1407072001",
    "district_id": "140707",
    "label": "Sinaboi",
    "value": "Sinaboi"
  },
  {
    "id": "1407072002",
    "district_id": "140707",
    "label": "Sei Bakau",
    "value": "Sei Bakau"
  },
  {
    "id": "1407072003",
    "district_id": "140707",
    "label": "Raja Bejamu",
    "value": "Raja Bejamu"
  },
  {
    "id": "1407072004",
    "district_id": "140707",
    "label": "Sungai Nyamuk",
    "value": "Sungai Nyamuk"
  },
  {
    "id": "1407072006",
    "district_id": "140707",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1407081010",
    "district_id": "140708",
    "label": "Pujud Selatan",
    "value": "Pujud Selatan"
  },
  {
    "id": "1407082001",
    "district_id": "140708",
    "label": "Teluk Nayang",
    "value": "Teluk Nayang"
  },
  {
    "id": "1407082002",
    "district_id": "140708",
    "label": "Pujud",
    "value": "Pujud"
  },
  {
    "id": "1407082003",
    "district_id": "140708",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1407082004",
    "district_id": "140708",
    "label": "Kasang Bangsawan",
    "value": "Kasang Bangsawan"
  },
  {
    "id": "1407082005",
    "district_id": "140708",
    "label": "Sungaipinang",
    "value": "Sungaipinang"
  },
  {
    "id": "1407082008",
    "district_id": "140708",
    "label": "Siarang-arang",
    "value": "Siarang-arang"
  },
  {
    "id": "1407082011",
    "district_id": "140708",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1407082015",
    "district_id": "140708",
    "label": "Pujud Utara",
    "value": "Pujud Utara"
  },
  {
    "id": "1407082016",
    "district_id": "140708",
    "label": "Babussalam Rokan",
    "value": "Babussalam Rokan"
  },
  {
    "id": "1407082017",
    "district_id": "140708",
    "label": "Perkebunan Siarang-arang",
    "value": "Perkebunan Siarang-arang"
  },
  {
    "id": "1407091005",
    "district_id": "140709",
    "label": "Melayu Besar Kota",
    "value": "Melayu Besar Kota"
  },
  {
    "id": "1407092001",
    "district_id": "140709",
    "label": "Melayu Besar",
    "value": "Melayu Besar"
  },
  {
    "id": "1407092002",
    "district_id": "140709",
    "label": "Melayu Tengah",
    "value": "Melayu Tengah"
  },
  {
    "id": "1407092003",
    "district_id": "140709",
    "label": "Batu Hampar",
    "value": "Batu Hampar"
  },
  {
    "id": "1407092004",
    "district_id": "140709",
    "label": "Mesah",
    "value": "Mesah"
  },
  {
    "id": "1407092006",
    "district_id": "140709",
    "label": "Labuhan Papan",
    "value": "Labuhan Papan"
  },
  {
    "id": "1407101001",
    "district_id": "140710",
    "label": "Bangko Kanan",
    "value": "Bangko Kanan"
  },
  {
    "id": "1407101006",
    "district_id": "140710",
    "label": "Bangko Kiri",
    "value": "Bangko Kiri"
  },
  {
    "id": "1407102002",
    "district_id": "140710",
    "label": "Bangko Jaya",
    "value": "Bangko Jaya"
  },
  {
    "id": "1407102003",
    "district_id": "140710",
    "label": "Bangko Sempurna",
    "value": "Bangko Sempurna"
  },
  {
    "id": "1407102004",
    "district_id": "140710",
    "label": "Bangko Bakti",
    "value": "Bangko Bakti"
  },
  {
    "id": "1407102005",
    "district_id": "140710",
    "label": "Bangko Pusako",
    "value": "Bangko Pusako"
  },
  {
    "id": "1407102007",
    "district_id": "140710",
    "label": "Sei Manasib",
    "value": "Sei Manasib"
  },
  {
    "id": "1407102008",
    "district_id": "140710",
    "label": "Teluk Bano I",
    "value": "Teluk Bano I"
  },
  {
    "id": "1407102009",
    "district_id": "140710",
    "label": "Bangko Makmur",
    "value": "Bangko Makmur"
  },
  {
    "id": "1407102010",
    "district_id": "140710",
    "label": "Pematang Damar",
    "value": "Pematang Damar"
  },
  {
    "id": "1407102011",
    "district_id": "140710",
    "label": "Pematang Ibul",
    "value": "Pematang Ibul"
  },
  {
    "id": "1407102012",
    "district_id": "140710",
    "label": "Bangko Permata",
    "value": "Bangko Permata"
  },
  {
    "id": "1407102013",
    "district_id": "140710",
    "label": "Bangko Mukti",
    "value": "Bangko Mukti"
  },
  {
    "id": "1407102014",
    "district_id": "140710",
    "label": "Bangko Lestari",
    "value": "Bangko Lestari"
  },
  {
    "id": "1407102015",
    "district_id": "140710",
    "label": "Bangko Balam",
    "value": "Bangko Balam"
  },
  {
    "id": "1407102016",
    "district_id": "140710",
    "label": "Bangko Mas Raya",
    "value": "Bangko Mas Raya"
  },
  {
    "id": "1407111001",
    "district_id": "140711",
    "label": "Simpang Kanan",
    "value": "Simpang Kanan"
  },
  {
    "id": "1407112002",
    "district_id": "140711",
    "label": "Kota Parit",
    "value": "Kota Parit"
  },
  {
    "id": "1407112003",
    "district_id": "140711",
    "label": "Bagan Nibung",
    "value": "Bagan Nibung"
  },
  {
    "id": "1407112004",
    "district_id": "140711",
    "label": "Bukit Damar",
    "value": "Bukit Damar"
  },
  {
    "id": "1407112005",
    "district_id": "140711",
    "label": "Bukit Mas",
    "value": "Bukit Mas"
  },
  {
    "id": "1407112006",
    "district_id": "140711",
    "label": "Bukit Selamat",
    "value": "Bukit Selamat"
  },
  {
    "id": "1407121004",
    "district_id": "140712",
    "label": "Bantayan Hilir",
    "value": "Bantayan Hilir"
  },
  {
    "id": "1407122001",
    "district_id": "140712",
    "label": "Bantaian",
    "value": "Bantaian"
  },
  {
    "id": "1407122002",
    "district_id": "140712",
    "label": "Sungaisialang",
    "value": "Sungaisialang"
  },
  {
    "id": "1407122003",
    "district_id": "140712",
    "label": "Bantayan Baru",
    "value": "Bantayan Baru"
  },
  {
    "id": "1407122005",
    "district_id": "140712",
    "label": "Sungaisialang Hulu",
    "value": "Sungaisialang Hulu"
  },
  {
    "id": "1407131002",
    "district_id": "140713",
    "label": "Rantau Kopar",
    "value": "Rantau Kopar"
  },
  {
    "id": "1407131003",
    "district_id": "140713",
    "label": "Sungai Rangau",
    "value": "Sungai Rangau"
  },
  {
    "id": "1407132001",
    "district_id": "140713",
    "label": "Sekapas",
    "value": "Sekapas"
  },
  {
    "id": "1407132004",
    "district_id": "140713",
    "label": "Bagan Cempedak",
    "value": "Bagan Cempedak"
  },
  {
    "id": "1407142001",
    "district_id": "140714",
    "label": "Pedamaran",
    "value": "Pedamaran"
  },
  {
    "id": "1407142002",
    "district_id": "140714",
    "label": "Rokan Baru",
    "value": "Rokan Baru"
  },
  {
    "id": "1407142003",
    "district_id": "140714",
    "label": "Sei Besar",
    "value": "Sei Besar"
  },
  {
    "id": "1407142004",
    "district_id": "140714",
    "label": "Suak Temenggung",
    "value": "Suak Temenggung"
  },
  {
    "id": "1407142005",
    "district_id": "140714",
    "label": "Teluk Bano II",
    "value": "Teluk Bano II"
  },
  {
    "id": "1407142006",
    "district_id": "140714",
    "label": "Pekaitan",
    "value": "Pekaitan"
  },
  {
    "id": "1407142007",
    "district_id": "140714",
    "label": "Suak Air Hitam",
    "value": "Suak Air Hitam"
  },
  {
    "id": "1407142008",
    "district_id": "140714",
    "label": "Kubu I",
    "value": "Kubu I"
  },
  {
    "id": "1407142009",
    "district_id": "140714",
    "label": "Karyo Mulyo Sari",
    "value": "Karyo Mulyo Sari"
  },
  {
    "id": "1407142010",
    "district_id": "140714",
    "label": "Rokan Baru Pesisir",
    "value": "Rokan Baru Pesisir"
  },
  {
    "id": "1407152001",
    "district_id": "140715",
    "label": "Rantau Panjang Kiri",
    "value": "Rantau Panjang Kiri"
  },
  {
    "id": "1407152002",
    "district_id": "140715",
    "label": "Teluknilap",
    "value": "Teluknilap"
  },
  {
    "id": "1407152003",
    "district_id": "140715",
    "label": "Sungaimajo",
    "value": "Sungaimajo"
  },
  {
    "id": "1407152004",
    "district_id": "140715",
    "label": "Sungaipinang",
    "value": "Sungaipinang"
  },
  {
    "id": "1407152005",
    "district_id": "140715",
    "label": "Sungaipanji-panji",
    "value": "Sungaipanji-panji"
  },
  {
    "id": "1407152006",
    "district_id": "140715",
    "label": "Jojol",
    "value": "Jojol"
  },
  {
    "id": "1407152007",
    "district_id": "140715",
    "label": "Pulauhalang Muka",
    "value": "Pulauhalang Muka"
  },
  {
    "id": "1407152008",
    "district_id": "140715",
    "label": "Pulauhalang Belakang",
    "value": "Pulauhalang Belakang"
  },
  {
    "id": "1407152009",
    "district_id": "140715",
    "label": "Rantau Panjang Kiri Hilir",
    "value": "Rantau Panjang Kiri Hilir"
  },
  {
    "id": "1407152010",
    "district_id": "140715",
    "label": "Teluknilap Jaya",
    "value": "Teluknilap Jaya"
  },
  {
    "id": "1407152011",
    "district_id": "140715",
    "label": "Sungaimajo Pusako",
    "value": "Sungaimajo Pusako"
  },
  {
    "id": "1407152012",
    "district_id": "140715",
    "label": "Pulauhalang Hulu",
    "value": "Pulauhalang Hulu"
  },
  {
    "id": "1407162001",
    "district_id": "140716",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1407162002",
    "district_id": "140716",
    "label": "Tanjung Medan Utara",
    "value": "Tanjung Medan Utara"
  },
  {
    "id": "1407162003",
    "district_id": "140716",
    "label": "Tanjung Medan Barat",
    "value": "Tanjung Medan Barat"
  },
  {
    "id": "1407162004",
    "district_id": "140716",
    "label": "Sei Meranti",
    "value": "Sei Meranti"
  },
  {
    "id": "1407162005",
    "district_id": "140716",
    "label": "Sei Meranti Darussalam",
    "value": "Sei Meranti Darussalam"
  },
  {
    "id": "1407162006",
    "district_id": "140716",
    "label": "Akar Belingkar",
    "value": "Akar Belingkar"
  },
  {
    "id": "1407162007",
    "district_id": "140716",
    "label": "Tangga Batu",
    "value": "Tangga Batu"
  },
  {
    "id": "1407162008",
    "district_id": "140716",
    "label": "Perkebunan Tanjung Medan",
    "value": "Perkebunan Tanjung Medan"
  },
  {
    "id": "1407162009",
    "district_id": "140716",
    "label": "Sri Kayangan",
    "value": "Sri Kayangan"
  },
  {
    "id": "1407162010",
    "district_id": "140716",
    "label": "Pondok Kresek",
    "value": "Pondok Kresek"
  },
  {
    "id": "1407162011",
    "district_id": "140716",
    "label": "Sungai Tapah",
    "value": "Sungai Tapah"
  },
  {
    "id": "1407162012",
    "district_id": "140716",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1407171001",
    "district_id": "140717",
    "label": "Bagan Sinembah Kota",
    "value": "Bagan Sinembah Kota"
  },
  {
    "id": "1407172002",
    "district_id": "140717",
    "label": "Bagan Sinembah",
    "value": "Bagan Sinembah"
  },
  {
    "id": "1407172003",
    "district_id": "140717",
    "label": "Harapan Makmur Selatan",
    "value": "Harapan Makmur Selatan"
  },
  {
    "id": "1407172004",
    "district_id": "140717",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "1407172005",
    "district_id": "140717",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1407172006",
    "district_id": "140717",
    "label": "Salak",
    "value": "Salak"
  },
  {
    "id": "1407172007",
    "district_id": "140717",
    "label": "Panca Mukti",
    "value": "Panca Mukti"
  },
  {
    "id": "1407172008",
    "district_id": "140717",
    "label": "Bagansinembah Timur",
    "value": "Bagansinembah Timur"
  },
  {
    "id": "1407172009",
    "district_id": "140717",
    "label": "Bagansinembah Utara",
    "value": "Bagansinembah Utara"
  },
  {
    "id": "1407172010",
    "district_id": "140717",
    "label": "Bagansinembah Barat",
    "value": "Bagansinembah Barat"
  },
  {
    "id": "1407181001",
    "district_id": "140718",
    "label": "Balai Jaya Kota",
    "value": "Balai Jaya Kota"
  },
  {
    "id": "1407181002",
    "district_id": "140718",
    "label": "Balam Sempurna Kota",
    "value": "Balam Sempurna Kota"
  },
  {
    "id": "1407182003",
    "district_id": "140718",
    "label": "Balam Jaya",
    "value": "Balam Jaya"
  },
  {
    "id": "1407182004",
    "district_id": "140718",
    "label": "Balai Jaya",
    "value": "Balai Jaya"
  },
  {
    "id": "1407182005",
    "district_id": "140718",
    "label": "Balam Sempurna",
    "value": "Balam Sempurna"
  },
  {
    "id": "1407182006",
    "district_id": "140718",
    "label": "Bagan Bhakti",
    "value": "Bagan Bhakti"
  },
  {
    "id": "1407182007",
    "district_id": "140718",
    "label": "Kencana",
    "value": "Kencana"
  },
  {
    "id": "1407182008",
    "district_id": "140718",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1407182009",
    "district_id": "140718",
    "label": "Pasir Putih Utara",
    "value": "Pasir Putih Utara"
  },
  {
    "id": "1407182010",
    "district_id": "140718",
    "label": "Pasir Putih Barat",
    "value": "Pasir Putih Barat"
  },
  {
    "id": "1407182011",
    "district_id": "140718",
    "label": "Lubuk Jawi",
    "value": "Lubuk Jawi"
  },
  {
    "id": "1408011001",
    "district_id": "140801",
    "label": "Kampung Dalam",
    "value": "Kampung Dalam"
  },
  {
    "id": "1408011002",
    "district_id": "140801",
    "label": "Kampung Rempak",
    "value": "Kampung Rempak"
  },
  {
    "id": "1408012003",
    "district_id": "140801",
    "label": "Langkai",
    "value": "Langkai"
  },
  {
    "id": "1408012011",
    "district_id": "140801",
    "label": "Tumang",
    "value": "Tumang"
  },
  {
    "id": "1408012012",
    "district_id": "140801",
    "label": "Merempan Hulu",
    "value": "Merempan Hulu"
  },
  {
    "id": "1408012013",
    "district_id": "140801",
    "label": "Rawang Air Putih",
    "value": "Rawang Air Putih"
  },
  {
    "id": "1408012014",
    "district_id": "140801",
    "label": "Suak Lanjut",
    "value": "Suak Lanjut"
  },
  {
    "id": "1408012015",
    "district_id": "140801",
    "label": "Buantan Besar",
    "value": "Buantan Besar"
  },
  {
    "id": "1408021001",
    "district_id": "140802",
    "label": "Sungai Apit",
    "value": "Sungai Apit"
  },
  {
    "id": "1408022002",
    "district_id": "140802",
    "label": "Teluk Lanus",
    "value": "Teluk Lanus"
  },
  {
    "id": "1408022003",
    "district_id": "140802",
    "label": "Tanjung Kuras",
    "value": "Tanjung Kuras"
  },
  {
    "id": "1408022004",
    "district_id": "140802",
    "label": "Parit I/II",
    "value": "Parit I/II"
  },
  {
    "id": "1408022005",
    "district_id": "140802",
    "label": "Teluk Mesjid",
    "value": "Teluk Mesjid"
  },
  {
    "id": "1408022008",
    "district_id": "140802",
    "label": "Sungai Kayu Ara",
    "value": "Sungai Kayu Ara"
  },
  {
    "id": "1408022009",
    "district_id": "140802",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1408022010",
    "district_id": "140802",
    "label": "Mengkapan",
    "value": "Mengkapan"
  },
  {
    "id": "1408022011",
    "district_id": "140802",
    "label": "Sungai Rawa",
    "value": "Sungai Rawa"
  },
  {
    "id": "1408022012",
    "district_id": "140802",
    "label": "Penyengat",
    "value": "Penyengat"
  },
  {
    "id": "1408022014",
    "district_id": "140802",
    "label": "Teluk Batil",
    "value": "Teluk Batil"
  },
  {
    "id": "1408022016",
    "district_id": "140802",
    "label": "Bunsur",
    "value": "Bunsur"
  },
  {
    "id": "1408022017",
    "district_id": "140802",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "1408022019",
    "district_id": "140802",
    "label": "Kayu Ara Permai",
    "value": "Kayu Ara Permai"
  },
  {
    "id": "1408022020",
    "district_id": "140802",
    "label": "Rawa Mekar Jaya",
    "value": "Rawa Mekar Jaya"
  },
  {
    "id": "1408031001",
    "district_id": "140803",
    "label": "Minas Jaya",
    "value": "Minas Jaya"
  },
  {
    "id": "1408032002",
    "district_id": "140803",
    "label": "Minas Timur",
    "value": "Minas Timur"
  },
  {
    "id": "1408032003",
    "district_id": "140803",
    "label": "Minas Barat",
    "value": "Minas Barat"
  },
  {
    "id": "1408032004",
    "district_id": "140803",
    "label": "Mandi Angin",
    "value": "Mandi Angin"
  },
  {
    "id": "1408032005",
    "district_id": "140803",
    "label": "Rantau Bertuah",
    "value": "Rantau Bertuah"
  },
  {
    "id": "1408041001",
    "district_id": "140804",
    "label": "Perawang",
    "value": "Perawang"
  },
  {
    "id": "1408042002",
    "district_id": "140804",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1408042003",
    "district_id": "140804",
    "label": "Pinang Sebatang",
    "value": "Pinang Sebatang"
  },
  {
    "id": "1408042004",
    "district_id": "140804",
    "label": "Meredan",
    "value": "Meredan"
  },
  {
    "id": "1408042005",
    "district_id": "140804",
    "label": "Pinang Sebatang Timur",
    "value": "Pinang Sebatang Timur"
  },
  {
    "id": "1408042006",
    "district_id": "140804",
    "label": "Pinang Sebatang Barat",
    "value": "Pinang Sebatang Barat"
  },
  {
    "id": "1408042007",
    "district_id": "140804",
    "label": "Meredan Barat",
    "value": "Meredan Barat"
  },
  {
    "id": "1408042008",
    "district_id": "140804",
    "label": "Perawang Barat",
    "value": "Perawang Barat"
  },
  {
    "id": "1408042009",
    "district_id": "140804",
    "label": "Tualang Timur",
    "value": "Tualang Timur"
  },
  {
    "id": "1408052001",
    "district_id": "140805",
    "label": "Muara Kelantan",
    "value": "Muara Kelantan"
  },
  {
    "id": "1408052002",
    "district_id": "140805",
    "label": "Teluk Lancang",
    "value": "Teluk Lancang"
  },
  {
    "id": "1408052003",
    "district_id": "140805",
    "label": "Sungai Selodang",
    "value": "Sungai Selodang"
  },
  {
    "id": "1408052004",
    "district_id": "140805",
    "label": "Olak",
    "value": "Olak"
  },
  {
    "id": "1408052005",
    "district_id": "140805",
    "label": "Lubuk Jering",
    "value": "Lubuk Jering"
  },
  {
    "id": "1408052006",
    "district_id": "140805",
    "label": "Muara Bungkal",
    "value": "Muara Bungkal"
  },
  {
    "id": "1408052007",
    "district_id": "140805",
    "label": "Lubuk Umbut",
    "value": "Lubuk Umbut"
  },
  {
    "id": "1408052008",
    "district_id": "140805",
    "label": "Bencah Umbai",
    "value": "Bencah Umbai"
  },
  {
    "id": "1408052009",
    "district_id": "140805",
    "label": "Tasik Betung",
    "value": "Tasik Betung"
  },
  {
    "id": "1408062001",
    "district_id": "140806",
    "label": "Dayun",
    "value": "Dayun"
  },
  {
    "id": "1408062002",
    "district_id": "140806",
    "label": "Banjar Seminai",
    "value": "Banjar Seminai"
  },
  {
    "id": "1408062003",
    "district_id": "140806",
    "label": "Teluk Merbau",
    "value": "Teluk Merbau"
  },
  {
    "id": "1408062004",
    "district_id": "140806",
    "label": "Merangkai",
    "value": "Merangkai"
  },
  {
    "id": "1408062005",
    "district_id": "140806",
    "label": "Lubuk Tilan",
    "value": "Lubuk Tilan"
  },
  {
    "id": "1408062006",
    "district_id": "140806",
    "label": "Berumbung Baru",
    "value": "Berumbung Baru"
  },
  {
    "id": "1408062007",
    "district_id": "140806",
    "label": "Pangkalan Makmur",
    "value": "Pangkalan Makmur"
  },
  {
    "id": "1408062008",
    "district_id": "140806",
    "label": "Buana Makmur",
    "value": "Buana Makmur"
  },
  {
    "id": "1408062009",
    "district_id": "140806",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1408062010",
    "district_id": "140806",
    "label": "Sawit Permai",
    "value": "Sawit Permai"
  },
  {
    "id": "1408062011",
    "district_id": "140806",
    "label": "Sialang Sakti",
    "value": "Sialang Sakti"
  },
  {
    "id": "1408072001",
    "district_id": "140807",
    "label": "Kerinci Kanan",
    "value": "Kerinci Kanan"
  },
  {
    "id": "1408072002",
    "district_id": "140807",
    "label": "Kerinci Kiri",
    "value": "Kerinci Kiri"
  },
  {
    "id": "1408072003",
    "district_id": "140807",
    "label": "Buana Bakti",
    "value": "Buana Bakti"
  },
  {
    "id": "1408072004",
    "district_id": "140807",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "1408072005",
    "district_id": "140807",
    "label": "Kumbara Utama",
    "value": "Kumbara Utama"
  },
  {
    "id": "1408072006",
    "district_id": "140807",
    "label": "Bukit Agung",
    "value": "Bukit Agung"
  },
  {
    "id": "1408072008",
    "district_id": "140807",
    "label": "Simpang Perak Jaya",
    "value": "Simpang Perak Jaya"
  },
  {
    "id": "1408072009",
    "district_id": "140807",
    "label": "Buatan Baru",
    "value": "Buatan Baru"
  },
  {
    "id": "1408072010",
    "district_id": "140807",
    "label": "Gabung Makmur",
    "value": "Gabung Makmur"
  },
  {
    "id": "1408072011",
    "district_id": "140807",
    "label": "Jati Mulya",
    "value": "Jati Mulya"
  },
  {
    "id": "1408072012",
    "district_id": "140807",
    "label": "Seminai",
    "value": "Seminai"
  },
  {
    "id": "1408072013",
    "district_id": "140807",
    "label": "Delima Jaya",
    "value": "Delima Jaya"
  },
  {
    "id": "1408082001",
    "district_id": "140808",
    "label": "Bunga Raya",
    "value": "Bunga Raya"
  },
  {
    "id": "1408082002",
    "district_id": "140808",
    "label": "Jati Baru",
    "value": "Jati Baru"
  },
  {
    "id": "1408082003",
    "district_id": "140808",
    "label": "Jayapura",
    "value": "Jayapura"
  },
  {
    "id": "1408082011",
    "district_id": "140808",
    "label": "Kemuning Muda",
    "value": "Kemuning Muda"
  },
  {
    "id": "1408082012",
    "district_id": "140808",
    "label": "Buatan Lestari",
    "value": "Buatan Lestari"
  },
  {
    "id": "1408082013",
    "district_id": "140808",
    "label": "Tuah Indrapura",
    "value": "Tuah Indrapura"
  },
  {
    "id": "1408082014",
    "district_id": "140808",
    "label": "Langsat Permai",
    "value": "Langsat Permai"
  },
  {
    "id": "1408082015",
    "district_id": "140808",
    "label": "Temusai",
    "value": "Temusai"
  },
  {
    "id": "1408082016",
    "district_id": "140808",
    "label": "Dayang Suri",
    "value": "Dayang Suri"
  },
  {
    "id": "1408082017",
    "district_id": "140808",
    "label": "Suak Merambai",
    "value": "Suak Merambai"
  },
  {
    "id": "1408092001",
    "district_id": "140809",
    "label": "Pangkalan Pisang",
    "value": "Pangkalan Pisang"
  },
  {
    "id": "1408092002",
    "district_id": "140809",
    "label": "Kuala Gasib",
    "value": "Kuala Gasib"
  },
  {
    "id": "1408092003",
    "district_id": "140809",
    "label": "Teluk Rimba",
    "value": "Teluk Rimba"
  },
  {
    "id": "1408092004",
    "district_id": "140809",
    "label": "Buatan I",
    "value": "Buatan I"
  },
  {
    "id": "1408092005",
    "district_id": "140809",
    "label": "Buatan II",
    "value": "Buatan II"
  },
  {
    "id": "1408092006",
    "district_id": "140809",
    "label": "Sengkemang",
    "value": "Sengkemang"
  },
  {
    "id": "1408092007",
    "district_id": "140809",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1408092008",
    "district_id": "140809",
    "label": "Empang Pandan",
    "value": "Empang Pandan"
  },
  {
    "id": "1408092009",
    "district_id": "140809",
    "label": "Keranji Guguh",
    "value": "Keranji Guguh"
  },
  {
    "id": "1408092010",
    "district_id": "140809",
    "label": "Sri Gemilang",
    "value": "Sri Gemilang"
  },
  {
    "id": "1408092011",
    "district_id": "140809",
    "label": "Tasik Seminai",
    "value": "Tasik Seminai"
  },
  {
    "id": "1408101001",
    "district_id": "140810",
    "label": "Simpang Belutu",
    "value": "Simpang Belutu"
  },
  {
    "id": "1408101002",
    "district_id": "140810",
    "label": "Telaga Sam-sam",
    "value": "Telaga Sam-sam"
  },
  {
    "id": "1408101003",
    "district_id": "140810",
    "label": "Kandis Kota",
    "value": "Kandis Kota"
  },
  {
    "id": "1408102004",
    "district_id": "140810",
    "label": "Belutu",
    "value": "Belutu"
  },
  {
    "id": "1408102005",
    "district_id": "140810",
    "label": "Kandis",
    "value": "Kandis"
  },
  {
    "id": "1408102006",
    "district_id": "140810",
    "label": "Sam-Sam",
    "value": "Sam-Sam"
  },
  {
    "id": "1408102007",
    "district_id": "140810",
    "label": "Bekalar",
    "value": "Bekalar"
  },
  {
    "id": "1408102008",
    "district_id": "140810",
    "label": "Jambai Makmur",
    "value": "Jambai Makmur"
  },
  {
    "id": "1408102009",
    "district_id": "140810",
    "label": "Sungai Gondang",
    "value": "Sungai Gondang"
  },
  {
    "id": "1408102010",
    "district_id": "140810",
    "label": "Pencing Bekulo",
    "value": "Pencing Bekulo"
  },
  {
    "id": "1408102011",
    "district_id": "140810",
    "label": "Libo Jaya",
    "value": "Libo Jaya"
  },
  {
    "id": "1408112001",
    "district_id": "140811",
    "label": "Lubuk Dalam",
    "value": "Lubuk Dalam"
  },
  {
    "id": "1408112002",
    "district_id": "140811",
    "label": "Rawang Kao",
    "value": "Rawang Kao"
  },
  {
    "id": "1408112003",
    "district_id": "140811",
    "label": "Sri Gading",
    "value": "Sri Gading"
  },
  {
    "id": "1408112004",
    "district_id": "140811",
    "label": "Sialang Baru",
    "value": "Sialang Baru"
  },
  {
    "id": "1408112005",
    "district_id": "140811",
    "label": "Sialang Palas",
    "value": "Sialang Palas"
  },
  {
    "id": "1408112006",
    "district_id": "140811",
    "label": "Empang Baru",
    "value": "Empang Baru"
  },
  {
    "id": "1408112007",
    "district_id": "140811",
    "label": "Rawang Kao Barat",
    "value": "Rawang Kao Barat"
  },
  {
    "id": "1408122001",
    "district_id": "140812",
    "label": "Bandar Sungai",
    "value": "Bandar Sungai"
  },
  {
    "id": "1408122002",
    "district_id": "140812",
    "label": "Rempak",
    "value": "Rempak"
  },
  {
    "id": "1408122003",
    "district_id": "140812",
    "label": "Belading",
    "value": "Belading"
  },
  {
    "id": "1408122004",
    "district_id": "140812",
    "label": "Sungai Tengah",
    "value": "Sungai Tengah"
  },
  {
    "id": "1408122005",
    "district_id": "140812",
    "label": "Laksamana",
    "value": "Laksamana"
  },
  {
    "id": "1408122006",
    "district_id": "140812",
    "label": "Sabak Permai",
    "value": "Sabak Permai"
  },
  {
    "id": "1408122007",
    "district_id": "140812",
    "label": "Bandar Pedada",
    "value": "Bandar Pedada"
  },
  {
    "id": "1408122008",
    "district_id": "140812",
    "label": "Selat Guntung",
    "value": "Selat Guntung"
  },
  {
    "id": "1408131006",
    "district_id": "140813",
    "label": "Sungaimempura",
    "value": "Sungaimempura"
  },
  {
    "id": "1408132001",
    "district_id": "140813",
    "label": "Kota Ringin",
    "value": "Kota Ringin"
  },
  {
    "id": "1408132002",
    "district_id": "140813",
    "label": "Paluh",
    "value": "Paluh"
  },
  {
    "id": "1408132003",
    "district_id": "140813",
    "label": "Benteng Hilir",
    "value": "Benteng Hilir"
  },
  {
    "id": "1408132004",
    "district_id": "140813",
    "label": "Benteng Hulu",
    "value": "Benteng Hulu"
  },
  {
    "id": "1408132005",
    "district_id": "140813",
    "label": "Kampung Tengah",
    "value": "Kampung Tengah"
  },
  {
    "id": "1408132007",
    "district_id": "140813",
    "label": "Merempan Hilir",
    "value": "Merempan Hilir"
  },
  {
    "id": "1408132008",
    "district_id": "140813",
    "label": "Telukmerempan",
    "value": "Telukmerempan"
  },
  {
    "id": "1408142001",
    "district_id": "140814",
    "label": "Sungai Berbari",
    "value": "Sungai Berbari"
  },
  {
    "id": "1408142002",
    "district_id": "140814",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "1408142003",
    "district_id": "140814",
    "label": "Dosan",
    "value": "Dosan"
  },
  {
    "id": "1408142004",
    "district_id": "140814",
    "label": "Benayah",
    "value": "Benayah"
  },
  {
    "id": "1408142005",
    "district_id": "140814",
    "label": "Pebadaran",
    "value": "Pebadaran"
  },
  {
    "id": "1408142006",
    "district_id": "140814",
    "label": "Dusun Pusaka",
    "value": "Dusun Pusaka"
  },
  {
    "id": "1408142007",
    "district_id": "140814",
    "label": "Perincit",
    "value": "Perincit"
  },
  {
    "id": "1409011012",
    "district_id": "140901",
    "label": "Pasar Lubuk Jambi",
    "value": "Pasar Lubuk Jambi"
  },
  {
    "id": "1409012004",
    "district_id": "140901",
    "label": "Air Buluh",
    "value": "Air Buluh"
  },
  {
    "id": "1409012005",
    "district_id": "140901",
    "label": "Pantai",
    "value": "Pantai"
  },
  {
    "id": "1409012007",
    "district_id": "140901",
    "label": "Lubuk Ramo",
    "value": "Lubuk Ramo"
  },
  {
    "id": "1409012008",
    "district_id": "140901",
    "label": "Seberang Cengar",
    "value": "Seberang Cengar"
  },
  {
    "id": "1409012009",
    "district_id": "140901",
    "label": "Koto Cengar",
    "value": "Koto Cengar"
  },
  {
    "id": "1409012010",
    "district_id": "140901",
    "label": "Sangau",
    "value": "Sangau"
  },
  {
    "id": "1409012011",
    "district_id": "140901",
    "label": "Banjar Padang",
    "value": "Banjar Padang"
  },
  {
    "id": "1409012013",
    "district_id": "140901",
    "label": "Kasang",
    "value": "Kasang"
  },
  {
    "id": "1409012014",
    "district_id": "140901",
    "label": "Koto Lubuk Jambi",
    "value": "Koto Lubuk Jambi"
  },
  {
    "id": "1409012015",
    "district_id": "140901",
    "label": "Pulaubinjai",
    "value": "Pulaubinjai"
  },
  {
    "id": "1409012016",
    "district_id": "140901",
    "label": "Seberang Pantai",
    "value": "Seberang Pantai"
  },
  {
    "id": "1409012017",
    "district_id": "140901",
    "label": "Rantau Sialang",
    "value": "Rantau Sialang"
  },
  {
    "id": "1409012018",
    "district_id": "140901",
    "label": "Luai",
    "value": "Luai"
  },
  {
    "id": "1409012019",
    "district_id": "140901",
    "label": "Banjar Guntung",
    "value": "Banjar Guntung"
  },
  {
    "id": "1409012020",
    "district_id": "140901",
    "label": "Bukitpedusunan",
    "value": "Bukitpedusunan"
  },
  {
    "id": "1409012021",
    "district_id": "140901",
    "label": "Saik",
    "value": "Saik"
  },
  {
    "id": "1409012022",
    "district_id": "140901",
    "label": "Pebaun Hulu",
    "value": "Pebaun Hulu"
  },
  {
    "id": "1409012023",
    "district_id": "140901",
    "label": "Pebaun Hilir",
    "value": "Pebaun Hilir"
  },
  {
    "id": "1409012024",
    "district_id": "140901",
    "label": "Kinali",
    "value": "Kinali"
  },
  {
    "id": "1409012025",
    "district_id": "140901",
    "label": "Bukit Kauman",
    "value": "Bukit Kauman"
  },
  {
    "id": "1409012026",
    "district_id": "140901",
    "label": "Aur Duri",
    "value": "Aur Duri"
  },
  {
    "id": "1409012027",
    "district_id": "140901",
    "label": "Sungaimanau",
    "value": "Sungaimanau"
  },
  {
    "id": "1409012030",
    "district_id": "140901",
    "label": "Muaro Tombang",
    "value": "Muaro Tombang"
  },
  {
    "id": "1409021016",
    "district_id": "140902",
    "label": "Pasar Teluk",
    "value": "Pasar Teluk"
  },
  {
    "id": "1409021018",
    "district_id": "140902",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1409021025",
    "district_id": "140902",
    "label": "Sungaijering",
    "value": "Sungaijering"
  },
  {
    "id": "1409022001",
    "district_id": "140902",
    "label": "Bandar Alai",
    "value": "Bandar Alai"
  },
  {
    "id": "1409022002",
    "district_id": "140902",
    "label": "Pulaukedundung",
    "value": "Pulaukedundung"
  },
  {
    "id": "1409022003",
    "district_id": "140902",
    "label": "Pulauaro",
    "value": "Pulauaro"
  },
  {
    "id": "1409022004",
    "district_id": "140902",
    "label": "Seberang Taluk",
    "value": "Seberang Taluk"
  },
  {
    "id": "1409022005",
    "district_id": "140902",
    "label": "Pulau Baru",
    "value": "Pulau Baru"
  },
  {
    "id": "1409022006",
    "district_id": "140902",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1409022007",
    "district_id": "140902",
    "label": "Kopah",
    "value": "Kopah"
  },
  {
    "id": "1409022008",
    "district_id": "140902",
    "label": "Munsalo",
    "value": "Munsalo"
  },
  {
    "id": "1409022014",
    "district_id": "140902",
    "label": "Sawah",
    "value": "Sawah"
  },
  {
    "id": "1409022015",
    "district_id": "140902",
    "label": "Koto Taluk",
    "value": "Koto Taluk"
  },
  {
    "id": "1409022017",
    "district_id": "140902",
    "label": "Koto Kari",
    "value": "Koto Kari"
  },
  {
    "id": "1409022019",
    "district_id": "140902",
    "label": "Pintu Gobang",
    "value": "Pintu Gobang"
  },
  {
    "id": "1409022020",
    "district_id": "140902",
    "label": "Jake",
    "value": "Jake"
  },
  {
    "id": "1409022021",
    "district_id": "140902",
    "label": "Pulau Godang Kari",
    "value": "Pulau Godang Kari"
  },
  {
    "id": "1409022022",
    "district_id": "140902",
    "label": "Jaya",
    "value": "Jaya"
  },
  {
    "id": "1409022023",
    "district_id": "140902",
    "label": "Beringin Taluk",
    "value": "Beringin Taluk"
  },
  {
    "id": "1409022024",
    "district_id": "140902",
    "label": "Sitorajo",
    "value": "Sitorajo"
  },
  {
    "id": "1409022026",
    "district_id": "140902",
    "label": "Seberang Taluk Hilir",
    "value": "Seberang Taluk Hilir"
  },
  {
    "id": "1409022027",
    "district_id": "140902",
    "label": "Titian Modang Kopah",
    "value": "Titian Modang Kopah"
  },
  {
    "id": "1409022028",
    "district_id": "140902",
    "label": "Pulaubanjar Kari",
    "value": "Pulaubanjar Kari"
  },
  {
    "id": "1409031003",
    "district_id": "140903",
    "label": "Muara Lembu",
    "value": "Muara Lembu"
  },
  {
    "id": "1409032001",
    "district_id": "140903",
    "label": "Pangkalan Indarung",
    "value": "Pangkalan Indarung"
  },
  {
    "id": "1409032002",
    "district_id": "140903",
    "label": "Pulaupadang",
    "value": "Pulaupadang"
  },
  {
    "id": "1409032004",
    "district_id": "140903",
    "label": "Logas",
    "value": "Logas"
  },
  {
    "id": "1409032005",
    "district_id": "140903",
    "label": "Kebun Lado",
    "value": "Kebun Lado"
  },
  {
    "id": "1409032006",
    "district_id": "140903",
    "label": "Sungaikuning",
    "value": "Sungaikuning"
  },
  {
    "id": "1409032007",
    "district_id": "140903",
    "label": "Sungaisirih",
    "value": "Sungaisirih"
  },
  {
    "id": "1409032008",
    "district_id": "140903",
    "label": "Sungaibawang",
    "value": "Sungaibawang"
  },
  {
    "id": "1409032009",
    "district_id": "140903",
    "label": "Air Emas",
    "value": "Air Emas"
  },
  {
    "id": "1409032010",
    "district_id": "140903",
    "label": "Pasir Emas",
    "value": "Pasir Emas"
  },
  {
    "id": "1409032011",
    "district_id": "140903",
    "label": "Petai Baru",
    "value": "Petai Baru"
  },
  {
    "id": "1409032012",
    "district_id": "140903",
    "label": "Sungaikeranji",
    "value": "Sungaikeranji"
  },
  {
    "id": "1409032013",
    "district_id": "140903",
    "label": "Sumber Datar",
    "value": "Sumber Datar"
  },
  {
    "id": "1409032014",
    "district_id": "140903",
    "label": "Logas Hilir",
    "value": "Logas Hilir"
  },
  {
    "id": "1409041001",
    "district_id": "140904",
    "label": "Pasar Usang",
    "value": "Pasar Usang"
  },
  {
    "id": "1409041002",
    "district_id": "140904",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1409042003",
    "district_id": "140904",
    "label": "Simpang Tanah Lapang",
    "value": "Simpang Tanah Lapang"
  },
  {
    "id": "1409042004",
    "district_id": "140904",
    "label": "Rawang Bonto",
    "value": "Rawang Bonto"
  },
  {
    "id": "1409042005",
    "district_id": "140904",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1409042006",
    "district_id": "140904",
    "label": "Banuaran",
    "value": "Banuaran"
  },
  {
    "id": "1409042007",
    "district_id": "140904",
    "label": "Kampung Madura",
    "value": "Kampung Madura"
  },
  {
    "id": "1409042009",
    "district_id": "140904",
    "label": "Pulau Kijang",
    "value": "Pulau Kijang"
  },
  {
    "id": "1409042015",
    "district_id": "140904",
    "label": "Pulaumadinah",
    "value": "Pulaumadinah"
  },
  {
    "id": "1409042016",
    "district_id": "140904",
    "label": "Kampung Tengah",
    "value": "Kampung Tengah"
  },
  {
    "id": "1409042017",
    "district_id": "140904",
    "label": "Kampung Medan",
    "value": "Kampung Medan"
  },
  {
    "id": "1409042018",
    "district_id": "140904",
    "label": "Kepala Pulau",
    "value": "Kepala Pulau"
  },
  {
    "id": "1409042019",
    "district_id": "140904",
    "label": "Teratak Baru",
    "value": "Teratak Baru"
  },
  {
    "id": "1409042020",
    "district_id": "140904",
    "label": "Dusun Tuo",
    "value": "Dusun Tuo"
  },
  {
    "id": "1409042028",
    "district_id": "140904",
    "label": "Gunungmelintang",
    "value": "Gunungmelintang"
  },
  {
    "id": "1409042030",
    "district_id": "140904",
    "label": "Simpang Pulauberalo",
    "value": "Simpang Pulauberalo"
  },
  {
    "id": "1409051001",
    "district_id": "140905",
    "label": "Pasar Cerenti",
    "value": "Pasar Cerenti"
  },
  {
    "id": "1409051002",
    "district_id": "140905",
    "label": "Koto Peraku",
    "value": "Koto Peraku"
  },
  {
    "id": "1409052003",
    "district_id": "140905",
    "label": "Kompe Berangin",
    "value": "Kompe Berangin"
  },
  {
    "id": "1409052004",
    "district_id": "140905",
    "label": "Sikakak",
    "value": "Sikakak"
  },
  {
    "id": "1409052005",
    "district_id": "140905",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1409052006",
    "district_id": "140905",
    "label": "Pulaujambu",
    "value": "Pulaujambu"
  },
  {
    "id": "1409052007",
    "district_id": "140905",
    "label": "Koto Cerenti",
    "value": "Koto Cerenti"
  },
  {
    "id": "1409052008",
    "district_id": "140905",
    "label": "Pulaubayur",
    "value": "Pulaubayur"
  },
  {
    "id": "1409052009",
    "district_id": "140905",
    "label": "Pulau Panjang Cerenti",
    "value": "Pulau Panjang Cerenti"
  },
  {
    "id": "1409052010",
    "district_id": "140905",
    "label": "Pesikaian",
    "value": "Pesikaian"
  },
  {
    "id": "1409052011",
    "district_id": "140905",
    "label": "Telukpauh",
    "value": "Telukpauh"
  },
  {
    "id": "1409052012",
    "district_id": "140905",
    "label": "Tanjungmedan",
    "value": "Tanjungmedan"
  },
  {
    "id": "1409052013",
    "district_id": "140905",
    "label": "Kampung Baru Timur",
    "value": "Kampung Baru Timur"
  },
  {
    "id": "1409061001",
    "district_id": "140906",
    "label": "Benai",
    "value": "Benai"
  },
  {
    "id": "1409062003",
    "district_id": "140906",
    "label": "Banjar Benai",
    "value": "Banjar Benai"
  },
  {
    "id": "1409062004",
    "district_id": "140906",
    "label": "Talontom",
    "value": "Talontom"
  },
  {
    "id": "1409062005",
    "district_id": "140906",
    "label": "Gunungkesiangan",
    "value": "Gunungkesiangan"
  },
  {
    "id": "1409062006",
    "district_id": "140906",
    "label": "Pulaukalimanting",
    "value": "Pulaukalimanting"
  },
  {
    "id": "1409062007",
    "district_id": "140906",
    "label": "Banjar Lopak",
    "value": "Banjar Lopak"
  },
  {
    "id": "1409062008",
    "district_id": "140906",
    "label": "Tanjungsimandolak",
    "value": "Tanjungsimandolak"
  },
  {
    "id": "1409062009",
    "district_id": "140906",
    "label": "Siberakun",
    "value": "Siberakun"
  },
  {
    "id": "1409062010",
    "district_id": "140906",
    "label": "Pulautongah",
    "value": "Pulautongah"
  },
  {
    "id": "1409062011",
    "district_id": "140906",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1409062012",
    "district_id": "140906",
    "label": "Pulauingu",
    "value": "Pulauingu"
  },
  {
    "id": "1409062013",
    "district_id": "140906",
    "label": "Simandolak",
    "value": "Simandolak"
  },
  {
    "id": "1409062014",
    "district_id": "140906",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1409062015",
    "district_id": "140906",
    "label": "Pulaulancang",
    "value": "Pulaulancang"
  },
  {
    "id": "1409062016",
    "district_id": "140906",
    "label": "Benai Kecil",
    "value": "Benai Kecil"
  },
  {
    "id": "1409062021",
    "district_id": "140906",
    "label": "Koto Benai",
    "value": "Koto Benai"
  },
  {
    "id": "1409072001",
    "district_id": "140907",
    "label": "Pulaumungkur",
    "value": "Pulaumungkur"
  },
  {
    "id": "1409072002",
    "district_id": "140907",
    "label": "Telukberingin",
    "value": "Telukberingin"
  },
  {
    "id": "1409072003",
    "district_id": "140907",
    "label": "Pulaurumput",
    "value": "Pulaurumput"
  },
  {
    "id": "1409072004",
    "district_id": "140907",
    "label": "Seberang Gunung",
    "value": "Seberang Gunung"
  },
  {
    "id": "1409072005",
    "district_id": "140907",
    "label": "Teberau Panjang",
    "value": "Teberau Panjang"
  },
  {
    "id": "1409072006",
    "district_id": "140907",
    "label": "Koto Gunung",
    "value": "Koto Gunung"
  },
  {
    "id": "1409072007",
    "district_id": "140907",
    "label": "Gunung",
    "value": "Gunung"
  },
  {
    "id": "1409072008",
    "district_id": "140907",
    "label": "Toar",
    "value": "Toar"
  },
  {
    "id": "1409072009",
    "district_id": "140907",
    "label": "Petapahan",
    "value": "Petapahan"
  },
  {
    "id": "1409072010",
    "district_id": "140907",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1409072011",
    "district_id": "140907",
    "label": "Pisang Berebus",
    "value": "Pisang Berebus"
  },
  {
    "id": "1409072012",
    "district_id": "140907",
    "label": "Lubuk Terentang",
    "value": "Lubuk Terentang"
  },
  {
    "id": "1409072013",
    "district_id": "140907",
    "label": "Siberobah",
    "value": "Siberobah"
  },
  {
    "id": "1409072014",
    "district_id": "140907",
    "label": "Seberang Sungai",
    "value": "Seberang Sungai"
  },
  {
    "id": "1409082001",
    "district_id": "140908",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1409082002",
    "district_id": "140908",
    "label": "Petai",
    "value": "Petai"
  },
  {
    "id": "1409082003",
    "district_id": "140908",
    "label": "Sungaipaku",
    "value": "Sungaipaku"
  },
  {
    "id": "1409082004",
    "district_id": "140908",
    "label": "Tanjungpauh",
    "value": "Tanjungpauh"
  },
  {
    "id": "1409082005",
    "district_id": "140908",
    "label": "Simpang Raya",
    "value": "Simpang Raya"
  },
  {
    "id": "1409082006",
    "district_id": "140908",
    "label": "Sungaibuluh",
    "value": "Sungaibuluh"
  },
  {
    "id": "1409082007",
    "district_id": "140908",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1409082008",
    "district_id": "140908",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1409082009",
    "district_id": "140908",
    "label": "Muara Bahan",
    "value": "Muara Bahan"
  },
  {
    "id": "1409082010",
    "district_id": "140908",
    "label": "Bukitraya",
    "value": "Bukitraya"
  },
  {
    "id": "1409082011",
    "district_id": "140908",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1409082012",
    "district_id": "140908",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1409092001",
    "district_id": "140909",
    "label": "Pasar Baru Pangean",
    "value": "Pasar Baru Pangean"
  },
  {
    "id": "1409092002",
    "district_id": "140909",
    "label": "Koto Pangean",
    "value": "Koto Pangean"
  },
  {
    "id": "1409092003",
    "district_id": "140909",
    "label": "Pulaukumpai",
    "value": "Pulaukumpai"
  },
  {
    "id": "1409092004",
    "district_id": "140909",
    "label": "Pulautongah",
    "value": "Pulautongah"
  },
  {
    "id": "1409092005",
    "district_id": "140909",
    "label": "Pulauderas",
    "value": "Pulauderas"
  },
  {
    "id": "1409092006",
    "district_id": "140909",
    "label": "Telukpauh",
    "value": "Telukpauh"
  },
  {
    "id": "1409092007",
    "district_id": "140909",
    "label": "Tanah Bekali",
    "value": "Tanah Bekali"
  },
  {
    "id": "1409092008",
    "district_id": "140909",
    "label": "Padang Tanggung",
    "value": "Padang Tanggung"
  },
  {
    "id": "1409092009",
    "district_id": "140909",
    "label": "Padang Kunik",
    "value": "Padang Kunik"
  },
  {
    "id": "1409092010",
    "district_id": "140909",
    "label": "Pembatang",
    "value": "Pembatang"
  },
  {
    "id": "1409092011",
    "district_id": "140909",
    "label": "Pauh Angit",
    "value": "Pauh Angit"
  },
  {
    "id": "1409092012",
    "district_id": "140909",
    "label": "Sukaping",
    "value": "Sukaping"
  },
  {
    "id": "1409092013",
    "district_id": "140909",
    "label": "Pulaurengas",
    "value": "Pulaurengas"
  },
  {
    "id": "1409092014",
    "district_id": "140909",
    "label": "Rawang Binjai",
    "value": "Rawang Binjai"
  },
  {
    "id": "1409092015",
    "district_id": "140909",
    "label": "Sako",
    "value": "Sako"
  },
  {
    "id": "1409092016",
    "district_id": "140909",
    "label": "Sungailangsat",
    "value": "Sungailangsat"
  },
  {
    "id": "1409092017",
    "district_id": "140909",
    "label": "Pauh Angit Hulu",
    "value": "Pauh Angit Hulu"
  },
  {
    "id": "1409102001",
    "district_id": "140910",
    "label": "Perhentian Luas",
    "value": "Perhentian Luas"
  },
  {
    "id": "1409102002",
    "district_id": "140910",
    "label": "Logas",
    "value": "Logas"
  },
  {
    "id": "1409102003",
    "district_id": "140910",
    "label": "Lubuk Kebun",
    "value": "Lubuk Kebun"
  },
  {
    "id": "1409102004",
    "district_id": "140910",
    "label": "Sungai Rambai",
    "value": "Sungai Rambai"
  },
  {
    "id": "1409102005",
    "district_id": "140910",
    "label": "Situgal",
    "value": "Situgal"
  },
  {
    "id": "1409102006",
    "district_id": "140910",
    "label": "Rambahan",
    "value": "Rambahan"
  },
  {
    "id": "1409102007",
    "district_id": "140910",
    "label": "Teratak Rendah",
    "value": "Teratak Rendah"
  },
  {
    "id": "1409102008",
    "district_id": "140910",
    "label": "Sikijang",
    "value": "Sikijang"
  },
  {
    "id": "1409102009",
    "district_id": "140910",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1409102010",
    "district_id": "140910",
    "label": "Sako Margasari",
    "value": "Sako Margasari"
  },
  {
    "id": "1409102011",
    "district_id": "140910",
    "label": "Kuantan Sako",
    "value": "Kuantan Sako"
  },
  {
    "id": "1409102012",
    "district_id": "140910",
    "label": "Hulu Teso",
    "value": "Hulu Teso"
  },
  {
    "id": "1409102013",
    "district_id": "140910",
    "label": "Giri Sako",
    "value": "Giri Sako"
  },
  {
    "id": "1409102014",
    "district_id": "140910",
    "label": "Bumi Mulya",
    "value": "Bumi Mulya"
  },
  {
    "id": "1409102015",
    "district_id": "140910",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1409112001",
    "district_id": "140911",
    "label": "Pasar Inuman",
    "value": "Pasar Inuman"
  },
  {
    "id": "1409112002",
    "district_id": "140911",
    "label": "Koto Inuman",
    "value": "Koto Inuman"
  },
  {
    "id": "1409112003",
    "district_id": "140911",
    "label": "Pulaupanjang Hulu",
    "value": "Pulaupanjang Hulu"
  },
  {
    "id": "1409112004",
    "district_id": "140911",
    "label": "Pulaupanjang Hilir",
    "value": "Pulaupanjang Hilir"
  },
  {
    "id": "1409112005",
    "district_id": "140911",
    "label": "Sigaruntang",
    "value": "Sigaruntang"
  },
  {
    "id": "1409112006",
    "district_id": "140911",
    "label": "Pulaubusuk",
    "value": "Pulaubusuk"
  },
  {
    "id": "1409112007",
    "district_id": "140911",
    "label": "Bedeng Sikuran",
    "value": "Bedeng Sikuran"
  },
  {
    "id": "1409112008",
    "district_id": "140911",
    "label": "Banjar Nan Tigo",
    "value": "Banjar Nan Tigo"
  },
  {
    "id": "1409112009",
    "district_id": "140911",
    "label": "Pulausipan",
    "value": "Pulausipan"
  },
  {
    "id": "1409112010",
    "district_id": "140911",
    "label": "Pulaubusuk Jaya",
    "value": "Pulaubusuk Jaya"
  },
  {
    "id": "1409112011",
    "district_id": "140911",
    "label": "Seberang Pulau Busuk",
    "value": "Seberang Pulau Busuk"
  },
  {
    "id": "1409112012",
    "district_id": "140911",
    "label": "Lebuh Lurus",
    "value": "Lebuh Lurus"
  },
  {
    "id": "1409112013",
    "district_id": "140911",
    "label": "Kampung Baru Koto",
    "value": "Kampung Baru Koto"
  },
  {
    "id": "1409112014",
    "district_id": "140911",
    "label": "Ketaping Jaya",
    "value": "Ketaping Jaya"
  },
  {
    "id": "1409122001",
    "district_id": "140912",
    "label": "Lubuk Ambacang",
    "value": "Lubuk Ambacang"
  },
  {
    "id": "1409122002",
    "district_id": "140912",
    "label": "Sungaikelelawar",
    "value": "Sungaikelelawar"
  },
  {
    "id": "1409122003",
    "district_id": "140912",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1409122004",
    "district_id": "140912",
    "label": "Sungaipinang",
    "value": "Sungaipinang"
  },
  {
    "id": "1409122005",
    "district_id": "140912",
    "label": "Sungaialah",
    "value": "Sungaialah"
  },
  {
    "id": "1409122006",
    "district_id": "140912",
    "label": "Koto Kombu",
    "value": "Koto Kombu"
  },
  {
    "id": "1409122007",
    "district_id": "140912",
    "label": "Sumpu",
    "value": "Sumpu"
  },
  {
    "id": "1409122008",
    "district_id": "140912",
    "label": "Mudik Ulo",
    "value": "Mudik Ulo"
  },
  {
    "id": "1409122009",
    "district_id": "140912",
    "label": "Inuman",
    "value": "Inuman"
  },
  {
    "id": "1409122010",
    "district_id": "140912",
    "label": "Tanjungmedang",
    "value": "Tanjungmedang"
  },
  {
    "id": "1409122011",
    "district_id": "140912",
    "label": "Serosa",
    "value": "Serosa"
  },
  {
    "id": "1409122012",
    "district_id": "140912",
    "label": "Sampurago",
    "value": "Sampurago"
  },
  {
    "id": "1409132001",
    "district_id": "140913",
    "label": "Pulaubaru",
    "value": "Pulaubaru"
  },
  {
    "id": "1409132002",
    "district_id": "140913",
    "label": "Pulauberalo",
    "value": "Pulauberalo"
  },
  {
    "id": "1409132003",
    "district_id": "140913",
    "label": "Pulaukulur",
    "value": "Pulaukulur"
  },
  {
    "id": "1409132004",
    "district_id": "140913",
    "label": "Pelukahan",
    "value": "Pelukahan"
  },
  {
    "id": "1409132005",
    "district_id": "140913",
    "label": "Pengalihan",
    "value": "Pengalihan"
  },
  {
    "id": "1409132006",
    "district_id": "140913",
    "label": "Lumbok",
    "value": "Lumbok"
  },
  {
    "id": "1409132007",
    "district_id": "140913",
    "label": "Teratak Jering",
    "value": "Teratak Jering"
  },
  {
    "id": "1409132008",
    "district_id": "140913",
    "label": "Tanjungpisang",
    "value": "Tanjungpisang"
  },
  {
    "id": "1409132009",
    "district_id": "140913",
    "label": "Danau",
    "value": "Danau"
  },
  {
    "id": "1409132010",
    "district_id": "140913",
    "label": "Kasang Limau Sundai",
    "value": "Kasang Limau Sundai"
  },
  {
    "id": "1409132011",
    "district_id": "140913",
    "label": "Koto Rajo",
    "value": "Koto Rajo"
  },
  {
    "id": "1409132012",
    "district_id": "140913",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1409132013",
    "district_id": "140913",
    "label": "Sungaisorik",
    "value": "Sungaisorik"
  },
  {
    "id": "1409132014",
    "district_id": "140913",
    "label": "Rawang Oguang",
    "value": "Rawang Oguang"
  },
  {
    "id": "1409141015",
    "district_id": "140914",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1409142001",
    "district_id": "140914",
    "label": "Pulaukomang Sentajo",
    "value": "Pulaukomang Sentajo"
  },
  {
    "id": "1409142002",
    "district_id": "140914",
    "label": "Muaro Sentajo",
    "value": "Muaro Sentajo"
  },
  {
    "id": "1409142003",
    "district_id": "140914",
    "label": "Koto Sentajo",
    "value": "Koto Sentajo"
  },
  {
    "id": "1409142004",
    "district_id": "140914",
    "label": "Kampungbaru Sentajo",
    "value": "Kampungbaru Sentajo"
  },
  {
    "id": "1409142005",
    "district_id": "140914",
    "label": "Pulaukopung Sentajo",
    "value": "Pulaukopung Sentajo"
  },
  {
    "id": "1409142006",
    "district_id": "140914",
    "label": "Teratak Air Hitam",
    "value": "Teratak Air Hitam"
  },
  {
    "id": "1409142007",
    "district_id": "140914",
    "label": "Seberang Teratak Air Hitam",
    "value": "Seberang Teratak Air Hitam"
  },
  {
    "id": "1409142008",
    "district_id": "140914",
    "label": "Parit Teratak Air Hitam",
    "value": "Parit Teratak Air Hitam"
  },
  {
    "id": "1409142009",
    "district_id": "140914",
    "label": "Jalur Patah",
    "value": "Jalur Patah"
  },
  {
    "id": "1409142010",
    "district_id": "140914",
    "label": "Geringing Baru",
    "value": "Geringing Baru"
  },
  {
    "id": "1409142011",
    "district_id": "140914",
    "label": "Marsawa",
    "value": "Marsawa"
  },
  {
    "id": "1409142012",
    "district_id": "140914",
    "label": "Langsat Hulu",
    "value": "Langsat Hulu"
  },
  {
    "id": "1409142013",
    "district_id": "140914",
    "label": "Muara Langsat",
    "value": "Muara Langsat"
  },
  {
    "id": "1409142014",
    "district_id": "140914",
    "label": "Geringging Jay",
    "value": "Geringging Jay"
  },
  {
    "id": "1409152001",
    "district_id": "140915",
    "label": "Perhentian Sungkai",
    "value": "Perhentian Sungkai"
  },
  {
    "id": "1409152002",
    "district_id": "140915",
    "label": "Ibul",
    "value": "Ibul"
  },
  {
    "id": "1409152003",
    "district_id": "140915",
    "label": "Muarapetai",
    "value": "Muarapetai"
  },
  {
    "id": "1409152004",
    "district_id": "140915",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1409152005",
    "district_id": "140915",
    "label": "Sungaibesar",
    "value": "Sungaibesar"
  },
  {
    "id": "1409152006",
    "district_id": "140915",
    "label": "Setiang",
    "value": "Setiang"
  },
  {
    "id": "1409152007",
    "district_id": "140915",
    "label": "Muaro Tiu Makmur",
    "value": "Muaro Tiu Makmur"
  },
  {
    "id": "1409152008",
    "district_id": "140915",
    "label": "Muaratobek",
    "value": "Muaratobek"
  },
  {
    "id": "1409152009",
    "district_id": "140915",
    "label": "Sungaibesar Hilir",
    "value": "Sungaibesar Hilir"
  },
  {
    "id": "1409152010",
    "district_id": "140915",
    "label": "Kampung Baru Ibul",
    "value": "Kampung Baru Ibul"
  },
  {
    "id": "1410011001",
    "district_id": "141001",
    "label": "Selatpanjang Kota",
    "value": "Selatpanjang Kota"
  },
  {
    "id": "1410011002",
    "district_id": "141001",
    "label": "Selatpanjang Barat",
    "value": "Selatpanjang Barat"
  },
  {
    "id": "1410011003",
    "district_id": "141001",
    "label": "Selatpanjang Selatan",
    "value": "Selatpanjang Selatan"
  },
  {
    "id": "1410011004",
    "district_id": "141001",
    "label": "Selatpanjang Timur",
    "value": "Selatpanjang Timur"
  },
  {
    "id": "1410012005",
    "district_id": "141001",
    "label": "Banglas",
    "value": "Banglas"
  },
  {
    "id": "1410012006",
    "district_id": "141001",
    "label": "Alah Air",
    "value": "Alah Air"
  },
  {
    "id": "1410012007",
    "district_id": "141001",
    "label": "Alah Air Timur",
    "value": "Alah Air Timur"
  },
  {
    "id": "1410012008",
    "district_id": "141001",
    "label": "Sesap",
    "value": "Sesap"
  },
  {
    "id": "1410012016",
    "district_id": "141001",
    "label": "Banglas Barat",
    "value": "Banglas Barat"
  },
  {
    "id": "1410022001",
    "district_id": "141002",
    "label": "Segomeng",
    "value": "Segomeng"
  },
  {
    "id": "1410022002",
    "district_id": "141002",
    "label": "Bantar",
    "value": "Bantar"
  },
  {
    "id": "1410022003",
    "district_id": "141002",
    "label": "Anak Setatah",
    "value": "Anak Setatah"
  },
  {
    "id": "1410022004",
    "district_id": "141002",
    "label": "Lemang",
    "value": "Lemang"
  },
  {
    "id": "1410022005",
    "district_id": "141002",
    "label": "Bokor",
    "value": "Bokor"
  },
  {
    "id": "1410022006",
    "district_id": "141002",
    "label": "Sungai Cina",
    "value": "Sungai Cina"
  },
  {
    "id": "1410022007",
    "district_id": "141002",
    "label": "Melai",
    "value": "Melai"
  },
  {
    "id": "1410022011",
    "district_id": "141002",
    "label": "Telaga Baru",
    "value": "Telaga Baru"
  },
  {
    "id": "1410022012",
    "district_id": "141002",
    "label": "Bina Maju",
    "value": "Bina Maju"
  },
  {
    "id": "1410022015",
    "district_id": "141002",
    "label": "Sialang Pasung",
    "value": "Sialang Pasung"
  },
  {
    "id": "1410022016",
    "district_id": "141002",
    "label": "Permai",
    "value": "Permai"
  },
  {
    "id": "1410022017",
    "district_id": "141002",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "1410032001",
    "district_id": "141003",
    "label": "Tanjung Samak",
    "value": "Tanjung Samak"
  },
  {
    "id": "1410032002",
    "district_id": "141003",
    "label": "Tanjung Medang",
    "value": "Tanjung Medang"
  },
  {
    "id": "1410032004",
    "district_id": "141003",
    "label": "Gemala Sari",
    "value": "Gemala Sari"
  },
  {
    "id": "1410032007",
    "district_id": "141003",
    "label": "Topang",
    "value": "Topang"
  },
  {
    "id": "1410032008",
    "district_id": "141003",
    "label": "Penyagun",
    "value": "Penyagun"
  },
  {
    "id": "1410032010",
    "district_id": "141003",
    "label": "Repan",
    "value": "Repan"
  },
  {
    "id": "1410032011",
    "district_id": "141003",
    "label": "Tanjung Bakau",
    "value": "Tanjung Bakau"
  },
  {
    "id": "1410032012",
    "district_id": "141003",
    "label": "Teluk Samak",
    "value": "Teluk Samak"
  },
  {
    "id": "1410032013",
    "district_id": "141003",
    "label": "Sungai Gayung Kiri",
    "value": "Sungai Gayung Kiri"
  },
  {
    "id": "1410032014",
    "district_id": "141003",
    "label": "Citra Damai",
    "value": "Citra Damai"
  },
  {
    "id": "1410032016",
    "district_id": "141003",
    "label": "Dwi Tunggal",
    "value": "Dwi Tunggal"
  },
  {
    "id": "1410032017",
    "district_id": "141003",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1410032018",
    "district_id": "141003",
    "label": "Tebun",
    "value": "Tebun"
  },
  {
    "id": "1410032020",
    "district_id": "141003",
    "label": "Tanjung Gemuk",
    "value": "Tanjung Gemuk"
  },
  {
    "id": "1410042001",
    "district_id": "141004",
    "label": "Alai",
    "value": "Alai"
  },
  {
    "id": "1410042002",
    "district_id": "141004",
    "label": "Tenan",
    "value": "Tenan"
  },
  {
    "id": "1410042003",
    "district_id": "141004",
    "label": "Insit",
    "value": "Insit"
  },
  {
    "id": "1410042004",
    "district_id": "141004",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1410042005",
    "district_id": "141004",
    "label": "Tanjung Peranap",
    "value": "Tanjung Peranap"
  },
  {
    "id": "1410042006",
    "district_id": "141004",
    "label": "Kundur",
    "value": "Kundur"
  },
  {
    "id": "1410042007",
    "district_id": "141004",
    "label": "Mekong",
    "value": "Mekong"
  },
  {
    "id": "1410042008",
    "district_id": "141004",
    "label": "Batang Malas",
    "value": "Batang Malas"
  },
  {
    "id": "1410042009",
    "district_id": "141004",
    "label": "Mantiasa",
    "value": "Mantiasa"
  },
  {
    "id": "1410042010",
    "district_id": "141004",
    "label": "Gogok Darussalam",
    "value": "Gogok Darussalam"
  },
  {
    "id": "1410042011",
    "district_id": "141004",
    "label": "Maini Darul Aman",
    "value": "Maini Darul Aman"
  },
  {
    "id": "1410042012",
    "district_id": "141004",
    "label": "Mengkikip",
    "value": "Mengkikip"
  },
  {
    "id": "1410042013",
    "district_id": "141004",
    "label": "Alai Selatan",
    "value": "Alai Selatan"
  },
  {
    "id": "1410042014",
    "district_id": "141004",
    "label": "Tanjung Darul Takzim",
    "value": "Tanjung Darul Takzim"
  },
  {
    "id": "1410051001",
    "district_id": "141005",
    "label": "Teluk Belitung",
    "value": "Teluk Belitung"
  },
  {
    "id": "1410052002",
    "district_id": "141005",
    "label": "Meranti Bunting",
    "value": "Meranti Bunting"
  },
  {
    "id": "1410052003",
    "district_id": "141005",
    "label": "Lukit",
    "value": "Lukit"
  },
  {
    "id": "1410052004",
    "district_id": "141005",
    "label": "Bagan Melibur",
    "value": "Bagan Melibur"
  },
  {
    "id": "1410052018",
    "district_id": "141005",
    "label": "Pelantai",
    "value": "Pelantai"
  },
  {
    "id": "1410052019",
    "district_id": "141005",
    "label": "Tanjung Kulim",
    "value": "Tanjung Kulim"
  },
  {
    "id": "1410052021",
    "district_id": "141005",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1410052023",
    "district_id": "141005",
    "label": "Mayang Sari",
    "value": "Mayang Sari"
  },
  {
    "id": "1410052026",
    "district_id": "141005",
    "label": "Sungai Anak Kamal",
    "value": "Sungai Anak Kamal"
  },
  {
    "id": "1410052027",
    "district_id": "141005",
    "label": "Sungai Tengah",
    "value": "Sungai Tengah"
  },
  {
    "id": "1410062001",
    "district_id": "141006",
    "label": "Kuala Merbau",
    "value": "Kuala Merbau"
  },
  {
    "id": "1410062002",
    "district_id": "141006",
    "label": "Renak Dungun",
    "value": "Renak Dungun"
  },
  {
    "id": "1410062003",
    "district_id": "141006",
    "label": "Baran Melintang",
    "value": "Baran Melintang"
  },
  {
    "id": "1410062004",
    "district_id": "141006",
    "label": "Teluk Ketapang",
    "value": "Teluk Ketapang"
  },
  {
    "id": "1410062005",
    "district_id": "141006",
    "label": "Semukut",
    "value": "Semukut"
  },
  {
    "id": "1410062006",
    "district_id": "141006",
    "label": "Centai",
    "value": "Centai"
  },
  {
    "id": "1410062007",
    "district_id": "141006",
    "label": "Tanjungbunga",
    "value": "Tanjungbunga"
  },
  {
    "id": "1410062008",
    "district_id": "141006",
    "label": "Batang Meranti",
    "value": "Batang Meranti"
  },
  {
    "id": "1410062009",
    "district_id": "141006",
    "label": "Pangkalan Balai",
    "value": "Pangkalan Balai"
  },
  {
    "id": "1410062010",
    "district_id": "141006",
    "label": "Padang Kamal",
    "value": "Padang Kamal"
  },
  {
    "id": "1410062011",
    "district_id": "141006",
    "label": "Ketapang Permai",
    "value": "Ketapang Permai"
  },
  {
    "id": "1410072001",
    "district_id": "141007",
    "label": "Lukun",
    "value": "Lukun"
  },
  {
    "id": "1410072002",
    "district_id": "141007",
    "label": "Sungai Tohor",
    "value": "Sungai Tohor"
  },
  {
    "id": "1410072003",
    "district_id": "141007",
    "label": "Nipah Sendanu",
    "value": "Nipah Sendanu"
  },
  {
    "id": "1410072004",
    "district_id": "141007",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1410072005",
    "district_id": "141007",
    "label": "Tanjung Gadai",
    "value": "Tanjung Gadai"
  },
  {
    "id": "1410072006",
    "district_id": "141007",
    "label": "Teluk Buntal",
    "value": "Teluk Buntal"
  },
  {
    "id": "1410072007",
    "district_id": "141007",
    "label": "Kepau Baru",
    "value": "Kepau Baru"
  },
  {
    "id": "1410072008",
    "district_id": "141007",
    "label": "Sungai Tohor Barat",
    "value": "Sungai Tohor Barat"
  },
  {
    "id": "1410072009",
    "district_id": "141007",
    "label": "Sendanu Darul Ihsan",
    "value": "Sendanu Darul Ihsan"
  },
  {
    "id": "1410072010",
    "district_id": "141007",
    "label": "Batin Suir",
    "value": "Batin Suir"
  },
  {
    "id": "1410082001",
    "district_id": "141008",
    "label": "Tanjung Padang",
    "value": "Tanjung Padang"
  },
  {
    "id": "1410082002",
    "district_id": "141008",
    "label": "Putri Puyu",
    "value": "Putri Puyu"
  },
  {
    "id": "1410082003",
    "district_id": "141008",
    "label": "Mekar Delima",
    "value": "Mekar Delima"
  },
  {
    "id": "1410082004",
    "district_id": "141008",
    "label": "Dedap",
    "value": "Dedap"
  },
  {
    "id": "1410082005",
    "district_id": "141008",
    "label": "Kudap",
    "value": "Kudap"
  },
  {
    "id": "1410082006",
    "district_id": "141008",
    "label": "Bandul",
    "value": "Bandul"
  },
  {
    "id": "1410082007",
    "district_id": "141008",
    "label": "Selat Akar",
    "value": "Selat Akar"
  },
  {
    "id": "1410082008",
    "district_id": "141008",
    "label": "Tanjung Pisang",
    "value": "Tanjung Pisang"
  },
  {
    "id": "1410082009",
    "district_id": "141008",
    "label": "Mengkopot",
    "value": "Mengkopot"
  },
  {
    "id": "1410082010",
    "district_id": "141008",
    "label": "Mengkirau",
    "value": "Mengkirau"
  },
  {
    "id": "1410092001",
    "district_id": "141009",
    "label": "Tanjung Kedabu",
    "value": "Tanjung Kedabu"
  },
  {
    "id": "1410092002",
    "district_id": "141009",
    "label": "Beting",
    "value": "Beting"
  },
  {
    "id": "1410092003",
    "district_id": "141009",
    "label": "Sokop",
    "value": "Sokop"
  },
  {
    "id": "1410092004",
    "district_id": "141009",
    "label": "Telesung",
    "value": "Telesung"
  },
  {
    "id": "1410092005",
    "district_id": "141009",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "1410092006",
    "district_id": "141009",
    "label": "Tenggayun Raya",
    "value": "Tenggayun Raya"
  },
  {
    "id": "1410092007",
    "district_id": "141009",
    "label": "Sendaur",
    "value": "Sendaur"
  },
  {
    "id": "1410092008",
    "district_id": "141009",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "1410092009",
    "district_id": "141009",
    "label": "Sonde",
    "value": "Sonde"
  },
  {
    "id": "1410092010",
    "district_id": "141009",
    "label": "Kedabu Rapat",
    "value": "Kedabu Rapat"
  },
  {
    "id": "1410092011",
    "district_id": "141009",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1471011002",
    "district_id": "147101",
    "label": "Jadirejo",
    "value": "Jadirejo"
  },
  {
    "id": "1471011003",
    "district_id": "147101",
    "label": "Kampung Tengah",
    "value": "Kampung Tengah"
  },
  {
    "id": "1471011004",
    "district_id": "147101",
    "label": "Kampung Melayu",
    "value": "Kampung Melayu"
  },
  {
    "id": "1471011005",
    "district_id": "147101",
    "label": "Kedung Sari",
    "value": "Kedung Sari"
  },
  {
    "id": "1471011006",
    "district_id": "147101",
    "label": "Harjosari",
    "value": "Harjosari"
  },
  {
    "id": "1471011007",
    "district_id": "147101",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1471011008",
    "district_id": "147101",
    "label": "Pulau Karomah",
    "value": "Pulau Karomah"
  },
  {
    "id": "1471021001",
    "district_id": "147102",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1471021002",
    "district_id": "147102",
    "label": "Sumahilang",
    "value": "Sumahilang"
  },
  {
    "id": "1471021003",
    "district_id": "147102",
    "label": "Tanah Datar",
    "value": "Tanah Datar"
  },
  {
    "id": "1471021004",
    "district_id": "147102",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1471021005",
    "district_id": "147102",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "1471021006",
    "district_id": "147102",
    "label": "Kota Tinggi",
    "value": "Kota Tinggi"
  },
  {
    "id": "1471031001",
    "district_id": "147103",
    "label": "Cinta Raja",
    "value": "Cinta Raja"
  },
  {
    "id": "1471031002",
    "district_id": "147103",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1471031003",
    "district_id": "147103",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1471041001",
    "district_id": "147104",
    "label": "Rintis",
    "value": "Rintis"
  },
  {
    "id": "1471041002",
    "district_id": "147104",
    "label": "Tanjung RHU",
    "value": "Tanjung RHU"
  },
  {
    "id": "1471041003",
    "district_id": "147104",
    "label": "Pesisir",
    "value": "Pesisir"
  },
  {
    "id": "1471041004",
    "district_id": "147104",
    "label": "Sekip",
    "value": "Sekip"
  },
  {
    "id": "1471051001",
    "district_id": "147105",
    "label": "Padang Bulan",
    "value": "Padang Bulan"
  },
  {
    "id": "1471051002",
    "district_id": "147105",
    "label": "Sago",
    "value": "Sago"
  },
  {
    "id": "1471051003",
    "district_id": "147105",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1471051004",
    "district_id": "147105",
    "label": "Kampung Dalam",
    "value": "Kampung Dalam"
  },
  {
    "id": "1471051005",
    "district_id": "147105",
    "label": "Kampung Bandar",
    "value": "Kampung Bandar"
  },
  {
    "id": "1471051006",
    "district_id": "147105",
    "label": "Padang Terubuk",
    "value": "Padang Terubuk"
  },
  {
    "id": "1471061003",
    "district_id": "147106",
    "label": "Rumbai Bukit Lembah Damai",
    "value": "Rumbai Bukit Lembah Damai"
  },
  {
    "id": "1471061005",
    "district_id": "147106",
    "label": "Muarafajar Timur",
    "value": "Muarafajar Timur"
  },
  {
    "id": "1471061006",
    "district_id": "147106",
    "label": "Umban Sari",
    "value": "Umban Sari"
  },
  {
    "id": "1471061008",
    "district_id": "147106",
    "label": "Sri Meranti",
    "value": "Sri Meranti"
  },
  {
    "id": "1471061009",
    "district_id": "147106",
    "label": "Palas",
    "value": "Palas"
  },
  {
    "id": "1471061010",
    "district_id": "147106",
    "label": "Muarafajar Barat",
    "value": "Muarafajar Barat"
  },
  {
    "id": "1471061011",
    "district_id": "147106",
    "label": "Rantaupanjang",
    "value": "Rantaupanjang"
  },
  {
    "id": "1471061012",
    "district_id": "147106",
    "label": "Maharani",
    "value": "Maharani"
  },
  {
    "id": "1471061013",
    "district_id": "147106",
    "label": "Agrowisata",
    "value": "Agrowisata"
  },
  {
    "id": "1471071005",
    "district_id": "147107",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1471071006",
    "district_id": "147107",
    "label": "Tangkerang Selatan",
    "value": "Tangkerang Selatan"
  },
  {
    "id": "1471071008",
    "district_id": "147107",
    "label": "Tangkerang Utara",
    "value": "Tangkerang Utara"
  },
  {
    "id": "1471071011",
    "district_id": "147107",
    "label": "Tangkerang Labuai",
    "value": "Tangkerang Labuai"
  },
  {
    "id": "1471071012",
    "district_id": "147107",
    "label": "Airdingin",
    "value": "Airdingin"
  },
  {
    "id": "1471081001",
    "district_id": "147108",
    "label": "Simpangbaru",
    "value": "Simpangbaru"
  },
  {
    "id": "1471081006",
    "district_id": "147108",
    "label": "Sidomulyo Barat",
    "value": "Sidomulyo Barat"
  },
  {
    "id": "1471081007",
    "district_id": "147108",
    "label": "Tuahkarya",
    "value": "Tuahkarya"
  },
  {
    "id": "1471081008",
    "district_id": "147108",
    "label": "Delima",
    "value": "Delima"
  },
  {
    "id": "1471081009",
    "district_id": "147108",
    "label": "Tobekgodang",
    "value": "Tobekgodang"
  },
  {
    "id": "1471081010",
    "district_id": "147108",
    "label": "Binawidya",
    "value": "Binawidya"
  },
  {
    "id": "1471081011",
    "district_id": "147108",
    "label": "Airputih",
    "value": "Airputih"
  },
  {
    "id": "1471081012",
    "district_id": "147108",
    "label": "Tuahmadani",
    "value": "Tuahmadani"
  },
  {
    "id": "1471081013",
    "district_id": "147108",
    "label": "Sialangmunggu",
    "value": "Sialangmunggu"
  },
  {
    "id": "1471091001",
    "district_id": "147109",
    "label": "Tangkerang Barat",
    "value": "Tangkerang Barat"
  },
  {
    "id": "1471091002",
    "district_id": "147109",
    "label": "Tangkerang Tengah",
    "value": "Tangkerang Tengah"
  },
  {
    "id": "1471091003",
    "district_id": "147109",
    "label": "Sidomulyo Timur",
    "value": "Sidomulyo Timur"
  },
  {
    "id": "1471091004",
    "district_id": "147109",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "1471091005",
    "district_id": "147109",
    "label": "Maharatu",
    "value": "Maharatu"
  },
  {
    "id": "1471091006",
    "district_id": "147109",
    "label": "Perhentianmarpoyan",
    "value": "Perhentianmarpoyan"
  },
  {
    "id": "1471101001",
    "district_id": "147110",
    "label": "Kulim",
    "value": "Kulim"
  },
  {
    "id": "1471101002",
    "district_id": "147110",
    "label": "Bencahlesung",
    "value": "Bencahlesung"
  },
  {
    "id": "1471101003",
    "district_id": "147110",
    "label": "Tangkerang Timur",
    "value": "Tangkerang Timur"
  },
  {
    "id": "1471101004",
    "district_id": "147110",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "1471101005",
    "district_id": "147110",
    "label": "Bambukuning",
    "value": "Bambukuning"
  },
  {
    "id": "1471101006",
    "district_id": "147110",
    "label": "Pebatuan",
    "value": "Pebatuan"
  },
  {
    "id": "1471101007",
    "district_id": "147110",
    "label": "Sialangrampai",
    "value": "Sialangrampai"
  },
  {
    "id": "1471101008",
    "district_id": "147110",
    "label": "Mentangor",
    "value": "Mentangor"
  },
  {
    "id": "1471101009",
    "district_id": "147110",
    "label": "Pematangkapau",
    "value": "Pematangkapau"
  },
  {
    "id": "1471101010",
    "district_id": "147110",
    "label": "Melebung",
    "value": "Melebung"
  },
  {
    "id": "1471101011",
    "district_id": "147110",
    "label": "Industritenayan",
    "value": "Industritenayan"
  },
  {
    "id": "1471101012",
    "district_id": "147110",
    "label": "Sialangsakti",
    "value": "Sialangsakti"
  },
  {
    "id": "1471101013",
    "district_id": "147110",
    "label": "Tuahnegeri",
    "value": "Tuahnegeri"
  },
  {
    "id": "1471111001",
    "district_id": "147111",
    "label": "Tampan",
    "value": "Tampan"
  },
  {
    "id": "1471111002",
    "district_id": "147111",
    "label": "Labuh Baru Timur",
    "value": "Labuh Baru Timur"
  },
  {
    "id": "1471111003",
    "district_id": "147111",
    "label": "Labuh Baru Barat",
    "value": "Labuh Baru Barat"
  },
  {
    "id": "1471111004",
    "district_id": "147111",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1471111005",
    "district_id": "147111",
    "label": "Bandarraya",
    "value": "Bandarraya"
  },
  {
    "id": "1471111006",
    "district_id": "147111",
    "label": "Sungaisibam",
    "value": "Sungaisibam"
  },
  {
    "id": "1471111007",
    "district_id": "147111",
    "label": "Tirtasiak",
    "value": "Tirtasiak"
  },
  {
    "id": "1471121001",
    "district_id": "147112",
    "label": "Meranti Pandak",
    "value": "Meranti Pandak"
  },
  {
    "id": "1471121002",
    "district_id": "147112",
    "label": "Limbungan",
    "value": "Limbungan"
  },
  {
    "id": "1471121003",
    "district_id": "147112",
    "label": "Lembah Sari",
    "value": "Lembah Sari"
  },
  {
    "id": "1471121004",
    "district_id": "147112",
    "label": "Lembah Damai",
    "value": "Lembah Damai"
  },
  {
    "id": "1471121005",
    "district_id": "147112",
    "label": "Tebing Tinggi Okura",
    "value": "Tebing Tinggi Okura"
  },
  {
    "id": "1471121006",
    "district_id": "147112",
    "label": "Limbungan Baru",
    "value": "Limbungan Baru"
  },
  {
    "id": "1471121007",
    "district_id": "147112",
    "label": "Sungaiambang",
    "value": "Sungaiambang"
  },
  {
    "id": "1471121008",
    "district_id": "147112",
    "label": "Sungaiukai",
    "value": "Sungaiukai"
  },
  {
    "id": "1472011003",
    "district_id": "147201",
    "label": "Pangkalan Sesai",
    "value": "Pangkalan Sesai"
  },
  {
    "id": "1472011006",
    "district_id": "147201",
    "label": "Purnama",
    "value": "Purnama"
  },
  {
    "id": "1472011008",
    "district_id": "147201",
    "label": "Bagan Keladi",
    "value": "Bagan Keladi"
  },
  {
    "id": "1472011009",
    "district_id": "147201",
    "label": "Simapang Tetap Darul Ichsan",
    "value": "Simapang Tetap Darul Ichsan"
  },
  {
    "id": "1472021002",
    "district_id": "147202",
    "label": "Teluk Binjai",
    "value": "Teluk Binjai"
  },
  {
    "id": "1472021003",
    "district_id": "147202",
    "label": "Tanjung Palas",
    "value": "Tanjung Palas"
  },
  {
    "id": "1472021004",
    "district_id": "147202",
    "label": "Jaya Mukti",
    "value": "Jaya Mukti"
  },
  {
    "id": "1472021006",
    "district_id": "147202",
    "label": "Buluh Kasap",
    "value": "Buluh Kasap"
  },
  {
    "id": "1472021009",
    "district_id": "147202",
    "label": "Bukit Batrem",
    "value": "Bukit Batrem"
  },
  {
    "id": "1472031001",
    "district_id": "147203",
    "label": "Bukit Nanas",
    "value": "Bukit Nanas"
  },
  {
    "id": "1472031002",
    "district_id": "147203",
    "label": "Kayu Kapur",
    "value": "Kayu Kapur"
  },
  {
    "id": "1472031003",
    "district_id": "147203",
    "label": "Gurun Panjang",
    "value": "Gurun Panjang"
  },
  {
    "id": "1472031004",
    "district_id": "147203",
    "label": "Bagan Besar",
    "value": "Bagan Besar"
  },
  {
    "id": "1472031005",
    "district_id": "147203",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1472041001",
    "district_id": "147204",
    "label": "Lubuk Gaung",
    "value": "Lubuk Gaung"
  },
  {
    "id": "1472041002",
    "district_id": "147204",
    "label": "Tanjung Penyebal",
    "value": "Tanjung Penyebal"
  },
  {
    "id": "1472041003",
    "district_id": "147204",
    "label": "Bangsal Aceh",
    "value": "Bangsal Aceh"
  },
  {
    "id": "1472041004",
    "district_id": "147204",
    "label": "Basilam Baru",
    "value": "Basilam Baru"
  },
  {
    "id": "1472041005",
    "district_id": "147204",
    "label": "Batu Teritip",
    "value": "Batu Teritip"
  },
  {
    "id": "1472051001",
    "district_id": "147205",
    "label": "Teluk Makmur",
    "value": "Teluk Makmur"
  },
  {
    "id": "1472051002",
    "district_id": "147205",
    "label": "Mundam",
    "value": "Mundam"
  },
  {
    "id": "1472051003",
    "district_id": "147205",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "1472051004",
    "district_id": "147205",
    "label": "Pelintung",
    "value": "Pelintung"
  },
  {
    "id": "1472061001",
    "district_id": "147206",
    "label": "Laksamana",
    "value": "Laksamana"
  },
  {
    "id": "1472061002",
    "district_id": "147206",
    "label": "Rimba Sekampung",
    "value": "Rimba Sekampung"
  },
  {
    "id": "1472061003",
    "district_id": "147206",
    "label": "Dumai Kota",
    "value": "Dumai Kota"
  },
  {
    "id": "1472061004",
    "district_id": "147206",
    "label": "Bintan",
    "value": "Bintan"
  },
  {
    "id": "1472061005",
    "district_id": "147206",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1472071001",
    "district_id": "147207",
    "label": "Ratu Sima",
    "value": "Ratu Sima"
  },
  {
    "id": "1472071002",
    "district_id": "147207",
    "label": "Bukit Timah",
    "value": "Bukit Timah"
  },
  {
    "id": "1472071003",
    "district_id": "147207",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1472071004",
    "district_id": "147207",
    "label": "Bukit Datuk",
    "value": "Bukit Datuk"
  },
  {
    "id": "1472071005",
    "district_id": "147207",
    "label": "Bumi Ayu",
    "value": "Bumi Ayu"
  },
  {
    "id": "1501011004",
    "district_id": "150101",
    "label": "Lempur Tengah",
    "value": "Lempur Tengah"
  },
  {
    "id": "1501012001",
    "district_id": "150101",
    "label": "Selam Paung",
    "value": "Selam Paung"
  },
  {
    "id": "1501012002",
    "district_id": "150101",
    "label": "Air Mumu",
    "value": "Air Mumu"
  },
  {
    "id": "1501012003",
    "district_id": "150101",
    "label": "Lempur Mudik",
    "value": "Lempur Mudik"
  },
  {
    "id": "1501012005",
    "district_id": "150101",
    "label": "Masgo",
    "value": "Masgo"
  },
  {
    "id": "1501012006",
    "district_id": "150101",
    "label": "Lempur Hilir",
    "value": "Lempur Hilir"
  },
  {
    "id": "1501012011",
    "district_id": "150101",
    "label": "Kebun Baru",
    "value": "Kebun Baru"
  },
  {
    "id": "1501012012",
    "district_id": "150101",
    "label": "Perikan Tengah",
    "value": "Perikan Tengah"
  },
  {
    "id": "1501012013",
    "district_id": "150101",
    "label": "Dusun Baru Lempur",
    "value": "Dusun Baru Lempur"
  },
  {
    "id": "1501012016",
    "district_id": "150101",
    "label": "Sungaihangat",
    "value": "Sungaihangat"
  },
  {
    "id": "1501012018",
    "district_id": "150101",
    "label": "Manjunto Lempur",
    "value": "Manjunto Lempur"
  },
  {
    "id": "1501012019",
    "district_id": "150101",
    "label": "Kebun Lima",
    "value": "Kebun Lima"
  },
  {
    "id": "1501022001",
    "district_id": "150102",
    "label": "Sanggaran Agung",
    "value": "Sanggaran Agung"
  },
  {
    "id": "1501022002",
    "district_id": "150102",
    "label": "Kota Baru Sanggaran Agung",
    "value": "Kota Baru Sanggaran Agung"
  },
  {
    "id": "1501022003",
    "district_id": "150102",
    "label": "Pendung Talang Genting",
    "value": "Pendung Talang Genting"
  },
  {
    "id": "1501022004",
    "district_id": "150102",
    "label": "Seleman",
    "value": "Seleman"
  },
  {
    "id": "1501022005",
    "district_id": "150102",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1501022006",
    "district_id": "150102",
    "label": "Cupak",
    "value": "Cupak"
  },
  {
    "id": "1501022007",
    "district_id": "150102",
    "label": "Tanjungtanah",
    "value": "Tanjungtanah"
  },
  {
    "id": "1501022008",
    "district_id": "150102",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1501022009",
    "district_id": "150102",
    "label": "Koto Iman",
    "value": "Koto Iman"
  },
  {
    "id": "1501022010",
    "district_id": "150102",
    "label": "Ujung Pasir",
    "value": "Ujung Pasir"
  },
  {
    "id": "1501022011",
    "district_id": "150102",
    "label": "Koto Petai",
    "value": "Koto Petai"
  },
  {
    "id": "1501022012",
    "district_id": "150102",
    "label": "Koto Salak",
    "value": "Koto Salak"
  },
  {
    "id": "1501022013",
    "district_id": "150102",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1501022014",
    "district_id": "150102",
    "label": "Talang Kemulun",
    "value": "Talang Kemulun"
  },
  {
    "id": "1501022015",
    "district_id": "150102",
    "label": "Dusun Baru Tanjung Tanah",
    "value": "Dusun Baru Tanjung Tanah"
  },
  {
    "id": "1501022016",
    "district_id": "150102",
    "label": "Pasar Sore Seleman",
    "value": "Pasar Sore Seleman"
  },
  {
    "id": "1501022017",
    "district_id": "150102",
    "label": "Koto Tuo Ujung Pasir",
    "value": "Koto Tuo Ujung Pasir"
  },
  {
    "id": "1501022018",
    "district_id": "150102",
    "label": "Tanjungharapan",
    "value": "Tanjungharapan"
  },
  {
    "id": "1501022019",
    "district_id": "150102",
    "label": "Agung Koto Iman",
    "value": "Agung Koto Iman"
  },
  {
    "id": "1501042001",
    "district_id": "150104",
    "label": "Kayu Aro Ambai",
    "value": "Kayu Aro Ambai"
  },
  {
    "id": "1501042002",
    "district_id": "150104",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1501042004",
    "district_id": "150104",
    "label": "Semerah",
    "value": "Semerah"
  },
  {
    "id": "1501042005",
    "district_id": "150104",
    "label": "Sebukar",
    "value": "Sebukar"
  },
  {
    "id": "1501042008",
    "district_id": "150104",
    "label": "Hiang Tinggi",
    "value": "Hiang Tinggi"
  },
  {
    "id": "1501042009",
    "district_id": "150104",
    "label": "Ambai Atas",
    "value": "Ambai Atas"
  },
  {
    "id": "1501042010",
    "district_id": "150104",
    "label": "Tanjungmudo",
    "value": "Tanjungmudo"
  },
  {
    "id": "1501042011",
    "district_id": "150104",
    "label": "Pendung Tengah",
    "value": "Pendung Tengah"
  },
  {
    "id": "1501042012",
    "district_id": "150104",
    "label": "Pendung Hilir",
    "value": "Pendung Hilir"
  },
  {
    "id": "1501042013",
    "district_id": "150104",
    "label": "Koto Baru Hiang",
    "value": "Koto Baru Hiang"
  },
  {
    "id": "1501042014",
    "district_id": "150104",
    "label": "Betung Kuning",
    "value": "Betung Kuning"
  },
  {
    "id": "1501042025",
    "district_id": "150104",
    "label": "Pondok Beringin",
    "value": "Pondok Beringin"
  },
  {
    "id": "1501042026",
    "district_id": "150104",
    "label": "Ambai Bawah",
    "value": "Ambai Bawah"
  },
  {
    "id": "1501042028",
    "district_id": "150104",
    "label": "Hiang Karya",
    "value": "Hiang Karya"
  },
  {
    "id": "1501042029",
    "district_id": "150104",
    "label": "Hiang Lestari",
    "value": "Hiang Lestari"
  },
  {
    "id": "1501042030",
    "district_id": "150104",
    "label": "Angkasa Pura",
    "value": "Angkasa Pura"
  },
  {
    "id": "1501042031",
    "district_id": "150104",
    "label": "Hiang Sakti",
    "value": "Hiang Sakti"
  },
  {
    "id": "1501042032",
    "district_id": "150104",
    "label": "Baru Semerah",
    "value": "Baru Semerah"
  },
  {
    "id": "1501042033",
    "district_id": "150104",
    "label": "Koto Sekilan Ambai",
    "value": "Koto Sekilan Ambai"
  },
  {
    "id": "1501042034",
    "district_id": "150104",
    "label": "Penawar Tinggi",
    "value": "Penawar Tinggi"
  },
  {
    "id": "1501052002",
    "district_id": "150105",
    "label": "Baru Semurup",
    "value": "Baru Semurup"
  },
  {
    "id": "1501052004",
    "district_id": "150105",
    "label": "Koto Dua Lama",
    "value": "Koto Dua Lama"
  },
  {
    "id": "1501052012",
    "district_id": "150105",
    "label": "Koto Di Air",
    "value": "Koto Di Air"
  },
  {
    "id": "1501052013",
    "district_id": "150105",
    "label": "Pasar Semurup",
    "value": "Pasar Semurup"
  },
  {
    "id": "1501052014",
    "district_id": "150105",
    "label": "Balai",
    "value": "Balai"
  },
  {
    "id": "1501052015",
    "district_id": "150105",
    "label": "Koto Majidin Hilir",
    "value": "Koto Majidin Hilir"
  },
  {
    "id": "1501052016",
    "district_id": "150105",
    "label": "Pendung Hilir",
    "value": "Pendung Hilir"
  },
  {
    "id": "1501052018",
    "district_id": "150105",
    "label": "Pendung Mudik",
    "value": "Pendung Mudik"
  },
  {
    "id": "1501052022",
    "district_id": "150105",
    "label": "Air Tenang",
    "value": "Air Tenang"
  },
  {
    "id": "1501052024",
    "district_id": "150105",
    "label": "Muara Semerah",
    "value": "Muara Semerah"
  },
  {
    "id": "1501052029",
    "district_id": "150105",
    "label": "Koto Majidin Mudik",
    "value": "Koto Majidin Mudik"
  },
  {
    "id": "1501052035",
    "district_id": "150105",
    "label": "Muara Semerah Mudik",
    "value": "Muara Semerah Mudik"
  },
  {
    "id": "1501052036",
    "district_id": "150105",
    "label": "Sawahan Koto Majidin",
    "value": "Sawahan Koto Majidin"
  },
  {
    "id": "1501052037",
    "district_id": "150105",
    "label": "Sawahan Jaya",
    "value": "Sawahan Jaya"
  },
  {
    "id": "1501052038",
    "district_id": "150105",
    "label": "Koto Majidin Di Air",
    "value": "Koto Majidin Di Air"
  },
  {
    "id": "1501052039",
    "district_id": "150105",
    "label": "Pendung Tengah",
    "value": "Pendung Tengah"
  },
  {
    "id": "1501061009",
    "district_id": "150106",
    "label": "Siulak Deras",
    "value": "Siulak Deras"
  },
  {
    "id": "1501062010",
    "district_id": "150106",
    "label": "Sungaibetung Hilir",
    "value": "Sungaibetung Hilir"
  },
  {
    "id": "1501062011",
    "district_id": "150106",
    "label": "Sungaibatu Gantih",
    "value": "Sungaibatu Gantih"
  },
  {
    "id": "1501062012",
    "district_id": "150106",
    "label": "Tanjunggenting",
    "value": "Tanjunggenting"
  },
  {
    "id": "1501062013",
    "district_id": "150106",
    "label": "Simpang Tutup",
    "value": "Simpang Tutup"
  },
  {
    "id": "1501062014",
    "district_id": "150106",
    "label": "Suko Pangkat",
    "value": "Suko Pangkat"
  },
  {
    "id": "1501062015",
    "district_id": "150106",
    "label": "Sungaibetung Mudik",
    "value": "Sungaibetung Mudik"
  },
  {
    "id": "1501062016",
    "district_id": "150106",
    "label": "Danautinggi",
    "value": "Danautinggi"
  },
  {
    "id": "1501062017",
    "district_id": "150106",
    "label": "Sungaigelampeh",
    "value": "Sungaigelampeh"
  },
  {
    "id": "1501062018",
    "district_id": "150106",
    "label": "Siulak Deras Mudik",
    "value": "Siulak Deras Mudik"
  },
  {
    "id": "1501062019",
    "district_id": "150106",
    "label": "Siulak Tenang",
    "value": "Siulak Tenang"
  },
  {
    "id": "1501062039",
    "district_id": "150106",
    "label": "Sungai Batu Gantih Hilir",
    "value": "Sungai Batu Gantih Hilir"
  },
  {
    "id": "1501062040",
    "district_id": "150106",
    "label": "Baru Sungai Betung Mudik",
    "value": "Baru Sungai Betung Mudik"
  },
  {
    "id": "1501062041",
    "district_id": "150106",
    "label": "Tanjunggenting Mudik",
    "value": "Tanjunggenting Mudik"
  },
  {
    "id": "1501062042",
    "district_id": "150106",
    "label": "Air Betung",
    "value": "Air Betung"
  },
  {
    "id": "1501062043",
    "district_id": "150106",
    "label": "Ujung Ladang",
    "value": "Ujung Ladang"
  },
  {
    "id": "1501072004",
    "district_id": "150107",
    "label": "Tarutung",
    "value": "Tarutung"
  },
  {
    "id": "1501072006",
    "district_id": "150107",
    "label": "Lubuk Paku",
    "value": "Lubuk Paku"
  },
  {
    "id": "1501072007",
    "district_id": "150107",
    "label": "Tamiai",
    "value": "Tamiai"
  },
  {
    "id": "1501072008",
    "district_id": "150107",
    "label": "Pematang Lingkung",
    "value": "Pematang Lingkung"
  },
  {
    "id": "1501072009",
    "district_id": "150107",
    "label": "Pasar Tamiai",
    "value": "Pasar Tamiai"
  },
  {
    "id": "1501072010",
    "district_id": "150107",
    "label": "Seberang Merangin",
    "value": "Seberang Merangin"
  },
  {
    "id": "1501072011",
    "district_id": "150107",
    "label": "Baru Pulau Sangkar",
    "value": "Baru Pulau Sangkar"
  },
  {
    "id": "1501072013",
    "district_id": "150107",
    "label": "Batang Merangin",
    "value": "Batang Merangin"
  },
  {
    "id": "1501072014",
    "district_id": "150107",
    "label": "Muara Hemat",
    "value": "Muara Hemat"
  },
  {
    "id": "1501082001",
    "district_id": "150108",
    "label": "Jujun",
    "value": "Jujun"
  },
  {
    "id": "1501082002",
    "district_id": "150108",
    "label": "Talang Lindung",
    "value": "Talang Lindung"
  },
  {
    "id": "1501082003",
    "district_id": "150108",
    "label": "Keluru",
    "value": "Keluru"
  },
  {
    "id": "1501082004",
    "district_id": "150108",
    "label": "Pidung",
    "value": "Pidung"
  },
  {
    "id": "1501082005",
    "district_id": "150108",
    "label": "Tanjungbatu",
    "value": "Tanjungbatu"
  },
  {
    "id": "1501082006",
    "district_id": "150108",
    "label": "Benik",
    "value": "Benik"
  },
  {
    "id": "1501082007",
    "district_id": "150108",
    "label": "Kt Tuo P. Tengah",
    "value": "Kt Tuo P. Tengah"
  },
  {
    "id": "1501082008",
    "district_id": "150108",
    "label": "Kt. Dian P. Tengah",
    "value": "Kt. Dian P. Tengah"
  },
  {
    "id": "1501082009",
    "district_id": "150108",
    "label": "Tanjungpauh Mudik",
    "value": "Tanjungpauh Mudik"
  },
  {
    "id": "1501082010",
    "district_id": "150108",
    "label": "Tanjungpauh Hilir",
    "value": "Tanjungpauh Hilir"
  },
  {
    "id": "1501082011",
    "district_id": "150108",
    "label": "Semerap",
    "value": "Semerap"
  },
  {
    "id": "1501082012",
    "district_id": "150108",
    "label": "Lempur Danau",
    "value": "Lempur Danau"
  },
  {
    "id": "1501082013",
    "district_id": "150108",
    "label": "Dsn. Baru P. Tengah",
    "value": "Dsn. Baru P. Tengah"
  },
  {
    "id": "1501082014",
    "district_id": "150108",
    "label": "Koto Baru Semerap",
    "value": "Koto Baru Semerap"
  },
  {
    "id": "1501082015",
    "district_id": "150108",
    "label": "Koto Patah",
    "value": "Koto Patah"
  },
  {
    "id": "1501082016",
    "district_id": "150108",
    "label": "Punai Merindu",
    "value": "Punai Merindu"
  },
  {
    "id": "1501082017",
    "district_id": "150108",
    "label": "Pondok Saguang",
    "value": "Pondok Saguang"
  },
  {
    "id": "1501082018",
    "district_id": "150108",
    "label": "Telago",
    "value": "Telago"
  },
  {
    "id": "1501082019",
    "district_id": "150108",
    "label": "Koto Agung",
    "value": "Koto Agung"
  },
  {
    "id": "1501082020",
    "district_id": "150108",
    "label": "Pancuran Tiga",
    "value": "Pancuran Tiga"
  },
  {
    "id": "1501082021",
    "district_id": "150108",
    "label": "Permai Baru",
    "value": "Permai Baru"
  },
  {
    "id": "1501082022",
    "district_id": "150108",
    "label": "Pulautengah",
    "value": "Pulautengah"
  },
  {
    "id": "1501082023",
    "district_id": "150108",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1501082024",
    "district_id": "150108",
    "label": "Pasar Semerap",
    "value": "Pasar Semerap"
  },
  {
    "id": "1501082025",
    "district_id": "150108",
    "label": "Jembatan Merah Pulau Tengah",
    "value": "Jembatan Merah Pulau Tengah"
  },
  {
    "id": "1501082026",
    "district_id": "150108",
    "label": "Limok Manaih Pulau Tengah",
    "value": "Limok Manaih Pulau Tengah"
  },
  {
    "id": "1501082027",
    "district_id": "150108",
    "label": "Pasar Jujun",
    "value": "Pasar Jujun"
  },
  {
    "id": "1501082028",
    "district_id": "150108",
    "label": "Pancuran Bangko",
    "value": "Pancuran Bangko"
  },
  {
    "id": "1501082029",
    "district_id": "150108",
    "label": "Sumur Jauh",
    "value": "Sumur Jauh"
  },
  {
    "id": "1501082030",
    "district_id": "150108",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1501082031",
    "district_id": "150108",
    "label": "Bukitpulai",
    "value": "Bukitpulai"
  },
  {
    "id": "1501082032",
    "district_id": "150108",
    "label": "Serumpun Pauh",
    "value": "Serumpun Pauh"
  },
  {
    "id": "1501092010",
    "district_id": "150109",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1501092011",
    "district_id": "150109",
    "label": "Sungaitanduk",
    "value": "Sungaitanduk"
  },
  {
    "id": "1501092012",
    "district_id": "150109",
    "label": "Kersik Tuo",
    "value": "Kersik Tuo"
  },
  {
    "id": "1501092013",
    "district_id": "150109",
    "label": "Sangir",
    "value": "Sangir"
  },
  {
    "id": "1501092014",
    "district_id": "150109",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1501092015",
    "district_id": "150109",
    "label": "Koto Panjang",
    "value": "Koto Panjang"
  },
  {
    "id": "1501092016",
    "district_id": "150109",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1501092017",
    "district_id": "150109",
    "label": "Sungaibendung Air",
    "value": "Sungaibendung Air"
  },
  {
    "id": "1501092022",
    "district_id": "150109",
    "label": "Sungaisampun",
    "value": "Sungaisampun"
  },
  {
    "id": "1501092024",
    "district_id": "150109",
    "label": "Bedeng Baru",
    "value": "Bedeng Baru"
  },
  {
    "id": "1501092025",
    "district_id": "150109",
    "label": "Batang Sangir",
    "value": "Batang Sangir"
  },
  {
    "id": "1501092030",
    "district_id": "150109",
    "label": "Sungaidalam",
    "value": "Sungaidalam"
  },
  {
    "id": "1501092038",
    "district_id": "150109",
    "label": "Koto Periang",
    "value": "Koto Periang"
  },
  {
    "id": "1501092039",
    "district_id": "150109",
    "label": "Lindung Jaya",
    "value": "Lindung Jaya"
  },
  {
    "id": "1501092041",
    "district_id": "150109",
    "label": "Renah Kasah",
    "value": "Renah Kasah"
  },
  {
    "id": "1501092042",
    "district_id": "150109",
    "label": "Pasar Sungai Tanduk",
    "value": "Pasar Sungai Tanduk"
  },
  {
    "id": "1501092043",
    "district_id": "150109",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1501092045",
    "district_id": "150109",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1501092047",
    "district_id": "150109",
    "label": "Sangir Tengah",
    "value": "Sangir Tengah"
  },
  {
    "id": "1501092048",
    "district_id": "150109",
    "label": "Tanjungbungo",
    "value": "Tanjungbungo"
  },
  {
    "id": "1501092049",
    "district_id": "150109",
    "label": "Bendung Air Timur",
    "value": "Bendung Air Timur"
  },
  {
    "id": "1501112001",
    "district_id": "150111",
    "label": "Kemantan Tinggi",
    "value": "Kemantan Tinggi"
  },
  {
    "id": "1501112002",
    "district_id": "150111",
    "label": "Kemantan Kebalai",
    "value": "Kemantan Kebalai"
  },
  {
    "id": "1501112003",
    "district_id": "150111",
    "label": "Kemantan Darat",
    "value": "Kemantan Darat"
  },
  {
    "id": "1501112004",
    "district_id": "150111",
    "label": "Sungaiabu",
    "value": "Sungaiabu"
  },
  {
    "id": "1501112005",
    "district_id": "150111",
    "label": "Pondok Sungaiabu",
    "value": "Pondok Sungaiabu"
  },
  {
    "id": "1501112006",
    "district_id": "150111",
    "label": "Koto Tebat",
    "value": "Koto Tebat"
  },
  {
    "id": "1501112007",
    "district_id": "150111",
    "label": "Sungaimedang",
    "value": "Sungaimedang"
  },
  {
    "id": "1501112008",
    "district_id": "150111",
    "label": "Air Hangat",
    "value": "Air Hangat"
  },
  {
    "id": "1501112009",
    "district_id": "150111",
    "label": "Baru Sungai Tutung",
    "value": "Baru Sungai Tutung"
  },
  {
    "id": "1501112010",
    "district_id": "150111",
    "label": "Pungut Tengah",
    "value": "Pungut Tengah"
  },
  {
    "id": "1501112011",
    "district_id": "150111",
    "label": "Pungut Hilir",
    "value": "Pungut Hilir"
  },
  {
    "id": "1501112012",
    "district_id": "150111",
    "label": "Pungut Mudik",
    "value": "Pungut Mudik"
  },
  {
    "id": "1501112014",
    "district_id": "150111",
    "label": "Kemantan Hilir",
    "value": "Kemantan Hilir"
  },
  {
    "id": "1501112015",
    "district_id": "150111",
    "label": "Sungaitutung",
    "value": "Sungaitutung"
  },
  {
    "id": "1501112018",
    "district_id": "150111",
    "label": "Kemantan Agung",
    "value": "Kemantan Agung"
  },
  {
    "id": "1501112019",
    "district_id": "150111",
    "label": "Sungaideras",
    "value": "Sungaideras"
  },
  {
    "id": "1501112020",
    "district_id": "150111",
    "label": "Kemantan Mudik",
    "value": "Kemantan Mudik"
  },
  {
    "id": "1501112021",
    "district_id": "150111",
    "label": "Simpang Empat Sungai Tutung",
    "value": "Simpang Empat Sungai Tutung"
  },
  {
    "id": "1501112022",
    "district_id": "150111",
    "label": "Baru Sungaideras",
    "value": "Baru Sungaideras"
  },
  {
    "id": "1501112023",
    "district_id": "150111",
    "label": "Taman Jernih Sungaitutung",
    "value": "Taman Jernih Sungaitutung"
  },
  {
    "id": "1501112024",
    "district_id": "150111",
    "label": "Baru Sungaimedang",
    "value": "Baru Sungaimedang"
  },
  {
    "id": "1501112025",
    "district_id": "150111",
    "label": "Kemantan Raya",
    "value": "Kemantan Raya"
  },
  {
    "id": "1501112026",
    "district_id": "150111",
    "label": "Baru Air Hangat",
    "value": "Baru Air Hangat"
  },
  {
    "id": "1501112027",
    "district_id": "150111",
    "label": "Air Panas Sungaiabu",
    "value": "Air Panas Sungaiabu"
  },
  {
    "id": "1501112028",
    "district_id": "150111",
    "label": "Baru Sungaiabu",
    "value": "Baru Sungaiabu"
  },
  {
    "id": "1501152001",
    "district_id": "150115",
    "label": "Sungairumpun",
    "value": "Sungairumpun"
  },
  {
    "id": "1501152002",
    "district_id": "150115",
    "label": "Sungaisikai",
    "value": "Sungaisikai"
  },
  {
    "id": "1501152003",
    "district_id": "150115",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "1501152004",
    "district_id": "150115",
    "label": "Pelompek",
    "value": "Pelompek"
  },
  {
    "id": "1501152005",
    "district_id": "150115",
    "label": "Jernih Jaya",
    "value": "Jernih Jaya"
  },
  {
    "id": "1501152006",
    "district_id": "150115",
    "label": "Bengkolan Duo",
    "value": "Bengkolan Duo"
  },
  {
    "id": "1501152007",
    "district_id": "150115",
    "label": "Lubuk Pauh",
    "value": "Lubuk Pauh"
  },
  {
    "id": "1501152008",
    "district_id": "150115",
    "label": "Telun Berasap",
    "value": "Telun Berasap"
  },
  {
    "id": "1501152009",
    "district_id": "150115",
    "label": "Bumbun Duri",
    "value": "Bumbun Duri"
  },
  {
    "id": "1501152010",
    "district_id": "150115",
    "label": "Pesisir Bukit",
    "value": "Pesisir Bukit"
  },
  {
    "id": "1501152011",
    "district_id": "150115",
    "label": "Pauh Tinggi",
    "value": "Pauh Tinggi"
  },
  {
    "id": "1501152012",
    "district_id": "150115",
    "label": "Sungaijernih",
    "value": "Sungaijernih"
  },
  {
    "id": "1501152013",
    "district_id": "150115",
    "label": "Pelompek Pasar Baru",
    "value": "Pelompek Pasar Baru"
  },
  {
    "id": "1501162001",
    "district_id": "150116",
    "label": "Siulak Gedang",
    "value": "Siulak Gedang"
  },
  {
    "id": "1501162004",
    "district_id": "150116",
    "label": "Pasar Siulak Gedang",
    "value": "Pasar Siulak Gedang"
  },
  {
    "id": "1501162005",
    "district_id": "150116",
    "label": "Siulak Panjang",
    "value": "Siulak Panjang"
  },
  {
    "id": "1501162006",
    "district_id": "150116",
    "label": "Siulak Kecil Mudik",
    "value": "Siulak Kecil Mudik"
  },
  {
    "id": "1501162007",
    "district_id": "150116",
    "label": "Koto Rendah",
    "value": "Koto Rendah"
  },
  {
    "id": "1501162009",
    "district_id": "150116",
    "label": "Lubuk Nagodang",
    "value": "Lubuk Nagodang"
  },
  {
    "id": "1501162010",
    "district_id": "150116",
    "label": "Sungaipegeh",
    "value": "Sungaipegeh"
  },
  {
    "id": "1501162011",
    "district_id": "150116",
    "label": "Sungailebuh",
    "value": "Sungailebuh"
  },
  {
    "id": "1501162012",
    "district_id": "150116",
    "label": "Koto Kapeh",
    "value": "Koto Kapeh"
  },
  {
    "id": "1501162013",
    "district_id": "150116",
    "label": "Koto Beringin",
    "value": "Koto Beringin"
  },
  {
    "id": "1501162014",
    "district_id": "150116",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1501162015",
    "district_id": "150116",
    "label": "Dusun Dalam",
    "value": "Dusun Dalam"
  },
  {
    "id": "1501162016",
    "district_id": "150116",
    "label": "Air Terjun",
    "value": "Air Terjun"
  },
  {
    "id": "1501162019",
    "district_id": "150116",
    "label": "Koto Aro",
    "value": "Koto Aro"
  },
  {
    "id": "1501162021",
    "district_id": "150116",
    "label": "Koto Lebuh Tinggi",
    "value": "Koto Lebuh Tinggi"
  },
  {
    "id": "1501162024",
    "district_id": "150116",
    "label": "Siulak Kecil Hilir",
    "value": "Siulak Kecil Hilir"
  },
  {
    "id": "1501162025",
    "district_id": "150116",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1501162028",
    "district_id": "150116",
    "label": "Telago Biru",
    "value": "Telago Biru"
  },
  {
    "id": "1501162030",
    "district_id": "150116",
    "label": "Tutung Bungkuk",
    "value": "Tutung Bungkuk"
  },
  {
    "id": "1501162033",
    "district_id": "150116",
    "label": "Baru Sungaipegeh",
    "value": "Baru Sungaipegeh"
  },
  {
    "id": "1501162034",
    "district_id": "150116",
    "label": "Bendar Sedap",
    "value": "Bendar Sedap"
  },
  {
    "id": "1501162035",
    "district_id": "150116",
    "label": "Plak Naneh",
    "value": "Plak Naneh"
  },
  {
    "id": "1501162036",
    "district_id": "150116",
    "label": "Padang Jantung",
    "value": "Padang Jantung"
  },
  {
    "id": "1501162038",
    "district_id": "150116",
    "label": "Pelak Gedang",
    "value": "Pelak Gedang"
  },
  {
    "id": "1501162039",
    "district_id": "150116",
    "label": "Demong Sakti",
    "value": "Demong Sakti"
  },
  {
    "id": "1501162040",
    "district_id": "150116",
    "label": "Pasar Senen",
    "value": "Pasar Senen"
  },
  {
    "id": "1501172001",
    "district_id": "150117",
    "label": "Belui",
    "value": "Belui"
  },
  {
    "id": "1501172002",
    "district_id": "150117",
    "label": "Baru Kubang",
    "value": "Baru Kubang"
  },
  {
    "id": "1501172003",
    "district_id": "150117",
    "label": "Koto Panjang",
    "value": "Koto Panjang"
  },
  {
    "id": "1501172004",
    "district_id": "150117",
    "label": "Kubang Gedang",
    "value": "Kubang Gedang"
  },
  {
    "id": "1501172005",
    "district_id": "150117",
    "label": "Semumu",
    "value": "Semumu"
  },
  {
    "id": "1501172006",
    "district_id": "150117",
    "label": "Belui Tinggi",
    "value": "Belui Tinggi"
  },
  {
    "id": "1501172007",
    "district_id": "150117",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1501172008",
    "district_id": "150117",
    "label": "Koto Payang",
    "value": "Koto Payang"
  },
  {
    "id": "1501172009",
    "district_id": "150117",
    "label": "Tebat Ijuk",
    "value": "Tebat Ijuk"
  },
  {
    "id": "1501172010",
    "district_id": "150117",
    "label": "Sekungkung",
    "value": "Sekungkung"
  },
  {
    "id": "1501172011",
    "district_id": "150117",
    "label": "Simpang Belui",
    "value": "Simpang Belui"
  },
  {
    "id": "1501172012",
    "district_id": "150117",
    "label": "Koto Lanang",
    "value": "Koto Lanang"
  },
  {
    "id": "1501172013",
    "district_id": "150117",
    "label": "Ladeh",
    "value": "Ladeh"
  },
  {
    "id": "1501172014",
    "district_id": "150117",
    "label": "Lubuk Suli",
    "value": "Lubuk Suli"
  },
  {
    "id": "1501172015",
    "district_id": "150117",
    "label": "Kayu Aho Mangkak Koto Lanang",
    "value": "Kayu Aho Mangkak Koto Lanang"
  },
  {
    "id": "1501172016",
    "district_id": "150117",
    "label": "Tambak Tinggi",
    "value": "Tambak Tinggi"
  },
  {
    "id": "1501172017",
    "district_id": "150117",
    "label": "Kubang Agung",
    "value": "Kubang Agung"
  },
  {
    "id": "1501172018",
    "district_id": "150117",
    "label": "Tebat Ijuk Dili",
    "value": "Tebat Ijuk Dili"
  },
  {
    "id": "1501172019",
    "district_id": "150117",
    "label": "Koto Simpai Kubang",
    "value": "Koto Simpai Kubang"
  },
  {
    "id": "1501172020",
    "district_id": "150117",
    "label": "Pahlawan Belui",
    "value": "Pahlawan Belui"
  },
  {
    "id": "1501182001",
    "district_id": "150118",
    "label": "Mukai Hilir",
    "value": "Mukai Hilir"
  },
  {
    "id": "1501182002",
    "district_id": "150118",
    "label": "Mukai Tengah",
    "value": "Mukai Tengah"
  },
  {
    "id": "1501182003",
    "district_id": "150118",
    "label": "Mukai Mudik",
    "value": "Mukai Mudik"
  },
  {
    "id": "1501182004",
    "district_id": "150118",
    "label": "Senimpik",
    "value": "Senimpik"
  },
  {
    "id": "1501182005",
    "district_id": "150118",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1501182006",
    "district_id": "150118",
    "label": "Mukai Tinggi",
    "value": "Mukai Tinggi"
  },
  {
    "id": "1501182007",
    "district_id": "150118",
    "label": "Mukai Pintu",
    "value": "Mukai Pintu"
  },
  {
    "id": "1501182008",
    "district_id": "150118",
    "label": "Sungailangkap",
    "value": "Sungailangkap"
  },
  {
    "id": "1501182009",
    "district_id": "150118",
    "label": "Pasir Jaya",
    "value": "Pasir Jaya"
  },
  {
    "id": "1501182010",
    "district_id": "150118",
    "label": "Sungaikuning",
    "value": "Sungaikuning"
  },
  {
    "id": "1501182011",
    "district_id": "150118",
    "label": "Mukai Seberang",
    "value": "Mukai Seberang"
  },
  {
    "id": "1501182012",
    "district_id": "150118",
    "label": "Talang Tinggi",
    "value": "Talang Tinggi"
  },
  {
    "id": "1501182013",
    "district_id": "150118",
    "label": "Lubuk Tabun",
    "value": "Lubuk Tabun"
  },
  {
    "id": "1501182014",
    "district_id": "150118",
    "label": "Koto Lua",
    "value": "Koto Lua"
  },
  {
    "id": "1501192001",
    "district_id": "150119",
    "label": "Batu Hampar",
    "value": "Batu Hampar"
  },
  {
    "id": "1501192002",
    "district_id": "150119",
    "label": "Sungairenah",
    "value": "Sungairenah"
  },
  {
    "id": "1501192003",
    "district_id": "150119",
    "label": "Bedeng Delapan",
    "value": "Bedeng Delapan"
  },
  {
    "id": "1501192004",
    "district_id": "150119",
    "label": "Bedeng Dua",
    "value": "Bedeng Dua"
  },
  {
    "id": "1501192005",
    "district_id": "150119",
    "label": "Sako Duo",
    "value": "Sako Duo"
  },
  {
    "id": "1501192006",
    "district_id": "150119",
    "label": "Sungailintang",
    "value": "Sungailintang"
  },
  {
    "id": "1501192007",
    "district_id": "150119",
    "label": "Kebun Baru",
    "value": "Kebun Baru"
  },
  {
    "id": "1501192008",
    "district_id": "150119",
    "label": "Patok Empat",
    "value": "Patok Empat"
  },
  {
    "id": "1501192009",
    "district_id": "150119",
    "label": "Sungaijambu",
    "value": "Sungaijambu"
  },
  {
    "id": "1501192010",
    "district_id": "150119",
    "label": "Sungaikering",
    "value": "Sungaikering"
  },
  {
    "id": "1501192011",
    "district_id": "150119",
    "label": "Giri Mulyo",
    "value": "Giri Mulyo"
  },
  {
    "id": "1501192012",
    "district_id": "150119",
    "label": "Gununglabu",
    "value": "Gununglabu"
  },
  {
    "id": "1501192013",
    "district_id": "150119",
    "label": "Bento",
    "value": "Bento"
  },
  {
    "id": "1501192014",
    "district_id": "150119",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1501192015",
    "district_id": "150119",
    "label": "Sungaiasam",
    "value": "Sungaiasam"
  },
  {
    "id": "1501192016",
    "district_id": "150119",
    "label": "Pasar Minggu",
    "value": "Pasar Minggu"
  },
  {
    "id": "1501192017",
    "district_id": "150119",
    "label": "Ensatu",
    "value": "Ensatu"
  },
  {
    "id": "1501202001",
    "district_id": "150120",
    "label": "Pasar Kerman",
    "value": "Pasar Kerman"
  },
  {
    "id": "1501202002",
    "district_id": "150120",
    "label": "Lolo Gedang",
    "value": "Lolo Gedang"
  },
  {
    "id": "1501202003",
    "district_id": "150120",
    "label": "Lolo Kecil",
    "value": "Lolo Kecil"
  },
  {
    "id": "1501202004",
    "district_id": "150120",
    "label": "Lolo Hilir",
    "value": "Lolo Hilir"
  },
  {
    "id": "1501202005",
    "district_id": "150120",
    "label": "Tanjung Syam",
    "value": "Tanjung Syam"
  },
  {
    "id": "1501202006",
    "district_id": "150120",
    "label": "Talang Kemuning",
    "value": "Talang Kemuning"
  },
  {
    "id": "1501202007",
    "district_id": "150120",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "1501202008",
    "district_id": "150120",
    "label": "Muak",
    "value": "Muak"
  },
  {
    "id": "1501202009",
    "district_id": "150120",
    "label": "Pengasi Lama",
    "value": "Pengasi Lama"
  },
  {
    "id": "1501202010",
    "district_id": "150120",
    "label": "Pulaupandan",
    "value": "Pulaupandan"
  },
  {
    "id": "1501202011",
    "district_id": "150120",
    "label": "Pulausangkar",
    "value": "Pulausangkar"
  },
  {
    "id": "1501202012",
    "district_id": "150120",
    "label": "Bintang Marak",
    "value": "Bintang Marak"
  },
  {
    "id": "1501202013",
    "district_id": "150120",
    "label": "Karang Pandan",
    "value": "Karang Pandan"
  },
  {
    "id": "1501202014",
    "district_id": "150120",
    "label": "Pengasi Baru",
    "value": "Pengasi Baru"
  },
  {
    "id": "1501202015",
    "district_id": "150120",
    "label": "Muaro Lulo",
    "value": "Muaro Lulo"
  },
  {
    "id": "1501212001",
    "district_id": "150121",
    "label": "Koto Mebai",
    "value": "Koto Mebai"
  },
  {
    "id": "1501212002",
    "district_id": "150121",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1501212003",
    "district_id": "150121",
    "label": "Air Panas",
    "value": "Air Panas"
  },
  {
    "id": "1501212004",
    "district_id": "150121",
    "label": "Hamparan Pugu",
    "value": "Hamparan Pugu"
  },
  {
    "id": "1501212005",
    "district_id": "150121",
    "label": "Pugu",
    "value": "Pugu"
  },
  {
    "id": "1501212006",
    "district_id": "150121",
    "label": "Koto Mudik",
    "value": "Koto Mudik"
  },
  {
    "id": "1501212007",
    "district_id": "150121",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1501212008",
    "district_id": "150121",
    "label": "Koto Datuk",
    "value": "Koto Datuk"
  },
  {
    "id": "1501212009",
    "district_id": "150121",
    "label": "Koto Dua Baru",
    "value": "Koto Dua Baru"
  },
  {
    "id": "1501212010",
    "district_id": "150121",
    "label": "Koto Cayo",
    "value": "Koto Cayo"
  },
  {
    "id": "1501212011",
    "district_id": "150121",
    "label": "Kecil",
    "value": "Kecil"
  },
  {
    "id": "1501212012",
    "district_id": "150121",
    "label": "Air Bersih",
    "value": "Air Bersih"
  },
  {
    "id": "1502012001",
    "district_id": "150201",
    "label": "Renah Kemumu",
    "value": "Renah Kemumu"
  },
  {
    "id": "1502012002",
    "district_id": "150201",
    "label": "Tanjung Kasri",
    "value": "Tanjung Kasri"
  },
  {
    "id": "1502012003",
    "district_id": "150201",
    "label": "Lubuk Mentilin",
    "value": "Lubuk Mentilin"
  },
  {
    "id": "1502012004",
    "district_id": "150201",
    "label": "Rantau Kermas",
    "value": "Rantau Kermas"
  },
  {
    "id": "1502012005",
    "district_id": "150201",
    "label": "Renah Alai",
    "value": "Renah Alai"
  },
  {
    "id": "1502012008",
    "district_id": "150201",
    "label": "Pulau Tengah",
    "value": "Pulau Tengah"
  },
  {
    "id": "1502012009",
    "district_id": "150201",
    "label": "Lubuk Pungguk",
    "value": "Lubuk Pungguk"
  },
  {
    "id": "1502012010",
    "district_id": "150201",
    "label": "Koto Renah",
    "value": "Koto Renah"
  },
  {
    "id": "1502012011",
    "district_id": "150201",
    "label": "Muara Madras",
    "value": "Muara Madras"
  },
  {
    "id": "1502012023",
    "district_id": "150201",
    "label": "Renah Pelaan",
    "value": "Renah Pelaan"
  },
  {
    "id": "1502012024",
    "district_id": "150201",
    "label": "Koto Rawang",
    "value": "Koto Rawang"
  },
  {
    "id": "1502021009",
    "district_id": "150202",
    "label": "Pasar Atas",
    "value": "Pasar Atas"
  },
  {
    "id": "1502021010",
    "district_id": "150202",
    "label": "Pasar Bangko",
    "value": "Pasar Bangko"
  },
  {
    "id": "1502021011",
    "district_id": "150202",
    "label": "Dusun Bangko",
    "value": "Dusun Bangko"
  },
  {
    "id": "1502021013",
    "district_id": "150202",
    "label": "Pematang Kandis",
    "value": "Pematang Kandis"
  },
  {
    "id": "1502022004",
    "district_id": "150202",
    "label": "Kungkai",
    "value": "Kungkai"
  },
  {
    "id": "1502022008",
    "district_id": "150202",
    "label": "Mudo",
    "value": "Mudo"
  },
  {
    "id": "1502022012",
    "district_id": "150202",
    "label": "Langling",
    "value": "Langling"
  },
  {
    "id": "1502022024",
    "district_id": "150202",
    "label": "Sungai Kapas",
    "value": "Sungai Kapas"
  },
  {
    "id": "1502032004",
    "district_id": "150203",
    "label": "Durian Rambun",
    "value": "Durian Rambun"
  },
  {
    "id": "1502032007",
    "district_id": "150203",
    "label": "Lubuk Birah",
    "value": "Lubuk Birah"
  },
  {
    "id": "1502032008",
    "district_id": "150203",
    "label": "Lubuk Beringin",
    "value": "Lubuk Beringin"
  },
  {
    "id": "1502032009",
    "district_id": "150203",
    "label": "Rantau Macang",
    "value": "Rantau Macang"
  },
  {
    "id": "1502032010",
    "district_id": "150203",
    "label": "Peradu Temberas",
    "value": "Peradu Temberas"
  },
  {
    "id": "1502032011",
    "district_id": "150203",
    "label": "Teluk Sikumbang",
    "value": "Teluk Sikumbang"
  },
  {
    "id": "1502032012",
    "district_id": "150203",
    "label": "Muara Siau",
    "value": "Muara Siau"
  },
  {
    "id": "1502032013",
    "district_id": "150203",
    "label": "Tiaro",
    "value": "Tiaro"
  },
  {
    "id": "1502032015",
    "district_id": "150203",
    "label": "Air Lago",
    "value": "Air Lago"
  },
  {
    "id": "1502032016",
    "district_id": "150203",
    "label": "Badak Tekurung",
    "value": "Badak Tekurung"
  },
  {
    "id": "1502032017",
    "district_id": "150203",
    "label": "Rantau Bidaro",
    "value": "Rantau Bidaro"
  },
  {
    "id": "1502032018",
    "district_id": "150203",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1502032021",
    "district_id": "150203",
    "label": "Pulau Raman",
    "value": "Pulau Raman"
  },
  {
    "id": "1502032024",
    "district_id": "150203",
    "label": "Pasar Muara Siau",
    "value": "Pasar Muara Siau"
  },
  {
    "id": "1502032027",
    "district_id": "150203",
    "label": "Sepantai Renah",
    "value": "Sepantai Renah"
  },
  {
    "id": "1502032028",
    "district_id": "150203",
    "label": "Sungai Ulas",
    "value": "Sungai Ulas"
  },
  {
    "id": "1502032029",
    "district_id": "150203",
    "label": "Rantau Bayur",
    "value": "Rantau Bayur"
  },
  {
    "id": "1502042007",
    "district_id": "150204",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1502042008",
    "district_id": "150204",
    "label": "Tiangko",
    "value": "Tiangko"
  },
  {
    "id": "1502042009",
    "district_id": "150204",
    "label": "Palipan",
    "value": "Palipan"
  },
  {
    "id": "1502042010",
    "district_id": "150204",
    "label": "Seringat",
    "value": "Seringat"
  },
  {
    "id": "1502042011",
    "district_id": "150204",
    "label": "Durian Lecah",
    "value": "Durian Lecah"
  },
  {
    "id": "1502042015",
    "district_id": "150204",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "1502042016",
    "district_id": "150204",
    "label": "Sungai Nilau",
    "value": "Sungai Nilau"
  },
  {
    "id": "1502042017",
    "district_id": "150204",
    "label": "Sungai Manau",
    "value": "Sungai Manau"
  },
  {
    "id": "1502042025",
    "district_id": "150204",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1502042026",
    "district_id": "150204",
    "label": "Gelanggang",
    "value": "Gelanggang"
  },
  {
    "id": "1502051002",
    "district_id": "150205",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1502051003",
    "district_id": "150205",
    "label": "Pasar R. Panjang",
    "value": "Pasar R. Panjang"
  },
  {
    "id": "1502051005",
    "district_id": "150205",
    "label": "Kampung Baruh",
    "value": "Kampung Baruh"
  },
  {
    "id": "1502051020",
    "district_id": "150205",
    "label": "Mampun",
    "value": "Mampun"
  },
  {
    "id": "1502051036",
    "district_id": "150205",
    "label": "Ps.Baru Rantau",
    "value": "Ps.Baru Rantau"
  },
  {
    "id": "1502052007",
    "district_id": "150205",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1502052008",
    "district_id": "150205",
    "label": "Koto Rayo",
    "value": "Koto Rayo"
  },
  {
    "id": "1502052011",
    "district_id": "150205",
    "label": "Tanjung Ilir",
    "value": "Tanjung Ilir"
  },
  {
    "id": "1502052014",
    "district_id": "150205",
    "label": "Beluran Panjang",
    "value": "Beluran Panjang"
  },
  {
    "id": "1502052015",
    "district_id": "150205",
    "label": "Seling",
    "value": "Seling"
  },
  {
    "id": "1502052035",
    "district_id": "150205",
    "label": "Lubuk Napal",
    "value": "Lubuk Napal"
  },
  {
    "id": "1502061008",
    "district_id": "150206",
    "label": "Pamenang",
    "value": "Pamenang"
  },
  {
    "id": "1502062004",
    "district_id": "150206",
    "label": "Jelatang",
    "value": "Jelatang"
  },
  {
    "id": "1502062005",
    "district_id": "150206",
    "label": "Tanjung Gedang",
    "value": "Tanjung Gedang"
  },
  {
    "id": "1502062006",
    "district_id": "150206",
    "label": "Empang Benao",
    "value": "Empang Benao"
  },
  {
    "id": "1502062007",
    "district_id": "150206",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1502062009",
    "district_id": "150206",
    "label": "Keroya",
    "value": "Keroya"
  },
  {
    "id": "1502062010",
    "district_id": "150206",
    "label": "Muara Belengo",
    "value": "Muara Belengo"
  },
  {
    "id": "1502062011",
    "district_id": "150206",
    "label": "Karang Berahi",
    "value": "Karang Berahi"
  },
  {
    "id": "1502062012",
    "district_id": "150206",
    "label": "Pauh Menang",
    "value": "Pauh Menang"
  },
  {
    "id": "1502062013",
    "district_id": "150206",
    "label": "Pematang Kancil",
    "value": "Pematang Kancil"
  },
  {
    "id": "1502062014",
    "district_id": "150206",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "1502062015",
    "district_id": "150206",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1502062026",
    "district_id": "150206",
    "label": "Sungai Udang",
    "value": "Sungai Udang"
  },
  {
    "id": "1502062027",
    "district_id": "150206",
    "label": "Pelakar Jaya",
    "value": "Pelakar Jaya"
  },
  {
    "id": "1502072009",
    "district_id": "150207",
    "label": "Muara Jernih",
    "value": "Muara Jernih"
  },
  {
    "id": "1502072010",
    "district_id": "150207",
    "label": "Kapuk",
    "value": "Kapuk"
  },
  {
    "id": "1502072011",
    "district_id": "150207",
    "label": "Pulau Aro",
    "value": "Pulau Aro"
  },
  {
    "id": "1502072012",
    "district_id": "150207",
    "label": "Rantau Ngarau",
    "value": "Rantau Ngarau"
  },
  {
    "id": "1502072013",
    "district_id": "150207",
    "label": "Medan Baru",
    "value": "Medan Baru"
  },
  {
    "id": "1502072014",
    "district_id": "150207",
    "label": "Muaro Seketuk",
    "value": "Muaro Seketuk"
  },
  {
    "id": "1502082001",
    "district_id": "150208",
    "label": "Sungai Sahut",
    "value": "Sungai Sahut"
  },
  {
    "id": "1502082002",
    "district_id": "150208",
    "label": "Bungo Antoi",
    "value": "Bungo Antoi"
  },
  {
    "id": "1502082003",
    "district_id": "150208",
    "label": "Muara Delang",
    "value": "Muara Delang"
  },
  {
    "id": "1502082004",
    "district_id": "150208",
    "label": "Sinar Gading",
    "value": "Sinar Gading"
  },
  {
    "id": "1502082005",
    "district_id": "150208",
    "label": "Bungo Tanjung",
    "value": "Bungo Tanjung"
  },
  {
    "id": "1502082006",
    "district_id": "150208",
    "label": "Rawa Jaya",
    "value": "Rawa Jaya"
  },
  {
    "id": "1502082007",
    "district_id": "150208",
    "label": "Gading Jaya",
    "value": "Gading Jaya"
  },
  {
    "id": "1502082008",
    "district_id": "150208",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1502092001",
    "district_id": "150209",
    "label": "Nilo Dingin",
    "value": "Nilo Dingin"
  },
  {
    "id": "1502092002",
    "district_id": "150209",
    "label": "Tanjung Berugo",
    "value": "Tanjung Berugo"
  },
  {
    "id": "1502092003",
    "district_id": "150209",
    "label": "Tuo",
    "value": "Tuo"
  },
  {
    "id": "1502092004",
    "district_id": "150209",
    "label": "Koto Rami",
    "value": "Koto Rami"
  },
  {
    "id": "1502092005",
    "district_id": "150209",
    "label": "Rancan",
    "value": "Rancan"
  },
  {
    "id": "1502092006",
    "district_id": "150209",
    "label": "Pasar Masurai",
    "value": "Pasar Masurai"
  },
  {
    "id": "1502092007",
    "district_id": "150209",
    "label": "Rantau Jering",
    "value": "Rantau Jering"
  },
  {
    "id": "1502092008",
    "district_id": "150209",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1502092009",
    "district_id": "150209",
    "label": "Muaro Pangi",
    "value": "Muaro Pangi"
  },
  {
    "id": "1502092010",
    "district_id": "150209",
    "label": "Sungai Lalang",
    "value": "Sungai Lalang"
  },
  {
    "id": "1502092011",
    "district_id": "150209",
    "label": "Talang Asal",
    "value": "Talang Asal"
  },
  {
    "id": "1502092012",
    "district_id": "150209",
    "label": "Muara Kelukup",
    "value": "Muara Kelukup"
  },
  {
    "id": "1502092013",
    "district_id": "150209",
    "label": "Muara Lengayo",
    "value": "Muara Lengayo"
  },
  {
    "id": "1502092014",
    "district_id": "150209",
    "label": "Talang Paruh",
    "value": "Talang Paruh"
  },
  {
    "id": "1502092015",
    "district_id": "150209",
    "label": "Durian Mukut",
    "value": "Durian Mukut"
  },
  {
    "id": "1502102001",
    "district_id": "150210",
    "label": "Pulau Rengas",
    "value": "Pulau Rengas"
  },
  {
    "id": "1502102002",
    "district_id": "150210",
    "label": "Biuku Tanjung",
    "value": "Biuku Tanjung"
  },
  {
    "id": "1502102003",
    "district_id": "150210",
    "label": "Bedeng Rejo",
    "value": "Bedeng Rejo"
  },
  {
    "id": "1502102004",
    "district_id": "150210",
    "label": "Sungai Putih",
    "value": "Sungai Putih"
  },
  {
    "id": "1502102005",
    "district_id": "150210",
    "label": "Bukit Beringin",
    "value": "Bukit Beringin"
  },
  {
    "id": "1502102006",
    "district_id": "150210",
    "label": "Pulau Rengas Ulu",
    "value": "Pulau Rengas Ulu"
  },
  {
    "id": "1502112001",
    "district_id": "150211",
    "label": "Sungai Ulak",
    "value": "Sungai Ulak"
  },
  {
    "id": "1502112002",
    "district_id": "150211",
    "label": "Aur Berduri",
    "value": "Aur Berduri"
  },
  {
    "id": "1502112003",
    "district_id": "150211",
    "label": "Telun",
    "value": "Telun"
  },
  {
    "id": "1502112004",
    "district_id": "150211",
    "label": "Nalo Gedang",
    "value": "Nalo Gedang"
  },
  {
    "id": "1502112005",
    "district_id": "150211",
    "label": "Baru Nalo",
    "value": "Baru Nalo"
  },
  {
    "id": "1502112006",
    "district_id": "150211",
    "label": "Mentawak",
    "value": "Mentawak"
  },
  {
    "id": "1502112007",
    "district_id": "150211",
    "label": "Danau",
    "value": "Danau"
  },
  {
    "id": "1502122001",
    "district_id": "150212",
    "label": "Lubuk Gaung",
    "value": "Lubuk Gaung"
  },
  {
    "id": "1502122002",
    "district_id": "150212",
    "label": "Salam Buku",
    "value": "Salam Buku"
  },
  {
    "id": "1502122003",
    "district_id": "150212",
    "label": "Pulau Baru",
    "value": "Pulau Baru"
  },
  {
    "id": "1502122004",
    "district_id": "150212",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "1502122005",
    "district_id": "150212",
    "label": "Titian Teras",
    "value": "Titian Teras"
  },
  {
    "id": "1502122006",
    "district_id": "150212",
    "label": "Kedarasan Panjang",
    "value": "Kedarasan Panjang"
  },
  {
    "id": "1502122007",
    "district_id": "150212",
    "label": "Rantau Alai",
    "value": "Rantau Alai"
  },
  {
    "id": "1502122008",
    "district_id": "150212",
    "label": "Tambang Besi",
    "value": "Tambang Besi"
  },
  {
    "id": "1502122009",
    "district_id": "150212",
    "label": "Pelangki",
    "value": "Pelangki"
  },
  {
    "id": "1502122010",
    "district_id": "150212",
    "label": "Pulau Layang",
    "value": "Pulau Layang"
  },
  {
    "id": "1502132001",
    "district_id": "150213",
    "label": "Tanjung Lamin",
    "value": "Tanjung Lamin"
  },
  {
    "id": "1502132002",
    "district_id": "150213",
    "label": "Limbur Merangin",
    "value": "Limbur Merangin"
  },
  {
    "id": "1502132003",
    "district_id": "150213",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1502132004",
    "district_id": "150213",
    "label": "Pinang Merah",
    "value": "Pinang Merah"
  },
  {
    "id": "1502132005",
    "district_id": "150213",
    "label": "Simpang L. Merangin",
    "value": "Simpang L. Merangin"
  },
  {
    "id": "1502132006",
    "district_id": "150213",
    "label": "Mampun Baru",
    "value": "Mampun Baru"
  },
  {
    "id": "1502132007",
    "district_id": "150213",
    "label": "Papit",
    "value": "Papit"
  },
  {
    "id": "1502132008",
    "district_id": "150213",
    "label": "PulauTujuh",
    "value": "PulauTujuh"
  },
  {
    "id": "1502142001",
    "district_id": "150214",
    "label": "Rantau Limau Manis",
    "value": "Rantau Limau Manis"
  },
  {
    "id": "1502142002",
    "district_id": "150214",
    "label": "Ulak Makam",
    "value": "Ulak Makam"
  },
  {
    "id": "1502142003",
    "district_id": "150214",
    "label": "Air Batu",
    "value": "Air Batu"
  },
  {
    "id": "1502142004",
    "district_id": "150214",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1502142005",
    "district_id": "150214",
    "label": "Kota Raja",
    "value": "Kota Raja"
  },
  {
    "id": "1502142006",
    "district_id": "150214",
    "label": "Tunggul Bulin",
    "value": "Tunggul Bulin"
  },
  {
    "id": "1502142007",
    "district_id": "150214",
    "label": "Mekar Limau Manis",
    "value": "Mekar Limau Manis"
  },
  {
    "id": "1502152001",
    "district_id": "150215",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "1502152002",
    "district_id": "150215",
    "label": "Sungai Bulian",
    "value": "Sungai Bulian"
  },
  {
    "id": "1502152003",
    "district_id": "150215",
    "label": "Sri Sembilan",
    "value": "Sri Sembilan"
  },
  {
    "id": "1502152004",
    "district_id": "150215",
    "label": "Bukit Subur",
    "value": "Bukit Subur"
  },
  {
    "id": "1502162001",
    "district_id": "150216",
    "label": "Muaro Panco Barat",
    "value": "Muaro Panco Barat"
  },
  {
    "id": "1502162002",
    "district_id": "150216",
    "label": "Talang Segegah",
    "value": "Talang Segegah"
  },
  {
    "id": "1502162003",
    "district_id": "150216",
    "label": "Durian Batakuk",
    "value": "Durian Batakuk"
  },
  {
    "id": "1502162004",
    "district_id": "150216",
    "label": "Muara Bantan",
    "value": "Muara Bantan"
  },
  {
    "id": "1502162005",
    "district_id": "150216",
    "label": "Parit Ujung Tanjung",
    "value": "Parit Ujung Tanjung"
  },
  {
    "id": "1502162006",
    "district_id": "150216",
    "label": "Guguk",
    "value": "Guguk"
  },
  {
    "id": "1502162007",
    "district_id": "150216",
    "label": "Markeh",
    "value": "Markeh"
  },
  {
    "id": "1502162008",
    "district_id": "150216",
    "label": "Air Batu",
    "value": "Air Batu"
  },
  {
    "id": "1502162009",
    "district_id": "150216",
    "label": "Marus Jaya",
    "value": "Marus Jaya"
  },
  {
    "id": "1502162010",
    "district_id": "150216",
    "label": "Renah Medan",
    "value": "Renah Medan"
  },
  {
    "id": "1502162011",
    "district_id": "150216",
    "label": "Simpang Parit",
    "value": "Simpang Parit"
  },
  {
    "id": "1502162012",
    "district_id": "150216",
    "label": "Muaro Panco Timur",
    "value": "Muaro Panco Timur"
  },
  {
    "id": "1502172001",
    "district_id": "150217",
    "label": "Baru Pangkalan Jambu",
    "value": "Baru Pangkalan Jambu"
  },
  {
    "id": "1502172002",
    "district_id": "150217",
    "label": "Bukit Perentak",
    "value": "Bukit Perentak"
  },
  {
    "id": "1502172003",
    "district_id": "150217",
    "label": "Birun",
    "value": "Birun"
  },
  {
    "id": "1502172004",
    "district_id": "150217",
    "label": "Tiga Alur",
    "value": "Tiga Alur"
  },
  {
    "id": "1502172005",
    "district_id": "150217",
    "label": "Bungo Tanjung",
    "value": "Bungo Tanjung"
  },
  {
    "id": "1502172006",
    "district_id": "150217",
    "label": "Sungai Jering",
    "value": "Sungai Jering"
  },
  {
    "id": "1502172007",
    "district_id": "150217",
    "label": "Kampung Limo",
    "value": "Kampung Limo"
  },
  {
    "id": "1502172008",
    "district_id": "150217",
    "label": "Tanjung Mudo",
    "value": "Tanjung Mudo"
  },
  {
    "id": "1502182001",
    "district_id": "150218",
    "label": "Tanjung Mudo",
    "value": "Tanjung Mudo"
  },
  {
    "id": "1502182002",
    "district_id": "150218",
    "label": "Koto Teguh",
    "value": "Koto Teguh"
  },
  {
    "id": "1502182003",
    "district_id": "150218",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1502182004",
    "district_id": "150218",
    "label": "Talang Tembago",
    "value": "Talang Tembago"
  },
  {
    "id": "1502182005",
    "district_id": "150218",
    "label": "Beringin Tinggi",
    "value": "Beringin Tinggi"
  },
  {
    "id": "1502182006",
    "district_id": "150218",
    "label": "Pematang Pauh",
    "value": "Pematang Pauh"
  },
  {
    "id": "1502182007",
    "district_id": "150218",
    "label": "Rantau Suli",
    "value": "Rantau Suli"
  },
  {
    "id": "1502182008",
    "district_id": "150218",
    "label": "Gedang",
    "value": "Gedang"
  },
  {
    "id": "1502182010",
    "district_id": "150218",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1502182011",
    "district_id": "150218",
    "label": "Tanjung Benuang",
    "value": "Tanjung Benuang"
  },
  {
    "id": "1502182012",
    "district_id": "150218",
    "label": "Jangkat",
    "value": "Jangkat"
  },
  {
    "id": "1502182013",
    "district_id": "150218",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1502182014",
    "district_id": "150218",
    "label": "Kabu",
    "value": "Kabu"
  },
  {
    "id": "1502182015",
    "district_id": "150218",
    "label": "Simpang Talang Tembago",
    "value": "Simpang Talang Tembago"
  },
  {
    "id": "1502192001",
    "district_id": "150219",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "1502192002",
    "district_id": "150219",
    "label": "Bukit Bungkul",
    "value": "Bukit Bungkul"
  },
  {
    "id": "1502192003",
    "district_id": "150219",
    "label": "Rasau",
    "value": "Rasau"
  },
  {
    "id": "1502192004",
    "district_id": "150219",
    "label": "Lantak Seribu",
    "value": "Lantak Seribu"
  },
  {
    "id": "1502202001",
    "district_id": "150220",
    "label": "Tambang Emas",
    "value": "Tambang Emas"
  },
  {
    "id": "1502202002",
    "district_id": "150220",
    "label": "Tanjung Benuang",
    "value": "Tanjung Benuang"
  },
  {
    "id": "1502202003",
    "district_id": "150220",
    "label": "Pulau Bayur",
    "value": "Pulau Bayur"
  },
  {
    "id": "1502202004",
    "district_id": "150220",
    "label": "Selango",
    "value": "Selango"
  },
  {
    "id": "1502212001",
    "district_id": "150221",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1502212002",
    "district_id": "150221",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1502212003",
    "district_id": "150221",
    "label": "Suko Rejo",
    "value": "Suko Rejo"
  },
  {
    "id": "1502212004",
    "district_id": "150221",
    "label": "Sido Rukun",
    "value": "Sido Rukun"
  },
  {
    "id": "1502212005",
    "district_id": "150221",
    "label": "Lubuk Bumbun",
    "value": "Lubuk Bumbun"
  },
  {
    "id": "1502212006",
    "district_id": "150221",
    "label": "Tegal Rejo",
    "value": "Tegal Rejo"
  },
  {
    "id": "1502222001",
    "district_id": "150222",
    "label": "Tambang Baru",
    "value": "Tambang Baru"
  },
  {
    "id": "1502222002",
    "district_id": "150222",
    "label": "Sido Lego",
    "value": "Sido Lego"
  },
  {
    "id": "1502222003",
    "district_id": "150222",
    "label": "Mensango",
    "value": "Mensango"
  },
  {
    "id": "1502222004",
    "district_id": "150222",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1502222005",
    "district_id": "150222",
    "label": "Sido Harjo",
    "value": "Sido Harjo"
  },
  {
    "id": "1502232001",
    "district_id": "150223",
    "label": "Muara Kibul",
    "value": "Muara Kibul"
  },
  {
    "id": "1502232002",
    "district_id": "150223",
    "label": "Tanjung Putus",
    "value": "Tanjung Putus"
  },
  {
    "id": "1502232003",
    "district_id": "150223",
    "label": "Pulau Tebakar",
    "value": "Pulau Tebakar"
  },
  {
    "id": "1502232004",
    "district_id": "150223",
    "label": "Batang Kibul",
    "value": "Batang Kibul"
  },
  {
    "id": "1502232005",
    "district_id": "150223",
    "label": "Sungai Tabir",
    "value": "Sungai Tabir"
  },
  {
    "id": "1502232006",
    "district_id": "150223",
    "label": "Telentam",
    "value": "Telentam"
  },
  {
    "id": "1502232007",
    "district_id": "150223",
    "label": "Ngaol",
    "value": "Ngaol"
  },
  {
    "id": "1502232008",
    "district_id": "150223",
    "label": "Air Liki",
    "value": "Air Liki"
  },
  {
    "id": "1502232009",
    "district_id": "150223",
    "label": "Pulau Lebar",
    "value": "Pulau Lebar"
  },
  {
    "id": "1502232010",
    "district_id": "150223",
    "label": "Muara Langeh",
    "value": "Muara Langeh"
  },
  {
    "id": "1502232011",
    "district_id": "150223",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1502232012",
    "district_id": "150223",
    "label": "Baru Kibul",
    "value": "Baru Kibul"
  },
  {
    "id": "1502232013",
    "district_id": "150223",
    "label": "Ngaol Ilir",
    "value": "Ngaol Ilir"
  },
  {
    "id": "1502232014",
    "district_id": "150223",
    "label": "Air Liki Baru",
    "value": "Air Liki Baru"
  },
  {
    "id": "1502242001",
    "district_id": "150224",
    "label": "Sekancing",
    "value": "Sekancing"
  },
  {
    "id": "1502242002",
    "district_id": "150224",
    "label": "Sekancing Ilir",
    "value": "Sekancing Ilir"
  },
  {
    "id": "1502242003",
    "district_id": "150224",
    "label": "Beringin Sanggul",
    "value": "Beringin Sanggul"
  },
  {
    "id": "1502242004",
    "district_id": "150224",
    "label": "Baru Bukit Punjung",
    "value": "Baru Bukit Punjung"
  },
  {
    "id": "1502242005",
    "district_id": "150224",
    "label": "Rantau Limau Kapas",
    "value": "Rantau Limau Kapas"
  },
  {
    "id": "1502242006",
    "district_id": "150224",
    "label": "Baru Sungai Sakai",
    "value": "Baru Sungai Sakai"
  },
  {
    "id": "1503012001",
    "district_id": "150301",
    "label": "Tambak Ratu",
    "value": "Tambak Ratu"
  },
  {
    "id": "1503012002",
    "district_id": "150301",
    "label": "Sungai Keradak",
    "value": "Sungai Keradak"
  },
  {
    "id": "1503012003",
    "district_id": "150301",
    "label": "Muaro Air Dua",
    "value": "Muaro Air Dua"
  },
  {
    "id": "1503012004",
    "district_id": "150301",
    "label": "Simpang Narso",
    "value": "Simpang Narso"
  },
  {
    "id": "1503012005",
    "district_id": "150301",
    "label": "Batin Pengambang",
    "value": "Batin Pengambang"
  },
  {
    "id": "1503012006",
    "district_id": "150301",
    "label": "Batu Empang",
    "value": "Batu Empang"
  },
  {
    "id": "1503012007",
    "district_id": "150301",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1503012008",
    "district_id": "150301",
    "label": "P. Salak Baru",
    "value": "P. Salak Baru"
  },
  {
    "id": "1503012009",
    "district_id": "150301",
    "label": "Muaro Pemuat",
    "value": "Muaro Pemuat"
  },
  {
    "id": "1503012010",
    "district_id": "150301",
    "label": "Lubuk Bangkar",
    "value": "Lubuk Bangkar"
  },
  {
    "id": "1503012011",
    "district_id": "150301",
    "label": "Pekan Gedang",
    "value": "Pekan Gedang"
  },
  {
    "id": "1503012012",
    "district_id": "150301",
    "label": "Raden Anom",
    "value": "Raden Anom"
  },
  {
    "id": "1503012013",
    "district_id": "150301",
    "label": "Datuk Nan Duo",
    "value": "Datuk Nan Duo"
  },
  {
    "id": "1503012014",
    "district_id": "150301",
    "label": "Bukit Kalimau Ulu",
    "value": "Bukit Kalimau Ulu"
  },
  {
    "id": "1503012015",
    "district_id": "150301",
    "label": "Sungai Bemban",
    "value": "Sungai Bemban"
  },
  {
    "id": "1503012016",
    "district_id": "150301",
    "label": "Muara Cuban",
    "value": "Muara Cuban"
  },
  {
    "id": "1503012017",
    "district_id": "150301",
    "label": "Sungai Baung",
    "value": "Sungai Baung"
  },
  {
    "id": "1503012018",
    "district_id": "150301",
    "label": "Padang Jering",
    "value": "Padang Jering"
  },
  {
    "id": "1503012019",
    "district_id": "150301",
    "label": "Kasiro",
    "value": "Kasiro"
  },
  {
    "id": "1503012020",
    "district_id": "150301",
    "label": "Kasiro Hilir",
    "value": "Kasiro Hilir"
  },
  {
    "id": "1503012021",
    "district_id": "150301",
    "label": "Bukit Berantai",
    "value": "Bukit Berantai"
  },
  {
    "id": "1503012022",
    "district_id": "150301",
    "label": "Bukit Sulah",
    "value": "Bukit Sulah"
  },
  {
    "id": "1503012023",
    "district_id": "150301",
    "label": "Paniban Baru",
    "value": "Paniban Baru"
  },
  {
    "id": "1503022005",
    "district_id": "150302",
    "label": "Pulau Pandan",
    "value": "Pulau Pandan"
  },
  {
    "id": "1503022006",
    "district_id": "150302",
    "label": "Temenggung",
    "value": "Temenggung"
  },
  {
    "id": "1503022007",
    "district_id": "150302",
    "label": "Ranggo",
    "value": "Ranggo"
  },
  {
    "id": "1503022008",
    "district_id": "150302",
    "label": "Panca Karya",
    "value": "Panca Karya"
  },
  {
    "id": "1503022009",
    "district_id": "150302",
    "label": "Lubuk Bedorong",
    "value": "Lubuk Bedorong"
  },
  {
    "id": "1503022010",
    "district_id": "150302",
    "label": "Maribung",
    "value": "Maribung"
  },
  {
    "id": "1503022011",
    "district_id": "150302",
    "label": "Napal Melintang",
    "value": "Napal Melintang"
  },
  {
    "id": "1503022012",
    "district_id": "150302",
    "label": "Berkun",
    "value": "Berkun"
  },
  {
    "id": "1503022013",
    "district_id": "150302",
    "label": "Mersip",
    "value": "Mersip"
  },
  {
    "id": "1503022014",
    "district_id": "150302",
    "label": "Tanjung Raden",
    "value": "Tanjung Raden"
  },
  {
    "id": "1503022016",
    "district_id": "150302",
    "label": "Moenti",
    "value": "Moenti"
  },
  {
    "id": "1503022017",
    "district_id": "150302",
    "label": "Muara Mensao",
    "value": "Muara Mensao"
  },
  {
    "id": "1503022018",
    "district_id": "150302",
    "label": "Demang",
    "value": "Demang"
  },
  {
    "id": "1503022021",
    "district_id": "150302",
    "label": "Temalang",
    "value": "Temalang"
  },
  {
    "id": "1503022022",
    "district_id": "150302",
    "label": "Muara Limun",
    "value": "Muara Limun"
  },
  {
    "id": "1503022023",
    "district_id": "150302",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1503031001",
    "district_id": "150303",
    "label": "Dusun Sarolangun",
    "value": "Dusun Sarolangun"
  },
  {
    "id": "1503031015",
    "district_id": "150303",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "1503031016",
    "district_id": "150303",
    "label": "Pasar Sarolangun",
    "value": "Pasar Sarolangun"
  },
  {
    "id": "1503031019",
    "district_id": "150303",
    "label": "Sarolangun Kembang",
    "value": "Sarolangun Kembang"
  },
  {
    "id": "1503031025",
    "district_id": "150303",
    "label": "Gunungkembang",
    "value": "Gunungkembang"
  },
  {
    "id": "1503031026",
    "district_id": "150303",
    "label": "Aur",
    "value": "Aur"
  },
  {
    "id": "1503032002",
    "district_id": "150303",
    "label": "Bernai",
    "value": "Bernai"
  },
  {
    "id": "1503032003",
    "district_id": "150303",
    "label": "Sungai Baung",
    "value": "Sungai Baung"
  },
  {
    "id": "1503032004",
    "district_id": "150303",
    "label": "Panti",
    "value": "Panti"
  },
  {
    "id": "1503032005",
    "district_id": "150303",
    "label": "Tinting",
    "value": "Tinting"
  },
  {
    "id": "1503032006",
    "district_id": "150303",
    "label": "Sungai Abang",
    "value": "Sungai Abang"
  },
  {
    "id": "1503032013",
    "district_id": "150303",
    "label": "Lidung",
    "value": "Lidung"
  },
  {
    "id": "1503032014",
    "district_id": "150303",
    "label": "Ladang Panjang",
    "value": "Ladang Panjang"
  },
  {
    "id": "1503032024",
    "district_id": "150303",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1503032027",
    "district_id": "150303",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1503032028",
    "district_id": "150303",
    "label": "Bernai Dalam",
    "value": "Bernai Dalam"
  },
  {
    "id": "1503041005",
    "district_id": "150304",
    "label": "Pauh",
    "value": "Pauh"
  },
  {
    "id": "1503042001",
    "district_id": "150304",
    "label": "Pangidaran",
    "value": "Pangidaran"
  },
  {
    "id": "1503042002",
    "district_id": "150304",
    "label": "Karang Mendapo",
    "value": "Karang Mendapo"
  },
  {
    "id": "1503042003",
    "district_id": "150304",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1503042004",
    "district_id": "150304",
    "label": "Batu Kucing",
    "value": "Batu Kucing"
  },
  {
    "id": "1503042006",
    "district_id": "150304",
    "label": "Semaran",
    "value": "Semaran"
  },
  {
    "id": "1503042007",
    "district_id": "150304",
    "label": "Kasang Melintang",
    "value": "Kasang Melintang"
  },
  {
    "id": "1503042008",
    "district_id": "150304",
    "label": "Pangkal Bulian",
    "value": "Pangkal Bulian"
  },
  {
    "id": "1503042009",
    "district_id": "150304",
    "label": "Lubuk Napal",
    "value": "Lubuk Napal"
  },
  {
    "id": "1503042010",
    "district_id": "150304",
    "label": "Sepintun",
    "value": "Sepintun"
  },
  {
    "id": "1503042011",
    "district_id": "150304",
    "label": "Lamban Sigatal",
    "value": "Lamban Sigatal"
  },
  {
    "id": "1503042020",
    "district_id": "150304",
    "label": "Seko Besar",
    "value": "Seko Besar"
  },
  {
    "id": "1503042021",
    "district_id": "150304",
    "label": "Taman Bandung",
    "value": "Taman Bandung"
  },
  {
    "id": "1503042022",
    "district_id": "150304",
    "label": "Danau Serdang",
    "value": "Danau Serdang"
  },
  {
    "id": "1503052001",
    "district_id": "150305",
    "label": "Penegah",
    "value": "Penegah"
  },
  {
    "id": "1503052002",
    "district_id": "150305",
    "label": "Pelawan",
    "value": "Pelawan"
  },
  {
    "id": "1503052003",
    "district_id": "150305",
    "label": "Pulauaro",
    "value": "Pulauaro"
  },
  {
    "id": "1503052004",
    "district_id": "150305",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "1503052005",
    "district_id": "150305",
    "label": "Lubuk Sepuh",
    "value": "Lubuk Sepuh"
  },
  {
    "id": "1503052006",
    "district_id": "150305",
    "label": "Rantanu Tenang",
    "value": "Rantanu Tenang"
  },
  {
    "id": "1503052007",
    "district_id": "150305",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1503052013",
    "district_id": "150305",
    "label": "Sungai Merah",
    "value": "Sungai Merah"
  },
  {
    "id": "1503052016",
    "district_id": "150305",
    "label": "Pematang Kulim",
    "value": "Pematang Kulim"
  },
  {
    "id": "1503052017",
    "district_id": "150305",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "1503052019",
    "district_id": "150305",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "1503052021",
    "district_id": "150305",
    "label": "Pasar Pelawan",
    "value": "Pasar Pelawan"
  },
  {
    "id": "1503052022",
    "district_id": "150305",
    "label": "Pelawan Jaya",
    "value": "Pelawan Jaya"
  },
  {
    "id": "1503052023",
    "district_id": "150305",
    "label": "Lubuk Sayak",
    "value": "Lubuk Sayak"
  },
  {
    "id": "1503062001",
    "district_id": "150306",
    "label": "Mandiangin",
    "value": "Mandiangin"
  },
  {
    "id": "1503062002",
    "district_id": "150306",
    "label": "Rangkiling Simpang",
    "value": "Rangkiling Simpang"
  },
  {
    "id": "1503062003",
    "district_id": "150306",
    "label": "Pemusiran",
    "value": "Pemusiran"
  },
  {
    "id": "1503062004",
    "district_id": "150306",
    "label": "Gurun Tuo Simpang",
    "value": "Gurun Tuo Simpang"
  },
  {
    "id": "1503062005",
    "district_id": "150306",
    "label": "Gurun Mudo",
    "value": "Gurun Mudo"
  },
  {
    "id": "1503062006",
    "district_id": "150306",
    "label": "Gurun Tuo",
    "value": "Gurun Tuo"
  },
  {
    "id": "1503062007",
    "district_id": "150306",
    "label": "Rangkiling",
    "value": "Rangkiling"
  },
  {
    "id": "1503062008",
    "district_id": "150306",
    "label": "Mandainangin Tuo",
    "value": "Mandainangin Tuo"
  },
  {
    "id": "1503062009",
    "district_id": "150306",
    "label": "Kertopati",
    "value": "Kertopati"
  },
  {
    "id": "1503062010",
    "district_id": "150306",
    "label": "Muaro Ketalo",
    "value": "Muaro Ketalo"
  },
  {
    "id": "1503062011",
    "district_id": "150306",
    "label": "Taman Dewa",
    "value": "Taman Dewa"
  },
  {
    "id": "1503062012",
    "district_id": "150306",
    "label": "Simpang Kertopati",
    "value": "Simpang Kertopati"
  },
  {
    "id": "1503062013",
    "district_id": "150306",
    "label": "Bukitperanginan",
    "value": "Bukitperanginan"
  },
  {
    "id": "1503062014",
    "district_id": "150306",
    "label": "Meranti Baru",
    "value": "Meranti Baru"
  },
  {
    "id": "1503062015",
    "district_id": "150306",
    "label": "Petiduran Baru",
    "value": "Petiduran Baru"
  },
  {
    "id": "1503062016",
    "district_id": "150306",
    "label": "Guruh Baru",
    "value": "Guruh Baru"
  },
  {
    "id": "1503062017",
    "district_id": "150306",
    "label": "Butang Baru",
    "value": "Butang Baru"
  },
  {
    "id": "1503062018",
    "district_id": "150306",
    "label": "Jati Baru",
    "value": "Jati Baru"
  },
  {
    "id": "1503062019",
    "district_id": "150306",
    "label": "Sungai Butang",
    "value": "Sungai Butang"
  },
  {
    "id": "1503062020",
    "district_id": "150306",
    "label": "Gurun Baru",
    "value": "Gurun Baru"
  },
  {
    "id": "1503062021",
    "district_id": "150306",
    "label": "Kute Jaye",
    "value": "Kute Jaye"
  },
  {
    "id": "1503062022",
    "district_id": "150306",
    "label": "Mandiangin Pasar",
    "value": "Mandiangin Pasar"
  },
  {
    "id": "1503062023",
    "district_id": "150306",
    "label": "Sungai Rotan",
    "value": "Sungai Rotan"
  },
  {
    "id": "1503062024",
    "district_id": "150306",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1503062025",
    "district_id": "150306",
    "label": "Jernang Baru",
    "value": "Jernang Baru"
  },
  {
    "id": "1503062026",
    "district_id": "150306",
    "label": "Meranti Jaya",
    "value": "Meranti Jaya"
  },
  {
    "id": "1503062027",
    "district_id": "150306",
    "label": "Jati Baru Mudo",
    "value": "Jati Baru Mudo"
  },
  {
    "id": "1503062028",
    "district_id": "150306",
    "label": "Talang Serdang",
    "value": "Talang Serdang"
  },
  {
    "id": "1503072001",
    "district_id": "150307",
    "label": "Lubuk Jering",
    "value": "Lubuk Jering"
  },
  {
    "id": "1503072002",
    "district_id": "150307",
    "label": "Jernih",
    "value": "Jernih"
  },
  {
    "id": "1503072003",
    "district_id": "150307",
    "label": "Desa Baru",
    "value": "Desa Baru"
  },
  {
    "id": "1503072004",
    "district_id": "150307",
    "label": "Semurung",
    "value": "Semurung"
  },
  {
    "id": "1503072005",
    "district_id": "150307",
    "label": "Lubuk Kepayang",
    "value": "Lubuk Kepayang"
  },
  {
    "id": "1503072006",
    "district_id": "150307",
    "label": "Pematang Kabau",
    "value": "Pematang Kabau"
  },
  {
    "id": "1503072007",
    "district_id": "150307",
    "label": "Bukit Suban",
    "value": "Bukit Suban"
  },
  {
    "id": "1503072008",
    "district_id": "150307",
    "label": "Mentawak Baru",
    "value": "Mentawak Baru"
  },
  {
    "id": "1503072009",
    "district_id": "150307",
    "label": "Mentawak Ulu",
    "value": "Mentawak Ulu"
  },
  {
    "id": "1503081011",
    "district_id": "150308",
    "label": "Limbur Tembesi",
    "value": "Limbur Tembesi"
  },
  {
    "id": "1503082001",
    "district_id": "150308",
    "label": "Rantau Gedang",
    "value": "Rantau Gedang"
  },
  {
    "id": "1503082002",
    "district_id": "150308",
    "label": "Penarun",
    "value": "Penarun"
  },
  {
    "id": "1503082003",
    "district_id": "150308",
    "label": "Pulau Lintang",
    "value": "Pulau Lintang"
  },
  {
    "id": "1503082004",
    "district_id": "150308",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1503082005",
    "district_id": "150308",
    "label": "Bangun Jayo",
    "value": "Bangun Jayo"
  },
  {
    "id": "1503082006",
    "district_id": "150308",
    "label": "Teluk Kecimbung",
    "value": "Teluk Kecimbung"
  },
  {
    "id": "1503082007",
    "district_id": "150308",
    "label": "Pulaumelako",
    "value": "Pulaumelako"
  },
  {
    "id": "1503082008",
    "district_id": "150308",
    "label": "Batu Penyabung",
    "value": "Batu Penyabung"
  },
  {
    "id": "1503082009",
    "district_id": "150308",
    "label": "Tanjunggagak",
    "value": "Tanjunggagak"
  },
  {
    "id": "1503082010",
    "district_id": "150308",
    "label": "Pulaubuayo",
    "value": "Pulaubuayo"
  },
  {
    "id": "1503082012",
    "district_id": "150308",
    "label": "Dusun Dalam",
    "value": "Dusun Dalam"
  },
  {
    "id": "1503082013",
    "district_id": "150308",
    "label": "Muaralati",
    "value": "Muaralati"
  },
  {
    "id": "1503082014",
    "district_id": "150308",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1503082015",
    "district_id": "150308",
    "label": "Teluk Mancur",
    "value": "Teluk Mancur"
  },
  {
    "id": "1503091001",
    "district_id": "150309",
    "label": "Sungaibenteng",
    "value": "Sungaibenteng"
  },
  {
    "id": "1503092002",
    "district_id": "150309",
    "label": "Bukittigo",
    "value": "Bukittigo"
  },
  {
    "id": "1503092003",
    "district_id": "150309",
    "label": "Sungaigedang",
    "value": "Sungaigedang"
  },
  {
    "id": "1503092004",
    "district_id": "150309",
    "label": "Payo Lebar",
    "value": "Payo Lebar"
  },
  {
    "id": "1503092005",
    "district_id": "150309",
    "label": "Bukitmurau",
    "value": "Bukitmurau"
  },
  {
    "id": "1503092006",
    "district_id": "150309",
    "label": "Perdamaian",
    "value": "Perdamaian"
  },
  {
    "id": "1503092007",
    "district_id": "150309",
    "label": "Pasar Singkut",
    "value": "Pasar Singkut"
  },
  {
    "id": "1503092008",
    "district_id": "150309",
    "label": "Siliwangi",
    "value": "Siliwangi"
  },
  {
    "id": "1503092009",
    "district_id": "150309",
    "label": "Simpang Nibung",
    "value": "Simpang Nibung"
  },
  {
    "id": "1503092010",
    "district_id": "150309",
    "label": "Bukit Talang Mas",
    "value": "Bukit Talang Mas"
  },
  {
    "id": "1503092011",
    "district_id": "150309",
    "label": "Bukit Bumi Raya",
    "value": "Bukit Bumi Raya"
  },
  {
    "id": "1503092012",
    "district_id": "150309",
    "label": "Argo Sari",
    "value": "Argo Sari"
  },
  {
    "id": "1503092013",
    "district_id": "150309",
    "label": "Sendang Sari",
    "value": "Sendang Sari"
  },
  {
    "id": "1503102001",
    "district_id": "150310",
    "label": "Kampung Tujuh",
    "value": "Kampung Tujuh"
  },
  {
    "id": "1503102002",
    "district_id": "150310",
    "label": "Lubuk Resam",
    "value": "Lubuk Resam"
  },
  {
    "id": "1503102003",
    "district_id": "150310",
    "label": "Telukrendah",
    "value": "Telukrendah"
  },
  {
    "id": "1503102004",
    "district_id": "150310",
    "label": "Teluktigo",
    "value": "Teluktigo"
  },
  {
    "id": "1503102005",
    "district_id": "150310",
    "label": "Lubuk Resam Hilir",
    "value": "Lubuk Resam Hilir"
  },
  {
    "id": "1503102006",
    "district_id": "150310",
    "label": "Pemuncak",
    "value": "Pemuncak"
  },
  {
    "id": "1503102007",
    "district_id": "150310",
    "label": "Tambang Tinggi",
    "value": "Tambang Tinggi"
  },
  {
    "id": "1503102008",
    "district_id": "150310",
    "label": "Tendah",
    "value": "Tendah"
  },
  {
    "id": "1503102009",
    "district_id": "150310",
    "label": "Sungaikeramat",
    "value": "Sungaikeramat"
  },
  {
    "id": "1503102010",
    "district_id": "150310",
    "label": "Sekamis",
    "value": "Sekamis"
  },
  {
    "id": "1504011001",
    "district_id": "150401",
    "label": "Kembang Paseban",
    "value": "Kembang Paseban"
  },
  {
    "id": "1504012002",
    "district_id": "150401",
    "label": "Sengkati Kecil",
    "value": "Sengkati Kecil"
  },
  {
    "id": "1504012003",
    "district_id": "150401",
    "label": "Sengkati Gedang",
    "value": "Sengkati Gedang"
  },
  {
    "id": "1504012004",
    "district_id": "150401",
    "label": "Sengkati Baru",
    "value": "Sengkati Baru"
  },
  {
    "id": "1504012005",
    "district_id": "150401",
    "label": "Kembang Tanjung",
    "value": "Kembang Tanjung"
  },
  {
    "id": "1504012006",
    "district_id": "150401",
    "label": "Mersam",
    "value": "Mersam"
  },
  {
    "id": "1504012007",
    "district_id": "150401",
    "label": "Benteng Rendah",
    "value": "Benteng Rendah"
  },
  {
    "id": "1504012008",
    "district_id": "150401",
    "label": "Sungai Puar",
    "value": "Sungai Puar"
  },
  {
    "id": "1504012009",
    "district_id": "150401",
    "label": "Rantau Gedang",
    "value": "Rantau Gedang"
  },
  {
    "id": "1504012010",
    "district_id": "150401",
    "label": "Pematang Gadung",
    "value": "Pematang Gadung"
  },
  {
    "id": "1504012011",
    "district_id": "150401",
    "label": "Teluk Melintang",
    "value": "Teluk Melintang"
  },
  {
    "id": "1504012012",
    "district_id": "150401",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "1504012013",
    "district_id": "150401",
    "label": "Belanti Jaya",
    "value": "Belanti Jaya"
  },
  {
    "id": "1504012014",
    "district_id": "150401",
    "label": "Tapah Sari",
    "value": "Tapah Sari"
  },
  {
    "id": "1504012015",
    "district_id": "150401",
    "label": "Bukit Kemuning",
    "value": "Bukit Kemuning"
  },
  {
    "id": "1504012016",
    "district_id": "150401",
    "label": "Simpang Rantau Gedang",
    "value": "Simpang Rantau Gedang"
  },
  {
    "id": "1504012017",
    "district_id": "150401",
    "label": "Tanjung Putra",
    "value": "Tanjung Putra"
  },
  {
    "id": "1504012018",
    "district_id": "150401",
    "label": "Sengkati Mudo",
    "value": "Sengkati Mudo"
  },
  {
    "id": "1504021001",
    "district_id": "150402",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1504021002",
    "district_id": "150402",
    "label": "Pasar Muara Tembesi",
    "value": "Pasar Muara Tembesi"
  },
  {
    "id": "1504022003",
    "district_id": "150402",
    "label": "Jebak",
    "value": "Jebak"
  },
  {
    "id": "1504022004",
    "district_id": "150402",
    "label": "Ampelu",
    "value": "Ampelu"
  },
  {
    "id": "1504022005",
    "district_id": "150402",
    "label": "Rambutan Masam",
    "value": "Rambutan Masam"
  },
  {
    "id": "1504022006",
    "district_id": "150402",
    "label": "Rantau Kapas Mudo",
    "value": "Rantau Kapas Mudo"
  },
  {
    "id": "1504022007",
    "district_id": "150402",
    "label": "Rantau Kapas Tuo",
    "value": "Rantau Kapas Tuo"
  },
  {
    "id": "1504022008",
    "district_id": "150402",
    "label": "Sungai Pulai",
    "value": "Sungai Pulai"
  },
  {
    "id": "1504022009",
    "district_id": "150402",
    "label": "Pulau",
    "value": "Pulau"
  },
  {
    "id": "1504022010",
    "district_id": "150402",
    "label": "Tanjung Marwo",
    "value": "Tanjung Marwo"
  },
  {
    "id": "1504022011",
    "district_id": "150402",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "1504022012",
    "district_id": "150402",
    "label": "Ampelu Mudo",
    "value": "Ampelu Mudo"
  },
  {
    "id": "1504022013",
    "district_id": "150402",
    "label": "Pelayangan",
    "value": "Pelayangan"
  },
  {
    "id": "1504022015",
    "district_id": "150402",
    "label": "Pematang Lima Suku",
    "value": "Pematang Lima Suku"
  },
  {
    "id": "1504031001",
    "district_id": "150403",
    "label": "Muara Bulian",
    "value": "Muara Bulian"
  },
  {
    "id": "1504031002",
    "district_id": "150403",
    "label": "Teratai",
    "value": "Teratai"
  },
  {
    "id": "1504031003",
    "district_id": "150403",
    "label": "Sridadi",
    "value": "Sridadi"
  },
  {
    "id": "1504031004",
    "district_id": "150403",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1504031005",
    "district_id": "150403",
    "label": "Rengas Condong",
    "value": "Rengas Condong"
  },
  {
    "id": "1504032006",
    "district_id": "150403",
    "label": "Pasar Terusan",
    "value": "Pasar Terusan"
  },
  {
    "id": "1504032007",
    "district_id": "150403",
    "label": "Simpang Terusan",
    "value": "Simpang Terusan"
  },
  {
    "id": "1504032008",
    "district_id": "150403",
    "label": "Malapari",
    "value": "Malapari"
  },
  {
    "id": "1504032009",
    "district_id": "150403",
    "label": "Napal Sisik",
    "value": "Napal Sisik"
  },
  {
    "id": "1504032010",
    "district_id": "150403",
    "label": "Rambahan",
    "value": "Rambahan"
  },
  {
    "id": "1504032011",
    "district_id": "150403",
    "label": "Olak",
    "value": "Olak"
  },
  {
    "id": "1504032012",
    "district_id": "150403",
    "label": "Muaro Singoan",
    "value": "Muaro Singoan"
  },
  {
    "id": "1504032013",
    "district_id": "150403",
    "label": "Aro",
    "value": "Aro"
  },
  {
    "id": "1504032014",
    "district_id": "150403",
    "label": "Rantau Puri",
    "value": "Rantau Puri"
  },
  {
    "id": "1504032015",
    "district_id": "150403",
    "label": "Sungai Baung",
    "value": "Sungai Baung"
  },
  {
    "id": "1504032016",
    "district_id": "150403",
    "label": "Bajubang Laut",
    "value": "Bajubang Laut"
  },
  {
    "id": "1504032017",
    "district_id": "150403",
    "label": "Singkawang",
    "value": "Singkawang"
  },
  {
    "id": "1504032018",
    "district_id": "150403",
    "label": "Tenam",
    "value": "Tenam"
  },
  {
    "id": "1504032019",
    "district_id": "150403",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "1504032020",
    "district_id": "150403",
    "label": "Kilangan",
    "value": "Kilangan"
  },
  {
    "id": "1504032021",
    "district_id": "150403",
    "label": "Pelayangan",
    "value": "Pelayangan"
  },
  {
    "id": "1504041003",
    "district_id": "150404",
    "label": "Durian Luncuk",
    "value": "Durian Luncuk"
  },
  {
    "id": "1504041012",
    "district_id": "150404",
    "label": "Muara Jangga",
    "value": "Muara Jangga"
  },
  {
    "id": "1504042001",
    "district_id": "150404",
    "label": "Jelutih",
    "value": "Jelutih"
  },
  {
    "id": "1504042002",
    "district_id": "150404",
    "label": "Olak Besar",
    "value": "Olak Besar"
  },
  {
    "id": "1504042004",
    "district_id": "150404",
    "label": "Aur Gading",
    "value": "Aur Gading"
  },
  {
    "id": "1504042005",
    "district_id": "150404",
    "label": "Paku Aji",
    "value": "Paku Aji"
  },
  {
    "id": "1504042006",
    "district_id": "150404",
    "label": "Hajran",
    "value": "Hajran"
  },
  {
    "id": "1504042007",
    "district_id": "150404",
    "label": "Mata Gual",
    "value": "Mata Gual"
  },
  {
    "id": "1504042008",
    "district_id": "150404",
    "label": "Jangga",
    "value": "Jangga"
  },
  {
    "id": "1504042009",
    "district_id": "150404",
    "label": "Koto Boyo",
    "value": "Koto Boyo"
  },
  {
    "id": "1504042010",
    "district_id": "150404",
    "label": "Simpang Karmeo",
    "value": "Simpang Karmeo"
  },
  {
    "id": "1504042011",
    "district_id": "150404",
    "label": "Karmeo",
    "value": "Karmeo"
  },
  {
    "id": "1504042013",
    "district_id": "150404",
    "label": "Jangga Baru",
    "value": "Jangga Baru"
  },
  {
    "id": "1504042014",
    "district_id": "150404",
    "label": "Terentang Baru",
    "value": "Terentang Baru"
  },
  {
    "id": "1504042015",
    "district_id": "150404",
    "label": "Bulian Baru",
    "value": "Bulian Baru"
  },
  {
    "id": "1504042016",
    "district_id": "150404",
    "label": "Simpang Jelutih",
    "value": "Simpang Jelutih"
  },
  {
    "id": "1504042017",
    "district_id": "150404",
    "label": "Simpang Aur Gading",
    "value": "Simpang Aur Gading"
  },
  {
    "id": "1504051002",
    "district_id": "150405",
    "label": "Jembatan Mas",
    "value": "Jembatan Mas"
  },
  {
    "id": "1504052001",
    "district_id": "150405",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1504052003",
    "district_id": "150405",
    "label": "Serasah",
    "value": "Serasah"
  },
  {
    "id": "1504052004",
    "district_id": "150405",
    "label": "Lubuk Ruso",
    "value": "Lubuk Ruso"
  },
  {
    "id": "1504052005",
    "district_id": "150405",
    "label": "Kuap",
    "value": "Kuap"
  },
  {
    "id": "1504052006",
    "district_id": "150405",
    "label": "Senaning",
    "value": "Senaning"
  },
  {
    "id": "1504052007",
    "district_id": "150405",
    "label": "Kubu Kandang",
    "value": "Kubu Kandang"
  },
  {
    "id": "1504052008",
    "district_id": "150405",
    "label": "Ture",
    "value": "Ture"
  },
  {
    "id": "1504052009",
    "district_id": "150405",
    "label": "Pulau Betung",
    "value": "Pulau Betung"
  },
  {
    "id": "1504052010",
    "district_id": "150405",
    "label": "Lopak Aur",
    "value": "Lopak Aur"
  },
  {
    "id": "1504052011",
    "district_id": "150405",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "1504052012",
    "district_id": "150405",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "1504052013",
    "district_id": "150405",
    "label": "Pulau Raman",
    "value": "Pulau Raman"
  },
  {
    "id": "1504052014",
    "district_id": "150405",
    "label": "Kaos",
    "value": "Kaos"
  },
  {
    "id": "1504052015",
    "district_id": "150405",
    "label": "Simpang Kubu Kandang",
    "value": "Simpang Kubu Kandang"
  },
  {
    "id": "1504052016",
    "district_id": "150405",
    "label": "Awin",
    "value": "Awin"
  },
  {
    "id": "1504052017",
    "district_id": "150405",
    "label": "Olak Rambahan",
    "value": "Olak Rambahan"
  },
  {
    "id": "1504052019",
    "district_id": "150405",
    "label": "Teluk Ketapang",
    "value": "Teluk Ketapang"
  },
  {
    "id": "1504052020",
    "district_id": "150405",
    "label": "Kampung Pulau",
    "value": "Kampung Pulau"
  },
  {
    "id": "1504061014",
    "district_id": "150406",
    "label": "Simpang S. Rengas",
    "value": "Simpang S. Rengas"
  },
  {
    "id": "1504062001",
    "district_id": "150406",
    "label": "Batu Sawar",
    "value": "Batu Sawar"
  },
  {
    "id": "1504062002",
    "district_id": "150406",
    "label": "Peninjauan",
    "value": "Peninjauan"
  },
  {
    "id": "1504062003",
    "district_id": "150406",
    "label": "Teluk Leban",
    "value": "Teluk Leban"
  },
  {
    "id": "1504062004",
    "district_id": "150406",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1504062005",
    "district_id": "150406",
    "label": "Rengas IX",
    "value": "Rengas IX"
  },
  {
    "id": "1504062006",
    "district_id": "150406",
    "label": "Kembang Seri",
    "value": "Kembang Seri"
  },
  {
    "id": "1504062007",
    "district_id": "150406",
    "label": "Buluh Kasab",
    "value": "Buluh Kasab"
  },
  {
    "id": "1504062008",
    "district_id": "150406",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1504062009",
    "district_id": "150406",
    "label": "Olak Kemang",
    "value": "Olak Kemang"
  },
  {
    "id": "1504062010",
    "district_id": "150406",
    "label": "Padang Kelapo",
    "value": "Padang Kelapo"
  },
  {
    "id": "1504062011",
    "district_id": "150406",
    "label": "Sungai Lingkar",
    "value": "Sungai Lingkar"
  },
  {
    "id": "1504062012",
    "district_id": "150406",
    "label": "Sungai Ruan Ulu",
    "value": "Sungai Ruan Ulu"
  },
  {
    "id": "1504062013",
    "district_id": "150406",
    "label": "Sungai Ruan Ilir",
    "value": "Sungai Ruan Ilir"
  },
  {
    "id": "1504062015",
    "district_id": "150406",
    "label": "Rawa Mekar",
    "value": "Rawa Mekar"
  },
  {
    "id": "1504062016",
    "district_id": "150406",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1504062017",
    "district_id": "150406",
    "label": "Kembang Seri Baru",
    "value": "Kembang Seri Baru"
  },
  {
    "id": "1504071004",
    "district_id": "150407",
    "label": "Bajubang",
    "value": "Bajubang"
  },
  {
    "id": "1504072001",
    "district_id": "150407",
    "label": "Bungku",
    "value": "Bungku"
  },
  {
    "id": "1504072002",
    "district_id": "150407",
    "label": "Pompa Air",
    "value": "Pompa Air"
  },
  {
    "id": "1504072003",
    "district_id": "150407",
    "label": "Ladang Peris",
    "value": "Ladang Peris"
  },
  {
    "id": "1504072005",
    "district_id": "150407",
    "label": "Penerokan",
    "value": "Penerokan"
  },
  {
    "id": "1504072006",
    "district_id": "150407",
    "label": "Petajen",
    "value": "Petajen"
  },
  {
    "id": "1504072007",
    "district_id": "150407",
    "label": "Batin",
    "value": "Batin"
  },
  {
    "id": "1504072008",
    "district_id": "150407",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1504072009",
    "district_id": "150407",
    "label": "Sungkai",
    "value": "Sungkai"
  },
  {
    "id": "1504072010",
    "district_id": "150407",
    "label": "Mekar Sari Nes",
    "value": "Mekar Sari Nes"
  },
  {
    "id": "1504081008",
    "district_id": "150408",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "1504082001",
    "district_id": "150408",
    "label": "Bulian Jaya",
    "value": "Bulian Jaya"
  },
  {
    "id": "1504082002",
    "district_id": "150408",
    "label": "Tidar Kuranji",
    "value": "Tidar Kuranji"
  },
  {
    "id": "1504082003",
    "district_id": "150408",
    "label": "Kehidupan Baru",
    "value": "Kehidupan Baru"
  },
  {
    "id": "1504082004",
    "district_id": "150408",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1504082005",
    "district_id": "150408",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1504082006",
    "district_id": "150408",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "1504082007",
    "district_id": "150408",
    "label": "Danau Embat",
    "value": "Danau Embat"
  },
  {
    "id": "1505011003",
    "district_id": "150501",
    "label": "Pijoan",
    "value": "Pijoan"
  },
  {
    "id": "1505012002",
    "district_id": "150501",
    "label": "Sungai Bertam",
    "value": "Sungai Bertam"
  },
  {
    "id": "1505012004",
    "district_id": "150501",
    "label": "Pematang Jering",
    "value": "Pematang Jering"
  },
  {
    "id": "1505012005",
    "district_id": "150501",
    "label": "Muaro Pijoan",
    "value": "Muaro Pijoan"
  },
  {
    "id": "1505012006",
    "district_id": "150501",
    "label": "Sungai Duren",
    "value": "Sungai Duren"
  },
  {
    "id": "1505012007",
    "district_id": "150501",
    "label": "Mendalo Darat",
    "value": "Mendalo Darat"
  },
  {
    "id": "1505012008",
    "district_id": "150501",
    "label": "Rengas Bandung",
    "value": "Rengas Bandung"
  },
  {
    "id": "1505012009",
    "district_id": "150501",
    "label": "Sarang Burung",
    "value": "Sarang Burung"
  },
  {
    "id": "1505012010",
    "district_id": "150501",
    "label": "Mendalo Laut",
    "value": "Mendalo Laut"
  },
  {
    "id": "1505012011",
    "district_id": "150501",
    "label": "Sembubuk",
    "value": "Sembubuk"
  },
  {
    "id": "1505012012",
    "district_id": "150501",
    "label": "Senaung",
    "value": "Senaung"
  },
  {
    "id": "1505012013",
    "district_id": "150501",
    "label": "Penyengat Olak",
    "value": "Penyengat Olak"
  },
  {
    "id": "1505012014",
    "district_id": "150501",
    "label": "Simpang Sungai Duren",
    "value": "Simpang Sungai Duren"
  },
  {
    "id": "1505012015",
    "district_id": "150501",
    "label": "Kedemangan",
    "value": "Kedemangan"
  },
  {
    "id": "1505012016",
    "district_id": "150501",
    "label": "Muhajirin",
    "value": "Muhajirin"
  },
  {
    "id": "1505012017",
    "district_id": "150501",
    "label": "Maro Sebo",
    "value": "Maro Sebo"
  },
  {
    "id": "1505012019",
    "district_id": "150501",
    "label": "Danau Sarang Elang",
    "value": "Danau Sarang Elang"
  },
  {
    "id": "1505012020",
    "district_id": "150501",
    "label": "Simpang Limo",
    "value": "Simpang Limo"
  },
  {
    "id": "1505012021",
    "district_id": "150501",
    "label": "Mendalo Indah",
    "value": "Mendalo Indah"
  },
  {
    "id": "1505012022",
    "district_id": "150501",
    "label": "Pematang Gajah",
    "value": "Pematang Gajah"
  },
  {
    "id": "1505021010",
    "district_id": "150502",
    "label": "Sengeti",
    "value": "Sengeti"
  },
  {
    "id": "1505022001",
    "district_id": "150502",
    "label": "Suak Putat",
    "value": "Suak Putat"
  },
  {
    "id": "1505022002",
    "district_id": "150502",
    "label": "Rantau Majo",
    "value": "Rantau Majo"
  },
  {
    "id": "1505022003",
    "district_id": "150502",
    "label": "Pulau Kayu Aro",
    "value": "Pulau Kayu Aro"
  },
  {
    "id": "1505022004",
    "district_id": "150502",
    "label": "Pematang Pulai",
    "value": "Pematang Pulai"
  },
  {
    "id": "1505022005",
    "district_id": "150502",
    "label": "Tan Tan",
    "value": "Tan Tan"
  },
  {
    "id": "1505022006",
    "district_id": "150502",
    "label": "Kedotan",
    "value": "Kedotan"
  },
  {
    "id": "1505022007",
    "district_id": "150502",
    "label": "Keranggan",
    "value": "Keranggan"
  },
  {
    "id": "1505022008",
    "district_id": "150502",
    "label": "Berembang",
    "value": "Berembang"
  },
  {
    "id": "1505022009",
    "district_id": "150502",
    "label": "Sekernan",
    "value": "Sekernan"
  },
  {
    "id": "1505022011",
    "district_id": "150502",
    "label": "Gerunggung",
    "value": "Gerunggung"
  },
  {
    "id": "1505022012",
    "district_id": "150502",
    "label": "Tunas Baru",
    "value": "Tunas Baru"
  },
  {
    "id": "1505022013",
    "district_id": "150502",
    "label": "Bukit Baling",
    "value": "Bukit Baling"
  },
  {
    "id": "1505022014",
    "district_id": "150502",
    "label": "Tanjung Lanjut",
    "value": "Tanjung Lanjut"
  },
  {
    "id": "1505022015",
    "district_id": "150502",
    "label": "Suko Awin Jaya",
    "value": "Suko Awin Jaya"
  },
  {
    "id": "1505022016",
    "district_id": "150502",
    "label": "Tunas Mudo",
    "value": "Tunas Mudo"
  },
  {
    "id": "1505031008",
    "district_id": "150503",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1505032001",
    "district_id": "150503",
    "label": "Puding",
    "value": "Puding"
  },
  {
    "id": "1505032002",
    "district_id": "150503",
    "label": "Pulau Mentaro",
    "value": "Pulau Mentaro"
  },
  {
    "id": "1505032003",
    "district_id": "150503",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1505032004",
    "district_id": "150503",
    "label": "Pematang Raman",
    "value": "Pematang Raman"
  },
  {
    "id": "1505032005",
    "district_id": "150503",
    "label": "Sungai Bungur",
    "value": "Sungai Bungur"
  },
  {
    "id": "1505032006",
    "district_id": "150503",
    "label": "Seponjen",
    "value": "Seponjen"
  },
  {
    "id": "1505032007",
    "district_id": "150503",
    "label": "Sogo",
    "value": "Sogo"
  },
  {
    "id": "1505032009",
    "district_id": "150503",
    "label": "Sungai Aur",
    "value": "Sungai Aur"
  },
  {
    "id": "1505032010",
    "district_id": "150503",
    "label": "Jebus",
    "value": "Jebus"
  },
  {
    "id": "1505032011",
    "district_id": "150503",
    "label": "Gedong Karya",
    "value": "Gedong Karya"
  },
  {
    "id": "1505032013",
    "district_id": "150503",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1505032014",
    "district_id": "150503",
    "label": "Londerang",
    "value": "Londerang"
  },
  {
    "id": "1505032016",
    "district_id": "150503",
    "label": "Petanang",
    "value": "Petanang"
  },
  {
    "id": "1505032017",
    "district_id": "150503",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1505032018",
    "district_id": "150503",
    "label": "Rondang",
    "value": "Rondang"
  },
  {
    "id": "1505032019",
    "district_id": "150503",
    "label": "Maju Jaya",
    "value": "Maju Jaya"
  },
  {
    "id": "1505041002",
    "district_id": "150504",
    "label": "Jambi Kecil",
    "value": "Jambi Kecil"
  },
  {
    "id": "1505042001",
    "district_id": "150504",
    "label": "Setiris",
    "value": "Setiris"
  },
  {
    "id": "1505042003",
    "district_id": "150504",
    "label": "Tanjung Katung",
    "value": "Tanjung Katung"
  },
  {
    "id": "1505042004",
    "district_id": "150504",
    "label": "Jambi Tulo",
    "value": "Jambi Tulo"
  },
  {
    "id": "1505042005",
    "district_id": "150504",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1505042006",
    "district_id": "150504",
    "label": "Danau Lamo",
    "value": "Danau Lamo"
  },
  {
    "id": "1505042007",
    "district_id": "150504",
    "label": "Muaro Jambi",
    "value": "Muaro Jambi"
  },
  {
    "id": "1505042016",
    "district_id": "150504",
    "label": "Niaso",
    "value": "Niaso"
  },
  {
    "id": "1505042017",
    "district_id": "150504",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "1505042018",
    "district_id": "150504",
    "label": "Danau Kedap",
    "value": "Danau Kedap"
  },
  {
    "id": "1505042019",
    "district_id": "150504",
    "label": "Mudung Darat",
    "value": "Mudung Darat"
  },
  {
    "id": "1505042020",
    "district_id": "150504",
    "label": "Lubuk Raman",
    "value": "Lubuk Raman"
  },
  {
    "id": "1505051009",
    "district_id": "150505",
    "label": "Tempino",
    "value": "Tempino"
  },
  {
    "id": "1505052001",
    "district_id": "150505",
    "label": "Tanjung Pauh Km. 32",
    "value": "Tanjung Pauh Km. 32"
  },
  {
    "id": "1505052002",
    "district_id": "150505",
    "label": "Pelempang",
    "value": "Pelempang"
  },
  {
    "id": "1505052003",
    "district_id": "150505",
    "label": "Sungai Landai",
    "value": "Sungai Landai"
  },
  {
    "id": "1505052004",
    "district_id": "150505",
    "label": "Ibru",
    "value": "Ibru"
  },
  {
    "id": "1505052005",
    "district_id": "150505",
    "label": "Nagasari",
    "value": "Nagasari"
  },
  {
    "id": "1505052006",
    "district_id": "150505",
    "label": "Sebapo",
    "value": "Sebapo"
  },
  {
    "id": "1505052010",
    "district_id": "150505",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1505052011",
    "district_id": "150505",
    "label": "Tanjung Pauh Km. 39",
    "value": "Tanjung Pauh Km. 39"
  },
  {
    "id": "1505052012",
    "district_id": "150505",
    "label": "Nyogan",
    "value": "Nyogan"
  },
  {
    "id": "1505052015",
    "district_id": "150505",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1505052016",
    "district_id": "150505",
    "label": "Pondok Meja",
    "value": "Pondok Meja"
  },
  {
    "id": "1505052017",
    "district_id": "150505",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1505052018",
    "district_id": "150505",
    "label": "Tanjung Pauh Talang Pelita",
    "value": "Tanjung Pauh Talang Pelita"
  },
  {
    "id": "1505052019",
    "district_id": "150505",
    "label": "Muaro Sebapo",
    "value": "Muaro Sebapo"
  },
  {
    "id": "1505062001",
    "district_id": "150506",
    "label": "Pudak",
    "value": "Pudak"
  },
  {
    "id": "1505062002",
    "district_id": "150506",
    "label": "Muara Kumpeh",
    "value": "Muara Kumpeh"
  },
  {
    "id": "1505062003",
    "district_id": "150506",
    "label": "Kota Karang",
    "value": "Kota Karang"
  },
  {
    "id": "1505062004",
    "district_id": "150506",
    "label": "Kasang Lopak Alai",
    "value": "Kasang Lopak Alai"
  },
  {
    "id": "1505062005",
    "district_id": "150506",
    "label": "Kasang Pudak",
    "value": "Kasang Pudak"
  },
  {
    "id": "1505062008",
    "district_id": "150506",
    "label": "Solok",
    "value": "Solok"
  },
  {
    "id": "1505062009",
    "district_id": "150506",
    "label": "Sakean",
    "value": "Sakean"
  },
  {
    "id": "1505062010",
    "district_id": "150506",
    "label": "Lopak Alai",
    "value": "Lopak Alai"
  },
  {
    "id": "1505062011",
    "district_id": "150506",
    "label": "Tarikan",
    "value": "Tarikan"
  },
  {
    "id": "1505062012",
    "district_id": "150506",
    "label": "Ramin",
    "value": "Ramin"
  },
  {
    "id": "1505062013",
    "district_id": "150506",
    "label": "Teluk Raya",
    "value": "Teluk Raya"
  },
  {
    "id": "1505062014",
    "district_id": "150506",
    "label": "Pemunduran",
    "value": "Pemunduran"
  },
  {
    "id": "1505062015",
    "district_id": "150506",
    "label": "Sipin Teluk Duren",
    "value": "Sipin Teluk Duren"
  },
  {
    "id": "1505062016",
    "district_id": "150506",
    "label": "Arang Arang",
    "value": "Arang Arang"
  },
  {
    "id": "1505062017",
    "district_id": "150506",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1505062018",
    "district_id": "150506",
    "label": "Sungai Terap",
    "value": "Sungai Terap"
  },
  {
    "id": "1505062023",
    "district_id": "150506",
    "label": "Kasang Kumpeh",
    "value": "Kasang Kumpeh"
  },
  {
    "id": "1505062024",
    "district_id": "150506",
    "label": "Kasang Kota Karang",
    "value": "Kasang Kota Karang"
  },
  {
    "id": "1505072004",
    "district_id": "150507",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1505072005",
    "district_id": "150507",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1505072006",
    "district_id": "150507",
    "label": "Panca Mulya",
    "value": "Panca Mulya"
  },
  {
    "id": "1505072007",
    "district_id": "150507",
    "label": "Marga Manunggal Jaya",
    "value": "Marga Manunggal Jaya"
  },
  {
    "id": "1505072012",
    "district_id": "150507",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1505072013",
    "district_id": "150507",
    "label": "Berkah",
    "value": "Berkah"
  },
  {
    "id": "1505072018",
    "district_id": "150507",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "1505072021",
    "district_id": "150507",
    "label": "Bukit Mas",
    "value": "Bukit Mas"
  },
  {
    "id": "1505072025",
    "district_id": "150507",
    "label": "Mekar Sari Makmur",
    "value": "Mekar Sari Makmur"
  },
  {
    "id": "1505072033",
    "district_id": "150507",
    "label": "Bakti Mulya",
    "value": "Bakti Mulya"
  },
  {
    "id": "1505072034",
    "district_id": "150507",
    "label": "Panca Bakti",
    "value": "Panca Bakti"
  },
  {
    "id": "1505082001",
    "district_id": "150508",
    "label": "Kebon IX",
    "value": "Kebon IX"
  },
  {
    "id": "1505082002",
    "district_id": "150508",
    "label": "Talang Belido",
    "value": "Talang Belido"
  },
  {
    "id": "1505082003",
    "district_id": "150508",
    "label": "Talang Kerinci",
    "value": "Talang Kerinci"
  },
  {
    "id": "1505082004",
    "district_id": "150508",
    "label": "Ladang Panjang",
    "value": "Ladang Panjang"
  },
  {
    "id": "1505082005",
    "district_id": "150508",
    "label": "Tangkit",
    "value": "Tangkit"
  },
  {
    "id": "1505082006",
    "district_id": "150508",
    "label": "Tangkit Baru",
    "value": "Tangkit Baru"
  },
  {
    "id": "1505082007",
    "district_id": "150508",
    "label": "Sungai Gelam",
    "value": "Sungai Gelam"
  },
  {
    "id": "1505082008",
    "district_id": "150508",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "1505082009",
    "district_id": "150508",
    "label": "Petaling Jaya",
    "value": "Petaling Jaya"
  },
  {
    "id": "1505082010",
    "district_id": "150508",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1505082011",
    "district_id": "150508",
    "label": "Mingkung Jaya",
    "value": "Mingkung Jaya"
  },
  {
    "id": "1505082012",
    "district_id": "150508",
    "label": "Trimulya Jaya",
    "value": "Trimulya Jaya"
  },
  {
    "id": "1505082013",
    "district_id": "150508",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1505082014",
    "district_id": "150508",
    "label": "Sido Mukti",
    "value": "Sido Mukti"
  },
  {
    "id": "1505082015",
    "district_id": "150508",
    "label": "Gambut Jaya",
    "value": "Gambut Jaya"
  },
  {
    "id": "1505092001",
    "district_id": "150509",
    "label": "Talang Bukit",
    "value": "Talang Bukit"
  },
  {
    "id": "1505092002",
    "district_id": "150509",
    "label": "Talang Datar",
    "value": "Talang Datar"
  },
  {
    "id": "1505092003",
    "district_id": "150509",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1505092004",
    "district_id": "150509",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1505092005",
    "district_id": "150509",
    "label": "Matra Manunggal",
    "value": "Matra Manunggal"
  },
  {
    "id": "1505092006",
    "district_id": "150509",
    "label": "Bukit Mulya",
    "value": "Bukit Mulya"
  },
  {
    "id": "1505092007",
    "district_id": "150509",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1505092008",
    "district_id": "150509",
    "label": "Pinang Tinggi",
    "value": "Pinang Tinggi"
  },
  {
    "id": "1505092009",
    "district_id": "150509",
    "label": "Markanding",
    "value": "Markanding"
  },
  {
    "id": "1505092010",
    "district_id": "150509",
    "label": "Sungai Dayo",
    "value": "Sungai Dayo"
  },
  {
    "id": "1505092011",
    "district_id": "150509",
    "label": "Bahar Mulya",
    "value": "Bahar Mulya"
  },
  {
    "id": "1505102001",
    "district_id": "150510",
    "label": "Bukit Subur",
    "value": "Bukit Subur"
  },
  {
    "id": "1505102002",
    "district_id": "150510",
    "label": "Tri Jaya",
    "value": "Tri Jaya"
  },
  {
    "id": "1505102003",
    "district_id": "150510",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1505102004",
    "district_id": "150510",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1505102005",
    "district_id": "150510",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1505102006",
    "district_id": "150510",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1505102007",
    "district_id": "150510",
    "label": "Adipura Kencana",
    "value": "Adipura Kencana"
  },
  {
    "id": "1505102008",
    "district_id": "150510",
    "label": "Bukit Jaya",
    "value": "Bukit Jaya"
  },
  {
    "id": "1505102009",
    "district_id": "150510",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1505102010",
    "district_id": "150510",
    "label": "Tanjung Lebar",
    "value": "Tanjung Lebar"
  },
  {
    "id": "1505112001",
    "district_id": "150511",
    "label": "Kunangan",
    "value": "Kunangan"
  },
  {
    "id": "1505112002",
    "district_id": "150511",
    "label": "Talang Duku",
    "value": "Talang Duku"
  },
  {
    "id": "1505112003",
    "district_id": "150511",
    "label": "Tebat Patah",
    "value": "Tebat Patah"
  },
  {
    "id": "1505112004",
    "district_id": "150511",
    "label": "Kemingking Dalam",
    "value": "Kemingking Dalam"
  },
  {
    "id": "1505112005",
    "district_id": "150511",
    "label": "Teluk Jambu",
    "value": "Teluk Jambu"
  },
  {
    "id": "1505112006",
    "district_id": "150511",
    "label": "Dusun Mudo",
    "value": "Dusun Mudo"
  },
  {
    "id": "1505112007",
    "district_id": "150511",
    "label": "Sekumbung",
    "value": "Sekumbung"
  },
  {
    "id": "1505112008",
    "district_id": "150511",
    "label": "Kemingking Luar",
    "value": "Kemingking Luar"
  },
  {
    "id": "1505112009",
    "district_id": "150511",
    "label": "Rukam",
    "value": "Rukam"
  },
  {
    "id": "1505112010",
    "district_id": "150511",
    "label": "Manis Mato",
    "value": "Manis Mato"
  },
  {
    "id": "1506011005",
    "district_id": "150601",
    "label": "Pelabuhan Dagang",
    "value": "Pelabuhan Dagang"
  },
  {
    "id": "1506012001",
    "district_id": "150601",
    "label": "Badang",
    "value": "Badang"
  },
  {
    "id": "1506012002",
    "district_id": "150601",
    "label": "Tanjungtayas",
    "value": "Tanjungtayas"
  },
  {
    "id": "1506012003",
    "district_id": "150601",
    "label": "Kuala Dasal",
    "value": "Kuala Dasal"
  },
  {
    "id": "1506012004",
    "district_id": "150601",
    "label": "Pematang Pauh",
    "value": "Pematang Pauh"
  },
  {
    "id": "1506012006",
    "district_id": "150601",
    "label": "Taman Raja",
    "value": "Taman Raja"
  },
  {
    "id": "1506012017",
    "district_id": "150601",
    "label": "Brasau",
    "value": "Brasau"
  },
  {
    "id": "1506012019",
    "district_id": "150601",
    "label": "Gemuruh",
    "value": "Gemuruh"
  },
  {
    "id": "1506012020",
    "district_id": "150601",
    "label": "Pematang Tembesu",
    "value": "Pematang Tembesu"
  },
  {
    "id": "1506012021",
    "district_id": "150601",
    "label": "Badang Sepakat",
    "value": "Badang Sepakat"
  },
  {
    "id": "1506021004",
    "district_id": "150602",
    "label": "Tungkal Empat Kota",
    "value": "Tungkal Empat Kota"
  },
  {
    "id": "1506021005",
    "district_id": "150602",
    "label": "Tungkal III",
    "value": "Tungkal III"
  },
  {
    "id": "1506021006",
    "district_id": "150602",
    "label": "Tungkal Harapan",
    "value": "Tungkal Harapan"
  },
  {
    "id": "1506021007",
    "district_id": "150602",
    "label": "Tungkal II",
    "value": "Tungkal II"
  },
  {
    "id": "1506021015",
    "district_id": "150602",
    "label": "Kampung Nelayan",
    "value": "Kampung Nelayan"
  },
  {
    "id": "1506021016",
    "district_id": "150602",
    "label": "Patunas",
    "value": "Patunas"
  },
  {
    "id": "1506021017",
    "district_id": "150602",
    "label": "Sriwijaya",
    "value": "Sriwijaya"
  },
  {
    "id": "1506021018",
    "district_id": "150602",
    "label": "Sungainibung",
    "value": "Sungainibung"
  },
  {
    "id": "1506022003",
    "district_id": "150602",
    "label": "Tungkal I",
    "value": "Tungkal I"
  },
  {
    "id": "1506022014",
    "district_id": "150602",
    "label": "Teluksialang",
    "value": "Teluksialang"
  },
  {
    "id": "1506031001",
    "district_id": "150603",
    "label": "Teluknilau",
    "value": "Teluknilau"
  },
  {
    "id": "1506032006",
    "district_id": "150603",
    "label": "Parit Pudin",
    "value": "Parit Pudin"
  },
  {
    "id": "1506032007",
    "district_id": "150603",
    "label": "Sungaiserindit",
    "value": "Sungaiserindit"
  },
  {
    "id": "1506032008",
    "district_id": "150603",
    "label": "Mekar Jati",
    "value": "Mekar Jati"
  },
  {
    "id": "1506032012",
    "district_id": "150603",
    "label": "Sungaipampang",
    "value": "Sungaipampang"
  },
  {
    "id": "1506032013",
    "district_id": "150603",
    "label": "Parit Sidang",
    "value": "Parit Sidang"
  },
  {
    "id": "1506032014",
    "district_id": "150603",
    "label": "Sungaijering",
    "value": "Sungaijering"
  },
  {
    "id": "1506032015",
    "district_id": "150603",
    "label": "Parit Bilal",
    "value": "Parit Bilal"
  },
  {
    "id": "1506032016",
    "district_id": "150603",
    "label": "SuakSamin",
    "value": "SuakSamin"
  },
  {
    "id": "1506032017",
    "district_id": "150603",
    "label": "Sungaibaung",
    "value": "Sungaibaung"
  },
  {
    "id": "1506032018",
    "district_id": "150603",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "1506032019",
    "district_id": "150603",
    "label": "Sungairaya",
    "value": "Sungairaya"
  },
  {
    "id": "1506032020",
    "district_id": "150603",
    "label": "Pasar Senin",
    "value": "Pasar Senin"
  },
  {
    "id": "1506041007",
    "district_id": "150604",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1506042001",
    "district_id": "150604",
    "label": "Pematang Lumut",
    "value": "Pematang Lumut"
  },
  {
    "id": "1506042008",
    "district_id": "150604",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1506042009",
    "district_id": "150604",
    "label": "Serdang Jaya",
    "value": "Serdang Jaya"
  },
  {
    "id": "1506042010",
    "district_id": "150604",
    "label": "Terjun Gajah",
    "value": "Terjun Gajah"
  },
  {
    "id": "1506042011",
    "district_id": "150604",
    "label": "Lubuk Terentang",
    "value": "Lubuk Terentang"
  },
  {
    "id": "1506042012",
    "district_id": "150604",
    "label": "Pematang Buluh",
    "value": "Pematang Buluh"
  },
  {
    "id": "1506042013",
    "district_id": "150604",
    "label": "Muntialo",
    "value": "Muntialo"
  },
  {
    "id": "1506042014",
    "district_id": "150604",
    "label": "Telukkulbi",
    "value": "Telukkulbi"
  },
  {
    "id": "1506042015",
    "district_id": "150604",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1506042016",
    "district_id": "150604",
    "label": "Sungaiterap",
    "value": "Sungaiterap"
  },
  {
    "id": "1506042017",
    "district_id": "150604",
    "label": "Mandala Jaya",
    "value": "Mandala Jaya"
  },
  {
    "id": "1506051007",
    "district_id": "150605",
    "label": "Merlung",
    "value": "Merlung"
  },
  {
    "id": "1506052005",
    "district_id": "150605",
    "label": "Penyabungan",
    "value": "Penyabungan"
  },
  {
    "id": "1506052006",
    "district_id": "150605",
    "label": "Lubuk Terap",
    "value": "Lubuk Terap"
  },
  {
    "id": "1506052008",
    "district_id": "150605",
    "label": "Tanjungpaku",
    "value": "Tanjungpaku"
  },
  {
    "id": "1506052012",
    "district_id": "150605",
    "label": "Tanjungbenanak",
    "value": "Tanjungbenanak"
  },
  {
    "id": "1506052013",
    "district_id": "150605",
    "label": "Bukitharapan",
    "value": "Bukitharapan"
  },
  {
    "id": "1506052014",
    "district_id": "150605",
    "label": "Adi Purwa",
    "value": "Adi Purwa"
  },
  {
    "id": "1506052018",
    "district_id": "150605",
    "label": "Pinang Gading",
    "value": "Pinang Gading"
  },
  {
    "id": "1506052020",
    "district_id": "150605",
    "label": "Merlung",
    "value": "Merlung"
  },
  {
    "id": "1506052021",
    "district_id": "150605",
    "label": "Tanjungmakmur",
    "value": "Tanjungmakmur"
  },
  {
    "id": "1506061001",
    "district_id": "150606",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1506062002",
    "district_id": "150606",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1506062003",
    "district_id": "150606",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1506062004",
    "district_id": "150606",
    "label": "Adi Jaya",
    "value": "Adi Jaya"
  },
  {
    "id": "1506062005",
    "district_id": "150606",
    "label": "Kelagian",
    "value": "Kelagian"
  },
  {
    "id": "1506062006",
    "district_id": "150606",
    "label": "Telukpengkah",
    "value": "Telukpengkah"
  },
  {
    "id": "1506062007",
    "district_id": "150606",
    "label": "Talang Makmur",
    "value": "Talang Makmur"
  },
  {
    "id": "1506062008",
    "district_id": "150606",
    "label": "Sungaikeruh",
    "value": "Sungaikeruh"
  },
  {
    "id": "1506062009",
    "district_id": "150606",
    "label": "Dataran Kempas",
    "value": "Dataran Kempas"
  },
  {
    "id": "1506062010",
    "district_id": "150606",
    "label": "Delima",
    "value": "Delima"
  },
  {
    "id": "1506071001",
    "district_id": "150607",
    "label": "Dusun Kebun",
    "value": "Dusun Kebun"
  },
  {
    "id": "1506072002",
    "district_id": "150607",
    "label": "Sri Agung",
    "value": "Sri Agung"
  },
  {
    "id": "1506072003",
    "district_id": "150607",
    "label": "Suban",
    "value": "Suban"
  },
  {
    "id": "1506072004",
    "district_id": "150607",
    "label": "Tanjungbojo",
    "value": "Tanjungbojo"
  },
  {
    "id": "1506072005",
    "district_id": "150607",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1506072006",
    "district_id": "150607",
    "label": "Lubuk Bernai",
    "value": "Lubuk Bernai"
  },
  {
    "id": "1506072007",
    "district_id": "150607",
    "label": "Rawamedang",
    "value": "Rawamedang"
  },
  {
    "id": "1506072008",
    "district_id": "150607",
    "label": "Sungaipenoban",
    "value": "Sungaipenoban"
  },
  {
    "id": "1506072009",
    "district_id": "150607",
    "label": "Rawangkempas",
    "value": "Rawangkempas"
  },
  {
    "id": "1506072010",
    "district_id": "150607",
    "label": "Lubuk Lawas",
    "value": "Lubuk Lawas"
  },
  {
    "id": "1506072011",
    "district_id": "150607",
    "label": "Sungaibadar",
    "value": "Sungaibadar"
  },
  {
    "id": "1506081001",
    "district_id": "150608",
    "label": "Lubuk Kambing",
    "value": "Lubuk Kambing"
  },
  {
    "id": "1506082002",
    "district_id": "150608",
    "label": "Pulaupauh",
    "value": "Pulaupauh"
  },
  {
    "id": "1506082003",
    "district_id": "150608",
    "label": "Rantau Benar",
    "value": "Rantau Benar"
  },
  {
    "id": "1506082004",
    "district_id": "150608",
    "label": "Lampisi",
    "value": "Lampisi"
  },
  {
    "id": "1506082005",
    "district_id": "150608",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1506082006",
    "district_id": "150608",
    "label": "Sungairotan",
    "value": "Sungairotan"
  },
  {
    "id": "1506082007",
    "district_id": "150608",
    "label": "Muaradanau",
    "value": "Muaradanau"
  },
  {
    "id": "1506082008",
    "district_id": "150608",
    "label": "Tanahtumbuh",
    "value": "Tanahtumbuh"
  },
  {
    "id": "1506082009",
    "district_id": "150608",
    "label": "Sungaipaur",
    "value": "Sungaipaur"
  },
  {
    "id": "1506082010",
    "district_id": "150608",
    "label": "Bukitbakar",
    "value": "Bukitbakar"
  },
  {
    "id": "1506091001",
    "district_id": "150609",
    "label": "Rantau Badak",
    "value": "Rantau Badak"
  },
  {
    "id": "1506092002",
    "district_id": "150609",
    "label": "Dusun Mudo",
    "value": "Dusun Mudo"
  },
  {
    "id": "1506092003",
    "district_id": "150609",
    "label": "Intan Jaya",
    "value": "Intan Jaya"
  },
  {
    "id": "1506092004",
    "district_id": "150609",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "1506092005",
    "district_id": "150609",
    "label": "Kemang Manis",
    "value": "Kemang Manis"
  },
  {
    "id": "1506092006",
    "district_id": "150609",
    "label": "Rantau Badak Lamo",
    "value": "Rantau Badak Lamo"
  },
  {
    "id": "1506092007",
    "district_id": "150609",
    "label": "Lubuk Sebontan",
    "value": "Lubuk Sebontan"
  },
  {
    "id": "1506092008",
    "district_id": "150609",
    "label": "Sungaipapauh",
    "value": "Sungaipapauh"
  },
  {
    "id": "1506092009",
    "district_id": "150609",
    "label": "Sungaimuluk",
    "value": "Sungaimuluk"
  },
  {
    "id": "1506092010",
    "district_id": "150609",
    "label": "Pematang Balam",
    "value": "Pematang Balam"
  },
  {
    "id": "1506101001",
    "district_id": "150610",
    "label": "Tungkal V",
    "value": "Tungkal V"
  },
  {
    "id": "1506102002",
    "district_id": "150610",
    "label": "Tungkal IV Desa",
    "value": "Tungkal IV Desa"
  },
  {
    "id": "1506102003",
    "district_id": "150610",
    "label": "Kuala Baru",
    "value": "Kuala Baru"
  },
  {
    "id": "1506102004",
    "district_id": "150610",
    "label": "Telukpulai Raya",
    "value": "Telukpulai Raya"
  },
  {
    "id": "1506102005",
    "district_id": "150610",
    "label": "Mekar Alam",
    "value": "Mekar Alam"
  },
  {
    "id": "1506102006",
    "district_id": "150610",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1506102007",
    "district_id": "150610",
    "label": "Kuala Kahar",
    "value": "Kuala Kahar"
  },
  {
    "id": "1506102008",
    "district_id": "150610",
    "label": "Muaraseberang",
    "value": "Muaraseberang"
  },
  {
    "id": "1506111001",
    "district_id": "150611",
    "label": "Bram Itam Kiri",
    "value": "Bram Itam Kiri"
  },
  {
    "id": "1506112002",
    "district_id": "150611",
    "label": "Bram Itam Kanan",
    "value": "Bram Itam Kanan"
  },
  {
    "id": "1506112003",
    "district_id": "150611",
    "label": "Tanjungsenjulang",
    "value": "Tanjungsenjulang"
  },
  {
    "id": "1506112004",
    "district_id": "150611",
    "label": "Pembengis",
    "value": "Pembengis"
  },
  {
    "id": "1506112005",
    "district_id": "150611",
    "label": "Bram Itam Raya",
    "value": "Bram Itam Raya"
  },
  {
    "id": "1506112006",
    "district_id": "150611",
    "label": "Pantaigading",
    "value": "Pantaigading"
  },
  {
    "id": "1506112007",
    "district_id": "150611",
    "label": "Jati Emas",
    "value": "Jati Emas"
  },
  {
    "id": "1506112008",
    "district_id": "150611",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "1506112009",
    "district_id": "150611",
    "label": "Mekar Tanjung",
    "value": "Mekar Tanjung"
  },
  {
    "id": "1506112010",
    "district_id": "150611",
    "label": "Semau",
    "value": "Semau"
  },
  {
    "id": "1506121001",
    "district_id": "150612",
    "label": "Betara Kiri",
    "value": "Betara Kiri"
  },
  {
    "id": "1506122002",
    "district_id": "150612",
    "label": "Sungaidualap",
    "value": "Sungaidualap"
  },
  {
    "id": "1506122003",
    "district_id": "150612",
    "label": "Betara Kanan",
    "value": "Betara Kanan"
  },
  {
    "id": "1506122004",
    "district_id": "150612",
    "label": "Sungaigebar",
    "value": "Sungaigebar"
  },
  {
    "id": "1506122005",
    "district_id": "150612",
    "label": "Dataran Pinang",
    "value": "Dataran Pinang"
  },
  {
    "id": "1506122006",
    "district_id": "150612",
    "label": "Tanjungpasir",
    "value": "Tanjungpasir"
  },
  {
    "id": "1506122007",
    "district_id": "150612",
    "label": "Sungaigebar Barat",
    "value": "Sungaigebar Barat"
  },
  {
    "id": "1506122008",
    "district_id": "150612",
    "label": "Sungaidungun",
    "value": "Sungaidungun"
  },
  {
    "id": "1506122009",
    "district_id": "150612",
    "label": "Kuala Indah",
    "value": "Kuala Indah"
  },
  {
    "id": "1506122010",
    "district_id": "150612",
    "label": "Suak Labu",
    "value": "Suak Labu"
  },
  {
    "id": "1506131001",
    "district_id": "150613",
    "label": "Senyerang",
    "value": "Senyerang"
  },
  {
    "id": "1506132002",
    "district_id": "150613",
    "label": "Sungaikayu Aro",
    "value": "Sungaikayu Aro"
  },
  {
    "id": "1506132003",
    "district_id": "150613",
    "label": "Telukketapang",
    "value": "Telukketapang"
  },
  {
    "id": "1506132004",
    "district_id": "150613",
    "label": "Sungairambai",
    "value": "Sungairambai"
  },
  {
    "id": "1506132005",
    "district_id": "150613",
    "label": "Margo Rukun",
    "value": "Margo Rukun"
  },
  {
    "id": "1506132006",
    "district_id": "150613",
    "label": "Kempas Jaya",
    "value": "Kempas Jaya"
  },
  {
    "id": "1506132007",
    "district_id": "150613",
    "label": "Lumahan",
    "value": "Lumahan"
  },
  {
    "id": "1506132008",
    "district_id": "150613",
    "label": "Sungailandak",
    "value": "Sungailandak"
  },
  {
    "id": "1506132009",
    "district_id": "150613",
    "label": "Sungsang",
    "value": "Sungsang"
  },
  {
    "id": "1506132010",
    "district_id": "150613",
    "label": "Sungaikepayang",
    "value": "Sungaikepayang"
  },
  {
    "id": "1507011010",
    "district_id": "150701",
    "label": "Muara Sabak Ilir",
    "value": "Muara Sabak Ilir"
  },
  {
    "id": "1507011013",
    "district_id": "150701",
    "label": "Muara Sabak Ulu",
    "value": "Muara Sabak Ulu"
  },
  {
    "id": "1507012001",
    "district_id": "150701",
    "label": "Alang-Alang",
    "value": "Alang-Alang"
  },
  {
    "id": "1507012002",
    "district_id": "150701",
    "label": "Lambur",
    "value": "Lambur"
  },
  {
    "id": "1507012004",
    "district_id": "150701",
    "label": "Simbur Naik",
    "value": "Simbur Naik"
  },
  {
    "id": "1507012011",
    "district_id": "150701",
    "label": "Lambur I",
    "value": "Lambur I"
  },
  {
    "id": "1507012012",
    "district_id": "150701",
    "label": "Lambur II",
    "value": "Lambur II"
  },
  {
    "id": "1507012014",
    "district_id": "150701",
    "label": "Kota Raja",
    "value": "Kota Raja"
  },
  {
    "id": "1507012015",
    "district_id": "150701",
    "label": "Siau Dalam",
    "value": "Siau Dalam"
  },
  {
    "id": "1507012016",
    "district_id": "150701",
    "label": "Sungai Ular",
    "value": "Sungai Ular"
  },
  {
    "id": "1507012017",
    "district_id": "150701",
    "label": "Kota Harapan",
    "value": "Kota Harapan"
  },
  {
    "id": "1507012018",
    "district_id": "150701",
    "label": "Kuala Simbur",
    "value": "Kuala Simbur"
  },
  {
    "id": "1507021001",
    "district_id": "150702",
    "label": "Nipah Panjang I",
    "value": "Nipah Panjang I"
  },
  {
    "id": "1507021002",
    "district_id": "150702",
    "label": "Nipah Panjang II",
    "value": "Nipah Panjang II"
  },
  {
    "id": "1507022003",
    "district_id": "150702",
    "label": "Simpang Jelita",
    "value": "Simpang Jelita"
  },
  {
    "id": "1507022004",
    "district_id": "150702",
    "label": "Simpang Datuk",
    "value": "Simpang Datuk"
  },
  {
    "id": "1507022005",
    "district_id": "150702",
    "label": "Teluk Kijing",
    "value": "Teluk Kijing"
  },
  {
    "id": "1507022006",
    "district_id": "150702",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "1507022007",
    "district_id": "150702",
    "label": "Pemusiran",
    "value": "Pemusiran"
  },
  {
    "id": "1507022008",
    "district_id": "150702",
    "label": "Sungai Tering",
    "value": "Sungai Tering"
  },
  {
    "id": "1507022009",
    "district_id": "150702",
    "label": "Sungai Jeruk",
    "value": "Sungai Jeruk"
  },
  {
    "id": "1507022010",
    "district_id": "150702",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1507031002",
    "district_id": "150703",
    "label": "Mendahara Ilir",
    "value": "Mendahara Ilir"
  },
  {
    "id": "1507032001",
    "district_id": "150703",
    "label": "Pangkal Duri",
    "value": "Pangkal Duri"
  },
  {
    "id": "1507032003",
    "district_id": "150703",
    "label": "Mendahara Tengah",
    "value": "Mendahara Tengah"
  },
  {
    "id": "1507032007",
    "district_id": "150703",
    "label": "Lagan Ilir",
    "value": "Lagan Ilir"
  },
  {
    "id": "1507032014",
    "district_id": "150703",
    "label": "Sinar Kalimantan",
    "value": "Sinar Kalimantan"
  },
  {
    "id": "1507032015",
    "district_id": "150703",
    "label": "Sungai Tawar",
    "value": "Sungai Tawar"
  },
  {
    "id": "1507032016",
    "district_id": "150703",
    "label": "Bakti Idaman",
    "value": "Bakti Idaman"
  },
  {
    "id": "1507032017",
    "district_id": "150703",
    "label": "Merbau",
    "value": "Merbau"
  },
  {
    "id": "1507032018",
    "district_id": "150703",
    "label": "Pangkal Duri Ilir",
    "value": "Pangkal Duri Ilir"
  },
  {
    "id": "1507041006",
    "district_id": "150704",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1507042004",
    "district_id": "150704",
    "label": "Rantau Rasau I",
    "value": "Rantau Rasau I"
  },
  {
    "id": "1507042005",
    "district_id": "150704",
    "label": "Rantau Rasau II",
    "value": "Rantau Rasau II"
  },
  {
    "id": "1507042007",
    "district_id": "150704",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "1507042008",
    "district_id": "150704",
    "label": "Bangun Karya",
    "value": "Bangun Karya"
  },
  {
    "id": "1507042009",
    "district_id": "150704",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "1507042011",
    "district_id": "150704",
    "label": "Sungai Dusun",
    "value": "Sungai Dusun"
  },
  {
    "id": "1507042013",
    "district_id": "150704",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1507042014",
    "district_id": "150704",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1507042015",
    "district_id": "150704",
    "label": "Pematang Mayan",
    "value": "Pematang Mayan"
  },
  {
    "id": "1507042016",
    "district_id": "150704",
    "label": "Tri Mulyo",
    "value": "Tri Mulyo"
  },
  {
    "id": "1507051005",
    "district_id": "150705",
    "label": "Sungailokan",
    "value": "Sungailokan"
  },
  {
    "id": "1507052001",
    "district_id": "150705",
    "label": "Sungai Sayang",
    "value": "Sungai Sayang"
  },
  {
    "id": "1507052002",
    "district_id": "150705",
    "label": "Air Hitam Laut",
    "value": "Air Hitam Laut"
  },
  {
    "id": "1507052003",
    "district_id": "150705",
    "label": "Sungai Jambat",
    "value": "Sungai Jambat"
  },
  {
    "id": "1507052004",
    "district_id": "150705",
    "label": "Sungai Itik",
    "value": "Sungai Itik"
  },
  {
    "id": "1507052006",
    "district_id": "150705",
    "label": "Sungai Benuh",
    "value": "Sungai Benuh"
  },
  {
    "id": "1507052007",
    "district_id": "150705",
    "label": "Sungai Cemara",
    "value": "Sungai Cemara"
  },
  {
    "id": "1507052008",
    "district_id": "150705",
    "label": "Remau Baku Tuo",
    "value": "Remau Baku Tuo"
  },
  {
    "id": "1507052009",
    "district_id": "150705",
    "label": "Labuhan Pering",
    "value": "Labuhan Pering"
  },
  {
    "id": "1507061006",
    "district_id": "150706",
    "label": "Rantau Indah",
    "value": "Rantau Indah"
  },
  {
    "id": "1507062003",
    "district_id": "150706",
    "label": "Kuala Dendang",
    "value": "Kuala Dendang"
  },
  {
    "id": "1507062004",
    "district_id": "150706",
    "label": "Kota Kandis Dendang",
    "value": "Kota Kandis Dendang"
  },
  {
    "id": "1507062005",
    "district_id": "150706",
    "label": "Koto Kandis",
    "value": "Koto Kandis"
  },
  {
    "id": "1507062007",
    "district_id": "150706",
    "label": "Sido Mukti",
    "value": "Sido Mukti"
  },
  {
    "id": "1507062008",
    "district_id": "150706",
    "label": "Catur Rahayu",
    "value": "Catur Rahayu"
  },
  {
    "id": "1507062009",
    "district_id": "150706",
    "label": "Jati Mulyo",
    "value": "Jati Mulyo"
  },
  {
    "id": "1507071001",
    "district_id": "150707",
    "label": "Kampung Singkep",
    "value": "Kampung Singkep"
  },
  {
    "id": "1507071002",
    "district_id": "150707",
    "label": "Nibung Putih",
    "value": "Nibung Putih"
  },
  {
    "id": "1507071003",
    "district_id": "150707",
    "label": "Rano",
    "value": "Rano"
  },
  {
    "id": "1507071004",
    "district_id": "150707",
    "label": "Talang Babat",
    "value": "Talang Babat"
  },
  {
    "id": "1507071005",
    "district_id": "150707",
    "label": "Parit Culum I",
    "value": "Parit Culum I"
  },
  {
    "id": "1507071006",
    "district_id": "150707",
    "label": "Parit Culum II",
    "value": "Parit Culum II"
  },
  {
    "id": "1507071007",
    "district_id": "150707",
    "label": "Telukdawan",
    "value": "Telukdawan"
  },
  {
    "id": "1507081001",
    "district_id": "150708",
    "label": "Kampung Laut",
    "value": "Kampung Laut"
  },
  {
    "id": "1507081002",
    "district_id": "150708",
    "label": "Tanjungsolok",
    "value": "Tanjungsolok"
  },
  {
    "id": "1507082003",
    "district_id": "150708",
    "label": "Teluk Majelis",
    "value": "Teluk Majelis"
  },
  {
    "id": "1507082004",
    "district_id": "150708",
    "label": "Kuala Lagan",
    "value": "Kuala Lagan"
  },
  {
    "id": "1507082005",
    "district_id": "150708",
    "label": "Majelis Hidayah",
    "value": "Majelis Hidayah"
  },
  {
    "id": "1507082006",
    "district_id": "150708",
    "label": "Manunggal Makmur",
    "value": "Manunggal Makmur"
  },
  {
    "id": "1507091001",
    "district_id": "150709",
    "label": "Simpang Tuan",
    "value": "Simpang Tuan"
  },
  {
    "id": "1507092002",
    "district_id": "150709",
    "label": "Pematang Rahim",
    "value": "Pematang Rahim"
  },
  {
    "id": "1507092003",
    "district_id": "150709",
    "label": "Sungai Toman",
    "value": "Sungai Toman"
  },
  {
    "id": "1507092004",
    "district_id": "150709",
    "label": "Bukit Tempurung",
    "value": "Bukit Tempurung"
  },
  {
    "id": "1507092005",
    "district_id": "150709",
    "label": "Sinar Wajo",
    "value": "Sinar Wajo"
  },
  {
    "id": "1507092006",
    "district_id": "150709",
    "label": "Sungai Beras",
    "value": "Sungai Beras"
  },
  {
    "id": "1507092007",
    "district_id": "150709",
    "label": "Mencolok",
    "value": "Mencolok"
  },
  {
    "id": "1507101010",
    "district_id": "150710",
    "label": "Pandan Jaya",
    "value": "Pandan Jaya"
  },
  {
    "id": "1507102001",
    "district_id": "150710",
    "label": "Lagan Ulu",
    "value": "Lagan Ulu"
  },
  {
    "id": "1507102002",
    "district_id": "150710",
    "label": "Lagan Tengah",
    "value": "Lagan Tengah"
  },
  {
    "id": "1507102004",
    "district_id": "150710",
    "label": "Pandan Makmur",
    "value": "Pandan Makmur"
  },
  {
    "id": "1507102005",
    "district_id": "150710",
    "label": "Pandan Lagan",
    "value": "Pandan Lagan"
  },
  {
    "id": "1507102006",
    "district_id": "150710",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1507102007",
    "district_id": "150710",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1507102008",
    "district_id": "150710",
    "label": "Rantau Karya",
    "value": "Rantau Karya"
  },
  {
    "id": "1507102009",
    "district_id": "150710",
    "label": "Pandan Sejahtera",
    "value": "Pandan Sejahtera"
  },
  {
    "id": "1507111001",
    "district_id": "150711",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1507112002",
    "district_id": "150711",
    "label": "Rantau Rasau Desa",
    "value": "Rantau Rasau Desa"
  },
  {
    "id": "1507112003",
    "district_id": "150711",
    "label": "Rantau Makmur",
    "value": "Rantau Makmur"
  },
  {
    "id": "1507112004",
    "district_id": "150711",
    "label": "Sungai Rambut",
    "value": "Sungai Rambut"
  },
  {
    "id": "1507112005",
    "district_id": "150711",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "1507112006",
    "district_id": "150711",
    "label": "Telaga Limo",
    "value": "Telaga Limo"
  },
  {
    "id": "1508012001",
    "district_id": "150801",
    "label": "Pedukun",
    "value": "Pedukun"
  },
  {
    "id": "1508012002",
    "district_id": "150801",
    "label": "Lubuk Niur",
    "value": "Lubuk Niur"
  },
  {
    "id": "1508012003",
    "district_id": "150801",
    "label": "Teluk Kecimbung",
    "value": "Teluk Kecimbung"
  },
  {
    "id": "1508012004",
    "district_id": "150801",
    "label": "Tanah Tumbuh",
    "value": "Tanah Tumbuh"
  },
  {
    "id": "1508012005",
    "district_id": "150801",
    "label": "Rambah",
    "value": "Rambah"
  },
  {
    "id": "1508012006",
    "district_id": "150801",
    "label": "Tebing Tinggi Uleh",
    "value": "Tebing Tinggi Uleh"
  },
  {
    "id": "1508012007",
    "district_id": "150801",
    "label": "Bukit Kemang",
    "value": "Bukit Kemang"
  },
  {
    "id": "1508012008",
    "district_id": "150801",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "1508012009",
    "district_id": "150801",
    "label": "Perenti Luweh",
    "value": "Perenti Luweh"
  },
  {
    "id": "1508012015",
    "district_id": "150801",
    "label": "Renah Jelmu",
    "value": "Renah Jelmu"
  },
  {
    "id": "1508012016",
    "district_id": "150801",
    "label": "Koto Jayo",
    "value": "Koto Jayo"
  },
  {
    "id": "1508022001",
    "district_id": "150802",
    "label": "Rantau Duku",
    "value": "Rantau Duku"
  },
  {
    "id": "1508022002",
    "district_id": "150802",
    "label": "Talang Sungai Bungo",
    "value": "Talang Sungai Bungo"
  },
  {
    "id": "1508022003",
    "district_id": "150802",
    "label": "Rantau Pandan",
    "value": "Rantau Pandan"
  },
  {
    "id": "1508022004",
    "district_id": "150802",
    "label": "Leban",
    "value": "Leban"
  },
  {
    "id": "1508022005",
    "district_id": "150802",
    "label": "Lubuk Kayu Aro",
    "value": "Lubuk Kayu Aro"
  },
  {
    "id": "1508022015",
    "district_id": "150802",
    "label": "Lubuk Mayan",
    "value": "Lubuk Mayan"
  },
  {
    "id": "1508031006",
    "district_id": "150803",
    "label": "Tanjung Gedang",
    "value": "Tanjung Gedang"
  },
  {
    "id": "1508031007",
    "district_id": "150803",
    "label": "Bungo Timur",
    "value": "Bungo Timur"
  },
  {
    "id": "1508031008",
    "district_id": "150803",
    "label": "Bungo Barat",
    "value": "Bungo Barat"
  },
  {
    "id": "1508031022",
    "district_id": "150803",
    "label": "Jaya Setia",
    "value": "Jaya Setia"
  },
  {
    "id": "1508031023",
    "district_id": "150803",
    "label": "Batang Bungo",
    "value": "Batang Bungo"
  },
  {
    "id": "1508042001",
    "district_id": "150804",
    "label": "Tanjung Belit",
    "value": "Tanjung Belit"
  },
  {
    "id": "1508042002",
    "district_id": "150804",
    "label": "Rantau Ikil",
    "value": "Rantau Ikil"
  },
  {
    "id": "1508042003",
    "district_id": "150804",
    "label": "Pulau Jelmu",
    "value": "Pulau Jelmu"
  },
  {
    "id": "1508042004",
    "district_id": "150804",
    "label": "Sirih Sekapur",
    "value": "Sirih Sekapur"
  },
  {
    "id": "1508042005",
    "district_id": "150804",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1508042007",
    "district_id": "150804",
    "label": "Jumbak",
    "value": "Jumbak"
  },
  {
    "id": "1508042009",
    "district_id": "150804",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1508042013",
    "district_id": "150804",
    "label": "Talang Pamesun",
    "value": "Talang Pamesun"
  },
  {
    "id": "1508042014",
    "district_id": "150804",
    "label": "Baru Balai Panjang",
    "value": "Baru Balai Panjang"
  },
  {
    "id": "1508042015",
    "district_id": "150804",
    "label": "Sirih Sekapur Perkembangan",
    "value": "Sirih Sekapur Perkembangan"
  },
  {
    "id": "1508052001",
    "district_id": "150805",
    "label": "Teluk Pandak",
    "value": "Teluk Pandak"
  },
  {
    "id": "1508052003",
    "district_id": "150805",
    "label": "Empelu",
    "value": "Empelu"
  },
  {
    "id": "1508052006",
    "district_id": "150805",
    "label": "Pasar Lubuk Landai",
    "value": "Pasar Lubuk Landai"
  },
  {
    "id": "1508052007",
    "district_id": "150805",
    "label": "Sungai Gambir",
    "value": "Sungai Gambir"
  },
  {
    "id": "1508052009",
    "district_id": "150805",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "1508052010",
    "district_id": "150805",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1508052012",
    "district_id": "150805",
    "label": "Tanah Bekali",
    "value": "Tanah Bekali"
  },
  {
    "id": "1508052015",
    "district_id": "150805",
    "label": "Telentam",
    "value": "Telentam"
  },
  {
    "id": "1508052017",
    "district_id": "150805",
    "label": "Pasar Rantau Embacang",
    "value": "Pasar Rantau Embacang"
  },
  {
    "id": "1508052019",
    "district_id": "150805",
    "label": "Tenam",
    "value": "Tenam"
  },
  {
    "id": "1508062001",
    "district_id": "150806",
    "label": "Baru Pelepat",
    "value": "Baru Pelepat"
  },
  {
    "id": "1508062002",
    "district_id": "150806",
    "label": "Dwi Karya Bakti",
    "value": "Dwi Karya Bakti"
  },
  {
    "id": "1508062003",
    "district_id": "150806",
    "label": "Rantau Keloyang",
    "value": "Rantau Keloyang"
  },
  {
    "id": "1508062004",
    "district_id": "150806",
    "label": "Balai Jaya",
    "value": "Balai Jaya"
  },
  {
    "id": "1508062005",
    "district_id": "150806",
    "label": "Rantel",
    "value": "Rantel"
  },
  {
    "id": "1508062006",
    "district_id": "150806",
    "label": "Senamat",
    "value": "Senamat"
  },
  {
    "id": "1508062007",
    "district_id": "150806",
    "label": "Sungai Beringin",
    "value": "Sungai Beringin"
  },
  {
    "id": "1508062008",
    "district_id": "150806",
    "label": "Sungai Gurun",
    "value": "Sungai Gurun"
  },
  {
    "id": "1508062009",
    "district_id": "150806",
    "label": "Batu Kerbau",
    "value": "Batu Kerbau"
  },
  {
    "id": "1508062010",
    "district_id": "150806",
    "label": "Gapura Suci",
    "value": "Gapura Suci"
  },
  {
    "id": "1508062011",
    "district_id": "150806",
    "label": "Mulya Bakti",
    "value": "Mulya Bakti"
  },
  {
    "id": "1508062012",
    "district_id": "150806",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1508062013",
    "district_id": "150806",
    "label": "Cilodang",
    "value": "Cilodang"
  },
  {
    "id": "1508062014",
    "district_id": "150806",
    "label": "Bukit Telago",
    "value": "Bukit Telago"
  },
  {
    "id": "1508062015",
    "district_id": "150806",
    "label": "Sekampil",
    "value": "Sekampil"
  },
  {
    "id": "1508072001",
    "district_id": "150807",
    "label": "Tanjung Bungo",
    "value": "Tanjung Bungo"
  },
  {
    "id": "1508072002",
    "district_id": "150807",
    "label": "Tuo Lbk. Mengkuang",
    "value": "Tuo Lbk. Mengkuang"
  },
  {
    "id": "1508072003",
    "district_id": "150807",
    "label": "Pauh Agung",
    "value": "Pauh Agung"
  },
  {
    "id": "1508072004",
    "district_id": "150807",
    "label": "Tuo Limbur",
    "value": "Tuo Limbur"
  },
  {
    "id": "1508072005",
    "district_id": "150807",
    "label": "Renah Sungai Besar",
    "value": "Renah Sungai Besar"
  },
  {
    "id": "1508072006",
    "district_id": "150807",
    "label": "Muaro Tebo Pandak",
    "value": "Muaro Tebo Pandak"
  },
  {
    "id": "1508072007",
    "district_id": "150807",
    "label": "Rantau Tipu",
    "value": "Rantau Tipu"
  },
  {
    "id": "1508072008",
    "district_id": "150807",
    "label": "Baru Lbk. Mengkuang",
    "value": "Baru Lbk. Mengkuang"
  },
  {
    "id": "1508072009",
    "district_id": "150807",
    "label": "Lbk. Tanah Terban",
    "value": "Lbk. Tanah Terban"
  },
  {
    "id": "1508072010",
    "district_id": "150807",
    "label": "Pemunyian",
    "value": "Pemunyian"
  },
  {
    "id": "1508072011",
    "district_id": "150807",
    "label": "Renah Sungai Ipuh",
    "value": "Renah Sungai Ipuh"
  },
  {
    "id": "1508072012",
    "district_id": "150807",
    "label": "Tebo Jaya",
    "value": "Tebo Jaya"
  },
  {
    "id": "1508072013",
    "district_id": "150807",
    "label": "Sekar Mengkuang",
    "value": "Sekar Mengkuang"
  },
  {
    "id": "1508072014",
    "district_id": "150807",
    "label": "Limbur Baru",
    "value": "Limbur Baru"
  },
  {
    "id": "1508082001",
    "district_id": "150808",
    "label": "Mangun Jayo",
    "value": "Mangun Jayo"
  },
  {
    "id": "1508082002",
    "district_id": "150808",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1508082003",
    "district_id": "150808",
    "label": "Tebat",
    "value": "Tebat"
  },
  {
    "id": "1508082004",
    "district_id": "150808",
    "label": "Baru Pusat Jalo",
    "value": "Baru Pusat Jalo"
  },
  {
    "id": "1508082005",
    "district_id": "150808",
    "label": "Bedaro",
    "value": "Bedaro"
  },
  {
    "id": "1508082006",
    "district_id": "150808",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1508082007",
    "district_id": "150808",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "1508082008",
    "district_id": "150808",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1508082009",
    "district_id": "150808",
    "label": "Pekan Jum'at",
    "value": "Pekan Jum'at"
  },
  {
    "id": "1508092001",
    "district_id": "150809",
    "label": "Koto Jayo",
    "value": "Koto Jayo"
  },
  {
    "id": "1508092002",
    "district_id": "150809",
    "label": "Danau",
    "value": "Danau"
  },
  {
    "id": "1508092003",
    "district_id": "150809",
    "label": "Muara Kuamang",
    "value": "Muara Kuamang"
  },
  {
    "id": "1508092004",
    "district_id": "150809",
    "label": "Lubuk",
    "value": "Lubuk"
  },
  {
    "id": "1508092005",
    "district_id": "150809",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1508092006",
    "district_id": "150809",
    "label": "Lembah Kuamang",
    "value": "Lembah Kuamang"
  },
  {
    "id": "1508092007",
    "district_id": "150809",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "1508092008",
    "district_id": "150809",
    "label": "Daya Murni",
    "value": "Daya Murni"
  },
  {
    "id": "1508092009",
    "district_id": "150809",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1508092010",
    "district_id": "150809",
    "label": "Maju Jaya",
    "value": "Maju Jaya"
  },
  {
    "id": "1508092011",
    "district_id": "150809",
    "label": "Tirta Mulya",
    "value": "Tirta Mulya"
  },
  {
    "id": "1508092012",
    "district_id": "150809",
    "label": "Lingga Kuamang",
    "value": "Lingga Kuamang"
  },
  {
    "id": "1508092013",
    "district_id": "150809",
    "label": "Bangun Harjo",
    "value": "Bangun Harjo"
  },
  {
    "id": "1508092014",
    "district_id": "150809",
    "label": "Kuning Gading",
    "value": "Kuning Gading"
  },
  {
    "id": "1508092015",
    "district_id": "150809",
    "label": "Kuamang Jaya",
    "value": "Kuamang Jaya"
  },
  {
    "id": "1508092016",
    "district_id": "150809",
    "label": "Karya Harapan Mukti",
    "value": "Karya Harapan Mukti"
  },
  {
    "id": "1508092017",
    "district_id": "150809",
    "label": "Padang Palangeh",
    "value": "Padang Palangeh"
  },
  {
    "id": "1508102001",
    "district_id": "150810",
    "label": "Tanjung Menanti",
    "value": "Tanjung Menanti"
  },
  {
    "id": "1508102002",
    "district_id": "150810",
    "label": "Babeko",
    "value": "Babeko"
  },
  {
    "id": "1508102003",
    "district_id": "150810",
    "label": "Sepunggur",
    "value": "Sepunggur"
  },
  {
    "id": "1508102004",
    "district_id": "150810",
    "label": "Simpang Bebeko",
    "value": "Simpang Bebeko"
  },
  {
    "id": "1508102005",
    "district_id": "150810",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1508102006",
    "district_id": "150810",
    "label": "Tuo Sepunggur",
    "value": "Tuo Sepunggur"
  },
  {
    "id": "1508111001",
    "district_id": "150811",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "1508111003",
    "district_id": "150811",
    "label": "Bungo Taman Agung",
    "value": "Bungo Taman Agung"
  },
  {
    "id": "1508111004",
    "district_id": "150811",
    "label": "Sungai Binjai",
    "value": "Sungai Binjai"
  },
  {
    "id": "1508112002",
    "district_id": "150811",
    "label": "Lubuk Benteng",
    "value": "Lubuk Benteng"
  },
  {
    "id": "1508112005",
    "district_id": "150811",
    "label": "Air Gemuruh",
    "value": "Air Gemuruh"
  },
  {
    "id": "1508112006",
    "district_id": "150811",
    "label": "Purwo Bakti",
    "value": "Purwo Bakti"
  },
  {
    "id": "1508112007",
    "district_id": "150811",
    "label": "Sarana Jaya",
    "value": "Sarana Jaya"
  },
  {
    "id": "1508112008",
    "district_id": "150811",
    "label": "Teluk Panjang",
    "value": "Teluk Panjang"
  },
  {
    "id": "1508121001",
    "district_id": "150812",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1508121002",
    "district_id": "150812",
    "label": "Sungai Kerjan",
    "value": "Sungai Kerjan"
  },
  {
    "id": "1508122003",
    "district_id": "150812",
    "label": "Talang Pantai",
    "value": "Talang Pantai"
  },
  {
    "id": "1508122004",
    "district_id": "150812",
    "label": "Sungai Arang",
    "value": "Sungai Arang"
  },
  {
    "id": "1508122005",
    "district_id": "150812",
    "label": "Pulau Pekan",
    "value": "Pulau Pekan"
  },
  {
    "id": "1508131001",
    "district_id": "150813",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1508131002",
    "district_id": "150813",
    "label": "Cadika",
    "value": "Cadika"
  },
  {
    "id": "1508132003",
    "district_id": "150813",
    "label": "Sungai Mengkuang",
    "value": "Sungai Mengkuang"
  },
  {
    "id": "1508132004",
    "district_id": "150813",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "1508142001",
    "district_id": "150814",
    "label": "Muara Buat",
    "value": "Muara Buat"
  },
  {
    "id": "1508142002",
    "district_id": "150814",
    "label": "Buat",
    "value": "Buat"
  },
  {
    "id": "1508142003",
    "district_id": "150814",
    "label": "Laman Panjang",
    "value": "Laman Panjang"
  },
  {
    "id": "1508142004",
    "district_id": "150814",
    "label": "Karak Apung",
    "value": "Karak Apung"
  },
  {
    "id": "1508142005",
    "district_id": "150814",
    "label": "Timbolasi",
    "value": "Timbolasi"
  },
  {
    "id": "1508142006",
    "district_id": "150814",
    "label": "Senamat Ulu",
    "value": "Senamat Ulu"
  },
  {
    "id": "1508142007",
    "district_id": "150814",
    "label": "Aur Cino",
    "value": "Aur Cino"
  },
  {
    "id": "1508142008",
    "district_id": "150814",
    "label": "Lubuk Beringin",
    "value": "Lubuk Beringin"
  },
  {
    "id": "1508142009",
    "district_id": "150814",
    "label": "Sungai Telang",
    "value": "Sungai Telang"
  },
  {
    "id": "1508152001",
    "district_id": "150815",
    "label": "Pelayang",
    "value": "Pelayang"
  },
  {
    "id": "1508152002",
    "district_id": "150815",
    "label": "Peninjau",
    "value": "Peninjau"
  },
  {
    "id": "1508152003",
    "district_id": "150815",
    "label": "Talang Silungko",
    "value": "Talang Silungko"
  },
  {
    "id": "1508152004",
    "district_id": "150815",
    "label": "Pulau Kerakap",
    "value": "Pulau Kerakap"
  },
  {
    "id": "1508152005",
    "district_id": "150815",
    "label": "Seberang Jaya",
    "value": "Seberang Jaya"
  },
  {
    "id": "1508162001",
    "district_id": "150816",
    "label": "Tepian Danto",
    "value": "Tepian Danto"
  },
  {
    "id": "1508162002",
    "district_id": "150816",
    "label": "Aur Gading",
    "value": "Aur Gading"
  },
  {
    "id": "1508162003",
    "district_id": "150816",
    "label": "Pulau Batu",
    "value": "Pulau Batu"
  },
  {
    "id": "1508162004",
    "district_id": "150816",
    "label": "Sari Mulya",
    "value": "Sari Mulya"
  },
  {
    "id": "1508162005",
    "district_id": "150816",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1508162006",
    "district_id": "150816",
    "label": "Lubuk Tenam",
    "value": "Lubuk Tenam"
  },
  {
    "id": "1508162007",
    "district_id": "150816",
    "label": "Kuamang",
    "value": "Kuamang"
  },
  {
    "id": "1508172001",
    "district_id": "150817",
    "label": "Sungai Mancur",
    "value": "Sungai Mancur"
  },
  {
    "id": "1508172002",
    "district_id": "150817",
    "label": "Tanah Periuk",
    "value": "Tanah Periuk"
  },
  {
    "id": "1508172003",
    "district_id": "150817",
    "label": "Lubuk Landai",
    "value": "Lubuk Landai"
  },
  {
    "id": "1508172004",
    "district_id": "150817",
    "label": "Rantau Embacang",
    "value": "Rantau Embacang"
  },
  {
    "id": "1508172005",
    "district_id": "150817",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1508172006",
    "district_id": "150817",
    "label": "Embacang Gedang",
    "value": "Embacang Gedang"
  },
  {
    "id": "1508172007",
    "district_id": "150817",
    "label": "Sungai Puri",
    "value": "Sungai Puri"
  },
  {
    "id": "1508172008",
    "district_id": "150817",
    "label": "Paku Aji",
    "value": "Paku Aji"
  },
  {
    "id": "1508172009",
    "district_id": "150817",
    "label": "Pematang Panjang",
    "value": "Pematang Panjang"
  },
  {
    "id": "1508172010",
    "district_id": "150817",
    "label": "Sungai Lilin",
    "value": "Sungai Lilin"
  },
  {
    "id": "1508172011",
    "district_id": "150817",
    "label": "Sungai Tembang",
    "value": "Sungai Tembang"
  },
  {
    "id": "1508172012",
    "district_id": "150817",
    "label": "Rantau Makmur",
    "value": "Rantau Makmur"
  },
  {
    "id": "1509011001",
    "district_id": "150901",
    "label": "Matebo",
    "value": "Matebo"
  },
  {
    "id": "1509011002",
    "district_id": "150901",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1509012003",
    "district_id": "150901",
    "label": "Bedaro Rampak",
    "value": "Bedaro Rampak"
  },
  {
    "id": "1509012004",
    "district_id": "150901",
    "label": "Mangun Jayo",
    "value": "Mangun Jayo"
  },
  {
    "id": "1509012005",
    "district_id": "150901",
    "label": "Teluk Pandak",
    "value": "Teluk Pandak"
  },
  {
    "id": "1509012006",
    "district_id": "150901",
    "label": "Tengah Ulu",
    "value": "Tengah Ulu"
  },
  {
    "id": "1509012007",
    "district_id": "150901",
    "label": "Semabu",
    "value": "Semabu"
  },
  {
    "id": "1509012008",
    "district_id": "150901",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1509012009",
    "district_id": "150901",
    "label": "Pelayang",
    "value": "Pelayang"
  },
  {
    "id": "1509012010",
    "district_id": "150901",
    "label": "Sungai Keruh",
    "value": "Sungai Keruh"
  },
  {
    "id": "1509012011",
    "district_id": "150901",
    "label": "Aburan Batang Tebo",
    "value": "Aburan Batang Tebo"
  },
  {
    "id": "1509012012",
    "district_id": "150901",
    "label": "Sungai Alai",
    "value": "Sungai Alai"
  },
  {
    "id": "1509021001",
    "district_id": "150902",
    "label": "Sungai Bengkal",
    "value": "Sungai Bengkal"
  },
  {
    "id": "1509022002",
    "district_id": "150902",
    "label": "Sungai Aro",
    "value": "Sungai Aro"
  },
  {
    "id": "1509022003",
    "district_id": "150902",
    "label": "Teluk Rendah Pasar",
    "value": "Teluk Rendah Pasar"
  },
  {
    "id": "1509022004",
    "district_id": "150902",
    "label": "Teluk Rendah Ulu",
    "value": "Teluk Rendah Ulu"
  },
  {
    "id": "1509022005",
    "district_id": "150902",
    "label": "Teluk Rendah Ilir",
    "value": "Teluk Rendah Ilir"
  },
  {
    "id": "1509022008",
    "district_id": "150902",
    "label": "Tuo Ilir",
    "value": "Tuo Ilir"
  },
  {
    "id": "1509022011",
    "district_id": "150902",
    "label": "Betung Bedarah Timur",
    "value": "Betung Bedarah Timur"
  },
  {
    "id": "1509022012",
    "district_id": "150902",
    "label": "Betung Bedarah Barat",
    "value": "Betung Bedarah Barat"
  },
  {
    "id": "1509022014",
    "district_id": "150902",
    "label": "Kunangan",
    "value": "Kunangan"
  },
  {
    "id": "1509022015",
    "district_id": "150902",
    "label": "Muara Ketalo",
    "value": "Muara Ketalo"
  },
  {
    "id": "1509022017",
    "district_id": "150902",
    "label": "Sungai Bengkal Barat",
    "value": "Sungai Bengkal Barat"
  },
  {
    "id": "1509031001",
    "district_id": "150903",
    "label": "Pulautemiang",
    "value": "Pulautemiang"
  },
  {
    "id": "1509032002",
    "district_id": "150903",
    "label": "Sungai Rambai",
    "value": "Sungai Rambai"
  },
  {
    "id": "1509032003",
    "district_id": "150903",
    "label": "Pagar Puding",
    "value": "Pagar Puding"
  },
  {
    "id": "1509032004",
    "district_id": "150903",
    "label": "Teluk Kembang Jambu",
    "value": "Teluk Kembang Jambu"
  },
  {
    "id": "1509032005",
    "district_id": "150903",
    "label": "Teluk Kasai Rambahan",
    "value": "Teluk Kasai Rambahan"
  },
  {
    "id": "1509032006",
    "district_id": "150903",
    "label": "Rantau Langkap",
    "value": "Rantau Langkap"
  },
  {
    "id": "1509032007",
    "district_id": "150903",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1509032008",
    "district_id": "150903",
    "label": "Bungo Tanjung",
    "value": "Bungo Tanjung"
  },
  {
    "id": "1509032009",
    "district_id": "150903",
    "label": "Pulau Panjang",
    "value": "Pulau Panjang"
  },
  {
    "id": "1509032010",
    "district_id": "150903",
    "label": "Lubuk Benteng",
    "value": "Lubuk Benteng"
  },
  {
    "id": "1509032011",
    "district_id": "150903",
    "label": "Teluk Kuali",
    "value": "Teluk Kuali"
  },
  {
    "id": "1509032012",
    "district_id": "150903",
    "label": "Malako Intan",
    "value": "Malako Intan"
  },
  {
    "id": "1509032015",
    "district_id": "150903",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "1509032016",
    "district_id": "150903",
    "label": "Pulau Jelmu",
    "value": "Pulau Jelmu"
  },
  {
    "id": "1509032017",
    "district_id": "150903",
    "label": "Medan Seri Rambahan",
    "value": "Medan Seri Rambahan"
  },
  {
    "id": "1509032018",
    "district_id": "150903",
    "label": "Ulak Banjir Rambahan",
    "value": "Ulak Banjir Rambahan"
  },
  {
    "id": "1509032019",
    "district_id": "150903",
    "label": "Teluk Pandan Rambahan",
    "value": "Teluk Pandan Rambahan"
  },
  {
    "id": "1509041001",
    "district_id": "150904",
    "label": "Wirotho Agung",
    "value": "Wirotho Agung"
  },
  {
    "id": "1509042002",
    "district_id": "150904",
    "label": "Perintis",
    "value": "Perintis"
  },
  {
    "id": "1509042003",
    "district_id": "150904",
    "label": "Rimbo Mulyo",
    "value": "Rimbo Mulyo"
  },
  {
    "id": "1509042004",
    "district_id": "150904",
    "label": "Purwo Harjo",
    "value": "Purwo Harjo"
  },
  {
    "id": "1509042005",
    "district_id": "150904",
    "label": "Tegal Arum",
    "value": "Tegal Arum"
  },
  {
    "id": "1509042006",
    "district_id": "150904",
    "label": "Tirta Kencana",
    "value": "Tirta Kencana"
  },
  {
    "id": "1509042007",
    "district_id": "150904",
    "label": "Sapta Mulia",
    "value": "Sapta Mulia"
  },
  {
    "id": "1509042008",
    "district_id": "150904",
    "label": "Pematang Sapat",
    "value": "Pematang Sapat"
  },
  {
    "id": "1509052001",
    "district_id": "150905",
    "label": "Teluk Singkawang",
    "value": "Teluk Singkawang"
  },
  {
    "id": "1509052002",
    "district_id": "150905",
    "label": "Lembak Bungur",
    "value": "Lembak Bungur"
  },
  {
    "id": "1509052003",
    "district_id": "150905",
    "label": "Teluk Langkap",
    "value": "Teluk Langkap"
  },
  {
    "id": "1509052004",
    "district_id": "150905",
    "label": "Tambun Arang",
    "value": "Tambun Arang"
  },
  {
    "id": "1509052005",
    "district_id": "150905",
    "label": "Punti Kalo",
    "value": "Punti Kalo"
  },
  {
    "id": "1509052006",
    "district_id": "150905",
    "label": "Jati Belarik",
    "value": "Jati Belarik"
  },
  {
    "id": "1509052007",
    "district_id": "150905",
    "label": "Tuo Sumay",
    "value": "Tuo Sumay"
  },
  {
    "id": "1509052008",
    "district_id": "150905",
    "label": "Teriti",
    "value": "Teriti"
  },
  {
    "id": "1509052009",
    "district_id": "150905",
    "label": "Suo-Suo",
    "value": "Suo-Suo"
  },
  {
    "id": "1509052010",
    "district_id": "150905",
    "label": "Muara Sekalo",
    "value": "Muara Sekalo"
  },
  {
    "id": "1509052011",
    "district_id": "150905",
    "label": "Semambu",
    "value": "Semambu"
  },
  {
    "id": "1509052012",
    "district_id": "150905",
    "label": "Pemayungan",
    "value": "Pemayungan"
  },
  {
    "id": "1509062001",
    "district_id": "150906",
    "label": "Tanjung Pucuk Jambi",
    "value": "Tanjung Pucuk Jambi"
  },
  {
    "id": "1509062002",
    "district_id": "150906",
    "label": "Kuamang",
    "value": "Kuamang"
  },
  {
    "id": "1509062003",
    "district_id": "150906",
    "label": "Teluk Kayu Putih",
    "value": "Teluk Kayu Putih"
  },
  {
    "id": "1509062004",
    "district_id": "150906",
    "label": "Sungai Abang",
    "value": "Sungai Abang"
  },
  {
    "id": "1509062005",
    "district_id": "150906",
    "label": "Aur Cino",
    "value": "Aur Cino"
  },
  {
    "id": "1509062007",
    "district_id": "150906",
    "label": "Muara Niro",
    "value": "Muara Niro"
  },
  {
    "id": "1509062013",
    "district_id": "150906",
    "label": "Teluk Lancang",
    "value": "Teluk Lancang"
  },
  {
    "id": "1509062014",
    "district_id": "150906",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1509062015",
    "district_id": "150906",
    "label": "Tabun",
    "value": "Tabun"
  },
  {
    "id": "1509062016",
    "district_id": "150906",
    "label": "Muara Tabun",
    "value": "Muara Tabun"
  },
  {
    "id": "1509072001",
    "district_id": "150907",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1509072002",
    "district_id": "150907",
    "label": "Wanareja",
    "value": "Wanareja"
  },
  {
    "id": "1509072003",
    "district_id": "150907",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1509072004",
    "district_id": "150907",
    "label": "Sido Rukun",
    "value": "Sido Rukun"
  },
  {
    "id": "1509072005",
    "district_id": "150907",
    "label": "Sungai Pandan",
    "value": "Sungai Pandan"
  },
  {
    "id": "1509072006",
    "district_id": "150907",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1509082001",
    "district_id": "150908",
    "label": "Giriwinangun",
    "value": "Giriwinangun"
  },
  {
    "id": "1509082002",
    "district_id": "150908",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1509082003",
    "district_id": "150908",
    "label": "Pulung Rejo",
    "value": "Pulung Rejo"
  },
  {
    "id": "1509082004",
    "district_id": "150908",
    "label": "Karang Dadi",
    "value": "Karang Dadi"
  },
  {
    "id": "1509082005",
    "district_id": "150908",
    "label": "Giri Purno",
    "value": "Giri Purno"
  },
  {
    "id": "1509082006",
    "district_id": "150908",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1509082007",
    "district_id": "150908",
    "label": "Sarimulya",
    "value": "Sarimulya"
  },
  {
    "id": "1509082008",
    "district_id": "150908",
    "label": "Sepakat Bersatu",
    "value": "Sepakat Bersatu"
  },
  {
    "id": "1509082009",
    "district_id": "150908",
    "label": "Rantau Kembang",
    "value": "Rantau Kembang"
  },
  {
    "id": "1509092001",
    "district_id": "150909",
    "label": "Muara Kilis",
    "value": "Muara Kilis"
  },
  {
    "id": "1509092002",
    "district_id": "150909",
    "label": "Penapalan",
    "value": "Penapalan"
  },
  {
    "id": "1509092003",
    "district_id": "150909",
    "label": "Mengupeh",
    "value": "Mengupeh"
  },
  {
    "id": "1509092004",
    "district_id": "150909",
    "label": "Rantau Api",
    "value": "Rantau Api"
  },
  {
    "id": "1509092005",
    "district_id": "150909",
    "label": "Lubuk Mandarsah",
    "value": "Lubuk Mandarsah"
  },
  {
    "id": "1509102001",
    "district_id": "150910",
    "label": "Pinang Belai",
    "value": "Pinang Belai"
  },
  {
    "id": "1509102002",
    "district_id": "150910",
    "label": "Sekutur Jaya",
    "value": "Sekutur Jaya"
  },
  {
    "id": "1509102003",
    "district_id": "150910",
    "label": "Bukit Pamuatan",
    "value": "Bukit Pamuatan"
  },
  {
    "id": "1509102004",
    "district_id": "150910",
    "label": "Napal Putih",
    "value": "Napal Putih"
  },
  {
    "id": "1509102005",
    "district_id": "150910",
    "label": "Sako Makmur",
    "value": "Sako Makmur"
  },
  {
    "id": "1509102006",
    "district_id": "150910",
    "label": "Pagar Puding Lamo",
    "value": "Pagar Puding Lamo"
  },
  {
    "id": "1509102007",
    "district_id": "150910",
    "label": "Tanjung Aur Seberang",
    "value": "Tanjung Aur Seberang"
  },
  {
    "id": "1509102008",
    "district_id": "150910",
    "label": "Teluk Melintang",
    "value": "Teluk Melintang"
  },
  {
    "id": "1509112001",
    "district_id": "150911",
    "label": "Teluk Kepayang Pulau Indah",
    "value": "Teluk Kepayang Pulau Indah"
  },
  {
    "id": "1509112002",
    "district_id": "150911",
    "label": "Balai Rajo",
    "value": "Balai Rajo"
  },
  {
    "id": "1509112003",
    "district_id": "150911",
    "label": "Cermin Alam",
    "value": "Cermin Alam"
  },
  {
    "id": "1509112004",
    "district_id": "150911",
    "label": "Sungai Karang",
    "value": "Sungai Karang"
  },
  {
    "id": "1509112005",
    "district_id": "150911",
    "label": "Paseban",
    "value": "Paseban"
  },
  {
    "id": "1509112006",
    "district_id": "150911",
    "label": "Pasir Mayang",
    "value": "Pasir Mayang"
  },
  {
    "id": "1509122001",
    "district_id": "150912",
    "label": "Tanah Garo",
    "value": "Tanah Garo"
  },
  {
    "id": "1509122002",
    "district_id": "150912",
    "label": "Pintas Tuo",
    "value": "Pintas Tuo"
  },
  {
    "id": "1509122003",
    "district_id": "150912",
    "label": "Embacang Gedang",
    "value": "Embacang Gedang"
  },
  {
    "id": "1509122004",
    "district_id": "150912",
    "label": "Bangun Seranten",
    "value": "Bangun Seranten"
  },
  {
    "id": "1509122005",
    "district_id": "150912",
    "label": "Tambun Arang",
    "value": "Tambun Arang"
  },
  {
    "id": "1509122006",
    "district_id": "150912",
    "label": "Sungai Jernih",
    "value": "Sungai Jernih"
  },
  {
    "id": "1509122007",
    "district_id": "150912",
    "label": "Bangko Pintas",
    "value": "Bangko Pintas"
  },
  {
    "id": "1509122008",
    "district_id": "150912",
    "label": "Olak Kemang",
    "value": "Olak Kemang"
  },
  {
    "id": "1571011001",
    "district_id": "157101",
    "label": "Simpang IV Sipin",
    "value": "Simpang IV Sipin"
  },
  {
    "id": "1571011002",
    "district_id": "157101",
    "label": "Buluran Kenali",
    "value": "Buluran Kenali"
  },
  {
    "id": "1571011003",
    "district_id": "157101",
    "label": "Teluk Kenali",
    "value": "Teluk Kenali"
  },
  {
    "id": "1571011004",
    "district_id": "157101",
    "label": "Telanaipura",
    "value": "Telanaipura"
  },
  {
    "id": "1571011010",
    "district_id": "157101",
    "label": "Penyengat Rendah",
    "value": "Penyengat Rendah"
  },
  {
    "id": "1571011011",
    "district_id": "157101",
    "label": "Pematang Sulur",
    "value": "Pematang Sulur"
  },
  {
    "id": "1571021002",
    "district_id": "157102",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1571021003",
    "district_id": "157102",
    "label": "Tambak Sari",
    "value": "Tambak Sari"
  },
  {
    "id": "1571021004",
    "district_id": "157102",
    "label": "Thehok",
    "value": "Thehok"
  },
  {
    "id": "1571021005",
    "district_id": "157102",
    "label": "Wijaya Pura",
    "value": "Wijaya Pura"
  },
  {
    "id": "1571021006",
    "district_id": "157102",
    "label": "Pakuan Baru",
    "value": "Pakuan Baru"
  },
  {
    "id": "1571031001",
    "district_id": "157103",
    "label": "Sijenjang",
    "value": "Sijenjang"
  },
  {
    "id": "1571031002",
    "district_id": "157103",
    "label": "Kasang Jaya",
    "value": "Kasang Jaya"
  },
  {
    "id": "1571031003",
    "district_id": "157103",
    "label": "Talang Banjar",
    "value": "Talang Banjar"
  },
  {
    "id": "1571031004",
    "district_id": "157103",
    "label": "Budiman",
    "value": "Budiman"
  },
  {
    "id": "1571031005",
    "district_id": "157103",
    "label": "Sulanjana",
    "value": "Sulanjana"
  },
  {
    "id": "1571031006",
    "district_id": "157103",
    "label": "Kasang",
    "value": "Kasang"
  },
  {
    "id": "1571031007",
    "district_id": "157103",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1571031008",
    "district_id": "157103",
    "label": "Rajawali",
    "value": "Rajawali"
  },
  {
    "id": "1571031010",
    "district_id": "157103",
    "label": "Tanjung Pinang",
    "value": "Tanjung Pinang"
  },
  {
    "id": "1571041001",
    "district_id": "157104",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1571041002",
    "district_id": "157104",
    "label": "Orang Kayo Hitam",
    "value": "Orang Kayo Hitam"
  },
  {
    "id": "1571041003",
    "district_id": "157104",
    "label": "Pasar Jambi",
    "value": "Pasar Jambi"
  },
  {
    "id": "1571041004",
    "district_id": "157104",
    "label": "Sungai Asam",
    "value": "Sungai Asam"
  },
  {
    "id": "1571051001",
    "district_id": "157105",
    "label": "Arab Melayu",
    "value": "Arab Melayu"
  },
  {
    "id": "1571051002",
    "district_id": "157105",
    "label": "Mudung Laut",
    "value": "Mudung Laut"
  },
  {
    "id": "1571051003",
    "district_id": "157105",
    "label": "Jelmu",
    "value": "Jelmu"
  },
  {
    "id": "1571051004",
    "district_id": "157105",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "1571051005",
    "district_id": "157105",
    "label": "Tahtul Yaman",
    "value": "Tahtul Yaman"
  },
  {
    "id": "1571051006",
    "district_id": "157105",
    "label": "Tanjung Johor",
    "value": "Tanjung Johor"
  },
  {
    "id": "1571061001",
    "district_id": "157106",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "1571061002",
    "district_id": "157106",
    "label": "Tanjung Raden",
    "value": "Tanjung Raden"
  },
  {
    "id": "1571061003",
    "district_id": "157106",
    "label": "Olak Kemang",
    "value": "Olak Kemang"
  },
  {
    "id": "1571061004",
    "district_id": "157106",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "1571061005",
    "district_id": "157106",
    "label": "Ulu Gedong",
    "value": "Ulu Gedong"
  },
  {
    "id": "1571071001",
    "district_id": "157107",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "1571071002",
    "district_id": "157107",
    "label": "Simpang III Sipin",
    "value": "Simpang III Sipin"
  },
  {
    "id": "1571071004",
    "district_id": "157107",
    "label": "Paal Lima",
    "value": "Paal Lima"
  },
  {
    "id": "1571071005",
    "district_id": "157107",
    "label": "Kenali Asam Bawah",
    "value": "Kenali Asam Bawah"
  },
  {
    "id": "1571071006",
    "district_id": "157107",
    "label": "Kenali Asam Atas",
    "value": "Kenali Asam Atas"
  },
  {
    "id": "1571081001",
    "district_id": "157108",
    "label": "Talang Jauh",
    "value": "Talang Jauh"
  },
  {
    "id": "1571081002",
    "district_id": "157108",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "1571081003",
    "district_id": "157108",
    "label": "Kebun Handil",
    "value": "Kebun Handil"
  },
  {
    "id": "1571081004",
    "district_id": "157108",
    "label": "Lebak Bandung",
    "value": "Lebak Bandung"
  },
  {
    "id": "1571081005",
    "district_id": "157108",
    "label": "Payo Lebar",
    "value": "Payo Lebar"
  },
  {
    "id": "1571081006",
    "district_id": "157108",
    "label": "Jelutung",
    "value": "Jelutung"
  },
  {
    "id": "1571081007",
    "district_id": "157108",
    "label": "Handil Jaya",
    "value": "Handil Jaya"
  },
  {
    "id": "1571091001",
    "district_id": "157109",
    "label": "Kenali Besar",
    "value": "Kenali Besar"
  },
  {
    "id": "1571091002",
    "district_id": "157109",
    "label": "Rawa Sari",
    "value": "Rawa Sari"
  },
  {
    "id": "1571091003",
    "district_id": "157109",
    "label": "Mayang Mangurai",
    "value": "Mayang Mangurai"
  },
  {
    "id": "1571091004",
    "district_id": "157109",
    "label": "Bagan Pete",
    "value": "Bagan Pete"
  },
  {
    "id": "1571091005",
    "district_id": "157109",
    "label": "Beliung",
    "value": "Beliung"
  },
  {
    "id": "1571101001",
    "district_id": "157110",
    "label": "Sungai Putri",
    "value": "Sungai Putri"
  },
  {
    "id": "1571101002",
    "district_id": "157110",
    "label": "Murni",
    "value": "Murni"
  },
  {
    "id": "1571101003",
    "district_id": "157110",
    "label": "Legok",
    "value": "Legok"
  },
  {
    "id": "1571101004",
    "district_id": "157110",
    "label": "Selamat",
    "value": "Selamat"
  },
  {
    "id": "1571101005",
    "district_id": "157110",
    "label": "Solok Sipin",
    "value": "Solok Sipin"
  },
  {
    "id": "1571111001",
    "district_id": "157111",
    "label": "Talang Bakung",
    "value": "Talang Bakung"
  },
  {
    "id": "1571111002",
    "district_id": "157111",
    "label": "Payo Selincah",
    "value": "Payo Selincah"
  },
  {
    "id": "1571111003",
    "district_id": "157111",
    "label": "Eka Jaya",
    "value": "Eka Jaya"
  },
  {
    "id": "1571111004",
    "district_id": "157111",
    "label": "Lingkar Selatan",
    "value": "Lingkar Selatan"
  },
  {
    "id": "1571111005",
    "district_id": "157111",
    "label": "Paal Merah",
    "value": "Paal Merah"
  },
  {
    "id": "1572011001",
    "district_id": "157201",
    "label": "Sungai Penuh",
    "value": "Sungai Penuh"
  },
  {
    "id": "1572011003",
    "district_id": "157201",
    "label": "Pasar Sungai Penuh",
    "value": "Pasar Sungai Penuh"
  },
  {
    "id": "1572012004",
    "district_id": "157201",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1572012006",
    "district_id": "157201",
    "label": "Gedang",
    "value": "Gedang"
  },
  {
    "id": "1572012014",
    "district_id": "157201",
    "label": "Amar Sakti",
    "value": "Amar Sakti"
  },
  {
    "id": "1572022001",
    "district_id": "157202",
    "label": "Koto Renah",
    "value": "Koto Renah"
  },
  {
    "id": "1572022002",
    "district_id": "157202",
    "label": "Koto Keras",
    "value": "Koto Keras"
  },
  {
    "id": "1572022003",
    "district_id": "157202",
    "label": "Koto Bento",
    "value": "Koto Bento"
  },
  {
    "id": "1572022004",
    "district_id": "157202",
    "label": "Koto Lolo",
    "value": "Koto Lolo"
  },
  {
    "id": "1572022005",
    "district_id": "157202",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1572022008",
    "district_id": "157202",
    "label": "Koto Duo",
    "value": "Koto Duo"
  },
  {
    "id": "1572022010",
    "district_id": "157202",
    "label": "Sungai Liuk",
    "value": "Sungai Liuk"
  },
  {
    "id": "1572022011",
    "district_id": "157202",
    "label": "Seberang",
    "value": "Seberang"
  },
  {
    "id": "1572022015",
    "district_id": "157202",
    "label": "Sumur Gedang",
    "value": "Sumur Gedang"
  },
  {
    "id": "1572032001",
    "district_id": "157203",
    "label": "Kampung Diilir",
    "value": "Kampung Diilir"
  },
  {
    "id": "1572032002",
    "district_id": "157203",
    "label": "Koto Beringin",
    "value": "Koto Beringin"
  },
  {
    "id": "1572032003",
    "district_id": "157203",
    "label": "Maliki Air",
    "value": "Maliki Air"
  },
  {
    "id": "1572032004",
    "district_id": "157203",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1572032005",
    "district_id": "157203",
    "label": "Koto Teluk",
    "value": "Koto Teluk"
  },
  {
    "id": "1572032006",
    "district_id": "157203",
    "label": "Kampung Dalam",
    "value": "Kampung Dalam"
  },
  {
    "id": "1572032007",
    "district_id": "157203",
    "label": "Simpang Tiga Rawang",
    "value": "Simpang Tiga Rawang"
  },
  {
    "id": "1572032008",
    "district_id": "157203",
    "label": "Larik Kemahan",
    "value": "Larik Kemahan"
  },
  {
    "id": "1572032009",
    "district_id": "157203",
    "label": "Dusun Diilir",
    "value": "Dusun Diilir"
  },
  {
    "id": "1572032010",
    "district_id": "157203",
    "label": "Paling Serumpun",
    "value": "Paling Serumpun"
  },
  {
    "id": "1572032011",
    "district_id": "157203",
    "label": "Koto Dian",
    "value": "Koto Dian"
  },
  {
    "id": "1572032012",
    "district_id": "157203",
    "label": "Tanjung Muda",
    "value": "Tanjung Muda"
  },
  {
    "id": "1572032013",
    "district_id": "157203",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "1572042001",
    "district_id": "157204",
    "label": "Baru Debai",
    "value": "Baru Debai"
  },
  {
    "id": "1572042002",
    "district_id": "157204",
    "label": "Pendung Hiang",
    "value": "Pendung Hiang"
  },
  {
    "id": "1572042003",
    "district_id": "157204",
    "label": "Koto Pudung",
    "value": "Koto Pudung"
  },
  {
    "id": "1572042004",
    "district_id": "157204",
    "label": "Koto Dumo",
    "value": "Koto Dumo"
  },
  {
    "id": "1572042005",
    "district_id": "157204",
    "label": "Sembilan",
    "value": "Sembilan"
  },
  {
    "id": "1572042006",
    "district_id": "157204",
    "label": "Koto Tuo",
    "value": "Koto Tuo"
  },
  {
    "id": "1572042007",
    "district_id": "157204",
    "label": "Koto Panap",
    "value": "Koto Panap"
  },
  {
    "id": "1572042008",
    "district_id": "157204",
    "label": "Koto Tengah",
    "value": "Koto Tengah"
  },
  {
    "id": "1572042009",
    "district_id": "157204",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "1572042010",
    "district_id": "157204",
    "label": "Koto Padang",
    "value": "Koto Padang"
  },
  {
    "id": "1572042011",
    "district_id": "157204",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "1572042012",
    "district_id": "157204",
    "label": "Kt. Baru Tanah Kampung",
    "value": "Kt. Baru Tanah Kampung"
  },
  {
    "id": "1572042013",
    "district_id": "157204",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1572052001",
    "district_id": "157205",
    "label": "Kumun Hilir",
    "value": "Kumun Hilir"
  },
  {
    "id": "1572052002",
    "district_id": "157205",
    "label": "Kumun Mudik",
    "value": "Kumun Mudik"
  },
  {
    "id": "1572052003",
    "district_id": "157205",
    "label": "Air Teluh",
    "value": "Air Teluh"
  },
  {
    "id": "1572052004",
    "district_id": "157205",
    "label": "Sandaran Galeh",
    "value": "Sandaran Galeh"
  },
  {
    "id": "1572052005",
    "district_id": "157205",
    "label": "Debai",
    "value": "Debai"
  },
  {
    "id": "1572052006",
    "district_id": "157205",
    "label": "Pinggir Air",
    "value": "Pinggir Air"
  },
  {
    "id": "1572052007",
    "district_id": "157205",
    "label": "Renah Kayu Embun",
    "value": "Renah Kayu Embun"
  },
  {
    "id": "1572052008",
    "district_id": "157205",
    "label": "Ulu Air",
    "value": "Ulu Air"
  },
  {
    "id": "1572052009",
    "district_id": "157205",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "1572061008",
    "district_id": "157206",
    "label": "Pondok Tinggi",
    "value": "Pondok Tinggi"
  },
  {
    "id": "1572062001",
    "district_id": "157206",
    "label": "Sungai Jernih",
    "value": "Sungai Jernih"
  },
  {
    "id": "1572062002",
    "district_id": "157206",
    "label": "Aur Duri",
    "value": "Aur Duri"
  },
  {
    "id": "1572062003",
    "district_id": "157206",
    "label": "Pondok Agung",
    "value": "Pondok Agung"
  },
  {
    "id": "1572062004",
    "district_id": "157206",
    "label": "Permanti",
    "value": "Permanti"
  },
  {
    "id": "1572062005",
    "district_id": "157206",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1572062006",
    "district_id": "157206",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1572062007",
    "district_id": "157206",
    "label": "Koto Lebu",
    "value": "Koto Lebu"
  },
  {
    "id": "1572072001",
    "district_id": "157207",
    "label": "Koto Baru",
    "value": "Koto Baru"
  },
  {
    "id": "1572072002",
    "district_id": "157207",
    "label": "Koto Limau Manis",
    "value": "Koto Limau Manis"
  },
  {
    "id": "1572072003",
    "district_id": "157207",
    "label": "Kampung Tengah",
    "value": "Kampung Tengah"
  },
  {
    "id": "1572072004",
    "district_id": "157207",
    "label": "Dujung Sakti",
    "value": "Dujung Sakti"
  },
  {
    "id": "1572072005",
    "district_id": "157207",
    "label": "Permai Indah",
    "value": "Permai Indah"
  },
  {
    "id": "1572072006",
    "district_id": "157207",
    "label": "Srimenanti",
    "value": "Srimenanti"
  },
  {
    "id": "1572081005",
    "district_id": "157208",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1572082001",
    "district_id": "157208",
    "label": "Sumur Anyir",
    "value": "Sumur Anyir"
  },
  {
    "id": "1572082002",
    "district_id": "157208",
    "label": "Koto Tinggi",
    "value": "Koto Tinggi"
  },
  {
    "id": "1572082003",
    "district_id": "157208",
    "label": "Talang Lindung",
    "value": "Talang Lindung"
  },
  {
    "id": "1572082004",
    "district_id": "157208",
    "label": "Pelayang Raya",
    "value": "Pelayang Raya"
  },
  {
    "id": "1572082006",
    "district_id": "157208",
    "label": "Sungai Ning",
    "value": "Sungai Ning"
  },
  {
    "id": "1601072001",
    "district_id": "160107",
    "label": "Negeri Sindang",
    "value": "Negeri Sindang"
  },
  {
    "id": "1601072002",
    "district_id": "160107",
    "label": "Rantau Kumpai",
    "value": "Rantau Kumpai"
  },
  {
    "id": "1601072003",
    "district_id": "160107",
    "label": "Lubuk Baru",
    "value": "Lubuk Baru"
  },
  {
    "id": "1601072004",
    "district_id": "160107",
    "label": "Kungkilan",
    "value": "Kungkilan"
  },
  {
    "id": "1601072005",
    "district_id": "160107",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1601072006",
    "district_id": "160107",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "1601072007",
    "district_id": "160107",
    "label": "Lubuk Leban",
    "value": "Lubuk Leban"
  },
  {
    "id": "1601072008",
    "district_id": "160107",
    "label": "Tungku Jaya",
    "value": "Tungku Jaya"
  },
  {
    "id": "1601072009",
    "district_id": "160107",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1601072010",
    "district_id": "160107",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1601072011",
    "district_id": "160107",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1601082001",
    "district_id": "160108",
    "label": "Gunung Meraksa",
    "value": "Gunung Meraksa"
  },
  {
    "id": "1601082003",
    "district_id": "160108",
    "label": "Tanjung Pura",
    "value": "Tanjung Pura"
  },
  {
    "id": "1601082004",
    "district_id": "160108",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1601082005",
    "district_id": "160108",
    "label": "Tangsi Lontar",
    "value": "Tangsi Lontar"
  },
  {
    "id": "1601082006",
    "district_id": "160108",
    "label": "Belambangan",
    "value": "Belambangan"
  },
  {
    "id": "1601082007",
    "district_id": "160108",
    "label": "Kesambirata",
    "value": "Kesambirata"
  },
  {
    "id": "1601082008",
    "district_id": "160108",
    "label": "Gunung Liwat",
    "value": "Gunung Liwat"
  },
  {
    "id": "1601082015",
    "district_id": "160108",
    "label": "Pengandonan",
    "value": "Pengandonan"
  },
  {
    "id": "1601082016",
    "district_id": "160108",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "1601082017",
    "district_id": "160108",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "1601082018",
    "district_id": "160108",
    "label": "Ujanmas",
    "value": "Ujanmas"
  },
  {
    "id": "1601082019",
    "district_id": "160108",
    "label": "Gunung Kuripan",
    "value": "Gunung Kuripan"
  },
  {
    "id": "1601092001",
    "district_id": "160109",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "1601092002",
    "district_id": "160109",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "1601092003",
    "district_id": "160109",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "1601092004",
    "district_id": "160109",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1601092006",
    "district_id": "160109",
    "label": "Lubuk Rukam",
    "value": "Lubuk Rukam"
  },
  {
    "id": "1601092007",
    "district_id": "160109",
    "label": "Bindu",
    "value": "Bindu"
  },
  {
    "id": "1601092009",
    "district_id": "160109",
    "label": "Mendala",
    "value": "Mendala"
  },
  {
    "id": "1601092010",
    "district_id": "160109",
    "label": "Peninjauan",
    "value": "Peninjauan"
  },
  {
    "id": "1601092011",
    "district_id": "160109",
    "label": "Saung Naga",
    "value": "Saung Naga"
  },
  {
    "id": "1601092022",
    "district_id": "160109",
    "label": "Makartitama",
    "value": "Makartitama"
  },
  {
    "id": "1601092023",
    "district_id": "160109",
    "label": "Espetiga",
    "value": "Espetiga"
  },
  {
    "id": "1601092024",
    "district_id": "160109",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "1601092025",
    "district_id": "160109",
    "label": "Mitra Kencana",
    "value": "Mitra Kencana"
  },
  {
    "id": "1601092026",
    "district_id": "160109",
    "label": "Penilikan",
    "value": "Penilikan"
  },
  {
    "id": "1601092027",
    "district_id": "160109",
    "label": "Panji Jaya",
    "value": "Panji Jaya"
  },
  {
    "id": "1601092028",
    "district_id": "160109",
    "label": "Karang Dapo",
    "value": "Karang Dapo"
  },
  {
    "id": "1601131003",
    "district_id": "160113",
    "label": "Tanjungagung",
    "value": "Tanjungagung"
  },
  {
    "id": "1601131004",
    "district_id": "160113",
    "label": "Saung Naga",
    "value": "Saung Naga"
  },
  {
    "id": "1601131005",
    "district_id": "160113",
    "label": "Talang Jawa",
    "value": "Talang Jawa"
  },
  {
    "id": "1601131007",
    "district_id": "160113",
    "label": "Batu Kuning",
    "value": "Batu Kuning"
  },
  {
    "id": "1601131011",
    "district_id": "160113",
    "label": "Air Gading",
    "value": "Air Gading"
  },
  {
    "id": "1601132001",
    "district_id": "160113",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "1601132002",
    "district_id": "160113",
    "label": "Laya",
    "value": "Laya"
  },
  {
    "id": "1601132006",
    "district_id": "160113",
    "label": "Pusar",
    "value": "Pusar"
  },
  {
    "id": "1601132008",
    "district_id": "160113",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1601132009",
    "district_id": "160113",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1601132010",
    "district_id": "160113",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "1601132012",
    "district_id": "160113",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1601141001",
    "district_id": "160114",
    "label": "Baturaja Lama",
    "value": "Baturaja Lama"
  },
  {
    "id": "1601141003",
    "district_id": "160114",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1601141004",
    "district_id": "160114",
    "label": "Kemalaraja",
    "value": "Kemalaraja"
  },
  {
    "id": "1601141005",
    "district_id": "160114",
    "label": "Sukaraya",
    "value": "Sukaraya"
  },
  {
    "id": "1601141006",
    "district_id": "160114",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1601141011",
    "district_id": "160114",
    "label": "Kemelak Bindung Langit",
    "value": "Kemelak Bindung Langit"
  },
  {
    "id": "1601141012",
    "district_id": "160114",
    "label": "Sepancar Lawang Kulon",
    "value": "Sepancar Lawang Kulon"
  },
  {
    "id": "1601141013",
    "district_id": "160114",
    "label": "Baturaja Permai",
    "value": "Baturaja Permai"
  },
  {
    "id": "1601141014",
    "district_id": "160114",
    "label": "Sekar Jaya",
    "value": "Sekar Jaya"
  },
  {
    "id": "1601142007",
    "district_id": "160114",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1601142008",
    "district_id": "160114",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1601142015",
    "district_id": "160114",
    "label": "Air Paoh",
    "value": "Air Paoh"
  },
  {
    "id": "1601142016",
    "district_id": "160114",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "1601202001",
    "district_id": "160120",
    "label": "Kelumpang",
    "value": "Kelumpang"
  },
  {
    "id": "1601202002",
    "district_id": "160120",
    "label": "Gunung Tiga",
    "value": "Gunung Tiga"
  },
  {
    "id": "1601202003",
    "district_id": "160120",
    "label": "Mendingin",
    "value": "Mendingin"
  },
  {
    "id": "1601202004",
    "district_id": "160120",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1601202005",
    "district_id": "160120",
    "label": "Pedataran",
    "value": "Pedataran"
  },
  {
    "id": "1601202006",
    "district_id": "160120",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1601202007",
    "district_id": "160120",
    "label": "Belandang",
    "value": "Belandang"
  },
  {
    "id": "1601212001",
    "district_id": "160121",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1601212002",
    "district_id": "160121",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1601212003",
    "district_id": "160121",
    "label": "Batanghari",
    "value": "Batanghari"
  },
  {
    "id": "1601212004",
    "district_id": "160121",
    "label": "Nyiur Sayak",
    "value": "Nyiur Sayak"
  },
  {
    "id": "1601212005",
    "district_id": "160121",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1601212006",
    "district_id": "160121",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1601212007",
    "district_id": "160121",
    "label": "Panggal Panggang",
    "value": "Panggal Panggang"
  },
  {
    "id": "1601212008",
    "district_id": "160121",
    "label": "Bedegung",
    "value": "Bedegung"
  },
  {
    "id": "1601212009",
    "district_id": "160121",
    "label": "Ulak Pandan",
    "value": "Ulak Pandan"
  },
  {
    "id": "1601212010",
    "district_id": "160121",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1601212011",
    "district_id": "160121",
    "label": "Tubohan",
    "value": "Tubohan"
  },
  {
    "id": "1601212012",
    "district_id": "160121",
    "label": "Raksa Jiwa",
    "value": "Raksa Jiwa"
  },
  {
    "id": "1601212013",
    "district_id": "160121",
    "label": "Seleman",
    "value": "Seleman"
  },
  {
    "id": "1601212014",
    "district_id": "160121",
    "label": "Kebun Jati",
    "value": "Kebun Jati"
  },
  {
    "id": "1601212015",
    "district_id": "160121",
    "label": "Singapura",
    "value": "Singapura"
  },
  {
    "id": "1601212016",
    "district_id": "160121",
    "label": "Pengaringan",
    "value": "Pengaringan"
  },
  {
    "id": "1601212017",
    "district_id": "160121",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "1601212018",
    "district_id": "160121",
    "label": "Pandan Dulang",
    "value": "Pandan Dulang"
  },
  {
    "id": "1601212020",
    "district_id": "160121",
    "label": "Panai Makmur",
    "value": "Panai Makmur"
  },
  {
    "id": "1601212021",
    "district_id": "160121",
    "label": "Guna Makmur",
    "value": "Guna Makmur"
  },
  {
    "id": "1601212022",
    "district_id": "160121",
    "label": "Tebing Kampung",
    "value": "Tebing Kampung"
  },
  {
    "id": "1601222001",
    "district_id": "160122",
    "label": "Merbau",
    "value": "Merbau"
  },
  {
    "id": "1601222002",
    "district_id": "160122",
    "label": "Gunung Meraksa",
    "value": "Gunung Meraksa"
  },
  {
    "id": "1601222003",
    "district_id": "160122",
    "label": "Kurup",
    "value": "Kurup"
  },
  {
    "id": "1601222004",
    "district_id": "160122",
    "label": "Banu Ayu",
    "value": "Banu Ayu"
  },
  {
    "id": "1601222005",
    "district_id": "160122",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1601222006",
    "district_id": "160122",
    "label": "Lubuk Batang Baru",
    "value": "Lubuk Batang Baru"
  },
  {
    "id": "1601222007",
    "district_id": "160122",
    "label": "Lubuk Batang Lama",
    "value": "Lubuk Batang Lama"
  },
  {
    "id": "1601222008",
    "district_id": "160122",
    "label": "Belatung",
    "value": "Belatung"
  },
  {
    "id": "1601222009",
    "district_id": "160122",
    "label": "Karta Mulya",
    "value": "Karta Mulya"
  },
  {
    "id": "1601222010",
    "district_id": "160122",
    "label": "Lunggaian",
    "value": "Lunggaian"
  },
  {
    "id": "1601222011",
    "district_id": "160122",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1601222012",
    "district_id": "160122",
    "label": "Tanjung Manggus",
    "value": "Tanjung Manggus"
  },
  {
    "id": "1601222015",
    "district_id": "160122",
    "label": "Markisa",
    "value": "Markisa"
  },
  {
    "id": "1601222016",
    "district_id": "160122",
    "label": "Air Wall",
    "value": "Air Wall"
  },
  {
    "id": "1601222017",
    "district_id": "160122",
    "label": "Sumber Bahagia",
    "value": "Sumber Bahagia"
  },
  {
    "id": "1601282001",
    "district_id": "160128",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1601282002",
    "district_id": "160128",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1601282003",
    "district_id": "160128",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1601282004",
    "district_id": "160128",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1601282005",
    "district_id": "160128",
    "label": "Bumi Kawa",
    "value": "Bumi Kawa"
  },
  {
    "id": "1601282006",
    "district_id": "160128",
    "label": "Tanjunglengkayap",
    "value": "Tanjunglengkayap"
  },
  {
    "id": "1601282007",
    "district_id": "160128",
    "label": "Lubuk Dalam",
    "value": "Lubuk Dalam"
  },
  {
    "id": "1601282008",
    "district_id": "160128",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1601282009",
    "district_id": "160128",
    "label": "Segara Kembang",
    "value": "Segara Kembang"
  },
  {
    "id": "1601282010",
    "district_id": "160128",
    "label": "Tihang",
    "value": "Tihang"
  },
  {
    "id": "1601282011",
    "district_id": "160128",
    "label": "Lubuk Hara",
    "value": "Lubuk Hara"
  },
  {
    "id": "1601282012",
    "district_id": "160128",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1601282013",
    "district_id": "160128",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1601282014",
    "district_id": "160128",
    "label": "Gedung Pekuon",
    "value": "Gedung Pekuon"
  },
  {
    "id": "1601282015",
    "district_id": "160128",
    "label": "Tualang",
    "value": "Tualang"
  },
  {
    "id": "1601282016",
    "district_id": "160128",
    "label": "Sundan",
    "value": "Sundan"
  },
  {
    "id": "1601282017",
    "district_id": "160128",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1601282018",
    "district_id": "160128",
    "label": "Way Heling",
    "value": "Way Heling"
  },
  {
    "id": "1601282019",
    "district_id": "160128",
    "label": "Umpam",
    "value": "Umpam"
  },
  {
    "id": "1601282020",
    "district_id": "160128",
    "label": "Fajar Jaya",
    "value": "Fajar Jaya"
  },
  {
    "id": "1601282021",
    "district_id": "160128",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1601282022",
    "district_id": "160128",
    "label": "Tanjungagung",
    "value": "Tanjungagung"
  },
  {
    "id": "1601292001",
    "district_id": "160129",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "1601292002",
    "district_id": "160129",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1601292003",
    "district_id": "160129",
    "label": "Marga Bakti",
    "value": "Marga Bakti"
  },
  {
    "id": "1601292004",
    "district_id": "160129",
    "label": "Sri Mulya",
    "value": "Sri Mulya"
  },
  {
    "id": "1601292005",
    "district_id": "160129",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1601292006",
    "district_id": "160129",
    "label": "Tanjung Makmur",
    "value": "Tanjung Makmur"
  },
  {
    "id": "1601302001",
    "district_id": "160130",
    "label": "Batu Marta I",
    "value": "Batu Marta I"
  },
  {
    "id": "1601302002",
    "district_id": "160130",
    "label": "Batu Marta II",
    "value": "Batu Marta II"
  },
  {
    "id": "1601302003",
    "district_id": "160130",
    "label": "Lekis Rejo",
    "value": "Lekis Rejo"
  },
  {
    "id": "1601302004",
    "district_id": "160130",
    "label": "Lubuk Banjar",
    "value": "Lubuk Banjar"
  },
  {
    "id": "1601302005",
    "district_id": "160130",
    "label": "Marta Jaya",
    "value": "Marta Jaya"
  },
  {
    "id": "1601302006",
    "district_id": "160130",
    "label": "Batu Raden",
    "value": "Batu Raden"
  },
  {
    "id": "1601302007",
    "district_id": "160130",
    "label": "Battu Winangun",
    "value": "Battu Winangun"
  },
  {
    "id": "1601312001",
    "district_id": "160131",
    "label": "Kemala Jaya",
    "value": "Kemala Jaya"
  },
  {
    "id": "1601312002",
    "district_id": "160131",
    "label": "Lontar",
    "value": "Lontar"
  },
  {
    "id": "1601312003",
    "district_id": "160131",
    "label": "Muara Saeh",
    "value": "Muara Saeh"
  },
  {
    "id": "1601312004",
    "district_id": "160131",
    "label": "Lubuk Tupak",
    "value": "Lubuk Tupak"
  },
  {
    "id": "1601312005",
    "district_id": "160131",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1601312006",
    "district_id": "160131",
    "label": "Surau",
    "value": "Surau"
  },
  {
    "id": "1601312007",
    "district_id": "160131",
    "label": "Karang Lantang",
    "value": "Karang Lantang"
  },
  {
    "id": "1601322001",
    "district_id": "160132",
    "label": "Bunglai",
    "value": "Bunglai"
  },
  {
    "id": "1601322002",
    "district_id": "160132",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1601322003",
    "district_id": "160132",
    "label": "Kampai",
    "value": "Kampai"
  },
  {
    "id": "1601322004",
    "district_id": "160132",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1601322005",
    "district_id": "160132",
    "label": "Kedaton Timur",
    "value": "Kedaton Timur"
  },
  {
    "id": "1601322006",
    "district_id": "160132",
    "label": "Suka Pindah",
    "value": "Suka Pindah"
  },
  {
    "id": "1601322007",
    "district_id": "160132",
    "label": "Sinar Kedaton",
    "value": "Sinar Kedaton"
  },
  {
    "id": "1601322008",
    "district_id": "160132",
    "label": "Lubuk Kemiling",
    "value": "Lubuk Kemiling"
  },
  {
    "id": "1602021011",
    "district_id": "160202",
    "label": "Tanjung Lubuk",
    "value": "Tanjung Lubuk"
  },
  {
    "id": "1602022001",
    "district_id": "160202",
    "label": "Ulak Kapal",
    "value": "Ulak Kapal"
  },
  {
    "id": "1602022002",
    "district_id": "160202",
    "label": "Tanjung Merindu",
    "value": "Tanjung Merindu"
  },
  {
    "id": "1602022003",
    "district_id": "160202",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1602022004",
    "district_id": "160202",
    "label": "Seritanjung",
    "value": "Seritanjung"
  },
  {
    "id": "1602022005",
    "district_id": "160202",
    "label": "Pengaraian",
    "value": "Pengaraian"
  },
  {
    "id": "1602022006",
    "district_id": "160202",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1602022008",
    "district_id": "160202",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1602022010",
    "district_id": "160202",
    "label": "Pulau Gemantung",
    "value": "Pulau Gemantung"
  },
  {
    "id": "1602022012",
    "district_id": "160202",
    "label": "Bumiagung",
    "value": "Bumiagung"
  },
  {
    "id": "1602022013",
    "district_id": "160202",
    "label": "Kotabumi",
    "value": "Kotabumi"
  },
  {
    "id": "1602022014",
    "district_id": "160202",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1602022015",
    "district_id": "160202",
    "label": "Tanjung Laga",
    "value": "Tanjung Laga"
  },
  {
    "id": "1602022016",
    "district_id": "160202",
    "label": "Tanjung Laut",
    "value": "Tanjung Laut"
  },
  {
    "id": "1602022017",
    "district_id": "160202",
    "label": "Ulak Balam",
    "value": "Ulak Balam"
  },
  {
    "id": "1602022018",
    "district_id": "160202",
    "label": "Jambu Ilir",
    "value": "Jambu Ilir"
  },
  {
    "id": "1602022031",
    "district_id": "160202",
    "label": "Jukdadak",
    "value": "Jukdadak"
  },
  {
    "id": "1602022032",
    "district_id": "160202",
    "label": "Atar Balam",
    "value": "Atar Balam"
  },
  {
    "id": "1602022033",
    "district_id": "160202",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1602022034",
    "district_id": "160202",
    "label": "Pulau Gemantung Ilir",
    "value": "Pulau Gemantung Ilir"
  },
  {
    "id": "1602022035",
    "district_id": "160202",
    "label": "Pulau Gemantung Ulu",
    "value": "Pulau Gemantung Ulu"
  },
  {
    "id": "1602022036",
    "district_id": "160202",
    "label": "Pulau Gemantung Darat",
    "value": "Pulau Gemantung Darat"
  },
  {
    "id": "1602032002",
    "district_id": "160203",
    "label": "Pedamaran VI",
    "value": "Pedamaran VI"
  },
  {
    "id": "1602032003",
    "district_id": "160203",
    "label": "Pedamaran V",
    "value": "Pedamaran V"
  },
  {
    "id": "1602032004",
    "district_id": "160203",
    "label": "Pedamaran IV",
    "value": "Pedamaran IV"
  },
  {
    "id": "1602032005",
    "district_id": "160203",
    "label": "Pedamaran III",
    "value": "Pedamaran III"
  },
  {
    "id": "1602032006",
    "district_id": "160203",
    "label": "Pedamaran II",
    "value": "Pedamaran II"
  },
  {
    "id": "1602032007",
    "district_id": "160203",
    "label": "Pedamaran I",
    "value": "Pedamaran I"
  },
  {
    "id": "1602032008",
    "district_id": "160203",
    "label": "Cinta Jaya",
    "value": "Cinta Jaya"
  },
  {
    "id": "1602032009",
    "district_id": "160203",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "1602032010",
    "district_id": "160203",
    "label": "Serinanti",
    "value": "Serinanti"
  },
  {
    "id": "1602032011",
    "district_id": "160203",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1602032017",
    "district_id": "160203",
    "label": "Burnai Timur",
    "value": "Burnai Timur"
  },
  {
    "id": "1602032018",
    "district_id": "160203",
    "label": "Suka Pulih",
    "value": "Suka Pulih"
  },
  {
    "id": "1602032019",
    "district_id": "160203",
    "label": "Menang Raya",
    "value": "Menang Raya"
  },
  {
    "id": "1602032021",
    "district_id": "160203",
    "label": "Lebuh Rarak",
    "value": "Lebuh Rarak"
  },
  {
    "id": "1602042001",
    "district_id": "160204",
    "label": "Pematang Panggang",
    "value": "Pematang Panggang"
  },
  {
    "id": "1602042002",
    "district_id": "160204",
    "label": "Sungai Sodong",
    "value": "Sungai Sodong"
  },
  {
    "id": "1602042005",
    "district_id": "160204",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1602042006",
    "district_id": "160204",
    "label": "Surya Adi",
    "value": "Surya Adi"
  },
  {
    "id": "1602042007",
    "district_id": "160204",
    "label": "Makarti Mulya",
    "value": "Makarti Mulya"
  },
  {
    "id": "1602042008",
    "district_id": "160204",
    "label": "Marga Bakti",
    "value": "Marga Bakti"
  },
  {
    "id": "1602042009",
    "district_id": "160204",
    "label": "Jaya Bakti",
    "value": "Jaya Bakti"
  },
  {
    "id": "1602042025",
    "district_id": "160204",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "1602042026",
    "district_id": "160204",
    "label": "Mekar Wangi",
    "value": "Mekar Wangi"
  },
  {
    "id": "1602042035",
    "district_id": "160204",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1602042037",
    "district_id": "160204",
    "label": "Kalideras",
    "value": "Kalideras"
  },
  {
    "id": "1602042038",
    "district_id": "160204",
    "label": "Sumber Deras",
    "value": "Sumber Deras"
  },
  {
    "id": "1602042047",
    "district_id": "160204",
    "label": "Kembang Jajar",
    "value": "Kembang Jajar"
  },
  {
    "id": "1602042050",
    "district_id": "160204",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1602042051",
    "district_id": "160204",
    "label": "Sido Basuki",
    "value": "Sido Basuki"
  },
  {
    "id": "1602042052",
    "district_id": "160204",
    "label": "Pematang Kasih",
    "value": "Pematang Kasih"
  },
  {
    "id": "1602042053",
    "district_id": "160204",
    "label": "Labuhan Jaya",
    "value": "Labuhan Jaya"
  },
  {
    "id": "1602051001",
    "district_id": "160205",
    "label": "Jua-jua",
    "value": "Jua-jua"
  },
  {
    "id": "1602051002",
    "district_id": "160205",
    "label": "Kayuagung",
    "value": "Kayuagung"
  },
  {
    "id": "1602051003",
    "district_id": "160205",
    "label": "Sidakersa",
    "value": "Sidakersa"
  },
  {
    "id": "1602051004",
    "district_id": "160205",
    "label": "Cintaraja",
    "value": "Cintaraja"
  },
  {
    "id": "1602051005",
    "district_id": "160205",
    "label": "Mangun Jaya",
    "value": "Mangun Jaya"
  },
  {
    "id": "1602051006",
    "district_id": "160205",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "1602051007",
    "district_id": "160205",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1602051008",
    "district_id": "160205",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "1602051009",
    "district_id": "160205",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "1602051010",
    "district_id": "160205",
    "label": "Kutaraja",
    "value": "Kutaraja"
  },
  {
    "id": "1602051025",
    "district_id": "160205",
    "label": "Tanjung Rancing",
    "value": "Tanjung Rancing"
  },
  {
    "id": "1602052011",
    "district_id": "160205",
    "label": "Kijang Ulu",
    "value": "Kijang Ulu"
  },
  {
    "id": "1602052012",
    "district_id": "160205",
    "label": "Muarabaru",
    "value": "Muarabaru"
  },
  {
    "id": "1602052013",
    "district_id": "160205",
    "label": "Tanjung Lubuk",
    "value": "Tanjung Lubuk"
  },
  {
    "id": "1602052014",
    "district_id": "160205",
    "label": "Arisan Buntal",
    "value": "Arisan Buntal"
  },
  {
    "id": "1602052015",
    "district_id": "160205",
    "label": "Celikah",
    "value": "Celikah"
  },
  {
    "id": "1602052016",
    "district_id": "160205",
    "label": "Banding Anyar",
    "value": "Banding Anyar"
  },
  {
    "id": "1602052017",
    "district_id": "160205",
    "label": "Buluh Cawang",
    "value": "Buluh Cawang"
  },
  {
    "id": "1602052018",
    "district_id": "160205",
    "label": "Lubuk Dalam",
    "value": "Lubuk Dalam"
  },
  {
    "id": "1602052019",
    "district_id": "160205",
    "label": "Anyar",
    "value": "Anyar"
  },
  {
    "id": "1602052020",
    "district_id": "160205",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1602052021",
    "district_id": "160205",
    "label": "Teloko",
    "value": "Teloko"
  },
  {
    "id": "1602052022",
    "district_id": "160205",
    "label": "Tanjung Serang",
    "value": "Tanjung Serang"
  },
  {
    "id": "1602052023",
    "district_id": "160205",
    "label": "Serigeni Baru",
    "value": "Serigeni Baru"
  },
  {
    "id": "1602052024",
    "district_id": "160205",
    "label": "Serigeni",
    "value": "Serigeni"
  },
  {
    "id": "1602082001",
    "district_id": "160208",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1602082002",
    "district_id": "160208",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1602082003",
    "district_id": "160208",
    "label": "Awal Terusan",
    "value": "Awal Terusan"
  },
  {
    "id": "1602082004",
    "district_id": "160208",
    "label": "Ulak Jermun",
    "value": "Ulak Jermun"
  },
  {
    "id": "1602082005",
    "district_id": "160208",
    "label": "Mangun Jaya",
    "value": "Mangun Jaya"
  },
  {
    "id": "1602082006",
    "district_id": "160208",
    "label": "Terusan Menang",
    "value": "Terusan Menang"
  },
  {
    "id": "1602082007",
    "district_id": "160208",
    "label": "Belanti",
    "value": "Belanti"
  },
  {
    "id": "1602082008",
    "district_id": "160208",
    "label": "Terate",
    "value": "Terate"
  },
  {
    "id": "1602082009",
    "district_id": "160208",
    "label": "Rengas Pitu",
    "value": "Rengas Pitu"
  },
  {
    "id": "1602082010",
    "district_id": "160208",
    "label": "Pantai",
    "value": "Pantai"
  },
  {
    "id": "1602082011",
    "district_id": "160208",
    "label": "Sirah Pulau Padang",
    "value": "Sirah Pulau Padang"
  },
  {
    "id": "1602082012",
    "district_id": "160208",
    "label": "Serdang Menang",
    "value": "Serdang Menang"
  },
  {
    "id": "1602082013",
    "district_id": "160208",
    "label": "Bungin Tinggi",
    "value": "Bungin Tinggi"
  },
  {
    "id": "1602082014",
    "district_id": "160208",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1602082015",
    "district_id": "160208",
    "label": "Berkat",
    "value": "Berkat"
  },
  {
    "id": "1602082016",
    "district_id": "160208",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1602082017",
    "district_id": "160208",
    "label": "Pematang Buluran",
    "value": "Pematang Buluran"
  },
  {
    "id": "1602082018",
    "district_id": "160208",
    "label": "Rawang Besar",
    "value": "Rawang Besar"
  },
  {
    "id": "1602082019",
    "district_id": "160208",
    "label": "Batu Ampar Baru",
    "value": "Batu Ampar Baru"
  },
  {
    "id": "1602082020",
    "district_id": "160208",
    "label": "Terusan Laut",
    "value": "Terusan Laut"
  },
  {
    "id": "1602111016",
    "district_id": "160211",
    "label": "Tulung Selapan Ulu",
    "value": "Tulung Selapan Ulu"
  },
  {
    "id": "1602112001",
    "district_id": "160211",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1602112002",
    "district_id": "160211",
    "label": "Pulau Beruang",
    "value": "Pulau Beruang"
  },
  {
    "id": "1602112003",
    "district_id": "160211",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1602112004",
    "district_id": "160211",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "1602112005",
    "district_id": "160211",
    "label": "Toman",
    "value": "Toman"
  },
  {
    "id": "1602112006",
    "district_id": "160211",
    "label": "Cambai",
    "value": "Cambai"
  },
  {
    "id": "1602112007",
    "district_id": "160211",
    "label": "Kayuara",
    "value": "Kayuara"
  },
  {
    "id": "1602112008",
    "district_id": "160211",
    "label": "Petaling",
    "value": "Petaling"
  },
  {
    "id": "1602112009",
    "district_id": "160211",
    "label": "Tulung Selapan Ilir",
    "value": "Tulung Selapan Ilir"
  },
  {
    "id": "1602112010",
    "district_id": "160211",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1602112011",
    "district_id": "160211",
    "label": "Lebung Gajah",
    "value": "Lebung Gajah"
  },
  {
    "id": "1602112012",
    "district_id": "160211",
    "label": "Lebung Itam",
    "value": "Lebung Itam"
  },
  {
    "id": "1602112013",
    "district_id": "160211",
    "label": "Penanggoan Duren",
    "value": "Penanggoan Duren"
  },
  {
    "id": "1602112014",
    "district_id": "160211",
    "label": "Jerambah Rengas",
    "value": "Jerambah Rengas"
  },
  {
    "id": "1602112015",
    "district_id": "160211",
    "label": "Simpang Tiga Makmur",
    "value": "Simpang Tiga Makmur"
  },
  {
    "id": "1602112017",
    "district_id": "160211",
    "label": "Simpang Tiga Jaya",
    "value": "Simpang Tiga Jaya"
  },
  {
    "id": "1602112018",
    "district_id": "160211",
    "label": "Simpang Tiga Sakti",
    "value": "Simpang Tiga Sakti"
  },
  {
    "id": "1602112019",
    "district_id": "160211",
    "label": "Tulung Seluang",
    "value": "Tulung Seluang"
  },
  {
    "id": "1602112020",
    "district_id": "160211",
    "label": "Rantau Lurus",
    "value": "Rantau Lurus"
  },
  {
    "id": "1602112021",
    "district_id": "160211",
    "label": "Kuala Dua Belas",
    "value": "Kuala Dua Belas"
  },
  {
    "id": "1602112022",
    "district_id": "160211",
    "label": "Simpang Tiga Abadi",
    "value": "Simpang Tiga Abadi"
  },
  {
    "id": "1602112023",
    "district_id": "160211",
    "label": "Tulung Selapan Timur",
    "value": "Tulung Selapan Timur"
  },
  {
    "id": "1602122001",
    "district_id": "160212",
    "label": "Ulak Kemang",
    "value": "Ulak Kemang"
  },
  {
    "id": "1602122002",
    "district_id": "160212",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "1602122003",
    "district_id": "160212",
    "label": "Keman",
    "value": "Keman"
  },
  {
    "id": "1602122004",
    "district_id": "160212",
    "label": "Kandis",
    "value": "Kandis"
  },
  {
    "id": "1602122005",
    "district_id": "160212",
    "label": "Ulak Depati",
    "value": "Ulak Depati"
  },
  {
    "id": "1602122006",
    "district_id": "160212",
    "label": "Pulau Layang",
    "value": "Pulau Layang"
  },
  {
    "id": "1602122007",
    "district_id": "160212",
    "label": "Tapus",
    "value": "Tapus"
  },
  {
    "id": "1602122008",
    "district_id": "160212",
    "label": "Pulau Betung",
    "value": "Pulau Betung"
  },
  {
    "id": "1602122009",
    "district_id": "160212",
    "label": "Bangsal",
    "value": "Bangsal"
  },
  {
    "id": "1602122010",
    "district_id": "160212",
    "label": "Kuro",
    "value": "Kuro"
  },
  {
    "id": "1602122011",
    "district_id": "160212",
    "label": "Menggeris",
    "value": "Menggeris"
  },
  {
    "id": "1602122012",
    "district_id": "160212",
    "label": "Pampangan",
    "value": "Pampangan"
  },
  {
    "id": "1602122013",
    "district_id": "160212",
    "label": "Serimenang",
    "value": "Serimenang"
  },
  {
    "id": "1602122014",
    "district_id": "160212",
    "label": "Secondong",
    "value": "Secondong"
  },
  {
    "id": "1602122015",
    "district_id": "160212",
    "label": "Jermun",
    "value": "Jermun"
  },
  {
    "id": "1602122016",
    "district_id": "160212",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1602122017",
    "district_id": "160212",
    "label": "Jungkal",
    "value": "Jungkal"
  },
  {
    "id": "1602122035",
    "district_id": "160212",
    "label": "Tanjung Kemang",
    "value": "Tanjung Kemang"
  },
  {
    "id": "1602122036",
    "district_id": "160212",
    "label": "Ulak Pianggu",
    "value": "Ulak Pianggu"
  },
  {
    "id": "1602122037",
    "district_id": "160212",
    "label": "Ulak Kemang Baru",
    "value": "Ulak Kemang Baru"
  },
  {
    "id": "1602122038",
    "district_id": "160212",
    "label": "Keman Baru",
    "value": "Keman Baru"
  },
  {
    "id": "1602122039",
    "district_id": "160212",
    "label": "Sri Mulya",
    "value": "Sri Mulya"
  },
  {
    "id": "1602132005",
    "district_id": "160213",
    "label": "Tebing Suluh",
    "value": "Tebing Suluh"
  },
  {
    "id": "1602132006",
    "district_id": "160213",
    "label": "Cahya Bumi",
    "value": "Cahya Bumi"
  },
  {
    "id": "1602132007",
    "district_id": "160213",
    "label": "Kutapandan",
    "value": "Kutapandan"
  },
  {
    "id": "1602132008",
    "district_id": "160213",
    "label": "Cahaya Maju",
    "value": "Cahaya Maju"
  },
  {
    "id": "1602132009",
    "district_id": "160213",
    "label": "Bumiagung",
    "value": "Bumiagung"
  },
  {
    "id": "1602132010",
    "district_id": "160213",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "1602132011",
    "district_id": "160213",
    "label": "Tugumulyo",
    "value": "Tugumulyo"
  },
  {
    "id": "1602132013",
    "district_id": "160213",
    "label": "Dabuk Rejo",
    "value": "Dabuk Rejo"
  },
  {
    "id": "1602132014",
    "district_id": "160213",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1602132015",
    "district_id": "160213",
    "label": "Bumi Arjo",
    "value": "Bumi Arjo"
  },
  {
    "id": "1602132016",
    "district_id": "160213",
    "label": "Tulung Harapan",
    "value": "Tulung Harapan"
  },
  {
    "id": "1602132017",
    "district_id": "160213",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "1602132025",
    "district_id": "160213",
    "label": "Tugu Jaya",
    "value": "Tugu Jaya"
  },
  {
    "id": "1602132026",
    "district_id": "160213",
    "label": "Tugu Agung",
    "value": "Tugu Agung"
  },
  {
    "id": "1602132027",
    "district_id": "160213",
    "label": "Sindang Sari",
    "value": "Sindang Sari"
  },
  {
    "id": "1602132028",
    "district_id": "160213",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1602132034",
    "district_id": "160213",
    "label": "Bumiarjo Makmur",
    "value": "Bumiarjo Makmur"
  },
  {
    "id": "1602132035",
    "district_id": "160213",
    "label": "Cahaya Makmur",
    "value": "Cahaya Makmur"
  },
  {
    "id": "1602132036",
    "district_id": "160213",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1602142001",
    "district_id": "160214",
    "label": "Tirta Mulya",
    "value": "Tirta Mulya"
  },
  {
    "id": "1602142002",
    "district_id": "160214",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1602142003",
    "district_id": "160214",
    "label": "Jadi Mulya",
    "value": "Jadi Mulya"
  },
  {
    "id": "1602142004",
    "district_id": "160214",
    "label": "Marga Tani",
    "value": "Marga Tani"
  },
  {
    "id": "1602142005",
    "district_id": "160214",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1602142006",
    "district_id": "160214",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "1602142007",
    "district_id": "160214",
    "label": "Srijaya Baru",
    "value": "Srijaya Baru"
  },
  {
    "id": "1602142008",
    "district_id": "160214",
    "label": "Nusantara",
    "value": "Nusantara"
  },
  {
    "id": "1602142009",
    "district_id": "160214",
    "label": "Kerta Mukti",
    "value": "Kerta Mukti"
  },
  {
    "id": "1602142010",
    "district_id": "160214",
    "label": "Nusakerta",
    "value": "Nusakerta"
  },
  {
    "id": "1602142011",
    "district_id": "160214",
    "label": "Banyu Biru",
    "value": "Banyu Biru"
  },
  {
    "id": "1602142012",
    "district_id": "160214",
    "label": "Pangkalan Damai",
    "value": "Pangkalan Damai"
  },
  {
    "id": "1602142013",
    "district_id": "160214",
    "label": "Negeri Sakti",
    "value": "Negeri Sakti"
  },
  {
    "id": "1602142014",
    "district_id": "160214",
    "label": "Rantau Karya",
    "value": "Rantau Karya"
  },
  {
    "id": "1602142015",
    "district_id": "160214",
    "label": "Pangkalan Sakti",
    "value": "Pangkalan Sakti"
  },
  {
    "id": "1602142016",
    "district_id": "160214",
    "label": "Simpang Heran",
    "value": "Simpang Heran"
  },
  {
    "id": "1602142017",
    "district_id": "160214",
    "label": "Rengas Abang",
    "value": "Rengas Abang"
  },
  {
    "id": "1602142018",
    "district_id": "160214",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "1602142019",
    "district_id": "160214",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  {
    "id": "1602152001",
    "district_id": "160215",
    "label": "Sungai Menang",
    "value": "Sungai Menang"
  },
  {
    "id": "1602152002",
    "district_id": "160215",
    "label": "Karangsia",
    "value": "Karangsia"
  },
  {
    "id": "1602152003",
    "district_id": "160215",
    "label": "Sungai Ceper",
    "value": "Sungai Ceper"
  },
  {
    "id": "1602152004",
    "district_id": "160215",
    "label": "Gajah Mati",
    "value": "Gajah Mati"
  },
  {
    "id": "1602152005",
    "district_id": "160215",
    "label": "Sungai Tepuk",
    "value": "Sungai Tepuk"
  },
  {
    "id": "1602152006",
    "district_id": "160215",
    "label": "Sungai Sibur",
    "value": "Sungai Sibur"
  },
  {
    "id": "1602152007",
    "district_id": "160215",
    "label": "Talang Makmur",
    "value": "Talang Makmur"
  },
  {
    "id": "1602152008",
    "district_id": "160215",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1602152009",
    "district_id": "160215",
    "label": "Talang Jaya",
    "value": "Talang Jaya"
  },
  {
    "id": "1602152010",
    "district_id": "160215",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1602152011",
    "district_id": "160215",
    "label": "Bumi Pratama Mandira",
    "value": "Bumi Pratama Mandira"
  },
  {
    "id": "1602152012",
    "district_id": "160215",
    "label": "Pinang Indah",
    "value": "Pinang Indah"
  },
  {
    "id": "1602152013",
    "district_id": "160215",
    "label": "Gajah Makmur",
    "value": "Gajah Makmur"
  },
  {
    "id": "1602152014",
    "district_id": "160215",
    "label": "Gading Jaya",
    "value": "Gading Jaya"
  },
  {
    "id": "1602152015",
    "district_id": "160215",
    "label": "Gajah Mukti",
    "value": "Gajah Mukti"
  },
  {
    "id": "1602152016",
    "district_id": "160215",
    "label": "Gajah Mulya",
    "value": "Gajah Mulya"
  },
  {
    "id": "1602152017",
    "district_id": "160215",
    "label": "Sri Gading",
    "value": "Sri Gading"
  },
  {
    "id": "1602152018",
    "district_id": "160215",
    "label": "Gading Mas",
    "value": "Gading Mas"
  },
  {
    "id": "1602172001",
    "district_id": "160217",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1602172002",
    "district_id": "160217",
    "label": "Lubuk Ketepeng",
    "value": "Lubuk Ketepeng"
  },
  {
    "id": "1602172003",
    "district_id": "160217",
    "label": "Talang Cempedak",
    "value": "Talang Cempedak"
  },
  {
    "id": "1602172004",
    "district_id": "160217",
    "label": "Bubusan",
    "value": "Bubusan"
  },
  {
    "id": "1602172005",
    "district_id": "160217",
    "label": "Air Itam",
    "value": "Air Itam"
  },
  {
    "id": "1602172006",
    "district_id": "160217",
    "label": "Jejawi",
    "value": "Jejawi"
  },
  {
    "id": "1602172007",
    "district_id": "160217",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1602172008",
    "district_id": "160217",
    "label": "Muara Batun",
    "value": "Muara Batun"
  },
  {
    "id": "1602172009",
    "district_id": "160217",
    "label": "Lingkis",
    "value": "Lingkis"
  },
  {
    "id": "1602172010",
    "district_id": "160217",
    "label": "Terusan Jawa",
    "value": "Terusan Jawa"
  },
  {
    "id": "1602172011",
    "district_id": "160217",
    "label": "Tanjung Ali",
    "value": "Tanjung Ali"
  },
  {
    "id": "1602172012",
    "district_id": "160217",
    "label": "Sukadarma",
    "value": "Sukadarma"
  },
  {
    "id": "1602172013",
    "district_id": "160217",
    "label": "Ulak Tembaga",
    "value": "Ulak Tembaga"
  },
  {
    "id": "1602172014",
    "district_id": "160217",
    "label": "Pedu",
    "value": "Pedu"
  },
  {
    "id": "1602172015",
    "district_id": "160217",
    "label": "Pematang Kijang",
    "value": "Pematang Kijang"
  },
  {
    "id": "1602172016",
    "district_id": "160217",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1602172017",
    "district_id": "160217",
    "label": "Padang Bulan",
    "value": "Padang Bulan"
  },
  {
    "id": "1602172018",
    "district_id": "160217",
    "label": "Batun Baru",
    "value": "Batun Baru"
  },
  {
    "id": "1602172019",
    "district_id": "160217",
    "label": "Danau Ceper",
    "value": "Danau Ceper"
  },
  {
    "id": "1602182001",
    "district_id": "160218",
    "label": "Sungai Somor",
    "value": "Sungai Somor"
  },
  {
    "id": "1602182002",
    "district_id": "160218",
    "label": "Sungai Lumpur",
    "value": "Sungai Lumpur"
  },
  {
    "id": "1602182003",
    "district_id": "160218",
    "label": "Sungai Jeruju",
    "value": "Sungai Jeruju"
  },
  {
    "id": "1602182004",
    "district_id": "160218",
    "label": "Sungai Ketupak",
    "value": "Sungai Ketupak"
  },
  {
    "id": "1602182005",
    "district_id": "160218",
    "label": "Ulak Kedondong",
    "value": "Ulak Kedondong"
  },
  {
    "id": "1602182006",
    "district_id": "160218",
    "label": "Pelimbangan",
    "value": "Pelimbangan"
  },
  {
    "id": "1602182007",
    "district_id": "160218",
    "label": "Cengal",
    "value": "Cengal"
  },
  {
    "id": "1602182008",
    "district_id": "160218",
    "label": "Talang Rimba",
    "value": "Talang Rimba"
  },
  {
    "id": "1602182009",
    "district_id": "160218",
    "label": "Sungai Pasir",
    "value": "Sungai Pasir"
  },
  {
    "id": "1602182010",
    "district_id": "160218",
    "label": "Kuala Sungai Jeruju",
    "value": "Kuala Sungai Jeruju"
  },
  {
    "id": "1602182011",
    "district_id": "160218",
    "label": "Kuala Sungai Pasir",
    "value": "Kuala Sungai Pasir"
  },
  {
    "id": "1602182012",
    "district_id": "160218",
    "label": "Pantai Harapan",
    "value": "Pantai Harapan"
  },
  {
    "id": "1602182013",
    "district_id": "160218",
    "label": "Adil Makmur",
    "value": "Adil Makmur"
  },
  {
    "id": "1602182014",
    "district_id": "160218",
    "label": "Balam Jeruju",
    "value": "Balam Jeruju"
  },
  {
    "id": "1602182015",
    "district_id": "160218",
    "label": "Kebun Cabe",
    "value": "Kebun Cabe"
  },
  {
    "id": "1602182016",
    "district_id": "160218",
    "label": "Lebak Beriang",
    "value": "Lebak Beriang"
  },
  {
    "id": "1602182017",
    "district_id": "160218",
    "label": "Parit Raya",
    "value": "Parit Raya"
  },
  {
    "id": "1602192001",
    "district_id": "160219",
    "label": "Deling",
    "value": "Deling"
  },
  {
    "id": "1602192002",
    "district_id": "160219",
    "label": "Pangkalan Lampan",
    "value": "Pangkalan Lampan"
  },
  {
    "id": "1602192003",
    "district_id": "160219",
    "label": "Lirik",
    "value": "Lirik"
  },
  {
    "id": "1602192004",
    "district_id": "160219",
    "label": "Lebung Batang",
    "value": "Lebung Batang"
  },
  {
    "id": "1602192005",
    "district_id": "160219",
    "label": "Sungai Bungin",
    "value": "Sungai Bungin"
  },
  {
    "id": "1602192006",
    "district_id": "160219",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1602192007",
    "district_id": "160219",
    "label": "Darat",
    "value": "Darat"
  },
  {
    "id": "1602192008",
    "district_id": "160219",
    "label": "Pulauan",
    "value": "Pulauan"
  },
  {
    "id": "1602192009",
    "district_id": "160219",
    "label": "Riding",
    "value": "Riding"
  },
  {
    "id": "1602192010",
    "district_id": "160219",
    "label": "Sunggutan",
    "value": "Sunggutan"
  },
  {
    "id": "1602192011",
    "district_id": "160219",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1602192012",
    "district_id": "160219",
    "label": "Air Pedara",
    "value": "Air Pedara"
  },
  {
    "id": "1602192013",
    "district_id": "160219",
    "label": "Rimba Samak",
    "value": "Rimba Samak"
  },
  {
    "id": "1602192014",
    "district_id": "160219",
    "label": "Talang Daya",
    "value": "Talang Daya"
  },
  {
    "id": "1602192015",
    "district_id": "160219",
    "label": "Air Rumbai",
    "value": "Air Rumbai"
  },
  {
    "id": "1602192016",
    "district_id": "160219",
    "label": "Rambai",
    "value": "Rambai"
  },
  {
    "id": "1602192017",
    "district_id": "160219",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "1602192018",
    "district_id": "160219",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "1602192019",
    "district_id": "160219",
    "label": "Rawa Tenam",
    "value": "Rawa Tenam"
  },
  {
    "id": "1602202001",
    "district_id": "160220",
    "label": "Catur Tunggal",
    "value": "Catur Tunggal"
  },
  {
    "id": "1602202002",
    "district_id": "160220",
    "label": "Surya Karta",
    "value": "Surya Karta"
  },
  {
    "id": "1602202003",
    "district_id": "160220",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1602202004",
    "district_id": "160220",
    "label": "Mukti Karya",
    "value": "Mukti Karya"
  },
  {
    "id": "1602202005",
    "district_id": "160220",
    "label": "Pematang Jaya",
    "value": "Pematang Jaya"
  },
  {
    "id": "1602202006",
    "district_id": "160220",
    "label": "Pematang Sukatani",
    "value": "Pematang Sukatani"
  },
  {
    "id": "1602202007",
    "district_id": "160220",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1602202008",
    "district_id": "160220",
    "label": "Cahaya Mas",
    "value": "Cahaya Mas"
  },
  {
    "id": "1602202009",
    "district_id": "160220",
    "label": "Karya Usaha",
    "value": "Karya Usaha"
  },
  {
    "id": "1602202010",
    "district_id": "160220",
    "label": "Pematang Binatani",
    "value": "Pematang Binatani"
  },
  {
    "id": "1602202011",
    "district_id": "160220",
    "label": "Bina Karsa",
    "value": "Bina Karsa"
  },
  {
    "id": "1602202012",
    "district_id": "160220",
    "label": "Pematang Sari",
    "value": "Pematang Sari"
  },
  {
    "id": "1602202013",
    "district_id": "160220",
    "label": "Pematang Sukaramah",
    "value": "Pematang Sukaramah"
  },
  {
    "id": "1602202014",
    "district_id": "160220",
    "label": "Cahaya Mulya",
    "value": "Cahaya Mulya"
  },
  {
    "id": "1602202015",
    "district_id": "160220",
    "label": "Gading Sari",
    "value": "Gading Sari"
  },
  {
    "id": "1602202017",
    "district_id": "160220",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "1602202018",
    "district_id": "160220",
    "label": "Mesuji Jaya",
    "value": "Mesuji Jaya"
  },
  {
    "id": "1602202019",
    "district_id": "160220",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1602202020",
    "district_id": "160220",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1602212001",
    "district_id": "160221",
    "label": "Embacang",
    "value": "Embacang"
  },
  {
    "id": "1602212002",
    "district_id": "160221",
    "label": "Balian",
    "value": "Balian"
  },
  {
    "id": "1602212003",
    "district_id": "160221",
    "label": "Sumbu Sari",
    "value": "Sumbu Sari"
  },
  {
    "id": "1602212004",
    "district_id": "160221",
    "label": "Suka Sari",
    "value": "Suka Sari"
  },
  {
    "id": "1602212005",
    "district_id": "160221",
    "label": "Ciptasari",
    "value": "Ciptasari"
  },
  {
    "id": "1602212006",
    "district_id": "160221",
    "label": "Mataram Jaya",
    "value": "Mataram Jaya"
  },
  {
    "id": "1602212007",
    "district_id": "160221",
    "label": "Bumi Makmur",
    "value": "Bumi Makmur"
  },
  {
    "id": "1602212008",
    "district_id": "160221",
    "label": "Sumber Baru",
    "value": "Sumber Baru"
  },
  {
    "id": "1602212009",
    "district_id": "160221",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1602212010",
    "district_id": "160221",
    "label": "Rotan Mulya",
    "value": "Rotan Mulya"
  },
  {
    "id": "1602212011",
    "district_id": "160221",
    "label": "Embacang Permai",
    "value": "Embacang Permai"
  },
  {
    "id": "1602212012",
    "district_id": "160221",
    "label": "Gedung Rejo",
    "value": "Gedung Rejo"
  },
  {
    "id": "1602212013",
    "district_id": "160221",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "1602212014",
    "district_id": "160221",
    "label": "Kemang Indah",
    "value": "Kemang Indah"
  },
  {
    "id": "1602212015",
    "district_id": "160221",
    "label": "Balian Makmur",
    "value": "Balian Makmur"
  },
  {
    "id": "1602212016",
    "district_id": "160221",
    "label": "Sedyomulyo",
    "value": "Sedyomulyo"
  },
  {
    "id": "1602212017",
    "district_id": "160221",
    "label": "Dabuk Makmur",
    "value": "Dabuk Makmur"
  },
  {
    "id": "1602222001",
    "district_id": "160222",
    "label": "Tanjung Sari I",
    "value": "Tanjung Sari I"
  },
  {
    "id": "1602222002",
    "district_id": "160222",
    "label": "Lubuk Seberuk",
    "value": "Lubuk Seberuk"
  },
  {
    "id": "1602222003",
    "district_id": "160222",
    "label": "Rantau Durian I",
    "value": "Rantau Durian I"
  },
  {
    "id": "1602222004",
    "district_id": "160222",
    "label": "Sungai Belida",
    "value": "Sungai Belida"
  },
  {
    "id": "1602222005",
    "district_id": "160222",
    "label": "Lempuing Indah",
    "value": "Lempuing Indah"
  },
  {
    "id": "1602222006",
    "district_id": "160222",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "1602222007",
    "district_id": "160222",
    "label": "Tania Makmur",
    "value": "Tania Makmur"
  },
  {
    "id": "1602222008",
    "district_id": "160222",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1602222009",
    "district_id": "160222",
    "label": "Muara Burnai II",
    "value": "Muara Burnai II"
  },
  {
    "id": "1602222010",
    "district_id": "160222",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1602222011",
    "district_id": "160222",
    "label": "Rantau Durian II",
    "value": "Rantau Durian II"
  },
  {
    "id": "1602222012",
    "district_id": "160222",
    "label": "Tanjung Sari II",
    "value": "Tanjung Sari II"
  },
  {
    "id": "1602222013",
    "district_id": "160222",
    "label": "Purwo Asri",
    "value": "Purwo Asri"
  },
  {
    "id": "1602222014",
    "district_id": "160222",
    "label": "Muara Burnai I",
    "value": "Muara Burnai I"
  },
  {
    "id": "1602222015",
    "district_id": "160222",
    "label": "Lubuk Makmur",
    "value": "Lubuk Makmur"
  },
  {
    "id": "1602222016",
    "district_id": "160222",
    "label": "Rantau Durian Asli",
    "value": "Rantau Durian Asli"
  },
  {
    "id": "1602232001",
    "district_id": "160223",
    "label": "Benawa",
    "value": "Benawa"
  },
  {
    "id": "1602232002",
    "district_id": "160223",
    "label": "Serapek",
    "value": "Serapek"
  },
  {
    "id": "1602232003",
    "district_id": "160223",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1602232004",
    "district_id": "160223",
    "label": "Muara Telang",
    "value": "Muara Telang"
  },
  {
    "id": "1602232005",
    "district_id": "160223",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1602232006",
    "district_id": "160223",
    "label": "Cinta Marga",
    "value": "Cinta Marga"
  },
  {
    "id": "1602232007",
    "district_id": "160223",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1602232008",
    "district_id": "160223",
    "label": "Talang Pengeran",
    "value": "Talang Pengeran"
  },
  {
    "id": "1602232009",
    "district_id": "160223",
    "label": "Ulak Ketapang",
    "value": "Ulak Ketapang"
  },
  {
    "id": "1602232010",
    "district_id": "160223",
    "label": "Mulyaguna",
    "value": "Mulyaguna"
  },
  {
    "id": "1602232011",
    "district_id": "160223",
    "label": "Panca Tunggal Benawa",
    "value": "Panca Tunggal Benawa"
  },
  {
    "id": "1602232012",
    "district_id": "160223",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "1602232013",
    "district_id": "160223",
    "label": "Sinar Harapan Mulya",
    "value": "Sinar Harapan Mulya"
  },
  {
    "id": "1602232014",
    "district_id": "160223",
    "label": "Seriguna",
    "value": "Seriguna"
  },
  {
    "id": "1602242001",
    "district_id": "160224",
    "label": "Pulau Geronggang",
    "value": "Pulau Geronggang"
  },
  {
    "id": "1602242002",
    "district_id": "160224",
    "label": "Sumber Hidup",
    "value": "Sumber Hidup"
  },
  {
    "id": "1602242003",
    "district_id": "160224",
    "label": "Gading Raja",
    "value": "Gading Raja"
  },
  {
    "id": "1602242004",
    "district_id": "160224",
    "label": "Maribaya",
    "value": "Maribaya"
  },
  {
    "id": "1602242005",
    "district_id": "160224",
    "label": "Pancawarna",
    "value": "Pancawarna"
  },
  {
    "id": "1602242006",
    "district_id": "160224",
    "label": "Tanjung Makmur",
    "value": "Tanjung Makmur"
  },
  {
    "id": "1602242007",
    "district_id": "160224",
    "label": "Kayu Labu",
    "value": "Kayu Labu"
  },
  {
    "id": "1603012011",
    "district_id": "160301",
    "label": "Embawang",
    "value": "Embawang"
  },
  {
    "id": "1603012012",
    "district_id": "160301",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1603012013",
    "district_id": "160301",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1603012014",
    "district_id": "160301",
    "label": "Lesung Batu",
    "value": "Lesung Batu"
  },
  {
    "id": "1603012015",
    "district_id": "160301",
    "label": "Pandan Enim",
    "value": "Pandan Enim"
  },
  {
    "id": "1603012016",
    "district_id": "160301",
    "label": "Paduraksa",
    "value": "Paduraksa"
  },
  {
    "id": "1603012017",
    "district_id": "160301",
    "label": "Matas",
    "value": "Matas"
  },
  {
    "id": "1603012018",
    "district_id": "160301",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1603012019",
    "district_id": "160301",
    "label": "Muara Emil",
    "value": "Muara Emil"
  },
  {
    "id": "1603012020",
    "district_id": "160301",
    "label": "Tanjung Karangan",
    "value": "Tanjung Karangan"
  },
  {
    "id": "1603012021",
    "district_id": "160301",
    "label": "Seleman",
    "value": "Seleman"
  },
  {
    "id": "1603012022",
    "district_id": "160301",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1603012023",
    "district_id": "160301",
    "label": "Tanjung Lalang",
    "value": "Tanjung Lalang"
  },
  {
    "id": "1603012024",
    "district_id": "160301",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1603021006",
    "district_id": "160302",
    "label": "Tungkal",
    "value": "Tungkal"
  },
  {
    "id": "1603021007",
    "district_id": "160302",
    "label": "Pasar II Muara Enim",
    "value": "Pasar II Muara Enim"
  },
  {
    "id": "1603021008",
    "district_id": "160302",
    "label": "Pasar III Muara Enim",
    "value": "Pasar III Muara Enim"
  },
  {
    "id": "1603021009",
    "district_id": "160302",
    "label": "Pasar I Muara Enim",
    "value": "Pasar I Muara Enim"
  },
  {
    "id": "1603021010",
    "district_id": "160302",
    "label": "Muara Enim",
    "value": "Muara Enim"
  },
  {
    "id": "1603021016",
    "district_id": "160302",
    "label": "Air Lintang",
    "value": "Air Lintang"
  },
  {
    "id": "1603022001",
    "district_id": "160302",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1603022002",
    "district_id": "160302",
    "label": "Karang Raja",
    "value": "Karang Raja"
  },
  {
    "id": "1603022003",
    "district_id": "160302",
    "label": "Muara Lawai",
    "value": "Muara Lawai"
  },
  {
    "id": "1603022004",
    "district_id": "160302",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1603022005",
    "district_id": "160302",
    "label": "Lubuk Emplas",
    "value": "Lubuk Emplas"
  },
  {
    "id": "1603022011",
    "district_id": "160302",
    "label": "Kepur",
    "value": "Kepur"
  },
  {
    "id": "1603022012",
    "district_id": "160302",
    "label": "Tanjung Serian",
    "value": "Tanjung Serian"
  },
  {
    "id": "1603022013",
    "district_id": "160302",
    "label": "Muara Harapan",
    "value": "Muara Harapan"
  },
  {
    "id": "1603022014",
    "district_id": "160302",
    "label": "Saka Jaya",
    "value": "Saka Jaya"
  },
  {
    "id": "1603022015",
    "district_id": "160302",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1603032001",
    "district_id": "160303",
    "label": "Gemawang",
    "value": "Gemawang"
  },
  {
    "id": "1603032002",
    "district_id": "160303",
    "label": "Lubuk Raman",
    "value": "Lubuk Raman"
  },
  {
    "id": "1603032003",
    "district_id": "160303",
    "label": "Jemenang",
    "value": "Jemenang"
  },
  {
    "id": "1603032004",
    "district_id": "160303",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1603032005",
    "district_id": "160303",
    "label": "Tebat Agung",
    "value": "Tebat Agung"
  },
  {
    "id": "1603032006",
    "district_id": "160303",
    "label": "Gerinam",
    "value": "Gerinam"
  },
  {
    "id": "1603032014",
    "district_id": "160303",
    "label": "Suban Jeriji",
    "value": "Suban Jeriji"
  },
  {
    "id": "1603032015",
    "district_id": "160303",
    "label": "Air Limau",
    "value": "Air Limau"
  },
  {
    "id": "1603032016",
    "district_id": "160303",
    "label": "Muara Emburung",
    "value": "Muara Emburung"
  },
  {
    "id": "1603032017",
    "district_id": "160303",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "1603032018",
    "district_id": "160303",
    "label": "Air Talas",
    "value": "Air Talas"
  },
  {
    "id": "1603032019",
    "district_id": "160303",
    "label": "Air Enau",
    "value": "Air Enau"
  },
  {
    "id": "1603032020",
    "district_id": "160303",
    "label": "Manunggal Makmur",
    "value": "Manunggal Makmur"
  },
  {
    "id": "1603032021",
    "district_id": "160303",
    "label": "Aur Duri",
    "value": "Aur Duri"
  },
  {
    "id": "1603032023",
    "district_id": "160303",
    "label": "Kasih Dewa",
    "value": "Kasih Dewa"
  },
  {
    "id": "1603032024",
    "district_id": "160303",
    "label": "Air Cekdam",
    "value": "Air Cekdam"
  },
  {
    "id": "1603042001",
    "district_id": "160304",
    "label": "Penanggiran",
    "value": "Penanggiran"
  },
  {
    "id": "1603042002",
    "district_id": "160304",
    "label": "Gunung Megang Luar",
    "value": "Gunung Megang Luar"
  },
  {
    "id": "1603042003",
    "district_id": "160304",
    "label": "Gunung Megang Dalam",
    "value": "Gunung Megang Dalam"
  },
  {
    "id": "1603042004",
    "district_id": "160304",
    "label": "Lubuk Mumpo",
    "value": "Lubuk Mumpo"
  },
  {
    "id": "1603042005",
    "district_id": "160304",
    "label": "Perjito",
    "value": "Perjito"
  },
  {
    "id": "1603042006",
    "district_id": "160304",
    "label": "Tanjung Muning",
    "value": "Tanjung Muning"
  },
  {
    "id": "1603042007",
    "district_id": "160304",
    "label": "Tanjung Terang",
    "value": "Tanjung Terang"
  },
  {
    "id": "1603042016",
    "district_id": "160304",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1603042017",
    "district_id": "160304",
    "label": "Sumaja Makmur",
    "value": "Sumaja Makmur"
  },
  {
    "id": "1603042018",
    "district_id": "160304",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1603042019",
    "district_id": "160304",
    "label": "Pajar Indah",
    "value": "Pajar Indah"
  },
  {
    "id": "1603042020",
    "district_id": "160304",
    "label": "Kayu Ara Sakti",
    "value": "Kayu Ara Sakti"
  },
  {
    "id": "1603042021",
    "district_id": "160304",
    "label": "Panang Jaya",
    "value": "Panang Jaya"
  },
  {
    "id": "1603061010",
    "district_id": "160306",
    "label": "Gelumbang",
    "value": "Gelumbang"
  },
  {
    "id": "1603062004",
    "district_id": "160306",
    "label": "Gaung Telang",
    "value": "Gaung Telang"
  },
  {
    "id": "1603062006",
    "district_id": "160306",
    "label": "Pinang Banjar",
    "value": "Pinang Banjar"
  },
  {
    "id": "1603062008",
    "district_id": "160306",
    "label": "Segayam",
    "value": "Segayam"
  },
  {
    "id": "1603062009",
    "district_id": "160306",
    "label": "Tambangan Kelekar",
    "value": "Tambangan Kelekar"
  },
  {
    "id": "1603062011",
    "district_id": "160306",
    "label": "Suka Menang",
    "value": "Suka Menang"
  },
  {
    "id": "1603062013",
    "district_id": "160306",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1603062014",
    "district_id": "160306",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "1603062015",
    "district_id": "160306",
    "label": "Midar",
    "value": "Midar"
  },
  {
    "id": "1603062016",
    "district_id": "160306",
    "label": "Mililian",
    "value": "Mililian"
  },
  {
    "id": "1603062017",
    "district_id": "160306",
    "label": "Pedataran",
    "value": "Pedataran"
  },
  {
    "id": "1603062018",
    "district_id": "160306",
    "label": "Teluk Limau",
    "value": "Teluk Limau"
  },
  {
    "id": "1603062019",
    "district_id": "160306",
    "label": "Kerta Mulya",
    "value": "Kerta Mulya"
  },
  {
    "id": "1603062020",
    "district_id": "160306",
    "label": "Sebau",
    "value": "Sebau"
  },
  {
    "id": "1603062021",
    "district_id": "160306",
    "label": "Sigam",
    "value": "Sigam"
  },
  {
    "id": "1603062022",
    "district_id": "160306",
    "label": "Payabakal",
    "value": "Payabakal"
  },
  {
    "id": "1603062023",
    "district_id": "160306",
    "label": "Bitis",
    "value": "Bitis"
  },
  {
    "id": "1603062024",
    "district_id": "160306",
    "label": "Gumai",
    "value": "Gumai"
  },
  {
    "id": "1603062025",
    "district_id": "160306",
    "label": "Putak",
    "value": "Putak"
  },
  {
    "id": "1603062026",
    "district_id": "160306",
    "label": "Talang Taling",
    "value": "Talang Taling"
  },
  {
    "id": "1603062030",
    "district_id": "160306",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1603062031",
    "district_id": "160306",
    "label": "Karang Endah Selatan",
    "value": "Karang Endah Selatan"
  },
  {
    "id": "1603062032",
    "district_id": "160306",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1603071001",
    "district_id": "160307",
    "label": "Tanjung Enim",
    "value": "Tanjung Enim"
  },
  {
    "id": "1603071002",
    "district_id": "160307",
    "label": "Pasar Tanjung Enim",
    "value": "Pasar Tanjung Enim"
  },
  {
    "id": "1603071003",
    "district_id": "160307",
    "label": "Tanjung Enim Selatan",
    "value": "Tanjung Enim Selatan"
  },
  {
    "id": "1603072004",
    "district_id": "160307",
    "label": "Tegal Rejo",
    "value": "Tegal Rejo"
  },
  {
    "id": "1603072005",
    "district_id": "160307",
    "label": "Lingga",
    "value": "Lingga"
  },
  {
    "id": "1603072006",
    "district_id": "160307",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1603072007",
    "district_id": "160307",
    "label": "Darmo",
    "value": "Darmo"
  },
  {
    "id": "1603082001",
    "district_id": "160308",
    "label": "Penindaian",
    "value": "Penindaian"
  },
  {
    "id": "1603082002",
    "district_id": "160308",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "1603082003",
    "district_id": "160308",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1603082004",
    "district_id": "160308",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1603082005",
    "district_id": "160308",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1603082006",
    "district_id": "160308",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "1603082007",
    "district_id": "160308",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1603082008",
    "district_id": "160308",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1603082009",
    "district_id": "160308",
    "label": "Karya Nyata",
    "value": "Karya Nyata"
  },
  {
    "id": "1603082010",
    "district_id": "160308",
    "label": "Perapau",
    "value": "Perapau"
  },
  {
    "id": "1603092001",
    "district_id": "160309",
    "label": "Tenam Bungkuk",
    "value": "Tenam Bungkuk"
  },
  {
    "id": "1603092002",
    "district_id": "160309",
    "label": "Kota Padang",
    "value": "Kota Padang"
  },
  {
    "id": "1603092003",
    "district_id": "160309",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1603092004",
    "district_id": "160309",
    "label": "Sri Tanjung",
    "value": "Sri Tanjung"
  },
  {
    "id": "1603092005",
    "district_id": "160309",
    "label": "Tebing Abang",
    "value": "Tebing Abang"
  },
  {
    "id": "1603092006",
    "district_id": "160309",
    "label": "Batu Surau",
    "value": "Batu Surau"
  },
  {
    "id": "1603092007",
    "district_id": "160309",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1603092008",
    "district_id": "160309",
    "label": "Muara Tenang",
    "value": "Muara Tenang"
  },
  {
    "id": "1603092009",
    "district_id": "160309",
    "label": "Rekimai Jaya",
    "value": "Rekimai Jaya"
  },
  {
    "id": "1603092010",
    "district_id": "160309",
    "label": "Palak Tanah",
    "value": "Palak Tanah"
  },
  {
    "id": "1603092011",
    "district_id": "160309",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1603092012",
    "district_id": "160309",
    "label": "Swarna Dwipa",
    "value": "Swarna Dwipa"
  },
  {
    "id": "1603102001",
    "district_id": "160310",
    "label": "Tanjung Tiga",
    "value": "Tanjung Tiga"
  },
  {
    "id": "1603102002",
    "district_id": "160310",
    "label": "Cahaya Alam",
    "value": "Cahaya Alam"
  },
  {
    "id": "1603102003",
    "district_id": "160310",
    "label": "Datar Lebar",
    "value": "Datar Lebar"
  },
  {
    "id": "1603102004",
    "district_id": "160310",
    "label": "Segamit",
    "value": "Segamit"
  },
  {
    "id": "1603102005",
    "district_id": "160310",
    "label": "Siring Agung",
    "value": "Siring Agung"
  },
  {
    "id": "1603102006",
    "district_id": "160310",
    "label": "Aremantai",
    "value": "Aremantai"
  },
  {
    "id": "1603102007",
    "district_id": "160310",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1603102008",
    "district_id": "160310",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1603102009",
    "district_id": "160310",
    "label": "Pelakat",
    "value": "Pelakat"
  },
  {
    "id": "1603102010",
    "district_id": "160310",
    "label": "Danau Gerak",
    "value": "Danau Gerak"
  },
  {
    "id": "1603112001",
    "district_id": "160311",
    "label": "Pinang Belarik",
    "value": "Pinang Belarik"
  },
  {
    "id": "1603112002",
    "district_id": "160311",
    "label": "Ujan Mas Lama",
    "value": "Ujan Mas Lama"
  },
  {
    "id": "1603112003",
    "district_id": "160311",
    "label": "Ujan Mas Baru",
    "value": "Ujan Mas Baru"
  },
  {
    "id": "1603112004",
    "district_id": "160311",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1603112005",
    "district_id": "160311",
    "label": "Muara Gula Baru",
    "value": "Muara Gula Baru"
  },
  {
    "id": "1603112006",
    "district_id": "160311",
    "label": "Muara Gula Lama",
    "value": "Muara Gula Lama"
  },
  {
    "id": "1603112007",
    "district_id": "160311",
    "label": "Guci",
    "value": "Guci"
  },
  {
    "id": "1603112008",
    "district_id": "160311",
    "label": "Ulak Bandung",
    "value": "Ulak Bandung"
  },
  {
    "id": "1603142005",
    "district_id": "160314",
    "label": "Aur",
    "value": "Aur"
  },
  {
    "id": "1603142006",
    "district_id": "160314",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1603142007",
    "district_id": "160314",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1603142008",
    "district_id": "160314",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1603142009",
    "district_id": "160314",
    "label": "Gunung Raja",
    "value": "Gunung Raja"
  },
  {
    "id": "1603142010",
    "district_id": "160314",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1603142011",
    "district_id": "160314",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1603142014",
    "district_id": "160314",
    "label": "Jiwa Baru",
    "value": "Jiwa Baru"
  },
  {
    "id": "1603142020",
    "district_id": "160314",
    "label": "Air Asam",
    "value": "Air Asam"
  },
  {
    "id": "1603142021",
    "district_id": "160314",
    "label": "Menanti",
    "value": "Menanti"
  },
  {
    "id": "1603152001",
    "district_id": "160315",
    "label": "Baru Rambang",
    "value": "Baru Rambang"
  },
  {
    "id": "1603152002",
    "district_id": "160315",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "1603152003",
    "district_id": "160315",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1603152004",
    "district_id": "160315",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1603152005",
    "district_id": "160315",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1603152006",
    "district_id": "160315",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1603152007",
    "district_id": "160315",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1603152008",
    "district_id": "160315",
    "label": "Sumber Rahayu",
    "value": "Sumber Rahayu"
  },
  {
    "id": "1603152009",
    "district_id": "160315",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1603152010",
    "district_id": "160315",
    "label": "Kencana Mulia",
    "value": "Kencana Mulia"
  },
  {
    "id": "1603152011",
    "district_id": "160315",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1603152012",
    "district_id": "160315",
    "label": "Sugihwaras Barat",
    "value": "Sugihwaras Barat"
  },
  {
    "id": "1603152013",
    "district_id": "160315",
    "label": "Air Keruh",
    "value": "Air Keruh"
  },
  {
    "id": "1603162001",
    "district_id": "160316",
    "label": "Tanjung Miring",
    "value": "Tanjung Miring"
  },
  {
    "id": "1603162002",
    "district_id": "160316",
    "label": "Danau Tampang",
    "value": "Danau Tampang"
  },
  {
    "id": "1603162003",
    "district_id": "160316",
    "label": "Kasai",
    "value": "Kasai"
  },
  {
    "id": "1603162004",
    "district_id": "160316",
    "label": "Sungai Rotan",
    "value": "Sungai Rotan"
  },
  {
    "id": "1603162005",
    "district_id": "160316",
    "label": "Sukamerindu",
    "value": "Sukamerindu"
  },
  {
    "id": "1603162006",
    "district_id": "160316",
    "label": "Tanding Marga",
    "value": "Tanding Marga"
  },
  {
    "id": "1603162007",
    "district_id": "160316",
    "label": "Muara Lematang",
    "value": "Muara Lematang"
  },
  {
    "id": "1603162008",
    "district_id": "160316",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "1603162009",
    "district_id": "160316",
    "label": "Modong",
    "value": "Modong"
  },
  {
    "id": "1603162010",
    "district_id": "160316",
    "label": "Sukacinta",
    "value": "Sukacinta"
  },
  {
    "id": "1603162011",
    "district_id": "160316",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1603162012",
    "district_id": "160316",
    "label": "Danau Rata",
    "value": "Danau Rata"
  },
  {
    "id": "1603162013",
    "district_id": "160316",
    "label": "Paya Angus",
    "value": "Paya Angus"
  },
  {
    "id": "1603162014",
    "district_id": "160316",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1603162015",
    "district_id": "160316",
    "label": "Petar Dalam",
    "value": "Petar Dalam"
  },
  {
    "id": "1603162016",
    "district_id": "160316",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1603162017",
    "district_id": "160316",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1603162018",
    "district_id": "160316",
    "label": "Danau Baru",
    "value": "Danau Baru"
  },
  {
    "id": "1603162019",
    "district_id": "160316",
    "label": "Petar Luar",
    "value": "Petar Luar"
  },
  {
    "id": "1603172007",
    "district_id": "160317",
    "label": "Petanang",
    "value": "Petanang"
  },
  {
    "id": "1603172008",
    "district_id": "160317",
    "label": "Talang Nangka",
    "value": "Talang Nangka"
  },
  {
    "id": "1603172009",
    "district_id": "160317",
    "label": "Alai",
    "value": "Alai"
  },
  {
    "id": "1603172010",
    "district_id": "160317",
    "label": "Sungai Duren",
    "value": "Sungai Duren"
  },
  {
    "id": "1603172011",
    "district_id": "160317",
    "label": "Tapus",
    "value": "Tapus"
  },
  {
    "id": "1603172012",
    "district_id": "160317",
    "label": "Lembak",
    "value": "Lembak"
  },
  {
    "id": "1603172013",
    "district_id": "160317",
    "label": "Kemang",
    "value": "Kemang"
  },
  {
    "id": "1603172014",
    "district_id": "160317",
    "label": "Lubuk Enau",
    "value": "Lubuk Enau"
  },
  {
    "id": "1603172015",
    "district_id": "160317",
    "label": "Alai Selatan",
    "value": "Alai Selatan"
  },
  {
    "id": "1603172019",
    "district_id": "160317",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1603192001",
    "district_id": "160319",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1603192002",
    "district_id": "160319",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1603192003",
    "district_id": "160319",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1603192004",
    "district_id": "160319",
    "label": "Pagarjati",
    "value": "Pagarjati"
  },
  {
    "id": "1603192005",
    "district_id": "160319",
    "label": "Hidup Baru",
    "value": "Hidup Baru"
  },
  {
    "id": "1603192006",
    "district_id": "160319",
    "label": "Rami Pasai",
    "value": "Rami Pasai"
  },
  {
    "id": "1603212001",
    "district_id": "160321",
    "label": "Embacang Kelekar",
    "value": "Embacang Kelekar"
  },
  {
    "id": "1603212002",
    "district_id": "160321",
    "label": "Menanti",
    "value": "Menanti"
  },
  {
    "id": "1603212003",
    "district_id": "160321",
    "label": "Menanti Selatan",
    "value": "Menanti Selatan"
  },
  {
    "id": "1603212004",
    "district_id": "160321",
    "label": "Suban Baru",
    "value": "Suban Baru"
  },
  {
    "id": "1603212005",
    "district_id": "160321",
    "label": "Pelempang",
    "value": "Pelempang"
  },
  {
    "id": "1603212006",
    "district_id": "160321",
    "label": "Teluk Jaya",
    "value": "Teluk Jaya"
  },
  {
    "id": "1603212007",
    "district_id": "160321",
    "label": "Tanjung Medang",
    "value": "Tanjung Medang"
  },
  {
    "id": "1603222001",
    "district_id": "160322",
    "label": "Gedung Buruk",
    "value": "Gedung Buruk"
  },
  {
    "id": "1603222002",
    "district_id": "160322",
    "label": "Arisan Musi",
    "value": "Arisan Musi"
  },
  {
    "id": "1603222003",
    "district_id": "160322",
    "label": "Harapan Mulia",
    "value": "Harapan Mulia"
  },
  {
    "id": "1603222004",
    "district_id": "160322",
    "label": "Mulia Abadi",
    "value": "Mulia Abadi"
  },
  {
    "id": "1603222005",
    "district_id": "160322",
    "label": "Arisan Musi Timur",
    "value": "Arisan Musi Timur"
  },
  {
    "id": "1603222006",
    "district_id": "160322",
    "label": "Patra Tani",
    "value": "Patra Tani"
  },
  {
    "id": "1603222007",
    "district_id": "160322",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1603222008",
    "district_id": "160322",
    "label": "Kayu Ara Batu",
    "value": "Kayu Ara Batu"
  },
  {
    "id": "1603232001",
    "district_id": "160323",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1603232002",
    "district_id": "160323",
    "label": "Simpang Tanjung",
    "value": "Simpang Tanjung"
  },
  {
    "id": "1603232003",
    "district_id": "160323",
    "label": "Cinta Kasih",
    "value": "Cinta Kasih"
  },
  {
    "id": "1603232004",
    "district_id": "160323",
    "label": "Teluk Lubuk",
    "value": "Teluk Lubuk"
  },
  {
    "id": "1603232005",
    "district_id": "160323",
    "label": "Berugo",
    "value": "Berugo"
  },
  {
    "id": "1603232006",
    "district_id": "160323",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "1603232007",
    "district_id": "160323",
    "label": "Bulang",
    "value": "Bulang"
  },
  {
    "id": "1603232008",
    "district_id": "160323",
    "label": "Belimbing Jaya",
    "value": "Belimbing Jaya"
  },
  {
    "id": "1603232009",
    "district_id": "160323",
    "label": "Darmo Kasih",
    "value": "Darmo Kasih"
  },
  {
    "id": "1603232010",
    "district_id": "160323",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "1603242001",
    "district_id": "160324",
    "label": "Tanjung Bunut",
    "value": "Tanjung Bunut"
  },
  {
    "id": "1603242002",
    "district_id": "160324",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "1603242003",
    "district_id": "160324",
    "label": "Lubuk Semantung",
    "value": "Lubuk Semantung"
  },
  {
    "id": "1603242004",
    "district_id": "160324",
    "label": "Lubuk Getam",
    "value": "Lubuk Getam"
  },
  {
    "id": "1603242005",
    "district_id": "160324",
    "label": "Talang Balai",
    "value": "Talang Balai"
  },
  {
    "id": "1603242006",
    "district_id": "160324",
    "label": "Talang Beliung",
    "value": "Talang Beliung"
  },
  {
    "id": "1603242007",
    "district_id": "160324",
    "label": "Sialingan",
    "value": "Sialingan"
  },
  {
    "id": "1603242008",
    "district_id": "160324",
    "label": "Tanjung Tiga",
    "value": "Tanjung Tiga"
  },
  {
    "id": "1603242009",
    "district_id": "160324",
    "label": "Ibul",
    "value": "Ibul"
  },
  {
    "id": "1603242010",
    "district_id": "160324",
    "label": "Gaung Asam",
    "value": "Gaung Asam"
  },
  {
    "id": "1603252001",
    "district_id": "160325",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1603252002",
    "district_id": "160325",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1603252003",
    "district_id": "160325",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "1603252004",
    "district_id": "160325",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1603252005",
    "district_id": "160325",
    "label": "Sumber Asri",
    "value": "Sumber Asri"
  },
  {
    "id": "1603252006",
    "district_id": "160325",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1603252007",
    "district_id": "160325",
    "label": "Prabumenang",
    "value": "Prabumenang"
  },
  {
    "id": "1603252008",
    "district_id": "160325",
    "label": "Lecah",
    "value": "Lecah"
  },
  {
    "id": "1603252009",
    "district_id": "160325",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1603252010",
    "district_id": "160325",
    "label": "Lubai Persada",
    "value": "Lubai Persada"
  },
  {
    "id": "1603252011",
    "district_id": "160325",
    "label": "Lubai Makmur",
    "value": "Lubai Makmur"
  },
  {
    "id": "1603262001",
    "district_id": "160326",
    "label": "Muara Niru",
    "value": "Muara Niru"
  },
  {
    "id": "1603262002",
    "district_id": "160326",
    "label": "Kahuripan Baru",
    "value": "Kahuripan Baru"
  },
  {
    "id": "1603262003",
    "district_id": "160326",
    "label": "Pangkalan Babat",
    "value": "Pangkalan Babat"
  },
  {
    "id": "1603262004",
    "district_id": "160326",
    "label": "Gunung Raja",
    "value": "Gunung Raja"
  },
  {
    "id": "1603262005",
    "district_id": "160326",
    "label": "Banuayu",
    "value": "Banuayu"
  },
  {
    "id": "1603262006",
    "district_id": "160326",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1603262007",
    "district_id": "160326",
    "label": "Kuripan Selatan",
    "value": "Kuripan Selatan"
  },
  {
    "id": "1603262008",
    "district_id": "160326",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "1603262009",
    "district_id": "160326",
    "label": "Dangku",
    "value": "Dangku"
  },
  {
    "id": "1603262010",
    "district_id": "160326",
    "label": "Siku",
    "value": "Siku"
  },
  {
    "id": "1603272001",
    "district_id": "160327",
    "label": "Lebak Budi",
    "value": "Lebak Budi"
  },
  {
    "id": "1603272002",
    "district_id": "160327",
    "label": "Lambur",
    "value": "Lambur"
  },
  {
    "id": "1603272003",
    "district_id": "160327",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1603272004",
    "district_id": "160327",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1603272005",
    "district_id": "160327",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1603272006",
    "district_id": "160327",
    "label": "Pandan Dulang",
    "value": "Pandan Dulang"
  },
  {
    "id": "1603272007",
    "district_id": "160327",
    "label": "Muara Meo",
    "value": "Muara Meo"
  },
  {
    "id": "1603272008",
    "district_id": "160327",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1603272009",
    "district_id": "160327",
    "label": "Indramayu",
    "value": "Indramayu"
  },
  {
    "id": "1603272010",
    "district_id": "160327",
    "label": "Bedegung",
    "value": "Bedegung"
  },
  {
    "id": "1603272011",
    "district_id": "160327",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1603272012",
    "district_id": "160327",
    "label": "Lubuk Nipis",
    "value": "Lubuk Nipis"
  },
  {
    "id": "1604012001",
    "district_id": "160401",
    "label": "Kembang Ayun",
    "value": "Kembang Ayun"
  },
  {
    "id": "1604012002",
    "district_id": "160401",
    "label": "Gunung Meraksa",
    "value": "Gunung Meraksa"
  },
  {
    "id": "1604012003",
    "district_id": "160401",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1604012004",
    "district_id": "160401",
    "label": "Batu Rancing",
    "value": "Batu Rancing"
  },
  {
    "id": "1604012005",
    "district_id": "160401",
    "label": "Suban",
    "value": "Suban"
  },
  {
    "id": "1604012006",
    "district_id": "160401",
    "label": "Genting",
    "value": "Genting"
  },
  {
    "id": "1604012007",
    "district_id": "160401",
    "label": "Gunung Ayu",
    "value": "Gunung Ayu"
  },
  {
    "id": "1604012008",
    "district_id": "160401",
    "label": "Ujung Pulau",
    "value": "Ujung Pulau"
  },
  {
    "id": "1604012009",
    "district_id": "160401",
    "label": "Muara Cawang",
    "value": "Muara Cawang"
  },
  {
    "id": "1604012010",
    "district_id": "160401",
    "label": "Kepala Siring",
    "value": "Kepala Siring"
  },
  {
    "id": "1604012011",
    "district_id": "160401",
    "label": "Simpang III Pumu",
    "value": "Simpang III Pumu"
  },
  {
    "id": "1604012012",
    "district_id": "160401",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "1604012013",
    "district_id": "160401",
    "label": "Talang Tinggi",
    "value": "Talang Tinggi"
  },
  {
    "id": "1604012014",
    "district_id": "160401",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1604062008",
    "district_id": "160406",
    "label": "Sadan",
    "value": "Sadan"
  },
  {
    "id": "1604062009",
    "district_id": "160406",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1604062010",
    "district_id": "160406",
    "label": "Bandar Aji",
    "value": "Bandar Aji"
  },
  {
    "id": "1604062011",
    "district_id": "160406",
    "label": "Tertap",
    "value": "Tertap"
  },
  {
    "id": "1604062012",
    "district_id": "160406",
    "label": "Serambi",
    "value": "Serambi"
  },
  {
    "id": "1604062013",
    "district_id": "160406",
    "label": "Muara Tawi",
    "value": "Muara Tawi"
  },
  {
    "id": "1604062014",
    "district_id": "160406",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1604062015",
    "district_id": "160406",
    "label": "Pelajaran",
    "value": "Pelajaran"
  },
  {
    "id": "1604062016",
    "district_id": "160406",
    "label": "Nanti Giri",
    "value": "Nanti Giri"
  },
  {
    "id": "1604062017",
    "district_id": "160406",
    "label": "Pama Salak",
    "value": "Pama Salak"
  },
  {
    "id": "1604062018",
    "district_id": "160406",
    "label": "Jemaring",
    "value": "Jemaring"
  },
  {
    "id": "1604062019",
    "district_id": "160406",
    "label": "Aromantai",
    "value": "Aromantai"
  },
  {
    "id": "1604062020",
    "district_id": "160406",
    "label": "Jarai",
    "value": "Jarai"
  },
  {
    "id": "1604062021",
    "district_id": "160406",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1604062022",
    "district_id": "160406",
    "label": "Mangun Sari",
    "value": "Mangun Sari"
  },
  {
    "id": "1604062023",
    "district_id": "160406",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1604062024",
    "district_id": "160406",
    "label": "Sukananti",
    "value": "Sukananti"
  },
  {
    "id": "1604062025",
    "district_id": "160406",
    "label": "Lubuk Saung",
    "value": "Lubuk Saung"
  },
  {
    "id": "1604062026",
    "district_id": "160406",
    "label": "Karang Tanding",
    "value": "Karang Tanding"
  },
  {
    "id": "1604062027",
    "district_id": "160406",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "1604062028",
    "district_id": "160406",
    "label": "Gunung Megang",
    "value": "Gunung Megang"
  },
  {
    "id": "1604072001",
    "district_id": "160407",
    "label": "Bangke",
    "value": "Bangke"
  },
  {
    "id": "1604072002",
    "district_id": "160407",
    "label": "Tebat Langsat",
    "value": "Tebat Langsat"
  },
  {
    "id": "1604072003",
    "district_id": "160407",
    "label": "Singapure",
    "value": "Singapure"
  },
  {
    "id": "1604072004",
    "district_id": "160407",
    "label": "Gunung Liwat",
    "value": "Gunung Liwat"
  },
  {
    "id": "1604072005",
    "district_id": "160407",
    "label": "Kebun Jati",
    "value": "Kebun Jati"
  },
  {
    "id": "1604072006",
    "district_id": "160407",
    "label": "Bintuhan",
    "value": "Bintuhan"
  },
  {
    "id": "1604072007",
    "district_id": "160407",
    "label": "Mutar Alam Baru",
    "value": "Mutar Alam Baru"
  },
  {
    "id": "1604072008",
    "district_id": "160407",
    "label": "Pandan Arang Ulu",
    "value": "Pandan Arang Ulu"
  },
  {
    "id": "1604072009",
    "district_id": "160407",
    "label": "Mutar Alam Lama",
    "value": "Mutar Alam Lama"
  },
  {
    "id": "1604072010",
    "district_id": "160407",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1604072011",
    "district_id": "160407",
    "label": "Tunggul Bute",
    "value": "Tunggul Bute"
  },
  {
    "id": "1604072012",
    "district_id": "160407",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1604072013",
    "district_id": "160407",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1604072014",
    "district_id": "160407",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1604072015",
    "district_id": "160407",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1604072016",
    "district_id": "160407",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1604072017",
    "district_id": "160407",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1604072018",
    "district_id": "160407",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1604072019",
    "district_id": "160407",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1604072020",
    "district_id": "160407",
    "label": "Pagaruyung",
    "value": "Pagaruyung"
  },
  {
    "id": "1604072031",
    "district_id": "160407",
    "label": "Muara Gula",
    "value": "Muara Gula"
  },
  {
    "id": "1604072032",
    "district_id": "160407",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1604082008",
    "district_id": "160408",
    "label": "Tanjung Mulak",
    "value": "Tanjung Mulak"
  },
  {
    "id": "1604082009",
    "district_id": "160408",
    "label": "Pulau Pinang",
    "value": "Pulau Pinang"
  },
  {
    "id": "1604082010",
    "district_id": "160408",
    "label": "Tanjung Sirih",
    "value": "Tanjung Sirih"
  },
  {
    "id": "1604082011",
    "district_id": "160408",
    "label": "Lubuk Sepang",
    "value": "Lubuk Sepang"
  },
  {
    "id": "1604082012",
    "district_id": "160408",
    "label": "Karang Dalam",
    "value": "Karang Dalam"
  },
  {
    "id": "1604082013",
    "district_id": "160408",
    "label": "Kuba",
    "value": "Kuba"
  },
  {
    "id": "1604082014",
    "district_id": "160408",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "1604082016",
    "district_id": "160408",
    "label": "Pagar Batu",
    "value": "Pagar Batu"
  },
  {
    "id": "1604082017",
    "district_id": "160408",
    "label": "Muara Siban",
    "value": "Muara Siban"
  },
  {
    "id": "1604082018",
    "district_id": "160408",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "1604092010",
    "district_id": "160409",
    "label": "Suka Cinta",
    "value": "Suka Cinta"
  },
  {
    "id": "1604092011",
    "district_id": "160409",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1604092012",
    "district_id": "160409",
    "label": "Tanjung Pinang",
    "value": "Tanjung Pinang"
  },
  {
    "id": "1604092013",
    "district_id": "160409",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1604092014",
    "district_id": "160409",
    "label": "Payo",
    "value": "Payo"
  },
  {
    "id": "1604092015",
    "district_id": "160409",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1604092016",
    "district_id": "160409",
    "label": "Tanjung Telang",
    "value": "Tanjung Telang"
  },
  {
    "id": "1604092017",
    "district_id": "160409",
    "label": "Lubuk Kepayang",
    "value": "Lubuk Kepayang"
  },
  {
    "id": "1604092018",
    "district_id": "160409",
    "label": "Muara Temiang",
    "value": "Muara Temiang"
  },
  {
    "id": "1604092019",
    "district_id": "160409",
    "label": "Ulak Pandan",
    "value": "Ulak Pandan"
  },
  {
    "id": "1604092020",
    "district_id": "160409",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1604092021",
    "district_id": "160409",
    "label": "Lebak Budi",
    "value": "Lebak Budi"
  },
  {
    "id": "1604092022",
    "district_id": "160409",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1604092023",
    "district_id": "160409",
    "label": "Kebur",
    "value": "Kebur"
  },
  {
    "id": "1604092024",
    "district_id": "160409",
    "label": "Telatang",
    "value": "Telatang"
  },
  {
    "id": "1604092025",
    "district_id": "160409",
    "label": "Muara Maung",
    "value": "Muara Maung"
  },
  {
    "id": "1604092026",
    "district_id": "160409",
    "label": "Merapi",
    "value": "Merapi"
  },
  {
    "id": "1604092040",
    "district_id": "160409",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1604092041",
    "district_id": "160409",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1604101031",
    "district_id": "160410",
    "label": "Lahat Tengah",
    "value": "Lahat Tengah"
  },
  {
    "id": "1604101032",
    "district_id": "160410",
    "label": "Gunung Gajah",
    "value": "Gunung Gajah"
  },
  {
    "id": "1604101033",
    "district_id": "160410",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1604101037",
    "district_id": "160410",
    "label": "Talang Jawa",
    "value": "Talang Jawa"
  },
  {
    "id": "1604101038",
    "district_id": "160410",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1604101040",
    "district_id": "160410",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1604101041",
    "district_id": "160410",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "1604101042",
    "district_id": "160410",
    "label": "Pasar Bawah",
    "value": "Pasar Bawah"
  },
  {
    "id": "1604101043",
    "district_id": "160410",
    "label": "RD. PJKA",
    "value": "RD. PJKA"
  },
  {
    "id": "1604101046",
    "district_id": "160410",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1604101051",
    "district_id": "160410",
    "label": "Kota Negara",
    "value": "Kota Negara"
  },
  {
    "id": "1604101052",
    "district_id": "160410",
    "label": "Kota Jaya",
    "value": "Kota Jaya"
  },
  {
    "id": "1604101054",
    "district_id": "160410",
    "label": "RD. PJKA Bandar Agung",
    "value": "RD. PJKA Bandar Agung"
  },
  {
    "id": "1604101058",
    "district_id": "160410",
    "label": "Sari Bunga Mas",
    "value": "Sari Bunga Mas"
  },
  {
    "id": "1604101059",
    "district_id": "160410",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1604101060",
    "district_id": "160410",
    "label": "Talang Jawa Selatan",
    "value": "Talang Jawa Selatan"
  },
  {
    "id": "1604101061",
    "district_id": "160410",
    "label": "Talang Jawa Utara",
    "value": "Talang Jawa Utara"
  },
  {
    "id": "1604102012",
    "district_id": "160410",
    "label": "Padang Lengkuas",
    "value": "Padang Lengkuas"
  },
  {
    "id": "1604102027",
    "district_id": "160410",
    "label": "Selawi",
    "value": "Selawi"
  },
  {
    "id": "1604102030",
    "district_id": "160410",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "1604102034",
    "district_id": "160410",
    "label": "Kota Raya",
    "value": "Kota Raya"
  },
  {
    "id": "1604102035",
    "district_id": "160410",
    "label": "Keban",
    "value": "Keban"
  },
  {
    "id": "1604102036",
    "district_id": "160410",
    "label": "Pagar Sari",
    "value": "Pagar Sari"
  },
  {
    "id": "1604102039",
    "district_id": "160410",
    "label": "Pagar Negara",
    "value": "Pagar Negara"
  },
  {
    "id": "1604102049",
    "district_id": "160410",
    "label": "Senabing",
    "value": "Senabing"
  },
  {
    "id": "1604102050",
    "district_id": "160410",
    "label": "Manggul",
    "value": "Manggul"
  },
  {
    "id": "1604102053",
    "district_id": "160410",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1604102055",
    "district_id": "160410",
    "label": "Giri Mulya",
    "value": "Giri Mulya"
  },
  {
    "id": "1604102056",
    "district_id": "160410",
    "label": "Makarti Tama",
    "value": "Makarti Tama"
  },
  {
    "id": "1604102057",
    "district_id": "160410",
    "label": "Ulak Mas",
    "value": "Ulak Mas"
  },
  {
    "id": "1604122001",
    "district_id": "160412",
    "label": "Talang Baru",
    "value": "Talang Baru"
  },
  {
    "id": "1604122002",
    "district_id": "160412",
    "label": "Talang Pagar Agung",
    "value": "Talang Pagar Agung"
  },
  {
    "id": "1604122003",
    "district_id": "160412",
    "label": "Bantunan",
    "value": "Bantunan"
  },
  {
    "id": "1604122004",
    "district_id": "160412",
    "label": "Suka Bumi",
    "value": "Suka Bumi"
  },
  {
    "id": "1604122005",
    "district_id": "160412",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1604122006",
    "district_id": "160412",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1604122007",
    "district_id": "160412",
    "label": "Kota Raya Lembak",
    "value": "Kota Raya Lembak"
  },
  {
    "id": "1604122008",
    "district_id": "160412",
    "label": "Sumur",
    "value": "Sumur"
  },
  {
    "id": "1604122009",
    "district_id": "160412",
    "label": "Gelung Sakti",
    "value": "Gelung Sakti"
  },
  {
    "id": "1604122010",
    "district_id": "160412",
    "label": "Pulau",
    "value": "Pulau"
  },
  {
    "id": "1604122011",
    "district_id": "160412",
    "label": "Ulak Bandung",
    "value": "Ulak Bandung"
  },
  {
    "id": "1604122012",
    "district_id": "160412",
    "label": "Talang Padang Tinggi",
    "value": "Talang Padang Tinggi"
  },
  {
    "id": "1604122019",
    "district_id": "160412",
    "label": "Aceh",
    "value": "Aceh"
  },
  {
    "id": "1604122020",
    "district_id": "160412",
    "label": "Pajar Tinggi",
    "value": "Pajar Tinggi"
  },
  {
    "id": "1604122025",
    "district_id": "160412",
    "label": "Kota Raya Darat",
    "value": "Kota Raya Darat"
  },
  {
    "id": "1604122026",
    "district_id": "160412",
    "label": "Jenti'an",
    "value": "Jenti'an"
  },
  {
    "id": "1604122027",
    "district_id": "160412",
    "label": "Tongkok",
    "value": "Tongkok"
  },
  {
    "id": "1604122028",
    "district_id": "160412",
    "label": "Benua Raja",
    "value": "Benua Raja"
  },
  {
    "id": "1604122029",
    "district_id": "160412",
    "label": "Talang Mengkenang",
    "value": "Talang Mengkenang"
  },
  {
    "id": "1604122030",
    "district_id": "160412",
    "label": "Talang Tangsi",
    "value": "Talang Tangsi"
  },
  {
    "id": "1604152007",
    "district_id": "160415",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1604152008",
    "district_id": "160415",
    "label": "Sukananti",
    "value": "Sukananti"
  },
  {
    "id": "1604152009",
    "district_id": "160415",
    "label": "Karang Lebak",
    "value": "Karang Lebak"
  },
  {
    "id": "1604152011",
    "district_id": "160415",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "1604152014",
    "district_id": "160415",
    "label": "Muara Tiga",
    "value": "Muara Tiga"
  },
  {
    "id": "1604152015",
    "district_id": "160415",
    "label": "Sengkuang",
    "value": "Sengkuang"
  },
  {
    "id": "1604152016",
    "district_id": "160415",
    "label": "Penindayan",
    "value": "Penindayan"
  },
  {
    "id": "1604152017",
    "district_id": "160415",
    "label": "Datar Balam",
    "value": "Datar Balam"
  },
  {
    "id": "1604152018",
    "district_id": "160415",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1604152019",
    "district_id": "160415",
    "label": "Geramat",
    "value": "Geramat"
  },
  {
    "id": "1604152020",
    "district_id": "160415",
    "label": "Pengentaan",
    "value": "Pengentaan"
  },
  {
    "id": "1604152021",
    "district_id": "160415",
    "label": "Lesung Batu",
    "value": "Lesung Batu"
  },
  {
    "id": "1604152022",
    "district_id": "160415",
    "label": "Air Puar",
    "value": "Air Puar"
  },
  {
    "id": "1604152024",
    "district_id": "160415",
    "label": "Mengkenang",
    "value": "Mengkenang"
  },
  {
    "id": "1604152029",
    "district_id": "160415",
    "label": "Lawang Agung Mulak",
    "value": "Lawang Agung Mulak"
  },
  {
    "id": "1604152030",
    "district_id": "160415",
    "label": "Padang Masat",
    "value": "Padang Masat"
  },
  {
    "id": "1604162001",
    "district_id": "160416",
    "label": "Pandan Arang",
    "value": "Pandan Arang"
  },
  {
    "id": "1604162002",
    "district_id": "160416",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1604162003",
    "district_id": "160416",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1604162004",
    "district_id": "160416",
    "label": "Pagardin",
    "value": "Pagardin"
  },
  {
    "id": "1604162005",
    "district_id": "160416",
    "label": "Pulau Beringin",
    "value": "Pulau Beringin"
  },
  {
    "id": "1604162006",
    "district_id": "160416",
    "label": "Beriningin Jaya",
    "value": "Beriningin Jaya"
  },
  {
    "id": "1604162007",
    "district_id": "160416",
    "label": "Banu Ayu",
    "value": "Banu Ayu"
  },
  {
    "id": "1604162008",
    "district_id": "160416",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1604162009",
    "district_id": "160416",
    "label": "Sirah Pulau",
    "value": "Sirah Pulau"
  },
  {
    "id": "1604162010",
    "district_id": "160416",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1604162011",
    "district_id": "160416",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1604162012",
    "district_id": "160416",
    "label": "Karang Cahaya",
    "value": "Karang Cahaya"
  },
  {
    "id": "1604162013",
    "district_id": "160416",
    "label": "Jaga Baya",
    "value": "Jaga Baya"
  },
  {
    "id": "1604162014",
    "district_id": "160416",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1604162015",
    "district_id": "160416",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1604162016",
    "district_id": "160416",
    "label": "Beringin Janggut",
    "value": "Beringin Janggut"
  },
  {
    "id": "1604162017",
    "district_id": "160416",
    "label": "Keban Raya",
    "value": "Keban Raya"
  },
  {
    "id": "1604162018",
    "district_id": "160416",
    "label": "Lubuk Tungkang",
    "value": "Lubuk Tungkang"
  },
  {
    "id": "1604172001",
    "district_id": "160417",
    "label": "Bunga Mas",
    "value": "Bunga Mas"
  },
  {
    "id": "1604172002",
    "district_id": "160417",
    "label": "Gunung Kembang",
    "value": "Gunung Kembang"
  },
  {
    "id": "1604172003",
    "district_id": "160417",
    "label": "Tanjung Bindu",
    "value": "Tanjung Bindu"
  },
  {
    "id": "1604172004",
    "district_id": "160417",
    "label": "Lubuk Tapang",
    "value": "Lubuk Tapang"
  },
  {
    "id": "1604172005",
    "district_id": "160417",
    "label": "Lubuk Nambulan",
    "value": "Lubuk Nambulan"
  },
  {
    "id": "1604172006",
    "district_id": "160417",
    "label": "Gelumbang",
    "value": "Gelumbang"
  },
  {
    "id": "1604172007",
    "district_id": "160417",
    "label": "Gunung Aji",
    "value": "Gunung Aji"
  },
  {
    "id": "1604172008",
    "district_id": "160417",
    "label": "Gunung Karto",
    "value": "Gunung Karto"
  },
  {
    "id": "1604172009",
    "district_id": "160417",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1604172010",
    "district_id": "160417",
    "label": "Padu Raksa",
    "value": "Padu Raksa"
  },
  {
    "id": "1604172011",
    "district_id": "160417",
    "label": "Seronggo",
    "value": "Seronggo"
  },
  {
    "id": "1604172012",
    "district_id": "160417",
    "label": "Datar Serdang",
    "value": "Datar Serdang"
  },
  {
    "id": "1604172013",
    "district_id": "160417",
    "label": "Batu Urip",
    "value": "Batu Urip"
  },
  {
    "id": "1604172014",
    "district_id": "160417",
    "label": "Cecar",
    "value": "Cecar"
  },
  {
    "id": "1604172015",
    "district_id": "160417",
    "label": "Patikal Lama",
    "value": "Patikal Lama"
  },
  {
    "id": "1604172016",
    "district_id": "160417",
    "label": "Muara Empayang",
    "value": "Muara Empayang"
  },
  {
    "id": "1604172017",
    "district_id": "160417",
    "label": "Lubuk Kute",
    "value": "Lubuk Kute"
  },
  {
    "id": "1604172018",
    "district_id": "160417",
    "label": "Patikal Baru",
    "value": "Patikal Baru"
  },
  {
    "id": "1604172019",
    "district_id": "160417",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1604172020",
    "district_id": "160417",
    "label": "Babat Lama",
    "value": "Babat Lama"
  },
  {
    "id": "1604172021",
    "district_id": "160417",
    "label": "Tanda Raja",
    "value": "Tanda Raja"
  },
  {
    "id": "1604172022",
    "district_id": "160417",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1604172023",
    "district_id": "160417",
    "label": "Sendawar",
    "value": "Sendawar"
  },
  {
    "id": "1604172024",
    "district_id": "160417",
    "label": "Lubuk Layang Ilir",
    "value": "Lubuk Layang Ilir"
  },
  {
    "id": "1604172025",
    "district_id": "160417",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1604172026",
    "district_id": "160417",
    "label": "Lubuk Layang Ulu",
    "value": "Lubuk Layang Ulu"
  },
  {
    "id": "1604172027",
    "district_id": "160417",
    "label": "Cempaka Sakti",
    "value": "Cempaka Sakti"
  },
  {
    "id": "1604172028",
    "district_id": "160417",
    "label": "Suka Harjo",
    "value": "Suka Harjo"
  },
  {
    "id": "1604172029",
    "district_id": "160417",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1604172030",
    "district_id": "160417",
    "label": "Kencana Sari",
    "value": "Kencana Sari"
  },
  {
    "id": "1604172031",
    "district_id": "160417",
    "label": "Linggar Jaya",
    "value": "Linggar Jaya"
  },
  {
    "id": "1604172032",
    "district_id": "160417",
    "label": "Purwaraja",
    "value": "Purwaraja"
  },
  {
    "id": "1604182001",
    "district_id": "160418",
    "label": "Sungai Laru",
    "value": "Sungai Laru"
  },
  {
    "id": "1604182002",
    "district_id": "160418",
    "label": "Muara Lingsing",
    "value": "Muara Lingsing"
  },
  {
    "id": "1604182003",
    "district_id": "160418",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1604182004",
    "district_id": "160418",
    "label": "Kepala Siring",
    "value": "Kepala Siring"
  },
  {
    "id": "1604182005",
    "district_id": "160418",
    "label": "Maspura",
    "value": "Maspura"
  },
  {
    "id": "1604182006",
    "district_id": "160418",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1604182007",
    "district_id": "160418",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1604182008",
    "district_id": "160418",
    "label": "Purbamas",
    "value": "Purbamas"
  },
  {
    "id": "1604182009",
    "district_id": "160418",
    "label": "Banyu Mas",
    "value": "Banyu Mas"
  },
  {
    "id": "1604192001",
    "district_id": "160419",
    "label": "Saung Naga",
    "value": "Saung Naga"
  },
  {
    "id": "1604192002",
    "district_id": "160419",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1604192003",
    "district_id": "160419",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1604192004",
    "district_id": "160419",
    "label": "Jajaran Baru",
    "value": "Jajaran Baru"
  },
  {
    "id": "1604192005",
    "district_id": "160419",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "1604192006",
    "district_id": "160419",
    "label": "Babatan Baru",
    "value": "Babatan Baru"
  },
  {
    "id": "1604192007",
    "district_id": "160419",
    "label": "Jajaran Lama",
    "value": "Jajaran Lama"
  },
  {
    "id": "1604192008",
    "district_id": "160419",
    "label": "Lubuk Seketi",
    "value": "Lubuk Seketi"
  },
  {
    "id": "1604192009",
    "district_id": "160419",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1604192010",
    "district_id": "160419",
    "label": "Singapura",
    "value": "Singapura"
  },
  {
    "id": "1604192011",
    "district_id": "160419",
    "label": "Ulak Bandung",
    "value": "Ulak Bandung"
  },
  {
    "id": "1604192012",
    "district_id": "160419",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1604192013",
    "district_id": "160419",
    "label": "Purnama Sari",
    "value": "Purnama Sari"
  },
  {
    "id": "1604192014",
    "district_id": "160419",
    "label": "Wanaraya",
    "value": "Wanaraya"
  },
  {
    "id": "1604192015",
    "district_id": "160419",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1604192016",
    "district_id": "160419",
    "label": "Suka Bakti",
    "value": "Suka Bakti"
  },
  {
    "id": "1604192017",
    "district_id": "160419",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1604192018",
    "district_id": "160419",
    "label": "Darma Raharja",
    "value": "Darma Raharja"
  },
  {
    "id": "1604192019",
    "district_id": "160419",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1604202001",
    "district_id": "160420",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1604202002",
    "district_id": "160420",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1604202003",
    "district_id": "160420",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1604202004",
    "district_id": "160420",
    "label": "Talang Tinggi",
    "value": "Talang Tinggi"
  },
  {
    "id": "1604202005",
    "district_id": "160420",
    "label": "Batu Niding",
    "value": "Batu Niding"
  },
  {
    "id": "1604202006",
    "district_id": "160420",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1604202007",
    "district_id": "160420",
    "label": "Lubuk Mabar",
    "value": "Lubuk Mabar"
  },
  {
    "id": "1604202008",
    "district_id": "160420",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1604202009",
    "district_id": "160420",
    "label": "Lubuk Tuba",
    "value": "Lubuk Tuba"
  },
  {
    "id": "1604202010",
    "district_id": "160420",
    "label": "Lubuk Atung",
    "value": "Lubuk Atung"
  },
  {
    "id": "1604202011",
    "district_id": "160420",
    "label": "Muara Cawang",
    "value": "Muara Cawang"
  },
  {
    "id": "1604212001",
    "district_id": "160421",
    "label": "Sugi Waras",
    "value": "Sugi Waras"
  },
  {
    "id": "1604212002",
    "district_id": "160421",
    "label": "Tanjung Periuk",
    "value": "Tanjung Periuk"
  },
  {
    "id": "1604212003",
    "district_id": "160421",
    "label": "Tanjung Karangan",
    "value": "Tanjung Karangan"
  },
  {
    "id": "1604212004",
    "district_id": "160421",
    "label": "Muara Tandi",
    "value": "Muara Tandi"
  },
  {
    "id": "1604212005",
    "district_id": "160421",
    "label": "Darmo",
    "value": "Darmo"
  },
  {
    "id": "1604212006",
    "district_id": "160421",
    "label": "Indikat Ilir",
    "value": "Indikat Ilir"
  },
  {
    "id": "1604212007",
    "district_id": "160421",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1604212008",
    "district_id": "160421",
    "label": "Mandi Angin",
    "value": "Mandi Angin"
  },
  {
    "id": "1604212009",
    "district_id": "160421",
    "label": "Ngalam Baru",
    "value": "Ngalam Baru"
  },
  {
    "id": "1604212010",
    "district_id": "160421",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1604212011",
    "district_id": "160421",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1604212012",
    "district_id": "160421",
    "label": "Tanah Pilih",
    "value": "Tanah Pilih"
  },
  {
    "id": "1604212013",
    "district_id": "160421",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1604212014",
    "district_id": "160421",
    "label": "Batay",
    "value": "Batay"
  },
  {
    "id": "1604212015",
    "district_id": "160421",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1604222001",
    "district_id": "160422",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1604222002",
    "district_id": "160422",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1604222003",
    "district_id": "160422",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1604222004",
    "district_id": "160422",
    "label": "Bandung Agung",
    "value": "Bandung Agung"
  },
  {
    "id": "1604222005",
    "district_id": "160422",
    "label": "Lesung Batu",
    "value": "Lesung Batu"
  },
  {
    "id": "1604222006",
    "district_id": "160422",
    "label": "Air Lingkar",
    "value": "Air Lingkar"
  },
  {
    "id": "1604222007",
    "district_id": "160422",
    "label": "Batu Rusa",
    "value": "Batu Rusa"
  },
  {
    "id": "1604222008",
    "district_id": "160422",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "1604222009",
    "district_id": "160422",
    "label": "Sawah Darat",
    "value": "Sawah Darat"
  },
  {
    "id": "1604222010",
    "district_id": "160422",
    "label": "Danau",
    "value": "Danau"
  },
  {
    "id": "1604222011",
    "district_id": "160422",
    "label": "Siring Agung",
    "value": "Siring Agung"
  },
  {
    "id": "1604222012",
    "district_id": "160422",
    "label": "Pagar Alam",
    "value": "Pagar Alam"
  },
  {
    "id": "1604222013",
    "district_id": "160422",
    "label": "Rimba Sujud",
    "value": "Rimba Sujud"
  },
  {
    "id": "1604222014",
    "district_id": "160422",
    "label": "Germidar Ilir",
    "value": "Germidar Ilir"
  },
  {
    "id": "1604222015",
    "district_id": "160422",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1604222016",
    "district_id": "160422",
    "label": "Germidar Ulu",
    "value": "Germidar Ulu"
  },
  {
    "id": "1604222017",
    "district_id": "160422",
    "label": "Padang Pagun",
    "value": "Padang Pagun"
  },
  {
    "id": "1604222018",
    "district_id": "160422",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1604222019",
    "district_id": "160422",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1604222020",
    "district_id": "160422",
    "label": "Merindu",
    "value": "Merindu"
  },
  {
    "id": "1604231014",
    "district_id": "160423",
    "label": "Lebuay Bandung",
    "value": "Lebuay Bandung"
  },
  {
    "id": "1604232001",
    "district_id": "160423",
    "label": "Sirah Pulau",
    "value": "Sirah Pulau"
  },
  {
    "id": "1604232002",
    "district_id": "160423",
    "label": "Gunung Kembang",
    "value": "Gunung Kembang"
  },
  {
    "id": "1604232003",
    "district_id": "160423",
    "label": "Prabu Menang",
    "value": "Prabu Menang"
  },
  {
    "id": "1604232004",
    "district_id": "160423",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1604232005",
    "district_id": "160423",
    "label": "Arahan",
    "value": "Arahan"
  },
  {
    "id": "1604232006",
    "district_id": "160423",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1604232007",
    "district_id": "160423",
    "label": "Sengkuang",
    "value": "Sengkuang"
  },
  {
    "id": "1604232008",
    "district_id": "160423",
    "label": "Tanjung Lontar",
    "value": "Tanjung Lontar"
  },
  {
    "id": "1604232009",
    "district_id": "160423",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1604232010",
    "district_id": "160423",
    "label": "Muara Lawai",
    "value": "Muara Lawai"
  },
  {
    "id": "1604232011",
    "district_id": "160423",
    "label": "Tanjung Jambu",
    "value": "Tanjung Jambu"
  },
  {
    "id": "1604232012",
    "district_id": "160423",
    "label": "Cempaka Wangi",
    "value": "Cempaka Wangi"
  },
  {
    "id": "1604232013",
    "district_id": "160423",
    "label": "Lematang Jaya",
    "value": "Lematang Jaya"
  },
  {
    "id": "1604242001",
    "district_id": "160424",
    "label": "Pulau Panas",
    "value": "Pulau Panas"
  },
  {
    "id": "1604242002",
    "district_id": "160424",
    "label": "Sindang Panjang",
    "value": "Sindang Panjang"
  },
  {
    "id": "1604242003",
    "district_id": "160424",
    "label": "Gunung Kembang",
    "value": "Gunung Kembang"
  },
  {
    "id": "1604242004",
    "district_id": "160424",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1604242005",
    "district_id": "160424",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1604242006",
    "district_id": "160424",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1604242007",
    "district_id": "160424",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1604242008",
    "district_id": "160424",
    "label": "Masam Bulau",
    "value": "Masam Bulau"
  },
  {
    "id": "1604242009",
    "district_id": "160424",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1604242010",
    "district_id": "160424",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1604242011",
    "district_id": "160424",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1604242012",
    "district_id": "160424",
    "label": "Tanjung Sakti",
    "value": "Tanjung Sakti"
  },
  {
    "id": "1604242013",
    "district_id": "160424",
    "label": "Negeri Kaya",
    "value": "Negeri Kaya"
  },
  {
    "id": "1604242014",
    "district_id": "160424",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1604242015",
    "district_id": "160424",
    "label": "Gunung Kerto",
    "value": "Gunung Kerto"
  },
  {
    "id": "1604242016",
    "district_id": "160424",
    "label": "Lubuk Tabun",
    "value": "Lubuk Tabun"
  },
  {
    "id": "1604242017",
    "district_id": "160424",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1604242018",
    "district_id": "160424",
    "label": "Lubuk Dalam",
    "value": "Lubuk Dalam"
  },
  {
    "id": "1604252001",
    "district_id": "160425",
    "label": "Tinggi Hari",
    "value": "Tinggi Hari"
  },
  {
    "id": "1604252002",
    "district_id": "160425",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1604252003",
    "district_id": "160425",
    "label": "Sinjar Bulan",
    "value": "Sinjar Bulan"
  },
  {
    "id": "1604252004",
    "district_id": "160425",
    "label": "Padang Gumay",
    "value": "Padang Gumay"
  },
  {
    "id": "1604252005",
    "district_id": "160425",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1604252006",
    "district_id": "160425",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "1604252007",
    "district_id": "160425",
    "label": "Lubuk Selo",
    "value": "Lubuk Selo"
  },
  {
    "id": "1604252008",
    "district_id": "160425",
    "label": "Rindu Hati",
    "value": "Rindu Hati"
  },
  {
    "id": "1604252009",
    "district_id": "160425",
    "label": "Sumber Karya",
    "value": "Sumber Karya"
  },
  {
    "id": "1604252010",
    "district_id": "160425",
    "label": "Trans SP II Padang Muara Dua",
    "value": "Trans SP II Padang Muara Dua"
  },
  {
    "id": "1604262001",
    "district_id": "160426",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1604262002",
    "district_id": "160426",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1604262003",
    "district_id": "160426",
    "label": "Lubuk Pedaro",
    "value": "Lubuk Pedaro"
  },
  {
    "id": "1604262004",
    "district_id": "160426",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1604262005",
    "district_id": "160426",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1604262006",
    "district_id": "160426",
    "label": "Talang Akar",
    "value": "Talang Akar"
  },
  {
    "id": "1604262007",
    "district_id": "160426",
    "label": "Lubuk Betung",
    "value": "Lubuk Betung"
  },
  {
    "id": "1604262008",
    "district_id": "160426",
    "label": "Perangai",
    "value": "Perangai"
  },
  {
    "id": "1604262009",
    "district_id": "160426",
    "label": "Geramat",
    "value": "Geramat"
  },
  {
    "id": "1604272001",
    "district_id": "160427",
    "label": "Pandan Arang Ilir",
    "value": "Pandan Arang Ilir"
  },
  {
    "id": "1604272002",
    "district_id": "160427",
    "label": "Tanjung Kurung Ulu",
    "value": "Tanjung Kurung Ulu"
  },
  {
    "id": "1604272003",
    "district_id": "160427",
    "label": "Tanjung Kurung Ilir",
    "value": "Tanjung Kurung Ilir"
  },
  {
    "id": "1604272004",
    "district_id": "160427",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1604272005",
    "district_id": "160427",
    "label": "Tanjung Nibung",
    "value": "Tanjung Nibung"
  },
  {
    "id": "1604272006",
    "district_id": "160427",
    "label": "Tanjung Tebat",
    "value": "Tanjung Tebat"
  },
  {
    "id": "1604272007",
    "district_id": "160427",
    "label": "Tanjung Bai",
    "value": "Tanjung Bai"
  },
  {
    "id": "1604272008",
    "district_id": "160427",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1604272009",
    "district_id": "160427",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1604272010",
    "district_id": "160427",
    "label": "Air Dingin Lama",
    "value": "Air Dingin Lama"
  },
  {
    "id": "1604272011",
    "district_id": "160427",
    "label": "Air Dingin Baru",
    "value": "Air Dingin Baru"
  },
  {
    "id": "1604272012",
    "district_id": "160427",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1604272013",
    "district_id": "160427",
    "label": "Talang Jawa",
    "value": "Talang Jawa"
  },
  {
    "id": "1604272014",
    "district_id": "160427",
    "label": "Padang Perigi",
    "value": "Padang Perigi"
  },
  {
    "id": "1604282001",
    "district_id": "160428",
    "label": "Muara Payang",
    "value": "Muara Payang"
  },
  {
    "id": "1604282002",
    "district_id": "160428",
    "label": "Muara Gelumpai",
    "value": "Muara Gelumpai"
  },
  {
    "id": "1604282003",
    "district_id": "160428",
    "label": "Talang Tinggi",
    "value": "Talang Tinggi"
  },
  {
    "id": "1604282004",
    "district_id": "160428",
    "label": "Lawang Agung Lama",
    "value": "Lawang Agung Lama"
  },
  {
    "id": "1604282005",
    "district_id": "160428",
    "label": "Lawang Agung Baru",
    "value": "Lawang Agung Baru"
  },
  {
    "id": "1604282006",
    "district_id": "160428",
    "label": "Muara Jauh",
    "value": "Muara Jauh"
  },
  {
    "id": "1604282007",
    "district_id": "160428",
    "label": "Bandu Agung",
    "value": "Bandu Agung"
  },
  {
    "id": "1604292001",
    "district_id": "160429",
    "label": "Sukamerindu",
    "value": "Sukamerindu"
  },
  {
    "id": "1604292002",
    "district_id": "160429",
    "label": "Guru Agung",
    "value": "Guru Agung"
  },
  {
    "id": "1604292003",
    "district_id": "160429",
    "label": "Karang Caya",
    "value": "Karang Caya"
  },
  {
    "id": "1604292004",
    "district_id": "160429",
    "label": "Gunung liwat",
    "value": "Gunung liwat"
  },
  {
    "id": "1604292005",
    "district_id": "160429",
    "label": "Kapitan",
    "value": "Kapitan"
  },
  {
    "id": "1604292006",
    "district_id": "160429",
    "label": "Rambai Kaca",
    "value": "Rambai Kaca"
  },
  {
    "id": "1604292007",
    "district_id": "160429",
    "label": "Pagar Kaya",
    "value": "Pagar Kaya"
  },
  {
    "id": "1604292008",
    "district_id": "160429",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1604292009",
    "district_id": "160429",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1604292010",
    "district_id": "160429",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1604302001",
    "district_id": "160430",
    "label": "Jadian Baru",
    "value": "Jadian Baru"
  },
  {
    "id": "1604302002",
    "district_id": "160430",
    "label": "Jadian Lama",
    "value": "Jadian Lama"
  },
  {
    "id": "1604302003",
    "district_id": "160430",
    "label": "Talang Berangin",
    "value": "Talang Berangin"
  },
  {
    "id": "1604302004",
    "district_id": "160430",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1604302005",
    "district_id": "160430",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1604302006",
    "district_id": "160430",
    "label": "Danau Belidang",
    "value": "Danau Belidang"
  },
  {
    "id": "1604302007",
    "district_id": "160430",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1604302008",
    "district_id": "160430",
    "label": "Lubuk Dendan",
    "value": "Lubuk Dendan"
  },
  {
    "id": "1604302009",
    "district_id": "160430",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1604302010",
    "district_id": "160430",
    "label": "Durian Dangkal",
    "value": "Durian Dangkal"
  },
  {
    "id": "1604312001",
    "district_id": "160431",
    "label": "Banjar Negara",
    "value": "Banjar Negara"
  },
  {
    "id": "1604312002",
    "district_id": "160431",
    "label": "Tanjung Payang",
    "value": "Tanjung Payang"
  },
  {
    "id": "1604312003",
    "district_id": "160431",
    "label": "Tanjung Tebat",
    "value": "Tanjung Tebat"
  },
  {
    "id": "1604312004",
    "district_id": "160431",
    "label": "Nantal",
    "value": "Nantal"
  },
  {
    "id": "1604312005",
    "district_id": "160431",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1604312006",
    "district_id": "160431",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "1604312007",
    "district_id": "160431",
    "label": "Kerung",
    "value": "Kerung"
  },
  {
    "id": "1604312008",
    "district_id": "160431",
    "label": "Muara Cawang",
    "value": "Muara Cawang"
  },
  {
    "id": "1604312009",
    "district_id": "160431",
    "label": "Talang Sawah",
    "value": "Talang Sawah"
  },
  {
    "id": "1604312010",
    "district_id": "160431",
    "label": "Talang Sejemput",
    "value": "Talang Sejemput"
  },
  {
    "id": "1605011001",
    "district_id": "160501",
    "label": "B. Srikaton",
    "value": "B. Srikaton"
  },
  {
    "id": "1605012002",
    "district_id": "160501",
    "label": "Surodadi",
    "value": "Surodadi"
  },
  {
    "id": "1605012003",
    "district_id": "160501",
    "label": "Tegal Rejo",
    "value": "Tegal Rejo"
  },
  {
    "id": "1605012004",
    "district_id": "160501",
    "label": "Trikoyo",
    "value": "Trikoyo"
  },
  {
    "id": "1605012005",
    "district_id": "160501",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "1605012006",
    "district_id": "160501",
    "label": "Widodo",
    "value": "Widodo"
  },
  {
    "id": "1605012007",
    "district_id": "160501",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "1605012008",
    "district_id": "160501",
    "label": "Nawangsasi",
    "value": "Nawangsasi"
  },
  {
    "id": "1605012009",
    "district_id": "160501",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "1605012010",
    "district_id": "160501",
    "label": "Wukirsari",
    "value": "Wukirsari"
  },
  {
    "id": "1605012011",
    "district_id": "160501",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "1605012012",
    "district_id": "160501",
    "label": "Dwijaya",
    "value": "Dwijaya"
  },
  {
    "id": "1605012013",
    "district_id": "160501",
    "label": "Siti Harjo",
    "value": "Siti Harjo"
  },
  {
    "id": "1605012014",
    "district_id": "160501",
    "label": "Mataram",
    "value": "Mataram"
  },
  {
    "id": "1605012015",
    "district_id": "160501",
    "label": "Tambahasri",
    "value": "Tambahasri"
  },
  {
    "id": "1605012016",
    "district_id": "160501",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "1605012017",
    "district_id": "160501",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1605012018",
    "district_id": "160501",
    "label": "Triwikaton",
    "value": "Triwikaton"
  },
  {
    "id": "1605021019",
    "district_id": "160502",
    "label": "Muara Lakitan",
    "value": "Muara Lakitan"
  },
  {
    "id": "1605022001",
    "district_id": "160502",
    "label": "Tri Anggun Jaya",
    "value": "Tri Anggun Jaya"
  },
  {
    "id": "1605022002",
    "district_id": "160502",
    "label": "Semangus",
    "value": "Semangus"
  },
  {
    "id": "1605022003",
    "district_id": "160502",
    "label": "Anyar",
    "value": "Anyar"
  },
  {
    "id": "1605022004",
    "district_id": "160502",
    "label": "Muara Rengas",
    "value": "Muara Rengas"
  },
  {
    "id": "1605022005",
    "district_id": "160502",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1605022006",
    "district_id": "160502",
    "label": "Semeteh",
    "value": "Semeteh"
  },
  {
    "id": "1605022007",
    "district_id": "160502",
    "label": "Prabumulih II",
    "value": "Prabumulih II"
  },
  {
    "id": "1605022008",
    "district_id": "160502",
    "label": "Prabumulih I",
    "value": "Prabumulih I"
  },
  {
    "id": "1605022009",
    "district_id": "160502",
    "label": "Lubuk Pandan",
    "value": "Lubuk Pandan"
  },
  {
    "id": "1605022010",
    "district_id": "160502",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "1605022011",
    "district_id": "160502",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1605022012",
    "district_id": "160502",
    "label": "Marga Baru",
    "value": "Marga Baru"
  },
  {
    "id": "1605022013",
    "district_id": "160502",
    "label": "Pendingan",
    "value": "Pendingan"
  },
  {
    "id": "1605022014",
    "district_id": "160502",
    "label": "Bumi Makmur",
    "value": "Bumi Makmur"
  },
  {
    "id": "1605022015",
    "district_id": "160502",
    "label": "Mukti Karya",
    "value": "Mukti Karya"
  },
  {
    "id": "1605022016",
    "district_id": "160502",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "1605022017",
    "district_id": "160502",
    "label": "Pian Raya",
    "value": "Pian Raya"
  },
  {
    "id": "1605022018",
    "district_id": "160502",
    "label": "Sindang Laya",
    "value": "Sindang Laya"
  },
  {
    "id": "1605022020",
    "district_id": "160502",
    "label": "Semanggus Baru",
    "value": "Semanggus Baru"
  },
  {
    "id": "1605031013",
    "district_id": "160503",
    "label": "Muara Kelingi",
    "value": "Muara Kelingi"
  },
  {
    "id": "1605032001",
    "district_id": "160503",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "1605032002",
    "district_id": "160503",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1605032003",
    "district_id": "160503",
    "label": "Tanjung Lama",
    "value": "Tanjung Lama"
  },
  {
    "id": "1605032012",
    "district_id": "160503",
    "label": "Mandi Aur",
    "value": "Mandi Aur"
  },
  {
    "id": "1605032014",
    "district_id": "160503",
    "label": "Lubuk Tua",
    "value": "Lubuk Tua"
  },
  {
    "id": "1605032015",
    "district_id": "160503",
    "label": "Mambang",
    "value": "Mambang"
  },
  {
    "id": "1605032016",
    "district_id": "160503",
    "label": "Bingin Janggut",
    "value": "Bingin Janggut"
  },
  {
    "id": "1605032017",
    "district_id": "160503",
    "label": "Tugu Sempurna",
    "value": "Tugu Sempurna"
  },
  {
    "id": "1605032019",
    "district_id": "160503",
    "label": "Marga Sakti",
    "value": "Marga Sakti"
  },
  {
    "id": "1605032021",
    "district_id": "160503",
    "label": "Karya Sakti",
    "value": "Karya Sakti"
  },
  {
    "id": "1605032022",
    "district_id": "160503",
    "label": "Petrans Jaya",
    "value": "Petrans Jaya"
  },
  {
    "id": "1605032023",
    "district_id": "160503",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1605032024",
    "district_id": "160503",
    "label": "Beliti Jaya",
    "value": "Beliti Jaya"
  },
  {
    "id": "1605032026",
    "district_id": "160503",
    "label": "Karya Teladan",
    "value": "Karya Teladan"
  },
  {
    "id": "1605032027",
    "district_id": "160503",
    "label": "Temuan Sari",
    "value": "Temuan Sari"
  },
  {
    "id": "1605032028",
    "district_id": "160503",
    "label": "Temuan Jaya",
    "value": "Temuan Jaya"
  },
  {
    "id": "1605032029",
    "district_id": "160503",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1605032030",
    "district_id": "160503",
    "label": "Mangan Jaya",
    "value": "Mangan Jaya"
  },
  {
    "id": "1605032031",
    "district_id": "160503",
    "label": "Lubuk Muda",
    "value": "Lubuk Muda"
  },
  {
    "id": "1605032032",
    "district_id": "160503",
    "label": "Sukamenang",
    "value": "Sukamenang"
  },
  {
    "id": "1605081006",
    "district_id": "160508",
    "label": "Marga Tunggal",
    "value": "Marga Tunggal"
  },
  {
    "id": "1605082001",
    "district_id": "160508",
    "label": "Giri Yoso",
    "value": "Giri Yoso"
  },
  {
    "id": "1605082002",
    "district_id": "160508",
    "label": "Kertosono",
    "value": "Kertosono"
  },
  {
    "id": "1605082003",
    "district_id": "160508",
    "label": "Margoyoso",
    "value": "Margoyoso"
  },
  {
    "id": "1605082004",
    "district_id": "160508",
    "label": "Bumi Rejo",
    "value": "Bumi Rejo"
  },
  {
    "id": "1605082005",
    "district_id": "160508",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1605082007",
    "district_id": "160508",
    "label": "Marga Tani",
    "value": "Marga Tani"
  },
  {
    "id": "1605082008",
    "district_id": "160508",
    "label": "Sukowono",
    "value": "Sukowono"
  },
  {
    "id": "1605082010",
    "district_id": "160508",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1605082012",
    "district_id": "160508",
    "label": "Dono Rejo",
    "value": "Dono Rejo"
  },
  {
    "id": "1605082015",
    "district_id": "160508",
    "label": "Ngesti Boga I",
    "value": "Ngesti Boga I"
  },
  {
    "id": "1605082016",
    "district_id": "160508",
    "label": "Ngesti Boga II",
    "value": "Ngesti Boga II"
  },
  {
    "id": "1605082020",
    "district_id": "160508",
    "label": "Ngestikarya",
    "value": "Ngestikarya"
  },
  {
    "id": "1605091003",
    "district_id": "160509",
    "label": "Pasar Muara Beliti",
    "value": "Pasar Muara Beliti"
  },
  {
    "id": "1605092001",
    "district_id": "160509",
    "label": "Pedang",
    "value": "Pedang"
  },
  {
    "id": "1605092002",
    "district_id": "160509",
    "label": "Muara Beliti Baru",
    "value": "Muara Beliti Baru"
  },
  {
    "id": "1605092004",
    "district_id": "160509",
    "label": "Mana Resmi",
    "value": "Mana Resmi"
  },
  {
    "id": "1605092005",
    "district_id": "160509",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1605092014",
    "district_id": "160509",
    "label": "Suro",
    "value": "Suro"
  },
  {
    "id": "1605092015",
    "district_id": "160509",
    "label": "Tanah Periuk",
    "value": "Tanah Periuk"
  },
  {
    "id": "1605092016",
    "district_id": "160509",
    "label": "Ketuan Jaya",
    "value": "Ketuan Jaya"
  },
  {
    "id": "1605092017",
    "district_id": "160509",
    "label": "Air Satan",
    "value": "Air Satan"
  },
  {
    "id": "1605092018",
    "district_id": "160509",
    "label": "Durian Remuk",
    "value": "Durian Remuk"
  },
  {
    "id": "1605092019",
    "district_id": "160509",
    "label": "Satan Indah Jaya",
    "value": "Satan Indah Jaya"
  },
  {
    "id": "1605092020",
    "district_id": "160509",
    "label": "Air Lesing",
    "value": "Air Lesing"
  },
  {
    "id": "1605101017",
    "district_id": "160510",
    "label": "Terawas",
    "value": "Terawas"
  },
  {
    "id": "1605102001",
    "district_id": "160510",
    "label": "Sumber Karya",
    "value": "Sumber Karya"
  },
  {
    "id": "1605102002",
    "district_id": "160510",
    "label": "Suko Rejo",
    "value": "Suko Rejo"
  },
  {
    "id": "1605102003",
    "district_id": "160510",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "1605102011",
    "district_id": "160510",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1605102014",
    "district_id": "160510",
    "label": "Sukamerindu",
    "value": "Sukamerindu"
  },
  {
    "id": "1605102015",
    "district_id": "160510",
    "label": "Paduraksa",
    "value": "Paduraksa"
  },
  {
    "id": "1605102016",
    "district_id": "160510",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "1605102018",
    "district_id": "160510",
    "label": "Pasenan",
    "value": "Pasenan"
  },
  {
    "id": "1605102019",
    "district_id": "160510",
    "label": "Sukaraya",
    "value": "Sukaraya"
  },
  {
    "id": "1605102020",
    "district_id": "160510",
    "label": "Sukamana",
    "value": "Sukamana"
  },
  {
    "id": "1605102021",
    "district_id": "160510",
    "label": "Kosgoro",
    "value": "Kosgoro"
  },
  {
    "id": "1605102022",
    "district_id": "160510",
    "label": "Sukaraya Baru",
    "value": "Sukaraya Baru"
  },
  {
    "id": "1605111001",
    "district_id": "160511",
    "label": "Selangit",
    "value": "Selangit"
  },
  {
    "id": "1605112002",
    "district_id": "160511",
    "label": "Lubuk Ngin",
    "value": "Lubuk Ngin"
  },
  {
    "id": "1605112003",
    "district_id": "160511",
    "label": "Napal Melintang",
    "value": "Napal Melintang"
  },
  {
    "id": "1605112004",
    "district_id": "160511",
    "label": "Taba Tengah",
    "value": "Taba Tengah"
  },
  {
    "id": "1605112005",
    "district_id": "160511",
    "label": "Taba Remanik",
    "value": "Taba Remanik"
  },
  {
    "id": "1605112006",
    "district_id": "160511",
    "label": "Batu Gane",
    "value": "Batu Gane"
  },
  {
    "id": "1605112007",
    "district_id": "160511",
    "label": "Karang Panggung",
    "value": "Karang Panggung"
  },
  {
    "id": "1605112008",
    "district_id": "160511",
    "label": "Muara Nilau",
    "value": "Muara Nilau"
  },
  {
    "id": "1605112009",
    "district_id": "160511",
    "label": "Perabumenang",
    "value": "Perabumenang"
  },
  {
    "id": "1605112010",
    "district_id": "160511",
    "label": "Taba Rena",
    "value": "Taba Rena"
  },
  {
    "id": "1605112011",
    "district_id": "160511",
    "label": "Taba Gindo",
    "value": "Taba Gindo"
  },
  {
    "id": "1605112012",
    "district_id": "160511",
    "label": "Lubuk Ngin Baru",
    "value": "Lubuk Ngin Baru"
  },
  {
    "id": "1605121001",
    "district_id": "160512",
    "label": "Megang Sakti I",
    "value": "Megang Sakti I"
  },
  {
    "id": "1605121021",
    "district_id": "160512",
    "label": "Talang Ubi",
    "value": "Talang Ubi"
  },
  {
    "id": "1605122002",
    "district_id": "160512",
    "label": "Muara Megang",
    "value": "Muara Megang"
  },
  {
    "id": "1605122003",
    "district_id": "160512",
    "label": "Megang Sakti II",
    "value": "Megang Sakti II"
  },
  {
    "id": "1605122004",
    "district_id": "160512",
    "label": "Megang Sakti III",
    "value": "Megang Sakti III"
  },
  {
    "id": "1605122005",
    "district_id": "160512",
    "label": "Megang Sakti IV",
    "value": "Megang Sakti IV"
  },
  {
    "id": "1605122006",
    "district_id": "160512",
    "label": "Jajaran Baru",
    "value": "Jajaran Baru"
  },
  {
    "id": "1605122007",
    "district_id": "160512",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1605122008",
    "district_id": "160512",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1605122009",
    "district_id": "160512",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1605122010",
    "district_id": "160512",
    "label": "Campur Sari",
    "value": "Campur Sari"
  },
  {
    "id": "1605122011",
    "district_id": "160512",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1605122012",
    "district_id": "160512",
    "label": "Marga Puspita",
    "value": "Marga Puspita"
  },
  {
    "id": "1605122013",
    "district_id": "160512",
    "label": "Megang Sakti V",
    "value": "Megang Sakti V"
  },
  {
    "id": "1605122014",
    "district_id": "160512",
    "label": "Pagar Ayu",
    "value": "Pagar Ayu"
  },
  {
    "id": "1605122015",
    "district_id": "160512",
    "label": "Karya Mulia",
    "value": "Karya Mulia"
  },
  {
    "id": "1605122016",
    "district_id": "160512",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1605122017",
    "district_id": "160512",
    "label": "Trisakti",
    "value": "Trisakti"
  },
  {
    "id": "1605122018",
    "district_id": "160512",
    "label": "Jajaran Baru II",
    "value": "Jajaran Baru II"
  },
  {
    "id": "1605122019",
    "district_id": "160512",
    "label": "Muara Megang I",
    "value": "Muara Megang I"
  },
  {
    "id": "1605122020",
    "district_id": "160512",
    "label": "Mulyo Sari",
    "value": "Mulyo Sari"
  },
  {
    "id": "1605131005",
    "district_id": "160513",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1605131007",
    "district_id": "160513",
    "label": "Mangun Harjo",
    "value": "Mangun Harjo"
  },
  {
    "id": "1605132001",
    "district_id": "160513",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1605132002",
    "district_id": "160513",
    "label": "Kerto Sari",
    "value": "Kerto Sari"
  },
  {
    "id": "1605132003",
    "district_id": "160513",
    "label": "Pagar Sari",
    "value": "Pagar Sari"
  },
  {
    "id": "1605132004",
    "district_id": "160513",
    "label": "Mardi Harjo",
    "value": "Mardi Harjo"
  },
  {
    "id": "1605132006",
    "district_id": "160513",
    "label": "Karya Dadi",
    "value": "Karya Dadi"
  },
  {
    "id": "1605132008",
    "district_id": "160513",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1605132009",
    "district_id": "160513",
    "label": "Sadar Karya",
    "value": "Sadar Karya"
  },
  {
    "id": "1605132010",
    "district_id": "160513",
    "label": "Purwakarya",
    "value": "Purwakarya"
  },
  {
    "id": "1605132011",
    "district_id": "160513",
    "label": "Tri Karya",
    "value": "Tri Karya"
  },
  {
    "id": "1605141017",
    "district_id": "160514",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1605142001",
    "district_id": "160514",
    "label": "Kembang Tanjung",
    "value": "Kembang Tanjung"
  },
  {
    "id": "1605142002",
    "district_id": "160514",
    "label": "Gn. Kembang Baru",
    "value": "Gn. Kembang Baru"
  },
  {
    "id": "1605142003",
    "district_id": "160514",
    "label": "Gn. Kembang Lama",
    "value": "Gn. Kembang Lama"
  },
  {
    "id": "1605142004",
    "district_id": "160514",
    "label": "Sungai Bunut",
    "value": "Sungai Bunut"
  },
  {
    "id": "1605142005",
    "district_id": "160514",
    "label": "Sungai Naik",
    "value": "Sungai Naik"
  },
  {
    "id": "1605142006",
    "district_id": "160514",
    "label": "Pangkalan T. Lama",
    "value": "Pangkalan T. Lama"
  },
  {
    "id": "1605142007",
    "district_id": "160514",
    "label": "Sadu",
    "value": "Sadu"
  },
  {
    "id": "1605142008",
    "district_id": "160514",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "1605142009",
    "district_id": "160514",
    "label": "Pelawe",
    "value": "Pelawe"
  },
  {
    "id": "1605142010",
    "district_id": "160514",
    "label": "Lubuk Pauh",
    "value": "Lubuk Pauh"
  },
  {
    "id": "1605142011",
    "district_id": "160514",
    "label": "Tri Jaya",
    "value": "Tri Jaya"
  },
  {
    "id": "1605142012",
    "district_id": "160514",
    "label": "Mulyo Harjo",
    "value": "Mulyo Harjo"
  },
  {
    "id": "1605142013",
    "district_id": "160514",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1605142014",
    "district_id": "160514",
    "label": "Raksa Budi",
    "value": "Raksa Budi"
  },
  {
    "id": "1605142015",
    "district_id": "160514",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1605142016",
    "district_id": "160514",
    "label": "Trimukti",
    "value": "Trimukti"
  },
  {
    "id": "1605142018",
    "district_id": "160514",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1605142019",
    "district_id": "160514",
    "label": "Sembatu Jaya",
    "value": "Sembatu Jaya"
  },
  {
    "id": "1605182001",
    "district_id": "160518",
    "label": "Lubuk Besar",
    "value": "Lubuk Besar"
  },
  {
    "id": "1605182002",
    "district_id": "160518",
    "label": "Batu Bandung",
    "value": "Batu Bandung"
  },
  {
    "id": "1605182003",
    "district_id": "160518",
    "label": "Rantau Bingin",
    "value": "Rantau Bingin"
  },
  {
    "id": "1605182004",
    "district_id": "160518",
    "label": "Rantau Serik",
    "value": "Rantau Serik"
  },
  {
    "id": "1605182005",
    "district_id": "160518",
    "label": "Muara Kati Lama",
    "value": "Muara Kati Lama"
  },
  {
    "id": "1605182006",
    "district_id": "160518",
    "label": "Muara Kati Baru I",
    "value": "Muara Kati Baru I"
  },
  {
    "id": "1605182007",
    "district_id": "160518",
    "label": "Muara Kati Baru II",
    "value": "Muara Kati Baru II"
  },
  {
    "id": "1605182008",
    "district_id": "160518",
    "label": "Kebur",
    "value": "Kebur"
  },
  {
    "id": "1605182009",
    "district_id": "160518",
    "label": "Simpang Gegas Temuan",
    "value": "Simpang Gegas Temuan"
  },
  {
    "id": "1605182010",
    "district_id": "160518",
    "label": "Kebur Jaya",
    "value": "Kebur Jaya"
  },
  {
    "id": "1605191002",
    "district_id": "160519",
    "label": "Sumber Harta",
    "value": "Sumber Harta"
  },
  {
    "id": "1605192001",
    "district_id": "160519",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1605192003",
    "district_id": "160519",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1605192004",
    "district_id": "160519",
    "label": "Jamburejo",
    "value": "Jamburejo"
  },
  {
    "id": "1605192005",
    "district_id": "160519",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1605192006",
    "district_id": "160519",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1605192007",
    "district_id": "160519",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1605192008",
    "district_id": "160519",
    "label": "Madang",
    "value": "Madang"
  },
  {
    "id": "1605192009",
    "district_id": "160519",
    "label": "Sukarami Jaya",
    "value": "Sukarami Jaya"
  },
  {
    "id": "1605192010",
    "district_id": "160519",
    "label": "Sumber Asri",
    "value": "Sumber Asri"
  },
  {
    "id": "1605202001",
    "district_id": "160520",
    "label": "Banpres",
    "value": "Banpres"
  },
  {
    "id": "1605202002",
    "district_id": "160520",
    "label": "Jaya Tunggal",
    "value": "Jaya Tunggal"
  },
  {
    "id": "1605202003",
    "district_id": "160520",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1605202004",
    "district_id": "160520",
    "label": "Darma Sakti",
    "value": "Darma Sakti"
  },
  {
    "id": "1605202005",
    "district_id": "160520",
    "label": "Jaya Bhakti",
    "value": "Jaya Bhakti"
  },
  {
    "id": "1605202006",
    "district_id": "160520",
    "label": "Remayu",
    "value": "Remayu"
  },
  {
    "id": "1605202007",
    "district_id": "160520",
    "label": "Petunang",
    "value": "Petunang"
  },
  {
    "id": "1605202008",
    "district_id": "160520",
    "label": "Lubuk Rumbai",
    "value": "Lubuk Rumbai"
  },
  {
    "id": "1605202009",
    "district_id": "160520",
    "label": "Air Beliti",
    "value": "Air Beliti"
  },
  {
    "id": "1605202010",
    "district_id": "160520",
    "label": "Leban Jaya",
    "value": "Leban Jaya"
  },
  {
    "id": "1605202011",
    "district_id": "160520",
    "label": "Bamasco",
    "value": "Bamasco"
  },
  {
    "id": "1605212001",
    "district_id": "160521",
    "label": "Sukowarno",
    "value": "Sukowarno"
  },
  {
    "id": "1605212002",
    "district_id": "160521",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1605212003",
    "district_id": "160521",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1605212004",
    "district_id": "160521",
    "label": "Ciptodadi",
    "value": "Ciptodadi"
  },
  {
    "id": "1605212005",
    "district_id": "160521",
    "label": "Sukarena",
    "value": "Sukarena"
  },
  {
    "id": "1605212006",
    "district_id": "160521",
    "label": "Yudha Karya Bakti",
    "value": "Yudha Karya Bakti"
  },
  {
    "id": "1605212007",
    "district_id": "160521",
    "label": "Rantau Alih",
    "value": "Rantau Alih"
  },
  {
    "id": "1605212008",
    "district_id": "160521",
    "label": "Ciptodadi II",
    "value": "Ciptodadi II"
  },
  {
    "id": "1606011009",
    "district_id": "160601",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "1606011010",
    "district_id": "160601",
    "label": "Serasan Jaya",
    "value": "Serasan Jaya"
  },
  {
    "id": "1606011011",
    "district_id": "160601",
    "label": "Soak baru",
    "value": "Soak baru"
  },
  {
    "id": "1606011012",
    "district_id": "160601",
    "label": "Balai Agung",
    "value": "Balai Agung"
  },
  {
    "id": "1606012001",
    "district_id": "160601",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1606012002",
    "district_id": "160601",
    "label": "Lumpatan",
    "value": "Lumpatan"
  },
  {
    "id": "1606012003",
    "district_id": "160601",
    "label": "Bailangu",
    "value": "Bailangu"
  },
  {
    "id": "1606012004",
    "district_id": "160601",
    "label": "Muara Teladan",
    "value": "Muara Teladan"
  },
  {
    "id": "1606012005",
    "district_id": "160601",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1606012006",
    "district_id": "160601",
    "label": "Sungai Medak",
    "value": "Sungai Medak"
  },
  {
    "id": "1606012007",
    "district_id": "160601",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  {
    "id": "1606012008",
    "district_id": "160601",
    "label": "Rimba Ukur",
    "value": "Rimba Ukur"
  },
  {
    "id": "1606012013",
    "district_id": "160601",
    "label": "Lumpatan 2",
    "value": "Lumpatan 2"
  },
  {
    "id": "1606012014",
    "district_id": "160601",
    "label": "Baliangu Timur",
    "value": "Baliangu Timur"
  },
  {
    "id": "1606022001",
    "district_id": "160602",
    "label": "Danau Cala",
    "value": "Danau Cala"
  },
  {
    "id": "1606022002",
    "district_id": "160602",
    "label": "Rantau Keroya",
    "value": "Rantau Keroya"
  },
  {
    "id": "1606022003",
    "district_id": "160602",
    "label": "Tanjung Agung Timur",
    "value": "Tanjung Agung Timur"
  },
  {
    "id": "1606022004",
    "district_id": "160602",
    "label": "Petaling",
    "value": "Petaling"
  },
  {
    "id": "1606022005",
    "district_id": "160602",
    "label": "Lais",
    "value": "Lais"
  },
  {
    "id": "1606022006",
    "district_id": "160602",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "1606022007",
    "district_id": "160602",
    "label": "Epil",
    "value": "Epil"
  },
  {
    "id": "1606022008",
    "district_id": "160602",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1606022009",
    "district_id": "160602",
    "label": "Teluk Kijing I",
    "value": "Teluk Kijing I"
  },
  {
    "id": "1606022010",
    "district_id": "160602",
    "label": "Teluk Kijing II",
    "value": "Teluk Kijing II"
  },
  {
    "id": "1606022011",
    "district_id": "160602",
    "label": "Teluk Kijing III",
    "value": "Teluk Kijing III"
  },
  {
    "id": "1606022012",
    "district_id": "160602",
    "label": "Tanjung Agung Utara",
    "value": "Tanjung Agung Utara"
  },
  {
    "id": "1606022013",
    "district_id": "160602",
    "label": "Tanjung Agung Barat",
    "value": "Tanjung Agung Barat"
  },
  {
    "id": "1606022014",
    "district_id": "160602",
    "label": "Tanjung Agung Selatan",
    "value": "Tanjung Agung Selatan"
  },
  {
    "id": "1606022015",
    "district_id": "160602",
    "label": "Lais Utara",
    "value": "Lais Utara"
  },
  {
    "id": "1606032002",
    "district_id": "160603",
    "label": "Pagar Kaya",
    "value": "Pagar Kaya"
  },
  {
    "id": "1606032003",
    "district_id": "160603",
    "label": "Sukalali",
    "value": "Sukalali"
  },
  {
    "id": "1606032004",
    "district_id": "160603",
    "label": "Kartayu",
    "value": "Kartayu"
  },
  {
    "id": "1606032006",
    "district_id": "160603",
    "label": "Sungai Dua",
    "value": "Sungai Dua"
  },
  {
    "id": "1606032007",
    "district_id": "160603",
    "label": "Sindang Marga",
    "value": "Sindang Marga"
  },
  {
    "id": "1606032008",
    "district_id": "160603",
    "label": "Tebing Bulang",
    "value": "Tebing Bulang"
  },
  {
    "id": "1606032009",
    "district_id": "160603",
    "label": "Gajah Mati",
    "value": "Gajah Mati"
  },
  {
    "id": "1606032010",
    "district_id": "160603",
    "label": "Rantau Sialang",
    "value": "Rantau Sialang"
  },
  {
    "id": "1606032014",
    "district_id": "160603",
    "label": "Kerta Jaya",
    "value": "Kerta Jaya"
  },
  {
    "id": "1606032015",
    "district_id": "160603",
    "label": "Keramat Jaya",
    "value": "Keramat Jaya"
  },
  {
    "id": "1606042001",
    "district_id": "160604",
    "label": "Bukit Sejahtera",
    "value": "Bukit Sejahtera"
  },
  {
    "id": "1606042002",
    "district_id": "160604",
    "label": "Bukit Pangkuasan",
    "value": "Bukit Pangkuasan"
  },
  {
    "id": "1606042003",
    "district_id": "160604",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "1606042004",
    "district_id": "160604",
    "label": "Talang Leban",
    "value": "Talang Leban"
  },
  {
    "id": "1606042005",
    "district_id": "160604",
    "label": "Talang Buluh",
    "value": "Talang Buluh"
  },
  {
    "id": "1606042006",
    "district_id": "160604",
    "label": "Lubuk Buah",
    "value": "Lubuk Buah"
  },
  {
    "id": "1606042007",
    "district_id": "160604",
    "label": "Sungai Napal",
    "value": "Sungai Napal"
  },
  {
    "id": "1606042008",
    "district_id": "160604",
    "label": "Lubuk Bintialo",
    "value": "Lubuk Bintialo"
  },
  {
    "id": "1606042009",
    "district_id": "160604",
    "label": "Sako Suban",
    "value": "Sako Suban"
  },
  {
    "id": "1606042010",
    "district_id": "160604",
    "label": "Bukit Selabu",
    "value": "Bukit Selabu"
  },
  {
    "id": "1606042011",
    "district_id": "160604",
    "label": "Saut",
    "value": "Saut"
  },
  {
    "id": "1606042012",
    "district_id": "160604",
    "label": "Tanjung Bali",
    "value": "Tanjung Bali"
  },
  {
    "id": "1606042013",
    "district_id": "160604",
    "label": "Pinggap",
    "value": "Pinggap"
  },
  {
    "id": "1606042014",
    "district_id": "160604",
    "label": "Pengaturan",
    "value": "Pengaturan"
  },
  {
    "id": "1606042015",
    "district_id": "160604",
    "label": "Ulak Kembang",
    "value": "Ulak Kembang"
  },
  {
    "id": "1606042016",
    "district_id": "160604",
    "label": "Pangkalan Bulian",
    "value": "Pangkalan Bulian"
  },
  {
    "id": "1606051016",
    "district_id": "160605",
    "label": "Ngulak I",
    "value": "Ngulak I"
  },
  {
    "id": "1606051019",
    "district_id": "160605",
    "label": "Ngulak",
    "value": "Ngulak"
  },
  {
    "id": "1606052001",
    "district_id": "160605",
    "label": "Air Balui",
    "value": "Air Balui"
  },
  {
    "id": "1606052002",
    "district_id": "160605",
    "label": "Nganti",
    "value": "Nganti"
  },
  {
    "id": "1606052003",
    "district_id": "160605",
    "label": "Jud I",
    "value": "Jud I"
  },
  {
    "id": "1606052004",
    "district_id": "160605",
    "label": "Jud II",
    "value": "Jud II"
  },
  {
    "id": "1606052005",
    "district_id": "160605",
    "label": "Penggage",
    "value": "Penggage"
  },
  {
    "id": "1606052006",
    "district_id": "160605",
    "label": "Ngunang",
    "value": "Ngunang"
  },
  {
    "id": "1606052007",
    "district_id": "160605",
    "label": "Ngulak II",
    "value": "Ngulak II"
  },
  {
    "id": "1606052008",
    "district_id": "160605",
    "label": "Ngulak III",
    "value": "Ngulak III"
  },
  {
    "id": "1606052009",
    "district_id": "160605",
    "label": "Ulak Embacang",
    "value": "Ulak Embacang"
  },
  {
    "id": "1606052010",
    "district_id": "160605",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "1606052011",
    "district_id": "160605",
    "label": "Kemang",
    "value": "Kemang"
  },
  {
    "id": "1606052012",
    "district_id": "160605",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1606052013",
    "district_id": "160605",
    "label": "Air Itam",
    "value": "Air Itam"
  },
  {
    "id": "1606052014",
    "district_id": "160605",
    "label": "Keban I",
    "value": "Keban I"
  },
  {
    "id": "1606052015",
    "district_id": "160605",
    "label": "Keban II",
    "value": "Keban II"
  },
  {
    "id": "1606052017",
    "district_id": "160605",
    "label": "Panai",
    "value": "Panai"
  },
  {
    "id": "1606052018",
    "district_id": "160605",
    "label": "Macang Sakti",
    "value": "Macang Sakti"
  },
  {
    "id": "1606061038",
    "district_id": "160606",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "1606061039",
    "district_id": "160606",
    "label": "Mangun Jaya",
    "value": "Mangun Jaya"
  },
  {
    "id": "1606062001",
    "district_id": "160606",
    "label": "Sereka",
    "value": "Sereka"
  },
  {
    "id": "1606062002",
    "district_id": "160606",
    "label": "Sugi Raya",
    "value": "Sugi Raya"
  },
  {
    "id": "1606062003",
    "district_id": "160606",
    "label": "Sugi Waras",
    "value": "Sugi Waras"
  },
  {
    "id": "1606062004",
    "district_id": "160606",
    "label": "Beruge",
    "value": "Beruge"
  },
  {
    "id": "1606062005",
    "district_id": "160606",
    "label": "Muara Punjung",
    "value": "Muara Punjung"
  },
  {
    "id": "1606062007",
    "district_id": "160606",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1606062008",
    "district_id": "160606",
    "label": "Toman",
    "value": "Toman"
  },
  {
    "id": "1606062009",
    "district_id": "160606",
    "label": "Kasmaran",
    "value": "Kasmaran"
  },
  {
    "id": "1606062022",
    "district_id": "160606",
    "label": "Sungai Angit",
    "value": "Sungai Angit"
  },
  {
    "id": "1606062023",
    "district_id": "160606",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "1606062042",
    "district_id": "160606",
    "label": "Pangkalan Jaya",
    "value": "Pangkalan Jaya"
  },
  {
    "id": "1606071020",
    "district_id": "160607",
    "label": "Sungai Lilin",
    "value": "Sungai Lilin"
  },
  {
    "id": "1606071030",
    "district_id": "160607",
    "label": "Sungai Lilin Jaya",
    "value": "Sungai Lilin Jaya"
  },
  {
    "id": "1606072007",
    "district_id": "160607",
    "label": "Sri Gunung",
    "value": "Sri Gunung"
  },
  {
    "id": "1606072008",
    "district_id": "160607",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "1606072009",
    "district_id": "160607",
    "label": "Linggo Sari",
    "value": "Linggo Sari"
  },
  {
    "id": "1606072010",
    "district_id": "160607",
    "label": "Mulyo Rejo",
    "value": "Mulyo Rejo"
  },
  {
    "id": "1606072011",
    "district_id": "160607",
    "label": "Suka Damai Baru",
    "value": "Suka Damai Baru"
  },
  {
    "id": "1606072012",
    "district_id": "160607",
    "label": "Cinta Damai",
    "value": "Cinta Damai"
  },
  {
    "id": "1606072013",
    "district_id": "160607",
    "label": "Berlian Makmur",
    "value": "Berlian Makmur"
  },
  {
    "id": "1606072014",
    "district_id": "160607",
    "label": "Bumi Kencana",
    "value": "Bumi Kencana"
  },
  {
    "id": "1606072015",
    "district_id": "160607",
    "label": "Mekar Jadi",
    "value": "Mekar Jadi"
  },
  {
    "id": "1606072016",
    "district_id": "160607",
    "label": "Nusa Serasan",
    "value": "Nusa Serasan"
  },
  {
    "id": "1606072017",
    "district_id": "160607",
    "label": "Pinang Banjar",
    "value": "Pinang Banjar"
  },
  {
    "id": "1606072019",
    "district_id": "160607",
    "label": "Panca Tunggal",
    "value": "Panca Tunggal"
  },
  {
    "id": "1606072021",
    "district_id": "160607",
    "label": "Bukit Jaya",
    "value": "Bukit Jaya"
  },
  {
    "id": "1606081015",
    "district_id": "160608",
    "label": "Keluang",
    "value": "Keluang"
  },
  {
    "id": "1606082001",
    "district_id": "160608",
    "label": "Tenggaro",
    "value": "Tenggaro"
  },
  {
    "id": "1606082003",
    "district_id": "160608",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1606082004",
    "district_id": "160608",
    "label": "Dawas",
    "value": "Dawas"
  },
  {
    "id": "1606082005",
    "district_id": "160608",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1606082006",
    "district_id": "160608",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1606082007",
    "district_id": "160608",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "1606082008",
    "district_id": "160608",
    "label": "Tegal Mulyo",
    "value": "Tegal Mulyo"
  },
  {
    "id": "1606082009",
    "district_id": "160608",
    "label": "Cipta Praja",
    "value": "Cipta Praja"
  },
  {
    "id": "1606082010",
    "district_id": "160608",
    "label": "Loka Jaya",
    "value": "Loka Jaya"
  },
  {
    "id": "1606082011",
    "district_id": "160608",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1606082012",
    "district_id": "160608",
    "label": "Sido Rejo",
    "value": "Sido Rejo"
  },
  {
    "id": "1606082013",
    "district_id": "160608",
    "label": "Mulyo Asih",
    "value": "Mulyo Asih"
  },
  {
    "id": "1606082014",
    "district_id": "160608",
    "label": "Sridamai",
    "value": "Sridamai"
  },
  {
    "id": "1606091016",
    "district_id": "160609",
    "label": "Bayung Lencir",
    "value": "Bayung Lencir"
  },
  {
    "id": "1606091065",
    "district_id": "160609",
    "label": "Bayung Lencir Indah",
    "value": "Bayung Lencir Indah"
  },
  {
    "id": "1606092006",
    "district_id": "160609",
    "label": "Muara Merang",
    "value": "Muara Merang"
  },
  {
    "id": "1606092007",
    "district_id": "160609",
    "label": "Mangsang",
    "value": "Mangsang"
  },
  {
    "id": "1606092008",
    "district_id": "160609",
    "label": "Tampang Baru",
    "value": "Tampang Baru"
  },
  {
    "id": "1606092009",
    "district_id": "160609",
    "label": "Kali Berau",
    "value": "Kali Berau"
  },
  {
    "id": "1606092010",
    "district_id": "160609",
    "label": "Sindang Marga",
    "value": "Sindang Marga"
  },
  {
    "id": "1606092011",
    "district_id": "160609",
    "label": "Telang",
    "value": "Telang"
  },
  {
    "id": "1606092012",
    "district_id": "160609",
    "label": "Pagar Desa",
    "value": "Pagar Desa"
  },
  {
    "id": "1606092013",
    "district_id": "160609",
    "label": "Mendis",
    "value": "Mendis"
  },
  {
    "id": "1606092014",
    "district_id": "160609",
    "label": "Pulai Gading",
    "value": "Pulai Gading"
  },
  {
    "id": "1606092015",
    "district_id": "160609",
    "label": "Muara Medak",
    "value": "Muara Medak"
  },
  {
    "id": "1606092017",
    "district_id": "160609",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1606092026",
    "district_id": "160609",
    "label": "Senawar Jaya",
    "value": "Senawar Jaya"
  },
  {
    "id": "1606092048",
    "district_id": "160609",
    "label": "Simpang Bayat",
    "value": "Simpang Bayat"
  },
  {
    "id": "1606092049",
    "district_id": "160609",
    "label": "Bayat Ilir",
    "value": "Bayat Ilir"
  },
  {
    "id": "1606092050",
    "district_id": "160609",
    "label": "Pangkalan Bayat",
    "value": "Pangkalan Bayat"
  },
  {
    "id": "1606092051",
    "district_id": "160609",
    "label": "Muara Bahar",
    "value": "Muara Bahar"
  },
  {
    "id": "1606092058",
    "district_id": "160609",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1606092059",
    "district_id": "160609",
    "label": "Mendis Jaya",
    "value": "Mendis Jaya"
  },
  {
    "id": "1606092060",
    "district_id": "160609",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "1606092061",
    "district_id": "160609",
    "label": "Wono Rejo",
    "value": "Wono Rejo"
  },
  {
    "id": "1606092063",
    "district_id": "160609",
    "label": "Lubuk Harjo",
    "value": "Lubuk Harjo"
  },
  {
    "id": "1606102001",
    "district_id": "160610",
    "label": "Sido Mukti",
    "value": "Sido Mukti"
  },
  {
    "id": "1606102002",
    "district_id": "160610",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1606102003",
    "district_id": "160610",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1606102004",
    "district_id": "160610",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1606102005",
    "district_id": "160610",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1606102006",
    "district_id": "160610",
    "label": "Bangun Harja",
    "value": "Bangun Harja"
  },
  {
    "id": "1606102007",
    "district_id": "160610",
    "label": "Sido Rahayu",
    "value": "Sido Rahayu"
  },
  {
    "id": "1606102008",
    "district_id": "160610",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "1606102009",
    "district_id": "160610",
    "label": "Warga Mulya",
    "value": "Warga Mulya"
  },
  {
    "id": "1606102010",
    "district_id": "160610",
    "label": "Tanjung Kaputran",
    "value": "Tanjung Kaputran"
  },
  {
    "id": "1606102011",
    "district_id": "160610",
    "label": "Air Putih Ulu",
    "value": "Air Putih Ulu"
  },
  {
    "id": "1606102012",
    "district_id": "160610",
    "label": "Sialang Agung",
    "value": "Sialang Agung"
  },
  {
    "id": "1606102013",
    "district_id": "160610",
    "label": "Cinta Karya",
    "value": "Cinta Karya"
  },
  {
    "id": "1606102014",
    "district_id": "160610",
    "label": "Air Putih Ilir",
    "value": "Air Putih Ilir"
  },
  {
    "id": "1606102015",
    "district_id": "160610",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "1606112001",
    "district_id": "160611",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1606112002",
    "district_id": "160611",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1606112003",
    "district_id": "160611",
    "label": "Karang Makmur",
    "value": "Karang Makmur"
  },
  {
    "id": "1606112004",
    "district_id": "160611",
    "label": "Perumpung Raya",
    "value": "Perumpung Raya"
  },
  {
    "id": "1606112005",
    "district_id": "160611",
    "label": "Galih Sari",
    "value": "Galih Sari"
  },
  {
    "id": "1606112006",
    "district_id": "160611",
    "label": "Karang Tirta",
    "value": "Karang Tirta"
  },
  {
    "id": "1606112007",
    "district_id": "160611",
    "label": "Purwo Agung",
    "value": "Purwo Agung"
  },
  {
    "id": "1606112008",
    "district_id": "160611",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1606112009",
    "district_id": "160611",
    "label": "Sri Karang Rejo",
    "value": "Sri Karang Rejo"
  },
  {
    "id": "1606112010",
    "district_id": "160611",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1606112011",
    "district_id": "160611",
    "label": "Agung Jaya",
    "value": "Agung Jaya"
  },
  {
    "id": "1606112012",
    "district_id": "160611",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1606112013",
    "district_id": "160611",
    "label": "Jaya Agung",
    "value": "Jaya Agung"
  },
  {
    "id": "1606112014",
    "district_id": "160611",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1606112015",
    "district_id": "160611",
    "label": "Mandala Sari",
    "value": "Mandala Sari"
  },
  {
    "id": "1606112016",
    "district_id": "160611",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1606112017",
    "district_id": "160611",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1606112018",
    "district_id": "160611",
    "label": "Sri Gading",
    "value": "Sri Gading"
  },
  {
    "id": "1606112019",
    "district_id": "160611",
    "label": "Mulya Agung",
    "value": "Mulya Agung"
  },
  {
    "id": "1606112020",
    "district_id": "160611",
    "label": "Karang Mukti",
    "value": "Karang Mukti"
  },
  {
    "id": "1606112021",
    "district_id": "160611",
    "label": "Madya Mulya",
    "value": "Madya Mulya"
  },
  {
    "id": "1606112022",
    "district_id": "160611",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1606112023",
    "district_id": "160611",
    "label": "Sari Agung",
    "value": "Sari Agung"
  },
  {
    "id": "1606112024",
    "district_id": "160611",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1606112025",
    "district_id": "160611",
    "label": "Ringin Agung",
    "value": "Ringin Agung"
  },
  {
    "id": "1606112026",
    "district_id": "160611",
    "label": "Tri Mulya Agung",
    "value": "Tri Mulya Agung"
  },
  {
    "id": "1606112027",
    "district_id": "160611",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1606122001",
    "district_id": "160612",
    "label": "Peninggalan",
    "value": "Peninggalan"
  },
  {
    "id": "1606122002",
    "district_id": "160612",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1606122003",
    "district_id": "160612",
    "label": "Berlian Jaya",
    "value": "Berlian Jaya"
  },
  {
    "id": "1606122004",
    "district_id": "160612",
    "label": "Simpang Tungkal",
    "value": "Simpang Tungkal"
  },
  {
    "id": "1606122005",
    "district_id": "160612",
    "label": "Pangkalan Tungkal",
    "value": "Pangkalan Tungkal"
  },
  {
    "id": "1606122006",
    "district_id": "160612",
    "label": "Sumber Harum",
    "value": "Sumber Harum"
  },
  {
    "id": "1606122007",
    "district_id": "160612",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1606122008",
    "district_id": "160612",
    "label": "Sinar Harapan",
    "value": "Sinar Harapan"
  },
  {
    "id": "1606122009",
    "district_id": "160612",
    "label": "Banjar Jaya",
    "value": "Banjar Jaya"
  },
  {
    "id": "1606122010",
    "district_id": "160612",
    "label": "Beji Mulyo",
    "value": "Beji Mulyo"
  },
  {
    "id": "1606122011",
    "district_id": "160612",
    "label": "Bero Jaya Timur",
    "value": "Bero Jaya Timur"
  },
  {
    "id": "1606122012",
    "district_id": "160612",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "1606122013",
    "district_id": "160612",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1606122014",
    "district_id": "160612",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1606122015",
    "district_id": "160612",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1606122016",
    "district_id": "160612",
    "label": "Sinar Tungkal",
    "value": "Sinar Tungkal"
  },
  {
    "id": "1606132001",
    "district_id": "160613",
    "label": "Ulak Paceh",
    "value": "Ulak Paceh"
  },
  {
    "id": "1606132002",
    "district_id": "160613",
    "label": "Ulak Paceh Jaya",
    "value": "Ulak Paceh Jaya"
  },
  {
    "id": "1606132003",
    "district_id": "160613",
    "label": "Tanjung Durian",
    "value": "Tanjung Durian"
  },
  {
    "id": "1606132004",
    "district_id": "160613",
    "label": "Napal",
    "value": "Napal"
  },
  {
    "id": "1606132005",
    "district_id": "160613",
    "label": "Rantau Kasih",
    "value": "Rantau Kasih"
  },
  {
    "id": "1606132006",
    "district_id": "160613",
    "label": "Karang Ringin I",
    "value": "Karang Ringin I"
  },
  {
    "id": "1606132007",
    "district_id": "160613",
    "label": "Karang Ringin II",
    "value": "Karang Ringin II"
  },
  {
    "id": "1606132008",
    "district_id": "160613",
    "label": "Ulak Teberau",
    "value": "Ulak Teberau"
  },
  {
    "id": "1606132009",
    "district_id": "160613",
    "label": "Bumi Ayu",
    "value": "Bumi Ayu"
  },
  {
    "id": "1606132010",
    "district_id": "160613",
    "label": "Karang Waru",
    "value": "Karang Waru"
  },
  {
    "id": "1606132011",
    "district_id": "160613",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1606132012",
    "district_id": "160613",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1606132013",
    "district_id": "160613",
    "label": "Simpang Sari",
    "value": "Simpang Sari"
  },
  {
    "id": "1606132014",
    "district_id": "160613",
    "label": "Talang Piase",
    "value": "Talang Piase"
  },
  {
    "id": "1606132015",
    "district_id": "160613",
    "label": "Pandan Dulang",
    "value": "Pandan Dulang"
  },
  {
    "id": "1606142001",
    "district_id": "160614",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1606142002",
    "district_id": "160614",
    "label": "Letang",
    "value": "Letang"
  },
  {
    "id": "1606142003",
    "district_id": "160614",
    "label": "Supat",
    "value": "Supat"
  },
  {
    "id": "1606142004",
    "district_id": "160614",
    "label": "Babat Banyuasin",
    "value": "Babat Banyuasin"
  },
  {
    "id": "1606142005",
    "district_id": "160614",
    "label": "Tanjung Kerang",
    "value": "Tanjung Kerang"
  },
  {
    "id": "1606142006",
    "district_id": "160614",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "1606142007",
    "district_id": "160614",
    "label": "Gajah Mati",
    "value": "Gajah Mati"
  },
  {
    "id": "1606142008",
    "district_id": "160614",
    "label": "Tenggulang Jaya",
    "value": "Tenggulang Jaya"
  },
  {
    "id": "1606142009",
    "district_id": "160614",
    "label": "Bandar Tenggulang",
    "value": "Bandar Tenggulang"
  },
  {
    "id": "1606142010",
    "district_id": "160614",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1606142011",
    "district_id": "160614",
    "label": "Tenggulang Baru",
    "value": "Tenggulang Baru"
  },
  {
    "id": "1606142012",
    "district_id": "160614",
    "label": "Supat Barat",
    "value": "Supat Barat"
  },
  {
    "id": "1606142013",
    "district_id": "160614",
    "label": "Supat Timur",
    "value": "Supat Timur"
  },
  {
    "id": "1606142014",
    "district_id": "160614",
    "label": "Seratus Lapan",
    "value": "Seratus Lapan"
  },
  {
    "id": "1606142015",
    "district_id": "160614",
    "label": "Babat Ramba Jaya",
    "value": "Babat Ramba Jaya"
  },
  {
    "id": "1606142016",
    "district_id": "160614",
    "label": "Gajah Muda",
    "value": "Gajah Muda"
  },
  {
    "id": "1606152001",
    "district_id": "160615",
    "label": "Jirak",
    "value": "Jirak"
  },
  {
    "id": "1606152002",
    "district_id": "160615",
    "label": "Rukun Rahayu",
    "value": "Rukun Rahayu"
  },
  {
    "id": "1606152003",
    "district_id": "160615",
    "label": "Talang Simpang",
    "value": "Talang Simpang"
  },
  {
    "id": "1606152004",
    "district_id": "160615",
    "label": "Jembatan Gantung",
    "value": "Jembatan Gantung"
  },
  {
    "id": "1606152005",
    "district_id": "160615",
    "label": "Talang Mandung",
    "value": "Talang Mandung"
  },
  {
    "id": "1606152006",
    "district_id": "160615",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "1606152007",
    "district_id": "160615",
    "label": "Bangkit Jaya",
    "value": "Bangkit Jaya"
  },
  {
    "id": "1606152008",
    "district_id": "160615",
    "label": "Baru Jaya",
    "value": "Baru Jaya"
  },
  {
    "id": "1606152009",
    "district_id": "160615",
    "label": "Sinar Jaya",
    "value": "Sinar Jaya"
  },
  {
    "id": "1606152010",
    "district_id": "160615",
    "label": "Setia Jaya",
    "value": "Setia Jaya"
  },
  {
    "id": "1606152011",
    "district_id": "160615",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1606152012",
    "district_id": "160615",
    "label": "Layan",
    "value": "Layan"
  },
  {
    "id": "1607011016",
    "district_id": "160701",
    "label": "Mariana",
    "value": "Mariana"
  },
  {
    "id": "1607011021",
    "district_id": "160701",
    "label": "Mariana Ilir",
    "value": "Mariana Ilir"
  },
  {
    "id": "1607012001",
    "district_id": "160701",
    "label": "Sungai Rebo",
    "value": "Sungai Rebo"
  },
  {
    "id": "1607012002",
    "district_id": "160701",
    "label": "Sungai Gerong",
    "value": "Sungai Gerong"
  },
  {
    "id": "1607012003",
    "district_id": "160701",
    "label": "Merah Mata",
    "value": "Merah Mata"
  },
  {
    "id": "1607012004",
    "district_id": "160701",
    "label": "Perajen",
    "value": "Perajen"
  },
  {
    "id": "1607012005",
    "district_id": "160701",
    "label": "Cinta Manis Lama",
    "value": "Cinta Manis Lama"
  },
  {
    "id": "1607012008",
    "district_id": "160701",
    "label": "Perambahan",
    "value": "Perambahan"
  },
  {
    "id": "1607012011",
    "district_id": "160701",
    "label": "Pematang Palas",
    "value": "Pematang Palas"
  },
  {
    "id": "1607012012",
    "district_id": "160701",
    "label": "Tirto Sari",
    "value": "Tirto Sari"
  },
  {
    "id": "1607012014",
    "district_id": "160701",
    "label": "Pulauborang",
    "value": "Pulauborang"
  },
  {
    "id": "1607012025",
    "district_id": "160701",
    "label": "Perambahan Baru",
    "value": "Perambahan Baru"
  },
  {
    "id": "1607012028",
    "district_id": "160701",
    "label": "Duren Ijo",
    "value": "Duren Ijo"
  },
  {
    "id": "1607022001",
    "district_id": "160702",
    "label": "Sungsang I",
    "value": "Sungsang I"
  },
  {
    "id": "1607022002",
    "district_id": "160702",
    "label": "Sungsang II",
    "value": "Sungsang II"
  },
  {
    "id": "1607022003",
    "district_id": "160702",
    "label": "Sungsang III",
    "value": "Sungsang III"
  },
  {
    "id": "1607022004",
    "district_id": "160702",
    "label": "Sungsang IV",
    "value": "Sungsang IV"
  },
  {
    "id": "1607022010",
    "district_id": "160702",
    "label": "Teluk Payo",
    "value": "Teluk Payo"
  },
  {
    "id": "1607022013",
    "district_id": "160702",
    "label": "Tanah Pilih",
    "value": "Tanah Pilih"
  },
  {
    "id": "1607022015",
    "district_id": "160702",
    "label": "Perajen Jaya",
    "value": "Perajen Jaya"
  },
  {
    "id": "1607022016",
    "district_id": "160702",
    "label": "Rimau Sungsang",
    "value": "Rimau Sungsang"
  },
  {
    "id": "1607022018",
    "district_id": "160702",
    "label": "Marga Sungsang",
    "value": "Marga Sungsang"
  },
  {
    "id": "1607022019",
    "district_id": "160702",
    "label": "Muara Sungsang",
    "value": "Muara Sungsang"
  },
  {
    "id": "1607031008",
    "district_id": "160703",
    "label": "Kayuara Kuning",
    "value": "Kayuara Kuning"
  },
  {
    "id": "1607031009",
    "district_id": "160703",
    "label": "Mulia Agung",
    "value": "Mulia Agung"
  },
  {
    "id": "1607031012",
    "district_id": "160703",
    "label": "Pangkalan Balai",
    "value": "Pangkalan Balai"
  },
  {
    "id": "1607031023",
    "district_id": "160703",
    "label": "Seterio",
    "value": "Seterio"
  },
  {
    "id": "1607031033",
    "district_id": "160703",
    "label": "Kedondong Raye",
    "value": "Kedondong Raye"
  },
  {
    "id": "1607032006",
    "district_id": "160703",
    "label": "Langkan",
    "value": "Langkan"
  },
  {
    "id": "1607032007",
    "district_id": "160703",
    "label": "Pangkalan Panji",
    "value": "Pangkalan Panji"
  },
  {
    "id": "1607032010",
    "district_id": "160703",
    "label": "Lubuk Saung",
    "value": "Lubuk Saung"
  },
  {
    "id": "1607032011",
    "district_id": "160703",
    "label": "Ujung Tanjung",
    "value": "Ujung Tanjung"
  },
  {
    "id": "1607032013",
    "district_id": "160703",
    "label": "Sidang Mas",
    "value": "Sidang Mas"
  },
  {
    "id": "1607032014",
    "district_id": "160703",
    "label": "Galang Tinggi",
    "value": "Galang Tinggi"
  },
  {
    "id": "1607032015",
    "district_id": "160703",
    "label": "Petaling",
    "value": "Petaling"
  },
  {
    "id": "1607032016",
    "district_id": "160703",
    "label": "Rimba Alai",
    "value": "Rimba Alai"
  },
  {
    "id": "1607032017",
    "district_id": "160703",
    "label": "Pelajau Ilir",
    "value": "Pelajau Ilir"
  },
  {
    "id": "1607032018",
    "district_id": "160703",
    "label": "Regan Agung",
    "value": "Regan Agung"
  },
  {
    "id": "1607032019",
    "district_id": "160703",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "1607032020",
    "district_id": "160703",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1607032021",
    "district_id": "160703",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1607032022",
    "district_id": "160703",
    "label": "Sri Bandung",
    "value": "Sri Bandung"
  },
  {
    "id": "1607032024",
    "district_id": "160703",
    "label": "Pelajau",
    "value": "Pelajau"
  },
  {
    "id": "1607032025",
    "district_id": "160703",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1607032026",
    "district_id": "160703",
    "label": "Terentang",
    "value": "Terentang"
  },
  {
    "id": "1607032028",
    "district_id": "160703",
    "label": "Tanjung Kepayang",
    "value": "Tanjung Kepayang"
  },
  {
    "id": "1607032034",
    "district_id": "160703",
    "label": "Sukaraja Baru",
    "value": "Sukaraja Baru"
  },
  {
    "id": "1607032037",
    "district_id": "160703",
    "label": "Terlangu",
    "value": "Terlangu"
  },
  {
    "id": "1607032038",
    "district_id": "160703",
    "label": "Rimba Balai",
    "value": "Rimba Balai"
  },
  {
    "id": "1607042002",
    "district_id": "160704",
    "label": "Teluk Betung",
    "value": "Teluk Betung"
  },
  {
    "id": "1607042004",
    "district_id": "160704",
    "label": "Mukut",
    "value": "Mukut"
  },
  {
    "id": "1607042006",
    "district_id": "160704",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "1607042007",
    "district_id": "160704",
    "label": "Dana Mulya",
    "value": "Dana Mulya"
  },
  {
    "id": "1607042008",
    "district_id": "160704",
    "label": "Wana Mukti",
    "value": "Wana Mukti"
  },
  {
    "id": "1607042009",
    "district_id": "160704",
    "label": "Budi Asih",
    "value": "Budi Asih"
  },
  {
    "id": "1607042013",
    "district_id": "160704",
    "label": "Rukun Makmur",
    "value": "Rukun Makmur"
  },
  {
    "id": "1607042014",
    "district_id": "160704",
    "label": "Nunggal Sari",
    "value": "Nunggal Sari"
  },
  {
    "id": "1607042017",
    "district_id": "160704",
    "label": "Majatra",
    "value": "Majatra"
  },
  {
    "id": "1607042018",
    "district_id": "160704",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1607042031",
    "district_id": "160704",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1607042032",
    "district_id": "160704",
    "label": "Rawa Banda",
    "value": "Rawa Banda"
  },
  {
    "id": "1607042037",
    "district_id": "160704",
    "label": "Senda Mukti",
    "value": "Senda Mukti"
  },
  {
    "id": "1607042038",
    "district_id": "160704",
    "label": "Tirta Mulya",
    "value": "Tirta Mulya"
  },
  {
    "id": "1607042039",
    "district_id": "160704",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1607042040",
    "district_id": "160704",
    "label": "Buana Murti",
    "value": "Buana Murti"
  },
  {
    "id": "1607042041",
    "district_id": "160704",
    "label": "Tabuan Asri",
    "value": "Tabuan Asri"
  },
  {
    "id": "1607051017",
    "district_id": "160705",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1607051021",
    "district_id": "160705",
    "label": "Rimba Asam",
    "value": "Rimba Asam"
  },
  {
    "id": "1607052002",
    "district_id": "160705",
    "label": "Pulau Rajak",
    "value": "Pulau Rajak"
  },
  {
    "id": "1607052003",
    "district_id": "160705",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "1607052004",
    "district_id": "160705",
    "label": "Sri Kembang",
    "value": "Sri Kembang"
  },
  {
    "id": "1607052005",
    "district_id": "160705",
    "label": "Lubuk Karet",
    "value": "Lubuk Karet"
  },
  {
    "id": "1607052014",
    "district_id": "160705",
    "label": "Taja Mulya",
    "value": "Taja Mulya"
  },
  {
    "id": "1607052015",
    "district_id": "160705",
    "label": "Taja Raya I",
    "value": "Taja Raya I"
  },
  {
    "id": "1607052016",
    "district_id": "160705",
    "label": "Taja Indah",
    "value": "Taja Indah"
  },
  {
    "id": "1607052018",
    "district_id": "160705",
    "label": "Taja Raya II",
    "value": "Taja Raya II"
  },
  {
    "id": "1607052020",
    "district_id": "160705",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1607061021",
    "district_id": "160706",
    "label": "Jakabaring Selatan",
    "value": "Jakabaring Selatan"
  },
  {
    "id": "1607062001",
    "district_id": "160706",
    "label": "Kebon Sahang",
    "value": "Kebon Sahang"
  },
  {
    "id": "1607062002",
    "district_id": "160706",
    "label": "Siju",
    "value": "Siju"
  },
  {
    "id": "1607062003",
    "district_id": "160706",
    "label": "Tanah Lembak",
    "value": "Tanah Lembak"
  },
  {
    "id": "1607062004",
    "district_id": "160706",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "1607062005",
    "district_id": "160706",
    "label": "Pelaju",
    "value": "Pelaju"
  },
  {
    "id": "1607062006",
    "district_id": "160706",
    "label": "Suka Pindah",
    "value": "Suka Pindah"
  },
  {
    "id": "1607062007",
    "district_id": "160706",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1607062008",
    "district_id": "160706",
    "label": "Tanjung Kerang",
    "value": "Tanjung Kerang"
  },
  {
    "id": "1607062009",
    "district_id": "160706",
    "label": "Rambutan",
    "value": "Rambutan"
  },
  {
    "id": "1607062010",
    "district_id": "160706",
    "label": "Tanjung Merbu",
    "value": "Tanjung Merbu"
  },
  {
    "id": "1607062011",
    "district_id": "160706",
    "label": "Sako",
    "value": "Sako"
  },
  {
    "id": "1607062012",
    "district_id": "160706",
    "label": "Gelebak Dalam",
    "value": "Gelebak Dalam"
  },
  {
    "id": "1607062013",
    "district_id": "160706",
    "label": "Menten",
    "value": "Menten"
  },
  {
    "id": "1607062014",
    "district_id": "160706",
    "label": "Pangkalan Gelebak",
    "value": "Pangkalan Gelebak"
  },
  {
    "id": "1607062015",
    "district_id": "160706",
    "label": "Sungai Dua",
    "value": "Sungai Dua"
  },
  {
    "id": "1607062016",
    "district_id": "160706",
    "label": "Sungaipinang",
    "value": "Sungaipinang"
  },
  {
    "id": "1607062017",
    "district_id": "160706",
    "label": "Sungai Kedukan",
    "value": "Sungai Kedukan"
  },
  {
    "id": "1607062019",
    "district_id": "160706",
    "label": "Durian Gadis",
    "value": "Durian Gadis"
  },
  {
    "id": "1607062020",
    "district_id": "160706",
    "label": "Pulau Parang",
    "value": "Pulau Parang"
  },
  {
    "id": "1607072001",
    "district_id": "160707",
    "label": "Muara Padang",
    "value": "Muara Padang"
  },
  {
    "id": "1607072002",
    "district_id": "160707",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1607072003",
    "district_id": "160707",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1607072006",
    "district_id": "160707",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1607072007",
    "district_id": "160707",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1607072008",
    "district_id": "160707",
    "label": "Air Gading",
    "value": "Air Gading"
  },
  {
    "id": "1607072009",
    "district_id": "160707",
    "label": "Tirto Raharjo",
    "value": "Tirto Raharjo"
  },
  {
    "id": "1607072010",
    "district_id": "160707",
    "label": "Daya Utama",
    "value": "Daya Utama"
  },
  {
    "id": "1607072011",
    "district_id": "160707",
    "label": "Daya Makmur",
    "value": "Daya Makmur"
  },
  {
    "id": "1607072012",
    "district_id": "160707",
    "label": "Sido Mulyo 18",
    "value": "Sido Mulyo 18"
  },
  {
    "id": "1607072013",
    "district_id": "160707",
    "label": "Sido Rejo",
    "value": "Sido Rejo"
  },
  {
    "id": "1607072018",
    "district_id": "160707",
    "label": "Sido Mulyo 20",
    "value": "Sido Mulyo 20"
  },
  {
    "id": "1607072037",
    "district_id": "160707",
    "label": "Margo Mulyo 20",
    "value": "Margo Mulyo 20"
  },
  {
    "id": "1607072038",
    "district_id": "160707",
    "label": "Margo Sugihan",
    "value": "Margo Sugihan"
  },
  {
    "id": "1607072042",
    "district_id": "160707",
    "label": "Tirta Jaya",
    "value": "Tirta Jaya"
  },
  {
    "id": "1607082007",
    "district_id": "160708",
    "label": "Marga Rahayu",
    "value": "Marga Rahayu"
  },
  {
    "id": "1607082008",
    "district_id": "160708",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1607082009",
    "district_id": "160708",
    "label": "Sumber Hidup",
    "value": "Sumber Hidup"
  },
  {
    "id": "1607082010",
    "district_id": "160708",
    "label": "Telang Rejo",
    "value": "Telang Rejo"
  },
  {
    "id": "1607082011",
    "district_id": "160708",
    "label": "Telang Jaya",
    "value": "Telang Jaya"
  },
  {
    "id": "1607082013",
    "district_id": "160708",
    "label": "Telang Makmur",
    "value": "Telang Makmur"
  },
  {
    "id": "1607082014",
    "district_id": "160708",
    "label": "Telang Karya",
    "value": "Telang Karya"
  },
  {
    "id": "1607082015",
    "district_id": "160708",
    "label": "Panca Mukti",
    "value": "Panca Mukti"
  },
  {
    "id": "1607082016",
    "district_id": "160708",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "1607082019",
    "district_id": "160708",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1607082024",
    "district_id": "160708",
    "label": "Talang Indah",
    "value": "Talang Indah"
  },
  {
    "id": "1607082025",
    "district_id": "160708",
    "label": "Mekar Mukti",
    "value": "Mekar Mukti"
  },
  {
    "id": "1607082026",
    "district_id": "160708",
    "label": "Upang Jaya",
    "value": "Upang Jaya"
  },
  {
    "id": "1607082027",
    "district_id": "160708",
    "label": "Upang Karya",
    "value": "Upang Karya"
  },
  {
    "id": "1607082028",
    "district_id": "160708",
    "label": "Upang Cemara",
    "value": "Upang Cemara"
  },
  {
    "id": "1607082029",
    "district_id": "160708",
    "label": "Upang Ceria",
    "value": "Upang Ceria"
  },
  {
    "id": "1607091012",
    "district_id": "160709",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "1607092002",
    "district_id": "160709",
    "label": "Delta Upang",
    "value": "Delta Upang"
  },
  {
    "id": "1607092006",
    "district_id": "160709",
    "label": "Pendowo Harjo",
    "value": "Pendowo Harjo"
  },
  {
    "id": "1607092007",
    "district_id": "160709",
    "label": "Pangestu",
    "value": "Pangestu"
  },
  {
    "id": "1607092008",
    "district_id": "160709",
    "label": "Tirta Kencana",
    "value": "Tirta Kencana"
  },
  {
    "id": "1607092010",
    "district_id": "160709",
    "label": "Upang Makmur",
    "value": "Upang Makmur"
  },
  {
    "id": "1607092013",
    "district_id": "160709",
    "label": "Upang Mulya",
    "value": "Upang Mulya"
  },
  {
    "id": "1607092017",
    "district_id": "160709",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1607092019",
    "district_id": "160709",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "1607092020",
    "district_id": "160709",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1607092021",
    "district_id": "160709",
    "label": "Sungai Semut",
    "value": "Sungai Semut"
  },
  {
    "id": "1607092022",
    "district_id": "160709",
    "label": "Muara Baru",
    "value": "Muara Baru"
  },
  {
    "id": "1607101002",
    "district_id": "160710",
    "label": "Air Batu",
    "value": "Air Batu"
  },
  {
    "id": "1607101003",
    "district_id": "160710",
    "label": "Sukomoro",
    "value": "Sukomoro"
  },
  {
    "id": "1607101004",
    "district_id": "160710",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1607101006",
    "district_id": "160710",
    "label": "Kenten",
    "value": "Kenten"
  },
  {
    "id": "1607101022",
    "district_id": "160710",
    "label": "Tanah Mas",
    "value": "Tanah Mas"
  },
  {
    "id": "1607101023",
    "district_id": "160710",
    "label": "Talang Keramat",
    "value": "Talang Keramat"
  },
  {
    "id": "1607102001",
    "district_id": "160710",
    "label": "Sungai Rengit",
    "value": "Sungai Rengit"
  },
  {
    "id": "1607102005",
    "district_id": "160710",
    "label": "Pangkalan Benteng",
    "value": "Pangkalan Benteng"
  },
  {
    "id": "1607102007",
    "district_id": "160710",
    "label": "Gasing",
    "value": "Gasing"
  },
  {
    "id": "1607102019",
    "district_id": "160710",
    "label": "Talang Buluh",
    "value": "Talang Buluh"
  },
  {
    "id": "1607102020",
    "district_id": "160710",
    "label": "Sungai Rengat Murni",
    "value": "Sungai Rengat Murni"
  },
  {
    "id": "1607102021",
    "district_id": "160710",
    "label": "Kenten Laut",
    "value": "Kenten Laut"
  },
  {
    "id": "1607112001",
    "district_id": "160711",
    "label": "Muara Abab",
    "value": "Muara Abab"
  },
  {
    "id": "1607112002",
    "district_id": "160711",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "1607112003",
    "district_id": "160711",
    "label": "Tanjung Tiga",
    "value": "Tanjung Tiga"
  },
  {
    "id": "1607112004",
    "district_id": "160711",
    "label": "Rantau Bayur",
    "value": "Rantau Bayur"
  },
  {
    "id": "1607112005",
    "district_id": "160711",
    "label": "Pagar Bulan",
    "value": "Pagar Bulan"
  },
  {
    "id": "1607112006",
    "district_id": "160711",
    "label": "Kemang Bejalu",
    "value": "Kemang Bejalu"
  },
  {
    "id": "1607112007",
    "district_id": "160711",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1607112008",
    "district_id": "160711",
    "label": "Srijaya",
    "value": "Srijaya"
  },
  {
    "id": "1607112009",
    "district_id": "160711",
    "label": "Sejagung",
    "value": "Sejagung"
  },
  {
    "id": "1607112010",
    "district_id": "160711",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1607112011",
    "district_id": "160711",
    "label": "Semuntul",
    "value": "Semuntul"
  },
  {
    "id": "1607112012",
    "district_id": "160711",
    "label": "Lebung",
    "value": "Lebung"
  },
  {
    "id": "1607112013",
    "district_id": "160711",
    "label": "Lubuk Rengas",
    "value": "Lubuk Rengas"
  },
  {
    "id": "1607112014",
    "district_id": "160711",
    "label": "Paldas",
    "value": "Paldas"
  },
  {
    "id": "1607112015",
    "district_id": "160711",
    "label": "Tebing Abang",
    "value": "Tebing Abang"
  },
  {
    "id": "1607112016",
    "district_id": "160711",
    "label": "Talang Kemang",
    "value": "Talang Kemang"
  },
  {
    "id": "1607112017",
    "district_id": "160711",
    "label": "Rantau Harapan",
    "value": "Rantau Harapan"
  },
  {
    "id": "1607112018",
    "district_id": "160711",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1607112019",
    "district_id": "160711",
    "label": "Sungai Naik",
    "value": "Sungai Naik"
  },
  {
    "id": "1607112020",
    "district_id": "160711",
    "label": "Sungai Lilin",
    "value": "Sungai Lilin"
  },
  {
    "id": "1607112021",
    "district_id": "160711",
    "label": "Sukarela",
    "value": "Sukarela"
  },
  {
    "id": "1607122001",
    "district_id": "160712",
    "label": "Telang Sari",
    "value": "Telang Sari"
  },
  {
    "id": "1607122002",
    "district_id": "160712",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "1607122003",
    "district_id": "160712",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1607122004",
    "district_id": "160712",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1607122005",
    "district_id": "160712",
    "label": "Sebalik",
    "value": "Sebalik"
  },
  {
    "id": "1607122006",
    "district_id": "160712",
    "label": "Tanjung Lago",
    "value": "Tanjung Lago"
  },
  {
    "id": "1607122007",
    "district_id": "160712",
    "label": "Kuala Puntian",
    "value": "Kuala Puntian"
  },
  {
    "id": "1607122008",
    "district_id": "160712",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "1607122009",
    "district_id": "160712",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "1607122010",
    "district_id": "160712",
    "label": "Banyu Urip",
    "value": "Banyu Urip"
  },
  {
    "id": "1607122011",
    "district_id": "160712",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1607122012",
    "district_id": "160712",
    "label": "Sumber Mekar Mukti",
    "value": "Sumber Mekar Mukti"
  },
  {
    "id": "1607122013",
    "district_id": "160712",
    "label": "Muara Sugih",
    "value": "Muara Sugih"
  },
  {
    "id": "1607122014",
    "district_id": "160712",
    "label": "Manggar Raya",
    "value": "Manggar Raya"
  },
  {
    "id": "1607122015",
    "district_id": "160712",
    "label": "Bunga Karang",
    "value": "Bunga Karang"
  },
  {
    "id": "1607132001",
    "district_id": "160713",
    "label": "Margo Mulyo 16",
    "value": "Margo Mulyo 16"
  },
  {
    "id": "1607132002",
    "district_id": "160713",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1607132003",
    "district_id": "160713",
    "label": "Daya Murni",
    "value": "Daya Murni"
  },
  {
    "id": "1607132004",
    "district_id": "160713",
    "label": "Daya Bangun Harjo",
    "value": "Daya Bangun Harjo"
  },
  {
    "id": "1607132005",
    "district_id": "160713",
    "label": "Sumber Mulyo",
    "value": "Sumber Mulyo"
  },
  {
    "id": "1607132006",
    "district_id": "160713",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1607132007",
    "district_id": "160713",
    "label": "Indrapura",
    "value": "Indrapura"
  },
  {
    "id": "1607132008",
    "district_id": "160713",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "1607132009",
    "district_id": "160713",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "1607132010",
    "district_id": "160713",
    "label": "Tirta Harja",
    "value": "Tirta Harja"
  },
  {
    "id": "1607132011",
    "district_id": "160713",
    "label": "Tirta Mulya",
    "value": "Tirta Mulya"
  },
  {
    "id": "1607132012",
    "district_id": "160713",
    "label": "Daya Kesuma",
    "value": "Daya Kesuma"
  },
  {
    "id": "1607132013",
    "district_id": "160713",
    "label": "Margo Rukun",
    "value": "Margo Rukun"
  },
  {
    "id": "1607132014",
    "district_id": "160713",
    "label": "Ganesha Mukti",
    "value": "Ganesha Mukti"
  },
  {
    "id": "1607132015",
    "district_id": "160713",
    "label": "Timbul Jaya",
    "value": "Timbul Jaya"
  },
  {
    "id": "1607132016",
    "district_id": "160713",
    "label": "Jalur Mulya",
    "value": "Jalur Mulya"
  },
  {
    "id": "1607132017",
    "district_id": "160713",
    "label": "Gilirang",
    "value": "Gilirang"
  },
  {
    "id": "1607132018",
    "district_id": "160713",
    "label": "Juru Taro",
    "value": "Juru Taro"
  },
  {
    "id": "1607132019",
    "district_id": "160713",
    "label": "Kuala Sugihan",
    "value": "Kuala Sugihan"
  },
  {
    "id": "1607132020",
    "district_id": "160713",
    "label": "Beringin Agung",
    "value": "Beringin Agung"
  },
  {
    "id": "1607132021",
    "district_id": "160713",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1607132022",
    "district_id": "160713",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1607142001",
    "district_id": "160714",
    "label": "Air Solok Batu",
    "value": "Air Solok Batu"
  },
  {
    "id": "1607142002",
    "district_id": "160714",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1607142003",
    "district_id": "160714",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "1607142004",
    "district_id": "160714",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1607142005",
    "district_id": "160714",
    "label": "Bintaran",
    "value": "Bintaran"
  },
  {
    "id": "1607142006",
    "district_id": "160714",
    "label": "Damar Wulan",
    "value": "Damar Wulan"
  },
  {
    "id": "1607142007",
    "district_id": "160714",
    "label": "Enggal Rejo",
    "value": "Enggal Rejo"
  },
  {
    "id": "1607142008",
    "district_id": "160714",
    "label": "Saleh Jaya",
    "value": "Saleh Jaya"
  },
  {
    "id": "1607142009",
    "district_id": "160714",
    "label": "Saleh Agung",
    "value": "Saleh Agung"
  },
  {
    "id": "1607142010",
    "district_id": "160714",
    "label": "Saleh Mulya",
    "value": "Saleh Mulya"
  },
  {
    "id": "1607142011",
    "district_id": "160714",
    "label": "Saleh Mukti",
    "value": "Saleh Mukti"
  },
  {
    "id": "1607142012",
    "district_id": "160714",
    "label": "Saleh Makmur",
    "value": "Saleh Makmur"
  },
  {
    "id": "1607142013",
    "district_id": "160714",
    "label": "Upang",
    "value": "Upang"
  },
  {
    "id": "1607142014",
    "district_id": "160714",
    "label": "Upang Marga",
    "value": "Upang Marga"
  },
  {
    "id": "1607152001",
    "district_id": "160715",
    "label": "Bentayan",
    "value": "Bentayan"
  },
  {
    "id": "1607152002",
    "district_id": "160715",
    "label": "Teluk Tenggulang",
    "value": "Teluk Tenggulang"
  },
  {
    "id": "1607152004",
    "district_id": "160715",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1607152005",
    "district_id": "160715",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1607152006",
    "district_id": "160715",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1607152007",
    "district_id": "160715",
    "label": "Karang Asem",
    "value": "Karang Asem"
  },
  {
    "id": "1607152008",
    "district_id": "160715",
    "label": "Marga Rahayu",
    "value": "Marga Rahayu"
  },
  {
    "id": "1607152009",
    "district_id": "160715",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1607152010",
    "district_id": "160715",
    "label": "Keluang",
    "value": "Keluang"
  },
  {
    "id": "1607152011",
    "district_id": "160715",
    "label": "Bumi Serdang",
    "value": "Bumi Serdang"
  },
  {
    "id": "1607152012",
    "district_id": "160715",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "1607152013",
    "district_id": "160715",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "1607152014",
    "district_id": "160715",
    "label": "Panca Mulya",
    "value": "Panca Mulya"
  },
  {
    "id": "1607152015",
    "district_id": "160715",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1607162001",
    "district_id": "160716",
    "label": "Talang Ipuh",
    "value": "Talang Ipuh"
  },
  {
    "id": "1607162002",
    "district_id": "160716",
    "label": "Durian Daun",
    "value": "Durian Daun"
  },
  {
    "id": "1607162003",
    "district_id": "160716",
    "label": "Lubuk Lancang",
    "value": "Lubuk Lancang"
  },
  {
    "id": "1607162004",
    "district_id": "160716",
    "label": "Biyuku",
    "value": "Biyuku"
  },
  {
    "id": "1607162005",
    "district_id": "160716",
    "label": "Bengkuang",
    "value": "Bengkuang"
  },
  {
    "id": "1607162006",
    "district_id": "160716",
    "label": "Rimba Terap",
    "value": "Rimba Terap"
  },
  {
    "id": "1607162007",
    "district_id": "160716",
    "label": "Sedang",
    "value": "Sedang"
  },
  {
    "id": "1607162008",
    "district_id": "160716",
    "label": "Tanjung Laut",
    "value": "Tanjung Laut"
  },
  {
    "id": "1607162009",
    "district_id": "160716",
    "label": "Air Senggeris",
    "value": "Air Senggeris"
  },
  {
    "id": "1607162010",
    "district_id": "160716",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "1607162011",
    "district_id": "160716",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1607172001",
    "district_id": "160717",
    "label": "Mainan",
    "value": "Mainan"
  },
  {
    "id": "1607172002",
    "district_id": "160717",
    "label": "Santan Sari",
    "value": "Santan Sari"
  },
  {
    "id": "1607172003",
    "district_id": "160717",
    "label": "Limau",
    "value": "Limau"
  },
  {
    "id": "1607172004",
    "district_id": "160717",
    "label": "Rejodadi",
    "value": "Rejodadi"
  },
  {
    "id": "1607172005",
    "district_id": "160717",
    "label": "Lalang Sembawa",
    "value": "Lalang Sembawa"
  },
  {
    "id": "1607172006",
    "district_id": "160717",
    "label": "Muara Damai",
    "value": "Muara Damai"
  },
  {
    "id": "1607172007",
    "district_id": "160717",
    "label": "Pulau Harapan",
    "value": "Pulau Harapan"
  },
  {
    "id": "1607172008",
    "district_id": "160717",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1607172009",
    "district_id": "160717",
    "label": "Limbang Mulia",
    "value": "Limbang Mulia"
  },
  {
    "id": "1607172010",
    "district_id": "160717",
    "label": "Sako Makmur",
    "value": "Sako Makmur"
  },
  {
    "id": "1607172011",
    "district_id": "160717",
    "label": "Pulau Muning",
    "value": "Pulau Muning"
  },
  {
    "id": "1607182001",
    "district_id": "160718",
    "label": "Terusan Dalam",
    "value": "Terusan Dalam"
  },
  {
    "id": "1607182002",
    "district_id": "160718",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1607182003",
    "district_id": "160718",
    "label": "Muara Telang",
    "value": "Muara Telang"
  },
  {
    "id": "1607182004",
    "district_id": "160718",
    "label": "Terusan Tengah",
    "value": "Terusan Tengah"
  },
  {
    "id": "1607182005",
    "district_id": "160718",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1607182006",
    "district_id": "160718",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "1607182007",
    "district_id": "160718",
    "label": "Muara Telang Marga",
    "value": "Muara Telang Marga"
  },
  {
    "id": "1607182008",
    "district_id": "160718",
    "label": "Terusan Muara",
    "value": "Terusan Muara"
  },
  {
    "id": "1607182009",
    "district_id": "160718",
    "label": "Talang Lubuk",
    "value": "Talang Lubuk"
  },
  {
    "id": "1607182010",
    "district_id": "160718",
    "label": "Sri Tiga",
    "value": "Sri Tiga"
  },
  {
    "id": "1607192001",
    "district_id": "160719",
    "label": "Cinta Manis Baru",
    "value": "Cinta Manis Baru"
  },
  {
    "id": "1607192002",
    "district_id": "160719",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1607192003",
    "district_id": "160719",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1607192004",
    "district_id": "160719",
    "label": "Nusa Makmur",
    "value": "Nusa Makmur"
  },
  {
    "id": "1607192005",
    "district_id": "160719",
    "label": "Rimba Jaya",
    "value": "Rimba Jaya"
  },
  {
    "id": "1607192006",
    "district_id": "160719",
    "label": "Teluk Tenggirik",
    "value": "Teluk Tenggirik"
  },
  {
    "id": "1607192007",
    "district_id": "160719",
    "label": "Sebubus",
    "value": "Sebubus"
  },
  {
    "id": "1607192008",
    "district_id": "160719",
    "label": "Padang Rejo",
    "value": "Padang Rejo"
  },
  {
    "id": "1607192009",
    "district_id": "160719",
    "label": "Kumbang Padang",
    "value": "Kumbang Padang"
  },
  {
    "id": "1607192010",
    "district_id": "160719",
    "label": "Muara Baru",
    "value": "Muara Baru"
  },
  {
    "id": "1607192011",
    "district_id": "160719",
    "label": "Tirta Makmur",
    "value": "Tirta Makmur"
  },
  {
    "id": "1607192012",
    "district_id": "160719",
    "label": "Panca Mulya",
    "value": "Panca Mulya"
  },
  {
    "id": "1607192013",
    "district_id": "160719",
    "label": "Budi Mulya",
    "value": "Budi Mulya"
  },
  {
    "id": "1607192014",
    "district_id": "160719",
    "label": "Panca Desa",
    "value": "Panca Desa"
  },
  {
    "id": "1607192015",
    "district_id": "160719",
    "label": "Air Kumbang Bakti",
    "value": "Air Kumbang Bakti"
  },
  {
    "id": "1607192016",
    "district_id": "160719",
    "label": "Sebokor",
    "value": "Sebokor"
  },
  {
    "id": "1607202001",
    "district_id": "160720",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1607202002",
    "district_id": "160720",
    "label": "Maju Ria",
    "value": "Maju Ria"
  },
  {
    "id": "1607202003",
    "district_id": "160720",
    "label": "Sri Agung",
    "value": "Sri Agung"
  },
  {
    "id": "1607202004",
    "district_id": "160720",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "1607202005",
    "district_id": "160720",
    "label": "Tabala Jaya",
    "value": "Tabala Jaya"
  },
  {
    "id": "1607202006",
    "district_id": "160720",
    "label": "Jati Sari",
    "value": "Jati Sari"
  },
  {
    "id": "1607202007",
    "district_id": "160720",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1607212001",
    "district_id": "160721",
    "label": "Penuguan",
    "value": "Penuguan"
  },
  {
    "id": "1607212002",
    "district_id": "160721",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1607212003",
    "district_id": "160721",
    "label": "Sumber Mukti",
    "value": "Sumber Mukti"
  },
  {
    "id": "1607212004",
    "district_id": "160721",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1607212005",
    "district_id": "160721",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "1607212006",
    "district_id": "160721",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1607212007",
    "district_id": "160721",
    "label": "Songo Makmur",
    "value": "Songo Makmur"
  },
  {
    "id": "1607212008",
    "district_id": "160721",
    "label": "Karang Manunggal",
    "value": "Karang Manunggal"
  },
  {
    "id": "1607212009",
    "district_id": "160721",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1607212010",
    "district_id": "160721",
    "label": "Ringin Harjo",
    "value": "Ringin Harjo"
  },
  {
    "id": "1607212011",
    "district_id": "160721",
    "label": "Bumi Rejo",
    "value": "Bumi Rejo"
  },
  {
    "id": "1607212012",
    "district_id": "160721",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "1608011007",
    "district_id": "160801",
    "label": "Dusun Martapura",
    "value": "Dusun Martapura"
  },
  {
    "id": "1608011008",
    "district_id": "160801",
    "label": "Pasar Martapura",
    "value": "Pasar Martapura"
  },
  {
    "id": "1608011014",
    "district_id": "160801",
    "label": "Paku Sengkunyit",
    "value": "Paku Sengkunyit"
  },
  {
    "id": "1608011016",
    "district_id": "160801",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1608011017",
    "district_id": "160801",
    "label": "Sungai Tuha Jaya",
    "value": "Sungai Tuha Jaya"
  },
  {
    "id": "1608011018",
    "district_id": "160801",
    "label": "Terukis Rahayu",
    "value": "Terukis Rahayu"
  },
  {
    "id": "1608011019",
    "district_id": "160801",
    "label": "Veteran Jaya",
    "value": "Veteran Jaya"
  },
  {
    "id": "1608012006",
    "district_id": "160801",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1608012009",
    "district_id": "160801",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1608012010",
    "district_id": "160801",
    "label": "Keromongan",
    "value": "Keromongan"
  },
  {
    "id": "1608012011",
    "district_id": "160801",
    "label": "Perjaya",
    "value": "Perjaya"
  },
  {
    "id": "1608012015",
    "district_id": "160801",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "1608012020",
    "district_id": "160801",
    "label": "Kotabaru Barat",
    "value": "Kotabaru Barat"
  },
  {
    "id": "1608012021",
    "district_id": "160801",
    "label": "Kotabaru Selatan",
    "value": "Kotabaru Selatan"
  },
  {
    "id": "1608012022",
    "district_id": "160801",
    "label": "Perjaya Barat",
    "value": "Perjaya Barat"
  },
  {
    "id": "1608012023",
    "district_id": "160801",
    "label": "Tanjung Kemala Barat",
    "value": "Tanjung Kemala Barat"
  },
  {
    "id": "1608022001",
    "district_id": "160802",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1608022002",
    "district_id": "160802",
    "label": "Kurungan Nyawa",
    "value": "Kurungan Nyawa"
  },
  {
    "id": "1608022003",
    "district_id": "160802",
    "label": "Pisang Jaya",
    "value": "Pisang Jaya"
  },
  {
    "id": "1608022004",
    "district_id": "160802",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1608022005",
    "district_id": "160802",
    "label": "Tebat Jaya",
    "value": "Tebat Jaya"
  },
  {
    "id": "1608022006",
    "district_id": "160802",
    "label": "Sridadi",
    "value": "Sridadi"
  },
  {
    "id": "1608022009",
    "district_id": "160802",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1608022010",
    "district_id": "160802",
    "label": "Way Halom",
    "value": "Way Halom"
  },
  {
    "id": "1608022031",
    "district_id": "160802",
    "label": "Kurungan Nyawa I",
    "value": "Kurungan Nyawa I"
  },
  {
    "id": "1608022032",
    "district_id": "160802",
    "label": "Kurungan Nyawa II",
    "value": "Kurungan Nyawa II"
  },
  {
    "id": "1608022033",
    "district_id": "160802",
    "label": "Kurungan Nyawa III",
    "value": "Kurungan Nyawa III"
  },
  {
    "id": "1608022034",
    "district_id": "160802",
    "label": "Cipta Muda",
    "value": "Cipta Muda"
  },
  {
    "id": "1608022035",
    "district_id": "160802",
    "label": "Aman Jaya",
    "value": "Aman Jaya"
  },
  {
    "id": "1608022036",
    "district_id": "160802",
    "label": "Sukaraja Tuha",
    "value": "Sukaraja Tuha"
  },
  {
    "id": "1608022037",
    "district_id": "160802",
    "label": "Ganjar Agung",
    "value": "Ganjar Agung"
  },
  {
    "id": "1608022038",
    "district_id": "160802",
    "label": "Mulyo Agung",
    "value": "Mulyo Agung"
  },
  {
    "id": "1608022039",
    "district_id": "160802",
    "label": "Muda Sentosa",
    "value": "Muda Sentosa"
  },
  {
    "id": "1608032001",
    "district_id": "160803",
    "label": "Sido Rahayu",
    "value": "Sido Rahayu"
  },
  {
    "id": "1608032002",
    "district_id": "160803",
    "label": "Tawang Rejo",
    "value": "Tawang Rejo"
  },
  {
    "id": "1608032006",
    "district_id": "160803",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1608032007",
    "district_id": "160803",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1608032008",
    "district_id": "160803",
    "label": "Harjo Winangun",
    "value": "Harjo Winangun"
  },
  {
    "id": "1608032009",
    "district_id": "160803",
    "label": "Pujo Rahayu",
    "value": "Pujo Rahayu"
  },
  {
    "id": "1608032010",
    "district_id": "160803",
    "label": "Triyoso",
    "value": "Triyoso"
  },
  {
    "id": "1608032011",
    "district_id": "160803",
    "label": "Sumber Suko",
    "value": "Sumber Suko"
  },
  {
    "id": "1608032013",
    "district_id": "160803",
    "label": "Suko Sari",
    "value": "Suko Sari"
  },
  {
    "id": "1608032014",
    "district_id": "160803",
    "label": "Gumawang",
    "value": "Gumawang"
  },
  {
    "id": "1608032015",
    "district_id": "160803",
    "label": "Bedilan",
    "value": "Bedilan"
  },
  {
    "id": "1608032016",
    "district_id": "160803",
    "label": "Tegal Rejo",
    "value": "Tegal Rejo"
  },
  {
    "id": "1608032017",
    "district_id": "160803",
    "label": "Sido Gede",
    "value": "Sido Gede"
  },
  {
    "id": "1608032018",
    "district_id": "160803",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1608032021",
    "district_id": "160803",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1608032022",
    "district_id": "160803",
    "label": "Serbaguna",
    "value": "Serbaguna"
  },
  {
    "id": "1608032023",
    "district_id": "160803",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1608032024",
    "district_id": "160803",
    "label": "Gedung Rejo",
    "value": "Gedung Rejo"
  },
  {
    "id": "1608032025",
    "district_id": "160803",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1608032026",
    "district_id": "160803",
    "label": "Gunung Mas",
    "value": "Gunung Mas"
  },
  {
    "id": "1608032027",
    "district_id": "160803",
    "label": "Sumbersuko Jaya",
    "value": "Sumbersuko Jaya"
  },
  {
    "id": "1608032028",
    "district_id": "160803",
    "label": "Karang Kemiri",
    "value": "Karang Kemiri"
  },
  {
    "id": "1608032029",
    "district_id": "160803",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "1608042001",
    "district_id": "160804",
    "label": "Gunung Batu",
    "value": "Gunung Batu"
  },
  {
    "id": "1608042010",
    "district_id": "160804",
    "label": "Ulak Baru",
    "value": "Ulak Baru"
  },
  {
    "id": "1608042011",
    "district_id": "160804",
    "label": "Gunung Jati",
    "value": "Gunung Jati"
  },
  {
    "id": "1608042012",
    "district_id": "160804",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1608042013",
    "district_id": "160804",
    "label": "Negeri Sakti",
    "value": "Negeri Sakti"
  },
  {
    "id": "1608042014",
    "district_id": "160804",
    "label": "Campang Tiga Hulu",
    "value": "Campang Tiga Hulu"
  },
  {
    "id": "1608042015",
    "district_id": "160804",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1608042016",
    "district_id": "160804",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "1608042017",
    "district_id": "160804",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "1608042018",
    "district_id": "160804",
    "label": "Campang Tiga Ilir",
    "value": "Campang Tiga Ilir"
  },
  {
    "id": "1608042019",
    "district_id": "160804",
    "label": "Maluai Indah",
    "value": "Maluai Indah"
  },
  {
    "id": "1608042020",
    "district_id": "160804",
    "label": "Harisan Jaya",
    "value": "Harisan Jaya"
  },
  {
    "id": "1608042021",
    "district_id": "160804",
    "label": "Campang Tiga Jaya",
    "value": "Campang Tiga Jaya"
  },
  {
    "id": "1608052001",
    "district_id": "160805",
    "label": "Negeri Pakuan",
    "value": "Negeri Pakuan"
  },
  {
    "id": "1608052002",
    "district_id": "160805",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1608052003",
    "district_id": "160805",
    "label": "Banu Mas",
    "value": "Banu Mas"
  },
  {
    "id": "1608052004",
    "district_id": "160805",
    "label": "Banu Ayu",
    "value": "Banu Ayu"
  },
  {
    "id": "1608052005",
    "district_id": "160805",
    "label": "Pulau Negara",
    "value": "Pulau Negara"
  },
  {
    "id": "1608052006",
    "district_id": "160805",
    "label": "Saung Dadi",
    "value": "Saung Dadi"
  },
  {
    "id": "1608052007",
    "district_id": "160805",
    "label": "Trantang Sakti",
    "value": "Trantang Sakti"
  },
  {
    "id": "1608052008",
    "district_id": "160805",
    "label": "Pemetung Besuki",
    "value": "Pemetung Besuki"
  },
  {
    "id": "1608052009",
    "district_id": "160805",
    "label": "Pahang Asri",
    "value": "Pahang Asri"
  },
  {
    "id": "1608052010",
    "district_id": "160805",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "1608052011",
    "district_id": "160805",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1608052012",
    "district_id": "160805",
    "label": "Negeri Agung Jaya",
    "value": "Negeri Agung Jaya"
  },
  {
    "id": "1608052013",
    "district_id": "160805",
    "label": "Bantan Pelita",
    "value": "Bantan Pelita"
  },
  {
    "id": "1608062001",
    "district_id": "160806",
    "label": "Karang Negara",
    "value": "Karang Negara"
  },
  {
    "id": "1608062002",
    "district_id": "160806",
    "label": "Riang Bandung",
    "value": "Riang Bandung"
  },
  {
    "id": "1608062005",
    "district_id": "160806",
    "label": "Kota Negara",
    "value": "Kota Negara"
  },
  {
    "id": "1608062006",
    "district_id": "160806",
    "label": "Karta Negara",
    "value": "Karta Negara"
  },
  {
    "id": "1608062007",
    "district_id": "160806",
    "label": "Pandan Agung",
    "value": "Pandan Agung"
  },
  {
    "id": "1608062009",
    "district_id": "160806",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1608062010",
    "district_id": "160806",
    "label": "Margo Tani",
    "value": "Margo Tani"
  },
  {
    "id": "1608062011",
    "district_id": "160806",
    "label": "Jati Mulya II",
    "value": "Jati Mulya II"
  },
  {
    "id": "1608062012",
    "district_id": "160806",
    "label": "Pandan Jaya",
    "value": "Pandan Jaya"
  },
  {
    "id": "1608062014",
    "district_id": "160806",
    "label": "Rasuan Baru",
    "value": "Rasuan Baru"
  },
  {
    "id": "1608062020",
    "district_id": "160806",
    "label": "Banbanrejo",
    "value": "Banbanrejo"
  },
  {
    "id": "1608062021",
    "district_id": "160806",
    "label": "Suka Negara",
    "value": "Suka Negara"
  },
  {
    "id": "1608062022",
    "district_id": "160806",
    "label": "Srikencana",
    "value": "Srikencana"
  },
  {
    "id": "1608062023",
    "district_id": "160806",
    "label": "Dadimulyo",
    "value": "Dadimulyo"
  },
  {
    "id": "1608062024",
    "district_id": "160806",
    "label": "Riang Bandung Ilir",
    "value": "Riang Bandung Ilir"
  },
  {
    "id": "1608062025",
    "district_id": "160806",
    "label": "Margotani II",
    "value": "Margotani II"
  },
  {
    "id": "1608062026",
    "district_id": "160806",
    "label": "Kota Negara Timur",
    "value": "Kota Negara Timur"
  },
  {
    "id": "1608062027",
    "district_id": "160806",
    "label": "Talang Giring",
    "value": "Talang Giring"
  },
  {
    "id": "1608062028",
    "district_id": "160806",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "1608072002",
    "district_id": "160807",
    "label": "Mendayun",
    "value": "Mendayun"
  },
  {
    "id": "1608072003",
    "district_id": "160807",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1608072004",
    "district_id": "160807",
    "label": "Karta Mulya",
    "value": "Karta Mulya"
  },
  {
    "id": "1608072007",
    "district_id": "160807",
    "label": "SP. Kerta Mulya",
    "value": "SP. Kerta Mulya"
  },
  {
    "id": "1608072014",
    "district_id": "160807",
    "label": "Harjo Mulyo",
    "value": "Harjo Mulyo"
  },
  {
    "id": "1608072015",
    "district_id": "160807",
    "label": "Jaya Bakti",
    "value": "Jaya Bakti"
  },
  {
    "id": "1608072016",
    "district_id": "160807",
    "label": "Rasuan",
    "value": "Rasuan"
  },
  {
    "id": "1608072017",
    "district_id": "160807",
    "label": "Mengulak",
    "value": "Mengulak"
  },
  {
    "id": "1608072019",
    "district_id": "160807",
    "label": "Jati Sari",
    "value": "Jati Sari"
  },
  {
    "id": "1608072021",
    "district_id": "160807",
    "label": "Tri Dadi",
    "value": "Tri Dadi"
  },
  {
    "id": "1608072022",
    "district_id": "160807",
    "label": "Agung Jati",
    "value": "Agung Jati"
  },
  {
    "id": "1608072023",
    "district_id": "160807",
    "label": "Rasuan Darat",
    "value": "Rasuan Darat"
  },
  {
    "id": "1608072024",
    "district_id": "160807",
    "label": "Harjo Mulyo Jaya",
    "value": "Harjo Mulyo Jaya"
  },
  {
    "id": "1608082002",
    "district_id": "160808",
    "label": "Jaya Mulya",
    "value": "Jaya Mulya"
  },
  {
    "id": "1608082003",
    "district_id": "160808",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "1608082007",
    "district_id": "160808",
    "label": "Margo Rejo",
    "value": "Margo Rejo"
  },
  {
    "id": "1608082009",
    "district_id": "160808",
    "label": "Trimoharjo",
    "value": "Trimoharjo"
  },
  {
    "id": "1608082010",
    "district_id": "160808",
    "label": "Taman Agung",
    "value": "Taman Agung"
  },
  {
    "id": "1608082011",
    "district_id": "160808",
    "label": "Krujon",
    "value": "Krujon"
  },
  {
    "id": "1608082013",
    "district_id": "160808",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1608082014",
    "district_id": "160808",
    "label": "Sriwangi",
    "value": "Sriwangi"
  },
  {
    "id": "1608082015",
    "district_id": "160808",
    "label": "Cahaya Negeri",
    "value": "Cahaya Negeri"
  },
  {
    "id": "1608082016",
    "district_id": "160808",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1608082017",
    "district_id": "160808",
    "label": "Taraman",
    "value": "Taraman"
  },
  {
    "id": "1608082025",
    "district_id": "160808",
    "label": "Mujo Rahayu",
    "value": "Mujo Rahayu"
  },
  {
    "id": "1608082026",
    "district_id": "160808",
    "label": "Taman Harjo",
    "value": "Taman Harjo"
  },
  {
    "id": "1608082027",
    "district_id": "160808",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "1608082028",
    "district_id": "160808",
    "label": "Taraman Jaya",
    "value": "Taraman Jaya"
  },
  {
    "id": "1608082029",
    "district_id": "160808",
    "label": "Karang Marga",
    "value": "Karang Marga"
  },
  {
    "id": "1608082030",
    "district_id": "160808",
    "label": "Trimo Rejo",
    "value": "Trimo Rejo"
  },
  {
    "id": "1608082031",
    "district_id": "160808",
    "label": "Sriwangi Ulu",
    "value": "Sriwangi Ulu"
  },
  {
    "id": "1608082032",
    "district_id": "160808",
    "label": "Taman Mulyo",
    "value": "Taman Mulyo"
  },
  {
    "id": "1608092004",
    "district_id": "160809",
    "label": "Tanjung Kemuning",
    "value": "Tanjung Kemuning"
  },
  {
    "id": "1608092005",
    "district_id": "160809",
    "label": "Raman Jaya",
    "value": "Raman Jaya"
  },
  {
    "id": "1608092006",
    "district_id": "160809",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1608092007",
    "district_id": "160809",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1608092008",
    "district_id": "160809",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1608092009",
    "district_id": "160809",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "1608092010",
    "district_id": "160809",
    "label": "Sri Bantolo",
    "value": "Sri Bantolo"
  },
  {
    "id": "1608092011",
    "district_id": "160809",
    "label": "Sumber Rahayu",
    "value": "Sumber Rahayu"
  },
  {
    "id": "1608092012",
    "district_id": "160809",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1608092015",
    "district_id": "160809",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1608092016",
    "district_id": "160809",
    "label": "Karang Manik",
    "value": "Karang Manik"
  },
  {
    "id": "1608092017",
    "district_id": "160809",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "1608092018",
    "district_id": "160809",
    "label": "Keli Rejo",
    "value": "Keli Rejo"
  },
  {
    "id": "1608092019",
    "district_id": "160809",
    "label": "Toto Rejo",
    "value": "Toto Rejo"
  },
  {
    "id": "1608092020",
    "district_id": "160809",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1608092021",
    "district_id": "160809",
    "label": "Kemuning Jaya",
    "value": "Kemuning Jaya"
  },
  {
    "id": "1608092022",
    "district_id": "160809",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1608092023",
    "district_id": "160809",
    "label": "Batu Mas",
    "value": "Batu Mas"
  },
  {
    "id": "1608092024",
    "district_id": "160809",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1608092025",
    "district_id": "160809",
    "label": "Srijaya",
    "value": "Srijaya"
  },
  {
    "id": "1608092026",
    "district_id": "160809",
    "label": "Tegal Besar",
    "value": "Tegal Besar"
  },
  {
    "id": "1608092027",
    "district_id": "160809",
    "label": "Darma Buana",
    "value": "Darma Buana"
  },
  {
    "id": "1608092028",
    "district_id": "160809",
    "label": "Rejo Mulyo",
    "value": "Rejo Mulyo"
  },
  {
    "id": "1608092029",
    "district_id": "160809",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1608102001",
    "district_id": "160810",
    "label": "Kutosari",
    "value": "Kutosari"
  },
  {
    "id": "1608102002",
    "district_id": "160810",
    "label": "Nusa Raya",
    "value": "Nusa Raya"
  },
  {
    "id": "1608102003",
    "district_id": "160810",
    "label": "Nusa Tunggal",
    "value": "Nusa Tunggal"
  },
  {
    "id": "1608102004",
    "district_id": "160810",
    "label": "Nusa Bali",
    "value": "Nusa Bali"
  },
  {
    "id": "1608102005",
    "district_id": "160810",
    "label": "Nusa Bakti",
    "value": "Nusa Bakti"
  },
  {
    "id": "1608102006",
    "district_id": "160810",
    "label": "Nusa Jaya",
    "value": "Nusa Jaya"
  },
  {
    "id": "1608102007",
    "district_id": "160810",
    "label": "Nusa Tenggara",
    "value": "Nusa Tenggara"
  },
  {
    "id": "1608102008",
    "district_id": "160810",
    "label": "Nusa Agung",
    "value": "Nusa Agung"
  },
  {
    "id": "1608102009",
    "district_id": "160810",
    "label": "Nusa Maju",
    "value": "Nusa Maju"
  },
  {
    "id": "1608102010",
    "district_id": "160810",
    "label": "Senu Marga",
    "value": "Senu Marga"
  },
  {
    "id": "1608102011",
    "district_id": "160810",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1608102013",
    "district_id": "160810",
    "label": "Trikarya",
    "value": "Trikarya"
  },
  {
    "id": "1608102016",
    "district_id": "160810",
    "label": "Suka Negara",
    "value": "Suka Negara"
  },
  {
    "id": "1608102017",
    "district_id": "160810",
    "label": "Karang Jadi",
    "value": "Karang Jadi"
  },
  {
    "id": "1608102018",
    "district_id": "160810",
    "label": "Dadi Rejo",
    "value": "Dadi Rejo"
  },
  {
    "id": "1608102019",
    "district_id": "160810",
    "label": "Ganti Warno",
    "value": "Ganti Warno"
  },
  {
    "id": "1608102020",
    "district_id": "160810",
    "label": "Sinar Bali",
    "value": "Sinar Bali"
  },
  {
    "id": "1608102021",
    "district_id": "160810",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1608102022",
    "district_id": "160810",
    "label": "Ringin Sari",
    "value": "Ringin Sari"
  },
  {
    "id": "1608102023",
    "district_id": "160810",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "1608112001",
    "district_id": "160811",
    "label": "Batu Raja Bungin",
    "value": "Batu Raja Bungin"
  },
  {
    "id": "1608112002",
    "district_id": "160811",
    "label": "Tulang Bawang",
    "value": "Tulang Bawang"
  },
  {
    "id": "1608112003",
    "district_id": "160811",
    "label": "Sabahlioh",
    "value": "Sabahlioh"
  },
  {
    "id": "1608112004",
    "district_id": "160811",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1608112005",
    "district_id": "160811",
    "label": "Peracak",
    "value": "Peracak"
  },
  {
    "id": "1608112006",
    "district_id": "160811",
    "label": "Negeri Ratu Baru",
    "value": "Negeri Ratu Baru"
  },
  {
    "id": "1608112007",
    "district_id": "160811",
    "label": "Tunas Peracak",
    "value": "Tunas Peracak"
  },
  {
    "id": "1608112008",
    "district_id": "160811",
    "label": "Sukabaru",
    "value": "Sukabaru"
  },
  {
    "id": "1608122001",
    "district_id": "160812",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "1608122002",
    "district_id": "160812",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "1608122003",
    "district_id": "160812",
    "label": "Teko Rejo",
    "value": "Teko Rejo"
  },
  {
    "id": "1608122004",
    "district_id": "160812",
    "label": "Sumber Asri",
    "value": "Sumber Asri"
  },
  {
    "id": "1608122005",
    "district_id": "160812",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1608122006",
    "district_id": "160812",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "1608122007",
    "district_id": "160812",
    "label": "Liman Sari",
    "value": "Liman Sari"
  },
  {
    "id": "1608122008",
    "district_id": "160812",
    "label": "Sumber Harjo",
    "value": "Sumber Harjo"
  },
  {
    "id": "1608122009",
    "district_id": "160812",
    "label": "Sumber Mulyo",
    "value": "Sumber Mulyo"
  },
  {
    "id": "1608122010",
    "district_id": "160812",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "1608122011",
    "district_id": "160812",
    "label": "Bangun Harjo",
    "value": "Bangun Harjo"
  },
  {
    "id": "1608122012",
    "district_id": "160812",
    "label": "Tambak Boyo",
    "value": "Tambak Boyo"
  },
  {
    "id": "1608122013",
    "district_id": "160812",
    "label": "Pengandonan",
    "value": "Pengandonan"
  },
  {
    "id": "1608122014",
    "district_id": "160812",
    "label": "Rejo Dadi",
    "value": "Rejo Dadi"
  },
  {
    "id": "1608122015",
    "district_id": "160812",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "1608122016",
    "district_id": "160812",
    "label": "Sumedang Sari",
    "value": "Sumedang Sari"
  },
  {
    "id": "1608122017",
    "district_id": "160812",
    "label": "Rowodadi",
    "value": "Rowodadi"
  },
  {
    "id": "1608122018",
    "district_id": "160812",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "1608122019",
    "district_id": "160812",
    "label": "Kumpul Rejo",
    "value": "Kumpul Rejo"
  },
  {
    "id": "1608122020",
    "district_id": "160812",
    "label": "Metro Rejo",
    "value": "Metro Rejo"
  },
  {
    "id": "1608122021",
    "district_id": "160812",
    "label": "Kedu",
    "value": "Kedu"
  },
  {
    "id": "1608122022",
    "district_id": "160812",
    "label": "Kedung Rejo",
    "value": "Kedung Rejo"
  },
  {
    "id": "1608122023",
    "district_id": "160812",
    "label": "Banyumas Asri",
    "value": "Banyumas Asri"
  },
  {
    "id": "1608122024",
    "district_id": "160812",
    "label": "Gumuk Rejo",
    "value": "Gumuk Rejo"
  },
  {
    "id": "1608122025",
    "district_id": "160812",
    "label": "Tanjung Mulya",
    "value": "Tanjung Mulya"
  },
  {
    "id": "1608122026",
    "district_id": "160812",
    "label": "Raman Agung",
    "value": "Raman Agung"
  },
  {
    "id": "1608122027",
    "district_id": "160812",
    "label": "Bukit Mas",
    "value": "Bukit Mas"
  },
  {
    "id": "1608122028",
    "district_id": "160812",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1608122029",
    "district_id": "160812",
    "label": "Berasan Mulya",
    "value": "Berasan Mulya"
  },
  {
    "id": "1608122030",
    "district_id": "160812",
    "label": "Sumber Tani",
    "value": "Sumber Tani"
  },
  {
    "id": "1608132001",
    "district_id": "160813",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "1608132002",
    "district_id": "160813",
    "label": "Nikan",
    "value": "Nikan"
  },
  {
    "id": "1608132003",
    "district_id": "160813",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "1608132004",
    "district_id": "160813",
    "label": "Wana Bakti",
    "value": "Wana Bakti"
  },
  {
    "id": "1608132005",
    "district_id": "160813",
    "label": "Batu Marta VI",
    "value": "Batu Marta VI"
  },
  {
    "id": "1608132006",
    "district_id": "160813",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "1608132007",
    "district_id": "160813",
    "label": "Bina Amarta",
    "value": "Bina Amarta"
  },
  {
    "id": "1608132008",
    "district_id": "160813",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1608132009",
    "district_id": "160813",
    "label": "Batu Marta X",
    "value": "Batu Marta X"
  },
  {
    "id": "1608132010",
    "district_id": "160813",
    "label": "Marta V Jaya",
    "value": "Marta V Jaya"
  },
  {
    "id": "1608142001",
    "district_id": "160814",
    "label": "Tanjung Kukuh",
    "value": "Tanjung Kukuh"
  },
  {
    "id": "1608142002",
    "district_id": "160814",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1608142003",
    "district_id": "160814",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "1608142004",
    "district_id": "160814",
    "label": "Menanga Besar",
    "value": "Menanga Besar"
  },
  {
    "id": "1608142005",
    "district_id": "160814",
    "label": "Menanga Tengah",
    "value": "Menanga Tengah"
  },
  {
    "id": "1608142006",
    "district_id": "160814",
    "label": "Adu manis",
    "value": "Adu manis"
  },
  {
    "id": "1608142007",
    "district_id": "160814",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1608142008",
    "district_id": "160814",
    "label": "Kangkung",
    "value": "Kangkung"
  },
  {
    "id": "1608142009",
    "district_id": "160814",
    "label": "Sri Tanjung",
    "value": "Sri Tanjung"
  },
  {
    "id": "1608142010",
    "district_id": "160814",
    "label": "Kangkung Ilir",
    "value": "Kangkung Ilir"
  },
  {
    "id": "1608142011",
    "district_id": "160814",
    "label": "Betung Timur",
    "value": "Betung Timur"
  },
  {
    "id": "1608142012",
    "district_id": "160814",
    "label": "Menanga Sari",
    "value": "Menanga Sari"
  },
  {
    "id": "1608152001",
    "district_id": "160815",
    "label": "Karang Melati",
    "value": "Karang Melati"
  },
  {
    "id": "1608152002",
    "district_id": "160815",
    "label": "Karang Menjangan",
    "value": "Karang Menjangan"
  },
  {
    "id": "1608152003",
    "district_id": "160815",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1608152004",
    "district_id": "160815",
    "label": "Tulung Harapan",
    "value": "Tulung Harapan"
  },
  {
    "id": "1608152005",
    "district_id": "160815",
    "label": "Kota Tanah",
    "value": "Kota Tanah"
  },
  {
    "id": "1608152006",
    "district_id": "160815",
    "label": "Nirwana",
    "value": "Nirwana"
  },
  {
    "id": "1608152007",
    "district_id": "160815",
    "label": "Burnai Mulya",
    "value": "Burnai Mulya"
  },
  {
    "id": "1608152008",
    "district_id": "160815",
    "label": "Kota Mulya",
    "value": "Kota Mulya"
  },
  {
    "id": "1608152009",
    "district_id": "160815",
    "label": "Bungin Jaya",
    "value": "Bungin Jaya"
  },
  {
    "id": "1608152010",
    "district_id": "160815",
    "label": "Burnai Jaya",
    "value": "Burnai Jaya"
  },
  {
    "id": "1608152011",
    "district_id": "160815",
    "label": "Mulia Jaya",
    "value": "Mulia Jaya"
  },
  {
    "id": "1608152012",
    "district_id": "160815",
    "label": "Melati Jaya",
    "value": "Melati Jaya"
  },
  {
    "id": "1608152013",
    "district_id": "160815",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "1608152014",
    "district_id": "160815",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "1608152015",
    "district_id": "160815",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1608152016",
    "district_id": "160815",
    "label": "Melati Agung",
    "value": "Melati Agung"
  },
  {
    "id": "1608152017",
    "district_id": "160815",
    "label": "Wana Makmur",
    "value": "Wana Makmur"
  },
  {
    "id": "1608152018",
    "district_id": "160815",
    "label": "Bawang Tikar",
    "value": "Bawang Tikar"
  },
  {
    "id": "1608152019",
    "district_id": "160815",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1608162001",
    "district_id": "160816",
    "label": "Jayapura",
    "value": "Jayapura"
  },
  {
    "id": "1608162002",
    "district_id": "160816",
    "label": "Bunga Mayang",
    "value": "Bunga Mayang"
  },
  {
    "id": "1608162003",
    "district_id": "160816",
    "label": "Way Salak",
    "value": "Way Salak"
  },
  {
    "id": "1608162004",
    "district_id": "160816",
    "label": "Mendah",
    "value": "Mendah"
  },
  {
    "id": "1608162005",
    "district_id": "160816",
    "label": "Tumijaya",
    "value": "Tumijaya"
  },
  {
    "id": "1608162006",
    "district_id": "160816",
    "label": "Condong",
    "value": "Condong"
  },
  {
    "id": "1608162007",
    "district_id": "160816",
    "label": "Peracak Jaya",
    "value": "Peracak Jaya"
  },
  {
    "id": "1608162008",
    "district_id": "160816",
    "label": "Kambang",
    "value": "Kambang"
  },
  {
    "id": "1608172001",
    "district_id": "160817",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "1608172002",
    "district_id": "160817",
    "label": "Sido Rejo",
    "value": "Sido Rejo"
  },
  {
    "id": "1608172003",
    "district_id": "160817",
    "label": "Banjar Rejo",
    "value": "Banjar Rejo"
  },
  {
    "id": "1608172004",
    "district_id": "160817",
    "label": "Panca Tunggal",
    "value": "Panca Tunggal"
  },
  {
    "id": "1608172005",
    "district_id": "160817",
    "label": "Marga Koyo",
    "value": "Marga Koyo"
  },
  {
    "id": "1608172006",
    "district_id": "160817",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1608172007",
    "district_id": "160817",
    "label": "Karsa Jaya",
    "value": "Karsa Jaya"
  },
  {
    "id": "1608172008",
    "district_id": "160817",
    "label": "Windusari",
    "value": "Windusari"
  },
  {
    "id": "1608172009",
    "district_id": "160817",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "1608172010",
    "district_id": "160817",
    "label": "Giri Mulyo",
    "value": "Giri Mulyo"
  },
  {
    "id": "1608172011",
    "district_id": "160817",
    "label": "Madugondo",
    "value": "Madugondo"
  },
  {
    "id": "1608172012",
    "district_id": "160817",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "1608172013",
    "district_id": "160817",
    "label": "Rejosari Jaya",
    "value": "Rejosari Jaya"
  },
  {
    "id": "1608182001",
    "district_id": "160818",
    "label": "Tugu Mulyo",
    "value": "Tugu Mulyo"
  },
  {
    "id": "1608182002",
    "district_id": "160818",
    "label": "Bangsa Negara",
    "value": "Bangsa Negara"
  },
  {
    "id": "1608182003",
    "district_id": "160818",
    "label": "Tugu Harum",
    "value": "Tugu Harum"
  },
  {
    "id": "1608182004",
    "district_id": "160818",
    "label": "Tulus Ayu",
    "value": "Tulus Ayu"
  },
  {
    "id": "1608182005",
    "district_id": "160818",
    "label": "Yosowinangun",
    "value": "Yosowinangun"
  },
  {
    "id": "1608182006",
    "district_id": "160818",
    "label": "Karang Binangun",
    "value": "Karang Binangun"
  },
  {
    "id": "1608182007",
    "district_id": "160818",
    "label": "Jati Mulyo I",
    "value": "Jati Mulyo I"
  },
  {
    "id": "1608182008",
    "district_id": "160818",
    "label": "Pandan Sari I",
    "value": "Pandan Sari I"
  },
  {
    "id": "1608182009",
    "district_id": "160818",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "1608182010",
    "district_id": "160818",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1608182011",
    "district_id": "160818",
    "label": "Lubuk Harjo",
    "value": "Lubuk Harjo"
  },
  {
    "id": "1608182012",
    "district_id": "160818",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "1608182013",
    "district_id": "160818",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "1608182014",
    "district_id": "160818",
    "label": "Tebing Sari Mulya",
    "value": "Tebing Sari Mulya"
  },
  {
    "id": "1608182015",
    "district_id": "160818",
    "label": "Marga Cinta",
    "value": "Marga Cinta"
  },
  {
    "id": "1608182016",
    "district_id": "160818",
    "label": "Karang Binangun II",
    "value": "Karang Binangun II"
  },
  {
    "id": "1608192001",
    "district_id": "160819",
    "label": "Sari Guna",
    "value": "Sari Guna"
  },
  {
    "id": "1608192002",
    "district_id": "160819",
    "label": "Sido Waluyo",
    "value": "Sido Waluyo"
  },
  {
    "id": "1608192003",
    "district_id": "160819",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1608192004",
    "district_id": "160819",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1608192005",
    "district_id": "160819",
    "label": "Petanggan",
    "value": "Petanggan"
  },
  {
    "id": "1608192006",
    "district_id": "160819",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1608192007",
    "district_id": "160819",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1608192008",
    "district_id": "160819",
    "label": "Ulak Buntar",
    "value": "Ulak Buntar"
  },
  {
    "id": "1608192009",
    "district_id": "160819",
    "label": "Sribudaya",
    "value": "Sribudaya"
  },
  {
    "id": "1608192010",
    "district_id": "160819",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "1608192011",
    "district_id": "160819",
    "label": "Tulung Sari",
    "value": "Tulung Sari"
  },
  {
    "id": "1608192012",
    "district_id": "160819",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "1608202001",
    "district_id": "160820",
    "label": "Sri Bunga",
    "value": "Sri Bunga"
  },
  {
    "id": "1608202002",
    "district_id": "160820",
    "label": "Anyar",
    "value": "Anyar"
  },
  {
    "id": "1608202003",
    "district_id": "160820",
    "label": "Pandan Sari II",
    "value": "Pandan Sari II"
  },
  {
    "id": "1608202004",
    "district_id": "160820",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "1608202005",
    "district_id": "160820",
    "label": "Surya Menang",
    "value": "Surya Menang"
  },
  {
    "id": "1608202006",
    "district_id": "160820",
    "label": "Muncak Kabau",
    "value": "Muncak Kabau"
  },
  {
    "id": "1608202007",
    "district_id": "160820",
    "label": "Sri Bulan",
    "value": "Sri Bulan"
  },
  {
    "id": "1609011013",
    "district_id": "160901",
    "label": "Batu Belang Jaya",
    "value": "Batu Belang Jaya"
  },
  {
    "id": "1609011014",
    "district_id": "160901",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1609011015",
    "district_id": "160901",
    "label": "Pancur Pungah",
    "value": "Pancur Pungah"
  },
  {
    "id": "1609011016",
    "district_id": "160901",
    "label": "Kisau",
    "value": "Kisau"
  },
  {
    "id": "1609011019",
    "district_id": "160901",
    "label": "Pasar Muaradua",
    "value": "Pasar Muaradua"
  },
  {
    "id": "1609012002",
    "district_id": "160901",
    "label": "Gedung Lepihan",
    "value": "Gedung Lepihan"
  },
  {
    "id": "1609012003",
    "district_id": "160901",
    "label": "Gunung Tiga",
    "value": "Gunung Tiga"
  },
  {
    "id": "1609012004",
    "district_id": "160901",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1609012005",
    "district_id": "160901",
    "label": "Sukaraja II",
    "value": "Sukaraja II"
  },
  {
    "id": "1609012017",
    "district_id": "160901",
    "label": "Pendagan",
    "value": "Pendagan"
  },
  {
    "id": "1609012018",
    "district_id": "160901",
    "label": "Mehanggin",
    "value": "Mehanggin"
  },
  {
    "id": "1609012024",
    "district_id": "160901",
    "label": "Pelangki",
    "value": "Pelangki"
  },
  {
    "id": "1609012025",
    "district_id": "160901",
    "label": "Batu Belang 2",
    "value": "Batu Belang 2"
  },
  {
    "id": "1609012026",
    "district_id": "160901",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "1609022012",
    "district_id": "160902",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1609022013",
    "district_id": "160902",
    "label": "Pulau Beringin",
    "value": "Pulau Beringin"
  },
  {
    "id": "1609022014",
    "district_id": "160902",
    "label": "Aromantai",
    "value": "Aromantai"
  },
  {
    "id": "1609022015",
    "district_id": "160902",
    "label": "Tanjung Kari",
    "value": "Tanjung Kari"
  },
  {
    "id": "1609022016",
    "district_id": "160902",
    "label": "Simpang Pancur",
    "value": "Simpang Pancur"
  },
  {
    "id": "1609022017",
    "district_id": "160902",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1609022018",
    "district_id": "160902",
    "label": "Gunung Batu",
    "value": "Gunung Batu"
  },
  {
    "id": "1609022019",
    "district_id": "160902",
    "label": "Kemu",
    "value": "Kemu"
  },
  {
    "id": "1609022020",
    "district_id": "160902",
    "label": "Anugerah Kemu",
    "value": "Anugerah Kemu"
  },
  {
    "id": "1609022021",
    "district_id": "160902",
    "label": "Kemu Ulu",
    "value": "Kemu Ulu"
  },
  {
    "id": "1609022022",
    "district_id": "160902",
    "label": "Pematang Obar",
    "value": "Pematang Obar"
  },
  {
    "id": "1609022023",
    "district_id": "160902",
    "label": "Tanjung Bulan Ulu",
    "value": "Tanjung Bulan Ulu"
  },
  {
    "id": "1609022024",
    "district_id": "160902",
    "label": "Pulau Beringin Utara",
    "value": "Pulau Beringin Utara"
  },
  {
    "id": "1609031023",
    "district_id": "160903",
    "label": "Bandar Agung Ranau",
    "value": "Bandar Agung Ranau"
  },
  {
    "id": "1609032020",
    "district_id": "160903",
    "label": "Way Timah",
    "value": "Way Timah"
  },
  {
    "id": "1609032021",
    "district_id": "160903",
    "label": "Sipatuhu",
    "value": "Sipatuhu"
  },
  {
    "id": "1609032022",
    "district_id": "160903",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "1609032024",
    "district_id": "160903",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "1609032025",
    "district_id": "160903",
    "label": "Sugih Waras",
    "value": "Sugih Waras"
  },
  {
    "id": "1609032026",
    "district_id": "160903",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1609032027",
    "district_id": "160903",
    "label": "Rantau Nipis",
    "value": "Rantau Nipis"
  },
  {
    "id": "1609032028",
    "district_id": "160903",
    "label": "Air Rupik",
    "value": "Air Rupik"
  },
  {
    "id": "1609032031",
    "district_id": "160903",
    "label": "Talang Merbau",
    "value": "Talang Merbau"
  },
  {
    "id": "1609032032",
    "district_id": "160903",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1609032033",
    "district_id": "160903",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1609032034",
    "district_id": "160903",
    "label": "Telanai",
    "value": "Telanai"
  },
  {
    "id": "1609032035",
    "district_id": "160903",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1609032036",
    "district_id": "160903",
    "label": "Karang Indah",
    "value": "Karang Indah"
  },
  {
    "id": "1609032037",
    "district_id": "160903",
    "label": "Tangsi Agung",
    "value": "Tangsi Agung"
  },
  {
    "id": "1609032038",
    "district_id": "160903",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1609032039",
    "district_id": "160903",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1609032040",
    "district_id": "160903",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1609032041",
    "district_id": "160903",
    "label": "Sipatuhu II",
    "value": "Sipatuhu II"
  },
  {
    "id": "1609032042",
    "district_id": "160903",
    "label": "Terap Mulia",
    "value": "Terap Mulia"
  },
  {
    "id": "1609032043",
    "district_id": "160903",
    "label": "Surabaya Timur",
    "value": "Surabaya Timur"
  },
  {
    "id": "1609042001",
    "district_id": "160904",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1609042002",
    "district_id": "160904",
    "label": "Bayur Tengah",
    "value": "Bayur Tengah"
  },
  {
    "id": "1609042003",
    "district_id": "160904",
    "label": "Tanjung Tebat",
    "value": "Tanjung Tebat"
  },
  {
    "id": "1609042004",
    "district_id": "160904",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "1609042005",
    "district_id": "160904",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1609042006",
    "district_id": "160904",
    "label": "Alun Dua",
    "value": "Alun Dua"
  },
  {
    "id": "1609042007",
    "district_id": "160904",
    "label": "Bandar Alam Baru",
    "value": "Bandar Alam Baru"
  },
  {
    "id": "1609042008",
    "district_id": "160904",
    "label": "Dusun Tengah",
    "value": "Dusun Tengah"
  },
  {
    "id": "1609042009",
    "district_id": "160904",
    "label": "Ulak Agung Ilir",
    "value": "Ulak Agung Ilir"
  },
  {
    "id": "1609042011",
    "district_id": "160904",
    "label": "Gunung Gare",
    "value": "Gunung Gare"
  },
  {
    "id": "1609042018",
    "district_id": "160904",
    "label": "Ulak Agung Ulu",
    "value": "Ulak Agung Ulu"
  },
  {
    "id": "1609042019",
    "district_id": "160904",
    "label": "Muaradua Kisam",
    "value": "Muaradua Kisam"
  },
  {
    "id": "1609042020",
    "district_id": "160904",
    "label": "Sukananti",
    "value": "Sukananti"
  },
  {
    "id": "1609042021",
    "district_id": "160904",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1609042022",
    "district_id": "160904",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1609042028",
    "district_id": "160904",
    "label": "Panantian",
    "value": "Panantian"
  },
  {
    "id": "1609042029",
    "district_id": "160904",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1609042040",
    "district_id": "160904",
    "label": "Simpang Lubuk Dalam",
    "value": "Simpang Lubuk Dalam"
  },
  {
    "id": "1609052001",
    "district_id": "160905",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1609052007",
    "district_id": "160905",
    "label": "Lubar",
    "value": "Lubar"
  },
  {
    "id": "1609052008",
    "district_id": "160905",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1609052009",
    "district_id": "160905",
    "label": "Simpangan",
    "value": "Simpangan"
  },
  {
    "id": "1609052010",
    "district_id": "160905",
    "label": "Bungin Campang",
    "value": "Bungin Campang"
  },
  {
    "id": "1609052029",
    "district_id": "160905",
    "label": "Simpang Agung",
    "value": "Simpang Agung"
  },
  {
    "id": "1609052030",
    "district_id": "160905",
    "label": "Sinar Mulyo",
    "value": "Sinar Mulyo"
  },
  {
    "id": "1609062007",
    "district_id": "160906",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1609062008",
    "district_id": "160906",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1609062010",
    "district_id": "160906",
    "label": "Tanjung Menang Ilir",
    "value": "Tanjung Menang Ilir"
  },
  {
    "id": "1609062011",
    "district_id": "160906",
    "label": "Tanjung Menang Ulu",
    "value": "Tanjung Menang Ulu"
  },
  {
    "id": "1609062012",
    "district_id": "160906",
    "label": "Kenali",
    "value": "Kenali"
  },
  {
    "id": "1609062013",
    "district_id": "160906",
    "label": "Negeri Cahya",
    "value": "Negeri Cahya"
  },
  {
    "id": "1609062014",
    "district_id": "160906",
    "label": "Sukaraja I",
    "value": "Sukaraja I"
  },
  {
    "id": "1609062015",
    "district_id": "160906",
    "label": "Tanjung Iman",
    "value": "Tanjung Iman"
  },
  {
    "id": "1609062016",
    "district_id": "160906",
    "label": "Bunga Mas",
    "value": "Bunga Mas"
  },
  {
    "id": "1609062017",
    "district_id": "160906",
    "label": "Lubuk Liku",
    "value": "Lubuk Liku"
  },
  {
    "id": "1609062018",
    "district_id": "160906",
    "label": "Kota Karang",
    "value": "Kota Karang"
  },
  {
    "id": "1609062019",
    "district_id": "160906",
    "label": "Negeri Batin",
    "value": "Negeri Batin"
  },
  {
    "id": "1609062020",
    "district_id": "160906",
    "label": "Madura",
    "value": "Madura"
  },
  {
    "id": "1609062021",
    "district_id": "160906",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1609062022",
    "district_id": "160906",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1609062023",
    "district_id": "160906",
    "label": "Talang Baru",
    "value": "Talang Baru"
  },
  {
    "id": "1609072001",
    "district_id": "160907",
    "label": "Sugih Rawas",
    "value": "Sugih Rawas"
  },
  {
    "id": "1609072002",
    "district_id": "160907",
    "label": "Padang Sari",
    "value": "Padang Sari"
  },
  {
    "id": "1609072003",
    "district_id": "160907",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1609072005",
    "district_id": "160907",
    "label": "Saung Naga",
    "value": "Saung Naga"
  },
  {
    "id": "1609072006",
    "district_id": "160907",
    "label": "Negeri Batin Baru",
    "value": "Negeri Batin Baru"
  },
  {
    "id": "1609072007",
    "district_id": "160907",
    "label": "Kota Aman",
    "value": "Kota Aman"
  },
  {
    "id": "1609072008",
    "district_id": "160907",
    "label": "Belambangan",
    "value": "Belambangan"
  },
  {
    "id": "1609072009",
    "district_id": "160907",
    "label": "Paninjauan",
    "value": "Paninjauan"
  },
  {
    "id": "1609072018",
    "district_id": "160907",
    "label": "Nagar Agung",
    "value": "Nagar Agung"
  },
  {
    "id": "1609072019",
    "district_id": "160907",
    "label": "Simpang Saga",
    "value": "Simpang Saga"
  },
  {
    "id": "1609072020",
    "district_id": "160907",
    "label": "Perupus Blambangan",
    "value": "Perupus Blambangan"
  },
  {
    "id": "1609072021",
    "district_id": "160907",
    "label": "Kagelang Blambangan",
    "value": "Kagelang Blambangan"
  },
  {
    "id": "1609072022",
    "district_id": "160907",
    "label": "Sukajadi Blambangan",
    "value": "Sukajadi Blambangan"
  },
  {
    "id": "1609072023",
    "district_id": "160907",
    "label": "Bedeng Blambangan",
    "value": "Bedeng Blambangan"
  },
  {
    "id": "1609082001",
    "district_id": "160908",
    "label": "Kota Dalam",
    "value": "Kota Dalam"
  },
  {
    "id": "1609082002",
    "district_id": "160908",
    "label": "Teluk Agung",
    "value": "Teluk Agung"
  },
  {
    "id": "1609082003",
    "district_id": "160908",
    "label": "Pulau Duku",
    "value": "Pulau Duku"
  },
  {
    "id": "1609082004",
    "district_id": "160908",
    "label": "Tanjung Besar",
    "value": "Tanjung Besar"
  },
  {
    "id": "1609082005",
    "district_id": "160908",
    "label": "Sinar Marga",
    "value": "Sinar Marga"
  },
  {
    "id": "1609082006",
    "district_id": "160908",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1609082007",
    "district_id": "160908",
    "label": "Galang Tinggi",
    "value": "Galang Tinggi"
  },
  {
    "id": "1609082008",
    "district_id": "160908",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1609082009",
    "district_id": "160908",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1609082010",
    "district_id": "160908",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "1609082011",
    "district_id": "160908",
    "label": "Kemang Bandung",
    "value": "Kemang Bandung"
  },
  {
    "id": "1609082012",
    "district_id": "160908",
    "label": "Selabung Belimbing Jaya",
    "value": "Selabung Belimbing Jaya"
  },
  {
    "id": "1609082013",
    "district_id": "160908",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "1609082014",
    "district_id": "160908",
    "label": "Air Baru",
    "value": "Air Baru"
  },
  {
    "id": "1609082015",
    "district_id": "160908",
    "label": "Pere'an",
    "value": "Pere'an"
  },
  {
    "id": "1609092001",
    "district_id": "160909",
    "label": "Tanjung Durian",
    "value": "Tanjung Durian"
  },
  {
    "id": "1609092002",
    "district_id": "160909",
    "label": "Sipin",
    "value": "Sipin"
  },
  {
    "id": "1609092003",
    "district_id": "160909",
    "label": "Kota Way",
    "value": "Kota Way"
  },
  {
    "id": "1609092004",
    "district_id": "160909",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1609092005",
    "district_id": "160909",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1609092006",
    "district_id": "160909",
    "label": "Serakat Jaya",
    "value": "Serakat Jaya"
  },
  {
    "id": "1609092007",
    "district_id": "160909",
    "label": "Karet Jaya",
    "value": "Karet Jaya"
  },
  {
    "id": "1609092008",
    "district_id": "160909",
    "label": "Danau Jaya",
    "value": "Danau Jaya"
  },
  {
    "id": "1609092009",
    "district_id": "160909",
    "label": "Sumber Ringin",
    "value": "Sumber Ringin"
  },
  {
    "id": "1609092010",
    "district_id": "160909",
    "label": "Kembang Tinggi",
    "value": "Kembang Tinggi"
  },
  {
    "id": "1609092011",
    "district_id": "160909",
    "label": "Sido Rahayu",
    "value": "Sido Rahayu"
  },
  {
    "id": "1609092012",
    "district_id": "160909",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1609092013",
    "district_id": "160909",
    "label": "Durian Sembilan",
    "value": "Durian Sembilan"
  },
  {
    "id": "1609092014",
    "district_id": "160909",
    "label": "Sumber Raya",
    "value": "Sumber Raya"
  },
  {
    "id": "1609092015",
    "district_id": "160909",
    "label": "Sinar Napalan",
    "value": "Sinar Napalan"
  },
  {
    "id": "1609092016",
    "district_id": "160909",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1609092017",
    "district_id": "160909",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "1609092018",
    "district_id": "160909",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1609092019",
    "district_id": "160909",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1609092020",
    "district_id": "160909",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1609092021",
    "district_id": "160909",
    "label": "Air Kelian",
    "value": "Air Kelian"
  },
  {
    "id": "1609092022",
    "district_id": "160909",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1609102001",
    "district_id": "160910",
    "label": "Balayan",
    "value": "Balayan"
  },
  {
    "id": "1609102002",
    "district_id": "160910",
    "label": "Berasang",
    "value": "Berasang"
  },
  {
    "id": "1609102003",
    "district_id": "160910",
    "label": "Tebat Gabus",
    "value": "Tebat Gabus"
  },
  {
    "id": "1609102004",
    "district_id": "160910",
    "label": "Padang Lay",
    "value": "Padang Lay"
  },
  {
    "id": "1609102005",
    "district_id": "160910",
    "label": "Danau Rata",
    "value": "Danau Rata"
  },
  {
    "id": "1609102006",
    "district_id": "160910",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1609102007",
    "district_id": "160910",
    "label": "Bandar Alam Lama",
    "value": "Bandar Alam Lama"
  },
  {
    "id": "1609102008",
    "district_id": "160910",
    "label": "Muara Payang",
    "value": "Muara Payang"
  },
  {
    "id": "1609102009",
    "district_id": "160910",
    "label": "Siring Agung",
    "value": "Siring Agung"
  },
  {
    "id": "1609102010",
    "district_id": "160910",
    "label": "Kota Padang",
    "value": "Kota Padang"
  },
  {
    "id": "1609102011",
    "district_id": "160910",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1609102012",
    "district_id": "160910",
    "label": "Tenang",
    "value": "Tenang"
  },
  {
    "id": "1609102013",
    "district_id": "160910",
    "label": "Gunung Megang",
    "value": "Gunung Megang"
  },
  {
    "id": "1609102014",
    "district_id": "160910",
    "label": "Ulak Pandan",
    "value": "Ulak Pandan"
  },
  {
    "id": "1609102015",
    "district_id": "160910",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1609102016",
    "district_id": "160910",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1609102017",
    "district_id": "160910",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "1609102018",
    "district_id": "160910",
    "label": "Singa Laga",
    "value": "Singa Laga"
  },
  {
    "id": "1609102019",
    "district_id": "160910",
    "label": "Air Alun",
    "value": "Air Alun"
  },
  {
    "id": "1609112001",
    "district_id": "160911",
    "label": "Pius",
    "value": "Pius"
  },
  {
    "id": "1609112002",
    "district_id": "160911",
    "label": "Pengandonan",
    "value": "Pengandonan"
  },
  {
    "id": "1609112003",
    "district_id": "160911",
    "label": "Siring Alam",
    "value": "Siring Alam"
  },
  {
    "id": "1609112004",
    "district_id": "160911",
    "label": "Simpang Campang",
    "value": "Simpang Campang"
  },
  {
    "id": "1609112005",
    "district_id": "160911",
    "label": "Pulau Kemiling",
    "value": "Pulau Kemiling"
  },
  {
    "id": "1609112006",
    "district_id": "160911",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1609112007",
    "district_id": "160911",
    "label": "Muara Sindang",
    "value": "Muara Sindang"
  },
  {
    "id": "1609112008",
    "district_id": "160911",
    "label": "Campang Jaya",
    "value": "Campang Jaya"
  },
  {
    "id": "1609112009",
    "district_id": "160911",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1609121013",
    "district_id": "160912",
    "label": "Simpang Sender",
    "value": "Simpang Sender"
  },
  {
    "id": "1609122001",
    "district_id": "160912",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1609122002",
    "district_id": "160912",
    "label": "Sukamarga",
    "value": "Sukamarga"
  },
  {
    "id": "1609122003",
    "district_id": "160912",
    "label": "Subik",
    "value": "Subik"
  },
  {
    "id": "1609122004",
    "district_id": "160912",
    "label": "Jepara",
    "value": "Jepara"
  },
  {
    "id": "1609122005",
    "district_id": "160912",
    "label": "Hangkusa",
    "value": "Hangkusa"
  },
  {
    "id": "1609122006",
    "district_id": "160912",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1609122007",
    "district_id": "160912",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "1609122008",
    "district_id": "160912",
    "label": "Tanjung Setia",
    "value": "Tanjung Setia"
  },
  {
    "id": "1609122009",
    "district_id": "160912",
    "label": "Gedung Baru",
    "value": "Gedung Baru"
  },
  {
    "id": "1609122010",
    "district_id": "160912",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1609122011",
    "district_id": "160912",
    "label": "Suka Bumi",
    "value": "Suka Bumi"
  },
  {
    "id": "1609122012",
    "district_id": "160912",
    "label": "Sumber Mulia",
    "value": "Sumber Mulia"
  },
  {
    "id": "1609122014",
    "district_id": "160912",
    "label": "Way Relai",
    "value": "Way Relai"
  },
  {
    "id": "1609122015",
    "district_id": "160912",
    "label": "Tanjung Baru Ranau",
    "value": "Tanjung Baru Ranau"
  },
  {
    "id": "1609122016",
    "district_id": "160912",
    "label": "Simpang Sender Selatan",
    "value": "Simpang Sender Selatan"
  },
  {
    "id": "1609122017",
    "district_id": "160912",
    "label": "Simpang Sender Utara",
    "value": "Simpang Sender Utara"
  },
  {
    "id": "1609122018",
    "district_id": "160912",
    "label": "Simpang Sender Timur",
    "value": "Simpang Sender Timur"
  },
  {
    "id": "1609122019",
    "district_id": "160912",
    "label": "Simpang Sender Tengah",
    "value": "Simpang Sender Tengah"
  },
  {
    "id": "1609122020",
    "district_id": "160912",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1609122021",
    "district_id": "160912",
    "label": "Pakhda Suka",
    "value": "Pakhda Suka"
  },
  {
    "id": "1609122022",
    "district_id": "160912",
    "label": "Serumpun Jaya",
    "value": "Serumpun Jaya"
  },
  {
    "id": "1609132001",
    "district_id": "160913",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1609132002",
    "district_id": "160913",
    "label": "Kota Batu",
    "value": "Kota Batu"
  },
  {
    "id": "1609132003",
    "district_id": "160913",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1609132004",
    "district_id": "160913",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1609132005",
    "district_id": "160913",
    "label": "Gunung Aji",
    "value": "Gunung Aji"
  },
  {
    "id": "1609132006",
    "district_id": "160913",
    "label": "Pilla",
    "value": "Pilla"
  },
  {
    "id": "1609132007",
    "district_id": "160913",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "1609132008",
    "district_id": "160913",
    "label": "Remanam Jaya",
    "value": "Remanam Jaya"
  },
  {
    "id": "1609132009",
    "district_id": "160913",
    "label": "Kiwis Raya",
    "value": "Kiwis Raya"
  },
  {
    "id": "1609132010",
    "district_id": "160913",
    "label": "Segigok Raya",
    "value": "Segigok Raya"
  },
  {
    "id": "1609132011",
    "district_id": "160913",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1609132012",
    "district_id": "160913",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1609132013",
    "district_id": "160913",
    "label": "Bedeng Tiga",
    "value": "Bedeng Tiga"
  },
  {
    "id": "1609132014",
    "district_id": "160913",
    "label": "Gedung Ranau",
    "value": "Gedung Ranau"
  },
  {
    "id": "1609132015",
    "district_id": "160913",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1609132016",
    "district_id": "160913",
    "label": "Way Wangi Seminung",
    "value": "Way Wangi Seminung"
  },
  {
    "id": "1609142001",
    "district_id": "160914",
    "label": "Merpang",
    "value": "Merpang"
  },
  {
    "id": "1609142002",
    "district_id": "160914",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1609142003",
    "district_id": "160914",
    "label": "Air Baru",
    "value": "Air Baru"
  },
  {
    "id": "1609142004",
    "district_id": "160914",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "1609142005",
    "district_id": "160914",
    "label": "Bumi Genap",
    "value": "Bumi Genap"
  },
  {
    "id": "1609142006",
    "district_id": "160914",
    "label": "Gedung Nyawa",
    "value": "Gedung Nyawa"
  },
  {
    "id": "1609142007",
    "district_id": "160914",
    "label": "Gedung Wani",
    "value": "Gedung Wani"
  },
  {
    "id": "1609142008",
    "district_id": "160914",
    "label": "Sura",
    "value": "Sura"
  },
  {
    "id": "1609142009",
    "district_id": "160914",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1609152001",
    "district_id": "160915",
    "label": "Pulau Kemuning",
    "value": "Pulau Kemuning"
  },
  {
    "id": "1609152002",
    "district_id": "160915",
    "label": "Guntung Jaya",
    "value": "Guntung Jaya"
  },
  {
    "id": "1609152003",
    "district_id": "160915",
    "label": "Tanah Pilih",
    "value": "Tanah Pilih"
  },
  {
    "id": "1609152004",
    "district_id": "160915",
    "label": "Ujan Mas",
    "value": "Ujan Mas"
  },
  {
    "id": "1609152005",
    "district_id": "160915",
    "label": "Pecah Pinggan",
    "value": "Pecah Pinggan"
  },
  {
    "id": "1609152006",
    "district_id": "160915",
    "label": "Cukoh Nau",
    "value": "Cukoh Nau"
  },
  {
    "id": "1609152007",
    "district_id": "160915",
    "label": "Simpang Luas",
    "value": "Simpang Luas"
  },
  {
    "id": "1609152008",
    "district_id": "160915",
    "label": "Sedau Jaya",
    "value": "Sedau Jaya"
  },
  {
    "id": "1609152009",
    "district_id": "160915",
    "label": "Sebaja",
    "value": "Sebaja"
  },
  {
    "id": "1609162001",
    "district_id": "160916",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1609162002",
    "district_id": "160916",
    "label": "Muara Sindang Ilir",
    "value": "Muara Sindang Ilir"
  },
  {
    "id": "1609162003",
    "district_id": "160916",
    "label": "Muara Sindang Tengah",
    "value": "Muara Sindang Tengah"
  },
  {
    "id": "1609162004",
    "district_id": "160916",
    "label": "Pematang Danau",
    "value": "Pematang Danau"
  },
  {
    "id": "1609162005",
    "district_id": "160916",
    "label": "Ulu Danau",
    "value": "Ulu Danau"
  },
  {
    "id": "1609162006",
    "district_id": "160916",
    "label": "Tebat Layang",
    "value": "Tebat Layang"
  },
  {
    "id": "1609162007",
    "district_id": "160916",
    "label": "Watas",
    "value": "Watas"
  },
  {
    "id": "1609172001",
    "district_id": "160917",
    "label": "Tekana",
    "value": "Tekana"
  },
  {
    "id": "1609172002",
    "district_id": "160917",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1609172003",
    "district_id": "160917",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "1609172004",
    "district_id": "160917",
    "label": "Jagaraga",
    "value": "Jagaraga"
  },
  {
    "id": "1609172005",
    "district_id": "160917",
    "label": "Damarpura",
    "value": "Damarpura"
  },
  {
    "id": "1609172006",
    "district_id": "160917",
    "label": "Tunas Jaya",
    "value": "Tunas Jaya"
  },
  {
    "id": "1609172007",
    "district_id": "160917",
    "label": "Sinar Danau",
    "value": "Sinar Danau"
  },
  {
    "id": "1609172008",
    "district_id": "160917",
    "label": "Gemiung",
    "value": "Gemiung"
  },
  {
    "id": "1609182001",
    "district_id": "160918",
    "label": "Peninggiran",
    "value": "Peninggiran"
  },
  {
    "id": "1609182002",
    "district_id": "160918",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1609182003",
    "district_id": "160918",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "1609182004",
    "district_id": "160918",
    "label": "Sukarena",
    "value": "Sukarena"
  },
  {
    "id": "1609182005",
    "district_id": "160918",
    "label": "Karang Pendeta",
    "value": "Karang Pendeta"
  },
  {
    "id": "1609182006",
    "district_id": "160918",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1609182007",
    "district_id": "160918",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "1609182008",
    "district_id": "160918",
    "label": "Kuripan II",
    "value": "Kuripan II"
  },
  {
    "id": "1609192001",
    "district_id": "160919",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1609192002",
    "district_id": "160919",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1609192003",
    "district_id": "160919",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1609192004",
    "district_id": "160919",
    "label": "Ruos",
    "value": "Ruos"
  },
  {
    "id": "1609192005",
    "district_id": "160919",
    "label": "Gunung Cahya",
    "value": "Gunung Cahya"
  },
  {
    "id": "1609192006",
    "district_id": "160919",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "1609192007",
    "district_id": "160919",
    "label": "Bendi",
    "value": "Bendi"
  },
  {
    "id": "1609192008",
    "district_id": "160919",
    "label": "Pekuolan",
    "value": "Pekuolan"
  },
  {
    "id": "1609192009",
    "district_id": "160919",
    "label": "Pelawi",
    "value": "Pelawi"
  },
  {
    "id": "1609192011",
    "district_id": "160919",
    "label": "Majar",
    "value": "Majar"
  },
  {
    "id": "1609192012",
    "district_id": "160919",
    "label": "Bumi Agung Jaya",
    "value": "Bumi Agung Jaya"
  },
  {
    "id": "1610011011",
    "district_id": "161001",
    "label": "Muara Kuang",
    "value": "Muara Kuang"
  },
  {
    "id": "1610012005",
    "district_id": "161001",
    "label": "Munggu",
    "value": "Munggu"
  },
  {
    "id": "1610012006",
    "district_id": "161001",
    "label": "Seri Menanti",
    "value": "Seri Menanti"
  },
  {
    "id": "1610012007",
    "district_id": "161001",
    "label": "Rantau Sialang",
    "value": "Rantau Sialang"
  },
  {
    "id": "1610012008",
    "district_id": "161001",
    "label": "Kuang Anyar",
    "value": "Kuang Anyar"
  },
  {
    "id": "1610012009",
    "district_id": "161001",
    "label": "Kasah",
    "value": "Kasah"
  },
  {
    "id": "1610012012",
    "district_id": "161001",
    "label": "Tanabang Ulu",
    "value": "Tanabang Ulu"
  },
  {
    "id": "1610012013",
    "district_id": "161001",
    "label": "Seri Kembang",
    "value": "Seri Kembang"
  },
  {
    "id": "1610012014",
    "district_id": "161001",
    "label": "Nagasari",
    "value": "Nagasari"
  },
  {
    "id": "1610012015",
    "district_id": "161001",
    "label": "Suka Cinta",
    "value": "Suka Cinta"
  },
  {
    "id": "1610012016",
    "district_id": "161001",
    "label": "Kelampadu",
    "value": "Kelampadu"
  },
  {
    "id": "1610012029",
    "district_id": "161001",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1610012030",
    "district_id": "161001",
    "label": "Ramakasih",
    "value": "Ramakasih"
  },
  {
    "id": "1610012031",
    "district_id": "161001",
    "label": "Tanabang Ilir",
    "value": "Tanabang Ilir"
  },
  {
    "id": "1610021017",
    "district_id": "161002",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "1610021037",
    "district_id": "161002",
    "label": "Tanjung Batu Timur",
    "value": "Tanjung Batu Timur"
  },
  {
    "id": "1610022010",
    "district_id": "161002",
    "label": "Seri Bandung",
    "value": "Seri Bandung"
  },
  {
    "id": "1610022011",
    "district_id": "161002",
    "label": "Seri Tanjung",
    "value": "Seri Tanjung"
  },
  {
    "id": "1610022012",
    "district_id": "161002",
    "label": "Tanjung Tambak",
    "value": "Tanjung Tambak"
  },
  {
    "id": "1610022013",
    "district_id": "161002",
    "label": "Senuro Timur",
    "value": "Senuro Timur"
  },
  {
    "id": "1610022014",
    "district_id": "161002",
    "label": "Tanjung Baru Petai",
    "value": "Tanjung Baru Petai"
  },
  {
    "id": "1610022015",
    "district_id": "161002",
    "label": "Tanjung Batu Seberang",
    "value": "Tanjung Batu Seberang"
  },
  {
    "id": "1610022016",
    "district_id": "161002",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1610022018",
    "district_id": "161002",
    "label": "Tanjung Atap",
    "value": "Tanjung Atap"
  },
  {
    "id": "1610022019",
    "district_id": "161002",
    "label": "Tanjung Pinang I",
    "value": "Tanjung Pinang I"
  },
  {
    "id": "1610022020",
    "district_id": "161002",
    "label": "Limbang Jaya I",
    "value": "Limbang Jaya I"
  },
  {
    "id": "1610022021",
    "district_id": "161002",
    "label": "Tanjung Laut",
    "value": "Tanjung Laut"
  },
  {
    "id": "1610022026",
    "district_id": "161002",
    "label": "Burai",
    "value": "Burai"
  },
  {
    "id": "1610022027",
    "district_id": "161002",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "1610022031",
    "district_id": "161002",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1610022032",
    "district_id": "161002",
    "label": "Tanjung Tambak Baru",
    "value": "Tanjung Tambak Baru"
  },
  {
    "id": "1610022033",
    "district_id": "161002",
    "label": "Limbang Jaya II",
    "value": "Limbang Jaya II"
  },
  {
    "id": "1610022034",
    "district_id": "161002",
    "label": "Senuro Barat",
    "value": "Senuro Barat"
  },
  {
    "id": "1610022035",
    "district_id": "161002",
    "label": "Tanjung Pinang II",
    "value": "Tanjung Pinang II"
  },
  {
    "id": "1610022036",
    "district_id": "161002",
    "label": "Tanjung Atap Barat",
    "value": "Tanjung Atap Barat"
  },
  {
    "id": "1610031001",
    "district_id": "161003",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1610031027",
    "district_id": "161003",
    "label": "Tanjung Raja Barat",
    "value": "Tanjung Raja Barat"
  },
  {
    "id": "1610031028",
    "district_id": "161003",
    "label": "Tanjung Raja Utara",
    "value": "Tanjung Raja Utara"
  },
  {
    "id": "1610031035",
    "district_id": "161003",
    "label": "Tanjung Raja Timur",
    "value": "Tanjung Raja Timur"
  },
  {
    "id": "1610032002",
    "district_id": "161003",
    "label": "Suka Pindah",
    "value": "Suka Pindah"
  },
  {
    "id": "1610032007",
    "district_id": "161003",
    "label": "Tanjung Temiang",
    "value": "Tanjung Temiang"
  },
  {
    "id": "1610032008",
    "district_id": "161003",
    "label": "Seri Dalam",
    "value": "Seri Dalam"
  },
  {
    "id": "1610032018",
    "district_id": "161003",
    "label": "Kerinjing",
    "value": "Kerinjing"
  },
  {
    "id": "1610032019",
    "district_id": "161003",
    "label": "Ulak Kerbau Baru",
    "value": "Ulak Kerbau Baru"
  },
  {
    "id": "1610032020",
    "district_id": "161003",
    "label": "Ulak Kerbau Lama",
    "value": "Ulak Kerbau Lama"
  },
  {
    "id": "1610032021",
    "district_id": "161003",
    "label": "Tanjung Agas",
    "value": "Tanjung Agas"
  },
  {
    "id": "1610032022",
    "district_id": "161003",
    "label": "Siring Alam",
    "value": "Siring Alam"
  },
  {
    "id": "1610032023",
    "district_id": "161003",
    "label": "Talang Balai Baru I",
    "value": "Talang Balai Baru I"
  },
  {
    "id": "1610032024",
    "district_id": "161003",
    "label": "Talang Balai Lama",
    "value": "Talang Balai Lama"
  },
  {
    "id": "1610032030",
    "district_id": "161003",
    "label": "Skonjing",
    "value": "Skonjing"
  },
  {
    "id": "1610032031",
    "district_id": "161003",
    "label": "Tanjung Raja Selatan",
    "value": "Tanjung Raja Selatan"
  },
  {
    "id": "1610032032",
    "district_id": "161003",
    "label": "Belanti",
    "value": "Belanti"
  },
  {
    "id": "1610032033",
    "district_id": "161003",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1610032034",
    "district_id": "161003",
    "label": "Talang Balai Baru II",
    "value": "Talang Balai Baru II"
  },
  {
    "id": "1610041030",
    "district_id": "161004",
    "label": "Indralaya Raya",
    "value": "Indralaya Raya"
  },
  {
    "id": "1610041031",
    "district_id": "161004",
    "label": "Indralaya Indah",
    "value": "Indralaya Indah"
  },
  {
    "id": "1610041032",
    "district_id": "161004",
    "label": "Indralaya Mulia",
    "value": "Indralaya Mulia"
  },
  {
    "id": "1610042005",
    "district_id": "161004",
    "label": "Lubuk Sakti",
    "value": "Lubuk Sakti"
  },
  {
    "id": "1610042006",
    "district_id": "161004",
    "label": "Tanjung Gelam",
    "value": "Tanjung Gelam"
  },
  {
    "id": "1610042007",
    "district_id": "161004",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1610042008",
    "district_id": "161004",
    "label": "Tanjung Sejaro",
    "value": "Tanjung Sejaro"
  },
  {
    "id": "1610042009",
    "district_id": "161004",
    "label": "Sakatiga Seberang",
    "value": "Sakatiga Seberang"
  },
  {
    "id": "1610042010",
    "district_id": "161004",
    "label": "Muara P. Ulu",
    "value": "Muara P. Ulu"
  },
  {
    "id": "1610042012",
    "district_id": "161004",
    "label": "Tanjung Seteko",
    "value": "Tanjung Seteko"
  },
  {
    "id": "1610042013",
    "district_id": "161004",
    "label": "Saka Tiga",
    "value": "Saka Tiga"
  },
  {
    "id": "1610042014",
    "district_id": "161004",
    "label": "Ulak Banding",
    "value": "Ulak Banding"
  },
  {
    "id": "1610042015",
    "district_id": "161004",
    "label": "Muara P. Ilir",
    "value": "Muara P. Ilir"
  },
  {
    "id": "1610042016",
    "district_id": "161004",
    "label": "Talang Aur",
    "value": "Talang Aur"
  },
  {
    "id": "1610042017",
    "district_id": "161004",
    "label": "Ulak Bedil",
    "value": "Ulak Bedil"
  },
  {
    "id": "1610042018",
    "district_id": "161004",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1610042019",
    "district_id": "161004",
    "label": "Sudimampir",
    "value": "Sudimampir"
  },
  {
    "id": "1610042027",
    "district_id": "161004",
    "label": "Ulak Segelung",
    "value": "Ulak Segelung"
  },
  {
    "id": "1610042028",
    "district_id": "161004",
    "label": "Sejaro Sakti",
    "value": "Sejaro Sakti"
  },
  {
    "id": "1610042029",
    "district_id": "161004",
    "label": "Tunas Aur",
    "value": "Tunas Aur"
  },
  {
    "id": "1610052015",
    "district_id": "161005",
    "label": "Aurstanding",
    "value": "Aurstanding"
  },
  {
    "id": "1610052016",
    "district_id": "161005",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1610052017",
    "district_id": "161005",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1610052018",
    "district_id": "161005",
    "label": "Palu",
    "value": "Palu"
  },
  {
    "id": "1610052019",
    "district_id": "161005",
    "label": "Teluk Kecapi",
    "value": "Teluk Kecapi"
  },
  {
    "id": "1610052020",
    "district_id": "161005",
    "label": "Pelabuhan Dalam",
    "value": "Pelabuhan Dalam"
  },
  {
    "id": "1610052021",
    "district_id": "161005",
    "label": "Pemulutan Ulu",
    "value": "Pemulutan Ulu"
  },
  {
    "id": "1610052022",
    "district_id": "161005",
    "label": "Pemulutan Ilir",
    "value": "Pemulutan Ilir"
  },
  {
    "id": "1610052023",
    "district_id": "161005",
    "label": "Sungai Rasau",
    "value": "Sungai Rasau"
  },
  {
    "id": "1610052024",
    "district_id": "161005",
    "label": "Ibul Besar I",
    "value": "Ibul Besar I"
  },
  {
    "id": "1610052025",
    "district_id": "161005",
    "label": "Pegayut",
    "value": "Pegayut"
  },
  {
    "id": "1610052029",
    "district_id": "161005",
    "label": "S. Pelabuhan Dlm",
    "value": "S. Pelabuhan Dlm"
  },
  {
    "id": "1610052030",
    "district_id": "161005",
    "label": "Ibul Besar II",
    "value": "Ibul Besar II"
  },
  {
    "id": "1610052031",
    "district_id": "161005",
    "label": "Ibul Besar III",
    "value": "Ibul Besar III"
  },
  {
    "id": "1610052032",
    "district_id": "161005",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1610052033",
    "district_id": "161005",
    "label": "Kedukan Bujang",
    "value": "Kedukan Bujang"
  },
  {
    "id": "1610052034",
    "district_id": "161005",
    "label": "Rawa Jaya",
    "value": "Rawa Jaya"
  },
  {
    "id": "1610052035",
    "district_id": "161005",
    "label": "Babatan Saudagar",
    "value": "Babatan Saudagar"
  },
  {
    "id": "1610052036",
    "district_id": "161005",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "1610052037",
    "district_id": "161005",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "1610052038",
    "district_id": "161005",
    "label": "Pipa Putih",
    "value": "Pipa Putih"
  },
  {
    "id": "1610052039",
    "district_id": "161005",
    "label": "Sungai Buaya",
    "value": "Sungai Buaya"
  },
  {
    "id": "1610052040",
    "district_id": "161005",
    "label": "Muara Baru",
    "value": "Muara Baru"
  },
  {
    "id": "1610052041",
    "district_id": "161005",
    "label": "Sembadak",
    "value": "Sembadak"
  },
  {
    "id": "1610052042",
    "district_id": "161005",
    "label": "Lebung Jangkar",
    "value": "Lebung Jangkar"
  },
  {
    "id": "1610062006",
    "district_id": "161006",
    "label": "Sirah Pulau Kilip",
    "value": "Sirah Pulau Kilip"
  },
  {
    "id": "1610062011",
    "district_id": "161006",
    "label": "Kelampaian",
    "value": "Kelampaian"
  },
  {
    "id": "1610062012",
    "district_id": "161006",
    "label": "Sukananti",
    "value": "Sukananti"
  },
  {
    "id": "1610062013",
    "district_id": "161006",
    "label": "Sukamarga",
    "value": "Sukamarga"
  },
  {
    "id": "1610062014",
    "district_id": "161006",
    "label": "Rantau Alai",
    "value": "Rantau Alai"
  },
  {
    "id": "1610062015",
    "district_id": "161006",
    "label": "Kerta Bayang",
    "value": "Kerta Bayang"
  },
  {
    "id": "1610062016",
    "district_id": "161006",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "1610062017",
    "district_id": "161006",
    "label": "Talang Sari",
    "value": "Talang Sari"
  },
  {
    "id": "1610062018",
    "district_id": "161006",
    "label": "Lebung Bandung",
    "value": "Lebung Bandung"
  },
  {
    "id": "1610062020",
    "district_id": "161006",
    "label": "Sanding Marga",
    "value": "Sanding Marga"
  },
  {
    "id": "1610062021",
    "district_id": "161006",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1610062022",
    "district_id": "161006",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1610062023",
    "district_id": "161006",
    "label": "Sukananti Baru",
    "value": "Sukananti Baru"
  },
  {
    "id": "1610071016",
    "district_id": "161007",
    "label": "Timbangan",
    "value": "Timbangan"
  },
  {
    "id": "1610072001",
    "district_id": "161007",
    "label": "Tanjung Pering",
    "value": "Tanjung Pering"
  },
  {
    "id": "1610072002",
    "district_id": "161007",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "1610072003",
    "district_id": "161007",
    "label": "Lorok",
    "value": "Lorok"
  },
  {
    "id": "1610072004",
    "district_id": "161007",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "1610072005",
    "district_id": "161007",
    "label": "Payakabung",
    "value": "Payakabung"
  },
  {
    "id": "1610072006",
    "district_id": "161007",
    "label": "Sungai Rambutan",
    "value": "Sungai Rambutan"
  },
  {
    "id": "1610072007",
    "district_id": "161007",
    "label": "Purna Jaya",
    "value": "Purna Jaya"
  },
  {
    "id": "1610072008",
    "district_id": "161007",
    "label": "Soak Batok",
    "value": "Soak Batok"
  },
  {
    "id": "1610072009",
    "district_id": "161007",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1610072010",
    "district_id": "161007",
    "label": "Tanjung Pule",
    "value": "Tanjung Pule"
  },
  {
    "id": "1610072011",
    "district_id": "161007",
    "label": "Permata Baru",
    "value": "Permata Baru"
  },
  {
    "id": "1610072012",
    "district_id": "161007",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "1610072013",
    "district_id": "161007",
    "label": "Pulau Kabai",
    "value": "Pulau Kabai"
  },
  {
    "id": "1610072014",
    "district_id": "161007",
    "label": "Pulau Semambu",
    "value": "Pulau Semambu"
  },
  {
    "id": "1610072015",
    "district_id": "161007",
    "label": "Palem Raya",
    "value": "Palem Raya"
  },
  {
    "id": "1610082001",
    "district_id": "161008",
    "label": "Mandi Angin",
    "value": "Mandi Angin"
  },
  {
    "id": "1610082002",
    "district_id": "161008",
    "label": "Tanjung Lubuk",
    "value": "Tanjung Lubuk"
  },
  {
    "id": "1610082003",
    "district_id": "161008",
    "label": "Tebing Gerinting Selatan",
    "value": "Tebing Gerinting Selatan"
  },
  {
    "id": "1610082004",
    "district_id": "161008",
    "label": "Arisan Gading",
    "value": "Arisan Gading"
  },
  {
    "id": "1610082005",
    "district_id": "161008",
    "label": "Tanjung Dayang Selatan",
    "value": "Tanjung Dayang Selatan"
  },
  {
    "id": "1610082006",
    "district_id": "161008",
    "label": "Beti",
    "value": "Beti"
  },
  {
    "id": "1610082007",
    "district_id": "161008",
    "label": "Meranjat I",
    "value": "Meranjat I"
  },
  {
    "id": "1610082008",
    "district_id": "161008",
    "label": "Meranjat Ilir",
    "value": "Meranjat Ilir"
  },
  {
    "id": "1610082009",
    "district_id": "161008",
    "label": "Meranjat II",
    "value": "Meranjat II"
  },
  {
    "id": "1610082010",
    "district_id": "161008",
    "label": "Sukaraja Lama",
    "value": "Sukaraja Lama"
  },
  {
    "id": "1610082011",
    "district_id": "161008",
    "label": "Sukaraja Baru",
    "value": "Sukaraja Baru"
  },
  {
    "id": "1610082012",
    "district_id": "161008",
    "label": "Tebing Gerinting Utara",
    "value": "Tebing Gerinting Utara"
  },
  {
    "id": "1610082013",
    "district_id": "161008",
    "label": "Tanjung Dayang Utara",
    "value": "Tanjung Dayang Utara"
  },
  {
    "id": "1610082014",
    "district_id": "161008",
    "label": "Meranjat III",
    "value": "Meranjat III"
  },
  {
    "id": "1610092001",
    "district_id": "161009",
    "label": "Pematang Bangsal",
    "value": "Pematang Bangsal"
  },
  {
    "id": "1610092002",
    "district_id": "161009",
    "label": "Maya Pati",
    "value": "Maya Pati"
  },
  {
    "id": "1610092003",
    "district_id": "161009",
    "label": "Sungai Lebung",
    "value": "Sungai Lebung"
  },
  {
    "id": "1610092005",
    "district_id": "161009",
    "label": "Kapuk",
    "value": "Kapuk"
  },
  {
    "id": "1610092006",
    "district_id": "161009",
    "label": "Ulak Aurstanding",
    "value": "Ulak Aurstanding"
  },
  {
    "id": "1610092007",
    "district_id": "161009",
    "label": "Maju Jaya",
    "value": "Maju Jaya"
  },
  {
    "id": "1610092008",
    "district_id": "161009",
    "label": "Naikan Tembakang",
    "value": "Naikan Tembakang"
  },
  {
    "id": "1610092009",
    "district_id": "161009",
    "label": "Segayam",
    "value": "Segayam"
  },
  {
    "id": "1610092010",
    "district_id": "161009",
    "label": "Cahaya Marga",
    "value": "Cahaya Marga"
  },
  {
    "id": "1610092011",
    "district_id": "161009",
    "label": "S. Lebung Ulu",
    "value": "S. Lebung Ulu"
  },
  {
    "id": "1610092012",
    "district_id": "161009",
    "label": "Sungai Keli",
    "value": "Sungai Keli"
  },
  {
    "id": "1610092013",
    "district_id": "161009",
    "label": "Lebak Pering",
    "value": "Lebak Pering"
  },
  {
    "id": "1610092014",
    "district_id": "161009",
    "label": "Sungai Ondok",
    "value": "Sungai Ondok"
  },
  {
    "id": "1610092015",
    "district_id": "161009",
    "label": "Harimau Tandang",
    "value": "Harimau Tandang"
  },
  {
    "id": "1610092016",
    "district_id": "161009",
    "label": "Pematang Bungur",
    "value": "Pematang Bungur"
  },
  {
    "id": "1610102001",
    "district_id": "161010",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1610102002",
    "district_id": "161010",
    "label": "Sarang Elang",
    "value": "Sarang Elang"
  },
  {
    "id": "1610102003",
    "district_id": "161010",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "1610102004",
    "district_id": "161010",
    "label": "Pulau Negara",
    "value": "Pulau Negara"
  },
  {
    "id": "1610102005",
    "district_id": "161010",
    "label": "Talang Pangeran Ulu",
    "value": "Talang Pangeran Ulu"
  },
  {
    "id": "1610102006",
    "district_id": "161010",
    "label": "Ulak Kembahang I",
    "value": "Ulak Kembahang I"
  },
  {
    "id": "1610102007",
    "district_id": "161010",
    "label": "Ulak Petangisan",
    "value": "Ulak Petangisan"
  },
  {
    "id": "1610102008",
    "district_id": "161010",
    "label": "Seri Bandung",
    "value": "Seri Bandung"
  },
  {
    "id": "1610102009",
    "district_id": "161010",
    "label": "Ulak Kembahang II",
    "value": "Ulak Kembahang II"
  },
  {
    "id": "1610102010",
    "district_id": "161010",
    "label": "Talang Pangeran Ilir",
    "value": "Talang Pangeran Ilir"
  },
  {
    "id": "1610102011",
    "district_id": "161010",
    "label": "Arisan Jaya",
    "value": "Arisan Jaya"
  },
  {
    "id": "1610112001",
    "district_id": "161011",
    "label": "Kotadaro I",
    "value": "Kotadaro I"
  },
  {
    "id": "1610112002",
    "district_id": "161011",
    "label": "Ketapang I",
    "value": "Ketapang I"
  },
  {
    "id": "1610112003",
    "district_id": "161011",
    "label": "Rantau Panjang Ilir",
    "value": "Rantau Panjang Ilir"
  },
  {
    "id": "1610112004",
    "district_id": "161011",
    "label": "Jagaraja",
    "value": "Jagaraja"
  },
  {
    "id": "1610112005",
    "district_id": "161011",
    "label": "Jagalano",
    "value": "Jagalano"
  },
  {
    "id": "1610112006",
    "district_id": "161011",
    "label": "Sejangko I",
    "value": "Sejangko I"
  },
  {
    "id": "1610112007",
    "district_id": "161011",
    "label": "Kota Daro II",
    "value": "Kota Daro II"
  },
  {
    "id": "1610112008",
    "district_id": "161011",
    "label": "Sungai Rotan",
    "value": "Sungai Rotan"
  },
  {
    "id": "1610112009",
    "district_id": "161011",
    "label": "Rantau Panjang Ulu",
    "value": "Rantau Panjang Ulu"
  },
  {
    "id": "1610112010",
    "district_id": "161011",
    "label": "Ketapang II",
    "value": "Ketapang II"
  },
  {
    "id": "1610112011",
    "district_id": "161011",
    "label": "Sejangko II",
    "value": "Sejangko II"
  },
  {
    "id": "1610112012",
    "district_id": "161011",
    "label": "Arisan Deras",
    "value": "Arisan Deras"
  },
  {
    "id": "1610121007",
    "district_id": "161012",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "1610122001",
    "district_id": "161012",
    "label": "Serijabo",
    "value": "Serijabo"
  },
  {
    "id": "1610122002",
    "district_id": "161012",
    "label": "Sungai Pinang II",
    "value": "Sungai Pinang II"
  },
  {
    "id": "1610122003",
    "district_id": "161012",
    "label": "Talang Dukun",
    "value": "Talang Dukun"
  },
  {
    "id": "1610122004",
    "district_id": "161012",
    "label": "Tanjung Serian",
    "value": "Tanjung Serian"
  },
  {
    "id": "1610122005",
    "district_id": "161012",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1610122006",
    "district_id": "161012",
    "label": "Sungai Pinang III",
    "value": "Sungai Pinang III"
  },
  {
    "id": "1610122008",
    "district_id": "161012",
    "label": "Pinang Jaya",
    "value": "Pinang Jaya"
  },
  {
    "id": "1610122009",
    "district_id": "161012",
    "label": "Sungai Pinang I",
    "value": "Sungai Pinang I"
  },
  {
    "id": "1610122010",
    "district_id": "161012",
    "label": "Pinang Mas",
    "value": "Pinang Mas"
  },
  {
    "id": "1610122011",
    "district_id": "161012",
    "label": "S. Pinang Lagati",
    "value": "S. Pinang Lagati"
  },
  {
    "id": "1610122012",
    "district_id": "161012",
    "label": "S. Pinang Nibung",
    "value": "S. Pinang Nibung"
  },
  {
    "id": "1610122013",
    "district_id": "161012",
    "label": "Seri Jabo Baru",
    "value": "Seri Jabo Baru"
  },
  {
    "id": "1610132001",
    "district_id": "161013",
    "label": "Lubuk Rukam",
    "value": "Lubuk Rukam"
  },
  {
    "id": "1610132002",
    "district_id": "161013",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1610132003",
    "district_id": "161013",
    "label": "Kandis I",
    "value": "Kandis I"
  },
  {
    "id": "1610132004",
    "district_id": "161013",
    "label": "Kumbang Ulu",
    "value": "Kumbang Ulu"
  },
  {
    "id": "1610132005",
    "district_id": "161013",
    "label": "Kumbang Ilir",
    "value": "Kumbang Ilir"
  },
  {
    "id": "1610132006",
    "district_id": "161013",
    "label": "Miji",
    "value": "Miji"
  },
  {
    "id": "1610132007",
    "district_id": "161013",
    "label": "Santapan Barat",
    "value": "Santapan Barat"
  },
  {
    "id": "1610132008",
    "district_id": "161013",
    "label": "Pandan Arang",
    "value": "Pandan Arang"
  },
  {
    "id": "1610132009",
    "district_id": "161013",
    "label": "Muara Kumbang",
    "value": "Muara Kumbang"
  },
  {
    "id": "1610132010",
    "district_id": "161013",
    "label": "Kandis II",
    "value": "Kandis II"
  },
  {
    "id": "1610132011",
    "district_id": "161013",
    "label": "Santapan Timur",
    "value": "Santapan Timur"
  },
  {
    "id": "1610132012",
    "district_id": "161013",
    "label": "Lubuk Segonang",
    "value": "Lubuk Segonang"
  },
  {
    "id": "1610142001",
    "district_id": "161014",
    "label": "Ibul Dalam",
    "value": "Ibul Dalam"
  },
  {
    "id": "1610142002",
    "district_id": "161014",
    "label": "Beringin Dalam",
    "value": "Beringin Dalam"
  },
  {
    "id": "1610142003",
    "district_id": "161014",
    "label": "Kuang Dalam Barat",
    "value": "Kuang Dalam Barat"
  },
  {
    "id": "1610142004",
    "district_id": "161014",
    "label": "Lubuk Tunggal",
    "value": "Lubuk Tunggal"
  },
  {
    "id": "1610142005",
    "district_id": "161014",
    "label": "Ulak Segara",
    "value": "Ulak Segara"
  },
  {
    "id": "1610142006",
    "district_id": "161014",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1610142007",
    "district_id": "161014",
    "label": "Tambang Rambang",
    "value": "Tambang Rambang"
  },
  {
    "id": "1610142008",
    "district_id": "161014",
    "label": "Tangai/Sukananti",
    "value": "Tangai/Sukananti"
  },
  {
    "id": "1610142009",
    "district_id": "161014",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "1610142010",
    "district_id": "161014",
    "label": "Tanjung Miring",
    "value": "Tanjung Miring"
  },
  {
    "id": "1610142011",
    "district_id": "161014",
    "label": "Sunur",
    "value": "Sunur"
  },
  {
    "id": "1610142012",
    "district_id": "161014",
    "label": "Kuang Dalam Timur",
    "value": "Kuang Dalam Timur"
  },
  {
    "id": "1610142013",
    "district_id": "161014",
    "label": "Suka Tangai",
    "value": "Suka Tangai"
  },
  {
    "id": "1610152001",
    "district_id": "161015",
    "label": "Ulak Kembahang",
    "value": "Ulak Kembahang"
  },
  {
    "id": "1610152002",
    "district_id": "161015",
    "label": "Lubuk Keliat",
    "value": "Lubuk Keliat"
  },
  {
    "id": "1610152003",
    "district_id": "161015",
    "label": "Embecang",
    "value": "Embecang"
  },
  {
    "id": "1610152004",
    "district_id": "161015",
    "label": "Kasi Raja",
    "value": "Kasi Raja"
  },
  {
    "id": "1610152005",
    "district_id": "161015",
    "label": "Talang Tengah Darat",
    "value": "Talang Tengah Darat"
  },
  {
    "id": "1610152006",
    "district_id": "161015",
    "label": "Betung II",
    "value": "Betung II"
  },
  {
    "id": "1610152007",
    "district_id": "161015",
    "label": "Ketiau",
    "value": "Ketiau"
  },
  {
    "id": "1610152008",
    "district_id": "161015",
    "label": "Payalengkang",
    "value": "Payalengkang"
  },
  {
    "id": "1610152009",
    "district_id": "161015",
    "label": "Betung I",
    "value": "Betung I"
  },
  {
    "id": "1610152010",
    "district_id": "161015",
    "label": "Talang Tengah Laut",
    "value": "Talang Tengah Laut"
  },
  {
    "id": "1610161012",
    "district_id": "161016",
    "label": "Payaraman Barat",
    "value": "Payaraman Barat"
  },
  {
    "id": "1610161013",
    "district_id": "161016",
    "label": "Payaraman Timur",
    "value": "Payaraman Timur"
  },
  {
    "id": "1610162001",
    "district_id": "161016",
    "label": "Tebedak I",
    "value": "Tebedak I"
  },
  {
    "id": "1610162002",
    "district_id": "161016",
    "label": "Lubuk Bandung",
    "value": "Lubuk Bandung"
  },
  {
    "id": "1610162003",
    "district_id": "161016",
    "label": "Talang Seleman",
    "value": "Talang Seleman"
  },
  {
    "id": "1610162004",
    "district_id": "161016",
    "label": "Tebedak II",
    "value": "Tebedak II"
  },
  {
    "id": "1610162005",
    "district_id": "161016",
    "label": "Tanjung lalang",
    "value": "Tanjung lalang"
  },
  {
    "id": "1610162006",
    "district_id": "161016",
    "label": "Seri Kembang I",
    "value": "Seri Kembang I"
  },
  {
    "id": "1610162007",
    "district_id": "161016",
    "label": "Rengas I",
    "value": "Rengas I"
  },
  {
    "id": "1610162008",
    "district_id": "161016",
    "label": "Paya Besar",
    "value": "Paya Besar"
  },
  {
    "id": "1610162009",
    "district_id": "161016",
    "label": "Rengas II",
    "value": "Rengas II"
  },
  {
    "id": "1610162010",
    "district_id": "161016",
    "label": "Seri Kembang II",
    "value": "Seri Kembang II"
  },
  {
    "id": "1610162011",
    "district_id": "161016",
    "label": "Seri Kembang III",
    "value": "Seri Kembang III"
  },
  {
    "id": "1611012001",
    "district_id": "161101",
    "label": "Batu Jungul",
    "value": "Batu Jungul"
  },
  {
    "id": "1611012002",
    "district_id": "161101",
    "label": "Suka Dana",
    "value": "Suka Dana"
  },
  {
    "id": "1611012003",
    "district_id": "161101",
    "label": "Padang Burnai",
    "value": "Padang Burnai"
  },
  {
    "id": "1611012004",
    "district_id": "161101",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "1611012005",
    "district_id": "161101",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1611012006",
    "district_id": "161101",
    "label": "Muara Pinang Baru",
    "value": "Muara Pinang Baru"
  },
  {
    "id": "1611012007",
    "district_id": "161101",
    "label": "Lubuk Tanjung",
    "value": "Lubuk Tanjung"
  },
  {
    "id": "1611012008",
    "district_id": "161101",
    "label": "Talang Baru",
    "value": "Talang Baru"
  },
  {
    "id": "1611012009",
    "district_id": "161101",
    "label": "Sapa Panjang",
    "value": "Sapa Panjang"
  },
  {
    "id": "1611012010",
    "district_id": "161101",
    "label": "Muara Pinang Lama",
    "value": "Muara Pinang Lama"
  },
  {
    "id": "1611012011",
    "district_id": "161101",
    "label": "Tanjung Tawang",
    "value": "Tanjung Tawang"
  },
  {
    "id": "1611012012",
    "district_id": "161101",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1611012013",
    "district_id": "161101",
    "label": "Pajar Menang",
    "value": "Pajar Menang"
  },
  {
    "id": "1611012014",
    "district_id": "161101",
    "label": "Niur",
    "value": "Niur"
  },
  {
    "id": "1611012015",
    "district_id": "161101",
    "label": "Muara Semah",
    "value": "Muara Semah"
  },
  {
    "id": "1611012016",
    "district_id": "161101",
    "label": "Talang Benteng",
    "value": "Talang Benteng"
  },
  {
    "id": "1611012017",
    "district_id": "161101",
    "label": "Lubuk Ulak",
    "value": "Lubuk Ulak"
  },
  {
    "id": "1611012018",
    "district_id": "161101",
    "label": "Batu Galang",
    "value": "Batu Galang"
  },
  {
    "id": "1611012019",
    "district_id": "161101",
    "label": "Muara Timbuk",
    "value": "Muara Timbuk"
  },
  {
    "id": "1611012020",
    "district_id": "161101",
    "label": "Seleman Ilir",
    "value": "Seleman Ilir"
  },
  {
    "id": "1611012021",
    "district_id": "161101",
    "label": "Seleman Ulu",
    "value": "Seleman Ulu"
  },
  {
    "id": "1611012022",
    "district_id": "161101",
    "label": "Sawah",
    "value": "Sawah"
  },
  {
    "id": "1611021018",
    "district_id": "161102",
    "label": "Beruge Ilir",
    "value": "Beruge Ilir"
  },
  {
    "id": "1611021030",
    "district_id": "161102",
    "label": "Pendopo",
    "value": "Pendopo"
  },
  {
    "id": "1611021031",
    "district_id": "161102",
    "label": "Pagar Tengah",
    "value": "Pagar Tengah"
  },
  {
    "id": "1611022001",
    "district_id": "161102",
    "label": "Jarakan",
    "value": "Jarakan"
  },
  {
    "id": "1611022002",
    "district_id": "161102",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1611022003",
    "district_id": "161102",
    "label": "Lubuk Sepang",
    "value": "Lubuk Sepang"
  },
  {
    "id": "1611022004",
    "district_id": "161102",
    "label": "Lubuk Layang",
    "value": "Lubuk Layang"
  },
  {
    "id": "1611022005",
    "district_id": "161102",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1611022006",
    "district_id": "161102",
    "label": "Tanjung Eran",
    "value": "Tanjung Eran"
  },
  {
    "id": "1611022007",
    "district_id": "161102",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1611022020",
    "district_id": "161102",
    "label": "Bayau",
    "value": "Bayau"
  },
  {
    "id": "1611022022",
    "district_id": "161102",
    "label": "Gn. Meraksa Baru",
    "value": "Gn. Meraksa Baru"
  },
  {
    "id": "1611022023",
    "district_id": "161102",
    "label": "Landur",
    "value": "Landur"
  },
  {
    "id": "1611022024",
    "district_id": "161102",
    "label": "Muara Karang",
    "value": "Muara Karang"
  },
  {
    "id": "1611022025",
    "district_id": "161102",
    "label": "Sarang Bulan",
    "value": "Sarang Bulan"
  },
  {
    "id": "1611022026",
    "district_id": "161102",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1611022027",
    "district_id": "161102",
    "label": "Batu Cawang",
    "value": "Batu Cawang"
  },
  {
    "id": "1611022028",
    "district_id": "161102",
    "label": "Manggilan",
    "value": "Manggilan"
  },
  {
    "id": "1611022029",
    "district_id": "161102",
    "label": "Gn Merakasa Lama",
    "value": "Gn Merakasa Lama"
  },
  {
    "id": "1611032001",
    "district_id": "161103",
    "label": "Air Kelinsar",
    "value": "Air Kelinsar"
  },
  {
    "id": "1611032002",
    "district_id": "161103",
    "label": "Talang Bengkulu",
    "value": "Talang Bengkulu"
  },
  {
    "id": "1611032003",
    "district_id": "161103",
    "label": "Kunduran",
    "value": "Kunduran"
  },
  {
    "id": "1611032004",
    "district_id": "161103",
    "label": "Simpang Perigi",
    "value": "Simpang Perigi"
  },
  {
    "id": "1611032005",
    "district_id": "161103",
    "label": "Muara Kalangan",
    "value": "Muara Kalangan"
  },
  {
    "id": "1611032006",
    "district_id": "161103",
    "label": "Batu Lintang",
    "value": "Batu Lintang"
  },
  {
    "id": "1611032007",
    "district_id": "161103",
    "label": "Padang Tepong",
    "value": "Padang Tepong"
  },
  {
    "id": "1611032008",
    "district_id": "161103",
    "label": "Muara Betung",
    "value": "Muara Betung"
  },
  {
    "id": "1611032009",
    "district_id": "161103",
    "label": "Lb. Puding Baru",
    "value": "Lb. Puding Baru"
  },
  {
    "id": "1611032010",
    "district_id": "161103",
    "label": "Pulau Kemang",
    "value": "Pulau Kemang"
  },
  {
    "id": "1611032011",
    "district_id": "161103",
    "label": "Lb. Puding Lama",
    "value": "Lb. Puding Lama"
  },
  {
    "id": "1611032012",
    "district_id": "161103",
    "label": "Batu Bidung",
    "value": "Batu Bidung"
  },
  {
    "id": "1611032024",
    "district_id": "161103",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1611032025",
    "district_id": "161103",
    "label": "Galang",
    "value": "Galang"
  },
  {
    "id": "1611041010",
    "district_id": "161104",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "1611041013",
    "district_id": "161104",
    "label": "Tanjungkupang",
    "value": "Tanjungkupang"
  },
  {
    "id": "1611041014",
    "district_id": "161104",
    "label": "Tanjung Makmur",
    "value": "Tanjung Makmur"
  },
  {
    "id": "1611041017",
    "district_id": "161104",
    "label": "Ps. Tebing Tinggi",
    "value": "Ps. Tebing Tinggi"
  },
  {
    "id": "1611041033",
    "district_id": "161104",
    "label": "Jaya Loka",
    "value": "Jaya Loka"
  },
  {
    "id": "1611041036",
    "district_id": "161104",
    "label": "Kelumpang Jaya",
    "value": "Kelumpang Jaya"
  },
  {
    "id": "1611042001",
    "district_id": "161104",
    "label": "Ujung Alih",
    "value": "Ujung Alih"
  },
  {
    "id": "1611042002",
    "district_id": "161104",
    "label": "Lb. Gelanggang",
    "value": "Lb. Gelanggang"
  },
  {
    "id": "1611042003",
    "district_id": "161104",
    "label": "Ulak Mengkudu",
    "value": "Ulak Mengkudu"
  },
  {
    "id": "1611042004",
    "district_id": "161104",
    "label": "Batu Pance",
    "value": "Batu Pance"
  },
  {
    "id": "1611042005",
    "district_id": "161104",
    "label": "Aur Gading",
    "value": "Aur Gading"
  },
  {
    "id": "1611042006",
    "district_id": "161104",
    "label": "Terusan Lama",
    "value": "Terusan Lama"
  },
  {
    "id": "1611042007",
    "district_id": "161104",
    "label": "Terusan Baru",
    "value": "Terusan Baru"
  },
  {
    "id": "1611042008",
    "district_id": "161104",
    "label": "Rantau Tenang",
    "value": "Rantau Tenang"
  },
  {
    "id": "1611042009",
    "district_id": "161104",
    "label": "Seguring Kecil",
    "value": "Seguring Kecil"
  },
  {
    "id": "1611042011",
    "district_id": "161104",
    "label": "Kemang Manis",
    "value": "Kemang Manis"
  },
  {
    "id": "1611042012",
    "district_id": "161104",
    "label": "Lampar Baru",
    "value": "Lampar Baru"
  },
  {
    "id": "1611042021",
    "district_id": "161104",
    "label": "Kota Gading",
    "value": "Kota Gading"
  },
  {
    "id": "1611042022",
    "district_id": "161104",
    "label": "Batu Raja Baru",
    "value": "Batu Raja Baru"
  },
  {
    "id": "1611042023",
    "district_id": "161104",
    "label": "Batu Raja Lama",
    "value": "Batu Raja Lama"
  },
  {
    "id": "1611042024",
    "district_id": "161104",
    "label": "Sugi Waras",
    "value": "Sugi Waras"
  },
  {
    "id": "1611042027",
    "district_id": "161104",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1611042030",
    "district_id": "161104",
    "label": "Fajar Bakti",
    "value": "Fajar Bakti"
  },
  {
    "id": "1611042032",
    "district_id": "161104",
    "label": "Pancur Mas",
    "value": "Pancur Mas"
  },
  {
    "id": "1611042034",
    "district_id": "161104",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "1611042035",
    "district_id": "161104",
    "label": "Tanjung Kupang Baru",
    "value": "Tanjung Kupang Baru"
  },
  {
    "id": "1611052001",
    "district_id": "161105",
    "label": "Umo Jati",
    "value": "Umo Jati"
  },
  {
    "id": "1611052002",
    "district_id": "161105",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1611052003",
    "district_id": "161105",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1611052004",
    "district_id": "161105",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "1611052005",
    "district_id": "161105",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1611052006",
    "district_id": "161105",
    "label": "Karang Tanding",
    "value": "Karang Tanding"
  },
  {
    "id": "1611052007",
    "district_id": "161105",
    "label": "Lubuk Cik",
    "value": "Lubuk Cik"
  },
  {
    "id": "1611052008",
    "district_id": "161105",
    "label": "Rantau Kasai",
    "value": "Rantau Kasai"
  },
  {
    "id": "1611052009",
    "district_id": "161105",
    "label": "Suka Rami",
    "value": "Suka Rami"
  },
  {
    "id": "1611052010",
    "district_id": "161105",
    "label": "Rantau Alih",
    "value": "Rantau Alih"
  },
  {
    "id": "1611052011",
    "district_id": "161105",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1611052012",
    "district_id": "161105",
    "label": "Lesung Batu",
    "value": "Lesung Batu"
  },
  {
    "id": "1611052013",
    "district_id": "161105",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "1611052014",
    "district_id": "161105",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1611052015",
    "district_id": "161105",
    "label": "Endalo",
    "value": "Endalo"
  },
  {
    "id": "1611052016",
    "district_id": "161105",
    "label": "Lubuk Tapang",
    "value": "Lubuk Tapang"
  },
  {
    "id": "1611062001",
    "district_id": "161106",
    "label": "Karang Are",
    "value": "Karang Are"
  },
  {
    "id": "1611062002",
    "district_id": "161106",
    "label": "Padang Titiran",
    "value": "Padang Titiran"
  },
  {
    "id": "1611062003",
    "district_id": "161106",
    "label": "Kembahang Lama",
    "value": "Kembahang Lama"
  },
  {
    "id": "1611062004",
    "district_id": "161106",
    "label": "Pasar Tl. Padang",
    "value": "Pasar Tl. Padang"
  },
  {
    "id": "1611062005",
    "district_id": "161106",
    "label": "Macang Manis",
    "value": "Macang Manis"
  },
  {
    "id": "1611062006",
    "district_id": "161106",
    "label": "Lubuk Buntak",
    "value": "Lubuk Buntak"
  },
  {
    "id": "1611062007",
    "district_id": "161106",
    "label": "Talang Durian",
    "value": "Talang Durian"
  },
  {
    "id": "1611062008",
    "district_id": "161106",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1611062009",
    "district_id": "161106",
    "label": "Kembahang Baru",
    "value": "Kembahang Baru"
  },
  {
    "id": "1611062010",
    "district_id": "161106",
    "label": "Canggu",
    "value": "Canggu"
  },
  {
    "id": "1611062011",
    "district_id": "161106",
    "label": "Remantai",
    "value": "Remantai"
  },
  {
    "id": "1611062012",
    "district_id": "161106",
    "label": "Ulak Dabuk",
    "value": "Ulak Dabuk"
  },
  {
    "id": "1611062013",
    "district_id": "161106",
    "label": "Lampar Baru",
    "value": "Lampar Baru"
  },
  {
    "id": "1611072001",
    "district_id": "161107",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1611072002",
    "district_id": "161107",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1611072003",
    "district_id": "161107",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1611072004",
    "district_id": "161107",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1611072005",
    "district_id": "161107",
    "label": "Kebon Jati",
    "value": "Kebon Jati"
  },
  {
    "id": "1611072006",
    "district_id": "161107",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1611072007",
    "district_id": "161107",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1611072008",
    "district_id": "161107",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1611072009",
    "district_id": "161107",
    "label": "Talang Randai",
    "value": "Talang Randai"
  },
  {
    "id": "1611072010",
    "district_id": "161107",
    "label": "Air Mayam",
    "value": "Air Mayam"
  },
  {
    "id": "1611072011",
    "district_id": "161107",
    "label": "Muara Rungga",
    "value": "Muara Rungga"
  },
  {
    "id": "1611072012",
    "district_id": "161107",
    "label": "Padang Gelai",
    "value": "Padang Gelai"
  },
  {
    "id": "1611072013",
    "district_id": "161107",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1611072014",
    "district_id": "161107",
    "label": "Muara Sindang",
    "value": "Muara Sindang"
  },
  {
    "id": "1611072015",
    "district_id": "161107",
    "label": "Muara Aman",
    "value": "Muara Aman"
  },
  {
    "id": "1611082001",
    "district_id": "161108",
    "label": "Puntang",
    "value": "Puntang"
  },
  {
    "id": "1611082002",
    "district_id": "161108",
    "label": "Bandar Aji",
    "value": "Bandar Aji"
  },
  {
    "id": "1611082003",
    "district_id": "161108",
    "label": "Paduraksa",
    "value": "Paduraksa"
  },
  {
    "id": "1611082004",
    "district_id": "161108",
    "label": "Karang Gede",
    "value": "Karang Gede"
  },
  {
    "id": "1611082005",
    "district_id": "161108",
    "label": "Karang Dapo Baru",
    "value": "Karang Dapo Baru"
  },
  {
    "id": "1611082006",
    "district_id": "161108",
    "label": "Karang Dapo Lama",
    "value": "Karang Dapo Lama"
  },
  {
    "id": "1611082007",
    "district_id": "161108",
    "label": "Tangga Rasa",
    "value": "Tangga Rasa"
  },
  {
    "id": "1611082008",
    "district_id": "161108",
    "label": "Tapa Baru",
    "value": "Tapa Baru"
  },
  {
    "id": "1611082009",
    "district_id": "161108",
    "label": "Tapa Lama",
    "value": "Tapa Lama"
  },
  {
    "id": "1611082010",
    "district_id": "161108",
    "label": "Martapura",
    "value": "Martapura"
  },
  {
    "id": "1611082011",
    "district_id": "161108",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1611092001",
    "district_id": "161109",
    "label": "Lubuk Kelumpang",
    "value": "Lubuk Kelumpang"
  },
  {
    "id": "1611092002",
    "district_id": "161109",
    "label": "Tanjungning Simpang",
    "value": "Tanjungning Simpang"
  },
  {
    "id": "1611092003",
    "district_id": "161109",
    "label": "Tanjungning Tengah",
    "value": "Tanjungning Tengah"
  },
  {
    "id": "1611092004",
    "district_id": "161109",
    "label": "Tanjungning Lama",
    "value": "Tanjungning Lama"
  },
  {
    "id": "1611092005",
    "district_id": "161109",
    "label": "Tanjungning Jaya",
    "value": "Tanjungning Jaya"
  },
  {
    "id": "1611092006",
    "district_id": "161109",
    "label": "Sawah",
    "value": "Sawah"
  },
  {
    "id": "1611092007",
    "district_id": "161109",
    "label": "Kebon",
    "value": "Kebon"
  },
  {
    "id": "1611092008",
    "district_id": "161109",
    "label": "Suka Kaya",
    "value": "Suka Kaya"
  },
  {
    "id": "1611092009",
    "district_id": "161109",
    "label": "Taba",
    "value": "Taba"
  },
  {
    "id": "1611092010",
    "district_id": "161109",
    "label": "Muara Saling",
    "value": "Muara Saling"
  },
  {
    "id": "1611102001",
    "district_id": "161110",
    "label": "Muaralintang Lama",
    "value": "Muaralintang Lama"
  },
  {
    "id": "1611102002",
    "district_id": "161110",
    "label": "Muaralintang Baru",
    "value": "Muaralintang Baru"
  },
  {
    "id": "1611102003",
    "district_id": "161110",
    "label": "Tebat Payang",
    "value": "Tebat Payang"
  },
  {
    "id": "1611102004",
    "district_id": "161110",
    "label": "Karang Caya",
    "value": "Karang Caya"
  },
  {
    "id": "1611102005",
    "district_id": "161110",
    "label": "Tanjungraya",
    "value": "Tanjungraya"
  },
  {
    "id": "1611102006",
    "district_id": "161110",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1611102007",
    "district_id": "161110",
    "label": "Air Kandis",
    "value": "Air Kandis"
  },
  {
    "id": "1611102008",
    "district_id": "161110",
    "label": "Lingge",
    "value": "Lingge"
  },
  {
    "id": "1611102009",
    "district_id": "161110",
    "label": "Rantau Dodor",
    "value": "Rantau Dodor"
  },
  {
    "id": "1611102010",
    "district_id": "161110",
    "label": "Kungkilan",
    "value": "Kungkilan"
  },
  {
    "id": "1612011003",
    "district_id": "161201",
    "label": "Talang Ubi Timur",
    "value": "Talang Ubi Timur"
  },
  {
    "id": "1612011010",
    "district_id": "161201",
    "label": "Talang Ubi Barat",
    "value": "Talang Ubi Barat"
  },
  {
    "id": "1612011011",
    "district_id": "161201",
    "label": "Talang Ubi Utara",
    "value": "Talang Ubi Utara"
  },
  {
    "id": "1612011012",
    "district_id": "161201",
    "label": "Talang Ubi Selatan",
    "value": "Talang Ubi Selatan"
  },
  {
    "id": "1612011017",
    "district_id": "161201",
    "label": "Pasar Bhayangkara",
    "value": "Pasar Bhayangkara"
  },
  {
    "id": "1612011018",
    "district_id": "161201",
    "label": "Handayani Mulya",
    "value": "Handayani Mulya"
  },
  {
    "id": "1612012001",
    "district_id": "161201",
    "label": "Talang Bulang",
    "value": "Talang Bulang"
  },
  {
    "id": "1612012002",
    "district_id": "161201",
    "label": "Benuang",
    "value": "Benuang"
  },
  {
    "id": "1612012004",
    "district_id": "161201",
    "label": "Talang Akar",
    "value": "Talang Akar"
  },
  {
    "id": "1612012005",
    "district_id": "161201",
    "label": "Sungai Ibul",
    "value": "Sungai Ibul"
  },
  {
    "id": "1612012006",
    "district_id": "161201",
    "label": "Karta Dewa",
    "value": "Karta Dewa"
  },
  {
    "id": "1612012007",
    "district_id": "161201",
    "label": "Panta Dewa",
    "value": "Panta Dewa"
  },
  {
    "id": "1612012008",
    "district_id": "161201",
    "label": "Sungai Baung",
    "value": "Sungai Baung"
  },
  {
    "id": "1612012009",
    "district_id": "161201",
    "label": "Semangus",
    "value": "Semangus"
  },
  {
    "id": "1612012013",
    "district_id": "161201",
    "label": "Sinar Dewa",
    "value": "Sinar Dewa"
  },
  {
    "id": "1612012014",
    "district_id": "161201",
    "label": "Benakat Minyak",
    "value": "Benakat Minyak"
  },
  {
    "id": "1612012015",
    "district_id": "161201",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1612012016",
    "district_id": "161201",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1612012019",
    "district_id": "161201",
    "label": "Beruge Darat",
    "value": "Beruge Darat"
  },
  {
    "id": "1612012020",
    "district_id": "161201",
    "label": "Simpang Tais",
    "value": "Simpang Tais"
  },
  {
    "id": "1612022001",
    "district_id": "161202",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1612022002",
    "district_id": "161202",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1612022003",
    "district_id": "161202",
    "label": "Prabu Menang",
    "value": "Prabu Menang"
  },
  {
    "id": "1612022004",
    "district_id": "161202",
    "label": "Tanding Marga",
    "value": "Tanding Marga"
  },
  {
    "id": "1612022005",
    "district_id": "161202",
    "label": "Karang Tanding",
    "value": "Karang Tanding"
  },
  {
    "id": "1612022006",
    "district_id": "161202",
    "label": "Lubuk Tampui",
    "value": "Lubuk Tampui"
  },
  {
    "id": "1612022007",
    "district_id": "161202",
    "label": "Tempirai",
    "value": "Tempirai"
  },
  {
    "id": "1612022008",
    "district_id": "161202",
    "label": "Tempirai Selatan",
    "value": "Tempirai Selatan"
  },
  {
    "id": "1612022009",
    "district_id": "161202",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1612022010",
    "district_id": "161202",
    "label": "Tempirai Utara",
    "value": "Tempirai Utara"
  },
  {
    "id": "1612022011",
    "district_id": "161202",
    "label": "Tempirai Timur",
    "value": "Tempirai Timur"
  },
  {
    "id": "1612022012",
    "district_id": "161202",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "1612022013",
    "district_id": "161202",
    "label": "Muara Ikan",
    "value": "Muara Ikan"
  },
  {
    "id": "1612032001",
    "district_id": "161203",
    "label": "Purun",
    "value": "Purun"
  },
  {
    "id": "1612032002",
    "district_id": "161203",
    "label": "Gunung Menang",
    "value": "Gunung Menang"
  },
  {
    "id": "1612032003",
    "district_id": "161203",
    "label": "Mangkunegara",
    "value": "Mangkunegara"
  },
  {
    "id": "1612032004",
    "district_id": "161203",
    "label": "Raja Jaya",
    "value": "Raja Jaya"
  },
  {
    "id": "1612032005",
    "district_id": "161203",
    "label": "Air Itam",
    "value": "Air Itam"
  },
  {
    "id": "1612032006",
    "district_id": "161203",
    "label": "Gunung Raja",
    "value": "Gunung Raja"
  },
  {
    "id": "1612032007",
    "district_id": "161203",
    "label": "Air Itam Timur",
    "value": "Air Itam Timur"
  },
  {
    "id": "1612032008",
    "district_id": "161203",
    "label": "Sungai Langan",
    "value": "Sungai Langan"
  },
  {
    "id": "1612032009",
    "district_id": "161203",
    "label": "Spantan Jaya",
    "value": "Spantan Jaya"
  },
  {
    "id": "1612032010",
    "district_id": "161203",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "1612032011",
    "district_id": "161203",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1612032012",
    "district_id": "161203",
    "label": "Purun Timur",
    "value": "Purun Timur"
  },
  {
    "id": "1612032013",
    "district_id": "161203",
    "label": "Mangkunegara Timur",
    "value": "Mangkunegara Timur"
  },
  {
    "id": "1612042001",
    "district_id": "161204",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1612042002",
    "district_id": "161204",
    "label": "Betung Barat",
    "value": "Betung Barat"
  },
  {
    "id": "1612042003",
    "district_id": "161204",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1612042004",
    "district_id": "161204",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1612042005",
    "district_id": "161204",
    "label": "Pengabuan",
    "value": "Pengabuan"
  },
  {
    "id": "1612042006",
    "district_id": "161204",
    "label": "Prambatan",
    "value": "Prambatan"
  },
  {
    "id": "1612042007",
    "district_id": "161204",
    "label": "Pengabuan Timur",
    "value": "Pengabuan Timur"
  },
  {
    "id": "1612042008",
    "district_id": "161204",
    "label": "Betung Selatan",
    "value": "Betung Selatan"
  },
  {
    "id": "1612052001",
    "district_id": "161205",
    "label": "Bumi Ayu",
    "value": "Bumi Ayu"
  },
  {
    "id": "1612052002",
    "district_id": "161205",
    "label": "Muara Sungai",
    "value": "Muara Sungai"
  },
  {
    "id": "1612052003",
    "district_id": "161205",
    "label": "Tanah Abang Utara",
    "value": "Tanah Abang Utara"
  },
  {
    "id": "1612052004",
    "district_id": "161205",
    "label": "Sedupi",
    "value": "Sedupi"
  },
  {
    "id": "1612052005",
    "district_id": "161205",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1612052006",
    "district_id": "161205",
    "label": "Curup",
    "value": "Curup"
  },
  {
    "id": "1612052007",
    "district_id": "161205",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "1612052008",
    "district_id": "161205",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "1612052009",
    "district_id": "161205",
    "label": "Modong",
    "value": "Modong"
  },
  {
    "id": "1612052010",
    "district_id": "161205",
    "label": "Tanah Abang Selatan",
    "value": "Tanah Abang Selatan"
  },
  {
    "id": "1612052011",
    "district_id": "161205",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1612052012",
    "district_id": "161205",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1612052013",
    "district_id": "161205",
    "label": "Lunas Jaya",
    "value": "Lunas Jaya"
  },
  {
    "id": "1612052014",
    "district_id": "161205",
    "label": "Raja Barat",
    "value": "Raja Barat"
  },
  {
    "id": "1612052015",
    "district_id": "161205",
    "label": "Tanah Abang Jaya",
    "value": "Tanah Abang Jaya"
  },
  {
    "id": "1612052016",
    "district_id": "161205",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1612052017",
    "district_id": "161205",
    "label": "Sukamanis",
    "value": "Sukamanis"
  },
  {
    "id": "1613011007",
    "district_id": "161301",
    "label": "Muara Rupit",
    "value": "Muara Rupit"
  },
  {
    "id": "1613012001",
    "district_id": "161301",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1613012002",
    "district_id": "161301",
    "label": "Noman",
    "value": "Noman"
  },
  {
    "id": "1613012003",
    "district_id": "161301",
    "label": "Batu Gajah",
    "value": "Batu Gajah"
  },
  {
    "id": "1613012004",
    "district_id": "161301",
    "label": "Maur Lama",
    "value": "Maur Lama"
  },
  {
    "id": "1613012005",
    "district_id": "161301",
    "label": "Maur Baru",
    "value": "Maur Baru"
  },
  {
    "id": "1613012006",
    "district_id": "161301",
    "label": "Bingin Rupit",
    "value": "Bingin Rupit"
  },
  {
    "id": "1613012008",
    "district_id": "161301",
    "label": "Lubuk Rumbai",
    "value": "Lubuk Rumbai"
  },
  {
    "id": "1613012009",
    "district_id": "161301",
    "label": "Pantai",
    "value": "Pantai"
  },
  {
    "id": "1613012010",
    "district_id": "161301",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1613012011",
    "district_id": "161301",
    "label": "Karang Waru",
    "value": "Karang Waru"
  },
  {
    "id": "1613012012",
    "district_id": "161301",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1613012013",
    "district_id": "161301",
    "label": "Sungai Jernih",
    "value": "Sungai Jernih"
  },
  {
    "id": "1613012014",
    "district_id": "161301",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1613012015",
    "district_id": "161301",
    "label": "Lubuk Rumbai Baru",
    "value": "Lubuk Rumbai Baru"
  },
  {
    "id": "1613012016",
    "district_id": "161301",
    "label": "Noman Baru",
    "value": "Noman Baru"
  },
  {
    "id": "1613012017",
    "district_id": "161301",
    "label": "Batu Gajah Baru",
    "value": "Batu Gajah Baru"
  },
  {
    "id": "1613021005",
    "district_id": "161302",
    "label": "Pasar Surulangun",
    "value": "Pasar Surulangun"
  },
  {
    "id": "1613022001",
    "district_id": "161302",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1613022002",
    "district_id": "161302",
    "label": "Kerta Dewa",
    "value": "Kerta Dewa"
  },
  {
    "id": "1613022003",
    "district_id": "161302",
    "label": "Teladas",
    "value": "Teladas"
  },
  {
    "id": "1613022004",
    "district_id": "161302",
    "label": "Pulau Lebar",
    "value": "Pulau Lebar"
  },
  {
    "id": "1613022006",
    "district_id": "161302",
    "label": "Sungai Baung",
    "value": "Sungai Baung"
  },
  {
    "id": "1613022007",
    "district_id": "161302",
    "label": "Sungai Jauh",
    "value": "Sungai Jauh"
  },
  {
    "id": "1613022008",
    "district_id": "161302",
    "label": "Sungai Kijang",
    "value": "Sungai Kijang"
  },
  {
    "id": "1613022009",
    "district_id": "161302",
    "label": "Surulangun",
    "value": "Surulangun"
  },
  {
    "id": "1613022010",
    "district_id": "161302",
    "label": "Lesung Batu",
    "value": "Lesung Batu"
  },
  {
    "id": "1613022011",
    "district_id": "161302",
    "label": "Lubuk Kemang",
    "value": "Lubuk Kemang"
  },
  {
    "id": "1613022012",
    "district_id": "161302",
    "label": "Remban",
    "value": "Remban"
  },
  {
    "id": "1613022013",
    "district_id": "161302",
    "label": "Lesung Batu Muda",
    "value": "Lesung Batu Muda"
  },
  {
    "id": "1613022014",
    "district_id": "161302",
    "label": "Simpang Nibung Rawas",
    "value": "Simpang Nibung Rawas"
  },
  {
    "id": "1613022015",
    "district_id": "161302",
    "label": "Lubuk Mas",
    "value": "Lubuk Mas"
  },
  {
    "id": "1613022016",
    "district_id": "161302",
    "label": "Sungai Lanang",
    "value": "Sungai Lanang"
  },
  {
    "id": "1613022017",
    "district_id": "161302",
    "label": "Sukomoro",
    "value": "Sukomoro"
  },
  {
    "id": "1613031003",
    "district_id": "161303",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "1613032001",
    "district_id": "161303",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "1613032002",
    "district_id": "161303",
    "label": "Jadi Mulya",
    "value": "Jadi Mulya"
  },
  {
    "id": "1613032004",
    "district_id": "161303",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1613032005",
    "district_id": "161303",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1613032006",
    "district_id": "161303",
    "label": "Sri Jaya Makmur",
    "value": "Sri Jaya Makmur"
  },
  {
    "id": "1613032007",
    "district_id": "161303",
    "label": "Kelumpang Jaya",
    "value": "Kelumpang Jaya"
  },
  {
    "id": "1613032008",
    "district_id": "161303",
    "label": "Bumi Makmur",
    "value": "Bumi Makmur"
  },
  {
    "id": "1613032009",
    "district_id": "161303",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1613032010",
    "district_id": "161303",
    "label": "Krani Jaya",
    "value": "Krani Jaya"
  },
  {
    "id": "1613032011",
    "district_id": "161303",
    "label": "Jadi Mulya I",
    "value": "Jadi Mulya I"
  },
  {
    "id": "1613041005",
    "district_id": "161304",
    "label": "Bingin Teluk",
    "value": "Bingin Teluk"
  },
  {
    "id": "1613042001",
    "district_id": "161304",
    "label": "Pauh",
    "value": "Pauh"
  },
  {
    "id": "1613042002",
    "district_id": "161304",
    "label": "Batu Kucing",
    "value": "Batu Kucing"
  },
  {
    "id": "1613042003",
    "district_id": "161304",
    "label": "Belani",
    "value": "Belani"
  },
  {
    "id": "1613042004",
    "district_id": "161304",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1613042006",
    "district_id": "161304",
    "label": "Mandi Angin",
    "value": "Mandi Angin"
  },
  {
    "id": "1613042007",
    "district_id": "161304",
    "label": "Air Bening",
    "value": "Air Bening"
  },
  {
    "id": "1613042008",
    "district_id": "161304",
    "label": "Beringin Sakti",
    "value": "Beringin Sakti"
  },
  {
    "id": "1613042009",
    "district_id": "161304",
    "label": "Beringin Makmur I",
    "value": "Beringin Makmur I"
  },
  {
    "id": "1613042010",
    "district_id": "161304",
    "label": "Beringin Makmur II",
    "value": "Beringin Makmur II"
  },
  {
    "id": "1613042011",
    "district_id": "161304",
    "label": "Pauh I",
    "value": "Pauh I"
  },
  {
    "id": "1613042012",
    "district_id": "161304",
    "label": "Ketapat Bening",
    "value": "Ketapat Bening"
  },
  {
    "id": "1613042013",
    "district_id": "161304",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "1613051004",
    "district_id": "161305",
    "label": "Karang Dapo I",
    "value": "Karang Dapo I"
  },
  {
    "id": "1613052001",
    "district_id": "161305",
    "label": "Aringin",
    "value": "Aringin"
  },
  {
    "id": "1613052002",
    "district_id": "161305",
    "label": "Biaro Baru",
    "value": "Biaro Baru"
  },
  {
    "id": "1613052003",
    "district_id": "161305",
    "label": "Biaro Lama",
    "value": "Biaro Lama"
  },
  {
    "id": "1613052005",
    "district_id": "161305",
    "label": "Rantau Kadam",
    "value": "Rantau Kadam"
  },
  {
    "id": "1613052006",
    "district_id": "161305",
    "label": "Kerta Sari",
    "value": "Kerta Sari"
  },
  {
    "id": "1613052007",
    "district_id": "161305",
    "label": "Karang Dapo II",
    "value": "Karang Dapo II"
  },
  {
    "id": "1613052008",
    "district_id": "161305",
    "label": "Setia Marga",
    "value": "Setia Marga"
  },
  {
    "id": "1613052009",
    "district_id": "161305",
    "label": "Bina Karya",
    "value": "Bina Karya"
  },
  {
    "id": "1613061009",
    "district_id": "161306",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1613062001",
    "district_id": "161306",
    "label": "Bukit Ulu",
    "value": "Bukit Ulu"
  },
  {
    "id": "1613062002",
    "district_id": "161306",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1613062003",
    "district_id": "161306",
    "label": "Lubuk Kumbung",
    "value": "Lubuk Kumbung"
  },
  {
    "id": "1613062004",
    "district_id": "161306",
    "label": "Muara Batang Empu",
    "value": "Muara Batang Empu"
  },
  {
    "id": "1613062005",
    "district_id": "161306",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1613062006",
    "district_id": "161306",
    "label": "Rantau Telang",
    "value": "Rantau Telang"
  },
  {
    "id": "1613062007",
    "district_id": "161306",
    "label": "Suka Menang",
    "value": "Suka Menang"
  },
  {
    "id": "1613062008",
    "district_id": "161306",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "1613062010",
    "district_id": "161306",
    "label": "Muara Tiku",
    "value": "Muara Tiku"
  },
  {
    "id": "1613062011",
    "district_id": "161306",
    "label": "Embacang Baru",
    "value": "Embacang Baru"
  },
  {
    "id": "1613062012",
    "district_id": "161306",
    "label": "Embacang Lama",
    "value": "Embacang Lama"
  },
  {
    "id": "1613062013",
    "district_id": "161306",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "1613062014",
    "district_id": "161306",
    "label": "Bukit Langkap",
    "value": "Bukit Langkap"
  },
  {
    "id": "1613062015",
    "district_id": "161306",
    "label": "Embacang Baru Ilir",
    "value": "Embacang Baru Ilir"
  },
  {
    "id": "1613071007",
    "district_id": "161307",
    "label": "Muara Kulam",
    "value": "Muara Kulam"
  },
  {
    "id": "1613072001",
    "district_id": "161307",
    "label": "Jangkat",
    "value": "Jangkat"
  },
  {
    "id": "1613072002",
    "district_id": "161307",
    "label": "Napal Licin",
    "value": "Napal Licin"
  },
  {
    "id": "1613072003",
    "district_id": "161307",
    "label": "Sosokan",
    "value": "Sosokan"
  },
  {
    "id": "1613072004",
    "district_id": "161307",
    "label": "Kuto Tanjung",
    "value": "Kuto Tanjung"
  },
  {
    "id": "1613072005",
    "district_id": "161307",
    "label": "Muara Kuis",
    "value": "Muara Kuis"
  },
  {
    "id": "1613072006",
    "district_id": "161307",
    "label": "Pulau Kidak",
    "value": "Pulau Kidak"
  },
  {
    "id": "1671011001",
    "district_id": "167101",
    "label": "35 Ilir",
    "value": "35 Ilir"
  },
  {
    "id": "1671011002",
    "district_id": "167101",
    "label": "32 Ilir",
    "value": "32 Ilir"
  },
  {
    "id": "1671011003",
    "district_id": "167101",
    "label": "30 Ilir",
    "value": "30 Ilir"
  },
  {
    "id": "1671011004",
    "district_id": "167101",
    "label": "29 Ilir",
    "value": "29 Ilir"
  },
  {
    "id": "1671011005",
    "district_id": "167101",
    "label": "28 Ilir",
    "value": "28 Ilir"
  },
  {
    "id": "1671011006",
    "district_id": "167101",
    "label": "27 Ilir",
    "value": "27 Ilir"
  },
  {
    "id": "1671011007",
    "district_id": "167101",
    "label": "Kemang Manis",
    "value": "Kemang Manis"
  },
  {
    "id": "1671021002",
    "district_id": "167102",
    "label": "1 Ulu",
    "value": "1 Ulu"
  },
  {
    "id": "1671021003",
    "district_id": "167102",
    "label": "2 Ulu",
    "value": "2 Ulu"
  },
  {
    "id": "1671021004",
    "district_id": "167102",
    "label": "3 / 4 Ulu",
    "value": "3 / 4 Ulu"
  },
  {
    "id": "1671021005",
    "district_id": "167102",
    "label": "5 Ulu",
    "value": "5 Ulu"
  },
  {
    "id": "1671021006",
    "district_id": "167102",
    "label": "7 Ulu",
    "value": "7 Ulu"
  },
  {
    "id": "1671031001",
    "district_id": "167103",
    "label": "11 Ulu",
    "value": "11 Ulu"
  },
  {
    "id": "1671031002",
    "district_id": "167103",
    "label": "12 Ulu",
    "value": "12 Ulu"
  },
  {
    "id": "1671031003",
    "district_id": "167103",
    "label": "13 Ulu",
    "value": "13 Ulu"
  },
  {
    "id": "1671031004",
    "district_id": "167103",
    "label": "14 Ulu",
    "value": "14 Ulu"
  },
  {
    "id": "1671031005",
    "district_id": "167103",
    "label": "16 Ulu",
    "value": "16 Ulu"
  },
  {
    "id": "1671031006",
    "district_id": "167103",
    "label": "Tangga Takat",
    "value": "Tangga Takat"
  },
  {
    "id": "1671031007",
    "district_id": "167103",
    "label": "Sentosa",
    "value": "Sentosa"
  },
  {
    "id": "1671041001",
    "district_id": "167104",
    "label": "Bukit Lama",
    "value": "Bukit Lama"
  },
  {
    "id": "1671041002",
    "district_id": "167104",
    "label": "Lorok Pakjo",
    "value": "Lorok Pakjo"
  },
  {
    "id": "1671041003",
    "district_id": "167104",
    "label": "26 Ilir D. I",
    "value": "26 Ilir D. I"
  },
  {
    "id": "1671041004",
    "district_id": "167104",
    "label": "Siring Agung",
    "value": "Siring Agung"
  },
  {
    "id": "1671041005",
    "district_id": "167104",
    "label": "Demang Lebar Daun",
    "value": "Demang Lebar Daun"
  },
  {
    "id": "1671041006",
    "district_id": "167104",
    "label": "Bukit Baru",
    "value": "Bukit Baru"
  },
  {
    "id": "1671051001",
    "district_id": "167105",
    "label": "18 Ilir",
    "value": "18 Ilir"
  },
  {
    "id": "1671051002",
    "district_id": "167105",
    "label": "16 Ilir",
    "value": "16 Ilir"
  },
  {
    "id": "1671051003",
    "district_id": "167105",
    "label": "13 Ilir",
    "value": "13 Ilir"
  },
  {
    "id": "1671051004",
    "district_id": "167105",
    "label": "14 Ilir",
    "value": "14 Ilir"
  },
  {
    "id": "1671051005",
    "district_id": "167105",
    "label": "15 Ilir",
    "value": "15 Ilir"
  },
  {
    "id": "1671051006",
    "district_id": "167105",
    "label": "17 Ilir",
    "value": "17 Ilir"
  },
  {
    "id": "1671051007",
    "district_id": "167105",
    "label": "20 Ilir D. III",
    "value": "20 Ilir D. III"
  },
  {
    "id": "1671051008",
    "district_id": "167105",
    "label": "20 Ilir D. IV",
    "value": "20 Ilir D. IV"
  },
  {
    "id": "1671051009",
    "district_id": "167105",
    "label": "20 Ilir D. I",
    "value": "20 Ilir D. I"
  },
  {
    "id": "1671051010",
    "district_id": "167105",
    "label": "Sungai Pangeran",
    "value": "Sungai Pangeran"
  },
  {
    "id": "1671051011",
    "district_id": "167105",
    "label": "Kepandean Baru",
    "value": "Kepandean Baru"
  },
  {
    "id": "1671061005",
    "district_id": "167106",
    "label": "5 Ilir",
    "value": "5 Ilir"
  },
  {
    "id": "1671061006",
    "district_id": "167106",
    "label": "3 Ilir",
    "value": "3 Ilir"
  },
  {
    "id": "1671061007",
    "district_id": "167106",
    "label": "1 Ilir",
    "value": "1 Ilir"
  },
  {
    "id": "1671061008",
    "district_id": "167106",
    "label": "2 Ilir",
    "value": "2 Ilir"
  },
  {
    "id": "1671061009",
    "district_id": "167106",
    "label": "Lawang Kidul",
    "value": "Lawang Kidul"
  },
  {
    "id": "1671061012",
    "district_id": "167106",
    "label": "Sei Buah",
    "value": "Sei Buah"
  },
  {
    "id": "1671071002",
    "district_id": "167107",
    "label": "Talang Betutu",
    "value": "Talang Betutu"
  },
  {
    "id": "1671071004",
    "district_id": "167107",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1671071005",
    "district_id": "167107",
    "label": "Suka Rami",
    "value": "Suka Rami"
  },
  {
    "id": "1671071007",
    "district_id": "167107",
    "label": "Kebun Bunga",
    "value": "Kebun Bunga"
  },
  {
    "id": "1671071009",
    "district_id": "167107",
    "label": "Suka Bangun",
    "value": "Suka Bangun"
  },
  {
    "id": "1671071010",
    "district_id": "167107",
    "label": "Talang Jambe",
    "value": "Talang Jambe"
  },
  {
    "id": "1671071011",
    "district_id": "167107",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "1671081001",
    "district_id": "167108",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1671081002",
    "district_id": "167108",
    "label": "Sako",
    "value": "Sako"
  },
  {
    "id": "1671081006",
    "district_id": "167108",
    "label": "Sialang",
    "value": "Sialang"
  },
  {
    "id": "1671081007",
    "district_id": "167108",
    "label": "Sako Baru",
    "value": "Sako Baru"
  },
  {
    "id": "1671091001",
    "district_id": "167109",
    "label": "20 Ilir D. II",
    "value": "20 Ilir D. II"
  },
  {
    "id": "1671091002",
    "district_id": "167109",
    "label": "Ario Kemuning",
    "value": "Ario Kemuning"
  },
  {
    "id": "1671091003",
    "district_id": "167109",
    "label": "Sekip Jaya",
    "value": "Sekip Jaya"
  },
  {
    "id": "1671091004",
    "district_id": "167109",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1671091005",
    "district_id": "167109",
    "label": "Pipa Reja",
    "value": "Pipa Reja"
  },
  {
    "id": "1671091006",
    "district_id": "167109",
    "label": "Talang Aman",
    "value": "Talang Aman"
  },
  {
    "id": "1671101001",
    "district_id": "167110",
    "label": "Bukit Sangkal",
    "value": "Bukit Sangkal"
  },
  {
    "id": "1671101002",
    "district_id": "167110",
    "label": "Kalidoni",
    "value": "Kalidoni"
  },
  {
    "id": "1671101003",
    "district_id": "167110",
    "label": "Sei Selayur",
    "value": "Sei Selayur"
  },
  {
    "id": "1671101004",
    "district_id": "167110",
    "label": "Sei Selincah",
    "value": "Sei Selincah"
  },
  {
    "id": "1671101005",
    "district_id": "167110",
    "label": "Sei Lais",
    "value": "Sei Lais"
  },
  {
    "id": "1671111001",
    "district_id": "167111",
    "label": "19 Ilir",
    "value": "19 Ilir"
  },
  {
    "id": "1671111002",
    "district_id": "167111",
    "label": "22 Ilir",
    "value": "22 Ilir"
  },
  {
    "id": "1671111003",
    "district_id": "167111",
    "label": "23 Ilir",
    "value": "23 Ilir"
  },
  {
    "id": "1671111004",
    "district_id": "167111",
    "label": "24 Ilir",
    "value": "24 Ilir"
  },
  {
    "id": "1671111005",
    "district_id": "167111",
    "label": "26 Ilir",
    "value": "26 Ilir"
  },
  {
    "id": "1671111006",
    "district_id": "167111",
    "label": "Talang Semut",
    "value": "Talang Semut"
  },
  {
    "id": "1671121001",
    "district_id": "167112",
    "label": "Gandus",
    "value": "Gandus"
  },
  {
    "id": "1671121002",
    "district_id": "167112",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1671121003",
    "district_id": "167112",
    "label": "36 Ilir",
    "value": "36 Ilir"
  },
  {
    "id": "1671121004",
    "district_id": "167112",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1671121005",
    "district_id": "167112",
    "label": "Pulo Kerto",
    "value": "Pulo Kerto"
  },
  {
    "id": "1671131001",
    "district_id": "167113",
    "label": "Kemas Rindo",
    "value": "Kemas Rindo"
  },
  {
    "id": "1671131002",
    "district_id": "167113",
    "label": "Kemang Agung",
    "value": "Kemang Agung"
  },
  {
    "id": "1671131003",
    "district_id": "167113",
    "label": "Keramasan",
    "value": "Keramasan"
  },
  {
    "id": "1671131004",
    "district_id": "167113",
    "label": "Kertapati",
    "value": "Kertapati"
  },
  {
    "id": "1671131005",
    "district_id": "167113",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "1671131006",
    "district_id": "167113",
    "label": "Ogan Baru",
    "value": "Ogan Baru"
  },
  {
    "id": "1671141001",
    "district_id": "167114",
    "label": "Plaju Ulu",
    "value": "Plaju Ulu"
  },
  {
    "id": "1671141002",
    "district_id": "167114",
    "label": "Plaju Darat",
    "value": "Plaju Darat"
  },
  {
    "id": "1671141003",
    "district_id": "167114",
    "label": "Plaju Ilir",
    "value": "Plaju Ilir"
  },
  {
    "id": "1671141004",
    "district_id": "167114",
    "label": "Bagus Kuning",
    "value": "Bagus Kuning"
  },
  {
    "id": "1671141005",
    "district_id": "167114",
    "label": "Komperta",
    "value": "Komperta"
  },
  {
    "id": "1671141006",
    "district_id": "167114",
    "label": "Talang Putri",
    "value": "Talang Putri"
  },
  {
    "id": "1671141007",
    "district_id": "167114",
    "label": "Talang Bubuk",
    "value": "Talang Bubuk"
  },
  {
    "id": "1671151001",
    "district_id": "167115",
    "label": "Alang-Alang Lebar",
    "value": "Alang-Alang Lebar"
  },
  {
    "id": "1671151002",
    "district_id": "167115",
    "label": "Sri Jaya",
    "value": "Sri Jaya"
  },
  {
    "id": "1671151003",
    "district_id": "167115",
    "label": "Talang Kelapa",
    "value": "Talang Kelapa"
  },
  {
    "id": "1671151004",
    "district_id": "167115",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "1671161001",
    "district_id": "167116",
    "label": "Sri Mulya",
    "value": "Sri Mulya"
  },
  {
    "id": "1671161002",
    "district_id": "167116",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1671161003",
    "district_id": "167116",
    "label": "Lebung Gajah",
    "value": "Lebung Gajah"
  },
  {
    "id": "1671161005",
    "district_id": "167116",
    "label": "Karya Mulia",
    "value": "Karya Mulia"
  },
  {
    "id": "1671171001",
    "district_id": "167117",
    "label": "8 Ulu",
    "value": "8 Ulu"
  },
  {
    "id": "1671171002",
    "district_id": "167117",
    "label": "9-10 Ulu",
    "value": "9-10 Ulu"
  },
  {
    "id": "1671171003",
    "district_id": "167117",
    "label": "15 Ulu",
    "value": "15 Ulu"
  },
  {
    "id": "1671171004",
    "district_id": "167117",
    "label": "Silaberanti",
    "value": "Silaberanti"
  },
  {
    "id": "1671171005",
    "district_id": "167117",
    "label": "Tuan Kentang",
    "value": "Tuan Kentang"
  },
  {
    "id": "1671181001",
    "district_id": "167118",
    "label": "8 Ilir",
    "value": "8 Ilir"
  },
  {
    "id": "1671181002",
    "district_id": "167118",
    "label": "9 Ilir",
    "value": "9 Ilir"
  },
  {
    "id": "1671181003",
    "district_id": "167118",
    "label": "10 Ilir",
    "value": "10 Ilir"
  },
  {
    "id": "1671181004",
    "district_id": "167118",
    "label": "11 Ilir",
    "value": "11 Ilir"
  },
  {
    "id": "1671181005",
    "district_id": "167118",
    "label": "Kuto Batu",
    "value": "Kuto Batu"
  },
  {
    "id": "1671181006",
    "district_id": "167118",
    "label": "Duku",
    "value": "Duku"
  },
  {
    "id": "1672011001",
    "district_id": "167201",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "1672011002",
    "district_id": "167201",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1672011005",
    "district_id": "167201",
    "label": "Pagar Alam",
    "value": "Pagar Alam"
  },
  {
    "id": "1672011007",
    "district_id": "167201",
    "label": "Alun Dua",
    "value": "Alun Dua"
  },
  {
    "id": "1672011014",
    "district_id": "167201",
    "label": "Selibar",
    "value": "Selibar"
  },
  {
    "id": "1672011016",
    "district_id": "167201",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1672011018",
    "district_id": "167201",
    "label": "Dempio Makmur",
    "value": "Dempio Makmur"
  },
  {
    "id": "1672011019",
    "district_id": "167201",
    "label": "Kusipan Babas",
    "value": "Kusipan Babas"
  },
  {
    "id": "1672011020",
    "district_id": "167201",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1672011021",
    "district_id": "167201",
    "label": "Curup Jare",
    "value": "Curup Jare"
  },
  {
    "id": "1672021003",
    "district_id": "167202",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1672021004",
    "district_id": "167202",
    "label": "Nendayung",
    "value": "Nendayung"
  },
  {
    "id": "1672021005",
    "district_id": "167202",
    "label": "Gunung Dempo",
    "value": "Gunung Dempo"
  },
  {
    "id": "1672021018",
    "district_id": "167202",
    "label": "Tebat Giri Indah",
    "value": "Tebat Giri Indah"
  },
  {
    "id": "1672021019",
    "district_id": "167202",
    "label": "Tumbak Ulas",
    "value": "Tumbak Ulas"
  },
  {
    "id": "1672021020",
    "district_id": "167202",
    "label": "Ulu Rurah",
    "value": "Ulu Rurah"
  },
  {
    "id": "1672021021",
    "district_id": "167202",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1672021022",
    "district_id": "167202",
    "label": "Basemah Sersan",
    "value": "Basemah Sersan"
  },
  {
    "id": "1672031006",
    "district_id": "167203",
    "label": "Jangkar Mas",
    "value": "Jangkar Mas"
  },
  {
    "id": "1672031012",
    "district_id": "167203",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1672031019",
    "district_id": "167203",
    "label": "Muara Siban",
    "value": "Muara Siban"
  },
  {
    "id": "1672031022",
    "district_id": "167203",
    "label": "Agung Lawongan",
    "value": "Agung Lawongan"
  },
  {
    "id": "1672031023",
    "district_id": "167203",
    "label": "Pagar Wangi",
    "value": "Pagar Wangi"
  },
  {
    "id": "1672031024",
    "district_id": "167203",
    "label": "Burung Dinang",
    "value": "Burung Dinang"
  },
  {
    "id": "1672031025",
    "district_id": "167203",
    "label": "Reba Tinggi",
    "value": "Reba Tinggi"
  },
  {
    "id": "1672041002",
    "district_id": "167204",
    "label": "Lubuk Buntak",
    "value": "Lubuk Buntak"
  },
  {
    "id": "1672041018",
    "district_id": "167204",
    "label": "Prabu Dipo",
    "value": "Prabu Dipo"
  },
  {
    "id": "1672041020",
    "district_id": "167204",
    "label": "Kance Diwe",
    "value": "Kance Diwe"
  },
  {
    "id": "1672041021",
    "district_id": "167204",
    "label": "Atung Bungsu",
    "value": "Atung Bungsu"
  },
  {
    "id": "1672041022",
    "district_id": "167204",
    "label": "Penjalang",
    "value": "Penjalang"
  },
  {
    "id": "1672051001",
    "district_id": "167205",
    "label": "Karang Dalo",
    "value": "Karang Dalo"
  },
  {
    "id": "1672051003",
    "district_id": "167205",
    "label": "Pelang Kenidai",
    "value": "Pelang Kenidai"
  },
  {
    "id": "1672051008",
    "district_id": "167205",
    "label": "Jokoh",
    "value": "Jokoh"
  },
  {
    "id": "1672051011",
    "district_id": "167205",
    "label": "Padang Temu",
    "value": "Padang Temu"
  },
  {
    "id": "1672051012",
    "district_id": "167205",
    "label": "Candi Jaya",
    "value": "Candi Jaya"
  },
  {
    "id": "1673011001",
    "district_id": "167301",
    "label": "Air Kuti",
    "value": "Air Kuti"
  },
  {
    "id": "1673011002",
    "district_id": "167301",
    "label": "Majapahit",
    "value": "Majapahit"
  },
  {
    "id": "1673011003",
    "district_id": "167301",
    "label": "Taba Jemekeh",
    "value": "Taba Jemekeh"
  },
  {
    "id": "1673011004",
    "district_id": "167301",
    "label": "Nikan Jaya",
    "value": "Nikan Jaya"
  },
  {
    "id": "1673011005",
    "district_id": "167301",
    "label": "Taba Lestari",
    "value": "Taba Lestari"
  },
  {
    "id": "1673011007",
    "district_id": "167301",
    "label": "Batu Urip Taba",
    "value": "Batu Urip Taba"
  },
  {
    "id": "1673011015",
    "district_id": "167301",
    "label": "Taba Koji",
    "value": "Taba Koji"
  },
  {
    "id": "1673011016",
    "district_id": "167301",
    "label": "Watervang",
    "value": "Watervang"
  },
  {
    "id": "1673021001",
    "district_id": "167302",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "1673021002",
    "district_id": "167302",
    "label": "Lubuk Tanjung",
    "value": "Lubuk Tanjung"
  },
  {
    "id": "1673021003",
    "district_id": "167302",
    "label": "Tanjung Indah",
    "value": "Tanjung Indah"
  },
  {
    "id": "1673021004",
    "district_id": "167302",
    "label": "Bandung Ujung",
    "value": "Bandung Ujung"
  },
  {
    "id": "1673021008",
    "district_id": "167302",
    "label": "Bandung Kiri",
    "value": "Bandung Kiri"
  },
  {
    "id": "1673021009",
    "district_id": "167302",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1673021010",
    "district_id": "167302",
    "label": "Muara Enim",
    "value": "Muara Enim"
  },
  {
    "id": "1673021015",
    "district_id": "167302",
    "label": "Watas Lubuk Durian",
    "value": "Watas Lubuk Durian"
  },
  {
    "id": "1673021016",
    "district_id": "167302",
    "label": "Lubuk Aman",
    "value": "Lubuk Aman"
  },
  {
    "id": "1673021018",
    "district_id": "167302",
    "label": "Tanjung Aman",
    "value": "Tanjung Aman"
  },
  {
    "id": "1673021019",
    "district_id": "167302",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "1673031001",
    "district_id": "167303",
    "label": "Lubuk Kupang",
    "value": "Lubuk Kupang"
  },
  {
    "id": "1673031003",
    "district_id": "167303",
    "label": "Rahmah",
    "value": "Rahmah"
  },
  {
    "id": "1673031008",
    "district_id": "167303",
    "label": "Jukung",
    "value": "Jukung"
  },
  {
    "id": "1673031009",
    "district_id": "167303",
    "label": "Air Kati",
    "value": "Air Kati"
  },
  {
    "id": "1673031010",
    "district_id": "167303",
    "label": "Air Teman",
    "value": "Air Teman"
  },
  {
    "id": "1673031011",
    "district_id": "167303",
    "label": "Perumnas Rahmah",
    "value": "Perumnas Rahmah"
  },
  {
    "id": "1673031012",
    "district_id": "167303",
    "label": "Lubuk Binjai",
    "value": "Lubuk Binjai"
  },
  {
    "id": "1673041001",
    "district_id": "167304",
    "label": "Durian Rampak",
    "value": "Durian Rampak"
  },
  {
    "id": "1673041002",
    "district_id": "167304",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1673041003",
    "district_id": "167304",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1673041004",
    "district_id": "167304",
    "label": "Marga Bakti",
    "value": "Marga Bakti"
  },
  {
    "id": "1673041005",
    "district_id": "167304",
    "label": "Belalau I",
    "value": "Belalau I"
  },
  {
    "id": "1673041006",
    "district_id": "167304",
    "label": "Belalau II",
    "value": "Belalau II"
  },
  {
    "id": "1673041007",
    "district_id": "167304",
    "label": "Taba Baru",
    "value": "Taba Baru"
  },
  {
    "id": "1673041008",
    "district_id": "167304",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "1673041009",
    "district_id": "167304",
    "label": "Petanang Ulu",
    "value": "Petanang Ulu"
  },
  {
    "id": "1673041010",
    "district_id": "167304",
    "label": "Petanang Ilir",
    "value": "Petanang Ilir"
  },
  {
    "id": "1673051001",
    "district_id": "167305",
    "label": "Jawa Kanan",
    "value": "Jawa Kanan"
  },
  {
    "id": "1673051002",
    "district_id": "167305",
    "label": "Jawa Kanan SS",
    "value": "Jawa Kanan SS"
  },
  {
    "id": "1673051003",
    "district_id": "167305",
    "label": "Wira Karya",
    "value": "Wira Karya"
  },
  {
    "id": "1673051004",
    "district_id": "167305",
    "label": "Dempo",
    "value": "Dempo"
  },
  {
    "id": "1673051005",
    "district_id": "167305",
    "label": "Jawa Kiri",
    "value": "Jawa Kiri"
  },
  {
    "id": "1673051006",
    "district_id": "167305",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1673051007",
    "district_id": "167305",
    "label": "Mesat Jaya",
    "value": "Mesat Jaya"
  },
  {
    "id": "1673051008",
    "district_id": "167305",
    "label": "Mesat Seni",
    "value": "Mesat Seni"
  },
  {
    "id": "1673051009",
    "district_id": "167305",
    "label": "Cereme Taba",
    "value": "Cereme Taba"
  },
  {
    "id": "1673061001",
    "district_id": "167306",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1673061002",
    "district_id": "167306",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1673061003",
    "district_id": "167306",
    "label": "Tapak Lebar",
    "value": "Tapak Lebar"
  },
  {
    "id": "1673061004",
    "district_id": "167306",
    "label": "Bandung Kanan",
    "value": "Bandung Kanan"
  },
  {
    "id": "1673061005",
    "district_id": "167306",
    "label": "Keputraan",
    "value": "Keputraan"
  },
  {
    "id": "1673061006",
    "district_id": "167306",
    "label": "Lubuklinggau Ilir",
    "value": "Lubuklinggau Ilir"
  },
  {
    "id": "1673061007",
    "district_id": "167306",
    "label": "Lubuklinggau Ulu",
    "value": "Lubuklinggau Ulu"
  },
  {
    "id": "1673061008",
    "district_id": "167306",
    "label": "Pasar Permiri",
    "value": "Pasar Permiri"
  },
  {
    "id": "1673071001",
    "district_id": "167307",
    "label": "Tanah Periuk",
    "value": "Tanah Periuk"
  },
  {
    "id": "1673071002",
    "district_id": "167307",
    "label": "Simpang Periuk",
    "value": "Simpang Periuk"
  },
  {
    "id": "1673071003",
    "district_id": "167307",
    "label": "Karang Ketuan",
    "value": "Karang Ketuan"
  },
  {
    "id": "1673071004",
    "district_id": "167307",
    "label": "Siring Agung",
    "value": "Siring Agung"
  },
  {
    "id": "1673071005",
    "district_id": "167307",
    "label": "Eka Marga",
    "value": "Eka Marga"
  },
  {
    "id": "1673071006",
    "district_id": "167307",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1673071007",
    "district_id": "167307",
    "label": "Marga Rahayu",
    "value": "Marga Rahayu"
  },
  {
    "id": "1673071008",
    "district_id": "167307",
    "label": "Taba Pingin",
    "value": "Taba Pingin"
  },
  {
    "id": "1673071009",
    "district_id": "167307",
    "label": "Moneng Sepati",
    "value": "Moneng Sepati"
  },
  {
    "id": "1673081001",
    "district_id": "167308",
    "label": "Batu Urip",
    "value": "Batu Urip"
  },
  {
    "id": "1673081002",
    "district_id": "167308",
    "label": "Ulak Surung",
    "value": "Ulak Surung"
  },
  {
    "id": "1673081003",
    "district_id": "167308",
    "label": "Kenanga",
    "value": "Kenanga"
  },
  {
    "id": "1673081004",
    "district_id": "167308",
    "label": "Pasar Satelit",
    "value": "Pasar Satelit"
  },
  {
    "id": "1673081005",
    "district_id": "167308",
    "label": "Senalang",
    "value": "Senalang"
  },
  {
    "id": "1673081006",
    "district_id": "167308",
    "label": "Megang",
    "value": "Megang"
  },
  {
    "id": "1673081007",
    "district_id": "167308",
    "label": "Jogoboyo",
    "value": "Jogoboyo"
  },
  {
    "id": "1673081008",
    "district_id": "167308",
    "label": "Puncak Kemuning",
    "value": "Puncak Kemuning"
  },
  {
    "id": "1673081009",
    "district_id": "167308",
    "label": "Ponorogo",
    "value": "Ponorogo"
  },
  {
    "id": "1673081010",
    "district_id": "167308",
    "label": "Kali Serayu",
    "value": "Kali Serayu"
  },
  {
    "id": "1674011001",
    "district_id": "167401",
    "label": "Prabumulih",
    "value": "Prabumulih"
  },
  {
    "id": "1674011007",
    "district_id": "167401",
    "label": "Patih Galung",
    "value": "Patih Galung"
  },
  {
    "id": "1674011009",
    "district_id": "167401",
    "label": "Muntang Tapus",
    "value": "Muntang Tapus"
  },
  {
    "id": "1674011011",
    "district_id": "167401",
    "label": "Gunung Kemala",
    "value": "Gunung Kemala"
  },
  {
    "id": "1674011012",
    "district_id": "167401",
    "label": "Payuputat",
    "value": "Payuputat"
  },
  {
    "id": "1674012013",
    "district_id": "167401",
    "label": "Tanjung Telang",
    "value": "Tanjung Telang"
  },
  {
    "id": "1674021003",
    "district_id": "167402",
    "label": "Karang Raja",
    "value": "Karang Raja"
  },
  {
    "id": "1674021004",
    "district_id": "167402",
    "label": "Tugu Kecil",
    "value": "Tugu Kecil"
  },
  {
    "id": "1674021005",
    "district_id": "167402",
    "label": "Prabu Jaya",
    "value": "Prabu Jaya"
  },
  {
    "id": "1674021006",
    "district_id": "167402",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1674021007",
    "district_id": "167402",
    "label": "Gunung Ibul",
    "value": "Gunung Ibul"
  },
  {
    "id": "1674021008",
    "district_id": "167402",
    "label": "Gunung Ibul Barat",
    "value": "Gunung Ibul Barat"
  },
  {
    "id": "1674021009",
    "district_id": "167402",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1674021010",
    "district_id": "167402",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1674031001",
    "district_id": "167403",
    "label": "Cambai",
    "value": "Cambai"
  },
  {
    "id": "1674031002",
    "district_id": "167403",
    "label": "Sindur",
    "value": "Sindur"
  },
  {
    "id": "1674031005",
    "district_id": "167403",
    "label": "Sungai Medang",
    "value": "Sungai Medang"
  },
  {
    "id": "1674032003",
    "district_id": "167403",
    "label": "Pangkul",
    "value": "Pangkul"
  },
  {
    "id": "1674032004",
    "district_id": "167403",
    "label": "Muara Sungai",
    "value": "Muara Sungai"
  },
  {
    "id": "1674041001",
    "district_id": "167404",
    "label": "Tanjung Rambang",
    "value": "Tanjung Rambang"
  },
  {
    "id": "1674042003",
    "district_id": "167404",
    "label": "Karya Mulya",
    "value": "Karya Mulya"
  },
  {
    "id": "1674042005",
    "district_id": "167404",
    "label": "Rambang Senuling",
    "value": "Rambang Senuling"
  },
  {
    "id": "1674042006",
    "district_id": "167404",
    "label": "Jungai",
    "value": "Jungai"
  },
  {
    "id": "1674042007",
    "district_id": "167404",
    "label": "Talang Batu",
    "value": "Talang Batu"
  },
  {
    "id": "1674042008",
    "district_id": "167404",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "1674042009",
    "district_id": "167404",
    "label": "Karang Bindu",
    "value": "Karang Bindu"
  },
  {
    "id": "1674042010",
    "district_id": "167404",
    "label": "Sinar Rambang",
    "value": "Sinar Rambang"
  },
  {
    "id": "1674042011",
    "district_id": "167404",
    "label": "Kemang Tanduk",
    "value": "Kemang Tanduk"
  },
  {
    "id": "1674051001",
    "district_id": "167405",
    "label": "Pasar Prabumulih I",
    "value": "Pasar Prabumulih I"
  },
  {
    "id": "1674051002",
    "district_id": "167405",
    "label": "Pasar Prabumulih II",
    "value": "Pasar Prabumulih II"
  },
  {
    "id": "1674051003",
    "district_id": "167405",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1674051004",
    "district_id": "167405",
    "label": "Mangga Besar",
    "value": "Mangga Besar"
  },
  {
    "id": "1674051005",
    "district_id": "167405",
    "label": "Anak Petai",
    "value": "Anak Petai"
  },
  {
    "id": "1674061001",
    "district_id": "167406",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "1674061002",
    "district_id": "167406",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1674061003",
    "district_id": "167406",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1674062004",
    "district_id": "167406",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1701012001",
    "district_id": "170101",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1701012002",
    "district_id": "170101",
    "label": "Rantau Sialang",
    "value": "Rantau Sialang"
  },
  {
    "id": "1701012003",
    "district_id": "170101",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1701012004",
    "district_id": "170101",
    "label": "Palak Siring",
    "value": "Palak Siring"
  },
  {
    "id": "1701012005",
    "district_id": "170101",
    "label": "Keban Agung I",
    "value": "Keban Agung I"
  },
  {
    "id": "1701012006",
    "district_id": "170101",
    "label": "Keban Agung II",
    "value": "Keban Agung II"
  },
  {
    "id": "1701012007",
    "district_id": "170101",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1701012008",
    "district_id": "170101",
    "label": "Tanjung Negara",
    "value": "Tanjung Negara"
  },
  {
    "id": "1701012009",
    "district_id": "170101",
    "label": "Keban Agung III",
    "value": "Keban Agung III"
  },
  {
    "id": "1701012010",
    "district_id": "170101",
    "label": "Suka Nanti",
    "value": "Suka Nanti"
  },
  {
    "id": "1701012011",
    "district_id": "170101",
    "label": "Muara Tiga",
    "value": "Muara Tiga"
  },
  {
    "id": "1701012012",
    "district_id": "170101",
    "label": "Tanjung Besar",
    "value": "Tanjung Besar"
  },
  {
    "id": "1701012013",
    "district_id": "170101",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1701012014",
    "district_id": "170101",
    "label": "Durian Sebatang",
    "value": "Durian Sebatang"
  },
  {
    "id": "1701012015",
    "district_id": "170101",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1701012027",
    "district_id": "170101",
    "label": "Nanti Agung",
    "value": "Nanti Agung"
  },
  {
    "id": "1701012028",
    "district_id": "170101",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1701012029",
    "district_id": "170101",
    "label": "Lubuk Resam",
    "value": "Lubuk Resam"
  },
  {
    "id": "1701012030",
    "district_id": "170101",
    "label": "Muara Tiga Ilir",
    "value": "Muara Tiga Ilir"
  },
  {
    "id": "1701021024",
    "district_id": "170102",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1701022001",
    "district_id": "170102",
    "label": "Padang Lebar",
    "value": "Padang Lebar"
  },
  {
    "id": "1701022002",
    "district_id": "170102",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1701022004",
    "district_id": "170102",
    "label": "Tanjung Menang",
    "value": "Tanjung Menang"
  },
  {
    "id": "1701022005",
    "district_id": "170102",
    "label": "Darat Sawah",
    "value": "Darat Sawah"
  },
  {
    "id": "1701022006",
    "district_id": "170102",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1701022007",
    "district_id": "170102",
    "label": "Babatan Ilir",
    "value": "Babatan Ilir"
  },
  {
    "id": "1701022008",
    "district_id": "170102",
    "label": "Babatan Ulu",
    "value": "Babatan Ulu"
  },
  {
    "id": "1701022009",
    "district_id": "170102",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1701022011",
    "district_id": "170102",
    "label": "Gunung Ayu",
    "value": "Gunung Ayu"
  },
  {
    "id": "1701022014",
    "district_id": "170102",
    "label": "Muara Pulutan",
    "value": "Muara Pulutan"
  },
  {
    "id": "1701022015",
    "district_id": "170102",
    "label": "Kota Bumi Baru",
    "value": "Kota Bumi Baru"
  },
  {
    "id": "1701022016",
    "district_id": "170102",
    "label": "Sindang Bulan",
    "value": "Sindang Bulan"
  },
  {
    "id": "1701022017",
    "district_id": "170102",
    "label": "Muara Payang",
    "value": "Muara Payang"
  },
  {
    "id": "1701022018",
    "district_id": "170102",
    "label": "Durian Seginim",
    "value": "Durian Seginim"
  },
  {
    "id": "1701022020",
    "district_id": "170102",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "1701022021",
    "district_id": "170102",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1701022022",
    "district_id": "170102",
    "label": "Dusun Tengah",
    "value": "Dusun Tengah"
  },
  {
    "id": "1701022023",
    "district_id": "170102",
    "label": "Padang Siring",
    "value": "Padang Siring"
  },
  {
    "id": "1701022029",
    "district_id": "170102",
    "label": "Darat Sawah Ulu",
    "value": "Darat Sawah Ulu"
  },
  {
    "id": "1701022030",
    "district_id": "170102",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1701022031",
    "district_id": "170102",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1701031018",
    "district_id": "170103",
    "label": "Masat",
    "value": "Masat"
  },
  {
    "id": "1701032001",
    "district_id": "170103",
    "label": "Tanjung Aur I",
    "value": "Tanjung Aur I"
  },
  {
    "id": "1701032002",
    "district_id": "170103",
    "label": "Anggut",
    "value": "Anggut"
  },
  {
    "id": "1701032003",
    "district_id": "170103",
    "label": "Tanjung Eran",
    "value": "Tanjung Eran"
  },
  {
    "id": "1701032004",
    "district_id": "170103",
    "label": "Padang Lebar",
    "value": "Padang Lebar"
  },
  {
    "id": "1701032005",
    "district_id": "170103",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1701032015",
    "district_id": "170103",
    "label": "Batu Bandung",
    "value": "Batu Bandung"
  },
  {
    "id": "1701032016",
    "district_id": "170103",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1701032017",
    "district_id": "170103",
    "label": "Kota Bumi",
    "value": "Kota Bumi"
  },
  {
    "id": "1701032019",
    "district_id": "170103",
    "label": "Air Umban",
    "value": "Air Umban"
  },
  {
    "id": "1701032020",
    "district_id": "170103",
    "label": "Sebilo",
    "value": "Sebilo"
  },
  {
    "id": "1701032021",
    "district_id": "170103",
    "label": "Ganjuh",
    "value": "Ganjuh"
  },
  {
    "id": "1701032022",
    "district_id": "170103",
    "label": "Puding",
    "value": "Puding"
  },
  {
    "id": "1701032023",
    "district_id": "170103",
    "label": "Padang Tambak",
    "value": "Padang Tambak"
  },
  {
    "id": "1701032024",
    "district_id": "170103",
    "label": "Padang Mumpo",
    "value": "Padang Mumpo"
  },
  {
    "id": "1701032025",
    "district_id": "170103",
    "label": "Beringin Datar",
    "value": "Beringin Datar"
  },
  {
    "id": "1701041022",
    "district_id": "170104",
    "label": "Kayu Kunyit",
    "value": "Kayu Kunyit"
  },
  {
    "id": "1701042001",
    "district_id": "170104",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1701042016",
    "district_id": "170104",
    "label": "Tanjung Besar",
    "value": "Tanjung Besar"
  },
  {
    "id": "1701042017",
    "district_id": "170104",
    "label": "Manggul",
    "value": "Manggul"
  },
  {
    "id": "1701042018",
    "district_id": "170104",
    "label": "Terulung",
    "value": "Terulung"
  },
  {
    "id": "1701042019",
    "district_id": "170104",
    "label": "Ketaping",
    "value": "Ketaping"
  },
  {
    "id": "1701042020",
    "district_id": "170104",
    "label": "Lubuk Sirih Ulu",
    "value": "Lubuk Sirih Ulu"
  },
  {
    "id": "1701042021",
    "district_id": "170104",
    "label": "Lubuk Sirih Ilir",
    "value": "Lubuk Sirih Ilir"
  },
  {
    "id": "1701042023",
    "district_id": "170104",
    "label": "Kota Padang",
    "value": "Kota Padang"
  },
  {
    "id": "1701042024",
    "district_id": "170104",
    "label": "Mela'o",
    "value": "Mela'o"
  },
  {
    "id": "1701042025",
    "district_id": "170104",
    "label": "Gunung Sakti",
    "value": "Gunung Sakti"
  },
  {
    "id": "1701042026",
    "district_id": "170104",
    "label": "Jeranglah Rendah",
    "value": "Jeranglah Rendah"
  },
  {
    "id": "1701042027",
    "district_id": "170104",
    "label": "Jeranglah Tinggi",
    "value": "Jeranglah Tinggi"
  },
  {
    "id": "1701042028",
    "district_id": "170104",
    "label": "Kembang Ayun",
    "value": "Kembang Ayun"
  },
  {
    "id": "1701042029",
    "district_id": "170104",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "1701042031",
    "district_id": "170104",
    "label": "Padang Manis",
    "value": "Padang Manis"
  },
  {
    "id": "1701042032",
    "district_id": "170104",
    "label": "Padang Pandan",
    "value": "Padang Pandan"
  },
  {
    "id": "1701042033",
    "district_id": "170104",
    "label": "Gunung Kembang",
    "value": "Gunung Kembang"
  },
  {
    "id": "1701051009",
    "district_id": "170105",
    "label": "Gunung Ayu",
    "value": "Gunung Ayu"
  },
  {
    "id": "1701051010",
    "district_id": "170105",
    "label": "Kota Medan",
    "value": "Kota Medan"
  },
  {
    "id": "1701051016",
    "district_id": "170105",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1701051017",
    "district_id": "170105",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1701051019",
    "district_id": "170105",
    "label": "Ibul",
    "value": "Ibul"
  },
  {
    "id": "1701051020",
    "district_id": "170105",
    "label": "Padang Kapuk",
    "value": "Padang Kapuk"
  },
  {
    "id": "1701052001",
    "district_id": "170105",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1701052004",
    "district_id": "170105",
    "label": "Padang Niur",
    "value": "Padang Niur"
  },
  {
    "id": "1701052005",
    "district_id": "170105",
    "label": "Tebat Kubu",
    "value": "Tebat Kubu"
  },
  {
    "id": "1701052006",
    "district_id": "170105",
    "label": "Gelumbang",
    "value": "Gelumbang"
  },
  {
    "id": "1701052018",
    "district_id": "170105",
    "label": "Padang Berangin",
    "value": "Padang Berangin"
  },
  {
    "id": "1701062001",
    "district_id": "170106",
    "label": "Selali",
    "value": "Selali"
  },
  {
    "id": "1701062002",
    "district_id": "170106",
    "label": "Air Kemang",
    "value": "Air Kemang"
  },
  {
    "id": "1701062003",
    "district_id": "170106",
    "label": "Pasar Pino",
    "value": "Pasar Pino"
  },
  {
    "id": "1701062004",
    "district_id": "170106",
    "label": "Tanggo Raso",
    "value": "Tanggo Raso"
  },
  {
    "id": "1701062005",
    "district_id": "170106",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1701062006",
    "district_id": "170106",
    "label": "Tungkal I",
    "value": "Tungkal I"
  },
  {
    "id": "1701062007",
    "district_id": "170106",
    "label": "Tungkal II",
    "value": "Tungkal II"
  },
  {
    "id": "1701062008",
    "district_id": "170106",
    "label": "Bandung Ayu",
    "value": "Bandung Ayu"
  },
  {
    "id": "1701062009",
    "district_id": "170106",
    "label": "Napal Melintang",
    "value": "Napal Melintang"
  },
  {
    "id": "1701062010",
    "district_id": "170106",
    "label": "Serang Bulan",
    "value": "Serang Bulan"
  },
  {
    "id": "1701062011",
    "district_id": "170106",
    "label": "Suka Bandung",
    "value": "Suka Bandung"
  },
  {
    "id": "1701062012",
    "district_id": "170106",
    "label": "Pagar Gading",
    "value": "Pagar Gading"
  },
  {
    "id": "1701062013",
    "district_id": "170106",
    "label": "Cinto Mandi",
    "value": "Cinto Mandi"
  },
  {
    "id": "1701062014",
    "district_id": "170106",
    "label": "Kembang Seri",
    "value": "Kembang Seri"
  },
  {
    "id": "1701062015",
    "district_id": "170106",
    "label": "Karang Cayo",
    "value": "Karang Cayo"
  },
  {
    "id": "1701062016",
    "district_id": "170106",
    "label": "Tanjung Aur II",
    "value": "Tanjung Aur II"
  },
  {
    "id": "1701062017",
    "district_id": "170106",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1701062018",
    "district_id": "170106",
    "label": "Kemang Manis",
    "value": "Kemang Manis"
  },
  {
    "id": "1701062019",
    "district_id": "170106",
    "label": "Padang Beriang",
    "value": "Padang Beriang"
  },
  {
    "id": "1701062020",
    "district_id": "170106",
    "label": "Padang Serasan",
    "value": "Padang Serasan"
  },
  {
    "id": "1701062021",
    "district_id": "170106",
    "label": "Telaga Dalam",
    "value": "Telaga Dalam"
  },
  {
    "id": "1701072001",
    "district_id": "170107",
    "label": "Karang Caya",
    "value": "Karang Caya"
  },
  {
    "id": "1701072002",
    "district_id": "170107",
    "label": "Betungan",
    "value": "Betungan"
  },
  {
    "id": "1701072003",
    "district_id": "170107",
    "label": "Pagar Banyu",
    "value": "Pagar Banyu"
  },
  {
    "id": "1701072004",
    "district_id": "170107",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1701072005",
    "district_id": "170107",
    "label": "Suka Rami",
    "value": "Suka Rami"
  },
  {
    "id": "1701072006",
    "district_id": "170107",
    "label": "Padang Bindu",
    "value": "Padang Bindu"
  },
  {
    "id": "1701072007",
    "district_id": "170107",
    "label": "Penindaian",
    "value": "Penindaian"
  },
  {
    "id": "1701072008",
    "district_id": "170107",
    "label": "Limus",
    "value": "Limus"
  },
  {
    "id": "1701072009",
    "district_id": "170107",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1701072010",
    "district_id": "170107",
    "label": "Lubuk Ladung",
    "value": "Lubuk Ladung"
  },
  {
    "id": "1701072011",
    "district_id": "170107",
    "label": "Air Sulau",
    "value": "Air Sulau"
  },
  {
    "id": "1701072012",
    "district_id": "170107",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1701082001",
    "district_id": "170108",
    "label": "Keban Jati",
    "value": "Keban Jati"
  },
  {
    "id": "1701082002",
    "district_id": "170108",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1701082003",
    "district_id": "170108",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1701082004",
    "district_id": "170108",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1701082005",
    "district_id": "170108",
    "label": "Palak Bengkerung",
    "value": "Palak Bengkerung"
  },
  {
    "id": "1701082006",
    "district_id": "170108",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1701082007",
    "district_id": "170108",
    "label": "Pino Baru",
    "value": "Pino Baru"
  },
  {
    "id": "1701082008",
    "district_id": "170108",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1701082009",
    "district_id": "170108",
    "label": "Suka Bandung",
    "value": "Suka Bandung"
  },
  {
    "id": "1701082010",
    "district_id": "170108",
    "label": "Maras",
    "value": "Maras"
  },
  {
    "id": "1701092001",
    "district_id": "170109",
    "label": "Batu Panco",
    "value": "Batu Panco"
  },
  {
    "id": "1701092002",
    "district_id": "170109",
    "label": "Batu Kuning",
    "value": "Batu Kuning"
  },
  {
    "id": "1701092003",
    "district_id": "170109",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1701092004",
    "district_id": "170109",
    "label": "Merambung",
    "value": "Merambung"
  },
  {
    "id": "1701092005",
    "district_id": "170109",
    "label": "Simpang Pino",
    "value": "Simpang Pino"
  },
  {
    "id": "1701092006",
    "district_id": "170109",
    "label": "Talang Tinggi",
    "value": "Talang Tinggi"
  },
  {
    "id": "1701092007",
    "district_id": "170109",
    "label": "Keban Jati",
    "value": "Keban Jati"
  },
  {
    "id": "1701092008",
    "district_id": "170109",
    "label": "Lubuk Tapi",
    "value": "Lubuk Tapi"
  },
  {
    "id": "1701092009",
    "district_id": "170109",
    "label": "Kayu Ajaran",
    "value": "Kayu Ajaran"
  },
  {
    "id": "1701092010",
    "district_id": "170109",
    "label": "Air Tenam Dalam",
    "value": "Air Tenam Dalam"
  },
  {
    "id": "1701102001",
    "district_id": "170110",
    "label": "Tumbak Tebing",
    "value": "Tumbak Tebing"
  },
  {
    "id": "1701102002",
    "district_id": "170110",
    "label": "Gunung Kayo",
    "value": "Gunung Kayo"
  },
  {
    "id": "1701102003",
    "district_id": "170110",
    "label": "Gindo Suli",
    "value": "Gindo Suli"
  },
  {
    "id": "1701102004",
    "district_id": "170110",
    "label": "Talang Indah",
    "value": "Talang Indah"
  },
  {
    "id": "1701102005",
    "district_id": "170110",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1701102006",
    "district_id": "170110",
    "label": "Padang Nibung",
    "value": "Padang Nibung"
  },
  {
    "id": "1701102007",
    "district_id": "170110",
    "label": "Tanjung Tebat",
    "value": "Tanjung Tebat"
  },
  {
    "id": "1701102008",
    "district_id": "170110",
    "label": "Padang Jawi",
    "value": "Padang Jawi"
  },
  {
    "id": "1701102009",
    "district_id": "170110",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1701102010",
    "district_id": "170110",
    "label": "Padang Burnai",
    "value": "Padang Burnai"
  },
  {
    "id": "1701111003",
    "district_id": "170111",
    "label": "Tanjung Mulia",
    "value": "Tanjung Mulia"
  },
  {
    "id": "1701111004",
    "district_id": "170111",
    "label": "Pasar Mulia",
    "value": "Pasar Mulia"
  },
  {
    "id": "1701111005",
    "district_id": "170111",
    "label": "Belakang Gedung",
    "value": "Belakang Gedung"
  },
  {
    "id": "1701111006",
    "district_id": "170111",
    "label": "Gunung Mesir",
    "value": "Gunung Mesir"
  },
  {
    "id": "1701111007",
    "district_id": "170111",
    "label": "Padang Sialang",
    "value": "Padang Sialang"
  },
  {
    "id": "1701111008",
    "district_id": "170111",
    "label": "Ketapang Besar",
    "value": "Ketapang Besar"
  },
  {
    "id": "1701111009",
    "district_id": "170111",
    "label": "Pasar Bawah",
    "value": "Pasar Bawah"
  },
  {
    "id": "1701112001",
    "district_id": "170111",
    "label": "Batu Kuning",
    "value": "Batu Kuning"
  },
  {
    "id": "1701112002",
    "district_id": "170111",
    "label": "Batu Lambang",
    "value": "Batu Lambang"
  },
  {
    "id": "1702061004",
    "district_id": "170206",
    "label": "Kota Padang",
    "value": "Kota Padang"
  },
  {
    "id": "1702061015",
    "district_id": "170206",
    "label": "Bedeng SS.",
    "value": "Bedeng SS."
  },
  {
    "id": "1702061016",
    "district_id": "170206",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1702062001",
    "district_id": "170206",
    "label": "Derati",
    "value": "Derati"
  },
  {
    "id": "1702062002",
    "district_id": "170206",
    "label": "Taba Anyar",
    "value": "Taba Anyar"
  },
  {
    "id": "1702062003",
    "district_id": "170206",
    "label": "Suka Rami",
    "value": "Suka Rami"
  },
  {
    "id": "1702062005",
    "district_id": "170206",
    "label": "Lubuk Mumpo",
    "value": "Lubuk Mumpo"
  },
  {
    "id": "1702062006",
    "district_id": "170206",
    "label": "Durian Mas",
    "value": "Durian Mas"
  },
  {
    "id": "1702062018",
    "district_id": "170206",
    "label": "Kota Padang Baru",
    "value": "Kota Padang Baru"
  },
  {
    "id": "1702062020",
    "district_id": "170206",
    "label": "Tanjung Gelang",
    "value": "Tanjung Gelang"
  },
  {
    "id": "1702071011",
    "district_id": "170207",
    "label": "Pasar PU Tanding",
    "value": "Pasar PU Tanding"
  },
  {
    "id": "1702072001",
    "district_id": "170207",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "1702072007",
    "district_id": "170207",
    "label": "Guru Agung",
    "value": "Guru Agung"
  },
  {
    "id": "1702072008",
    "district_id": "170207",
    "label": "Belumai II",
    "value": "Belumai II"
  },
  {
    "id": "1702072009",
    "district_id": "170207",
    "label": "Belumai I",
    "value": "Belumai I"
  },
  {
    "id": "1702072010",
    "district_id": "170207",
    "label": "Ulak Tanding",
    "value": "Ulak Tanding"
  },
  {
    "id": "1702072012",
    "district_id": "170207",
    "label": "Tanjung Sanai I",
    "value": "Tanjung Sanai I"
  },
  {
    "id": "1702072013",
    "district_id": "170207",
    "label": "Muara Telita",
    "value": "Muara Telita"
  },
  {
    "id": "1702072014",
    "district_id": "170207",
    "label": "Ujan Panas",
    "value": "Ujan Panas"
  },
  {
    "id": "1702072021",
    "district_id": "170207",
    "label": "Taba Tinggi",
    "value": "Taba Tinggi"
  },
  {
    "id": "1702072022",
    "district_id": "170207",
    "label": "Tanjung Sanai II",
    "value": "Tanjung Sanai II"
  },
  {
    "id": "1702072025",
    "district_id": "170207",
    "label": "Taktoi",
    "value": "Taktoi"
  },
  {
    "id": "1702072026",
    "district_id": "170207",
    "label": "Kasie Kasubun",
    "value": "Kasie Kasubun"
  },
  {
    "id": "1702072027",
    "district_id": "170207",
    "label": "Air Kati",
    "value": "Air Kati"
  },
  {
    "id": "1702072028",
    "district_id": "170207",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "1702081006",
    "district_id": "170208",
    "label": "Beringin Tiga",
    "value": "Beringin Tiga"
  },
  {
    "id": "1702082001",
    "district_id": "170208",
    "label": "Sindang Jaya",
    "value": "Sindang Jaya"
  },
  {
    "id": "1702082002",
    "district_id": "170208",
    "label": "Sindang Jati",
    "value": "Sindang Jati"
  },
  {
    "id": "1702082003",
    "district_id": "170208",
    "label": "Belitar Muka",
    "value": "Belitar Muka"
  },
  {
    "id": "1702082004",
    "district_id": "170208",
    "label": "Pelalo",
    "value": "Pelalo"
  },
  {
    "id": "1702082005",
    "district_id": "170208",
    "label": "Kayu Manis",
    "value": "Kayu Manis"
  },
  {
    "id": "1702082009",
    "district_id": "170208",
    "label": "Air Dingin",
    "value": "Air Dingin"
  },
  {
    "id": "1702082011",
    "district_id": "170208",
    "label": "Belitar Seberang",
    "value": "Belitar Seberang"
  },
  {
    "id": "1702082013",
    "district_id": "170208",
    "label": "Cahaya Negeri",
    "value": "Cahaya Negeri"
  },
  {
    "id": "1702082014",
    "district_id": "170208",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1702091012",
    "district_id": "170209",
    "label": "Dwi Tunggal",
    "value": "Dwi Tunggal"
  },
  {
    "id": "1702091013",
    "district_id": "170209",
    "label": "Air Putih Lama",
    "value": "Air Putih Lama"
  },
  {
    "id": "1702091023",
    "district_id": "170209",
    "label": "Adirejo",
    "value": "Adirejo"
  },
  {
    "id": "1702091025",
    "district_id": "170209",
    "label": "Air Rambai",
    "value": "Air Rambai"
  },
  {
    "id": "1702091026",
    "district_id": "170209",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1702091027",
    "district_id": "170209",
    "label": "Pasar Tengah",
    "value": "Pasar Tengah"
  },
  {
    "id": "1702091031",
    "district_id": "170209",
    "label": "Jalan Baru",
    "value": "Jalan Baru"
  },
  {
    "id": "1702091033",
    "district_id": "170209",
    "label": "Talang Benih",
    "value": "Talang Benih"
  },
  {
    "id": "1702091047",
    "district_id": "170209",
    "label": "Timbul Rejo",
    "value": "Timbul Rejo"
  },
  {
    "id": "1702102001",
    "district_id": "170210",
    "label": "Air Mundu",
    "value": "Air Mundu"
  },
  {
    "id": "1702102002",
    "district_id": "170210",
    "label": "Tebat Tenong Dalam",
    "value": "Tebat Tenong Dalam"
  },
  {
    "id": "1702102003",
    "district_id": "170210",
    "label": "Baru Manis",
    "value": "Baru Manis"
  },
  {
    "id": "1702102012",
    "district_id": "170210",
    "label": "Kampung Melayu",
    "value": "Kampung Melayu"
  },
  {
    "id": "1702102013",
    "district_id": "170210",
    "label": "Sentral Baru",
    "value": "Sentral Baru"
  },
  {
    "id": "1702102015",
    "district_id": "170210",
    "label": "Kampung Sajad",
    "value": "Kampung Sajad"
  },
  {
    "id": "1702102016",
    "district_id": "170210",
    "label": "Suka Rami",
    "value": "Suka Rami"
  },
  {
    "id": "1702102017",
    "district_id": "170210",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1702102018",
    "district_id": "170210",
    "label": "Selamat Sudiarjo",
    "value": "Selamat Sudiarjo"
  },
  {
    "id": "1702102019",
    "district_id": "170210",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1702102020",
    "district_id": "170210",
    "label": "Air Pikat",
    "value": "Air Pikat"
  },
  {
    "id": "1702102021",
    "district_id": "170210",
    "label": "Tebat Pulau",
    "value": "Tebat Pulau"
  },
  {
    "id": "1702111004",
    "district_id": "170211",
    "label": "Air Duku",
    "value": "Air Duku"
  },
  {
    "id": "1702111006",
    "district_id": "170211",
    "label": "Cawang Baru",
    "value": "Cawang Baru"
  },
  {
    "id": "1702111008",
    "district_id": "170211",
    "label": "Simpang Nangka",
    "value": "Simpang Nangka"
  },
  {
    "id": "1702112001",
    "district_id": "170211",
    "label": "Air Putih Kali",
    "value": "Air Putih Kali"
  },
  {
    "id": "1702112002",
    "district_id": "170211",
    "label": "Air Meles Atas",
    "value": "Air Meles Atas"
  },
  {
    "id": "1702112003",
    "district_id": "170211",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "1702112005",
    "district_id": "170211",
    "label": "Suban Ayam",
    "value": "Suban Ayam"
  },
  {
    "id": "1702112007",
    "district_id": "170211",
    "label": "Cawang Lama",
    "value": "Cawang Lama"
  },
  {
    "id": "1702112009",
    "district_id": "170211",
    "label": "Sumber Urip",
    "value": "Sumber Urip"
  },
  {
    "id": "1702112010",
    "district_id": "170211",
    "label": "Sumber Bening",
    "value": "Sumber Bening"
  },
  {
    "id": "1702112011",
    "district_id": "170211",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1702112012",
    "district_id": "170211",
    "label": "Kayu Manis",
    "value": "Kayu Manis"
  },
  {
    "id": "1702112013",
    "district_id": "170211",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1702112014",
    "district_id": "170211",
    "label": "Kali Padang",
    "value": "Kali Padang"
  },
  {
    "id": "1702112015",
    "district_id": "170211",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "1702112016",
    "district_id": "170211",
    "label": "Talang Lahat",
    "value": "Talang Lahat"
  },
  {
    "id": "1702161001",
    "district_id": "170216",
    "label": "Dusun Curup",
    "value": "Dusun Curup"
  },
  {
    "id": "1702161002",
    "district_id": "170216",
    "label": "Tunas Harapan",
    "value": "Tunas Harapan"
  },
  {
    "id": "1702162003",
    "district_id": "170216",
    "label": "Batu Dewa",
    "value": "Batu Dewa"
  },
  {
    "id": "1702162004",
    "district_id": "170216",
    "label": "Perbo",
    "value": "Perbo"
  },
  {
    "id": "1702162005",
    "district_id": "170216",
    "label": "Batu Panco",
    "value": "Batu Panco"
  },
  {
    "id": "1702162006",
    "district_id": "170216",
    "label": "Dusun Sawah",
    "value": "Dusun Sawah"
  },
  {
    "id": "1702162007",
    "district_id": "170216",
    "label": "Lubuk Kembang",
    "value": "Lubuk Kembang"
  },
  {
    "id": "1702162008",
    "district_id": "170216",
    "label": "Suka Datang",
    "value": "Suka Datang"
  },
  {
    "id": "1702162009",
    "district_id": "170216",
    "label": "Tabarenah",
    "value": "Tabarenah"
  },
  {
    "id": "1702162010",
    "district_id": "170216",
    "label": "Pahlawan",
    "value": "Pahlawan"
  },
  {
    "id": "1702162011",
    "district_id": "170216",
    "label": "Tasik Malaya",
    "value": "Tasik Malaya"
  },
  {
    "id": "1702162012",
    "district_id": "170216",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1702162013",
    "district_id": "170216",
    "label": "Kota Pagu",
    "value": "Kota Pagu"
  },
  {
    "id": "1702162014",
    "district_id": "170216",
    "label": "Seguring",
    "value": "Seguring"
  },
  {
    "id": "1702171001",
    "district_id": "170217",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1702171002",
    "district_id": "170217",
    "label": "Kesambe Baru",
    "value": "Kesambe Baru"
  },
  {
    "id": "1702171003",
    "district_id": "170217",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1702171004",
    "district_id": "170217",
    "label": "Talang Ulu",
    "value": "Talang Ulu"
  },
  {
    "id": "1702172005",
    "district_id": "170217",
    "label": "Duku Ulu",
    "value": "Duku Ulu"
  },
  {
    "id": "1702172006",
    "district_id": "170217",
    "label": "Duku Ilir",
    "value": "Duku Ilir"
  },
  {
    "id": "1702172007",
    "district_id": "170217",
    "label": "Air Meles Bawah",
    "value": "Air Meles Bawah"
  },
  {
    "id": "1702172008",
    "district_id": "170217",
    "label": "Kampung Delima",
    "value": "Kampung Delima"
  },
  {
    "id": "1702172009",
    "district_id": "170217",
    "label": "Kesambe Lama",
    "value": "Kesambe Lama"
  },
  {
    "id": "1702181001",
    "district_id": "170218",
    "label": "Air Putih Baru",
    "value": "Air Putih Baru"
  },
  {
    "id": "1702181002",
    "district_id": "170218",
    "label": "Tempel Rejo",
    "value": "Tempel Rejo"
  },
  {
    "id": "1702182003",
    "district_id": "170218",
    "label": "Rimbo Recap",
    "value": "Rimbo Recap"
  },
  {
    "id": "1702182004",
    "district_id": "170218",
    "label": "Watas Marga",
    "value": "Watas Marga"
  },
  {
    "id": "1702182005",
    "district_id": "170218",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1702182006",
    "district_id": "170218",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1702182007",
    "district_id": "170218",
    "label": "Lubuk Ubar",
    "value": "Lubuk Ubar"
  },
  {
    "id": "1702182008",
    "district_id": "170218",
    "label": "Pungguk Lalang",
    "value": "Pungguk Lalang"
  },
  {
    "id": "1702182009",
    "district_id": "170218",
    "label": "Turan Baru",
    "value": "Turan Baru"
  },
  {
    "id": "1702182010",
    "district_id": "170218",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1702182011",
    "district_id": "170218",
    "label": "Air Lanang",
    "value": "Air Lanang"
  },
  {
    "id": "1702191001",
    "district_id": "170219",
    "label": "Kepala Siring",
    "value": "Kepala Siring"
  },
  {
    "id": "1702191002",
    "district_id": "170219",
    "label": "Talang Rimbo Baru",
    "value": "Talang Rimbo Baru"
  },
  {
    "id": "1702191003",
    "district_id": "170219",
    "label": "Talang Rimbo Lama",
    "value": "Talang Rimbo Lama"
  },
  {
    "id": "1702191004",
    "district_id": "170219",
    "label": "Siderejo",
    "value": "Siderejo"
  },
  {
    "id": "1702191005",
    "district_id": "170219",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "1702191006",
    "district_id": "170219",
    "label": "Air Bang",
    "value": "Air Bang"
  },
  {
    "id": "1702191007",
    "district_id": "170219",
    "label": "Pelabuhan Baru",
    "value": "Pelabuhan Baru"
  },
  {
    "id": "1702191008",
    "district_id": "170219",
    "label": "Batu Galing",
    "value": "Batu Galing"
  },
  {
    "id": "1702191009",
    "district_id": "170219",
    "label": "Banyu Mas",
    "value": "Banyu Mas"
  },
  {
    "id": "1702192010",
    "district_id": "170219",
    "label": "Air Merah",
    "value": "Air Merah"
  },
  {
    "id": "1702202001",
    "district_id": "170220",
    "label": "Kepala Curup",
    "value": "Kepala Curup"
  },
  {
    "id": "1702202002",
    "district_id": "170220",
    "label": "Kampung Jeruk",
    "value": "Kampung Jeruk"
  },
  {
    "id": "1702202003",
    "district_id": "170220",
    "label": "Simpang Beliti",
    "value": "Simpang Beliti"
  },
  {
    "id": "1702202004",
    "district_id": "170220",
    "label": "Taba Padang",
    "value": "Taba Padang"
  },
  {
    "id": "1702202005",
    "district_id": "170220",
    "label": "Air Apo",
    "value": "Air Apo"
  },
  {
    "id": "1702212001",
    "district_id": "170221",
    "label": "Apur",
    "value": "Apur"
  },
  {
    "id": "1702212002",
    "district_id": "170221",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1702212003",
    "district_id": "170221",
    "label": "Lubuk Alai",
    "value": "Lubuk Alai"
  },
  {
    "id": "1702212004",
    "district_id": "170221",
    "label": "Karang Pinang",
    "value": "Karang Pinang"
  },
  {
    "id": "1702212005",
    "district_id": "170221",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1702212006",
    "district_id": "170221",
    "label": "Pengambang",
    "value": "Pengambang"
  },
  {
    "id": "1702212007",
    "district_id": "170221",
    "label": "Jabi",
    "value": "Jabi"
  },
  {
    "id": "1702212008",
    "district_id": "170221",
    "label": "Tanjung Herang",
    "value": "Tanjung Herang"
  },
  {
    "id": "1702212009",
    "district_id": "170221",
    "label": "Air Nau",
    "value": "Air Nau"
  },
  {
    "id": "1702222001",
    "district_id": "170222",
    "label": "IV Suko Menanti",
    "value": "IV Suko Menanti"
  },
  {
    "id": "1702222002",
    "district_id": "170222",
    "label": "Air Rusa",
    "value": "Air Rusa"
  },
  {
    "id": "1702222003",
    "district_id": "170222",
    "label": "Bengko",
    "value": "Bengko"
  },
  {
    "id": "1702222004",
    "district_id": "170222",
    "label": "Sinar Gunung",
    "value": "Sinar Gunung"
  },
  {
    "id": "1702222005",
    "district_id": "170222",
    "label": "Warung Pojok",
    "value": "Warung Pojok"
  },
  {
    "id": "1702222006",
    "district_id": "170222",
    "label": "Talang Belitar",
    "value": "Talang Belitar"
  },
  {
    "id": "1702232001",
    "district_id": "170223",
    "label": "Merantau",
    "value": "Merantau"
  },
  {
    "id": "1702232002",
    "district_id": "170223",
    "label": "Periang",
    "value": "Periang"
  },
  {
    "id": "1702232003",
    "district_id": "170223",
    "label": "Balai Buntar",
    "value": "Balai Buntar"
  },
  {
    "id": "1702232004",
    "district_id": "170223",
    "label": "LubukTunjung",
    "value": "LubukTunjung"
  },
  {
    "id": "1702232005",
    "district_id": "170223",
    "label": "LB. Belimbing I",
    "value": "LB. Belimbing I"
  },
  {
    "id": "1702232006",
    "district_id": "170223",
    "label": "LB Belimbing II",
    "value": "LB Belimbing II"
  },
  {
    "id": "1702232007",
    "district_id": "170223",
    "label": "LB. Bingin Baru",
    "value": "LB. Bingin Baru"
  },
  {
    "id": "1702232008",
    "district_id": "170223",
    "label": "Sari Pulau",
    "value": "Sari Pulau"
  },
  {
    "id": "1702232009",
    "district_id": "170223",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1702232010",
    "district_id": "170223",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "1702242001",
    "district_id": "170224",
    "label": "Dataran Tapus",
    "value": "Dataran Tapus"
  },
  {
    "id": "1702242002",
    "district_id": "170224",
    "label": "Bandung Marga",
    "value": "Bandung Marga"
  },
  {
    "id": "1702242003",
    "district_id": "170224",
    "label": "Pal Seratus",
    "value": "Pal Seratus"
  },
  {
    "id": "1702242004",
    "district_id": "170224",
    "label": "Pal VII",
    "value": "Pal VII"
  },
  {
    "id": "1702242005",
    "district_id": "170224",
    "label": "Pal VIII",
    "value": "Pal VIII"
  },
  {
    "id": "1702242006",
    "district_id": "170224",
    "label": "Tebat Tenong Luar",
    "value": "Tebat Tenong Luar"
  },
  {
    "id": "1702242007",
    "district_id": "170224",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1702242008",
    "district_id": "170224",
    "label": "Babakan Baru",
    "value": "Babakan Baru"
  },
  {
    "id": "1702242009",
    "district_id": "170224",
    "label": "Sumberejo Transad",
    "value": "Sumberejo Transad"
  },
  {
    "id": "1702242010",
    "district_id": "170224",
    "label": "Air Bening",
    "value": "Air Bening"
  },
  {
    "id": "1703012001",
    "district_id": "170301",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1703012002",
    "district_id": "170301",
    "label": "Meok",
    "value": "Meok"
  },
  {
    "id": "1703012003",
    "district_id": "170301",
    "label": "Malakoni",
    "value": "Malakoni"
  },
  {
    "id": "1703012004",
    "district_id": "170301",
    "label": "Kaana",
    "value": "Kaana"
  },
  {
    "id": "1703012005",
    "district_id": "170301",
    "label": "Kahyapu",
    "value": "Kahyapu"
  },
  {
    "id": "1703012006",
    "district_id": "170301",
    "label": "Apoho",
    "value": "Apoho"
  },
  {
    "id": "1703061009",
    "district_id": "170306",
    "label": "Lubuk Durian",
    "value": "Lubuk Durian"
  },
  {
    "id": "1703062001",
    "district_id": "170306",
    "label": "Talang Jambu",
    "value": "Talang Jambu"
  },
  {
    "id": "1703062002",
    "district_id": "170306",
    "label": "Tebat Pacur",
    "value": "Tebat Pacur"
  },
  {
    "id": "1703062003",
    "district_id": "170306",
    "label": "Talang Curup",
    "value": "Talang Curup"
  },
  {
    "id": "1703062004",
    "district_id": "170306",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "1703062005",
    "district_id": "170306",
    "label": "Salam Harjo",
    "value": "Salam Harjo"
  },
  {
    "id": "1703062006",
    "district_id": "170306",
    "label": "Yogja Baru",
    "value": "Yogja Baru"
  },
  {
    "id": "1703062007",
    "district_id": "170306",
    "label": "Talang Pasak",
    "value": "Talang Pasak"
  },
  {
    "id": "1703062008",
    "district_id": "170306",
    "label": "Perbo",
    "value": "Perbo"
  },
  {
    "id": "1703062010",
    "district_id": "170306",
    "label": "Lubuk Jale",
    "value": "Lubuk Jale"
  },
  {
    "id": "1703062011",
    "district_id": "170306",
    "label": "Simpang Ketenong",
    "value": "Simpang Ketenong"
  },
  {
    "id": "1703062012",
    "district_id": "170306",
    "label": "Aur Gading",
    "value": "Aur Gading"
  },
  {
    "id": "1703062024",
    "district_id": "170306",
    "label": "Kedu Baru",
    "value": "Kedu Baru"
  },
  {
    "id": "1703062026",
    "district_id": "170306",
    "label": "Serumbung",
    "value": "Serumbung"
  },
  {
    "id": "1703062027",
    "district_id": "170306",
    "label": "Magelang",
    "value": "Magelang"
  },
  {
    "id": "1703062028",
    "district_id": "170306",
    "label": "Tanjung Putus",
    "value": "Tanjung Putus"
  },
  {
    "id": "1703062029",
    "district_id": "170306",
    "label": "Penyangkak",
    "value": "Penyangkak"
  },
  {
    "id": "1703062031",
    "district_id": "170306",
    "label": "Banyumas Baru",
    "value": "Banyumas Baru"
  },
  {
    "id": "1703071026",
    "district_id": "170307",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1703071027",
    "district_id": "170307",
    "label": "Gunung Alam",
    "value": "Gunung Alam"
  },
  {
    "id": "1703072001",
    "district_id": "170307",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1703072002",
    "district_id": "170307",
    "label": "Lubuk Sahung",
    "value": "Lubuk Sahung"
  },
  {
    "id": "1703072003",
    "district_id": "170307",
    "label": "Kuro Tidur",
    "value": "Kuro Tidur"
  },
  {
    "id": "1703072004",
    "district_id": "170307",
    "label": "Karang Suci",
    "value": "Karang Suci"
  },
  {
    "id": "1703072005",
    "district_id": "170307",
    "label": "Rama Agung",
    "value": "Rama Agung"
  },
  {
    "id": "1703072008",
    "district_id": "170307",
    "label": "Talang Denau",
    "value": "Talang Denau"
  },
  {
    "id": "1703072009",
    "district_id": "170307",
    "label": "Gunung Selan",
    "value": "Gunung Selan"
  },
  {
    "id": "1703072010",
    "district_id": "170307",
    "label": "Datar Ruyung",
    "value": "Datar Ruyung"
  },
  {
    "id": "1703072011",
    "district_id": "170307",
    "label": "Senali",
    "value": "Senali"
  },
  {
    "id": "1703072012",
    "district_id": "170307",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1703072014",
    "district_id": "170307",
    "label": "Sido Urip",
    "value": "Sido Urip"
  },
  {
    "id": "1703072015",
    "district_id": "170307",
    "label": "Karang Anyar I",
    "value": "Karang Anyar I"
  },
  {
    "id": "1703072016",
    "district_id": "170307",
    "label": "Taba Tembilang",
    "value": "Taba Tembilang"
  },
  {
    "id": "1703072022",
    "district_id": "170307",
    "label": "Karang Anyar II",
    "value": "Karang Anyar II"
  },
  {
    "id": "1703082001",
    "district_id": "170308",
    "label": "Tanjung Anom",
    "value": "Tanjung Anom"
  },
  {
    "id": "1703082002",
    "district_id": "170308",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1703082003",
    "district_id": "170308",
    "label": "Wono Harjo",
    "value": "Wono Harjo"
  },
  {
    "id": "1703082004",
    "district_id": "170308",
    "label": "Giri Mulya",
    "value": "Giri Mulya"
  },
  {
    "id": "1703082005",
    "district_id": "170308",
    "label": "Rena Jaya",
    "value": "Rena Jaya"
  },
  {
    "id": "1703082006",
    "district_id": "170308",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1703092001",
    "district_id": "170309",
    "label": "Lubuk Banyau",
    "value": "Lubuk Banyau"
  },
  {
    "id": "1703092002",
    "district_id": "170309",
    "label": "Talang Tua",
    "value": "Talang Tua"
  },
  {
    "id": "1703092003",
    "district_id": "170309",
    "label": "Marga Sakti",
    "value": "Marga Sakti"
  },
  {
    "id": "1703092004",
    "district_id": "170309",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1703092005",
    "district_id": "170309",
    "label": "Padang Jaya",
    "value": "Padang Jaya"
  },
  {
    "id": "1703092006",
    "district_id": "170309",
    "label": "Arga Mulya",
    "value": "Arga Mulya"
  },
  {
    "id": "1703092007",
    "district_id": "170309",
    "label": "Sido Mukti",
    "value": "Sido Mukti"
  },
  {
    "id": "1703092008",
    "district_id": "170309",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1703092009",
    "district_id": "170309",
    "label": "Tanah Hitam",
    "value": "Tanah Hitam"
  },
  {
    "id": "1703092010",
    "district_id": "170309",
    "label": "Tambak Rejo",
    "value": "Tambak Rejo"
  },
  {
    "id": "1703092011",
    "district_id": "170309",
    "label": "Sido Luhur",
    "value": "Sido Luhur"
  },
  {
    "id": "1703092012",
    "district_id": "170309",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "1703101010",
    "district_id": "170310",
    "label": "Pasar Lais",
    "value": "Pasar Lais"
  },
  {
    "id": "1703102008",
    "district_id": "170310",
    "label": "Datar Lebar",
    "value": "Datar Lebar"
  },
  {
    "id": "1703102009",
    "district_id": "170310",
    "label": "Air Padang",
    "value": "Air Padang"
  },
  {
    "id": "1703102011",
    "district_id": "170310",
    "label": "Durian Daun",
    "value": "Durian Daun"
  },
  {
    "id": "1703102012",
    "district_id": "170310",
    "label": "Dusun Raja",
    "value": "Dusun Raja"
  },
  {
    "id": "1703102013",
    "district_id": "170310",
    "label": "Lubuk Lesung",
    "value": "Lubuk Lesung"
  },
  {
    "id": "1703102014",
    "district_id": "170310",
    "label": "Pal 30",
    "value": "Pal 30"
  },
  {
    "id": "1703102015",
    "district_id": "170310",
    "label": "Jagobayo",
    "value": "Jagobayo"
  },
  {
    "id": "1703102017",
    "district_id": "170310",
    "label": "Talang Rasau",
    "value": "Talang Rasau"
  },
  {
    "id": "1703102018",
    "district_id": "170310",
    "label": "Lubuk Gedang",
    "value": "Lubuk Gedang"
  },
  {
    "id": "1703102019",
    "district_id": "170310",
    "label": "Suka Langu",
    "value": "Suka Langu"
  },
  {
    "id": "1703102020",
    "district_id": "170310",
    "label": "Taba Baru",
    "value": "Taba Baru"
  },
  {
    "id": "1703102021",
    "district_id": "170310",
    "label": "Kalbang",
    "value": "Kalbang"
  },
  {
    "id": "1703112001",
    "district_id": "170311",
    "label": "Serangai",
    "value": "Serangai"
  },
  {
    "id": "1703112002",
    "district_id": "170311",
    "label": "Selolong",
    "value": "Selolong"
  },
  {
    "id": "1703112003",
    "district_id": "170311",
    "label": "Air Lakok",
    "value": "Air Lakok"
  },
  {
    "id": "1703112004",
    "district_id": "170311",
    "label": "Bintunan",
    "value": "Bintunan"
  },
  {
    "id": "1703112005",
    "district_id": "170311",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1703112006",
    "district_id": "170311",
    "label": "Batik Nau",
    "value": "Batik Nau"
  },
  {
    "id": "1703112007",
    "district_id": "170311",
    "label": "Pagar Ruyung",
    "value": "Pagar Ruyung"
  },
  {
    "id": "1703112008",
    "district_id": "170311",
    "label": "Durian Amparan",
    "value": "Durian Amparan"
  },
  {
    "id": "1703112009",
    "district_id": "170311",
    "label": "Taba Kulintang",
    "value": "Taba Kulintang"
  },
  {
    "id": "1703112010",
    "district_id": "170311",
    "label": "Ulak Tanding",
    "value": "Ulak Tanding"
  },
  {
    "id": "1703112011",
    "district_id": "170311",
    "label": "Seberang Tunggal",
    "value": "Seberang Tunggal"
  },
  {
    "id": "1703112012",
    "district_id": "170311",
    "label": "Sekiau",
    "value": "Sekiau"
  },
  {
    "id": "1703112013",
    "district_id": "170311",
    "label": "Samban Jaya",
    "value": "Samban Jaya"
  },
  {
    "id": "1703112014",
    "district_id": "170311",
    "label": "Peninjau",
    "value": "Peninjau"
  },
  {
    "id": "1703112015",
    "district_id": "170311",
    "label": "Air Manganyau",
    "value": "Air Manganyau"
  },
  {
    "id": "1703122001",
    "district_id": "170312",
    "label": "Urai",
    "value": "Urai"
  },
  {
    "id": "1703122002",
    "district_id": "170312",
    "label": "Pasar Ketahun",
    "value": "Pasar Ketahun"
  },
  {
    "id": "1703122003",
    "district_id": "170312",
    "label": "Giri Kencana",
    "value": "Giri Kencana"
  },
  {
    "id": "1703122004",
    "district_id": "170312",
    "label": "Kualalangi",
    "value": "Kualalangi"
  },
  {
    "id": "1703122005",
    "district_id": "170312",
    "label": "Dusun Raja",
    "value": "Dusun Raja"
  },
  {
    "id": "1703122012",
    "district_id": "170312",
    "label": "Lubuk Mindai",
    "value": "Lubuk Mindai"
  },
  {
    "id": "1703122013",
    "district_id": "170312",
    "label": "Fajar Baru",
    "value": "Fajar Baru"
  },
  {
    "id": "1703122014",
    "district_id": "170312",
    "label": "Talang Baru",
    "value": "Talang Baru"
  },
  {
    "id": "1703122019",
    "district_id": "170312",
    "label": "Melati Harjo K.1",
    "value": "Melati Harjo K.1"
  },
  {
    "id": "1703122020",
    "district_id": "170312",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "1703122021",
    "district_id": "170312",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "1703132003",
    "district_id": "170313",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1703132004",
    "district_id": "170313",
    "label": "Jabi",
    "value": "Jabi"
  },
  {
    "id": "1703132005",
    "district_id": "170313",
    "label": "Napal Putih",
    "value": "Napal Putih"
  },
  {
    "id": "1703132006",
    "district_id": "170313",
    "label": "Air Tenang",
    "value": "Air Tenang"
  },
  {
    "id": "1703132007",
    "district_id": "170313",
    "label": "Teluk Anggung",
    "value": "Teluk Anggung"
  },
  {
    "id": "1703132008",
    "district_id": "170313",
    "label": "Tanjung Kemayan",
    "value": "Tanjung Kemayan"
  },
  {
    "id": "1703132009",
    "district_id": "170313",
    "label": "Muara Santan",
    "value": "Muara Santan"
  },
  {
    "id": "1703132016",
    "district_id": "170313",
    "label": "Lebong Tandai",
    "value": "Lebong Tandai"
  },
  {
    "id": "1703132019",
    "district_id": "170313",
    "label": "Gembung Raya",
    "value": "Gembung Raya"
  },
  {
    "id": "1703132020",
    "district_id": "170313",
    "label": "Kinal Jaya",
    "value": "Kinal Jaya"
  },
  {
    "id": "1703142007",
    "district_id": "170314",
    "label": "Talang Arah",
    "value": "Talang Arah"
  },
  {
    "id": "1703142008",
    "district_id": "170314",
    "label": "Pasar Sebelat",
    "value": "Pasar Sebelat"
  },
  {
    "id": "1703142009",
    "district_id": "170314",
    "label": "Kota Bani",
    "value": "Kota Bani"
  },
  {
    "id": "1703142013",
    "district_id": "170314",
    "label": "Air Muring",
    "value": "Air Muring"
  },
  {
    "id": "1703142014",
    "district_id": "170314",
    "label": "Air Pandan",
    "value": "Air Pandan"
  },
  {
    "id": "1703142015",
    "district_id": "170314",
    "label": "Cipta Mulya",
    "value": "Cipta Mulya"
  },
  {
    "id": "1703142017",
    "district_id": "170314",
    "label": "Air Petai",
    "value": "Air Petai"
  },
  {
    "id": "1703142018",
    "district_id": "170314",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "1703142019",
    "district_id": "170314",
    "label": "Karang Pulau",
    "value": "Karang Pulau"
  },
  {
    "id": "1703152003",
    "district_id": "170315",
    "label": "Genting Perangkap",
    "value": "Genting Perangkap"
  },
  {
    "id": "1703152004",
    "district_id": "170315",
    "label": "Lubuk Balam",
    "value": "Lubuk Balam"
  },
  {
    "id": "1703152005",
    "district_id": "170315",
    "label": "Datar Macang",
    "value": "Datar Macang"
  },
  {
    "id": "1703152006",
    "district_id": "170315",
    "label": "Tanjung Genting",
    "value": "Tanjung Genting"
  },
  {
    "id": "1703152007",
    "district_id": "170315",
    "label": "Talang Renah",
    "value": "Talang Renah"
  },
  {
    "id": "1703152008",
    "district_id": "170315",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1703152009",
    "district_id": "170315",
    "label": "Talang Lembak",
    "value": "Talang Lembak"
  },
  {
    "id": "1703152010",
    "district_id": "170315",
    "label": "Kertapati",
    "value": "Kertapati"
  },
  {
    "id": "1703152011",
    "district_id": "170315",
    "label": "Talang Pungguk",
    "value": "Talang Pungguk"
  },
  {
    "id": "1703152012",
    "district_id": "170315",
    "label": "Talang Baru Ginting",
    "value": "Talang Baru Ginting"
  },
  {
    "id": "1703152013",
    "district_id": "170315",
    "label": "Tanjung Karet",
    "value": "Tanjung Karet"
  },
  {
    "id": "1703152014",
    "district_id": "170315",
    "label": "Talang Ginting",
    "value": "Talang Ginting"
  },
  {
    "id": "1703152015",
    "district_id": "170315",
    "label": "Sungai Pura",
    "value": "Sungai Pura"
  },
  {
    "id": "1703152016",
    "district_id": "170315",
    "label": "Dusun Curup",
    "value": "Dusun Curup"
  },
  {
    "id": "1703152017",
    "district_id": "170315",
    "label": "Penyangkak",
    "value": "Penyangkak"
  },
  {
    "id": "1703162001",
    "district_id": "170316",
    "label": "Pasar Bembah",
    "value": "Pasar Bembah"
  },
  {
    "id": "1703162002",
    "district_id": "170316",
    "label": "Tepi Laut",
    "value": "Tepi Laut"
  },
  {
    "id": "1703162003",
    "district_id": "170316",
    "label": "Pasar Kerkap",
    "value": "Pasar Kerkap"
  },
  {
    "id": "1703162005",
    "district_id": "170316",
    "label": "Air Napal",
    "value": "Air Napal"
  },
  {
    "id": "1703162006",
    "district_id": "170316",
    "label": "Talang Kering",
    "value": "Talang Kering"
  },
  {
    "id": "1703162007",
    "district_id": "170316",
    "label": "Selubuk",
    "value": "Selubuk"
  },
  {
    "id": "1703162008",
    "district_id": "170316",
    "label": "Pasar Tebat",
    "value": "Pasar Tebat"
  },
  {
    "id": "1703162009",
    "district_id": "170316",
    "label": "Lubuk Tanjung",
    "value": "Lubuk Tanjung"
  },
  {
    "id": "1703162011",
    "district_id": "170316",
    "label": "Pukur",
    "value": "Pukur"
  },
  {
    "id": "1703162014",
    "district_id": "170316",
    "label": "Pasar Palik",
    "value": "Pasar Palik"
  },
  {
    "id": "1703162015",
    "district_id": "170316",
    "label": "Tebing Kandang",
    "value": "Tebing Kandang"
  },
  {
    "id": "1703162016",
    "district_id": "170316",
    "label": "Talang Jarang",
    "value": "Talang Jarang"
  },
  {
    "id": "1703192001",
    "district_id": "170319",
    "label": "Kota Lekat",
    "value": "Kota Lekat"
  },
  {
    "id": "1703192002",
    "district_id": "170319",
    "label": "Padang Bendar",
    "value": "Padang Bendar"
  },
  {
    "id": "1703192003",
    "district_id": "170319",
    "label": "Taba Padang R",
    "value": "Taba Padang R"
  },
  {
    "id": "1703192004",
    "district_id": "170319",
    "label": "Taba Padang Kol",
    "value": "Taba Padang Kol"
  },
  {
    "id": "1703192005",
    "district_id": "170319",
    "label": "Air Banai",
    "value": "Air Banai"
  },
  {
    "id": "1703192006",
    "district_id": "170319",
    "label": "Batu Raja R",
    "value": "Batu Raja R"
  },
  {
    "id": "1703192007",
    "district_id": "170319",
    "label": "Batu Raja Kol",
    "value": "Batu Raja Kol"
  },
  {
    "id": "1703192008",
    "district_id": "170319",
    "label": "Batu Layang",
    "value": "Batu Layang"
  },
  {
    "id": "1703192009",
    "district_id": "170319",
    "label": "Batu Roto",
    "value": "Batu Roto"
  },
  {
    "id": "1703192010",
    "district_id": "170319",
    "label": "Air Ba'us I",
    "value": "Air Ba'us I"
  },
  {
    "id": "1703192011",
    "district_id": "170319",
    "label": "Air Ba'us II",
    "value": "Air Ba'us II"
  },
  {
    "id": "1703192012",
    "district_id": "170319",
    "label": "Pematang Balam",
    "value": "Pematang Balam"
  },
  {
    "id": "1703192013",
    "district_id": "170319",
    "label": "Talang Rendah",
    "value": "Talang Rendah"
  },
  {
    "id": "1703192014",
    "district_id": "170319",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1703192015",
    "district_id": "170319",
    "label": "Kota Lekat Mudik",
    "value": "Kota Lekat Mudik"
  },
  {
    "id": "1703202001",
    "district_id": "170320",
    "label": "Padang Kala",
    "value": "Padang Kala"
  },
  {
    "id": "1703202002",
    "district_id": "170320",
    "label": "Kembang Manis",
    "value": "Kembang Manis"
  },
  {
    "id": "1703202003",
    "district_id": "170320",
    "label": "Teluk Ajang",
    "value": "Teluk Ajang"
  },
  {
    "id": "1703202004",
    "district_id": "170320",
    "label": "Balam",
    "value": "Balam"
  },
  {
    "id": "1703202005",
    "district_id": "170320",
    "label": "Suka Rami",
    "value": "Suka Rami"
  },
  {
    "id": "1703202006",
    "district_id": "170320",
    "label": "Mesigit",
    "value": "Mesigit"
  },
  {
    "id": "1703202007",
    "district_id": "170320",
    "label": "Retes",
    "value": "Retes"
  },
  {
    "id": "1703202008",
    "district_id": "170320",
    "label": "Lubuk Mumpo",
    "value": "Lubuk Mumpo"
  },
  {
    "id": "1703202009",
    "district_id": "170320",
    "label": "Talang Ulu",
    "value": "Talang Ulu"
  },
  {
    "id": "1703202010",
    "district_id": "170320",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1703211001",
    "district_id": "170321",
    "label": "Kemumu",
    "value": "Kemumu"
  },
  {
    "id": "1703212002",
    "district_id": "170321",
    "label": "Tebing Kaning",
    "value": "Tebing Kaning"
  },
  {
    "id": "1703212003",
    "district_id": "170321",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1703212004",
    "district_id": "170321",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1703212005",
    "district_id": "170321",
    "label": "Pematang Sapang",
    "value": "Pematang Sapang"
  },
  {
    "id": "1703212006",
    "district_id": "170321",
    "label": "Pagar Banyu",
    "value": "Pagar Banyu"
  },
  {
    "id": "1703212007",
    "district_id": "170321",
    "label": "Gardu",
    "value": "Gardu"
  },
  {
    "id": "1703212008",
    "district_id": "170321",
    "label": "Kali",
    "value": "Kali"
  },
  {
    "id": "1703212009",
    "district_id": "170321",
    "label": "Kalai Duai",
    "value": "Kalai Duai"
  },
  {
    "id": "1703212010",
    "district_id": "170321",
    "label": "Pagar Ruyung",
    "value": "Pagar Ruyung"
  },
  {
    "id": "1703212011",
    "district_id": "170321",
    "label": "Air Merah",
    "value": "Air Merah"
  },
  {
    "id": "1703212012",
    "district_id": "170321",
    "label": "Gunung Besar",
    "value": "Gunung Besar"
  },
  {
    "id": "1703222001",
    "district_id": "170322",
    "label": "Sengkuang",
    "value": "Sengkuang"
  },
  {
    "id": "1703222002",
    "district_id": "170322",
    "label": "Lubuk Pendam",
    "value": "Lubuk Pendam"
  },
  {
    "id": "1703222003",
    "district_id": "170322",
    "label": "Alun Dua",
    "value": "Alun Dua"
  },
  {
    "id": "1703222004",
    "district_id": "170322",
    "label": "Ketapi",
    "value": "Ketapi"
  },
  {
    "id": "1703222005",
    "district_id": "170322",
    "label": "Lubuk Semantung",
    "value": "Lubuk Semantung"
  },
  {
    "id": "1703222006",
    "district_id": "170322",
    "label": "Sawang Lebar",
    "value": "Sawang Lebar"
  },
  {
    "id": "1703222007",
    "district_id": "170322",
    "label": "Sawang Lebar Ilir",
    "value": "Sawang Lebar Ilir"
  },
  {
    "id": "1703222008",
    "district_id": "170322",
    "label": "Lubuk Gading",
    "value": "Lubuk Gading"
  },
  {
    "id": "1703222009",
    "district_id": "170322",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1703222010",
    "district_id": "170322",
    "label": "Padang Sepan",
    "value": "Padang Sepan"
  },
  {
    "id": "1703232001",
    "district_id": "170323",
    "label": "Air Lelangi",
    "value": "Air Lelangi"
  },
  {
    "id": "1703232002",
    "district_id": "170323",
    "label": "Bukit Berlian",
    "value": "Bukit Berlian"
  },
  {
    "id": "1703232003",
    "district_id": "170323",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1703232004",
    "district_id": "170323",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1703232005",
    "district_id": "170323",
    "label": "Pagardin",
    "value": "Pagardin"
  },
  {
    "id": "1703232006",
    "district_id": "170323",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1703232007",
    "district_id": "170323",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1703232008",
    "district_id": "170323",
    "label": "Bangun Karya",
    "value": "Bangun Karya"
  },
  {
    "id": "1703232009",
    "district_id": "170323",
    "label": "Pondok Bakil",
    "value": "Pondok Bakil"
  },
  {
    "id": "1703232010",
    "district_id": "170323",
    "label": "Talang Berantai",
    "value": "Talang Berantai"
  },
  {
    "id": "1703242001",
    "district_id": "170324",
    "label": "Air Sebayur",
    "value": "Air Sebayur"
  },
  {
    "id": "1703242002",
    "district_id": "170324",
    "label": "Air Sekamanak",
    "value": "Air Sekamanak"
  },
  {
    "id": "1703242003",
    "district_id": "170324",
    "label": "Air Simpang",
    "value": "Air Simpang"
  },
  {
    "id": "1703242004",
    "district_id": "170324",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "1703242005",
    "district_id": "170324",
    "label": "Marga Bhakti",
    "value": "Marga Bhakti"
  },
  {
    "id": "1703242006",
    "district_id": "170324",
    "label": "Bumi Harjo",
    "value": "Bumi Harjo"
  },
  {
    "id": "1703242007",
    "district_id": "170324",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1703242008",
    "district_id": "170324",
    "label": "Gunung Payung",
    "value": "Gunung Payung"
  },
  {
    "id": "1703242009",
    "district_id": "170324",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "1703242010",
    "district_id": "170324",
    "label": "Tanjung Muara",
    "value": "Tanjung Muara"
  },
  {
    "id": "1703252001",
    "district_id": "170325",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "1703252002",
    "district_id": "170325",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1703252003",
    "district_id": "170325",
    "label": "Karya Pelita",
    "value": "Karya Pelita"
  },
  {
    "id": "1703252004",
    "district_id": "170325",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1703252005",
    "district_id": "170325",
    "label": "Suka Baru",
    "value": "Suka Baru"
  },
  {
    "id": "1703252006",
    "district_id": "170325",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1703252007",
    "district_id": "170325",
    "label": "Suka Medan",
    "value": "Suka Medan"
  },
  {
    "id": "1703252008",
    "district_id": "170325",
    "label": "Karya Bakti",
    "value": "Karya Bakti"
  },
  {
    "id": "1703252009",
    "district_id": "170325",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "1703252010",
    "district_id": "170325",
    "label": "Suka Negara",
    "value": "Suka Negara"
  },
  {
    "id": "1704012001",
    "district_id": "170401",
    "label": "Gedung Wani",
    "value": "Gedung Wani"
  },
  {
    "id": "1704012002",
    "district_id": "170401",
    "label": "Penandingan",
    "value": "Penandingan"
  },
  {
    "id": "1704012003",
    "district_id": "170401",
    "label": "Geramat",
    "value": "Geramat"
  },
  {
    "id": "1704012004",
    "district_id": "170401",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1704012005",
    "district_id": "170401",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1704012006",
    "district_id": "170401",
    "label": "Pengurung",
    "value": "Pengurung"
  },
  {
    "id": "1704012007",
    "district_id": "170401",
    "label": "Pinang Jawa",
    "value": "Pinang Jawa"
  },
  {
    "id": "1704012008",
    "district_id": "170401",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1704012009",
    "district_id": "170401",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1704012010",
    "district_id": "170401",
    "label": "Gunung Megang",
    "value": "Gunung Megang"
  },
  {
    "id": "1704012020",
    "district_id": "170401",
    "label": "Talang Berangin",
    "value": "Talang Berangin"
  },
  {
    "id": "1704012021",
    "district_id": "170401",
    "label": "Jawi",
    "value": "Jawi"
  },
  {
    "id": "1704012022",
    "district_id": "170401",
    "label": "Papahan",
    "value": "Papahan"
  },
  {
    "id": "1704012023",
    "district_id": "170401",
    "label": "Pinang Jawa II",
    "value": "Pinang Jawa II"
  },
  {
    "id": "1704022002",
    "district_id": "170402",
    "label": "Tinggi Ari",
    "value": "Tinggi Ari"
  },
  {
    "id": "1704022003",
    "district_id": "170402",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1704022004",
    "district_id": "170402",
    "label": "Aur Ringit",
    "value": "Aur Ringit"
  },
  {
    "id": "1704022008",
    "district_id": "170402",
    "label": "Pelajaran",
    "value": "Pelajaran"
  },
  {
    "id": "1704022009",
    "district_id": "170402",
    "label": "Tanjung Kemuning",
    "value": "Tanjung Kemuning"
  },
  {
    "id": "1704022010",
    "district_id": "170402",
    "label": "Selika",
    "value": "Selika"
  },
  {
    "id": "1704022011",
    "district_id": "170402",
    "label": "Tanjung Iman",
    "value": "Tanjung Iman"
  },
  {
    "id": "1704022012",
    "district_id": "170402",
    "label": "Padang Leban",
    "value": "Padang Leban"
  },
  {
    "id": "1704022013",
    "district_id": "170402",
    "label": "Beriang Tinggi",
    "value": "Beriang Tinggi"
  },
  {
    "id": "1704022014",
    "district_id": "170402",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1704022015",
    "district_id": "170402",
    "label": "Pelajaran II",
    "value": "Pelajaran II"
  },
  {
    "id": "1704022016",
    "district_id": "170402",
    "label": "Selika II",
    "value": "Selika II"
  },
  {
    "id": "1704022017",
    "district_id": "170402",
    "label": "Sulauwangi",
    "value": "Sulauwangi"
  },
  {
    "id": "1704022018",
    "district_id": "170402",
    "label": "Padang Kedondong",
    "value": "Padang Kedondong"
  },
  {
    "id": "1704022020",
    "district_id": "170402",
    "label": "Padang Tinggi",
    "value": "Padang Tinggi"
  },
  {
    "id": "1704022021",
    "district_id": "170402",
    "label": "Tanjung Kemuning II",
    "value": "Tanjung Kemuning II"
  },
  {
    "id": "1704022022",
    "district_id": "170402",
    "label": "Tanjung Kemuning III",
    "value": "Tanjung Kemuning III"
  },
  {
    "id": "1704022023",
    "district_id": "170402",
    "label": "Tanjung Aur II",
    "value": "Tanjung Aur II"
  },
  {
    "id": "1704022024",
    "district_id": "170402",
    "label": "Tanjung Iman II",
    "value": "Tanjung Iman II"
  },
  {
    "id": "1704022025",
    "district_id": "170402",
    "label": "Selika III",
    "value": "Selika III"
  },
  {
    "id": "1704031010",
    "district_id": "170403",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1704032001",
    "district_id": "170403",
    "label": "Tanjung Betung",
    "value": "Tanjung Betung"
  },
  {
    "id": "1704032003",
    "district_id": "170403",
    "label": "Padang Manis",
    "value": "Padang Manis"
  },
  {
    "id": "1704032006",
    "district_id": "170403",
    "label": "Guru Agung",
    "value": "Guru Agung"
  },
  {
    "id": "1704032009",
    "district_id": "170403",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1704032021",
    "district_id": "170403",
    "label": "Bandu Agung",
    "value": "Bandu Agung"
  },
  {
    "id": "1704032027",
    "district_id": "170403",
    "label": "Tanjung Betung II",
    "value": "Tanjung Betung II"
  },
  {
    "id": "1704032028",
    "district_id": "170403",
    "label": "Coko Enau",
    "value": "Coko Enau"
  },
  {
    "id": "1704032029",
    "district_id": "170403",
    "label": "Pancur Negara",
    "value": "Pancur Negara"
  },
  {
    "id": "1704032030",
    "district_id": "170403",
    "label": "Perugaian",
    "value": "Perugaian"
  },
  {
    "id": "1704032031",
    "district_id": "170403",
    "label": "Guru Agung II",
    "value": "Guru Agung II"
  },
  {
    "id": "1704041007",
    "district_id": "170404",
    "label": "Tanjung Iman",
    "value": "Tanjung Iman"
  },
  {
    "id": "1704042001",
    "district_id": "170404",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1704042002",
    "district_id": "170404",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1704042003",
    "district_id": "170404",
    "label": "Padang Hangat",
    "value": "Padang Hangat"
  },
  {
    "id": "1704042004",
    "district_id": "170404",
    "label": "Kemang Manis",
    "value": "Kemang Manis"
  },
  {
    "id": "1704042005",
    "district_id": "170404",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1704042006",
    "district_id": "170404",
    "label": "Sinar Jaya",
    "value": "Sinar Jaya"
  },
  {
    "id": "1704042008",
    "district_id": "170404",
    "label": "Tanjung Pandan",
    "value": "Tanjung Pandan"
  },
  {
    "id": "1704042009",
    "district_id": "170404",
    "label": "Padang Baru",
    "value": "Padang Baru"
  },
  {
    "id": "1704051013",
    "district_id": "170405",
    "label": "Bandar Bintuhan",
    "value": "Bandar Bintuhan"
  },
  {
    "id": "1704052009",
    "district_id": "170405",
    "label": "Sekunyit",
    "value": "Sekunyit"
  },
  {
    "id": "1704052010",
    "district_id": "170405",
    "label": "Suka Bandung",
    "value": "Suka Bandung"
  },
  {
    "id": "1704052011",
    "district_id": "170405",
    "label": "Air Dingin",
    "value": "Air Dingin"
  },
  {
    "id": "1704052012",
    "district_id": "170405",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1704052014",
    "district_id": "170405",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "1704052015",
    "district_id": "170405",
    "label": "Gedung Sako",
    "value": "Gedung Sako"
  },
  {
    "id": "1704052016",
    "district_id": "170405",
    "label": "Jembatan Dua",
    "value": "Jembatan Dua"
  },
  {
    "id": "1704052017",
    "district_id": "170405",
    "label": "Tanjung Besar",
    "value": "Tanjung Besar"
  },
  {
    "id": "1704052021",
    "district_id": "170405",
    "label": "Pengubaian",
    "value": "Pengubaian"
  },
  {
    "id": "1704052022",
    "district_id": "170405",
    "label": "Pahlawan Ratu",
    "value": "Pahlawan Ratu"
  },
  {
    "id": "1704052023",
    "district_id": "170405",
    "label": "Pasar Saoh",
    "value": "Pasar Saoh"
  },
  {
    "id": "1704052024",
    "district_id": "170405",
    "label": "Kepala Pasar",
    "value": "Kepala Pasar"
  },
  {
    "id": "1704052025",
    "district_id": "170405",
    "label": "Padang Petron",
    "value": "Padang Petron"
  },
  {
    "id": "1704052026",
    "district_id": "170405",
    "label": "Sawah Jangkung",
    "value": "Sawah Jangkung"
  },
  {
    "id": "1704052027",
    "district_id": "170405",
    "label": "Selasih",
    "value": "Selasih"
  },
  {
    "id": "1704052028",
    "district_id": "170405",
    "label": "Padang Genting",
    "value": "Padang Genting"
  },
  {
    "id": "1704052029",
    "district_id": "170405",
    "label": "Gedung Sako II",
    "value": "Gedung Sako II"
  },
  {
    "id": "1704052030",
    "district_id": "170405",
    "label": "Sinar Pagi",
    "value": "Sinar Pagi"
  },
  {
    "id": "1704062001",
    "district_id": "170406",
    "label": "Parda Suka",
    "value": "Parda Suka"
  },
  {
    "id": "1704062002",
    "district_id": "170406",
    "label": "Air Long",
    "value": "Air Long"
  },
  {
    "id": "1704062003",
    "district_id": "170406",
    "label": "Linau",
    "value": "Linau"
  },
  {
    "id": "1704062004",
    "district_id": "170406",
    "label": "Way Hawang",
    "value": "Way Hawang"
  },
  {
    "id": "1704062005",
    "district_id": "170406",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1704062006",
    "district_id": "170406",
    "label": "Tanjung Ganti",
    "value": "Tanjung Ganti"
  },
  {
    "id": "1704062007",
    "district_id": "170406",
    "label": "Kedataran",
    "value": "Kedataran"
  },
  {
    "id": "1704062008",
    "district_id": "170406",
    "label": "Tanjung Aur",
    "value": "Tanjung Aur"
  },
  {
    "id": "1704062009",
    "district_id": "170406",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1704062010",
    "district_id": "170406",
    "label": "Suka Menanti",
    "value": "Suka Menanti"
  },
  {
    "id": "1704062012",
    "district_id": "170406",
    "label": "Benteng Harapan",
    "value": "Benteng Harapan"
  },
  {
    "id": "1704062013",
    "district_id": "170406",
    "label": "Bakal Makmur",
    "value": "Bakal Makmur"
  },
  {
    "id": "1704062014",
    "district_id": "170406",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "1704062015",
    "district_id": "170406",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1704062016",
    "district_id": "170406",
    "label": "Arga Mulya",
    "value": "Arga Mulya"
  },
  {
    "id": "1704062017",
    "district_id": "170406",
    "label": "Air Jelatang",
    "value": "Air Jelatang"
  },
  {
    "id": "1704062019",
    "district_id": "170406",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "1704062020",
    "district_id": "170406",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1704062021",
    "district_id": "170406",
    "label": "Sinar Mulya",
    "value": "Sinar Mulya"
  },
  {
    "id": "1704072001",
    "district_id": "170407",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1704072002",
    "district_id": "170407",
    "label": "Gedung Menung",
    "value": "Gedung Menung"
  },
  {
    "id": "1704072003",
    "district_id": "170407",
    "label": "Ulak Pandan",
    "value": "Ulak Pandan"
  },
  {
    "id": "1704072005",
    "district_id": "170407",
    "label": "Merpas",
    "value": "Merpas"
  },
  {
    "id": "1704072007",
    "district_id": "170407",
    "label": "Tanjung Betuah",
    "value": "Tanjung Betuah"
  },
  {
    "id": "1704072008",
    "district_id": "170407",
    "label": "Air Palawan",
    "value": "Air Palawan"
  },
  {
    "id": "1704072009",
    "district_id": "170407",
    "label": "Tebing Rambutan",
    "value": "Tebing Rambutan"
  },
  {
    "id": "1704072010",
    "district_id": "170407",
    "label": "Batu Lungun",
    "value": "Batu Lungun"
  },
  {
    "id": "1704072011",
    "district_id": "170407",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "1704072012",
    "district_id": "170407",
    "label": "Suku Tiga",
    "value": "Suku Tiga"
  },
  {
    "id": "1704072013",
    "district_id": "170407",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "1704072014",
    "district_id": "170407",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1704072015",
    "district_id": "170407",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1704072016",
    "district_id": "170407",
    "label": "Tri Jaya",
    "value": "Tri Jaya"
  },
  {
    "id": "1704072017",
    "district_id": "170407",
    "label": "Air Batang",
    "value": "Air Batang"
  },
  {
    "id": "1704072018",
    "district_id": "170407",
    "label": "Sinar Banten",
    "value": "Sinar Banten"
  },
  {
    "id": "1704072019",
    "district_id": "170407",
    "label": "Pasar Jum'at",
    "value": "Pasar Jum'at"
  },
  {
    "id": "1704082001",
    "district_id": "170408",
    "label": "Bunga Melur",
    "value": "Bunga Melur"
  },
  {
    "id": "1704082002",
    "district_id": "170408",
    "label": "Karang Dapo",
    "value": "Karang Dapo"
  },
  {
    "id": "1704082003",
    "district_id": "170408",
    "label": "Lubuk Gung",
    "value": "Lubuk Gung"
  },
  {
    "id": "1704082004",
    "district_id": "170408",
    "label": "Gunung Tiga",
    "value": "Gunung Tiga"
  },
  {
    "id": "1704082005",
    "district_id": "170408",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1704082006",
    "district_id": "170408",
    "label": "Mentiring",
    "value": "Mentiring"
  },
  {
    "id": "1704082007",
    "district_id": "170408",
    "label": "Cahaya Batin",
    "value": "Cahaya Batin"
  },
  {
    "id": "1704082008",
    "district_id": "170408",
    "label": "Awat Mata",
    "value": "Awat Mata"
  },
  {
    "id": "1704082009",
    "district_id": "170408",
    "label": "Nusuk",
    "value": "Nusuk"
  },
  {
    "id": "1704082010",
    "district_id": "170408",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1704082011",
    "district_id": "170408",
    "label": "Gunung Tiga II",
    "value": "Gunung Tiga II"
  },
  {
    "id": "1704082012",
    "district_id": "170408",
    "label": "Padang Panjang",
    "value": "Padang Panjang"
  },
  {
    "id": "1704082013",
    "district_id": "170408",
    "label": "Masria Baru",
    "value": "Masria Baru"
  },
  {
    "id": "1704092001",
    "district_id": "170409",
    "label": "Tanjung Ganti I",
    "value": "Tanjung Ganti I"
  },
  {
    "id": "1704092002",
    "district_id": "170409",
    "label": "Tanjung Ganti II",
    "value": "Tanjung Ganti II"
  },
  {
    "id": "1704092003",
    "district_id": "170409",
    "label": "Rigangan I",
    "value": "Rigangan I"
  },
  {
    "id": "1704092004",
    "district_id": "170409",
    "label": "Rigangan II",
    "value": "Rigangan II"
  },
  {
    "id": "1704092005",
    "district_id": "170409",
    "label": "Rigangan III",
    "value": "Rigangan III"
  },
  {
    "id": "1704092006",
    "district_id": "170409",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1704092007",
    "district_id": "170409",
    "label": "Darat Sawah",
    "value": "Darat Sawah"
  },
  {
    "id": "1704092008",
    "district_id": "170409",
    "label": "Siring Agung",
    "value": "Siring Agung"
  },
  {
    "id": "1704092009",
    "district_id": "170409",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1704092010",
    "district_id": "170409",
    "label": "Talang Tais",
    "value": "Talang Tais"
  },
  {
    "id": "1704092011",
    "district_id": "170409",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1704092012",
    "district_id": "170409",
    "label": "Talang Marap",
    "value": "Talang Marap"
  },
  {
    "id": "1704092013",
    "district_id": "170409",
    "label": "Suka Rami II",
    "value": "Suka Rami II"
  },
  {
    "id": "1704102001",
    "district_id": "170410",
    "label": "Bangun Jiwa",
    "value": "Bangun Jiwa"
  },
  {
    "id": "1704102002",
    "district_id": "170410",
    "label": "Cahaya Negeri",
    "value": "Cahaya Negeri"
  },
  {
    "id": "1704102003",
    "district_id": "170410",
    "label": "Padang Jati",
    "value": "Padang Jati"
  },
  {
    "id": "1704102004",
    "district_id": "170410",
    "label": "Ganda Suli",
    "value": "Ganda Suli"
  },
  {
    "id": "1704102005",
    "district_id": "170410",
    "label": "Durian Besar",
    "value": "Durian Besar"
  },
  {
    "id": "1704102006",
    "district_id": "170410",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1704102007",
    "district_id": "170410",
    "label": "Umbul",
    "value": "Umbul"
  },
  {
    "id": "1704102008",
    "district_id": "170410",
    "label": "Benua Ratu",
    "value": "Benua Ratu"
  },
  {
    "id": "1704102009",
    "district_id": "170410",
    "label": "Tuguk",
    "value": "Tuguk"
  },
  {
    "id": "1704102010",
    "district_id": "170410",
    "label": "Kepahyang",
    "value": "Kepahyang"
  },
  {
    "id": "1704102011",
    "district_id": "170410",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1704102012",
    "district_id": "170410",
    "label": "Serdang Indah",
    "value": "Serdang Indah"
  },
  {
    "id": "1704112001",
    "district_id": "170411",
    "label": "Muara Sahung",
    "value": "Muara Sahung"
  },
  {
    "id": "1704112002",
    "district_id": "170411",
    "label": "Tri Tunggal Bakti",
    "value": "Tri Tunggal Bakti"
  },
  {
    "id": "1704112003",
    "district_id": "170411",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1704112004",
    "district_id": "170411",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1704112005",
    "district_id": "170411",
    "label": "Ulak Bandung",
    "value": "Ulak Bandung"
  },
  {
    "id": "1704112006",
    "district_id": "170411",
    "label": "Cinta Makmur",
    "value": "Cinta Makmur"
  },
  {
    "id": "1704112007",
    "district_id": "170411",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "1704122001",
    "district_id": "170412",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1704122002",
    "district_id": "170412",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "1704122003",
    "district_id": "170412",
    "label": "Cucupan",
    "value": "Cucupan"
  },
  {
    "id": "1704122004",
    "district_id": "170412",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1704122005",
    "district_id": "170412",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1704122006",
    "district_id": "170412",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "1704122007",
    "district_id": "170412",
    "label": "Kepahyang",
    "value": "Kepahyang"
  },
  {
    "id": "1704122008",
    "district_id": "170412",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1704122009",
    "district_id": "170412",
    "label": "Muara Tetap",
    "value": "Muara Tetap"
  },
  {
    "id": "1704122010",
    "district_id": "170412",
    "label": "Kasuk Baru",
    "value": "Kasuk Baru"
  },
  {
    "id": "1704122011",
    "district_id": "170412",
    "label": "Padang Binjai",
    "value": "Padang Binjai"
  },
  {
    "id": "1704122012",
    "district_id": "170412",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "1704132001",
    "district_id": "170413",
    "label": "Datar Lebar",
    "value": "Datar Lebar"
  },
  {
    "id": "1704132002",
    "district_id": "170413",
    "label": "Tanjung Bunian",
    "value": "Tanjung Bunian"
  },
  {
    "id": "1704132003",
    "district_id": "170413",
    "label": "Sinar Bulan",
    "value": "Sinar Bulan"
  },
  {
    "id": "1704132004",
    "district_id": "170413",
    "label": "Sukananti",
    "value": "Sukananti"
  },
  {
    "id": "1704132005",
    "district_id": "170413",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1704132006",
    "district_id": "170413",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1704132007",
    "district_id": "170413",
    "label": "Senak",
    "value": "Senak"
  },
  {
    "id": "1704132008",
    "district_id": "170413",
    "label": "Datar Lebar II",
    "value": "Datar Lebar II"
  },
  {
    "id": "1704132009",
    "district_id": "170413",
    "label": "Aur Gading",
    "value": "Aur Gading"
  },
  {
    "id": "1704142001",
    "district_id": "170414",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1704142002",
    "district_id": "170414",
    "label": "Talang Besar",
    "value": "Talang Besar"
  },
  {
    "id": "1704142003",
    "district_id": "170414",
    "label": "Talang Jawi I",
    "value": "Talang Jawi I"
  },
  {
    "id": "1704142004",
    "district_id": "170414",
    "label": "Talang Jawi II",
    "value": "Talang Jawi II"
  },
  {
    "id": "1704142005",
    "district_id": "170414",
    "label": "Air Kering",
    "value": "Air Kering"
  },
  {
    "id": "1704142006",
    "district_id": "170414",
    "label": "Gunung Kaya",
    "value": "Gunung Kaya"
  },
  {
    "id": "1704142007",
    "district_id": "170414",
    "label": "Ulak Agung",
    "value": "Ulak Agung"
  },
  {
    "id": "1704142008",
    "district_id": "170414",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1704142009",
    "district_id": "170414",
    "label": "Air Kering II",
    "value": "Air Kering II"
  },
  {
    "id": "1704152001",
    "district_id": "170415",
    "label": "Naga Rantai",
    "value": "Naga Rantai"
  },
  {
    "id": "1704152002",
    "district_id": "170415",
    "label": "Bungin Tambun",
    "value": "Bungin Tambun"
  },
  {
    "id": "1704152003",
    "district_id": "170415",
    "label": "Bungin Tambun II",
    "value": "Bungin Tambun II"
  },
  {
    "id": "1704152004",
    "district_id": "170415",
    "label": "Bungin Tambun III",
    "value": "Bungin Tambun III"
  },
  {
    "id": "1704152005",
    "district_id": "170415",
    "label": "Manau IX",
    "value": "Manau IX"
  },
  {
    "id": "1704152006",
    "district_id": "170415",
    "label": "Manau IX Dua",
    "value": "Manau IX Dua"
  },
  {
    "id": "1704152007",
    "district_id": "170415",
    "label": "Pagar Alam",
    "value": "Pagar Alam"
  },
  {
    "id": "1704152008",
    "district_id": "170415",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1704152009",
    "district_id": "170415",
    "label": "Coko Betung",
    "value": "Coko Betung"
  },
  {
    "id": "1704152010",
    "district_id": "170415",
    "label": "Marga Mulyo",
    "value": "Marga Mulyo"
  },
  {
    "id": "1704152011",
    "district_id": "170415",
    "label": "Jati Mulyo",
    "value": "Jati Mulyo"
  },
  {
    "id": "1705011001",
    "district_id": "170501",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "1705011025",
    "district_id": "170501",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1705012002",
    "district_id": "170501",
    "label": "Niur",
    "value": "Niur"
  },
  {
    "id": "1705012003",
    "district_id": "170501",
    "label": "Lubuk Sahung",
    "value": "Lubuk Sahung"
  },
  {
    "id": "1705012011",
    "district_id": "170501",
    "label": "Bukit Peninjauan I",
    "value": "Bukit Peninjauan I"
  },
  {
    "id": "1705012026",
    "district_id": "170501",
    "label": "Padang Pelawi",
    "value": "Padang Pelawi"
  },
  {
    "id": "1705012027",
    "district_id": "170501",
    "label": "Kayu Arang",
    "value": "Kayu Arang"
  },
  {
    "id": "1705012028",
    "district_id": "170501",
    "label": "Jenggalu",
    "value": "Jenggalu"
  },
  {
    "id": "1705012029",
    "district_id": "170501",
    "label": "Cahaya Negeri",
    "value": "Cahaya Negeri"
  },
  {
    "id": "1705012030",
    "district_id": "170501",
    "label": "Bukit Peninjauan II",
    "value": "Bukit Peninjauan II"
  },
  {
    "id": "1705012032",
    "district_id": "170501",
    "label": "Riak Siabun",
    "value": "Riak Siabun"
  },
  {
    "id": "1705012033",
    "district_id": "170501",
    "label": "Padang Kuas",
    "value": "Padang Kuas"
  },
  {
    "id": "1705012034",
    "district_id": "170501",
    "label": "Kuti Agung",
    "value": "Kuti Agung"
  },
  {
    "id": "1705012035",
    "district_id": "170501",
    "label": "Riak Siabun I",
    "value": "Riak Siabun I"
  },
  {
    "id": "1705012036",
    "district_id": "170501",
    "label": "Sido Luhur",
    "value": "Sido Luhur"
  },
  {
    "id": "1705012037",
    "district_id": "170501",
    "label": "Sumber Arung",
    "value": "Sumber Arung"
  },
  {
    "id": "1705012038",
    "district_id": "170501",
    "label": "Sido Sari",
    "value": "Sido Sari"
  },
  {
    "id": "1705012039",
    "district_id": "170501",
    "label": "Sari Mulyo",
    "value": "Sari Mulyo"
  },
  {
    "id": "1705012043",
    "district_id": "170501",
    "label": "Air Kemuning",
    "value": "Air Kemuning"
  },
  {
    "id": "1705012044",
    "district_id": "170501",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1705012045",
    "district_id": "170501",
    "label": "Air Petai",
    "value": "Air Petai"
  },
  {
    "id": "1705021009",
    "district_id": "170502",
    "label": "Lubuk Kebur",
    "value": "Lubuk Kebur"
  },
  {
    "id": "1705021010",
    "district_id": "170502",
    "label": "Pasar Tais",
    "value": "Pasar Tais"
  },
  {
    "id": "1705021011",
    "district_id": "170502",
    "label": "Lubuk Lintang",
    "value": "Lubuk Lintang"
  },
  {
    "id": "1705021012",
    "district_id": "170502",
    "label": "Talang Saling",
    "value": "Talang Saling"
  },
  {
    "id": "1705021013",
    "district_id": "170502",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1705021014",
    "district_id": "170502",
    "label": "Talang Dantuk",
    "value": "Talang Dantuk"
  },
  {
    "id": "1705021024",
    "district_id": "170502",
    "label": "Napal",
    "value": "Napal"
  },
  {
    "id": "1705031014",
    "district_id": "170503",
    "label": "Masmambang",
    "value": "Masmambang"
  },
  {
    "id": "1705032002",
    "district_id": "170503",
    "label": "Napal Melintang",
    "value": "Napal Melintang"
  },
  {
    "id": "1705032003",
    "district_id": "170503",
    "label": "Serambi Gunung",
    "value": "Serambi Gunung"
  },
  {
    "id": "1705032005",
    "district_id": "170503",
    "label": "Air Payangan",
    "value": "Air Payangan"
  },
  {
    "id": "1705032012",
    "district_id": "170503",
    "label": "Batu Tugu",
    "value": "Batu Tugu"
  },
  {
    "id": "1705032013",
    "district_id": "170503",
    "label": "Kampai",
    "value": "Kampai"
  },
  {
    "id": "1705032015",
    "district_id": "170503",
    "label": "Lubuk Ngantungan",
    "value": "Lubuk Ngantungan"
  },
  {
    "id": "1705032016",
    "district_id": "170503",
    "label": "Durian Bubur",
    "value": "Durian Bubur"
  },
  {
    "id": "1705032017",
    "district_id": "170503",
    "label": "Muara Danau",
    "value": "Muara Danau"
  },
  {
    "id": "1705032018",
    "district_id": "170503",
    "label": "Lubuk Gio",
    "value": "Lubuk Gio"
  },
  {
    "id": "1705032019",
    "district_id": "170503",
    "label": "Kembang Seri",
    "value": "Kembang Seri"
  },
  {
    "id": "1705032034",
    "district_id": "170503",
    "label": "Bunut Tinggi",
    "value": "Bunut Tinggi"
  },
  {
    "id": "1705032035",
    "district_id": "170503",
    "label": "Air Teras",
    "value": "Air Teras"
  },
  {
    "id": "1705032036",
    "district_id": "170503",
    "label": "Lubuk Gadis",
    "value": "Lubuk Gadis"
  },
  {
    "id": "1705032045",
    "district_id": "170503",
    "label": "Simpang Tiga Pagar Gasing",
    "value": "Simpang Tiga Pagar Gasing"
  },
  {
    "id": "1705032046",
    "district_id": "170503",
    "label": "Harapan Mulya",
    "value": "Harapan Mulya"
  },
  {
    "id": "1705041005",
    "district_id": "170504",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1705042001",
    "district_id": "170504",
    "label": "Maras Jauh",
    "value": "Maras Jauh"
  },
  {
    "id": "1705042002",
    "district_id": "170504",
    "label": "Bandung Agung",
    "value": "Bandung Agung"
  },
  {
    "id": "1705042003",
    "district_id": "170504",
    "label": "Pinju Layang",
    "value": "Pinju Layang"
  },
  {
    "id": "1705042004",
    "district_id": "170504",
    "label": "Nanjungan",
    "value": "Nanjungan"
  },
  {
    "id": "1705042006",
    "district_id": "170504",
    "label": "Petai Kayu",
    "value": "Petai Kayu"
  },
  {
    "id": "1705042007",
    "district_id": "170504",
    "label": "Napalan",
    "value": "Napalan"
  },
  {
    "id": "1705042008",
    "district_id": "170504",
    "label": "Renah Gajah Mati I",
    "value": "Renah Gajah Mati I"
  },
  {
    "id": "1705042009",
    "district_id": "170504",
    "label": "Renah Gajah Mati II",
    "value": "Renah Gajah Mati II"
  },
  {
    "id": "1705042010",
    "district_id": "170504",
    "label": "Cugung Langu",
    "value": "Cugung Langu"
  },
  {
    "id": "1705042011",
    "district_id": "170504",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "1705042012",
    "district_id": "170504",
    "label": "Air Melancar",
    "value": "Air Melancar"
  },
  {
    "id": "1705042013",
    "district_id": "170504",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1705042014",
    "district_id": "170504",
    "label": "Nanti Agung",
    "value": "Nanti Agung"
  },
  {
    "id": "1705042015",
    "district_id": "170504",
    "label": "Talang Durian",
    "value": "Talang Durian"
  },
  {
    "id": "1705042016",
    "district_id": "170504",
    "label": "Gunung Mesir",
    "value": "Gunung Mesir"
  },
  {
    "id": "1705042017",
    "district_id": "170504",
    "label": "Kayu Elang",
    "value": "Kayu Elang"
  },
  {
    "id": "1705042018",
    "district_id": "170504",
    "label": "Padang Serunaian",
    "value": "Padang Serunaian"
  },
  {
    "id": "1705042019",
    "district_id": "170504",
    "label": "Teba Gunung",
    "value": "Teba Gunung"
  },
  {
    "id": "1705042020",
    "district_id": "170504",
    "label": "Mekar Sari Mukti",
    "value": "Mekar Sari Mukti"
  },
  {
    "id": "1705042021",
    "district_id": "170504",
    "label": "Kemang Manis",
    "value": "Kemang Manis"
  },
  {
    "id": "1705042022",
    "district_id": "170504",
    "label": "Gunung Megang",
    "value": "Gunung Megang"
  },
  {
    "id": "1705042023",
    "district_id": "170504",
    "label": "Telatan",
    "value": "Telatan"
  },
  {
    "id": "1705042024",
    "district_id": "170504",
    "label": "Suban",
    "value": "Suban"
  },
  {
    "id": "1705051005",
    "district_id": "170505",
    "label": "Kembang Mumpo",
    "value": "Kembang Mumpo"
  },
  {
    "id": "1705052001",
    "district_id": "170505",
    "label": "Sendawar",
    "value": "Sendawar"
  },
  {
    "id": "1705052002",
    "district_id": "170505",
    "label": "Gelombang",
    "value": "Gelombang"
  },
  {
    "id": "1705052003",
    "district_id": "170505",
    "label": "Lubuk Betung",
    "value": "Lubuk Betung"
  },
  {
    "id": "1705052004",
    "district_id": "170505",
    "label": "Padang Peri",
    "value": "Padang Peri"
  },
  {
    "id": "1705052006",
    "district_id": "170505",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1705052007",
    "district_id": "170505",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1705052008",
    "district_id": "170505",
    "label": "Ketapang Baru",
    "value": "Ketapang Baru"
  },
  {
    "id": "1705052009",
    "district_id": "170505",
    "label": "Tedunan",
    "value": "Tedunan"
  },
  {
    "id": "1705052010",
    "district_id": "170505",
    "label": "Talang Alai",
    "value": "Talang Alai"
  },
  {
    "id": "1705052011",
    "district_id": "170505",
    "label": "Padang Bakung",
    "value": "Padang Bakung"
  },
  {
    "id": "1705052012",
    "district_id": "170505",
    "label": "Jambat Akar",
    "value": "Jambat Akar"
  },
  {
    "id": "1705052013",
    "district_id": "170505",
    "label": "Gunung Kembang",
    "value": "Gunung Kembang"
  },
  {
    "id": "1705052014",
    "district_id": "170505",
    "label": "Gunung Bantan",
    "value": "Gunung Bantan"
  },
  {
    "id": "1705052015",
    "district_id": "170505",
    "label": "Maras Tengah",
    "value": "Maras Tengah"
  },
  {
    "id": "1705052016",
    "district_id": "170505",
    "label": "Padang Kelapa",
    "value": "Padang Kelapa"
  },
  {
    "id": "1705052017",
    "district_id": "170505",
    "label": "Pematang Riding",
    "value": "Pematang Riding"
  },
  {
    "id": "1705052018",
    "district_id": "170505",
    "label": "Talang Beringin",
    "value": "Talang Beringin"
  },
  {
    "id": "1705052019",
    "district_id": "170505",
    "label": "Genting Juar",
    "value": "Genting Juar"
  },
  {
    "id": "1705052020",
    "district_id": "170505",
    "label": "Muara Maras",
    "value": "Muara Maras"
  },
  {
    "id": "1705052021",
    "district_id": "170505",
    "label": "Rimbo Besak",
    "value": "Rimbo Besak"
  },
  {
    "id": "1705052022",
    "district_id": "170505",
    "label": "Muara Timput",
    "value": "Muara Timput"
  },
  {
    "id": "1705052023",
    "district_id": "170505",
    "label": "Serian Bandung",
    "value": "Serian Bandung"
  },
  {
    "id": "1705052024",
    "district_id": "170505",
    "label": "Talang Kemang",
    "value": "Talang Kemang"
  },
  {
    "id": "1705052025",
    "district_id": "170505",
    "label": "Maras Bantan",
    "value": "Maras Bantan"
  },
  {
    "id": "1705052026",
    "district_id": "170505",
    "label": "Karang Dapo",
    "value": "Karang Dapo"
  },
  {
    "id": "1705061006",
    "district_id": "170506",
    "label": "Dermayu",
    "value": "Dermayu"
  },
  {
    "id": "1705062001",
    "district_id": "170506",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1705062002",
    "district_id": "170506",
    "label": "Talang Alai",
    "value": "Talang Alai"
  },
  {
    "id": "1705062003",
    "district_id": "170506",
    "label": "Talang Benuang",
    "value": "Talang Benuang"
  },
  {
    "id": "1705062004",
    "district_id": "170506",
    "label": "Padang Pelasan",
    "value": "Padang Pelasan"
  },
  {
    "id": "1705062005",
    "district_id": "170506",
    "label": "Air Periukan",
    "value": "Air Periukan"
  },
  {
    "id": "1705062007",
    "district_id": "170506",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1705062008",
    "district_id": "170506",
    "label": "Pasar Ngalam",
    "value": "Pasar Ngalam"
  },
  {
    "id": "1705062009",
    "district_id": "170506",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "1705062010",
    "district_id": "170506",
    "label": "Kungkai Baru",
    "value": "Kungkai Baru"
  },
  {
    "id": "1705062011",
    "district_id": "170506",
    "label": "Tawang Rejo",
    "value": "Tawang Rejo"
  },
  {
    "id": "1705062012",
    "district_id": "170506",
    "label": "Lawang Agung",
    "value": "Lawang Agung"
  },
  {
    "id": "1705062013",
    "district_id": "170506",
    "label": "Taba Lubuk Puding",
    "value": "Taba Lubuk Puding"
  },
  {
    "id": "1705062014",
    "district_id": "170506",
    "label": "Talang Sebaris",
    "value": "Talang Sebaris"
  },
  {
    "id": "1705062015",
    "district_id": "170506",
    "label": "Lubuk Gilang",
    "value": "Lubuk Gilang"
  },
  {
    "id": "1705062016",
    "district_id": "170506",
    "label": "Lokasi Baru",
    "value": "Lokasi Baru"
  },
  {
    "id": "1705072001",
    "district_id": "170507",
    "label": "Talang Giring",
    "value": "Talang Giring"
  },
  {
    "id": "1705072002",
    "district_id": "170507",
    "label": "Padang Capo Ilir",
    "value": "Padang Capo Ilir"
  },
  {
    "id": "1705072003",
    "district_id": "170507",
    "label": "Padang Capo Ulu",
    "value": "Padang Capo Ulu"
  },
  {
    "id": "1705072004",
    "district_id": "170507",
    "label": "Talang Kebun",
    "value": "Talang Kebun"
  },
  {
    "id": "1705072005",
    "district_id": "170507",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1705072006",
    "district_id": "170507",
    "label": "Cawang",
    "value": "Cawang"
  },
  {
    "id": "1705072007",
    "district_id": "170507",
    "label": "Arang Sapat",
    "value": "Arang Sapat"
  },
  {
    "id": "1705072008",
    "district_id": "170507",
    "label": "Dusun Tengah",
    "value": "Dusun Tengah"
  },
  {
    "id": "1705072009",
    "district_id": "170507",
    "label": "Tanjung Kuaw",
    "value": "Tanjung Kuaw"
  },
  {
    "id": "1705072010",
    "district_id": "170507",
    "label": "Napal Jungur",
    "value": "Napal Jungur"
  },
  {
    "id": "1705072011",
    "district_id": "170507",
    "label": "Tumbu'an",
    "value": "Tumbu'an"
  },
  {
    "id": "1705072012",
    "district_id": "170507",
    "label": "Rena Panjang",
    "value": "Rena Panjang"
  },
  {
    "id": "1705072013",
    "district_id": "170507",
    "label": "Sakaian",
    "value": "Sakaian"
  },
  {
    "id": "1705072014",
    "district_id": "170507",
    "label": "Lubuk Terentang",
    "value": "Lubuk Terentang"
  },
  {
    "id": "1705082001",
    "district_id": "170508",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1705082002",
    "district_id": "170508",
    "label": "Air Latak",
    "value": "Air Latak"
  },
  {
    "id": "1705082003",
    "district_id": "170508",
    "label": "Talang Perapat",
    "value": "Talang Perapat"
  },
  {
    "id": "1705082004",
    "district_id": "170508",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1705082005",
    "district_id": "170508",
    "label": "Talang Tinggi",
    "value": "Talang Tinggi"
  },
  {
    "id": "1705082006",
    "district_id": "170508",
    "label": "Lunjuk",
    "value": "Lunjuk"
  },
  {
    "id": "1705082007",
    "district_id": "170508",
    "label": "Lubuk Lagan",
    "value": "Lubuk Lagan"
  },
  {
    "id": "1705082008",
    "district_id": "170508",
    "label": "Purbosari",
    "value": "Purbosari"
  },
  {
    "id": "1705082009",
    "district_id": "170508",
    "label": "Sengkuang Jaya",
    "value": "Sengkuang Jaya"
  },
  {
    "id": "1705091004",
    "district_id": "170509",
    "label": "Selebar",
    "value": "Selebar"
  },
  {
    "id": "1705091005",
    "district_id": "170509",
    "label": "Sembayat",
    "value": "Sembayat"
  },
  {
    "id": "1705091006",
    "district_id": "170509",
    "label": "Bunga Mas",
    "value": "Bunga Mas"
  },
  {
    "id": "1705092001",
    "district_id": "170509",
    "label": "Talang Sali",
    "value": "Talang Sali"
  },
  {
    "id": "1705092002",
    "district_id": "170509",
    "label": "Kunduran",
    "value": "Kunduran"
  },
  {
    "id": "1705092003",
    "district_id": "170509",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1705092007",
    "district_id": "170509",
    "label": "Tenangan",
    "value": "Tenangan"
  },
  {
    "id": "1705092008",
    "district_id": "170509",
    "label": "Rawa Sari",
    "value": "Rawa Sari"
  },
  {
    "id": "1705101003",
    "district_id": "170510",
    "label": "Puguk",
    "value": "Puguk"
  },
  {
    "id": "1705102001",
    "district_id": "170510",
    "label": "Talang Rami",
    "value": "Talang Rami"
  },
  {
    "id": "1705102002",
    "district_id": "170510",
    "label": "Talang Beringin",
    "value": "Talang Beringin"
  },
  {
    "id": "1705102004",
    "district_id": "170510",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "1705102005",
    "district_id": "170510",
    "label": "Selingsingan",
    "value": "Selingsingan"
  },
  {
    "id": "1705102006",
    "district_id": "170510",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "1705102007",
    "district_id": "170510",
    "label": "Sekalak",
    "value": "Sekalak"
  },
  {
    "id": "1705102008",
    "district_id": "170510",
    "label": "Lubuk Resam",
    "value": "Lubuk Resam"
  },
  {
    "id": "1705102009",
    "district_id": "170510",
    "label": "Sinar Pagi",
    "value": "Sinar Pagi"
  },
  {
    "id": "1705102010",
    "district_id": "170510",
    "label": "Talang Empat",
    "value": "Talang Empat"
  },
  {
    "id": "1705111008",
    "district_id": "170511",
    "label": "Rimbo Kedui",
    "value": "Rimbo Kedui"
  },
  {
    "id": "1705111010",
    "district_id": "170511",
    "label": "Padang Rambun",
    "value": "Padang Rambun"
  },
  {
    "id": "1705111011",
    "district_id": "170511",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1705112001",
    "district_id": "170511",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1705112002",
    "district_id": "170511",
    "label": "Tanjung Seru",
    "value": "Tanjung Seru"
  },
  {
    "id": "1705112003",
    "district_id": "170511",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "1705112004",
    "district_id": "170511",
    "label": "Pasar Seluma",
    "value": "Pasar Seluma"
  },
  {
    "id": "1705112005",
    "district_id": "170511",
    "label": "Padang Genting",
    "value": "Padang Genting"
  },
  {
    "id": "1705112006",
    "district_id": "170511",
    "label": "Sengkuang",
    "value": "Sengkuang"
  },
  {
    "id": "1705112007",
    "district_id": "170511",
    "label": "Tangga Batu",
    "value": "Tangga Batu"
  },
  {
    "id": "1705112009",
    "district_id": "170511",
    "label": "Tanjung Seluai",
    "value": "Tanjung Seluai"
  },
  {
    "id": "1705112012",
    "district_id": "170511",
    "label": "Padang Merbau",
    "value": "Padang Merbau"
  },
  {
    "id": "1705122001",
    "district_id": "170512",
    "label": "Pering Baru",
    "value": "Pering Baru"
  },
  {
    "id": "1705122002",
    "district_id": "170512",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1705122003",
    "district_id": "170512",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1705122004",
    "district_id": "170512",
    "label": "Napalan",
    "value": "Napalan"
  },
  {
    "id": "1705122005",
    "district_id": "170512",
    "label": "Taba",
    "value": "Taba"
  },
  {
    "id": "1705122006",
    "district_id": "170512",
    "label": "Bakal Dalam",
    "value": "Bakal Dalam"
  },
  {
    "id": "1705122007",
    "district_id": "170512",
    "label": "Lubuk Lagan",
    "value": "Lubuk Lagan"
  },
  {
    "id": "1705122008",
    "district_id": "170512",
    "label": "Sungai Petai",
    "value": "Sungai Petai"
  },
  {
    "id": "1705122009",
    "district_id": "170512",
    "label": "Suka Bulan",
    "value": "Suka Bulan"
  },
  {
    "id": "1705122010",
    "district_id": "170512",
    "label": "Tebat Sibun",
    "value": "Tebat Sibun"
  },
  {
    "id": "1705122011",
    "district_id": "170512",
    "label": "Batu Balai",
    "value": "Batu Balai"
  },
  {
    "id": "1705132001",
    "district_id": "170513",
    "label": "Pagar",
    "value": "Pagar"
  },
  {
    "id": "1705132002",
    "district_id": "170513",
    "label": "Muara Simpur",
    "value": "Muara Simpur"
  },
  {
    "id": "1705132003",
    "district_id": "170513",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1705132004",
    "district_id": "170513",
    "label": "Pagar Banyu",
    "value": "Pagar Banyu"
  },
  {
    "id": "1705132005",
    "district_id": "170513",
    "label": "Simpur Ijang",
    "value": "Simpur Ijang"
  },
  {
    "id": "1705132006",
    "district_id": "170513",
    "label": "Air Keruh",
    "value": "Air Keruh"
  },
  {
    "id": "1705132007",
    "district_id": "170513",
    "label": "Hargo Binangun",
    "value": "Hargo Binangun"
  },
  {
    "id": "1705132008",
    "district_id": "170513",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1705132009",
    "district_id": "170513",
    "label": "Giri Nanto",
    "value": "Giri Nanto"
  },
  {
    "id": "1705132010",
    "district_id": "170513",
    "label": "Banyu Kencana",
    "value": "Banyu Kencana"
  },
  {
    "id": "1705132011",
    "district_id": "170513",
    "label": "Muara Nibung",
    "value": "Muara Nibung"
  },
  {
    "id": "1705132012",
    "district_id": "170513",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1705132013",
    "district_id": "170513",
    "label": "Giri Mulya",
    "value": "Giri Mulya"
  },
  {
    "id": "1705142001",
    "district_id": "170514",
    "label": "Pasar Talo",
    "value": "Pasar Talo"
  },
  {
    "id": "1705142002",
    "district_id": "170514",
    "label": "Dusun Baru",
    "value": "Dusun Baru"
  },
  {
    "id": "1705142003",
    "district_id": "170514",
    "label": "Talang Kabu",
    "value": "Talang Kabu"
  },
  {
    "id": "1705142004",
    "district_id": "170514",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "1705142005",
    "district_id": "170514",
    "label": "Penago Baru",
    "value": "Penago Baru"
  },
  {
    "id": "1705142006",
    "district_id": "170514",
    "label": "Nanti Agung",
    "value": "Nanti Agung"
  },
  {
    "id": "1705142007",
    "district_id": "170514",
    "label": "Talang Panjang",
    "value": "Talang Panjang"
  },
  {
    "id": "1705142008",
    "district_id": "170514",
    "label": "Penago I",
    "value": "Penago I"
  },
  {
    "id": "1705142009",
    "district_id": "170514",
    "label": "Penago II",
    "value": "Penago II"
  },
  {
    "id": "1705142010",
    "district_id": "170514",
    "label": "Rawah Indah",
    "value": "Rawah Indah"
  },
  {
    "id": "1705142011",
    "district_id": "170514",
    "label": "Padang Cekur",
    "value": "Padang Cekur"
  },
  {
    "id": "1705142012",
    "district_id": "170514",
    "label": "Padang Batu",
    "value": "Padang Batu"
  },
  {
    "id": "1705142013",
    "district_id": "170514",
    "label": "Paluah Terap",
    "value": "Paluah Terap"
  },
  {
    "id": "1705142014",
    "district_id": "170514",
    "label": "Margo Sari",
    "value": "Margo Sari"
  },
  {
    "id": "1705142015",
    "district_id": "170514",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1706012005",
    "district_id": "170601",
    "label": "Suka Pindah",
    "value": "Suka Pindah"
  },
  {
    "id": "1706012006",
    "district_id": "170601",
    "label": "Lubuk Pinang",
    "value": "Lubuk Pinang"
  },
  {
    "id": "1706012007",
    "district_id": "170601",
    "label": "Arah Tiga",
    "value": "Arah Tiga"
  },
  {
    "id": "1706012008",
    "district_id": "170601",
    "label": "Lubuk Gedang",
    "value": "Lubuk Gedang"
  },
  {
    "id": "1706012009",
    "district_id": "170601",
    "label": "Tanjung Alai",
    "value": "Tanjung Alai"
  },
  {
    "id": "1706012013",
    "district_id": "170601",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1706012018",
    "district_id": "170601",
    "label": "Ranah Karya",
    "value": "Ranah Karya"
  },
  {
    "id": "1706021009",
    "district_id": "170602",
    "label": "Pasar Muko-Muko",
    "value": "Pasar Muko-Muko"
  },
  {
    "id": "1706021017",
    "district_id": "170602",
    "label": "Koto Jaya",
    "value": "Koto Jaya"
  },
  {
    "id": "1706021018",
    "district_id": "170602",
    "label": "Bandar Ratu",
    "value": "Bandar Ratu"
  },
  {
    "id": "1706022007",
    "district_id": "170602",
    "label": "Pasar Sebelah",
    "value": "Pasar Sebelah"
  },
  {
    "id": "1706022008",
    "district_id": "170602",
    "label": "Ujung Padang",
    "value": "Ujung Padang"
  },
  {
    "id": "1706022010",
    "district_id": "170602",
    "label": "Pondok Batu",
    "value": "Pondok Batu"
  },
  {
    "id": "1706022011",
    "district_id": "170602",
    "label": "Tanah Rekah",
    "value": "Tanah Rekah"
  },
  {
    "id": "1706022012",
    "district_id": "170602",
    "label": "Selagan Jaya",
    "value": "Selagan Jaya"
  },
  {
    "id": "1706022019",
    "district_id": "170602",
    "label": "Tanah Harapan",
    "value": "Tanah Harapan"
  },
  {
    "id": "1706032008",
    "district_id": "170603",
    "label": "Teras Terunjam",
    "value": "Teras Terunjam"
  },
  {
    "id": "1706032009",
    "district_id": "170603",
    "label": "Pondok Kopi",
    "value": "Pondok Kopi"
  },
  {
    "id": "1706032013",
    "district_id": "170603",
    "label": "Setia Budi",
    "value": "Setia Budi"
  },
  {
    "id": "1706032014",
    "district_id": "170603",
    "label": "Tunggal Jaya",
    "value": "Tunggal Jaya"
  },
  {
    "id": "1706032024",
    "district_id": "170603",
    "label": "Talang Kuning",
    "value": "Talang Kuning"
  },
  {
    "id": "1706032025",
    "district_id": "170603",
    "label": "Teruntung",
    "value": "Teruntung"
  },
  {
    "id": "1706032026",
    "district_id": "170603",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1706032027",
    "district_id": "170603",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1706042001",
    "district_id": "170604",
    "label": "Air Bikuk",
    "value": "Air Bikuk"
  },
  {
    "id": "1706042004",
    "district_id": "170604",
    "label": "Tunggang",
    "value": "Tunggang"
  },
  {
    "id": "1706042005",
    "district_id": "170604",
    "label": "Pondok Suguh",
    "value": "Pondok Suguh"
  },
  {
    "id": "1706042006",
    "district_id": "170604",
    "label": "Air Berau",
    "value": "Air Berau"
  },
  {
    "id": "1706042010",
    "district_id": "170604",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1706042014",
    "district_id": "170604",
    "label": "Pondok Kandang",
    "value": "Pondok Kandang"
  },
  {
    "id": "1706042022",
    "district_id": "170604",
    "label": "Bumi Mekar Jaya",
    "value": "Bumi Mekar Jaya"
  },
  {
    "id": "1706042027",
    "district_id": "170604",
    "label": "Lubuk Bento",
    "value": "Lubuk Bento"
  },
  {
    "id": "1706042029",
    "district_id": "170604",
    "label": "Sinar Laut",
    "value": "Sinar Laut"
  },
  {
    "id": "1706042030",
    "district_id": "170604",
    "label": "Karya Mulya",
    "value": "Karya Mulya"
  },
  {
    "id": "1706042031",
    "district_id": "170604",
    "label": "Teluk Bakung",
    "value": "Teluk Bakung"
  },
  {
    "id": "1706052004",
    "district_id": "170605",
    "label": "Air Buluh",
    "value": "Air Buluh"
  },
  {
    "id": "1706052005",
    "district_id": "170605",
    "label": "Pulau Baru",
    "value": "Pulau Baru"
  },
  {
    "id": "1706052006",
    "district_id": "170605",
    "label": "Pasar Ipuh",
    "value": "Pasar Ipuh"
  },
  {
    "id": "1706052007",
    "district_id": "170605",
    "label": "Medan Jaya",
    "value": "Medan Jaya"
  },
  {
    "id": "1706052008",
    "district_id": "170605",
    "label": "Sibak",
    "value": "Sibak"
  },
  {
    "id": "1706052009",
    "district_id": "170605",
    "label": "Semundam",
    "value": "Semundam"
  },
  {
    "id": "1706052014",
    "district_id": "170605",
    "label": "Retak Ilir",
    "value": "Retak Ilir"
  },
  {
    "id": "1706052015",
    "district_id": "170605",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1706052017",
    "district_id": "170605",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1706052023",
    "district_id": "170605",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "1706052028",
    "district_id": "170605",
    "label": "Pulai Payung",
    "value": "Pulai Payung"
  },
  {
    "id": "1706052029",
    "district_id": "170605",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "1706052030",
    "district_id": "170605",
    "label": "Tirta Mulya",
    "value": "Tirta Mulya"
  },
  {
    "id": "1706052033",
    "district_id": "170605",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1706052034",
    "district_id": "170605",
    "label": "Pulau Makmur",
    "value": "Pulau Makmur"
  },
  {
    "id": "1706052035",
    "district_id": "170605",
    "label": "Mundam Marap",
    "value": "Mundam Marap"
  },
  {
    "id": "1706062001",
    "district_id": "170606",
    "label": "Talang Arah",
    "value": "Talang Arah"
  },
  {
    "id": "1706062002",
    "district_id": "170606",
    "label": "Serami Baru",
    "value": "Serami Baru"
  },
  {
    "id": "1706062003",
    "district_id": "170606",
    "label": "Lubuk Talang",
    "value": "Lubuk Talang"
  },
  {
    "id": "1706062004",
    "district_id": "170606",
    "label": "Gajah Makmur",
    "value": "Gajah Makmur"
  },
  {
    "id": "1706062005",
    "district_id": "170606",
    "label": "Semambang Makmur",
    "value": "Semambang Makmur"
  },
  {
    "id": "1706062006",
    "district_id": "170606",
    "label": "Talang Baru",
    "value": "Talang Baru"
  },
  {
    "id": "1706062007",
    "district_id": "170606",
    "label": "Air Merah",
    "value": "Air Merah"
  },
  {
    "id": "1706072001",
    "district_id": "170607",
    "label": "Arga Jaya",
    "value": "Arga Jaya"
  },
  {
    "id": "1706072002",
    "district_id": "170607",
    "label": "Air Rami",
    "value": "Air Rami"
  },
  {
    "id": "1706072003",
    "district_id": "170607",
    "label": "Rami Mulya",
    "value": "Rami Mulya"
  },
  {
    "id": "1706072004",
    "district_id": "170607",
    "label": "Talang Rio",
    "value": "Talang Rio"
  },
  {
    "id": "1706072005",
    "district_id": "170607",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "1706072006",
    "district_id": "170607",
    "label": "Marga Mulia",
    "value": "Marga Mulia"
  },
  {
    "id": "1706072007",
    "district_id": "170607",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1706072008",
    "district_id": "170607",
    "label": "Dusun Pulau",
    "value": "Dusun Pulau"
  },
  {
    "id": "1706072009",
    "district_id": "170607",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "1706072010",
    "district_id": "170607",
    "label": "Tirta Kencana",
    "value": "Tirta Kencana"
  },
  {
    "id": "1706072011",
    "district_id": "170607",
    "label": "Cinta Asih",
    "value": "Cinta Asih"
  },
  {
    "id": "1706072012",
    "district_id": "170607",
    "label": "Bukit Mulya",
    "value": "Bukit Mulya"
  },
  {
    "id": "1706082001",
    "district_id": "170608",
    "label": "Pasar Bantal",
    "value": "Pasar Bantal"
  },
  {
    "id": "1706082002",
    "district_id": "170608",
    "label": "Nenggalo",
    "value": "Nenggalo"
  },
  {
    "id": "1706082003",
    "district_id": "170608",
    "label": "Pondok Baru",
    "value": "Pondok Baru"
  },
  {
    "id": "1706082004",
    "district_id": "170608",
    "label": "Bunga Tanjung",
    "value": "Bunga Tanjung"
  },
  {
    "id": "1706082005",
    "district_id": "170608",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1706082006",
    "district_id": "170608",
    "label": "Teramang Jaya",
    "value": "Teramang Jaya"
  },
  {
    "id": "1706082007",
    "district_id": "170608",
    "label": "Brangan Mulya",
    "value": "Brangan Mulya"
  },
  {
    "id": "1706082008",
    "district_id": "170608",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1706082009",
    "district_id": "170608",
    "label": "Lubuk Selandak",
    "value": "Lubuk Selandak"
  },
  {
    "id": "1706082010",
    "district_id": "170608",
    "label": "Nelan Indah",
    "value": "Nelan Indah"
  },
  {
    "id": "1706082011",
    "district_id": "170608",
    "label": "Mandi Angin Jaya",
    "value": "Mandi Angin Jaya"
  },
  {
    "id": "1706082012",
    "district_id": "170608",
    "label": "Batu Ejung",
    "value": "Batu Ejung"
  },
  {
    "id": "1706082013",
    "district_id": "170608",
    "label": "Pernyah",
    "value": "Pernyah"
  },
  {
    "id": "1706092001",
    "district_id": "170609",
    "label": "Sungai Ipuh",
    "value": "Sungai Ipuh"
  },
  {
    "id": "1706092002",
    "district_id": "170609",
    "label": "Lubuk Sahung",
    "value": "Lubuk Sahung"
  },
  {
    "id": "1706092003",
    "district_id": "170609",
    "label": "Surian Bungkal",
    "value": "Surian Bungkal"
  },
  {
    "id": "1706092004",
    "district_id": "170609",
    "label": "Sungai Gading",
    "value": "Sungai Gading"
  },
  {
    "id": "1706092005",
    "district_id": "170609",
    "label": "Pondok Baru",
    "value": "Pondok Baru"
  },
  {
    "id": "1706092006",
    "district_id": "170609",
    "label": "Sungai Jerinjing",
    "value": "Sungai Jerinjing"
  },
  {
    "id": "1706092007",
    "district_id": "170609",
    "label": "Lubuk Bangko",
    "value": "Lubuk Bangko"
  },
  {
    "id": "1706092008",
    "district_id": "170609",
    "label": "Talang Buai",
    "value": "Talang Buai"
  },
  {
    "id": "1706092009",
    "district_id": "170609",
    "label": "Aur Cina",
    "value": "Aur Cina"
  },
  {
    "id": "1706092010",
    "district_id": "170609",
    "label": "Talang Medan",
    "value": "Talang Medan"
  },
  {
    "id": "1706092011",
    "district_id": "170609",
    "label": "Sungai Ipuh Satu",
    "value": "Sungai Ipuh Satu"
  },
  {
    "id": "1706092012",
    "district_id": "170609",
    "label": "Sungai Ipuh Dua",
    "value": "Sungai Ipuh Dua"
  },
  {
    "id": "1706102001",
    "district_id": "170610",
    "label": "Penarik",
    "value": "Penarik"
  },
  {
    "id": "1706102002",
    "district_id": "170610",
    "label": "Lubuk Mukti",
    "value": "Lubuk Mukti"
  },
  {
    "id": "1706102003",
    "district_id": "170610",
    "label": "Bumi Mulya",
    "value": "Bumi Mulya"
  },
  {
    "id": "1706102004",
    "district_id": "170610",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1706102005",
    "district_id": "170610",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "1706102006",
    "district_id": "170610",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1706102007",
    "district_id": "170610",
    "label": "Sido Dadi",
    "value": "Sido Dadi"
  },
  {
    "id": "1706102008",
    "district_id": "170610",
    "label": "Maju Makmur",
    "value": "Maju Makmur"
  },
  {
    "id": "1706102009",
    "district_id": "170610",
    "label": "Sendang Mulyo",
    "value": "Sendang Mulyo"
  },
  {
    "id": "1706102010",
    "district_id": "170610",
    "label": "Marga Mulya Sakti",
    "value": "Marga Mulya Sakti"
  },
  {
    "id": "1706102011",
    "district_id": "170610",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "1706102012",
    "district_id": "170610",
    "label": "Mekar Mulya",
    "value": "Mekar Mulya"
  },
  {
    "id": "1706102013",
    "district_id": "170610",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1706102014",
    "district_id": "170610",
    "label": "Marga Mukti",
    "value": "Marga Mukti"
  },
  {
    "id": "1706112001",
    "district_id": "170611",
    "label": "Lubuk Sanai",
    "value": "Lubuk Sanai"
  },
  {
    "id": "1706112002",
    "district_id": "170611",
    "label": "Rawa Mulya",
    "value": "Rawa Mulya"
  },
  {
    "id": "1706112003",
    "district_id": "170611",
    "label": "Rawa Bangun",
    "value": "Rawa Bangun"
  },
  {
    "id": "1706112004",
    "district_id": "170611",
    "label": "Tanjung Mulya",
    "value": "Tanjung Mulya"
  },
  {
    "id": "1706112005",
    "district_id": "170611",
    "label": "Pauh Tarenja",
    "value": "Pauh Tarenja"
  },
  {
    "id": "1706112006",
    "district_id": "170611",
    "label": "Dusun Baru Pelokan",
    "value": "Dusun Baru Pelokan"
  },
  {
    "id": "1706112007",
    "district_id": "170611",
    "label": "Lubuk Sanai II",
    "value": "Lubuk Sanai II"
  },
  {
    "id": "1706112009",
    "district_id": "170611",
    "label": "Lubuk Sanai III",
    "value": "Lubuk Sanai III"
  },
  {
    "id": "1706122001",
    "district_id": "170612",
    "label": "Lalang Luas",
    "value": "Lalang Luas"
  },
  {
    "id": "1706122002",
    "district_id": "170612",
    "label": "Resno",
    "value": "Resno"
  },
  {
    "id": "1706122003",
    "district_id": "170612",
    "label": "Pondok Panjang",
    "value": "Pondok Panjang"
  },
  {
    "id": "1706122004",
    "district_id": "170612",
    "label": "Talang Petai",
    "value": "Talang Petai"
  },
  {
    "id": "1706122005",
    "district_id": "170612",
    "label": "Sungai Lintang",
    "value": "Sungai Lintang"
  },
  {
    "id": "1706122006",
    "district_id": "170612",
    "label": "Pondok Tengah",
    "value": "Pondok Tengah"
  },
  {
    "id": "1706122007",
    "district_id": "170612",
    "label": "Talang Sakti",
    "value": "Talang Sakti"
  },
  {
    "id": "1706122008",
    "district_id": "170612",
    "label": "Talang Sepakat",
    "value": "Talang Sepakat"
  },
  {
    "id": "1706122009",
    "district_id": "170612",
    "label": "Lubuk Cabau",
    "value": "Lubuk Cabau"
  },
  {
    "id": "1706122010",
    "district_id": "170612",
    "label": "Sungai Rengas",
    "value": "Sungai Rengas"
  },
  {
    "id": "1706132001",
    "district_id": "170613",
    "label": "Pondok Makmur",
    "value": "Pondok Makmur"
  },
  {
    "id": "1706132002",
    "district_id": "170613",
    "label": "Manjunto Jaya",
    "value": "Manjunto Jaya"
  },
  {
    "id": "1706132003",
    "district_id": "170613",
    "label": "Tirta Makmur",
    "value": "Tirta Makmur"
  },
  {
    "id": "1706132004",
    "district_id": "170613",
    "label": "Agung Jaya",
    "value": "Agung Jaya"
  },
  {
    "id": "1706132005",
    "district_id": "170613",
    "label": "Tirta Mulya",
    "value": "Tirta Mulya"
  },
  {
    "id": "1706132006",
    "district_id": "170613",
    "label": "Kota Praja",
    "value": "Kota Praja"
  },
  {
    "id": "1706132007",
    "district_id": "170613",
    "label": "Sinar Jaya",
    "value": "Sinar Jaya"
  },
  {
    "id": "1706132008",
    "district_id": "170613",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1706142001",
    "district_id": "170614",
    "label": "Dusun Baru V Koto",
    "value": "Dusun Baru V Koto"
  },
  {
    "id": "1706142002",
    "district_id": "170614",
    "label": "Air Dikit",
    "value": "Air Dikit"
  },
  {
    "id": "1706142003",
    "district_id": "170614",
    "label": "Pondok Lunang",
    "value": "Pondok Lunang"
  },
  {
    "id": "1706142004",
    "district_id": "170614",
    "label": "Sari Bulan",
    "value": "Sari Bulan"
  },
  {
    "id": "1706142005",
    "district_id": "170614",
    "label": "Sari Makmur",
    "value": "Sari Makmur"
  },
  {
    "id": "1706142006",
    "district_id": "170614",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1706142007",
    "district_id": "170614",
    "label": "Air Kasai",
    "value": "Air Kasai"
  },
  {
    "id": "1706152001",
    "district_id": "170615",
    "label": "Retak Mudik",
    "value": "Retak Mudik"
  },
  {
    "id": "1706152002",
    "district_id": "170615",
    "label": "Padang Gading",
    "value": "Padang Gading"
  },
  {
    "id": "1706152003",
    "district_id": "170615",
    "label": "Gajah Mati",
    "value": "Gajah Mati"
  },
  {
    "id": "1706152004",
    "district_id": "170615",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1706152005",
    "district_id": "170615",
    "label": "Sido Dadi",
    "value": "Sido Dadi"
  },
  {
    "id": "1706152006",
    "district_id": "170615",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1706152007",
    "district_id": "170615",
    "label": "Talang Gading",
    "value": "Talang Gading"
  },
  {
    "id": "1706152008",
    "district_id": "170615",
    "label": "Gading Jaya",
    "value": "Gading Jaya"
  },
  {
    "id": "1706152009",
    "district_id": "170615",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1707011004",
    "district_id": "170701",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "1707011006",
    "district_id": "170701",
    "label": "Pasar Muara Aman",
    "value": "Pasar Muara Aman"
  },
  {
    "id": "1707012002",
    "district_id": "170701",
    "label": "Gandung",
    "value": "Gandung"
  },
  {
    "id": "1707012005",
    "district_id": "170701",
    "label": "Tunggang",
    "value": "Tunggang"
  },
  {
    "id": "1707012007",
    "district_id": "170701",
    "label": "Talang Ulu",
    "value": "Talang Ulu"
  },
  {
    "id": "1707012014",
    "district_id": "170701",
    "label": "Nangai Amen",
    "value": "Nangai Amen"
  },
  {
    "id": "1707012016",
    "district_id": "170701",
    "label": "Lebong Tambang",
    "value": "Lebong Tambang"
  },
  {
    "id": "1707012022",
    "district_id": "170701",
    "label": "Ladang Palembang",
    "value": "Ladang Palembang"
  },
  {
    "id": "1707012023",
    "district_id": "170701",
    "label": "Kampung Dalam",
    "value": "Kampung Dalam"
  },
  {
    "id": "1707012024",
    "district_id": "170701",
    "label": "Kampung Muara Aman",
    "value": "Kampung Muara Aman"
  },
  {
    "id": "1707012028",
    "district_id": "170701",
    "label": "Lokasari",
    "value": "Lokasari"
  },
  {
    "id": "1707012033",
    "district_id": "170701",
    "label": "Gandung Baru",
    "value": "Gandung Baru"
  },
  {
    "id": "1707022001",
    "district_id": "170702",
    "label": "Sukau Kayo",
    "value": "Sukau Kayo"
  },
  {
    "id": "1707022004",
    "district_id": "170702",
    "label": "Tik Tebing",
    "value": "Tik Tebing"
  },
  {
    "id": "1707022005",
    "district_id": "170702",
    "label": "Tabeak Blau",
    "value": "Tabeak Blau"
  },
  {
    "id": "1707022006",
    "district_id": "170702",
    "label": "Tabeak Blau I",
    "value": "Tabeak Blau I"
  },
  {
    "id": "1707022008",
    "district_id": "170702",
    "label": "Daneu",
    "value": "Daneu"
  },
  {
    "id": "1707022012",
    "district_id": "170702",
    "label": "Blau",
    "value": "Blau"
  },
  {
    "id": "1707031005",
    "district_id": "170703",
    "label": "Embong Panjang",
    "value": "Embong Panjang"
  },
  {
    "id": "1707032006",
    "district_id": "170703",
    "label": "Tanjung Bungai I",
    "value": "Tanjung Bungai I"
  },
  {
    "id": "1707032007",
    "district_id": "170703",
    "label": "Semelako I",
    "value": "Semelako I"
  },
  {
    "id": "1707032008",
    "district_id": "170703",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1707032009",
    "district_id": "170703",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1707032013",
    "district_id": "170703",
    "label": "Semelako II",
    "value": "Semelako II"
  },
  {
    "id": "1707032014",
    "district_id": "170703",
    "label": "Semelako III",
    "value": "Semelako III"
  },
  {
    "id": "1707032015",
    "district_id": "170703",
    "label": "Semelako Atas",
    "value": "Semelako Atas"
  },
  {
    "id": "1707032016",
    "district_id": "170703",
    "label": "Danau Liang",
    "value": "Danau Liang"
  },
  {
    "id": "1707032020",
    "district_id": "170703",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1707032023",
    "district_id": "170703",
    "label": "Tanjung Bungai II",
    "value": "Tanjung Bungai II"
  },
  {
    "id": "1707041002",
    "district_id": "170704",
    "label": "Tes",
    "value": "Tes"
  },
  {
    "id": "1707041003",
    "district_id": "170704",
    "label": "Taba Anyar",
    "value": "Taba Anyar"
  },
  {
    "id": "1707041004",
    "district_id": "170704",
    "label": "Mubai",
    "value": "Mubai"
  },
  {
    "id": "1707041005",
    "district_id": "170704",
    "label": "Turan Lalang",
    "value": "Turan Lalang"
  },
  {
    "id": "1707042001",
    "district_id": "170704",
    "label": "Kutai Donok",
    "value": "Kutai Donok"
  },
  {
    "id": "1707042013",
    "district_id": "170704",
    "label": "Turan Tiging",
    "value": "Turan Tiging"
  },
  {
    "id": "1707042014",
    "district_id": "170704",
    "label": "Suka Sari",
    "value": "Suka Sari"
  },
  {
    "id": "1707042016",
    "district_id": "170704",
    "label": "Mangkurajo",
    "value": "Mangkurajo"
  },
  {
    "id": "1707042018",
    "district_id": "170704",
    "label": "Tik Jeniak",
    "value": "Tik Jeniak"
  },
  {
    "id": "1707042019",
    "district_id": "170704",
    "label": "Manai Blau",
    "value": "Manai Blau"
  },
  {
    "id": "1707051002",
    "district_id": "170705",
    "label": "Rimbo Pengadang",
    "value": "Rimbo Pengadang"
  },
  {
    "id": "1707052009",
    "district_id": "170705",
    "label": "Talang Ratau",
    "value": "Talang Ratau"
  },
  {
    "id": "1707052010",
    "district_id": "170705",
    "label": "Bioa Sengok",
    "value": "Bioa Sengok"
  },
  {
    "id": "1707052011",
    "district_id": "170705",
    "label": "Tik Kuto",
    "value": "Tik Kuto"
  },
  {
    "id": "1707052012",
    "district_id": "170705",
    "label": "Teluk Dien",
    "value": "Teluk Dien"
  },
  {
    "id": "1707052013",
    "district_id": "170705",
    "label": "Bajok",
    "value": "Bajok"
  },
  {
    "id": "1707061001",
    "district_id": "170706",
    "label": "Topos",
    "value": "Topos"
  },
  {
    "id": "1707062002",
    "district_id": "170706",
    "label": "Tik Sirong",
    "value": "Tik Sirong"
  },
  {
    "id": "1707062003",
    "district_id": "170706",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1707062004",
    "district_id": "170706",
    "label": "Ajai Siang",
    "value": "Ajai Siang"
  },
  {
    "id": "1707062005",
    "district_id": "170706",
    "label": "Talang Donok",
    "value": "Talang Donok"
  },
  {
    "id": "1707062006",
    "district_id": "170706",
    "label": "Talang Donok I",
    "value": "Talang Donok I"
  },
  {
    "id": "1707062007",
    "district_id": "170706",
    "label": "Talang Baru I",
    "value": "Talang Baru I"
  },
  {
    "id": "1707062008",
    "district_id": "170706",
    "label": "Talang Baru II",
    "value": "Talang Baru II"
  },
  {
    "id": "1707072001",
    "district_id": "170707",
    "label": "Karang Dapo Atas",
    "value": "Karang Dapo Atas"
  },
  {
    "id": "1707072002",
    "district_id": "170707",
    "label": "Bukit Nibung",
    "value": "Bukit Nibung"
  },
  {
    "id": "1707072003",
    "district_id": "170707",
    "label": "Karang Dapo Bawah",
    "value": "Karang Dapo Bawah"
  },
  {
    "id": "1707072004",
    "district_id": "170707",
    "label": "Pungguk Pedaro",
    "value": "Pungguk Pedaro"
  },
  {
    "id": "1707072005",
    "district_id": "170707",
    "label": "Talang Kerinci",
    "value": "Talang Kerinci"
  },
  {
    "id": "1707072006",
    "district_id": "170707",
    "label": "Pelabuhan Talang Liak",
    "value": "Pelabuhan Talang Liak"
  },
  {
    "id": "1707072007",
    "district_id": "170707",
    "label": "Talang Liak I",
    "value": "Talang Liak I"
  },
  {
    "id": "1707072008",
    "district_id": "170707",
    "label": "Talang Liak II",
    "value": "Talang Liak II"
  },
  {
    "id": "1707072009",
    "district_id": "170707",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "1707082001",
    "district_id": "170708",
    "label": "Ujung Tanjung I",
    "value": "Ujung Tanjung I"
  },
  {
    "id": "1707082002",
    "district_id": "170708",
    "label": "Muning Agung",
    "value": "Muning Agung"
  },
  {
    "id": "1707082003",
    "district_id": "170708",
    "label": "Ujung Tanjung II",
    "value": "Ujung Tanjung II"
  },
  {
    "id": "1707082004",
    "district_id": "170708",
    "label": "Ujung Tanjung III",
    "value": "Ujung Tanjung III"
  },
  {
    "id": "1707082005",
    "district_id": "170708",
    "label": "Magelang Baru",
    "value": "Magelang Baru"
  },
  {
    "id": "1707082006",
    "district_id": "170708",
    "label": "Lemeu Pit",
    "value": "Lemeu Pit"
  },
  {
    "id": "1707082007",
    "district_id": "170708",
    "label": "Tabeak Kauk",
    "value": "Tabeak Kauk"
  },
  {
    "id": "1707082008",
    "district_id": "170708",
    "label": "Tabeak Dipoa",
    "value": "Tabeak Dipoa"
  },
  {
    "id": "1707082009",
    "district_id": "170708",
    "label": "Suka Bumi",
    "value": "Suka Bumi"
  },
  {
    "id": "1707091001",
    "district_id": "170709",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1707092002",
    "district_id": "170709",
    "label": "Sukau Datang",
    "value": "Sukau Datang"
  },
  {
    "id": "1707092003",
    "district_id": "170709",
    "label": "Sukau Datang I",
    "value": "Sukau Datang I"
  },
  {
    "id": "1707092004",
    "district_id": "170709",
    "label": "Gunung Alam",
    "value": "Gunung Alam"
  },
  {
    "id": "1707092005",
    "district_id": "170709",
    "label": "Tabeak Blau II",
    "value": "Tabeak Blau II"
  },
  {
    "id": "1707092006",
    "district_id": "170709",
    "label": "Kota Baru Santan",
    "value": "Kota Baru Santan"
  },
  {
    "id": "1707092007",
    "district_id": "170709",
    "label": "Tik Teleu",
    "value": "Tik Teleu"
  },
  {
    "id": "1707092008",
    "district_id": "170709",
    "label": "Pelabai",
    "value": "Pelabai"
  },
  {
    "id": "1707101009",
    "district_id": "170710",
    "label": "Amen",
    "value": "Amen"
  },
  {
    "id": "1707102001",
    "district_id": "170710",
    "label": "Nangai Tayau",
    "value": "Nangai Tayau"
  },
  {
    "id": "1707102002",
    "district_id": "170710",
    "label": "Nangai Tayau I",
    "value": "Nangai Tayau I"
  },
  {
    "id": "1707102003",
    "district_id": "170710",
    "label": "Pyang Mbik",
    "value": "Pyang Mbik"
  },
  {
    "id": "1707102004",
    "district_id": "170710",
    "label": "Sukau Mergo",
    "value": "Sukau Mergo"
  },
  {
    "id": "1707102005",
    "district_id": "170710",
    "label": "Sukau Rajo",
    "value": "Sukau Rajo"
  },
  {
    "id": "1707102006",
    "district_id": "170710",
    "label": "Talang Bunut",
    "value": "Talang Bunut"
  },
  {
    "id": "1707102007",
    "district_id": "170710",
    "label": "Selebar Jaya",
    "value": "Selebar Jaya"
  },
  {
    "id": "1707102008",
    "district_id": "170710",
    "label": "Garut",
    "value": "Garut"
  },
  {
    "id": "1707102010",
    "district_id": "170710",
    "label": "Sungai Gerong",
    "value": "Sungai Gerong"
  },
  {
    "id": "1707112001",
    "district_id": "170711",
    "label": "Embong",
    "value": "Embong"
  },
  {
    "id": "1707112002",
    "district_id": "170711",
    "label": "Embong I",
    "value": "Embong I"
  },
  {
    "id": "1707112003",
    "district_id": "170711",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1707112004",
    "district_id": "170711",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1707112005",
    "district_id": "170711",
    "label": "Lemeu",
    "value": "Lemeu"
  },
  {
    "id": "1707112006",
    "district_id": "170711",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "1707112007",
    "district_id": "170711",
    "label": "Tangua",
    "value": "Tangua"
  },
  {
    "id": "1707122001",
    "district_id": "170712",
    "label": "Ketenong I",
    "value": "Ketenong I"
  },
  {
    "id": "1707122002",
    "district_id": "170712",
    "label": "Ketenong II",
    "value": "Ketenong II"
  },
  {
    "id": "1707122003",
    "district_id": "170712",
    "label": "Ketenong Jaya",
    "value": "Ketenong Jaya"
  },
  {
    "id": "1707122004",
    "district_id": "170712",
    "label": "Tambang Saweak",
    "value": "Tambang Saweak"
  },
  {
    "id": "1707122005",
    "district_id": "170712",
    "label": "Air Kopras",
    "value": "Air Kopras"
  },
  {
    "id": "1707122006",
    "district_id": "170712",
    "label": "Bioa Putiak",
    "value": "Bioa Putiak"
  },
  {
    "id": "1707122007",
    "district_id": "170712",
    "label": "Sebelat",
    "value": "Sebelat"
  },
  {
    "id": "1707122008",
    "district_id": "170712",
    "label": "Sungai Lisai",
    "value": "Sungai Lisai"
  },
  {
    "id": "1708011007",
    "district_id": "170801",
    "label": "Keban Agung",
    "value": "Keban Agung"
  },
  {
    "id": "1708012001",
    "district_id": "170801",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1708012002",
    "district_id": "170801",
    "label": "Kembang Seri",
    "value": "Kembang Seri"
  },
  {
    "id": "1708012003",
    "district_id": "170801",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1708012004",
    "district_id": "170801",
    "label": "Embong Ijuk",
    "value": "Embong Ijuk"
  },
  {
    "id": "1708012005",
    "district_id": "170801",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1708012006",
    "district_id": "170801",
    "label": "Talang Pito",
    "value": "Talang Pito"
  },
  {
    "id": "1708012008",
    "district_id": "170801",
    "label": "Cinto Mandi",
    "value": "Cinto Mandi"
  },
  {
    "id": "1708012009",
    "district_id": "170801",
    "label": "Lembur Lama",
    "value": "Lembur Lama"
  },
  {
    "id": "1708012010",
    "district_id": "170801",
    "label": "Taba Baru",
    "value": "Taba Baru"
  },
  {
    "id": "1708012011",
    "district_id": "170801",
    "label": "Bukit Menyan",
    "value": "Bukit Menyan"
  },
  {
    "id": "1708012016",
    "district_id": "170801",
    "label": "Batu Belarik",
    "value": "Batu Belarik"
  },
  {
    "id": "1708012017",
    "district_id": "170801",
    "label": "Embong Sido",
    "value": "Embong Sido"
  },
  {
    "id": "1708012022",
    "district_id": "170801",
    "label": "Talang Sawah",
    "value": "Talang Sawah"
  },
  {
    "id": "1708012023",
    "district_id": "170801",
    "label": "Sosokan Cinta Mandi",
    "value": "Sosokan Cinta Mandi"
  },
  {
    "id": "1708012024",
    "district_id": "170801",
    "label": "Muara Langkap",
    "value": "Muara Langkap"
  },
  {
    "id": "1708012025",
    "district_id": "170801",
    "label": "Air Raman",
    "value": "Air Raman"
  },
  {
    "id": "1708012028",
    "district_id": "170801",
    "label": "Langgar Jaya",
    "value": "Langgar Jaya"
  },
  {
    "id": "1708012029",
    "district_id": "170801",
    "label": "Cinta Mandi Baru",
    "value": "Cinta Mandi Baru"
  },
  {
    "id": "1708021002",
    "district_id": "170802",
    "label": "Ujan Mas Atas",
    "value": "Ujan Mas Atas"
  },
  {
    "id": "1708022001",
    "district_id": "170802",
    "label": "Daspeta",
    "value": "Daspeta"
  },
  {
    "id": "1708022003",
    "district_id": "170802",
    "label": "Suro Lembak",
    "value": "Suro Lembak"
  },
  {
    "id": "1708022004",
    "district_id": "170802",
    "label": "Tanjung Alam",
    "value": "Tanjung Alam"
  },
  {
    "id": "1708022005",
    "district_id": "170802",
    "label": "Suro Ilir",
    "value": "Suro Ilir"
  },
  {
    "id": "1708022006",
    "district_id": "170802",
    "label": "Suro Muncar",
    "value": "Suro Muncar"
  },
  {
    "id": "1708022007",
    "district_id": "170802",
    "label": "Suro Baru",
    "value": "Suro Baru"
  },
  {
    "id": "1708022008",
    "district_id": "170802",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "1708022009",
    "district_id": "170802",
    "label": "Bumi Sari",
    "value": "Bumi Sari"
  },
  {
    "id": "1708022016",
    "district_id": "170802",
    "label": "Cugung Lalang",
    "value": "Cugung Lalang"
  },
  {
    "id": "1708022017",
    "district_id": "170802",
    "label": "Ujan Mas Bawah",
    "value": "Ujan Mas Bawah"
  },
  {
    "id": "1708022018",
    "district_id": "170802",
    "label": "Suro Bali",
    "value": "Suro Bali"
  },
  {
    "id": "1708022019",
    "district_id": "170802",
    "label": "Pungguk Meranti",
    "value": "Pungguk Meranti"
  },
  {
    "id": "1708022020",
    "district_id": "170802",
    "label": "Pungguk Meringang",
    "value": "Pungguk Meringang"
  },
  {
    "id": "1708022021",
    "district_id": "170802",
    "label": "Meranti Jaya",
    "value": "Meranti Jaya"
  },
  {
    "id": "1708022023",
    "district_id": "170802",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "1708022024",
    "district_id": "170802",
    "label": "Daspetah II",
    "value": "Daspetah II"
  },
  {
    "id": "1708031008",
    "district_id": "170803",
    "label": "Tebat Karai",
    "value": "Tebat Karai"
  },
  {
    "id": "1708032006",
    "district_id": "170803",
    "label": "Taba Santing",
    "value": "Taba Santing"
  },
  {
    "id": "1708032007",
    "district_id": "170803",
    "label": "Tertik",
    "value": "Tertik"
  },
  {
    "id": "1708032009",
    "district_id": "170803",
    "label": "Talang Karet",
    "value": "Talang Karet"
  },
  {
    "id": "1708032010",
    "district_id": "170803",
    "label": "Peraduan Binjai",
    "value": "Peraduan Binjai"
  },
  {
    "id": "1708032011",
    "district_id": "170803",
    "label": "Penanjung Panjang",
    "value": "Penanjung Panjang"
  },
  {
    "id": "1708032012",
    "district_id": "170803",
    "label": "Nanti Agung",
    "value": "Nanti Agung"
  },
  {
    "id": "1708032013",
    "district_id": "170803",
    "label": "Taba Air Pauh",
    "value": "Taba Air Pauh"
  },
  {
    "id": "1708032014",
    "district_id": "170803",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "1708032019",
    "district_id": "170803",
    "label": "Tapak Gedung",
    "value": "Tapak Gedung"
  },
  {
    "id": "1708032020",
    "district_id": "170803",
    "label": "Tebing Penyamun",
    "value": "Tebing Penyamun"
  },
  {
    "id": "1708032021",
    "district_id": "170803",
    "label": "Sinar Gunung",
    "value": "Sinar Gunung"
  },
  {
    "id": "1708032022",
    "district_id": "170803",
    "label": "Taba Saling",
    "value": "Taba Saling"
  },
  {
    "id": "1708032023",
    "district_id": "170803",
    "label": "Penanjung Panjang Atas",
    "value": "Penanjung Panjang Atas"
  },
  {
    "id": "1708041003",
    "district_id": "170804",
    "label": "Pensiunan",
    "value": "Pensiunan"
  },
  {
    "id": "1708041004",
    "district_id": "170804",
    "label": "Pasar Kepahiangan",
    "value": "Pasar Kepahiangan"
  },
  {
    "id": "1708041005",
    "district_id": "170804",
    "label": "Pasar Ujung",
    "value": "Pasar Ujung"
  },
  {
    "id": "1708041011",
    "district_id": "170804",
    "label": "Dusun Kepahiang",
    "value": "Dusun Kepahiang"
  },
  {
    "id": "1708041025",
    "district_id": "170804",
    "label": "Kampung Pensiunan",
    "value": "Kampung Pensiunan"
  },
  {
    "id": "1708041026",
    "district_id": "170804",
    "label": "Pasar Sejantung",
    "value": "Pasar Sejantung"
  },
  {
    "id": "1708041027",
    "district_id": "170804",
    "label": "Padang Lekat",
    "value": "Padang Lekat"
  },
  {
    "id": "1708042001",
    "district_id": "170804",
    "label": "Kelilik",
    "value": "Kelilik"
  },
  {
    "id": "1708042002",
    "district_id": "170804",
    "label": "Tebat Monok",
    "value": "Tebat Monok"
  },
  {
    "id": "1708042006",
    "district_id": "170804",
    "label": "Permu",
    "value": "Permu"
  },
  {
    "id": "1708042007",
    "district_id": "170804",
    "label": "Imigrasi Permu",
    "value": "Imigrasi Permu"
  },
  {
    "id": "1708042008",
    "district_id": "170804",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1708042009",
    "district_id": "170804",
    "label": "Westkust",
    "value": "Westkust"
  },
  {
    "id": "1708042010",
    "district_id": "170804",
    "label": "Kampung Bogor",
    "value": "Kampung Bogor"
  },
  {
    "id": "1708042012",
    "district_id": "170804",
    "label": "Kuto Rejo",
    "value": "Kuto Rejo"
  },
  {
    "id": "1708042013",
    "district_id": "170804",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1708042014",
    "district_id": "170804",
    "label": "Taba Tebelet",
    "value": "Taba Tebelet"
  },
  {
    "id": "1708042015",
    "district_id": "170804",
    "label": "Pelangkian",
    "value": "Pelangkian"
  },
  {
    "id": "1708042016",
    "district_id": "170804",
    "label": "Kelobak",
    "value": "Kelobak"
  },
  {
    "id": "1708042017",
    "district_id": "170804",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1708042023",
    "district_id": "170804",
    "label": "Sukamerindu",
    "value": "Sukamerindu"
  },
  {
    "id": "1708042028",
    "district_id": "170804",
    "label": "Bogor Baru",
    "value": "Bogor Baru"
  },
  {
    "id": "1708042029",
    "district_id": "170804",
    "label": "Permu Bawah",
    "value": "Permu Bawah"
  },
  {
    "id": "1708051002",
    "district_id": "170805",
    "label": "Durian Depun",
    "value": "Durian Depun"
  },
  {
    "id": "1708052001",
    "district_id": "170805",
    "label": "Pulo Geto",
    "value": "Pulo Geto"
  },
  {
    "id": "1708052003",
    "district_id": "170805",
    "label": "Taba Mulan",
    "value": "Taba Mulan"
  },
  {
    "id": "1708052004",
    "district_id": "170805",
    "label": "Lubuk Penyamun",
    "value": "Lubuk Penyamun"
  },
  {
    "id": "1708052005",
    "district_id": "170805",
    "label": "SP. Kota Bingin",
    "value": "SP. Kota Bingin"
  },
  {
    "id": "1708052006",
    "district_id": "170805",
    "label": "Bukit Barisan",
    "value": "Bukit Barisan"
  },
  {
    "id": "1708052007",
    "district_id": "170805",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1708052008",
    "district_id": "170805",
    "label": "Pulo Geto Baru",
    "value": "Pulo Geto Baru"
  },
  {
    "id": "1708061005",
    "district_id": "170806",
    "label": "Tangsi Baru",
    "value": "Tangsi Baru"
  },
  {
    "id": "1708062001",
    "district_id": "170806",
    "label": "Bandung Baru",
    "value": "Bandung Baru"
  },
  {
    "id": "1708062002",
    "district_id": "170806",
    "label": "Bukit Sari",
    "value": "Bukit Sari"
  },
  {
    "id": "1708062003",
    "district_id": "170806",
    "label": "Tugu Rejo",
    "value": "Tugu Rejo"
  },
  {
    "id": "1708062004",
    "district_id": "170806",
    "label": "Suka Sari",
    "value": "Suka Sari"
  },
  {
    "id": "1708062006",
    "district_id": "170806",
    "label": "Air Sempiang",
    "value": "Air Sempiang"
  },
  {
    "id": "1708062007",
    "district_id": "170806",
    "label": "Tangsi Duren",
    "value": "Tangsi Duren"
  },
  {
    "id": "1708062008",
    "district_id": "170806",
    "label": "Babakan Bogor",
    "value": "Babakan Bogor"
  },
  {
    "id": "1708062009",
    "district_id": "170806",
    "label": "Barat Wetan",
    "value": "Barat Wetan"
  },
  {
    "id": "1708062010",
    "district_id": "170806",
    "label": "Pematang Donok",
    "value": "Pematang Donok"
  },
  {
    "id": "1708062011",
    "district_id": "170806",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1708062012",
    "district_id": "170806",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1708062013",
    "district_id": "170806",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1708062014",
    "district_id": "170806",
    "label": "Sido Rejo",
    "value": "Sido Rejo"
  },
  {
    "id": "1708062015",
    "district_id": "170806",
    "label": "Bandung Jaya",
    "value": "Bandung Jaya"
  },
  {
    "id": "1708072001",
    "district_id": "170807",
    "label": "Benuang Galing",
    "value": "Benuang Galing"
  },
  {
    "id": "1708072002",
    "district_id": "170807",
    "label": "Air Selimang",
    "value": "Air Selimang"
  },
  {
    "id": "1708072003",
    "district_id": "170807",
    "label": "Tebat Laut",
    "value": "Tebat Laut"
  },
  {
    "id": "1708072004",
    "district_id": "170807",
    "label": "Temdak",
    "value": "Temdak"
  },
  {
    "id": "1708072005",
    "district_id": "170807",
    "label": "Taba Padang",
    "value": "Taba Padang"
  },
  {
    "id": "1708072006",
    "district_id": "170807",
    "label": "Lubuk Saung",
    "value": "Lubuk Saung"
  },
  {
    "id": "1708072007",
    "district_id": "170807",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1708072008",
    "district_id": "170807",
    "label": "Cirebon Baru",
    "value": "Cirebon Baru"
  },
  {
    "id": "1708072009",
    "district_id": "170807",
    "label": "Talang Gelompok",
    "value": "Talang Gelompok"
  },
  {
    "id": "1708072010",
    "district_id": "170807",
    "label": "Air Pesi",
    "value": "Air Pesi"
  },
  {
    "id": "1708072011",
    "district_id": "170807",
    "label": "Talang Babatan",
    "value": "Talang Babatan"
  },
  {
    "id": "1708072012",
    "district_id": "170807",
    "label": "Bayung",
    "value": "Bayung"
  },
  {
    "id": "1708072013",
    "district_id": "170807",
    "label": "Sungai Jernih",
    "value": "Sungai Jernih"
  },
  {
    "id": "1708082001",
    "district_id": "170808",
    "label": "Limbur Baru",
    "value": "Limbur Baru"
  },
  {
    "id": "1708082002",
    "district_id": "170808",
    "label": "Batu Kalung",
    "value": "Batu Kalung"
  },
  {
    "id": "1708082003",
    "district_id": "170808",
    "label": "Batu Bandung",
    "value": "Batu Bandung"
  },
  {
    "id": "1708082004",
    "district_id": "170808",
    "label": "Sosokan Baru",
    "value": "Sosokan Baru"
  },
  {
    "id": "1708082005",
    "district_id": "170808",
    "label": "Sosokan Taba",
    "value": "Sosokan Taba"
  },
  {
    "id": "1708082006",
    "district_id": "170808",
    "label": "Talang Tige",
    "value": "Talang Tige"
  },
  {
    "id": "1708082007",
    "district_id": "170808",
    "label": "Renah Kurung",
    "value": "Renah Kurung"
  },
  {
    "id": "1708082008",
    "district_id": "170808",
    "label": "Warung Pojok",
    "value": "Warung Pojok"
  },
  {
    "id": "1709012001",
    "district_id": "170901",
    "label": "Taba Terunjam",
    "value": "Taba Terunjam"
  },
  {
    "id": "1709012002",
    "district_id": "170901",
    "label": "Talang Empat",
    "value": "Talang Empat"
  },
  {
    "id": "1709012003",
    "district_id": "170901",
    "label": "Padang Tambak",
    "value": "Padang Tambak"
  },
  {
    "id": "1709012004",
    "district_id": "170901",
    "label": "Durian Demang",
    "value": "Durian Demang"
  },
  {
    "id": "1709012005",
    "district_id": "170901",
    "label": "Pelajau",
    "value": "Pelajau"
  },
  {
    "id": "1709012007",
    "district_id": "170901",
    "label": "Dusun Baru II",
    "value": "Dusun Baru II"
  },
  {
    "id": "1709012008",
    "district_id": "170901",
    "label": "Penanding",
    "value": "Penanding"
  },
  {
    "id": "1709012009",
    "district_id": "170901",
    "label": "Karang Tinggi",
    "value": "Karang Tinggi"
  },
  {
    "id": "1709012010",
    "district_id": "170901",
    "label": "Renah Lebar",
    "value": "Renah Lebar"
  },
  {
    "id": "1709012011",
    "district_id": "170901",
    "label": "Kancing",
    "value": "Kancing"
  },
  {
    "id": "1709012012",
    "district_id": "170901",
    "label": "Ujung Karang",
    "value": "Ujung Karang"
  },
  {
    "id": "1709012013",
    "district_id": "170901",
    "label": "Taba Mutung",
    "value": "Taba Mutung"
  },
  {
    "id": "1709012015",
    "district_id": "170901",
    "label": "Renah Semanek",
    "value": "Renah Semanek"
  },
  {
    "id": "1709022001",
    "district_id": "170902",
    "label": "Air Sebakul",
    "value": "Air Sebakul"
  },
  {
    "id": "1709022004",
    "district_id": "170902",
    "label": "Taba Pasmah",
    "value": "Taba Pasmah"
  },
  {
    "id": "1709022005",
    "district_id": "170902",
    "label": "Kembang Seri",
    "value": "Kembang Seri"
  },
  {
    "id": "1709022006",
    "district_id": "170902",
    "label": "Tengah Padang",
    "value": "Tengah Padang"
  },
  {
    "id": "1709022007",
    "district_id": "170902",
    "label": "Jayakarta",
    "value": "Jayakarta"
  },
  {
    "id": "1709022008",
    "district_id": "170902",
    "label": "Nakau",
    "value": "Nakau"
  },
  {
    "id": "1709022009",
    "district_id": "170902",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1709022014",
    "district_id": "170902",
    "label": "Jum'at",
    "value": "Jum'at"
  },
  {
    "id": "1709022015",
    "district_id": "170902",
    "label": "Padang Ulak Tanjung",
    "value": "Padang Ulak Tanjung"
  },
  {
    "id": "1709022017",
    "district_id": "170902",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "1709032009",
    "district_id": "170903",
    "label": "Talang Pauh",
    "value": "Talang Pauh"
  },
  {
    "id": "1709032010",
    "district_id": "170903",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "1709032011",
    "district_id": "170903",
    "label": "Pekik Nyaring",
    "value": "Pekik Nyaring"
  },
  {
    "id": "1709032012",
    "district_id": "170903",
    "label": "Pasar Pedati",
    "value": "Pasar Pedati"
  },
  {
    "id": "1709032013",
    "district_id": "170903",
    "label": "Sunda Kelapa",
    "value": "Sunda Kelapa"
  },
  {
    "id": "1709032014",
    "district_id": "170903",
    "label": "Pondok Kelapa",
    "value": "Pondok Kelapa"
  },
  {
    "id": "1709032015",
    "district_id": "170903",
    "label": "Padang Betuah",
    "value": "Padang Betuah"
  },
  {
    "id": "1709032016",
    "district_id": "170903",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1709032017",
    "district_id": "170903",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1709032018",
    "district_id": "170903",
    "label": "Talang Boseng",
    "value": "Talang Boseng"
  },
  {
    "id": "1709032019",
    "district_id": "170903",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1709032020",
    "district_id": "170903",
    "label": "Kembang Ayun",
    "value": "Kembang Ayun"
  },
  {
    "id": "1709032021",
    "district_id": "170903",
    "label": "Panca Mukti",
    "value": "Panca Mukti"
  },
  {
    "id": "1709032022",
    "district_id": "170903",
    "label": "Sri Kuncoro",
    "value": "Sri Kuncoro"
  },
  {
    "id": "1709032023",
    "district_id": "170903",
    "label": "Bintang Selatan",
    "value": "Bintang Selatan"
  },
  {
    "id": "1709032024",
    "district_id": "170903",
    "label": "Abu Sakim",
    "value": "Abu Sakim"
  },
  {
    "id": "1709032025",
    "district_id": "170903",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "1709042002",
    "district_id": "170904",
    "label": "Aturan Mumpo",
    "value": "Aturan Mumpo"
  },
  {
    "id": "1709042003",
    "district_id": "170904",
    "label": "Talang Tengah II",
    "value": "Talang Tengah II"
  },
  {
    "id": "1709042004",
    "district_id": "170904",
    "label": "Kota Titik",
    "value": "Kota Titik"
  },
  {
    "id": "1709042007",
    "district_id": "170904",
    "label": "Senabah",
    "value": "Senabah"
  },
  {
    "id": "1709042015",
    "district_id": "170904",
    "label": "Air Kotok",
    "value": "Air Kotok"
  },
  {
    "id": "1709042016",
    "district_id": "170904",
    "label": "Batu Beriang",
    "value": "Batu Beriang"
  },
  {
    "id": "1709042017",
    "district_id": "170904",
    "label": "Pematang Tiga",
    "value": "Pematang Tiga"
  },
  {
    "id": "1709042018",
    "district_id": "170904",
    "label": "Tiambang",
    "value": "Tiambang"
  },
  {
    "id": "1709042019",
    "district_id": "170904",
    "label": "Kebun Lebar",
    "value": "Kebun Lebar"
  },
  {
    "id": "1709042020",
    "district_id": "170904",
    "label": "Tanjung Kepahyang",
    "value": "Tanjung Kepahyang"
  },
  {
    "id": "1709042021",
    "district_id": "170904",
    "label": "Pematang Tiga Lama",
    "value": "Pematang Tiga Lama"
  },
  {
    "id": "1709042022",
    "district_id": "170904",
    "label": "Aturan Mumpo II",
    "value": "Aturan Mumpo II"
  },
  {
    "id": "1709042023",
    "district_id": "170904",
    "label": "Genting Dabuk",
    "value": "Genting Dabuk"
  },
  {
    "id": "1709052006",
    "district_id": "170905",
    "label": "Temiang",
    "value": "Temiang"
  },
  {
    "id": "1709052008",
    "district_id": "170905",
    "label": "Taba Renah",
    "value": "Taba Renah"
  },
  {
    "id": "1709052009",
    "district_id": "170905",
    "label": "Kerta Pati",
    "value": "Kerta Pati"
  },
  {
    "id": "1709052010",
    "district_id": "170905",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1709052012",
    "district_id": "170905",
    "label": "Karang Are",
    "value": "Karang Are"
  },
  {
    "id": "1709052013",
    "district_id": "170905",
    "label": "Datar Penokat",
    "value": "Datar Penokat"
  },
  {
    "id": "1709052014",
    "district_id": "170905",
    "label": "Layang Lekat",
    "value": "Layang Lekat"
  },
  {
    "id": "1709052015",
    "district_id": "170905",
    "label": "Tumbuk",
    "value": "Tumbuk"
  },
  {
    "id": "1709052016",
    "district_id": "170905",
    "label": "Talang Curup",
    "value": "Talang Curup"
  },
  {
    "id": "1709052017",
    "district_id": "170905",
    "label": "Rena Kandis",
    "value": "Rena Kandis"
  },
  {
    "id": "1709052018",
    "district_id": "170905",
    "label": "Arga Indah I",
    "value": "Arga Indah I"
  },
  {
    "id": "1709052022",
    "district_id": "170905",
    "label": "Keroya",
    "value": "Keroya"
  },
  {
    "id": "1709052023",
    "district_id": "170905",
    "label": "Kertapati Mudik",
    "value": "Kertapati Mudik"
  },
  {
    "id": "1709052024",
    "district_id": "170905",
    "label": "Rena Jaya",
    "value": "Rena Jaya"
  },
  {
    "id": "1709061007",
    "district_id": "170906",
    "label": "Taba Penanjung",
    "value": "Taba Penanjung"
  },
  {
    "id": "1709062002",
    "district_id": "170906",
    "label": "Tanjung Raman",
    "value": "Tanjung Raman"
  },
  {
    "id": "1709062003",
    "district_id": "170906",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1709062004",
    "district_id": "170906",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "1709062005",
    "district_id": "170906",
    "label": "Lubuk Sini",
    "value": "Lubuk Sini"
  },
  {
    "id": "1709062008",
    "district_id": "170906",
    "label": "Taba Teret",
    "value": "Taba Teret"
  },
  {
    "id": "1709062009",
    "district_id": "170906",
    "label": "Surau",
    "value": "Surau"
  },
  {
    "id": "1709062010",
    "district_id": "170906",
    "label": "Rindu Hati",
    "value": "Rindu Hati"
  },
  {
    "id": "1709062011",
    "district_id": "170906",
    "label": "Tanjung Heran",
    "value": "Tanjung Heran"
  },
  {
    "id": "1709062012",
    "district_id": "170906",
    "label": "Datar Lebar",
    "value": "Datar Lebar"
  },
  {
    "id": "1709062013",
    "district_id": "170906",
    "label": "Penum",
    "value": "Penum"
  },
  {
    "id": "1709062023",
    "district_id": "170906",
    "label": "Bajak I",
    "value": "Bajak I"
  },
  {
    "id": "1709062024",
    "district_id": "170906",
    "label": "Taba Baru",
    "value": "Taba Baru"
  },
  {
    "id": "1709072001",
    "district_id": "170907",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "1709072002",
    "district_id": "170907",
    "label": "Penembang",
    "value": "Penembang"
  },
  {
    "id": "1709072003",
    "district_id": "170907",
    "label": "Talang Ambung",
    "value": "Talang Ambung"
  },
  {
    "id": "1709072004",
    "district_id": "170907",
    "label": "Taba Durian Sebakul",
    "value": "Taba Durian Sebakul"
  },
  {
    "id": "1709072005",
    "district_id": "170907",
    "label": "Lubuk Unen",
    "value": "Lubuk Unen"
  },
  {
    "id": "1709072006",
    "district_id": "170907",
    "label": "Pungguk Ketupak",
    "value": "Pungguk Ketupak"
  },
  {
    "id": "1709072007",
    "district_id": "170907",
    "label": "Pungguk Beringin",
    "value": "Pungguk Beringin"
  },
  {
    "id": "1709072008",
    "district_id": "170907",
    "label": "Ulak Lebar",
    "value": "Ulak Lebar"
  },
  {
    "id": "1709072009",
    "district_id": "170907",
    "label": "Kelindang",
    "value": "Kelindang"
  },
  {
    "id": "1709072010",
    "district_id": "170907",
    "label": "Bajak II",
    "value": "Bajak II"
  },
  {
    "id": "1709072011",
    "district_id": "170907",
    "label": "Lubuk Unen Baru",
    "value": "Lubuk Unen Baru"
  },
  {
    "id": "1709072012",
    "district_id": "170907",
    "label": "Padang Kedeper",
    "value": "Padang Kedeper"
  },
  {
    "id": "1709072013",
    "district_id": "170907",
    "label": "Kelindang Atas",
    "value": "Kelindang Atas"
  },
  {
    "id": "1709082001",
    "district_id": "170908",
    "label": "Komering",
    "value": "Komering"
  },
  {
    "id": "1709082002",
    "district_id": "170908",
    "label": "Bajak Tiga",
    "value": "Bajak Tiga"
  },
  {
    "id": "1709082003",
    "district_id": "170908",
    "label": "Rajak Besi",
    "value": "Rajak Besi"
  },
  {
    "id": "1709082004",
    "district_id": "170908",
    "label": "Punjung",
    "value": "Punjung"
  },
  {
    "id": "1709082005",
    "district_id": "170908",
    "label": "Susup",
    "value": "Susup"
  },
  {
    "id": "1709082006",
    "district_id": "170908",
    "label": "Arga Indah II",
    "value": "Arga Indah II"
  },
  {
    "id": "1709082007",
    "district_id": "170908",
    "label": "Curup",
    "value": "Curup"
  },
  {
    "id": "1709082008",
    "district_id": "170908",
    "label": "Karang Panggung",
    "value": "Karang Panggung"
  },
  {
    "id": "1709082009",
    "district_id": "170908",
    "label": "Lubuk Pendam",
    "value": "Lubuk Pendam"
  },
  {
    "id": "1709082010",
    "district_id": "170908",
    "label": "Lubuk Puar",
    "value": "Lubuk Puar"
  },
  {
    "id": "1709082011",
    "district_id": "170908",
    "label": "Durian Lebar",
    "value": "Durian Lebar"
  },
  {
    "id": "1709082012",
    "district_id": "170908",
    "label": "Pagar Agung",
    "value": "Pagar Agung"
  },
  {
    "id": "1709082013",
    "district_id": "170908",
    "label": "Pungguk Jaya",
    "value": "Pungguk Jaya"
  },
  {
    "id": "1709082014",
    "district_id": "170908",
    "label": "Taba Gemantung",
    "value": "Taba Gemantung"
  },
  {
    "id": "1709082015",
    "district_id": "170908",
    "label": "Pagar Besi",
    "value": "Pagar Besi"
  },
  {
    "id": "1709092001",
    "district_id": "170909",
    "label": "Pondok Kubang",
    "value": "Pondok Kubang"
  },
  {
    "id": "1709092002",
    "district_id": "170909",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "1709092003",
    "district_id": "170909",
    "label": "Dusun Anyar",
    "value": "Dusun Anyar"
  },
  {
    "id": "1709092004",
    "district_id": "170909",
    "label": "Tanjung Dalam",
    "value": "Tanjung Dalam"
  },
  {
    "id": "1709092005",
    "district_id": "170909",
    "label": "Talang Tengah I",
    "value": "Talang Tengah I"
  },
  {
    "id": "1709092006",
    "district_id": "170909",
    "label": "Paku Haji",
    "value": "Paku Haji"
  },
  {
    "id": "1709092007",
    "district_id": "170909",
    "label": "Tanjung Terdana",
    "value": "Tanjung Terdana"
  },
  {
    "id": "1709092008",
    "district_id": "170909",
    "label": "Linggar Galing",
    "value": "Linggar Galing"
  },
  {
    "id": "1709092009",
    "district_id": "170909",
    "label": "Dusun Baru I",
    "value": "Dusun Baru I"
  },
  {
    "id": "1709092010",
    "district_id": "170909",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "1709092011",
    "district_id": "170909",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1709092012",
    "district_id": "170909",
    "label": "Taba Jambu",
    "value": "Taba Jambu"
  },
  {
    "id": "1709102001",
    "district_id": "170910",
    "label": "Sekayun",
    "value": "Sekayun"
  },
  {
    "id": "1709102002",
    "district_id": "170910",
    "label": "Sekayun Mudik",
    "value": "Sekayun Mudik"
  },
  {
    "id": "1709102003",
    "district_id": "170910",
    "label": "Sekayun Hilir",
    "value": "Sekayun Hilir"
  },
  {
    "id": "1709102004",
    "district_id": "170910",
    "label": "Talang Donok",
    "value": "Talang Donok"
  },
  {
    "id": "1709102005",
    "district_id": "170910",
    "label": "Taba Tengah",
    "value": "Taba Tengah"
  },
  {
    "id": "1709102006",
    "district_id": "170910",
    "label": "Padang Berunai",
    "value": "Padang Berunai"
  },
  {
    "id": "1709102007",
    "district_id": "170910",
    "label": "Lubuk Langkap",
    "value": "Lubuk Langkap"
  },
  {
    "id": "1709102008",
    "district_id": "170910",
    "label": "Air Napal",
    "value": "Air Napal"
  },
  {
    "id": "1709102009",
    "district_id": "170910",
    "label": "Genting",
    "value": "Genting"
  },
  {
    "id": "1709102010",
    "district_id": "170910",
    "label": "Talang Panjang",
    "value": "Talang Panjang"
  },
  {
    "id": "1709102011",
    "district_id": "170910",
    "label": "Bang Haji",
    "value": "Bang Haji"
  },
  {
    "id": "1709102012",
    "district_id": "170910",
    "label": "Sungkai Berayun",
    "value": "Sungkai Berayun"
  },
  {
    "id": "1709112001",
    "district_id": "170911",
    "label": "Pagar Gunung",
    "value": "Pagar Gunung"
  },
  {
    "id": "1709112002",
    "district_id": "170911",
    "label": "Karang Nanding",
    "value": "Karang Nanding"
  },
  {
    "id": "1709112003",
    "district_id": "170911",
    "label": "Semidang",
    "value": "Semidang"
  },
  {
    "id": "1709112004",
    "district_id": "170911",
    "label": "Gajah Mati",
    "value": "Gajah Mati"
  },
  {
    "id": "1709112005",
    "district_id": "170911",
    "label": "Padang Siring",
    "value": "Padang Siring"
  },
  {
    "id": "1709112006",
    "district_id": "170911",
    "label": "Lagan Bungin",
    "value": "Lagan Bungin"
  },
  {
    "id": "1709112007",
    "district_id": "170911",
    "label": "Pagar Jati",
    "value": "Pagar Jati"
  },
  {
    "id": "1709112008",
    "district_id": "170911",
    "label": "Taba Lagan",
    "value": "Taba Lagan"
  },
  {
    "id": "1709112009",
    "district_id": "170911",
    "label": "Lagan",
    "value": "Lagan"
  },
  {
    "id": "1709112010",
    "district_id": "170911",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "1709112011",
    "district_id": "170911",
    "label": "Kota Niur",
    "value": "Kota Niur"
  },
  {
    "id": "1771011001",
    "district_id": "177101",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1771011002",
    "district_id": "177101",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "1771011003",
    "district_id": "177101",
    "label": "Pekan Sabtu",
    "value": "Pekan Sabtu"
  },
  {
    "id": "1771011004",
    "district_id": "177101",
    "label": "Bumu Ayu",
    "value": "Bumu Ayu"
  },
  {
    "id": "1771011005",
    "district_id": "177101",
    "label": "Betungan",
    "value": "Betungan"
  },
  {
    "id": "1771011006",
    "district_id": "177101",
    "label": "Sumur Dewa",
    "value": "Sumur Dewa"
  },
  {
    "id": "1771021001",
    "district_id": "177102",
    "label": "Padang Harapan",
    "value": "Padang Harapan"
  },
  {
    "id": "1771021002",
    "district_id": "177102",
    "label": "Jalan Gedang",
    "value": "Jalan Gedang"
  },
  {
    "id": "1771021021",
    "district_id": "177102",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1771021024",
    "district_id": "177102",
    "label": "Lingkar Barat",
    "value": "Lingkar Barat"
  },
  {
    "id": "1771021027",
    "district_id": "177102",
    "label": "Cempaka Permai",
    "value": "Cempaka Permai"
  },
  {
    "id": "1771031001",
    "district_id": "177103",
    "label": "Berkas",
    "value": "Berkas"
  },
  {
    "id": "1771031002",
    "district_id": "177103",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1771031003",
    "district_id": "177103",
    "label": "Jitra",
    "value": "Jitra"
  },
  {
    "id": "1771031004",
    "district_id": "177103",
    "label": "Pasar Melintang",
    "value": "Pasar Melintang"
  },
  {
    "id": "1771031005",
    "district_id": "177103",
    "label": "Kebun Roos",
    "value": "Kebun Roos"
  },
  {
    "id": "1771031007",
    "district_id": "177103",
    "label": "Tengah Padang",
    "value": "Tengah Padang"
  },
  {
    "id": "1771031008",
    "district_id": "177103",
    "label": "Bajak",
    "value": "Bajak"
  },
  {
    "id": "1771031010",
    "district_id": "177103",
    "label": "Pondok Besi",
    "value": "Pondok Besi"
  },
  {
    "id": "1771031011",
    "district_id": "177103",
    "label": "Kebun Keling",
    "value": "Kebun Keling"
  },
  {
    "id": "1771031013",
    "district_id": "177103",
    "label": "Sumur Meleleh",
    "value": "Sumur Meleleh"
  },
  {
    "id": "1771031014",
    "district_id": "177103",
    "label": "Malabero",
    "value": "Malabero"
  },
  {
    "id": "1771031018",
    "district_id": "177103",
    "label": "Pintu Batu",
    "value": "Pintu Batu"
  },
  {
    "id": "1771031019",
    "district_id": "177103",
    "label": "Kampung Bali",
    "value": "Kampung Bali"
  },
  {
    "id": "1771041001",
    "district_id": "177104",
    "label": "Kandang Limun",
    "value": "Kandang Limun"
  },
  {
    "id": "1771041002",
    "district_id": "177104",
    "label": "Bentiring",
    "value": "Bentiring"
  },
  {
    "id": "1771041003",
    "district_id": "177104",
    "label": "Pematang Gubernur",
    "value": "Pematang Gubernur"
  },
  {
    "id": "1771041004",
    "district_id": "177104",
    "label": "Rawa Makmur",
    "value": "Rawa Makmur"
  },
  {
    "id": "1771041005",
    "district_id": "177104",
    "label": "Beringin Raya",
    "value": "Beringin Raya"
  },
  {
    "id": "1771041006",
    "district_id": "177104",
    "label": "Bentiring Permai",
    "value": "Bentiring Permai"
  },
  {
    "id": "1771041007",
    "district_id": "177104",
    "label": "Rawa Makmur Permai",
    "value": "Rawa Makmur Permai"
  },
  {
    "id": "1771051001",
    "district_id": "177105",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "1771051002",
    "district_id": "177105",
    "label": "Kandang Mas",
    "value": "Kandang Mas"
  },
  {
    "id": "1771051003",
    "district_id": "177105",
    "label": "Teluk Sepang",
    "value": "Teluk Sepang"
  },
  {
    "id": "1771051004",
    "district_id": "177105",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1771051005",
    "district_id": "177105",
    "label": "Padang Serai",
    "value": "Padang Serai"
  },
  {
    "id": "1771051006",
    "district_id": "177105",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1771061001",
    "district_id": "177106",
    "label": "Kebun Tebeng",
    "value": "Kebun Tebeng"
  },
  {
    "id": "1771061002",
    "district_id": "177106",
    "label": "Tanah Patah",
    "value": "Tanah Patah"
  },
  {
    "id": "1771061003",
    "district_id": "177106",
    "label": "Nusa Indah",
    "value": "Nusa Indah"
  },
  {
    "id": "1771061004",
    "district_id": "177106",
    "label": "Kebun Beler",
    "value": "Kebun Beler"
  },
  {
    "id": "1771061005",
    "district_id": "177106",
    "label": "Kebun Kenanga",
    "value": "Kebun Kenanga"
  },
  {
    "id": "1771061006",
    "district_id": "177106",
    "label": "Sawah Lebar",
    "value": "Sawah Lebar"
  },
  {
    "id": "1771061007",
    "district_id": "177106",
    "label": "Sawah Lebar Baru",
    "value": "Sawah Lebar Baru"
  },
  {
    "id": "1771061008",
    "district_id": "177106",
    "label": "Lempuing",
    "value": "Lempuing"
  },
  {
    "id": "1771071001",
    "district_id": "177107",
    "label": "Padang Jati",
    "value": "Padang Jati"
  },
  {
    "id": "1771071002",
    "district_id": "177107",
    "label": "Kebun Dahri",
    "value": "Kebun Dahri"
  },
  {
    "id": "1771071003",
    "district_id": "177107",
    "label": "Belakang Pondok",
    "value": "Belakang Pondok"
  },
  {
    "id": "1771071004",
    "district_id": "177107",
    "label": "Penurunan",
    "value": "Penurunan"
  },
  {
    "id": "1771071005",
    "district_id": "177107",
    "label": "Anggut Bawah",
    "value": "Anggut Bawah"
  },
  {
    "id": "1771071006",
    "district_id": "177107",
    "label": "Anggut Atas",
    "value": "Anggut Atas"
  },
  {
    "id": "1771071007",
    "district_id": "177107",
    "label": "Anggut Dalam",
    "value": "Anggut Dalam"
  },
  {
    "id": "1771071008",
    "district_id": "177107",
    "label": "Kebun Geran",
    "value": "Kebun Geran"
  },
  {
    "id": "1771071009",
    "district_id": "177107",
    "label": "Pengantungan",
    "value": "Pengantungan"
  },
  {
    "id": "1771081001",
    "district_id": "177108",
    "label": "Kampung Kelawi",
    "value": "Kampung Kelawi"
  },
  {
    "id": "1771081002",
    "district_id": "177108",
    "label": "Sukamerindu",
    "value": "Sukamerindu"
  },
  {
    "id": "1771081003",
    "district_id": "177108",
    "label": "Semarang",
    "value": "Semarang"
  },
  {
    "id": "1771081004",
    "district_id": "177108",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1771081005",
    "district_id": "177108",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1771081006",
    "district_id": "177108",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "1771081007",
    "district_id": "177108",
    "label": "Pasar Bengkulu",
    "value": "Pasar Bengkulu"
  },
  {
    "id": "1771091001",
    "district_id": "177109",
    "label": "Panorama",
    "value": "Panorama"
  },
  {
    "id": "1771091002",
    "district_id": "177109",
    "label": "Jembatan Kecil",
    "value": "Jembatan Kecil"
  },
  {
    "id": "1771091003",
    "district_id": "177109",
    "label": "Dusun Besar",
    "value": "Dusun Besar"
  },
  {
    "id": "1771091004",
    "district_id": "177109",
    "label": "Padang Nangka",
    "value": "Padang Nangka"
  },
  {
    "id": "1771091005",
    "district_id": "177109",
    "label": "Timur Indah",
    "value": "Timur Indah"
  },
  {
    "id": "1771091006",
    "district_id": "177109",
    "label": "Lingkar Timur",
    "value": "Lingkar Timur"
  },
  {
    "id": "1801042001",
    "district_id": "180104",
    "label": "Hajimena",
    "value": "Hajimena"
  },
  {
    "id": "1801042002",
    "district_id": "180104",
    "label": "Sidosari",
    "value": "Sidosari"
  },
  {
    "id": "1801042003",
    "district_id": "180104",
    "label": "Pemanggilan",
    "value": "Pemanggilan"
  },
  {
    "id": "1801042004",
    "district_id": "180104",
    "label": "Natar",
    "value": "Natar"
  },
  {
    "id": "1801042005",
    "district_id": "180104",
    "label": "Muara Putih",
    "value": "Muara Putih"
  },
  {
    "id": "1801042006",
    "district_id": "180104",
    "label": "Negara Ratu",
    "value": "Negara Ratu"
  },
  {
    "id": "1801042007",
    "district_id": "180104",
    "label": "Merak Batin",
    "value": "Merak Batin"
  },
  {
    "id": "1801042008",
    "district_id": "180104",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1801042009",
    "district_id": "180104",
    "label": "Bumi Sari",
    "value": "Bumi Sari"
  },
  {
    "id": "1801042010",
    "district_id": "180104",
    "label": "Candi Mas",
    "value": "Candi Mas"
  },
  {
    "id": "1801042011",
    "district_id": "180104",
    "label": "Branti Raya",
    "value": "Branti Raya"
  },
  {
    "id": "1801042012",
    "district_id": "180104",
    "label": "Haduyang",
    "value": "Haduyang"
  },
  {
    "id": "1801042013",
    "district_id": "180104",
    "label": "Banjar Negeri",
    "value": "Banjar Negeri"
  },
  {
    "id": "1801042014",
    "district_id": "180104",
    "label": "Mandah",
    "value": "Mandah"
  },
  {
    "id": "1801042015",
    "district_id": "180104",
    "label": "Rulung Helok",
    "value": "Rulung Helok"
  },
  {
    "id": "1801042016",
    "district_id": "180104",
    "label": "Bandarejo",
    "value": "Bandarejo"
  },
  {
    "id": "1801042017",
    "district_id": "180104",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1801042018",
    "district_id": "180104",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1801042019",
    "district_id": "180104",
    "label": "Pancasila",
    "value": "Pancasila"
  },
  {
    "id": "1801042020",
    "district_id": "180104",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "1801042021",
    "district_id": "180104",
    "label": "Rulung Raya",
    "value": "Rulung Raya"
  },
  {
    "id": "1801042022",
    "district_id": "180104",
    "label": "Krawang Sari",
    "value": "Krawang Sari"
  },
  {
    "id": "1801042023",
    "district_id": "180104",
    "label": "Wai Sari",
    "value": "Wai Sari"
  },
  {
    "id": "1801042024",
    "district_id": "180104",
    "label": "Kali Sari",
    "value": "Kali Sari"
  },
  {
    "id": "1801042025",
    "district_id": "180104",
    "label": "Rulung Sari",
    "value": "Rulung Sari"
  },
  {
    "id": "1801042026",
    "district_id": "180104",
    "label": "Rulung Mulya",
    "value": "Rulung Mulya"
  },
  {
    "id": "1801052001",
    "district_id": "180105",
    "label": "Kaliasin",
    "value": "Kaliasin"
  },
  {
    "id": "1801052003",
    "district_id": "180105",
    "label": "Lematang",
    "value": "Lematang"
  },
  {
    "id": "1801052004",
    "district_id": "180105",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "1801052005",
    "district_id": "180105",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1801052006",
    "district_id": "180105",
    "label": "Sinar Ogan",
    "value": "Sinar Ogan"
  },
  {
    "id": "1801052007",
    "district_id": "180105",
    "label": "Jati Baru",
    "value": "Jati Baru"
  },
  {
    "id": "1801052008",
    "district_id": "180105",
    "label": "Sabah Balau",
    "value": "Sabah Balau"
  },
  {
    "id": "1801052009",
    "district_id": "180105",
    "label": "Budi Lestari",
    "value": "Budi Lestari"
  },
  {
    "id": "1801052010",
    "district_id": "180105",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "1801052011",
    "district_id": "180105",
    "label": "Jati Indah",
    "value": "Jati Indah"
  },
  {
    "id": "1801052012",
    "district_id": "180105",
    "label": "Way Galih",
    "value": "Way Galih"
  },
  {
    "id": "1801052013",
    "district_id": "180105",
    "label": "Sindang Sari",
    "value": "Sindang Sari"
  },
  {
    "id": "1801052015",
    "district_id": "180105",
    "label": "Purwodadi Simpang",
    "value": "Purwodadi Simpang"
  },
  {
    "id": "1801052021",
    "district_id": "180105",
    "label": "Galih Lunik",
    "value": "Galih Lunik"
  },
  {
    "id": "1801052022",
    "district_id": "180105",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "1801052023",
    "district_id": "180105",
    "label": "Sri Katon",
    "value": "Sri Katon"
  },
  {
    "id": "1801061006",
    "district_id": "180106",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1801061007",
    "district_id": "180106",
    "label": "Kalianda",
    "value": "Kalianda"
  },
  {
    "id": "1801061008",
    "district_id": "180106",
    "label": "Way Urang",
    "value": "Way Urang"
  },
  {
    "id": "1801061028",
    "district_id": "180106",
    "label": "Wai Lubuk",
    "value": "Wai Lubuk"
  },
  {
    "id": "1801062001",
    "district_id": "180106",
    "label": "Jondong",
    "value": "Jondong"
  },
  {
    "id": "1801062002",
    "district_id": "180106",
    "label": "Tengkujuh",
    "value": "Tengkujuh"
  },
  {
    "id": "1801062003",
    "district_id": "180106",
    "label": "Pauh Tanjung Iman",
    "value": "Pauh Tanjung Iman"
  },
  {
    "id": "1801062004",
    "district_id": "180106",
    "label": "Sumur Kumbang",
    "value": "Sumur Kumbang"
  },
  {
    "id": "1801062005",
    "district_id": "180106",
    "label": "Maja",
    "value": "Maja"
  },
  {
    "id": "1801062009",
    "district_id": "180106",
    "label": "Buah Berak",
    "value": "Buah Berak"
  },
  {
    "id": "1801062010",
    "district_id": "180106",
    "label": "Kesugihan",
    "value": "Kesugihan"
  },
  {
    "id": "1801062011",
    "district_id": "180106",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "1801062012",
    "district_id": "180106",
    "label": "Kecapi",
    "value": "Kecapi"
  },
  {
    "id": "1801062013",
    "district_id": "180106",
    "label": "Babulang",
    "value": "Babulang"
  },
  {
    "id": "1801062014",
    "district_id": "180106",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "1801062015",
    "district_id": "180106",
    "label": "Palembapang",
    "value": "Palembapang"
  },
  {
    "id": "1801062016",
    "district_id": "180106",
    "label": "Tajimalela",
    "value": "Tajimalela"
  },
  {
    "id": "1801062017",
    "district_id": "180106",
    "label": "Canggu",
    "value": "Canggu"
  },
  {
    "id": "1801062018",
    "district_id": "180106",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1801062019",
    "district_id": "180106",
    "label": "Merak Belatung",
    "value": "Merak Belatung"
  },
  {
    "id": "1801062020",
    "district_id": "180106",
    "label": "Bulok",
    "value": "Bulok"
  },
  {
    "id": "1801062021",
    "district_id": "180106",
    "label": "Munjuk Sampurna",
    "value": "Munjuk Sampurna"
  },
  {
    "id": "1801062022",
    "district_id": "180106",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1801062023",
    "district_id": "180106",
    "label": "Margacatur",
    "value": "Margacatur"
  },
  {
    "id": "1801062024",
    "district_id": "180106",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "1801062025",
    "district_id": "180106",
    "label": "Agom",
    "value": "Agom"
  },
  {
    "id": "1801062026",
    "district_id": "180106",
    "label": "Negeri Pandan",
    "value": "Negeri Pandan"
  },
  {
    "id": "1801062027",
    "district_id": "180106",
    "label": "Taman Agung",
    "value": "Taman Agung"
  },
  {
    "id": "1801062029",
    "district_id": "180106",
    "label": "Hara Banjar Manis",
    "value": "Hara Banjar Manis"
  },
  {
    "id": "1801072001",
    "district_id": "180107",
    "label": "Bandar Dalam",
    "value": "Bandar Dalam"
  },
  {
    "id": "1801072002",
    "district_id": "180107",
    "label": "Campang Tiga",
    "value": "Campang Tiga"
  },
  {
    "id": "1801072003",
    "district_id": "180107",
    "label": "Talang Baru",
    "value": "Talang Baru"
  },
  {
    "id": "1801072004",
    "district_id": "180107",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1801072005",
    "district_id": "180107",
    "label": "Kota Dalam",
    "value": "Kota Dalam"
  },
  {
    "id": "1801072006",
    "district_id": "180107",
    "label": "Budidaya",
    "value": "Budidaya"
  },
  {
    "id": "1801072007",
    "district_id": "180107",
    "label": "Siring Jaha",
    "value": "Siring Jaha"
  },
  {
    "id": "1801072008",
    "district_id": "180107",
    "label": "Suak",
    "value": "Suak"
  },
  {
    "id": "1801072009",
    "district_id": "180107",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1801072010",
    "district_id": "180107",
    "label": "Sukamarga",
    "value": "Sukamarga"
  },
  {
    "id": "1801072011",
    "district_id": "180107",
    "label": "Seloretno",
    "value": "Seloretno"
  },
  {
    "id": "1801072012",
    "district_id": "180107",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1801072013",
    "district_id": "180107",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1801072014",
    "district_id": "180107",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1801072015",
    "district_id": "180107",
    "label": "Sidowaluyo",
    "value": "Sidowaluyo"
  },
  {
    "id": "1801072020",
    "district_id": "180107",
    "label": "Banjar Suri",
    "value": "Banjar Suri"
  },
  {
    "id": "1801082001",
    "district_id": "180108",
    "label": "Tarahan",
    "value": "Tarahan"
  },
  {
    "id": "1801082002",
    "district_id": "180108",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "1801082003",
    "district_id": "180108",
    "label": "Pardasuka",
    "value": "Pardasuka"
  },
  {
    "id": "1801082004",
    "district_id": "180108",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1801082005",
    "district_id": "180108",
    "label": "Tanjung Ratu",
    "value": "Tanjung Ratu"
  },
  {
    "id": "1801082006",
    "district_id": "180108",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1801082007",
    "district_id": "180108",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "1801082008",
    "district_id": "180108",
    "label": "Trans Tanjungan",
    "value": "Trans Tanjungan"
  },
  {
    "id": "1801082009",
    "district_id": "180108",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "1801082018",
    "district_id": "180108",
    "label": "Karya Tunggal",
    "value": "Karya Tunggal"
  },
  {
    "id": "1801082019",
    "district_id": "180108",
    "label": "Rangai Tri Tunggal",
    "value": "Rangai Tri Tunggal"
  },
  {
    "id": "1801082020",
    "district_id": "180108",
    "label": "Sidomekar",
    "value": "Sidomekar"
  },
  {
    "id": "1801092001",
    "district_id": "180109",
    "label": "Kekiling",
    "value": "Kekiling"
  },
  {
    "id": "1801092002",
    "district_id": "180109",
    "label": "Belambangan",
    "value": "Belambangan"
  },
  {
    "id": "1801092003",
    "district_id": "180109",
    "label": "Rawi",
    "value": "Rawi"
  },
  {
    "id": "1801092004",
    "district_id": "180109",
    "label": "Padan",
    "value": "Padan"
  },
  {
    "id": "1801092005",
    "district_id": "180109",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1801092006",
    "district_id": "180109",
    "label": "Taman Baru",
    "value": "Taman Baru"
  },
  {
    "id": "1801092007",
    "district_id": "180109",
    "label": "Kelau",
    "value": "Kelau"
  },
  {
    "id": "1801092008",
    "district_id": "180109",
    "label": "Ruang Tengah",
    "value": "Ruang Tengah"
  },
  {
    "id": "1801092009",
    "district_id": "180109",
    "label": "Pasuruan",
    "value": "Pasuruan"
  },
  {
    "id": "1801092010",
    "district_id": "180109",
    "label": "Klaten",
    "value": "Klaten"
  },
  {
    "id": "1801092011",
    "district_id": "180109",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1801092012",
    "district_id": "180109",
    "label": "Banjar Masin",
    "value": "Banjar Masin"
  },
  {
    "id": "1801092013",
    "district_id": "180109",
    "label": "Way Kalam",
    "value": "Way Kalam"
  },
  {
    "id": "1801092014",
    "district_id": "180109",
    "label": "Gedung Harta",
    "value": "Gedung Harta"
  },
  {
    "id": "1801092015",
    "district_id": "180109",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "1801092016",
    "district_id": "180109",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  {
    "id": "1801092017",
    "district_id": "180109",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1801092018",
    "district_id": "180109",
    "label": "Tetaan",
    "value": "Tetaan"
  },
  {
    "id": "1801092019",
    "district_id": "180109",
    "label": "Suka Baru",
    "value": "Suka Baru"
  },
  {
    "id": "1801092020",
    "district_id": "180109",
    "label": "Pisang",
    "value": "Pisang"
  },
  {
    "id": "1801092021",
    "district_id": "180109",
    "label": "Tanjung Heran",
    "value": "Tanjung Heran"
  },
  {
    "id": "1801092026",
    "district_id": "180109",
    "label": "Gandri",
    "value": "Gandri"
  },
  {
    "id": "1801102001",
    "district_id": "180110",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1801102002",
    "district_id": "180110",
    "label": "Sukabakti",
    "value": "Sukabakti"
  },
  {
    "id": "1801102003",
    "district_id": "180110",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1801102004",
    "district_id": "180110",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1801102005",
    "district_id": "180110",
    "label": "Bangunan",
    "value": "Bangunan"
  },
  {
    "id": "1801102006",
    "district_id": "180110",
    "label": "Rejo Mulyo",
    "value": "Rejo Mulyo"
  },
  {
    "id": "1801102007",
    "district_id": "180110",
    "label": "Palas Aji",
    "value": "Palas Aji"
  },
  {
    "id": "1801102008",
    "district_id": "180110",
    "label": "Palas Pasemah",
    "value": "Palas Pasemah"
  },
  {
    "id": "1801102009",
    "district_id": "180110",
    "label": "Palas Jaya",
    "value": "Palas Jaya"
  },
  {
    "id": "1801102010",
    "district_id": "180110",
    "label": "Bandan Hurip",
    "value": "Bandan Hurip"
  },
  {
    "id": "1801102011",
    "district_id": "180110",
    "label": "Pulau Tengah",
    "value": "Pulau Tengah"
  },
  {
    "id": "1801102012",
    "district_id": "180110",
    "label": "Bali Agung",
    "value": "Bali Agung"
  },
  {
    "id": "1801102013",
    "district_id": "180110",
    "label": "Bumi Daya",
    "value": "Bumi Daya"
  },
  {
    "id": "1801102014",
    "district_id": "180110",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1801102015",
    "district_id": "180110",
    "label": "Bumi Restu",
    "value": "Bumi Restu"
  },
  {
    "id": "1801102016",
    "district_id": "180110",
    "label": "Pematang Baru",
    "value": "Pematang Baru"
  },
  {
    "id": "1801102017",
    "district_id": "180110",
    "label": "Mekar Mulya",
    "value": "Mekar Mulya"
  },
  {
    "id": "1801102018",
    "district_id": "180110",
    "label": "Bumi Asih",
    "value": "Bumi Asih"
  },
  {
    "id": "1801102019",
    "district_id": "180110",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "1801102020",
    "district_id": "180110",
    "label": "Bumi Asri",
    "value": "Bumi Asri"
  },
  {
    "id": "1801102021",
    "district_id": "180110",
    "label": "Pulau Jaya",
    "value": "Pulau Jaya"
  },
  {
    "id": "1801132001",
    "district_id": "180113",
    "label": "Way Hui",
    "value": "Way Hui"
  },
  {
    "id": "1801132002",
    "district_id": "180113",
    "label": "Sidodadi Asri",
    "value": "Sidodadi Asri"
  },
  {
    "id": "1801132003",
    "district_id": "180113",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1801132004",
    "district_id": "180113",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1801132005",
    "district_id": "180113",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "1801132006",
    "district_id": "180113",
    "label": "Gedung Harapan",
    "value": "Gedung Harapan"
  },
  {
    "id": "1801132007",
    "district_id": "180113",
    "label": "Marga Agung",
    "value": "Marga Agung"
  },
  {
    "id": "1801132008",
    "district_id": "180113",
    "label": "Jati Mulyo",
    "value": "Jati Mulyo"
  },
  {
    "id": "1801132009",
    "district_id": "180113",
    "label": "Fajar Baru",
    "value": "Fajar Baru"
  },
  {
    "id": "1801132010",
    "district_id": "180113",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1801132011",
    "district_id": "180113",
    "label": "Marga Kaya",
    "value": "Marga Kaya"
  },
  {
    "id": "1801132012",
    "district_id": "180113",
    "label": "Margo Lestari",
    "value": "Margo Lestari"
  },
  {
    "id": "1801132013",
    "district_id": "180113",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1801132014",
    "district_id": "180113",
    "label": "Sinar Rejeki",
    "value": "Sinar Rejeki"
  },
  {
    "id": "1801132015",
    "district_id": "180113",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1801132016",
    "district_id": "180113",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "1801132017",
    "district_id": "180113",
    "label": "Purwotani",
    "value": "Purwotani"
  },
  {
    "id": "1801132018",
    "district_id": "180113",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1801132019",
    "district_id": "180113",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1801132020",
    "district_id": "180113",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1801132021",
    "district_id": "180113",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "1801142001",
    "district_id": "180114",
    "label": "Bangunrejo",
    "value": "Bangunrejo"
  },
  {
    "id": "1801142002",
    "district_id": "180114",
    "label": "Sripendowo",
    "value": "Sripendowo"
  },
  {
    "id": "1801142003",
    "district_id": "180114",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1801142004",
    "district_id": "180114",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "1801142005",
    "district_id": "180114",
    "label": "Legundi",
    "value": "Legundi"
  },
  {
    "id": "1801142006",
    "district_id": "180114",
    "label": "Tri Dharma Yoga",
    "value": "Tri Dharma Yoga"
  },
  {
    "id": "1801142007",
    "district_id": "180114",
    "label": "Ruguk",
    "value": "Ruguk"
  },
  {
    "id": "1801142008",
    "district_id": "180114",
    "label": "Sumur",
    "value": "Sumur"
  },
  {
    "id": "1801142009",
    "district_id": "180114",
    "label": "Sumber Nadi",
    "value": "Sumber Nadi"
  },
  {
    "id": "1801142010",
    "district_id": "180114",
    "label": "Sidoasih",
    "value": "Sidoasih"
  },
  {
    "id": "1801142011",
    "district_id": "180114",
    "label": "Pematang Pasir",
    "value": "Pematang Pasir"
  },
  {
    "id": "1801142012",
    "district_id": "180114",
    "label": "Berundung",
    "value": "Berundung"
  },
  {
    "id": "1801142013",
    "district_id": "180114",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "1801142014",
    "district_id": "180114",
    "label": "Kemukus",
    "value": "Kemukus"
  },
  {
    "id": "1801142015",
    "district_id": "180114",
    "label": "Lebung Nala",
    "value": "Lebung Nala"
  },
  {
    "id": "1801142016",
    "district_id": "180114",
    "label": "Sidoluhur",
    "value": "Sidoluhur"
  },
  {
    "id": "1801142017",
    "district_id": "180114",
    "label": "Wai Sidomukti",
    "value": "Wai Sidomukti"
  },
  {
    "id": "1801152001",
    "district_id": "180115",
    "label": "Kuala Sekampung",
    "value": "Kuala Sekampung"
  },
  {
    "id": "1801152002",
    "district_id": "180115",
    "label": "Baktirasa",
    "value": "Baktirasa"
  },
  {
    "id": "1801152003",
    "district_id": "180115",
    "label": "Mandala Sari",
    "value": "Mandala Sari"
  },
  {
    "id": "1801152004",
    "district_id": "180115",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "1801152005",
    "district_id": "180115",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1801152006",
    "district_id": "180115",
    "label": "Marga Jasa",
    "value": "Marga Jasa"
  },
  {
    "id": "1801152007",
    "district_id": "180115",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1801152008",
    "district_id": "180115",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1801152009",
    "district_id": "180115",
    "label": "Kedaung",
    "value": "Kedaung"
  },
  {
    "id": "1801152010",
    "district_id": "180115",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "1801162001",
    "district_id": "180116",
    "label": "Banding",
    "value": "Banding"
  },
  {
    "id": "1801162002",
    "district_id": "180116",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1801162003",
    "district_id": "180116",
    "label": "Batu Balak",
    "value": "Batu Balak"
  },
  {
    "id": "1801162004",
    "district_id": "180116",
    "label": "Canti",
    "value": "Canti"
  },
  {
    "id": "1801162005",
    "district_id": "180116",
    "label": "Canggung",
    "value": "Canggung"
  },
  {
    "id": "1801162006",
    "district_id": "180116",
    "label": "Cugung",
    "value": "Cugung"
  },
  {
    "id": "1801162007",
    "district_id": "180116",
    "label": "Hargo Pancuran",
    "value": "Hargo Pancuran"
  },
  {
    "id": "1801162008",
    "district_id": "180116",
    "label": "Kerinjing",
    "value": "Kerinjing"
  },
  {
    "id": "1801162009",
    "district_id": "180116",
    "label": "Kunjir",
    "value": "Kunjir"
  },
  {
    "id": "1801162010",
    "district_id": "180116",
    "label": "Kota Guring",
    "value": "Kota Guring"
  },
  {
    "id": "1801162011",
    "district_id": "180116",
    "label": "Rajabasa",
    "value": "Rajabasa"
  },
  {
    "id": "1801162012",
    "district_id": "180116",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1801162013",
    "district_id": "180116",
    "label": "Tanjung Gading",
    "value": "Tanjung Gading"
  },
  {
    "id": "1801162014",
    "district_id": "180116",
    "label": "Tejang Pulau Sebesi",
    "value": "Tejang Pulau Sebesi"
  },
  {
    "id": "1801162015",
    "district_id": "180116",
    "label": "Way Muli",
    "value": "Way Muli"
  },
  {
    "id": "1801162016",
    "district_id": "180116",
    "label": "Wai Muli Timur",
    "value": "Wai Muli Timur"
  },
  {
    "id": "1801172001",
    "district_id": "180117",
    "label": "Batuliman Indah",
    "value": "Batuliman Indah"
  },
  {
    "id": "1801172002",
    "district_id": "180117",
    "label": "Sinar Palembang",
    "value": "Sinar Palembang"
  },
  {
    "id": "1801172003",
    "district_id": "180117",
    "label": "Rantau Minyak",
    "value": "Rantau Minyak"
  },
  {
    "id": "1801172004",
    "district_id": "180117",
    "label": "Sidosari",
    "value": "Sidosari"
  },
  {
    "id": "1801172005",
    "district_id": "180117",
    "label": "Way Gelam",
    "value": "Way Gelam"
  },
  {
    "id": "1801172006",
    "district_id": "180117",
    "label": "Cinta Mulya",
    "value": "Cinta Mulya"
  },
  {
    "id": "1801172007",
    "district_id": "180117",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "1801172008",
    "district_id": "180117",
    "label": "Beringin Kencana",
    "value": "Beringin Kencana"
  },
  {
    "id": "1801172009",
    "district_id": "180117",
    "label": "Sinar Pasemah",
    "value": "Sinar Pasemah"
  },
  {
    "id": "1801172010",
    "district_id": "180117",
    "label": "Rawa Selapan",
    "value": "Rawa Selapan"
  },
  {
    "id": "1801172011",
    "district_id": "180117",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "1801172012",
    "district_id": "180117",
    "label": "Trimomukti",
    "value": "Trimomukti"
  },
  {
    "id": "1801172013",
    "district_id": "180117",
    "label": "Titiwangi",
    "value": "Titiwangi"
  },
  {
    "id": "1801172014",
    "district_id": "180117",
    "label": "Karya Mulya Sari",
    "value": "Karya Mulya Sari"
  },
  {
    "id": "1801182001",
    "district_id": "180118",
    "label": "Merbau Mataram",
    "value": "Merbau Mataram"
  },
  {
    "id": "1801182002",
    "district_id": "180118",
    "label": "Suban",
    "value": "Suban"
  },
  {
    "id": "1801182003",
    "district_id": "180118",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1801182004",
    "district_id": "180118",
    "label": "Baru Ranji",
    "value": "Baru Ranji"
  },
  {
    "id": "1801182005",
    "district_id": "180118",
    "label": "Karang Raja",
    "value": "Karang Raja"
  },
  {
    "id": "1801182006",
    "district_id": "180118",
    "label": "Talang Jawa",
    "value": "Talang Jawa"
  },
  {
    "id": "1801182007",
    "district_id": "180118",
    "label": "Lebung Sari",
    "value": "Lebung Sari"
  },
  {
    "id": "1801182008",
    "district_id": "180118",
    "label": "Puji Rahayu",
    "value": "Puji Rahayu"
  },
  {
    "id": "1801182009",
    "district_id": "180118",
    "label": "Batu Agung",
    "value": "Batu Agung"
  },
  {
    "id": "1801182010",
    "district_id": "180118",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1801182011",
    "district_id": "180118",
    "label": "Sinar Karya",
    "value": "Sinar Karya"
  },
  {
    "id": "1801182012",
    "district_id": "180118",
    "label": "Triharjo",
    "value": "Triharjo"
  },
  {
    "id": "1801182013",
    "district_id": "180118",
    "label": "Panca Tunggal",
    "value": "Panca Tunggal"
  },
  {
    "id": "1801182014",
    "district_id": "180118",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1801182015",
    "district_id": "180118",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "1801212001",
    "district_id": "180121",
    "label": "Semanak",
    "value": "Semanak"
  },
  {
    "id": "1801212002",
    "district_id": "180121",
    "label": "Toto Harjo",
    "value": "Toto Harjo"
  },
  {
    "id": "1801212003",
    "district_id": "180121",
    "label": "Hatta",
    "value": "Hatta"
  },
  {
    "id": "1801212004",
    "district_id": "180121",
    "label": "Bakauheni",
    "value": "Bakauheni"
  },
  {
    "id": "1801212005",
    "district_id": "180121",
    "label": "Kelawi",
    "value": "Kelawi"
  },
  {
    "id": "1801222001",
    "district_id": "180122",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1801222002",
    "district_id": "180122",
    "label": "Purwodadi Dalam",
    "value": "Purwodadi Dalam"
  },
  {
    "id": "1801222003",
    "district_id": "180122",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "1801222004",
    "district_id": "180122",
    "label": "Wawasan",
    "value": "Wawasan"
  },
  {
    "id": "1801222005",
    "district_id": "180122",
    "label": "Mulyosari",
    "value": "Mulyosari"
  },
  {
    "id": "1801222006",
    "district_id": "180122",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "1801222007",
    "district_id": "180122",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "1801222008",
    "district_id": "180122",
    "label": "Malangsari",
    "value": "Malangsari"
  },
  {
    "id": "1801232001",
    "district_id": "180123",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1801232002",
    "district_id": "180123",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1801232003",
    "district_id": "180123",
    "label": "Talang Way Sulan",
    "value": "Talang Way Sulan"
  },
  {
    "id": "1801232004",
    "district_id": "180123",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "1801232005",
    "district_id": "180123",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1801232006",
    "district_id": "180123",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1801232007",
    "district_id": "180123",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "1801232008",
    "district_id": "180123",
    "label": "Karang Pucung",
    "value": "Karang Pucung"
  },
  {
    "id": "1801242001",
    "district_id": "180124",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1801242002",
    "district_id": "180124",
    "label": "Sidomakmur",
    "value": "Sidomakmur"
  },
  {
    "id": "1801242003",
    "district_id": "180124",
    "label": "Sidoreno",
    "value": "Sidoreno"
  },
  {
    "id": "1801242004",
    "district_id": "180124",
    "label": "Bali Nuraga",
    "value": "Bali Nuraga"
  },
  {
    "id": "1802012001",
    "district_id": "180201",
    "label": "Sri Way Langsep",
    "value": "Sri Way Langsep"
  },
  {
    "id": "1802012002",
    "district_id": "180201",
    "label": "Way Krui",
    "value": "Way Krui"
  },
  {
    "id": "1802012003",
    "district_id": "180201",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "1802012004",
    "district_id": "180201",
    "label": "Balai Rejo",
    "value": "Balai Rejo"
  },
  {
    "id": "1802012005",
    "district_id": "180201",
    "label": "Watu Agung",
    "value": "Watu Agung"
  },
  {
    "id": "1802012006",
    "district_id": "180201",
    "label": "Sri Basuki",
    "value": "Sri Basuki"
  },
  {
    "id": "1802012007",
    "district_id": "180201",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "1802012008",
    "district_id": "180201",
    "label": "Kali Dadi",
    "value": "Kali Dadi"
  },
  {
    "id": "1802012009",
    "district_id": "180201",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1802012010",
    "district_id": "180201",
    "label": "Sri Dadi",
    "value": "Sri Dadi"
  },
  {
    "id": "1802012011",
    "district_id": "180201",
    "label": "Suko Sari",
    "value": "Suko Sari"
  },
  {
    "id": "1802012012",
    "district_id": "180201",
    "label": "Sinar Sari",
    "value": "Sinar Sari"
  },
  {
    "id": "1802012013",
    "district_id": "180201",
    "label": "Ponco Warno",
    "value": "Ponco Warno"
  },
  {
    "id": "1802012014",
    "district_id": "180201",
    "label": "Sri Purnomo",
    "value": "Sri Purnomo"
  },
  {
    "id": "1802012015",
    "district_id": "180201",
    "label": "Sinar Rejo",
    "value": "Sinar Rejo"
  },
  {
    "id": "1802012016",
    "district_id": "180201",
    "label": "Agung Timur",
    "value": "Agung Timur"
  },
  {
    "id": "1802012017",
    "district_id": "180201",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "1802022001",
    "district_id": "180202",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1802022002",
    "district_id": "180202",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1802022003",
    "district_id": "180202",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1802022004",
    "district_id": "180202",
    "label": "Cimarias",
    "value": "Cimarias"
  },
  {
    "id": "1802022005",
    "district_id": "180202",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1802022006",
    "district_id": "180202",
    "label": "Sidoluhur",
    "value": "Sidoluhur"
  },
  {
    "id": "1802022007",
    "district_id": "180202",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1802022008",
    "district_id": "180202",
    "label": "Timbul Rejo",
    "value": "Timbul Rejo"
  },
  {
    "id": "1802022009",
    "district_id": "180202",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "1802022010",
    "district_id": "180202",
    "label": "Sinar Seputih",
    "value": "Sinar Seputih"
  },
  {
    "id": "1802022011",
    "district_id": "180202",
    "label": "Sinar Luas",
    "value": "Sinar Luas"
  },
  {
    "id": "1802022012",
    "district_id": "180202",
    "label": "Tanjung Pandan",
    "value": "Tanjung Pandan"
  },
  {
    "id": "1802022013",
    "district_id": "180202",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1802022014",
    "district_id": "180202",
    "label": "Sri Pendowo",
    "value": "Sri Pendowo"
  },
  {
    "id": "1802022015",
    "district_id": "180202",
    "label": "Sukowaringin",
    "value": "Sukowaringin"
  },
  {
    "id": "1802022016",
    "district_id": "180202",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1802022017",
    "district_id": "180202",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1802032001",
    "district_id": "180203",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1802032002",
    "district_id": "180203",
    "label": "Sendang Ayu",
    "value": "Sendang Ayu"
  },
  {
    "id": "1802032003",
    "district_id": "180203",
    "label": "Bandar Sari",
    "value": "Bandar Sari"
  },
  {
    "id": "1802032004",
    "district_id": "180203",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "1802032005",
    "district_id": "180203",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "1802032006",
    "district_id": "180203",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1802032007",
    "district_id": "180203",
    "label": "Karang Tanjung",
    "value": "Karang Tanjung"
  },
  {
    "id": "1802032008",
    "district_id": "180203",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1802032009",
    "district_id": "180203",
    "label": "Sri Agung",
    "value": "Sri Agung"
  },
  {
    "id": "1802032010",
    "district_id": "180203",
    "label": "Haduyang Ratu",
    "value": "Haduyang Ratu"
  },
  {
    "id": "1802032011",
    "district_id": "180203",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "1802032018",
    "district_id": "180203",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1802032019",
    "district_id": "180203",
    "label": "Mojokerto",
    "value": "Mojokerto"
  },
  {
    "id": "1802032020",
    "district_id": "180203",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1802032021",
    "district_id": "180203",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1802041005",
    "district_id": "180204",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "1802041006",
    "district_id": "180204",
    "label": "Gunung S. Raya",
    "value": "Gunung S. Raya"
  },
  {
    "id": "1802041007",
    "district_id": "180204",
    "label": "Komering Agung",
    "value": "Komering Agung"
  },
  {
    "id": "1802041008",
    "district_id": "180204",
    "label": "Seputih Jaya",
    "value": "Seputih Jaya"
  },
  {
    "id": "1802042001",
    "district_id": "180204",
    "label": "Terbanggi Subing",
    "value": "Terbanggi Subing"
  },
  {
    "id": "1802042002",
    "district_id": "180204",
    "label": "Terbanggi Agung",
    "value": "Terbanggi Agung"
  },
  {
    "id": "1802042003",
    "district_id": "180204",
    "label": "Fajar Bulan",
    "value": "Fajar Bulan"
  },
  {
    "id": "1802042004",
    "district_id": "180204",
    "label": "Komering Putih",
    "value": "Komering Putih"
  },
  {
    "id": "1802042009",
    "district_id": "180204",
    "label": "Buyut Udik",
    "value": "Buyut Udik"
  },
  {
    "id": "1802042010",
    "district_id": "180204",
    "label": "Buyut Ilir",
    "value": "Buyut Ilir"
  },
  {
    "id": "1802042011",
    "district_id": "180204",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1802042012",
    "district_id": "180204",
    "label": "Wono Sari",
    "value": "Wono Sari"
  },
  {
    "id": "1802042013",
    "district_id": "180204",
    "label": "Putra Buyut",
    "value": "Putra Buyut"
  },
  {
    "id": "1802042014",
    "district_id": "180204",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1802042015",
    "district_id": "180204",
    "label": "Buyut Utara",
    "value": "Buyut Utara"
  },
  {
    "id": "1802051003",
    "district_id": "180205",
    "label": "Adi Puro",
    "value": "Adi Puro"
  },
  {
    "id": "1802051004",
    "district_id": "180205",
    "label": "Trimurjo",
    "value": "Trimurjo"
  },
  {
    "id": "1802051007",
    "district_id": "180205",
    "label": "Simbar Waringin",
    "value": "Simbar Waringin"
  },
  {
    "id": "1802052001",
    "district_id": "180205",
    "label": "Depok Rejo",
    "value": "Depok Rejo"
  },
  {
    "id": "1802052002",
    "district_id": "180205",
    "label": "Liman Benawi",
    "value": "Liman Benawi"
  },
  {
    "id": "1802052005",
    "district_id": "180205",
    "label": "Noto Harjo",
    "value": "Noto Harjo"
  },
  {
    "id": "1802052006",
    "district_id": "180205",
    "label": "Purwo Dadi",
    "value": "Purwo Dadi"
  },
  {
    "id": "1802052008",
    "district_id": "180205",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "1802052009",
    "district_id": "180205",
    "label": "Purwo Adi",
    "value": "Purwo Adi"
  },
  {
    "id": "1802052010",
    "district_id": "180205",
    "label": "Pujo Dadi",
    "value": "Pujo Dadi"
  },
  {
    "id": "1802052011",
    "district_id": "180205",
    "label": "Pujo Kerto",
    "value": "Pujo Kerto"
  },
  {
    "id": "1802052012",
    "district_id": "180205",
    "label": "Untoro",
    "value": "Untoro"
  },
  {
    "id": "1802052013",
    "district_id": "180205",
    "label": "Pujo Asri",
    "value": "Pujo Asri"
  },
  {
    "id": "1802052014",
    "district_id": "180205",
    "label": "Pujo Basuki",
    "value": "Pujo Basuki"
  },
  {
    "id": "1802062001",
    "district_id": "180206",
    "label": "Nunggal Rejo",
    "value": "Nunggal Rejo"
  },
  {
    "id": "1802062002",
    "district_id": "180206",
    "label": "Badran Sari",
    "value": "Badran Sari"
  },
  {
    "id": "1802062003",
    "district_id": "180206",
    "label": "Sri Sawahan",
    "value": "Sri Sawahan"
  },
  {
    "id": "1802062004",
    "district_id": "180206",
    "label": "Toto Katon",
    "value": "Toto Katon"
  },
  {
    "id": "1802062005",
    "district_id": "180206",
    "label": "Tanggul Angin",
    "value": "Tanggul Angin"
  },
  {
    "id": "1802062006",
    "district_id": "180206",
    "label": "Ngesti Rahayu",
    "value": "Ngesti Rahayu"
  },
  {
    "id": "1802062007",
    "district_id": "180206",
    "label": "Mojopahit",
    "value": "Mojopahit"
  },
  {
    "id": "1802062008",
    "district_id": "180206",
    "label": "Asto Mulyo",
    "value": "Asto Mulyo"
  },
  {
    "id": "1802062009",
    "district_id": "180206",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1802071002",
    "district_id": "180207",
    "label": "Bandar Jaya Timur",
    "value": "Bandar Jaya Timur"
  },
  {
    "id": "1802071003",
    "district_id": "180207",
    "label": "Bandar Jaya Barat",
    "value": "Bandar Jaya Barat"
  },
  {
    "id": "1802071004",
    "district_id": "180207",
    "label": "Yukum Jaya",
    "value": "Yukum Jaya"
  },
  {
    "id": "1802072001",
    "district_id": "180207",
    "label": "Adi Jaya",
    "value": "Adi Jaya"
  },
  {
    "id": "1802072005",
    "district_id": "180207",
    "label": "Indra Putra Subing",
    "value": "Indra Putra Subing"
  },
  {
    "id": "1802072006",
    "district_id": "180207",
    "label": "Karang Endah",
    "value": "Karang Endah"
  },
  {
    "id": "1802072007",
    "district_id": "180207",
    "label": "Nambah Dadi",
    "value": "Nambah Dadi"
  },
  {
    "id": "1802072008",
    "district_id": "180207",
    "label": "Ono Harjo",
    "value": "Ono Harjo"
  },
  {
    "id": "1802072009",
    "district_id": "180207",
    "label": "Terbanggi Besar",
    "value": "Terbanggi Besar"
  },
  {
    "id": "1802072010",
    "district_id": "180207",
    "label": "Poncowati",
    "value": "Poncowati"
  },
  {
    "id": "1802082001",
    "district_id": "180208",
    "label": "Rejo Basuki",
    "value": "Rejo Basuki"
  },
  {
    "id": "1802082002",
    "district_id": "180208",
    "label": "Rejo Asri",
    "value": "Rejo Asri"
  },
  {
    "id": "1802082003",
    "district_id": "180208",
    "label": "Rukti Endah",
    "value": "Rukti Endah"
  },
  {
    "id": "1802082004",
    "district_id": "180208",
    "label": "Rama Dewa",
    "value": "Rama Dewa"
  },
  {
    "id": "1802082005",
    "district_id": "180208",
    "label": "Ratna Chaton",
    "value": "Ratna Chaton"
  },
  {
    "id": "1802082006",
    "district_id": "180208",
    "label": "Rama Yana",
    "value": "Rama Yana"
  },
  {
    "id": "1802082007",
    "district_id": "180208",
    "label": "Rama Indra",
    "value": "Rama Indra"
  },
  {
    "id": "1802082008",
    "district_id": "180208",
    "label": "Rukti Harjo",
    "value": "Rukti Harjo"
  },
  {
    "id": "1802082009",
    "district_id": "180208",
    "label": "Rama Gunawan",
    "value": "Rama Gunawan"
  },
  {
    "id": "1802082010",
    "district_id": "180208",
    "label": "Rama Oetama",
    "value": "Rama Oetama"
  },
  {
    "id": "1802082011",
    "district_id": "180208",
    "label": "Rama Nirwana",
    "value": "Rama Nirwana"
  },
  {
    "id": "1802082012",
    "district_id": "180208",
    "label": "Rama Murti",
    "value": "Rama Murti"
  },
  {
    "id": "1802082013",
    "district_id": "180208",
    "label": "Rama Klandungan",
    "value": "Rama Klandungan"
  },
  {
    "id": "1802082014",
    "district_id": "180208",
    "label": "Buyut Baru",
    "value": "Buyut Baru"
  },
  {
    "id": "1802092001",
    "district_id": "180209",
    "label": "Teluk Dalem Ilir",
    "value": "Teluk Dalem Ilir"
  },
  {
    "id": "1802092002",
    "district_id": "180209",
    "label": "Rekso Binangun",
    "value": "Rekso Binangun"
  },
  {
    "id": "1802092003",
    "district_id": "180209",
    "label": "Rukti Basuki",
    "value": "Rukti Basuki"
  },
  {
    "id": "1802092004",
    "district_id": "180209",
    "label": "Restu Baru",
    "value": "Restu Baru"
  },
  {
    "id": "1802092005",
    "district_id": "180209",
    "label": "Restu Buana",
    "value": "Restu Buana"
  },
  {
    "id": "1802092006",
    "district_id": "180209",
    "label": "Reno Basuki",
    "value": "Reno Basuki"
  },
  {
    "id": "1802092013",
    "district_id": "180209",
    "label": "Bina Karya Putra",
    "value": "Bina Karya Putra"
  },
  {
    "id": "1802092014",
    "district_id": "180209",
    "label": "Bina Karya Buana",
    "value": "Bina Karya Buana"
  },
  {
    "id": "1802092015",
    "district_id": "180209",
    "label": "Bina Karya Mandiri",
    "value": "Bina Karya Mandiri"
  },
  {
    "id": "1802102001",
    "district_id": "180210",
    "label": "Sumber Bahagia",
    "value": "Sumber Bahagia"
  },
  {
    "id": "1802102002",
    "district_id": "180210",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1802102003",
    "district_id": "180210",
    "label": "Setia Budi",
    "value": "Setia Budi"
  },
  {
    "id": "1802102004",
    "district_id": "180210",
    "label": "Sumber Baru",
    "value": "Sumber Baru"
  },
  {
    "id": "1802102005",
    "district_id": "180210",
    "label": "Siswo Binangun",
    "value": "Siswo Binangun"
  },
  {
    "id": "1802102006",
    "district_id": "180210",
    "label": "Sanggar Buana",
    "value": "Sanggar Buana"
  },
  {
    "id": "1802102007",
    "district_id": "180210",
    "label": "Sakti Buana",
    "value": "Sakti Buana"
  },
  {
    "id": "1802102008",
    "district_id": "180210",
    "label": "Setia Bakti",
    "value": "Setia Bakti"
  },
  {
    "id": "1802102009",
    "district_id": "180210",
    "label": "Sri Basuki",
    "value": "Sri Basuki"
  },
  {
    "id": "1802102010",
    "district_id": "180210",
    "label": "Sri Bhakti",
    "value": "Sri Bhakti"
  },
  {
    "id": "1802102011",
    "district_id": "180210",
    "label": "Swastika Buana",
    "value": "Swastika Buana"
  },
  {
    "id": "1802102012",
    "district_id": "180210",
    "label": "Sumber Fajar",
    "value": "Sumber Fajar"
  },
  {
    "id": "1802102013",
    "district_id": "180210",
    "label": "Tanjung Kerajan",
    "value": "Tanjung Kerajan"
  },
  {
    "id": "1802112001",
    "district_id": "180211",
    "label": "Fajar Mataram",
    "value": "Fajar Mataram"
  },
  {
    "id": "1802112002",
    "district_id": "180211",
    "label": "Rejosari Mataram",
    "value": "Rejosari Mataram"
  },
  {
    "id": "1802112003",
    "district_id": "180211",
    "label": "Qurnia Mataram",
    "value": "Qurnia Mataram"
  },
  {
    "id": "1802112004",
    "district_id": "180211",
    "label": "Trimulyo Mataram",
    "value": "Trimulyo Mataram"
  },
  {
    "id": "1802112005",
    "district_id": "180211",
    "label": "Utama Jaya Mataram",
    "value": "Utama Jaya Mataram"
  },
  {
    "id": "1802112006",
    "district_id": "180211",
    "label": "Sumber Agung Mataram",
    "value": "Sumber Agung Mataram"
  },
  {
    "id": "1802112007",
    "district_id": "180211",
    "label": "Varia Agung Mataram",
    "value": "Varia Agung Mataram"
  },
  {
    "id": "1802112008",
    "district_id": "180211",
    "label": "Wirata Agung Mataram",
    "value": "Wirata Agung Mataram"
  },
  {
    "id": "1802112009",
    "district_id": "180211",
    "label": "Dharma Agung Mataram",
    "value": "Dharma Agung Mataram"
  },
  {
    "id": "1802112010",
    "district_id": "180211",
    "label": "Bumi Setia Mataram",
    "value": "Bumi Setia Mataram"
  },
  {
    "id": "1802112011",
    "district_id": "180211",
    "label": "Subing Karya",
    "value": "Subing Karya"
  },
  {
    "id": "1802112012",
    "district_id": "180211",
    "label": "Banjar Agung Mataram",
    "value": "Banjar Agung Mataram"
  },
  {
    "id": "1802122001",
    "district_id": "180212",
    "label": "Gaya Baru VI",
    "value": "Gaya Baru VI"
  },
  {
    "id": "1802122002",
    "district_id": "180212",
    "label": "Sumber Katon",
    "value": "Sumber Katon"
  },
  {
    "id": "1802122003",
    "district_id": "180212",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "1802122004",
    "district_id": "180212",
    "label": "Gaya Baru VII",
    "value": "Gaya Baru VII"
  },
  {
    "id": "1802122005",
    "district_id": "180212",
    "label": "Gaya Baru IV",
    "value": "Gaya Baru IV"
  },
  {
    "id": "1802122006",
    "district_id": "180212",
    "label": "Gaya Baru III",
    "value": "Gaya Baru III"
  },
  {
    "id": "1802122007",
    "district_id": "180212",
    "label": "Gaya Baru II",
    "value": "Gaya Baru II"
  },
  {
    "id": "1802122008",
    "district_id": "180212",
    "label": "Gaya Baru VIII",
    "value": "Gaya Baru VIII"
  },
  {
    "id": "1802122009",
    "district_id": "180212",
    "label": "Gaya Baru I",
    "value": "Gaya Baru I"
  },
  {
    "id": "1802122010",
    "district_id": "180212",
    "label": "Mataram Ilir",
    "value": "Mataram Ilir"
  },
  {
    "id": "1802122011",
    "district_id": "180212",
    "label": "Rawa Betik",
    "value": "Rawa Betik"
  },
  {
    "id": "1802122012",
    "district_id": "180212",
    "label": "Srimulya Jaya",
    "value": "Srimulya Jaya"
  },
  {
    "id": "1802122013",
    "district_id": "180212",
    "label": "Kenanga Sari",
    "value": "Kenanga Sari"
  },
  {
    "id": "1802132001",
    "district_id": "180213",
    "label": "Gunung Batin Udik",
    "value": "Gunung Batin Udik"
  },
  {
    "id": "1802132002",
    "district_id": "180213",
    "label": "Gunung Batin Ilir",
    "value": "Gunung Batin Ilir"
  },
  {
    "id": "1802132003",
    "district_id": "180213",
    "label": "Gunung Batin Baru",
    "value": "Gunung Batin Baru"
  },
  {
    "id": "1802132004",
    "district_id": "180213",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1802132005",
    "district_id": "180213",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1802132006",
    "district_id": "180213",
    "label": "Bandar Sakti",
    "value": "Bandar Sakti"
  },
  {
    "id": "1802132007",
    "district_id": "180213",
    "label": "Tanjung Anom",
    "value": "Tanjung Anom"
  },
  {
    "id": "1802142001",
    "district_id": "180214",
    "label": "Suka Jawa",
    "value": "Suka Jawa"
  },
  {
    "id": "1802142002",
    "district_id": "180214",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "1802142003",
    "district_id": "180214",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1802142004",
    "district_id": "180214",
    "label": "Bumi Raharjo",
    "value": "Bumi Raharjo"
  },
  {
    "id": "1802142005",
    "district_id": "180214",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "1802142006",
    "district_id": "180214",
    "label": "Bumi Ratu",
    "value": "Bumi Ratu"
  },
  {
    "id": "1802142007",
    "district_id": "180214",
    "label": "Bulu Sari",
    "value": "Bulu Sari"
  },
  {
    "id": "1802142008",
    "district_id": "180214",
    "label": "Bumi Rahayu",
    "value": "Bumi Rahayu"
  },
  {
    "id": "1802142009",
    "district_id": "180214",
    "label": "Sido Waras",
    "value": "Sido Waras"
  },
  {
    "id": "1802142010",
    "district_id": "180214",
    "label": "Tulang Kakan",
    "value": "Tulang Kakan"
  },
  {
    "id": "1802152001",
    "district_id": "180215",
    "label": "Rengas",
    "value": "Rengas"
  },
  {
    "id": "1802152002",
    "district_id": "180215",
    "label": "Kesuma Dadi",
    "value": "Kesuma Dadi"
  },
  {
    "id": "1802152003",
    "district_id": "180215",
    "label": "Sinar Banten",
    "value": "Sinar Banten"
  },
  {
    "id": "1802152004",
    "district_id": "180215",
    "label": "Kesuma Jaya",
    "value": "Kesuma Jaya"
  },
  {
    "id": "1802152005",
    "district_id": "180215",
    "label": "Binjai Ngagung",
    "value": "Binjai Ngagung"
  },
  {
    "id": "1802152006",
    "district_id": "180215",
    "label": "Kedatuan",
    "value": "Kedatuan"
  },
  {
    "id": "1802152007",
    "district_id": "180215",
    "label": "Goras Jaya",
    "value": "Goras Jaya"
  },
  {
    "id": "1802152008",
    "district_id": "180215",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1802162001",
    "district_id": "180216",
    "label": "Harapan Rejo",
    "value": "Harapan Rejo"
  },
  {
    "id": "1802162002",
    "district_id": "180216",
    "label": "Endang Rejo",
    "value": "Endang Rejo"
  },
  {
    "id": "1802162003",
    "district_id": "180216",
    "label": "Dono Arum",
    "value": "Dono Arum"
  },
  {
    "id": "1802162004",
    "district_id": "180216",
    "label": "Simpang Agung",
    "value": "Simpang Agung"
  },
  {
    "id": "1802162005",
    "district_id": "180216",
    "label": "Bumi Kencana",
    "value": "Bumi Kencana"
  },
  {
    "id": "1802162006",
    "district_id": "180216",
    "label": "Gayau Sakti",
    "value": "Gayau Sakti"
  },
  {
    "id": "1802162007",
    "district_id": "180216",
    "label": "Fajar Asri",
    "value": "Fajar Asri"
  },
  {
    "id": "1802162008",
    "district_id": "180216",
    "label": "Muji Rahayu",
    "value": "Muji Rahayu"
  },
  {
    "id": "1802162009",
    "district_id": "180216",
    "label": "Sulusuban",
    "value": "Sulusuban"
  },
  {
    "id": "1802162010",
    "district_id": "180216",
    "label": "Bumi Mas",
    "value": "Bumi Mas"
  },
  {
    "id": "1802172001",
    "district_id": "180217",
    "label": "Tanjung Ratu Ilir",
    "value": "Tanjung Ratu Ilir"
  },
  {
    "id": "1802172002",
    "district_id": "180217",
    "label": "Candi Rejo",
    "value": "Candi Rejo"
  },
  {
    "id": "1802172003",
    "district_id": "180217",
    "label": "Banjar Ratu",
    "value": "Banjar Ratu"
  },
  {
    "id": "1802172004",
    "district_id": "180217",
    "label": "Banjar Kertahayu",
    "value": "Banjar Kertahayu"
  },
  {
    "id": "1802172005",
    "district_id": "180217",
    "label": "Lempuyang Bandar",
    "value": "Lempuyang Bandar"
  },
  {
    "id": "1802172006",
    "district_id": "180217",
    "label": "Purnama Tunggal",
    "value": "Purnama Tunggal"
  },
  {
    "id": "1802172007",
    "district_id": "180217",
    "label": "Banjar Rejo",
    "value": "Banjar Rejo"
  },
  {
    "id": "1802172008",
    "district_id": "180217",
    "label": "Putra Lempuyang",
    "value": "Putra Lempuyang"
  },
  {
    "id": "1802182001",
    "district_id": "180218",
    "label": "Sriwijaya Mataram",
    "value": "Sriwijaya Mataram"
  },
  {
    "id": "1802182002",
    "district_id": "180218",
    "label": "Uman Agung Mataram",
    "value": "Uman Agung Mataram"
  },
  {
    "id": "1802182003",
    "district_id": "180218",
    "label": "Terbanggi Ilir",
    "value": "Terbanggi Ilir"
  },
  {
    "id": "1802182004",
    "district_id": "180218",
    "label": "Jatidatar Mataram",
    "value": "Jatidatar Mataram"
  },
  {
    "id": "1802182005",
    "district_id": "180218",
    "label": "Sendang Agung Mataram",
    "value": "Sendang Agung Mataram"
  },
  {
    "id": "1802182006",
    "district_id": "180218",
    "label": "Mataram Udik",
    "value": "Mataram Udik"
  },
  {
    "id": "1802182007",
    "district_id": "180218",
    "label": "Terbanggi Mulya",
    "value": "Terbanggi Mulya"
  },
  {
    "id": "1802182008",
    "district_id": "180218",
    "label": "Mataram Jaya",
    "value": "Mataram Jaya"
  },
  {
    "id": "1802182009",
    "district_id": "180218",
    "label": "Sumber Rejeki Mataram",
    "value": "Sumber Rejeki Mataram"
  },
  {
    "id": "1802192001",
    "district_id": "180219",
    "label": "Kota Batu",
    "value": "Kota Batu"
  },
  {
    "id": "1802192002",
    "district_id": "180219",
    "label": "Sangun Ratu",
    "value": "Sangun Ratu"
  },
  {
    "id": "1802192003",
    "district_id": "180219",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "1802192004",
    "district_id": "180219",
    "label": "Gunung Haji",
    "value": "Gunung Haji"
  },
  {
    "id": "1802192005",
    "district_id": "180219",
    "label": "Negri Ratu",
    "value": "Negri Ratu"
  },
  {
    "id": "1802192006",
    "district_id": "180219",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1802192007",
    "district_id": "180219",
    "label": "Payung Rejo",
    "value": "Payung Rejo"
  },
  {
    "id": "1802192008",
    "district_id": "180219",
    "label": "Payung Batu",
    "value": "Payung Batu"
  },
  {
    "id": "1802192009",
    "district_id": "180219",
    "label": "Negeri Kepayungan",
    "value": "Negeri Kepayungan"
  },
  {
    "id": "1802192010",
    "district_id": "180219",
    "label": "Segala Mider",
    "value": "Segala Mider"
  },
  {
    "id": "1802192011",
    "district_id": "180219",
    "label": "Tias Bangun",
    "value": "Tias Bangun"
  },
  {
    "id": "1802192012",
    "district_id": "180219",
    "label": "Riau Priangan",
    "value": "Riau Priangan"
  },
  {
    "id": "1802192013",
    "district_id": "180219",
    "label": "Payung Dadi",
    "value": "Payung Dadi"
  },
  {
    "id": "1802192014",
    "district_id": "180219",
    "label": "Payung Makmur",
    "value": "Payung Makmur"
  },
  {
    "id": "1802192015",
    "district_id": "180219",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1802192016",
    "district_id": "180219",
    "label": "Tawang Negeri",
    "value": "Tawang Negeri"
  },
  {
    "id": "1802192017",
    "district_id": "180219",
    "label": "Sinar Negeri",
    "value": "Sinar Negeri"
  },
  {
    "id": "1802192018",
    "district_id": "180219",
    "label": "Pekandangan",
    "value": "Pekandangan"
  },
  {
    "id": "1802192019",
    "district_id": "180219",
    "label": "Payung Mulya",
    "value": "Payung Mulya"
  },
  {
    "id": "1802192020",
    "district_id": "180219",
    "label": "Padang Rejo",
    "value": "Padang Rejo"
  },
  {
    "id": "1802202001",
    "district_id": "180220",
    "label": "Nyukang Harjo",
    "value": "Nyukang Harjo"
  },
  {
    "id": "1802202002",
    "district_id": "180220",
    "label": "Lingga Pura",
    "value": "Lingga Pura"
  },
  {
    "id": "1802202003",
    "district_id": "180220",
    "label": "Sido Harjo",
    "value": "Sido Harjo"
  },
  {
    "id": "1802202004",
    "district_id": "180220",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "1802202005",
    "district_id": "180220",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1802202006",
    "district_id": "180220",
    "label": "Negeri Katon",
    "value": "Negeri Katon"
  },
  {
    "id": "1802202007",
    "district_id": "180220",
    "label": "Gedung Harta",
    "value": "Gedung Harta"
  },
  {
    "id": "1802202008",
    "district_id": "180220",
    "label": "Tanjung Ratu",
    "value": "Tanjung Ratu"
  },
  {
    "id": "1802202009",
    "district_id": "180220",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1802202010",
    "district_id": "180220",
    "label": "Gilih Karang Jati",
    "value": "Gilih Karang Jati"
  },
  {
    "id": "1802202011",
    "district_id": "180220",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1802202012",
    "district_id": "180220",
    "label": "Gedung Aji",
    "value": "Gedung Aji"
  },
  {
    "id": "1802202013",
    "district_id": "180220",
    "label": "Negeri Jaya",
    "value": "Negeri Jaya"
  },
  {
    "id": "1802202014",
    "district_id": "180220",
    "label": "Mekar Harjo",
    "value": "Mekar Harjo"
  },
  {
    "id": "1802212001",
    "district_id": "180221",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "1802212002",
    "district_id": "180221",
    "label": "Jaya Sakti",
    "value": "Jaya Sakti"
  },
  {
    "id": "1802212003",
    "district_id": "180221",
    "label": "Haji Pemanggilan",
    "value": "Haji Pemanggilan"
  },
  {
    "id": "1802212004",
    "district_id": "180221",
    "label": "Negara Bumi Ilir",
    "value": "Negara Bumi Ilir"
  },
  {
    "id": "1802212005",
    "district_id": "180221",
    "label": "Bumi Aji",
    "value": "Bumi Aji"
  },
  {
    "id": "1802212006",
    "district_id": "180221",
    "label": "Negara Aji Tua",
    "value": "Negara Aji Tua"
  },
  {
    "id": "1802212007",
    "district_id": "180221",
    "label": "Negara Bumi Udik",
    "value": "Negara Bumi Udik"
  },
  {
    "id": "1802212008",
    "district_id": "180221",
    "label": "Negara Aji Baru",
    "value": "Negara Aji Baru"
  },
  {
    "id": "1802212009",
    "district_id": "180221",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1802212010",
    "district_id": "180221",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1802212011",
    "district_id": "180221",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "1802212012",
    "district_id": "180221",
    "label": "Mulyo Haji",
    "value": "Mulyo Haji"
  },
  {
    "id": "1802222001",
    "district_id": "180222",
    "label": "Sendang Mulyo",
    "value": "Sendang Mulyo"
  },
  {
    "id": "1802222002",
    "district_id": "180222",
    "label": "Sendang Rejo",
    "value": "Sendang Rejo"
  },
  {
    "id": "1802222003",
    "district_id": "180222",
    "label": "Sendang Baru",
    "value": "Sendang Baru"
  },
  {
    "id": "1802222004",
    "district_id": "180222",
    "label": "Sendang Retno",
    "value": "Sendang Retno"
  },
  {
    "id": "1802222005",
    "district_id": "180222",
    "label": "Sendang Asih",
    "value": "Sendang Asih"
  },
  {
    "id": "1802222006",
    "district_id": "180222",
    "label": "Sendang Agung",
    "value": "Sendang Agung"
  },
  {
    "id": "1802222007",
    "district_id": "180222",
    "label": "Sendang Asri",
    "value": "Sendang Asri"
  },
  {
    "id": "1802222008",
    "district_id": "180222",
    "label": "Sendang Mukti",
    "value": "Sendang Mukti"
  },
  {
    "id": "1802222009",
    "district_id": "180222",
    "label": "Kutowinangun",
    "value": "Kutowinangun"
  },
  {
    "id": "1802232001",
    "district_id": "180223",
    "label": "Sritejo Kencono",
    "value": "Sritejo Kencono"
  },
  {
    "id": "1802232002",
    "district_id": "180223",
    "label": "Sapto Mulyo",
    "value": "Sapto Mulyo"
  },
  {
    "id": "1802232003",
    "district_id": "180223",
    "label": "Nambah Rejo",
    "value": "Nambah Rejo"
  },
  {
    "id": "1802232004",
    "district_id": "180223",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1802232005",
    "district_id": "180223",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1802232006",
    "district_id": "180223",
    "label": "Kota Gajah",
    "value": "Kota Gajah"
  },
  {
    "id": "1802232007",
    "district_id": "180223",
    "label": "Kota Gajah Timur",
    "value": "Kota Gajah Timur"
  },
  {
    "id": "1802242001",
    "district_id": "180224",
    "label": "Sri Kencono",
    "value": "Sri Kencono"
  },
  {
    "id": "1802242002",
    "district_id": "180224",
    "label": "Bumi Nabung Ilir",
    "value": "Bumi Nabung Ilir"
  },
  {
    "id": "1802242003",
    "district_id": "180224",
    "label": "Bumi Nabung Utara",
    "value": "Bumi Nabung Utara"
  },
  {
    "id": "1802242004",
    "district_id": "180224",
    "label": "Bumi Nabung Selatan",
    "value": "Bumi Nabung Selatan"
  },
  {
    "id": "1802242005",
    "district_id": "180224",
    "label": "Bumi Nabung Timur",
    "value": "Bumi Nabung Timur"
  },
  {
    "id": "1802242006",
    "district_id": "180224",
    "label": "Bumi Nabung Baru",
    "value": "Bumi Nabung Baru"
  },
  {
    "id": "1802242007",
    "district_id": "180224",
    "label": "Sri Kenanga",
    "value": "Sri Kenanga"
  },
  {
    "id": "1802252001",
    "district_id": "180225",
    "label": "Sri Binangun",
    "value": "Sri Binangun"
  },
  {
    "id": "1802252002",
    "district_id": "180225",
    "label": "Sri Bawono",
    "value": "Sri Bawono"
  },
  {
    "id": "1802252003",
    "district_id": "180225",
    "label": "Sri Budaya",
    "value": "Sri Budaya"
  },
  {
    "id": "1802252004",
    "district_id": "180225",
    "label": "Suko Binangun",
    "value": "Suko Binangun"
  },
  {
    "id": "1802252005",
    "district_id": "180225",
    "label": "Sri Busono",
    "value": "Sri Busono"
  },
  {
    "id": "1802252006",
    "district_id": "180225",
    "label": "Sangga Buana",
    "value": "Sangga Buana"
  },
  {
    "id": "1802262001",
    "district_id": "180226",
    "label": "Gaya Baru V",
    "value": "Gaya Baru V"
  },
  {
    "id": "1802262002",
    "district_id": "180226",
    "label": "Surabaya Ilir",
    "value": "Surabaya Ilir"
  },
  {
    "id": "1802262003",
    "district_id": "180226",
    "label": "Rajawali",
    "value": "Rajawali"
  },
  {
    "id": "1802262004",
    "district_id": "180226",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "1802262005",
    "district_id": "180226",
    "label": "Subang Jaya",
    "value": "Subang Jaya"
  },
  {
    "id": "1802262006",
    "district_id": "180226",
    "label": "Cabang",
    "value": "Cabang"
  },
  {
    "id": "1802262007",
    "district_id": "180226",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1802262008",
    "district_id": "180226",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1802262009",
    "district_id": "180226",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1802262010",
    "district_id": "180226",
    "label": "Surabaya Baru",
    "value": "Surabaya Baru"
  },
  {
    "id": "1802272001",
    "district_id": "180227",
    "label": "Gedung Sari",
    "value": "Gedung Sari"
  },
  {
    "id": "1802272002",
    "district_id": "180227",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "1802272003",
    "district_id": "180227",
    "label": "Gedung Ratu",
    "value": "Gedung Ratu"
  },
  {
    "id": "1802272004",
    "district_id": "180227",
    "label": "Bandar Putih Tua",
    "value": "Bandar Putih Tua"
  },
  {
    "id": "1802272005",
    "district_id": "180227",
    "label": "Karang Jawa",
    "value": "Karang Jawa"
  },
  {
    "id": "1802272006",
    "district_id": "180227",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1802282001",
    "district_id": "180228",
    "label": "Bina Karya Jaya",
    "value": "Bina Karya Jaya"
  },
  {
    "id": "1802282002",
    "district_id": "180228",
    "label": "Bina Karya Utama",
    "value": "Bina Karya Utama"
  },
  {
    "id": "1802282003",
    "district_id": "180228",
    "label": "Bina Karya Sakti",
    "value": "Bina Karya Sakti"
  },
  {
    "id": "1802282004",
    "district_id": "180228",
    "label": "Joharan",
    "value": "Joharan"
  },
  {
    "id": "1802282005",
    "district_id": "180228",
    "label": "Rantau Jaya Baru",
    "value": "Rantau Jaya Baru"
  },
  {
    "id": "1802282006",
    "district_id": "180228",
    "label": "Rantau Jaya Ilir",
    "value": "Rantau Jaya Ilir"
  },
  {
    "id": "1802282007",
    "district_id": "180228",
    "label": "Bina Karya Baru",
    "value": "Bina Karya Baru"
  },
  {
    "id": "1802282008",
    "district_id": "180228",
    "label": "Mranggi Jaya",
    "value": "Mranggi Jaya"
  },
  {
    "id": "1802282009",
    "district_id": "180228",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1802282010",
    "district_id": "180228",
    "label": "Rantau Jaya makmur",
    "value": "Rantau Jaya makmur"
  },
  {
    "id": "1803011001",
    "district_id": "180301",
    "label": "Bukit Kemuning",
    "value": "Bukit Kemuning"
  },
  {
    "id": "1803012002",
    "district_id": "180301",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1803012003",
    "district_id": "180301",
    "label": "Suka Menanti",
    "value": "Suka Menanti"
  },
  {
    "id": "1803012004",
    "district_id": "180301",
    "label": "Muara Aman",
    "value": "Muara Aman"
  },
  {
    "id": "1803012005",
    "district_id": "180301",
    "label": "Tanjung Baru Timur",
    "value": "Tanjung Baru Timur"
  },
  {
    "id": "1803012006",
    "district_id": "180301",
    "label": "Dwikora",
    "value": "Dwikora"
  },
  {
    "id": "1803012007",
    "district_id": "180301",
    "label": "Tanjung Waras",
    "value": "Tanjung Waras"
  },
  {
    "id": "1803012008",
    "district_id": "180301",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1803021001",
    "district_id": "180302",
    "label": "Kotabumi Udik",
    "value": "Kotabumi Udik"
  },
  {
    "id": "1803021002",
    "district_id": "180302",
    "label": "Cempedak",
    "value": "Cempedak"
  },
  {
    "id": "1803021003",
    "district_id": "180302",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1803021004",
    "district_id": "180302",
    "label": "Kotabumi Tengah",
    "value": "Kotabumi Tengah"
  },
  {
    "id": "1803021005",
    "district_id": "180302",
    "label": "Kotabumi Pasar",
    "value": "Kotabumi Pasar"
  },
  {
    "id": "1803021006",
    "district_id": "180302",
    "label": "Kotabumi Ilir",
    "value": "Kotabumi Ilir"
  },
  {
    "id": "1803021007",
    "district_id": "180302",
    "label": "Sindang Sari",
    "value": "Sindang Sari"
  },
  {
    "id": "1803021008",
    "district_id": "180302",
    "label": "Sri Basuki",
    "value": "Sri Basuki"
  },
  {
    "id": "1803021009",
    "district_id": "180302",
    "label": "Kota Gapura",
    "value": "Kota Gapura"
  },
  {
    "id": "1803022010",
    "district_id": "180302",
    "label": "Talang Bojong",
    "value": "Talang Bojong"
  },
  {
    "id": "1803022011",
    "district_id": "180302",
    "label": "Sumber Arum",
    "value": "Sumber Arum"
  },
  {
    "id": "1803022012",
    "district_id": "180302",
    "label": "Bojong Barat",
    "value": "Bojong Barat"
  },
  {
    "id": "1803022013",
    "district_id": "180302",
    "label": "Kotabumi Tengah Barat",
    "value": "Kotabumi Tengah Barat"
  },
  {
    "id": "1803032002",
    "district_id": "180303",
    "label": "Bumi Ratu",
    "value": "Bumi Ratu"
  },
  {
    "id": "1803032003",
    "district_id": "180303",
    "label": "Labuhan Ratu Pasar",
    "value": "Labuhan Ratu Pasar"
  },
  {
    "id": "1803032004",
    "district_id": "180303",
    "label": "Sinar Galih",
    "value": "Sinar Galih"
  },
  {
    "id": "1803032005",
    "district_id": "180303",
    "label": "Labuhan Ratu Kampung",
    "value": "Labuhan Ratu Kampung"
  },
  {
    "id": "1803032006",
    "district_id": "180303",
    "label": "Gedung Ketapang",
    "value": "Gedung Ketapang"
  },
  {
    "id": "1803032007",
    "district_id": "180303",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1803032008",
    "district_id": "180303",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "1803032009",
    "district_id": "180303",
    "label": "Karang Rejo I",
    "value": "Karang Rejo I"
  },
  {
    "id": "1803032010",
    "district_id": "180303",
    "label": "Banjar Ketapang",
    "value": "Banjar Ketapang"
  },
  {
    "id": "1803032017",
    "district_id": "180303",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1803032023",
    "district_id": "180303",
    "label": "Gunung Labuhan",
    "value": "Gunung Labuhan"
  },
  {
    "id": "1803042001",
    "district_id": "180304",
    "label": "Karang Waringin",
    "value": "Karang Waringin"
  },
  {
    "id": "1803042002",
    "district_id": "180304",
    "label": "Suka Sari",
    "value": "Suka Sari"
  },
  {
    "id": "1803042003",
    "district_id": "180304",
    "label": "Merambung",
    "value": "Merambung"
  },
  {
    "id": "1803042004",
    "district_id": "180304",
    "label": "Sinar Jaya",
    "value": "Sinar Jaya"
  },
  {
    "id": "1803042005",
    "district_id": "180304",
    "label": "Sindang Agung",
    "value": "Sindang Agung"
  },
  {
    "id": "1803042006",
    "district_id": "180304",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1803042007",
    "district_id": "180304",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1803042008",
    "district_id": "180304",
    "label": "Tulung Balak",
    "value": "Tulung Balak"
  },
  {
    "id": "1803042009",
    "district_id": "180304",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1803042010",
    "district_id": "180304",
    "label": "Tanjung Riang",
    "value": "Tanjung Riang"
  },
  {
    "id": "1803042011",
    "district_id": "180304",
    "label": "Kemala Raja",
    "value": "Kemala Raja"
  },
  {
    "id": "1803042012",
    "district_id": "180304",
    "label": "Sindang Marga",
    "value": "Sindang Marga"
  },
  {
    "id": "1803042013",
    "district_id": "180304",
    "label": "Ulak Ata",
    "value": "Ulak Ata"
  },
  {
    "id": "1803042014",
    "district_id": "180304",
    "label": "Gunung Katon",
    "value": "Gunung Katon"
  },
  {
    "id": "1803042015",
    "district_id": "180304",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1803042016",
    "district_id": "180304",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "1803042017",
    "district_id": "180304",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1803042018",
    "district_id": "180304",
    "label": "Sinar Mulya",
    "value": "Sinar Mulya"
  },
  {
    "id": "1803042019",
    "district_id": "180304",
    "label": "Priangan Baru",
    "value": "Priangan Baru"
  },
  {
    "id": "1803052001",
    "district_id": "180305",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1803052002",
    "district_id": "180305",
    "label": "Bumi Agung Marga",
    "value": "Bumi Agung Marga"
  },
  {
    "id": "1803052003",
    "district_id": "180305",
    "label": "Pungguk Lama",
    "value": "Pungguk Lama"
  },
  {
    "id": "1803052004",
    "district_id": "180305",
    "label": "Gedung Nyapah",
    "value": "Gedung Nyapah"
  },
  {
    "id": "1803052005",
    "district_id": "180305",
    "label": "Penagan Ratu",
    "value": "Penagan Ratu"
  },
  {
    "id": "1803052006",
    "district_id": "180305",
    "label": "Peraduan Waras",
    "value": "Peraduan Waras"
  },
  {
    "id": "1803052007",
    "district_id": "180305",
    "label": "Surakarta",
    "value": "Surakarta"
  },
  {
    "id": "1803052008",
    "district_id": "180305",
    "label": "Rejo Mulyo",
    "value": "Rejo Mulyo"
  },
  {
    "id": "1803052009",
    "district_id": "180305",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "1803052010",
    "district_id": "180305",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "1803052011",
    "district_id": "180305",
    "label": "Papan Rejo",
    "value": "Papan Rejo"
  },
  {
    "id": "1803052012",
    "district_id": "180305",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1803062005",
    "district_id": "180306",
    "label": "Tanjung Harta",
    "value": "Tanjung Harta"
  },
  {
    "id": "1803062006",
    "district_id": "180306",
    "label": "Ogan Lima",
    "value": "Ogan Lima"
  },
  {
    "id": "1803062007",
    "district_id": "180306",
    "label": "Hujan Mas",
    "value": "Hujan Mas"
  },
  {
    "id": "1803062008",
    "district_id": "180306",
    "label": "Simpang Agung",
    "value": "Simpang Agung"
  },
  {
    "id": "1803062009",
    "district_id": "180306",
    "label": "Bumi Nabung",
    "value": "Bumi Nabung"
  },
  {
    "id": "1803062010",
    "district_id": "180306",
    "label": "Kamplas",
    "value": "Kamplas"
  },
  {
    "id": "1803062011",
    "district_id": "180306",
    "label": "Kistang",
    "value": "Kistang"
  },
  {
    "id": "1803062012",
    "district_id": "180306",
    "label": "Cahaya Negeri",
    "value": "Cahaya Negeri"
  },
  {
    "id": "1803062013",
    "district_id": "180306",
    "label": "Lepang Besar",
    "value": "Lepang Besar"
  },
  {
    "id": "1803062016",
    "district_id": "180306",
    "label": "Pengaringan",
    "value": "Pengaringan"
  },
  {
    "id": "1803062017",
    "district_id": "180306",
    "label": "Gunung Betuah",
    "value": "Gunung Betuah"
  },
  {
    "id": "1803062021",
    "district_id": "180306",
    "label": "Way Wakak",
    "value": "Way Wakak"
  },
  {
    "id": "1803062023",
    "district_id": "180306",
    "label": "Pematang Kasih",
    "value": "Pematang Kasih"
  },
  {
    "id": "1803062024",
    "district_id": "180306",
    "label": "Bumi Mandiri",
    "value": "Bumi Mandiri"
  },
  {
    "id": "1803072004",
    "district_id": "180307",
    "label": "Trimodadi",
    "value": "Trimodadi"
  },
  {
    "id": "1803072005",
    "district_id": "180307",
    "label": "Cabang Empat",
    "value": "Cabang Empat"
  },
  {
    "id": "1803072006",
    "district_id": "180307",
    "label": "Sinar Ogan",
    "value": "Sinar Ogan"
  },
  {
    "id": "1803072007",
    "district_id": "180307",
    "label": "Kalibalangan",
    "value": "Kalibalangan"
  },
  {
    "id": "1803072009",
    "district_id": "180307",
    "label": "Gilih Sukanegeri",
    "value": "Gilih Sukanegeri"
  },
  {
    "id": "1803072010",
    "district_id": "180307",
    "label": "Candimas",
    "value": "Candimas"
  },
  {
    "id": "1803072011",
    "district_id": "180307",
    "label": "Kembang Tanjung",
    "value": "Kembang Tanjung"
  },
  {
    "id": "1803072012",
    "district_id": "180307",
    "label": "Abung Jayo",
    "value": "Abung Jayo"
  },
  {
    "id": "1803072013",
    "district_id": "180307",
    "label": "Kembang Gading",
    "value": "Kembang Gading"
  },
  {
    "id": "1803072014",
    "district_id": "180307",
    "label": "Ratu Abung",
    "value": "Ratu Abung"
  },
  {
    "id": "1803072015",
    "district_id": "180307",
    "label": "Bandar Kagungan Raya",
    "value": "Bandar Kagungan Raya"
  },
  {
    "id": "1803072016",
    "district_id": "180307",
    "label": "Bumi Raya",
    "value": "Bumi Raya"
  },
  {
    "id": "1803072017",
    "district_id": "180307",
    "label": "Kemalo Abung",
    "value": "Kemalo Abung"
  },
  {
    "id": "1803072018",
    "district_id": "180307",
    "label": "Cabang Abung Raya",
    "value": "Cabang Abung Raya"
  },
  {
    "id": "1803072019",
    "district_id": "180307",
    "label": "Kalibening Raya",
    "value": "Kalibening Raya"
  },
  {
    "id": "1803072020",
    "district_id": "180307",
    "label": "Way Lunik",
    "value": "Way Lunik"
  },
  {
    "id": "1803082005",
    "district_id": "180308",
    "label": "Ogan Jaya",
    "value": "Ogan Jaya"
  },
  {
    "id": "1803082009",
    "district_id": "180308",
    "label": "Ciamis",
    "value": "Ciamis"
  },
  {
    "id": "1803082010",
    "district_id": "180308",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "1803082011",
    "district_id": "180308",
    "label": "Negeri Sakti",
    "value": "Negeri Sakti"
  },
  {
    "id": "1803082012",
    "district_id": "180308",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1803082019",
    "district_id": "180308",
    "label": "Negara Ratu",
    "value": "Negara Ratu"
  },
  {
    "id": "1803082020",
    "district_id": "180308",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "1803082021",
    "district_id": "180308",
    "label": "Gedung Batin",
    "value": "Gedung Batin"
  },
  {
    "id": "1803082022",
    "district_id": "180308",
    "label": "Negara Batin",
    "value": "Negara Batin"
  },
  {
    "id": "1803082023",
    "district_id": "180308",
    "label": "Kota Negara",
    "value": "Kota Negara"
  },
  {
    "id": "1803082024",
    "district_id": "180308",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1803082028",
    "district_id": "180308",
    "label": "Baru Raharja",
    "value": "Baru Raharja"
  },
  {
    "id": "1803082029",
    "district_id": "180308",
    "label": "Hanakau Jaya",
    "value": "Hanakau Jaya"
  },
  {
    "id": "1803082032",
    "district_id": "180308",
    "label": "Negara Batin II",
    "value": "Negara Batin II"
  },
  {
    "id": "1803082033",
    "district_id": "180308",
    "label": "Kota Negara Ilir",
    "value": "Kota Negara Ilir"
  },
  {
    "id": "1803092001",
    "district_id": "180309",
    "label": "Madukoro",
    "value": "Madukoro"
  },
  {
    "id": "1803092002",
    "district_id": "180309",
    "label": "Kali Cinta",
    "value": "Kali Cinta"
  },
  {
    "id": "1803092003",
    "district_id": "180309",
    "label": "Wono Marto",
    "value": "Wono Marto"
  },
  {
    "id": "1803092004",
    "district_id": "180309",
    "label": "Talang Jali",
    "value": "Talang Jali"
  },
  {
    "id": "1803092005",
    "district_id": "180309",
    "label": "Margo Rejo",
    "value": "Margo Rejo"
  },
  {
    "id": "1803092006",
    "district_id": "180309",
    "label": "Banjar Wangi",
    "value": "Banjar Wangi"
  },
  {
    "id": "1803092007",
    "district_id": "180309",
    "label": "Sawo Jajar",
    "value": "Sawo Jajar"
  },
  {
    "id": "1803092008",
    "district_id": "180309",
    "label": "Madokoro Baru",
    "value": "Madokoro Baru"
  },
  {
    "id": "1803101001",
    "district_id": "180310",
    "label": "Kelapa Tujuh",
    "value": "Kelapa Tujuh"
  },
  {
    "id": "1803101002",
    "district_id": "180310",
    "label": "Tanjungaman",
    "value": "Tanjungaman"
  },
  {
    "id": "1803101003",
    "district_id": "180310",
    "label": "Kota Alam",
    "value": "Kota Alam"
  },
  {
    "id": "1803101011",
    "district_id": "180310",
    "label": "Tanjungharapan",
    "value": "Tanjungharapan"
  },
  {
    "id": "1803101012",
    "district_id": "180310",
    "label": "Tanjungsenang",
    "value": "Tanjungsenang"
  },
  {
    "id": "1803102004",
    "district_id": "180310",
    "label": "Mulang Maya",
    "value": "Mulang Maya"
  },
  {
    "id": "1803102005",
    "district_id": "180310",
    "label": "Bandar Putih",
    "value": "Bandar Putih"
  },
  {
    "id": "1803102006",
    "district_id": "180310",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1803102007",
    "district_id": "180310",
    "label": "Curup Guruh",
    "value": "Curup Guruh"
  },
  {
    "id": "1803102008",
    "district_id": "180310",
    "label": "Jerangkang",
    "value": "Jerangkang"
  },
  {
    "id": "1803102009",
    "district_id": "180310",
    "label": "Way Melan",
    "value": "Way Melan"
  },
  {
    "id": "1803102010",
    "district_id": "180310",
    "label": "Taman Jaya",
    "value": "Taman Jaya"
  },
  {
    "id": "1803102013",
    "district_id": "180310",
    "label": "Alam Jaya",
    "value": "Alam Jaya"
  },
  {
    "id": "1803102014",
    "district_id": "180310",
    "label": "Sinar Mas Alam",
    "value": "Sinar Mas Alam"
  },
  {
    "id": "1803112001",
    "district_id": "180311",
    "label": "Gunung Besar",
    "value": "Gunung Besar"
  },
  {
    "id": "1803112002",
    "district_id": "180311",
    "label": "Kinciran",
    "value": "Kinciran"
  },
  {
    "id": "1803112003",
    "district_id": "180311",
    "label": "Negla Sari",
    "value": "Negla Sari"
  },
  {
    "id": "1803112004",
    "district_id": "180311",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1803112005",
    "district_id": "180311",
    "label": "Sri Bandung",
    "value": "Sri Bandung"
  },
  {
    "id": "1803112006",
    "district_id": "180311",
    "label": "Subik",
    "value": "Subik"
  },
  {
    "id": "1803112007",
    "district_id": "180311",
    "label": "Gunung Sadar",
    "value": "Gunung Sadar"
  },
  {
    "id": "1803112008",
    "district_id": "180311",
    "label": "Gunung Gijul",
    "value": "Gunung Gijul"
  },
  {
    "id": "1803112016",
    "district_id": "180311",
    "label": "Pekurun Barat",
    "value": "Pekurun Barat"
  },
  {
    "id": "1803112017",
    "district_id": "180311",
    "label": "Pekurun Selatan",
    "value": "Pekurun Selatan"
  },
  {
    "id": "1803112018",
    "district_id": "180311",
    "label": "Pekurun Utara",
    "value": "Pekurun Utara"
  },
  {
    "id": "1803122001",
    "district_id": "180312",
    "label": "Ulak Rengas",
    "value": "Ulak Rengas"
  },
  {
    "id": "1803122002",
    "district_id": "180312",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1803122003",
    "district_id": "180312",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1803122004",
    "district_id": "180312",
    "label": "Sekipi",
    "value": "Sekipi"
  },
  {
    "id": "1803122005",
    "district_id": "180312",
    "label": "Sido Kayo",
    "value": "Sido Kayo"
  },
  {
    "id": "1803122006",
    "district_id": "180312",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1803122007",
    "district_id": "180312",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1803122008",
    "district_id": "180312",
    "label": "Kebun Dalam",
    "value": "Kebun Dalam"
  },
  {
    "id": "1803132001",
    "district_id": "180313",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1803132002",
    "district_id": "180313",
    "label": "Semuli Raya",
    "value": "Semuli Raya"
  },
  {
    "id": "1803132003",
    "district_id": "180313",
    "label": "Semuli Jaya",
    "value": "Semuli Jaya"
  },
  {
    "id": "1803132004",
    "district_id": "180313",
    "label": "Papan Asri",
    "value": "Papan Asri"
  },
  {
    "id": "1803132005",
    "district_id": "180313",
    "label": "Gunung Keramat",
    "value": "Gunung Keramat"
  },
  {
    "id": "1803132006",
    "district_id": "180313",
    "label": "Sido Rahayu",
    "value": "Sido Rahayu"
  },
  {
    "id": "1803132007",
    "district_id": "180313",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1803142001",
    "district_id": "180314",
    "label": "Tatakarya",
    "value": "Tatakarya"
  },
  {
    "id": "1803142002",
    "district_id": "180314",
    "label": "Bandar Abung",
    "value": "Bandar Abung"
  },
  {
    "id": "1803142003",
    "district_id": "180314",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1803142004",
    "district_id": "180314",
    "label": "Purba Sakti",
    "value": "Purba Sakti"
  },
  {
    "id": "1803142005",
    "district_id": "180314",
    "label": "Bumi Raharja",
    "value": "Bumi Raharja"
  },
  {
    "id": "1803142006",
    "district_id": "180314",
    "label": "Bumi Restu",
    "value": "Bumi Restu"
  },
  {
    "id": "1803142007",
    "district_id": "180314",
    "label": "Bandar Sakti",
    "value": "Bandar Sakti"
  },
  {
    "id": "1803142008",
    "district_id": "180314",
    "label": "Karya Sakti",
    "value": "Karya Sakti"
  },
  {
    "id": "1803142009",
    "district_id": "180314",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "1803152001",
    "district_id": "180315",
    "label": "Negeri Ujung Karang",
    "value": "Negeri Ujung Karang"
  },
  {
    "id": "1803152002",
    "district_id": "180315",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1803152003",
    "district_id": "180315",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1803152004",
    "district_id": "180315",
    "label": "Karang Rejo II",
    "value": "Karang Rejo II"
  },
  {
    "id": "1803152005",
    "district_id": "180315",
    "label": "Karang Sakti",
    "value": "Karang Sakti"
  },
  {
    "id": "1803152006",
    "district_id": "180315",
    "label": "Karang Mulyo",
    "value": "Karang Mulyo"
  },
  {
    "id": "1803152007",
    "district_id": "180315",
    "label": "Banjar Negeri",
    "value": "Banjar Negeri"
  },
  {
    "id": "1803152008",
    "district_id": "180315",
    "label": "Banjar Ratu",
    "value": "Banjar Ratu"
  },
  {
    "id": "1803152009",
    "district_id": "180315",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1803152010",
    "district_id": "180315",
    "label": "Pakuon Agung",
    "value": "Pakuon Agung"
  },
  {
    "id": "1803152011",
    "district_id": "180315",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1803162001",
    "district_id": "180316",
    "label": "Negara Tulang Bawang",
    "value": "Negara Tulang Bawang"
  },
  {
    "id": "1803162002",
    "district_id": "180316",
    "label": "Kota Napal",
    "value": "Kota Napal"
  },
  {
    "id": "1803162003",
    "district_id": "180316",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "1803162004",
    "district_id": "180316",
    "label": "Sukadana Udik",
    "value": "Sukadana Udik"
  },
  {
    "id": "1803162005",
    "district_id": "180316",
    "label": "Sukadana Ilir",
    "value": "Sukadana Ilir"
  },
  {
    "id": "1803162006",
    "district_id": "180316",
    "label": "Haduyang Ratu",
    "value": "Haduyang Ratu"
  },
  {
    "id": "1803162007",
    "district_id": "180316",
    "label": "Iso Rejo",
    "value": "Iso Rejo"
  },
  {
    "id": "1803162008",
    "district_id": "180316",
    "label": "Mulyo Rejo II",
    "value": "Mulyo Rejo II"
  },
  {
    "id": "1803162009",
    "district_id": "180316",
    "label": "Tulang Bawang Baru",
    "value": "Tulang Bawang Baru"
  },
  {
    "id": "1803162010",
    "district_id": "180316",
    "label": "Mulyo Rejo I",
    "value": "Mulyo Rejo I"
  },
  {
    "id": "1803162011",
    "district_id": "180316",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1803172001",
    "district_id": "180317",
    "label": "Negera Kemakmuran",
    "value": "Negera Kemakmuran"
  },
  {
    "id": "1803172002",
    "district_id": "180317",
    "label": "Bonglai Tengah",
    "value": "Bonglai Tengah"
  },
  {
    "id": "1803172003",
    "district_id": "180317",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1803172004",
    "district_id": "180317",
    "label": "Lubuk Rukam",
    "value": "Lubuk Rukam"
  },
  {
    "id": "1803172005",
    "district_id": "180317",
    "label": "Ibul Jaya",
    "value": "Ibul Jaya"
  },
  {
    "id": "1803172006",
    "district_id": "180317",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1803172007",
    "district_id": "180317",
    "label": "Gedung Makrifat",
    "value": "Gedung Makrifat"
  },
  {
    "id": "1803172008",
    "district_id": "180317",
    "label": "Gedung Raja",
    "value": "Gedung Raja"
  },
  {
    "id": "1803172009",
    "district_id": "180317",
    "label": "Gedung Negara",
    "value": "Gedung Negara"
  },
  {
    "id": "1803172010",
    "district_id": "180317",
    "label": "Tulung Buyut",
    "value": "Tulung Buyut"
  },
  {
    "id": "1803182001",
    "district_id": "180318",
    "label": "Batu Nangkop",
    "value": "Batu Nangkop"
  },
  {
    "id": "1803182002",
    "district_id": "180318",
    "label": "Negara Bumi",
    "value": "Negara Bumi"
  },
  {
    "id": "1803182003",
    "district_id": "180318",
    "label": "Melungun Ratu",
    "value": "Melungun Ratu"
  },
  {
    "id": "1803182004",
    "district_id": "180318",
    "label": "Negeri Campang Jaya",
    "value": "Negeri Campang Jaya"
  },
  {
    "id": "1803182005",
    "district_id": "180318",
    "label": "Pampang Tangguk Jaya",
    "value": "Pampang Tangguk Jaya"
  },
  {
    "id": "1803182006",
    "district_id": "180318",
    "label": "Negeri Galih Rejo",
    "value": "Negeri Galih Rejo"
  },
  {
    "id": "1803182007",
    "district_id": "180318",
    "label": "Ratu Jaya",
    "value": "Ratu Jaya"
  },
  {
    "id": "1803182008",
    "district_id": "180318",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1803192001",
    "district_id": "180319",
    "label": "Nyapah Banyu",
    "value": "Nyapah Banyu"
  },
  {
    "id": "1803192002",
    "district_id": "180319",
    "label": "Sinar Gunung",
    "value": "Sinar Gunung"
  },
  {
    "id": "1803192003",
    "district_id": "180319",
    "label": "Campang Gijul",
    "value": "Campang Gijul"
  },
  {
    "id": "1803192004",
    "district_id": "180319",
    "label": "Sumber Tani",
    "value": "Sumber Tani"
  },
  {
    "id": "1803192005",
    "district_id": "180319",
    "label": "Pekurun",
    "value": "Pekurun"
  },
  {
    "id": "1803192006",
    "district_id": "180319",
    "label": "Ogan Campang",
    "value": "Ogan Campang"
  },
  {
    "id": "1803192007",
    "district_id": "180319",
    "label": "Pekurun Tengah",
    "value": "Pekurun Tengah"
  },
  {
    "id": "1803192008",
    "district_id": "180319",
    "label": "Pekurun Udik",
    "value": "Pekurun Udik"
  },
  {
    "id": "1803192009",
    "district_id": "180319",
    "label": "Ogan Jaya",
    "value": "Ogan Jaya"
  },
  {
    "id": "1803202001",
    "district_id": "180320",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "1803202002",
    "district_id": "180320",
    "label": "Sri Agung",
    "value": "Sri Agung"
  },
  {
    "id": "1803202003",
    "district_id": "180320",
    "label": "Lepang Tengah",
    "value": "Lepang Tengah"
  },
  {
    "id": "1803202004",
    "district_id": "180320",
    "label": "Cahaya Makmur",
    "value": "Cahaya Makmur"
  },
  {
    "id": "1803202005",
    "district_id": "180320",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1803202006",
    "district_id": "180320",
    "label": "Sri Jaya",
    "value": "Sri Jaya"
  },
  {
    "id": "1803202007",
    "district_id": "180320",
    "label": "Negara Agung",
    "value": "Negara Agung"
  },
  {
    "id": "1803202008",
    "district_id": "180320",
    "label": "Cempaka Barat",
    "value": "Cempaka Barat"
  },
  {
    "id": "1803202009",
    "district_id": "180320",
    "label": "Cempaka Timur",
    "value": "Cempaka Timur"
  },
  {
    "id": "1803212001",
    "district_id": "180321",
    "label": "Sinar Harapan",
    "value": "Sinar Harapan"
  },
  {
    "id": "1803212002",
    "district_id": "180321",
    "label": "Kubu Hitu",
    "value": "Kubu Hitu"
  },
  {
    "id": "1803212003",
    "district_id": "180321",
    "label": "Negeri Sakti",
    "value": "Negeri Sakti"
  },
  {
    "id": "1803212004",
    "district_id": "180321",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1803212005",
    "district_id": "180321",
    "label": "Gunung Raja",
    "value": "Gunung Raja"
  },
  {
    "id": "1803212006",
    "district_id": "180321",
    "label": "Cahaya Mas",
    "value": "Cahaya Mas"
  },
  {
    "id": "1803212007",
    "district_id": "180321",
    "label": "Comok Sinar Jaya",
    "value": "Comok Sinar Jaya"
  },
  {
    "id": "1803212008",
    "district_id": "180321",
    "label": "Way Isem",
    "value": "Way Isem"
  },
  {
    "id": "1803212009",
    "district_id": "180321",
    "label": "Gunung Maknibai",
    "value": "Gunung Maknibai"
  },
  {
    "id": "1803212010",
    "district_id": "180321",
    "label": "Negeri Batin Jaya",
    "value": "Negeri Batin Jaya"
  },
  {
    "id": "1803222001",
    "district_id": "180322",
    "label": "Aji Kagungan",
    "value": "Aji Kagungan"
  },
  {
    "id": "1803222002",
    "district_id": "180322",
    "label": "Talang Jembatan",
    "value": "Talang Jembatan"
  },
  {
    "id": "1803222003",
    "district_id": "180322",
    "label": "Sabuk Empat",
    "value": "Sabuk Empat"
  },
  {
    "id": "1803222004",
    "district_id": "180322",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "1803222005",
    "district_id": "180322",
    "label": "Sabuk Indah",
    "value": "Sabuk Indah"
  },
  {
    "id": "1803222006",
    "district_id": "180322",
    "label": "Bindu",
    "value": "Bindu"
  },
  {
    "id": "1803222007",
    "district_id": "180322",
    "label": "Way Perancang",
    "value": "Way Perancang"
  },
  {
    "id": "1803232001",
    "district_id": "180323",
    "label": "Pagar",
    "value": "Pagar"
  },
  {
    "id": "1803232002",
    "district_id": "180323",
    "label": "Blambangan",
    "value": "Blambangan"
  },
  {
    "id": "1803232003",
    "district_id": "180323",
    "label": "Jagang",
    "value": "Jagang"
  },
  {
    "id": "1803232004",
    "district_id": "180323",
    "label": "Tanjung Iman",
    "value": "Tanjung Iman"
  },
  {
    "id": "1803232005",
    "district_id": "180323",
    "label": "Tulung Singkip",
    "value": "Tulung Singkip"
  },
  {
    "id": "1803232006",
    "district_id": "180323",
    "label": "Pagar Gading",
    "value": "Pagar Gading"
  },
  {
    "id": "1803232007",
    "district_id": "180323",
    "label": "Buring Kencana",
    "value": "Buring Kencana"
  },
  {
    "id": "1804041001",
    "district_id": "180404",
    "label": "Pasar Liwa",
    "value": "Pasar Liwa"
  },
  {
    "id": "1804041003",
    "district_id": "180404",
    "label": "Way Mengaku",
    "value": "Way Mengaku"
  },
  {
    "id": "1804042002",
    "district_id": "180404",
    "label": "Kubu Perahu",
    "value": "Kubu Perahu"
  },
  {
    "id": "1804042004",
    "district_id": "180404",
    "label": "Padang Cahya",
    "value": "Padang Cahya"
  },
  {
    "id": "1804042005",
    "district_id": "180404",
    "label": "Sebarus",
    "value": "Sebarus"
  },
  {
    "id": "1804042006",
    "district_id": "180404",
    "label": "Way Empulau Ulu",
    "value": "Way Empulau Ulu"
  },
  {
    "id": "1804042007",
    "district_id": "180404",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "1804042008",
    "district_id": "180404",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "1804042009",
    "district_id": "180404",
    "label": "Padang Dalom",
    "value": "Padang Dalom"
  },
  {
    "id": "1804042010",
    "district_id": "180404",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1804042011",
    "district_id": "180404",
    "label": "Bahway",
    "value": "Bahway"
  },
  {
    "id": "1804042012",
    "district_id": "180404",
    "label": "Sedampah Indah",
    "value": "Sedampah Indah"
  },
  {
    "id": "1804051016",
    "district_id": "180405",
    "label": "Tugu Sari",
    "value": "Tugu Sari"
  },
  {
    "id": "1804052001",
    "district_id": "180405",
    "label": "Simpang Sari",
    "value": "Simpang Sari"
  },
  {
    "id": "1804052010",
    "district_id": "180405",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1804052011",
    "district_id": "180405",
    "label": "Sindang Pagar",
    "value": "Sindang Pagar"
  },
  {
    "id": "1804052012",
    "district_id": "180405",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "1804052013",
    "district_id": "180405",
    "label": "Way Petai",
    "value": "Way Petai"
  },
  {
    "id": "1804062001",
    "district_id": "180406",
    "label": "Kenali",
    "value": "Kenali"
  },
  {
    "id": "1804062002",
    "district_id": "180406",
    "label": "Kejadian",
    "value": "Kejadian"
  },
  {
    "id": "1804062003",
    "district_id": "180406",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1804062004",
    "district_id": "180406",
    "label": "Turgak",
    "value": "Turgak"
  },
  {
    "id": "1804062005",
    "district_id": "180406",
    "label": "Bedudu",
    "value": "Bedudu"
  },
  {
    "id": "1804062006",
    "district_id": "180406",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1804062007",
    "district_id": "180406",
    "label": "Hujung",
    "value": "Hujung"
  },
  {
    "id": "1804062013",
    "district_id": "180406",
    "label": "Serungkuk",
    "value": "Serungkuk"
  },
  {
    "id": "1804062019",
    "district_id": "180406",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1804062020",
    "district_id": "180406",
    "label": "Fajar Agung",
    "value": "Fajar Agung"
  },
  {
    "id": "1804071001",
    "district_id": "180407",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1804072002",
    "district_id": "180407",
    "label": "Puralaksana",
    "value": "Puralaksana"
  },
  {
    "id": "1804072003",
    "district_id": "180407",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1804072004",
    "district_id": "180407",
    "label": "Mutar Alam",
    "value": "Mutar Alam"
  },
  {
    "id": "1804072005",
    "district_id": "180407",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1804072006",
    "district_id": "180407",
    "label": "Sukananti",
    "value": "Sukananti"
  },
  {
    "id": "1804072007",
    "district_id": "180407",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1804072008",
    "district_id": "180407",
    "label": "Padang Tambak",
    "value": "Padang Tambak"
  },
  {
    "id": "1804072009",
    "district_id": "180407",
    "label": "Tambak Jaya",
    "value": "Tambak Jaya"
  },
  {
    "id": "1804081005",
    "district_id": "180408",
    "label": "Sekincau",
    "value": "Sekincau"
  },
  {
    "id": "1804082001",
    "district_id": "180408",
    "label": "Pampangan",
    "value": "Pampangan"
  },
  {
    "id": "1804082006",
    "district_id": "180408",
    "label": "Waspada",
    "value": "Waspada"
  },
  {
    "id": "1804082007",
    "district_id": "180408",
    "label": "Tiga Jaya",
    "value": "Tiga Jaya"
  },
  {
    "id": "1804082008",
    "district_id": "180408",
    "label": "Giham Sukamaju",
    "value": "Giham Sukamaju"
  },
  {
    "id": "1804092003",
    "district_id": "180409",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1804092004",
    "district_id": "180409",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1804092005",
    "district_id": "180409",
    "label": "Tuguratu",
    "value": "Tuguratu"
  },
  {
    "id": "1804092006",
    "district_id": "180409",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "1804092010",
    "district_id": "180409",
    "label": "Roworejo",
    "value": "Roworejo"
  },
  {
    "id": "1804092017",
    "district_id": "180409",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1804092018",
    "district_id": "180409",
    "label": "Ringin Sari",
    "value": "Ringin Sari"
  },
  {
    "id": "1804102001",
    "district_id": "180410",
    "label": "Kembahang",
    "value": "Kembahang"
  },
  {
    "id": "1804102002",
    "district_id": "180410",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1804102003",
    "district_id": "180410",
    "label": "Kegeringan",
    "value": "Kegeringan"
  },
  {
    "id": "1804102004",
    "district_id": "180410",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "1804102005",
    "district_id": "180410",
    "label": "Pekon Balak",
    "value": "Pekon Balak"
  },
  {
    "id": "1804102006",
    "district_id": "180410",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "1804102007",
    "district_id": "180410",
    "label": "Canggu",
    "value": "Canggu"
  },
  {
    "id": "1804102008",
    "district_id": "180410",
    "label": "Kota Besi",
    "value": "Kota Besi"
  },
  {
    "id": "1804102009",
    "district_id": "180410",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1804102010",
    "district_id": "180410",
    "label": "Kerang",
    "value": "Kerang"
  },
  {
    "id": "1804102011",
    "district_id": "180410",
    "label": "Teba Liyokh",
    "value": "Teba Liyokh"
  },
  {
    "id": "1804112001",
    "district_id": "180411",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1804112002",
    "district_id": "180411",
    "label": "Hanakau",
    "value": "Hanakau"
  },
  {
    "id": "1804112003",
    "district_id": "180411",
    "label": "Buay Nyerupa",
    "value": "Buay Nyerupa"
  },
  {
    "id": "1804112004",
    "district_id": "180411",
    "label": "Tapak Siring",
    "value": "Tapak Siring"
  },
  {
    "id": "1804112005",
    "district_id": "180411",
    "label": "Jaga Raga",
    "value": "Jaga Raga"
  },
  {
    "id": "1804112009",
    "district_id": "180411",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1804112012",
    "district_id": "180411",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1804112018",
    "district_id": "180411",
    "label": "Bandar Baru",
    "value": "Bandar Baru"
  },
  {
    "id": "1804112019",
    "district_id": "180411",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "1804112020",
    "district_id": "180411",
    "label": "Teba Pering Jaya",
    "value": "Teba Pering Jaya"
  },
  {
    "id": "1804152001",
    "district_id": "180415",
    "label": "Gedung Surian",
    "value": "Gedung Surian"
  },
  {
    "id": "1804152002",
    "district_id": "180415",
    "label": "Pura Mekar",
    "value": "Pura Mekar"
  },
  {
    "id": "1804152003",
    "district_id": "180415",
    "label": "Cipta Waras",
    "value": "Cipta Waras"
  },
  {
    "id": "1804152004",
    "district_id": "180415",
    "label": "Tri Mulyo",
    "value": "Tri Mulyo"
  },
  {
    "id": "1804152005",
    "district_id": "180415",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1804182001",
    "district_id": "180418",
    "label": "Pura Jaya",
    "value": "Pura Jaya"
  },
  {
    "id": "1804182002",
    "district_id": "180418",
    "label": "Pura Wiwitan",
    "value": "Pura Wiwitan"
  },
  {
    "id": "1804182003",
    "district_id": "180418",
    "label": "Tribudi Syukur",
    "value": "Tribudi Syukur"
  },
  {
    "id": "1804182004",
    "district_id": "180418",
    "label": "Muara Jaya I",
    "value": "Muara Jaya I"
  },
  {
    "id": "1804182005",
    "district_id": "180418",
    "label": "Muara Jaya II",
    "value": "Muara Jaya II"
  },
  {
    "id": "1804182006",
    "district_id": "180418",
    "label": "Tribudi Makmur",
    "value": "Tribudi Makmur"
  },
  {
    "id": "1804182007",
    "district_id": "180418",
    "label": "Tugu Mulya",
    "value": "Tugu Mulya"
  },
  {
    "id": "1804182008",
    "district_id": "180418",
    "label": "Cipta Mulya",
    "value": "Cipta Mulya"
  },
  {
    "id": "1804182009",
    "district_id": "180418",
    "label": "Muara Baru",
    "value": "Muara Baru"
  },
  {
    "id": "1804182010",
    "district_id": "180418",
    "label": "Sinar Luas",
    "value": "Sinar Luas"
  },
  {
    "id": "1804192001",
    "district_id": "180419",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1804192002",
    "district_id": "180419",
    "label": "Semarang Jaya",
    "value": "Semarang Jaya"
  },
  {
    "id": "1804192003",
    "district_id": "180419",
    "label": "Sumber Alam",
    "value": "Sumber Alam"
  },
  {
    "id": "1804192004",
    "district_id": "180419",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1804192005",
    "district_id": "180419",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1804192006",
    "district_id": "180419",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1804192007",
    "district_id": "180419",
    "label": "Sinar Jaya",
    "value": "Sinar Jaya"
  },
  {
    "id": "1804192008",
    "district_id": "180419",
    "label": "Rigis Jaya",
    "value": "Rigis Jaya"
  },
  {
    "id": "1804192009",
    "district_id": "180419",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "1804192010",
    "district_id": "180419",
    "label": "Manggarai",
    "value": "Manggarai"
  },
  {
    "id": "1804202001",
    "district_id": "180420",
    "label": "Pahayu Jaya",
    "value": "Pahayu Jaya"
  },
  {
    "id": "1804202002",
    "district_id": "180420",
    "label": "Basungan",
    "value": "Basungan"
  },
  {
    "id": "1804202003",
    "district_id": "180420",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1804202004",
    "district_id": "180420",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1804202005",
    "district_id": "180420",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1804202006",
    "district_id": "180420",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1804202007",
    "district_id": "180420",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1804202008",
    "district_id": "180420",
    "label": "Batu Api",
    "value": "Batu Api"
  },
  {
    "id": "1804202009",
    "district_id": "180420",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1804202010",
    "district_id": "180420",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1804212001",
    "district_id": "180421",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "1804212002",
    "district_id": "180421",
    "label": "Batu Kebayan",
    "value": "Batu Kebayan"
  },
  {
    "id": "1804212003",
    "district_id": "180421",
    "label": "Atar Bawang",
    "value": "Atar Bawang"
  },
  {
    "id": "1804212004",
    "district_id": "180421",
    "label": "Campang Tiga",
    "value": "Campang Tiga"
  },
  {
    "id": "1804212005",
    "district_id": "180421",
    "label": "Luas",
    "value": "Luas"
  },
  {
    "id": "1804212006",
    "district_id": "180421",
    "label": "Bakhu",
    "value": "Bakhu"
  },
  {
    "id": "1804212007",
    "district_id": "180421",
    "label": "Way Ngison",
    "value": "Way Ngison"
  },
  {
    "id": "1804212008",
    "district_id": "180421",
    "label": "Kubu Liku Jaya",
    "value": "Kubu Liku Jaya"
  },
  {
    "id": "1804212009",
    "district_id": "180421",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1804212010",
    "district_id": "180421",
    "label": "Atar Kuwau",
    "value": "Atar Kuwau"
  },
  {
    "id": "1804222001",
    "district_id": "180422",
    "label": "Lombok",
    "value": "Lombok"
  },
  {
    "id": "1804222002",
    "district_id": "180422",
    "label": "Heni Arong",
    "value": "Heni Arong"
  },
  {
    "id": "1804222003",
    "district_id": "180422",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1804222004",
    "district_id": "180422",
    "label": "Lombok Timur",
    "value": "Lombok Timur"
  },
  {
    "id": "1804222005",
    "district_id": "180422",
    "label": "Suka Banjar II Ujung Rembun",
    "value": "Suka Banjar II Ujung Rembun"
  },
  {
    "id": "1804222006",
    "district_id": "180422",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1804222007",
    "district_id": "180422",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "1804222008",
    "district_id": "180422",
    "label": "Keagungan",
    "value": "Keagungan"
  },
  {
    "id": "1804222009",
    "district_id": "180422",
    "label": "Tawan Suka Mulya",
    "value": "Tawan Suka Mulya"
  },
  {
    "id": "1804222010",
    "district_id": "180422",
    "label": "Pancur Mas",
    "value": "Pancur Mas"
  },
  {
    "id": "1804222011",
    "district_id": "180422",
    "label": "Lombok Selatan",
    "value": "Lombok Selatan"
  },
  {
    "id": "1804232001",
    "district_id": "180423",
    "label": "Suoh",
    "value": "Suoh"
  },
  {
    "id": "1804232002",
    "district_id": "180423",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "1804232003",
    "district_id": "180423",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1804232004",
    "district_id": "180423",
    "label": "Ringin Jaya",
    "value": "Ringin Jaya"
  },
  {
    "id": "1804232005",
    "district_id": "180423",
    "label": "Bumi Hantatai",
    "value": "Bumi Hantatai"
  },
  {
    "id": "1804232006",
    "district_id": "180423",
    "label": "Gunungratu",
    "value": "Gunungratu"
  },
  {
    "id": "1804232007",
    "district_id": "180423",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "1804232008",
    "district_id": "180423",
    "label": "Negeri Jaya",
    "value": "Negeri Jaya"
  },
  {
    "id": "1804232009",
    "district_id": "180423",
    "label": "Tembelang",
    "value": "Tembelang"
  },
  {
    "id": "1804232010",
    "district_id": "180423",
    "label": "Tri Mekar Jaya",
    "value": "Tri Mekar Jaya"
  },
  {
    "id": "1805021008",
    "district_id": "180502",
    "label": "Menggala Selatan",
    "value": "Menggala Selatan"
  },
  {
    "id": "1805021009",
    "district_id": "180502",
    "label": "Ujung Gunung Ilir",
    "value": "Ujung Gunung Ilir"
  },
  {
    "id": "1805021010",
    "district_id": "180502",
    "label": "Menggala Tengah",
    "value": "Menggala Tengah"
  },
  {
    "id": "1805021011",
    "district_id": "180502",
    "label": "Menggala Kota",
    "value": "Menggala Kota"
  },
  {
    "id": "1805022001",
    "district_id": "180502",
    "label": "Bujung Tenuk",
    "value": "Bujung Tenuk"
  },
  {
    "id": "1805022002",
    "district_id": "180502",
    "label": "Ujung Gunung Ilir",
    "value": "Ujung Gunung Ilir"
  },
  {
    "id": "1805022007",
    "district_id": "180502",
    "label": "Astra Ksetra",
    "value": "Astra Ksetra"
  },
  {
    "id": "1805022013",
    "district_id": "180502",
    "label": "Kagungan Rahayu",
    "value": "Kagungan Rahayu"
  },
  {
    "id": "1805022014",
    "district_id": "180502",
    "label": "Tiuh Tohou",
    "value": "Tiuh Tohou"
  },
  {
    "id": "1805062001",
    "district_id": "180506",
    "label": "Aji Jaya KNPI",
    "value": "Aji Jaya KNPI"
  },
  {
    "id": "1805062002",
    "district_id": "180506",
    "label": "Kecubung Jaya",
    "value": "Kecubung Jaya"
  },
  {
    "id": "1805062008",
    "district_id": "180506",
    "label": "Kecubung Mulya",
    "value": "Kecubung Mulya"
  },
  {
    "id": "1805062015",
    "district_id": "180506",
    "label": "Gedung Aji",
    "value": "Gedung Aji"
  },
  {
    "id": "1805062016",
    "district_id": "180506",
    "label": "Penawar",
    "value": "Penawar"
  },
  {
    "id": "1805062022",
    "district_id": "180506",
    "label": "Penawar Baru",
    "value": "Penawar Baru"
  },
  {
    "id": "1805062023",
    "district_id": "180506",
    "label": "Aji Murni Jaya",
    "value": "Aji Murni Jaya"
  },
  {
    "id": "1805062024",
    "district_id": "180506",
    "label": "Aji Mesir",
    "value": "Aji Mesir"
  },
  {
    "id": "1805062025",
    "district_id": "180506",
    "label": "Aji Permai Talang Buah",
    "value": "Aji Permai Talang Buah"
  },
  {
    "id": "1805062026",
    "district_id": "180506",
    "label": "Bandar Aji Jaya",
    "value": "Bandar Aji Jaya"
  },
  {
    "id": "1805082001",
    "district_id": "180508",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1805082007",
    "district_id": "180508",
    "label": "Tri Dharma Wira Jaya",
    "value": "Tri Dharma Wira Jaya"
  },
  {
    "id": "1805082009",
    "district_id": "180508",
    "label": "Moris Jaya",
    "value": "Moris Jaya"
  },
  {
    "id": "1805082010",
    "district_id": "180508",
    "label": "Tunggal Warga",
    "value": "Tunggal Warga"
  },
  {
    "id": "1805082011",
    "district_id": "180508",
    "label": "Dwi Warga Tunggal Jaya",
    "value": "Dwi Warga Tunggal Jaya"
  },
  {
    "id": "1805082019",
    "district_id": "180508",
    "label": "Tri Mulya Jaya",
    "value": "Tri Mulya Jaya"
  },
  {
    "id": "1805082020",
    "district_id": "180508",
    "label": "Tri Mukti Jaya",
    "value": "Tri Mukti Jaya"
  },
  {
    "id": "1805082021",
    "district_id": "180508",
    "label": "Tri Tunggal Jaya",
    "value": "Tri Tunggal Jaya"
  },
  {
    "id": "1805082022",
    "district_id": "180508",
    "label": "Warga Makmur Jaya",
    "value": "Warga Makmur Jaya"
  },
  {
    "id": "1805082023",
    "district_id": "180508",
    "label": "Warga Indah Jaya",
    "value": "Warga Indah Jaya"
  },
  {
    "id": "1805082024",
    "district_id": "180508",
    "label": "Banjar Dewa",
    "value": "Banjar Dewa"
  },
  {
    "id": "1805112001",
    "district_id": "180511",
    "label": "Gunung Tapa",
    "value": "Gunung Tapa"
  },
  {
    "id": "1805112002",
    "district_id": "180511",
    "label": "Gedung Meneng",
    "value": "Gedung Meneng"
  },
  {
    "id": "1805112006",
    "district_id": "180511",
    "label": "Bakung Udik",
    "value": "Bakung Udik"
  },
  {
    "id": "1805112007",
    "district_id": "180511",
    "label": "Bakung Ilir",
    "value": "Bakung Ilir"
  },
  {
    "id": "1805112008",
    "district_id": "180511",
    "label": "Gedung Bandar Rahayu",
    "value": "Gedung Bandar Rahayu"
  },
  {
    "id": "1805112014",
    "district_id": "180511",
    "label": "Gunung Tapa Ilir",
    "value": "Gunung Tapa Ilir"
  },
  {
    "id": "1805112015",
    "district_id": "180511",
    "label": "Gunung Tapa Tengah",
    "value": "Gunung Tapa Tengah"
  },
  {
    "id": "1805112016",
    "district_id": "180511",
    "label": "Gunung Tapa Udik",
    "value": "Gunung Tapa Udik"
  },
  {
    "id": "1805112017",
    "district_id": "180511",
    "label": "Gedung Bandar Rejo",
    "value": "Gedung Bandar Rejo"
  },
  {
    "id": "1805112018",
    "district_id": "180511",
    "label": "Bakung Rahayu",
    "value": "Bakung Rahayu"
  },
  {
    "id": "1805112019",
    "district_id": "180511",
    "label": "Gedung Meneng Baru",
    "value": "Gedung Meneng Baru"
  },
  {
    "id": "1805122003",
    "district_id": "180512",
    "label": "Yudha Karya Jitu",
    "value": "Yudha Karya Jitu"
  },
  {
    "id": "1805122004",
    "district_id": "180512",
    "label": "Gedung Karya Jitu",
    "value": "Gedung Karya Jitu"
  },
  {
    "id": "1805122005",
    "district_id": "180512",
    "label": "Hargo Rejo",
    "value": "Hargo Rejo"
  },
  {
    "id": "1805122006",
    "district_id": "180512",
    "label": "Wono Agung",
    "value": "Wono Agung"
  },
  {
    "id": "1805122008",
    "district_id": "180512",
    "label": "Karya Jitu Mukti",
    "value": "Karya Jitu Mukti"
  },
  {
    "id": "1805122009",
    "district_id": "180512",
    "label": "Bumi Ratu",
    "value": "Bumi Ratu"
  },
  {
    "id": "1805122010",
    "district_id": "180512",
    "label": "Medasari",
    "value": "Medasari"
  },
  {
    "id": "1805122013",
    "district_id": "180512",
    "label": "Hargo Mulyo",
    "value": "Hargo Mulyo"
  },
  {
    "id": "1805122014",
    "district_id": "180512",
    "label": "Karya Cipta Abadi",
    "value": "Karya Cipta Abadi"
  },
  {
    "id": "1805132001",
    "district_id": "180513",
    "label": "Tri Rejo Mulyo",
    "value": "Tri Rejo Mulyo"
  },
  {
    "id": "1805132002",
    "district_id": "180513",
    "label": "Tri Jaya",
    "value": "Tri Jaya"
  },
  {
    "id": "1805132005",
    "district_id": "180513",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1805132006",
    "district_id": "180513",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1805132010",
    "district_id": "180513",
    "label": "Bogatama",
    "value": "Bogatama"
  },
  {
    "id": "1805132011",
    "district_id": "180513",
    "label": "Wiratama",
    "value": "Wiratama"
  },
  {
    "id": "1805132013",
    "district_id": "180513",
    "label": "Tri Tunggal Jaya",
    "value": "Tri Tunggal Jaya"
  },
  {
    "id": "1805132019",
    "district_id": "180513",
    "label": "Pulo Gadung",
    "value": "Pulo Gadung"
  },
  {
    "id": "1805132020",
    "district_id": "180513",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1805132021",
    "district_id": "180513",
    "label": "Dwimulyo",
    "value": "Dwimulyo"
  },
  {
    "id": "1805132022",
    "district_id": "180513",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1805132023",
    "district_id": "180513",
    "label": "Wira Agung Sari",
    "value": "Wira Agung Sari"
  },
  {
    "id": "1805132024",
    "district_id": "180513",
    "label": "Sidomakmur",
    "value": "Sidomakmur"
  },
  {
    "id": "1805132025",
    "district_id": "180513",
    "label": "Trikarya",
    "value": "Trikarya"
  },
  {
    "id": "1805182001",
    "district_id": "180518",
    "label": "Bumi Dipasena Utama",
    "value": "Bumi Dipasena Utama"
  },
  {
    "id": "1805182002",
    "district_id": "180518",
    "label": "Bumi Dipasena Agung",
    "value": "Bumi Dipasena Agung"
  },
  {
    "id": "1805182003",
    "district_id": "180518",
    "label": "Bumi Dipasena Jaya",
    "value": "Bumi Dipasena Jaya"
  },
  {
    "id": "1805182004",
    "district_id": "180518",
    "label": "Bumi Dipasena Abadi",
    "value": "Bumi Dipasena Abadi"
  },
  {
    "id": "1805182005",
    "district_id": "180518",
    "label": "Bumi Dipasena Makmur",
    "value": "Bumi Dipasena Makmur"
  },
  {
    "id": "1805182006",
    "district_id": "180518",
    "label": "Bumi Sentosa",
    "value": "Bumi Sentosa"
  },
  {
    "id": "1805182007",
    "district_id": "180518",
    "label": "Bumi Dipasena Mulya",
    "value": "Bumi Dipasena Mulya"
  },
  {
    "id": "1805182008",
    "district_id": "180518",
    "label": "Bumi Dipasena Sejahtera",
    "value": "Bumi Dipasena Sejahtera"
  },
  {
    "id": "1805202001",
    "district_id": "180520",
    "label": "Bujuk Agung",
    "value": "Bujuk Agung"
  },
  {
    "id": "1805202002",
    "district_id": "180520",
    "label": "Ringin Sari",
    "value": "Ringin Sari"
  },
  {
    "id": "1805202003",
    "district_id": "180520",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1805202004",
    "district_id": "180520",
    "label": "Catur Karya Buana",
    "value": "Catur Karya Buana"
  },
  {
    "id": "1805202005",
    "district_id": "180520",
    "label": "Purwa Jaya",
    "value": "Purwa Jaya"
  },
  {
    "id": "1805202006",
    "district_id": "180520",
    "label": "Penawar Jaya",
    "value": "Penawar Jaya"
  },
  {
    "id": "1805202007",
    "district_id": "180520",
    "label": "Agung Dalem",
    "value": "Agung Dalem"
  },
  {
    "id": "1805202008",
    "district_id": "180520",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1805202009",
    "district_id": "180520",
    "label": "Tri Tunggal Jaya",
    "value": "Tri Tunggal Jaya"
  },
  {
    "id": "1805202010",
    "district_id": "180520",
    "label": "Agung Jaya",
    "value": "Agung Jaya"
  },
  {
    "id": "1805202011",
    "district_id": "180520",
    "label": "Penawar Rejo",
    "value": "Penawar Rejo"
  },
  {
    "id": "1805202012",
    "district_id": "180520",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1805222001",
    "district_id": "180522",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1805222002",
    "district_id": "180522",
    "label": "Batang Hari",
    "value": "Batang Hari"
  },
  {
    "id": "1805222003",
    "district_id": "180522",
    "label": "Panggung Mulyo",
    "value": "Panggung Mulyo"
  },
  {
    "id": "1805222004",
    "district_id": "180522",
    "label": "Duto Yoso Mulyo",
    "value": "Duto Yoso Mulyo"
  },
  {
    "id": "1805222005",
    "district_id": "180522",
    "label": "Andalas Cermin",
    "value": "Andalas Cermin"
  },
  {
    "id": "1805222006",
    "district_id": "180522",
    "label": "Rawa Ragil",
    "value": "Rawa Ragil"
  },
  {
    "id": "1805222007",
    "district_id": "180522",
    "label": "Gedung Jaya",
    "value": "Gedung Jaya"
  },
  {
    "id": "1805222008",
    "district_id": "180522",
    "label": "Bumi Sari",
    "value": "Bumi Sari"
  },
  {
    "id": "1805222009",
    "district_id": "180522",
    "label": "Mulyo Dadi",
    "value": "Mulyo Dadi"
  },
  {
    "id": "1805232001",
    "district_id": "180523",
    "label": "Gedung Harapan",
    "value": "Gedung Harapan"
  },
  {
    "id": "1805232002",
    "district_id": "180523",
    "label": "Gedung Asri",
    "value": "Gedung Asri"
  },
  {
    "id": "1805232003",
    "district_id": "180523",
    "label": "Gedung Rejo Sakti",
    "value": "Gedung Rejo Sakti"
  },
  {
    "id": "1805232004",
    "district_id": "180523",
    "label": "Pasar Batang",
    "value": "Pasar Batang"
  },
  {
    "id": "1805232005",
    "district_id": "180523",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "1805232006",
    "district_id": "180523",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "1805232007",
    "district_id": "180523",
    "label": "Wono Rejo",
    "value": "Wono Rejo"
  },
  {
    "id": "1805232008",
    "district_id": "180523",
    "label": "Panca Tunggal Jaya",
    "value": "Panca Tunggal Jaya"
  },
  {
    "id": "1805232009",
    "district_id": "180523",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1805252001",
    "district_id": "180525",
    "label": "Teladas",
    "value": "Teladas"
  },
  {
    "id": "1805252002",
    "district_id": "180525",
    "label": "Kekatung",
    "value": "Kekatung"
  },
  {
    "id": "1805252003",
    "district_id": "180525",
    "label": "Kuala Teladas",
    "value": "Kuala Teladas"
  },
  {
    "id": "1805252004",
    "district_id": "180525",
    "label": "Mahabang",
    "value": "Mahabang"
  },
  {
    "id": "1805252005",
    "district_id": "180525",
    "label": "Sungai Nibung",
    "value": "Sungai Nibung"
  },
  {
    "id": "1805252006",
    "district_id": "180525",
    "label": "Pasiran Jaya",
    "value": "Pasiran Jaya"
  },
  {
    "id": "1805252007",
    "district_id": "180525",
    "label": "Bratasena Adiwarna",
    "value": "Bratasena Adiwarna"
  },
  {
    "id": "1805252008",
    "district_id": "180525",
    "label": "Bratasena Mandiri",
    "value": "Bratasena Mandiri"
  },
  {
    "id": "1805252009",
    "district_id": "180525",
    "label": "Way Dente",
    "value": "Way Dente"
  },
  {
    "id": "1805252010",
    "district_id": "180525",
    "label": "Dente Makmur",
    "value": "Dente Makmur"
  },
  {
    "id": "1805252011",
    "district_id": "180525",
    "label": "Pendowo Asri",
    "value": "Pendowo Asri"
  },
  {
    "id": "1805252012",
    "district_id": "180525",
    "label": "Sungai Burung",
    "value": "Sungai Burung"
  },
  {
    "id": "1805262001",
    "district_id": "180526",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1805262002",
    "district_id": "180526",
    "label": "Paduan Rajawali",
    "value": "Paduan Rajawali"
  },
  {
    "id": "1805262003",
    "district_id": "180526",
    "label": "Karya Bhakti",
    "value": "Karya Bhakti"
  },
  {
    "id": "1805262004",
    "district_id": "180526",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1805262005",
    "district_id": "180526",
    "label": "Bina Bumi",
    "value": "Bina Bumi"
  },
  {
    "id": "1805262006",
    "district_id": "180526",
    "label": "Mulyo Aji",
    "value": "Mulyo Aji"
  },
  {
    "id": "1805262007",
    "district_id": "180526",
    "label": "Kecubung Raya",
    "value": "Kecubung Raya"
  },
  {
    "id": "1805262008",
    "district_id": "180526",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1805272001",
    "district_id": "180527",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "1805272002",
    "district_id": "180527",
    "label": "Mesir Dwi Jaya",
    "value": "Mesir Dwi Jaya"
  },
  {
    "id": "1805272003",
    "district_id": "180527",
    "label": "Makartitama",
    "value": "Makartitama"
  },
  {
    "id": "1805272004",
    "district_id": "180527",
    "label": "Suka Bhakti",
    "value": "Suka Bhakti"
  },
  {
    "id": "1805272005",
    "district_id": "180527",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "1805272006",
    "district_id": "180527",
    "label": "Setia Tama",
    "value": "Setia Tama"
  },
  {
    "id": "1805272007",
    "district_id": "180527",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1805272008",
    "district_id": "180527",
    "label": "Mekar Asri",
    "value": "Mekar Asri"
  },
  {
    "id": "1805272009",
    "district_id": "180527",
    "label": "Sidomekar",
    "value": "Sidomekar"
  },
  {
    "id": "1805292001",
    "district_id": "180529",
    "label": "Panca Mulia",
    "value": "Panca Mulia"
  },
  {
    "id": "1805292002",
    "district_id": "180529",
    "label": "Panca Karsa Purna Jaya",
    "value": "Panca Karsa Purna Jaya"
  },
  {
    "id": "1805292003",
    "district_id": "180529",
    "label": "Kahuripan Jaya",
    "value": "Kahuripan Jaya"
  },
  {
    "id": "1805292004",
    "district_id": "180529",
    "label": "Bawang Sakti Jaya",
    "value": "Bawang Sakti Jaya"
  },
  {
    "id": "1805292005",
    "district_id": "180529",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1805292006",
    "district_id": "180529",
    "label": "Balai Murni Jaya",
    "value": "Balai Murni Jaya"
  },
  {
    "id": "1805292007",
    "district_id": "180529",
    "label": "Mekar Indah Jaya",
    "value": "Mekar Indah Jaya"
  },
  {
    "id": "1805292008",
    "district_id": "180529",
    "label": "Jaya Makmur",
    "value": "Jaya Makmur"
  },
  {
    "id": "1805292009",
    "district_id": "180529",
    "label": "Bawang Tirto Mulyo",
    "value": "Bawang Tirto Mulyo"
  },
  {
    "id": "1805292010",
    "district_id": "180529",
    "label": "Karya Murni Jaya",
    "value": "Karya Murni Jaya"
  },
  {
    "id": "1805302001",
    "district_id": "180530",
    "label": "Lebuh Dalam",
    "value": "Lebuh Dalam"
  },
  {
    "id": "1805302002",
    "district_id": "180530",
    "label": "Menggala",
    "value": "Menggala"
  },
  {
    "id": "1805302003",
    "district_id": "180530",
    "label": "Lingai",
    "value": "Lingai"
  },
  {
    "id": "1805302004",
    "district_id": "180530",
    "label": "Kibang Pacing",
    "value": "Kibang Pacing"
  },
  {
    "id": "1805302005",
    "district_id": "180530",
    "label": "Sungai Luar",
    "value": "Sungai Luar"
  },
  {
    "id": "1805302006",
    "district_id": "180530",
    "label": "Kahuripan Dalam",
    "value": "Kahuripan Dalam"
  },
  {
    "id": "1805302007",
    "district_id": "180530",
    "label": "Cempaka Dalem",
    "value": "Cempaka Dalem"
  },
  {
    "id": "1805302008",
    "district_id": "180530",
    "label": "Bedarou Indah",
    "value": "Bedarou Indah"
  },
  {
    "id": "1805302009",
    "district_id": "180530",
    "label": "Tri Makmur Jaya",
    "value": "Tri Makmur Jaya"
  },
  {
    "id": "1805302010",
    "district_id": "180530",
    "label": "Cempaka Jaya",
    "value": "Cempaka Jaya"
  },
  {
    "id": "1806011001",
    "district_id": "180601",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "1806011002",
    "district_id": "180601",
    "label": "Pasar Madang",
    "value": "Pasar Madang"
  },
  {
    "id": "1806011003",
    "district_id": "180601",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1806012016",
    "district_id": "180601",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1806012019",
    "district_id": "180601",
    "label": "Pananggungan",
    "value": "Pananggungan"
  },
  {
    "id": "1806012020",
    "district_id": "180601",
    "label": "Terdana",
    "value": "Terdana"
  },
  {
    "id": "1806012021",
    "district_id": "180601",
    "label": "Kelungu",
    "value": "Kelungu"
  },
  {
    "id": "1806012022",
    "district_id": "180601",
    "label": "Pardasuka",
    "value": "Pardasuka"
  },
  {
    "id": "1806012023",
    "district_id": "180601",
    "label": "Teratas",
    "value": "Teratas"
  },
  {
    "id": "1806012024",
    "district_id": "180601",
    "label": "Kusa",
    "value": "Kusa"
  },
  {
    "id": "1806012025",
    "district_id": "180601",
    "label": "Terbaya",
    "value": "Terbaya"
  },
  {
    "id": "1806012026",
    "district_id": "180601",
    "label": "Kedamaian",
    "value": "Kedamaian"
  },
  {
    "id": "1806012027",
    "district_id": "180601",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1806012039",
    "district_id": "180601",
    "label": "Kota Batu",
    "value": "Kota Batu"
  },
  {
    "id": "1806012040",
    "district_id": "180601",
    "label": "Campang Tiga",
    "value": "Campang Tiga"
  },
  {
    "id": "1806012041",
    "district_id": "180601",
    "label": "Benteng Jaya",
    "value": "Benteng Jaya"
  },
  {
    "id": "1806022012",
    "district_id": "180602",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "1806022015",
    "district_id": "180602",
    "label": "Kejayaan",
    "value": "Kejayaan"
  },
  {
    "id": "1806022016",
    "district_id": "180602",
    "label": "Suka Bumi",
    "value": "Suka Bumi"
  },
  {
    "id": "1806022017",
    "district_id": "180602",
    "label": "Suka Negeri Jaya",
    "value": "Suka Negeri Jaya"
  },
  {
    "id": "1806022018",
    "district_id": "180602",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1806022019",
    "district_id": "180602",
    "label": "Singosari",
    "value": "Singosari"
  },
  {
    "id": "1806022020",
    "district_id": "180602",
    "label": "Sinar Banten",
    "value": "Sinar Banten"
  },
  {
    "id": "1806022021",
    "district_id": "180602",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1806022022",
    "district_id": "180602",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "1806022023",
    "district_id": "180602",
    "label": "Talang Padang",
    "value": "Talang Padang"
  },
  {
    "id": "1806022024",
    "district_id": "180602",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1806022025",
    "district_id": "180602",
    "label": "Suka Bandung",
    "value": "Suka Bandung"
  },
  {
    "id": "1806022026",
    "district_id": "180602",
    "label": "Suka Merindu",
    "value": "Suka Merindu"
  },
  {
    "id": "1806022027",
    "district_id": "180602",
    "label": "Sinar Semendo",
    "value": "Sinar Semendo"
  },
  {
    "id": "1806022028",
    "district_id": "180602",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1806022029",
    "district_id": "180602",
    "label": "Sinar Petir",
    "value": "Sinar Petir"
  },
  {
    "id": "1806022030",
    "district_id": "180602",
    "label": "Way Halom",
    "value": "Way Halom"
  },
  {
    "id": "1806022031",
    "district_id": "180602",
    "label": "Talang Sepuh",
    "value": "Talang Sepuh"
  },
  {
    "id": "1806022032",
    "district_id": "180602",
    "label": "Sinar Harapan",
    "value": "Sinar Harapan"
  },
  {
    "id": "1806022033",
    "district_id": "180602",
    "label": "Sinar Betung",
    "value": "Sinar Betung"
  },
  {
    "id": "1806032001",
    "district_id": "180603",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1806032002",
    "district_id": "180603",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "1806032003",
    "district_id": "180603",
    "label": "Dadirejo",
    "value": "Dadirejo"
  },
  {
    "id": "1806032004",
    "district_id": "180603",
    "label": "Banyu Urip",
    "value": "Banyu Urip"
  },
  {
    "id": "1806032005",
    "district_id": "180603",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "1806032006",
    "district_id": "180603",
    "label": "Soponyono",
    "value": "Soponyono"
  },
  {
    "id": "1806032007",
    "district_id": "180603",
    "label": "Bandar Kejadian",
    "value": "Bandar Kejadian"
  },
  {
    "id": "1806032008",
    "district_id": "180603",
    "label": "Way Panas",
    "value": "Way Panas"
  },
  {
    "id": "1806032009",
    "district_id": "180603",
    "label": "Sinar Saudara",
    "value": "Sinar Saudara"
  },
  {
    "id": "1806032010",
    "district_id": "180603",
    "label": "Sridadi",
    "value": "Sridadi"
  },
  {
    "id": "1806032011",
    "district_id": "180603",
    "label": "Lakaran",
    "value": "Lakaran"
  },
  {
    "id": "1806032012",
    "district_id": "180603",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "1806032013",
    "district_id": "180603",
    "label": "Pardasuka",
    "value": "Pardasuka"
  },
  {
    "id": "1806032014",
    "district_id": "180603",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1806032015",
    "district_id": "180603",
    "label": "Padang Manis",
    "value": "Padang Manis"
  },
  {
    "id": "1806032016",
    "district_id": "180603",
    "label": "Kejadian",
    "value": "Kejadian"
  },
  {
    "id": "1806032017",
    "district_id": "180603",
    "label": "Dadisari",
    "value": "Dadisari"
  },
  {
    "id": "1806032018",
    "district_id": "180603",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "1806032019",
    "district_id": "180603",
    "label": "Banjar Negara",
    "value": "Banjar Negara"
  },
  {
    "id": "1806032020",
    "district_id": "180603",
    "label": "Pekon Balak",
    "value": "Pekon Balak"
  },
  {
    "id": "1806032021",
    "district_id": "180603",
    "label": "Kunyayan",
    "value": "Kunyayan"
  },
  {
    "id": "1806032022",
    "district_id": "180603",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1806032023",
    "district_id": "180603",
    "label": "Negeri Ngarip",
    "value": "Negeri Ngarip"
  },
  {
    "id": "1806032034",
    "district_id": "180603",
    "label": "Dadimulyo",
    "value": "Dadimulyo"
  },
  {
    "id": "1806032035",
    "district_id": "180603",
    "label": "Sampang Turus",
    "value": "Sampang Turus"
  },
  {
    "id": "1806032036",
    "district_id": "180603",
    "label": "Sri Melati",
    "value": "Sri Melati"
  },
  {
    "id": "1806032037",
    "district_id": "180603",
    "label": "Way Liwok",
    "value": "Way Liwok"
  },
  {
    "id": "1806032038",
    "district_id": "180603",
    "label": "Sumur Tujuh",
    "value": "Sumur Tujuh"
  },
  {
    "id": "1806042001",
    "district_id": "180604",
    "label": "Talang Beringin",
    "value": "Talang Beringin"
  },
  {
    "id": "1806042002",
    "district_id": "180604",
    "label": "Gunung Megang",
    "value": "Gunung Megang"
  },
  {
    "id": "1806042003",
    "district_id": "180604",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1806042004",
    "district_id": "180604",
    "label": "Tanjung Begelung",
    "value": "Tanjung Begelung"
  },
  {
    "id": "1806042005",
    "district_id": "180604",
    "label": "Sinar Mulyo",
    "value": "Sinar Mulyo"
  },
  {
    "id": "1806042006",
    "district_id": "180604",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "1806042007",
    "district_id": "180604",
    "label": "Gedung Agung",
    "value": "Gedung Agung"
  },
  {
    "id": "1806042008",
    "district_id": "180604",
    "label": "Penantian",
    "value": "Penantian"
  },
  {
    "id": "1806042009",
    "district_id": "180604",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "1806042010",
    "district_id": "180604",
    "label": "Tekad",
    "value": "Tekad"
  },
  {
    "id": "1806042011",
    "district_id": "180604",
    "label": "Pulau Panggung",
    "value": "Pulau Panggung"
  },
  {
    "id": "1806042012",
    "district_id": "180604",
    "label": "Gunung Meraksa",
    "value": "Gunung Meraksa"
  },
  {
    "id": "1806042013",
    "district_id": "180604",
    "label": "Way Ilahan",
    "value": "Way Ilahan"
  },
  {
    "id": "1806042014",
    "district_id": "180604",
    "label": "Batu Bedil",
    "value": "Batu Bedil"
  },
  {
    "id": "1806042015",
    "district_id": "180604",
    "label": "Air Bakoman",
    "value": "Air Bakoman"
  },
  {
    "id": "1806042025",
    "district_id": "180604",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "1806042026",
    "district_id": "180604",
    "label": "Sindang Marga",
    "value": "Sindang Marga"
  },
  {
    "id": "1806042027",
    "district_id": "180604",
    "label": "Talang Jawa",
    "value": "Talang Jawa"
  },
  {
    "id": "1806042028",
    "district_id": "180604",
    "label": "Srimanganten",
    "value": "Srimanganten"
  },
  {
    "id": "1806042029",
    "district_id": "180604",
    "label": "Tanjung Gunung",
    "value": "Tanjung Gunung"
  },
  {
    "id": "1806042030",
    "district_id": "180604",
    "label": "Sinar Mancak",
    "value": "Sinar Mancak"
  },
  {
    "id": "1806092001",
    "district_id": "180609",
    "label": "Karang Buah",
    "value": "Karang Buah"
  },
  {
    "id": "1806092002",
    "district_id": "180609",
    "label": "Sawang Balak",
    "value": "Sawang Balak"
  },
  {
    "id": "1806092003",
    "district_id": "180609",
    "label": "Way Rilau",
    "value": "Way Rilau"
  },
  {
    "id": "1806092004",
    "district_id": "180609",
    "label": "Tanjung Raja",
    "value": "Tanjung Raja"
  },
  {
    "id": "1806092005",
    "district_id": "180609",
    "label": "Tengor",
    "value": "Tengor"
  },
  {
    "id": "1806092006",
    "district_id": "180609",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1806092007",
    "district_id": "180609",
    "label": "Kejadian Lom",
    "value": "Kejadian Lom"
  },
  {
    "id": "1806092008",
    "district_id": "180609",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1806092009",
    "district_id": "180609",
    "label": "Banjar Negeri",
    "value": "Banjar Negeri"
  },
  {
    "id": "1806092010",
    "district_id": "180609",
    "label": "Gedung",
    "value": "Gedung"
  },
  {
    "id": "1806092011",
    "district_id": "180609",
    "label": "Suka Padang",
    "value": "Suka Padang"
  },
  {
    "id": "1806092012",
    "district_id": "180609",
    "label": "Kacamarga",
    "value": "Kacamarga"
  },
  {
    "id": "1806092013",
    "district_id": "180609",
    "label": "Pampangan",
    "value": "Pampangan"
  },
  {
    "id": "1806092014",
    "district_id": "180609",
    "label": "Banjar Manis",
    "value": "Banjar Manis"
  },
  {
    "id": "1806092015",
    "district_id": "180609",
    "label": "Tanjung Betuah",
    "value": "Tanjung Betuah"
  },
  {
    "id": "1806092016",
    "district_id": "180609",
    "label": "Putih Doh",
    "value": "Putih Doh"
  },
  {
    "id": "1806092017",
    "district_id": "180609",
    "label": "Pekondoh",
    "value": "Pekondoh"
  },
  {
    "id": "1806092028",
    "district_id": "180609",
    "label": "Kubulangka",
    "value": "Kubulangka"
  },
  {
    "id": "1806092029",
    "district_id": "180609",
    "label": "Kutakakhang",
    "value": "Kutakakhang"
  },
  {
    "id": "1806092030",
    "district_id": "180609",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1806112001",
    "district_id": "180611",
    "label": "Rantau Tijang",
    "value": "Rantau Tijang"
  },
  {
    "id": "1806112002",
    "district_id": "180611",
    "label": "Tiuh Memon",
    "value": "Tiuh Memon"
  },
  {
    "id": "1806112003",
    "district_id": "180611",
    "label": "Banjar Agung Udik",
    "value": "Banjar Agung Udik"
  },
  {
    "id": "1806112004",
    "district_id": "180611",
    "label": "Tanjung Heran",
    "value": "Tanjung Heran"
  },
  {
    "id": "1806112005",
    "district_id": "180611",
    "label": "Sumanda",
    "value": "Sumanda"
  },
  {
    "id": "1806112006",
    "district_id": "180611",
    "label": "Campang Way Handak",
    "value": "Campang Way Handak"
  },
  {
    "id": "1806112007",
    "district_id": "180611",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "1806112008",
    "district_id": "180611",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "1806112009",
    "district_id": "180611",
    "label": "Binjai Wangi",
    "value": "Binjai Wangi"
  },
  {
    "id": "1806112010",
    "district_id": "180611",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1806112011",
    "district_id": "180611",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1806112012",
    "district_id": "180611",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "1806112013",
    "district_id": "180611",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1806112014",
    "district_id": "180611",
    "label": "Sinar Agung",
    "value": "Sinar Agung"
  },
  {
    "id": "1806112015",
    "district_id": "180611",
    "label": "Tangkit Serdang",
    "value": "Tangkit Serdang"
  },
  {
    "id": "1806112016",
    "district_id": "180611",
    "label": "Gunung Kasih",
    "value": "Gunung Kasih"
  },
  {
    "id": "1806112017",
    "district_id": "180611",
    "label": "Way Jaha",
    "value": "Way Jaha"
  },
  {
    "id": "1806112018",
    "district_id": "180611",
    "label": "Banjar Agung Ilir",
    "value": "Banjar Agung Ilir"
  },
  {
    "id": "1806112019",
    "district_id": "180611",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "1806112020",
    "district_id": "180611",
    "label": "Way Piring",
    "value": "Way Piring"
  },
  {
    "id": "1806112021",
    "district_id": "180611",
    "label": "Pungkut",
    "value": "Pungkut"
  },
  {
    "id": "1806112022",
    "district_id": "180611",
    "label": "Gunung Tiga",
    "value": "Gunung Tiga"
  },
  {
    "id": "1806112023",
    "district_id": "180611",
    "label": "Way Manak",
    "value": "Way Manak"
  },
  {
    "id": "1806112024",
    "district_id": "180611",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1806112025",
    "district_id": "180611",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "1806112026",
    "district_id": "180611",
    "label": "Kayuhubi",
    "value": "Kayuhubi"
  },
  {
    "id": "1806112027",
    "district_id": "180611",
    "label": "Talang Lebar",
    "value": "Talang Lebar"
  },
  {
    "id": "1806122001",
    "district_id": "180612",
    "label": "Tugu Papak",
    "value": "Tugu Papak"
  },
  {
    "id": "1806122002",
    "district_id": "180612",
    "label": "Kacapura",
    "value": "Kacapura"
  },
  {
    "id": "1806122003",
    "district_id": "180612",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1806122004",
    "district_id": "180612",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1806122005",
    "district_id": "180612",
    "label": "Tugu Rejo",
    "value": "Tugu Rejo"
  },
  {
    "id": "1806122006",
    "district_id": "180612",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1806122007",
    "district_id": "180612",
    "label": "Garut",
    "value": "Garut"
  },
  {
    "id": "1806122008",
    "district_id": "180612",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1806122009",
    "district_id": "180612",
    "label": "Kanoman",
    "value": "Kanoman"
  },
  {
    "id": "1806122010",
    "district_id": "180612",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "1806122011",
    "district_id": "180612",
    "label": "Sudimoro Bangun",
    "value": "Sudimoro Bangun"
  },
  {
    "id": "1806122012",
    "district_id": "180612",
    "label": "Sri Purnomo",
    "value": "Sri Purnomo"
  },
  {
    "id": "1806122013",
    "district_id": "180612",
    "label": "Sri Kuncoro",
    "value": "Sri Kuncoro"
  },
  {
    "id": "1806122014",
    "district_id": "180612",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1806122015",
    "district_id": "180612",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1806122016",
    "district_id": "180612",
    "label": "Tulung Asahan",
    "value": "Tulung Asahan"
  },
  {
    "id": "1806122017",
    "district_id": "180612",
    "label": "Sri Katon",
    "value": "Sri Katon"
  },
  {
    "id": "1806122018",
    "district_id": "180612",
    "label": "Pardawaras",
    "value": "Pardawaras"
  },
  {
    "id": "1806122019",
    "district_id": "180612",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "1806122020",
    "district_id": "180612",
    "label": "Way Kerap",
    "value": "Way Kerap"
  },
  {
    "id": "1806122021",
    "district_id": "180612",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "1806122022",
    "district_id": "180612",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1806132001",
    "district_id": "180613",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "1806132002",
    "district_id": "180613",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1806132003",
    "district_id": "180613",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1806132004",
    "district_id": "180613",
    "label": "Tegal Binangun",
    "value": "Tegal Binangun"
  },
  {
    "id": "1806132005",
    "district_id": "180613",
    "label": "Argopeni",
    "value": "Argopeni"
  },
  {
    "id": "1806132006",
    "district_id": "180613",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "1806132007",
    "district_id": "180613",
    "label": "Margoyoso",
    "value": "Margoyoso"
  },
  {
    "id": "1806132008",
    "district_id": "180613",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "1806132009",
    "district_id": "180613",
    "label": "Simpang Kanan",
    "value": "Simpang Kanan"
  },
  {
    "id": "1806132010",
    "district_id": "180613",
    "label": "Wonoharjo",
    "value": "Wonoharjo"
  },
  {
    "id": "1806132011",
    "district_id": "180613",
    "label": "Sumber Mulyo",
    "value": "Sumber Mulyo"
  },
  {
    "id": "1806132012",
    "district_id": "180613",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "1806132013",
    "district_id": "180613",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1806152001",
    "district_id": "180615",
    "label": "Datarajan",
    "value": "Datarajan"
  },
  {
    "id": "1806152002",
    "district_id": "180615",
    "label": "Gunung Tiga",
    "value": "Gunung Tiga"
  },
  {
    "id": "1806152003",
    "district_id": "180615",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1806152004",
    "district_id": "180615",
    "label": "Pagar Alam Ulu Belu",
    "value": "Pagar Alam Ulu Belu"
  },
  {
    "id": "1806152005",
    "district_id": "180615",
    "label": "Muara Dua Ulu Belu",
    "value": "Muara Dua Ulu Belu"
  },
  {
    "id": "1806152006",
    "district_id": "180615",
    "label": "Ngarip",
    "value": "Ngarip"
  },
  {
    "id": "1806152007",
    "district_id": "180615",
    "label": "Penantian Ulu Belu",
    "value": "Penantian Ulu Belu"
  },
  {
    "id": "1806152008",
    "district_id": "180615",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1806152009",
    "district_id": "180615",
    "label": "Ulu Semong",
    "value": "Ulu Semong"
  },
  {
    "id": "1806152010",
    "district_id": "180615",
    "label": "Sirna Galih",
    "value": "Sirna Galih"
  },
  {
    "id": "1806152011",
    "district_id": "180615",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1806152012",
    "district_id": "180615",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1806152013",
    "district_id": "180615",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "1806152014",
    "district_id": "180615",
    "label": "Sinar Banten",
    "value": "Sinar Banten"
  },
  {
    "id": "1806152015",
    "district_id": "180615",
    "label": "Air Abang",
    "value": "Air Abang"
  },
  {
    "id": "1806152016",
    "district_id": "180615",
    "label": "Petay Kayu",
    "value": "Petay Kayu"
  },
  {
    "id": "1806162001",
    "district_id": "180616",
    "label": "Tampang",
    "value": "Tampang"
  },
  {
    "id": "1806162002",
    "district_id": "180616",
    "label": "Kaur Gading",
    "value": "Kaur Gading"
  },
  {
    "id": "1806162003",
    "district_id": "180616",
    "label": "Tirom",
    "value": "Tirom"
  },
  {
    "id": "1806162004",
    "district_id": "180616",
    "label": "Way Nipah",
    "value": "Way Nipah"
  },
  {
    "id": "1806162005",
    "district_id": "180616",
    "label": "Guring",
    "value": "Guring"
  },
  {
    "id": "1806162006",
    "district_id": "180616",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "1806162007",
    "district_id": "180616",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "1806162009",
    "district_id": "180616",
    "label": "Teluk Brak",
    "value": "Teluk Brak"
  },
  {
    "id": "1806162010",
    "district_id": "180616",
    "label": "Karang Brak",
    "value": "Karang Brak"
  },
  {
    "id": "1806162011",
    "district_id": "180616",
    "label": "Pesangun",
    "value": "Pesangun"
  },
  {
    "id": "1806162012",
    "district_id": "180616",
    "label": "Way Asahan",
    "value": "Way Asahan"
  },
  {
    "id": "1806162013",
    "district_id": "180616",
    "label": "Tampang Muda",
    "value": "Tampang Muda"
  },
  {
    "id": "1806162014",
    "district_id": "180616",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1806162015",
    "district_id": "180616",
    "label": "Martanda",
    "value": "Martanda"
  },
  {
    "id": "1806172001",
    "district_id": "180617",
    "label": "Negeri Kelumbayan",
    "value": "Negeri Kelumbayan"
  },
  {
    "id": "1806172002",
    "district_id": "180617",
    "label": "Pekon Susuk",
    "value": "Pekon Susuk"
  },
  {
    "id": "1806172003",
    "district_id": "180617",
    "label": "Napal",
    "value": "Napal"
  },
  {
    "id": "1806172005",
    "district_id": "180617",
    "label": "Pekon Unggak",
    "value": "Pekon Unggak"
  },
  {
    "id": "1806172006",
    "district_id": "180617",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1806172009",
    "district_id": "180617",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "1806172010",
    "district_id": "180617",
    "label": "Umbar",
    "value": "Umbar"
  },
  {
    "id": "1806172012",
    "district_id": "180617",
    "label": "Kiluan Negeri",
    "value": "Kiluan Negeri"
  },
  {
    "id": "1806182001",
    "district_id": "180618",
    "label": "Belu",
    "value": "Belu"
  },
  {
    "id": "1806182002",
    "district_id": "180618",
    "label": "Negara Batin",
    "value": "Negara Batin"
  },
  {
    "id": "1806182003",
    "district_id": "180618",
    "label": "Banjar Masin",
    "value": "Banjar Masin"
  },
  {
    "id": "1806182004",
    "district_id": "180618",
    "label": "Kanyangan",
    "value": "Kanyangan"
  },
  {
    "id": "1806182005",
    "district_id": "180618",
    "label": "Kandang Besi",
    "value": "Kandang Besi"
  },
  {
    "id": "1806182006",
    "district_id": "180618",
    "label": "Tebak Bunuk",
    "value": "Tebak Bunuk"
  },
  {
    "id": "1806182007",
    "district_id": "180618",
    "label": "Way Gelang",
    "value": "Way Gelang"
  },
  {
    "id": "1806182008",
    "district_id": "180618",
    "label": "Tala Gening",
    "value": "Tala Gening"
  },
  {
    "id": "1806182009",
    "district_id": "180618",
    "label": "Gedung Jambu",
    "value": "Gedung Jambu"
  },
  {
    "id": "1806182010",
    "district_id": "180618",
    "label": "Maja",
    "value": "Maja"
  },
  {
    "id": "1806182011",
    "district_id": "180618",
    "label": "Pulau Benawang",
    "value": "Pulau Benawang"
  },
  {
    "id": "1806182012",
    "district_id": "180618",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "1806182013",
    "district_id": "180618",
    "label": "Kesugihan",
    "value": "Kesugihan"
  },
  {
    "id": "1806182014",
    "district_id": "180618",
    "label": "Pejajaran",
    "value": "Pejajaran"
  },
  {
    "id": "1806182015",
    "district_id": "180618",
    "label": "Kalimiring",
    "value": "Kalimiring"
  },
  {
    "id": "1806182016",
    "district_id": "180618",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1806192001",
    "district_id": "180619",
    "label": "Batu Kramat",
    "value": "Batu Kramat"
  },
  {
    "id": "1806192002",
    "district_id": "180619",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1806192003",
    "district_id": "180619",
    "label": "Umbul Buah",
    "value": "Umbul Buah"
  },
  {
    "id": "1806192004",
    "district_id": "180619",
    "label": "Menggala",
    "value": "Menggala"
  },
  {
    "id": "1806192005",
    "district_id": "180619",
    "label": "Mulang Maya",
    "value": "Mulang Maya"
  },
  {
    "id": "1806192006",
    "district_id": "180619",
    "label": "Sukabanjar",
    "value": "Sukabanjar"
  },
  {
    "id": "1806192007",
    "district_id": "180619",
    "label": "Tanjung Anom",
    "value": "Tanjung Anom"
  },
  {
    "id": "1806192008",
    "district_id": "180619",
    "label": "Kagungan",
    "value": "Kagungan"
  },
  {
    "id": "1806192009",
    "district_id": "180619",
    "label": "Kerta",
    "value": "Kerta"
  },
  {
    "id": "1806192010",
    "district_id": "180619",
    "label": "Teba",
    "value": "Teba"
  },
  {
    "id": "1806192011",
    "district_id": "180619",
    "label": "Talang Rejo",
    "value": "Talang Rejo"
  },
  {
    "id": "1806192012",
    "district_id": "180619",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1806202001",
    "district_id": "180620",
    "label": "Banjar Manis",
    "value": "Banjar Manis"
  },
  {
    "id": "1806202002",
    "district_id": "180620",
    "label": "Campang",
    "value": "Campang"
  },
  {
    "id": "1806202003",
    "district_id": "180620",
    "label": "Kuta Dalom",
    "value": "Kuta Dalom"
  },
  {
    "id": "1806202004",
    "district_id": "180620",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1806202005",
    "district_id": "180620",
    "label": "Gisting Bawah",
    "value": "Gisting Bawah"
  },
  {
    "id": "1806202006",
    "district_id": "180620",
    "label": "Gisting Atas",
    "value": "Gisting Atas"
  },
  {
    "id": "1806202007",
    "district_id": "180620",
    "label": "Sidokaton",
    "value": "Sidokaton"
  },
  {
    "id": "1806202008",
    "district_id": "180620",
    "label": "Landbaw",
    "value": "Landbaw"
  },
  {
    "id": "1806202009",
    "district_id": "180620",
    "label": "Gisting Permai",
    "value": "Gisting Permai"
  },
  {
    "id": "1806212001",
    "district_id": "180621",
    "label": "Banjar Negeri",
    "value": "Banjar Negeri"
  },
  {
    "id": "1806212002",
    "district_id": "180621",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1806212003",
    "district_id": "180621",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1806212004",
    "district_id": "180621",
    "label": "Kedaloman",
    "value": "Kedaloman"
  },
  {
    "id": "1806212005",
    "district_id": "180621",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1806212006",
    "district_id": "180621",
    "label": "Sukamernah",
    "value": "Sukamernah"
  },
  {
    "id": "1806212007",
    "district_id": "180621",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "1806212008",
    "district_id": "180621",
    "label": "Pariaman",
    "value": "Pariaman"
  },
  {
    "id": "1806212009",
    "district_id": "180621",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "1806212010",
    "district_id": "180621",
    "label": "Way Halom",
    "value": "Way Halom"
  },
  {
    "id": "1806212011",
    "district_id": "180621",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "1806212012",
    "district_id": "180621",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "1806242001",
    "district_id": "180624",
    "label": "Badak",
    "value": "Badak"
  },
  {
    "id": "1806242002",
    "district_id": "180624",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1806242003",
    "district_id": "180624",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "1806242004",
    "district_id": "180624",
    "label": "Tegineneng",
    "value": "Tegineneng"
  },
  {
    "id": "1806242005",
    "district_id": "180624",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "1806242006",
    "district_id": "180624",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1806242007",
    "district_id": "180624",
    "label": "Pekon Ampai",
    "value": "Pekon Ampai"
  },
  {
    "id": "1806242008",
    "district_id": "180624",
    "label": "Pariaman",
    "value": "Pariaman"
  },
  {
    "id": "1806242009",
    "district_id": "180624",
    "label": "Atar Brak",
    "value": "Atar Brak"
  },
  {
    "id": "1806242010",
    "district_id": "180624",
    "label": "Tanjung Siom",
    "value": "Tanjung Siom"
  },
  {
    "id": "1806242011",
    "district_id": "180624",
    "label": "Tanjung Jaya",
    "value": "Tanjung Jaya"
  },
  {
    "id": "1806252001",
    "district_id": "180625",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1806252002",
    "district_id": "180625",
    "label": "Bandar Sukabumi",
    "value": "Bandar Sukabumi"
  },
  {
    "id": "1806252003",
    "district_id": "180625",
    "label": "Sanggi",
    "value": "Sanggi"
  },
  {
    "id": "1806252004",
    "district_id": "180625",
    "label": "Rajabasa",
    "value": "Rajabasa"
  },
  {
    "id": "1806252005",
    "district_id": "180625",
    "label": "Banding",
    "value": "Banding"
  },
  {
    "id": "1806252006",
    "district_id": "180625",
    "label": "Gunung Doh",
    "value": "Gunung Doh"
  },
  {
    "id": "1806252007",
    "district_id": "180625",
    "label": "Simpang Bayur",
    "value": "Simpang Bayur"
  },
  {
    "id": "1806252008",
    "district_id": "180625",
    "label": "Atar Lebar",
    "value": "Atar Lebar"
  },
  {
    "id": "1806252009",
    "district_id": "180625",
    "label": "Sinar Bangun",
    "value": "Sinar Bangun"
  },
  {
    "id": "1806252010",
    "district_id": "180625",
    "label": "Sanggi Unggak",
    "value": "Sanggi Unggak"
  },
  {
    "id": "1806252011",
    "district_id": "180625",
    "label": "Tulung Sari",
    "value": "Tulung Sari"
  },
  {
    "id": "1806262001",
    "district_id": "180626",
    "label": "Way Harong",
    "value": "Way Harong"
  },
  {
    "id": "1806262002",
    "district_id": "180626",
    "label": "Air Kubang",
    "value": "Air Kubang"
  },
  {
    "id": "1806262003",
    "district_id": "180626",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "1806262004",
    "district_id": "180626",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1806262005",
    "district_id": "180626",
    "label": "Air Naningan",
    "value": "Air Naningan"
  },
  {
    "id": "1806262006",
    "district_id": "180626",
    "label": "Datar Lebuay",
    "value": "Datar Lebuay"
  },
  {
    "id": "1806262007",
    "district_id": "180626",
    "label": "Sinar Jawa",
    "value": "Sinar Jawa"
  },
  {
    "id": "1806262008",
    "district_id": "180626",
    "label": "Batu Tegi",
    "value": "Batu Tegi"
  },
  {
    "id": "1806262009",
    "district_id": "180626",
    "label": "Sinar Sekampung",
    "value": "Sinar Sekampung"
  },
  {
    "id": "1806262010",
    "district_id": "180626",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "1806272001",
    "district_id": "180627",
    "label": "Sukamara",
    "value": "Sukamara"
  },
  {
    "id": "1806272002",
    "district_id": "180627",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "1806272003",
    "district_id": "180627",
    "label": "Banjar Masin",
    "value": "Banjar Masin"
  },
  {
    "id": "1806272004",
    "district_id": "180627",
    "label": "Suka Agung Barat",
    "value": "Suka Agung Barat"
  },
  {
    "id": "1806272005",
    "district_id": "180627",
    "label": "Suka Agung",
    "value": "Suka Agung"
  },
  {
    "id": "1806272006",
    "district_id": "180627",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1806272007",
    "district_id": "180627",
    "label": "Napal",
    "value": "Napal"
  },
  {
    "id": "1806272008",
    "district_id": "180627",
    "label": "Sinar Petir",
    "value": "Sinar Petir"
  },
  {
    "id": "1806272009",
    "district_id": "180627",
    "label": "Pematang Nebak",
    "value": "Pematang Nebak"
  },
  {
    "id": "1806272010",
    "district_id": "180627",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1806282001",
    "district_id": "180628",
    "label": "Lengkukai",
    "value": "Lengkukai"
  },
  {
    "id": "1806282002",
    "district_id": "180628",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1806282003",
    "district_id": "180628",
    "label": "Merbau",
    "value": "Merbau"
  },
  {
    "id": "1806282004",
    "district_id": "180628",
    "label": "Batu Patah",
    "value": "Batu Patah"
  },
  {
    "id": "1806282005",
    "district_id": "180628",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1806282006",
    "district_id": "180628",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1807012001",
    "district_id": "180701",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "1807012002",
    "district_id": "180701",
    "label": "Pakuan Aji",
    "value": "Pakuan Aji"
  },
  {
    "id": "1807012003",
    "district_id": "180701",
    "label": "Bumi Nabung Udik",
    "value": "Bumi Nabung Udik"
  },
  {
    "id": "1807012004",
    "district_id": "180701",
    "label": "Rajabasa Batanghari",
    "value": "Rajabasa Batanghari"
  },
  {
    "id": "1807012005",
    "district_id": "180701",
    "label": "Negara Nabung",
    "value": "Negara Nabung"
  },
  {
    "id": "1807012006",
    "district_id": "180701",
    "label": "Terbangi Marga",
    "value": "Terbangi Marga"
  },
  {
    "id": "1807012007",
    "district_id": "180701",
    "label": "Mataram Marga",
    "value": "Mataram Marga"
  },
  {
    "id": "1807012008",
    "district_id": "180701",
    "label": "Pasar Sukadana",
    "value": "Pasar Sukadana"
  },
  {
    "id": "1807012009",
    "district_id": "180701",
    "label": "Surabaya Udik",
    "value": "Surabaya Udik"
  },
  {
    "id": "1807012010",
    "district_id": "180701",
    "label": "Rantau Jaya Udik",
    "value": "Rantau Jaya Udik"
  },
  {
    "id": "1807012011",
    "district_id": "180701",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "1807012012",
    "district_id": "180701",
    "label": "Sukadana Ilir",
    "value": "Sukadana Ilir"
  },
  {
    "id": "1807012013",
    "district_id": "180701",
    "label": "Bumi Ayu",
    "value": "Bumi Ayu"
  },
  {
    "id": "1807012014",
    "district_id": "180701",
    "label": "Sukadana Timur",
    "value": "Sukadana Timur"
  },
  {
    "id": "1807012015",
    "district_id": "180701",
    "label": "Rantau Jaya Udik II",
    "value": "Rantau Jaya Udik II"
  },
  {
    "id": "1807012016",
    "district_id": "180701",
    "label": "Putra Aji II",
    "value": "Putra Aji II"
  },
  {
    "id": "1807012017",
    "district_id": "180701",
    "label": "Putra Aji I",
    "value": "Putra Aji I"
  },
  {
    "id": "1807012018",
    "district_id": "180701",
    "label": "Sukadana Selatan",
    "value": "Sukadana Selatan"
  },
  {
    "id": "1807012019",
    "district_id": "180701",
    "label": "Sukadana Jaya",
    "value": "Sukadana Jaya"
  },
  {
    "id": "1807012020",
    "district_id": "180701",
    "label": "Sukadana Tengah",
    "value": "Sukadana Tengah"
  },
  {
    "id": "1807022001",
    "district_id": "180702",
    "label": "Labuhan Maringgai",
    "value": "Labuhan Maringgai"
  },
  {
    "id": "1807022002",
    "district_id": "180702",
    "label": "Muara Gading Mas",
    "value": "Muara Gading Mas"
  },
  {
    "id": "1807022003",
    "district_id": "180702",
    "label": "Sriminosari",
    "value": "Sriminosari"
  },
  {
    "id": "1807022004",
    "district_id": "180702",
    "label": "Bandar Negeri",
    "value": "Bandar Negeri"
  },
  {
    "id": "1807022005",
    "district_id": "180702",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "1807022006",
    "district_id": "180702",
    "label": "Karya Tani",
    "value": "Karya Tani"
  },
  {
    "id": "1807022007",
    "district_id": "180702",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "1807022008",
    "district_id": "180702",
    "label": "Sukorahayu",
    "value": "Sukorahayu"
  },
  {
    "id": "1807022009",
    "district_id": "180702",
    "label": "Srigading",
    "value": "Srigading"
  },
  {
    "id": "1807022010",
    "district_id": "180702",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1807022011",
    "district_id": "180702",
    "label": "Maringgai",
    "value": "Maringgai"
  },
  {
    "id": "1807032001",
    "district_id": "180703",
    "label": "Negara Batin",
    "value": "Negara Batin"
  },
  {
    "id": "1807032002",
    "district_id": "180703",
    "label": "Asahan",
    "value": "Asahan"
  },
  {
    "id": "1807032003",
    "district_id": "180703",
    "label": "Adirejo",
    "value": "Adirejo"
  },
  {
    "id": "1807032004",
    "district_id": "180703",
    "label": "Gunung Mekar",
    "value": "Gunung Mekar"
  },
  {
    "id": "1807032005",
    "district_id": "180703",
    "label": "Gunung Sugih Kecil",
    "value": "Gunung Sugih Kecil"
  },
  {
    "id": "1807032006",
    "district_id": "180703",
    "label": "Pematang Tahalo",
    "value": "Pematang Tahalo"
  },
  {
    "id": "1807032007",
    "district_id": "180703",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "1807032008",
    "district_id": "180703",
    "label": "Negara Saka",
    "value": "Negara Saka"
  },
  {
    "id": "1807032014",
    "district_id": "180703",
    "label": "Betengsari",
    "value": "Betengsari"
  },
  {
    "id": "1807032015",
    "district_id": "180703",
    "label": "Belimbing Sari",
    "value": "Belimbing Sari"
  },
  {
    "id": "1807032016",
    "district_id": "180703",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1807032018",
    "district_id": "180703",
    "label": "Mumbang Jaya",
    "value": "Mumbang Jaya"
  },
  {
    "id": "1807032019",
    "district_id": "180703",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1807032020",
    "district_id": "180703",
    "label": "Adi Luhur",
    "value": "Adi Luhur"
  },
  {
    "id": "1807032021",
    "district_id": "180703",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "1807042001",
    "district_id": "180704",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "1807042002",
    "district_id": "180704",
    "label": "Adirejo",
    "value": "Adirejo"
  },
  {
    "id": "1807042003",
    "district_id": "180704",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1807042004",
    "district_id": "180704",
    "label": "Gondangrejo",
    "value": "Gondangrejo"
  },
  {
    "id": "1807042005",
    "district_id": "180704",
    "label": "Siraman",
    "value": "Siraman"
  },
  {
    "id": "1807042006",
    "district_id": "180704",
    "label": "Tulusrejo",
    "value": "Tulusrejo"
  },
  {
    "id": "1807042007",
    "district_id": "180704",
    "label": "Jojog",
    "value": "Jojog"
  },
  {
    "id": "1807042008",
    "district_id": "180704",
    "label": "Ganti Warno",
    "value": "Ganti Warno"
  },
  {
    "id": "1807042009",
    "district_id": "180704",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "1807042010",
    "district_id": "180704",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1807042011",
    "district_id": "180704",
    "label": "Gantimulyo",
    "value": "Gantimulyo"
  },
  {
    "id": "1807042012",
    "district_id": "180704",
    "label": "Adijaya",
    "value": "Adijaya"
  },
  {
    "id": "1807052001",
    "district_id": "180705",
    "label": "Sumber Gede",
    "value": "Sumber Gede"
  },
  {
    "id": "1807052002",
    "district_id": "180705",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "1807052003",
    "district_id": "180705",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1807052004",
    "district_id": "180705",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "1807052005",
    "district_id": "180705",
    "label": "Girikarto",
    "value": "Girikarto"
  },
  {
    "id": "1807052006",
    "district_id": "180705",
    "label": "Hargomulyo",
    "value": "Hargomulyo"
  },
  {
    "id": "1807052007",
    "district_id": "180705",
    "label": "Wonokarto",
    "value": "Wonokarto"
  },
  {
    "id": "1807052008",
    "district_id": "180705",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "1807052009",
    "district_id": "180705",
    "label": "Giriklopomulyo",
    "value": "Giriklopomulyo"
  },
  {
    "id": "1807052010",
    "district_id": "180705",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1807052011",
    "district_id": "180705",
    "label": "Sambikarto",
    "value": "Sambikarto"
  },
  {
    "id": "1807052012",
    "district_id": "180705",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1807052013",
    "district_id": "180705",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "1807052014",
    "district_id": "180705",
    "label": "Mekar Mulya",
    "value": "Mekar Mulya"
  },
  {
    "id": "1807052015",
    "district_id": "180705",
    "label": "Jadi Mulyo",
    "value": "Jadi Mulyo"
  },
  {
    "id": "1807052016",
    "district_id": "180705",
    "label": "Mekar Mukti",
    "value": "Mekar Mukti"
  },
  {
    "id": "1807052017",
    "district_id": "180705",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1807062001",
    "district_id": "180706",
    "label": "Banarjoyo",
    "value": "Banarjoyo"
  },
  {
    "id": "1807062002",
    "district_id": "180706",
    "label": "Buana Sakti",
    "value": "Buana Sakti"
  },
  {
    "id": "1807062003",
    "district_id": "180706",
    "label": "Balekencono",
    "value": "Balekencono"
  },
  {
    "id": "1807062004",
    "district_id": "180706",
    "label": "Rejo Agung",
    "value": "Rejo Agung"
  },
  {
    "id": "1807062005",
    "district_id": "180706",
    "label": "Adiwarno",
    "value": "Adiwarno"
  },
  {
    "id": "1807062006",
    "district_id": "180706",
    "label": "Telogorejo",
    "value": "Telogorejo"
  },
  {
    "id": "1807062007",
    "district_id": "180706",
    "label": "Nampirejo",
    "value": "Nampirejo"
  },
  {
    "id": "1807062008",
    "district_id": "180706",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "1807062009",
    "district_id": "180706",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "1807062010",
    "district_id": "180706",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "1807062011",
    "district_id": "180706",
    "label": "Batangharjo",
    "value": "Batangharjo"
  },
  {
    "id": "1807062012",
    "district_id": "180706",
    "label": "Bumi Harjo",
    "value": "Bumi Harjo"
  },
  {
    "id": "1807062013",
    "district_id": "180706",
    "label": "Bumi Mas",
    "value": "Bumi Mas"
  },
  {
    "id": "1807062014",
    "district_id": "180706",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "1807062015",
    "district_id": "180706",
    "label": "Sri Basuki",
    "value": "Sri Basuki"
  },
  {
    "id": "1807062016",
    "district_id": "180706",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1807062017",
    "district_id": "180706",
    "label": "Purwodadi Mekar",
    "value": "Purwodadi Mekar"
  },
  {
    "id": "1807072001",
    "district_id": "180707",
    "label": "Braja Sakti",
    "value": "Braja Sakti"
  },
  {
    "id": "1807072002",
    "district_id": "180707",
    "label": "Braja Fajar",
    "value": "Braja Fajar"
  },
  {
    "id": "1807072003",
    "district_id": "180707",
    "label": "Braja Emas",
    "value": "Braja Emas"
  },
  {
    "id": "1807072004",
    "district_id": "180707",
    "label": "Braja Dewa",
    "value": "Braja Dewa"
  },
  {
    "id": "1807072005",
    "district_id": "180707",
    "label": "Braja Caka",
    "value": "Braja Caka"
  },
  {
    "id": "1807072006",
    "district_id": "180707",
    "label": "Sri Wangi",
    "value": "Sri Wangi"
  },
  {
    "id": "1807072007",
    "district_id": "180707",
    "label": "Jepara",
    "value": "Jepara"
  },
  {
    "id": "1807072008",
    "district_id": "180707",
    "label": "Sri Rejosari",
    "value": "Sri Rejosari"
  },
  {
    "id": "1807072009",
    "district_id": "180707",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "1807072010",
    "district_id": "180707",
    "label": "Labuhan Ratu II",
    "value": "Labuhan Ratu II"
  },
  {
    "id": "1807072011",
    "district_id": "180707",
    "label": "Braja Asri",
    "value": "Braja Asri"
  },
  {
    "id": "1807072012",
    "district_id": "180707",
    "label": "Labuhan Ratu I",
    "value": "Labuhan Ratu I"
  },
  {
    "id": "1807072013",
    "district_id": "180707",
    "label": "Sumur Bandung",
    "value": "Sumur Bandung"
  },
  {
    "id": "1807072014",
    "district_id": "180707",
    "label": "Sumber Marga",
    "value": "Sumber Marga"
  },
  {
    "id": "1807072015",
    "district_id": "180707",
    "label": "Labuhan Ratu Danau",
    "value": "Labuhan Ratu Danau"
  },
  {
    "id": "1807072016",
    "district_id": "180707",
    "label": "Labuhan Ratu Baru",
    "value": "Labuhan Ratu Baru"
  },
  {
    "id": "1807082001",
    "district_id": "180708",
    "label": "Taman Fajar",
    "value": "Taman Fajar"
  },
  {
    "id": "1807082002",
    "district_id": "180708",
    "label": "Taman Asri",
    "value": "Taman Asri"
  },
  {
    "id": "1807082003",
    "district_id": "180708",
    "label": "Taman Bogo",
    "value": "Taman Bogo"
  },
  {
    "id": "1807082004",
    "district_id": "180708",
    "label": "Tambah Dadi",
    "value": "Tambah Dadi"
  },
  {
    "id": "1807082005",
    "district_id": "180708",
    "label": "Taman Cari",
    "value": "Taman Cari"
  },
  {
    "id": "1807082006",
    "district_id": "180708",
    "label": "Taman Endah",
    "value": "Taman Endah"
  },
  {
    "id": "1807082007",
    "district_id": "180708",
    "label": "Tegal Gondo",
    "value": "Tegal Gondo"
  },
  {
    "id": "1807082008",
    "district_id": "180708",
    "label": "Toto Harjo",
    "value": "Toto Harjo"
  },
  {
    "id": "1807082009",
    "district_id": "180708",
    "label": "Tanjung Inten",
    "value": "Tanjung Inten"
  },
  {
    "id": "1807082010",
    "district_id": "180708",
    "label": "Tegal Yoso",
    "value": "Tegal Yoso"
  },
  {
    "id": "1807082011",
    "district_id": "180708",
    "label": "Tambah Luhur",
    "value": "Tambah Luhur"
  },
  {
    "id": "1807082012",
    "district_id": "180708",
    "label": "Tanjung Kesuma",
    "value": "Tanjung Kesuma"
  },
  {
    "id": "1807092001",
    "district_id": "180709",
    "label": "Kota Raman",
    "value": "Kota Raman"
  },
  {
    "id": "1807092002",
    "district_id": "180709",
    "label": "Raman Aji",
    "value": "Raman Aji"
  },
  {
    "id": "1807092003",
    "district_id": "180709",
    "label": "Rukti Sedyo",
    "value": "Rukti Sedyo"
  },
  {
    "id": "1807092004",
    "district_id": "180709",
    "label": "Ratna Daya",
    "value": "Ratna Daya"
  },
  {
    "id": "1807092005",
    "district_id": "180709",
    "label": "Rejo Binangun",
    "value": "Rejo Binangun"
  },
  {
    "id": "1807092006",
    "district_id": "180709",
    "label": "Rantau Fajar",
    "value": "Rantau Fajar"
  },
  {
    "id": "1807092007",
    "district_id": "180709",
    "label": "Raman Endra",
    "value": "Raman Endra"
  },
  {
    "id": "1807092008",
    "district_id": "180709",
    "label": "Raman Fajar",
    "value": "Raman Fajar"
  },
  {
    "id": "1807092009",
    "district_id": "180709",
    "label": "Restu Rahayu",
    "value": "Restu Rahayu"
  },
  {
    "id": "1807092010",
    "district_id": "180709",
    "label": "Rejo Katon",
    "value": "Rejo Katon"
  },
  {
    "id": "1807092011",
    "district_id": "180709",
    "label": "Rama Puja",
    "value": "Rama Puja"
  },
  {
    "id": "1807102001",
    "district_id": "180710",
    "label": "Kibang",
    "value": "Kibang"
  },
  {
    "id": "1807102002",
    "district_id": "180710",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1807102003",
    "district_id": "180710",
    "label": "Margototo",
    "value": "Margototo"
  },
  {
    "id": "1807102004",
    "district_id": "180710",
    "label": "Purbosembodo",
    "value": "Purbosembodo"
  },
  {
    "id": "1807102005",
    "district_id": "180710",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1807102006",
    "district_id": "180710",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "1807102007",
    "district_id": "180710",
    "label": "Jaya Asri",
    "value": "Jaya Asri"
  },
  {
    "id": "1807112001",
    "district_id": "180711",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1807112002",
    "district_id": "180711",
    "label": "Negeri Tua",
    "value": "Negeri Tua"
  },
  {
    "id": "1807112003",
    "district_id": "180711",
    "label": "Negeri Katon",
    "value": "Negeri Katon"
  },
  {
    "id": "1807112004",
    "district_id": "180711",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1807112005",
    "district_id": "180711",
    "label": "Negeri Jemanten",
    "value": "Negeri Jemanten"
  },
  {
    "id": "1807112006",
    "district_id": "180711",
    "label": "Sukaraja Tiga",
    "value": "Sukaraja Tiga"
  },
  {
    "id": "1807112007",
    "district_id": "180711",
    "label": "Gedung Wani",
    "value": "Gedung Wani"
  },
  {
    "id": "1807112008",
    "district_id": "180711",
    "label": "Jaya Guna",
    "value": "Jaya Guna"
  },
  {
    "id": "1807112009",
    "district_id": "180711",
    "label": "Sukadana Baru",
    "value": "Sukadana Baru"
  },
  {
    "id": "1807112010",
    "district_id": "180711",
    "label": "Nabang Baru",
    "value": "Nabang Baru"
  },
  {
    "id": "1807112011",
    "district_id": "180711",
    "label": "Surya Mataram",
    "value": "Surya Mataram"
  },
  {
    "id": "1807112012",
    "district_id": "180711",
    "label": "Gedungwani Timur",
    "value": "Gedungwani Timur"
  },
  {
    "id": "1807112013",
    "district_id": "180711",
    "label": "Trisinar",
    "value": "Trisinar"
  },
  {
    "id": "1807122001",
    "district_id": "180712",
    "label": "Pugung Raharjo",
    "value": "Pugung Raharjo"
  },
  {
    "id": "1807122002",
    "district_id": "180712",
    "label": "Mengandung Sari",
    "value": "Mengandung Sari"
  },
  {
    "id": "1807122003",
    "district_id": "180712",
    "label": "Toba",
    "value": "Toba"
  },
  {
    "id": "1807122004",
    "district_id": "180712",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1807122005",
    "district_id": "180712",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "1807122006",
    "district_id": "180712",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1807122007",
    "district_id": "180712",
    "label": "Sindang Anom",
    "value": "Sindang Anom"
  },
  {
    "id": "1807122008",
    "district_id": "180712",
    "label": "Bauh Gunung Sari",
    "value": "Bauh Gunung Sari"
  },
  {
    "id": "1807122009",
    "district_id": "180712",
    "label": "Gunung Pasir Jaya",
    "value": "Gunung Pasir Jaya"
  },
  {
    "id": "1807122010",
    "district_id": "180712",
    "label": "Gunung Sugih Besar",
    "value": "Gunung Sugih Besar"
  },
  {
    "id": "1807122011",
    "district_id": "180712",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "1807122012",
    "district_id": "180712",
    "label": "Brawijaya",
    "value": "Brawijaya"
  },
  {
    "id": "1807122013",
    "district_id": "180712",
    "label": "Gunung Mulyo",
    "value": "Gunung Mulyo"
  },
  {
    "id": "1807122014",
    "district_id": "180712",
    "label": "Bumi Mulyo",
    "value": "Bumi Mulyo"
  },
  {
    "id": "1807122015",
    "district_id": "180712",
    "label": "Purwokencono",
    "value": "Purwokencono"
  },
  {
    "id": "1807132001",
    "district_id": "180713",
    "label": "Sukaraja Nuban",
    "value": "Sukaraja Nuban"
  },
  {
    "id": "1807132002",
    "district_id": "180713",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1807132003",
    "district_id": "180713",
    "label": "Tulung Balak",
    "value": "Tulung Balak"
  },
  {
    "id": "1807132004",
    "district_id": "180713",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1807132005",
    "district_id": "180713",
    "label": "Kedaton I",
    "value": "Kedaton I"
  },
  {
    "id": "1807132006",
    "district_id": "180713",
    "label": "Kedaton II",
    "value": "Kedaton II"
  },
  {
    "id": "1807132007",
    "district_id": "180713",
    "label": "Trisnomulyo",
    "value": "Trisnomulyo"
  },
  {
    "id": "1807132008",
    "district_id": "180713",
    "label": "Cempaka Nuban",
    "value": "Cempaka Nuban"
  },
  {
    "id": "1807132009",
    "district_id": "180713",
    "label": "Gedung Dalem",
    "value": "Gedung Dalem"
  },
  {
    "id": "1807132010",
    "district_id": "180713",
    "label": "Bumi Jawa",
    "value": "Bumi Jawa"
  },
  {
    "id": "1807132011",
    "district_id": "180713",
    "label": "Gunung Tiga",
    "value": "Gunung Tiga"
  },
  {
    "id": "1807132012",
    "district_id": "180713",
    "label": "Negara Ratu",
    "value": "Negara Ratu"
  },
  {
    "id": "1807132013",
    "district_id": "180713",
    "label": "Sukacari",
    "value": "Sukacari"
  },
  {
    "id": "1807142001",
    "district_id": "180714",
    "label": "Donomulyo",
    "value": "Donomulyo"
  },
  {
    "id": "1807142002",
    "district_id": "180714",
    "label": "Nyampir",
    "value": "Nyampir"
  },
  {
    "id": "1807142003",
    "district_id": "180714",
    "label": "Bumi Tinggi",
    "value": "Bumi Tinggi"
  },
  {
    "id": "1807142004",
    "district_id": "180714",
    "label": "Lehan",
    "value": "Lehan"
  },
  {
    "id": "1807142005",
    "district_id": "180714",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "1807142006",
    "district_id": "180714",
    "label": "Catur Swako",
    "value": "Catur Swako"
  },
  {
    "id": "1807142007",
    "district_id": "180714",
    "label": "Mulyo Asri",
    "value": "Mulyo Asri"
  },
  {
    "id": "1807152001",
    "district_id": "180715",
    "label": "Sribhawono",
    "value": "Sribhawono"
  },
  {
    "id": "1807152002",
    "district_id": "180715",
    "label": "Sadar Sriwijaya",
    "value": "Sadar Sriwijaya"
  },
  {
    "id": "1807152003",
    "district_id": "180715",
    "label": "Srimenanti",
    "value": "Srimenanti"
  },
  {
    "id": "1807152004",
    "district_id": "180715",
    "label": "Sripendowo",
    "value": "Sripendowo"
  },
  {
    "id": "1807152005",
    "district_id": "180715",
    "label": "Waringin Jaya",
    "value": "Waringin Jaya"
  },
  {
    "id": "1807152006",
    "district_id": "180715",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1807152007",
    "district_id": "180715",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1807162001",
    "district_id": "180716",
    "label": "Mataram Baru",
    "value": "Mataram Baru"
  },
  {
    "id": "1807162002",
    "district_id": "180716",
    "label": "Rajabasa Baru",
    "value": "Rajabasa Baru"
  },
  {
    "id": "1807162003",
    "district_id": "180716",
    "label": "Teluk Dalem",
    "value": "Teluk Dalem"
  },
  {
    "id": "1807162004",
    "district_id": "180716",
    "label": "Way Areng",
    "value": "Way Areng"
  },
  {
    "id": "1807162005",
    "district_id": "180716",
    "label": "Tulung Pasik",
    "value": "Tulung Pasik"
  },
  {
    "id": "1807162006",
    "district_id": "180716",
    "label": "Mandala Sari",
    "value": "Mandala Sari"
  },
  {
    "id": "1807162007",
    "district_id": "180716",
    "label": "Kebon Damar",
    "value": "Kebon Damar"
  },
  {
    "id": "1807172001",
    "district_id": "180717",
    "label": "Wana",
    "value": "Wana"
  },
  {
    "id": "1807172002",
    "district_id": "180717",
    "label": "Tanjung Aji",
    "value": "Tanjung Aji"
  },
  {
    "id": "1807172003",
    "district_id": "180717",
    "label": "Tebing",
    "value": "Tebing"
  },
  {
    "id": "1807172004",
    "district_id": "180717",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1807172005",
    "district_id": "180717",
    "label": "Itik Renday",
    "value": "Itik Renday"
  },
  {
    "id": "1807172006",
    "district_id": "180717",
    "label": "Sumber Hadi",
    "value": "Sumber Hadi"
  },
  {
    "id": "1807182001",
    "district_id": "180718",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1807182002",
    "district_id": "180718",
    "label": "Pempen",
    "value": "Pempen"
  },
  {
    "id": "1807182003",
    "district_id": "180718",
    "label": "Way Mili",
    "value": "Way Mili"
  },
  {
    "id": "1807182004",
    "district_id": "180718",
    "label": "Pelindung Jaya",
    "value": "Pelindung Jaya"
  },
  {
    "id": "1807182005",
    "district_id": "180718",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "1807192001",
    "district_id": "180719",
    "label": "Pasir Sakti",
    "value": "Pasir Sakti"
  },
  {
    "id": "1807192002",
    "district_id": "180719",
    "label": "Mulyo Sari",
    "value": "Mulyo Sari"
  },
  {
    "id": "1807192003",
    "district_id": "180719",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1807192004",
    "district_id": "180719",
    "label": "Rejo Mulyo",
    "value": "Rejo Mulyo"
  },
  {
    "id": "1807192005",
    "district_id": "180719",
    "label": "Kedung Ringin",
    "value": "Kedung Ringin"
  },
  {
    "id": "1807192006",
    "district_id": "180719",
    "label": "Labuhan Ratu",
    "value": "Labuhan Ratu"
  },
  {
    "id": "1807192007",
    "district_id": "180719",
    "label": "Sumur Kucing",
    "value": "Sumur Kucing"
  },
  {
    "id": "1807192008",
    "district_id": "180719",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1807202001",
    "district_id": "180720",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "1807202002",
    "district_id": "180720",
    "label": "Ngesti Karya",
    "value": "Ngesti Karya"
  },
  {
    "id": "1807202003",
    "district_id": "180720",
    "label": "Tri Tunggal",
    "value": "Tri Tunggal"
  },
  {
    "id": "1807202004",
    "district_id": "180720",
    "label": "Sidorahayu",
    "value": "Sidorahayu"
  },
  {
    "id": "1807202005",
    "district_id": "180720",
    "label": "Jembrana",
    "value": "Jembrana"
  },
  {
    "id": "1807202006",
    "district_id": "180720",
    "label": "Tanjung Wangi",
    "value": "Tanjung Wangi"
  },
  {
    "id": "1807202007",
    "district_id": "180720",
    "label": "Marga Batin",
    "value": "Marga Batin"
  },
  {
    "id": "1807202008",
    "district_id": "180720",
    "label": "Mekar Karya",
    "value": "Mekar Karya"
  },
  {
    "id": "1807202009",
    "district_id": "180720",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1807202010",
    "district_id": "180720",
    "label": "Karang Anom",
    "value": "Karang Anom"
  },
  {
    "id": "1807202011",
    "district_id": "180720",
    "label": "Karya Basuki",
    "value": "Karya Basuki"
  },
  {
    "id": "1807212001",
    "district_id": "180721",
    "label": "Labuhan Ratu",
    "value": "Labuhan Ratu"
  },
  {
    "id": "1807212002",
    "district_id": "180721",
    "label": "Labuhan Ratu III",
    "value": "Labuhan Ratu III"
  },
  {
    "id": "1807212003",
    "district_id": "180721",
    "label": "Labuhan Ratu IV",
    "value": "Labuhan Ratu IV"
  },
  {
    "id": "1807212004",
    "district_id": "180721",
    "label": "Labuhan Ratu V",
    "value": "Labuhan Ratu V"
  },
  {
    "id": "1807212005",
    "district_id": "180721",
    "label": "Labuhan Ratu VI",
    "value": "Labuhan Ratu VI"
  },
  {
    "id": "1807212006",
    "district_id": "180721",
    "label": "Rajabasa Lama",
    "value": "Rajabasa Lama"
  },
  {
    "id": "1807212007",
    "district_id": "180721",
    "label": "Rajabasa Lama I",
    "value": "Rajabasa Lama I"
  },
  {
    "id": "1807212008",
    "district_id": "180721",
    "label": "Rajabasa Lama II",
    "value": "Rajabasa Lama II"
  },
  {
    "id": "1807212009",
    "district_id": "180721",
    "label": "Labuhan Ratu VII",
    "value": "Labuhan Ratu VII"
  },
  {
    "id": "1807212010",
    "district_id": "180721",
    "label": "Labuhan Ratu VIII",
    "value": "Labuhan Ratu VIII"
  },
  {
    "id": "1807212011",
    "district_id": "180721",
    "label": "Labuhan Ratu IX",
    "value": "Labuhan Ratu IX"
  },
  {
    "id": "1807222001",
    "district_id": "180722",
    "label": "Braja Harjosari",
    "value": "Braja Harjosari"
  },
  {
    "id": "1807222002",
    "district_id": "180722",
    "label": "Braja Gemilang",
    "value": "Braja Gemilang"
  },
  {
    "id": "1807222003",
    "district_id": "180722",
    "label": "Braja Indah",
    "value": "Braja Indah"
  },
  {
    "id": "1807222004",
    "district_id": "180722",
    "label": "Braja Yekti",
    "value": "Braja Yekti"
  },
  {
    "id": "1807222005",
    "district_id": "180722",
    "label": "Braja Kencana",
    "value": "Braja Kencana"
  },
  {
    "id": "1807222006",
    "district_id": "180722",
    "label": "Braja Luhur",
    "value": "Braja Luhur"
  },
  {
    "id": "1807222007",
    "district_id": "180722",
    "label": "Braja Mulya",
    "value": "Braja Mulya"
  },
  {
    "id": "1807232001",
    "district_id": "180723",
    "label": "Tambah Subur",
    "value": "Tambah Subur"
  },
  {
    "id": "1807232002",
    "district_id": "180723",
    "label": "Tanjung Qencono",
    "value": "Tanjung Qencono"
  },
  {
    "id": "1807232003",
    "district_id": "180723",
    "label": "Tanjung Tirto",
    "value": "Tanjung Tirto"
  },
  {
    "id": "1807232004",
    "district_id": "180723",
    "label": "Toto Mulyo",
    "value": "Toto Mulyo"
  },
  {
    "id": "1807232005",
    "district_id": "180723",
    "label": "Toto Projo",
    "value": "Toto Projo"
  },
  {
    "id": "1807232006",
    "district_id": "180723",
    "label": "Taman Negeri",
    "value": "Taman Negeri"
  },
  {
    "id": "1807232007",
    "district_id": "180723",
    "label": "Tegal Ombo",
    "value": "Tegal Ombo"
  },
  {
    "id": "1807232008",
    "district_id": "180723",
    "label": "Kali Pasir",
    "value": "Kali Pasir"
  },
  {
    "id": "1807242001",
    "district_id": "180724",
    "label": "Bungkuk",
    "value": "Bungkuk"
  },
  {
    "id": "1807242002",
    "district_id": "180724",
    "label": "Batu Badak",
    "value": "Batu Badak"
  },
  {
    "id": "1807242003",
    "district_id": "180724",
    "label": "Peniangan",
    "value": "Peniangan"
  },
  {
    "id": "1807242004",
    "district_id": "180724",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "1807242005",
    "district_id": "180724",
    "label": "Giri Mulyo",
    "value": "Giri Mulyo"
  },
  {
    "id": "1807242006",
    "district_id": "180724",
    "label": "Gunung Mas",
    "value": "Gunung Mas"
  },
  {
    "id": "1807242007",
    "district_id": "180724",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1807242008",
    "district_id": "180724",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "1808011005",
    "district_id": "180801",
    "label": "Blambangan Umpu",
    "value": "Blambangan Umpu"
  },
  {
    "id": "1808012001",
    "district_id": "180801",
    "label": "Rambang Jaya",
    "value": "Rambang Jaya"
  },
  {
    "id": "1808012002",
    "district_id": "180801",
    "label": "Gistang",
    "value": "Gistang"
  },
  {
    "id": "1808012003",
    "district_id": "180801",
    "label": "Sidoarjo",
    "value": "Sidoarjo"
  },
  {
    "id": "1808012004",
    "district_id": "180801",
    "label": "Bumi Ratu",
    "value": "Bumi Ratu"
  },
  {
    "id": "1808012006",
    "district_id": "180801",
    "label": "Lembasung",
    "value": "Lembasung"
  },
  {
    "id": "1808012007",
    "district_id": "180801",
    "label": "Negeri Baru",
    "value": "Negeri Baru"
  },
  {
    "id": "1808012008",
    "district_id": "180801",
    "label": "Negeri Batin",
    "value": "Negeri Batin"
  },
  {
    "id": "1808012009",
    "district_id": "180801",
    "label": "Gunung Sangkaran",
    "value": "Gunung Sangkaran"
  },
  {
    "id": "1808012010",
    "district_id": "180801",
    "label": "Tanjung Raja Giham",
    "value": "Tanjung Raja Giham"
  },
  {
    "id": "1808012011",
    "district_id": "180801",
    "label": "Segara Mider",
    "value": "Segara Mider"
  },
  {
    "id": "1808012012",
    "district_id": "180801",
    "label": "Umpu Bhakti",
    "value": "Umpu Bhakti"
  },
  {
    "id": "1808012013",
    "district_id": "180801",
    "label": "Sangkaran Bhakti",
    "value": "Sangkaran Bhakti"
  },
  {
    "id": "1808012014",
    "district_id": "180801",
    "label": "Bumi Baru",
    "value": "Bumi Baru"
  },
  {
    "id": "1808012015",
    "district_id": "180801",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1808012016",
    "district_id": "180801",
    "label": "Sri Rejeki",
    "value": "Sri Rejeki"
  },
  {
    "id": "1808012017",
    "district_id": "180801",
    "label": "Karang Umpu",
    "value": "Karang Umpu"
  },
  {
    "id": "1808012018",
    "district_id": "180801",
    "label": "Umpu Kencana",
    "value": "Umpu Kencana"
  },
  {
    "id": "1808012019",
    "district_id": "180801",
    "label": "Sriwijaya",
    "value": "Sriwijaya"
  },
  {
    "id": "1808012020",
    "district_id": "180801",
    "label": "Brata Yudha",
    "value": "Brata Yudha"
  },
  {
    "id": "1808012021",
    "district_id": "180801",
    "label": "Gedung Batin",
    "value": "Gedung Batin"
  },
  {
    "id": "1808012022",
    "district_id": "180801",
    "label": "Tanjung Raja Sakti",
    "value": "Tanjung Raja Sakti"
  },
  {
    "id": "1808012023",
    "district_id": "180801",
    "label": "Negeri Bumi Putra",
    "value": "Negeri Bumi Putra"
  },
  {
    "id": "1808012024",
    "district_id": "180801",
    "label": "Panca Negeri",
    "value": "Panca Negeri"
  },
  {
    "id": "1808012025",
    "district_id": "180801",
    "label": "Gedung Riang",
    "value": "Gedung Riang"
  },
  {
    "id": "1808012026",
    "district_id": "180801",
    "label": "Ojo Lali",
    "value": "Ojo Lali"
  },
  {
    "id": "1808021004",
    "district_id": "180802",
    "label": "Kasui Pasar",
    "value": "Kasui Pasar"
  },
  {
    "id": "1808022001",
    "district_id": "180802",
    "label": "Datar Bancong",
    "value": "Datar Bancong"
  },
  {
    "id": "1808022002",
    "district_id": "180802",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1808022003",
    "district_id": "180802",
    "label": "Karang Lantang",
    "value": "Karang Lantang"
  },
  {
    "id": "1808022005",
    "district_id": "180802",
    "label": "Kasui Lama",
    "value": "Kasui Lama"
  },
  {
    "id": "1808022006",
    "district_id": "180802",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1808022007",
    "district_id": "180802",
    "label": "Jukuh Kemuning",
    "value": "Jukuh Kemuning"
  },
  {
    "id": "1808022008",
    "district_id": "180802",
    "label": "Kota Way",
    "value": "Kota Way"
  },
  {
    "id": "1808022009",
    "district_id": "180802",
    "label": "Tanjung Kurung",
    "value": "Tanjung Kurung"
  },
  {
    "id": "1808022010",
    "district_id": "180802",
    "label": "Talang Mangga",
    "value": "Talang Mangga"
  },
  {
    "id": "1808022011",
    "district_id": "180802",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1808022012",
    "district_id": "180802",
    "label": "Sinar Gading",
    "value": "Sinar Gading"
  },
  {
    "id": "1808022013",
    "district_id": "180802",
    "label": "Jaya Tinggi",
    "value": "Jaya Tinggi"
  },
  {
    "id": "1808022014",
    "district_id": "180802",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1808022015",
    "district_id": "180802",
    "label": "Gelombang Panjang",
    "value": "Gelombang Panjang"
  },
  {
    "id": "1808022016",
    "district_id": "180802",
    "label": "Tangkas",
    "value": "Tangkas"
  },
  {
    "id": "1808022017",
    "district_id": "180802",
    "label": "Tanjung Bulan",
    "value": "Tanjung Bulan"
  },
  {
    "id": "1808022018",
    "district_id": "180802",
    "label": "Tanjungkurung Lama",
    "value": "Tanjungkurung Lama"
  },
  {
    "id": "1808022019",
    "district_id": "180802",
    "label": "Bukitbatu",
    "value": "Bukitbatu"
  },
  {
    "id": "1808031006",
    "district_id": "180803",
    "label": "Pasar Banjit",
    "value": "Pasar Banjit"
  },
  {
    "id": "1808032001",
    "district_id": "180803",
    "label": "Bunglai",
    "value": "Bunglai"
  },
  {
    "id": "1808032002",
    "district_id": "180803",
    "label": "Campang Lapan",
    "value": "Campang Lapan"
  },
  {
    "id": "1808032003",
    "district_id": "180803",
    "label": "Sumber Baru",
    "value": "Sumber Baru"
  },
  {
    "id": "1808032004",
    "district_id": "180803",
    "label": "Rantau Temiang",
    "value": "Rantau Temiang"
  },
  {
    "id": "1808032005",
    "district_id": "180803",
    "label": "Juku Batu",
    "value": "Juku Batu"
  },
  {
    "id": "1808032007",
    "district_id": "180803",
    "label": "Bali Sadar",
    "value": "Bali Sadar"
  },
  {
    "id": "1808032008",
    "district_id": "180803",
    "label": "Dono Mulyo",
    "value": "Dono Mulyo"
  },
  {
    "id": "1808032009",
    "district_id": "180803",
    "label": "Bali Sadar Utara",
    "value": "Bali Sadar Utara"
  },
  {
    "id": "1808032010",
    "district_id": "180803",
    "label": "Bali Sadar Selatan",
    "value": "Bali Sadar Selatan"
  },
  {
    "id": "1808032011",
    "district_id": "180803",
    "label": "Menanga Jaya",
    "value": "Menanga Jaya"
  },
  {
    "id": "1808032012",
    "district_id": "180803",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "1808032013",
    "district_id": "180803",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "1808032014",
    "district_id": "180803",
    "label": "Rebang Tinggi",
    "value": "Rebang Tinggi"
  },
  {
    "id": "1808032015",
    "district_id": "180803",
    "label": "Simpang Asam",
    "value": "Simpang Asam"
  },
  {
    "id": "1808032016",
    "district_id": "180803",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "1808032017",
    "district_id": "180803",
    "label": "Kemu",
    "value": "Kemu"
  },
  {
    "id": "1808032018",
    "district_id": "180803",
    "label": "Menanga Siamang",
    "value": "Menanga Siamang"
  },
  {
    "id": "1808032019",
    "district_id": "180803",
    "label": "Neki",
    "value": "Neki"
  },
  {
    "id": "1808032020",
    "district_id": "180803",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "1808041009",
    "district_id": "180804",
    "label": "Taman Asri",
    "value": "Taman Asri"
  },
  {
    "id": "1808041015",
    "district_id": "180804",
    "label": "Tiuh Balak Pasar",
    "value": "Tiuh Balak Pasar"
  },
  {
    "id": "1808041016",
    "district_id": "180804",
    "label": "Campur Asri",
    "value": "Campur Asri"
  },
  {
    "id": "1808042001",
    "district_id": "180804",
    "label": "Setia Negara",
    "value": "Setia Negara"
  },
  {
    "id": "1808042002",
    "district_id": "180804",
    "label": "Gedung Pakuon",
    "value": "Gedung Pakuon"
  },
  {
    "id": "1808042003",
    "district_id": "180804",
    "label": "Bumi Merapi",
    "value": "Bumi Merapi"
  },
  {
    "id": "1808042004",
    "district_id": "180804",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "1808042005",
    "district_id": "180804",
    "label": "Gunung Katun",
    "value": "Gunung Katun"
  },
  {
    "id": "1808042006",
    "district_id": "180804",
    "label": "Bhakti Negara",
    "value": "Bhakti Negara"
  },
  {
    "id": "1808042007",
    "district_id": "180804",
    "label": "Bumi Rejo",
    "value": "Bumi Rejo"
  },
  {
    "id": "1808042008",
    "district_id": "180804",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "1808042010",
    "district_id": "180804",
    "label": "Banjar Setia",
    "value": "Banjar Setia"
  },
  {
    "id": "1808042011",
    "district_id": "180804",
    "label": "Gedung Rejo",
    "value": "Gedung Rejo"
  },
  {
    "id": "1808042012",
    "district_id": "180804",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1808042013",
    "district_id": "180804",
    "label": "Cugah",
    "value": "Cugah"
  },
  {
    "id": "1808042014",
    "district_id": "180804",
    "label": "Tiuh Balak I",
    "value": "Tiuh Balak I"
  },
  {
    "id": "1808042017",
    "district_id": "180804",
    "label": "Tiuh Balak",
    "value": "Tiuh Balak"
  },
  {
    "id": "1808042018",
    "district_id": "180804",
    "label": "Mekar Asri",
    "value": "Mekar Asri"
  },
  {
    "id": "1808042019",
    "district_id": "180804",
    "label": "Banjar Negara",
    "value": "Banjar Negara"
  },
  {
    "id": "1808042020",
    "district_id": "180804",
    "label": "Banjar Masin",
    "value": "Banjar Masin"
  },
  {
    "id": "1808042021",
    "district_id": "180804",
    "label": "Banjar Mulya",
    "value": "Banjar Mulya"
  },
  {
    "id": "1808042022",
    "district_id": "180804",
    "label": "Banjar Baru",
    "value": "Banjar Baru"
  },
  {
    "id": "1808052001",
    "district_id": "180805",
    "label": "Tulang Bawang",
    "value": "Tulang Bawang"
  },
  {
    "id": "1808052002",
    "district_id": "180805",
    "label": "Mesir Udik",
    "value": "Mesir Udik"
  },
  {
    "id": "1808052005",
    "district_id": "180805",
    "label": "Mesir Ilir",
    "value": "Mesir Ilir"
  },
  {
    "id": "1808052006",
    "district_id": "180805",
    "label": "Sapto Renggo",
    "value": "Sapto Renggo"
  },
  {
    "id": "1808052007",
    "district_id": "180805",
    "label": "Giri Harjo",
    "value": "Giri Harjo"
  },
  {
    "id": "1808052009",
    "district_id": "180805",
    "label": "Bumi Agung Wates",
    "value": "Bumi Agung Wates"
  },
  {
    "id": "1808052017",
    "district_id": "180805",
    "label": "Serdang Kuring",
    "value": "Serdang Kuring"
  },
  {
    "id": "1808052024",
    "district_id": "180805",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1808052025",
    "district_id": "180805",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1808052026",
    "district_id": "180805",
    "label": "Putra Dewa",
    "value": "Putra Dewa"
  },
  {
    "id": "1808052027",
    "district_id": "180805",
    "label": "Dewa Agung",
    "value": "Dewa Agung"
  },
  {
    "id": "1808062001",
    "district_id": "180806",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "1808062002",
    "district_id": "180806",
    "label": "Tanjung Ratu",
    "value": "Tanjung Ratu"
  },
  {
    "id": "1808062003",
    "district_id": "180806",
    "label": "Pakuan Ratu",
    "value": "Pakuan Ratu"
  },
  {
    "id": "1808062004",
    "district_id": "180806",
    "label": "Gunung Cahya",
    "value": "Gunung Cahya"
  },
  {
    "id": "1808062005",
    "district_id": "180806",
    "label": "Rumbih",
    "value": "Rumbih"
  },
  {
    "id": "1808062006",
    "district_id": "180806",
    "label": "Negara Ratu",
    "value": "Negara Ratu"
  },
  {
    "id": "1808062007",
    "district_id": "180806",
    "label": "Negara Sakti",
    "value": "Negara Sakti"
  },
  {
    "id": "1808062008",
    "district_id": "180806",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "1808062009",
    "district_id": "180806",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1808062010",
    "district_id": "180806",
    "label": "Tanjung Serupa",
    "value": "Tanjung Serupa"
  },
  {
    "id": "1808062011",
    "district_id": "180806",
    "label": "Negara Harja",
    "value": "Negara Harja"
  },
  {
    "id": "1808062012",
    "district_id": "180806",
    "label": "Bakti Negara",
    "value": "Bakti Negara"
  },
  {
    "id": "1808062013",
    "district_id": "180806",
    "label": "Pakuan Baru",
    "value": "Pakuan Baru"
  },
  {
    "id": "1808062014",
    "district_id": "180806",
    "label": "Negara Tama",
    "value": "Negara Tama"
  },
  {
    "id": "1808062015",
    "district_id": "180806",
    "label": "Way Tawar",
    "value": "Way Tawar"
  },
  {
    "id": "1808062016",
    "district_id": "180806",
    "label": "Gunung Waras",
    "value": "Gunung Waras"
  },
  {
    "id": "1808062017",
    "district_id": "180806",
    "label": "Pakuan Sakti",
    "value": "Pakuan Sakti"
  },
  {
    "id": "1808062018",
    "district_id": "180806",
    "label": "Serupa Indah",
    "value": "Serupa Indah"
  },
  {
    "id": "1808062019",
    "district_id": "180806",
    "label": "Bumi Mulya",
    "value": "Bumi Mulya"
  },
  {
    "id": "1808072001",
    "district_id": "180807",
    "label": "Negeri Agung",
    "value": "Negeri Agung"
  },
  {
    "id": "1808072002",
    "district_id": "180807",
    "label": "Bandar Dalam",
    "value": "Bandar Dalam"
  },
  {
    "id": "1808072003",
    "district_id": "180807",
    "label": "Pulau Batu",
    "value": "Pulau Batu"
  },
  {
    "id": "1808072004",
    "district_id": "180807",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  {
    "id": "1808072005",
    "district_id": "180807",
    "label": "Karya Agung",
    "value": "Karya Agung"
  },
  {
    "id": "1808072006",
    "district_id": "180807",
    "label": "Sunsang",
    "value": "Sunsang"
  },
  {
    "id": "1808072007",
    "district_id": "180807",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1808072008",
    "district_id": "180807",
    "label": "Kotabumi Way Kanan",
    "value": "Kotabumi Way Kanan"
  },
  {
    "id": "1808072009",
    "district_id": "180807",
    "label": "Gedung Meneng",
    "value": "Gedung Meneng"
  },
  {
    "id": "1808072010",
    "district_id": "180807",
    "label": "Gedung Harapan",
    "value": "Gedung Harapan"
  },
  {
    "id": "1808072011",
    "district_id": "180807",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1808072012",
    "district_id": "180807",
    "label": "Kali Papan",
    "value": "Kali Papan"
  },
  {
    "id": "1808072013",
    "district_id": "180807",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "1808072014",
    "district_id": "180807",
    "label": "Way Limau",
    "value": "Way Limau"
  },
  {
    "id": "1808072015",
    "district_id": "180807",
    "label": "Gedung Jaya",
    "value": "Gedung Jaya"
  },
  {
    "id": "1808072016",
    "district_id": "180807",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1808072017",
    "district_id": "180807",
    "label": "Bandar Kasih",
    "value": "Bandar Kasih"
  },
  {
    "id": "1808072018",
    "district_id": "180807",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "1808072019",
    "district_id": "180807",
    "label": "Mulya Agung",
    "value": "Mulya Agung"
  },
  {
    "id": "1808082001",
    "district_id": "180808",
    "label": "Way Tuba",
    "value": "Way Tuba"
  },
  {
    "id": "1808082002",
    "district_id": "180808",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "1808082003",
    "district_id": "180808",
    "label": "Say Umpu",
    "value": "Say Umpu"
  },
  {
    "id": "1808082004",
    "district_id": "180808",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1808082005",
    "district_id": "180808",
    "label": "Suma Mukti",
    "value": "Suma Mukti"
  },
  {
    "id": "1808082006",
    "district_id": "180808",
    "label": "Ramsai",
    "value": "Ramsai"
  },
  {
    "id": "1808082007",
    "district_id": "180808",
    "label": "Bandar Sari",
    "value": "Bandar Sari"
  },
  {
    "id": "1808082008",
    "district_id": "180808",
    "label": "Bumi Dana",
    "value": "Bumi Dana"
  },
  {
    "id": "1808082009",
    "district_id": "180808",
    "label": "Bukit Gemuruh",
    "value": "Bukit Gemuruh"
  },
  {
    "id": "1808082010",
    "district_id": "180808",
    "label": "Way Mencar",
    "value": "Way Mencar"
  },
  {
    "id": "1808082011",
    "district_id": "180808",
    "label": "Way Pisang",
    "value": "Way Pisang"
  },
  {
    "id": "1808082012",
    "district_id": "180808",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "1808082013",
    "district_id": "180808",
    "label": "Way Tuba Asri",
    "value": "Way Tuba Asri"
  },
  {
    "id": "1808092001",
    "district_id": "180809",
    "label": "Lebak Peniangan",
    "value": "Lebak Peniangan"
  },
  {
    "id": "1808092002",
    "district_id": "180809",
    "label": "Tanjung Tiga",
    "value": "Tanjung Tiga"
  },
  {
    "id": "1808092003",
    "district_id": "180809",
    "label": "Madang Jaya",
    "value": "Madang Jaya"
  },
  {
    "id": "1808092004",
    "district_id": "180809",
    "label": "Air Ringkih",
    "value": "Air Ringkih"
  },
  {
    "id": "1808092005",
    "district_id": "180809",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1808092006",
    "district_id": "180809",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1808092007",
    "district_id": "180809",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1808092008",
    "district_id": "180809",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1808092009",
    "district_id": "180809",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "1808092010",
    "district_id": "180809",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1808102001",
    "district_id": "180810",
    "label": "Gunung Labuhan",
    "value": "Gunung Labuhan"
  },
  {
    "id": "1808102002",
    "district_id": "180810",
    "label": "Gunung Baru",
    "value": "Gunung Baru"
  },
  {
    "id": "1808102003",
    "district_id": "180810",
    "label": "Banjar Sakti",
    "value": "Banjar Sakti"
  },
  {
    "id": "1808102004",
    "district_id": "180810",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1808102005",
    "district_id": "180810",
    "label": "Negeri Mulyo",
    "value": "Negeri Mulyo"
  },
  {
    "id": "1808102006",
    "district_id": "180810",
    "label": "Negeri Sungkai",
    "value": "Negeri Sungkai"
  },
  {
    "id": "1808102007",
    "district_id": "180810",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1808102008",
    "district_id": "180810",
    "label": "Way Tuba",
    "value": "Way Tuba"
  },
  {
    "id": "1808102009",
    "district_id": "180810",
    "label": "Bengkulu",
    "value": "Bengkulu"
  },
  {
    "id": "1808102010",
    "district_id": "180810",
    "label": "Bengkulu Rejo",
    "value": "Bengkulu Rejo"
  },
  {
    "id": "1808102011",
    "district_id": "180810",
    "label": "Kayu Batu",
    "value": "Kayu Batu"
  },
  {
    "id": "1808102012",
    "district_id": "180810",
    "label": "Bengkulu Raman",
    "value": "Bengkulu Raman"
  },
  {
    "id": "1808102013",
    "district_id": "180810",
    "label": "Bengkulu Jaya",
    "value": "Bengkulu Jaya"
  },
  {
    "id": "1808102014",
    "district_id": "180810",
    "label": "Tiuh Balak II",
    "value": "Tiuh Balak II"
  },
  {
    "id": "1808102015",
    "district_id": "180810",
    "label": "Curup Patah",
    "value": "Curup Patah"
  },
  {
    "id": "1808102016",
    "district_id": "180810",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1808102017",
    "district_id": "180810",
    "label": "Banjar Ratu",
    "value": "Banjar Ratu"
  },
  {
    "id": "1808102018",
    "district_id": "180810",
    "label": "Negeri Ujan Mas",
    "value": "Negeri Ujan Mas"
  },
  {
    "id": "1808102019",
    "district_id": "180810",
    "label": "Bengkulu Tengah",
    "value": "Bengkulu Tengah"
  },
  {
    "id": "1808102020",
    "district_id": "180810",
    "label": "Gunungpekuwon",
    "value": "Gunungpekuwon"
  },
  {
    "id": "1808102021",
    "district_id": "180810",
    "label": "Labuhan Jaya",
    "value": "Labuhan Jaya"
  },
  {
    "id": "1808112001",
    "district_id": "180811",
    "label": "Negara Batin",
    "value": "Negara Batin"
  },
  {
    "id": "1808112002",
    "district_id": "180811",
    "label": "Purwa Agung",
    "value": "Purwa Agung"
  },
  {
    "id": "1808112003",
    "district_id": "180811",
    "label": "Purwa Negara",
    "value": "Purwa Negara"
  },
  {
    "id": "1808112004",
    "district_id": "180811",
    "label": "Setia Negara",
    "value": "Setia Negara"
  },
  {
    "id": "1808112005",
    "district_id": "180811",
    "label": "Sri Mulyo",
    "value": "Sri Mulyo"
  },
  {
    "id": "1808112006",
    "district_id": "180811",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1808112007",
    "district_id": "180811",
    "label": "Karta Jaya",
    "value": "Karta Jaya"
  },
  {
    "id": "1808112008",
    "district_id": "180811",
    "label": "Gisting Jaya",
    "value": "Gisting Jaya"
  },
  {
    "id": "1808112009",
    "district_id": "180811",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1808112010",
    "district_id": "180811",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "1808112011",
    "district_id": "180811",
    "label": "Gedung Jaya",
    "value": "Gedung Jaya"
  },
  {
    "id": "1808112012",
    "district_id": "180811",
    "label": "Adi Jaya",
    "value": "Adi Jaya"
  },
  {
    "id": "1808112013",
    "district_id": "180811",
    "label": "Sari Jaya",
    "value": "Sari Jaya"
  },
  {
    "id": "1808112014",
    "district_id": "180811",
    "label": "Negara Mulya",
    "value": "Negara Mulya"
  },
  {
    "id": "1808112015",
    "district_id": "180811",
    "label": "Kota Jawa",
    "value": "Kota Jawa"
  },
  {
    "id": "1808122001",
    "district_id": "180812",
    "label": "Negeri Besar",
    "value": "Negeri Besar"
  },
  {
    "id": "1808122002",
    "district_id": "180812",
    "label": "Kiling-kiling",
    "value": "Kiling-kiling"
  },
  {
    "id": "1808122003",
    "district_id": "180812",
    "label": "Kaliawi",
    "value": "Kaliawi"
  },
  {
    "id": "1808122004",
    "district_id": "180812",
    "label": "Tiuh Baru",
    "value": "Tiuh Baru"
  },
  {
    "id": "1808122005",
    "district_id": "180812",
    "label": "Bima Sakti",
    "value": "Bima Sakti"
  },
  {
    "id": "1808122006",
    "district_id": "180812",
    "label": "Tegal Mukti",
    "value": "Tegal Mukti"
  },
  {
    "id": "1808122007",
    "district_id": "180812",
    "label": "Negara Jaya",
    "value": "Negara Jaya"
  },
  {
    "id": "1808122008",
    "district_id": "180812",
    "label": "Pagar Iman",
    "value": "Pagar Iman"
  },
  {
    "id": "1808122009",
    "district_id": "180812",
    "label": "Sri Basuki",
    "value": "Sri Basuki"
  },
  {
    "id": "1808122010",
    "district_id": "180812",
    "label": "Kaliawi Indah",
    "value": "Kaliawi Indah"
  },
  {
    "id": "1808122011",
    "district_id": "180812",
    "label": "Negeri Jaya",
    "value": "Negeri Jaya"
  },
  {
    "id": "1808122012",
    "district_id": "180812",
    "label": "Tanjungmas",
    "value": "Tanjungmas"
  },
  {
    "id": "1808122013",
    "district_id": "180812",
    "label": "Negeri Kasih",
    "value": "Negeri Kasih"
  },
  {
    "id": "1808132001",
    "district_id": "180813",
    "label": "Bumi Harjo",
    "value": "Bumi Harjo"
  },
  {
    "id": "1808132002",
    "district_id": "180813",
    "label": "Punjul Agung",
    "value": "Punjul Agung"
  },
  {
    "id": "1808132003",
    "district_id": "180813",
    "label": "Sri Tunggal",
    "value": "Sri Tunggal"
  },
  {
    "id": "1808132004",
    "district_id": "180813",
    "label": "Suka Bumi",
    "value": "Suka Bumi"
  },
  {
    "id": "1808132005",
    "district_id": "180813",
    "label": "Suka Agung",
    "value": "Suka Agung"
  },
  {
    "id": "1808132006",
    "district_id": "180813",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "1808132007",
    "district_id": "180813",
    "label": "Nuar Maju",
    "value": "Nuar Maju"
  },
  {
    "id": "1808132008",
    "district_id": "180813",
    "label": "Lebung Lawe",
    "value": "Lebung Lawe"
  },
  {
    "id": "1808132009",
    "district_id": "180813",
    "label": "Way Agung",
    "value": "Way Agung"
  },
  {
    "id": "1808142001",
    "district_id": "180814",
    "label": "Pisang Baru",
    "value": "Pisang Baru"
  },
  {
    "id": "1808142002",
    "district_id": "180814",
    "label": "Pisang Indah",
    "value": "Pisang Indah"
  },
  {
    "id": "1808142003",
    "district_id": "180814",
    "label": "Sri Numpi",
    "value": "Sri Numpi"
  },
  {
    "id": "1808142004",
    "district_id": "180814",
    "label": "Mulyo Harjo",
    "value": "Mulyo Harjo"
  },
  {
    "id": "1808142005",
    "district_id": "180814",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1808142006",
    "district_id": "180814",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "1808142007",
    "district_id": "180814",
    "label": "Wono Harjo",
    "value": "Wono Harjo"
  },
  {
    "id": "1808142008",
    "district_id": "180814",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1808142009",
    "district_id": "180814",
    "label": "Tanjung Dalom",
    "value": "Tanjung Dalom"
  },
  {
    "id": "1808142010",
    "district_id": "180814",
    "label": "Bumi Say Agung",
    "value": "Bumi Say Agung"
  },
  {
    "id": "1809012001",
    "district_id": "180901",
    "label": "Padang Ratu",
    "value": "Padang Ratu"
  },
  {
    "id": "1809012002",
    "district_id": "180901",
    "label": "Pampangan",
    "value": "Pampangan"
  },
  {
    "id": "1809012003",
    "district_id": "180901",
    "label": "Cipadang",
    "value": "Cipadang"
  },
  {
    "id": "1809012004",
    "district_id": "180901",
    "label": "Way Layap",
    "value": "Way Layap"
  },
  {
    "id": "1809012005",
    "district_id": "180901",
    "label": "Sukadadi",
    "value": "Sukadadi"
  },
  {
    "id": "1809012006",
    "district_id": "180901",
    "label": "Gedung Tataan",
    "value": "Gedung Tataan"
  },
  {
    "id": "1809012007",
    "district_id": "180901",
    "label": "Bagelen",
    "value": "Bagelen"
  },
  {
    "id": "1809012008",
    "district_id": "180901",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1809012009",
    "district_id": "180901",
    "label": "Kebagusan",
    "value": "Kebagusan"
  },
  {
    "id": "1809012010",
    "district_id": "180901",
    "label": "Sungai Langka",
    "value": "Sungai Langka"
  },
  {
    "id": "1809012011",
    "district_id": "180901",
    "label": "Kurungan Nyawa",
    "value": "Kurungan Nyawa"
  },
  {
    "id": "1809012012",
    "district_id": "180901",
    "label": "Negeri Sakti",
    "value": "Negeri Sakti"
  },
  {
    "id": "1809012013",
    "district_id": "180901",
    "label": "Bernung",
    "value": "Bernung"
  },
  {
    "id": "1809012014",
    "district_id": "180901",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1809012015",
    "district_id": "180901",
    "label": "Wiyono",
    "value": "Wiyono"
  },
  {
    "id": "1809012016",
    "district_id": "180901",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "1809012017",
    "district_id": "180901",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "1809012018",
    "district_id": "180901",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "1809012019",
    "district_id": "180901",
    "label": "Kutoarjo",
    "value": "Kutoarjo"
  },
  {
    "id": "1809022001",
    "district_id": "180902",
    "label": "Kagungan Ratu",
    "value": "Kagungan Ratu"
  },
  {
    "id": "1809022002",
    "district_id": "180902",
    "label": "Kali Rejo",
    "value": "Kali Rejo"
  },
  {
    "id": "1809022003",
    "district_id": "180902",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "1809022004",
    "district_id": "180902",
    "label": "Pujo Rahayu",
    "value": "Pujo Rahayu"
  },
  {
    "id": "1809022005",
    "district_id": "180902",
    "label": "Negeri Katon",
    "value": "Negeri Katon"
  },
  {
    "id": "1809022006",
    "district_id": "180902",
    "label": "Ponco Kresno",
    "value": "Ponco Kresno"
  },
  {
    "id": "1809022007",
    "district_id": "180902",
    "label": "Halangan Ratu",
    "value": "Halangan Ratu"
  },
  {
    "id": "1809022008",
    "district_id": "180902",
    "label": "Pejambon",
    "value": "Pejambon"
  },
  {
    "id": "1809022009",
    "district_id": "180902",
    "label": "Negara Saka",
    "value": "Negara Saka"
  },
  {
    "id": "1809022010",
    "district_id": "180902",
    "label": "Sinar Bandung",
    "value": "Sinar Bandung"
  },
  {
    "id": "1809022011",
    "district_id": "180902",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1809022012",
    "district_id": "180902",
    "label": "Rowo Rejo",
    "value": "Rowo Rejo"
  },
  {
    "id": "1809022013",
    "district_id": "180902",
    "label": "Tresno Maju",
    "value": "Tresno Maju"
  },
  {
    "id": "1809022014",
    "district_id": "180902",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "1809022015",
    "district_id": "180902",
    "label": "Lumbirejo",
    "value": "Lumbirejo"
  },
  {
    "id": "1809022016",
    "district_id": "180902",
    "label": "Tri Rahayu",
    "value": "Tri Rahayu"
  },
  {
    "id": "1809022017",
    "district_id": "180902",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "1809022018",
    "district_id": "180902",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "1809022019",
    "district_id": "180902",
    "label": "Negeri Ulangan Jaya",
    "value": "Negeri Ulangan Jaya"
  },
  {
    "id": "1809032001",
    "district_id": "180903",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "1809032002",
    "district_id": "180903",
    "label": "Kejadian",
    "value": "Kejadian"
  },
  {
    "id": "1809032003",
    "district_id": "180903",
    "label": "Batang Hari Ogan",
    "value": "Batang Hari Ogan"
  },
  {
    "id": "1809032004",
    "district_id": "180903",
    "label": "Negara Ratu Wates",
    "value": "Negara Ratu Wates"
  },
  {
    "id": "1809032005",
    "district_id": "180903",
    "label": "Gunung Sugih Baru",
    "value": "Gunung Sugih Baru"
  },
  {
    "id": "1809032006",
    "district_id": "180903",
    "label": "Gedung Gumanti",
    "value": "Gedung Gumanti"
  },
  {
    "id": "1809032007",
    "district_id": "180903",
    "label": "Kresno Widodo",
    "value": "Kresno Widodo"
  },
  {
    "id": "1809032008",
    "district_id": "180903",
    "label": "Sinarjati",
    "value": "Sinarjati"
  },
  {
    "id": "1809032009",
    "district_id": "180903",
    "label": "Margo Rejo",
    "value": "Margo Rejo"
  },
  {
    "id": "1809032010",
    "district_id": "180903",
    "label": "Gerning",
    "value": "Gerning"
  },
  {
    "id": "1809032011",
    "district_id": "180903",
    "label": "Panca Bakti",
    "value": "Panca Bakti"
  },
  {
    "id": "1809032012",
    "district_id": "180903",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1809032013",
    "district_id": "180903",
    "label": "Rejo Agung",
    "value": "Rejo Agung"
  },
  {
    "id": "1809032014",
    "district_id": "180903",
    "label": "Kota Agung",
    "value": "Kota Agung"
  },
  {
    "id": "1809032015",
    "district_id": "180903",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "1809032016",
    "district_id": "180903",
    "label": "Sriwedari",
    "value": "Sriwedari"
  },
  {
    "id": "1809042001",
    "district_id": "180904",
    "label": "Padang Manis",
    "value": "Padang Manis"
  },
  {
    "id": "1809042002",
    "district_id": "180904",
    "label": "Banjar Negeri",
    "value": "Banjar Negeri"
  },
  {
    "id": "1809042003",
    "district_id": "180904",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1809042004",
    "district_id": "180904",
    "label": "Pekondoh Gedung",
    "value": "Pekondoh Gedung"
  },
  {
    "id": "1809042005",
    "district_id": "180904",
    "label": "Pekondoh",
    "value": "Pekondoh"
  },
  {
    "id": "1809042006",
    "district_id": "180904",
    "label": "Kota Dalam",
    "value": "Kota Dalam"
  },
  {
    "id": "1809042007",
    "district_id": "180904",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1809042008",
    "district_id": "180904",
    "label": "Gedong Dalam",
    "value": "Gedong Dalam"
  },
  {
    "id": "1809042009",
    "district_id": "180904",
    "label": "Sindang Garut",
    "value": "Sindang Garut"
  },
  {
    "id": "1809042010",
    "district_id": "180904",
    "label": "Baturaja",
    "value": "Baturaja"
  },
  {
    "id": "1809042011",
    "district_id": "180904",
    "label": "Way Harong",
    "value": "Way Harong"
  },
  {
    "id": "1809042012",
    "district_id": "180904",
    "label": "Gunung Rejo",
    "value": "Gunung Rejo"
  },
  {
    "id": "1809042013",
    "district_id": "180904",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "1809042014",
    "district_id": "180904",
    "label": "Cimanuk",
    "value": "Cimanuk"
  },
  {
    "id": "1809042015",
    "district_id": "180904",
    "label": "Sukamandi",
    "value": "Sukamandi"
  },
  {
    "id": "1809042016",
    "district_id": "180904",
    "label": "Paguyuban",
    "value": "Paguyuban"
  },
  {
    "id": "1809052001",
    "district_id": "180905",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "1809052004",
    "district_id": "180905",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "1809052009",
    "district_id": "180905",
    "label": "Hanau Berak",
    "value": "Hanau Berak"
  },
  {
    "id": "1809052010",
    "district_id": "180905",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "1809052011",
    "district_id": "180905",
    "label": "Padang Cermin",
    "value": "Padang Cermin"
  },
  {
    "id": "1809052012",
    "district_id": "180905",
    "label": "Sanggi",
    "value": "Sanggi"
  },
  {
    "id": "1809052016",
    "district_id": "180905",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "1809052017",
    "district_id": "180905",
    "label": "Way Urang",
    "value": "Way Urang"
  },
  {
    "id": "1809052025",
    "district_id": "180905",
    "label": "Khepong Jaya",
    "value": "Khepong Jaya"
  },
  {
    "id": "1809052026",
    "district_id": "180905",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "1809052031",
    "district_id": "180905",
    "label": "Gayau",
    "value": "Gayau"
  },
  {
    "id": "1809062001",
    "district_id": "180906",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "1809062002",
    "district_id": "180906",
    "label": "Banding Agung",
    "value": "Banding Agung"
  },
  {
    "id": "1809062003",
    "district_id": "180906",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "1809062004",
    "district_id": "180906",
    "label": "Sukajaya Pidada",
    "value": "Sukajaya Pidada"
  },
  {
    "id": "1809062005",
    "district_id": "180906",
    "label": "Rusaba",
    "value": "Rusaba"
  },
  {
    "id": "1809062006",
    "district_id": "180906",
    "label": "Kota Jawa",
    "value": "Kota Jawa"
  },
  {
    "id": "1809062016",
    "district_id": "180906",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1809062017",
    "district_id": "180906",
    "label": "Pagar Jaya",
    "value": "Pagar Jaya"
  },
  {
    "id": "1809062019",
    "district_id": "180906",
    "label": "Pulau Legundi",
    "value": "Pulau Legundi"
  },
  {
    "id": "1809062020",
    "district_id": "180906",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1809062021",
    "district_id": "180906",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "1809072008",
    "district_id": "180907",
    "label": "Way Kepayang",
    "value": "Way Kepayang"
  },
  {
    "id": "1809072009",
    "district_id": "180907",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1809072010",
    "district_id": "180907",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "1809072011",
    "district_id": "180907",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "1809072012",
    "district_id": "180907",
    "label": "Tempel Rejo",
    "value": "Tempel Rejo"
  },
  {
    "id": "1809072013",
    "district_id": "180907",
    "label": "Kertasana",
    "value": "Kertasana"
  },
  {
    "id": "1809072014",
    "district_id": "180907",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "1809072017",
    "district_id": "180907",
    "label": "Sinar Harapan",
    "value": "Sinar Harapan"
  },
  {
    "id": "1809072018",
    "district_id": "180907",
    "label": "Teba Jawa",
    "value": "Teba Jawa"
  },
  {
    "id": "1809072019",
    "district_id": "180907",
    "label": "Babakan Loa",
    "value": "Babakan Loa"
  },
  {
    "id": "1809072020",
    "district_id": "180907",
    "label": "Pesawaran Indah",
    "value": "Pesawaran Indah"
  },
  {
    "id": "1809072021",
    "district_id": "180907",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1809082001",
    "district_id": "180908",
    "label": "Sukajaya Punduh",
    "value": "Sukajaya Punduh"
  },
  {
    "id": "1809082002",
    "district_id": "180908",
    "label": "Maja",
    "value": "Maja"
  },
  {
    "id": "1809082003",
    "district_id": "180908",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1809082004",
    "district_id": "180908",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "1809082005",
    "district_id": "180908",
    "label": "Umbul Limus",
    "value": "Umbul Limus"
  },
  {
    "id": "1809082006",
    "district_id": "180908",
    "label": "Pekon Ampai",
    "value": "Pekon Ampai"
  },
  {
    "id": "1809082007",
    "district_id": "180908",
    "label": "Kunyaian",
    "value": "Kunyaian"
  },
  {
    "id": "1809082008",
    "district_id": "180908",
    "label": "Kekatang",
    "value": "Kekatang"
  },
  {
    "id": "1809082009",
    "district_id": "180908",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1809082010",
    "district_id": "180908",
    "label": "Pulau Pahawang",
    "value": "Pulau Pahawang"
  },
  {
    "id": "1809092001",
    "district_id": "180909",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  {
    "id": "1809092002",
    "district_id": "180909",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1809092003",
    "district_id": "180909",
    "label": "Padang Cermin",
    "value": "Padang Cermin"
  },
  {
    "id": "1809092004",
    "district_id": "180909",
    "label": "Bayas Jaya",
    "value": "Bayas Jaya"
  },
  {
    "id": "1809092005",
    "district_id": "180909",
    "label": "Tanjung Kerta",
    "value": "Tanjung Kerta"
  },
  {
    "id": "1809092006",
    "district_id": "180909",
    "label": "Kota Jawa",
    "value": "Kota Jawa"
  },
  {
    "id": "1809092007",
    "district_id": "180909",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1809092008",
    "district_id": "180909",
    "label": "Mada Jaya",
    "value": "Mada Jaya"
  },
  {
    "id": "1809092009",
    "district_id": "180909",
    "label": "Kubu Batu",
    "value": "Kubu Batu"
  },
  {
    "id": "1809092010",
    "district_id": "180909",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1809102001",
    "district_id": "180910",
    "label": "Batu Menyan",
    "value": "Batu Menyan"
  },
  {
    "id": "1809102002",
    "district_id": "180910",
    "label": "Cilimus",
    "value": "Cilimus"
  },
  {
    "id": "1809102003",
    "district_id": "180910",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "1809102004",
    "district_id": "180910",
    "label": "Hanura",
    "value": "Hanura"
  },
  {
    "id": "1809102005",
    "district_id": "180910",
    "label": "Hurun",
    "value": "Hurun"
  },
  {
    "id": "1809102006",
    "district_id": "180910",
    "label": "Munca",
    "value": "Munca"
  },
  {
    "id": "1809102007",
    "district_id": "180910",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1809102008",
    "district_id": "180910",
    "label": "Sukajaya Lempasing",
    "value": "Sukajaya Lempasing"
  },
  {
    "id": "1809102009",
    "district_id": "180910",
    "label": "Talang Mulya",
    "value": "Talang Mulya"
  },
  {
    "id": "1809102010",
    "district_id": "180910",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1809112001",
    "district_id": "180911",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "1809112002",
    "district_id": "180911",
    "label": "Bunut Seberang",
    "value": "Bunut Seberang"
  },
  {
    "id": "1809112003",
    "district_id": "180911",
    "label": "Wates Way Ratai",
    "value": "Wates Way Ratai"
  },
  {
    "id": "1809112004",
    "district_id": "180911",
    "label": "Ceringin Asri",
    "value": "Ceringin Asri"
  },
  {
    "id": "1809112005",
    "district_id": "180911",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1809112006",
    "district_id": "180911",
    "label": "Mulyo Sari",
    "value": "Mulyo Sari"
  },
  {
    "id": "1809112007",
    "district_id": "180911",
    "label": "Poncorejo",
    "value": "Poncorejo"
  },
  {
    "id": "1809112008",
    "district_id": "180911",
    "label": "Gunung Rejo",
    "value": "Gunung Rejo"
  },
  {
    "id": "1809112009",
    "district_id": "180911",
    "label": "Pesawaran Indah",
    "value": "Pesawaran Indah"
  },
  {
    "id": "1809112010",
    "district_id": "180911",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1810011001",
    "district_id": "181001",
    "label": "Fajaresuk",
    "value": "Fajaresuk"
  },
  {
    "id": "1810011002",
    "district_id": "181001",
    "label": "Pringsewu Utara",
    "value": "Pringsewu Utara"
  },
  {
    "id": "1810011003",
    "district_id": "181001",
    "label": "Pringsewu Selatan",
    "value": "Pringsewu Selatan"
  },
  {
    "id": "1810011004",
    "district_id": "181001",
    "label": "Pringsewu Barat",
    "value": "Pringsewu Barat"
  },
  {
    "id": "1810011005",
    "district_id": "181001",
    "label": "Pringsewu Timur",
    "value": "Pringsewu Timur"
  },
  {
    "id": "1810012006",
    "district_id": "181001",
    "label": "Margakaya",
    "value": "Margakaya"
  },
  {
    "id": "1810012007",
    "district_id": "181001",
    "label": "Waluyojati",
    "value": "Waluyojati"
  },
  {
    "id": "1810012008",
    "district_id": "181001",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1810012009",
    "district_id": "181001",
    "label": "Podomoro",
    "value": "Podomoro"
  },
  {
    "id": "1810012010",
    "district_id": "181001",
    "label": "Bumi Arum",
    "value": "Bumi Arum"
  },
  {
    "id": "1810012011",
    "district_id": "181001",
    "label": "Fajar Agung",
    "value": "Fajar Agung"
  },
  {
    "id": "1810012012",
    "district_id": "181001",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1810012013",
    "district_id": "181001",
    "label": "Bumi Ayu",
    "value": "Bumi Ayu"
  },
  {
    "id": "1810012014",
    "district_id": "181001",
    "label": "Podosari",
    "value": "Podosari"
  },
  {
    "id": "1810012015",
    "district_id": "181001",
    "label": "Fajar Agung Barat",
    "value": "Fajar Agung Barat"
  },
  {
    "id": "1810022001",
    "district_id": "181002",
    "label": "Parerejo",
    "value": "Parerejo"
  },
  {
    "id": "1810022002",
    "district_id": "181002",
    "label": "Blitarejo",
    "value": "Blitarejo"
  },
  {
    "id": "1810022003",
    "district_id": "181002",
    "label": "Panjerejo",
    "value": "Panjerejo"
  },
  {
    "id": "1810022004",
    "district_id": "181002",
    "label": "Bulokarto",
    "value": "Bulokarto"
  },
  {
    "id": "1810022005",
    "district_id": "181002",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "1810022006",
    "district_id": "181002",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "1810022007",
    "district_id": "181002",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "1810022008",
    "district_id": "181002",
    "label": "Gadingrejo",
    "value": "Gadingrejo"
  },
  {
    "id": "1810022009",
    "district_id": "181002",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "1810022010",
    "district_id": "181002",
    "label": "Tulung Agung",
    "value": "Tulung Agung"
  },
  {
    "id": "1810022011",
    "district_id": "181002",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "1810022012",
    "district_id": "181002",
    "label": "Yogyakarta",
    "value": "Yogyakarta"
  },
  {
    "id": "1810022013",
    "district_id": "181002",
    "label": "Kediri",
    "value": "Kediri"
  },
  {
    "id": "1810022014",
    "district_id": "181002",
    "label": "Mataram",
    "value": "Mataram"
  },
  {
    "id": "1810022015",
    "district_id": "181002",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "1810022016",
    "district_id": "181002",
    "label": "Klaten",
    "value": "Klaten"
  },
  {
    "id": "1810022017",
    "district_id": "181002",
    "label": "Wates Timur",
    "value": "Wates Timur"
  },
  {
    "id": "1810022018",
    "district_id": "181002",
    "label": "Wates Selatan",
    "value": "Wates Selatan"
  },
  {
    "id": "1810022019",
    "district_id": "181002",
    "label": "Gading Rejo Timur",
    "value": "Gading Rejo Timur"
  },
  {
    "id": "1810022020",
    "district_id": "181002",
    "label": "Gading Rejo Utara",
    "value": "Gading Rejo Utara"
  },
  {
    "id": "1810022021",
    "district_id": "181002",
    "label": "Tambah Rejo Barat",
    "value": "Tambah Rejo Barat"
  },
  {
    "id": "1810022022",
    "district_id": "181002",
    "label": "Wonodadi Utara",
    "value": "Wonodadi Utara"
  },
  {
    "id": "1810022023",
    "district_id": "181002",
    "label": "Yogyakarta Selatan",
    "value": "Yogyakarta Selatan"
  },
  {
    "id": "1810032001",
    "district_id": "181003",
    "label": "Ambarawa",
    "value": "Ambarawa"
  },
  {
    "id": "1810032002",
    "district_id": "181003",
    "label": "Ambarawa Barat",
    "value": "Ambarawa Barat"
  },
  {
    "id": "1810032003",
    "district_id": "181003",
    "label": "Kresno Mulyo",
    "value": "Kresno Mulyo"
  },
  {
    "id": "1810032004",
    "district_id": "181003",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1810032005",
    "district_id": "181003",
    "label": "Tanjung Anom",
    "value": "Tanjung Anom"
  },
  {
    "id": "1810032006",
    "district_id": "181003",
    "label": "Jati Agung",
    "value": "Jati Agung"
  },
  {
    "id": "1810032007",
    "district_id": "181003",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "1810032008",
    "district_id": "181003",
    "label": "Ambarawa Timur",
    "value": "Ambarawa Timur"
  },
  {
    "id": "1810042001",
    "district_id": "181004",
    "label": "Kedaung",
    "value": "Kedaung"
  },
  {
    "id": "1810042002",
    "district_id": "181004",
    "label": "Pardasuka",
    "value": "Pardasuka"
  },
  {
    "id": "1810042003",
    "district_id": "181004",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1810042004",
    "district_id": "181004",
    "label": "Tanjung Rusia",
    "value": "Tanjung Rusia"
  },
  {
    "id": "1810042005",
    "district_id": "181004",
    "label": "Warga Mulyo",
    "value": "Warga Mulyo"
  },
  {
    "id": "1810042006",
    "district_id": "181004",
    "label": "Pujodadi",
    "value": "Pujodadi"
  },
  {
    "id": "1810042007",
    "district_id": "181004",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "1810042008",
    "district_id": "181004",
    "label": "Selapan",
    "value": "Selapan"
  },
  {
    "id": "1810042009",
    "district_id": "181004",
    "label": "Rantau Tijang",
    "value": "Rantau Tijang"
  },
  {
    "id": "1810042010",
    "district_id": "181004",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1810042011",
    "district_id": "181004",
    "label": "Pardasuka Timur",
    "value": "Pardasuka Timur"
  },
  {
    "id": "1810042012",
    "district_id": "181004",
    "label": "Tanjung Rusia Timur",
    "value": "Tanjung Rusia Timur"
  },
  {
    "id": "1810042013",
    "district_id": "181004",
    "label": "Pardasuka Selatan",
    "value": "Pardasuka Selatan"
  },
  {
    "id": "1810052001",
    "district_id": "181005",
    "label": "Candi Retno",
    "value": "Candi Retno"
  },
  {
    "id": "1810052002",
    "district_id": "181005",
    "label": "Tanjung Dalom",
    "value": "Tanjung Dalom"
  },
  {
    "id": "1810052003",
    "district_id": "181005",
    "label": "Way Ngison",
    "value": "Way Ngison"
  },
  {
    "id": "1810052004",
    "district_id": "181005",
    "label": "Suka Wangi",
    "value": "Suka Wangi"
  },
  {
    "id": "1810052005",
    "district_id": "181005",
    "label": "Suka Ratu",
    "value": "Suka Ratu"
  },
  {
    "id": "1810052006",
    "district_id": "181005",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "1810052007",
    "district_id": "181005",
    "label": "Patoman",
    "value": "Patoman"
  },
  {
    "id": "1810052008",
    "district_id": "181005",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "1810052009",
    "district_id": "181005",
    "label": "Gumuk Mas",
    "value": "Gumuk Mas"
  },
  {
    "id": "1810052010",
    "district_id": "181005",
    "label": "Bumi Ratu",
    "value": "Bumi Ratu"
  },
  {
    "id": "1810052011",
    "district_id": "181005",
    "label": "Panutan",
    "value": "Panutan"
  },
  {
    "id": "1810052012",
    "district_id": "181005",
    "label": "Lugusari",
    "value": "Lugusari"
  },
  {
    "id": "1810052019",
    "district_id": "181005",
    "label": "Pamenang",
    "value": "Pamenang"
  },
  {
    "id": "1810052020",
    "district_id": "181005",
    "label": "Gemah Ripah",
    "value": "Gemah Ripah"
  },
  {
    "id": "1810052023",
    "district_id": "181005",
    "label": "Pasir Ukir",
    "value": "Pasir Ukir"
  },
  {
    "id": "1810052024",
    "district_id": "181005",
    "label": "Gumukrejo",
    "value": "Gumukrejo"
  },
  {
    "id": "1810052027",
    "district_id": "181005",
    "label": "Puji Harjo",
    "value": "Puji Harjo"
  },
  {
    "id": "1810052028",
    "district_id": "181005",
    "label": "Padang Rejo",
    "value": "Padang Rejo"
  },
  {
    "id": "1810052029",
    "district_id": "181005",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1810052030",
    "district_id": "181005",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1810052031",
    "district_id": "181005",
    "label": "Ganjaran",
    "value": "Ganjaran"
  },
  {
    "id": "1810052032",
    "district_id": "181005",
    "label": "Bumi Rejo",
    "value": "Bumi Rejo"
  },
  {
    "id": "1810062001",
    "district_id": "181006",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "1810062002",
    "district_id": "181006",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "1810062003",
    "district_id": "181006",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1810062004",
    "district_id": "181006",
    "label": "Sriwungu",
    "value": "Sriwungu"
  },
  {
    "id": "1810062005",
    "district_id": "181006",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "1810062006",
    "district_id": "181006",
    "label": "Waya Krui",
    "value": "Waya Krui"
  },
  {
    "id": "1810062007",
    "district_id": "181006",
    "label": "Sri Rahayu",
    "value": "Sri Rahayu"
  },
  {
    "id": "1810062008",
    "district_id": "181006",
    "label": "Nusa Wungu",
    "value": "Nusa Wungu"
  },
  {
    "id": "1810062009",
    "district_id": "181006",
    "label": "Sinar Mulya",
    "value": "Sinar Mulya"
  },
  {
    "id": "1810062010",
    "district_id": "181006",
    "label": "Banyu Urip",
    "value": "Banyu Urip"
  },
  {
    "id": "1810062011",
    "district_id": "181006",
    "label": "Mulyo Rejo",
    "value": "Mulyo Rejo"
  },
  {
    "id": "1810072001",
    "district_id": "181007",
    "label": "Adiluwih",
    "value": "Adiluwih"
  },
  {
    "id": "1810072002",
    "district_id": "181007",
    "label": "Bandung Baru",
    "value": "Bandung Baru"
  },
  {
    "id": "1810072003",
    "district_id": "181007",
    "label": "Sinarwayah",
    "value": "Sinarwayah"
  },
  {
    "id": "1810072004",
    "district_id": "181007",
    "label": "Enggal Rejo",
    "value": "Enggal Rejo"
  },
  {
    "id": "1810072005",
    "district_id": "181007",
    "label": "Sukoharum",
    "value": "Sukoharum"
  },
  {
    "id": "1810072006",
    "district_id": "181007",
    "label": "Waringin Sari Timur",
    "value": "Waringin Sari Timur"
  },
  {
    "id": "1810072007",
    "district_id": "181007",
    "label": "Tri Tunggal Mulya",
    "value": "Tri Tunggal Mulya"
  },
  {
    "id": "1810072008",
    "district_id": "181007",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "1810072009",
    "district_id": "181007",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "1810072010",
    "district_id": "181007",
    "label": "Tunggul Pawenang",
    "value": "Tunggul Pawenang"
  },
  {
    "id": "1810072011",
    "district_id": "181007",
    "label": "Bandung Baru Barat",
    "value": "Bandung Baru Barat"
  },
  {
    "id": "1810072012",
    "district_id": "181007",
    "label": "Totokarto",
    "value": "Totokarto"
  },
  {
    "id": "1810072013",
    "district_id": "181007",
    "label": "Kuta Waringin",
    "value": "Kuta Waringin"
  },
  {
    "id": "1810082001",
    "district_id": "181008",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "1810082002",
    "district_id": "181008",
    "label": "Sukoharjo I",
    "value": "Sukoharjo I"
  },
  {
    "id": "1810082003",
    "district_id": "181008",
    "label": "Sukoharjo II",
    "value": "Sukoharjo II"
  },
  {
    "id": "1810082004",
    "district_id": "181008",
    "label": "Sukoharjo III",
    "value": "Sukoharjo III"
  },
  {
    "id": "1810082005",
    "district_id": "181008",
    "label": "Sukoharjo IV",
    "value": "Sukoharjo IV"
  },
  {
    "id": "1810082006",
    "district_id": "181008",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "1810082007",
    "district_id": "181008",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "1810082008",
    "district_id": "181008",
    "label": "Pandan Surat",
    "value": "Pandan Surat"
  },
  {
    "id": "1810082009",
    "district_id": "181008",
    "label": "Keputran",
    "value": "Keputran"
  },
  {
    "id": "1810082010",
    "district_id": "181008",
    "label": "Sukoyoso",
    "value": "Sukoyoso"
  },
  {
    "id": "1810082011",
    "district_id": "181008",
    "label": "Siliwangi",
    "value": "Siliwangi"
  },
  {
    "id": "1810082012",
    "district_id": "181008",
    "label": "Waringinsari Barat",
    "value": "Waringinsari Barat"
  },
  {
    "id": "1810082013",
    "district_id": "181008",
    "label": "Pandan Sari Selatan",
    "value": "Pandan Sari Selatan"
  },
  {
    "id": "1810082014",
    "district_id": "181008",
    "label": "Sinar Baru Timur",
    "value": "Sinar Baru Timur"
  },
  {
    "id": "1810082015",
    "district_id": "181008",
    "label": "Panggung Rejo Utara",
    "value": "Panggung Rejo Utara"
  },
  {
    "id": "1810082016",
    "district_id": "181008",
    "label": "Sukoharjo III Barat",
    "value": "Sukoharjo III Barat"
  },
  {
    "id": "1810092001",
    "district_id": "181009",
    "label": "Fajar Baru",
    "value": "Fajar Baru"
  },
  {
    "id": "1810092002",
    "district_id": "181009",
    "label": "Kemilin",
    "value": "Kemilin"
  },
  {
    "id": "1810092003",
    "district_id": "181009",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "1810092004",
    "district_id": "181009",
    "label": "Fajar Mulia",
    "value": "Fajar Mulia"
  },
  {
    "id": "1810092005",
    "district_id": "181009",
    "label": "Margosari",
    "value": "Margosari"
  },
  {
    "id": "1810092006",
    "district_id": "181009",
    "label": "Giri Tunggal",
    "value": "Giri Tunggal"
  },
  {
    "id": "1810092007",
    "district_id": "181009",
    "label": "Sumber Bandung",
    "value": "Sumber Bandung"
  },
  {
    "id": "1810092008",
    "district_id": "181009",
    "label": "Madaraya",
    "value": "Madaraya"
  },
  {
    "id": "1810092009",
    "district_id": "181009",
    "label": "Way Kunir",
    "value": "Way Kunir"
  },
  {
    "id": "1810092010",
    "district_id": "181009",
    "label": "Gunung Raya",
    "value": "Gunung Raya"
  },
  {
    "id": "1811012001",
    "district_id": "181101",
    "label": "Wiralaga I",
    "value": "Wiralaga I"
  },
  {
    "id": "1811012002",
    "district_id": "181101",
    "label": "Wiralaga II",
    "value": "Wiralaga II"
  },
  {
    "id": "1811012003",
    "district_id": "181101",
    "label": "Sungai Badak",
    "value": "Sungai Badak"
  },
  {
    "id": "1811012004",
    "district_id": "181101",
    "label": "Nipah Kuning",
    "value": "Nipah Kuning"
  },
  {
    "id": "1811012005",
    "district_id": "181101",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "1811012006",
    "district_id": "181101",
    "label": "Tirta Laga",
    "value": "Tirta Laga"
  },
  {
    "id": "1811012007",
    "district_id": "181101",
    "label": "Tanjung Serayan",
    "value": "Tanjung Serayan"
  },
  {
    "id": "1811012008",
    "district_id": "181101",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "1811012009",
    "district_id": "181101",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "1811012010",
    "district_id": "181101",
    "label": "Wiralaga Mulya",
    "value": "Wiralaga Mulya"
  },
  {
    "id": "1811012011",
    "district_id": "181101",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1811022001",
    "district_id": "181102",
    "label": "Talang Batu",
    "value": "Talang Batu"
  },
  {
    "id": "1811022002",
    "district_id": "181102",
    "label": "Sungai Cambai",
    "value": "Sungai Cambai"
  },
  {
    "id": "1811022003",
    "district_id": "181102",
    "label": "Tanjung Mas Makmur",
    "value": "Tanjung Mas Makmur"
  },
  {
    "id": "1811022004",
    "district_id": "181102",
    "label": "Margo Jadi",
    "value": "Margo Jadi"
  },
  {
    "id": "1811022005",
    "district_id": "181102",
    "label": "Tanjung Meneng",
    "value": "Tanjung Meneng"
  },
  {
    "id": "1811022006",
    "district_id": "181102",
    "label": "Eka Mulya",
    "value": "Eka Mulya"
  },
  {
    "id": "1811022007",
    "district_id": "181102",
    "label": "Wono Sari",
    "value": "Wono Sari"
  },
  {
    "id": "1811022008",
    "district_id": "181102",
    "label": "Dwi Karya Mustika",
    "value": "Dwi Karya Mustika"
  },
  {
    "id": "1811022009",
    "district_id": "181102",
    "label": "Pangkal Mas Jaya",
    "value": "Pangkal Mas Jaya"
  },
  {
    "id": "1811022010",
    "district_id": "181102",
    "label": "Pangkal Mas Mulya",
    "value": "Pangkal Mas Mulya"
  },
  {
    "id": "1811022011",
    "district_id": "181102",
    "label": "Tanjung Mas Mulya",
    "value": "Tanjung Mas Mulya"
  },
  {
    "id": "1811022012",
    "district_id": "181102",
    "label": "Muara Mas",
    "value": "Muara Mas"
  },
  {
    "id": "1811022013",
    "district_id": "181102",
    "label": "Tanjung Mas Jaya",
    "value": "Tanjung Mas Jaya"
  },
  {
    "id": "1811022014",
    "district_id": "181102",
    "label": "Margo Jaya",
    "value": "Margo Jaya"
  },
  {
    "id": "1811022015",
    "district_id": "181102",
    "label": "Pangkal Mas",
    "value": "Pangkal Mas"
  },
  {
    "id": "1811022016",
    "district_id": "181102",
    "label": "Tanjung Menang Raya",
    "value": "Tanjung Menang Raya"
  },
  {
    "id": "1811022017",
    "district_id": "181102",
    "label": "Tebing Karya Mandiri",
    "value": "Tebing Karya Mandiri"
  },
  {
    "id": "1811022018",
    "district_id": "181102",
    "label": "Tanjung Mas Rejo",
    "value": "Tanjung Mas Rejo"
  },
  {
    "id": "1811022019",
    "district_id": "181102",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1811022020",
    "district_id": "181102",
    "label": "Muara Asri",
    "value": "Muara Asri"
  },
  {
    "id": "1811032001",
    "district_id": "181103",
    "label": "Sungai Buaya",
    "value": "Sungai Buaya"
  },
  {
    "id": "1811032002",
    "district_id": "181103",
    "label": "Panggung Jaya",
    "value": "Panggung Jaya"
  },
  {
    "id": "1811032003",
    "district_id": "181103",
    "label": "Sungai Sidang",
    "value": "Sungai Sidang"
  },
  {
    "id": "1811032004",
    "district_id": "181103",
    "label": "Sidang Sido Rahayu",
    "value": "Sidang Sido Rahayu"
  },
  {
    "id": "1811032005",
    "district_id": "181103",
    "label": "Sidang Way Puji",
    "value": "Sidang Way Puji"
  },
  {
    "id": "1811032006",
    "district_id": "181103",
    "label": "Sidang Bandar Anom",
    "value": "Sidang Bandar Anom"
  },
  {
    "id": "1811032007",
    "district_id": "181103",
    "label": "Panggung Rejo",
    "value": "Panggung Rejo"
  },
  {
    "id": "1811032008",
    "district_id": "181103",
    "label": "Sidang Iso Mukti",
    "value": "Sidang Iso Mukti"
  },
  {
    "id": "1811032009",
    "district_id": "181103",
    "label": "Sidang Kurnia Agung",
    "value": "Sidang Kurnia Agung"
  },
  {
    "id": "1811032010",
    "district_id": "181103",
    "label": "Telogo Rejo",
    "value": "Telogo Rejo"
  },
  {
    "id": "1811032011",
    "district_id": "181103",
    "label": "Sidang Gunung Tiga",
    "value": "Sidang Gunung Tiga"
  },
  {
    "id": "1811032012",
    "district_id": "181103",
    "label": "Sidang Muara Jaya",
    "value": "Sidang Muara Jaya"
  },
  {
    "id": "1811032013",
    "district_id": "181103",
    "label": "Sidang Makmur",
    "value": "Sidang Makmur"
  },
  {
    "id": "1811042001",
    "district_id": "181104",
    "label": "Buko Poso",
    "value": "Buko Poso"
  },
  {
    "id": "1811042002",
    "district_id": "181104",
    "label": "Kejadian",
    "value": "Kejadian"
  },
  {
    "id": "1811042003",
    "district_id": "181104",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "1811042004",
    "district_id": "181104",
    "label": "Kebun Dalem",
    "value": "Kebun Dalem"
  },
  {
    "id": "1811042005",
    "district_id": "181104",
    "label": "Panca Warna",
    "value": "Panca Warna"
  },
  {
    "id": "1811042006",
    "district_id": "181104",
    "label": "Gedung Boga",
    "value": "Gedung Boga"
  },
  {
    "id": "1811042007",
    "district_id": "181104",
    "label": "Suka Agung",
    "value": "Suka Agung"
  },
  {
    "id": "1811042008",
    "district_id": "181104",
    "label": "Rejo Mulyo",
    "value": "Rejo Mulyo"
  },
  {
    "id": "1811042009",
    "district_id": "181104",
    "label": "Hadi Mulyo",
    "value": "Hadi Mulyo"
  },
  {
    "id": "1811042010",
    "district_id": "181104",
    "label": "Labuhan Batin",
    "value": "Labuhan Batin"
  },
  {
    "id": "1811042011",
    "district_id": "181104",
    "label": "Labuhan Baru",
    "value": "Labuhan Baru"
  },
  {
    "id": "1811042012",
    "district_id": "181104",
    "label": "Labuhan Makmur",
    "value": "Labuhan Makmur"
  },
  {
    "id": "1811042013",
    "district_id": "181104",
    "label": "Sri Gedung Mulya",
    "value": "Sri Gedung Mulya"
  },
  {
    "id": "1811042014",
    "district_id": "181104",
    "label": "Labuhan Permai",
    "value": "Labuhan Permai"
  },
  {
    "id": "1811042015",
    "district_id": "181104",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1811042016",
    "district_id": "181104",
    "label": "Margo Bhakti",
    "value": "Margo Bhakti"
  },
  {
    "id": "1811042017",
    "district_id": "181104",
    "label": "Labuhan Mulya",
    "value": "Labuhan Mulya"
  },
  {
    "id": "1811042018",
    "district_id": "181104",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "1811042019",
    "district_id": "181104",
    "label": "Tri Tunggal Jaya",
    "value": "Tri Tunggal Jaya"
  },
  {
    "id": "1811042020",
    "district_id": "181104",
    "label": "Suka Mandiri",
    "value": "Suka Mandiri"
  },
  {
    "id": "1811052001",
    "district_id": "181105",
    "label": "Simpang Pematang",
    "value": "Simpang Pematang"
  },
  {
    "id": "1811052002",
    "district_id": "181105",
    "label": "Budi Aji",
    "value": "Budi Aji"
  },
  {
    "id": "1811052003",
    "district_id": "181105",
    "label": "Margo Rahayu",
    "value": "Margo Rahayu"
  },
  {
    "id": "1811052004",
    "district_id": "181105",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "1811052005",
    "district_id": "181105",
    "label": "Wira Bangun",
    "value": "Wira Bangun"
  },
  {
    "id": "1811052006",
    "district_id": "181105",
    "label": "Agung Batin",
    "value": "Agung Batin"
  },
  {
    "id": "1811052007",
    "district_id": "181105",
    "label": "Bangun Mulyo",
    "value": "Bangun Mulyo"
  },
  {
    "id": "1811052008",
    "district_id": "181105",
    "label": "Jaya Sakti",
    "value": "Jaya Sakti"
  },
  {
    "id": "1811052009",
    "district_id": "181105",
    "label": "Rejo Binangun",
    "value": "Rejo Binangun"
  },
  {
    "id": "1811052010",
    "district_id": "181105",
    "label": "Simpang Mesuji",
    "value": "Simpang Mesuji"
  },
  {
    "id": "1811052011",
    "district_id": "181105",
    "label": "Margo Makmur",
    "value": "Margo Makmur"
  },
  {
    "id": "1811052012",
    "district_id": "181105",
    "label": "Aji Jaya",
    "value": "Aji Jaya"
  },
  {
    "id": "1811052013",
    "district_id": "181105",
    "label": "Mulya Agung",
    "value": "Mulya Agung"
  },
  {
    "id": "1811062001",
    "district_id": "181106",
    "label": "Adi Luhur",
    "value": "Adi Luhur"
  },
  {
    "id": "1811062002",
    "district_id": "181106",
    "label": "Mukti Karya",
    "value": "Mukti Karya"
  },
  {
    "id": "1811062003",
    "district_id": "181106",
    "label": "Fajar Baru",
    "value": "Fajar Baru"
  },
  {
    "id": "1811062004",
    "district_id": "181106",
    "label": "Adi Mulyo",
    "value": "Adi Mulyo"
  },
  {
    "id": "1811062005",
    "district_id": "181106",
    "label": "Fajar Asri",
    "value": "Fajar Asri"
  },
  {
    "id": "1811062006",
    "district_id": "181106",
    "label": "Fajar Indah",
    "value": "Fajar Indah"
  },
  {
    "id": "1811062007",
    "district_id": "181106",
    "label": "Adi Karya Mulya",
    "value": "Adi Karya Mulya"
  },
  {
    "id": "1811072001",
    "district_id": "181107",
    "label": "Brabasan",
    "value": "Brabasan"
  },
  {
    "id": "1811072002",
    "district_id": "181107",
    "label": "Gedung Ram",
    "value": "Gedung Ram"
  },
  {
    "id": "1811072003",
    "district_id": "181107",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "1811072004",
    "district_id": "181107",
    "label": "Bujung Buring",
    "value": "Bujung Buring"
  },
  {
    "id": "1811072005",
    "district_id": "181107",
    "label": "Kagungan Dalam",
    "value": "Kagungan Dalam"
  },
  {
    "id": "1811072006",
    "district_id": "181107",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "1811072007",
    "district_id": "181107",
    "label": "Harapan Mukti",
    "value": "Harapan Mukti"
  },
  {
    "id": "1811072008",
    "district_id": "181107",
    "label": "Sri Tanjung",
    "value": "Sri Tanjung"
  },
  {
    "id": "1811072009",
    "district_id": "181107",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1811072010",
    "district_id": "181107",
    "label": "Muara Tenang",
    "value": "Muara Tenang"
  },
  {
    "id": "1811072011",
    "district_id": "181107",
    "label": "Wira Jaya",
    "value": "Wira Jaya"
  },
  {
    "id": "1811072012",
    "district_id": "181107",
    "label": "Sinar Laga",
    "value": "Sinar Laga"
  },
  {
    "id": "1811072013",
    "district_id": "181107",
    "label": "Tri Karya Mulya",
    "value": "Tri Karya Mulya"
  },
  {
    "id": "1811072014",
    "district_id": "181107",
    "label": "Berasan Makmur",
    "value": "Berasan Makmur"
  },
  {
    "id": "1811072015",
    "district_id": "181107",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1811072016",
    "district_id": "181107",
    "label": "Gedung Mulya",
    "value": "Gedung Mulya"
  },
  {
    "id": "1811072017",
    "district_id": "181107",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "1811072018",
    "district_id": "181107",
    "label": "Muara Tenang Timur",
    "value": "Muara Tenang Timur"
  },
  {
    "id": "1811072019",
    "district_id": "181107",
    "label": "Sriwijaya",
    "value": "Sriwijaya"
  },
  {
    "id": "1811072020",
    "district_id": "181107",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "1811072021",
    "district_id": "181107",
    "label": "Bujung Buring Baru",
    "value": "Bujung Buring Baru"
  },
  {
    "id": "1812011001",
    "district_id": "181201",
    "label": "Mulyo Asri",
    "value": "Mulyo Asri"
  },
  {
    "id": "1812011007",
    "district_id": "181201",
    "label": "Panaragan Jaya",
    "value": "Panaragan Jaya"
  },
  {
    "id": "1812012002",
    "district_id": "181201",
    "label": "Panaragan",
    "value": "Panaragan"
  },
  {
    "id": "1812012003",
    "district_id": "181201",
    "label": "Bandar Dewa",
    "value": "Bandar Dewa"
  },
  {
    "id": "1812012004",
    "district_id": "181201",
    "label": "Menggala Mas",
    "value": "Menggala Mas"
  },
  {
    "id": "1812012005",
    "district_id": "181201",
    "label": "Penumangan",
    "value": "Penumangan"
  },
  {
    "id": "1812012006",
    "district_id": "181201",
    "label": "Penumangan Baru",
    "value": "Penumangan Baru"
  },
  {
    "id": "1812012008",
    "district_id": "181201",
    "label": "Tirta Kencana",
    "value": "Tirta Kencana"
  },
  {
    "id": "1812012009",
    "district_id": "181201",
    "label": "Pulung Kencana",
    "value": "Pulung Kencana"
  },
  {
    "id": "1812012010",
    "district_id": "181201",
    "label": "Mulya Kencana",
    "value": "Mulya Kencana"
  },
  {
    "id": "1812012011",
    "district_id": "181201",
    "label": "Candra Kencana",
    "value": "Candra Kencana"
  },
  {
    "id": "1812012012",
    "district_id": "181201",
    "label": "Tunas Asri",
    "value": "Tunas Asri"
  },
  {
    "id": "1812012013",
    "district_id": "181201",
    "label": "Wono Kerto",
    "value": "Wono Kerto"
  },
  {
    "id": "1812012014",
    "district_id": "181201",
    "label": "Panaragan Jaya Utama",
    "value": "Panaragan Jaya Utama"
  },
  {
    "id": "1812012015",
    "district_id": "181201",
    "label": "Panaragan Jaya Indah",
    "value": "Panaragan Jaya Indah"
  },
  {
    "id": "1812012016",
    "district_id": "181201",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1812012017",
    "district_id": "181201",
    "label": "Tirta Makmur",
    "value": "Tirta Makmur"
  },
  {
    "id": "1812012018",
    "district_id": "181201",
    "label": "Candra Mukti",
    "value": "Candra Mukti"
  },
  {
    "id": "1812012019",
    "district_id": "181201",
    "label": "Candra Jaya",
    "value": "Candra Jaya"
  },
  {
    "id": "1812021001",
    "district_id": "181202",
    "label": "Daya Murni",
    "value": "Daya Murni"
  },
  {
    "id": "1812022002",
    "district_id": "181202",
    "label": "Daya Sakti",
    "value": "Daya Sakti"
  },
  {
    "id": "1812022003",
    "district_id": "181202",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "1812022004",
    "district_id": "181202",
    "label": "Makarti",
    "value": "Makarti"
  },
  {
    "id": "1812022005",
    "district_id": "181202",
    "label": "Gunung Menanti",
    "value": "Gunung Menanti"
  },
  {
    "id": "1812022006",
    "district_id": "181202",
    "label": "Daya Asri",
    "value": "Daya Asri"
  },
  {
    "id": "1812022007",
    "district_id": "181202",
    "label": "Murni Jaya",
    "value": "Murni Jaya"
  },
  {
    "id": "1812022008",
    "district_id": "181202",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1812022009",
    "district_id": "181202",
    "label": "Margo Dadi",
    "value": "Margo Dadi"
  },
  {
    "id": "1812022010",
    "district_id": "181202",
    "label": "Gunung Timbul",
    "value": "Gunung Timbul"
  },
  {
    "id": "1812032001",
    "district_id": "181203",
    "label": "Karta",
    "value": "Karta"
  },
  {
    "id": "1812032002",
    "district_id": "181203",
    "label": "Karta Sari",
    "value": "Karta Sari"
  },
  {
    "id": "1812032003",
    "district_id": "181203",
    "label": "Karta Raharja",
    "value": "Karta Raharja"
  },
  {
    "id": "1812032004",
    "district_id": "181203",
    "label": "Gunung Katun Tanjungan",
    "value": "Gunung Katun Tanjungan"
  },
  {
    "id": "1812032005",
    "district_id": "181203",
    "label": "Gunung Katun Malai",
    "value": "Gunung Katun Malai"
  },
  {
    "id": "1812032006",
    "district_id": "181203",
    "label": "Gedung Ratu",
    "value": "Gedung Ratu"
  },
  {
    "id": "1812032007",
    "district_id": "181203",
    "label": "Kagungan Ratu",
    "value": "Kagungan Ratu"
  },
  {
    "id": "1812032008",
    "district_id": "181203",
    "label": "Marga Kencana",
    "value": "Marga Kencana"
  },
  {
    "id": "1812032009",
    "district_id": "181203",
    "label": "Way Sido",
    "value": "Way Sido"
  },
  {
    "id": "1812042002",
    "district_id": "181204",
    "label": "Toto Mulyo",
    "value": "Toto Mulyo"
  },
  {
    "id": "1812042006",
    "district_id": "181204",
    "label": "Setia Bumi",
    "value": "Setia Bumi"
  },
  {
    "id": "1812042007",
    "district_id": "181204",
    "label": "Gunung Terang",
    "value": "Gunung Terang"
  },
  {
    "id": "1812042008",
    "district_id": "181204",
    "label": "Gunung Agung",
    "value": "Gunung Agung"
  },
  {
    "id": "1812042011",
    "district_id": "181204",
    "label": "Kagungan Jaya",
    "value": "Kagungan Jaya"
  },
  {
    "id": "1812042012",
    "district_id": "181204",
    "label": "Terang Mulya",
    "value": "Terang Mulya"
  },
  {
    "id": "1812042013",
    "district_id": "181204",
    "label": "Terang Bumi Agung",
    "value": "Terang Bumi Agung"
  },
  {
    "id": "1812042015",
    "district_id": "181204",
    "label": "Setia Agung",
    "value": "Setia Agung"
  },
  {
    "id": "1812042017",
    "district_id": "181204",
    "label": "Mulyo Jadi",
    "value": "Mulyo Jadi"
  },
  {
    "id": "1812042020",
    "district_id": "181204",
    "label": "Terang Makmur",
    "value": "Terang Makmur"
  },
  {
    "id": "1812052001",
    "district_id": "181205",
    "label": "Tunas Jaya",
    "value": "Tunas Jaya"
  },
  {
    "id": "1812052002",
    "district_id": "181205",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1812052003",
    "district_id": "181205",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "1812052004",
    "district_id": "181205",
    "label": "Jaya Murni",
    "value": "Jaya Murni"
  },
  {
    "id": "1812052005",
    "district_id": "181205",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "1812052006",
    "district_id": "181205",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "1812052007",
    "district_id": "181205",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "1812052008",
    "district_id": "181205",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "1812052009",
    "district_id": "181205",
    "label": "Wono Rejo",
    "value": "Wono Rejo"
  },
  {
    "id": "1812052010",
    "district_id": "181205",
    "label": "Tri Tunggal Jaya",
    "value": "Tri Tunggal Jaya"
  },
  {
    "id": "1812052011",
    "district_id": "181205",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "1812052012",
    "district_id": "181205",
    "label": "Dwikora Jaya",
    "value": "Dwikora Jaya"
  },
  {
    "id": "1812052013",
    "district_id": "181205",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "1812062001",
    "district_id": "181206",
    "label": "Agung Jaya",
    "value": "Agung Jaya"
  },
  {
    "id": "1812062002",
    "district_id": "181206",
    "label": "Mercu Buana",
    "value": "Mercu Buana"
  },
  {
    "id": "1812062003",
    "district_id": "181206",
    "label": "Balam Jaya",
    "value": "Balam Jaya"
  },
  {
    "id": "1812062004",
    "district_id": "181206",
    "label": "Pagar Buana",
    "value": "Pagar Buana"
  },
  {
    "id": "1812062005",
    "district_id": "181206",
    "label": "Indraloka I",
    "value": "Indraloka I"
  },
  {
    "id": "1812062006",
    "district_id": "181206",
    "label": "Indraloka II",
    "value": "Indraloka II"
  },
  {
    "id": "1812062007",
    "district_id": "181206",
    "label": "Balam Asri",
    "value": "Balam Asri"
  },
  {
    "id": "1812062008",
    "district_id": "181206",
    "label": "Indraloka Jaya",
    "value": "Indraloka Jaya"
  },
  {
    "id": "1812062009",
    "district_id": "181206",
    "label": "Indraloka Mukti",
    "value": "Indraloka Mukti"
  },
  {
    "id": "1812072001",
    "district_id": "181207",
    "label": "Kibang Budi Jaya",
    "value": "Kibang Budi Jaya"
  },
  {
    "id": "1812072002",
    "district_id": "181207",
    "label": "Lesung Bhakti Jaya",
    "value": "Lesung Bhakti Jaya"
  },
  {
    "id": "1812072003",
    "district_id": "181207",
    "label": "Mekar Sari Jaya",
    "value": "Mekar Sari Jaya"
  },
  {
    "id": "1812072004",
    "district_id": "181207",
    "label": "Pagar Jaya",
    "value": "Pagar Jaya"
  },
  {
    "id": "1812072005",
    "district_id": "181207",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "1812072006",
    "district_id": "181207",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1812072007",
    "district_id": "181207",
    "label": "Kibang Yekti Jaya",
    "value": "Kibang Yekti Jaya"
  },
  {
    "id": "1812072008",
    "district_id": "181207",
    "label": "Kibang Tri Jaya",
    "value": "Kibang Tri Jaya"
  },
  {
    "id": "1812072009",
    "district_id": "181207",
    "label": "Gilang Tunggal Makarta",
    "value": "Gilang Tunggal Makarta"
  },
  {
    "id": "1812072010",
    "district_id": "181207",
    "label": "Kibang Mulya Jaya",
    "value": "Kibang Mulya Jaya"
  },
  {
    "id": "1812082001",
    "district_id": "181208",
    "label": "Pagar Dewa",
    "value": "Pagar Dewa"
  },
  {
    "id": "1812082002",
    "district_id": "181208",
    "label": "Pagar Dewa Suka Mulya",
    "value": "Pagar Dewa Suka Mulya"
  },
  {
    "id": "1812082003",
    "district_id": "181208",
    "label": "Cahyou Randu",
    "value": "Cahyou Randu"
  },
  {
    "id": "1812082004",
    "district_id": "181208",
    "label": "Bujung Dewa",
    "value": "Bujung Dewa"
  },
  {
    "id": "1812082005",
    "district_id": "181208",
    "label": "Bujung Sari Marga",
    "value": "Bujung Sari Marga"
  },
  {
    "id": "1812082006",
    "district_id": "181208",
    "label": "Marga Jaya Indah",
    "value": "Marga Jaya Indah"
  },
  {
    "id": "1812092001",
    "district_id": "181209",
    "label": "Panca Marga",
    "value": "Panca Marga"
  },
  {
    "id": "1812092002",
    "district_id": "181209",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "1812092003",
    "district_id": "181209",
    "label": "Sakti Jaya",
    "value": "Sakti Jaya"
  },
  {
    "id": "1812092004",
    "district_id": "181209",
    "label": "Margo Mulya",
    "value": "Margo Mulya"
  },
  {
    "id": "1812092005",
    "district_id": "181209",
    "label": "Margo Dadi",
    "value": "Margo Dadi"
  },
  {
    "id": "1812092006",
    "district_id": "181209",
    "label": "Marga Sari",
    "value": "Marga Sari"
  },
  {
    "id": "1812092007",
    "district_id": "181209",
    "label": "Mulyo Sari",
    "value": "Mulyo Sari"
  },
  {
    "id": "1812092008",
    "district_id": "181209",
    "label": "Toto Katon",
    "value": "Toto Katon"
  },
  {
    "id": "1812092009",
    "district_id": "181209",
    "label": "Toto Makmur",
    "value": "Toto Makmur"
  },
  {
    "id": "1812092010",
    "district_id": "181209",
    "label": "Toto Wonodadi",
    "value": "Toto Wonodadi"
  },
  {
    "id": "1813011005",
    "district_id": "181301",
    "label": "Pasar Krui",
    "value": "Pasar Krui"
  },
  {
    "id": "1813011008",
    "district_id": "181301",
    "label": "Pasar Kota Krui",
    "value": "Pasar Kota Krui"
  },
  {
    "id": "1813012001",
    "district_id": "181301",
    "label": "Way Redak",
    "value": "Way Redak"
  },
  {
    "id": "1813012002",
    "district_id": "181301",
    "label": "Serai",
    "value": "Serai"
  },
  {
    "id": "1813012003",
    "district_id": "181301",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "1813012004",
    "district_id": "181301",
    "label": "Rawas",
    "value": "Rawas"
  },
  {
    "id": "1813012006",
    "district_id": "181301",
    "label": "Suka Negara",
    "value": "Suka Negara"
  },
  {
    "id": "1813012007",
    "district_id": "181301",
    "label": "Pahmungan",
    "value": "Pahmungan"
  },
  {
    "id": "1813022001",
    "district_id": "181302",
    "label": "Negeri Ratu Tenumbang",
    "value": "Negeri Ratu Tenumbang"
  },
  {
    "id": "1813022002",
    "district_id": "181302",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1813022003",
    "district_id": "181302",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "1813022004",
    "district_id": "181302",
    "label": "Sumur Jaya",
    "value": "Sumur Jaya"
  },
  {
    "id": "1813022005",
    "district_id": "181302",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1813022006",
    "district_id": "181302",
    "label": "Pagar Dalam",
    "value": "Pagar Dalam"
  },
  {
    "id": "1813022007",
    "district_id": "181302",
    "label": "Tanjung Setia",
    "value": "Tanjung Setia"
  },
  {
    "id": "1813022008",
    "district_id": "181302",
    "label": "Biha",
    "value": "Biha"
  },
  {
    "id": "1813022009",
    "district_id": "181302",
    "label": "Way Jambu",
    "value": "Way Jambu"
  },
  {
    "id": "1813022010",
    "district_id": "181302",
    "label": "Marang",
    "value": "Marang"
  },
  {
    "id": "1813022011",
    "district_id": "181302",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "1813022012",
    "district_id": "181302",
    "label": "Bangun Negara",
    "value": "Bangun Negara"
  },
  {
    "id": "1813022013",
    "district_id": "181302",
    "label": "Ulok Manik",
    "value": "Ulok Manik"
  },
  {
    "id": "1813022014",
    "district_id": "181302",
    "label": "Paku Negara",
    "value": "Paku Negara"
  },
  {
    "id": "1813022015",
    "district_id": "181302",
    "label": "Tulung Bamban",
    "value": "Tulung Bamban"
  },
  {
    "id": "1813032001",
    "district_id": "181303",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "1813032002",
    "district_id": "181303",
    "label": "Tanjung Sakti",
    "value": "Tanjung Sakti"
  },
  {
    "id": "1813032003",
    "district_id": "181303",
    "label": "Way Batang",
    "value": "Way Batang"
  },
  {
    "id": "1813032004",
    "district_id": "181303",
    "label": "Lemong",
    "value": "Lemong"
  },
  {
    "id": "1813032005",
    "district_id": "181303",
    "label": "Cahaya Negeri",
    "value": "Cahaya Negeri"
  },
  {
    "id": "1813032006",
    "district_id": "181303",
    "label": "Malaya",
    "value": "Malaya"
  },
  {
    "id": "1813032007",
    "district_id": "181303",
    "label": "Bambang",
    "value": "Bambang"
  },
  {
    "id": "1813032008",
    "district_id": "181303",
    "label": "Pagar Dalam",
    "value": "Pagar Dalam"
  },
  {
    "id": "1813032009",
    "district_id": "181303",
    "label": "Bandar Pugung",
    "value": "Bandar Pugung"
  },
  {
    "id": "1813032010",
    "district_id": "181303",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  {
    "id": "1813032011",
    "district_id": "181303",
    "label": "Rata Agung",
    "value": "Rata Agung"
  },
  {
    "id": "1813032012",
    "district_id": "181303",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "1813032013",
    "district_id": "181303",
    "label": "Parda Haga",
    "value": "Parda Haga"
  },
  {
    "id": "1813042001",
    "district_id": "181304",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1813042002",
    "district_id": "181304",
    "label": "Padang Rindu",
    "value": "Padang Rindu"
  },
  {
    "id": "1813042003",
    "district_id": "181304",
    "label": "Negeri Ratu",
    "value": "Negeri Ratu"
  },
  {
    "id": "1813042004",
    "district_id": "181304",
    "label": "Kerbang Dalam",
    "value": "Kerbang Dalam"
  },
  {
    "id": "1813042005",
    "district_id": "181304",
    "label": "Kota Karang",
    "value": "Kota Karang"
  },
  {
    "id": "1813042006",
    "district_id": "181304",
    "label": "Balam",
    "value": "Balam"
  },
  {
    "id": "1813042007",
    "district_id": "181304",
    "label": "Way Narta",
    "value": "Way Narta"
  },
  {
    "id": "1813042008",
    "district_id": "181304",
    "label": "Kerbang Langgar",
    "value": "Kerbang Langgar"
  },
  {
    "id": "1813042009",
    "district_id": "181304",
    "label": "Walur",
    "value": "Walur"
  },
  {
    "id": "1813042010",
    "district_id": "181304",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "1813042011",
    "district_id": "181304",
    "label": "Pemancar",
    "value": "Pemancar"
  },
  {
    "id": "1813042012",
    "district_id": "181304",
    "label": "Gedau",
    "value": "Gedau"
  },
  {
    "id": "1813052001",
    "district_id": "181305",
    "label": "Kebuayan",
    "value": "Kebuayan"
  },
  {
    "id": "1813052002",
    "district_id": "181305",
    "label": "Way Nukak",
    "value": "Way Nukak"
  },
  {
    "id": "1813052003",
    "district_id": "181305",
    "label": "Way Sindi",
    "value": "Way Sindi"
  },
  {
    "id": "1813052004",
    "district_id": "181305",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  {
    "id": "1813052005",
    "district_id": "181305",
    "label": "Menyancang",
    "value": "Menyancang"
  },
  {
    "id": "1813052006",
    "district_id": "181305",
    "label": "Laay",
    "value": "Laay"
  },
  {
    "id": "1813052007",
    "district_id": "181305",
    "label": "Penggawa V Ulu",
    "value": "Penggawa V Ulu"
  },
  {
    "id": "1813052008",
    "district_id": "181305",
    "label": "Penggawa V Tengah",
    "value": "Penggawa V Tengah"
  },
  {
    "id": "1813052009",
    "district_id": "181305",
    "label": "Way Sindi Utara",
    "value": "Way Sindi Utara"
  },
  {
    "id": "1813052010",
    "district_id": "181305",
    "label": "Tembakak Way Sindi",
    "value": "Tembakak Way Sindi"
  },
  {
    "id": "1813052011",
    "district_id": "181305",
    "label": "Way Sindi Hanuan",
    "value": "Way Sindi Hanuan"
  },
  {
    "id": "1813052012",
    "district_id": "181305",
    "label": "Asahan Way Sindi",
    "value": "Asahan Way Sindi"
  },
  {
    "id": "1813062001",
    "district_id": "181306",
    "label": "Pasar Pulaupisang",
    "value": "Pasar Pulaupisang"
  },
  {
    "id": "1813062002",
    "district_id": "181306",
    "label": "Labuhan",
    "value": "Labuhan"
  },
  {
    "id": "1813062003",
    "district_id": "181306",
    "label": "Bandar Dalam",
    "value": "Bandar Dalam"
  },
  {
    "id": "1813062004",
    "district_id": "181306",
    "label": "Pekon Lok",
    "value": "Pekon Lok"
  },
  {
    "id": "1813062005",
    "district_id": "181306",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "1813062006",
    "district_id": "181306",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1813072001",
    "district_id": "181307",
    "label": "Pajar Bulan",
    "value": "Pajar Bulan"
  },
  {
    "id": "1813072002",
    "district_id": "181307",
    "label": "Bumi Waras",
    "value": "Bumi Waras"
  },
  {
    "id": "1813072003",
    "district_id": "181307",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "1813072004",
    "district_id": "181307",
    "label": "Penggawa V Ilir",
    "value": "Penggawa V Ilir"
  },
  {
    "id": "1813072005",
    "district_id": "181307",
    "label": "Ulu Krui",
    "value": "Ulu Krui"
  },
  {
    "id": "1813072006",
    "district_id": "181307",
    "label": "Gunung Kemala",
    "value": "Gunung Kemala"
  },
  {
    "id": "1813072007",
    "district_id": "181307",
    "label": "Labuhan Mandi",
    "value": "Labuhan Mandi"
  },
  {
    "id": "1813072008",
    "district_id": "181307",
    "label": "Suka Baru",
    "value": "Suka Baru"
  },
  {
    "id": "1813072009",
    "district_id": "181307",
    "label": "Penggawa Lima",
    "value": "Penggawa Lima"
  },
  {
    "id": "1813072010",
    "district_id": "181307",
    "label": "Gunungkemala Timur",
    "value": "Gunungkemala Timur"
  },
  {
    "id": "1813082001",
    "district_id": "181308",
    "label": "Balai Kencana",
    "value": "Balai Kencana"
  },
  {
    "id": "1813082002",
    "district_id": "181308",
    "label": "Way Suluh",
    "value": "Way Suluh"
  },
  {
    "id": "1813082003",
    "district_id": "181308",
    "label": "Way Napal",
    "value": "Way Napal"
  },
  {
    "id": "1813082004",
    "district_id": "181308",
    "label": "Padang Haluan",
    "value": "Padang Haluan"
  },
  {
    "id": "1813082005",
    "district_id": "181308",
    "label": "Lintik",
    "value": "Lintik"
  },
  {
    "id": "1813082006",
    "district_id": "181308",
    "label": "Walur",
    "value": "Walur"
  },
  {
    "id": "1813082007",
    "district_id": "181308",
    "label": "Pemerihan",
    "value": "Pemerihan"
  },
  {
    "id": "1813082008",
    "district_id": "181308",
    "label": "Mandiri Sejati",
    "value": "Mandiri Sejati"
  },
  {
    "id": "1813082009",
    "district_id": "181308",
    "label": "Padang Raya",
    "value": "Padang Raya"
  },
  {
    "id": "1813082010",
    "district_id": "181308",
    "label": "Suka Jadi",
    "value": "Suka Jadi"
  },
  {
    "id": "1813092001",
    "district_id": "181309",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1813092002",
    "district_id": "181309",
    "label": "Ulok Mukti",
    "value": "Ulok Mukti"
  },
  {
    "id": "1813092003",
    "district_id": "181309",
    "label": "Negeri Ratu Ngambur",
    "value": "Negeri Ratu Ngambur"
  },
  {
    "id": "1813092004",
    "district_id": "181309",
    "label": "Pekon Mon",
    "value": "Pekon Mon"
  },
  {
    "id": "1813092005",
    "district_id": "181309",
    "label": "Gedung Cahya Kuningan",
    "value": "Gedung Cahya Kuningan"
  },
  {
    "id": "1813092006",
    "district_id": "181309",
    "label": "Suka Banjar",
    "value": "Suka Banjar"
  },
  {
    "id": "1813092007",
    "district_id": "181309",
    "label": "Suka Negara",
    "value": "Suka Negara"
  },
  {
    "id": "1813092008",
    "district_id": "181309",
    "label": "Muara Tembulih",
    "value": "Muara Tembulih"
  },
  {
    "id": "1813092009",
    "district_id": "181309",
    "label": "Bumi Ratu",
    "value": "Bumi Ratu"
  },
  {
    "id": "1813102001",
    "district_id": "181310",
    "label": "Negeri Ratu Ngaras",
    "value": "Negeri Ratu Ngaras"
  },
  {
    "id": "1813102003",
    "district_id": "181310",
    "label": "Kota Batu",
    "value": "Kota Batu"
  },
  {
    "id": "1813102004",
    "district_id": "181310",
    "label": "Mulang Maya",
    "value": "Mulang Maya"
  },
  {
    "id": "1813102005",
    "district_id": "181310",
    "label": "Rajabasa",
    "value": "Rajabasa"
  },
  {
    "id": "1813102006",
    "district_id": "181310",
    "label": "Pardasuka",
    "value": "Pardasuka"
  },
  {
    "id": "1813102017",
    "district_id": "181310",
    "label": "Padang Alam",
    "value": "Padang Alam"
  },
  {
    "id": "1813102018",
    "district_id": "181310",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "1813102019",
    "district_id": "181310",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1813102020",
    "district_id": "181310",
    "label": "Bandar Jaya",
    "value": "Bandar Jaya"
  },
  {
    "id": "1813112001",
    "district_id": "181311",
    "label": "Pagar Bukit",
    "value": "Pagar Bukit"
  },
  {
    "id": "1813112002",
    "district_id": "181311",
    "label": "Tanjung Kemala",
    "value": "Tanjung Kemala"
  },
  {
    "id": "1813112003",
    "district_id": "181311",
    "label": "Suka Marga",
    "value": "Suka Marga"
  },
  {
    "id": "1813112004",
    "district_id": "181311",
    "label": "Kota Jawa",
    "value": "Kota Jawa"
  },
  {
    "id": "1813112005",
    "district_id": "181311",
    "label": "Penyandingan",
    "value": "Penyandingan"
  },
  {
    "id": "1813112006",
    "district_id": "181311",
    "label": "Bandar Dalam",
    "value": "Bandar Dalam"
  },
  {
    "id": "1813112007",
    "district_id": "181311",
    "label": "Way Haru",
    "value": "Way Haru"
  },
  {
    "id": "1813112008",
    "district_id": "181311",
    "label": "Pemerihan",
    "value": "Pemerihan"
  },
  {
    "id": "1813112009",
    "district_id": "181311",
    "label": "Suka Negeri",
    "value": "Suka Negeri"
  },
  {
    "id": "1813112010",
    "district_id": "181311",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "1813112011",
    "district_id": "181311",
    "label": "Siring Gading",
    "value": "Siring Gading"
  },
  {
    "id": "1813112012",
    "district_id": "181311",
    "label": "Way Tias",
    "value": "Way Tias"
  },
  {
    "id": "1813112013",
    "district_id": "181311",
    "label": "Tanjung Rejo",
    "value": "Tanjung Rejo"
  },
  {
    "id": "1813112014",
    "district_id": "181311",
    "label": "Pagar Bukit Induk",
    "value": "Pagar Bukit Induk"
  },
  {
    "id": "1871011003",
    "district_id": "187101",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "1871011004",
    "district_id": "187101",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "1871011005",
    "district_id": "187101",
    "label": "Sukamenanti",
    "value": "Sukamenanti"
  },
  {
    "id": "1871011006",
    "district_id": "187101",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "1871011009",
    "district_id": "187101",
    "label": "Sukamenanti Baru",
    "value": "Sukamenanti Baru"
  },
  {
    "id": "1871011010",
    "district_id": "187101",
    "label": "Penengahan",
    "value": "Penengahan"
  },
  {
    "id": "1871011012",
    "district_id": "187101",
    "label": "Penengahan Raya",
    "value": "Penengahan Raya"
  },
  {
    "id": "1871021003",
    "district_id": "187102",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "1871021004",
    "district_id": "187102",
    "label": "Way Dadi",
    "value": "Way Dadi"
  },
  {
    "id": "1871021006",
    "district_id": "187102",
    "label": "Sukarame Baru",
    "value": "Sukarame Baru"
  },
  {
    "id": "1871021007",
    "district_id": "187102",
    "label": "Way Dadi Baru",
    "value": "Way Dadi Baru"
  },
  {
    "id": "1871021008",
    "district_id": "187102",
    "label": "Korpri Jaya",
    "value": "Korpri Jaya"
  },
  {
    "id": "1871021009",
    "district_id": "187102",
    "label": "Korpri Raya",
    "value": "Korpri Raya"
  },
  {
    "id": "1871031001",
    "district_id": "187103",
    "label": "Gedong Air",
    "value": "Gedong Air"
  },
  {
    "id": "1871031002",
    "district_id": "187103",
    "label": "Sukajawa",
    "value": "Sukajawa"
  },
  {
    "id": "1871031004",
    "district_id": "187103",
    "label": "Susunan Baru",
    "value": "Susunan Baru"
  },
  {
    "id": "1871031006",
    "district_id": "187103",
    "label": "Sukadana Ham",
    "value": "Sukadana Ham"
  },
  {
    "id": "1871031008",
    "district_id": "187103",
    "label": "Kelapa Tiga Permai",
    "value": "Kelapa Tiga Permai"
  },
  {
    "id": "1871031010",
    "district_id": "187103",
    "label": "Sukajawa Baru",
    "value": "Sukajawa Baru"
  },
  {
    "id": "1871031011",
    "district_id": "187103",
    "label": "Segalamider",
    "value": "Segalamider"
  },
  {
    "id": "1871041001",
    "district_id": "187104",
    "label": "Panjang Selatan",
    "value": "Panjang Selatan"
  },
  {
    "id": "1871041002",
    "district_id": "187104",
    "label": "Srengsem",
    "value": "Srengsem"
  },
  {
    "id": "1871041003",
    "district_id": "187104",
    "label": "Panjang Utara",
    "value": "Panjang Utara"
  },
  {
    "id": "1871041004",
    "district_id": "187104",
    "label": "Pidada",
    "value": "Pidada"
  },
  {
    "id": "1871041007",
    "district_id": "187104",
    "label": "Karang Maritim",
    "value": "Karang Maritim"
  },
  {
    "id": "1871041008",
    "district_id": "187104",
    "label": "Way Lunik",
    "value": "Way Lunik"
  },
  {
    "id": "1871041009",
    "district_id": "187104",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "1871041010",
    "district_id": "187104",
    "label": "Ketapang Kuala",
    "value": "Ketapang Kuala"
  },
  {
    "id": "1871051002",
    "district_id": "187105",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "1871051003",
    "district_id": "187105",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "1871051004",
    "district_id": "187105",
    "label": "Kebon Jeruk",
    "value": "Kebon Jeruk"
  },
  {
    "id": "1871051005",
    "district_id": "187105",
    "label": "Sawah Lama",
    "value": "Sawah Lama"
  },
  {
    "id": "1871051006",
    "district_id": "187105",
    "label": "Sawah Brebes",
    "value": "Sawah Brebes"
  },
  {
    "id": "1871061001",
    "district_id": "187106",
    "label": "Durian Payung",
    "value": "Durian Payung"
  },
  {
    "id": "1871061002",
    "district_id": "187106",
    "label": "Gotong Royong",
    "value": "Gotong Royong"
  },
  {
    "id": "1871061005",
    "district_id": "187106",
    "label": "Palapa",
    "value": "Palapa"
  },
  {
    "id": "1871061006",
    "district_id": "187106",
    "label": "Kaliawi",
    "value": "Kaliawi"
  },
  {
    "id": "1871061007",
    "district_id": "187106",
    "label": "Kelapa Tiga",
    "value": "Kelapa Tiga"
  },
  {
    "id": "1871061010",
    "district_id": "187106",
    "label": "Pasir Gintung",
    "value": "Pasir Gintung"
  },
  {
    "id": "1871061012",
    "district_id": "187106",
    "label": "Kaliawi Persada",
    "value": "Kaliawi Persada"
  },
  {
    "id": "1871071001",
    "district_id": "187107",
    "label": "Pesawahan",
    "value": "Pesawahan"
  },
  {
    "id": "1871071002",
    "district_id": "187107",
    "label": "Telukbetung",
    "value": "Telukbetung"
  },
  {
    "id": "1871071008",
    "district_id": "187107",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "1871071009",
    "district_id": "187107",
    "label": "Gedong Pakuon",
    "value": "Gedong Pakuon"
  },
  {
    "id": "1871071012",
    "district_id": "187107",
    "label": "Sumur Putri",
    "value": "Sumur Putri"
  },
  {
    "id": "1871071013",
    "district_id": "187107",
    "label": "Gunungmas",
    "value": "Gunungmas"
  },
  {
    "id": "1871081005",
    "district_id": "187108",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "1871081006",
    "district_id": "187108",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "1871081007",
    "district_id": "187108",
    "label": "Negeri Olok Gading",
    "value": "Negeri Olok Gading"
  },
  {
    "id": "1871081008",
    "district_id": "187108",
    "label": "Sukarame II",
    "value": "Sukarame II"
  },
  {
    "id": "1871081009",
    "district_id": "187108",
    "label": "Batu Putuk",
    "value": "Batu Putuk"
  },
  {
    "id": "1871091001",
    "district_id": "187109",
    "label": "Kupang Kota",
    "value": "Kupang Kota"
  },
  {
    "id": "1871091003",
    "district_id": "187109",
    "label": "Kupang Raya",
    "value": "Kupang Raya"
  },
  {
    "id": "1871091004",
    "district_id": "187109",
    "label": "Kupang Teba",
    "value": "Kupang Teba"
  },
  {
    "id": "1871091006",
    "district_id": "187109",
    "label": "Pengajaran",
    "value": "Pengajaran"
  },
  {
    "id": "1871091007",
    "district_id": "187109",
    "label": "Gulak Galik",
    "value": "Gulak Galik"
  },
  {
    "id": "1871091008",
    "district_id": "187109",
    "label": "Sumur Batu",
    "value": "Sumur Batu"
  },
  {
    "id": "1871101001",
    "district_id": "187110",
    "label": "Rajabasa",
    "value": "Rajabasa"
  },
  {
    "id": "1871101002",
    "district_id": "187110",
    "label": "Gedong Meneng",
    "value": "Gedong Meneng"
  },
  {
    "id": "1871101005",
    "district_id": "187110",
    "label": "Rajabasa Nunyai",
    "value": "Rajabasa Nunyai"
  },
  {
    "id": "1871101006",
    "district_id": "187110",
    "label": "Rajabasa Pemuka",
    "value": "Rajabasa Pemuka"
  },
  {
    "id": "1871101007",
    "district_id": "187110",
    "label": "Gedong Meneng Baru",
    "value": "Gedong Meneng Baru"
  },
  {
    "id": "1871101008",
    "district_id": "187110",
    "label": "Rajabasa Raya",
    "value": "Rajabasa Raya"
  },
  {
    "id": "1871101009",
    "district_id": "187110",
    "label": "Rajabasa Jaya",
    "value": "Rajabasa Jaya"
  },
  {
    "id": "1871111001",
    "district_id": "187111",
    "label": "Tanjung Senang",
    "value": "Tanjung Senang"
  },
  {
    "id": "1871111002",
    "district_id": "187111",
    "label": "Way Kandis",
    "value": "Way Kandis"
  },
  {
    "id": "1871111003",
    "district_id": "187111",
    "label": "Labuhan Dalam",
    "value": "Labuhan Dalam"
  },
  {
    "id": "1871111004",
    "district_id": "187111",
    "label": "Perumnas Way Kandis",
    "value": "Perumnas Way Kandis"
  },
  {
    "id": "1871111005",
    "district_id": "187111",
    "label": "Pematang Wangi",
    "value": "Pematang Wangi"
  },
  {
    "id": "1871121002",
    "district_id": "187112",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "1871121004",
    "district_id": "187112",
    "label": "Sukabumi Indah",
    "value": "Sukabumi Indah"
  },
  {
    "id": "1871121007",
    "district_id": "187112",
    "label": "Campang Raya",
    "value": "Campang Raya"
  },
  {
    "id": "1871121008",
    "district_id": "187112",
    "label": "Nusantara Permai",
    "value": "Nusantara Permai"
  },
  {
    "id": "1871121009",
    "district_id": "187112",
    "label": "Campang Jaya",
    "value": "Campang Jaya"
  },
  {
    "id": "1871121010",
    "district_id": "187112",
    "label": "Way Gubak",
    "value": "Way Gubak"
  },
  {
    "id": "1871121011",
    "district_id": "187112",
    "label": "Way Laga",
    "value": "Way Laga"
  },
  {
    "id": "1871131001",
    "district_id": "187113",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "1871131003",
    "district_id": "187113",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "1871131004",
    "district_id": "187113",
    "label": "Kemiling Permai",
    "value": "Kemiling Permai"
  },
  {
    "id": "1871131005",
    "district_id": "187113",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "1871131006",
    "district_id": "187113",
    "label": "Kedaung",
    "value": "Kedaung"
  },
  {
    "id": "1871131007",
    "district_id": "187113",
    "label": "Pinang Jaya",
    "value": "Pinang Jaya"
  },
  {
    "id": "1871131008",
    "district_id": "187113",
    "label": "Sumberrejo Sejahtera",
    "value": "Sumberrejo Sejahtera"
  },
  {
    "id": "1871131009",
    "district_id": "187113",
    "label": "Kemiling Raya",
    "value": "Kemiling Raya"
  },
  {
    "id": "1871131010",
    "district_id": "187113",
    "label": "Beringin Raya",
    "value": "Beringin Raya"
  },
  {
    "id": "1871141001",
    "district_id": "187114",
    "label": "Labuhan Ratu",
    "value": "Labuhan Ratu"
  },
  {
    "id": "1871141002",
    "district_id": "187114",
    "label": "Labuhan Ratu Raya",
    "value": "Labuhan Ratu Raya"
  },
  {
    "id": "1871141003",
    "district_id": "187114",
    "label": "Sepang Jaya",
    "value": "Sepang Jaya"
  },
  {
    "id": "1871141004",
    "district_id": "187114",
    "label": "Kota Sepang",
    "value": "Kota Sepang"
  },
  {
    "id": "1871141006",
    "district_id": "187114",
    "label": "Kampung Baru Raya",
    "value": "Kampung Baru Raya"
  },
  {
    "id": "1871141007",
    "district_id": "187114",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "1871151001",
    "district_id": "187115",
    "label": "Perumnas Way Halim",
    "value": "Perumnas Way Halim"
  },
  {
    "id": "1871151002",
    "district_id": "187115",
    "label": "Way Halim Permai",
    "value": "Way Halim Permai"
  },
  {
    "id": "1871151003",
    "district_id": "187115",
    "label": "Gunungsulah",
    "value": "Gunungsulah"
  },
  {
    "id": "1871151004",
    "district_id": "187115",
    "label": "Jagabaya I",
    "value": "Jagabaya I"
  },
  {
    "id": "1871151005",
    "district_id": "187115",
    "label": "Jagabaya II",
    "value": "Jagabaya II"
  },
  {
    "id": "1871151006",
    "district_id": "187115",
    "label": "Jagabaya III",
    "value": "Jagabaya III"
  },
  {
    "id": "1871161001",
    "district_id": "187116",
    "label": "Langkapura",
    "value": "Langkapura"
  },
  {
    "id": "1871161002",
    "district_id": "187116",
    "label": "Langkapura Baru",
    "value": "Langkapura Baru"
  },
  {
    "id": "1871161003",
    "district_id": "187116",
    "label": "Gunungterang",
    "value": "Gunungterang"
  },
  {
    "id": "1871161005",
    "district_id": "187116",
    "label": "Bilabong Jaya",
    "value": "Bilabong Jaya"
  },
  {
    "id": "1871161006",
    "district_id": "187116",
    "label": "Gunungagung",
    "value": "Gunungagung"
  },
  {
    "id": "1871171001",
    "district_id": "187117",
    "label": "Enggal",
    "value": "Enggal"
  },
  {
    "id": "1871171002",
    "district_id": "187117",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "1871171003",
    "district_id": "187117",
    "label": "Tanjungkarang",
    "value": "Tanjungkarang"
  },
  {
    "id": "1871171004",
    "district_id": "187117",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "1871171005",
    "district_id": "187117",
    "label": "Rawalaut",
    "value": "Rawalaut"
  },
  {
    "id": "1871171006",
    "district_id": "187117",
    "label": "Pahoman",
    "value": "Pahoman"
  },
  {
    "id": "1871181001",
    "district_id": "187118",
    "label": "Kedamaian",
    "value": "Kedamaian"
  },
  {
    "id": "1871181002",
    "district_id": "187118",
    "label": "Bumi Kedamaian",
    "value": "Bumi Kedamaian"
  },
  {
    "id": "1871181003",
    "district_id": "187118",
    "label": "Tanjungagung Raya",
    "value": "Tanjungagung Raya"
  },
  {
    "id": "1871181004",
    "district_id": "187118",
    "label": "Tanjungbaru",
    "value": "Tanjungbaru"
  },
  {
    "id": "1871181005",
    "district_id": "187118",
    "label": "Kalibalau Kencana",
    "value": "Kalibalau Kencana"
  },
  {
    "id": "1871181006",
    "district_id": "187118",
    "label": "Tanjungraya",
    "value": "Tanjungraya"
  },
  {
    "id": "1871181007",
    "district_id": "187118",
    "label": "Tanjunggading",
    "value": "Tanjunggading"
  },
  {
    "id": "1871191001",
    "district_id": "187119",
    "label": "Kota Karang",
    "value": "Kota Karang"
  },
  {
    "id": "1871191002",
    "district_id": "187119",
    "label": "Kota Karang Raya",
    "value": "Kota Karang Raya"
  },
  {
    "id": "1871191003",
    "district_id": "187119",
    "label": "Parwata",
    "value": "Parwata"
  },
  {
    "id": "1871191004",
    "district_id": "187119",
    "label": "Keteguhan",
    "value": "Keteguhan"
  },
  {
    "id": "1871191005",
    "district_id": "187119",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "1871191006",
    "district_id": "187119",
    "label": "Way Tataan",
    "value": "Way Tataan"
  },
  {
    "id": "1871201001",
    "district_id": "187120",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "1871201002",
    "district_id": "187120",
    "label": "Bumi Waras",
    "value": "Bumi Waras"
  },
  {
    "id": "1871201003",
    "district_id": "187120",
    "label": "Garuntang",
    "value": "Garuntang"
  },
  {
    "id": "1871201004",
    "district_id": "187120",
    "label": "Bumi Raya",
    "value": "Bumi Raya"
  },
  {
    "id": "1871201005",
    "district_id": "187120",
    "label": "Kangkung",
    "value": "Kangkung"
  },
  {
    "id": "1872011001",
    "district_id": "187201",
    "label": "Metro",
    "value": "Metro"
  },
  {
    "id": "1872011002",
    "district_id": "187201",
    "label": "Hadimulyo Timur",
    "value": "Hadimulyo Timur"
  },
  {
    "id": "1872011003",
    "district_id": "187201",
    "label": "Imopuro",
    "value": "Imopuro"
  },
  {
    "id": "1872011004",
    "district_id": "187201",
    "label": "Hadimulyo Barat",
    "value": "Hadimulyo Barat"
  },
  {
    "id": "1872011005",
    "district_id": "187201",
    "label": "Yosomulyo",
    "value": "Yosomulyo"
  },
  {
    "id": "1872021001",
    "district_id": "187202",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "1872021002",
    "district_id": "187202",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "1872021003",
    "district_id": "187202",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "1872021004",
    "district_id": "187202",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "1872031001",
    "district_id": "187203",
    "label": "Mulyojati",
    "value": "Mulyojati"
  },
  {
    "id": "1872031002",
    "district_id": "187203",
    "label": "Ganjar Agung",
    "value": "Ganjar Agung"
  },
  {
    "id": "1872031003",
    "district_id": "187203",
    "label": "Mulyosari",
    "value": "Mulyosari"
  },
  {
    "id": "1872031004",
    "district_id": "187203",
    "label": "Ganjar Asri",
    "value": "Ganjar Asri"
  },
  {
    "id": "1872041001",
    "district_id": "187204",
    "label": "Yosodadi",
    "value": "Yosodadi"
  },
  {
    "id": "1872041002",
    "district_id": "187204",
    "label": "Tejosari",
    "value": "Tejosari"
  },
  {
    "id": "1872041003",
    "district_id": "187204",
    "label": "Iring Mulyo",
    "value": "Iring Mulyo"
  },
  {
    "id": "1872041004",
    "district_id": "187204",
    "label": "Yosorejo",
    "value": "Yosorejo"
  },
  {
    "id": "1872041005",
    "district_id": "187204",
    "label": "Tejo Agung",
    "value": "Tejo Agung"
  },
  {
    "id": "1872051001",
    "district_id": "187205",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "1872051002",
    "district_id": "187205",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "1872051003",
    "district_id": "187205",
    "label": "Sumbersari Bantul",
    "value": "Sumbersari Bantul"
  },
  {
    "id": "1872051004",
    "district_id": "187205",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "1901011001",
    "district_id": "190101",
    "label": "Sungailiat",
    "value": "Sungailiat"
  },
  {
    "id": "1901011002",
    "district_id": "190101",
    "label": "Sri Menanti",
    "value": "Sri Menanti"
  },
  {
    "id": "1901011003",
    "district_id": "190101",
    "label": "Kudai",
    "value": "Kudai"
  },
  {
    "id": "1901011004",
    "district_id": "190101",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "1901011005",
    "district_id": "190101",
    "label": "Kenanga",
    "value": "Kenanga"
  },
  {
    "id": "1901011006",
    "district_id": "190101",
    "label": "Parit Padang",
    "value": "Parit Padang"
  },
  {
    "id": "1901011008",
    "district_id": "190101",
    "label": "Sinar Jaya Jelutung",
    "value": "Sinar Jaya Jelutung"
  },
  {
    "id": "1901011009",
    "district_id": "190101",
    "label": "Matras",
    "value": "Matras"
  },
  {
    "id": "1901011010",
    "district_id": "190101",
    "label": "Jelitik",
    "value": "Jelitik"
  },
  {
    "id": "1901011011",
    "district_id": "190101",
    "label": "Surya Timur",
    "value": "Surya Timur"
  },
  {
    "id": "1901011012",
    "district_id": "190101",
    "label": "Lubuk Kelik",
    "value": "Lubuk Kelik"
  },
  {
    "id": "1901011013",
    "district_id": "190101",
    "label": "Bukit Betung",
    "value": "Bukit Betung"
  },
  {
    "id": "1901012007",
    "district_id": "190101",
    "label": "Rebo",
    "value": "Rebo"
  },
  {
    "id": "1901021001",
    "district_id": "190102",
    "label": "Kuto Panji",
    "value": "Kuto Panji"
  },
  {
    "id": "1901021002",
    "district_id": "190102",
    "label": "Air Jukung",
    "value": "Air Jukung"
  },
  {
    "id": "1901021003",
    "district_id": "190102",
    "label": "Bukit Ketok",
    "value": "Bukit Ketok"
  },
  {
    "id": "1901021009",
    "district_id": "190102",
    "label": "Remodong Indah",
    "value": "Remodong Indah"
  },
  {
    "id": "1901021010",
    "district_id": "190102",
    "label": "Air Asam",
    "value": "Air Asam"
  },
  {
    "id": "1901021011",
    "district_id": "190102",
    "label": "Mantung",
    "value": "Mantung"
  },
  {
    "id": "1901021012",
    "district_id": "190102",
    "label": "Belinyu",
    "value": "Belinyu"
  },
  {
    "id": "1901022004",
    "district_id": "190102",
    "label": "Gunung Muda",
    "value": "Gunung Muda"
  },
  {
    "id": "1901022005",
    "district_id": "190102",
    "label": "Gunung Pelawan",
    "value": "Gunung Pelawan"
  },
  {
    "id": "1901022006",
    "district_id": "190102",
    "label": "Riding Panjang",
    "value": "Riding Panjang"
  },
  {
    "id": "1901022007",
    "district_id": "190102",
    "label": "Lumut",
    "value": "Lumut"
  },
  {
    "id": "1901022008",
    "district_id": "190102",
    "label": "Bintet",
    "value": "Bintet"
  },
  {
    "id": "1901032001",
    "district_id": "190103",
    "label": "Batu Rusa",
    "value": "Batu Rusa"
  },
  {
    "id": "1901032002",
    "district_id": "190103",
    "label": "Balun Ijuk",
    "value": "Balun Ijuk"
  },
  {
    "id": "1901032003",
    "district_id": "190103",
    "label": "Riding Panjang",
    "value": "Riding Panjang"
  },
  {
    "id": "1901032004",
    "district_id": "190103",
    "label": "Jurung",
    "value": "Jurung"
  },
  {
    "id": "1901032005",
    "district_id": "190103",
    "label": "Kimak",
    "value": "Kimak"
  },
  {
    "id": "1901032006",
    "district_id": "190103",
    "label": "Pagarawan",
    "value": "Pagarawan"
  },
  {
    "id": "1901032007",
    "district_id": "190103",
    "label": "Merawang",
    "value": "Merawang"
  },
  {
    "id": "1901032008",
    "district_id": "190103",
    "label": "Air Anyir",
    "value": "Air Anyir"
  },
  {
    "id": "1901032009",
    "district_id": "190103",
    "label": "Dwi Makmur",
    "value": "Dwi Makmur"
  },
  {
    "id": "1901032010",
    "district_id": "190103",
    "label": "Jada Bahrin",
    "value": "Jada Bahrin"
  },
  {
    "id": "1901042001",
    "district_id": "190104",
    "label": "Petaling",
    "value": "Petaling"
  },
  {
    "id": "1901042002",
    "district_id": "190104",
    "label": "Penagan",
    "value": "Penagan"
  },
  {
    "id": "1901042003",
    "district_id": "190104",
    "label": "Zed",
    "value": "Zed"
  },
  {
    "id": "1901042004",
    "district_id": "190104",
    "label": "Mendo",
    "value": "Mendo"
  },
  {
    "id": "1901042005",
    "district_id": "190104",
    "label": "Paya Benua",
    "value": "Paya Benua"
  },
  {
    "id": "1901042006",
    "district_id": "190104",
    "label": "Cengkong Abang",
    "value": "Cengkong Abang"
  },
  {
    "id": "1901042007",
    "district_id": "190104",
    "label": "Kace",
    "value": "Kace"
  },
  {
    "id": "1901042008",
    "district_id": "190104",
    "label": "Kemuja",
    "value": "Kemuja"
  },
  {
    "id": "1901042009",
    "district_id": "190104",
    "label": "Air Duren",
    "value": "Air Duren"
  },
  {
    "id": "1901042010",
    "district_id": "190104",
    "label": "Kota Kapur",
    "value": "Kota Kapur"
  },
  {
    "id": "1901042011",
    "district_id": "190104",
    "label": "Air Buluh",
    "value": "Air Buluh"
  },
  {
    "id": "1901042012",
    "district_id": "190104",
    "label": "Rukam",
    "value": "Rukam"
  },
  {
    "id": "1901042013",
    "district_id": "190104",
    "label": "Labuh Air Pandan",
    "value": "Labuh Air Pandan"
  },
  {
    "id": "1901042014",
    "district_id": "190104",
    "label": "Kace Timur",
    "value": "Kace Timur"
  },
  {
    "id": "1901042015",
    "district_id": "190104",
    "label": "Petaling Banjar",
    "value": "Petaling Banjar"
  },
  {
    "id": "1901052001",
    "district_id": "190105",
    "label": "Air Ruai",
    "value": "Air Ruai"
  },
  {
    "id": "1901052002",
    "district_id": "190105",
    "label": "Air Duren",
    "value": "Air Duren"
  },
  {
    "id": "1901052003",
    "district_id": "190105",
    "label": "Penyamun",
    "value": "Penyamun"
  },
  {
    "id": "1901052004",
    "district_id": "190105",
    "label": "Sempan",
    "value": "Sempan"
  },
  {
    "id": "1901052005",
    "district_id": "190105",
    "label": "Pemali",
    "value": "Pemali"
  },
  {
    "id": "1901052006",
    "district_id": "190105",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "1901062001",
    "district_id": "190106",
    "label": "Bakam",
    "value": "Bakam"
  },
  {
    "id": "1901062002",
    "district_id": "190106",
    "label": "Kapuk",
    "value": "Kapuk"
  },
  {
    "id": "1901062003",
    "district_id": "190106",
    "label": "Dalil",
    "value": "Dalil"
  },
  {
    "id": "1901062004",
    "district_id": "190106",
    "label": "Neknang",
    "value": "Neknang"
  },
  {
    "id": "1901062005",
    "district_id": "190106",
    "label": "Tiang Tarah",
    "value": "Tiang Tarah"
  },
  {
    "id": "1901062006",
    "district_id": "190106",
    "label": "Mangka",
    "value": "Mangka"
  },
  {
    "id": "1901062007",
    "district_id": "190106",
    "label": "Mabat",
    "value": "Mabat"
  },
  {
    "id": "1901062008",
    "district_id": "190106",
    "label": "Bukit Layang",
    "value": "Bukit Layang"
  },
  {
    "id": "1901062009",
    "district_id": "190106",
    "label": "Maras Senang",
    "value": "Maras Senang"
  },
  {
    "id": "1901072001",
    "district_id": "190107",
    "label": "Riau",
    "value": "Riau"
  },
  {
    "id": "1901072002",
    "district_id": "190107",
    "label": "Pangkal Niur",
    "value": "Pangkal Niur"
  },
  {
    "id": "1901072003",
    "district_id": "190107",
    "label": "Pugul",
    "value": "Pugul"
  },
  {
    "id": "1901072004",
    "district_id": "190107",
    "label": "Cit",
    "value": "Cit"
  },
  {
    "id": "1901072005",
    "district_id": "190107",
    "label": "Deniang",
    "value": "Deniang"
  },
  {
    "id": "1901072006",
    "district_id": "190107",
    "label": "Silip",
    "value": "Silip"
  },
  {
    "id": "1901072007",
    "district_id": "190107",
    "label": "Mapur",
    "value": "Mapur"
  },
  {
    "id": "1901072008",
    "district_id": "190107",
    "label": "Banyu Asin",
    "value": "Banyu Asin"
  },
  {
    "id": "1901072009",
    "district_id": "190107",
    "label": "Berbura",
    "value": "Berbura"
  },
  {
    "id": "1901082001",
    "district_id": "190108",
    "label": "Puding Besar",
    "value": "Puding Besar"
  },
  {
    "id": "1901082002",
    "district_id": "190108",
    "label": "Labu",
    "value": "Labu"
  },
  {
    "id": "1901082003",
    "district_id": "190108",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "1901082004",
    "district_id": "190108",
    "label": "Tanah Bawah",
    "value": "Tanah Bawah"
  },
  {
    "id": "1901082005",
    "district_id": "190108",
    "label": "Saing",
    "value": "Saing"
  },
  {
    "id": "1901082006",
    "district_id": "190108",
    "label": "Kota Waringin",
    "value": "Kota Waringin"
  },
  {
    "id": "1901082007",
    "district_id": "190108",
    "label": "Kayu Besi",
    "value": "Kayu Besi"
  },
  {
    "id": "1902011001",
    "district_id": "190201",
    "label": "Kota",
    "value": "Kota"
  },
  {
    "id": "1902011002",
    "district_id": "190201",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "1902011005",
    "district_id": "190201",
    "label": "Lesung Batang",
    "value": "Lesung Batang"
  },
  {
    "id": "1902011006",
    "district_id": "190201",
    "label": "Pangkallalang",
    "value": "Pangkallalang"
  },
  {
    "id": "1902011009",
    "district_id": "190201",
    "label": "Tanjungpendam",
    "value": "Tanjungpendam"
  },
  {
    "id": "1902011011",
    "district_id": "190201",
    "label": "Paal Satu",
    "value": "Paal Satu"
  },
  {
    "id": "1902011016",
    "district_id": "190201",
    "label": "Kampong Damai",
    "value": "Kampong Damai"
  },
  {
    "id": "1902012003",
    "district_id": "190201",
    "label": "Buluh Tumbang",
    "value": "Buluh Tumbang"
  },
  {
    "id": "1902012004",
    "district_id": "190201",
    "label": "Perawas",
    "value": "Perawas"
  },
  {
    "id": "1902012007",
    "district_id": "190201",
    "label": "Dukong",
    "value": "Dukong"
  },
  {
    "id": "1902012008",
    "district_id": "190201",
    "label": "Juru Seberang",
    "value": "Juru Seberang"
  },
  {
    "id": "1902012010",
    "district_id": "190201",
    "label": "Air Saga",
    "value": "Air Saga"
  },
  {
    "id": "1902012012",
    "district_id": "190201",
    "label": "Air Merbau",
    "value": "Air Merbau"
  },
  {
    "id": "1902012013",
    "district_id": "190201",
    "label": "Aik Pelempang Jaya",
    "value": "Aik Pelempang Jaya"
  },
  {
    "id": "1902012014",
    "district_id": "190201",
    "label": "Aik Ketekok",
    "value": "Aik Ketekok"
  },
  {
    "id": "1902012015",
    "district_id": "190201",
    "label": "Aik Rayak",
    "value": "Aik Rayak"
  },
  {
    "id": "1902022001",
    "district_id": "190202",
    "label": "Membalong",
    "value": "Membalong"
  },
  {
    "id": "1902022002",
    "district_id": "190202",
    "label": "Perpat",
    "value": "Perpat"
  },
  {
    "id": "1902022003",
    "district_id": "190202",
    "label": "Lassar",
    "value": "Lassar"
  },
  {
    "id": "1902022004",
    "district_id": "190202",
    "label": "Simpang Rusa",
    "value": "Simpang Rusa"
  },
  {
    "id": "1902022005",
    "district_id": "190202",
    "label": "Kembiri",
    "value": "Kembiri"
  },
  {
    "id": "1902022006",
    "district_id": "190202",
    "label": "Bantan",
    "value": "Bantan"
  },
  {
    "id": "1902022007",
    "district_id": "190202",
    "label": "Tanjungrusa",
    "value": "Tanjungrusa"
  },
  {
    "id": "1902022008",
    "district_id": "190202",
    "label": "Mentigi",
    "value": "Mentigi"
  },
  {
    "id": "1902022009",
    "district_id": "190202",
    "label": "Pulau Seliu",
    "value": "Pulau Seliu"
  },
  {
    "id": "1902022010",
    "district_id": "190202",
    "label": "Pulau Sumedang",
    "value": "Pulau Sumedang"
  },
  {
    "id": "1902022011",
    "district_id": "190202",
    "label": "Gunung Riting",
    "value": "Gunung Riting"
  },
  {
    "id": "1902022012",
    "district_id": "190202",
    "label": "Padang Kandis",
    "value": "Padang Kandis"
  },
  {
    "id": "1902032001",
    "district_id": "190203",
    "label": "Selat Nasik",
    "value": "Selat Nasik"
  },
  {
    "id": "1902032002",
    "district_id": "190203",
    "label": "Petaling",
    "value": "Petaling"
  },
  {
    "id": "1902032003",
    "district_id": "190203",
    "label": "Suak Gual",
    "value": "Suak Gual"
  },
  {
    "id": "1902032004",
    "district_id": "190203",
    "label": "Pulau Gersik",
    "value": "Pulau Gersik"
  },
  {
    "id": "1902042001",
    "district_id": "190204",
    "label": "Sijuk",
    "value": "Sijuk"
  },
  {
    "id": "1902042002",
    "district_id": "190204",
    "label": "Batu Itam",
    "value": "Batu Itam"
  },
  {
    "id": "1902042003",
    "district_id": "190204",
    "label": "Terong",
    "value": "Terong"
  },
  {
    "id": "1902042004",
    "district_id": "190204",
    "label": "Air Seruk",
    "value": "Air Seruk"
  },
  {
    "id": "1902042005",
    "district_id": "190204",
    "label": "Tanjung Binga",
    "value": "Tanjung Binga"
  },
  {
    "id": "1902042006",
    "district_id": "190204",
    "label": "Air Selumar",
    "value": "Air Selumar"
  },
  {
    "id": "1902042007",
    "district_id": "190204",
    "label": "Sungai Padang",
    "value": "Sungai Padang"
  },
  {
    "id": "1902042008",
    "district_id": "190204",
    "label": "Keciput",
    "value": "Keciput"
  },
  {
    "id": "1902042009",
    "district_id": "190204",
    "label": "Pelepak Pute",
    "value": "Pelepak Pute"
  },
  {
    "id": "1902042010",
    "district_id": "190204",
    "label": "Tanjong Tinggi",
    "value": "Tanjong Tinggi"
  },
  {
    "id": "1902052001",
    "district_id": "190205",
    "label": "Badau",
    "value": "Badau"
  },
  {
    "id": "1902052002",
    "district_id": "190205",
    "label": "Air Batu Buding",
    "value": "Air Batu Buding"
  },
  {
    "id": "1902052003",
    "district_id": "190205",
    "label": "Sungai Samak",
    "value": "Sungai Samak"
  },
  {
    "id": "1902052004",
    "district_id": "190205",
    "label": "Kacang Butor",
    "value": "Kacang Butor"
  },
  {
    "id": "1902052005",
    "district_id": "190205",
    "label": "Cerucuk",
    "value": "Cerucuk"
  },
  {
    "id": "1902052006",
    "district_id": "190205",
    "label": "Pegantungan",
    "value": "Pegantungan"
  },
  {
    "id": "1902052007",
    "district_id": "190205",
    "label": "Ibul",
    "value": "Ibul"
  },
  {
    "id": "1903011001",
    "district_id": "190301",
    "label": "Toboali",
    "value": "Toboali"
  },
  {
    "id": "1903011002",
    "district_id": "190301",
    "label": "Teladan",
    "value": "Teladan"
  },
  {
    "id": "1903011003",
    "district_id": "190301",
    "label": "Tanjungketapang",
    "value": "Tanjungketapang"
  },
  {
    "id": "1903012004",
    "district_id": "190301",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "1903012005",
    "district_id": "190301",
    "label": "Jeriji",
    "value": "Jeriji"
  },
  {
    "id": "1903012006",
    "district_id": "190301",
    "label": "Bikang",
    "value": "Bikang"
  },
  {
    "id": "1903012007",
    "district_id": "190301",
    "label": "Gadung",
    "value": "Gadung"
  },
  {
    "id": "1903012008",
    "district_id": "190301",
    "label": "Rias",
    "value": "Rias"
  },
  {
    "id": "1903012010",
    "district_id": "190301",
    "label": "Kepoh",
    "value": "Kepoh"
  },
  {
    "id": "1903012012",
    "district_id": "190301",
    "label": "Keposang",
    "value": "Keposang"
  },
  {
    "id": "1903012013",
    "district_id": "190301",
    "label": "Rindik",
    "value": "Rindik"
  },
  {
    "id": "1903022001",
    "district_id": "190302",
    "label": "Tanjunglabu",
    "value": "Tanjunglabu"
  },
  {
    "id": "1903022002",
    "district_id": "190302",
    "label": "Tanjungsangkar",
    "value": "Tanjungsangkar"
  },
  {
    "id": "1903022004",
    "district_id": "190302",
    "label": "Kumbung",
    "value": "Kumbung"
  },
  {
    "id": "1903022005",
    "district_id": "190302",
    "label": "Penutuk",
    "value": "Penutuk"
  },
  {
    "id": "1903032001",
    "district_id": "190303",
    "label": "Airgegas",
    "value": "Airgegas"
  },
  {
    "id": "1903032002",
    "district_id": "190303",
    "label": "Delas",
    "value": "Delas"
  },
  {
    "id": "1903032003",
    "district_id": "190303",
    "label": "Pergam",
    "value": "Pergam"
  },
  {
    "id": "1903032004",
    "district_id": "190303",
    "label": "Bencah",
    "value": "Bencah"
  },
  {
    "id": "1903032005",
    "district_id": "190303",
    "label": "Nyelanding",
    "value": "Nyelanding"
  },
  {
    "id": "1903032006",
    "district_id": "190303",
    "label": "Nangka",
    "value": "Nangka"
  },
  {
    "id": "1903032007",
    "district_id": "190303",
    "label": "Ranggas",
    "value": "Ranggas"
  },
  {
    "id": "1903032008",
    "district_id": "190303",
    "label": "Airbara",
    "value": "Airbara"
  },
  {
    "id": "1903032009",
    "district_id": "190303",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "1903032010",
    "district_id": "190303",
    "label": "Tepus",
    "value": "Tepus"
  },
  {
    "id": "1903042001",
    "district_id": "190304",
    "label": "Jelutung II",
    "value": "Jelutung II"
  },
  {
    "id": "1903042002",
    "district_id": "190304",
    "label": "Gudang",
    "value": "Gudang"
  },
  {
    "id": "1903042003",
    "district_id": "190304",
    "label": "Bangka Kota",
    "value": "Bangka Kota"
  },
  {
    "id": "1903042004",
    "district_id": "190304",
    "label": "Rajik",
    "value": "Rajik"
  },
  {
    "id": "1903042005",
    "district_id": "190304",
    "label": "Sebagin",
    "value": "Sebagin"
  },
  {
    "id": "1903042006",
    "district_id": "190304",
    "label": "Simpang Rimba",
    "value": "Simpang Rimba"
  },
  {
    "id": "1903042007",
    "district_id": "190304",
    "label": "Permis",
    "value": "Permis"
  },
  {
    "id": "1903052001",
    "district_id": "190305",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "1903052002",
    "district_id": "190305",
    "label": "Malik",
    "value": "Malik"
  },
  {
    "id": "1903052003",
    "district_id": "190305",
    "label": "Sengir",
    "value": "Sengir"
  },
  {
    "id": "1903052004",
    "district_id": "190305",
    "label": "Pangkal Buluh",
    "value": "Pangkal Buluh"
  },
  {
    "id": "1903052005",
    "district_id": "190305",
    "label": "Irat",
    "value": "Irat"
  },
  {
    "id": "1903052006",
    "district_id": "190305",
    "label": "Bedengung",
    "value": "Bedengung"
  },
  {
    "id": "1903052008",
    "district_id": "190305",
    "label": "Nadung",
    "value": "Nadung"
  },
  {
    "id": "1903052009",
    "district_id": "190305",
    "label": "Ranggung",
    "value": "Ranggung"
  },
  {
    "id": "1903052012",
    "district_id": "190305",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "1903062001",
    "district_id": "190306",
    "label": "Sadai",
    "value": "Sadai"
  },
  {
    "id": "1903062002",
    "district_id": "190306",
    "label": "Tukak",
    "value": "Tukak"
  },
  {
    "id": "1903062003",
    "district_id": "190306",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1903062004",
    "district_id": "190306",
    "label": "Tiram",
    "value": "Tiram"
  },
  {
    "id": "1903062005",
    "district_id": "190306",
    "label": "Bukit Terap",
    "value": "Bukit Terap"
  },
  {
    "id": "1903072001",
    "district_id": "190307",
    "label": "Batu Betumpang",
    "value": "Batu Betumpang"
  },
  {
    "id": "1903072002",
    "district_id": "190307",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "1903072003",
    "district_id": "190307",
    "label": "Panca Tunggal",
    "value": "Panca Tunggal"
  },
  {
    "id": "1903072004",
    "district_id": "190307",
    "label": "Sumber Jaya Permai",
    "value": "Sumber Jaya Permai"
  },
  {
    "id": "1903072005",
    "district_id": "190307",
    "label": "Fajar Indah",
    "value": "Fajar Indah"
  },
  {
    "id": "1903082001",
    "district_id": "190308",
    "label": "Pongok",
    "value": "Pongok"
  },
  {
    "id": "1903082002",
    "district_id": "190308",
    "label": "Celagen",
    "value": "Celagen"
  },
  {
    "id": "1904011001",
    "district_id": "190401",
    "label": "Koba",
    "value": "Koba"
  },
  {
    "id": "1904011002",
    "district_id": "190401",
    "label": "Arung Dalam",
    "value": "Arung Dalam"
  },
  {
    "id": "1904011017",
    "district_id": "190401",
    "label": "Berok",
    "value": "Berok"
  },
  {
    "id": "1904011018",
    "district_id": "190401",
    "label": "Padang Mulia",
    "value": "Padang Mulia"
  },
  {
    "id": "1904011019",
    "district_id": "190401",
    "label": "Simpang Perlang",
    "value": "Simpang Perlang"
  },
  {
    "id": "1904012003",
    "district_id": "190401",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "1904012005",
    "district_id": "190401",
    "label": "Kurau (Timur)",
    "value": "Kurau (Timur)"
  },
  {
    "id": "1904012006",
    "district_id": "190401",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "1904012007",
    "district_id": "190401",
    "label": "Penyak",
    "value": "Penyak"
  },
  {
    "id": "1904012008",
    "district_id": "190401",
    "label": "Terentang III",
    "value": "Terentang III"
  },
  {
    "id": "1904012010",
    "district_id": "190401",
    "label": "Kurau Barat",
    "value": "Kurau Barat"
  },
  {
    "id": "1904021001",
    "district_id": "190402",
    "label": "Dul",
    "value": "Dul"
  },
  {
    "id": "1904022004",
    "district_id": "190402",
    "label": "Air Mesu",
    "value": "Air Mesu"
  },
  {
    "id": "1904022006",
    "district_id": "190402",
    "label": "Mangkol",
    "value": "Mangkol"
  },
  {
    "id": "1904022007",
    "district_id": "190402",
    "label": "Tanjung Gunung",
    "value": "Tanjung Gunung"
  },
  {
    "id": "1904022010",
    "district_id": "190402",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "1904022012",
    "district_id": "190402",
    "label": "Padang Baru",
    "value": "Padang Baru"
  },
  {
    "id": "1904022013",
    "district_id": "190402",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "1904022014",
    "district_id": "190402",
    "label": "Beluluk",
    "value": "Beluluk"
  },
  {
    "id": "1904022016",
    "district_id": "190402",
    "label": "Pedindang",
    "value": "Pedindang"
  },
  {
    "id": "1904022018",
    "district_id": "190402",
    "label": "Batu Belubang",
    "value": "Batu Belubang"
  },
  {
    "id": "1904022020",
    "district_id": "190402",
    "label": "Air Mesu Timur",
    "value": "Air Mesu Timur"
  },
  {
    "id": "1904022021",
    "district_id": "190402",
    "label": "Kebintik",
    "value": "Kebintik"
  },
  {
    "id": "1904031001",
    "district_id": "190403",
    "label": "Sungai Selan",
    "value": "Sungai Selan"
  },
  {
    "id": "1904032002",
    "district_id": "190403",
    "label": "Kerantai",
    "value": "Kerantai"
  },
  {
    "id": "1904032003",
    "district_id": "190403",
    "label": "Keretak",
    "value": "Keretak"
  },
  {
    "id": "1904032004",
    "district_id": "190403",
    "label": "Lampur",
    "value": "Lampur"
  },
  {
    "id": "1904032005",
    "district_id": "190403",
    "label": "Sarang Mandi",
    "value": "Sarang Mandi"
  },
  {
    "id": "1904032006",
    "district_id": "190403",
    "label": "Munggu",
    "value": "Munggu"
  },
  {
    "id": "1904032007",
    "district_id": "190403",
    "label": "Kemingking",
    "value": "Kemingking"
  },
  {
    "id": "1904032008",
    "district_id": "190403",
    "label": "Sungai Selan Atas",
    "value": "Sungai Selan Atas"
  },
  {
    "id": "1904032009",
    "district_id": "190403",
    "label": "Tanjung Pura",
    "value": "Tanjung Pura"
  },
  {
    "id": "1904032010",
    "district_id": "190403",
    "label": "Romadhon",
    "value": "Romadhon"
  },
  {
    "id": "1904032011",
    "district_id": "190403",
    "label": "Kerakas",
    "value": "Kerakas"
  },
  {
    "id": "1904032012",
    "district_id": "190403",
    "label": "Melabun",
    "value": "Melabun"
  },
  {
    "id": "1904032013",
    "district_id": "190403",
    "label": "Keretak Atas",
    "value": "Keretak Atas"
  },
  {
    "id": "1904042001",
    "district_id": "190404",
    "label": "Simpang Katis",
    "value": "Simpang Katis"
  },
  {
    "id": "1904042002",
    "district_id": "190404",
    "label": "Celuak",
    "value": "Celuak"
  },
  {
    "id": "1904042003",
    "district_id": "190404",
    "label": "Sungkap",
    "value": "Sungkap"
  },
  {
    "id": "1904042004",
    "district_id": "190404",
    "label": "Beruas",
    "value": "Beruas"
  },
  {
    "id": "1904042005",
    "district_id": "190404",
    "label": "Puput",
    "value": "Puput"
  },
  {
    "id": "1904042006",
    "district_id": "190404",
    "label": "Teru",
    "value": "Teru"
  },
  {
    "id": "1904042007",
    "district_id": "190404",
    "label": "Terak",
    "value": "Terak"
  },
  {
    "id": "1904042008",
    "district_id": "190404",
    "label": "Pasir Garam",
    "value": "Pasir Garam"
  },
  {
    "id": "1904042009",
    "district_id": "190404",
    "label": "Katis",
    "value": "Katis"
  },
  {
    "id": "1904042010",
    "district_id": "190404",
    "label": "Pinang Sebatang",
    "value": "Pinang Sebatang"
  },
  {
    "id": "1904052001",
    "district_id": "190405",
    "label": "Namang",
    "value": "Namang"
  },
  {
    "id": "1904052002",
    "district_id": "190405",
    "label": "Cambai",
    "value": "Cambai"
  },
  {
    "id": "1904052003",
    "district_id": "190405",
    "label": "Kayu Besi",
    "value": "Kayu Besi"
  },
  {
    "id": "1904052004",
    "district_id": "190405",
    "label": "Jelutung",
    "value": "Jelutung"
  },
  {
    "id": "1904052005",
    "district_id": "190405",
    "label": "Belilik",
    "value": "Belilik"
  },
  {
    "id": "1904052006",
    "district_id": "190405",
    "label": "Bhaskara Bhakti",
    "value": "Bhaskara Bhakti"
  },
  {
    "id": "1904052008",
    "district_id": "190405",
    "label": "Bukit Kijang",
    "value": "Bukit Kijang"
  },
  {
    "id": "1904052009",
    "district_id": "190405",
    "label": "Cambai Selatan",
    "value": "Cambai Selatan"
  },
  {
    "id": "1904062001",
    "district_id": "190406",
    "label": "Perlang",
    "value": "Perlang"
  },
  {
    "id": "1904062002",
    "district_id": "190406",
    "label": "Batu Beriga",
    "value": "Batu Beriga"
  },
  {
    "id": "1904062003",
    "district_id": "190406",
    "label": "Lubuk Besar",
    "value": "Lubuk Besar"
  },
  {
    "id": "1904062004",
    "district_id": "190406",
    "label": "Kulur",
    "value": "Kulur"
  },
  {
    "id": "1904062005",
    "district_id": "190406",
    "label": "Lubuk Lingkuk",
    "value": "Lubuk Lingkuk"
  },
  {
    "id": "1904062006",
    "district_id": "190406",
    "label": "Lubuk Pabrik",
    "value": "Lubuk Pabrik"
  },
  {
    "id": "1904062007",
    "district_id": "190406",
    "label": "Trubus",
    "value": "Trubus"
  },
  {
    "id": "1904062008",
    "district_id": "190406",
    "label": "Kulur Ilir",
    "value": "Kulur Ilir"
  },
  {
    "id": "1904062009",
    "district_id": "190406",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "1905011001",
    "district_id": "190501",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "1905011002",
    "district_id": "190501",
    "label": "Sungai Daeng",
    "value": "Sungai Daeng"
  },
  {
    "id": "1905011003",
    "district_id": "190501",
    "label": "Sungai Baru",
    "value": "Sungai Baru"
  },
  {
    "id": "1905012004",
    "district_id": "190501",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "1905012005",
    "district_id": "190501",
    "label": "Air Belo",
    "value": "Air Belo"
  },
  {
    "id": "1905012006",
    "district_id": "190501",
    "label": "Belo Laut",
    "value": "Belo Laut"
  },
  {
    "id": "1905012007",
    "district_id": "190501",
    "label": "Air Limau",
    "value": "Air Limau"
  },
  {
    "id": "1905022001",
    "district_id": "190502",
    "label": "Pelangas",
    "value": "Pelangas"
  },
  {
    "id": "1905022002",
    "district_id": "190502",
    "label": "Kundi",
    "value": "Kundi"
  },
  {
    "id": "1905022003",
    "district_id": "190502",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "1905022004",
    "district_id": "190502",
    "label": "Peradong",
    "value": "Peradong"
  },
  {
    "id": "1905022005",
    "district_id": "190502",
    "label": "Air Nyatoh",
    "value": "Air Nyatoh"
  },
  {
    "id": "1905022006",
    "district_id": "190502",
    "label": "Berang",
    "value": "Berang"
  },
  {
    "id": "1905022007",
    "district_id": "190502",
    "label": "Rambat",
    "value": "Rambat"
  },
  {
    "id": "1905022008",
    "district_id": "190502",
    "label": "Simpang Gong",
    "value": "Simpang Gong"
  },
  {
    "id": "1905022009",
    "district_id": "190502",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1905022010",
    "district_id": "190502",
    "label": "Ibul",
    "value": "Ibul"
  },
  {
    "id": "1905022011",
    "district_id": "190502",
    "label": "Pangek",
    "value": "Pangek"
  },
  {
    "id": "1905022012",
    "district_id": "190502",
    "label": "Bukit Terak",
    "value": "Bukit Terak"
  },
  {
    "id": "1905022013",
    "district_id": "190502",
    "label": "Air Menduyung",
    "value": "Air Menduyung"
  },
  {
    "id": "1905032001",
    "district_id": "190503",
    "label": "Jebus",
    "value": "Jebus"
  },
  {
    "id": "1905032002",
    "district_id": "190503",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "1905032003",
    "district_id": "190503",
    "label": "Tumbak Petar",
    "value": "Tumbak Petar"
  },
  {
    "id": "1905032004",
    "district_id": "190503",
    "label": "Limbung",
    "value": "Limbung"
  },
  {
    "id": "1905032005",
    "district_id": "190503",
    "label": "Rukam",
    "value": "Rukam"
  },
  {
    "id": "1905032008",
    "district_id": "190503",
    "label": "Ranggi Asam",
    "value": "Ranggi Asam"
  },
  {
    "id": "1905032009",
    "district_id": "190503",
    "label": "Ketap",
    "value": "Ketap"
  },
  {
    "id": "1905032018",
    "district_id": "190503",
    "label": "Mislak",
    "value": "Mislak"
  },
  {
    "id": "1905032019",
    "district_id": "190503",
    "label": "Pebuar",
    "value": "Pebuar"
  },
  {
    "id": "1905032020",
    "district_id": "190503",
    "label": "Sinar Manik",
    "value": "Sinar Manik"
  },
  {
    "id": "1905032021",
    "district_id": "190503",
    "label": "Air Kuang",
    "value": "Air Kuang"
  },
  {
    "id": "1905041001",
    "district_id": "190504",
    "label": "Kelapa",
    "value": "Kelapa"
  },
  {
    "id": "1905042002",
    "district_id": "190504",
    "label": "Mancung",
    "value": "Mancung"
  },
  {
    "id": "1905042003",
    "district_id": "190504",
    "label": "Kayu Arang",
    "value": "Kayu Arang"
  },
  {
    "id": "1905042004",
    "district_id": "190504",
    "label": "Pusuk",
    "value": "Pusuk"
  },
  {
    "id": "1905042005",
    "district_id": "190504",
    "label": "Dendang",
    "value": "Dendang"
  },
  {
    "id": "1905042006",
    "district_id": "190504",
    "label": "Kacung",
    "value": "Kacung"
  },
  {
    "id": "1905042007",
    "district_id": "190504",
    "label": "Sinar Sari",
    "value": "Sinar Sari"
  },
  {
    "id": "1905042008",
    "district_id": "190504",
    "label": "Beruas",
    "value": "Beruas"
  },
  {
    "id": "1905042009",
    "district_id": "190504",
    "label": "Tebing",
    "value": "Tebing"
  },
  {
    "id": "1905042010",
    "district_id": "190504",
    "label": "Terentang",
    "value": "Terentang"
  },
  {
    "id": "1905042011",
    "district_id": "190504",
    "label": "Tuik",
    "value": "Tuik"
  },
  {
    "id": "1905042012",
    "district_id": "190504",
    "label": "Tugang",
    "value": "Tugang"
  },
  {
    "id": "1905042013",
    "district_id": "190504",
    "label": "Air Bulin",
    "value": "Air Bulin"
  },
  {
    "id": "1905042014",
    "district_id": "190504",
    "label": "Pangkal Beras",
    "value": "Pangkal Beras"
  },
  {
    "id": "1905052001",
    "district_id": "190505",
    "label": "Tempilang",
    "value": "Tempilang"
  },
  {
    "id": "1905052002",
    "district_id": "190505",
    "label": "Tanjung Niur",
    "value": "Tanjung Niur"
  },
  {
    "id": "1905052003",
    "district_id": "190505",
    "label": "Sangku",
    "value": "Sangku"
  },
  {
    "id": "1905052004",
    "district_id": "190505",
    "label": "Buyan Kelumbi",
    "value": "Buyan Kelumbi"
  },
  {
    "id": "1905052005",
    "district_id": "190505",
    "label": "Penyampak",
    "value": "Penyampak"
  },
  {
    "id": "1905052006",
    "district_id": "190505",
    "label": "Benteng Kota",
    "value": "Benteng Kota"
  },
  {
    "id": "1905052007",
    "district_id": "190505",
    "label": "Air Lintang",
    "value": "Air Lintang"
  },
  {
    "id": "1905052008",
    "district_id": "190505",
    "label": "Sinar Surya",
    "value": "Sinar Surya"
  },
  {
    "id": "1905052009",
    "district_id": "190505",
    "label": "Simpang Yul",
    "value": "Simpang Yul"
  },
  {
    "id": "1905062001",
    "district_id": "190506",
    "label": "Sekar Biru",
    "value": "Sekar Biru"
  },
  {
    "id": "1905062002",
    "district_id": "190506",
    "label": "Telak",
    "value": "Telak"
  },
  {
    "id": "1905062003",
    "district_id": "190506",
    "label": "Cupat",
    "value": "Cupat"
  },
  {
    "id": "1905062004",
    "district_id": "190506",
    "label": "Bakit",
    "value": "Bakit"
  },
  {
    "id": "1905062005",
    "district_id": "190506",
    "label": "Puput",
    "value": "Puput"
  },
  {
    "id": "1905062006",
    "district_id": "190506",
    "label": "Air Gantang",
    "value": "Air Gantang"
  },
  {
    "id": "1905062007",
    "district_id": "190506",
    "label": "Kelabat",
    "value": "Kelabat"
  },
  {
    "id": "1905062008",
    "district_id": "190506",
    "label": "Kapit",
    "value": "Kapit"
  },
  {
    "id": "1905062009",
    "district_id": "190506",
    "label": "Semulut",
    "value": "Semulut"
  },
  {
    "id": "1905062010",
    "district_id": "190506",
    "label": "Teluk Limau",
    "value": "Teluk Limau"
  },
  {
    "id": "1906012001",
    "district_id": "190601",
    "label": "Lalang Jaya",
    "value": "Lalang Jaya"
  },
  {
    "id": "1906012002",
    "district_id": "190601",
    "label": "Kurnia Jaya",
    "value": "Kurnia Jaya"
  },
  {
    "id": "1906012003",
    "district_id": "190601",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "1906012004",
    "district_id": "190601",
    "label": "Kelubi",
    "value": "Kelubi"
  },
  {
    "id": "1906012006",
    "district_id": "190601",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "1906012007",
    "district_id": "190601",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "1906012010",
    "district_id": "190601",
    "label": "Buku Limau",
    "value": "Buku Limau"
  },
  {
    "id": "1906012012",
    "district_id": "190601",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "1906012013",
    "district_id": "190601",
    "label": "Bentaian Jaya",
    "value": "Bentaian Jaya"
  },
  {
    "id": "1906022001",
    "district_id": "190602",
    "label": "Gantung",
    "value": "Gantung"
  },
  {
    "id": "1906022002",
    "district_id": "190602",
    "label": "Selingsing",
    "value": "Selingsing"
  },
  {
    "id": "1906022003",
    "district_id": "190602",
    "label": "Jangkar Asam",
    "value": "Jangkar Asam"
  },
  {
    "id": "1906022004",
    "district_id": "190602",
    "label": "Lilangan",
    "value": "Lilangan"
  },
  {
    "id": "1906022009",
    "district_id": "190602",
    "label": "Lenggang",
    "value": "Lenggang"
  },
  {
    "id": "1906022010",
    "district_id": "190602",
    "label": "Batu Penyu",
    "value": "Batu Penyu"
  },
  {
    "id": "1906022011",
    "district_id": "190602",
    "label": "Limbongan",
    "value": "Limbongan"
  },
  {
    "id": "1906032001",
    "district_id": "190603",
    "label": "Dendang",
    "value": "Dendang"
  },
  {
    "id": "1906032002",
    "district_id": "190603",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  {
    "id": "1906032005",
    "district_id": "190603",
    "label": "Nyuruk",
    "value": "Nyuruk"
  },
  {
    "id": "1906032008",
    "district_id": "190603",
    "label": "Balok",
    "value": "Balok"
  },
  {
    "id": "1906042001",
    "district_id": "190604",
    "label": "Mentawak",
    "value": "Mentawak"
  },
  {
    "id": "1906042002",
    "district_id": "190604",
    "label": "Senyubuk",
    "value": "Senyubuk"
  },
  {
    "id": "1906042003",
    "district_id": "190604",
    "label": "Cendil",
    "value": "Cendil"
  },
  {
    "id": "1906042005",
    "district_id": "190604",
    "label": "Buding",
    "value": "Buding"
  },
  {
    "id": "1906042006",
    "district_id": "190604",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "1906042007",
    "district_id": "190604",
    "label": "Pembaharuan",
    "value": "Pembaharuan"
  },
  {
    "id": "1906052001",
    "district_id": "190605",
    "label": "Air Kelik",
    "value": "Air Kelik"
  },
  {
    "id": "1906052002",
    "district_id": "190605",
    "label": "Mempaya",
    "value": "Mempaya"
  },
  {
    "id": "1906052003",
    "district_id": "190605",
    "label": "Burong Mandi",
    "value": "Burong Mandi"
  },
  {
    "id": "1906052004",
    "district_id": "190605",
    "label": "Mengkubang",
    "value": "Mengkubang"
  },
  {
    "id": "1906052005",
    "district_id": "190605",
    "label": "Sukamandi",
    "value": "Sukamandi"
  },
  {
    "id": "1906062001",
    "district_id": "190606",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "1906062002",
    "district_id": "190606",
    "label": "Renggiang",
    "value": "Renggiang"
  },
  {
    "id": "1906062003",
    "district_id": "190606",
    "label": "Aik Madu",
    "value": "Aik Madu"
  },
  {
    "id": "1906062004",
    "district_id": "190606",
    "label": "Lintang",
    "value": "Lintang"
  },
  {
    "id": "1906072001",
    "district_id": "190607",
    "label": "Simpang Pesak",
    "value": "Simpang Pesak"
  },
  {
    "id": "1906072002",
    "district_id": "190607",
    "label": "Tanjung Batu Itam",
    "value": "Tanjung Batu Itam"
  },
  {
    "id": "1906072003",
    "district_id": "190607",
    "label": "Tanjung Kelumpang",
    "value": "Tanjung Kelumpang"
  },
  {
    "id": "1906072004",
    "district_id": "190607",
    "label": "Dukong",
    "value": "Dukong"
  },
  {
    "id": "1971011004",
    "district_id": "197101",
    "label": "Semabung Lama",
    "value": "Semabung Lama"
  },
  {
    "id": "1971011006",
    "district_id": "197101",
    "label": "Bacang",
    "value": "Bacang"
  },
  {
    "id": "1971011007",
    "district_id": "197101",
    "label": "Air Itam",
    "value": "Air Itam"
  },
  {
    "id": "1971011008",
    "district_id": "197101",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "1971011009",
    "district_id": "197101",
    "label": "Temberan",
    "value": "Temberan"
  },
  {
    "id": "1971011010",
    "district_id": "197101",
    "label": "Sinar Bulan",
    "value": "Sinar Bulan"
  },
  {
    "id": "1971011011",
    "district_id": "197101",
    "label": "Air Mawar",
    "value": "Air Mawar"
  },
  {
    "id": "1971021001",
    "district_id": "197102",
    "label": "Opas Indah",
    "value": "Opas Indah"
  },
  {
    "id": "1971021002",
    "district_id": "197102",
    "label": "Gedung Nasional",
    "value": "Gedung Nasional"
  },
  {
    "id": "1971021003",
    "district_id": "197102",
    "label": "Batin Tikal",
    "value": "Batin Tikal"
  },
  {
    "id": "1971021004",
    "district_id": "197102",
    "label": "Rawa Bangun",
    "value": "Rawa Bangun"
  },
  {
    "id": "1971021005",
    "district_id": "197102",
    "label": "Kejaksaan",
    "value": "Kejaksaan"
  },
  {
    "id": "1971031004",
    "district_id": "197103",
    "label": "Pasir Garam",
    "value": "Pasir Garam"
  },
  {
    "id": "1971031005",
    "district_id": "197103",
    "label": "Lontong Pancur",
    "value": "Lontong Pancur"
  },
  {
    "id": "1971031006",
    "district_id": "197103",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "1971031007",
    "district_id": "197103",
    "label": "Ampui",
    "value": "Ampui"
  },
  {
    "id": "1971031008",
    "district_id": "197103",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "1971041001",
    "district_id": "197104",
    "label": "Pintu Air",
    "value": "Pintu Air"
  },
  {
    "id": "1971041002",
    "district_id": "197104",
    "label": "Bintang",
    "value": "Bintang"
  },
  {
    "id": "1971041005",
    "district_id": "197104",
    "label": "Masjid Jamik",
    "value": "Masjid Jamik"
  },
  {
    "id": "1971041006",
    "district_id": "197104",
    "label": "Asam",
    "value": "Asam"
  },
  {
    "id": "1971041007",
    "district_id": "197104",
    "label": "Melintang",
    "value": "Melintang"
  },
  {
    "id": "1971041008",
    "district_id": "197104",
    "label": "Parit Lalang",
    "value": "Parit Lalang"
  },
  {
    "id": "1971041009",
    "district_id": "197104",
    "label": "Keramat",
    "value": "Keramat"
  },
  {
    "id": "1971041010",
    "district_id": "197104",
    "label": "Gajah Mada",
    "value": "Gajah Mada"
  },
  {
    "id": "1971051001",
    "district_id": "197105",
    "label": "Taman Bunga",
    "value": "Taman Bunga"
  },
  {
    "id": "1971051002",
    "district_id": "197105",
    "label": "Bukitmerapin",
    "value": "Bukitmerapin"
  },
  {
    "id": "1971051004",
    "district_id": "197105",
    "label": "Bukitsari",
    "value": "Bukitsari"
  },
  {
    "id": "1971051005",
    "district_id": "197105",
    "label": "Tua Tunu",
    "value": "Tua Tunu"
  },
  {
    "id": "1971051006",
    "district_id": "197105",
    "label": "Kacang Pedang",
    "value": "Kacang Pedang"
  },
  {
    "id": "1971051007",
    "district_id": "197105",
    "label": "Air Kepala Tujuh",
    "value": "Air Kepala Tujuh"
  },
  {
    "id": "1971061001",
    "district_id": "197106",
    "label": "Selindung",
    "value": "Selindung"
  },
  {
    "id": "1971061002",
    "district_id": "197106",
    "label": "Selindung Baru",
    "value": "Selindung Baru"
  },
  {
    "id": "1971061003",
    "district_id": "197106",
    "label": "Gabek Satu",
    "value": "Gabek Satu"
  },
  {
    "id": "1971061004",
    "district_id": "197106",
    "label": "Gabek Dua",
    "value": "Gabek Dua"
  },
  {
    "id": "1971061005",
    "district_id": "197106",
    "label": "Air Salemba",
    "value": "Air Salemba"
  },
  {
    "id": "1971061006",
    "district_id": "197106",
    "label": "Jerambah Gantung",
    "value": "Jerambah Gantung"
  },
  {
    "id": "1971071001",
    "district_id": "197107",
    "label": "Pasar Padi",
    "value": "Pasar Padi"
  },
  {
    "id": "1971071002",
    "district_id": "197107",
    "label": "Bukitintan",
    "value": "Bukitintan"
  },
  {
    "id": "1971071003",
    "district_id": "197107",
    "label": "Bukitbesar",
    "value": "Bukitbesar"
  },
  {
    "id": "1971071004",
    "district_id": "197107",
    "label": "Sriwijaya",
    "value": "Sriwijaya"
  },
  {
    "id": "1971071005",
    "district_id": "197107",
    "label": "Semabung Baru",
    "value": "Semabung Baru"
  },
  {
    "id": "2101041005",
    "district_id": "210104",
    "label": "Kawal",
    "value": "Kawal"
  },
  {
    "id": "2101042001",
    "district_id": "210104",
    "label": "Gunung Kijang",
    "value": "Gunung Kijang"
  },
  {
    "id": "2101042002",
    "district_id": "210104",
    "label": "Malang Rapat",
    "value": "Malang Rapat"
  },
  {
    "id": "2101042003",
    "district_id": "210104",
    "label": "Teluk Bakau",
    "value": "Teluk Bakau"
  },
  {
    "id": "2101061008",
    "district_id": "210106",
    "label": "Kijang Kota",
    "value": "Kijang Kota"
  },
  {
    "id": "2101061009",
    "district_id": "210106",
    "label": "Sungai Lekop",
    "value": "Sungai Lekop"
  },
  {
    "id": "2101061010",
    "district_id": "210106",
    "label": "Gunung Lengkuas",
    "value": "Gunung Lengkuas"
  },
  {
    "id": "2101061011",
    "district_id": "210106",
    "label": "Sungai Enam",
    "value": "Sungai Enam"
  },
  {
    "id": "2101071001",
    "district_id": "210107",
    "label": "Tanjung Uban Kota",
    "value": "Tanjung Uban Kota"
  },
  {
    "id": "2101071002",
    "district_id": "210107",
    "label": "Tanjung Uban Utara",
    "value": "Tanjung Uban Utara"
  },
  {
    "id": "2101071003",
    "district_id": "210107",
    "label": "Tanjung Uban Selatan",
    "value": "Tanjung Uban Selatan"
  },
  {
    "id": "2101071011",
    "district_id": "210107",
    "label": "Tanjung Uban Timur",
    "value": "Tanjung Uban Timur"
  },
  {
    "id": "2101072005",
    "district_id": "210107",
    "label": "Lancang Kuning",
    "value": "Lancang Kuning"
  },
  {
    "id": "2101081006",
    "district_id": "210108",
    "label": "Tembeling Tanjung",
    "value": "Tembeling Tanjung"
  },
  {
    "id": "2101082001",
    "district_id": "210108",
    "label": "Bintan Buyu",
    "value": "Bintan Buyu"
  },
  {
    "id": "2101082002",
    "district_id": "210108",
    "label": "Pangkil",
    "value": "Pangkil"
  },
  {
    "id": "2101082003",
    "district_id": "210108",
    "label": "Penaga",
    "value": "Penaga"
  },
  {
    "id": "2101082004",
    "district_id": "210108",
    "label": "Pengujan",
    "value": "Pengujan"
  },
  {
    "id": "2101082005",
    "district_id": "210108",
    "label": "Tembeling",
    "value": "Tembeling"
  },
  {
    "id": "2101091001",
    "district_id": "210109",
    "label": "Teluk Sekuni",
    "value": "Teluk Sekuni"
  },
  {
    "id": "2101092002",
    "district_id": "210109",
    "label": "Batu Lepuk",
    "value": "Batu Lepuk"
  },
  {
    "id": "2101092003",
    "district_id": "210109",
    "label": "Kampung Hilir",
    "value": "Kampung Hilir"
  },
  {
    "id": "2101092004",
    "district_id": "210109",
    "label": "Kampung Melayu",
    "value": "Kampung Melayu"
  },
  {
    "id": "2101092005",
    "district_id": "210109",
    "label": "Pulau Mentebung",
    "value": "Pulau Mentebung"
  },
  {
    "id": "2101092006",
    "district_id": "210109",
    "label": "Pulau Pinang",
    "value": "Pulau Pinang"
  },
  {
    "id": "2101092007",
    "district_id": "210109",
    "label": "Kukup",
    "value": "Kukup"
  },
  {
    "id": "2101092008",
    "district_id": "210109",
    "label": "Pengikik",
    "value": "Pengikik"
  },
  {
    "id": "2101101007",
    "district_id": "210110",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "2101102001",
    "district_id": "210110",
    "label": "Berakit",
    "value": "Berakit"
  },
  {
    "id": "2101102002",
    "district_id": "210110",
    "label": "Ekang Anculai",
    "value": "Ekang Anculai"
  },
  {
    "id": "2101102003",
    "district_id": "210110",
    "label": "Pengudang",
    "value": "Pengudang"
  },
  {
    "id": "2101102004",
    "district_id": "210110",
    "label": "Sebong Lagoi",
    "value": "Sebong Lagoi"
  },
  {
    "id": "2101102005",
    "district_id": "210110",
    "label": "Sebong Pereh",
    "value": "Sebong Pereh"
  },
  {
    "id": "2101102006",
    "district_id": "210110",
    "label": "Sri Bintan",
    "value": "Sri Bintan"
  },
  {
    "id": "2101121004",
    "district_id": "210112",
    "label": "Toapaya Asri",
    "value": "Toapaya Asri"
  },
  {
    "id": "2101122001",
    "district_id": "210112",
    "label": "Toapaya",
    "value": "Toapaya"
  },
  {
    "id": "2101122002",
    "district_id": "210112",
    "label": "Toapaya Utara",
    "value": "Toapaya Utara"
  },
  {
    "id": "2101122003",
    "district_id": "210112",
    "label": "Toapaya Selatan",
    "value": "Toapaya Selatan"
  },
  {
    "id": "2101132001",
    "district_id": "210113",
    "label": "Mantang Baru",
    "value": "Mantang Baru"
  },
  {
    "id": "2101132002",
    "district_id": "210113",
    "label": "Mantang Besar",
    "value": "Mantang Besar"
  },
  {
    "id": "2101132003",
    "district_id": "210113",
    "label": "Mantang Lama",
    "value": "Mantang Lama"
  },
  {
    "id": "2101132004",
    "district_id": "210113",
    "label": "Dendun",
    "value": "Dendun"
  },
  {
    "id": "2101142001",
    "district_id": "210114",
    "label": "Kelong",
    "value": "Kelong"
  },
  {
    "id": "2101142002",
    "district_id": "210114",
    "label": "Mapur",
    "value": "Mapur"
  },
  {
    "id": "2101142003",
    "district_id": "210114",
    "label": "Numbing",
    "value": "Numbing"
  },
  {
    "id": "2101142004",
    "district_id": "210114",
    "label": "Air Gelubi",
    "value": "Air Gelubi"
  },
  {
    "id": "2101151003",
    "district_id": "210115",
    "label": "Teluk Lobam",
    "value": "Teluk Lobam"
  },
  {
    "id": "2101151005",
    "district_id": "210115",
    "label": "Tanjung Permai",
    "value": "Tanjung Permai"
  },
  {
    "id": "2101152001",
    "district_id": "210115",
    "label": "Busung",
    "value": "Busung"
  },
  {
    "id": "2101152002",
    "district_id": "210115",
    "label": "Teluk Sasah",
    "value": "Teluk Sasah"
  },
  {
    "id": "2101152004",
    "district_id": "210115",
    "label": "Kuala Sempang",
    "value": "Kuala Sempang"
  },
  {
    "id": "2102011003",
    "district_id": "210201",
    "label": "Moro",
    "value": "Moro"
  },
  {
    "id": "2102011010",
    "district_id": "210201",
    "label": "Moro Timur",
    "value": "Moro Timur"
  },
  {
    "id": "2102012004",
    "district_id": "210201",
    "label": "Pauh",
    "value": "Pauh"
  },
  {
    "id": "2102012005",
    "district_id": "210201",
    "label": "Sugie",
    "value": "Sugie"
  },
  {
    "id": "2102012006",
    "district_id": "210201",
    "label": "Keban",
    "value": "Keban"
  },
  {
    "id": "2102012007",
    "district_id": "210201",
    "label": "Selat Mie",
    "value": "Selat Mie"
  },
  {
    "id": "2102012008",
    "district_id": "210201",
    "label": "Tanjung Pelanduk",
    "value": "Tanjung Pelanduk"
  },
  {
    "id": "2102012009",
    "district_id": "210201",
    "label": "Jang",
    "value": "Jang"
  },
  {
    "id": "2102012011",
    "district_id": "210201",
    "label": "Niur Permai",
    "value": "Niur Permai"
  },
  {
    "id": "2102012012",
    "district_id": "210201",
    "label": "Rawajaya",
    "value": "Rawajaya"
  },
  {
    "id": "2102012013",
    "district_id": "210201",
    "label": "Buluh Patah",
    "value": "Buluh Patah"
  },
  {
    "id": "2102012014",
    "district_id": "210201",
    "label": "Pulaumoro",
    "value": "Pulaumoro"
  },
  {
    "id": "2102021001",
    "district_id": "210202",
    "label": "Tanjungbatu Barat",
    "value": "Tanjungbatu Barat"
  },
  {
    "id": "2102021002",
    "district_id": "210202",
    "label": "Tanjungbatu Kota",
    "value": "Tanjungbatu Kota"
  },
  {
    "id": "2102021009",
    "district_id": "210202",
    "label": "Gading Sari",
    "value": "Gading Sari"
  },
  {
    "id": "2102022004",
    "district_id": "210202",
    "label": "Sebesi",
    "value": "Sebesi"
  },
  {
    "id": "2102022005",
    "district_id": "210202",
    "label": "Sei Ungar",
    "value": "Sei Ungar"
  },
  {
    "id": "2102022006",
    "district_id": "210202",
    "label": "Lubuk",
    "value": "Lubuk"
  },
  {
    "id": "2102031001",
    "district_id": "210203",
    "label": "Tanjungbalai",
    "value": "Tanjungbalai"
  },
  {
    "id": "2102031002",
    "district_id": "210203",
    "label": "Telukair",
    "value": "Telukair"
  },
  {
    "id": "2102031003",
    "district_id": "210203",
    "label": "Sungailakam Timur",
    "value": "Sungailakam Timur"
  },
  {
    "id": "2102031006",
    "district_id": "210203",
    "label": "Lubuk Semut",
    "value": "Lubuk Semut"
  },
  {
    "id": "2102031007",
    "district_id": "210203",
    "label": "Tanjungbalai Kota",
    "value": "Tanjungbalai Kota"
  },
  {
    "id": "2102031008",
    "district_id": "210203",
    "label": "Sungailakam Barat",
    "value": "Sungailakam Barat"
  },
  {
    "id": "2102032004",
    "district_id": "210203",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "2102032005",
    "district_id": "210203",
    "label": "Tulang",
    "value": "Tulang"
  },
  {
    "id": "2102032009",
    "district_id": "210203",
    "label": "Selatmendaun",
    "value": "Selatmendaun"
  },
  {
    "id": "2102041001",
    "district_id": "210204",
    "label": "Meral Kota",
    "value": "Meral Kota"
  },
  {
    "id": "2102041002",
    "district_id": "210204",
    "label": "Baran Barat",
    "value": "Baran Barat"
  },
  {
    "id": "2102041003",
    "district_id": "210204",
    "label": "Sei Raya",
    "value": "Sei Raya"
  },
  {
    "id": "2102041006",
    "district_id": "210204",
    "label": "Baran Timur",
    "value": "Baran Timur"
  },
  {
    "id": "2102041007",
    "district_id": "210204",
    "label": "Sungai Pasir",
    "value": "Sungai Pasir"
  },
  {
    "id": "2102041008",
    "district_id": "210204",
    "label": "Parit Benut",
    "value": "Parit Benut"
  },
  {
    "id": "2102051001",
    "district_id": "210205",
    "label": "Tebing",
    "value": "Tebing"
  },
  {
    "id": "2102051002",
    "district_id": "210205",
    "label": "Teluk Uma",
    "value": "Teluk Uma"
  },
  {
    "id": "2102051003",
    "district_id": "210205",
    "label": "Kapling",
    "value": "Kapling"
  },
  {
    "id": "2102051004",
    "district_id": "210205",
    "label": "Pamak",
    "value": "Pamak"
  },
  {
    "id": "2102051008",
    "district_id": "210205",
    "label": "Harjosari",
    "value": "Harjosari"
  },
  {
    "id": "2102052007",
    "district_id": "210205",
    "label": "Pongkar",
    "value": "Pongkar"
  },
  {
    "id": "2102061001",
    "district_id": "210206",
    "label": "Buru",
    "value": "Buru"
  },
  {
    "id": "2102061002",
    "district_id": "210206",
    "label": "Lubuk Puding",
    "value": "Lubuk Puding"
  },
  {
    "id": "2102062003",
    "district_id": "210206",
    "label": "Tanjung Hutan",
    "value": "Tanjung Hutan"
  },
  {
    "id": "2102062004",
    "district_id": "210206",
    "label": "Tanjung Batu Kecil",
    "value": "Tanjung Batu Kecil"
  },
  {
    "id": "2102071012",
    "district_id": "210207",
    "label": "Tanjungberlian Kota",
    "value": "Tanjungberlian Kota"
  },
  {
    "id": "2102072003",
    "district_id": "210207",
    "label": "Sei Ungar Utara",
    "value": "Sei Ungar Utara"
  },
  {
    "id": "2102072004",
    "district_id": "210207",
    "label": "Teluk Radang",
    "value": "Teluk Radang"
  },
  {
    "id": "2102072010",
    "district_id": "210207",
    "label": "Perayun",
    "value": "Perayun"
  },
  {
    "id": "2102072013",
    "district_id": "210207",
    "label": "Tanjungberlian Barat",
    "value": "Tanjungberlian Barat"
  },
  {
    "id": "2102081001",
    "district_id": "210208",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "2102082002",
    "district_id": "210208",
    "label": "Sawang Selatan",
    "value": "Sawang Selatan"
  },
  {
    "id": "2102082003",
    "district_id": "210208",
    "label": "Sawang Laut",
    "value": "Sawang Laut"
  },
  {
    "id": "2102082004",
    "district_id": "210208",
    "label": "Kundur",
    "value": "Kundur"
  },
  {
    "id": "2102082005",
    "district_id": "210208",
    "label": "Gemuruh",
    "value": "Gemuruh"
  },
  {
    "id": "2102092002",
    "district_id": "210209",
    "label": "Sanglar",
    "value": "Sanglar"
  },
  {
    "id": "2102092003",
    "district_id": "210209",
    "label": "Tanjung Kilang",
    "value": "Tanjung Kilang"
  },
  {
    "id": "2102092004",
    "district_id": "210209",
    "label": "Semembang",
    "value": "Semembang"
  },
  {
    "id": "2102092005",
    "district_id": "210209",
    "label": "Telaga Tujuh",
    "value": "Telaga Tujuh"
  },
  {
    "id": "2102101001",
    "district_id": "210210",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "2102101002",
    "district_id": "210210",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "2102102003",
    "district_id": "210210",
    "label": "Pangke",
    "value": "Pangke"
  },
  {
    "id": "2102102004",
    "district_id": "210210",
    "label": "Pangke Barat",
    "value": "Pangke Barat"
  },
  {
    "id": "2102111001",
    "district_id": "210211",
    "label": "Alai",
    "value": "Alai"
  },
  {
    "id": "2102112002",
    "district_id": "210211",
    "label": "Batu Limau",
    "value": "Batu Limau"
  },
  {
    "id": "2102112003",
    "district_id": "210211",
    "label": "Ngal",
    "value": "Ngal"
  },
  {
    "id": "2102112004",
    "district_id": "210211",
    "label": "Sungaibuluh",
    "value": "Sungaibuluh"
  },
  {
    "id": "2102122001",
    "district_id": "210212",
    "label": "Sebele",
    "value": "Sebele"
  },
  {
    "id": "2102122002",
    "district_id": "210212",
    "label": "Lebuh",
    "value": "Lebuh"
  },
  {
    "id": "2102122003",
    "district_id": "210212",
    "label": "Penarah",
    "value": "Penarah"
  },
  {
    "id": "2102122004",
    "district_id": "210212",
    "label": "Sei Asam",
    "value": "Sei Asam"
  },
  {
    "id": "2102122005",
    "district_id": "210212",
    "label": "Tebias",
    "value": "Tebias"
  },
  {
    "id": "2102122006",
    "district_id": "210212",
    "label": "Degong",
    "value": "Degong"
  },
  {
    "id": "2103041003",
    "district_id": "210304",
    "label": "Sabang Barat",
    "value": "Sabang Barat"
  },
  {
    "id": "2103042001",
    "district_id": "210304",
    "label": "Sebelat",
    "value": "Sebelat"
  },
  {
    "id": "2103042005",
    "district_id": "210304",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "2103051008",
    "district_id": "210305",
    "label": "Sedanau",
    "value": "Sedanau"
  },
  {
    "id": "2103052007",
    "district_id": "210305",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "2103052012",
    "district_id": "210305",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "2103052013",
    "district_id": "210305",
    "label": "Piantengah",
    "value": "Piantengah"
  },
  {
    "id": "2103052015",
    "district_id": "210305",
    "label": "Selaut",
    "value": "Selaut"
  },
  {
    "id": "2103061003",
    "district_id": "210306",
    "label": "Serasan",
    "value": "Serasan"
  },
  {
    "id": "2103062002",
    "district_id": "210306",
    "label": "Kampung Hilir",
    "value": "Kampung Hilir"
  },
  {
    "id": "2103062005",
    "district_id": "210306",
    "label": "Batu Berlian",
    "value": "Batu Berlian"
  },
  {
    "id": "2103062006",
    "district_id": "210306",
    "label": "Tanjung Setelung",
    "value": "Tanjung Setelung"
  },
  {
    "id": "2103062007",
    "district_id": "210306",
    "label": "Tanjung Balau",
    "value": "Tanjung Balau"
  },
  {
    "id": "2103062010",
    "district_id": "210306",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "2103062011",
    "district_id": "210306",
    "label": "Jermalik",
    "value": "Jermalik"
  },
  {
    "id": "2103071011",
    "district_id": "210307",
    "label": "Ranai Kota",
    "value": "Ranai Kota"
  },
  {
    "id": "2103071016",
    "district_id": "210307",
    "label": "Ranai Darat",
    "value": "Ranai Darat"
  },
  {
    "id": "2103071017",
    "district_id": "210307",
    "label": "Bandarsyah",
    "value": "Bandarsyah"
  },
  {
    "id": "2103071019",
    "district_id": "210307",
    "label": "Batu Hitam",
    "value": "Batu Hitam"
  },
  {
    "id": "2103072002",
    "district_id": "210307",
    "label": "Sungai Ulu",
    "value": "Sungai Ulu"
  },
  {
    "id": "2103072004",
    "district_id": "210307",
    "label": "Sepempang",
    "value": "Sepempang"
  },
  {
    "id": "2103072014",
    "district_id": "210307",
    "label": "Batu Gajah",
    "value": "Batu Gajah"
  },
  {
    "id": "2103082001",
    "district_id": "210308",
    "label": "Kelarik Utara",
    "value": "Kelarik Utara"
  },
  {
    "id": "2103082002",
    "district_id": "210308",
    "label": "Kelarik",
    "value": "Kelarik"
  },
  {
    "id": "2103082003",
    "district_id": "210308",
    "label": "Kelarik Barat",
    "value": "Kelarik Barat"
  },
  {
    "id": "2103082004",
    "district_id": "210308",
    "label": "Kelarik Air Mali",
    "value": "Kelarik Air Mali"
  },
  {
    "id": "2103082005",
    "district_id": "210308",
    "label": "Teluk Buton",
    "value": "Teluk Buton"
  },
  {
    "id": "2103082006",
    "district_id": "210308",
    "label": "Belakang Gunung",
    "value": "Belakang Gunung"
  },
  {
    "id": "2103082007",
    "district_id": "210308",
    "label": "Seluan Barat",
    "value": "Seluan Barat"
  },
  {
    "id": "2103082008",
    "district_id": "210308",
    "label": "Gunung Durian",
    "value": "Gunung Durian"
  },
  {
    "id": "2103092001",
    "district_id": "210309",
    "label": "Subi",
    "value": "Subi"
  },
  {
    "id": "2103092002",
    "district_id": "210309",
    "label": "Subi Besar",
    "value": "Subi Besar"
  },
  {
    "id": "2103092003",
    "district_id": "210309",
    "label": "Meliah",
    "value": "Meliah"
  },
  {
    "id": "2103092004",
    "district_id": "210309",
    "label": "Pulau Panjang",
    "value": "Pulau Panjang"
  },
  {
    "id": "2103092005",
    "district_id": "210309",
    "label": "Terayak",
    "value": "Terayak"
  },
  {
    "id": "2103092006",
    "district_id": "210309",
    "label": "Pulau Kerdau",
    "value": "Pulau Kerdau"
  },
  {
    "id": "2103092007",
    "district_id": "210309",
    "label": "Subi Besar Timur",
    "value": "Subi Besar Timur"
  },
  {
    "id": "2103092008",
    "district_id": "210309",
    "label": "Meliah Selatan",
    "value": "Meliah Selatan"
  },
  {
    "id": "2103102001",
    "district_id": "210310",
    "label": "Tanjung Pala",
    "value": "Tanjung Pala"
  },
  {
    "id": "2103102002",
    "district_id": "210310",
    "label": "Air Payang",
    "value": "Air Payang"
  },
  {
    "id": "2103102003",
    "district_id": "210310",
    "label": "Kadur",
    "value": "Kadur"
  },
  {
    "id": "2103112001",
    "district_id": "210311",
    "label": "Sabang Mawang",
    "value": "Sabang Mawang"
  },
  {
    "id": "2103112003",
    "district_id": "210311",
    "label": "Sededap",
    "value": "Sededap"
  },
  {
    "id": "2103112004",
    "district_id": "210311",
    "label": "Tanjung Batang",
    "value": "Tanjung Batang"
  },
  {
    "id": "2103112005",
    "district_id": "210311",
    "label": "Serantas",
    "value": "Serantas"
  },
  {
    "id": "2103112006",
    "district_id": "210311",
    "label": "Sabang Mawang Barat",
    "value": "Sabang Mawang Barat"
  },
  {
    "id": "2103112010",
    "district_id": "210311",
    "label": "Teluk Labuh",
    "value": "Teluk Labuh"
  },
  {
    "id": "2103152001",
    "district_id": "210315",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "2103152002",
    "district_id": "210315",
    "label": "Ceruk",
    "value": "Ceruk"
  },
  {
    "id": "2103152003",
    "district_id": "210315",
    "label": "Kelanga",
    "value": "Kelanga"
  },
  {
    "id": "2103152004",
    "district_id": "210315",
    "label": "Pengadah",
    "value": "Pengadah"
  },
  {
    "id": "2103152005",
    "district_id": "210315",
    "label": "Sebadai Ulu",
    "value": "Sebadai Ulu"
  },
  {
    "id": "2103152006",
    "district_id": "210315",
    "label": "Limau Manis",
    "value": "Limau Manis"
  },
  {
    "id": "2103152007",
    "district_id": "210315",
    "label": "Seleman",
    "value": "Seleman"
  },
  {
    "id": "2103162001",
    "district_id": "210316",
    "label": "Tapau",
    "value": "Tapau"
  },
  {
    "id": "2103162002",
    "district_id": "210316",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "2103162003",
    "district_id": "210316",
    "label": "Air Lengit",
    "value": "Air Lengit"
  },
  {
    "id": "2103182001",
    "district_id": "210318",
    "label": "Cemaga",
    "value": "Cemaga"
  },
  {
    "id": "2103182002",
    "district_id": "210318",
    "label": "Cemaga Selatan (Setengar)",
    "value": "Cemaga Selatan (Setengar)"
  },
  {
    "id": "2103182003",
    "district_id": "210318",
    "label": "Cemaga Utara (Singgang Bulan)",
    "value": "Cemaga Utara (Singgang Bulan)"
  },
  {
    "id": "2103182004",
    "district_id": "210318",
    "label": "Cemaga Tengah",
    "value": "Cemaga Tengah"
  },
  {
    "id": "2103192001",
    "district_id": "210319",
    "label": "Arung Ayam",
    "value": "Arung Ayam"
  },
  {
    "id": "2103192002",
    "district_id": "210319",
    "label": "Air Nusa",
    "value": "Air Nusa"
  },
  {
    "id": "2103192004",
    "district_id": "210319",
    "label": "Air Ringau",
    "value": "Air Ringau"
  },
  {
    "id": "2103192005",
    "district_id": "210319",
    "label": "Payak",
    "value": "Payak"
  },
  {
    "id": "2103202001",
    "district_id": "210320",
    "label": "Batubi Jaya",
    "value": "Batubi Jaya"
  },
  {
    "id": "2103202002",
    "district_id": "210320",
    "label": "Gunung Putri",
    "value": "Gunung Putri"
  },
  {
    "id": "2103202003",
    "district_id": "210320",
    "label": "Sedarat Baru",
    "value": "Sedarat Baru"
  },
  {
    "id": "2103202004",
    "district_id": "210320",
    "label": "Sedanau Timur",
    "value": "Sedanau Timur"
  },
  {
    "id": "2103202005",
    "district_id": "210320",
    "label": "Semedang",
    "value": "Semedang"
  },
  {
    "id": "2103212001",
    "district_id": "210321",
    "label": "Pulau Tiga",
    "value": "Pulau Tiga"
  },
  {
    "id": "2103212002",
    "district_id": "210321",
    "label": "Tanjung Kumbik Utara",
    "value": "Tanjung Kumbik Utara"
  },
  {
    "id": "2103212003",
    "district_id": "210321",
    "label": "Setumuk",
    "value": "Setumuk"
  },
  {
    "id": "2103212004",
    "district_id": "210321",
    "label": "Selading",
    "value": "Selading"
  },
  {
    "id": "2103222001",
    "district_id": "210322",
    "label": "Air Kumpai",
    "value": "Air Kumpai"
  },
  {
    "id": "2103222002",
    "district_id": "210322",
    "label": "Batu Belanak",
    "value": "Batu Belanak"
  },
  {
    "id": "2103222003",
    "district_id": "210322",
    "label": "Gunung Jambat",
    "value": "Gunung Jambat"
  },
  {
    "id": "2104011001",
    "district_id": "210401",
    "label": "Dabo",
    "value": "Dabo"
  },
  {
    "id": "2104011007",
    "district_id": "210401",
    "label": "Dabo Lama",
    "value": "Dabo Lama"
  },
  {
    "id": "2104011012",
    "district_id": "210401",
    "label": "Sungailumpur",
    "value": "Sungailumpur"
  },
  {
    "id": "2104012008",
    "district_id": "210401",
    "label": "Batu Berdaun",
    "value": "Batu Berdaun"
  },
  {
    "id": "2104012009",
    "district_id": "210401",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "2104012010",
    "district_id": "210401",
    "label": "Batu Kacang",
    "value": "Batu Kacang"
  },
  {
    "id": "2104021010",
    "district_id": "210402",
    "label": "Daik",
    "value": "Daik"
  },
  {
    "id": "2104022001",
    "district_id": "210402",
    "label": "Pekajang",
    "value": "Pekajang"
  },
  {
    "id": "2104022002",
    "district_id": "210402",
    "label": "Kelombok",
    "value": "Kelombok"
  },
  {
    "id": "2104022003",
    "district_id": "210402",
    "label": "Mepar",
    "value": "Mepar"
  },
  {
    "id": "2104022006",
    "district_id": "210402",
    "label": "Kelumu",
    "value": "Kelumu"
  },
  {
    "id": "2104022007",
    "district_id": "210402",
    "label": "Mentuda",
    "value": "Mentuda"
  },
  {
    "id": "2104022008",
    "district_id": "210402",
    "label": "Merawang",
    "value": "Merawang"
  },
  {
    "id": "2104022009",
    "district_id": "210402",
    "label": "Panggak Darat",
    "value": "Panggak Darat"
  },
  {
    "id": "2104022011",
    "district_id": "210402",
    "label": "Panggak Laut",
    "value": "Panggak Laut"
  },
  {
    "id": "2104022012",
    "district_id": "210402",
    "label": "Musai",
    "value": "Musai"
  },
  {
    "id": "2104022019",
    "district_id": "210402",
    "label": "Nerekeh",
    "value": "Nerekeh"
  },
  {
    "id": "2104031001",
    "district_id": "210403",
    "label": "Senayang",
    "value": "Senayang"
  },
  {
    "id": "2104032002",
    "district_id": "210403",
    "label": "Mamut",
    "value": "Mamut"
  },
  {
    "id": "2104032012",
    "district_id": "210403",
    "label": "Laboh",
    "value": "Laboh"
  },
  {
    "id": "2104032013",
    "district_id": "210403",
    "label": "Penaah",
    "value": "Penaah"
  },
  {
    "id": "2104032015",
    "district_id": "210403",
    "label": "Baran",
    "value": "Baran"
  },
  {
    "id": "2104041006",
    "district_id": "210404",
    "label": "Raya",
    "value": "Raya"
  },
  {
    "id": "2104042001",
    "district_id": "210404",
    "label": "Bakong",
    "value": "Bakong"
  },
  {
    "id": "2104042002",
    "district_id": "210404",
    "label": "Kuala Raya",
    "value": "Kuala Raya"
  },
  {
    "id": "2104042003",
    "district_id": "210404",
    "label": "Marok Tua",
    "value": "Marok Tua"
  },
  {
    "id": "2104042005",
    "district_id": "210404",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "2104042007",
    "district_id": "210404",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "2104042008",
    "district_id": "210404",
    "label": "Sungai Harapan",
    "value": "Sungai Harapan"
  },
  {
    "id": "2104042009",
    "district_id": "210404",
    "label": "Jagoh",
    "value": "Jagoh"
  },
  {
    "id": "2104042012",
    "district_id": "210404",
    "label": "Tinjul",
    "value": "Tinjul"
  },
  {
    "id": "2104042013",
    "district_id": "210404",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "2104042014",
    "district_id": "210404",
    "label": "Tanjung Irat",
    "value": "Tanjung Irat"
  },
  {
    "id": "2104042015",
    "district_id": "210404",
    "label": "Bukit Belah",
    "value": "Bukit Belah"
  },
  {
    "id": "2104051007",
    "district_id": "210405",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "2104052001",
    "district_id": "210405",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "2104052002",
    "district_id": "210405",
    "label": "Duara",
    "value": "Duara"
  },
  {
    "id": "2104052003",
    "district_id": "210405",
    "label": "Limbung",
    "value": "Limbung"
  },
  {
    "id": "2104052004",
    "district_id": "210405",
    "label": "Resun",
    "value": "Resun"
  },
  {
    "id": "2104052005",
    "district_id": "210405",
    "label": "Sekanah",
    "value": "Sekanah"
  },
  {
    "id": "2104052006",
    "district_id": "210405",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "2104052008",
    "district_id": "210405",
    "label": "Linau",
    "value": "Linau"
  },
  {
    "id": "2104052009",
    "district_id": "210405",
    "label": "Sungaibesar",
    "value": "Sungaibesar"
  },
  {
    "id": "2104052010",
    "district_id": "210405",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "2104052011",
    "district_id": "210405",
    "label": "Belungkur",
    "value": "Belungkur"
  },
  {
    "id": "2104052012",
    "district_id": "210405",
    "label": "Resun Pesisir",
    "value": "Resun Pesisir"
  },
  {
    "id": "2104062001",
    "district_id": "210406",
    "label": "Berindat",
    "value": "Berindat"
  },
  {
    "id": "2104062002",
    "district_id": "210406",
    "label": "Sedamai",
    "value": "Sedamai"
  },
  {
    "id": "2104062003",
    "district_id": "210406",
    "label": "Lanjut",
    "value": "Lanjut"
  },
  {
    "id": "2104062004",
    "district_id": "210406",
    "label": "Kote",
    "value": "Kote"
  },
  {
    "id": "2104062005",
    "district_id": "210406",
    "label": "Persing",
    "value": "Persing"
  },
  {
    "id": "2104062006",
    "district_id": "210406",
    "label": "Pelakak",
    "value": "Pelakak"
  },
  {
    "id": "2104072001",
    "district_id": "210407",
    "label": "Bukitlangkap",
    "value": "Bukitlangkap"
  },
  {
    "id": "2104072002",
    "district_id": "210407",
    "label": "Keton",
    "value": "Keton"
  },
  {
    "id": "2104072003",
    "district_id": "210407",
    "label": "Pekaka",
    "value": "Pekaka"
  },
  {
    "id": "2104072004",
    "district_id": "210407",
    "label": "Kerandin",
    "value": "Kerandin"
  },
  {
    "id": "2104072005",
    "district_id": "210407",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "2104072006",
    "district_id": "210407",
    "label": "Kudung",
    "value": "Kudung"
  },
  {
    "id": "2104082001",
    "district_id": "210408",
    "label": "Selayar",
    "value": "Selayar"
  },
  {
    "id": "2104082002",
    "district_id": "210408",
    "label": "Penuba",
    "value": "Penuba"
  },
  {
    "id": "2104082003",
    "district_id": "210408",
    "label": "Penuba Timur",
    "value": "Penuba Timur"
  },
  {
    "id": "2104082004",
    "district_id": "210408",
    "label": "Pantai Harapan",
    "value": "Pantai Harapan"
  },
  {
    "id": "2104092001",
    "district_id": "210409",
    "label": "Marok Kecil",
    "value": "Marok Kecil"
  },
  {
    "id": "2104092002",
    "district_id": "210409",
    "label": "Berhala",
    "value": "Berhala"
  },
  {
    "id": "2104092003",
    "district_id": "210409",
    "label": "Resang",
    "value": "Resang"
  },
  {
    "id": "2104102001",
    "district_id": "210410",
    "label": "Busung Panjang",
    "value": "Busung Panjang"
  },
  {
    "id": "2104102002",
    "district_id": "210410",
    "label": "Posek",
    "value": "Posek"
  },
  {
    "id": "2104102003",
    "district_id": "210410",
    "label": "Suak Buaya",
    "value": "Suak Buaya"
  },
  {
    "id": "2104112001",
    "district_id": "210411",
    "label": "Benan",
    "value": "Benan"
  },
  {
    "id": "2104112002",
    "district_id": "210411",
    "label": "Mensanak",
    "value": "Mensanak"
  },
  {
    "id": "2104112003",
    "district_id": "210411",
    "label": "Pulau Bukit",
    "value": "Pulau Bukit"
  },
  {
    "id": "2104112004",
    "district_id": "210411",
    "label": "Pulau Duyung",
    "value": "Pulau Duyung"
  },
  {
    "id": "2104112005",
    "district_id": "210411",
    "label": "Pulau Medang",
    "value": "Pulau Medang"
  },
  {
    "id": "2104122001",
    "district_id": "210412",
    "label": "Temiang",
    "value": "Temiang"
  },
  {
    "id": "2104122002",
    "district_id": "210412",
    "label": "Tajur Biru",
    "value": "Tajur Biru"
  },
  {
    "id": "2104122003",
    "district_id": "210412",
    "label": "Pulau Batang",
    "value": "Pulau Batang"
  },
  {
    "id": "2104132001",
    "district_id": "210413",
    "label": "Batu Belubang",
    "value": "Batu Belubang"
  },
  {
    "id": "2104132002",
    "district_id": "210413",
    "label": "Cempa",
    "value": "Cempa"
  },
  {
    "id": "2104132003",
    "district_id": "210413",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "2104132004",
    "district_id": "210413",
    "label": "Tanjung Kelit",
    "value": "Tanjung Kelit"
  },
  {
    "id": "2104132005",
    "district_id": "210413",
    "label": "Tanjung Lipat",
    "value": "Tanjung Lipat"
  },
  {
    "id": "2104132006",
    "district_id": "210413",
    "label": "Rejai",
    "value": "Rejai"
  },
  {
    "id": "2105011001",
    "district_id": "210501",
    "label": "Terempa",
    "value": "Terempa"
  },
  {
    "id": "2105012002",
    "district_id": "210501",
    "label": "Terempa Barat",
    "value": "Terempa Barat"
  },
  {
    "id": "2105012003",
    "district_id": "210501",
    "label": "Tarempa Selatan",
    "value": "Tarempa Selatan"
  },
  {
    "id": "2105012004",
    "district_id": "210501",
    "label": "Tarempa Timur",
    "value": "Tarempa Timur"
  },
  {
    "id": "2105012005",
    "district_id": "210501",
    "label": "Pesisir Timur",
    "value": "Pesisir Timur"
  },
  {
    "id": "2105012006",
    "district_id": "210501",
    "label": "Sri Tanjung",
    "value": "Sri Tanjung"
  },
  {
    "id": "2105012007",
    "district_id": "210501",
    "label": "Tarempa Barat Daya",
    "value": "Tarempa Barat Daya"
  },
  {
    "id": "2105022002",
    "district_id": "210502",
    "label": "Tebang",
    "value": "Tebang"
  },
  {
    "id": "2105022003",
    "district_id": "210502",
    "label": "Ladan",
    "value": "Ladan"
  },
  {
    "id": "2105022004",
    "district_id": "210502",
    "label": "Payalaman",
    "value": "Payalaman"
  },
  {
    "id": "2105022005",
    "district_id": "210502",
    "label": "Piabung",
    "value": "Piabung"
  },
  {
    "id": "2105022006",
    "district_id": "210502",
    "label": "Langir",
    "value": "Langir"
  },
  {
    "id": "2105022007",
    "district_id": "210502",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "2105022008",
    "district_id": "210502",
    "label": "Putik",
    "value": "Putik"
  },
  {
    "id": "2105022010",
    "district_id": "210502",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "2105022011",
    "district_id": "210502",
    "label": "Matak",
    "value": "Matak"
  },
  {
    "id": "2105022012",
    "district_id": "210502",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "2105022013",
    "district_id": "210502",
    "label": "Payamaram",
    "value": "Payamaram"
  },
  {
    "id": "2105022015",
    "district_id": "210502",
    "label": "Belibak",
    "value": "Belibak"
  },
  {
    "id": "2105032001",
    "district_id": "210503",
    "label": "Nyamuk",
    "value": "Nyamuk"
  },
  {
    "id": "2105032002",
    "district_id": "210503",
    "label": "Batu Belah",
    "value": "Batu Belah"
  },
  {
    "id": "2105032003",
    "district_id": "210503",
    "label": "Munjan",
    "value": "Munjan"
  },
  {
    "id": "2105032004",
    "district_id": "210503",
    "label": "Serat",
    "value": "Serat"
  },
  {
    "id": "2105032005",
    "district_id": "210503",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "2105032006",
    "district_id": "210503",
    "label": "Temburun",
    "value": "Temburun"
  },
  {
    "id": "2105042001",
    "district_id": "210504",
    "label": "Kiabu",
    "value": "Kiabu"
  },
  {
    "id": "2105042002",
    "district_id": "210504",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "2105042003",
    "district_id": "210504",
    "label": "Mengkait",
    "value": "Mengkait"
  },
  {
    "id": "2105042004",
    "district_id": "210504",
    "label": "Tiangau",
    "value": "Tiangau"
  },
  {
    "id": "2105042005",
    "district_id": "210504",
    "label": "Telaga Kecil",
    "value": "Telaga Kecil"
  },
  {
    "id": "2105042006",
    "district_id": "210504",
    "label": "Lingai",
    "value": "Lingai"
  },
  {
    "id": "2105042007",
    "district_id": "210504",
    "label": "Air Bini",
    "value": "Air Bini"
  },
  {
    "id": "2105052001",
    "district_id": "210505",
    "label": "Kuala Maras",
    "value": "Kuala Maras"
  },
  {
    "id": "2105052002",
    "district_id": "210505",
    "label": "Ulu Maras",
    "value": "Ulu Maras"
  },
  {
    "id": "2105052003",
    "district_id": "210505",
    "label": "Bukit Padi",
    "value": "Bukit Padi"
  },
  {
    "id": "2105052004",
    "district_id": "210505",
    "label": "Genting Pulur",
    "value": "Genting Pulur"
  },
  {
    "id": "2105061005",
    "district_id": "210506",
    "label": "Letung",
    "value": "Letung"
  },
  {
    "id": "2105062001",
    "district_id": "210506",
    "label": "Mampok",
    "value": "Mampok"
  },
  {
    "id": "2105062002",
    "district_id": "210506",
    "label": "Rewak",
    "value": "Rewak"
  },
  {
    "id": "2105062006",
    "district_id": "210506",
    "label": "Air Biru",
    "value": "Air Biru"
  },
  {
    "id": "2105062008",
    "district_id": "210506",
    "label": "Batu Berapit",
    "value": "Batu Berapit"
  },
  {
    "id": "2105062009",
    "district_id": "210506",
    "label": "Landak",
    "value": "Landak"
  },
  {
    "id": "2105072001",
    "district_id": "210507",
    "label": "Air Asuk",
    "value": "Air Asuk"
  },
  {
    "id": "2105072002",
    "district_id": "210507",
    "label": "Air Sena",
    "value": "Air Sena"
  },
  {
    "id": "2105072003",
    "district_id": "210507",
    "label": "Teluk Siantan",
    "value": "Teluk Siantan"
  },
  {
    "id": "2105072004",
    "district_id": "210507",
    "label": "Lidi",
    "value": "Lidi"
  },
  {
    "id": "2105072005",
    "district_id": "210507",
    "label": "Liuk",
    "value": "Liuk"
  },
  {
    "id": "2105072006",
    "district_id": "210507",
    "label": "Teluk Sunting",
    "value": "Teluk Sunting"
  },
  {
    "id": "2105082001",
    "district_id": "210508",
    "label": "Mubur",
    "value": "Mubur"
  },
  {
    "id": "2105082002",
    "district_id": "210508",
    "label": "Piasan",
    "value": "Piasan"
  },
  {
    "id": "2105082003",
    "district_id": "210508",
    "label": "Bayat",
    "value": "Bayat"
  },
  {
    "id": "2105092001",
    "district_id": "210509",
    "label": "Impol",
    "value": "Impol"
  },
  {
    "id": "2105092002",
    "district_id": "210509",
    "label": "Keramut",
    "value": "Keramut"
  },
  {
    "id": "2105092003",
    "district_id": "210509",
    "label": "Sunggak",
    "value": "Sunggak"
  },
  {
    "id": "2171011002",
    "district_id": "217101",
    "label": "Pemping",
    "value": "Pemping"
  },
  {
    "id": "2171011003",
    "district_id": "217101",
    "label": "Kasu",
    "value": "Kasu"
  },
  {
    "id": "2171011004",
    "district_id": "217101",
    "label": "Pulau Terung",
    "value": "Pulau Terung"
  },
  {
    "id": "2171011005",
    "district_id": "217101",
    "label": "Pecong",
    "value": "Pecong"
  },
  {
    "id": "2171011006",
    "district_id": "217101",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "2171011007",
    "district_id": "217101",
    "label": "Sekanak Raya",
    "value": "Sekanak Raya"
  },
  {
    "id": "2171021001",
    "district_id": "217102",
    "label": "Tanjung Sengkuang",
    "value": "Tanjung Sengkuang"
  },
  {
    "id": "2171021002",
    "district_id": "217102",
    "label": "Sungai Jodoh",
    "value": "Sungai Jodoh"
  },
  {
    "id": "2171021003",
    "district_id": "217102",
    "label": "Batu Merah",
    "value": "Batu Merah"
  },
  {
    "id": "2171021004",
    "district_id": "217102",
    "label": "Kampung Seraya",
    "value": "Kampung Seraya"
  },
  {
    "id": "2171031001",
    "district_id": "217103",
    "label": "Sungai Harapan",
    "value": "Sungai Harapan"
  },
  {
    "id": "2171031002",
    "district_id": "217103",
    "label": "Tanjung Pinggir",
    "value": "Tanjung Pinggir"
  },
  {
    "id": "2171031003",
    "district_id": "217103",
    "label": "Tanjung Riau",
    "value": "Tanjung Riau"
  },
  {
    "id": "2171031005",
    "district_id": "217103",
    "label": "Tiban Indah",
    "value": "Tiban Indah"
  },
  {
    "id": "2171031006",
    "district_id": "217103",
    "label": "Tiban Baru",
    "value": "Tiban Baru"
  },
  {
    "id": "2171031007",
    "district_id": "217103",
    "label": "Tiban Lama",
    "value": "Tiban Lama"
  },
  {
    "id": "2171031008",
    "district_id": "217103",
    "label": "Patam Lestari",
    "value": "Patam Lestari"
  },
  {
    "id": "2171041001",
    "district_id": "217104",
    "label": "Sambau",
    "value": "Sambau"
  },
  {
    "id": "2171041002",
    "district_id": "217104",
    "label": "Batu Besar",
    "value": "Batu Besar"
  },
  {
    "id": "2171041003",
    "district_id": "217104",
    "label": "Kabil",
    "value": "Kabil"
  },
  {
    "id": "2171041004",
    "district_id": "217104",
    "label": "Ngenang",
    "value": "Ngenang"
  },
  {
    "id": "2171051001",
    "district_id": "217105",
    "label": "Bulang Lintang",
    "value": "Bulang Lintang"
  },
  {
    "id": "2171051002",
    "district_id": "217105",
    "label": "Pulau Buluh",
    "value": "Pulau Buluh"
  },
  {
    "id": "2171051003",
    "district_id": "217105",
    "label": "Temoyong",
    "value": "Temoyong"
  },
  {
    "id": "2171051004",
    "district_id": "217105",
    "label": "Batu Legong",
    "value": "Batu Legong"
  },
  {
    "id": "2171051005",
    "district_id": "217105",
    "label": "Pantai Gelam",
    "value": "Pantai Gelam"
  },
  {
    "id": "2171051006",
    "district_id": "217105",
    "label": "Pulau Setokok",
    "value": "Pulau Setokok"
  },
  {
    "id": "2171061001",
    "district_id": "217106",
    "label": "Kampung Pelita",
    "value": "Kampung Pelita"
  },
  {
    "id": "2171061003",
    "district_id": "217106",
    "label": "Lubuk Baja Kota",
    "value": "Lubuk Baja Kota"
  },
  {
    "id": "2171061004",
    "district_id": "217106",
    "label": "Batu Selicin",
    "value": "Batu Selicin"
  },
  {
    "id": "2171061005",
    "district_id": "217106",
    "label": "Tanjung Uma",
    "value": "Tanjung Uma"
  },
  {
    "id": "2171061006",
    "district_id": "217106",
    "label": "Baloi Indah",
    "value": "Baloi Indah"
  },
  {
    "id": "2171071001",
    "district_id": "217107",
    "label": "Muka Kuning",
    "value": "Muka Kuning"
  },
  {
    "id": "2171071002",
    "district_id": "217107",
    "label": "Duriangkang",
    "value": "Duriangkang"
  },
  {
    "id": "2171071003",
    "district_id": "217107",
    "label": "Mangsang",
    "value": "Mangsang"
  },
  {
    "id": "2171071004",
    "district_id": "217107",
    "label": "Tanjung Piayu",
    "value": "Tanjung Piayu"
  },
  {
    "id": "2171081001",
    "district_id": "217108",
    "label": "Sijantung",
    "value": "Sijantung"
  },
  {
    "id": "2171081002",
    "district_id": "217108",
    "label": "Karas",
    "value": "Karas"
  },
  {
    "id": "2171081003",
    "district_id": "217108",
    "label": "Galang Baru",
    "value": "Galang Baru"
  },
  {
    "id": "2171081004",
    "district_id": "217108",
    "label": "Sembulang",
    "value": "Sembulang"
  },
  {
    "id": "2171081005",
    "district_id": "217108",
    "label": "Rempang Cate",
    "value": "Rempang Cate"
  },
  {
    "id": "2171081006",
    "district_id": "217108",
    "label": "Subang Mas",
    "value": "Subang Mas"
  },
  {
    "id": "2171081007",
    "district_id": "217108",
    "label": "Pulau Abang",
    "value": "Pulau Abang"
  },
  {
    "id": "2171081008",
    "district_id": "217108",
    "label": "Air Raja",
    "value": "Air Raja"
  },
  {
    "id": "2171091001",
    "district_id": "217109",
    "label": "Bengkong Indah",
    "value": "Bengkong Indah"
  },
  {
    "id": "2171091002",
    "district_id": "217109",
    "label": "Bengkong Laut",
    "value": "Bengkong Laut"
  },
  {
    "id": "2171091003",
    "district_id": "217109",
    "label": "Sadai",
    "value": "Sadai"
  },
  {
    "id": "2171091004",
    "district_id": "217109",
    "label": "Tanjung Buntung",
    "value": "Tanjung Buntung"
  },
  {
    "id": "2171101001",
    "district_id": "217110",
    "label": "Baloi Permai",
    "value": "Baloi Permai"
  },
  {
    "id": "2171101002",
    "district_id": "217110",
    "label": "Taman Baloi",
    "value": "Taman Baloi"
  },
  {
    "id": "2171101003",
    "district_id": "217110",
    "label": "Teluk Tering",
    "value": "Teluk Tering"
  },
  {
    "id": "2171101004",
    "district_id": "217110",
    "label": "Belian",
    "value": "Belian"
  },
  {
    "id": "2171101005",
    "district_id": "217110",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "2171101006",
    "district_id": "217110",
    "label": "Sungai Panas",
    "value": "Sungai Panas"
  },
  {
    "id": "2171111001",
    "district_id": "217111",
    "label": "Tembesi",
    "value": "Tembesi"
  },
  {
    "id": "2171111002",
    "district_id": "217111",
    "label": "Sungai Binti",
    "value": "Sungai Binti"
  },
  {
    "id": "2171111003",
    "district_id": "217111",
    "label": "Sungai Lekop",
    "value": "Sungai Lekop"
  },
  {
    "id": "2171111004",
    "district_id": "217111",
    "label": "Sagulung Kota",
    "value": "Sagulung Kota"
  },
  {
    "id": "2171111005",
    "district_id": "217111",
    "label": "Sungai Langkai",
    "value": "Sungai Langkai"
  },
  {
    "id": "2171111006",
    "district_id": "217111",
    "label": "Sungai Pelunggut",
    "value": "Sungai Pelunggut"
  },
  {
    "id": "2171121001",
    "district_id": "217112",
    "label": "Tanjung Uncang",
    "value": "Tanjung Uncang"
  },
  {
    "id": "2171121002",
    "district_id": "217112",
    "label": "Buliang",
    "value": "Buliang"
  },
  {
    "id": "2171121003",
    "district_id": "217112",
    "label": "Kibing",
    "value": "Kibing"
  },
  {
    "id": "2171121004",
    "district_id": "217112",
    "label": "Bukit Tempayan",
    "value": "Bukit Tempayan"
  },
  {
    "id": "2172011001",
    "district_id": "217201",
    "label": "Tanjung Pinang Barat",
    "value": "Tanjung Pinang Barat"
  },
  {
    "id": "2172011002",
    "district_id": "217201",
    "label": "Kemboja",
    "value": "Kemboja"
  },
  {
    "id": "2172011003",
    "district_id": "217201",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "2172011004",
    "district_id": "217201",
    "label": "Bukit Cermin",
    "value": "Bukit Cermin"
  },
  {
    "id": "2172021001",
    "district_id": "217202",
    "label": "Melayu Kota Piring",
    "value": "Melayu Kota Piring"
  },
  {
    "id": "2172021002",
    "district_id": "217202",
    "label": "Kampung Bulang",
    "value": "Kampung Bulang"
  },
  {
    "id": "2172021003",
    "district_id": "217202",
    "label": "Air Raja",
    "value": "Air Raja"
  },
  {
    "id": "2172021004",
    "district_id": "217202",
    "label": "Batu IX",
    "value": "Batu IX"
  },
  {
    "id": "2172021005",
    "district_id": "217202",
    "label": "Pinang Kencana",
    "value": "Pinang Kencana"
  },
  {
    "id": "2172031001",
    "district_id": "217203",
    "label": "Tanjung Pinang Kota",
    "value": "Tanjung Pinang Kota"
  },
  {
    "id": "2172031003",
    "district_id": "217203",
    "label": "Senggarang",
    "value": "Senggarang"
  },
  {
    "id": "2172031004",
    "district_id": "217203",
    "label": "Penyengat",
    "value": "Penyengat"
  },
  {
    "id": "2172031005",
    "district_id": "217203",
    "label": "Kampung Bugis",
    "value": "Kampung Bugis"
  },
  {
    "id": "2172041001",
    "district_id": "217204",
    "label": "Tanjung Pinang Timur",
    "value": "Tanjung Pinang Timur"
  },
  {
    "id": "2172041002",
    "district_id": "217204",
    "label": "Dompak",
    "value": "Dompak"
  },
  {
    "id": "2172041003",
    "district_id": "217204",
    "label": "Tanjung Ayun Sakti",
    "value": "Tanjung Ayun Sakti"
  },
  {
    "id": "2172041004",
    "district_id": "217204",
    "label": "Sei Jang",
    "value": "Sei Jang"
  },
  {
    "id": "2172041005",
    "district_id": "217204",
    "label": "Tanjung Unggat",
    "value": "Tanjung Unggat"
  },
  {
    "id": "3101011001",
    "district_id": "310101",
    "label": "Pulau Panggang",
    "value": "Pulau Panggang"
  },
  {
    "id": "3101011002",
    "district_id": "310101",
    "label": "Pulau Kelapa",
    "value": "Pulau Kelapa"
  },
  {
    "id": "3101011003",
    "district_id": "310101",
    "label": "Pulau Harapan",
    "value": "Pulau Harapan"
  },
  {
    "id": "3101021001",
    "district_id": "310102",
    "label": "Pulau Untung Jawa",
    "value": "Pulau Untung Jawa"
  },
  {
    "id": "3101021002",
    "district_id": "310102",
    "label": "Pulau Tidung",
    "value": "Pulau Tidung"
  },
  {
    "id": "3101021003",
    "district_id": "310102",
    "label": "Pulau Pari",
    "value": "Pulau Pari"
  },
  {
    "id": "3171011001",
    "district_id": "317101",
    "label": "Gambir",
    "value": "Gambir"
  },
  {
    "id": "3171011002",
    "district_id": "317101",
    "label": "Cideng",
    "value": "Cideng"
  },
  {
    "id": "3171011003",
    "district_id": "317101",
    "label": "Petojo Utara",
    "value": "Petojo Utara"
  },
  {
    "id": "3171011004",
    "district_id": "317101",
    "label": "Petojo Selatan",
    "value": "Petojo Selatan"
  },
  {
    "id": "3171011005",
    "district_id": "317101",
    "label": "Kebon Kelapa",
    "value": "Kebon Kelapa"
  },
  {
    "id": "3171011006",
    "district_id": "317101",
    "label": "Duri Pulo",
    "value": "Duri Pulo"
  },
  {
    "id": "3171021001",
    "district_id": "317102",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "3171021002",
    "district_id": "317102",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3171021003",
    "district_id": "317102",
    "label": "Kartini",
    "value": "Kartini"
  },
  {
    "id": "3171021004",
    "district_id": "317102",
    "label": "Gunung Sahari Utara",
    "value": "Gunung Sahari Utara"
  },
  {
    "id": "3171021005",
    "district_id": "317102",
    "label": "Mangga Dua Selatan",
    "value": "Mangga Dua Selatan"
  },
  {
    "id": "3171031001",
    "district_id": "317103",
    "label": "Kemayoran",
    "value": "Kemayoran"
  },
  {
    "id": "3171031002",
    "district_id": "317103",
    "label": "Kebon Kosong",
    "value": "Kebon Kosong"
  },
  {
    "id": "3171031003",
    "district_id": "317103",
    "label": "Harapan Mulia",
    "value": "Harapan Mulia"
  },
  {
    "id": "3171031004",
    "district_id": "317103",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "3171031005",
    "district_id": "317103",
    "label": "Gunung Sahari Selatan",
    "value": "Gunung Sahari Selatan"
  },
  {
    "id": "3171031006",
    "district_id": "317103",
    "label": "Cempaka Baru",
    "value": "Cempaka Baru"
  },
  {
    "id": "3171031007",
    "district_id": "317103",
    "label": "Sumur Batu",
    "value": "Sumur Batu"
  },
  {
    "id": "3171031008",
    "district_id": "317103",
    "label": "Utan Panjang",
    "value": "Utan Panjang"
  },
  {
    "id": "3171041001",
    "district_id": "317104",
    "label": "Senen",
    "value": "Senen"
  },
  {
    "id": "3171041002",
    "district_id": "317104",
    "label": "Kenari",
    "value": "Kenari"
  },
  {
    "id": "3171041003",
    "district_id": "317104",
    "label": "Paseban",
    "value": "Paseban"
  },
  {
    "id": "3171041004",
    "district_id": "317104",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3171041005",
    "district_id": "317104",
    "label": "Kwitang",
    "value": "Kwitang"
  },
  {
    "id": "3171041006",
    "district_id": "317104",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "3171051001",
    "district_id": "317105",
    "label": "Cempaka Putih Timur",
    "value": "Cempaka Putih Timur"
  },
  {
    "id": "3171051002",
    "district_id": "317105",
    "label": "Cempaka Putih Barat",
    "value": "Cempaka Putih Barat"
  },
  {
    "id": "3171051003",
    "district_id": "317105",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "3171061001",
    "district_id": "317106",
    "label": "Menteng",
    "value": "Menteng"
  },
  {
    "id": "3171061002",
    "district_id": "317106",
    "label": "Pegangsaan",
    "value": "Pegangsaan"
  },
  {
    "id": "3171061003",
    "district_id": "317106",
    "label": "Cikini",
    "value": "Cikini"
  },
  {
    "id": "3171061004",
    "district_id": "317106",
    "label": "Gondangdia",
    "value": "Gondangdia"
  },
  {
    "id": "3171061005",
    "district_id": "317106",
    "label": "Kebon Sirih",
    "value": "Kebon Sirih"
  },
  {
    "id": "3171071001",
    "district_id": "317107",
    "label": "Gelora",
    "value": "Gelora"
  },
  {
    "id": "3171071002",
    "district_id": "317107",
    "label": "Bendungan Hilir",
    "value": "Bendungan Hilir"
  },
  {
    "id": "3171071003",
    "district_id": "317107",
    "label": "Karet Tengsin",
    "value": "Karet Tengsin"
  },
  {
    "id": "3171071004",
    "district_id": "317107",
    "label": "Petamburan",
    "value": "Petamburan"
  },
  {
    "id": "3171071005",
    "district_id": "317107",
    "label": "Kebon Melati",
    "value": "Kebon Melati"
  },
  {
    "id": "3171071006",
    "district_id": "317107",
    "label": "Kebon Kacang",
    "value": "Kebon Kacang"
  },
  {
    "id": "3171071007",
    "district_id": "317107",
    "label": "Kampung Bali",
    "value": "Kampung Bali"
  },
  {
    "id": "3171081001",
    "district_id": "317108",
    "label": "Johar Baru",
    "value": "Johar Baru"
  },
  {
    "id": "3171081002",
    "district_id": "317108",
    "label": "Kampung Rawa",
    "value": "Kampung Rawa"
  },
  {
    "id": "3171081003",
    "district_id": "317108",
    "label": "Galur",
    "value": "Galur"
  },
  {
    "id": "3171081004",
    "district_id": "317108",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "3172011001",
    "district_id": "317201",
    "label": "Penjaringan",
    "value": "Penjaringan"
  },
  {
    "id": "3172011002",
    "district_id": "317201",
    "label": "Kamal Muara",
    "value": "Kamal Muara"
  },
  {
    "id": "3172011003",
    "district_id": "317201",
    "label": "Kapuk Muara",
    "value": "Kapuk Muara"
  },
  {
    "id": "3172011004",
    "district_id": "317201",
    "label": "Pejagalan",
    "value": "Pejagalan"
  },
  {
    "id": "3172011005",
    "district_id": "317201",
    "label": "Pluit",
    "value": "Pluit"
  },
  {
    "id": "3172021001",
    "district_id": "317202",
    "label": "Tanjung Priok",
    "value": "Tanjung Priok"
  },
  {
    "id": "3172021002",
    "district_id": "317202",
    "label": "Sunter Jaya",
    "value": "Sunter Jaya"
  },
  {
    "id": "3172021003",
    "district_id": "317202",
    "label": "Papanggo",
    "value": "Papanggo"
  },
  {
    "id": "3172021004",
    "district_id": "317202",
    "label": "Sungai Bambu",
    "value": "Sungai Bambu"
  },
  {
    "id": "3172021005",
    "district_id": "317202",
    "label": "Kebon Bawang",
    "value": "Kebon Bawang"
  },
  {
    "id": "3172021006",
    "district_id": "317202",
    "label": "Sunter Agung",
    "value": "Sunter Agung"
  },
  {
    "id": "3172021007",
    "district_id": "317202",
    "label": "Warakas",
    "value": "Warakas"
  },
  {
    "id": "3172031001",
    "district_id": "317203",
    "label": "Koja",
    "value": "Koja"
  },
  {
    "id": "3172031002",
    "district_id": "317203",
    "label": "Tugu Utara",
    "value": "Tugu Utara"
  },
  {
    "id": "3172031003",
    "district_id": "317203",
    "label": "Lagoa",
    "value": "Lagoa"
  },
  {
    "id": "3172031004",
    "district_id": "317203",
    "label": "Rawa Badak Utara",
    "value": "Rawa Badak Utara"
  },
  {
    "id": "3172031005",
    "district_id": "317203",
    "label": "Tugu Selatan",
    "value": "Tugu Selatan"
  },
  {
    "id": "3172031006",
    "district_id": "317203",
    "label": "Rawa Badak Selatan",
    "value": "Rawa Badak Selatan"
  },
  {
    "id": "3172041001",
    "district_id": "317204",
    "label": "Cilincing",
    "value": "Cilincing"
  },
  {
    "id": "3172041002",
    "district_id": "317204",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3172041003",
    "district_id": "317204",
    "label": "Marunda",
    "value": "Marunda"
  },
  {
    "id": "3172041004",
    "district_id": "317204",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "3172041005",
    "district_id": "317204",
    "label": "Semper Timur",
    "value": "Semper Timur"
  },
  {
    "id": "3172041006",
    "district_id": "317204",
    "label": "Rorotan",
    "value": "Rorotan"
  },
  {
    "id": "3172041007",
    "district_id": "317204",
    "label": "Semper Barat",
    "value": "Semper Barat"
  },
  {
    "id": "3172051001",
    "district_id": "317205",
    "label": "Pademangan Timur",
    "value": "Pademangan Timur"
  },
  {
    "id": "3172051002",
    "district_id": "317205",
    "label": "Pademangan Barat",
    "value": "Pademangan Barat"
  },
  {
    "id": "3172051003",
    "district_id": "317205",
    "label": "Ancol",
    "value": "Ancol"
  },
  {
    "id": "3172061001",
    "district_id": "317206",
    "label": "Kelapa Gading Timur",
    "value": "Kelapa Gading Timur"
  },
  {
    "id": "3172061002",
    "district_id": "317206",
    "label": "Pegangsaan Dua",
    "value": "Pegangsaan Dua"
  },
  {
    "id": "3172061003",
    "district_id": "317206",
    "label": "Kelapa Gading Barat",
    "value": "Kelapa Gading Barat"
  },
  {
    "id": "3173011001",
    "district_id": "317301",
    "label": "Cengkareng Barat",
    "value": "Cengkareng Barat"
  },
  {
    "id": "3173011002",
    "district_id": "317301",
    "label": "Duri Kosambi",
    "value": "Duri Kosambi"
  },
  {
    "id": "3173011003",
    "district_id": "317301",
    "label": "Rawa Buaya",
    "value": "Rawa Buaya"
  },
  {
    "id": "3173011004",
    "district_id": "317301",
    "label": "Kedaung Kali Angke",
    "value": "Kedaung Kali Angke"
  },
  {
    "id": "3173011005",
    "district_id": "317301",
    "label": "Kapuk",
    "value": "Kapuk"
  },
  {
    "id": "3173011006",
    "district_id": "317301",
    "label": "Cengkareng Timur",
    "value": "Cengkareng Timur"
  },
  {
    "id": "3173021001",
    "district_id": "317302",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3173021002",
    "district_id": "317302",
    "label": "Tanjung Duren Utara",
    "value": "Tanjung Duren Utara"
  },
  {
    "id": "3173021003",
    "district_id": "317302",
    "label": "Tomang",
    "value": "Tomang"
  },
  {
    "id": "3173021004",
    "district_id": "317302",
    "label": "Jelambar",
    "value": "Jelambar"
  },
  {
    "id": "3173021005",
    "district_id": "317302",
    "label": "Tanjung Duren Selatan",
    "value": "Tanjung Duren Selatan"
  },
  {
    "id": "3173021006",
    "district_id": "317302",
    "label": "Jelambar Baru",
    "value": "Jelambar Baru"
  },
  {
    "id": "3173021007",
    "district_id": "317302",
    "label": "Wijaya Kusuma",
    "value": "Wijaya Kusuma"
  },
  {
    "id": "3173031001",
    "district_id": "317303",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "3173031002",
    "district_id": "317303",
    "label": "Krukut",
    "value": "Krukut"
  },
  {
    "id": "3173031003",
    "district_id": "317303",
    "label": "Maphar",
    "value": "Maphar"
  },
  {
    "id": "3173031004",
    "district_id": "317303",
    "label": "Tangki",
    "value": "Tangki"
  },
  {
    "id": "3173031005",
    "district_id": "317303",
    "label": "Mangga Besar",
    "value": "Mangga Besar"
  },
  {
    "id": "3173031006",
    "district_id": "317303",
    "label": "Keagungan",
    "value": "Keagungan"
  },
  {
    "id": "3173031007",
    "district_id": "317303",
    "label": "Glodok",
    "value": "Glodok"
  },
  {
    "id": "3173031008",
    "district_id": "317303",
    "label": "Pinangsia",
    "value": "Pinangsia"
  },
  {
    "id": "3173041001",
    "district_id": "317304",
    "label": "Tambora",
    "value": "Tambora"
  },
  {
    "id": "3173041002",
    "district_id": "317304",
    "label": "Kali Anyar",
    "value": "Kali Anyar"
  },
  {
    "id": "3173041003",
    "district_id": "317304",
    "label": "Duri Utara",
    "value": "Duri Utara"
  },
  {
    "id": "3173041004",
    "district_id": "317304",
    "label": "Tanah Sereal",
    "value": "Tanah Sereal"
  },
  {
    "id": "3173041005",
    "district_id": "317304",
    "label": "Krendang",
    "value": "Krendang"
  },
  {
    "id": "3173041006",
    "district_id": "317304",
    "label": "Jembatan Besi",
    "value": "Jembatan Besi"
  },
  {
    "id": "3173041007",
    "district_id": "317304",
    "label": "Angke",
    "value": "Angke"
  },
  {
    "id": "3173041008",
    "district_id": "317304",
    "label": "Jembatan Lima",
    "value": "Jembatan Lima"
  },
  {
    "id": "3173041009",
    "district_id": "317304",
    "label": "Pekojan",
    "value": "Pekojan"
  },
  {
    "id": "3173041010",
    "district_id": "317304",
    "label": "Roa Malaka",
    "value": "Roa Malaka"
  },
  {
    "id": "3173041011",
    "district_id": "317304",
    "label": "Duri Selatan",
    "value": "Duri Selatan"
  },
  {
    "id": "3173051001",
    "district_id": "317305",
    "label": "Kebon Jeruk",
    "value": "Kebon Jeruk"
  },
  {
    "id": "3173051002",
    "district_id": "317305",
    "label": "Sukabumi Utara",
    "value": "Sukabumi Utara"
  },
  {
    "id": "3173051003",
    "district_id": "317305",
    "label": "Sukabumi Selatan",
    "value": "Sukabumi Selatan"
  },
  {
    "id": "3173051004",
    "district_id": "317305",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "3173051005",
    "district_id": "317305",
    "label": "Duri Kepa",
    "value": "Duri Kepa"
  },
  {
    "id": "3173051006",
    "district_id": "317305",
    "label": "Kedoya Utara",
    "value": "Kedoya Utara"
  },
  {
    "id": "3173051007",
    "district_id": "317305",
    "label": "Kedoya Selatan",
    "value": "Kedoya Selatan"
  },
  {
    "id": "3173061001",
    "district_id": "317306",
    "label": "Kalideres",
    "value": "Kalideres"
  },
  {
    "id": "3173061002",
    "district_id": "317306",
    "label": "Semanan",
    "value": "Semanan"
  },
  {
    "id": "3173061003",
    "district_id": "317306",
    "label": "Tegal Alur",
    "value": "Tegal Alur"
  },
  {
    "id": "3173061004",
    "district_id": "317306",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "3173061005",
    "district_id": "317306",
    "label": "Pegadungan",
    "value": "Pegadungan"
  },
  {
    "id": "3173071001",
    "district_id": "317307",
    "label": "Palmerah",
    "value": "Palmerah"
  },
  {
    "id": "3173071002",
    "district_id": "317307",
    "label": "Slipi",
    "value": "Slipi"
  },
  {
    "id": "3173071003",
    "district_id": "317307",
    "label": "Kota Bambu Utara",
    "value": "Kota Bambu Utara"
  },
  {
    "id": "3173071004",
    "district_id": "317307",
    "label": "Jatipulo",
    "value": "Jatipulo"
  },
  {
    "id": "3173071005",
    "district_id": "317307",
    "label": "Kemanggisan",
    "value": "Kemanggisan"
  },
  {
    "id": "3173071006",
    "district_id": "317307",
    "label": "Kota Bambu Selatan",
    "value": "Kota Bambu Selatan"
  },
  {
    "id": "3173081001",
    "district_id": "317308",
    "label": "Kembangan Utara",
    "value": "Kembangan Utara"
  },
  {
    "id": "3173081002",
    "district_id": "317308",
    "label": "Meruya Utara",
    "value": "Meruya Utara"
  },
  {
    "id": "3173081003",
    "district_id": "317308",
    "label": "Meruya Selatan",
    "value": "Meruya Selatan"
  },
  {
    "id": "3173081004",
    "district_id": "317308",
    "label": "Srengseng",
    "value": "Srengseng"
  },
  {
    "id": "3173081005",
    "district_id": "317308",
    "label": "Joglo",
    "value": "Joglo"
  },
  {
    "id": "3173081006",
    "district_id": "317308",
    "label": "Kembangan Selatan",
    "value": "Kembangan Selatan"
  },
  {
    "id": "3174011001",
    "district_id": "317401",
    "label": "Tebet Timur",
    "value": "Tebet Timur"
  },
  {
    "id": "3174011002",
    "district_id": "317401",
    "label": "Tebet Barat",
    "value": "Tebet Barat"
  },
  {
    "id": "3174011003",
    "district_id": "317401",
    "label": "Menteng Dalam",
    "value": "Menteng Dalam"
  },
  {
    "id": "3174011004",
    "district_id": "317401",
    "label": "Kebon Baru",
    "value": "Kebon Baru"
  },
  {
    "id": "3174011005",
    "district_id": "317401",
    "label": "Bukit Duri",
    "value": "Bukit Duri"
  },
  {
    "id": "3174011006",
    "district_id": "317401",
    "label": "Manggarai Selatan",
    "value": "Manggarai Selatan"
  },
  {
    "id": "3174011007",
    "district_id": "317401",
    "label": "Manggarai",
    "value": "Manggarai"
  },
  {
    "id": "3174021001",
    "district_id": "317402",
    "label": "Setia Budi",
    "value": "Setia Budi"
  },
  {
    "id": "3174021002",
    "district_id": "317402",
    "label": "Karet Semanggi",
    "value": "Karet Semanggi"
  },
  {
    "id": "3174021003",
    "district_id": "317402",
    "label": "Karet Kuningan",
    "value": "Karet Kuningan"
  },
  {
    "id": "3174021004",
    "district_id": "317402",
    "label": "Karet",
    "value": "Karet"
  },
  {
    "id": "3174021005",
    "district_id": "317402",
    "label": "Menteng Atas",
    "value": "Menteng Atas"
  },
  {
    "id": "3174021006",
    "district_id": "317402",
    "label": "Pasar Manggis",
    "value": "Pasar Manggis"
  },
  {
    "id": "3174021007",
    "district_id": "317402",
    "label": "Guntur",
    "value": "Guntur"
  },
  {
    "id": "3174021008",
    "district_id": "317402",
    "label": "Kuningan Timur",
    "value": "Kuningan Timur"
  },
  {
    "id": "3174031001",
    "district_id": "317403",
    "label": "Mampang Prapatan",
    "value": "Mampang Prapatan"
  },
  {
    "id": "3174031002",
    "district_id": "317403",
    "label": "Bangka",
    "value": "Bangka"
  },
  {
    "id": "3174031003",
    "district_id": "317403",
    "label": "Pela Mampang",
    "value": "Pela Mampang"
  },
  {
    "id": "3174031004",
    "district_id": "317403",
    "label": "Tegal Parang",
    "value": "Tegal Parang"
  },
  {
    "id": "3174031005",
    "district_id": "317403",
    "label": "Kuningan Barat",
    "value": "Kuningan Barat"
  },
  {
    "id": "3174041001",
    "district_id": "317404",
    "label": "Pasar Minggu",
    "value": "Pasar Minggu"
  },
  {
    "id": "3174041002",
    "district_id": "317404",
    "label": "Jati Padang",
    "value": "Jati Padang"
  },
  {
    "id": "3174041003",
    "district_id": "317404",
    "label": "Cilandak Timur",
    "value": "Cilandak Timur"
  },
  {
    "id": "3174041004",
    "district_id": "317404",
    "label": "Ragunan",
    "value": "Ragunan"
  },
  {
    "id": "3174041005",
    "district_id": "317404",
    "label": "Pejaten Timur",
    "value": "Pejaten Timur"
  },
  {
    "id": "3174041006",
    "district_id": "317404",
    "label": "Pejaten Barat",
    "value": "Pejaten Barat"
  },
  {
    "id": "3174041007",
    "district_id": "317404",
    "label": "Kebagusan",
    "value": "Kebagusan"
  },
  {
    "id": "3174051001",
    "district_id": "317405",
    "label": "Kebayoran Lama Utara",
    "value": "Kebayoran Lama Utara"
  },
  {
    "id": "3174051002",
    "district_id": "317405",
    "label": "Pondok Pinang",
    "value": "Pondok Pinang"
  },
  {
    "id": "3174051003",
    "district_id": "317405",
    "label": "Cipulir",
    "value": "Cipulir"
  },
  {
    "id": "3174051004",
    "district_id": "317405",
    "label": "Grogol Utara",
    "value": "Grogol Utara"
  },
  {
    "id": "3174051005",
    "district_id": "317405",
    "label": "Grogol Selatan",
    "value": "Grogol Selatan"
  },
  {
    "id": "3174051006",
    "district_id": "317405",
    "label": "Kebayoran Lama Selatan",
    "value": "Kebayoran Lama Selatan"
  },
  {
    "id": "3174061001",
    "district_id": "317406",
    "label": "Cilandak Barat",
    "value": "Cilandak Barat"
  },
  {
    "id": "3174061002",
    "district_id": "317406",
    "label": "Lebak Bulus",
    "value": "Lebak Bulus"
  },
  {
    "id": "3174061003",
    "district_id": "317406",
    "label": "Pondok Labu",
    "value": "Pondok Labu"
  },
  {
    "id": "3174061004",
    "district_id": "317406",
    "label": "Gandaria Selatan",
    "value": "Gandaria Selatan"
  },
  {
    "id": "3174061005",
    "district_id": "317406",
    "label": "Cipete Selatan",
    "value": "Cipete Selatan"
  },
  {
    "id": "3174071001",
    "district_id": "317407",
    "label": "Melawai",
    "value": "Melawai"
  },
  {
    "id": "3174071002",
    "district_id": "317407",
    "label": "Gunung",
    "value": "Gunung"
  },
  {
    "id": "3174071003",
    "district_id": "317407",
    "label": "Kramat Pela",
    "value": "Kramat Pela"
  },
  {
    "id": "3174071004",
    "district_id": "317407",
    "label": "Selong",
    "value": "Selong"
  },
  {
    "id": "3174071005",
    "district_id": "317407",
    "label": "Rawa Barat",
    "value": "Rawa Barat"
  },
  {
    "id": "3174071006",
    "district_id": "317407",
    "label": "Senayan",
    "value": "Senayan"
  },
  {
    "id": "3174071007",
    "district_id": "317407",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "3174071008",
    "district_id": "317407",
    "label": "Petogogan",
    "value": "Petogogan"
  },
  {
    "id": "3174071009",
    "district_id": "317407",
    "label": "Gandaria Utara",
    "value": "Gandaria Utara"
  },
  {
    "id": "3174071010",
    "district_id": "317407",
    "label": "Cipete Utara",
    "value": "Cipete Utara"
  },
  {
    "id": "3174081001",
    "district_id": "317408",
    "label": "Pancoran",
    "value": "Pancoran"
  },
  {
    "id": "3174081002",
    "district_id": "317408",
    "label": "Kalibata",
    "value": "Kalibata"
  },
  {
    "id": "3174081003",
    "district_id": "317408",
    "label": "Rawajati",
    "value": "Rawajati"
  },
  {
    "id": "3174081004",
    "district_id": "317408",
    "label": "Duren Tiga",
    "value": "Duren Tiga"
  },
  {
    "id": "3174081005",
    "district_id": "317408",
    "label": "Pengadegan",
    "value": "Pengadegan"
  },
  {
    "id": "3174081006",
    "district_id": "317408",
    "label": "Cikoko",
    "value": "Cikoko"
  },
  {
    "id": "3174091001",
    "district_id": "317409",
    "label": "Jagakarsa",
    "value": "Jagakarsa"
  },
  {
    "id": "3174091002",
    "district_id": "317409",
    "label": "Srengseng Sawah",
    "value": "Srengseng Sawah"
  },
  {
    "id": "3174091003",
    "district_id": "317409",
    "label": "Ciganjur",
    "value": "Ciganjur"
  },
  {
    "id": "3174091004",
    "district_id": "317409",
    "label": "Lenteng Agung",
    "value": "Lenteng Agung"
  },
  {
    "id": "3174091005",
    "district_id": "317409",
    "label": "Tanjung Barat",
    "value": "Tanjung Barat"
  },
  {
    "id": "3174091006",
    "district_id": "317409",
    "label": "Cipedak",
    "value": "Cipedak"
  },
  {
    "id": "3174101001",
    "district_id": "317410",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3174101002",
    "district_id": "317410",
    "label": "Bintaro",
    "value": "Bintaro"
  },
  {
    "id": "3174101003",
    "district_id": "317410",
    "label": "Petukangan Utara",
    "value": "Petukangan Utara"
  },
  {
    "id": "3174101004",
    "district_id": "317410",
    "label": "Petukangan Selatan",
    "value": "Petukangan Selatan"
  },
  {
    "id": "3174101005",
    "district_id": "317410",
    "label": "Ulujami",
    "value": "Ulujami"
  },
  {
    "id": "3175011001",
    "district_id": "317501",
    "label": "Pisangan Baru",
    "value": "Pisangan Baru"
  },
  {
    "id": "3175011002",
    "district_id": "317501",
    "label": "Utan Kayu Utara",
    "value": "Utan Kayu Utara"
  },
  {
    "id": "3175011003",
    "district_id": "317501",
    "label": "Kayu Manis",
    "value": "Kayu Manis"
  },
  {
    "id": "3175011004",
    "district_id": "317501",
    "label": "Palmeriam",
    "value": "Palmeriam"
  },
  {
    "id": "3175011005",
    "district_id": "317501",
    "label": "Kebon Manggis",
    "value": "Kebon Manggis"
  },
  {
    "id": "3175011006",
    "district_id": "317501",
    "label": "Utan Kayu Selatan",
    "value": "Utan Kayu Selatan"
  },
  {
    "id": "3175021001",
    "district_id": "317502",
    "label": "Pulo Gadung",
    "value": "Pulo Gadung"
  },
  {
    "id": "3175021002",
    "district_id": "317502",
    "label": "Pisangan Timur",
    "value": "Pisangan Timur"
  },
  {
    "id": "3175021003",
    "district_id": "317502",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3175021004",
    "district_id": "317502",
    "label": "Jatinegara Kaum",
    "value": "Jatinegara Kaum"
  },
  {
    "id": "3175021005",
    "district_id": "317502",
    "label": "Rawamangun",
    "value": "Rawamangun"
  },
  {
    "id": "3175021006",
    "district_id": "317502",
    "label": "Kayu Putih",
    "value": "Kayu Putih"
  },
  {
    "id": "3175021007",
    "district_id": "317502",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3175031001",
    "district_id": "317503",
    "label": "Kampung Melayu",
    "value": "Kampung Melayu"
  },
  {
    "id": "3175031002",
    "district_id": "317503",
    "label": "Bidara Cina",
    "value": "Bidara Cina"
  },
  {
    "id": "3175031003",
    "district_id": "317503",
    "label": "Bali Mester",
    "value": "Bali Mester"
  },
  {
    "id": "3175031004",
    "district_id": "317503",
    "label": "Rawa Bunga",
    "value": "Rawa Bunga"
  },
  {
    "id": "3175031005",
    "district_id": "317503",
    "label": "Cipinang Cempedak",
    "value": "Cipinang Cempedak"
  },
  {
    "id": "3175031006",
    "district_id": "317503",
    "label": "Cipinang Muara",
    "value": "Cipinang Muara"
  },
  {
    "id": "3175031007",
    "district_id": "317503",
    "label": "Cipinang Besar Selatan",
    "value": "Cipinang Besar Selatan"
  },
  {
    "id": "3175031008",
    "district_id": "317503",
    "label": "Cipinang Besar Utara",
    "value": "Cipinang Besar Utara"
  },
  {
    "id": "3175041001",
    "district_id": "317504",
    "label": "Kramatjati",
    "value": "Kramatjati"
  },
  {
    "id": "3175041002",
    "district_id": "317504",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "3175041003",
    "district_id": "317504",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3175041004",
    "district_id": "317504",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "3175041005",
    "district_id": "317504",
    "label": "Balekambang",
    "value": "Balekambang"
  },
  {
    "id": "3175041006",
    "district_id": "317504",
    "label": "Cililitan",
    "value": "Cililitan"
  },
  {
    "id": "3175041007",
    "district_id": "317504",
    "label": "Cawang",
    "value": "Cawang"
  },
  {
    "id": "3175051001",
    "district_id": "317505",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3175051002",
    "district_id": "317505",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "3175051003",
    "district_id": "317505",
    "label": "Cijantung",
    "value": "Cijantung"
  },
  {
    "id": "3175051004",
    "district_id": "317505",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3175051005",
    "district_id": "317505",
    "label": "Pekayon",
    "value": "Pekayon"
  },
  {
    "id": "3175061001",
    "district_id": "317506",
    "label": "Jatinegara",
    "value": "Jatinegara"
  },
  {
    "id": "3175061002",
    "district_id": "317506",
    "label": "Rawa Terate",
    "value": "Rawa Terate"
  },
  {
    "id": "3175061003",
    "district_id": "317506",
    "label": "Penggilingan",
    "value": "Penggilingan"
  },
  {
    "id": "3175061004",
    "district_id": "317506",
    "label": "Cakung Timur",
    "value": "Cakung Timur"
  },
  {
    "id": "3175061005",
    "district_id": "317506",
    "label": "Pulo Gebang",
    "value": "Pulo Gebang"
  },
  {
    "id": "3175061006",
    "district_id": "317506",
    "label": "Ujung Menteng",
    "value": "Ujung Menteng"
  },
  {
    "id": "3175061007",
    "district_id": "317506",
    "label": "Cakung Barat",
    "value": "Cakung Barat"
  },
  {
    "id": "3175071001",
    "district_id": "317507",
    "label": "Duren Sawit",
    "value": "Duren Sawit"
  },
  {
    "id": "3175071002",
    "district_id": "317507",
    "label": "Pondok Bambu",
    "value": "Pondok Bambu"
  },
  {
    "id": "3175071003",
    "district_id": "317507",
    "label": "Klender",
    "value": "Klender"
  },
  {
    "id": "3175071004",
    "district_id": "317507",
    "label": "Pondok Kelapa",
    "value": "Pondok Kelapa"
  },
  {
    "id": "3175071005",
    "district_id": "317507",
    "label": "Malaka Sari",
    "value": "Malaka Sari"
  },
  {
    "id": "3175071006",
    "district_id": "317507",
    "label": "Malaka Jaya",
    "value": "Malaka Jaya"
  },
  {
    "id": "3175071007",
    "district_id": "317507",
    "label": "Pondok Kopi",
    "value": "Pondok Kopi"
  },
  {
    "id": "3175081001",
    "district_id": "317508",
    "label": "Makasar",
    "value": "Makasar"
  },
  {
    "id": "3175081002",
    "district_id": "317508",
    "label": "Pinangranti",
    "value": "Pinangranti"
  },
  {
    "id": "3175081003",
    "district_id": "317508",
    "label": "Kebon Pala",
    "value": "Kebon Pala"
  },
  {
    "id": "3175081004",
    "district_id": "317508",
    "label": "Halim Perdana Kusuma",
    "value": "Halim Perdana Kusuma"
  },
  {
    "id": "3175081005",
    "district_id": "317508",
    "label": "Cipinang Melayu",
    "value": "Cipinang Melayu"
  },
  {
    "id": "3175091001",
    "district_id": "317509",
    "label": "Ciracas",
    "value": "Ciracas"
  },
  {
    "id": "3175091002",
    "district_id": "317509",
    "label": "Cibubur",
    "value": "Cibubur"
  },
  {
    "id": "3175091003",
    "district_id": "317509",
    "label": "Kelapa Dua Wetan",
    "value": "Kelapa Dua Wetan"
  },
  {
    "id": "3175091004",
    "district_id": "317509",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3175091005",
    "district_id": "317509",
    "label": "Rambutan",
    "value": "Rambutan"
  },
  {
    "id": "3175101001",
    "district_id": "317510",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "3175101002",
    "district_id": "317510",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3175101003",
    "district_id": "317510",
    "label": "Pondok Ranggon",
    "value": "Pondok Ranggon"
  },
  {
    "id": "3175101004",
    "district_id": "317510",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3175101005",
    "district_id": "317510",
    "label": "Setu",
    "value": "Setu"
  },
  {
    "id": "3175101006",
    "district_id": "317510",
    "label": "Bambu Apus",
    "value": "Bambu Apus"
  },
  {
    "id": "3175101007",
    "district_id": "317510",
    "label": "Lubang Buaya",
    "value": "Lubang Buaya"
  },
  {
    "id": "3175101008",
    "district_id": "317510",
    "label": "Ceger",
    "value": "Ceger"
  },
  {
    "id": "3201011001",
    "district_id": "320101",
    "label": "Pondok Rajeg",
    "value": "Pondok Rajeg"
  },
  {
    "id": "3201011002",
    "district_id": "320101",
    "label": "Karadenan",
    "value": "Karadenan"
  },
  {
    "id": "3201011003",
    "district_id": "320101",
    "label": "Harapanjaya",
    "value": "Harapanjaya"
  },
  {
    "id": "3201011004",
    "district_id": "320101",
    "label": "Nanggewer",
    "value": "Nanggewer"
  },
  {
    "id": "3201011005",
    "district_id": "320101",
    "label": "Nanggewer Mekar",
    "value": "Nanggewer Mekar"
  },
  {
    "id": "3201011006",
    "district_id": "320101",
    "label": "Cibinong",
    "value": "Cibinong"
  },
  {
    "id": "3201011007",
    "district_id": "320101",
    "label": "Pakansari",
    "value": "Pakansari"
  },
  {
    "id": "3201011008",
    "district_id": "320101",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "3201011009",
    "district_id": "320101",
    "label": "Sukahati",
    "value": "Sukahati"
  },
  {
    "id": "3201011010",
    "district_id": "320101",
    "label": "Ciriung",
    "value": "Ciriung"
  },
  {
    "id": "3201011011",
    "district_id": "320101",
    "label": "Cirimekar",
    "value": "Cirimekar"
  },
  {
    "id": "3201011012",
    "district_id": "320101",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3201011013",
    "district_id": "320101",
    "label": "Pabuaran Mekar",
    "value": "Pabuaran Mekar"
  },
  {
    "id": "3201022001",
    "district_id": "320102",
    "label": "Wanaherang",
    "value": "Wanaherang"
  },
  {
    "id": "3201022002",
    "district_id": "320102",
    "label": "Bojong Kulur",
    "value": "Bojong Kulur"
  },
  {
    "id": "3201022003",
    "district_id": "320102",
    "label": "Ciangsana",
    "value": "Ciangsana"
  },
  {
    "id": "3201022004",
    "district_id": "320102",
    "label": "Gunung Putri",
    "value": "Gunung Putri"
  },
  {
    "id": "3201022005",
    "district_id": "320102",
    "label": "Bojong Nangka",
    "value": "Bojong Nangka"
  },
  {
    "id": "3201022006",
    "district_id": "320102",
    "label": "Tlajung Udik",
    "value": "Tlajung Udik"
  },
  {
    "id": "3201022007",
    "district_id": "320102",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3201022008",
    "district_id": "320102",
    "label": "Cikeas Udik",
    "value": "Cikeas Udik"
  },
  {
    "id": "3201022009",
    "district_id": "320102",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3201022010",
    "district_id": "320102",
    "label": "Karanggan",
    "value": "Karanggan"
  },
  {
    "id": "3201031006",
    "district_id": "320103",
    "label": "Puspanegara",
    "value": "Puspanegara"
  },
  {
    "id": "3201031007",
    "district_id": "320103",
    "label": "Karang Asem Barat",
    "value": "Karang Asem Barat"
  },
  {
    "id": "3201032001",
    "district_id": "320103",
    "label": "Puspasari",
    "value": "Puspasari"
  },
  {
    "id": "3201032002",
    "district_id": "320103",
    "label": "Citeureup",
    "value": "Citeureup"
  },
  {
    "id": "3201032003",
    "district_id": "320103",
    "label": "Leuwinutug",
    "value": "Leuwinutug"
  },
  {
    "id": "3201032004",
    "district_id": "320103",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "3201032005",
    "district_id": "320103",
    "label": "Sanja",
    "value": "Sanja"
  },
  {
    "id": "3201032008",
    "district_id": "320103",
    "label": "Karang Asem Timur",
    "value": "Karang Asem Timur"
  },
  {
    "id": "3201032009",
    "district_id": "320103",
    "label": "Tarikolot",
    "value": "Tarikolot"
  },
  {
    "id": "3201032010",
    "district_id": "320103",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3201032011",
    "district_id": "320103",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3201032012",
    "district_id": "320103",
    "label": "Sukahati",
    "value": "Sukahati"
  },
  {
    "id": "3201032013",
    "district_id": "320103",
    "label": "Hambalang",
    "value": "Hambalang"
  },
  {
    "id": "3201032014",
    "district_id": "320103",
    "label": "Pasirmukti",
    "value": "Pasirmukti"
  },
  {
    "id": "3201042001",
    "district_id": "320104",
    "label": "Gununggeulis",
    "value": "Gununggeulis"
  },
  {
    "id": "3201042002",
    "district_id": "320104",
    "label": "Cilebut Timur",
    "value": "Cilebut Timur"
  },
  {
    "id": "3201042003",
    "district_id": "320104",
    "label": "Cilebut Barat",
    "value": "Cilebut Barat"
  },
  {
    "id": "3201042004",
    "district_id": "320104",
    "label": "Cibanon",
    "value": "Cibanon"
  },
  {
    "id": "3201042005",
    "district_id": "320104",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3201042006",
    "district_id": "320104",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3201042007",
    "district_id": "320104",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3201042008",
    "district_id": "320104",
    "label": "Cikeas",
    "value": "Cikeas"
  },
  {
    "id": "3201042009",
    "district_id": "320104",
    "label": "Pasir Jambu",
    "value": "Pasir Jambu"
  },
  {
    "id": "3201042010",
    "district_id": "320104",
    "label": "Cimandala",
    "value": "Cimandala"
  },
  {
    "id": "3201042011",
    "district_id": "320104",
    "label": "Cijujung",
    "value": "Cijujung"
  },
  {
    "id": "3201042012",
    "district_id": "320104",
    "label": "Cadasngampar",
    "value": "Cadasngampar"
  },
  {
    "id": "3201042013",
    "district_id": "320104",
    "label": "Pasirlaja",
    "value": "Pasirlaja"
  },
  {
    "id": "3201052001",
    "district_id": "320105",
    "label": "Cijayanti",
    "value": "Cijayanti"
  },
  {
    "id": "3201052002",
    "district_id": "320105",
    "label": "Sumurbatu",
    "value": "Sumurbatu"
  },
  {
    "id": "3201052003",
    "district_id": "320105",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3201052004",
    "district_id": "320105",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3201052005",
    "district_id": "320105",
    "label": "Cipambuan",
    "value": "Cipambuan"
  },
  {
    "id": "3201052006",
    "district_id": "320105",
    "label": "Kadumanggu",
    "value": "Kadumanggu"
  },
  {
    "id": "3201052007",
    "district_id": "320105",
    "label": "Citaringgul",
    "value": "Citaringgul"
  },
  {
    "id": "3201052008",
    "district_id": "320105",
    "label": "Babakan Madang",
    "value": "Babakan Madang"
  },
  {
    "id": "3201052009",
    "district_id": "320105",
    "label": "Bojong Koneng",
    "value": "Bojong Koneng"
  },
  {
    "id": "3201062001",
    "district_id": "320106",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3201062002",
    "district_id": "320106",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3201062003",
    "district_id": "320106",
    "label": "Singajaya",
    "value": "Singajaya"
  },
  {
    "id": "3201062004",
    "district_id": "320106",
    "label": "Sukasirna",
    "value": "Sukasirna"
  },
  {
    "id": "3201062005",
    "district_id": "320106",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "3201062006",
    "district_id": "320106",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3201062007",
    "district_id": "320106",
    "label": "Weninggalih",
    "value": "Weninggalih"
  },
  {
    "id": "3201062008",
    "district_id": "320106",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3201062009",
    "district_id": "320106",
    "label": "Jonggol",
    "value": "Jonggol"
  },
  {
    "id": "3201062010",
    "district_id": "320106",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3201062011",
    "district_id": "320106",
    "label": "Singasari",
    "value": "Singasari"
  },
  {
    "id": "3201062012",
    "district_id": "320106",
    "label": "Balekambang",
    "value": "Balekambang"
  },
  {
    "id": "3201062013",
    "district_id": "320106",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3201062014",
    "district_id": "320106",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3201072001",
    "district_id": "320107",
    "label": "Pasirangin",
    "value": "Pasirangin"
  },
  {
    "id": "3201072002",
    "district_id": "320107",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3201072003",
    "district_id": "320107",
    "label": "Mampir",
    "value": "Mampir"
  },
  {
    "id": "3201072004",
    "district_id": "320107",
    "label": "Dayeuh",
    "value": "Dayeuh"
  },
  {
    "id": "3201072005",
    "district_id": "320107",
    "label": "Gandoang",
    "value": "Gandoang"
  },
  {
    "id": "3201072006",
    "district_id": "320107",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3201072007",
    "district_id": "320107",
    "label": "Cileungsi Kidul",
    "value": "Cileungsi Kidul"
  },
  {
    "id": "3201072008",
    "district_id": "320107",
    "label": "Cipeucang",
    "value": "Cipeucang"
  },
  {
    "id": "3201072009",
    "district_id": "320107",
    "label": "Situsari",
    "value": "Situsari"
  },
  {
    "id": "3201072010",
    "district_id": "320107",
    "label": "Cipenjo",
    "value": "Cipenjo"
  },
  {
    "id": "3201072011",
    "district_id": "320107",
    "label": "Limusnunggal",
    "value": "Limusnunggal"
  },
  {
    "id": "3201072012",
    "district_id": "320107",
    "label": "Cileungsi",
    "value": "Cileungsi"
  },
  {
    "id": "3201082001",
    "district_id": "320108",
    "label": "Karyamekar",
    "value": "Karyamekar"
  },
  {
    "id": "3201082002",
    "district_id": "320108",
    "label": "Babakanraden",
    "value": "Babakanraden"
  },
  {
    "id": "3201082003",
    "district_id": "320108",
    "label": "Cikutamahi",
    "value": "Cikutamahi"
  },
  {
    "id": "3201082004",
    "district_id": "320108",
    "label": "Kutamekar",
    "value": "Kutamekar"
  },
  {
    "id": "3201082005",
    "district_id": "320108",
    "label": "Cariu",
    "value": "Cariu"
  },
  {
    "id": "3201082006",
    "district_id": "320108",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3201082007",
    "district_id": "320108",
    "label": "Bantarkuning",
    "value": "Bantarkuning"
  },
  {
    "id": "3201082008",
    "district_id": "320108",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3201082009",
    "district_id": "320108",
    "label": "Tegalpanjang",
    "value": "Tegalpanjang"
  },
  {
    "id": "3201082010",
    "district_id": "320108",
    "label": "Cibatutiga",
    "value": "Cibatutiga"
  },
  {
    "id": "3201092001",
    "district_id": "320109",
    "label": "Wargajaya",
    "value": "Wargajaya"
  },
  {
    "id": "3201092002",
    "district_id": "320109",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3201092003",
    "district_id": "320109",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "3201092004",
    "district_id": "320109",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3201092005",
    "district_id": "320109",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3201092006",
    "district_id": "320109",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3201092007",
    "district_id": "320109",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3201092008",
    "district_id": "320109",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3201092009",
    "district_id": "320109",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3201092010",
    "district_id": "320109",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "3201102001",
    "district_id": "320110",
    "label": "Parung",
    "value": "Parung"
  },
  {
    "id": "3201102002",
    "district_id": "320110",
    "label": "Iwul",
    "value": "Iwul"
  },
  {
    "id": "3201102003",
    "district_id": "320110",
    "label": "Bojongsempu",
    "value": "Bojongsempu"
  },
  {
    "id": "3201102004",
    "district_id": "320110",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3201102005",
    "district_id": "320110",
    "label": "Cogreg",
    "value": "Cogreg"
  },
  {
    "id": "3201102006",
    "district_id": "320110",
    "label": "Pamegarsari",
    "value": "Pamegarsari"
  },
  {
    "id": "3201102007",
    "district_id": "320110",
    "label": "Warujaya",
    "value": "Warujaya"
  },
  {
    "id": "3201102008",
    "district_id": "320110",
    "label": "Bojongindah",
    "value": "Bojongindah"
  },
  {
    "id": "3201102009",
    "district_id": "320110",
    "label": "Jabonmekar",
    "value": "Jabonmekar"
  },
  {
    "id": "3201112001",
    "district_id": "320111",
    "label": "Cidokom",
    "value": "Cidokom"
  },
  {
    "id": "3201112002",
    "district_id": "320111",
    "label": "Padurenan",
    "value": "Padurenan"
  },
  {
    "id": "3201112003",
    "district_id": "320111",
    "label": "Pengasinan",
    "value": "Pengasinan"
  },
  {
    "id": "3201112004",
    "district_id": "320111",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3201112005",
    "district_id": "320111",
    "label": "Gunungsindur",
    "value": "Gunungsindur"
  },
  {
    "id": "3201112006",
    "district_id": "320111",
    "label": "Jampang",
    "value": "Jampang"
  },
  {
    "id": "3201112007",
    "district_id": "320111",
    "label": "Cibadung",
    "value": "Cibadung"
  },
  {
    "id": "3201112008",
    "district_id": "320111",
    "label": "Cibinong",
    "value": "Cibinong"
  },
  {
    "id": "3201112009",
    "district_id": "320111",
    "label": "Rawakalong",
    "value": "Rawakalong"
  },
  {
    "id": "3201112010",
    "district_id": "320111",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3201121006",
    "district_id": "320112",
    "label": "Atang Senjaya",
    "value": "Atang Senjaya"
  },
  {
    "id": "3201122001",
    "district_id": "320112",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3201122002",
    "district_id": "320112",
    "label": "Parakanjaya",
    "value": "Parakanjaya"
  },
  {
    "id": "3201122003",
    "district_id": "320112",
    "label": "Kemang",
    "value": "Kemang"
  },
  {
    "id": "3201122004",
    "district_id": "320112",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3201122005",
    "district_id": "320112",
    "label": "Semplak Barat",
    "value": "Semplak Barat"
  },
  {
    "id": "3201122007",
    "district_id": "320112",
    "label": "Jampang",
    "value": "Jampang"
  },
  {
    "id": "3201122008",
    "district_id": "320112",
    "label": "Pondok Udik",
    "value": "Pondok Udik"
  },
  {
    "id": "3201122009",
    "district_id": "320112",
    "label": "Tegal",
    "value": "Tegal"
  },
  {
    "id": "3201131007",
    "district_id": "320113",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3201132001",
    "district_id": "320113",
    "label": "Bojongbaru",
    "value": "Bojongbaru"
  },
  {
    "id": "3201132002",
    "district_id": "320113",
    "label": "Cimanggis",
    "value": "Cimanggis"
  },
  {
    "id": "3201132003",
    "district_id": "320113",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3201132004",
    "district_id": "320113",
    "label": "Ragajaya",
    "value": "Ragajaya"
  },
  {
    "id": "3201132005",
    "district_id": "320113",
    "label": "Kedungwaringin",
    "value": "Kedungwaringin"
  },
  {
    "id": "3201132006",
    "district_id": "320113",
    "label": "Waringinjaya",
    "value": "Waringinjaya"
  },
  {
    "id": "3201132008",
    "district_id": "320113",
    "label": "Rawapanjang",
    "value": "Rawapanjang"
  },
  {
    "id": "3201132009",
    "district_id": "320113",
    "label": "Bojonggede",
    "value": "Bojonggede"
  },
  {
    "id": "3201142001",
    "district_id": "320114",
    "label": "Leuwiliang",
    "value": "Leuwiliang"
  },
  {
    "id": "3201142002",
    "district_id": "320114",
    "label": "Purasari",
    "value": "Purasari"
  },
  {
    "id": "3201142003",
    "district_id": "320114",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "3201142004",
    "district_id": "320114",
    "label": "Pabangbon",
    "value": "Pabangbon"
  },
  {
    "id": "3201142005",
    "district_id": "320114",
    "label": "Karacak",
    "value": "Karacak"
  },
  {
    "id": "3201142006",
    "district_id": "320114",
    "label": "Barengkok",
    "value": "Barengkok"
  },
  {
    "id": "3201142007",
    "district_id": "320114",
    "label": "Leuwimekar",
    "value": "Leuwimekar"
  },
  {
    "id": "3201142008",
    "district_id": "320114",
    "label": "Puraseda",
    "value": "Puraseda"
  },
  {
    "id": "3201142009",
    "district_id": "320114",
    "label": "Cibeber I",
    "value": "Cibeber I"
  },
  {
    "id": "3201142010",
    "district_id": "320114",
    "label": "Cibeber II",
    "value": "Cibeber II"
  },
  {
    "id": "3201142011",
    "district_id": "320114",
    "label": "Karehkel",
    "value": "Karehkel"
  },
  {
    "id": "3201152001",
    "district_id": "320115",
    "label": "Ciampea",
    "value": "Ciampea"
  },
  {
    "id": "3201152002",
    "district_id": "320115",
    "label": "Cinangka",
    "value": "Cinangka"
  },
  {
    "id": "3201152003",
    "district_id": "320115",
    "label": "Cihideungudik",
    "value": "Cihideungudik"
  },
  {
    "id": "3201152004",
    "district_id": "320115",
    "label": "Bojongjengkol",
    "value": "Bojongjengkol"
  },
  {
    "id": "3201152005",
    "district_id": "320115",
    "label": "Tegalwaru",
    "value": "Tegalwaru"
  },
  {
    "id": "3201152006",
    "district_id": "320115",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3201152007",
    "district_id": "320115",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3201152008",
    "district_id": "320115",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3201152009",
    "district_id": "320115",
    "label": "Bojongrangkas",
    "value": "Bojongrangkas"
  },
  {
    "id": "3201152010",
    "district_id": "320115",
    "label": "Cihideunghilir",
    "value": "Cihideunghilir"
  },
  {
    "id": "3201152011",
    "district_id": "320115",
    "label": "Cibanteng",
    "value": "Cibanteng"
  },
  {
    "id": "3201152012",
    "district_id": "320115",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "3201152013",
    "district_id": "320115",
    "label": "Ciampea Udik",
    "value": "Ciampea Udik"
  },
  {
    "id": "3201162001",
    "district_id": "320116",
    "label": "Situ Udik",
    "value": "Situ Udik"
  },
  {
    "id": "3201162002",
    "district_id": "320116",
    "label": "Situ Ilir",
    "value": "Situ Ilir"
  },
  {
    "id": "3201162003",
    "district_id": "320116",
    "label": "Cemplang",
    "value": "Cemplang"
  },
  {
    "id": "3201162004",
    "district_id": "320116",
    "label": "Cibatok I",
    "value": "Cibatok I"
  },
  {
    "id": "3201162005",
    "district_id": "320116",
    "label": "Ciaruteun Udik",
    "value": "Ciaruteun Udik"
  },
  {
    "id": "3201162006",
    "district_id": "320116",
    "label": "Leuweungkolot",
    "value": "Leuweungkolot"
  },
  {
    "id": "3201162007",
    "district_id": "320116",
    "label": "Cimanggu I",
    "value": "Cimanggu I"
  },
  {
    "id": "3201162008",
    "district_id": "320116",
    "label": "Cimanggu II",
    "value": "Cimanggu II"
  },
  {
    "id": "3201162009",
    "district_id": "320116",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3201162010",
    "district_id": "320116",
    "label": "Cijujung",
    "value": "Cijujung"
  },
  {
    "id": "3201162011",
    "district_id": "320116",
    "label": "Ciaruteun Ilir",
    "value": "Ciaruteun Ilir"
  },
  {
    "id": "3201162012",
    "district_id": "320116",
    "label": "Cibatok II",
    "value": "Cibatok II"
  },
  {
    "id": "3201162013",
    "district_id": "320116",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3201162014",
    "district_id": "320116",
    "label": "Galuga",
    "value": "Galuga"
  },
  {
    "id": "3201162015",
    "district_id": "320116",
    "label": "Girimulya",
    "value": "Girimulya"
  },
  {
    "id": "3201172001",
    "district_id": "320117",
    "label": "Purwabakti",
    "value": "Purwabakti"
  },
  {
    "id": "3201172002",
    "district_id": "320117",
    "label": "Cibunian",
    "value": "Cibunian"
  },
  {
    "id": "3201172003",
    "district_id": "320117",
    "label": "Cibitungwetan",
    "value": "Cibitungwetan"
  },
  {
    "id": "3201172004",
    "district_id": "320117",
    "label": "Gunungmenyan",
    "value": "Gunungmenyan"
  },
  {
    "id": "3201172005",
    "district_id": "320117",
    "label": "Gunungbunder II",
    "value": "Gunungbunder II"
  },
  {
    "id": "3201172006",
    "district_id": "320117",
    "label": "Pasarean",
    "value": "Pasarean"
  },
  {
    "id": "3201172007",
    "district_id": "320117",
    "label": "Cimayang",
    "value": "Cimayang"
  },
  {
    "id": "3201172008",
    "district_id": "320117",
    "label": "Pamijahan",
    "value": "Pamijahan"
  },
  {
    "id": "3201172009",
    "district_id": "320117",
    "label": "Cibening",
    "value": "Cibening"
  },
  {
    "id": "3201172010",
    "district_id": "320117",
    "label": "Gunungbunder I",
    "value": "Gunungbunder I"
  },
  {
    "id": "3201172011",
    "district_id": "320117",
    "label": "Cibitung Kulon",
    "value": "Cibitung Kulon"
  },
  {
    "id": "3201172012",
    "district_id": "320117",
    "label": "Gunung Picung",
    "value": "Gunung Picung"
  },
  {
    "id": "3201172013",
    "district_id": "320117",
    "label": "Ciasihan",
    "value": "Ciasihan"
  },
  {
    "id": "3201172014",
    "district_id": "320117",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3201172015",
    "district_id": "320117",
    "label": "Ciasmara",
    "value": "Ciasmara"
  },
  {
    "id": "3201182001",
    "district_id": "320118",
    "label": "Rumpin",
    "value": "Rumpin"
  },
  {
    "id": "3201182002",
    "district_id": "320118",
    "label": "Leuwibatu",
    "value": "Leuwibatu"
  },
  {
    "id": "3201182003",
    "district_id": "320118",
    "label": "Cidokom",
    "value": "Cidokom"
  },
  {
    "id": "3201182004",
    "district_id": "320118",
    "label": "Gobang",
    "value": "Gobang"
  },
  {
    "id": "3201182005",
    "district_id": "320118",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3201182006",
    "district_id": "320118",
    "label": "Rabak",
    "value": "Rabak"
  },
  {
    "id": "3201182007",
    "district_id": "320118",
    "label": "Kampungsawah",
    "value": "Kampungsawah"
  },
  {
    "id": "3201182008",
    "district_id": "320118",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3201182009",
    "district_id": "320118",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3201182010",
    "district_id": "320118",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3201182011",
    "district_id": "320118",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3201182012",
    "district_id": "320118",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3201182013",
    "district_id": "320118",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3201182014",
    "district_id": "320118",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3201192001",
    "district_id": "320119",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3201192002",
    "district_id": "320119",
    "label": "Pangradin",
    "value": "Pangradin"
  },
  {
    "id": "3201192003",
    "district_id": "320119",
    "label": "Kalongsawah",
    "value": "Kalongsawah"
  },
  {
    "id": "3201192004",
    "district_id": "320119",
    "label": "Sipak",
    "value": "Sipak"
  },
  {
    "id": "3201192005",
    "district_id": "320119",
    "label": "Jasinga",
    "value": "Jasinga"
  },
  {
    "id": "3201192006",
    "district_id": "320119",
    "label": "Koleang",
    "value": "Koleang"
  },
  {
    "id": "3201192007",
    "district_id": "320119",
    "label": "Cikopomayak",
    "value": "Cikopomayak"
  },
  {
    "id": "3201192008",
    "district_id": "320119",
    "label": "Setu",
    "value": "Setu"
  },
  {
    "id": "3201192009",
    "district_id": "320119",
    "label": "Barengkok",
    "value": "Barengkok"
  },
  {
    "id": "3201192010",
    "district_id": "320119",
    "label": "Bagoang",
    "value": "Bagoang"
  },
  {
    "id": "3201192011",
    "district_id": "320119",
    "label": "Pangaur",
    "value": "Pangaur"
  },
  {
    "id": "3201192012",
    "district_id": "320119",
    "label": "Pamagersari",
    "value": "Pamagersari"
  },
  {
    "id": "3201192013",
    "district_id": "320119",
    "label": "Jugala Jaya",
    "value": "Jugala Jaya"
  },
  {
    "id": "3201192014",
    "district_id": "320119",
    "label": "Tegalwangi",
    "value": "Tegalwangi"
  },
  {
    "id": "3201192015",
    "district_id": "320119",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3201192016",
    "district_id": "320119",
    "label": "Wirajaya",
    "value": "Wirajaya"
  },
  {
    "id": "3201202001",
    "district_id": "320120",
    "label": "Jagabaya",
    "value": "Jagabaya"
  },
  {
    "id": "3201202002",
    "district_id": "320120",
    "label": "Gorowong",
    "value": "Gorowong"
  },
  {
    "id": "3201202003",
    "district_id": "320120",
    "label": "Dago",
    "value": "Dago"
  },
  {
    "id": "3201202004",
    "district_id": "320120",
    "label": "Pingku",
    "value": "Pingku"
  },
  {
    "id": "3201202005",
    "district_id": "320120",
    "label": "Cikuda",
    "value": "Cikuda"
  },
  {
    "id": "3201202006",
    "district_id": "320120",
    "label": "Parungpanjang",
    "value": "Parungpanjang"
  },
  {
    "id": "3201202007",
    "district_id": "320120",
    "label": "Lumpang",
    "value": "Lumpang"
  },
  {
    "id": "3201202008",
    "district_id": "320120",
    "label": "Cibunar",
    "value": "Cibunar"
  },
  {
    "id": "3201202009",
    "district_id": "320120",
    "label": "Jagabita",
    "value": "Jagabita"
  },
  {
    "id": "3201202010",
    "district_id": "320120",
    "label": "Gintungcilejet",
    "value": "Gintungcilejet"
  },
  {
    "id": "3201202011",
    "district_id": "320120",
    "label": "Kabasiran",
    "value": "Kabasiran"
  },
  {
    "id": "3201212001",
    "district_id": "320121",
    "label": "Malasari",
    "value": "Malasari"
  },
  {
    "id": "3201212002",
    "district_id": "320121",
    "label": "Curugbitung",
    "value": "Curugbitung"
  },
  {
    "id": "3201212003",
    "district_id": "320121",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3201212004",
    "district_id": "320121",
    "label": "Bantarkaret",
    "value": "Bantarkaret"
  },
  {
    "id": "3201212005",
    "district_id": "320121",
    "label": "Hambaro",
    "value": "Hambaro"
  },
  {
    "id": "3201212006",
    "district_id": "320121",
    "label": "Kalongliud",
    "value": "Kalongliud"
  },
  {
    "id": "3201212007",
    "district_id": "320121",
    "label": "Nanggung",
    "value": "Nanggung"
  },
  {
    "id": "3201212008",
    "district_id": "320121",
    "label": "Parakanmuncang",
    "value": "Parakanmuncang"
  },
  {
    "id": "3201212009",
    "district_id": "320121",
    "label": "Pangkaljaya",
    "value": "Pangkaljaya"
  },
  {
    "id": "3201212010",
    "district_id": "320121",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3201212011",
    "district_id": "320121",
    "label": "Batu Tulis",
    "value": "Batu Tulis"
  },
  {
    "id": "3201222001",
    "district_id": "320122",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3201222002",
    "district_id": "320122",
    "label": "Cigudeg",
    "value": "Cigudeg"
  },
  {
    "id": "3201222003",
    "district_id": "320122",
    "label": "Bunar",
    "value": "Bunar"
  },
  {
    "id": "3201222004",
    "district_id": "320122",
    "label": "Banyuresmi",
    "value": "Banyuresmi"
  },
  {
    "id": "3201222005",
    "district_id": "320122",
    "label": "Cintamanik",
    "value": "Cintamanik"
  },
  {
    "id": "3201222006",
    "district_id": "320122",
    "label": "Argapura",
    "value": "Argapura"
  },
  {
    "id": "3201222007",
    "district_id": "320122",
    "label": "Bangunjaya",
    "value": "Bangunjaya"
  },
  {
    "id": "3201222008",
    "district_id": "320122",
    "label": "Rengasjajar",
    "value": "Rengasjajar"
  },
  {
    "id": "3201222009",
    "district_id": "320122",
    "label": "Batujajar",
    "value": "Batujajar"
  },
  {
    "id": "3201222010",
    "district_id": "320122",
    "label": "Wargajaya",
    "value": "Wargajaya"
  },
  {
    "id": "3201222011",
    "district_id": "320122",
    "label": "Sukaraksa",
    "value": "Sukaraksa"
  },
  {
    "id": "3201222012",
    "district_id": "320122",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "3201222013",
    "district_id": "320122",
    "label": "Banyuasih",
    "value": "Banyuasih"
  },
  {
    "id": "3201222014",
    "district_id": "320122",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3201222015",
    "district_id": "320122",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3201232001",
    "district_id": "320123",
    "label": "Tapos",
    "value": "Tapos"
  },
  {
    "id": "3201232002",
    "district_id": "320123",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3201232003",
    "district_id": "320123",
    "label": "Batok",
    "value": "Batok"
  },
  {
    "id": "3201232004",
    "district_id": "320123",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3201232005",
    "district_id": "320123",
    "label": "Tenjo",
    "value": "Tenjo"
  },
  {
    "id": "3201232006",
    "district_id": "320123",
    "label": "Cilaku",
    "value": "Cilaku"
  },
  {
    "id": "3201232007",
    "district_id": "320123",
    "label": "Singabraja",
    "value": "Singabraja"
  },
  {
    "id": "3201232008",
    "district_id": "320123",
    "label": "Singabangsa",
    "value": "Singabangsa"
  },
  {
    "id": "3201232009",
    "district_id": "320123",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3201242001",
    "district_id": "320124",
    "label": "Cileungsi",
    "value": "Cileungsi"
  },
  {
    "id": "3201242002",
    "district_id": "320124",
    "label": "Citapen",
    "value": "Citapen"
  },
  {
    "id": "3201242003",
    "district_id": "320124",
    "label": "Cibedug",
    "value": "Cibedug"
  },
  {
    "id": "3201242004",
    "district_id": "320124",
    "label": "Jambuluwuk",
    "value": "Jambuluwuk"
  },
  {
    "id": "3201242005",
    "district_id": "320124",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3201242006",
    "district_id": "320124",
    "label": "Teluk Pinang",
    "value": "Teluk Pinang"
  },
  {
    "id": "3201242007",
    "district_id": "320124",
    "label": "Banjar Waru",
    "value": "Banjar Waru"
  },
  {
    "id": "3201242008",
    "district_id": "320124",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3201242009",
    "district_id": "320124",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "3201242010",
    "district_id": "320124",
    "label": "Bojong Murni",
    "value": "Bojong Murni"
  },
  {
    "id": "3201242011",
    "district_id": "320124",
    "label": "Banjar Wangi",
    "value": "Banjar Wangi"
  },
  {
    "id": "3201242012",
    "district_id": "320124",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3201242013",
    "district_id": "320124",
    "label": "Bitungsari",
    "value": "Bitungsari"
  },
  {
    "id": "3201251010",
    "district_id": "320125",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3201252001",
    "district_id": "320125",
    "label": "Batulayang",
    "value": "Batulayang"
  },
  {
    "id": "3201252002",
    "district_id": "320125",
    "label": "Jogjogan",
    "value": "Jogjogan"
  },
  {
    "id": "3201252003",
    "district_id": "320125",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3201252004",
    "district_id": "320125",
    "label": "Cilember",
    "value": "Cilember"
  },
  {
    "id": "3201252005",
    "district_id": "320125",
    "label": "Citeko",
    "value": "Citeko"
  },
  {
    "id": "3201252006",
    "district_id": "320125",
    "label": "Tugu Selatan",
    "value": "Tugu Selatan"
  },
  {
    "id": "3201252007",
    "district_id": "320125",
    "label": "Leuwimalang",
    "value": "Leuwimalang"
  },
  {
    "id": "3201252008",
    "district_id": "320125",
    "label": "Kopo",
    "value": "Kopo"
  },
  {
    "id": "3201252009",
    "district_id": "320125",
    "label": "Tugu Utara",
    "value": "Tugu Utara"
  },
  {
    "id": "3201262001",
    "district_id": "320126",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3201262002",
    "district_id": "320126",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "3201262003",
    "district_id": "320126",
    "label": "Gadog",
    "value": "Gadog"
  },
  {
    "id": "3201262004",
    "district_id": "320126",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3201262005",
    "district_id": "320126",
    "label": "Megamendung",
    "value": "Megamendung"
  },
  {
    "id": "3201262006",
    "district_id": "320126",
    "label": "Cipayung Datar",
    "value": "Cipayung Datar"
  },
  {
    "id": "3201262007",
    "district_id": "320126",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3201262008",
    "district_id": "320126",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3201262009",
    "district_id": "320126",
    "label": "Cipayung Girang",
    "value": "Cipayung Girang"
  },
  {
    "id": "3201262010",
    "district_id": "320126",
    "label": "Sukamahi",
    "value": "Sukamahi"
  },
  {
    "id": "3201262011",
    "district_id": "320126",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3201262012",
    "district_id": "320126",
    "label": "Pasir Angin",
    "value": "Pasir Angin"
  },
  {
    "id": "3201272001",
    "district_id": "320127",
    "label": "Pasir Muncang",
    "value": "Pasir Muncang"
  },
  {
    "id": "3201272002",
    "district_id": "320127",
    "label": "Cimande Hilir",
    "value": "Cimande Hilir"
  },
  {
    "id": "3201272003",
    "district_id": "320127",
    "label": "Ciderum",
    "value": "Ciderum"
  },
  {
    "id": "3201272004",
    "district_id": "320127",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3201272005",
    "district_id": "320127",
    "label": "Ciherang Pondok",
    "value": "Ciherang Pondok"
  },
  {
    "id": "3201272006",
    "district_id": "320127",
    "label": "Cinagara",
    "value": "Cinagara"
  },
  {
    "id": "3201272007",
    "district_id": "320127",
    "label": "Cimande",
    "value": "Cimande"
  },
  {
    "id": "3201272008",
    "district_id": "320127",
    "label": "Pancawati",
    "value": "Pancawati"
  },
  {
    "id": "3201272009",
    "district_id": "320127",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "3201272010",
    "district_id": "320127",
    "label": "Basir Buncir",
    "value": "Basir Buncir"
  },
  {
    "id": "3201272011",
    "district_id": "320127",
    "label": "Lemah Duhur",
    "value": "Lemah Duhur"
  },
  {
    "id": "3201272012",
    "district_id": "320127",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3201282001",
    "district_id": "320128",
    "label": "Cijeruk",
    "value": "Cijeruk"
  },
  {
    "id": "3201282002",
    "district_id": "320128",
    "label": "Cipelang",
    "value": "Cipelang"
  },
  {
    "id": "3201282003",
    "district_id": "320128",
    "label": "Warung Menteng",
    "value": "Warung Menteng"
  },
  {
    "id": "3201282004",
    "district_id": "320128",
    "label": "Tajur Halang",
    "value": "Tajur Halang"
  },
  {
    "id": "3201282005",
    "district_id": "320128",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3201282006",
    "district_id": "320128",
    "label": "Cibalung",
    "value": "Cibalung"
  },
  {
    "id": "3201282007",
    "district_id": "320128",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3201282008",
    "district_id": "320128",
    "label": "Palasari",
    "value": "Palasari"
  },
  {
    "id": "3201282009",
    "district_id": "320128",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3201291003",
    "district_id": "320129",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3201292001",
    "district_id": "320129",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3201292002",
    "district_id": "320129",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3201292004",
    "district_id": "320129",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3201292005",
    "district_id": "320129",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3201292006",
    "district_id": "320129",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3201292007",
    "district_id": "320129",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "3201292008",
    "district_id": "320129",
    "label": "Ciapus",
    "value": "Ciapus"
  },
  {
    "id": "3201292009",
    "district_id": "320129",
    "label": "Kota Batu",
    "value": "Kota Batu"
  },
  {
    "id": "3201292010",
    "district_id": "320129",
    "label": "Laladon",
    "value": "Laladon"
  },
  {
    "id": "3201292011",
    "district_id": "320129",
    "label": "Ciomas Rahayu",
    "value": "Ciomas Rahayu"
  },
  {
    "id": "3201302001",
    "district_id": "320130",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "3201302002",
    "district_id": "320130",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3201302003",
    "district_id": "320130",
    "label": "Sinarsari",
    "value": "Sinarsari"
  },
  {
    "id": "3201302004",
    "district_id": "320130",
    "label": "Sukawening",
    "value": "Sukawening"
  },
  {
    "id": "3201302005",
    "district_id": "320130",
    "label": "Petir",
    "value": "Petir"
  },
  {
    "id": "3201302006",
    "district_id": "320130",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3201302007",
    "district_id": "320130",
    "label": "Cikarawang",
    "value": "Cikarawang"
  },
  {
    "id": "3201302008",
    "district_id": "320130",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3201302009",
    "district_id": "320130",
    "label": "Dramaga",
    "value": "Dramaga"
  },
  {
    "id": "3201302010",
    "district_id": "320130",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3201312001",
    "district_id": "320131",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3201312002",
    "district_id": "320131",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3201312003",
    "district_id": "320131",
    "label": "Pasireurih",
    "value": "Pasireurih"
  },
  {
    "id": "3201312004",
    "district_id": "320131",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3201312005",
    "district_id": "320131",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3201312006",
    "district_id": "320131",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3201312007",
    "district_id": "320131",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3201312008",
    "district_id": "320131",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3201322001",
    "district_id": "320132",
    "label": "Klapanunggal",
    "value": "Klapanunggal"
  },
  {
    "id": "3201322002",
    "district_id": "320132",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3201322003",
    "district_id": "320132",
    "label": "Nambo",
    "value": "Nambo"
  },
  {
    "id": "3201322004",
    "district_id": "320132",
    "label": "Lulut",
    "value": "Lulut"
  },
  {
    "id": "3201322005",
    "district_id": "320132",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3201322006",
    "district_id": "320132",
    "label": "Kembang Kuning",
    "value": "Kembang Kuning"
  },
  {
    "id": "3201322007",
    "district_id": "320132",
    "label": "Bantar Jati",
    "value": "Bantar Jati"
  },
  {
    "id": "3201322008",
    "district_id": "320132",
    "label": "Leuwikaret",
    "value": "Leuwikaret"
  },
  {
    "id": "3201322009",
    "district_id": "320132",
    "label": "Ligarmukti",
    "value": "Ligarmukti"
  },
  {
    "id": "3201332001",
    "district_id": "320133",
    "label": "Putat Nutug",
    "value": "Putat Nutug"
  },
  {
    "id": "3201332002",
    "district_id": "320133",
    "label": "Ciseeng",
    "value": "Ciseeng"
  },
  {
    "id": "3201332003",
    "district_id": "320133",
    "label": "Parigi Mekar",
    "value": "Parigi Mekar"
  },
  {
    "id": "3201332004",
    "district_id": "320133",
    "label": "Cibentang",
    "value": "Cibentang"
  },
  {
    "id": "3201332005",
    "district_id": "320133",
    "label": "Cibeuteung Udik",
    "value": "Cibeuteung Udik"
  },
  {
    "id": "3201332006",
    "district_id": "320133",
    "label": "Karihkil",
    "value": "Karihkil"
  },
  {
    "id": "3201332007",
    "district_id": "320133",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3201332008",
    "district_id": "320133",
    "label": "Cihoe",
    "value": "Cihoe"
  },
  {
    "id": "3201332009",
    "district_id": "320133",
    "label": "Cibeuteung Muara",
    "value": "Cibeuteung Muara"
  },
  {
    "id": "3201332010",
    "district_id": "320133",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3201342001",
    "district_id": "320134",
    "label": "Bantarjaya",
    "value": "Bantarjaya"
  },
  {
    "id": "3201342002",
    "district_id": "320134",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3201342003",
    "district_id": "320134",
    "label": "Pasirgaok",
    "value": "Pasirgaok"
  },
  {
    "id": "3201342004",
    "district_id": "320134",
    "label": "Rancabungur",
    "value": "Rancabungur"
  },
  {
    "id": "3201342005",
    "district_id": "320134",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3201342006",
    "district_id": "320134",
    "label": "Candali",
    "value": "Candali"
  },
  {
    "id": "3201342007",
    "district_id": "320134",
    "label": "Cimulang",
    "value": "Cimulang"
  },
  {
    "id": "3201352001",
    "district_id": "320135",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3201352002",
    "district_id": "320135",
    "label": "Kiarasari",
    "value": "Kiarasari"
  },
  {
    "id": "3201352003",
    "district_id": "320135",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3201352004",
    "district_id": "320135",
    "label": "Sipayung",
    "value": "Sipayung"
  },
  {
    "id": "3201352005",
    "district_id": "320135",
    "label": "Cileuksa",
    "value": "Cileuksa"
  },
  {
    "id": "3201352006",
    "district_id": "320135",
    "label": "Kiarapandak",
    "value": "Kiarapandak"
  },
  {
    "id": "3201352007",
    "district_id": "320135",
    "label": "Harkatjaya",
    "value": "Harkatjaya"
  },
  {
    "id": "3201352008",
    "district_id": "320135",
    "label": "Sukamulih",
    "value": "Sukamulih"
  },
  {
    "id": "3201352009",
    "district_id": "320135",
    "label": "Pasir Madang",
    "value": "Pasir Madang"
  },
  {
    "id": "3201352010",
    "district_id": "320135",
    "label": "Urug",
    "value": "Urug"
  },
  {
    "id": "3201352011",
    "district_id": "320135",
    "label": "Jayaraharja",
    "value": "Jayaraharja"
  },
  {
    "id": "3201362001",
    "district_id": "320136",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3201362002",
    "district_id": "320136",
    "label": "Selawangi",
    "value": "Selawangi"
  },
  {
    "id": "3201362003",
    "district_id": "320136",
    "label": "Tanjungrasa",
    "value": "Tanjungrasa"
  },
  {
    "id": "3201362004",
    "district_id": "320136",
    "label": "Antajaya",
    "value": "Antajaya"
  },
  {
    "id": "3201362005",
    "district_id": "320136",
    "label": "Pasir Tanjung",
    "value": "Pasir Tanjung"
  },
  {
    "id": "3201362006",
    "district_id": "320136",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3201362007",
    "district_id": "320136",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3201362008",
    "district_id": "320136",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3201362009",
    "district_id": "320136",
    "label": "Buanajaya",
    "value": "Buanajaya"
  },
  {
    "id": "3201362010",
    "district_id": "320136",
    "label": "Sirnarasa",
    "value": "Sirnarasa"
  },
  {
    "id": "3201372001",
    "district_id": "320137",
    "label": "Tajurhalang",
    "value": "Tajurhalang"
  },
  {
    "id": "3201372002",
    "district_id": "320137",
    "label": "Citayam",
    "value": "Citayam"
  },
  {
    "id": "3201372003",
    "district_id": "320137",
    "label": "Sasak Panjang",
    "value": "Sasak Panjang"
  },
  {
    "id": "3201372004",
    "district_id": "320137",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3201372005",
    "district_id": "320137",
    "label": "Sukmajaya",
    "value": "Sukmajaya"
  },
  {
    "id": "3201372006",
    "district_id": "320137",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3201372007",
    "district_id": "320137",
    "label": "Kalisuren",
    "value": "Kalisuren"
  },
  {
    "id": "3201382001",
    "district_id": "320138",
    "label": "Cigombong",
    "value": "Cigombong"
  },
  {
    "id": "3201382002",
    "district_id": "320138",
    "label": "Watesjaya",
    "value": "Watesjaya"
  },
  {
    "id": "3201382003",
    "district_id": "320138",
    "label": "Ciburuy",
    "value": "Ciburuy"
  },
  {
    "id": "3201382004",
    "district_id": "320138",
    "label": "Srogol",
    "value": "Srogol"
  },
  {
    "id": "3201382005",
    "district_id": "320138",
    "label": "Cisalada",
    "value": "Cisalada"
  },
  {
    "id": "3201382006",
    "district_id": "320138",
    "label": "Tugujaya",
    "value": "Tugujaya"
  },
  {
    "id": "3201382007",
    "district_id": "320138",
    "label": "Pasirjaya",
    "value": "Pasirjaya"
  },
  {
    "id": "3201382008",
    "district_id": "320138",
    "label": "Ciburayut",
    "value": "Ciburayut"
  },
  {
    "id": "3201382009",
    "district_id": "320138",
    "label": "Ciadeg",
    "value": "Ciadeg"
  },
  {
    "id": "3201392001",
    "district_id": "320139",
    "label": "Leuwisadeng",
    "value": "Leuwisadeng"
  },
  {
    "id": "3201392002",
    "district_id": "320139",
    "label": "Babakan Sadeng",
    "value": "Babakan Sadeng"
  },
  {
    "id": "3201392003",
    "district_id": "320139",
    "label": "Sadeng Kolot",
    "value": "Sadeng Kolot"
  },
  {
    "id": "3201392004",
    "district_id": "320139",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3201392005",
    "district_id": "320139",
    "label": "Kalong I",
    "value": "Kalong I"
  },
  {
    "id": "3201392006",
    "district_id": "320139",
    "label": "Kalong II",
    "value": "Kalong II"
  },
  {
    "id": "3201392007",
    "district_id": "320139",
    "label": "Sadeng",
    "value": "Sadeng"
  },
  {
    "id": "3201392008",
    "district_id": "320139",
    "label": "Sibanteng",
    "value": "Sibanteng"
  },
  {
    "id": "3201402001",
    "district_id": "320140",
    "label": "Tapos I",
    "value": "Tapos I"
  },
  {
    "id": "3201402002",
    "district_id": "320140",
    "label": "Tapos II",
    "value": "Tapos II"
  },
  {
    "id": "3201402003",
    "district_id": "320140",
    "label": "Cibitung Tengah",
    "value": "Cibitung Tengah"
  },
  {
    "id": "3201402004",
    "district_id": "320140",
    "label": "Situdaun",
    "value": "Situdaun"
  },
  {
    "id": "3201402005",
    "district_id": "320140",
    "label": "Cinangneng",
    "value": "Cinangneng"
  },
  {
    "id": "3201402006",
    "district_id": "320140",
    "label": "Gunung Malang",
    "value": "Gunung Malang"
  },
  {
    "id": "3201402007",
    "district_id": "320140",
    "label": "Gunung Mulya",
    "value": "Gunung Mulya"
  },
  {
    "id": "3202011001",
    "district_id": "320201",
    "label": "Palabuhanratu",
    "value": "Palabuhanratu"
  },
  {
    "id": "3202012002",
    "district_id": "320201",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3202012003",
    "district_id": "320201",
    "label": "Citepus",
    "value": "Citepus"
  },
  {
    "id": "3202012004",
    "district_id": "320201",
    "label": "Buniwangi",
    "value": "Buniwangi"
  },
  {
    "id": "3202012005",
    "district_id": "320201",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3202012006",
    "district_id": "320201",
    "label": "Pasirsuren",
    "value": "Pasirsuren"
  },
  {
    "id": "3202012007",
    "district_id": "320201",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3202012008",
    "district_id": "320201",
    "label": "Citarik",
    "value": "Citarik"
  },
  {
    "id": "3202012009",
    "district_id": "320201",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3202012010",
    "district_id": "320201",
    "label": "Jayanti",
    "value": "Jayanti"
  },
  {
    "id": "3202022001",
    "district_id": "320202",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3202022002",
    "district_id": "320202",
    "label": "Loji",
    "value": "Loji"
  },
  {
    "id": "3202022003",
    "district_id": "320202",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3202022004",
    "district_id": "320202",
    "label": "Cihaur",
    "value": "Cihaur"
  },
  {
    "id": "3202022005",
    "district_id": "320202",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3202022006",
    "district_id": "320202",
    "label": "Mekarasih",
    "value": "Mekarasih"
  },
  {
    "id": "3202022007",
    "district_id": "320202",
    "label": "Sangrawayang",
    "value": "Sangrawayang"
  },
  {
    "id": "3202032001",
    "district_id": "320203",
    "label": "Cikakak",
    "value": "Cikakak"
  },
  {
    "id": "3202032002",
    "district_id": "320203",
    "label": "Cimaja",
    "value": "Cimaja"
  },
  {
    "id": "3202032003",
    "district_id": "320203",
    "label": "Ridogalih",
    "value": "Ridogalih"
  },
  {
    "id": "3202032004",
    "district_id": "320203",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3202032005",
    "district_id": "320203",
    "label": "Cileungsing",
    "value": "Cileungsing"
  },
  {
    "id": "3202032006",
    "district_id": "320203",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3202032007",
    "district_id": "320203",
    "label": "Sirnarasa",
    "value": "Sirnarasa"
  },
  {
    "id": "3202032008",
    "district_id": "320203",
    "label": "Gandasoli",
    "value": "Gandasoli"
  },
  {
    "id": "3202032009",
    "district_id": "320203",
    "label": "Cirendang",
    "value": "Cirendang"
  },
  {
    "id": "3202042001",
    "district_id": "320204",
    "label": "Bantargadung",
    "value": "Bantargadung"
  },
  {
    "id": "3202042002",
    "district_id": "320204",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "3202042003",
    "district_id": "320204",
    "label": "Bojonggaling",
    "value": "Bojonggaling"
  },
  {
    "id": "3202042004",
    "district_id": "320204",
    "label": "Limusnunggal",
    "value": "Limusnunggal"
  },
  {
    "id": "3202042005",
    "district_id": "320204",
    "label": "Bantargebang",
    "value": "Bantargebang"
  },
  {
    "id": "3202042006",
    "district_id": "320204",
    "label": "Buanajaya",
    "value": "Buanajaya"
  },
  {
    "id": "3202042007",
    "district_id": "320204",
    "label": "Boyongsari",
    "value": "Boyongsari"
  },
  {
    "id": "3202052001",
    "district_id": "320205",
    "label": "Cisolok",
    "value": "Cisolok"
  },
  {
    "id": "3202052002",
    "district_id": "320205",
    "label": "Pasir Baru",
    "value": "Pasir Baru"
  },
  {
    "id": "3202052003",
    "district_id": "320205",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3202052004",
    "district_id": "320205",
    "label": "Cikelat",
    "value": "Cikelat"
  },
  {
    "id": "3202052005",
    "district_id": "320205",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3202052006",
    "district_id": "320205",
    "label": "Gunungkaramat",
    "value": "Gunungkaramat"
  },
  {
    "id": "3202052007",
    "district_id": "320205",
    "label": "Gunungtanjung",
    "value": "Gunungtanjung"
  },
  {
    "id": "3202052008",
    "district_id": "320205",
    "label": "Karangpapak",
    "value": "Karangpapak"
  },
  {
    "id": "3202052009",
    "district_id": "320205",
    "label": "Sirnaresmi",
    "value": "Sirnaresmi"
  },
  {
    "id": "3202052010",
    "district_id": "320205",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3202052011",
    "district_id": "320205",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3202052012",
    "district_id": "320205",
    "label": "Wangunsari",
    "value": "Wangunsari"
  },
  {
    "id": "3202052013",
    "district_id": "320205",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3202062001",
    "district_id": "320206",
    "label": "Cikidang",
    "value": "Cikidang"
  },
  {
    "id": "3202062002",
    "district_id": "320206",
    "label": "Cikiray",
    "value": "Cikiray"
  },
  {
    "id": "3202062003",
    "district_id": "320206",
    "label": "Gunungmalang",
    "value": "Gunungmalang"
  },
  {
    "id": "3202062004",
    "district_id": "320206",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3202062005",
    "district_id": "320206",
    "label": "Cicareuh",
    "value": "Cicareuh"
  },
  {
    "id": "3202062006",
    "district_id": "320206",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "3202062007",
    "district_id": "320206",
    "label": "Bumi Sari",
    "value": "Bumi Sari"
  },
  {
    "id": "3202062008",
    "district_id": "320206",
    "label": "Sampora",
    "value": "Sampora"
  },
  {
    "id": "3202062009",
    "district_id": "320206",
    "label": "Nangkakoneng",
    "value": "Nangkakoneng"
  },
  {
    "id": "3202062010",
    "district_id": "320206",
    "label": "Mekarnangka",
    "value": "Mekarnangka"
  },
  {
    "id": "3202062011",
    "district_id": "320206",
    "label": "Cijambe",
    "value": "Cijambe"
  },
  {
    "id": "3202062012",
    "district_id": "320206",
    "label": "Cikarae Thoyyibah",
    "value": "Cikarae Thoyyibah"
  },
  {
    "id": "3202072001",
    "district_id": "320207",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3202072002",
    "district_id": "320207",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3202072003",
    "district_id": "320207",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3202072004",
    "district_id": "320207",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3202072005",
    "district_id": "320207",
    "label": "Langkapjaya",
    "value": "Langkapjaya"
  },
  {
    "id": "3202082001",
    "district_id": "320208",
    "label": "Jampangtengah",
    "value": "Jampangtengah"
  },
  {
    "id": "3202082002",
    "district_id": "320208",
    "label": "Padabeunghar",
    "value": "Padabeunghar"
  },
  {
    "id": "3202082003",
    "district_id": "320208",
    "label": "Bantarpanjang",
    "value": "Bantarpanjang"
  },
  {
    "id": "3202082004",
    "district_id": "320208",
    "label": "Bojongjengkol",
    "value": "Bojongjengkol"
  },
  {
    "id": "3202082005",
    "district_id": "320208",
    "label": "Nangerang",
    "value": "Nangerang"
  },
  {
    "id": "3202082006",
    "district_id": "320208",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3202082007",
    "district_id": "320208",
    "label": "Sindangresmi",
    "value": "Sindangresmi"
  },
  {
    "id": "3202082008",
    "district_id": "320208",
    "label": "Panumbangan",
    "value": "Panumbangan"
  },
  {
    "id": "3202082009",
    "district_id": "320208",
    "label": "Cijulang",
    "value": "Cijulang"
  },
  {
    "id": "3202082010",
    "district_id": "320208",
    "label": "Bantaragung",
    "value": "Bantaragung"
  },
  {
    "id": "3202082011",
    "district_id": "320208",
    "label": "Bojongtipar",
    "value": "Bojongtipar"
  },
  {
    "id": "3202092001",
    "district_id": "320209",
    "label": "Warungkiara",
    "value": "Warungkiara"
  },
  {
    "id": "3202092002",
    "district_id": "320209",
    "label": "Bojongkerta",
    "value": "Bojongkerta"
  },
  {
    "id": "3202092003",
    "district_id": "320209",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3202092004",
    "district_id": "320209",
    "label": "Bantarkalong",
    "value": "Bantarkalong"
  },
  {
    "id": "3202092005",
    "district_id": "320209",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3202092006",
    "district_id": "320209",
    "label": "Ubrug",
    "value": "Ubrug"
  },
  {
    "id": "3202092007",
    "district_id": "320209",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3202092008",
    "district_id": "320209",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3202092009",
    "district_id": "320209",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3202092010",
    "district_id": "320209",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3202092011",
    "district_id": "320209",
    "label": "Damarraja",
    "value": "Damarraja"
  },
  {
    "id": "3202092012",
    "district_id": "320209",
    "label": "Tarisi",
    "value": "Tarisi"
  },
  {
    "id": "3202102001",
    "district_id": "320210",
    "label": "Cikembar",
    "value": "Cikembar"
  },
  {
    "id": "3202102002",
    "district_id": "320210",
    "label": "Parakanlima",
    "value": "Parakanlima"
  },
  {
    "id": "3202102003",
    "district_id": "320210",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3202102004",
    "district_id": "320210",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3202102005",
    "district_id": "320210",
    "label": "Bojongkembar",
    "value": "Bojongkembar"
  },
  {
    "id": "3202102006",
    "district_id": "320210",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3202102007",
    "district_id": "320210",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "3202102008",
    "district_id": "320210",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3202102009",
    "district_id": "320210",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3202102010",
    "district_id": "320210",
    "label": "Bojongraharja",
    "value": "Bojongraharja"
  },
  {
    "id": "3202111001",
    "district_id": "320211",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3202112002",
    "district_id": "320211",
    "label": "Sekarwangi",
    "value": "Sekarwangi"
  },
  {
    "id": "3202112003",
    "district_id": "320211",
    "label": "Pamuruyan",
    "value": "Pamuruyan"
  },
  {
    "id": "3202112004",
    "district_id": "320211",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3202112005",
    "district_id": "320211",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3202112006",
    "district_id": "320211",
    "label": "Warnajati",
    "value": "Warnajati"
  },
  {
    "id": "3202112007",
    "district_id": "320211",
    "label": "Tenjojaya",
    "value": "Tenjojaya"
  },
  {
    "id": "3202112008",
    "district_id": "320211",
    "label": "Ciheulang Tonggoh",
    "value": "Ciheulang Tonggoh"
  },
  {
    "id": "3202112009",
    "district_id": "320211",
    "label": "Sukasirna",
    "value": "Sukasirna"
  },
  {
    "id": "3202112010",
    "district_id": "320211",
    "label": "Batununggal",
    "value": "Batununggal"
  },
  {
    "id": "3202122001",
    "district_id": "320212",
    "label": "Kalaparea",
    "value": "Kalaparea"
  },
  {
    "id": "3202122002",
    "district_id": "320212",
    "label": "Pawenang",
    "value": "Pawenang"
  },
  {
    "id": "3202122003",
    "district_id": "320212",
    "label": "Nagrak Utara",
    "value": "Nagrak Utara"
  },
  {
    "id": "3202122004",
    "district_id": "320212",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3202122009",
    "district_id": "320212",
    "label": "Balekambang",
    "value": "Balekambang"
  },
  {
    "id": "3202122010",
    "district_id": "320212",
    "label": "Babakan Panjang",
    "value": "Babakan Panjang"
  },
  {
    "id": "3202122011",
    "district_id": "320212",
    "label": "Darmareja",
    "value": "Darmareja"
  },
  {
    "id": "3202122012",
    "district_id": "320212",
    "label": "Cihanyawar",
    "value": "Cihanyawar"
  },
  {
    "id": "3202122013",
    "district_id": "320212",
    "label": "Nagrak Selatan",
    "value": "Nagrak Selatan"
  },
  {
    "id": "3202122014",
    "district_id": "320212",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3202132001",
    "district_id": "320213",
    "label": "Parungkuda",
    "value": "Parungkuda"
  },
  {
    "id": "3202132002",
    "district_id": "320213",
    "label": "Palasari Hilir",
    "value": "Palasari Hilir"
  },
  {
    "id": "3202132003",
    "district_id": "320213",
    "label": "Kompa",
    "value": "Kompa"
  },
  {
    "id": "3202132004",
    "district_id": "320213",
    "label": "Pondokkaso Landeuh",
    "value": "Pondokkaso Landeuh"
  },
  {
    "id": "3202132005",
    "district_id": "320213",
    "label": "Sundawenang",
    "value": "Sundawenang"
  },
  {
    "id": "3202132006",
    "district_id": "320213",
    "label": "Bojongkokosan",
    "value": "Bojongkokosan"
  },
  {
    "id": "3202132007",
    "district_id": "320213",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3202132008",
    "district_id": "320213",
    "label": "Babakanjaya",
    "value": "Babakanjaya"
  },
  {
    "id": "3202142001",
    "district_id": "320214",
    "label": "Bojonggenteng",
    "value": "Bojonggenteng"
  },
  {
    "id": "3202142002",
    "district_id": "320214",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3202142003",
    "district_id": "320214",
    "label": "Berekah",
    "value": "Berekah"
  },
  {
    "id": "3202142004",
    "district_id": "320214",
    "label": "Cipanengah",
    "value": "Cipanengah"
  },
  {
    "id": "3202142005",
    "district_id": "320214",
    "label": "Bojonggaling",
    "value": "Bojonggaling"
  },
  {
    "id": "3202152001",
    "district_id": "320215",
    "label": "Parakansalak",
    "value": "Parakansalak"
  },
  {
    "id": "3202152002",
    "district_id": "320215",
    "label": "Bojonglongok",
    "value": "Bojonglongok"
  },
  {
    "id": "3202152003",
    "district_id": "320215",
    "label": "Sukakersa",
    "value": "Sukakersa"
  },
  {
    "id": "3202152004",
    "district_id": "320215",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3202152005",
    "district_id": "320215",
    "label": "Bojongasih",
    "value": "Bojongasih"
  },
  {
    "id": "3202152006",
    "district_id": "320215",
    "label": "Lebaksari",
    "value": "Lebaksari"
  },
  {
    "id": "3202161001",
    "district_id": "320216",
    "label": "Cicurug",
    "value": "Cicurug"
  },
  {
    "id": "3202162002",
    "district_id": "320216",
    "label": "Nyangkowek",
    "value": "Nyangkowek"
  },
  {
    "id": "3202162003",
    "district_id": "320216",
    "label": "Tenjolaya",
    "value": "Tenjolaya"
  },
  {
    "id": "3202162004",
    "district_id": "320216",
    "label": "Benda",
    "value": "Benda"
  },
  {
    "id": "3202162005",
    "district_id": "320216",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3202162006",
    "district_id": "320216",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3202162007",
    "district_id": "320216",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3202162008",
    "district_id": "320216",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3202162009",
    "district_id": "320216",
    "label": "Tenjoayu",
    "value": "Tenjoayu"
  },
  {
    "id": "3202162010",
    "district_id": "320216",
    "label": "Kutajaya",
    "value": "Kutajaya"
  },
  {
    "id": "3202162011",
    "district_id": "320216",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3202162012",
    "district_id": "320216",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3202162013",
    "district_id": "320216",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3202172001",
    "district_id": "320217",
    "label": "Pondokaso Tonggoh",
    "value": "Pondokaso Tonggoh"
  },
  {
    "id": "3202172002",
    "district_id": "320217",
    "label": "Babakanpari",
    "value": "Babakanpari"
  },
  {
    "id": "3202172003",
    "district_id": "320217",
    "label": "Pondokaso Tengah",
    "value": "Pondokaso Tengah"
  },
  {
    "id": "3202172004",
    "district_id": "320217",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3202172005",
    "district_id": "320217",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3202172006",
    "district_id": "320217",
    "label": "Jayabakti",
    "value": "Jayabakti"
  },
  {
    "id": "3202172007",
    "district_id": "320217",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3202172008",
    "district_id": "320217",
    "label": "Pasirdoton",
    "value": "Pasirdoton"
  },
  {
    "id": "3202182001",
    "district_id": "320218",
    "label": "Kalapanunggal",
    "value": "Kalapanunggal"
  },
  {
    "id": "3202182002",
    "district_id": "320218",
    "label": "Palasari Girang",
    "value": "Palasari Girang"
  },
  {
    "id": "3202182003",
    "district_id": "320218",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3202182004",
    "district_id": "320218",
    "label": "Makasari",
    "value": "Makasari"
  },
  {
    "id": "3202182005",
    "district_id": "320218",
    "label": "Kadununggal",
    "value": "Kadununggal"
  },
  {
    "id": "3202182006",
    "district_id": "320218",
    "label": "Walang Sari",
    "value": "Walang Sari"
  },
  {
    "id": "3202182007",
    "district_id": "320218",
    "label": "Gunungendut",
    "value": "Gunungendut"
  },
  {
    "id": "3202192001",
    "district_id": "320219",
    "label": "Kabandungan",
    "value": "Kabandungan"
  },
  {
    "id": "3202192002",
    "district_id": "320219",
    "label": "Cipeuteuy",
    "value": "Cipeuteuy"
  },
  {
    "id": "3202192003",
    "district_id": "320219",
    "label": "Cihamerang",
    "value": "Cihamerang"
  },
  {
    "id": "3202192004",
    "district_id": "320219",
    "label": "Tugubandung",
    "value": "Tugubandung"
  },
  {
    "id": "3202192005",
    "district_id": "320219",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3202192006",
    "district_id": "320219",
    "label": "Cianaga",
    "value": "Cianaga"
  },
  {
    "id": "3202202001",
    "district_id": "320220",
    "label": "Waluran",
    "value": "Waluran"
  },
  {
    "id": "3202202002",
    "district_id": "320220",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3202202003",
    "district_id": "320220",
    "label": "Caringinnunggal",
    "value": "Caringinnunggal"
  },
  {
    "id": "3202202004",
    "district_id": "320220",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3202202005",
    "district_id": "320220",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "3202202006",
    "district_id": "320220",
    "label": "Waluran Mandiri",
    "value": "Waluran Mandiri"
  },
  {
    "id": "3202211001",
    "district_id": "320221",
    "label": "Jampangkulon",
    "value": "Jampangkulon"
  },
  {
    "id": "3202212002",
    "district_id": "320221",
    "label": "Bojonggenteng",
    "value": "Bojonggenteng"
  },
  {
    "id": "3202212003",
    "district_id": "320221",
    "label": "Cikarang",
    "value": "Cikarang"
  },
  {
    "id": "3202212005",
    "district_id": "320221",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3202212007",
    "district_id": "320221",
    "label": "Nagraksari",
    "value": "Nagraksari"
  },
  {
    "id": "3202212008",
    "district_id": "320221",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3202212009",
    "district_id": "320221",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3202212011",
    "district_id": "320221",
    "label": "Ciparay",
    "value": "Ciparay"
  },
  {
    "id": "3202212013",
    "district_id": "320221",
    "label": "Padajaya",
    "value": "Padajaya"
  },
  {
    "id": "3202212014",
    "district_id": "320221",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3202212016",
    "district_id": "320221",
    "label": "Cikaranggeusan",
    "value": "Cikaranggeusan"
  },
  {
    "id": "3202222001",
    "district_id": "320222",
    "label": "Ciemas",
    "value": "Ciemas"
  },
  {
    "id": "3202222002",
    "district_id": "320222",
    "label": "Cibenda",
    "value": "Cibenda"
  },
  {
    "id": "3202222003",
    "district_id": "320222",
    "label": "Ciwaru",
    "value": "Ciwaru"
  },
  {
    "id": "3202222004",
    "district_id": "320222",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3202222005",
    "district_id": "320222",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3202222006",
    "district_id": "320222",
    "label": "Tamanjaya",
    "value": "Tamanjaya"
  },
  {
    "id": "3202222007",
    "district_id": "320222",
    "label": "Mandrajaya",
    "value": "Mandrajaya"
  },
  {
    "id": "3202222008",
    "district_id": "320222",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3202222009",
    "district_id": "320222",
    "label": "Mekarsakti",
    "value": "Mekarsakti"
  },
  {
    "id": "3202232001",
    "district_id": "320223",
    "label": "Kalibunder",
    "value": "Kalibunder"
  },
  {
    "id": "3202232002",
    "district_id": "320223",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3202232003",
    "district_id": "320223",
    "label": "Cimahpar",
    "value": "Cimahpar"
  },
  {
    "id": "3202232004",
    "district_id": "320223",
    "label": "Sekarsari",
    "value": "Sekarsari"
  },
  {
    "id": "3202232005",
    "district_id": "320223",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3202232006",
    "district_id": "320223",
    "label": "Balekambang",
    "value": "Balekambang"
  },
  {
    "id": "3202232007",
    "district_id": "320223",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3202241013",
    "district_id": "320224",
    "label": "Surade",
    "value": "Surade"
  },
  {
    "id": "3202242001",
    "district_id": "320224",
    "label": "Jagamukti",
    "value": "Jagamukti"
  },
  {
    "id": "3202242002",
    "district_id": "320224",
    "label": "Citanglar",
    "value": "Citanglar"
  },
  {
    "id": "3202242003",
    "district_id": "320224",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3202242005",
    "district_id": "320224",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3202242006",
    "district_id": "320224",
    "label": "Kadaleman",
    "value": "Kadaleman"
  },
  {
    "id": "3202242007",
    "district_id": "320224",
    "label": "Gunungsungging",
    "value": "Gunungsungging"
  },
  {
    "id": "3202242008",
    "district_id": "320224",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3202242009",
    "district_id": "320224",
    "label": "Pasiripis",
    "value": "Pasiripis"
  },
  {
    "id": "3202242010",
    "district_id": "320224",
    "label": "Buniwangi",
    "value": "Buniwangi"
  },
  {
    "id": "3202242011",
    "district_id": "320224",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3202242012",
    "district_id": "320224",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3202252001",
    "district_id": "320225",
    "label": "Talagamurni",
    "value": "Talagamurni"
  },
  {
    "id": "3202252002",
    "district_id": "320225",
    "label": "Banyumurni",
    "value": "Banyumurni"
  },
  {
    "id": "3202252003",
    "district_id": "320225",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "3202252004",
    "district_id": "320225",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3202252005",
    "district_id": "320225",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3202252006",
    "district_id": "320225",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "3202262001",
    "district_id": "320226",
    "label": "Ciracap",
    "value": "Ciracap"
  },
  {
    "id": "3202262002",
    "district_id": "320226",
    "label": "Cikangkung",
    "value": "Cikangkung"
  },
  {
    "id": "3202262003",
    "district_id": "320226",
    "label": "Gunungbatu",
    "value": "Gunungbatu"
  },
  {
    "id": "3202262004",
    "district_id": "320226",
    "label": "Purwasedar",
    "value": "Purwasedar"
  },
  {
    "id": "3202262005",
    "district_id": "320226",
    "label": "Pasirpanjang",
    "value": "Pasirpanjang"
  },
  {
    "id": "3202262006",
    "district_id": "320226",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3202262007",
    "district_id": "320226",
    "label": "Ujunggenteng",
    "value": "Ujunggenteng"
  },
  {
    "id": "3202262008",
    "district_id": "320226",
    "label": "Pangumbahan",
    "value": "Pangumbahan"
  },
  {
    "id": "3202272001",
    "district_id": "320227",
    "label": "Cikujang",
    "value": "Cikujang"
  },
  {
    "id": "3202272002",
    "district_id": "320227",
    "label": "Gunungguruh",
    "value": "Gunungguruh"
  },
  {
    "id": "3202272003",
    "district_id": "320227",
    "label": "Cibentang",
    "value": "Cibentang"
  },
  {
    "id": "3202272004",
    "district_id": "320227",
    "label": "Sirnaresmi",
    "value": "Sirnaresmi"
  },
  {
    "id": "3202272005",
    "district_id": "320227",
    "label": "Kebonmanggu",
    "value": "Kebonmanggu"
  },
  {
    "id": "3202272006",
    "district_id": "320227",
    "label": "Cibolang",
    "value": "Cibolang"
  },
  {
    "id": "3202272007",
    "district_id": "320227",
    "label": "Mangkalaya",
    "value": "Mangkalaya"
  },
  {
    "id": "3202282001",
    "district_id": "320228",
    "label": "Cicantayan",
    "value": "Cicantayan"
  },
  {
    "id": "3202282002",
    "district_id": "320228",
    "label": "Lembursawah",
    "value": "Lembursawah"
  },
  {
    "id": "3202282003",
    "district_id": "320228",
    "label": "Cijalingan",
    "value": "Cijalingan"
  },
  {
    "id": "3202282004",
    "district_id": "320228",
    "label": "Cisande",
    "value": "Cisande"
  },
  {
    "id": "3202282005",
    "district_id": "320228",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "3202282006",
    "district_id": "320228",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3202282007",
    "district_id": "320228",
    "label": "Sikadamai",
    "value": "Sikadamai"
  },
  {
    "id": "3202282008",
    "district_id": "320228",
    "label": "Cimanggis",
    "value": "Cimanggis"
  },
  {
    "id": "3202292001",
    "district_id": "320229",
    "label": "Gunungjaya",
    "value": "Gunungjaya"
  },
  {
    "id": "3202292002",
    "district_id": "320229",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3202292003",
    "district_id": "320229",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3202292004",
    "district_id": "320229",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3202292005",
    "district_id": "320229",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3202292006",
    "district_id": "320229",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3202292007",
    "district_id": "320229",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3202292008",
    "district_id": "320229",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "3202292009",
    "district_id": "320229",
    "label": "Selajambe",
    "value": "Selajambe"
  },
  {
    "id": "3202292010",
    "district_id": "320229",
    "label": "Padaasih",
    "value": "Padaasih"
  },
  {
    "id": "3202292011",
    "district_id": "320229",
    "label": "Cibolang Kaler",
    "value": "Cibolang Kaler"
  },
  {
    "id": "3202292012",
    "district_id": "320229",
    "label": "Kutasirna",
    "value": "Kutasirna"
  },
  {
    "id": "3202292013",
    "district_id": "320229",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3202302001",
    "district_id": "320230",
    "label": "Kadudampit",
    "value": "Kadudampit"
  },
  {
    "id": "3202302002",
    "district_id": "320230",
    "label": "Citamiang",
    "value": "Citamiang"
  },
  {
    "id": "3202302003",
    "district_id": "320230",
    "label": "Muaradua",
    "value": "Muaradua"
  },
  {
    "id": "3202302004",
    "district_id": "320230",
    "label": "Gedepangrango",
    "value": "Gedepangrango"
  },
  {
    "id": "3202302005",
    "district_id": "320230",
    "label": "Sukamanis",
    "value": "Sukamanis"
  },
  {
    "id": "3202302006",
    "district_id": "320230",
    "label": "Undrusbinangun",
    "value": "Undrusbinangun"
  },
  {
    "id": "3202302007",
    "district_id": "320230",
    "label": "Cipetir",
    "value": "Cipetir"
  },
  {
    "id": "3202302008",
    "district_id": "320230",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3202302009",
    "district_id": "320230",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3202312001",
    "district_id": "320231",
    "label": "Caringin Wetan",
    "value": "Caringin Wetan"
  },
  {
    "id": "3202312002",
    "district_id": "320231",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3202312003",
    "district_id": "320231",
    "label": "Caringin Kulon",
    "value": "Caringin Kulon"
  },
  {
    "id": "3202312004",
    "district_id": "320231",
    "label": "Seuseupan",
    "value": "Seuseupan"
  },
  {
    "id": "3202312005",
    "district_id": "320231",
    "label": "Cijengkol",
    "value": "Cijengkol"
  },
  {
    "id": "3202312006",
    "district_id": "320231",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3202312007",
    "district_id": "320231",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3202312008",
    "district_id": "320231",
    "label": "Cikembang",
    "value": "Cikembang"
  },
  {
    "id": "3202312009",
    "district_id": "320231",
    "label": "Pasir Datar Indah",
    "value": "Pasir Datar Indah"
  },
  {
    "id": "3202322001",
    "district_id": "320232",
    "label": "Parungseah",
    "value": "Parungseah"
  },
  {
    "id": "3202322002",
    "district_id": "320232",
    "label": "Sudajayagirang",
    "value": "Sudajayagirang"
  },
  {
    "id": "3202322003",
    "district_id": "320232",
    "label": "Karawang",
    "value": "Karawang"
  },
  {
    "id": "3202322004",
    "district_id": "320232",
    "label": "Warnasari",
    "value": "Warnasari"
  },
  {
    "id": "3202322005",
    "district_id": "320232",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3202322006",
    "district_id": "320232",
    "label": "Perbawati",
    "value": "Perbawati"
  },
  {
    "id": "3202332001",
    "district_id": "320233",
    "label": "Pasirhalang",
    "value": "Pasirhalang"
  },
  {
    "id": "3202332002",
    "district_id": "320233",
    "label": "Selawi",
    "value": "Selawi"
  },
  {
    "id": "3202332003",
    "district_id": "320233",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3202332004",
    "district_id": "320233",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3202332005",
    "district_id": "320233",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3202332006",
    "district_id": "320233",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3202332007",
    "district_id": "320233",
    "label": "Sukamekar",
    "value": "Sukamekar"
  },
  {
    "id": "3202332008",
    "district_id": "320233",
    "label": "Selawangi",
    "value": "Selawangi"
  },
  {
    "id": "3202332009",
    "district_id": "320233",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3202342001",
    "district_id": "320234",
    "label": "Kebonpedes",
    "value": "Kebonpedes"
  },
  {
    "id": "3202342002",
    "district_id": "320234",
    "label": "Cikaret",
    "value": "Cikaret"
  },
  {
    "id": "3202342003",
    "district_id": "320234",
    "label": "Bojongsawah",
    "value": "Bojongsawah"
  },
  {
    "id": "3202342004",
    "district_id": "320234",
    "label": "Sasagaran",
    "value": "Sasagaran"
  },
  {
    "id": "3202342005",
    "district_id": "320234",
    "label": "Jambenenggang",
    "value": "Jambenenggang"
  },
  {
    "id": "3202352001",
    "district_id": "320235",
    "label": "Cireunghas",
    "value": "Cireunghas"
  },
  {
    "id": "3202352002",
    "district_id": "320235",
    "label": "Cipurut",
    "value": "Cipurut"
  },
  {
    "id": "3202352003",
    "district_id": "320235",
    "label": "Bencoy",
    "value": "Bencoy"
  },
  {
    "id": "3202352004",
    "district_id": "320235",
    "label": "Cikurutug",
    "value": "Cikurutug"
  },
  {
    "id": "3202352005",
    "district_id": "320235",
    "label": "Tegalpanjang",
    "value": "Tegalpanjang"
  },
  {
    "id": "3202362001",
    "district_id": "320236",
    "label": "Sukalarang",
    "value": "Sukalarang"
  },
  {
    "id": "3202362002",
    "district_id": "320236",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3202362003",
    "district_id": "320236",
    "label": "Cimangkok",
    "value": "Cimangkok"
  },
  {
    "id": "3202362004",
    "district_id": "320236",
    "label": "Titisan",
    "value": "Titisan"
  },
  {
    "id": "3202362005",
    "district_id": "320236",
    "label": "Semplak",
    "value": "Semplak"
  },
  {
    "id": "3202362006",
    "district_id": "320236",
    "label": "Prianganjaya",
    "value": "Prianganjaya"
  },
  {
    "id": "3202372001",
    "district_id": "320237",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3202372002",
    "district_id": "320237",
    "label": "Ciwalat",
    "value": "Ciwalat"
  },
  {
    "id": "3202372003",
    "district_id": "320237",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3202372004",
    "district_id": "320237",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3202372005",
    "district_id": "320237",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3202372006",
    "district_id": "320237",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3202372007",
    "district_id": "320237",
    "label": "Lembur Sawah",
    "value": "Lembur Sawah"
  },
  {
    "id": "3202382001",
    "district_id": "320238",
    "label": "Purabaya",
    "value": "Purabaya"
  },
  {
    "id": "3202382002",
    "district_id": "320238",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3202382003",
    "district_id": "320238",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3202382004",
    "district_id": "320238",
    "label": "Cimerang",
    "value": "Cimerang"
  },
  {
    "id": "3202382005",
    "district_id": "320238",
    "label": "Citamiang",
    "value": "Citamiang"
  },
  {
    "id": "3202382006",
    "district_id": "320238",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3202382007",
    "district_id": "320238",
    "label": "Cicukang",
    "value": "Cicukang"
  },
  {
    "id": "3202392001",
    "district_id": "320239",
    "label": "Nyalindung",
    "value": "Nyalindung"
  },
  {
    "id": "3202392002",
    "district_id": "320239",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3202392003",
    "district_id": "320239",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3202392004",
    "district_id": "320239",
    "label": "Bojongkalong",
    "value": "Bojongkalong"
  },
  {
    "id": "3202392005",
    "district_id": "320239",
    "label": "Cijangkar",
    "value": "Cijangkar"
  },
  {
    "id": "3202392006",
    "district_id": "320239",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3202392007",
    "district_id": "320239",
    "label": "Wangunreja",
    "value": "Wangunreja"
  },
  {
    "id": "3202392008",
    "district_id": "320239",
    "label": "Cisitu",
    "value": "Cisitu"
  },
  {
    "id": "3202392009",
    "district_id": "320239",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3202392010",
    "district_id": "320239",
    "label": "Kertaangsana",
    "value": "Kertaangsana"
  },
  {
    "id": "3202402001",
    "district_id": "320240",
    "label": "Gegerbitung",
    "value": "Gegerbitung"
  },
  {
    "id": "3202402002",
    "district_id": "320240",
    "label": "Cijurey",
    "value": "Cijurey"
  },
  {
    "id": "3202402003",
    "district_id": "320240",
    "label": "Ciengang",
    "value": "Ciengang"
  },
  {
    "id": "3202402004",
    "district_id": "320240",
    "label": "Karangjaya",
    "value": "Karangjaya"
  },
  {
    "id": "3202402005",
    "district_id": "320240",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3202402006",
    "district_id": "320240",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3202402007",
    "district_id": "320240",
    "label": "Buniwangi",
    "value": "Buniwangi"
  },
  {
    "id": "3202412001",
    "district_id": "320241",
    "label": "Sagaranten",
    "value": "Sagaranten"
  },
  {
    "id": "3202412002",
    "district_id": "320241",
    "label": "Curugluhur",
    "value": "Curugluhur"
  },
  {
    "id": "3202412003",
    "district_id": "320241",
    "label": "Cibaregbeg",
    "value": "Cibaregbeg"
  },
  {
    "id": "3202412004",
    "district_id": "320241",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3202412005",
    "district_id": "320241",
    "label": "Datarnangka",
    "value": "Datarnangka"
  },
  {
    "id": "3202412006",
    "district_id": "320241",
    "label": "Puncakmanggis",
    "value": "Puncakmanggis"
  },
  {
    "id": "3202412007",
    "district_id": "320241",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3202412008",
    "district_id": "320241",
    "label": "Gunungbentang",
    "value": "Gunungbentang"
  },
  {
    "id": "3202412009",
    "district_id": "320241",
    "label": "Sinarbentang",
    "value": "Sinarbentang"
  },
  {
    "id": "3202412010",
    "district_id": "320241",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "3202412011",
    "district_id": "320241",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3202412012",
    "district_id": "320241",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3202422001",
    "district_id": "320242",
    "label": "Curugkembar",
    "value": "Curugkembar"
  },
  {
    "id": "3202422002",
    "district_id": "320242",
    "label": "Cimenteng",
    "value": "Cimenteng"
  },
  {
    "id": "3202422003",
    "district_id": "320242",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3202422004",
    "district_id": "320242",
    "label": "Sindangraja",
    "value": "Sindangraja"
  },
  {
    "id": "3202422005",
    "district_id": "320242",
    "label": "Mekartanjung",
    "value": "Mekartanjung"
  },
  {
    "id": "3202422006",
    "district_id": "320242",
    "label": "Nagrakjaya",
    "value": "Nagrakjaya"
  },
  {
    "id": "3202422007",
    "district_id": "320242",
    "label": "Bojongtugu",
    "value": "Bojongtugu"
  },
  {
    "id": "3202432001",
    "district_id": "320243",
    "label": "Cidolog",
    "value": "Cidolog"
  },
  {
    "id": "3202432002",
    "district_id": "320243",
    "label": "Cipamingkis",
    "value": "Cipamingkis"
  },
  {
    "id": "3202432003",
    "district_id": "320243",
    "label": "Cikarang",
    "value": "Cikarang"
  },
  {
    "id": "3202432004",
    "district_id": "320243",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3202432005",
    "district_id": "320243",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3202442001",
    "district_id": "320244",
    "label": "Padasenang",
    "value": "Padasenang"
  },
  {
    "id": "3202442002",
    "district_id": "320244",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3202442003",
    "district_id": "320244",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3202442004",
    "district_id": "320244",
    "label": "Hegarmulya",
    "value": "Hegarmulya"
  },
  {
    "id": "3202442005",
    "district_id": "320244",
    "label": "Tenjolaut",
    "value": "Tenjolaut"
  },
  {
    "id": "3202442006",
    "district_id": "320244",
    "label": "Mekartani",
    "value": "Mekartani"
  },
  {
    "id": "3202452001",
    "district_id": "320245",
    "label": "Tegalbuleud",
    "value": "Tegalbuleud"
  },
  {
    "id": "3202452002",
    "district_id": "320245",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3202452003",
    "district_id": "320245",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3202452004",
    "district_id": "320245",
    "label": "Calingcing",
    "value": "Calingcing"
  },
  {
    "id": "3202452005",
    "district_id": "320245",
    "label": "Nangela",
    "value": "Nangela"
  },
  {
    "id": "3202452006",
    "district_id": "320245",
    "label": "Rambay",
    "value": "Rambay"
  },
  {
    "id": "3202452007",
    "district_id": "320245",
    "label": "Buniasih",
    "value": "Buniasih"
  },
  {
    "id": "3202452008",
    "district_id": "320245",
    "label": "Sirnamekar",
    "value": "Sirnamekar"
  },
  {
    "id": "3202462001",
    "district_id": "320246",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3202462002",
    "district_id": "320246",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3202462003",
    "district_id": "320246",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3202462004",
    "district_id": "320246",
    "label": "Karangmekar",
    "value": "Karangmekar"
  },
  {
    "id": "3202462005",
    "district_id": "320246",
    "label": "Boregahindah",
    "value": "Boregahindah"
  },
  {
    "id": "3202462006",
    "district_id": "320246",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3202472001",
    "district_id": "320247",
    "label": "Ciambar",
    "value": "Ciambar"
  },
  {
    "id": "3202472002",
    "district_id": "320247",
    "label": "Ginanjar",
    "value": "Ginanjar"
  },
  {
    "id": "3202472003",
    "district_id": "320247",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3202472004",
    "district_id": "320247",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3202472005",
    "district_id": "320247",
    "label": "Ambarjaya",
    "value": "Ambarjaya"
  },
  {
    "id": "3202472006",
    "district_id": "320247",
    "label": "Cibunarjaya",
    "value": "Cibunarjaya"
  },
  {
    "id": "3203011006",
    "district_id": "320301",
    "label": "Pamoyanan",
    "value": "Pamoyanan"
  },
  {
    "id": "3203011007",
    "district_id": "320301",
    "label": "Sawahgede",
    "value": "Sawahgede"
  },
  {
    "id": "3203011008",
    "district_id": "320301",
    "label": "Bojongherang",
    "value": "Bojongherang"
  },
  {
    "id": "3203011009",
    "district_id": "320301",
    "label": "Sayang",
    "value": "Sayang"
  },
  {
    "id": "3203011010",
    "district_id": "320301",
    "label": "Solokpandan",
    "value": "Solokpandan"
  },
  {
    "id": "3203011011",
    "district_id": "320301",
    "label": "Muka",
    "value": "Muka"
  },
  {
    "id": "3203012001",
    "district_id": "320301",
    "label": "Babakan Karet",
    "value": "Babakan Karet"
  },
  {
    "id": "3203012002",
    "district_id": "320301",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3203012003",
    "district_id": "320301",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3203012004",
    "district_id": "320301",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3203012005",
    "district_id": "320301",
    "label": "Limbangansari",
    "value": "Limbangansari"
  },
  {
    "id": "3203022002",
    "district_id": "320302",
    "label": "Jambudipa",
    "value": "Jambudipa"
  },
  {
    "id": "3203022006",
    "district_id": "320302",
    "label": "Cisarandi",
    "value": "Cisarandi"
  },
  {
    "id": "3203022007",
    "district_id": "320302",
    "label": "Cikaroya",
    "value": "Cikaroya"
  },
  {
    "id": "3203022008",
    "district_id": "320302",
    "label": "Bunikasih",
    "value": "Bunikasih"
  },
  {
    "id": "3203022009",
    "district_id": "320302",
    "label": "Ciwalen",
    "value": "Ciwalen"
  },
  {
    "id": "3203022010",
    "district_id": "320302",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3203022011",
    "district_id": "320302",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3203022014",
    "district_id": "320302",
    "label": "Bunisari",
    "value": "Bunisari"
  },
  {
    "id": "3203022016",
    "district_id": "320302",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203022017",
    "district_id": "320302",
    "label": "Cieundeur",
    "value": "Cieundeur"
  },
  {
    "id": "3203022018",
    "district_id": "320302",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3203032001",
    "district_id": "320303",
    "label": "Cipetir",
    "value": "Cipetir"
  },
  {
    "id": "3203032002",
    "district_id": "320303",
    "label": "Cihaur",
    "value": "Cihaur"
  },
  {
    "id": "3203032003",
    "district_id": "320303",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3203032004",
    "district_id": "320303",
    "label": "Cibaregbeg",
    "value": "Cibaregbeg"
  },
  {
    "id": "3203032005",
    "district_id": "320303",
    "label": "Sukaraharja",
    "value": "Sukaraharja"
  },
  {
    "id": "3203032006",
    "district_id": "320303",
    "label": "Peuteuycondong",
    "value": "Peuteuycondong"
  },
  {
    "id": "3203032007",
    "district_id": "320303",
    "label": "Mayak",
    "value": "Mayak"
  },
  {
    "id": "3203032008",
    "district_id": "320303",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3203032009",
    "district_id": "320303",
    "label": "Cibokor",
    "value": "Cibokor"
  },
  {
    "id": "3203032010",
    "district_id": "320303",
    "label": "Selagedang",
    "value": "Selagedang"
  },
  {
    "id": "3203032011",
    "district_id": "320303",
    "label": "Karangnunggal",
    "value": "Karangnunggal"
  },
  {
    "id": "3203032012",
    "district_id": "320303",
    "label": "Kanoman",
    "value": "Kanoman"
  },
  {
    "id": "3203032013",
    "district_id": "320303",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3203032014",
    "district_id": "320303",
    "label": "Girimulya",
    "value": "Girimulya"
  },
  {
    "id": "3203032015",
    "district_id": "320303",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3203032016",
    "district_id": "320303",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3203032017",
    "district_id": "320303",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3203032018",
    "district_id": "320303",
    "label": "Salamnunggal",
    "value": "Salamnunggal"
  },
  {
    "id": "3203042001",
    "district_id": "320304",
    "label": "Rancagoong",
    "value": "Rancagoong"
  },
  {
    "id": "3203042002",
    "district_id": "320304",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3203042003",
    "district_id": "320304",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3203042004",
    "district_id": "320304",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3203042005",
    "district_id": "320304",
    "label": "Cibinonghilir",
    "value": "Cibinonghilir"
  },
  {
    "id": "3203042006",
    "district_id": "320304",
    "label": "Rahong",
    "value": "Rahong"
  },
  {
    "id": "3203042007",
    "district_id": "320304",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3203042008",
    "district_id": "320304",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3203042009",
    "district_id": "320304",
    "label": "Ciharashas",
    "value": "Ciharashas"
  },
  {
    "id": "3203042010",
    "district_id": "320304",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3203052001",
    "district_id": "320305",
    "label": "Ciranjang",
    "value": "Ciranjang"
  },
  {
    "id": "3203052002",
    "district_id": "320305",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3203052004",
    "district_id": "320305",
    "label": "Cibiuk",
    "value": "Cibiuk"
  },
  {
    "id": "3203052005",
    "district_id": "320305",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3203052006",
    "district_id": "320305",
    "label": "Karangwangi",
    "value": "Karangwangi"
  },
  {
    "id": "3203052007",
    "district_id": "320305",
    "label": "Nanggalamekar",
    "value": "Nanggalamekar"
  },
  {
    "id": "3203052008",
    "district_id": "320305",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3203052009",
    "district_id": "320305",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3203052012",
    "district_id": "320305",
    "label": "Mekargalih",
    "value": "Mekargalih"
  },
  {
    "id": "3203062001",
    "district_id": "320306",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3203062002",
    "district_id": "320306",
    "label": "Bojongpicung",
    "value": "Bojongpicung"
  },
  {
    "id": "3203062004",
    "district_id": "320306",
    "label": "Cibarengkok",
    "value": "Cibarengkok"
  },
  {
    "id": "3203062006",
    "district_id": "320306",
    "label": "Kemang",
    "value": "Kemang"
  },
  {
    "id": "3203062007",
    "district_id": "320306",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3203062008",
    "district_id": "320306",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3203062009",
    "district_id": "320306",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3203062010",
    "district_id": "320306",
    "label": "Sukarama",
    "value": "Sukarama"
  },
  {
    "id": "3203062012",
    "district_id": "320306",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3203062013",
    "district_id": "320306",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3203062014",
    "district_id": "320306",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3203072001",
    "district_id": "320307",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3203072002",
    "district_id": "320307",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3203072003",
    "district_id": "320307",
    "label": "Sindanglaka",
    "value": "Sindanglaka"
  },
  {
    "id": "3203072004",
    "district_id": "320307",
    "label": "Maleber",
    "value": "Maleber"
  },
  {
    "id": "3203072005",
    "district_id": "320307",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3203072006",
    "district_id": "320307",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3203072007",
    "district_id": "320307",
    "label": "Sukasarana",
    "value": "Sukasarana"
  },
  {
    "id": "3203072008",
    "district_id": "320307",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3203072009",
    "district_id": "320307",
    "label": "Babakancaringin",
    "value": "Babakancaringin"
  },
  {
    "id": "3203072010",
    "district_id": "320307",
    "label": "Sabandar",
    "value": "Sabandar"
  },
  {
    "id": "3203072011",
    "district_id": "320307",
    "label": "Sindangasih",
    "value": "Sindangasih"
  },
  {
    "id": "3203072012",
    "district_id": "320307",
    "label": "Sukataris",
    "value": "Sukataris"
  },
  {
    "id": "3203072013",
    "district_id": "320307",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3203072014",
    "district_id": "320307",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3203072015",
    "district_id": "320307",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3203072016",
    "district_id": "320307",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203082001",
    "district_id": "320308",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3203082002",
    "district_id": "320308",
    "label": "Bobojong",
    "value": "Bobojong"
  },
  {
    "id": "3203082003",
    "district_id": "320308",
    "label": "Jamali",
    "value": "Jamali"
  },
  {
    "id": "3203082004",
    "district_id": "320308",
    "label": "Cikidangbayabang",
    "value": "Cikidangbayabang"
  },
  {
    "id": "3203082005",
    "district_id": "320308",
    "label": "Mande",
    "value": "Mande"
  },
  {
    "id": "3203082006",
    "district_id": "320308",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3203082007",
    "district_id": "320308",
    "label": "Ciandam",
    "value": "Ciandam"
  },
  {
    "id": "3203082008",
    "district_id": "320308",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3203082009",
    "district_id": "320308",
    "label": "Leuwikoja",
    "value": "Leuwikoja"
  },
  {
    "id": "3203082010",
    "district_id": "320308",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3203082011",
    "district_id": "320308",
    "label": "Kutawaringin",
    "value": "Kutawaringin"
  },
  {
    "id": "3203082012",
    "district_id": "320308",
    "label": "Murnisari",
    "value": "Murnisari"
  },
  {
    "id": "3203092001",
    "district_id": "320309",
    "label": "Selajambe",
    "value": "Selajambe"
  },
  {
    "id": "3203092002",
    "district_id": "320309",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203092003",
    "district_id": "320309",
    "label": "Panyusuhan",
    "value": "Panyusuhan"
  },
  {
    "id": "3203092004",
    "district_id": "320309",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3203092005",
    "district_id": "320309",
    "label": "Sidangraja",
    "value": "Sidangraja"
  },
  {
    "id": "3203092006",
    "district_id": "320309",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3203092007",
    "district_id": "320309",
    "label": "Sukasirna",
    "value": "Sukasirna"
  },
  {
    "id": "3203092008",
    "district_id": "320309",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3203092009",
    "district_id": "320309",
    "label": "Babakansari",
    "value": "Babakansari"
  },
  {
    "id": "3203092010",
    "district_id": "320309",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3203102001",
    "district_id": "320310",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3203102003",
    "district_id": "320310",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3203102005",
    "district_id": "320310",
    "label": "Sukanagalih",
    "value": "Sukanagalih"
  },
  {
    "id": "3203102007",
    "district_id": "320310",
    "label": "Cipendawa",
    "value": "Cipendawa"
  },
  {
    "id": "3203102010",
    "district_id": "320310",
    "label": "Ciputri",
    "value": "Ciputri"
  },
  {
    "id": "3203102012",
    "district_id": "320310",
    "label": "Gadog",
    "value": "Gadog"
  },
  {
    "id": "3203102013",
    "district_id": "320310",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3203112001",
    "district_id": "320311",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3203112002",
    "district_id": "320311",
    "label": "Gasol",
    "value": "Gasol"
  },
  {
    "id": "3203112003",
    "district_id": "320311",
    "label": "Cijedil",
    "value": "Cijedil"
  },
  {
    "id": "3203112004",
    "district_id": "320311",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3203112005",
    "district_id": "320311",
    "label": "Nyalindung",
    "value": "Nyalindung"
  },
  {
    "id": "3203112006",
    "district_id": "320311",
    "label": "Mangunkerta",
    "value": "Mangunkerta"
  },
  {
    "id": "3203112007",
    "district_id": "320311",
    "label": "Sarampad",
    "value": "Sarampad"
  },
  {
    "id": "3203112008",
    "district_id": "320311",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3203112009",
    "district_id": "320311",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3203112010",
    "district_id": "320311",
    "label": "Cibulakan",
    "value": "Cibulakan"
  },
  {
    "id": "3203112011",
    "district_id": "320311",
    "label": "Benjot",
    "value": "Benjot"
  },
  {
    "id": "3203112012",
    "district_id": "320311",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203112013",
    "district_id": "320311",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3203112014",
    "district_id": "320311",
    "label": "Cirumput",
    "value": "Cirumput"
  },
  {
    "id": "3203112015",
    "district_id": "320311",
    "label": "Padaluyu",
    "value": "Padaluyu"
  },
  {
    "id": "3203112016",
    "district_id": "320311",
    "label": "Galudra",
    "value": "Galudra"
  },
  {
    "id": "3203122001",
    "district_id": "320312",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3203122002",
    "district_id": "320312",
    "label": "Gudang",
    "value": "Gudang"
  },
  {
    "id": "3203122003",
    "district_id": "320312",
    "label": "Cinangsi",
    "value": "Cinangsi"
  },
  {
    "id": "3203122004",
    "district_id": "320312",
    "label": "Cijagang",
    "value": "Cijagang"
  },
  {
    "id": "3203122005",
    "district_id": "320312",
    "label": "Majalaya",
    "value": "Majalaya"
  },
  {
    "id": "3203122006",
    "district_id": "320312",
    "label": "Kamurang",
    "value": "Kamurang"
  },
  {
    "id": "3203122007",
    "district_id": "320312",
    "label": "Warudoyong",
    "value": "Warudoyong"
  },
  {
    "id": "3203122008",
    "district_id": "320312",
    "label": "Ciramagirang",
    "value": "Ciramagirang"
  },
  {
    "id": "3203122009",
    "district_id": "320312",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3203122010",
    "district_id": "320312",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203122011",
    "district_id": "320312",
    "label": "Padajaya",
    "value": "Padajaya"
  },
  {
    "id": "3203122012",
    "district_id": "320312",
    "label": "Cigunungherang",
    "value": "Cigunungherang"
  },
  {
    "id": "3203122013",
    "district_id": "320312",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3203122014",
    "district_id": "320312",
    "label": "Mekargalih",
    "value": "Mekargalih"
  },
  {
    "id": "3203122015",
    "district_id": "320312",
    "label": "Lembahsari",
    "value": "Lembahsari"
  },
  {
    "id": "3203122016",
    "district_id": "320312",
    "label": "Mentengsari",
    "value": "Mentengsari"
  },
  {
    "id": "3203122017",
    "district_id": "320312",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3203122018",
    "district_id": "320312",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3203132001",
    "district_id": "320313",
    "label": "Cikanyere",
    "value": "Cikanyere"
  },
  {
    "id": "3203132002",
    "district_id": "320313",
    "label": "Cikancana",
    "value": "Cikancana"
  },
  {
    "id": "3203132003",
    "district_id": "320313",
    "label": "Ciwalen",
    "value": "Ciwalen"
  },
  {
    "id": "3203132004",
    "district_id": "320313",
    "label": "Kawungluwuk",
    "value": "Kawungluwuk"
  },
  {
    "id": "3203132005",
    "district_id": "320313",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3203132006",
    "district_id": "320313",
    "label": "Sukamahi",
    "value": "Sukamahi"
  },
  {
    "id": "3203132007",
    "district_id": "320313",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3203132008",
    "district_id": "320313",
    "label": "Cibanteng",
    "value": "Cibanteng"
  },
  {
    "id": "3203132009",
    "district_id": "320313",
    "label": "Kubang",
    "value": "Kubang"
  },
  {
    "id": "3203132010",
    "district_id": "320313",
    "label": "Pakuon",
    "value": "Pakuon"
  },
  {
    "id": "3203132011",
    "district_id": "320313",
    "label": "Rawabelut",
    "value": "Rawabelut"
  },
  {
    "id": "3203142001",
    "district_id": "320314",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3203142002",
    "district_id": "320314",
    "label": "Sukajembar",
    "value": "Sukajembar"
  },
  {
    "id": "3203142003",
    "district_id": "320314",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3203142004",
    "district_id": "320314",
    "label": "Ciguha",
    "value": "Ciguha"
  },
  {
    "id": "3203142005",
    "district_id": "320314",
    "label": "Sukamekar",
    "value": "Sukamekar"
  },
  {
    "id": "3203142006",
    "district_id": "320314",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3203142007",
    "district_id": "320314",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3203142008",
    "district_id": "320314",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3203142009",
    "district_id": "320314",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3203142010",
    "district_id": "320314",
    "label": "Jayagiri",
    "value": "Jayagiri"
  },
  {
    "id": "3203152001",
    "district_id": "320315",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3203152002",
    "district_id": "320315",
    "label": "Cimenteng",
    "value": "Cimenteng"
  },
  {
    "id": "3203152003",
    "district_id": "320315",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3203152004",
    "district_id": "320315",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3203152005",
    "district_id": "320315",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3203152006",
    "district_id": "320315",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3203152007",
    "district_id": "320315",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3203152008",
    "district_id": "320315",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3203152009",
    "district_id": "320315",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3203152010",
    "district_id": "320315",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3203152011",
    "district_id": "320315",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3203162001",
    "district_id": "320316",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3203162002",
    "district_id": "320316",
    "label": "Sindangresmi",
    "value": "Sindangresmi"
  },
  {
    "id": "3203162003",
    "district_id": "320316",
    "label": "Sindanghayu",
    "value": "Sindanghayu"
  },
  {
    "id": "3203162004",
    "district_id": "320316",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3203162005",
    "district_id": "320316",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3203162006",
    "district_id": "320316",
    "label": "Bungbangsari",
    "value": "Bungbangsari"
  },
  {
    "id": "3203162007",
    "district_id": "320316",
    "label": "Cisujen",
    "value": "Cisujen"
  },
  {
    "id": "3203162008",
    "district_id": "320316",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3203162009",
    "district_id": "320316",
    "label": "Waringinsari",
    "value": "Waringinsari"
  },
  {
    "id": "3203172003",
    "district_id": "320317",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3203172006",
    "district_id": "320317",
    "label": "Pasirdalem",
    "value": "Pasirdalem"
  },
  {
    "id": "3203172008",
    "district_id": "320317",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3203172009",
    "district_id": "320317",
    "label": "Bojongkasih",
    "value": "Bojongkasih"
  },
  {
    "id": "3203172010",
    "district_id": "320317",
    "label": "Wargaasih",
    "value": "Wargaasih"
  },
  {
    "id": "3203172011",
    "district_id": "320317",
    "label": "Sukaraharja",
    "value": "Sukaraharja"
  },
  {
    "id": "3203172014",
    "district_id": "320317",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3203172016",
    "district_id": "320317",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3203172017",
    "district_id": "320317",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3203172019",
    "district_id": "320317",
    "label": "Wargasari",
    "value": "Wargasari"
  },
  {
    "id": "3203172020",
    "district_id": "320317",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3203172021",
    "district_id": "320317",
    "label": "Kadupandak",
    "value": "Kadupandak"
  },
  {
    "id": "3203172022",
    "district_id": "320317",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "3203172023",
    "district_id": "320317",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3203182001",
    "district_id": "320318",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3203182002",
    "district_id": "320318",
    "label": "Pangadegan",
    "value": "Pangadegan"
  },
  {
    "id": "3203182004",
    "district_id": "320318",
    "label": "Gelaranyar",
    "value": "Gelaranyar"
  },
  {
    "id": "3203182005",
    "district_id": "320318",
    "label": "Buniwangi",
    "value": "Buniwangi"
  },
  {
    "id": "3203182007",
    "district_id": "320318",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "3203182008",
    "district_id": "320318",
    "label": "Pasirbaru",
    "value": "Pasirbaru"
  },
  {
    "id": "3203182009",
    "district_id": "320318",
    "label": "Bunijaya",
    "value": "Bunijaya"
  },
  {
    "id": "3203182010",
    "district_id": "320318",
    "label": "Situhiang",
    "value": "Situhiang"
  },
  {
    "id": "3203182011",
    "district_id": "320318",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3203182012",
    "district_id": "320318",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3203182013",
    "district_id": "320318",
    "label": "Selagedang",
    "value": "Selagedang"
  },
  {
    "id": "3203182014",
    "district_id": "320318",
    "label": "Padamaju",
    "value": "Padamaju"
  },
  {
    "id": "3203182015",
    "district_id": "320318",
    "label": "Karangharja",
    "value": "Karangharja"
  },
  {
    "id": "3203182018",
    "district_id": "320318",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3203192001",
    "district_id": "320319",
    "label": "Tanggeung",
    "value": "Tanggeung"
  },
  {
    "id": "3203192002",
    "district_id": "320319",
    "label": "Rawagede",
    "value": "Rawagede"
  },
  {
    "id": "3203192003",
    "district_id": "320319",
    "label": "Cilongsong",
    "value": "Cilongsong"
  },
  {
    "id": "3203192004",
    "district_id": "320319",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3203192005",
    "district_id": "320319",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3203192006",
    "district_id": "320319",
    "label": "Pagermaneuh",
    "value": "Pagermaneuh"
  },
  {
    "id": "3203192007",
    "district_id": "320319",
    "label": "Bojongpetir",
    "value": "Bojongpetir"
  },
  {
    "id": "3203192009",
    "district_id": "320319",
    "label": "Pasirjambu",
    "value": "Pasirjambu"
  },
  {
    "id": "3203192010",
    "district_id": "320319",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3203192013",
    "district_id": "320319",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3203192014",
    "district_id": "320319",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3203192015",
    "district_id": "320319",
    "label": "Padaluyu",
    "value": "Padaluyu"
  },
  {
    "id": "3203202001",
    "district_id": "320320",
    "label": "Pananggapan",
    "value": "Pananggapan"
  },
  {
    "id": "3203202002",
    "district_id": "320320",
    "label": "Cikangkareng",
    "value": "Cikangkareng"
  },
  {
    "id": "3203202003",
    "district_id": "320320",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3203202004",
    "district_id": "320320",
    "label": "Panyindangan",
    "value": "Panyindangan"
  },
  {
    "id": "3203202005",
    "district_id": "320320",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3203202006",
    "district_id": "320320",
    "label": "Cimaskara",
    "value": "Cimaskara"
  },
  {
    "id": "3203202007",
    "district_id": "320320",
    "label": "Pamoyanan",
    "value": "Pamoyanan"
  },
  {
    "id": "3203202008",
    "district_id": "320320",
    "label": "Batulawang",
    "value": "Batulawang"
  },
  {
    "id": "3203202009",
    "district_id": "320320",
    "label": "Sukamekar",
    "value": "Sukamekar"
  },
  {
    "id": "3203202010",
    "district_id": "320320",
    "label": "Hamerang",
    "value": "Hamerang"
  },
  {
    "id": "3203202011",
    "district_id": "320320",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3203202012",
    "district_id": "320320",
    "label": "Wargaluyu",
    "value": "Wargaluyu"
  },
  {
    "id": "3203202013",
    "district_id": "320320",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3203202014",
    "district_id": "320320",
    "label": "Ciburial",
    "value": "Ciburial"
  },
  {
    "id": "3203212001",
    "district_id": "320321",
    "label": "Saganten",
    "value": "Saganten"
  },
  {
    "id": "3203212002",
    "district_id": "320321",
    "label": "Muaracikadu",
    "value": "Muaracikadu"
  },
  {
    "id": "3203212003",
    "district_id": "320321",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3203212004",
    "district_id": "320321",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3203212005",
    "district_id": "320321",
    "label": "Jayagiri",
    "value": "Jayagiri"
  },
  {
    "id": "3203212006",
    "district_id": "320321",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3203212007",
    "district_id": "320321",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3203212008",
    "district_id": "320321",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3203212009",
    "district_id": "320321",
    "label": "Hegarsari",
    "value": "Hegarsari"
  },
  {
    "id": "3203212010",
    "district_id": "320321",
    "label": "Mekarlaksana",
    "value": "Mekarlaksana"
  },
  {
    "id": "3203212011",
    "district_id": "320321",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3203222004",
    "district_id": "320322",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3203222005",
    "district_id": "320322",
    "label": "Bojongkaso",
    "value": "Bojongkaso"
  },
  {
    "id": "3203222006",
    "district_id": "320322",
    "label": "Bunisari",
    "value": "Bunisari"
  },
  {
    "id": "3203222007",
    "district_id": "320322",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3203222010",
    "district_id": "320322",
    "label": "Sinarlaut",
    "value": "Sinarlaut"
  },
  {
    "id": "3203222014",
    "district_id": "320322",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3203222016",
    "district_id": "320322",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3203222017",
    "district_id": "320322",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3203222018",
    "district_id": "320322",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "3203222019",
    "district_id": "320322",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3203222022",
    "district_id": "320322",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3203232001",
    "district_id": "320323",
    "label": "Cidamar",
    "value": "Cidamar"
  },
  {
    "id": "3203232002",
    "district_id": "320323",
    "label": "Cibuluh",
    "value": "Cibuluh"
  },
  {
    "id": "3203232003",
    "district_id": "320323",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3203232004",
    "district_id": "320323",
    "label": "Cimaragang",
    "value": "Cimaragang"
  },
  {
    "id": "3203232005",
    "district_id": "320323",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3203232006",
    "district_id": "320323",
    "label": "Kertajadi",
    "value": "Kertajadi"
  },
  {
    "id": "3203232007",
    "district_id": "320323",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3203232008",
    "district_id": "320323",
    "label": "Gelarpawitan",
    "value": "Gelarpawitan"
  },
  {
    "id": "3203232009",
    "district_id": "320323",
    "label": "Karangwangi",
    "value": "Karangwangi"
  },
  {
    "id": "3203232010",
    "district_id": "320323",
    "label": "Karyabakti",
    "value": "Karyabakti"
  },
  {
    "id": "3203232011",
    "district_id": "320323",
    "label": "Jayapura",
    "value": "Jayapura"
  },
  {
    "id": "3203232012",
    "district_id": "320323",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3203232013",
    "district_id": "320323",
    "label": "Puncakbaru",
    "value": "Puncakbaru"
  },
  {
    "id": "3203232014",
    "district_id": "320323",
    "label": "Gelarwangi",
    "value": "Gelarwangi"
  },
  {
    "id": "3203242001",
    "district_id": "320324",
    "label": "Naringgul",
    "value": "Naringgul"
  },
  {
    "id": "3203242002",
    "district_id": "320324",
    "label": "Balegede",
    "value": "Balegede"
  },
  {
    "id": "3203242003",
    "district_id": "320324",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3203242004",
    "district_id": "320324",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3203242005",
    "district_id": "320324",
    "label": "Cinerang",
    "value": "Cinerang"
  },
  {
    "id": "3203242006",
    "district_id": "320324",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3203242007",
    "district_id": "320324",
    "label": "Sukabakti",
    "value": "Sukabakti"
  },
  {
    "id": "3203242008",
    "district_id": "320324",
    "label": "Malati",
    "value": "Malati"
  },
  {
    "id": "3203242009",
    "district_id": "320324",
    "label": "Wangunsari",
    "value": "Wangunsari"
  },
  {
    "id": "3203242010",
    "district_id": "320324",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203242011",
    "district_id": "320324",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3203252001",
    "district_id": "320325",
    "label": "Cibanggala",
    "value": "Cibanggala"
  },
  {
    "id": "3203252002",
    "district_id": "320325",
    "label": "Campakawarna",
    "value": "Campakawarna"
  },
  {
    "id": "3203252003",
    "district_id": "320325",
    "label": "Sukasirna",
    "value": "Sukasirna"
  },
  {
    "id": "3203252004",
    "district_id": "320325",
    "label": "Campakamulya",
    "value": "Campakamulya"
  },
  {
    "id": "3203252005",
    "district_id": "320325",
    "label": "Sukabungah",
    "value": "Sukabungah"
  },
  {
    "id": "3203262001",
    "district_id": "320326",
    "label": "Kalapanunggal",
    "value": "Kalapanunggal"
  },
  {
    "id": "3203262002",
    "district_id": "320326",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3203262003",
    "district_id": "320326",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3203262004",
    "district_id": "320326",
    "label": "Padaluyu",
    "value": "Padaluyu"
  },
  {
    "id": "3203262005",
    "district_id": "320326",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203262006",
    "district_id": "320326",
    "label": "Cisaranten",
    "value": "Cisaranten"
  },
  {
    "id": "3203262007",
    "district_id": "320326",
    "label": "Mekarlaksana",
    "value": "Mekarlaksana"
  },
  {
    "id": "3203262008",
    "district_id": "320326",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3203262009",
    "district_id": "320326",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3203262010",
    "district_id": "320326",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3203272001",
    "district_id": "320327",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3203272002",
    "district_id": "320327",
    "label": "Songgom",
    "value": "Songgom"
  },
  {
    "id": "3203272003",
    "district_id": "320327",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3203272004",
    "district_id": "320327",
    "label": "Cikancana",
    "value": "Cikancana"
  },
  {
    "id": "3203272005",
    "district_id": "320327",
    "label": "Kebonpeuteuy",
    "value": "Kebonpeuteuy"
  },
  {
    "id": "3203272006",
    "district_id": "320327",
    "label": "Gekbrong",
    "value": "Gekbrong"
  },
  {
    "id": "3203272007",
    "district_id": "320327",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3203272008",
    "district_id": "320327",
    "label": "Cintaasih",
    "value": "Cintaasih"
  },
  {
    "id": "3203282001",
    "district_id": "320328",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "3203282002",
    "district_id": "320328",
    "label": "Cimacan",
    "value": "Cimacan"
  },
  {
    "id": "3203282003",
    "district_id": "320328",
    "label": "Ciloto",
    "value": "Ciloto"
  },
  {
    "id": "3203282004",
    "district_id": "320328",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3203282005",
    "district_id": "320328",
    "label": "Batulawang",
    "value": "Batulawang"
  },
  {
    "id": "3203282006",
    "district_id": "320328",
    "label": "Palasari",
    "value": "Palasari"
  },
  {
    "id": "3203282007",
    "district_id": "320328",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3203292001",
    "district_id": "320329",
    "label": "Parakantugu",
    "value": "Parakantugu"
  },
  {
    "id": "3203292002",
    "district_id": "320329",
    "label": "Cijati",
    "value": "Cijati"
  },
  {
    "id": "3203292003",
    "district_id": "320329",
    "label": "Bojonglarang",
    "value": "Bojonglarang"
  },
  {
    "id": "3203292004",
    "district_id": "320329",
    "label": "Padaasih",
    "value": "Padaasih"
  },
  {
    "id": "3203292005",
    "district_id": "320329",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3203292006",
    "district_id": "320329",
    "label": "Sukamahi",
    "value": "Sukamahi"
  },
  {
    "id": "3203292007",
    "district_id": "320329",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3203292008",
    "district_id": "320329",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3203292009",
    "district_id": "320329",
    "label": "Sinarbakti",
    "value": "Sinarbakti"
  },
  {
    "id": "3203292010",
    "district_id": "320329",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3203302001",
    "district_id": "320330",
    "label": "Pusakasari",
    "value": "Pusakasari"
  },
  {
    "id": "3203302002",
    "district_id": "320330",
    "label": "Walahir",
    "value": "Walahir"
  },
  {
    "id": "3203302003",
    "district_id": "320330",
    "label": "Purabaya",
    "value": "Purabaya"
  },
  {
    "id": "3203302004",
    "district_id": "320330",
    "label": "Puncakwangi",
    "value": "Puncakwangi"
  },
  {
    "id": "3203302005",
    "district_id": "320330",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3203302006",
    "district_id": "320330",
    "label": "Nagasari",
    "value": "Nagasari"
  },
  {
    "id": "3203302007",
    "district_id": "320330",
    "label": "Sukasirna",
    "value": "Sukasirna"
  },
  {
    "id": "3203302008",
    "district_id": "320330",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3203302009",
    "district_id": "320330",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3203302010",
    "district_id": "320330",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3203302011",
    "district_id": "320330",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "3203302012",
    "district_id": "320330",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3203312001",
    "district_id": "320331",
    "label": "Haurwangi",
    "value": "Haurwangi"
  },
  {
    "id": "3203312002",
    "district_id": "320331",
    "label": "Cihea",
    "value": "Cihea"
  },
  {
    "id": "3203312003",
    "district_id": "320331",
    "label": "Ramasari",
    "value": "Ramasari"
  },
  {
    "id": "3203312004",
    "district_id": "320331",
    "label": "Mekarwangi/Sukatani",
    "value": "Mekarwangi/Sukatani"
  },
  {
    "id": "3203312005",
    "district_id": "320331",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3203312006",
    "district_id": "320331",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3203312007",
    "district_id": "320331",
    "label": "Cipeuyeum",
    "value": "Cipeuyeum"
  },
  {
    "id": "3203312008",
    "district_id": "320331",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3203322001",
    "district_id": "320332",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3203322002",
    "district_id": "320332",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "3203322003",
    "district_id": "320332",
    "label": "Pusakajaya",
    "value": "Pusakajaya"
  },
  {
    "id": "3203322004",
    "district_id": "320332",
    "label": "Kubang",
    "value": "Kubang"
  },
  {
    "id": "3203322005",
    "district_id": "320332",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3203322006",
    "district_id": "320332",
    "label": "Karangjaya",
    "value": "Karangjaya"
  },
  {
    "id": "3203322007",
    "district_id": "320332",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3203322008",
    "district_id": "320332",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3203322009",
    "district_id": "320332",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3204052001",
    "district_id": "320405",
    "label": "Cileunyi Kulon",
    "value": "Cileunyi Kulon"
  },
  {
    "id": "3204052002",
    "district_id": "320405",
    "label": "Cileunyi Wetan",
    "value": "Cileunyi Wetan"
  },
  {
    "id": "3204052003",
    "district_id": "320405",
    "label": "Cimekar",
    "value": "Cimekar"
  },
  {
    "id": "3204052004",
    "district_id": "320405",
    "label": "Cinunuk",
    "value": "Cinunuk"
  },
  {
    "id": "3204052005",
    "district_id": "320405",
    "label": "Cibiru Hilir",
    "value": "Cibiru Hilir"
  },
  {
    "id": "3204052006",
    "district_id": "320405",
    "label": "Cibiru Wetan",
    "value": "Cibiru Wetan"
  },
  {
    "id": "3204061001",
    "district_id": "320406",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3204061002",
    "district_id": "320406",
    "label": "Cibeunying",
    "value": "Cibeunying"
  },
  {
    "id": "3204062003",
    "district_id": "320406",
    "label": "Cimenyan",
    "value": "Cimenyan"
  },
  {
    "id": "3204062004",
    "district_id": "320406",
    "label": "Mandalamekar",
    "value": "Mandalamekar"
  },
  {
    "id": "3204062005",
    "district_id": "320406",
    "label": "Cikadut",
    "value": "Cikadut"
  },
  {
    "id": "3204062006",
    "district_id": "320406",
    "label": "Ciburial",
    "value": "Ciburial"
  },
  {
    "id": "3204062007",
    "district_id": "320406",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3204062008",
    "district_id": "320406",
    "label": "Mekarsaluyu",
    "value": "Mekarsaluyu"
  },
  {
    "id": "3204062009",
    "district_id": "320406",
    "label": "Mekarmanik",
    "value": "Mekarmanik"
  },
  {
    "id": "3204072001",
    "district_id": "320407",
    "label": "Jatiendah",
    "value": "Jatiendah"
  },
  {
    "id": "3204072002",
    "district_id": "320407",
    "label": "Cilengkrang",
    "value": "Cilengkrang"
  },
  {
    "id": "3204072003",
    "district_id": "320407",
    "label": "Cipanjalu",
    "value": "Cipanjalu"
  },
  {
    "id": "3204072004",
    "district_id": "320407",
    "label": "Melatiwangi",
    "value": "Melatiwangi"
  },
  {
    "id": "3204072005",
    "district_id": "320407",
    "label": "Ciporeat",
    "value": "Ciporeat"
  },
  {
    "id": "3204072006",
    "district_id": "320407",
    "label": "Girimekar",
    "value": "Girimekar"
  },
  {
    "id": "3204082001",
    "district_id": "320408",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3204082002",
    "district_id": "320408",
    "label": "Bojongsoang",
    "value": "Bojongsoang"
  },
  {
    "id": "3204082003",
    "district_id": "320408",
    "label": "Buahbatu",
    "value": "Buahbatu"
  },
  {
    "id": "3204082004",
    "district_id": "320408",
    "label": "Cipagalo",
    "value": "Cipagalo"
  },
  {
    "id": "3204082005",
    "district_id": "320408",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3204082006",
    "district_id": "320408",
    "label": "Tegalluar",
    "value": "Tegalluar"
  },
  {
    "id": "3204091004",
    "district_id": "320409",
    "label": "Sulaiman",
    "value": "Sulaiman"
  },
  {
    "id": "3204092001",
    "district_id": "320409",
    "label": "Margahayu Tengah",
    "value": "Margahayu Tengah"
  },
  {
    "id": "3204092002",
    "district_id": "320409",
    "label": "Magahayu Selatan",
    "value": "Magahayu Selatan"
  },
  {
    "id": "3204092003",
    "district_id": "320409",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3204092005",
    "district_id": "320409",
    "label": "Sayati",
    "value": "Sayati"
  },
  {
    "id": "3204102001",
    "district_id": "320410",
    "label": "Margaasih",
    "value": "Margaasih"
  },
  {
    "id": "3204102002",
    "district_id": "320410",
    "label": "Lagadar",
    "value": "Lagadar"
  },
  {
    "id": "3204102003",
    "district_id": "320410",
    "label": "Nanjung",
    "value": "Nanjung"
  },
  {
    "id": "3204102004",
    "district_id": "320410",
    "label": "Mekarrahayu",
    "value": "Mekarrahayu"
  },
  {
    "id": "3204102005",
    "district_id": "320410",
    "label": "Rahayu",
    "value": "Rahayu"
  },
  {
    "id": "3204102006",
    "district_id": "320410",
    "label": "Cigondewah Hilir",
    "value": "Cigondewah Hilir"
  },
  {
    "id": "3204112001",
    "district_id": "320411",
    "label": "Sangkanhurip",
    "value": "Sangkanhurip"
  },
  {
    "id": "3204112002",
    "district_id": "320411",
    "label": "Katapang",
    "value": "Katapang"
  },
  {
    "id": "3204112004",
    "district_id": "320411",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "3204112006",
    "district_id": "320411",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3204112007",
    "district_id": "320411",
    "label": "Cilampeni",
    "value": "Cilampeni"
  },
  {
    "id": "3204112008",
    "district_id": "320411",
    "label": "Pangauban",
    "value": "Pangauban"
  },
  {
    "id": "3204112009",
    "district_id": "320411",
    "label": "Banyusari",
    "value": "Banyusari"
  },
  {
    "id": "3204121001",
    "district_id": "320412",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3204122002",
    "district_id": "320412",
    "label": "Dayeuhkolot",
    "value": "Dayeuhkolot"
  },
  {
    "id": "3204122003",
    "district_id": "320412",
    "label": "Cangkuang Wetan",
    "value": "Cangkuang Wetan"
  },
  {
    "id": "3204122004",
    "district_id": "320412",
    "label": "Cangkuang Kulon",
    "value": "Cangkuang Kulon"
  },
  {
    "id": "3204122005",
    "district_id": "320412",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3204122006",
    "district_id": "320412",
    "label": "Citeureup",
    "value": "Citeureup"
  },
  {
    "id": "3204132001",
    "district_id": "320413",
    "label": "Kamasan",
    "value": "Kamasan"
  },
  {
    "id": "3204132002",
    "district_id": "320413",
    "label": "Banjaran Wetan",
    "value": "Banjaran Wetan"
  },
  {
    "id": "3204132003",
    "district_id": "320413",
    "label": "Banjaran Kulon",
    "value": "Banjaran Kulon"
  },
  {
    "id": "3204132005",
    "district_id": "320413",
    "label": "Ciapus",
    "value": "Ciapus"
  },
  {
    "id": "3204132006",
    "district_id": "320413",
    "label": "Sindangpanon",
    "value": "Sindangpanon"
  },
  {
    "id": "3204132007",
    "district_id": "320413",
    "label": "Kiangroke",
    "value": "Kiangroke"
  },
  {
    "id": "3204132008",
    "district_id": "320413",
    "label": "Tarajusari",
    "value": "Tarajusari"
  },
  {
    "id": "3204132012",
    "district_id": "320413",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3204132013",
    "district_id": "320413",
    "label": "Margahurip",
    "value": "Margahurip"
  },
  {
    "id": "3204132016",
    "district_id": "320413",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3204132018",
    "district_id": "320413",
    "label": "Pasirmulya",
    "value": "Pasirmulya"
  },
  {
    "id": "3204142001",
    "district_id": "320414",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3204142002",
    "district_id": "320414",
    "label": "Bojongmanggu",
    "value": "Bojongmanggu"
  },
  {
    "id": "3204142003",
    "district_id": "320414",
    "label": "Rancatungku",
    "value": "Rancatungku"
  },
  {
    "id": "3204142004",
    "district_id": "320414",
    "label": "Bojongkunci",
    "value": "Bojongkunci"
  },
  {
    "id": "3204142005",
    "district_id": "320414",
    "label": "Rancamulya",
    "value": "Rancamulya"
  },
  {
    "id": "3204142006",
    "district_id": "320414",
    "label": "Langonsari",
    "value": "Langonsari"
  },
  {
    "id": "3204152001",
    "district_id": "320415",
    "label": "Pangalengan",
    "value": "Pangalengan"
  },
  {
    "id": "3204152002",
    "district_id": "320415",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3204152003",
    "district_id": "320415",
    "label": "Warnasari",
    "value": "Warnasari"
  },
  {
    "id": "3204152004",
    "district_id": "320415",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3204152005",
    "district_id": "320415",
    "label": "Lamajang",
    "value": "Lamajang"
  },
  {
    "id": "3204152006",
    "district_id": "320415",
    "label": "Margamukti",
    "value": "Margamukti"
  },
  {
    "id": "3204152007",
    "district_id": "320415",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3204152008",
    "district_id": "320415",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3204152009",
    "district_id": "320415",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3204152010",
    "district_id": "320415",
    "label": "Tribaktimulya",
    "value": "Tribaktimulya"
  },
  {
    "id": "3204152011",
    "district_id": "320415",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3204152012",
    "district_id": "320415",
    "label": "Wanasuka",
    "value": "Wanasuka"
  },
  {
    "id": "3204152013",
    "district_id": "320415",
    "label": "Margamekar",
    "value": "Margamekar"
  },
  {
    "id": "3204162001",
    "district_id": "320416",
    "label": "Arjasari",
    "value": "Arjasari"
  },
  {
    "id": "3204162002",
    "district_id": "320416",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "3204162003",
    "district_id": "320416",
    "label": "Batukarut",
    "value": "Batukarut"
  },
  {
    "id": "3204162004",
    "district_id": "320416",
    "label": "Ancolmekar",
    "value": "Ancolmekar"
  },
  {
    "id": "3204162005",
    "district_id": "320416",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "3204162006",
    "district_id": "320416",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "3204162007",
    "district_id": "320416",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3204162008",
    "district_id": "320416",
    "label": "Pinggirsari",
    "value": "Pinggirsari"
  },
  {
    "id": "3204162009",
    "district_id": "320416",
    "label": "Patrolsari",
    "value": "Patrolsari"
  },
  {
    "id": "3204162010",
    "district_id": "320416",
    "label": "Rancakole",
    "value": "Rancakole"
  },
  {
    "id": "3204162011",
    "district_id": "320416",
    "label": "Wargaluyu",
    "value": "Wargaluyu"
  },
  {
    "id": "3204172001",
    "district_id": "320417",
    "label": "Cimaung",
    "value": "Cimaung"
  },
  {
    "id": "3204172002",
    "district_id": "320417",
    "label": "Jagabaya",
    "value": "Jagabaya"
  },
  {
    "id": "3204172003",
    "district_id": "320417",
    "label": "Pasirhuni",
    "value": "Pasirhuni"
  },
  {
    "id": "3204172004",
    "district_id": "320417",
    "label": "Campakamulya",
    "value": "Campakamulya"
  },
  {
    "id": "3204172005",
    "district_id": "320417",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3204172006",
    "district_id": "320417",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3204172007",
    "district_id": "320417",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3204172008",
    "district_id": "320417",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3204172009",
    "district_id": "320417",
    "label": "Malasari",
    "value": "Malasari"
  },
  {
    "id": "3204172010",
    "district_id": "320417",
    "label": "Warjabakti",
    "value": "Warjabakti"
  },
  {
    "id": "3204252001",
    "district_id": "320425",
    "label": "Cicalengka Kulon",
    "value": "Cicalengka Kulon"
  },
  {
    "id": "3204252002",
    "district_id": "320425",
    "label": "Cicalengka Wetan",
    "value": "Cicalengka Wetan"
  },
  {
    "id": "3204252003",
    "district_id": "320425",
    "label": "Babakan Peuteuy",
    "value": "Babakan Peuteuy"
  },
  {
    "id": "3204252004",
    "district_id": "320425",
    "label": "Cikuya",
    "value": "Cikuya"
  },
  {
    "id": "3204252005",
    "district_id": "320425",
    "label": "Dampit",
    "value": "Dampit"
  },
  {
    "id": "3204252006",
    "district_id": "320425",
    "label": "Margaasih",
    "value": "Margaasih"
  },
  {
    "id": "3204252007",
    "district_id": "320425",
    "label": "Narawita",
    "value": "Narawita"
  },
  {
    "id": "3204252008",
    "district_id": "320425",
    "label": "Panenjoan",
    "value": "Panenjoan"
  },
  {
    "id": "3204252009",
    "district_id": "320425",
    "label": "Tanjungwangi",
    "value": "Tanjungwangi"
  },
  {
    "id": "3204252010",
    "district_id": "320425",
    "label": "Tenjolaya",
    "value": "Tenjolaya"
  },
  {
    "id": "3204252011",
    "district_id": "320425",
    "label": "Waluya",
    "value": "Waluya"
  },
  {
    "id": "3204252012",
    "district_id": "320425",
    "label": "Nagrog",
    "value": "Nagrog"
  },
  {
    "id": "3204262001",
    "district_id": "320426",
    "label": "Nagreg",
    "value": "Nagreg"
  },
  {
    "id": "3204262002",
    "district_id": "320426",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3204262003",
    "district_id": "320426",
    "label": "Ciaro",
    "value": "Ciaro"
  },
  {
    "id": "3204262004",
    "district_id": "320426",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3204262005",
    "district_id": "320426",
    "label": "Citaman",
    "value": "Citaman"
  },
  {
    "id": "3204262006",
    "district_id": "320426",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "3204262007",
    "district_id": "320426",
    "label": "Nagreg Kendan",
    "value": "Nagreg Kendan"
  },
  {
    "id": "3204262008",
    "district_id": "320426",
    "label": "Ganjar Sabar",
    "value": "Ganjar Sabar"
  },
  {
    "id": "3204272001",
    "district_id": "320427",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3204272002",
    "district_id": "320427",
    "label": "Cikancung",
    "value": "Cikancung"
  },
  {
    "id": "3204272003",
    "district_id": "320427",
    "label": "Cikasungka",
    "value": "Cikasungka"
  },
  {
    "id": "3204272004",
    "district_id": "320427",
    "label": "Cihanyir",
    "value": "Cihanyir"
  },
  {
    "id": "3204272005",
    "district_id": "320427",
    "label": "Ciluluk",
    "value": "Ciluluk"
  },
  {
    "id": "3204272006",
    "district_id": "320427",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3204272007",
    "district_id": "320427",
    "label": "Mekarlaksana",
    "value": "Mekarlaksana"
  },
  {
    "id": "3204272008",
    "district_id": "320427",
    "label": "Tanjunglaya",
    "value": "Tanjunglaya"
  },
  {
    "id": "3204272009",
    "district_id": "320427",
    "label": "Srirahayu",
    "value": "Srirahayu"
  },
  {
    "id": "3204281014",
    "district_id": "320428",
    "label": "Rancaekek Kencana",
    "value": "Rancaekek Kencana"
  },
  {
    "id": "3204282001",
    "district_id": "320428",
    "label": "Rancaekek Wetan",
    "value": "Rancaekek Wetan"
  },
  {
    "id": "3204282002",
    "district_id": "320428",
    "label": "Rancaekek Kulon",
    "value": "Rancaekek Kulon"
  },
  {
    "id": "3204282003",
    "district_id": "320428",
    "label": "Bojongsalam",
    "value": "Bojongsalam"
  },
  {
    "id": "3204282004",
    "district_id": "320428",
    "label": "Bojongloa",
    "value": "Bojongloa"
  },
  {
    "id": "3204282005",
    "district_id": "320428",
    "label": "Jelegong",
    "value": "Jelegong"
  },
  {
    "id": "3204282006",
    "district_id": "320428",
    "label": "Linggar",
    "value": "Linggar"
  },
  {
    "id": "3204282007",
    "district_id": "320428",
    "label": "Cangkuang",
    "value": "Cangkuang"
  },
  {
    "id": "3204282008",
    "district_id": "320428",
    "label": "Haurpugur",
    "value": "Haurpugur"
  },
  {
    "id": "3204282009",
    "district_id": "320428",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3204282010",
    "district_id": "320428",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3204282011",
    "district_id": "320428",
    "label": "Tegal Sumedang",
    "value": "Tegal Sumedang"
  },
  {
    "id": "3204282012",
    "district_id": "320428",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3204282013",
    "district_id": "320428",
    "label": "Nanjung Mekar",
    "value": "Nanjung Mekar"
  },
  {
    "id": "3204292001",
    "district_id": "320429",
    "label": "Ciparay",
    "value": "Ciparay"
  },
  {
    "id": "3204292002",
    "district_id": "320429",
    "label": "Gunungleutik",
    "value": "Gunungleutik"
  },
  {
    "id": "3204292003",
    "district_id": "320429",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3204292004",
    "district_id": "320429",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3204292005",
    "district_id": "320429",
    "label": "Ciheulang",
    "value": "Ciheulang"
  },
  {
    "id": "3204292006",
    "district_id": "320429",
    "label": "Pakutandang",
    "value": "Pakutandang"
  },
  {
    "id": "3204292007",
    "district_id": "320429",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3204292008",
    "district_id": "320429",
    "label": "Mangunharja",
    "value": "Mangunharja"
  },
  {
    "id": "3204292009",
    "district_id": "320429",
    "label": "Sagaracipta",
    "value": "Sagaracipta"
  },
  {
    "id": "3204292010",
    "district_id": "320429",
    "label": "Sarimahi",
    "value": "Sarimahi"
  },
  {
    "id": "3204292011",
    "district_id": "320429",
    "label": "Serangmekar",
    "value": "Serangmekar"
  },
  {
    "id": "3204292012",
    "district_id": "320429",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3204292013",
    "district_id": "320429",
    "label": "Bumiwangi",
    "value": "Bumiwangi"
  },
  {
    "id": "3204292014",
    "district_id": "320429",
    "label": "Mekarlaksana",
    "value": "Mekarlaksana"
  },
  {
    "id": "3204302001",
    "district_id": "320430",
    "label": "Cipeujeuh",
    "value": "Cipeujeuh"
  },
  {
    "id": "3204302002",
    "district_id": "320430",
    "label": "Cikitu",
    "value": "Cikitu"
  },
  {
    "id": "3204302003",
    "district_id": "320430",
    "label": "Cananggela",
    "value": "Cananggela"
  },
  {
    "id": "3204302004",
    "district_id": "320430",
    "label": "Maruyung",
    "value": "Maruyung"
  },
  {
    "id": "3204302005",
    "district_id": "320430",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3204302006",
    "district_id": "320430",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3204302007",
    "district_id": "320430",
    "label": "Cikawao",
    "value": "Cikawao"
  },
  {
    "id": "3204302008",
    "district_id": "320430",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3204302009",
    "district_id": "320430",
    "label": "Pangauban",
    "value": "Pangauban"
  },
  {
    "id": "3204302010",
    "district_id": "320430",
    "label": "Mandalahaji",
    "value": "Mandalahaji"
  },
  {
    "id": "3204302011",
    "district_id": "320430",
    "label": "Girimulya",
    "value": "Girimulya"
  },
  {
    "id": "3204302012",
    "district_id": "320430",
    "label": "Tanjungwangi",
    "value": "Tanjungwangi"
  },
  {
    "id": "3204302013",
    "district_id": "320430",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3204312001",
    "district_id": "320431",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3204312002",
    "district_id": "320431",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3204312003",
    "district_id": "320431",
    "label": "Santosa",
    "value": "Santosa"
  },
  {
    "id": "3204312004",
    "district_id": "320431",
    "label": "Tarumajaya",
    "value": "Tarumajaya"
  },
  {
    "id": "3204312005",
    "district_id": "320431",
    "label": "Neglawangi",
    "value": "Neglawangi"
  },
  {
    "id": "3204312006",
    "district_id": "320431",
    "label": "Cihawuk",
    "value": "Cihawuk"
  },
  {
    "id": "3204312007",
    "district_id": "320431",
    "label": "Cikembang",
    "value": "Cikembang"
  },
  {
    "id": "3204312008",
    "district_id": "320431",
    "label": "Resmi Tingal",
    "value": "Resmi Tingal"
  },
  {
    "id": "3204321001",
    "district_id": "320432",
    "label": "Baleendah",
    "value": "Baleendah"
  },
  {
    "id": "3204321002",
    "district_id": "320432",
    "label": "Andir",
    "value": "Andir"
  },
  {
    "id": "3204321003",
    "district_id": "320432",
    "label": "Manggahang",
    "value": "Manggahang"
  },
  {
    "id": "3204321004",
    "district_id": "320432",
    "label": "Jelekong",
    "value": "Jelekong"
  },
  {
    "id": "3204321008",
    "district_id": "320432",
    "label": "Wargamekar",
    "value": "Wargamekar"
  },
  {
    "id": "3204322005",
    "district_id": "320432",
    "label": "Bojongmalaka",
    "value": "Bojongmalaka"
  },
  {
    "id": "3204322006",
    "district_id": "320432",
    "label": "Rancamanyar",
    "value": "Rancamanyar"
  },
  {
    "id": "3204322007",
    "district_id": "320432",
    "label": "Malakasari",
    "value": "Malakasari"
  },
  {
    "id": "3204332001",
    "district_id": "320433",
    "label": "Majalaya",
    "value": "Majalaya"
  },
  {
    "id": "3204332002",
    "district_id": "320433",
    "label": "Wangisagara",
    "value": "Wangisagara"
  },
  {
    "id": "3204332003",
    "district_id": "320433",
    "label": "Biru",
    "value": "Biru"
  },
  {
    "id": "3204332004",
    "district_id": "320433",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3204332005",
    "district_id": "320433",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3204332006",
    "district_id": "320433",
    "label": "Majasetra",
    "value": "Majasetra"
  },
  {
    "id": "3204332007",
    "district_id": "320433",
    "label": "Majakerta",
    "value": "Majakerta"
  },
  {
    "id": "3204332008",
    "district_id": "320433",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3204332009",
    "district_id": "320433",
    "label": "Padaulun",
    "value": "Padaulun"
  },
  {
    "id": "3204332010",
    "district_id": "320433",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3204332011",
    "district_id": "320433",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3204342001",
    "district_id": "320434",
    "label": "Rancakasumba",
    "value": "Rancakasumba"
  },
  {
    "id": "3204342002",
    "district_id": "320434",
    "label": "Solokanjeruk",
    "value": "Solokanjeruk"
  },
  {
    "id": "3204342003",
    "district_id": "320434",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3204342004",
    "district_id": "320434",
    "label": "Panyadap",
    "value": "Panyadap"
  },
  {
    "id": "3204342005",
    "district_id": "320434",
    "label": "Bojongemas",
    "value": "Bojongemas"
  },
  {
    "id": "3204342006",
    "district_id": "320434",
    "label": "Padamukti",
    "value": "Padamukti"
  },
  {
    "id": "3204342007",
    "district_id": "320434",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3204352001",
    "district_id": "320435",
    "label": "Cigentur",
    "value": "Cigentur"
  },
  {
    "id": "3204352002",
    "district_id": "320435",
    "label": "Cipedes",
    "value": "Cipedes"
  },
  {
    "id": "3204352003",
    "district_id": "320435",
    "label": "Loa",
    "value": "Loa"
  },
  {
    "id": "3204352004",
    "district_id": "320435",
    "label": "Cijagra",
    "value": "Cijagra"
  },
  {
    "id": "3204352005",
    "district_id": "320435",
    "label": "Cipaku",
    "value": "Cipaku"
  },
  {
    "id": "3204352006",
    "district_id": "320435",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3204352007",
    "district_id": "320435",
    "label": "Drawati",
    "value": "Drawati"
  },
  {
    "id": "3204352008",
    "district_id": "320435",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3204352009",
    "district_id": "320435",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3204352010",
    "district_id": "320435",
    "label": "Karangtunggal",
    "value": "Karangtunggal"
  },
  {
    "id": "3204352011",
    "district_id": "320435",
    "label": "Mekarpawitan",
    "value": "Mekarpawitan"
  },
  {
    "id": "3204352012",
    "district_id": "320435",
    "label": "Tangsimekar",
    "value": "Tangsimekar"
  },
  {
    "id": "3204362001",
    "district_id": "320436",
    "label": "Ibun",
    "value": "Ibun"
  },
  {
    "id": "3204362002",
    "district_id": "320436",
    "label": "Laksana",
    "value": "Laksana"
  },
  {
    "id": "3204362003",
    "district_id": "320436",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3204362004",
    "district_id": "320436",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3204362005",
    "district_id": "320436",
    "label": "Pangguh",
    "value": "Pangguh"
  },
  {
    "id": "3204362006",
    "district_id": "320436",
    "label": "Lampegan",
    "value": "Lampegan"
  },
  {
    "id": "3204362007",
    "district_id": "320436",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3204362008",
    "district_id": "320436",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3204362009",
    "district_id": "320436",
    "label": "Sudi",
    "value": "Sudi"
  },
  {
    "id": "3204362010",
    "district_id": "320436",
    "label": "Tangulun",
    "value": "Tangulun"
  },
  {
    "id": "3204362011",
    "district_id": "320436",
    "label": "Cibeet",
    "value": "Cibeet"
  },
  {
    "id": "3204362012",
    "district_id": "320436",
    "label": "Karyalaksana",
    "value": "Karyalaksana"
  },
  {
    "id": "3204372001",
    "district_id": "320437",
    "label": "Soreang",
    "value": "Soreang"
  },
  {
    "id": "3204372002",
    "district_id": "320437",
    "label": "Sadu",
    "value": "Sadu"
  },
  {
    "id": "3204372004",
    "district_id": "320437",
    "label": "Panyirapan",
    "value": "Panyirapan"
  },
  {
    "id": "3204372010",
    "district_id": "320437",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3204372011",
    "district_id": "320437",
    "label": "Pamekaran",
    "value": "Pamekaran"
  },
  {
    "id": "3204372017",
    "district_id": "320437",
    "label": "Karamatmulya",
    "value": "Karamatmulya"
  },
  {
    "id": "3204372018",
    "district_id": "320437",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3204372019",
    "district_id": "320437",
    "label": "Cingcin",
    "value": "Cingcin"
  },
  {
    "id": "3204372020",
    "district_id": "320437",
    "label": "Parungserab",
    "value": "Parungserab"
  },
  {
    "id": "3204372021",
    "district_id": "320437",
    "label": "Sekarwangi",
    "value": "Sekarwangi"
  },
  {
    "id": "3204382001",
    "district_id": "320438",
    "label": "Pasirjambu",
    "value": "Pasirjambu"
  },
  {
    "id": "3204382002",
    "district_id": "320438",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3204382003",
    "district_id": "320438",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3204382004",
    "district_id": "320438",
    "label": "Cukanggenteng",
    "value": "Cukanggenteng"
  },
  {
    "id": "3204382005",
    "district_id": "320438",
    "label": "Cisondari",
    "value": "Cisondari"
  },
  {
    "id": "3204382006",
    "district_id": "320438",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3204382007",
    "district_id": "320438",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3204382008",
    "district_id": "320438",
    "label": "Mekarmaju",
    "value": "Mekarmaju"
  },
  {
    "id": "3204382009",
    "district_id": "320438",
    "label": "Sugihmukti",
    "value": "Sugihmukti"
  },
  {
    "id": "3204382010",
    "district_id": "320438",
    "label": "Tenjolaya",
    "value": "Tenjolaya"
  },
  {
    "id": "3204392001",
    "district_id": "320439",
    "label": "Lebakmuncang",
    "value": "Lebakmuncang"
  },
  {
    "id": "3204392002",
    "district_id": "320439",
    "label": "Ciwidey",
    "value": "Ciwidey"
  },
  {
    "id": "3204392003",
    "district_id": "320439",
    "label": "Nengkelan",
    "value": "Nengkelan"
  },
  {
    "id": "3204392004",
    "district_id": "320439",
    "label": "Panundaan",
    "value": "Panundaan"
  },
  {
    "id": "3204392005",
    "district_id": "320439",
    "label": "Panyocokan",
    "value": "Panyocokan"
  },
  {
    "id": "3204392006",
    "district_id": "320439",
    "label": "Rawabogo",
    "value": "Rawabogo"
  },
  {
    "id": "3204392007",
    "district_id": "320439",
    "label": "Sukawening",
    "value": "Sukawening"
  },
  {
    "id": "3204402001",
    "district_id": "320440",
    "label": "Patengan",
    "value": "Patengan"
  },
  {
    "id": "3204402002",
    "district_id": "320440",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3204402003",
    "district_id": "320440",
    "label": "Indragiri",
    "value": "Indragiri"
  },
  {
    "id": "3204402004",
    "district_id": "320440",
    "label": "Cipelah",
    "value": "Cipelah"
  },
  {
    "id": "3204402005",
    "district_id": "320440",
    "label": "Alamendah",
    "value": "Alamendah"
  },
  {
    "id": "3204442001",
    "district_id": "320444",
    "label": "Cangkuang",
    "value": "Cangkuang"
  },
  {
    "id": "3204442002",
    "district_id": "320444",
    "label": "Ciluncat",
    "value": "Ciluncat"
  },
  {
    "id": "3204442003",
    "district_id": "320444",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3204442004",
    "district_id": "320444",
    "label": "Bandasari",
    "value": "Bandasari"
  },
  {
    "id": "3204442005",
    "district_id": "320444",
    "label": "Pananjung",
    "value": "Pananjung"
  },
  {
    "id": "3204442006",
    "district_id": "320444",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3204442007",
    "district_id": "320444",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3204462001",
    "district_id": "320446",
    "label": "Jelegong",
    "value": "Jelegong"
  },
  {
    "id": "3204462002",
    "district_id": "320446",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3204462003",
    "district_id": "320446",
    "label": "Pameuntasan",
    "value": "Pameuntasan"
  },
  {
    "id": "3204462004",
    "district_id": "320446",
    "label": "Kopo",
    "value": "Kopo"
  },
  {
    "id": "3204462005",
    "district_id": "320446",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3204462006",
    "district_id": "320446",
    "label": "Kutawaringin",
    "value": "Kutawaringin"
  },
  {
    "id": "3204462007",
    "district_id": "320446",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3204462008",
    "district_id": "320446",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3204462009",
    "district_id": "320446",
    "label": "Buninagara",
    "value": "Buninagara"
  },
  {
    "id": "3204462010",
    "district_id": "320446",
    "label": "Gajah Mekar",
    "value": "Gajah Mekar"
  },
  {
    "id": "3204462011",
    "district_id": "320446",
    "label": "Cilame",
    "value": "Cilame"
  },
  {
    "id": "3205011001",
    "district_id": "320501",
    "label": "Kota Kulon",
    "value": "Kota Kulon"
  },
  {
    "id": "3205011002",
    "district_id": "320501",
    "label": "Kota Wetan",
    "value": "Kota Wetan"
  },
  {
    "id": "3205011003",
    "district_id": "320501",
    "label": "Margawati",
    "value": "Margawati"
  },
  {
    "id": "3205011004",
    "district_id": "320501",
    "label": "Pakuwon",
    "value": "Pakuwon"
  },
  {
    "id": "3205011005",
    "district_id": "320501",
    "label": "Muara Sanding",
    "value": "Muara Sanding"
  },
  {
    "id": "3205011006",
    "district_id": "320501",
    "label": "Sukamentri",
    "value": "Sukamentri"
  },
  {
    "id": "3205011007",
    "district_id": "320501",
    "label": "Ciwalen",
    "value": "Ciwalen"
  },
  {
    "id": "3205011008",
    "district_id": "320501",
    "label": "Paminggir",
    "value": "Paminggir"
  },
  {
    "id": "3205011009",
    "district_id": "320501",
    "label": "Regol",
    "value": "Regol"
  },
  {
    "id": "3205011010",
    "district_id": "320501",
    "label": "Sukanegla",
    "value": "Sukanegla"
  },
  {
    "id": "3205011011",
    "district_id": "320501",
    "label": "Cimuncang",
    "value": "Cimuncang"
  },
  {
    "id": "3205021001",
    "district_id": "320502",
    "label": "Suci Kaler",
    "value": "Suci Kaler"
  },
  {
    "id": "3205021002",
    "district_id": "320502",
    "label": "Lebakjaya",
    "value": "Lebakjaya"
  },
  {
    "id": "3205021003",
    "district_id": "320502",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3205021014",
    "district_id": "320502",
    "label": "Lengkongjaya",
    "value": "Lengkongjaya"
  },
  {
    "id": "3205022004",
    "district_id": "320502",
    "label": "Karangpawitan",
    "value": "Karangpawitan"
  },
  {
    "id": "3205022005",
    "district_id": "320502",
    "label": "Situgede",
    "value": "Situgede"
  },
  {
    "id": "3205022006",
    "district_id": "320502",
    "label": "Cimurah",
    "value": "Cimurah"
  },
  {
    "id": "3205022007",
    "district_id": "320502",
    "label": "Suci",
    "value": "Suci"
  },
  {
    "id": "3205022008",
    "district_id": "320502",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3205022009",
    "district_id": "320502",
    "label": "Godog",
    "value": "Godog"
  },
  {
    "id": "3205022010",
    "district_id": "320502",
    "label": "Situsari",
    "value": "Situsari"
  },
  {
    "id": "3205022011",
    "district_id": "320502",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3205022012",
    "district_id": "320502",
    "label": "Sindangpalay",
    "value": "Sindangpalay"
  },
  {
    "id": "3205022013",
    "district_id": "320502",
    "label": "Lebakagung",
    "value": "Lebakagung"
  },
  {
    "id": "3205022015",
    "district_id": "320502",
    "label": "Sindanggalih",
    "value": "Sindanggalih"
  },
  {
    "id": "3205022016",
    "district_id": "320502",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205022017",
    "district_id": "320502",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3205022018",
    "district_id": "320502",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3205022019",
    "district_id": "320502",
    "label": "Situsaeur",
    "value": "Situsaeur"
  },
  {
    "id": "3205022020",
    "district_id": "320502",
    "label": "Situjaya",
    "value": "Situjaya"
  },
  {
    "id": "3205032001",
    "district_id": "320503",
    "label": "Wanaraja",
    "value": "Wanaraja"
  },
  {
    "id": "3205032002",
    "district_id": "320503",
    "label": "Wanamekar",
    "value": "Wanamekar"
  },
  {
    "id": "3205032003",
    "district_id": "320503",
    "label": "Cinunuk",
    "value": "Cinunuk"
  },
  {
    "id": "3205032007",
    "district_id": "320503",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3205032008",
    "district_id": "320503",
    "label": "Sindangratu",
    "value": "Sindangratu"
  },
  {
    "id": "3205032011",
    "district_id": "320503",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3205032015",
    "district_id": "320503",
    "label": "Sindangmekar",
    "value": "Sindangmekar"
  },
  {
    "id": "3205032019",
    "district_id": "320503",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3205032024",
    "district_id": "320503",
    "label": "Sindangprabu",
    "value": "Sindangprabu"
  },
  {
    "id": "3205041012",
    "district_id": "320504",
    "label": "Pananjung",
    "value": "Pananjung"
  },
  {
    "id": "3205042001",
    "district_id": "320504",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3205042002",
    "district_id": "320504",
    "label": "Cimanganten",
    "value": "Cimanganten"
  },
  {
    "id": "3205042003",
    "district_id": "320504",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3205042004",
    "district_id": "320504",
    "label": "Rancabango",
    "value": "Rancabango"
  },
  {
    "id": "3205042005",
    "district_id": "320504",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3205042006",
    "district_id": "320504",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3205042007",
    "district_id": "320504",
    "label": "Tanjung Kamuning",
    "value": "Tanjung Kamuning"
  },
  {
    "id": "3205042008",
    "district_id": "320504",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3205042009",
    "district_id": "320504",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3205042010",
    "district_id": "320504",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3205042011",
    "district_id": "320504",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3205042013",
    "district_id": "320504",
    "label": "Panjiwangi",
    "value": "Panjiwangi"
  },
  {
    "id": "3205051001",
    "district_id": "320505",
    "label": "Pataruman",
    "value": "Pataruman"
  },
  {
    "id": "3205051002",
    "district_id": "320505",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3205051006",
    "district_id": "320505",
    "label": "Jayawaras",
    "value": "Jayawaras"
  },
  {
    "id": "3205051009",
    "district_id": "320505",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3205051012",
    "district_id": "320505",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3205052003",
    "district_id": "320505",
    "label": "Jayaraga",
    "value": "Jayaraga"
  },
  {
    "id": "3205052004",
    "district_id": "320505",
    "label": "Haurpanggung",
    "value": "Haurpanggung"
  },
  {
    "id": "3205052005",
    "district_id": "320505",
    "label": "Cibunar",
    "value": "Cibunar"
  },
  {
    "id": "3205052007",
    "district_id": "320505",
    "label": "Sukabakti",
    "value": "Sukabakti"
  },
  {
    "id": "3205052008",
    "district_id": "320505",
    "label": "Tarogong",
    "value": "Tarogong"
  },
  {
    "id": "3205052010",
    "district_id": "320505",
    "label": "Mekargalih",
    "value": "Mekargalih"
  },
  {
    "id": "3205052011",
    "district_id": "320505",
    "label": "Kersamenak",
    "value": "Kersamenak"
  },
  {
    "id": "3205062001",
    "district_id": "320506",
    "label": "Banyuresmi",
    "value": "Banyuresmi"
  },
  {
    "id": "3205062002",
    "district_id": "320506",
    "label": "Cimareme",
    "value": "Cimareme"
  },
  {
    "id": "3205062003",
    "district_id": "320506",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3205062004",
    "district_id": "320506",
    "label": "Sukasenang",
    "value": "Sukasenang"
  },
  {
    "id": "3205062005",
    "district_id": "320506",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3205062006",
    "district_id": "320506",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3205062007",
    "district_id": "320506",
    "label": "Dangdeur",
    "value": "Dangdeur"
  },
  {
    "id": "3205062008",
    "district_id": "320506",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3205062009",
    "district_id": "320506",
    "label": "Pamekarsari",
    "value": "Pamekarsari"
  },
  {
    "id": "3205062010",
    "district_id": "320506",
    "label": "Binakarya",
    "value": "Binakarya"
  },
  {
    "id": "3205062011",
    "district_id": "320506",
    "label": "Bagendit",
    "value": "Bagendit"
  },
  {
    "id": "3205062012",
    "district_id": "320506",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3205062013",
    "district_id": "320506",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "3205062014",
    "district_id": "320506",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3205062015",
    "district_id": "320506",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3205072001",
    "district_id": "320507",
    "label": "Samarang",
    "value": "Samarang"
  },
  {
    "id": "3205072002",
    "district_id": "320507",
    "label": "Cintarakyat",
    "value": "Cintarakyat"
  },
  {
    "id": "3205072003",
    "district_id": "320507",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3205072004",
    "district_id": "320507",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3205072005",
    "district_id": "320507",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3205072006",
    "district_id": "320507",
    "label": "Cintakarya",
    "value": "Cintakarya"
  },
  {
    "id": "3205072007",
    "district_id": "320507",
    "label": "Tanjung Karya",
    "value": "Tanjung Karya"
  },
  {
    "id": "3205072008",
    "district_id": "320507",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3205072009",
    "district_id": "320507",
    "label": "Cintarasa",
    "value": "Cintarasa"
  },
  {
    "id": "3205072010",
    "district_id": "320507",
    "label": "Cintaasih",
    "value": "Cintaasih"
  },
  {
    "id": "3205072011",
    "district_id": "320507",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3205072012",
    "district_id": "320507",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3205072013",
    "district_id": "320507",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3205082001",
    "district_id": "320508",
    "label": "Pasirwangi",
    "value": "Pasirwangi"
  },
  {
    "id": "3205082002",
    "district_id": "320508",
    "label": "Pasirkiamis",
    "value": "Pasirkiamis"
  },
  {
    "id": "3205082003",
    "district_id": "320508",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3205082004",
    "district_id": "320508",
    "label": "Karyamekar",
    "value": "Karyamekar"
  },
  {
    "id": "3205082005",
    "district_id": "320508",
    "label": "Padawaas",
    "value": "Padawaas"
  },
  {
    "id": "3205082006",
    "district_id": "320508",
    "label": "Padaasih",
    "value": "Padaasih"
  },
  {
    "id": "3205082007",
    "district_id": "320508",
    "label": "Barusari",
    "value": "Barusari"
  },
  {
    "id": "3205082008",
    "district_id": "320508",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3205082009",
    "district_id": "320508",
    "label": "Padamukti",
    "value": "Padamukti"
  },
  {
    "id": "3205082010",
    "district_id": "320508",
    "label": "Sinarjaya",
    "value": "Sinarjaya"
  },
  {
    "id": "3205082011",
    "district_id": "320508",
    "label": "Sarimukti",
    "value": "Sarimukti"
  },
  {
    "id": "3205082012",
    "district_id": "320508",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3205092001",
    "district_id": "320509",
    "label": "Leles",
    "value": "Leles"
  },
  {
    "id": "3205092002",
    "district_id": "320509",
    "label": "Ciburial",
    "value": "Ciburial"
  },
  {
    "id": "3205092003",
    "district_id": "320509",
    "label": "Jangkurang",
    "value": "Jangkurang"
  },
  {
    "id": "3205092004",
    "district_id": "320509",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3205092005",
    "district_id": "320509",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "3205092006",
    "district_id": "320509",
    "label": "Cangkuang",
    "value": "Cangkuang"
  },
  {
    "id": "3205092007",
    "district_id": "320509",
    "label": "Salamnunggal",
    "value": "Salamnunggal"
  },
  {
    "id": "3205092008",
    "district_id": "320509",
    "label": "Kandangmukti",
    "value": "Kandangmukti"
  },
  {
    "id": "3205092009",
    "district_id": "320509",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3205092010",
    "district_id": "320509",
    "label": "Cipancar",
    "value": "Cipancar"
  },
  {
    "id": "3205092011",
    "district_id": "320509",
    "label": "Haruman",
    "value": "Haruman"
  },
  {
    "id": "3205092012",
    "district_id": "320509",
    "label": "Dano",
    "value": "Dano"
  },
  {
    "id": "3205102001",
    "district_id": "320510",
    "label": "Kadungora",
    "value": "Kadungora"
  },
  {
    "id": "3205102002",
    "district_id": "320510",
    "label": "Gandamekar",
    "value": "Gandamekar"
  },
  {
    "id": "3205102003",
    "district_id": "320510",
    "label": "Cikembulan",
    "value": "Cikembulan"
  },
  {
    "id": "3205102004",
    "district_id": "320510",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3205102005",
    "district_id": "320510",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3205102006",
    "district_id": "320510",
    "label": "Tanggulun",
    "value": "Tanggulun"
  },
  {
    "id": "3205102007",
    "district_id": "320510",
    "label": "Mekarbakti",
    "value": "Mekarbakti"
  },
  {
    "id": "3205102008",
    "district_id": "320510",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3205102009",
    "district_id": "320510",
    "label": "Harumansari",
    "value": "Harumansari"
  },
  {
    "id": "3205102010",
    "district_id": "320510",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3205102011",
    "district_id": "320510",
    "label": "Hegarsari",
    "value": "Hegarsari"
  },
  {
    "id": "3205102012",
    "district_id": "320510",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3205102013",
    "district_id": "320510",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3205102014",
    "district_id": "320510",
    "label": "Rancasalak",
    "value": "Rancasalak"
  },
  {
    "id": "3205112001",
    "district_id": "320511",
    "label": "Leuwigoong",
    "value": "Leuwigoong"
  },
  {
    "id": "3205112002",
    "district_id": "320511",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3205112003",
    "district_id": "320511",
    "label": "Margacinta",
    "value": "Margacinta"
  },
  {
    "id": "3205112004",
    "district_id": "320511",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "3205112005",
    "district_id": "320511",
    "label": "Dungusiku",
    "value": "Dungusiku"
  },
  {
    "id": "3205112006",
    "district_id": "320511",
    "label": "Tambak Sari",
    "value": "Tambak Sari"
  },
  {
    "id": "3205112007",
    "district_id": "320511",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3205112008",
    "district_id": "320511",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3205122001",
    "district_id": "320512",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "3205122002",
    "district_id": "320512",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205122003",
    "district_id": "320512",
    "label": "Cibunar",
    "value": "Cibunar"
  },
  {
    "id": "3205122004",
    "district_id": "320512",
    "label": "Sindangsuka",
    "value": "Sindangsuka"
  },
  {
    "id": "3205122005",
    "district_id": "320512",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3205122006",
    "district_id": "320512",
    "label": "Sukalilah",
    "value": "Sukalilah"
  },
  {
    "id": "3205122007",
    "district_id": "320512",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3205122008",
    "district_id": "320512",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3205122009",
    "district_id": "320512",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3205122010",
    "district_id": "320512",
    "label": "Keresek",
    "value": "Keresek"
  },
  {
    "id": "3205122011",
    "district_id": "320512",
    "label": "Wanakerta",
    "value": "Wanakerta"
  },
  {
    "id": "3205132001",
    "district_id": "320513",
    "label": "Kersamanah",
    "value": "Kersamanah"
  },
  {
    "id": "3205132002",
    "district_id": "320513",
    "label": "Nanjungjaya",
    "value": "Nanjungjaya"
  },
  {
    "id": "3205132003",
    "district_id": "320513",
    "label": "Sukamerang",
    "value": "Sukamerang"
  },
  {
    "id": "3205132004",
    "district_id": "320513",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3205132005",
    "district_id": "320513",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3205132006",
    "district_id": "320513",
    "label": "Mekarraya",
    "value": "Mekarraya"
  },
  {
    "id": "3205142001",
    "district_id": "320514",
    "label": "Malangbong",
    "value": "Malangbong"
  },
  {
    "id": "3205142002",
    "district_id": "320514",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3205142003",
    "district_id": "320514",
    "label": "Cibunar",
    "value": "Cibunar"
  },
  {
    "id": "3205142004",
    "district_id": "320514",
    "label": "Cikarang",
    "value": "Cikarang"
  },
  {
    "id": "3205142005",
    "district_id": "320514",
    "label": "Sakawayang",
    "value": "Sakawayang"
  },
  {
    "id": "3205142006",
    "district_id": "320514",
    "label": "Mekarasih",
    "value": "Mekarasih"
  },
  {
    "id": "3205142007",
    "district_id": "320514",
    "label": "Cisitu",
    "value": "Cisitu"
  },
  {
    "id": "3205142008",
    "district_id": "320514",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3205142009",
    "district_id": "320514",
    "label": "Lewobaru",
    "value": "Lewobaru"
  },
  {
    "id": "3205142010",
    "district_id": "320514",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3205142011",
    "district_id": "320514",
    "label": "Cilampuyang",
    "value": "Cilampuyang"
  },
  {
    "id": "3205142012",
    "district_id": "320514",
    "label": "Cinagara",
    "value": "Cinagara"
  },
  {
    "id": "3205142013",
    "district_id": "320514",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3205142014",
    "district_id": "320514",
    "label": "Citeras",
    "value": "Citeras"
  },
  {
    "id": "3205142015",
    "district_id": "320514",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3205142016",
    "district_id": "320514",
    "label": "Sekarwangi",
    "value": "Sekarwangi"
  },
  {
    "id": "3205142017",
    "district_id": "320514",
    "label": "Kutanagara",
    "value": "Kutanagara"
  },
  {
    "id": "3205142018",
    "district_id": "320514",
    "label": "Sanding",
    "value": "Sanding"
  },
  {
    "id": "3205142019",
    "district_id": "320514",
    "label": "Cihaurkuning",
    "value": "Cihaurkuning"
  },
  {
    "id": "3205142020",
    "district_id": "320514",
    "label": "Baru Dua",
    "value": "Baru Dua"
  },
  {
    "id": "3205142021",
    "district_id": "320514",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3205142022",
    "district_id": "320514",
    "label": "Bunisari",
    "value": "Bunisari"
  },
  {
    "id": "3205142023",
    "district_id": "320514",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3205142024",
    "district_id": "320514",
    "label": "Girimakmur",
    "value": "Girimakmur"
  },
  {
    "id": "3205152001",
    "district_id": "320515",
    "label": "Sukawening",
    "value": "Sukawening"
  },
  {
    "id": "3205152002",
    "district_id": "320515",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3205152003",
    "district_id": "320515",
    "label": "Mekarluyu",
    "value": "Mekarluyu"
  },
  {
    "id": "3205152004",
    "district_id": "320515",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3205152005",
    "district_id": "320515",
    "label": "Sudalarang",
    "value": "Sudalarang"
  },
  {
    "id": "3205152006",
    "district_id": "320515",
    "label": "Sukasono",
    "value": "Sukasono"
  },
  {
    "id": "3205152007",
    "district_id": "320515",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3205152008",
    "district_id": "320515",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3205152009",
    "district_id": "320515",
    "label": "Maripari",
    "value": "Maripari"
  },
  {
    "id": "3205152010",
    "district_id": "320515",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3205152011",
    "district_id": "320515",
    "label": "Mekar Hurip",
    "value": "Mekar Hurip"
  },
  {
    "id": "3205162001",
    "district_id": "320516",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3205162002",
    "district_id": "320516",
    "label": "Cintamanik",
    "value": "Cintamanik"
  },
  {
    "id": "3205162003",
    "district_id": "320516",
    "label": "Cinta",
    "value": "Cinta"
  },
  {
    "id": "3205162004",
    "district_id": "320516",
    "label": "Sindanggalih",
    "value": "Sindanggalih"
  },
  {
    "id": "3205172001",
    "district_id": "320517",
    "label": "Bayongbong",
    "value": "Bayongbong"
  },
  {
    "id": "3205172002",
    "district_id": "320517",
    "label": "Panembong",
    "value": "Panembong"
  },
  {
    "id": "3205172003",
    "district_id": "320517",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3205172004",
    "district_id": "320517",
    "label": "Selakuray",
    "value": "Selakuray"
  },
  {
    "id": "3205172005",
    "district_id": "320517",
    "label": "Karyajaya",
    "value": "Karyajaya"
  },
  {
    "id": "3205172006",
    "district_id": "320517",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3205172007",
    "district_id": "320517",
    "label": "Pamalayan",
    "value": "Pamalayan"
  },
  {
    "id": "3205172008",
    "district_id": "320517",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205172009",
    "district_id": "320517",
    "label": "Ciburuy",
    "value": "Ciburuy"
  },
  {
    "id": "3205172010",
    "district_id": "320517",
    "label": "Ciela",
    "value": "Ciela"
  },
  {
    "id": "3205172011",
    "district_id": "320517",
    "label": "Cikedokan",
    "value": "Cikedokan"
  },
  {
    "id": "3205172012",
    "district_id": "320517",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3205172013",
    "district_id": "320517",
    "label": "Sukasenang",
    "value": "Sukasenang"
  },
  {
    "id": "3205172014",
    "district_id": "320517",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3205172015",
    "district_id": "320517",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3205172016",
    "district_id": "320517",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3205172017",
    "district_id": "320517",
    "label": "Cinisti",
    "value": "Cinisti"
  },
  {
    "id": "3205172018",
    "district_id": "320517",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3205182001",
    "district_id": "320518",
    "label": "Cigedug",
    "value": "Cigedug"
  },
  {
    "id": "3205182002",
    "district_id": "320518",
    "label": "Barusuda",
    "value": "Barusuda"
  },
  {
    "id": "3205182003",
    "district_id": "320518",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3205182004",
    "district_id": "320518",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3205182005",
    "district_id": "320518",
    "label": "Cintanagara",
    "value": "Cintanagara"
  },
  {
    "id": "3205192001",
    "district_id": "320519",
    "label": "Cilawu",
    "value": "Cilawu"
  },
  {
    "id": "3205192002",
    "district_id": "320519",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3205192003",
    "district_id": "320519",
    "label": "Karyamekar",
    "value": "Karyamekar"
  },
  {
    "id": "3205192004",
    "district_id": "320519",
    "label": "Dawungsari",
    "value": "Dawungsari"
  },
  {
    "id": "3205192005",
    "district_id": "320519",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3205192006",
    "district_id": "320519",
    "label": "Sukahati",
    "value": "Sukahati"
  },
  {
    "id": "3205192007",
    "district_id": "320519",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205192008",
    "district_id": "320519",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3205192009",
    "district_id": "320519",
    "label": "Desakolot",
    "value": "Desakolot"
  },
  {
    "id": "3205192010",
    "district_id": "320519",
    "label": "Ngamplangsari",
    "value": "Ngamplangsari"
  },
  {
    "id": "3205192011",
    "district_id": "320519",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3205192012",
    "district_id": "320519",
    "label": "Dangiang",
    "value": "Dangiang"
  },
  {
    "id": "3205192013",
    "district_id": "320519",
    "label": "Dayeuhmanggung",
    "value": "Dayeuhmanggung"
  },
  {
    "id": "3205192014",
    "district_id": "320519",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3205192015",
    "district_id": "320519",
    "label": "Mangurakyat",
    "value": "Mangurakyat"
  },
  {
    "id": "3205192016",
    "district_id": "320519",
    "label": "Ngamplang",
    "value": "Ngamplang"
  },
  {
    "id": "3205192017",
    "district_id": "320519",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3205192018",
    "district_id": "320519",
    "label": "Sukamurni",
    "value": "Sukamurni"
  },
  {
    "id": "3205202001",
    "district_id": "320520",
    "label": "Cisurupan",
    "value": "Cisurupan"
  },
  {
    "id": "3205202002",
    "district_id": "320520",
    "label": "Cidatar",
    "value": "Cidatar"
  },
  {
    "id": "3205202003",
    "district_id": "320520",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3205202004",
    "district_id": "320520",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3205202005",
    "district_id": "320520",
    "label": "Situsari",
    "value": "Situsari"
  },
  {
    "id": "3205202006",
    "district_id": "320520",
    "label": "Pakuwon",
    "value": "Pakuwon"
  },
  {
    "id": "3205202007",
    "district_id": "320520",
    "label": "Balewangi",
    "value": "Balewangi"
  },
  {
    "id": "3205202008",
    "district_id": "320520",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3205202009",
    "district_id": "320520",
    "label": "Cisero",
    "value": "Cisero"
  },
  {
    "id": "3205202010",
    "district_id": "320520",
    "label": "Kramatwangi",
    "value": "Kramatwangi"
  },
  {
    "id": "3205202011",
    "district_id": "320520",
    "label": "Cipaganti",
    "value": "Cipaganti"
  },
  {
    "id": "3205202012",
    "district_id": "320520",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3205202013",
    "district_id": "320520",
    "label": "Tambakbaya",
    "value": "Tambakbaya"
  },
  {
    "id": "3205202014",
    "district_id": "320520",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3205202015",
    "district_id": "320520",
    "label": "Pangauban",
    "value": "Pangauban"
  },
  {
    "id": "3205202016",
    "district_id": "320520",
    "label": "Simpangsari",
    "value": "Simpangsari"
  },
  {
    "id": "3205202017",
    "district_id": "320520",
    "label": "Cintaasih",
    "value": "Cintaasih"
  },
  {
    "id": "3205212001",
    "district_id": "320521",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3205212002",
    "district_id": "320521",
    "label": "Cintadamai",
    "value": "Cintadamai"
  },
  {
    "id": "3205212003",
    "district_id": "320521",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3205212004",
    "district_id": "320521",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3205212005",
    "district_id": "320521",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3205212006",
    "district_id": "320521",
    "label": "Padamukti",
    "value": "Padamukti"
  },
  {
    "id": "3205212007",
    "district_id": "320521",
    "label": "Sukalilah",
    "value": "Sukalilah"
  },
  {
    "id": "3205222001",
    "district_id": "320522",
    "label": "Cikajang",
    "value": "Cikajang"
  },
  {
    "id": "3205222002",
    "district_id": "320522",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3205222003",
    "district_id": "320522",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205222004",
    "district_id": "320522",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3205222005",
    "district_id": "320522",
    "label": "Giriawas",
    "value": "Giriawas"
  },
  {
    "id": "3205222006",
    "district_id": "320522",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3205222007",
    "district_id": "320522",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3205222008",
    "district_id": "320522",
    "label": "Cikandang",
    "value": "Cikandang"
  },
  {
    "id": "3205222009",
    "district_id": "320522",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3205222010",
    "district_id": "320522",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3205222011",
    "district_id": "320522",
    "label": "Cipangramatan",
    "value": "Cipangramatan"
  },
  {
    "id": "3205222012",
    "district_id": "320522",
    "label": "Kramatwangi",
    "value": "Kramatwangi"
  },
  {
    "id": "3205232001",
    "district_id": "320523",
    "label": "Banjarwangi",
    "value": "Banjarwangi"
  },
  {
    "id": "3205232002",
    "district_id": "320523",
    "label": "Talagajaya",
    "value": "Talagajaya"
  },
  {
    "id": "3205232003",
    "district_id": "320523",
    "label": "Padahurip",
    "value": "Padahurip"
  },
  {
    "id": "3205232004",
    "district_id": "320523",
    "label": "Kadongdong",
    "value": "Kadongdong"
  },
  {
    "id": "3205232005",
    "district_id": "320523",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3205232006",
    "district_id": "320523",
    "label": "Jayabakti",
    "value": "Jayabakti"
  },
  {
    "id": "3205232007",
    "district_id": "320523",
    "label": "Mulyajaya",
    "value": "Mulyajaya"
  },
  {
    "id": "3205232008",
    "district_id": "320523",
    "label": "Dangiang",
    "value": "Dangiang"
  },
  {
    "id": "3205232009",
    "district_id": "320523",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3205232010",
    "district_id": "320523",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3205232011",
    "district_id": "320523",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3205242001",
    "district_id": "320524",
    "label": "Singajaya",
    "value": "Singajaya"
  },
  {
    "id": "3205242002",
    "district_id": "320524",
    "label": "Ciudian",
    "value": "Ciudian"
  },
  {
    "id": "3205242003",
    "district_id": "320524",
    "label": "Mekartani",
    "value": "Mekartani"
  },
  {
    "id": "3205242004",
    "district_id": "320524",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3205242005",
    "district_id": "320524",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3205242006",
    "district_id": "320524",
    "label": "Pancasura",
    "value": "Pancasura"
  },
  {
    "id": "3205242007",
    "district_id": "320524",
    "label": "Karangagung",
    "value": "Karangagung"
  },
  {
    "id": "3205242008",
    "district_id": "320524",
    "label": "Cigintung",
    "value": "Cigintung"
  },
  {
    "id": "3205242009",
    "district_id": "320524",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3205252001",
    "district_id": "320525",
    "label": "Cihurip",
    "value": "Cihurip"
  },
  {
    "id": "3205252002",
    "district_id": "320525",
    "label": "Cisangkal",
    "value": "Cisangkal"
  },
  {
    "id": "3205252003",
    "district_id": "320525",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3205252004",
    "district_id": "320525",
    "label": "Jayamukti",
    "value": "Jayamukti"
  },
  {
    "id": "3205262001",
    "district_id": "320526",
    "label": "Peundeuy",
    "value": "Peundeuy"
  },
  {
    "id": "3205262002",
    "district_id": "320526",
    "label": "Toblong",
    "value": "Toblong"
  },
  {
    "id": "3205262003",
    "district_id": "320526",
    "label": "Saribakti",
    "value": "Saribakti"
  },
  {
    "id": "3205262004",
    "district_id": "320526",
    "label": "Pangrumasan",
    "value": "Pangrumasan"
  },
  {
    "id": "3205262005",
    "district_id": "320526",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3205262006",
    "district_id": "320526",
    "label": "Purwajaya",
    "value": "Purwajaya"
  },
  {
    "id": "3205272001",
    "district_id": "320527",
    "label": "Pameungpeuk",
    "value": "Pameungpeuk"
  },
  {
    "id": "3205272002",
    "district_id": "320527",
    "label": "Mancagahar",
    "value": "Mancagahar"
  },
  {
    "id": "3205272003",
    "district_id": "320527",
    "label": "Paas",
    "value": "Paas"
  },
  {
    "id": "3205272004",
    "district_id": "320527",
    "label": "Mandalakasih",
    "value": "Mandalakasih"
  },
  {
    "id": "3205272005",
    "district_id": "320527",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3205272006",
    "district_id": "320527",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3205272007",
    "district_id": "320527",
    "label": "Sirnabakti",
    "value": "Sirnabakti"
  },
  {
    "id": "3205272008",
    "district_id": "320527",
    "label": "Bojong Kidul",
    "value": "Bojong Kidul"
  },
  {
    "id": "3205282001",
    "district_id": "320528",
    "label": "Cisompet",
    "value": "Cisompet"
  },
  {
    "id": "3205282002",
    "district_id": "320528",
    "label": "Cihaurkuning",
    "value": "Cihaurkuning"
  },
  {
    "id": "3205282003",
    "district_id": "320528",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3205282004",
    "district_id": "320528",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3205282005",
    "district_id": "320528",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3205282006",
    "district_id": "320528",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3205282007",
    "district_id": "320528",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3205282008",
    "district_id": "320528",
    "label": "Panyindangan",
    "value": "Panyindangan"
  },
  {
    "id": "3205282009",
    "district_id": "320528",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3205282010",
    "district_id": "320528",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3205282011",
    "district_id": "320528",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3205292001",
    "district_id": "320529",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "3205292002",
    "district_id": "320529",
    "label": "Maroko",
    "value": "Maroko"
  },
  {
    "id": "3205292003",
    "district_id": "320529",
    "label": "Sancang",
    "value": "Sancang"
  },
  {
    "id": "3205292004",
    "district_id": "320529",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3205292005",
    "district_id": "320529",
    "label": "Sagara",
    "value": "Sagara"
  },
  {
    "id": "3205292006",
    "district_id": "320529",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205292007",
    "district_id": "320529",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3205292008",
    "district_id": "320529",
    "label": "Mekar Mukti",
    "value": "Mekar Mukti"
  },
  {
    "id": "3205292009",
    "district_id": "320529",
    "label": "Cigaronggong",
    "value": "Cigaronggong"
  },
  {
    "id": "3205292010",
    "district_id": "320529",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3205292011",
    "district_id": "320529",
    "label": "Najaten",
    "value": "Najaten"
  },
  {
    "id": "3205302001",
    "district_id": "320530",
    "label": "Cikelet",
    "value": "Cikelet"
  },
  {
    "id": "3205302002",
    "district_id": "320530",
    "label": "Linggamanik",
    "value": "Linggamanik"
  },
  {
    "id": "3205302003",
    "district_id": "320530",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3205302004",
    "district_id": "320530",
    "label": "Cogadog",
    "value": "Cogadog"
  },
  {
    "id": "3205302005",
    "district_id": "320530",
    "label": "Pamalayan",
    "value": "Pamalayan"
  },
  {
    "id": "3205302006",
    "district_id": "320530",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3205302007",
    "district_id": "320530",
    "label": "Cijambe",
    "value": "Cijambe"
  },
  {
    "id": "3205302008",
    "district_id": "320530",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3205302009",
    "district_id": "320530",
    "label": "Ciroyom",
    "value": "Ciroyom"
  },
  {
    "id": "3205302010",
    "district_id": "320530",
    "label": "Awassagara",
    "value": "Awassagara"
  },
  {
    "id": "3205302011",
    "district_id": "320530",
    "label": "Tipar",
    "value": "Tipar"
  },
  {
    "id": "3205312001",
    "district_id": "320531",
    "label": "Bungbulang",
    "value": "Bungbulang"
  },
  {
    "id": "3205312002",
    "district_id": "320531",
    "label": "Hanjuang",
    "value": "Hanjuang"
  },
  {
    "id": "3205312003",
    "district_id": "320531",
    "label": "Gunamekar",
    "value": "Gunamekar"
  },
  {
    "id": "3205312004",
    "district_id": "320531",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3205312005",
    "district_id": "320531",
    "label": "Mekarbakti",
    "value": "Mekarbakti"
  },
  {
    "id": "3205312006",
    "district_id": "320531",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3205312007",
    "district_id": "320531",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3205312008",
    "district_id": "320531",
    "label": "Sinarjaya",
    "value": "Sinarjaya"
  },
  {
    "id": "3205312009",
    "district_id": "320531",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3205312010",
    "district_id": "320531",
    "label": "Cihikeu",
    "value": "Cihikeu"
  },
  {
    "id": "3205312011",
    "district_id": "320531",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3205312012",
    "district_id": "320531",
    "label": "Gunung Jampang",
    "value": "Gunung Jampang"
  },
  {
    "id": "3205312013",
    "district_id": "320531",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3205322001",
    "district_id": "320532",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3205322002",
    "district_id": "320532",
    "label": "Karangwangi",
    "value": "Karangwangi"
  },
  {
    "id": "3205322003",
    "district_id": "320532",
    "label": "Jayabaya",
    "value": "Jayabaya"
  },
  {
    "id": "3205322004",
    "district_id": "320532",
    "label": "Cijayana",
    "value": "Cijayana"
  },
  {
    "id": "3205322005",
    "district_id": "320532",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205332001",
    "district_id": "320533",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3205332002",
    "district_id": "320533",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3205332003",
    "district_id": "320533",
    "label": "Tanjungmulya",
    "value": "Tanjungmulya"
  },
  {
    "id": "3205332004",
    "district_id": "320533",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3205332005",
    "district_id": "320533",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3205332006",
    "district_id": "320533",
    "label": "Pasirlangu",
    "value": "Pasirlangu"
  },
  {
    "id": "3205332007",
    "district_id": "320533",
    "label": "Talagawangi",
    "value": "Talagawangi"
  },
  {
    "id": "3205332008",
    "district_id": "320533",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "3205332009",
    "district_id": "320533",
    "label": "Panyindangan",
    "value": "Panyindangan"
  },
  {
    "id": "3205332010",
    "district_id": "320533",
    "label": "Tegalgede",
    "value": "Tegalgede"
  },
  {
    "id": "3205332011",
    "district_id": "320533",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3205332012",
    "district_id": "320533",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3205332013",
    "district_id": "320533",
    "label": "Jayamekar",
    "value": "Jayamekar"
  },
  {
    "id": "3205342001",
    "district_id": "320534",
    "label": "Pakenjeng",
    "value": "Pakenjeng"
  },
  {
    "id": "3205342002",
    "district_id": "320534",
    "label": "Garumukti",
    "value": "Garumukti"
  },
  {
    "id": "3205342003",
    "district_id": "320534",
    "label": "Panawa",
    "value": "Panawa"
  },
  {
    "id": "3205342004",
    "district_id": "320534",
    "label": "Pananjung",
    "value": "Pananjung"
  },
  {
    "id": "3205342005",
    "district_id": "320534",
    "label": "Linggarjati",
    "value": "Linggarjati"
  },
  {
    "id": "3205352001",
    "district_id": "320535",
    "label": "Cisewu",
    "value": "Cisewu"
  },
  {
    "id": "3205352002",
    "district_id": "320535",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3205352003",
    "district_id": "320535",
    "label": "Pamalayan",
    "value": "Pamalayan"
  },
  {
    "id": "3205352004",
    "district_id": "320535",
    "label": "Cikarang",
    "value": "Cikarang"
  },
  {
    "id": "3205352005",
    "district_id": "320535",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3205352006",
    "district_id": "320535",
    "label": "Nyalindung",
    "value": "Nyalindung"
  },
  {
    "id": "3205352007",
    "district_id": "320535",
    "label": "Karangsewu",
    "value": "Karangsewu"
  },
  {
    "id": "3205352008",
    "district_id": "320535",
    "label": "Mekarsewu",
    "value": "Mekarsewu"
  },
  {
    "id": "3205352009",
    "district_id": "320535",
    "label": "Panggalih",
    "value": "Panggalih"
  },
  {
    "id": "3205362001",
    "district_id": "320536",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3205362002",
    "district_id": "320536",
    "label": "Indralayang",
    "value": "Indralayang"
  },
  {
    "id": "3205362003",
    "district_id": "320536",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "3205362004",
    "district_id": "320536",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3205362005",
    "district_id": "320536",
    "label": "Purbayani",
    "value": "Purbayani"
  },
  {
    "id": "3205362006",
    "district_id": "320536",
    "label": "Samuderajaya",
    "value": "Samuderajaya"
  },
  {
    "id": "3205372001",
    "district_id": "320537",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3205372002",
    "district_id": "320537",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3205372003",
    "district_id": "320537",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3205372004",
    "district_id": "320537",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3205372005",
    "district_id": "320537",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3205372006",
    "district_id": "320537",
    "label": "Selaawi",
    "value": "Selaawi"
  },
  {
    "id": "3205372007",
    "district_id": "320537",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3205382001",
    "district_id": "320538",
    "label": "Limbangan Tengah",
    "value": "Limbangan Tengah"
  },
  {
    "id": "3205382002",
    "district_id": "320538",
    "label": "Limbangan Barat",
    "value": "Limbangan Barat"
  },
  {
    "id": "3205382003",
    "district_id": "320538",
    "label": "Galihpakuwon",
    "value": "Galihpakuwon"
  },
  {
    "id": "3205382004",
    "district_id": "320538",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "3205382005",
    "district_id": "320538",
    "label": "Cijolang",
    "value": "Cijolang"
  },
  {
    "id": "3205382006",
    "district_id": "320538",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3205382007",
    "district_id": "320538",
    "label": "Simpen Kaler",
    "value": "Simpen Kaler"
  },
  {
    "id": "3205382008",
    "district_id": "320538",
    "label": "Pasirwaru",
    "value": "Pasirwaru"
  },
  {
    "id": "3205382009",
    "district_id": "320538",
    "label": "Simpen Kidul",
    "value": "Simpen Kidul"
  },
  {
    "id": "3205382010",
    "district_id": "320538",
    "label": "Ciwangi",
    "value": "Ciwangi"
  },
  {
    "id": "3205382011",
    "district_id": "320538",
    "label": "Cigagade",
    "value": "Cigagade"
  },
  {
    "id": "3205382012",
    "district_id": "320538",
    "label": "Limbangan Timur",
    "value": "Limbangan Timur"
  },
  {
    "id": "3205382013",
    "district_id": "320538",
    "label": "Dunguswiru",
    "value": "Dunguswiru"
  },
  {
    "id": "3205382014",
    "district_id": "320538",
    "label": "Pangeureunan",
    "value": "Pangeureunan"
  },
  {
    "id": "3205392001",
    "district_id": "320539",
    "label": "Selaawi",
    "value": "Selaawi"
  },
  {
    "id": "3205392002",
    "district_id": "320539",
    "label": "Putrajawa",
    "value": "Putrajawa"
  },
  {
    "id": "3205392003",
    "district_id": "320539",
    "label": "Cigawir",
    "value": "Cigawir"
  },
  {
    "id": "3205392004",
    "district_id": "320539",
    "label": "Pelitaasih",
    "value": "Pelitaasih"
  },
  {
    "id": "3205392005",
    "district_id": "320539",
    "label": "Cirapuhan",
    "value": "Cirapuhan"
  },
  {
    "id": "3205392006",
    "district_id": "320539",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3205392007",
    "district_id": "320539",
    "label": "Samida",
    "value": "Samida"
  },
  {
    "id": "3205402001",
    "district_id": "320540",
    "label": "Cipareuan",
    "value": "Cipareuan"
  },
  {
    "id": "3205402002",
    "district_id": "320540",
    "label": "Cibiuk Kaler",
    "value": "Cibiuk Kaler"
  },
  {
    "id": "3205402003",
    "district_id": "320540",
    "label": "Cibiuk Kidul",
    "value": "Cibiuk Kidul"
  },
  {
    "id": "3205402004",
    "district_id": "320540",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3205402005",
    "district_id": "320540",
    "label": "Lingkungpasir",
    "value": "Lingkungpasir"
  },
  {
    "id": "3205412001",
    "district_id": "320541",
    "label": "Citangtu",
    "value": "Citangtu"
  },
  {
    "id": "3205412002",
    "district_id": "320541",
    "label": "Cimarabas",
    "value": "Cimarabas"
  },
  {
    "id": "3205412003",
    "district_id": "320541",
    "label": "Babakan Loa",
    "value": "Babakan Loa"
  },
  {
    "id": "3205412004",
    "district_id": "320541",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3205412005",
    "district_id": "320541",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3205412006",
    "district_id": "320541",
    "label": "Cihuni",
    "value": "Cihuni"
  },
  {
    "id": "3205412007",
    "district_id": "320541",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3205412008",
    "district_id": "320541",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3205422001",
    "district_id": "320542",
    "label": "Sadang",
    "value": "Sadang"
  },
  {
    "id": "3205422002",
    "district_id": "320542",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3205422003",
    "district_id": "320542",
    "label": "Tenjonagara",
    "value": "Tenjonagara"
  },
  {
    "id": "3205422004",
    "district_id": "320542",
    "label": "Tegalpanjang",
    "value": "Tegalpanjang"
  },
  {
    "id": "3205422005",
    "district_id": "320542",
    "label": "Cigadog",
    "value": "Cigadog"
  },
  {
    "id": "3205422006",
    "district_id": "320542",
    "label": "Linggamukti",
    "value": "Linggamukti"
  },
  {
    "id": "3205422007",
    "district_id": "320542",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3206012001",
    "district_id": "320601",
    "label": "Ciheras",
    "value": "Ciheras"
  },
  {
    "id": "3206012002",
    "district_id": "320601",
    "label": "Cipatujah",
    "value": "Cipatujah"
  },
  {
    "id": "3206012003",
    "district_id": "320601",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "3206012004",
    "district_id": "320601",
    "label": "Cikawunggading",
    "value": "Cikawunggading"
  },
  {
    "id": "3206012005",
    "district_id": "320601",
    "label": "Bantarkalong",
    "value": "Bantarkalong"
  },
  {
    "id": "3206012006",
    "district_id": "320601",
    "label": "Darawati",
    "value": "Darawati"
  },
  {
    "id": "3206012007",
    "district_id": "320601",
    "label": "Nagrog",
    "value": "Nagrog"
  },
  {
    "id": "3206012008",
    "district_id": "320601",
    "label": "Pameutingan",
    "value": "Pameutingan"
  },
  {
    "id": "3206012009",
    "district_id": "320601",
    "label": "Tobongjaya",
    "value": "Tobongjaya"
  },
  {
    "id": "3206012010",
    "district_id": "320601",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "3206012011",
    "district_id": "320601",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3206012012",
    "district_id": "320601",
    "label": "Ciandum",
    "value": "Ciandum"
  },
  {
    "id": "3206012013",
    "district_id": "320601",
    "label": "Nangelasari",
    "value": "Nangelasari"
  },
  {
    "id": "3206012014",
    "district_id": "320601",
    "label": "Padawaras",
    "value": "Padawaras"
  },
  {
    "id": "3206012015",
    "district_id": "320601",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3206022001",
    "district_id": "320602",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3206022002",
    "district_id": "320602",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3206022003",
    "district_id": "320602",
    "label": "Cikupa",
    "value": "Cikupa"
  },
  {
    "id": "3206022004",
    "district_id": "320602",
    "label": "Karangnunggal",
    "value": "Karangnunggal"
  },
  {
    "id": "3206022005",
    "district_id": "320602",
    "label": "Karangmekar",
    "value": "Karangmekar"
  },
  {
    "id": "3206022006",
    "district_id": "320602",
    "label": "Cikukulu",
    "value": "Cikukulu"
  },
  {
    "id": "3206022007",
    "district_id": "320602",
    "label": "Cibatuireng",
    "value": "Cibatuireng"
  },
  {
    "id": "3206022008",
    "district_id": "320602",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "3206022009",
    "district_id": "320602",
    "label": "Sarimanggu",
    "value": "Sarimanggu"
  },
  {
    "id": "3206022010",
    "district_id": "320602",
    "label": "Sukawangun",
    "value": "Sukawangun"
  },
  {
    "id": "3206022011",
    "district_id": "320602",
    "label": "Cintawangi",
    "value": "Cintawangi"
  },
  {
    "id": "3206022012",
    "district_id": "320602",
    "label": "Cikapinis",
    "value": "Cikapinis"
  },
  {
    "id": "3206022013",
    "district_id": "320602",
    "label": "Sarimukti",
    "value": "Sarimukti"
  },
  {
    "id": "3206022014",
    "district_id": "320602",
    "label": "Kujang",
    "value": "Kujang"
  },
  {
    "id": "3206032001",
    "district_id": "320603",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3206032002",
    "district_id": "320603",
    "label": "Kalapagenep",
    "value": "Kalapagenep"
  },
  {
    "id": "3206032003",
    "district_id": "320603",
    "label": "Cikancra",
    "value": "Cikancra"
  },
  {
    "id": "3206032004",
    "district_id": "320603",
    "label": "Singkir",
    "value": "Singkir"
  },
  {
    "id": "3206032005",
    "district_id": "320603",
    "label": "Panyiaran",
    "value": "Panyiaran"
  },
  {
    "id": "3206032006",
    "district_id": "320603",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3206032007",
    "district_id": "320603",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3206032008",
    "district_id": "320603",
    "label": "Mandalajaya",
    "value": "Mandalajaya"
  },
  {
    "id": "3206032009",
    "district_id": "320603",
    "label": "Cidadali",
    "value": "Cidadali"
  },
  {
    "id": "3206032010",
    "district_id": "320603",
    "label": "Cimanuk",
    "value": "Cimanuk"
  },
  {
    "id": "3206032011",
    "district_id": "320603",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3206032012",
    "district_id": "320603",
    "label": "Kubangsari",
    "value": "Kubangsari"
  },
  {
    "id": "3206032013",
    "district_id": "320603",
    "label": "Tonjongsari",
    "value": "Tonjongsari"
  },
  {
    "id": "3206042001",
    "district_id": "320604",
    "label": "Cibuniasih",
    "value": "Cibuniasih"
  },
  {
    "id": "3206042002",
    "district_id": "320604",
    "label": "Pangliaran",
    "value": "Pangliaran"
  },
  {
    "id": "3206042003",
    "district_id": "320604",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3206042004",
    "district_id": "320604",
    "label": "Cibongas",
    "value": "Cibongas"
  },
  {
    "id": "3206042005",
    "district_id": "320604",
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "id": "3206042006",
    "district_id": "320604",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3206042007",
    "district_id": "320604",
    "label": "Cikawung",
    "value": "Cikawung"
  },
  {
    "id": "3206042008",
    "district_id": "320604",
    "label": "Jayamukti",
    "value": "Jayamukti"
  },
  {
    "id": "3206042009",
    "district_id": "320604",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3206042010",
    "district_id": "320604",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3206042011",
    "district_id": "320604",
    "label": "Pancawangi",
    "value": "Pancawangi"
  },
  {
    "id": "3206052001",
    "district_id": "320605",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3206052002",
    "district_id": "320605",
    "label": "Cilumba",
    "value": "Cilumba"
  },
  {
    "id": "3206052003",
    "district_id": "320605",
    "label": "Pakemitan",
    "value": "Pakemitan"
  },
  {
    "id": "3206052004",
    "district_id": "320605",
    "label": "Cogreg",
    "value": "Cogreg"
  },
  {
    "id": "3206052005",
    "district_id": "320605",
    "label": "Cayur",
    "value": "Cayur"
  },
  {
    "id": "3206052006",
    "district_id": "320605",
    "label": "Lengkongbarang",
    "value": "Lengkongbarang"
  },
  {
    "id": "3206052007",
    "district_id": "320605",
    "label": "Sindangasih",
    "value": "Sindangasih"
  },
  {
    "id": "3206052008",
    "district_id": "320605",
    "label": "Tanjungbarang",
    "value": "Tanjungbarang"
  },
  {
    "id": "3206052009",
    "district_id": "320605",
    "label": "Linggalaksana",
    "value": "Linggalaksana"
  },
  {
    "id": "3206062001",
    "district_id": "320606",
    "label": "Cisempur",
    "value": "Cisempur"
  },
  {
    "id": "3206062002",
    "district_id": "320606",
    "label": "Setiawaras",
    "value": "Setiawaras"
  },
  {
    "id": "3206062003",
    "district_id": "320606",
    "label": "Eureunpalay",
    "value": "Eureunpalay"
  },
  {
    "id": "3206062004",
    "district_id": "320606",
    "label": "Cibalong",
    "value": "Cibalong"
  },
  {
    "id": "3206062005",
    "district_id": "320606",
    "label": "Singajaya",
    "value": "Singajaya"
  },
  {
    "id": "3206062006",
    "district_id": "320606",
    "label": "Parung",
    "value": "Parung"
  },
  {
    "id": "3206072001",
    "district_id": "320607",
    "label": "Parungponteng",
    "value": "Parungponteng"
  },
  {
    "id": "3206072002",
    "district_id": "320607",
    "label": "Cigunung",
    "value": "Cigunung"
  },
  {
    "id": "3206072003",
    "district_id": "320607",
    "label": "Cibanteng",
    "value": "Cibanteng"
  },
  {
    "id": "3206072004",
    "district_id": "320607",
    "label": "Barumekar",
    "value": "Barumekar"
  },
  {
    "id": "3206072005",
    "district_id": "320607",
    "label": "Cibungur",
    "value": "Cibungur"
  },
  {
    "id": "3206072006",
    "district_id": "320607",
    "label": "Burujuljaya",
    "value": "Burujuljaya"
  },
  {
    "id": "3206072007",
    "district_id": "320607",
    "label": "Girikencana",
    "value": "Girikencana"
  },
  {
    "id": "3206072008",
    "district_id": "320607",
    "label": "Karyabakti",
    "value": "Karyabakti"
  },
  {
    "id": "3206082001",
    "district_id": "320608",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3206082002",
    "district_id": "320608",
    "label": "Parakanhonje",
    "value": "Parakanhonje"
  },
  {
    "id": "3206082003",
    "district_id": "320608",
    "label": "Pamijahan",
    "value": "Pamijahan"
  },
  {
    "id": "3206082004",
    "district_id": "320608",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3206082005",
    "district_id": "320608",
    "label": "Wangunsari",
    "value": "Wangunsari"
  },
  {
    "id": "3206082006",
    "district_id": "320608",
    "label": "Hegarwangi",
    "value": "Hegarwangi"
  },
  {
    "id": "3206082007",
    "district_id": "320608",
    "label": "Wakap",
    "value": "Wakap"
  },
  {
    "id": "3206082008",
    "district_id": "320608",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3206092001",
    "district_id": "320609",
    "label": "Mertajaya",
    "value": "Mertajaya"
  },
  {
    "id": "3206092002",
    "district_id": "320609",
    "label": "Cikadongdong",
    "value": "Cikadongdong"
  },
  {
    "id": "3206092003",
    "district_id": "320609",
    "label": "Bojongasih",
    "value": "Bojongasih"
  },
  {
    "id": "3206092004",
    "district_id": "320609",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3206092005",
    "district_id": "320609",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3206092006",
    "district_id": "320609",
    "label": "Toblongan",
    "value": "Toblongan"
  },
  {
    "id": "3206102001",
    "district_id": "320610",
    "label": "Cikuya",
    "value": "Cikuya"
  },
  {
    "id": "3206102002",
    "district_id": "320610",
    "label": "Cintabodas",
    "value": "Cintabodas"
  },
  {
    "id": "3206102003",
    "district_id": "320610",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3206102004",
    "district_id": "320610",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3206102005",
    "district_id": "320610",
    "label": "Mekarlaksana",
    "value": "Mekarlaksana"
  },
  {
    "id": "3206112001",
    "district_id": "320611",
    "label": "Bojongkapol",
    "value": "Bojongkapol"
  },
  {
    "id": "3206112002",
    "district_id": "320611",
    "label": "Pedangkamulyan",
    "value": "Pedangkamulyan"
  },
  {
    "id": "3206112003",
    "district_id": "320611",
    "label": "Bojonggambir",
    "value": "Bojonggambir"
  },
  {
    "id": "3206112004",
    "district_id": "320611",
    "label": "Ciroyom",
    "value": "Ciroyom"
  },
  {
    "id": "3206112005",
    "district_id": "320611",
    "label": "Wandasari",
    "value": "Wandasari"
  },
  {
    "id": "3206112006",
    "district_id": "320611",
    "label": "Campakasari",
    "value": "Campakasari"
  },
  {
    "id": "3206112007",
    "district_id": "320611",
    "label": "Mangkonjaya",
    "value": "Mangkonjaya"
  },
  {
    "id": "3206112008",
    "district_id": "320611",
    "label": "Kertanegla",
    "value": "Kertanegla"
  },
  {
    "id": "3206112009",
    "district_id": "320611",
    "label": "Purwaraharja",
    "value": "Purwaraharja"
  },
  {
    "id": "3206112010",
    "district_id": "320611",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3206122001",
    "district_id": "320612",
    "label": "Parumasan",
    "value": "Parumasan"
  },
  {
    "id": "3206122002",
    "district_id": "320612",
    "label": "Cukangkawung",
    "value": "Cukangkawung"
  },
  {
    "id": "3206122003",
    "district_id": "320612",
    "label": "Sodonghilir",
    "value": "Sodonghilir"
  },
  {
    "id": "3206122004",
    "district_id": "320612",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3206122005",
    "district_id": "320612",
    "label": "Cipaingeun",
    "value": "Cipaingeun"
  },
  {
    "id": "3206122006",
    "district_id": "320612",
    "label": "Leuwidulang",
    "value": "Leuwidulang"
  },
  {
    "id": "3206122007",
    "district_id": "320612",
    "label": "Muncang",
    "value": "Muncang"
  },
  {
    "id": "3206122008",
    "district_id": "320612",
    "label": "Sepatnunggal",
    "value": "Sepatnunggal"
  },
  {
    "id": "3206122009",
    "district_id": "320612",
    "label": "Cukangjayaguna",
    "value": "Cukangjayaguna"
  },
  {
    "id": "3206122010",
    "district_id": "320612",
    "label": "Raksajaya",
    "value": "Raksajaya"
  },
  {
    "id": "3206122011",
    "district_id": "320612",
    "label": "Pakalongan",
    "value": "Pakalongan"
  },
  {
    "id": "3206122012",
    "district_id": "320612",
    "label": "Sukabakti",
    "value": "Sukabakti"
  },
  {
    "id": "3206132001",
    "district_id": "320613",
    "label": "Taraju",
    "value": "Taraju"
  },
  {
    "id": "3206132002",
    "district_id": "320613",
    "label": "Cikubang",
    "value": "Cikubang"
  },
  {
    "id": "3206132003",
    "district_id": "320613",
    "label": "Deudeul",
    "value": "Deudeul"
  },
  {
    "id": "3206132004",
    "district_id": "320613",
    "label": "Purwarahayu",
    "value": "Purwarahayu"
  },
  {
    "id": "3206132005",
    "district_id": "320613",
    "label": "Singasari",
    "value": "Singasari"
  },
  {
    "id": "3206132006",
    "district_id": "320613",
    "label": "Banyuasih",
    "value": "Banyuasih"
  },
  {
    "id": "3206132007",
    "district_id": "320613",
    "label": "Raksasari",
    "value": "Raksasari"
  },
  {
    "id": "3206132008",
    "district_id": "320613",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3206132009",
    "district_id": "320613",
    "label": "Pageralam",
    "value": "Pageralam"
  },
  {
    "id": "3206142001",
    "district_id": "320614",
    "label": "Jahiang",
    "value": "Jahiang"
  },
  {
    "id": "3206142002",
    "district_id": "320614",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3206142003",
    "district_id": "320614",
    "label": "Salawu",
    "value": "Salawu"
  },
  {
    "id": "3206142004",
    "district_id": "320614",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3206142005",
    "district_id": "320614",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3206142006",
    "district_id": "320614",
    "label": "Tenjowaringin",
    "value": "Tenjowaringin"
  },
  {
    "id": "3206142007",
    "district_id": "320614",
    "label": "Sundawenang",
    "value": "Sundawenang"
  },
  {
    "id": "3206142008",
    "district_id": "320614",
    "label": "Kawungsari",
    "value": "Kawungsari"
  },
  {
    "id": "3206142009",
    "district_id": "320614",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3206142010",
    "district_id": "320614",
    "label": "Kutawaringin",
    "value": "Kutawaringin"
  },
  {
    "id": "3206142011",
    "district_id": "320614",
    "label": "Karangmukti",
    "value": "Karangmukti"
  },
  {
    "id": "3206142012",
    "district_id": "320614",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3206152001",
    "district_id": "320615",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3206152002",
    "district_id": "320615",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3206152003",
    "district_id": "320615",
    "label": "Puspasari",
    "value": "Puspasari"
  },
  {
    "id": "3206152004",
    "district_id": "320615",
    "label": "Puspahiang",
    "value": "Puspahiang"
  },
  {
    "id": "3206152005",
    "district_id": "320615",
    "label": "Luyubakti",
    "value": "Luyubakti"
  },
  {
    "id": "3206152006",
    "district_id": "320615",
    "label": "Pusparahayu",
    "value": "Pusparahayu"
  },
  {
    "id": "3206152007",
    "district_id": "320615",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3206152008",
    "district_id": "320615",
    "label": "Puspajaya",
    "value": "Puspajaya"
  },
  {
    "id": "3206162001",
    "district_id": "320616",
    "label": "Cikeusal",
    "value": "Cikeusal"
  },
  {
    "id": "3206162002",
    "district_id": "320616",
    "label": "Cibalanarik",
    "value": "Cibalanarik"
  },
  {
    "id": "3206162003",
    "district_id": "320616",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3206162004",
    "district_id": "320616",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3206162005",
    "district_id": "320616",
    "label": "Cilolohan",
    "value": "Cilolohan"
  },
  {
    "id": "3206162006",
    "district_id": "320616",
    "label": "Cintajaya",
    "value": "Cintajaya"
  },
  {
    "id": "3206162007",
    "district_id": "320616",
    "label": "Sukasenang",
    "value": "Sukasenang"
  },
  {
    "id": "3206172001",
    "district_id": "320617",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3206172002",
    "district_id": "320617",
    "label": "Leuwibudah",
    "value": "Leuwibudah"
  },
  {
    "id": "3206172003",
    "district_id": "320617",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3206172004",
    "district_id": "320617",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3206172005",
    "district_id": "320617",
    "label": "Linggaraja",
    "value": "Linggaraja"
  },
  {
    "id": "3206172006",
    "district_id": "320617",
    "label": "Janggala",
    "value": "Janggala"
  },
  {
    "id": "3206172007",
    "district_id": "320617",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3206172008",
    "district_id": "320617",
    "label": "Tarunajaya",
    "value": "Tarunajaya"
  },
  {
    "id": "3206182001",
    "district_id": "320618",
    "label": "Mandalahayu",
    "value": "Mandalahayu"
  },
  {
    "id": "3206182002",
    "district_id": "320618",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3206182003",
    "district_id": "320618",
    "label": "Kawitan",
    "value": "Kawitan"
  },
  {
    "id": "3206182004",
    "district_id": "320618",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "3206182005",
    "district_id": "320618",
    "label": "Karyawangi",
    "value": "Karyawangi"
  },
  {
    "id": "3206182006",
    "district_id": "320618",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3206182007",
    "district_id": "320618",
    "label": "Mandalaguna",
    "value": "Mandalaguna"
  },
  {
    "id": "3206182008",
    "district_id": "320618",
    "label": "Karyamandala",
    "value": "Karyamandala"
  },
  {
    "id": "3206182009",
    "district_id": "320618",
    "label": "Banjarwaringin",
    "value": "Banjarwaringin"
  },
  {
    "id": "3206192001",
    "district_id": "320619",
    "label": "Kaputihan",
    "value": "Kaputihan"
  },
  {
    "id": "3206192002",
    "district_id": "320619",
    "label": "Setiawangi",
    "value": "Setiawangi"
  },
  {
    "id": "3206192003",
    "district_id": "320619",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3206192004",
    "district_id": "320619",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3206192005",
    "district_id": "320619",
    "label": "Jatiwaras",
    "value": "Jatiwaras"
  },
  {
    "id": "3206192006",
    "district_id": "320619",
    "label": "Papayan",
    "value": "Papayan"
  },
  {
    "id": "3206192007",
    "district_id": "320619",
    "label": "Ciwarak",
    "value": "Ciwarak"
  },
  {
    "id": "3206192008",
    "district_id": "320619",
    "label": "Kersagalih",
    "value": "Kersagalih"
  },
  {
    "id": "3206192009",
    "district_id": "320619",
    "label": "Mandalamekar",
    "value": "Mandalamekar"
  },
  {
    "id": "3206192010",
    "district_id": "320619",
    "label": "Kertarahayu",
    "value": "Kertarahayu"
  },
  {
    "id": "3206192011",
    "district_id": "320619",
    "label": "Mandalahurip",
    "value": "Mandalahurip"
  },
  {
    "id": "3206202001",
    "district_id": "320620",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3206202002",
    "district_id": "320620",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3206202003",
    "district_id": "320620",
    "label": "Cijulang",
    "value": "Cijulang"
  },
  {
    "id": "3206202004",
    "district_id": "320620",
    "label": "Ciampanan",
    "value": "Ciampanan"
  },
  {
    "id": "3206202005",
    "district_id": "320620",
    "label": "Cineam",
    "value": "Cineam"
  },
  {
    "id": "3206202006",
    "district_id": "320620",
    "label": "Rajadatu",
    "value": "Rajadatu"
  },
  {
    "id": "3206202007",
    "district_id": "320620",
    "label": "Ancol",
    "value": "Ancol"
  },
  {
    "id": "3206202008",
    "district_id": "320620",
    "label": "Nagaratengah",
    "value": "Nagaratengah"
  },
  {
    "id": "3206202009",
    "district_id": "320620",
    "label": "Pasirmukti",
    "value": "Pasirmukti"
  },
  {
    "id": "3206202010",
    "district_id": "320620",
    "label": "Madiasari",
    "value": "Madiasari"
  },
  {
    "id": "3206212001",
    "district_id": "320621",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3206212002",
    "district_id": "320621",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "3206212003",
    "district_id": "320621",
    "label": "Karanglayung",
    "value": "Karanglayung"
  },
  {
    "id": "3206212004",
    "district_id": "320621",
    "label": "Citalahab",
    "value": "Citalahab"
  },
  {
    "id": "3206222001",
    "district_id": "320622",
    "label": "Cihaur",
    "value": "Cihaur"
  },
  {
    "id": "3206222002",
    "district_id": "320622",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3206222003",
    "district_id": "320622",
    "label": "Pasirpanjang",
    "value": "Pasirpanjang"
  },
  {
    "id": "3206222004",
    "district_id": "320622",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3206222005",
    "district_id": "320622",
    "label": "Kamulyan",
    "value": "Kamulyan"
  },
  {
    "id": "3206222006",
    "district_id": "320622",
    "label": "Manonjaya",
    "value": "Manonjaya"
  },
  {
    "id": "3206222007",
    "district_id": "320622",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3206222008",
    "district_id": "320622",
    "label": "Pasirbatang",
    "value": "Pasirbatang"
  },
  {
    "id": "3206222009",
    "district_id": "320622",
    "label": "Kalimanggis",
    "value": "Kalimanggis"
  },
  {
    "id": "3206222010",
    "district_id": "320622",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "3206222011",
    "district_id": "320622",
    "label": "Batusumur",
    "value": "Batusumur"
  },
  {
    "id": "3206222012",
    "district_id": "320622",
    "label": "Gunajaya",
    "value": "Gunajaya"
  },
  {
    "id": "3206232001",
    "district_id": "320623",
    "label": "Cinunjang",
    "value": "Cinunjang"
  },
  {
    "id": "3206232002",
    "district_id": "320623",
    "label": "Gunungtanjung",
    "value": "Gunungtanjung"
  },
  {
    "id": "3206232003",
    "district_id": "320623",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3206232004",
    "district_id": "320623",
    "label": "Jatijaya",
    "value": "Jatijaya"
  },
  {
    "id": "3206232005",
    "district_id": "320623",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3206232006",
    "district_id": "320623",
    "label": "Giriwangi",
    "value": "Giriwangi"
  },
  {
    "id": "3206232007",
    "district_id": "320623",
    "label": "Malatisuka",
    "value": "Malatisuka"
  },
  {
    "id": "3206242001",
    "district_id": "320624",
    "label": "Cikunten",
    "value": "Cikunten"
  },
  {
    "id": "3206242002",
    "district_id": "320624",
    "label": "Singaparna",
    "value": "Singaparna"
  },
  {
    "id": "3206242003",
    "district_id": "320624",
    "label": "Cipakat",
    "value": "Cipakat"
  },
  {
    "id": "3206242004",
    "district_id": "320624",
    "label": "Cintaraja",
    "value": "Cintaraja"
  },
  {
    "id": "3206242005",
    "district_id": "320624",
    "label": "Cikunir",
    "value": "Cikunir"
  },
  {
    "id": "3206242006",
    "district_id": "320624",
    "label": "Cokadongdong",
    "value": "Cokadongdong"
  },
  {
    "id": "3206242007",
    "district_id": "320624",
    "label": "Sukaasih",
    "value": "Sukaasih"
  },
  {
    "id": "3206242008",
    "district_id": "320624",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3206242009",
    "district_id": "320624",
    "label": "Singasari",
    "value": "Singasari"
  },
  {
    "id": "3206242010",
    "district_id": "320624",
    "label": "Sukaherang",
    "value": "Sukaherang"
  },
  {
    "id": "3206252001",
    "district_id": "320625",
    "label": "Sukasukur",
    "value": "Sukasukur"
  },
  {
    "id": "3206252002",
    "district_id": "320625",
    "label": "Salebu",
    "value": "Salebu"
  },
  {
    "id": "3206252003",
    "district_id": "320625",
    "label": "Mangunreja",
    "value": "Mangunreja"
  },
  {
    "id": "3206252004",
    "district_id": "320625",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3206252005",
    "district_id": "320625",
    "label": "Pasirsalam",
    "value": "Pasirsalam"
  },
  {
    "id": "3206252006",
    "district_id": "320625",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3206262001",
    "district_id": "320626",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3206262002",
    "district_id": "320626",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3206262003",
    "district_id": "320626",
    "label": "Sukakarsa",
    "value": "Sukakarsa"
  },
  {
    "id": "3206262004",
    "district_id": "320626",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3206262005",
    "district_id": "320626",
    "label": "Sukarapih",
    "value": "Sukarapih"
  },
  {
    "id": "3206262006",
    "district_id": "320626",
    "label": "Wargakerta",
    "value": "Wargakerta"
  },
  {
    "id": "3206272001",
    "district_id": "320627",
    "label": "Kersamaju",
    "value": "Kersamaju"
  },
  {
    "id": "3206272002",
    "district_id": "320627",
    "label": "Nangtang",
    "value": "Nangtang"
  },
  {
    "id": "3206272003",
    "district_id": "320627",
    "label": "Pusparaja",
    "value": "Pusparaja"
  },
  {
    "id": "3206272004",
    "district_id": "320627",
    "label": "Jayapura",
    "value": "Jayapura"
  },
  {
    "id": "3206272005",
    "district_id": "320627",
    "label": "Lengkongjaya",
    "value": "Lengkongjaya"
  },
  {
    "id": "3206272006",
    "district_id": "320627",
    "label": "Nangerang",
    "value": "Nangerang"
  },
  {
    "id": "3206272007",
    "district_id": "320627",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3206272008",
    "district_id": "320627",
    "label": "Sirnaraja",
    "value": "Sirnaraja"
  },
  {
    "id": "3206272009",
    "district_id": "320627",
    "label": "Cidugaleun",
    "value": "Cidugaleun"
  },
  {
    "id": "3206272010",
    "district_id": "320627",
    "label": "Parentas",
    "value": "Parentas"
  },
  {
    "id": "3206272011",
    "district_id": "320627",
    "label": "Puspamukti",
    "value": "Puspamukti"
  },
  {
    "id": "3206272012",
    "district_id": "320627",
    "label": "Tenjonagara",
    "value": "Tenjonagara"
  },
  {
    "id": "3206272013",
    "district_id": "320627",
    "label": "Cigalontang",
    "value": "Cigalontang"
  },
  {
    "id": "3206272014",
    "district_id": "320627",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3206272015",
    "district_id": "320627",
    "label": "Tanjungkarang",
    "value": "Tanjungkarang"
  },
  {
    "id": "3206272016",
    "district_id": "320627",
    "label": "Sirnaputra",
    "value": "Sirnaputra"
  },
  {
    "id": "3206282001",
    "district_id": "320628",
    "label": "Arjasari",
    "value": "Arjasari"
  },
  {
    "id": "3206282002",
    "district_id": "320628",
    "label": "Ciawang",
    "value": "Ciawang"
  },
  {
    "id": "3206282003",
    "district_id": "320628",
    "label": "Cigadog",
    "value": "Cigadog"
  },
  {
    "id": "3206282004",
    "district_id": "320628",
    "label": "Linggawangi",
    "value": "Linggawangi"
  },
  {
    "id": "3206282005",
    "district_id": "320628",
    "label": "Jayamukti",
    "value": "Jayamukti"
  },
  {
    "id": "3206282006",
    "district_id": "320628",
    "label": "Mandalagiri",
    "value": "Mandalagiri"
  },
  {
    "id": "3206282007",
    "district_id": "320628",
    "label": "Linggamulya",
    "value": "Linggamulya"
  },
  {
    "id": "3206292001",
    "district_id": "320629",
    "label": "Cilampunghilir",
    "value": "Cilampunghilir"
  },
  {
    "id": "3206292002",
    "district_id": "320629",
    "label": "Rancapaku",
    "value": "Rancapaku"
  },
  {
    "id": "3206292003",
    "district_id": "320629",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3206292004",
    "district_id": "320629",
    "label": "Cisaruni",
    "value": "Cisaruni"
  },
  {
    "id": "3206292005",
    "district_id": "320629",
    "label": "Padakembang",
    "value": "Padakembang"
  },
  {
    "id": "3206302001",
    "district_id": "320630",
    "label": "Sariwangi",
    "value": "Sariwangi"
  },
  {
    "id": "3206302002",
    "district_id": "320630",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3206302003",
    "district_id": "320630",
    "label": "Jayaratu",
    "value": "Jayaratu"
  },
  {
    "id": "3206302004",
    "district_id": "320630",
    "label": "Linggasirna",
    "value": "Linggasirna"
  },
  {
    "id": "3206302005",
    "district_id": "320630",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3206302006",
    "district_id": "320630",
    "label": "Sukamulih",
    "value": "Sukamulih"
  },
  {
    "id": "3206302007",
    "district_id": "320630",
    "label": "Selawangi",
    "value": "Selawangi"
  },
  {
    "id": "3206302008",
    "district_id": "320630",
    "label": "Jayaputra",
    "value": "Jayaputra"
  },
  {
    "id": "3206312001",
    "district_id": "320631",
    "label": "Linggajati",
    "value": "Linggajati"
  },
  {
    "id": "3206312002",
    "district_id": "320631",
    "label": "Tawangbanteng",
    "value": "Tawangbanteng"
  },
  {
    "id": "3206312003",
    "district_id": "320631",
    "label": "Sinagar",
    "value": "Sinagar"
  },
  {
    "id": "3206312004",
    "district_id": "320631",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3206312005",
    "district_id": "320631",
    "label": "Sukamahi",
    "value": "Sukamahi"
  },
  {
    "id": "3206312006",
    "district_id": "320631",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3206312007",
    "district_id": "320631",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3206312008",
    "district_id": "320631",
    "label": "Indrajaya",
    "value": "Indrajaya"
  },
  {
    "id": "3206322001",
    "district_id": "320632",
    "label": "Cisayong",
    "value": "Cisayong"
  },
  {
    "id": "3206322002",
    "district_id": "320632",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3206322003",
    "district_id": "320632",
    "label": "Sukasukur",
    "value": "Sukasukur"
  },
  {
    "id": "3206322004",
    "district_id": "320632",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3206322005",
    "district_id": "320632",
    "label": "Nusawangi",
    "value": "Nusawangi"
  },
  {
    "id": "3206322006",
    "district_id": "320632",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3206322007",
    "district_id": "320632",
    "label": "Cileuleus",
    "value": "Cileuleus"
  },
  {
    "id": "3206322008",
    "district_id": "320632",
    "label": "Jatihurip",
    "value": "Jatihurip"
  },
  {
    "id": "3206322009",
    "district_id": "320632",
    "label": "Sukasetia",
    "value": "Sukasetia"
  },
  {
    "id": "3206322010",
    "district_id": "320632",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3206322011",
    "district_id": "320632",
    "label": "Sukaraharja",
    "value": "Sukaraharja"
  },
  {
    "id": "3206322012",
    "district_id": "320632",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3206322013",
    "district_id": "320632",
    "label": "Santanamekar",
    "value": "Santanamekar"
  },
  {
    "id": "3206332001",
    "district_id": "320633",
    "label": "Banyurasa",
    "value": "Banyurasa"
  },
  {
    "id": "3206332002",
    "district_id": "320633",
    "label": "Calincing",
    "value": "Calincing"
  },
  {
    "id": "3206332003",
    "district_id": "320633",
    "label": "Sukahening",
    "value": "Sukahening"
  },
  {
    "id": "3206332004",
    "district_id": "320633",
    "label": "Kiarajangkung",
    "value": "Kiarajangkung"
  },
  {
    "id": "3206332005",
    "district_id": "320633",
    "label": "Kudadepa",
    "value": "Kudadepa"
  },
  {
    "id": "3206332006",
    "district_id": "320633",
    "label": "Banyuresmi",
    "value": "Banyuresmi"
  },
  {
    "id": "3206332007",
    "district_id": "320633",
    "label": "Sundakerta",
    "value": "Sundakerta"
  },
  {
    "id": "3206342001",
    "district_id": "320634",
    "label": "Dawagung",
    "value": "Dawagung"
  },
  {
    "id": "3206342002",
    "district_id": "320634",
    "label": "Rajapolah",
    "value": "Rajapolah"
  },
  {
    "id": "3206342003",
    "district_id": "320634",
    "label": "Manggungjaya",
    "value": "Manggungjaya"
  },
  {
    "id": "3206342004",
    "district_id": "320634",
    "label": "Manggungsari",
    "value": "Manggungsari"
  },
  {
    "id": "3206342005",
    "district_id": "320634",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3206342006",
    "district_id": "320634",
    "label": "Rajamandala",
    "value": "Rajamandala"
  },
  {
    "id": "3206342007",
    "district_id": "320634",
    "label": "Sukanagalih",
    "value": "Sukanagalih"
  },
  {
    "id": "3206342008",
    "district_id": "320634",
    "label": "Tanjungpura",
    "value": "Tanjungpura"
  },
  {
    "id": "3206352001",
    "district_id": "320635",
    "label": "Condong",
    "value": "Condong"
  },
  {
    "id": "3206352002",
    "district_id": "320635",
    "label": "Bojonggaok",
    "value": "Bojonggaok"
  },
  {
    "id": "3206352003",
    "district_id": "320635",
    "label": "Sindangraja",
    "value": "Sindangraja"
  },
  {
    "id": "3206352004",
    "district_id": "320635",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3206352005",
    "district_id": "320635",
    "label": "Geresik",
    "value": "Geresik"
  },
  {
    "id": "3206352006",
    "district_id": "320635",
    "label": "Karangsembung",
    "value": "Karangsembung"
  },
  {
    "id": "3206352007",
    "district_id": "320635",
    "label": "Tanjungmekar",
    "value": "Tanjungmekar"
  },
  {
    "id": "3206352008",
    "district_id": "320635",
    "label": "Karangresik",
    "value": "Karangresik"
  },
  {
    "id": "3206362001",
    "district_id": "320636",
    "label": "Gombong",
    "value": "Gombong"
  },
  {
    "id": "3206362002",
    "district_id": "320636",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3206362003",
    "district_id": "320636",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3206362004",
    "district_id": "320636",
    "label": "Pakemitan",
    "value": "Pakemitan"
  },
  {
    "id": "3206362005",
    "district_id": "320636",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3206362006",
    "district_id": "320636",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3206362007",
    "district_id": "320636",
    "label": "Pasirhuni",
    "value": "Pasirhuni"
  },
  {
    "id": "3206362008",
    "district_id": "320636",
    "label": "Citamba",
    "value": "Citamba"
  },
  {
    "id": "3206362009",
    "district_id": "320636",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3206362010",
    "district_id": "320636",
    "label": "Kurniabakti",
    "value": "Kurniabakti"
  },
  {
    "id": "3206362011",
    "district_id": "320636",
    "label": "Pakamitankidul",
    "value": "Pakamitankidul"
  },
  {
    "id": "3206372001",
    "district_id": "320637",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3206372002",
    "district_id": "320637",
    "label": "Dirgahayu",
    "value": "Dirgahayu"
  },
  {
    "id": "3206372003",
    "district_id": "320637",
    "label": "Cibahayu",
    "value": "Cibahayu"
  },
  {
    "id": "3206372004",
    "district_id": "320637",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3206372005",
    "district_id": "320637",
    "label": "Buniasih",
    "value": "Buniasih"
  },
  {
    "id": "3206372006",
    "district_id": "320637",
    "label": "Pamoyanan",
    "value": "Pamoyanan"
  },
  {
    "id": "3206382001",
    "district_id": "320638",
    "label": "Cipacing",
    "value": "Cipacing"
  },
  {
    "id": "3206382002",
    "district_id": "320638",
    "label": "Pagerageung",
    "value": "Pagerageung"
  },
  {
    "id": "3206382003",
    "district_id": "320638",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3206382004",
    "district_id": "320638",
    "label": "Tanjungkerta",
    "value": "Tanjungkerta"
  },
  {
    "id": "3206382005",
    "district_id": "320638",
    "label": "Puteran",
    "value": "Puteran"
  },
  {
    "id": "3206382006",
    "district_id": "320638",
    "label": "Guranteng",
    "value": "Guranteng"
  },
  {
    "id": "3206382007",
    "district_id": "320638",
    "label": "Nanggewer",
    "value": "Nanggewer"
  },
  {
    "id": "3206382008",
    "district_id": "320638",
    "label": "Sukapada",
    "value": "Sukapada"
  },
  {
    "id": "3206382009",
    "district_id": "320638",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3206382010",
    "district_id": "320638",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3206392001",
    "district_id": "320639",
    "label": "Cipondok",
    "value": "Cipondok"
  },
  {
    "id": "3206392002",
    "district_id": "320639",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3206392003",
    "district_id": "320639",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3206392004",
    "district_id": "320639",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3206392005",
    "district_id": "320639",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3206392006",
    "district_id": "320639",
    "label": "Sukapancar",
    "value": "Sukapancar"
  },
  {
    "id": "3206392007",
    "district_id": "320639",
    "label": "Sukaresik",
    "value": "Sukaresik"
  },
  {
    "id": "3206392008",
    "district_id": "320639",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3207011001",
    "district_id": "320701",
    "label": "Ciamis",
    "value": "Ciamis"
  },
  {
    "id": "3207011002",
    "district_id": "320701",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3207011003",
    "district_id": "320701",
    "label": "Maleber",
    "value": "Maleber"
  },
  {
    "id": "3207011004",
    "district_id": "320701",
    "label": "Cigembor",
    "value": "Cigembor"
  },
  {
    "id": "3207011005",
    "district_id": "320701",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "3207011006",
    "district_id": "320701",
    "label": "Linggasari",
    "value": "Linggasari"
  },
  {
    "id": "3207011007",
    "district_id": "320701",
    "label": "Sindangrasa",
    "value": "Sindangrasa"
  },
  {
    "id": "3207012008",
    "district_id": "320701",
    "label": "Pawindan",
    "value": "Pawindan"
  },
  {
    "id": "3207012009",
    "district_id": "320701",
    "label": "Cisadap",
    "value": "Cisadap"
  },
  {
    "id": "3207012010",
    "district_id": "320701",
    "label": "Imbanagara",
    "value": "Imbanagara"
  },
  {
    "id": "3207012011",
    "district_id": "320701",
    "label": "Imbanagara Raya",
    "value": "Imbanagara Raya"
  },
  {
    "id": "3207012012",
    "district_id": "320701",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "3207022001",
    "district_id": "320702",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3207022002",
    "district_id": "320702",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3207022003",
    "district_id": "320702",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3207022011",
    "district_id": "320702",
    "label": "Panaragan",
    "value": "Panaragan"
  },
  {
    "id": "3207022012",
    "district_id": "320702",
    "label": "Darmacaang",
    "value": "Darmacaang"
  },
  {
    "id": "3207022013",
    "district_id": "320702",
    "label": "Kujang",
    "value": "Kujang"
  },
  {
    "id": "3207022014",
    "district_id": "320702",
    "label": "Nasol",
    "value": "Nasol"
  },
  {
    "id": "3207022016",
    "district_id": "320702",
    "label": "Cimari",
    "value": "Cimari"
  },
  {
    "id": "3207022017",
    "district_id": "320702",
    "label": "Gegempalan",
    "value": "Gegempalan"
  },
  {
    "id": "3207032001",
    "district_id": "320703",
    "label": "Handapherang",
    "value": "Handapherang"
  },
  {
    "id": "3207032002",
    "district_id": "320703",
    "label": "Ciharalang",
    "value": "Ciharalang"
  },
  {
    "id": "3207032003",
    "district_id": "320703",
    "label": "Bojongmengger",
    "value": "Bojongmengger"
  },
  {
    "id": "3207032004",
    "district_id": "320703",
    "label": "Karangkamulyan",
    "value": "Karangkamulyan"
  },
  {
    "id": "3207032005",
    "district_id": "320703",
    "label": "Kertabumi",
    "value": "Kertabumi"
  },
  {
    "id": "3207032006",
    "district_id": "320703",
    "label": "Cijeungjing",
    "value": "Cijeungjing"
  },
  {
    "id": "3207032007",
    "district_id": "320703",
    "label": "Pamalayan",
    "value": "Pamalayan"
  },
  {
    "id": "3207032008",
    "district_id": "320703",
    "label": "Dewasari",
    "value": "Dewasari"
  },
  {
    "id": "3207032009",
    "district_id": "320703",
    "label": "Utama",
    "value": "Utama"
  },
  {
    "id": "3207032010",
    "district_id": "320703",
    "label": "Kertaharja",
    "value": "Kertaharja"
  },
  {
    "id": "3207032011",
    "district_id": "320703",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3207042001",
    "district_id": "320704",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3207042002",
    "district_id": "320704",
    "label": "Sadananya",
    "value": "Sadananya"
  },
  {
    "id": "3207042003",
    "district_id": "320704",
    "label": "Werasari",
    "value": "Werasari"
  },
  {
    "id": "3207042004",
    "district_id": "320704",
    "label": "Mangkubumi",
    "value": "Mangkubumi"
  },
  {
    "id": "3207042005",
    "district_id": "320704",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3207042006",
    "district_id": "320704",
    "label": "Bendasari",
    "value": "Bendasari"
  },
  {
    "id": "3207042007",
    "district_id": "320704",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3207042008",
    "district_id": "320704",
    "label": "Mekarjadi",
    "value": "Mekarjadi"
  },
  {
    "id": "3207052001",
    "district_id": "320705",
    "label": "Cidolog",
    "value": "Cidolog"
  },
  {
    "id": "3207052002",
    "district_id": "320705",
    "label": "Janggala",
    "value": "Janggala"
  },
  {
    "id": "3207052003",
    "district_id": "320705",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3207052004",
    "district_id": "320705",
    "label": "Jelegong",
    "value": "Jelegong"
  },
  {
    "id": "3207052005",
    "district_id": "320705",
    "label": "Ciparay",
    "value": "Ciparay"
  },
  {
    "id": "3207052006",
    "district_id": "320705",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3207062001",
    "district_id": "320706",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3207062002",
    "district_id": "320706",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3207062003",
    "district_id": "320706",
    "label": "Cijulang",
    "value": "Cijulang"
  },
  {
    "id": "3207062004",
    "district_id": "320706",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3207062005",
    "district_id": "320706",
    "label": "Sukasetia",
    "value": "Sukasetia"
  },
  {
    "id": "3207062006",
    "district_id": "320706",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3207062007",
    "district_id": "320706",
    "label": "Cihaurbeuti",
    "value": "Cihaurbeuti"
  },
  {
    "id": "3207062008",
    "district_id": "320706",
    "label": "Pasirtamiang",
    "value": "Pasirtamiang"
  },
  {
    "id": "3207062009",
    "district_id": "320706",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3207062010",
    "district_id": "320706",
    "label": "Pamokolan",
    "value": "Pamokolan"
  },
  {
    "id": "3207062011",
    "district_id": "320706",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3207072001",
    "district_id": "320707",
    "label": "Medanglayang",
    "value": "Medanglayang"
  },
  {
    "id": "3207072002",
    "district_id": "320707",
    "label": "Panumbangan",
    "value": "Panumbangan"
  },
  {
    "id": "3207072003",
    "district_id": "320707",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3207072004",
    "district_id": "320707",
    "label": "Golat",
    "value": "Golat"
  },
  {
    "id": "3207072005",
    "district_id": "320707",
    "label": "Sindangherang",
    "value": "Sindangherang"
  },
  {
    "id": "3207072006",
    "district_id": "320707",
    "label": "Banjarangsana",
    "value": "Banjarangsana"
  },
  {
    "id": "3207072007",
    "district_id": "320707",
    "label": "Payungagung",
    "value": "Payungagung"
  },
  {
    "id": "3207072008",
    "district_id": "320707",
    "label": "Tanjungmulya",
    "value": "Tanjungmulya"
  },
  {
    "id": "3207072009",
    "district_id": "320707",
    "label": "Payungsari",
    "value": "Payungsari"
  },
  {
    "id": "3207072010",
    "district_id": "320707",
    "label": "Jayagiri",
    "value": "Jayagiri"
  },
  {
    "id": "3207072011",
    "district_id": "320707",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3207072012",
    "district_id": "320707",
    "label": "Sindangmukti",
    "value": "Sindangmukti"
  },
  {
    "id": "3207072013",
    "district_id": "320707",
    "label": "Sindangbarang",
    "value": "Sindangbarang"
  },
  {
    "id": "3207072014",
    "district_id": "320707",
    "label": "Buanamekar",
    "value": "Buanamekar"
  },
  {
    "id": "3207082001",
    "district_id": "320708",
    "label": "Panjalu",
    "value": "Panjalu"
  },
  {
    "id": "3207082002",
    "district_id": "320708",
    "label": "Kertamandala",
    "value": "Kertamandala"
  },
  {
    "id": "3207082003",
    "district_id": "320708",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3207082004",
    "district_id": "320708",
    "label": "Sandingtaman",
    "value": "Sandingtaman"
  },
  {
    "id": "3207082005",
    "district_id": "320708",
    "label": "Maparah",
    "value": "Maparah"
  },
  {
    "id": "3207082009",
    "district_id": "320708",
    "label": "Bahara",
    "value": "Bahara"
  },
  {
    "id": "3207082012",
    "district_id": "320708",
    "label": "Hujungtiwu",
    "value": "Hujungtiwu"
  },
  {
    "id": "3207082013",
    "district_id": "320708",
    "label": "Mandalare",
    "value": "Mandalare"
  },
  {
    "id": "3207092001",
    "district_id": "320709",
    "label": "Kawali",
    "value": "Kawali"
  },
  {
    "id": "3207092002",
    "district_id": "320709",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3207092004",
    "district_id": "320709",
    "label": "Karangpawitan",
    "value": "Karangpawitan"
  },
  {
    "id": "3207092005",
    "district_id": "320709",
    "label": "Winduraja",
    "value": "Winduraja"
  },
  {
    "id": "3207092006",
    "district_id": "320709",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3207092011",
    "district_id": "320709",
    "label": "Citeureup",
    "value": "Citeureup"
  },
  {
    "id": "3207092013",
    "district_id": "320709",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3207092014",
    "district_id": "320709",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3207092015",
    "district_id": "320709",
    "label": "Kawalimukti",
    "value": "Kawalimukti"
  },
  {
    "id": "3207092016",
    "district_id": "320709",
    "label": "Selasari",
    "value": "Selasari"
  },
  {
    "id": "3207092019",
    "district_id": "320709",
    "label": "Linggapura",
    "value": "Linggapura"
  },
  {
    "id": "3207102001",
    "district_id": "320710",
    "label": "Panawangan",
    "value": "Panawangan"
  },
  {
    "id": "3207102002",
    "district_id": "320710",
    "label": "Sagalaherang",
    "value": "Sagalaherang"
  },
  {
    "id": "3207102003",
    "district_id": "320710",
    "label": "Nagarapageuh",
    "value": "Nagarapageuh"
  },
  {
    "id": "3207102004",
    "district_id": "320710",
    "label": "Nagarajati",
    "value": "Nagarajati"
  },
  {
    "id": "3207102005",
    "district_id": "320710",
    "label": "Nagarajaya",
    "value": "Nagarajaya"
  },
  {
    "id": "3207102006",
    "district_id": "320710",
    "label": "Kertayasa",
    "value": "Kertayasa"
  },
  {
    "id": "3207102007",
    "district_id": "320710",
    "label": "Indragiri",
    "value": "Indragiri"
  },
  {
    "id": "3207102008",
    "district_id": "320710",
    "label": "Cinyasag",
    "value": "Cinyasag"
  },
  {
    "id": "3207102009",
    "district_id": "320710",
    "label": "Sadapaingan",
    "value": "Sadapaingan"
  },
  {
    "id": "3207102010",
    "district_id": "320710",
    "label": "Jagabaya",
    "value": "Jagabaya"
  },
  {
    "id": "3207102011",
    "district_id": "320710",
    "label": "Gardujaya",
    "value": "Gardujaya"
  },
  {
    "id": "3207102012",
    "district_id": "320710",
    "label": "Karangpaningal",
    "value": "Karangpaningal"
  },
  {
    "id": "3207102013",
    "district_id": "320710",
    "label": "Bangunjaya",
    "value": "Bangunjaya"
  },
  {
    "id": "3207102014",
    "district_id": "320710",
    "label": "Girilaya",
    "value": "Girilaya"
  },
  {
    "id": "3207102015",
    "district_id": "320710",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3207102016",
    "district_id": "320710",
    "label": "Nagarawangi",
    "value": "Nagarawangi"
  },
  {
    "id": "3207102017",
    "district_id": "320710",
    "label": "Mekarbuana",
    "value": "Mekarbuana"
  },
  {
    "id": "3207102018",
    "district_id": "320710",
    "label": "Natanegara",
    "value": "Natanegara"
  },
  {
    "id": "3207112001",
    "district_id": "320711",
    "label": "Buniseuri",
    "value": "Buniseuri"
  },
  {
    "id": "3207112002",
    "district_id": "320711",
    "label": "Selacai",
    "value": "Selacai"
  },
  {
    "id": "3207112003",
    "district_id": "320711",
    "label": "Jalatrang",
    "value": "Jalatrang"
  },
  {
    "id": "3207112004",
    "district_id": "320711",
    "label": "Cipaku",
    "value": "Cipaku"
  },
  {
    "id": "3207112005",
    "district_id": "320711",
    "label": "Cieurih",
    "value": "Cieurih"
  },
  {
    "id": "3207112006",
    "district_id": "320711",
    "label": "Gereba",
    "value": "Gereba"
  },
  {
    "id": "3207112007",
    "district_id": "320711",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "3207112008",
    "district_id": "320711",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3207112009",
    "district_id": "320711",
    "label": "Pusakasari",
    "value": "Pusakasari"
  },
  {
    "id": "3207112010",
    "district_id": "320711",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3207112011",
    "district_id": "320711",
    "label": "Selamanik",
    "value": "Selamanik"
  },
  {
    "id": "3207112012",
    "district_id": "320711",
    "label": "Ciakar",
    "value": "Ciakar"
  },
  {
    "id": "3207112013",
    "district_id": "320711",
    "label": "Sukawening",
    "value": "Sukawening"
  },
  {
    "id": "3207122001",
    "district_id": "320712",
    "label": "Jatinagara",
    "value": "Jatinagara"
  },
  {
    "id": "3207122002",
    "district_id": "320712",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3207122003",
    "district_id": "320712",
    "label": "Cintanagara",
    "value": "Cintanagara"
  },
  {
    "id": "3207122004",
    "district_id": "320712",
    "label": "Dayeuhluhur",
    "value": "Dayeuhluhur"
  },
  {
    "id": "3207122005",
    "district_id": "320712",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3207122006",
    "district_id": "320712",
    "label": "Bayasari",
    "value": "Bayasari"
  },
  {
    "id": "3207132001",
    "district_id": "320713",
    "label": "Rajadesa",
    "value": "Rajadesa"
  },
  {
    "id": "3207132002",
    "district_id": "320713",
    "label": "Tanjungsukur",
    "value": "Tanjungsukur"
  },
  {
    "id": "3207132003",
    "district_id": "320713",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3207132004",
    "district_id": "320713",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3207132005",
    "district_id": "320713",
    "label": "Andapraja",
    "value": "Andapraja"
  },
  {
    "id": "3207132006",
    "district_id": "320713",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3207132007",
    "district_id": "320713",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3207132008",
    "district_id": "320713",
    "label": "Tigaherang",
    "value": "Tigaherang"
  },
  {
    "id": "3207132009",
    "district_id": "320713",
    "label": "Sirnabaya",
    "value": "Sirnabaya"
  },
  {
    "id": "3207132010",
    "district_id": "320713",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3207132011",
    "district_id": "320713",
    "label": "Purwaraja",
    "value": "Purwaraja"
  },
  {
    "id": "3207142001",
    "district_id": "320714",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3207142002",
    "district_id": "320714",
    "label": "Salakaria",
    "value": "Salakaria"
  },
  {
    "id": "3207142003",
    "district_id": "320714",
    "label": "Margaharja",
    "value": "Margaharja"
  },
  {
    "id": "3207142004",
    "district_id": "320714",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3207142005",
    "district_id": "320714",
    "label": "Bunter",
    "value": "Bunter"
  },
  {
    "id": "3207142006",
    "district_id": "320714",
    "label": "Ciparigi",
    "value": "Ciparigi"
  },
  {
    "id": "3207152001",
    "district_id": "320715",
    "label": "Rancah",
    "value": "Rancah"
  },
  {
    "id": "3207152002",
    "district_id": "320715",
    "label": "Kiarapayung",
    "value": "Kiarapayung"
  },
  {
    "id": "3207152003",
    "district_id": "320715",
    "label": "Cileungsir",
    "value": "Cileungsir"
  },
  {
    "id": "3207152004",
    "district_id": "320715",
    "label": "Cisontrol",
    "value": "Cisontrol"
  },
  {
    "id": "3207152005",
    "district_id": "320715",
    "label": "Situmandala",
    "value": "Situmandala"
  },
  {
    "id": "3207152006",
    "district_id": "320715",
    "label": "Kawunglarang",
    "value": "Kawunglarang"
  },
  {
    "id": "3207152007",
    "district_id": "320715",
    "label": "Patakaharja",
    "value": "Patakaharja"
  },
  {
    "id": "3207152008",
    "district_id": "320715",
    "label": "Bojonggedang",
    "value": "Bojonggedang"
  },
  {
    "id": "3207152009",
    "district_id": "320715",
    "label": "Karangpari",
    "value": "Karangpari"
  },
  {
    "id": "3207152010",
    "district_id": "320715",
    "label": "Dadiharja",
    "value": "Dadiharja"
  },
  {
    "id": "3207152011",
    "district_id": "320715",
    "label": "Jangalaharja",
    "value": "Jangalaharja"
  },
  {
    "id": "3207152012",
    "district_id": "320715",
    "label": "Giriharja",
    "value": "Giriharja"
  },
  {
    "id": "3207152013",
    "district_id": "320715",
    "label": "Wangunsari",
    "value": "Wangunsari"
  },
  {
    "id": "3207162001",
    "district_id": "320716",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3207162002",
    "district_id": "320716",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3207162003",
    "district_id": "320716",
    "label": "Kaso",
    "value": "Kaso"
  },
  {
    "id": "3207162004",
    "district_id": "320716",
    "label": "Kadupandak",
    "value": "Kadupandak"
  },
  {
    "id": "3207162005",
    "district_id": "320716",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3207162006",
    "district_id": "320716",
    "label": "Karangpaningal",
    "value": "Karangpaningal"
  },
  {
    "id": "3207172006",
    "district_id": "320717",
    "label": "Sidaharja",
    "value": "Sidaharja"
  },
  {
    "id": "3207172007",
    "district_id": "320717",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3207172009",
    "district_id": "320717",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3207172010",
    "district_id": "320717",
    "label": "Cintajaya",
    "value": "Cintajaya"
  },
  {
    "id": "3207172011",
    "district_id": "320717",
    "label": "Cintaratu",
    "value": "Cintaratu"
  },
  {
    "id": "3207172012",
    "district_id": "320717",
    "label": "Sindangangin",
    "value": "Sindangangin"
  },
  {
    "id": "3207172013",
    "district_id": "320717",
    "label": "Tambakreja",
    "value": "Tambakreja"
  },
  {
    "id": "3207172017",
    "district_id": "320717",
    "label": "Baregbeg",
    "value": "Baregbeg"
  },
  {
    "id": "3207172018",
    "district_id": "320717",
    "label": "Kalapasawit",
    "value": "Kalapasawit"
  },
  {
    "id": "3207172019",
    "district_id": "320717",
    "label": "Puloerang",
    "value": "Puloerang"
  },
  {
    "id": "3207182005",
    "district_id": "320718",
    "label": "Kawasen",
    "value": "Kawasen"
  },
  {
    "id": "3207182006",
    "district_id": "320718",
    "label": "Cicapar",
    "value": "Cicapar"
  },
  {
    "id": "3207182007",
    "district_id": "320718",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3207182008",
    "district_id": "320718",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3207182009",
    "district_id": "320718",
    "label": "Sindanghayu",
    "value": "Sindanghayu"
  },
  {
    "id": "3207182010",
    "district_id": "320718",
    "label": "Sindangasih",
    "value": "Sindangasih"
  },
  {
    "id": "3207182012",
    "district_id": "320718",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3207182013",
    "district_id": "320718",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3207182014",
    "district_id": "320718",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3207182015",
    "district_id": "320718",
    "label": "Ratawangi",
    "value": "Ratawangi"
  },
  {
    "id": "3207182016",
    "district_id": "320718",
    "label": "Ciulu",
    "value": "Ciulu"
  },
  {
    "id": "3207182018",
    "district_id": "320718",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3207192001",
    "district_id": "320719",
    "label": "Pamarican",
    "value": "Pamarican"
  },
  {
    "id": "3207192002",
    "district_id": "320719",
    "label": "Sidamulih",
    "value": "Sidamulih"
  },
  {
    "id": "3207192003",
    "district_id": "320719",
    "label": "Kertahayu",
    "value": "Kertahayu"
  },
  {
    "id": "3207192004",
    "district_id": "320719",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3207192005",
    "district_id": "320719",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3207192006",
    "district_id": "320719",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3207192007",
    "district_id": "320719",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3207192008",
    "district_id": "320719",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3207192009",
    "district_id": "320719",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3207192010",
    "district_id": "320719",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3207192011",
    "district_id": "320719",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3207192012",
    "district_id": "320719",
    "label": "Sidaharja",
    "value": "Sidaharja"
  },
  {
    "id": "3207192013",
    "district_id": "320719",
    "label": "Pasirnagara",
    "value": "Pasirnagara"
  },
  {
    "id": "3207192014",
    "district_id": "320719",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3207292001",
    "district_id": "320729",
    "label": "Cimaragas",
    "value": "Cimaragas"
  },
  {
    "id": "3207292002",
    "district_id": "320729",
    "label": "Beber",
    "value": "Beber"
  },
  {
    "id": "3207292003",
    "district_id": "320729",
    "label": "Raksabaya",
    "value": "Raksabaya"
  },
  {
    "id": "3207292004",
    "district_id": "320729",
    "label": "Bojongmalang",
    "value": "Bojongmalang"
  },
  {
    "id": "3207292005",
    "district_id": "320729",
    "label": "Jayaraksa",
    "value": "Jayaraksa"
  },
  {
    "id": "3207302001",
    "district_id": "320730",
    "label": "Cisaga",
    "value": "Cisaga"
  },
  {
    "id": "3207302002",
    "district_id": "320730",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3207302003",
    "district_id": "320730",
    "label": "Kepel",
    "value": "Kepel"
  },
  {
    "id": "3207302004",
    "district_id": "320730",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3207302005",
    "district_id": "320730",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3207302006",
    "district_id": "320730",
    "label": "Bangunharja",
    "value": "Bangunharja"
  },
  {
    "id": "3207302007",
    "district_id": "320730",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3207302008",
    "district_id": "320730",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3207302009",
    "district_id": "320730",
    "label": "Danasari",
    "value": "Danasari"
  },
  {
    "id": "3207302010",
    "district_id": "320730",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3207302011",
    "district_id": "320730",
    "label": "Karyamulya",
    "value": "Karyamulya"
  },
  {
    "id": "3207312001",
    "district_id": "320731",
    "label": "Sindangkasih",
    "value": "Sindangkasih"
  },
  {
    "id": "3207312002",
    "district_id": "320731",
    "label": "Gunungcupu",
    "value": "Gunungcupu"
  },
  {
    "id": "3207312003",
    "district_id": "320731",
    "label": "Budiasih",
    "value": "Budiasih"
  },
  {
    "id": "3207312004",
    "district_id": "320731",
    "label": "Budiharja",
    "value": "Budiharja"
  },
  {
    "id": "3207312005",
    "district_id": "320731",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3207312006",
    "district_id": "320731",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3207312007",
    "district_id": "320731",
    "label": "Sukasenang",
    "value": "Sukasenang"
  },
  {
    "id": "3207312008",
    "district_id": "320731",
    "label": "Sukaresik",
    "value": "Sukaresik"
  },
  {
    "id": "3207312009",
    "district_id": "320731",
    "label": "Wanasigra",
    "value": "Wanasigra"
  },
  {
    "id": "3207322001",
    "district_id": "320732",
    "label": "Baregbeg",
    "value": "Baregbeg"
  },
  {
    "id": "3207322002",
    "district_id": "320732",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3207322003",
    "district_id": "320732",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3207322004",
    "district_id": "320732",
    "label": "Saguling",
    "value": "Saguling"
  },
  {
    "id": "3207322005",
    "district_id": "320732",
    "label": "Petirhilir",
    "value": "Petirhilir"
  },
  {
    "id": "3207322006",
    "district_id": "320732",
    "label": "Pusakanagara",
    "value": "Pusakanagara"
  },
  {
    "id": "3207322007",
    "district_id": "320732",
    "label": "Jelat",
    "value": "Jelat"
  },
  {
    "id": "3207322008",
    "district_id": "320732",
    "label": "Karangampel",
    "value": "Karangampel"
  },
  {
    "id": "3207322009",
    "district_id": "320732",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3207332001",
    "district_id": "320733",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3207332002",
    "district_id": "320733",
    "label": "Tenggerraharja",
    "value": "Tenggerraharja"
  },
  {
    "id": "3207332003",
    "district_id": "320733",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3207332004",
    "district_id": "320733",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3207332005",
    "district_id": "320733",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3207342001",
    "district_id": "320734",
    "label": "Cikupa",
    "value": "Cikupa"
  },
  {
    "id": "3207342002",
    "district_id": "320734",
    "label": "Sukaraharja",
    "value": "Sukaraharja"
  },
  {
    "id": "3207342003",
    "district_id": "320734",
    "label": "Lumbung",
    "value": "Lumbung"
  },
  {
    "id": "3207342004",
    "district_id": "320734",
    "label": "Lumbungsari",
    "value": "Lumbungsari"
  },
  {
    "id": "3207342005",
    "district_id": "320734",
    "label": "Awiluar",
    "value": "Awiluar"
  },
  {
    "id": "3207342006",
    "district_id": "320734",
    "label": "Darmaraja",
    "value": "Darmaraja"
  },
  {
    "id": "3207342007",
    "district_id": "320734",
    "label": "Rawa",
    "value": "Rawa"
  },
  {
    "id": "3207342008",
    "district_id": "320734",
    "label": "Sadewata",
    "value": "Sadewata"
  },
  {
    "id": "3207352001",
    "district_id": "320735",
    "label": "Bantardawa",
    "value": "Bantardawa"
  },
  {
    "id": "3207352002",
    "district_id": "320735",
    "label": "Purwadadi",
    "value": "Purwadadi"
  },
  {
    "id": "3207352003",
    "district_id": "320735",
    "label": "Purwajaya",
    "value": "Purwajaya"
  },
  {
    "id": "3207352004",
    "district_id": "320735",
    "label": "Karangpaningal",
    "value": "Karangpaningal"
  },
  {
    "id": "3207352005",
    "district_id": "320735",
    "label": "Sidarahayu",
    "value": "Sidarahayu"
  },
  {
    "id": "3207352006",
    "district_id": "320735",
    "label": "Padaringan",
    "value": "Padaringan"
  },
  {
    "id": "3207352007",
    "district_id": "320735",
    "label": "Pasirlawang",
    "value": "Pasirlawang"
  },
  {
    "id": "3207352008",
    "district_id": "320735",
    "label": "Kutawaringin",
    "value": "Kutawaringin"
  },
  {
    "id": "3207352009",
    "district_id": "320735",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3207372001",
    "district_id": "320737",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3207372002",
    "district_id": "320737",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3207372003",
    "district_id": "320737",
    "label": "Cigayam",
    "value": "Cigayam"
  },
  {
    "id": "3207372004",
    "district_id": "320737",
    "label": "Langkapsari",
    "value": "Langkapsari"
  },
  {
    "id": "3207372005",
    "district_id": "320737",
    "label": "Kalijaya",
    "value": "Kalijaya"
  },
  {
    "id": "3207372006",
    "district_id": "320737",
    "label": "Sindangrasa",
    "value": "Sindangrasa"
  },
  {
    "id": "3207372007",
    "district_id": "320737",
    "label": "Cikaso",
    "value": "Cikaso"
  },
  {
    "id": "3207372008",
    "district_id": "320737",
    "label": "Cikupa",
    "value": "Cikupa"
  },
  {
    "id": "3207372009",
    "district_id": "320737",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3207372010",
    "district_id": "320737",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3208012001",
    "district_id": "320801",
    "label": "Sindangjawa",
    "value": "Sindangjawa"
  },
  {
    "id": "3208012002",
    "district_id": "320801",
    "label": "Ciketak",
    "value": "Ciketak"
  },
  {
    "id": "3208012003",
    "district_id": "320801",
    "label": "Nangka",
    "value": "Nangka"
  },
  {
    "id": "3208012004",
    "district_id": "320801",
    "label": "Windujanten",
    "value": "Windujanten"
  },
  {
    "id": "3208012005",
    "district_id": "320801",
    "label": "Kadugede",
    "value": "Kadugede"
  },
  {
    "id": "3208012006",
    "district_id": "320801",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "3208012007",
    "district_id": "320801",
    "label": "Cipondok",
    "value": "Cipondok"
  },
  {
    "id": "3208012008",
    "district_id": "320801",
    "label": "Bayuning",
    "value": "Bayuning"
  },
  {
    "id": "3208012009",
    "district_id": "320801",
    "label": "Tinggar",
    "value": "Tinggar"
  },
  {
    "id": "3208012010",
    "district_id": "320801",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3208012011",
    "district_id": "320801",
    "label": "Cisukadana",
    "value": "Cisukadana"
  },
  {
    "id": "3208012012",
    "district_id": "320801",
    "label": "Margabakti",
    "value": "Margabakti"
  },
  {
    "id": "3208022001",
    "district_id": "320802",
    "label": "Pinara",
    "value": "Pinara"
  },
  {
    "id": "3208022002",
    "district_id": "320802",
    "label": "Cijeumit",
    "value": "Cijeumit"
  },
  {
    "id": "3208022003",
    "district_id": "320802",
    "label": "Cipedes",
    "value": "Cipedes"
  },
  {
    "id": "3208022004",
    "district_id": "320802",
    "label": "Ciniru",
    "value": "Ciniru"
  },
  {
    "id": "3208022005",
    "district_id": "320802",
    "label": "Rambatan",
    "value": "Rambatan"
  },
  {
    "id": "3208022006",
    "district_id": "320802",
    "label": "Longkewang",
    "value": "Longkewang"
  },
  {
    "id": "3208022007",
    "district_id": "320802",
    "label": "Mungkaldatar",
    "value": "Mungkaldatar"
  },
  {
    "id": "3208022008",
    "district_id": "320802",
    "label": "Gunungmanik",
    "value": "Gunungmanik"
  },
  {
    "id": "3208022009",
    "district_id": "320802",
    "label": "Pamupukan",
    "value": "Pamupukan"
  },
  {
    "id": "3208032001",
    "district_id": "320803",
    "label": "Tangkolo",
    "value": "Tangkolo"
  },
  {
    "id": "3208032002",
    "district_id": "320803",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3208032003",
    "district_id": "320803",
    "label": "Subang",
    "value": "Subang"
  },
  {
    "id": "3208032004",
    "district_id": "320803",
    "label": "Gunungaci",
    "value": "Gunungaci"
  },
  {
    "id": "3208032005",
    "district_id": "320803",
    "label": "Situgede",
    "value": "Situgede"
  },
  {
    "id": "3208032006",
    "district_id": "320803",
    "label": "Bangunjaya",
    "value": "Bangunjaya"
  },
  {
    "id": "3208032007",
    "district_id": "320803",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3208042001",
    "district_id": "320804",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3208042002",
    "district_id": "320804",
    "label": "Cilayung",
    "value": "Cilayung"
  },
  {
    "id": "3208042003",
    "district_id": "320804",
    "label": "Ciwaru",
    "value": "Ciwaru"
  },
  {
    "id": "3208042004",
    "district_id": "320804",
    "label": "Garajati",
    "value": "Garajati"
  },
  {
    "id": "3208042005",
    "district_id": "320804",
    "label": "Baok",
    "value": "Baok"
  },
  {
    "id": "3208042006",
    "district_id": "320804",
    "label": "Andamui",
    "value": "Andamui"
  },
  {
    "id": "3208042007",
    "district_id": "320804",
    "label": "Sagaranten",
    "value": "Sagaranten"
  },
  {
    "id": "3208042008",
    "district_id": "320804",
    "label": "Citundun",
    "value": "Citundun"
  },
  {
    "id": "3208042009",
    "district_id": "320804",
    "label": "Lebakherang",
    "value": "Lebakherang"
  },
  {
    "id": "3208042010",
    "district_id": "320804",
    "label": "Karangbaru",
    "value": "Karangbaru"
  },
  {
    "id": "3208042011",
    "district_id": "320804",
    "label": "Linggajaya",
    "value": "Linggajaya"
  },
  {
    "id": "3208042012",
    "district_id": "320804",
    "label": "Citikur",
    "value": "Citikur"
  },
  {
    "id": "3208052001",
    "district_id": "320805",
    "label": "Cipondoh",
    "value": "Cipondoh"
  },
  {
    "id": "3208052002",
    "district_id": "320805",
    "label": "Sindangjawa",
    "value": "Sindangjawa"
  },
  {
    "id": "3208052003",
    "district_id": "320805",
    "label": "Ciangir",
    "value": "Ciangir"
  },
  {
    "id": "3208052004",
    "district_id": "320805",
    "label": "Cibingbin",
    "value": "Cibingbin"
  },
  {
    "id": "3208052005",
    "district_id": "320805",
    "label": "Citenjo",
    "value": "Citenjo"
  },
  {
    "id": "3208052006",
    "district_id": "320805",
    "label": "Dukuhbadag",
    "value": "Dukuhbadag"
  },
  {
    "id": "3208052007",
    "district_id": "320805",
    "label": "Bantarpanjang",
    "value": "Bantarpanjang"
  },
  {
    "id": "3208052008",
    "district_id": "320805",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3208052009",
    "district_id": "320805",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3208052010",
    "district_id": "320805",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3208062001",
    "district_id": "320806",
    "label": "Dukuhpicung",
    "value": "Dukuhpicung"
  },
  {
    "id": "3208062002",
    "district_id": "320806",
    "label": "Walaharcageur",
    "value": "Walaharcageur"
  },
  {
    "id": "3208062003",
    "district_id": "320806",
    "label": "Wilanagara",
    "value": "Wilanagara"
  },
  {
    "id": "3208062004",
    "district_id": "320806",
    "label": "Cigedang",
    "value": "Cigedang"
  },
  {
    "id": "3208062005",
    "district_id": "320806",
    "label": "Luragung Tonggoh",
    "value": "Luragung Tonggoh"
  },
  {
    "id": "3208062006",
    "district_id": "320806",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3208062007",
    "district_id": "320806",
    "label": "Cirahayu",
    "value": "Cirahayu"
  },
  {
    "id": "3208062008",
    "district_id": "320806",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3208062009",
    "district_id": "320806",
    "label": "Sindangsuka",
    "value": "Sindangsuka"
  },
  {
    "id": "3208062010",
    "district_id": "320806",
    "label": "Cikandang",
    "value": "Cikandang"
  },
  {
    "id": "3208062011",
    "district_id": "320806",
    "label": "Panyosogan",
    "value": "Panyosogan"
  },
  {
    "id": "3208062012",
    "district_id": "320806",
    "label": "Gunungkarung",
    "value": "Gunungkarung"
  },
  {
    "id": "3208062013",
    "district_id": "320806",
    "label": "Luragung Landeuh",
    "value": "Luragung Landeuh"
  },
  {
    "id": "3208062014",
    "district_id": "320806",
    "label": "Dukuhmaja",
    "value": "Dukuhmaja"
  },
  {
    "id": "3208062015",
    "district_id": "320806",
    "label": "Benda",
    "value": "Benda"
  },
  {
    "id": "3208062016",
    "district_id": "320806",
    "label": "Cikaduwetan",
    "value": "Cikaduwetan"
  },
  {
    "id": "3208072010",
    "district_id": "320807",
    "label": "Langseb",
    "value": "Langseb"
  },
  {
    "id": "3208072011",
    "district_id": "320807",
    "label": "Cinagara",
    "value": "Cinagara"
  },
  {
    "id": "3208072012",
    "district_id": "320807",
    "label": "Cineumbeuy",
    "value": "Cineumbeuy"
  },
  {
    "id": "3208072013",
    "district_id": "320807",
    "label": "Cipetir",
    "value": "Cipetir"
  },
  {
    "id": "3208072014",
    "district_id": "320807",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "3208072015",
    "district_id": "320807",
    "label": "Mancagar",
    "value": "Mancagar"
  },
  {
    "id": "3208072016",
    "district_id": "320807",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3208072017",
    "district_id": "320807",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "3208072018",
    "district_id": "320807",
    "label": "Pasayangan",
    "value": "Pasayangan"
  },
  {
    "id": "3208072019",
    "district_id": "320807",
    "label": "Pajawankidul",
    "value": "Pajawankidul"
  },
  {
    "id": "3208072020",
    "district_id": "320807",
    "label": "Pagundan",
    "value": "Pagundan"
  },
  {
    "id": "3208072021",
    "district_id": "320807",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3208072025",
    "district_id": "320807",
    "label": "Manggari",
    "value": "Manggari"
  },
  {
    "id": "3208082001",
    "district_id": "320808",
    "label": "Gewok",
    "value": "Gewok"
  },
  {
    "id": "3208082002",
    "district_id": "320808",
    "label": "Cirukem",
    "value": "Cirukem"
  },
  {
    "id": "3208082003",
    "district_id": "320808",
    "label": "Pakembangan",
    "value": "Pakembangan"
  },
  {
    "id": "3208082004",
    "district_id": "320808",
    "label": "Sukaimut",
    "value": "Sukaimut"
  },
  {
    "id": "3208082005",
    "district_id": "320808",
    "label": "Cikananga",
    "value": "Cikananga"
  },
  {
    "id": "3208082006",
    "district_id": "320808",
    "label": "Tambakbaya",
    "value": "Tambakbaya"
  },
  {
    "id": "3208082007",
    "district_id": "320808",
    "label": "Garawangi",
    "value": "Garawangi"
  },
  {
    "id": "3208082008",
    "district_id": "320808",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3208082009",
    "district_id": "320808",
    "label": "Mancagar",
    "value": "Mancagar"
  },
  {
    "id": "3208082010",
    "district_id": "320808",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3208082011",
    "district_id": "320808",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3208082023",
    "district_id": "320808",
    "label": "Karamatwangi",
    "value": "Karamatwangi"
  },
  {
    "id": "3208082024",
    "district_id": "320808",
    "label": "Citiusari",
    "value": "Citiusari"
  },
  {
    "id": "3208082026",
    "district_id": "320808",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3208082027",
    "district_id": "320808",
    "label": "Tembong",
    "value": "Tembong"
  },
  {
    "id": "3208082028",
    "district_id": "320808",
    "label": "Kutakembaran",
    "value": "Kutakembaran"
  },
  {
    "id": "3208082029",
    "district_id": "320808",
    "label": "Kadatuan",
    "value": "Kadatuan"
  },
  {
    "id": "3208091001",
    "district_id": "320809",
    "label": "Citangtu",
    "value": "Citangtu"
  },
  {
    "id": "3208091002",
    "district_id": "320809",
    "label": "Kuningan",
    "value": "Kuningan"
  },
  {
    "id": "3208091003",
    "district_id": "320809",
    "label": "Winduhaji",
    "value": "Winduhaji"
  },
  {
    "id": "3208091004",
    "district_id": "320809",
    "label": "Windusengkahan",
    "value": "Windusengkahan"
  },
  {
    "id": "3208091005",
    "district_id": "320809",
    "label": "Cijoho",
    "value": "Cijoho"
  },
  {
    "id": "3208091006",
    "district_id": "320809",
    "label": "Purwawinangun",
    "value": "Purwawinangun"
  },
  {
    "id": "3208091007",
    "district_id": "320809",
    "label": "Cigintung",
    "value": "Cigintung"
  },
  {
    "id": "3208091008",
    "district_id": "320809",
    "label": "Cirendang",
    "value": "Cirendang"
  },
  {
    "id": "3208091009",
    "district_id": "320809",
    "label": "Ciporang",
    "value": "Ciporang"
  },
  {
    "id": "3208091010",
    "district_id": "320809",
    "label": "Awirarangan",
    "value": "Awirarangan"
  },
  {
    "id": "3208092011",
    "district_id": "320809",
    "label": "Cibinuang",
    "value": "Cibinuang"
  },
  {
    "id": "3208092012",
    "district_id": "320809",
    "label": "Karangtawang",
    "value": "Karangtawang"
  },
  {
    "id": "3208092013",
    "district_id": "320809",
    "label": "Kedungarum",
    "value": "Kedungarum"
  },
  {
    "id": "3208092014",
    "district_id": "320809",
    "label": "Ancaran",
    "value": "Ancaran"
  },
  {
    "id": "3208092015",
    "district_id": "320809",
    "label": "Kasturi",
    "value": "Kasturi"
  },
  {
    "id": "3208092016",
    "district_id": "320809",
    "label": "Padarek",
    "value": "Padarek"
  },
  {
    "id": "3208102001",
    "district_id": "320810",
    "label": "Geresik",
    "value": "Geresik"
  },
  {
    "id": "3208102002",
    "district_id": "320810",
    "label": "Kadurama",
    "value": "Kadurama"
  },
  {
    "id": "3208102003",
    "district_id": "320810",
    "label": "Ciawigebang",
    "value": "Ciawigebang"
  },
  {
    "id": "3208102004",
    "district_id": "320810",
    "label": "Lebaksiuh",
    "value": "Lebaksiuh"
  },
  {
    "id": "3208102005",
    "district_id": "320810",
    "label": "Ciputat",
    "value": "Ciputat"
  },
  {
    "id": "3208102006",
    "district_id": "320810",
    "label": "Pajawan Lor",
    "value": "Pajawan Lor"
  },
  {
    "id": "3208102007",
    "district_id": "320810",
    "label": "Sidaraja",
    "value": "Sidaraja"
  },
  {
    "id": "3208102008",
    "district_id": "320810",
    "label": "Kapandayan",
    "value": "Kapandayan"
  },
  {
    "id": "3208102009",
    "district_id": "320810",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3208102010",
    "district_id": "320810",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3208102011",
    "district_id": "320810",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3208102012",
    "district_id": "320810",
    "label": "Cihaur",
    "value": "Cihaur"
  },
  {
    "id": "3208102013",
    "district_id": "320810",
    "label": "Ciawi Lor",
    "value": "Ciawi Lor"
  },
  {
    "id": "3208102014",
    "district_id": "320810",
    "label": "Cigarukgak",
    "value": "Cigarukgak"
  },
  {
    "id": "3208102015",
    "district_id": "320810",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3208102016",
    "district_id": "320810",
    "label": "Cihirup",
    "value": "Cihirup"
  },
  {
    "id": "3208102017",
    "district_id": "320810",
    "label": "Karamatmulya",
    "value": "Karamatmulya"
  },
  {
    "id": "3208102018",
    "district_id": "320810",
    "label": "Pamijahan",
    "value": "Pamijahan"
  },
  {
    "id": "3208102019",
    "district_id": "320810",
    "label": "Karangkamulyan",
    "value": "Karangkamulyan"
  },
  {
    "id": "3208102020",
    "district_id": "320810",
    "label": "Padarama",
    "value": "Padarama"
  },
  {
    "id": "3208102021",
    "district_id": "320810",
    "label": "Cikubangmulya",
    "value": "Cikubangmulya"
  },
  {
    "id": "3208102022",
    "district_id": "320810",
    "label": "Dukuhdalem",
    "value": "Dukuhdalem"
  },
  {
    "id": "3208102023",
    "district_id": "320810",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3208102024",
    "district_id": "320810",
    "label": "Cijagamulya",
    "value": "Cijagamulya"
  },
  {
    "id": "3208112001",
    "district_id": "320811",
    "label": "Cihideung Girang",
    "value": "Cihideung Girang"
  },
  {
    "id": "3208112002",
    "district_id": "320811",
    "label": "Cihideung Hilir",
    "value": "Cihideung Hilir"
  },
  {
    "id": "3208112003",
    "district_id": "320811",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3208112004",
    "district_id": "320811",
    "label": "Kertawinangun",
    "value": "Kertawinangun"
  },
  {
    "id": "3208112005",
    "district_id": "320811",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "3208112006",
    "district_id": "320811",
    "label": "Legok",
    "value": "Legok"
  },
  {
    "id": "3208112007",
    "district_id": "320811",
    "label": "Cikeusik",
    "value": "Cikeusik"
  },
  {
    "id": "3208112008",
    "district_id": "320811",
    "label": "Cieurih",
    "value": "Cieurih"
  },
  {
    "id": "3208112009",
    "district_id": "320811",
    "label": "Cibulan",
    "value": "Cibulan"
  },
  {
    "id": "3208112010",
    "district_id": "320811",
    "label": "Nanggela",
    "value": "Nanggela"
  },
  {
    "id": "3208112011",
    "district_id": "320811",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3208112012",
    "district_id": "320811",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3208122001",
    "district_id": "320812",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3208122002",
    "district_id": "320812",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3208122003",
    "district_id": "320812",
    "label": "Jalaksana",
    "value": "Jalaksana"
  },
  {
    "id": "3208122004",
    "district_id": "320812",
    "label": "Sangkanerang",
    "value": "Sangkanerang"
  },
  {
    "id": "3208122005",
    "district_id": "320812",
    "label": "Sayana",
    "value": "Sayana"
  },
  {
    "id": "3208122006",
    "district_id": "320812",
    "label": "Peusing",
    "value": "Peusing"
  },
  {
    "id": "3208122007",
    "district_id": "320812",
    "label": "Sembawa",
    "value": "Sembawa"
  },
  {
    "id": "3208122008",
    "district_id": "320812",
    "label": "Sadamantra",
    "value": "Sadamantra"
  },
  {
    "id": "3208122009",
    "district_id": "320812",
    "label": "Manis Kidul",
    "value": "Manis Kidul"
  },
  {
    "id": "3208122010",
    "district_id": "320812",
    "label": "Manis Lor",
    "value": "Manis Lor"
  },
  {
    "id": "3208122011",
    "district_id": "320812",
    "label": "Ciniru",
    "value": "Ciniru"
  },
  {
    "id": "3208122012",
    "district_id": "320812",
    "label": "Padamenak",
    "value": "Padamenak"
  },
  {
    "id": "3208122013",
    "district_id": "320812",
    "label": "Babakanmulya",
    "value": "Babakanmulya"
  },
  {
    "id": "3208122014",
    "district_id": "320812",
    "label": "Sindangbarang",
    "value": "Sindangbarang"
  },
  {
    "id": "3208122015",
    "district_id": "320812",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3208132006",
    "district_id": "320813",
    "label": "Bandorasa Wetan",
    "value": "Bandorasa Wetan"
  },
  {
    "id": "3208132007",
    "district_id": "320813",
    "label": "Bandorasa Kulon",
    "value": "Bandorasa Kulon"
  },
  {
    "id": "3208132008",
    "district_id": "320813",
    "label": "Linggasana",
    "value": "Linggasana"
  },
  {
    "id": "3208132009",
    "district_id": "320813",
    "label": "Linggarjati",
    "value": "Linggarjati"
  },
  {
    "id": "3208132010",
    "district_id": "320813",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3208132015",
    "district_id": "320813",
    "label": "Cilimus",
    "value": "Cilimus"
  },
  {
    "id": "3208132016",
    "district_id": "320813",
    "label": "Setianegara",
    "value": "Setianegara"
  },
  {
    "id": "3208132017",
    "district_id": "320813",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3208132018",
    "district_id": "320813",
    "label": "Kaliaren",
    "value": "Kaliaren"
  },
  {
    "id": "3208132019",
    "district_id": "320813",
    "label": "Sampora",
    "value": "Sampora"
  },
  {
    "id": "3208132020",
    "district_id": "320813",
    "label": "Caracas",
    "value": "Caracas"
  },
  {
    "id": "3208132023",
    "district_id": "320813",
    "label": "Linggamekar",
    "value": "Linggamekar"
  },
  {
    "id": "3208132024",
    "district_id": "320813",
    "label": "Linggaindah",
    "value": "Linggaindah"
  },
  {
    "id": "3208142001",
    "district_id": "320814",
    "label": "Cirea",
    "value": "Cirea"
  },
  {
    "id": "3208142002",
    "district_id": "320814",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3208142003",
    "district_id": "320814",
    "label": "Seda",
    "value": "Seda"
  },
  {
    "id": "3208142004",
    "district_id": "320814",
    "label": "Trijaya",
    "value": "Trijaya"
  },
  {
    "id": "3208142005",
    "district_id": "320814",
    "label": "Randobawa Girang",
    "value": "Randobawa Girang"
  },
  {
    "id": "3208142006",
    "district_id": "320814",
    "label": "Pakembangan",
    "value": "Pakembangan"
  },
  {
    "id": "3208142007",
    "district_id": "320814",
    "label": "Randobawa Ilir",
    "value": "Randobawa Ilir"
  },
  {
    "id": "3208142008",
    "district_id": "320814",
    "label": "Kertawinangun",
    "value": "Kertawinangun"
  },
  {
    "id": "3208142009",
    "district_id": "320814",
    "label": "Mandirancan",
    "value": "Mandirancan"
  },
  {
    "id": "3208142010",
    "district_id": "320814",
    "label": "Naggela",
    "value": "Naggela"
  },
  {
    "id": "3208142011",
    "district_id": "320814",
    "label": "Nanggerangjaya",
    "value": "Nanggerangjaya"
  },
  {
    "id": "3208142012",
    "district_id": "320814",
    "label": "Salakadomas",
    "value": "Salakadomas"
  },
  {
    "id": "3208152001",
    "district_id": "320815",
    "label": "Selajambe",
    "value": "Selajambe"
  },
  {
    "id": "3208152002",
    "district_id": "320815",
    "label": "Cantilan",
    "value": "Cantilan"
  },
  {
    "id": "3208152003",
    "district_id": "320815",
    "label": "Ciberung",
    "value": "Ciberung"
  },
  {
    "id": "3208152004",
    "district_id": "320815",
    "label": "Kutawaringin",
    "value": "Kutawaringin"
  },
  {
    "id": "3208152005",
    "district_id": "320815",
    "label": "Jamberama",
    "value": "Jamberama"
  },
  {
    "id": "3208152006",
    "district_id": "320815",
    "label": "Bagawat",
    "value": "Bagawat"
  },
  {
    "id": "3208152007",
    "district_id": "320815",
    "label": "Padahurip",
    "value": "Padahurip"
  },
  {
    "id": "3208162002",
    "district_id": "320816",
    "label": "Gereba",
    "value": "Gereba"
  },
  {
    "id": "3208162004",
    "district_id": "320816",
    "label": "Cilaja",
    "value": "Cilaja"
  },
  {
    "id": "3208162005",
    "district_id": "320816",
    "label": "Cikubangsari",
    "value": "Cikubangsari"
  },
  {
    "id": "3208162006",
    "district_id": "320816",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3208162007",
    "district_id": "320816",
    "label": "Cilowa",
    "value": "Cilowa"
  },
  {
    "id": "3208162008",
    "district_id": "320816",
    "label": "Cibentang",
    "value": "Cibentang"
  },
  {
    "id": "3208162009",
    "district_id": "320816",
    "label": "Ragawacana",
    "value": "Ragawacana"
  },
  {
    "id": "3208162010",
    "district_id": "320816",
    "label": "Pajambon",
    "value": "Pajambon"
  },
  {
    "id": "3208162011",
    "district_id": "320816",
    "label": "Gandasoli",
    "value": "Gandasoli"
  },
  {
    "id": "3208162012",
    "district_id": "320816",
    "label": "Kalapagunung",
    "value": "Kalapagunung"
  },
  {
    "id": "3208162013",
    "district_id": "320816",
    "label": "Kramatmulya",
    "value": "Kramatmulya"
  },
  {
    "id": "3208162014",
    "district_id": "320816",
    "label": "Cikaso",
    "value": "Cikaso"
  },
  {
    "id": "3208162015",
    "district_id": "320816",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3208162017",
    "district_id": "320816",
    "label": "Widarasari",
    "value": "Widarasari"
  },
  {
    "id": "3208172001",
    "district_id": "320817",
    "label": "Cimenga",
    "value": "Cimenga"
  },
  {
    "id": "3208172002",
    "district_id": "320817",
    "label": "Cageur",
    "value": "Cageur"
  },
  {
    "id": "3208172003",
    "district_id": "320817",
    "label": "Sakerta Barat",
    "value": "Sakerta Barat"
  },
  {
    "id": "3208172004",
    "district_id": "320817",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3208172005",
    "district_id": "320817",
    "label": "Paninggaran",
    "value": "Paninggaran"
  },
  {
    "id": "3208172006",
    "district_id": "320817",
    "label": "Cipasung",
    "value": "Cipasung"
  },
  {
    "id": "3208172007",
    "district_id": "320817",
    "label": "Kawah Manuk",
    "value": "Kawah Manuk"
  },
  {
    "id": "3208172008",
    "district_id": "320817",
    "label": "Cikupa",
    "value": "Cikupa"
  },
  {
    "id": "3208172009",
    "district_id": "320817",
    "label": "Parung",
    "value": "Parung"
  },
  {
    "id": "3208172010",
    "district_id": "320817",
    "label": "Darma",
    "value": "Darma"
  },
  {
    "id": "3208172011",
    "district_id": "320817",
    "label": "Jagara",
    "value": "Jagara"
  },
  {
    "id": "3208172012",
    "district_id": "320817",
    "label": "Bakom",
    "value": "Bakom"
  },
  {
    "id": "3208172013",
    "district_id": "320817",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3208172014",
    "district_id": "320817",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3208172015",
    "district_id": "320817",
    "label": "Sagarahiyang",
    "value": "Sagarahiyang"
  },
  {
    "id": "3208172016",
    "district_id": "320817",
    "label": "Gunungsirah",
    "value": "Gunungsirah"
  },
  {
    "id": "3208172017",
    "district_id": "320817",
    "label": "Sakerta Timur",
    "value": "Sakerta Timur"
  },
  {
    "id": "3208172018",
    "district_id": "320817",
    "label": "Tugumulya",
    "value": "Tugumulya"
  },
  {
    "id": "3208172019",
    "district_id": "320817",
    "label": "Situsari",
    "value": "Situsari"
  },
  {
    "id": "3208181001",
    "district_id": "320818",
    "label": "Cigugur",
    "value": "Cigugur"
  },
  {
    "id": "3208181002",
    "district_id": "320818",
    "label": "Cigadung",
    "value": "Cigadung"
  },
  {
    "id": "3208181003",
    "district_id": "320818",
    "label": "Winduherang",
    "value": "Winduherang"
  },
  {
    "id": "3208181004",
    "district_id": "320818",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3208181005",
    "district_id": "320818",
    "label": "Cipari",
    "value": "Cipari"
  },
  {
    "id": "3208182006",
    "district_id": "320818",
    "label": "Cileuleuy",
    "value": "Cileuleuy"
  },
  {
    "id": "3208182007",
    "district_id": "320818",
    "label": "Puncak",
    "value": "Puncak"
  },
  {
    "id": "3208182008",
    "district_id": "320818",
    "label": "Gunungkeling",
    "value": "Gunungkeling"
  },
  {
    "id": "3208182009",
    "district_id": "320818",
    "label": "Cisantana",
    "value": "Cisantana"
  },
  {
    "id": "3208182010",
    "district_id": "320818",
    "label": "Babakanmulya",
    "value": "Babakanmulya"
  },
  {
    "id": "3208192001",
    "district_id": "320819",
    "label": "Padabeunghar",
    "value": "Padabeunghar"
  },
  {
    "id": "3208192002",
    "district_id": "320819",
    "label": "Kaduela",
    "value": "Kaduela"
  },
  {
    "id": "3208192003",
    "district_id": "320819",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3208192004",
    "district_id": "320819",
    "label": "Ciwiru",
    "value": "Ciwiru"
  },
  {
    "id": "3208192005",
    "district_id": "320819",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3208192006",
    "district_id": "320819",
    "label": "Cimara",
    "value": "Cimara"
  },
  {
    "id": "3208192007",
    "district_id": "320819",
    "label": "Paniis",
    "value": "Paniis"
  },
  {
    "id": "3208192008",
    "district_id": "320819",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3208192009",
    "district_id": "320819",
    "label": "Singkup",
    "value": "Singkup"
  },
  {
    "id": "3208192010",
    "district_id": "320819",
    "label": "Padamatang",
    "value": "Padamatang"
  },
  {
    "id": "3208202001",
    "district_id": "320820",
    "label": "Haurkuning",
    "value": "Haurkuning"
  },
  {
    "id": "3208202002",
    "district_id": "320820",
    "label": "Kertayuga",
    "value": "Kertayuga"
  },
  {
    "id": "3208202003",
    "district_id": "320820",
    "label": "Windusari",
    "value": "Windusari"
  },
  {
    "id": "3208202004",
    "district_id": "320820",
    "label": "Nusaherang",
    "value": "Nusaherang"
  },
  {
    "id": "3208202005",
    "district_id": "320820",
    "label": "Jambar",
    "value": "Jambar"
  },
  {
    "id": "3208202006",
    "district_id": "320820",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3208202007",
    "district_id": "320820",
    "label": "Kertawirama",
    "value": "Kertawirama"
  },
  {
    "id": "3208202008",
    "district_id": "320820",
    "label": "Ciasih",
    "value": "Ciasih"
  },
  {
    "id": "3208212001",
    "district_id": "320821",
    "label": "Muncangela",
    "value": "Muncangela"
  },
  {
    "id": "3208212002",
    "district_id": "320821",
    "label": "Karoya",
    "value": "Karoya"
  },
  {
    "id": "3208212003",
    "district_id": "320821",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3208212004",
    "district_id": "320821",
    "label": "Cimaranten",
    "value": "Cimaranten"
  },
  {
    "id": "3208212005",
    "district_id": "320821",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3208212006",
    "district_id": "320821",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3208212007",
    "district_id": "320821",
    "label": "Salareuma",
    "value": "Salareuma"
  },
  {
    "id": "3208212008",
    "district_id": "320821",
    "label": "Suganangan",
    "value": "Suganangan"
  },
  {
    "id": "3208212009",
    "district_id": "320821",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3208212010",
    "district_id": "320821",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3208222001",
    "district_id": "320822",
    "label": "Silebu",
    "value": "Silebu"
  },
  {
    "id": "3208222002",
    "district_id": "320822",
    "label": "Patalagan",
    "value": "Patalagan"
  },
  {
    "id": "3208222003",
    "district_id": "320822",
    "label": "Kahiyangan",
    "value": "Kahiyangan"
  },
  {
    "id": "3208222004",
    "district_id": "320822",
    "label": "Tajurbuntu",
    "value": "Tajurbuntu"
  },
  {
    "id": "3208222005",
    "district_id": "320822",
    "label": "Pancalang",
    "value": "Pancalang"
  },
  {
    "id": "3208222006",
    "district_id": "320822",
    "label": "Sumbakeling",
    "value": "Sumbakeling"
  },
  {
    "id": "3208222007",
    "district_id": "320822",
    "label": "Sarewu",
    "value": "Sarewu"
  },
  {
    "id": "3208222008",
    "district_id": "320822",
    "label": "Sindangkempeng",
    "value": "Sindangkempeng"
  },
  {
    "id": "3208222009",
    "district_id": "320822",
    "label": "Rajawetan",
    "value": "Rajawetan"
  },
  {
    "id": "3208222010",
    "district_id": "320822",
    "label": "Tenjolayar",
    "value": "Tenjolayar"
  },
  {
    "id": "3208222011",
    "district_id": "320822",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3208222012",
    "district_id": "320822",
    "label": "Tarikolot",
    "value": "Tarikolot"
  },
  {
    "id": "3208222013",
    "district_id": "320822",
    "label": "Danalampah",
    "value": "Danalampah"
  },
  {
    "id": "3208232001",
    "district_id": "320823",
    "label": "Garatengah",
    "value": "Garatengah"
  },
  {
    "id": "3208232002",
    "district_id": "320823",
    "label": "Rajadanu",
    "value": "Rajadanu"
  },
  {
    "id": "3208232003",
    "district_id": "320823",
    "label": "Singkup",
    "value": "Singkup"
  },
  {
    "id": "3208232004",
    "district_id": "320823",
    "label": "Japara",
    "value": "Japara"
  },
  {
    "id": "3208232005",
    "district_id": "320823",
    "label": "Wano",
    "value": "Wano"
  },
  {
    "id": "3208232006",
    "district_id": "320823",
    "label": "Citapen",
    "value": "Citapen"
  },
  {
    "id": "3208232007",
    "district_id": "320823",
    "label": "Cengal",
    "value": "Cengal"
  },
  {
    "id": "3208232008",
    "district_id": "320823",
    "label": "Cikeleng",
    "value": "Cikeleng"
  },
  {
    "id": "3208232009",
    "district_id": "320823",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3208232010",
    "district_id": "320823",
    "label": "Dukuhdalem",
    "value": "Dukuhdalem"
  },
  {
    "id": "3208242003",
    "district_id": "320824",
    "label": "Cileuya",
    "value": "Cileuya"
  },
  {
    "id": "3208242004",
    "district_id": "320824",
    "label": "Margamukti",
    "value": "Margamukti"
  },
  {
    "id": "3208242005",
    "district_id": "320824",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3208242006",
    "district_id": "320824",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "3208242007",
    "district_id": "320824",
    "label": "Kananga",
    "value": "Kananga"
  },
  {
    "id": "3208242008",
    "district_id": "320824",
    "label": "Cikeusal",
    "value": "Cikeusal"
  },
  {
    "id": "3208242009",
    "district_id": "320824",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3208242010",
    "district_id": "320824",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3208242011",
    "district_id": "320824",
    "label": "Mulyajaya",
    "value": "Mulyajaya"
  },
  {
    "id": "3208242012",
    "district_id": "320824",
    "label": "Cimulya",
    "value": "Cimulya"
  },
  {
    "id": "3208252001",
    "district_id": "320825",
    "label": "Legokherang",
    "value": "Legokherang"
  },
  {
    "id": "3208252002",
    "district_id": "320825",
    "label": "Bungurberes",
    "value": "Bungurberes"
  },
  {
    "id": "3208252003",
    "district_id": "320825",
    "label": "Cilebak",
    "value": "Cilebak"
  },
  {
    "id": "3208252004",
    "district_id": "320825",
    "label": "Jalatrang",
    "value": "Jalatrang"
  },
  {
    "id": "3208252005",
    "district_id": "320825",
    "label": "Patala",
    "value": "Patala"
  },
  {
    "id": "3208252006",
    "district_id": "320825",
    "label": "Madapajaya",
    "value": "Madapajaya"
  },
  {
    "id": "3208252007",
    "district_id": "320825",
    "label": "Cilimusari",
    "value": "Cilimusari"
  },
  {
    "id": "3208262001",
    "district_id": "320826",
    "label": "Citapen",
    "value": "Citapen"
  },
  {
    "id": "3208262002",
    "district_id": "320826",
    "label": "Pasiragung",
    "value": "Pasiragung"
  },
  {
    "id": "3208262003",
    "district_id": "320826",
    "label": "Tundagan",
    "value": "Tundagan"
  },
  {
    "id": "3208262004",
    "district_id": "320826",
    "label": "Bunigeulis",
    "value": "Bunigeulis"
  },
  {
    "id": "3208262005",
    "district_id": "320826",
    "label": "Hantara",
    "value": "Hantara"
  },
  {
    "id": "3208262006",
    "district_id": "320826",
    "label": "Pakapasan Girang",
    "value": "Pakapasan Girang"
  },
  {
    "id": "3208262007",
    "district_id": "320826",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3208262008",
    "district_id": "320826",
    "label": "Pakapasan Hilir",
    "value": "Pakapasan Hilir"
  },
  {
    "id": "3208272001",
    "district_id": "320827",
    "label": "Kalimanggis Kulon",
    "value": "Kalimanggis Kulon"
  },
  {
    "id": "3208272002",
    "district_id": "320827",
    "label": "Partawangunan",
    "value": "Partawangunan"
  },
  {
    "id": "3208272003",
    "district_id": "320827",
    "label": "Cipancur",
    "value": "Cipancur"
  },
  {
    "id": "3208272004",
    "district_id": "320827",
    "label": "Kertawana",
    "value": "Kertawana"
  },
  {
    "id": "3208272005",
    "district_id": "320827",
    "label": "Wanasaraya",
    "value": "Wanasaraya"
  },
  {
    "id": "3208272006",
    "district_id": "320827",
    "label": "Kalimanggis Wetan",
    "value": "Kalimanggis Wetan"
  },
  {
    "id": "3208282001",
    "district_id": "320828",
    "label": "Cimara",
    "value": "Cimara"
  },
  {
    "id": "3208282002",
    "district_id": "320828",
    "label": "Sumurwiru",
    "value": "Sumurwiru"
  },
  {
    "id": "3208282003",
    "district_id": "320828",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3208282004",
    "district_id": "320828",
    "label": "Tarikolot",
    "value": "Tarikolot"
  },
  {
    "id": "3208282005",
    "district_id": "320828",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3208282006",
    "district_id": "320828",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3208282007",
    "district_id": "320828",
    "label": "Kawungsari",
    "value": "Kawungsari"
  },
  {
    "id": "3208282008",
    "district_id": "320828",
    "label": "Sukarapih",
    "value": "Sukarapih"
  },
  {
    "id": "3208292001",
    "district_id": "320829",
    "label": "Karangkancana",
    "value": "Karangkancana"
  },
  {
    "id": "3208292002",
    "district_id": "320829",
    "label": "Kaduagung",
    "value": "Kaduagung"
  },
  {
    "id": "3208292003",
    "district_id": "320829",
    "label": "Segong",
    "value": "Segong"
  },
  {
    "id": "3208292004",
    "district_id": "320829",
    "label": "Tanjungkerta",
    "value": "Tanjungkerta"
  },
  {
    "id": "3208292005",
    "district_id": "320829",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3208292006",
    "district_id": "320829",
    "label": "Margacina",
    "value": "Margacina"
  },
  {
    "id": "3208292007",
    "district_id": "320829",
    "label": "Jabranti",
    "value": "Jabranti"
  },
  {
    "id": "3208292008",
    "district_id": "320829",
    "label": "Simpayjaya",
    "value": "Simpayjaya"
  },
  {
    "id": "3208292009",
    "district_id": "320829",
    "label": "Cihanjaro",
    "value": "Cihanjaro"
  },
  {
    "id": "3208302001",
    "district_id": "320830",
    "label": "Garahaji",
    "value": "Garahaji"
  },
  {
    "id": "3208302002",
    "district_id": "320830",
    "label": "Galaherang",
    "value": "Galaherang"
  },
  {
    "id": "3208302003",
    "district_id": "320830",
    "label": "Cipakem",
    "value": "Cipakem"
  },
  {
    "id": "3208302004",
    "district_id": "320830",
    "label": "Maleber",
    "value": "Maleber"
  },
  {
    "id": "3208302005",
    "district_id": "320830",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3208302006",
    "district_id": "320830",
    "label": "Kutaraja",
    "value": "Kutaraja"
  },
  {
    "id": "3208302007",
    "district_id": "320830",
    "label": "Ciporang",
    "value": "Ciporang"
  },
  {
    "id": "3208302008",
    "district_id": "320830",
    "label": "Dukuhtengah",
    "value": "Dukuhtengah"
  },
  {
    "id": "3208302009",
    "district_id": "320830",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3208302010",
    "district_id": "320830",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3208302011",
    "district_id": "320830",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3208302012",
    "district_id": "320830",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3208302013",
    "district_id": "320830",
    "label": "Kutamandarakan",
    "value": "Kutamandarakan"
  },
  {
    "id": "3208302014",
    "district_id": "320830",
    "label": "Mandalajaya",
    "value": "Mandalajaya"
  },
  {
    "id": "3208302015",
    "district_id": "320830",
    "label": "Giriwaringin",
    "value": "Giriwaringin"
  },
  {
    "id": "3208302016",
    "district_id": "320830",
    "label": "Buniasih",
    "value": "Buniasih"
  },
  {
    "id": "3208312001",
    "district_id": "320831",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3208312002",
    "district_id": "320831",
    "label": "Kaduagung",
    "value": "Kaduagung"
  },
  {
    "id": "3208312003",
    "district_id": "320831",
    "label": "Kertaungaran",
    "value": "Kertaungaran"
  },
  {
    "id": "3208312004",
    "district_id": "320831",
    "label": "Sindangagung",
    "value": "Sindangagung"
  },
  {
    "id": "3208312005",
    "district_id": "320831",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3208312006",
    "district_id": "320831",
    "label": "Kertayasa",
    "value": "Kertayasa"
  },
  {
    "id": "3208312007",
    "district_id": "320831",
    "label": "Kertawangunan",
    "value": "Kertawangunan"
  },
  {
    "id": "3208312008",
    "district_id": "320831",
    "label": "Babakanreuma",
    "value": "Babakanreuma"
  },
  {
    "id": "3208312009",
    "district_id": "320831",
    "label": "Tirtawangunan",
    "value": "Tirtawangunan"
  },
  {
    "id": "3208312010",
    "district_id": "320831",
    "label": "Dukuh Lor",
    "value": "Dukuh Lor"
  },
  {
    "id": "3208312011",
    "district_id": "320831",
    "label": "Taraju",
    "value": "Taraju"
  },
  {
    "id": "3208312012",
    "district_id": "320831",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3208322001",
    "district_id": "320832",
    "label": "Bunigeulis",
    "value": "Bunigeulis"
  },
  {
    "id": "3208322002",
    "district_id": "320832",
    "label": "Jambugeulis",
    "value": "Jambugeulis"
  },
  {
    "id": "3208322003",
    "district_id": "320832",
    "label": "Koreak",
    "value": "Koreak"
  },
  {
    "id": "3208322004",
    "district_id": "320832",
    "label": "Karangmuncang",
    "value": "Karangmuncang"
  },
  {
    "id": "3208322005",
    "district_id": "320832",
    "label": "Sangkanhurip",
    "value": "Sangkanhurip"
  },
  {
    "id": "3208322006",
    "district_id": "320832",
    "label": "Panawuan",
    "value": "Panawuan"
  },
  {
    "id": "3208322007",
    "district_id": "320832",
    "label": "Timbang",
    "value": "Timbang"
  },
  {
    "id": "3208322008",
    "district_id": "320832",
    "label": "Babakanjati",
    "value": "Babakanjati"
  },
  {
    "id": "3208322009",
    "district_id": "320832",
    "label": "Indapatra",
    "value": "Indapatra"
  },
  {
    "id": "3208322010",
    "district_id": "320832",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3208322011",
    "district_id": "320832",
    "label": "Sangkanmulya",
    "value": "Sangkanmulya"
  },
  {
    "id": "3209012008",
    "district_id": "320901",
    "label": "Waled Desa",
    "value": "Waled Desa"
  },
  {
    "id": "3209012009",
    "district_id": "320901",
    "label": "Waled Kota",
    "value": "Waled Kota"
  },
  {
    "id": "3209012010",
    "district_id": "320901",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3209012011",
    "district_id": "320901",
    "label": "Waled Asem",
    "value": "Waled Asem"
  },
  {
    "id": "3209012012",
    "district_id": "320901",
    "label": "Ambit",
    "value": "Ambit"
  },
  {
    "id": "3209012013",
    "district_id": "320901",
    "label": "Ciuyah",
    "value": "Ciuyah"
  },
  {
    "id": "3209012014",
    "district_id": "320901",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3209012015",
    "district_id": "320901",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3209012016",
    "district_id": "320901",
    "label": "Cikulak Kidul",
    "value": "Cikulak Kidul"
  },
  {
    "id": "3209012017",
    "district_id": "320901",
    "label": "Cikulak",
    "value": "Cikulak"
  },
  {
    "id": "3209012018",
    "district_id": "320901",
    "label": "Cibogo",
    "value": "Cibogo"
  },
  {
    "id": "3209012019",
    "district_id": "320901",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3209022008",
    "district_id": "320902",
    "label": "Leuweunggajah",
    "value": "Leuweunggajah"
  },
  {
    "id": "3209022009",
    "district_id": "320902",
    "label": "Tenjomaya",
    "value": "Tenjomaya"
  },
  {
    "id": "3209022010",
    "district_id": "320902",
    "label": "Damarguna",
    "value": "Damarguna"
  },
  {
    "id": "3209022011",
    "district_id": "320902",
    "label": "Jatiseeng",
    "value": "Jatiseeng"
  },
  {
    "id": "3209022012",
    "district_id": "320902",
    "label": "Jatiseeng Kidul",
    "value": "Jatiseeng Kidul"
  },
  {
    "id": "3209022013",
    "district_id": "320902",
    "label": "Ciledug Kulon",
    "value": "Ciledug Kulon"
  },
  {
    "id": "3209022014",
    "district_id": "320902",
    "label": "Ciledug Wetan",
    "value": "Ciledug Wetan"
  },
  {
    "id": "3209022015",
    "district_id": "320902",
    "label": "Ciledug Lor",
    "value": "Ciledug Lor"
  },
  {
    "id": "3209022016",
    "district_id": "320902",
    "label": "Ciledug Tengah",
    "value": "Ciledug Tengah"
  },
  {
    "id": "3209022017",
    "district_id": "320902",
    "label": "Bojongnegara",
    "value": "Bojongnegara"
  },
  {
    "id": "3209032001",
    "district_id": "320903",
    "label": "Astanalanggar",
    "value": "Astanalanggar"
  },
  {
    "id": "3209032002",
    "district_id": "320903",
    "label": "Barisan",
    "value": "Barisan"
  },
  {
    "id": "3209032003",
    "district_id": "320903",
    "label": "Losari Kidul",
    "value": "Losari Kidul"
  },
  {
    "id": "3209032004",
    "district_id": "320903",
    "label": "Panggangsari",
    "value": "Panggangsari"
  },
  {
    "id": "3209032005",
    "district_id": "320903",
    "label": "Losari Lor",
    "value": "Losari Lor"
  },
  {
    "id": "3209032006",
    "district_id": "320903",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3209032007",
    "district_id": "320903",
    "label": "Kalirahayu",
    "value": "Kalirahayu"
  },
  {
    "id": "3209032008",
    "district_id": "320903",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3209032009",
    "district_id": "320903",
    "label": "Ambulu",
    "value": "Ambulu"
  },
  {
    "id": "3209032010",
    "district_id": "320903",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3209042001",
    "district_id": "320904",
    "label": "Pabedilan Kidul",
    "value": "Pabedilan Kidul"
  },
  {
    "id": "3209042002",
    "district_id": "320904",
    "label": "Pabedilan Wetan",
    "value": "Pabedilan Wetan"
  },
  {
    "id": "3209042003",
    "district_id": "320904",
    "label": "Pabedilan Kulon",
    "value": "Pabedilan Kulon"
  },
  {
    "id": "3209042004",
    "district_id": "320904",
    "label": "Pabedilan Kaler",
    "value": "Pabedilan Kaler"
  },
  {
    "id": "3209042005",
    "district_id": "320904",
    "label": "Silih Asih",
    "value": "Silih Asih"
  },
  {
    "id": "3209042006",
    "district_id": "320904",
    "label": "Tersana",
    "value": "Tersana"
  },
  {
    "id": "3209042007",
    "district_id": "320904",
    "label": "Pasuruan",
    "value": "Pasuruan"
  },
  {
    "id": "3209042008",
    "district_id": "320904",
    "label": "Dukuhwidara",
    "value": "Dukuhwidara"
  },
  {
    "id": "3209042009",
    "district_id": "320904",
    "label": "Kalibuntu",
    "value": "Kalibuntu"
  },
  {
    "id": "3209042010",
    "district_id": "320904",
    "label": "Kalimukti",
    "value": "Kalimukti"
  },
  {
    "id": "3209042011",
    "district_id": "320904",
    "label": "Sidaresmi",
    "value": "Sidaresmi"
  },
  {
    "id": "3209042012",
    "district_id": "320904",
    "label": "Babakan Losari",
    "value": "Babakan Losari"
  },
  {
    "id": "3209042013",
    "district_id": "320904",
    "label": "Babakan Losari Lor",
    "value": "Babakan Losari Lor"
  },
  {
    "id": "3209052001",
    "district_id": "320905",
    "label": "Cangkuang",
    "value": "Cangkuang"
  },
  {
    "id": "3209052002",
    "district_id": "320905",
    "label": "Serang Wetan",
    "value": "Serang Wetan"
  },
  {
    "id": "3209052003",
    "district_id": "320905",
    "label": "Serang Kulon",
    "value": "Serang Kulon"
  },
  {
    "id": "3209052004",
    "district_id": "320905",
    "label": "Gembongan Mekar",
    "value": "Gembongan Mekar"
  },
  {
    "id": "3209052005",
    "district_id": "320905",
    "label": "Gembongan",
    "value": "Gembongan"
  },
  {
    "id": "3209052006",
    "district_id": "320905",
    "label": "Babakan Gebang",
    "value": "Babakan Gebang"
  },
  {
    "id": "3209052007",
    "district_id": "320905",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3209052008",
    "district_id": "320905",
    "label": "Bojonggebang",
    "value": "Bojonggebang"
  },
  {
    "id": "3209052009",
    "district_id": "320905",
    "label": "Sumber Kidul",
    "value": "Sumber Kidul"
  },
  {
    "id": "3209052010",
    "district_id": "320905",
    "label": "Sumber Lor",
    "value": "Sumber Lor"
  },
  {
    "id": "3209052011",
    "district_id": "320905",
    "label": "Kudumulya",
    "value": "Kudumulya"
  },
  {
    "id": "3209052012",
    "district_id": "320905",
    "label": "Kudukeras",
    "value": "Kudukeras"
  },
  {
    "id": "3209052013",
    "district_id": "320905",
    "label": "Pakusamben",
    "value": "Pakusamben"
  },
  {
    "id": "3209052014",
    "district_id": "320905",
    "label": "Karangwangun",
    "value": "Karangwangun"
  },
  {
    "id": "3209062006",
    "district_id": "320906",
    "label": "Tambelang",
    "value": "Tambelang"
  },
  {
    "id": "3209062011",
    "district_id": "320906",
    "label": "Karangsuwung",
    "value": "Karangsuwung"
  },
  {
    "id": "3209062012",
    "district_id": "320906",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3209062013",
    "district_id": "320906",
    "label": "Kalimeang",
    "value": "Kalimeang"
  },
  {
    "id": "3209062014",
    "district_id": "320906",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3209062015",
    "district_id": "320906",
    "label": "Karangmekar",
    "value": "Karangmekar"
  },
  {
    "id": "3209062016",
    "district_id": "320906",
    "label": "Kubangkarang",
    "value": "Kubangkarang"
  },
  {
    "id": "3209062017",
    "district_id": "320906",
    "label": "Karangsembung",
    "value": "Karangsembung"
  },
  {
    "id": "3209072001",
    "district_id": "320907",
    "label": "Leuwidingding",
    "value": "Leuwidingding"
  },
  {
    "id": "3209072002",
    "district_id": "320907",
    "label": "Asem",
    "value": "Asem"
  },
  {
    "id": "3209072003",
    "district_id": "320907",
    "label": "Cipeujeuh Kulon",
    "value": "Cipeujeuh Kulon"
  },
  {
    "id": "3209072004",
    "district_id": "320907",
    "label": "Cipeujeuh Wetan",
    "value": "Cipeujeuh Wetan"
  },
  {
    "id": "3209072005",
    "district_id": "320907",
    "label": "Sindanglaut",
    "value": "Sindanglaut"
  },
  {
    "id": "3209072006",
    "district_id": "320907",
    "label": "Lemahabang Kulon",
    "value": "Lemahabang Kulon"
  },
  {
    "id": "3209072007",
    "district_id": "320907",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "3209072008",
    "district_id": "320907",
    "label": "Sigong",
    "value": "Sigong"
  },
  {
    "id": "3209072009",
    "district_id": "320907",
    "label": "Sarajaya",
    "value": "Sarajaya"
  },
  {
    "id": "3209072010",
    "district_id": "320907",
    "label": "Picungpugur",
    "value": "Picungpugur"
  },
  {
    "id": "3209072011",
    "district_id": "320907",
    "label": "Tuk Karangsuwung",
    "value": "Tuk Karangsuwung"
  },
  {
    "id": "3209072012",
    "district_id": "320907",
    "label": "Belawa",
    "value": "Belawa"
  },
  {
    "id": "3209072013",
    "district_id": "320907",
    "label": "Wangkelang",
    "value": "Wangkelang"
  },
  {
    "id": "3209082001",
    "district_id": "320908",
    "label": "Karangamangu",
    "value": "Karangamangu"
  },
  {
    "id": "3209082002",
    "district_id": "320908",
    "label": "Susukan Lebak",
    "value": "Susukan Lebak"
  },
  {
    "id": "3209082003",
    "district_id": "320908",
    "label": "Susukan Agung",
    "value": "Susukan Agung"
  },
  {
    "id": "3209082004",
    "district_id": "320908",
    "label": "Susukan Tonggoh",
    "value": "Susukan Tonggoh"
  },
  {
    "id": "3209082005",
    "district_id": "320908",
    "label": "Wilulang",
    "value": "Wilulang"
  },
  {
    "id": "3209082006",
    "district_id": "320908",
    "label": "Sampih",
    "value": "Sampih"
  },
  {
    "id": "3209082007",
    "district_id": "320908",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3209082008",
    "district_id": "320908",
    "label": "Ciawiasih",
    "value": "Ciawiasih"
  },
  {
    "id": "3209082009",
    "district_id": "320908",
    "label": "Ciawijapura",
    "value": "Ciawijapura"
  },
  {
    "id": "3209082010",
    "district_id": "320908",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3209082011",
    "district_id": "320908",
    "label": "Curug Wetan",
    "value": "Curug Wetan"
  },
  {
    "id": "3209082012",
    "district_id": "320908",
    "label": "Kaligawe Wetan",
    "value": "Kaligawe Wetan"
  },
  {
    "id": "3209082013",
    "district_id": "320908",
    "label": "Kaligawe",
    "value": "Kaligawe"
  },
  {
    "id": "3209092001",
    "district_id": "320909",
    "label": "Karangwuni",
    "value": "Karangwuni"
  },
  {
    "id": "3209092002",
    "district_id": "320909",
    "label": "Sedong Kidul",
    "value": "Sedong Kidul"
  },
  {
    "id": "3209092003",
    "district_id": "320909",
    "label": "Sedong Lor",
    "value": "Sedong Lor"
  },
  {
    "id": "3209092004",
    "district_id": "320909",
    "label": "Windujaya",
    "value": "Windujaya"
  },
  {
    "id": "3209092005",
    "district_id": "320909",
    "label": "Winduhaji",
    "value": "Winduhaji"
  },
  {
    "id": "3209092006",
    "district_id": "320909",
    "label": "Kertawangun",
    "value": "Kertawangun"
  },
  {
    "id": "3209092007",
    "district_id": "320909",
    "label": "Penambangan",
    "value": "Penambangan"
  },
  {
    "id": "3209092008",
    "district_id": "320909",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "3209092009",
    "district_id": "320909",
    "label": "Panongan",
    "value": "Panongan"
  },
  {
    "id": "3209092010",
    "district_id": "320909",
    "label": "Panongan Lor",
    "value": "Panongan Lor"
  },
  {
    "id": "3209102004",
    "district_id": "320910",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3209102005",
    "district_id": "320910",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3209102006",
    "district_id": "320910",
    "label": "Mertapada Kulon",
    "value": "Mertapada Kulon"
  },
  {
    "id": "3209102007",
    "district_id": "320910",
    "label": "Mertapada Wetan",
    "value": "Mertapada Wetan"
  },
  {
    "id": "3209102008",
    "district_id": "320910",
    "label": "Buntet",
    "value": "Buntet"
  },
  {
    "id": "3209102009",
    "district_id": "320910",
    "label": "Kanci Kulon",
    "value": "Kanci Kulon"
  },
  {
    "id": "3209102010",
    "district_id": "320910",
    "label": "Kanci",
    "value": "Kanci"
  },
  {
    "id": "3209102011",
    "district_id": "320910",
    "label": "Astanajapura",
    "value": "Astanajapura"
  },
  {
    "id": "3209102012",
    "district_id": "320910",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "3209102013",
    "district_id": "320910",
    "label": "Japura Kidul",
    "value": "Japura Kidul"
  },
  {
    "id": "3209102014",
    "district_id": "320910",
    "label": "Japura Bakti",
    "value": "Japura Bakti"
  },
  {
    "id": "3209112001",
    "district_id": "320911",
    "label": "Getrakmoyan",
    "value": "Getrakmoyan"
  },
  {
    "id": "3209112002",
    "district_id": "320911",
    "label": "Ender",
    "value": "Ender"
  },
  {
    "id": "3209112003",
    "district_id": "320911",
    "label": "Pangenan",
    "value": "Pangenan"
  },
  {
    "id": "3209112004",
    "district_id": "320911",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3209112005",
    "district_id": "320911",
    "label": "Rawaurip",
    "value": "Rawaurip"
  },
  {
    "id": "3209112006",
    "district_id": "320911",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "3209112007",
    "district_id": "320911",
    "label": "Japura Lor",
    "value": "Japura Lor"
  },
  {
    "id": "3209112008",
    "district_id": "320911",
    "label": "Pengarengan",
    "value": "Pengarengan"
  },
  {
    "id": "3209112009",
    "district_id": "320911",
    "label": "Astana Mukti",
    "value": "Astana Mukti"
  },
  {
    "id": "3209122001",
    "district_id": "320912",
    "label": "Setupatok",
    "value": "Setupatok"
  },
  {
    "id": "3209122002",
    "district_id": "320912",
    "label": "Penpen",
    "value": "Penpen"
  },
  {
    "id": "3209122003",
    "district_id": "320912",
    "label": "Mundumesigit",
    "value": "Mundumesigit"
  },
  {
    "id": "3209122004",
    "district_id": "320912",
    "label": "Luwung",
    "value": "Luwung"
  },
  {
    "id": "3209122005",
    "district_id": "320912",
    "label": "Waruduwur",
    "value": "Waruduwur"
  },
  {
    "id": "3209122006",
    "district_id": "320912",
    "label": "Citemu",
    "value": "Citemu"
  },
  {
    "id": "3209122007",
    "district_id": "320912",
    "label": "Bandengan",
    "value": "Bandengan"
  },
  {
    "id": "3209122008",
    "district_id": "320912",
    "label": "Mundupesisir",
    "value": "Mundupesisir"
  },
  {
    "id": "3209122009",
    "district_id": "320912",
    "label": "Suci",
    "value": "Suci"
  },
  {
    "id": "3209122010",
    "district_id": "320912",
    "label": "Banjarwangunan",
    "value": "Banjarwangunan"
  },
  {
    "id": "3209122011",
    "district_id": "320912",
    "label": "Pamengkang",
    "value": "Pamengkang"
  },
  {
    "id": "3209122012",
    "district_id": "320912",
    "label": "Sinar Rancang",
    "value": "Sinar Rancang"
  },
  {
    "id": "3209132001",
    "district_id": "320913",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "3209132002",
    "district_id": "320913",
    "label": "Sindangkasih",
    "value": "Sindangkasih"
  },
  {
    "id": "3209132003",
    "district_id": "320913",
    "label": "Sindanghayu",
    "value": "Sindanghayu"
  },
  {
    "id": "3209132004",
    "district_id": "320913",
    "label": "Ciawigajah",
    "value": "Ciawigajah"
  },
  {
    "id": "3209132008",
    "district_id": "320913",
    "label": "Cikancas",
    "value": "Cikancas"
  },
  {
    "id": "3209132009",
    "district_id": "320913",
    "label": "Halimpu",
    "value": "Halimpu"
  },
  {
    "id": "3209132010",
    "district_id": "320913",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3209132011",
    "district_id": "320913",
    "label": "Beber",
    "value": "Beber"
  },
  {
    "id": "3209132012",
    "district_id": "320913",
    "label": "Patapan",
    "value": "Patapan"
  },
  {
    "id": "3209132013",
    "district_id": "320913",
    "label": "Kondangsari",
    "value": "Kondangsari"
  },
  {
    "id": "3209142001",
    "district_id": "320914",
    "label": "Sampiran",
    "value": "Sampiran"
  },
  {
    "id": "3209142002",
    "district_id": "320914",
    "label": "Ciperna",
    "value": "Ciperna"
  },
  {
    "id": "3209142003",
    "district_id": "320914",
    "label": "Kecomberan",
    "value": "Kecomberan"
  },
  {
    "id": "3209142004",
    "district_id": "320914",
    "label": "Cirebon Girang",
    "value": "Cirebon Girang"
  },
  {
    "id": "3209142005",
    "district_id": "320914",
    "label": "Kerandon",
    "value": "Kerandon"
  },
  {
    "id": "3209142006",
    "district_id": "320914",
    "label": "Wanasaba Kidul",
    "value": "Wanasaba Kidul"
  },
  {
    "id": "3209142007",
    "district_id": "320914",
    "label": "Wanasaba Lor",
    "value": "Wanasaba Lor"
  },
  {
    "id": "3209142008",
    "district_id": "320914",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "3209142009",
    "district_id": "320914",
    "label": "Kepongpongan",
    "value": "Kepongpongan"
  },
  {
    "id": "3209142010",
    "district_id": "320914",
    "label": "Sarwadadi",
    "value": "Sarwadadi"
  },
  {
    "id": "3209142011",
    "district_id": "320914",
    "label": "Kubang",
    "value": "Kubang"
  },
  {
    "id": "3209151003",
    "district_id": "320915",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3209151004",
    "district_id": "320915",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3209151005",
    "district_id": "320915",
    "label": "Perbutulan",
    "value": "Perbutulan"
  },
  {
    "id": "3209151006",
    "district_id": "320915",
    "label": "Kaliwadas",
    "value": "Kaliwadas"
  },
  {
    "id": "3209151007",
    "district_id": "320915",
    "label": "Pasalakan",
    "value": "Pasalakan"
  },
  {
    "id": "3209151008",
    "district_id": "320915",
    "label": "Watubelah",
    "value": "Watubelah"
  },
  {
    "id": "3209151009",
    "district_id": "320915",
    "label": "Pejambon",
    "value": "Pejambon"
  },
  {
    "id": "3209151010",
    "district_id": "320915",
    "label": "Gegunung",
    "value": "Gegunung"
  },
  {
    "id": "3209151011",
    "district_id": "320915",
    "label": "Kemantren",
    "value": "Kemantren"
  },
  {
    "id": "3209151012",
    "district_id": "320915",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3209151013",
    "district_id": "320915",
    "label": "Tukmudal",
    "value": "Tukmudal"
  },
  {
    "id": "3209151014",
    "district_id": "320915",
    "label": "Kenanga",
    "value": "Kenanga"
  },
  {
    "id": "3209152001",
    "district_id": "320915",
    "label": "Matangaji",
    "value": "Matangaji"
  },
  {
    "id": "3209152002",
    "district_id": "320915",
    "label": "Sidawangi",
    "value": "Sidawangi"
  },
  {
    "id": "3209162001",
    "district_id": "320916",
    "label": "Bobos",
    "value": "Bobos"
  },
  {
    "id": "3209162002",
    "district_id": "320916",
    "label": "Cikalahang",
    "value": "Cikalahang"
  },
  {
    "id": "3209162003",
    "district_id": "320916",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "3209162004",
    "district_id": "320916",
    "label": "Dukupuntang",
    "value": "Dukupuntang"
  },
  {
    "id": "3209162005",
    "district_id": "320916",
    "label": "Balad",
    "value": "Balad"
  },
  {
    "id": "3209162006",
    "district_id": "320916",
    "label": "Cangkoak",
    "value": "Cangkoak"
  },
  {
    "id": "3209162007",
    "district_id": "320916",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3209162008",
    "district_id": "320916",
    "label": "Sindangmekar",
    "value": "Sindangmekar"
  },
  {
    "id": "3209162009",
    "district_id": "320916",
    "label": "Sindangjawa",
    "value": "Sindangjawa"
  },
  {
    "id": "3209162010",
    "district_id": "320916",
    "label": "Kepunduan",
    "value": "Kepunduan"
  },
  {
    "id": "3209162011",
    "district_id": "320916",
    "label": "Girinata",
    "value": "Girinata"
  },
  {
    "id": "3209162012",
    "district_id": "320916",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "3209162013",
    "district_id": "320916",
    "label": "Kedongdong Kidul",
    "value": "Kedongdong Kidul"
  },
  {
    "id": "3209172002",
    "district_id": "320917",
    "label": "Cilukrak",
    "value": "Cilukrak"
  },
  {
    "id": "3209172003",
    "district_id": "320917",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3209172004",
    "district_id": "320917",
    "label": "Balerante",
    "value": "Balerante"
  },
  {
    "id": "3209172005",
    "district_id": "320917",
    "label": "Panongan",
    "value": "Panongan"
  },
  {
    "id": "3209172006",
    "district_id": "320917",
    "label": "Beberan",
    "value": "Beberan"
  },
  {
    "id": "3209172007",
    "district_id": "320917",
    "label": "Semplo",
    "value": "Semplo"
  },
  {
    "id": "3209172008",
    "district_id": "320917",
    "label": "Palimanan Timur",
    "value": "Palimanan Timur"
  },
  {
    "id": "3209172011",
    "district_id": "320917",
    "label": "Pegagan",
    "value": "Pegagan"
  },
  {
    "id": "3209172012",
    "district_id": "320917",
    "label": "Lungbenda",
    "value": "Lungbenda"
  },
  {
    "id": "3209172013",
    "district_id": "320917",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3209172014",
    "district_id": "320917",
    "label": "Tegalkarang",
    "value": "Tegalkarang"
  },
  {
    "id": "3209172015",
    "district_id": "320917",
    "label": "Cengkuang",
    "value": "Cengkuang"
  },
  {
    "id": "3209182001",
    "district_id": "320918",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "3209182002",
    "district_id": "320918",
    "label": "Pamijahan",
    "value": "Pamijahan"
  },
  {
    "id": "3209182003",
    "district_id": "320918",
    "label": "Lurah",
    "value": "Lurah"
  },
  {
    "id": "3209182004",
    "district_id": "320918",
    "label": "Marikangen",
    "value": "Marikangen"
  },
  {
    "id": "3209182005",
    "district_id": "320918",
    "label": "Bode Lor",
    "value": "Bode Lor"
  },
  {
    "id": "3209182006",
    "district_id": "320918",
    "label": "Bodesari",
    "value": "Bodesari"
  },
  {
    "id": "3209182007",
    "district_id": "320918",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3209182008",
    "district_id": "320918",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3209182009",
    "district_id": "320918",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3209182010",
    "district_id": "320918",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3209182011",
    "district_id": "320918",
    "label": "Purbawinangun",
    "value": "Purbawinangun"
  },
  {
    "id": "3209182012",
    "district_id": "320918",
    "label": "Kebarepan",
    "value": "Kebarepan"
  },
  {
    "id": "3209182013",
    "district_id": "320918",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3209182014",
    "district_id": "320918",
    "label": "Kedungsana",
    "value": "Kedungsana"
  },
  {
    "id": "3209182015",
    "district_id": "320918",
    "label": "Danamulya",
    "value": "Danamulya"
  },
  {
    "id": "3209192001",
    "district_id": "320919",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3209192002",
    "district_id": "320919",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3209192003",
    "district_id": "320919",
    "label": "Megugede",
    "value": "Megugede"
  },
  {
    "id": "3209192004",
    "district_id": "320919",
    "label": "Megucilik",
    "value": "Megucilik"
  },
  {
    "id": "3209192005",
    "district_id": "320919",
    "label": "Setu Wetan",
    "value": "Setu Wetan"
  },
  {
    "id": "3209192006",
    "district_id": "320919",
    "label": "Setu Kulon",
    "value": "Setu Kulon"
  },
  {
    "id": "3209192007",
    "district_id": "320919",
    "label": "Weru Kidul",
    "value": "Weru Kidul"
  },
  {
    "id": "3209192008",
    "district_id": "320919",
    "label": "Weru Lor",
    "value": "Weru Lor"
  },
  {
    "id": "3209192009",
    "district_id": "320919",
    "label": "Tegalwangi",
    "value": "Tegalwangi"
  },
  {
    "id": "3209202004",
    "district_id": "320920",
    "label": "Kedungdawa",
    "value": "Kedungdawa"
  },
  {
    "id": "3209202005",
    "district_id": "320920",
    "label": "Kalikoa",
    "value": "Kalikoa"
  },
  {
    "id": "3209202006",
    "district_id": "320920",
    "label": "Tuk",
    "value": "Tuk"
  },
  {
    "id": "3209202007",
    "district_id": "320920",
    "label": "Kedungjaya",
    "value": "Kedungjaya"
  },
  {
    "id": "3209202008",
    "district_id": "320920",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3209202009",
    "district_id": "320920",
    "label": "Kertawinangun",
    "value": "Kertawinangun"
  },
  {
    "id": "3209202010",
    "district_id": "320920",
    "label": "Sutawinangun",
    "value": "Sutawinangun"
  },
  {
    "id": "3209202011",
    "district_id": "320920",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3209212001",
    "district_id": "320921",
    "label": "Adidharma",
    "value": "Adidharma"
  },
  {
    "id": "3209212002",
    "district_id": "320921",
    "label": "Pasindangan",
    "value": "Pasindangan"
  },
  {
    "id": "3209212003",
    "district_id": "320921",
    "label": "Jadimulya",
    "value": "Jadimulya"
  },
  {
    "id": "3209212004",
    "district_id": "320921",
    "label": "Klayan",
    "value": "Klayan"
  },
  {
    "id": "3209212005",
    "district_id": "320921",
    "label": "Jatimerta",
    "value": "Jatimerta"
  },
  {
    "id": "3209212006",
    "district_id": "320921",
    "label": "Astana",
    "value": "Astana"
  },
  {
    "id": "3209212007",
    "district_id": "320921",
    "label": "Kalisapu",
    "value": "Kalisapu"
  },
  {
    "id": "3209212008",
    "district_id": "320921",
    "label": "Wanakaya",
    "value": "Wanakaya"
  },
  {
    "id": "3209212009",
    "district_id": "320921",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3209212010",
    "district_id": "320921",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3209212011",
    "district_id": "320921",
    "label": "Buyut",
    "value": "Buyut"
  },
  {
    "id": "3209212012",
    "district_id": "320921",
    "label": "Mayung",
    "value": "Mayung"
  },
  {
    "id": "3209212015",
    "district_id": "320921",
    "label": "Mertasinga",
    "value": "Mertasinga"
  },
  {
    "id": "3209222010",
    "district_id": "320922",
    "label": "Kertasura",
    "value": "Kertasura"
  },
  {
    "id": "3209222011",
    "district_id": "320922",
    "label": "Pegagan Kidul",
    "value": "Pegagan Kidul"
  },
  {
    "id": "3209222012",
    "district_id": "320922",
    "label": "Pegaan Lor",
    "value": "Pegaan Lor"
  },
  {
    "id": "3209222013",
    "district_id": "320922",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3209222014",
    "district_id": "320922",
    "label": "Karangkendal",
    "value": "Karangkendal"
  },
  {
    "id": "3209222015",
    "district_id": "320922",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3209222016",
    "district_id": "320922",
    "label": "Kapetakan",
    "value": "Kapetakan"
  },
  {
    "id": "3209222017",
    "district_id": "320922",
    "label": "Bungko",
    "value": "Bungko"
  },
  {
    "id": "3209222018",
    "district_id": "320922",
    "label": "Bungko Lor",
    "value": "Bungko Lor"
  },
  {
    "id": "3209232001",
    "district_id": "320923",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3209232002",
    "district_id": "320923",
    "label": "Klangenan",
    "value": "Klangenan"
  },
  {
    "id": "3209232003",
    "district_id": "320923",
    "label": "Danawinangun",
    "value": "Danawinangun"
  },
  {
    "id": "3209232007",
    "district_id": "320923",
    "label": "Pakantingan",
    "value": "Pakantingan"
  },
  {
    "id": "3209232008",
    "district_id": "320923",
    "label": "Jemaras Kidul",
    "value": "Jemaras Kidul"
  },
  {
    "id": "3209232009",
    "district_id": "320923",
    "label": "Jemaras Lor",
    "value": "Jemaras Lor"
  },
  {
    "id": "3209232010",
    "district_id": "320923",
    "label": "Slangit",
    "value": "Slangit"
  },
  {
    "id": "3209232011",
    "district_id": "320923",
    "label": "Kreyo",
    "value": "Kreyo"
  },
  {
    "id": "3209232012",
    "district_id": "320923",
    "label": "Bangodua",
    "value": "Bangodua"
  },
  {
    "id": "3209242001",
    "district_id": "320924",
    "label": "Sende",
    "value": "Sende"
  },
  {
    "id": "3209242002",
    "district_id": "320924",
    "label": "Jungjang Wetan",
    "value": "Jungjang Wetan"
  },
  {
    "id": "3209242003",
    "district_id": "320924",
    "label": "Jungjang",
    "value": "Jungjang"
  },
  {
    "id": "3209242004",
    "district_id": "320924",
    "label": "Arjawinangun",
    "value": "Arjawinangun"
  },
  {
    "id": "3209242005",
    "district_id": "320924",
    "label": "Tegalgubug",
    "value": "Tegalgubug"
  },
  {
    "id": "3209242006",
    "district_id": "320924",
    "label": "Tegal Gubug Lor",
    "value": "Tegal Gubug Lor"
  },
  {
    "id": "3209242007",
    "district_id": "320924",
    "label": "Rawagatel",
    "value": "Rawagatel"
  },
  {
    "id": "3209242008",
    "district_id": "320924",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "3209242009",
    "district_id": "320924",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3209242010",
    "district_id": "320924",
    "label": "Geyongan",
    "value": "Geyongan"
  },
  {
    "id": "3209242011",
    "district_id": "320924",
    "label": "Kebonturi",
    "value": "Kebonturi"
  },
  {
    "id": "3209252001",
    "district_id": "320925",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3209252002",
    "district_id": "320925",
    "label": "Pangurangan Kulon",
    "value": "Pangurangan Kulon"
  },
  {
    "id": "3209252003",
    "district_id": "320925",
    "label": "Pangurangan Wetan",
    "value": "Pangurangan Wetan"
  },
  {
    "id": "3209252004",
    "district_id": "320925",
    "label": "Pangurangan Lor",
    "value": "Pangurangan Lor"
  },
  {
    "id": "3209252005",
    "district_id": "320925",
    "label": "Pangurangan",
    "value": "Pangurangan"
  },
  {
    "id": "3209252006",
    "district_id": "320925",
    "label": "Gujeg",
    "value": "Gujeg"
  },
  {
    "id": "3209252007",
    "district_id": "320925",
    "label": "Lemahtamba",
    "value": "Lemahtamba"
  },
  {
    "id": "3209252008",
    "district_id": "320925",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3209252009",
    "district_id": "320925",
    "label": "Kroya",
    "value": "Kroya"
  },
  {
    "id": "3209262004",
    "district_id": "320926",
    "label": "Budur",
    "value": "Budur"
  },
  {
    "id": "3209262005",
    "district_id": "320926",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3209262006",
    "district_id": "320926",
    "label": "Ciwaringin",
    "value": "Ciwaringin"
  },
  {
    "id": "3209262007",
    "district_id": "320926",
    "label": "Gintungranjeng",
    "value": "Gintungranjeng"
  },
  {
    "id": "3209262008",
    "district_id": "320926",
    "label": "Gintung Kidul",
    "value": "Gintung Kidul"
  },
  {
    "id": "3209262009",
    "district_id": "320926",
    "label": "Gintung Tengah",
    "value": "Gintung Tengah"
  },
  {
    "id": "3209262010",
    "district_id": "320926",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3209262011",
    "district_id": "320926",
    "label": "Galagamba",
    "value": "Galagamba"
  },
  {
    "id": "3209272001",
    "district_id": "320927",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3209272002",
    "district_id": "320927",
    "label": "Wiyong",
    "value": "Wiyong"
  },
  {
    "id": "3209272003",
    "district_id": "320927",
    "label": "Kedongdong",
    "value": "Kedongdong"
  },
  {
    "id": "3209272004",
    "district_id": "320927",
    "label": "Gintung Lor",
    "value": "Gintung Lor"
  },
  {
    "id": "3209272005",
    "district_id": "320927",
    "label": "Bojong Kulon",
    "value": "Bojong Kulon"
  },
  {
    "id": "3209272006",
    "district_id": "320927",
    "label": "Kejiwan",
    "value": "Kejiwan"
  },
  {
    "id": "3209272007",
    "district_id": "320927",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3209272008",
    "district_id": "320927",
    "label": "Luwung Kencana",
    "value": "Luwung Kencana"
  },
  {
    "id": "3209272009",
    "district_id": "320927",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3209272010",
    "district_id": "320927",
    "label": "Jatipura",
    "value": "Jatipura"
  },
  {
    "id": "3209272011",
    "district_id": "320927",
    "label": "Ujunggebang",
    "value": "Ujunggebang"
  },
  {
    "id": "3209272012",
    "district_id": "320927",
    "label": "Jatianom",
    "value": "Jatianom"
  },
  {
    "id": "3209282001",
    "district_id": "320928",
    "label": "Kedungdalem",
    "value": "Kedungdalem"
  },
  {
    "id": "3209282002",
    "district_id": "320928",
    "label": "Panunggul",
    "value": "Panunggul"
  },
  {
    "id": "3209282003",
    "district_id": "320928",
    "label": "Gegesik Wetan",
    "value": "Gegesik Wetan"
  },
  {
    "id": "3209282004",
    "district_id": "320928",
    "label": "Gegesik Kidul",
    "value": "Gegesik Kidul"
  },
  {
    "id": "3209282005",
    "district_id": "320928",
    "label": "Gegesik Lor",
    "value": "Gegesik Lor"
  },
  {
    "id": "3209282006",
    "district_id": "320928",
    "label": "Gegesik Kulon",
    "value": "Gegesik Kulon"
  },
  {
    "id": "3209282007",
    "district_id": "320928",
    "label": "Slendra",
    "value": "Slendra"
  },
  {
    "id": "3209282008",
    "district_id": "320928",
    "label": "Jagapura Kidul",
    "value": "Jagapura Kidul"
  },
  {
    "id": "3209282009",
    "district_id": "320928",
    "label": "Jagapura Wetan",
    "value": "Jagapura Wetan"
  },
  {
    "id": "3209282010",
    "district_id": "320928",
    "label": "Jagapura Kulon",
    "value": "Jagapura Kulon"
  },
  {
    "id": "3209282011",
    "district_id": "320928",
    "label": "Jagapura Lor",
    "value": "Jagapura Lor"
  },
  {
    "id": "3209282012",
    "district_id": "320928",
    "label": "Sibubut",
    "value": "Sibubut"
  },
  {
    "id": "3209282013",
    "district_id": "320928",
    "label": "Bayalangu Lor",
    "value": "Bayalangu Lor"
  },
  {
    "id": "3209282014",
    "district_id": "320928",
    "label": "Bayalangu Kidul",
    "value": "Bayalangu Kidul"
  },
  {
    "id": "3209292001",
    "district_id": "320929",
    "label": "Kalideres",
    "value": "Kalideres"
  },
  {
    "id": "3209292002",
    "district_id": "320929",
    "label": "Prajawinangun Kulon",
    "value": "Prajawinangun Kulon"
  },
  {
    "id": "3209292003",
    "district_id": "320929",
    "label": "Prajawinangun Wetan",
    "value": "Prajawinangun Wetan"
  },
  {
    "id": "3209292004",
    "district_id": "320929",
    "label": "Ujungsemi",
    "value": "Ujungsemi"
  },
  {
    "id": "3209292005",
    "district_id": "320929",
    "label": "Wargabinangun",
    "value": "Wargabinangun"
  },
  {
    "id": "3209292006",
    "district_id": "320929",
    "label": "Kaliwedi Lor",
    "value": "Kaliwedi Lor"
  },
  {
    "id": "3209292007",
    "district_id": "320929",
    "label": "Kaliwedi Kidul",
    "value": "Kaliwedi Kidul"
  },
  {
    "id": "3209292008",
    "district_id": "320929",
    "label": "Guwa Kidul",
    "value": "Guwa Kidul"
  },
  {
    "id": "3209292009",
    "district_id": "320929",
    "label": "Guwa Lor",
    "value": "Guwa Lor"
  },
  {
    "id": "3209302001",
    "district_id": "320930",
    "label": "Dompyong Kulon",
    "value": "Dompyong Kulon"
  },
  {
    "id": "3209302002",
    "district_id": "320930",
    "label": "Dompyong Wetan",
    "value": "Dompyong Wetan"
  },
  {
    "id": "3209302003",
    "district_id": "320930",
    "label": "Kalimekar",
    "value": "Kalimekar"
  },
  {
    "id": "3209302004",
    "district_id": "320930",
    "label": "Kalimoro",
    "value": "Kalimoro"
  },
  {
    "id": "3209302005",
    "district_id": "320930",
    "label": "Gagasari",
    "value": "Gagasari"
  },
  {
    "id": "3209302006",
    "district_id": "320930",
    "label": "Kalipasung",
    "value": "Kalipasung"
  },
  {
    "id": "3209302007",
    "district_id": "320930",
    "label": "Gebang Kulon",
    "value": "Gebang Kulon"
  },
  {
    "id": "3209302008",
    "district_id": "320930",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3209302009",
    "district_id": "320930",
    "label": "Gebang Ilir",
    "value": "Gebang Ilir"
  },
  {
    "id": "3209302010",
    "district_id": "320930",
    "label": "Gebang Udik",
    "value": "Gebang Udik"
  },
  {
    "id": "3209302011",
    "district_id": "320930",
    "label": "Gebang Mekar",
    "value": "Gebang Mekar"
  },
  {
    "id": "3209302012",
    "district_id": "320930",
    "label": "Pelayangan",
    "value": "Pelayangan"
  },
  {
    "id": "3209302013",
    "district_id": "320930",
    "label": "Melakasari",
    "value": "Melakasari"
  },
  {
    "id": "3209312001",
    "district_id": "320931",
    "label": "Cikeduk",
    "value": "Cikeduk"
  },
  {
    "id": "3209312002",
    "district_id": "320931",
    "label": "Warujaya",
    "value": "Warujaya"
  },
  {
    "id": "3209312003",
    "district_id": "320931",
    "label": "Warukawung",
    "value": "Warukawung"
  },
  {
    "id": "3209312004",
    "district_id": "320931",
    "label": "Warugede",
    "value": "Warugede"
  },
  {
    "id": "3209312005",
    "district_id": "320931",
    "label": "Waruroyom",
    "value": "Waruroyom"
  },
  {
    "id": "3209312006",
    "district_id": "320931",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3209312007",
    "district_id": "320931",
    "label": "Kasugengan Kidul",
    "value": "Kasugengan Kidul"
  },
  {
    "id": "3209312008",
    "district_id": "320931",
    "label": "Kasugengan Lor",
    "value": "Kasugengan Lor"
  },
  {
    "id": "3209312009",
    "district_id": "320931",
    "label": "Keduanan",
    "value": "Keduanan"
  },
  {
    "id": "3209312010",
    "district_id": "320931",
    "label": "Kejuden",
    "value": "Kejuden"
  },
  {
    "id": "3209312011",
    "district_id": "320931",
    "label": "Getasan",
    "value": "Getasan"
  },
  {
    "id": "3209312012",
    "district_id": "320931",
    "label": "Karangwangi",
    "value": "Karangwangi"
  },
  {
    "id": "3209322001",
    "district_id": "320932",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3209322002",
    "district_id": "320932",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3209322003",
    "district_id": "320932",
    "label": "Cilengkrang Girang",
    "value": "Cilengkrang Girang"
  },
  {
    "id": "3209322004",
    "district_id": "320932",
    "label": "Cilengkrang",
    "value": "Cilengkrang"
  },
  {
    "id": "3209322005",
    "district_id": "320932",
    "label": "Pasaleman",
    "value": "Pasaleman"
  },
  {
    "id": "3209322006",
    "district_id": "320932",
    "label": "Cigobang Wangi",
    "value": "Cigobang Wangi"
  },
  {
    "id": "3209322007",
    "district_id": "320932",
    "label": "Cigobang",
    "value": "Cigobang"
  },
  {
    "id": "3209332001",
    "district_id": "320933",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3209332002",
    "district_id": "320933",
    "label": "Pabuaran Kidul",
    "value": "Pabuaran Kidul"
  },
  {
    "id": "3209332003",
    "district_id": "320933",
    "label": "Pabuaran Wetan",
    "value": "Pabuaran Wetan"
  },
  {
    "id": "3209332004",
    "district_id": "320933",
    "label": "Pabuaran Lor",
    "value": "Pabuaran Lor"
  },
  {
    "id": "3209332005",
    "district_id": "320933",
    "label": "Jatirenggang",
    "value": "Jatirenggang"
  },
  {
    "id": "3209332006",
    "district_id": "320933",
    "label": "Hulubanteng",
    "value": "Hulubanteng"
  },
  {
    "id": "3209332007",
    "district_id": "320933",
    "label": "Hulubanteng Lor",
    "value": "Hulubanteng Lor"
  },
  {
    "id": "3209342001",
    "district_id": "320934",
    "label": "Seuseupan",
    "value": "Seuseupan"
  },
  {
    "id": "3209342002",
    "district_id": "320934",
    "label": "Blender",
    "value": "Blender"
  },
  {
    "id": "3209342003",
    "district_id": "320934",
    "label": "Sumurkondang",
    "value": "Sumurkondang"
  },
  {
    "id": "3209342004",
    "district_id": "320934",
    "label": "Kubangdeleg",
    "value": "Kubangdeleg"
  },
  {
    "id": "3209342005",
    "district_id": "320934",
    "label": "Jatipiring",
    "value": "Jatipiring"
  },
  {
    "id": "3209342006",
    "district_id": "320934",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3209342007",
    "district_id": "320934",
    "label": "Karangwangi",
    "value": "Karangwangi"
  },
  {
    "id": "3209342008",
    "district_id": "320934",
    "label": "Karangwareng",
    "value": "Karangwareng"
  },
  {
    "id": "3209342009",
    "district_id": "320934",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3209352001",
    "district_id": "320935",
    "label": "Palir",
    "value": "Palir"
  },
  {
    "id": "3209352002",
    "district_id": "320935",
    "label": "Astapada",
    "value": "Astapada"
  },
  {
    "id": "3209352003",
    "district_id": "320935",
    "label": "Gesik",
    "value": "Gesik"
  },
  {
    "id": "3209352004",
    "district_id": "320935",
    "label": "Kemlakagede",
    "value": "Kemlakagede"
  },
  {
    "id": "3209352005",
    "district_id": "320935",
    "label": "Dawuan",
    "value": "Dawuan"
  },
  {
    "id": "3209352006",
    "district_id": "320935",
    "label": "Battembat",
    "value": "Battembat"
  },
  {
    "id": "3209352007",
    "district_id": "320935",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "3209352008",
    "district_id": "320935",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3209362001",
    "district_id": "320936",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3209362002",
    "district_id": "320936",
    "label": "Kaliwulu",
    "value": "Kaliwulu"
  },
  {
    "id": "3209362003",
    "district_id": "320936",
    "label": "Panembahan",
    "value": "Panembahan"
  },
  {
    "id": "3209362004",
    "district_id": "320936",
    "label": "Trusmi Wetan",
    "value": "Trusmi Wetan"
  },
  {
    "id": "3209362005",
    "district_id": "320936",
    "label": "Trusmi Kulon",
    "value": "Trusmi Kulon"
  },
  {
    "id": "3209362006",
    "district_id": "320936",
    "label": "Wotgali",
    "value": "Wotgali"
  },
  {
    "id": "3209362007",
    "district_id": "320936",
    "label": "Gamel",
    "value": "Gamel"
  },
  {
    "id": "3209362008",
    "district_id": "320936",
    "label": "Sarabau",
    "value": "Sarabau"
  },
  {
    "id": "3209362009",
    "district_id": "320936",
    "label": "Cankring",
    "value": "Cankring"
  },
  {
    "id": "3209362010",
    "district_id": "320936",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3209372001",
    "district_id": "320937",
    "label": "Cikeusal",
    "value": "Cikeusal"
  },
  {
    "id": "3209372002",
    "district_id": "320937",
    "label": "Palimanan Barat",
    "value": "Palimanan Barat"
  },
  {
    "id": "3209372003",
    "district_id": "320937",
    "label": "Kedungbunder",
    "value": "Kedungbunder"
  },
  {
    "id": "3209372004",
    "district_id": "320937",
    "label": "Cupang",
    "value": "Cupang"
  },
  {
    "id": "3209372005",
    "district_id": "320937",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3209372006",
    "district_id": "320937",
    "label": "Walahar",
    "value": "Walahar"
  },
  {
    "id": "3209372007",
    "district_id": "320937",
    "label": "Kempek",
    "value": "Kempek"
  },
  {
    "id": "3209372008",
    "district_id": "320937",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3209382001",
    "district_id": "320938",
    "label": "Gumulung Lebak",
    "value": "Gumulung Lebak"
  },
  {
    "id": "3209382002",
    "district_id": "320938",
    "label": "Lebakmekar",
    "value": "Lebakmekar"
  },
  {
    "id": "3209382003",
    "district_id": "320938",
    "label": "Gumulung Tonggoh",
    "value": "Gumulung Tonggoh"
  },
  {
    "id": "3209382004",
    "district_id": "320938",
    "label": "Kamarang",
    "value": "Kamarang"
  },
  {
    "id": "3209382005",
    "district_id": "320938",
    "label": "Greged",
    "value": "Greged"
  },
  {
    "id": "3209382006",
    "district_id": "320938",
    "label": "Kamarang Lebak",
    "value": "Kamarang Lebak"
  },
  {
    "id": "3209382007",
    "district_id": "320938",
    "label": "Durajaya",
    "value": "Durajaya"
  },
  {
    "id": "3209382008",
    "district_id": "320938",
    "label": "Jatipancur",
    "value": "Jatipancur"
  },
  {
    "id": "3209382009",
    "district_id": "320938",
    "label": "Sindang Kempeng",
    "value": "Sindang Kempeng"
  },
  {
    "id": "3209382010",
    "district_id": "320938",
    "label": "Nanggela",
    "value": "Nanggela"
  },
  {
    "id": "3209392001",
    "district_id": "320939",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3209392002",
    "district_id": "320939",
    "label": "Sirnabaya",
    "value": "Sirnabaya"
  },
  {
    "id": "3209392003",
    "district_id": "320939",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "3209392004",
    "district_id": "320939",
    "label": "Purwawinangun",
    "value": "Purwawinangun"
  },
  {
    "id": "3209392005",
    "district_id": "320939",
    "label": "Keraton",
    "value": "Keraton"
  },
  {
    "id": "3209392006",
    "district_id": "320939",
    "label": "Surakarta",
    "value": "Surakarta"
  },
  {
    "id": "3209392007",
    "district_id": "320939",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3209392008",
    "district_id": "320939",
    "label": "Suranenggala Kidul",
    "value": "Suranenggala Kidul"
  },
  {
    "id": "3209392009",
    "district_id": "320939",
    "label": "Suranenggala Kulon",
    "value": "Suranenggala Kulon"
  },
  {
    "id": "3209392010",
    "district_id": "320939",
    "label": "Suranenggala Lor",
    "value": "Suranenggala Lor"
  },
  {
    "id": "3209392011",
    "district_id": "320939",
    "label": "Suranenggala",
    "value": "Suranenggala"
  },
  {
    "id": "3209402001",
    "district_id": "320940",
    "label": "Jamblang",
    "value": "Jamblang"
  },
  {
    "id": "3209402002",
    "district_id": "320940",
    "label": "Sitiwinangun",
    "value": "Sitiwinangun"
  },
  {
    "id": "3209402003",
    "district_id": "320940",
    "label": "Wangunharja",
    "value": "Wangunharja"
  },
  {
    "id": "3209402004",
    "district_id": "320940",
    "label": "Bojong Wetan",
    "value": "Bojong Wetan"
  },
  {
    "id": "3209402005",
    "district_id": "320940",
    "label": "Bojong Lor",
    "value": "Bojong Lor"
  },
  {
    "id": "3209402006",
    "district_id": "320940",
    "label": "Orimalang",
    "value": "Orimalang"
  },
  {
    "id": "3209402007",
    "district_id": "320940",
    "label": "Bakung Kidul",
    "value": "Bakung Kidul"
  },
  {
    "id": "3209402008",
    "district_id": "320940",
    "label": "Bakung Lor",
    "value": "Bakung Lor"
  },
  {
    "id": "3210012001",
    "district_id": "321001",
    "label": "Cipasung",
    "value": "Cipasung"
  },
  {
    "id": "3210012002",
    "district_id": "321001",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3210012003",
    "district_id": "321001",
    "label": "Borogojol",
    "value": "Borogojol"
  },
  {
    "id": "3210012004",
    "district_id": "321001",
    "label": "Cibulan",
    "value": "Cibulan"
  },
  {
    "id": "3210012005",
    "district_id": "321001",
    "label": "Lemahputih",
    "value": "Lemahputih"
  },
  {
    "id": "3210012006",
    "district_id": "321001",
    "label": "Sadawangi",
    "value": "Sadawangi"
  },
  {
    "id": "3210012007",
    "district_id": "321001",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3210012008",
    "district_id": "321001",
    "label": "Padarek",
    "value": "Padarek"
  },
  {
    "id": "3210012009",
    "district_id": "321001",
    "label": "Kalapadua",
    "value": "Kalapadua"
  },
  {
    "id": "3210012010",
    "district_id": "321001",
    "label": "Cigaleuh",
    "value": "Cigaleuh"
  },
  {
    "id": "3210012011",
    "district_id": "321001",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3210012012",
    "district_id": "321001",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3210012013",
    "district_id": "321001",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3210012014",
    "district_id": "321001",
    "label": "Sinargalih",
    "value": "Sinargalih"
  },
  {
    "id": "3210012015",
    "district_id": "321001",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3210012016",
    "district_id": "321001",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3210012017",
    "district_id": "321001",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3210012018",
    "district_id": "321001",
    "label": "Dayeuhwangi",
    "value": "Dayeuhwangi"
  },
  {
    "id": "3210012019",
    "district_id": "321001",
    "label": "Lemahsugih",
    "value": "Lemahsugih"
  },
  {
    "id": "3210022002",
    "district_id": "321002",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3210022008",
    "district_id": "321002",
    "label": "Cimangguhilir",
    "value": "Cimangguhilir"
  },
  {
    "id": "3210022009",
    "district_id": "321002",
    "label": "Salawangi",
    "value": "Salawangi"
  },
  {
    "id": "3210022010",
    "district_id": "321002",
    "label": "Bantarujeg",
    "value": "Bantarujeg"
  },
  {
    "id": "3210022011",
    "district_id": "321002",
    "label": "Gununglarang",
    "value": "Gununglarang"
  },
  {
    "id": "3210022012",
    "district_id": "321002",
    "label": "Cikidang",
    "value": "Cikidang"
  },
  {
    "id": "3210022013",
    "district_id": "321002",
    "label": "Cinambo",
    "value": "Cinambo"
  },
  {
    "id": "3210022014",
    "district_id": "321002",
    "label": "Haurgeulis",
    "value": "Haurgeulis"
  },
  {
    "id": "3210022015",
    "district_id": "321002",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3210022016",
    "district_id": "321002",
    "label": "Wadowetan",
    "value": "Wadowetan"
  },
  {
    "id": "3210022017",
    "district_id": "321002",
    "label": "Babakansari",
    "value": "Babakansari"
  },
  {
    "id": "3210022018",
    "district_id": "321002",
    "label": "Silihwangi",
    "value": "Silihwangi"
  },
  {
    "id": "3210022022",
    "district_id": "321002",
    "label": "Sindanghurip",
    "value": "Sindanghurip"
  },
  {
    "id": "3210032001",
    "district_id": "321003",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3210032002",
    "district_id": "321003",
    "label": "Cisoka",
    "value": "Cisoka"
  },
  {
    "id": "3210032003",
    "district_id": "321003",
    "label": "Sindangpanji",
    "value": "Sindangpanji"
  },
  {
    "id": "3210032004",
    "district_id": "321003",
    "label": "Cikijing",
    "value": "Cikijing"
  },
  {
    "id": "3210032005",
    "district_id": "321003",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "3210032006",
    "district_id": "321003",
    "label": "Banjaransari",
    "value": "Banjaransari"
  },
  {
    "id": "3210032007",
    "district_id": "321003",
    "label": "Kasturi",
    "value": "Kasturi"
  },
  {
    "id": "3210032008",
    "district_id": "321003",
    "label": "Cidulang",
    "value": "Cidulang"
  },
  {
    "id": "3210032009",
    "district_id": "321003",
    "label": "Jagasari",
    "value": "Jagasari"
  },
  {
    "id": "3210032010",
    "district_id": "321003",
    "label": "Bagjasari",
    "value": "Bagjasari"
  },
  {
    "id": "3210032011",
    "district_id": "321003",
    "label": "Sunalari",
    "value": "Sunalari"
  },
  {
    "id": "3210032012",
    "district_id": "321003",
    "label": "Cipulus",
    "value": "Cipulus"
  },
  {
    "id": "3210032013",
    "district_id": "321003",
    "label": "Kancana",
    "value": "Kancana"
  },
  {
    "id": "3210032014",
    "district_id": "321003",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3210032015",
    "district_id": "321003",
    "label": "Cilancang",
    "value": "Cilancang"
  },
  {
    "id": "3210042001",
    "district_id": "321004",
    "label": "Lampuyang",
    "value": "Lampuyang"
  },
  {
    "id": "3210042002",
    "district_id": "321004",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3210042003",
    "district_id": "321004",
    "label": "Cikeusal",
    "value": "Cikeusal"
  },
  {
    "id": "3210042004",
    "district_id": "321004",
    "label": "Jatipamor",
    "value": "Jatipamor"
  },
  {
    "id": "3210042005",
    "district_id": "321004",
    "label": "Argasari",
    "value": "Argasari"
  },
  {
    "id": "3210042006",
    "district_id": "321004",
    "label": "Cicanir",
    "value": "Cicanir"
  },
  {
    "id": "3210042007",
    "district_id": "321004",
    "label": "Campaga",
    "value": "Campaga"
  },
  {
    "id": "3210042008",
    "district_id": "321004",
    "label": "Sukaperna",
    "value": "Sukaperna"
  },
  {
    "id": "3210042009",
    "district_id": "321004",
    "label": "Talaga Kulon",
    "value": "Talaga Kulon"
  },
  {
    "id": "3210042010",
    "district_id": "321004",
    "label": "Talaga Wetan",
    "value": "Talaga Wetan"
  },
  {
    "id": "3210042011",
    "district_id": "321004",
    "label": "Ganeas",
    "value": "Ganeas"
  },
  {
    "id": "3210042012",
    "district_id": "321004",
    "label": "Salado",
    "value": "Salado"
  },
  {
    "id": "3210042013",
    "district_id": "321004",
    "label": "Gunungmanik",
    "value": "Gunungmanik"
  },
  {
    "id": "3210042014",
    "district_id": "321004",
    "label": "Kertahayu",
    "value": "Kertahayu"
  },
  {
    "id": "3210042015",
    "district_id": "321004",
    "label": "Mekarraharja",
    "value": "Mekarraharja"
  },
  {
    "id": "3210042016",
    "district_id": "321004",
    "label": "Margamukti",
    "value": "Margamukti"
  },
  {
    "id": "3210042017",
    "district_id": "321004",
    "label": "Mekarhurip",
    "value": "Mekarhurip"
  },
  {
    "id": "3210052001",
    "district_id": "321005",
    "label": "Sagara",
    "value": "Sagara"
  },
  {
    "id": "3210052002",
    "district_id": "321005",
    "label": "Cibunut",
    "value": "Cibunut"
  },
  {
    "id": "3210052003",
    "district_id": "321005",
    "label": "Tejamulya",
    "value": "Tejamulya"
  },
  {
    "id": "3210052004",
    "district_id": "321005",
    "label": "Sukasari Kaler",
    "value": "Sukasari Kaler"
  },
  {
    "id": "3210052005",
    "district_id": "321005",
    "label": "Argamukti",
    "value": "Argamukti"
  },
  {
    "id": "3210052006",
    "district_id": "321005",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3210052007",
    "district_id": "321005",
    "label": "Sadasari",
    "value": "Sadasari"
  },
  {
    "id": "3210052008",
    "district_id": "321005",
    "label": "Haurseah",
    "value": "Haurseah"
  },
  {
    "id": "3210052009",
    "district_id": "321005",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3210052010",
    "district_id": "321005",
    "label": "Cikaracak",
    "value": "Cikaracak"
  },
  {
    "id": "3210052011",
    "district_id": "321005",
    "label": "Heubeulisuk",
    "value": "Heubeulisuk"
  },
  {
    "id": "3210052012",
    "district_id": "321005",
    "label": "Sukasari Kidul",
    "value": "Sukasari Kidul"
  },
  {
    "id": "3210052013",
    "district_id": "321005",
    "label": "Gunungwangi",
    "value": "Gunungwangi"
  },
  {
    "id": "3210052014",
    "district_id": "321005",
    "label": "Argalingga",
    "value": "Argalingga"
  },
  {
    "id": "3210062001",
    "district_id": "321006",
    "label": "Cihaur",
    "value": "Cihaur"
  },
  {
    "id": "3210062002",
    "district_id": "321006",
    "label": "Wanahayu",
    "value": "Wanahayu"
  },
  {
    "id": "3210062003",
    "district_id": "321006",
    "label": "Cengal",
    "value": "Cengal"
  },
  {
    "id": "3210062004",
    "district_id": "321006",
    "label": "Anggrawati",
    "value": "Anggrawati"
  },
  {
    "id": "3210062005",
    "district_id": "321006",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3210062006",
    "district_id": "321006",
    "label": "Malongpong",
    "value": "Malongpong"
  },
  {
    "id": "3210062007",
    "district_id": "321006",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3210062008",
    "district_id": "321006",
    "label": "Maja Utara",
    "value": "Maja Utara"
  },
  {
    "id": "3210062009",
    "district_id": "321006",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3210062010",
    "district_id": "321006",
    "label": "Cieurih",
    "value": "Cieurih"
  },
  {
    "id": "3210062011",
    "district_id": "321006",
    "label": "Kartabasuki",
    "value": "Kartabasuki"
  },
  {
    "id": "3210062012",
    "district_id": "321006",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "3210062013",
    "district_id": "321006",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3210062014",
    "district_id": "321006",
    "label": "Paniis",
    "value": "Paniis"
  },
  {
    "id": "3210062015",
    "district_id": "321006",
    "label": "Cicalung",
    "value": "Cicalung"
  },
  {
    "id": "3210062016",
    "district_id": "321006",
    "label": "Pageraji",
    "value": "Pageraji"
  },
  {
    "id": "3210062017",
    "district_id": "321006",
    "label": "Maja Selatan",
    "value": "Maja Selatan"
  },
  {
    "id": "3210062018",
    "district_id": "321006",
    "label": "Nunuk Baru",
    "value": "Nunuk Baru"
  },
  {
    "id": "3210071001",
    "district_id": "321007",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3210071002",
    "district_id": "321007",
    "label": "Babakan Jawa",
    "value": "Babakan Jawa"
  },
  {
    "id": "3210071003",
    "district_id": "321007",
    "label": "Cicurug",
    "value": "Cicurug"
  },
  {
    "id": "3210071004",
    "district_id": "321007",
    "label": "Sindangkasih",
    "value": "Sindangkasih"
  },
  {
    "id": "3210071007",
    "district_id": "321007",
    "label": "Majalengka Wetan",
    "value": "Majalengka Wetan"
  },
  {
    "id": "3210071008",
    "district_id": "321007",
    "label": "Majalengka Kulon",
    "value": "Majalengka Kulon"
  },
  {
    "id": "3210071009",
    "district_id": "321007",
    "label": "Tarikolot",
    "value": "Tarikolot"
  },
  {
    "id": "3210071010",
    "district_id": "321007",
    "label": "Cikasarung",
    "value": "Cikasarung"
  },
  {
    "id": "3210071011",
    "district_id": "321007",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3210071013",
    "district_id": "321007",
    "label": "Cijati",
    "value": "Cijati"
  },
  {
    "id": "3210072005",
    "district_id": "321007",
    "label": "Kulur",
    "value": "Kulur"
  },
  {
    "id": "3210072006",
    "district_id": "321007",
    "label": "Kawunggirang",
    "value": "Kawunggirang"
  },
  {
    "id": "3210072012",
    "district_id": "321007",
    "label": "Sidamukti",
    "value": "Sidamukti"
  },
  {
    "id": "3210072014",
    "district_id": "321007",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3210082001",
    "district_id": "321008",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3210082002",
    "district_id": "321008",
    "label": "Padahanten",
    "value": "Padahanten"
  },
  {
    "id": "3210082009",
    "district_id": "321008",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3210082010",
    "district_id": "321008",
    "label": "Salagedang",
    "value": "Salagedang"
  },
  {
    "id": "3210082011",
    "district_id": "321008",
    "label": "Cikeusik",
    "value": "Cikeusik"
  },
  {
    "id": "3210082012",
    "district_id": "321008",
    "label": "Jayi",
    "value": "Jayi"
  },
  {
    "id": "3210082013",
    "district_id": "321008",
    "label": "Nanggewer",
    "value": "Nanggewer"
  },
  {
    "id": "3210082014",
    "district_id": "321008",
    "label": "Palabuan",
    "value": "Palabuan"
  },
  {
    "id": "3210082015",
    "district_id": "321008",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3210082016",
    "district_id": "321008",
    "label": "Babakanmanjeti",
    "value": "Babakanmanjeti"
  },
  {
    "id": "3210082017",
    "district_id": "321008",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3210082018",
    "district_id": "321008",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3210082019",
    "district_id": "321008",
    "label": "Candrajaya",
    "value": "Candrajaya"
  },
  {
    "id": "3210092001",
    "district_id": "321009",
    "label": "Pajajar",
    "value": "Pajajar"
  },
  {
    "id": "3210092002",
    "district_id": "321009",
    "label": "Teja",
    "value": "Teja"
  },
  {
    "id": "3210092003",
    "district_id": "321009",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "3210092004",
    "district_id": "321009",
    "label": "Babakankareo",
    "value": "Babakankareo"
  },
  {
    "id": "3210092005",
    "district_id": "321009",
    "label": "Sindangpano",
    "value": "Sindangpano"
  },
  {
    "id": "3210092006",
    "district_id": "321009",
    "label": "Sadomas",
    "value": "Sadomas"
  },
  {
    "id": "3210092007",
    "district_id": "321009",
    "label": "Kumbung",
    "value": "Kumbung"
  },
  {
    "id": "3210092008",
    "district_id": "321009",
    "label": "Rajagaluh Kidul",
    "value": "Rajagaluh Kidul"
  },
  {
    "id": "3210092009",
    "district_id": "321009",
    "label": "Singawada",
    "value": "Singawada"
  },
  {
    "id": "3210092010",
    "district_id": "321009",
    "label": "Rajagaluh Lor",
    "value": "Rajagaluh Lor"
  },
  {
    "id": "3210092011",
    "district_id": "321009",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3210092012",
    "district_id": "321009",
    "label": "Cisetu",
    "value": "Cisetu"
  },
  {
    "id": "3210092013",
    "district_id": "321009",
    "label": "Rajagaluh",
    "value": "Rajagaluh"
  },
  {
    "id": "3210102001",
    "district_id": "321010",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3210102002",
    "district_id": "321010",
    "label": "Patuanan",
    "value": "Patuanan"
  },
  {
    "id": "3210102003",
    "district_id": "321010",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3210102004",
    "district_id": "321010",
    "label": "Lame",
    "value": "Lame"
  },
  {
    "id": "3210102005",
    "district_id": "321010",
    "label": "Mindi",
    "value": "Mindi"
  },
  {
    "id": "3210102006",
    "district_id": "321010",
    "label": "Rajawangi",
    "value": "Rajawangi"
  },
  {
    "id": "3210102007",
    "district_id": "321010",
    "label": "Leuwikujang",
    "value": "Leuwikujang"
  },
  {
    "id": "3210102008",
    "district_id": "321010",
    "label": "Mirat",
    "value": "Mirat"
  },
  {
    "id": "3210102009",
    "district_id": "321010",
    "label": "Leuwimunding",
    "value": "Leuwimunding"
  },
  {
    "id": "3210102010",
    "district_id": "321010",
    "label": "Ciparay",
    "value": "Ciparay"
  },
  {
    "id": "3210102011",
    "district_id": "321010",
    "label": "Heuleut",
    "value": "Heuleut"
  },
  {
    "id": "3210102012",
    "district_id": "321010",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3210102013",
    "district_id": "321010",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3210102014",
    "district_id": "321010",
    "label": "Parungjaya",
    "value": "Parungjaya"
  },
  {
    "id": "3210112001",
    "district_id": "321011",
    "label": "Burujul Kulon",
    "value": "Burujul Kulon"
  },
  {
    "id": "3210112002",
    "district_id": "321011",
    "label": "Burujul Wetan",
    "value": "Burujul Wetan"
  },
  {
    "id": "3210112003",
    "district_id": "321011",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3210112004",
    "district_id": "321011",
    "label": "Andir",
    "value": "Andir"
  },
  {
    "id": "3210112005",
    "district_id": "321011",
    "label": "Sukaraja Wetan",
    "value": "Sukaraja Wetan"
  },
  {
    "id": "3210112006",
    "district_id": "321011",
    "label": "Pinangraja",
    "value": "Pinangraja"
  },
  {
    "id": "3210112007",
    "district_id": "321011",
    "label": "Cibentar",
    "value": "Cibentar"
  },
  {
    "id": "3210112008",
    "district_id": "321011",
    "label": "Leuweunggede",
    "value": "Leuweunggede"
  },
  {
    "id": "3210112009",
    "district_id": "321011",
    "label": "Cibolerang",
    "value": "Cibolerang"
  },
  {
    "id": "3210112010",
    "district_id": "321011",
    "label": "Sutawangi",
    "value": "Sutawangi"
  },
  {
    "id": "3210112011",
    "district_id": "321011",
    "label": "Jatisura",
    "value": "Jatisura"
  },
  {
    "id": "3210112012",
    "district_id": "321011",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "3210112013",
    "district_id": "321011",
    "label": "Loji",
    "value": "Loji"
  },
  {
    "id": "3210112014",
    "district_id": "321011",
    "label": "Sukaraja Kulon",
    "value": "Sukaraja Kulon"
  },
  {
    "id": "3210112015",
    "district_id": "321011",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3210112016",
    "district_id": "321011",
    "label": "Surawangi",
    "value": "Surawangi"
  },
  {
    "id": "3210122006",
    "district_id": "321012",
    "label": "Gandu",
    "value": "Gandu"
  },
  {
    "id": "3210122007",
    "district_id": "321012",
    "label": "Dawuan",
    "value": "Dawuan"
  },
  {
    "id": "3210122008",
    "district_id": "321012",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "3210122010",
    "district_id": "321012",
    "label": "Mandapa",
    "value": "Mandapa"
  },
  {
    "id": "3210122011",
    "district_id": "321012",
    "label": "Balida",
    "value": "Balida"
  },
  {
    "id": "3210122012",
    "district_id": "321012",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3210122013",
    "district_id": "321012",
    "label": "Salawana",
    "value": "Salawana"
  },
  {
    "id": "3210122015",
    "district_id": "321012",
    "label": "Bojongcideres",
    "value": "Bojongcideres"
  },
  {
    "id": "3210122017",
    "district_id": "321012",
    "label": "Sinarjati",
    "value": "Sinarjati"
  },
  {
    "id": "3210122018",
    "district_id": "321012",
    "label": "Pasirmalati",
    "value": "Pasirmalati"
  },
  {
    "id": "3210122021",
    "district_id": "321012",
    "label": "Baturuyuk",
    "value": "Baturuyuk"
  },
  {
    "id": "3210132001",
    "district_id": "321013",
    "label": "Heuleut",
    "value": "Heuleut"
  },
  {
    "id": "3210132002",
    "district_id": "321013",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3210132003",
    "district_id": "321013",
    "label": "Babakananyar",
    "value": "Babakananyar"
  },
  {
    "id": "3210132004",
    "district_id": "321013",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "3210132005",
    "district_id": "321013",
    "label": "Liangjulang",
    "value": "Liangjulang"
  },
  {
    "id": "3210132006",
    "district_id": "321013",
    "label": "Pagandon",
    "value": "Pagandon"
  },
  {
    "id": "3210132007",
    "district_id": "321013",
    "label": "Cipaku",
    "value": "Cipaku"
  },
  {
    "id": "3210142001",
    "district_id": "321014",
    "label": "Pakubeureum",
    "value": "Pakubeureum"
  },
  {
    "id": "3210142002",
    "district_id": "321014",
    "label": "Sukawana",
    "value": "Sukawana"
  },
  {
    "id": "3210142003",
    "district_id": "321014",
    "label": "Kertawinangun",
    "value": "Kertawinangun"
  },
  {
    "id": "3210142004",
    "district_id": "321014",
    "label": "Palasah",
    "value": "Palasah"
  },
  {
    "id": "3210142005",
    "district_id": "321014",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3210142006",
    "district_id": "321014",
    "label": "Kertajati",
    "value": "Kertajati"
  },
  {
    "id": "3210142007",
    "district_id": "321014",
    "label": "Bantarjati",
    "value": "Bantarjati"
  },
  {
    "id": "3210142008",
    "district_id": "321014",
    "label": "Pasiripis",
    "value": "Pasiripis"
  },
  {
    "id": "3210142009",
    "district_id": "321014",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3210142010",
    "district_id": "321014",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3210142011",
    "district_id": "321014",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3210142012",
    "district_id": "321014",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3210142013",
    "district_id": "321014",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3210142014",
    "district_id": "321014",
    "label": "Sahbandar",
    "value": "Sahbandar"
  },
  {
    "id": "3210152001",
    "district_id": "321015",
    "label": "Biyawak",
    "value": "Biyawak"
  },
  {
    "id": "3210152002",
    "district_id": "321015",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "3210152003",
    "district_id": "321015",
    "label": "Panongan",
    "value": "Panongan"
  },
  {
    "id": "3210152004",
    "district_id": "321015",
    "label": "Randegan Wetan",
    "value": "Randegan Wetan"
  },
  {
    "id": "3210152005",
    "district_id": "321015",
    "label": "Putridalem",
    "value": "Putridalem"
  },
  {
    "id": "3210152006",
    "district_id": "321015",
    "label": "Jatitengah",
    "value": "Jatitengah"
  },
  {
    "id": "3210152007",
    "district_id": "321015",
    "label": "Jatitujuh",
    "value": "Jatitujuh"
  },
  {
    "id": "3210152008",
    "district_id": "321015",
    "label": "Babajurang",
    "value": "Babajurang"
  },
  {
    "id": "3210152009",
    "district_id": "321015",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3210152010",
    "district_id": "321015",
    "label": "Jatiraga",
    "value": "Jatiraga"
  },
  {
    "id": "3210152011",
    "district_id": "321015",
    "label": "Sumber Wetan",
    "value": "Sumber Wetan"
  },
  {
    "id": "3210152012",
    "district_id": "321015",
    "label": "Pangkalanpari",
    "value": "Pangkalanpari"
  },
  {
    "id": "3210152013",
    "district_id": "321015",
    "label": "Randegan Kulon",
    "value": "Randegan Kulon"
  },
  {
    "id": "3210152014",
    "district_id": "321015",
    "label": "Sumber Kulon",
    "value": "Sumber Kulon"
  },
  {
    "id": "3210152015",
    "district_id": "321015",
    "label": "Pasindangan",
    "value": "Pasindangan"
  },
  {
    "id": "3210162001",
    "district_id": "321016",
    "label": "Cibogor",
    "value": "Cibogor"
  },
  {
    "id": "3210162002",
    "district_id": "321016",
    "label": "Beber",
    "value": "Beber"
  },
  {
    "id": "3210162003",
    "district_id": "321016",
    "label": "Beusi",
    "value": "Beusi"
  },
  {
    "id": "3210162004",
    "district_id": "321016",
    "label": "Tegalaren",
    "value": "Tegalaren"
  },
  {
    "id": "3210162005",
    "district_id": "321016",
    "label": "Buntu",
    "value": "Buntu"
  },
  {
    "id": "3210162006",
    "district_id": "321016",
    "label": "Ligung",
    "value": "Ligung"
  },
  {
    "id": "3210162007",
    "district_id": "321016",
    "label": "Wanasalam",
    "value": "Wanasalam"
  },
  {
    "id": "3210162008",
    "district_id": "321016",
    "label": "Ampel",
    "value": "Ampel"
  },
  {
    "id": "3210162009",
    "district_id": "321016",
    "label": "Bantarwaru",
    "value": "Bantarwaru"
  },
  {
    "id": "3210162010",
    "district_id": "321016",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3210162011",
    "district_id": "321016",
    "label": "Kedungkencana",
    "value": "Kedungkencana"
  },
  {
    "id": "3210162012",
    "district_id": "321016",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3210162013",
    "district_id": "321016",
    "label": "Leuweunghapit",
    "value": "Leuweunghapit"
  },
  {
    "id": "3210162014",
    "district_id": "321016",
    "label": "Ligung Lor",
    "value": "Ligung Lor"
  },
  {
    "id": "3210162015",
    "district_id": "321016",
    "label": "Sukawera",
    "value": "Sukawera"
  },
  {
    "id": "3210162016",
    "district_id": "321016",
    "label": "Gandawesi",
    "value": "Gandawesi"
  },
  {
    "id": "3210162017",
    "district_id": "321016",
    "label": "Kodasari",
    "value": "Kodasari"
  },
  {
    "id": "3210162018",
    "district_id": "321016",
    "label": "Leuwiliang Baru",
    "value": "Leuwiliang Baru"
  },
  {
    "id": "3210162019",
    "district_id": "321016",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3210172001",
    "district_id": "321017",
    "label": "Bongas Wetan",
    "value": "Bongas Wetan"
  },
  {
    "id": "3210172002",
    "district_id": "321017",
    "label": "Bongas Kulon",
    "value": "Bongas Kulon"
  },
  {
    "id": "3210172003",
    "district_id": "321017",
    "label": "Garawangi",
    "value": "Garawangi"
  },
  {
    "id": "3210172004",
    "district_id": "321017",
    "label": "Rancaputat",
    "value": "Rancaputat"
  },
  {
    "id": "3210172005",
    "district_id": "321017",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3210172006",
    "district_id": "321017",
    "label": "Sepat",
    "value": "Sepat"
  },
  {
    "id": "3210172007",
    "district_id": "321017",
    "label": "Paningkiran",
    "value": "Paningkiran"
  },
  {
    "id": "3210172008",
    "district_id": "321017",
    "label": "Parapatan",
    "value": "Parapatan"
  },
  {
    "id": "3210172009",
    "district_id": "321017",
    "label": "Panjalin Kidul",
    "value": "Panjalin Kidul"
  },
  {
    "id": "3210172010",
    "district_id": "321017",
    "label": "Cidenok",
    "value": "Cidenok"
  },
  {
    "id": "3210172011",
    "district_id": "321017",
    "label": "Lojikobong",
    "value": "Lojikobong"
  },
  {
    "id": "3210172012",
    "district_id": "321017",
    "label": "Panjalin Lor",
    "value": "Panjalin Lor"
  },
  {
    "id": "3210172013",
    "district_id": "321017",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3210172014",
    "district_id": "321017",
    "label": "Pancaksuji",
    "value": "Pancaksuji"
  },
  {
    "id": "3210172015",
    "district_id": "321017",
    "label": "Gelok Mulya",
    "value": "Gelok Mulya"
  },
  {
    "id": "3210182001",
    "district_id": "321018",
    "label": "Cijurey",
    "value": "Cijurey"
  },
  {
    "id": "3210182002",
    "district_id": "321018",
    "label": "Pasirmuncang",
    "value": "Pasirmuncang"
  },
  {
    "id": "3210182003",
    "district_id": "321018",
    "label": "Jatipamor",
    "value": "Jatipamor"
  },
  {
    "id": "3210182004",
    "district_id": "321018",
    "label": "Bantrangsana",
    "value": "Bantrangsana"
  },
  {
    "id": "3210182005",
    "district_id": "321018",
    "label": "Jatiserang",
    "value": "Jatiserang"
  },
  {
    "id": "3210182006",
    "district_id": "321018",
    "label": "Bonang",
    "value": "Bonang"
  },
  {
    "id": "3210182007",
    "district_id": "321018",
    "label": "Leuwiseeng",
    "value": "Leuwiseeng"
  },
  {
    "id": "3210182008",
    "district_id": "321018",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "3210182009",
    "district_id": "321018",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3210192001",
    "district_id": "321019",
    "label": "Majasuka",
    "value": "Majasuka"
  },
  {
    "id": "3210192002",
    "district_id": "321019",
    "label": "Cisambeng",
    "value": "Cisambeng"
  },
  {
    "id": "3210192003",
    "district_id": "321019",
    "label": "Palasah",
    "value": "Palasah"
  },
  {
    "id": "3210192004",
    "district_id": "321019",
    "label": "Weragati",
    "value": "Weragati"
  },
  {
    "id": "3210192005",
    "district_id": "321019",
    "label": "Trajaya",
    "value": "Trajaya"
  },
  {
    "id": "3210192006",
    "district_id": "321019",
    "label": "Tarikolot",
    "value": "Tarikolot"
  },
  {
    "id": "3210192007",
    "district_id": "321019",
    "label": "Buniwangi",
    "value": "Buniwangi"
  },
  {
    "id": "3210192008",
    "district_id": "321019",
    "label": "Sindanghaji",
    "value": "Sindanghaji"
  },
  {
    "id": "3210192009",
    "district_id": "321019",
    "label": "Waringin",
    "value": "Waringin"
  },
  {
    "id": "3210192010",
    "district_id": "321019",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "3210192011",
    "district_id": "321019",
    "label": "Karamat",
    "value": "Karamat"
  },
  {
    "id": "3210192012",
    "district_id": "321019",
    "label": "Enggalwangi",
    "value": "Enggalwangi"
  },
  {
    "id": "3210192013",
    "district_id": "321019",
    "label": "Sindangwasa",
    "value": "Sindangwasa"
  },
  {
    "id": "3210201004",
    "district_id": "321020",
    "label": "Cigasong",
    "value": "Cigasong"
  },
  {
    "id": "3210201005",
    "district_id": "321020",
    "label": "Simpeureum",
    "value": "Simpeureum"
  },
  {
    "id": "3210201006",
    "district_id": "321020",
    "label": "Cicenang",
    "value": "Cicenang"
  },
  {
    "id": "3210202001",
    "district_id": "321020",
    "label": "Kawunghilir",
    "value": "Kawunghilir"
  },
  {
    "id": "3210202002",
    "district_id": "321020",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "3210202003",
    "district_id": "321020",
    "label": "Tenjolayar",
    "value": "Tenjolayar"
  },
  {
    "id": "3210202007",
    "district_id": "321020",
    "label": "Baribis",
    "value": "Baribis"
  },
  {
    "id": "3210202008",
    "district_id": "321020",
    "label": "Batujaya",
    "value": "Batujaya"
  },
  {
    "id": "3210202009",
    "district_id": "321020",
    "label": "Kutamanggu",
    "value": "Kutamanggu"
  },
  {
    "id": "3210202010",
    "district_id": "321020",
    "label": "Karayunan",
    "value": "Karayunan"
  },
  {
    "id": "3210212001",
    "district_id": "321021",
    "label": "Bantaragung",
    "value": "Bantaragung"
  },
  {
    "id": "3210212002",
    "district_id": "321021",
    "label": "Padaherang",
    "value": "Padaherang"
  },
  {
    "id": "3210212003",
    "district_id": "321021",
    "label": "Lengkong Kulon",
    "value": "Lengkong Kulon"
  },
  {
    "id": "3210212004",
    "district_id": "321021",
    "label": "Jerukleueut",
    "value": "Jerukleueut"
  },
  {
    "id": "3210212005",
    "district_id": "321021",
    "label": "Sindangwangi",
    "value": "Sindangwangi"
  },
  {
    "id": "3210212006",
    "district_id": "321021",
    "label": "Buahkapas",
    "value": "Buahkapas"
  },
  {
    "id": "3210212007",
    "district_id": "321021",
    "label": "Ujungberung",
    "value": "Ujungberung"
  },
  {
    "id": "3210212008",
    "district_id": "321021",
    "label": "Balagedog",
    "value": "Balagedog"
  },
  {
    "id": "3210212009",
    "district_id": "321021",
    "label": "Leuwilaja",
    "value": "Leuwilaja"
  },
  {
    "id": "3210212010",
    "district_id": "321021",
    "label": "Lengkong Wetan",
    "value": "Lengkong Wetan"
  },
  {
    "id": "3210222001",
    "district_id": "321022",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "3210222002",
    "district_id": "321022",
    "label": "Sunia",
    "value": "Sunia"
  },
  {
    "id": "3210222003",
    "district_id": "321022",
    "label": "Darmalarang",
    "value": "Darmalarang"
  },
  {
    "id": "3210222004",
    "district_id": "321022",
    "label": "Sindangpala",
    "value": "Sindangpala"
  },
  {
    "id": "3210222005",
    "district_id": "321022",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3210222006",
    "district_id": "321022",
    "label": "Kagok",
    "value": "Kagok"
  },
  {
    "id": "3210222007",
    "district_id": "321022",
    "label": "Cimeong",
    "value": "Cimeong"
  },
  {
    "id": "3210222008",
    "district_id": "321022",
    "label": "Panyindangan",
    "value": "Panyindangan"
  },
  {
    "id": "3210222009",
    "district_id": "321022",
    "label": "Kareo",
    "value": "Kareo"
  },
  {
    "id": "3210222010",
    "district_id": "321022",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3210222011",
    "district_id": "321022",
    "label": "Sunia Baru",
    "value": "Sunia Baru"
  },
  {
    "id": "3210222012",
    "district_id": "321022",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3210222013",
    "district_id": "321022",
    "label": "Girimulya",
    "value": "Girimulya"
  },
  {
    "id": "3210232001",
    "district_id": "321023",
    "label": "Sedareja",
    "value": "Sedareja"
  },
  {
    "id": "3210232002",
    "district_id": "321023",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3210232003",
    "district_id": "321023",
    "label": "Maniis",
    "value": "Maniis"
  },
  {
    "id": "3210232004",
    "district_id": "321023",
    "label": "Nagarakembang",
    "value": "Nagarakembang"
  },
  {
    "id": "3210232005",
    "district_id": "321023",
    "label": "Wangkelang",
    "value": "Wangkelang"
  },
  {
    "id": "3210232006",
    "district_id": "321023",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3210232007",
    "district_id": "321023",
    "label": "Cingambul",
    "value": "Cingambul"
  },
  {
    "id": "3210232008",
    "district_id": "321023",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3210232009",
    "district_id": "321023",
    "label": "Ciranjeng",
    "value": "Ciranjeng"
  },
  {
    "id": "3210232010",
    "district_id": "321023",
    "label": "Rawa",
    "value": "Rawa"
  },
  {
    "id": "3210232011",
    "district_id": "321023",
    "label": "Kondangmekar",
    "value": "Kondangmekar"
  },
  {
    "id": "3210232012",
    "district_id": "321023",
    "label": "Cintaasih",
    "value": "Cintaasih"
  },
  {
    "id": "3210232013",
    "district_id": "321023",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "3210242001",
    "district_id": "321024",
    "label": "Jatisawit",
    "value": "Jatisawit"
  },
  {
    "id": "3210242002",
    "district_id": "321024",
    "label": "Leuwikidang",
    "value": "Leuwikidang"
  },
  {
    "id": "3210242003",
    "district_id": "321024",
    "label": "Ranji Kulon",
    "value": "Ranji Kulon"
  },
  {
    "id": "3210242004",
    "district_id": "321024",
    "label": "Ranji Wetan",
    "value": "Ranji Wetan"
  },
  {
    "id": "3210242005",
    "district_id": "321024",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3210242006",
    "district_id": "321024",
    "label": "Kasokandel",
    "value": "Kasokandel"
  },
  {
    "id": "3210242007",
    "district_id": "321024",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3210242008",
    "district_id": "321024",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3210242009",
    "district_id": "321024",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3210242010",
    "district_id": "321024",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "3210252001",
    "district_id": "321025",
    "label": "Pasirayu",
    "value": "Pasirayu"
  },
  {
    "id": "3210252002",
    "district_id": "321025",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "3210252003",
    "district_id": "321025",
    "label": "Garawastu",
    "value": "Garawastu"
  },
  {
    "id": "3210252004",
    "district_id": "321025",
    "label": "Indrakila",
    "value": "Indrakila"
  },
  {
    "id": "3210252005",
    "district_id": "321025",
    "label": "Gunungkuning",
    "value": "Gunungkuning"
  },
  {
    "id": "3210252007",
    "district_id": "321025",
    "label": "Sangkanhurip",
    "value": "Sangkanhurip"
  },
  {
    "id": "3210252008",
    "district_id": "321025",
    "label": "Bayureja",
    "value": "Bayureja"
  },
  {
    "id": "3210262001",
    "district_id": "321026",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3210262002",
    "district_id": "321026",
    "label": "Werasari",
    "value": "Werasari"
  },
  {
    "id": "3210262003",
    "district_id": "321026",
    "label": "Malausma",
    "value": "Malausma"
  },
  {
    "id": "3210262004",
    "district_id": "321026",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "3210262005",
    "district_id": "321026",
    "label": "Cimuncang",
    "value": "Cimuncang"
  },
  {
    "id": "3210262006",
    "district_id": "321026",
    "label": "Ciranca",
    "value": "Ciranca"
  },
  {
    "id": "3210262007",
    "district_id": "321026",
    "label": "Banyusari",
    "value": "Banyusari"
  },
  {
    "id": "3210262008",
    "district_id": "321026",
    "label": "Buninagara",
    "value": "Buninagara"
  },
  {
    "id": "3210262009",
    "district_id": "321026",
    "label": "Jagamulya",
    "value": "Jagamulya"
  },
  {
    "id": "3210262010",
    "district_id": "321026",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3210262011",
    "district_id": "321026",
    "label": "Kramat Jaya",
    "value": "Kramat Jaya"
  },
  {
    "id": "3211012001",
    "district_id": "321101",
    "label": "Cimungkal",
    "value": "Cimungkal"
  },
  {
    "id": "3211012002",
    "district_id": "321101",
    "label": "Ganjaresik",
    "value": "Ganjaresik"
  },
  {
    "id": "3211012003",
    "district_id": "321101",
    "label": "Cilengkrang",
    "value": "Cilengkrang"
  },
  {
    "id": "3211012004",
    "district_id": "321101",
    "label": "Cikareo Selatan",
    "value": "Cikareo Selatan"
  },
  {
    "id": "3211012005",
    "district_id": "321101",
    "label": "Cikareo Utara",
    "value": "Cikareo Utara"
  },
  {
    "id": "3211012006",
    "district_id": "321101",
    "label": "Wado",
    "value": "Wado"
  },
  {
    "id": "3211012007",
    "district_id": "321101",
    "label": "Mulyajaya",
    "value": "Mulyajaya"
  },
  {
    "id": "3211012009",
    "district_id": "321101",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3211012010",
    "district_id": "321101",
    "label": "Cisurat",
    "value": "Cisurat"
  },
  {
    "id": "3211012011",
    "district_id": "321101",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3211022001",
    "district_id": "321102",
    "label": "Sirnasari",
    "value": "Sirnasari"
  },
  {
    "id": "3211022002",
    "district_id": "321102",
    "label": "Tarikolot",
    "value": "Tarikolot"
  },
  {
    "id": "3211022003",
    "district_id": "321102",
    "label": "Pawenang",
    "value": "Pawenang"
  },
  {
    "id": "3211022004",
    "district_id": "321102",
    "label": "Sarimekar",
    "value": "Sarimekar"
  },
  {
    "id": "3211022005",
    "district_id": "321102",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3211022006",
    "district_id": "321102",
    "label": "Kirisik",
    "value": "Kirisik"
  },
  {
    "id": "3211022007",
    "district_id": "321102",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3211022008",
    "district_id": "321102",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3211022009",
    "district_id": "321102",
    "label": "Cimanintin",
    "value": "Cimanintin"
  },
  {
    "id": "3211032001",
    "district_id": "321103",
    "label": "Darmaraja",
    "value": "Darmaraja"
  },
  {
    "id": "3211032002",
    "district_id": "321103",
    "label": "Darmajaya",
    "value": "Darmajaya"
  },
  {
    "id": "3211032003",
    "district_id": "321103",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3211032005",
    "district_id": "321103",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3211032006",
    "district_id": "321103",
    "label": "Cikeusi",
    "value": "Cikeusi"
  },
  {
    "id": "3211032007",
    "district_id": "321103",
    "label": "Cipeuteuy",
    "value": "Cipeuteuy"
  },
  {
    "id": "3211032009",
    "district_id": "321103",
    "label": "Cieunteung",
    "value": "Cieunteung"
  },
  {
    "id": "3211032010",
    "district_id": "321103",
    "label": "Karangpakuan",
    "value": "Karangpakuan"
  },
  {
    "id": "3211032011",
    "district_id": "321103",
    "label": "Pakualam",
    "value": "Pakualam"
  },
  {
    "id": "3211032013",
    "district_id": "321103",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3211032015",
    "district_id": "321103",
    "label": "Tarunajaya",
    "value": "Tarunajaya"
  },
  {
    "id": "3211032016",
    "district_id": "321103",
    "label": "Ranggon",
    "value": "Ranggon"
  },
  {
    "id": "3211042001",
    "district_id": "321104",
    "label": "Jayamekar",
    "value": "Jayamekar"
  },
  {
    "id": "3211042002",
    "district_id": "321104",
    "label": "Buanamekar",
    "value": "Buanamekar"
  },
  {
    "id": "3211042003",
    "district_id": "321104",
    "label": "Cibugel",
    "value": "Cibugel"
  },
  {
    "id": "3211042004",
    "district_id": "321104",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3211042005",
    "district_id": "321104",
    "label": "Cipasang",
    "value": "Cipasang"
  },
  {
    "id": "3211042006",
    "district_id": "321104",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3211042007",
    "district_id": "321104",
    "label": "Jayamandiri",
    "value": "Jayamandiri"
  },
  {
    "id": "3211052001",
    "district_id": "321105",
    "label": "Cisitu",
    "value": "Cisitu"
  },
  {
    "id": "3211052002",
    "district_id": "321105",
    "label": "Situmekar",
    "value": "Situmekar"
  },
  {
    "id": "3211052003",
    "district_id": "321105",
    "label": "Pajagan",
    "value": "Pajagan"
  },
  {
    "id": "3211052004",
    "district_id": "321105",
    "label": "Cigintung",
    "value": "Cigintung"
  },
  {
    "id": "3211052005",
    "district_id": "321105",
    "label": "Sundamekar",
    "value": "Sundamekar"
  },
  {
    "id": "3211052006",
    "district_id": "321105",
    "label": "Linggajaya",
    "value": "Linggajaya"
  },
  {
    "id": "3211052007",
    "district_id": "321105",
    "label": "Ranjeng",
    "value": "Ranjeng"
  },
  {
    "id": "3211052008",
    "district_id": "321105",
    "label": "Cilopang",
    "value": "Cilopang"
  },
  {
    "id": "3211052009",
    "district_id": "321105",
    "label": "Cimarga",
    "value": "Cimarga"
  },
  {
    "id": "3211052010",
    "district_id": "321105",
    "label": "Cinangsi",
    "value": "Cinangsi"
  },
  {
    "id": "3211062001",
    "district_id": "321106",
    "label": "Situraja Utara",
    "value": "Situraja Utara"
  },
  {
    "id": "3211062002",
    "district_id": "321106",
    "label": "Situraja",
    "value": "Situraja"
  },
  {
    "id": "3211062003",
    "district_id": "321106",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3211062004",
    "district_id": "321106",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3211062005",
    "district_id": "321106",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3211062006",
    "district_id": "321106",
    "label": "Kaduwulung",
    "value": "Kaduwulung"
  },
  {
    "id": "3211062007",
    "district_id": "321106",
    "label": "Karangheuleut",
    "value": "Karangheuleut"
  },
  {
    "id": "3211062008",
    "district_id": "321106",
    "label": "Cijeler",
    "value": "Cijeler"
  },
  {
    "id": "3211062009",
    "district_id": "321106",
    "label": "Ambit",
    "value": "Ambit"
  },
  {
    "id": "3211062010",
    "district_id": "321106",
    "label": "Jatimekar",
    "value": "Jatimekar"
  },
  {
    "id": "3211062011",
    "district_id": "321106",
    "label": "Cijati",
    "value": "Cijati"
  },
  {
    "id": "3211062012",
    "district_id": "321106",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3211062013",
    "district_id": "321106",
    "label": "Malaka",
    "value": "Malaka"
  },
  {
    "id": "3211062014",
    "district_id": "321106",
    "label": "Sukatali",
    "value": "Sukatali"
  },
  {
    "id": "3211062015",
    "district_id": "321106",
    "label": "Cicarimanah",
    "value": "Cicarimanah"
  },
  {
    "id": "3211072001",
    "district_id": "321107",
    "label": "Conggeang Kulon",
    "value": "Conggeang Kulon"
  },
  {
    "id": "3211072002",
    "district_id": "321107",
    "label": "Conggeang Wetan",
    "value": "Conggeang Wetan"
  },
  {
    "id": "3211072003",
    "district_id": "321107",
    "label": "Cipamekar",
    "value": "Cipamekar"
  },
  {
    "id": "3211072004",
    "district_id": "321107",
    "label": "Cibeureuyeuh",
    "value": "Cibeureuyeuh"
  },
  {
    "id": "3211072005",
    "district_id": "321107",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3211072006",
    "district_id": "321107",
    "label": "Babakan Asem",
    "value": "Babakan Asem"
  },
  {
    "id": "3211072007",
    "district_id": "321107",
    "label": "Padaasih",
    "value": "Padaasih"
  },
  {
    "id": "3211072008",
    "district_id": "321107",
    "label": "Ungkal",
    "value": "Ungkal"
  },
  {
    "id": "3211072009",
    "district_id": "321107",
    "label": "Karang Layung",
    "value": "Karang Layung"
  },
  {
    "id": "3211072010",
    "district_id": "321107",
    "label": "Cacaban",
    "value": "Cacaban"
  },
  {
    "id": "3211072011",
    "district_id": "321107",
    "label": "Narimbang",
    "value": "Narimbang"
  },
  {
    "id": "3211072012",
    "district_id": "321107",
    "label": "Cibubuan",
    "value": "Cibubuan"
  },
  {
    "id": "3211082001",
    "district_id": "321108",
    "label": "Pasehkidul",
    "value": "Pasehkidul"
  },
  {
    "id": "3211082002",
    "district_id": "321108",
    "label": "Paseh Kaler",
    "value": "Paseh Kaler"
  },
  {
    "id": "3211082003",
    "district_id": "321108",
    "label": "Legok Kidul",
    "value": "Legok Kidul"
  },
  {
    "id": "3211082004",
    "district_id": "321108",
    "label": "Legok Kaler",
    "value": "Legok Kaler"
  },
  {
    "id": "3211082005",
    "district_id": "321108",
    "label": "Bongkok",
    "value": "Bongkok"
  },
  {
    "id": "3211082006",
    "district_id": "321108",
    "label": "Padanan",
    "value": "Padanan"
  },
  {
    "id": "3211082007",
    "district_id": "321108",
    "label": "Pasirreungit",
    "value": "Pasirreungit"
  },
  {
    "id": "3211082008",
    "district_id": "321108",
    "label": "Cijambe",
    "value": "Cijambe"
  },
  {
    "id": "3211082009",
    "district_id": "321108",
    "label": "Haurkuning",
    "value": "Haurkuning"
  },
  {
    "id": "3211082010",
    "district_id": "321108",
    "label": "Citepok",
    "value": "Citepok"
  },
  {
    "id": "3211092001",
    "district_id": "321109",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3211092002",
    "district_id": "321109",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3211092003",
    "district_id": "321109",
    "label": "Pamekarsari",
    "value": "Pamekarsari"
  },
  {
    "id": "3211092004",
    "district_id": "321109",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3211092005",
    "district_id": "321109",
    "label": "Surian",
    "value": "Surian"
  },
  {
    "id": "3211092006",
    "district_id": "321109",
    "label": "Suriamukti",
    "value": "Suriamukti"
  },
  {
    "id": "3211092007",
    "district_id": "321109",
    "label": "Suriamedal",
    "value": "Suriamedal"
  },
  {
    "id": "3211092008",
    "district_id": "321109",
    "label": "Ranggasari",
    "value": "Ranggasari"
  },
  {
    "id": "3211092009",
    "district_id": "321109",
    "label": "Nanjung Wangi",
    "value": "Nanjung Wangi"
  },
  {
    "id": "3211102001",
    "district_id": "321110",
    "label": "Buahdua",
    "value": "Buahdua"
  },
  {
    "id": "3211102002",
    "district_id": "321110",
    "label": "Hariang",
    "value": "Hariang"
  },
  {
    "id": "3211102003",
    "district_id": "321110",
    "label": "Karangbungur",
    "value": "Karangbungur"
  },
  {
    "id": "3211102004",
    "district_id": "321110",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3211102005",
    "district_id": "321110",
    "label": "Citaleus",
    "value": "Citaleus"
  },
  {
    "id": "3211102006",
    "district_id": "321110",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3211102007",
    "district_id": "321110",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "3211102008",
    "district_id": "321110",
    "label": "Sekarwangi",
    "value": "Sekarwangi"
  },
  {
    "id": "3211102009",
    "district_id": "321110",
    "label": "Gendereh",
    "value": "Gendereh"
  },
  {
    "id": "3211102010",
    "district_id": "321110",
    "label": "Panyindangan",
    "value": "Panyindangan"
  },
  {
    "id": "3211102011",
    "district_id": "321110",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3211102012",
    "district_id": "321110",
    "label": "Bojongloa",
    "value": "Bojongloa"
  },
  {
    "id": "3211102013",
    "district_id": "321110",
    "label": "Cikurubuk",
    "value": "Cikurubuk"
  },
  {
    "id": "3211102014",
    "district_id": "321110",
    "label": "Ciawitali",
    "value": "Ciawitali"
  },
  {
    "id": "3211112001",
    "district_id": "321111",
    "label": "Gudang",
    "value": "Gudang"
  },
  {
    "id": "3211112002",
    "district_id": "321111",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3211112003",
    "district_id": "321111",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3211112004",
    "district_id": "321111",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3211112005",
    "district_id": "321111",
    "label": "Kutamandiri",
    "value": "Kutamandiri"
  },
  {
    "id": "3211112006",
    "district_id": "321111",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3211112007",
    "district_id": "321111",
    "label": "Raharja",
    "value": "Raharja"
  },
  {
    "id": "3211112008",
    "district_id": "321111",
    "label": "Cijambu",
    "value": "Cijambu"
  },
  {
    "id": "3211112009",
    "district_id": "321111",
    "label": "Pasigaran",
    "value": "Pasigaran"
  },
  {
    "id": "3211112010",
    "district_id": "321111",
    "label": "Gunungmanik",
    "value": "Gunungmanik"
  },
  {
    "id": "3211112011",
    "district_id": "321111",
    "label": "Kadakajaya",
    "value": "Kadakajaya"
  },
  {
    "id": "3211112012",
    "district_id": "321111",
    "label": "Cinanjung",
    "value": "Cinanjung"
  },
  {
    "id": "3211122001",
    "district_id": "321112",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3211122002",
    "district_id": "321112",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "3211122003",
    "district_id": "321112",
    "label": "Banyuresmi",
    "value": "Banyuresmi"
  },
  {
    "id": "3211122004",
    "district_id": "321112",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3211122005",
    "district_id": "321112",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3211122006",
    "district_id": "321112",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3211122007",
    "district_id": "321112",
    "label": "Sukarapih",
    "value": "Sukarapih"
  },
  {
    "id": "3211132001",
    "district_id": "321113",
    "label": "Cigendel",
    "value": "Cigendel"
  },
  {
    "id": "3211132002",
    "district_id": "321113",
    "label": "Cijeruk",
    "value": "Cijeruk"
  },
  {
    "id": "3211132003",
    "district_id": "321113",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3211132004",
    "district_id": "321113",
    "label": "Haurgombong",
    "value": "Haurgombong"
  },
  {
    "id": "3211132005",
    "district_id": "321113",
    "label": "Cilembu",
    "value": "Cilembu"
  },
  {
    "id": "3211132006",
    "district_id": "321113",
    "label": "Cimarias",
    "value": "Cimarias"
  },
  {
    "id": "3211132007",
    "district_id": "321113",
    "label": "Cinanggerang",
    "value": "Cinanggerang"
  },
  {
    "id": "3211132008",
    "district_id": "321113",
    "label": "Mekarbakti",
    "value": "Mekarbakti"
  },
  {
    "id": "3211132009",
    "district_id": "321113",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3211132010",
    "district_id": "321113",
    "label": "Ciptasari",
    "value": "Ciptasari"
  },
  {
    "id": "3211132011",
    "district_id": "321113",
    "label": "Citali",
    "value": "Citali"
  },
  {
    "id": "3211142001",
    "district_id": "321114",
    "label": "Cimanggung",
    "value": "Cimanggung"
  },
  {
    "id": "3211142002",
    "district_id": "321114",
    "label": "Sindangpakuon",
    "value": "Sindangpakuon"
  },
  {
    "id": "3211142003",
    "district_id": "321114",
    "label": "Tegalmanggung",
    "value": "Tegalmanggung"
  },
  {
    "id": "3211142004",
    "district_id": "321114",
    "label": "Sindulang",
    "value": "Sindulang"
  },
  {
    "id": "3211142005",
    "district_id": "321114",
    "label": "Sindanggalih",
    "value": "Sindanggalih"
  },
  {
    "id": "3211142006",
    "district_id": "321114",
    "label": "Sawahdadap",
    "value": "Sawahdadap"
  },
  {
    "id": "3211142007",
    "district_id": "321114",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3211142008",
    "district_id": "321114",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3211142009",
    "district_id": "321114",
    "label": "Mangunarga",
    "value": "Mangunarga"
  },
  {
    "id": "3211142010",
    "district_id": "321114",
    "label": "Cihanjuang",
    "value": "Cihanjuang"
  },
  {
    "id": "3211142011",
    "district_id": "321114",
    "label": "Pasirnanjung",
    "value": "Pasirnanjung"
  },
  {
    "id": "3211152001",
    "district_id": "321115",
    "label": "Cikeruh",
    "value": "Cikeruh"
  },
  {
    "id": "3211152002",
    "district_id": "321115",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3211152003",
    "district_id": "321115",
    "label": "Cibeusi",
    "value": "Cibeusi"
  },
  {
    "id": "3211152004",
    "district_id": "321115",
    "label": "Cipacing",
    "value": "Cipacing"
  },
  {
    "id": "3211152005",
    "district_id": "321115",
    "label": "Sayang",
    "value": "Sayang"
  },
  {
    "id": "3211152006",
    "district_id": "321115",
    "label": "Mekargalih",
    "value": "Mekargalih"
  },
  {
    "id": "3211152007",
    "district_id": "321115",
    "label": "Cintamulya",
    "value": "Cintamulya"
  },
  {
    "id": "3211152008",
    "district_id": "321115",
    "label": "Jatimukti",
    "value": "Jatimukti"
  },
  {
    "id": "3211152009",
    "district_id": "321115",
    "label": "Cisempur",
    "value": "Cisempur"
  },
  {
    "id": "3211152010",
    "district_id": "321115",
    "label": "Jatiroke",
    "value": "Jatiroke"
  },
  {
    "id": "3211152011",
    "district_id": "321115",
    "label": "Cileles",
    "value": "Cileles"
  },
  {
    "id": "3211152012",
    "district_id": "321115",
    "label": "Cilayung",
    "value": "Cilayung"
  },
  {
    "id": "3211162001",
    "district_id": "321116",
    "label": "Nagarawangi",
    "value": "Nagarawangi"
  },
  {
    "id": "3211162002",
    "district_id": "321116",
    "label": "Cibunar",
    "value": "Cibunar"
  },
  {
    "id": "3211162003",
    "district_id": "321116",
    "label": "Pangadegan",
    "value": "Pangadegan"
  },
  {
    "id": "3211162004",
    "district_id": "321116",
    "label": "Sukahayu",
    "value": "Sukahayu"
  },
  {
    "id": "3211162005",
    "district_id": "321116",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3211162006",
    "district_id": "321116",
    "label": "Pamekaran",
    "value": "Pamekaran"
  },
  {
    "id": "3211162007",
    "district_id": "321116",
    "label": "Rancakalong",
    "value": "Rancakalong"
  },
  {
    "id": "3211162008",
    "district_id": "321116",
    "label": "Sukasirnarasa",
    "value": "Sukasirnarasa"
  },
  {
    "id": "3211162009",
    "district_id": "321116",
    "label": "Cibungur",
    "value": "Cibungur"
  },
  {
    "id": "3211162010",
    "district_id": "321116",
    "label": "Pasirbiru",
    "value": "Pasirbiru"
  },
  {
    "id": "3211171001",
    "district_id": "321117",
    "label": "Pasanggrahan Baru",
    "value": "Pasanggrahan Baru"
  },
  {
    "id": "3211171002",
    "district_id": "321117",
    "label": "Kotakulon",
    "value": "Kotakulon"
  },
  {
    "id": "3211171003",
    "district_id": "321117",
    "label": "Regolwetan",
    "value": "Regolwetan"
  },
  {
    "id": "3211171004",
    "district_id": "321117",
    "label": "Cipameungpeuk",
    "value": "Cipameungpeuk"
  },
  {
    "id": "3211172005",
    "district_id": "321117",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3211172006",
    "district_id": "321117",
    "label": "Baginda",
    "value": "Baginda"
  },
  {
    "id": "3211172007",
    "district_id": "321117",
    "label": "Cipancar",
    "value": "Cipancar"
  },
  {
    "id": "3211172008",
    "district_id": "321117",
    "label": "Citengah",
    "value": "Citengah"
  },
  {
    "id": "3211172009",
    "district_id": "321117",
    "label": "Gunasari",
    "value": "Gunasari"
  },
  {
    "id": "3211172010",
    "district_id": "321117",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3211172011",
    "district_id": "321117",
    "label": "Margamekar",
    "value": "Margamekar"
  },
  {
    "id": "3211172012",
    "district_id": "321117",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3211172013",
    "district_id": "321117",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3211172014",
    "district_id": "321117",
    "label": "Mekar Rahayu",
    "value": "Mekar Rahayu"
  },
  {
    "id": "3211181001",
    "district_id": "321118",
    "label": "Kotakaler",
    "value": "Kotakaler"
  },
  {
    "id": "3211181002",
    "district_id": "321118",
    "label": "Situ",
    "value": "Situ"
  },
  {
    "id": "3211181003",
    "district_id": "321118",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3211182004",
    "district_id": "321118",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3211182005",
    "district_id": "321118",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3211182006",
    "district_id": "321118",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3211182007",
    "district_id": "321118",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3211182008",
    "district_id": "321118",
    "label": "Margamukti",
    "value": "Margamukti"
  },
  {
    "id": "3211182009",
    "district_id": "321118",
    "label": "Sirnamulya",
    "value": "Sirnamulya"
  },
  {
    "id": "3211182010",
    "district_id": "321118",
    "label": "Kebonjati",
    "value": "Kebonjati"
  },
  {
    "id": "3211182011",
    "district_id": "321118",
    "label": "Jatihurip",
    "value": "Jatihurip"
  },
  {
    "id": "3211182012",
    "district_id": "321118",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3211182013",
    "district_id": "321118",
    "label": "Rancamulya",
    "value": "Rancamulya"
  },
  {
    "id": "3211192001",
    "district_id": "321119",
    "label": "Ganeas",
    "value": "Ganeas"
  },
  {
    "id": "3211192002",
    "district_id": "321119",
    "label": "Dayeuhluhur",
    "value": "Dayeuhluhur"
  },
  {
    "id": "3211192003",
    "district_id": "321119",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3211192004",
    "district_id": "321119",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3211192005",
    "district_id": "321119",
    "label": "Sukawening",
    "value": "Sukawening"
  },
  {
    "id": "3211192006",
    "district_id": "321119",
    "label": "Tanjunghurip",
    "value": "Tanjunghurip"
  },
  {
    "id": "3211192007",
    "district_id": "321119",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3211192008",
    "district_id": "321119",
    "label": "Cikonengkulon",
    "value": "Cikonengkulon"
  },
  {
    "id": "3211202001",
    "district_id": "321120",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3211202002",
    "district_id": "321120",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "3211202003",
    "district_id": "321120",
    "label": "Gunturmekar",
    "value": "Gunturmekar"
  },
  {
    "id": "3211202004",
    "district_id": "321120",
    "label": "Mulyamekar",
    "value": "Mulyamekar"
  },
  {
    "id": "3211202005",
    "district_id": "321120",
    "label": "Banyuasih",
    "value": "Banyuasih"
  },
  {
    "id": "3211202006",
    "district_id": "321120",
    "label": "Kertamekar",
    "value": "Kertamekar"
  },
  {
    "id": "3211202007",
    "district_id": "321120",
    "label": "Kertaharja",
    "value": "Kertaharja"
  },
  {
    "id": "3211202008",
    "district_id": "321120",
    "label": "Cigentur",
    "value": "Cigentur"
  },
  {
    "id": "3211202009",
    "district_id": "321120",
    "label": "Tanjungmekar",
    "value": "Tanjungmekar"
  },
  {
    "id": "3211202010",
    "district_id": "321120",
    "label": "Tanjungmulya",
    "value": "Tanjungmulya"
  },
  {
    "id": "3211202011",
    "district_id": "321120",
    "label": "Boros",
    "value": "Boros"
  },
  {
    "id": "3211202012",
    "district_id": "321120",
    "label": "Awilega",
    "value": "Awilega"
  },
  {
    "id": "3211212001",
    "district_id": "321121",
    "label": "Cikaramas",
    "value": "Cikaramas"
  },
  {
    "id": "3211212002",
    "district_id": "321121",
    "label": "Wargaluyu",
    "value": "Wargaluyu"
  },
  {
    "id": "3211212003",
    "district_id": "321121",
    "label": "Jingkang",
    "value": "Jingkang"
  },
  {
    "id": "3211212004",
    "district_id": "321121",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "3211212005",
    "district_id": "321121",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3211212006",
    "district_id": "321121",
    "label": "Tanjungwangi",
    "value": "Tanjungwangi"
  },
  {
    "id": "3211212007",
    "district_id": "321121",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3211212008",
    "district_id": "321121",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3211212009",
    "district_id": "321121",
    "label": "Tanjung Medar",
    "value": "Tanjung Medar"
  },
  {
    "id": "3211222001",
    "district_id": "321122",
    "label": "Cimalaka",
    "value": "Cimalaka"
  },
  {
    "id": "3211222002",
    "district_id": "321122",
    "label": "Galudra",
    "value": "Galudra"
  },
  {
    "id": "3211222003",
    "district_id": "321122",
    "label": "Cibeureum Kulon",
    "value": "Cibeureum Kulon"
  },
  {
    "id": "3211222004",
    "district_id": "321122",
    "label": "Naluk",
    "value": "Naluk"
  },
  {
    "id": "3211222005",
    "district_id": "321122",
    "label": "Nyalindung",
    "value": "Nyalindung"
  },
  {
    "id": "3211222006",
    "district_id": "321122",
    "label": "Trunamanggala",
    "value": "Trunamanggala"
  },
  {
    "id": "3211222007",
    "district_id": "321122",
    "label": "Cikole",
    "value": "Cikole"
  },
  {
    "id": "3211222008",
    "district_id": "321122",
    "label": "Cibeureum Wetan",
    "value": "Cibeureum Wetan"
  },
  {
    "id": "3211222009",
    "district_id": "321122",
    "label": "Mandalaherang",
    "value": "Mandalaherang"
  },
  {
    "id": "3211222010",
    "district_id": "321122",
    "label": "Licin",
    "value": "Licin"
  },
  {
    "id": "3211222011",
    "district_id": "321122",
    "label": "Citimun",
    "value": "Citimun"
  },
  {
    "id": "3211222012",
    "district_id": "321122",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3211222013",
    "district_id": "321122",
    "label": "Padasari",
    "value": "Padasari"
  },
  {
    "id": "3211222014",
    "district_id": "321122",
    "label": "Cimuja",
    "value": "Cimuja"
  },
  {
    "id": "3211232001",
    "district_id": "321123",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3211232002",
    "district_id": "321123",
    "label": "Ciuyah",
    "value": "Ciuyah"
  },
  {
    "id": "3211232003",
    "district_id": "321123",
    "label": "Cimara",
    "value": "Cimara"
  },
  {
    "id": "3211232004",
    "district_id": "321123",
    "label": "Bantarmara",
    "value": "Bantarmara"
  },
  {
    "id": "3211232005",
    "district_id": "321123",
    "label": "Cipandanwangi",
    "value": "Cipandanwangi"
  },
  {
    "id": "3211232006",
    "district_id": "321123",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3211232007",
    "district_id": "321123",
    "label": "Kebonkalapa",
    "value": "Kebonkalapa"
  },
  {
    "id": "3211242001",
    "district_id": "321124",
    "label": "Tomo",
    "value": "Tomo"
  },
  {
    "id": "3211242002",
    "district_id": "321124",
    "label": "Tolengas",
    "value": "Tolengas"
  },
  {
    "id": "3211242003",
    "district_id": "321124",
    "label": "Darmawangi",
    "value": "Darmawangi"
  },
  {
    "id": "3211242004",
    "district_id": "321124",
    "label": "Marongge",
    "value": "Marongge"
  },
  {
    "id": "3211242005",
    "district_id": "321124",
    "label": "Jembarwangi",
    "value": "Jembarwangi"
  },
  {
    "id": "3211242006",
    "district_id": "321124",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3211242007",
    "district_id": "321124",
    "label": "Cipeles",
    "value": "Cipeles"
  },
  {
    "id": "3211242008",
    "district_id": "321124",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3211242010",
    "district_id": "321124",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3211252001",
    "district_id": "321125",
    "label": "Ujungjaya",
    "value": "Ujungjaya"
  },
  {
    "id": "3211252002",
    "district_id": "321125",
    "label": "Palabuan",
    "value": "Palabuan"
  },
  {
    "id": "3211252003",
    "district_id": "321125",
    "label": "Palasari",
    "value": "Palasari"
  },
  {
    "id": "3211252004",
    "district_id": "321125",
    "label": "Keboncau",
    "value": "Keboncau"
  },
  {
    "id": "3211252005",
    "district_id": "321125",
    "label": "Sakurjaya",
    "value": "Sakurjaya"
  },
  {
    "id": "3211252006",
    "district_id": "321125",
    "label": "Kudangwangi",
    "value": "Kudangwangi"
  },
  {
    "id": "3211252007",
    "district_id": "321125",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3211252008",
    "district_id": "321125",
    "label": "Cipelang",
    "value": "Cipelang"
  },
  {
    "id": "3211252009",
    "district_id": "321125",
    "label": "Cibuluh",
    "value": "Cibuluh"
  },
  {
    "id": "3211262001",
    "district_id": "321126",
    "label": "Cijeungjing",
    "value": "Cijeungjing"
  },
  {
    "id": "3211262002",
    "district_id": "321126",
    "label": "Kadujaya",
    "value": "Kadujaya"
  },
  {
    "id": "3211262003",
    "district_id": "321126",
    "label": "Lebaksiuh",
    "value": "Lebaksiuh"
  },
  {
    "id": "3211262004",
    "district_id": "321126",
    "label": "Cintajaya",
    "value": "Cintajaya"
  },
  {
    "id": "3211262005",
    "district_id": "321126",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3211262006",
    "district_id": "321126",
    "label": "Mekarasih",
    "value": "Mekarasih"
  },
  {
    "id": "3211262008",
    "district_id": "321126",
    "label": "Ciranggem",
    "value": "Ciranggem"
  },
  {
    "id": "3211262009",
    "district_id": "321126",
    "label": "Cisampih",
    "value": "Cisampih"
  },
  {
    "id": "3211262010",
    "district_id": "321126",
    "label": "Jemah",
    "value": "Jemah"
  },
  {
    "id": "3211262011",
    "district_id": "321126",
    "label": "Karedok",
    "value": "Karedok"
  },
  {
    "id": "3211262012",
    "district_id": "321126",
    "label": "Kadu",
    "value": "Kadu"
  },
  {
    "id": "3212012007",
    "district_id": "321201",
    "label": "Haurkolot",
    "value": "Haurkolot"
  },
  {
    "id": "3212012008",
    "district_id": "321201",
    "label": "Haurgeulis",
    "value": "Haurgeulis"
  },
  {
    "id": "3212012009",
    "district_id": "321201",
    "label": "Sukajati",
    "value": "Sukajati"
  },
  {
    "id": "3212012010",
    "district_id": "321201",
    "label": "Wanakaya",
    "value": "Wanakaya"
  },
  {
    "id": "3212012011",
    "district_id": "321201",
    "label": "Karangtumaritis",
    "value": "Karangtumaritis"
  },
  {
    "id": "3212012012",
    "district_id": "321201",
    "label": "Kertanegara",
    "value": "Kertanegara"
  },
  {
    "id": "3212012013",
    "district_id": "321201",
    "label": "Cipancuh",
    "value": "Cipancuh"
  },
  {
    "id": "3212012014",
    "district_id": "321201",
    "label": "Mekarjati",
    "value": "Mekarjati"
  },
  {
    "id": "3212012015",
    "district_id": "321201",
    "label": "Sidadadi",
    "value": "Sidadadi"
  },
  {
    "id": "3212012016",
    "district_id": "321201",
    "label": "Sumbermulya",
    "value": "Sumbermulya"
  },
  {
    "id": "3212022001",
    "district_id": "321202",
    "label": "Tanjungkerta",
    "value": "Tanjungkerta"
  },
  {
    "id": "3212022002",
    "district_id": "321202",
    "label": "Kroya",
    "value": "Kroya"
  },
  {
    "id": "3212022003",
    "district_id": "321202",
    "label": "Sumbon",
    "value": "Sumbon"
  },
  {
    "id": "3212022004",
    "district_id": "321202",
    "label": "Sukamelang",
    "value": "Sukamelang"
  },
  {
    "id": "3212022005",
    "district_id": "321202",
    "label": "Temiyang",
    "value": "Temiyang"
  },
  {
    "id": "3212022006",
    "district_id": "321202",
    "label": "Tamiyangsari",
    "value": "Tamiyangsari"
  },
  {
    "id": "3212022007",
    "district_id": "321202",
    "label": "Jayamulya",
    "value": "Jayamulya"
  },
  {
    "id": "3212022008",
    "district_id": "321202",
    "label": "Sukaslamet",
    "value": "Sukaslamet"
  },
  {
    "id": "3212022009",
    "district_id": "321202",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3212032001",
    "district_id": "321203",
    "label": "Kedungdawa",
    "value": "Kedungdawa"
  },
  {
    "id": "3212032002",
    "district_id": "321203",
    "label": "Babakanjaya",
    "value": "Babakanjaya"
  },
  {
    "id": "3212032003",
    "district_id": "321203",
    "label": "Gabus Kulon",
    "value": "Gabus Kulon"
  },
  {
    "id": "3212032004",
    "district_id": "321203",
    "label": "Sekarmulya",
    "value": "Sekarmulya"
  },
  {
    "id": "3212032005",
    "district_id": "321203",
    "label": "Kedokangabus",
    "value": "Kedokangabus"
  },
  {
    "id": "3212032006",
    "district_id": "321203",
    "label": "Rancamulya",
    "value": "Rancamulya"
  },
  {
    "id": "3212032007",
    "district_id": "321203",
    "label": "Rancahan",
    "value": "Rancahan"
  },
  {
    "id": "3212032008",
    "district_id": "321203",
    "label": "Gabuswetan",
    "value": "Gabuswetan"
  },
  {
    "id": "3212032009",
    "district_id": "321203",
    "label": "Drunten Wetan",
    "value": "Drunten Wetan"
  },
  {
    "id": "3212032010",
    "district_id": "321203",
    "label": "Drunten Kulon",
    "value": "Drunten Kulon"
  },
  {
    "id": "3212042003",
    "district_id": "321204",
    "label": "Loyang",
    "value": "Loyang"
  },
  {
    "id": "3212042004",
    "district_id": "321204",
    "label": "Amis",
    "value": "Amis"
  },
  {
    "id": "3212042005",
    "district_id": "321204",
    "label": "Jatisura",
    "value": "Jatisura"
  },
  {
    "id": "3212042006",
    "district_id": "321204",
    "label": "Jambak",
    "value": "Jambak"
  },
  {
    "id": "3212042007",
    "district_id": "321204",
    "label": "Cikidung",
    "value": "Cikidung"
  },
  {
    "id": "3212042012",
    "district_id": "321204",
    "label": "Cikedung Lor",
    "value": "Cikedung Lor"
  },
  {
    "id": "3212042013",
    "district_id": "321204",
    "label": "Mundakjaya",
    "value": "Mundakjaya"
  },
  {
    "id": "3212052001",
    "district_id": "321205",
    "label": "Tunggulpayung",
    "value": "Tunggulpayung"
  },
  {
    "id": "3212052002",
    "district_id": "321205",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3212052003",
    "district_id": "321205",
    "label": "Nunuk",
    "value": "Nunuk"
  },
  {
    "id": "3212052004",
    "district_id": "321205",
    "label": "Tempel",
    "value": "Tempel"
  },
  {
    "id": "3212052005",
    "district_id": "321205",
    "label": "Pangauban",
    "value": "Pangauban"
  },
  {
    "id": "3212052006",
    "district_id": "321205",
    "label": "Telagasari",
    "value": "Telagasari"
  },
  {
    "id": "3212052007",
    "district_id": "321205",
    "label": "Langgengsari",
    "value": "Langgengsari"
  },
  {
    "id": "3212052008",
    "district_id": "321205",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3212052009",
    "district_id": "321205",
    "label": "Lelea",
    "value": "Lelea"
  },
  {
    "id": "3212052010",
    "district_id": "321205",
    "label": "Cempeh",
    "value": "Cempeh"
  },
  {
    "id": "3212052011",
    "district_id": "321205",
    "label": "Tempel Kulon",
    "value": "Tempel Kulon"
  },
  {
    "id": "3212062008",
    "district_id": "321206",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3212062015",
    "district_id": "321206",
    "label": "Bangodua",
    "value": "Bangodua"
  },
  {
    "id": "3212062016",
    "district_id": "321206",
    "label": "Beduyut",
    "value": "Beduyut"
  },
  {
    "id": "3212062017",
    "district_id": "321206",
    "label": "Rancasari",
    "value": "Rancasari"
  },
  {
    "id": "3212062018",
    "district_id": "321206",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3212062019",
    "district_id": "321206",
    "label": "Karanggetas",
    "value": "Karanggetas"
  },
  {
    "id": "3212062020",
    "district_id": "321206",
    "label": "Tegalgirang",
    "value": "Tegalgirang"
  },
  {
    "id": "3212062021",
    "district_id": "321206",
    "label": "Malangsari",
    "value": "Malangsari"
  },
  {
    "id": "3212072006",
    "district_id": "321207",
    "label": "Bangkaloa Ilir",
    "value": "Bangkaloa Ilir"
  },
  {
    "id": "3212072007",
    "district_id": "321207",
    "label": "Widasari",
    "value": "Widasari"
  },
  {
    "id": "3212072008",
    "district_id": "321207",
    "label": "Kalensari",
    "value": "Kalensari"
  },
  {
    "id": "3212072010",
    "district_id": "321207",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3212072011",
    "district_id": "321207",
    "label": "Ujungaris",
    "value": "Ujungaris"
  },
  {
    "id": "3212072012",
    "district_id": "321207",
    "label": "Kongsijaya",
    "value": "Kongsijaya"
  },
  {
    "id": "3212072013",
    "district_id": "321207",
    "label": "Ujungjaya",
    "value": "Ujungjaya"
  },
  {
    "id": "3212072014",
    "district_id": "321207",
    "label": "Ujungpondok Jaya",
    "value": "Ujungpondok Jaya"
  },
  {
    "id": "3212072015",
    "district_id": "321207",
    "label": "Leuwigede",
    "value": "Leuwigede"
  },
  {
    "id": "3212072016",
    "district_id": "321207",
    "label": "Kasmaran",
    "value": "Kasmaran"
  },
  {
    "id": "3212082007",
    "district_id": "321208",
    "label": "Tulungagung",
    "value": "Tulungagung"
  },
  {
    "id": "3212082008",
    "district_id": "321208",
    "label": "Jengkok",
    "value": "Jengkok"
  },
  {
    "id": "3212082009",
    "district_id": "321208",
    "label": "Tegal Wirangrong",
    "value": "Tegal Wirangrong"
  },
  {
    "id": "3212082010",
    "district_id": "321208",
    "label": "Manguntara",
    "value": "Manguntara"
  },
  {
    "id": "3212082011",
    "district_id": "321208",
    "label": "Jambe",
    "value": "Jambe"
  },
  {
    "id": "3212082012",
    "district_id": "321208",
    "label": "Lemahayu",
    "value": "Lemahayu"
  },
  {
    "id": "3212082013",
    "district_id": "321208",
    "label": "Tenajar Kidul",
    "value": "Tenajar Kidul"
  },
  {
    "id": "3212082014",
    "district_id": "321208",
    "label": "Kertasemaya",
    "value": "Kertasemaya"
  },
  {
    "id": "3212082015",
    "district_id": "321208",
    "label": "Kliwed",
    "value": "Kliwed"
  },
  {
    "id": "3212082016",
    "district_id": "321208",
    "label": "Tenajar",
    "value": "Tenajar"
  },
  {
    "id": "3212082017",
    "district_id": "321208",
    "label": "Larangan Jambe",
    "value": "Larangan Jambe"
  },
  {
    "id": "3212082018",
    "district_id": "321208",
    "label": "Tenajar Lor",
    "value": "Tenajar Lor"
  },
  {
    "id": "3212082019",
    "district_id": "321208",
    "label": "Sukawera",
    "value": "Sukawera"
  },
  {
    "id": "3212092001",
    "district_id": "321209",
    "label": "Purwajaya",
    "value": "Purwajaya"
  },
  {
    "id": "3212092002",
    "district_id": "321209",
    "label": "Kapringan",
    "value": "Kapringan"
  },
  {
    "id": "3212092003",
    "district_id": "321209",
    "label": "Singakerta",
    "value": "Singakerta"
  },
  {
    "id": "3212092004",
    "district_id": "321209",
    "label": "Dukuhjati",
    "value": "Dukuhjati"
  },
  {
    "id": "3212092005",
    "district_id": "321209",
    "label": "Tegalmulya",
    "value": "Tegalmulya"
  },
  {
    "id": "3212092006",
    "district_id": "321209",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3212092007",
    "district_id": "321209",
    "label": "Srengseng",
    "value": "Srengseng"
  },
  {
    "id": "3212092008",
    "district_id": "321209",
    "label": "Luwunggesik",
    "value": "Luwunggesik"
  },
  {
    "id": "3212092009",
    "district_id": "321209",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3212092010",
    "district_id": "321209",
    "label": "Krangkeng",
    "value": "Krangkeng"
  },
  {
    "id": "3212092011",
    "district_id": "321209",
    "label": "Tanjakan",
    "value": "Tanjakan"
  },
  {
    "id": "3212102008",
    "district_id": "321210",
    "label": "Tanjungpura",
    "value": "Tanjungpura"
  },
  {
    "id": "3212102009",
    "district_id": "321210",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3212102010",
    "district_id": "321210",
    "label": "Pringgacala",
    "value": "Pringgacala"
  },
  {
    "id": "3212102011",
    "district_id": "321210",
    "label": "Benda",
    "value": "Benda"
  },
  {
    "id": "3212102012",
    "district_id": "321210",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3212102013",
    "district_id": "321210",
    "label": "Karangampel Kidul",
    "value": "Karangampel Kidul"
  },
  {
    "id": "3212102014",
    "district_id": "321210",
    "label": "Karangampel",
    "value": "Karangampel"
  },
  {
    "id": "3212102015",
    "district_id": "321210",
    "label": "Dukuh Jeruk",
    "value": "Dukuh Jeruk"
  },
  {
    "id": "3212102016",
    "district_id": "321210",
    "label": "Dukuh Tengah",
    "value": "Dukuh Tengah"
  },
  {
    "id": "3212102017",
    "district_id": "321210",
    "label": "Mundu",
    "value": "Mundu"
  },
  {
    "id": "3212102019",
    "district_id": "321210",
    "label": "Kaplongan Lor",
    "value": "Kaplongan Lor"
  },
  {
    "id": "3212112001",
    "district_id": "321211",
    "label": "Segeran Kidul",
    "value": "Segeran Kidul"
  },
  {
    "id": "3212112002",
    "district_id": "321211",
    "label": "Segeran Lor",
    "value": "Segeran Lor"
  },
  {
    "id": "3212112003",
    "district_id": "321211",
    "label": "Juntiweden",
    "value": "Juntiweden"
  },
  {
    "id": "3212112004",
    "district_id": "321211",
    "label": "Dadap",
    "value": "Dadap"
  },
  {
    "id": "3212112005",
    "district_id": "321211",
    "label": "Juntinyuat",
    "value": "Juntinyuat"
  },
  {
    "id": "3212112006",
    "district_id": "321211",
    "label": "Juntikedokan",
    "value": "Juntikedokan"
  },
  {
    "id": "3212112007",
    "district_id": "321211",
    "label": "Pondoh",
    "value": "Pondoh"
  },
  {
    "id": "3212112008",
    "district_id": "321211",
    "label": "Sambimaya",
    "value": "Sambimaya"
  },
  {
    "id": "3212112009",
    "district_id": "321211",
    "label": "Tinumpuk",
    "value": "Tinumpuk"
  },
  {
    "id": "3212112010",
    "district_id": "321211",
    "label": "Juntikebon",
    "value": "Juntikebon"
  },
  {
    "id": "3212112011",
    "district_id": "321211",
    "label": "Lombang",
    "value": "Lombang"
  },
  {
    "id": "3212112012",
    "district_id": "321211",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3212122001",
    "district_id": "321212",
    "label": "Sleman",
    "value": "Sleman"
  },
  {
    "id": "3212122002",
    "district_id": "321212",
    "label": "Tambi",
    "value": "Tambi"
  },
  {
    "id": "3212122003",
    "district_id": "321212",
    "label": "Sudikampiran",
    "value": "Sudikampiran"
  },
  {
    "id": "3212122004",
    "district_id": "321212",
    "label": "Tambi Lor",
    "value": "Tambi Lor"
  },
  {
    "id": "3212122005",
    "district_id": "321212",
    "label": "Sleman Lor",
    "value": "Sleman Lor"
  },
  {
    "id": "3212122006",
    "district_id": "321212",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3212122007",
    "district_id": "321212",
    "label": "Majasih",
    "value": "Majasih"
  },
  {
    "id": "3212122008",
    "district_id": "321212",
    "label": "Sliyeg",
    "value": "Sliyeg"
  },
  {
    "id": "3212122009",
    "district_id": "321212",
    "label": "Gadingan",
    "value": "Gadingan"
  },
  {
    "id": "3212122010",
    "district_id": "321212",
    "label": "Mekargading",
    "value": "Mekargading"
  },
  {
    "id": "3212122011",
    "district_id": "321212",
    "label": "Sliyeg Lor",
    "value": "Sliyeg Lor"
  },
  {
    "id": "3212122012",
    "district_id": "321212",
    "label": "Tugu Kidul",
    "value": "Tugu Kidul"
  },
  {
    "id": "3212122013",
    "district_id": "321212",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3212122016",
    "district_id": "321212",
    "label": "Longok",
    "value": "Longok"
  },
  {
    "id": "3212132001",
    "district_id": "321213",
    "label": "Sukalila",
    "value": "Sukalila"
  },
  {
    "id": "3212132002",
    "district_id": "321213",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3212132003",
    "district_id": "321213",
    "label": "Jatibarang Baru",
    "value": "Jatibarang Baru"
  },
  {
    "id": "3212132004",
    "district_id": "321213",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3212132005",
    "district_id": "321213",
    "label": "Bulak Lor",
    "value": "Bulak Lor"
  },
  {
    "id": "3212132006",
    "district_id": "321213",
    "label": "Jatibarang",
    "value": "Jatibarang"
  },
  {
    "id": "3212132007",
    "district_id": "321213",
    "label": "Kebulen",
    "value": "Kebulen"
  },
  {
    "id": "3212132008",
    "district_id": "321213",
    "label": "Pawidean",
    "value": "Pawidean"
  },
  {
    "id": "3212132009",
    "district_id": "321213",
    "label": "Jatisawit",
    "value": "Jatisawit"
  },
  {
    "id": "3212132010",
    "district_id": "321213",
    "label": "Jatisawit Lor",
    "value": "Jatisawit Lor"
  },
  {
    "id": "3212132011",
    "district_id": "321213",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3212132012",
    "district_id": "321213",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3212132013",
    "district_id": "321213",
    "label": "Malangsemirang",
    "value": "Malangsemirang"
  },
  {
    "id": "3212132014",
    "district_id": "321213",
    "label": "Lobener",
    "value": "Lobener"
  },
  {
    "id": "3212132015",
    "district_id": "321213",
    "label": "Lobener Lor",
    "value": "Lobener Lor"
  },
  {
    "id": "3212142001",
    "district_id": "321214",
    "label": "Tegal Sembadra",
    "value": "Tegal Sembadra"
  },
  {
    "id": "3212142002",
    "district_id": "321214",
    "label": "Sukareja",
    "value": "Sukareja"
  },
  {
    "id": "3212142003",
    "district_id": "321214",
    "label": "Sukaurip",
    "value": "Sukaurip"
  },
  {
    "id": "3212142004",
    "district_id": "321214",
    "label": "Rawadalem",
    "value": "Rawadalem"
  },
  {
    "id": "3212142005",
    "district_id": "321214",
    "label": "Gelarmendala",
    "value": "Gelarmendala"
  },
  {
    "id": "3212142006",
    "district_id": "321214",
    "label": "Tegalurung",
    "value": "Tegalurung"
  },
  {
    "id": "3212142007",
    "district_id": "321214",
    "label": "Balongan",
    "value": "Balongan"
  },
  {
    "id": "3212142008",
    "district_id": "321214",
    "label": "Majakerta",
    "value": "Majakerta"
  },
  {
    "id": "3212142009",
    "district_id": "321214",
    "label": "Sudimampir",
    "value": "Sudimampir"
  },
  {
    "id": "3212142010",
    "district_id": "321214",
    "label": "Sudimampir Lor",
    "value": "Sudimampir Lor"
  },
  {
    "id": "3212151001",
    "district_id": "321215",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3212151002",
    "district_id": "321215",
    "label": "Kepandean",
    "value": "Kepandean"
  },
  {
    "id": "3212151003",
    "district_id": "321215",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3212151004",
    "district_id": "321215",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3212151005",
    "district_id": "321215",
    "label": "Lemahmekar",
    "value": "Lemahmekar"
  },
  {
    "id": "3212151006",
    "district_id": "321215",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "3212151007",
    "district_id": "321215",
    "label": "Margadadi",
    "value": "Margadadi"
  },
  {
    "id": "3212151008",
    "district_id": "321215",
    "label": "Paoman",
    "value": "Paoman"
  },
  {
    "id": "3212152009",
    "district_id": "321215",
    "label": "Telukagung",
    "value": "Telukagung"
  },
  {
    "id": "3212152010",
    "district_id": "321215",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3212152011",
    "district_id": "321215",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3212152012",
    "district_id": "321215",
    "label": "Pekandangan Jaya",
    "value": "Pekandangan Jaya"
  },
  {
    "id": "3212152013",
    "district_id": "321215",
    "label": "Singaraja",
    "value": "Singaraja"
  },
  {
    "id": "3212152014",
    "district_id": "321215",
    "label": "Singajaya",
    "value": "Singajaya"
  },
  {
    "id": "3212152015",
    "district_id": "321215",
    "label": "Pekandangan",
    "value": "Pekandangan"
  },
  {
    "id": "3212152016",
    "district_id": "321215",
    "label": "Karangsong",
    "value": "Karangsong"
  },
  {
    "id": "3212152017",
    "district_id": "321215",
    "label": "Pabeanudik",
    "value": "Pabeanudik"
  },
  {
    "id": "3212152020",
    "district_id": "321215",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3212162001",
    "district_id": "321216",
    "label": "Panyindangan Kulon",
    "value": "Panyindangan Kulon"
  },
  {
    "id": "3212162002",
    "district_id": "321216",
    "label": "Rambatan Wetan",
    "value": "Rambatan Wetan"
  },
  {
    "id": "3212162003",
    "district_id": "321216",
    "label": "Panyindangan Wetan",
    "value": "Panyindangan Wetan"
  },
  {
    "id": "3212162004",
    "district_id": "321216",
    "label": "Kenanga",
    "value": "Kenanga"
  },
  {
    "id": "3212162005",
    "district_id": "321216",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "3212162006",
    "district_id": "321216",
    "label": "Dermayu",
    "value": "Dermayu"
  },
  {
    "id": "3212162007",
    "district_id": "321216",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "3212162008",
    "district_id": "321216",
    "label": "Penganjang",
    "value": "Penganjang"
  },
  {
    "id": "3212162009",
    "district_id": "321216",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3212162014",
    "district_id": "321216",
    "label": "Wanantara",
    "value": "Wanantara"
  },
  {
    "id": "3212172001",
    "district_id": "321217",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3212172002",
    "district_id": "321217",
    "label": "Cantigi Kulon",
    "value": "Cantigi Kulon"
  },
  {
    "id": "3212172003",
    "district_id": "321217",
    "label": "Cantigi Wetan",
    "value": "Cantigi Wetan"
  },
  {
    "id": "3212172004",
    "district_id": "321217",
    "label": "Panyingkiran Kidul",
    "value": "Panyingkiran Kidul"
  },
  {
    "id": "3212172005",
    "district_id": "321217",
    "label": "Panyingkiran Lor",
    "value": "Panyingkiran Lor"
  },
  {
    "id": "3212172006",
    "district_id": "321217",
    "label": "Lamarantarung",
    "value": "Lamarantarung"
  },
  {
    "id": "3212172007",
    "district_id": "321217",
    "label": "Cemara",
    "value": "Cemara"
  },
  {
    "id": "3212182001",
    "district_id": "321218",
    "label": "Kiajaran Wetan",
    "value": "Kiajaran Wetan"
  },
  {
    "id": "3212182002",
    "district_id": "321218",
    "label": "Kiajaran Kulon",
    "value": "Kiajaran Kulon"
  },
  {
    "id": "3212182003",
    "district_id": "321218",
    "label": "Lanjan",
    "value": "Lanjan"
  },
  {
    "id": "3212182004",
    "district_id": "321218",
    "label": "Langut",
    "value": "Langut"
  },
  {
    "id": "3212182005",
    "district_id": "321218",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3212182006",
    "district_id": "321218",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3212182007",
    "district_id": "321218",
    "label": "Legok",
    "value": "Legok"
  },
  {
    "id": "3212182008",
    "district_id": "321218",
    "label": "Bojongslawi",
    "value": "Bojongslawi"
  },
  {
    "id": "3212182009",
    "district_id": "321218",
    "label": "Lohbener",
    "value": "Lohbener"
  },
  {
    "id": "3212182010",
    "district_id": "321218",
    "label": "Pamayahan",
    "value": "Pamayahan"
  },
  {
    "id": "3212182011",
    "district_id": "321218",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "3212182012",
    "district_id": "321218",
    "label": "Rambatan Kulon",
    "value": "Rambatan Kulon"
  },
  {
    "id": "3212192001",
    "district_id": "321219",
    "label": "Arahan Kidul",
    "value": "Arahan Kidul"
  },
  {
    "id": "3212192002",
    "district_id": "321219",
    "label": "Arahan Lor",
    "value": "Arahan Lor"
  },
  {
    "id": "3212192003",
    "district_id": "321219",
    "label": "Linggajati",
    "value": "Linggajati"
  },
  {
    "id": "3212192004",
    "district_id": "321219",
    "label": "Cidempet",
    "value": "Cidempet"
  },
  {
    "id": "3212192005",
    "district_id": "321219",
    "label": "Sukadadi",
    "value": "Sukadadi"
  },
  {
    "id": "3212192006",
    "district_id": "321219",
    "label": "Pranggong",
    "value": "Pranggong"
  },
  {
    "id": "3212192007",
    "district_id": "321219",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3212192008",
    "district_id": "321219",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3212202001",
    "district_id": "321220",
    "label": "Ranjeng",
    "value": "Ranjeng"
  },
  {
    "id": "3212202002",
    "district_id": "321220",
    "label": "Krimun",
    "value": "Krimun"
  },
  {
    "id": "3212202003",
    "district_id": "321220",
    "label": "Puntang",
    "value": "Puntang"
  },
  {
    "id": "3212202004",
    "district_id": "321220",
    "label": "Pegagan",
    "value": "Pegagan"
  },
  {
    "id": "3212202005",
    "district_id": "321220",
    "label": "Rajaiyang",
    "value": "Rajaiyang"
  },
  {
    "id": "3212202006",
    "district_id": "321220",
    "label": "Jangga",
    "value": "Jangga"
  },
  {
    "id": "3212202007",
    "district_id": "321220",
    "label": "Jumbleng",
    "value": "Jumbleng"
  },
  {
    "id": "3212202008",
    "district_id": "321220",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3212202010",
    "district_id": "321220",
    "label": "Losarang",
    "value": "Losarang"
  },
  {
    "id": "3212202011",
    "district_id": "321220",
    "label": "Muntur",
    "value": "Muntur"
  },
  {
    "id": "3212202012",
    "district_id": "321220",
    "label": "Santing",
    "value": "Santing"
  },
  {
    "id": "3212202013",
    "district_id": "321220",
    "label": "Cemara Kulon",
    "value": "Cemara Kulon"
  },
  {
    "id": "3212212001",
    "district_id": "321221",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3212212002",
    "district_id": "321221",
    "label": "Pranti",
    "value": "Pranti"
  },
  {
    "id": "3212212003",
    "district_id": "321221",
    "label": "Wirakanan",
    "value": "Wirakanan"
  },
  {
    "id": "3212212004",
    "district_id": "321221",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3212212005",
    "district_id": "321221",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3212212006",
    "district_id": "321221",
    "label": "Wirapanjunan",
    "value": "Wirapanjunan"
  },
  {
    "id": "3212212007",
    "district_id": "321221",
    "label": "Pareangirang",
    "value": "Pareangirang"
  },
  {
    "id": "3212212008",
    "district_id": "321221",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3212212009",
    "district_id": "321221",
    "label": "Ilir",
    "value": "Ilir"
  },
  {
    "id": "3212212010",
    "district_id": "321221",
    "label": "Soge",
    "value": "Soge"
  },
  {
    "id": "3212212011",
    "district_id": "321221",
    "label": "Eretan Wetan",
    "value": "Eretan Wetan"
  },
  {
    "id": "3212212012",
    "district_id": "321221",
    "label": "Eretan Kulon",
    "value": "Eretan Kulon"
  },
  {
    "id": "3212212013",
    "district_id": "321221",
    "label": "Kertawinangun",
    "value": "Kertawinangun"
  },
  {
    "id": "3212222001",
    "district_id": "321222",
    "label": "Cipedang",
    "value": "Cipedang"
  },
  {
    "id": "3212222002",
    "district_id": "321222",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3212222003",
    "district_id": "321222",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3212222004",
    "district_id": "321222",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3212222005",
    "district_id": "321222",
    "label": "Bongas",
    "value": "Bongas"
  },
  {
    "id": "3212222006",
    "district_id": "321222",
    "label": "Cipaat",
    "value": "Cipaat"
  },
  {
    "id": "3212222007",
    "district_id": "321222",
    "label": "Kertamulya",
    "value": "Kertamulya"
  },
  {
    "id": "3212222008",
    "district_id": "321222",
    "label": "Plawangan",
    "value": "Plawangan"
  },
  {
    "id": "3212232001",
    "district_id": "321223",
    "label": "Anjatan",
    "value": "Anjatan"
  },
  {
    "id": "3212232002",
    "district_id": "321223",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "3212232003",
    "district_id": "321223",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "3212232004",
    "district_id": "321223",
    "label": "Bugistua",
    "value": "Bugistua"
  },
  {
    "id": "3212232005",
    "district_id": "321223",
    "label": "Salamdarma",
    "value": "Salamdarma"
  },
  {
    "id": "3212232006",
    "district_id": "321223",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3212232007",
    "district_id": "321223",
    "label": "Wanguk",
    "value": "Wanguk"
  },
  {
    "id": "3212232008",
    "district_id": "321223",
    "label": "Lempuyang",
    "value": "Lempuyang"
  },
  {
    "id": "3212232009",
    "district_id": "321223",
    "label": "Kopyah",
    "value": "Kopyah"
  },
  {
    "id": "3212232010",
    "district_id": "321223",
    "label": "Anjatan Baru",
    "value": "Anjatan Baru"
  },
  {
    "id": "3212232011",
    "district_id": "321223",
    "label": "Cilandak",
    "value": "Cilandak"
  },
  {
    "id": "3212232012",
    "district_id": "321223",
    "label": "Cilandak Lor",
    "value": "Cilandak Lor"
  },
  {
    "id": "3212232013",
    "district_id": "321223",
    "label": "Anjatan Utara",
    "value": "Anjatan Utara"
  },
  {
    "id": "3212242001",
    "district_id": "321224",
    "label": "Bogor",
    "value": "Bogor"
  },
  {
    "id": "3212242002",
    "district_id": "321224",
    "label": "Sukra",
    "value": "Sukra"
  },
  {
    "id": "3212242003",
    "district_id": "321224",
    "label": "Ujunggebang",
    "value": "Ujunggebang"
  },
  {
    "id": "3212242004",
    "district_id": "321224",
    "label": "Tagaltaman",
    "value": "Tagaltaman"
  },
  {
    "id": "3212242005",
    "district_id": "321224",
    "label": "Sukra Wetan",
    "value": "Sukra Wetan"
  },
  {
    "id": "3212242006",
    "district_id": "321224",
    "label": "Sumuradem",
    "value": "Sumuradem"
  },
  {
    "id": "3212242007",
    "district_id": "321224",
    "label": "Sumuradem Timur",
    "value": "Sumuradem Timur"
  },
  {
    "id": "3212242017",
    "district_id": "321224",
    "label": "Karanglayung",
    "value": "Karanglayung"
  },
  {
    "id": "3212252001",
    "district_id": "321225",
    "label": "Bantarwaru",
    "value": "Bantarwaru"
  },
  {
    "id": "3212252002",
    "district_id": "321225",
    "label": "Sanca",
    "value": "Sanca"
  },
  {
    "id": "3212252003",
    "district_id": "321225",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3212252004",
    "district_id": "321225",
    "label": "Gantar",
    "value": "Gantar"
  },
  {
    "id": "3212252005",
    "district_id": "321225",
    "label": "Situraja",
    "value": "Situraja"
  },
  {
    "id": "3212252006",
    "district_id": "321225",
    "label": "Balareja",
    "value": "Balareja"
  },
  {
    "id": "3212252007",
    "district_id": "321225",
    "label": "Mekarwaru",
    "value": "Mekarwaru"
  },
  {
    "id": "3212262001",
    "district_id": "321226",
    "label": "Cikawung",
    "value": "Cikawung"
  },
  {
    "id": "3212262002",
    "district_id": "321226",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3212262003",
    "district_id": "321226",
    "label": "Jatimunggul",
    "value": "Jatimunggul"
  },
  {
    "id": "3212262004",
    "district_id": "321226",
    "label": "Plosokerep",
    "value": "Plosokerep"
  },
  {
    "id": "3212262005",
    "district_id": "321226",
    "label": "Rajasinga",
    "value": "Rajasinga"
  },
  {
    "id": "3212262006",
    "district_id": "321226",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3212262007",
    "district_id": "321226",
    "label": "Cibereng",
    "value": "Cibereng"
  },
  {
    "id": "3212262008",
    "district_id": "321226",
    "label": "Kendayakan",
    "value": "Kendayakan"
  },
  {
    "id": "3212262009",
    "district_id": "321226",
    "label": "Manggungan",
    "value": "Manggungan"
  },
  {
    "id": "3212272001",
    "district_id": "321227",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3212272002",
    "district_id": "321227",
    "label": "Bondan",
    "value": "Bondan"
  },
  {
    "id": "3212272003",
    "district_id": "321227",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3212272004",
    "district_id": "321227",
    "label": "Sukagumiwang",
    "value": "Sukagumiwang"
  },
  {
    "id": "3212272005",
    "district_id": "321227",
    "label": "Tersana",
    "value": "Tersana"
  },
  {
    "id": "3212272006",
    "district_id": "321227",
    "label": "Cadang Pinggan",
    "value": "Cadang Pinggan"
  },
  {
    "id": "3212272007",
    "district_id": "321227",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3212282001",
    "district_id": "321228",
    "label": "Cangkingan",
    "value": "Cangkingan"
  },
  {
    "id": "3212282002",
    "district_id": "321228",
    "label": "Jayawinangun",
    "value": "Jayawinangun"
  },
  {
    "id": "3212282003",
    "district_id": "321228",
    "label": "Kedokan Agung",
    "value": "Kedokan Agung"
  },
  {
    "id": "3212282004",
    "district_id": "321228",
    "label": "Kedokanbunder",
    "value": "Kedokanbunder"
  },
  {
    "id": "3212282005",
    "district_id": "321228",
    "label": "Kedokanbunder Wetan",
    "value": "Kedokanbunder Wetan"
  },
  {
    "id": "3212282006",
    "district_id": "321228",
    "label": "Kaplongan",
    "value": "Kaplongan"
  },
  {
    "id": "3212282007",
    "district_id": "321228",
    "label": "Jayalaksana",
    "value": "Jayalaksana"
  },
  {
    "id": "3212292001",
    "district_id": "321229",
    "label": "Brondong",
    "value": "Brondong"
  },
  {
    "id": "3212292002",
    "district_id": "321229",
    "label": "Pabeanilir",
    "value": "Pabeanilir"
  },
  {
    "id": "3212292003",
    "district_id": "321229",
    "label": "Pagirikan",
    "value": "Pagirikan"
  },
  {
    "id": "3212292004",
    "district_id": "321229",
    "label": "Pasekan",
    "value": "Pasekan"
  },
  {
    "id": "3212292005",
    "district_id": "321229",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3212292006",
    "district_id": "321229",
    "label": "Totoran",
    "value": "Totoran"
  },
  {
    "id": "3212302001",
    "district_id": "321230",
    "label": "Bodas",
    "value": "Bodas"
  },
  {
    "id": "3212302002",
    "district_id": "321230",
    "label": "Gadel",
    "value": "Gadel"
  },
  {
    "id": "3212302003",
    "district_id": "321230",
    "label": "Rancajawat",
    "value": "Rancajawat"
  },
  {
    "id": "3212302004",
    "district_id": "321230",
    "label": "Kerticala",
    "value": "Kerticala"
  },
  {
    "id": "3212302005",
    "district_id": "321230",
    "label": "Cangko",
    "value": "Cangko"
  },
  {
    "id": "3212302006",
    "district_id": "321230",
    "label": "Karangkerta",
    "value": "Karangkerta"
  },
  {
    "id": "3212302007",
    "district_id": "321230",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3212302008",
    "district_id": "321230",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3212302009",
    "district_id": "321230",
    "label": "Lajer",
    "value": "Lajer"
  },
  {
    "id": "3212302010",
    "district_id": "321230",
    "label": "Tukdana",
    "value": "Tukdana"
  },
  {
    "id": "3212302011",
    "district_id": "321230",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3212302012",
    "district_id": "321230",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "3212302013",
    "district_id": "321230",
    "label": "Sukaperna",
    "value": "Sukaperna"
  },
  {
    "id": "3212312001",
    "district_id": "321231",
    "label": "Limpas",
    "value": "Limpas"
  },
  {
    "id": "3212312002",
    "district_id": "321231",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3212312003",
    "district_id": "321231",
    "label": "Patrol Baru",
    "value": "Patrol Baru"
  },
  {
    "id": "3212312004",
    "district_id": "321231",
    "label": "Patrol Lor",
    "value": "Patrol Lor"
  },
  {
    "id": "3212312005",
    "district_id": "321231",
    "label": "Patrol",
    "value": "Patrol"
  },
  {
    "id": "3212312006",
    "district_id": "321231",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3212312007",
    "district_id": "321231",
    "label": "Arjasari",
    "value": "Arjasari"
  },
  {
    "id": "3212312008",
    "district_id": "321231",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3213012003",
    "district_id": "321301",
    "label": "Sukamandi",
    "value": "Sukamandi"
  },
  {
    "id": "3213012004",
    "district_id": "321301",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3213012005",
    "district_id": "321301",
    "label": "Sagalaherang Kidul",
    "value": "Sagalaherang Kidul"
  },
  {
    "id": "3213012006",
    "district_id": "321301",
    "label": "Dayeuh Kolot",
    "value": "Dayeuh Kolot"
  },
  {
    "id": "3213012009",
    "district_id": "321301",
    "label": "Leles",
    "value": "Leles"
  },
  {
    "id": "3213012010",
    "district_id": "321301",
    "label": "Curugagung",
    "value": "Curugagung"
  },
  {
    "id": "3213012011",
    "district_id": "321301",
    "label": "Sagalaherang Kaler",
    "value": "Sagalaherang Kaler"
  },
  {
    "id": "3213022001",
    "district_id": "321302",
    "label": "Cupunagara",
    "value": "Cupunagara"
  },
  {
    "id": "3213022002",
    "district_id": "321302",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3213022003",
    "district_id": "321302",
    "label": "Gardusayang",
    "value": "Gardusayang"
  },
  {
    "id": "3213022004",
    "district_id": "321302",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "3213022005",
    "district_id": "321302",
    "label": "Sukakerti",
    "value": "Sukakerti"
  },
  {
    "id": "3213022007",
    "district_id": "321302",
    "label": "Darmaga",
    "value": "Darmaga"
  },
  {
    "id": "3213022008",
    "district_id": "321302",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3213022011",
    "district_id": "321302",
    "label": "Cigadog",
    "value": "Cigadog"
  },
  {
    "id": "3213022014",
    "district_id": "321302",
    "label": "Pakuhaji",
    "value": "Pakuhaji"
  },
  {
    "id": "3213031001",
    "district_id": "321303",
    "label": "Parung",
    "value": "Parung"
  },
  {
    "id": "3213031002",
    "district_id": "321303",
    "label": "Cigadung",
    "value": "Cigadung"
  },
  {
    "id": "3213031003",
    "district_id": "321303",
    "label": "Pasirkareumbi",
    "value": "Pasirkareumbi"
  },
  {
    "id": "3213031004",
    "district_id": "321303",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3213031005",
    "district_id": "321303",
    "label": "Soklat",
    "value": "Soklat"
  },
  {
    "id": "3213031006",
    "district_id": "321303",
    "label": "Sukamelang",
    "value": "Sukamelang"
  },
  {
    "id": "3213031007",
    "district_id": "321303",
    "label": "Dangdeur",
    "value": "Dangdeur"
  },
  {
    "id": "3213031008",
    "district_id": "321303",
    "label": "Wanareja",
    "value": "Wanareja"
  },
  {
    "id": "3213042006",
    "district_id": "321304",
    "label": "Marengmang",
    "value": "Marengmang"
  },
  {
    "id": "3213042007",
    "district_id": "321304",
    "label": "Kalijati Timur",
    "value": "Kalijati Timur"
  },
  {
    "id": "3213042008",
    "district_id": "321304",
    "label": "Kaliangsana",
    "value": "Kaliangsana"
  },
  {
    "id": "3213042011",
    "district_id": "321304",
    "label": "Kalijati Barat",
    "value": "Kalijati Barat"
  },
  {
    "id": "3213042012",
    "district_id": "321304",
    "label": "Tanggulun Timur",
    "value": "Tanggulun Timur"
  },
  {
    "id": "3213042013",
    "district_id": "321304",
    "label": "Tanggulun Barat",
    "value": "Tanggulun Barat"
  },
  {
    "id": "3213042017",
    "district_id": "321304",
    "label": "Caracas",
    "value": "Caracas"
  },
  {
    "id": "3213042018",
    "district_id": "321304",
    "label": "Ciruluk",
    "value": "Ciruluk"
  },
  {
    "id": "3213042019",
    "district_id": "321304",
    "label": "Jalupang",
    "value": "Jalupang"
  },
  {
    "id": "3213042020",
    "district_id": "321304",
    "label": "Banggalamulya",
    "value": "Banggalamulya"
  },
  {
    "id": "3213052001",
    "district_id": "321305",
    "label": "Cihambulu",
    "value": "Cihambulu"
  },
  {
    "id": "3213052002",
    "district_id": "321305",
    "label": "Siluman",
    "value": "Siluman"
  },
  {
    "id": "3213052003",
    "district_id": "321305",
    "label": "Pringkasap",
    "value": "Pringkasap"
  },
  {
    "id": "3213052004",
    "district_id": "321305",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3213052005",
    "district_id": "321305",
    "label": "Karanghegar",
    "value": "Karanghegar"
  },
  {
    "id": "3213052006",
    "district_id": "321305",
    "label": "Salamjaya",
    "value": "Salamjaya"
  },
  {
    "id": "3213052007",
    "district_id": "321305",
    "label": "Kadawung",
    "value": "Kadawung"
  },
  {
    "id": "3213052008",
    "district_id": "321305",
    "label": "Balebandung Jaya",
    "value": "Balebandung Jaya"
  },
  {
    "id": "3213062001",
    "district_id": "321306",
    "label": "Wanakerta",
    "value": "Wanakerta"
  },
  {
    "id": "3213062002",
    "district_id": "321306",
    "label": "Pagon",
    "value": "Pagon"
  },
  {
    "id": "3213062003",
    "district_id": "321306",
    "label": "Prapatan",
    "value": "Prapatan"
  },
  {
    "id": "3213062004",
    "district_id": "321306",
    "label": "Purwadadi",
    "value": "Purwadadi"
  },
  {
    "id": "3213062005",
    "district_id": "321306",
    "label": "Belendung",
    "value": "Belendung"
  },
  {
    "id": "3213062006",
    "district_id": "321306",
    "label": "Koranji",
    "value": "Koranji"
  },
  {
    "id": "3213062007",
    "district_id": "321306",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "3213062008",
    "district_id": "321306",
    "label": "Pasirbungur",
    "value": "Pasirbungur"
  },
  {
    "id": "3213062009",
    "district_id": "321306",
    "label": "Purwadadi Barat",
    "value": "Purwadadi Barat"
  },
  {
    "id": "3213062010",
    "district_id": "321306",
    "label": "Rancamahi",
    "value": "Rancamahi"
  },
  {
    "id": "3213072004",
    "district_id": "321307",
    "label": "Gunungsembung",
    "value": "Gunungsembung"
  },
  {
    "id": "3213072005",
    "district_id": "321307",
    "label": "Gembor",
    "value": "Gembor"
  },
  {
    "id": "3213072006",
    "district_id": "321307",
    "label": "Kamarung",
    "value": "Kamarung"
  },
  {
    "id": "3213072010",
    "district_id": "321307",
    "label": "Gambarsari",
    "value": "Gambarsari"
  },
  {
    "id": "3213072011",
    "district_id": "321307",
    "label": "Pagaden",
    "value": "Pagaden"
  },
  {
    "id": "3213072012",
    "district_id": "321307",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3213072013",
    "district_id": "321307",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3213072014",
    "district_id": "321307",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3213072015",
    "district_id": "321307",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3213072019",
    "district_id": "321307",
    "label": "Jabong",
    "value": "Jabong"
  },
  {
    "id": "3213082001",
    "district_id": "321308",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3213082002",
    "district_id": "321308",
    "label": "Kihiyang",
    "value": "Kihiyang"
  },
  {
    "id": "3213082003",
    "district_id": "321308",
    "label": "Binong",
    "value": "Binong"
  },
  {
    "id": "3213082004",
    "district_id": "321308",
    "label": "Kediri",
    "value": "Kediri"
  },
  {
    "id": "3213082010",
    "district_id": "321308",
    "label": "Citrajaya",
    "value": "Citrajaya"
  },
  {
    "id": "3213082011",
    "district_id": "321308",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3213082014",
    "district_id": "321308",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3213082016",
    "district_id": "321308",
    "label": "Karangwangi",
    "value": "Karangwangi"
  },
  {
    "id": "3213082018",
    "district_id": "321308",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3213092001",
    "district_id": "321309",
    "label": "Ciasem Girang",
    "value": "Ciasem Girang"
  },
  {
    "id": "3213092002",
    "district_id": "321309",
    "label": "Ciasem Tengah",
    "value": "Ciasem Tengah"
  },
  {
    "id": "3213092003",
    "district_id": "321309",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3213092004",
    "district_id": "321309",
    "label": "Ciasem Hilir",
    "value": "Ciasem Hilir"
  },
  {
    "id": "3213092005",
    "district_id": "321309",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3213092006",
    "district_id": "321309",
    "label": "Sukamandijaya",
    "value": "Sukamandijaya"
  },
  {
    "id": "3213092007",
    "district_id": "321309",
    "label": "Ciasem Baru",
    "value": "Ciasem Baru"
  },
  {
    "id": "3213092008",
    "district_id": "321309",
    "label": "Pinangsari",
    "value": "Pinangsari"
  },
  {
    "id": "3213092009",
    "district_id": "321309",
    "label": "Jatibaru",
    "value": "Jatibaru"
  },
  {
    "id": "3213102005",
    "district_id": "321310",
    "label": "Pusakaratu",
    "value": "Pusakaratu"
  },
  {
    "id": "3213102006",
    "district_id": "321310",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3213102007",
    "district_id": "321310",
    "label": "Kalentambo",
    "value": "Kalentambo"
  },
  {
    "id": "3213102008",
    "district_id": "321310",
    "label": "Patimban",
    "value": "Patimban"
  },
  {
    "id": "3213102012",
    "district_id": "321310",
    "label": "Rancadaka",
    "value": "Rancadaka"
  },
  {
    "id": "3213102014",
    "district_id": "321310",
    "label": "Kotasari",
    "value": "Kotasari"
  },
  {
    "id": "3213102015",
    "district_id": "321310",
    "label": "Mundusari",
    "value": "Mundusari"
  },
  {
    "id": "3213112002",
    "district_id": "321311",
    "label": "Rancasari",
    "value": "Rancasari"
  },
  {
    "id": "3213112003",
    "district_id": "321311",
    "label": "Rancahilir",
    "value": "Rancahilir"
  },
  {
    "id": "3213112004",
    "district_id": "321311",
    "label": "Pamanukan",
    "value": "Pamanukan"
  },
  {
    "id": "3213112006",
    "district_id": "321311",
    "label": "Pamanukan Hilir",
    "value": "Pamanukan Hilir"
  },
  {
    "id": "3213112007",
    "district_id": "321311",
    "label": "Pamanukan Sebrang",
    "value": "Pamanukan Sebrang"
  },
  {
    "id": "3213112009",
    "district_id": "321311",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3213112010",
    "district_id": "321311",
    "label": "Lengkongjaya",
    "value": "Lengkongjaya"
  },
  {
    "id": "3213112011",
    "district_id": "321311",
    "label": "Bongas",
    "value": "Bongas"
  },
  {
    "id": "3213122007",
    "district_id": "321312",
    "label": "Curugrendeng",
    "value": "Curugrendeng"
  },
  {
    "id": "3213122008",
    "district_id": "321312",
    "label": "Tambakan",
    "value": "Tambakan"
  },
  {
    "id": "3213122009",
    "district_id": "321312",
    "label": "Bunihayu",
    "value": "Bunihayu"
  },
  {
    "id": "3213122011",
    "district_id": "321312",
    "label": "Kumpay",
    "value": "Kumpay"
  },
  {
    "id": "3213122012",
    "district_id": "321312",
    "label": "Sarireja",
    "value": "Sarireja"
  },
  {
    "id": "3213122014",
    "district_id": "321312",
    "label": "Tambakmekar",
    "value": "Tambakmekar"
  },
  {
    "id": "3213122017",
    "district_id": "321312",
    "label": "Jalancagak",
    "value": "Jalancagak"
  },
  {
    "id": "3213132001",
    "district_id": "321313",
    "label": "Tanjungtiga",
    "value": "Tanjungtiga"
  },
  {
    "id": "3213132002",
    "district_id": "321313",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "3213132003",
    "district_id": "321313",
    "label": "Blanakan",
    "value": "Blanakan"
  },
  {
    "id": "3213132004",
    "district_id": "321313",
    "label": "Rawameneng",
    "value": "Rawameneng"
  },
  {
    "id": "3213132005",
    "district_id": "321313",
    "label": "Cilamayagirang",
    "value": "Cilamayagirang"
  },
  {
    "id": "3213132006",
    "district_id": "321313",
    "label": "Jayamukti",
    "value": "Jayamukti"
  },
  {
    "id": "3213132007",
    "district_id": "321313",
    "label": "Cilamayahilir",
    "value": "Cilamayahilir"
  },
  {
    "id": "3213132008",
    "district_id": "321313",
    "label": "Rawamekar",
    "value": "Rawamekar"
  },
  {
    "id": "3213132009",
    "district_id": "321313",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3213142001",
    "district_id": "321314",
    "label": "Buniara",
    "value": "Buniara"
  },
  {
    "id": "3213142002",
    "district_id": "321314",
    "label": "Tanjungsiang",
    "value": "Tanjungsiang"
  },
  {
    "id": "3213142003",
    "district_id": "321314",
    "label": "Cimeuhmal",
    "value": "Cimeuhmal"
  },
  {
    "id": "3213142004",
    "district_id": "321314",
    "label": "Sirap",
    "value": "Sirap"
  },
  {
    "id": "3213142005",
    "district_id": "321314",
    "label": "Kawungluwuk",
    "value": "Kawungluwuk"
  },
  {
    "id": "3213142006",
    "district_id": "321314",
    "label": "Cibuluh",
    "value": "Cibuluh"
  },
  {
    "id": "3213142007",
    "district_id": "321314",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3213142008",
    "district_id": "321314",
    "label": "Rancamanggung",
    "value": "Rancamanggung"
  },
  {
    "id": "3213142009",
    "district_id": "321314",
    "label": "Cikawung",
    "value": "Cikawung"
  },
  {
    "id": "3213142010",
    "district_id": "321314",
    "label": "Gandasoli",
    "value": "Gandasoli"
  },
  {
    "id": "3213152001",
    "district_id": "321315",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3213152002",
    "district_id": "321315",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3213152003",
    "district_id": "321315",
    "label": "Jatireja",
    "value": "Jatireja"
  },
  {
    "id": "3213152004",
    "district_id": "321315",
    "label": "Compreng",
    "value": "Compreng"
  },
  {
    "id": "3213152005",
    "district_id": "321315",
    "label": "Kalensari",
    "value": "Kalensari"
  },
  {
    "id": "3213152006",
    "district_id": "321315",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3213152007",
    "district_id": "321315",
    "label": "Kiarasari",
    "value": "Kiarasari"
  },
  {
    "id": "3213152008",
    "district_id": "321315",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3213162001",
    "district_id": "321316",
    "label": "Rancabango",
    "value": "Rancabango"
  },
  {
    "id": "3213162002",
    "district_id": "321316",
    "label": "Tanjungrasa",
    "value": "Tanjungrasa"
  },
  {
    "id": "3213162003",
    "district_id": "321316",
    "label": "Ciberes",
    "value": "Ciberes"
  },
  {
    "id": "3213162004",
    "district_id": "321316",
    "label": "Jatiragas Hilir",
    "value": "Jatiragas Hilir"
  },
  {
    "id": "3213162005",
    "district_id": "321316",
    "label": "Tambakjati",
    "value": "Tambakjati"
  },
  {
    "id": "3213162006",
    "district_id": "321316",
    "label": "Rancajaya",
    "value": "Rancajaya"
  },
  {
    "id": "3213162007",
    "district_id": "321316",
    "label": "Gempolsari",
    "value": "Gempolsari"
  },
  {
    "id": "3213162008",
    "district_id": "321316",
    "label": "Tanjungrasa Kidul",
    "value": "Tanjungrasa Kidul"
  },
  {
    "id": "3213162009",
    "district_id": "321316",
    "label": "Rancaasih",
    "value": "Rancaasih"
  },
  {
    "id": "3213162010",
    "district_id": "321316",
    "label": "Rancamulya",
    "value": "Rancamulya"
  },
  {
    "id": "3213172001",
    "district_id": "321317",
    "label": "Sumurbarang",
    "value": "Sumurbarang"
  },
  {
    "id": "3213172002",
    "district_id": "321317",
    "label": "Cibogo",
    "value": "Cibogo"
  },
  {
    "id": "3213172003",
    "district_id": "321317",
    "label": "Cinangsi",
    "value": "Cinangsi"
  },
  {
    "id": "3213172004",
    "district_id": "321317",
    "label": "Padaasih",
    "value": "Padaasih"
  },
  {
    "id": "3213172005",
    "district_id": "321317",
    "label": "Sadawarna",
    "value": "Sadawarna"
  },
  {
    "id": "3213172006",
    "district_id": "321317",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3213172007",
    "district_id": "321317",
    "label": "Cisaga",
    "value": "Cisaga"
  },
  {
    "id": "3213172008",
    "district_id": "321317",
    "label": "Belendung",
    "value": "Belendung"
  },
  {
    "id": "3213172009",
    "district_id": "321317",
    "label": "Cibalandong Jaya",
    "value": "Cibalandong Jaya"
  },
  {
    "id": "3213182001",
    "district_id": "321318",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3213182002",
    "district_id": "321318",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3213182003",
    "district_id": "321318",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3213182004",
    "district_id": "321318",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3213182005",
    "district_id": "321318",
    "label": "Simpar",
    "value": "Simpar"
  },
  {
    "id": "3213182006",
    "district_id": "321318",
    "label": "Parigimulya",
    "value": "Parigimulya"
  },
  {
    "id": "3213182007",
    "district_id": "321318",
    "label": "Sidajaya",
    "value": "Sidajaya"
  },
  {
    "id": "3213182008",
    "district_id": "321318",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3213182009",
    "district_id": "321318",
    "label": "Kosambi",
    "value": "Kosambi"
  },
  {
    "id": "3213182010",
    "district_id": "321318",
    "label": "Manyingsal",
    "value": "Manyingsal"
  },
  {
    "id": "3213192001",
    "district_id": "321319",
    "label": "Gunungtua",
    "value": "Gunungtua"
  },
  {
    "id": "3213192002",
    "district_id": "321319",
    "label": "Cijambe",
    "value": "Cijambe"
  },
  {
    "id": "3213192003",
    "district_id": "321319",
    "label": "Cirangkong",
    "value": "Cirangkong"
  },
  {
    "id": "3213192004",
    "district_id": "321319",
    "label": "Cimenteng",
    "value": "Cimenteng"
  },
  {
    "id": "3213192005",
    "district_id": "321319",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3213192006",
    "district_id": "321319",
    "label": "Tanjungwangi",
    "value": "Tanjungwangi"
  },
  {
    "id": "3213192007",
    "district_id": "321319",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3213192008",
    "district_id": "321319",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3213202001",
    "district_id": "321320",
    "label": "Karangmukti",
    "value": "Karangmukti"
  },
  {
    "id": "3213202002",
    "district_id": "321320",
    "label": "Cimayasari",
    "value": "Cimayasari"
  },
  {
    "id": "3213202003",
    "district_id": "321320",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3213202004",
    "district_id": "321320",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3213202005",
    "district_id": "321320",
    "label": "Wantilan",
    "value": "Wantilan"
  },
  {
    "id": "3213202006",
    "district_id": "321320",
    "label": "Kosar",
    "value": "Kosar"
  },
  {
    "id": "3213202007",
    "district_id": "321320",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3213212001",
    "district_id": "321321",
    "label": "Tegalurung",
    "value": "Tegalurung"
  },
  {
    "id": "3213212002",
    "district_id": "321321",
    "label": "Bobos",
    "value": "Bobos"
  },
  {
    "id": "3213212003",
    "district_id": "321321",
    "label": "Legonkulon",
    "value": "Legonkulon"
  },
  {
    "id": "3213212004",
    "district_id": "321321",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "3213212005",
    "district_id": "321321",
    "label": "Legonwetan",
    "value": "Legonwetan"
  },
  {
    "id": "3213212006",
    "district_id": "321321",
    "label": "Pangarengan",
    "value": "Pangarengan"
  },
  {
    "id": "3213212007",
    "district_id": "321321",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3213222001",
    "district_id": "321322",
    "label": "Kawunganten",
    "value": "Kawunganten"
  },
  {
    "id": "3213222002",
    "district_id": "321322",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3213222003",
    "district_id": "321322",
    "label": "Cikaum Barat",
    "value": "Cikaum Barat"
  },
  {
    "id": "3213222004",
    "district_id": "321322",
    "label": "Cikaum Timur",
    "value": "Cikaum Timur"
  },
  {
    "id": "3213222005",
    "district_id": "321322",
    "label": "Tanjungsari Barat",
    "value": "Tanjungsari Barat"
  },
  {
    "id": "3213222006",
    "district_id": "321322",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3213222007",
    "district_id": "321322",
    "label": "Tanjungsari Timur",
    "value": "Tanjungsari Timur"
  },
  {
    "id": "3213222008",
    "district_id": "321322",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "3213222009",
    "district_id": "321322",
    "label": "Pasirmuncang",
    "value": "Pasirmuncang"
  },
  {
    "id": "3213232001",
    "district_id": "321323",
    "label": "Cijengkol",
    "value": "Cijengkol"
  },
  {
    "id": "3213232002",
    "district_id": "321323",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3213232003",
    "district_id": "321323",
    "label": "Cintamekar",
    "value": "Cintamekar"
  },
  {
    "id": "3213232004",
    "district_id": "321323",
    "label": "Ponggang",
    "value": "Ponggang"
  },
  {
    "id": "3213232005",
    "district_id": "321323",
    "label": "Cikujang",
    "value": "Cikujang"
  },
  {
    "id": "3213232006",
    "district_id": "321323",
    "label": "Cipancar",
    "value": "Cipancar"
  },
  {
    "id": "3213242001",
    "district_id": "321324",
    "label": "Anggasari",
    "value": "Anggasari"
  },
  {
    "id": "3213242002",
    "district_id": "321324",
    "label": "Batangsari",
    "value": "Batangsari"
  },
  {
    "id": "3213242003",
    "district_id": "321324",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3213242004",
    "district_id": "321324",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "3213242005",
    "district_id": "321324",
    "label": "Curugreja",
    "value": "Curugreja"
  },
  {
    "id": "3213242006",
    "district_id": "321324",
    "label": "Sukareja",
    "value": "Sukareja"
  },
  {
    "id": "3213242007",
    "district_id": "321324",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3213252001",
    "district_id": "321325",
    "label": "Tanjungrasa",
    "value": "Tanjungrasa"
  },
  {
    "id": "3213252002",
    "district_id": "321325",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3213252003",
    "district_id": "321325",
    "label": "Gardumukti",
    "value": "Gardumukti"
  },
  {
    "id": "3213252004",
    "district_id": "321325",
    "label": "Mariuk",
    "value": "Mariuk"
  },
  {
    "id": "3213252005",
    "district_id": "321325",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3213252006",
    "district_id": "321325",
    "label": "Tambakdahan",
    "value": "Tambakdahan"
  },
  {
    "id": "3213252007",
    "district_id": "321325",
    "label": "Rancaudik",
    "value": "Rancaudik"
  },
  {
    "id": "3213252008",
    "district_id": "321325",
    "label": "Bojongkeding",
    "value": "Bojongkeding"
  },
  {
    "id": "3213252009",
    "district_id": "321325",
    "label": "Bojonegara",
    "value": "Bojonegara"
  },
  {
    "id": "3213262001",
    "district_id": "321326",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3213262002",
    "district_id": "321326",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3213262003",
    "district_id": "321326",
    "label": "Bojongloa",
    "value": "Bojongloa"
  },
  {
    "id": "3213262004",
    "district_id": "321326",
    "label": "Sukamelang",
    "value": "Sukamelang"
  },
  {
    "id": "3213262005",
    "district_id": "321326",
    "label": "Tenjolaya",
    "value": "Tenjolaya"
  },
  {
    "id": "3213262006",
    "district_id": "321326",
    "label": "Kasomalang Wetan",
    "value": "Kasomalang Wetan"
  },
  {
    "id": "3213262007",
    "district_id": "321326",
    "label": "Kasomalang Kulon",
    "value": "Kasomalang Kulon"
  },
  {
    "id": "3213262008",
    "district_id": "321326",
    "label": "Cimanglid",
    "value": "Cimanglid"
  },
  {
    "id": "3213272001",
    "district_id": "321327",
    "label": "Manyeti",
    "value": "Manyeti"
  },
  {
    "id": "3213272002",
    "district_id": "321327",
    "label": "Rawalele",
    "value": "Rawalele"
  },
  {
    "id": "3213272003",
    "district_id": "321327",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3213272004",
    "district_id": "321327",
    "label": "Situsari",
    "value": "Situsari"
  },
  {
    "id": "3213272005",
    "district_id": "321327",
    "label": "Dawuan Kaler",
    "value": "Dawuan Kaler"
  },
  {
    "id": "3213272006",
    "district_id": "321327",
    "label": "Dawuan Kidul",
    "value": "Dawuan Kidul"
  },
  {
    "id": "3213272007",
    "district_id": "321327",
    "label": "Cisampih",
    "value": "Cisampih"
  },
  {
    "id": "3213272008",
    "district_id": "321327",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3213272009",
    "district_id": "321327",
    "label": "Batusari",
    "value": "Batusari"
  },
  {
    "id": "3213272010",
    "district_id": "321327",
    "label": "Jambelaer",
    "value": "Jambelaer"
  },
  {
    "id": "3213282001",
    "district_id": "321328",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3213282002",
    "district_id": "321328",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3213282003",
    "district_id": "321328",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3213282004",
    "district_id": "321328",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "3213282005",
    "district_id": "321328",
    "label": "Pangsor",
    "value": "Pangsor"
  },
  {
    "id": "3213282006",
    "district_id": "321328",
    "label": "Sumurgintung",
    "value": "Sumurgintung"
  },
  {
    "id": "3213282007",
    "district_id": "321328",
    "label": "Balimbing",
    "value": "Balimbing"
  },
  {
    "id": "3213282008",
    "district_id": "321328",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3213282009",
    "district_id": "321328",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3213292001",
    "district_id": "321329",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3213292002",
    "district_id": "321329",
    "label": "Ciater",
    "value": "Ciater"
  },
  {
    "id": "3213292003",
    "district_id": "321329",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3213292004",
    "district_id": "321329",
    "label": "Cibeusi",
    "value": "Cibeusi"
  },
  {
    "id": "3213292005",
    "district_id": "321329",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "3213292006",
    "district_id": "321329",
    "label": "Palasari",
    "value": "Palasari"
  },
  {
    "id": "3213292007",
    "district_id": "321329",
    "label": "Sanca",
    "value": "Sanca"
  },
  {
    "id": "3213302001",
    "district_id": "321330",
    "label": "Kebondanas",
    "value": "Kebondanas"
  },
  {
    "id": "3213302002",
    "district_id": "321330",
    "label": "Pusakajaya",
    "value": "Pusakajaya"
  },
  {
    "id": "3213302003",
    "district_id": "321330",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3213302004",
    "district_id": "321330",
    "label": "Bojongtengah",
    "value": "Bojongtengah"
  },
  {
    "id": "3213302005",
    "district_id": "321330",
    "label": "Bojongjaya",
    "value": "Bojongjaya"
  },
  {
    "id": "3213302006",
    "district_id": "321330",
    "label": "Cigugur",
    "value": "Cigugur"
  },
  {
    "id": "3213302007",
    "district_id": "321330",
    "label": "Cigugur Kaler",
    "value": "Cigugur Kaler"
  },
  {
    "id": "3213302008",
    "district_id": "321330",
    "label": "Rangdu",
    "value": "Rangdu"
  },
  {
    "id": "3214011001",
    "district_id": "321401",
    "label": "Nagri Kidul",
    "value": "Nagri Kidul"
  },
  {
    "id": "3214011002",
    "district_id": "321401",
    "label": "Nagri Kaler",
    "value": "Nagri Kaler"
  },
  {
    "id": "3214011003",
    "district_id": "321401",
    "label": "Nagri Tengah",
    "value": "Nagri Tengah"
  },
  {
    "id": "3214011004",
    "district_id": "321401",
    "label": "Sindangkasih",
    "value": "Sindangkasih"
  },
  {
    "id": "3214011005",
    "district_id": "321401",
    "label": "Cipaisan",
    "value": "Cipaisan"
  },
  {
    "id": "3214011006",
    "district_id": "321401",
    "label": "Purwamekar",
    "value": "Purwamekar"
  },
  {
    "id": "3214011007",
    "district_id": "321401",
    "label": "Ciseureuh",
    "value": "Ciseureuh"
  },
  {
    "id": "3214011008",
    "district_id": "321401",
    "label": "Tegalmuncul",
    "value": "Tegalmuncul"
  },
  {
    "id": "3214011009",
    "district_id": "321401",
    "label": "Munjuljaya",
    "value": "Munjuljaya"
  },
  {
    "id": "3214012010",
    "district_id": "321401",
    "label": "Citalang",
    "value": "Citalang"
  },
  {
    "id": "3214022001",
    "district_id": "321402",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3214022002",
    "district_id": "321402",
    "label": "Campakasari",
    "value": "Campakasari"
  },
  {
    "id": "3214022003",
    "district_id": "321402",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "3214022004",
    "district_id": "321402",
    "label": "Cirende",
    "value": "Cirende"
  },
  {
    "id": "3214022005",
    "district_id": "321402",
    "label": "Cikumpay",
    "value": "Cikumpay"
  },
  {
    "id": "3214022006",
    "district_id": "321402",
    "label": "Cijaya",
    "value": "Cijaya"
  },
  {
    "id": "3214022007",
    "district_id": "321402",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3214022008",
    "district_id": "321402",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "3214022009",
    "district_id": "321402",
    "label": "Cijunti",
    "value": "Cijunti"
  },
  {
    "id": "3214022010",
    "district_id": "321402",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3214032001",
    "district_id": "321403",
    "label": "Cikaobandung",
    "value": "Cikaobandung"
  },
  {
    "id": "3214032002",
    "district_id": "321403",
    "label": "Jatimekar",
    "value": "Jatimekar"
  },
  {
    "id": "3214032003",
    "district_id": "321403",
    "label": "Jatiluhur",
    "value": "Jatiluhur"
  },
  {
    "id": "3214032004",
    "district_id": "321403",
    "label": "Cilegong",
    "value": "Cilegong"
  },
  {
    "id": "3214032005",
    "district_id": "321403",
    "label": "Kembangkuning",
    "value": "Kembangkuning"
  },
  {
    "id": "3214032006",
    "district_id": "321403",
    "label": "Cibinong",
    "value": "Cibinong"
  },
  {
    "id": "3214032007",
    "district_id": "321403",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3214032008",
    "district_id": "321403",
    "label": "Mekargalih",
    "value": "Mekargalih"
  },
  {
    "id": "3214032009",
    "district_id": "321403",
    "label": "Cisalada",
    "value": "Cisalada"
  },
  {
    "id": "3214032010",
    "district_id": "321403",
    "label": "Parakanlima",
    "value": "Parakanlima"
  },
  {
    "id": "3214042001",
    "district_id": "321404",
    "label": "Plered",
    "value": "Plered"
  },
  {
    "id": "3214042002",
    "district_id": "321404",
    "label": "Palinggihan",
    "value": "Palinggihan"
  },
  {
    "id": "3214042003",
    "district_id": "321404",
    "label": "Cibogohilir",
    "value": "Cibogohilir"
  },
  {
    "id": "3214042004",
    "district_id": "321404",
    "label": "Linggasari",
    "value": "Linggasari"
  },
  {
    "id": "3214042005",
    "district_id": "321404",
    "label": "Gandasoli",
    "value": "Gandasoli"
  },
  {
    "id": "3214042006",
    "district_id": "321404",
    "label": "Citeko",
    "value": "Citeko"
  },
  {
    "id": "3214042007",
    "district_id": "321404",
    "label": "Liunggunung",
    "value": "Liunggunung"
  },
  {
    "id": "3214042008",
    "district_id": "321404",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "3214042009",
    "district_id": "321404",
    "label": "Anjun",
    "value": "Anjun"
  },
  {
    "id": "3214042010",
    "district_id": "321404",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3214042011",
    "district_id": "321404",
    "label": "Babakansari",
    "value": "Babakansari"
  },
  {
    "id": "3214042012",
    "district_id": "321404",
    "label": "Gandamekar",
    "value": "Gandamekar"
  },
  {
    "id": "3214042013",
    "district_id": "321404",
    "label": "Citekokaler",
    "value": "Citekokaler"
  },
  {
    "id": "3214042014",
    "district_id": "321404",
    "label": "Pamoyanan",
    "value": "Pamoyanan"
  },
  {
    "id": "3214042015",
    "district_id": "321404",
    "label": "Sempur",
    "value": "Sempur"
  },
  {
    "id": "3214042016",
    "district_id": "321404",
    "label": "Cibogo Girang",
    "value": "Cibogo Girang"
  },
  {
    "id": "3214052001",
    "district_id": "321405",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3214052002",
    "district_id": "321405",
    "label": "Malangnengah",
    "value": "Malangnengah"
  },
  {
    "id": "3214052003",
    "district_id": "321405",
    "label": "Cilalawi",
    "value": "Cilalawi"
  },
  {
    "id": "3214052004",
    "district_id": "321405",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3214052005",
    "district_id": "321405",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3214052006",
    "district_id": "321405",
    "label": "Cianting Utara",
    "value": "Cianting Utara"
  },
  {
    "id": "3214052007",
    "district_id": "321405",
    "label": "Cianting",
    "value": "Cianting"
  },
  {
    "id": "3214052008",
    "district_id": "321405",
    "label": "Pasirmunjul",
    "value": "Pasirmunjul"
  },
  {
    "id": "3214052009",
    "district_id": "321405",
    "label": "Tajursindang",
    "value": "Tajursindang"
  },
  {
    "id": "3214052010",
    "district_id": "321405",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3214052011",
    "district_id": "321405",
    "label": "Cijantung",
    "value": "Cijantung"
  },
  {
    "id": "3214052012",
    "district_id": "321405",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3214052013",
    "district_id": "321405",
    "label": "Panyindangan",
    "value": "Panyindangan"
  },
  {
    "id": "3214052014",
    "district_id": "321405",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3214062001",
    "district_id": "321406",
    "label": "Darangdan",
    "value": "Darangdan"
  },
  {
    "id": "3214062002",
    "district_id": "321406",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3214062003",
    "district_id": "321406",
    "label": "Cilingga",
    "value": "Cilingga"
  },
  {
    "id": "3214062004",
    "district_id": "321406",
    "label": "Nangewer",
    "value": "Nangewer"
  },
  {
    "id": "3214062005",
    "district_id": "321406",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3214062006",
    "district_id": "321406",
    "label": "Linggarsari",
    "value": "Linggarsari"
  },
  {
    "id": "3214062007",
    "district_id": "321406",
    "label": "Sawit",
    "value": "Sawit"
  },
  {
    "id": "3214062008",
    "district_id": "321406",
    "label": "Linggamukti",
    "value": "Linggamukti"
  },
  {
    "id": "3214062009",
    "district_id": "321406",
    "label": "Gununghejo",
    "value": "Gununghejo"
  },
  {
    "id": "3214062010",
    "district_id": "321406",
    "label": "Pasirangin",
    "value": "Pasirangin"
  },
  {
    "id": "3214062011",
    "district_id": "321406",
    "label": "Legoksari",
    "value": "Legoksari"
  },
  {
    "id": "3214062012",
    "district_id": "321406",
    "label": "Sirnamanah",
    "value": "Sirnamanah"
  },
  {
    "id": "3214062013",
    "district_id": "321406",
    "label": "Sadarkarya",
    "value": "Sadarkarya"
  },
  {
    "id": "3214062014",
    "district_id": "321406",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3214062015",
    "district_id": "321406",
    "label": "Nagrak",
    "value": "Nagrak"
  },
  {
    "id": "3214072001",
    "district_id": "321407",
    "label": "Gunungkarung",
    "value": "Gunungkarung"
  },
  {
    "id": "3214072002",
    "district_id": "321407",
    "label": "Citamiang",
    "value": "Citamiang"
  },
  {
    "id": "3214072003",
    "district_id": "321407",
    "label": "Sinargalih",
    "value": "Sinargalih"
  },
  {
    "id": "3214072004",
    "district_id": "321407",
    "label": "Tegaldatar",
    "value": "Tegaldatar"
  },
  {
    "id": "3214072005",
    "district_id": "321407",
    "label": "Cijati",
    "value": "Cijati"
  },
  {
    "id": "3214072006",
    "district_id": "321407",
    "label": "Ciramahilir",
    "value": "Ciramahilir"
  },
  {
    "id": "3214072007",
    "district_id": "321407",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3214072008",
    "district_id": "321407",
    "label": "Pasirjambu",
    "value": "Pasirjambu"
  },
  {
    "id": "3214082001",
    "district_id": "321408",
    "label": "Batutumpang",
    "value": "Batutumpang"
  },
  {
    "id": "3214082002",
    "district_id": "321408",
    "label": "Citalang",
    "value": "Citalang"
  },
  {
    "id": "3214082003",
    "district_id": "321408",
    "label": "Tegalwaru",
    "value": "Tegalwaru"
  },
  {
    "id": "3214082004",
    "district_id": "321408",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3214082005",
    "district_id": "321408",
    "label": "Warungjeruk",
    "value": "Warungjeruk"
  },
  {
    "id": "3214082006",
    "district_id": "321408",
    "label": "Galumpit",
    "value": "Galumpit"
  },
  {
    "id": "3214082007",
    "district_id": "321408",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3214082008",
    "district_id": "321408",
    "label": "Karoya",
    "value": "Karoya"
  },
  {
    "id": "3214082009",
    "district_id": "321408",
    "label": "Cadassari",
    "value": "Cadassari"
  },
  {
    "id": "3214082010",
    "district_id": "321408",
    "label": "Cadasmekar",
    "value": "Cadasmekar"
  },
  {
    "id": "3214082011",
    "district_id": "321408",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3214082012",
    "district_id": "321408",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3214082013",
    "district_id": "321408",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3214092001",
    "district_id": "321409",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "3214092002",
    "district_id": "321409",
    "label": "Sukadami",
    "value": "Sukadami"
  },
  {
    "id": "3214092003",
    "district_id": "321409",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3214092004",
    "district_id": "321409",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3214092005",
    "district_id": "321409",
    "label": "Nagrog",
    "value": "Nagrog"
  },
  {
    "id": "3214092006",
    "district_id": "321409",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3214092007",
    "district_id": "321409",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3214092008",
    "district_id": "321409",
    "label": "Nangerang",
    "value": "Nangerang"
  },
  {
    "id": "3214092009",
    "district_id": "321409",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3214092010",
    "district_id": "321409",
    "label": "Sumurugul",
    "value": "Sumurugul"
  },
  {
    "id": "3214092011",
    "district_id": "321409",
    "label": "Raharja",
    "value": "Raharja"
  },
  {
    "id": "3214092012",
    "district_id": "321409",
    "label": "Sakambang",
    "value": "Sakambang"
  },
  {
    "id": "3214092013",
    "district_id": "321409",
    "label": "Taringgul Tonggoh",
    "value": "Taringgul Tonggoh"
  },
  {
    "id": "3214092014",
    "district_id": "321409",
    "label": "Legokhuni",
    "value": "Legokhuni"
  },
  {
    "id": "3214092015",
    "district_id": "321409",
    "label": "Taringgul Tengah",
    "value": "Taringgul Tengah"
  },
  {
    "id": "3214102001",
    "district_id": "321410",
    "label": "Pasawahananyar",
    "value": "Pasawahananyar"
  },
  {
    "id": "3214102002",
    "district_id": "321410",
    "label": "Pasawahan",
    "value": "Pasawahan"
  },
  {
    "id": "3214102003",
    "district_id": "321410",
    "label": "Sawahkulon",
    "value": "Sawahkulon"
  },
  {
    "id": "3214102004",
    "district_id": "321410",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3214102005",
    "district_id": "321410",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3214102006",
    "district_id": "321410",
    "label": "Pasawahankidul",
    "value": "Pasawahankidul"
  },
  {
    "id": "3214102007",
    "district_id": "321410",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3214102008",
    "district_id": "321410",
    "label": "Lebakanyar",
    "value": "Lebakanyar"
  },
  {
    "id": "3214102009",
    "district_id": "321410",
    "label": "Selaawi",
    "value": "Selaawi"
  },
  {
    "id": "3214102010",
    "district_id": "321410",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3214102011",
    "district_id": "321410",
    "label": "Warungkadu",
    "value": "Warungkadu"
  },
  {
    "id": "3214102012",
    "district_id": "321410",
    "label": "Cihuni",
    "value": "Cihuni"
  },
  {
    "id": "3214112001",
    "district_id": "321411",
    "label": "Bojong Barat",
    "value": "Bojong Barat"
  },
  {
    "id": "3214112002",
    "district_id": "321411",
    "label": "Bojong Timur",
    "value": "Bojong Timur"
  },
  {
    "id": "3214112003",
    "district_id": "321411",
    "label": "Cikeris",
    "value": "Cikeris"
  },
  {
    "id": "3214112004",
    "district_id": "321411",
    "label": "Cihanjawar",
    "value": "Cihanjawar"
  },
  {
    "id": "3214112005",
    "district_id": "321411",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3214112006",
    "district_id": "321411",
    "label": "Cileunca",
    "value": "Cileunca"
  },
  {
    "id": "3214112007",
    "district_id": "321411",
    "label": "Cibingbin",
    "value": "Cibingbin"
  },
  {
    "id": "3214112008",
    "district_id": "321411",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3214112009",
    "district_id": "321411",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3214112010",
    "district_id": "321411",
    "label": "Pawenang",
    "value": "Pawenang"
  },
  {
    "id": "3214112011",
    "district_id": "321411",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3214112012",
    "district_id": "321411",
    "label": "Sindangpanon",
    "value": "Sindangpanon"
  },
  {
    "id": "3214112013",
    "district_id": "321411",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3214112014",
    "district_id": "321411",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3214122001",
    "district_id": "321412",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3214122002",
    "district_id": "321412",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3214122003",
    "district_id": "321412",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3214122004",
    "district_id": "321412",
    "label": "Babakancikao",
    "value": "Babakancikao"
  },
  {
    "id": "3214122005",
    "district_id": "321412",
    "label": "Kadumekar",
    "value": "Kadumekar"
  },
  {
    "id": "3214122006",
    "district_id": "321412",
    "label": "Marancang",
    "value": "Marancang"
  },
  {
    "id": "3214122007",
    "district_id": "321412",
    "label": "Ciwareng",
    "value": "Ciwareng"
  },
  {
    "id": "3214122008",
    "district_id": "321412",
    "label": "Mulyamekar",
    "value": "Mulyamekar"
  },
  {
    "id": "3214122009",
    "district_id": "321412",
    "label": "Cigelam",
    "value": "Cigelam"
  },
  {
    "id": "3214132001",
    "district_id": "321413",
    "label": "Ciwangi",
    "value": "Ciwangi"
  },
  {
    "id": "3214132002",
    "district_id": "321413",
    "label": "Cibening",
    "value": "Cibening"
  },
  {
    "id": "3214132003",
    "district_id": "321413",
    "label": "Bungursari",
    "value": "Bungursari"
  },
  {
    "id": "3214132004",
    "district_id": "321413",
    "label": "Dangdeur",
    "value": "Dangdeur"
  },
  {
    "id": "3214132005",
    "district_id": "321413",
    "label": "Cibungur",
    "value": "Cibungur"
  },
  {
    "id": "3214132006",
    "district_id": "321413",
    "label": "Wanakerta",
    "value": "Wanakerta"
  },
  {
    "id": "3214132007",
    "district_id": "321413",
    "label": "Cinangka",
    "value": "Cinangka"
  },
  {
    "id": "3214132008",
    "district_id": "321413",
    "label": "Cikopo",
    "value": "Cikopo"
  },
  {
    "id": "3214132009",
    "district_id": "321413",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3214132010",
    "district_id": "321413",
    "label": "Karangmukti",
    "value": "Karangmukti"
  },
  {
    "id": "3214142001",
    "district_id": "321414",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "3214142002",
    "district_id": "321414",
    "label": "Cilandak",
    "value": "Cilandak"
  },
  {
    "id": "3214142003",
    "district_id": "321414",
    "label": "Karyamekar",
    "value": "Karyamekar"
  },
  {
    "id": "3214142004",
    "district_id": "321414",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3214142005",
    "district_id": "321414",
    "label": "Ciparungsari",
    "value": "Ciparungsari"
  },
  {
    "id": "3214142006",
    "district_id": "321414",
    "label": "Cirangkong",
    "value": "Cirangkong"
  },
  {
    "id": "3214142007",
    "district_id": "321414",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3214142008",
    "district_id": "321414",
    "label": "Cibukamanah",
    "value": "Cibukamanah"
  },
  {
    "id": "3214142009",
    "district_id": "321414",
    "label": "Wanawali",
    "value": "Wanawali"
  },
  {
    "id": "3214142010",
    "district_id": "321414",
    "label": "Cipancur",
    "value": "Cipancur"
  },
  {
    "id": "3214152001",
    "district_id": "321415",
    "label": "Kutamanah",
    "value": "Kutamanah"
  },
  {
    "id": "3214152002",
    "district_id": "321415",
    "label": "Kertamanah",
    "value": "Kertamanah"
  },
  {
    "id": "3214152003",
    "district_id": "321415",
    "label": "Ciririp",
    "value": "Ciririp"
  },
  {
    "id": "3214152004",
    "district_id": "321415",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3214152005",
    "district_id": "321415",
    "label": "Parungbanteng",
    "value": "Parungbanteng"
  },
  {
    "id": "3214162001",
    "district_id": "321416",
    "label": "Parakansalam",
    "value": "Parakansalam"
  },
  {
    "id": "3214162002",
    "district_id": "321416",
    "label": "Pondokbungur",
    "value": "Pondokbungur"
  },
  {
    "id": "3214162003",
    "district_id": "321416",
    "label": "Salem",
    "value": "Salem"
  },
  {
    "id": "3214162004",
    "district_id": "321416",
    "label": "Galudra",
    "value": "Galudra"
  },
  {
    "id": "3214162005",
    "district_id": "321416",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3214162006",
    "district_id": "321416",
    "label": "Salammulya",
    "value": "Salammulya"
  },
  {
    "id": "3214162007",
    "district_id": "321416",
    "label": "Salamjaya",
    "value": "Salamjaya"
  },
  {
    "id": "3214162008",
    "district_id": "321416",
    "label": "Bungurjaya",
    "value": "Bungurjaya"
  },
  {
    "id": "3214162009",
    "district_id": "321416",
    "label": "Gurudug",
    "value": "Gurudug"
  },
  {
    "id": "3214162010",
    "district_id": "321416",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3214162011",
    "district_id": "321416",
    "label": "Situ",
    "value": "Situ"
  },
  {
    "id": "3214172001",
    "district_id": "321417",
    "label": "Karangpedas",
    "value": "Karangpedas"
  },
  {
    "id": "3214172002",
    "district_id": "321417",
    "label": "Ciracas",
    "value": "Ciracas"
  },
  {
    "id": "3214172003",
    "district_id": "321417",
    "label": "Parakangarokgek",
    "value": "Parakangarokgek"
  },
  {
    "id": "3214172004",
    "district_id": "321417",
    "label": "Taringgullandeuh",
    "value": "Taringgullandeuh"
  },
  {
    "id": "3214172005",
    "district_id": "321417",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3214172006",
    "district_id": "321417",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3214172007",
    "district_id": "321417",
    "label": "Gardu",
    "value": "Gardu"
  },
  {
    "id": "3214172008",
    "district_id": "321417",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3214172009",
    "district_id": "321417",
    "label": "Pusakamulya",
    "value": "Pusakamulya"
  },
  {
    "id": "3214172010",
    "district_id": "321417",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3215011001",
    "district_id": "321501",
    "label": "Karawang Kulon",
    "value": "Karawang Kulon"
  },
  {
    "id": "3215011003",
    "district_id": "321501",
    "label": "Adiarsa Barat",
    "value": "Adiarsa Barat"
  },
  {
    "id": "3215011005",
    "district_id": "321501",
    "label": "Tanjungpura",
    "value": "Tanjungpura"
  },
  {
    "id": "3215011006",
    "district_id": "321501",
    "label": "Tanjungmekar",
    "value": "Tanjungmekar"
  },
  {
    "id": "3215011007",
    "district_id": "321501",
    "label": "Tunggakjati",
    "value": "Tunggakjati"
  },
  {
    "id": "3215011010",
    "district_id": "321501",
    "label": "Karawangpawitan",
    "value": "Karawangpawitan"
  },
  {
    "id": "3215011011",
    "district_id": "321501",
    "label": "Nagasari",
    "value": "Nagasari"
  },
  {
    "id": "3215011012",
    "district_id": "321501",
    "label": "Mekarjati",
    "value": "Mekarjati"
  },
  {
    "id": "3215022001",
    "district_id": "321502",
    "label": "Ciptasari",
    "value": "Ciptasari"
  },
  {
    "id": "3215022002",
    "district_id": "321502",
    "label": "Tamanmekar",
    "value": "Tamanmekar"
  },
  {
    "id": "3215022003",
    "district_id": "321502",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3215022004",
    "district_id": "321502",
    "label": "Jatilaksana",
    "value": "Jatilaksana"
  },
  {
    "id": "3215022005",
    "district_id": "321502",
    "label": "Cintaasih",
    "value": "Cintaasih"
  },
  {
    "id": "3215022006",
    "district_id": "321502",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3215022007",
    "district_id": "321502",
    "label": "Mulangsari",
    "value": "Mulangsari"
  },
  {
    "id": "3215022008",
    "district_id": "321502",
    "label": "Medalsari",
    "value": "Medalsari"
  },
  {
    "id": "3215032001",
    "district_id": "321503",
    "label": "Telukjambe",
    "value": "Telukjambe"
  },
  {
    "id": "3215032002",
    "district_id": "321503",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3215032003",
    "district_id": "321503",
    "label": "Sirnabaya",
    "value": "Sirnabaya"
  },
  {
    "id": "3215032004",
    "district_id": "321503",
    "label": "Puseurjaya",
    "value": "Puseurjaya"
  },
  {
    "id": "3215032005",
    "district_id": "321503",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3215032006",
    "district_id": "321503",
    "label": "Wadas",
    "value": "Wadas"
  },
  {
    "id": "3215032011",
    "district_id": "321503",
    "label": "Purwadana",
    "value": "Purwadana"
  },
  {
    "id": "3215032012",
    "district_id": "321503",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "3215032019",
    "district_id": "321503",
    "label": "Pinayungan",
    "value": "Pinayungan"
  },
  {
    "id": "3215042001",
    "district_id": "321504",
    "label": "Kutapohaci",
    "value": "Kutapohaci"
  },
  {
    "id": "3215042003",
    "district_id": "321504",
    "label": "Parungmulya",
    "value": "Parungmulya"
  },
  {
    "id": "3215042004",
    "district_id": "321504",
    "label": "Kutamekar",
    "value": "Kutamekar"
  },
  {
    "id": "3215042005",
    "district_id": "321504",
    "label": "Kutanegara",
    "value": "Kutanegara"
  },
  {
    "id": "3215042006",
    "district_id": "321504",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3215042007",
    "district_id": "321504",
    "label": "Mulyasejati",
    "value": "Mulyasejati"
  },
  {
    "id": "3215042008",
    "district_id": "321504",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3215052001",
    "district_id": "321505",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3215052002",
    "district_id": "321505",
    "label": "Pancawati",
    "value": "Pancawati"
  },
  {
    "id": "3215052003",
    "district_id": "321505",
    "label": "Walahar",
    "value": "Walahar"
  },
  {
    "id": "3215052004",
    "district_id": "321505",
    "label": "Kiarapayung",
    "value": "Kiarapayung"
  },
  {
    "id": "3215052005",
    "district_id": "321505",
    "label": "Sumurkondang",
    "value": "Sumurkondang"
  },
  {
    "id": "3215052006",
    "district_id": "321505",
    "label": "Cibalongsari",
    "value": "Cibalongsari"
  },
  {
    "id": "3215052007",
    "district_id": "321505",
    "label": "Klari",
    "value": "Klari"
  },
  {
    "id": "3215052008",
    "district_id": "321505",
    "label": "Belendung",
    "value": "Belendung"
  },
  {
    "id": "3215052016",
    "district_id": "321505",
    "label": "Anggadita",
    "value": "Anggadita"
  },
  {
    "id": "3215052017",
    "district_id": "321505",
    "label": "Gintungkerta",
    "value": "Gintungkerta"
  },
  {
    "id": "3215052018",
    "district_id": "321505",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3215052019",
    "district_id": "321505",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3215052020",
    "district_id": "321505",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "3215062001",
    "district_id": "321506",
    "label": "Rengasdengklok Selatan",
    "value": "Rengasdengklok Selatan"
  },
  {
    "id": "3215062002",
    "district_id": "321506",
    "label": "Rengasdengklok Utara",
    "value": "Rengasdengklok Utara"
  },
  {
    "id": "3215062003",
    "district_id": "321506",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3215062004",
    "district_id": "321506",
    "label": "Dewisari",
    "value": "Dewisari"
  },
  {
    "id": "3215062005",
    "district_id": "321506",
    "label": "Amansari",
    "value": "Amansari"
  },
  {
    "id": "3215062006",
    "district_id": "321506",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "3215062007",
    "district_id": "321506",
    "label": "Dukuhkarya",
    "value": "Dukuhkarya"
  },
  {
    "id": "3215062008",
    "district_id": "321506",
    "label": "Kalangsari",
    "value": "Kalangsari"
  },
  {
    "id": "3215062009",
    "district_id": "321506",
    "label": "Kalangsurya",
    "value": "Kalangsurya"
  },
  {
    "id": "3215072001",
    "district_id": "321507",
    "label": "Waluya",
    "value": "Waluya"
  },
  {
    "id": "3215072002",
    "district_id": "321507",
    "label": "Mulyajaya",
    "value": "Mulyajaya"
  },
  {
    "id": "3215072003",
    "district_id": "321507",
    "label": "Kutakarya",
    "value": "Kutakarya"
  },
  {
    "id": "3215072004",
    "district_id": "321507",
    "label": "Kutagandok",
    "value": "Kutagandok"
  },
  {
    "id": "3215072005",
    "district_id": "321507",
    "label": "Kutamukti",
    "value": "Kutamukti"
  },
  {
    "id": "3215072006",
    "district_id": "321507",
    "label": "Kutajaya",
    "value": "Kutajaya"
  },
  {
    "id": "3215072007",
    "district_id": "321507",
    "label": "Sampalan",
    "value": "Sampalan"
  },
  {
    "id": "3215072008",
    "district_id": "321507",
    "label": "Sindangmulya",
    "value": "Sindangmulya"
  },
  {
    "id": "3215072009",
    "district_id": "321507",
    "label": "Sindangmukti",
    "value": "Sindangmukti"
  },
  {
    "id": "3215072010",
    "district_id": "321507",
    "label": "Kutaraja",
    "value": "Kutaraja"
  },
  {
    "id": "3215072011",
    "district_id": "321507",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3215072012",
    "district_id": "321507",
    "label": "Sindangkarya",
    "value": "Sindangkarya"
  },
  {
    "id": "3215082001",
    "district_id": "321508",
    "label": "Batujaya",
    "value": "Batujaya"
  },
  {
    "id": "3215082002",
    "district_id": "321508",
    "label": "Telukambulu",
    "value": "Telukambulu"
  },
  {
    "id": "3215082003",
    "district_id": "321508",
    "label": "Telukbango",
    "value": "Telukbango"
  },
  {
    "id": "3215082004",
    "district_id": "321508",
    "label": "Karyabhakti",
    "value": "Karyabhakti"
  },
  {
    "id": "3215082005",
    "district_id": "321508",
    "label": "Baturaden",
    "value": "Baturaden"
  },
  {
    "id": "3215082006",
    "district_id": "321508",
    "label": "Segaran",
    "value": "Segaran"
  },
  {
    "id": "3215082007",
    "district_id": "321508",
    "label": "Segarjaya",
    "value": "Segarjaya"
  },
  {
    "id": "3215082008",
    "district_id": "321508",
    "label": "Kutaampel",
    "value": "Kutaampel"
  },
  {
    "id": "3215082009",
    "district_id": "321508",
    "label": "Karyamulya",
    "value": "Karyamulya"
  },
  {
    "id": "3215082010",
    "district_id": "321508",
    "label": "Karyamakmur",
    "value": "Karyamakmur"
  },
  {
    "id": "3215092001",
    "district_id": "321509",
    "label": "Sabajaya",
    "value": "Sabajaya"
  },
  {
    "id": "3215092002",
    "district_id": "321509",
    "label": "Pisangsambo",
    "value": "Pisangsambo"
  },
  {
    "id": "3215092003",
    "district_id": "321509",
    "label": "Gempolkarya",
    "value": "Gempolkarya"
  },
  {
    "id": "3215092004",
    "district_id": "321509",
    "label": "Medankarya",
    "value": "Medankarya"
  },
  {
    "id": "3215092005",
    "district_id": "321509",
    "label": "Tambaksumur",
    "value": "Tambaksumur"
  },
  {
    "id": "3215092006",
    "district_id": "321509",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3215092007",
    "district_id": "321509",
    "label": "Sumurlaban",
    "value": "Sumurlaban"
  },
  {
    "id": "3215092008",
    "district_id": "321509",
    "label": "Srijaya",
    "value": "Srijaya"
  },
  {
    "id": "3215092009",
    "district_id": "321509",
    "label": "Kutamakmur",
    "value": "Kutamakmur"
  },
  {
    "id": "3215092010",
    "district_id": "321509",
    "label": "Bolang",
    "value": "Bolang"
  },
  {
    "id": "3215092011",
    "district_id": "321509",
    "label": "Srikamulyan",
    "value": "Srikamulyan"
  },
  {
    "id": "3215102001",
    "district_id": "321510",
    "label": "Payungsari",
    "value": "Payungsari"
  },
  {
    "id": "3215102002",
    "district_id": "321510",
    "label": "Karangjaya",
    "value": "Karangjaya"
  },
  {
    "id": "3215102003",
    "district_id": "321510",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3215102005",
    "district_id": "321510",
    "label": "Sungaibuntu",
    "value": "Sungaibuntu"
  },
  {
    "id": "3215102006",
    "district_id": "321510",
    "label": "Dongkal",
    "value": "Dongkal"
  },
  {
    "id": "3215102007",
    "district_id": "321510",
    "label": "Kertamulya",
    "value": "Kertamulya"
  },
  {
    "id": "3215102008",
    "district_id": "321510",
    "label": "Puspasari",
    "value": "Puspasari"
  },
  {
    "id": "3215102010",
    "district_id": "321510",
    "label": "Labanjaya",
    "value": "Labanjaya"
  },
  {
    "id": "3215102012",
    "district_id": "321510",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3215102016",
    "district_id": "321510",
    "label": "Rangdumulya",
    "value": "Rangdumulya"
  },
  {
    "id": "3215102017",
    "district_id": "321510",
    "label": "Kendaljaya",
    "value": "Kendaljaya"
  },
  {
    "id": "3215102018",
    "district_id": "321510",
    "label": "Malangsari",
    "value": "Malangsari"
  },
  {
    "id": "3215112001",
    "district_id": "321511",
    "label": "Pejaten",
    "value": "Pejaten"
  },
  {
    "id": "3215112002",
    "district_id": "321511",
    "label": "Cibuaya",
    "value": "Cibuaya"
  },
  {
    "id": "3215112003",
    "district_id": "321511",
    "label": "Kertarahayu",
    "value": "Kertarahayu"
  },
  {
    "id": "3215112004",
    "district_id": "321511",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3215112005",
    "district_id": "321511",
    "label": "Kedungjeruk",
    "value": "Kedungjeruk"
  },
  {
    "id": "3215112006",
    "district_id": "321511",
    "label": "Kalidungjaya",
    "value": "Kalidungjaya"
  },
  {
    "id": "3215112007",
    "district_id": "321511",
    "label": "Sedari",
    "value": "Sedari"
  },
  {
    "id": "3215112008",
    "district_id": "321511",
    "label": "Cemarajaya",
    "value": "Cemarajaya"
  },
  {
    "id": "3215112009",
    "district_id": "321511",
    "label": "Jayamulya",
    "value": "Jayamulya"
  },
  {
    "id": "3215112010",
    "district_id": "321511",
    "label": "Kedungjaya",
    "value": "Kedungjaya"
  },
  {
    "id": "3215112011",
    "district_id": "321511",
    "label": "Gebangjaya",
    "value": "Gebangjaya"
  },
  {
    "id": "3215122001",
    "district_id": "321512",
    "label": "Tanjungmekar",
    "value": "Tanjungmekar"
  },
  {
    "id": "3215122002",
    "district_id": "321512",
    "label": "Telagajaya",
    "value": "Telagajaya"
  },
  {
    "id": "3215122003",
    "district_id": "321512",
    "label": "Telukbuyung",
    "value": "Telukbuyung"
  },
  {
    "id": "3215122004",
    "district_id": "321512",
    "label": "Tanahbaru",
    "value": "Tanahbaru"
  },
  {
    "id": "3215122005",
    "district_id": "321512",
    "label": "Solokan",
    "value": "Solokan"
  },
  {
    "id": "3215122006",
    "district_id": "321512",
    "label": "Tanjungbungin",
    "value": "Tanjungbungin"
  },
  {
    "id": "3215122007",
    "district_id": "321512",
    "label": "Telukjaya",
    "value": "Telukjaya"
  },
  {
    "id": "3215122008",
    "district_id": "321512",
    "label": "Tanjungpakis",
    "value": "Tanjungpakis"
  },
  {
    "id": "3215132001",
    "district_id": "321513",
    "label": "Dawuan Timur",
    "value": "Dawuan Timur"
  },
  {
    "id": "3215132002",
    "district_id": "321513",
    "label": "Kalihurip",
    "value": "Kalihurip"
  },
  {
    "id": "3215132003",
    "district_id": "321513",
    "label": "Cikampek Kota",
    "value": "Cikampek Kota"
  },
  {
    "id": "3215132004",
    "district_id": "321513",
    "label": "Dawuan Tengah",
    "value": "Dawuan Tengah"
  },
  {
    "id": "3215132006",
    "district_id": "321513",
    "label": "Cikampek Selatan",
    "value": "Cikampek Selatan"
  },
  {
    "id": "3215132007",
    "district_id": "321513",
    "label": "Cikampek Pusaka",
    "value": "Cikampek Pusaka"
  },
  {
    "id": "3215132008",
    "district_id": "321513",
    "label": "Cikampek Barat",
    "value": "Cikampek Barat"
  },
  {
    "id": "3215132009",
    "district_id": "321513",
    "label": "Cikampek Timur",
    "value": "Cikampek Timur"
  },
  {
    "id": "3215132010",
    "district_id": "321513",
    "label": "Kamojing",
    "value": "Kamojing"
  },
  {
    "id": "3215132011",
    "district_id": "321513",
    "label": "Dawuan Barat",
    "value": "Dawuan Barat"
  },
  {
    "id": "3215142001",
    "district_id": "321514",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3215142002",
    "district_id": "321514",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3215142003",
    "district_id": "321514",
    "label": "Barugbug",
    "value": "Barugbug"
  },
  {
    "id": "3215142004",
    "district_id": "321514",
    "label": "Cikalongsari",
    "value": "Cikalongsari"
  },
  {
    "id": "3215142005",
    "district_id": "321514",
    "label": "Cirejag",
    "value": "Cirejag"
  },
  {
    "id": "3215142006",
    "district_id": "321514",
    "label": "Jatibaru",
    "value": "Jatibaru"
  },
  {
    "id": "3215142007",
    "district_id": "321514",
    "label": "Jatiragas",
    "value": "Jatiragas"
  },
  {
    "id": "3215142008",
    "district_id": "321514",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "3215142009",
    "district_id": "321514",
    "label": "Kalijati",
    "value": "Kalijati"
  },
  {
    "id": "3215142010",
    "district_id": "321514",
    "label": "Balonggandu",
    "value": "Balonggandu"
  },
  {
    "id": "3215142011",
    "district_id": "321514",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3215142012",
    "district_id": "321514",
    "label": "Situdam",
    "value": "Situdam"
  },
  {
    "id": "3215142013",
    "district_id": "321514",
    "label": "Sukamekar",
    "value": "Sukamekar"
  },
  {
    "id": "3215142014",
    "district_id": "321514",
    "label": "Telarsari",
    "value": "Telarsari"
  },
  {
    "id": "3215152001",
    "district_id": "321515",
    "label": "Mekarmaya",
    "value": "Mekarmaya"
  },
  {
    "id": "3215152002",
    "district_id": "321515",
    "label": "Cilamaya",
    "value": "Cilamaya"
  },
  {
    "id": "3215152003",
    "district_id": "321515",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "3215152004",
    "district_id": "321515",
    "label": "Muarabaru",
    "value": "Muarabaru"
  },
  {
    "id": "3215152005",
    "district_id": "321515",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3215152006",
    "district_id": "321515",
    "label": "Tegalwaru",
    "value": "Tegalwaru"
  },
  {
    "id": "3215152007",
    "district_id": "321515",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3215152008",
    "district_id": "321515",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3215152009",
    "district_id": "321515",
    "label": "Cikarang",
    "value": "Cikarang"
  },
  {
    "id": "3215152010",
    "district_id": "321515",
    "label": "Rawagempol Wetan",
    "value": "Rawagempol Wetan"
  },
  {
    "id": "3215152011",
    "district_id": "321515",
    "label": "Rawagempol Kulon",
    "value": "Rawagempol Kulon"
  },
  {
    "id": "3215152012",
    "district_id": "321515",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3215162001",
    "district_id": "321516",
    "label": "Citarik",
    "value": "Citarik"
  },
  {
    "id": "3215162002",
    "district_id": "321516",
    "label": "Karangsinom",
    "value": "Karangsinom"
  },
  {
    "id": "3215162003",
    "district_id": "321516",
    "label": "Karangjaya",
    "value": "Karangjaya"
  },
  {
    "id": "3215162004",
    "district_id": "321516",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3215162005",
    "district_id": "321516",
    "label": "Parakanmulya",
    "value": "Parakanmulya"
  },
  {
    "id": "3215162006",
    "district_id": "321516",
    "label": "Kamurang",
    "value": "Kamurang"
  },
  {
    "id": "3215162007",
    "district_id": "321516",
    "label": "Cipondoh",
    "value": "Cipondoh"
  },
  {
    "id": "3215162008",
    "district_id": "321516",
    "label": "Kertawaluya",
    "value": "Kertawaluya"
  },
  {
    "id": "3215162009",
    "district_id": "321516",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3215162010",
    "district_id": "321516",
    "label": "Tirtasari",
    "value": "Tirtasari"
  },
  {
    "id": "3215172001",
    "district_id": "321517",
    "label": "Telagamulya",
    "value": "Telagamulya"
  },
  {
    "id": "3215172002",
    "district_id": "321517",
    "label": "Telagasari",
    "value": "Telagasari"
  },
  {
    "id": "3215172003",
    "district_id": "321517",
    "label": "Pasirtalaga",
    "value": "Pasirtalaga"
  },
  {
    "id": "3215172004",
    "district_id": "321517",
    "label": "Cariumulya",
    "value": "Cariumulya"
  },
  {
    "id": "3215172005",
    "district_id": "321517",
    "label": "Pasirmukti",
    "value": "Pasirmukti"
  },
  {
    "id": "3215172006",
    "district_id": "321517",
    "label": "Kalibuaya",
    "value": "Kalibuaya"
  },
  {
    "id": "3215172007",
    "district_id": "321517",
    "label": "Pasirkamuning",
    "value": "Pasirkamuning"
  },
  {
    "id": "3215172008",
    "district_id": "321517",
    "label": "Kalijaya",
    "value": "Kalijaya"
  },
  {
    "id": "3215172009",
    "district_id": "321517",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3215172010",
    "district_id": "321517",
    "label": "Cadas Kertajaya",
    "value": "Cadas Kertajaya"
  },
  {
    "id": "3215172011",
    "district_id": "321517",
    "label": "Cilewo",
    "value": "Cilewo"
  },
  {
    "id": "3215172012",
    "district_id": "321517",
    "label": "Ciwulan",
    "value": "Ciwulan"
  },
  {
    "id": "3215172013",
    "district_id": "321517",
    "label": "Linggarsari",
    "value": "Linggarsari"
  },
  {
    "id": "3215172014",
    "district_id": "321517",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3215182001",
    "district_id": "321518",
    "label": "Sukamerta",
    "value": "Sukamerta"
  },
  {
    "id": "3215182002",
    "district_id": "321518",
    "label": "Kutawargi",
    "value": "Kutawargi"
  },
  {
    "id": "3215182003",
    "district_id": "321518",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "3215182004",
    "district_id": "321518",
    "label": "Pasirkaliki",
    "value": "Pasirkaliki"
  },
  {
    "id": "3215182005",
    "district_id": "321518",
    "label": "Pasirawi",
    "value": "Pasirawi"
  },
  {
    "id": "3215182006",
    "district_id": "321518",
    "label": "Balongsari",
    "value": "Balongsari"
  },
  {
    "id": "3215182007",
    "district_id": "321518",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3215182008",
    "district_id": "321518",
    "label": "Sekarwangi",
    "value": "Sekarwangi"
  },
  {
    "id": "3215182009",
    "district_id": "321518",
    "label": "Purwamekar",
    "value": "Purwamekar"
  },
  {
    "id": "3215182010",
    "district_id": "321518",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3215182011",
    "district_id": "321518",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3215182012",
    "district_id": "321518",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3215182014",
    "district_id": "321518",
    "label": "Gombongsari",
    "value": "Gombongsari"
  },
  {
    "id": "3215192001",
    "district_id": "321519",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3215192002",
    "district_id": "321519",
    "label": "Ciwaringin",
    "value": "Ciwaringin"
  },
  {
    "id": "3215192003",
    "district_id": "321519",
    "label": "Waringinkarya",
    "value": "Waringinkarya"
  },
  {
    "id": "3215192004",
    "district_id": "321519",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3215192005",
    "district_id": "321519",
    "label": "Karangtanjung",
    "value": "Karangtanjung"
  },
  {
    "id": "3215192006",
    "district_id": "321519",
    "label": "Pasirtanjung",
    "value": "Pasirtanjung"
  },
  {
    "id": "3215192007",
    "district_id": "321519",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "3215192008",
    "district_id": "321519",
    "label": "Lemahmukti",
    "value": "Lemahmukti"
  },
  {
    "id": "3215192009",
    "district_id": "321519",
    "label": "Pulojaya",
    "value": "Pulojaya"
  },
  {
    "id": "3215192010",
    "district_id": "321519",
    "label": "Pulokalapa",
    "value": "Pulokalapa"
  },
  {
    "id": "3215192011",
    "district_id": "321519",
    "label": "Pulomulya",
    "value": "Pulomulya"
  },
  {
    "id": "3215202001",
    "district_id": "321520",
    "label": "Pancakarya",
    "value": "Pancakarya"
  },
  {
    "id": "3215202002",
    "district_id": "321520",
    "label": "Dayeuhluhur",
    "value": "Dayeuhluhur"
  },
  {
    "id": "3215202003",
    "district_id": "321520",
    "label": "Lemahduhur",
    "value": "Lemahduhur"
  },
  {
    "id": "3215202004",
    "district_id": "321520",
    "label": "Lemahkarya",
    "value": "Lemahkarya"
  },
  {
    "id": "3215202005",
    "district_id": "321520",
    "label": "Lemahsubur",
    "value": "Lemahsubur"
  },
  {
    "id": "3215202006",
    "district_id": "321520",
    "label": "Lemahmakmur",
    "value": "Lemahmakmur"
  },
  {
    "id": "3215202008",
    "district_id": "321520",
    "label": "Purwajaya",
    "value": "Purwajaya"
  },
  {
    "id": "3215202010",
    "district_id": "321520",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3215202011",
    "district_id": "321520",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3215202012",
    "district_id": "321520",
    "label": "Pagadungan",
    "value": "Pagadungan"
  },
  {
    "id": "3215202013",
    "district_id": "321520",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3215202014",
    "district_id": "321520",
    "label": "Cikuntul",
    "value": "Cikuntul"
  },
  {
    "id": "3215202016",
    "district_id": "321520",
    "label": "Jayanegara",
    "value": "Jayanegara"
  },
  {
    "id": "3215202017",
    "district_id": "321520",
    "label": "Ciparagejaya",
    "value": "Ciparagejaya"
  },
  {
    "id": "3215212001",
    "district_id": "321521",
    "label": "Majalaya",
    "value": "Majalaya"
  },
  {
    "id": "3215212002",
    "district_id": "321521",
    "label": "Ciranggon",
    "value": "Ciranggon"
  },
  {
    "id": "3215212003",
    "district_id": "321521",
    "label": "Pasirjengkol",
    "value": "Pasirjengkol"
  },
  {
    "id": "3215212005",
    "district_id": "321521",
    "label": "Sarijaya",
    "value": "Sarijaya"
  },
  {
    "id": "3215212006",
    "district_id": "321521",
    "label": "Pasirmulya",
    "value": "Pasirmulya"
  },
  {
    "id": "3215212007",
    "district_id": "321521",
    "label": "Lemahmulya",
    "value": "Lemahmulya"
  },
  {
    "id": "3215212008",
    "district_id": "321521",
    "label": "Bangle",
    "value": "Bangle"
  },
  {
    "id": "3215222001",
    "district_id": "321522",
    "label": "Jayamakmur",
    "value": "Jayamakmur"
  },
  {
    "id": "3215222002",
    "district_id": "321522",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3215222003",
    "district_id": "321522",
    "label": "Makmurjaya",
    "value": "Makmurjaya"
  },
  {
    "id": "3215222004",
    "district_id": "321522",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3215222005",
    "district_id": "321522",
    "label": "Jayakerta",
    "value": "Jayakerta"
  },
  {
    "id": "3215222006",
    "district_id": "321522",
    "label": "Medangasem",
    "value": "Medangasem"
  },
  {
    "id": "3215222007",
    "district_id": "321522",
    "label": "Ciptamarga",
    "value": "Ciptamarga"
  },
  {
    "id": "3215222008",
    "district_id": "321522",
    "label": "Kampungsawah",
    "value": "Kampungsawah"
  },
  {
    "id": "3215232001",
    "district_id": "321523",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3215232002",
    "district_id": "321523",
    "label": "Tegalurung",
    "value": "Tegalurung"
  },
  {
    "id": "3215232003",
    "district_id": "321523",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3215232004",
    "district_id": "321523",
    "label": "Bayur Lor",
    "value": "Bayur Lor"
  },
  {
    "id": "3215232005",
    "district_id": "321523",
    "label": "Bayur Kidul",
    "value": "Bayur Kidul"
  },
  {
    "id": "3215232006",
    "district_id": "321523",
    "label": "Kiara",
    "value": "Kiara"
  },
  {
    "id": "3215232007",
    "district_id": "321523",
    "label": "Manggungjaya",
    "value": "Manggungjaya"
  },
  {
    "id": "3215232008",
    "district_id": "321523",
    "label": "Sumurgede",
    "value": "Sumurgede"
  },
  {
    "id": "3215232009",
    "district_id": "321523",
    "label": "Muktijaya",
    "value": "Muktijaya"
  },
  {
    "id": "3215232010",
    "district_id": "321523",
    "label": "Pasirukem",
    "value": "Pasirukem"
  },
  {
    "id": "3215232011",
    "district_id": "321523",
    "label": "Pasirjaya",
    "value": "Pasirjaya"
  },
  {
    "id": "3215232012",
    "district_id": "321523",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3215242001",
    "district_id": "321524",
    "label": "Gembongan",
    "value": "Gembongan"
  },
  {
    "id": "3215242002",
    "district_id": "321524",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3215242003",
    "district_id": "321524",
    "label": "Gempol Kolot",
    "value": "Gempol Kolot"
  },
  {
    "id": "3215242004",
    "district_id": "321524",
    "label": "Banyuasih",
    "value": "Banyuasih"
  },
  {
    "id": "3215242005",
    "district_id": "321524",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3215242006",
    "district_id": "321524",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3215242007",
    "district_id": "321524",
    "label": "Jayamukti",
    "value": "Jayamukti"
  },
  {
    "id": "3215242008",
    "district_id": "321524",
    "label": "Cicinde Utara",
    "value": "Cicinde Utara"
  },
  {
    "id": "3215242009",
    "district_id": "321524",
    "label": "Cicinde Selatan",
    "value": "Cicinde Selatan"
  },
  {
    "id": "3215242010",
    "district_id": "321524",
    "label": "Mekarasih",
    "value": "Mekarasih"
  },
  {
    "id": "3215242011",
    "district_id": "321524",
    "label": "Talunjaya",
    "value": "Talunjaya"
  },
  {
    "id": "3215242012",
    "district_id": "321524",
    "label": "Pamekaran",
    "value": "Pamekaran"
  },
  {
    "id": "3215252001",
    "district_id": "321525",
    "label": "Wancimekar",
    "value": "Wancimekar"
  },
  {
    "id": "3215252002",
    "district_id": "321525",
    "label": "Pangulah Selatan",
    "value": "Pangulah Selatan"
  },
  {
    "id": "3215252003",
    "district_id": "321525",
    "label": "Pangulah Utara",
    "value": "Pangulah Utara"
  },
  {
    "id": "3215252004",
    "district_id": "321525",
    "label": "Pangulah Baru",
    "value": "Pangulah Baru"
  },
  {
    "id": "3215252005",
    "district_id": "321525",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3215252006",
    "district_id": "321525",
    "label": "Jomin Timur",
    "value": "Jomin Timur"
  },
  {
    "id": "3215252007",
    "district_id": "321525",
    "label": "Jomin Barat",
    "value": "Jomin Barat"
  },
  {
    "id": "3215252008",
    "district_id": "321525",
    "label": "Sarimulya",
    "value": "Sarimulya"
  },
  {
    "id": "3215252009",
    "district_id": "321525",
    "label": "Cilkampek Utara",
    "value": "Cilkampek Utara"
  },
  {
    "id": "3215261001",
    "district_id": "321526",
    "label": "Karawang Wetan",
    "value": "Karawang Wetan"
  },
  {
    "id": "3215261002",
    "district_id": "321526",
    "label": "Adiarsa Timur",
    "value": "Adiarsa Timur"
  },
  {
    "id": "3215261003",
    "district_id": "321526",
    "label": "Palumbonsari",
    "value": "Palumbonsari"
  },
  {
    "id": "3215261004",
    "district_id": "321526",
    "label": "Plawad",
    "value": "Plawad"
  },
  {
    "id": "3215262005",
    "district_id": "321526",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3215262006",
    "district_id": "321526",
    "label": "Warungbambu",
    "value": "Warungbambu"
  },
  {
    "id": "3215262007",
    "district_id": "321526",
    "label": "Kondangjaya",
    "value": "Kondangjaya"
  },
  {
    "id": "3215262008",
    "district_id": "321526",
    "label": "Tegal Sawah",
    "value": "Tegal Sawah"
  },
  {
    "id": "3215272001",
    "district_id": "321527",
    "label": "Margakaya",
    "value": "Margakaya"
  },
  {
    "id": "3215272002",
    "district_id": "321527",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3215272003",
    "district_id": "321527",
    "label": "Kalangligar",
    "value": "Kalangligar"
  },
  {
    "id": "3215272004",
    "district_id": "321527",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3215272005",
    "district_id": "321527",
    "label": "Parungsari",
    "value": "Parungsari"
  },
  {
    "id": "3215272006",
    "district_id": "321527",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3215272007",
    "district_id": "321527",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3215272008",
    "district_id": "321527",
    "label": "Wanakerta",
    "value": "Wanakerta"
  },
  {
    "id": "3215272009",
    "district_id": "321527",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3215272010",
    "district_id": "321527",
    "label": "Mulyajaya",
    "value": "Mulyajaya"
  },
  {
    "id": "3215282001",
    "district_id": "321528",
    "label": "Cigunungsari",
    "value": "Cigunungsari"
  },
  {
    "id": "3215282002",
    "district_id": "321528",
    "label": "Wargasetra",
    "value": "Wargasetra"
  },
  {
    "id": "3215282003",
    "district_id": "321528",
    "label": "Mekarbuana",
    "value": "Mekarbuana"
  },
  {
    "id": "3215282004",
    "district_id": "321528",
    "label": "Cintalaksana",
    "value": "Cintalaksana"
  },
  {
    "id": "3215282005",
    "district_id": "321528",
    "label": "Cintawargi",
    "value": "Cintawargi"
  },
  {
    "id": "3215282006",
    "district_id": "321528",
    "label": "Cintalanggeng",
    "value": "Cintalanggeng"
  },
  {
    "id": "3215282007",
    "district_id": "321528",
    "label": "Kutalanggeng",
    "value": "Kutalanggeng"
  },
  {
    "id": "3215282008",
    "district_id": "321528",
    "label": "Kutamaneuh",
    "value": "Kutamaneuh"
  },
  {
    "id": "3215282009",
    "district_id": "321528",
    "label": "Cipurwasari",
    "value": "Cipurwasari"
  },
  {
    "id": "3215292001",
    "district_id": "321529",
    "label": "Darawolong",
    "value": "Darawolong"
  },
  {
    "id": "3215292002",
    "district_id": "321529",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3215292003",
    "district_id": "321529",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3215292004",
    "district_id": "321529",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3215292005",
    "district_id": "321529",
    "label": "Tamelang",
    "value": "Tamelang"
  },
  {
    "id": "3215292006",
    "district_id": "321529",
    "label": "Cengkong",
    "value": "Cengkong"
  },
  {
    "id": "3215292007",
    "district_id": "321529",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3215292008",
    "district_id": "321529",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3215302001",
    "district_id": "321530",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3215302002",
    "district_id": "321530",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "3215302003",
    "district_id": "321530",
    "label": "Pusakajaya Selatan",
    "value": "Pusakajaya Selatan"
  },
  {
    "id": "3215302004",
    "district_id": "321530",
    "label": "Cikande",
    "value": "Cikande"
  },
  {
    "id": "3215302005",
    "district_id": "321530",
    "label": "Kosambibatu",
    "value": "Kosambibatu"
  },
  {
    "id": "3215302006",
    "district_id": "321530",
    "label": "Pusakajaya Utara",
    "value": "Pusakajaya Utara"
  },
  {
    "id": "3215302007",
    "district_id": "321530",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3215302008",
    "district_id": "321530",
    "label": "Ciptamargi",
    "value": "Ciptamargi"
  },
  {
    "id": "3215302009",
    "district_id": "321530",
    "label": "Mekarpohaci",
    "value": "Mekarpohaci"
  },
  {
    "id": "3215302010",
    "district_id": "321530",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3216012001",
    "district_id": "321601",
    "label": "Sagara Makmur",
    "value": "Sagara Makmur"
  },
  {
    "id": "3216012002",
    "district_id": "321601",
    "label": "Segarajaya",
    "value": "Segarajaya"
  },
  {
    "id": "3216012003",
    "district_id": "321601",
    "label": "Pusaka Rakyat",
    "value": "Pusaka Rakyat"
  },
  {
    "id": "3216012004",
    "district_id": "321601",
    "label": "Pahlawan Setia",
    "value": "Pahlawan Setia"
  },
  {
    "id": "3216012005",
    "district_id": "321601",
    "label": "Setia Mulya",
    "value": "Setia Mulya"
  },
  {
    "id": "3216012006",
    "district_id": "321601",
    "label": "Samudra Jaya",
    "value": "Samudra Jaya"
  },
  {
    "id": "3216012007",
    "district_id": "321601",
    "label": "Setia Asih",
    "value": "Setia Asih"
  },
  {
    "id": "3216012008",
    "district_id": "321601",
    "label": "Pantai Makmur",
    "value": "Pantai Makmur"
  },
  {
    "id": "3216021006",
    "district_id": "321602",
    "label": "Bahagia",
    "value": "Bahagia"
  },
  {
    "id": "3216021007",
    "district_id": "321602",
    "label": "Kebalen",
    "value": "Kebalen"
  },
  {
    "id": "3216022001",
    "district_id": "321602",
    "label": "Bunibakti",
    "value": "Bunibakti"
  },
  {
    "id": "3216022002",
    "district_id": "321602",
    "label": "Muarabakti",
    "value": "Muarabakti"
  },
  {
    "id": "3216022003",
    "district_id": "321602",
    "label": "Kedung Pengawas",
    "value": "Kedung Pengawas"
  },
  {
    "id": "3216022004",
    "district_id": "321602",
    "label": "Hurip Jaya",
    "value": "Hurip Jaya"
  },
  {
    "id": "3216022005",
    "district_id": "321602",
    "label": "Pantaihurip",
    "value": "Pantaihurip"
  },
  {
    "id": "3216022008",
    "district_id": "321602",
    "label": "Babelankota",
    "value": "Babelankota"
  },
  {
    "id": "3216022009",
    "district_id": "321602",
    "label": "Kedungjaya",
    "value": "Kedungjaya"
  },
  {
    "id": "3216032001",
    "district_id": "321603",
    "label": "Sukaringin",
    "value": "Sukaringin"
  },
  {
    "id": "3216032002",
    "district_id": "321603",
    "label": "Sukabudi",
    "value": "Sukabudi"
  },
  {
    "id": "3216032003",
    "district_id": "321603",
    "label": "Sukadaya",
    "value": "Sukadaya"
  },
  {
    "id": "3216032004",
    "district_id": "321603",
    "label": "Sukawangi",
    "value": "Sukawangi"
  },
  {
    "id": "3216032005",
    "district_id": "321603",
    "label": "Sukakerta",
    "value": "Sukakerta"
  },
  {
    "id": "3216032006",
    "district_id": "321603",
    "label": "Sukatenang",
    "value": "Sukatenang"
  },
  {
    "id": "3216032007",
    "district_id": "321603",
    "label": "Sukamekar",
    "value": "Sukamekar"
  },
  {
    "id": "3216042001",
    "district_id": "321604",
    "label": "Sukawijaya",
    "value": "Sukawijaya"
  },
  {
    "id": "3216042002",
    "district_id": "321604",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3216042003",
    "district_id": "321604",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3216042004",
    "district_id": "321604",
    "label": "Sukarapih",
    "value": "Sukarapih"
  },
  {
    "id": "3216042005",
    "district_id": "321604",
    "label": "Sukarahayu",
    "value": "Sukarahayu"
  },
  {
    "id": "3216042006",
    "district_id": "321604",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3216042007",
    "district_id": "321604",
    "label": "Sukabakti",
    "value": "Sukabakti"
  },
  {
    "id": "3216052001",
    "district_id": "321605",
    "label": "Satriajaya",
    "value": "Satriajaya"
  },
  {
    "id": "3216052002",
    "district_id": "321605",
    "label": "Jejalenjaya",
    "value": "Jejalenjaya"
  },
  {
    "id": "3216052003",
    "district_id": "321605",
    "label": "Satriamekar",
    "value": "Satriamekar"
  },
  {
    "id": "3216052004",
    "district_id": "321605",
    "label": "Sriamur",
    "value": "Sriamur"
  },
  {
    "id": "3216052005",
    "district_id": "321605",
    "label": "Srimukti",
    "value": "Srimukti"
  },
  {
    "id": "3216052006",
    "district_id": "321605",
    "label": "Srijaya",
    "value": "Srijaya"
  },
  {
    "id": "3216052007",
    "district_id": "321605",
    "label": "Srimahi",
    "value": "Srimahi"
  },
  {
    "id": "3216052008",
    "district_id": "321605",
    "label": "Karangsatria",
    "value": "Karangsatria"
  },
  {
    "id": "3216061001",
    "district_id": "321606",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3216062002",
    "district_id": "321606",
    "label": "Lambangsari",
    "value": "Lambangsari"
  },
  {
    "id": "3216062003",
    "district_id": "321606",
    "label": "Lambangjaya",
    "value": "Lambangjaya"
  },
  {
    "id": "3216062004",
    "district_id": "321606",
    "label": "Tambun",
    "value": "Tambun"
  },
  {
    "id": "3216062005",
    "district_id": "321606",
    "label": "Setiadarma",
    "value": "Setiadarma"
  },
  {
    "id": "3216062006",
    "district_id": "321606",
    "label": "Setiamekar",
    "value": "Setiamekar"
  },
  {
    "id": "3216062007",
    "district_id": "321606",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3216062008",
    "district_id": "321606",
    "label": "Tridaya Sakti",
    "value": "Tridaya Sakti"
  },
  {
    "id": "3216062009",
    "district_id": "321606",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "3216062010",
    "district_id": "321606",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3216071001",
    "district_id": "321607",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3216072002",
    "district_id": "321607",
    "label": "Wanajaya",
    "value": "Wanajaya"
  },
  {
    "id": "3216072003",
    "district_id": "321607",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3216072004",
    "district_id": "321607",
    "label": "Muktiwari",
    "value": "Muktiwari"
  },
  {
    "id": "3216072005",
    "district_id": "321607",
    "label": "Sarimukti",
    "value": "Sarimukti"
  },
  {
    "id": "3216072006",
    "district_id": "321607",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3216072007",
    "district_id": "321607",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3216081008",
    "district_id": "321608",
    "label": "Telaga Asih",
    "value": "Telaga Asih"
  },
  {
    "id": "3216082001",
    "district_id": "321608",
    "label": "Telagamurni",
    "value": "Telagamurni"
  },
  {
    "id": "3216082002",
    "district_id": "321608",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3216082003",
    "district_id": "321608",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "3216082004",
    "district_id": "321608",
    "label": "Danau Indah",
    "value": "Danau Indah"
  },
  {
    "id": "3216082005",
    "district_id": "321608",
    "label": "Gandamekar",
    "value": "Gandamekar"
  },
  {
    "id": "3216082006",
    "district_id": "321608",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "3216082007",
    "district_id": "321608",
    "label": "Sukadanau",
    "value": "Sukadanau"
  },
  {
    "id": "3216082009",
    "district_id": "321608",
    "label": "Kalijaya",
    "value": "Kalijaya"
  },
  {
    "id": "3216082010",
    "district_id": "321608",
    "label": "Telajung",
    "value": "Telajung"
  },
  {
    "id": "3216082011",
    "district_id": "321608",
    "label": "Cikedokan",
    "value": "Cikedokan"
  },
  {
    "id": "3216092001",
    "district_id": "321609",
    "label": "Cikarangkota",
    "value": "Cikarangkota"
  },
  {
    "id": "3216092002",
    "district_id": "321609",
    "label": "Karangbaru",
    "value": "Karangbaru"
  },
  {
    "id": "3216092003",
    "district_id": "321609",
    "label": "Karangasih",
    "value": "Karangasih"
  },
  {
    "id": "3216092004",
    "district_id": "321609",
    "label": "Waluya",
    "value": "Waluya"
  },
  {
    "id": "3216092005",
    "district_id": "321609",
    "label": "Karangraharja",
    "value": "Karangraharja"
  },
  {
    "id": "3216092006",
    "district_id": "321609",
    "label": "Pasirgombong",
    "value": "Pasirgombong"
  },
  {
    "id": "3216092007",
    "district_id": "321609",
    "label": "Simpangan",
    "value": "Simpangan"
  },
  {
    "id": "3216092008",
    "district_id": "321609",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3216092009",
    "district_id": "321609",
    "label": "Hajarmekar",
    "value": "Hajarmekar"
  },
  {
    "id": "3216092010",
    "district_id": "321609",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3216092011",
    "district_id": "321609",
    "label": "Wangunharja",
    "value": "Wangunharja"
  },
  {
    "id": "3216102001",
    "district_id": "321610",
    "label": "Sukaraya",
    "value": "Sukaraya"
  },
  {
    "id": "3216102002",
    "district_id": "321610",
    "label": "Karangrahayu",
    "value": "Karangrahayu"
  },
  {
    "id": "3216102003",
    "district_id": "321610",
    "label": "Karangsetia",
    "value": "Karangsetia"
  },
  {
    "id": "3216102004",
    "district_id": "321610",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3216102005",
    "district_id": "321610",
    "label": "Karangbahagia",
    "value": "Karangbahagia"
  },
  {
    "id": "3216102006",
    "district_id": "321610",
    "label": "Karangsentosa",
    "value": "Karangsentosa"
  },
  {
    "id": "3216102007",
    "district_id": "321610",
    "label": "Karangsatu",
    "value": "Karangsatu"
  },
  {
    "id": "3216102008",
    "district_id": "321610",
    "label": "Karangmukti",
    "value": "Karangmukti"
  },
  {
    "id": "3216111007",
    "district_id": "321611",
    "label": "Sertajaya",
    "value": "Sertajaya"
  },
  {
    "id": "3216112001",
    "district_id": "321611",
    "label": "Tanjungbaru",
    "value": "Tanjungbaru"
  },
  {
    "id": "3216112002",
    "district_id": "321611",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "3216112003",
    "district_id": "321611",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3216112004",
    "district_id": "321611",
    "label": "Jatireja",
    "value": "Jatireja"
  },
  {
    "id": "3216112005",
    "district_id": "321611",
    "label": "Jatibaru",
    "value": "Jatibaru"
  },
  {
    "id": "3216112006",
    "district_id": "321611",
    "label": "Labansari",
    "value": "Labansari"
  },
  {
    "id": "3216112008",
    "district_id": "321611",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3216122001",
    "district_id": "321612",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "3216122002",
    "district_id": "321612",
    "label": "Waringinjaya",
    "value": "Waringinjaya"
  },
  {
    "id": "3216122003",
    "district_id": "321612",
    "label": "Karangmekar",
    "value": "Karangmekar"
  },
  {
    "id": "3216122004",
    "district_id": "321612",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3216122005",
    "district_id": "321612",
    "label": "Karangharum",
    "value": "Karangharum"
  },
  {
    "id": "3216122006",
    "district_id": "321612",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3216122007",
    "district_id": "321612",
    "label": "Kedungwaringin",
    "value": "Kedungwaringin"
  },
  {
    "id": "3216131011",
    "district_id": "321613",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3216132001",
    "district_id": "321613",
    "label": "Karangharja",
    "value": "Karangharja"
  },
  {
    "id": "3216132002",
    "district_id": "321613",
    "label": "Karangsegar",
    "value": "Karangsegar"
  },
  {
    "id": "3216132003",
    "district_id": "321613",
    "label": "Sumberurip",
    "value": "Sumberurip"
  },
  {
    "id": "3216132004",
    "district_id": "321613",
    "label": "Sumbereja",
    "value": "Sumbereja"
  },
  {
    "id": "3216132005",
    "district_id": "321613",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3216132006",
    "district_id": "321613",
    "label": "Karangjaya",
    "value": "Karangjaya"
  },
  {
    "id": "3216132007",
    "district_id": "321613",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3216132008",
    "district_id": "321613",
    "label": "Karangpatri",
    "value": "Karangpatri"
  },
  {
    "id": "3216132009",
    "district_id": "321613",
    "label": "Karanghaur",
    "value": "Karanghaur"
  },
  {
    "id": "3216132010",
    "district_id": "321613",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3216132012",
    "district_id": "321613",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3216132013",
    "district_id": "321613",
    "label": "Bantarjaya",
    "value": "Bantarjaya"
  },
  {
    "id": "3216142001",
    "district_id": "321614",
    "label": "Sukamurni",
    "value": "Sukamurni"
  },
  {
    "id": "3216142002",
    "district_id": "321614",
    "label": "Sukaindah",
    "value": "Sukaindah"
  },
  {
    "id": "3216142003",
    "district_id": "321614",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3216142004",
    "district_id": "321614",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3216142005",
    "district_id": "321614",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3216142006",
    "district_id": "321614",
    "label": "Sukakarsa",
    "value": "Sukakarsa"
  },
  {
    "id": "3216142007",
    "district_id": "321614",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "3216152001",
    "district_id": "321615",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3216152002",
    "district_id": "321615",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3216152003",
    "district_id": "321615",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3216152004",
    "district_id": "321615",
    "label": "Sukaasih",
    "value": "Sukaasih"
  },
  {
    "id": "3216152005",
    "district_id": "321615",
    "label": "Sukarukun",
    "value": "Sukarukun"
  },
  {
    "id": "3216152006",
    "district_id": "321615",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3216152007",
    "district_id": "321615",
    "label": "Sukadarma",
    "value": "Sukadarma"
  },
  {
    "id": "3216162001",
    "district_id": "321616",
    "label": "Jayabakti",
    "value": "Jayabakti"
  },
  {
    "id": "3216162002",
    "district_id": "321616",
    "label": "Jayalaksana",
    "value": "Jayalaksana"
  },
  {
    "id": "3216162003",
    "district_id": "321616",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3216162004",
    "district_id": "321616",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3216162005",
    "district_id": "321616",
    "label": "Setialaksana",
    "value": "Setialaksana"
  },
  {
    "id": "3216162006",
    "district_id": "321616",
    "label": "Setiajaya",
    "value": "Setiajaya"
  },
  {
    "id": "3216162007",
    "district_id": "321616",
    "label": "Lenggahjaya",
    "value": "Lenggahjaya"
  },
  {
    "id": "3216162008",
    "district_id": "321616",
    "label": "Lenggahsari",
    "value": "Lenggahsari"
  },
  {
    "id": "3216172001",
    "district_id": "321617",
    "label": "Pantai Mekar",
    "value": "Pantai Mekar"
  },
  {
    "id": "3216172002",
    "district_id": "321617",
    "label": "Pantai Bahagia",
    "value": "Pantai Bahagia"
  },
  {
    "id": "3216172003",
    "district_id": "321617",
    "label": "Pantai Bakti",
    "value": "Pantai Bakti"
  },
  {
    "id": "3216172004",
    "district_id": "321617",
    "label": "Pantai Harapanjaya",
    "value": "Pantai Harapanjaya"
  },
  {
    "id": "3216172005",
    "district_id": "321617",
    "label": "Pantai Sederhana",
    "value": "Pantai Sederhana"
  },
  {
    "id": "3216172006",
    "district_id": "321617",
    "label": "Jayasakti",
    "value": "Jayasakti"
  },
  {
    "id": "3216182001",
    "district_id": "321618",
    "label": "Cijengkol",
    "value": "Cijengkol"
  },
  {
    "id": "3216182002",
    "district_id": "321618",
    "label": "Lubangbuaya",
    "value": "Lubangbuaya"
  },
  {
    "id": "3216182003",
    "district_id": "321618",
    "label": "Burangkeng",
    "value": "Burangkeng"
  },
  {
    "id": "3216182004",
    "district_id": "321618",
    "label": "Ciledug",
    "value": "Ciledug"
  },
  {
    "id": "3216182005",
    "district_id": "321618",
    "label": "Cibening",
    "value": "Cibening"
  },
  {
    "id": "3216182006",
    "district_id": "321618",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "3216182007",
    "district_id": "321618",
    "label": "Taman Rahayu",
    "value": "Taman Rahayu"
  },
  {
    "id": "3216182008",
    "district_id": "321618",
    "label": "Cikarageman",
    "value": "Cikarageman"
  },
  {
    "id": "3216182009",
    "district_id": "321618",
    "label": "Ragamanunggal",
    "value": "Ragamanunggal"
  },
  {
    "id": "3216182010",
    "district_id": "321618",
    "label": "Muktijaya",
    "value": "Muktijaya"
  },
  {
    "id": "3216182011",
    "district_id": "321618",
    "label": "Kertarahayu",
    "value": "Kertarahayu"
  },
  {
    "id": "3216192001",
    "district_id": "321619",
    "label": "Cibatu",
    "value": "Cibatu"
  },
  {
    "id": "3216192002",
    "district_id": "321619",
    "label": "Sukasejati",
    "value": "Sukasejati"
  },
  {
    "id": "3216192003",
    "district_id": "321619",
    "label": "Ciantra",
    "value": "Ciantra"
  },
  {
    "id": "3216192004",
    "district_id": "321619",
    "label": "Sukadami",
    "value": "Sukadami"
  },
  {
    "id": "3216192005",
    "district_id": "321619",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3216192006",
    "district_id": "321619",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3216192007",
    "district_id": "321619",
    "label": "Pasirsari",
    "value": "Pasirsari"
  },
  {
    "id": "3216202001",
    "district_id": "321620",
    "label": "Cicau",
    "value": "Cicau"
  },
  {
    "id": "3216202002",
    "district_id": "321620",
    "label": "Sukamahi",
    "value": "Sukamahi"
  },
  {
    "id": "3216202003",
    "district_id": "321620",
    "label": "Pasiranji",
    "value": "Pasiranji"
  },
  {
    "id": "3216202004",
    "district_id": "321620",
    "label": "Hegarmukti",
    "value": "Hegarmukti"
  },
  {
    "id": "3216202005",
    "district_id": "321620",
    "label": "Jayamukti",
    "value": "Jayamukti"
  },
  {
    "id": "3216202006",
    "district_id": "321620",
    "label": "Pasirtanjung",
    "value": "Pasirtanjung"
  },
  {
    "id": "3216212001",
    "district_id": "321621",
    "label": "Sukaragam",
    "value": "Sukaragam"
  },
  {
    "id": "3216212002",
    "district_id": "321621",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3216212003",
    "district_id": "321621",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3216212004",
    "district_id": "321621",
    "label": "Jayamulya",
    "value": "Jayamulya"
  },
  {
    "id": "3216212005",
    "district_id": "321621",
    "label": "Nagacipta",
    "value": "Nagacipta"
  },
  {
    "id": "3216212006",
    "district_id": "321621",
    "label": "Nagasari",
    "value": "Nagasari"
  },
  {
    "id": "3216212007",
    "district_id": "321621",
    "label": "Cilangkara",
    "value": "Cilangkara"
  },
  {
    "id": "3216212008",
    "district_id": "321621",
    "label": "Jayasampurna",
    "value": "Jayasampurna"
  },
  {
    "id": "3216222001",
    "district_id": "321622",
    "label": "Sirnajati",
    "value": "Sirnajati"
  },
  {
    "id": "3216222002",
    "district_id": "321622",
    "label": "Ridogalih",
    "value": "Ridogalih"
  },
  {
    "id": "3216222003",
    "district_id": "321622",
    "label": "Ridomanah",
    "value": "Ridomanah"
  },
  {
    "id": "3216222004",
    "district_id": "321622",
    "label": "Wibawamulya",
    "value": "Wibawamulya"
  },
  {
    "id": "3216222005",
    "district_id": "321622",
    "label": "Cibarusah Kota",
    "value": "Cibarusah Kota"
  },
  {
    "id": "3216222006",
    "district_id": "321622",
    "label": "Cibarusah Jaya",
    "value": "Cibarusah Jaya"
  },
  {
    "id": "3216222007",
    "district_id": "321622",
    "label": "Sindangmulya",
    "value": "Sindangmulya"
  },
  {
    "id": "3216232001",
    "district_id": "321623",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3216232002",
    "district_id": "321623",
    "label": "Karangindah",
    "value": "Karangindah"
  },
  {
    "id": "3216232003",
    "district_id": "321623",
    "label": "Bojongmangu",
    "value": "Bojongmangu"
  },
  {
    "id": "3216232004",
    "district_id": "321623",
    "label": "Sukabungah",
    "value": "Sukabungah"
  },
  {
    "id": "3216232005",
    "district_id": "321623",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3216232006",
    "district_id": "321623",
    "label": "Medalkrisna",
    "value": "Medalkrisna"
  },
  {
    "id": "3217012001",
    "district_id": "321701",
    "label": "Pagerwangi",
    "value": "Pagerwangi"
  },
  {
    "id": "3217012002",
    "district_id": "321701",
    "label": "Kayuambon",
    "value": "Kayuambon"
  },
  {
    "id": "3217012003",
    "district_id": "321701",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "3217012004",
    "district_id": "321701",
    "label": "Cikidang",
    "value": "Cikidang"
  },
  {
    "id": "3217012005",
    "district_id": "321701",
    "label": "Cikahuripan",
    "value": "Cikahuripan"
  },
  {
    "id": "3217012006",
    "district_id": "321701",
    "label": "Cikole",
    "value": "Cikole"
  },
  {
    "id": "3217012007",
    "district_id": "321701",
    "label": "Gudangkahuripan",
    "value": "Gudangkahuripan"
  },
  {
    "id": "3217012008",
    "district_id": "321701",
    "label": "Jayagiri",
    "value": "Jayagiri"
  },
  {
    "id": "3217012009",
    "district_id": "321701",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3217012010",
    "district_id": "321701",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3217012011",
    "district_id": "321701",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3217012012",
    "district_id": "321701",
    "label": "Cibogo",
    "value": "Cibogo"
  },
  {
    "id": "3217012013",
    "district_id": "321701",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3217012014",
    "district_id": "321701",
    "label": "Suntenjaya",
    "value": "Suntenjaya"
  },
  {
    "id": "3217012015",
    "district_id": "321701",
    "label": "Wangunharja",
    "value": "Wangunharja"
  },
  {
    "id": "3217012016",
    "district_id": "321701",
    "label": "Wangunsari",
    "value": "Wangunsari"
  },
  {
    "id": "3217022001",
    "district_id": "321702",
    "label": "Karyawangi",
    "value": "Karyawangi"
  },
  {
    "id": "3217022002",
    "district_id": "321702",
    "label": "Cihanjuang",
    "value": "Cihanjuang"
  },
  {
    "id": "3217022003",
    "district_id": "321702",
    "label": "Cihanjuangrahayu",
    "value": "Cihanjuangrahayu"
  },
  {
    "id": "3217022004",
    "district_id": "321702",
    "label": "Cihideung",
    "value": "Cihideung"
  },
  {
    "id": "3217022005",
    "district_id": "321702",
    "label": "Ciwaruga",
    "value": "Ciwaruga"
  },
  {
    "id": "3217022006",
    "district_id": "321702",
    "label": "Cigugurgirang",
    "value": "Cigugurgirang"
  },
  {
    "id": "3217022007",
    "district_id": "321702",
    "label": "Sariwangi",
    "value": "Sariwangi"
  },
  {
    "id": "3217032001",
    "district_id": "321703",
    "label": "Jambudipa",
    "value": "Jambudipa"
  },
  {
    "id": "3217032002",
    "district_id": "321703",
    "label": "Padaasih",
    "value": "Padaasih"
  },
  {
    "id": "3217032003",
    "district_id": "321703",
    "label": "Pasirhalang",
    "value": "Pasirhalang"
  },
  {
    "id": "3217032004",
    "district_id": "321703",
    "label": "Pasirlangu",
    "value": "Pasirlangu"
  },
  {
    "id": "3217032005",
    "district_id": "321703",
    "label": "Cipada",
    "value": "Cipada"
  },
  {
    "id": "3217032006",
    "district_id": "321703",
    "label": "Kertawangi",
    "value": "Kertawangi"
  },
  {
    "id": "3217032007",
    "district_id": "321703",
    "label": "Tugumukti",
    "value": "Tugumukti"
  },
  {
    "id": "3217032008",
    "district_id": "321703",
    "label": "Sadangmekar",
    "value": "Sadangmekar"
  },
  {
    "id": "3217042001",
    "district_id": "321704",
    "label": "Ciptagumati",
    "value": "Ciptagumati"
  },
  {
    "id": "3217042002",
    "district_id": "321704",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3217042003",
    "district_id": "321704",
    "label": "Cipada",
    "value": "Cipada"
  },
  {
    "id": "3217042004",
    "district_id": "321704",
    "label": "Cisomangbarat",
    "value": "Cisomangbarat"
  },
  {
    "id": "3217042005",
    "district_id": "321704",
    "label": "Ganjarsari",
    "value": "Ganjarsari"
  },
  {
    "id": "3217042006",
    "district_id": "321704",
    "label": "Kanangasari",
    "value": "Kanangasari"
  },
  {
    "id": "3217042007",
    "district_id": "321704",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3217042008",
    "district_id": "321704",
    "label": "Mandalamukti",
    "value": "Mandalamukti"
  },
  {
    "id": "3217042009",
    "district_id": "321704",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3217042010",
    "district_id": "321704",
    "label": "Puteran",
    "value": "Puteran"
  },
  {
    "id": "3217042011",
    "district_id": "321704",
    "label": "Rende",
    "value": "Rende"
  },
  {
    "id": "3217042012",
    "district_id": "321704",
    "label": "Tenjolaut",
    "value": "Tenjolaut"
  },
  {
    "id": "3217042013",
    "district_id": "321704",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3217052001",
    "district_id": "321705",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3217052002",
    "district_id": "321705",
    "label": "Ciharashas",
    "value": "Ciharashas"
  },
  {
    "id": "3217052003",
    "district_id": "321705",
    "label": "Bojongmekar",
    "value": "Bojongmekar"
  },
  {
    "id": "3217052004",
    "district_id": "321705",
    "label": "Ciroyom",
    "value": "Ciroyom"
  },
  {
    "id": "3217052005",
    "district_id": "321705",
    "label": "Jatimekar",
    "value": "Jatimekar"
  },
  {
    "id": "3217052006",
    "district_id": "321705",
    "label": "Margalaksana",
    "value": "Margalaksana"
  },
  {
    "id": "3217052007",
    "district_id": "321705",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3217052008",
    "district_id": "321705",
    "label": "Nanggeleng",
    "value": "Nanggeleng"
  },
  {
    "id": "3217052009",
    "district_id": "321705",
    "label": "Nyenang",
    "value": "Nyenang"
  },
  {
    "id": "3217052010",
    "district_id": "321705",
    "label": "Sirnaraja",
    "value": "Sirnaraja"
  },
  {
    "id": "3217052011",
    "district_id": "321705",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3217052012",
    "district_id": "321705",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3217062001",
    "district_id": "321706",
    "label": "Ngamprah",
    "value": "Ngamprah"
  },
  {
    "id": "3217062002",
    "district_id": "321706",
    "label": "Cimareme",
    "value": "Cimareme"
  },
  {
    "id": "3217062003",
    "district_id": "321706",
    "label": "Cilame",
    "value": "Cilame"
  },
  {
    "id": "3217062004",
    "district_id": "321706",
    "label": "Tanimulya",
    "value": "Tanimulya"
  },
  {
    "id": "3217062005",
    "district_id": "321706",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3217062006",
    "district_id": "321706",
    "label": "Bojongkoneng",
    "value": "Bojongkoneng"
  },
  {
    "id": "3217062007",
    "district_id": "321706",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3217062008",
    "district_id": "321706",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3217062009",
    "district_id": "321706",
    "label": "Gadobangkong",
    "value": "Gadobangkong"
  },
  {
    "id": "3217062010",
    "district_id": "321706",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3217062011",
    "district_id": "321706",
    "label": "Pakuhaji",
    "value": "Pakuhaji"
  },
  {
    "id": "3217072001",
    "district_id": "321707",
    "label": "Ciptaharja",
    "value": "Ciptaharja"
  },
  {
    "id": "3217072002",
    "district_id": "321707",
    "label": "Cipatat",
    "value": "Cipatat"
  },
  {
    "id": "3217072003",
    "district_id": "321707",
    "label": "Citatah",
    "value": "Citatah"
  },
  {
    "id": "3217072004",
    "district_id": "321707",
    "label": "Rajamandalakulon",
    "value": "Rajamandalakulon"
  },
  {
    "id": "3217072005",
    "district_id": "321707",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "3217072006",
    "district_id": "321707",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3217072007",
    "district_id": "321707",
    "label": "Nyalindung",
    "value": "Nyalindung"
  },
  {
    "id": "3217072008",
    "district_id": "321707",
    "label": "Gunungmasigit",
    "value": "Gunungmasigit"
  },
  {
    "id": "3217072009",
    "district_id": "321707",
    "label": "Cirawamekar",
    "value": "Cirawamekar"
  },
  {
    "id": "3217072010",
    "district_id": "321707",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3217072011",
    "district_id": "321707",
    "label": "Sumurbandung",
    "value": "Sumurbandung"
  },
  {
    "id": "3217072012",
    "district_id": "321707",
    "label": "Sarimukti",
    "value": "Sarimukti"
  },
  {
    "id": "3217082001",
    "district_id": "321708",
    "label": "Kertamulya",
    "value": "Kertamulya"
  },
  {
    "id": "3217082002",
    "district_id": "321708",
    "label": "Padalarang",
    "value": "Padalarang"
  },
  {
    "id": "3217082003",
    "district_id": "321708",
    "label": "Cimerang",
    "value": "Cimerang"
  },
  {
    "id": "3217082004",
    "district_id": "321708",
    "label": "Campaka Mekar",
    "value": "Campaka Mekar"
  },
  {
    "id": "3217082005",
    "district_id": "321708",
    "label": "Tagogapu",
    "value": "Tagogapu"
  },
  {
    "id": "3217082006",
    "district_id": "321708",
    "label": "Ciburuy",
    "value": "Ciburuy"
  },
  {
    "id": "3217082007",
    "district_id": "321708",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3217082008",
    "district_id": "321708",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3217082009",
    "district_id": "321708",
    "label": "Jayamekar",
    "value": "Jayamekar"
  },
  {
    "id": "3217082010",
    "district_id": "321708",
    "label": "Laksanamekar",
    "value": "Laksanamekar"
  },
  {
    "id": "3217092001",
    "district_id": "321709",
    "label": "Batujajar Timur",
    "value": "Batujajar Timur"
  },
  {
    "id": "3217092002",
    "district_id": "321709",
    "label": "Batujajar Barat",
    "value": "Batujajar Barat"
  },
  {
    "id": "3217092004",
    "district_id": "321709",
    "label": "Galanggang",
    "value": "Galanggang"
  },
  {
    "id": "3217092005",
    "district_id": "321709",
    "label": "Cangkorah",
    "value": "Cangkorah"
  },
  {
    "id": "3217092007",
    "district_id": "321709",
    "label": "Selacau",
    "value": "Selacau"
  },
  {
    "id": "3217092008",
    "district_id": "321709",
    "label": "Pangauban",
    "value": "Pangauban"
  },
  {
    "id": "3217092010",
    "district_id": "321709",
    "label": "Giriasih",
    "value": "Giriasih"
  },
  {
    "id": "3217102001",
    "district_id": "321710",
    "label": "Cipatik",
    "value": "Cipatik"
  },
  {
    "id": "3217102002",
    "district_id": "321710",
    "label": "Citapen",
    "value": "Citapen"
  },
  {
    "id": "3217102003",
    "district_id": "321710",
    "label": "Cihampelas",
    "value": "Cihampelas"
  },
  {
    "id": "3217102004",
    "district_id": "321710",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3217102005",
    "district_id": "321710",
    "label": "Mekarmukti",
    "value": "Mekarmukti"
  },
  {
    "id": "3217102006",
    "district_id": "321710",
    "label": "Pataruman",
    "value": "Pataruman"
  },
  {
    "id": "3217102007",
    "district_id": "321710",
    "label": "Situwangi",
    "value": "Situwangi"
  },
  {
    "id": "3217102008",
    "district_id": "321710",
    "label": "Singajaya",
    "value": "Singajaya"
  },
  {
    "id": "3217102009",
    "district_id": "321710",
    "label": "Tanjungwangi",
    "value": "Tanjungwangi"
  },
  {
    "id": "3217102010",
    "district_id": "321710",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3217112001",
    "district_id": "321711",
    "label": "Cililin",
    "value": "Cililin"
  },
  {
    "id": "3217112002",
    "district_id": "321711",
    "label": "Budiharja",
    "value": "Budiharja"
  },
  {
    "id": "3217112003",
    "district_id": "321711",
    "label": "Batulayang",
    "value": "Batulayang"
  },
  {
    "id": "3217112004",
    "district_id": "321711",
    "label": "Bongas",
    "value": "Bongas"
  },
  {
    "id": "3217112005",
    "district_id": "321711",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3217112006",
    "district_id": "321711",
    "label": "Karangtanjung",
    "value": "Karangtanjung"
  },
  {
    "id": "3217112007",
    "district_id": "321711",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3217112008",
    "district_id": "321711",
    "label": "Kidangpananjung",
    "value": "Kidangpananjung"
  },
  {
    "id": "3217112009",
    "district_id": "321711",
    "label": "Mukapayung",
    "value": "Mukapayung"
  },
  {
    "id": "3217112010",
    "district_id": "321711",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3217112011",
    "district_id": "321711",
    "label": "Rancapanggung",
    "value": "Rancapanggung"
  },
  {
    "id": "3217122001",
    "district_id": "321712",
    "label": "Sarinagen",
    "value": "Sarinagen"
  },
  {
    "id": "3217122002",
    "district_id": "321712",
    "label": "Baranangsiang",
    "value": "Baranangsiang"
  },
  {
    "id": "3217122003",
    "district_id": "321712",
    "label": "Citalem",
    "value": "Citalem"
  },
  {
    "id": "3217122004",
    "district_id": "321712",
    "label": "Cijenuk",
    "value": "Cijenuk"
  },
  {
    "id": "3217122005",
    "district_id": "321712",
    "label": "Cijambu",
    "value": "Cijambu"
  },
  {
    "id": "3217122006",
    "district_id": "321712",
    "label": "Cibenda",
    "value": "Cibenda"
  },
  {
    "id": "3217122007",
    "district_id": "321712",
    "label": "Cintaasih",
    "value": "Cintaasih"
  },
  {
    "id": "3217122008",
    "district_id": "321712",
    "label": "Cicangkanghilir",
    "value": "Cicangkanghilir"
  },
  {
    "id": "3217122009",
    "district_id": "321712",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3217122010",
    "district_id": "321712",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3217122011",
    "district_id": "321712",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3217122012",
    "district_id": "321712",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3217122013",
    "district_id": "321712",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3217122014",
    "district_id": "321712",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3217132001",
    "district_id": "321713",
    "label": "Cibedug",
    "value": "Cibedug"
  },
  {
    "id": "3217132002",
    "district_id": "321713",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3217132003",
    "district_id": "321713",
    "label": "Bojongsalam",
    "value": "Bojongsalam"
  },
  {
    "id": "3217132004",
    "district_id": "321713",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "3217132005",
    "district_id": "321713",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3217132006",
    "district_id": "321713",
    "label": "Cinengah",
    "value": "Cinengah"
  },
  {
    "id": "3217132007",
    "district_id": "321713",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3217132008",
    "district_id": "321713",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3217142001",
    "district_id": "321714",
    "label": "Cintakarya",
    "value": "Cintakarya"
  },
  {
    "id": "3217142002",
    "district_id": "321714",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "3217142003",
    "district_id": "321714",
    "label": "Buninagara",
    "value": "Buninagara"
  },
  {
    "id": "3217142004",
    "district_id": "321714",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3217142005",
    "district_id": "321714",
    "label": "Cicangkanggirang",
    "value": "Cicangkanggirang"
  },
  {
    "id": "3217142006",
    "district_id": "321714",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3217142007",
    "district_id": "321714",
    "label": "Pasirpogor",
    "value": "Pasirpogor"
  },
  {
    "id": "3217142008",
    "district_id": "321714",
    "label": "Puncaksari",
    "value": "Puncaksari"
  },
  {
    "id": "3217142009",
    "district_id": "321714",
    "label": "Rancasenggang",
    "value": "Rancasenggang"
  },
  {
    "id": "3217142010",
    "district_id": "321714",
    "label": "Weninggalih",
    "value": "Weninggalih"
  },
  {
    "id": "3217142011",
    "district_id": "321714",
    "label": "Wangunsari",
    "value": "Wangunsari"
  },
  {
    "id": "3217152001",
    "district_id": "321715",
    "label": "Sirnajaya",
    "value": "Sirnajaya"
  },
  {
    "id": "3217152002",
    "district_id": "321715",
    "label": "Gununghalu",
    "value": "Gununghalu"
  },
  {
    "id": "3217152003",
    "district_id": "321715",
    "label": "Bunijaya",
    "value": "Bunijaya"
  },
  {
    "id": "3217152004",
    "district_id": "321715",
    "label": "Celak",
    "value": "Celak"
  },
  {
    "id": "3217152005",
    "district_id": "321715",
    "label": "Cilangari",
    "value": "Cilangari"
  },
  {
    "id": "3217152006",
    "district_id": "321715",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3217152007",
    "district_id": "321715",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3217152008",
    "district_id": "321715",
    "label": "Tamanjaya",
    "value": "Tamanjaya"
  },
  {
    "id": "3217152009",
    "district_id": "321715",
    "label": "Wargasaluyu",
    "value": "Wargasaluyu"
  },
  {
    "id": "3217162001",
    "district_id": "321716",
    "label": "Cikande",
    "value": "Cikande"
  },
  {
    "id": "3217162002",
    "district_id": "321716",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3217162003",
    "district_id": "321716",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3217162004",
    "district_id": "321716",
    "label": "Bojonghaleuang",
    "value": "Bojonghaleuang"
  },
  {
    "id": "3217162005",
    "district_id": "321716",
    "label": "Cipangeran",
    "value": "Cipangeran"
  },
  {
    "id": "3217162006",
    "district_id": "321716",
    "label": "Saguling",
    "value": "Saguling"
  },
  {
    "id": "3218012001",
    "district_id": "321801",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "3218012002",
    "district_id": "321801",
    "label": "Karangbenda",
    "value": "Karangbenda"
  },
  {
    "id": "3218012003",
    "district_id": "321801",
    "label": "Ciliang",
    "value": "Ciliang"
  },
  {
    "id": "3218012004",
    "district_id": "321801",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3218012005",
    "district_id": "321801",
    "label": "Cintaratu",
    "value": "Cintaratu"
  },
  {
    "id": "3218012006",
    "district_id": "321801",
    "label": "Selasari",
    "value": "Selasari"
  },
  {
    "id": "3218012007",
    "district_id": "321801",
    "label": "Karangjaladri",
    "value": "Karangjaladri"
  },
  {
    "id": "3218012008",
    "district_id": "321801",
    "label": "Cibenda",
    "value": "Cibenda"
  },
  {
    "id": "3218012009",
    "district_id": "321801",
    "label": "Cintakarya",
    "value": "Cintakarya"
  },
  {
    "id": "3218012010",
    "district_id": "321801",
    "label": "Parakanmanggu",
    "value": "Parakanmanggu"
  },
  {
    "id": "3218022001",
    "district_id": "321802",
    "label": "Batukaras",
    "value": "Batukaras"
  },
  {
    "id": "3218022002",
    "district_id": "321802",
    "label": "Ciakar",
    "value": "Ciakar"
  },
  {
    "id": "3218022003",
    "district_id": "321802",
    "label": "Cibanten",
    "value": "Cibanten"
  },
  {
    "id": "3218022004",
    "district_id": "321802",
    "label": "Kertayasa",
    "value": "Kertayasa"
  },
  {
    "id": "3218022005",
    "district_id": "321802",
    "label": "Cijulang",
    "value": "Cijulang"
  },
  {
    "id": "3218022006",
    "district_id": "321802",
    "label": "Kondangjajar",
    "value": "Kondangjajar"
  },
  {
    "id": "3218022007",
    "district_id": "321802",
    "label": "Margacinta",
    "value": "Margacinta"
  },
  {
    "id": "3218032001",
    "district_id": "321803",
    "label": "Kertaharja",
    "value": "Kertaharja"
  },
  {
    "id": "3218032002",
    "district_id": "321803",
    "label": "Ciparanti",
    "value": "Ciparanti"
  },
  {
    "id": "3218032003",
    "district_id": "321803",
    "label": "Legokjawa",
    "value": "Legokjawa"
  },
  {
    "id": "3218032004",
    "district_id": "321803",
    "label": "Masawah",
    "value": "Masawah"
  },
  {
    "id": "3218032005",
    "district_id": "321803",
    "label": "Cimerak",
    "value": "Cimerak"
  },
  {
    "id": "3218032006",
    "district_id": "321803",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3218032007",
    "district_id": "321803",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3218032008",
    "district_id": "321803",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3218032009",
    "district_id": "321803",
    "label": "Batumalang",
    "value": "Batumalang"
  },
  {
    "id": "3218032010",
    "district_id": "321803",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3218032011",
    "district_id": "321803",
    "label": "Limusgede",
    "value": "Limusgede"
  },
  {
    "id": "3218042001",
    "district_id": "321804",
    "label": "Cigugur",
    "value": "Cigugur"
  },
  {
    "id": "3218042002",
    "district_id": "321804",
    "label": "Cimindi",
    "value": "Cimindi"
  },
  {
    "id": "3218042003",
    "district_id": "321804",
    "label": "Pagerbumi",
    "value": "Pagerbumi"
  },
  {
    "id": "3218042004",
    "district_id": "321804",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3218042005",
    "district_id": "321804",
    "label": "Bunisari",
    "value": "Bunisari"
  },
  {
    "id": "3218042006",
    "district_id": "321804",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3218042007",
    "district_id": "321804",
    "label": "Harumandala",
    "value": "Harumandala"
  },
  {
    "id": "3218052001",
    "district_id": "321805",
    "label": "Karangkamiri",
    "value": "Karangkamiri"
  },
  {
    "id": "3218052002",
    "district_id": "321805",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3218052003",
    "district_id": "321805",
    "label": "Langkaplancar",
    "value": "Langkaplancar"
  },
  {
    "id": "3218052004",
    "district_id": "321805",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3218052005",
    "district_id": "321805",
    "label": "Bojongkondang",
    "value": "Bojongkondang"
  },
  {
    "id": "3218052006",
    "district_id": "321805",
    "label": "Bangunjaya",
    "value": "Bangunjaya"
  },
  {
    "id": "3218052007",
    "district_id": "321805",
    "label": "Jayasari",
    "value": "Jayasari"
  },
  {
    "id": "3218052008",
    "district_id": "321805",
    "label": "Jadimulya",
    "value": "Jadimulya"
  },
  {
    "id": "3218052009",
    "district_id": "321805",
    "label": "Jadikarya",
    "value": "Jadikarya"
  },
  {
    "id": "3218052010",
    "district_id": "321805",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3218052011",
    "district_id": "321805",
    "label": "Bangunkarya",
    "value": "Bangunkarya"
  },
  {
    "id": "3218052012",
    "district_id": "321805",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3218052013",
    "district_id": "321805",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3218052014",
    "district_id": "321805",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3218052015",
    "district_id": "321805",
    "label": "Bungur Raya",
    "value": "Bungur Raya"
  },
  {
    "id": "3218062001",
    "district_id": "321806",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3218062002",
    "district_id": "321806",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3218062003",
    "district_id": "321806",
    "label": "Mangunjaya",
    "value": "Mangunjaya"
  },
  {
    "id": "3218062004",
    "district_id": "321806",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3218062005",
    "district_id": "321806",
    "label": "Jangraga",
    "value": "Jangraga"
  },
  {
    "id": "3218072001",
    "district_id": "321807",
    "label": "Panyutran",
    "value": "Panyutran"
  },
  {
    "id": "3218072002",
    "district_id": "321807",
    "label": "Ciganjeng",
    "value": "Ciganjeng"
  },
  {
    "id": "3218072003",
    "district_id": "321807",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3218072004",
    "district_id": "321807",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3218072005",
    "district_id": "321807",
    "label": "Paledah",
    "value": "Paledah"
  },
  {
    "id": "3218072006",
    "district_id": "321807",
    "label": "Karangpawitan",
    "value": "Karangpawitan"
  },
  {
    "id": "3218072007",
    "district_id": "321807",
    "label": "Padaherang",
    "value": "Padaherang"
  },
  {
    "id": "3218072008",
    "district_id": "321807",
    "label": "Kedungwuluh",
    "value": "Kedungwuluh"
  },
  {
    "id": "3218072009",
    "district_id": "321807",
    "label": "Cibogo",
    "value": "Cibogo"
  },
  {
    "id": "3218072010",
    "district_id": "321807",
    "label": "Pasirgeulis",
    "value": "Pasirgeulis"
  },
  {
    "id": "3218072011",
    "district_id": "321807",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3218072012",
    "district_id": "321807",
    "label": "Sindangwangi",
    "value": "Sindangwangi"
  },
  {
    "id": "3218072013",
    "district_id": "321807",
    "label": "Maruyungsari",
    "value": "Maruyungsari"
  },
  {
    "id": "3218072014",
    "district_id": "321807",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3218082001",
    "district_id": "321808",
    "label": "Putrapinggan",
    "value": "Putrapinggan"
  },
  {
    "id": "3218082002",
    "district_id": "321808",
    "label": "Emplak",
    "value": "Emplak"
  },
  {
    "id": "3218082003",
    "district_id": "321808",
    "label": "Bagolo",
    "value": "Bagolo"
  },
  {
    "id": "3218082004",
    "district_id": "321808",
    "label": "Pamotan",
    "value": "Pamotan"
  },
  {
    "id": "3218082005",
    "district_id": "321808",
    "label": "Kalipucang",
    "value": "Kalipucang"
  },
  {
    "id": "3218082006",
    "district_id": "321808",
    "label": "Cibuluh",
    "value": "Cibuluh"
  },
  {
    "id": "3218082007",
    "district_id": "321808",
    "label": "Banjarharja",
    "value": "Banjarharja"
  },
  {
    "id": "3218082008",
    "district_id": "321808",
    "label": "Tunggilis",
    "value": "Tunggilis"
  },
  {
    "id": "3218082009",
    "district_id": "321808",
    "label": "Ciparakan",
    "value": "Ciparakan"
  },
  {
    "id": "3218092001",
    "district_id": "321809",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3218092002",
    "district_id": "321809",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3218092003",
    "district_id": "321809",
    "label": "Purbahayu",
    "value": "Purbahayu"
  },
  {
    "id": "3218092004",
    "district_id": "321809",
    "label": "Pangandaran",
    "value": "Pangandaran"
  },
  {
    "id": "3218092005",
    "district_id": "321809",
    "label": "Wonoharjo",
    "value": "Wonoharjo"
  },
  {
    "id": "3218092006",
    "district_id": "321809",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3218092007",
    "district_id": "321809",
    "label": "Pananjung",
    "value": "Pananjung"
  },
  {
    "id": "3218092008",
    "district_id": "321809",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3218102001",
    "district_id": "321810",
    "label": "Sidamulih",
    "value": "Sidamulih"
  },
  {
    "id": "3218102002",
    "district_id": "321810",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3218102003",
    "district_id": "321810",
    "label": "Sukaresik",
    "value": "Sukaresik"
  },
  {
    "id": "3218102004",
    "district_id": "321810",
    "label": "Cikembulan",
    "value": "Cikembulan"
  },
  {
    "id": "3218102005",
    "district_id": "321810",
    "label": "Pajaten",
    "value": "Pajaten"
  },
  {
    "id": "3218102006",
    "district_id": "321810",
    "label": "Kersaratu",
    "value": "Kersaratu"
  },
  {
    "id": "3218102007",
    "district_id": "321810",
    "label": "Kalijati",
    "value": "Kalijati"
  },
  {
    "id": "3271011001",
    "district_id": "327101",
    "label": "Batu Tulis",
    "value": "Batu Tulis"
  },
  {
    "id": "3271011002",
    "district_id": "327101",
    "label": "Bondongan",
    "value": "Bondongan"
  },
  {
    "id": "3271011003",
    "district_id": "327101",
    "label": "Empang",
    "value": "Empang"
  },
  {
    "id": "3271011004",
    "district_id": "327101",
    "label": "Lawanggintung",
    "value": "Lawanggintung"
  },
  {
    "id": "3271011005",
    "district_id": "327101",
    "label": "Pamoyanan",
    "value": "Pamoyanan"
  },
  {
    "id": "3271011006",
    "district_id": "327101",
    "label": "Ranggamekar",
    "value": "Ranggamekar"
  },
  {
    "id": "3271011007",
    "district_id": "327101",
    "label": "Mulyaharja",
    "value": "Mulyaharja"
  },
  {
    "id": "3271011008",
    "district_id": "327101",
    "label": "Cikaret",
    "value": "Cikaret"
  },
  {
    "id": "3271011009",
    "district_id": "327101",
    "label": "Bojongkerta",
    "value": "Bojongkerta"
  },
  {
    "id": "3271011010",
    "district_id": "327101",
    "label": "Rancamaya",
    "value": "Rancamaya"
  },
  {
    "id": "3271011011",
    "district_id": "327101",
    "label": "Kertamaya",
    "value": "Kertamaya"
  },
  {
    "id": "3271011012",
    "district_id": "327101",
    "label": "Harjasari",
    "value": "Harjasari"
  },
  {
    "id": "3271011013",
    "district_id": "327101",
    "label": "Muarasari",
    "value": "Muarasari"
  },
  {
    "id": "3271011014",
    "district_id": "327101",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "3271011015",
    "district_id": "327101",
    "label": "Pakuan",
    "value": "Pakuan"
  },
  {
    "id": "3271011016",
    "district_id": "327101",
    "label": "Cipaku",
    "value": "Cipaku"
  },
  {
    "id": "3271021001",
    "district_id": "327102",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3271021002",
    "district_id": "327102",
    "label": "Baranangsiang",
    "value": "Baranangsiang"
  },
  {
    "id": "3271021003",
    "district_id": "327102",
    "label": "Katulampa",
    "value": "Katulampa"
  },
  {
    "id": "3271021004",
    "district_id": "327102",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3271021005",
    "district_id": "327102",
    "label": "Sindangrasa",
    "value": "Sindangrasa"
  },
  {
    "id": "3271021006",
    "district_id": "327102",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "3271031001",
    "district_id": "327103",
    "label": "Gudang",
    "value": "Gudang"
  },
  {
    "id": "3271031002",
    "district_id": "327103",
    "label": "Paledang",
    "value": "Paledang"
  },
  {
    "id": "3271031003",
    "district_id": "327103",
    "label": "Pabaton",
    "value": "Pabaton"
  },
  {
    "id": "3271031004",
    "district_id": "327103",
    "label": "Cibogor",
    "value": "Cibogor"
  },
  {
    "id": "3271031005",
    "district_id": "327103",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3271031006",
    "district_id": "327103",
    "label": "Sempur",
    "value": "Sempur"
  },
  {
    "id": "3271031007",
    "district_id": "327103",
    "label": "Tegallega",
    "value": "Tegallega"
  },
  {
    "id": "3271031008",
    "district_id": "327103",
    "label": "Babakan Pasar",
    "value": "Babakan Pasar"
  },
  {
    "id": "3271031009",
    "district_id": "327103",
    "label": "Panaragan",
    "value": "Panaragan"
  },
  {
    "id": "3271031010",
    "district_id": "327103",
    "label": "Ciwaringin",
    "value": "Ciwaringin"
  },
  {
    "id": "3271031011",
    "district_id": "327103",
    "label": "Kebon Kalapa",
    "value": "Kebon Kalapa"
  },
  {
    "id": "3271041001",
    "district_id": "327104",
    "label": "Menteng",
    "value": "Menteng"
  },
  {
    "id": "3271041002",
    "district_id": "327104",
    "label": "Sindang Barang",
    "value": "Sindang Barang"
  },
  {
    "id": "3271041003",
    "district_id": "327104",
    "label": "Bubulak",
    "value": "Bubulak"
  },
  {
    "id": "3271041004",
    "district_id": "327104",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3271041005",
    "district_id": "327104",
    "label": "Balumbang Jaya",
    "value": "Balumbang Jaya"
  },
  {
    "id": "3271041006",
    "district_id": "327104",
    "label": "Situ Gede",
    "value": "Situ Gede"
  },
  {
    "id": "3271041007",
    "district_id": "327104",
    "label": "Semplak",
    "value": "Semplak"
  },
  {
    "id": "3271041008",
    "district_id": "327104",
    "label": "Cilendek Barat",
    "value": "Cilendek Barat"
  },
  {
    "id": "3271041009",
    "district_id": "327104",
    "label": "Cilendek Timur",
    "value": "Cilendek Timur"
  },
  {
    "id": "3271041010",
    "district_id": "327104",
    "label": "Curug Mekar",
    "value": "Curug Mekar"
  },
  {
    "id": "3271041011",
    "district_id": "327104",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3271041012",
    "district_id": "327104",
    "label": "Pasir Jaya",
    "value": "Pasir Jaya"
  },
  {
    "id": "3271041013",
    "district_id": "327104",
    "label": "Pasir Kuda",
    "value": "Pasir Kuda"
  },
  {
    "id": "3271041014",
    "district_id": "327104",
    "label": "Pasir Mulya",
    "value": "Pasir Mulya"
  },
  {
    "id": "3271041015",
    "district_id": "327104",
    "label": "Gunung Batu",
    "value": "Gunung Batu"
  },
  {
    "id": "3271041016",
    "district_id": "327104",
    "label": "Loji",
    "value": "Loji"
  },
  {
    "id": "3271051001",
    "district_id": "327105",
    "label": "Bantarjati",
    "value": "Bantarjati"
  },
  {
    "id": "3271051002",
    "district_id": "327105",
    "label": "Tegal Gundil",
    "value": "Tegal Gundil"
  },
  {
    "id": "3271051003",
    "district_id": "327105",
    "label": "Kedung Halang",
    "value": "Kedung Halang"
  },
  {
    "id": "3271051004",
    "district_id": "327105",
    "label": "Ciparigi",
    "value": "Ciparigi"
  },
  {
    "id": "3271051005",
    "district_id": "327105",
    "label": "Cibuluh",
    "value": "Cibuluh"
  },
  {
    "id": "3271051006",
    "district_id": "327105",
    "label": "Ciluar",
    "value": "Ciluar"
  },
  {
    "id": "3271051007",
    "district_id": "327105",
    "label": "Tanah Baru",
    "value": "Tanah Baru"
  },
  {
    "id": "3271051008",
    "district_id": "327105",
    "label": "Cimahpar",
    "value": "Cimahpar"
  },
  {
    "id": "3271061001",
    "district_id": "327106",
    "label": "Tanah Sareal",
    "value": "Tanah Sareal"
  },
  {
    "id": "3271061002",
    "district_id": "327106",
    "label": "Kebon Pedes",
    "value": "Kebon Pedes"
  },
  {
    "id": "3271061003",
    "district_id": "327106",
    "label": "Kedung Badak",
    "value": "Kedung Badak"
  },
  {
    "id": "3271061004",
    "district_id": "327106",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3271061005",
    "district_id": "327106",
    "label": "Kedung Waringin",
    "value": "Kedung Waringin"
  },
  {
    "id": "3271061006",
    "district_id": "327106",
    "label": "Kedung Jaya",
    "value": "Kedung Jaya"
  },
  {
    "id": "3271061007",
    "district_id": "327106",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "3271061008",
    "district_id": "327106",
    "label": "Mekar Wangi",
    "value": "Mekar Wangi"
  },
  {
    "id": "3271061009",
    "district_id": "327106",
    "label": "Kencana",
    "value": "Kencana"
  },
  {
    "id": "3271061010",
    "district_id": "327106",
    "label": "Kayu Manis",
    "value": "Kayu Manis"
  },
  {
    "id": "3271061011",
    "district_id": "327106",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3272011001",
    "district_id": "327201",
    "label": "Gunung Puyuh",
    "value": "Gunung Puyuh"
  },
  {
    "id": "3272011002",
    "district_id": "327201",
    "label": "Karamat",
    "value": "Karamat"
  },
  {
    "id": "3272011003",
    "district_id": "327201",
    "label": "Sriwidari",
    "value": "Sriwidari"
  },
  {
    "id": "3272011004",
    "district_id": "327201",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3272021001",
    "district_id": "327202",
    "label": "Cikole",
    "value": "Cikole"
  },
  {
    "id": "3272021002",
    "district_id": "327202",
    "label": "Selabatu",
    "value": "Selabatu"
  },
  {
    "id": "3272021003",
    "district_id": "327202",
    "label": "Gunungparang",
    "value": "Gunungparang"
  },
  {
    "id": "3272021004",
    "district_id": "327202",
    "label": "Kebonjati",
    "value": "Kebonjati"
  },
  {
    "id": "3272021005",
    "district_id": "327202",
    "label": "Cisarua",
    "value": "Cisarua"
  },
  {
    "id": "3272021006",
    "district_id": "327202",
    "label": "Subangjaya",
    "value": "Subangjaya"
  },
  {
    "id": "3272031001",
    "district_id": "327203",
    "label": "Citamiang",
    "value": "Citamiang"
  },
  {
    "id": "3272031002",
    "district_id": "327203",
    "label": "Tipar",
    "value": "Tipar"
  },
  {
    "id": "3272031003",
    "district_id": "327203",
    "label": "Nanggeleng",
    "value": "Nanggeleng"
  },
  {
    "id": "3272031004",
    "district_id": "327203",
    "label": "Gedongpanjang",
    "value": "Gedongpanjang"
  },
  {
    "id": "3272031005",
    "district_id": "327203",
    "label": "Cikondang",
    "value": "Cikondang"
  },
  {
    "id": "3272041001",
    "district_id": "327204",
    "label": "Warudoyong",
    "value": "Warudoyong"
  },
  {
    "id": "3272041002",
    "district_id": "327204",
    "label": "Nyomplong",
    "value": "Nyomplong"
  },
  {
    "id": "3272041003",
    "district_id": "327204",
    "label": "Dayeuhluhur",
    "value": "Dayeuhluhur"
  },
  {
    "id": "3272041004",
    "district_id": "327204",
    "label": "Sukakarya",
    "value": "Sukakarya"
  },
  {
    "id": "3272041005",
    "district_id": "327204",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "3272051001",
    "district_id": "327205",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "3272051002",
    "district_id": "327205",
    "label": "Jayaraksa",
    "value": "Jayaraksa"
  },
  {
    "id": "3272051003",
    "district_id": "327205",
    "label": "Jayamekar",
    "value": "Jayamekar"
  },
  {
    "id": "3272051004",
    "district_id": "327205",
    "label": "Sudajaya Hilir",
    "value": "Sudajaya Hilir"
  },
  {
    "id": "3272061001",
    "district_id": "327206",
    "label": "Cipanengah",
    "value": "Cipanengah"
  },
  {
    "id": "3272061002",
    "district_id": "327206",
    "label": "Situmekar",
    "value": "Situmekar"
  },
  {
    "id": "3272061003",
    "district_id": "327206",
    "label": "Lembursitu",
    "value": "Lembursitu"
  },
  {
    "id": "3272061004",
    "district_id": "327206",
    "label": "Cikundul",
    "value": "Cikundul"
  },
  {
    "id": "3272061005",
    "district_id": "327206",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3272071001",
    "district_id": "327207",
    "label": "Cibeureum Hilir",
    "value": "Cibeureum Hilir"
  },
  {
    "id": "3272071002",
    "district_id": "327207",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3272071003",
    "district_id": "327207",
    "label": "Sindangpalay",
    "value": "Sindangpalay"
  },
  {
    "id": "3272071004",
    "district_id": "327207",
    "label": "Limusnunggal",
    "value": "Limusnunggal"
  },
  {
    "id": "3273011001",
    "district_id": "327301",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3273011002",
    "district_id": "327301",
    "label": "Gegerkalong",
    "value": "Gegerkalong"
  },
  {
    "id": "3273011003",
    "district_id": "327301",
    "label": "Isola",
    "value": "Isola"
  },
  {
    "id": "3273011004",
    "district_id": "327301",
    "label": "Sarijadi",
    "value": "Sarijadi"
  },
  {
    "id": "3273021001",
    "district_id": "327302",
    "label": "Cipaganti",
    "value": "Cipaganti"
  },
  {
    "id": "3273021002",
    "district_id": "327302",
    "label": "Lebak Gede",
    "value": "Lebak Gede"
  },
  {
    "id": "3273021003",
    "district_id": "327302",
    "label": "Sadang Serang",
    "value": "Sadang Serang"
  },
  {
    "id": "3273021004",
    "district_id": "327302",
    "label": "Dago",
    "value": "Dago"
  },
  {
    "id": "3273021005",
    "district_id": "327302",
    "label": "Sekeloa",
    "value": "Sekeloa"
  },
  {
    "id": "3273021006",
    "district_id": "327302",
    "label": "Lebak Siliwangi",
    "value": "Lebak Siliwangi"
  },
  {
    "id": "3273031001",
    "district_id": "327303",
    "label": "Babakan Ciparay",
    "value": "Babakan Ciparay"
  },
  {
    "id": "3273031002",
    "district_id": "327303",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3273031003",
    "district_id": "327303",
    "label": "Sukahaji",
    "value": "Sukahaji"
  },
  {
    "id": "3273031004",
    "district_id": "327303",
    "label": "Margahayu Utara",
    "value": "Margahayu Utara"
  },
  {
    "id": "3273031005",
    "district_id": "327303",
    "label": "Margasuka",
    "value": "Margasuka"
  },
  {
    "id": "3273031006",
    "district_id": "327303",
    "label": "Cirangrang",
    "value": "Cirangrang"
  },
  {
    "id": "3273041001",
    "district_id": "327304",
    "label": "Kopo",
    "value": "Kopo"
  },
  {
    "id": "3273041002",
    "district_id": "327304",
    "label": "Babakan Tarogong",
    "value": "Babakan Tarogong"
  },
  {
    "id": "3273041003",
    "district_id": "327304",
    "label": "Jamika",
    "value": "Jamika"
  },
  {
    "id": "3273041004",
    "district_id": "327304",
    "label": "Babakan Asih",
    "value": "Babakan Asih"
  },
  {
    "id": "3273041005",
    "district_id": "327304",
    "label": "Suka Asih",
    "value": "Suka Asih"
  },
  {
    "id": "3273051001",
    "district_id": "327305",
    "label": "Maleber",
    "value": "Maleber"
  },
  {
    "id": "3273051002",
    "district_id": "327305",
    "label": "Dungus Cariang",
    "value": "Dungus Cariang"
  },
  {
    "id": "3273051003",
    "district_id": "327305",
    "label": "Ciroyom",
    "value": "Ciroyom"
  },
  {
    "id": "3273051004",
    "district_id": "327305",
    "label": "Kebon Jeruk",
    "value": "Kebon Jeruk"
  },
  {
    "id": "3273051005",
    "district_id": "327305",
    "label": "Garuda",
    "value": "Garuda"
  },
  {
    "id": "3273051006",
    "district_id": "327305",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3273061001",
    "district_id": "327306",
    "label": "Husein Sastranegara",
    "value": "Husein Sastranegara"
  },
  {
    "id": "3273061002",
    "district_id": "327306",
    "label": "Arjuna",
    "value": "Arjuna"
  },
  {
    "id": "3273061003",
    "district_id": "327306",
    "label": "Pajajaran",
    "value": "Pajajaran"
  },
  {
    "id": "3273061004",
    "district_id": "327306",
    "label": "Pasirkaliki",
    "value": "Pasirkaliki"
  },
  {
    "id": "3273061005",
    "district_id": "327306",
    "label": "Pamoyanan",
    "value": "Pamoyanan"
  },
  {
    "id": "3273061006",
    "district_id": "327306",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3273071001",
    "district_id": "327307",
    "label": "Pasteur",
    "value": "Pasteur"
  },
  {
    "id": "3273071002",
    "district_id": "327307",
    "label": "Cipedes",
    "value": "Cipedes"
  },
  {
    "id": "3273071003",
    "district_id": "327307",
    "label": "Sukawarna",
    "value": "Sukawarna"
  },
  {
    "id": "3273071004",
    "district_id": "327307",
    "label": "Sukagalih",
    "value": "Sukagalih"
  },
  {
    "id": "3273071005",
    "district_id": "327307",
    "label": "Sukabungah",
    "value": "Sukabungah"
  },
  {
    "id": "3273081001",
    "district_id": "327308",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3273081002",
    "district_id": "327308",
    "label": "Ciumbuleuit",
    "value": "Ciumbuleuit"
  },
  {
    "id": "3273081003",
    "district_id": "327308",
    "label": "Ledeng",
    "value": "Ledeng"
  },
  {
    "id": "3273091001",
    "district_id": "327309",
    "label": "Cihapit",
    "value": "Cihapit"
  },
  {
    "id": "3273091002",
    "district_id": "327309",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "3273091003",
    "district_id": "327309",
    "label": "Citarum",
    "value": "Citarum"
  },
  {
    "id": "3273101001",
    "district_id": "327310",
    "label": "Karasak",
    "value": "Karasak"
  },
  {
    "id": "3273101002",
    "district_id": "327310",
    "label": "Nyengseret",
    "value": "Nyengseret"
  },
  {
    "id": "3273101003",
    "district_id": "327310",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3273101004",
    "district_id": "327310",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3273101005",
    "district_id": "327310",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3273101006",
    "district_id": "327310",
    "label": "Pelindung Hewan",
    "value": "Pelindung Hewan"
  },
  {
    "id": "3273111001",
    "district_id": "327311",
    "label": "Cigereleng",
    "value": "Cigereleng"
  },
  {
    "id": "3273111002",
    "district_id": "327311",
    "label": "Ancol",
    "value": "Ancol"
  },
  {
    "id": "3273111003",
    "district_id": "327311",
    "label": "Pungkur",
    "value": "Pungkur"
  },
  {
    "id": "3273111004",
    "district_id": "327311",
    "label": "Balong Gede",
    "value": "Balong Gede"
  },
  {
    "id": "3273111005",
    "district_id": "327311",
    "label": "Ciseureuh",
    "value": "Ciseureuh"
  },
  {
    "id": "3273111006",
    "district_id": "327311",
    "label": "Ciateul",
    "value": "Ciateul"
  },
  {
    "id": "3273111007",
    "district_id": "327311",
    "label": "Pasirluyu",
    "value": "Pasirluyu"
  },
  {
    "id": "3273121001",
    "district_id": "327312",
    "label": "Gumuruh",
    "value": "Gumuruh"
  },
  {
    "id": "3273121002",
    "district_id": "327312",
    "label": "Maleer",
    "value": "Maleer"
  },
  {
    "id": "3273121003",
    "district_id": "327312",
    "label": "Cibangkong",
    "value": "Cibangkong"
  },
  {
    "id": "3273121004",
    "district_id": "327312",
    "label": "Kacapiring",
    "value": "Kacapiring"
  },
  {
    "id": "3273121005",
    "district_id": "327312",
    "label": "Kebon Waru",
    "value": "Kebon Waru"
  },
  {
    "id": "3273121006",
    "district_id": "327312",
    "label": "Kebon Gedang",
    "value": "Kebon Gedang"
  },
  {
    "id": "3273121007",
    "district_id": "327312",
    "label": "Samoja",
    "value": "Samoja"
  },
  {
    "id": "3273121008",
    "district_id": "327312",
    "label": "Binong",
    "value": "Binong"
  },
  {
    "id": "3273131001",
    "district_id": "327313",
    "label": "Cijagra",
    "value": "Cijagra"
  },
  {
    "id": "3273131002",
    "district_id": "327313",
    "label": "Lingkar Selatan",
    "value": "Lingkar Selatan"
  },
  {
    "id": "3273131003",
    "district_id": "327313",
    "label": "Burangrang",
    "value": "Burangrang"
  },
  {
    "id": "3273131004",
    "district_id": "327313",
    "label": "Paledang",
    "value": "Paledang"
  },
  {
    "id": "3273131005",
    "district_id": "327313",
    "label": "Turangga",
    "value": "Turangga"
  },
  {
    "id": "3273131006",
    "district_id": "327313",
    "label": "Malabar",
    "value": "Malabar"
  },
  {
    "id": "3273131007",
    "district_id": "327313",
    "label": "Cikawao",
    "value": "Cikawao"
  },
  {
    "id": "3273141001",
    "district_id": "327314",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3273141002",
    "district_id": "327314",
    "label": "Cikutra",
    "value": "Cikutra"
  },
  {
    "id": "3273141003",
    "district_id": "327314",
    "label": "Cicadas",
    "value": "Cicadas"
  },
  {
    "id": "3273141004",
    "district_id": "327314",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3273141005",
    "district_id": "327314",
    "label": "Sukapada",
    "value": "Sukapada"
  },
  {
    "id": "3273141006",
    "district_id": "327314",
    "label": "Pasirlayung",
    "value": "Pasirlayung"
  },
  {
    "id": "3273151001",
    "district_id": "327315",
    "label": "Cijerah",
    "value": "Cijerah"
  },
  {
    "id": "3273151002",
    "district_id": "327315",
    "label": "Cibuntu",
    "value": "Cibuntu"
  },
  {
    "id": "3273151003",
    "district_id": "327315",
    "label": "Warung Muncang",
    "value": "Warung Muncang"
  },
  {
    "id": "3273151004",
    "district_id": "327315",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3273151005",
    "district_id": "327315",
    "label": "Cigondewah Kaler",
    "value": "Cigondewah Kaler"
  },
  {
    "id": "3273151006",
    "district_id": "327315",
    "label": "Gempol Sari",
    "value": "Gempol Sari"
  },
  {
    "id": "3273151007",
    "district_id": "327315",
    "label": "Cigondewah Rahayu",
    "value": "Cigondewah Rahayu"
  },
  {
    "id": "3273151008",
    "district_id": "327315",
    "label": "Cigondewah Kidul",
    "value": "Cigondewah Kidul"
  },
  {
    "id": "3273161001",
    "district_id": "327316",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3273161002",
    "district_id": "327316",
    "label": "Kebon Jayanti",
    "value": "Kebon Jayanti"
  },
  {
    "id": "3273161003",
    "district_id": "327316",
    "label": "Babakan Surabaya",
    "value": "Babakan Surabaya"
  },
  {
    "id": "3273161004",
    "district_id": "327316",
    "label": "Cicaheum",
    "value": "Cicaheum"
  },
  {
    "id": "3273161005",
    "district_id": "327316",
    "label": "Babakan Sari",
    "value": "Babakan Sari"
  },
  {
    "id": "3273161006",
    "district_id": "327316",
    "label": "Kebon Kangkung",
    "value": "Kebon Kangkung"
  },
  {
    "id": "3273171001",
    "district_id": "327317",
    "label": "Situsaeur",
    "value": "Situsaeur"
  },
  {
    "id": "3273171002",
    "district_id": "327317",
    "label": "Kebon Lega",
    "value": "Kebon Lega"
  },
  {
    "id": "3273171003",
    "district_id": "327317",
    "label": "Cibaduyut",
    "value": "Cibaduyut"
  },
  {
    "id": "3273171004",
    "district_id": "327317",
    "label": "Mekar Wangi",
    "value": "Mekar Wangi"
  },
  {
    "id": "3273171005",
    "district_id": "327317",
    "label": "Cibaduyut Kidul",
    "value": "Cibaduyut Kidul"
  },
  {
    "id": "3273171006",
    "district_id": "327317",
    "label": "Cibaduyut Wetan",
    "value": "Cibaduyut Wetan"
  },
  {
    "id": "3273181001",
    "district_id": "327318",
    "label": "Cihaur Geulis",
    "value": "Cihaur Geulis"
  },
  {
    "id": "3273181002",
    "district_id": "327318",
    "label": "Sukaluyu",
    "value": "Sukaluyu"
  },
  {
    "id": "3273181003",
    "district_id": "327318",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3273181004",
    "district_id": "327318",
    "label": "Cigadung",
    "value": "Cigadung"
  },
  {
    "id": "3273191001",
    "district_id": "327319",
    "label": "Braga",
    "value": "Braga"
  },
  {
    "id": "3273191002",
    "district_id": "327319",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "3273191003",
    "district_id": "327319",
    "label": "Kebon Pisang",
    "value": "Kebon Pisang"
  },
  {
    "id": "3273191004",
    "district_id": "327319",
    "label": "Babakan Ciamis",
    "value": "Babakan Ciamis"
  },
  {
    "id": "3273201001",
    "district_id": "327320",
    "label": "Antapani Kulon",
    "value": "Antapani Kulon"
  },
  {
    "id": "3273201004",
    "district_id": "327320",
    "label": "Antapani Tengah",
    "value": "Antapani Tengah"
  },
  {
    "id": "3273201005",
    "district_id": "327320",
    "label": "Antapani Kidul",
    "value": "Antapani Kidul"
  },
  {
    "id": "3273201006",
    "district_id": "327320",
    "label": "Antapani Wetan",
    "value": "Antapani Wetan"
  },
  {
    "id": "3273211001",
    "district_id": "327321",
    "label": "Batununggal",
    "value": "Batununggal"
  },
  {
    "id": "3273211002",
    "district_id": "327321",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3273211003",
    "district_id": "327321",
    "label": "Mengger",
    "value": "Mengger"
  },
  {
    "id": "3273211004",
    "district_id": "327321",
    "label": "Kujangsari",
    "value": "Kujangsari"
  },
  {
    "id": "3273221001",
    "district_id": "327322",
    "label": "Sekejati",
    "value": "Sekejati"
  },
  {
    "id": "3273221002",
    "district_id": "327322",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3273221003",
    "district_id": "327322",
    "label": "Cijaura",
    "value": "Cijaura"
  },
  {
    "id": "3273221004",
    "district_id": "327322",
    "label": "Jati Sari",
    "value": "Jati Sari"
  },
  {
    "id": "3273231001",
    "district_id": "327323",
    "label": "Cipamokolan",
    "value": "Cipamokolan"
  },
  {
    "id": "3273231002",
    "district_id": "327323",
    "label": "Derwati",
    "value": "Derwati"
  },
  {
    "id": "3273231003",
    "district_id": "327323",
    "label": "Manjahlega",
    "value": "Manjahlega"
  },
  {
    "id": "3273231004",
    "district_id": "327323",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3273241001",
    "district_id": "327324",
    "label": "Sukamiskin",
    "value": "Sukamiskin"
  },
  {
    "id": "3273241002",
    "district_id": "327324",
    "label": "Cisaranten Bina",
    "value": "Cisaranten Bina"
  },
  {
    "id": "3273241003",
    "district_id": "327324",
    "label": "Cisaranten Kulon",
    "value": "Cisaranten Kulon"
  },
  {
    "id": "3273241004",
    "district_id": "327324",
    "label": "Cisaranten Endah",
    "value": "Cisaranten Endah"
  },
  {
    "id": "3273251001",
    "district_id": "327325",
    "label": "Palasari",
    "value": "Palasari"
  },
  {
    "id": "3273251002",
    "district_id": "327325",
    "label": "Cipadung",
    "value": "Cipadung"
  },
  {
    "id": "3273251003",
    "district_id": "327325",
    "label": "Pasir Biru",
    "value": "Pasir Biru"
  },
  {
    "id": "3273251004",
    "district_id": "327325",
    "label": "Cisurupan",
    "value": "Cisurupan"
  },
  {
    "id": "3273261003",
    "district_id": "327326",
    "label": "Pasir Endah",
    "value": "Pasir Endah"
  },
  {
    "id": "3273261004",
    "district_id": "327326",
    "label": "Cigending",
    "value": "Cigending"
  },
  {
    "id": "3273261005",
    "district_id": "327326",
    "label": "Pasirwangi",
    "value": "Pasirwangi"
  },
  {
    "id": "3273261006",
    "district_id": "327326",
    "label": "Pasirjati",
    "value": "Pasirjati"
  },
  {
    "id": "3273261007",
    "district_id": "327326",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3273271001",
    "district_id": "327327",
    "label": "Cimenerang",
    "value": "Cimenerang"
  },
  {
    "id": "3273271002",
    "district_id": "327327",
    "label": "Cisantren Kidul",
    "value": "Cisantren Kidul"
  },
  {
    "id": "3273271003",
    "district_id": "327327",
    "label": "Rancabolang",
    "value": "Rancabolang"
  },
  {
    "id": "3273271004",
    "district_id": "327327",
    "label": "Rancanumpang",
    "value": "Rancanumpang"
  },
  {
    "id": "3273281001",
    "district_id": "327328",
    "label": "Cipadung Kulon",
    "value": "Cipadung Kulon"
  },
  {
    "id": "3273281002",
    "district_id": "327328",
    "label": "Cipadung Kidul",
    "value": "Cipadung Kidul"
  },
  {
    "id": "3273281003",
    "district_id": "327328",
    "label": "Cipadung Wetan",
    "value": "Cipadung Wetan"
  },
  {
    "id": "3273281004",
    "district_id": "327328",
    "label": "Mekar Mulya",
    "value": "Mekar Mulya"
  },
  {
    "id": "3273291001",
    "district_id": "327329",
    "label": "Cisaranten Wetan",
    "value": "Cisaranten Wetan"
  },
  {
    "id": "3273291002",
    "district_id": "327329",
    "label": "Pakemitan",
    "value": "Pakemitan"
  },
  {
    "id": "3273291003",
    "district_id": "327329",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3273291004",
    "district_id": "327329",
    "label": "Babakan Penghulu",
    "value": "Babakan Penghulu"
  },
  {
    "id": "3273301001",
    "district_id": "327330",
    "label": "Jatihandap",
    "value": "Jatihandap"
  },
  {
    "id": "3273301002",
    "district_id": "327330",
    "label": "Karang Pamulang",
    "value": "Karang Pamulang"
  },
  {
    "id": "3273301003",
    "district_id": "327330",
    "label": "Pasir Impun",
    "value": "Pasir Impun"
  },
  {
    "id": "3273301004",
    "district_id": "327330",
    "label": "Sindang Jaya",
    "value": "Sindang Jaya"
  },
  {
    "id": "3274011001",
    "district_id": "327401",
    "label": "Kejaksan",
    "value": "Kejaksan"
  },
  {
    "id": "3274011002",
    "district_id": "327401",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3274011003",
    "district_id": "327401",
    "label": "Kesenden",
    "value": "Kesenden"
  },
  {
    "id": "3274011004",
    "district_id": "327401",
    "label": "Kebonbaru",
    "value": "Kebonbaru"
  },
  {
    "id": "3274021001",
    "district_id": "327402",
    "label": "Pegambiran",
    "value": "Pegambiran"
  },
  {
    "id": "3274021002",
    "district_id": "327402",
    "label": "Lemahwungkuk",
    "value": "Lemahwungkuk"
  },
  {
    "id": "3274021003",
    "district_id": "327402",
    "label": "Kesepuhan",
    "value": "Kesepuhan"
  },
  {
    "id": "3274021004",
    "district_id": "327402",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3274031001",
    "district_id": "327403",
    "label": "Kalijaga",
    "value": "Kalijaga"
  },
  {
    "id": "3274031002",
    "district_id": "327403",
    "label": "Harjamukti",
    "value": "Harjamukti"
  },
  {
    "id": "3274031003",
    "district_id": "327403",
    "label": "Kecapi",
    "value": "Kecapi"
  },
  {
    "id": "3274031004",
    "district_id": "327403",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3274031005",
    "district_id": "327403",
    "label": "Argasunya",
    "value": "Argasunya"
  },
  {
    "id": "3274041001",
    "district_id": "327404",
    "label": "Jagasatru",
    "value": "Jagasatru"
  },
  {
    "id": "3274041002",
    "district_id": "327404",
    "label": "Pekalipan",
    "value": "Pekalipan"
  },
  {
    "id": "3274041003",
    "district_id": "327404",
    "label": "Pulasaren",
    "value": "Pulasaren"
  },
  {
    "id": "3274041004",
    "district_id": "327404",
    "label": "Pekalangan",
    "value": "Pekalangan"
  },
  {
    "id": "3274051001",
    "district_id": "327405",
    "label": "Pekiringan",
    "value": "Pekiringan"
  },
  {
    "id": "3274051002",
    "district_id": "327405",
    "label": "Sunyaragi",
    "value": "Sunyaragi"
  },
  {
    "id": "3274051003",
    "district_id": "327405",
    "label": "Kesambi",
    "value": "Kesambi"
  },
  {
    "id": "3274051004",
    "district_id": "327405",
    "label": "Drajat",
    "value": "Drajat"
  },
  {
    "id": "3274051005",
    "district_id": "327405",
    "label": "Karyamulya",
    "value": "Karyamulya"
  },
  {
    "id": "3275011001",
    "district_id": "327501",
    "label": "Bekasijaya",
    "value": "Bekasijaya"
  },
  {
    "id": "3275011002",
    "district_id": "327501",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "3275011003",
    "district_id": "327501",
    "label": "Durenjaya",
    "value": "Durenjaya"
  },
  {
    "id": "3275011004",
    "district_id": "327501",
    "label": "Arenjaya",
    "value": "Arenjaya"
  },
  {
    "id": "3275021001",
    "district_id": "327502",
    "label": "Bintara",
    "value": "Bintara"
  },
  {
    "id": "3275021002",
    "district_id": "327502",
    "label": "Kranji",
    "value": "Kranji"
  },
  {
    "id": "3275021003",
    "district_id": "327502",
    "label": "Kotabaru",
    "value": "Kotabaru"
  },
  {
    "id": "3275021004",
    "district_id": "327502",
    "label": "Bintarajaya",
    "value": "Bintarajaya"
  },
  {
    "id": "3275021005",
    "district_id": "327502",
    "label": "Jakasampurna",
    "value": "Jakasampurna"
  },
  {
    "id": "3275031001",
    "district_id": "327503",
    "label": "Kaliabang Tengah",
    "value": "Kaliabang Tengah"
  },
  {
    "id": "3275031002",
    "district_id": "327503",
    "label": "Perwira",
    "value": "Perwira"
  },
  {
    "id": "3275031003",
    "district_id": "327503",
    "label": "Harapanbaru",
    "value": "Harapanbaru"
  },
  {
    "id": "3275031004",
    "district_id": "327503",
    "label": "Teluk Pucung",
    "value": "Teluk Pucung"
  },
  {
    "id": "3275031005",
    "district_id": "327503",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3275031006",
    "district_id": "327503",
    "label": "Harapanjaya",
    "value": "Harapanjaya"
  },
  {
    "id": "3275041001",
    "district_id": "327504",
    "label": "Pekayonjaya",
    "value": "Pekayonjaya"
  },
  {
    "id": "3275041002",
    "district_id": "327504",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3275041003",
    "district_id": "327504",
    "label": "Jakamulya",
    "value": "Jakamulya"
  },
  {
    "id": "3275041004",
    "district_id": "327504",
    "label": "Jakasetia",
    "value": "Jakasetia"
  },
  {
    "id": "3275041005",
    "district_id": "327504",
    "label": "Kayuringinjaya",
    "value": "Kayuringinjaya"
  },
  {
    "id": "3275051001",
    "district_id": "327505",
    "label": "Bojong Rawalumbu",
    "value": "Bojong Rawalumbu"
  },
  {
    "id": "3275051002",
    "district_id": "327505",
    "label": "Pengasinan",
    "value": "Pengasinan"
  },
  {
    "id": "3275051003",
    "district_id": "327505",
    "label": "Sepanjangjaya",
    "value": "Sepanjangjaya"
  },
  {
    "id": "3275051004",
    "district_id": "327505",
    "label": "Bojongmenteng",
    "value": "Bojongmenteng"
  },
  {
    "id": "3275061001",
    "district_id": "327506",
    "label": "Medansatria",
    "value": "Medansatria"
  },
  {
    "id": "3275061002",
    "district_id": "327506",
    "label": "Harapanmulya",
    "value": "Harapanmulya"
  },
  {
    "id": "3275061003",
    "district_id": "327506",
    "label": "Pejuang",
    "value": "Pejuang"
  },
  {
    "id": "3275061004",
    "district_id": "327506",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "3275071003",
    "district_id": "327507",
    "label": "Bantargebang",
    "value": "Bantargebang"
  },
  {
    "id": "3275071004",
    "district_id": "327507",
    "label": "Cikiwul",
    "value": "Cikiwul"
  },
  {
    "id": "3275071007",
    "district_id": "327507",
    "label": "Ciketingudik",
    "value": "Ciketingudik"
  },
  {
    "id": "3275071008",
    "district_id": "327507",
    "label": "Sumurbatu",
    "value": "Sumurbatu"
  },
  {
    "id": "3275081001",
    "district_id": "327508",
    "label": "Jatiwaringin",
    "value": "Jatiwaringin"
  },
  {
    "id": "3275081002",
    "district_id": "327508",
    "label": "Jatibening",
    "value": "Jatibening"
  },
  {
    "id": "3275081003",
    "district_id": "327508",
    "label": "Jatimakmur",
    "value": "Jatimakmur"
  },
  {
    "id": "3275081006",
    "district_id": "327508",
    "label": "Jatibening Baru",
    "value": "Jatibening Baru"
  },
  {
    "id": "3275081007",
    "district_id": "327508",
    "label": "Jaticempaka",
    "value": "Jaticempaka"
  },
  {
    "id": "3275091001",
    "district_id": "327509",
    "label": "Jatimekar",
    "value": "Jatimekar"
  },
  {
    "id": "3275091002",
    "district_id": "327509",
    "label": "Jatiasih",
    "value": "Jatiasih"
  },
  {
    "id": "3275091003",
    "district_id": "327509",
    "label": "Jatikramat",
    "value": "Jatikramat"
  },
  {
    "id": "3275091004",
    "district_id": "327509",
    "label": "Jatirasa",
    "value": "Jatirasa"
  },
  {
    "id": "3275091005",
    "district_id": "327509",
    "label": "Jatiluhur",
    "value": "Jatiluhur"
  },
  {
    "id": "3275091006",
    "district_id": "327509",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3275101001",
    "district_id": "327510",
    "label": "Jatisampurna",
    "value": "Jatisampurna"
  },
  {
    "id": "3275101002",
    "district_id": "327510",
    "label": "Jatikarya",
    "value": "Jatikarya"
  },
  {
    "id": "3275101003",
    "district_id": "327510",
    "label": "Jatiranggon",
    "value": "Jatiranggon"
  },
  {
    "id": "3275101004",
    "district_id": "327510",
    "label": "Jatirangga",
    "value": "Jatirangga"
  },
  {
    "id": "3275101005",
    "district_id": "327510",
    "label": "Jatiraden",
    "value": "Jatiraden"
  },
  {
    "id": "3275111001",
    "district_id": "327511",
    "label": "Padurenan",
    "value": "Padurenan"
  },
  {
    "id": "3275111002",
    "district_id": "327511",
    "label": "Cimuning",
    "value": "Cimuning"
  },
  {
    "id": "3275111003",
    "district_id": "327511",
    "label": "Mustikajaya",
    "value": "Mustikajaya"
  },
  {
    "id": "3275111004",
    "district_id": "327511",
    "label": "Mustikasari",
    "value": "Mustikasari"
  },
  {
    "id": "3275121001",
    "district_id": "327512",
    "label": "Jatirahayu",
    "value": "Jatirahayu"
  },
  {
    "id": "3275121002",
    "district_id": "327512",
    "label": "Jatiwarna",
    "value": "Jatiwarna"
  },
  {
    "id": "3275121003",
    "district_id": "327512",
    "label": "Jatimelati",
    "value": "Jatimelati"
  },
  {
    "id": "3275121004",
    "district_id": "327512",
    "label": "Jatimurni",
    "value": "Jatimurni"
  },
  {
    "id": "3276011006",
    "district_id": "327601",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3276011007",
    "district_id": "327601",
    "label": "Depok Jaya",
    "value": "Depok Jaya"
  },
  {
    "id": "3276011008",
    "district_id": "327601",
    "label": "Pancoran Mas",
    "value": "Pancoran Mas"
  },
  {
    "id": "3276011009",
    "district_id": "327601",
    "label": "Mampang",
    "value": "Mampang"
  },
  {
    "id": "3276011010",
    "district_id": "327601",
    "label": "Rangkapan Jaya Baru",
    "value": "Rangkapan Jaya Baru"
  },
  {
    "id": "3276011011",
    "district_id": "327601",
    "label": "Rangkapan Jaya",
    "value": "Rangkapan Jaya"
  },
  {
    "id": "3276021007",
    "district_id": "327602",
    "label": "Harjamukti",
    "value": "Harjamukti"
  },
  {
    "id": "3276021008",
    "district_id": "327602",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3276021009",
    "district_id": "327602",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3276021010",
    "district_id": "327602",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3276021011",
    "district_id": "327602",
    "label": "Pasir Gunung Selatan",
    "value": "Pasir Gunung Selatan"
  },
  {
    "id": "3276021012",
    "district_id": "327602",
    "label": "Cisalak Pasar",
    "value": "Cisalak Pasar"
  },
  {
    "id": "3276031001",
    "district_id": "327603",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "3276031002",
    "district_id": "327603",
    "label": "Bedahan",
    "value": "Bedahan"
  },
  {
    "id": "3276031003",
    "district_id": "327603",
    "label": "Pengasinan",
    "value": "Pengasinan"
  },
  {
    "id": "3276031009",
    "district_id": "327603",
    "label": "Cinangka",
    "value": "Cinangka"
  },
  {
    "id": "3276031010",
    "district_id": "327603",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3276031011",
    "district_id": "327603",
    "label": "Sawangan Baru",
    "value": "Sawangan Baru"
  },
  {
    "id": "3276031012",
    "district_id": "327603",
    "label": "Kedaung",
    "value": "Kedaung"
  },
  {
    "id": "3276041001",
    "district_id": "327604",
    "label": "Maruyung",
    "value": "Maruyung"
  },
  {
    "id": "3276041002",
    "district_id": "327604",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3276041003",
    "district_id": "327604",
    "label": "Krukut",
    "value": "Krukut"
  },
  {
    "id": "3276041004",
    "district_id": "327604",
    "label": "Limo",
    "value": "Limo"
  },
  {
    "id": "3276051001",
    "district_id": "327605",
    "label": "Sukmajaya",
    "value": "Sukmajaya"
  },
  {
    "id": "3276051003",
    "district_id": "327605",
    "label": "Abadi Jaya",
    "value": "Abadi Jaya"
  },
  {
    "id": "3276051004",
    "district_id": "327605",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "3276051005",
    "district_id": "327605",
    "label": "Baktijaya",
    "value": "Baktijaya"
  },
  {
    "id": "3276051008",
    "district_id": "327605",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3276051010",
    "district_id": "327605",
    "label": "Tirtajaya",
    "value": "Tirtajaya"
  },
  {
    "id": "3276061001",
    "district_id": "327606",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3276061002",
    "district_id": "327606",
    "label": "Kukusan",
    "value": "Kukusan"
  },
  {
    "id": "3276061003",
    "district_id": "327606",
    "label": "Tanah Baru",
    "value": "Tanah Baru"
  },
  {
    "id": "3276061004",
    "district_id": "327606",
    "label": "Kemiri Muka",
    "value": "Kemiri Muka"
  },
  {
    "id": "3276061005",
    "district_id": "327606",
    "label": "Pondok Cina",
    "value": "Pondok Cina"
  },
  {
    "id": "3276061006",
    "district_id": "327606",
    "label": "Beji Timur",
    "value": "Beji Timur"
  },
  {
    "id": "3276071001",
    "district_id": "327607",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "3276071002",
    "district_id": "327607",
    "label": "Cipayung Jaya",
    "value": "Cipayung Jaya"
  },
  {
    "id": "3276071003",
    "district_id": "327607",
    "label": "Ratu Jaya",
    "value": "Ratu Jaya"
  },
  {
    "id": "3276071004",
    "district_id": "327607",
    "label": "Bojong Pondok Terong",
    "value": "Bojong Pondok Terong"
  },
  {
    "id": "3276071005",
    "district_id": "327607",
    "label": "Pondok Jaya",
    "value": "Pondok Jaya"
  },
  {
    "id": "3276081001",
    "district_id": "327608",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3276081002",
    "district_id": "327608",
    "label": "Cilodong",
    "value": "Cilodong"
  },
  {
    "id": "3276081003",
    "district_id": "327608",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "3276081004",
    "district_id": "327608",
    "label": "Kalimulya",
    "value": "Kalimulya"
  },
  {
    "id": "3276081005",
    "district_id": "327608",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3276091001",
    "district_id": "327609",
    "label": "Cinere",
    "value": "Cinere"
  },
  {
    "id": "3276091002",
    "district_id": "327609",
    "label": "Gandul",
    "value": "Gandul"
  },
  {
    "id": "3276091003",
    "district_id": "327609",
    "label": "Pangkalan Jati",
    "value": "Pangkalan Jati"
  },
  {
    "id": "3276091004",
    "district_id": "327609",
    "label": "Pangkalan Jati Baru",
    "value": "Pangkalan Jati Baru"
  },
  {
    "id": "3276101001",
    "district_id": "327610",
    "label": "Tapos",
    "value": "Tapos"
  },
  {
    "id": "3276101002",
    "district_id": "327610",
    "label": "Leuwinanggung",
    "value": "Leuwinanggung"
  },
  {
    "id": "3276101003",
    "district_id": "327610",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3276101004",
    "district_id": "327610",
    "label": "Sukamaju Baru",
    "value": "Sukamaju Baru"
  },
  {
    "id": "3276101005",
    "district_id": "327610",
    "label": "Jatijajar",
    "value": "Jatijajar"
  },
  {
    "id": "3276101006",
    "district_id": "327610",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3276101007",
    "district_id": "327610",
    "label": "Cimpaeun",
    "value": "Cimpaeun"
  },
  {
    "id": "3276111001",
    "district_id": "327611",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3276111002",
    "district_id": "327611",
    "label": "Bojongsari Baru",
    "value": "Bojongsari Baru"
  },
  {
    "id": "3276111003",
    "district_id": "327611",
    "label": "Serua",
    "value": "Serua"
  },
  {
    "id": "3276111004",
    "district_id": "327611",
    "label": "Pondok Petir",
    "value": "Pondok Petir"
  },
  {
    "id": "3276111005",
    "district_id": "327611",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3276111006",
    "district_id": "327611",
    "label": "Duren Mekar",
    "value": "Duren Mekar"
  },
  {
    "id": "3276111007",
    "district_id": "327611",
    "label": "Duren Seribu",
    "value": "Duren Seribu"
  },
  {
    "id": "3277011001",
    "district_id": "327701",
    "label": "Melong",
    "value": "Melong"
  },
  {
    "id": "3277011002",
    "district_id": "327701",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3277011003",
    "district_id": "327701",
    "label": "Utama",
    "value": "Utama"
  },
  {
    "id": "3277011004",
    "district_id": "327701",
    "label": "Leuwigajah",
    "value": "Leuwigajah"
  },
  {
    "id": "3277011005",
    "district_id": "327701",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3277021001",
    "district_id": "327702",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "3277021002",
    "district_id": "327702",
    "label": "Cigugur Tengah",
    "value": "Cigugur Tengah"
  },
  {
    "id": "3277021003",
    "district_id": "327702",
    "label": "Karangmekar",
    "value": "Karangmekar"
  },
  {
    "id": "3277021004",
    "district_id": "327702",
    "label": "Setiamanah",
    "value": "Setiamanah"
  },
  {
    "id": "3277021005",
    "district_id": "327702",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3277021006",
    "district_id": "327702",
    "label": "Cimahi",
    "value": "Cimahi"
  },
  {
    "id": "3277031001",
    "district_id": "327703",
    "label": "Pasirkaliki",
    "value": "Pasirkaliki"
  },
  {
    "id": "3277031002",
    "district_id": "327703",
    "label": "Cibabat",
    "value": "Cibabat"
  },
  {
    "id": "3277031003",
    "district_id": "327703",
    "label": "Citeureup",
    "value": "Citeureup"
  },
  {
    "id": "3277031004",
    "district_id": "327703",
    "label": "Cipageran",
    "value": "Cipageran"
  },
  {
    "id": "3278011001",
    "district_id": "327801",
    "label": "Yudanagara",
    "value": "Yudanagara"
  },
  {
    "id": "3278011002",
    "district_id": "327801",
    "label": "Nagarawangi",
    "value": "Nagarawangi"
  },
  {
    "id": "3278011003",
    "district_id": "327801",
    "label": "Cilembang",
    "value": "Cilembang"
  },
  {
    "id": "3278011004",
    "district_id": "327801",
    "label": "Argasari",
    "value": "Argasari"
  },
  {
    "id": "3278011005",
    "district_id": "327801",
    "label": "Tugujaya",
    "value": "Tugujaya"
  },
  {
    "id": "3278011006",
    "district_id": "327801",
    "label": "Tuguraja",
    "value": "Tuguraja"
  },
  {
    "id": "3278021001",
    "district_id": "327802",
    "label": "Panglayungan",
    "value": "Panglayungan"
  },
  {
    "id": "3278021002",
    "district_id": "327802",
    "label": "Cipedes",
    "value": "Cipedes"
  },
  {
    "id": "3278021003",
    "district_id": "327802",
    "label": "Nagarasari",
    "value": "Nagarasari"
  },
  {
    "id": "3278021004",
    "district_id": "327802",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3278031001",
    "district_id": "327803",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3278031002",
    "district_id": "327803",
    "label": "Empangsari",
    "value": "Empangsari"
  },
  {
    "id": "3278031003",
    "district_id": "327803",
    "label": "Lengkongsari",
    "value": "Lengkongsari"
  },
  {
    "id": "3278031004",
    "district_id": "327803",
    "label": "Cikalang",
    "value": "Cikalang"
  },
  {
    "id": "3278031005",
    "district_id": "327803",
    "label": "Kahuripan",
    "value": "Kahuripan"
  },
  {
    "id": "3278041003",
    "district_id": "327804",
    "label": "Indihiang",
    "value": "Indihiang"
  },
  {
    "id": "3278041004",
    "district_id": "327804",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3278041005",
    "district_id": "327804",
    "label": "Parakannyasag",
    "value": "Parakannyasag"
  },
  {
    "id": "3278041006",
    "district_id": "327804",
    "label": "Panyingkiran",
    "value": "Panyingkiran"
  },
  {
    "id": "3278041007",
    "district_id": "327804",
    "label": "Sukamaju Kaler",
    "value": "Sukamaju Kaler"
  },
  {
    "id": "3278041008",
    "district_id": "327804",
    "label": "Sukamaju Kidul",
    "value": "Sukamaju Kidul"
  },
  {
    "id": "3278051001",
    "district_id": "327805",
    "label": "Kersamenak",
    "value": "Kersamenak"
  },
  {
    "id": "3278051002",
    "district_id": "327805",
    "label": "Cilamajang",
    "value": "Cilamajang"
  },
  {
    "id": "3278051003",
    "district_id": "327805",
    "label": "Gunung Tandala",
    "value": "Gunung Tandala"
  },
  {
    "id": "3278051004",
    "district_id": "327805",
    "label": "Urug",
    "value": "Urug"
  },
  {
    "id": "3278051005",
    "district_id": "327805",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3278051006",
    "district_id": "327805",
    "label": "Cibeuti",
    "value": "Cibeuti"
  },
  {
    "id": "3278051007",
    "district_id": "327805",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3278051008",
    "district_id": "327805",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3278051009",
    "district_id": "327805",
    "label": "Leuwiliang",
    "value": "Leuwiliang"
  },
  {
    "id": "3278051010",
    "district_id": "327805",
    "label": "Gunung Gede",
    "value": "Gunung Gede"
  },
  {
    "id": "3278061005",
    "district_id": "327806",
    "label": "Setiaratu",
    "value": "Setiaratu"
  },
  {
    "id": "3278061007",
    "district_id": "327806",
    "label": "Kersanagara",
    "value": "Kersanagara"
  },
  {
    "id": "3278061008",
    "district_id": "327806",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "3278061009",
    "district_id": "327806",
    "label": "Awipari",
    "value": "Awipari"
  },
  {
    "id": "3278061010",
    "district_id": "327806",
    "label": "Setianagara",
    "value": "Setianagara"
  },
  {
    "id": "3278061011",
    "district_id": "327806",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3278061012",
    "district_id": "327806",
    "label": "Ciakar",
    "value": "Ciakar"
  },
  {
    "id": "3278061013",
    "district_id": "327806",
    "label": "Margabakti",
    "value": "Margabakti"
  },
  {
    "id": "3278061014",
    "district_id": "327806",
    "label": "Setiajaya",
    "value": "Setiajaya"
  },
  {
    "id": "3278071001",
    "district_id": "327807",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3278071002",
    "district_id": "327807",
    "label": "Mugarsari",
    "value": "Mugarsari"
  },
  {
    "id": "3278071003",
    "district_id": "327807",
    "label": "Tamanjaya",
    "value": "Tamanjaya"
  },
  {
    "id": "3278071004",
    "district_id": "327807",
    "label": "Sumelap",
    "value": "Sumelap"
  },
  {
    "id": "3278071005",
    "district_id": "327807",
    "label": "Setiawargi",
    "value": "Setiawargi"
  },
  {
    "id": "3278071006",
    "district_id": "327807",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3278071007",
    "district_id": "327807",
    "label": "Sukahurip",
    "value": "Sukahurip"
  },
  {
    "id": "3278071008",
    "district_id": "327807",
    "label": "Setiamulya",
    "value": "Setiamulya"
  },
  {
    "id": "3278081001",
    "district_id": "327808",
    "label": "Mangkubumi",
    "value": "Mangkubumi"
  },
  {
    "id": "3278081002",
    "district_id": "327808",
    "label": "Cigantang",
    "value": "Cigantang"
  },
  {
    "id": "3278081003",
    "district_id": "327808",
    "label": "Karikil",
    "value": "Karikil"
  },
  {
    "id": "3278081004",
    "district_id": "327808",
    "label": "Linggajaya",
    "value": "Linggajaya"
  },
  {
    "id": "3278081005",
    "district_id": "327808",
    "label": "Cipawitra",
    "value": "Cipawitra"
  },
  {
    "id": "3278081006",
    "district_id": "327808",
    "label": "Sambongpari",
    "value": "Sambongpari"
  },
  {
    "id": "3278081007",
    "district_id": "327808",
    "label": "Sambongjaya",
    "value": "Sambongjaya"
  },
  {
    "id": "3278081008",
    "district_id": "327808",
    "label": "Cipari",
    "value": "Cipari"
  },
  {
    "id": "3278091001",
    "district_id": "327809",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3278091002",
    "district_id": "327809",
    "label": "Sukarindik",
    "value": "Sukarindik"
  },
  {
    "id": "3278091003",
    "district_id": "327809",
    "label": "Bungursari",
    "value": "Bungursari"
  },
  {
    "id": "3278091004",
    "district_id": "327809",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3278091005",
    "district_id": "327809",
    "label": "Cibunigeulis",
    "value": "Cibunigeulis"
  },
  {
    "id": "3278091006",
    "district_id": "327809",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3278091007",
    "district_id": "327809",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3278101001",
    "district_id": "327810",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3278101002",
    "district_id": "327810",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3278101003",
    "district_id": "327810",
    "label": "Purbaratu",
    "value": "Purbaratu"
  },
  {
    "id": "3278101004",
    "district_id": "327810",
    "label": "Sukaasih",
    "value": "Sukaasih"
  },
  {
    "id": "3278101005",
    "district_id": "327810",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3278101006",
    "district_id": "327810",
    "label": "Singkup",
    "value": "Singkup"
  },
  {
    "id": "3279011001",
    "district_id": "327901",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3279011002",
    "district_id": "327901",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3279011006",
    "district_id": "327901",
    "label": "Situbatu",
    "value": "Situbatu"
  },
  {
    "id": "3279012003",
    "district_id": "327901",
    "label": "Balokang",
    "value": "Balokang"
  },
  {
    "id": "3279012004",
    "district_id": "327901",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3279012005",
    "district_id": "327901",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3279012007",
    "district_id": "327901",
    "label": "Jajawar",
    "value": "Jajawar"
  },
  {
    "id": "3279021001",
    "district_id": "327902",
    "label": "Hegarsari",
    "value": "Hegarsari"
  },
  {
    "id": "3279021002",
    "district_id": "327902",
    "label": "Pataruman",
    "value": "Pataruman"
  },
  {
    "id": "3279022003",
    "district_id": "327902",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3279022004",
    "district_id": "327902",
    "label": "Batulawang",
    "value": "Batulawang"
  },
  {
    "id": "3279022005",
    "district_id": "327902",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "3279022006",
    "district_id": "327902",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3279022007",
    "district_id": "327902",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "3279022008",
    "district_id": "327902",
    "label": "Sinartanjung",
    "value": "Sinartanjung"
  },
  {
    "id": "3279031001",
    "district_id": "327903",
    "label": "Purwaharja",
    "value": "Purwaharja"
  },
  {
    "id": "3279031004",
    "district_id": "327903",
    "label": "Karangpanimbal",
    "value": "Karangpanimbal"
  },
  {
    "id": "3279032002",
    "district_id": "327903",
    "label": "Raharja",
    "value": "Raharja"
  },
  {
    "id": "3279032003",
    "district_id": "327903",
    "label": "Mekarharja",
    "value": "Mekarharja"
  },
  {
    "id": "3279041004",
    "district_id": "327904",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "3279041005",
    "district_id": "327904",
    "label": "Bojongkantong",
    "value": "Bojongkantong"
  },
  {
    "id": "3279042001",
    "district_id": "327904",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3279042002",
    "district_id": "327904",
    "label": "Rejasari",
    "value": "Rejasari"
  },
  {
    "id": "3279042003",
    "district_id": "327904",
    "label": "Waringinsari",
    "value": "Waringinsari"
  },
  {
    "id": "3279042006",
    "district_id": "327904",
    "label": "Kujangsari",
    "value": "Kujangsari"
  },
  {
    "id": "3301012001",
    "district_id": "330101",
    "label": "Tambakreja",
    "value": "Tambakreja"
  },
  {
    "id": "3301012002",
    "district_id": "330101",
    "label": "Bumireja",
    "value": "Bumireja"
  },
  {
    "id": "3301012003",
    "district_id": "330101",
    "label": "Ciklapa",
    "value": "Ciklapa"
  },
  {
    "id": "3301012004",
    "district_id": "330101",
    "label": "Kedungreja",
    "value": "Kedungreja"
  },
  {
    "id": "3301012005",
    "district_id": "330101",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3301012006",
    "district_id": "330101",
    "label": "Rejamulya",
    "value": "Rejamulya"
  },
  {
    "id": "3301012007",
    "district_id": "330101",
    "label": "Sidanegara",
    "value": "Sidanegara"
  },
  {
    "id": "3301012008",
    "district_id": "330101",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3301012009",
    "district_id": "330101",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3301012010",
    "district_id": "330101",
    "label": "Bangunreja",
    "value": "Bangunreja"
  },
  {
    "id": "3301012011",
    "district_id": "330101",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3301022001",
    "district_id": "330102",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "3301022002",
    "district_id": "330102",
    "label": "Slarang",
    "value": "Slarang"
  },
  {
    "id": "3301022003",
    "district_id": "330102",
    "label": "Kesugihan",
    "value": "Kesugihan"
  },
  {
    "id": "3301022004",
    "district_id": "330102",
    "label": "Kalisabuk",
    "value": "Kalisabuk"
  },
  {
    "id": "3301022005",
    "district_id": "330102",
    "label": "Karangkandri",
    "value": "Karangkandri"
  },
  {
    "id": "3301022006",
    "district_id": "330102",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3301022007",
    "district_id": "330102",
    "label": "Dondong",
    "value": "Dondong"
  },
  {
    "id": "3301022008",
    "district_id": "330102",
    "label": "Planjan",
    "value": "Planjan"
  },
  {
    "id": "3301022009",
    "district_id": "330102",
    "label": "Ciwuni",
    "value": "Ciwuni"
  },
  {
    "id": "3301022010",
    "district_id": "330102",
    "label": "Karangjengkol",
    "value": "Karangjengkol"
  },
  {
    "id": "3301022011",
    "district_id": "330102",
    "label": "Keleng",
    "value": "Keleng"
  },
  {
    "id": "3301022012",
    "district_id": "330102",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3301022013",
    "district_id": "330102",
    "label": "Bulupayung",
    "value": "Bulupayung"
  },
  {
    "id": "3301022014",
    "district_id": "330102",
    "label": "Kuripan Kidul",
    "value": "Kuripan Kidul"
  },
  {
    "id": "3301022015",
    "district_id": "330102",
    "label": "Jangrana",
    "value": "Jangrana"
  },
  {
    "id": "3301022016",
    "district_id": "330102",
    "label": "Kesugihan Kidul",
    "value": "Kesugihan Kidul"
  },
  {
    "id": "3301032001",
    "district_id": "330103",
    "label": "Welahan Wetan",
    "value": "Welahan Wetan"
  },
  {
    "id": "3301032002",
    "district_id": "330103",
    "label": "Glempangpasir",
    "value": "Glempangpasir"
  },
  {
    "id": "3301032003",
    "district_id": "330103",
    "label": "Pedasong",
    "value": "Pedasong"
  },
  {
    "id": "3301032004",
    "district_id": "330103",
    "label": "Karangbenda",
    "value": "Karangbenda"
  },
  {
    "id": "3301032005",
    "district_id": "330103",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3301032006",
    "district_id": "330103",
    "label": "Bunton",
    "value": "Bunton"
  },
  {
    "id": "3301032007",
    "district_id": "330103",
    "label": "Wlahar",
    "value": "Wlahar"
  },
  {
    "id": "3301032008",
    "district_id": "330103",
    "label": "Penggalang",
    "value": "Penggalang"
  },
  {
    "id": "3301032009",
    "district_id": "330103",
    "label": "Adipala",
    "value": "Adipala"
  },
  {
    "id": "3301032010",
    "district_id": "330103",
    "label": "Adireja Kulon",
    "value": "Adireja Kulon"
  },
  {
    "id": "3301032011",
    "district_id": "330103",
    "label": "Adireja Wetan",
    "value": "Adireja Wetan"
  },
  {
    "id": "3301032012",
    "district_id": "330103",
    "label": "Adiraja",
    "value": "Adiraja"
  },
  {
    "id": "3301032013",
    "district_id": "330103",
    "label": "Doplang",
    "value": "Doplang"
  },
  {
    "id": "3301032014",
    "district_id": "330103",
    "label": "Kalikudi",
    "value": "Kalikudi"
  },
  {
    "id": "3301032015",
    "district_id": "330103",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3301032016",
    "district_id": "330103",
    "label": "Gombolharjo",
    "value": "Gombolharjo"
  },
  {
    "id": "3301042001",
    "district_id": "330104",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3301042002",
    "district_id": "330104",
    "label": "Kepudang",
    "value": "Kepudang"
  },
  {
    "id": "3301042003",
    "district_id": "330104",
    "label": "Jepara Kulon",
    "value": "Jepara Kulon"
  },
  {
    "id": "3301042004",
    "district_id": "330104",
    "label": "Widarapayung Kulon",
    "value": "Widarapayung Kulon"
  },
  {
    "id": "3301042005",
    "district_id": "330104",
    "label": "Jepara Wetan",
    "value": "Jepara Wetan"
  },
  {
    "id": "3301042006",
    "district_id": "330104",
    "label": "Bangkal",
    "value": "Bangkal"
  },
  {
    "id": "3301042007",
    "district_id": "330104",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3301042008",
    "district_id": "330104",
    "label": "Widarapayung Wetan",
    "value": "Widarapayung Wetan"
  },
  {
    "id": "3301042009",
    "district_id": "330104",
    "label": "Alangamba",
    "value": "Alangamba"
  },
  {
    "id": "3301042010",
    "district_id": "330104",
    "label": "Pasuruhan",
    "value": "Pasuruhan"
  },
  {
    "id": "3301042011",
    "district_id": "330104",
    "label": "Sidaurip",
    "value": "Sidaurip"
  },
  {
    "id": "3301042012",
    "district_id": "330104",
    "label": "Pagubugan",
    "value": "Pagubugan"
  },
  {
    "id": "3301042013",
    "district_id": "330104",
    "label": "Pesawahan",
    "value": "Pesawahan"
  },
  {
    "id": "3301042014",
    "district_id": "330104",
    "label": "Kemojing",
    "value": "Kemojing"
  },
  {
    "id": "3301042015",
    "district_id": "330104",
    "label": "Karangnangka",
    "value": "Karangnangka"
  },
  {
    "id": "3301042016",
    "district_id": "330104",
    "label": "Sidayu",
    "value": "Sidayu"
  },
  {
    "id": "3301042017",
    "district_id": "330104",
    "label": "Pagubugan Kulon",
    "value": "Pagubugan Kulon"
  },
  {
    "id": "3301052001",
    "district_id": "330105",
    "label": "Karangtawang",
    "value": "Karangtawang"
  },
  {
    "id": "3301052002",
    "district_id": "330105",
    "label": "Karangpakis",
    "value": "Karangpakis"
  },
  {
    "id": "3301052003",
    "district_id": "330105",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3301052004",
    "district_id": "330105",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3301052005",
    "district_id": "330105",
    "label": "Banjareja",
    "value": "Banjareja"
  },
  {
    "id": "3301052006",
    "district_id": "330105",
    "label": "Kedungbenda",
    "value": "Kedungbenda"
  },
  {
    "id": "3301052007",
    "district_id": "330105",
    "label": "Klumprit",
    "value": "Klumprit"
  },
  {
    "id": "3301052008",
    "district_id": "330105",
    "label": "Karangsembung",
    "value": "Karangsembung"
  },
  {
    "id": "3301052009",
    "district_id": "330105",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3301052010",
    "district_id": "330105",
    "label": "Nusawangkal",
    "value": "Nusawangkal"
  },
  {
    "id": "3301052011",
    "district_id": "330105",
    "label": "Karangputat",
    "value": "Karangputat"
  },
  {
    "id": "3301052012",
    "district_id": "330105",
    "label": "Banjarwaru",
    "value": "Banjarwaru"
  },
  {
    "id": "3301052013",
    "district_id": "330105",
    "label": "Danasri Kidul",
    "value": "Danasri Kidul"
  },
  {
    "id": "3301052014",
    "district_id": "330105",
    "label": "Nusawungu",
    "value": "Nusawungu"
  },
  {
    "id": "3301052015",
    "district_id": "330105",
    "label": "Danasri Lor",
    "value": "Danasri Lor"
  },
  {
    "id": "3301052016",
    "district_id": "330105",
    "label": "Danasri",
    "value": "Danasri"
  },
  {
    "id": "3301052017",
    "district_id": "330105",
    "label": "Sikanco",
    "value": "Sikanco"
  },
  {
    "id": "3301062001",
    "district_id": "330106",
    "label": "Sikampuh",
    "value": "Sikampuh"
  },
  {
    "id": "3301062002",
    "district_id": "330106",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3301062003",
    "district_id": "330106",
    "label": "Ayamalas",
    "value": "Ayamalas"
  },
  {
    "id": "3301062004",
    "district_id": "330106",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3301062005",
    "district_id": "330106",
    "label": "Kroya",
    "value": "Kroya"
  },
  {
    "id": "3301062006",
    "district_id": "330106",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3301062007",
    "district_id": "330106",
    "label": "Pucung Kidul",
    "value": "Pucung Kidul"
  },
  {
    "id": "3301062008",
    "district_id": "330106",
    "label": "Mergawati",
    "value": "Mergawati"
  },
  {
    "id": "3301062009",
    "district_id": "330106",
    "label": "Pucung Lor",
    "value": "Pucung Lor"
  },
  {
    "id": "3301062010",
    "district_id": "330106",
    "label": "Bajing",
    "value": "Bajing"
  },
  {
    "id": "3301062011",
    "district_id": "330106",
    "label": "Gentasari",
    "value": "Gentasari"
  },
  {
    "id": "3301062012",
    "district_id": "330106",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3301062013",
    "district_id": "330106",
    "label": "Mujur",
    "value": "Mujur"
  },
  {
    "id": "3301062014",
    "district_id": "330106",
    "label": "Buntu",
    "value": "Buntu"
  },
  {
    "id": "3301062015",
    "district_id": "330106",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3301062016",
    "district_id": "330106",
    "label": "Bajing Kulon",
    "value": "Bajing Kulon"
  },
  {
    "id": "3301062017",
    "district_id": "330106",
    "label": "Mujur Lor",
    "value": "Mujur Lor"
  },
  {
    "id": "3301072001",
    "district_id": "330107",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3301072002",
    "district_id": "330107",
    "label": "Karangrena",
    "value": "Karangrena"
  },
  {
    "id": "3301072003",
    "district_id": "330107",
    "label": "Maos Kidul",
    "value": "Maos Kidul"
  },
  {
    "id": "3301072004",
    "district_id": "330107",
    "label": "Maos Lor",
    "value": "Maos Lor"
  },
  {
    "id": "3301072005",
    "district_id": "330107",
    "label": "Kalijaran",
    "value": "Kalijaran"
  },
  {
    "id": "3301072006",
    "district_id": "330107",
    "label": "Mernek",
    "value": "Mernek"
  },
  {
    "id": "3301072007",
    "district_id": "330107",
    "label": "Panisihan",
    "value": "Panisihan"
  },
  {
    "id": "3301072008",
    "district_id": "330107",
    "label": "Glempang",
    "value": "Glempang"
  },
  {
    "id": "3301072009",
    "district_id": "330107",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3301072010",
    "district_id": "330107",
    "label": "Klapagada",
    "value": "Klapagada"
  },
  {
    "id": "3301082001",
    "district_id": "330108",
    "label": "Tritih Wetan",
    "value": "Tritih Wetan"
  },
  {
    "id": "3301082002",
    "district_id": "330108",
    "label": "Sumingkir",
    "value": "Sumingkir"
  },
  {
    "id": "3301082003",
    "district_id": "330108",
    "label": "Jeruklegi Wetan",
    "value": "Jeruklegi Wetan"
  },
  {
    "id": "3301082004",
    "district_id": "330108",
    "label": "Brebeg",
    "value": "Brebeg"
  },
  {
    "id": "3301082005",
    "district_id": "330108",
    "label": "Jeruklegi Kulon",
    "value": "Jeruklegi Kulon"
  },
  {
    "id": "3301082006",
    "district_id": "330108",
    "label": "Cilibang",
    "value": "Cilibang"
  },
  {
    "id": "3301082007",
    "district_id": "330108",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "3301082008",
    "district_id": "330108",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3301082009",
    "district_id": "330108",
    "label": "Jambusari",
    "value": "Jambusari"
  },
  {
    "id": "3301082010",
    "district_id": "330108",
    "label": "Prapagan",
    "value": "Prapagan"
  },
  {
    "id": "3301082011",
    "district_id": "330108",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3301082012",
    "district_id": "330108",
    "label": "Citepus",
    "value": "Citepus"
  },
  {
    "id": "3301082013",
    "district_id": "330108",
    "label": "Tritih Lor",
    "value": "Tritih Lor"
  },
  {
    "id": "3301092001",
    "district_id": "330109",
    "label": "Grugu",
    "value": "Grugu"
  },
  {
    "id": "3301092002",
    "district_id": "330109",
    "label": "Bringkeng",
    "value": "Bringkeng"
  },
  {
    "id": "3301092003",
    "district_id": "330109",
    "label": "Ujungmanik",
    "value": "Ujungmanik"
  },
  {
    "id": "3301092004",
    "district_id": "330109",
    "label": "Kubangkangkung",
    "value": "Kubangkangkung"
  },
  {
    "id": "3301092005",
    "district_id": "330109",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3301092006",
    "district_id": "330109",
    "label": "Mentasan",
    "value": "Mentasan"
  },
  {
    "id": "3301092007",
    "district_id": "330109",
    "label": "Kalijeruk",
    "value": "Kalijeruk"
  },
  {
    "id": "3301092008",
    "district_id": "330109",
    "label": "Kawunganten",
    "value": "Kawunganten"
  },
  {
    "id": "3301092009",
    "district_id": "330109",
    "label": "Sarwadadi",
    "value": "Sarwadadi"
  },
  {
    "id": "3301092010",
    "district_id": "330109",
    "label": "Kawunganten Lor",
    "value": "Kawunganten Lor"
  },
  {
    "id": "3301092014",
    "district_id": "330109",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3301092015",
    "district_id": "330109",
    "label": "Sidaurip",
    "value": "Sidaurip"
  },
  {
    "id": "3301102001",
    "district_id": "330110",
    "label": "Gandrungmangu",
    "value": "Gandrungmangu"
  },
  {
    "id": "3301102002",
    "district_id": "330110",
    "label": "Gandrungmanis",
    "value": "Gandrungmanis"
  },
  {
    "id": "3301102003",
    "district_id": "330110",
    "label": "Cisumur",
    "value": "Cisumur"
  },
  {
    "id": "3301102004",
    "district_id": "330110",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3301102005",
    "district_id": "330110",
    "label": "Cinangsi",
    "value": "Cinangsi"
  },
  {
    "id": "3301102006",
    "district_id": "330110",
    "label": "Karanggintung",
    "value": "Karanggintung"
  },
  {
    "id": "3301102007",
    "district_id": "330110",
    "label": "Rungkang",
    "value": "Rungkang"
  },
  {
    "id": "3301102008",
    "district_id": "330110",
    "label": "Sidaurip",
    "value": "Sidaurip"
  },
  {
    "id": "3301102009",
    "district_id": "330110",
    "label": "Gintungreja",
    "value": "Gintungreja"
  },
  {
    "id": "3301102010",
    "district_id": "330110",
    "label": "Layansari",
    "value": "Layansari"
  },
  {
    "id": "3301102011",
    "district_id": "330110",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3301102012",
    "district_id": "330110",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "3301102013",
    "district_id": "330110",
    "label": "Wringinharjo",
    "value": "Wringinharjo"
  },
  {
    "id": "3301102014",
    "district_id": "330110",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3301112001",
    "district_id": "330111",
    "label": "Tinggarjaya",
    "value": "Tinggarjaya"
  },
  {
    "id": "3301112002",
    "district_id": "330111",
    "label": "Sidareja",
    "value": "Sidareja"
  },
  {
    "id": "3301112003",
    "district_id": "330111",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3301112004",
    "district_id": "330111",
    "label": "Kunci",
    "value": "Kunci"
  },
  {
    "id": "3301112005",
    "district_id": "330111",
    "label": "Karanggedang",
    "value": "Karanggedang"
  },
  {
    "id": "3301112006",
    "district_id": "330111",
    "label": "Penyarang",
    "value": "Penyarang"
  },
  {
    "id": "3301112007",
    "district_id": "330111",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3301112008",
    "district_id": "330111",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3301112009",
    "district_id": "330111",
    "label": "Gunungreja",
    "value": "Gunungreja"
  },
  {
    "id": "3301112010",
    "district_id": "330111",
    "label": "Sudagaran",
    "value": "Sudagaran"
  },
  {
    "id": "3301122001",
    "district_id": "330112",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3301122002",
    "district_id": "330112",
    "label": "Pangawaren",
    "value": "Pangawaren"
  },
  {
    "id": "3301122003",
    "district_id": "330112",
    "label": "Gunungtelu",
    "value": "Gunungtelu"
  },
  {
    "id": "3301122004",
    "district_id": "330112",
    "label": "Sindangbarang",
    "value": "Sindangbarang"
  },
  {
    "id": "3301122005",
    "district_id": "330112",
    "label": "Karangpucung",
    "value": "Karangpucung"
  },
  {
    "id": "3301122006",
    "district_id": "330112",
    "label": "Ciporos",
    "value": "Ciporos"
  },
  {
    "id": "3301122007",
    "district_id": "330112",
    "label": "Tayem",
    "value": "Tayem"
  },
  {
    "id": "3301122008",
    "district_id": "330112",
    "label": "Bengbulang",
    "value": "Bengbulang"
  },
  {
    "id": "3301122009",
    "district_id": "330112",
    "label": "Surusunda",
    "value": "Surusunda"
  },
  {
    "id": "3301122010",
    "district_id": "330112",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3301122011",
    "district_id": "330112",
    "label": "Ciruyung",
    "value": "Ciruyung"
  },
  {
    "id": "3301122012",
    "district_id": "330112",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3301122013",
    "district_id": "330112",
    "label": "Tayem Timur",
    "value": "Tayem Timur"
  },
  {
    "id": "3301122014",
    "district_id": "330112",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3301132001",
    "district_id": "330113",
    "label": "Panimbang",
    "value": "Panimbang"
  },
  {
    "id": "3301132002",
    "district_id": "330113",
    "label": "Bantarmangu",
    "value": "Bantarmangu"
  },
  {
    "id": "3301132003",
    "district_id": "330113",
    "label": "Bantarpanjang",
    "value": "Bantarpanjang"
  },
  {
    "id": "3301132004",
    "district_id": "330113",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3301132005",
    "district_id": "330113",
    "label": "Cilempuyang",
    "value": "Cilempuyang"
  },
  {
    "id": "3301132006",
    "district_id": "330113",
    "label": "Negarajati",
    "value": "Negarajati"
  },
  {
    "id": "3301132007",
    "district_id": "330113",
    "label": "Cisalak",
    "value": "Cisalak"
  },
  {
    "id": "3301132008",
    "district_id": "330113",
    "label": "Cibalung",
    "value": "Cibalung"
  },
  {
    "id": "3301132009",
    "district_id": "330113",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3301132010",
    "district_id": "330113",
    "label": "Kutabima",
    "value": "Kutabima"
  },
  {
    "id": "3301132011",
    "district_id": "330113",
    "label": "Pesahangan",
    "value": "Pesahangan"
  },
  {
    "id": "3301132012",
    "district_id": "330113",
    "label": "Cijati",
    "value": "Cijati"
  },
  {
    "id": "3301132013",
    "district_id": "330113",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3301132014",
    "district_id": "330113",
    "label": "Rejodadi",
    "value": "Rejodadi"
  },
  {
    "id": "3301132015",
    "district_id": "330113",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "3301142001",
    "district_id": "330114",
    "label": "Pahonjean",
    "value": "Pahonjean"
  },
  {
    "id": "3301142002",
    "district_id": "330114",
    "label": "Salebu",
    "value": "Salebu"
  },
  {
    "id": "3301142003",
    "district_id": "330114",
    "label": "Cibeunying",
    "value": "Cibeunying"
  },
  {
    "id": "3301142004",
    "district_id": "330114",
    "label": "Jenang",
    "value": "Jenang"
  },
  {
    "id": "3301142005",
    "district_id": "330114",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3301142006",
    "district_id": "330114",
    "label": "Cilopadang",
    "value": "Cilopadang"
  },
  {
    "id": "3301142007",
    "district_id": "330114",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3301142008",
    "district_id": "330114",
    "label": "Boja",
    "value": "Boja"
  },
  {
    "id": "3301142009",
    "district_id": "330114",
    "label": "Ujungbarang",
    "value": "Ujungbarang"
  },
  {
    "id": "3301142010",
    "district_id": "330114",
    "label": "Pengadegan",
    "value": "Pengadegan"
  },
  {
    "id": "3301142011",
    "district_id": "330114",
    "label": "Sepatnunggal",
    "value": "Sepatnunggal"
  },
  {
    "id": "3301142012",
    "district_id": "330114",
    "label": "Sadabumi",
    "value": "Sadabumi"
  },
  {
    "id": "3301142013",
    "district_id": "330114",
    "label": "Sadahayu",
    "value": "Sadahayu"
  },
  {
    "id": "3301142014",
    "district_id": "330114",
    "label": "Mulyadadi",
    "value": "Mulyadadi"
  },
  {
    "id": "3301142015",
    "district_id": "330114",
    "label": "Padangjaya",
    "value": "Padangjaya"
  },
  {
    "id": "3301142016",
    "district_id": "330114",
    "label": "Padangsari",
    "value": "Padangsari"
  },
  {
    "id": "3301142017",
    "district_id": "330114",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "3301152001",
    "district_id": "330115",
    "label": "Tarisi",
    "value": "Tarisi"
  },
  {
    "id": "3301152002",
    "district_id": "330115",
    "label": "Bantar",
    "value": "Bantar"
  },
  {
    "id": "3301152003",
    "district_id": "330115",
    "label": "Wanareja",
    "value": "Wanareja"
  },
  {
    "id": "3301152004",
    "district_id": "330115",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3301152005",
    "district_id": "330115",
    "label": "Malabar",
    "value": "Malabar"
  },
  {
    "id": "3301152006",
    "district_id": "330115",
    "label": "Majingklak",
    "value": "Majingklak"
  },
  {
    "id": "3301152007",
    "district_id": "330115",
    "label": "Madura",
    "value": "Madura"
  },
  {
    "id": "3301152008",
    "district_id": "330115",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3301152009",
    "district_id": "330115",
    "label": "Palugon",
    "value": "Palugon"
  },
  {
    "id": "3301152010",
    "district_id": "330115",
    "label": "Cigintung",
    "value": "Cigintung"
  },
  {
    "id": "3301152011",
    "district_id": "330115",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3301152012",
    "district_id": "330115",
    "label": "Adimulya",
    "value": "Adimulya"
  },
  {
    "id": "3301152013",
    "district_id": "330115",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3301152014",
    "district_id": "330115",
    "label": "Cilongkrang",
    "value": "Cilongkrang"
  },
  {
    "id": "3301152015",
    "district_id": "330115",
    "label": "Purwasari",
    "value": "Purwasari"
  },
  {
    "id": "3301152016",
    "district_id": "330115",
    "label": "Madusari",
    "value": "Madusari"
  },
  {
    "id": "3301162001",
    "district_id": "330116",
    "label": "Panulisan",
    "value": "Panulisan"
  },
  {
    "id": "3301162002",
    "district_id": "330116",
    "label": "Matenggeng",
    "value": "Matenggeng"
  },
  {
    "id": "3301162003",
    "district_id": "330116",
    "label": "Ciwalen",
    "value": "Ciwalen"
  },
  {
    "id": "3301162004",
    "district_id": "330116",
    "label": "Dayeuhluhur",
    "value": "Dayeuhluhur"
  },
  {
    "id": "3301162005",
    "district_id": "330116",
    "label": "Hanum",
    "value": "Hanum"
  },
  {
    "id": "3301162006",
    "district_id": "330116",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "3301162007",
    "district_id": "330116",
    "label": "Bingkeng",
    "value": "Bingkeng"
  },
  {
    "id": "3301162008",
    "district_id": "330116",
    "label": "Bolang",
    "value": "Bolang"
  },
  {
    "id": "3301162009",
    "district_id": "330116",
    "label": "Kutaagung",
    "value": "Kutaagung"
  },
  {
    "id": "3301162010",
    "district_id": "330116",
    "label": "Cijeruk",
    "value": "Cijeruk"
  },
  {
    "id": "3301162011",
    "district_id": "330116",
    "label": "Cilumping",
    "value": "Cilumping"
  },
  {
    "id": "3301162012",
    "district_id": "330116",
    "label": "Sumpinghayu",
    "value": "Sumpinghayu"
  },
  {
    "id": "3301162013",
    "district_id": "330116",
    "label": "Panulisan Barat",
    "value": "Panulisan Barat"
  },
  {
    "id": "3301162014",
    "district_id": "330116",
    "label": "Panulisan Timur",
    "value": "Panulisan Timur"
  },
  {
    "id": "3301172001",
    "district_id": "330117",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3301172002",
    "district_id": "330117",
    "label": "Brani",
    "value": "Brani"
  },
  {
    "id": "3301172003",
    "district_id": "330117",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "3301172004",
    "district_id": "330117",
    "label": "Sidasari",
    "value": "Sidasari"
  },
  {
    "id": "3301172005",
    "district_id": "330117",
    "label": "Paketingan",
    "value": "Paketingan"
  },
  {
    "id": "3301172006",
    "district_id": "330117",
    "label": "Ketanggung",
    "value": "Ketanggung"
  },
  {
    "id": "3301172007",
    "district_id": "330117",
    "label": "Nusajati",
    "value": "Nusajati"
  },
  {
    "id": "3301172008",
    "district_id": "330117",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3301172009",
    "district_id": "330117",
    "label": "Paberasan",
    "value": "Paberasan"
  },
  {
    "id": "3301172010",
    "district_id": "330117",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3301182001",
    "district_id": "330118",
    "label": "Caruy",
    "value": "Caruy"
  },
  {
    "id": "3301182002",
    "district_id": "330118",
    "label": "Segaralangu",
    "value": "Segaralangu"
  },
  {
    "id": "3301182003",
    "district_id": "330118",
    "label": "Pegadingan",
    "value": "Pegadingan"
  },
  {
    "id": "3301182004",
    "district_id": "330118",
    "label": "Cisuru",
    "value": "Cisuru"
  },
  {
    "id": "3301182005",
    "district_id": "330118",
    "label": "Cipari",
    "value": "Cipari"
  },
  {
    "id": "3301182006",
    "district_id": "330118",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3301182007",
    "district_id": "330118",
    "label": "Mulyadadi",
    "value": "Mulyadadi"
  },
  {
    "id": "3301182008",
    "district_id": "330118",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3301182009",
    "district_id": "330118",
    "label": "Kutasari",
    "value": "Kutasari"
  },
  {
    "id": "3301182010",
    "district_id": "330118",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3301182011",
    "district_id": "330118",
    "label": "Sidasari",
    "value": "Sidasari"
  },
  {
    "id": "3301192001",
    "district_id": "330119",
    "label": "Patimuan",
    "value": "Patimuan"
  },
  {
    "id": "3301192002",
    "district_id": "330119",
    "label": "Rawaapu",
    "value": "Rawaapu"
  },
  {
    "id": "3301192003",
    "district_id": "330119",
    "label": "Sidamukti",
    "value": "Sidamukti"
  },
  {
    "id": "3301192004",
    "district_id": "330119",
    "label": "Purwadadi",
    "value": "Purwadadi"
  },
  {
    "id": "3301192005",
    "district_id": "330119",
    "label": "Cinyawang",
    "value": "Cinyawang"
  },
  {
    "id": "3301192006",
    "district_id": "330119",
    "label": "Bulupayung",
    "value": "Bulupayung"
  },
  {
    "id": "3301192007",
    "district_id": "330119",
    "label": "Cimrutu",
    "value": "Cimrutu"
  },
  {
    "id": "3301202001",
    "district_id": "330120",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3301202002",
    "district_id": "330120",
    "label": "Bantarsari",
    "value": "Bantarsari"
  },
  {
    "id": "3301202003",
    "district_id": "330120",
    "label": "Cikedondong",
    "value": "Cikedondong"
  },
  {
    "id": "3301202004",
    "district_id": "330120",
    "label": "Kedungwadas",
    "value": "Kedungwadas"
  },
  {
    "id": "3301202005",
    "district_id": "330120",
    "label": "Citembong",
    "value": "Citembong"
  },
  {
    "id": "3301202006",
    "district_id": "330120",
    "label": "Kamulyan",
    "value": "Kamulyan"
  },
  {
    "id": "3301202007",
    "district_id": "330120",
    "label": "Rawajaya",
    "value": "Rawajaya"
  },
  {
    "id": "3301202008",
    "district_id": "330120",
    "label": "Bulaksari",
    "value": "Bulaksari"
  },
  {
    "id": "3301211001",
    "district_id": "330121",
    "label": "Sidakaya",
    "value": "Sidakaya"
  },
  {
    "id": "3301211002",
    "district_id": "330121",
    "label": "Cilacap",
    "value": "Cilacap"
  },
  {
    "id": "3301211003",
    "district_id": "330121",
    "label": "Tambakreja",
    "value": "Tambakreja"
  },
  {
    "id": "3301211004",
    "district_id": "330121",
    "label": "Tegalkamulyan",
    "value": "Tegalkamulyan"
  },
  {
    "id": "3301211005",
    "district_id": "330121",
    "label": "Tegalreja",
    "value": "Tegalreja"
  },
  {
    "id": "3301221001",
    "district_id": "330122",
    "label": "Lomanis",
    "value": "Lomanis"
  },
  {
    "id": "3301221002",
    "district_id": "330122",
    "label": "Gunungsimping",
    "value": "Gunungsimping"
  },
  {
    "id": "3301221003",
    "district_id": "330122",
    "label": "Sidanegara",
    "value": "Sidanegara"
  },
  {
    "id": "3301221004",
    "district_id": "330122",
    "label": "Donan",
    "value": "Donan"
  },
  {
    "id": "3301221005",
    "district_id": "330122",
    "label": "Kutawaru",
    "value": "Kutawaru"
  },
  {
    "id": "3301231001",
    "district_id": "330123",
    "label": "Mertasinga",
    "value": "Mertasinga"
  },
  {
    "id": "3301231002",
    "district_id": "330123",
    "label": "Gumilir",
    "value": "Gumilir"
  },
  {
    "id": "3301231003",
    "district_id": "330123",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3301231004",
    "district_id": "330123",
    "label": "Tritih Kulon",
    "value": "Tritih Kulon"
  },
  {
    "id": "3301231005",
    "district_id": "330123",
    "label": "Kebonmanis",
    "value": "Kebonmanis"
  },
  {
    "id": "3301242001",
    "district_id": "330124",
    "label": "Ujunggagak",
    "value": "Ujunggagak"
  },
  {
    "id": "3301242002",
    "district_id": "330124",
    "label": "Ujungalang",
    "value": "Ujungalang"
  },
  {
    "id": "3301242003",
    "district_id": "330124",
    "label": "Panikel",
    "value": "Panikel"
  },
  {
    "id": "3301242004",
    "district_id": "330124",
    "label": "Klaces",
    "value": "Klaces"
  },
  {
    "id": "3302012001",
    "district_id": "330201",
    "label": "Cirahab",
    "value": "Cirahab"
  },
  {
    "id": "3302012002",
    "district_id": "330201",
    "label": "Canduk",
    "value": "Canduk"
  },
  {
    "id": "3302012003",
    "district_id": "330201",
    "label": "Parungkamal",
    "value": "Parungkamal"
  },
  {
    "id": "3302012004",
    "district_id": "330201",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3302012005",
    "district_id": "330201",
    "label": "Karanggayam",
    "value": "Karanggayam"
  },
  {
    "id": "3302012006",
    "district_id": "330201",
    "label": "Cidora",
    "value": "Cidora"
  },
  {
    "id": "3302012007",
    "district_id": "330201",
    "label": "Lumbir",
    "value": "Lumbir"
  },
  {
    "id": "3302012008",
    "district_id": "330201",
    "label": "Dermaji",
    "value": "Dermaji"
  },
  {
    "id": "3302012009",
    "district_id": "330201",
    "label": "Kedunggede",
    "value": "Kedunggede"
  },
  {
    "id": "3302012010",
    "district_id": "330201",
    "label": "Cingebul",
    "value": "Cingebul"
  },
  {
    "id": "3302022001",
    "district_id": "330202",
    "label": "Randegan",
    "value": "Randegan"
  },
  {
    "id": "3302022002",
    "district_id": "330202",
    "label": "Rawaheng",
    "value": "Rawaheng"
  },
  {
    "id": "3302022003",
    "district_id": "330202",
    "label": "Pengadegan",
    "value": "Pengadegan"
  },
  {
    "id": "3302022004",
    "district_id": "330202",
    "label": "Klapagading",
    "value": "Klapagading"
  },
  {
    "id": "3302022005",
    "district_id": "330202",
    "label": "Wangon",
    "value": "Wangon"
  },
  {
    "id": "3302022006",
    "district_id": "330202",
    "label": "Banteran",
    "value": "Banteran"
  },
  {
    "id": "3302022007",
    "district_id": "330202",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3302022008",
    "district_id": "330202",
    "label": "Jurangbahas",
    "value": "Jurangbahas"
  },
  {
    "id": "3302022009",
    "district_id": "330202",
    "label": "Cikakak",
    "value": "Cikakak"
  },
  {
    "id": "3302022010",
    "district_id": "330202",
    "label": "Wlahar",
    "value": "Wlahar"
  },
  {
    "id": "3302022011",
    "district_id": "330202",
    "label": "Windunegara",
    "value": "Windunegara"
  },
  {
    "id": "3302022012",
    "district_id": "330202",
    "label": "Klapagading Kulon",
    "value": "Klapagading Kulon"
  },
  {
    "id": "3302032001",
    "district_id": "330203",
    "label": "Gunung Wetan",
    "value": "Gunung Wetan"
  },
  {
    "id": "3302032002",
    "district_id": "330203",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3302032003",
    "district_id": "330203",
    "label": "Karanglewas",
    "value": "Karanglewas"
  },
  {
    "id": "3302032004",
    "district_id": "330203",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3302032005",
    "district_id": "330203",
    "label": "Margasana",
    "value": "Margasana"
  },
  {
    "id": "3302032006",
    "district_id": "330203",
    "label": "Adisara",
    "value": "Adisara"
  },
  {
    "id": "3302032007",
    "district_id": "330203",
    "label": "Kedungwringin",
    "value": "Kedungwringin"
  },
  {
    "id": "3302032008",
    "district_id": "330203",
    "label": "Bantar",
    "value": "Bantar"
  },
  {
    "id": "3302032009",
    "district_id": "330203",
    "label": "Tinggarjaya",
    "value": "Tinggarjaya"
  },
  {
    "id": "3302032010",
    "district_id": "330203",
    "label": "Tunjung",
    "value": "Tunjung"
  },
  {
    "id": "3302032011",
    "district_id": "330203",
    "label": "Gentawangi",
    "value": "Gentawangi"
  },
  {
    "id": "3302042001",
    "district_id": "330204",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3302042002",
    "district_id": "330204",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "3302042003",
    "district_id": "330204",
    "label": "Banjarparakan",
    "value": "Banjarparakan"
  },
  {
    "id": "3302042004",
    "district_id": "330204",
    "label": "Rawalo",
    "value": "Rawalo"
  },
  {
    "id": "3302042005",
    "district_id": "330204",
    "label": "Tambaknegara",
    "value": "Tambaknegara"
  },
  {
    "id": "3302042006",
    "district_id": "330204",
    "label": "Sidamulih",
    "value": "Sidamulih"
  },
  {
    "id": "3302042007",
    "district_id": "330204",
    "label": "Pesawahan",
    "value": "Pesawahan"
  },
  {
    "id": "3302042008",
    "district_id": "330204",
    "label": "Tipar",
    "value": "Tipar"
  },
  {
    "id": "3302042009",
    "district_id": "330204",
    "label": "Sanggreman",
    "value": "Sanggreman"
  },
  {
    "id": "3302052001",
    "district_id": "330205",
    "label": "Adisana",
    "value": "Adisana"
  },
  {
    "id": "3302052002",
    "district_id": "330205",
    "label": "Bangsa",
    "value": "Bangsa"
  },
  {
    "id": "3302052003",
    "district_id": "330205",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3302052004",
    "district_id": "330205",
    "label": "Randegan",
    "value": "Randegan"
  },
  {
    "id": "3302052005",
    "district_id": "330205",
    "label": "Kaliwedi",
    "value": "Kaliwedi"
  },
  {
    "id": "3302052006",
    "district_id": "330205",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3302052007",
    "district_id": "330205",
    "label": "Kalisalak",
    "value": "Kalisalak"
  },
  {
    "id": "3302052008",
    "district_id": "330205",
    "label": "Cindaga",
    "value": "Cindaga"
  },
  {
    "id": "3302052009",
    "district_id": "330205",
    "label": "Kebasen",
    "value": "Kebasen"
  },
  {
    "id": "3302052010",
    "district_id": "330205",
    "label": "Gambarsari",
    "value": "Gambarsari"
  },
  {
    "id": "3302052011",
    "district_id": "330205",
    "label": "Tumiyang",
    "value": "Tumiyang"
  },
  {
    "id": "3302052012",
    "district_id": "330205",
    "label": "Mandirancan",
    "value": "Mandirancan"
  },
  {
    "id": "3302062001",
    "district_id": "330206",
    "label": "Grujugan",
    "value": "Grujugan"
  },
  {
    "id": "3302062002",
    "district_id": "330206",
    "label": "Sirau",
    "value": "Sirau"
  },
  {
    "id": "3302062003",
    "district_id": "330206",
    "label": "Sibalung",
    "value": "Sibalung"
  },
  {
    "id": "3302062004",
    "district_id": "330206",
    "label": "Sibrama",
    "value": "Sibrama"
  },
  {
    "id": "3302062005",
    "district_id": "330206",
    "label": "Kedungpring",
    "value": "Kedungpring"
  },
  {
    "id": "3302062006",
    "district_id": "330206",
    "label": "Kecila",
    "value": "Kecila"
  },
  {
    "id": "3302062007",
    "district_id": "330206",
    "label": "Nusamangir",
    "value": "Nusamangir"
  },
  {
    "id": "3302062008",
    "district_id": "330206",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3302062009",
    "district_id": "330206",
    "label": "Kebarongan",
    "value": "Kebarongan"
  },
  {
    "id": "3302062010",
    "district_id": "330206",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3302062011",
    "district_id": "330206",
    "label": "Pageralang",
    "value": "Pageralang"
  },
  {
    "id": "3302062012",
    "district_id": "330206",
    "label": "Alasmalang",
    "value": "Alasmalang"
  },
  {
    "id": "3302062013",
    "district_id": "330206",
    "label": "Petarangan",
    "value": "Petarangan"
  },
  {
    "id": "3302062014",
    "district_id": "330206",
    "label": "Karanggintung",
    "value": "Karanggintung"
  },
  {
    "id": "3302062015",
    "district_id": "330206",
    "label": "Karangsalam",
    "value": "Karangsalam"
  },
  {
    "id": "3302071012",
    "district_id": "330207",
    "label": "Kebokura",
    "value": "Kebokura"
  },
  {
    "id": "3302071013",
    "district_id": "330207",
    "label": "Sumpiuh",
    "value": "Sumpiuh"
  },
  {
    "id": "3302071014",
    "district_id": "330207",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3302072001",
    "district_id": "330207",
    "label": "Nusadadi",
    "value": "Nusadadi"
  },
  {
    "id": "3302072002",
    "district_id": "330207",
    "label": "Selandaka",
    "value": "Selandaka"
  },
  {
    "id": "3302072003",
    "district_id": "330207",
    "label": "Karanggedang",
    "value": "Karanggedang"
  },
  {
    "id": "3302072004",
    "district_id": "330207",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3302072005",
    "district_id": "330207",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "3302072006",
    "district_id": "330207",
    "label": "Kuntili",
    "value": "Kuntili"
  },
  {
    "id": "3302072007",
    "district_id": "330207",
    "label": "Lebeng",
    "value": "Lebeng"
  },
  {
    "id": "3302072008",
    "district_id": "330207",
    "label": "Selanegara",
    "value": "Selanegara"
  },
  {
    "id": "3302072009",
    "district_id": "330207",
    "label": "Bogangin",
    "value": "Bogangin"
  },
  {
    "id": "3302072010",
    "district_id": "330207",
    "label": "Banjarpanepen",
    "value": "Banjarpanepen"
  },
  {
    "id": "3302072011",
    "district_id": "330207",
    "label": "Ketanda",
    "value": "Ketanda"
  },
  {
    "id": "3302082001",
    "district_id": "330208",
    "label": "Plangkapan",
    "value": "Plangkapan"
  },
  {
    "id": "3302082002",
    "district_id": "330208",
    "label": "Gumelar Lor",
    "value": "Gumelar Lor"
  },
  {
    "id": "3302082003",
    "district_id": "330208",
    "label": "Gumelar Kidul",
    "value": "Gumelar Kidul"
  },
  {
    "id": "3302082004",
    "district_id": "330208",
    "label": "Karangpetir",
    "value": "Karangpetir"
  },
  {
    "id": "3302082005",
    "district_id": "330208",
    "label": "Gebangsari",
    "value": "Gebangsari"
  },
  {
    "id": "3302082006",
    "district_id": "330208",
    "label": "Prembun",
    "value": "Prembun"
  },
  {
    "id": "3302082007",
    "district_id": "330208",
    "label": "Buniayu",
    "value": "Buniayu"
  },
  {
    "id": "3302082008",
    "district_id": "330208",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3302082009",
    "district_id": "330208",
    "label": "Karangpucung",
    "value": "Karangpucung"
  },
  {
    "id": "3302082010",
    "district_id": "330208",
    "label": "Kamulyan",
    "value": "Kamulyan"
  },
  {
    "id": "3302082011",
    "district_id": "330208",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3302082012",
    "district_id": "330208",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3302092001",
    "district_id": "330209",
    "label": "Tanggeran",
    "value": "Tanggeran"
  },
  {
    "id": "3302092002",
    "district_id": "330209",
    "label": "Sokawera",
    "value": "Sokawera"
  },
  {
    "id": "3302092003",
    "district_id": "330209",
    "label": "Somagede",
    "value": "Somagede"
  },
  {
    "id": "3302092004",
    "district_id": "330209",
    "label": "Klinting",
    "value": "Klinting"
  },
  {
    "id": "3302092005",
    "district_id": "330209",
    "label": "Kemawi",
    "value": "Kemawi"
  },
  {
    "id": "3302092006",
    "district_id": "330209",
    "label": "Piasa Kulon",
    "value": "Piasa Kulon"
  },
  {
    "id": "3302092007",
    "district_id": "330209",
    "label": "Kanding",
    "value": "Kanding"
  },
  {
    "id": "3302092008",
    "district_id": "330209",
    "label": "Somakaton",
    "value": "Somakaton"
  },
  {
    "id": "3302092009",
    "district_id": "330209",
    "label": "Plana",
    "value": "Plana"
  },
  {
    "id": "3302102001",
    "district_id": "330210",
    "label": "Srowot",
    "value": "Srowot"
  },
  {
    "id": "3302102002",
    "district_id": "330210",
    "label": "Suro",
    "value": "Suro"
  },
  {
    "id": "3302102003",
    "district_id": "330210",
    "label": "Kaliori",
    "value": "Kaliori"
  },
  {
    "id": "3302102004",
    "district_id": "330210",
    "label": "Wlahar Wetan",
    "value": "Wlahar Wetan"
  },
  {
    "id": "3302102005",
    "district_id": "330210",
    "label": "Pekaja",
    "value": "Pekaja"
  },
  {
    "id": "3302102006",
    "district_id": "330210",
    "label": "Karangdadap",
    "value": "Karangdadap"
  },
  {
    "id": "3302102007",
    "district_id": "330210",
    "label": "Kalibagor",
    "value": "Kalibagor"
  },
  {
    "id": "3302102008",
    "district_id": "330210",
    "label": "Pajerukan",
    "value": "Pajerukan"
  },
  {
    "id": "3302102009",
    "district_id": "330210",
    "label": "Petir",
    "value": "Petir"
  },
  {
    "id": "3302102010",
    "district_id": "330210",
    "label": "Kalicupak Kidul",
    "value": "Kalicupak Kidul"
  },
  {
    "id": "3302102011",
    "district_id": "330210",
    "label": "Kalicupak Lor",
    "value": "Kalicupak Lor"
  },
  {
    "id": "3302102012",
    "district_id": "330210",
    "label": "Kalisogra Wetan",
    "value": "Kalisogra Wetan"
  },
  {
    "id": "3302112001",
    "district_id": "330211",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3302112002",
    "district_id": "330211",
    "label": "Pasinggangan",
    "value": "Pasinggangan"
  },
  {
    "id": "3302112003",
    "district_id": "330211",
    "label": "Kedunggede",
    "value": "Kedunggede"
  },
  {
    "id": "3302112004",
    "district_id": "330211",
    "label": "Karangrau",
    "value": "Karangrau"
  },
  {
    "id": "3302112005",
    "district_id": "330211",
    "label": "Kejawar",
    "value": "Kejawar"
  },
  {
    "id": "3302112006",
    "district_id": "330211",
    "label": "Danaraja",
    "value": "Danaraja"
  },
  {
    "id": "3302112007",
    "district_id": "330211",
    "label": "Kedunguter",
    "value": "Kedunguter"
  },
  {
    "id": "3302112008",
    "district_id": "330211",
    "label": "Sudagaran",
    "value": "Sudagaran"
  },
  {
    "id": "3302112009",
    "district_id": "330211",
    "label": "Pekunden",
    "value": "Pekunden"
  },
  {
    "id": "3302112010",
    "district_id": "330211",
    "label": "Kalisube",
    "value": "Kalisube"
  },
  {
    "id": "3302112011",
    "district_id": "330211",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3302112012",
    "district_id": "330211",
    "label": "Papringan",
    "value": "Papringan"
  },
  {
    "id": "3302122001",
    "district_id": "330212",
    "label": "Wlahar Kulon",
    "value": "Wlahar Kulon"
  },
  {
    "id": "3302122002",
    "district_id": "330212",
    "label": "Sokawera",
    "value": "Sokawera"
  },
  {
    "id": "3302122003",
    "district_id": "330212",
    "label": "Pegalongan",
    "value": "Pegalongan"
  },
  {
    "id": "3302122004",
    "district_id": "330212",
    "label": "Patikraja",
    "value": "Patikraja"
  },
  {
    "id": "3302122005",
    "district_id": "330212",
    "label": "Notog",
    "value": "Notog"
  },
  {
    "id": "3302122006",
    "district_id": "330212",
    "label": "Karangendep",
    "value": "Karangendep"
  },
  {
    "id": "3302122007",
    "district_id": "330212",
    "label": "Sawangan Wetan",
    "value": "Sawangan Wetan"
  },
  {
    "id": "3302122008",
    "district_id": "330212",
    "label": "Kedungwuluh Kidul",
    "value": "Kedungwuluh Kidul"
  },
  {
    "id": "3302122009",
    "district_id": "330212",
    "label": "Kedungrandu",
    "value": "Kedungrandu"
  },
  {
    "id": "3302122010",
    "district_id": "330212",
    "label": "Kedungwuluh Lor",
    "value": "Kedungwuluh Lor"
  },
  {
    "id": "3302122011",
    "district_id": "330212",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3302122012",
    "district_id": "330212",
    "label": "Sidabowa",
    "value": "Sidabowa"
  },
  {
    "id": "3302122013",
    "district_id": "330212",
    "label": "Kedungwringin",
    "value": "Kedungwringin"
  },
  {
    "id": "3302132001",
    "district_id": "330213",
    "label": "Gerduren",
    "value": "Gerduren"
  },
  {
    "id": "3302132002",
    "district_id": "330213",
    "label": "Karangtalun Kidul",
    "value": "Karangtalun Kidul"
  },
  {
    "id": "3302132003",
    "district_id": "330213",
    "label": "Kaliurip",
    "value": "Kaliurip"
  },
  {
    "id": "3302132004",
    "district_id": "330213",
    "label": "Karangtalun Lor",
    "value": "Karangtalun Lor"
  },
  {
    "id": "3302132005",
    "district_id": "330213",
    "label": "Purwojati",
    "value": "Purwojati"
  },
  {
    "id": "3302132006",
    "district_id": "330213",
    "label": "Klapasawit",
    "value": "Klapasawit"
  },
  {
    "id": "3302132007",
    "district_id": "330213",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3302132008",
    "district_id": "330213",
    "label": "Kaliputih",
    "value": "Kaliputih"
  },
  {
    "id": "3302132009",
    "district_id": "330213",
    "label": "Kaliwangi",
    "value": "Kaliwangi"
  },
  {
    "id": "3302132010",
    "district_id": "330213",
    "label": "Kalitapen",
    "value": "Kalitapen"
  },
  {
    "id": "3302142001",
    "district_id": "330214",
    "label": "Darmakradenan",
    "value": "Darmakradenan"
  },
  {
    "id": "3302142002",
    "district_id": "330214",
    "label": "Tipar Kidul",
    "value": "Tipar Kidul"
  },
  {
    "id": "3302142003",
    "district_id": "330214",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3302142004",
    "district_id": "330214",
    "label": "Jingkang",
    "value": "Jingkang"
  },
  {
    "id": "3302142005",
    "district_id": "330214",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3302142006",
    "district_id": "330214",
    "label": "Kalibenda",
    "value": "Kalibenda"
  },
  {
    "id": "3302142007",
    "district_id": "330214",
    "label": "Pancurendang",
    "value": "Pancurendang"
  },
  {
    "id": "3302142008",
    "district_id": "330214",
    "label": "Pancasan",
    "value": "Pancasan"
  },
  {
    "id": "3302142009",
    "district_id": "330214",
    "label": "Karangbawang",
    "value": "Karangbawang"
  },
  {
    "id": "3302142010",
    "district_id": "330214",
    "label": "Kracak",
    "value": "Kracak"
  },
  {
    "id": "3302142011",
    "district_id": "330214",
    "label": "Ajibarang Kulon",
    "value": "Ajibarang Kulon"
  },
  {
    "id": "3302142012",
    "district_id": "330214",
    "label": "Ajibarang Wetan",
    "value": "Ajibarang Wetan"
  },
  {
    "id": "3302142013",
    "district_id": "330214",
    "label": "Lesmana",
    "value": "Lesmana"
  },
  {
    "id": "3302142014",
    "district_id": "330214",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3302142015",
    "district_id": "330214",
    "label": "Ciberung",
    "value": "Ciberung"
  },
  {
    "id": "3302152001",
    "district_id": "330215",
    "label": "Karangkemojing",
    "value": "Karangkemojing"
  },
  {
    "id": "3302152002",
    "district_id": "330215",
    "label": "Paningkaban",
    "value": "Paningkaban"
  },
  {
    "id": "3302152003",
    "district_id": "330215",
    "label": "Cihonje",
    "value": "Cihonje"
  },
  {
    "id": "3302152004",
    "district_id": "330215",
    "label": "Gancang",
    "value": "Gancang"
  },
  {
    "id": "3302152005",
    "district_id": "330215",
    "label": "Kedungurang",
    "value": "Kedungurang"
  },
  {
    "id": "3302152006",
    "district_id": "330215",
    "label": "Gumelar",
    "value": "Gumelar"
  },
  {
    "id": "3302152007",
    "district_id": "330215",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3302152008",
    "district_id": "330215",
    "label": "Tlaga",
    "value": "Tlaga"
  },
  {
    "id": "3302152009",
    "district_id": "330215",
    "label": "Samudra",
    "value": "Samudra"
  },
  {
    "id": "3302152010",
    "district_id": "330215",
    "label": "Samudra Kulon",
    "value": "Samudra Kulon"
  },
  {
    "id": "3302162001",
    "district_id": "330216",
    "label": "Cikembulan",
    "value": "Cikembulan"
  },
  {
    "id": "3302162002",
    "district_id": "330216",
    "label": "Candinegara",
    "value": "Candinegara"
  },
  {
    "id": "3302162003",
    "district_id": "330216",
    "label": "Karangklesem",
    "value": "Karangklesem"
  },
  {
    "id": "3302162004",
    "district_id": "330216",
    "label": "Cikawung",
    "value": "Cikawung"
  },
  {
    "id": "3302162005",
    "district_id": "330216",
    "label": "Cibangkong",
    "value": "Cibangkong"
  },
  {
    "id": "3302162006",
    "district_id": "330216",
    "label": "Petahunan",
    "value": "Petahunan"
  },
  {
    "id": "3302162007",
    "district_id": "330216",
    "label": "Semedo",
    "value": "Semedo"
  },
  {
    "id": "3302162008",
    "district_id": "330216",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3302162009",
    "district_id": "330216",
    "label": "Pasiraman Lor",
    "value": "Pasiraman Lor"
  },
  {
    "id": "3302162010",
    "district_id": "330216",
    "label": "Pasiraman Kidul",
    "value": "Pasiraman Kidul"
  },
  {
    "id": "3302162011",
    "district_id": "330216",
    "label": "Tumiyang",
    "value": "Tumiyang"
  },
  {
    "id": "3302162012",
    "district_id": "330216",
    "label": "Glempang",
    "value": "Glempang"
  },
  {
    "id": "3302162013",
    "district_id": "330216",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3302162014",
    "district_id": "330216",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3302162015",
    "district_id": "330216",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3302162016",
    "district_id": "330216",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3302172001",
    "district_id": "330217",
    "label": "Panusupan",
    "value": "Panusupan"
  },
  {
    "id": "3302172002",
    "district_id": "330217",
    "label": "Jatisaba",
    "value": "Jatisaba"
  },
  {
    "id": "3302172003",
    "district_id": "330217",
    "label": "Kasegeran",
    "value": "Kasegeran"
  },
  {
    "id": "3302172004",
    "district_id": "330217",
    "label": "Pejogol",
    "value": "Pejogol"
  },
  {
    "id": "3302172005",
    "district_id": "330217",
    "label": "Langgongsari",
    "value": "Langgongsari"
  },
  {
    "id": "3302172006",
    "district_id": "330217",
    "label": "Pageraji",
    "value": "Pageraji"
  },
  {
    "id": "3302172007",
    "district_id": "330217",
    "label": "Sudimara",
    "value": "Sudimara"
  },
  {
    "id": "3302172008",
    "district_id": "330217",
    "label": "Batuanten",
    "value": "Batuanten"
  },
  {
    "id": "3302172009",
    "district_id": "330217",
    "label": "Cipete",
    "value": "Cipete"
  },
  {
    "id": "3302172010",
    "district_id": "330217",
    "label": "Cilongok",
    "value": "Cilongok"
  },
  {
    "id": "3302172011",
    "district_id": "330217",
    "label": "Pernasidi",
    "value": "Pernasidi"
  },
  {
    "id": "3302172012",
    "district_id": "330217",
    "label": "Cikidang",
    "value": "Cikidang"
  },
  {
    "id": "3302172013",
    "district_id": "330217",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3302172014",
    "district_id": "330217",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3302172015",
    "district_id": "330217",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3302172016",
    "district_id": "330217",
    "label": "Panembangan",
    "value": "Panembangan"
  },
  {
    "id": "3302172017",
    "district_id": "330217",
    "label": "Rancamaya",
    "value": "Rancamaya"
  },
  {
    "id": "3302172018",
    "district_id": "330217",
    "label": "Sambirata",
    "value": "Sambirata"
  },
  {
    "id": "3302172019",
    "district_id": "330217",
    "label": "Gununglurah",
    "value": "Gununglurah"
  },
  {
    "id": "3302172020",
    "district_id": "330217",
    "label": "Sokawera",
    "value": "Sokawera"
  },
  {
    "id": "3302182001",
    "district_id": "330218",
    "label": "Kediri",
    "value": "Kediri"
  },
  {
    "id": "3302182002",
    "district_id": "330218",
    "label": "Pangebatan",
    "value": "Pangebatan"
  },
  {
    "id": "3302182003",
    "district_id": "330218",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3302182004",
    "district_id": "330218",
    "label": "Karanglewas Kidul",
    "value": "Karanglewas Kidul"
  },
  {
    "id": "3302182005",
    "district_id": "330218",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3302182006",
    "district_id": "330218",
    "label": "Pasir Wetan",
    "value": "Pasir Wetan"
  },
  {
    "id": "3302182007",
    "district_id": "330218",
    "label": "Pasir Lor",
    "value": "Pasir Lor"
  },
  {
    "id": "3302182008",
    "district_id": "330218",
    "label": "Pasir Kulon",
    "value": "Pasir Kulon"
  },
  {
    "id": "3302182009",
    "district_id": "330218",
    "label": "Jipang",
    "value": "Jipang"
  },
  {
    "id": "3302182010",
    "district_id": "330218",
    "label": "Karanggude",
    "value": "Karanggude"
  },
  {
    "id": "3302182011",
    "district_id": "330218",
    "label": "Singasari",
    "value": "Singasari"
  },
  {
    "id": "3302182012",
    "district_id": "330218",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3302182013",
    "district_id": "330218",
    "label": "Sunyalangu",
    "value": "Sunyalangu"
  },
  {
    "id": "3302192001",
    "district_id": "330219",
    "label": "Kalikidang",
    "value": "Kalikidang"
  },
  {
    "id": "3302192002",
    "district_id": "330219",
    "label": "Wiradadi",
    "value": "Wiradadi"
  },
  {
    "id": "3302192003",
    "district_id": "330219",
    "label": "Karangkedawung",
    "value": "Karangkedawung"
  },
  {
    "id": "3302192004",
    "district_id": "330219",
    "label": "Sokaraja Tengah",
    "value": "Sokaraja Tengah"
  },
  {
    "id": "3302192005",
    "district_id": "330219",
    "label": "Sokaraja Kidul",
    "value": "Sokaraja Kidul"
  },
  {
    "id": "3302192006",
    "district_id": "330219",
    "label": "Klahang",
    "value": "Klahang"
  },
  {
    "id": "3302192007",
    "district_id": "330219",
    "label": "Banjarsari Kidul",
    "value": "Banjarsari Kidul"
  },
  {
    "id": "3302192008",
    "district_id": "330219",
    "label": "Sokaraja Wetan",
    "value": "Sokaraja Wetan"
  },
  {
    "id": "3302192009",
    "district_id": "330219",
    "label": "Jompo Kulon",
    "value": "Jompo Kulon"
  },
  {
    "id": "3302192010",
    "district_id": "330219",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3302192011",
    "district_id": "330219",
    "label": "Lemberang",
    "value": "Lemberang"
  },
  {
    "id": "3302192012",
    "district_id": "330219",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3302192013",
    "district_id": "330219",
    "label": "Sokaraja Lor",
    "value": "Sokaraja Lor"
  },
  {
    "id": "3302192014",
    "district_id": "330219",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "3302192015",
    "district_id": "330219",
    "label": "Pamijen",
    "value": "Pamijen"
  },
  {
    "id": "3302192016",
    "district_id": "330219",
    "label": "Sokaraja Kulon",
    "value": "Sokaraja Kulon"
  },
  {
    "id": "3302192017",
    "district_id": "330219",
    "label": "Karangnanas",
    "value": "Karangnanas"
  },
  {
    "id": "3302192018",
    "district_id": "330219",
    "label": "Karangrau",
    "value": "Karangrau"
  },
  {
    "id": "3302202001",
    "district_id": "330220",
    "label": "Ledug",
    "value": "Ledug"
  },
  {
    "id": "3302202002",
    "district_id": "330220",
    "label": "Pliken",
    "value": "Pliken"
  },
  {
    "id": "3302202003",
    "district_id": "330220",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3302202004",
    "district_id": "330220",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3302202005",
    "district_id": "330220",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3302202006",
    "district_id": "330220",
    "label": "Sambeng Wetan",
    "value": "Sambeng Wetan"
  },
  {
    "id": "3302202007",
    "district_id": "330220",
    "label": "Sambeng Kulon",
    "value": "Sambeng Kulon"
  },
  {
    "id": "3302202008",
    "district_id": "330220",
    "label": "Purbadana",
    "value": "Purbadana"
  },
  {
    "id": "3302202009",
    "district_id": "330220",
    "label": "Kembaran",
    "value": "Kembaran"
  },
  {
    "id": "3302202010",
    "district_id": "330220",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3302202011",
    "district_id": "330220",
    "label": "Karangsoka",
    "value": "Karangsoka"
  },
  {
    "id": "3302202012",
    "district_id": "330220",
    "label": "Dukuhwaluh",
    "value": "Dukuhwaluh"
  },
  {
    "id": "3302202013",
    "district_id": "330220",
    "label": "Tambaksari Kidul",
    "value": "Tambaksari Kidul"
  },
  {
    "id": "3302202014",
    "district_id": "330220",
    "label": "Bantarwuni",
    "value": "Bantarwuni"
  },
  {
    "id": "3302202015",
    "district_id": "330220",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3302202016",
    "district_id": "330220",
    "label": "Linggasari",
    "value": "Linggasari"
  },
  {
    "id": "3302212001",
    "district_id": "330221",
    "label": "Silado",
    "value": "Silado"
  },
  {
    "id": "3302212002",
    "district_id": "330221",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3302212003",
    "district_id": "330221",
    "label": "Karangcegak",
    "value": "Karangcegak"
  },
  {
    "id": "3302212004",
    "district_id": "330221",
    "label": "Sumbang",
    "value": "Sumbang"
  },
  {
    "id": "3302212005",
    "district_id": "330221",
    "label": "Tambaksogra",
    "value": "Tambaksogra"
  },
  {
    "id": "3302212006",
    "district_id": "330221",
    "label": "Kebanggan",
    "value": "Kebanggan"
  },
  {
    "id": "3302212007",
    "district_id": "330221",
    "label": "Kawungcarang",
    "value": "Kawungcarang"
  },
  {
    "id": "3302212008",
    "district_id": "330221",
    "label": "Karanggintung",
    "value": "Karanggintung"
  },
  {
    "id": "3302212009",
    "district_id": "330221",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "3302212010",
    "district_id": "330221",
    "label": "Banjarsari Kulon",
    "value": "Banjarsari Kulon"
  },
  {
    "id": "3302212011",
    "district_id": "330221",
    "label": "Banjarsari Wetan",
    "value": "Banjarsari Wetan"
  },
  {
    "id": "3302212012",
    "district_id": "330221",
    "label": "Banteran",
    "value": "Banteran"
  },
  {
    "id": "3302212013",
    "district_id": "330221",
    "label": "Ciberem",
    "value": "Ciberem"
  },
  {
    "id": "3302212014",
    "district_id": "330221",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3302212015",
    "district_id": "330221",
    "label": "Sikapat",
    "value": "Sikapat"
  },
  {
    "id": "3302212016",
    "district_id": "330221",
    "label": "Gandatapa",
    "value": "Gandatapa"
  },
  {
    "id": "3302212017",
    "district_id": "330221",
    "label": "Kotayasa",
    "value": "Kotayasa"
  },
  {
    "id": "3302212018",
    "district_id": "330221",
    "label": "Limpakuwus",
    "value": "Limpakuwus"
  },
  {
    "id": "3302212019",
    "district_id": "330221",
    "label": "Kedungmalang",
    "value": "Kedungmalang"
  },
  {
    "id": "3302222001",
    "district_id": "330222",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3302222002",
    "district_id": "330222",
    "label": "Kutasari",
    "value": "Kutasari"
  },
  {
    "id": "3302222003",
    "district_id": "330222",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "3302222004",
    "district_id": "330222",
    "label": "Pamijen",
    "value": "Pamijen"
  },
  {
    "id": "3302222005",
    "district_id": "330222",
    "label": "Rempoah",
    "value": "Rempoah"
  },
  {
    "id": "3302222006",
    "district_id": "330222",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3302222007",
    "district_id": "330222",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3302222008",
    "district_id": "330222",
    "label": "Kemutug Kidul",
    "value": "Kemutug Kidul"
  },
  {
    "id": "3302222009",
    "district_id": "330222",
    "label": "Karangsalam Lor",
    "value": "Karangsalam Lor"
  },
  {
    "id": "3302222010",
    "district_id": "330222",
    "label": "Kemutug Lor",
    "value": "Kemutug Lor"
  },
  {
    "id": "3302222011",
    "district_id": "330222",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3302222012",
    "district_id": "330222",
    "label": "Ketenger",
    "value": "Ketenger"
  },
  {
    "id": "3302232001",
    "district_id": "330223",
    "label": "Karangsalam Kidul",
    "value": "Karangsalam Kidul"
  },
  {
    "id": "3302232002",
    "district_id": "330223",
    "label": "Kebocoran",
    "value": "Kebocoran"
  },
  {
    "id": "3302232003",
    "district_id": "330223",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3302232004",
    "district_id": "330223",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3302232005",
    "district_id": "330223",
    "label": "Karangnangka",
    "value": "Karangnangka"
  },
  {
    "id": "3302232006",
    "district_id": "330223",
    "label": "Keniten",
    "value": "Keniten"
  },
  {
    "id": "3302232007",
    "district_id": "330223",
    "label": "Dawuhan Wetan",
    "value": "Dawuhan Wetan"
  },
  {
    "id": "3302232008",
    "district_id": "330223",
    "label": "Dawuhan Kulon",
    "value": "Dawuhan Kulon"
  },
  {
    "id": "3302232009",
    "district_id": "330223",
    "label": "Baseh",
    "value": "Baseh"
  },
  {
    "id": "3302232010",
    "district_id": "330223",
    "label": "Kalisalak",
    "value": "Kalisalak"
  },
  {
    "id": "3302232011",
    "district_id": "330223",
    "label": "Windujaya",
    "value": "Windujaya"
  },
  {
    "id": "3302232012",
    "district_id": "330223",
    "label": "Kalikesur",
    "value": "Kalikesur"
  },
  {
    "id": "3302232013",
    "district_id": "330223",
    "label": "Kutaliman",
    "value": "Kutaliman"
  },
  {
    "id": "3302232014",
    "district_id": "330223",
    "label": "Melung",
    "value": "Melung"
  },
  {
    "id": "3302241001",
    "district_id": "330224",
    "label": "Karangklesem",
    "value": "Karangklesem"
  },
  {
    "id": "3302241002",
    "district_id": "330224",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "3302241003",
    "district_id": "330224",
    "label": "Berkoh",
    "value": "Berkoh"
  },
  {
    "id": "3302241004",
    "district_id": "330224",
    "label": "Purwokerto Kidul",
    "value": "Purwokerto Kidul"
  },
  {
    "id": "3302241005",
    "district_id": "330224",
    "label": "Purwokerto Kulon",
    "value": "Purwokerto Kulon"
  },
  {
    "id": "3302241006",
    "district_id": "330224",
    "label": "Karangpucung",
    "value": "Karangpucung"
  },
  {
    "id": "3302241007",
    "district_id": "330224",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3302251001",
    "district_id": "330225",
    "label": "Karanglewas Lor",
    "value": "Karanglewas Lor"
  },
  {
    "id": "3302251002",
    "district_id": "330225",
    "label": "Pasir Kidul",
    "value": "Pasir Kidul"
  },
  {
    "id": "3302251003",
    "district_id": "330225",
    "label": "Rejasari",
    "value": "Rejasari"
  },
  {
    "id": "3302251004",
    "district_id": "330225",
    "label": "Pasirmuncang",
    "value": "Pasirmuncang"
  },
  {
    "id": "3302251005",
    "district_id": "330225",
    "label": "Bantarsoka",
    "value": "Bantarsoka"
  },
  {
    "id": "3302251006",
    "district_id": "330225",
    "label": "Kober",
    "value": "Kober"
  },
  {
    "id": "3302251007",
    "district_id": "330225",
    "label": "Kedungwuluh",
    "value": "Kedungwuluh"
  },
  {
    "id": "3302261001",
    "district_id": "330226",
    "label": "Sokanegara",
    "value": "Sokanegara"
  },
  {
    "id": "3302261002",
    "district_id": "330226",
    "label": "Kranji",
    "value": "Kranji"
  },
  {
    "id": "3302261003",
    "district_id": "330226",
    "label": "Purwokerto Lor",
    "value": "Purwokerto Lor"
  },
  {
    "id": "3302261004",
    "district_id": "330226",
    "label": "Purwokerto Wetan",
    "value": "Purwokerto Wetan"
  },
  {
    "id": "3302261005",
    "district_id": "330226",
    "label": "Mersi",
    "value": "Mersi"
  },
  {
    "id": "3302261006",
    "district_id": "330226",
    "label": "Arcawinangun",
    "value": "Arcawinangun"
  },
  {
    "id": "3302271001",
    "district_id": "330227",
    "label": "Purwanegara",
    "value": "Purwanegara"
  },
  {
    "id": "3302271002",
    "district_id": "330227",
    "label": "Bancarkembar",
    "value": "Bancarkembar"
  },
  {
    "id": "3302271003",
    "district_id": "330227",
    "label": "Sumampir",
    "value": "Sumampir"
  },
  {
    "id": "3302271004",
    "district_id": "330227",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3302271005",
    "district_id": "330227",
    "label": "Grendeng",
    "value": "Grendeng"
  },
  {
    "id": "3302271006",
    "district_id": "330227",
    "label": "Karangwangkal",
    "value": "Karangwangkal"
  },
  {
    "id": "3302271007",
    "district_id": "330227",
    "label": "Bobosan",
    "value": "Bobosan"
  },
  {
    "id": "3303012001",
    "district_id": "330301",
    "label": "Kedungbenda",
    "value": "Kedungbenda"
  },
  {
    "id": "3303012002",
    "district_id": "330301",
    "label": "Bokol",
    "value": "Bokol"
  },
  {
    "id": "3303012003",
    "district_id": "330301",
    "label": "Pelumutan",
    "value": "Pelumutan"
  },
  {
    "id": "3303012004",
    "district_id": "330301",
    "label": "Majatengah",
    "value": "Majatengah"
  },
  {
    "id": "3303012005",
    "district_id": "330301",
    "label": "Kedunglegok",
    "value": "Kedunglegok"
  },
  {
    "id": "3303012006",
    "district_id": "330301",
    "label": "Kemangkon",
    "value": "Kemangkon"
  },
  {
    "id": "3303012007",
    "district_id": "330301",
    "label": "Panican",
    "value": "Panican"
  },
  {
    "id": "3303012008",
    "district_id": "330301",
    "label": "Bakulan",
    "value": "Bakulan"
  },
  {
    "id": "3303012009",
    "district_id": "330301",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3303012010",
    "district_id": "330301",
    "label": "Pegandekan",
    "value": "Pegandekan"
  },
  {
    "id": "3303012011",
    "district_id": "330301",
    "label": "Senon",
    "value": "Senon"
  },
  {
    "id": "3303012012",
    "district_id": "330301",
    "label": "Sumilir",
    "value": "Sumilir"
  },
  {
    "id": "3303012013",
    "district_id": "330301",
    "label": "Kalialang",
    "value": "Kalialang"
  },
  {
    "id": "3303012014",
    "district_id": "330301",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3303012015",
    "district_id": "330301",
    "label": "Muntang",
    "value": "Muntang"
  },
  {
    "id": "3303012016",
    "district_id": "330301",
    "label": "Gambarsari",
    "value": "Gambarsari"
  },
  {
    "id": "3303012017",
    "district_id": "330301",
    "label": "Toyareka",
    "value": "Toyareka"
  },
  {
    "id": "3303012018",
    "district_id": "330301",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3303012019",
    "district_id": "330301",
    "label": "Majasem",
    "value": "Majasem"
  },
  {
    "id": "3303022001",
    "district_id": "330302",
    "label": "Tidu",
    "value": "Tidu"
  },
  {
    "id": "3303022002",
    "district_id": "330302",
    "label": "Wirasaba",
    "value": "Wirasaba"
  },
  {
    "id": "3303022003",
    "district_id": "330302",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3303022004",
    "district_id": "330302",
    "label": "Cipawon",
    "value": "Cipawon"
  },
  {
    "id": "3303022005",
    "district_id": "330302",
    "label": "Karangcengis",
    "value": "Karangcengis"
  },
  {
    "id": "3303022006",
    "district_id": "330302",
    "label": "Karanggedang",
    "value": "Karanggedang"
  },
  {
    "id": "3303022007",
    "district_id": "330302",
    "label": "Karangnangka",
    "value": "Karangnangka"
  },
  {
    "id": "3303022008",
    "district_id": "330302",
    "label": "Kutawis",
    "value": "Kutawis"
  },
  {
    "id": "3303022009",
    "district_id": "330302",
    "label": "Kebutuh",
    "value": "Kebutuh"
  },
  {
    "id": "3303022010",
    "district_id": "330302",
    "label": "Penaruban",
    "value": "Penaruban"
  },
  {
    "id": "3303022011",
    "district_id": "330302",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "3303022012",
    "district_id": "330302",
    "label": "Bukateja",
    "value": "Bukateja"
  },
  {
    "id": "3303022013",
    "district_id": "330302",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3303022014",
    "district_id": "330302",
    "label": "Bajong",
    "value": "Bajong"
  },
  {
    "id": "3303032001",
    "district_id": "330303",
    "label": "Bandingan",
    "value": "Bandingan"
  },
  {
    "id": "3303032002",
    "district_id": "330303",
    "label": "Lamuk",
    "value": "Lamuk"
  },
  {
    "id": "3303032003",
    "district_id": "330303",
    "label": "Sokanegara",
    "value": "Sokanegara"
  },
  {
    "id": "3303032004",
    "district_id": "330303",
    "label": "Gumiwang",
    "value": "Gumiwang"
  },
  {
    "id": "3303032005",
    "district_id": "330303",
    "label": "Krenceng",
    "value": "Krenceng"
  },
  {
    "id": "3303032006",
    "district_id": "330303",
    "label": "Nangkasawit",
    "value": "Nangkasawit"
  },
  {
    "id": "3303032007",
    "district_id": "330303",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3303032008",
    "district_id": "330303",
    "label": "Kejobong",
    "value": "Kejobong"
  },
  {
    "id": "3303032009",
    "district_id": "330303",
    "label": "Langgar",
    "value": "Langgar"
  },
  {
    "id": "3303032010",
    "district_id": "330303",
    "label": "Timbang",
    "value": "Timbang"
  },
  {
    "id": "3303032011",
    "district_id": "330303",
    "label": "Nangkod",
    "value": "Nangkod"
  },
  {
    "id": "3303032012",
    "district_id": "330303",
    "label": "Kedarpan",
    "value": "Kedarpan"
  },
  {
    "id": "3303032013",
    "district_id": "330303",
    "label": "Pangempon",
    "value": "Pangempon"
  },
  {
    "id": "3303042001",
    "district_id": "330304",
    "label": "Lamongan",
    "value": "Lamongan"
  },
  {
    "id": "3303042002",
    "district_id": "330304",
    "label": "Tejasari",
    "value": "Tejasari"
  },
  {
    "id": "3303042003",
    "district_id": "330304",
    "label": "Cilapar",
    "value": "Cilapar"
  },
  {
    "id": "3303042004",
    "district_id": "330304",
    "label": "Penolih",
    "value": "Penolih"
  },
  {
    "id": "3303042005",
    "district_id": "330304",
    "label": "Sinduraja",
    "value": "Sinduraja"
  },
  {
    "id": "3303042006",
    "district_id": "330304",
    "label": "Selakambang",
    "value": "Selakambang"
  },
  {
    "id": "3303042007",
    "district_id": "330304",
    "label": "Selanegara",
    "value": "Selanegara"
  },
  {
    "id": "3303042008",
    "district_id": "330304",
    "label": "Kaligondang",
    "value": "Kaligondang"
  },
  {
    "id": "3303042009",
    "district_id": "330304",
    "label": "Brecek",
    "value": "Brecek"
  },
  {
    "id": "3303042010",
    "district_id": "330304",
    "label": "Sempor Lor",
    "value": "Sempor Lor"
  },
  {
    "id": "3303042011",
    "district_id": "330304",
    "label": "Penaruban",
    "value": "Penaruban"
  },
  {
    "id": "3303042012",
    "district_id": "330304",
    "label": "Kalikajar",
    "value": "Kalikajar"
  },
  {
    "id": "3303042013",
    "district_id": "330304",
    "label": "Kembaran Wetan",
    "value": "Kembaran Wetan"
  },
  {
    "id": "3303042014",
    "district_id": "330304",
    "label": "Slinga",
    "value": "Slinga"
  },
  {
    "id": "3303042015",
    "district_id": "330304",
    "label": "Arenan",
    "value": "Arenan"
  },
  {
    "id": "3303042016",
    "district_id": "330304",
    "label": "Sidanegara",
    "value": "Sidanegara"
  },
  {
    "id": "3303042017",
    "district_id": "330304",
    "label": "Pagerandong",
    "value": "Pagerandong"
  },
  {
    "id": "3303042018",
    "district_id": "330304",
    "label": "Sidareja",
    "value": "Sidareja"
  },
  {
    "id": "3303051001",
    "district_id": "330305",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3303051003",
    "district_id": "330305",
    "label": "Kedungmenjangan",
    "value": "Kedungmenjangan"
  },
  {
    "id": "3303051005",
    "district_id": "330305",
    "label": "Bancar",
    "value": "Bancar"
  },
  {
    "id": "3303051006",
    "district_id": "330305",
    "label": "Purbalingga Wetan",
    "value": "Purbalingga Wetan"
  },
  {
    "id": "3303051007",
    "district_id": "330305",
    "label": "Penambongan",
    "value": "Penambongan"
  },
  {
    "id": "3303051008",
    "district_id": "330305",
    "label": "Purbalingga Kidul",
    "value": "Purbalingga Kidul"
  },
  {
    "id": "3303051009",
    "district_id": "330305",
    "label": "Kandanggampang",
    "value": "Kandanggampang"
  },
  {
    "id": "3303051010",
    "district_id": "330305",
    "label": "Purbalingga Kulon",
    "value": "Purbalingga Kulon"
  },
  {
    "id": "3303051011",
    "district_id": "330305",
    "label": "Purbalingga Lor",
    "value": "Purbalingga Lor"
  },
  {
    "id": "3303051012",
    "district_id": "330305",
    "label": "Kembaran Kulon",
    "value": "Kembaran Kulon"
  },
  {
    "id": "3303051013",
    "district_id": "330305",
    "label": "Wirasana",
    "value": "Wirasana"
  },
  {
    "id": "3303052002",
    "district_id": "330305",
    "label": "Toyareja",
    "value": "Toyareja"
  },
  {
    "id": "3303052004",
    "district_id": "330305",
    "label": "Jatisaba",
    "value": "Jatisaba"
  },
  {
    "id": "3303061011",
    "district_id": "330306",
    "label": "Mewek",
    "value": "Mewek"
  },
  {
    "id": "3303061012",
    "district_id": "330306",
    "label": "Karangmanyar",
    "value": "Karangmanyar"
  },
  {
    "id": "3303061013",
    "district_id": "330306",
    "label": "Kalikabong",
    "value": "Kalikabong"
  },
  {
    "id": "3303062001",
    "district_id": "330306",
    "label": "Jompo",
    "value": "Jompo"
  },
  {
    "id": "3303062002",
    "district_id": "330306",
    "label": "Rabak",
    "value": "Rabak"
  },
  {
    "id": "3303062003",
    "district_id": "330306",
    "label": "Blater",
    "value": "Blater"
  },
  {
    "id": "3303062004",
    "district_id": "330306",
    "label": "Manduraga",
    "value": "Manduraga"
  },
  {
    "id": "3303062005",
    "district_id": "330306",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3303062006",
    "district_id": "330306",
    "label": "Kalimanah Kulon",
    "value": "Kalimanah Kulon"
  },
  {
    "id": "3303062007",
    "district_id": "330306",
    "label": "Sidakangen",
    "value": "Sidakangen"
  },
  {
    "id": "3303062008",
    "district_id": "330306",
    "label": "Karangpetir",
    "value": "Karangpetir"
  },
  {
    "id": "3303062009",
    "district_id": "330306",
    "label": "Kalimanah Wetan",
    "value": "Kalimanah Wetan"
  },
  {
    "id": "3303062010",
    "district_id": "330306",
    "label": "Grecol",
    "value": "Grecol"
  },
  {
    "id": "3303062014",
    "district_id": "330306",
    "label": "Selabaya",
    "value": "Selabaya"
  },
  {
    "id": "3303062015",
    "district_id": "330306",
    "label": "Klapasawit",
    "value": "Klapasawit"
  },
  {
    "id": "3303062016",
    "district_id": "330306",
    "label": "Kedungwuluh",
    "value": "Kedungwuluh"
  },
  {
    "id": "3303062017",
    "district_id": "330306",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3303072001",
    "district_id": "330307",
    "label": "Karanglewas",
    "value": "Karanglewas"
  },
  {
    "id": "3303072002",
    "district_id": "330307",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3303072003",
    "district_id": "330307",
    "label": "Sumingkir",
    "value": "Sumingkir"
  },
  {
    "id": "3303072004",
    "district_id": "330307",
    "label": "Meri",
    "value": "Meri"
  },
  {
    "id": "3303072005",
    "district_id": "330307",
    "label": "Kutasari",
    "value": "Kutasari"
  },
  {
    "id": "3303072006",
    "district_id": "330307",
    "label": "Karangklesem",
    "value": "Karangklesem"
  },
  {
    "id": "3303072007",
    "district_id": "330307",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3303072008",
    "district_id": "330307",
    "label": "Karangaren",
    "value": "Karangaren"
  },
  {
    "id": "3303072009",
    "district_id": "330307",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3303072010",
    "district_id": "330307",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "3303072011",
    "district_id": "330307",
    "label": "Candiwulan",
    "value": "Candiwulan"
  },
  {
    "id": "3303072012",
    "district_id": "330307",
    "label": "Karangcegak",
    "value": "Karangcegak"
  },
  {
    "id": "3303072013",
    "district_id": "330307",
    "label": "Candinata",
    "value": "Candinata"
  },
  {
    "id": "3303072014",
    "district_id": "330307",
    "label": "Karangjengkol",
    "value": "Karangjengkol"
  },
  {
    "id": "3303082001",
    "district_id": "330308",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3303082002",
    "district_id": "330308",
    "label": "Onje",
    "value": "Onje"
  },
  {
    "id": "3303082003",
    "district_id": "330308",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "3303082004",
    "district_id": "330308",
    "label": "Tangkisan",
    "value": "Tangkisan"
  },
  {
    "id": "3303082005",
    "district_id": "330308",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3303082006",
    "district_id": "330308",
    "label": "Lambur",
    "value": "Lambur"
  },
  {
    "id": "3303082007",
    "district_id": "330308",
    "label": "Selaganggeng",
    "value": "Selaganggeng"
  },
  {
    "id": "3303082008",
    "district_id": "330308",
    "label": "Mangunegara",
    "value": "Mangunegara"
  },
  {
    "id": "3303082009",
    "district_id": "330308",
    "label": "Karangnangka",
    "value": "Karangnangka"
  },
  {
    "id": "3303082010",
    "district_id": "330308",
    "label": "Mrebet",
    "value": "Mrebet"
  },
  {
    "id": "3303082011",
    "district_id": "330308",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3303082012",
    "district_id": "330308",
    "label": "Serayu Karanganyar",
    "value": "Serayu Karanganyar"
  },
  {
    "id": "3303082013",
    "district_id": "330308",
    "label": "Serayu Larangan",
    "value": "Serayu Larangan"
  },
  {
    "id": "3303082014",
    "district_id": "330308",
    "label": "Campakoah",
    "value": "Campakoah"
  },
  {
    "id": "3303082015",
    "district_id": "330308",
    "label": "Pagerandong",
    "value": "Pagerandong"
  },
  {
    "id": "3303082016",
    "district_id": "330308",
    "label": "Cipaku",
    "value": "Cipaku"
  },
  {
    "id": "3303082017",
    "district_id": "330308",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3303082018",
    "district_id": "330308",
    "label": "Pengalusan",
    "value": "Pengalusan"
  },
  {
    "id": "3303082019",
    "district_id": "330308",
    "label": "Sangkanayu",
    "value": "Sangkanayu"
  },
  {
    "id": "3303092001",
    "district_id": "330309",
    "label": "Gandasuli",
    "value": "Gandasuli"
  },
  {
    "id": "3303092002",
    "district_id": "330309",
    "label": "Kalapacung",
    "value": "Kalapacung"
  },
  {
    "id": "3303092003",
    "district_id": "330309",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3303092004",
    "district_id": "330309",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3303092005",
    "district_id": "330309",
    "label": "Majapura",
    "value": "Majapura"
  },
  {
    "id": "3303092006",
    "district_id": "330309",
    "label": "Bobotsari",
    "value": "Bobotsari"
  },
  {
    "id": "3303092007",
    "district_id": "330309",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3303092008",
    "district_id": "330309",
    "label": "Pakuncen",
    "value": "Pakuncen"
  },
  {
    "id": "3303092009",
    "district_id": "330309",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3303092010",
    "district_id": "330309",
    "label": "Gunungkarang",
    "value": "Gunungkarang"
  },
  {
    "id": "3303092011",
    "district_id": "330309",
    "label": "Talagening",
    "value": "Talagening"
  },
  {
    "id": "3303092012",
    "district_id": "330309",
    "label": "Tlagayasa",
    "value": "Tlagayasa"
  },
  {
    "id": "3303092013",
    "district_id": "330309",
    "label": "Dagan",
    "value": "Dagan"
  },
  {
    "id": "3303092014",
    "district_id": "330309",
    "label": "Palumbungan",
    "value": "Palumbungan"
  },
  {
    "id": "3303092015",
    "district_id": "330309",
    "label": "Limbasari",
    "value": "Limbasari"
  },
  {
    "id": "3303092016",
    "district_id": "330309",
    "label": "Palumbungan Wetan",
    "value": "Palumbungan Wetan"
  },
  {
    "id": "3303102001",
    "district_id": "330310",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3303102002",
    "district_id": "330310",
    "label": "Kutabawa",
    "value": "Kutabawa"
  },
  {
    "id": "3303102003",
    "district_id": "330310",
    "label": "Siwarak",
    "value": "Siwarak"
  },
  {
    "id": "3303102004",
    "district_id": "330310",
    "label": "Tlahab Lor",
    "value": "Tlahab Lor"
  },
  {
    "id": "3303102005",
    "district_id": "330310",
    "label": "Tlahab Kidul",
    "value": "Tlahab Kidul"
  },
  {
    "id": "3303102006",
    "district_id": "330310",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3303102007",
    "district_id": "330310",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3303112001",
    "district_id": "330311",
    "label": "Ponjen",
    "value": "Ponjen"
  },
  {
    "id": "3303112002",
    "district_id": "330311",
    "label": "Buara",
    "value": "Buara"
  },
  {
    "id": "3303112003",
    "district_id": "330311",
    "label": "Brakas",
    "value": "Brakas"
  },
  {
    "id": "3303112004",
    "district_id": "330311",
    "label": "Bungkanel",
    "value": "Bungkanel"
  },
  {
    "id": "3303112005",
    "district_id": "330311",
    "label": "Lumpang",
    "value": "Lumpang"
  },
  {
    "id": "3303112006",
    "district_id": "330311",
    "label": "Karanggedang",
    "value": "Karanggedang"
  },
  {
    "id": "3303112007",
    "district_id": "330311",
    "label": "Kabunderan",
    "value": "Kabunderan"
  },
  {
    "id": "3303112008",
    "district_id": "330311",
    "label": "Jambudesa",
    "value": "Jambudesa"
  },
  {
    "id": "3303112009",
    "district_id": "330311",
    "label": "Maribaya",
    "value": "Maribaya"
  },
  {
    "id": "3303112010",
    "district_id": "330311",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3303112012",
    "district_id": "330311",
    "label": "Banjarkerta",
    "value": "Banjarkerta"
  },
  {
    "id": "3303112013",
    "district_id": "330311",
    "label": "Kalijaran",
    "value": "Kalijaran"
  },
  {
    "id": "3303112014",
    "district_id": "330311",
    "label": "Kaliori",
    "value": "Kaliori"
  },
  {
    "id": "3303122001",
    "district_id": "330312",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3303122002",
    "district_id": "330312",
    "label": "Pepedan",
    "value": "Pepedan"
  },
  {
    "id": "3303122003",
    "district_id": "330312",
    "label": "Pekiringan",
    "value": "Pekiringan"
  },
  {
    "id": "3303122004",
    "district_id": "330312",
    "label": "Grantung",
    "value": "Grantung"
  },
  {
    "id": "3303122005",
    "district_id": "330312",
    "label": "Rajawana",
    "value": "Rajawana"
  },
  {
    "id": "3303122006",
    "district_id": "330312",
    "label": "Tajug",
    "value": "Tajug"
  },
  {
    "id": "3303122007",
    "district_id": "330312",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3303122008",
    "district_id": "330312",
    "label": "Baleraksa",
    "value": "Baleraksa"
  },
  {
    "id": "3303122009",
    "district_id": "330312",
    "label": "Tunjungmuli",
    "value": "Tunjungmuli"
  },
  {
    "id": "3303122010",
    "district_id": "330312",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3303122011",
    "district_id": "330312",
    "label": "Sirau",
    "value": "Sirau"
  },
  {
    "id": "3303132001",
    "district_id": "330313",
    "label": "Wlahar",
    "value": "Wlahar"
  },
  {
    "id": "3303132002",
    "district_id": "330313",
    "label": "Bantarbarang",
    "value": "Bantarbarang"
  },
  {
    "id": "3303132003",
    "district_id": "330313",
    "label": "Karangbawang",
    "value": "Karangbawang"
  },
  {
    "id": "3303132004",
    "district_id": "330313",
    "label": "Gunungwuled",
    "value": "Gunungwuled"
  },
  {
    "id": "3303132005",
    "district_id": "330313",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3303132006",
    "district_id": "330313",
    "label": "Bodaskarangjati",
    "value": "Bodaskarangjati"
  },
  {
    "id": "3303132007",
    "district_id": "330313",
    "label": "Wanogara Wetan",
    "value": "Wanogara Wetan"
  },
  {
    "id": "3303132008",
    "district_id": "330313",
    "label": "Wanogara Kulon",
    "value": "Wanogara Kulon"
  },
  {
    "id": "3303132009",
    "district_id": "330313",
    "label": "Makam",
    "value": "Makam"
  },
  {
    "id": "3303132010",
    "district_id": "330313",
    "label": "Sumampir",
    "value": "Sumampir"
  },
  {
    "id": "3303132011",
    "district_id": "330313",
    "label": "Tanalum",
    "value": "Tanalum"
  },
  {
    "id": "3303132012",
    "district_id": "330313",
    "label": "Panusupan",
    "value": "Panusupan"
  },
  {
    "id": "3303142001",
    "district_id": "330314",
    "label": "Brobot",
    "value": "Brobot"
  },
  {
    "id": "3303142002",
    "district_id": "330314",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "3303142003",
    "district_id": "330314",
    "label": "Galuh",
    "value": "Galuh"
  },
  {
    "id": "3303142004",
    "district_id": "330314",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3303142005",
    "district_id": "330314",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3303142006",
    "district_id": "330314",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3303142007",
    "district_id": "330314",
    "label": "Kajongan",
    "value": "Kajongan"
  },
  {
    "id": "3303142008",
    "district_id": "330314",
    "label": "Karangbanjar",
    "value": "Karangbanjar"
  },
  {
    "id": "3303142009",
    "district_id": "330314",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3303142010",
    "district_id": "330314",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "3303142011",
    "district_id": "330314",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "3303142012",
    "district_id": "330314",
    "label": "Metenggeng",
    "value": "Metenggeng"
  },
  {
    "id": "3303142013",
    "district_id": "330314",
    "label": "Bumisari",
    "value": "Bumisari"
  },
  {
    "id": "3303151004",
    "district_id": "330315",
    "label": "Karangsentul",
    "value": "Karangsentul"
  },
  {
    "id": "3303152001",
    "district_id": "330315",
    "label": "Karangpule",
    "value": "Karangpule"
  },
  {
    "id": "3303152002",
    "district_id": "330315",
    "label": "Sokawera",
    "value": "Sokawera"
  },
  {
    "id": "3303152003",
    "district_id": "330315",
    "label": "Karangjambe",
    "value": "Karangjambe"
  },
  {
    "id": "3303152005",
    "district_id": "330315",
    "label": "Bojanegara",
    "value": "Bojanegara"
  },
  {
    "id": "3303152006",
    "district_id": "330315",
    "label": "Gemuruh",
    "value": "Gemuruh"
  },
  {
    "id": "3303152007",
    "district_id": "330315",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3303152008",
    "district_id": "330315",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3303152009",
    "district_id": "330315",
    "label": "Padamara",
    "value": "Padamara"
  },
  {
    "id": "3303152010",
    "district_id": "330315",
    "label": "Purbayasa",
    "value": "Purbayasa"
  },
  {
    "id": "3303152011",
    "district_id": "330315",
    "label": "Kalitinggar",
    "value": "Kalitinggar"
  },
  {
    "id": "3303152012",
    "district_id": "330315",
    "label": "Mipiran",
    "value": "Mipiran"
  },
  {
    "id": "3303152013",
    "district_id": "330315",
    "label": "Karanggambas",
    "value": "Karanggambas"
  },
  {
    "id": "3303152014",
    "district_id": "330315",
    "label": "Kalitinggar Kidul",
    "value": "Kalitinggar Kidul"
  },
  {
    "id": "3303162001",
    "district_id": "330316",
    "label": "Panunggalan",
    "value": "Panunggalan"
  },
  {
    "id": "3303162002",
    "district_id": "330316",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3303162003",
    "district_id": "330316",
    "label": "Pasunggingan",
    "value": "Pasunggingan"
  },
  {
    "id": "3303162004",
    "district_id": "330316",
    "label": "Pengadegan",
    "value": "Pengadegan"
  },
  {
    "id": "3303162005",
    "district_id": "330316",
    "label": "Karangjoho",
    "value": "Karangjoho"
  },
  {
    "id": "3303162006",
    "district_id": "330316",
    "label": "Bedagas",
    "value": "Bedagas"
  },
  {
    "id": "3303162007",
    "district_id": "330316",
    "label": "Tumanggal",
    "value": "Tumanggal"
  },
  {
    "id": "3303162008",
    "district_id": "330316",
    "label": "Tegalpingen",
    "value": "Tegalpingen"
  },
  {
    "id": "3303162009",
    "district_id": "330316",
    "label": "Tetel",
    "value": "Tetel"
  },
  {
    "id": "3303172001",
    "district_id": "330317",
    "label": "Purbasari",
    "value": "Purbasari"
  },
  {
    "id": "3303172002",
    "district_id": "330317",
    "label": "Sirandu",
    "value": "Sirandu"
  },
  {
    "id": "3303172003",
    "district_id": "330317",
    "label": "Karangjambu",
    "value": "Karangjambu"
  },
  {
    "id": "3303172004",
    "district_id": "330317",
    "label": "Sanguwatang",
    "value": "Sanguwatang"
  },
  {
    "id": "3303172005",
    "district_id": "330317",
    "label": "Jingkang",
    "value": "Jingkang"
  },
  {
    "id": "3303172006",
    "district_id": "330317",
    "label": "Danasari",
    "value": "Danasari"
  },
  {
    "id": "3303182001",
    "district_id": "330318",
    "label": "Krangean",
    "value": "Krangean"
  },
  {
    "id": "3303182002",
    "district_id": "330318",
    "label": "Darma",
    "value": "Darma"
  },
  {
    "id": "3303182003",
    "district_id": "330318",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "3303182004",
    "district_id": "330318",
    "label": "Adiarsa",
    "value": "Adiarsa"
  },
  {
    "id": "3303182005",
    "district_id": "330318",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3303182006",
    "district_id": "330318",
    "label": "Karangpucung",
    "value": "Karangpucung"
  },
  {
    "id": "3303182007",
    "district_id": "330318",
    "label": "Condong",
    "value": "Condong"
  },
  {
    "id": "3303182008",
    "district_id": "330318",
    "label": "Kasih",
    "value": "Kasih"
  },
  {
    "id": "3303182009",
    "district_id": "330318",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3303182010",
    "district_id": "330318",
    "label": "Kertanegara",
    "value": "Kertanegara"
  },
  {
    "id": "3303182011",
    "district_id": "330318",
    "label": "Mergasana",
    "value": "Mergasana"
  },
  {
    "id": "3304012001",
    "district_id": "330401",
    "label": "Berta",
    "value": "Berta"
  },
  {
    "id": "3304012002",
    "district_id": "330401",
    "label": "Derik",
    "value": "Derik"
  },
  {
    "id": "3304012003",
    "district_id": "330401",
    "label": "Gumelem Wetan",
    "value": "Gumelem Wetan"
  },
  {
    "id": "3304012004",
    "district_id": "330401",
    "label": "Gumelem Kulon",
    "value": "Gumelem Kulon"
  },
  {
    "id": "3304012005",
    "district_id": "330401",
    "label": "Penarusan Wetan",
    "value": "Penarusan Wetan"
  },
  {
    "id": "3304012006",
    "district_id": "330401",
    "label": "Penarusan Kulon",
    "value": "Penarusan Kulon"
  },
  {
    "id": "3304012007",
    "district_id": "330401",
    "label": "Brengkok",
    "value": "Brengkok"
  },
  {
    "id": "3304012008",
    "district_id": "330401",
    "label": "Pekikiran",
    "value": "Pekikiran"
  },
  {
    "id": "3304012009",
    "district_id": "330401",
    "label": "Piasa Wetan",
    "value": "Piasa Wetan"
  },
  {
    "id": "3304012010",
    "district_id": "330401",
    "label": "Karangsalam",
    "value": "Karangsalam"
  },
  {
    "id": "3304012011",
    "district_id": "330401",
    "label": "Kemranggon",
    "value": "Kemranggon"
  },
  {
    "id": "3304012012",
    "district_id": "330401",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3304012013",
    "district_id": "330401",
    "label": "Dermasari",
    "value": "Dermasari"
  },
  {
    "id": "3304012014",
    "district_id": "330401",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3304012015",
    "district_id": "330401",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3304022001",
    "district_id": "330402",
    "label": "Sirkandi",
    "value": "Sirkandi"
  },
  {
    "id": "3304022002",
    "district_id": "330402",
    "label": "Pagak",
    "value": "Pagak"
  },
  {
    "id": "3304022003",
    "district_id": "330402",
    "label": "Kecitran",
    "value": "Kecitran"
  },
  {
    "id": "3304022004",
    "district_id": "330402",
    "label": "Purwareja",
    "value": "Purwareja"
  },
  {
    "id": "3304022005",
    "district_id": "330402",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3304022006",
    "district_id": "330402",
    "label": "Kalilandak",
    "value": "Kalilandak"
  },
  {
    "id": "3304022007",
    "district_id": "330402",
    "label": "Kalimandi",
    "value": "Kalimandi"
  },
  {
    "id": "3304022008",
    "district_id": "330402",
    "label": "Kaliwinasuh",
    "value": "Kaliwinasuh"
  },
  {
    "id": "3304032001",
    "district_id": "330403",
    "label": "Jalatunda",
    "value": "Jalatunda"
  },
  {
    "id": "3304032002",
    "district_id": "330403",
    "label": "Somawangi",
    "value": "Somawangi"
  },
  {
    "id": "3304032003",
    "district_id": "330403",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3304032004",
    "district_id": "330403",
    "label": "Kebanaran",
    "value": "Kebanaran"
  },
  {
    "id": "3304032005",
    "district_id": "330403",
    "label": "Glempang",
    "value": "Glempang"
  },
  {
    "id": "3304032006",
    "district_id": "330403",
    "label": "Salamerta",
    "value": "Salamerta"
  },
  {
    "id": "3304032007",
    "district_id": "330403",
    "label": "Purwasaba",
    "value": "Purwasaba"
  },
  {
    "id": "3304032008",
    "district_id": "330403",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3304032009",
    "district_id": "330403",
    "label": "Panggisari",
    "value": "Panggisari"
  },
  {
    "id": "3304032010",
    "district_id": "330403",
    "label": "Candiwulan",
    "value": "Candiwulan"
  },
  {
    "id": "3304032011",
    "district_id": "330403",
    "label": "Simbang",
    "value": "Simbang"
  },
  {
    "id": "3304032012",
    "district_id": "330403",
    "label": "Kertayasa",
    "value": "Kertayasa"
  },
  {
    "id": "3304032013",
    "district_id": "330403",
    "label": "Banjengan",
    "value": "Banjengan"
  },
  {
    "id": "3304032014",
    "district_id": "330403",
    "label": "Mandiraja Kulon",
    "value": "Mandiraja Kulon"
  },
  {
    "id": "3304032015",
    "district_id": "330403",
    "label": "Kebakalan",
    "value": "Kebakalan"
  },
  {
    "id": "3304032016",
    "district_id": "330403",
    "label": "Mandiraja Wetan",
    "value": "Mandiraja Wetan"
  },
  {
    "id": "3304042001",
    "district_id": "330404",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3304042002",
    "district_id": "330404",
    "label": "Merden",
    "value": "Merden"
  },
  {
    "id": "3304042003",
    "district_id": "330404",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3304042004",
    "district_id": "330404",
    "label": "Kaliajir",
    "value": "Kaliajir"
  },
  {
    "id": "3304042005",
    "district_id": "330404",
    "label": "Petir",
    "value": "Petir"
  },
  {
    "id": "3304042006",
    "district_id": "330404",
    "label": "Pucungbedug",
    "value": "Pucungbedug"
  },
  {
    "id": "3304042007",
    "district_id": "330404",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3304042008",
    "district_id": "330404",
    "label": "Mertasari",
    "value": "Mertasari"
  },
  {
    "id": "3304042009",
    "district_id": "330404",
    "label": "Danaraja",
    "value": "Danaraja"
  },
  {
    "id": "3304042010",
    "district_id": "330404",
    "label": "Purwonegoro",
    "value": "Purwonegoro"
  },
  {
    "id": "3304042011",
    "district_id": "330404",
    "label": "Kalipelus",
    "value": "Kalipelus"
  },
  {
    "id": "3304042012",
    "district_id": "330404",
    "label": "Gumiwang",
    "value": "Gumiwang"
  },
  {
    "id": "3304042013",
    "district_id": "330404",
    "label": "Kutawuluh",
    "value": "Kutawuluh"
  },
  {
    "id": "3304052001",
    "district_id": "330405",
    "label": "Wanadri",
    "value": "Wanadri"
  },
  {
    "id": "3304052002",
    "district_id": "330405",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3304052003",
    "district_id": "330405",
    "label": "Majalengka",
    "value": "Majalengka"
  },
  {
    "id": "3304052004",
    "district_id": "330405",
    "label": "Wiramastra",
    "value": "Wiramastra"
  },
  {
    "id": "3304052005",
    "district_id": "330405",
    "label": "Kutayasa",
    "value": "Kutayasa"
  },
  {
    "id": "3304052006",
    "district_id": "330405",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3304052007",
    "district_id": "330405",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3304052008",
    "district_id": "330405",
    "label": "Watuurip",
    "value": "Watuurip"
  },
  {
    "id": "3304052009",
    "district_id": "330405",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3304052010",
    "district_id": "330405",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3304052011",
    "district_id": "330405",
    "label": "Mantrianom",
    "value": "Mantrianom"
  },
  {
    "id": "3304052012",
    "district_id": "330405",
    "label": "Binorong",
    "value": "Binorong"
  },
  {
    "id": "3304052013",
    "district_id": "330405",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3304052014",
    "district_id": "330405",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "3304052015",
    "district_id": "330405",
    "label": "Bandingan",
    "value": "Bandingan"
  },
  {
    "id": "3304052016",
    "district_id": "330405",
    "label": "Blambangan",
    "value": "Blambangan"
  },
  {
    "id": "3304052017",
    "district_id": "330405",
    "label": "Gemuruh",
    "value": "Gemuruh"
  },
  {
    "id": "3304052018",
    "district_id": "330405",
    "label": "Pucang",
    "value": "Pucang"
  },
  {
    "id": "3304061001",
    "district_id": "330406",
    "label": "Argasoka",
    "value": "Argasoka"
  },
  {
    "id": "3304061006",
    "district_id": "330406",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3304061007",
    "district_id": "330406",
    "label": "Wangon",
    "value": "Wangon"
  },
  {
    "id": "3304061008",
    "district_id": "330406",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3304061009",
    "district_id": "330406",
    "label": "Sokanandi",
    "value": "Sokanandi"
  },
  {
    "id": "3304061010",
    "district_id": "330406",
    "label": "Parakancanggah",
    "value": "Parakancanggah"
  },
  {
    "id": "3304061011",
    "district_id": "330406",
    "label": "Semarang",
    "value": "Semarang"
  },
  {
    "id": "3304061012",
    "district_id": "330406",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3304061013",
    "district_id": "330406",
    "label": "Kutabanjarnegara",
    "value": "Kutabanjarnegara"
  },
  {
    "id": "3304062002",
    "district_id": "330406",
    "label": "Ampelsari",
    "value": "Ampelsari"
  },
  {
    "id": "3304062003",
    "district_id": "330406",
    "label": "Tlagawera",
    "value": "Tlagawera"
  },
  {
    "id": "3304062004",
    "district_id": "330406",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "3304062005",
    "district_id": "330406",
    "label": "Sokayasa",
    "value": "Sokayasa"
  },
  {
    "id": "3304071015",
    "district_id": "330407",
    "label": "Kalibenda",
    "value": "Kalibenda"
  },
  {
    "id": "3304072001",
    "district_id": "330407",
    "label": "Pringamba",
    "value": "Pringamba"
  },
  {
    "id": "3304072002",
    "district_id": "330407",
    "label": "Sawal",
    "value": "Sawal"
  },
  {
    "id": "3304072003",
    "district_id": "330407",
    "label": "Panawaren",
    "value": "Panawaren"
  },
  {
    "id": "3304072004",
    "district_id": "330407",
    "label": "Tunggara",
    "value": "Tunggara"
  },
  {
    "id": "3304072005",
    "district_id": "330407",
    "label": "Randegan",
    "value": "Randegan"
  },
  {
    "id": "3304072006",
    "district_id": "330407",
    "label": "Bojanegara",
    "value": "Bojanegara"
  },
  {
    "id": "3304072007",
    "district_id": "330407",
    "label": "Bandingan",
    "value": "Bandingan"
  },
  {
    "id": "3304072008",
    "district_id": "330407",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3304072009",
    "district_id": "330407",
    "label": "Gembongan",
    "value": "Gembongan"
  },
  {
    "id": "3304072010",
    "district_id": "330407",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3304072011",
    "district_id": "330407",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3304072012",
    "district_id": "330407",
    "label": "Wanacipta",
    "value": "Wanacipta"
  },
  {
    "id": "3304072013",
    "district_id": "330407",
    "label": "Sigaluh",
    "value": "Sigaluh"
  },
  {
    "id": "3304072014",
    "district_id": "330407",
    "label": "Singamerta",
    "value": "Singamerta"
  },
  {
    "id": "3304081011",
    "district_id": "330408",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3304081012",
    "district_id": "330408",
    "label": "Rejasa",
    "value": "Rejasa"
  },
  {
    "id": "3304082001",
    "district_id": "330408",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3304082002",
    "district_id": "330408",
    "label": "Penawangan",
    "value": "Penawangan"
  },
  {
    "id": "3304082003",
    "district_id": "330408",
    "label": "Talunamba",
    "value": "Talunamba"
  },
  {
    "id": "3304082004",
    "district_id": "330408",
    "label": "Madukara",
    "value": "Madukara"
  },
  {
    "id": "3304082005",
    "district_id": "330408",
    "label": "Kutayasa",
    "value": "Kutayasa"
  },
  {
    "id": "3304082006",
    "district_id": "330408",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3304082007",
    "district_id": "330408",
    "label": "Pagelak",
    "value": "Pagelak"
  },
  {
    "id": "3304082008",
    "district_id": "330408",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3304082009",
    "district_id": "330408",
    "label": "Bantarwaru",
    "value": "Bantarwaru"
  },
  {
    "id": "3304082010",
    "district_id": "330408",
    "label": "Sered",
    "value": "Sered"
  },
  {
    "id": "3304082013",
    "district_id": "330408",
    "label": "Petambakan",
    "value": "Petambakan"
  },
  {
    "id": "3304082014",
    "district_id": "330408",
    "label": "Rakitan",
    "value": "Rakitan"
  },
  {
    "id": "3304082015",
    "district_id": "330408",
    "label": "Blitar",
    "value": "Blitar"
  },
  {
    "id": "3304082016",
    "district_id": "330408",
    "label": "Kaliurip",
    "value": "Kaliurip"
  },
  {
    "id": "3304082017",
    "district_id": "330408",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3304082018",
    "district_id": "330408",
    "label": "Gununggiana",
    "value": "Gununggiana"
  },
  {
    "id": "3304082019",
    "district_id": "330408",
    "label": "Clapar",
    "value": "Clapar"
  },
  {
    "id": "3304082020",
    "district_id": "330408",
    "label": "Pakelen",
    "value": "Pakelen"
  },
  {
    "id": "3304092001",
    "district_id": "330409",
    "label": "Jenggawur",
    "value": "Jenggawur"
  },
  {
    "id": "3304092002",
    "district_id": "330409",
    "label": "Banjarkulon",
    "value": "Banjarkulon"
  },
  {
    "id": "3304092003",
    "district_id": "330409",
    "label": "Banjarmangu",
    "value": "Banjarmangu"
  },
  {
    "id": "3304092004",
    "district_id": "330409",
    "label": "Rejasari",
    "value": "Rejasari"
  },
  {
    "id": "3304092005",
    "district_id": "330409",
    "label": "Kesenet",
    "value": "Kesenet"
  },
  {
    "id": "3304092006",
    "district_id": "330409",
    "label": "Kalilunjar",
    "value": "Kalilunjar"
  },
  {
    "id": "3304092007",
    "district_id": "330409",
    "label": "Sijeruk",
    "value": "Sijeruk"
  },
  {
    "id": "3304092008",
    "district_id": "330409",
    "label": "Kendaga",
    "value": "Kendaga"
  },
  {
    "id": "3304092009",
    "district_id": "330409",
    "label": "Gripit",
    "value": "Gripit"
  },
  {
    "id": "3304092010",
    "district_id": "330409",
    "label": "Pekandangan",
    "value": "Pekandangan"
  },
  {
    "id": "3304092011",
    "district_id": "330409",
    "label": "Sigeblog",
    "value": "Sigeblog"
  },
  {
    "id": "3304092012",
    "district_id": "330409",
    "label": "Paseh",
    "value": "Paseh"
  },
  {
    "id": "3304092013",
    "district_id": "330409",
    "label": "Sipedang",
    "value": "Sipedang"
  },
  {
    "id": "3304092014",
    "district_id": "330409",
    "label": "Sijenggung",
    "value": "Sijenggung"
  },
  {
    "id": "3304092015",
    "district_id": "330409",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3304092016",
    "district_id": "330409",
    "label": "Prendengan",
    "value": "Prendengan"
  },
  {
    "id": "3304092017",
    "district_id": "330409",
    "label": "Majatengah",
    "value": "Majatengah"
  },
  {
    "id": "3304102001",
    "district_id": "330410",
    "label": "Kasilib",
    "value": "Kasilib"
  },
  {
    "id": "3304102002",
    "district_id": "330410",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "3304102003",
    "district_id": "330410",
    "label": "Karangjambe",
    "value": "Karangjambe"
  },
  {
    "id": "3304102004",
    "district_id": "330410",
    "label": "Wanadadi",
    "value": "Wanadadi"
  },
  {
    "id": "3304102005",
    "district_id": "330410",
    "label": "Wanakarsa",
    "value": "Wanakarsa"
  },
  {
    "id": "3304102006",
    "district_id": "330410",
    "label": "Lemahjaya",
    "value": "Lemahjaya"
  },
  {
    "id": "3304102007",
    "district_id": "330410",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3304102008",
    "district_id": "330410",
    "label": "Kandangwangi",
    "value": "Kandangwangi"
  },
  {
    "id": "3304102009",
    "district_id": "330410",
    "label": "Medayu",
    "value": "Medayu"
  },
  {
    "id": "3304102010",
    "district_id": "330410",
    "label": "Linggasari",
    "value": "Linggasari"
  },
  {
    "id": "3304102011",
    "district_id": "330410",
    "label": "Gumingsir",
    "value": "Gumingsir"
  },
  {
    "id": "3304112001",
    "district_id": "330411",
    "label": "Pingit",
    "value": "Pingit"
  },
  {
    "id": "3304112002",
    "district_id": "330411",
    "label": "Situwangi",
    "value": "Situwangi"
  },
  {
    "id": "3304112003",
    "district_id": "330411",
    "label": "Gelang",
    "value": "Gelang"
  },
  {
    "id": "3304112004",
    "district_id": "330411",
    "label": "Rakit",
    "value": "Rakit"
  },
  {
    "id": "3304112005",
    "district_id": "330411",
    "label": "Adipasir",
    "value": "Adipasir"
  },
  {
    "id": "3304112006",
    "district_id": "330411",
    "label": "Bandingan",
    "value": "Bandingan"
  },
  {
    "id": "3304112007",
    "district_id": "330411",
    "label": "Kincang",
    "value": "Kincang"
  },
  {
    "id": "3304112008",
    "district_id": "330411",
    "label": "Badamita",
    "value": "Badamita"
  },
  {
    "id": "3304112009",
    "district_id": "330411",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3304112010",
    "district_id": "330411",
    "label": "Luwung",
    "value": "Luwung"
  },
  {
    "id": "3304112011",
    "district_id": "330411",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3304122001",
    "district_id": "330412",
    "label": "Sambong",
    "value": "Sambong"
  },
  {
    "id": "3304122002",
    "district_id": "330412",
    "label": "Danakerta",
    "value": "Danakerta"
  },
  {
    "id": "3304122003",
    "district_id": "330412",
    "label": "Klapa",
    "value": "Klapa"
  },
  {
    "id": "3304122004",
    "district_id": "330412",
    "label": "Kecepit",
    "value": "Kecepit"
  },
  {
    "id": "3304122005",
    "district_id": "330412",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3304122006",
    "district_id": "330412",
    "label": "Tribuana",
    "value": "Tribuana"
  },
  {
    "id": "3304122007",
    "district_id": "330412",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3304122008",
    "district_id": "330412",
    "label": "Sidarata",
    "value": "Sidarata"
  },
  {
    "id": "3304122009",
    "district_id": "330412",
    "label": "Badakarya",
    "value": "Badakarya"
  },
  {
    "id": "3304122010",
    "district_id": "330412",
    "label": "Punggelan",
    "value": "Punggelan"
  },
  {
    "id": "3304122011",
    "district_id": "330412",
    "label": "Jembangan",
    "value": "Jembangan"
  },
  {
    "id": "3304122012",
    "district_id": "330412",
    "label": "Purwasana",
    "value": "Purwasana"
  },
  {
    "id": "3304122013",
    "district_id": "330412",
    "label": "Petuguran",
    "value": "Petuguran"
  },
  {
    "id": "3304122014",
    "district_id": "330412",
    "label": "Bondolharjo",
    "value": "Bondolharjo"
  },
  {
    "id": "3304122015",
    "district_id": "330412",
    "label": "Tanjungtirta",
    "value": "Tanjungtirta"
  },
  {
    "id": "3304122016",
    "district_id": "330412",
    "label": "Tlaga",
    "value": "Tlaga"
  },
  {
    "id": "3304122017",
    "district_id": "330412",
    "label": "Mlaya",
    "value": "Mlaya"
  },
  {
    "id": "3304132001",
    "district_id": "330413",
    "label": "Paweden",
    "value": "Paweden"
  },
  {
    "id": "3304132002",
    "district_id": "330413",
    "label": "Gumelar",
    "value": "Gumelar"
  },
  {
    "id": "3304132003",
    "district_id": "330413",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3304132004",
    "district_id": "330413",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "3304132005",
    "district_id": "330413",
    "label": "Slatri",
    "value": "Slatri"
  },
  {
    "id": "3304132006",
    "district_id": "330413",
    "label": "Pagerpelah",
    "value": "Pagerpelah"
  },
  {
    "id": "3304132007",
    "district_id": "330413",
    "label": "Pasuruhan",
    "value": "Pasuruhan"
  },
  {
    "id": "3304132008",
    "district_id": "330413",
    "label": "Karanggondang",
    "value": "Karanggondang"
  },
  {
    "id": "3304132009",
    "district_id": "330413",
    "label": "Jlegong",
    "value": "Jlegong"
  },
  {
    "id": "3304132010",
    "district_id": "330413",
    "label": "Ambal",
    "value": "Ambal"
  },
  {
    "id": "3304132011",
    "district_id": "330413",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3304132012",
    "district_id": "330413",
    "label": "Karangkobar",
    "value": "Karangkobar"
  },
  {
    "id": "3304132013",
    "district_id": "330413",
    "label": "Leksana",
    "value": "Leksana"
  },
  {
    "id": "3304142001",
    "district_id": "330414",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3304142002",
    "district_id": "330414",
    "label": "Karangnangka",
    "value": "Karangnangka"
  },
  {
    "id": "3304142003",
    "district_id": "330414",
    "label": "Aribaya",
    "value": "Aribaya"
  },
  {
    "id": "3304142004",
    "district_id": "330414",
    "label": "Nagasari",
    "value": "Nagasari"
  },
  {
    "id": "3304142005",
    "district_id": "330414",
    "label": "Gumingsir",
    "value": "Gumingsir"
  },
  {
    "id": "3304142006",
    "district_id": "330414",
    "label": "Sokaraja",
    "value": "Sokaraja"
  },
  {
    "id": "3304142007",
    "district_id": "330414",
    "label": "Kayuares",
    "value": "Kayuares"
  },
  {
    "id": "3304142008",
    "district_id": "330414",
    "label": "Metawana",
    "value": "Metawana"
  },
  {
    "id": "3304142009",
    "district_id": "330414",
    "label": "Kalitlaga",
    "value": "Kalitlaga"
  },
  {
    "id": "3304142010",
    "district_id": "330414",
    "label": "Karekan",
    "value": "Karekan"
  },
  {
    "id": "3304142011",
    "district_id": "330414",
    "label": "Plumbungan",
    "value": "Plumbungan"
  },
  {
    "id": "3304142012",
    "district_id": "330414",
    "label": "Pagentan",
    "value": "Pagentan"
  },
  {
    "id": "3304142013",
    "district_id": "330414",
    "label": "Kasmaran",
    "value": "Kasmaran"
  },
  {
    "id": "3304142014",
    "district_id": "330414",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3304142015",
    "district_id": "330414",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3304142016",
    "district_id": "330414",
    "label": "Tegaljeruk",
    "value": "Tegaljeruk"
  },
  {
    "id": "3304152001",
    "district_id": "330415",
    "label": "Kalilunjar",
    "value": "Kalilunjar"
  },
  {
    "id": "3304152002",
    "district_id": "330415",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3304152003",
    "district_id": "330415",
    "label": "Sarwodadi",
    "value": "Sarwodadi"
  },
  {
    "id": "3304152004",
    "district_id": "330415",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3304152005",
    "district_id": "330415",
    "label": "Giritirta",
    "value": "Giritirta"
  },
  {
    "id": "3304152006",
    "district_id": "330415",
    "label": "Biting",
    "value": "Biting"
  },
  {
    "id": "3304152007",
    "district_id": "330415",
    "label": "Tlahab",
    "value": "Tlahab"
  },
  {
    "id": "3304152008",
    "district_id": "330415",
    "label": "Darmayasa",
    "value": "Darmayasa"
  },
  {
    "id": "3304152009",
    "district_id": "330415",
    "label": "Pejawaran",
    "value": "Pejawaran"
  },
  {
    "id": "3304152010",
    "district_id": "330415",
    "label": "Penusupan",
    "value": "Penusupan"
  },
  {
    "id": "3304152011",
    "district_id": "330415",
    "label": "Ratamba",
    "value": "Ratamba"
  },
  {
    "id": "3304152012",
    "district_id": "330415",
    "label": "Sidengok",
    "value": "Sidengok"
  },
  {
    "id": "3304152013",
    "district_id": "330415",
    "label": "Pegundungan",
    "value": "Pegundungan"
  },
  {
    "id": "3304152014",
    "district_id": "330415",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3304152015",
    "district_id": "330415",
    "label": "Semangkung",
    "value": "Semangkung"
  },
  {
    "id": "3304152016",
    "district_id": "330415",
    "label": "Condongcampur",
    "value": "Condongcampur"
  },
  {
    "id": "3304152017",
    "district_id": "330415",
    "label": "Gembol",
    "value": "Gembol"
  },
  {
    "id": "3304162001",
    "district_id": "330416",
    "label": "Batur",
    "value": "Batur"
  },
  {
    "id": "3304162002",
    "district_id": "330416",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3304162003",
    "district_id": "330416",
    "label": "Pasurenan",
    "value": "Pasurenan"
  },
  {
    "id": "3304162004",
    "district_id": "330416",
    "label": "Pekasiran",
    "value": "Pekasiran"
  },
  {
    "id": "3304162005",
    "district_id": "330416",
    "label": "Kepakisan",
    "value": "Kepakisan"
  },
  {
    "id": "3304162006",
    "district_id": "330416",
    "label": "Bakal",
    "value": "Bakal"
  },
  {
    "id": "3304162007",
    "district_id": "330416",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3304162008",
    "district_id": "330416",
    "label": "Dieng Kulon",
    "value": "Dieng Kulon"
  },
  {
    "id": "3304172001",
    "district_id": "330417",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3304172002",
    "district_id": "330417",
    "label": "Suwidak",
    "value": "Suwidak"
  },
  {
    "id": "3304172003",
    "district_id": "330417",
    "label": "Bantar",
    "value": "Bantar"
  },
  {
    "id": "3304172004",
    "district_id": "330417",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3304172005",
    "district_id": "330417",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3304172006",
    "district_id": "330417",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3304172007",
    "district_id": "330417",
    "label": "Kubang",
    "value": "Kubang"
  },
  {
    "id": "3304172008",
    "district_id": "330417",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3304172009",
    "district_id": "330417",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "3304172010",
    "district_id": "330417",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3304172011",
    "district_id": "330417",
    "label": "Balun",
    "value": "Balun"
  },
  {
    "id": "3304172012",
    "district_id": "330417",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3304172013",
    "district_id": "330417",
    "label": "Wanaraja",
    "value": "Wanaraja"
  },
  {
    "id": "3304172014",
    "district_id": "330417",
    "label": "Jatilawang",
    "value": "Jatilawang"
  },
  {
    "id": "3304172015",
    "district_id": "330417",
    "label": "Legoksayem",
    "value": "Legoksayem"
  },
  {
    "id": "3304172016",
    "district_id": "330417",
    "label": "Kasimpar",
    "value": "Kasimpar"
  },
  {
    "id": "3304172017",
    "district_id": "330417",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "3304182001",
    "district_id": "330418",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "3304182002",
    "district_id": "330418",
    "label": "Asinan",
    "value": "Asinan"
  },
  {
    "id": "3304182003",
    "district_id": "330418",
    "label": "Sembawa",
    "value": "Sembawa"
  },
  {
    "id": "3304182004",
    "district_id": "330418",
    "label": "Kalibombong",
    "value": "Kalibombong"
  },
  {
    "id": "3304182005",
    "district_id": "330418",
    "label": "Majatengah",
    "value": "Majatengah"
  },
  {
    "id": "3304182006",
    "district_id": "330418",
    "label": "Kalisat Kidul",
    "value": "Kalisat Kidul"
  },
  {
    "id": "3304182007",
    "district_id": "330418",
    "label": "Sirukem",
    "value": "Sirukem"
  },
  {
    "id": "3304182008",
    "district_id": "330418",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3304182009",
    "district_id": "330418",
    "label": "Sidakangen",
    "value": "Sidakangen"
  },
  {
    "id": "3304182010",
    "district_id": "330418",
    "label": "Sikumpul",
    "value": "Sikumpul"
  },
  {
    "id": "3304182011",
    "district_id": "330418",
    "label": "Gununglangit",
    "value": "Gununglangit"
  },
  {
    "id": "3304182012",
    "district_id": "330418",
    "label": "Bedana",
    "value": "Bedana"
  },
  {
    "id": "3304182013",
    "district_id": "330418",
    "label": "Sirukun",
    "value": "Sirukun"
  },
  {
    "id": "3304182014",
    "district_id": "330418",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3304182015",
    "district_id": "330418",
    "label": "Plorengan",
    "value": "Plorengan"
  },
  {
    "id": "3304182016",
    "district_id": "330418",
    "label": "Kasinoman",
    "value": "Kasinoman"
  },
  {
    "id": "3304192001",
    "district_id": "330419",
    "label": "Pandanarum",
    "value": "Pandanarum"
  },
  {
    "id": "3304192002",
    "district_id": "330419",
    "label": "Sinduaji",
    "value": "Sinduaji"
  },
  {
    "id": "3304192003",
    "district_id": "330419",
    "label": "Pasegeran",
    "value": "Pasegeran"
  },
  {
    "id": "3304192004",
    "district_id": "330419",
    "label": "Pingit Lor",
    "value": "Pingit Lor"
  },
  {
    "id": "3304192005",
    "district_id": "330419",
    "label": "Lawen",
    "value": "Lawen"
  },
  {
    "id": "3304192006",
    "district_id": "330419",
    "label": "Sirongge",
    "value": "Sirongge"
  },
  {
    "id": "3304192007",
    "district_id": "330419",
    "label": "Pringamba",
    "value": "Pringamba"
  },
  {
    "id": "3304192008",
    "district_id": "330419",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3304202001",
    "district_id": "330420",
    "label": "Pagedongan",
    "value": "Pagedongan"
  },
  {
    "id": "3304202002",
    "district_id": "330420",
    "label": "Gunungjati",
    "value": "Gunungjati"
  },
  {
    "id": "3304202003",
    "district_id": "330420",
    "label": "Twelagiri",
    "value": "Twelagiri"
  },
  {
    "id": "3304202004",
    "district_id": "330420",
    "label": "Kebutuhduwur",
    "value": "Kebutuhduwur"
  },
  {
    "id": "3304202005",
    "district_id": "330420",
    "label": "Kebutuhjurang",
    "value": "Kebutuhjurang"
  },
  {
    "id": "3304202006",
    "district_id": "330420",
    "label": "Pesangkalan",
    "value": "Pesangkalan"
  },
  {
    "id": "3304202007",
    "district_id": "330420",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3304202008",
    "district_id": "330420",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "3304202009",
    "district_id": "330420",
    "label": "Gentansari",
    "value": "Gentansari"
  },
  {
    "id": "3305012001",
    "district_id": "330501",
    "label": "Argopeni",
    "value": "Argopeni"
  },
  {
    "id": "3305012002",
    "district_id": "330501",
    "label": "Karangduwur",
    "value": "Karangduwur"
  },
  {
    "id": "3305012003",
    "district_id": "330501",
    "label": "Srati",
    "value": "Srati"
  },
  {
    "id": "3305012004",
    "district_id": "330501",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "3305012005",
    "district_id": "330501",
    "label": "Jintung",
    "value": "Jintung"
  },
  {
    "id": "3305012006",
    "district_id": "330501",
    "label": "Banjararjo",
    "value": "Banjararjo"
  },
  {
    "id": "3305012007",
    "district_id": "330501",
    "label": "Argosari",
    "value": "Argosari"
  },
  {
    "id": "3305012008",
    "district_id": "330501",
    "label": "Watukelir",
    "value": "Watukelir"
  },
  {
    "id": "3305012009",
    "district_id": "330501",
    "label": "Kalibangkang",
    "value": "Kalibangkang"
  },
  {
    "id": "3305012010",
    "district_id": "330501",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3305012011",
    "district_id": "330501",
    "label": "Kalipoh",
    "value": "Kalipoh"
  },
  {
    "id": "3305012012",
    "district_id": "330501",
    "label": "Ayah",
    "value": "Ayah"
  },
  {
    "id": "3305012013",
    "district_id": "330501",
    "label": "Candirenggo",
    "value": "Candirenggo"
  },
  {
    "id": "3305012014",
    "district_id": "330501",
    "label": "Mangunweni",
    "value": "Mangunweni"
  },
  {
    "id": "3305012015",
    "district_id": "330501",
    "label": "Jatijajar",
    "value": "Jatijajar"
  },
  {
    "id": "3305012016",
    "district_id": "330501",
    "label": "Demangsari",
    "value": "Demangsari"
  },
  {
    "id": "3305012017",
    "district_id": "330501",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3305012018",
    "district_id": "330501",
    "label": "Kedungweru",
    "value": "Kedungweru"
  },
  {
    "id": "3305022001",
    "district_id": "330502",
    "label": "Karangbolong",
    "value": "Karangbolong"
  },
  {
    "id": "3305022002",
    "district_id": "330502",
    "label": "Jladri",
    "value": "Jladri"
  },
  {
    "id": "3305022003",
    "district_id": "330502",
    "label": "Adiwarno",
    "value": "Adiwarno"
  },
  {
    "id": "3305022004",
    "district_id": "330502",
    "label": "Rangkah",
    "value": "Rangkah"
  },
  {
    "id": "3305022005",
    "district_id": "330502",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3305022006",
    "district_id": "330502",
    "label": "Geblug",
    "value": "Geblug"
  },
  {
    "id": "3305022007",
    "district_id": "330502",
    "label": "Rogodadi",
    "value": "Rogodadi"
  },
  {
    "id": "3305022008",
    "district_id": "330502",
    "label": "Pakuran",
    "value": "Pakuran"
  },
  {
    "id": "3305022009",
    "district_id": "330502",
    "label": "Buayan",
    "value": "Buayan"
  },
  {
    "id": "3305022010",
    "district_id": "330502",
    "label": "Sikayu",
    "value": "Sikayu"
  },
  {
    "id": "3305022011",
    "district_id": "330502",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3305022012",
    "district_id": "330502",
    "label": "Rogodono",
    "value": "Rogodono"
  },
  {
    "id": "3305022013",
    "district_id": "330502",
    "label": "Banyumudal",
    "value": "Banyumudal"
  },
  {
    "id": "3305022014",
    "district_id": "330502",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3305022015",
    "district_id": "330502",
    "label": "Nogoraji",
    "value": "Nogoraji"
  },
  {
    "id": "3305022016",
    "district_id": "330502",
    "label": "Mergosono",
    "value": "Mergosono"
  },
  {
    "id": "3305022017",
    "district_id": "330502",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3305022018",
    "district_id": "330502",
    "label": "Jogomulyo",
    "value": "Jogomulyo"
  },
  {
    "id": "3305022019",
    "district_id": "330502",
    "label": "Purbowangi",
    "value": "Purbowangi"
  },
  {
    "id": "3305022020",
    "district_id": "330502",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "3305032001",
    "district_id": "330503",
    "label": "Tambakmulyo",
    "value": "Tambakmulyo"
  },
  {
    "id": "3305032002",
    "district_id": "330503",
    "label": "Surorejan",
    "value": "Surorejan"
  },
  {
    "id": "3305032003",
    "district_id": "330503",
    "label": "Waluyorejo",
    "value": "Waluyorejo"
  },
  {
    "id": "3305032004",
    "district_id": "330503",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3305032005",
    "district_id": "330503",
    "label": "Puliharjo",
    "value": "Puliharjo"
  },
  {
    "id": "3305032006",
    "district_id": "330503",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3305032007",
    "district_id": "330503",
    "label": "Arjowinangun",
    "value": "Arjowinangun"
  },
  {
    "id": "3305032008",
    "district_id": "330503",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3305032009",
    "district_id": "330503",
    "label": "Kaleng",
    "value": "Kaleng"
  },
  {
    "id": "3305032010",
    "district_id": "330503",
    "label": "Tukinggedong",
    "value": "Tukinggedong"
  },
  {
    "id": "3305032011",
    "district_id": "330503",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "3305032012",
    "district_id": "330503",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3305032013",
    "district_id": "330503",
    "label": "Wetonkulon",
    "value": "Wetonkulon"
  },
  {
    "id": "3305032014",
    "district_id": "330503",
    "label": "Pesuruhan",
    "value": "Pesuruhan"
  },
  {
    "id": "3305032015",
    "district_id": "330503",
    "label": "Wetonwetan",
    "value": "Wetonwetan"
  },
  {
    "id": "3305032016",
    "district_id": "330503",
    "label": "Kedalemankulon",
    "value": "Kedalemankulon"
  },
  {
    "id": "3305032017",
    "district_id": "330503",
    "label": "Kedalemanwetan",
    "value": "Kedalemanwetan"
  },
  {
    "id": "3305032018",
    "district_id": "330503",
    "label": "Srusuhjurutengah",
    "value": "Srusuhjurutengah"
  },
  {
    "id": "3305032019",
    "district_id": "330503",
    "label": "Sitiadi",
    "value": "Sitiadi"
  },
  {
    "id": "3305032020",
    "district_id": "330503",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3305032021",
    "district_id": "330503",
    "label": "Madurejo",
    "value": "Madurejo"
  },
  {
    "id": "3305032022",
    "district_id": "330503",
    "label": "Sidobunder",
    "value": "Sidobunder"
  },
  {
    "id": "3305032023",
    "district_id": "330503",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3305042001",
    "district_id": "330504",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3305042002",
    "district_id": "330504",
    "label": "Karanggadung",
    "value": "Karanggadung"
  },
  {
    "id": "3305042003",
    "district_id": "330504",
    "label": "Tegalretno",
    "value": "Tegalretno"
  },
  {
    "id": "3305042004",
    "district_id": "330504",
    "label": "Ampelsari",
    "value": "Ampelsari"
  },
  {
    "id": "3305042005",
    "district_id": "330504",
    "label": "Munggu",
    "value": "Munggu"
  },
  {
    "id": "3305042006",
    "district_id": "330504",
    "label": "Kewangunan",
    "value": "Kewangunan"
  },
  {
    "id": "3305042007",
    "district_id": "330504",
    "label": "Karangduwur",
    "value": "Karangduwur"
  },
  {
    "id": "3305042008",
    "district_id": "330504",
    "label": "Petanahan",
    "value": "Petanahan"
  },
  {
    "id": "3305042009",
    "district_id": "330504",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3305042010",
    "district_id": "330504",
    "label": "Grogolpenatus",
    "value": "Grogolpenatus"
  },
  {
    "id": "3305042011",
    "district_id": "330504",
    "label": "Grogolbeningsari",
    "value": "Grogolbeningsari"
  },
  {
    "id": "3305042012",
    "district_id": "330504",
    "label": "Jogomertan",
    "value": "Jogomertan"
  },
  {
    "id": "3305042013",
    "district_id": "330504",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3305042014",
    "district_id": "330504",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3305042015",
    "district_id": "330504",
    "label": "Grujugan",
    "value": "Grujugan"
  },
  {
    "id": "3305042016",
    "district_id": "330504",
    "label": "Kritig",
    "value": "Kritig"
  },
  {
    "id": "3305042017",
    "district_id": "330504",
    "label": "Nampudadi",
    "value": "Nampudadi"
  },
  {
    "id": "3305042018",
    "district_id": "330504",
    "label": "Tresnorejo",
    "value": "Tresnorejo"
  },
  {
    "id": "3305042019",
    "district_id": "330504",
    "label": "Podourip",
    "value": "Podourip"
  },
  {
    "id": "3305042020",
    "district_id": "330504",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3305042021",
    "district_id": "330504",
    "label": "Banjarwinangun",
    "value": "Banjarwinangun"
  },
  {
    "id": "3305052001",
    "district_id": "330505",
    "label": "Jogosimo",
    "value": "Jogosimo"
  },
  {
    "id": "3305052002",
    "district_id": "330505",
    "label": "Tanggulangin",
    "value": "Tanggulangin"
  },
  {
    "id": "3305052003",
    "district_id": "330505",
    "label": "Pandanlor",
    "value": "Pandanlor"
  },
  {
    "id": "3305052004",
    "district_id": "330505",
    "label": "Tambakprogaten",
    "value": "Tambakprogaten"
  },
  {
    "id": "3305052005",
    "district_id": "330505",
    "label": "Gebangsari",
    "value": "Gebangsari"
  },
  {
    "id": "3305052006",
    "district_id": "330505",
    "label": "Klegenrejo",
    "value": "Klegenrejo"
  },
  {
    "id": "3305052007",
    "district_id": "330505",
    "label": "Bendogarap",
    "value": "Bendogarap"
  },
  {
    "id": "3305052008",
    "district_id": "330505",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3305052009",
    "district_id": "330505",
    "label": "Jerukagung",
    "value": "Jerukagung"
  },
  {
    "id": "3305052010",
    "district_id": "330505",
    "label": "Klegenwonosari",
    "value": "Klegenwonosari"
  },
  {
    "id": "3305052011",
    "district_id": "330505",
    "label": "Klirong",
    "value": "Klirong"
  },
  {
    "id": "3305052012",
    "district_id": "330505",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3305052013",
    "district_id": "330505",
    "label": "Jatimalang",
    "value": "Jatimalang"
  },
  {
    "id": "3305052014",
    "district_id": "330505",
    "label": "Karangglonggong",
    "value": "Karangglonggong"
  },
  {
    "id": "3305052015",
    "district_id": "330505",
    "label": "Ranterejo",
    "value": "Ranterejo"
  },
  {
    "id": "3305052016",
    "district_id": "330505",
    "label": "Wotbuwono",
    "value": "Wotbuwono"
  },
  {
    "id": "3305052017",
    "district_id": "330505",
    "label": "Tambakagung",
    "value": "Tambakagung"
  },
  {
    "id": "3305052018",
    "district_id": "330505",
    "label": "Sitirejo",
    "value": "Sitirejo"
  },
  {
    "id": "3305052019",
    "district_id": "330505",
    "label": "Gadungrejo",
    "value": "Gadungrejo"
  },
  {
    "id": "3305052020",
    "district_id": "330505",
    "label": "Dorowati",
    "value": "Dorowati"
  },
  {
    "id": "3305052021",
    "district_id": "330505",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3305052022",
    "district_id": "330505",
    "label": "Kebadongan",
    "value": "Kebadongan"
  },
  {
    "id": "3305052023",
    "district_id": "330505",
    "label": "Podoluhur",
    "value": "Podoluhur"
  },
  {
    "id": "3305052024",
    "district_id": "330505",
    "label": "Kedungwinangun",
    "value": "Kedungwinangun"
  },
  {
    "id": "3305062001",
    "district_id": "330506",
    "label": "Ayamputih",
    "value": "Ayamputih"
  },
  {
    "id": "3305062002",
    "district_id": "330506",
    "label": "Setrojenar",
    "value": "Setrojenar"
  },
  {
    "id": "3305062003",
    "district_id": "330506",
    "label": "Brecong",
    "value": "Brecong"
  },
  {
    "id": "3305062004",
    "district_id": "330506",
    "label": "Banjurpasar",
    "value": "Banjurpasar"
  },
  {
    "id": "3305062005",
    "district_id": "330506",
    "label": "Indrosari",
    "value": "Indrosari"
  },
  {
    "id": "3305062006",
    "district_id": "330506",
    "label": "Buluspesantren",
    "value": "Buluspesantren"
  },
  {
    "id": "3305062007",
    "district_id": "330506",
    "label": "Banjurmukadan",
    "value": "Banjurmukadan"
  },
  {
    "id": "3305062008",
    "district_id": "330506",
    "label": "Waluyo",
    "value": "Waluyo"
  },
  {
    "id": "3305062009",
    "district_id": "330506",
    "label": "Bocor",
    "value": "Bocor"
  },
  {
    "id": "3305062010",
    "district_id": "330506",
    "label": "Maduretno",
    "value": "Maduretno"
  },
  {
    "id": "3305062011",
    "district_id": "330506",
    "label": "Ambalkumolo",
    "value": "Ambalkumolo"
  },
  {
    "id": "3305062012",
    "district_id": "330506",
    "label": "Rantewringin",
    "value": "Rantewringin"
  },
  {
    "id": "3305062013",
    "district_id": "330506",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3305062014",
    "district_id": "330506",
    "label": "Sangubanyu",
    "value": "Sangubanyu"
  },
  {
    "id": "3305062015",
    "district_id": "330506",
    "label": "Arjowinangun",
    "value": "Arjowinangun"
  },
  {
    "id": "3305062016",
    "district_id": "330506",
    "label": "Ampih",
    "value": "Ampih"
  },
  {
    "id": "3305062017",
    "district_id": "330506",
    "label": "Jogopaten",
    "value": "Jogopaten"
  },
  {
    "id": "3305062018",
    "district_id": "330506",
    "label": "Kloposawit",
    "value": "Kloposawit"
  },
  {
    "id": "3305062019",
    "district_id": "330506",
    "label": "Sidomoro",
    "value": "Sidomoro"
  },
  {
    "id": "3305062020",
    "district_id": "330506",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3305062021",
    "district_id": "330506",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3305072001",
    "district_id": "330507",
    "label": "Entak",
    "value": "Entak"
  },
  {
    "id": "3305072002",
    "district_id": "330507",
    "label": "Plempukankembaran",
    "value": "Plempukankembaran"
  },
  {
    "id": "3305072003",
    "district_id": "330507",
    "label": "Kenoyojayan",
    "value": "Kenoyojayan"
  },
  {
    "id": "3305072004",
    "district_id": "330507",
    "label": "Ambalresmi",
    "value": "Ambalresmi"
  },
  {
    "id": "3305072005",
    "district_id": "330507",
    "label": "Kaibonpetangkuran",
    "value": "Kaibonpetangkuran"
  },
  {
    "id": "3305072006",
    "district_id": "330507",
    "label": "Kaibon",
    "value": "Kaibon"
  },
  {
    "id": "3305072007",
    "district_id": "330507",
    "label": "Sumberjati",
    "value": "Sumberjati"
  },
  {
    "id": "3305072008",
    "district_id": "330507",
    "label": "Blengorwetan",
    "value": "Blengorwetan"
  },
  {
    "id": "3305072009",
    "district_id": "330507",
    "label": "Blengorkulon",
    "value": "Blengorkulon"
  },
  {
    "id": "3305072010",
    "district_id": "330507",
    "label": "Benerwetan",
    "value": "Benerwetan"
  },
  {
    "id": "3305072011",
    "district_id": "330507",
    "label": "Benerkulon",
    "value": "Benerkulon"
  },
  {
    "id": "3305072012",
    "district_id": "330507",
    "label": "Ambalkliwonan",
    "value": "Ambalkliwonan"
  },
  {
    "id": "3305072013",
    "district_id": "330507",
    "label": "Pasarsenen",
    "value": "Pasarsenen"
  },
  {
    "id": "3305072014",
    "district_id": "330507",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3305072015",
    "district_id": "330507",
    "label": "Ambalkebrek",
    "value": "Ambalkebrek"
  },
  {
    "id": "3305072016",
    "district_id": "330507",
    "label": "Gondanglegi",
    "value": "Gondanglegi"
  },
  {
    "id": "3305072017",
    "district_id": "330507",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3305072018",
    "district_id": "330507",
    "label": "Lajer",
    "value": "Lajer"
  },
  {
    "id": "3305072019",
    "district_id": "330507",
    "label": "Singosari",
    "value": "Singosari"
  },
  {
    "id": "3305072020",
    "district_id": "330507",
    "label": "Sidoluhur",
    "value": "Sidoluhur"
  },
  {
    "id": "3305072021",
    "district_id": "330507",
    "label": "Sinungrejo",
    "value": "Sinungrejo"
  },
  {
    "id": "3305072022",
    "district_id": "330507",
    "label": "Ambarwinangun",
    "value": "Ambarwinangun"
  },
  {
    "id": "3305072023",
    "district_id": "330507",
    "label": "Peneket",
    "value": "Peneket"
  },
  {
    "id": "3305072024",
    "district_id": "330507",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3305072025",
    "district_id": "330507",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3305072026",
    "district_id": "330507",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3305072027",
    "district_id": "330507",
    "label": "Prasutan",
    "value": "Prasutan"
  },
  {
    "id": "3305072028",
    "district_id": "330507",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3305072029",
    "district_id": "330507",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "3305072030",
    "district_id": "330507",
    "label": "Surobayan",
    "value": "Surobayan"
  },
  {
    "id": "3305072031",
    "district_id": "330507",
    "label": "Dukuhrejosari",
    "value": "Dukuhrejosari"
  },
  {
    "id": "3305072032",
    "district_id": "330507",
    "label": "Kembangsawit",
    "value": "Kembangsawit"
  },
  {
    "id": "3305082001",
    "district_id": "330508",
    "label": "Miritpetikusan",
    "value": "Miritpetikusan"
  },
  {
    "id": "3305082002",
    "district_id": "330508",
    "label": "Tlogodepok",
    "value": "Tlogodepok"
  },
  {
    "id": "3305082003",
    "district_id": "330508",
    "label": "Mirit",
    "value": "Mirit"
  },
  {
    "id": "3305082004",
    "district_id": "330508",
    "label": "Tlogopragoto",
    "value": "Tlogopragoto"
  },
  {
    "id": "3305082005",
    "district_id": "330508",
    "label": "Lembupurwo",
    "value": "Lembupurwo"
  },
  {
    "id": "3305082006",
    "district_id": "330508",
    "label": "Wiromartan",
    "value": "Wiromartan"
  },
  {
    "id": "3305082007",
    "district_id": "330508",
    "label": "Rowo",
    "value": "Rowo"
  },
  {
    "id": "3305082008",
    "district_id": "330508",
    "label": "Singoyudan",
    "value": "Singoyudan"
  },
  {
    "id": "3305082009",
    "district_id": "330508",
    "label": "Wergonayan",
    "value": "Wergonayan"
  },
  {
    "id": "3305082010",
    "district_id": "330508",
    "label": "Selotumpeng",
    "value": "Selotumpeng"
  },
  {
    "id": "3305082011",
    "district_id": "330508",
    "label": "Sitibentar",
    "value": "Sitibentar"
  },
  {
    "id": "3305082012",
    "district_id": "330508",
    "label": "Karanggede",
    "value": "Karanggede"
  },
  {
    "id": "3305082013",
    "district_id": "330508",
    "label": "Kertodeso",
    "value": "Kertodeso"
  },
  {
    "id": "3305082014",
    "district_id": "330508",
    "label": "Patukrejomulyo",
    "value": "Patukrejomulyo"
  },
  {
    "id": "3305082015",
    "district_id": "330508",
    "label": "Patukgawemulyo",
    "value": "Patukgawemulyo"
  },
  {
    "id": "3305082016",
    "district_id": "330508",
    "label": "Mangunranan",
    "value": "Mangunranan"
  },
  {
    "id": "3305082017",
    "district_id": "330508",
    "label": "Pekutan",
    "value": "Pekutan"
  },
  {
    "id": "3305082018",
    "district_id": "330508",
    "label": "Wirogaten",
    "value": "Wirogaten"
  },
  {
    "id": "3305082019",
    "district_id": "330508",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3305082020",
    "district_id": "330508",
    "label": "Ngabean",
    "value": "Ngabean"
  },
  {
    "id": "3305082021",
    "district_id": "330508",
    "label": "Sarwogadung",
    "value": "Sarwogadung"
  },
  {
    "id": "3305082022",
    "district_id": "330508",
    "label": "Krubungan",
    "value": "Krubungan"
  },
  {
    "id": "3305092001",
    "district_id": "330509",
    "label": "Tersobo",
    "value": "Tersobo"
  },
  {
    "id": "3305092002",
    "district_id": "330509",
    "label": "Prembun",
    "value": "Prembun"
  },
  {
    "id": "3305092003",
    "district_id": "330509",
    "label": "Kabekelan",
    "value": "Kabekelan"
  },
  {
    "id": "3305092004",
    "district_id": "330509",
    "label": "Tunggalroso",
    "value": "Tunggalroso"
  },
  {
    "id": "3305092005",
    "district_id": "330509",
    "label": "Kedungwaru",
    "value": "Kedungwaru"
  },
  {
    "id": "3305092006",
    "district_id": "330509",
    "label": "Bagung",
    "value": "Bagung"
  },
  {
    "id": "3305092007",
    "district_id": "330509",
    "label": "Sidogede",
    "value": "Sidogede"
  },
  {
    "id": "3305092008",
    "district_id": "330509",
    "label": "Sembirkadipaten",
    "value": "Sembirkadipaten"
  },
  {
    "id": "3305092009",
    "district_id": "330509",
    "label": "Kedungbulus",
    "value": "Kedungbulus"
  },
  {
    "id": "3305092010",
    "district_id": "330509",
    "label": "Mulyosri",
    "value": "Mulyosri"
  },
  {
    "id": "3305092011",
    "district_id": "330509",
    "label": "Pesuningan",
    "value": "Pesuningan"
  },
  {
    "id": "3305092012",
    "district_id": "330509",
    "label": "Pecarikan",
    "value": "Pecarikan"
  },
  {
    "id": "3305092013",
    "district_id": "330509",
    "label": "Kabuaran",
    "value": "Kabuaran"
  },
  {
    "id": "3305102001",
    "district_id": "330510",
    "label": "Pekunden",
    "value": "Pekunden"
  },
  {
    "id": "3305102002",
    "district_id": "330510",
    "label": "Tanjungmeru",
    "value": "Tanjungmeru"
  },
  {
    "id": "3305102003",
    "district_id": "330510",
    "label": "Kuwarisan",
    "value": "Kuwarisan"
  },
  {
    "id": "3305102004",
    "district_id": "330510",
    "label": "Kutowinangun",
    "value": "Kutowinangun"
  },
  {
    "id": "3305102005",
    "district_id": "330510",
    "label": "Lundong",
    "value": "Lundong"
  },
  {
    "id": "3305102006",
    "district_id": "330510",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3305102007",
    "district_id": "330510",
    "label": "Babadsari",
    "value": "Babadsari"
  },
  {
    "id": "3305102008",
    "district_id": "330510",
    "label": "Ungaran",
    "value": "Ungaran"
  },
  {
    "id": "3305102009",
    "district_id": "330510",
    "label": "Mrinen",
    "value": "Mrinen"
  },
  {
    "id": "3305102010",
    "district_id": "330510",
    "label": "Pejagatan",
    "value": "Pejagatan"
  },
  {
    "id": "3305102011",
    "district_id": "330510",
    "label": "Triwarno",
    "value": "Triwarno"
  },
  {
    "id": "3305102012",
    "district_id": "330510",
    "label": "Korowelang",
    "value": "Korowelang"
  },
  {
    "id": "3305102013",
    "district_id": "330510",
    "label": "Jlegiwinangun",
    "value": "Jlegiwinangun"
  },
  {
    "id": "3305102014",
    "district_id": "330510",
    "label": "Lumbu",
    "value": "Lumbu"
  },
  {
    "id": "3305102015",
    "district_id": "330510",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3305102016",
    "district_id": "330510",
    "label": "Kaliputih",
    "value": "Kaliputih"
  },
  {
    "id": "3305102017",
    "district_id": "330510",
    "label": "Tanjungseto",
    "value": "Tanjungseto"
  },
  {
    "id": "3305102018",
    "district_id": "330510",
    "label": "Pesalakan",
    "value": "Pesalakan"
  },
  {
    "id": "3305102019",
    "district_id": "330510",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3305112001",
    "district_id": "330511",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3305112002",
    "district_id": "330511",
    "label": "Surotrunan",
    "value": "Surotrunan"
  },
  {
    "id": "3305112003",
    "district_id": "330511",
    "label": "Kambangsari",
    "value": "Kambangsari"
  },
  {
    "id": "3305112004",
    "district_id": "330511",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3305112005",
    "district_id": "330511",
    "label": "Tanuharjo",
    "value": "Tanuharjo"
  },
  {
    "id": "3305112006",
    "district_id": "330511",
    "label": "Karangtanjung",
    "value": "Karangtanjung"
  },
  {
    "id": "3305112007",
    "district_id": "330511",
    "label": "Kemangguan",
    "value": "Kemangguan"
  },
  {
    "id": "3305112008",
    "district_id": "330511",
    "label": "Kalijaya",
    "value": "Kalijaya"
  },
  {
    "id": "3305112009",
    "district_id": "330511",
    "label": "Karangkembang",
    "value": "Karangkembang"
  },
  {
    "id": "3305112010",
    "district_id": "330511",
    "label": "Seliling",
    "value": "Seliling"
  },
  {
    "id": "3305112011",
    "district_id": "330511",
    "label": "Tlogowulung",
    "value": "Tlogowulung"
  },
  {
    "id": "3305112012",
    "district_id": "330511",
    "label": "Kaliputih",
    "value": "Kaliputih"
  },
  {
    "id": "3305112013",
    "district_id": "330511",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "3305112014",
    "district_id": "330511",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3305112015",
    "district_id": "330511",
    "label": "Kalirancang",
    "value": "Kalirancang"
  },
  {
    "id": "3305112016",
    "district_id": "330511",
    "label": "Krakal",
    "value": "Krakal"
  },
  {
    "id": "3305121010",
    "district_id": "330512",
    "label": "Selang",
    "value": "Selang"
  },
  {
    "id": "3305121012",
    "district_id": "330512",
    "label": "Tamanwinangun",
    "value": "Tamanwinangun"
  },
  {
    "id": "3305121013",
    "district_id": "330512",
    "label": "Panjer",
    "value": "Panjer"
  },
  {
    "id": "3305121024",
    "district_id": "330512",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3305121026",
    "district_id": "330512",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3305122001",
    "district_id": "330512",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "3305122002",
    "district_id": "330512",
    "label": "Murtirejo",
    "value": "Murtirejo"
  },
  {
    "id": "3305122003",
    "district_id": "330512",
    "label": "Depokrejo",
    "value": "Depokrejo"
  },
  {
    "id": "3305122004",
    "district_id": "330512",
    "label": "Mengkowo",
    "value": "Mengkowo"
  },
  {
    "id": "3305122005",
    "district_id": "330512",
    "label": "Gesikan",
    "value": "Gesikan"
  },
  {
    "id": "3305122006",
    "district_id": "330512",
    "label": "Kalibagor",
    "value": "Kalibagor"
  },
  {
    "id": "3305122007",
    "district_id": "330512",
    "label": "Argopeni",
    "value": "Argopeni"
  },
  {
    "id": "3305122008",
    "district_id": "330512",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3305122009",
    "district_id": "330512",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3305122011",
    "district_id": "330512",
    "label": "Adikarso",
    "value": "Adikarso"
  },
  {
    "id": "3305122014",
    "district_id": "330512",
    "label": "Kembaran",
    "value": "Kembaran"
  },
  {
    "id": "3305122015",
    "district_id": "330512",
    "label": "Sumberadi",
    "value": "Sumberadi"
  },
  {
    "id": "3305122016",
    "district_id": "330512",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3305122017",
    "district_id": "330512",
    "label": "Roworejo",
    "value": "Roworejo"
  },
  {
    "id": "3305122018",
    "district_id": "330512",
    "label": "Tanahsari",
    "value": "Tanahsari"
  },
  {
    "id": "3305122019",
    "district_id": "330512",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3305122020",
    "district_id": "330512",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3305122021",
    "district_id": "330512",
    "label": "Kalijirek",
    "value": "Kalijirek"
  },
  {
    "id": "3305122022",
    "district_id": "330512",
    "label": "Candiwulan",
    "value": "Candiwulan"
  },
  {
    "id": "3305122023",
    "district_id": "330512",
    "label": "Kawedusan",
    "value": "Kawedusan"
  },
  {
    "id": "3305122025",
    "district_id": "330512",
    "label": "Kutosari",
    "value": "Kutosari"
  },
  {
    "id": "3305122027",
    "district_id": "330512",
    "label": "Gemeksekti",
    "value": "Gemeksekti"
  },
  {
    "id": "3305122028",
    "district_id": "330512",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3305122029",
    "district_id": "330512",
    "label": "Jemur",
    "value": "Jemur"
  },
  {
    "id": "3305132001",
    "district_id": "330513",
    "label": "Logede",
    "value": "Logede"
  },
  {
    "id": "3305132002",
    "district_id": "330513",
    "label": "Kewayuhan",
    "value": "Kewayuhan"
  },
  {
    "id": "3305132003",
    "district_id": "330513",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3305132004",
    "district_id": "330513",
    "label": "Pejagoan",
    "value": "Pejagoan"
  },
  {
    "id": "3305132005",
    "district_id": "330513",
    "label": "Kebulusan",
    "value": "Kebulusan"
  },
  {
    "id": "3305132006",
    "district_id": "330513",
    "label": "Aditirto",
    "value": "Aditirto"
  },
  {
    "id": "3305132007",
    "district_id": "330513",
    "label": "Karangpoh",
    "value": "Karangpoh"
  },
  {
    "id": "3305132008",
    "district_id": "330513",
    "label": "Jemur",
    "value": "Jemur"
  },
  {
    "id": "3305132009",
    "district_id": "330513",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3305132010",
    "district_id": "330513",
    "label": "Kebagoran",
    "value": "Kebagoran"
  },
  {
    "id": "3305132011",
    "district_id": "330513",
    "label": "Pengaringan",
    "value": "Pengaringan"
  },
  {
    "id": "3305132012",
    "district_id": "330513",
    "label": "Peniron",
    "value": "Peniron"
  },
  {
    "id": "3305132013",
    "district_id": "330513",
    "label": "Watulawang",
    "value": "Watulawang"
  },
  {
    "id": "3305142001",
    "district_id": "330514",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "3305142002",
    "district_id": "330514",
    "label": "Trikarso",
    "value": "Trikarso"
  },
  {
    "id": "3305142003",
    "district_id": "330514",
    "label": "Sidoarjo",
    "value": "Sidoarjo"
  },
  {
    "id": "3305142004",
    "district_id": "330514",
    "label": "Giwangretno",
    "value": "Giwangretno"
  },
  {
    "id": "3305142005",
    "district_id": "330514",
    "label": "Jabres",
    "value": "Jabres"
  },
  {
    "id": "3305142006",
    "district_id": "330514",
    "label": "Sruweng",
    "value": "Sruweng"
  },
  {
    "id": "3305142007",
    "district_id": "330514",
    "label": "Karanggedang",
    "value": "Karanggedang"
  },
  {
    "id": "3305142008",
    "district_id": "330514",
    "label": "Purwodeso",
    "value": "Purwodeso"
  },
  {
    "id": "3305142009",
    "district_id": "330514",
    "label": "Klepusanggar",
    "value": "Klepusanggar"
  },
  {
    "id": "3305142010",
    "district_id": "330514",
    "label": "Tanggeran",
    "value": "Tanggeran"
  },
  {
    "id": "3305142011",
    "district_id": "330514",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3305142012",
    "district_id": "330514",
    "label": "Karangpule",
    "value": "Karangpule"
  },
  {
    "id": "3305142013",
    "district_id": "330514",
    "label": "Pakuran",
    "value": "Pakuran"
  },
  {
    "id": "3305142014",
    "district_id": "330514",
    "label": "Pengempon",
    "value": "Pengempon"
  },
  {
    "id": "3305142015",
    "district_id": "330514",
    "label": "Kejawang",
    "value": "Kejawang"
  },
  {
    "id": "3305142016",
    "district_id": "330514",
    "label": "Karangjambu",
    "value": "Karangjambu"
  },
  {
    "id": "3305142017",
    "district_id": "330514",
    "label": "Sidoagung",
    "value": "Sidoagung"
  },
  {
    "id": "3305142018",
    "district_id": "330514",
    "label": "Penusupan",
    "value": "Penusupan"
  },
  {
    "id": "3305142019",
    "district_id": "330514",
    "label": "Donosari",
    "value": "Donosari"
  },
  {
    "id": "3305142020",
    "district_id": "330514",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3305142021",
    "district_id": "330514",
    "label": "Condongcampur",
    "value": "Condongcampur"
  },
  {
    "id": "3305152001",
    "district_id": "330515",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3305152002",
    "district_id": "330515",
    "label": "Tambaharjo",
    "value": "Tambaharjo"
  },
  {
    "id": "3305152003",
    "district_id": "330515",
    "label": "Tepakyang",
    "value": "Tepakyang"
  },
  {
    "id": "3305152004",
    "district_id": "330515",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3305152005",
    "district_id": "330515",
    "label": "Wajasari",
    "value": "Wajasari"
  },
  {
    "id": "3305152006",
    "district_id": "330515",
    "label": "Candiwulan",
    "value": "Candiwulan"
  },
  {
    "id": "3305152007",
    "district_id": "330515",
    "label": "Adikarto",
    "value": "Adikarto"
  },
  {
    "id": "3305152008",
    "district_id": "330515",
    "label": "Adimulyo",
    "value": "Adimulyo"
  },
  {
    "id": "3305152009",
    "district_id": "330515",
    "label": "Temanggal",
    "value": "Temanggal"
  },
  {
    "id": "3305152010",
    "district_id": "330515",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3305152011",
    "district_id": "330515",
    "label": "Adiluhur",
    "value": "Adiluhur"
  },
  {
    "id": "3305152012",
    "district_id": "330515",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3305152013",
    "district_id": "330515",
    "label": "Sekarteja",
    "value": "Sekarteja"
  },
  {
    "id": "3305152014",
    "district_id": "330515",
    "label": "Kemujan",
    "value": "Kemujan"
  },
  {
    "id": "3305152015",
    "district_id": "330515",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3305152016",
    "district_id": "330515",
    "label": "Banyuroto",
    "value": "Banyuroto"
  },
  {
    "id": "3305152017",
    "district_id": "330515",
    "label": "Meles",
    "value": "Meles"
  },
  {
    "id": "3305152018",
    "district_id": "330515",
    "label": "Caruban",
    "value": "Caruban"
  },
  {
    "id": "3305152019",
    "district_id": "330515",
    "label": "Bonjok",
    "value": "Bonjok"
  },
  {
    "id": "3305152020",
    "district_id": "330515",
    "label": "Arjomulyo",
    "value": "Arjomulyo"
  },
  {
    "id": "3305152021",
    "district_id": "330515",
    "label": "Arjosari",
    "value": "Arjosari"
  },
  {
    "id": "3305152022",
    "district_id": "330515",
    "label": "Pekuwon",
    "value": "Pekuwon"
  },
  {
    "id": "3305152023",
    "district_id": "330515",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3305162001",
    "district_id": "330516",
    "label": "Kamulyan",
    "value": "Kamulyan"
  },
  {
    "id": "3305162002",
    "district_id": "330516",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3305162003",
    "district_id": "330516",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3305162004",
    "district_id": "330516",
    "label": "Kalipurwo",
    "value": "Kalipurwo"
  },
  {
    "id": "3305162005",
    "district_id": "330516",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3305162006",
    "district_id": "330516",
    "label": "Pondokgebangsari",
    "value": "Pondokgebangsari"
  },
  {
    "id": "3305162007",
    "district_id": "330516",
    "label": "Kuwarasan",
    "value": "Kuwarasan"
  },
  {
    "id": "3305162008",
    "district_id": "330516",
    "label": "Harjodowo",
    "value": "Harjodowo"
  },
  {
    "id": "3305162009",
    "district_id": "330516",
    "label": "Lemahduwur",
    "value": "Lemahduwur"
  },
  {
    "id": "3305162010",
    "district_id": "330516",
    "label": "Madureso",
    "value": "Madureso"
  },
  {
    "id": "3305162011",
    "district_id": "330516",
    "label": "Mangli",
    "value": "Mangli"
  },
  {
    "id": "3305162012",
    "district_id": "330516",
    "label": "Gandusari",
    "value": "Gandusari"
  },
  {
    "id": "3305162013",
    "district_id": "330516",
    "label": "Ori",
    "value": "Ori"
  },
  {
    "id": "3305162014",
    "district_id": "330516",
    "label": "Serut",
    "value": "Serut"
  },
  {
    "id": "3305162015",
    "district_id": "330516",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3305162016",
    "district_id": "330516",
    "label": "Gumawang",
    "value": "Gumawang"
  },
  {
    "id": "3305162017",
    "district_id": "330516",
    "label": "Wonoyoso",
    "value": "Wonoyoso"
  },
  {
    "id": "3305162018",
    "district_id": "330516",
    "label": "Gunungmujil",
    "value": "Gunungmujil"
  },
  {
    "id": "3305162019",
    "district_id": "330516",
    "label": "Kuwaru",
    "value": "Kuwaru"
  },
  {
    "id": "3305162020",
    "district_id": "330516",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3305162021",
    "district_id": "330516",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3305162022",
    "district_id": "330516",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3305172001",
    "district_id": "330517",
    "label": "Redisari",
    "value": "Redisari"
  },
  {
    "id": "3305172002",
    "district_id": "330517",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3305172003",
    "district_id": "330517",
    "label": "Pringtutul",
    "value": "Pringtutul"
  },
  {
    "id": "3305172004",
    "district_id": "330517",
    "label": "Rowokele",
    "value": "Rowokele"
  },
  {
    "id": "3305172005",
    "district_id": "330517",
    "label": "Bumiagung",
    "value": "Bumiagung"
  },
  {
    "id": "3305172006",
    "district_id": "330517",
    "label": "Jatiluhur",
    "value": "Jatiluhur"
  },
  {
    "id": "3305172007",
    "district_id": "330517",
    "label": "Kretek",
    "value": "Kretek"
  },
  {
    "id": "3305172008",
    "district_id": "330517",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3305172009",
    "district_id": "330517",
    "label": "Giyanti",
    "value": "Giyanti"
  },
  {
    "id": "3305172010",
    "district_id": "330517",
    "label": "Wonoharjo",
    "value": "Wonoharjo"
  },
  {
    "id": "3305172011",
    "district_id": "330517",
    "label": "Wagirpandan",
    "value": "Wagirpandan"
  },
  {
    "id": "3305182001",
    "district_id": "330518",
    "label": "Sidoharum",
    "value": "Sidoharum"
  },
  {
    "id": "3305182002",
    "district_id": "330518",
    "label": "Selokerto",
    "value": "Selokerto"
  },
  {
    "id": "3305182003",
    "district_id": "330518",
    "label": "Kalibeji",
    "value": "Kalibeji"
  },
  {
    "id": "3305182004",
    "district_id": "330518",
    "label": "Jatinegara",
    "value": "Jatinegara"
  },
  {
    "id": "3305182005",
    "district_id": "330518",
    "label": "Bejiruyung",
    "value": "Bejiruyung"
  },
  {
    "id": "3305182006",
    "district_id": "330518",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3305182007",
    "district_id": "330518",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "3305182008",
    "district_id": "330518",
    "label": "Semali",
    "value": "Semali"
  },
  {
    "id": "3305182009",
    "district_id": "330518",
    "label": "Bonosari",
    "value": "Bonosari"
  },
  {
    "id": "3305182010",
    "district_id": "330518",
    "label": "Sempor",
    "value": "Sempor"
  },
  {
    "id": "3305182011",
    "district_id": "330518",
    "label": "Tunjungseto",
    "value": "Tunjungseto"
  },
  {
    "id": "3305182012",
    "district_id": "330518",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "3305182013",
    "district_id": "330518",
    "label": "Donorejo",
    "value": "Donorejo"
  },
  {
    "id": "3305182014",
    "district_id": "330518",
    "label": "Kedungwringin",
    "value": "Kedungwringin"
  },
  {
    "id": "3305182015",
    "district_id": "330518",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3305182016",
    "district_id": "330518",
    "label": "Somagede",
    "value": "Somagede"
  },
  {
    "id": "3305191008",
    "district_id": "330519",
    "label": "Gombong",
    "value": "Gombong"
  },
  {
    "id": "3305191009",
    "district_id": "330519",
    "label": "Wonokriyo",
    "value": "Wonokriyo"
  },
  {
    "id": "3305192001",
    "district_id": "330519",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3305192002",
    "district_id": "330519",
    "label": "Kemukus",
    "value": "Kemukus"
  },
  {
    "id": "3305192003",
    "district_id": "330519",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3305192004",
    "district_id": "330519",
    "label": "Panjangsari",
    "value": "Panjangsari"
  },
  {
    "id": "3305192005",
    "district_id": "330519",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3305192006",
    "district_id": "330519",
    "label": "Kedungpuji",
    "value": "Kedungpuji"
  },
  {
    "id": "3305192007",
    "district_id": "330519",
    "label": "Wero",
    "value": "Wero"
  },
  {
    "id": "3305192010",
    "district_id": "330519",
    "label": "Semondo",
    "value": "Semondo"
  },
  {
    "id": "3305192011",
    "district_id": "330519",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "3305192012",
    "district_id": "330519",
    "label": "Sidayu",
    "value": "Sidayu"
  },
  {
    "id": "3305192013",
    "district_id": "330519",
    "label": "Wonosigro",
    "value": "Wonosigro"
  },
  {
    "id": "3305192014",
    "district_id": "330519",
    "label": "Klopogodo",
    "value": "Klopogodo"
  },
  {
    "id": "3305201002",
    "district_id": "330520",
    "label": "Panjatan",
    "value": "Panjatan"
  },
  {
    "id": "3305201003",
    "district_id": "330520",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3305201004",
    "district_id": "330520",
    "label": "Jatiluhur",
    "value": "Jatiluhur"
  },
  {
    "id": "3305201007",
    "district_id": "330520",
    "label": "Plarangan",
    "value": "Plarangan"
  },
  {
    "id": "3305202001",
    "district_id": "330520",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3305202005",
    "district_id": "330520",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3305202006",
    "district_id": "330520",
    "label": "Giripurno",
    "value": "Giripurno"
  },
  {
    "id": "3305202008",
    "district_id": "330520",
    "label": "Karangkemiri",
    "value": "Karangkemiri"
  },
  {
    "id": "3305202009",
    "district_id": "330520",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3305202010",
    "district_id": "330520",
    "label": "Grenggeng",
    "value": "Grenggeng"
  },
  {
    "id": "3305202011",
    "district_id": "330520",
    "label": "Pohkumbang",
    "value": "Pohkumbang"
  },
  {
    "id": "3305212001",
    "district_id": "330521",
    "label": "Karanggayam",
    "value": "Karanggayam"
  },
  {
    "id": "3305212002",
    "district_id": "330521",
    "label": "Kajoran",
    "value": "Kajoran"
  },
  {
    "id": "3305212003",
    "district_id": "330521",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3305212004",
    "district_id": "330521",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3305212005",
    "district_id": "330521",
    "label": "Penimbun",
    "value": "Penimbun"
  },
  {
    "id": "3305212006",
    "district_id": "330521",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3305212007",
    "district_id": "330521",
    "label": "Pagebangan",
    "value": "Pagebangan"
  },
  {
    "id": "3305212008",
    "district_id": "330521",
    "label": "Clapar",
    "value": "Clapar"
  },
  {
    "id": "3305212009",
    "district_id": "330521",
    "label": "Logandu",
    "value": "Logandu"
  },
  {
    "id": "3305212010",
    "district_id": "330521",
    "label": "Kebakalan",
    "value": "Kebakalan"
  },
  {
    "id": "3305212011",
    "district_id": "330521",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3305212012",
    "district_id": "330521",
    "label": "Wonotirto",
    "value": "Wonotirto"
  },
  {
    "id": "3305212013",
    "district_id": "330521",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "3305212014",
    "district_id": "330521",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3305212015",
    "district_id": "330521",
    "label": "Ginandong",
    "value": "Ginandong"
  },
  {
    "id": "3305212016",
    "district_id": "330521",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3305212017",
    "district_id": "330521",
    "label": "Glontor",
    "value": "Glontor"
  },
  {
    "id": "3305212018",
    "district_id": "330521",
    "label": "Selogiri",
    "value": "Selogiri"
  },
  {
    "id": "3305212019",
    "district_id": "330521",
    "label": "Giritirto",
    "value": "Giritirto"
  },
  {
    "id": "3305222001",
    "district_id": "330522",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3305222002",
    "district_id": "330522",
    "label": "Seboro",
    "value": "Seboro"
  },
  {
    "id": "3305222003",
    "district_id": "330522",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3305222004",
    "district_id": "330522",
    "label": "Sadangkulon",
    "value": "Sadangkulon"
  },
  {
    "id": "3305222005",
    "district_id": "330522",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3305222006",
    "district_id": "330522",
    "label": "Sadangwetan",
    "value": "Sadangwetan"
  },
  {
    "id": "3305222007",
    "district_id": "330522",
    "label": "Kedunggong",
    "value": "Kedunggong"
  },
  {
    "id": "3305232001",
    "district_id": "330523",
    "label": "Patukrejo",
    "value": "Patukrejo"
  },
  {
    "id": "3305232002",
    "district_id": "330523",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3305232003",
    "district_id": "330523",
    "label": "Pujodadi",
    "value": "Pujodadi"
  },
  {
    "id": "3305232004",
    "district_id": "330523",
    "label": "Balorejo",
    "value": "Balorejo"
  },
  {
    "id": "3305232005",
    "district_id": "330523",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3305232006",
    "district_id": "330523",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3305232007",
    "district_id": "330523",
    "label": "Bonorowo",
    "value": "Bonorowo"
  },
  {
    "id": "3305232008",
    "district_id": "330523",
    "label": "Sirnoboyo",
    "value": "Sirnoboyo"
  },
  {
    "id": "3305232009",
    "district_id": "330523",
    "label": "Bonjokkidul",
    "value": "Bonjokkidul"
  },
  {
    "id": "3305232010",
    "district_id": "330523",
    "label": "Bonjoklor",
    "value": "Bonjoklor"
  },
  {
    "id": "3305232011",
    "district_id": "330523",
    "label": "Mrentul",
    "value": "Mrentul"
  },
  {
    "id": "3305242001",
    "district_id": "330524",
    "label": "Pejengkolan",
    "value": "Pejengkolan"
  },
  {
    "id": "3305242002",
    "district_id": "330524",
    "label": "Balingasal",
    "value": "Balingasal"
  },
  {
    "id": "3305242003",
    "district_id": "330524",
    "label": "Merden",
    "value": "Merden"
  },
  {
    "id": "3305242004",
    "district_id": "330524",
    "label": "Kalijering",
    "value": "Kalijering"
  },
  {
    "id": "3305242005",
    "district_id": "330524",
    "label": "Kaligubug",
    "value": "Kaligubug"
  },
  {
    "id": "3305242006",
    "district_id": "330524",
    "label": "Sidototo",
    "value": "Sidototo"
  },
  {
    "id": "3305242007",
    "district_id": "330524",
    "label": "Rahayu",
    "value": "Rahayu"
  },
  {
    "id": "3305242008",
    "district_id": "330524",
    "label": "Sendangdalem",
    "value": "Sendangdalem"
  },
  {
    "id": "3305242009",
    "district_id": "330524",
    "label": "Padureso",
    "value": "Padureso"
  },
  {
    "id": "3305252001",
    "district_id": "330525",
    "label": "Jatipurus",
    "value": "Jatipurus"
  },
  {
    "id": "3305252002",
    "district_id": "330525",
    "label": "Lerepkebumen",
    "value": "Lerepkebumen"
  },
  {
    "id": "3305252003",
    "district_id": "330525",
    "label": "Blater",
    "value": "Blater"
  },
  {
    "id": "3305252004",
    "district_id": "330525",
    "label": "Poncowarno",
    "value": "Poncowarno"
  },
  {
    "id": "3305252005",
    "district_id": "330525",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3305252006",
    "district_id": "330525",
    "label": "Jembangan",
    "value": "Jembangan"
  },
  {
    "id": "3305252007",
    "district_id": "330525",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3305252008",
    "district_id": "330525",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3305252009",
    "district_id": "330525",
    "label": "Tirtomoyo",
    "value": "Tirtomoyo"
  },
  {
    "id": "3305252010",
    "district_id": "330525",
    "label": "Soka",
    "value": "Soka"
  },
  {
    "id": "3305252011",
    "district_id": "330525",
    "label": "Kebapangan",
    "value": "Kebapangan"
  },
  {
    "id": "3305262001",
    "district_id": "330526",
    "label": "Widoro",
    "value": "Widoro"
  },
  {
    "id": "3305262002",
    "district_id": "330526",
    "label": "Seling",
    "value": "Seling"
  },
  {
    "id": "3305262003",
    "district_id": "330526",
    "label": "Pencil",
    "value": "Pencil"
  },
  {
    "id": "3305262004",
    "district_id": "330526",
    "label": "Kedungwaru",
    "value": "Kedungwaru"
  },
  {
    "id": "3305262005",
    "district_id": "330526",
    "label": "Kaligending",
    "value": "Kaligending"
  },
  {
    "id": "3305262006",
    "district_id": "330526",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3305262007",
    "district_id": "330526",
    "label": "Pujotirto",
    "value": "Pujotirto"
  },
  {
    "id": "3305262008",
    "district_id": "330526",
    "label": "Wadasmalang",
    "value": "Wadasmalang"
  },
  {
    "id": "3305262009",
    "district_id": "330526",
    "label": "Tlepok",
    "value": "Tlepok"
  },
  {
    "id": "3305262010",
    "district_id": "330526",
    "label": "Kalisana",
    "value": "Kalisana"
  },
  {
    "id": "3305262011",
    "district_id": "330526",
    "label": "Langse",
    "value": "Langse"
  },
  {
    "id": "3305262012",
    "district_id": "330526",
    "label": "Banioro",
    "value": "Banioro"
  },
  {
    "id": "3305262013",
    "district_id": "330526",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "3305262014",
    "district_id": "330526",
    "label": "Totogan",
    "value": "Totogan"
  },
  {
    "id": "3306012001",
    "district_id": "330601",
    "label": "Harjobinangun",
    "value": "Harjobinangun"
  },
  {
    "id": "3306012002",
    "district_id": "330601",
    "label": "Patutrejo",
    "value": "Patutrejo"
  },
  {
    "id": "3306012003",
    "district_id": "330601",
    "label": "Ketawangrejo",
    "value": "Ketawangrejo"
  },
  {
    "id": "3306012004",
    "district_id": "330601",
    "label": "Munggangsari",
    "value": "Munggangsari"
  },
  {
    "id": "3306012005",
    "district_id": "330601",
    "label": "Kertojayan",
    "value": "Kertojayan"
  },
  {
    "id": "3306012006",
    "district_id": "330601",
    "label": "Pasaranom",
    "value": "Pasaranom"
  },
  {
    "id": "3306012007",
    "district_id": "330601",
    "label": "Ukirsari",
    "value": "Ukirsari"
  },
  {
    "id": "3306012008",
    "district_id": "330601",
    "label": "Nambangan",
    "value": "Nambangan"
  },
  {
    "id": "3306012009",
    "district_id": "330601",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3306012010",
    "district_id": "330601",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3306012011",
    "district_id": "330601",
    "label": "Rowodadi",
    "value": "Rowodadi"
  },
  {
    "id": "3306012012",
    "district_id": "330601",
    "label": "Bakurejo",
    "value": "Bakurejo"
  },
  {
    "id": "3306012013",
    "district_id": "330601",
    "label": "Duduwetan",
    "value": "Duduwetan"
  },
  {
    "id": "3306012014",
    "district_id": "330601",
    "label": "Dudukulon",
    "value": "Dudukulon"
  },
  {
    "id": "3306012015",
    "district_id": "330601",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3306012016",
    "district_id": "330601",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3306012017",
    "district_id": "330601",
    "label": "Tulusrejo",
    "value": "Tulusrejo"
  },
  {
    "id": "3306012018",
    "district_id": "330601",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3306012019",
    "district_id": "330601",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3306012020",
    "district_id": "330601",
    "label": "Wonoenggal",
    "value": "Wonoenggal"
  },
  {
    "id": "3306012021",
    "district_id": "330601",
    "label": "Banyuyoso",
    "value": "Banyuyoso"
  },
  {
    "id": "3306012022",
    "district_id": "330601",
    "label": "Grabag",
    "value": "Grabag"
  },
  {
    "id": "3306012023",
    "district_id": "330601",
    "label": "Aglik",
    "value": "Aglik"
  },
  {
    "id": "3306012024",
    "district_id": "330601",
    "label": "Roworejo",
    "value": "Roworejo"
  },
  {
    "id": "3306012025",
    "district_id": "330601",
    "label": "Tunggulrejo",
    "value": "Tunggulrejo"
  },
  {
    "id": "3306012026",
    "district_id": "330601",
    "label": "Kedungkamal",
    "value": "Kedungkamal"
  },
  {
    "id": "3306012027",
    "district_id": "330601",
    "label": "Sangubanyu",
    "value": "Sangubanyu"
  },
  {
    "id": "3306012028",
    "district_id": "330601",
    "label": "Dukuhdungus",
    "value": "Dukuhdungus"
  },
  {
    "id": "3306012029",
    "district_id": "330601",
    "label": "Tlepokwetan",
    "value": "Tlepokwetan"
  },
  {
    "id": "3306012030",
    "district_id": "330601",
    "label": "Tlepokkulon",
    "value": "Tlepokkulon"
  },
  {
    "id": "3306012031",
    "district_id": "330601",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3306012032",
    "district_id": "330601",
    "label": "Kese",
    "value": "Kese"
  },
  {
    "id": "3306022001",
    "district_id": "330602",
    "label": "Ngentak",
    "value": "Ngentak"
  },
  {
    "id": "3306022002",
    "district_id": "330602",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3306022003",
    "district_id": "330602",
    "label": "Wero",
    "value": "Wero"
  },
  {
    "id": "3306022004",
    "district_id": "330602",
    "label": "Pagak",
    "value": "Pagak"
  },
  {
    "id": "3306022005",
    "district_id": "330602",
    "label": "Malang",
    "value": "Malang"
  },
  {
    "id": "3306022006",
    "district_id": "330602",
    "label": "Depokrejo",
    "value": "Depokrejo"
  },
  {
    "id": "3306022007",
    "district_id": "330602",
    "label": "Keburuhan",
    "value": "Keburuhan"
  },
  {
    "id": "3306022008",
    "district_id": "330602",
    "label": "Awu-awu",
    "value": "Awu-awu"
  },
  {
    "id": "3306022009",
    "district_id": "330602",
    "label": "Kumpulsari",
    "value": "Kumpulsari"
  },
  {
    "id": "3306022010",
    "district_id": "330602",
    "label": "Kesidan",
    "value": "Kesidan"
  },
  {
    "id": "3306022011",
    "district_id": "330602",
    "label": "Wonoroto",
    "value": "Wonoroto"
  },
  {
    "id": "3306022012",
    "district_id": "330602",
    "label": "Pejagran",
    "value": "Pejagran"
  },
  {
    "id": "3306022013",
    "district_id": "330602",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3306022014",
    "district_id": "330602",
    "label": "Wonosri",
    "value": "Wonosri"
  },
  {
    "id": "3306022015",
    "district_id": "330602",
    "label": "Jeruken",
    "value": "Jeruken"
  },
  {
    "id": "3306022016",
    "district_id": "330602",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3306022017",
    "district_id": "330602",
    "label": "Kalitanjung",
    "value": "Kalitanjung"
  },
  {
    "id": "3306022018",
    "district_id": "330602",
    "label": "Kuwukan",
    "value": "Kuwukan"
  },
  {
    "id": "3306022019",
    "district_id": "330602",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "3306022020",
    "district_id": "330602",
    "label": "Rasukan",
    "value": "Rasukan"
  },
  {
    "id": "3306022021",
    "district_id": "330602",
    "label": "Laban",
    "value": "Laban"
  },
  {
    "id": "3306022022",
    "district_id": "330602",
    "label": "Wasiat",
    "value": "Wasiat"
  },
  {
    "id": "3306022023",
    "district_id": "330602",
    "label": "Tunjungan",
    "value": "Tunjungan"
  },
  {
    "id": "3306022024",
    "district_id": "330602",
    "label": "Ringgit",
    "value": "Ringgit"
  },
  {
    "id": "3306022025",
    "district_id": "330602",
    "label": "Kaliwungukidul",
    "value": "Kaliwungukidul"
  },
  {
    "id": "3306022026",
    "district_id": "330602",
    "label": "Kaliwungulor",
    "value": "Kaliwungulor"
  },
  {
    "id": "3306022027",
    "district_id": "330602",
    "label": "Cokroyasan",
    "value": "Cokroyasan"
  },
  {
    "id": "3306022028",
    "district_id": "330602",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3306022029",
    "district_id": "330602",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3306022030",
    "district_id": "330602",
    "label": "Susuk",
    "value": "Susuk"
  },
  {
    "id": "3306022031",
    "district_id": "330602",
    "label": "Klandaran",
    "value": "Klandaran"
  },
  {
    "id": "3306022032",
    "district_id": "330602",
    "label": "Briyan",
    "value": "Briyan"
  },
  {
    "id": "3306022033",
    "district_id": "330602",
    "label": "Ngombol",
    "value": "Ngombol"
  },
  {
    "id": "3306022034",
    "district_id": "330602",
    "label": "Joso",
    "value": "Joso"
  },
  {
    "id": "3306022035",
    "district_id": "330602",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3306022036",
    "district_id": "330602",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "3306022037",
    "district_id": "330602",
    "label": "Walikoro",
    "value": "Walikoro"
  },
  {
    "id": "3306022038",
    "district_id": "330602",
    "label": "Sruwoh",
    "value": "Sruwoh"
  },
  {
    "id": "3306022039",
    "district_id": "330602",
    "label": "Wonoboyo",
    "value": "Wonoboyo"
  },
  {
    "id": "3306022040",
    "district_id": "330602",
    "label": "Kembangkuning",
    "value": "Kembangkuning"
  },
  {
    "id": "3306022041",
    "district_id": "330602",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "3306022042",
    "district_id": "330602",
    "label": "Mendiro",
    "value": "Mendiro"
  },
  {
    "id": "3306022043",
    "district_id": "330602",
    "label": "Wunut",
    "value": "Wunut"
  },
  {
    "id": "3306022044",
    "district_id": "330602",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3306022045",
    "district_id": "330602",
    "label": "Wingkosigromulyo",
    "value": "Wingkosigromulyo"
  },
  {
    "id": "3306022046",
    "district_id": "330602",
    "label": "Wingkoharjo",
    "value": "Wingkoharjo"
  },
  {
    "id": "3306022047",
    "district_id": "330602",
    "label": "Singkilkulon",
    "value": "Singkilkulon"
  },
  {
    "id": "3306022048",
    "district_id": "330602",
    "label": "Singkilwetan",
    "value": "Singkilwetan"
  },
  {
    "id": "3306022049",
    "district_id": "330602",
    "label": "Tumenggungan",
    "value": "Tumenggungan"
  },
  {
    "id": "3306022050",
    "district_id": "330602",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3306022051",
    "district_id": "330602",
    "label": "Wingkosanggrahan",
    "value": "Wingkosanggrahan"
  },
  {
    "id": "3306022052",
    "district_id": "330602",
    "label": "Wingkomulyo",
    "value": "Wingkomulyo"
  },
  {
    "id": "3306022053",
    "district_id": "330602",
    "label": "Wingkotinumpuk",
    "value": "Wingkotinumpuk"
  },
  {
    "id": "3306022054",
    "district_id": "330602",
    "label": "Piyono",
    "value": "Piyono"
  },
  {
    "id": "3306022055",
    "district_id": "330602",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3306022056",
    "district_id": "330602",
    "label": "Secang",
    "value": "Secang"
  },
  {
    "id": "3306022057",
    "district_id": "330602",
    "label": "Seboropasar",
    "value": "Seboropasar"
  },
  {
    "id": "3306032001",
    "district_id": "330603",
    "label": "Jatimalang",
    "value": "Jatimalang"
  },
  {
    "id": "3306032002",
    "district_id": "330603",
    "label": "Jatikontal",
    "value": "Jatikontal"
  },
  {
    "id": "3306032003",
    "district_id": "330603",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3306032004",
    "district_id": "330603",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3306032005",
    "district_id": "330603",
    "label": "Jogoboyo",
    "value": "Jogoboyo"
  },
  {
    "id": "3306032006",
    "district_id": "330603",
    "label": "Watukuro",
    "value": "Watukuro"
  },
  {
    "id": "3306032007",
    "district_id": "330603",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3306032008",
    "district_id": "330603",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3306032009",
    "district_id": "330603",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3306032010",
    "district_id": "330603",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3306032011",
    "district_id": "330603",
    "label": "Jogoresan",
    "value": "Jogoresan"
  },
  {
    "id": "3306032012",
    "district_id": "330603",
    "label": "Geparang",
    "value": "Geparang"
  },
  {
    "id": "3306032013",
    "district_id": "330603",
    "label": "Nampurejo",
    "value": "Nampurejo"
  },
  {
    "id": "3306032014",
    "district_id": "330603",
    "label": "Kentengrejo",
    "value": "Kentengrejo"
  },
  {
    "id": "3306032015",
    "district_id": "330603",
    "label": "Nampu",
    "value": "Nampu"
  },
  {
    "id": "3306032016",
    "district_id": "330603",
    "label": "Gesing",
    "value": "Gesing"
  },
  {
    "id": "3306032017",
    "district_id": "330603",
    "label": "Keponggok",
    "value": "Keponggok"
  },
  {
    "id": "3306032018",
    "district_id": "330603",
    "label": "Bubutan",
    "value": "Bubutan"
  },
  {
    "id": "3306032019",
    "district_id": "330603",
    "label": "Tegalaren",
    "value": "Tegalaren"
  },
  {
    "id": "3306032020",
    "district_id": "330603",
    "label": "Sukomanah",
    "value": "Sukomanah"
  },
  {
    "id": "3306032021",
    "district_id": "330603",
    "label": "Blendung",
    "value": "Blendung"
  },
  {
    "id": "3306032022",
    "district_id": "330603",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3306032023",
    "district_id": "330603",
    "label": "Bongkot",
    "value": "Bongkot"
  },
  {
    "id": "3306032024",
    "district_id": "330603",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3306032025",
    "district_id": "330603",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3306032026",
    "district_id": "330603",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3306032027",
    "district_id": "330603",
    "label": "Jenarwetan",
    "value": "Jenarwetan"
  },
  {
    "id": "3306032028",
    "district_id": "330603",
    "label": "Jenarkidul",
    "value": "Jenarkidul"
  },
  {
    "id": "3306032029",
    "district_id": "330603",
    "label": "Jenarlor",
    "value": "Jenarlor"
  },
  {
    "id": "3306032030",
    "district_id": "330603",
    "label": "Pundensari",
    "value": "Pundensari"
  },
  {
    "id": "3306032031",
    "district_id": "330603",
    "label": "Kesugihan",
    "value": "Kesugihan"
  },
  {
    "id": "3306032032",
    "district_id": "330603",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3306032033",
    "district_id": "330603",
    "label": "Plandi",
    "value": "Plandi"
  },
  {
    "id": "3306032034",
    "district_id": "330603",
    "label": "Bragolan",
    "value": "Bragolan"
  },
  {
    "id": "3306032035",
    "district_id": "330603",
    "label": "Ketangi",
    "value": "Ketangi"
  },
  {
    "id": "3306032036",
    "district_id": "330603",
    "label": "Karangmulyo",
    "value": "Karangmulyo"
  },
  {
    "id": "3306032037",
    "district_id": "330603",
    "label": "Keduren",
    "value": "Keduren"
  },
  {
    "id": "3306032038",
    "district_id": "330603",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3306032039",
    "district_id": "330603",
    "label": "Brondongrejo",
    "value": "Brondongrejo"
  },
  {
    "id": "3306032040",
    "district_id": "330603",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3306042001",
    "district_id": "330604",
    "label": "Dadirejo",
    "value": "Dadirejo"
  },
  {
    "id": "3306042002",
    "district_id": "330604",
    "label": "Tlogokotes",
    "value": "Tlogokotes"
  },
  {
    "id": "3306042003",
    "district_id": "330604",
    "label": "Bapangsari",
    "value": "Bapangsari"
  },
  {
    "id": "3306042004",
    "district_id": "330604",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3306042005",
    "district_id": "330604",
    "label": "Bagelen",
    "value": "Bagelen"
  },
  {
    "id": "3306042006",
    "district_id": "330604",
    "label": "Krendetan",
    "value": "Krendetan"
  },
  {
    "id": "3306042007",
    "district_id": "330604",
    "label": "Somorejo",
    "value": "Somorejo"
  },
  {
    "id": "3306042008",
    "district_id": "330604",
    "label": "Hargorojo",
    "value": "Hargorojo"
  },
  {
    "id": "3306042009",
    "district_id": "330604",
    "label": "Durensari",
    "value": "Durensari"
  },
  {
    "id": "3306042010",
    "district_id": "330604",
    "label": "Semono",
    "value": "Semono"
  },
  {
    "id": "3306042011",
    "district_id": "330604",
    "label": "Sokoagung",
    "value": "Sokoagung"
  },
  {
    "id": "3306042012",
    "district_id": "330604",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3306042013",
    "district_id": "330604",
    "label": "Clapar",
    "value": "Clapar"
  },
  {
    "id": "3306042014",
    "district_id": "330604",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3306042015",
    "district_id": "330604",
    "label": "Semagung",
    "value": "Semagung"
  },
  {
    "id": "3306042016",
    "district_id": "330604",
    "label": "Piji",
    "value": "Piji"
  },
  {
    "id": "3306042017",
    "district_id": "330604",
    "label": "Kemanukan",
    "value": "Kemanukan"
  },
  {
    "id": "3306052001",
    "district_id": "330605",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3306052002",
    "district_id": "330605",
    "label": "Somongari",
    "value": "Somongari"
  },
  {
    "id": "3306052003",
    "district_id": "330605",
    "label": "Donorejo",
    "value": "Donorejo"
  },
  {
    "id": "3306052004",
    "district_id": "330605",
    "label": "Hulosobo",
    "value": "Hulosobo"
  },
  {
    "id": "3306052005",
    "district_id": "330605",
    "label": "Kaliharjo",
    "value": "Kaliharjo"
  },
  {
    "id": "3306052006",
    "district_id": "330605",
    "label": "Kaligono",
    "value": "Kaligono"
  },
  {
    "id": "3306052007",
    "district_id": "330605",
    "label": "Tlogoguwo",
    "value": "Tlogoguwo"
  },
  {
    "id": "3306052008",
    "district_id": "330605",
    "label": "Pandanrejo",
    "value": "Pandanrejo"
  },
  {
    "id": "3306052009",
    "district_id": "330605",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3306052010",
    "district_id": "330605",
    "label": "Tlogobulu",
    "value": "Tlogobulu"
  },
  {
    "id": "3306052011",
    "district_id": "330605",
    "label": "Purbowono",
    "value": "Purbowono"
  },
  {
    "id": "3306052012",
    "district_id": "330605",
    "label": "Ngaran",
    "value": "Ngaran"
  },
  {
    "id": "3306052013",
    "district_id": "330605",
    "label": "Kedunggubah",
    "value": "Kedunggubah"
  },
  {
    "id": "3306052014",
    "district_id": "330605",
    "label": "Jelok",
    "value": "Jelok"
  },
  {
    "id": "3306052015",
    "district_id": "330605",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3306052016",
    "district_id": "330605",
    "label": "Gunungwangi",
    "value": "Gunungwangi"
  },
  {
    "id": "3306052017",
    "district_id": "330605",
    "label": "Hardimulyo",
    "value": "Hardimulyo"
  },
  {
    "id": "3306052018",
    "district_id": "330605",
    "label": "Sudorogo",
    "value": "Sudorogo"
  },
  {
    "id": "3306052019",
    "district_id": "330605",
    "label": "Sumowono",
    "value": "Sumowono"
  },
  {
    "id": "3306052020",
    "district_id": "330605",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3306052021",
    "district_id": "330605",
    "label": "Pucungroto",
    "value": "Pucungroto"
  },
  {
    "id": "3306061007",
    "district_id": "330606",
    "label": "Cangkrepkidul",
    "value": "Cangkrepkidul"
  },
  {
    "id": "3306061008",
    "district_id": "330606",
    "label": "Cangkreplor",
    "value": "Cangkreplor"
  },
  {
    "id": "3306061010",
    "district_id": "330606",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3306061011",
    "district_id": "330606",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3306061012",
    "district_id": "330606",
    "label": "Pangenrejo",
    "value": "Pangenrejo"
  },
  {
    "id": "3306061013",
    "district_id": "330606",
    "label": "Pangenjurutengah",
    "value": "Pangenjurutengah"
  },
  {
    "id": "3306061014",
    "district_id": "330606",
    "label": "Doplang",
    "value": "Doplang"
  },
  {
    "id": "3306061015",
    "district_id": "330606",
    "label": "Sindurjan",
    "value": "Sindurjan"
  },
  {
    "id": "3306061016",
    "district_id": "330606",
    "label": "Paduroso",
    "value": "Paduroso"
  },
  {
    "id": "3306061017",
    "district_id": "330606",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3306061018",
    "district_id": "330606",
    "label": "Mranti",
    "value": "Mranti"
  },
  {
    "id": "3306061019",
    "district_id": "330606",
    "label": "Mudal",
    "value": "Mudal"
  },
  {
    "id": "3306061020",
    "district_id": "330606",
    "label": "Keseneng",
    "value": "Keseneng"
  },
  {
    "id": "3306061021",
    "district_id": "330606",
    "label": "Baledono",
    "value": "Baledono"
  },
  {
    "id": "3306062001",
    "district_id": "330606",
    "label": "Pacekelan",
    "value": "Pacekelan"
  },
  {
    "id": "3306062002",
    "district_id": "330606",
    "label": "Plipir",
    "value": "Plipir"
  },
  {
    "id": "3306062003",
    "district_id": "330606",
    "label": "Brenggong",
    "value": "Brenggong"
  },
  {
    "id": "3306062004",
    "district_id": "330606",
    "label": "Ganggeng",
    "value": "Ganggeng"
  },
  {
    "id": "3306062005",
    "district_id": "330606",
    "label": "Semawung",
    "value": "Semawung"
  },
  {
    "id": "3306062006",
    "district_id": "330606",
    "label": "Wonoroto",
    "value": "Wonoroto"
  },
  {
    "id": "3306062009",
    "district_id": "330606",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3306062022",
    "district_id": "330606",
    "label": "Wonotulus",
    "value": "Wonotulus"
  },
  {
    "id": "3306062023",
    "district_id": "330606",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3306062024",
    "district_id": "330606",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3306062025",
    "district_id": "330606",
    "label": "Donorati",
    "value": "Donorati"
  },
  {
    "id": "3306071010",
    "district_id": "330607",
    "label": "Borokulon",
    "value": "Borokulon"
  },
  {
    "id": "3306071026",
    "district_id": "330607",
    "label": "Kledungkradenan",
    "value": "Kledungkradenan"
  },
  {
    "id": "3306071027",
    "district_id": "330607",
    "label": "Kledung Karangdalem",
    "value": "Kledung Karangdalem"
  },
  {
    "id": "3306072001",
    "district_id": "330607",
    "label": "Seborokrapyak",
    "value": "Seborokrapyak"
  },
  {
    "id": "3306072002",
    "district_id": "330607",
    "label": "Triwarno",
    "value": "Triwarno"
  },
  {
    "id": "3306072003",
    "district_id": "330607",
    "label": "Bajangrejo",
    "value": "Bajangrejo"
  },
  {
    "id": "3306072004",
    "district_id": "330607",
    "label": "Bencorejo",
    "value": "Bencorejo"
  },
  {
    "id": "3306072005",
    "district_id": "330607",
    "label": "Surorejo",
    "value": "Surorejo"
  },
  {
    "id": "3306072006",
    "district_id": "330607",
    "label": "Wangunrejo",
    "value": "Wangunrejo"
  },
  {
    "id": "3306072007",
    "district_id": "330607",
    "label": "Cengkawakrejo",
    "value": "Cengkawakrejo"
  },
  {
    "id": "3306072008",
    "district_id": "330607",
    "label": "Popongan",
    "value": "Popongan"
  },
  {
    "id": "3306072009",
    "district_id": "330607",
    "label": "Borowetan",
    "value": "Borowetan"
  },
  {
    "id": "3306072011",
    "district_id": "330607",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3306072012",
    "district_id": "330607",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3306072013",
    "district_id": "330607",
    "label": "Malangrejo",
    "value": "Malangrejo"
  },
  {
    "id": "3306072014",
    "district_id": "330607",
    "label": "Tegalkuning",
    "value": "Tegalkuning"
  },
  {
    "id": "3306072015",
    "district_id": "330607",
    "label": "Kliwonan",
    "value": "Kliwonan"
  },
  {
    "id": "3306072016",
    "district_id": "330607",
    "label": "Kertosono",
    "value": "Kertosono"
  },
  {
    "id": "3306072017",
    "district_id": "330607",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3306072018",
    "district_id": "330607",
    "label": "Sawit",
    "value": "Sawit"
  },
  {
    "id": "3306072019",
    "district_id": "330607",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3306072020",
    "district_id": "330607",
    "label": "Pakisrejo",
    "value": "Pakisrejo"
  },
  {
    "id": "3306072021",
    "district_id": "330607",
    "label": "Candingasinan",
    "value": "Candingasinan"
  },
  {
    "id": "3306072022",
    "district_id": "330607",
    "label": "Sokowaten",
    "value": "Sokowaten"
  },
  {
    "id": "3306072023",
    "district_id": "330607",
    "label": "Golok",
    "value": "Golok"
  },
  {
    "id": "3306072024",
    "district_id": "330607",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3306072025",
    "district_id": "330607",
    "label": "Condongsari",
    "value": "Condongsari"
  },
  {
    "id": "3306081020",
    "district_id": "330608",
    "label": "Sucenjurutengah",
    "value": "Sucenjurutengah"
  },
  {
    "id": "3306082001",
    "district_id": "330608",
    "label": "Jono",
    "value": "Jono"
  },
  {
    "id": "3306082002",
    "district_id": "330608",
    "label": "Pogungjurutengah",
    "value": "Pogungjurutengah"
  },
  {
    "id": "3306082003",
    "district_id": "330608",
    "label": "Pogungrejo",
    "value": "Pogungrejo"
  },
  {
    "id": "3306082004",
    "district_id": "330608",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3306082005",
    "district_id": "330608",
    "label": "Pogungkalangan",
    "value": "Pogungkalangan"
  },
  {
    "id": "3306082006",
    "district_id": "330608",
    "label": "Ketiwijayan",
    "value": "Ketiwijayan"
  },
  {
    "id": "3306082007",
    "district_id": "330608",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3306082008",
    "district_id": "330608",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3306082009",
    "district_id": "330608",
    "label": "Dewi",
    "value": "Dewi"
  },
  {
    "id": "3306082010",
    "district_id": "330608",
    "label": "Tangkisan",
    "value": "Tangkisan"
  },
  {
    "id": "3306082011",
    "district_id": "330608",
    "label": "Jatingarang",
    "value": "Jatingarang"
  },
  {
    "id": "3306082012",
    "district_id": "330608",
    "label": "Bandungkidul",
    "value": "Bandungkidul"
  },
  {
    "id": "3306082013",
    "district_id": "330608",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3306082014",
    "district_id": "330608",
    "label": "Besole",
    "value": "Besole"
  },
  {
    "id": "3306082015",
    "district_id": "330608",
    "label": "Botorejo",
    "value": "Botorejo"
  },
  {
    "id": "3306082016",
    "district_id": "330608",
    "label": "Botodaleman",
    "value": "Botodaleman"
  },
  {
    "id": "3306082017",
    "district_id": "330608",
    "label": "Dukuhrejo",
    "value": "Dukuhrejo"
  },
  {
    "id": "3306082018",
    "district_id": "330608",
    "label": "Grantung",
    "value": "Grantung"
  },
  {
    "id": "3306082019",
    "district_id": "330608",
    "label": "Kalimiru",
    "value": "Kalimiru"
  },
  {
    "id": "3306082021",
    "district_id": "330608",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3306082022",
    "district_id": "330608",
    "label": "Bayan",
    "value": "Bayan"
  },
  {
    "id": "3306082023",
    "district_id": "330608",
    "label": "Pekutan",
    "value": "Pekutan"
  },
  {
    "id": "3306082024",
    "district_id": "330608",
    "label": "Jrakah",
    "value": "Jrakah"
  },
  {
    "id": "3306082025",
    "district_id": "330608",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3306082026",
    "district_id": "330608",
    "label": "Pucangagung",
    "value": "Pucangagung"
  },
  {
    "id": "3306091009",
    "district_id": "330609",
    "label": "Bayem",
    "value": "Bayem"
  },
  {
    "id": "3306091010",
    "district_id": "330609",
    "label": "Semawung Kembaran",
    "value": "Semawung Kembaran"
  },
  {
    "id": "3306091014",
    "district_id": "330609",
    "label": "Semawung Daleman",
    "value": "Semawung Daleman"
  },
  {
    "id": "3306091015",
    "district_id": "330609",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3306091016",
    "district_id": "330609",
    "label": "Kutoarjo",
    "value": "Kutoarjo"
  },
  {
    "id": "3306091017",
    "district_id": "330609",
    "label": "Katerban",
    "value": "Katerban"
  },
  {
    "id": "3306092001",
    "district_id": "330609",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3306092002",
    "district_id": "330609",
    "label": "Tuntungpait",
    "value": "Tuntungpait"
  },
  {
    "id": "3306092003",
    "district_id": "330609",
    "label": "Kiyangkongrejo",
    "value": "Kiyangkongrejo"
  },
  {
    "id": "3306092004",
    "district_id": "330609",
    "label": "Suren",
    "value": "Suren"
  },
  {
    "id": "3306092005",
    "district_id": "330609",
    "label": "Karangwuluh",
    "value": "Karangwuluh"
  },
  {
    "id": "3306092006",
    "district_id": "330609",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3306092007",
    "district_id": "330609",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3306092008",
    "district_id": "330609",
    "label": "Majir",
    "value": "Majir"
  },
  {
    "id": "3306092011",
    "district_id": "330609",
    "label": "Kuwurejo",
    "value": "Kuwurejo"
  },
  {
    "id": "3306092012",
    "district_id": "330609",
    "label": "Pringgowijayan",
    "value": "Pringgowijayan"
  },
  {
    "id": "3306092013",
    "district_id": "330609",
    "label": "Sidarum",
    "value": "Sidarum"
  },
  {
    "id": "3306092018",
    "district_id": "330609",
    "label": "Pacor",
    "value": "Pacor"
  },
  {
    "id": "3306092019",
    "district_id": "330609",
    "label": "Tunggorono",
    "value": "Tunggorono"
  },
  {
    "id": "3306092020",
    "district_id": "330609",
    "label": "Tepus Kulon",
    "value": "Tepus Kulon"
  },
  {
    "id": "3306092021",
    "district_id": "330609",
    "label": "Sokoharjo",
    "value": "Sokoharjo"
  },
  {
    "id": "3306092022",
    "district_id": "330609",
    "label": "Kemadu Lor",
    "value": "Kemadu Lor"
  },
  {
    "id": "3306092023",
    "district_id": "330609",
    "label": "Kaligesing",
    "value": "Kaligesing"
  },
  {
    "id": "3306092024",
    "district_id": "330609",
    "label": "Tepus Wetan",
    "value": "Tepus Wetan"
  },
  {
    "id": "3306092025",
    "district_id": "330609",
    "label": "Wirun",
    "value": "Wirun"
  },
  {
    "id": "3306092026",
    "district_id": "330609",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3306092027",
    "district_id": "330609",
    "label": "Tursino",
    "value": "Tursino"
  },
  {
    "id": "3306102001",
    "district_id": "330610",
    "label": "Kedungmulyo",
    "value": "Kedungmulyo"
  },
  {
    "id": "3306102002",
    "district_id": "330610",
    "label": "Mangunjayan",
    "value": "Mangunjayan"
  },
  {
    "id": "3306102003",
    "district_id": "330610",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3306102004",
    "district_id": "330610",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3306102005",
    "district_id": "330610",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3306102006",
    "district_id": "330610",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3306102007",
    "district_id": "330610",
    "label": "Langenrejo",
    "value": "Langenrejo"
  },
  {
    "id": "3306102008",
    "district_id": "330610",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3306102009",
    "district_id": "330610",
    "label": "Wareng",
    "value": "Wareng"
  },
  {
    "id": "3306102010",
    "district_id": "330610",
    "label": "Ketug",
    "value": "Ketug"
  },
  {
    "id": "3306102011",
    "district_id": "330610",
    "label": "Rowodadi",
    "value": "Rowodadi"
  },
  {
    "id": "3306102012",
    "district_id": "330610",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3306102013",
    "district_id": "330610",
    "label": "Sruwohdukuh",
    "value": "Sruwohdukuh"
  },
  {
    "id": "3306102014",
    "district_id": "330610",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3306102015",
    "district_id": "330610",
    "label": "Wonorejowetan",
    "value": "Wonorejowetan"
  },
  {
    "id": "3306102016",
    "district_id": "330610",
    "label": "Wonorejokulon",
    "value": "Wonorejokulon"
  },
  {
    "id": "3306102017",
    "district_id": "330610",
    "label": "Kunirejokulon",
    "value": "Kunirejokulon"
  },
  {
    "id": "3306102018",
    "district_id": "330610",
    "label": "Kunirejowetan",
    "value": "Kunirejowetan"
  },
  {
    "id": "3306102019",
    "district_id": "330610",
    "label": "Kedungsri",
    "value": "Kedungsri"
  },
  {
    "id": "3306102020",
    "district_id": "330610",
    "label": "Kedungagung",
    "value": "Kedungagung"
  },
  {
    "id": "3306102021",
    "district_id": "330610",
    "label": "Sruwohrejo",
    "value": "Sruwohrejo"
  },
  {
    "id": "3306102022",
    "district_id": "330610",
    "label": "Lugurejo",
    "value": "Lugurejo"
  },
  {
    "id": "3306102023",
    "district_id": "330610",
    "label": "Lugu",
    "value": "Lugu"
  },
  {
    "id": "3306102024",
    "district_id": "330610",
    "label": "Kunir",
    "value": "Kunir"
  },
  {
    "id": "3306102025",
    "district_id": "330610",
    "label": "Lubangdukuh",
    "value": "Lubangdukuh"
  },
  {
    "id": "3306102026",
    "district_id": "330610",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3306102027",
    "district_id": "330610",
    "label": "Polomarto",
    "value": "Polomarto"
  },
  {
    "id": "3306102028",
    "district_id": "330610",
    "label": "Tegalgondo",
    "value": "Tegalgondo"
  },
  {
    "id": "3306102029",
    "district_id": "330610",
    "label": "Lubanglor",
    "value": "Lubanglor"
  },
  {
    "id": "3306102030",
    "district_id": "330610",
    "label": "Lubangindangan",
    "value": "Lubangindangan"
  },
  {
    "id": "3306102031",
    "district_id": "330610",
    "label": "Lubangkidul",
    "value": "Lubangkidul"
  },
  {
    "id": "3306102032",
    "district_id": "330610",
    "label": "Lubangsampang",
    "value": "Lubangsampang"
  },
  {
    "id": "3306102033",
    "district_id": "330610",
    "label": "Dlangu",
    "value": "Dlangu"
  },
  {
    "id": "3306102034",
    "district_id": "330610",
    "label": "Andong",
    "value": "Andong"
  },
  {
    "id": "3306102035",
    "district_id": "330610",
    "label": "Kaliwatubumi",
    "value": "Kaliwatubumi"
  },
  {
    "id": "3306102036",
    "district_id": "330610",
    "label": "Kaliwatukranggan",
    "value": "Kaliwatukranggan"
  },
  {
    "id": "3306102037",
    "district_id": "330610",
    "label": "Panggeldlangu",
    "value": "Panggeldlangu"
  },
  {
    "id": "3306102038",
    "district_id": "330610",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3306102039",
    "district_id": "330610",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3306102040",
    "district_id": "330610",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3306102041",
    "district_id": "330610",
    "label": "Wironatan",
    "value": "Wironatan"
  },
  {
    "id": "3306112001",
    "district_id": "330611",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3306112002",
    "district_id": "330611",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3306112003",
    "district_id": "330611",
    "label": "Pepe",
    "value": "Pepe"
  },
  {
    "id": "3306112004",
    "district_id": "330611",
    "label": "Tunjungtejo",
    "value": "Tunjungtejo"
  },
  {
    "id": "3306112005",
    "district_id": "330611",
    "label": "Sekartejo",
    "value": "Sekartejo"
  },
  {
    "id": "3306112006",
    "district_id": "330611",
    "label": "Tersidilor",
    "value": "Tersidilor"
  },
  {
    "id": "3306112007",
    "district_id": "330611",
    "label": "Tersidikidul",
    "value": "Tersidikidul"
  },
  {
    "id": "3306112008",
    "district_id": "330611",
    "label": "Tasikmadu",
    "value": "Tasikmadu"
  },
  {
    "id": "3306112009",
    "district_id": "330611",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3306112010",
    "district_id": "330611",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3306112011",
    "district_id": "330611",
    "label": "Gumawangrejo",
    "value": "Gumawangrejo"
  },
  {
    "id": "3306112012",
    "district_id": "330611",
    "label": "Keburusan",
    "value": "Keburusan"
  },
  {
    "id": "3306112013",
    "district_id": "330611",
    "label": "Wonoyoso",
    "value": "Wonoyoso"
  },
  {
    "id": "3306112014",
    "district_id": "330611",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "3306112015",
    "district_id": "330611",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3306112016",
    "district_id": "330611",
    "label": "Blekatuk",
    "value": "Blekatuk"
  },
  {
    "id": "3306112017",
    "district_id": "330611",
    "label": "Dlisen Wetan",
    "value": "Dlisen Wetan"
  },
  {
    "id": "3306112018",
    "district_id": "330611",
    "label": "Dlisenkulon",
    "value": "Dlisenkulon"
  },
  {
    "id": "3306112019",
    "district_id": "330611",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3306112020",
    "district_id": "330611",
    "label": "Petuguran",
    "value": "Petuguran"
  },
  {
    "id": "3306112021",
    "district_id": "330611",
    "label": "Pekacangan",
    "value": "Pekacangan"
  },
  {
    "id": "3306112022",
    "district_id": "330611",
    "label": "Karanggetas",
    "value": "Karanggetas"
  },
  {
    "id": "3306112023",
    "district_id": "330611",
    "label": "Luweng Kidul",
    "value": "Luweng Kidul"
  },
  {
    "id": "3306112024",
    "district_id": "330611",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3306112025",
    "district_id": "330611",
    "label": "Megulungkidul",
    "value": "Megulungkidul"
  },
  {
    "id": "3306112026",
    "district_id": "330611",
    "label": "Pituruh",
    "value": "Pituruh"
  },
  {
    "id": "3306112027",
    "district_id": "330611",
    "label": "Sikambang",
    "value": "Sikambang"
  },
  {
    "id": "3306112028",
    "district_id": "330611",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3306112029",
    "district_id": "330611",
    "label": "Prigelan",
    "value": "Prigelan"
  },
  {
    "id": "3306112030",
    "district_id": "330611",
    "label": "Ngandagan",
    "value": "Ngandagan"
  },
  {
    "id": "3306112031",
    "district_id": "330611",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3306112032",
    "district_id": "330611",
    "label": "Megulunglor",
    "value": "Megulunglor"
  },
  {
    "id": "3306112033",
    "district_id": "330611",
    "label": "Prapagkidul",
    "value": "Prapagkidul"
  },
  {
    "id": "3306112034",
    "district_id": "330611",
    "label": "Kembangkuning",
    "value": "Kembangkuning"
  },
  {
    "id": "3306112035",
    "district_id": "330611",
    "label": "Luwenglor",
    "value": "Luwenglor"
  },
  {
    "id": "3306112036",
    "district_id": "330611",
    "label": "Brengkol",
    "value": "Brengkol"
  },
  {
    "id": "3306112037",
    "district_id": "330611",
    "label": "Girigondo",
    "value": "Girigondo"
  },
  {
    "id": "3306112038",
    "district_id": "330611",
    "label": "Prapaglor",
    "value": "Prapaglor"
  },
  {
    "id": "3306112039",
    "district_id": "330611",
    "label": "Kalikotes",
    "value": "Kalikotes"
  },
  {
    "id": "3306112040",
    "district_id": "330611",
    "label": "Kesawen",
    "value": "Kesawen"
  },
  {
    "id": "3306112041",
    "district_id": "330611",
    "label": "Kedungbatur",
    "value": "Kedungbatur"
  },
  {
    "id": "3306112042",
    "district_id": "330611",
    "label": "Polowangi",
    "value": "Polowangi"
  },
  {
    "id": "3306112043",
    "district_id": "330611",
    "label": "Kalijering",
    "value": "Kalijering"
  },
  {
    "id": "3306112044",
    "district_id": "330611",
    "label": "Somogede",
    "value": "Somogede"
  },
  {
    "id": "3306112045",
    "district_id": "330611",
    "label": "Kaligondang",
    "value": "Kaligondang"
  },
  {
    "id": "3306112046",
    "district_id": "330611",
    "label": "Kaligintung",
    "value": "Kaligintung"
  },
  {
    "id": "3306112047",
    "district_id": "330611",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3306112048",
    "district_id": "330611",
    "label": "Wonosido",
    "value": "Wonosido"
  },
  {
    "id": "3306112049",
    "district_id": "330611",
    "label": "Pamriyan",
    "value": "Pamriyan"
  },
  {
    "id": "3306122001",
    "district_id": "330612",
    "label": "Waled",
    "value": "Waled"
  },
  {
    "id": "3306122002",
    "district_id": "330612",
    "label": "Kroyokulon",
    "value": "Kroyokulon"
  },
  {
    "id": "3306122003",
    "district_id": "330612",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3306122004",
    "district_id": "330612",
    "label": "Gesikan",
    "value": "Gesikan"
  },
  {
    "id": "3306122005",
    "district_id": "330612",
    "label": "Paitan",
    "value": "Paitan"
  },
  {
    "id": "3306122006",
    "district_id": "330612",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3306122007",
    "district_id": "330612",
    "label": "Rowobayem",
    "value": "Rowobayem"
  },
  {
    "id": "3306122008",
    "district_id": "330612",
    "label": "Kemiri Kidul",
    "value": "Kemiri Kidul"
  },
  {
    "id": "3306122009",
    "district_id": "330612",
    "label": "Bedono Kluwung",
    "value": "Bedono Kluwung"
  },
  {
    "id": "3306122010",
    "district_id": "330612",
    "label": "Bedono Pageron",
    "value": "Bedono Pageron"
  },
  {
    "id": "3306122011",
    "district_id": "330612",
    "label": "Bedono Karangduwur",
    "value": "Bedono Karangduwur"
  },
  {
    "id": "3306122012",
    "district_id": "330612",
    "label": "Rebug",
    "value": "Rebug"
  },
  {
    "id": "3306122013",
    "district_id": "330612",
    "label": "Loning",
    "value": "Loning"
  },
  {
    "id": "3306122014",
    "district_id": "330612",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3306122015",
    "district_id": "330612",
    "label": "Sutoragan",
    "value": "Sutoragan"
  },
  {
    "id": "3306122016",
    "district_id": "330612",
    "label": "Jatiwangsan",
    "value": "Jatiwangsan"
  },
  {
    "id": "3306122017",
    "district_id": "330612",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3306122018",
    "district_id": "330612",
    "label": "Girijoyo",
    "value": "Girijoyo"
  },
  {
    "id": "3306122019",
    "district_id": "330612",
    "label": "Turus",
    "value": "Turus"
  },
  {
    "id": "3306122020",
    "district_id": "330612",
    "label": "Dilem",
    "value": "Dilem"
  },
  {
    "id": "3306122021",
    "district_id": "330612",
    "label": "Kedunglo",
    "value": "Kedunglo"
  },
  {
    "id": "3306122022",
    "district_id": "330612",
    "label": "Wonosuko",
    "value": "Wonosuko"
  },
  {
    "id": "3306122023",
    "district_id": "330612",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3306122024",
    "district_id": "330612",
    "label": "Kalimeneng",
    "value": "Kalimeneng"
  },
  {
    "id": "3306122025",
    "district_id": "330612",
    "label": "Kemirilor",
    "value": "Kemirilor"
  },
  {
    "id": "3306122026",
    "district_id": "330612",
    "label": "Kerep",
    "value": "Kerep"
  },
  {
    "id": "3306122027",
    "district_id": "330612",
    "label": "Kroyolor",
    "value": "Kroyolor"
  },
  {
    "id": "3306122028",
    "district_id": "330612",
    "label": "Samping",
    "value": "Samping"
  },
  {
    "id": "3306122029",
    "district_id": "330612",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3306122030",
    "district_id": "330612",
    "label": "Kaliglagah",
    "value": "Kaliglagah"
  },
  {
    "id": "3306122031",
    "district_id": "330612",
    "label": "Kapiteran",
    "value": "Kapiteran"
  },
  {
    "id": "3306122032",
    "district_id": "330612",
    "label": "Wanurojo",
    "value": "Wanurojo"
  },
  {
    "id": "3306122033",
    "district_id": "330612",
    "label": "Rejowinangun",
    "value": "Rejowinangun"
  },
  {
    "id": "3306122034",
    "district_id": "330612",
    "label": "Kaliurip",
    "value": "Kaliurip"
  },
  {
    "id": "3306122035",
    "district_id": "330612",
    "label": "Kedungpomahanwetan",
    "value": "Kedungpomahanwetan"
  },
  {
    "id": "3306122036",
    "district_id": "330612",
    "label": "Karangluas",
    "value": "Karangluas"
  },
  {
    "id": "3306122037",
    "district_id": "330612",
    "label": "Kedungpomahankulon",
    "value": "Kedungpomahankulon"
  },
  {
    "id": "3306122038",
    "district_id": "330612",
    "label": "Sukogelap",
    "value": "Sukogelap"
  },
  {
    "id": "3306122039",
    "district_id": "330612",
    "label": "Gunungteges",
    "value": "Gunungteges"
  },
  {
    "id": "3306122040",
    "district_id": "330612",
    "label": "Purbayan",
    "value": "Purbayan"
  },
  {
    "id": "3306132001",
    "district_id": "330613",
    "label": "Puspo",
    "value": "Puspo"
  },
  {
    "id": "3306132002",
    "district_id": "330613",
    "label": "Plipiran",
    "value": "Plipiran"
  },
  {
    "id": "3306132003",
    "district_id": "330613",
    "label": "Pakisarum",
    "value": "Pakisarum"
  },
  {
    "id": "3306132004",
    "district_id": "330613",
    "label": "Brunorejo",
    "value": "Brunorejo"
  },
  {
    "id": "3306132005",
    "district_id": "330613",
    "label": "Brunosari",
    "value": "Brunosari"
  },
  {
    "id": "3306132006",
    "district_id": "330613",
    "label": "Somoleter",
    "value": "Somoleter"
  },
  {
    "id": "3306132007",
    "district_id": "330613",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3306132008",
    "district_id": "330613",
    "label": "Gowong",
    "value": "Gowong"
  },
  {
    "id": "3306132009",
    "district_id": "330613",
    "label": "Brondong",
    "value": "Brondong"
  },
  {
    "id": "3306132010",
    "district_id": "330613",
    "label": "Cepedak",
    "value": "Cepedak"
  },
  {
    "id": "3306132011",
    "district_id": "330613",
    "label": "Gunungcondong",
    "value": "Gunungcondong"
  },
  {
    "id": "3306132012",
    "district_id": "330613",
    "label": "Kemranggen",
    "value": "Kemranggen"
  },
  {
    "id": "3306132013",
    "district_id": "330613",
    "label": "Karanggedang",
    "value": "Karanggedang"
  },
  {
    "id": "3306132014",
    "district_id": "330613",
    "label": "Giyombong",
    "value": "Giyombong"
  },
  {
    "id": "3306132015",
    "district_id": "330613",
    "label": "Kambangan",
    "value": "Kambangan"
  },
  {
    "id": "3306132016",
    "district_id": "330613",
    "label": "Watuduwur",
    "value": "Watuduwur"
  },
  {
    "id": "3306132017",
    "district_id": "330613",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3306132018",
    "district_id": "330613",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3306141003",
    "district_id": "330614",
    "label": "Lugosobo",
    "value": "Lugosobo"
  },
  {
    "id": "3306142001",
    "district_id": "330614",
    "label": "Winongkidul",
    "value": "Winongkidul"
  },
  {
    "id": "3306142002",
    "district_id": "330614",
    "label": "Seren",
    "value": "Seren"
  },
  {
    "id": "3306142004",
    "district_id": "330614",
    "label": "Gintungan",
    "value": "Gintungan"
  },
  {
    "id": "3306142005",
    "district_id": "330614",
    "label": "Bulus",
    "value": "Bulus"
  },
  {
    "id": "3306142006",
    "district_id": "330614",
    "label": "Rendeng",
    "value": "Rendeng"
  },
  {
    "id": "3306142007",
    "district_id": "330614",
    "label": "Kroyo",
    "value": "Kroyo"
  },
  {
    "id": "3306142008",
    "district_id": "330614",
    "label": "Winonglor",
    "value": "Winonglor"
  },
  {
    "id": "3306142009",
    "district_id": "330614",
    "label": "Mlaran",
    "value": "Mlaran"
  },
  {
    "id": "3306142010",
    "district_id": "330614",
    "label": "Pelutan",
    "value": "Pelutan"
  },
  {
    "id": "3306142011",
    "district_id": "330614",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3306142012",
    "district_id": "330614",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3306142013",
    "district_id": "330614",
    "label": "Salam",
    "value": "Salam"
  },
  {
    "id": "3306142014",
    "district_id": "330614",
    "label": "Pakem",
    "value": "Pakem"
  },
  {
    "id": "3306142015",
    "district_id": "330614",
    "label": "Wonotopo",
    "value": "Wonotopo"
  },
  {
    "id": "3306142016",
    "district_id": "330614",
    "label": "Sidoleren",
    "value": "Sidoleren"
  },
  {
    "id": "3306142017",
    "district_id": "330614",
    "label": "Penungkulan",
    "value": "Penungkulan"
  },
  {
    "id": "3306142018",
    "district_id": "330614",
    "label": "Redin",
    "value": "Redin"
  },
  {
    "id": "3306142019",
    "district_id": "330614",
    "label": "Tlogosono",
    "value": "Tlogosono"
  },
  {
    "id": "3306142020",
    "district_id": "330614",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3306142021",
    "district_id": "330614",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3306142022",
    "district_id": "330614",
    "label": "Prumben",
    "value": "Prumben"
  },
  {
    "id": "3306142023",
    "district_id": "330614",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3306142024",
    "district_id": "330614",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3306142025",
    "district_id": "330614",
    "label": "Kalitengkek",
    "value": "Kalitengkek"
  },
  {
    "id": "3306152001",
    "district_id": "330615",
    "label": "Kalinongko",
    "value": "Kalinongko"
  },
  {
    "id": "3306152002",
    "district_id": "330615",
    "label": "Trirejo",
    "value": "Trirejo"
  },
  {
    "id": "3306152003",
    "district_id": "330615",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3306152004",
    "district_id": "330615",
    "label": "Kalikalong",
    "value": "Kalikalong"
  },
  {
    "id": "3306152005",
    "district_id": "330615",
    "label": "Rimun",
    "value": "Rimun"
  },
  {
    "id": "3306152006",
    "district_id": "330615",
    "label": "Tepansari",
    "value": "Tepansari"
  },
  {
    "id": "3306152007",
    "district_id": "330615",
    "label": "Kaliglagah",
    "value": "Kaliglagah"
  },
  {
    "id": "3306152008",
    "district_id": "330615",
    "label": "Tridadi",
    "value": "Tridadi"
  },
  {
    "id": "3306152009",
    "district_id": "330615",
    "label": "Banyuasin Separe",
    "value": "Banyuasin Separe"
  },
  {
    "id": "3306152010",
    "district_id": "330615",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3306152011",
    "district_id": "330615",
    "label": "Kalisemo",
    "value": "Kalisemo"
  },
  {
    "id": "3306152012",
    "district_id": "330615",
    "label": "Loano",
    "value": "Loano"
  },
  {
    "id": "3306152013",
    "district_id": "330615",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3306152014",
    "district_id": "330615",
    "label": "Kedungpoh",
    "value": "Kedungpoh"
  },
  {
    "id": "3306152015",
    "district_id": "330615",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3306152016",
    "district_id": "330615",
    "label": "Kebongunung",
    "value": "Kebongunung"
  },
  {
    "id": "3306152017",
    "district_id": "330615",
    "label": "Mudalrejo",
    "value": "Mudalrejo"
  },
  {
    "id": "3306152018",
    "district_id": "330615",
    "label": "Kemejing",
    "value": "Kemejing"
  },
  {
    "id": "3306152019",
    "district_id": "330615",
    "label": "Banyuasin Kembaran",
    "value": "Banyuasin Kembaran"
  },
  {
    "id": "3306152020",
    "district_id": "330615",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3306152021",
    "district_id": "330615",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3306162001",
    "district_id": "330616",
    "label": "Benowo",
    "value": "Benowo"
  },
  {
    "id": "3306162002",
    "district_id": "330616",
    "label": "Kalitapas",
    "value": "Kalitapas"
  },
  {
    "id": "3306162003",
    "district_id": "330616",
    "label": "Kaliwader",
    "value": "Kaliwader"
  },
  {
    "id": "3306162004",
    "district_id": "330616",
    "label": "Kedungpucang",
    "value": "Kedungpucang"
  },
  {
    "id": "3306162005",
    "district_id": "330616",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3306162006",
    "district_id": "330616",
    "label": "Kaliboto",
    "value": "Kaliboto"
  },
  {
    "id": "3306162007",
    "district_id": "330616",
    "label": "Kaliurip",
    "value": "Kaliurip"
  },
  {
    "id": "3306162008",
    "district_id": "330616",
    "label": "Kedungloteng",
    "value": "Kedungloteng"
  },
  {
    "id": "3306162009",
    "district_id": "330616",
    "label": "Wadas",
    "value": "Wadas"
  },
  {
    "id": "3306162010",
    "district_id": "330616",
    "label": "Cacaban Kidul",
    "value": "Cacaban Kidul"
  },
  {
    "id": "3306162011",
    "district_id": "330616",
    "label": "Cacaban Lor",
    "value": "Cacaban Lor"
  },
  {
    "id": "3306162012",
    "district_id": "330616",
    "label": "Pekacangan",
    "value": "Pekacangan"
  },
  {
    "id": "3306162013",
    "district_id": "330616",
    "label": "Medono",
    "value": "Medono"
  },
  {
    "id": "3306162014",
    "district_id": "330616",
    "label": "Bleber",
    "value": "Bleber"
  },
  {
    "id": "3306162015",
    "district_id": "330616",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3306162016",
    "district_id": "330616",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3306162017",
    "district_id": "330616",
    "label": "Guntur",
    "value": "Guntur"
  },
  {
    "id": "3306162018",
    "district_id": "330616",
    "label": "Legetan",
    "value": "Legetan"
  },
  {
    "id": "3306162019",
    "district_id": "330616",
    "label": "Ketosari",
    "value": "Ketosari"
  },
  {
    "id": "3306162020",
    "district_id": "330616",
    "label": "Kamijoro",
    "value": "Kamijoro"
  },
  {
    "id": "3306162021",
    "district_id": "330616",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3306162022",
    "district_id": "330616",
    "label": "Mayungsari",
    "value": "Mayungsari"
  },
  {
    "id": "3306162023",
    "district_id": "330616",
    "label": "Kalijambe",
    "value": "Kalijambe"
  },
  {
    "id": "3306162024",
    "district_id": "330616",
    "label": "Sukowuwuh",
    "value": "Sukowuwuh"
  },
  {
    "id": "3306162025",
    "district_id": "330616",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3306162026",
    "district_id": "330616",
    "label": "Nglaris",
    "value": "Nglaris"
  },
  {
    "id": "3306162027",
    "district_id": "330616",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3306162028",
    "district_id": "330616",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3307011007",
    "district_id": "330701",
    "label": "Wadaslintang",
    "value": "Wadaslintang"
  },
  {
    "id": "3307012001",
    "district_id": "330701",
    "label": "Kaligowong",
    "value": "Kaligowong"
  },
  {
    "id": "3307012002",
    "district_id": "330701",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3307012003",
    "district_id": "330701",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3307012004",
    "district_id": "330701",
    "label": "Erorejo",
    "value": "Erorejo"
  },
  {
    "id": "3307012005",
    "district_id": "330701",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3307012006",
    "district_id": "330701",
    "label": "Panerusan",
    "value": "Panerusan"
  },
  {
    "id": "3307012008",
    "district_id": "330701",
    "label": "Plunjaran",
    "value": "Plunjaran"
  },
  {
    "id": "3307012009",
    "district_id": "330701",
    "label": "Kumejing",
    "value": "Kumejing"
  },
  {
    "id": "3307012010",
    "district_id": "330701",
    "label": "Lancar",
    "value": "Lancar"
  },
  {
    "id": "3307012011",
    "district_id": "330701",
    "label": "Somogede",
    "value": "Somogede"
  },
  {
    "id": "3307012012",
    "district_id": "330701",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3307012013",
    "district_id": "330701",
    "label": "Tirip",
    "value": "Tirip"
  },
  {
    "id": "3307012014",
    "district_id": "330701",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3307012015",
    "district_id": "330701",
    "label": "Gumelar",
    "value": "Gumelar"
  },
  {
    "id": "3307012016",
    "district_id": "330701",
    "label": "Ngalian",
    "value": "Ngalian"
  },
  {
    "id": "3307012017",
    "district_id": "330701",
    "label": "Kalidadap",
    "value": "Kalidadap"
  },
  {
    "id": "3307021008",
    "district_id": "330702",
    "label": "Kepil",
    "value": "Kepil"
  },
  {
    "id": "3307022001",
    "district_id": "330702",
    "label": "Gondowulan",
    "value": "Gondowulan"
  },
  {
    "id": "3307022002",
    "district_id": "330702",
    "label": "Jangkrikan",
    "value": "Jangkrikan"
  },
  {
    "id": "3307022003",
    "district_id": "330702",
    "label": "Tegeswetan",
    "value": "Tegeswetan"
  },
  {
    "id": "3307022004",
    "district_id": "330702",
    "label": "Gadingsukuh",
    "value": "Gadingsukuh"
  },
  {
    "id": "3307022005",
    "district_id": "330702",
    "label": "Burat",
    "value": "Burat"
  },
  {
    "id": "3307022006",
    "district_id": "330702",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3307022007",
    "district_id": "330702",
    "label": "Gadingrejo",
    "value": "Gadingrejo"
  },
  {
    "id": "3307022009",
    "district_id": "330702",
    "label": "Beran",
    "value": "Beran"
  },
  {
    "id": "3307022010",
    "district_id": "330702",
    "label": "Kapulogo",
    "value": "Kapulogo"
  },
  {
    "id": "3307022011",
    "district_id": "330702",
    "label": "Kagungan",
    "value": "Kagungan"
  },
  {
    "id": "3307022012",
    "district_id": "330702",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3307022013",
    "district_id": "330702",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3307022014",
    "district_id": "330702",
    "label": "Ngalian",
    "value": "Ngalian"
  },
  {
    "id": "3307022015",
    "district_id": "330702",
    "label": "Kalipuru",
    "value": "Kalipuru"
  },
  {
    "id": "3307022016",
    "district_id": "330702",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3307022017",
    "district_id": "330702",
    "label": "Kaliwuluh",
    "value": "Kaliwuluh"
  },
  {
    "id": "3307022018",
    "district_id": "330702",
    "label": "Tegalgot",
    "value": "Tegalgot"
  },
  {
    "id": "3307022019",
    "district_id": "330702",
    "label": "Warangan",
    "value": "Warangan"
  },
  {
    "id": "3307022020",
    "district_id": "330702",
    "label": "Ropoh",
    "value": "Ropoh"
  },
  {
    "id": "3307022021",
    "district_id": "330702",
    "label": "Pulosaren",
    "value": "Pulosaren"
  },
  {
    "id": "3307031008",
    "district_id": "330703",
    "label": "Sapuran",
    "value": "Sapuran"
  },
  {
    "id": "3307032001",
    "district_id": "330703",
    "label": "Bogoran",
    "value": "Bogoran"
  },
  {
    "id": "3307032002",
    "district_id": "330703",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3307032003",
    "district_id": "330703",
    "label": "Pecekelan",
    "value": "Pecekelan"
  },
  {
    "id": "3307032004",
    "district_id": "330703",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "3307032005",
    "district_id": "330703",
    "label": "Surojoyo",
    "value": "Surojoyo"
  },
  {
    "id": "3307032006",
    "district_id": "330703",
    "label": "Talunombo",
    "value": "Talunombo"
  },
  {
    "id": "3307032007",
    "district_id": "330703",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3307032009",
    "district_id": "330703",
    "label": "Jolontoro",
    "value": "Jolontoro"
  },
  {
    "id": "3307032010",
    "district_id": "330703",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3307032011",
    "district_id": "330703",
    "label": "Ngadisalam",
    "value": "Ngadisalam"
  },
  {
    "id": "3307032012",
    "district_id": "330703",
    "label": "Tempuranduwur",
    "value": "Tempuranduwur"
  },
  {
    "id": "3307032013",
    "district_id": "330703",
    "label": "Marongsari",
    "value": "Marongsari"
  },
  {
    "id": "3307032014",
    "district_id": "330703",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3307032015",
    "district_id": "330703",
    "label": "Banyumudal",
    "value": "Banyumudal"
  },
  {
    "id": "3307032016",
    "district_id": "330703",
    "label": "Ngadikerso",
    "value": "Ngadikerso"
  },
  {
    "id": "3307032017",
    "district_id": "330703",
    "label": "Rimpak",
    "value": "Rimpak"
  },
  {
    "id": "3307041015",
    "district_id": "330704",
    "label": "Kaliwiro",
    "value": "Kaliwiro"
  },
  {
    "id": "3307042001",
    "district_id": "330704",
    "label": "Selomanik",
    "value": "Selomanik"
  },
  {
    "id": "3307042002",
    "district_id": "330704",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3307042003",
    "district_id": "330704",
    "label": "Medono",
    "value": "Medono"
  },
  {
    "id": "3307042004",
    "district_id": "330704",
    "label": "Ngadisono",
    "value": "Ngadisono"
  },
  {
    "id": "3307042005",
    "district_id": "330704",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3307042006",
    "district_id": "330704",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3307042007",
    "district_id": "330704",
    "label": "Kaliguwo",
    "value": "Kaliguwo"
  },
  {
    "id": "3307042008",
    "district_id": "330704",
    "label": "Pesodongan",
    "value": "Pesodongan"
  },
  {
    "id": "3307042009",
    "district_id": "330704",
    "label": "Lamuk",
    "value": "Lamuk"
  },
  {
    "id": "3307042010",
    "district_id": "330704",
    "label": "Pucungkerep",
    "value": "Pucungkerep"
  },
  {
    "id": "3307042011",
    "district_id": "330704",
    "label": "Gambaran",
    "value": "Gambaran"
  },
  {
    "id": "3307042012",
    "district_id": "330704",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3307042013",
    "district_id": "330704",
    "label": "Grugu",
    "value": "Grugu"
  },
  {
    "id": "3307042014",
    "district_id": "330704",
    "label": "Tracap",
    "value": "Tracap"
  },
  {
    "id": "3307042016",
    "district_id": "330704",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3307042017",
    "district_id": "330704",
    "label": "Cledok",
    "value": "Cledok"
  },
  {
    "id": "3307042018",
    "district_id": "330704",
    "label": "Winongsari",
    "value": "Winongsari"
  },
  {
    "id": "3307042019",
    "district_id": "330704",
    "label": "Sukoreno",
    "value": "Sukoreno"
  },
  {
    "id": "3307042020",
    "district_id": "330704",
    "label": "Kemiriombo",
    "value": "Kemiriombo"
  },
  {
    "id": "3307042021",
    "district_id": "330704",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3307051006",
    "district_id": "330705",
    "label": "Leksono",
    "value": "Leksono"
  },
  {
    "id": "3307052001",
    "district_id": "330705",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3307052002",
    "district_id": "330705",
    "label": "Lipursari",
    "value": "Lipursari"
  },
  {
    "id": "3307052003",
    "district_id": "330705",
    "label": "Selokromo",
    "value": "Selokromo"
  },
  {
    "id": "3307052004",
    "district_id": "330705",
    "label": "Sojokerto",
    "value": "Sojokerto"
  },
  {
    "id": "3307052005",
    "district_id": "330705",
    "label": "Besani",
    "value": "Besani"
  },
  {
    "id": "3307052007",
    "district_id": "330705",
    "label": "Jlamprang",
    "value": "Jlamprang"
  },
  {
    "id": "3307052008",
    "district_id": "330705",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3307052009",
    "district_id": "330705",
    "label": "Jonggolsari",
    "value": "Jonggolsari"
  },
  {
    "id": "3307052010",
    "district_id": "330705",
    "label": "Kalimendong",
    "value": "Kalimendong"
  },
  {
    "id": "3307052011",
    "district_id": "330705",
    "label": "Timbang",
    "value": "Timbang"
  },
  {
    "id": "3307052012",
    "district_id": "330705",
    "label": "Pacarmulyo",
    "value": "Pacarmulyo"
  },
  {
    "id": "3307052013",
    "district_id": "330705",
    "label": "Durensawit",
    "value": "Durensawit"
  },
  {
    "id": "3307052014",
    "district_id": "330705",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3307061008",
    "district_id": "330706",
    "label": "Selomerto",
    "value": "Selomerto"
  },
  {
    "id": "3307061011",
    "district_id": "330706",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3307062001",
    "district_id": "330706",
    "label": "Kecis",
    "value": "Kecis"
  },
  {
    "id": "3307062002",
    "district_id": "330706",
    "label": "Kaliputih",
    "value": "Kaliputih"
  },
  {
    "id": "3307062003",
    "district_id": "330706",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3307062004",
    "district_id": "330706",
    "label": "Balekambang",
    "value": "Balekambang"
  },
  {
    "id": "3307062005",
    "district_id": "330706",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3307062006",
    "district_id": "330706",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3307062007",
    "district_id": "330706",
    "label": "Gunungtawang",
    "value": "Gunungtawang"
  },
  {
    "id": "3307062009",
    "district_id": "330706",
    "label": "Pakuncen",
    "value": "Pakuncen"
  },
  {
    "id": "3307062010",
    "district_id": "330706",
    "label": "Kalierang",
    "value": "Kalierang"
  },
  {
    "id": "3307062012",
    "district_id": "330706",
    "label": "Wilayu",
    "value": "Wilayu"
  },
  {
    "id": "3307062013",
    "district_id": "330706",
    "label": "Sinduagung",
    "value": "Sinduagung"
  },
  {
    "id": "3307062014",
    "district_id": "330706",
    "label": "Sumberwulan",
    "value": "Sumberwulan"
  },
  {
    "id": "3307062015",
    "district_id": "330706",
    "label": "Plobangan",
    "value": "Plobangan"
  },
  {
    "id": "3307062016",
    "district_id": "330706",
    "label": "Simbarejo",
    "value": "Simbarejo"
  },
  {
    "id": "3307062017",
    "district_id": "330706",
    "label": "Wulungsari",
    "value": "Wulungsari"
  },
  {
    "id": "3307062018",
    "district_id": "330706",
    "label": "Bumitirto",
    "value": "Bumitirto"
  },
  {
    "id": "3307062019",
    "district_id": "330706",
    "label": "Semayu",
    "value": "Semayu"
  },
  {
    "id": "3307062020",
    "district_id": "330706",
    "label": "Adiwarno",
    "value": "Adiwarno"
  },
  {
    "id": "3307062021",
    "district_id": "330706",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3307062022",
    "district_id": "330706",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3307062023",
    "district_id": "330706",
    "label": "Tumenggungan",
    "value": "Tumenggungan"
  },
  {
    "id": "3307062024",
    "district_id": "330706",
    "label": "Ngadimulyo",
    "value": "Ngadimulyo"
  },
  {
    "id": "3307071006",
    "district_id": "330707",
    "label": "Kalikajar",
    "value": "Kalikajar"
  },
  {
    "id": "3307072001",
    "district_id": "330707",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3307072002",
    "district_id": "330707",
    "label": "Mungkung",
    "value": "Mungkung"
  },
  {
    "id": "3307072003",
    "district_id": "330707",
    "label": "Perboto",
    "value": "Perboto"
  },
  {
    "id": "3307072004",
    "district_id": "330707",
    "label": "Kedalon",
    "value": "Kedalon"
  },
  {
    "id": "3307072005",
    "district_id": "330707",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3307072007",
    "district_id": "330707",
    "label": "Simbang",
    "value": "Simbang"
  },
  {
    "id": "3307072008",
    "district_id": "330707",
    "label": "Karangduwur",
    "value": "Karangduwur"
  },
  {
    "id": "3307072009",
    "district_id": "330707",
    "label": "Kwadungan",
    "value": "Kwadungan"
  },
  {
    "id": "3307072010",
    "district_id": "330707",
    "label": "Purwojiwo",
    "value": "Purwojiwo"
  },
  {
    "id": "3307072011",
    "district_id": "330707",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3307072012",
    "district_id": "330707",
    "label": "Kalikuning",
    "value": "Kalikuning"
  },
  {
    "id": "3307072013",
    "district_id": "330707",
    "label": "Maduretno",
    "value": "Maduretno"
  },
  {
    "id": "3307072014",
    "district_id": "330707",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "3307072015",
    "district_id": "330707",
    "label": "Kembaran",
    "value": "Kembaran"
  },
  {
    "id": "3307072016",
    "district_id": "330707",
    "label": "lamuk",
    "value": "lamuk"
  },
  {
    "id": "3307072017",
    "district_id": "330707",
    "label": "Bowongso",
    "value": "Bowongso"
  },
  {
    "id": "3307072018",
    "district_id": "330707",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3307072019",
    "district_id": "330707",
    "label": "Butuh Kidul",
    "value": "Butuh Kidul"
  },
  {
    "id": "3307081001",
    "district_id": "330708",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3307081008",
    "district_id": "330708",
    "label": "Kertek",
    "value": "Kertek"
  },
  {
    "id": "3307082002",
    "district_id": "330708",
    "label": "Sudungdewo",
    "value": "Sudungdewo"
  },
  {
    "id": "3307082003",
    "district_id": "330708",
    "label": "Bejiarum",
    "value": "Bejiarum"
  },
  {
    "id": "3307082004",
    "district_id": "330708",
    "label": "Ngadikusuman",
    "value": "Ngadikusuman"
  },
  {
    "id": "3307082005",
    "district_id": "330708",
    "label": "Bojasari",
    "value": "Bojasari"
  },
  {
    "id": "3307082006",
    "district_id": "330708",
    "label": "Surengede",
    "value": "Surengede"
  },
  {
    "id": "3307082007",
    "district_id": "330708",
    "label": "Sindupaten",
    "value": "Sindupaten"
  },
  {
    "id": "3307082009",
    "district_id": "330708",
    "label": "Sumberdalem",
    "value": "Sumberdalem"
  },
  {
    "id": "3307082010",
    "district_id": "330708",
    "label": "Purwojati",
    "value": "Purwojati"
  },
  {
    "id": "3307082011",
    "district_id": "330708",
    "label": "Karangluhur",
    "value": "Karangluhur"
  },
  {
    "id": "3307082012",
    "district_id": "330708",
    "label": "Tlogodalem",
    "value": "Tlogodalem"
  },
  {
    "id": "3307082013",
    "district_id": "330708",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3307082014",
    "district_id": "330708",
    "label": "Damarkasiyan",
    "value": "Damarkasiyan"
  },
  {
    "id": "3307082015",
    "district_id": "330708",
    "label": "Tlogomulyo",
    "value": "Tlogomulyo"
  },
  {
    "id": "3307082016",
    "district_id": "330708",
    "label": "Pagerejo",
    "value": "Pagerejo"
  },
  {
    "id": "3307082017",
    "district_id": "330708",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3307082018",
    "district_id": "330708",
    "label": "Purbosono",
    "value": "Purbosono"
  },
  {
    "id": "3307082019",
    "district_id": "330708",
    "label": "Candiyasan",
    "value": "Candiyasan"
  },
  {
    "id": "3307082020",
    "district_id": "330708",
    "label": "Kapencar",
    "value": "Kapencar"
  },
  {
    "id": "3307082021",
    "district_id": "330708",
    "label": "Reco",
    "value": "Reco"
  },
  {
    "id": "3307091002",
    "district_id": "330709",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3307091003",
    "district_id": "330709",
    "label": "Mlipak",
    "value": "Mlipak"
  },
  {
    "id": "3307091004",
    "district_id": "330709",
    "label": "Jaraksari",
    "value": "Jaraksari"
  },
  {
    "id": "3307091006",
    "district_id": "330709",
    "label": "Kramatan",
    "value": "Kramatan"
  },
  {
    "id": "3307091008",
    "district_id": "330709",
    "label": "Bumiroso",
    "value": "Bumiroso"
  },
  {
    "id": "3307091009",
    "district_id": "330709",
    "label": "Rojoimo",
    "value": "Rojoimo"
  },
  {
    "id": "3307091010",
    "district_id": "330709",
    "label": "Pagerkukuh",
    "value": "Pagerkukuh"
  },
  {
    "id": "3307091011",
    "district_id": "330709",
    "label": "Sambek",
    "value": "Sambek"
  },
  {
    "id": "3307091013",
    "district_id": "330709",
    "label": "Kejiwan",
    "value": "Kejiwan"
  },
  {
    "id": "3307091014",
    "district_id": "330709",
    "label": "Kalianget",
    "value": "Kalianget"
  },
  {
    "id": "3307091015",
    "district_id": "330709",
    "label": "Jlamprang",
    "value": "Jlamprang"
  },
  {
    "id": "3307091020",
    "district_id": "330709",
    "label": "Wonosobo Barat",
    "value": "Wonosobo Barat"
  },
  {
    "id": "3307091021",
    "district_id": "330709",
    "label": "Wonosobo Timur",
    "value": "Wonosobo Timur"
  },
  {
    "id": "3307092001",
    "district_id": "330709",
    "label": "Wonolelo",
    "value": "Wonolelo"
  },
  {
    "id": "3307092005",
    "district_id": "330709",
    "label": "Jogoyitnan",
    "value": "Jogoyitnan"
  },
  {
    "id": "3307092007",
    "district_id": "330709",
    "label": "Pancurwening",
    "value": "Pancurwening"
  },
  {
    "id": "3307092016",
    "district_id": "330709",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3307092017",
    "district_id": "330709",
    "label": "Bomerto",
    "value": "Bomerto"
  },
  {
    "id": "3307092018",
    "district_id": "330709",
    "label": "Sariyoso",
    "value": "Sariyoso"
  },
  {
    "id": "3307092019",
    "district_id": "330709",
    "label": "Tlogojati",
    "value": "Tlogojati"
  },
  {
    "id": "3307101010",
    "district_id": "330710",
    "label": "Wonoroto",
    "value": "Wonoroto"
  },
  {
    "id": "3307102001",
    "district_id": "330710",
    "label": "Bumiroso",
    "value": "Bumiroso"
  },
  {
    "id": "3307102002",
    "district_id": "330710",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3307102003",
    "district_id": "330710",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3307102004",
    "district_id": "330710",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3307102005",
    "district_id": "330710",
    "label": "Banyukembar",
    "value": "Banyukembar"
  },
  {
    "id": "3307102006",
    "district_id": "330710",
    "label": "Gumawangkidul",
    "value": "Gumawangkidul"
  },
  {
    "id": "3307102007",
    "district_id": "330710",
    "label": "Wonosroyo",
    "value": "Wonosroyo"
  },
  {
    "id": "3307102008",
    "district_id": "330710",
    "label": "Watumalang",
    "value": "Watumalang"
  },
  {
    "id": "3307102009",
    "district_id": "330710",
    "label": "Pasuruhan",
    "value": "Pasuruhan"
  },
  {
    "id": "3307102011",
    "district_id": "330710",
    "label": "Lumajang",
    "value": "Lumajang"
  },
  {
    "id": "3307102012",
    "district_id": "330710",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3307102013",
    "district_id": "330710",
    "label": "Wonokampir",
    "value": "Wonokampir"
  },
  {
    "id": "3307102014",
    "district_id": "330710",
    "label": "Krinjing",
    "value": "Krinjing"
  },
  {
    "id": "3307102015",
    "district_id": "330710",
    "label": "Mutisari",
    "value": "Mutisari"
  },
  {
    "id": "3307102016",
    "district_id": "330710",
    "label": "Kalidesel",
    "value": "Kalidesel"
  },
  {
    "id": "3307111004",
    "district_id": "330711",
    "label": "Mudal",
    "value": "Mudal"
  },
  {
    "id": "3307111005",
    "district_id": "330711",
    "label": "Andongsili",
    "value": "Andongsili"
  },
  {
    "id": "3307111009",
    "district_id": "330711",
    "label": "Kalibeber",
    "value": "Kalibeber"
  },
  {
    "id": "3307112001",
    "district_id": "330711",
    "label": "Sojopuro",
    "value": "Sojopuro"
  },
  {
    "id": "3307112002",
    "district_id": "330711",
    "label": "candirejo",
    "value": "candirejo"
  },
  {
    "id": "3307112003",
    "district_id": "330711",
    "label": "Keseneng",
    "value": "Keseneng"
  },
  {
    "id": "3307112006",
    "district_id": "330711",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3307112007",
    "district_id": "330711",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3307112008",
    "district_id": "330711",
    "label": "Blederan",
    "value": "Blederan"
  },
  {
    "id": "3307112010",
    "district_id": "330711",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3307112011",
    "district_id": "330711",
    "label": "Larangankulon",
    "value": "Larangankulon"
  },
  {
    "id": "3307112012",
    "district_id": "330711",
    "label": "Pungangan",
    "value": "Pungangan"
  },
  {
    "id": "3307112013",
    "district_id": "330711",
    "label": "Gunturmadu",
    "value": "Gunturmadu"
  },
  {
    "id": "3307112014",
    "district_id": "330711",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3307112015",
    "district_id": "330711",
    "label": "wonokromo",
    "value": "wonokromo"
  },
  {
    "id": "3307112016",
    "district_id": "330711",
    "label": "Derongisor",
    "value": "Derongisor"
  },
  {
    "id": "3307112017",
    "district_id": "330711",
    "label": "Deroduwur",
    "value": "Deroduwur"
  },
  {
    "id": "3307112018",
    "district_id": "330711",
    "label": "Slukatan",
    "value": "Slukatan"
  },
  {
    "id": "3307112019",
    "district_id": "330711",
    "label": "Kebrengan",
    "value": "Kebrengan"
  },
  {
    "id": "3307121005",
    "district_id": "330712",
    "label": "Garung",
    "value": "Garung"
  },
  {
    "id": "3307122001",
    "district_id": "330712",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3307122002",
    "district_id": "330712",
    "label": "Gemblengan",
    "value": "Gemblengan"
  },
  {
    "id": "3307122003",
    "district_id": "330712",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3307122004",
    "district_id": "330712",
    "label": "Kayugiyang",
    "value": "Kayugiyang"
  },
  {
    "id": "3307122006",
    "district_id": "330712",
    "label": "Siwuran",
    "value": "Siwuran"
  },
  {
    "id": "3307122007",
    "district_id": "330712",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3307122008",
    "district_id": "330712",
    "label": "Jengkol",
    "value": "Jengkol"
  },
  {
    "id": "3307122009",
    "district_id": "330712",
    "label": "Tlogo",
    "value": "Tlogo"
  },
  {
    "id": "3307122010",
    "district_id": "330712",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3307122011",
    "district_id": "330712",
    "label": "Menjer",
    "value": "Menjer"
  },
  {
    "id": "3307122012",
    "district_id": "330712",
    "label": "Mlandi",
    "value": "Mlandi"
  },
  {
    "id": "3307122013",
    "district_id": "330712",
    "label": "Laranganlor",
    "value": "Laranganlor"
  },
  {
    "id": "3307122014",
    "district_id": "330712",
    "label": "Sitiharjo",
    "value": "Sitiharjo"
  },
  {
    "id": "3307122015",
    "district_id": "330712",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3307131008",
    "district_id": "330713",
    "label": "Kejajar",
    "value": "Kejajar"
  },
  {
    "id": "3307132001",
    "district_id": "330713",
    "label": "Campursari",
    "value": "Campursari"
  },
  {
    "id": "3307132002",
    "district_id": "330713",
    "label": "Sikunang",
    "value": "Sikunang"
  },
  {
    "id": "3307132003",
    "district_id": "330713",
    "label": "Sembungan",
    "value": "Sembungan"
  },
  {
    "id": "3307132004",
    "district_id": "330713",
    "label": "Kreo",
    "value": "Kreo"
  },
  {
    "id": "3307132005",
    "district_id": "330713",
    "label": "Tambi",
    "value": "Tambi"
  },
  {
    "id": "3307132006",
    "district_id": "330713",
    "label": "Buntu",
    "value": "Buntu"
  },
  {
    "id": "3307132007",
    "district_id": "330713",
    "label": "Sigedang",
    "value": "Sigedang"
  },
  {
    "id": "3307132009",
    "district_id": "330713",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3307132010",
    "district_id": "330713",
    "label": "Tieng",
    "value": "Tieng"
  },
  {
    "id": "3307132011",
    "district_id": "330713",
    "label": "Parikesit",
    "value": "Parikesit"
  },
  {
    "id": "3307132012",
    "district_id": "330713",
    "label": "Jojogan",
    "value": "Jojogan"
  },
  {
    "id": "3307132013",
    "district_id": "330713",
    "label": "Dieng",
    "value": "Dieng"
  },
  {
    "id": "3307132014",
    "district_id": "330713",
    "label": "Patakbanteng",
    "value": "Patakbanteng"
  },
  {
    "id": "3307132015",
    "district_id": "330713",
    "label": "Surengede",
    "value": "Surengede"
  },
  {
    "id": "3307132016",
    "district_id": "330713",
    "label": "Igirmranak",
    "value": "Igirmranak"
  },
  {
    "id": "3307142001",
    "district_id": "330714",
    "label": "Kupangan",
    "value": "Kupangan"
  },
  {
    "id": "3307142002",
    "district_id": "330714",
    "label": "Mergosari",
    "value": "Mergosari"
  },
  {
    "id": "3307142003",
    "district_id": "330714",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3307142004",
    "district_id": "330714",
    "label": "Rogojati",
    "value": "Rogojati"
  },
  {
    "id": "3307142005",
    "district_id": "330714",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3307142006",
    "district_id": "330714",
    "label": "Sempol",
    "value": "Sempol"
  },
  {
    "id": "3307142007",
    "district_id": "330714",
    "label": "Plodongan",
    "value": "Plodongan"
  },
  {
    "id": "3307142008",
    "district_id": "330714",
    "label": "Suroyudan",
    "value": "Suroyudan"
  },
  {
    "id": "3307142009",
    "district_id": "330714",
    "label": "Gumiwang",
    "value": "Gumiwang"
  },
  {
    "id": "3307142010",
    "district_id": "330714",
    "label": "Gunungtugel",
    "value": "Gunungtugel"
  },
  {
    "id": "3307142011",
    "district_id": "330714",
    "label": "Pulus",
    "value": "Pulus"
  },
  {
    "id": "3307142012",
    "district_id": "330714",
    "label": "Pucungwetan",
    "value": "Pucungwetan"
  },
  {
    "id": "3307142013",
    "district_id": "330714",
    "label": "Kajeksan",
    "value": "Kajeksan"
  },
  {
    "id": "3307142014",
    "district_id": "330714",
    "label": "Tlogo",
    "value": "Tlogo"
  },
  {
    "id": "3307142015",
    "district_id": "330714",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "3307142016",
    "district_id": "330714",
    "label": "Garunglor",
    "value": "Garunglor"
  },
  {
    "id": "3307142017",
    "district_id": "330714",
    "label": "Jebengplampitan",
    "value": "Jebengplampitan"
  },
  {
    "id": "3307152001",
    "district_id": "330715",
    "label": "Pengarengan",
    "value": "Pengarengan"
  },
  {
    "id": "3307152002",
    "district_id": "330715",
    "label": "Kalikarung",
    "value": "Kalikarung"
  },
  {
    "id": "3307152003",
    "district_id": "330715",
    "label": "Dempel",
    "value": "Dempel"
  },
  {
    "id": "3307152004",
    "district_id": "330715",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "3307152005",
    "district_id": "330715",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3307152006",
    "district_id": "330715",
    "label": "Mergolangu",
    "value": "Mergolangu"
  },
  {
    "id": "3307152007",
    "district_id": "330715",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3307152008",
    "district_id": "330715",
    "label": "Kalialang",
    "value": "Kalialang"
  },
  {
    "id": "3308012001",
    "district_id": "330801",
    "label": "Ngargoretno",
    "value": "Ngargoretno"
  },
  {
    "id": "3308012002",
    "district_id": "330801",
    "label": "Paripurno",
    "value": "Paripurno"
  },
  {
    "id": "3308012003",
    "district_id": "330801",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3308012004",
    "district_id": "330801",
    "label": "Menoreh",
    "value": "Menoreh"
  },
  {
    "id": "3308012005",
    "district_id": "330801",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3308012006",
    "district_id": "330801",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3308012007",
    "district_id": "330801",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3308012008",
    "district_id": "330801",
    "label": "Salaman",
    "value": "Salaman"
  },
  {
    "id": "3308012009",
    "district_id": "330801",
    "label": "Kalisalak",
    "value": "Kalisalak"
  },
  {
    "id": "3308012010",
    "district_id": "330801",
    "label": "Sriwedari",
    "value": "Sriwedari"
  },
  {
    "id": "3308012011",
    "district_id": "330801",
    "label": "Jebengsari",
    "value": "Jebengsari"
  },
  {
    "id": "3308012012",
    "district_id": "330801",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3308012013",
    "district_id": "330801",
    "label": "Banjarharjo",
    "value": "Banjarharjo"
  },
  {
    "id": "3308012014",
    "district_id": "330801",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3308012015",
    "district_id": "330801",
    "label": "Ngampeldento",
    "value": "Ngampeldento"
  },
  {
    "id": "3308012016",
    "district_id": "330801",
    "label": "Sidosari",
    "value": "Sidosari"
  },
  {
    "id": "3308012017",
    "district_id": "330801",
    "label": "Sawangargo",
    "value": "Sawangargo"
  },
  {
    "id": "3308012018",
    "district_id": "330801",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3308012019",
    "district_id": "330801",
    "label": "Margoyoso",
    "value": "Margoyoso"
  },
  {
    "id": "3308012020",
    "district_id": "330801",
    "label": "Kaliabu",
    "value": "Kaliabu"
  },
  {
    "id": "3308022001",
    "district_id": "330802",
    "label": "Giripurno",
    "value": "Giripurno"
  },
  {
    "id": "3308022002",
    "district_id": "330802",
    "label": "Giritengah",
    "value": "Giritengah"
  },
  {
    "id": "3308022003",
    "district_id": "330802",
    "label": "Tuksongo",
    "value": "Tuksongo"
  },
  {
    "id": "3308022004",
    "district_id": "330802",
    "label": "Majaksingi",
    "value": "Majaksingi"
  },
  {
    "id": "3308022005",
    "district_id": "330802",
    "label": "Kenalan",
    "value": "Kenalan"
  },
  {
    "id": "3308022006",
    "district_id": "330802",
    "label": "Bigaran",
    "value": "Bigaran"
  },
  {
    "id": "3308022007",
    "district_id": "330802",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3308022008",
    "district_id": "330802",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3308022009",
    "district_id": "330802",
    "label": "Ngargogondo",
    "value": "Ngargogondo"
  },
  {
    "id": "3308022010",
    "district_id": "330802",
    "label": "Wanurejo",
    "value": "Wanurejo"
  },
  {
    "id": "3308022011",
    "district_id": "330802",
    "label": "Borobudur",
    "value": "Borobudur"
  },
  {
    "id": "3308022012",
    "district_id": "330802",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3308022013",
    "district_id": "330802",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3308022014",
    "district_id": "330802",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3308022015",
    "district_id": "330802",
    "label": "Ngadiharjo",
    "value": "Ngadiharjo"
  },
  {
    "id": "3308022016",
    "district_id": "330802",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3308022017",
    "district_id": "330802",
    "label": "Tegalarum",
    "value": "Tegalarum"
  },
  {
    "id": "3308022018",
    "district_id": "330802",
    "label": "Kembanglimus",
    "value": "Kembanglimus"
  },
  {
    "id": "3308022019",
    "district_id": "330802",
    "label": "Wringinputih",
    "value": "Wringinputih"
  },
  {
    "id": "3308022020",
    "district_id": "330802",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3308032001",
    "district_id": "330803",
    "label": "Bligo",
    "value": "Bligo"
  },
  {
    "id": "3308032002",
    "district_id": "330803",
    "label": "Pakunden",
    "value": "Pakunden"
  },
  {
    "id": "3308032003",
    "district_id": "330803",
    "label": "Somokaton",
    "value": "Somokaton"
  },
  {
    "id": "3308032004",
    "district_id": "330803",
    "label": "Ngluwar",
    "value": "Ngluwar"
  },
  {
    "id": "3308032005",
    "district_id": "330803",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3308032006",
    "district_id": "330803",
    "label": "Jamuskauman",
    "value": "Jamuskauman"
  },
  {
    "id": "3308032007",
    "district_id": "330803",
    "label": "Plosogede",
    "value": "Plosogede"
  },
  {
    "id": "3308032008",
    "district_id": "330803",
    "label": "Blongkeng",
    "value": "Blongkeng"
  },
  {
    "id": "3308042001",
    "district_id": "330804",
    "label": "Mantingan",
    "value": "Mantingan"
  },
  {
    "id": "3308042002",
    "district_id": "330804",
    "label": "Salam",
    "value": "Salam"
  },
  {
    "id": "3308042003",
    "district_id": "330804",
    "label": "Sucen",
    "value": "Sucen"
  },
  {
    "id": "3308042004",
    "district_id": "330804",
    "label": "Kadiluwih",
    "value": "Kadiluwih"
  },
  {
    "id": "3308042005",
    "district_id": "330804",
    "label": "Somoketro",
    "value": "Somoketro"
  },
  {
    "id": "3308042006",
    "district_id": "330804",
    "label": "Jumoyo",
    "value": "Jumoyo"
  },
  {
    "id": "3308042007",
    "district_id": "330804",
    "label": "Tirto",
    "value": "Tirto"
  },
  {
    "id": "3308042008",
    "district_id": "330804",
    "label": "Baturono",
    "value": "Baturono"
  },
  {
    "id": "3308042009",
    "district_id": "330804",
    "label": "Tersangede",
    "value": "Tersangede"
  },
  {
    "id": "3308042010",
    "district_id": "330804",
    "label": "Sirahan",
    "value": "Sirahan"
  },
  {
    "id": "3308042011",
    "district_id": "330804",
    "label": "Seloboro",
    "value": "Seloboro"
  },
  {
    "id": "3308042012",
    "district_id": "330804",
    "label": "Gulon",
    "value": "Gulon"
  },
  {
    "id": "3308052001",
    "district_id": "330805",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3308052002",
    "district_id": "330805",
    "label": "Pandanretno",
    "value": "Pandanretno"
  },
  {
    "id": "3308052003",
    "district_id": "330805",
    "label": "Kaliurang",
    "value": "Kaliurang"
  },
  {
    "id": "3308052004",
    "district_id": "330805",
    "label": "Tegalrandu",
    "value": "Tegalrandu"
  },
  {
    "id": "3308052005",
    "district_id": "330805",
    "label": "Kamongan",
    "value": "Kamongan"
  },
  {
    "id": "3308052006",
    "district_id": "330805",
    "label": "Jerukagung",
    "value": "Jerukagung"
  },
  {
    "id": "3308052007",
    "district_id": "330805",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3308052008",
    "district_id": "330805",
    "label": "Banyuadem",
    "value": "Banyuadem"
  },
  {
    "id": "3308052009",
    "district_id": "330805",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "3308052010",
    "district_id": "330805",
    "label": "Srumbung",
    "value": "Srumbung"
  },
  {
    "id": "3308052011",
    "district_id": "330805",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3308052012",
    "district_id": "330805",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "3308052013",
    "district_id": "330805",
    "label": "Ngargosoko",
    "value": "Ngargosoko"
  },
  {
    "id": "3308052014",
    "district_id": "330805",
    "label": "Polengan",
    "value": "Polengan"
  },
  {
    "id": "3308052015",
    "district_id": "330805",
    "label": "Pucanganom",
    "value": "Pucanganom"
  },
  {
    "id": "3308052016",
    "district_id": "330805",
    "label": "Kemiren",
    "value": "Kemiren"
  },
  {
    "id": "3308052017",
    "district_id": "330805",
    "label": "Nglumut",
    "value": "Nglumut"
  },
  {
    "id": "3308062001",
    "district_id": "330806",
    "label": "Ketunggeng",
    "value": "Ketunggeng"
  },
  {
    "id": "3308062002",
    "district_id": "330806",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3308062003",
    "district_id": "330806",
    "label": "Ngadipuro",
    "value": "Ngadipuro"
  },
  {
    "id": "3308062004",
    "district_id": "330806",
    "label": "Banyubiru",
    "value": "Banyubiru"
  },
  {
    "id": "3308062005",
    "district_id": "330806",
    "label": "Banyudono",
    "value": "Banyudono"
  },
  {
    "id": "3308062006",
    "district_id": "330806",
    "label": "Dukun",
    "value": "Dukun"
  },
  {
    "id": "3308062007",
    "district_id": "330806",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "3308062008",
    "district_id": "330806",
    "label": "Ngargomulyo",
    "value": "Ngargomulyo"
  },
  {
    "id": "3308062009",
    "district_id": "330806",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3308062010",
    "district_id": "330806",
    "label": "Mangunsoko",
    "value": "Mangunsoko"
  },
  {
    "id": "3308062011",
    "district_id": "330806",
    "label": "Sewukan",
    "value": "Sewukan"
  },
  {
    "id": "3308062012",
    "district_id": "330806",
    "label": "Sengi",
    "value": "Sengi"
  },
  {
    "id": "3308062013",
    "district_id": "330806",
    "label": "Paten",
    "value": "Paten"
  },
  {
    "id": "3308062014",
    "district_id": "330806",
    "label": "Krinjing",
    "value": "Krinjing"
  },
  {
    "id": "3308062015",
    "district_id": "330806",
    "label": "Keningar",
    "value": "Keningar"
  },
  {
    "id": "3308072001",
    "district_id": "330807",
    "label": "Gondowangi",
    "value": "Gondowangi"
  },
  {
    "id": "3308072002",
    "district_id": "330807",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3308072003",
    "district_id": "330807",
    "label": "Krogowanan",
    "value": "Krogowanan"
  },
  {
    "id": "3308072004",
    "district_id": "330807",
    "label": "Kapuhan",
    "value": "Kapuhan"
  },
  {
    "id": "3308072005",
    "district_id": "330807",
    "label": "Ketep",
    "value": "Ketep"
  },
  {
    "id": "3308072006",
    "district_id": "330807",
    "label": "Wonolelo",
    "value": "Wonolelo"
  },
  {
    "id": "3308072007",
    "district_id": "330807",
    "label": "Banyuroto",
    "value": "Banyuroto"
  },
  {
    "id": "3308072008",
    "district_id": "330807",
    "label": "Wulunggunung",
    "value": "Wulunggunung"
  },
  {
    "id": "3308072009",
    "district_id": "330807",
    "label": "Gantang",
    "value": "Gantang"
  },
  {
    "id": "3308072010",
    "district_id": "330807",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3308072011",
    "district_id": "330807",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3308072012",
    "district_id": "330807",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3308072013",
    "district_id": "330807",
    "label": "Tirtosari",
    "value": "Tirtosari"
  },
  {
    "id": "3308072014",
    "district_id": "330807",
    "label": "Podosoko",
    "value": "Podosoko"
  },
  {
    "id": "3308072015",
    "district_id": "330807",
    "label": "Soronalan",
    "value": "Soronalan"
  },
  {
    "id": "3308081014",
    "district_id": "330808",
    "label": "Muntilan",
    "value": "Muntilan"
  },
  {
    "id": "3308082001",
    "district_id": "330808",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3308082002",
    "district_id": "330808",
    "label": "Sokorini",
    "value": "Sokorini"
  },
  {
    "id": "3308082003",
    "district_id": "330808",
    "label": "Sriwedari",
    "value": "Sriwedari"
  },
  {
    "id": "3308082004",
    "district_id": "330808",
    "label": "Congkrang",
    "value": "Congkrang"
  },
  {
    "id": "3308082005",
    "district_id": "330808",
    "label": "Adikarto",
    "value": "Adikarto"
  },
  {
    "id": "3308082006",
    "district_id": "330808",
    "label": "Menayu",
    "value": "Menayu"
  },
  {
    "id": "3308082007",
    "district_id": "330808",
    "label": "Keji",
    "value": "Keji"
  },
  {
    "id": "3308082008",
    "district_id": "330808",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3308082009",
    "district_id": "330808",
    "label": "Gunungpring",
    "value": "Gunungpring"
  },
  {
    "id": "3308082010",
    "district_id": "330808",
    "label": "Pucungrejo",
    "value": "Pucungrejo"
  },
  {
    "id": "3308082011",
    "district_id": "330808",
    "label": "Tamanagung",
    "value": "Tamanagung"
  },
  {
    "id": "3308082012",
    "district_id": "330808",
    "label": "Gondosuli",
    "value": "Gondosuli"
  },
  {
    "id": "3308082013",
    "district_id": "330808",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3308091003",
    "district_id": "330809",
    "label": "Mendut",
    "value": "Mendut"
  },
  {
    "id": "3308091004",
    "district_id": "330809",
    "label": "Sawitan",
    "value": "Sawitan"
  },
  {
    "id": "3308092001",
    "district_id": "330809",
    "label": "Progowati",
    "value": "Progowati"
  },
  {
    "id": "3308092002",
    "district_id": "330809",
    "label": "Ngrajek",
    "value": "Ngrajek"
  },
  {
    "id": "3308092005",
    "district_id": "330809",
    "label": "Rambeanak",
    "value": "Rambeanak"
  },
  {
    "id": "3308092006",
    "district_id": "330809",
    "label": "Paremono",
    "value": "Paremono"
  },
  {
    "id": "3308092007",
    "district_id": "330809",
    "label": "Pabelan",
    "value": "Pabelan"
  },
  {
    "id": "3308092008",
    "district_id": "330809",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3308092009",
    "district_id": "330809",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3308092010",
    "district_id": "330809",
    "label": "Mungkid",
    "value": "Mungkid"
  },
  {
    "id": "3308092011",
    "district_id": "330809",
    "label": "Ambartawang",
    "value": "Ambartawang"
  },
  {
    "id": "3308092012",
    "district_id": "330809",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3308092013",
    "district_id": "330809",
    "label": "Blondo",
    "value": "Blondo"
  },
  {
    "id": "3308092014",
    "district_id": "330809",
    "label": "Senden",
    "value": "Senden"
  },
  {
    "id": "3308092015",
    "district_id": "330809",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3308092016",
    "district_id": "330809",
    "label": "Treko",
    "value": "Treko"
  },
  {
    "id": "3308101009",
    "district_id": "330810",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3308102001",
    "district_id": "330810",
    "label": "Deyangan",
    "value": "Deyangan"
  },
  {
    "id": "3308102002",
    "district_id": "330810",
    "label": "Pasuruhan",
    "value": "Pasuruhan"
  },
  {
    "id": "3308102003",
    "district_id": "330810",
    "label": "Donorojo",
    "value": "Donorojo"
  },
  {
    "id": "3308102004",
    "district_id": "330810",
    "label": "Kalinegoro",
    "value": "Kalinegoro"
  },
  {
    "id": "3308102005",
    "district_id": "330810",
    "label": "Jogonegoro",
    "value": "Jogonegoro"
  },
  {
    "id": "3308102006",
    "district_id": "330810",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3308102007",
    "district_id": "330810",
    "label": "Bondowoso",
    "value": "Bondowoso"
  },
  {
    "id": "3308102008",
    "district_id": "330810",
    "label": "Danurejo",
    "value": "Danurejo"
  },
  {
    "id": "3308102010",
    "district_id": "330810",
    "label": "Banyurojo",
    "value": "Banyurojo"
  },
  {
    "id": "3308102011",
    "district_id": "330810",
    "label": "Banjarnegoro",
    "value": "Banjarnegoro"
  },
  {
    "id": "3308102012",
    "district_id": "330810",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3308102013",
    "district_id": "330810",
    "label": "Mertoyudan",
    "value": "Mertoyudan"
  },
  {
    "id": "3308112001",
    "district_id": "330811",
    "label": "Ringinanom",
    "value": "Ringinanom"
  },
  {
    "id": "3308112002",
    "district_id": "330811",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3308112003",
    "district_id": "330811",
    "label": "Sidoagung",
    "value": "Sidoagung"
  },
  {
    "id": "3308112004",
    "district_id": "330811",
    "label": "Tanggulrejo",
    "value": "Tanggulrejo"
  },
  {
    "id": "3308112005",
    "district_id": "330811",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3308112006",
    "district_id": "330811",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3308112007",
    "district_id": "330811",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3308112008",
    "district_id": "330811",
    "label": "Prajeksari",
    "value": "Prajeksari"
  },
  {
    "id": "3308112009",
    "district_id": "330811",
    "label": "Jogomulyo",
    "value": "Jogomulyo"
  },
  {
    "id": "3308112010",
    "district_id": "330811",
    "label": "Growong",
    "value": "Growong"
  },
  {
    "id": "3308112011",
    "district_id": "330811",
    "label": "Temanggal",
    "value": "Temanggal"
  },
  {
    "id": "3308112012",
    "district_id": "330811",
    "label": "Pringombo",
    "value": "Pringombo"
  },
  {
    "id": "3308112013",
    "district_id": "330811",
    "label": "Kemutuk",
    "value": "Kemutuk"
  },
  {
    "id": "3308112014",
    "district_id": "330811",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "3308112015",
    "district_id": "330811",
    "label": "Tugurejo",
    "value": "Tugurejo"
  },
  {
    "id": "3308122001",
    "district_id": "330812",
    "label": "Wonogiri",
    "value": "Wonogiri"
  },
  {
    "id": "3308122002",
    "district_id": "330812",
    "label": "Kwaderan",
    "value": "Kwaderan"
  },
  {
    "id": "3308122003",
    "district_id": "330812",
    "label": "Madukoro",
    "value": "Madukoro"
  },
  {
    "id": "3308122004",
    "district_id": "330812",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3308122005",
    "district_id": "330812",
    "label": "Madugondo",
    "value": "Madugondo"
  },
  {
    "id": "3308122006",
    "district_id": "330812",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3308122007",
    "district_id": "330812",
    "label": "Ngendrosari",
    "value": "Ngendrosari"
  },
  {
    "id": "3308122008",
    "district_id": "330812",
    "label": "Lesanpuro",
    "value": "Lesanpuro"
  },
  {
    "id": "3308122009",
    "district_id": "330812",
    "label": "Banjaretno",
    "value": "Banjaretno"
  },
  {
    "id": "3308122010",
    "district_id": "330812",
    "label": "Krinjing",
    "value": "Krinjing"
  },
  {
    "id": "3308122011",
    "district_id": "330812",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3308122012",
    "district_id": "330812",
    "label": "Wadas",
    "value": "Wadas"
  },
  {
    "id": "3308122013",
    "district_id": "330812",
    "label": "Kajoran",
    "value": "Kajoran"
  },
  {
    "id": "3308122014",
    "district_id": "330812",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3308122015",
    "district_id": "330812",
    "label": "Sambak",
    "value": "Sambak"
  },
  {
    "id": "3308122016",
    "district_id": "330812",
    "label": "Bambusari",
    "value": "Bambusari"
  },
  {
    "id": "3308122017",
    "district_id": "330812",
    "label": "Wuwuharjo",
    "value": "Wuwuharjo"
  },
  {
    "id": "3308122018",
    "district_id": "330812",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3308122019",
    "district_id": "330812",
    "label": "Pandanretno",
    "value": "Pandanretno"
  },
  {
    "id": "3308122020",
    "district_id": "330812",
    "label": "Krumpakan",
    "value": "Krumpakan"
  },
  {
    "id": "3308122021",
    "district_id": "330812",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3308122022",
    "district_id": "330812",
    "label": "Sangen",
    "value": "Sangen"
  },
  {
    "id": "3308122023",
    "district_id": "330812",
    "label": "Pucungroto",
    "value": "Pucungroto"
  },
  {
    "id": "3308122024",
    "district_id": "330812",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3308122025",
    "district_id": "330812",
    "label": "Sidowangi",
    "value": "Sidowangi"
  },
  {
    "id": "3308122026",
    "district_id": "330812",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3308122027",
    "district_id": "330812",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3308122028",
    "district_id": "330812",
    "label": "Sutopati",
    "value": "Sutopati"
  },
  {
    "id": "3308122029",
    "district_id": "330812",
    "label": "Sukomakmur",
    "value": "Sukomakmur"
  },
  {
    "id": "3308132001",
    "district_id": "330813",
    "label": "Kaliangkrik",
    "value": "Kaliangkrik"
  },
  {
    "id": "3308132002",
    "district_id": "330813",
    "label": "Ngawonggo",
    "value": "Ngawonggo"
  },
  {
    "id": "3308132003",
    "district_id": "330813",
    "label": "Munggangsari",
    "value": "Munggangsari"
  },
  {
    "id": "3308132004",
    "district_id": "330813",
    "label": "Ngargosoko",
    "value": "Ngargosoko"
  },
  {
    "id": "3308132005",
    "district_id": "330813",
    "label": "Ngendrokilo",
    "value": "Ngendrokilo"
  },
  {
    "id": "3308132006",
    "district_id": "330813",
    "label": "Maduretno",
    "value": "Maduretno"
  },
  {
    "id": "3308132007",
    "district_id": "330813",
    "label": "Temanggung",
    "value": "Temanggung"
  },
  {
    "id": "3308132008",
    "district_id": "330813",
    "label": "Pengarengan",
    "value": "Pengarengan"
  },
  {
    "id": "3308132009",
    "district_id": "330813",
    "label": "Mangli",
    "value": "Mangli"
  },
  {
    "id": "3308132010",
    "district_id": "330813",
    "label": "Selomoyo",
    "value": "Selomoyo"
  },
  {
    "id": "3308132011",
    "district_id": "330813",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3308132012",
    "district_id": "330813",
    "label": "Giriwarno",
    "value": "Giriwarno"
  },
  {
    "id": "3308132013",
    "district_id": "330813",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3308132014",
    "district_id": "330813",
    "label": "Kebonlegi",
    "value": "Kebonlegi"
  },
  {
    "id": "3308132015",
    "district_id": "330813",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3308132016",
    "district_id": "330813",
    "label": "Beseran",
    "value": "Beseran"
  },
  {
    "id": "3308132017",
    "district_id": "330813",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3308132018",
    "district_id": "330813",
    "label": "Ketangi",
    "value": "Ketangi"
  },
  {
    "id": "3308132019",
    "district_id": "330813",
    "label": "Balekerto",
    "value": "Balekerto"
  },
  {
    "id": "3308132020",
    "district_id": "330813",
    "label": "Adipuro",
    "value": "Adipuro"
  },
  {
    "id": "3308142001",
    "district_id": "330814",
    "label": "Bandongan",
    "value": "Bandongan"
  },
  {
    "id": "3308142002",
    "district_id": "330814",
    "label": "Salamkanci",
    "value": "Salamkanci"
  },
  {
    "id": "3308142003",
    "district_id": "330814",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3308142004",
    "district_id": "330814",
    "label": "Tonoboyo",
    "value": "Tonoboyo"
  },
  {
    "id": "3308142005",
    "district_id": "330814",
    "label": "Gandusari",
    "value": "Gandusari"
  },
  {
    "id": "3308142006",
    "district_id": "330814",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3308142007",
    "district_id": "330814",
    "label": "Trasan",
    "value": "Trasan"
  },
  {
    "id": "3308142008",
    "district_id": "330814",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "3308142009",
    "district_id": "330814",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3308142010",
    "district_id": "330814",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3308142011",
    "district_id": "330814",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3308142012",
    "district_id": "330814",
    "label": "Kalegen",
    "value": "Kalegen"
  },
  {
    "id": "3308142013",
    "district_id": "330814",
    "label": "Ngepanrejo",
    "value": "Ngepanrejo"
  },
  {
    "id": "3308142014",
    "district_id": "330814",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3308152001",
    "district_id": "330815",
    "label": "Surojoyo",
    "value": "Surojoyo"
  },
  {
    "id": "3308152002",
    "district_id": "330815",
    "label": "Tempak",
    "value": "Tempak"
  },
  {
    "id": "3308152003",
    "district_id": "330815",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3308152004",
    "district_id": "330815",
    "label": "Mejing",
    "value": "Mejing"
  },
  {
    "id": "3308152005",
    "district_id": "330815",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3308152006",
    "district_id": "330815",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3308152007",
    "district_id": "330815",
    "label": "Giyanti",
    "value": "Giyanti"
  },
  {
    "id": "3308152008",
    "district_id": "330815",
    "label": "Kembaran",
    "value": "Kembaran"
  },
  {
    "id": "3308152009",
    "district_id": "330815",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3308152010",
    "district_id": "330815",
    "label": "Podosoko",
    "value": "Podosoko"
  },
  {
    "id": "3308152011",
    "district_id": "330815",
    "label": "Tampirkulon",
    "value": "Tampirkulon"
  },
  {
    "id": "3308152012",
    "district_id": "330815",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3308152013",
    "district_id": "330815",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3308152014",
    "district_id": "330815",
    "label": "Tampirwetan",
    "value": "Tampirwetan"
  },
  {
    "id": "3308152015",
    "district_id": "330815",
    "label": "Tembelang",
    "value": "Tembelang"
  },
  {
    "id": "3308152016",
    "district_id": "330815",
    "label": "Sonorejo",
    "value": "Sonorejo"
  },
  {
    "id": "3308152017",
    "district_id": "330815",
    "label": "Trenten",
    "value": "Trenten"
  },
  {
    "id": "3308152018",
    "district_id": "330815",
    "label": "Bateh",
    "value": "Bateh"
  },
  {
    "id": "3308152019",
    "district_id": "330815",
    "label": "Surodadi",
    "value": "Surodadi"
  },
  {
    "id": "3308162001",
    "district_id": "330816",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3308162002",
    "district_id": "330816",
    "label": "Banyusidi",
    "value": "Banyusidi"
  },
  {
    "id": "3308162003",
    "district_id": "330816",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3308162004",
    "district_id": "330816",
    "label": "Daseh",
    "value": "Daseh"
  },
  {
    "id": "3308162005",
    "district_id": "330816",
    "label": "Gumelem",
    "value": "Gumelem"
  },
  {
    "id": "3308162006",
    "district_id": "330816",
    "label": "Kaponan",
    "value": "Kaponan"
  },
  {
    "id": "3308162007",
    "district_id": "330816",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3308162008",
    "district_id": "330816",
    "label": "Ketundan",
    "value": "Ketundan"
  },
  {
    "id": "3308162009",
    "district_id": "330816",
    "label": "Daleman Kidul",
    "value": "Daleman Kidul"
  },
  {
    "id": "3308162010",
    "district_id": "330816",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3308162011",
    "district_id": "330816",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "3308162012",
    "district_id": "330816",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3308162013",
    "district_id": "330816",
    "label": "Kajangkoso",
    "value": "Kajangkoso"
  },
  {
    "id": "3308162014",
    "district_id": "330816",
    "label": "Munengwarangan",
    "value": "Munengwarangan"
  },
  {
    "id": "3308162015",
    "district_id": "330816",
    "label": "Gondangsari",
    "value": "Gondangsari"
  },
  {
    "id": "3308162016",
    "district_id": "330816",
    "label": "Kenalan",
    "value": "Kenalan"
  },
  {
    "id": "3308162017",
    "district_id": "330816",
    "label": "Pogalan",
    "value": "Pogalan"
  },
  {
    "id": "3308162018",
    "district_id": "330816",
    "label": "Gejagan",
    "value": "Gejagan"
  },
  {
    "id": "3308162019",
    "district_id": "330816",
    "label": "Muneng",
    "value": "Muneng"
  },
  {
    "id": "3308162020",
    "district_id": "330816",
    "label": "Jambewangi",
    "value": "Jambewangi"
  },
  {
    "id": "3308172001",
    "district_id": "330817",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "3308172002",
    "district_id": "330817",
    "label": "Jogonayan",
    "value": "Jogonayan"
  },
  {
    "id": "3308172003",
    "district_id": "330817",
    "label": "Tejosari",
    "value": "Tejosari"
  },
  {
    "id": "3308172004",
    "district_id": "330817",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3308172005",
    "district_id": "330817",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3308172006",
    "district_id": "330817",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3308172007",
    "district_id": "330817",
    "label": "Genikan",
    "value": "Genikan"
  },
  {
    "id": "3308172008",
    "district_id": "330817",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3308172009",
    "district_id": "330817",
    "label": "Madyogondo",
    "value": "Madyogondo"
  },
  {
    "id": "3308172010",
    "district_id": "330817",
    "label": "Jogoyasan",
    "value": "Jogoyasan"
  },
  {
    "id": "3308172011",
    "district_id": "330817",
    "label": "Keditan",
    "value": "Keditan"
  },
  {
    "id": "3308172012",
    "district_id": "330817",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3308172013",
    "district_id": "330817",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3308172014",
    "district_id": "330817",
    "label": "Selomirah",
    "value": "Selomirah"
  },
  {
    "id": "3308172015",
    "district_id": "330817",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3308172016",
    "district_id": "330817",
    "label": "Seloprojo",
    "value": "Seloprojo"
  },
  {
    "id": "3308182001",
    "district_id": "330818",
    "label": "Grabag",
    "value": "Grabag"
  },
  {
    "id": "3308182002",
    "district_id": "330818",
    "label": "Sumurarum",
    "value": "Sumurarum"
  },
  {
    "id": "3308182003",
    "district_id": "330818",
    "label": "Banyusari",
    "value": "Banyusari"
  },
  {
    "id": "3308182004",
    "district_id": "330818",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "3308182005",
    "district_id": "330818",
    "label": "Sidogede",
    "value": "Sidogede"
  },
  {
    "id": "3308182006",
    "district_id": "330818",
    "label": "Citrosono",
    "value": "Citrosono"
  },
  {
    "id": "3308182007",
    "district_id": "330818",
    "label": "Kleteran",
    "value": "Kleteran"
  },
  {
    "id": "3308182008",
    "district_id": "330818",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3308182009",
    "district_id": "330818",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3308182010",
    "district_id": "330818",
    "label": "Baleagung",
    "value": "Baleagung"
  },
  {
    "id": "3308182011",
    "district_id": "330818",
    "label": "Klegen",
    "value": "Klegen"
  },
  {
    "id": "3308182012",
    "district_id": "330818",
    "label": "Kalikuto",
    "value": "Kalikuto"
  },
  {
    "id": "3308182013",
    "district_id": "330818",
    "label": "Kalipucang",
    "value": "Kalipucang"
  },
  {
    "id": "3308182014",
    "district_id": "330818",
    "label": "Seworan",
    "value": "Seworan"
  },
  {
    "id": "3308182015",
    "district_id": "330818",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3308182016",
    "district_id": "330818",
    "label": "Sambungrejo",
    "value": "Sambungrejo"
  },
  {
    "id": "3308182017",
    "district_id": "330818",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3308182018",
    "district_id": "330818",
    "label": "Tirto",
    "value": "Tirto"
  },
  {
    "id": "3308182019",
    "district_id": "330818",
    "label": "Ketawang",
    "value": "Ketawang"
  },
  {
    "id": "3308182020",
    "district_id": "330818",
    "label": "Salam",
    "value": "Salam"
  },
  {
    "id": "3308182021",
    "district_id": "330818",
    "label": "Cokro",
    "value": "Cokro"
  },
  {
    "id": "3308182022",
    "district_id": "330818",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3308182023",
    "district_id": "330818",
    "label": "Ngrancah",
    "value": "Ngrancah"
  },
  {
    "id": "3308182024",
    "district_id": "330818",
    "label": "Sugihmas",
    "value": "Sugihmas"
  },
  {
    "id": "3308182025",
    "district_id": "330818",
    "label": "Pesidi",
    "value": "Pesidi"
  },
  {
    "id": "3308182026",
    "district_id": "330818",
    "label": "Giriwetan",
    "value": "Giriwetan"
  },
  {
    "id": "3308182027",
    "district_id": "330818",
    "label": "Pucungsari",
    "value": "Pucungsari"
  },
  {
    "id": "3308182028",
    "district_id": "330818",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3308192001",
    "district_id": "330819",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3308192002",
    "district_id": "330819",
    "label": "Soroyudan",
    "value": "Soroyudan"
  },
  {
    "id": "3308192003",
    "district_id": "330819",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3308192004",
    "district_id": "330819",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3308192005",
    "district_id": "330819",
    "label": "Dlimas",
    "value": "Dlimas"
  },
  {
    "id": "3308192006",
    "district_id": "330819",
    "label": "Banyusari",
    "value": "Banyusari"
  },
  {
    "id": "3308192007",
    "district_id": "330819",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3308192008",
    "district_id": "330819",
    "label": "Tampingan",
    "value": "Tampingan"
  },
  {
    "id": "3308192009",
    "district_id": "330819",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3308192010",
    "district_id": "330819",
    "label": "Glagahombo",
    "value": "Glagahombo"
  },
  {
    "id": "3308192011",
    "district_id": "330819",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3308192012",
    "district_id": "330819",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3308192013",
    "district_id": "330819",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3308192014",
    "district_id": "330819",
    "label": "Klopo",
    "value": "Klopo"
  },
  {
    "id": "3308192015",
    "district_id": "330819",
    "label": "Japan",
    "value": "Japan"
  },
  {
    "id": "3308192016",
    "district_id": "330819",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3308192017",
    "district_id": "330819",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3308192018",
    "district_id": "330819",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3308192019",
    "district_id": "330819",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3308192020",
    "district_id": "330819",
    "label": "Donorejo",
    "value": "Donorejo"
  },
  {
    "id": "3308192021",
    "district_id": "330819",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3308201001",
    "district_id": "330820",
    "label": "Secang",
    "value": "Secang"
  },
  {
    "id": "3308202002",
    "district_id": "330820",
    "label": "Madusari",
    "value": "Madusari"
  },
  {
    "id": "3308202003",
    "district_id": "330820",
    "label": "Madyocondro",
    "value": "Madyocondro"
  },
  {
    "id": "3308202004",
    "district_id": "330820",
    "label": "Ngabean",
    "value": "Ngabean"
  },
  {
    "id": "3308202005",
    "district_id": "330820",
    "label": "Krincing",
    "value": "Krincing"
  },
  {
    "id": "3308202006",
    "district_id": "330820",
    "label": "Donorejo",
    "value": "Donorejo"
  },
  {
    "id": "3308202007",
    "district_id": "330820",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3308202008",
    "district_id": "330820",
    "label": "Candiretno",
    "value": "Candiretno"
  },
  {
    "id": "3308202009",
    "district_id": "330820",
    "label": "Pancuranmas",
    "value": "Pancuranmas"
  },
  {
    "id": "3308202010",
    "district_id": "330820",
    "label": "Jambewangi",
    "value": "Jambewangi"
  },
  {
    "id": "3308202011",
    "district_id": "330820",
    "label": "Payaman",
    "value": "Payaman"
  },
  {
    "id": "3308202012",
    "district_id": "330820",
    "label": "Kalijoso",
    "value": "Kalijoso"
  },
  {
    "id": "3308202013",
    "district_id": "330820",
    "label": "Ngadirojo",
    "value": "Ngadirojo"
  },
  {
    "id": "3308202014",
    "district_id": "330820",
    "label": "Karangkajen",
    "value": "Karangkajen"
  },
  {
    "id": "3308202015",
    "district_id": "330820",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3308202016",
    "district_id": "330820",
    "label": "Pucang",
    "value": "Pucang"
  },
  {
    "id": "3308202017",
    "district_id": "330820",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3308202018",
    "district_id": "330820",
    "label": "Donomulyo",
    "value": "Donomulyo"
  },
  {
    "id": "3308202019",
    "district_id": "330820",
    "label": "Girikulon",
    "value": "Girikulon"
  },
  {
    "id": "3308202020",
    "district_id": "330820",
    "label": "Pirikan",
    "value": "Pirikan"
  },
  {
    "id": "3308212001",
    "district_id": "330821",
    "label": "Windusari",
    "value": "Windusari"
  },
  {
    "id": "3308212002",
    "district_id": "330821",
    "label": "Pasangsari",
    "value": "Pasangsari"
  },
  {
    "id": "3308212003",
    "district_id": "330821",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3308212004",
    "district_id": "330821",
    "label": "Bandarsedayu",
    "value": "Bandarsedayu"
  },
  {
    "id": "3308212005",
    "district_id": "330821",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3308212006",
    "district_id": "330821",
    "label": "Balesari",
    "value": "Balesari"
  },
  {
    "id": "3308212007",
    "district_id": "330821",
    "label": "Kembangkuning",
    "value": "Kembangkuning"
  },
  {
    "id": "3308212008",
    "district_id": "330821",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3308212009",
    "district_id": "330821",
    "label": "Wonoroto",
    "value": "Wonoroto"
  },
  {
    "id": "3308212010",
    "district_id": "330821",
    "label": "Genito",
    "value": "Genito"
  },
  {
    "id": "3308212011",
    "district_id": "330821",
    "label": "Kentengsari",
    "value": "Kentengsari"
  },
  {
    "id": "3308212012",
    "district_id": "330821",
    "label": "Umbulsari",
    "value": "Umbulsari"
  },
  {
    "id": "3308212013",
    "district_id": "330821",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3308212014",
    "district_id": "330821",
    "label": "Dampit",
    "value": "Dampit"
  },
  {
    "id": "3308212015",
    "district_id": "330821",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3308212016",
    "district_id": "330821",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3308212017",
    "district_id": "330821",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3308212018",
    "district_id": "330821",
    "label": "Kalijoso",
    "value": "Kalijoso"
  },
  {
    "id": "3308212019",
    "district_id": "330821",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3308212020",
    "district_id": "330821",
    "label": "Gondangrejo",
    "value": "Gondangrejo"
  },
  {
    "id": "3309012001",
    "district_id": "330901",
    "label": "Tlogolele",
    "value": "Tlogolele"
  },
  {
    "id": "3309012002",
    "district_id": "330901",
    "label": "Klakah",
    "value": "Klakah"
  },
  {
    "id": "3309012003",
    "district_id": "330901",
    "label": "Jrakah",
    "value": "Jrakah"
  },
  {
    "id": "3309012004",
    "district_id": "330901",
    "label": "Lencoh",
    "value": "Lencoh"
  },
  {
    "id": "3309012005",
    "district_id": "330901",
    "label": "Suroteleng",
    "value": "Suroteleng"
  },
  {
    "id": "3309012006",
    "district_id": "330901",
    "label": "Samiran",
    "value": "Samiran"
  },
  {
    "id": "3309012007",
    "district_id": "330901",
    "label": "Selo",
    "value": "Selo"
  },
  {
    "id": "3309012008",
    "district_id": "330901",
    "label": "Tarubatang",
    "value": "Tarubatang"
  },
  {
    "id": "3309012009",
    "district_id": "330901",
    "label": "Senden",
    "value": "Senden"
  },
  {
    "id": "3309012010",
    "district_id": "330901",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3309022002",
    "district_id": "330902",
    "label": "Tanduk",
    "value": "Tanduk"
  },
  {
    "id": "3309022003",
    "district_id": "330902",
    "label": "Banyuanyar",
    "value": "Banyuanyar"
  },
  {
    "id": "3309022004",
    "district_id": "330902",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3309022005",
    "district_id": "330902",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3309022006",
    "district_id": "330902",
    "label": "Selodoko",
    "value": "Selodoko"
  },
  {
    "id": "3309022007",
    "district_id": "330902",
    "label": "Ngenden",
    "value": "Ngenden"
  },
  {
    "id": "3309022008",
    "district_id": "330902",
    "label": "Ngampon",
    "value": "Ngampon"
  },
  {
    "id": "3309022009",
    "district_id": "330902",
    "label": "Gondangslamet",
    "value": "Gondangslamet"
  },
  {
    "id": "3309022010",
    "district_id": "330902",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3309022011",
    "district_id": "330902",
    "label": "Urutsewu",
    "value": "Urutsewu"
  },
  {
    "id": "3309032001",
    "district_id": "330903",
    "label": "Wonodoyo",
    "value": "Wonodoyo"
  },
  {
    "id": "3309032002",
    "district_id": "330903",
    "label": "Jombong",
    "value": "Jombong"
  },
  {
    "id": "3309032003",
    "district_id": "330903",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3309032004",
    "district_id": "330903",
    "label": "Sumbung",
    "value": "Sumbung"
  },
  {
    "id": "3309032005",
    "district_id": "330903",
    "label": "Paras",
    "value": "Paras"
  },
  {
    "id": "3309032006",
    "district_id": "330903",
    "label": "Jelok",
    "value": "Jelok"
  },
  {
    "id": "3309032007",
    "district_id": "330903",
    "label": "Bakulan",
    "value": "Bakulan"
  },
  {
    "id": "3309032008",
    "district_id": "330903",
    "label": "Candigatak",
    "value": "Candigatak"
  },
  {
    "id": "3309032009",
    "district_id": "330903",
    "label": "Cabeankunti",
    "value": "Cabeankunti"
  },
  {
    "id": "3309032010",
    "district_id": "330903",
    "label": "Mliwis",
    "value": "Mliwis"
  },
  {
    "id": "3309032011",
    "district_id": "330903",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "3309032012",
    "district_id": "330903",
    "label": "Genting",
    "value": "Genting"
  },
  {
    "id": "3309032013",
    "district_id": "330903",
    "label": "Cepogo",
    "value": "Cepogo"
  },
  {
    "id": "3309032014",
    "district_id": "330903",
    "label": "Kembangkuning",
    "value": "Kembangkuning"
  },
  {
    "id": "3309032015",
    "district_id": "330903",
    "label": "Gubug",
    "value": "Gubug"
  },
  {
    "id": "3309042011",
    "district_id": "330904",
    "label": "Pagerjurang",
    "value": "Pagerjurang"
  },
  {
    "id": "3309042012",
    "district_id": "330904",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3309042013",
    "district_id": "330904",
    "label": "Sruni",
    "value": "Sruni"
  },
  {
    "id": "3309042014",
    "district_id": "330904",
    "label": "Cluntang",
    "value": "Cluntang"
  },
  {
    "id": "3309042015",
    "district_id": "330904",
    "label": "Kembangsari",
    "value": "Kembangsari"
  },
  {
    "id": "3309042016",
    "district_id": "330904",
    "label": "Ringinlarik",
    "value": "Ringinlarik"
  },
  {
    "id": "3309042017",
    "district_id": "330904",
    "label": "Kebongulo",
    "value": "Kebongulo"
  },
  {
    "id": "3309042018",
    "district_id": "330904",
    "label": "Musuk",
    "value": "Musuk"
  },
  {
    "id": "3309042019",
    "district_id": "330904",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3309042020",
    "district_id": "330904",
    "label": "Pusporenggo",
    "value": "Pusporenggo"
  },
  {
    "id": "3309051001",
    "district_id": "330905",
    "label": "Pulisen",
    "value": "Pulisen"
  },
  {
    "id": "3309051002",
    "district_id": "330905",
    "label": "Siswodipuran",
    "value": "Siswodipuran"
  },
  {
    "id": "3309051004",
    "district_id": "330905",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3309052003",
    "district_id": "330905",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "3309052005",
    "district_id": "330905",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3309052006",
    "district_id": "330905",
    "label": "Penggung",
    "value": "Penggung"
  },
  {
    "id": "3309052007",
    "district_id": "330905",
    "label": "Kiringan",
    "value": "Kiringan"
  },
  {
    "id": "3309052008",
    "district_id": "330905",
    "label": "Mudal",
    "value": "Mudal"
  },
  {
    "id": "3309052009",
    "district_id": "330905",
    "label": "Kebonbimo",
    "value": "Kebonbimo"
  },
  {
    "id": "3309061007",
    "district_id": "330906",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3309061009",
    "district_id": "330906",
    "label": "Mojosongo",
    "value": "Mojosongo"
  },
  {
    "id": "3309062001",
    "district_id": "330906",
    "label": "Madu",
    "value": "Madu"
  },
  {
    "id": "3309062002",
    "district_id": "330906",
    "label": "Singosari",
    "value": "Singosari"
  },
  {
    "id": "3309062003",
    "district_id": "330906",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3309062004",
    "district_id": "330906",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3309062005",
    "district_id": "330906",
    "label": "Jurug",
    "value": "Jurug"
  },
  {
    "id": "3309062006",
    "district_id": "330906",
    "label": "Karangnongko",
    "value": "Karangnongko"
  },
  {
    "id": "3309062008",
    "district_id": "330906",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3309062010",
    "district_id": "330906",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3309062011",
    "district_id": "330906",
    "label": "Brajan",
    "value": "Brajan"
  },
  {
    "id": "3309062012",
    "district_id": "330906",
    "label": "Metuk",
    "value": "Metuk"
  },
  {
    "id": "3309062013",
    "district_id": "330906",
    "label": "Dlingo",
    "value": "Dlingo"
  },
  {
    "id": "3309072001",
    "district_id": "330907",
    "label": "Kopen",
    "value": "Kopen"
  },
  {
    "id": "3309072002",
    "district_id": "330907",
    "label": "Doplang",
    "value": "Doplang"
  },
  {
    "id": "3309072003",
    "district_id": "330907",
    "label": "Kadireso",
    "value": "Kadireso"
  },
  {
    "id": "3309072004",
    "district_id": "330907",
    "label": "Nepen",
    "value": "Nepen"
  },
  {
    "id": "3309072005",
    "district_id": "330907",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3309072006",
    "district_id": "330907",
    "label": "Bangsalan",
    "value": "Bangsalan"
  },
  {
    "id": "3309072007",
    "district_id": "330907",
    "label": "Salakan",
    "value": "Salakan"
  },
  {
    "id": "3309072008",
    "district_id": "330907",
    "label": "Teras",
    "value": "Teras"
  },
  {
    "id": "3309072009",
    "district_id": "330907",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3309072010",
    "district_id": "330907",
    "label": "Mojolegi",
    "value": "Mojolegi"
  },
  {
    "id": "3309072011",
    "district_id": "330907",
    "label": "Gumukrejo",
    "value": "Gumukrejo"
  },
  {
    "id": "3309072012",
    "district_id": "330907",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3309072013",
    "district_id": "330907",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3309082001",
    "district_id": "330908",
    "label": "Kateguhan",
    "value": "Kateguhan"
  },
  {
    "id": "3309082002",
    "district_id": "330908",
    "label": "Manjung",
    "value": "Manjung"
  },
  {
    "id": "3309082003",
    "district_id": "330908",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3309082004",
    "district_id": "330908",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3309082005",
    "district_id": "330908",
    "label": "Tlawong",
    "value": "Tlawong"
  },
  {
    "id": "3309082006",
    "district_id": "330908",
    "label": "Jenengan",
    "value": "Jenengan"
  },
  {
    "id": "3309082007",
    "district_id": "330908",
    "label": "Cepokosawit",
    "value": "Cepokosawit"
  },
  {
    "id": "3309082008",
    "district_id": "330908",
    "label": "Kemasan",
    "value": "Kemasan"
  },
  {
    "id": "3309082009",
    "district_id": "330908",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3309082010",
    "district_id": "330908",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3309082011",
    "district_id": "330908",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3309082012",
    "district_id": "330908",
    "label": "Guwokajen",
    "value": "Guwokajen"
  },
  {
    "id": "3309092001",
    "district_id": "330909",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3309092002",
    "district_id": "330909",
    "label": "Jipangan",
    "value": "Jipangan"
  },
  {
    "id": "3309092003",
    "district_id": "330909",
    "label": "Jembungan",
    "value": "Jembungan"
  },
  {
    "id": "3309092004",
    "district_id": "330909",
    "label": "Sambon",
    "value": "Sambon"
  },
  {
    "id": "3309092005",
    "district_id": "330909",
    "label": "Kuwiran",
    "value": "Kuwiran"
  },
  {
    "id": "3309092006",
    "district_id": "330909",
    "label": "Cangkringan",
    "value": "Cangkringan"
  },
  {
    "id": "3309092007",
    "district_id": "330909",
    "label": "Ngaru-aru",
    "value": "Ngaru-aru"
  },
  {
    "id": "3309092008",
    "district_id": "330909",
    "label": "Bendan",
    "value": "Bendan"
  },
  {
    "id": "3309092009",
    "district_id": "330909",
    "label": "Ketaon",
    "value": "Ketaon"
  },
  {
    "id": "3309092010",
    "district_id": "330909",
    "label": "Banyudono",
    "value": "Banyudono"
  },
  {
    "id": "3309092011",
    "district_id": "330909",
    "label": "Batan",
    "value": "Batan"
  },
  {
    "id": "3309092012",
    "district_id": "330909",
    "label": "Denggungan",
    "value": "Denggungan"
  },
  {
    "id": "3309092013",
    "district_id": "330909",
    "label": "Bangak",
    "value": "Bangak"
  },
  {
    "id": "3309092014",
    "district_id": "330909",
    "label": "Trayu",
    "value": "Trayu"
  },
  {
    "id": "3309092015",
    "district_id": "330909",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3309102001",
    "district_id": "330910",
    "label": "Canden",
    "value": "Canden"
  },
  {
    "id": "3309102002",
    "district_id": "330910",
    "label": "Senting",
    "value": "Senting"
  },
  {
    "id": "3309102003",
    "district_id": "330910",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3309102004",
    "district_id": "330910",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3309102005",
    "district_id": "330910",
    "label": "Glintang",
    "value": "Glintang"
  },
  {
    "id": "3309102006",
    "district_id": "330910",
    "label": "Catur",
    "value": "Catur"
  },
  {
    "id": "3309102007",
    "district_id": "330910",
    "label": "Tawengan",
    "value": "Tawengan"
  },
  {
    "id": "3309102008",
    "district_id": "330910",
    "label": "Sambi",
    "value": "Sambi"
  },
  {
    "id": "3309102009",
    "district_id": "330910",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3309102010",
    "district_id": "330910",
    "label": "Kepoh",
    "value": "Kepoh"
  },
  {
    "id": "3309102011",
    "district_id": "330910",
    "label": "Jagoan",
    "value": "Jagoan"
  },
  {
    "id": "3309102012",
    "district_id": "330910",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3309102013",
    "district_id": "330910",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3309102014",
    "district_id": "330910",
    "label": "Trosobo",
    "value": "Trosobo"
  },
  {
    "id": "3309102015",
    "district_id": "330910",
    "label": "Cermo",
    "value": "Cermo"
  },
  {
    "id": "3309102016",
    "district_id": "330910",
    "label": "Nglembu",
    "value": "Nglembu"
  },
  {
    "id": "3309112001",
    "district_id": "330911",
    "label": "Ngargorejo",
    "value": "Ngargorejo"
  },
  {
    "id": "3309112002",
    "district_id": "330911",
    "label": "Sobokerto",
    "value": "Sobokerto"
  },
  {
    "id": "3309112003",
    "district_id": "330911",
    "label": "Ngesrep",
    "value": "Ngesrep"
  },
  {
    "id": "3309112004",
    "district_id": "330911",
    "label": "Gagaksipat",
    "value": "Gagaksipat"
  },
  {
    "id": "3309112005",
    "district_id": "330911",
    "label": "Donohudan",
    "value": "Donohudan"
  },
  {
    "id": "3309112006",
    "district_id": "330911",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3309112007",
    "district_id": "330911",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3309112008",
    "district_id": "330911",
    "label": "Kismoyoso",
    "value": "Kismoyoso"
  },
  {
    "id": "3309112009",
    "district_id": "330911",
    "label": "Dibal",
    "value": "Dibal"
  },
  {
    "id": "3309112010",
    "district_id": "330911",
    "label": "Sindon",
    "value": "Sindon"
  },
  {
    "id": "3309112011",
    "district_id": "330911",
    "label": "Manggung",
    "value": "Manggung"
  },
  {
    "id": "3309112012",
    "district_id": "330911",
    "label": "Giriroto",
    "value": "Giriroto"
  },
  {
    "id": "3309122001",
    "district_id": "330912",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3309122002",
    "district_id": "330912",
    "label": "Potronayan",
    "value": "Potronayan"
  },
  {
    "id": "3309122003",
    "district_id": "330912",
    "label": "Sembungan",
    "value": "Sembungan"
  },
  {
    "id": "3309122004",
    "district_id": "330912",
    "label": "Jeron",
    "value": "Jeron"
  },
  {
    "id": "3309122005",
    "district_id": "330912",
    "label": "Ketitang",
    "value": "Ketitang"
  },
  {
    "id": "3309122006",
    "district_id": "330912",
    "label": "Rembun",
    "value": "Rembun"
  },
  {
    "id": "3309122007",
    "district_id": "330912",
    "label": "Guli",
    "value": "Guli"
  },
  {
    "id": "3309122008",
    "district_id": "330912",
    "label": "Tegalgiri",
    "value": "Tegalgiri"
  },
  {
    "id": "3309122009",
    "district_id": "330912",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3309122010",
    "district_id": "330912",
    "label": "Keyongan",
    "value": "Keyongan"
  },
  {
    "id": "3309122011",
    "district_id": "330912",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3309122012",
    "district_id": "330912",
    "label": "Glonggong",
    "value": "Glonggong"
  },
  {
    "id": "3309122013",
    "district_id": "330912",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "3309132001",
    "district_id": "330913",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3309132002",
    "district_id": "330913",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3309132003",
    "district_id": "330913",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3309132004",
    "district_id": "330913",
    "label": "Teter",
    "value": "Teter"
  },
  {
    "id": "3309132005",
    "district_id": "330913",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3309132006",
    "district_id": "330913",
    "label": "Walen",
    "value": "Walen"
  },
  {
    "id": "3309132007",
    "district_id": "330913",
    "label": "Pentur",
    "value": "Pentur"
  },
  {
    "id": "3309132008",
    "district_id": "330913",
    "label": "Gunung",
    "value": "Gunung"
  },
  {
    "id": "3309132009",
    "district_id": "330913",
    "label": "Talakbroto",
    "value": "Talakbroto"
  },
  {
    "id": "3309132010",
    "district_id": "330913",
    "label": "Kedunglengkong",
    "value": "Kedunglengkong"
  },
  {
    "id": "3309132011",
    "district_id": "330913",
    "label": "Blagung",
    "value": "Blagung"
  },
  {
    "id": "3309132012",
    "district_id": "330913",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3309132013",
    "district_id": "330913",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3309142001",
    "district_id": "330914",
    "label": "Manyaran",
    "value": "Manyaran"
  },
  {
    "id": "3309142002",
    "district_id": "330914",
    "label": "Sempulur",
    "value": "Sempulur"
  },
  {
    "id": "3309142003",
    "district_id": "330914",
    "label": "Klumpit",
    "value": "Klumpit"
  },
  {
    "id": "3309142004",
    "district_id": "330914",
    "label": "Pinggir",
    "value": "Pinggir"
  },
  {
    "id": "3309142005",
    "district_id": "330914",
    "label": "Bantengan",
    "value": "Bantengan"
  },
  {
    "id": "3309142006",
    "district_id": "330914",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3309142007",
    "district_id": "330914",
    "label": "Sranten",
    "value": "Sranten"
  },
  {
    "id": "3309142008",
    "district_id": "330914",
    "label": "Grogolan",
    "value": "Grogolan"
  },
  {
    "id": "3309142009",
    "district_id": "330914",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3309142010",
    "district_id": "330914",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3309142011",
    "district_id": "330914",
    "label": "Karangkepoh",
    "value": "Karangkepoh"
  },
  {
    "id": "3309142012",
    "district_id": "330914",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3309142013",
    "district_id": "330914",
    "label": "Kebonan",
    "value": "Kebonan"
  },
  {
    "id": "3309142014",
    "district_id": "330914",
    "label": "Klari",
    "value": "Klari"
  },
  {
    "id": "3309142015",
    "district_id": "330914",
    "label": "Bangkok",
    "value": "Bangkok"
  },
  {
    "id": "3309142016",
    "district_id": "330914",
    "label": "Dologan",
    "value": "Dologan"
  },
  {
    "id": "3309152001",
    "district_id": "330915",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "3309152002",
    "district_id": "330915",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3309152003",
    "district_id": "330915",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3309152004",
    "district_id": "330915",
    "label": "Jaten",
    "value": "Jaten"
  },
  {
    "id": "3309152005",
    "district_id": "330915",
    "label": "Blumbang",
    "value": "Blumbang"
  },
  {
    "id": "3309152006",
    "district_id": "330915",
    "label": "Sangge",
    "value": "Sangge"
  },
  {
    "id": "3309152007",
    "district_id": "330915",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3309152008",
    "district_id": "330915",
    "label": "Bade",
    "value": "Bade"
  },
  {
    "id": "3309152009",
    "district_id": "330915",
    "label": "Klego",
    "value": "Klego"
  },
  {
    "id": "3309152010",
    "district_id": "330915",
    "label": "Gondanglegi",
    "value": "Gondanglegi"
  },
  {
    "id": "3309152011",
    "district_id": "330915",
    "label": "Karanggatak",
    "value": "Karanggatak"
  },
  {
    "id": "3309152012",
    "district_id": "330915",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3309152013",
    "district_id": "330915",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3309162001",
    "district_id": "330916",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3309162002",
    "district_id": "330916",
    "label": "Gondangrawe",
    "value": "Gondangrawe"
  },
  {
    "id": "3309162003",
    "district_id": "330916",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3309162004",
    "district_id": "330916",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3309162005",
    "district_id": "330916",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3309162006",
    "district_id": "330916",
    "label": "Senggrong",
    "value": "Senggrong"
  },
  {
    "id": "3309162007",
    "district_id": "330916",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3309162008",
    "district_id": "330916",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3309162009",
    "district_id": "330916",
    "label": "Andong",
    "value": "Andong"
  },
  {
    "id": "3309162010",
    "district_id": "330916",
    "label": "Munggur",
    "value": "Munggur"
  },
  {
    "id": "3309162011",
    "district_id": "330916",
    "label": "Pakang",
    "value": "Pakang"
  },
  {
    "id": "3309162012",
    "district_id": "330916",
    "label": "Pranggong",
    "value": "Pranggong"
  },
  {
    "id": "3309162013",
    "district_id": "330916",
    "label": "Kunti",
    "value": "Kunti"
  },
  {
    "id": "3309162014",
    "district_id": "330916",
    "label": "Pelemrejo",
    "value": "Pelemrejo"
  },
  {
    "id": "3309162015",
    "district_id": "330916",
    "label": "Semawung",
    "value": "Semawung"
  },
  {
    "id": "3309162016",
    "district_id": "330916",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3309172001",
    "district_id": "330917",
    "label": "Watugede",
    "value": "Watugede"
  },
  {
    "id": "3309172002",
    "district_id": "330917",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3309172003",
    "district_id": "330917",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "3309172004",
    "district_id": "330917",
    "label": "Klewor",
    "value": "Klewor"
  },
  {
    "id": "3309172005",
    "district_id": "330917",
    "label": "Bawu",
    "value": "Bawu"
  },
  {
    "id": "3309172006",
    "district_id": "330917",
    "label": "Kendel",
    "value": "Kendel"
  },
  {
    "id": "3309172010",
    "district_id": "330917",
    "label": "Kemusu",
    "value": "Kemusu"
  },
  {
    "id": "3309172011",
    "district_id": "330917",
    "label": "Genengsari",
    "value": "Genengsari"
  },
  {
    "id": "3309172012",
    "district_id": "330917",
    "label": "Kedungmulyo",
    "value": "Kedungmulyo"
  },
  {
    "id": "3309172013",
    "district_id": "330917",
    "label": "Wonoharjo",
    "value": "Wonoharjo"
  },
  {
    "id": "3309182002",
    "district_id": "330918",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3309182003",
    "district_id": "330918",
    "label": "Ketoyan",
    "value": "Ketoyan"
  },
  {
    "id": "3309182004",
    "district_id": "330918",
    "label": "Bolo",
    "value": "Bolo"
  },
  {
    "id": "3309182005",
    "district_id": "330918",
    "label": "Banyusri",
    "value": "Banyusri"
  },
  {
    "id": "3309182006",
    "district_id": "330918",
    "label": "Gosono",
    "value": "Gosono"
  },
  {
    "id": "3309182007",
    "district_id": "330918",
    "label": "Wonosegoro",
    "value": "Wonosegoro"
  },
  {
    "id": "3309182008",
    "district_id": "330918",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3309182014",
    "district_id": "330918",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3309182019",
    "district_id": "330918",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3309182020",
    "district_id": "330918",
    "label": "Lemahireng",
    "value": "Lemahireng"
  },
  {
    "id": "3309182021",
    "district_id": "330918",
    "label": "Guwo",
    "value": "Guwo"
  },
  {
    "id": "3309191006",
    "district_id": "330919",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3309192001",
    "district_id": "330919",
    "label": "Krobokan",
    "value": "Krobokan"
  },
  {
    "id": "3309192002",
    "district_id": "330919",
    "label": "Ngaren",
    "value": "Ngaren"
  },
  {
    "id": "3309192003",
    "district_id": "330919",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3309192004",
    "district_id": "330919",
    "label": "Kayen",
    "value": "Kayen"
  },
  {
    "id": "3309192005",
    "district_id": "330919",
    "label": "Jerukan",
    "value": "Jerukan"
  },
  {
    "id": "3309192007",
    "district_id": "330919",
    "label": "Pilangrejo",
    "value": "Pilangrejo"
  },
  {
    "id": "3309192008",
    "district_id": "330919",
    "label": "Cerme",
    "value": "Cerme"
  },
  {
    "id": "3309192009",
    "district_id": "330919",
    "label": "Juwangi",
    "value": "Juwangi"
  },
  {
    "id": "3309192010",
    "district_id": "330919",
    "label": "Ngleses",
    "value": "Ngleses"
  },
  {
    "id": "3309202001",
    "district_id": "330920",
    "label": "Seboto",
    "value": "Seboto"
  },
  {
    "id": "3309202002",
    "district_id": "330920",
    "label": "Kaligentong",
    "value": "Kaligentong"
  },
  {
    "id": "3309202003",
    "district_id": "330920",
    "label": "Gladagsari",
    "value": "Gladagsari"
  },
  {
    "id": "3309202004",
    "district_id": "330920",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3309202005",
    "district_id": "330920",
    "label": "Ngagrong",
    "value": "Ngagrong"
  },
  {
    "id": "3309202006",
    "district_id": "330920",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3309202007",
    "district_id": "330920",
    "label": "Ngargoloka",
    "value": "Ngargoloka"
  },
  {
    "id": "3309202008",
    "district_id": "330920",
    "label": "Sampetan",
    "value": "Sampetan"
  },
  {
    "id": "3309202009",
    "district_id": "330920",
    "label": "Ngadirojo",
    "value": "Ngadirojo"
  },
  {
    "id": "3309202010",
    "district_id": "330920",
    "label": "Jlarem",
    "value": "Jlarem"
  },
  {
    "id": "3309212001",
    "district_id": "330921",
    "label": "Lampar",
    "value": "Lampar"
  },
  {
    "id": "3309212002",
    "district_id": "330921",
    "label": "Dragan",
    "value": "Dragan"
  },
  {
    "id": "3309212003",
    "district_id": "330921",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3309212004",
    "district_id": "330921",
    "label": "Jemowo",
    "value": "Jemowo"
  },
  {
    "id": "3309212005",
    "district_id": "330921",
    "label": "Sumur",
    "value": "Sumur"
  },
  {
    "id": "3309212006",
    "district_id": "330921",
    "label": "Sangup",
    "value": "Sangup"
  },
  {
    "id": "3309212007",
    "district_id": "330921",
    "label": "Mriyan",
    "value": "Mriyan"
  },
  {
    "id": "3309212008",
    "district_id": "330921",
    "label": "Lanjaran",
    "value": "Lanjaran"
  },
  {
    "id": "3309212009",
    "district_id": "330921",
    "label": "Karangkendal",
    "value": "Karangkendal"
  },
  {
    "id": "3309212010",
    "district_id": "330921",
    "label": "Keposong",
    "value": "Keposong"
  },
  {
    "id": "3309222001",
    "district_id": "330922",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "3309222002",
    "district_id": "330922",
    "label": "Kedungpilang",
    "value": "Kedungpilang"
  },
  {
    "id": "3309222003",
    "district_id": "330922",
    "label": "Kalinanas",
    "value": "Kalinanas"
  },
  {
    "id": "3309222004",
    "district_id": "330922",
    "label": "Gilirejo",
    "value": "Gilirejo"
  },
  {
    "id": "3309222005",
    "district_id": "330922",
    "label": "Jatilawang",
    "value": "Jatilawang"
  },
  {
    "id": "3309222006",
    "district_id": "330922",
    "label": "Garangan",
    "value": "Garangan"
  },
  {
    "id": "3309222007",
    "district_id": "330922",
    "label": "Bercak",
    "value": "Bercak"
  },
  {
    "id": "3309222008",
    "district_id": "330922",
    "label": "Bengle",
    "value": "Bengle"
  },
  {
    "id": "3309222009",
    "district_id": "330922",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3309222010",
    "district_id": "330922",
    "label": "Repaking",
    "value": "Repaking"
  },
  {
    "id": "3310012001",
    "district_id": "331001",
    "label": "Sengon",
    "value": "Sengon"
  },
  {
    "id": "3310012002",
    "district_id": "331001",
    "label": "Cucukan",
    "value": "Cucukan"
  },
  {
    "id": "3310012003",
    "district_id": "331001",
    "label": "Kotesan",
    "value": "Kotesan"
  },
  {
    "id": "3310012004",
    "district_id": "331001",
    "label": "Pereng",
    "value": "Pereng"
  },
  {
    "id": "3310012005",
    "district_id": "331001",
    "label": "Kebondalem Kidul",
    "value": "Kebondalem Kidul"
  },
  {
    "id": "3310012006",
    "district_id": "331001",
    "label": "Tlogo",
    "value": "Tlogo"
  },
  {
    "id": "3310012007",
    "district_id": "331001",
    "label": "Taji",
    "value": "Taji"
  },
  {
    "id": "3310012008",
    "district_id": "331001",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3310012009",
    "district_id": "331001",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3310012010",
    "district_id": "331001",
    "label": "Kemudo",
    "value": "Kemudo"
  },
  {
    "id": "3310012011",
    "district_id": "331001",
    "label": "Bugisan",
    "value": "Bugisan"
  },
  {
    "id": "3310012012",
    "district_id": "331001",
    "label": "Kokosan",
    "value": "Kokosan"
  },
  {
    "id": "3310012013",
    "district_id": "331001",
    "label": "Kebondalem Lor",
    "value": "Kebondalem Lor"
  },
  {
    "id": "3310012014",
    "district_id": "331001",
    "label": "Brajan",
    "value": "Brajan"
  },
  {
    "id": "3310012015",
    "district_id": "331001",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3310012016",
    "district_id": "331001",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3310022001",
    "district_id": "331002",
    "label": "Gentan",
    "value": "Gentan"
  },
  {
    "id": "3310022002",
    "district_id": "331002",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3310022003",
    "district_id": "331002",
    "label": "Jogoprayan",
    "value": "Jogoprayan"
  },
  {
    "id": "3310022004",
    "district_id": "331002",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3310022005",
    "district_id": "331002",
    "label": "Ngandong",
    "value": "Ngandong"
  },
  {
    "id": "3310022006",
    "district_id": "331002",
    "label": "Kerten",
    "value": "Kerten"
  },
  {
    "id": "3310022007",
    "district_id": "331002",
    "label": "Katekan",
    "value": "Katekan"
  },
  {
    "id": "3310022008",
    "district_id": "331002",
    "label": "Sawit",
    "value": "Sawit"
  },
  {
    "id": "3310022009",
    "district_id": "331002",
    "label": "Muruh",
    "value": "Muruh"
  },
  {
    "id": "3310022010",
    "district_id": "331002",
    "label": "Mutihan",
    "value": "Mutihan"
  },
  {
    "id": "3310022011",
    "district_id": "331002",
    "label": "Baturan",
    "value": "Baturan"
  },
  {
    "id": "3310022012",
    "district_id": "331002",
    "label": "Mlese",
    "value": "Mlese"
  },
  {
    "id": "3310022013",
    "district_id": "331002",
    "label": "Gesikan",
    "value": "Gesikan"
  },
  {
    "id": "3310022014",
    "district_id": "331002",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3310022015",
    "district_id": "331002",
    "label": "Towangsan",
    "value": "Towangsan"
  },
  {
    "id": "3310022016",
    "district_id": "331002",
    "label": "Ceporan",
    "value": "Ceporan"
  },
  {
    "id": "3310032001",
    "district_id": "331003",
    "label": "Pesu",
    "value": "Pesu"
  },
  {
    "id": "3310032002",
    "district_id": "331003",
    "label": "Dengkeng",
    "value": "Dengkeng"
  },
  {
    "id": "3310032003",
    "district_id": "331003",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3310032004",
    "district_id": "331003",
    "label": "Kadilanggon",
    "value": "Kadilanggon"
  },
  {
    "id": "3310032005",
    "district_id": "331003",
    "label": "Kaligayam",
    "value": "Kaligayam"
  },
  {
    "id": "3310032006",
    "district_id": "331003",
    "label": "Melikan",
    "value": "Melikan"
  },
  {
    "id": "3310032007",
    "district_id": "331003",
    "label": "Jiwowetan",
    "value": "Jiwowetan"
  },
  {
    "id": "3310032008",
    "district_id": "331003",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3310032009",
    "district_id": "331003",
    "label": "Pasung",
    "value": "Pasung"
  },
  {
    "id": "3310032010",
    "district_id": "331003",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "3310032011",
    "district_id": "331003",
    "label": "Canan",
    "value": "Canan"
  },
  {
    "id": "3310032012",
    "district_id": "331003",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3310032013",
    "district_id": "331003",
    "label": "Gadungan",
    "value": "Gadungan"
  },
  {
    "id": "3310032014",
    "district_id": "331003",
    "label": "Pandes",
    "value": "Pandes"
  },
  {
    "id": "3310032015",
    "district_id": "331003",
    "label": "Birit",
    "value": "Birit"
  },
  {
    "id": "3310032016",
    "district_id": "331003",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3310032017",
    "district_id": "331003",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3310032018",
    "district_id": "331003",
    "label": "Trotok",
    "value": "Trotok"
  },
  {
    "id": "3310032019",
    "district_id": "331003",
    "label": "Kadibolo",
    "value": "Kadibolo"
  },
  {
    "id": "3310042001",
    "district_id": "331004",
    "label": "Ngerangan",
    "value": "Ngerangan"
  },
  {
    "id": "3310042002",
    "district_id": "331004",
    "label": "Jambakan",
    "value": "Jambakan"
  },
  {
    "id": "3310042003",
    "district_id": "331004",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3310042004",
    "district_id": "331004",
    "label": "Jarum",
    "value": "Jarum"
  },
  {
    "id": "3310042005",
    "district_id": "331004",
    "label": "Nengahan",
    "value": "Nengahan"
  },
  {
    "id": "3310042006",
    "district_id": "331004",
    "label": "Bogem",
    "value": "Bogem"
  },
  {
    "id": "3310042007",
    "district_id": "331004",
    "label": "Paseban",
    "value": "Paseban"
  },
  {
    "id": "3310042008",
    "district_id": "331004",
    "label": "Krikilan",
    "value": "Krikilan"
  },
  {
    "id": "3310042009",
    "district_id": "331004",
    "label": "Beluk",
    "value": "Beluk"
  },
  {
    "id": "3310042010",
    "district_id": "331004",
    "label": "Banyuripan",
    "value": "Banyuripan"
  },
  {
    "id": "3310042011",
    "district_id": "331004",
    "label": "Gununggajah",
    "value": "Gununggajah"
  },
  {
    "id": "3310042012",
    "district_id": "331004",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3310042013",
    "district_id": "331004",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "3310042014",
    "district_id": "331004",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3310042015",
    "district_id": "331004",
    "label": "Kebon",
    "value": "Kebon"
  },
  {
    "id": "3310042016",
    "district_id": "331004",
    "label": "Jotangan",
    "value": "Jotangan"
  },
  {
    "id": "3310042017",
    "district_id": "331004",
    "label": "Krakitan",
    "value": "Krakitan"
  },
  {
    "id": "3310042018",
    "district_id": "331004",
    "label": "Wiro",
    "value": "Wiro"
  },
  {
    "id": "3310052001",
    "district_id": "331005",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3310052002",
    "district_id": "331005",
    "label": "Burikan",
    "value": "Burikan"
  },
  {
    "id": "3310052003",
    "district_id": "331005",
    "label": "Nanggulan",
    "value": "Nanggulan"
  },
  {
    "id": "3310052004",
    "district_id": "331005",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3310052005",
    "district_id": "331005",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3310052006",
    "district_id": "331005",
    "label": "Kedungampel",
    "value": "Kedungampel"
  },
  {
    "id": "3310052007",
    "district_id": "331005",
    "label": "Bawak",
    "value": "Bawak"
  },
  {
    "id": "3310052008",
    "district_id": "331005",
    "label": "Barepan",
    "value": "Barepan"
  },
  {
    "id": "3310052009",
    "district_id": "331005",
    "label": "Pakisan",
    "value": "Pakisan"
  },
  {
    "id": "3310052010",
    "district_id": "331005",
    "label": "Balak",
    "value": "Balak"
  },
  {
    "id": "3310052011",
    "district_id": "331005",
    "label": "Cawas",
    "value": "Cawas"
  },
  {
    "id": "3310052012",
    "district_id": "331005",
    "label": "Plosowangi",
    "value": "Plosowangi"
  },
  {
    "id": "3310052013",
    "district_id": "331005",
    "label": "Baran",
    "value": "Baran"
  },
  {
    "id": "3310052014",
    "district_id": "331005",
    "label": "Tirtomarto",
    "value": "Tirtomarto"
  },
  {
    "id": "3310052015",
    "district_id": "331005",
    "label": "Japanan",
    "value": "Japanan"
  },
  {
    "id": "3310052016",
    "district_id": "331005",
    "label": "Tlingsing",
    "value": "Tlingsing"
  },
  {
    "id": "3310052017",
    "district_id": "331005",
    "label": "Mlese",
    "value": "Mlese"
  },
  {
    "id": "3310052018",
    "district_id": "331005",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3310052019",
    "district_id": "331005",
    "label": "Pogung",
    "value": "Pogung"
  },
  {
    "id": "3310052020",
    "district_id": "331005",
    "label": "Bogor",
    "value": "Bogor"
  },
  {
    "id": "3310062001",
    "district_id": "331006",
    "label": "Karangpakel",
    "value": "Karangpakel"
  },
  {
    "id": "3310062002",
    "district_id": "331006",
    "label": "Wanglu",
    "value": "Wanglu"
  },
  {
    "id": "3310062003",
    "district_id": "331006",
    "label": "Trucuk",
    "value": "Trucuk"
  },
  {
    "id": "3310062004",
    "district_id": "331006",
    "label": "Kalikebo",
    "value": "Kalikebo"
  },
  {
    "id": "3310062005",
    "district_id": "331006",
    "label": "Gaden",
    "value": "Gaden"
  },
  {
    "id": "3310062006",
    "district_id": "331006",
    "label": "Planggu",
    "value": "Planggu"
  },
  {
    "id": "3310062007",
    "district_id": "331006",
    "label": "Pundungsari",
    "value": "Pundungsari"
  },
  {
    "id": "3310062008",
    "district_id": "331006",
    "label": "Sajen",
    "value": "Sajen"
  },
  {
    "id": "3310062009",
    "district_id": "331006",
    "label": "Puluhan",
    "value": "Puluhan"
  },
  {
    "id": "3310062010",
    "district_id": "331006",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3310062011",
    "district_id": "331006",
    "label": "Sabranglor",
    "value": "Sabranglor"
  },
  {
    "id": "3310062012",
    "district_id": "331006",
    "label": "Jatipuro",
    "value": "Jatipuro"
  },
  {
    "id": "3310062013",
    "district_id": "331006",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3310062014",
    "district_id": "331006",
    "label": "Mireng",
    "value": "Mireng"
  },
  {
    "id": "3310062015",
    "district_id": "331006",
    "label": "Bero",
    "value": "Bero"
  },
  {
    "id": "3310062016",
    "district_id": "331006",
    "label": "Mandong",
    "value": "Mandong"
  },
  {
    "id": "3310062017",
    "district_id": "331006",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3310062018",
    "district_id": "331006",
    "label": "Palar",
    "value": "Palar"
  },
  {
    "id": "3310072001",
    "district_id": "331007",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3310072002",
    "district_id": "331007",
    "label": "Basin",
    "value": "Basin"
  },
  {
    "id": "3310072003",
    "district_id": "331007",
    "label": "Pluneng",
    "value": "Pluneng"
  },
  {
    "id": "3310072004",
    "district_id": "331007",
    "label": "Ngrundul",
    "value": "Ngrundul"
  },
  {
    "id": "3310072005",
    "district_id": "331007",
    "label": "Malangjiwan",
    "value": "Malangjiwan"
  },
  {
    "id": "3310072006",
    "district_id": "331007",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3310072007",
    "district_id": "331007",
    "label": "Menden",
    "value": "Menden"
  },
  {
    "id": "3310082001",
    "district_id": "331008",
    "label": "Pakahan",
    "value": "Pakahan"
  },
  {
    "id": "3310082002",
    "district_id": "331008",
    "label": "Ngering",
    "value": "Ngering"
  },
  {
    "id": "3310082003",
    "district_id": "331008",
    "label": "Rejoso",
    "value": "Rejoso"
  },
  {
    "id": "3310082004",
    "district_id": "331008",
    "label": "Titang",
    "value": "Titang"
  },
  {
    "id": "3310082005",
    "district_id": "331008",
    "label": "Somopuro",
    "value": "Somopuro"
  },
  {
    "id": "3310082006",
    "district_id": "331008",
    "label": "Tangkisan Pos",
    "value": "Tangkisan Pos"
  },
  {
    "id": "3310082007",
    "district_id": "331008",
    "label": "Gondangan",
    "value": "Gondangan"
  },
  {
    "id": "3310082008",
    "district_id": "331008",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3310082009",
    "district_id": "331008",
    "label": "Sumyang",
    "value": "Sumyang"
  },
  {
    "id": "3310082010",
    "district_id": "331008",
    "label": "Karangdukuh",
    "value": "Karangdukuh"
  },
  {
    "id": "3310082011",
    "district_id": "331008",
    "label": "Plawikan",
    "value": "Plawikan"
  },
  {
    "id": "3310082012",
    "district_id": "331008",
    "label": "Kraguman",
    "value": "Kraguman"
  },
  {
    "id": "3310082013",
    "district_id": "331008",
    "label": "Granting",
    "value": "Granting"
  },
  {
    "id": "3310082014",
    "district_id": "331008",
    "label": "Prawatan",
    "value": "Prawatan"
  },
  {
    "id": "3310082015",
    "district_id": "331008",
    "label": "Wonoboyo",
    "value": "Wonoboyo"
  },
  {
    "id": "3310082016",
    "district_id": "331008",
    "label": "Dompyongan",
    "value": "Dompyongan"
  },
  {
    "id": "3310082017",
    "district_id": "331008",
    "label": "Joton",
    "value": "Joton"
  },
  {
    "id": "3310082018",
    "district_id": "331008",
    "label": "Tambakan",
    "value": "Tambakan"
  },
  {
    "id": "3310092001",
    "district_id": "331009",
    "label": "Nangsri",
    "value": "Nangsri"
  },
  {
    "id": "3310092002",
    "district_id": "331009",
    "label": "Barukan",
    "value": "Barukan"
  },
  {
    "id": "3310092003",
    "district_id": "331009",
    "label": "Borangan",
    "value": "Borangan"
  },
  {
    "id": "3310092004",
    "district_id": "331009",
    "label": "Sukorini",
    "value": "Sukorini"
  },
  {
    "id": "3310092005",
    "district_id": "331009",
    "label": "Ngemplakseneng",
    "value": "Ngemplakseneng"
  },
  {
    "id": "3310092006",
    "district_id": "331009",
    "label": "Sapen",
    "value": "Sapen"
  },
  {
    "id": "3310092007",
    "district_id": "331009",
    "label": "Kecemen",
    "value": "Kecemen"
  },
  {
    "id": "3310092008",
    "district_id": "331009",
    "label": "Tijayan",
    "value": "Tijayan"
  },
  {
    "id": "3310092009",
    "district_id": "331009",
    "label": "Bendan",
    "value": "Bendan"
  },
  {
    "id": "3310092010",
    "district_id": "331009",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3310092011",
    "district_id": "331009",
    "label": "Solodiran",
    "value": "Solodiran"
  },
  {
    "id": "3310092012",
    "district_id": "331009",
    "label": "Taskombang",
    "value": "Taskombang"
  },
  {
    "id": "3310092013",
    "district_id": "331009",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3310092014",
    "district_id": "331009",
    "label": "Kebonalas",
    "value": "Kebonalas"
  },
  {
    "id": "3310092015",
    "district_id": "331009",
    "label": "Leses",
    "value": "Leses"
  },
  {
    "id": "3310092016",
    "district_id": "331009",
    "label": "Kepurun",
    "value": "Kepurun"
  },
  {
    "id": "3310102001",
    "district_id": "331010",
    "label": "Gumul",
    "value": "Gumul"
  },
  {
    "id": "3310102002",
    "district_id": "331010",
    "label": "Banyuaeng",
    "value": "Banyuaeng"
  },
  {
    "id": "3310102003",
    "district_id": "331010",
    "label": "Kadilajo",
    "value": "Kadilajo"
  },
  {
    "id": "3310102004",
    "district_id": "331010",
    "label": "Somokaton",
    "value": "Somokaton"
  },
  {
    "id": "3310102005",
    "district_id": "331010",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3310102006",
    "district_id": "331010",
    "label": "Karangnongko",
    "value": "Karangnongko"
  },
  {
    "id": "3310102007",
    "district_id": "331010",
    "label": "Jagalan",
    "value": "Jagalan"
  },
  {
    "id": "3310102008",
    "district_id": "331010",
    "label": "Demakijo",
    "value": "Demakijo"
  },
  {
    "id": "3310102009",
    "district_id": "331010",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3310102010",
    "district_id": "331010",
    "label": "Kanoman",
    "value": "Kanoman"
  },
  {
    "id": "3310102011",
    "district_id": "331010",
    "label": "Gemampir",
    "value": "Gemampir"
  },
  {
    "id": "3310102012",
    "district_id": "331010",
    "label": "Jiwan",
    "value": "Jiwan"
  },
  {
    "id": "3310102013",
    "district_id": "331010",
    "label": "Logede",
    "value": "Logede"
  },
  {
    "id": "3310102014",
    "district_id": "331010",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3310112001",
    "district_id": "331011",
    "label": "Srebegan",
    "value": "Srebegan"
  },
  {
    "id": "3310112002",
    "district_id": "331011",
    "label": "Kajen",
    "value": "Kajen"
  },
  {
    "id": "3310112003",
    "district_id": "331011",
    "label": "Jambukidul",
    "value": "Jambukidul"
  },
  {
    "id": "3310112004",
    "district_id": "331011",
    "label": "Kujon",
    "value": "Kujon"
  },
  {
    "id": "3310112005",
    "district_id": "331011",
    "label": "Pokak",
    "value": "Pokak"
  },
  {
    "id": "3310112006",
    "district_id": "331011",
    "label": "Pasungan",
    "value": "Pasungan"
  },
  {
    "id": "3310112007",
    "district_id": "331011",
    "label": "Mlese",
    "value": "Mlese"
  },
  {
    "id": "3310112008",
    "district_id": "331011",
    "label": "Jombor",
    "value": "Jombor"
  },
  {
    "id": "3310112009",
    "district_id": "331011",
    "label": "Meger",
    "value": "Meger"
  },
  {
    "id": "3310112010",
    "district_id": "331011",
    "label": "Dlimas",
    "value": "Dlimas"
  },
  {
    "id": "3310112011",
    "district_id": "331011",
    "label": "Jambukulon",
    "value": "Jambukulon"
  },
  {
    "id": "3310112012",
    "district_id": "331011",
    "label": "Ceper",
    "value": "Ceper"
  },
  {
    "id": "3310112013",
    "district_id": "331011",
    "label": "Kurung",
    "value": "Kurung"
  },
  {
    "id": "3310112014",
    "district_id": "331011",
    "label": "Cetan",
    "value": "Cetan"
  },
  {
    "id": "3310112015",
    "district_id": "331011",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3310112016",
    "district_id": "331011",
    "label": "Ngawonggo",
    "value": "Ngawonggo"
  },
  {
    "id": "3310112017",
    "district_id": "331011",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3310112018",
    "district_id": "331011",
    "label": "Kuncen",
    "value": "Kuncen"
  },
  {
    "id": "3310122001",
    "district_id": "331012",
    "label": "Temuwangi",
    "value": "Temuwangi"
  },
  {
    "id": "3310122002",
    "district_id": "331012",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3310122003",
    "district_id": "331012",
    "label": "Ngaren",
    "value": "Ngaren"
  },
  {
    "id": "3310122004",
    "district_id": "331012",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3310122005",
    "district_id": "331012",
    "label": "Jetiswetan",
    "value": "Jetiswetan"
  },
  {
    "id": "3310122006",
    "district_id": "331012",
    "label": "Keden",
    "value": "Keden"
  },
  {
    "id": "3310122007",
    "district_id": "331012",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3310122008",
    "district_id": "331012",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "3310122009",
    "district_id": "331012",
    "label": "Kedungan",
    "value": "Kedungan"
  },
  {
    "id": "3310122010",
    "district_id": "331012",
    "label": "Sobayan",
    "value": "Sobayan"
  },
  {
    "id": "3310122011",
    "district_id": "331012",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "3310122012",
    "district_id": "331012",
    "label": "Troketon",
    "value": "Troketon"
  },
  {
    "id": "3310122013",
    "district_id": "331012",
    "label": "Kaligawe",
    "value": "Kaligawe"
  },
  {
    "id": "3310122014",
    "district_id": "331012",
    "label": "Lemahireng",
    "value": "Lemahireng"
  },
  {
    "id": "3310132001",
    "district_id": "331013",
    "label": "Tulas",
    "value": "Tulas"
  },
  {
    "id": "3310132002",
    "district_id": "331013",
    "label": "Bulusan",
    "value": "Bulusan"
  },
  {
    "id": "3310132003",
    "district_id": "331013",
    "label": "Ringinputih",
    "value": "Ringinputih"
  },
  {
    "id": "3310132004",
    "district_id": "331013",
    "label": "Soka",
    "value": "Soka"
  },
  {
    "id": "3310132005",
    "district_id": "331013",
    "label": "Tumpukan",
    "value": "Tumpukan"
  },
  {
    "id": "3310132006",
    "district_id": "331013",
    "label": "Karangjoho",
    "value": "Karangjoho"
  },
  {
    "id": "3310132007",
    "district_id": "331013",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3310132008",
    "district_id": "331013",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3310132009",
    "district_id": "331013",
    "label": "Karangdowo",
    "value": "Karangdowo"
  },
  {
    "id": "3310132010",
    "district_id": "331013",
    "label": "Munggung",
    "value": "Munggung"
  },
  {
    "id": "3310132011",
    "district_id": "331013",
    "label": "Sentono",
    "value": "Sentono"
  },
  {
    "id": "3310132012",
    "district_id": "331013",
    "label": "Pugeran",
    "value": "Pugeran"
  },
  {
    "id": "3310132013",
    "district_id": "331013",
    "label": "Ngolodono",
    "value": "Ngolodono"
  },
  {
    "id": "3310132014",
    "district_id": "331013",
    "label": "Karangwungu",
    "value": "Karangwungu"
  },
  {
    "id": "3310132015",
    "district_id": "331013",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3310132016",
    "district_id": "331013",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3310132017",
    "district_id": "331013",
    "label": "Tegalampel",
    "value": "Tegalampel"
  },
  {
    "id": "3310132018",
    "district_id": "331013",
    "label": "Bakungan",
    "value": "Bakungan"
  },
  {
    "id": "3310132019",
    "district_id": "331013",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "3310142001",
    "district_id": "331014",
    "label": "Trasan",
    "value": "Trasan"
  },
  {
    "id": "3310142002",
    "district_id": "331014",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3310142003",
    "district_id": "331014",
    "label": "Juwiran",
    "value": "Juwiran"
  },
  {
    "id": "3310142004",
    "district_id": "331014",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3310142005",
    "district_id": "331014",
    "label": "Ketitang",
    "value": "Ketitang"
  },
  {
    "id": "3310142006",
    "district_id": "331014",
    "label": "Gondangsari",
    "value": "Gondangsari"
  },
  {
    "id": "3310142007",
    "district_id": "331014",
    "label": "Serenan",
    "value": "Serenan"
  },
  {
    "id": "3310142008",
    "district_id": "331014",
    "label": "Tlogorandu",
    "value": "Tlogorandu"
  },
  {
    "id": "3310142009",
    "district_id": "331014",
    "label": "Bolopleret",
    "value": "Bolopleret"
  },
  {
    "id": "3310142010",
    "district_id": "331014",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3310142011",
    "district_id": "331014",
    "label": "Kenaiban",
    "value": "Kenaiban"
  },
  {
    "id": "3310142012",
    "district_id": "331014",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3310142013",
    "district_id": "331014",
    "label": "Jaten",
    "value": "Jaten"
  },
  {
    "id": "3310142014",
    "district_id": "331014",
    "label": "Mrisen",
    "value": "Mrisen"
  },
  {
    "id": "3310142015",
    "district_id": "331014",
    "label": "Pundungan",
    "value": "Pundungan"
  },
  {
    "id": "3310142016",
    "district_id": "331014",
    "label": "Juwiring",
    "value": "Juwiring"
  },
  {
    "id": "3310142017",
    "district_id": "331014",
    "label": "Kwarasan",
    "value": "Kwarasan"
  },
  {
    "id": "3310142018",
    "district_id": "331014",
    "label": "Carikan",
    "value": "Carikan"
  },
  {
    "id": "3310142019",
    "district_id": "331014",
    "label": "Taji",
    "value": "Taji"
  },
  {
    "id": "3310152001",
    "district_id": "331015",
    "label": "Sidowarno",
    "value": "Sidowarno"
  },
  {
    "id": "3310152002",
    "district_id": "331015",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3310152003",
    "district_id": "331015",
    "label": "Gunting",
    "value": "Gunting"
  },
  {
    "id": "3310152004",
    "district_id": "331015",
    "label": "Kingkang",
    "value": "Kingkang"
  },
  {
    "id": "3310152005",
    "district_id": "331015",
    "label": "Jelobo",
    "value": "Jelobo"
  },
  {
    "id": "3310152006",
    "district_id": "331015",
    "label": "Lumbungkerep",
    "value": "Lumbungkerep"
  },
  {
    "id": "3310152007",
    "district_id": "331015",
    "label": "Ngreden",
    "value": "Ngreden"
  },
  {
    "id": "3310152008",
    "district_id": "331015",
    "label": "Bulan",
    "value": "Bulan"
  },
  {
    "id": "3310152009",
    "district_id": "331015",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3310152010",
    "district_id": "331015",
    "label": "Wadunggetas",
    "value": "Wadunggetas"
  },
  {
    "id": "3310152011",
    "district_id": "331015",
    "label": "Tegalgondo",
    "value": "Tegalgondo"
  },
  {
    "id": "3310152012",
    "district_id": "331015",
    "label": "Bolali",
    "value": "Bolali"
  },
  {
    "id": "3310152013",
    "district_id": "331015",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3310152014",
    "district_id": "331015",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3310152015",
    "district_id": "331015",
    "label": "Bentangan",
    "value": "Bentangan"
  },
  {
    "id": "3310152016",
    "district_id": "331015",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3310152017",
    "district_id": "331015",
    "label": "Pandanan",
    "value": "Pandanan"
  },
  {
    "id": "3310152018",
    "district_id": "331015",
    "label": "Teloyo",
    "value": "Teloyo"
  },
  {
    "id": "3310162001",
    "district_id": "331016",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3310162002",
    "district_id": "331016",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3310162003",
    "district_id": "331016",
    "label": "Butuhan",
    "value": "Butuhan"
  },
  {
    "id": "3310162004",
    "district_id": "331016",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3310162005",
    "district_id": "331016",
    "label": "Bowan",
    "value": "Bowan"
  },
  {
    "id": "3310162006",
    "district_id": "331016",
    "label": "Sribit",
    "value": "Sribit"
  },
  {
    "id": "3310162007",
    "district_id": "331016",
    "label": "Mendak",
    "value": "Mendak"
  },
  {
    "id": "3310162008",
    "district_id": "331016",
    "label": "Krecek",
    "value": "Krecek"
  },
  {
    "id": "3310162009",
    "district_id": "331016",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3310162010",
    "district_id": "331016",
    "label": "Sabrang",
    "value": "Sabrang"
  },
  {
    "id": "3310162011",
    "district_id": "331016",
    "label": "Tlobong",
    "value": "Tlobong"
  },
  {
    "id": "3310162012",
    "district_id": "331016",
    "label": "Gatak",
    "value": "Gatak"
  },
  {
    "id": "3310162013",
    "district_id": "331016",
    "label": "Delanggu",
    "value": "Delanggu"
  },
  {
    "id": "3310162014",
    "district_id": "331016",
    "label": "Kepanjen",
    "value": "Kepanjen"
  },
  {
    "id": "3310162015",
    "district_id": "331016",
    "label": "Segaran",
    "value": "Segaran"
  },
  {
    "id": "3310162016",
    "district_id": "331016",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3310172001",
    "district_id": "331017",
    "label": "Glagahwangi",
    "value": "Glagahwangi"
  },
  {
    "id": "3310172002",
    "district_id": "331017",
    "label": "Kapungan",
    "value": "Kapungan"
  },
  {
    "id": "3310172003",
    "district_id": "331017",
    "label": "Kahuman",
    "value": "Kahuman"
  },
  {
    "id": "3310172004",
    "district_id": "331017",
    "label": "Ngaran",
    "value": "Ngaran"
  },
  {
    "id": "3310172005",
    "district_id": "331017",
    "label": "Borongan",
    "value": "Borongan"
  },
  {
    "id": "3310172006",
    "district_id": "331017",
    "label": "Nganjat",
    "value": "Nganjat"
  },
  {
    "id": "3310172007",
    "district_id": "331017",
    "label": "Jimus",
    "value": "Jimus"
  },
  {
    "id": "3310172008",
    "district_id": "331017",
    "label": "Turus",
    "value": "Turus"
  },
  {
    "id": "3310172009",
    "district_id": "331017",
    "label": "Polan",
    "value": "Polan"
  },
  {
    "id": "3310172010",
    "district_id": "331017",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3310172011",
    "district_id": "331017",
    "label": "Ponggok",
    "value": "Ponggok"
  },
  {
    "id": "3310172012",
    "district_id": "331017",
    "label": "Wangen",
    "value": "Wangen"
  },
  {
    "id": "3310172013",
    "district_id": "331017",
    "label": "Keprabon",
    "value": "Keprabon"
  },
  {
    "id": "3310172014",
    "district_id": "331017",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3310172015",
    "district_id": "331017",
    "label": "Kebonharjo",
    "value": "Kebonharjo"
  },
  {
    "id": "3310172016",
    "district_id": "331017",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3310172017",
    "district_id": "331017",
    "label": "Sidowayah",
    "value": "Sidowayah"
  },
  {
    "id": "3310172018",
    "district_id": "331017",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3310182001",
    "district_id": "331018",
    "label": "Jambeyan",
    "value": "Jambeyan"
  },
  {
    "id": "3310182002",
    "district_id": "331018",
    "label": "Jungkare",
    "value": "Jungkare"
  },
  {
    "id": "3310182003",
    "district_id": "331018",
    "label": "Kadirejo",
    "value": "Kadirejo"
  },
  {
    "id": "3310182004",
    "district_id": "331018",
    "label": "Tarubasan",
    "value": "Tarubasan"
  },
  {
    "id": "3310182005",
    "district_id": "331018",
    "label": "Troso",
    "value": "Troso"
  },
  {
    "id": "3310182006",
    "district_id": "331018",
    "label": "Blanceran",
    "value": "Blanceran"
  },
  {
    "id": "3310182007",
    "district_id": "331018",
    "label": "Kunden",
    "value": "Kunden"
  },
  {
    "id": "3310182008",
    "district_id": "331018",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3310182009",
    "district_id": "331018",
    "label": "Beku",
    "value": "Beku"
  },
  {
    "id": "3310182010",
    "district_id": "331018",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "3310182011",
    "district_id": "331018",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3310182012",
    "district_id": "331018",
    "label": "Padas",
    "value": "Padas"
  },
  {
    "id": "3310182013",
    "district_id": "331018",
    "label": "Soropaten",
    "value": "Soropaten"
  },
  {
    "id": "3310182014",
    "district_id": "331018",
    "label": "Jurangjero",
    "value": "Jurangjero"
  },
  {
    "id": "3310182015",
    "district_id": "331018",
    "label": "Ngabeyan",
    "value": "Ngabeyan"
  },
  {
    "id": "3310182016",
    "district_id": "331018",
    "label": "Gledeg",
    "value": "Gledeg"
  },
  {
    "id": "3310182017",
    "district_id": "331018",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3310182018",
    "district_id": "331018",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "3310182019",
    "district_id": "331018",
    "label": "Jeblog",
    "value": "Jeblog"
  },
  {
    "id": "3310192001",
    "district_id": "331019",
    "label": "Mundu",
    "value": "Mundu"
  },
  {
    "id": "3310192002",
    "district_id": "331019",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3310192003",
    "district_id": "331019",
    "label": "Pomah",
    "value": "Pomah"
  },
  {
    "id": "3310192004",
    "district_id": "331019",
    "label": "Bono",
    "value": "Bono"
  },
  {
    "id": "3310192005",
    "district_id": "331019",
    "label": "Kiringan",
    "value": "Kiringan"
  },
  {
    "id": "3310192006",
    "district_id": "331019",
    "label": "Majegan",
    "value": "Majegan"
  },
  {
    "id": "3310192007",
    "district_id": "331019",
    "label": "Dalangan",
    "value": "Dalangan"
  },
  {
    "id": "3310192008",
    "district_id": "331019",
    "label": "Gedongjetis",
    "value": "Gedongjetis"
  },
  {
    "id": "3310192009",
    "district_id": "331019",
    "label": "Sorogaten",
    "value": "Sorogaten"
  },
  {
    "id": "3310192010",
    "district_id": "331019",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3310192011",
    "district_id": "331019",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3310192012",
    "district_id": "331019",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3310192013",
    "district_id": "331019",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "3310192014",
    "district_id": "331019",
    "label": "Malangan",
    "value": "Malangan"
  },
  {
    "id": "3310192015",
    "district_id": "331019",
    "label": "Pucangmiliran",
    "value": "Pucangmiliran"
  },
  {
    "id": "3310192016",
    "district_id": "331019",
    "label": "Cokro",
    "value": "Cokro"
  },
  {
    "id": "3310192017",
    "district_id": "331019",
    "label": "Daleman",
    "value": "Daleman"
  },
  {
    "id": "3310192018",
    "district_id": "331019",
    "label": "Wunut",
    "value": "Wunut"
  },
  {
    "id": "3310201015",
    "district_id": "331020",
    "label": "Jatinom",
    "value": "Jatinom"
  },
  {
    "id": "3310202001",
    "district_id": "331020",
    "label": "Beteng",
    "value": "Beteng"
  },
  {
    "id": "3310202002",
    "district_id": "331020",
    "label": "Randulanang",
    "value": "Randulanang"
  },
  {
    "id": "3310202003",
    "district_id": "331020",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "3310202004",
    "district_id": "331020",
    "label": "Jemawan",
    "value": "Jemawan"
  },
  {
    "id": "3310202005",
    "district_id": "331020",
    "label": "Gedaren",
    "value": "Gedaren"
  },
  {
    "id": "3310202006",
    "district_id": "331020",
    "label": "Cawan",
    "value": "Cawan"
  },
  {
    "id": "3310202007",
    "district_id": "331020",
    "label": "Tibayan",
    "value": "Tibayan"
  },
  {
    "id": "3310202008",
    "district_id": "331020",
    "label": "Bengking",
    "value": "Bengking"
  },
  {
    "id": "3310202009",
    "district_id": "331020",
    "label": "Temuireng",
    "value": "Temuireng"
  },
  {
    "id": "3310202010",
    "district_id": "331020",
    "label": "Bandungan",
    "value": "Bandungan"
  },
  {
    "id": "3310202011",
    "district_id": "331020",
    "label": "Kayumas",
    "value": "Kayumas"
  },
  {
    "id": "3310202012",
    "district_id": "331020",
    "label": "Socokangsi",
    "value": "Socokangsi"
  },
  {
    "id": "3310202013",
    "district_id": "331020",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "3310202014",
    "district_id": "331020",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3310202016",
    "district_id": "331020",
    "label": "Bonyokan",
    "value": "Bonyokan"
  },
  {
    "id": "3310202017",
    "district_id": "331020",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3310202018",
    "district_id": "331020",
    "label": "Puluhan",
    "value": "Puluhan"
  },
  {
    "id": "3310212001",
    "district_id": "331021",
    "label": "Bawukan",
    "value": "Bawukan"
  },
  {
    "id": "3310212002",
    "district_id": "331021",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3310212003",
    "district_id": "331021",
    "label": "Panggang",
    "value": "Panggang"
  },
  {
    "id": "3310212004",
    "district_id": "331021",
    "label": "Balerante",
    "value": "Balerante"
  },
  {
    "id": "3310212005",
    "district_id": "331021",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3310212006",
    "district_id": "331021",
    "label": "Tegalmulyo",
    "value": "Tegalmulyo"
  },
  {
    "id": "3310212007",
    "district_id": "331021",
    "label": "Tlogowatu",
    "value": "Tlogowatu"
  },
  {
    "id": "3310212008",
    "district_id": "331021",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3310212009",
    "district_id": "331021",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3310212010",
    "district_id": "331021",
    "label": "Kendalsari",
    "value": "Kendalsari"
  },
  {
    "id": "3310212011",
    "district_id": "331021",
    "label": "Dompol",
    "value": "Dompol"
  },
  {
    "id": "3310212012",
    "district_id": "331021",
    "label": "Kemalang",
    "value": "Kemalang"
  },
  {
    "id": "3310212013",
    "district_id": "331021",
    "label": "Keputran",
    "value": "Keputran"
  },
  {
    "id": "3310222001",
    "district_id": "331022",
    "label": "Manjung",
    "value": "Manjung"
  },
  {
    "id": "3310222002",
    "district_id": "331022",
    "label": "Gatak",
    "value": "Gatak"
  },
  {
    "id": "3310222003",
    "district_id": "331022",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3310222004",
    "district_id": "331022",
    "label": "Senden",
    "value": "Senden"
  },
  {
    "id": "3310222005",
    "district_id": "331022",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3310222006",
    "district_id": "331022",
    "label": "Kahuman",
    "value": "Kahuman"
  },
  {
    "id": "3310222007",
    "district_id": "331022",
    "label": "Pepe",
    "value": "Pepe"
  },
  {
    "id": "3310222008",
    "district_id": "331022",
    "label": "Manjungan",
    "value": "Manjungan"
  },
  {
    "id": "3310222009",
    "district_id": "331022",
    "label": "Kwaren",
    "value": "Kwaren"
  },
  {
    "id": "3310222010",
    "district_id": "331022",
    "label": "Mayungan",
    "value": "Mayungan"
  },
  {
    "id": "3310222011",
    "district_id": "331022",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3310222012",
    "district_id": "331022",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3310222013",
    "district_id": "331022",
    "label": "Drono",
    "value": "Drono"
  },
  {
    "id": "3310232001",
    "district_id": "331023",
    "label": "Jimbung",
    "value": "Jimbung"
  },
  {
    "id": "3310232002",
    "district_id": "331023",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3310232003",
    "district_id": "331023",
    "label": "Kalikotes",
    "value": "Kalikotes"
  },
  {
    "id": "3310232004",
    "district_id": "331023",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3310232005",
    "district_id": "331023",
    "label": "Tambongwetan",
    "value": "Tambongwetan"
  },
  {
    "id": "3310232006",
    "district_id": "331023",
    "label": "Jogosetran",
    "value": "Jogosetran"
  },
  {
    "id": "3310232007",
    "district_id": "331023",
    "label": "Gemblegan",
    "value": "Gemblegan"
  },
  {
    "id": "3310241002",
    "district_id": "331024",
    "label": "Barenglor",
    "value": "Barenglor"
  },
  {
    "id": "3310241007",
    "district_id": "331024",
    "label": "Gergunung",
    "value": "Gergunung"
  },
  {
    "id": "3310242001",
    "district_id": "331024",
    "label": "Sekarsuli",
    "value": "Sekarsuli"
  },
  {
    "id": "3310242003",
    "district_id": "331024",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3310242004",
    "district_id": "331024",
    "label": "Ketandan",
    "value": "Ketandan"
  },
  {
    "id": "3310242005",
    "district_id": "331024",
    "label": "Belangwetan",
    "value": "Belangwetan"
  },
  {
    "id": "3310242006",
    "district_id": "331024",
    "label": "Jonggrangan",
    "value": "Jonggrangan"
  },
  {
    "id": "3310242008",
    "district_id": "331024",
    "label": "Jebugan",
    "value": "Jebugan"
  },
  {
    "id": "3310251001",
    "district_id": "331025",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3310251002",
    "district_id": "331025",
    "label": "Kabupaten",
    "value": "Kabupaten"
  },
  {
    "id": "3310251003",
    "district_id": "331025",
    "label": "Klaten",
    "value": "Klaten"
  },
  {
    "id": "3310251004",
    "district_id": "331025",
    "label": "Tonggalan",
    "value": "Tonggalan"
  },
  {
    "id": "3310251006",
    "district_id": "331025",
    "label": "Buntalan",
    "value": "Buntalan"
  },
  {
    "id": "3310251008",
    "district_id": "331025",
    "label": "Mojayan",
    "value": "Mojayan"
  },
  {
    "id": "3310252005",
    "district_id": "331025",
    "label": "Semangkak",
    "value": "Semangkak"
  },
  {
    "id": "3310252007",
    "district_id": "331025",
    "label": "Jomboran",
    "value": "Jomboran"
  },
  {
    "id": "3310252009",
    "district_id": "331025",
    "label": "Gumulan",
    "value": "Gumulan"
  },
  {
    "id": "3310261009",
    "district_id": "331026",
    "label": "Gayamprit",
    "value": "Gayamprit"
  },
  {
    "id": "3310262001",
    "district_id": "331026",
    "label": "Kajoran",
    "value": "Kajoran"
  },
  {
    "id": "3310262002",
    "district_id": "331026",
    "label": "Glodogan",
    "value": "Glodogan"
  },
  {
    "id": "3310262003",
    "district_id": "331026",
    "label": "Ngalas",
    "value": "Ngalas"
  },
  {
    "id": "3310262004",
    "district_id": "331026",
    "label": "Danguran",
    "value": "Danguran"
  },
  {
    "id": "3310262005",
    "district_id": "331026",
    "label": "Trunuh",
    "value": "Trunuh"
  },
  {
    "id": "3310262006",
    "district_id": "331026",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3310262007",
    "district_id": "331026",
    "label": "Merbung",
    "value": "Merbung"
  },
  {
    "id": "3310262008",
    "district_id": "331026",
    "label": "Tegalyoso",
    "value": "Tegalyoso"
  },
  {
    "id": "3310262010",
    "district_id": "331026",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3310262011",
    "district_id": "331026",
    "label": "Nglinggi",
    "value": "Nglinggi"
  },
  {
    "id": "3310262012",
    "district_id": "331026",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3311012001",
    "district_id": "331101",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3311012002",
    "district_id": "331101",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3311012003",
    "district_id": "331101",
    "label": "Karangwuni",
    "value": "Karangwuni"
  },
  {
    "id": "3311012004",
    "district_id": "331101",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3311012005",
    "district_id": "331101",
    "label": "Jatingarang",
    "value": "Jatingarang"
  },
  {
    "id": "3311012006",
    "district_id": "331101",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3311012007",
    "district_id": "331101",
    "label": "Alasombo",
    "value": "Alasombo"
  },
  {
    "id": "3311012008",
    "district_id": "331101",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3311012009",
    "district_id": "331101",
    "label": "Weru",
    "value": "Weru"
  },
  {
    "id": "3311012010",
    "district_id": "331101",
    "label": "Karakan",
    "value": "Karakan"
  },
  {
    "id": "3311012011",
    "district_id": "331101",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3311012012",
    "district_id": "331101",
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "id": "3311012013",
    "district_id": "331101",
    "label": "Ngreco",
    "value": "Ngreco"
  },
  {
    "id": "3311022001",
    "district_id": "331102",
    "label": "Sanggang",
    "value": "Sanggang"
  },
  {
    "id": "3311022002",
    "district_id": "331102",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "3311022003",
    "district_id": "331102",
    "label": "Gentan",
    "value": "Gentan"
  },
  {
    "id": "3311022004",
    "district_id": "331102",
    "label": "Kedungsono",
    "value": "Kedungsono"
  },
  {
    "id": "3311022005",
    "district_id": "331102",
    "label": "Tiyaran",
    "value": "Tiyaran"
  },
  {
    "id": "3311022006",
    "district_id": "331102",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3311022007",
    "district_id": "331102",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3311022008",
    "district_id": "331102",
    "label": "Kunden",
    "value": "Kunden"
  },
  {
    "id": "3311022009",
    "district_id": "331102",
    "label": "Puron",
    "value": "Puron"
  },
  {
    "id": "3311022010",
    "district_id": "331102",
    "label": "Malangan",
    "value": "Malangan"
  },
  {
    "id": "3311022011",
    "district_id": "331102",
    "label": "Lengking",
    "value": "Lengking"
  },
  {
    "id": "3311022012",
    "district_id": "331102",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3311032001",
    "district_id": "331103",
    "label": "Pundungrejo",
    "value": "Pundungrejo"
  },
  {
    "id": "3311032002",
    "district_id": "331103",
    "label": "Watubonang",
    "value": "Watubonang"
  },
  {
    "id": "3311032003",
    "district_id": "331103",
    "label": "Kedungjambal",
    "value": "Kedungjambal"
  },
  {
    "id": "3311032004",
    "district_id": "331103",
    "label": "Grajegan",
    "value": "Grajegan"
  },
  {
    "id": "3311032005",
    "district_id": "331103",
    "label": "Lorog",
    "value": "Lorog"
  },
  {
    "id": "3311032006",
    "district_id": "331103",
    "label": "Kateguhan",
    "value": "Kateguhan"
  },
  {
    "id": "3311032007",
    "district_id": "331103",
    "label": "Dalangan",
    "value": "Dalangan"
  },
  {
    "id": "3311032008",
    "district_id": "331103",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3311032009",
    "district_id": "331103",
    "label": "Tangkisan",
    "value": "Tangkisan"
  },
  {
    "id": "3311032010",
    "district_id": "331103",
    "label": "Ponowaren",
    "value": "Ponowaren"
  },
  {
    "id": "3311032011",
    "district_id": "331103",
    "label": "Majasto",
    "value": "Majasto"
  },
  {
    "id": "3311032012",
    "district_id": "331103",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "3311041001",
    "district_id": "331104",
    "label": "Kenep",
    "value": "Kenep"
  },
  {
    "id": "3311041002",
    "district_id": "331104",
    "label": "Banmati",
    "value": "Banmati"
  },
  {
    "id": "3311041003",
    "district_id": "331104",
    "label": "Mandan",
    "value": "Mandan"
  },
  {
    "id": "3311041004",
    "district_id": "331104",
    "label": "Begajah",
    "value": "Begajah"
  },
  {
    "id": "3311041005",
    "district_id": "331104",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3311041006",
    "district_id": "331104",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3311041007",
    "district_id": "331104",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3311041008",
    "district_id": "331104",
    "label": "Combongan",
    "value": "Combongan"
  },
  {
    "id": "3311041009",
    "district_id": "331104",
    "label": "Kriwen",
    "value": "Kriwen"
  },
  {
    "id": "3311041010",
    "district_id": "331104",
    "label": "Bulakan",
    "value": "Bulakan"
  },
  {
    "id": "3311041011",
    "district_id": "331104",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3311041012",
    "district_id": "331104",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3311041013",
    "district_id": "331104",
    "label": "Bulakrejo",
    "value": "Bulakrejo"
  },
  {
    "id": "3311041014",
    "district_id": "331104",
    "label": "Sonorejo",
    "value": "Sonorejo"
  },
  {
    "id": "3311052001",
    "district_id": "331105",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3311052002",
    "district_id": "331105",
    "label": "Jangglengan",
    "value": "Jangglengan"
  },
  {
    "id": "3311052003",
    "district_id": "331105",
    "label": "Serut",
    "value": "Serut"
  },
  {
    "id": "3311052004",
    "district_id": "331105",
    "label": "Juron",
    "value": "Juron"
  },
  {
    "id": "3311052005",
    "district_id": "331105",
    "label": "Celep",
    "value": "Celep"
  },
  {
    "id": "3311052006",
    "district_id": "331105",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3311052007",
    "district_id": "331105",
    "label": "Gupit",
    "value": "Gupit"
  },
  {
    "id": "3311052008",
    "district_id": "331105",
    "label": "Plesan",
    "value": "Plesan"
  },
  {
    "id": "3311052009",
    "district_id": "331105",
    "label": "Kedungwinong",
    "value": "Kedungwinong"
  },
  {
    "id": "3311052010",
    "district_id": "331105",
    "label": "Nguter",
    "value": "Nguter"
  },
  {
    "id": "3311052011",
    "district_id": "331105",
    "label": "Baran",
    "value": "Baran"
  },
  {
    "id": "3311052012",
    "district_id": "331105",
    "label": "Daleman",
    "value": "Daleman"
  },
  {
    "id": "3311052013",
    "district_id": "331105",
    "label": "Lawu",
    "value": "Lawu"
  },
  {
    "id": "3311052014",
    "district_id": "331105",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3311052015",
    "district_id": "331105",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "3311052016",
    "district_id": "331105",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3311061001",
    "district_id": "331106",
    "label": "Jombor",
    "value": "Jombor"
  },
  {
    "id": "3311062002",
    "district_id": "331106",
    "label": "Toriyo",
    "value": "Toriyo"
  },
  {
    "id": "3311062003",
    "district_id": "331106",
    "label": "Mulur",
    "value": "Mulur"
  },
  {
    "id": "3311062004",
    "district_id": "331106",
    "label": "Jagan",
    "value": "Jagan"
  },
  {
    "id": "3311062005",
    "district_id": "331106",
    "label": "Manisharjo",
    "value": "Manisharjo"
  },
  {
    "id": "3311062006",
    "district_id": "331106",
    "label": "Cabeyan",
    "value": "Cabeyan"
  },
  {
    "id": "3311062007",
    "district_id": "331106",
    "label": "Puhgogor",
    "value": "Puhgogor"
  },
  {
    "id": "3311062008",
    "district_id": "331106",
    "label": "Paluhombo",
    "value": "Paluhombo"
  },
  {
    "id": "3311062009",
    "district_id": "331106",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3311062010",
    "district_id": "331106",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3311062011",
    "district_id": "331106",
    "label": "Mertan",
    "value": "Mertan"
  },
  {
    "id": "3311062012",
    "district_id": "331106",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3311062013",
    "district_id": "331106",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3311062014",
    "district_id": "331106",
    "label": "Gentan",
    "value": "Gentan"
  },
  {
    "id": "3311072001",
    "district_id": "331107",
    "label": "Pranan",
    "value": "Pranan"
  },
  {
    "id": "3311072002",
    "district_id": "331107",
    "label": "Karangwuni",
    "value": "Karangwuni"
  },
  {
    "id": "3311072003",
    "district_id": "331107",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3311072004",
    "district_id": "331107",
    "label": "Ngombakan",
    "value": "Ngombakan"
  },
  {
    "id": "3311072005",
    "district_id": "331107",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3311072006",
    "district_id": "331107",
    "label": "Godog",
    "value": "Godog"
  },
  {
    "id": "3311072007",
    "district_id": "331107",
    "label": "Kemasan",
    "value": "Kemasan"
  },
  {
    "id": "3311072008",
    "district_id": "331107",
    "label": "Kenokorejo",
    "value": "Kenokorejo"
  },
  {
    "id": "3311072009",
    "district_id": "331107",
    "label": "Tepisari",
    "value": "Tepisari"
  },
  {
    "id": "3311072010",
    "district_id": "331107",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3311072011",
    "district_id": "331107",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3311072012",
    "district_id": "331107",
    "label": "Polokarto",
    "value": "Polokarto"
  },
  {
    "id": "3311072013",
    "district_id": "331107",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "3311072014",
    "district_id": "331107",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3311072015",
    "district_id": "331107",
    "label": "Jatisobo",
    "value": "Jatisobo"
  },
  {
    "id": "3311072016",
    "district_id": "331107",
    "label": "Kayuapak",
    "value": "Kayuapak"
  },
  {
    "id": "3311072017",
    "district_id": "331107",
    "label": "Genengsari",
    "value": "Genengsari"
  },
  {
    "id": "3311082001",
    "district_id": "331108",
    "label": "Laban",
    "value": "Laban"
  },
  {
    "id": "3311082002",
    "district_id": "331108",
    "label": "Tegalmade",
    "value": "Tegalmade"
  },
  {
    "id": "3311082003",
    "district_id": "331108",
    "label": "Wirun",
    "value": "Wirun"
  },
  {
    "id": "3311082004",
    "district_id": "331108",
    "label": "Bekonang",
    "value": "Bekonang"
  },
  {
    "id": "3311082005",
    "district_id": "331108",
    "label": "Cangkol",
    "value": "Cangkol"
  },
  {
    "id": "3311082006",
    "district_id": "331108",
    "label": "Klumprit",
    "value": "Klumprit"
  },
  {
    "id": "3311082007",
    "district_id": "331108",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3311082008",
    "district_id": "331108",
    "label": "Sapen",
    "value": "Sapen"
  },
  {
    "id": "3311082009",
    "district_id": "331108",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3311082010",
    "district_id": "331108",
    "label": "Demakan",
    "value": "Demakan"
  },
  {
    "id": "3311082011",
    "district_id": "331108",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3311082012",
    "district_id": "331108",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3311082013",
    "district_id": "331108",
    "label": "Gadingan",
    "value": "Gadingan"
  },
  {
    "id": "3311082014",
    "district_id": "331108",
    "label": "Palur",
    "value": "Palur"
  },
  {
    "id": "3311082015",
    "district_id": "331108",
    "label": "Triyagan",
    "value": "Triyagan"
  },
  {
    "id": "3311092001",
    "district_id": "331109",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3311092002",
    "district_id": "331109",
    "label": "Telukan",
    "value": "Telukan"
  },
  {
    "id": "3311092003",
    "district_id": "331109",
    "label": "Parangjoro",
    "value": "Parangjoro"
  },
  {
    "id": "3311092004",
    "district_id": "331109",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "3311092005",
    "district_id": "331109",
    "label": "Langenharjo",
    "value": "Langenharjo"
  },
  {
    "id": "3311092006",
    "district_id": "331109",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3311092007",
    "district_id": "331109",
    "label": "Madegondo",
    "value": "Madegondo"
  },
  {
    "id": "3311092008",
    "district_id": "331109",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3311092009",
    "district_id": "331109",
    "label": "Kadokan",
    "value": "Kadokan"
  },
  {
    "id": "3311092010",
    "district_id": "331109",
    "label": "Kwarasan",
    "value": "Kwarasan"
  },
  {
    "id": "3311092011",
    "district_id": "331109",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3311092012",
    "district_id": "331109",
    "label": "Manang",
    "value": "Manang"
  },
  {
    "id": "3311092013",
    "district_id": "331109",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3311092014",
    "district_id": "331109",
    "label": "Cemani",
    "value": "Cemani"
  },
  {
    "id": "3311102001",
    "district_id": "331110",
    "label": "Ngrombo",
    "value": "Ngrombo"
  },
  {
    "id": "3311102002",
    "district_id": "331110",
    "label": "Mancasan",
    "value": "Mancasan"
  },
  {
    "id": "3311102003",
    "district_id": "331110",
    "label": "Gedongan",
    "value": "Gedongan"
  },
  {
    "id": "3311102004",
    "district_id": "331110",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3311102005",
    "district_id": "331110",
    "label": "Bentakan",
    "value": "Bentakan"
  },
  {
    "id": "3311102006",
    "district_id": "331110",
    "label": "Kudu",
    "value": "Kudu"
  },
  {
    "id": "3311102007",
    "district_id": "331110",
    "label": "Kadilangu",
    "value": "Kadilangu"
  },
  {
    "id": "3311102008",
    "district_id": "331110",
    "label": "Bakipandeyan",
    "value": "Bakipandeyan"
  },
  {
    "id": "3311102009",
    "district_id": "331110",
    "label": "Menuran",
    "value": "Menuran"
  },
  {
    "id": "3311102010",
    "district_id": "331110",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3311102011",
    "district_id": "331110",
    "label": "Siwal",
    "value": "Siwal"
  },
  {
    "id": "3311102012",
    "district_id": "331110",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3311102013",
    "district_id": "331110",
    "label": "Gentan",
    "value": "Gentan"
  },
  {
    "id": "3311102014",
    "district_id": "331110",
    "label": "Purbayan",
    "value": "Purbayan"
  },
  {
    "id": "3311112001",
    "district_id": "331111",
    "label": "Sanggung",
    "value": "Sanggung"
  },
  {
    "id": "3311112002",
    "district_id": "331111",
    "label": "Kagokan",
    "value": "Kagokan"
  },
  {
    "id": "3311112003",
    "district_id": "331111",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3311112004",
    "district_id": "331111",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3311112005",
    "district_id": "331111",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3311112006",
    "district_id": "331111",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3311112007",
    "district_id": "331111",
    "label": "Trosemi",
    "value": "Trosemi"
  },
  {
    "id": "3311112008",
    "district_id": "331111",
    "label": "Luwang",
    "value": "Luwang"
  },
  {
    "id": "3311112009",
    "district_id": "331111",
    "label": "Klaseman",
    "value": "Klaseman"
  },
  {
    "id": "3311112010",
    "district_id": "331111",
    "label": "Tempel",
    "value": "Tempel"
  },
  {
    "id": "3311112011",
    "district_id": "331111",
    "label": "Sraten",
    "value": "Sraten"
  },
  {
    "id": "3311112012",
    "district_id": "331111",
    "label": "Wironanggan",
    "value": "Wironanggan"
  },
  {
    "id": "3311112013",
    "district_id": "331111",
    "label": "Trangsan",
    "value": "Trangsan"
  },
  {
    "id": "3311112014",
    "district_id": "331111",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "3311121002",
    "district_id": "331112",
    "label": "Kartasura",
    "value": "Kartasura"
  },
  {
    "id": "3311121004",
    "district_id": "331112",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3311122001",
    "district_id": "331112",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3311122003",
    "district_id": "331112",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3311122005",
    "district_id": "331112",
    "label": "Gumpang",
    "value": "Gumpang"
  },
  {
    "id": "3311122006",
    "district_id": "331112",
    "label": "Makamhaji",
    "value": "Makamhaji"
  },
  {
    "id": "3311122007",
    "district_id": "331112",
    "label": "Pabelan",
    "value": "Pabelan"
  },
  {
    "id": "3311122008",
    "district_id": "331112",
    "label": "Gonilan",
    "value": "Gonilan"
  },
  {
    "id": "3311122009",
    "district_id": "331112",
    "label": "Singopuran",
    "value": "Singopuran"
  },
  {
    "id": "3311122010",
    "district_id": "331112",
    "label": "Ngabeyan",
    "value": "Ngabeyan"
  },
  {
    "id": "3311122011",
    "district_id": "331112",
    "label": "Wirogunan",
    "value": "Wirogunan"
  },
  {
    "id": "3311122012",
    "district_id": "331112",
    "label": "Kertonatan",
    "value": "Kertonatan"
  },
  {
    "id": "3312011009",
    "district_id": "331201",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3312012001",
    "district_id": "331201",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3312012002",
    "district_id": "331201",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3312012003",
    "district_id": "331201",
    "label": "Petirsari",
    "value": "Petirsari"
  },
  {
    "id": "3312012004",
    "district_id": "331201",
    "label": "Gambirmanis",
    "value": "Gambirmanis"
  },
  {
    "id": "3312012005",
    "district_id": "331201",
    "label": "Suci",
    "value": "Suci"
  },
  {
    "id": "3312012006",
    "district_id": "331201",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3312012007",
    "district_id": "331201",
    "label": "Watangrejo",
    "value": "Watangrejo"
  },
  {
    "id": "3312012008",
    "district_id": "331201",
    "label": "Pracimantoro",
    "value": "Pracimantoro"
  },
  {
    "id": "3312012010",
    "district_id": "331201",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3312012011",
    "district_id": "331201",
    "label": "Jimbar",
    "value": "Jimbar"
  },
  {
    "id": "3312012012",
    "district_id": "331201",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3312012013",
    "district_id": "331201",
    "label": "Trukan",
    "value": "Trukan"
  },
  {
    "id": "3312012014",
    "district_id": "331201",
    "label": "Tubokarto",
    "value": "Tubokarto"
  },
  {
    "id": "3312012015",
    "district_id": "331201",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3312012016",
    "district_id": "331201",
    "label": "Gebangharjo",
    "value": "Gebangharjo"
  },
  {
    "id": "3312012017",
    "district_id": "331201",
    "label": "Glinggang",
    "value": "Glinggang"
  },
  {
    "id": "3312012018",
    "district_id": "331201",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3312021004",
    "district_id": "331202",
    "label": "Bayemharjo",
    "value": "Bayemharjo"
  },
  {
    "id": "3312021006",
    "district_id": "331202",
    "label": "Giritontro",
    "value": "Giritontro"
  },
  {
    "id": "3312022001",
    "district_id": "331202",
    "label": "Ngargoharjo",
    "value": "Ngargoharjo"
  },
  {
    "id": "3312022002",
    "district_id": "331202",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3312022003",
    "district_id": "331202",
    "label": "Tlogoharjo",
    "value": "Tlogoharjo"
  },
  {
    "id": "3312022005",
    "district_id": "331202",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3312022007",
    "district_id": "331202",
    "label": "Pucanganom",
    "value": "Pucanganom"
  },
  {
    "id": "3312031002",
    "district_id": "331203",
    "label": "Girikikis",
    "value": "Girikikis"
  },
  {
    "id": "3312031011",
    "district_id": "331203",
    "label": "Giriwoyo",
    "value": "Giriwoyo"
  },
  {
    "id": "3312032001",
    "district_id": "331203",
    "label": "Tirtosuworo",
    "value": "Tirtosuworo"
  },
  {
    "id": "3312032003",
    "district_id": "331203",
    "label": "Guwotirto",
    "value": "Guwotirto"
  },
  {
    "id": "3312032004",
    "district_id": "331203",
    "label": "Ngancar",
    "value": "Ngancar"
  },
  {
    "id": "3312032005",
    "district_id": "331203",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3312032006",
    "district_id": "331203",
    "label": "Gedongrejo",
    "value": "Gedongrejo"
  },
  {
    "id": "3312032007",
    "district_id": "331203",
    "label": "Pidekso",
    "value": "Pidekso"
  },
  {
    "id": "3312032008",
    "district_id": "331203",
    "label": "Tukulrejo",
    "value": "Tukulrejo"
  },
  {
    "id": "3312032009",
    "district_id": "331203",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3312032010",
    "district_id": "331203",
    "label": "Selomarto",
    "value": "Selomarto"
  },
  {
    "id": "3312032012",
    "district_id": "331203",
    "label": "Sejati",
    "value": "Sejati"
  },
  {
    "id": "3312032013",
    "district_id": "331203",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3312032014",
    "district_id": "331203",
    "label": "Platarejo",
    "value": "Platarejo"
  },
  {
    "id": "3312032015",
    "district_id": "331203",
    "label": "Sirnoboyo",
    "value": "Sirnoboyo"
  },
  {
    "id": "3312032016",
    "district_id": "331203",
    "label": "Tawangharjo",
    "value": "Tawangharjo"
  },
  {
    "id": "3312041008",
    "district_id": "331204",
    "label": "Selopuro",
    "value": "Selopuro"
  },
  {
    "id": "3312042001",
    "district_id": "331204",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3312042002",
    "district_id": "331204",
    "label": "Tegiri",
    "value": "Tegiri"
  },
  {
    "id": "3312042003",
    "district_id": "331204",
    "label": "Batuwarno",
    "value": "Batuwarno"
  },
  {
    "id": "3312042004",
    "district_id": "331204",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3312042005",
    "district_id": "331204",
    "label": "Ronggojati",
    "value": "Ronggojati"
  },
  {
    "id": "3312042006",
    "district_id": "331204",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3312042007",
    "district_id": "331204",
    "label": "Kudi",
    "value": "Kudi"
  },
  {
    "id": "3312051010",
    "district_id": "331205",
    "label": "Tirtomoyo",
    "value": "Tirtomoyo"
  },
  {
    "id": "3312051012",
    "district_id": "331205",
    "label": "Ngarjosari",
    "value": "Ngarjosari"
  },
  {
    "id": "3312052001",
    "district_id": "331205",
    "label": "Hargosari",
    "value": "Hargosari"
  },
  {
    "id": "3312052002",
    "district_id": "331205",
    "label": "Dlepih",
    "value": "Dlepih"
  },
  {
    "id": "3312052003",
    "district_id": "331205",
    "label": "Wiroko",
    "value": "Wiroko"
  },
  {
    "id": "3312052004",
    "district_id": "331205",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3312052005",
    "district_id": "331205",
    "label": "Hargorejo",
    "value": "Hargorejo"
  },
  {
    "id": "3312052006",
    "district_id": "331205",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3312052007",
    "district_id": "331205",
    "label": "Genengharjo",
    "value": "Genengharjo"
  },
  {
    "id": "3312052008",
    "district_id": "331205",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3312052009",
    "district_id": "331205",
    "label": "Hargantoro",
    "value": "Hargantoro"
  },
  {
    "id": "3312052011",
    "district_id": "331205",
    "label": "Banyakprodo",
    "value": "Banyakprodo"
  },
  {
    "id": "3312052013",
    "district_id": "331205",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3312052014",
    "district_id": "331205",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3312061004",
    "district_id": "331206",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3312061008",
    "district_id": "331206",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3312062001",
    "district_id": "331206",
    "label": "Wonoharjo",
    "value": "Wonoharjo"
  },
  {
    "id": "3312062002",
    "district_id": "331206",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3312062003",
    "district_id": "331206",
    "label": "Kulurejo",
    "value": "Kulurejo"
  },
  {
    "id": "3312062005",
    "district_id": "331206",
    "label": "Semin",
    "value": "Semin"
  },
  {
    "id": "3312062006",
    "district_id": "331206",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3312062007",
    "district_id": "331206",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3312062009",
    "district_id": "331206",
    "label": "Pondoksari",
    "value": "Pondoksari"
  },
  {
    "id": "3312062010",
    "district_id": "331206",
    "label": "Ngadiroyo",
    "value": "Ngadiroyo"
  },
  {
    "id": "3312062011",
    "district_id": "331206",
    "label": "Ngadipiro",
    "value": "Ngadipiro"
  },
  {
    "id": "3312072001",
    "district_id": "331207",
    "label": "Glesungrejo",
    "value": "Glesungrejo"
  },
  {
    "id": "3312072002",
    "district_id": "331207",
    "label": "Balepanjang",
    "value": "Balepanjang"
  },
  {
    "id": "3312072003",
    "district_id": "331207",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3312072004",
    "district_id": "331207",
    "label": "Belikurip",
    "value": "Belikurip"
  },
  {
    "id": "3312072005",
    "district_id": "331207",
    "label": "Baturetno",
    "value": "Baturetno"
  },
  {
    "id": "3312072006",
    "district_id": "331207",
    "label": "Gambiranom",
    "value": "Gambiranom"
  },
  {
    "id": "3312072007",
    "district_id": "331207",
    "label": "Talunombo",
    "value": "Talunombo"
  },
  {
    "id": "3312072008",
    "district_id": "331207",
    "label": "Saradan",
    "value": "Saradan"
  },
  {
    "id": "3312072009",
    "district_id": "331207",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3312072010",
    "district_id": "331207",
    "label": "Setrorejo",
    "value": "Setrorejo"
  },
  {
    "id": "3312072011",
    "district_id": "331207",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3312072012",
    "district_id": "331207",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3312072013",
    "district_id": "331207",
    "label": "Kedungombo",
    "value": "Kedungombo"
  },
  {
    "id": "3312081003",
    "district_id": "331208",
    "label": "Puloharjo",
    "value": "Puloharjo"
  },
  {
    "id": "3312081009",
    "district_id": "331208",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3312082001",
    "district_id": "331208",
    "label": "Basuhan",
    "value": "Basuhan"
  },
  {
    "id": "3312082002",
    "district_id": "331208",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3312082004",
    "district_id": "331208",
    "label": "Sindukarto",
    "value": "Sindukarto"
  },
  {
    "id": "3312082005",
    "district_id": "331208",
    "label": "Panekan",
    "value": "Panekan"
  },
  {
    "id": "3312082006",
    "district_id": "331208",
    "label": "Baleharjo",
    "value": "Baleharjo"
  },
  {
    "id": "3312082007",
    "district_id": "331208",
    "label": "Sumberharjo",
    "value": "Sumberharjo"
  },
  {
    "id": "3312082008",
    "district_id": "331208",
    "label": "Minggarharjo",
    "value": "Minggarharjo"
  },
  {
    "id": "3312082010",
    "district_id": "331208",
    "label": "Tegalharjo",
    "value": "Tegalharjo"
  },
  {
    "id": "3312082011",
    "district_id": "331208",
    "label": "Eromoko",
    "value": "Eromoko"
  },
  {
    "id": "3312082012",
    "district_id": "331208",
    "label": "Ngunggahan",
    "value": "Ngunggahan"
  },
  {
    "id": "3312082013",
    "district_id": "331208",
    "label": "Tempurharjo",
    "value": "Tempurharjo"
  },
  {
    "id": "3312082014",
    "district_id": "331208",
    "label": "Pasekan",
    "value": "Pasekan"
  },
  {
    "id": "3312082015",
    "district_id": "331208",
    "label": "Ngandong",
    "value": "Ngandong"
  },
  {
    "id": "3312091003",
    "district_id": "331209",
    "label": "Mojopuro",
    "value": "Mojopuro"
  },
  {
    "id": "3312091007",
    "district_id": "331209",
    "label": "Wuryantoro",
    "value": "Wuryantoro"
  },
  {
    "id": "3312092001",
    "district_id": "331209",
    "label": "Genukharjo",
    "value": "Genukharjo"
  },
  {
    "id": "3312092002",
    "district_id": "331209",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3312092004",
    "district_id": "331209",
    "label": "Mlopoharjo",
    "value": "Mlopoharjo"
  },
  {
    "id": "3312092005",
    "district_id": "331209",
    "label": "Pulutan Kulon",
    "value": "Pulutan Kulon"
  },
  {
    "id": "3312092006",
    "district_id": "331209",
    "label": "Pulutan Wetan",
    "value": "Pulutan Wetan"
  },
  {
    "id": "3312092008",
    "district_id": "331209",
    "label": "Gumiwang Lor",
    "value": "Gumiwang Lor"
  },
  {
    "id": "3312101006",
    "district_id": "331210",
    "label": "Pagutan",
    "value": "Pagutan"
  },
  {
    "id": "3312101007",
    "district_id": "331210",
    "label": "Punduhsari",
    "value": "Punduhsari"
  },
  {
    "id": "3312102001",
    "district_id": "331210",
    "label": "Kepuhsari",
    "value": "Kepuhsari"
  },
  {
    "id": "3312102002",
    "district_id": "331210",
    "label": "Pijiharjo",
    "value": "Pijiharjo"
  },
  {
    "id": "3312102003",
    "district_id": "331210",
    "label": "Bero",
    "value": "Bero"
  },
  {
    "id": "3312102004",
    "district_id": "331210",
    "label": "Gunungan",
    "value": "Gunungan"
  },
  {
    "id": "3312102005",
    "district_id": "331210",
    "label": "Karanglor",
    "value": "Karanglor"
  },
  {
    "id": "3312111007",
    "district_id": "331211",
    "label": "Kaliancar",
    "value": "Kaliancar"
  },
  {
    "id": "3312112001",
    "district_id": "331211",
    "label": "Pare",
    "value": "Pare"
  },
  {
    "id": "3312112002",
    "district_id": "331211",
    "label": "Keloran",
    "value": "Keloran"
  },
  {
    "id": "3312112003",
    "district_id": "331211",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3312112004",
    "district_id": "331211",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3312112005",
    "district_id": "331211",
    "label": "Jendi",
    "value": "Jendi"
  },
  {
    "id": "3312112006",
    "district_id": "331211",
    "label": "Singodutan",
    "value": "Singodutan"
  },
  {
    "id": "3312112008",
    "district_id": "331211",
    "label": "Gemantar",
    "value": "Gemantar"
  },
  {
    "id": "3312112009",
    "district_id": "331211",
    "label": "Jaten",
    "value": "Jaten"
  },
  {
    "id": "3312112010",
    "district_id": "331211",
    "label": "Nambangan",
    "value": "Nambangan"
  },
  {
    "id": "3312112011",
    "district_id": "331211",
    "label": "Sendangijo",
    "value": "Sendangijo"
  },
  {
    "id": "3312121002",
    "district_id": "331212",
    "label": "Wuryorejo",
    "value": "Wuryorejo"
  },
  {
    "id": "3312121003",
    "district_id": "331212",
    "label": "Giritirto",
    "value": "Giritirto"
  },
  {
    "id": "3312121006",
    "district_id": "331212",
    "label": "Giripurwo",
    "value": "Giripurwo"
  },
  {
    "id": "3312121007",
    "district_id": "331212",
    "label": "Giriwono",
    "value": "Giriwono"
  },
  {
    "id": "3312121008",
    "district_id": "331212",
    "label": "Wonoboyo",
    "value": "Wonoboyo"
  },
  {
    "id": "3312121015",
    "district_id": "331212",
    "label": "Wonokarto",
    "value": "Wonokarto"
  },
  {
    "id": "3312122001",
    "district_id": "331212",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3312122004",
    "district_id": "331212",
    "label": "Pokohkidul",
    "value": "Pokohkidul"
  },
  {
    "id": "3312122005",
    "district_id": "331212",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3312122009",
    "district_id": "331212",
    "label": "Bulusulur",
    "value": "Bulusulur"
  },
  {
    "id": "3312122010",
    "district_id": "331212",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3312122011",
    "district_id": "331212",
    "label": "Wonoharjo",
    "value": "Wonoharjo"
  },
  {
    "id": "3312122012",
    "district_id": "331212",
    "label": "Manjung",
    "value": "Manjung"
  },
  {
    "id": "3312122013",
    "district_id": "331212",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3312122014",
    "district_id": "331212",
    "label": "Sonoharjo",
    "value": "Sonoharjo"
  },
  {
    "id": "3312131009",
    "district_id": "331213",
    "label": "Mlokomanis Kulon",
    "value": "Mlokomanis Kulon"
  },
  {
    "id": "3312131010",
    "district_id": "331213",
    "label": "Kasihan",
    "value": "Kasihan"
  },
  {
    "id": "3312132001",
    "district_id": "331213",
    "label": "Gemawang",
    "value": "Gemawang"
  },
  {
    "id": "3312132002",
    "district_id": "331213",
    "label": "Kerjo Kidul",
    "value": "Kerjo Kidul"
  },
  {
    "id": "3312132003",
    "district_id": "331213",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3312132004",
    "district_id": "331213",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "3312132005",
    "district_id": "331213",
    "label": "Kerjo Lor",
    "value": "Kerjo Lor"
  },
  {
    "id": "3312132006",
    "district_id": "331213",
    "label": "Ngadirojo Kidul",
    "value": "Ngadirojo Kidul"
  },
  {
    "id": "3312132007",
    "district_id": "331213",
    "label": "Ngadirojo Lor",
    "value": "Ngadirojo Lor"
  },
  {
    "id": "3312132008",
    "district_id": "331213",
    "label": "Mlokomanis Wetan",
    "value": "Mlokomanis Wetan"
  },
  {
    "id": "3312132011",
    "district_id": "331213",
    "label": "Jatimarto",
    "value": "Jatimarto"
  },
  {
    "id": "3312141009",
    "district_id": "331214",
    "label": "Kayuloko",
    "value": "Kayuloko"
  },
  {
    "id": "3312141012",
    "district_id": "331214",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3312142001",
    "district_id": "331214",
    "label": "Sempukerep",
    "value": "Sempukerep"
  },
  {
    "id": "3312142002",
    "district_id": "331214",
    "label": "Sembukan",
    "value": "Sembukan"
  },
  {
    "id": "3312142003",
    "district_id": "331214",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3312142004",
    "district_id": "331214",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3312142005",
    "district_id": "331214",
    "label": "Widoro",
    "value": "Widoro"
  },
  {
    "id": "3312142006",
    "district_id": "331214",
    "label": "Mojoreno",
    "value": "Mojoreno"
  },
  {
    "id": "3312142007",
    "district_id": "331214",
    "label": "Ngabeyan",
    "value": "Ngabeyan"
  },
  {
    "id": "3312142008",
    "district_id": "331214",
    "label": "Kedunggupit",
    "value": "Kedunggupit"
  },
  {
    "id": "3312142010",
    "district_id": "331214",
    "label": "Tremes",
    "value": "Tremes"
  },
  {
    "id": "3312142011",
    "district_id": "331214",
    "label": "Jatinom",
    "value": "Jatinom"
  },
  {
    "id": "3312151009",
    "district_id": "331215",
    "label": "Sanggrong",
    "value": "Sanggrong"
  },
  {
    "id": "3312151014",
    "district_id": "331215",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "3312152001",
    "district_id": "331215",
    "label": "Brenggolo",
    "value": "Brenggolo"
  },
  {
    "id": "3312152002",
    "district_id": "331215",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3312152003",
    "district_id": "331215",
    "label": "Guno",
    "value": "Guno"
  },
  {
    "id": "3312152004",
    "district_id": "331215",
    "label": "Dawungan",
    "value": "Dawungan"
  },
  {
    "id": "3312152005",
    "district_id": "331215",
    "label": "Ngelo",
    "value": "Ngelo"
  },
  {
    "id": "3312152006",
    "district_id": "331215",
    "label": "Pingkuk",
    "value": "Pingkuk"
  },
  {
    "id": "3312152007",
    "district_id": "331215",
    "label": "Mojopuro",
    "value": "Mojopuro"
  },
  {
    "id": "3312152008",
    "district_id": "331215",
    "label": "Pesido",
    "value": "Pesido"
  },
  {
    "id": "3312152010",
    "district_id": "331215",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3312152011",
    "district_id": "331215",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3312152012",
    "district_id": "331215",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3312152013",
    "district_id": "331215",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3312152015",
    "district_id": "331215",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3312161005",
    "district_id": "331216",
    "label": "Kismantoro",
    "value": "Kismantoro"
  },
  {
    "id": "3312161008",
    "district_id": "331216",
    "label": "Gesing",
    "value": "Gesing"
  },
  {
    "id": "3312162001",
    "district_id": "331216",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3312162002",
    "district_id": "331216",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3312162003",
    "district_id": "331216",
    "label": "Bugelan",
    "value": "Bugelan"
  },
  {
    "id": "3312162004",
    "district_id": "331216",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3312162006",
    "district_id": "331216",
    "label": "Miri",
    "value": "Miri"
  },
  {
    "id": "3312162007",
    "district_id": "331216",
    "label": "Lemahbang",
    "value": "Lemahbang"
  },
  {
    "id": "3312162009",
    "district_id": "331216",
    "label": "Gambiranom",
    "value": "Gambiranom"
  },
  {
    "id": "3312162010",
    "district_id": "331216",
    "label": "Gedawung",
    "value": "Gedawung"
  },
  {
    "id": "3312171002",
    "district_id": "331217",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3312171007",
    "district_id": "331217",
    "label": "Purwantoro",
    "value": "Purwantoro"
  },
  {
    "id": "3312172001",
    "district_id": "331217",
    "label": "Biting",
    "value": "Biting"
  },
  {
    "id": "3312172003",
    "district_id": "331217",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3312172004",
    "district_id": "331217",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3312172005",
    "district_id": "331217",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3312172006",
    "district_id": "331217",
    "label": "Miricinde",
    "value": "Miricinde"
  },
  {
    "id": "3312172008",
    "district_id": "331217",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3312172009",
    "district_id": "331217",
    "label": "Kepyar",
    "value": "Kepyar"
  },
  {
    "id": "3312172010",
    "district_id": "331217",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3312172011",
    "district_id": "331217",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3312172012",
    "district_id": "331217",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3312172013",
    "district_id": "331217",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3312172014",
    "district_id": "331217",
    "label": "Talesan",
    "value": "Talesan"
  },
  {
    "id": "3312172015",
    "district_id": "331217",
    "label": "Sukomangu",
    "value": "Sukomangu"
  },
  {
    "id": "3312181005",
    "district_id": "331218",
    "label": "Bulukerto",
    "value": "Bulukerto"
  },
  {
    "id": "3312182001",
    "district_id": "331218",
    "label": "Domas",
    "value": "Domas"
  },
  {
    "id": "3312182002",
    "district_id": "331218",
    "label": "Nadi",
    "value": "Nadi"
  },
  {
    "id": "3312182003",
    "district_id": "331218",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3312182004",
    "district_id": "331218",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3312182006",
    "district_id": "331218",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3312182007",
    "district_id": "331218",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3312182008",
    "district_id": "331218",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3312182009",
    "district_id": "331218",
    "label": "Conto",
    "value": "Conto"
  },
  {
    "id": "3312182010",
    "district_id": "331218",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3312191008",
    "district_id": "331219",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3312191014",
    "district_id": "331219",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3312192001",
    "district_id": "331219",
    "label": "Padarangin",
    "value": "Padarangin"
  },
  {
    "id": "3312192002",
    "district_id": "331219",
    "label": "Watusomo",
    "value": "Watusomo"
  },
  {
    "id": "3312192003",
    "district_id": "331219",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3312192004",
    "district_id": "331219",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "3312192005",
    "district_id": "331219",
    "label": "Made",
    "value": "Made"
  },
  {
    "id": "3312192006",
    "district_id": "331219",
    "label": "Tunggur",
    "value": "Tunggur"
  },
  {
    "id": "3312192007",
    "district_id": "331219",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3312192009",
    "district_id": "331219",
    "label": "Slogohimo",
    "value": "Slogohimo"
  },
  {
    "id": "3312192010",
    "district_id": "331219",
    "label": "Soco",
    "value": "Soco"
  },
  {
    "id": "3312192011",
    "district_id": "331219",
    "label": "Klunggen",
    "value": "Klunggen"
  },
  {
    "id": "3312192012",
    "district_id": "331219",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3312192013",
    "district_id": "331219",
    "label": "Gunan",
    "value": "Gunan"
  },
  {
    "id": "3312192015",
    "district_id": "331219",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3312192016",
    "district_id": "331219",
    "label": "Sokoboyo",
    "value": "Sokoboyo"
  },
  {
    "id": "3312192017",
    "district_id": "331219",
    "label": "Setren",
    "value": "Setren"
  },
  {
    "id": "3312201009",
    "district_id": "331220",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3312201012",
    "district_id": "331220",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3312202001",
    "district_id": "331220",
    "label": "Tasikhargo",
    "value": "Tasikhargo"
  },
  {
    "id": "3312202002",
    "district_id": "331220",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3312202003",
    "district_id": "331220",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3312202004",
    "district_id": "331220",
    "label": "Gondangsari",
    "value": "Gondangsari"
  },
  {
    "id": "3312202005",
    "district_id": "331220",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3312202006",
    "district_id": "331220",
    "label": "Ngrompak",
    "value": "Ngrompak"
  },
  {
    "id": "3312202007",
    "district_id": "331220",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3312202008",
    "district_id": "331220",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3312202010",
    "district_id": "331220",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3312202011",
    "district_id": "331220",
    "label": "Tanggulangin",
    "value": "Tanggulangin"
  },
  {
    "id": "3312202013",
    "district_id": "331220",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3312202014",
    "district_id": "331220",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3312202015",
    "district_id": "331220",
    "label": "Jatisrono",
    "value": "Jatisrono"
  },
  {
    "id": "3312202016",
    "district_id": "331220",
    "label": "Watangsono",
    "value": "Watangsono"
  },
  {
    "id": "3312202017",
    "district_id": "331220",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3312211005",
    "district_id": "331221",
    "label": "Jatipurno",
    "value": "Jatipurno"
  },
  {
    "id": "3312211009",
    "district_id": "331221",
    "label": "Balepanjang",
    "value": "Balepanjang"
  },
  {
    "id": "3312212001",
    "district_id": "331221",
    "label": "Giriyoso",
    "value": "Giriyoso"
  },
  {
    "id": "3312212002",
    "district_id": "331221",
    "label": "Kopen",
    "value": "Kopen"
  },
  {
    "id": "3312212003",
    "district_id": "331221",
    "label": "Jatipurwo",
    "value": "Jatipurwo"
  },
  {
    "id": "3312212004",
    "district_id": "331221",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3312212006",
    "district_id": "331221",
    "label": "Slogoretno",
    "value": "Slogoretno"
  },
  {
    "id": "3312212007",
    "district_id": "331221",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3312212008",
    "district_id": "331221",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3312212010",
    "district_id": "331221",
    "label": "Jeporo",
    "value": "Jeporo"
  },
  {
    "id": "3312212011",
    "district_id": "331221",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3312221003",
    "district_id": "331222",
    "label": "Sidokarto",
    "value": "Sidokarto"
  },
  {
    "id": "3312221007",
    "district_id": "331222",
    "label": "Gemawang",
    "value": "Gemawang"
  },
  {
    "id": "3312222001",
    "district_id": "331222",
    "label": "Waleng",
    "value": "Waleng"
  },
  {
    "id": "3312222002",
    "district_id": "331222",
    "label": "Doho",
    "value": "Doho"
  },
  {
    "id": "3312222004",
    "district_id": "331222",
    "label": "Girimarto",
    "value": "Girimarto"
  },
  {
    "id": "3312222005",
    "district_id": "331222",
    "label": "Jendi",
    "value": "Jendi"
  },
  {
    "id": "3312222006",
    "district_id": "331222",
    "label": "Nungkulan",
    "value": "Nungkulan"
  },
  {
    "id": "3312222008",
    "district_id": "331222",
    "label": "Tambakmerang",
    "value": "Tambakmerang"
  },
  {
    "id": "3312222009",
    "district_id": "331222",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3312222010",
    "district_id": "331222",
    "label": "Giriwarno",
    "value": "Giriwarno"
  },
  {
    "id": "3312222011",
    "district_id": "331222",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3312222012",
    "district_id": "331222",
    "label": "Semagar",
    "value": "Semagar"
  },
  {
    "id": "3312222013",
    "district_id": "331222",
    "label": "Sanan",
    "value": "Sanan"
  },
  {
    "id": "3312222014",
    "district_id": "331222",
    "label": "Bubakan",
    "value": "Bubakan"
  },
  {
    "id": "3312232001",
    "district_id": "331223",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "3312232002",
    "district_id": "331223",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3312232003",
    "district_id": "331223",
    "label": "Ngambarsari",
    "value": "Ngambarsari"
  },
  {
    "id": "3312232004",
    "district_id": "331223",
    "label": "Jeblogan",
    "value": "Jeblogan"
  },
  {
    "id": "3312232005",
    "district_id": "331223",
    "label": "Temboro",
    "value": "Temboro"
  },
  {
    "id": "3312242001",
    "district_id": "331224",
    "label": "Songbledeg",
    "value": "Songbledeg"
  },
  {
    "id": "3312242002",
    "district_id": "331224",
    "label": "Ketos",
    "value": "Ketos"
  },
  {
    "id": "3312242003",
    "district_id": "331224",
    "label": "Paranggupito",
    "value": "Paranggupito"
  },
  {
    "id": "3312242004",
    "district_id": "331224",
    "label": "Gudangharjo",
    "value": "Gudangharjo"
  },
  {
    "id": "3312242005",
    "district_id": "331224",
    "label": "Gunturharjo",
    "value": "Gunturharjo"
  },
  {
    "id": "3312242006",
    "district_id": "331224",
    "label": "Sambiharjo",
    "value": "Sambiharjo"
  },
  {
    "id": "3312242007",
    "district_id": "331224",
    "label": "Johunut",
    "value": "Johunut"
  },
  {
    "id": "3312242008",
    "district_id": "331224",
    "label": "Gendayakan",
    "value": "Gendayakan"
  },
  {
    "id": "3312251002",
    "district_id": "331225",
    "label": "Giriharjo",
    "value": "Giriharjo"
  },
  {
    "id": "3312252001",
    "district_id": "331225",
    "label": "Puhpelem",
    "value": "Puhpelem"
  },
  {
    "id": "3312252003",
    "district_id": "331225",
    "label": "Nguneng",
    "value": "Nguneng"
  },
  {
    "id": "3312252004",
    "district_id": "331225",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3312252005",
    "district_id": "331225",
    "label": "Tengger",
    "value": "Tengger"
  },
  {
    "id": "3312252006",
    "district_id": "331225",
    "label": "Golo",
    "value": "Golo"
  },
  {
    "id": "3313012001",
    "district_id": "331301",
    "label": "Ngepungsari",
    "value": "Ngepungsari"
  },
  {
    "id": "3313012002",
    "district_id": "331301",
    "label": "Jatipurwo",
    "value": "Jatipurwo"
  },
  {
    "id": "3313012003",
    "district_id": "331301",
    "label": "Jatipuro",
    "value": "Jatipuro"
  },
  {
    "id": "3313012004",
    "district_id": "331301",
    "label": "Jatisobo",
    "value": "Jatisobo"
  },
  {
    "id": "3313012005",
    "district_id": "331301",
    "label": "Jatiwarno",
    "value": "Jatiwarno"
  },
  {
    "id": "3313012006",
    "district_id": "331301",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3313012007",
    "district_id": "331301",
    "label": "Jatisuko",
    "value": "Jatisuko"
  },
  {
    "id": "3313012008",
    "district_id": "331301",
    "label": "Jatiharjo",
    "value": "Jatiharjo"
  },
  {
    "id": "3313012009",
    "district_id": "331301",
    "label": "Jatikuwung",
    "value": "Jatikuwung"
  },
  {
    "id": "3313012010",
    "district_id": "331301",
    "label": "Jatiroyo",
    "value": "Jatiroyo"
  },
  {
    "id": "3313022001",
    "district_id": "331302",
    "label": "Jatisawit",
    "value": "Jatisawit"
  },
  {
    "id": "3313022002",
    "district_id": "331302",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3313022003",
    "district_id": "331302",
    "label": "Wonokeling",
    "value": "Wonokeling"
  },
  {
    "id": "3313022004",
    "district_id": "331302",
    "label": "Jatiyoso",
    "value": "Jatiyoso"
  },
  {
    "id": "3313022005",
    "district_id": "331302",
    "label": "Tlobo",
    "value": "Tlobo"
  },
  {
    "id": "3313022006",
    "district_id": "331302",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3313022007",
    "district_id": "331302",
    "label": "Beruk",
    "value": "Beruk"
  },
  {
    "id": "3313022008",
    "district_id": "331302",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3313022009",
    "district_id": "331302",
    "label": "Wukirsawit",
    "value": "Wukirsawit"
  },
  {
    "id": "3313032001",
    "district_id": "331303",
    "label": "Paseban",
    "value": "Paseban"
  },
  {
    "id": "3313032002",
    "district_id": "331303",
    "label": "Lemahbang",
    "value": "Lemahbang"
  },
  {
    "id": "3313032003",
    "district_id": "331303",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3313032004",
    "district_id": "331303",
    "label": "Kwangsan",
    "value": "Kwangsan"
  },
  {
    "id": "3313032005",
    "district_id": "331303",
    "label": "Karangbangun",
    "value": "Karangbangun"
  },
  {
    "id": "3313032006",
    "district_id": "331303",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3313032007",
    "district_id": "331303",
    "label": "Giriwondo",
    "value": "Giriwondo"
  },
  {
    "id": "3313032008",
    "district_id": "331303",
    "label": "Kadipiro",
    "value": "Kadipiro"
  },
  {
    "id": "3313032009",
    "district_id": "331303",
    "label": "Jumantoro",
    "value": "Jumantoro"
  },
  {
    "id": "3313032010",
    "district_id": "331303",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3313032011",
    "district_id": "331303",
    "label": "Jumapolo",
    "value": "Jumapolo"
  },
  {
    "id": "3313032012",
    "district_id": "331303",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3313042001",
    "district_id": "331304",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3313042002",
    "district_id": "331304",
    "label": "Kebak",
    "value": "Kebak"
  },
  {
    "id": "3313042003",
    "district_id": "331304",
    "label": "Gemantar",
    "value": "Gemantar"
  },
  {
    "id": "3313042004",
    "district_id": "331304",
    "label": "Tunggulrejo",
    "value": "Tunggulrejo"
  },
  {
    "id": "3313042005",
    "district_id": "331304",
    "label": "Genengan",
    "value": "Genengan"
  },
  {
    "id": "3313042006",
    "district_id": "331304",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3313042007",
    "district_id": "331304",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3313042008",
    "district_id": "331304",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3313042009",
    "district_id": "331304",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3313042010",
    "district_id": "331304",
    "label": "Blorong",
    "value": "Blorong"
  },
  {
    "id": "3313042011",
    "district_id": "331304",
    "label": "Sringin",
    "value": "Sringin"
  },
  {
    "id": "3313052001",
    "district_id": "331305",
    "label": "Ngadiluwih",
    "value": "Ngadiluwih"
  },
  {
    "id": "3313052002",
    "district_id": "331305",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3313052003",
    "district_id": "331305",
    "label": "Matesih",
    "value": "Matesih"
  },
  {
    "id": "3313052004",
    "district_id": "331305",
    "label": "Karangbangun",
    "value": "Karangbangun"
  },
  {
    "id": "3313052005",
    "district_id": "331305",
    "label": "Koripan",
    "value": "Koripan"
  },
  {
    "id": "3313052006",
    "district_id": "331305",
    "label": "Girilayu",
    "value": "Girilayu"
  },
  {
    "id": "3313052007",
    "district_id": "331305",
    "label": "Pablengan",
    "value": "Pablengan"
  },
  {
    "id": "3313052008",
    "district_id": "331305",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3313052009",
    "district_id": "331305",
    "label": "Gantiwarno",
    "value": "Gantiwarno"
  },
  {
    "id": "3313061002",
    "district_id": "331306",
    "label": "Blumbang",
    "value": "Blumbang"
  },
  {
    "id": "3313061003",
    "district_id": "331306",
    "label": "Kalisoro",
    "value": "Kalisoro"
  },
  {
    "id": "3313061004",
    "district_id": "331306",
    "label": "Tawangmangu",
    "value": "Tawangmangu"
  },
  {
    "id": "3313062001",
    "district_id": "331306",
    "label": "Gondosuli",
    "value": "Gondosuli"
  },
  {
    "id": "3313062005",
    "district_id": "331306",
    "label": "Sepanjang",
    "value": "Sepanjang"
  },
  {
    "id": "3313062006",
    "district_id": "331306",
    "label": "Bandardawung",
    "value": "Bandardawung"
  },
  {
    "id": "3313062007",
    "district_id": "331306",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3313062008",
    "district_id": "331306",
    "label": "Nglebak",
    "value": "Nglebak"
  },
  {
    "id": "3313062009",
    "district_id": "331306",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3313062010",
    "district_id": "331306",
    "label": "Tengklik",
    "value": "Tengklik"
  },
  {
    "id": "3313072001",
    "district_id": "331307",
    "label": "Puntukrejo",
    "value": "Puntukrejo"
  },
  {
    "id": "3313072002",
    "district_id": "331307",
    "label": "Berjo",
    "value": "Berjo"
  },
  {
    "id": "3313072003",
    "district_id": "331307",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3313072004",
    "district_id": "331307",
    "label": "Segorogunung",
    "value": "Segorogunung"
  },
  {
    "id": "3313072005",
    "district_id": "331307",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3313072006",
    "district_id": "331307",
    "label": "Nglegok",
    "value": "Nglegok"
  },
  {
    "id": "3313072007",
    "district_id": "331307",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3313072008",
    "district_id": "331307",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3313072009",
    "district_id": "331307",
    "label": "Ngargoyoso",
    "value": "Ngargoyoso"
  },
  {
    "id": "3313082001",
    "district_id": "331308",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3313082002",
    "district_id": "331308",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3313082003",
    "district_id": "331308",
    "label": "Doplang",
    "value": "Doplang"
  },
  {
    "id": "3313082004",
    "district_id": "331308",
    "label": "Gerdu",
    "value": "Gerdu"
  },
  {
    "id": "3313082005",
    "district_id": "331308",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3313082006",
    "district_id": "331308",
    "label": "Salam",
    "value": "Salam"
  },
  {
    "id": "3313082007",
    "district_id": "331308",
    "label": "Karangpandan",
    "value": "Karangpandan"
  },
  {
    "id": "3313082008",
    "district_id": "331308",
    "label": "Tohkuning",
    "value": "Tohkuning"
  },
  {
    "id": "3313082009",
    "district_id": "331308",
    "label": "Gondangmanis",
    "value": "Gondangmanis"
  },
  {
    "id": "3313082010",
    "district_id": "331308",
    "label": "Dayu",
    "value": "Dayu"
  },
  {
    "id": "3313082011",
    "district_id": "331308",
    "label": "Harjosari",
    "value": "Harjosari"
  },
  {
    "id": "3313091001",
    "district_id": "331309",
    "label": "Lalung",
    "value": "Lalung"
  },
  {
    "id": "3313091002",
    "district_id": "331309",
    "label": "Bolong",
    "value": "Bolong"
  },
  {
    "id": "3313091003",
    "district_id": "331309",
    "label": "Jantiharjo",
    "value": "Jantiharjo"
  },
  {
    "id": "3313091004",
    "district_id": "331309",
    "label": "Tegalgede",
    "value": "Tegalgede"
  },
  {
    "id": "3313091005",
    "district_id": "331309",
    "label": "Jungke",
    "value": "Jungke"
  },
  {
    "id": "3313091006",
    "district_id": "331309",
    "label": "Cangakan",
    "value": "Cangakan"
  },
  {
    "id": "3313091007",
    "district_id": "331309",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3313091008",
    "district_id": "331309",
    "label": "Bejen",
    "value": "Bejen"
  },
  {
    "id": "3313091009",
    "district_id": "331309",
    "label": "Popongan",
    "value": "Popongan"
  },
  {
    "id": "3313091010",
    "district_id": "331309",
    "label": "Gayamdompo",
    "value": "Gayamdompo"
  },
  {
    "id": "3313091011",
    "district_id": "331309",
    "label": "Delingan",
    "value": "Delingan"
  },
  {
    "id": "3313091012",
    "district_id": "331309",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3313102001",
    "district_id": "331310",
    "label": "Buran",
    "value": "Buran"
  },
  {
    "id": "3313102002",
    "district_id": "331310",
    "label": "Papahan",
    "value": "Papahan"
  },
  {
    "id": "3313102003",
    "district_id": "331310",
    "label": "Ngijo",
    "value": "Ngijo"
  },
  {
    "id": "3313102004",
    "district_id": "331310",
    "label": "Gaum",
    "value": "Gaum"
  },
  {
    "id": "3313102005",
    "district_id": "331310",
    "label": "Suruh",
    "value": "Suruh"
  },
  {
    "id": "3313102006",
    "district_id": "331310",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3313102007",
    "district_id": "331310",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3313102008",
    "district_id": "331310",
    "label": "Kaling",
    "value": "Kaling"
  },
  {
    "id": "3313102009",
    "district_id": "331310",
    "label": "Wonolopo",
    "value": "Wonolopo"
  },
  {
    "id": "3313102010",
    "district_id": "331310",
    "label": "Kalijirak",
    "value": "Kalijirak"
  },
  {
    "id": "3313112001",
    "district_id": "331311",
    "label": "Suruhkalang",
    "value": "Suruhkalang"
  },
  {
    "id": "3313112002",
    "district_id": "331311",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3313112003",
    "district_id": "331311",
    "label": "Jaten",
    "value": "Jaten"
  },
  {
    "id": "3313112004",
    "district_id": "331311",
    "label": "Dagen",
    "value": "Dagen"
  },
  {
    "id": "3313112005",
    "district_id": "331311",
    "label": "Ngringo",
    "value": "Ngringo"
  },
  {
    "id": "3313112006",
    "district_id": "331311",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3313112007",
    "district_id": "331311",
    "label": "Sroyo",
    "value": "Sroyo"
  },
  {
    "id": "3313112008",
    "district_id": "331311",
    "label": "Brujul",
    "value": "Brujul"
  },
  {
    "id": "3313122001",
    "district_id": "331312",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3313122002",
    "district_id": "331312",
    "label": "Bolon",
    "value": "Bolon"
  },
  {
    "id": "3313122003",
    "district_id": "331312",
    "label": "Malangjiwan",
    "value": "Malangjiwan"
  },
  {
    "id": "3313122004",
    "district_id": "331312",
    "label": "Paulan",
    "value": "Paulan"
  },
  {
    "id": "3313122005",
    "district_id": "331312",
    "label": "Gajahan",
    "value": "Gajahan"
  },
  {
    "id": "3313122006",
    "district_id": "331312",
    "label": "Blulukan",
    "value": "Blulukan"
  },
  {
    "id": "3313122007",
    "district_id": "331312",
    "label": "Gawanan",
    "value": "Gawanan"
  },
  {
    "id": "3313122008",
    "district_id": "331312",
    "label": "Gedongan",
    "value": "Gedongan"
  },
  {
    "id": "3313122009",
    "district_id": "331312",
    "label": "Tohudan",
    "value": "Tohudan"
  },
  {
    "id": "3313122010",
    "district_id": "331312",
    "label": "Baturan",
    "value": "Baturan"
  },
  {
    "id": "3313122011",
    "district_id": "331312",
    "label": "Klodran",
    "value": "Klodran"
  },
  {
    "id": "3313132001",
    "district_id": "331313",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3313132002",
    "district_id": "331313",
    "label": "Plesungan",
    "value": "Plesungan"
  },
  {
    "id": "3313132003",
    "district_id": "331313",
    "label": "Jatikuwung",
    "value": "Jatikuwung"
  },
  {
    "id": "3313132004",
    "district_id": "331313",
    "label": "Selokaton",
    "value": "Selokaton"
  },
  {
    "id": "3313132005",
    "district_id": "331313",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3313132006",
    "district_id": "331313",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3313132007",
    "district_id": "331313",
    "label": "Jeruksawit",
    "value": "Jeruksawit"
  },
  {
    "id": "3313132008",
    "district_id": "331313",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3313132009",
    "district_id": "331313",
    "label": "Kragan",
    "value": "Kragan"
  },
  {
    "id": "3313132010",
    "district_id": "331313",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3313132011",
    "district_id": "331313",
    "label": "Dayu",
    "value": "Dayu"
  },
  {
    "id": "3313132012",
    "district_id": "331313",
    "label": "Tuban",
    "value": "Tuban"
  },
  {
    "id": "3313132013",
    "district_id": "331313",
    "label": "Krendowahono",
    "value": "Krendowahono"
  },
  {
    "id": "3313142001",
    "district_id": "331314",
    "label": "Banjarharjo",
    "value": "Banjarharjo"
  },
  {
    "id": "3313142002",
    "district_id": "331314",
    "label": "Alastuwo",
    "value": "Alastuwo"
  },
  {
    "id": "3313142003",
    "district_id": "331314",
    "label": "Macanan",
    "value": "Macanan"
  },
  {
    "id": "3313142004",
    "district_id": "331314",
    "label": "Nangsri",
    "value": "Nangsri"
  },
  {
    "id": "3313142005",
    "district_id": "331314",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3313142006",
    "district_id": "331314",
    "label": "Kebak",
    "value": "Kebak"
  },
  {
    "id": "3313142007",
    "district_id": "331314",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3313142008",
    "district_id": "331314",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3313142009",
    "district_id": "331314",
    "label": "Malanggaten",
    "value": "Malanggaten"
  },
  {
    "id": "3313142010",
    "district_id": "331314",
    "label": "Kaliwuluh",
    "value": "Kaliwuluh"
  },
  {
    "id": "3313152001",
    "district_id": "331315",
    "label": "Sewurejo",
    "value": "Sewurejo"
  },
  {
    "id": "3313152002",
    "district_id": "331315",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3313152003",
    "district_id": "331315",
    "label": "Mojogedang",
    "value": "Mojogedang"
  },
  {
    "id": "3313152004",
    "district_id": "331315",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3313152005",
    "district_id": "331315",
    "label": "Mojoroto",
    "value": "Mojoroto"
  },
  {
    "id": "3313152006",
    "district_id": "331315",
    "label": "Kaliboto",
    "value": "Kaliboto"
  },
  {
    "id": "3313152007",
    "district_id": "331315",
    "label": "Buntar",
    "value": "Buntar"
  },
  {
    "id": "3313152008",
    "district_id": "331315",
    "label": "Gebyog",
    "value": "Gebyog"
  },
  {
    "id": "3313152009",
    "district_id": "331315",
    "label": "Gentungan",
    "value": "Gentungan"
  },
  {
    "id": "3313152010",
    "district_id": "331315",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "3313152011",
    "district_id": "331315",
    "label": "Pereng",
    "value": "Pereng"
  },
  {
    "id": "3313152012",
    "district_id": "331315",
    "label": "Munggur",
    "value": "Munggur"
  },
  {
    "id": "3313152013",
    "district_id": "331315",
    "label": "Kedungjeruk",
    "value": "Kedungjeruk"
  },
  {
    "id": "3313162001",
    "district_id": "331316",
    "label": "Kuto",
    "value": "Kuto"
  },
  {
    "id": "3313162002",
    "district_id": "331316",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3313162003",
    "district_id": "331316",
    "label": "Ganten",
    "value": "Ganten"
  },
  {
    "id": "3313162004",
    "district_id": "331316",
    "label": "Gempolan",
    "value": "Gempolan"
  },
  {
    "id": "3313162005",
    "district_id": "331316",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3313162006",
    "district_id": "331316",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3313162007",
    "district_id": "331316",
    "label": "Kwadungan",
    "value": "Kwadungan"
  },
  {
    "id": "3313162008",
    "district_id": "331316",
    "label": "Botok",
    "value": "Botok"
  },
  {
    "id": "3313162009",
    "district_id": "331316",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3313162010",
    "district_id": "331316",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3313172001",
    "district_id": "331317",
    "label": "Gumeng",
    "value": "Gumeng"
  },
  {
    "id": "3313172002",
    "district_id": "331317",
    "label": "Anggrasmanis",
    "value": "Anggrasmanis"
  },
  {
    "id": "3313172003",
    "district_id": "331317",
    "label": "Jenawi",
    "value": "Jenawi"
  },
  {
    "id": "3313172004",
    "district_id": "331317",
    "label": "Trengguli",
    "value": "Trengguli"
  },
  {
    "id": "3313172005",
    "district_id": "331317",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3313172006",
    "district_id": "331317",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3313172007",
    "district_id": "331317",
    "label": "Seloromo",
    "value": "Seloromo"
  },
  {
    "id": "3313172008",
    "district_id": "331317",
    "label": "Menjing",
    "value": "Menjing"
  },
  {
    "id": "3313172009",
    "district_id": "331317",
    "label": "Lempong",
    "value": "Lempong"
  },
  {
    "id": "3314012001",
    "district_id": "331401",
    "label": "Keden",
    "value": "Keden"
  },
  {
    "id": "3314012002",
    "district_id": "331401",
    "label": "Trobayan",
    "value": "Trobayan"
  },
  {
    "id": "3314012003",
    "district_id": "331401",
    "label": "Kalimacan",
    "value": "Kalimacan"
  },
  {
    "id": "3314012004",
    "district_id": "331401",
    "label": "Jetiskarangpung",
    "value": "Jetiskarangpung"
  },
  {
    "id": "3314012005",
    "district_id": "331401",
    "label": "Krikilan",
    "value": "Krikilan"
  },
  {
    "id": "3314012006",
    "district_id": "331401",
    "label": "Bukuran",
    "value": "Bukuran"
  },
  {
    "id": "3314012007",
    "district_id": "331401",
    "label": "Ngebung",
    "value": "Ngebung"
  },
  {
    "id": "3314012008",
    "district_id": "331401",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "3314012009",
    "district_id": "331401",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3314012010",
    "district_id": "331401",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3314012011",
    "district_id": "331401",
    "label": "Saren",
    "value": "Saren"
  },
  {
    "id": "3314012012",
    "district_id": "331401",
    "label": "Sambirembe",
    "value": "Sambirembe"
  },
  {
    "id": "3314012013",
    "district_id": "331401",
    "label": "Donoyudan",
    "value": "Donoyudan"
  },
  {
    "id": "3314012014",
    "district_id": "331401",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3314022001",
    "district_id": "331402",
    "label": "Karangwaru",
    "value": "Karangwaru"
  },
  {
    "id": "3314022002",
    "district_id": "331402",
    "label": "Ngrombo",
    "value": "Ngrombo"
  },
  {
    "id": "3314022003",
    "district_id": "331402",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3314022004",
    "district_id": "331402",
    "label": "Somomorodukuh",
    "value": "Somomorodukuh"
  },
  {
    "id": "3314022005",
    "district_id": "331402",
    "label": "Cangkol",
    "value": "Cangkol"
  },
  {
    "id": "3314022006",
    "district_id": "331402",
    "label": "Manyarejo",
    "value": "Manyarejo"
  },
  {
    "id": "3314022007",
    "district_id": "331402",
    "label": "Pungsari",
    "value": "Pungsari"
  },
  {
    "id": "3314022008",
    "district_id": "331402",
    "label": "Jembangan",
    "value": "Jembangan"
  },
  {
    "id": "3314022009",
    "district_id": "331402",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "3314022010",
    "district_id": "331402",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3314022011",
    "district_id": "331402",
    "label": "Gedongan",
    "value": "Gedongan"
  },
  {
    "id": "3314022012",
    "district_id": "331402",
    "label": "Plupuh",
    "value": "Plupuh"
  },
  {
    "id": "3314022013",
    "district_id": "331402",
    "label": "Dari",
    "value": "Dari"
  },
  {
    "id": "3314022014",
    "district_id": "331402",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3314022015",
    "district_id": "331402",
    "label": "Karungan",
    "value": "Karungan"
  },
  {
    "id": "3314022016",
    "district_id": "331402",
    "label": "Gentanbanaran",
    "value": "Gentanbanaran"
  },
  {
    "id": "3314032001",
    "district_id": "331403",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3314032002",
    "district_id": "331403",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3314032003",
    "district_id": "331403",
    "label": "Krebet",
    "value": "Krebet"
  },
  {
    "id": "3314032004",
    "district_id": "331403",
    "label": "Sepat",
    "value": "Sepat"
  },
  {
    "id": "3314032005",
    "district_id": "331403",
    "label": "Jirapan",
    "value": "Jirapan"
  },
  {
    "id": "3314032006",
    "district_id": "331403",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3314032007",
    "district_id": "331403",
    "label": "Dawungan",
    "value": "Dawungan"
  },
  {
    "id": "3314032008",
    "district_id": "331403",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3314032009",
    "district_id": "331403",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3314032010",
    "district_id": "331403",
    "label": "Kliwonan",
    "value": "Kliwonan"
  },
  {
    "id": "3314032011",
    "district_id": "331403",
    "label": "Pilang",
    "value": "Pilang"
  },
  {
    "id": "3314032012",
    "district_id": "331403",
    "label": "Pringanom",
    "value": "Pringanom"
  },
  {
    "id": "3314032013",
    "district_id": "331403",
    "label": "Krikilan",
    "value": "Krikilan"
  },
  {
    "id": "3314042001",
    "district_id": "331404",
    "label": "Pengkok",
    "value": "Pengkok"
  },
  {
    "id": "3314042002",
    "district_id": "331404",
    "label": "Celep",
    "value": "Celep"
  },
  {
    "id": "3314042003",
    "district_id": "331404",
    "label": "Karangpelem",
    "value": "Karangpelem"
  },
  {
    "id": "3314042004",
    "district_id": "331404",
    "label": "Mojodoyong",
    "value": "Mojodoyong"
  },
  {
    "id": "3314042005",
    "district_id": "331404",
    "label": "Jenggrik",
    "value": "Jenggrik"
  },
  {
    "id": "3314042006",
    "district_id": "331404",
    "label": "Mojokerto",
    "value": "Mojokerto"
  },
  {
    "id": "3314042007",
    "district_id": "331404",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3314042008",
    "district_id": "331404",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3314042009",
    "district_id": "331404",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3314042010",
    "district_id": "331404",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3314052001",
    "district_id": "331405",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3314052002",
    "district_id": "331405",
    "label": "Jambeyan",
    "value": "Jambeyan"
  },
  {
    "id": "3314052003",
    "district_id": "331405",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3314052004",
    "district_id": "331405",
    "label": "Musuk",
    "value": "Musuk"
  },
  {
    "id": "3314052005",
    "district_id": "331405",
    "label": "Kadipiro",
    "value": "Kadipiro"
  },
  {
    "id": "3314052006",
    "district_id": "331405",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3314052007",
    "district_id": "331405",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3314052008",
    "district_id": "331405",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3314052009",
    "district_id": "331405",
    "label": "Sambi",
    "value": "Sambi"
  },
  {
    "id": "3314062001",
    "district_id": "331406",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "3314062002",
    "district_id": "331406",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3314062003",
    "district_id": "331406",
    "label": "Tunggul",
    "value": "Tunggul"
  },
  {
    "id": "3314062004",
    "district_id": "331406",
    "label": "Glonggong",
    "value": "Glonggong"
  },
  {
    "id": "3314062005",
    "district_id": "331406",
    "label": "Kaliwedi",
    "value": "Kaliwedi"
  },
  {
    "id": "3314062006",
    "district_id": "331406",
    "label": "Wonotolo",
    "value": "Wonotolo"
  },
  {
    "id": "3314062007",
    "district_id": "331406",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3314062008",
    "district_id": "331406",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3314062009",
    "district_id": "331406",
    "label": "Bumiaji",
    "value": "Bumiaji"
  },
  {
    "id": "3314072001",
    "district_id": "331407",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3314072002",
    "district_id": "331407",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3314072003",
    "district_id": "331407",
    "label": "Cemeng",
    "value": "Cemeng"
  },
  {
    "id": "3314072004",
    "district_id": "331407",
    "label": "Bedoro",
    "value": "Bedoro"
  },
  {
    "id": "3314072005",
    "district_id": "331407",
    "label": "Toyogo",
    "value": "Toyogo"
  },
  {
    "id": "3314072006",
    "district_id": "331407",
    "label": "Banyurip",
    "value": "Banyurip"
  },
  {
    "id": "3314072007",
    "district_id": "331407",
    "label": "Gringging",
    "value": "Gringging"
  },
  {
    "id": "3314072008",
    "district_id": "331407",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3314072009",
    "district_id": "331407",
    "label": "Sambungmacan",
    "value": "Sambungmacan"
  },
  {
    "id": "3314082001",
    "district_id": "331408",
    "label": "Ngarum",
    "value": "Ngarum"
  },
  {
    "id": "3314082002",
    "district_id": "331408",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3314082003",
    "district_id": "331408",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3314082004",
    "district_id": "331408",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3314082005",
    "district_id": "331408",
    "label": "Kebonromo",
    "value": "Kebonromo"
  },
  {
    "id": "3314082006",
    "district_id": "331408",
    "label": "Gabus",
    "value": "Gabus"
  },
  {
    "id": "3314082007",
    "district_id": "331408",
    "label": "Karangudi",
    "value": "Karangudi"
  },
  {
    "id": "3314082008",
    "district_id": "331408",
    "label": "Klandungan",
    "value": "Klandungan"
  },
  {
    "id": "3314091009",
    "district_id": "331409",
    "label": "Plumbungan",
    "value": "Plumbungan"
  },
  {
    "id": "3314091010",
    "district_id": "331409",
    "label": "Kroyo",
    "value": "Kroyo"
  },
  {
    "id": "3314092001",
    "district_id": "331409",
    "label": "Kedungwaduk",
    "value": "Kedungwaduk"
  },
  {
    "id": "3314092002",
    "district_id": "331409",
    "label": "Jurangjero",
    "value": "Jurangjero"
  },
  {
    "id": "3314092003",
    "district_id": "331409",
    "label": "Saradan",
    "value": "Saradan"
  },
  {
    "id": "3314092004",
    "district_id": "331409",
    "label": "Plosokerep",
    "value": "Plosokerep"
  },
  {
    "id": "3314092005",
    "district_id": "331409",
    "label": "Guworejo",
    "value": "Guworejo"
  },
  {
    "id": "3314092006",
    "district_id": "331409",
    "label": "Puro",
    "value": "Puro"
  },
  {
    "id": "3314092007",
    "district_id": "331409",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3314092008",
    "district_id": "331409",
    "label": "Pelemgadung",
    "value": "Pelemgadung"
  },
  {
    "id": "3314101001",
    "district_id": "331410",
    "label": "Sine",
    "value": "Sine"
  },
  {
    "id": "3314101002",
    "district_id": "331410",
    "label": "Sragen Kulon",
    "value": "Sragen Kulon"
  },
  {
    "id": "3314101003",
    "district_id": "331410",
    "label": "Sragen Tengah",
    "value": "Sragen Tengah"
  },
  {
    "id": "3314101004",
    "district_id": "331410",
    "label": "Sragen Wetan",
    "value": "Sragen Wetan"
  },
  {
    "id": "3314101005",
    "district_id": "331410",
    "label": "Nglorog",
    "value": "Nglorog"
  },
  {
    "id": "3314101006",
    "district_id": "331410",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3314102007",
    "district_id": "331410",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3314102008",
    "district_id": "331410",
    "label": "Kedungupit",
    "value": "Kedungupit"
  },
  {
    "id": "3314112001",
    "district_id": "331411",
    "label": "Bentak",
    "value": "Bentak"
  },
  {
    "id": "3314112002",
    "district_id": "331411",
    "label": "Purwosuman",
    "value": "Purwosuman"
  },
  {
    "id": "3314112003",
    "district_id": "331411",
    "label": "Patihan",
    "value": "Patihan"
  },
  {
    "id": "3314112004",
    "district_id": "331411",
    "label": "Duyungan",
    "value": "Duyungan"
  },
  {
    "id": "3314112005",
    "district_id": "331411",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3314112006",
    "district_id": "331411",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3314112007",
    "district_id": "331411",
    "label": "Singopadu",
    "value": "Singopadu"
  },
  {
    "id": "3314112008",
    "district_id": "331411",
    "label": "Taraman",
    "value": "Taraman"
  },
  {
    "id": "3314112009",
    "district_id": "331411",
    "label": "Tenggak",
    "value": "Tenggak"
  },
  {
    "id": "3314112010",
    "district_id": "331411",
    "label": "Sribit",
    "value": "Sribit"
  },
  {
    "id": "3314112011",
    "district_id": "331411",
    "label": "Jambanan",
    "value": "Jambanan"
  },
  {
    "id": "3314112012",
    "district_id": "331411",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "3314122001",
    "district_id": "331412",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3314122002",
    "district_id": "331412",
    "label": "Slogo",
    "value": "Slogo"
  },
  {
    "id": "3314122003",
    "district_id": "331412",
    "label": "Jono",
    "value": "Jono"
  },
  {
    "id": "3314122004",
    "district_id": "331412",
    "label": "Gawan",
    "value": "Gawan"
  },
  {
    "id": "3314122005",
    "district_id": "331412",
    "label": "Kecik",
    "value": "Kecik"
  },
  {
    "id": "3314122006",
    "district_id": "331412",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3314122007",
    "district_id": "331412",
    "label": "Suwatu",
    "value": "Suwatu"
  },
  {
    "id": "3314122008",
    "district_id": "331412",
    "label": "Ketro",
    "value": "Ketro"
  },
  {
    "id": "3314122009",
    "district_id": "331412",
    "label": "Padas",
    "value": "Padas"
  },
  {
    "id": "3314122010",
    "district_id": "331412",
    "label": "Tanon",
    "value": "Tanon"
  },
  {
    "id": "3314122011",
    "district_id": "331412",
    "label": "Gabugan",
    "value": "Gabugan"
  },
  {
    "id": "3314122012",
    "district_id": "331412",
    "label": "Sambiduwur",
    "value": "Sambiduwur"
  },
  {
    "id": "3314122013",
    "district_id": "331412",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3314122014",
    "district_id": "331412",
    "label": "Bonagung",
    "value": "Bonagung"
  },
  {
    "id": "3314122015",
    "district_id": "331412",
    "label": "Kalikobok",
    "value": "Kalikobok"
  },
  {
    "id": "3314122016",
    "district_id": "331412",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3314131002",
    "district_id": "331413",
    "label": "Ngembatpadas",
    "value": "Ngembatpadas"
  },
  {
    "id": "3314131003",
    "district_id": "331413",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3314131009",
    "district_id": "331413",
    "label": "Gemolong",
    "value": "Gemolong"
  },
  {
    "id": "3314131010",
    "district_id": "331413",
    "label": "Kwangen",
    "value": "Kwangen"
  },
  {
    "id": "3314132001",
    "district_id": "331413",
    "label": "Kaloran",
    "value": "Kaloran"
  },
  {
    "id": "3314132004",
    "district_id": "331413",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3314132005",
    "district_id": "331413",
    "label": "Jatibatur",
    "value": "Jatibatur"
  },
  {
    "id": "3314132006",
    "district_id": "331413",
    "label": "Peleman",
    "value": "Peleman"
  },
  {
    "id": "3314132007",
    "district_id": "331413",
    "label": "Genengduwur",
    "value": "Genengduwur"
  },
  {
    "id": "3314132008",
    "district_id": "331413",
    "label": "Tegaldowo",
    "value": "Tegaldowo"
  },
  {
    "id": "3314132011",
    "district_id": "331413",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3314132012",
    "district_id": "331413",
    "label": "Jenalas",
    "value": "Jenalas"
  },
  {
    "id": "3314132013",
    "district_id": "331413",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "3314132014",
    "district_id": "331413",
    "label": "Nganti",
    "value": "Nganti"
  },
  {
    "id": "3314142001",
    "district_id": "331414",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3314142002",
    "district_id": "331414",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3314142003",
    "district_id": "331414",
    "label": "Sunggingan",
    "value": "Sunggingan"
  },
  {
    "id": "3314142004",
    "district_id": "331414",
    "label": "Girimargo",
    "value": "Girimargo"
  },
  {
    "id": "3314142005",
    "district_id": "331414",
    "label": "Doyong",
    "value": "Doyong"
  },
  {
    "id": "3314142006",
    "district_id": "331414",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3314142007",
    "district_id": "331414",
    "label": "Brojol",
    "value": "Brojol"
  },
  {
    "id": "3314142008",
    "district_id": "331414",
    "label": "Bagor",
    "value": "Bagor"
  },
  {
    "id": "3314142009",
    "district_id": "331414",
    "label": "Gilirejo",
    "value": "Gilirejo"
  },
  {
    "id": "3314142010",
    "district_id": "331414",
    "label": "Gilirejo Baru",
    "value": "Gilirejo Baru"
  },
  {
    "id": "3314152001",
    "district_id": "331415",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "3314152002",
    "district_id": "331415",
    "label": "Hadiluwih",
    "value": "Hadiluwih"
  },
  {
    "id": "3314152003",
    "district_id": "331415",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3314152004",
    "district_id": "331415",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3314152005",
    "district_id": "331415",
    "label": "Mojopuro",
    "value": "Mojopuro"
  },
  {
    "id": "3314152006",
    "district_id": "331415",
    "label": "Ngandul",
    "value": "Ngandul"
  },
  {
    "id": "3314152007",
    "district_id": "331415",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3314152008",
    "district_id": "331415",
    "label": "Pagak",
    "value": "Pagak"
  },
  {
    "id": "3314152009",
    "district_id": "331415",
    "label": "Tlogotirto",
    "value": "Tlogotirto"
  },
  {
    "id": "3314152010",
    "district_id": "331415",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3314152011",
    "district_id": "331415",
    "label": "Ngargotirto",
    "value": "Ngargotirto"
  },
  {
    "id": "3314162001",
    "district_id": "331416",
    "label": "Sono",
    "value": "Sono"
  },
  {
    "id": "3314162002",
    "district_id": "331416",
    "label": "Tempelrejo",
    "value": "Tempelrejo"
  },
  {
    "id": "3314162003",
    "district_id": "331416",
    "label": "Trombol",
    "value": "Trombol"
  },
  {
    "id": "3314162004",
    "district_id": "331416",
    "label": "Pare",
    "value": "Pare"
  },
  {
    "id": "3314162005",
    "district_id": "331416",
    "label": "Jekani",
    "value": "Jekani"
  },
  {
    "id": "3314162006",
    "district_id": "331416",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3314162007",
    "district_id": "331416",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3314162008",
    "district_id": "331416",
    "label": "Gemantar",
    "value": "Gemantar"
  },
  {
    "id": "3314162009",
    "district_id": "331416",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3314172001",
    "district_id": "331417",
    "label": "Newung",
    "value": "Newung"
  },
  {
    "id": "3314172002",
    "district_id": "331417",
    "label": "Jatitengah",
    "value": "Jatitengah"
  },
  {
    "id": "3314172003",
    "district_id": "331417",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3314172004",
    "district_id": "331417",
    "label": "Juwok",
    "value": "Juwok"
  },
  {
    "id": "3314172005",
    "district_id": "331417",
    "label": "Pantirejo",
    "value": "Pantirejo"
  },
  {
    "id": "3314172006",
    "district_id": "331417",
    "label": "Majenang",
    "value": "Majenang"
  },
  {
    "id": "3314172007",
    "district_id": "331417",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3314172008",
    "district_id": "331417",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3314172009",
    "district_id": "331417",
    "label": "Baleharjo",
    "value": "Baleharjo"
  },
  {
    "id": "3314182001",
    "district_id": "331418",
    "label": "Tanggan",
    "value": "Tanggan"
  },
  {
    "id": "3314182002",
    "district_id": "331418",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3314182003",
    "district_id": "331418",
    "label": "Blangu",
    "value": "Blangu"
  },
  {
    "id": "3314182004",
    "district_id": "331418",
    "label": "Gesi",
    "value": "Gesi"
  },
  {
    "id": "3314182005",
    "district_id": "331418",
    "label": "Srawung",
    "value": "Srawung"
  },
  {
    "id": "3314182006",
    "district_id": "331418",
    "label": "Poleng",
    "value": "Poleng"
  },
  {
    "id": "3314182007",
    "district_id": "331418",
    "label": "Slendro",
    "value": "Slendro"
  },
  {
    "id": "3314192001",
    "district_id": "331419",
    "label": "Katelan",
    "value": "Katelan"
  },
  {
    "id": "3314192002",
    "district_id": "331419",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3314192003",
    "district_id": "331419",
    "label": "Jekawal",
    "value": "Jekawal"
  },
  {
    "id": "3314192004",
    "district_id": "331419",
    "label": "Galeh",
    "value": "Galeh"
  },
  {
    "id": "3314192005",
    "district_id": "331419",
    "label": "Ngrombo",
    "value": "Ngrombo"
  },
  {
    "id": "3314192006",
    "district_id": "331419",
    "label": "Sigit",
    "value": "Sigit"
  },
  {
    "id": "3314192007",
    "district_id": "331419",
    "label": "Denanyar",
    "value": "Denanyar"
  },
  {
    "id": "3314202001",
    "district_id": "331420",
    "label": "Japoh",
    "value": "Japoh"
  },
  {
    "id": "3314202002",
    "district_id": "331420",
    "label": "Ngepringan",
    "value": "Ngepringan"
  },
  {
    "id": "3314202003",
    "district_id": "331420",
    "label": "Mlale",
    "value": "Mlale"
  },
  {
    "id": "3314202004",
    "district_id": "331420",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3314202005",
    "district_id": "331420",
    "label": "Kandangsapi",
    "value": "Kandangsapi"
  },
  {
    "id": "3314202006",
    "district_id": "331420",
    "label": "Jenar",
    "value": "Jenar"
  },
  {
    "id": "3314202007",
    "district_id": "331420",
    "label": "Banyurip",
    "value": "Banyurip"
  },
  {
    "id": "3315012001",
    "district_id": "331501",
    "label": "Karanglangu",
    "value": "Karanglangu"
  },
  {
    "id": "3315012002",
    "district_id": "331501",
    "label": "Panimbo",
    "value": "Panimbo"
  },
  {
    "id": "3315012003",
    "district_id": "331501",
    "label": "Deras",
    "value": "Deras"
  },
  {
    "id": "3315012004",
    "district_id": "331501",
    "label": "Kentengsari",
    "value": "Kentengsari"
  },
  {
    "id": "3315012005",
    "district_id": "331501",
    "label": "Ngombak",
    "value": "Ngombak"
  },
  {
    "id": "3315012006",
    "district_id": "331501",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3315012007",
    "district_id": "331501",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "3315012008",
    "district_id": "331501",
    "label": "Klitikan",
    "value": "Klitikan"
  },
  {
    "id": "3315012009",
    "district_id": "331501",
    "label": "Padas",
    "value": "Padas"
  },
  {
    "id": "3315012010",
    "district_id": "331501",
    "label": "Kalimaro",
    "value": "Kalimaro"
  },
  {
    "id": "3315012011",
    "district_id": "331501",
    "label": "Jumo",
    "value": "Jumo"
  },
  {
    "id": "3315012012",
    "district_id": "331501",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3315022001",
    "district_id": "331502",
    "label": "Nampu",
    "value": "Nampu"
  },
  {
    "id": "3315022002",
    "district_id": "331502",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3315022003",
    "district_id": "331502",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3315022004",
    "district_id": "331502",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3315022005",
    "district_id": "331502",
    "label": "Telawah",
    "value": "Telawah"
  },
  {
    "id": "3315022006",
    "district_id": "331502",
    "label": "Cekel",
    "value": "Cekel"
  },
  {
    "id": "3315022007",
    "district_id": "331502",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3315022008",
    "district_id": "331502",
    "label": "Sendangharjo",
    "value": "Sendangharjo"
  },
  {
    "id": "3315022009",
    "district_id": "331502",
    "label": "Mangin",
    "value": "Mangin"
  },
  {
    "id": "3315022010",
    "district_id": "331502",
    "label": "Sumberjosari",
    "value": "Sumberjosari"
  },
  {
    "id": "3315022011",
    "district_id": "331502",
    "label": "Ketro",
    "value": "Ketro"
  },
  {
    "id": "3315022012",
    "district_id": "331502",
    "label": "Gunungtumpeng",
    "value": "Gunungtumpeng"
  },
  {
    "id": "3315022013",
    "district_id": "331502",
    "label": "Temurejo",
    "value": "Temurejo"
  },
  {
    "id": "3315022014",
    "district_id": "331502",
    "label": "Putatnganten",
    "value": "Putatnganten"
  },
  {
    "id": "3315022015",
    "district_id": "331502",
    "label": "Termas",
    "value": "Termas"
  },
  {
    "id": "3315022016",
    "district_id": "331502",
    "label": "Dempel",
    "value": "Dempel"
  },
  {
    "id": "3315022017",
    "district_id": "331502",
    "label": "Rawoh",
    "value": "Rawoh"
  },
  {
    "id": "3315022018",
    "district_id": "331502",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3315022019",
    "district_id": "331502",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3315032001",
    "district_id": "331503",
    "label": "Lajer",
    "value": "Lajer"
  },
  {
    "id": "3315032002",
    "district_id": "331503",
    "label": "Sedadi",
    "value": "Sedadi"
  },
  {
    "id": "3315032003",
    "district_id": "331503",
    "label": "Bologarang",
    "value": "Bologarang"
  },
  {
    "id": "3315032004",
    "district_id": "331503",
    "label": "Karangwader",
    "value": "Karangwader"
  },
  {
    "id": "3315032005",
    "district_id": "331503",
    "label": "Toko",
    "value": "Toko"
  },
  {
    "id": "3315032006",
    "district_id": "331503",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3315032007",
    "district_id": "331503",
    "label": "Leyangan",
    "value": "Leyangan"
  },
  {
    "id": "3315032008",
    "district_id": "331503",
    "label": "Watupawon",
    "value": "Watupawon"
  },
  {
    "id": "3315032009",
    "district_id": "331503",
    "label": "Tunggu",
    "value": "Tunggu"
  },
  {
    "id": "3315032010",
    "district_id": "331503",
    "label": "Jipang",
    "value": "Jipang"
  },
  {
    "id": "3315032011",
    "district_id": "331503",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3315032012",
    "district_id": "331503",
    "label": "Curut",
    "value": "Curut"
  },
  {
    "id": "3315032013",
    "district_id": "331503",
    "label": "Wedoro",
    "value": "Wedoro"
  },
  {
    "id": "3315032014",
    "district_id": "331503",
    "label": "Kluwan",
    "value": "Kluwan"
  },
  {
    "id": "3315032015",
    "district_id": "331503",
    "label": "Karangpahing",
    "value": "Karangpahing"
  },
  {
    "id": "3315032016",
    "district_id": "331503",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "3315032017",
    "district_id": "331503",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3315032018",
    "district_id": "331503",
    "label": "Wolo",
    "value": "Wolo"
  },
  {
    "id": "3315032019",
    "district_id": "331503",
    "label": "Ngeluk",
    "value": "Ngeluk"
  },
  {
    "id": "3315032020",
    "district_id": "331503",
    "label": "Penawangan",
    "value": "Penawangan"
  },
  {
    "id": "3315042001",
    "district_id": "331504",
    "label": "Ngrandah",
    "value": "Ngrandah"
  },
  {
    "id": "3315042002",
    "district_id": "331504",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3315042003",
    "district_id": "331504",
    "label": "Genengsari",
    "value": "Genengsari"
  },
  {
    "id": "3315042004",
    "district_id": "331504",
    "label": "Bandungharjo",
    "value": "Bandungharjo"
  },
  {
    "id": "3315042005",
    "district_id": "331504",
    "label": "Sindurejo",
    "value": "Sindurejo"
  },
  {
    "id": "3315042006",
    "district_id": "331504",
    "label": "Genengadal",
    "value": "Genengadal"
  },
  {
    "id": "3315042007",
    "district_id": "331504",
    "label": "Dimoro",
    "value": "Dimoro"
  },
  {
    "id": "3315042008",
    "district_id": "331504",
    "label": "Katong",
    "value": "Katong"
  },
  {
    "id": "3315042009",
    "district_id": "331504",
    "label": "Pilangpayung",
    "value": "Pilangpayung"
  },
  {
    "id": "3315042010",
    "district_id": "331504",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3315042011",
    "district_id": "331504",
    "label": "Krangganharjo",
    "value": "Krangganharjo"
  },
  {
    "id": "3315042012",
    "district_id": "331504",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3315042013",
    "district_id": "331504",
    "label": "Tambirejo",
    "value": "Tambirejo"
  },
  {
    "id": "3315042014",
    "district_id": "331504",
    "label": "Plosoharjo",
    "value": "Plosoharjo"
  },
  {
    "id": "3315042015",
    "district_id": "331504",
    "label": "Boloh",
    "value": "Boloh"
  },
  {
    "id": "3315042016",
    "district_id": "331504",
    "label": "Tunggak",
    "value": "Tunggak"
  },
  {
    "id": "3315052001",
    "district_id": "331505",
    "label": "Rambat",
    "value": "Rambat"
  },
  {
    "id": "3315052002",
    "district_id": "331505",
    "label": "Kalangbancar",
    "value": "Kalangbancar"
  },
  {
    "id": "3315052003",
    "district_id": "331505",
    "label": "Juworo",
    "value": "Juworo"
  },
  {
    "id": "3315052004",
    "district_id": "331505",
    "label": "Monggot",
    "value": "Monggot"
  },
  {
    "id": "3315052005",
    "district_id": "331505",
    "label": "Ngrandu",
    "value": "Ngrandu"
  },
  {
    "id": "3315052006",
    "district_id": "331505",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3315052007",
    "district_id": "331505",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3315052008",
    "district_id": "331505",
    "label": "Asemrudung",
    "value": "Asemrudung"
  },
  {
    "id": "3315052009",
    "district_id": "331505",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3315052010",
    "district_id": "331505",
    "label": "Geyer",
    "value": "Geyer"
  },
  {
    "id": "3315052011",
    "district_id": "331505",
    "label": "Ledokdawan",
    "value": "Ledokdawan"
  },
  {
    "id": "3315052012",
    "district_id": "331505",
    "label": "Sobo",
    "value": "Sobo"
  },
  {
    "id": "3315052013",
    "district_id": "331505",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "3315062001",
    "district_id": "331506",
    "label": "Randurejo",
    "value": "Randurejo"
  },
  {
    "id": "3315062002",
    "district_id": "331506",
    "label": "Mlowokarang Talun",
    "value": "Mlowokarang Talun"
  },
  {
    "id": "3315062003",
    "district_id": "331506",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3315062004",
    "district_id": "331506",
    "label": "Jatiharjo",
    "value": "Jatiharjo"
  },
  {
    "id": "3315062005",
    "district_id": "331506",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3315062006",
    "district_id": "331506",
    "label": "Tuko",
    "value": "Tuko"
  },
  {
    "id": "3315062007",
    "district_id": "331506",
    "label": "Panunggalan",
    "value": "Panunggalan"
  },
  {
    "id": "3315062008",
    "district_id": "331506",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3315062009",
    "district_id": "331506",
    "label": "Jetaksari",
    "value": "Jetaksari"
  },
  {
    "id": "3315062010",
    "district_id": "331506",
    "label": "Pulokulon",
    "value": "Pulokulon"
  },
  {
    "id": "3315062011",
    "district_id": "331506",
    "label": "Jambon",
    "value": "Jambon"
  },
  {
    "id": "3315062012",
    "district_id": "331506",
    "label": "Karangharjo",
    "value": "Karangharjo"
  },
  {
    "id": "3315062013",
    "district_id": "331506",
    "label": "Sembungharjo",
    "value": "Sembungharjo"
  },
  {
    "id": "3315072001",
    "district_id": "331507",
    "label": "Sengonwetan",
    "value": "Sengonwetan"
  },
  {
    "id": "3315072002",
    "district_id": "331507",
    "label": "Sambongbangi",
    "value": "Sambongbangi"
  },
  {
    "id": "3315072003",
    "district_id": "331507",
    "label": "Banjardowo",
    "value": "Banjardowo"
  },
  {
    "id": "3315072004",
    "district_id": "331507",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3315072005",
    "district_id": "331507",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3315072006",
    "district_id": "331507",
    "label": "Bago",
    "value": "Bago"
  },
  {
    "id": "3315072007",
    "district_id": "331507",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3315072008",
    "district_id": "331507",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3315072009",
    "district_id": "331507",
    "label": "Crewek",
    "value": "Crewek"
  },
  {
    "id": "3315072010",
    "district_id": "331507",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3315072011",
    "district_id": "331507",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3315072012",
    "district_id": "331507",
    "label": "Kuwu",
    "value": "Kuwu"
  },
  {
    "id": "3315072013",
    "district_id": "331507",
    "label": "Grabagan",
    "value": "Grabagan"
  },
  {
    "id": "3315072014",
    "district_id": "331507",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3315082001",
    "district_id": "331508",
    "label": "Keyongan",
    "value": "Keyongan"
  },
  {
    "id": "3315082002",
    "district_id": "331508",
    "label": "Suwatu",
    "value": "Suwatu"
  },
  {
    "id": "3315082003",
    "district_id": "331508",
    "label": "Nglinduk",
    "value": "Nglinduk"
  },
  {
    "id": "3315082004",
    "district_id": "331508",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3315082005",
    "district_id": "331508",
    "label": "Sulursari",
    "value": "Sulursari"
  },
  {
    "id": "3315082006",
    "district_id": "331508",
    "label": "Tlogotirto",
    "value": "Tlogotirto"
  },
  {
    "id": "3315082007",
    "district_id": "331508",
    "label": "Gabus",
    "value": "Gabus"
  },
  {
    "id": "3315082008",
    "district_id": "331508",
    "label": "Pandanharum",
    "value": "Pandanharum"
  },
  {
    "id": "3315082009",
    "district_id": "331508",
    "label": "Tunggulrejo",
    "value": "Tunggulrejo"
  },
  {
    "id": "3315082010",
    "district_id": "331508",
    "label": "Tahunan",
    "value": "Tahunan"
  },
  {
    "id": "3315082011",
    "district_id": "331508",
    "label": "Bendoharjo",
    "value": "Bendoharjo"
  },
  {
    "id": "3315082012",
    "district_id": "331508",
    "label": "Kalipang",
    "value": "Kalipang"
  },
  {
    "id": "3315082013",
    "district_id": "331508",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3315082014",
    "district_id": "331508",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3315092001",
    "district_id": "331509",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3315092002",
    "district_id": "331509",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3315092003",
    "district_id": "331509",
    "label": "Kalangdosari",
    "value": "Kalangdosari"
  },
  {
    "id": "3315092004",
    "district_id": "331509",
    "label": "Kalanglundo",
    "value": "Kalanglundo"
  },
  {
    "id": "3315092005",
    "district_id": "331509",
    "label": "Truwolu",
    "value": "Truwolu"
  },
  {
    "id": "3315092006",
    "district_id": "331509",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "3315092007",
    "district_id": "331509",
    "label": "Bandungsari",
    "value": "Bandungsari"
  },
  {
    "id": "3315092008",
    "district_id": "331509",
    "label": "Ngaringan",
    "value": "Ngaringan"
  },
  {
    "id": "3315092009",
    "district_id": "331509",
    "label": "Ngarap-arap",
    "value": "Ngarap-arap"
  },
  {
    "id": "3315092010",
    "district_id": "331509",
    "label": "Belor",
    "value": "Belor"
  },
  {
    "id": "3315092011",
    "district_id": "331509",
    "label": "Tanjungharjo",
    "value": "Tanjungharjo"
  },
  {
    "id": "3315092012",
    "district_id": "331509",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3315101006",
    "district_id": "331510",
    "label": "Wirosari",
    "value": "Wirosari"
  },
  {
    "id": "3315101007",
    "district_id": "331510",
    "label": "Kunden",
    "value": "Kunden"
  },
  {
    "id": "3315102001",
    "district_id": "331510",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3315102002",
    "district_id": "331510",
    "label": "Kropak",
    "value": "Kropak"
  },
  {
    "id": "3315102003",
    "district_id": "331510",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3315102004",
    "district_id": "331510",
    "label": "Dapurno",
    "value": "Dapurno"
  },
  {
    "id": "3315102005",
    "district_id": "331510",
    "label": "Mojorebo",
    "value": "Mojorebo"
  },
  {
    "id": "3315102008",
    "district_id": "331510",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3315102009",
    "district_id": "331510",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3315102010",
    "district_id": "331510",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3315102011",
    "district_id": "331510",
    "label": "Tambakselo",
    "value": "Tambakselo"
  },
  {
    "id": "3315102012",
    "district_id": "331510",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3315102013",
    "district_id": "331510",
    "label": "Dokoro",
    "value": "Dokoro"
  },
  {
    "id": "3315102014",
    "district_id": "331510",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3315112001",
    "district_id": "331511",
    "label": "Mayahan",
    "value": "Mayahan"
  },
  {
    "id": "3315112002",
    "district_id": "331511",
    "label": "Pulongrambe",
    "value": "Pulongrambe"
  },
  {
    "id": "3315112003",
    "district_id": "331511",
    "label": "Jono",
    "value": "Jono"
  },
  {
    "id": "3315112004",
    "district_id": "331511",
    "label": "Selo",
    "value": "Selo"
  },
  {
    "id": "3315112005",
    "district_id": "331511",
    "label": "Tawangharjo",
    "value": "Tawangharjo"
  },
  {
    "id": "3315112006",
    "district_id": "331511",
    "label": "Tarub",
    "value": "Tarub"
  },
  {
    "id": "3315112007",
    "district_id": "331511",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3315112008",
    "district_id": "331511",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3315112009",
    "district_id": "331511",
    "label": "Godan",
    "value": "Godan"
  },
  {
    "id": "3315112010",
    "district_id": "331511",
    "label": "Kemadahbatur",
    "value": "Kemadahbatur"
  },
  {
    "id": "3315121001",
    "district_id": "331512",
    "label": "Grobogan",
    "value": "Grobogan"
  },
  {
    "id": "3315122002",
    "district_id": "331512",
    "label": "Getasrejo",
    "value": "Getasrejo"
  },
  {
    "id": "3315122003",
    "district_id": "331512",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3315122004",
    "district_id": "331512",
    "label": "Tanggungharjo",
    "value": "Tanggungharjo"
  },
  {
    "id": "3315122005",
    "district_id": "331512",
    "label": "Teguhan",
    "value": "Teguhan"
  },
  {
    "id": "3315122006",
    "district_id": "331512",
    "label": "Ngabenrejo",
    "value": "Ngabenrejo"
  },
  {
    "id": "3315122007",
    "district_id": "331512",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3315122008",
    "district_id": "331512",
    "label": "Putatsari",
    "value": "Putatsari"
  },
  {
    "id": "3315122009",
    "district_id": "331512",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3315122010",
    "district_id": "331512",
    "label": "Lebengjumuk",
    "value": "Lebengjumuk"
  },
  {
    "id": "3315122011",
    "district_id": "331512",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3315122012",
    "district_id": "331512",
    "label": "Sumberjatipohon",
    "value": "Sumberjatipohon"
  },
  {
    "id": "3315131003",
    "district_id": "331513",
    "label": "Danyang",
    "value": "Danyang"
  },
  {
    "id": "3315131004",
    "district_id": "331513",
    "label": "Kalongan",
    "value": "Kalongan"
  },
  {
    "id": "3315131012",
    "district_id": "331513",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3315131013",
    "district_id": "331513",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3315132001",
    "district_id": "331513",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3315132002",
    "district_id": "331513",
    "label": "Genuksuran",
    "value": "Genuksuran"
  },
  {
    "id": "3315132005",
    "district_id": "331513",
    "label": "Ngraji",
    "value": "Ngraji"
  },
  {
    "id": "3315132006",
    "district_id": "331513",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3315132007",
    "district_id": "331513",
    "label": "Nambuhan",
    "value": "Nambuhan"
  },
  {
    "id": "3315132008",
    "district_id": "331513",
    "label": "Warukaranganyar",
    "value": "Warukaranganyar"
  },
  {
    "id": "3315132009",
    "district_id": "331513",
    "label": "Nglobar",
    "value": "Nglobar"
  },
  {
    "id": "3315132010",
    "district_id": "331513",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3315132011",
    "district_id": "331513",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3315132014",
    "district_id": "331513",
    "label": "Ngembak",
    "value": "Ngembak"
  },
  {
    "id": "3315132015",
    "district_id": "331513",
    "label": "Cingkrong",
    "value": "Cingkrong"
  },
  {
    "id": "3315132016",
    "district_id": "331513",
    "label": "Pulorejo",
    "value": "Pulorejo"
  },
  {
    "id": "3315132017",
    "district_id": "331513",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "3315142001",
    "district_id": "331514",
    "label": "Menduran",
    "value": "Menduran"
  },
  {
    "id": "3315142002",
    "district_id": "331514",
    "label": "Jangkungharjo",
    "value": "Jangkungharjo"
  },
  {
    "id": "3315142003",
    "district_id": "331514",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3315142004",
    "district_id": "331514",
    "label": "Lemah Putih",
    "value": "Lemah Putih"
  },
  {
    "id": "3315142005",
    "district_id": "331514",
    "label": "Tirem",
    "value": "Tirem"
  },
  {
    "id": "3315142006",
    "district_id": "331514",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3315142007",
    "district_id": "331514",
    "label": "Kronggen",
    "value": "Kronggen"
  },
  {
    "id": "3315142008",
    "district_id": "331514",
    "label": "Katekan",
    "value": "Katekan"
  },
  {
    "id": "3315142009",
    "district_id": "331514",
    "label": "Tegalsumur",
    "value": "Tegalsumur"
  },
  {
    "id": "3315152001",
    "district_id": "331515",
    "label": "Kandangrejo",
    "value": "Kandangrejo"
  },
  {
    "id": "3315152002",
    "district_id": "331515",
    "label": "Selojari",
    "value": "Selojari"
  },
  {
    "id": "3315152003",
    "district_id": "331515",
    "label": "Taruman",
    "value": "Taruman"
  },
  {
    "id": "3315152004",
    "district_id": "331515",
    "label": "Penganten",
    "value": "Penganten"
  },
  {
    "id": "3315152005",
    "district_id": "331515",
    "label": "Klambu",
    "value": "Klambu"
  },
  {
    "id": "3315152006",
    "district_id": "331515",
    "label": "Menawan",
    "value": "Menawan"
  },
  {
    "id": "3315152007",
    "district_id": "331515",
    "label": "Terkesi",
    "value": "Terkesi"
  },
  {
    "id": "3315152008",
    "district_id": "331515",
    "label": "Jenengan",
    "value": "Jenengan"
  },
  {
    "id": "3315152009",
    "district_id": "331515",
    "label": "Wandan Kemiri",
    "value": "Wandan Kemiri"
  },
  {
    "id": "3315162001",
    "district_id": "331516",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3315162002",
    "district_id": "331516",
    "label": "Werdoyo",
    "value": "Werdoyo"
  },
  {
    "id": "3315162003",
    "district_id": "331516",
    "label": "Guci",
    "value": "Guci"
  },
  {
    "id": "3315162004",
    "district_id": "331516",
    "label": "Anggaswangi",
    "value": "Anggaswangi"
  },
  {
    "id": "3315162005",
    "district_id": "331516",
    "label": "Ketangirejo",
    "value": "Ketangirejo"
  },
  {
    "id": "3315162006",
    "district_id": "331516",
    "label": "Sambung",
    "value": "Sambung"
  },
  {
    "id": "3315162007",
    "district_id": "331516",
    "label": "Sumurgede",
    "value": "Sumurgede"
  },
  {
    "id": "3315162008",
    "district_id": "331516",
    "label": "Latak",
    "value": "Latak"
  },
  {
    "id": "3315162009",
    "district_id": "331516",
    "label": "Tungu",
    "value": "Tungu"
  },
  {
    "id": "3315162010",
    "district_id": "331516",
    "label": "Pahesan",
    "value": "Pahesan"
  },
  {
    "id": "3315162011",
    "district_id": "331516",
    "label": "Wanutunggal",
    "value": "Wanutunggal"
  },
  {
    "id": "3315162012",
    "district_id": "331516",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "3315162013",
    "district_id": "331516",
    "label": "Manggarwetan",
    "value": "Manggarwetan"
  },
  {
    "id": "3315162014",
    "district_id": "331516",
    "label": "Tinanding",
    "value": "Tinanding"
  },
  {
    "id": "3315162015",
    "district_id": "331516",
    "label": "Manggarmas",
    "value": "Manggarmas"
  },
  {
    "id": "3315162016",
    "district_id": "331516",
    "label": "Harjowinangun",
    "value": "Harjowinangun"
  },
  {
    "id": "3315162017",
    "district_id": "331516",
    "label": "Rajek",
    "value": "Rajek"
  },
  {
    "id": "3315162018",
    "district_id": "331516",
    "label": "Dorolegi",
    "value": "Dorolegi"
  },
  {
    "id": "3315162019",
    "district_id": "331516",
    "label": "Kopek",
    "value": "Kopek"
  },
  {
    "id": "3315162020",
    "district_id": "331516",
    "label": "Kemloko",
    "value": "Kemloko"
  },
  {
    "id": "3315162021",
    "district_id": "331516",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3315162022",
    "district_id": "331516",
    "label": "Gundi",
    "value": "Gundi"
  },
  {
    "id": "3315162023",
    "district_id": "331516",
    "label": "Jatilor",
    "value": "Jatilor"
  },
  {
    "id": "3315162024",
    "district_id": "331516",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3315162025",
    "district_id": "331516",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3315162026",
    "district_id": "331516",
    "label": "Godong",
    "value": "Godong"
  },
  {
    "id": "3315162027",
    "district_id": "331516",
    "label": "Ketitang",
    "value": "Ketitang"
  },
  {
    "id": "3315162028",
    "district_id": "331516",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3315172001",
    "district_id": "331517",
    "label": "Penadaran",
    "value": "Penadaran"
  },
  {
    "id": "3315172002",
    "district_id": "331517",
    "label": "Ginggangtani",
    "value": "Ginggangtani"
  },
  {
    "id": "3315172003",
    "district_id": "331517",
    "label": "Glapan",
    "value": "Glapan"
  },
  {
    "id": "3315172004",
    "district_id": "331517",
    "label": "Trisari",
    "value": "Trisari"
  },
  {
    "id": "3315172005",
    "district_id": "331517",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3315172006",
    "district_id": "331517",
    "label": "Jeketro",
    "value": "Jeketro"
  },
  {
    "id": "3315172007",
    "district_id": "331517",
    "label": "Saban",
    "value": "Saban"
  },
  {
    "id": "3315172008",
    "district_id": "331517",
    "label": "Mlilir",
    "value": "Mlilir"
  },
  {
    "id": "3315172009",
    "district_id": "331517",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3315172010",
    "district_id": "331517",
    "label": "Papanrejo",
    "value": "Papanrejo"
  },
  {
    "id": "3315172011",
    "district_id": "331517",
    "label": "Kunjeng",
    "value": "Kunjeng"
  },
  {
    "id": "3315172012",
    "district_id": "331517",
    "label": "Kuwaron",
    "value": "Kuwaron"
  },
  {
    "id": "3315172013",
    "district_id": "331517",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3315172014",
    "district_id": "331517",
    "label": "Gubug",
    "value": "Gubug"
  },
  {
    "id": "3315172015",
    "district_id": "331517",
    "label": "Pranten",
    "value": "Pranten"
  },
  {
    "id": "3315172016",
    "district_id": "331517",
    "label": "Baturagung",
    "value": "Baturagung"
  },
  {
    "id": "3315172017",
    "district_id": "331517",
    "label": "Jatipecaron",
    "value": "Jatipecaron"
  },
  {
    "id": "3315172018",
    "district_id": "331517",
    "label": "Tambakan",
    "value": "Tambakan"
  },
  {
    "id": "3315172019",
    "district_id": "331517",
    "label": "Ringinkidul",
    "value": "Ringinkidul"
  },
  {
    "id": "3315172020",
    "district_id": "331517",
    "label": "Ringinharjo",
    "value": "Ringinharjo"
  },
  {
    "id": "3315172021",
    "district_id": "331517",
    "label": "Tlogomulyo",
    "value": "Tlogomulyo"
  },
  {
    "id": "3315182001",
    "district_id": "331518",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3315182002",
    "district_id": "331518",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3315182003",
    "district_id": "331518",
    "label": "Medani",
    "value": "Medani"
  },
  {
    "id": "3315182004",
    "district_id": "331518",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3315182005",
    "district_id": "331518",
    "label": "Tanggirejo",
    "value": "Tanggirejo"
  },
  {
    "id": "3315182006",
    "district_id": "331518",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3315182007",
    "district_id": "331518",
    "label": "Gebangan",
    "value": "Gebangan"
  },
  {
    "id": "3315182008",
    "district_id": "331518",
    "label": "Kejawan",
    "value": "Kejawan"
  },
  {
    "id": "3315182009",
    "district_id": "331518",
    "label": "Tegowanuwetan",
    "value": "Tegowanuwetan"
  },
  {
    "id": "3315182010",
    "district_id": "331518",
    "label": "Tegowanukulon",
    "value": "Tegowanukulon"
  },
  {
    "id": "3315182011",
    "district_id": "331518",
    "label": "Tajemsari",
    "value": "Tajemsari"
  },
  {
    "id": "3315182012",
    "district_id": "331518",
    "label": "Karangpasar",
    "value": "Karangpasar"
  },
  {
    "id": "3315182013",
    "district_id": "331518",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3315182014",
    "district_id": "331518",
    "label": "Pepe",
    "value": "Pepe"
  },
  {
    "id": "3315182015",
    "district_id": "331518",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3315182016",
    "district_id": "331518",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3315182017",
    "district_id": "331518",
    "label": "Gaji",
    "value": "Gaji"
  },
  {
    "id": "3315182018",
    "district_id": "331518",
    "label": "Tunjungharjo",
    "value": "Tunjungharjo"
  },
  {
    "id": "3315192001",
    "district_id": "331519",
    "label": "Ringinpitu",
    "value": "Ringinpitu"
  },
  {
    "id": "3315192002",
    "district_id": "331519",
    "label": "Ngambakrejo",
    "value": "Ngambakrejo"
  },
  {
    "id": "3315192003",
    "district_id": "331519",
    "label": "Kapung",
    "value": "Kapung"
  },
  {
    "id": "3315192004",
    "district_id": "331519",
    "label": "Mrisi",
    "value": "Mrisi"
  },
  {
    "id": "3315192005",
    "district_id": "331519",
    "label": "Kaliwenang",
    "value": "Kaliwenang"
  },
  {
    "id": "3315192006",
    "district_id": "331519",
    "label": "Sugihmanik",
    "value": "Sugihmanik"
  },
  {
    "id": "3315192007",
    "district_id": "331519",
    "label": "Tanggungharjo",
    "value": "Tanggungharjo"
  },
  {
    "id": "3315192008",
    "district_id": "331519",
    "label": "Brabo",
    "value": "Brabo"
  },
  {
    "id": "3315192009",
    "district_id": "331519",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "3316012001",
    "district_id": "331601",
    "label": "Bangkleyan",
    "value": "Bangkleyan"
  },
  {
    "id": "3316012002",
    "district_id": "331601",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3316012003",
    "district_id": "331601",
    "label": "Kepoh",
    "value": "Kepoh"
  },
  {
    "id": "3316012004",
    "district_id": "331601",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3316012005",
    "district_id": "331601",
    "label": "Jegong",
    "value": "Jegong"
  },
  {
    "id": "3316012006",
    "district_id": "331601",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3316012007",
    "district_id": "331601",
    "label": "Singget",
    "value": "Singget"
  },
  {
    "id": "3316012008",
    "district_id": "331601",
    "label": "Gabusan",
    "value": "Gabusan"
  },
  {
    "id": "3316012009",
    "district_id": "331601",
    "label": "Doplang",
    "value": "Doplang"
  },
  {
    "id": "3316012010",
    "district_id": "331601",
    "label": "Randulawang",
    "value": "Randulawang"
  },
  {
    "id": "3316012011",
    "district_id": "331601",
    "label": "Tobo",
    "value": "Tobo"
  },
  {
    "id": "3316012012",
    "district_id": "331601",
    "label": "Pengkoljagong",
    "value": "Pengkoljagong"
  },
  {
    "id": "3316021005",
    "district_id": "331602",
    "label": "Randublatung",
    "value": "Randublatung"
  },
  {
    "id": "3316021011",
    "district_id": "331602",
    "label": "Wulung",
    "value": "Wulung"
  },
  {
    "id": "3316022001",
    "district_id": "331602",
    "label": "Tlogotuwung",
    "value": "Tlogotuwung"
  },
  {
    "id": "3316022002",
    "district_id": "331602",
    "label": "Bodeh",
    "value": "Bodeh"
  },
  {
    "id": "3316022003",
    "district_id": "331602",
    "label": "Gembyungan",
    "value": "Gembyungan"
  },
  {
    "id": "3316022004",
    "district_id": "331602",
    "label": "Sambongwangan",
    "value": "Sambongwangan"
  },
  {
    "id": "3316022006",
    "district_id": "331602",
    "label": "Pilang",
    "value": "Pilang"
  },
  {
    "id": "3316022007",
    "district_id": "331602",
    "label": "Temulus",
    "value": "Temulus"
  },
  {
    "id": "3316022008",
    "district_id": "331602",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3316022009",
    "district_id": "331602",
    "label": "Kutukan",
    "value": "Kutukan"
  },
  {
    "id": "3316022010",
    "district_id": "331602",
    "label": "Kediren",
    "value": "Kediren"
  },
  {
    "id": "3316022012",
    "district_id": "331602",
    "label": "Kadengan",
    "value": "Kadengan"
  },
  {
    "id": "3316022013",
    "district_id": "331602",
    "label": "Bekutuk",
    "value": "Bekutuk"
  },
  {
    "id": "3316022014",
    "district_id": "331602",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3316022015",
    "district_id": "331602",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3316022016",
    "district_id": "331602",
    "label": "Tanggel",
    "value": "Tanggel"
  },
  {
    "id": "3316022017",
    "district_id": "331602",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3316022018",
    "district_id": "331602",
    "label": "Ngliron",
    "value": "Ngliron"
  },
  {
    "id": "3316032001",
    "district_id": "331603",
    "label": "Megeri",
    "value": "Megeri"
  },
  {
    "id": "3316032002",
    "district_id": "331603",
    "label": "Nglebak",
    "value": "Nglebak"
  },
  {
    "id": "3316032003",
    "district_id": "331603",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3316032004",
    "district_id": "331603",
    "label": "Nginggil",
    "value": "Nginggil"
  },
  {
    "id": "3316032005",
    "district_id": "331603",
    "label": "Ngrawoh",
    "value": "Ngrawoh"
  },
  {
    "id": "3316032006",
    "district_id": "331603",
    "label": "Mendenrejo",
    "value": "Mendenrejo"
  },
  {
    "id": "3316032007",
    "district_id": "331603",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3316032008",
    "district_id": "331603",
    "label": "Mojorembun",
    "value": "Mojorembun"
  },
  {
    "id": "3316032009",
    "district_id": "331603",
    "label": "Nglungger",
    "value": "Nglungger"
  },
  {
    "id": "3316032010",
    "district_id": "331603",
    "label": "Medalem",
    "value": "Medalem"
  },
  {
    "id": "3316042001",
    "district_id": "331604",
    "label": "Gondel",
    "value": "Gondel"
  },
  {
    "id": "3316042002",
    "district_id": "331604",
    "label": "Ketuwan",
    "value": "Ketuwan"
  },
  {
    "id": "3316042003",
    "district_id": "331604",
    "label": "Jimbung",
    "value": "Jimbung"
  },
  {
    "id": "3316042004",
    "district_id": "331604",
    "label": "Panolan",
    "value": "Panolan"
  },
  {
    "id": "3316042005",
    "district_id": "331604",
    "label": "Klagen",
    "value": "Klagen"
  },
  {
    "id": "3316042006",
    "district_id": "331604",
    "label": "Kemantren",
    "value": "Kemantren"
  },
  {
    "id": "3316042007",
    "district_id": "331604",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3316042008",
    "district_id": "331604",
    "label": "Wado",
    "value": "Wado"
  },
  {
    "id": "3316042009",
    "district_id": "331604",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "3316042010",
    "district_id": "331604",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3316042011",
    "district_id": "331604",
    "label": "Sogo",
    "value": "Sogo"
  },
  {
    "id": "3316042012",
    "district_id": "331604",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "3316042013",
    "district_id": "331604",
    "label": "Nglandeyan",
    "value": "Nglandeyan"
  },
  {
    "id": "3316042014",
    "district_id": "331604",
    "label": "Kalen",
    "value": "Kalen"
  },
  {
    "id": "3316042015",
    "district_id": "331604",
    "label": "Ngraho",
    "value": "Ngraho"
  },
  {
    "id": "3316042016",
    "district_id": "331604",
    "label": "Kedungtuban",
    "value": "Kedungtuban"
  },
  {
    "id": "3316042017",
    "district_id": "331604",
    "label": "Galuk",
    "value": "Galuk"
  },
  {
    "id": "3316051011",
    "district_id": "331605",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "3316051013",
    "district_id": "331605",
    "label": "Balun",
    "value": "Balun"
  },
  {
    "id": "3316051014",
    "district_id": "331605",
    "label": "Cepu",
    "value": "Cepu"
  },
  {
    "id": "3316051015",
    "district_id": "331605",
    "label": "Ngelo",
    "value": "Ngelo"
  },
  {
    "id": "3316051016",
    "district_id": "331605",
    "label": "Karangboyo",
    "value": "Karangboyo"
  },
  {
    "id": "3316051017",
    "district_id": "331605",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3316052001",
    "district_id": "331605",
    "label": "Gadon",
    "value": "Gadon"
  },
  {
    "id": "3316052002",
    "district_id": "331605",
    "label": "Ngloram",
    "value": "Ngloram"
  },
  {
    "id": "3316052003",
    "district_id": "331605",
    "label": "Cabean",
    "value": "Cabean"
  },
  {
    "id": "3316052004",
    "district_id": "331605",
    "label": "Kapuan",
    "value": "Kapuan"
  },
  {
    "id": "3316052005",
    "district_id": "331605",
    "label": "Jipang",
    "value": "Jipang"
  },
  {
    "id": "3316052006",
    "district_id": "331605",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3316052007",
    "district_id": "331605",
    "label": "Sumberpitu",
    "value": "Sumberpitu"
  },
  {
    "id": "3316052008",
    "district_id": "331605",
    "label": "Kentong",
    "value": "Kentong"
  },
  {
    "id": "3316052009",
    "district_id": "331605",
    "label": "Mernung",
    "value": "Mernung"
  },
  {
    "id": "3316052010",
    "district_id": "331605",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3316052012",
    "district_id": "331605",
    "label": "Nglanjuk",
    "value": "Nglanjuk"
  },
  {
    "id": "3316062001",
    "district_id": "331606",
    "label": "Temengeng",
    "value": "Temengeng"
  },
  {
    "id": "3316062002",
    "district_id": "331606",
    "label": "Sambongrejo",
    "value": "Sambongrejo"
  },
  {
    "id": "3316062003",
    "district_id": "331606",
    "label": "Gadu",
    "value": "Gadu"
  },
  {
    "id": "3316062004",
    "district_id": "331606",
    "label": "Sambong",
    "value": "Sambong"
  },
  {
    "id": "3316062005",
    "district_id": "331606",
    "label": "Pojokwatu",
    "value": "Pojokwatu"
  },
  {
    "id": "3316062006",
    "district_id": "331606",
    "label": "Gagakan",
    "value": "Gagakan"
  },
  {
    "id": "3316062007",
    "district_id": "331606",
    "label": "Biting",
    "value": "Biting"
  },
  {
    "id": "3316062008",
    "district_id": "331606",
    "label": "Brabowan",
    "value": "Brabowan"
  },
  {
    "id": "3316062009",
    "district_id": "331606",
    "label": "Ledok",
    "value": "Ledok"
  },
  {
    "id": "3316062010",
    "district_id": "331606",
    "label": "Giyanti",
    "value": "Giyanti"
  },
  {
    "id": "3316072001",
    "district_id": "331607",
    "label": "Nglobo",
    "value": "Nglobo"
  },
  {
    "id": "3316072002",
    "district_id": "331607",
    "label": "Cabak",
    "value": "Cabak"
  },
  {
    "id": "3316072003",
    "district_id": "331607",
    "label": "Nglebur",
    "value": "Nglebur"
  },
  {
    "id": "3316072004",
    "district_id": "331607",
    "label": "Janjang",
    "value": "Janjang"
  },
  {
    "id": "3316072005",
    "district_id": "331607",
    "label": "Bleboh",
    "value": "Bleboh"
  },
  {
    "id": "3316072006",
    "district_id": "331607",
    "label": "Ketringan",
    "value": "Ketringan"
  },
  {
    "id": "3316072007",
    "district_id": "331607",
    "label": "Singonegoro",
    "value": "Singonegoro"
  },
  {
    "id": "3316072008",
    "district_id": "331607",
    "label": "Jiken",
    "value": "Jiken"
  },
  {
    "id": "3316072009",
    "district_id": "331607",
    "label": "Genjahan",
    "value": "Genjahan"
  },
  {
    "id": "3316072010",
    "district_id": "331607",
    "label": "Jiworejo",
    "value": "Jiworejo"
  },
  {
    "id": "3316072011",
    "district_id": "331607",
    "label": "Bangowan",
    "value": "Bangowan"
  },
  {
    "id": "3316081013",
    "district_id": "331608",
    "label": "Jepon",
    "value": "Jepon"
  },
  {
    "id": "3316082001",
    "district_id": "331608",
    "label": "Blungun",
    "value": "Blungun"
  },
  {
    "id": "3316082002",
    "district_id": "331608",
    "label": "Semanggi",
    "value": "Semanggi"
  },
  {
    "id": "3316082003",
    "district_id": "331608",
    "label": "Ngampon",
    "value": "Ngampon"
  },
  {
    "id": "3316082004",
    "district_id": "331608",
    "label": "Jomblang",
    "value": "Jomblang"
  },
  {
    "id": "3316082005",
    "district_id": "331608",
    "label": "Palon",
    "value": "Palon"
  },
  {
    "id": "3316082006",
    "district_id": "331608",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3316082007",
    "district_id": "331608",
    "label": "Sumurboto",
    "value": "Sumurboto"
  },
  {
    "id": "3316082008",
    "district_id": "331608",
    "label": "Brumbung",
    "value": "Brumbung"
  },
  {
    "id": "3316082009",
    "district_id": "331608",
    "label": "Turirejo",
    "value": "Turirejo"
  },
  {
    "id": "3316082010",
    "district_id": "331608",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3316082011",
    "district_id": "331608",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3316082012",
    "district_id": "331608",
    "label": "Tempellemahbang",
    "value": "Tempellemahbang"
  },
  {
    "id": "3316082014",
    "district_id": "331608",
    "label": "Seso",
    "value": "Seso"
  },
  {
    "id": "3316082015",
    "district_id": "331608",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3316082016",
    "district_id": "331608",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3316082017",
    "district_id": "331608",
    "label": "Nglarohgunung",
    "value": "Nglarohgunung"
  },
  {
    "id": "3316082018",
    "district_id": "331608",
    "label": "Kawengan",
    "value": "Kawengan"
  },
  {
    "id": "3316082019",
    "district_id": "331608",
    "label": "Gersi",
    "value": "Gersi"
  },
  {
    "id": "3316082020",
    "district_id": "331608",
    "label": "Gedangdowo",
    "value": "Gedangdowo"
  },
  {
    "id": "3316082021",
    "district_id": "331608",
    "label": "Puledagel",
    "value": "Puledagel"
  },
  {
    "id": "3316082022",
    "district_id": "331608",
    "label": "Bacem",
    "value": "Bacem"
  },
  {
    "id": "3316082023",
    "district_id": "331608",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3316082024",
    "district_id": "331608",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3316082025",
    "district_id": "331608",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3316091006",
    "district_id": "331609",
    "label": "Beran",
    "value": "Beran"
  },
  {
    "id": "3316091008",
    "district_id": "331609",
    "label": "Bangkle",
    "value": "Bangkle"
  },
  {
    "id": "3316091009",
    "district_id": "331609",
    "label": "Kedungjenar",
    "value": "Kedungjenar"
  },
  {
    "id": "3316091010",
    "district_id": "331609",
    "label": "Mlangsen",
    "value": "Mlangsen"
  },
  {
    "id": "3316091011",
    "district_id": "331609",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3316091012",
    "district_id": "331609",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3316091013",
    "district_id": "331609",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3316091014",
    "district_id": "331609",
    "label": "Sonorejo",
    "value": "Sonorejo"
  },
  {
    "id": "3316091015",
    "district_id": "331609",
    "label": "Kunden",
    "value": "Kunden"
  },
  {
    "id": "3316091016",
    "district_id": "331609",
    "label": "Tempelan",
    "value": "Tempelan"
  },
  {
    "id": "3316091017",
    "district_id": "331609",
    "label": "Tegalgunung",
    "value": "Tegalgunung"
  },
  {
    "id": "3316091018",
    "district_id": "331609",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3316092001",
    "district_id": "331609",
    "label": "Jepangrejo",
    "value": "Jepangrejo"
  },
  {
    "id": "3316092002",
    "district_id": "331609",
    "label": "Kamolan",
    "value": "Kamolan"
  },
  {
    "id": "3316092003",
    "district_id": "331609",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3316092004",
    "district_id": "331609",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3316092005",
    "district_id": "331609",
    "label": "Andongrejo",
    "value": "Andongrejo"
  },
  {
    "id": "3316092007",
    "district_id": "331609",
    "label": "Jejeruk",
    "value": "Jejeruk"
  },
  {
    "id": "3316092019",
    "district_id": "331609",
    "label": "Temurejo",
    "value": "Temurejo"
  },
  {
    "id": "3316092020",
    "district_id": "331609",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3316092021",
    "district_id": "331609",
    "label": "Patalan",
    "value": "Patalan"
  },
  {
    "id": "3316092022",
    "district_id": "331609",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3316092023",
    "district_id": "331609",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3316092024",
    "district_id": "331609",
    "label": "Ngadipurwo",
    "value": "Ngadipurwo"
  },
  {
    "id": "3316092025",
    "district_id": "331609",
    "label": "Sendangharjo",
    "value": "Sendangharjo"
  },
  {
    "id": "3316092026",
    "district_id": "331609",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3316092027",
    "district_id": "331609",
    "label": "Plantungan",
    "value": "Plantungan"
  },
  {
    "id": "3316092028",
    "district_id": "331609",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3316102001",
    "district_id": "331610",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3316102002",
    "district_id": "331610",
    "label": "Kedungringin",
    "value": "Kedungringin"
  },
  {
    "id": "3316102003",
    "district_id": "331610",
    "label": "Adirejo",
    "value": "Adirejo"
  },
  {
    "id": "3316102004",
    "district_id": "331610",
    "label": "Tamanrejo",
    "value": "Tamanrejo"
  },
  {
    "id": "3316102005",
    "district_id": "331610",
    "label": "Tutup",
    "value": "Tutup"
  },
  {
    "id": "3316102006",
    "district_id": "331610",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3316102007",
    "district_id": "331610",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3316102008",
    "district_id": "331610",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "3316102009",
    "district_id": "331610",
    "label": "Sambongrejo",
    "value": "Sambongrejo"
  },
  {
    "id": "3316102010",
    "district_id": "331610",
    "label": "Tunjungan",
    "value": "Tunjungan"
  },
  {
    "id": "3316102011",
    "district_id": "331610",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3316102012",
    "district_id": "331610",
    "label": "Gempolrejo",
    "value": "Gempolrejo"
  },
  {
    "id": "3316102013",
    "district_id": "331610",
    "label": "Nglangitan",
    "value": "Nglangitan"
  },
  {
    "id": "3316102014",
    "district_id": "331610",
    "label": "Keser",
    "value": "Keser"
  },
  {
    "id": "3316102015",
    "district_id": "331610",
    "label": "Sitirejo",
    "value": "Sitirejo"
  },
  {
    "id": "3316112001",
    "district_id": "331611",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3316112002",
    "district_id": "331611",
    "label": "Jatiklampok",
    "value": "Jatiklampok"
  },
  {
    "id": "3316112003",
    "district_id": "331611",
    "label": "Klopoduwur",
    "value": "Klopoduwur"
  },
  {
    "id": "3316112004",
    "district_id": "331611",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3316112005",
    "district_id": "331611",
    "label": "Balongsari",
    "value": "Balongsari"
  },
  {
    "id": "3316112006",
    "district_id": "331611",
    "label": "Bacem",
    "value": "Bacem"
  },
  {
    "id": "3316112007",
    "district_id": "331611",
    "label": "Wonosemi",
    "value": "Wonosemi"
  },
  {
    "id": "3316112008",
    "district_id": "331611",
    "label": "Sendanggayam",
    "value": "Sendanggayam"
  },
  {
    "id": "3316112009",
    "district_id": "331611",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3316112010",
    "district_id": "331611",
    "label": "Mojowetan",
    "value": "Mojowetan"
  },
  {
    "id": "3316112011",
    "district_id": "331611",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3316112012",
    "district_id": "331611",
    "label": "Gedongsari",
    "value": "Gedongsari"
  },
  {
    "id": "3316112013",
    "district_id": "331611",
    "label": "Sendangwungu",
    "value": "Sendangwungu"
  },
  {
    "id": "3316112014",
    "district_id": "331611",
    "label": "Balongrejo",
    "value": "Balongrejo"
  },
  {
    "id": "3316112015",
    "district_id": "331611",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3316112016",
    "district_id": "331611",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3316112017",
    "district_id": "331611",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3316112018",
    "district_id": "331611",
    "label": "Sembongin",
    "value": "Sembongin"
  },
  {
    "id": "3316112019",
    "district_id": "331611",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3316112020",
    "district_id": "331611",
    "label": "Buluroto",
    "value": "Buluroto"
  },
  {
    "id": "3316121015",
    "district_id": "331612",
    "label": "Punggursugih",
    "value": "Punggursugih"
  },
  {
    "id": "3316121018",
    "district_id": "331612",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3316122001",
    "district_id": "331612",
    "label": "Rowobungkul",
    "value": "Rowobungkul"
  },
  {
    "id": "3316122002",
    "district_id": "331612",
    "label": "Gedebeg",
    "value": "Gedebeg"
  },
  {
    "id": "3316122003",
    "district_id": "331612",
    "label": "Sambonganyar",
    "value": "Sambonganyar"
  },
  {
    "id": "3316122004",
    "district_id": "331612",
    "label": "Kendayaan",
    "value": "Kendayaan"
  },
  {
    "id": "3316122005",
    "district_id": "331612",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3316122006",
    "district_id": "331612",
    "label": "Bergolo",
    "value": "Bergolo"
  },
  {
    "id": "3316122007",
    "district_id": "331612",
    "label": "Bandungrojo",
    "value": "Bandungrojo"
  },
  {
    "id": "3316122008",
    "district_id": "331612",
    "label": "Kedungsatriyan",
    "value": "Kedungsatriyan"
  },
  {
    "id": "3316122009",
    "district_id": "331612",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3316122010",
    "district_id": "331612",
    "label": "Jetakwanger",
    "value": "Jetakwanger"
  },
  {
    "id": "3316122011",
    "district_id": "331612",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3316122012",
    "district_id": "331612",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3316122013",
    "district_id": "331612",
    "label": "Talokwohmojo",
    "value": "Talokwohmojo"
  },
  {
    "id": "3316122014",
    "district_id": "331612",
    "label": "Trembulrejo",
    "value": "Trembulrejo"
  },
  {
    "id": "3316122016",
    "district_id": "331612",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3316122017",
    "district_id": "331612",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3316122019",
    "district_id": "331612",
    "label": "Semawur",
    "value": "Semawur"
  },
  {
    "id": "3316122020",
    "district_id": "331612",
    "label": "Bradag",
    "value": "Bradag"
  },
  {
    "id": "3316122021",
    "district_id": "331612",
    "label": "Gotputuk",
    "value": "Gotputuk"
  },
  {
    "id": "3316122022",
    "district_id": "331612",
    "label": "Berbak",
    "value": "Berbak"
  },
  {
    "id": "3316122023",
    "district_id": "331612",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "3316122024",
    "district_id": "331612",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3316122025",
    "district_id": "331612",
    "label": "Wantilgung",
    "value": "Wantilgung"
  },
  {
    "id": "3316122026",
    "district_id": "331612",
    "label": "Bogowanti",
    "value": "Bogowanti"
  },
  {
    "id": "3316122027",
    "district_id": "331612",
    "label": "Sambongrejo",
    "value": "Sambongrejo"
  },
  {
    "id": "3316122028",
    "district_id": "331612",
    "label": "Srigading",
    "value": "Srigading"
  },
  {
    "id": "3316122029",
    "district_id": "331612",
    "label": "Karangjong",
    "value": "Karangjong"
  },
  {
    "id": "3316131017",
    "district_id": "331613",
    "label": "Kunduran",
    "value": "Kunduran"
  },
  {
    "id": "3316132001",
    "district_id": "331613",
    "label": "Botoreco",
    "value": "Botoreco"
  },
  {
    "id": "3316132002",
    "district_id": "331613",
    "label": "Buloh",
    "value": "Buloh"
  },
  {
    "id": "3316132003",
    "district_id": "331613",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3316132004",
    "district_id": "331613",
    "label": "Kodokan",
    "value": "Kodokan"
  },
  {
    "id": "3316132005",
    "district_id": "331613",
    "label": "Sonokidul",
    "value": "Sonokidul"
  },
  {
    "id": "3316132006",
    "district_id": "331613",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3316132007",
    "district_id": "331613",
    "label": "Cungkup",
    "value": "Cungkup"
  },
  {
    "id": "3316132008",
    "district_id": "331613",
    "label": "plosorejo",
    "value": "plosorejo"
  },
  {
    "id": "3316132009",
    "district_id": "331613",
    "label": "Ngilen",
    "value": "Ngilen"
  },
  {
    "id": "3316132010",
    "district_id": "331613",
    "label": "Bakah",
    "value": "Bakah"
  },
  {
    "id": "3316132011",
    "district_id": "331613",
    "label": "Kalangrejo",
    "value": "Kalangrejo"
  },
  {
    "id": "3316132012",
    "district_id": "331613",
    "label": "Blumbangrejo",
    "value": "Blumbangrejo"
  },
  {
    "id": "3316132013",
    "district_id": "331613",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3316132014",
    "district_id": "331613",
    "label": "Klokah",
    "value": "Klokah"
  },
  {
    "id": "3316132015",
    "district_id": "331613",
    "label": "Muraharjo",
    "value": "Muraharjo"
  },
  {
    "id": "3316132016",
    "district_id": "331613",
    "label": "Jagong",
    "value": "Jagong"
  },
  {
    "id": "3316132018",
    "district_id": "331613",
    "label": "Gagaan",
    "value": "Gagaan"
  },
  {
    "id": "3316132019",
    "district_id": "331613",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3316132020",
    "district_id": "331613",
    "label": "Bejirejo",
    "value": "Bejirejo"
  },
  {
    "id": "3316132021",
    "district_id": "331613",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3316132022",
    "district_id": "331613",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "3316132023",
    "district_id": "331613",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3316132024",
    "district_id": "331613",
    "label": "Ngawenombo",
    "value": "Ngawenombo"
  },
  {
    "id": "3316132025",
    "district_id": "331613",
    "label": "Sendangwates",
    "value": "Sendangwates"
  },
  {
    "id": "3316132026",
    "district_id": "331613",
    "label": "Kedungwaru",
    "value": "Kedungwaru"
  },
  {
    "id": "3316142001",
    "district_id": "331614",
    "label": "Tinapan",
    "value": "Tinapan"
  },
  {
    "id": "3316142002",
    "district_id": "331614",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3316142003",
    "district_id": "331614",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3316142004",
    "district_id": "331614",
    "label": "Pelemsengir",
    "value": "Pelemsengir"
  },
  {
    "id": "3316142005",
    "district_id": "331614",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3316142006",
    "district_id": "331614",
    "label": "Sonokulon",
    "value": "Sonokulon"
  },
  {
    "id": "3316142007",
    "district_id": "331614",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3316142008",
    "district_id": "331614",
    "label": "Gunungan",
    "value": "Gunungan"
  },
  {
    "id": "3316142009",
    "district_id": "331614",
    "label": "Kajengan",
    "value": "Kajengan"
  },
  {
    "id": "3316142010",
    "district_id": "331614",
    "label": "Dringo",
    "value": "Dringo"
  },
  {
    "id": "3316142011",
    "district_id": "331614",
    "label": "Cokrowati",
    "value": "Cokrowati"
  },
  {
    "id": "3316142012",
    "district_id": "331614",
    "label": "Ketileng",
    "value": "Ketileng"
  },
  {
    "id": "3316142013",
    "district_id": "331614",
    "label": "Todanan",
    "value": "Todanan"
  },
  {
    "id": "3316142014",
    "district_id": "331614",
    "label": "Ngumbul",
    "value": "Ngumbul"
  },
  {
    "id": "3316142015",
    "district_id": "331614",
    "label": "Wukirsari",
    "value": "Wukirsari"
  },
  {
    "id": "3316142016",
    "district_id": "331614",
    "label": "Bicak",
    "value": "Bicak"
  },
  {
    "id": "3316142017",
    "district_id": "331614",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3316142018",
    "district_id": "331614",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3316142019",
    "district_id": "331614",
    "label": "Dalangan",
    "value": "Dalangan"
  },
  {
    "id": "3316142020",
    "district_id": "331614",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3316142021",
    "district_id": "331614",
    "label": "Gondoriyo",
    "value": "Gondoriyo"
  },
  {
    "id": "3316142022",
    "district_id": "331614",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3316142023",
    "district_id": "331614",
    "label": "Bedingin",
    "value": "Bedingin"
  },
  {
    "id": "3316142024",
    "district_id": "331614",
    "label": "Ledok",
    "value": "Ledok"
  },
  {
    "id": "3316142025",
    "district_id": "331614",
    "label": "Kedungbacin",
    "value": "Kedungbacin"
  },
  {
    "id": "3316152001",
    "district_id": "331615",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3316152002",
    "district_id": "331615",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3316152003",
    "district_id": "331615",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3316152004",
    "district_id": "331615",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3316152005",
    "district_id": "331615",
    "label": "Prantaan",
    "value": "Prantaan"
  },
  {
    "id": "3316152006",
    "district_id": "331615",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3316152007",
    "district_id": "331615",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3316152008",
    "district_id": "331615",
    "label": "Gembol",
    "value": "Gembol"
  },
  {
    "id": "3316152009",
    "district_id": "331615",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3316152010",
    "district_id": "331615",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3316152011",
    "district_id": "331615",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3316152012",
    "district_id": "331615",
    "label": "Gandu",
    "value": "Gandu"
  },
  {
    "id": "3316152013",
    "district_id": "331615",
    "label": "Nglengkir",
    "value": "Nglengkir"
  },
  {
    "id": "3316152014",
    "district_id": "331615",
    "label": "Jurangjero",
    "value": "Jurangjero"
  },
  {
    "id": "3316162001",
    "district_id": "331616",
    "label": "Harjowinangun",
    "value": "Harjowinangun"
  },
  {
    "id": "3316162002",
    "district_id": "331616",
    "label": "Tengger",
    "value": "Tengger"
  },
  {
    "id": "3316162003",
    "district_id": "331616",
    "label": "Krocok",
    "value": "Krocok"
  },
  {
    "id": "3316162004",
    "district_id": "331616",
    "label": "Ngapus",
    "value": "Ngapus"
  },
  {
    "id": "3316162005",
    "district_id": "331616",
    "label": "Dologan",
    "value": "Dologan"
  },
  {
    "id": "3316162006",
    "district_id": "331616",
    "label": "Tlogowungu",
    "value": "Tlogowungu"
  },
  {
    "id": "3316162007",
    "district_id": "331616",
    "label": "Japah",
    "value": "Japah"
  },
  {
    "id": "3316162008",
    "district_id": "331616",
    "label": "Beganjing",
    "value": "Beganjing"
  },
  {
    "id": "3316162009",
    "district_id": "331616",
    "label": "Ngrambitan",
    "value": "Ngrambitan"
  },
  {
    "id": "3316162010",
    "district_id": "331616",
    "label": "Pengkolrejo",
    "value": "Pengkolrejo"
  },
  {
    "id": "3316162011",
    "district_id": "331616",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3316162012",
    "district_id": "331616",
    "label": "Wotbakah",
    "value": "Wotbakah"
  },
  {
    "id": "3316162013",
    "district_id": "331616",
    "label": "Padaan",
    "value": "Padaan"
  },
  {
    "id": "3316162014",
    "district_id": "331616",
    "label": "Bogem",
    "value": "Bogem"
  },
  {
    "id": "3316162015",
    "district_id": "331616",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3316162016",
    "district_id": "331616",
    "label": "Ngiyono",
    "value": "Ngiyono"
  },
  {
    "id": "3316162017",
    "district_id": "331616",
    "label": "Gaplokan",
    "value": "Gaplokan"
  },
  {
    "id": "3316162018",
    "district_id": "331616",
    "label": "Kalinanas",
    "value": "Kalinanas"
  },
  {
    "id": "3317012001",
    "district_id": "331701",
    "label": "Ronggo Mulyo",
    "value": "Ronggo Mulyo"
  },
  {
    "id": "3317012002",
    "district_id": "331701",
    "label": "Logede",
    "value": "Logede"
  },
  {
    "id": "3317012003",
    "district_id": "331701",
    "label": "Pelemsari",
    "value": "Pelemsari"
  },
  {
    "id": "3317012004",
    "district_id": "331701",
    "label": "Logung",
    "value": "Logung"
  },
  {
    "id": "3317012005",
    "district_id": "331701",
    "label": "Krikilan",
    "value": "Krikilan"
  },
  {
    "id": "3317012006",
    "district_id": "331701",
    "label": "Kedungtulub",
    "value": "Kedungtulub"
  },
  {
    "id": "3317012007",
    "district_id": "331701",
    "label": "Polbayem",
    "value": "Polbayem"
  },
  {
    "id": "3317012008",
    "district_id": "331701",
    "label": "Jatihadi",
    "value": "Jatihadi"
  },
  {
    "id": "3317012009",
    "district_id": "331701",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3317012010",
    "district_id": "331701",
    "label": "Jadi",
    "value": "Jadi"
  },
  {
    "id": "3317012011",
    "district_id": "331701",
    "label": "Grawan",
    "value": "Grawan"
  },
  {
    "id": "3317012012",
    "district_id": "331701",
    "label": "Randuagung",
    "value": "Randuagung"
  },
  {
    "id": "3317012013",
    "district_id": "331701",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3317012014",
    "district_id": "331701",
    "label": "Tlogotunggal",
    "value": "Tlogotunggal"
  },
  {
    "id": "3317012015",
    "district_id": "331701",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3317012016",
    "district_id": "331701",
    "label": "Megulung",
    "value": "Megulung"
  },
  {
    "id": "3317012017",
    "district_id": "331701",
    "label": "Kedungasem",
    "value": "Kedungasem"
  },
  {
    "id": "3317012018",
    "district_id": "331701",
    "label": "Sekarsari",
    "value": "Sekarsari"
  },
  {
    "id": "3317022001",
    "district_id": "331702",
    "label": "Mlatirejo",
    "value": "Mlatirejo"
  },
  {
    "id": "3317022002",
    "district_id": "331702",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3317022003",
    "district_id": "331702",
    "label": "Pondokrejo",
    "value": "Pondokrejo"
  },
  {
    "id": "3317022004",
    "district_id": "331702",
    "label": "Warugunung",
    "value": "Warugunung"
  },
  {
    "id": "3317022005",
    "district_id": "331702",
    "label": "Pinggan",
    "value": "Pinggan"
  },
  {
    "id": "3317022006",
    "district_id": "331702",
    "label": "Cabean Kidul",
    "value": "Cabean Kidul"
  },
  {
    "id": "3317022007",
    "district_id": "331702",
    "label": "Lambangan Kulon",
    "value": "Lambangan Kulon"
  },
  {
    "id": "3317022008",
    "district_id": "331702",
    "label": "Lambangan Wetan",
    "value": "Lambangan Wetan"
  },
  {
    "id": "3317022009",
    "district_id": "331702",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3317022010",
    "district_id": "331702",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3317022011",
    "district_id": "331702",
    "label": "Pasedan",
    "value": "Pasedan"
  },
  {
    "id": "3317022012",
    "district_id": "331702",
    "label": "Ngulaan",
    "value": "Ngulaan"
  },
  {
    "id": "3317022013",
    "district_id": "331702",
    "label": "Jukung",
    "value": "Jukung"
  },
  {
    "id": "3317022014",
    "district_id": "331702",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3317022015",
    "district_id": "331702",
    "label": "Mantingan",
    "value": "Mantingan"
  },
  {
    "id": "3317022016",
    "district_id": "331702",
    "label": "Kadiwono",
    "value": "Kadiwono"
  },
  {
    "id": "3317032001",
    "district_id": "331703",
    "label": "Kajar",
    "value": "Kajar"
  },
  {
    "id": "3317032002",
    "district_id": "331703",
    "label": "Timbrangan",
    "value": "Timbrangan"
  },
  {
    "id": "3317032003",
    "district_id": "331703",
    "label": "Tegaldowo",
    "value": "Tegaldowo"
  },
  {
    "id": "3317032004",
    "district_id": "331703",
    "label": "Pasucen",
    "value": "Pasucen"
  },
  {
    "id": "3317032005",
    "district_id": "331703",
    "label": "Suntri",
    "value": "Suntri"
  },
  {
    "id": "3317032006",
    "district_id": "331703",
    "label": "Dowan",
    "value": "Dowan"
  },
  {
    "id": "3317032007",
    "district_id": "331703",
    "label": "Trembes",
    "value": "Trembes"
  },
  {
    "id": "3317032008",
    "district_id": "331703",
    "label": "Gunem",
    "value": "Gunem"
  },
  {
    "id": "3317032009",
    "district_id": "331703",
    "label": "Kulutan",
    "value": "Kulutan"
  },
  {
    "id": "3317032010",
    "district_id": "331703",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3317032011",
    "district_id": "331703",
    "label": "Telgawah",
    "value": "Telgawah"
  },
  {
    "id": "3317032012",
    "district_id": "331703",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3317032013",
    "district_id": "331703",
    "label": "Panohan",
    "value": "Panohan"
  },
  {
    "id": "3317032014",
    "district_id": "331703",
    "label": "Demaan",
    "value": "Demaan"
  },
  {
    "id": "3317032015",
    "district_id": "331703",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3317032016",
    "district_id": "331703",
    "label": "Sambungpayak",
    "value": "Sambungpayak"
  },
  {
    "id": "3317042001",
    "district_id": "331704",
    "label": "Bancang",
    "value": "Bancang"
  },
  {
    "id": "3317042002",
    "district_id": "331704",
    "label": "Mrayun",
    "value": "Mrayun"
  },
  {
    "id": "3317042003",
    "district_id": "331704",
    "label": "Ngajaran",
    "value": "Ngajaran"
  },
  {
    "id": "3317042004",
    "district_id": "331704",
    "label": "Tahunan",
    "value": "Tahunan"
  },
  {
    "id": "3317042005",
    "district_id": "331704",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3317042006",
    "district_id": "331704",
    "label": "Jinanten",
    "value": "Jinanten"
  },
  {
    "id": "3317042007",
    "district_id": "331704",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3317042008",
    "district_id": "331704",
    "label": "Sale",
    "value": "Sale"
  },
  {
    "id": "3317042009",
    "district_id": "331704",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3317042010",
    "district_id": "331704",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3317042011",
    "district_id": "331704",
    "label": "Tengger",
    "value": "Tengger"
  },
  {
    "id": "3317042012",
    "district_id": "331704",
    "label": "Bitingan",
    "value": "Bitingan"
  },
  {
    "id": "3317042013",
    "district_id": "331704",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3317042014",
    "district_id": "331704",
    "label": "Rendeng",
    "value": "Rendeng"
  },
  {
    "id": "3317042015",
    "district_id": "331704",
    "label": "Ukir",
    "value": "Ukir"
  },
  {
    "id": "3317052001",
    "district_id": "331705",
    "label": "Lodan Kulon",
    "value": "Lodan Kulon"
  },
  {
    "id": "3317052002",
    "district_id": "331705",
    "label": "Lodan Wetan",
    "value": "Lodan Wetan"
  },
  {
    "id": "3317052003",
    "district_id": "331705",
    "label": "Bonjor",
    "value": "Bonjor"
  },
  {
    "id": "3317052004",
    "district_id": "331705",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3317052005",
    "district_id": "331705",
    "label": "Sampung",
    "value": "Sampung"
  },
  {
    "id": "3317052006",
    "district_id": "331705",
    "label": "Baturno",
    "value": "Baturno"
  },
  {
    "id": "3317052007",
    "district_id": "331705",
    "label": "Babaktulung",
    "value": "Babaktulung"
  },
  {
    "id": "3317052008",
    "district_id": "331705",
    "label": "Nglojo",
    "value": "Nglojo"
  },
  {
    "id": "3317052009",
    "district_id": "331705",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3317052010",
    "district_id": "331705",
    "label": "Pelang",
    "value": "Pelang"
  },
  {
    "id": "3317052011",
    "district_id": "331705",
    "label": "Gilis",
    "value": "Gilis"
  },
  {
    "id": "3317052012",
    "district_id": "331705",
    "label": "Gunungmulyo",
    "value": "Gunungmulyo"
  },
  {
    "id": "3317052013",
    "district_id": "331705",
    "label": "Gonggang",
    "value": "Gonggang"
  },
  {
    "id": "3317052014",
    "district_id": "331705",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3317052015",
    "district_id": "331705",
    "label": "Kalipang",
    "value": "Kalipang"
  },
  {
    "id": "3317052016",
    "district_id": "331705",
    "label": "Dadapmulyo",
    "value": "Dadapmulyo"
  },
  {
    "id": "3317052017",
    "district_id": "331705",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3317052018",
    "district_id": "331705",
    "label": "Banowan",
    "value": "Banowan"
  },
  {
    "id": "3317052019",
    "district_id": "331705",
    "label": "Temperak",
    "value": "Temperak"
  },
  {
    "id": "3317052020",
    "district_id": "331705",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3317052021",
    "district_id": "331705",
    "label": "Bajingjowo",
    "value": "Bajingjowo"
  },
  {
    "id": "3317052022",
    "district_id": "331705",
    "label": "Bajingmeduro",
    "value": "Bajingmeduro"
  },
  {
    "id": "3317052023",
    "district_id": "331705",
    "label": "Sarangmeduro",
    "value": "Sarangmeduro"
  },
  {
    "id": "3317062001",
    "district_id": "331706",
    "label": "Ngulahan",
    "value": "Ngulahan"
  },
  {
    "id": "3317062002",
    "district_id": "331706",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3317062003",
    "district_id": "331706",
    "label": "Karas",
    "value": "Karas"
  },
  {
    "id": "3317062004",
    "district_id": "331706",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3317062005",
    "district_id": "331706",
    "label": "Gesikan",
    "value": "Gesikan"
  },
  {
    "id": "3317062006",
    "district_id": "331706",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3317062007",
    "district_id": "331706",
    "label": "Sedan",
    "value": "Sedan"
  },
  {
    "id": "3317062008",
    "district_id": "331706",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3317062009",
    "district_id": "331706",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3317062010",
    "district_id": "331706",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3317062011",
    "district_id": "331706",
    "label": "Kedungringin",
    "value": "Kedungringin"
  },
  {
    "id": "3317062012",
    "district_id": "331706",
    "label": "Gandirojo",
    "value": "Gandirojo"
  },
  {
    "id": "3317062013",
    "district_id": "331706",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3317062014",
    "district_id": "331706",
    "label": "Lemahputih",
    "value": "Lemahputih"
  },
  {
    "id": "3317062015",
    "district_id": "331706",
    "label": "Kumbo",
    "value": "Kumbo"
  },
  {
    "id": "3317062016",
    "district_id": "331706",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3317062017",
    "district_id": "331706",
    "label": "Sambong",
    "value": "Sambong"
  },
  {
    "id": "3317062018",
    "district_id": "331706",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3317062019",
    "district_id": "331706",
    "label": "Kenongo",
    "value": "Kenongo"
  },
  {
    "id": "3317062020",
    "district_id": "331706",
    "label": "Jambeyan",
    "value": "Jambeyan"
  },
  {
    "id": "3317062021",
    "district_id": "331706",
    "label": "Menoro",
    "value": "Menoro"
  },
  {
    "id": "3317072001",
    "district_id": "331707",
    "label": "Megal",
    "value": "Megal"
  },
  {
    "id": "3317072002",
    "district_id": "331707",
    "label": "Ngemplakrejo",
    "value": "Ngemplakrejo"
  },
  {
    "id": "3317072003",
    "district_id": "331707",
    "label": "Pragen",
    "value": "Pragen"
  },
  {
    "id": "3317072004",
    "district_id": "331707",
    "label": "Samaran",
    "value": "Samaran"
  },
  {
    "id": "3317072005",
    "district_id": "331707",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  {
    "id": "3317072006",
    "district_id": "331707",
    "label": "Bamban",
    "value": "Bamban"
  },
  {
    "id": "3317072007",
    "district_id": "331707",
    "label": "Bangunrejo",
    "value": "Bangunrejo"
  },
  {
    "id": "3317072008",
    "district_id": "331707",
    "label": "Pamotan",
    "value": "Pamotan"
  },
  {
    "id": "3317072009",
    "district_id": "331707",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3317072010",
    "district_id": "331707",
    "label": "Tempaling",
    "value": "Tempaling"
  },
  {
    "id": "3317072011",
    "district_id": "331707",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3317072012",
    "district_id": "331707",
    "label": "Mlagen",
    "value": "Mlagen"
  },
  {
    "id": "3317072013",
    "district_id": "331707",
    "label": "Kepohagung",
    "value": "Kepohagung"
  },
  {
    "id": "3317072014",
    "district_id": "331707",
    "label": "Mlawat",
    "value": "Mlawat"
  },
  {
    "id": "3317072015",
    "district_id": "331707",
    "label": "Segoromulyo",
    "value": "Segoromulyo"
  },
  {
    "id": "3317072016",
    "district_id": "331707",
    "label": "Ketangi",
    "value": "Ketangi"
  },
  {
    "id": "3317072017",
    "district_id": "331707",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3317072018",
    "district_id": "331707",
    "label": "Gegersimo",
    "value": "Gegersimo"
  },
  {
    "id": "3317072019",
    "district_id": "331707",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3317072020",
    "district_id": "331707",
    "label": "Japerejo",
    "value": "Japerejo"
  },
  {
    "id": "3317072021",
    "district_id": "331707",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "3317072022",
    "district_id": "331707",
    "label": "Ringin",
    "value": "Ringin"
  },
  {
    "id": "3317072023",
    "district_id": "331707",
    "label": "Sumbangrejo",
    "value": "Sumbangrejo"
  },
  {
    "id": "3317082001",
    "district_id": "331708",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3317082002",
    "district_id": "331708",
    "label": "Kemadu",
    "value": "Kemadu"
  },
  {
    "id": "3317082003",
    "district_id": "331708",
    "label": "Sulang",
    "value": "Sulang"
  },
  {
    "id": "3317082004",
    "district_id": "331708",
    "label": "Pomahan",
    "value": "Pomahan"
  },
  {
    "id": "3317082005",
    "district_id": "331708",
    "label": "Rukem",
    "value": "Rukem"
  },
  {
    "id": "3317082006",
    "district_id": "331708",
    "label": "Korowelang",
    "value": "Korowelang"
  },
  {
    "id": "3317082007",
    "district_id": "331708",
    "label": "Karangharjo",
    "value": "Karangharjo"
  },
  {
    "id": "3317082008",
    "district_id": "331708",
    "label": "Jatimudo",
    "value": "Jatimudo"
  },
  {
    "id": "3317082009",
    "district_id": "331708",
    "label": "Kunir",
    "value": "Kunir"
  },
  {
    "id": "3317082010",
    "district_id": "331708",
    "label": "Glebeg",
    "value": "Glebeg"
  },
  {
    "id": "3317082011",
    "district_id": "331708",
    "label": "Bogorame",
    "value": "Bogorame"
  },
  {
    "id": "3317082012",
    "district_id": "331708",
    "label": "Kaliombo",
    "value": "Kaliombo"
  },
  {
    "id": "3317082013",
    "district_id": "331708",
    "label": "Sudo",
    "value": "Sudo"
  },
  {
    "id": "3317082014",
    "district_id": "331708",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3317082015",
    "district_id": "331708",
    "label": "Pragu",
    "value": "Pragu"
  },
  {
    "id": "3317082016",
    "district_id": "331708",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3317082017",
    "district_id": "331708",
    "label": "Seren",
    "value": "Seren"
  },
  {
    "id": "3317082018",
    "district_id": "331708",
    "label": "Pranti",
    "value": "Pranti"
  },
  {
    "id": "3317082019",
    "district_id": "331708",
    "label": "Pedak",
    "value": "Pedak"
  },
  {
    "id": "3317082020",
    "district_id": "331708",
    "label": "Landoh",
    "value": "Landoh"
  },
  {
    "id": "3317082021",
    "district_id": "331708",
    "label": "Kerep",
    "value": "Kerep"
  },
  {
    "id": "3317092001",
    "district_id": "331709",
    "label": "Meteseh",
    "value": "Meteseh"
  },
  {
    "id": "3317092002",
    "district_id": "331709",
    "label": "Maguan",
    "value": "Maguan"
  },
  {
    "id": "3317092003",
    "district_id": "331709",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3317092004",
    "district_id": "331709",
    "label": "Wiroto",
    "value": "Wiroto"
  },
  {
    "id": "3317092005",
    "district_id": "331709",
    "label": "Banggi",
    "value": "Banggi"
  },
  {
    "id": "3317092006",
    "district_id": "331709",
    "label": "Kuangsan",
    "value": "Kuangsan"
  },
  {
    "id": "3317092007",
    "district_id": "331709",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3317092008",
    "district_id": "331709",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3317092009",
    "district_id": "331709",
    "label": "Karangsekar",
    "value": "Karangsekar"
  },
  {
    "id": "3317092010",
    "district_id": "331709",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3317092011",
    "district_id": "331709",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3317092012",
    "district_id": "331709",
    "label": "Sambiyan",
    "value": "Sambiyan"
  },
  {
    "id": "3317092013",
    "district_id": "331709",
    "label": "Mojorembun",
    "value": "Mojorembun"
  },
  {
    "id": "3317092014",
    "district_id": "331709",
    "label": "Tunggulsari",
    "value": "Tunggulsari"
  },
  {
    "id": "3317092015",
    "district_id": "331709",
    "label": "Tambakagung",
    "value": "Tambakagung"
  },
  {
    "id": "3317092016",
    "district_id": "331709",
    "label": "Mojowarno",
    "value": "Mojowarno"
  },
  {
    "id": "3317092017",
    "district_id": "331709",
    "label": "Dresikulon",
    "value": "Dresikulon"
  },
  {
    "id": "3317092018",
    "district_id": "331709",
    "label": "Dresiwetan",
    "value": "Dresiwetan"
  },
  {
    "id": "3317092019",
    "district_id": "331709",
    "label": "Tasikharjo",
    "value": "Tasikharjo"
  },
  {
    "id": "3317092020",
    "district_id": "331709",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3317092021",
    "district_id": "331709",
    "label": "Bogoharjo",
    "value": "Bogoharjo"
  },
  {
    "id": "3317092022",
    "district_id": "331709",
    "label": "Banyudono",
    "value": "Banyudono"
  },
  {
    "id": "3317092023",
    "district_id": "331709",
    "label": "Pantiharjo",
    "value": "Pantiharjo"
  },
  {
    "id": "3317101019",
    "district_id": "331710",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3317101020",
    "district_id": "331710",
    "label": "Gegunung Kulon",
    "value": "Gegunung Kulon"
  },
  {
    "id": "3317101022",
    "district_id": "331710",
    "label": "Pacar",
    "value": "Pacar"
  },
  {
    "id": "3317101023",
    "district_id": "331710",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3317101027",
    "district_id": "331710",
    "label": "Leteh",
    "value": "Leteh"
  },
  {
    "id": "3317101028",
    "district_id": "331710",
    "label": "Sidowayah",
    "value": "Sidowayah"
  },
  {
    "id": "3317101029",
    "district_id": "331710",
    "label": "Kutoharjo",
    "value": "Kutoharjo"
  },
  {
    "id": "3317102001",
    "district_id": "331710",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3317102002",
    "district_id": "331710",
    "label": "Turusgede",
    "value": "Turusgede"
  },
  {
    "id": "3317102003",
    "district_id": "331710",
    "label": "Kumendung",
    "value": "Kumendung"
  },
  {
    "id": "3317102004",
    "district_id": "331710",
    "label": "Sridadi",
    "value": "Sridadi"
  },
  {
    "id": "3317102005",
    "district_id": "331710",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3317102006",
    "district_id": "331710",
    "label": "Tlogomojo",
    "value": "Tlogomojo"
  },
  {
    "id": "3317102007",
    "district_id": "331710",
    "label": "Kasreman",
    "value": "Kasreman"
  },
  {
    "id": "3317102008",
    "district_id": "331710",
    "label": "Punjulharjo",
    "value": "Punjulharjo"
  },
  {
    "id": "3317102009",
    "district_id": "331710",
    "label": "Tritunggal",
    "value": "Tritunggal"
  },
  {
    "id": "3317102010",
    "district_id": "331710",
    "label": "Pasar Banggi",
    "value": "Pasar Banggi"
  },
  {
    "id": "3317102011",
    "district_id": "331710",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3317102012",
    "district_id": "331710",
    "label": "Weton",
    "value": "Weton"
  },
  {
    "id": "3317102013",
    "district_id": "331710",
    "label": "Ngotet",
    "value": "Ngotet"
  },
  {
    "id": "3317102014",
    "district_id": "331710",
    "label": "Mondoteko",
    "value": "Mondoteko"
  },
  {
    "id": "3317102015",
    "district_id": "331710",
    "label": "Ngadem",
    "value": "Ngadem"
  },
  {
    "id": "3317102016",
    "district_id": "331710",
    "label": "Ketanggi",
    "value": "Ketanggi"
  },
  {
    "id": "3317102017",
    "district_id": "331710",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "3317102018",
    "district_id": "331710",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3317102021",
    "district_id": "331710",
    "label": "Gegunung Wetan",
    "value": "Gegunung Wetan"
  },
  {
    "id": "3317102024",
    "district_id": "331710",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3317102025",
    "district_id": "331710",
    "label": "Tasikagung",
    "value": "Tasikagung"
  },
  {
    "id": "3317102026",
    "district_id": "331710",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3317102030",
    "district_id": "331710",
    "label": "Padaran",
    "value": "Padaran"
  },
  {
    "id": "3317102031",
    "district_id": "331710",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3317102032",
    "district_id": "331710",
    "label": "Kabonganlor",
    "value": "Kabonganlor"
  },
  {
    "id": "3317102033",
    "district_id": "331710",
    "label": "Kabongankidul",
    "value": "Kabongankidul"
  },
  {
    "id": "3317102034",
    "district_id": "331710",
    "label": "Tireman",
    "value": "Tireman"
  },
  {
    "id": "3317112001",
    "district_id": "331711",
    "label": "Jepeledok",
    "value": "Jepeledok"
  },
  {
    "id": "3317112002",
    "district_id": "331711",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3317112003",
    "district_id": "331711",
    "label": "Doropayung",
    "value": "Doropayung"
  },
  {
    "id": "3317112004",
    "district_id": "331711",
    "label": "Keraskepoh",
    "value": "Keraskepoh"
  },
  {
    "id": "3317112005",
    "district_id": "331711",
    "label": "Tuyuhan",
    "value": "Tuyuhan"
  },
  {
    "id": "3317112006",
    "district_id": "331711",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "3317112007",
    "district_id": "331711",
    "label": "Gemblengmulyo",
    "value": "Gemblengmulyo"
  },
  {
    "id": "3317112008",
    "district_id": "331711",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3317112009",
    "district_id": "331711",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3317112010",
    "district_id": "331711",
    "label": "Sidowayah",
    "value": "Sidowayah"
  },
  {
    "id": "3317112011",
    "district_id": "331711",
    "label": "Kedung",
    "value": "Kedung"
  },
  {
    "id": "3317112012",
    "district_id": "331711",
    "label": "Punggurharjo",
    "value": "Punggurharjo"
  },
  {
    "id": "3317112013",
    "district_id": "331711",
    "label": "Langkir",
    "value": "Langkir"
  },
  {
    "id": "3317112014",
    "district_id": "331711",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "3317112015",
    "district_id": "331711",
    "label": "Pohlandak",
    "value": "Pohlandak"
  },
  {
    "id": "3317112016",
    "district_id": "331711",
    "label": "Warugunung",
    "value": "Warugunung"
  },
  {
    "id": "3317112017",
    "district_id": "331711",
    "label": "Criwik",
    "value": "Criwik"
  },
  {
    "id": "3317112018",
    "district_id": "331711",
    "label": "Wuwur",
    "value": "Wuwur"
  },
  {
    "id": "3317112019",
    "district_id": "331711",
    "label": "Ngulangan",
    "value": "Ngulangan"
  },
  {
    "id": "3317112020",
    "district_id": "331711",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3317112021",
    "district_id": "331711",
    "label": "Johogunung",
    "value": "Johogunung"
  },
  {
    "id": "3317112022",
    "district_id": "331711",
    "label": "Trenggulunan",
    "value": "Trenggulunan"
  },
  {
    "id": "3317112023",
    "district_id": "331711",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3317122001",
    "district_id": "331712",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3317122002",
    "district_id": "331712",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3317122003",
    "district_id": "331712",
    "label": "Sendangwaru",
    "value": "Sendangwaru"
  },
  {
    "id": "3317122004",
    "district_id": "331712",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3317122005",
    "district_id": "331712",
    "label": "Kendalagung",
    "value": "Kendalagung"
  },
  {
    "id": "3317122006",
    "district_id": "331712",
    "label": "Mojokerto",
    "value": "Mojokerto"
  },
  {
    "id": "3317122007",
    "district_id": "331712",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "3317122008",
    "district_id": "331712",
    "label": "Kebloran",
    "value": "Kebloran"
  },
  {
    "id": "3317122009",
    "district_id": "331712",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3317122010",
    "district_id": "331712",
    "label": "Karanglincak",
    "value": "Karanglincak"
  },
  {
    "id": "3317122011",
    "district_id": "331712",
    "label": "Karangharjo",
    "value": "Karangharjo"
  },
  {
    "id": "3317122012",
    "district_id": "331712",
    "label": "Kragan",
    "value": "Kragan"
  },
  {
    "id": "3317122013",
    "district_id": "331712",
    "label": "Tegalmulyo",
    "value": "Tegalmulyo"
  },
  {
    "id": "3317122014",
    "district_id": "331712",
    "label": "Balongmulyo",
    "value": "Balongmulyo"
  },
  {
    "id": "3317122015",
    "district_id": "331712",
    "label": "Narukan",
    "value": "Narukan"
  },
  {
    "id": "3317122016",
    "district_id": "331712",
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "id": "3317122017",
    "district_id": "331712",
    "label": "Terjan",
    "value": "Terjan"
  },
  {
    "id": "3317122018",
    "district_id": "331712",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3317122019",
    "district_id": "331712",
    "label": "Watupecah",
    "value": "Watupecah"
  },
  {
    "id": "3317122020",
    "district_id": "331712",
    "label": "Woro",
    "value": "Woro"
  },
  {
    "id": "3317122021",
    "district_id": "331712",
    "label": "Sumurpule",
    "value": "Sumurpule"
  },
  {
    "id": "3317122022",
    "district_id": "331712",
    "label": "Plawangan",
    "value": "Plawangan"
  },
  {
    "id": "3317122023",
    "district_id": "331712",
    "label": "Sumbergayam",
    "value": "Sumbergayam"
  },
  {
    "id": "3317122024",
    "district_id": "331712",
    "label": "Pandangan Wetan",
    "value": "Pandangan Wetan"
  },
  {
    "id": "3317122025",
    "district_id": "331712",
    "label": "Pandangan Kulon",
    "value": "Pandangan Kulon"
  },
  {
    "id": "3317122026",
    "district_id": "331712",
    "label": "Sumurtawang",
    "value": "Sumurtawang"
  },
  {
    "id": "3317122027",
    "district_id": "331712",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3317132001",
    "district_id": "331713",
    "label": "Sanetan",
    "value": "Sanetan"
  },
  {
    "id": "3317132002",
    "district_id": "331713",
    "label": "Rakitan",
    "value": "Rakitan"
  },
  {
    "id": "3317132003",
    "district_id": "331713",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3317132004",
    "district_id": "331713",
    "label": "Labuhan Kidul",
    "value": "Labuhan Kidul"
  },
  {
    "id": "3317132005",
    "district_id": "331713",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3317132006",
    "district_id": "331713",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3317132007",
    "district_id": "331713",
    "label": "Manggar",
    "value": "Manggar"
  },
  {
    "id": "3317132008",
    "district_id": "331713",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3317132009",
    "district_id": "331713",
    "label": "Langgar",
    "value": "Langgar"
  },
  {
    "id": "3317132010",
    "district_id": "331713",
    "label": "Sluke",
    "value": "Sluke"
  },
  {
    "id": "3317132011",
    "district_id": "331713",
    "label": "Jurangjero",
    "value": "Jurangjero"
  },
  {
    "id": "3317132012",
    "district_id": "331713",
    "label": "Leran",
    "value": "Leran"
  },
  {
    "id": "3317132013",
    "district_id": "331713",
    "label": "Trahan",
    "value": "Trahan"
  },
  {
    "id": "3317132014",
    "district_id": "331713",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3317142001",
    "district_id": "331714",
    "label": "Karasgede",
    "value": "Karasgede"
  },
  {
    "id": "3317142002",
    "district_id": "331714",
    "label": "Jolotundo",
    "value": "Jolotundo"
  },
  {
    "id": "3317142003",
    "district_id": "331714",
    "label": "Sumbergirang",
    "value": "Sumbergirang"
  },
  {
    "id": "3317142004",
    "district_id": "331714",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3317142005",
    "district_id": "331714",
    "label": "Babagan",
    "value": "Babagan"
  },
  {
    "id": "3317142006",
    "district_id": "331714",
    "label": "Dorokandang",
    "value": "Dorokandang"
  },
  {
    "id": "3317142007",
    "district_id": "331714",
    "label": "Gedongmulyo",
    "value": "Gedongmulyo"
  },
  {
    "id": "3317142008",
    "district_id": "331714",
    "label": "Dasun",
    "value": "Dasun"
  },
  {
    "id": "3317142009",
    "district_id": "331714",
    "label": "Soditan",
    "value": "Soditan"
  },
  {
    "id": "3317142010",
    "district_id": "331714",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3317142011",
    "district_id": "331714",
    "label": "Selopuro",
    "value": "Selopuro"
  },
  {
    "id": "3317142012",
    "district_id": "331714",
    "label": "Sendangcoyo",
    "value": "Sendangcoyo"
  },
  {
    "id": "3317142013",
    "district_id": "331714",
    "label": "Ngargomulyo",
    "value": "Ngargomulyo"
  },
  {
    "id": "3317142014",
    "district_id": "331714",
    "label": "Kajar",
    "value": "Kajar"
  },
  {
    "id": "3317142015",
    "district_id": "331714",
    "label": "Gowak",
    "value": "Gowak"
  },
  {
    "id": "3317142016",
    "district_id": "331714",
    "label": "Sendangasri",
    "value": "Sendangasri"
  },
  {
    "id": "3317142017",
    "district_id": "331714",
    "label": "Tasiksono",
    "value": "Tasiksono"
  },
  {
    "id": "3317142018",
    "district_id": "331714",
    "label": "Sriombo",
    "value": "Sriombo"
  },
  {
    "id": "3317142019",
    "district_id": "331714",
    "label": "Bonang",
    "value": "Bonang"
  },
  {
    "id": "3317142020",
    "district_id": "331714",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3318012001",
    "district_id": "331801",
    "label": "Prawoto",
    "value": "Prawoto"
  },
  {
    "id": "3318012002",
    "district_id": "331801",
    "label": "Pakem",
    "value": "Pakem"
  },
  {
    "id": "3318012003",
    "district_id": "331801",
    "label": "Wegil",
    "value": "Wegil"
  },
  {
    "id": "3318012004",
    "district_id": "331801",
    "label": "Kuwawur",
    "value": "Kuwawur"
  },
  {
    "id": "3318012005",
    "district_id": "331801",
    "label": "Baleadi",
    "value": "Baleadi"
  },
  {
    "id": "3318012006",
    "district_id": "331801",
    "label": "Wotan",
    "value": "Wotan"
  },
  {
    "id": "3318012007",
    "district_id": "331801",
    "label": "Kedungwinong",
    "value": "Kedungwinong"
  },
  {
    "id": "3318012008",
    "district_id": "331801",
    "label": "Porangparing",
    "value": "Porangparing"
  },
  {
    "id": "3318012009",
    "district_id": "331801",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3318012010",
    "district_id": "331801",
    "label": "Baturejo",
    "value": "Baturejo"
  },
  {
    "id": "3318012011",
    "district_id": "331801",
    "label": "Gadudero",
    "value": "Gadudero"
  },
  {
    "id": "3318012012",
    "district_id": "331801",
    "label": "Sumbersoko",
    "value": "Sumbersoko"
  },
  {
    "id": "3318012013",
    "district_id": "331801",
    "label": "Tompegunung",
    "value": "Tompegunung"
  },
  {
    "id": "3318012014",
    "district_id": "331801",
    "label": "Kedumulyo",
    "value": "Kedumulyo"
  },
  {
    "id": "3318012015",
    "district_id": "331801",
    "label": "Kasiyan",
    "value": "Kasiyan"
  },
  {
    "id": "3318012016",
    "district_id": "331801",
    "label": "Cengkalsewu",
    "value": "Cengkalsewu"
  },
  {
    "id": "3318022001",
    "district_id": "331802",
    "label": "Jimbaran",
    "value": "Jimbaran"
  },
  {
    "id": "3318022002",
    "district_id": "331802",
    "label": "Durensawit",
    "value": "Durensawit"
  },
  {
    "id": "3318022003",
    "district_id": "331802",
    "label": "Slungkep",
    "value": "Slungkep"
  },
  {
    "id": "3318022004",
    "district_id": "331802",
    "label": "Beketel",
    "value": "Beketel"
  },
  {
    "id": "3318022005",
    "district_id": "331802",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3318022006",
    "district_id": "331802",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3318022007",
    "district_id": "331802",
    "label": "Brati",
    "value": "Brati"
  },
  {
    "id": "3318022008",
    "district_id": "331802",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "3318022009",
    "district_id": "331802",
    "label": "Kayen",
    "value": "Kayen"
  },
  {
    "id": "3318022010",
    "district_id": "331802",
    "label": "Rogomulyo",
    "value": "Rogomulyo"
  },
  {
    "id": "3318022011",
    "district_id": "331802",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "3318022012",
    "district_id": "331802",
    "label": "Pasuruhan",
    "value": "Pasuruhan"
  },
  {
    "id": "3318022013",
    "district_id": "331802",
    "label": "Pesagi",
    "value": "Pesagi"
  },
  {
    "id": "3318022014",
    "district_id": "331802",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3318022015",
    "district_id": "331802",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3318022016",
    "district_id": "331802",
    "label": "Boloagung",
    "value": "Boloagung"
  },
  {
    "id": "3318022017",
    "district_id": "331802",
    "label": "Sundoluhur",
    "value": "Sundoluhur"
  },
  {
    "id": "3318032001",
    "district_id": "331803",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3318032002",
    "district_id": "331803",
    "label": "Maitan",
    "value": "Maitan"
  },
  {
    "id": "3318032003",
    "district_id": "331803",
    "label": "Wukirsari",
    "value": "Wukirsari"
  },
  {
    "id": "3318032004",
    "district_id": "331803",
    "label": "Sinomwidodo",
    "value": "Sinomwidodo"
  },
  {
    "id": "3318032005",
    "district_id": "331803",
    "label": "Keben",
    "value": "Keben"
  },
  {
    "id": "3318032006",
    "district_id": "331803",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3318032007",
    "district_id": "331803",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "3318032008",
    "district_id": "331803",
    "label": "Mojomulyo",
    "value": "Mojomulyo"
  },
  {
    "id": "3318032009",
    "district_id": "331803",
    "label": "Karangawen",
    "value": "Karangawen"
  },
  {
    "id": "3318032010",
    "district_id": "331803",
    "label": "Mangunrekso",
    "value": "Mangunrekso"
  },
  {
    "id": "3318032011",
    "district_id": "331803",
    "label": "Tambaharjo",
    "value": "Tambaharjo"
  },
  {
    "id": "3318032012",
    "district_id": "331803",
    "label": "Tambahagung",
    "value": "Tambahagung"
  },
  {
    "id": "3318032013",
    "district_id": "331803",
    "label": "Sitirejo",
    "value": "Sitirejo"
  },
  {
    "id": "3318032014",
    "district_id": "331803",
    "label": "Kedalingan",
    "value": "Kedalingan"
  },
  {
    "id": "3318032015",
    "district_id": "331803",
    "label": "Karangmulyo",
    "value": "Karangmulyo"
  },
  {
    "id": "3318032016",
    "district_id": "331803",
    "label": "Karangwono",
    "value": "Karangwono"
  },
  {
    "id": "3318032017",
    "district_id": "331803",
    "label": "Angkatan Lor",
    "value": "Angkatan Lor"
  },
  {
    "id": "3318032018",
    "district_id": "331803",
    "label": "Angkatan Kidul",
    "value": "Angkatan Kidul"
  },
  {
    "id": "3318042001",
    "district_id": "331804",
    "label": "Pohgading",
    "value": "Pohgading"
  },
  {
    "id": "3318042002",
    "district_id": "331804",
    "label": "Gunungpanti",
    "value": "Gunungpanti"
  },
  {
    "id": "3318042003",
    "district_id": "331804",
    "label": "Godo",
    "value": "Godo"
  },
  {
    "id": "3318042004",
    "district_id": "331804",
    "label": "Kropak",
    "value": "Kropak"
  },
  {
    "id": "3318042005",
    "district_id": "331804",
    "label": "Karangsumber",
    "value": "Karangsumber"
  },
  {
    "id": "3318042006",
    "district_id": "331804",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3318042007",
    "district_id": "331804",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3318042008",
    "district_id": "331804",
    "label": "Kebolampang",
    "value": "Kebolampang"
  },
  {
    "id": "3318042009",
    "district_id": "331804",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3318042010",
    "district_id": "331804",
    "label": "Pagendisan",
    "value": "Pagendisan"
  },
  {
    "id": "3318042011",
    "district_id": "331804",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "3318042012",
    "district_id": "331804",
    "label": "Danyangmulyo",
    "value": "Danyangmulyo"
  },
  {
    "id": "3318042013",
    "district_id": "331804",
    "label": "Kudur",
    "value": "Kudur"
  },
  {
    "id": "3318042014",
    "district_id": "331804",
    "label": "Padangan",
    "value": "Padangan"
  },
  {
    "id": "3318042015",
    "district_id": "331804",
    "label": "Blingijati",
    "value": "Blingijati"
  },
  {
    "id": "3318042016",
    "district_id": "331804",
    "label": "Mintorahayu",
    "value": "Mintorahayu"
  },
  {
    "id": "3318042017",
    "district_id": "331804",
    "label": "Kebowan",
    "value": "Kebowan"
  },
  {
    "id": "3318042018",
    "district_id": "331804",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3318042019",
    "district_id": "331804",
    "label": "Klecoregonang",
    "value": "Klecoregonang"
  },
  {
    "id": "3318042020",
    "district_id": "331804",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3318042021",
    "district_id": "331804",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3318042022",
    "district_id": "331804",
    "label": "Bringinwareng",
    "value": "Bringinwareng"
  },
  {
    "id": "3318042023",
    "district_id": "331804",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3318042024",
    "district_id": "331804",
    "label": "Degan",
    "value": "Degan"
  },
  {
    "id": "3318042025",
    "district_id": "331804",
    "label": "Serutsadang",
    "value": "Serutsadang"
  },
  {
    "id": "3318042026",
    "district_id": "331804",
    "label": "Pulorejo",
    "value": "Pulorejo"
  },
  {
    "id": "3318042027",
    "district_id": "331804",
    "label": "Karangkonang",
    "value": "Karangkonang"
  },
  {
    "id": "3318042028",
    "district_id": "331804",
    "label": "Tanggel",
    "value": "Tanggel"
  },
  {
    "id": "3318042029",
    "district_id": "331804",
    "label": "Wirun",
    "value": "Wirun"
  },
  {
    "id": "3318042030",
    "district_id": "331804",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "3318052001",
    "district_id": "331805",
    "label": "Bodeh",
    "value": "Bodeh"
  },
  {
    "id": "3318052002",
    "district_id": "331805",
    "label": "Karangwotan",
    "value": "Karangwotan"
  },
  {
    "id": "3318052003",
    "district_id": "331805",
    "label": "Kepohkencono",
    "value": "Kepohkencono"
  },
  {
    "id": "3318052004",
    "district_id": "331805",
    "label": "Wateshaji",
    "value": "Wateshaji"
  },
  {
    "id": "3318052005",
    "district_id": "331805",
    "label": "Lumbungmas",
    "value": "Lumbungmas"
  },
  {
    "id": "3318052006",
    "district_id": "331805",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3318052007",
    "district_id": "331805",
    "label": "Sitimulyo",
    "value": "Sitimulyo"
  },
  {
    "id": "3318052008",
    "district_id": "331805",
    "label": "Kletek",
    "value": "Kletek"
  },
  {
    "id": "3318052009",
    "district_id": "331805",
    "label": "Terteg",
    "value": "Terteg"
  },
  {
    "id": "3318052010",
    "district_id": "331805",
    "label": "Mencon",
    "value": "Mencon"
  },
  {
    "id": "3318052011",
    "district_id": "331805",
    "label": "Puncakwangi",
    "value": "Puncakwangi"
  },
  {
    "id": "3318052012",
    "district_id": "331805",
    "label": "Pelemgede",
    "value": "Pelemgede"
  },
  {
    "id": "3318052013",
    "district_id": "331805",
    "label": "Tanjungsekar",
    "value": "Tanjungsekar"
  },
  {
    "id": "3318052014",
    "district_id": "331805",
    "label": "Triguno",
    "value": "Triguno"
  },
  {
    "id": "3318052015",
    "district_id": "331805",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3318052016",
    "district_id": "331805",
    "label": "Grogolsari",
    "value": "Grogolsari"
  },
  {
    "id": "3318052017",
    "district_id": "331805",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3318052018",
    "district_id": "331805",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3318052019",
    "district_id": "331805",
    "label": "Sokopuluhan",
    "value": "Sokopuluhan"
  },
  {
    "id": "3318052020",
    "district_id": "331805",
    "label": "Tegalwero",
    "value": "Tegalwero"
  },
  {
    "id": "3318062001",
    "district_id": "331806",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3318062002",
    "district_id": "331806",
    "label": "Trikoyo",
    "value": "Trikoyo"
  },
  {
    "id": "3318062003",
    "district_id": "331806",
    "label": "Sumberan",
    "value": "Sumberan"
  },
  {
    "id": "3318062004",
    "district_id": "331806",
    "label": "Mojolampir",
    "value": "Mojolampir"
  },
  {
    "id": "3318062005",
    "district_id": "331806",
    "label": "Mantingan",
    "value": "Mantingan"
  },
  {
    "id": "3318062006",
    "district_id": "331806",
    "label": "Ronggo",
    "value": "Ronggo"
  },
  {
    "id": "3318062007",
    "district_id": "331806",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3318062008",
    "district_id": "331806",
    "label": "Sidoluhur",
    "value": "Sidoluhur"
  },
  {
    "id": "3318062009",
    "district_id": "331806",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "3318062010",
    "district_id": "331806",
    "label": "Arumanis",
    "value": "Arumanis"
  },
  {
    "id": "3318062011",
    "district_id": "331806",
    "label": "Tegalarum",
    "value": "Tegalarum"
  },
  {
    "id": "3318062012",
    "district_id": "331806",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3318062013",
    "district_id": "331806",
    "label": "Mojoluhur",
    "value": "Mojoluhur"
  },
  {
    "id": "3318062014",
    "district_id": "331806",
    "label": "Kebonturi",
    "value": "Kebonturi"
  },
  {
    "id": "3318062015",
    "district_id": "331806",
    "label": "Lundo",
    "value": "Lundo"
  },
  {
    "id": "3318062016",
    "district_id": "331806",
    "label": "Sukorukun",
    "value": "Sukorukun"
  },
  {
    "id": "3318062017",
    "district_id": "331806",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3318062018",
    "district_id": "331806",
    "label": "Manjang",
    "value": "Manjang"
  },
  {
    "id": "3318062019",
    "district_id": "331806",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3318062020",
    "district_id": "331806",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3318062021",
    "district_id": "331806",
    "label": "Sriwedari",
    "value": "Sriwedari"
  },
  {
    "id": "3318072001",
    "district_id": "331807",
    "label": "Tlogomojo",
    "value": "Tlogomojo"
  },
  {
    "id": "3318072002",
    "district_id": "331807",
    "label": "Ngening",
    "value": "Ngening"
  },
  {
    "id": "3318072003",
    "district_id": "331807",
    "label": "Raci",
    "value": "Raci"
  },
  {
    "id": "3318072004",
    "district_id": "331807",
    "label": "Ketitangwetan",
    "value": "Ketitangwetan"
  },
  {
    "id": "3318072005",
    "district_id": "331807",
    "label": "Bumimulyo",
    "value": "Bumimulyo"
  },
  {
    "id": "3318072006",
    "district_id": "331807",
    "label": "Jembangan",
    "value": "Jembangan"
  },
  {
    "id": "3318072007",
    "district_id": "331807",
    "label": "Klayusiwalan",
    "value": "Klayusiwalan"
  },
  {
    "id": "3318072008",
    "district_id": "331807",
    "label": "Bulumulyo",
    "value": "Bulumulyo"
  },
  {
    "id": "3318072009",
    "district_id": "331807",
    "label": "Sukoagung",
    "value": "Sukoagung"
  },
  {
    "id": "3318072010",
    "district_id": "331807",
    "label": "Tompomulyo",
    "value": "Tompomulyo"
  },
  {
    "id": "3318072011",
    "district_id": "331807",
    "label": "Kuniran",
    "value": "Kuniran"
  },
  {
    "id": "3318072012",
    "district_id": "331807",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3318072013",
    "district_id": "331807",
    "label": "Kedalon",
    "value": "Kedalon"
  },
  {
    "id": "3318072014",
    "district_id": "331807",
    "label": "Gajahkumpul",
    "value": "Gajahkumpul"
  },
  {
    "id": "3318072015",
    "district_id": "331807",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3318072016",
    "district_id": "331807",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3318072017",
    "district_id": "331807",
    "label": "Mangunlegi",
    "value": "Mangunlegi"
  },
  {
    "id": "3318072018",
    "district_id": "331807",
    "label": "Pecangaan",
    "value": "Pecangaan"
  },
  {
    "id": "3318082001",
    "district_id": "331808",
    "label": "Sejomulyo",
    "value": "Sejomulyo"
  },
  {
    "id": "3318082002",
    "district_id": "331808",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3318082003",
    "district_id": "331808",
    "label": "Ketip",
    "value": "Ketip"
  },
  {
    "id": "3318082004",
    "district_id": "331808",
    "label": "Pekuwon",
    "value": "Pekuwon"
  },
  {
    "id": "3318082005",
    "district_id": "331808",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3318082006",
    "district_id": "331808",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3318082007",
    "district_id": "331808",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3318082008",
    "district_id": "331808",
    "label": "Kedungpancing",
    "value": "Kedungpancing"
  },
  {
    "id": "3318082009",
    "district_id": "331808",
    "label": "Jepuro",
    "value": "Jepuro"
  },
  {
    "id": "3318082010",
    "district_id": "331808",
    "label": "Tluwah",
    "value": "Tluwah"
  },
  {
    "id": "3318082011",
    "district_id": "331808",
    "label": "Doropayung",
    "value": "Doropayung"
  },
  {
    "id": "3318082012",
    "district_id": "331808",
    "label": "Mintomulyo",
    "value": "Mintomulyo"
  },
  {
    "id": "3318082013",
    "district_id": "331808",
    "label": "Gadingrejo",
    "value": "Gadingrejo"
  },
  {
    "id": "3318082014",
    "district_id": "331808",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3318082015",
    "district_id": "331808",
    "label": "Langgenharjo",
    "value": "Langgenharjo"
  },
  {
    "id": "3318082016",
    "district_id": "331808",
    "label": "Genengmulyo",
    "value": "Genengmulyo"
  },
  {
    "id": "3318082017",
    "district_id": "331808",
    "label": "Agungmulyo",
    "value": "Agungmulyo"
  },
  {
    "id": "3318082018",
    "district_id": "331808",
    "label": "Bakaran Kulon",
    "value": "Bakaran Kulon"
  },
  {
    "id": "3318082019",
    "district_id": "331808",
    "label": "Bakaran Wetan",
    "value": "Bakaran Wetan"
  },
  {
    "id": "3318082020",
    "district_id": "331808",
    "label": "Dukutalit",
    "value": "Dukutalit"
  },
  {
    "id": "3318082021",
    "district_id": "331808",
    "label": "Growong Kidul",
    "value": "Growong Kidul"
  },
  {
    "id": "3318082022",
    "district_id": "331808",
    "label": "Growong Lor",
    "value": "Growong Lor"
  },
  {
    "id": "3318082023",
    "district_id": "331808",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3318082024",
    "district_id": "331808",
    "label": "Pajeksan",
    "value": "Pajeksan"
  },
  {
    "id": "3318082025",
    "district_id": "331808",
    "label": "Kudukeras",
    "value": "Kudukeras"
  },
  {
    "id": "3318082026",
    "district_id": "331808",
    "label": "Kebonsawahan",
    "value": "Kebonsawahan"
  },
  {
    "id": "3318082027",
    "district_id": "331808",
    "label": "Bajomulyo",
    "value": "Bajomulyo"
  },
  {
    "id": "3318082028",
    "district_id": "331808",
    "label": "Bendar",
    "value": "Bendar"
  },
  {
    "id": "3318082029",
    "district_id": "331808",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3318092001",
    "district_id": "331809",
    "label": "Kedungmulyo",
    "value": "Kedungmulyo"
  },
  {
    "id": "3318092002",
    "district_id": "331809",
    "label": "Ngastorejo",
    "value": "Ngastorejo"
  },
  {
    "id": "3318092003",
    "district_id": "331809",
    "label": "Karangrowo",
    "value": "Karangrowo"
  },
  {
    "id": "3318092004",
    "district_id": "331809",
    "label": "Sonorejo",
    "value": "Sonorejo"
  },
  {
    "id": "3318092005",
    "district_id": "331809",
    "label": "Sendangsoko",
    "value": "Sendangsoko"
  },
  {
    "id": "3318092006",
    "district_id": "331809",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3318092007",
    "district_id": "331809",
    "label": "Sidoarum",
    "value": "Sidoarum"
  },
  {
    "id": "3318092008",
    "district_id": "331809",
    "label": "Tondomulyo",
    "value": "Tondomulyo"
  },
  {
    "id": "3318092009",
    "district_id": "331809",
    "label": "Bungasrejo",
    "value": "Bungasrejo"
  },
  {
    "id": "3318092010",
    "district_id": "331809",
    "label": "Glonggong",
    "value": "Glonggong"
  },
  {
    "id": "3318092011",
    "district_id": "331809",
    "label": "Kalimulyo",
    "value": "Kalimulyo"
  },
  {
    "id": "3318092012",
    "district_id": "331809",
    "label": "Tambahmulyo",
    "value": "Tambahmulyo"
  },
  {
    "id": "3318092013",
    "district_id": "331809",
    "label": "Tondokerto",
    "value": "Tondokerto"
  },
  {
    "id": "3318092014",
    "district_id": "331809",
    "label": "Sembaturagung",
    "value": "Sembaturagung"
  },
  {
    "id": "3318092015",
    "district_id": "331809",
    "label": "Dukuhmulyo",
    "value": "Dukuhmulyo"
  },
  {
    "id": "3318092016",
    "district_id": "331809",
    "label": "Puluhan Tengah",
    "value": "Puluhan Tengah"
  },
  {
    "id": "3318092017",
    "district_id": "331809",
    "label": "Mantingan Tengah",
    "value": "Mantingan Tengah"
  },
  {
    "id": "3318092018",
    "district_id": "331809",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3318092019",
    "district_id": "331809",
    "label": "Karangrejo Lor",
    "value": "Karangrejo Lor"
  },
  {
    "id": "3318092020",
    "district_id": "331809",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3318092021",
    "district_id": "331809",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3318092022",
    "district_id": "331809",
    "label": "Jakenan",
    "value": "Jakenan"
  },
  {
    "id": "3318092023",
    "district_id": "331809",
    "label": "Plosojenar",
    "value": "Plosojenar"
  },
  {
    "id": "3318101005",
    "district_id": "331810",
    "label": "Pati Wetan",
    "value": "Pati Wetan"
  },
  {
    "id": "3318101007",
    "district_id": "331810",
    "label": "Pati Kidul",
    "value": "Pati Kidul"
  },
  {
    "id": "3318101012",
    "district_id": "331810",
    "label": "Pati Lor",
    "value": "Pati Lor"
  },
  {
    "id": "3318101013",
    "district_id": "331810",
    "label": "Parenggan",
    "value": "Parenggan"
  },
  {
    "id": "3318101015",
    "district_id": "331810",
    "label": "Kalidoro",
    "value": "Kalidoro"
  },
  {
    "id": "3318102001",
    "district_id": "331810",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3318102002",
    "district_id": "331810",
    "label": "Gajahmati",
    "value": "Gajahmati"
  },
  {
    "id": "3318102003",
    "district_id": "331810",
    "label": "Mustokoharjo",
    "value": "Mustokoharjo"
  },
  {
    "id": "3318102004",
    "district_id": "331810",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3318102006",
    "district_id": "331810",
    "label": "Blaru",
    "value": "Blaru"
  },
  {
    "id": "3318102008",
    "district_id": "331810",
    "label": "Plangitan",
    "value": "Plangitan"
  },
  {
    "id": "3318102009",
    "district_id": "331810",
    "label": "Puri",
    "value": "Puri"
  },
  {
    "id": "3318102010",
    "district_id": "331810",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3318102011",
    "district_id": "331810",
    "label": "Ngarus",
    "value": "Ngarus"
  },
  {
    "id": "3318102014",
    "district_id": "331810",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3318102016",
    "district_id": "331810",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3318102017",
    "district_id": "331810",
    "label": "Geritan",
    "value": "Geritan"
  },
  {
    "id": "3318102018",
    "district_id": "331810",
    "label": "Dengkek",
    "value": "Dengkek"
  },
  {
    "id": "3318102019",
    "district_id": "331810",
    "label": "Sugiharjo",
    "value": "Sugiharjo"
  },
  {
    "id": "3318102020",
    "district_id": "331810",
    "label": "Widorokandang",
    "value": "Widorokandang"
  },
  {
    "id": "3318102021",
    "district_id": "331810",
    "label": "Payang",
    "value": "Payang"
  },
  {
    "id": "3318102022",
    "district_id": "331810",
    "label": "Kutoharjo",
    "value": "Kutoharjo"
  },
  {
    "id": "3318102023",
    "district_id": "331810",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "3318102024",
    "district_id": "331810",
    "label": "Mulyoharjo",
    "value": "Mulyoharjo"
  },
  {
    "id": "3318102025",
    "district_id": "331810",
    "label": "Tambaharjo",
    "value": "Tambaharjo"
  },
  {
    "id": "3318102026",
    "district_id": "331810",
    "label": "Tambahsari",
    "value": "Tambahsari"
  },
  {
    "id": "3318102027",
    "district_id": "331810",
    "label": "Ngepungrojo",
    "value": "Ngepungrojo"
  },
  {
    "id": "3318102028",
    "district_id": "331810",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3318102029",
    "district_id": "331810",
    "label": "Sinoman",
    "value": "Sinoman"
  },
  {
    "id": "3318112001",
    "district_id": "331811",
    "label": "Wuwur",
    "value": "Wuwur"
  },
  {
    "id": "3318112002",
    "district_id": "331811",
    "label": "Karaban",
    "value": "Karaban"
  },
  {
    "id": "3318112003",
    "district_id": "331811",
    "label": "Tlogoayu",
    "value": "Tlogoayu"
  },
  {
    "id": "3318112004",
    "district_id": "331811",
    "label": "Bogotanjung",
    "value": "Bogotanjung"
  },
  {
    "id": "3318112005",
    "district_id": "331811",
    "label": "Kuryokalangan",
    "value": "Kuryokalangan"
  },
  {
    "id": "3318112006",
    "district_id": "331811",
    "label": "Gabus",
    "value": "Gabus"
  },
  {
    "id": "3318112007",
    "district_id": "331811",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3318112008",
    "district_id": "331811",
    "label": "Sunggingwarno",
    "value": "Sunggingwarno"
  },
  {
    "id": "3318112009",
    "district_id": "331811",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "3318112010",
    "district_id": "331811",
    "label": "Tambahmulyo",
    "value": "Tambahmulyo"
  },
  {
    "id": "3318112011",
    "district_id": "331811",
    "label": "Sugihrejo",
    "value": "Sugihrejo"
  },
  {
    "id": "3318112012",
    "district_id": "331811",
    "label": "Mojolawaran",
    "value": "Mojolawaran"
  },
  {
    "id": "3318112013",
    "district_id": "331811",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3318112014",
    "district_id": "331811",
    "label": "Pantirejo",
    "value": "Pantirejo"
  },
  {
    "id": "3318112015",
    "district_id": "331811",
    "label": "Tanjang",
    "value": "Tanjang"
  },
  {
    "id": "3318112016",
    "district_id": "331811",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3318112017",
    "district_id": "331811",
    "label": "Plumbungan",
    "value": "Plumbungan"
  },
  {
    "id": "3318112018",
    "district_id": "331811",
    "label": "Babalan",
    "value": "Babalan"
  },
  {
    "id": "3318112019",
    "district_id": "331811",
    "label": "Koripandriyo",
    "value": "Koripandriyo"
  },
  {
    "id": "3318112020",
    "district_id": "331811",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3318112021",
    "district_id": "331811",
    "label": "Gempolsari",
    "value": "Gempolsari"
  },
  {
    "id": "3318112022",
    "district_id": "331811",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3318112023",
    "district_id": "331811",
    "label": "Mintobasuki",
    "value": "Mintobasuki"
  },
  {
    "id": "3318112024",
    "district_id": "331811",
    "label": "Kosekan",
    "value": "Kosekan"
  },
  {
    "id": "3318122001",
    "district_id": "331812",
    "label": "Jambean Kidul",
    "value": "Jambean Kidul"
  },
  {
    "id": "3318122002",
    "district_id": "331812",
    "label": "Wangunrejo",
    "value": "Wangunrejo"
  },
  {
    "id": "3318122003",
    "district_id": "331812",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3318122004",
    "district_id": "331812",
    "label": "Sokokulon",
    "value": "Sokokulon"
  },
  {
    "id": "3318122005",
    "district_id": "331812",
    "label": "Jimbaran",
    "value": "Jimbaran"
  },
  {
    "id": "3318122006",
    "district_id": "331812",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3318122007",
    "district_id": "331812",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3318122008",
    "district_id": "331812",
    "label": "Penambuhan",
    "value": "Penambuhan"
  },
  {
    "id": "3318122009",
    "district_id": "331812",
    "label": "Langenharjo",
    "value": "Langenharjo"
  },
  {
    "id": "3318122010",
    "district_id": "331812",
    "label": "Dadirejo",
    "value": "Dadirejo"
  },
  {
    "id": "3318122011",
    "district_id": "331812",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3318122012",
    "district_id": "331812",
    "label": "Badegan",
    "value": "Badegan"
  },
  {
    "id": "3318122013",
    "district_id": "331812",
    "label": "Pegandan",
    "value": "Pegandan"
  },
  {
    "id": "3318122014",
    "district_id": "331812",
    "label": "Sokobubuk",
    "value": "Sokobubuk"
  },
  {
    "id": "3318122015",
    "district_id": "331812",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3318122016",
    "district_id": "331812",
    "label": "Mataraman",
    "value": "Mataraman"
  },
  {
    "id": "3318122017",
    "district_id": "331812",
    "label": "Langse",
    "value": "Langse"
  },
  {
    "id": "3318122018",
    "district_id": "331812",
    "label": "Muktiharjo",
    "value": "Muktiharjo"
  },
  {
    "id": "3318132001",
    "district_id": "331813",
    "label": "Bermi",
    "value": "Bermi"
  },
  {
    "id": "3318132002",
    "district_id": "331813",
    "label": "Kedungbulus",
    "value": "Kedungbulus"
  },
  {
    "id": "3318132003",
    "district_id": "331813",
    "label": "Semirejo",
    "value": "Semirejo"
  },
  {
    "id": "3318132004",
    "district_id": "331813",
    "label": "Wonosekar",
    "value": "Wonosekar"
  },
  {
    "id": "3318132005",
    "district_id": "331813",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "3318132006",
    "district_id": "331813",
    "label": "Plukaran",
    "value": "Plukaran"
  },
  {
    "id": "3318132007",
    "district_id": "331813",
    "label": "Bageng",
    "value": "Bageng"
  },
  {
    "id": "3318132008",
    "district_id": "331813",
    "label": "Pohgading",
    "value": "Pohgading"
  },
  {
    "id": "3318132009",
    "district_id": "331813",
    "label": "Klakahkasihan",
    "value": "Klakahkasihan"
  },
  {
    "id": "3318132010",
    "district_id": "331813",
    "label": "Ketanggan",
    "value": "Ketanggan"
  },
  {
    "id": "3318132011",
    "district_id": "331813",
    "label": "Sitiluhur",
    "value": "Sitiluhur"
  },
  {
    "id": "3318142001",
    "district_id": "331814",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3318142002",
    "district_id": "331814",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3318142003",
    "district_id": "331814",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3318142004",
    "district_id": "331814",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3318142005",
    "district_id": "331814",
    "label": "Regaloh",
    "value": "Regaloh"
  },
  {
    "id": "3318142006",
    "district_id": "331814",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3318142007",
    "district_id": "331814",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3318142008",
    "district_id": "331814",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3318142009",
    "district_id": "331814",
    "label": "Guwo",
    "value": "Guwo"
  },
  {
    "id": "3318142010",
    "district_id": "331814",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3318142011",
    "district_id": "331814",
    "label": "Lahar",
    "value": "Lahar"
  },
  {
    "id": "3318142012",
    "district_id": "331814",
    "label": "Suwatu",
    "value": "Suwatu"
  },
  {
    "id": "3318142013",
    "district_id": "331814",
    "label": "Cabak",
    "value": "Cabak"
  },
  {
    "id": "3318142014",
    "district_id": "331814",
    "label": "Klumpit",
    "value": "Klumpit"
  },
  {
    "id": "3318142015",
    "district_id": "331814",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3318152001",
    "district_id": "331815",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3318152002",
    "district_id": "331815",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3318152003",
    "district_id": "331815",
    "label": "Tawangharjo",
    "value": "Tawangharjo"
  },
  {
    "id": "3318152004",
    "district_id": "331815",
    "label": "Ngurensiti",
    "value": "Ngurensiti"
  },
  {
    "id": "3318152005",
    "district_id": "331815",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3318152006",
    "district_id": "331815",
    "label": "Panggungroyom",
    "value": "Panggungroyom"
  },
  {
    "id": "3318152007",
    "district_id": "331815",
    "label": "Jontro",
    "value": "Jontro"
  },
  {
    "id": "3318152008",
    "district_id": "331815",
    "label": "Suwaduk",
    "value": "Suwaduk"
  },
  {
    "id": "3318152009",
    "district_id": "331815",
    "label": "Wedarijaksa",
    "value": "Wedarijaksa"
  },
  {
    "id": "3318152010",
    "district_id": "331815",
    "label": "Pagerharjo",
    "value": "Pagerharjo"
  },
  {
    "id": "3318152011",
    "district_id": "331815",
    "label": "Ngurenrejo",
    "value": "Ngurenrejo"
  },
  {
    "id": "3318152012",
    "district_id": "331815",
    "label": "Bangsalrejo",
    "value": "Bangsalrejo"
  },
  {
    "id": "3318152013",
    "district_id": "331815",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3318152014",
    "district_id": "331815",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3318152015",
    "district_id": "331815",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3318152016",
    "district_id": "331815",
    "label": "Tlogoharum",
    "value": "Tlogoharum"
  },
  {
    "id": "3318152017",
    "district_id": "331815",
    "label": "Kepoh",
    "value": "Kepoh"
  },
  {
    "id": "3318152018",
    "district_id": "331815",
    "label": "Tluwuk",
    "value": "Tluwuk"
  },
  {
    "id": "3318162001",
    "district_id": "331816",
    "label": "Tegalarum",
    "value": "Tegalarum"
  },
  {
    "id": "3318162002",
    "district_id": "331816",
    "label": "Soneyan",
    "value": "Soneyan"
  },
  {
    "id": "3318162003",
    "district_id": "331816",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3318162004",
    "district_id": "331816",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3318162005",
    "district_id": "331816",
    "label": "Pohijo",
    "value": "Pohijo"
  },
  {
    "id": "3318162006",
    "district_id": "331816",
    "label": "Kertomulyo",
    "value": "Kertomulyo"
  },
  {
    "id": "3318162007",
    "district_id": "331816",
    "label": "Langgenharjo",
    "value": "Langgenharjo"
  },
  {
    "id": "3318162008",
    "district_id": "331816",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3318162009",
    "district_id": "331816",
    "label": "Bulumanis Kidul",
    "value": "Bulumanis Kidul"
  },
  {
    "id": "3318162010",
    "district_id": "331816",
    "label": "Bulumanis Lor",
    "value": "Bulumanis Lor"
  },
  {
    "id": "3318162011",
    "district_id": "331816",
    "label": "Sekarjalak",
    "value": "Sekarjalak"
  },
  {
    "id": "3318162012",
    "district_id": "331816",
    "label": "Kajen",
    "value": "Kajen"
  },
  {
    "id": "3318162013",
    "district_id": "331816",
    "label": "Ngemplak Kidul",
    "value": "Ngemplak Kidul"
  },
  {
    "id": "3318162014",
    "district_id": "331816",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3318162015",
    "district_id": "331816",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3318162016",
    "district_id": "331816",
    "label": "Ngemplak Lor",
    "value": "Ngemplak Lor"
  },
  {
    "id": "3318162017",
    "district_id": "331816",
    "label": "Waturoyo",
    "value": "Waturoyo"
  },
  {
    "id": "3318162018",
    "district_id": "331816",
    "label": "Cebolek Kidul",
    "value": "Cebolek Kidul"
  },
  {
    "id": "3318162019",
    "district_id": "331816",
    "label": "Tunjungrejo",
    "value": "Tunjungrejo"
  },
  {
    "id": "3318162020",
    "district_id": "331816",
    "label": "Margoyoso",
    "value": "Margoyoso"
  },
  {
    "id": "3318162021",
    "district_id": "331816",
    "label": "Margotohu Kidul",
    "value": "Margotohu Kidul"
  },
  {
    "id": "3318162022",
    "district_id": "331816",
    "label": "Semerak",
    "value": "Semerak"
  },
  {
    "id": "3318172001",
    "district_id": "331817",
    "label": "Jrahi",
    "value": "Jrahi"
  },
  {
    "id": "3318172002",
    "district_id": "331817",
    "label": "Giling",
    "value": "Giling"
  },
  {
    "id": "3318172003",
    "district_id": "331817",
    "label": "Gulangpongge",
    "value": "Gulangpongge"
  },
  {
    "id": "3318172004",
    "district_id": "331817",
    "label": "Jepalo",
    "value": "Jepalo"
  },
  {
    "id": "3318172005",
    "district_id": "331817",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3318172006",
    "district_id": "331817",
    "label": "Sampok",
    "value": "Sampok"
  },
  {
    "id": "3318172007",
    "district_id": "331817",
    "label": "Pesagen",
    "value": "Pesagen"
  },
  {
    "id": "3318172008",
    "district_id": "331817",
    "label": "Gadu",
    "value": "Gadu"
  },
  {
    "id": "3318172009",
    "district_id": "331817",
    "label": "Gajihan",
    "value": "Gajihan"
  },
  {
    "id": "3318172010",
    "district_id": "331817",
    "label": "Perdopo",
    "value": "Perdopo"
  },
  {
    "id": "3318172011",
    "district_id": "331817",
    "label": "Gunungwungkal",
    "value": "Gunungwungkal"
  },
  {
    "id": "3318172012",
    "district_id": "331817",
    "label": "Bancak",
    "value": "Bancak"
  },
  {
    "id": "3318172013",
    "district_id": "331817",
    "label": "Jembulwunut",
    "value": "Jembulwunut"
  },
  {
    "id": "3318172014",
    "district_id": "331817",
    "label": "Ngetuk",
    "value": "Ngetuk"
  },
  {
    "id": "3318172015",
    "district_id": "331817",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3318182001",
    "district_id": "331818",
    "label": "Medani",
    "value": "Medani"
  },
  {
    "id": "3318182002",
    "district_id": "331818",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3318182003",
    "district_id": "331818",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3318182004",
    "district_id": "331818",
    "label": "Payak",
    "value": "Payak"
  },
  {
    "id": "3318182005",
    "district_id": "331818",
    "label": "Sirahan",
    "value": "Sirahan"
  },
  {
    "id": "3318182006",
    "district_id": "331818",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3318182007",
    "district_id": "331818",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3318182008",
    "district_id": "331818",
    "label": "Bleber",
    "value": "Bleber"
  },
  {
    "id": "3318182009",
    "district_id": "331818",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3318182010",
    "district_id": "331818",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "3318182011",
    "district_id": "331818",
    "label": "Gesengan",
    "value": "Gesengan"
  },
  {
    "id": "3318182012",
    "district_id": "331818",
    "label": "Gerit",
    "value": "Gerit"
  },
  {
    "id": "3318182013",
    "district_id": "331818",
    "label": "Sumur",
    "value": "Sumur"
  },
  {
    "id": "3318192001",
    "district_id": "331819",
    "label": "Pondowan",
    "value": "Pondowan"
  },
  {
    "id": "3318192002",
    "district_id": "331819",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3318192003",
    "district_id": "331819",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3318192004",
    "district_id": "331819",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3318192005",
    "district_id": "331819",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3318192006",
    "district_id": "331819",
    "label": "Jepat Kidul",
    "value": "Jepat Kidul"
  },
  {
    "id": "3318192007",
    "district_id": "331819",
    "label": "Tunggulsari",
    "value": "Tunggulsari"
  },
  {
    "id": "3318192008",
    "district_id": "331819",
    "label": "Jepat Lor",
    "value": "Jepat Lor"
  },
  {
    "id": "3318192009",
    "district_id": "331819",
    "label": "Tendas",
    "value": "Tendas"
  },
  {
    "id": "3318192010",
    "district_id": "331819",
    "label": "Keboromo",
    "value": "Keboromo"
  },
  {
    "id": "3318192011",
    "district_id": "331819",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3318192012",
    "district_id": "331819",
    "label": "Tayu Wetan",
    "value": "Tayu Wetan"
  },
  {
    "id": "3318192013",
    "district_id": "331819",
    "label": "Tayu Kulon",
    "value": "Tayu Kulon"
  },
  {
    "id": "3318192014",
    "district_id": "331819",
    "label": "Pundenrejo",
    "value": "Pundenrejo"
  },
  {
    "id": "3318192015",
    "district_id": "331819",
    "label": "Kedungbang",
    "value": "Kedungbang"
  },
  {
    "id": "3318192016",
    "district_id": "331819",
    "label": "Bendokaton Kidul",
    "value": "Bendokaton Kidul"
  },
  {
    "id": "3318192017",
    "district_id": "331819",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3318192018",
    "district_id": "331819",
    "label": "Bulungan",
    "value": "Bulungan"
  },
  {
    "id": "3318192019",
    "district_id": "331819",
    "label": "Luwang",
    "value": "Luwang"
  },
  {
    "id": "3318192020",
    "district_id": "331819",
    "label": "Dororejo",
    "value": "Dororejo"
  },
  {
    "id": "3318192021",
    "district_id": "331819",
    "label": "Kalikalong",
    "value": "Kalikalong"
  },
  {
    "id": "3318202001",
    "district_id": "331820",
    "label": "Wedusan",
    "value": "Wedusan"
  },
  {
    "id": "3318202002",
    "district_id": "331820",
    "label": "Dumpil",
    "value": "Dumpil"
  },
  {
    "id": "3318202003",
    "district_id": "331820",
    "label": "Ngagel",
    "value": "Ngagel"
  },
  {
    "id": "3318202004",
    "district_id": "331820",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3318202005",
    "district_id": "331820",
    "label": "Kenanti",
    "value": "Kenanti"
  },
  {
    "id": "3318202006",
    "district_id": "331820",
    "label": "Alasdowo",
    "value": "Alasdowo"
  },
  {
    "id": "3318202007",
    "district_id": "331820",
    "label": "Banyutowo",
    "value": "Banyutowo"
  },
  {
    "id": "3318202008",
    "district_id": "331820",
    "label": "Dukuhseti",
    "value": "Dukuhseti"
  },
  {
    "id": "3318202009",
    "district_id": "331820",
    "label": "Grogolan",
    "value": "Grogolan"
  },
  {
    "id": "3318202010",
    "district_id": "331820",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3318202011",
    "district_id": "331820",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "3318202012",
    "district_id": "331820",
    "label": "Puncel",
    "value": "Puncel"
  },
  {
    "id": "3318212001",
    "district_id": "331821",
    "label": "Kajar",
    "value": "Kajar"
  },
  {
    "id": "3318212002",
    "district_id": "331821",
    "label": "Trangkil",
    "value": "Trangkil"
  },
  {
    "id": "3318212003",
    "district_id": "331821",
    "label": "Pasucen",
    "value": "Pasucen"
  },
  {
    "id": "3318212004",
    "district_id": "331821",
    "label": "Tegalharjo",
    "value": "Tegalharjo"
  },
  {
    "id": "3318212005",
    "district_id": "331821",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3318212006",
    "district_id": "331821",
    "label": "Ketanen",
    "value": "Ketanen"
  },
  {
    "id": "3318212007",
    "district_id": "331821",
    "label": "Karanglegi",
    "value": "Karanglegi"
  },
  {
    "id": "3318212008",
    "district_id": "331821",
    "label": "Karangwage",
    "value": "Karangwage"
  },
  {
    "id": "3318212009",
    "district_id": "331821",
    "label": "Kadilangu",
    "value": "Kadilangu"
  },
  {
    "id": "3318212010",
    "district_id": "331821",
    "label": "Tlutup",
    "value": "Tlutup"
  },
  {
    "id": "3318212011",
    "district_id": "331821",
    "label": "Krandan",
    "value": "Krandan"
  },
  {
    "id": "3318212012",
    "district_id": "331821",
    "label": "Kertomulyo",
    "value": "Kertomulyo"
  },
  {
    "id": "3318212013",
    "district_id": "331821",
    "label": "Rejoagung",
    "value": "Rejoagung"
  },
  {
    "id": "3318212014",
    "district_id": "331821",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3318212015",
    "district_id": "331821",
    "label": "Sambilawang",
    "value": "Sambilawang"
  },
  {
    "id": "3318212016",
    "district_id": "331821",
    "label": "Asempapan",
    "value": "Asempapan"
  },
  {
    "id": "3319012001",
    "district_id": "331901",
    "label": "Bakalankrapyak",
    "value": "Bakalankrapyak"
  },
  {
    "id": "3319012002",
    "district_id": "331901",
    "label": "Prambatan Kidul",
    "value": "Prambatan Kidul"
  },
  {
    "id": "3319012003",
    "district_id": "331901",
    "label": "Prambatan Lor",
    "value": "Prambatan Lor"
  },
  {
    "id": "3319012004",
    "district_id": "331901",
    "label": "Garung Kidul",
    "value": "Garung Kidul"
  },
  {
    "id": "3319012005",
    "district_id": "331901",
    "label": "Setrokalangan",
    "value": "Setrokalangan"
  },
  {
    "id": "3319012006",
    "district_id": "331901",
    "label": "Banget",
    "value": "Banget"
  },
  {
    "id": "3319012007",
    "district_id": "331901",
    "label": "Blimbing Kidul",
    "value": "Blimbing Kidul"
  },
  {
    "id": "3319012008",
    "district_id": "331901",
    "label": "Sidorekso",
    "value": "Sidorekso"
  },
  {
    "id": "3319012009",
    "district_id": "331901",
    "label": "Gamong",
    "value": "Gamong"
  },
  {
    "id": "3319012010",
    "district_id": "331901",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3319012011",
    "district_id": "331901",
    "label": "Garung Lor",
    "value": "Garung Lor"
  },
  {
    "id": "3319012012",
    "district_id": "331901",
    "label": "Karangampel",
    "value": "Karangampel"
  },
  {
    "id": "3319012013",
    "district_id": "331901",
    "label": "Mijen",
    "value": "Mijen"
  },
  {
    "id": "3319012014",
    "district_id": "331901",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3319012015",
    "district_id": "331901",
    "label": "Papringan",
    "value": "Papringan"
  },
  {
    "id": "3319021001",
    "district_id": "331902",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3319021004",
    "district_id": "331902",
    "label": "Sunggingan",
    "value": "Sunggingan"
  },
  {
    "id": "3319021005",
    "district_id": "331902",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3319021006",
    "district_id": "331902",
    "label": "Wergu Wetan",
    "value": "Wergu Wetan"
  },
  {
    "id": "3319021007",
    "district_id": "331902",
    "label": "Wergu Kulon",
    "value": "Wergu Kulon"
  },
  {
    "id": "3319021008",
    "district_id": "331902",
    "label": "Mlati Kidul",
    "value": "Mlati Kidul"
  },
  {
    "id": "3319021009",
    "district_id": "331902",
    "label": "Mlati Norowito",
    "value": "Mlati Norowito"
  },
  {
    "id": "3319021017",
    "district_id": "331902",
    "label": "Kerjasan",
    "value": "Kerjasan"
  },
  {
    "id": "3319021018",
    "district_id": "331902",
    "label": "Kajeksan",
    "value": "Kajeksan"
  },
  {
    "id": "3319022002",
    "district_id": "331902",
    "label": "Janggalan",
    "value": "Janggalan"
  },
  {
    "id": "3319022003",
    "district_id": "331902",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3319022010",
    "district_id": "331902",
    "label": "Mlati Lor",
    "value": "Mlati Lor"
  },
  {
    "id": "3319022011",
    "district_id": "331902",
    "label": "Nganguk",
    "value": "Nganguk"
  },
  {
    "id": "3319022012",
    "district_id": "331902",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3319022013",
    "district_id": "331902",
    "label": "Demaan",
    "value": "Demaan"
  },
  {
    "id": "3319022014",
    "district_id": "331902",
    "label": "Langgardalem",
    "value": "Langgardalem"
  },
  {
    "id": "3319022015",
    "district_id": "331902",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3319022016",
    "district_id": "331902",
    "label": "Damaran",
    "value": "Damaran"
  },
  {
    "id": "3319022019",
    "district_id": "331902",
    "label": "Krandon",
    "value": "Krandon"
  },
  {
    "id": "3319022020",
    "district_id": "331902",
    "label": "Singocandi",
    "value": "Singocandi"
  },
  {
    "id": "3319022021",
    "district_id": "331902",
    "label": "Glantengan",
    "value": "Glantengan"
  },
  {
    "id": "3319022022",
    "district_id": "331902",
    "label": "Kaliputu",
    "value": "Kaliputu"
  },
  {
    "id": "3319022023",
    "district_id": "331902",
    "label": "Barongan",
    "value": "Barongan"
  },
  {
    "id": "3319022024",
    "district_id": "331902",
    "label": "Burikan",
    "value": "Burikan"
  },
  {
    "id": "3319022025",
    "district_id": "331902",
    "label": "Rendeng",
    "value": "Rendeng"
  },
  {
    "id": "3319032001",
    "district_id": "331903",
    "label": "Jetiskapuan",
    "value": "Jetiskapuan"
  },
  {
    "id": "3319032002",
    "district_id": "331903",
    "label": "Tanjungkarang",
    "value": "Tanjungkarang"
  },
  {
    "id": "3319032003",
    "district_id": "331903",
    "label": "Jati Wetan",
    "value": "Jati Wetan"
  },
  {
    "id": "3319032004",
    "district_id": "331903",
    "label": "Pasuruhan Kidul",
    "value": "Pasuruhan Kidul"
  },
  {
    "id": "3319032005",
    "district_id": "331903",
    "label": "Pasuruhan Lor",
    "value": "Pasuruhan Lor"
  },
  {
    "id": "3319032006",
    "district_id": "331903",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3319032007",
    "district_id": "331903",
    "label": "Jati Kulon",
    "value": "Jati Kulon"
  },
  {
    "id": "3319032008",
    "district_id": "331903",
    "label": "Getaspejaten",
    "value": "Getaspejaten"
  },
  {
    "id": "3319032009",
    "district_id": "331903",
    "label": "Loram Kulon",
    "value": "Loram Kulon"
  },
  {
    "id": "3319032010",
    "district_id": "331903",
    "label": "Loram Wetan",
    "value": "Loram Wetan"
  },
  {
    "id": "3319032011",
    "district_id": "331903",
    "label": "Jepangpakis",
    "value": "Jepangpakis"
  },
  {
    "id": "3319032012",
    "district_id": "331903",
    "label": "Megawon",
    "value": "Megawon"
  },
  {
    "id": "3319032013",
    "district_id": "331903",
    "label": "Ngembal Kulon",
    "value": "Ngembal Kulon"
  },
  {
    "id": "3319032014",
    "district_id": "331903",
    "label": "Tumpangkrasak",
    "value": "Tumpangkrasak"
  },
  {
    "id": "3319042001",
    "district_id": "331904",
    "label": "Wonosoco",
    "value": "Wonosoco"
  },
  {
    "id": "3319042002",
    "district_id": "331904",
    "label": "Lambangan",
    "value": "Lambangan"
  },
  {
    "id": "3319042003",
    "district_id": "331904",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3319042004",
    "district_id": "331904",
    "label": "Medini",
    "value": "Medini"
  },
  {
    "id": "3319042005",
    "district_id": "331904",
    "label": "Sambung",
    "value": "Sambung"
  },
  {
    "id": "3319042006",
    "district_id": "331904",
    "label": "Glagahwaru",
    "value": "Glagahwaru"
  },
  {
    "id": "3319042007",
    "district_id": "331904",
    "label": "Kutuk",
    "value": "Kutuk"
  },
  {
    "id": "3319042008",
    "district_id": "331904",
    "label": "Undaan Kidul",
    "value": "Undaan Kidul"
  },
  {
    "id": "3319042009",
    "district_id": "331904",
    "label": "Undaan Tengah",
    "value": "Undaan Tengah"
  },
  {
    "id": "3319042010",
    "district_id": "331904",
    "label": "Karangrowo",
    "value": "Karangrowo"
  },
  {
    "id": "3319042011",
    "district_id": "331904",
    "label": "Larikrejo",
    "value": "Larikrejo"
  },
  {
    "id": "3319042012",
    "district_id": "331904",
    "label": "Undaan Lor",
    "value": "Undaan Lor"
  },
  {
    "id": "3319042013",
    "district_id": "331904",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3319042014",
    "district_id": "331904",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3319042015",
    "district_id": "331904",
    "label": "Terangmas",
    "value": "Terangmas"
  },
  {
    "id": "3319042016",
    "district_id": "331904",
    "label": "Berugenjang",
    "value": "Berugenjang"
  },
  {
    "id": "3319052001",
    "district_id": "331905",
    "label": "Gulang",
    "value": "Gulang"
  },
  {
    "id": "3319052002",
    "district_id": "331905",
    "label": "Jepang",
    "value": "Jepang"
  },
  {
    "id": "3319052003",
    "district_id": "331905",
    "label": "Payaman",
    "value": "Payaman"
  },
  {
    "id": "3319052004",
    "district_id": "331905",
    "label": "Kirig",
    "value": "Kirig"
  },
  {
    "id": "3319052005",
    "district_id": "331905",
    "label": "Temulus",
    "value": "Temulus"
  },
  {
    "id": "3319052006",
    "district_id": "331905",
    "label": "Kesambi",
    "value": "Kesambi"
  },
  {
    "id": "3319052007",
    "district_id": "331905",
    "label": "Jojo",
    "value": "Jojo"
  },
  {
    "id": "3319052008",
    "district_id": "331905",
    "label": "Hadiwarno",
    "value": "Hadiwarno"
  },
  {
    "id": "3319052009",
    "district_id": "331905",
    "label": "Mejobo",
    "value": "Mejobo"
  },
  {
    "id": "3319052010",
    "district_id": "331905",
    "label": "Golantepus",
    "value": "Golantepus"
  },
  {
    "id": "3319052011",
    "district_id": "331905",
    "label": "Tenggeles",
    "value": "Tenggeles"
  },
  {
    "id": "3319062001",
    "district_id": "331906",
    "label": "Sadang",
    "value": "Sadang"
  },
  {
    "id": "3319062002",
    "district_id": "331906",
    "label": "Bulungcangkring",
    "value": "Bulungcangkring"
  },
  {
    "id": "3319062003",
    "district_id": "331906",
    "label": "Bulung Kulon",
    "value": "Bulung Kulon"
  },
  {
    "id": "3319062004",
    "district_id": "331906",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3319062005",
    "district_id": "331906",
    "label": "Gondoharum",
    "value": "Gondoharum"
  },
  {
    "id": "3319062006",
    "district_id": "331906",
    "label": "Terban",
    "value": "Terban"
  },
  {
    "id": "3319062007",
    "district_id": "331906",
    "label": "Pladen",
    "value": "Pladen"
  },
  {
    "id": "3319062008",
    "district_id": "331906",
    "label": "Klaling",
    "value": "Klaling"
  },
  {
    "id": "3319062009",
    "district_id": "331906",
    "label": "Jekulo",
    "value": "Jekulo"
  },
  {
    "id": "3319062010",
    "district_id": "331906",
    "label": "Hadipolo",
    "value": "Hadipolo"
  },
  {
    "id": "3319062011",
    "district_id": "331906",
    "label": "Honggosoco",
    "value": "Honggosoco"
  },
  {
    "id": "3319062012",
    "district_id": "331906",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3319072001",
    "district_id": "331907",
    "label": "Dersalam",
    "value": "Dersalam"
  },
  {
    "id": "3319072002",
    "district_id": "331907",
    "label": "Ngembalrejo",
    "value": "Ngembalrejo"
  },
  {
    "id": "3319072003",
    "district_id": "331907",
    "label": "Karangbener",
    "value": "Karangbener"
  },
  {
    "id": "3319072004",
    "district_id": "331907",
    "label": "Gondangmanis",
    "value": "Gondangmanis"
  },
  {
    "id": "3319072005",
    "district_id": "331907",
    "label": "Pedawang",
    "value": "Pedawang"
  },
  {
    "id": "3319072006",
    "district_id": "331907",
    "label": "Bacin",
    "value": "Bacin"
  },
  {
    "id": "3319072007",
    "district_id": "331907",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "3319072008",
    "district_id": "331907",
    "label": "Peganjaran",
    "value": "Peganjaran"
  },
  {
    "id": "3319072009",
    "district_id": "331907",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3319072010",
    "district_id": "331907",
    "label": "Bae",
    "value": "Bae"
  },
  {
    "id": "3319082001",
    "district_id": "331908",
    "label": "Gribig",
    "value": "Gribig"
  },
  {
    "id": "3319082002",
    "district_id": "331908",
    "label": "Klumpit",
    "value": "Klumpit"
  },
  {
    "id": "3319082003",
    "district_id": "331908",
    "label": "Getassrabi",
    "value": "Getassrabi"
  },
  {
    "id": "3319082004",
    "district_id": "331908",
    "label": "Padurenan",
    "value": "Padurenan"
  },
  {
    "id": "3319082005",
    "district_id": "331908",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3319082006",
    "district_id": "331908",
    "label": "Besito",
    "value": "Besito"
  },
  {
    "id": "3319082007",
    "district_id": "331908",
    "label": "Jurang",
    "value": "Jurang"
  },
  {
    "id": "3319082008",
    "district_id": "331908",
    "label": "Gondosari",
    "value": "Gondosari"
  },
  {
    "id": "3319082009",
    "district_id": "331908",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3319082010",
    "district_id": "331908",
    "label": "Menawan",
    "value": "Menawan"
  },
  {
    "id": "3319082011",
    "district_id": "331908",
    "label": "Rahtawu",
    "value": "Rahtawu"
  },
  {
    "id": "3319092001",
    "district_id": "331909",
    "label": "Samirejo",
    "value": "Samirejo"
  },
  {
    "id": "3319092002",
    "district_id": "331909",
    "label": "Cendono",
    "value": "Cendono"
  },
  {
    "id": "3319092003",
    "district_id": "331909",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3319092004",
    "district_id": "331909",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3319092005",
    "district_id": "331909",
    "label": "Kandangmas",
    "value": "Kandangmas"
  },
  {
    "id": "3319092006",
    "district_id": "331909",
    "label": "Glagah Kulon",
    "value": "Glagah Kulon"
  },
  {
    "id": "3319092007",
    "district_id": "331909",
    "label": "Tergo",
    "value": "Tergo"
  },
  {
    "id": "3319092008",
    "district_id": "331909",
    "label": "Cranggang",
    "value": "Cranggang"
  },
  {
    "id": "3319092009",
    "district_id": "331909",
    "label": "Lau",
    "value": "Lau"
  },
  {
    "id": "3319092010",
    "district_id": "331909",
    "label": "Piji",
    "value": "Piji"
  },
  {
    "id": "3319092011",
    "district_id": "331909",
    "label": "Puyoh",
    "value": "Puyoh"
  },
  {
    "id": "3319092012",
    "district_id": "331909",
    "label": "Soco",
    "value": "Soco"
  },
  {
    "id": "3319092013",
    "district_id": "331909",
    "label": "Ternadi",
    "value": "Ternadi"
  },
  {
    "id": "3319092014",
    "district_id": "331909",
    "label": "Kajar",
    "value": "Kajar"
  },
  {
    "id": "3319092015",
    "district_id": "331909",
    "label": "Kuwukan",
    "value": "Kuwukan"
  },
  {
    "id": "3319092016",
    "district_id": "331909",
    "label": "Dukuhwaringin",
    "value": "Dukuhwaringin"
  },
  {
    "id": "3319092017",
    "district_id": "331909",
    "label": "Japan",
    "value": "Japan"
  },
  {
    "id": "3319092018",
    "district_id": "331909",
    "label": "Colo",
    "value": "Colo"
  },
  {
    "id": "3320012001",
    "district_id": "332001",
    "label": "Kedungmalang",
    "value": "Kedungmalang"
  },
  {
    "id": "3320012002",
    "district_id": "332001",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3320012003",
    "district_id": "332001",
    "label": "Karangaji",
    "value": "Karangaji"
  },
  {
    "id": "3320012004",
    "district_id": "332001",
    "label": "Tedunan",
    "value": "Tedunan"
  },
  {
    "id": "3320012005",
    "district_id": "332001",
    "label": "Sowan Lor",
    "value": "Sowan Lor"
  },
  {
    "id": "3320012006",
    "district_id": "332001",
    "label": "Sowan Kidul",
    "value": "Sowan Kidul"
  },
  {
    "id": "3320012007",
    "district_id": "332001",
    "label": "Wanusobo",
    "value": "Wanusobo"
  },
  {
    "id": "3320012008",
    "district_id": "332001",
    "label": "Surodadi",
    "value": "Surodadi"
  },
  {
    "id": "3320012009",
    "district_id": "332001",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "3320012010",
    "district_id": "332001",
    "label": "Bulak Baru",
    "value": "Bulak Baru"
  },
  {
    "id": "3320012011",
    "district_id": "332001",
    "label": "Jondang",
    "value": "Jondang"
  },
  {
    "id": "3320012012",
    "district_id": "332001",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3320012013",
    "district_id": "332001",
    "label": "Dongos",
    "value": "Dongos"
  },
  {
    "id": "3320012014",
    "district_id": "332001",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "3320012015",
    "district_id": "332001",
    "label": "Kerso",
    "value": "Kerso"
  },
  {
    "id": "3320012016",
    "district_id": "332001",
    "label": "Tanggul Tlare",
    "value": "Tanggul Tlare"
  },
  {
    "id": "3320012017",
    "district_id": "332001",
    "label": "Rau",
    "value": "Rau"
  },
  {
    "id": "3320012018",
    "district_id": "332001",
    "label": "Sukosono",
    "value": "Sukosono"
  },
  {
    "id": "3320022001",
    "district_id": "332002",
    "label": "Kaliombo",
    "value": "Kaliombo"
  },
  {
    "id": "3320022002",
    "district_id": "332002",
    "label": "Karangrandu",
    "value": "Karangrandu"
  },
  {
    "id": "3320022003",
    "district_id": "332002",
    "label": "Gerdu",
    "value": "Gerdu"
  },
  {
    "id": "3320022004",
    "district_id": "332002",
    "label": "Pecangaan Kulon",
    "value": "Pecangaan Kulon"
  },
  {
    "id": "3320022005",
    "district_id": "332002",
    "label": "Rengging",
    "value": "Rengging"
  },
  {
    "id": "3320022006",
    "district_id": "332002",
    "label": "Troso",
    "value": "Troso"
  },
  {
    "id": "3320022007",
    "district_id": "332002",
    "label": "Ngeling",
    "value": "Ngeling"
  },
  {
    "id": "3320022008",
    "district_id": "332002",
    "label": "Pulodarat",
    "value": "Pulodarat"
  },
  {
    "id": "3320022009",
    "district_id": "332002",
    "label": "Lebuawu",
    "value": "Lebuawu"
  },
  {
    "id": "3320022010",
    "district_id": "332002",
    "label": "Gemulung",
    "value": "Gemulung"
  },
  {
    "id": "3320022011",
    "district_id": "332002",
    "label": "Pecangaan Wetan",
    "value": "Pecangaan Wetan"
  },
  {
    "id": "3320022012",
    "district_id": "332002",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3320032001",
    "district_id": "332003",
    "label": "Ujung Pandan",
    "value": "Ujung Pandan"
  },
  {
    "id": "3320032002",
    "district_id": "332003",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3320032003",
    "district_id": "332003",
    "label": "Guwosobokerto",
    "value": "Guwosobokerto"
  },
  {
    "id": "3320032004",
    "district_id": "332003",
    "label": "Kedungsarimulyo",
    "value": "Kedungsarimulyo"
  },
  {
    "id": "3320032005",
    "district_id": "332003",
    "label": "Bugo",
    "value": "Bugo"
  },
  {
    "id": "3320032006",
    "district_id": "332003",
    "label": "Welahan",
    "value": "Welahan"
  },
  {
    "id": "3320032007",
    "district_id": "332003",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3320032008",
    "district_id": "332003",
    "label": "Ketilengsingolelo",
    "value": "Ketilengsingolelo"
  },
  {
    "id": "3320032009",
    "district_id": "332003",
    "label": "Kalipucang Wetan",
    "value": "Kalipucang Wetan"
  },
  {
    "id": "3320032010",
    "district_id": "332003",
    "label": "Kalipucang Kulon",
    "value": "Kalipucang Kulon"
  },
  {
    "id": "3320032011",
    "district_id": "332003",
    "label": "Gidanglelo",
    "value": "Gidanglelo"
  },
  {
    "id": "3320032012",
    "district_id": "332003",
    "label": "Kendengsidialit",
    "value": "Kendengsidialit"
  },
  {
    "id": "3320032013",
    "district_id": "332003",
    "label": "Sidigede",
    "value": "Sidigede"
  },
  {
    "id": "3320032014",
    "district_id": "332003",
    "label": "Teluk Wetan",
    "value": "Teluk Wetan"
  },
  {
    "id": "3320032015",
    "district_id": "332003",
    "label": "Brantak Sekarjati",
    "value": "Brantak Sekarjati"
  },
  {
    "id": "3320042001",
    "district_id": "332004",
    "label": "Mayong Lor",
    "value": "Mayong Lor"
  },
  {
    "id": "3320042002",
    "district_id": "332004",
    "label": "Tigojuru",
    "value": "Tigojuru"
  },
  {
    "id": "3320042003",
    "district_id": "332004",
    "label": "Paren",
    "value": "Paren"
  },
  {
    "id": "3320042004",
    "district_id": "332004",
    "label": "Kuanyar",
    "value": "Kuanyar"
  },
  {
    "id": "3320042005",
    "district_id": "332004",
    "label": "Pelang",
    "value": "Pelang"
  },
  {
    "id": "3320042006",
    "district_id": "332004",
    "label": "Sengonbugel",
    "value": "Sengonbugel"
  },
  {
    "id": "3320042007",
    "district_id": "332004",
    "label": "Jebol",
    "value": "Jebol"
  },
  {
    "id": "3320042008",
    "district_id": "332004",
    "label": "Singorojo",
    "value": "Singorojo"
  },
  {
    "id": "3320042009",
    "district_id": "332004",
    "label": "Pelemkerep",
    "value": "Pelemkerep"
  },
  {
    "id": "3320042010",
    "district_id": "332004",
    "label": "Buaran",
    "value": "Buaran"
  },
  {
    "id": "3320042011",
    "district_id": "332004",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3320042012",
    "district_id": "332004",
    "label": "Rajekwesi",
    "value": "Rajekwesi"
  },
  {
    "id": "3320042013",
    "district_id": "332004",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "3320042014",
    "district_id": "332004",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3320042015",
    "district_id": "332004",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3320042016",
    "district_id": "332004",
    "label": "Bungu",
    "value": "Bungu"
  },
  {
    "id": "3320042017",
    "district_id": "332004",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "3320042018",
    "district_id": "332004",
    "label": "Mayong Kidul",
    "value": "Mayong Kidul"
  },
  {
    "id": "3320052001",
    "district_id": "332005",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3320052002",
    "district_id": "332005",
    "label": "Raguklampitan",
    "value": "Raguklampitan"
  },
  {
    "id": "3320052003",
    "district_id": "332005",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3320052004",
    "district_id": "332005",
    "label": "Bawu",
    "value": "Bawu"
  },
  {
    "id": "3320052005",
    "district_id": "332005",
    "label": "Mindahan",
    "value": "Mindahan"
  },
  {
    "id": "3320052006",
    "district_id": "332005",
    "label": "Somosari",
    "value": "Somosari"
  },
  {
    "id": "3320052007",
    "district_id": "332005",
    "label": "Batealit",
    "value": "Batealit"
  },
  {
    "id": "3320052008",
    "district_id": "332005",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3320052009",
    "district_id": "332005",
    "label": "Bantrung",
    "value": "Bantrung"
  },
  {
    "id": "3320052010",
    "district_id": "332005",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "3320052011",
    "district_id": "332005",
    "label": "Mindahan Kidul",
    "value": "Mindahan Kidul"
  },
  {
    "id": "3320061001",
    "district_id": "332006",
    "label": "Karangkebagusan",
    "value": "Karangkebagusan"
  },
  {
    "id": "3320061002",
    "district_id": "332006",
    "label": "Demaan",
    "value": "Demaan"
  },
  {
    "id": "3320061003",
    "district_id": "332006",
    "label": "Potroyudan",
    "value": "Potroyudan"
  },
  {
    "id": "3320061004",
    "district_id": "332006",
    "label": "Bapangan",
    "value": "Bapangan"
  },
  {
    "id": "3320061005",
    "district_id": "332006",
    "label": "Saripan",
    "value": "Saripan"
  },
  {
    "id": "3320061006",
    "district_id": "332006",
    "label": "Panggang",
    "value": "Panggang"
  },
  {
    "id": "3320061007",
    "district_id": "332006",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3320061008",
    "district_id": "332006",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3320061009",
    "district_id": "332006",
    "label": "Jobokuto",
    "value": "Jobokuto"
  },
  {
    "id": "3320061010",
    "district_id": "332006",
    "label": "Ujungbatu",
    "value": "Ujungbatu"
  },
  {
    "id": "3320061011",
    "district_id": "332006",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3320062012",
    "district_id": "332006",
    "label": "Mulyoharjo",
    "value": "Mulyoharjo"
  },
  {
    "id": "3320062013",
    "district_id": "332006",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3320062014",
    "district_id": "332006",
    "label": "Kedungcino",
    "value": "Kedungcino"
  },
  {
    "id": "3320062015",
    "district_id": "332006",
    "label": "Kuwasen",
    "value": "Kuwasen"
  },
  {
    "id": "3320062016",
    "district_id": "332006",
    "label": "Bandengan",
    "value": "Bandengan"
  },
  {
    "id": "3320072001",
    "district_id": "332007",
    "label": "Mororejo",
    "value": "Mororejo"
  },
  {
    "id": "3320072009",
    "district_id": "332007",
    "label": "Suwawal",
    "value": "Suwawal"
  },
  {
    "id": "3320072010",
    "district_id": "332007",
    "label": "Sinanggul",
    "value": "Sinanggul"
  },
  {
    "id": "3320072011",
    "district_id": "332007",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3320072012",
    "district_id": "332007",
    "label": "Srobyong",
    "value": "Srobyong"
  },
  {
    "id": "3320072013",
    "district_id": "332007",
    "label": "Sekuro",
    "value": "Sekuro"
  },
  {
    "id": "3320072014",
    "district_id": "332007",
    "label": "Karanggondang",
    "value": "Karanggondang"
  },
  {
    "id": "3320072015",
    "district_id": "332007",
    "label": "Jambu Timur",
    "value": "Jambu Timur"
  },
  {
    "id": "3320082001",
    "district_id": "332008",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3320082002",
    "district_id": "332008",
    "label": "Kepuk",
    "value": "Kepuk"
  },
  {
    "id": "3320082003",
    "district_id": "332008",
    "label": "Papasan",
    "value": "Papasan"
  },
  {
    "id": "3320082004",
    "district_id": "332008",
    "label": "Srikandang",
    "value": "Srikandang"
  },
  {
    "id": "3320082005",
    "district_id": "332008",
    "label": "Tengguli",
    "value": "Tengguli"
  },
  {
    "id": "3320082006",
    "district_id": "332008",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3320082007",
    "district_id": "332008",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3320082008",
    "district_id": "332008",
    "label": "Wedelan",
    "value": "Wedelan"
  },
  {
    "id": "3320082009",
    "district_id": "332008",
    "label": "Kedungleper",
    "value": "Kedungleper"
  },
  {
    "id": "3320082010",
    "district_id": "332008",
    "label": "Jerukwangi",
    "value": "Jerukwangi"
  },
  {
    "id": "3320082011",
    "district_id": "332008",
    "label": "Bondo",
    "value": "Bondo"
  },
  {
    "id": "3320082012",
    "district_id": "332008",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3320092001",
    "district_id": "332009",
    "label": "Tempur",
    "value": "Tempur"
  },
  {
    "id": "3320092002",
    "district_id": "332009",
    "label": "Damarwulan",
    "value": "Damarwulan"
  },
  {
    "id": "3320092003",
    "district_id": "332009",
    "label": "Kunir",
    "value": "Kunir"
  },
  {
    "id": "3320092004",
    "district_id": "332009",
    "label": "Watuaji",
    "value": "Watuaji"
  },
  {
    "id": "3320092005",
    "district_id": "332009",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3320092006",
    "district_id": "332009",
    "label": "Tunahan",
    "value": "Tunahan"
  },
  {
    "id": "3320092007",
    "district_id": "332009",
    "label": "Kaligarang",
    "value": "Kaligarang"
  },
  {
    "id": "3320092008",
    "district_id": "332009",
    "label": "Keling",
    "value": "Keling"
  },
  {
    "id": "3320092009",
    "district_id": "332009",
    "label": "Gelang",
    "value": "Gelang"
  },
  {
    "id": "3320092010",
    "district_id": "332009",
    "label": "Jlegong",
    "value": "Jlegong"
  },
  {
    "id": "3320092011",
    "district_id": "332009",
    "label": "Kelet",
    "value": "Kelet"
  },
  {
    "id": "3320092020",
    "district_id": "332009",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3320102001",
    "district_id": "332010",
    "label": "Karimunjawa",
    "value": "Karimunjawa"
  },
  {
    "id": "3320102002",
    "district_id": "332010",
    "label": "Kemujan",
    "value": "Kemujan"
  },
  {
    "id": "3320102003",
    "district_id": "332010",
    "label": "Parang",
    "value": "Parang"
  },
  {
    "id": "3320102004",
    "district_id": "332010",
    "label": "Nyamuk",
    "value": "Nyamuk"
  },
  {
    "id": "3320112001",
    "district_id": "332011",
    "label": "Ngabul",
    "value": "Ngabul"
  },
  {
    "id": "3320112002",
    "district_id": "332011",
    "label": "Langon",
    "value": "Langon"
  },
  {
    "id": "3320112003",
    "district_id": "332011",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3320112004",
    "district_id": "332011",
    "label": "Petekeyan",
    "value": "Petekeyan"
  },
  {
    "id": "3320112005",
    "district_id": "332011",
    "label": "Mangunan",
    "value": "Mangunan"
  },
  {
    "id": "3320112006",
    "district_id": "332011",
    "label": "Platar",
    "value": "Platar"
  },
  {
    "id": "3320112007",
    "district_id": "332011",
    "label": "Semat",
    "value": "Semat"
  },
  {
    "id": "3320112008",
    "district_id": "332011",
    "label": "Telukawur",
    "value": "Telukawur"
  },
  {
    "id": "3320112009",
    "district_id": "332011",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3320112010",
    "district_id": "332011",
    "label": "Tegalsambi",
    "value": "Tegalsambi"
  },
  {
    "id": "3320112011",
    "district_id": "332011",
    "label": "Mantingan",
    "value": "Mantingan"
  },
  {
    "id": "3320112012",
    "district_id": "332011",
    "label": "Tahunan",
    "value": "Tahunan"
  },
  {
    "id": "3320112013",
    "district_id": "332011",
    "label": "Kecapi",
    "value": "Kecapi"
  },
  {
    "id": "3320112014",
    "district_id": "332011",
    "label": "Senenan",
    "value": "Senenan"
  },
  {
    "id": "3320112015",
    "district_id": "332011",
    "label": "Krapyak",
    "value": "Krapyak"
  },
  {
    "id": "3320122001",
    "district_id": "332012",
    "label": "Blimbingrejo",
    "value": "Blimbingrejo"
  },
  {
    "id": "3320122002",
    "district_id": "332012",
    "label": "Tunggulpandean",
    "value": "Tunggulpandean"
  },
  {
    "id": "3320122003",
    "district_id": "332012",
    "label": "Pringtulis",
    "value": "Pringtulis"
  },
  {
    "id": "3320122004",
    "district_id": "332012",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3320122005",
    "district_id": "332012",
    "label": "Gemiring Kidul",
    "value": "Gemiring Kidul"
  },
  {
    "id": "3320122006",
    "district_id": "332012",
    "label": "Gemiring Lor",
    "value": "Gemiring Lor"
  },
  {
    "id": "3320122007",
    "district_id": "332012",
    "label": "Nalumsari",
    "value": "Nalumsari"
  },
  {
    "id": "3320122008",
    "district_id": "332012",
    "label": "Tritis",
    "value": "Tritis"
  },
  {
    "id": "3320122009",
    "district_id": "332012",
    "label": "Daren",
    "value": "Daren"
  },
  {
    "id": "3320122010",
    "district_id": "332012",
    "label": "Karangnongko",
    "value": "Karangnongko"
  },
  {
    "id": "3320122011",
    "district_id": "332012",
    "label": "Ngetuk",
    "value": "Ngetuk"
  },
  {
    "id": "3320122012",
    "district_id": "332012",
    "label": "Bedanpete",
    "value": "Bedanpete"
  },
  {
    "id": "3320122013",
    "district_id": "332012",
    "label": "Muryolobo",
    "value": "Muryolobo"
  },
  {
    "id": "3320122014",
    "district_id": "332012",
    "label": "Bategede",
    "value": "Bategede"
  },
  {
    "id": "3320122015",
    "district_id": "332012",
    "label": "Dorang",
    "value": "Dorang"
  },
  {
    "id": "3320132001",
    "district_id": "332013",
    "label": "Batukali",
    "value": "Batukali"
  },
  {
    "id": "3320132002",
    "district_id": "332013",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3320132003",
    "district_id": "332013",
    "label": "Banyuputih",
    "value": "Banyuputih"
  },
  {
    "id": "3320132004",
    "district_id": "332013",
    "label": "Pendosawalan",
    "value": "Pendosawalan"
  },
  {
    "id": "3320132005",
    "district_id": "332013",
    "label": "Damarjati",
    "value": "Damarjati"
  },
  {
    "id": "3320132006",
    "district_id": "332013",
    "label": "Purwogondo",
    "value": "Purwogondo"
  },
  {
    "id": "3320132007",
    "district_id": "332013",
    "label": "Margoyoso",
    "value": "Margoyoso"
  },
  {
    "id": "3320132008",
    "district_id": "332013",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3320132009",
    "district_id": "332013",
    "label": "Kriyan",
    "value": "Kriyan"
  },
  {
    "id": "3320132010",
    "district_id": "332013",
    "label": "Robayan",
    "value": "Robayan"
  },
  {
    "id": "3320132011",
    "district_id": "332013",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3320132012",
    "district_id": "332013",
    "label": "Manyargading",
    "value": "Manyargading"
  },
  {
    "id": "3320142001",
    "district_id": "332014",
    "label": "Dudakawu",
    "value": "Dudakawu"
  },
  {
    "id": "3320142002",
    "district_id": "332014",
    "label": "Sumanding",
    "value": "Sumanding"
  },
  {
    "id": "3320142003",
    "district_id": "332014",
    "label": "Bucu",
    "value": "Bucu"
  },
  {
    "id": "3320142004",
    "district_id": "332014",
    "label": "Cepogo",
    "value": "Cepogo"
  },
  {
    "id": "3320142005",
    "district_id": "332014",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "3320142006",
    "district_id": "332014",
    "label": "Jinggotan",
    "value": "Jinggotan"
  },
  {
    "id": "3320142007",
    "district_id": "332014",
    "label": "Dermolo",
    "value": "Dermolo"
  },
  {
    "id": "3320142008",
    "district_id": "332014",
    "label": "Kaliaman",
    "value": "Kaliaman"
  },
  {
    "id": "3320142009",
    "district_id": "332014",
    "label": "Tubanan",
    "value": "Tubanan"
  },
  {
    "id": "3320142010",
    "district_id": "332014",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3320142011",
    "district_id": "332014",
    "label": "Kancilan",
    "value": "Kancilan"
  },
  {
    "id": "3320152001",
    "district_id": "332015",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3320152002",
    "district_id": "332015",
    "label": "Bulungan",
    "value": "Bulungan"
  },
  {
    "id": "3320152003",
    "district_id": "332015",
    "label": "Suwawal Timur",
    "value": "Suwawal Timur"
  },
  {
    "id": "3320152004",
    "district_id": "332015",
    "label": "Kawak",
    "value": "Kawak"
  },
  {
    "id": "3320152005",
    "district_id": "332015",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3320152006",
    "district_id": "332015",
    "label": "Plajan",
    "value": "Plajan"
  },
  {
    "id": "3320152007",
    "district_id": "332015",
    "label": "Slagi",
    "value": "Slagi"
  },
  {
    "id": "3320152008",
    "district_id": "332015",
    "label": "Mambak",
    "value": "Mambak"
  },
  {
    "id": "3320162001",
    "district_id": "332016",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3320162002",
    "district_id": "332016",
    "label": "Clering",
    "value": "Clering"
  },
  {
    "id": "3320162003",
    "district_id": "332016",
    "label": "Ujungwatu",
    "value": "Ujungwatu"
  },
  {
    "id": "3320162004",
    "district_id": "332016",
    "label": "Banyumanis",
    "value": "Banyumanis"
  },
  {
    "id": "3320162005",
    "district_id": "332016",
    "label": "Tulakan",
    "value": "Tulakan"
  },
  {
    "id": "3320162006",
    "district_id": "332016",
    "label": "Bandungharjo",
    "value": "Bandungharjo"
  },
  {
    "id": "3320162007",
    "district_id": "332016",
    "label": "Blingoh",
    "value": "Blingoh"
  },
  {
    "id": "3320162008",
    "district_id": "332016",
    "label": "Jugo",
    "value": "Jugo"
  },
  {
    "id": "3321012001",
    "district_id": "332101",
    "label": "Banyumeneng",
    "value": "Banyumeneng"
  },
  {
    "id": "3321012002",
    "district_id": "332101",
    "label": "Kebonbatur",
    "value": "Kebonbatur"
  },
  {
    "id": "3321012003",
    "district_id": "332101",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3321012004",
    "district_id": "332101",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3321012005",
    "district_id": "332101",
    "label": "Kangkung",
    "value": "Kangkung"
  },
  {
    "id": "3321012006",
    "district_id": "332101",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "3321012007",
    "district_id": "332101",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3321012008",
    "district_id": "332101",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3321012009",
    "district_id": "332101",
    "label": "Brumbung",
    "value": "Brumbung"
  },
  {
    "id": "3321012010",
    "district_id": "332101",
    "label": "Kembangarum",
    "value": "Kembangarum"
  },
  {
    "id": "3321012011",
    "district_id": "332101",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3321012012",
    "district_id": "332101",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3321012013",
    "district_id": "332101",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3321012014",
    "district_id": "332101",
    "label": "Menur",
    "value": "Menur"
  },
  {
    "id": "3321012015",
    "district_id": "332101",
    "label": "Jamus",
    "value": "Jamus"
  },
  {
    "id": "3321012016",
    "district_id": "332101",
    "label": "Wringinjajar",
    "value": "Wringinjajar"
  },
  {
    "id": "3321012017",
    "district_id": "332101",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3321012018",
    "district_id": "332101",
    "label": "Tegalarum",
    "value": "Tegalarum"
  },
  {
    "id": "3321012019",
    "district_id": "332101",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3321022001",
    "district_id": "332102",
    "label": "Jragung",
    "value": "Jragung"
  },
  {
    "id": "3321022002",
    "district_id": "332102",
    "label": "Wonosekar",
    "value": "Wonosekar"
  },
  {
    "id": "3321022003",
    "district_id": "332102",
    "label": "Margohayu",
    "value": "Margohayu"
  },
  {
    "id": "3321022004",
    "district_id": "332102",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "3321022005",
    "district_id": "332102",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3321022006",
    "district_id": "332102",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3321022007",
    "district_id": "332102",
    "label": "Karangawen",
    "value": "Karangawen"
  },
  {
    "id": "3321022008",
    "district_id": "332102",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3321022009",
    "district_id": "332102",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3321022010",
    "district_id": "332102",
    "label": "Brambang",
    "value": "Brambang"
  },
  {
    "id": "3321022011",
    "district_id": "332102",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3321022012",
    "district_id": "332102",
    "label": "Pundenarum",
    "value": "Pundenarum"
  },
  {
    "id": "3321032001",
    "district_id": "332103",
    "label": "Blerong",
    "value": "Blerong"
  },
  {
    "id": "3321032002",
    "district_id": "332103",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3321032003",
    "district_id": "332103",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3321032004",
    "district_id": "332103",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3321032005",
    "district_id": "332103",
    "label": "Pamongan",
    "value": "Pamongan"
  },
  {
    "id": "3321032006",
    "district_id": "332103",
    "label": "Tlogoweru",
    "value": "Tlogoweru"
  },
  {
    "id": "3321032007",
    "district_id": "332103",
    "label": "Bogosari",
    "value": "Bogosari"
  },
  {
    "id": "3321032008",
    "district_id": "332103",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3321032009",
    "district_id": "332103",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3321032010",
    "district_id": "332103",
    "label": "Gaji",
    "value": "Gaji"
  },
  {
    "id": "3321032011",
    "district_id": "332103",
    "label": "Krandon",
    "value": "Krandon"
  },
  {
    "id": "3321032012",
    "district_id": "332103",
    "label": "Tangkis",
    "value": "Tangkis"
  },
  {
    "id": "3321032013",
    "district_id": "332103",
    "label": "Temuroso",
    "value": "Temuroso"
  },
  {
    "id": "3321032014",
    "district_id": "332103",
    "label": "Bakalrejo",
    "value": "Bakalrejo"
  },
  {
    "id": "3321032015",
    "district_id": "332103",
    "label": "Guntur",
    "value": "Guntur"
  },
  {
    "id": "3321032016",
    "district_id": "332103",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3321032017",
    "district_id": "332103",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3321032018",
    "district_id": "332103",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3321032019",
    "district_id": "332103",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3321032020",
    "district_id": "332103",
    "label": "Turitempel",
    "value": "Turitempel"
  },
  {
    "id": "3321042001",
    "district_id": "332104",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3321042002",
    "district_id": "332104",
    "label": "Dombo",
    "value": "Dombo"
  },
  {
    "id": "3321042003",
    "district_id": "332104",
    "label": "Jetaksari",
    "value": "Jetaksari"
  },
  {
    "id": "3321042004",
    "district_id": "332104",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3321042005",
    "district_id": "332104",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3321042006",
    "district_id": "332104",
    "label": "Prampelan",
    "value": "Prampelan"
  },
  {
    "id": "3321042007",
    "district_id": "332104",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3321042008",
    "district_id": "332104",
    "label": "Tambakroto",
    "value": "Tambakroto"
  },
  {
    "id": "3321042009",
    "district_id": "332104",
    "label": "Loireng",
    "value": "Loireng"
  },
  {
    "id": "3321042010",
    "district_id": "332104",
    "label": "Sayung",
    "value": "Sayung"
  },
  {
    "id": "3321042011",
    "district_id": "332104",
    "label": "Sriwulan",
    "value": "Sriwulan"
  },
  {
    "id": "3321042012",
    "district_id": "332104",
    "label": "Bedono",
    "value": "Bedono"
  },
  {
    "id": "3321042013",
    "district_id": "332104",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3321042014",
    "district_id": "332104",
    "label": "Sidogemah",
    "value": "Sidogemah"
  },
  {
    "id": "3321042015",
    "district_id": "332104",
    "label": "Gemulak",
    "value": "Gemulak"
  },
  {
    "id": "3321042016",
    "district_id": "332104",
    "label": "Timbulsloko",
    "value": "Timbulsloko"
  },
  {
    "id": "3321042017",
    "district_id": "332104",
    "label": "Surodadi",
    "value": "Surodadi"
  },
  {
    "id": "3321042018",
    "district_id": "332104",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3321042019",
    "district_id": "332104",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3321042020",
    "district_id": "332104",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3321052001",
    "district_id": "332105",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3321052002",
    "district_id": "332105",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3321052003",
    "district_id": "332105",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3321052004",
    "district_id": "332105",
    "label": "Donorejo",
    "value": "Donorejo"
  },
  {
    "id": "3321052005",
    "district_id": "332105",
    "label": "Pidodo",
    "value": "Pidodo"
  },
  {
    "id": "3321052006",
    "district_id": "332105",
    "label": "Klitih",
    "value": "Klitih"
  },
  {
    "id": "3321052007",
    "district_id": "332105",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "3321052008",
    "district_id": "332105",
    "label": "Kedunguter",
    "value": "Kedunguter"
  },
  {
    "id": "3321052009",
    "district_id": "332105",
    "label": "Dukun",
    "value": "Dukun"
  },
  {
    "id": "3321052010",
    "district_id": "332105",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3321052011",
    "district_id": "332105",
    "label": "Karangtowo",
    "value": "Karangtowo"
  },
  {
    "id": "3321052012",
    "district_id": "332105",
    "label": "Wonowoso",
    "value": "Wonowoso"
  },
  {
    "id": "3321052013",
    "district_id": "332105",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3321052014",
    "district_id": "332105",
    "label": "Batu",
    "value": "Batu"
  },
  {
    "id": "3321052015",
    "district_id": "332105",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3321052016",
    "district_id": "332105",
    "label": "Wonoagung",
    "value": "Wonoagung"
  },
  {
    "id": "3321052017",
    "district_id": "332105",
    "label": "Tambakbulusan",
    "value": "Tambakbulusan"
  },
  {
    "id": "3321062001",
    "district_id": "332106",
    "label": "Doreng",
    "value": "Doreng"
  },
  {
    "id": "3321062002",
    "district_id": "332106",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3321062003",
    "district_id": "332106",
    "label": "Tlogodowo",
    "value": "Tlogodowo"
  },
  {
    "id": "3321062004",
    "district_id": "332106",
    "label": "Karangrowo",
    "value": "Karangrowo"
  },
  {
    "id": "3321062005",
    "district_id": "332106",
    "label": "Lempuyang",
    "value": "Lempuyang"
  },
  {
    "id": "3321062006",
    "district_id": "332106",
    "label": "Kendaldoyong",
    "value": "Kendaldoyong"
  },
  {
    "id": "3321062007",
    "district_id": "332106",
    "label": "Wonosalam",
    "value": "Wonosalam"
  },
  {
    "id": "3321062008",
    "district_id": "332106",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3321062009",
    "district_id": "332106",
    "label": "Jogoloyo",
    "value": "Jogoloyo"
  },
  {
    "id": "3321062010",
    "district_id": "332106",
    "label": "Botorejo",
    "value": "Botorejo"
  },
  {
    "id": "3321062011",
    "district_id": "332106",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3321062012",
    "district_id": "332106",
    "label": "Pilangrejo",
    "value": "Pilangrejo"
  },
  {
    "id": "3321062013",
    "district_id": "332106",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3321062014",
    "district_id": "332106",
    "label": "Kerangkulon",
    "value": "Kerangkulon"
  },
  {
    "id": "3321062015",
    "district_id": "332106",
    "label": "Bunderan",
    "value": "Bunderan"
  },
  {
    "id": "3321062016",
    "district_id": "332106",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3321062017",
    "district_id": "332106",
    "label": "Mojodemak",
    "value": "Mojodemak"
  },
  {
    "id": "3321062018",
    "district_id": "332106",
    "label": "Kuncir",
    "value": "Kuncir"
  },
  {
    "id": "3321062019",
    "district_id": "332106",
    "label": "Trengguli",
    "value": "Trengguli"
  },
  {
    "id": "3321062020",
    "district_id": "332106",
    "label": "Mrisen",
    "value": "Mrisen"
  },
  {
    "id": "3321062021",
    "district_id": "332106",
    "label": "Mranak",
    "value": "Mranak"
  },
  {
    "id": "3321072001",
    "district_id": "332107",
    "label": "Merak",
    "value": "Merak"
  },
  {
    "id": "3321072002",
    "district_id": "332107",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3321072003",
    "district_id": "332107",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3321072004",
    "district_id": "332107",
    "label": "Botosengon",
    "value": "Botosengon"
  },
  {
    "id": "3321072005",
    "district_id": "332107",
    "label": "Jerukgulung",
    "value": "Jerukgulung"
  },
  {
    "id": "3321072006",
    "district_id": "332107",
    "label": "Kunir",
    "value": "Kunir"
  },
  {
    "id": "3321072007",
    "district_id": "332107",
    "label": "Brakas",
    "value": "Brakas"
  },
  {
    "id": "3321072008",
    "district_id": "332107",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3321072009",
    "district_id": "332107",
    "label": "Baleromo",
    "value": "Baleromo"
  },
  {
    "id": "3321072010",
    "district_id": "332107",
    "label": "Kedungori",
    "value": "Kedungori"
  },
  {
    "id": "3321072011",
    "district_id": "332107",
    "label": "Kuwu",
    "value": "Kuwu"
  },
  {
    "id": "3321072012",
    "district_id": "332107",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3321072013",
    "district_id": "332107",
    "label": "Gempoldenok",
    "value": "Gempoldenok"
  },
  {
    "id": "3321072015",
    "district_id": "332107",
    "label": "Harjowinangun",
    "value": "Harjowinangun"
  },
  {
    "id": "3321072016",
    "district_id": "332107",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3321072017",
    "district_id": "332107",
    "label": "Dempet",
    "value": "Dempet"
  },
  {
    "id": "3321082001",
    "district_id": "332108",
    "label": "Surodadi",
    "value": "Surodadi"
  },
  {
    "id": "3321082002",
    "district_id": "332108",
    "label": "Jatisono",
    "value": "Jatisono"
  },
  {
    "id": "3321082003",
    "district_id": "332108",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "3321082004",
    "district_id": "332108",
    "label": "Gedangalas",
    "value": "Gedangalas"
  },
  {
    "id": "3321082005",
    "district_id": "332108",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3321082006",
    "district_id": "332108",
    "label": "Tanjunganyar",
    "value": "Tanjunganyar"
  },
  {
    "id": "3321082007",
    "district_id": "332108",
    "label": "Wilalung",
    "value": "Wilalung"
  },
  {
    "id": "3321082008",
    "district_id": "332108",
    "label": "Medini",
    "value": "Medini"
  },
  {
    "id": "3321082009",
    "district_id": "332108",
    "label": "Mlatiharjo",
    "value": "Mlatiharjo"
  },
  {
    "id": "3321082010",
    "district_id": "332108",
    "label": "Tambirejo",
    "value": "Tambirejo"
  },
  {
    "id": "3321082011",
    "district_id": "332108",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3321082012",
    "district_id": "332108",
    "label": "Boyolali",
    "value": "Boyolali"
  },
  {
    "id": "3321082013",
    "district_id": "332108",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "3321082014",
    "district_id": "332108",
    "label": "Sari",
    "value": "Sari"
  },
  {
    "id": "3321082015",
    "district_id": "332108",
    "label": "Mlekang",
    "value": "Mlekang"
  },
  {
    "id": "3321082016",
    "district_id": "332108",
    "label": "Sambung",
    "value": "Sambung"
  },
  {
    "id": "3321082017",
    "district_id": "332108",
    "label": "Mojosimo",
    "value": "Mojosimo"
  },
  {
    "id": "3321082018",
    "district_id": "332108",
    "label": "Tlogopandogan",
    "value": "Tlogopandogan"
  },
  {
    "id": "3321092001",
    "district_id": "332109",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3321092002",
    "district_id": "332109",
    "label": "Ngaluran",
    "value": "Ngaluran"
  },
  {
    "id": "3321092003",
    "district_id": "332109",
    "label": "Wonoketingal",
    "value": "Wonoketingal"
  },
  {
    "id": "3321092004",
    "district_id": "332109",
    "label": "Cangkringrembang",
    "value": "Cangkringrembang"
  },
  {
    "id": "3321092005",
    "district_id": "332109",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3321092006",
    "district_id": "332109",
    "label": "Tuwang",
    "value": "Tuwang"
  },
  {
    "id": "3321092007",
    "district_id": "332109",
    "label": "Undaan Kidul",
    "value": "Undaan Kidul"
  },
  {
    "id": "3321092008",
    "district_id": "332109",
    "label": "Undaan Lor",
    "value": "Undaan Lor"
  },
  {
    "id": "3321092009",
    "district_id": "332109",
    "label": "Ketanjung",
    "value": "Ketanjung"
  },
  {
    "id": "3321092010",
    "district_id": "332109",
    "label": "Ngemplikwetan",
    "value": "Ngemplikwetan"
  },
  {
    "id": "3321092011",
    "district_id": "332109",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3321092012",
    "district_id": "332109",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3321092013",
    "district_id": "332109",
    "label": "Kedungwaru Kidul",
    "value": "Kedungwaru Kidul"
  },
  {
    "id": "3321092014",
    "district_id": "332109",
    "label": "Kedungwaru Lor",
    "value": "Kedungwaru Lor"
  },
  {
    "id": "3321092015",
    "district_id": "332109",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3321092016",
    "district_id": "332109",
    "label": "Tugu Lor",
    "value": "Tugu Lor"
  },
  {
    "id": "3321092017",
    "district_id": "332109",
    "label": "Kotaan",
    "value": "Kotaan"
  },
  {
    "id": "3321102001",
    "district_id": "332110",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3321102002",
    "district_id": "332110",
    "label": "Ngelowetan",
    "value": "Ngelowetan"
  },
  {
    "id": "3321102003",
    "district_id": "332110",
    "label": "Mlaten",
    "value": "Mlaten"
  },
  {
    "id": "3321102004",
    "district_id": "332110",
    "label": "Bantengmati",
    "value": "Bantengmati"
  },
  {
    "id": "3321102005",
    "district_id": "332110",
    "label": "Ngelokulon",
    "value": "Ngelokulon"
  },
  {
    "id": "3321102006",
    "district_id": "332110",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "3321102007",
    "district_id": "332110",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3321102008",
    "district_id": "332110",
    "label": "Ngegot",
    "value": "Ngegot"
  },
  {
    "id": "3321102009",
    "district_id": "332110",
    "label": "Jleper",
    "value": "Jleper"
  },
  {
    "id": "3321102010",
    "district_id": "332110",
    "label": "Pecuk",
    "value": "Pecuk"
  },
  {
    "id": "3321102011",
    "district_id": "332110",
    "label": "Tanggul",
    "value": "Tanggul"
  },
  {
    "id": "3321102012",
    "district_id": "332110",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3321102013",
    "district_id": "332110",
    "label": "Bremi",
    "value": "Bremi"
  },
  {
    "id": "3321102014",
    "district_id": "332110",
    "label": "Mijen",
    "value": "Mijen"
  },
  {
    "id": "3321102015",
    "district_id": "332110",
    "label": "Gempolsongo",
    "value": "Gempolsongo"
  },
  {
    "id": "3321111014",
    "district_id": "332111",
    "label": "Mangunjiwan",
    "value": "Mangunjiwan"
  },
  {
    "id": "3321111015",
    "district_id": "332111",
    "label": "Kalicilik",
    "value": "Kalicilik"
  },
  {
    "id": "3321111016",
    "district_id": "332111",
    "label": "Singorejo",
    "value": "Singorejo"
  },
  {
    "id": "3321111017",
    "district_id": "332111",
    "label": "Betokan",
    "value": "Betokan"
  },
  {
    "id": "3321111018",
    "district_id": "332111",
    "label": "Bintoro",
    "value": "Bintoro"
  },
  {
    "id": "3321111019",
    "district_id": "332111",
    "label": "Kadilangu",
    "value": "Kadilangu"
  },
  {
    "id": "3321112001",
    "district_id": "332111",
    "label": "Bolo",
    "value": "Bolo"
  },
  {
    "id": "3321112002",
    "district_id": "332111",
    "label": "Bango",
    "value": "Bango"
  },
  {
    "id": "3321112003",
    "district_id": "332111",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "3321112004",
    "district_id": "332111",
    "label": "Sedo",
    "value": "Sedo"
  },
  {
    "id": "3321112005",
    "district_id": "332111",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3321112006",
    "district_id": "332111",
    "label": "Turirejo",
    "value": "Turirejo"
  },
  {
    "id": "3321112007",
    "district_id": "332111",
    "label": "Raji",
    "value": "Raji"
  },
  {
    "id": "3321112008",
    "district_id": "332111",
    "label": "Cabean",
    "value": "Cabean"
  },
  {
    "id": "3321112009",
    "district_id": "332111",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3321112010",
    "district_id": "332111",
    "label": "Karangmlati",
    "value": "Karangmlati"
  },
  {
    "id": "3321112011",
    "district_id": "332111",
    "label": "Katonsari",
    "value": "Katonsari"
  },
  {
    "id": "3321112012",
    "district_id": "332111",
    "label": "Kalikondang",
    "value": "Kalikondang"
  },
  {
    "id": "3321112013",
    "district_id": "332111",
    "label": "Donorojo",
    "value": "Donorojo"
  },
  {
    "id": "3321122001",
    "district_id": "332112",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3321122002",
    "district_id": "332112",
    "label": "Krajanbogo",
    "value": "Krajanbogo"
  },
  {
    "id": "3321122003",
    "district_id": "332112",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3321122004",
    "district_id": "332112",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3321122005",
    "district_id": "332112",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3321122006",
    "district_id": "332112",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3321122007",
    "district_id": "332112",
    "label": "Gebangarum",
    "value": "Gebangarum"
  },
  {
    "id": "3321122008",
    "district_id": "332112",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3321122009",
    "district_id": "332112",
    "label": "Margolinduk",
    "value": "Margolinduk"
  },
  {
    "id": "3321122010",
    "district_id": "332112",
    "label": "Morodemak",
    "value": "Morodemak"
  },
  {
    "id": "3321122011",
    "district_id": "332112",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3321122012",
    "district_id": "332112",
    "label": "Betahwalang",
    "value": "Betahwalang"
  },
  {
    "id": "3321122013",
    "district_id": "332112",
    "label": "Serangan",
    "value": "Serangan"
  },
  {
    "id": "3321122014",
    "district_id": "332112",
    "label": "Tridonorejo",
    "value": "Tridonorejo"
  },
  {
    "id": "3321122015",
    "district_id": "332112",
    "label": "Tlogoboyo",
    "value": "Tlogoboyo"
  },
  {
    "id": "3321122016",
    "district_id": "332112",
    "label": "Jatirogo",
    "value": "Jatirogo"
  },
  {
    "id": "3321122017",
    "district_id": "332112",
    "label": "Bonangrejo",
    "value": "Bonangrejo"
  },
  {
    "id": "3321122018",
    "district_id": "332112",
    "label": "Poncoharjo",
    "value": "Poncoharjo"
  },
  {
    "id": "3321122019",
    "district_id": "332112",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3321122020",
    "district_id": "332112",
    "label": "Jali",
    "value": "Jali"
  },
  {
    "id": "3321122021",
    "district_id": "332112",
    "label": "Weding",
    "value": "Weding"
  },
  {
    "id": "3321132001",
    "district_id": "332113",
    "label": "Wedung",
    "value": "Wedung"
  },
  {
    "id": "3321132002",
    "district_id": "332113",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3321132003",
    "district_id": "332113",
    "label": "Ruwit",
    "value": "Ruwit"
  },
  {
    "id": "3321132004",
    "district_id": "332113",
    "label": "Kenduren",
    "value": "Kenduren"
  },
  {
    "id": "3321132005",
    "district_id": "332113",
    "label": "Buko",
    "value": "Buko"
  },
  {
    "id": "3321132006",
    "district_id": "332113",
    "label": "Mandung",
    "value": "Mandung"
  },
  {
    "id": "3321132007",
    "district_id": "332113",
    "label": "Berahan Kulon",
    "value": "Berahan Kulon"
  },
  {
    "id": "3321132008",
    "district_id": "332113",
    "label": "Berahan Wetan",
    "value": "Berahan Wetan"
  },
  {
    "id": "3321132009",
    "district_id": "332113",
    "label": "Bungo",
    "value": "Bungo"
  },
  {
    "id": "3321132010",
    "district_id": "332113",
    "label": "Tempel",
    "value": "Tempel"
  },
  {
    "id": "3321132011",
    "district_id": "332113",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3321132012",
    "district_id": "332113",
    "label": "Jungsemi",
    "value": "Jungsemi"
  },
  {
    "id": "3321132013",
    "district_id": "332113",
    "label": "Jungpasir",
    "value": "Jungpasir"
  },
  {
    "id": "3321132014",
    "district_id": "332113",
    "label": "Mutihwetan",
    "value": "Mutihwetan"
  },
  {
    "id": "3321132015",
    "district_id": "332113",
    "label": "Babalan",
    "value": "Babalan"
  },
  {
    "id": "3321132016",
    "district_id": "332113",
    "label": "Mutihkulon",
    "value": "Mutihkulon"
  },
  {
    "id": "3321132017",
    "district_id": "332113",
    "label": "Tedunan",
    "value": "Tedunan"
  },
  {
    "id": "3321132018",
    "district_id": "332113",
    "label": "Kendalasem",
    "value": "Kendalasem"
  },
  {
    "id": "3321132019",
    "district_id": "332113",
    "label": "Kedungmutih",
    "value": "Kedungmutih"
  },
  {
    "id": "3321132020",
    "district_id": "332113",
    "label": "Kedungkarang",
    "value": "Kedungkarang"
  },
  {
    "id": "3321142001",
    "district_id": "332114",
    "label": "Pilangwetan",
    "value": "Pilangwetan"
  },
  {
    "id": "3321142002",
    "district_id": "332114",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3321142003",
    "district_id": "332114",
    "label": "Klampok Lor",
    "value": "Klampok Lor"
  },
  {
    "id": "3321142004",
    "district_id": "332114",
    "label": "Mijen",
    "value": "Mijen"
  },
  {
    "id": "3321142005",
    "district_id": "332114",
    "label": "Werdoyo",
    "value": "Werdoyo"
  },
  {
    "id": "3321142006",
    "district_id": "332114",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3321142007",
    "district_id": "332114",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "3321142008",
    "district_id": "332114",
    "label": "Mangunanlor",
    "value": "Mangunanlor"
  },
  {
    "id": "3321142009",
    "district_id": "332114",
    "label": "Tlogosih",
    "value": "Tlogosih"
  },
  {
    "id": "3321142010",
    "district_id": "332114",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3321142011",
    "district_id": "332114",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "3321142012",
    "district_id": "332114",
    "label": "Solowire",
    "value": "Solowire"
  },
  {
    "id": "3321142013",
    "district_id": "332114",
    "label": "Sokokidul",
    "value": "Sokokidul"
  },
  {
    "id": "3321142014",
    "district_id": "332114",
    "label": "Megonten",
    "value": "Megonten"
  },
  {
    "id": "3322012001",
    "district_id": "332201",
    "label": "Tajuk",
    "value": "Tajuk"
  },
  {
    "id": "3322012002",
    "district_id": "332201",
    "label": "Batur",
    "value": "Batur"
  },
  {
    "id": "3322012003",
    "district_id": "332201",
    "label": "Kopeng",
    "value": "Kopeng"
  },
  {
    "id": "3322012004",
    "district_id": "332201",
    "label": "Tolokan",
    "value": "Tolokan"
  },
  {
    "id": "3322012005",
    "district_id": "332201",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3322012006",
    "district_id": "332201",
    "label": "Getasan",
    "value": "Getasan"
  },
  {
    "id": "3322012007",
    "district_id": "332201",
    "label": "Sumogawe",
    "value": "Sumogawe"
  },
  {
    "id": "3322012008",
    "district_id": "332201",
    "label": "Samirono",
    "value": "Samirono"
  },
  {
    "id": "3322012009",
    "district_id": "332201",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3322012010",
    "district_id": "332201",
    "label": "Polobogo",
    "value": "Polobogo"
  },
  {
    "id": "3322012011",
    "district_id": "332201",
    "label": "Manggihan",
    "value": "Manggihan"
  },
  {
    "id": "3322012012",
    "district_id": "332201",
    "label": "Ngrawan",
    "value": "Ngrawan"
  },
  {
    "id": "3322012013",
    "district_id": "332201",
    "label": "Nogosaren",
    "value": "Nogosaren"
  },
  {
    "id": "3322022001",
    "district_id": "332202",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3322022002",
    "district_id": "332202",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3322022003",
    "district_id": "332202",
    "label": "Sruwen",
    "value": "Sruwen"
  },
  {
    "id": "3322022004",
    "district_id": "332202",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3322022005",
    "district_id": "332202",
    "label": "Tengaran",
    "value": "Tengaran"
  },
  {
    "id": "3322022006",
    "district_id": "332202",
    "label": "Klero",
    "value": "Klero"
  },
  {
    "id": "3322022007",
    "district_id": "332202",
    "label": "Regunung",
    "value": "Regunung"
  },
  {
    "id": "3322022008",
    "district_id": "332202",
    "label": "Cukil",
    "value": "Cukil"
  },
  {
    "id": "3322022009",
    "district_id": "332202",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3322022010",
    "district_id": "332202",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3322022011",
    "district_id": "332202",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3322022012",
    "district_id": "332202",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3322022013",
    "district_id": "332202",
    "label": "Tegalwaton",
    "value": "Tegalwaton"
  },
  {
    "id": "3322022014",
    "district_id": "332202",
    "label": "Barukan",
    "value": "Barukan"
  },
  {
    "id": "3322022015",
    "district_id": "332202",
    "label": "Nyamat",
    "value": "Nyamat"
  },
  {
    "id": "3322032001",
    "district_id": "332203",
    "label": "Badran",
    "value": "Badran"
  },
  {
    "id": "3322032002",
    "district_id": "332203",
    "label": "Timpik",
    "value": "Timpik"
  },
  {
    "id": "3322032003",
    "district_id": "332203",
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "id": "3322032004",
    "district_id": "332203",
    "label": "Bakalrejo",
    "value": "Bakalrejo"
  },
  {
    "id": "3322032005",
    "district_id": "332203",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3322032006",
    "district_id": "332203",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3322032007",
    "district_id": "332203",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3322032008",
    "district_id": "332203",
    "label": "Gentan",
    "value": "Gentan"
  },
  {
    "id": "3322032009",
    "district_id": "332203",
    "label": "Muncar",
    "value": "Muncar"
  },
  {
    "id": "3322032010",
    "district_id": "332203",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3322032011",
    "district_id": "332203",
    "label": "Koripan",
    "value": "Koripan"
  },
  {
    "id": "3322032012",
    "district_id": "332203",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3322032013",
    "district_id": "332203",
    "label": "Kemetul",
    "value": "Kemetul"
  },
  {
    "id": "3322042001",
    "district_id": "332204",
    "label": "Kebowan",
    "value": "Kebowan"
  },
  {
    "id": "3322042002",
    "district_id": "332204",
    "label": "Beji Lor",
    "value": "Beji Lor"
  },
  {
    "id": "3322042003",
    "district_id": "332204",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3322042004",
    "district_id": "332204",
    "label": "Dersansari",
    "value": "Dersansari"
  },
  {
    "id": "3322042005",
    "district_id": "332204",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3322042006",
    "district_id": "332204",
    "label": "Ketanggi",
    "value": "Ketanggi"
  },
  {
    "id": "3322042007",
    "district_id": "332204",
    "label": "Medayu",
    "value": "Medayu"
  },
  {
    "id": "3322042008",
    "district_id": "332204",
    "label": "Bonomerto",
    "value": "Bonomerto"
  },
  {
    "id": "3322042009",
    "district_id": "332204",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3322042010",
    "district_id": "332204",
    "label": "Kedungringin",
    "value": "Kedungringin"
  },
  {
    "id": "3322042011",
    "district_id": "332204",
    "label": "Gunung Tumpeng",
    "value": "Gunung Tumpeng"
  },
  {
    "id": "3322042012",
    "district_id": "332204",
    "label": "Reksosari",
    "value": "Reksosari"
  },
  {
    "id": "3322042013",
    "district_id": "332204",
    "label": "Suruh",
    "value": "Suruh"
  },
  {
    "id": "3322042014",
    "district_id": "332204",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3322042015",
    "district_id": "332204",
    "label": "Krandon Lor",
    "value": "Krandon Lor"
  },
  {
    "id": "3322042016",
    "district_id": "332204",
    "label": "Cukilan",
    "value": "Cukilan"
  },
  {
    "id": "3322042017",
    "district_id": "332204",
    "label": "Dadap Ayam",
    "value": "Dadap Ayam"
  },
  {
    "id": "3322052001",
    "district_id": "332205",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3322052002",
    "district_id": "332205",
    "label": "Ujung-ujung",
    "value": "Ujung-ujung"
  },
  {
    "id": "3322052003",
    "district_id": "332205",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3322052004",
    "district_id": "332205",
    "label": "Karang Gondang",
    "value": "Karang Gondang"
  },
  {
    "id": "3322052005",
    "district_id": "332205",
    "label": "Segiri",
    "value": "Segiri"
  },
  {
    "id": "3322052006",
    "district_id": "332205",
    "label": "Terban",
    "value": "Terban"
  },
  {
    "id": "3322052007",
    "district_id": "332205",
    "label": "Tukang",
    "value": "Tukang"
  },
  {
    "id": "3322052008",
    "district_id": "332205",
    "label": "Semowo",
    "value": "Semowo"
  },
  {
    "id": "3322052009",
    "district_id": "332205",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3322052010",
    "district_id": "332205",
    "label": "Jembrak",
    "value": "Jembrak"
  },
  {
    "id": "3322052011",
    "district_id": "332205",
    "label": "Glawan",
    "value": "Glawan"
  },
  {
    "id": "3322052012",
    "district_id": "332205",
    "label": "Pabelan",
    "value": "Pabelan"
  },
  {
    "id": "3322052013",
    "district_id": "332205",
    "label": "Kauman Lor",
    "value": "Kauman Lor"
  },
  {
    "id": "3322052014",
    "district_id": "332205",
    "label": "Bejaten",
    "value": "Bejaten"
  },
  {
    "id": "3322052015",
    "district_id": "332205",
    "label": "Kadirejo",
    "value": "Kadirejo"
  },
  {
    "id": "3322052016",
    "district_id": "332205",
    "label": "Giling",
    "value": "Giling"
  },
  {
    "id": "3322052017",
    "district_id": "332205",
    "label": "Padaan",
    "value": "Padaan"
  },
  {
    "id": "3322062001",
    "district_id": "332206",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3322062002",
    "district_id": "332206",
    "label": "Kalibeji",
    "value": "Kalibeji"
  },
  {
    "id": "3322062003",
    "district_id": "332206",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3322062004",
    "district_id": "332206",
    "label": "Sraten",
    "value": "Sraten"
  },
  {
    "id": "3322062005",
    "district_id": "332206",
    "label": "Jombor",
    "value": "Jombor"
  },
  {
    "id": "3322062006",
    "district_id": "332206",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3322062007",
    "district_id": "332206",
    "label": "Kesongo",
    "value": "Kesongo"
  },
  {
    "id": "3322062008",
    "district_id": "332206",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3322062009",
    "district_id": "332206",
    "label": "Lopait",
    "value": "Lopait"
  },
  {
    "id": "3322062010",
    "district_id": "332206",
    "label": "Tuntang",
    "value": "Tuntang"
  },
  {
    "id": "3322062011",
    "district_id": "332206",
    "label": "Delik",
    "value": "Delik"
  },
  {
    "id": "3322062012",
    "district_id": "332206",
    "label": "Tlogo",
    "value": "Tlogo"
  },
  {
    "id": "3322062013",
    "district_id": "332206",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3322062014",
    "district_id": "332206",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3322062015",
    "district_id": "332206",
    "label": "Tlompakan",
    "value": "Tlompakan"
  },
  {
    "id": "3322062016",
    "district_id": "332206",
    "label": "Ngajaran",
    "value": "Ngajaran"
  },
  {
    "id": "3322072001",
    "district_id": "332207",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3322072002",
    "district_id": "332207",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3322072003",
    "district_id": "332207",
    "label": "Sepakung",
    "value": "Sepakung"
  },
  {
    "id": "3322072004",
    "district_id": "332207",
    "label": "Wirogomo",
    "value": "Wirogomo"
  },
  {
    "id": "3322072005",
    "district_id": "332207",
    "label": "Kemambang",
    "value": "Kemambang"
  },
  {
    "id": "3322072006",
    "district_id": "332207",
    "label": "Tegaron",
    "value": "Tegaron"
  },
  {
    "id": "3322072007",
    "district_id": "332207",
    "label": "Rowoboni",
    "value": "Rowoboni"
  },
  {
    "id": "3322072008",
    "district_id": "332207",
    "label": "Kebondowo",
    "value": "Kebondowo"
  },
  {
    "id": "3322072009",
    "district_id": "332207",
    "label": "Banyubiru",
    "value": "Banyubiru"
  },
  {
    "id": "3322072010",
    "district_id": "332207",
    "label": "Ngrapah",
    "value": "Ngrapah"
  },
  {
    "id": "3322081006",
    "district_id": "332208",
    "label": "Gondoriyo",
    "value": "Gondoriyo"
  },
  {
    "id": "3322082001",
    "district_id": "332208",
    "label": "Gemawang",
    "value": "Gemawang"
  },
  {
    "id": "3322082002",
    "district_id": "332208",
    "label": "Bedono",
    "value": "Bedono"
  },
  {
    "id": "3322082003",
    "district_id": "332208",
    "label": "Kelurahan",
    "value": "Kelurahan"
  },
  {
    "id": "3322082004",
    "district_id": "332208",
    "label": "Brongkol",
    "value": "Brongkol"
  },
  {
    "id": "3322082005",
    "district_id": "332208",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3322082007",
    "district_id": "332208",
    "label": "Kuwarasan",
    "value": "Kuwarasan"
  },
  {
    "id": "3322082008",
    "district_id": "332208",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3322082009",
    "district_id": "332208",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3322082010",
    "district_id": "332208",
    "label": "Genting",
    "value": "Genting"
  },
  {
    "id": "3322092001",
    "district_id": "332209",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3322092002",
    "district_id": "332209",
    "label": "Candigaron",
    "value": "Candigaron"
  },
  {
    "id": "3322092003",
    "district_id": "332209",
    "label": "Ngadikerso",
    "value": "Ngadikerso"
  },
  {
    "id": "3322092004",
    "district_id": "332209",
    "label": "Lanjan",
    "value": "Lanjan"
  },
  {
    "id": "3322092005",
    "district_id": "332209",
    "label": "Jubelan",
    "value": "Jubelan"
  },
  {
    "id": "3322092006",
    "district_id": "332209",
    "label": "Sumowono",
    "value": "Sumowono"
  },
  {
    "id": "3322092007",
    "district_id": "332209",
    "label": "Trayu",
    "value": "Trayu"
  },
  {
    "id": "3322092008",
    "district_id": "332209",
    "label": "Kemitir",
    "value": "Kemitir"
  },
  {
    "id": "3322092009",
    "district_id": "332209",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3322092010",
    "district_id": "332209",
    "label": "Pledokan",
    "value": "Pledokan"
  },
  {
    "id": "3322092011",
    "district_id": "332209",
    "label": "Mendongan",
    "value": "Mendongan"
  },
  {
    "id": "3322092012",
    "district_id": "332209",
    "label": "Bumen",
    "value": "Bumen"
  },
  {
    "id": "3322092013",
    "district_id": "332209",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3322092014",
    "district_id": "332209",
    "label": "Kemawi",
    "value": "Kemawi"
  },
  {
    "id": "3322092015",
    "district_id": "332209",
    "label": "Piyanggang",
    "value": "Piyanggang"
  },
  {
    "id": "3322092016",
    "district_id": "332209",
    "label": "Keseneng",
    "value": "Keseneng"
  },
  {
    "id": "3322101001",
    "district_id": "332210",
    "label": "Ngampin",
    "value": "Ngampin"
  },
  {
    "id": "3322101002",
    "district_id": "332210",
    "label": "Pojoksari",
    "value": "Pojoksari"
  },
  {
    "id": "3322101004",
    "district_id": "332210",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "3322101005",
    "district_id": "332210",
    "label": "Lodoyong",
    "value": "Lodoyong"
  },
  {
    "id": "3322101006",
    "district_id": "332210",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "3322101007",
    "district_id": "332210",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3322101008",
    "district_id": "332210",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "3322101010",
    "district_id": "332210",
    "label": "Baran",
    "value": "Baran"
  },
  {
    "id": "3322102003",
    "district_id": "332210",
    "label": "Bejalen",
    "value": "Bejalen"
  },
  {
    "id": "3322102009",
    "district_id": "332210",
    "label": "Pasekan",
    "value": "Pasekan"
  },
  {
    "id": "3322111002",
    "district_id": "332211",
    "label": "Bawen",
    "value": "Bawen"
  },
  {
    "id": "3322111007",
    "district_id": "332211",
    "label": "Harjosari",
    "value": "Harjosari"
  },
  {
    "id": "3322112001",
    "district_id": "332211",
    "label": "Doplang",
    "value": "Doplang"
  },
  {
    "id": "3322112003",
    "district_id": "332211",
    "label": "Asinan",
    "value": "Asinan"
  },
  {
    "id": "3322112004",
    "district_id": "332211",
    "label": "Polosiri",
    "value": "Polosiri"
  },
  {
    "id": "3322112005",
    "district_id": "332211",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3322112006",
    "district_id": "332211",
    "label": "Lemahireng",
    "value": "Lemahireng"
  },
  {
    "id": "3322112008",
    "district_id": "332211",
    "label": "Samban",
    "value": "Samban"
  },
  {
    "id": "3322112009",
    "district_id": "332211",
    "label": "Poncoruso",
    "value": "Poncoruso"
  },
  {
    "id": "3322122001",
    "district_id": "332212",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3322122002",
    "district_id": "332212",
    "label": "Popongan",
    "value": "Popongan"
  },
  {
    "id": "3322122003",
    "district_id": "332212",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3322122004",
    "district_id": "332212",
    "label": "Rembes",
    "value": "Rembes"
  },
  {
    "id": "3322122005",
    "district_id": "332212",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3322122006",
    "district_id": "332212",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3322122007",
    "district_id": "332212",
    "label": "Kalijambe",
    "value": "Kalijambe"
  },
  {
    "id": "3322122008",
    "district_id": "332212",
    "label": "Kalikurmo",
    "value": "Kalikurmo"
  },
  {
    "id": "3322122009",
    "district_id": "332212",
    "label": "Gogodalem",
    "value": "Gogodalem"
  },
  {
    "id": "3322122010",
    "district_id": "332212",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3322122011",
    "district_id": "332212",
    "label": "Wiru",
    "value": "Wiru"
  },
  {
    "id": "3322122012",
    "district_id": "332212",
    "label": "Nyemoh",
    "value": "Nyemoh"
  },
  {
    "id": "3322122013",
    "district_id": "332212",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3322122014",
    "district_id": "332212",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3322122015",
    "district_id": "332212",
    "label": "Truko",
    "value": "Truko"
  },
  {
    "id": "3322122016",
    "district_id": "332212",
    "label": "Banding",
    "value": "Banding"
  },
  {
    "id": "3322131001",
    "district_id": "332213",
    "label": "Ngempon",
    "value": "Ngempon"
  },
  {
    "id": "3322131002",
    "district_id": "332213",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3322131005",
    "district_id": "332213",
    "label": "Wujil",
    "value": "Wujil"
  },
  {
    "id": "3322131009",
    "district_id": "332213",
    "label": "Bergas Lor",
    "value": "Bergas Lor"
  },
  {
    "id": "3322132003",
    "district_id": "332213",
    "label": "Wringinputih",
    "value": "Wringinputih"
  },
  {
    "id": "3322132004",
    "district_id": "332213",
    "label": "Gondoriyo",
    "value": "Gondoriyo"
  },
  {
    "id": "3322132006",
    "district_id": "332213",
    "label": "Gebugan",
    "value": "Gebugan"
  },
  {
    "id": "3322132007",
    "district_id": "332213",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3322132008",
    "district_id": "332213",
    "label": "Munding",
    "value": "Munding"
  },
  {
    "id": "3322132010",
    "district_id": "332213",
    "label": "Bergas Kidul",
    "value": "Bergas Kidul"
  },
  {
    "id": "3322132011",
    "district_id": "332213",
    "label": "Randugunting",
    "value": "Randugunting"
  },
  {
    "id": "3322132012",
    "district_id": "332213",
    "label": "Jatijajar",
    "value": "Jatijajar"
  },
  {
    "id": "3322132013",
    "district_id": "332213",
    "label": "Diwak",
    "value": "Diwak"
  },
  {
    "id": "3322151004",
    "district_id": "332215",
    "label": "Pringapus",
    "value": "Pringapus"
  },
  {
    "id": "3322152001",
    "district_id": "332215",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3322152002",
    "district_id": "332215",
    "label": "Derekan",
    "value": "Derekan"
  },
  {
    "id": "3322152003",
    "district_id": "332215",
    "label": "Jatirunggo",
    "value": "Jatirunggo"
  },
  {
    "id": "3322152005",
    "district_id": "332215",
    "label": "Pringsari",
    "value": "Pringsari"
  },
  {
    "id": "3322152006",
    "district_id": "332215",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3322152007",
    "district_id": "332215",
    "label": "Wonoyoso",
    "value": "Wonoyoso"
  },
  {
    "id": "3322152008",
    "district_id": "332215",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3322152009",
    "district_id": "332215",
    "label": "Penawangan",
    "value": "Penawangan"
  },
  {
    "id": "3322162001",
    "district_id": "332216",
    "label": "Bancak",
    "value": "Bancak"
  },
  {
    "id": "3322162002",
    "district_id": "332216",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3322162003",
    "district_id": "332216",
    "label": "Jlumpang",
    "value": "Jlumpang"
  },
  {
    "id": "3322162004",
    "district_id": "332216",
    "label": "Bantal",
    "value": "Bantal"
  },
  {
    "id": "3322162005",
    "district_id": "332216",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3322162006",
    "district_id": "332216",
    "label": "Plumutan",
    "value": "Plumutan"
  },
  {
    "id": "3322162007",
    "district_id": "332216",
    "label": "Lembu",
    "value": "Lembu"
  },
  {
    "id": "3322162008",
    "district_id": "332216",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3322162009",
    "district_id": "332216",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3322172001",
    "district_id": "332217",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3322172002",
    "district_id": "332217",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3322172003",
    "district_id": "332217",
    "label": "Kener",
    "value": "Kener"
  },
  {
    "id": "3322172004",
    "district_id": "332217",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3322172005",
    "district_id": "332217",
    "label": "Mukiran",
    "value": "Mukiran"
  },
  {
    "id": "3322172006",
    "district_id": "332217",
    "label": "Pager",
    "value": "Pager"
  },
  {
    "id": "3322172007",
    "district_id": "332217",
    "label": "Papringan",
    "value": "Papringan"
  },
  {
    "id": "3322172008",
    "district_id": "332217",
    "label": "Payungan",
    "value": "Payungan"
  },
  {
    "id": "3322172009",
    "district_id": "332217",
    "label": "Rogomulyo",
    "value": "Rogomulyo"
  },
  {
    "id": "3322172010",
    "district_id": "332217",
    "label": "Siwal",
    "value": "Siwal"
  },
  {
    "id": "3322172011",
    "district_id": "332217",
    "label": "Udanwuh",
    "value": "Udanwuh"
  },
  {
    "id": "3322181006",
    "district_id": "332218",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3322181007",
    "district_id": "332218",
    "label": "Genuk",
    "value": "Genuk"
  },
  {
    "id": "3322181008",
    "district_id": "332218",
    "label": "Ungaran",
    "value": "Ungaran"
  },
  {
    "id": "3322181009",
    "district_id": "332218",
    "label": "Bandarjo",
    "value": "Bandarjo"
  },
  {
    "id": "3322181010",
    "district_id": "332218",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3322182001",
    "district_id": "332218",
    "label": "Branjang",
    "value": "Branjang"
  },
  {
    "id": "3322182002",
    "district_id": "332218",
    "label": "Kalisidi",
    "value": "Kalisidi"
  },
  {
    "id": "3322182003",
    "district_id": "332218",
    "label": "Keji",
    "value": "Keji"
  },
  {
    "id": "3322182004",
    "district_id": "332218",
    "label": "Lerep",
    "value": "Lerep"
  },
  {
    "id": "3322182005",
    "district_id": "332218",
    "label": "Nyatnyono",
    "value": "Nyatnyono"
  },
  {
    "id": "3322182011",
    "district_id": "332218",
    "label": "Gogik",
    "value": "Gogik"
  },
  {
    "id": "3322191001",
    "district_id": "332219",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3322191002",
    "district_id": "332219",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3322191003",
    "district_id": "332219",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3322191007",
    "district_id": "332219",
    "label": "Gedanganak",
    "value": "Gedanganak"
  },
  {
    "id": "3322191008",
    "district_id": "332219",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3322192004",
    "district_id": "332219",
    "label": "Mluweh",
    "value": "Mluweh"
  },
  {
    "id": "3322192005",
    "district_id": "332219",
    "label": "Kawengen",
    "value": "Kawengen"
  },
  {
    "id": "3322192006",
    "district_id": "332219",
    "label": "Kalikayen",
    "value": "Kalikayen"
  },
  {
    "id": "3322192009",
    "district_id": "332219",
    "label": "Leyangan",
    "value": "Leyangan"
  },
  {
    "id": "3322192010",
    "district_id": "332219",
    "label": "Kalongan",
    "value": "Kalongan"
  },
  {
    "id": "3322201011",
    "district_id": "332220",
    "label": "Bandungan",
    "value": "Bandungan"
  },
  {
    "id": "3322202001",
    "district_id": "332220",
    "label": "Mlilir",
    "value": "Mlilir"
  },
  {
    "id": "3322202002",
    "district_id": "332220",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3322202003",
    "district_id": "332220",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3322202004",
    "district_id": "332220",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3322202005",
    "district_id": "332220",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3322202006",
    "district_id": "332220",
    "label": "Jimbaran",
    "value": "Jimbaran"
  },
  {
    "id": "3322202007",
    "district_id": "332220",
    "label": "Pakopen",
    "value": "Pakopen"
  },
  {
    "id": "3322202008",
    "district_id": "332220",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3322202009",
    "district_id": "332220",
    "label": "Banyukuning",
    "value": "Banyukuning"
  },
  {
    "id": "3323012001",
    "district_id": "332301",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3323012002",
    "district_id": "332301",
    "label": "Tegalurung",
    "value": "Tegalurung"
  },
  {
    "id": "3323012003",
    "district_id": "332301",
    "label": "Campursari",
    "value": "Campursari"
  },
  {
    "id": "3323012004",
    "district_id": "332301",
    "label": "Gandurejo",
    "value": "Gandurejo"
  },
  {
    "id": "3323012005",
    "district_id": "332301",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3323012006",
    "district_id": "332301",
    "label": "Gondosuli",
    "value": "Gondosuli"
  },
  {
    "id": "3323012007",
    "district_id": "332301",
    "label": "Ngimbrang",
    "value": "Ngimbrang"
  },
  {
    "id": "3323012008",
    "district_id": "332301",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "3323012009",
    "district_id": "332301",
    "label": "Danupayan",
    "value": "Danupayan"
  },
  {
    "id": "3323012010",
    "district_id": "332301",
    "label": "Mondoretno",
    "value": "Mondoretno"
  },
  {
    "id": "3323012011",
    "district_id": "332301",
    "label": "Pandemulyo",
    "value": "Pandemulyo"
  },
  {
    "id": "3323012012",
    "district_id": "332301",
    "label": "Pasuruan",
    "value": "Pasuruan"
  },
  {
    "id": "3323012013",
    "district_id": "332301",
    "label": "Pakurejo",
    "value": "Pakurejo"
  },
  {
    "id": "3323012014",
    "district_id": "332301",
    "label": "Malangsari",
    "value": "Malangsari"
  },
  {
    "id": "3323012015",
    "district_id": "332301",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3323012016",
    "district_id": "332301",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3323012017",
    "district_id": "332301",
    "label": "Bansari",
    "value": "Bansari"
  },
  {
    "id": "3323012018",
    "district_id": "332301",
    "label": "Wonotirto",
    "value": "Wonotirto"
  },
  {
    "id": "3323012019",
    "district_id": "332301",
    "label": "Pengilon",
    "value": "Pengilon"
  },
  {
    "id": "3323022001",
    "district_id": "332302",
    "label": "Tembarak",
    "value": "Tembarak"
  },
  {
    "id": "3323022002",
    "district_id": "332302",
    "label": "Menggoro",
    "value": "Menggoro"
  },
  {
    "id": "3323022003",
    "district_id": "332302",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3323022004",
    "district_id": "332302",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3323022005",
    "district_id": "332302",
    "label": "Kemloko",
    "value": "Kemloko"
  },
  {
    "id": "3323022006",
    "district_id": "332302",
    "label": "Gandu",
    "value": "Gandu"
  },
  {
    "id": "3323022007",
    "district_id": "332302",
    "label": "Botoputih",
    "value": "Botoputih"
  },
  {
    "id": "3323022008",
    "district_id": "332302",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3323022009",
    "district_id": "332302",
    "label": "Greges",
    "value": "Greges"
  },
  {
    "id": "3323022010",
    "district_id": "332302",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3323022011",
    "district_id": "332302",
    "label": "Jragan",
    "value": "Jragan"
  },
  {
    "id": "3323022012",
    "district_id": "332302",
    "label": "Drono",
    "value": "Drono"
  },
  {
    "id": "3323022013",
    "district_id": "332302",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3323031001",
    "district_id": "332303",
    "label": "Temanggung I",
    "value": "Temanggung I"
  },
  {
    "id": "3323031002",
    "district_id": "332303",
    "label": "Temanggung II",
    "value": "Temanggung II"
  },
  {
    "id": "3323031003",
    "district_id": "332303",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3323031004",
    "district_id": "332303",
    "label": "Jampiroso",
    "value": "Jampiroso"
  },
  {
    "id": "3323031005",
    "district_id": "332303",
    "label": "Jampirejo",
    "value": "Jampirejo"
  },
  {
    "id": "3323031006",
    "district_id": "332303",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3323031007",
    "district_id": "332303",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3323031008",
    "district_id": "332303",
    "label": "Kowangan",
    "value": "Kowangan"
  },
  {
    "id": "3323031009",
    "district_id": "332303",
    "label": "Jurang",
    "value": "Jurang"
  },
  {
    "id": "3323031010",
    "district_id": "332303",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3323031012",
    "district_id": "332303",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3323031013",
    "district_id": "332303",
    "label": "Manding",
    "value": "Manding"
  },
  {
    "id": "3323031014",
    "district_id": "332303",
    "label": "Mungseng",
    "value": "Mungseng"
  },
  {
    "id": "3323031015",
    "district_id": "332303",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3323031016",
    "district_id": "332303",
    "label": "Giyanti",
    "value": "Giyanti"
  },
  {
    "id": "3323031018",
    "district_id": "332303",
    "label": "Madureso",
    "value": "Madureso"
  },
  {
    "id": "3323031022",
    "district_id": "332303",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3323031025",
    "district_id": "332303",
    "label": "Walitelon Utara",
    "value": "Walitelon Utara"
  },
  {
    "id": "3323031026",
    "district_id": "332303",
    "label": "Walitelon Selatan",
    "value": "Walitelon Selatan"
  },
  {
    "id": "3323032011",
    "district_id": "332303",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3323032017",
    "district_id": "332303",
    "label": "Guntur",
    "value": "Guntur"
  },
  {
    "id": "3323032019",
    "district_id": "332303",
    "label": "Lungge",
    "value": "Lungge"
  },
  {
    "id": "3323032020",
    "district_id": "332303",
    "label": "Mudal",
    "value": "Mudal"
  },
  {
    "id": "3323032021",
    "district_id": "332303",
    "label": "Gilingsari",
    "value": "Gilingsari"
  },
  {
    "id": "3323032024",
    "district_id": "332303",
    "label": "Nampirejo",
    "value": "Nampirejo"
  },
  {
    "id": "3323042001",
    "district_id": "332304",
    "label": "Pringsurat",
    "value": "Pringsurat"
  },
  {
    "id": "3323042002",
    "district_id": "332304",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3323042003",
    "district_id": "332304",
    "label": "Soropadan",
    "value": "Soropadan"
  },
  {
    "id": "3323042004",
    "district_id": "332304",
    "label": "Kupen",
    "value": "Kupen"
  },
  {
    "id": "3323042005",
    "district_id": "332304",
    "label": "Karangwuni",
    "value": "Karangwuni"
  },
  {
    "id": "3323042006",
    "district_id": "332304",
    "label": "Gowak",
    "value": "Gowak"
  },
  {
    "id": "3323042007",
    "district_id": "332304",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3323042008",
    "district_id": "332304",
    "label": "Ngipik",
    "value": "Ngipik"
  },
  {
    "id": "3323042009",
    "district_id": "332304",
    "label": "Pingit",
    "value": "Pingit"
  },
  {
    "id": "3323042010",
    "district_id": "332304",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3323042011",
    "district_id": "332304",
    "label": "Soborejo",
    "value": "Soborejo"
  },
  {
    "id": "3323042012",
    "district_id": "332304",
    "label": "Nglorog",
    "value": "Nglorog"
  },
  {
    "id": "3323042013",
    "district_id": "332304",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3323042014",
    "district_id": "332304",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3323052001",
    "district_id": "332305",
    "label": "Kaloran",
    "value": "Kaloran"
  },
  {
    "id": "3323052002",
    "district_id": "332305",
    "label": "Tlogowungu",
    "value": "Tlogowungu"
  },
  {
    "id": "3323052003",
    "district_id": "332305",
    "label": "Gandon",
    "value": "Gandon"
  },
  {
    "id": "3323052004",
    "district_id": "332305",
    "label": "Tleter",
    "value": "Tleter"
  },
  {
    "id": "3323052005",
    "district_id": "332305",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3323052006",
    "district_id": "332305",
    "label": "Kalimanggis",
    "value": "Kalimanggis"
  },
  {
    "id": "3323052007",
    "district_id": "332305",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3323052008",
    "district_id": "332305",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3323052009",
    "district_id": "332305",
    "label": "Geblog",
    "value": "Geblog"
  },
  {
    "id": "3323052010",
    "district_id": "332305",
    "label": "Tegowanuh",
    "value": "Tegowanuh"
  },
  {
    "id": "3323052011",
    "district_id": "332305",
    "label": "Keblukan",
    "value": "Keblukan"
  },
  {
    "id": "3323052012",
    "district_id": "332305",
    "label": "Tepusen",
    "value": "Tepusen"
  },
  {
    "id": "3323052013",
    "district_id": "332305",
    "label": "Gandulan",
    "value": "Gandulan"
  },
  {
    "id": "3323052014",
    "district_id": "332305",
    "label": "Kwarakan",
    "value": "Kwarakan"
  },
  {
    "id": "3323062001",
    "district_id": "332306",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3323062002",
    "district_id": "332306",
    "label": "Baledu",
    "value": "Baledu"
  },
  {
    "id": "3323062003",
    "district_id": "332306",
    "label": "Caruban",
    "value": "Caruban"
  },
  {
    "id": "3323062004",
    "district_id": "332306",
    "label": "Wadas",
    "value": "Wadas"
  },
  {
    "id": "3323062005",
    "district_id": "332306",
    "label": "Samiranan",
    "value": "Samiranan"
  },
  {
    "id": "3323062006",
    "district_id": "332306",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3323062007",
    "district_id": "332306",
    "label": "Kembangsari",
    "value": "Kembangsari"
  },
  {
    "id": "3323062008",
    "district_id": "332306",
    "label": "Gesing",
    "value": "Gesing"
  },
  {
    "id": "3323062009",
    "district_id": "332306",
    "label": "Margolelo",
    "value": "Margolelo"
  },
  {
    "id": "3323062010",
    "district_id": "332306",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3323062011",
    "district_id": "332306",
    "label": "Kedungumpul",
    "value": "Kedungumpul"
  },
  {
    "id": "3323062012",
    "district_id": "332306",
    "label": "Rowo",
    "value": "Rowo"
  },
  {
    "id": "3323062013",
    "district_id": "332306",
    "label": "Malebo",
    "value": "Malebo"
  },
  {
    "id": "3323062014",
    "district_id": "332306",
    "label": "Tlogopucang",
    "value": "Tlogopucang"
  },
  {
    "id": "3323062015",
    "district_id": "332306",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3323062016",
    "district_id": "332306",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3323072001",
    "district_id": "332307",
    "label": "Kedu",
    "value": "Kedu"
  },
  {
    "id": "3323072002",
    "district_id": "332307",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3323072003",
    "district_id": "332307",
    "label": "Salamsari",
    "value": "Salamsari"
  },
  {
    "id": "3323072004",
    "district_id": "332307",
    "label": "Danurejo",
    "value": "Danurejo"
  },
  {
    "id": "3323072005",
    "district_id": "332307",
    "label": "Mojotengah",
    "value": "Mojotengah"
  },
  {
    "id": "3323072006",
    "district_id": "332307",
    "label": "Karangtejo",
    "value": "Karangtejo"
  },
  {
    "id": "3323072007",
    "district_id": "332307",
    "label": "Mergowati",
    "value": "Mergowati"
  },
  {
    "id": "3323072008",
    "district_id": "332307",
    "label": "Kutoanyar",
    "value": "Kutoanyar"
  },
  {
    "id": "3323072009",
    "district_id": "332307",
    "label": "Kundisari",
    "value": "Kundisari"
  },
  {
    "id": "3323072010",
    "district_id": "332307",
    "label": "Ngadimulyo",
    "value": "Ngadimulyo"
  },
  {
    "id": "3323072011",
    "district_id": "332307",
    "label": "Gondangwayang",
    "value": "Gondangwayang"
  },
  {
    "id": "3323072012",
    "district_id": "332307",
    "label": "Bojonegoro",
    "value": "Bojonegoro"
  },
  {
    "id": "3323072013",
    "district_id": "332307",
    "label": "Bandunggede",
    "value": "Bandunggede"
  },
  {
    "id": "3323072014",
    "district_id": "332307",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3323081001",
    "district_id": "332308",
    "label": "Parakan Wetan",
    "value": "Parakan Wetan"
  },
  {
    "id": "3323081006",
    "district_id": "332308",
    "label": "Parakan Kauman",
    "value": "Parakan Kauman"
  },
  {
    "id": "3323082002",
    "district_id": "332308",
    "label": "Campursalam",
    "value": "Campursalam"
  },
  {
    "id": "3323082003",
    "district_id": "332308",
    "label": "Wanutengah",
    "value": "Wanutengah"
  },
  {
    "id": "3323082004",
    "district_id": "332308",
    "label": "Nglondong",
    "value": "Nglondong"
  },
  {
    "id": "3323082005",
    "district_id": "332308",
    "label": "Bagusan",
    "value": "Bagusan"
  },
  {
    "id": "3323082007",
    "district_id": "332308",
    "label": "Dangkel",
    "value": "Dangkel"
  },
  {
    "id": "3323082008",
    "district_id": "332308",
    "label": "Mandisari",
    "value": "Mandisari"
  },
  {
    "id": "3323082009",
    "district_id": "332308",
    "label": "Tegalroso",
    "value": "Tegalroso"
  },
  {
    "id": "3323082010",
    "district_id": "332308",
    "label": "Traji",
    "value": "Traji"
  },
  {
    "id": "3323082011",
    "district_id": "332308",
    "label": "Watukumpul",
    "value": "Watukumpul"
  },
  {
    "id": "3323082012",
    "district_id": "332308",
    "label": "Ringinanom",
    "value": "Ringinanom"
  },
  {
    "id": "3323082013",
    "district_id": "332308",
    "label": "Depokharjo",
    "value": "Depokharjo"
  },
  {
    "id": "3323082014",
    "district_id": "332308",
    "label": "Caturanom",
    "value": "Caturanom"
  },
  {
    "id": "3323082015",
    "district_id": "332308",
    "label": "Glapansari",
    "value": "Glapansari"
  },
  {
    "id": "3323082016",
    "district_id": "332308",
    "label": "Sunggingsari",
    "value": "Sunggingsari"
  },
  {
    "id": "3323091019",
    "district_id": "332309",
    "label": "Manggong",
    "value": "Manggong"
  },
  {
    "id": "3323092001",
    "district_id": "332309",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3323092002",
    "district_id": "332309",
    "label": "Gondangwinangun",
    "value": "Gondangwinangun"
  },
  {
    "id": "3323092003",
    "district_id": "332309",
    "label": "Gandu Wetan",
    "value": "Gandu Wetan"
  },
  {
    "id": "3323092004",
    "district_id": "332309",
    "label": "Ngaren",
    "value": "Ngaren"
  },
  {
    "id": "3323092005",
    "district_id": "332309",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3323092006",
    "district_id": "332309",
    "label": "Dlimoyo",
    "value": "Dlimoyo"
  },
  {
    "id": "3323092007",
    "district_id": "332309",
    "label": "Purbosari",
    "value": "Purbosari"
  },
  {
    "id": "3323092008",
    "district_id": "332309",
    "label": "Campursari",
    "value": "Campursari"
  },
  {
    "id": "3323092009",
    "district_id": "332309",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3323092010",
    "district_id": "332309",
    "label": "Giripurno",
    "value": "Giripurno"
  },
  {
    "id": "3323092011",
    "district_id": "332309",
    "label": "Katekan",
    "value": "Katekan"
  },
  {
    "id": "3323092012",
    "district_id": "332309",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3323092013",
    "district_id": "332309",
    "label": "Medari",
    "value": "Medari"
  },
  {
    "id": "3323092014",
    "district_id": "332309",
    "label": "Karanggedong",
    "value": "Karanggedong"
  },
  {
    "id": "3323092015",
    "district_id": "332309",
    "label": "Munggangsari",
    "value": "Munggangsari"
  },
  {
    "id": "3323092016",
    "district_id": "332309",
    "label": "Kataan",
    "value": "Kataan"
  },
  {
    "id": "3323092017",
    "district_id": "332309",
    "label": "Petirejo",
    "value": "Petirejo"
  },
  {
    "id": "3323092018",
    "district_id": "332309",
    "label": "Pringapus",
    "value": "Pringapus"
  },
  {
    "id": "3323092020",
    "district_id": "332309",
    "label": "Gejagan",
    "value": "Gejagan"
  },
  {
    "id": "3323102001",
    "district_id": "332310",
    "label": "Jumo",
    "value": "Jumo"
  },
  {
    "id": "3323102002",
    "district_id": "332310",
    "label": "Jamusan",
    "value": "Jamusan"
  },
  {
    "id": "3323102003",
    "district_id": "332310",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3323102004",
    "district_id": "332310",
    "label": "Giyono",
    "value": "Giyono"
  },
  {
    "id": "3323102005",
    "district_id": "332310",
    "label": "Gununggempol",
    "value": "Gununggempol"
  },
  {
    "id": "3323102006",
    "district_id": "332310",
    "label": "Padureso",
    "value": "Padureso"
  },
  {
    "id": "3323102007",
    "district_id": "332310",
    "label": "Barang",
    "value": "Barang"
  },
  {
    "id": "3323102008",
    "district_id": "332310",
    "label": "Jombor",
    "value": "Jombor"
  },
  {
    "id": "3323102009",
    "district_id": "332310",
    "label": "Ketitang",
    "value": "Ketitang"
  },
  {
    "id": "3323102010",
    "district_id": "332310",
    "label": "Morobongo",
    "value": "Morobongo"
  },
  {
    "id": "3323102011",
    "district_id": "332310",
    "label": "Karangtejo",
    "value": "Karangtejo"
  },
  {
    "id": "3323102012",
    "district_id": "332310",
    "label": "Sukomarto",
    "value": "Sukomarto"
  },
  {
    "id": "3323102013",
    "district_id": "332310",
    "label": "Gedongsari",
    "value": "Gedongsari"
  },
  {
    "id": "3323112001",
    "district_id": "332311",
    "label": "Tretep",
    "value": "Tretep"
  },
  {
    "id": "3323112002",
    "district_id": "332311",
    "label": "Donorojo",
    "value": "Donorojo"
  },
  {
    "id": "3323112003",
    "district_id": "332311",
    "label": "Nglarangan",
    "value": "Nglarangan"
  },
  {
    "id": "3323112004",
    "district_id": "332311",
    "label": "Sigedong",
    "value": "Sigedong"
  },
  {
    "id": "3323112005",
    "district_id": "332311",
    "label": "Bonjor",
    "value": "Bonjor"
  },
  {
    "id": "3323112006",
    "district_id": "332311",
    "label": "Tempelsari",
    "value": "Tempelsari"
  },
  {
    "id": "3323112007",
    "district_id": "332311",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3323112008",
    "district_id": "332311",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3323112009",
    "district_id": "332311",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3323112010",
    "district_id": "332311",
    "label": "Simpar",
    "value": "Simpar"
  },
  {
    "id": "3323112011",
    "district_id": "332311",
    "label": "Tlogo",
    "value": "Tlogo"
  },
  {
    "id": "3323122001",
    "district_id": "332312",
    "label": "Candiroto",
    "value": "Candiroto"
  },
  {
    "id": "3323122002",
    "district_id": "332312",
    "label": "Lempuyang",
    "value": "Lempuyang"
  },
  {
    "id": "3323122003",
    "district_id": "332312",
    "label": "Canggal",
    "value": "Canggal"
  },
  {
    "id": "3323122004",
    "district_id": "332312",
    "label": "Kentengsari",
    "value": "Kentengsari"
  },
  {
    "id": "3323122005",
    "district_id": "332312",
    "label": "Ngabeyan",
    "value": "Ngabeyan"
  },
  {
    "id": "3323122006",
    "district_id": "332312",
    "label": "Bantir",
    "value": "Bantir"
  },
  {
    "id": "3323122007",
    "district_id": "332312",
    "label": "Krawitan",
    "value": "Krawitan"
  },
  {
    "id": "3323122008",
    "district_id": "332312",
    "label": "Muntung",
    "value": "Muntung"
  },
  {
    "id": "3323122009",
    "district_id": "332312",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3323122010",
    "district_id": "332312",
    "label": "Mento",
    "value": "Mento"
  },
  {
    "id": "3323122011",
    "district_id": "332312",
    "label": "Muneng",
    "value": "Muneng"
  },
  {
    "id": "3323122012",
    "district_id": "332312",
    "label": "Plosogaden",
    "value": "Plosogaden"
  },
  {
    "id": "3323122013",
    "district_id": "332312",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3323122014",
    "district_id": "332312",
    "label": "Gunungpayung",
    "value": "Gunungpayung"
  },
  {
    "id": "3323131001",
    "district_id": "332313",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3323132002",
    "district_id": "332313",
    "label": "Badran",
    "value": "Badran"
  },
  {
    "id": "3323132003",
    "district_id": "332313",
    "label": "Bengkal",
    "value": "Bengkal"
  },
  {
    "id": "3323132004",
    "district_id": "332313",
    "label": "Pare",
    "value": "Pare"
  },
  {
    "id": "3323132005",
    "district_id": "332313",
    "label": "Nguwet",
    "value": "Nguwet"
  },
  {
    "id": "3323132006",
    "district_id": "332313",
    "label": "Ngropoh",
    "value": "Ngropoh"
  },
  {
    "id": "3323132007",
    "district_id": "332313",
    "label": "Pendowo",
    "value": "Pendowo"
  },
  {
    "id": "3323132008",
    "district_id": "332313",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3323132009",
    "district_id": "332313",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3323132010",
    "district_id": "332313",
    "label": "Kemloko",
    "value": "Kemloko"
  },
  {
    "id": "3323132011",
    "district_id": "332313",
    "label": "Gentan",
    "value": "Gentan"
  },
  {
    "id": "3323132012",
    "district_id": "332313",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3323132013",
    "district_id": "332313",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3323142001",
    "district_id": "332314",
    "label": "Tlogomulyo",
    "value": "Tlogomulyo"
  },
  {
    "id": "3323142002",
    "district_id": "332314",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3323142003",
    "district_id": "332314",
    "label": "Sriwungu",
    "value": "Sriwungu"
  },
  {
    "id": "3323142004",
    "district_id": "332314",
    "label": "Langgeng",
    "value": "Langgeng"
  },
  {
    "id": "3323142005",
    "district_id": "332314",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3323142006",
    "district_id": "332314",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3323142007",
    "district_id": "332314",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3323142008",
    "district_id": "332314",
    "label": "Kerokan",
    "value": "Kerokan"
  },
  {
    "id": "3323142009",
    "district_id": "332314",
    "label": "Legoksari",
    "value": "Legoksari"
  },
  {
    "id": "3323142010",
    "district_id": "332314",
    "label": "Tlilir",
    "value": "Tlilir"
  },
  {
    "id": "3323142011",
    "district_id": "332314",
    "label": "Gedegan",
    "value": "Gedegan"
  },
  {
    "id": "3323142012",
    "district_id": "332314",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3323152001",
    "district_id": "332315",
    "label": "Selopampang",
    "value": "Selopampang"
  },
  {
    "id": "3323152002",
    "district_id": "332315",
    "label": "Tanggulanom",
    "value": "Tanggulanom"
  },
  {
    "id": "3323152003",
    "district_id": "332315",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3323152004",
    "district_id": "332315",
    "label": "Salamrejo",
    "value": "Salamrejo"
  },
  {
    "id": "3323152005",
    "district_id": "332315",
    "label": "Ngaditirto",
    "value": "Ngaditirto"
  },
  {
    "id": "3323152006",
    "district_id": "332315",
    "label": "Bulan",
    "value": "Bulan"
  },
  {
    "id": "3323152007",
    "district_id": "332315",
    "label": "Kacepit",
    "value": "Kacepit"
  },
  {
    "id": "3323152008",
    "district_id": "332315",
    "label": "Gambasan",
    "value": "Gambasan"
  },
  {
    "id": "3323152009",
    "district_id": "332315",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3323152010",
    "district_id": "332315",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3323152011",
    "district_id": "332315",
    "label": "Bagusan",
    "value": "Bagusan"
  },
  {
    "id": "3323152012",
    "district_id": "332315",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3323162001",
    "district_id": "332316",
    "label": "Bansari",
    "value": "Bansari"
  },
  {
    "id": "3323162002",
    "district_id": "332316",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3323162003",
    "district_id": "332316",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3323162004",
    "district_id": "332316",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3323162005",
    "district_id": "332316",
    "label": "Balesari",
    "value": "Balesari"
  },
  {
    "id": "3323162006",
    "district_id": "332316",
    "label": "Purborejo",
    "value": "Purborejo"
  },
  {
    "id": "3323162007",
    "district_id": "332316",
    "label": "Tlogowero",
    "value": "Tlogowero"
  },
  {
    "id": "3323162008",
    "district_id": "332316",
    "label": "Mranggen Kidul",
    "value": "Mranggen Kidul"
  },
  {
    "id": "3323162009",
    "district_id": "332316",
    "label": "Mranggen Tengah",
    "value": "Mranggen Tengah"
  },
  {
    "id": "3323162010",
    "district_id": "332316",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3323162011",
    "district_id": "332316",
    "label": "Campuranom",
    "value": "Campuranom"
  },
  {
    "id": "3323162012",
    "district_id": "332316",
    "label": "Gentingsari",
    "value": "Gentingsari"
  },
  {
    "id": "3323162013",
    "district_id": "332316",
    "label": "Tanurejo",
    "value": "Tanurejo"
  },
  {
    "id": "3323172001",
    "district_id": "332317",
    "label": "Kledung",
    "value": "Kledung"
  },
  {
    "id": "3323172002",
    "district_id": "332317",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3323172003",
    "district_id": "332317",
    "label": "Paponan",
    "value": "Paponan"
  },
  {
    "id": "3323172004",
    "district_id": "332317",
    "label": "Jeketro",
    "value": "Jeketro"
  },
  {
    "id": "3323172005",
    "district_id": "332317",
    "label": "Kwadungan Gunung",
    "value": "Kwadungan Gunung"
  },
  {
    "id": "3323172006",
    "district_id": "332317",
    "label": "Kwadungan Jurang",
    "value": "Kwadungan Jurang"
  },
  {
    "id": "3323172007",
    "district_id": "332317",
    "label": "Tlahab",
    "value": "Tlahab"
  },
  {
    "id": "3323172008",
    "district_id": "332317",
    "label": "Petarangan",
    "value": "Petarangan"
  },
  {
    "id": "3323172009",
    "district_id": "332317",
    "label": "Canggal",
    "value": "Canggal"
  },
  {
    "id": "3323172010",
    "district_id": "332317",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3323172011",
    "district_id": "332317",
    "label": "Kruwisan",
    "value": "Kruwisan"
  },
  {
    "id": "3323172012",
    "district_id": "332317",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3323172013",
    "district_id": "332317",
    "label": "Tuksari",
    "value": "Tuksari"
  },
  {
    "id": "3323182001",
    "district_id": "332318",
    "label": "Bejen",
    "value": "Bejen"
  },
  {
    "id": "3323182002",
    "district_id": "332318",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3323182003",
    "district_id": "332318",
    "label": "Jlegong",
    "value": "Jlegong"
  },
  {
    "id": "3323182004",
    "district_id": "332318",
    "label": "Prangkokan",
    "value": "Prangkokan"
  },
  {
    "id": "3323182005",
    "district_id": "332318",
    "label": "Lowungu",
    "value": "Lowungu"
  },
  {
    "id": "3323182006",
    "district_id": "332318",
    "label": "Larangan Luwok",
    "value": "Larangan Luwok"
  },
  {
    "id": "3323182007",
    "district_id": "332318",
    "label": "Congkrang",
    "value": "Congkrang"
  },
  {
    "id": "3323182008",
    "district_id": "332318",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3323182009",
    "district_id": "332318",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3323182010",
    "district_id": "332318",
    "label": "Selosabrang",
    "value": "Selosabrang"
  },
  {
    "id": "3323182011",
    "district_id": "332318",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3323182012",
    "district_id": "332318",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3323182013",
    "district_id": "332318",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3323182014",
    "district_id": "332318",
    "label": "Ngaliyan",
    "value": "Ngaliyan"
  },
  {
    "id": "3323192001",
    "district_id": "332319",
    "label": "Wonoboyo",
    "value": "Wonoboyo"
  },
  {
    "id": "3323192002",
    "district_id": "332319",
    "label": "Pateken",
    "value": "Pateken"
  },
  {
    "id": "3323192003",
    "district_id": "332319",
    "label": "Pitrosari",
    "value": "Pitrosari"
  },
  {
    "id": "3323192004",
    "district_id": "332319",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3323192005",
    "district_id": "332319",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3323192006",
    "district_id": "332319",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3323192007",
    "district_id": "332319",
    "label": "Tening",
    "value": "Tening"
  },
  {
    "id": "3323192008",
    "district_id": "332319",
    "label": "Wonocoyo",
    "value": "Wonocoyo"
  },
  {
    "id": "3323192009",
    "district_id": "332319",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3323192010",
    "district_id": "332319",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3323192011",
    "district_id": "332319",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3323192012",
    "district_id": "332319",
    "label": "Cemoro",
    "value": "Cemoro"
  },
  {
    "id": "3323192013",
    "district_id": "332319",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3323202001",
    "district_id": "332320",
    "label": "Gemawang",
    "value": "Gemawang"
  },
  {
    "id": "3323202002",
    "district_id": "332320",
    "label": "Muncar",
    "value": "Muncar"
  },
  {
    "id": "3323202003",
    "district_id": "332320",
    "label": "Kemiriombo",
    "value": "Kemiriombo"
  },
  {
    "id": "3323202004",
    "district_id": "332320",
    "label": "Ngadisepi",
    "value": "Ngadisepi"
  },
  {
    "id": "3323202005",
    "district_id": "332320",
    "label": "Krempong",
    "value": "Krempong"
  },
  {
    "id": "3323202006",
    "district_id": "332320",
    "label": "Sucen",
    "value": "Sucen"
  },
  {
    "id": "3323202007",
    "district_id": "332320",
    "label": "Karangseneng",
    "value": "Karangseneng"
  },
  {
    "id": "3323202008",
    "district_id": "332320",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3323202009",
    "district_id": "332320",
    "label": "Kalibanger",
    "value": "Kalibanger"
  },
  {
    "id": "3323202010",
    "district_id": "332320",
    "label": "Jambon",
    "value": "Jambon"
  },
  {
    "id": "3324012001",
    "district_id": "332401",
    "label": "Blumah",
    "value": "Blumah"
  },
  {
    "id": "3324012002",
    "district_id": "332401",
    "label": "Kediten",
    "value": "Kediten"
  },
  {
    "id": "3324012003",
    "district_id": "332401",
    "label": "Tlogopayung",
    "value": "Tlogopayung"
  },
  {
    "id": "3324012004",
    "district_id": "332401",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3324012005",
    "district_id": "332401",
    "label": "Manggungmangu",
    "value": "Manggungmangu"
  },
  {
    "id": "3324012006",
    "district_id": "332401",
    "label": "Tirtomulyo",
    "value": "Tirtomulyo"
  },
  {
    "id": "3324012007",
    "district_id": "332401",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3324012008",
    "district_id": "332401",
    "label": "Jurangagung",
    "value": "Jurangagung"
  },
  {
    "id": "3324012009",
    "district_id": "332401",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3324012010",
    "district_id": "332401",
    "label": "Wadas",
    "value": "Wadas"
  },
  {
    "id": "3324012011",
    "district_id": "332401",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3324012012",
    "district_id": "332401",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3324022002",
    "district_id": "332402",
    "label": "Getasblawong",
    "value": "Getasblawong"
  },
  {
    "id": "3324022003",
    "district_id": "332402",
    "label": "Parakan Sebaran",
    "value": "Parakan Sebaran"
  },
  {
    "id": "3324022004",
    "district_id": "332402",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3324022005",
    "district_id": "332402",
    "label": "Krikil",
    "value": "Krikil"
  },
  {
    "id": "3324022006",
    "district_id": "332402",
    "label": "Pucakwangi",
    "value": "Pucakwangi"
  },
  {
    "id": "3324022007",
    "district_id": "332402",
    "label": "Pageruyung",
    "value": "Pageruyung"
  },
  {
    "id": "3324022008",
    "district_id": "332402",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3324022009",
    "district_id": "332402",
    "label": "Gebangan",
    "value": "Gebangan"
  },
  {
    "id": "3324022010",
    "district_id": "332402",
    "label": "Surokonto Wetan",
    "value": "Surokonto Wetan"
  },
  {
    "id": "3324022011",
    "district_id": "332402",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3324022012",
    "district_id": "332402",
    "label": "Kebongembong",
    "value": "Kebongembong"
  },
  {
    "id": "3324022013",
    "district_id": "332402",
    "label": "Surokonto Kulon",
    "value": "Surokonto Kulon"
  },
  {
    "id": "3324022014",
    "district_id": "332402",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3324022015",
    "district_id": "332402",
    "label": "Gondoharum",
    "value": "Gondoharum"
  },
  {
    "id": "3324032001",
    "district_id": "332403",
    "label": "Gentinggunung",
    "value": "Gentinggunung"
  },
  {
    "id": "3324032002",
    "district_id": "332403",
    "label": "Bringinsari",
    "value": "Bringinsari"
  },
  {
    "id": "3324032003",
    "district_id": "332403",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3324032004",
    "district_id": "332403",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3324032005",
    "district_id": "332403",
    "label": "Pesaren",
    "value": "Pesaren"
  },
  {
    "id": "3324032006",
    "district_id": "332403",
    "label": "Tamanrejo",
    "value": "Tamanrejo"
  },
  {
    "id": "3324032007",
    "district_id": "332403",
    "label": "Harjodowo",
    "value": "Harjodowo"
  },
  {
    "id": "3324032008",
    "district_id": "332403",
    "label": "Peron",
    "value": "Peron"
  },
  {
    "id": "3324032009",
    "district_id": "332403",
    "label": "Damarjati",
    "value": "Damarjati"
  },
  {
    "id": "3324032010",
    "district_id": "332403",
    "label": "Mulyosari",
    "value": "Mulyosari"
  },
  {
    "id": "3324032011",
    "district_id": "332403",
    "label": "Kalipakis",
    "value": "Kalipakis"
  },
  {
    "id": "3324032012",
    "district_id": "332403",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3324032013",
    "district_id": "332403",
    "label": "Selokaton",
    "value": "Selokaton"
  },
  {
    "id": "3324032014",
    "district_id": "332403",
    "label": "Ngadiwarno",
    "value": "Ngadiwarno"
  },
  {
    "id": "3324032015",
    "district_id": "332403",
    "label": "Tampingwinarno",
    "value": "Tampingwinarno"
  },
  {
    "id": "3324032016",
    "district_id": "332403",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3324032017",
    "district_id": "332403",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3324032018",
    "district_id": "332403",
    "label": "Kalibogor",
    "value": "Kalibogor"
  },
  {
    "id": "3324042001",
    "district_id": "332404",
    "label": "Pakisan",
    "value": "Pakisan"
  },
  {
    "id": "3324042002",
    "district_id": "332404",
    "label": "Plososari",
    "value": "Plososari"
  },
  {
    "id": "3324042003",
    "district_id": "332404",
    "label": "Mlatiharjo",
    "value": "Mlatiharjo"
  },
  {
    "id": "3324042004",
    "district_id": "332404",
    "label": "Wirosari",
    "value": "Wirosari"
  },
  {
    "id": "3324042005",
    "district_id": "332404",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3324042006",
    "district_id": "332404",
    "label": "Selo",
    "value": "Selo"
  },
  {
    "id": "3324042007",
    "district_id": "332404",
    "label": "Curugsewu",
    "value": "Curugsewu"
  },
  {
    "id": "3324042008",
    "district_id": "332404",
    "label": "Gedong",
    "value": "Gedong"
  },
  {
    "id": "3324042009",
    "district_id": "332404",
    "label": "Sukomangli",
    "value": "Sukomangli"
  },
  {
    "id": "3324042010",
    "district_id": "332404",
    "label": "Kalibareng",
    "value": "Kalibareng"
  },
  {
    "id": "3324042011",
    "district_id": "332404",
    "label": "Kalilumpang",
    "value": "Kalilumpang"
  },
  {
    "id": "3324042012",
    "district_id": "332404",
    "label": "Kalices",
    "value": "Kalices"
  },
  {
    "id": "3324042013",
    "district_id": "332404",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3324042014",
    "district_id": "332404",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3324052001",
    "district_id": "332405",
    "label": "Cening",
    "value": "Cening"
  },
  {
    "id": "3324052002",
    "district_id": "332405",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3324052003",
    "district_id": "332405",
    "label": "Kaliputih",
    "value": "Kaliputih"
  },
  {
    "id": "3324052004",
    "district_id": "332405",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3324052005",
    "district_id": "332405",
    "label": "Banyuringin",
    "value": "Banyuringin"
  },
  {
    "id": "3324052006",
    "district_id": "332405",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3324052007",
    "district_id": "332405",
    "label": "Ngareanak",
    "value": "Ngareanak"
  },
  {
    "id": "3324052008",
    "district_id": "332405",
    "label": "Singorojo",
    "value": "Singorojo"
  },
  {
    "id": "3324052009",
    "district_id": "332405",
    "label": "Cacaban",
    "value": "Cacaban"
  },
  {
    "id": "3324052010",
    "district_id": "332405",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3324052011",
    "district_id": "332405",
    "label": "Merbuh",
    "value": "Merbuh"
  },
  {
    "id": "3324052012",
    "district_id": "332405",
    "label": "Trayu",
    "value": "Trayu"
  },
  {
    "id": "3324052013",
    "district_id": "332405",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3324062001",
    "district_id": "332406",
    "label": "Kedungboto",
    "value": "Kedungboto"
  },
  {
    "id": "3324062002",
    "district_id": "332406",
    "label": "Peron",
    "value": "Peron"
  },
  {
    "id": "3324062003",
    "district_id": "332406",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3324062004",
    "district_id": "332406",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3324062005",
    "district_id": "332406",
    "label": "Sumberahayu",
    "value": "Sumberahayu"
  },
  {
    "id": "3324062006",
    "district_id": "332406",
    "label": "Tambahsari",
    "value": "Tambahsari"
  },
  {
    "id": "3324062007",
    "district_id": "332406",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3324062008",
    "district_id": "332406",
    "label": "Pagertoyo",
    "value": "Pagertoyo"
  },
  {
    "id": "3324062009",
    "district_id": "332406",
    "label": "Sriwulan",
    "value": "Sriwulan"
  },
  {
    "id": "3324062010",
    "district_id": "332406",
    "label": "Tabet",
    "value": "Tabet"
  },
  {
    "id": "3324062011",
    "district_id": "332406",
    "label": "Ngesrepbalong",
    "value": "Ngesrepbalong"
  },
  {
    "id": "3324062012",
    "district_id": "332406",
    "label": "Gonoharjo",
    "value": "Gonoharjo"
  },
  {
    "id": "3324062013",
    "district_id": "332406",
    "label": "Jawisari",
    "value": "Jawisari"
  },
  {
    "id": "3324062014",
    "district_id": "332406",
    "label": "Margosari",
    "value": "Margosari"
  },
  {
    "id": "3324062015",
    "district_id": "332406",
    "label": "Tamanrejo",
    "value": "Tamanrejo"
  },
  {
    "id": "3324062016",
    "district_id": "332406",
    "label": "Pagerwojo",
    "value": "Pagerwojo"
  },
  {
    "id": "3324072001",
    "district_id": "332407",
    "label": "Medono",
    "value": "Medono"
  },
  {
    "id": "3324072002",
    "district_id": "332407",
    "label": "Pasigitan",
    "value": "Pasigitan"
  },
  {
    "id": "3324072003",
    "district_id": "332407",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3324072004",
    "district_id": "332407",
    "label": "Leban",
    "value": "Leban"
  },
  {
    "id": "3324072005",
    "district_id": "332407",
    "label": "Kliris",
    "value": "Kliris"
  },
  {
    "id": "3324072006",
    "district_id": "332407",
    "label": "Puguh",
    "value": "Puguh"
  },
  {
    "id": "3324072007",
    "district_id": "332407",
    "label": "Ngabean",
    "value": "Ngabean"
  },
  {
    "id": "3324072008",
    "district_id": "332407",
    "label": "Karangmanggis",
    "value": "Karangmanggis"
  },
  {
    "id": "3324072009",
    "district_id": "332407",
    "label": "Kaligading",
    "value": "Kaligading"
  },
  {
    "id": "3324072010",
    "district_id": "332407",
    "label": "Purwogondo",
    "value": "Purwogondo"
  },
  {
    "id": "3324072011",
    "district_id": "332407",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3324072012",
    "district_id": "332407",
    "label": "Salamsari",
    "value": "Salamsari"
  },
  {
    "id": "3324072013",
    "district_id": "332407",
    "label": "Tampingan",
    "value": "Tampingan"
  },
  {
    "id": "3324072014",
    "district_id": "332407",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3324072015",
    "district_id": "332407",
    "label": "Boja",
    "value": "Boja"
  },
  {
    "id": "3324072016",
    "district_id": "332407",
    "label": "Bebengan",
    "value": "Bebengan"
  },
  {
    "id": "3324072017",
    "district_id": "332407",
    "label": "Meteseh",
    "value": "Meteseh"
  },
  {
    "id": "3324072018",
    "district_id": "332407",
    "label": "Trisobo",
    "value": "Trisobo"
  },
  {
    "id": "3324082003",
    "district_id": "332408",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3324082004",
    "district_id": "332408",
    "label": "Nolokerto",
    "value": "Nolokerto"
  },
  {
    "id": "3324082009",
    "district_id": "332408",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3324082010",
    "district_id": "332408",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3324082011",
    "district_id": "332408",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3324082012",
    "district_id": "332408",
    "label": "Krajankulon",
    "value": "Krajankulon"
  },
  {
    "id": "3324082013",
    "district_id": "332408",
    "label": "Kutoharjo",
    "value": "Kutoharjo"
  },
  {
    "id": "3324082014",
    "district_id": "332408",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3324082015",
    "district_id": "332408",
    "label": "Mororejo",
    "value": "Mororejo"
  },
  {
    "id": "3324092001",
    "district_id": "332409",
    "label": "Tunggulsari",
    "value": "Tunggulsari"
  },
  {
    "id": "3324092002",
    "district_id": "332409",
    "label": "Sumur",
    "value": "Sumur"
  },
  {
    "id": "3324092003",
    "district_id": "332409",
    "label": "Blorok",
    "value": "Blorok"
  },
  {
    "id": "3324092004",
    "district_id": "332409",
    "label": "Penjalin",
    "value": "Penjalin"
  },
  {
    "id": "3324092005",
    "district_id": "332409",
    "label": "Kertomulyo",
    "value": "Kertomulyo"
  },
  {
    "id": "3324092006",
    "district_id": "332409",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3324092007",
    "district_id": "332409",
    "label": "Tosari",
    "value": "Tosari"
  },
  {
    "id": "3324092008",
    "district_id": "332409",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3324092009",
    "district_id": "332409",
    "label": "Turunrejo",
    "value": "Turunrejo"
  },
  {
    "id": "3324092010",
    "district_id": "332409",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3324092011",
    "district_id": "332409",
    "label": "Brangsong",
    "value": "Brangsong"
  },
  {
    "id": "3324092012",
    "district_id": "332409",
    "label": "Kebonadem",
    "value": "Kebonadem"
  },
  {
    "id": "3324102001",
    "district_id": "332410",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3324102002",
    "district_id": "332410",
    "label": "Tegorejo",
    "value": "Tegorejo"
  },
  {
    "id": "3324102003",
    "district_id": "332410",
    "label": "Pesawahan",
    "value": "Pesawahan"
  },
  {
    "id": "3324102004",
    "district_id": "332410",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3324102005",
    "district_id": "332410",
    "label": "Puguh",
    "value": "Puguh"
  },
  {
    "id": "3324102006",
    "district_id": "332410",
    "label": "Dawungsari",
    "value": "Dawungsari"
  },
  {
    "id": "3324102007",
    "district_id": "332410",
    "label": "Pucangrejo",
    "value": "Pucangrejo"
  },
  {
    "id": "3324102008",
    "district_id": "332410",
    "label": "Gubugsari",
    "value": "Gubugsari"
  },
  {
    "id": "3324102009",
    "district_id": "332410",
    "label": "Pegandon",
    "value": "Pegandon"
  },
  {
    "id": "3324102010",
    "district_id": "332410",
    "label": "Penanggulan",
    "value": "Penanggulan"
  },
  {
    "id": "3324102011",
    "district_id": "332410",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3324102012",
    "district_id": "332410",
    "label": "Karangmulyo",
    "value": "Karangmulyo"
  },
  {
    "id": "3324112001",
    "district_id": "332411",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3324112002",
    "district_id": "332411",
    "label": "Pamriyan",
    "value": "Pamriyan"
  },
  {
    "id": "3324112003",
    "district_id": "332411",
    "label": "Jenarsari",
    "value": "Jenarsari"
  },
  {
    "id": "3324112004",
    "district_id": "332411",
    "label": "Poncorejo",
    "value": "Poncorejo"
  },
  {
    "id": "3324112005",
    "district_id": "332411",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3324112006",
    "district_id": "332411",
    "label": "Krompaan",
    "value": "Krompaan"
  },
  {
    "id": "3324112007",
    "district_id": "332411",
    "label": "Gemuhblanten",
    "value": "Gemuhblanten"
  },
  {
    "id": "3324112008",
    "district_id": "332411",
    "label": "Tamangede",
    "value": "Tamangede"
  },
  {
    "id": "3324112009",
    "district_id": "332411",
    "label": "Lumansari",
    "value": "Lumansari"
  },
  {
    "id": "3324112010",
    "district_id": "332411",
    "label": "Johorejo",
    "value": "Johorejo"
  },
  {
    "id": "3324112011",
    "district_id": "332411",
    "label": "Tlahab",
    "value": "Tlahab"
  },
  {
    "id": "3324112012",
    "district_id": "332411",
    "label": "Pucangrejo",
    "value": "Pucangrejo"
  },
  {
    "id": "3324112013",
    "district_id": "332411",
    "label": "Sojomerto",
    "value": "Sojomerto"
  },
  {
    "id": "3324112014",
    "district_id": "332411",
    "label": "Triharjo",
    "value": "Triharjo"
  },
  {
    "id": "3324112015",
    "district_id": "332411",
    "label": "Cepokomulyo",
    "value": "Cepokomulyo"
  },
  {
    "id": "3324112016",
    "district_id": "332411",
    "label": "Galih",
    "value": "Galih"
  },
  {
    "id": "3324122001",
    "district_id": "332412",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3324122002",
    "district_id": "332412",
    "label": "Penyangkringan",
    "value": "Penyangkringan"
  },
  {
    "id": "3324122003",
    "district_id": "332412",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3324122004",
    "district_id": "332412",
    "label": "Manggungsari",
    "value": "Manggungsari"
  },
  {
    "id": "3324122005",
    "district_id": "332412",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3324122006",
    "district_id": "332412",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3324122007",
    "district_id": "332412",
    "label": "Weleri",
    "value": "Weleri"
  },
  {
    "id": "3324122008",
    "district_id": "332412",
    "label": "Nawangsari",
    "value": "Nawangsari"
  },
  {
    "id": "3324122009",
    "district_id": "332412",
    "label": "Karangdowo",
    "value": "Karangdowo"
  },
  {
    "id": "3324122010",
    "district_id": "332412",
    "label": "Penaruban",
    "value": "Penaruban"
  },
  {
    "id": "3324122011",
    "district_id": "332412",
    "label": "Sambongsari",
    "value": "Sambongsari"
  },
  {
    "id": "3324122012",
    "district_id": "332412",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3324122013",
    "district_id": "332412",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "3324122014",
    "district_id": "332412",
    "label": "Pucuksari",
    "value": "Pucuksari"
  },
  {
    "id": "3324122015",
    "district_id": "332412",
    "label": "Tratemulyo",
    "value": "Tratemulyo"
  },
  {
    "id": "3324122016",
    "district_id": "332412",
    "label": "Montongsari",
    "value": "Montongsari"
  },
  {
    "id": "3324132001",
    "district_id": "332413",
    "label": "Pandes",
    "value": "Pandes"
  },
  {
    "id": "3324132002",
    "district_id": "332413",
    "label": "Podosari",
    "value": "Podosari"
  },
  {
    "id": "3324132003",
    "district_id": "332413",
    "label": "Botomulyo",
    "value": "Botomulyo"
  },
  {
    "id": "3324132004",
    "district_id": "332413",
    "label": "Cepiring",
    "value": "Cepiring"
  },
  {
    "id": "3324132005",
    "district_id": "332413",
    "label": "Damarsari",
    "value": "Damarsari"
  },
  {
    "id": "3324132006",
    "district_id": "332413",
    "label": "Karangayu",
    "value": "Karangayu"
  },
  {
    "id": "3324132007",
    "district_id": "332413",
    "label": "Karangsuno",
    "value": "Karangsuno"
  },
  {
    "id": "3324132008",
    "district_id": "332413",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3324132009",
    "district_id": "332413",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3324132010",
    "district_id": "332413",
    "label": "Juwiring",
    "value": "Juwiring"
  },
  {
    "id": "3324132011",
    "district_id": "332413",
    "label": "Kaliayu",
    "value": "Kaliayu"
  },
  {
    "id": "3324132012",
    "district_id": "332413",
    "label": "Kalirandugede",
    "value": "Kalirandugede"
  },
  {
    "id": "3324132013",
    "district_id": "332413",
    "label": "Korowelangkulon",
    "value": "Korowelangkulon"
  },
  {
    "id": "3324132014",
    "district_id": "332413",
    "label": "Korowelanganyar",
    "value": "Korowelanganyar"
  },
  {
    "id": "3324132015",
    "district_id": "332413",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3324142001",
    "district_id": "332414",
    "label": "Bulugede",
    "value": "Bulugede"
  },
  {
    "id": "3324142002",
    "district_id": "332414",
    "label": "Margosari",
    "value": "Margosari"
  },
  {
    "id": "3324142003",
    "district_id": "332414",
    "label": "Donosari",
    "value": "Donosari"
  },
  {
    "id": "3324142004",
    "district_id": "332414",
    "label": "Lanji",
    "value": "Lanji"
  },
  {
    "id": "3324142005",
    "district_id": "332414",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3324142006",
    "district_id": "332414",
    "label": "Kebonharjo",
    "value": "Kebonharjo"
  },
  {
    "id": "3324142007",
    "district_id": "332414",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3324142008",
    "district_id": "332414",
    "label": "Jambearum",
    "value": "Jambearum"
  },
  {
    "id": "3324142009",
    "district_id": "332414",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3324142010",
    "district_id": "332414",
    "label": "Sukolilan",
    "value": "Sukolilan"
  },
  {
    "id": "3324142011",
    "district_id": "332414",
    "label": "Bangunrejo",
    "value": "Bangunrejo"
  },
  {
    "id": "3324142012",
    "district_id": "332414",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3324142013",
    "district_id": "332414",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3324142014",
    "district_id": "332414",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3324142015",
    "district_id": "332414",
    "label": "Pidodowetan",
    "value": "Pidodowetan"
  },
  {
    "id": "3324142016",
    "district_id": "332414",
    "label": "Pidodokulon",
    "value": "Pidodokulon"
  },
  {
    "id": "3324142017",
    "district_id": "332414",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3324142018",
    "district_id": "332414",
    "label": "Kartikajaya",
    "value": "Kartikajaya"
  },
  {
    "id": "3324151001",
    "district_id": "332415",
    "label": "Candiroto",
    "value": "Candiroto"
  },
  {
    "id": "3324151002",
    "district_id": "332415",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3324151003",
    "district_id": "332415",
    "label": "Jotang",
    "value": "Jotang"
  },
  {
    "id": "3324151004",
    "district_id": "332415",
    "label": "Trompo",
    "value": "Trompo"
  },
  {
    "id": "3324151005",
    "district_id": "332415",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3324151006",
    "district_id": "332415",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3324151007",
    "district_id": "332415",
    "label": "Kalibutuwetan",
    "value": "Kalibutuwetan"
  },
  {
    "id": "3324151008",
    "district_id": "332415",
    "label": "Sijeruk",
    "value": "Sijeruk"
  },
  {
    "id": "3324151009",
    "district_id": "332415",
    "label": "Tunggulrejo",
    "value": "Tunggulrejo"
  },
  {
    "id": "3324151010",
    "district_id": "332415",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3324151011",
    "district_id": "332415",
    "label": "Bugangin",
    "value": "Bugangin"
  },
  {
    "id": "3324151012",
    "district_id": "332415",
    "label": "Langenharjo",
    "value": "Langenharjo"
  },
  {
    "id": "3324151013",
    "district_id": "332415",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3324151014",
    "district_id": "332415",
    "label": "Patukangan",
    "value": "Patukangan"
  },
  {
    "id": "3324151015",
    "district_id": "332415",
    "label": "Pegulon",
    "value": "Pegulon"
  },
  {
    "id": "3324151016",
    "district_id": "332415",
    "label": "Banyutowo",
    "value": "Banyutowo"
  },
  {
    "id": "3324151017",
    "district_id": "332415",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3324151018",
    "district_id": "332415",
    "label": "Ngilir",
    "value": "Ngilir"
  },
  {
    "id": "3324151019",
    "district_id": "332415",
    "label": "Bandengan",
    "value": "Bandengan"
  },
  {
    "id": "3324151020",
    "district_id": "332415",
    "label": "Balok",
    "value": "Balok"
  },
  {
    "id": "3324162001",
    "district_id": "332416",
    "label": "Wonotenggang",
    "value": "Wonotenggang"
  },
  {
    "id": "3324162002",
    "district_id": "332416",
    "label": "Pojoksari",
    "value": "Pojoksari"
  },
  {
    "id": "3324162003",
    "district_id": "332416",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3324162004",
    "district_id": "332416",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3324162005",
    "district_id": "332416",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3324162006",
    "district_id": "332416",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3324162007",
    "district_id": "332416",
    "label": "Jatipurwo",
    "value": "Jatipurwo"
  },
  {
    "id": "3324162008",
    "district_id": "332416",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3324162009",
    "district_id": "332416",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3324162010",
    "district_id": "332416",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3324162011",
    "district_id": "332416",
    "label": "Sendangdawuhan",
    "value": "Sendangdawuhan"
  },
  {
    "id": "3324162012",
    "district_id": "332416",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3324162013",
    "district_id": "332416",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3324162014",
    "district_id": "332416",
    "label": "Gebanganom",
    "value": "Gebanganom"
  },
  {
    "id": "3324162015",
    "district_id": "332416",
    "label": "Gempolsewu",
    "value": "Gempolsewu"
  },
  {
    "id": "3324162016",
    "district_id": "332416",
    "label": "Sendangsikucing",
    "value": "Sendangsikucing"
  },
  {
    "id": "3324172001",
    "district_id": "332417",
    "label": "Truko",
    "value": "Truko"
  },
  {
    "id": "3324172002",
    "district_id": "332417",
    "label": "Lebosari",
    "value": "Lebosari"
  },
  {
    "id": "3324172003",
    "district_id": "332417",
    "label": "Kadilangu",
    "value": "Kadilangu"
  },
  {
    "id": "3324172004",
    "district_id": "332417",
    "label": "Gembonganomwetan",
    "value": "Gembonganomwetan"
  },
  {
    "id": "3324172005",
    "district_id": "332417",
    "label": "Kaliyoso",
    "value": "Kaliyoso"
  },
  {
    "id": "3324172006",
    "district_id": "332417",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3324172007",
    "district_id": "332417",
    "label": "Sendangkulon",
    "value": "Sendangkulon"
  },
  {
    "id": "3324172008",
    "district_id": "332417",
    "label": "Sendangdawung",
    "value": "Sendangdawung"
  },
  {
    "id": "3324172009",
    "district_id": "332417",
    "label": "Laban",
    "value": "Laban"
  },
  {
    "id": "3324172010",
    "district_id": "332417",
    "label": "Karangmalangwetan",
    "value": "Karangmalangwetan"
  },
  {
    "id": "3324172011",
    "district_id": "332417",
    "label": "Jungsemi",
    "value": "Jungsemi"
  },
  {
    "id": "3324172012",
    "district_id": "332417",
    "label": "Kangkung",
    "value": "Kangkung"
  },
  {
    "id": "3324172013",
    "district_id": "332417",
    "label": "Tanjungmojo",
    "value": "Tanjungmojo"
  },
  {
    "id": "3324172014",
    "district_id": "332417",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3324172015",
    "district_id": "332417",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3324182001",
    "district_id": "332418",
    "label": "Ngerjo",
    "value": "Ngerjo"
  },
  {
    "id": "3324182002",
    "district_id": "332418",
    "label": "Kedungasri",
    "value": "Kedungasri"
  },
  {
    "id": "3324182003",
    "district_id": "332418",
    "label": "Kedunggading",
    "value": "Kedunggading"
  },
  {
    "id": "3324182004",
    "district_id": "332418",
    "label": "Ringinarum",
    "value": "Ringinarum"
  },
  {
    "id": "3324182005",
    "district_id": "332418",
    "label": "Ngawensari",
    "value": "Ngawensari"
  },
  {
    "id": "3324182006",
    "district_id": "332418",
    "label": "Tejorejo",
    "value": "Tejorejo"
  },
  {
    "id": "3324182007",
    "district_id": "332418",
    "label": "Wungurejo",
    "value": "Wungurejo"
  },
  {
    "id": "3324182008",
    "district_id": "332418",
    "label": "Rowobranten",
    "value": "Rowobranten"
  },
  {
    "id": "3324182009",
    "district_id": "332418",
    "label": "Caruban",
    "value": "Caruban"
  },
  {
    "id": "3324182010",
    "district_id": "332418",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3324182011",
    "district_id": "332418",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3324182012",
    "district_id": "332418",
    "label": "Pegerdawung",
    "value": "Pegerdawung"
  },
  {
    "id": "3324192001",
    "district_id": "332419",
    "label": "Dempelrejo",
    "value": "Dempelrejo"
  },
  {
    "id": "3324192002",
    "district_id": "332419",
    "label": "Putatgede",
    "value": "Putatgede"
  },
  {
    "id": "3324192003",
    "district_id": "332419",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3324192004",
    "district_id": "332419",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3324192005",
    "district_id": "332419",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3324192006",
    "district_id": "332419",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3324192007",
    "district_id": "332419",
    "label": "Bojonggede",
    "value": "Bojonggede"
  },
  {
    "id": "3324192008",
    "district_id": "332419",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3324192009",
    "district_id": "332419",
    "label": "Ngampel Kulon",
    "value": "Ngampel Kulon"
  },
  {
    "id": "3324192010",
    "district_id": "332419",
    "label": "Ngampel Wetan",
    "value": "Ngampel Wetan"
  },
  {
    "id": "3324192011",
    "district_id": "332419",
    "label": "Sudipayung",
    "value": "Sudipayung"
  },
  {
    "id": "3324192012",
    "district_id": "332419",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3324202001",
    "district_id": "332420",
    "label": "Jerukgiling",
    "value": "Jerukgiling"
  },
  {
    "id": "3324202002",
    "district_id": "332420",
    "label": "Kedungsuren",
    "value": "Kedungsuren"
  },
  {
    "id": "3324202003",
    "district_id": "332420",
    "label": "Darupono",
    "value": "Darupono"
  },
  {
    "id": "3324202004",
    "district_id": "332420",
    "label": "Protomulyo",
    "value": "Protomulyo"
  },
  {
    "id": "3324202005",
    "district_id": "332420",
    "label": "Magelung",
    "value": "Magelung"
  },
  {
    "id": "3324202006",
    "district_id": "332420",
    "label": "Plantaran",
    "value": "Plantaran"
  },
  {
    "id": "3324202007",
    "district_id": "332420",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3324202008",
    "district_id": "332420",
    "label": "Sidomakmur",
    "value": "Sidomakmur"
  },
  {
    "id": "3325012001",
    "district_id": "332501",
    "label": "Silurah",
    "value": "Silurah"
  },
  {
    "id": "3325012002",
    "district_id": "332501",
    "label": "Sodong",
    "value": "Sodong"
  },
  {
    "id": "3325012003",
    "district_id": "332501",
    "label": "Gringgingsari",
    "value": "Gringgingsari"
  },
  {
    "id": "3325012004",
    "district_id": "332501",
    "label": "Kedungmalang",
    "value": "Kedungmalang"
  },
  {
    "id": "3325012005",
    "district_id": "332501",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3325012006",
    "district_id": "332501",
    "label": "Wonotunggal",
    "value": "Wonotunggal"
  },
  {
    "id": "3325012007",
    "district_id": "332501",
    "label": "Brokoh",
    "value": "Brokoh"
  },
  {
    "id": "3325012008",
    "district_id": "332501",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3325012009",
    "district_id": "332501",
    "label": "Brayo",
    "value": "Brayo"
  },
  {
    "id": "3325012010",
    "district_id": "332501",
    "label": "Kemligi",
    "value": "Kemligi"
  },
  {
    "id": "3325012011",
    "district_id": "332501",
    "label": "Sigayam",
    "value": "Sigayam"
  },
  {
    "id": "3325012012",
    "district_id": "332501",
    "label": "Kreyo",
    "value": "Kreyo"
  },
  {
    "id": "3325012013",
    "district_id": "332501",
    "label": "Siwatu",
    "value": "Siwatu"
  },
  {
    "id": "3325012014",
    "district_id": "332501",
    "label": "Dringo",
    "value": "Dringo"
  },
  {
    "id": "3325012015",
    "district_id": "332501",
    "label": "Penangkan",
    "value": "Penangkan"
  },
  {
    "id": "3325022001",
    "district_id": "332502",
    "label": "Tombo",
    "value": "Tombo"
  },
  {
    "id": "3325022002",
    "district_id": "332502",
    "label": "Wonomerto",
    "value": "Wonomerto"
  },
  {
    "id": "3325022003",
    "district_id": "332502",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3325022004",
    "district_id": "332502",
    "label": "Pesalakan",
    "value": "Pesalakan"
  },
  {
    "id": "3325022005",
    "district_id": "332502",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3325022006",
    "district_id": "332502",
    "label": "Sidayu",
    "value": "Sidayu"
  },
  {
    "id": "3325022007",
    "district_id": "332502",
    "label": "Toso",
    "value": "Toso"
  },
  {
    "id": "3325022010",
    "district_id": "332502",
    "label": "Kluwih",
    "value": "Kluwih"
  },
  {
    "id": "3325022011",
    "district_id": "332502",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3325022012",
    "district_id": "332502",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "3325022013",
    "district_id": "332502",
    "label": "Tumbrep",
    "value": "Tumbrep"
  },
  {
    "id": "3325022014",
    "district_id": "332502",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3325022015",
    "district_id": "332502",
    "label": "Puncanggading",
    "value": "Puncanggading"
  },
  {
    "id": "3325022016",
    "district_id": "332502",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3325022018",
    "district_id": "332502",
    "label": "Wonosegoro",
    "value": "Wonosegoro"
  },
  {
    "id": "3325022019",
    "district_id": "332502",
    "label": "Simpar",
    "value": "Simpar"
  },
  {
    "id": "3325022020",
    "district_id": "332502",
    "label": "Batiombo",
    "value": "Batiombo"
  },
  {
    "id": "3325032001",
    "district_id": "332503",
    "label": "Gerlang",
    "value": "Gerlang"
  },
  {
    "id": "3325032002",
    "district_id": "332503",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3325032003",
    "district_id": "332503",
    "label": "Kembanglangit",
    "value": "Kembanglangit"
  },
  {
    "id": "3325032004",
    "district_id": "332503",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3325032005",
    "district_id": "332503",
    "label": "Bismo",
    "value": "Bismo"
  },
  {
    "id": "3325032006",
    "district_id": "332503",
    "label": "Keteleng",
    "value": "Keteleng"
  },
  {
    "id": "3325032007",
    "district_id": "332503",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3325032009",
    "district_id": "332503",
    "label": "Besani",
    "value": "Besani"
  },
  {
    "id": "3325032010",
    "district_id": "332503",
    "label": "Wonobodro",
    "value": "Wonobodro"
  },
  {
    "id": "3325032011",
    "district_id": "332503",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "3325032012",
    "district_id": "332503",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3325032013",
    "district_id": "332503",
    "label": "Kambangan",
    "value": "Kambangan"
  },
  {
    "id": "3325032014",
    "district_id": "332503",
    "label": "Keputon",
    "value": "Keputon"
  },
  {
    "id": "3325032015",
    "district_id": "332503",
    "label": "Blado",
    "value": "Blado"
  },
  {
    "id": "3325032016",
    "district_id": "332503",
    "label": "Cokro",
    "value": "Cokro"
  },
  {
    "id": "3325032017",
    "district_id": "332503",
    "label": "Selopajang Barat",
    "value": "Selopajang Barat"
  },
  {
    "id": "3325032020",
    "district_id": "332503",
    "label": "Kalipancur",
    "value": "Kalipancur"
  },
  {
    "id": "3325032021",
    "district_id": "332503",
    "label": "Selopajang Timur",
    "value": "Selopajang Timur"
  },
  {
    "id": "3325042001",
    "district_id": "332504",
    "label": "Pacet",
    "value": "Pacet"
  },
  {
    "id": "3325042002",
    "district_id": "332504",
    "label": "Mojotengah",
    "value": "Mojotengah"
  },
  {
    "id": "3325042003",
    "district_id": "332504",
    "label": "Cablikan",
    "value": "Cablikan"
  },
  {
    "id": "3325042004",
    "district_id": "332504",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3325042005",
    "district_id": "332504",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3325042006",
    "district_id": "332504",
    "label": "Reban",
    "value": "Reban"
  },
  {
    "id": "3325042007",
    "district_id": "332504",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "3325042008",
    "district_id": "332504",
    "label": "Adinuso",
    "value": "Adinuso"
  },
  {
    "id": "3325042009",
    "district_id": "332504",
    "label": "Kumesu",
    "value": "Kumesu"
  },
  {
    "id": "3325042010",
    "district_id": "332504",
    "label": "Kepundung",
    "value": "Kepundung"
  },
  {
    "id": "3325042011",
    "district_id": "332504",
    "label": "Padomasan",
    "value": "Padomasan"
  },
  {
    "id": "3325042012",
    "district_id": "332504",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3325042013",
    "district_id": "332504",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "3325042014",
    "district_id": "332504",
    "label": "Sojomerto",
    "value": "Sojomerto"
  },
  {
    "id": "3325042015",
    "district_id": "332504",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3325042016",
    "district_id": "332504",
    "label": "Polodoro",
    "value": "Polodoro"
  },
  {
    "id": "3325042017",
    "district_id": "332504",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3325042018",
    "district_id": "332504",
    "label": "Sukomangli",
    "value": "Sukomangli"
  },
  {
    "id": "3325042019",
    "district_id": "332504",
    "label": "Wonorojo",
    "value": "Wonorojo"
  },
  {
    "id": "3325052001",
    "district_id": "332505",
    "label": "Pranten",
    "value": "Pranten"
  },
  {
    "id": "3325052002",
    "district_id": "332505",
    "label": "Deles",
    "value": "Deles"
  },
  {
    "id": "3325052003",
    "district_id": "332505",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3325052004",
    "district_id": "332505",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3325052005",
    "district_id": "332505",
    "label": "Kebaturan",
    "value": "Kebaturan"
  },
  {
    "id": "3325052006",
    "district_id": "332505",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3325052007",
    "district_id": "332505",
    "label": "Sangubanyu",
    "value": "Sangubanyu"
  },
  {
    "id": "3325052008",
    "district_id": "332505",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3325052009",
    "district_id": "332505",
    "label": "Jlamprang",
    "value": "Jlamprang"
  },
  {
    "id": "3325052010",
    "district_id": "332505",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "3325052011",
    "district_id": "332505",
    "label": "Candigugur",
    "value": "Candigugur"
  },
  {
    "id": "3325052012",
    "district_id": "332505",
    "label": "Pangempon",
    "value": "Pangempon"
  },
  {
    "id": "3325052013",
    "district_id": "332505",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3325052014",
    "district_id": "332505",
    "label": "Surjo",
    "value": "Surjo"
  },
  {
    "id": "3325052015",
    "district_id": "332505",
    "label": "Soka",
    "value": "Soka"
  },
  {
    "id": "3325052016",
    "district_id": "332505",
    "label": "Sibebek",
    "value": "Sibebek"
  },
  {
    "id": "3325052017",
    "district_id": "332505",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3325052018",
    "district_id": "332505",
    "label": "Pasusukan",
    "value": "Pasusukan"
  },
  {
    "id": "3325052019",
    "district_id": "332505",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3325052020",
    "district_id": "332505",
    "label": "Purbo",
    "value": "Purbo"
  },
  {
    "id": "3325062002",
    "district_id": "332506",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3325062003",
    "district_id": "332506",
    "label": "Banteng",
    "value": "Banteng"
  },
  {
    "id": "3325062004",
    "district_id": "332506",
    "label": "Sumurbanger",
    "value": "Sumurbanger"
  },
  {
    "id": "3325062005",
    "district_id": "332506",
    "label": "Margosono",
    "value": "Margosono"
  },
  {
    "id": "3325062006",
    "district_id": "332506",
    "label": "Sidalang",
    "value": "Sidalang"
  },
  {
    "id": "3325062007",
    "district_id": "332506",
    "label": "Plosowangi",
    "value": "Plosowangi"
  },
  {
    "id": "3325062008",
    "district_id": "332506",
    "label": "Wanar",
    "value": "Wanar"
  },
  {
    "id": "3325062009",
    "district_id": "332506",
    "label": "Gondo",
    "value": "Gondo"
  },
  {
    "id": "3325062010",
    "district_id": "332506",
    "label": "Rejosari Barat",
    "value": "Rejosari Barat"
  },
  {
    "id": "3325062011",
    "district_id": "332506",
    "label": "Boja",
    "value": "Boja"
  },
  {
    "id": "3325062012",
    "district_id": "332506",
    "label": "Pujut",
    "value": "Pujut"
  },
  {
    "id": "3325062013",
    "district_id": "332506",
    "label": "Tersono",
    "value": "Tersono"
  },
  {
    "id": "3325062014",
    "district_id": "332506",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3325062015",
    "district_id": "332506",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3325062016",
    "district_id": "332506",
    "label": "Harjowinangun Barat",
    "value": "Harjowinangun Barat"
  },
  {
    "id": "3325062017",
    "district_id": "332506",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "3325062020",
    "district_id": "332506",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3325062021",
    "district_id": "332506",
    "label": "Satriyan",
    "value": "Satriyan"
  },
  {
    "id": "3325062022",
    "district_id": "332506",
    "label": "Harjowinangun Timur",
    "value": "Harjowinangun Timur"
  },
  {
    "id": "3325062023",
    "district_id": "332506",
    "label": "Rejosari Timur",
    "value": "Rejosari Timur"
  },
  {
    "id": "3325072005",
    "district_id": "332507",
    "label": "Surodadi",
    "value": "Surodadi"
  },
  {
    "id": "3325072006",
    "district_id": "332507",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3325072007",
    "district_id": "332507",
    "label": "Plelen",
    "value": "Plelen"
  },
  {
    "id": "3325072008",
    "district_id": "332507",
    "label": "Kutosari",
    "value": "Kutosari"
  },
  {
    "id": "3325072009",
    "district_id": "332507",
    "label": "Mentosari",
    "value": "Mentosari"
  },
  {
    "id": "3325072010",
    "district_id": "332507",
    "label": "Gringsing",
    "value": "Gringsing"
  },
  {
    "id": "3325072011",
    "district_id": "332507",
    "label": "Yosorejo",
    "value": "Yosorejo"
  },
  {
    "id": "3325072012",
    "district_id": "332507",
    "label": "Krengseng",
    "value": "Krengseng"
  },
  {
    "id": "3325072013",
    "district_id": "332507",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3325072014",
    "district_id": "332507",
    "label": "Ketanggan",
    "value": "Ketanggan"
  },
  {
    "id": "3325072015",
    "district_id": "332507",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "3325072016",
    "district_id": "332507",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3325072017",
    "district_id": "332507",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3325072018",
    "district_id": "332507",
    "label": "Tedunan",
    "value": "Tedunan"
  },
  {
    "id": "3325072019",
    "district_id": "332507",
    "label": "Madugowongjati",
    "value": "Madugowongjati"
  },
  {
    "id": "3325082001",
    "district_id": "332508",
    "label": "Ngaliyan",
    "value": "Ngaliyan"
  },
  {
    "id": "3325082002",
    "district_id": "332508",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3325082003",
    "district_id": "332508",
    "label": "Tembok",
    "value": "Tembok"
  },
  {
    "id": "3325082004",
    "district_id": "332508",
    "label": "Donorejo",
    "value": "Donorejo"
  },
  {
    "id": "3325082005",
    "district_id": "332508",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3325082006",
    "district_id": "332508",
    "label": "Kalisalak",
    "value": "Kalisalak"
  },
  {
    "id": "3325082007",
    "district_id": "332508",
    "label": "Limpung",
    "value": "Limpung"
  },
  {
    "id": "3325082008",
    "district_id": "332508",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3325082009",
    "district_id": "332508",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3325082010",
    "district_id": "332508",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3325082011",
    "district_id": "332508",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3325082012",
    "district_id": "332508",
    "label": "Amongrogo",
    "value": "Amongrogo"
  },
  {
    "id": "3325082013",
    "district_id": "332508",
    "label": "Dlisen",
    "value": "Dlisen"
  },
  {
    "id": "3325082015",
    "district_id": "332508",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3325082016",
    "district_id": "332508",
    "label": "Pungangan",
    "value": "Pungangan"
  },
  {
    "id": "3325082023",
    "district_id": "332508",
    "label": "Lobang",
    "value": "Lobang"
  },
  {
    "id": "3325082024",
    "district_id": "332508",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3325092004",
    "district_id": "332509",
    "label": "Menjangan",
    "value": "Menjangan"
  },
  {
    "id": "3325092005",
    "district_id": "332509",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3325092006",
    "district_id": "332509",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3325092007",
    "district_id": "332509",
    "label": "Tenggulangharjo",
    "value": "Tenggulangharjo"
  },
  {
    "id": "3325092008",
    "district_id": "332509",
    "label": "Kalimanggis",
    "value": "Kalimanggis"
  },
  {
    "id": "3325092009",
    "district_id": "332509",
    "label": "Keborangan",
    "value": "Keborangan"
  },
  {
    "id": "3325092010",
    "district_id": "332509",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3325092011",
    "district_id": "332509",
    "label": "Subah",
    "value": "Subah"
  },
  {
    "id": "3325092012",
    "district_id": "332509",
    "label": "Kumejing",
    "value": "Kumejing"
  },
  {
    "id": "3325092014",
    "district_id": "332509",
    "label": "Durenombo",
    "value": "Durenombo"
  },
  {
    "id": "3325092019",
    "district_id": "332509",
    "label": "Clapar",
    "value": "Clapar"
  },
  {
    "id": "3325092020",
    "district_id": "332509",
    "label": "Adinuso",
    "value": "Adinuso"
  },
  {
    "id": "3325092021",
    "district_id": "332509",
    "label": "Sengon",
    "value": "Sengon"
  },
  {
    "id": "3325092023",
    "district_id": "332509",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3325092024",
    "district_id": "332509",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3325092025",
    "district_id": "332509",
    "label": "Kemiri Barat",
    "value": "Kemiri Barat"
  },
  {
    "id": "3325092026",
    "district_id": "332509",
    "label": "Kemiri Timur",
    "value": "Kemiri Timur"
  },
  {
    "id": "3325102001",
    "district_id": "332510",
    "label": "Wringingintung",
    "value": "Wringingintung"
  },
  {
    "id": "3325102002",
    "district_id": "332510",
    "label": "Sembojo",
    "value": "Sembojo"
  },
  {
    "id": "3325102003",
    "district_id": "332510",
    "label": "Posong",
    "value": "Posong"
  },
  {
    "id": "3325102004",
    "district_id": "332510",
    "label": "Kaliboyo",
    "value": "Kaliboyo"
  },
  {
    "id": "3325102005",
    "district_id": "332510",
    "label": "Simbangdesa",
    "value": "Simbangdesa"
  },
  {
    "id": "3325102006",
    "district_id": "332510",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3325102007",
    "district_id": "332510",
    "label": "Tulis",
    "value": "Tulis"
  },
  {
    "id": "3325102017",
    "district_id": "332510",
    "label": "Simbangjati",
    "value": "Simbangjati"
  },
  {
    "id": "3325102018",
    "district_id": "332510",
    "label": "Kedungsegog",
    "value": "Kedungsegog"
  },
  {
    "id": "3325102019",
    "district_id": "332510",
    "label": "Kenconorejo",
    "value": "Kenconorejo"
  },
  {
    "id": "3325102020",
    "district_id": "332510",
    "label": "Ponowareng",
    "value": "Ponowareng"
  },
  {
    "id": "3325102024",
    "district_id": "332510",
    "label": "Siberuk",
    "value": "Siberuk"
  },
  {
    "id": "3325102025",
    "district_id": "332510",
    "label": "Kebumen",
    "value": "Kebumen"
  },
  {
    "id": "3325102026",
    "district_id": "332510",
    "label": "Cluwuk",
    "value": "Cluwuk"
  },
  {
    "id": "3325102027",
    "district_id": "332510",
    "label": "Jrakahpayung",
    "value": "Jrakahpayung"
  },
  {
    "id": "3325102028",
    "district_id": "332510",
    "label": "Jolosekti",
    "value": "Jolosekti"
  },
  {
    "id": "3325102029",
    "district_id": "332510",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3325111013",
    "district_id": "332511",
    "label": "Watesalit",
    "value": "Watesalit"
  },
  {
    "id": "3325111014",
    "district_id": "332511",
    "label": "Proyonanggan Tengah",
    "value": "Proyonanggan Tengah"
  },
  {
    "id": "3325111015",
    "district_id": "332511",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3325111016",
    "district_id": "332511",
    "label": "Karangasem Utara",
    "value": "Karangasem Utara"
  },
  {
    "id": "3325111017",
    "district_id": "332511",
    "label": "Kasepuhan",
    "value": "Kasepuhan"
  },
  {
    "id": "3325111018",
    "district_id": "332511",
    "label": "Sambong",
    "value": "Sambong"
  },
  {
    "id": "3325111019",
    "district_id": "332511",
    "label": "Proyonanggan Utara",
    "value": "Proyonanggan Utara"
  },
  {
    "id": "3325111020",
    "district_id": "332511",
    "label": "Proyonanggan Selatan",
    "value": "Proyonanggan Selatan"
  },
  {
    "id": "3325111021",
    "district_id": "332511",
    "label": "Karangasem Selatan",
    "value": "Karangasem Selatan"
  },
  {
    "id": "3325112001",
    "district_id": "332511",
    "label": "Rowobelang",
    "value": "Rowobelang"
  },
  {
    "id": "3325112002",
    "district_id": "332511",
    "label": "Cepokokuning",
    "value": "Cepokokuning"
  },
  {
    "id": "3325112003",
    "district_id": "332511",
    "label": "Pasekaran",
    "value": "Pasekaran"
  },
  {
    "id": "3325112004",
    "district_id": "332511",
    "label": "Kalisalak",
    "value": "Kalisalak"
  },
  {
    "id": "3325112005",
    "district_id": "332511",
    "label": "Kecepak",
    "value": "Kecepak"
  },
  {
    "id": "3325112006",
    "district_id": "332511",
    "label": "Klidang Wetan",
    "value": "Klidang Wetan"
  },
  {
    "id": "3325112007",
    "district_id": "332511",
    "label": "Klidang Lor",
    "value": "Klidang Lor"
  },
  {
    "id": "3325112008",
    "district_id": "332511",
    "label": "Kalipucang Wetan",
    "value": "Kalipucang Wetan"
  },
  {
    "id": "3325112009",
    "district_id": "332511",
    "label": "Kalipucang Kulon",
    "value": "Kalipucang Kulon"
  },
  {
    "id": "3325112010",
    "district_id": "332511",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3325112011",
    "district_id": "332511",
    "label": "Denasri Kulon",
    "value": "Denasri Kulon"
  },
  {
    "id": "3325112012",
    "district_id": "332511",
    "label": "Denasri Wetan",
    "value": "Denasri Wetan"
  },
  {
    "id": "3325122001",
    "district_id": "332512",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3325122002",
    "district_id": "332512",
    "label": "Kaliwareng",
    "value": "Kaliwareng"
  },
  {
    "id": "3325122003",
    "district_id": "332512",
    "label": "Pejambon",
    "value": "Pejambon"
  },
  {
    "id": "3325122004",
    "district_id": "332512",
    "label": "Sariglagah",
    "value": "Sariglagah"
  },
  {
    "id": "3325122005",
    "district_id": "332512",
    "label": "Pesaren",
    "value": "Pesaren"
  },
  {
    "id": "3325122006",
    "district_id": "332512",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3325122007",
    "district_id": "332512",
    "label": "Cepagan",
    "value": "Cepagan"
  },
  {
    "id": "3325122008",
    "district_id": "332512",
    "label": "Masin",
    "value": "Masin"
  },
  {
    "id": "3325122009",
    "district_id": "332512",
    "label": "Banjiran",
    "value": "Banjiran"
  },
  {
    "id": "3325122010",
    "district_id": "332512",
    "label": "Warungasem",
    "value": "Warungasem"
  },
  {
    "id": "3325122011",
    "district_id": "332512",
    "label": "Gapuro",
    "value": "Gapuro"
  },
  {
    "id": "3325122012",
    "district_id": "332512",
    "label": "Kalibeluk",
    "value": "Kalibeluk"
  },
  {
    "id": "3325122013",
    "district_id": "332512",
    "label": "Sawahjoho",
    "value": "Sawahjoho"
  },
  {
    "id": "3325122014",
    "district_id": "332512",
    "label": "Candiareng",
    "value": "Candiareng"
  },
  {
    "id": "3325122015",
    "district_id": "332512",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "3325122016",
    "district_id": "332512",
    "label": "Menguneng",
    "value": "Menguneng"
  },
  {
    "id": "3325122017",
    "district_id": "332512",
    "label": "Terban",
    "value": "Terban"
  },
  {
    "id": "3325122018",
    "district_id": "332512",
    "label": "Sijono",
    "value": "Sijono"
  },
  {
    "id": "3325132001",
    "district_id": "332513",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3325132002",
    "district_id": "332513",
    "label": "Kandeman",
    "value": "Kandeman"
  },
  {
    "id": "3325132003",
    "district_id": "332513",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3325132004",
    "district_id": "332513",
    "label": "Lawangaji",
    "value": "Lawangaji"
  },
  {
    "id": "3325132005",
    "district_id": "332513",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3325132006",
    "district_id": "332513",
    "label": "Tragung",
    "value": "Tragung"
  },
  {
    "id": "3325132007",
    "district_id": "332513",
    "label": "Cempereng",
    "value": "Cempereng"
  },
  {
    "id": "3325132008",
    "district_id": "332513",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3325132009",
    "district_id": "332513",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3325132010",
    "district_id": "332513",
    "label": "Ujungnegoro",
    "value": "Ujungnegoro"
  },
  {
    "id": "3325132011",
    "district_id": "332513",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "3325132012",
    "district_id": "332513",
    "label": "Juragan",
    "value": "Juragan"
  },
  {
    "id": "3325132013",
    "district_id": "332513",
    "label": "Botolambat",
    "value": "Botolambat"
  },
  {
    "id": "3325142001",
    "district_id": "332514",
    "label": "Pecalungan",
    "value": "Pecalungan"
  },
  {
    "id": "3325142002",
    "district_id": "332514",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3325142003",
    "district_id": "332514",
    "label": "Gombong",
    "value": "Gombong"
  },
  {
    "id": "3325142004",
    "district_id": "332514",
    "label": "Randu",
    "value": "Randu"
  },
  {
    "id": "3325142005",
    "district_id": "332514",
    "label": "Siguci",
    "value": "Siguci"
  },
  {
    "id": "3325142006",
    "district_id": "332514",
    "label": "Pretek",
    "value": "Pretek"
  },
  {
    "id": "3325142007",
    "district_id": "332514",
    "label": "Selokarto",
    "value": "Selokarto"
  },
  {
    "id": "3325142008",
    "district_id": "332514",
    "label": "Gemuh",
    "value": "Gemuh"
  },
  {
    "id": "3325142009",
    "district_id": "332514",
    "label": "Gumawang",
    "value": "Gumawang"
  },
  {
    "id": "3325142010",
    "district_id": "332514",
    "label": "Keniten",
    "value": "Keniten"
  },
  {
    "id": "3325152001",
    "district_id": "332515",
    "label": "Banyuputih",
    "value": "Banyuputih"
  },
  {
    "id": "3325152002",
    "district_id": "332515",
    "label": "Kalibalik",
    "value": "Kalibalik"
  },
  {
    "id": "3325152003",
    "district_id": "332515",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3325152004",
    "district_id": "332515",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3325152005",
    "district_id": "332515",
    "label": "Dlimas",
    "value": "Dlimas"
  },
  {
    "id": "3325152006",
    "district_id": "332515",
    "label": "Luwung",
    "value": "Luwung"
  },
  {
    "id": "3325152008",
    "district_id": "332515",
    "label": "Penundan",
    "value": "Penundan"
  },
  {
    "id": "3325152009",
    "district_id": "332515",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3325152010",
    "district_id": "332515",
    "label": "Timbang",
    "value": "Timbang"
  },
  {
    "id": "3325152011",
    "district_id": "332515",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3325152012",
    "district_id": "332515",
    "label": "Kalangsono",
    "value": "Kalangsono"
  },
  {
    "id": "3326012001",
    "district_id": "332601",
    "label": "Klesem",
    "value": "Klesem"
  },
  {
    "id": "3326012002",
    "district_id": "332601",
    "label": "Bodas",
    "value": "Bodas"
  },
  {
    "id": "3326012003",
    "district_id": "332601",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3326012004",
    "district_id": "332601",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "3326012005",
    "district_id": "332601",
    "label": "Garungwiyoro",
    "value": "Garungwiyoro"
  },
  {
    "id": "3326012006",
    "district_id": "332601",
    "label": "Bojongkoneng",
    "value": "Bojongkoneng"
  },
  {
    "id": "3326012007",
    "district_id": "332601",
    "label": "Bubak",
    "value": "Bubak"
  },
  {
    "id": "3326012008",
    "district_id": "332601",
    "label": "Wangkelang",
    "value": "Wangkelang"
  },
  {
    "id": "3326012009",
    "district_id": "332601",
    "label": "Kandangserang",
    "value": "Kandangserang"
  },
  {
    "id": "3326012010",
    "district_id": "332601",
    "label": "Luragung",
    "value": "Luragung"
  },
  {
    "id": "3326012011",
    "district_id": "332601",
    "label": "Lambur",
    "value": "Lambur"
  },
  {
    "id": "3326012012",
    "district_id": "332601",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "3326012013",
    "district_id": "332601",
    "label": "Trajumas",
    "value": "Trajumas"
  },
  {
    "id": "3326012014",
    "district_id": "332601",
    "label": "Karanggondang",
    "value": "Karanggondang"
  },
  {
    "id": "3326022001",
    "district_id": "332602",
    "label": "Werdi",
    "value": "Werdi"
  },
  {
    "id": "3326022002",
    "district_id": "332602",
    "label": "Winduaji",
    "value": "Winduaji"
  },
  {
    "id": "3326022003",
    "district_id": "332602",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3326022004",
    "district_id": "332602",
    "label": "Lumeneng",
    "value": "Lumeneng"
  },
  {
    "id": "3326022005",
    "district_id": "332602",
    "label": "Tangeran",
    "value": "Tangeran"
  },
  {
    "id": "3326022006",
    "district_id": "332602",
    "label": "Kaliboja",
    "value": "Kaliboja"
  },
  {
    "id": "3326022007",
    "district_id": "332602",
    "label": "Kaliombo",
    "value": "Kaliombo"
  },
  {
    "id": "3326022008",
    "district_id": "332602",
    "label": "Botosari",
    "value": "Botosari"
  },
  {
    "id": "3326022009",
    "district_id": "332602",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3326022010",
    "district_id": "332602",
    "label": "Paninggaran",
    "value": "Paninggaran"
  },
  {
    "id": "3326022011",
    "district_id": "332602",
    "label": "Domiyang",
    "value": "Domiyang"
  },
  {
    "id": "3326022012",
    "district_id": "332602",
    "label": "Notogiwang",
    "value": "Notogiwang"
  },
  {
    "id": "3326022013",
    "district_id": "332602",
    "label": "Lambanggelun",
    "value": "Lambanggelun"
  },
  {
    "id": "3326022014",
    "district_id": "332602",
    "label": "Tenogo",
    "value": "Tenogo"
  },
  {
    "id": "3326022015",
    "district_id": "332602",
    "label": "Bedagung",
    "value": "Bedagung"
  },
  {
    "id": "3326032001",
    "district_id": "332603",
    "label": "Tembelangunung",
    "value": "Tembelangunung"
  },
  {
    "id": "3326032002",
    "district_id": "332603",
    "label": "Pamutuh",
    "value": "Pamutuh"
  },
  {
    "id": "3326032003",
    "district_id": "332603",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3326032004",
    "district_id": "332603",
    "label": "Wonosido",
    "value": "Wonosido"
  },
  {
    "id": "3326032005",
    "district_id": "332603",
    "label": "Timbangsari",
    "value": "Timbangsari"
  },
  {
    "id": "3326032006",
    "district_id": "332603",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3326032007",
    "district_id": "332603",
    "label": "Kutorembet",
    "value": "Kutorembet"
  },
  {
    "id": "3326032008",
    "district_id": "332603",
    "label": "Lebakbarang",
    "value": "Lebakbarang"
  },
  {
    "id": "3326032009",
    "district_id": "332603",
    "label": "Kapundutan",
    "value": "Kapundutan"
  },
  {
    "id": "3326032010",
    "district_id": "332603",
    "label": "Bantarkulon",
    "value": "Bantarkulon"
  },
  {
    "id": "3326032011",
    "district_id": "332603",
    "label": "Mendolo",
    "value": "Mendolo"
  },
  {
    "id": "3326042001",
    "district_id": "332604",
    "label": "Simego",
    "value": "Simego"
  },
  {
    "id": "3326042002",
    "district_id": "332604",
    "label": "Gumelem",
    "value": "Gumelem"
  },
  {
    "id": "3326042003",
    "district_id": "332604",
    "label": "Tlogohendro",
    "value": "Tlogohendro"
  },
  {
    "id": "3326042004",
    "district_id": "332604",
    "label": "Yosorejo",
    "value": "Yosorejo"
  },
  {
    "id": "3326042005",
    "district_id": "332604",
    "label": "Curugmuncar",
    "value": "Curugmuncar"
  },
  {
    "id": "3326042006",
    "district_id": "332604",
    "label": "Songgodadi",
    "value": "Songgodadi"
  },
  {
    "id": "3326042007",
    "district_id": "332604",
    "label": "Tlogopakis",
    "value": "Tlogopakis"
  },
  {
    "id": "3326042008",
    "district_id": "332604",
    "label": "Kasimpar",
    "value": "Kasimpar"
  },
  {
    "id": "3326042009",
    "district_id": "332604",
    "label": "Kayupuring",
    "value": "Kayupuring"
  },
  {
    "id": "3326052001",
    "district_id": "332605",
    "label": "Mesoyi",
    "value": "Mesoyi"
  },
  {
    "id": "3326052002",
    "district_id": "332605",
    "label": "Jolotigo",
    "value": "Jolotigo"
  },
  {
    "id": "3326052003",
    "district_id": "332605",
    "label": "Sengare",
    "value": "Sengare"
  },
  {
    "id": "3326052004",
    "district_id": "332605",
    "label": "Donowangun",
    "value": "Donowangun"
  },
  {
    "id": "3326052005",
    "district_id": "332605",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3326052006",
    "district_id": "332605",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3326052007",
    "district_id": "332605",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3326052008",
    "district_id": "332605",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3326052009",
    "district_id": "332605",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3326052010",
    "district_id": "332605",
    "label": "Krompeng",
    "value": "Krompeng"
  },
  {
    "id": "3326062001",
    "district_id": "332606",
    "label": "Pungangan",
    "value": "Pungangan"
  },
  {
    "id": "3326062002",
    "district_id": "332606",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3326062003",
    "district_id": "332606",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "3326062004",
    "district_id": "332606",
    "label": "Rogoselo",
    "value": "Rogoselo"
  },
  {
    "id": "3326062005",
    "district_id": "332606",
    "label": "Harjosari",
    "value": "Harjosari"
  },
  {
    "id": "3326062006",
    "district_id": "332606",
    "label": "Larikan",
    "value": "Larikan"
  },
  {
    "id": "3326062007",
    "district_id": "332606",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "3326062008",
    "district_id": "332606",
    "label": "Dororejo",
    "value": "Dororejo"
  },
  {
    "id": "3326062009",
    "district_id": "332606",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3326062010",
    "district_id": "332606",
    "label": "Kutosari",
    "value": "Kutosari"
  },
  {
    "id": "3326062011",
    "district_id": "332606",
    "label": "Wringinagung",
    "value": "Wringinagung"
  },
  {
    "id": "3326062012",
    "district_id": "332606",
    "label": "Kalimojosari",
    "value": "Kalimojosari"
  },
  {
    "id": "3326062013",
    "district_id": "332606",
    "label": "Bligorejo",
    "value": "Bligorejo"
  },
  {
    "id": "3326062014",
    "district_id": "332606",
    "label": "Doro",
    "value": "Doro"
  },
  {
    "id": "3326072001",
    "district_id": "332607",
    "label": "Gutomo",
    "value": "Gutomo"
  },
  {
    "id": "3326072002",
    "district_id": "332607",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3326072003",
    "district_id": "332607",
    "label": "Karanggondang",
    "value": "Karanggondang"
  },
  {
    "id": "3326072004",
    "district_id": "332607",
    "label": "Lolong",
    "value": "Lolong"
  },
  {
    "id": "3326072005",
    "district_id": "332607",
    "label": "Pedawang",
    "value": "Pedawang"
  },
  {
    "id": "3326072006",
    "district_id": "332607",
    "label": "Pododadi",
    "value": "Pododadi"
  },
  {
    "id": "3326072007",
    "district_id": "332607",
    "label": "Legokalong",
    "value": "Legokalong"
  },
  {
    "id": "3326072008",
    "district_id": "332607",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3326072009",
    "district_id": "332607",
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "id": "3326072010",
    "district_id": "332607",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3326072011",
    "district_id": "332607",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3326072012",
    "district_id": "332607",
    "label": "Sokosari",
    "value": "Sokosari"
  },
  {
    "id": "3326072013",
    "district_id": "332607",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3326072014",
    "district_id": "332607",
    "label": "Kayugeritan",
    "value": "Kayugeritan"
  },
  {
    "id": "3326072015",
    "district_id": "332607",
    "label": "Kutosari",
    "value": "Kutosari"
  },
  {
    "id": "3326081016",
    "district_id": "332608",
    "label": "Kajen",
    "value": "Kajen"
  },
  {
    "id": "3326082001",
    "district_id": "332608",
    "label": "Tambakroto",
    "value": "Tambakroto"
  },
  {
    "id": "3326082002",
    "district_id": "332608",
    "label": "Kutorojo",
    "value": "Kutorojo"
  },
  {
    "id": "3326082003",
    "district_id": "332608",
    "label": "Linggoasri",
    "value": "Linggoasri"
  },
  {
    "id": "3326082004",
    "district_id": "332608",
    "label": "Brengkolang",
    "value": "Brengkolang"
  },
  {
    "id": "3326082005",
    "district_id": "332608",
    "label": "Pringsurat",
    "value": "Pringsurat"
  },
  {
    "id": "3326082006",
    "district_id": "332608",
    "label": "Sokoyoso",
    "value": "Sokoyoso"
  },
  {
    "id": "3326082007",
    "district_id": "332608",
    "label": "Sinangohprendeng",
    "value": "Sinangohprendeng"
  },
  {
    "id": "3326082008",
    "district_id": "332608",
    "label": "Kajongan",
    "value": "Kajongan"
  },
  {
    "id": "3326082009",
    "district_id": "332608",
    "label": "Pekiringanageng",
    "value": "Pekiringanageng"
  },
  {
    "id": "3326082010",
    "district_id": "332608",
    "label": "Gandarum",
    "value": "Gandarum"
  },
  {
    "id": "3326082011",
    "district_id": "332608",
    "label": "Sabarwangi",
    "value": "Sabarwangi"
  },
  {
    "id": "3326082012",
    "district_id": "332608",
    "label": "Kalijoyo",
    "value": "Kalijoyo"
  },
  {
    "id": "3326082013",
    "district_id": "332608",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3326082014",
    "district_id": "332608",
    "label": "Pekiringanalit",
    "value": "Pekiringanalit"
  },
  {
    "id": "3326082015",
    "district_id": "332608",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "3326082017",
    "district_id": "332608",
    "label": "Nyamok",
    "value": "Nyamok"
  },
  {
    "id": "3326082018",
    "district_id": "332608",
    "label": "Tanjungkulon",
    "value": "Tanjungkulon"
  },
  {
    "id": "3326082019",
    "district_id": "332608",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3326082020",
    "district_id": "332608",
    "label": "Gejlig",
    "value": "Gejlig"
  },
  {
    "id": "3326082021",
    "district_id": "332608",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3326082022",
    "district_id": "332608",
    "label": "Sangkanjoyo",
    "value": "Sangkanjoyo"
  },
  {
    "id": "3326082023",
    "district_id": "332608",
    "label": "Salit",
    "value": "Salit"
  },
  {
    "id": "3326082024",
    "district_id": "332608",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3326082025",
    "district_id": "332608",
    "label": "Rowolaku",
    "value": "Rowolaku"
  },
  {
    "id": "3326092001",
    "district_id": "332609",
    "label": "Windurojo",
    "value": "Windurojo"
  },
  {
    "id": "3326092002",
    "district_id": "332609",
    "label": "Ujungnegoro",
    "value": "Ujungnegoro"
  },
  {
    "id": "3326092003",
    "district_id": "332609",
    "label": "Brondong",
    "value": "Brondong"
  },
  {
    "id": "3326092004",
    "district_id": "332609",
    "label": "Podosari",
    "value": "Podosari"
  },
  {
    "id": "3326092005",
    "district_id": "332609",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3326092006",
    "district_id": "332609",
    "label": "Jagung",
    "value": "Jagung"
  },
  {
    "id": "3326092007",
    "district_id": "332609",
    "label": "Kwasen",
    "value": "Kwasen"
  },
  {
    "id": "3326092008",
    "district_id": "332609",
    "label": "Karyomukti",
    "value": "Karyomukti"
  },
  {
    "id": "3326092009",
    "district_id": "332609",
    "label": "Kesesi",
    "value": "Kesesi"
  },
  {
    "id": "3326092010",
    "district_id": "332609",
    "label": "Srinahan",
    "value": "Srinahan"
  },
  {
    "id": "3326092011",
    "district_id": "332609",
    "label": "Kaibahan",
    "value": "Kaibahan"
  },
  {
    "id": "3326092012",
    "district_id": "332609",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3326092013",
    "district_id": "332609",
    "label": "Watugajah",
    "value": "Watugajah"
  },
  {
    "id": "3326092014",
    "district_id": "332609",
    "label": "Watupayung",
    "value": "Watupayung"
  },
  {
    "id": "3326092015",
    "district_id": "332609",
    "label": "Krandon",
    "value": "Krandon"
  },
  {
    "id": "3326092016",
    "district_id": "332609",
    "label": "Ponolawen",
    "value": "Ponolawen"
  },
  {
    "id": "3326092017",
    "district_id": "332609",
    "label": "Kalimade",
    "value": "Kalimade"
  },
  {
    "id": "3326092018",
    "district_id": "332609",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3326092019",
    "district_id": "332609",
    "label": "Sidosari",
    "value": "Sidosari"
  },
  {
    "id": "3326092020",
    "district_id": "332609",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3326092021",
    "district_id": "332609",
    "label": "Pantirejo",
    "value": "Pantirejo"
  },
  {
    "id": "3326092022",
    "district_id": "332609",
    "label": "Kwigaran",
    "value": "Kwigaran"
  },
  {
    "id": "3326092023",
    "district_id": "332609",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3326101017",
    "district_id": "332610",
    "label": "Sragi",
    "value": "Sragi"
  },
  {
    "id": "3326102001",
    "district_id": "332610",
    "label": "Ketanonageng",
    "value": "Ketanonageng"
  },
  {
    "id": "3326102002",
    "district_id": "332610",
    "label": "Mrican",
    "value": "Mrican"
  },
  {
    "id": "3326102003",
    "district_id": "332610",
    "label": "Bulaksari",
    "value": "Bulaksari"
  },
  {
    "id": "3326102004",
    "district_id": "332610",
    "label": "Sumubkidul",
    "value": "Sumubkidul"
  },
  {
    "id": "3326102005",
    "district_id": "332610",
    "label": "Kalijambe",
    "value": "Kalijambe"
  },
  {
    "id": "3326102006",
    "district_id": "332610",
    "label": "Sumublor",
    "value": "Sumublor"
  },
  {
    "id": "3326102007",
    "district_id": "332610",
    "label": "Krsakageng",
    "value": "Krsakageng"
  },
  {
    "id": "3326102008",
    "district_id": "332610",
    "label": "Sijeruk",
    "value": "Sijeruk"
  },
  {
    "id": "3326102009",
    "district_id": "332610",
    "label": "Tegalsuruh",
    "value": "Tegalsuruh"
  },
  {
    "id": "3326102010",
    "district_id": "332610",
    "label": "Bulakpelem",
    "value": "Bulakpelem"
  },
  {
    "id": "3326102011",
    "district_id": "332610",
    "label": "Gebangkerep",
    "value": "Gebangkerep"
  },
  {
    "id": "3326102012",
    "district_id": "332610",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3326102013",
    "district_id": "332610",
    "label": "Kedungjaran",
    "value": "Kedungjaran"
  },
  {
    "id": "3326102014",
    "district_id": "332610",
    "label": "Klunjukan",
    "value": "Klunjukan"
  },
  {
    "id": "3326102015",
    "district_id": "332610",
    "label": "Tegalontar",
    "value": "Tegalontar"
  },
  {
    "id": "3326102016",
    "district_id": "332610",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3326112001",
    "district_id": "332611",
    "label": "Bukur",
    "value": "Bukur"
  },
  {
    "id": "3326112002",
    "district_id": "332611",
    "label": "Kalipancur",
    "value": "Kalipancur"
  },
  {
    "id": "3326112003",
    "district_id": "332611",
    "label": "Sumurjomblangbogo",
    "value": "Sumurjomblangbogo"
  },
  {
    "id": "3326112004",
    "district_id": "332611",
    "label": "Pantianom",
    "value": "Pantianom"
  },
  {
    "id": "3326112005",
    "district_id": "332611",
    "label": "Randumuktiwaren",
    "value": "Randumuktiwaren"
  },
  {
    "id": "3326112006",
    "district_id": "332611",
    "label": "Legokclile",
    "value": "Legokclile"
  },
  {
    "id": "3326112007",
    "district_id": "332611",
    "label": "Bojongwetan",
    "value": "Bojongwetan"
  },
  {
    "id": "3326112008",
    "district_id": "332611",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3326112009",
    "district_id": "332611",
    "label": "Wangandowo",
    "value": "Wangandowo"
  },
  {
    "id": "3326112010",
    "district_id": "332611",
    "label": "Ketitangkidul",
    "value": "Ketitangkidul"
  },
  {
    "id": "3326112011",
    "district_id": "332611",
    "label": "Menjangan",
    "value": "Menjangan"
  },
  {
    "id": "3326112012",
    "district_id": "332611",
    "label": "Ketitanglor",
    "value": "Ketitanglor"
  },
  {
    "id": "3326112013",
    "district_id": "332611",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3326112014",
    "district_id": "332611",
    "label": "Bojonglor",
    "value": "Bojonglor"
  },
  {
    "id": "3326112015",
    "district_id": "332611",
    "label": "Bojongminggir",
    "value": "Bojongminggir"
  },
  {
    "id": "3326112016",
    "district_id": "332611",
    "label": "Wiroditan",
    "value": "Wiroditan"
  },
  {
    "id": "3326112017",
    "district_id": "332611",
    "label": "Kemasan",
    "value": "Kemasan"
  },
  {
    "id": "3326112018",
    "district_id": "332611",
    "label": "Jajarwayang",
    "value": "Jajarwayang"
  },
  {
    "id": "3326112019",
    "district_id": "332611",
    "label": "Babalanlor",
    "value": "Babalanlor"
  },
  {
    "id": "3326112020",
    "district_id": "332611",
    "label": "Babalankidul",
    "value": "Babalankidul"
  },
  {
    "id": "3326112021",
    "district_id": "332611",
    "label": "Karagsari",
    "value": "Karagsari"
  },
  {
    "id": "3326112022",
    "district_id": "332611",
    "label": "Sembungjambu",
    "value": "Sembungjambu"
  },
  {
    "id": "3326122001",
    "district_id": "332612",
    "label": "Jetakkidul",
    "value": "Jetakkidul"
  },
  {
    "id": "3326122002",
    "district_id": "332612",
    "label": "Sastrodirjan",
    "value": "Sastrodirjan"
  },
  {
    "id": "3326122003",
    "district_id": "332612",
    "label": "Legokgunung",
    "value": "Legokgunung"
  },
  {
    "id": "3326122004",
    "district_id": "332612",
    "label": "Galangpengampon",
    "value": "Galangpengampon"
  },
  {
    "id": "3326122005",
    "district_id": "332612",
    "label": "Kwagean",
    "value": "Kwagean"
  },
  {
    "id": "3326122006",
    "district_id": "332612",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3326122007",
    "district_id": "332612",
    "label": "Rowokembu",
    "value": "Rowokembu"
  },
  {
    "id": "3326122008",
    "district_id": "332612",
    "label": "Wonopringgo",
    "value": "Wonopringgo"
  },
  {
    "id": "3326122009",
    "district_id": "332612",
    "label": "Sampih",
    "value": "Sampih"
  },
  {
    "id": "3326122010",
    "district_id": "332612",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3326122011",
    "district_id": "332612",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3326122012",
    "district_id": "332612",
    "label": "Jetaklengkong",
    "value": "Jetaklengkong"
  },
  {
    "id": "3326122013",
    "district_id": "332612",
    "label": "Pengadentengah",
    "value": "Pengadentengah"
  },
  {
    "id": "3326122014",
    "district_id": "332612",
    "label": "Surobayan",
    "value": "Surobayan"
  },
  {
    "id": "3326131013",
    "district_id": "332613",
    "label": "Pekajangan",
    "value": "Pekajangan"
  },
  {
    "id": "3326131019",
    "district_id": "332613",
    "label": "Kedungwuni Timur",
    "value": "Kedungwuni Timur"
  },
  {
    "id": "3326131020",
    "district_id": "332613",
    "label": "Kedungwuni Barat",
    "value": "Kedungwuni Barat"
  },
  {
    "id": "3326132001",
    "district_id": "332613",
    "label": "Kedungpatangewu",
    "value": "Kedungpatangewu"
  },
  {
    "id": "3326132002",
    "district_id": "332613",
    "label": "Pakisputih",
    "value": "Pakisputih"
  },
  {
    "id": "3326132003",
    "district_id": "332613",
    "label": "Rowocacing",
    "value": "Rowocacing"
  },
  {
    "id": "3326132004",
    "district_id": "332613",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "3326132005",
    "district_id": "332613",
    "label": "Tosaran",
    "value": "Tosaran"
  },
  {
    "id": "3326132006",
    "district_id": "332613",
    "label": "Pajomblangan",
    "value": "Pajomblangan"
  },
  {
    "id": "3326132007",
    "district_id": "332613",
    "label": "Proto",
    "value": "Proto"
  },
  {
    "id": "3326132008",
    "district_id": "332613",
    "label": "Kwayangan",
    "value": "Kwayangan"
  },
  {
    "id": "3326132010",
    "district_id": "332613",
    "label": "Podo",
    "value": "Podo"
  },
  {
    "id": "3326132011",
    "district_id": "332613",
    "label": "Salakbrojo",
    "value": "Salakbrojo"
  },
  {
    "id": "3326132012",
    "district_id": "332613",
    "label": "Ambokembang",
    "value": "Ambokembang"
  },
  {
    "id": "3326132014",
    "district_id": "332613",
    "label": "Tangkiltengah",
    "value": "Tangkiltengah"
  },
  {
    "id": "3326132015",
    "district_id": "332613",
    "label": "Karangdowo",
    "value": "Karangdowo"
  },
  {
    "id": "3326132016",
    "district_id": "332613",
    "label": "Bugangan",
    "value": "Bugangan"
  },
  {
    "id": "3326132017",
    "district_id": "332613",
    "label": "Rengas",
    "value": "Rengas"
  },
  {
    "id": "3326132018",
    "district_id": "332613",
    "label": "Tangkilkulon",
    "value": "Tangkilkulon"
  },
  {
    "id": "3326141002",
    "district_id": "332614",
    "label": "Sapugarut",
    "value": "Sapugarut"
  },
  {
    "id": "3326141004",
    "district_id": "332614",
    "label": "Bligo",
    "value": "Bligo"
  },
  {
    "id": "3326141008",
    "district_id": "332614",
    "label": "Simbangkulon",
    "value": "Simbangkulon"
  },
  {
    "id": "3326142001",
    "district_id": "332614",
    "label": "Coprayan",
    "value": "Coprayan"
  },
  {
    "id": "3326142003",
    "district_id": "332614",
    "label": "Wonoyoso",
    "value": "Wonoyoso"
  },
  {
    "id": "3326142005",
    "district_id": "332614",
    "label": "Pakumbulan",
    "value": "Pakumbulan"
  },
  {
    "id": "3326142006",
    "district_id": "332614",
    "label": "Watusalam",
    "value": "Watusalam"
  },
  {
    "id": "3326142007",
    "district_id": "332614",
    "label": "Simbangwetan",
    "value": "Simbangwetan"
  },
  {
    "id": "3326142009",
    "district_id": "332614",
    "label": "Kertijayan",
    "value": "Kertijayan"
  },
  {
    "id": "3326142010",
    "district_id": "332614",
    "label": "Paweden",
    "value": "Paweden"
  },
  {
    "id": "3326152001",
    "district_id": "332615",
    "label": "Wuled",
    "value": "Wuled"
  },
  {
    "id": "3326152002",
    "district_id": "332615",
    "label": "Ngalian",
    "value": "Ngalian"
  },
  {
    "id": "3326152003",
    "district_id": "332615",
    "label": "Pandanarum",
    "value": "Pandanarum"
  },
  {
    "id": "3326152004",
    "district_id": "332615",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3326152005",
    "district_id": "332615",
    "label": "Silirejo",
    "value": "Silirejo"
  },
  {
    "id": "3326152006",
    "district_id": "332615",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3326152007",
    "district_id": "332615",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3326152008",
    "district_id": "332615",
    "label": "Samborejo",
    "value": "Samborejo"
  },
  {
    "id": "3326152009",
    "district_id": "332615",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3326152010",
    "district_id": "332615",
    "label": "Pacar",
    "value": "Pacar"
  },
  {
    "id": "3326152011",
    "district_id": "332615",
    "label": "Dadirejo",
    "value": "Dadirejo"
  },
  {
    "id": "3326152012",
    "district_id": "332615",
    "label": "Karangjompo",
    "value": "Karangjompo"
  },
  {
    "id": "3326152013",
    "district_id": "332615",
    "label": "Tegaldowo",
    "value": "Tegaldowo"
  },
  {
    "id": "3326152014",
    "district_id": "332615",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3326152015",
    "district_id": "332615",
    "label": "Jeruksari",
    "value": "Jeruksari"
  },
  {
    "id": "3326152016",
    "district_id": "332615",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3326161010",
    "district_id": "332616",
    "label": "Gumawang",
    "value": "Gumawang"
  },
  {
    "id": "3326161011",
    "district_id": "332616",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3326161012",
    "district_id": "332616",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "3326161013",
    "district_id": "332616",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3326161014",
    "district_id": "332616",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3326162001",
    "district_id": "332616",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3326162002",
    "district_id": "332616",
    "label": "Delegtukang",
    "value": "Delegtukang"
  },
  {
    "id": "3326162003",
    "district_id": "332616",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3326162004",
    "district_id": "332616",
    "label": "Petukangan",
    "value": "Petukangan"
  },
  {
    "id": "3326162005",
    "district_id": "332616",
    "label": "Wiradesa",
    "value": "Wiradesa"
  },
  {
    "id": "3326162006",
    "district_id": "332616",
    "label": "Warukidul",
    "value": "Warukidul"
  },
  {
    "id": "3326162007",
    "district_id": "332616",
    "label": "Bondansari",
    "value": "Bondansari"
  },
  {
    "id": "3326162008",
    "district_id": "332616",
    "label": "Warulor",
    "value": "Warulor"
  },
  {
    "id": "3326162009",
    "district_id": "332616",
    "label": "Kampil",
    "value": "Kampil"
  },
  {
    "id": "3326162015",
    "district_id": "332616",
    "label": "Kemplong",
    "value": "Kemplong"
  },
  {
    "id": "3326162016",
    "district_id": "332616",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3326172001",
    "district_id": "332617",
    "label": "Mejasem",
    "value": "Mejasem"
  },
  {
    "id": "3326172002",
    "district_id": "332617",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3326172003",
    "district_id": "332617",
    "label": "Tengengkulon",
    "value": "Tengengkulon"
  },
  {
    "id": "3326172004",
    "district_id": "332617",
    "label": "Tunjungsari",
    "value": "Tunjungsari"
  },
  {
    "id": "3326172005",
    "district_id": "332617",
    "label": "Pait",
    "value": "Pait"
  },
  {
    "id": "3326172006",
    "district_id": "332617",
    "label": "Tengengwetan",
    "value": "Tengengwetan"
  },
  {
    "id": "3326172007",
    "district_id": "332617",
    "label": "Rembun",
    "value": "Rembun"
  },
  {
    "id": "3326172008",
    "district_id": "332617",
    "label": "Yosorejo",
    "value": "Yosorejo"
  },
  {
    "id": "3326172009",
    "district_id": "332617",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "3326172010",
    "district_id": "332617",
    "label": "Boyoteluk",
    "value": "Boyoteluk"
  },
  {
    "id": "3326172011",
    "district_id": "332617",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3326172012",
    "district_id": "332617",
    "label": "Blacanan",
    "value": "Blacanan"
  },
  {
    "id": "3326172013",
    "district_id": "332617",
    "label": "Blimbingwuluh",
    "value": "Blimbingwuluh"
  },
  {
    "id": "3326182001",
    "district_id": "332618",
    "label": "Logandeng",
    "value": "Logandeng"
  },
  {
    "id": "3326182002",
    "district_id": "332618",
    "label": "Jrebengkembang",
    "value": "Jrebengkembang"
  },
  {
    "id": "3326182003",
    "district_id": "332618",
    "label": "Pagumenganmas",
    "value": "Pagumenganmas"
  },
  {
    "id": "3326182004",
    "district_id": "332618",
    "label": "Kedungkebo",
    "value": "Kedungkebo"
  },
  {
    "id": "3326182005",
    "district_id": "332618",
    "label": "Kaligawe",
    "value": "Kaligawe"
  },
  {
    "id": "3326182006",
    "district_id": "332618",
    "label": "Karangdadap",
    "value": "Karangdadap"
  },
  {
    "id": "3326182007",
    "district_id": "332618",
    "label": "Kalilembu",
    "value": "Kalilembu"
  },
  {
    "id": "3326182008",
    "district_id": "332618",
    "label": "Pangkah",
    "value": "Pangkah"
  },
  {
    "id": "3326182009",
    "district_id": "332618",
    "label": "Kebonrowopucang",
    "value": "Kebonrowopucang"
  },
  {
    "id": "3326182010",
    "district_id": "332618",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3326182011",
    "district_id": "332618",
    "label": "Pegandon",
    "value": "Pegandon"
  },
  {
    "id": "3326192001",
    "district_id": "332619",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3326192002",
    "district_id": "332619",
    "label": "Sijambe",
    "value": "Sijambe"
  },
  {
    "id": "3326192003",
    "district_id": "332619",
    "label": "Bebel",
    "value": "Bebel"
  },
  {
    "id": "3326192006",
    "district_id": "332619",
    "label": "Werdi",
    "value": "Werdi"
  },
  {
    "id": "3326192007",
    "district_id": "332619",
    "label": "Rowoyoso",
    "value": "Rowoyoso"
  },
  {
    "id": "3326192008",
    "district_id": "332619",
    "label": "Semut",
    "value": "Semut"
  },
  {
    "id": "3326192009",
    "district_id": "332619",
    "label": "Tratebang",
    "value": "Tratebang"
  },
  {
    "id": "3326192010",
    "district_id": "332619",
    "label": "Wonokertokulon",
    "value": "Wonokertokulon"
  },
  {
    "id": "3326192011",
    "district_id": "332619",
    "label": "Wonokertowetan",
    "value": "Wonokertowetan"
  },
  {
    "id": "3326192012",
    "district_id": "332619",
    "label": "Api-Api",
    "value": "Api-Api"
  },
  {
    "id": "3326192013",
    "district_id": "332619",
    "label": "Pecakaran",
    "value": "Pecakaran"
  },
  {
    "id": "3327012001",
    "district_id": "332701",
    "label": "Plakaran",
    "value": "Plakaran"
  },
  {
    "id": "3327012002",
    "district_id": "332701",
    "label": "Mandiraja",
    "value": "Mandiraja"
  },
  {
    "id": "3327012003",
    "district_id": "332701",
    "label": "Walangsanga",
    "value": "Walangsanga"
  },
  {
    "id": "3327012004",
    "district_id": "332701",
    "label": "Sima",
    "value": "Sima"
  },
  {
    "id": "3327012005",
    "district_id": "332701",
    "label": "Moga",
    "value": "Moga"
  },
  {
    "id": "3327012006",
    "district_id": "332701",
    "label": "Banyumudal",
    "value": "Banyumudal"
  },
  {
    "id": "3327012007",
    "district_id": "332701",
    "label": "Wangkelang",
    "value": "Wangkelang"
  },
  {
    "id": "3327012008",
    "district_id": "332701",
    "label": "Kebanggan",
    "value": "Kebanggan"
  },
  {
    "id": "3327012009",
    "district_id": "332701",
    "label": "Pepedan",
    "value": "Pepedan"
  },
  {
    "id": "3327012010",
    "district_id": "332701",
    "label": "Gendoang",
    "value": "Gendoang"
  },
  {
    "id": "3327022001",
    "district_id": "332702",
    "label": "Clekatakan",
    "value": "Clekatakan"
  },
  {
    "id": "3327022002",
    "district_id": "332702",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3327022003",
    "district_id": "332702",
    "label": "Penakir",
    "value": "Penakir"
  },
  {
    "id": "3327022004",
    "district_id": "332702",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3327022005",
    "district_id": "332702",
    "label": "Jurangmangu",
    "value": "Jurangmangu"
  },
  {
    "id": "3327022006",
    "district_id": "332702",
    "label": "Gambuhan",
    "value": "Gambuhan"
  },
  {
    "id": "3327022007",
    "district_id": "332702",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3327022008",
    "district_id": "332702",
    "label": "Nyalembeng",
    "value": "Nyalembeng"
  },
  {
    "id": "3327022009",
    "district_id": "332702",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3327022010",
    "district_id": "332702",
    "label": "Pagenteran",
    "value": "Pagenteran"
  },
  {
    "id": "3327022011",
    "district_id": "332702",
    "label": "Siremeng",
    "value": "Siremeng"
  },
  {
    "id": "3327022012",
    "district_id": "332702",
    "label": "Cikendung",
    "value": "Cikendung"
  },
  {
    "id": "3327032001",
    "district_id": "332703",
    "label": "Gombong",
    "value": "Gombong"
  },
  {
    "id": "3327032002",
    "district_id": "332703",
    "label": "Belik",
    "value": "Belik"
  },
  {
    "id": "3327032003",
    "district_id": "332703",
    "label": "Gunungtiga",
    "value": "Gunungtiga"
  },
  {
    "id": "3327032004",
    "district_id": "332703",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "3327032005",
    "district_id": "332703",
    "label": "Badak",
    "value": "Badak"
  },
  {
    "id": "3327032006",
    "district_id": "332703",
    "label": "Gunungjaya",
    "value": "Gunungjaya"
  },
  {
    "id": "3327032007",
    "district_id": "332703",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "3327032008",
    "district_id": "332703",
    "label": "Mendelem",
    "value": "Mendelem"
  },
  {
    "id": "3327032009",
    "district_id": "332703",
    "label": "Beluk",
    "value": "Beluk"
  },
  {
    "id": "3327032010",
    "district_id": "332703",
    "label": "Bulakan",
    "value": "Bulakan"
  },
  {
    "id": "3327032011",
    "district_id": "332703",
    "label": "Sikasur",
    "value": "Sikasur"
  },
  {
    "id": "3327032012",
    "district_id": "332703",
    "label": "Kalisaleh",
    "value": "Kalisaleh"
  },
  {
    "id": "3327042001",
    "district_id": "332704",
    "label": "Tlagasana",
    "value": "Tlagasana"
  },
  {
    "id": "3327042002",
    "district_id": "332704",
    "label": "Tundagan",
    "value": "Tundagan"
  },
  {
    "id": "3327042003",
    "district_id": "332704",
    "label": "Bongas",
    "value": "Bongas"
  },
  {
    "id": "3327042004",
    "district_id": "332704",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3327042005",
    "district_id": "332704",
    "label": "Cawet",
    "value": "Cawet"
  },
  {
    "id": "3327042006",
    "district_id": "332704",
    "label": "Medayu",
    "value": "Medayu"
  },
  {
    "id": "3327042007",
    "district_id": "332704",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3327042008",
    "district_id": "332704",
    "label": "Bodas",
    "value": "Bodas"
  },
  {
    "id": "3327042009",
    "district_id": "332704",
    "label": "Jojogan",
    "value": "Jojogan"
  },
  {
    "id": "3327042010",
    "district_id": "332704",
    "label": "Majalangu",
    "value": "Majalangu"
  },
  {
    "id": "3327042011",
    "district_id": "332704",
    "label": "Tambi",
    "value": "Tambi"
  },
  {
    "id": "3327042012",
    "district_id": "332704",
    "label": "Watukumpul",
    "value": "Watukumpul"
  },
  {
    "id": "3327042013",
    "district_id": "332704",
    "label": "Gapura",
    "value": "Gapura"
  },
  {
    "id": "3327042014",
    "district_id": "332704",
    "label": "Majakerta",
    "value": "Majakerta"
  },
  {
    "id": "3327042015",
    "district_id": "332704",
    "label": "Wisnu",
    "value": "Wisnu"
  },
  {
    "id": "3327052001",
    "district_id": "332705",
    "label": "Longkeyang",
    "value": "Longkeyang"
  },
  {
    "id": "3327052002",
    "district_id": "332705",
    "label": "Jatingarang",
    "value": "Jatingarang"
  },
  {
    "id": "3327052003",
    "district_id": "332705",
    "label": "Gunungbatu",
    "value": "Gunungbatu"
  },
  {
    "id": "3327052004",
    "district_id": "332705",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "3327052005",
    "district_id": "332705",
    "label": "Kwasen",
    "value": "Kwasen"
  },
  {
    "id": "3327052006",
    "district_id": "332705",
    "label": "Jatiroyom",
    "value": "Jatiroyom"
  },
  {
    "id": "3327052007",
    "district_id": "332705",
    "label": "Parunggalih",
    "value": "Parunggalih"
  },
  {
    "id": "3327052008",
    "district_id": "332705",
    "label": "Payung",
    "value": "Payung"
  },
  {
    "id": "3327052009",
    "district_id": "332705",
    "label": "Cangak",
    "value": "Cangak"
  },
  {
    "id": "3327052010",
    "district_id": "332705",
    "label": "Kebandungan",
    "value": "Kebandungan"
  },
  {
    "id": "3327052011",
    "district_id": "332705",
    "label": "Kesesirejo",
    "value": "Kesesirejo"
  },
  {
    "id": "3327052012",
    "district_id": "332705",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3327052013",
    "district_id": "332705",
    "label": "Karangbrai",
    "value": "Karangbrai"
  },
  {
    "id": "3327052014",
    "district_id": "332705",
    "label": "Jraganan",
    "value": "Jraganan"
  },
  {
    "id": "3327052015",
    "district_id": "332705",
    "label": "Kebandaran",
    "value": "Kebandaran"
  },
  {
    "id": "3327052016",
    "district_id": "332705",
    "label": "Bodeh",
    "value": "Bodeh"
  },
  {
    "id": "3327052017",
    "district_id": "332705",
    "label": "Muncang",
    "value": "Muncang"
  },
  {
    "id": "3327052018",
    "district_id": "332705",
    "label": "Kelangdepok",
    "value": "Kelangdepok"
  },
  {
    "id": "3327052019",
    "district_id": "332705",
    "label": "Pendowo",
    "value": "Pendowo"
  },
  {
    "id": "3327062001",
    "district_id": "332706",
    "label": "Sumurkidang",
    "value": "Sumurkidang"
  },
  {
    "id": "3327062002",
    "district_id": "332706",
    "label": "Wanarata",
    "value": "Wanarata"
  },
  {
    "id": "3327062003",
    "district_id": "332706",
    "label": "Pedagung",
    "value": "Pedagung"
  },
  {
    "id": "3327062004",
    "district_id": "332706",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "3327062005",
    "district_id": "332706",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3327062006",
    "district_id": "332706",
    "label": "Pegiringan",
    "value": "Pegiringan"
  },
  {
    "id": "3327062007",
    "district_id": "332706",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3327062008",
    "district_id": "332706",
    "label": "Purana",
    "value": "Purana"
  },
  {
    "id": "3327062009",
    "district_id": "332706",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3327062010",
    "district_id": "332706",
    "label": "Sarwodadi",
    "value": "Sarwodadi"
  },
  {
    "id": "3327062011",
    "district_id": "332706",
    "label": "Bantarbolang",
    "value": "Bantarbolang"
  },
  {
    "id": "3327062012",
    "district_id": "332706",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3327062013",
    "district_id": "332706",
    "label": "Glandang",
    "value": "Glandang"
  },
  {
    "id": "3327062014",
    "district_id": "332706",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "3327062015",
    "district_id": "332706",
    "label": "Kebon Gede",
    "value": "Kebon Gede"
  },
  {
    "id": "3327062016",
    "district_id": "332706",
    "label": "Paguyangan",
    "value": "Paguyangan"
  },
  {
    "id": "3327062017",
    "district_id": "332706",
    "label": "Lenggerong",
    "value": "Lenggerong"
  },
  {
    "id": "3327072001",
    "district_id": "332707",
    "label": "Kecepit",
    "value": "Kecepit"
  },
  {
    "id": "3327072002",
    "district_id": "332707",
    "label": "Gembyang",
    "value": "Gembyang"
  },
  {
    "id": "3327072003",
    "district_id": "332707",
    "label": "Mejagong",
    "value": "Mejagong"
  },
  {
    "id": "3327072004",
    "district_id": "332707",
    "label": "Penusupan",
    "value": "Penusupan"
  },
  {
    "id": "3327072005",
    "district_id": "332707",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3327072006",
    "district_id": "332707",
    "label": "Randudongkal",
    "value": "Randudongkal"
  },
  {
    "id": "3327072007",
    "district_id": "332707",
    "label": "Karangmoncol",
    "value": "Karangmoncol"
  },
  {
    "id": "3327072008",
    "district_id": "332707",
    "label": "Semingkir",
    "value": "Semingkir"
  },
  {
    "id": "3327072009",
    "district_id": "332707",
    "label": "Semaya",
    "value": "Semaya"
  },
  {
    "id": "3327072010",
    "district_id": "332707",
    "label": "Tanahbaya",
    "value": "Tanahbaya"
  },
  {
    "id": "3327072011",
    "district_id": "332707",
    "label": "Lodaya",
    "value": "Lodaya"
  },
  {
    "id": "3327072012",
    "district_id": "332707",
    "label": "Rembul",
    "value": "Rembul"
  },
  {
    "id": "3327072013",
    "district_id": "332707",
    "label": "Kreyo",
    "value": "Kreyo"
  },
  {
    "id": "3327072014",
    "district_id": "332707",
    "label": "Kalimas",
    "value": "Kalimas"
  },
  {
    "id": "3327072015",
    "district_id": "332707",
    "label": "Mangli",
    "value": "Mangli"
  },
  {
    "id": "3327072016",
    "district_id": "332707",
    "label": "Kalitorong",
    "value": "Kalitorong"
  },
  {
    "id": "3327072017",
    "district_id": "332707",
    "label": "Kejene",
    "value": "Kejene"
  },
  {
    "id": "3327072018",
    "district_id": "332707",
    "label": "Gongseng",
    "value": "Gongseng"
  },
  {
    "id": "3327081004",
    "district_id": "332708",
    "label": "Paduraksa",
    "value": "Paduraksa"
  },
  {
    "id": "3327081013",
    "district_id": "332708",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3327081014",
    "district_id": "332708",
    "label": "Bojongbata",
    "value": "Bojongbata"
  },
  {
    "id": "3327081015",
    "district_id": "332708",
    "label": "Mulyoharjo",
    "value": "Mulyoharjo"
  },
  {
    "id": "3327081016",
    "district_id": "332708",
    "label": "Pelutan",
    "value": "Pelutan"
  },
  {
    "id": "3327081018",
    "district_id": "332708",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3327081019",
    "district_id": "332708",
    "label": "Widuri",
    "value": "Widuri"
  },
  {
    "id": "3327082001",
    "district_id": "332708",
    "label": "Banjarmulya",
    "value": "Banjarmulya"
  },
  {
    "id": "3327082002",
    "district_id": "332708",
    "label": "Surajaya",
    "value": "Surajaya"
  },
  {
    "id": "3327082003",
    "district_id": "332708",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3327082005",
    "district_id": "332708",
    "label": "Pegongsoran",
    "value": "Pegongsoran"
  },
  {
    "id": "3327082006",
    "district_id": "332708",
    "label": "Sungapan",
    "value": "Sungapan"
  },
  {
    "id": "3327082007",
    "district_id": "332708",
    "label": "Saradan",
    "value": "Saradan"
  },
  {
    "id": "3327082008",
    "district_id": "332708",
    "label": "Sewaka",
    "value": "Sewaka"
  },
  {
    "id": "3327082009",
    "district_id": "332708",
    "label": "Mengori",
    "value": "Mengori"
  },
  {
    "id": "3327082010",
    "district_id": "332708",
    "label": "Wanamulya",
    "value": "Wanamulya"
  },
  {
    "id": "3327082011",
    "district_id": "332708",
    "label": "Bojongnangka",
    "value": "Bojongnangka"
  },
  {
    "id": "3327082012",
    "district_id": "332708",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3327082017",
    "district_id": "332708",
    "label": "Lawangrejo",
    "value": "Lawangrejo"
  },
  {
    "id": "3327082020",
    "district_id": "332708",
    "label": "Danasari",
    "value": "Danasari"
  },
  {
    "id": "3327091015",
    "district_id": "332709",
    "label": "Wanarejan Selatan",
    "value": "Wanarejan Selatan"
  },
  {
    "id": "3327091016",
    "district_id": "332709",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3327092001",
    "district_id": "332709",
    "label": "Penggarit",
    "value": "Penggarit"
  },
  {
    "id": "3327092002",
    "district_id": "332709",
    "label": "Pener",
    "value": "Pener"
  },
  {
    "id": "3327092003",
    "district_id": "332709",
    "label": "Jrakah",
    "value": "Jrakah"
  },
  {
    "id": "3327092004",
    "district_id": "332709",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3327092005",
    "district_id": "332709",
    "label": "Sokawangi",
    "value": "Sokawangi"
  },
  {
    "id": "3327092006",
    "district_id": "332709",
    "label": "Kejambon",
    "value": "Kejambon"
  },
  {
    "id": "3327092007",
    "district_id": "332709",
    "label": "Jebed Utara",
    "value": "Jebed Utara"
  },
  {
    "id": "3327092008",
    "district_id": "332709",
    "label": "Cibelok",
    "value": "Cibelok"
  },
  {
    "id": "3327092009",
    "district_id": "332709",
    "label": "Banjardawa",
    "value": "Banjardawa"
  },
  {
    "id": "3327092010",
    "district_id": "332709",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3327092011",
    "district_id": "332709",
    "label": "Sitemu",
    "value": "Sitemu"
  },
  {
    "id": "3327092012",
    "district_id": "332709",
    "label": "Pedurungan",
    "value": "Pedurungan"
  },
  {
    "id": "3327092013",
    "district_id": "332709",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3327092014",
    "district_id": "332709",
    "label": "Kaligelang",
    "value": "Kaligelang"
  },
  {
    "id": "3327092017",
    "district_id": "332709",
    "label": "Kabunan",
    "value": "Kabunan"
  },
  {
    "id": "3327092018",
    "district_id": "332709",
    "label": "Asemdoyong",
    "value": "Asemdoyong"
  },
  {
    "id": "3327092019",
    "district_id": "332709",
    "label": "Kedungbanjar",
    "value": "Kedungbanjar"
  },
  {
    "id": "3327092020",
    "district_id": "332709",
    "label": "Wanarejan Utara",
    "value": "Wanarejan Utara"
  },
  {
    "id": "3327092021",
    "district_id": "332709",
    "label": "Jebed Selatan",
    "value": "Jebed Selatan"
  },
  {
    "id": "3327101006",
    "district_id": "332710",
    "label": "Petarukan",
    "value": "Petarukan"
  },
  {
    "id": "3327102001",
    "district_id": "332710",
    "label": "Kendalsari",
    "value": "Kendalsari"
  },
  {
    "id": "3327102002",
    "district_id": "332710",
    "label": "Widodaren",
    "value": "Widodaren"
  },
  {
    "id": "3327102003",
    "district_id": "332710",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3327102004",
    "district_id": "332710",
    "label": "Petanjungan",
    "value": "Petanjungan"
  },
  {
    "id": "3327102005",
    "district_id": "332710",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3327102007",
    "district_id": "332710",
    "label": "Kalirandu",
    "value": "Kalirandu"
  },
  {
    "id": "3327102008",
    "district_id": "332710",
    "label": "Iser",
    "value": "Iser"
  },
  {
    "id": "3327102009",
    "district_id": "332710",
    "label": "Sirangkang",
    "value": "Sirangkang"
  },
  {
    "id": "3327102010",
    "district_id": "332710",
    "label": "Pesucen",
    "value": "Pesucen"
  },
  {
    "id": "3327102011",
    "district_id": "332710",
    "label": "Temuireng",
    "value": "Temuireng"
  },
  {
    "id": "3327102012",
    "district_id": "332710",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3327102013",
    "district_id": "332710",
    "label": "Kendaldoyong",
    "value": "Kendaldoyong"
  },
  {
    "id": "3327102014",
    "district_id": "332710",
    "label": "Pegundan",
    "value": "Pegundan"
  },
  {
    "id": "3327102015",
    "district_id": "332710",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3327102016",
    "district_id": "332710",
    "label": "Tegalmlati",
    "value": "Tegalmlati"
  },
  {
    "id": "3327102017",
    "district_id": "332710",
    "label": "Loning",
    "value": "Loning"
  },
  {
    "id": "3327102018",
    "district_id": "332710",
    "label": "Klareyan",
    "value": "Klareyan"
  },
  {
    "id": "3327102019",
    "district_id": "332710",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3327102020",
    "district_id": "332710",
    "label": "Nyamplungsari",
    "value": "Nyamplungsari"
  },
  {
    "id": "3327112001",
    "district_id": "332711",
    "label": "Sokawati",
    "value": "Sokawati"
  },
  {
    "id": "3327112002",
    "district_id": "332711",
    "label": "Tegalsari Timur",
    "value": "Tegalsari Timur"
  },
  {
    "id": "3327112003",
    "district_id": "332711",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3327112004",
    "district_id": "332711",
    "label": "Karangtalok",
    "value": "Karangtalok"
  },
  {
    "id": "3327112005",
    "district_id": "332711",
    "label": "Wonogiri",
    "value": "Wonogiri"
  },
  {
    "id": "3327112006",
    "district_id": "332711",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3327112007",
    "district_id": "332711",
    "label": "Ampelgading",
    "value": "Ampelgading"
  },
  {
    "id": "3327112008",
    "district_id": "332711",
    "label": "Cibiyuk",
    "value": "Cibiyuk"
  },
  {
    "id": "3327112009",
    "district_id": "332711",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3327112010",
    "district_id": "332711",
    "label": "Banglarangan",
    "value": "Banglarangan"
  },
  {
    "id": "3327112011",
    "district_id": "332711",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3327112012",
    "district_id": "332711",
    "label": "Ujunggede",
    "value": "Ujunggede"
  },
  {
    "id": "3327112013",
    "district_id": "332711",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3327112014",
    "district_id": "332711",
    "label": "Kebagusan",
    "value": "Kebagusan"
  },
  {
    "id": "3327112015",
    "district_id": "332711",
    "label": "Sidokare",
    "value": "Sidokare"
  },
  {
    "id": "3327112016",
    "district_id": "332711",
    "label": "Tegalsari Barat",
    "value": "Tegalsari Barat"
  },
  {
    "id": "3327121005",
    "district_id": "332712",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "3327122001",
    "district_id": "332712",
    "label": "Tumbal",
    "value": "Tumbal"
  },
  {
    "id": "3327122002",
    "district_id": "332712",
    "label": "Pecangakan",
    "value": "Pecangakan"
  },
  {
    "id": "3327122003",
    "district_id": "332712",
    "label": "Sikayu",
    "value": "Sikayu"
  },
  {
    "id": "3327122004",
    "district_id": "332712",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3327122006",
    "district_id": "332712",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3327122007",
    "district_id": "332712",
    "label": "Lowa",
    "value": "Lowa"
  },
  {
    "id": "3327122008",
    "district_id": "332712",
    "label": "Ambokulon",
    "value": "Ambokulon"
  },
  {
    "id": "3327122009",
    "district_id": "332712",
    "label": "Gandu",
    "value": "Gandu"
  },
  {
    "id": "3327122010",
    "district_id": "332712",
    "label": "Gedeg",
    "value": "Gedeg"
  },
  {
    "id": "3327122011",
    "district_id": "332712",
    "label": "Gintung",
    "value": "Gintung"
  },
  {
    "id": "3327122012",
    "district_id": "332712",
    "label": "Sarwodadi",
    "value": "Sarwodadi"
  },
  {
    "id": "3327122013",
    "district_id": "332712",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3327122014",
    "district_id": "332712",
    "label": "Klegen",
    "value": "Klegen"
  },
  {
    "id": "3327122015",
    "district_id": "332712",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "3327122016",
    "district_id": "332712",
    "label": "Kebojongan",
    "value": "Kebojongan"
  },
  {
    "id": "3327122017",
    "district_id": "332712",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "3327122018",
    "district_id": "332712",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3327132001",
    "district_id": "332713",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3327132002",
    "district_id": "332713",
    "label": "Botekan",
    "value": "Botekan"
  },
  {
    "id": "3327132003",
    "district_id": "332713",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3327132004",
    "district_id": "332713",
    "label": "Ambowetan",
    "value": "Ambowetan"
  },
  {
    "id": "3327132005",
    "district_id": "332713",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3327132006",
    "district_id": "332713",
    "label": "Wiyorowetan",
    "value": "Wiyorowetan"
  },
  {
    "id": "3327132007",
    "district_id": "332713",
    "label": "Samong",
    "value": "Samong"
  },
  {
    "id": "3327132008",
    "district_id": "332713",
    "label": "Tasikrejo",
    "value": "Tasikrejo"
  },
  {
    "id": "3327132009",
    "district_id": "332713",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3327132010",
    "district_id": "332713",
    "label": "Kaliprau",
    "value": "Kaliprau"
  },
  {
    "id": "3327132011",
    "district_id": "332713",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3327132012",
    "district_id": "332713",
    "label": "Pamutih",
    "value": "Pamutih"
  },
  {
    "id": "3327132013",
    "district_id": "332713",
    "label": "Padek",
    "value": "Padek"
  },
  {
    "id": "3327132014",
    "district_id": "332713",
    "label": "Blendung",
    "value": "Blendung"
  },
  {
    "id": "3327132015",
    "district_id": "332713",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3327132016",
    "district_id": "332713",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3327132017",
    "district_id": "332713",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3327132018",
    "district_id": "332713",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3327142001",
    "district_id": "332714",
    "label": "Pakembaran",
    "value": "Pakembaran"
  },
  {
    "id": "3327142002",
    "district_id": "332714",
    "label": "Warungpring",
    "value": "Warungpring"
  },
  {
    "id": "3327142003",
    "district_id": "332714",
    "label": "Karangdawa",
    "value": "Karangdawa"
  },
  {
    "id": "3327142004",
    "district_id": "332714",
    "label": "Datar",
    "value": "Datar"
  },
  {
    "id": "3327142005",
    "district_id": "332714",
    "label": "Cibuyur",
    "value": "Cibuyur"
  },
  {
    "id": "3327142006",
    "district_id": "332714",
    "label": "Mereng",
    "value": "Mereng"
  },
  {
    "id": "3328012001",
    "district_id": "332801",
    "label": "Prupuk Selatan",
    "value": "Prupuk Selatan"
  },
  {
    "id": "3328012002",
    "district_id": "332801",
    "label": "Kaligayam",
    "value": "Kaligayam"
  },
  {
    "id": "3328012003",
    "district_id": "332801",
    "label": "Dukuh Tengah",
    "value": "Dukuh Tengah"
  },
  {
    "id": "3328012004",
    "district_id": "332801",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3328012005",
    "district_id": "332801",
    "label": "Danaraja",
    "value": "Danaraja"
  },
  {
    "id": "3328012006",
    "district_id": "332801",
    "label": "Jembayat",
    "value": "Jembayat"
  },
  {
    "id": "3328012007",
    "district_id": "332801",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3328012008",
    "district_id": "332801",
    "label": "Pakulaut",
    "value": "Pakulaut"
  },
  {
    "id": "3328012009",
    "district_id": "332801",
    "label": "Karangdawa",
    "value": "Karangdawa"
  },
  {
    "id": "3328012010",
    "district_id": "332801",
    "label": "Kalisalak",
    "value": "Kalisalak"
  },
  {
    "id": "3328012011",
    "district_id": "332801",
    "label": "Jatilaba",
    "value": "Jatilaba"
  },
  {
    "id": "3328012012",
    "district_id": "332801",
    "label": "Prupuk Utara",
    "value": "Prupuk Utara"
  },
  {
    "id": "3328012013",
    "district_id": "332801",
    "label": "Marga Ayu",
    "value": "Marga Ayu"
  },
  {
    "id": "3328022001",
    "district_id": "332802",
    "label": "Guci",
    "value": "Guci"
  },
  {
    "id": "3328022002",
    "district_id": "332802",
    "label": "Sigedong",
    "value": "Sigedong"
  },
  {
    "id": "3328022003",
    "district_id": "332802",
    "label": "Batumirah",
    "value": "Batumirah"
  },
  {
    "id": "3328022004",
    "district_id": "332802",
    "label": "Bumijawa",
    "value": "Bumijawa"
  },
  {
    "id": "3328022005",
    "district_id": "332802",
    "label": "Sokasari",
    "value": "Sokasari"
  },
  {
    "id": "3328022006",
    "district_id": "332802",
    "label": "Sokatengah",
    "value": "Sokatengah"
  },
  {
    "id": "3328022007",
    "district_id": "332802",
    "label": "Sumbaga",
    "value": "Sumbaga"
  },
  {
    "id": "3328022008",
    "district_id": "332802",
    "label": "Traju",
    "value": "Traju"
  },
  {
    "id": "3328022009",
    "district_id": "332802",
    "label": "Muncanglarang",
    "value": "Muncanglarang"
  },
  {
    "id": "3328022010",
    "district_id": "332802",
    "label": "Begawat",
    "value": "Begawat"
  },
  {
    "id": "3328022011",
    "district_id": "332802",
    "label": "Dukuhbenda",
    "value": "Dukuhbenda"
  },
  {
    "id": "3328022012",
    "district_id": "332802",
    "label": "Cintamanik",
    "value": "Cintamanik"
  },
  {
    "id": "3328022013",
    "district_id": "332802",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "3328022014",
    "district_id": "332802",
    "label": "Gunungagung",
    "value": "Gunungagung"
  },
  {
    "id": "3328022015",
    "district_id": "332802",
    "label": "Jejeg",
    "value": "Jejeg"
  },
  {
    "id": "3328022016",
    "district_id": "332802",
    "label": "Pagerkasih",
    "value": "Pagerkasih"
  },
  {
    "id": "3328022017",
    "district_id": "332802",
    "label": "Carul",
    "value": "Carul"
  },
  {
    "id": "3328022018",
    "district_id": "332802",
    "label": "Cawitali",
    "value": "Cawitali"
  },
  {
    "id": "3328032001",
    "district_id": "332803",
    "label": "Rembul",
    "value": "Rembul"
  },
  {
    "id": "3328032002",
    "district_id": "332803",
    "label": "Dukuhtengah",
    "value": "Dukuhtengah"
  },
  {
    "id": "3328032003",
    "district_id": "332803",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3328032004",
    "district_id": "332803",
    "label": "Suniarsih",
    "value": "Suniarsih"
  },
  {
    "id": "3328032005",
    "district_id": "332803",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3328032006",
    "district_id": "332803",
    "label": "Tuwel",
    "value": "Tuwel"
  },
  {
    "id": "3328032007",
    "district_id": "332803",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3328032008",
    "district_id": "332803",
    "label": "Buniwah",
    "value": "Buniwah"
  },
  {
    "id": "3328032009",
    "district_id": "332803",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3328032010",
    "district_id": "332803",
    "label": "Batunyana",
    "value": "Batunyana"
  },
  {
    "id": "3328032011",
    "district_id": "332803",
    "label": "Sangkanayu",
    "value": "Sangkanayu"
  },
  {
    "id": "3328032012",
    "district_id": "332803",
    "label": "Gunungjati",
    "value": "Gunungjati"
  },
  {
    "id": "3328032013",
    "district_id": "332803",
    "label": "Puncangluwuk",
    "value": "Puncangluwuk"
  },
  {
    "id": "3328032014",
    "district_id": "332803",
    "label": "Kajenengan",
    "value": "Kajenengan"
  },
  {
    "id": "3328032015",
    "district_id": "332803",
    "label": "Kalijambu",
    "value": "Kalijambu"
  },
  {
    "id": "3328032016",
    "district_id": "332803",
    "label": "Danasari",
    "value": "Danasari"
  },
  {
    "id": "3328032017",
    "district_id": "332803",
    "label": "Cikura",
    "value": "Cikura"
  },
  {
    "id": "3328042001",
    "district_id": "332804",
    "label": "Cenggini",
    "value": "Cenggini"
  },
  {
    "id": "3328042002",
    "district_id": "332804",
    "label": "Bukateja",
    "value": "Bukateja"
  },
  {
    "id": "3328042003",
    "district_id": "332804",
    "label": "Kalibakung",
    "value": "Kalibakung"
  },
  {
    "id": "3328042004",
    "district_id": "332804",
    "label": "Karangjambu",
    "value": "Karangjambu"
  },
  {
    "id": "3328042005",
    "district_id": "332804",
    "label": "Cilongok",
    "value": "Cilongok"
  },
  {
    "id": "3328042006",
    "district_id": "332804",
    "label": "Tembongwah",
    "value": "Tembongwah"
  },
  {
    "id": "3328042007",
    "district_id": "332804",
    "label": "Danareja",
    "value": "Danareja"
  },
  {
    "id": "3328042008",
    "district_id": "332804",
    "label": "Sangkanjaya",
    "value": "Sangkanjaya"
  },
  {
    "id": "3328042009",
    "district_id": "332804",
    "label": "Danawarih",
    "value": "Danawarih"
  },
  {
    "id": "3328042010",
    "district_id": "332804",
    "label": "Pagerwangi",
    "value": "Pagerwangi"
  },
  {
    "id": "3328042011",
    "district_id": "332804",
    "label": "Harjowinangun",
    "value": "Harjowinangun"
  },
  {
    "id": "3328042012",
    "district_id": "332804",
    "label": "Batuagung",
    "value": "Batuagung"
  },
  {
    "id": "3328042013",
    "district_id": "332804",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3328042014",
    "district_id": "332804",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3328042015",
    "district_id": "332804",
    "label": "Sesepan",
    "value": "Sesepan"
  },
  {
    "id": "3328042016",
    "district_id": "332804",
    "label": "Wringinjenggot",
    "value": "Wringinjenggot"
  },
  {
    "id": "3328042017",
    "district_id": "332804",
    "label": "Pamiritan",
    "value": "Pamiritan"
  },
  {
    "id": "3328042018",
    "district_id": "332804",
    "label": "Balapulang Wetan",
    "value": "Balapulang Wetan"
  },
  {
    "id": "3328042019",
    "district_id": "332804",
    "label": "Balapulang Kulon",
    "value": "Balapulang Kulon"
  },
  {
    "id": "3328042020",
    "district_id": "332804",
    "label": "Cibunar",
    "value": "Cibunar"
  },
  {
    "id": "3328052001",
    "district_id": "332805",
    "label": "Srengseng",
    "value": "Srengseng"
  },
  {
    "id": "3328052002",
    "district_id": "332805",
    "label": "Rajegwesi",
    "value": "Rajegwesi"
  },
  {
    "id": "3328052003",
    "district_id": "332805",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3328052004",
    "district_id": "332805",
    "label": "Mulyoharjo",
    "value": "Mulyoharjo"
  },
  {
    "id": "3328052005",
    "district_id": "332805",
    "label": "Semboja",
    "value": "Semboja"
  },
  {
    "id": "3328052006",
    "district_id": "332805",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3328052007",
    "district_id": "332805",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "3328052008",
    "district_id": "332805",
    "label": "Pagerbarang",
    "value": "Pagerbarang"
  },
  {
    "id": "3328052009",
    "district_id": "332805",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3328052010",
    "district_id": "332805",
    "label": "Kertaharja",
    "value": "Kertaharja"
  },
  {
    "id": "3328052011",
    "district_id": "332805",
    "label": "Kedungsugih",
    "value": "Kedungsugih"
  },
  {
    "id": "3328052012",
    "district_id": "332805",
    "label": "Surokidul",
    "value": "Surokidul"
  },
  {
    "id": "3328052013",
    "district_id": "332805",
    "label": "Pesarean",
    "value": "Pesarean"
  },
  {
    "id": "3328062001",
    "district_id": "332806",
    "label": "Timbangreja",
    "value": "Timbangreja"
  },
  {
    "id": "3328062002",
    "district_id": "332806",
    "label": "Lebaksiu Kidul",
    "value": "Lebaksiu Kidul"
  },
  {
    "id": "3328062003",
    "district_id": "332806",
    "label": "Lebaksiu Lor",
    "value": "Lebaksiu Lor"
  },
  {
    "id": "3328062004",
    "district_id": "332806",
    "label": "Kajen",
    "value": "Kajen"
  },
  {
    "id": "3328062005",
    "district_id": "332806",
    "label": "Yamansari",
    "value": "Yamansari"
  },
  {
    "id": "3328062006",
    "district_id": "332806",
    "label": "Kesuben",
    "value": "Kesuben"
  },
  {
    "id": "3328062007",
    "district_id": "332806",
    "label": "Balaradin",
    "value": "Balaradin"
  },
  {
    "id": "3328062008",
    "district_id": "332806",
    "label": "Lebakgowah",
    "value": "Lebakgowah"
  },
  {
    "id": "3328062009",
    "district_id": "332806",
    "label": "Dukuhlo",
    "value": "Dukuhlo"
  },
  {
    "id": "3328062010",
    "district_id": "332806",
    "label": "Pendawa",
    "value": "Pendawa"
  },
  {
    "id": "3328062011",
    "district_id": "332806",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3328062012",
    "district_id": "332806",
    "label": "Kambangan",
    "value": "Kambangan"
  },
  {
    "id": "3328062013",
    "district_id": "332806",
    "label": "Slarangkidul",
    "value": "Slarangkidul"
  },
  {
    "id": "3328062014",
    "district_id": "332806",
    "label": "Tegalandong",
    "value": "Tegalandong"
  },
  {
    "id": "3328062015",
    "district_id": "332806",
    "label": "Dukuhdamu",
    "value": "Dukuhdamu"
  },
  {
    "id": "3328072001",
    "district_id": "332807",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3328072002",
    "district_id": "332807",
    "label": "Mokaha",
    "value": "Mokaha"
  },
  {
    "id": "3328072003",
    "district_id": "332807",
    "label": "Penyalahan",
    "value": "Penyalahan"
  },
  {
    "id": "3328072004",
    "district_id": "332807",
    "label": "Sitail",
    "value": "Sitail"
  },
  {
    "id": "3328072005",
    "district_id": "332807",
    "label": "Sumbarang",
    "value": "Sumbarang"
  },
  {
    "id": "3328072006",
    "district_id": "332807",
    "label": "Cerih",
    "value": "Cerih"
  },
  {
    "id": "3328072007",
    "district_id": "332807",
    "label": "Gantungan",
    "value": "Gantungan"
  },
  {
    "id": "3328072008",
    "district_id": "332807",
    "label": "Argatawang",
    "value": "Argatawang"
  },
  {
    "id": "3328072009",
    "district_id": "332807",
    "label": "Padasari",
    "value": "Padasari"
  },
  {
    "id": "3328072010",
    "district_id": "332807",
    "label": "Capar",
    "value": "Capar"
  },
  {
    "id": "3328072011",
    "district_id": "332807",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "3328072012",
    "district_id": "332807",
    "label": "Dukuhbangsa",
    "value": "Dukuhbangsa"
  },
  {
    "id": "3328072013",
    "district_id": "332807",
    "label": "Jatinegara",
    "value": "Jatinegara"
  },
  {
    "id": "3328072014",
    "district_id": "332807",
    "label": "Luwijawa",
    "value": "Luwijawa"
  },
  {
    "id": "3328072015",
    "district_id": "332807",
    "label": "Lembasari",
    "value": "Lembasari"
  },
  {
    "id": "3328072016",
    "district_id": "332807",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3328072017",
    "district_id": "332807",
    "label": "Wotgalih",
    "value": "Wotgalih"
  },
  {
    "id": "3328082001",
    "district_id": "332808",
    "label": "Penujah",
    "value": "Penujah"
  },
  {
    "id": "3328082002",
    "district_id": "332808",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3328082003",
    "district_id": "332808",
    "label": "Tonggara",
    "value": "Tonggara"
  },
  {
    "id": "3328082004",
    "district_id": "332808",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3328082005",
    "district_id": "332808",
    "label": "Dukuhjati Wetan",
    "value": "Dukuhjati Wetan"
  },
  {
    "id": "3328082006",
    "district_id": "332808",
    "label": "Sumingkir",
    "value": "Sumingkir"
  },
  {
    "id": "3328082007",
    "district_id": "332808",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3328082008",
    "district_id": "332808",
    "label": "Kebandingan",
    "value": "Kebandingan"
  },
  {
    "id": "3328082009",
    "district_id": "332808",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3328082010",
    "district_id": "332808",
    "label": "Semedo",
    "value": "Semedo"
  },
  {
    "id": "3328092001",
    "district_id": "332809",
    "label": "Dermasuci",
    "value": "Dermasuci"
  },
  {
    "id": "3328092002",
    "district_id": "332809",
    "label": "Pener",
    "value": "Pener"
  },
  {
    "id": "3328092003",
    "district_id": "332809",
    "label": "Dukuhjati Kidul",
    "value": "Dukuhjati Kidul"
  },
  {
    "id": "3328092004",
    "district_id": "332809",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3328092005",
    "district_id": "332809",
    "label": "Penusupan",
    "value": "Penusupan"
  },
  {
    "id": "3328092006",
    "district_id": "332809",
    "label": "Bogares Kidul",
    "value": "Bogares Kidul"
  },
  {
    "id": "3328092007",
    "district_id": "332809",
    "label": "Bogares Lor",
    "value": "Bogares Lor"
  },
  {
    "id": "3328092008",
    "district_id": "332809",
    "label": "Pangkah",
    "value": "Pangkah"
  },
  {
    "id": "3328092009",
    "district_id": "332809",
    "label": "Dukuhsembung",
    "value": "Dukuhsembung"
  },
  {
    "id": "3328092010",
    "district_id": "332809",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3328092011",
    "district_id": "332809",
    "label": "Kendalserut",
    "value": "Kendalserut"
  },
  {
    "id": "3328092012",
    "district_id": "332809",
    "label": "Grobog Kulon",
    "value": "Grobog Kulon"
  },
  {
    "id": "3328092013",
    "district_id": "332809",
    "label": "Bedug",
    "value": "Bedug"
  },
  {
    "id": "3328092014",
    "district_id": "332809",
    "label": "Pecabean",
    "value": "Pecabean"
  },
  {
    "id": "3328092015",
    "district_id": "332809",
    "label": "Grobog Wetan",
    "value": "Grobog Wetan"
  },
  {
    "id": "3328092016",
    "district_id": "332809",
    "label": "Talok",
    "value": "Talok"
  },
  {
    "id": "3328092017",
    "district_id": "332809",
    "label": "Paketiban",
    "value": "Paketiban"
  },
  {
    "id": "3328092018",
    "district_id": "332809",
    "label": "Rancawiru",
    "value": "Rancawiru"
  },
  {
    "id": "3328092019",
    "district_id": "332809",
    "label": "Jenggawur",
    "value": "Jenggawur"
  },
  {
    "id": "3328092020",
    "district_id": "332809",
    "label": "Kalikangkung",
    "value": "Kalikangkung"
  },
  {
    "id": "3328092021",
    "district_id": "332809",
    "label": "Pubayasa",
    "value": "Pubayasa"
  },
  {
    "id": "3328092022",
    "district_id": "332809",
    "label": "Dermasandi",
    "value": "Dermasandi"
  },
  {
    "id": "3328092023",
    "district_id": "332809",
    "label": "Balamoa",
    "value": "Balamoa"
  },
  {
    "id": "3328101006",
    "district_id": "332810",
    "label": "Pakembaran",
    "value": "Pakembaran"
  },
  {
    "id": "3328101007",
    "district_id": "332810",
    "label": "Kagok",
    "value": "Kagok"
  },
  {
    "id": "3328101008",
    "district_id": "332810",
    "label": "Procot",
    "value": "Procot"
  },
  {
    "id": "3328101009",
    "district_id": "332810",
    "label": "Kudaile",
    "value": "Kudaile"
  },
  {
    "id": "3328101010",
    "district_id": "332810",
    "label": "Slawi Wetan",
    "value": "Slawi Wetan"
  },
  {
    "id": "3328102001",
    "district_id": "332810",
    "label": "Kalisapu",
    "value": "Kalisapu"
  },
  {
    "id": "3328102002",
    "district_id": "332810",
    "label": "Dukuhwringin",
    "value": "Dukuhwringin"
  },
  {
    "id": "3328102003",
    "district_id": "332810",
    "label": "Dukuhsalam",
    "value": "Dukuhsalam"
  },
  {
    "id": "3328102004",
    "district_id": "332810",
    "label": "Slawi Kulon",
    "value": "Slawi Kulon"
  },
  {
    "id": "3328102005",
    "district_id": "332810",
    "label": "Trayeman",
    "value": "Trayeman"
  },
  {
    "id": "3328112001",
    "district_id": "332811",
    "label": "Pedeslohor",
    "value": "Pedeslohor"
  },
  {
    "id": "3328112002",
    "district_id": "332811",
    "label": "Lumingser",
    "value": "Lumingser"
  },
  {
    "id": "3328112003",
    "district_id": "332811",
    "label": "Kedungsukun",
    "value": "Kedungsukun"
  },
  {
    "id": "3328112004",
    "district_id": "332811",
    "label": "Pagiyanten",
    "value": "Pagiyanten"
  },
  {
    "id": "3328112005",
    "district_id": "332811",
    "label": "Penarukan",
    "value": "Penarukan"
  },
  {
    "id": "3328112006",
    "district_id": "332811",
    "label": "Harjosari Lor",
    "value": "Harjosari Lor"
  },
  {
    "id": "3328112007",
    "district_id": "332811",
    "label": "Tembok Lor",
    "value": "Tembok Lor"
  },
  {
    "id": "3328112008",
    "district_id": "332811",
    "label": "Tembok Kidul",
    "value": "Tembok Kidul"
  },
  {
    "id": "3328112009",
    "district_id": "332811",
    "label": "Tembok Banjaran",
    "value": "Tembok Banjaran"
  },
  {
    "id": "3328112010",
    "district_id": "332811",
    "label": "Tembok Luwung",
    "value": "Tembok Luwung"
  },
  {
    "id": "3328112011",
    "district_id": "332811",
    "label": "Adiwerna",
    "value": "Adiwerna"
  },
  {
    "id": "3328112012",
    "district_id": "332811",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3328112013",
    "district_id": "332811",
    "label": "Lemahduwur",
    "value": "Lemahduwur"
  },
  {
    "id": "3328112014",
    "district_id": "332811",
    "label": "Pesarean",
    "value": "Pesarean"
  },
  {
    "id": "3328112015",
    "district_id": "332811",
    "label": "Ujungrusi",
    "value": "Ujungrusi"
  },
  {
    "id": "3328112016",
    "district_id": "332811",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "3328112017",
    "district_id": "332811",
    "label": "Kaliwadas",
    "value": "Kaliwadas"
  },
  {
    "id": "3328112018",
    "district_id": "332811",
    "label": "Pecangakan",
    "value": "Pecangakan"
  },
  {
    "id": "3328112019",
    "district_id": "332811",
    "label": "Gumalar",
    "value": "Gumalar"
  },
  {
    "id": "3328112020",
    "district_id": "332811",
    "label": "Bersole",
    "value": "Bersole"
  },
  {
    "id": "3328112021",
    "district_id": "332811",
    "label": "Harjosari Kidul",
    "value": "Harjosari Kidul"
  },
  {
    "id": "3328122001",
    "district_id": "332812",
    "label": "Pegirikan",
    "value": "Pegirikan"
  },
  {
    "id": "3328122002",
    "district_id": "332812",
    "label": "Pekiringan",
    "value": "Pekiringan"
  },
  {
    "id": "3328122003",
    "district_id": "332812",
    "label": "Gembongkulon",
    "value": "Gembongkulon"
  },
  {
    "id": "3328122004",
    "district_id": "332812",
    "label": "Pasangan",
    "value": "Pasangan"
  },
  {
    "id": "3328122005",
    "district_id": "332812",
    "label": "Langgen",
    "value": "Langgen"
  },
  {
    "id": "3328122006",
    "district_id": "332812",
    "label": "Bengle",
    "value": "Bengle"
  },
  {
    "id": "3328122007",
    "district_id": "332812",
    "label": "Dukuhmalang",
    "value": "Dukuhmalang"
  },
  {
    "id": "3328122008",
    "district_id": "332812",
    "label": "Pesayangan",
    "value": "Pesayangan"
  },
  {
    "id": "3328122009",
    "district_id": "332812",
    "label": "Kajen",
    "value": "Kajen"
  },
  {
    "id": "3328122010",
    "district_id": "332812",
    "label": "Kebasen",
    "value": "Kebasen"
  },
  {
    "id": "3328122011",
    "district_id": "332812",
    "label": "Tegalwangi",
    "value": "Tegalwangi"
  },
  {
    "id": "3328122012",
    "district_id": "332812",
    "label": "Kaligayam",
    "value": "Kaligayam"
  },
  {
    "id": "3328122013",
    "district_id": "332812",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "3328122014",
    "district_id": "332812",
    "label": "Kaladawa",
    "value": "Kaladawa"
  },
  {
    "id": "3328122015",
    "district_id": "332812",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3328122016",
    "district_id": "332812",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3328122017",
    "district_id": "332812",
    "label": "Getaskerep",
    "value": "Getaskerep"
  },
  {
    "id": "3328122018",
    "district_id": "332812",
    "label": "Pacul",
    "value": "Pacul"
  },
  {
    "id": "3328122019",
    "district_id": "332812",
    "label": "Wangandawa",
    "value": "Wangandawa"
  },
  {
    "id": "3328132001",
    "district_id": "332813",
    "label": "Ketanggungan",
    "value": "Ketanggungan"
  },
  {
    "id": "3328132002",
    "district_id": "332813",
    "label": "Kupu",
    "value": "Kupu"
  },
  {
    "id": "3328132003",
    "district_id": "332813",
    "label": "Sidakaton",
    "value": "Sidakaton"
  },
  {
    "id": "3328132004",
    "district_id": "332813",
    "label": "Sidapurna",
    "value": "Sidapurna"
  },
  {
    "id": "3328132005",
    "district_id": "332813",
    "label": "Pepedan",
    "value": "Pepedan"
  },
  {
    "id": "3328132006",
    "district_id": "332813",
    "label": "Pengarasan",
    "value": "Pengarasan"
  },
  {
    "id": "3328132007",
    "district_id": "332813",
    "label": "Lawatan",
    "value": "Lawatan"
  },
  {
    "id": "3328132008",
    "district_id": "332813",
    "label": "Dukuhturi",
    "value": "Dukuhturi"
  },
  {
    "id": "3328132009",
    "district_id": "332813",
    "label": "Pekauman Kulon",
    "value": "Pekauman Kulon"
  },
  {
    "id": "3328132010",
    "district_id": "332813",
    "label": "Pagongan",
    "value": "Pagongan"
  },
  {
    "id": "3328132011",
    "district_id": "332813",
    "label": "Debongwetan",
    "value": "Debongwetan"
  },
  {
    "id": "3328132012",
    "district_id": "332813",
    "label": "Kepandean",
    "value": "Kepandean"
  },
  {
    "id": "3328132013",
    "district_id": "332813",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3328132014",
    "district_id": "332813",
    "label": "Bandasari",
    "value": "Bandasari"
  },
  {
    "id": "3328132015",
    "district_id": "332813",
    "label": "Sutapranan",
    "value": "Sutapranan"
  },
  {
    "id": "3328132016",
    "district_id": "332813",
    "label": "Kademangaran",
    "value": "Kademangaran"
  },
  {
    "id": "3328132017",
    "district_id": "332813",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3328132018",
    "district_id": "332813",
    "label": "Pengabean",
    "value": "Pengabean"
  },
  {
    "id": "3328142001",
    "district_id": "332814",
    "label": "Jatirawa",
    "value": "Jatirawa"
  },
  {
    "id": "3328142002",
    "district_id": "332814",
    "label": "Kabukan",
    "value": "Kabukan"
  },
  {
    "id": "3328142003",
    "district_id": "332814",
    "label": "Setu",
    "value": "Setu"
  },
  {
    "id": "3328142004",
    "district_id": "332814",
    "label": "Purbasana",
    "value": "Purbasana"
  },
  {
    "id": "3328142005",
    "district_id": "332814",
    "label": "Kemanggungan",
    "value": "Kemanggungan"
  },
  {
    "id": "3328142006",
    "district_id": "332814",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3328142007",
    "district_id": "332814",
    "label": "Lebeteng",
    "value": "Lebeteng"
  },
  {
    "id": "3328142008",
    "district_id": "332814",
    "label": "Brekat",
    "value": "Brekat"
  },
  {
    "id": "3328142009",
    "district_id": "332814",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3328142010",
    "district_id": "332814",
    "label": "Bulakwaru",
    "value": "Bulakwaru"
  },
  {
    "id": "3328142011",
    "district_id": "332814",
    "label": "Mindaka",
    "value": "Mindaka"
  },
  {
    "id": "3328142012",
    "district_id": "332814",
    "label": "Tarub",
    "value": "Tarub"
  },
  {
    "id": "3328142013",
    "district_id": "332814",
    "label": "Kedungbungkus",
    "value": "Kedungbungkus"
  },
  {
    "id": "3328142014",
    "district_id": "332814",
    "label": "Kedokansayang",
    "value": "Kedokansayang"
  },
  {
    "id": "3328142015",
    "district_id": "332814",
    "label": "Bumiharja",
    "value": "Bumiharja"
  },
  {
    "id": "3328142016",
    "district_id": "332814",
    "label": "Kalijambe",
    "value": "Kalijambe"
  },
  {
    "id": "3328142017",
    "district_id": "332814",
    "label": "Margapadang",
    "value": "Margapadang"
  },
  {
    "id": "3328142018",
    "district_id": "332814",
    "label": "Kesamiran",
    "value": "Kesamiran"
  },
  {
    "id": "3328142019",
    "district_id": "332814",
    "label": "Kesadikan",
    "value": "Kesadikan"
  },
  {
    "id": "3328142020",
    "district_id": "332814",
    "label": "Mangunsaren",
    "value": "Mangunsaren"
  },
  {
    "id": "3328151019",
    "district_id": "332815",
    "label": "Dampyak",
    "value": "Dampyak"
  },
  {
    "id": "3328152001",
    "district_id": "332815",
    "label": "Mejasem Barat",
    "value": "Mejasem Barat"
  },
  {
    "id": "3328152002",
    "district_id": "332815",
    "label": "Jatilawang",
    "value": "Jatilawang"
  },
  {
    "id": "3328152003",
    "district_id": "332815",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3328152004",
    "district_id": "332815",
    "label": "Kemantran",
    "value": "Kemantran"
  },
  {
    "id": "3328152005",
    "district_id": "332815",
    "label": "Kertaharja",
    "value": "Kertaharja"
  },
  {
    "id": "3328152006",
    "district_id": "332815",
    "label": "Ketileng",
    "value": "Ketileng"
  },
  {
    "id": "3328152007",
    "district_id": "332815",
    "label": "Kepunduhan",
    "value": "Kepunduhan"
  },
  {
    "id": "3328152008",
    "district_id": "332815",
    "label": "Bangun Galih",
    "value": "Bangun Galih"
  },
  {
    "id": "3328152009",
    "district_id": "332815",
    "label": "Tanjungharja",
    "value": "Tanjungharja"
  },
  {
    "id": "3328152010",
    "district_id": "332815",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3328152011",
    "district_id": "332815",
    "label": "Plumbungan",
    "value": "Plumbungan"
  },
  {
    "id": "3328152012",
    "district_id": "332815",
    "label": "Maribaya",
    "value": "Maribaya"
  },
  {
    "id": "3328152013",
    "district_id": "332815",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3328152014",
    "district_id": "332815",
    "label": "Bongkok",
    "value": "Bongkok"
  },
  {
    "id": "3328152015",
    "district_id": "332815",
    "label": "Munjungagung",
    "value": "Munjungagung"
  },
  {
    "id": "3328152016",
    "district_id": "332815",
    "label": "Dinuk",
    "value": "Dinuk"
  },
  {
    "id": "3328152017",
    "district_id": "332815",
    "label": "Kertayasa",
    "value": "Kertayasa"
  },
  {
    "id": "3328152018",
    "district_id": "332815",
    "label": "Padaharja",
    "value": "Padaharja"
  },
  {
    "id": "3328152020",
    "district_id": "332815",
    "label": "Mejasem Timur",
    "value": "Mejasem Timur"
  },
  {
    "id": "3328162001",
    "district_id": "332816",
    "label": "Karangwuluh",
    "value": "Karangwuluh"
  },
  {
    "id": "3328162002",
    "district_id": "332816",
    "label": "Gembongdadi",
    "value": "Gembongdadi"
  },
  {
    "id": "3328162003",
    "district_id": "332816",
    "label": "Karangmulya",
    "value": "Karangmulya"
  },
  {
    "id": "3328162004",
    "district_id": "332816",
    "label": "Harjasari",
    "value": "Harjasari"
  },
  {
    "id": "3328162005",
    "district_id": "332816",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3328162006",
    "district_id": "332816",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3328162007",
    "district_id": "332816",
    "label": "Jatibogor",
    "value": "Jatibogor"
  },
  {
    "id": "3328162008",
    "district_id": "332816",
    "label": "Sidaharja",
    "value": "Sidaharja"
  },
  {
    "id": "3328162009",
    "district_id": "332816",
    "label": "Purwahamba",
    "value": "Purwahamba"
  },
  {
    "id": "3328162010",
    "district_id": "332816",
    "label": "Suradadi",
    "value": "Suradadi"
  },
  {
    "id": "3328162011",
    "district_id": "332816",
    "label": "Bojongsana",
    "value": "Bojongsana"
  },
  {
    "id": "3328172001",
    "district_id": "332817",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3328172002",
    "district_id": "332817",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "3328172003",
    "district_id": "332817",
    "label": "Kendayakan",
    "value": "Kendayakan"
  },
  {
    "id": "3328172004",
    "district_id": "332817",
    "label": "Warureja",
    "value": "Warureja"
  },
  {
    "id": "3328172005",
    "district_id": "332817",
    "label": "Demangharjo",
    "value": "Demangharjo"
  },
  {
    "id": "3328172006",
    "district_id": "332817",
    "label": "Banjarturi",
    "value": "Banjarturi"
  },
  {
    "id": "3328172007",
    "district_id": "332817",
    "label": "Kedungkelor",
    "value": "Kedungkelor"
  },
  {
    "id": "3328172008",
    "district_id": "332817",
    "label": "Sukareja",
    "value": "Sukareja"
  },
  {
    "id": "3328172009",
    "district_id": "332817",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3328172010",
    "district_id": "332817",
    "label": "Sigentong",
    "value": "Sigentong"
  },
  {
    "id": "3328172011",
    "district_id": "332817",
    "label": "Kreman",
    "value": "Kreman"
  },
  {
    "id": "3328172012",
    "district_id": "332817",
    "label": "Rangi Mulya",
    "value": "Rangi Mulya"
  },
  {
    "id": "3328182001",
    "district_id": "332818",
    "label": "Slaranglor",
    "value": "Slaranglor"
  },
  {
    "id": "3328182002",
    "district_id": "332818",
    "label": "Selapura",
    "value": "Selapura"
  },
  {
    "id": "3328182003",
    "district_id": "332818",
    "label": "Blubuk",
    "value": "Blubuk"
  },
  {
    "id": "3328182004",
    "district_id": "332818",
    "label": "Gumayun",
    "value": "Gumayun"
  },
  {
    "id": "3328182005",
    "district_id": "332818",
    "label": "Kabunan",
    "value": "Kabunan"
  },
  {
    "id": "3328182006",
    "district_id": "332818",
    "label": "Pedagangan",
    "value": "Pedagangan"
  },
  {
    "id": "3328182007",
    "district_id": "332818",
    "label": "Kalisoka",
    "value": "Kalisoka"
  },
  {
    "id": "3328182008",
    "district_id": "332818",
    "label": "Sindang",
    "value": "Sindang"
  },
  {
    "id": "3328182009",
    "district_id": "332818",
    "label": "Dukuhwaru",
    "value": "Dukuhwaru"
  },
  {
    "id": "3328182010",
    "district_id": "332818",
    "label": "Bulakpacing",
    "value": "Bulakpacing"
  },
  {
    "id": "3329012001",
    "district_id": "332901",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3329012002",
    "district_id": "332901",
    "label": "Bentar",
    "value": "Bentar"
  },
  {
    "id": "3329012003",
    "district_id": "332901",
    "label": "Bentarsari",
    "value": "Bentarsari"
  },
  {
    "id": "3329012004",
    "district_id": "332901",
    "label": "Capar",
    "value": "Capar"
  },
  {
    "id": "3329012005",
    "district_id": "332901",
    "label": "Ciputih",
    "value": "Ciputih"
  },
  {
    "id": "3329012006",
    "district_id": "332901",
    "label": "Citimbang",
    "value": "Citimbang"
  },
  {
    "id": "3329012007",
    "district_id": "332901",
    "label": "Ganggawang",
    "value": "Ganggawang"
  },
  {
    "id": "3329012008",
    "district_id": "332901",
    "label": "Gandoang",
    "value": "Gandoang"
  },
  {
    "id": "3329012009",
    "district_id": "332901",
    "label": "Gunungjaya",
    "value": "Gunungjaya"
  },
  {
    "id": "3329012010",
    "district_id": "332901",
    "label": "Gununglarang",
    "value": "Gununglarang"
  },
  {
    "id": "3329012011",
    "district_id": "332901",
    "label": "Gunungsugih",
    "value": "Gunungsugih"
  },
  {
    "id": "3329012012",
    "district_id": "332901",
    "label": "Gunungtajem",
    "value": "Gunungtajem"
  },
  {
    "id": "3329012013",
    "district_id": "332901",
    "label": "Indrajaya",
    "value": "Indrajaya"
  },
  {
    "id": "3329012014",
    "district_id": "332901",
    "label": "Kadumanis",
    "value": "Kadumanis"
  },
  {
    "id": "3329012015",
    "district_id": "332901",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3329012016",
    "district_id": "332901",
    "label": "Pasirpanjang",
    "value": "Pasirpanjang"
  },
  {
    "id": "3329012017",
    "district_id": "332901",
    "label": "Salem",
    "value": "Salem"
  },
  {
    "id": "3329012018",
    "district_id": "332901",
    "label": "Tembongraja",
    "value": "Tembongraja"
  },
  {
    "id": "3329012019",
    "district_id": "332901",
    "label": "Wanoja",
    "value": "Wanoja"
  },
  {
    "id": "3329012020",
    "district_id": "332901",
    "label": "Winduasri",
    "value": "Winduasri"
  },
  {
    "id": "3329012021",
    "district_id": "332901",
    "label": "Windusakti",
    "value": "Windusakti"
  },
  {
    "id": "3329022001",
    "district_id": "332902",
    "label": "Bangbayang",
    "value": "Bangbayang"
  },
  {
    "id": "3329022002",
    "district_id": "332902",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3329022003",
    "district_id": "332902",
    "label": "Bantarkawung",
    "value": "Bantarkawung"
  },
  {
    "id": "3329022004",
    "district_id": "332902",
    "label": "Bantarwaru",
    "value": "Bantarwaru"
  },
  {
    "id": "3329022005",
    "district_id": "332902",
    "label": "Cibentang",
    "value": "Cibentang"
  },
  {
    "id": "3329022006",
    "district_id": "332902",
    "label": "Cinanas",
    "value": "Cinanas"
  },
  {
    "id": "3329022007",
    "district_id": "332902",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3329022008",
    "district_id": "332902",
    "label": "Jipang",
    "value": "Jipang"
  },
  {
    "id": "3329022009",
    "district_id": "332902",
    "label": "Karangpari",
    "value": "Karangpari"
  },
  {
    "id": "3329022010",
    "district_id": "332902",
    "label": "Kebandungan",
    "value": "Kebandungan"
  },
  {
    "id": "3329022011",
    "district_id": "332902",
    "label": "Legok",
    "value": "Legok"
  },
  {
    "id": "3329022012",
    "district_id": "332902",
    "label": "Pangebatan",
    "value": "Pangebatan"
  },
  {
    "id": "3329022013",
    "district_id": "332902",
    "label": "Pengarasan",
    "value": "Pengarasan"
  },
  {
    "id": "3329022014",
    "district_id": "332902",
    "label": "Sindangwangi",
    "value": "Sindangwangi"
  },
  {
    "id": "3329022015",
    "district_id": "332902",
    "label": "Tambakserang",
    "value": "Tambakserang"
  },
  {
    "id": "3329022016",
    "district_id": "332902",
    "label": "Terlaya",
    "value": "Terlaya"
  },
  {
    "id": "3329022017",
    "district_id": "332902",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "3329022018",
    "district_id": "332902",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3329032001",
    "district_id": "332903",
    "label": "Adisana",
    "value": "Adisana"
  },
  {
    "id": "3329032002",
    "district_id": "332903",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3329032003",
    "district_id": "332903",
    "label": "Dukuhturi",
    "value": "Dukuhturi"
  },
  {
    "id": "3329032004",
    "district_id": "332903",
    "label": "Jatisawit",
    "value": "Jatisawit"
  },
  {
    "id": "3329032005",
    "district_id": "332903",
    "label": "Kalierang",
    "value": "Kalierang"
  },
  {
    "id": "3329032006",
    "district_id": "332903",
    "label": "Kalilangkap",
    "value": "Kalilangkap"
  },
  {
    "id": "3329032007",
    "district_id": "332903",
    "label": "Kalinusu",
    "value": "Kalinusu"
  },
  {
    "id": "3329032008",
    "district_id": "332903",
    "label": "Kalisumur",
    "value": "Kalisumur"
  },
  {
    "id": "3329032009",
    "district_id": "332903",
    "label": "Kaliwadas",
    "value": "Kaliwadas"
  },
  {
    "id": "3329032010",
    "district_id": "332903",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "3329032011",
    "district_id": "332903",
    "label": "Laren",
    "value": "Laren"
  },
  {
    "id": "3329032012",
    "district_id": "332903",
    "label": "Negaradaha",
    "value": "Negaradaha"
  },
  {
    "id": "3329032013",
    "district_id": "332903",
    "label": "Pamijen",
    "value": "Pamijen"
  },
  {
    "id": "3329032014",
    "district_id": "332903",
    "label": "Penggarutan",
    "value": "Penggarutan"
  },
  {
    "id": "3329032015",
    "district_id": "332903",
    "label": "Pruwatan",
    "value": "Pruwatan"
  },
  {
    "id": "3329042001",
    "district_id": "332904",
    "label": "Cilibur",
    "value": "Cilibur"
  },
  {
    "id": "3329042002",
    "district_id": "332904",
    "label": "Cipetung",
    "value": "Cipetung"
  },
  {
    "id": "3329042003",
    "district_id": "332904",
    "label": "Kedungoleng",
    "value": "Kedungoleng"
  },
  {
    "id": "3329042004",
    "district_id": "332904",
    "label": "Kretek",
    "value": "Kretek"
  },
  {
    "id": "3329042005",
    "district_id": "332904",
    "label": "Pagojengan",
    "value": "Pagojengan"
  },
  {
    "id": "3329042006",
    "district_id": "332904",
    "label": "Paguyangan",
    "value": "Paguyangan"
  },
  {
    "id": "3329042007",
    "district_id": "332904",
    "label": "Pakujati",
    "value": "Pakujati"
  },
  {
    "id": "3329042008",
    "district_id": "332904",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3329042009",
    "district_id": "332904",
    "label": "Ragatunjung",
    "value": "Ragatunjung"
  },
  {
    "id": "3329042010",
    "district_id": "332904",
    "label": "Taraban",
    "value": "Taraban"
  },
  {
    "id": "3329042011",
    "district_id": "332904",
    "label": "Wanatirta",
    "value": "Wanatirta"
  },
  {
    "id": "3329042012",
    "district_id": "332904",
    "label": "Winduaji",
    "value": "Winduaji"
  },
  {
    "id": "3329052001",
    "district_id": "332905",
    "label": "Batursari",
    "value": "Batursari"
  },
  {
    "id": "3329052002",
    "district_id": "332905",
    "label": "Benda",
    "value": "Benda"
  },
  {
    "id": "3329052003",
    "district_id": "332905",
    "label": "Buniwah",
    "value": "Buniwah"
  },
  {
    "id": "3329052004",
    "district_id": "332905",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3329052005",
    "district_id": "332905",
    "label": "Igirklanceng",
    "value": "Igirklanceng"
  },
  {
    "id": "3329052006",
    "district_id": "332905",
    "label": "Kaligiri",
    "value": "Kaligiri"
  },
  {
    "id": "3329052007",
    "district_id": "332905",
    "label": "Kaliloka",
    "value": "Kaliloka"
  },
  {
    "id": "3329052008",
    "district_id": "332905",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3329052009",
    "district_id": "332905",
    "label": "Mendala",
    "value": "Mendala"
  },
  {
    "id": "3329052010",
    "district_id": "332905",
    "label": "Mlayang",
    "value": "Mlayang"
  },
  {
    "id": "3329052011",
    "district_id": "332905",
    "label": "Sridadi",
    "value": "Sridadi"
  },
  {
    "id": "3329052012",
    "district_id": "332905",
    "label": "Plompong",
    "value": "Plompong"
  },
  {
    "id": "3329052013",
    "district_id": "332905",
    "label": "Wanareja",
    "value": "Wanareja"
  },
  {
    "id": "3329062001",
    "district_id": "332906",
    "label": "Galuhtimur",
    "value": "Galuhtimur"
  },
  {
    "id": "3329062002",
    "district_id": "332906",
    "label": "Kalijurang",
    "value": "Kalijurang"
  },
  {
    "id": "3329062003",
    "district_id": "332906",
    "label": "Karangjongkeng",
    "value": "Karangjongkeng"
  },
  {
    "id": "3329062004",
    "district_id": "332906",
    "label": "Kutamendala",
    "value": "Kutamendala"
  },
  {
    "id": "3329062005",
    "district_id": "332906",
    "label": "Kutayu",
    "value": "Kutayu"
  },
  {
    "id": "3329062006",
    "district_id": "332906",
    "label": "Linggapura",
    "value": "Linggapura"
  },
  {
    "id": "3329062007",
    "district_id": "332906",
    "label": "Negarayu",
    "value": "Negarayu"
  },
  {
    "id": "3329062008",
    "district_id": "332906",
    "label": "Pepedan",
    "value": "Pepedan"
  },
  {
    "id": "3329062009",
    "district_id": "332906",
    "label": "Purbayasa",
    "value": "Purbayasa"
  },
  {
    "id": "3329062010",
    "district_id": "332906",
    "label": "Rajawetan",
    "value": "Rajawetan"
  },
  {
    "id": "3329062011",
    "district_id": "332906",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3329062012",
    "district_id": "332906",
    "label": "Tanggeran",
    "value": "Tanggeran"
  },
  {
    "id": "3329062013",
    "district_id": "332906",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3329062014",
    "district_id": "332906",
    "label": "Watujaya",
    "value": "Watujaya"
  },
  {
    "id": "3329072001",
    "district_id": "332907",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3329072002",
    "district_id": "332907",
    "label": "Buaran",
    "value": "Buaran"
  },
  {
    "id": "3329072003",
    "district_id": "332907",
    "label": "Janegara",
    "value": "Janegara"
  },
  {
    "id": "3329072004",
    "district_id": "332907",
    "label": "Jatibarang Kidul",
    "value": "Jatibarang Kidul"
  },
  {
    "id": "3329072005",
    "district_id": "332907",
    "label": "Jatibarang Lor",
    "value": "Jatibarang Lor"
  },
  {
    "id": "3329072006",
    "district_id": "332907",
    "label": "Kalialang",
    "value": "Kalialang"
  },
  {
    "id": "3329072007",
    "district_id": "332907",
    "label": "Kalipucang",
    "value": "Kalipucang"
  },
  {
    "id": "3329072008",
    "district_id": "332907",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3329072009",
    "district_id": "332907",
    "label": "Kebogadung",
    "value": "Kebogadung"
  },
  {
    "id": "3329072010",
    "district_id": "332907",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3329072011",
    "district_id": "332907",
    "label": "Kedungtukang",
    "value": "Kedungtukang"
  },
  {
    "id": "3329072012",
    "district_id": "332907",
    "label": "Kemiriamba",
    "value": "Kemiriamba"
  },
  {
    "id": "3329072013",
    "district_id": "332907",
    "label": "Kendawa",
    "value": "Kendawa"
  },
  {
    "id": "3329072014",
    "district_id": "332907",
    "label": "Kertasinduyasa",
    "value": "Kertasinduyasa"
  },
  {
    "id": "3329072015",
    "district_id": "332907",
    "label": "Klampis",
    "value": "Klampis"
  },
  {
    "id": "3329072016",
    "district_id": "332907",
    "label": "Klikiran",
    "value": "Klikiran"
  },
  {
    "id": "3329072017",
    "district_id": "332907",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3329072018",
    "district_id": "332907",
    "label": "Pamengger",
    "value": "Pamengger"
  },
  {
    "id": "3329072019",
    "district_id": "332907",
    "label": "Pedeslohor",
    "value": "Pedeslohor"
  },
  {
    "id": "3329072020",
    "district_id": "332907",
    "label": "Rengasbandung",
    "value": "Rengasbandung"
  },
  {
    "id": "3329072021",
    "district_id": "332907",
    "label": "Tegalwulung",
    "value": "Tegalwulung"
  },
  {
    "id": "3329072022",
    "district_id": "332907",
    "label": "Tembelang",
    "value": "Tembelang"
  },
  {
    "id": "3329082001",
    "district_id": "332908",
    "label": "Glonggong",
    "value": "Glonggong"
  },
  {
    "id": "3329082002",
    "district_id": "332908",
    "label": "Dukuhwringin",
    "value": "Dukuhwringin"
  },
  {
    "id": "3329082003",
    "district_id": "332908",
    "label": "Dumeling",
    "value": "Dumeling"
  },
  {
    "id": "3329082004",
    "district_id": "332908",
    "label": "Jagalempeni",
    "value": "Jagalempeni"
  },
  {
    "id": "3329082005",
    "district_id": "332908",
    "label": "Keboledan",
    "value": "Keboledan"
  },
  {
    "id": "3329082006",
    "district_id": "332908",
    "label": "Kertabesuki",
    "value": "Kertabesuki"
  },
  {
    "id": "3329082007",
    "district_id": "332908",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3329082008",
    "district_id": "332908",
    "label": "Kupu",
    "value": "Kupu"
  },
  {
    "id": "3329082009",
    "district_id": "332908",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3329082010",
    "district_id": "332908",
    "label": "Pebatan",
    "value": "Pebatan"
  },
  {
    "id": "3329082011",
    "district_id": "332908",
    "label": "Pesantunan",
    "value": "Pesantunan"
  },
  {
    "id": "3329082012",
    "district_id": "332908",
    "label": "Sawojajar",
    "value": "Sawojajar"
  },
  {
    "id": "3329082013",
    "district_id": "332908",
    "label": "Siasem",
    "value": "Siasem"
  },
  {
    "id": "3329082014",
    "district_id": "332908",
    "label": "Sidamulya",
    "value": "Sidamulya"
  },
  {
    "id": "3329082015",
    "district_id": "332908",
    "label": "Sigentong",
    "value": "Sigentong"
  },
  {
    "id": "3329082016",
    "district_id": "332908",
    "label": "Sisalam",
    "value": "Sisalam"
  },
  {
    "id": "3329082017",
    "district_id": "332908",
    "label": "Siwungkuk",
    "value": "Siwungkuk"
  },
  {
    "id": "3329082018",
    "district_id": "332908",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3329082019",
    "district_id": "332908",
    "label": "Tegalgandu",
    "value": "Tegalgandu"
  },
  {
    "id": "3329082020",
    "district_id": "332908",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3329091002",
    "district_id": "332909",
    "label": "Brebes",
    "value": "Brebes"
  },
  {
    "id": "3329091003",
    "district_id": "332909",
    "label": "Gandasuli",
    "value": "Gandasuli"
  },
  {
    "id": "3329091011",
    "district_id": "332909",
    "label": "Limbangan Kulon",
    "value": "Limbangan Kulon"
  },
  {
    "id": "3329091012",
    "district_id": "332909",
    "label": "Limbangan Wetan",
    "value": "Limbangan Wetan"
  },
  {
    "id": "3329091015",
    "district_id": "332909",
    "label": "Pasarbatang",
    "value": "Pasarbatang"
  },
  {
    "id": "3329092001",
    "district_id": "332909",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3329092004",
    "district_id": "332909",
    "label": "Kaligangsa Kulon",
    "value": "Kaligangsa Kulon"
  },
  {
    "id": "3329092005",
    "district_id": "332909",
    "label": "Kaligangsa Wetan",
    "value": "Kaligangsa Wetan"
  },
  {
    "id": "3329092006",
    "district_id": "332909",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3329092007",
    "district_id": "332909",
    "label": "Kaliwlingi",
    "value": "Kaliwlingi"
  },
  {
    "id": "3329092008",
    "district_id": "332909",
    "label": "Kedunguter",
    "value": "Kedunguter"
  },
  {
    "id": "3329092009",
    "district_id": "332909",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3329092010",
    "district_id": "332909",
    "label": "Lembarawa",
    "value": "Lembarawa"
  },
  {
    "id": "3329092013",
    "district_id": "332909",
    "label": "Padasugih",
    "value": "Padasugih"
  },
  {
    "id": "3329092014",
    "district_id": "332909",
    "label": "Pagejugan",
    "value": "Pagejugan"
  },
  {
    "id": "3329092016",
    "district_id": "332909",
    "label": "Pemaron",
    "value": "Pemaron"
  },
  {
    "id": "3329092017",
    "district_id": "332909",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3329092018",
    "district_id": "332909",
    "label": "Randusanga Kulon",
    "value": "Randusanga Kulon"
  },
  {
    "id": "3329092019",
    "district_id": "332909",
    "label": "Randusanga Wetan",
    "value": "Randusanga Wetan"
  },
  {
    "id": "3329092020",
    "district_id": "332909",
    "label": "Sigambir",
    "value": "Sigambir"
  },
  {
    "id": "3329092021",
    "district_id": "332909",
    "label": "Tengki",
    "value": "Tengki"
  },
  {
    "id": "3329092022",
    "district_id": "332909",
    "label": "Terlangu",
    "value": "Terlangu"
  },
  {
    "id": "3329092023",
    "district_id": "332909",
    "label": "Wangandalem",
    "value": "Wangandalem"
  },
  {
    "id": "3329102001",
    "district_id": "332910",
    "label": "Cenang",
    "value": "Cenang"
  },
  {
    "id": "3329102002",
    "district_id": "332910",
    "label": "Dukuhmaja",
    "value": "Dukuhmaja"
  },
  {
    "id": "3329102003",
    "district_id": "332910",
    "label": "Jatirokeh",
    "value": "Jatirokeh"
  },
  {
    "id": "3329102004",
    "district_id": "332910",
    "label": "Karangsembung",
    "value": "Karangsembung"
  },
  {
    "id": "3329102005",
    "district_id": "332910",
    "label": "Songgom",
    "value": "Songgom"
  },
  {
    "id": "3329102006",
    "district_id": "332910",
    "label": "Wanacala",
    "value": "Wanacala"
  },
  {
    "id": "3329102007",
    "district_id": "332910",
    "label": "Wanatawang",
    "value": "Wanatawang"
  },
  {
    "id": "3329102008",
    "district_id": "332910",
    "label": "Gegerkunci",
    "value": "Gegerkunci"
  },
  {
    "id": "3329102009",
    "district_id": "332910",
    "label": "Jatimakmur",
    "value": "Jatimakmur"
  },
  {
    "id": "3329102010",
    "district_id": "332910",
    "label": "Songgom Lor",
    "value": "Songgom Lor"
  },
  {
    "id": "3329112001",
    "district_id": "332911",
    "label": "Ciampel",
    "value": "Ciampel"
  },
  {
    "id": "3329112002",
    "district_id": "332911",
    "label": "Cigedog",
    "value": "Cigedog"
  },
  {
    "id": "3329112003",
    "district_id": "332911",
    "label": "Cikandang",
    "value": "Cikandang"
  },
  {
    "id": "3329112004",
    "district_id": "332911",
    "label": "Jagapura",
    "value": "Jagapura"
  },
  {
    "id": "3329112005",
    "district_id": "332911",
    "label": "Kemukten",
    "value": "Kemukten"
  },
  {
    "id": "3329112006",
    "district_id": "332911",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3329112007",
    "district_id": "332911",
    "label": "Kersana",
    "value": "Kersana"
  },
  {
    "id": "3329112008",
    "district_id": "332911",
    "label": "Kramatsampang",
    "value": "Kramatsampang"
  },
  {
    "id": "3329112009",
    "district_id": "332911",
    "label": "Kubangpari",
    "value": "Kubangpari"
  },
  {
    "id": "3329112010",
    "district_id": "332911",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3329112011",
    "district_id": "332911",
    "label": "Pende",
    "value": "Pende"
  },
  {
    "id": "3329112012",
    "district_id": "332911",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3329112013",
    "district_id": "332911",
    "label": "Sutamaja",
    "value": "Sutamaja"
  },
  {
    "id": "3329122001",
    "district_id": "332912",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3329122002",
    "district_id": "332912",
    "label": "Blubuk",
    "value": "Blubuk"
  },
  {
    "id": "3329122003",
    "district_id": "332912",
    "label": "Bojongsari",
    "value": "Bojongsari"
  },
  {
    "id": "3329122004",
    "district_id": "332912",
    "label": "Dukuhsalam",
    "value": "Dukuhsalam"
  },
  {
    "id": "3329122005",
    "district_id": "332912",
    "label": "Jatisawit",
    "value": "Jatisawit"
  },
  {
    "id": "3329122006",
    "district_id": "332912",
    "label": "Kalibuntu",
    "value": "Kalibuntu"
  },
  {
    "id": "3329122007",
    "district_id": "332912",
    "label": "Karangdempel",
    "value": "Karangdempel"
  },
  {
    "id": "3329122008",
    "district_id": "332912",
    "label": "Karangjunti",
    "value": "Karangjunti"
  },
  {
    "id": "3329122009",
    "district_id": "332912",
    "label": "Karangsambung",
    "value": "Karangsambung"
  },
  {
    "id": "3329122010",
    "district_id": "332912",
    "label": "Kecipir",
    "value": "Kecipir"
  },
  {
    "id": "3329122011",
    "district_id": "332912",
    "label": "Kedungneng",
    "value": "Kedungneng"
  },
  {
    "id": "3329122012",
    "district_id": "332912",
    "label": "Limbangan",
    "value": "Limbangan"
  },
  {
    "id": "3329122013",
    "district_id": "332912",
    "label": "Losari Kidul",
    "value": "Losari Kidul"
  },
  {
    "id": "3329122014",
    "district_id": "332912",
    "label": "Losari Lor",
    "value": "Losari Lor"
  },
  {
    "id": "3329122015",
    "district_id": "332912",
    "label": "Negla",
    "value": "Negla"
  },
  {
    "id": "3329122016",
    "district_id": "332912",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3329122017",
    "district_id": "332912",
    "label": "Pengabean",
    "value": "Pengabean"
  },
  {
    "id": "3329122018",
    "district_id": "332912",
    "label": "Prapag Kidul",
    "value": "Prapag Kidul"
  },
  {
    "id": "3329122019",
    "district_id": "332912",
    "label": "Prapag Lor",
    "value": "Prapag Lor"
  },
  {
    "id": "3329122020",
    "district_id": "332912",
    "label": "Randegan",
    "value": "Randegan"
  },
  {
    "id": "3329122021",
    "district_id": "332912",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3329122022",
    "district_id": "332912",
    "label": "Rungkang",
    "value": "Rungkang"
  },
  {
    "id": "3329132001",
    "district_id": "332913",
    "label": "Karangreja",
    "value": "Karangreja"
  },
  {
    "id": "3329132002",
    "district_id": "332913",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3329132003",
    "district_id": "332913",
    "label": "Kemurang Kulon",
    "value": "Kemurang Kulon"
  },
  {
    "id": "3329132004",
    "district_id": "332913",
    "label": "Kemurang Wetan",
    "value": "Kemurang Wetan"
  },
  {
    "id": "3329132005",
    "district_id": "332913",
    "label": "Krakahan",
    "value": "Krakahan"
  },
  {
    "id": "3329132006",
    "district_id": "332913",
    "label": "Kupangputat",
    "value": "Kupangputat"
  },
  {
    "id": "3329132007",
    "district_id": "332913",
    "label": "Luwunggede",
    "value": "Luwunggede"
  },
  {
    "id": "3329132008",
    "district_id": "332913",
    "label": "Lemahabang",
    "value": "Lemahabang"
  },
  {
    "id": "3329132009",
    "district_id": "332913",
    "label": "Luwungbata",
    "value": "Luwungbata"
  },
  {
    "id": "3329132010",
    "district_id": "332913",
    "label": "Mundu",
    "value": "Mundu"
  },
  {
    "id": "3329132011",
    "district_id": "332913",
    "label": "Pejagan",
    "value": "Pejagan"
  },
  {
    "id": "3329132012",
    "district_id": "332913",
    "label": "Pengaradan",
    "value": "Pengaradan"
  },
  {
    "id": "3329132013",
    "district_id": "332913",
    "label": "Sarireja",
    "value": "Sarireja"
  },
  {
    "id": "3329132014",
    "district_id": "332913",
    "label": "Sengon",
    "value": "Sengon"
  },
  {
    "id": "3329132015",
    "district_id": "332913",
    "label": "Sidakaton",
    "value": "Sidakaton"
  },
  {
    "id": "3329132016",
    "district_id": "332913",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3329132017",
    "district_id": "332913",
    "label": "Tegongan",
    "value": "Tegongan"
  },
  {
    "id": "3329132018",
    "district_id": "332913",
    "label": "Tengguli",
    "value": "Tengguli"
  },
  {
    "id": "3329142001",
    "district_id": "332914",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3329142002",
    "district_id": "332914",
    "label": "Banjaratma",
    "value": "Banjaratma"
  },
  {
    "id": "3329142003",
    "district_id": "332914",
    "label": "Bulakamba",
    "value": "Bulakamba"
  },
  {
    "id": "3329142004",
    "district_id": "332914",
    "label": "Bulakparen",
    "value": "Bulakparen"
  },
  {
    "id": "3329142005",
    "district_id": "332914",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3329142006",
    "district_id": "332914",
    "label": "Cimohong",
    "value": "Cimohong"
  },
  {
    "id": "3329142007",
    "district_id": "332914",
    "label": "Cipelem",
    "value": "Cipelem"
  },
  {
    "id": "3329142008",
    "district_id": "332914",
    "label": "Dukuhlo",
    "value": "Dukuhlo"
  },
  {
    "id": "3329142009",
    "district_id": "332914",
    "label": "Grinting",
    "value": "Grinting"
  },
  {
    "id": "3329142010",
    "district_id": "332914",
    "label": "Jubang",
    "value": "Jubang"
  },
  {
    "id": "3329142011",
    "district_id": "332914",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3329142012",
    "district_id": "332914",
    "label": "Kluwut",
    "value": "Kluwut"
  },
  {
    "id": "3329142013",
    "district_id": "332914",
    "label": "Luwungragi",
    "value": "Luwungragi"
  },
  {
    "id": "3329142014",
    "district_id": "332914",
    "label": "Pakijangan",
    "value": "Pakijangan"
  },
  {
    "id": "3329142015",
    "district_id": "332914",
    "label": "Petunjungan",
    "value": "Petunjungan"
  },
  {
    "id": "3329142016",
    "district_id": "332914",
    "label": "Pulogading",
    "value": "Pulogading"
  },
  {
    "id": "3329142017",
    "district_id": "332914",
    "label": "Rancawuluh",
    "value": "Rancawuluh"
  },
  {
    "id": "3329142018",
    "district_id": "332914",
    "label": "Siwuluh",
    "value": "Siwuluh"
  },
  {
    "id": "3329142019",
    "district_id": "332914",
    "label": "Tegalglagah",
    "value": "Tegalglagah"
  },
  {
    "id": "3329152001",
    "district_id": "332915",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "3329152002",
    "district_id": "332915",
    "label": "Karangbale",
    "value": "Karangbale"
  },
  {
    "id": "3329152003",
    "district_id": "332915",
    "label": "Kedungbokor",
    "value": "Kedungbokor"
  },
  {
    "id": "3329152004",
    "district_id": "332915",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3329152005",
    "district_id": "332915",
    "label": "Luwunggede",
    "value": "Luwunggede"
  },
  {
    "id": "3329152006",
    "district_id": "332915",
    "label": "Pamulihan",
    "value": "Pamulihan"
  },
  {
    "id": "3329152007",
    "district_id": "332915",
    "label": "Rengaspendawa",
    "value": "Rengaspendawa"
  },
  {
    "id": "3329152008",
    "district_id": "332915",
    "label": "Sitanggal",
    "value": "Sitanggal"
  },
  {
    "id": "3329152009",
    "district_id": "332915",
    "label": "Slatri",
    "value": "Slatri"
  },
  {
    "id": "3329152010",
    "district_id": "332915",
    "label": "Wlahar",
    "value": "Wlahar"
  },
  {
    "id": "3329152011",
    "district_id": "332915",
    "label": "Siandong",
    "value": "Siandong"
  },
  {
    "id": "3329162001",
    "district_id": "332916",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "3329162002",
    "district_id": "332916",
    "label": "Buara",
    "value": "Buara"
  },
  {
    "id": "3329162003",
    "district_id": "332916",
    "label": "Bulakelor",
    "value": "Bulakelor"
  },
  {
    "id": "3329162004",
    "district_id": "332916",
    "label": "Ciduwet",
    "value": "Ciduwet"
  },
  {
    "id": "3329162005",
    "district_id": "332916",
    "label": "Cikeusal Kidul",
    "value": "Cikeusal Kidul"
  },
  {
    "id": "3329162006",
    "district_id": "332916",
    "label": "Cikeusal Lor",
    "value": "Cikeusal Lor"
  },
  {
    "id": "3329162007",
    "district_id": "332916",
    "label": "Ciseureuh",
    "value": "Ciseureuh"
  },
  {
    "id": "3329162008",
    "district_id": "332916",
    "label": "Dukuhbadag",
    "value": "Dukuhbadag"
  },
  {
    "id": "3329162009",
    "district_id": "332916",
    "label": "Dukuhtengah",
    "value": "Dukuhtengah"
  },
  {
    "id": "3329162010",
    "district_id": "332916",
    "label": "Jemasih",
    "value": "Jemasih"
  },
  {
    "id": "3329162011",
    "district_id": "332916",
    "label": "Karangbandung",
    "value": "Karangbandung"
  },
  {
    "id": "3329162012",
    "district_id": "332916",
    "label": "Ketanggungan",
    "value": "Ketanggungan"
  },
  {
    "id": "3329162013",
    "district_id": "332916",
    "label": "Kubangjati",
    "value": "Kubangjati"
  },
  {
    "id": "3329162014",
    "district_id": "332916",
    "label": "Kubangwungu",
    "value": "Kubangwungu"
  },
  {
    "id": "3329162015",
    "district_id": "332916",
    "label": "Kubangsari",
    "value": "Kubangsari"
  },
  {
    "id": "3329162016",
    "district_id": "332916",
    "label": "Pamedaran",
    "value": "Pamedaran"
  },
  {
    "id": "3329162017",
    "district_id": "332916",
    "label": "Sindangjaya",
    "value": "Sindangjaya"
  },
  {
    "id": "3329162018",
    "district_id": "332916",
    "label": "Tanggungsari",
    "value": "Tanggungsari"
  },
  {
    "id": "3329162019",
    "district_id": "332916",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3329162020",
    "district_id": "332916",
    "label": "Dukuhturi",
    "value": "Dukuhturi"
  },
  {
    "id": "3329162021",
    "district_id": "332916",
    "label": "Padakaton",
    "value": "Padakaton"
  },
  {
    "id": "3329172001",
    "district_id": "332917",
    "label": "Bandungsari",
    "value": "Bandungsari"
  },
  {
    "id": "3329172002",
    "district_id": "332917",
    "label": "Banjarlor",
    "value": "Banjarlor"
  },
  {
    "id": "3329172003",
    "district_id": "332917",
    "label": "Banjarharjo",
    "value": "Banjarharjo"
  },
  {
    "id": "3329172004",
    "district_id": "332917",
    "label": "Blandongan",
    "value": "Blandongan"
  },
  {
    "id": "3329172005",
    "district_id": "332917",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3329172006",
    "district_id": "332917",
    "label": "Cibendung",
    "value": "Cibendung"
  },
  {
    "id": "3329172007",
    "district_id": "332917",
    "label": "Cibuniwangi",
    "value": "Cibuniwangi"
  },
  {
    "id": "3329172008",
    "district_id": "332917",
    "label": "Cigadung",
    "value": "Cigadung"
  },
  {
    "id": "3329172009",
    "district_id": "332917",
    "label": "Cihaur",
    "value": "Cihaur"
  },
  {
    "id": "3329172010",
    "district_id": "332917",
    "label": "Cikakak",
    "value": "Cikakak"
  },
  {
    "id": "3329172011",
    "district_id": "332917",
    "label": "Cikuya",
    "value": "Cikuya"
  },
  {
    "id": "3329172012",
    "district_id": "332917",
    "label": "Cimunding",
    "value": "Cimunding"
  },
  {
    "id": "3329172013",
    "district_id": "332917",
    "label": "Cipajang",
    "value": "Cipajang"
  },
  {
    "id": "3329172014",
    "district_id": "332917",
    "label": "Dukuhjeruk",
    "value": "Dukuhjeruk"
  },
  {
    "id": "3329172015",
    "district_id": "332917",
    "label": "Karangmaja",
    "value": "Karangmaja"
  },
  {
    "id": "3329172016",
    "district_id": "332917",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "3329172017",
    "district_id": "332917",
    "label": "Kubangjero",
    "value": "Kubangjero"
  },
  {
    "id": "3329172018",
    "district_id": "332917",
    "label": "Malahayu",
    "value": "Malahayu"
  },
  {
    "id": "3329172019",
    "district_id": "332917",
    "label": "Parereja",
    "value": "Parereja"
  },
  {
    "id": "3329172020",
    "district_id": "332917",
    "label": "Penanggapan",
    "value": "Penanggapan"
  },
  {
    "id": "3329172021",
    "district_id": "332917",
    "label": "Pende",
    "value": "Pende"
  },
  {
    "id": "3329172022",
    "district_id": "332917",
    "label": "Sindangheula",
    "value": "Sindangheula"
  },
  {
    "id": "3329172023",
    "district_id": "332917",
    "label": "Sukareja",
    "value": "Sukareja"
  },
  {
    "id": "3329172024",
    "district_id": "332917",
    "label": "Tegalreja",
    "value": "Tegalreja"
  },
  {
    "id": "3329172025",
    "district_id": "332917",
    "label": "Tiwulandu",
    "value": "Tiwulandu"
  },
  {
    "id": "3371011003",
    "district_id": "337101",
    "label": "Rejowinangun Selatan",
    "value": "Rejowinangun Selatan"
  },
  {
    "id": "3371011007",
    "district_id": "337101",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3371011008",
    "district_id": "337101",
    "label": "Jurangombo Utara",
    "value": "Jurangombo Utara"
  },
  {
    "id": "3371011009",
    "district_id": "337101",
    "label": "Jurangombo Selatan",
    "value": "Jurangombo Selatan"
  },
  {
    "id": "3371011010",
    "district_id": "337101",
    "label": "Tidar Utara",
    "value": "Tidar Utara"
  },
  {
    "id": "3371011011",
    "district_id": "337101",
    "label": "Tidar Selatan",
    "value": "Tidar Selatan"
  },
  {
    "id": "3371021003",
    "district_id": "337102",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3371021004",
    "district_id": "337102",
    "label": "Potrobangsan",
    "value": "Potrobangsan"
  },
  {
    "id": "3371021005",
    "district_id": "337102",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3371021008",
    "district_id": "337102",
    "label": "Kramat Utara",
    "value": "Kramat Utara"
  },
  {
    "id": "3371021009",
    "district_id": "337102",
    "label": "Kramat Selatan",
    "value": "Kramat Selatan"
  },
  {
    "id": "3371031001",
    "district_id": "337103",
    "label": "Kemirirejo",
    "value": "Kemirirejo"
  },
  {
    "id": "3371031002",
    "district_id": "337103",
    "label": "Cacaban",
    "value": "Cacaban"
  },
  {
    "id": "3371031003",
    "district_id": "337103",
    "label": "Rejowinangun Utara",
    "value": "Rejowinangun Utara"
  },
  {
    "id": "3371031004",
    "district_id": "337103",
    "label": "Magelang",
    "value": "Magelang"
  },
  {
    "id": "3371031005",
    "district_id": "337103",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "3371031006",
    "district_id": "337103",
    "label": "Gelangan",
    "value": "Gelangan"
  },
  {
    "id": "3372011001",
    "district_id": "337201",
    "label": "Pajang",
    "value": "Pajang"
  },
  {
    "id": "3372011002",
    "district_id": "337201",
    "label": "Laweyan",
    "value": "Laweyan"
  },
  {
    "id": "3372011003",
    "district_id": "337201",
    "label": "Bumi",
    "value": "Bumi"
  },
  {
    "id": "3372011004",
    "district_id": "337201",
    "label": "Panularan",
    "value": "Panularan"
  },
  {
    "id": "3372011005",
    "district_id": "337201",
    "label": "Penumping",
    "value": "Penumping"
  },
  {
    "id": "3372011006",
    "district_id": "337201",
    "label": "Sriwedari",
    "value": "Sriwedari"
  },
  {
    "id": "3372011007",
    "district_id": "337201",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3372011008",
    "district_id": "337201",
    "label": "Sondakan",
    "value": "Sondakan"
  },
  {
    "id": "3372011009",
    "district_id": "337201",
    "label": "Kerten",
    "value": "Kerten"
  },
  {
    "id": "3372011010",
    "district_id": "337201",
    "label": "Jajar",
    "value": "Jajar"
  },
  {
    "id": "3372011011",
    "district_id": "337201",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3372021001",
    "district_id": "337202",
    "label": "Joyotakan",
    "value": "Joyotakan"
  },
  {
    "id": "3372021002",
    "district_id": "337202",
    "label": "Danukusuman",
    "value": "Danukusuman"
  },
  {
    "id": "3372021003",
    "district_id": "337202",
    "label": "Serengan",
    "value": "Serengan"
  },
  {
    "id": "3372021004",
    "district_id": "337202",
    "label": "Tipes",
    "value": "Tipes"
  },
  {
    "id": "3372021005",
    "district_id": "337202",
    "label": "Kratonan",
    "value": "Kratonan"
  },
  {
    "id": "3372021006",
    "district_id": "337202",
    "label": "Jayengan",
    "value": "Jayengan"
  },
  {
    "id": "3372021007",
    "district_id": "337202",
    "label": "Kemlayan",
    "value": "Kemlayan"
  },
  {
    "id": "3372031001",
    "district_id": "337203",
    "label": "Joyosuran",
    "value": "Joyosuran"
  },
  {
    "id": "3372031002",
    "district_id": "337203",
    "label": "Semanggi",
    "value": "Semanggi"
  },
  {
    "id": "3372031003",
    "district_id": "337203",
    "label": "Pasarkliwon",
    "value": "Pasarkliwon"
  },
  {
    "id": "3372031004",
    "district_id": "337203",
    "label": "Gajahan",
    "value": "Gajahan"
  },
  {
    "id": "3372031005",
    "district_id": "337203",
    "label": "Baluwarti",
    "value": "Baluwarti"
  },
  {
    "id": "3372031006",
    "district_id": "337203",
    "label": "Kampungbaru",
    "value": "Kampungbaru"
  },
  {
    "id": "3372031007",
    "district_id": "337203",
    "label": "Kedunglumbu",
    "value": "Kedunglumbu"
  },
  {
    "id": "3372031008",
    "district_id": "337203",
    "label": "Sangkrah",
    "value": "Sangkrah"
  },
  {
    "id": "3372031009",
    "district_id": "337203",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3372031010",
    "district_id": "337203",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3372041001",
    "district_id": "337204",
    "label": "Kepatihan Kulon",
    "value": "Kepatihan Kulon"
  },
  {
    "id": "3372041002",
    "district_id": "337204",
    "label": "Kepatihan Wetan",
    "value": "Kepatihan Wetan"
  },
  {
    "id": "3372041003",
    "district_id": "337204",
    "label": "Sudiroprajan",
    "value": "Sudiroprajan"
  },
  {
    "id": "3372041004",
    "district_id": "337204",
    "label": "Gandekan",
    "value": "Gandekan"
  },
  {
    "id": "3372041005",
    "district_id": "337204",
    "label": "Sewu",
    "value": "Sewu"
  },
  {
    "id": "3372041006",
    "district_id": "337204",
    "label": "Pucangsawit",
    "value": "Pucangsawit"
  },
  {
    "id": "3372041007",
    "district_id": "337204",
    "label": "Jagalan",
    "value": "Jagalan"
  },
  {
    "id": "3372041008",
    "district_id": "337204",
    "label": "Purwodiningratan",
    "value": "Purwodiningratan"
  },
  {
    "id": "3372041009",
    "district_id": "337204",
    "label": "Tegalharjo",
    "value": "Tegalharjo"
  },
  {
    "id": "3372041010",
    "district_id": "337204",
    "label": "Jebres",
    "value": "Jebres"
  },
  {
    "id": "3372041011",
    "district_id": "337204",
    "label": "Mojosongo",
    "value": "Mojosongo"
  },
  {
    "id": "3372051001",
    "district_id": "337205",
    "label": "Kadipiro",
    "value": "Kadipiro"
  },
  {
    "id": "3372051002",
    "district_id": "337205",
    "label": "Nusukan",
    "value": "Nusukan"
  },
  {
    "id": "3372051003",
    "district_id": "337205",
    "label": "Gilingan",
    "value": "Gilingan"
  },
  {
    "id": "3372051004",
    "district_id": "337205",
    "label": "Setabelan",
    "value": "Setabelan"
  },
  {
    "id": "3372051005",
    "district_id": "337205",
    "label": "Kestalan",
    "value": "Kestalan"
  },
  {
    "id": "3372051006",
    "district_id": "337205",
    "label": "Keprabon",
    "value": "Keprabon"
  },
  {
    "id": "3372051007",
    "district_id": "337205",
    "label": "Timuran",
    "value": "Timuran"
  },
  {
    "id": "3372051008",
    "district_id": "337205",
    "label": "Ketelan",
    "value": "Ketelan"
  },
  {
    "id": "3372051009",
    "district_id": "337205",
    "label": "Punggawan",
    "value": "Punggawan"
  },
  {
    "id": "3372051010",
    "district_id": "337205",
    "label": "Mangkubumen",
    "value": "Mangkubumen"
  },
  {
    "id": "3372051011",
    "district_id": "337205",
    "label": "Manahan",
    "value": "Manahan"
  },
  {
    "id": "3372051012",
    "district_id": "337205",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3372051013",
    "district_id": "337205",
    "label": "Banyuanyar",
    "value": "Banyuanyar"
  },
  {
    "id": "3372051014",
    "district_id": "337205",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3372051015",
    "district_id": "337205",
    "label": "Joglo",
    "value": "Joglo"
  },
  {
    "id": "3373011001",
    "district_id": "337301",
    "label": "Blotongan",
    "value": "Blotongan"
  },
  {
    "id": "3373011002",
    "district_id": "337301",
    "label": "Sidorejo Lor",
    "value": "Sidorejo Lor"
  },
  {
    "id": "3373011003",
    "district_id": "337301",
    "label": "Salatiga",
    "value": "Salatiga"
  },
  {
    "id": "3373011004",
    "district_id": "337301",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3373011005",
    "district_id": "337301",
    "label": "Kauman Kidul",
    "value": "Kauman Kidul"
  },
  {
    "id": "3373011006",
    "district_id": "337301",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "3373021001",
    "district_id": "337302",
    "label": "Kutowinangun Kidul",
    "value": "Kutowinangun Kidul"
  },
  {
    "id": "3373021002",
    "district_id": "337302",
    "label": "Gendongan",
    "value": "Gendongan"
  },
  {
    "id": "3373021003",
    "district_id": "337302",
    "label": "Sidorejo Kidul",
    "value": "Sidorejo Kidul"
  },
  {
    "id": "3373021004",
    "district_id": "337302",
    "label": "Kalibening",
    "value": "Kalibening"
  },
  {
    "id": "3373021005",
    "district_id": "337302",
    "label": "Tingkir Lor",
    "value": "Tingkir Lor"
  },
  {
    "id": "3373021006",
    "district_id": "337302",
    "label": "Tingkir Tengah",
    "value": "Tingkir Tengah"
  },
  {
    "id": "3373021007",
    "district_id": "337302",
    "label": "Kutowinangun Lor",
    "value": "Kutowinangun Lor"
  },
  {
    "id": "3373031001",
    "district_id": "337303",
    "label": "Noborejo",
    "value": "Noborejo"
  },
  {
    "id": "3373031002",
    "district_id": "337303",
    "label": "Ledok",
    "value": "Ledok"
  },
  {
    "id": "3373031003",
    "district_id": "337303",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3373031004",
    "district_id": "337303",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3373031005",
    "district_id": "337303",
    "label": "Randuacir",
    "value": "Randuacir"
  },
  {
    "id": "3373031006",
    "district_id": "337303",
    "label": "Cebongan",
    "value": "Cebongan"
  },
  {
    "id": "3373041001",
    "district_id": "337304",
    "label": "Kecandran",
    "value": "Kecandran"
  },
  {
    "id": "3373041002",
    "district_id": "337304",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3373041003",
    "district_id": "337304",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3373041004",
    "district_id": "337304",
    "label": "Kalicacing",
    "value": "Kalicacing"
  },
  {
    "id": "3374011001",
    "district_id": "337401",
    "label": "Miroto",
    "value": "Miroto"
  },
  {
    "id": "3374011002",
    "district_id": "337401",
    "label": "Brumbungan",
    "value": "Brumbungan"
  },
  {
    "id": "3374011003",
    "district_id": "337401",
    "label": "Jagalan",
    "value": "Jagalan"
  },
  {
    "id": "3374011004",
    "district_id": "337401",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3374011005",
    "district_id": "337401",
    "label": "Gabahan",
    "value": "Gabahan"
  },
  {
    "id": "3374011006",
    "district_id": "337401",
    "label": "Kembangsari",
    "value": "Kembangsari"
  },
  {
    "id": "3374011007",
    "district_id": "337401",
    "label": "Sekayu",
    "value": "Sekayu"
  },
  {
    "id": "3374011008",
    "district_id": "337401",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3374011009",
    "district_id": "337401",
    "label": "Bangunharjo",
    "value": "Bangunharjo"
  },
  {
    "id": "3374011010",
    "district_id": "337401",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3374011011",
    "district_id": "337401",
    "label": "Purwodinatan",
    "value": "Purwodinatan"
  },
  {
    "id": "3374011012",
    "district_id": "337401",
    "label": "Karang Kidul",
    "value": "Karang Kidul"
  },
  {
    "id": "3374011013",
    "district_id": "337401",
    "label": "Pekunden",
    "value": "Pekunden"
  },
  {
    "id": "3374011014",
    "district_id": "337401",
    "label": "Pindrikan Kidul",
    "value": "Pindrikan Kidul"
  },
  {
    "id": "3374011015",
    "district_id": "337401",
    "label": "Pindrikan Lor",
    "value": "Pindrikan Lor"
  },
  {
    "id": "3374021001",
    "district_id": "337402",
    "label": "Bandarharjo",
    "value": "Bandarharjo"
  },
  {
    "id": "3374021002",
    "district_id": "337402",
    "label": "Bulu Lor",
    "value": "Bulu Lor"
  },
  {
    "id": "3374021003",
    "district_id": "337402",
    "label": "Plombokan",
    "value": "Plombokan"
  },
  {
    "id": "3374021004",
    "district_id": "337402",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3374021005",
    "district_id": "337402",
    "label": "Kuningan",
    "value": "Kuningan"
  },
  {
    "id": "3374021006",
    "district_id": "337402",
    "label": "Panggung Lor",
    "value": "Panggung Lor"
  },
  {
    "id": "3374021007",
    "district_id": "337402",
    "label": "Panggung Kidul",
    "value": "Panggung Kidul"
  },
  {
    "id": "3374021008",
    "district_id": "337402",
    "label": "Tanjung Mas",
    "value": "Tanjung Mas"
  },
  {
    "id": "3374021009",
    "district_id": "337402",
    "label": "Dadapsari",
    "value": "Dadapsari"
  },
  {
    "id": "3374031001",
    "district_id": "337403",
    "label": "Kemijen",
    "value": "Kemijen"
  },
  {
    "id": "3374031002",
    "district_id": "337403",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3374031003",
    "district_id": "337403",
    "label": "Mlatibaru",
    "value": "Mlatibaru"
  },
  {
    "id": "3374031004",
    "district_id": "337403",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3374031005",
    "district_id": "337403",
    "label": "Mlatiharjo",
    "value": "Mlatiharjo"
  },
  {
    "id": "3374031006",
    "district_id": "337403",
    "label": "Bugangan",
    "value": "Bugangan"
  },
  {
    "id": "3374031007",
    "district_id": "337403",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3374031008",
    "district_id": "337403",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3374031009",
    "district_id": "337403",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3374031010",
    "district_id": "337403",
    "label": "Karangtempel",
    "value": "Karangtempel"
  },
  {
    "id": "3374041001",
    "district_id": "337404",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3374041002",
    "district_id": "337404",
    "label": "Kaligawe",
    "value": "Kaligawe"
  },
  {
    "id": "3374041003",
    "district_id": "337404",
    "label": "Sawah Besar",
    "value": "Sawah Besar"
  },
  {
    "id": "3374041004",
    "district_id": "337404",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3374041005",
    "district_id": "337404",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "3374041006",
    "district_id": "337404",
    "label": "Pandean Lamper",
    "value": "Pandean Lamper"
  },
  {
    "id": "3374041007",
    "district_id": "337404",
    "label": "Gayamsari",
    "value": "Gayamsari"
  },
  {
    "id": "3374051001",
    "district_id": "337405",
    "label": "Sembungharjo",
    "value": "Sembungharjo"
  },
  {
    "id": "3374051002",
    "district_id": "337405",
    "label": "Kudu",
    "value": "Kudu"
  },
  {
    "id": "3374051003",
    "district_id": "337405",
    "label": "Karangroto",
    "value": "Karangroto"
  },
  {
    "id": "3374051004",
    "district_id": "337405",
    "label": "Genuksari",
    "value": "Genuksari"
  },
  {
    "id": "3374051005",
    "district_id": "337405",
    "label": "Banjardowo",
    "value": "Banjardowo"
  },
  {
    "id": "3374051006",
    "district_id": "337405",
    "label": "Gebangsari",
    "value": "Gebangsari"
  },
  {
    "id": "3374051007",
    "district_id": "337405",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3374051008",
    "district_id": "337405",
    "label": "Penggaron Lor",
    "value": "Penggaron Lor"
  },
  {
    "id": "3374051009",
    "district_id": "337405",
    "label": "Muktiharjo Lor",
    "value": "Muktiharjo Lor"
  },
  {
    "id": "3374051010",
    "district_id": "337405",
    "label": "Bangetayu Kulon",
    "value": "Bangetayu Kulon"
  },
  {
    "id": "3374051011",
    "district_id": "337405",
    "label": "Bangetayu Wetan",
    "value": "Bangetayu Wetan"
  },
  {
    "id": "3374051012",
    "district_id": "337405",
    "label": "Terboyo Kulon",
    "value": "Terboyo Kulon"
  },
  {
    "id": "3374051013",
    "district_id": "337405",
    "label": "Terboyo Wetan",
    "value": "Terboyo Wetan"
  },
  {
    "id": "3374061001",
    "district_id": "337406",
    "label": "Penggaron Kidul",
    "value": "Penggaron Kidul"
  },
  {
    "id": "3374061002",
    "district_id": "337406",
    "label": "Tlogomulyo",
    "value": "Tlogomulyo"
  },
  {
    "id": "3374061003",
    "district_id": "337406",
    "label": "Tlogosari Wetan",
    "value": "Tlogosari Wetan"
  },
  {
    "id": "3374061004",
    "district_id": "337406",
    "label": "Tlogosari Kulon",
    "value": "Tlogosari Kulon"
  },
  {
    "id": "3374061005",
    "district_id": "337406",
    "label": "Muktiharjo Kidul",
    "value": "Muktiharjo Kidul"
  },
  {
    "id": "3374061006",
    "district_id": "337406",
    "label": "Plamongansari",
    "value": "Plamongansari"
  },
  {
    "id": "3374061007",
    "district_id": "337406",
    "label": "Gemah",
    "value": "Gemah"
  },
  {
    "id": "3374061008",
    "district_id": "337406",
    "label": "Pedurungan Kidul",
    "value": "Pedurungan Kidul"
  },
  {
    "id": "3374061009",
    "district_id": "337406",
    "label": "Pedurungan Lor",
    "value": "Pedurungan Lor"
  },
  {
    "id": "3374061010",
    "district_id": "337406",
    "label": "Pedurungan Tengah",
    "value": "Pedurungan Tengah"
  },
  {
    "id": "3374061011",
    "district_id": "337406",
    "label": "Palebon",
    "value": "Palebon"
  },
  {
    "id": "3374061012",
    "district_id": "337406",
    "label": "Kalicari",
    "value": "Kalicari"
  },
  {
    "id": "3374071001",
    "district_id": "337407",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3374071002",
    "district_id": "337407",
    "label": "Bulustalan",
    "value": "Bulustalan"
  },
  {
    "id": "3374071003",
    "district_id": "337407",
    "label": "Barusari",
    "value": "Barusari"
  },
  {
    "id": "3374071004",
    "district_id": "337407",
    "label": "Mugasari",
    "value": "Mugasari"
  },
  {
    "id": "3374071005",
    "district_id": "337407",
    "label": "Pleburan",
    "value": "Pleburan"
  },
  {
    "id": "3374071006",
    "district_id": "337407",
    "label": "Wonodri",
    "value": "Wonodri"
  },
  {
    "id": "3374071007",
    "district_id": "337407",
    "label": "Peterongan",
    "value": "Peterongan"
  },
  {
    "id": "3374071008",
    "district_id": "337407",
    "label": "Lamper Lor",
    "value": "Lamper Lor"
  },
  {
    "id": "3374071009",
    "district_id": "337407",
    "label": "Lamper Kidul",
    "value": "Lamper Kidul"
  },
  {
    "id": "3374071010",
    "district_id": "337407",
    "label": "Lamper Tengah",
    "value": "Lamper Tengah"
  },
  {
    "id": "3374081001",
    "district_id": "337408",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3374081002",
    "district_id": "337408",
    "label": "Jatingaleh",
    "value": "Jatingaleh"
  },
  {
    "id": "3374081003",
    "district_id": "337408",
    "label": "Kaliwiru",
    "value": "Kaliwiru"
  },
  {
    "id": "3374081004",
    "district_id": "337408",
    "label": "Jomblang",
    "value": "Jomblang"
  },
  {
    "id": "3374081005",
    "district_id": "337408",
    "label": "Karanganyar Gunung",
    "value": "Karanganyar Gunung"
  },
  {
    "id": "3374081006",
    "district_id": "337408",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3374081007",
    "district_id": "337408",
    "label": "Wonotinggal",
    "value": "Wonotinggal"
  },
  {
    "id": "3374091001",
    "district_id": "337409",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3374091002",
    "district_id": "337409",
    "label": "Bendan Dhuwur",
    "value": "Bendan Dhuwur"
  },
  {
    "id": "3374091003",
    "district_id": "337409",
    "label": "Bendan Ngisor",
    "value": "Bendan Ngisor"
  },
  {
    "id": "3374091004",
    "district_id": "337409",
    "label": "Sampangan",
    "value": "Sampangan"
  },
  {
    "id": "3374091005",
    "district_id": "337409",
    "label": "Gajahmungkur",
    "value": "Gajahmungkur"
  },
  {
    "id": "3374091006",
    "district_id": "337409",
    "label": "Lempongsari",
    "value": "Lempongsari"
  },
  {
    "id": "3374091007",
    "district_id": "337409",
    "label": "Petompon",
    "value": "Petompon"
  },
  {
    "id": "3374091008",
    "district_id": "337409",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3374101001",
    "district_id": "337410",
    "label": "Meteseh",
    "value": "Meteseh"
  },
  {
    "id": "3374101002",
    "district_id": "337410",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3374101003",
    "district_id": "337410",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3374101004",
    "district_id": "337410",
    "label": "Bulusan",
    "value": "Bulusan"
  },
  {
    "id": "3374101005",
    "district_id": "337410",
    "label": "Kramas",
    "value": "Kramas"
  },
  {
    "id": "3374101006",
    "district_id": "337410",
    "label": "Tembalang",
    "value": "Tembalang"
  },
  {
    "id": "3374101007",
    "district_id": "337410",
    "label": "Jangli",
    "value": "Jangli"
  },
  {
    "id": "3374101008",
    "district_id": "337410",
    "label": "Tandang",
    "value": "Tandang"
  },
  {
    "id": "3374101009",
    "district_id": "337410",
    "label": "Kedungmundu",
    "value": "Kedungmundu"
  },
  {
    "id": "3374101010",
    "district_id": "337410",
    "label": "Sendangguwo",
    "value": "Sendangguwo"
  },
  {
    "id": "3374101011",
    "district_id": "337410",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3374101012",
    "district_id": "337410",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3374111001",
    "district_id": "337411",
    "label": "Pudakpayung",
    "value": "Pudakpayung"
  },
  {
    "id": "3374111002",
    "district_id": "337411",
    "label": "Gedawang",
    "value": "Gedawang"
  },
  {
    "id": "3374111003",
    "district_id": "337411",
    "label": "Jabungan",
    "value": "Jabungan"
  },
  {
    "id": "3374111004",
    "district_id": "337411",
    "label": "Pedalangan",
    "value": "Pedalangan"
  },
  {
    "id": "3374111005",
    "district_id": "337411",
    "label": "Banyumanik",
    "value": "Banyumanik"
  },
  {
    "id": "3374111006",
    "district_id": "337411",
    "label": "Srondol Kulon",
    "value": "Srondol Kulon"
  },
  {
    "id": "3374111007",
    "district_id": "337411",
    "label": "Srondol Wetan",
    "value": "Srondol Wetan"
  },
  {
    "id": "3374111008",
    "district_id": "337411",
    "label": "Ngesrep",
    "value": "Ngesrep"
  },
  {
    "id": "3374111009",
    "district_id": "337411",
    "label": "Tinjomoyo",
    "value": "Tinjomoyo"
  },
  {
    "id": "3374111010",
    "district_id": "337411",
    "label": "Padangsari",
    "value": "Padangsari"
  },
  {
    "id": "3374111011",
    "district_id": "337411",
    "label": "Sumurboto",
    "value": "Sumurboto"
  },
  {
    "id": "3374121001",
    "district_id": "337412",
    "label": "Pakintelan",
    "value": "Pakintelan"
  },
  {
    "id": "3374121002",
    "district_id": "337412",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3374121003",
    "district_id": "337412",
    "label": "Plalangan",
    "value": "Plalangan"
  },
  {
    "id": "3374121004",
    "district_id": "337412",
    "label": "Gunungpati",
    "value": "Gunungpati"
  },
  {
    "id": "3374121005",
    "district_id": "337412",
    "label": "Nongkosawit",
    "value": "Nongkosawit"
  },
  {
    "id": "3374121006",
    "district_id": "337412",
    "label": "Pongangan",
    "value": "Pongangan"
  },
  {
    "id": "3374121007",
    "district_id": "337412",
    "label": "Ngijo",
    "value": "Ngijo"
  },
  {
    "id": "3374121008",
    "district_id": "337412",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3374121009",
    "district_id": "337412",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3374121010",
    "district_id": "337412",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3374121011",
    "district_id": "337412",
    "label": "Sadeng",
    "value": "Sadeng"
  },
  {
    "id": "3374121012",
    "district_id": "337412",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3374121013",
    "district_id": "337412",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3374121014",
    "district_id": "337412",
    "label": "Sumurrejo",
    "value": "Sumurrejo"
  },
  {
    "id": "3374121015",
    "district_id": "337412",
    "label": "Kalisegoro",
    "value": "Kalisegoro"
  },
  {
    "id": "3374121016",
    "district_id": "337412",
    "label": "Kandri",
    "value": "Kandri"
  },
  {
    "id": "3374131001",
    "district_id": "337413",
    "label": "Ngemplak Simongan",
    "value": "Ngemplak Simongan"
  },
  {
    "id": "3374131002",
    "district_id": "337413",
    "label": "Manyaran",
    "value": "Manyaran"
  },
  {
    "id": "3374131003",
    "district_id": "337413",
    "label": "Krapyak",
    "value": "Krapyak"
  },
  {
    "id": "3374131004",
    "district_id": "337413",
    "label": "Tambakharjo",
    "value": "Tambakharjo"
  },
  {
    "id": "3374131005",
    "district_id": "337413",
    "label": "Kalibanteng Kulon",
    "value": "Kalibanteng Kulon"
  },
  {
    "id": "3374131006",
    "district_id": "337413",
    "label": "Kalibanteng Kidul",
    "value": "Kalibanteng Kidul"
  },
  {
    "id": "3374131007",
    "district_id": "337413",
    "label": "Gisikdrono",
    "value": "Gisikdrono"
  },
  {
    "id": "3374131008",
    "district_id": "337413",
    "label": "Bongsari",
    "value": "Bongsari"
  },
  {
    "id": "3374131009",
    "district_id": "337413",
    "label": "Bojongsalaman",
    "value": "Bojongsalaman"
  },
  {
    "id": "3374131010",
    "district_id": "337413",
    "label": "Salamanmloyo",
    "value": "Salamanmloyo"
  },
  {
    "id": "3374131011",
    "district_id": "337413",
    "label": "Cabean",
    "value": "Cabean"
  },
  {
    "id": "3374131012",
    "district_id": "337413",
    "label": "Karangayu",
    "value": "Karangayu"
  },
  {
    "id": "3374131013",
    "district_id": "337413",
    "label": "Krobokan",
    "value": "Krobokan"
  },
  {
    "id": "3374131014",
    "district_id": "337413",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3374131015",
    "district_id": "337413",
    "label": "Tawangmas",
    "value": "Tawangmas"
  },
  {
    "id": "3374131016",
    "district_id": "337413",
    "label": "Kembangarum",
    "value": "Kembangarum"
  },
  {
    "id": "3374141001",
    "district_id": "337414",
    "label": "Cangkiran",
    "value": "Cangkiran"
  },
  {
    "id": "3374141002",
    "district_id": "337414",
    "label": "Bubakan",
    "value": "Bubakan"
  },
  {
    "id": "3374141003",
    "district_id": "337414",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3374141004",
    "district_id": "337414",
    "label": "Polaman",
    "value": "Polaman"
  },
  {
    "id": "3374141005",
    "district_id": "337414",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3374141006",
    "district_id": "337414",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "3374141007",
    "district_id": "337414",
    "label": "Wonolopo",
    "value": "Wonolopo"
  },
  {
    "id": "3374141008",
    "district_id": "337414",
    "label": "Mijen",
    "value": "Mijen"
  },
  {
    "id": "3374141009",
    "district_id": "337414",
    "label": "Jatibarang",
    "value": "Jatibarang"
  },
  {
    "id": "3374141010",
    "district_id": "337414",
    "label": "Kedungpane",
    "value": "Kedungpane"
  },
  {
    "id": "3374141011",
    "district_id": "337414",
    "label": "Ngadirgo",
    "value": "Ngadirgo"
  },
  {
    "id": "3374141012",
    "district_id": "337414",
    "label": "Wonoplumbon",
    "value": "Wonoplumbon"
  },
  {
    "id": "3374141013",
    "district_id": "337414",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3374141014",
    "district_id": "337414",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3374151001",
    "district_id": "337415",
    "label": "Gondoriyo",
    "value": "Gondoriyo"
  },
  {
    "id": "3374151002",
    "district_id": "337415",
    "label": "Podorejo",
    "value": "Podorejo"
  },
  {
    "id": "3374151003",
    "district_id": "337415",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "3374151004",
    "district_id": "337415",
    "label": "Purwoyoso",
    "value": "Purwoyoso"
  },
  {
    "id": "3374151005",
    "district_id": "337415",
    "label": "Kalipancur",
    "value": "Kalipancur"
  },
  {
    "id": "3374151006",
    "district_id": "337415",
    "label": "Bambankerep",
    "value": "Bambankerep"
  },
  {
    "id": "3374151007",
    "district_id": "337415",
    "label": "Ngaliyan",
    "value": "Ngaliyan"
  },
  {
    "id": "3374151008",
    "district_id": "337415",
    "label": "Tambakaji",
    "value": "Tambakaji"
  },
  {
    "id": "3374151009",
    "district_id": "337415",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3374151010",
    "district_id": "337415",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3374161001",
    "district_id": "337416",
    "label": "Jerakah",
    "value": "Jerakah"
  },
  {
    "id": "3374161002",
    "district_id": "337416",
    "label": "Tugurejo",
    "value": "Tugurejo"
  },
  {
    "id": "3374161003",
    "district_id": "337416",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3374161004",
    "district_id": "337416",
    "label": "Randugarut",
    "value": "Randugarut"
  },
  {
    "id": "3374161005",
    "district_id": "337416",
    "label": "Mangkang Wetan",
    "value": "Mangkang Wetan"
  },
  {
    "id": "3374161006",
    "district_id": "337416",
    "label": "Mangkang Kulon",
    "value": "Mangkang Kulon"
  },
  {
    "id": "3374161007",
    "district_id": "337416",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3375011002",
    "district_id": "337501",
    "label": "Medono",
    "value": "Medono"
  },
  {
    "id": "3375011003",
    "district_id": "337501",
    "label": "Podosugih",
    "value": "Podosugih"
  },
  {
    "id": "3375011004",
    "district_id": "337501",
    "label": "Sapuro Kebulen",
    "value": "Sapuro Kebulen"
  },
  {
    "id": "3375011006",
    "district_id": "337501",
    "label": "Bendan Kergon",
    "value": "Bendan Kergon"
  },
  {
    "id": "3375011008",
    "district_id": "337501",
    "label": "Pasirkratonkramat",
    "value": "Pasirkratonkramat"
  },
  {
    "id": "3375011009",
    "district_id": "337501",
    "label": "Tirto",
    "value": "Tirto"
  },
  {
    "id": "3375011012",
    "district_id": "337501",
    "label": "Pringrejo",
    "value": "Pringrejo"
  },
  {
    "id": "3375021002",
    "district_id": "337502",
    "label": "Noyontaansari",
    "value": "Noyontaansari"
  },
  {
    "id": "3375021004",
    "district_id": "337502",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3375021007",
    "district_id": "337502",
    "label": "Poncol",
    "value": "Poncol"
  },
  {
    "id": "3375021008",
    "district_id": "337502",
    "label": "Klego",
    "value": "Klego"
  },
  {
    "id": "3375021009",
    "district_id": "337502",
    "label": "Gamer",
    "value": "Gamer"
  },
  {
    "id": "3375021010",
    "district_id": "337502",
    "label": "Setono",
    "value": "Setono"
  },
  {
    "id": "3375021012",
    "district_id": "337502",
    "label": "Kali Baros",
    "value": "Kali Baros"
  },
  {
    "id": "3375031002",
    "district_id": "337503",
    "label": "Krapyak",
    "value": "Krapyak"
  },
  {
    "id": "3375031003",
    "district_id": "337503",
    "label": "Kandang Panjang",
    "value": "Kandang Panjang"
  },
  {
    "id": "3375031004",
    "district_id": "337503",
    "label": "Panjang Wetan",
    "value": "Panjang Wetan"
  },
  {
    "id": "3375031006",
    "district_id": "337503",
    "label": "Padukuhan Kraton",
    "value": "Padukuhan Kraton"
  },
  {
    "id": "3375031007",
    "district_id": "337503",
    "label": "Degayu",
    "value": "Degayu"
  },
  {
    "id": "3375031009",
    "district_id": "337503",
    "label": "Bandengan",
    "value": "Bandengan"
  },
  {
    "id": "3375031010",
    "district_id": "337503",
    "label": "Panjang Baru",
    "value": "Panjang Baru"
  },
  {
    "id": "3375041002",
    "district_id": "337504",
    "label": "Banyurip",
    "value": "Banyurip"
  },
  {
    "id": "3375041003",
    "district_id": "337504",
    "label": "Buaran Kradenan",
    "value": "Buaran Kradenan"
  },
  {
    "id": "3375041004",
    "district_id": "337504",
    "label": "Jenggot",
    "value": "Jenggot"
  },
  {
    "id": "3375041006",
    "district_id": "337504",
    "label": "Kuripan Kertoharjo",
    "value": "Kuripan Kertoharjo"
  },
  {
    "id": "3375041008",
    "district_id": "337504",
    "label": "Kuripan Yosorejo",
    "value": "Kuripan Yosorejo"
  },
  {
    "id": "3375041010",
    "district_id": "337504",
    "label": "Sokoduwet",
    "value": "Sokoduwet"
  },
  {
    "id": "3376011001",
    "district_id": "337601",
    "label": "Pesurungan Kidul",
    "value": "Pesurungan Kidul"
  },
  {
    "id": "3376011002",
    "district_id": "337601",
    "label": "Debong Lor",
    "value": "Debong Lor"
  },
  {
    "id": "3376011003",
    "district_id": "337601",
    "label": "Kemandungan",
    "value": "Kemandungan"
  },
  {
    "id": "3376011004",
    "district_id": "337601",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3376011005",
    "district_id": "337601",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3376011006",
    "district_id": "337601",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3376011007",
    "district_id": "337601",
    "label": "Muarareja",
    "value": "Muarareja"
  },
  {
    "id": "3376021001",
    "district_id": "337602",
    "label": "Kejambon",
    "value": "Kejambon"
  },
  {
    "id": "3376021002",
    "district_id": "337602",
    "label": "Slerok",
    "value": "Slerok"
  },
  {
    "id": "3376021003",
    "district_id": "337602",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "3376021004",
    "district_id": "337602",
    "label": "Mangkukusuman",
    "value": "Mangkukusuman"
  },
  {
    "id": "3376021005",
    "district_id": "337602",
    "label": "Mintaragen",
    "value": "Mintaragen"
  },
  {
    "id": "3376031001",
    "district_id": "337603",
    "label": "Kalinyamat Wetan",
    "value": "Kalinyamat Wetan"
  },
  {
    "id": "3376031002",
    "district_id": "337603",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3376031003",
    "district_id": "337603",
    "label": "Debong Kidul",
    "value": "Debong Kidul"
  },
  {
    "id": "3376031004",
    "district_id": "337603",
    "label": "Tunon",
    "value": "Tunon"
  },
  {
    "id": "3376031005",
    "district_id": "337603",
    "label": "Keturen",
    "value": "Keturen"
  },
  {
    "id": "3376031006",
    "district_id": "337603",
    "label": "Debong Kulon",
    "value": "Debong Kulon"
  },
  {
    "id": "3376031007",
    "district_id": "337603",
    "label": "Debong Tengah",
    "value": "Debong Tengah"
  },
  {
    "id": "3376031008",
    "district_id": "337603",
    "label": "Randugunting",
    "value": "Randugunting"
  },
  {
    "id": "3376041001",
    "district_id": "337604",
    "label": "Kaligangsa",
    "value": "Kaligangsa"
  },
  {
    "id": "3376041002",
    "district_id": "337604",
    "label": "Krandon",
    "value": "Krandon"
  },
  {
    "id": "3376041003",
    "district_id": "337604",
    "label": "Cabawan",
    "value": "Cabawan"
  },
  {
    "id": "3376041004",
    "district_id": "337604",
    "label": "Kalinyamat Kulon",
    "value": "Kalinyamat Kulon"
  },
  {
    "id": "3376041005",
    "district_id": "337604",
    "label": "Margadana",
    "value": "Margadana"
  },
  {
    "id": "3376041006",
    "district_id": "337604",
    "label": "Sumurpanggang",
    "value": "Sumurpanggang"
  },
  {
    "id": "3376041007",
    "district_id": "337604",
    "label": "Pesurungan Lor",
    "value": "Pesurungan Lor"
  },
  {
    "id": "3401012001",
    "district_id": "340101",
    "label": "Jangkaran",
    "value": "Jangkaran"
  },
  {
    "id": "3401012002",
    "district_id": "340101",
    "label": "Sindutan",
    "value": "Sindutan"
  },
  {
    "id": "3401012003",
    "district_id": "340101",
    "label": "Palihan",
    "value": "Palihan"
  },
  {
    "id": "3401012004",
    "district_id": "340101",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "3401012005",
    "district_id": "340101",
    "label": "Kalidengen",
    "value": "Kalidengen"
  },
  {
    "id": "3401012006",
    "district_id": "340101",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3401012007",
    "district_id": "340101",
    "label": "Kedundang",
    "value": "Kedundang"
  },
  {
    "id": "3401012008",
    "district_id": "340101",
    "label": "Demen",
    "value": "Demen"
  },
  {
    "id": "3401012009",
    "district_id": "340101",
    "label": "Kulur",
    "value": "Kulur"
  },
  {
    "id": "3401012010",
    "district_id": "340101",
    "label": "Kaligintung",
    "value": "Kaligintung"
  },
  {
    "id": "3401012011",
    "district_id": "340101",
    "label": "Temon Wetan",
    "value": "Temon Wetan"
  },
  {
    "id": "3401012012",
    "district_id": "340101",
    "label": "Temon Kulon",
    "value": "Temon Kulon"
  },
  {
    "id": "3401012013",
    "district_id": "340101",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3401012014",
    "district_id": "340101",
    "label": "Janten",
    "value": "Janten"
  },
  {
    "id": "3401012015",
    "district_id": "340101",
    "label": "Karangwuluh",
    "value": "Karangwuluh"
  },
  {
    "id": "3401021008",
    "district_id": "340102",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3401022001",
    "district_id": "340102",
    "label": "Karangwuni",
    "value": "Karangwuni"
  },
  {
    "id": "3401022002",
    "district_id": "340102",
    "label": "Sogan",
    "value": "Sogan"
  },
  {
    "id": "3401022003",
    "district_id": "340102",
    "label": "Kulwaru",
    "value": "Kulwaru"
  },
  {
    "id": "3401022004",
    "district_id": "340102",
    "label": "Ngestiharjo",
    "value": "Ngestiharjo"
  },
  {
    "id": "3401022005",
    "district_id": "340102",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3401022006",
    "district_id": "340102",
    "label": "Triharjo",
    "value": "Triharjo"
  },
  {
    "id": "3401022007",
    "district_id": "340102",
    "label": "Giripeni",
    "value": "Giripeni"
  },
  {
    "id": "3401032001",
    "district_id": "340103",
    "label": "Garongan",
    "value": "Garongan"
  },
  {
    "id": "3401032002",
    "district_id": "340103",
    "label": "Pleret",
    "value": "Pleret"
  },
  {
    "id": "3401032003",
    "district_id": "340103",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3401032004",
    "district_id": "340103",
    "label": "Kanoman",
    "value": "Kanoman"
  },
  {
    "id": "3401032005",
    "district_id": "340103",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3401032006",
    "district_id": "340103",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3401032007",
    "district_id": "340103",
    "label": "Tayuban",
    "value": "Tayuban"
  },
  {
    "id": "3401032008",
    "district_id": "340103",
    "label": "Gotakan",
    "value": "Gotakan"
  },
  {
    "id": "3401032009",
    "district_id": "340103",
    "label": "Panjatan",
    "value": "Panjatan"
  },
  {
    "id": "3401032010",
    "district_id": "340103",
    "label": "Cerme",
    "value": "Cerme"
  },
  {
    "id": "3401032011",
    "district_id": "340103",
    "label": "Krembangan",
    "value": "Krembangan"
  },
  {
    "id": "3401042001",
    "district_id": "340104",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3401042002",
    "district_id": "340104",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3401042003",
    "district_id": "340104",
    "label": "Nomporejo",
    "value": "Nomporejo"
  },
  {
    "id": "3401042004",
    "district_id": "340104",
    "label": "Karangsewu",
    "value": "Karangsewu"
  },
  {
    "id": "3401042005",
    "district_id": "340104",
    "label": "Tirtorahayu",
    "value": "Tirtorahayu"
  },
  {
    "id": "3401042006",
    "district_id": "340104",
    "label": "Pandowan",
    "value": "Pandowan"
  },
  {
    "id": "3401042007",
    "district_id": "340104",
    "label": "Brosot",
    "value": "Brosot"
  },
  {
    "id": "3401052001",
    "district_id": "340105",
    "label": "Wahyuharjo",
    "value": "Wahyuharjo"
  },
  {
    "id": "3401052002",
    "district_id": "340105",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3401052003",
    "district_id": "340105",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3401052004",
    "district_id": "340105",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3401052005",
    "district_id": "340105",
    "label": "Gulurejo",
    "value": "Gulurejo"
  },
  {
    "id": "3401052006",
    "district_id": "340105",
    "label": "Ngentakrejo",
    "value": "Ngentakrejo"
  },
  {
    "id": "3401062001",
    "district_id": "340106",
    "label": "Demangrejo",
    "value": "Demangrejo"
  },
  {
    "id": "3401062002",
    "district_id": "340106",
    "label": "Srikayangan",
    "value": "Srikayangan"
  },
  {
    "id": "3401062003",
    "district_id": "340106",
    "label": "Tuksono",
    "value": "Tuksono"
  },
  {
    "id": "3401062004",
    "district_id": "340106",
    "label": "Salamrejo",
    "value": "Salamrejo"
  },
  {
    "id": "3401062005",
    "district_id": "340106",
    "label": "Sukoreno",
    "value": "Sukoreno"
  },
  {
    "id": "3401062006",
    "district_id": "340106",
    "label": "Kaliagung",
    "value": "Kaliagung"
  },
  {
    "id": "3401062007",
    "district_id": "340106",
    "label": "Sentolo",
    "value": "Sentolo"
  },
  {
    "id": "3401062008",
    "district_id": "340106",
    "label": "Banguncipto",
    "value": "Banguncipto"
  },
  {
    "id": "3401072001",
    "district_id": "340107",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3401072002",
    "district_id": "340107",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3401072003",
    "district_id": "340107",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3401072004",
    "district_id": "340107",
    "label": "Margosari",
    "value": "Margosari"
  },
  {
    "id": "3401072005",
    "district_id": "340107",
    "label": "Pengasih",
    "value": "Pengasih"
  },
  {
    "id": "3401072006",
    "district_id": "340107",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3401072007",
    "district_id": "340107",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3401082001",
    "district_id": "340108",
    "label": "Hargomulyo",
    "value": "Hargomulyo"
  },
  {
    "id": "3401082002",
    "district_id": "340108",
    "label": "Hargorejo",
    "value": "Hargorejo"
  },
  {
    "id": "3401082003",
    "district_id": "340108",
    "label": "Hargowilis",
    "value": "Hargowilis"
  },
  {
    "id": "3401082004",
    "district_id": "340108",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3401082005",
    "district_id": "340108",
    "label": "Hargotirto",
    "value": "Hargotirto"
  },
  {
    "id": "3401092001",
    "district_id": "340109",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3401092002",
    "district_id": "340109",
    "label": "Giripurwo",
    "value": "Giripurwo"
  },
  {
    "id": "3401092003",
    "district_id": "340109",
    "label": "Pendoworejo",
    "value": "Pendoworejo"
  },
  {
    "id": "3401092004",
    "district_id": "340109",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3401102001",
    "district_id": "340110",
    "label": "Banyuroto",
    "value": "Banyuroto"
  },
  {
    "id": "3401102002",
    "district_id": "340110",
    "label": "Donomulyo",
    "value": "Donomulyo"
  },
  {
    "id": "3401102003",
    "district_id": "340110",
    "label": "Wijimulyo",
    "value": "Wijimulyo"
  },
  {
    "id": "3401102004",
    "district_id": "340110",
    "label": "Tanjungharjo",
    "value": "Tanjungharjo"
  },
  {
    "id": "3401102005",
    "district_id": "340110",
    "label": "Jatisarono",
    "value": "Jatisarono"
  },
  {
    "id": "3401102006",
    "district_id": "340110",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3401112001",
    "district_id": "340111",
    "label": "Kebonharjo",
    "value": "Kebonharjo"
  },
  {
    "id": "3401112002",
    "district_id": "340111",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3401112003",
    "district_id": "340111",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "3401112004",
    "district_id": "340111",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3401112005",
    "district_id": "340111",
    "label": "Gerbosari",
    "value": "Gerbosari"
  },
  {
    "id": "3401112006",
    "district_id": "340111",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3401112007",
    "district_id": "340111",
    "label": "Pagerharjo",
    "value": "Pagerharjo"
  },
  {
    "id": "3401122001",
    "district_id": "340112",
    "label": "Banjararum",
    "value": "Banjararum"
  },
  {
    "id": "3401122002",
    "district_id": "340112",
    "label": "Banjarasri",
    "value": "Banjarasri"
  },
  {
    "id": "3401122003",
    "district_id": "340112",
    "label": "Banjarharjo",
    "value": "Banjarharjo"
  },
  {
    "id": "3401122004",
    "district_id": "340112",
    "label": "Banjaroyo",
    "value": "Banjaroyo"
  },
  {
    "id": "3402012001",
    "district_id": "340201",
    "label": "Poncosari",
    "value": "Poncosari"
  },
  {
    "id": "3402012002",
    "district_id": "340201",
    "label": "Trimurti",
    "value": "Trimurti"
  },
  {
    "id": "3402022001",
    "district_id": "340202",
    "label": "Gadingsari",
    "value": "Gadingsari"
  },
  {
    "id": "3402022002",
    "district_id": "340202",
    "label": "Gadingharjo",
    "value": "Gadingharjo"
  },
  {
    "id": "3402022003",
    "district_id": "340202",
    "label": "Srigading",
    "value": "Srigading"
  },
  {
    "id": "3402022004",
    "district_id": "340202",
    "label": "Murtigading",
    "value": "Murtigading"
  },
  {
    "id": "3402032001",
    "district_id": "340203",
    "label": "Tirtomulyo",
    "value": "Tirtomulyo"
  },
  {
    "id": "3402032002",
    "district_id": "340203",
    "label": "Parangtritis",
    "value": "Parangtritis"
  },
  {
    "id": "3402032003",
    "district_id": "340203",
    "label": "Donotirto",
    "value": "Donotirto"
  },
  {
    "id": "3402032004",
    "district_id": "340203",
    "label": "Tirtosari",
    "value": "Tirtosari"
  },
  {
    "id": "3402032005",
    "district_id": "340203",
    "label": "Tirtohargo",
    "value": "Tirtohargo"
  },
  {
    "id": "3402042001",
    "district_id": "340204",
    "label": "Seloharjo",
    "value": "Seloharjo"
  },
  {
    "id": "3402042002",
    "district_id": "340204",
    "label": "Panjangrejo",
    "value": "Panjangrejo"
  },
  {
    "id": "3402042003",
    "district_id": "340204",
    "label": "Srihardono",
    "value": "Srihardono"
  },
  {
    "id": "3402052001",
    "district_id": "340205",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3402052002",
    "district_id": "340205",
    "label": "Mulyodadi",
    "value": "Mulyodadi"
  },
  {
    "id": "3402052003",
    "district_id": "340205",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3402062001",
    "district_id": "340206",
    "label": "Catuharjo",
    "value": "Catuharjo"
  },
  {
    "id": "3402062002",
    "district_id": "340206",
    "label": "Triharjo",
    "value": "Triharjo"
  },
  {
    "id": "3402062003",
    "district_id": "340206",
    "label": "Gilangharjo",
    "value": "Gilangharjo"
  },
  {
    "id": "3402062004",
    "district_id": "340206",
    "label": "Wijirejo",
    "value": "Wijirejo"
  },
  {
    "id": "3402072001",
    "district_id": "340207",
    "label": "Triwidadi",
    "value": "Triwidadi"
  },
  {
    "id": "3402072002",
    "district_id": "340207",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3402072003",
    "district_id": "340207",
    "label": "Guwosari",
    "value": "Guwosari"
  },
  {
    "id": "3402082001",
    "district_id": "340208",
    "label": "Palbapang",
    "value": "Palbapang"
  },
  {
    "id": "3402082002",
    "district_id": "340208",
    "label": "Ringinharjo",
    "value": "Ringinharjo"
  },
  {
    "id": "3402082003",
    "district_id": "340208",
    "label": "Bantul",
    "value": "Bantul"
  },
  {
    "id": "3402082004",
    "district_id": "340208",
    "label": "Trirenggo",
    "value": "Trirenggo"
  },
  {
    "id": "3402082005",
    "district_id": "340208",
    "label": "Sabdodadi",
    "value": "Sabdodadi"
  },
  {
    "id": "3402092001",
    "district_id": "340209",
    "label": "Patalan",
    "value": "Patalan"
  },
  {
    "id": "3402092002",
    "district_id": "340209",
    "label": "Canden",
    "value": "Canden"
  },
  {
    "id": "3402092003",
    "district_id": "340209",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3402092004",
    "district_id": "340209",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3402102001",
    "district_id": "340210",
    "label": "Selopamioro",
    "value": "Selopamioro"
  },
  {
    "id": "3402102002",
    "district_id": "340210",
    "label": "Sriharjo",
    "value": "Sriharjo"
  },
  {
    "id": "3402102003",
    "district_id": "340210",
    "label": "Wukirsari",
    "value": "Wukirsari"
  },
  {
    "id": "3402102004",
    "district_id": "340210",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3402102005",
    "district_id": "340210",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3402102006",
    "district_id": "340210",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3402102007",
    "district_id": "340210",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3402102008",
    "district_id": "340210",
    "label": "Imogiri",
    "value": "Imogiri"
  },
  {
    "id": "3402112001",
    "district_id": "340211",
    "label": "Mangunan",
    "value": "Mangunan"
  },
  {
    "id": "3402112002",
    "district_id": "340211",
    "label": "Muntuk",
    "value": "Muntuk"
  },
  {
    "id": "3402112003",
    "district_id": "340211",
    "label": "Dlingo",
    "value": "Dlingo"
  },
  {
    "id": "3402112004",
    "district_id": "340211",
    "label": "Temuwuh",
    "value": "Temuwuh"
  },
  {
    "id": "3402112005",
    "district_id": "340211",
    "label": "Terong",
    "value": "Terong"
  },
  {
    "id": "3402112006",
    "district_id": "340211",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3402122001",
    "district_id": "340212",
    "label": "Baturetno",
    "value": "Baturetno"
  },
  {
    "id": "3402122002",
    "district_id": "340212",
    "label": "Banguntapan",
    "value": "Banguntapan"
  },
  {
    "id": "3402122003",
    "district_id": "340212",
    "label": "Jagalan",
    "value": "Jagalan"
  },
  {
    "id": "3402122004",
    "district_id": "340212",
    "label": "Singosaren",
    "value": "Singosaren"
  },
  {
    "id": "3402122005",
    "district_id": "340212",
    "label": "Jambidan",
    "value": "Jambidan"
  },
  {
    "id": "3402122006",
    "district_id": "340212",
    "label": "Potorono",
    "value": "Potorono"
  },
  {
    "id": "3402122007",
    "district_id": "340212",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "3402122008",
    "district_id": "340212",
    "label": "Wirokerten",
    "value": "Wirokerten"
  },
  {
    "id": "3402132001",
    "district_id": "340213",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "3402132002",
    "district_id": "340213",
    "label": "Pleret",
    "value": "Pleret"
  },
  {
    "id": "3402132003",
    "district_id": "340213",
    "label": "Segoroyoso",
    "value": "Segoroyoso"
  },
  {
    "id": "3402132004",
    "district_id": "340213",
    "label": "Bawuran",
    "value": "Bawuran"
  },
  {
    "id": "3402132005",
    "district_id": "340213",
    "label": "Wonolelo",
    "value": "Wonolelo"
  },
  {
    "id": "3402142001",
    "district_id": "340214",
    "label": "Sitimulyo",
    "value": "Sitimulyo"
  },
  {
    "id": "3402142002",
    "district_id": "340214",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "3402142003",
    "district_id": "340214",
    "label": "Srimartani",
    "value": "Srimartani"
  },
  {
    "id": "3402152001",
    "district_id": "340215",
    "label": "Pendowoharjo",
    "value": "Pendowoharjo"
  },
  {
    "id": "3402152002",
    "district_id": "340215",
    "label": "Timbulharjo",
    "value": "Timbulharjo"
  },
  {
    "id": "3402152003",
    "district_id": "340215",
    "label": "Bangunharjo",
    "value": "Bangunharjo"
  },
  {
    "id": "3402152004",
    "district_id": "340215",
    "label": "Panggungharjo",
    "value": "Panggungharjo"
  },
  {
    "id": "3402162001",
    "district_id": "340216",
    "label": "Bangunjiwo",
    "value": "Bangunjiwo"
  },
  {
    "id": "3402162002",
    "district_id": "340216",
    "label": "Tirtonirmolo",
    "value": "Tirtonirmolo"
  },
  {
    "id": "3402162003",
    "district_id": "340216",
    "label": "Tamantirto",
    "value": "Tamantirto"
  },
  {
    "id": "3402162004",
    "district_id": "340216",
    "label": "Ngestiharjo",
    "value": "Ngestiharjo"
  },
  {
    "id": "3402172001",
    "district_id": "340217",
    "label": "Argodadi",
    "value": "Argodadi"
  },
  {
    "id": "3402172002",
    "district_id": "340217",
    "label": "Argorejo",
    "value": "Argorejo"
  },
  {
    "id": "3402172003",
    "district_id": "340217",
    "label": "Argosari",
    "value": "Argosari"
  },
  {
    "id": "3402172004",
    "district_id": "340217",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "3403012001",
    "district_id": "340301",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3403012002",
    "district_id": "340301",
    "label": "Kepek",
    "value": "Kepek"
  },
  {
    "id": "3403012003",
    "district_id": "340301",
    "label": "Piyaman",
    "value": "Piyaman"
  },
  {
    "id": "3403012004",
    "district_id": "340301",
    "label": "Gari",
    "value": "Gari"
  },
  {
    "id": "3403012005",
    "district_id": "340301",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3403012006",
    "district_id": "340301",
    "label": "Selang",
    "value": "Selang"
  },
  {
    "id": "3403012007",
    "district_id": "340301",
    "label": "Baleharjo",
    "value": "Baleharjo"
  },
  {
    "id": "3403012008",
    "district_id": "340301",
    "label": "Siraman",
    "value": "Siraman"
  },
  {
    "id": "3403012009",
    "district_id": "340301",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "3403012010",
    "district_id": "340301",
    "label": "Wareng",
    "value": "Wareng"
  },
  {
    "id": "3403012011",
    "district_id": "340301",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3403012012",
    "district_id": "340301",
    "label": "Mulo",
    "value": "Mulo"
  },
  {
    "id": "3403012013",
    "district_id": "340301",
    "label": "Wunung",
    "value": "Wunung"
  },
  {
    "id": "3403012014",
    "district_id": "340301",
    "label": "Karangrejek",
    "value": "Karangrejek"
  },
  {
    "id": "3403022001",
    "district_id": "340302",
    "label": "Natah",
    "value": "Natah"
  },
  {
    "id": "3403022002",
    "district_id": "340302",
    "label": "Pilangrejo",
    "value": "Pilangrejo"
  },
  {
    "id": "3403022003",
    "district_id": "340302",
    "label": "Kedungpoh",
    "value": "Kedungpoh"
  },
  {
    "id": "3403022004",
    "district_id": "340302",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3403022005",
    "district_id": "340302",
    "label": "Kedungkeris",
    "value": "Kedungkeris"
  },
  {
    "id": "3403022006",
    "district_id": "340302",
    "label": "Nglipar",
    "value": "Nglipar"
  },
  {
    "id": "3403022007",
    "district_id": "340302",
    "label": "Katongan",
    "value": "Katongan"
  },
  {
    "id": "3403032001",
    "district_id": "340303",
    "label": "Banyusoco",
    "value": "Banyusoco"
  },
  {
    "id": "3403032002",
    "district_id": "340303",
    "label": "Plembutan",
    "value": "Plembutan"
  },
  {
    "id": "3403032003",
    "district_id": "340303",
    "label": "Bleberan",
    "value": "Bleberan"
  },
  {
    "id": "3403032004",
    "district_id": "340303",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3403032005",
    "district_id": "340303",
    "label": "Dengok",
    "value": "Dengok"
  },
  {
    "id": "3403032006",
    "district_id": "340303",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3403032007",
    "district_id": "340303",
    "label": "Playen",
    "value": "Playen"
  },
  {
    "id": "3403032008",
    "district_id": "340303",
    "label": "Ngawu",
    "value": "Ngawu"
  },
  {
    "id": "3403032009",
    "district_id": "340303",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3403032010",
    "district_id": "340303",
    "label": "Logandeng",
    "value": "Logandeng"
  },
  {
    "id": "3403032011",
    "district_id": "340303",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3403032012",
    "district_id": "340303",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3403032013",
    "district_id": "340303",
    "label": "Ngleri",
    "value": "Ngleri"
  },
  {
    "id": "3403042001",
    "district_id": "340304",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3403042002",
    "district_id": "340304",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3403042003",
    "district_id": "340304",
    "label": "Pengkok",
    "value": "Pengkok"
  },
  {
    "id": "3403042004",
    "district_id": "340304",
    "label": "Semoyo",
    "value": "Semoyo"
  },
  {
    "id": "3403042005",
    "district_id": "340304",
    "label": "Salam",
    "value": "Salam"
  },
  {
    "id": "3403042006",
    "district_id": "340304",
    "label": "Patuk",
    "value": "Patuk"
  },
  {
    "id": "3403042007",
    "district_id": "340304",
    "label": "Ngoro-oro",
    "value": "Ngoro-oro"
  },
  {
    "id": "3403042008",
    "district_id": "340304",
    "label": "Nglanggeran",
    "value": "Nglanggeran"
  },
  {
    "id": "3403042009",
    "district_id": "340304",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "3403042010",
    "district_id": "340304",
    "label": "Nglegi",
    "value": "Nglegi"
  },
  {
    "id": "3403042011",
    "district_id": "340304",
    "label": "Terbah",
    "value": "Terbah"
  },
  {
    "id": "3403052001",
    "district_id": "340305",
    "label": "Sodo",
    "value": "Sodo"
  },
  {
    "id": "3403052002",
    "district_id": "340305",
    "label": "Pampang",
    "value": "Pampang"
  },
  {
    "id": "3403052003",
    "district_id": "340305",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3403052004",
    "district_id": "340305",
    "label": "Karangduwet",
    "value": "Karangduwet"
  },
  {
    "id": "3403052005",
    "district_id": "340305",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3403052006",
    "district_id": "340305",
    "label": "Mulusan",
    "value": "Mulusan"
  },
  {
    "id": "3403052007",
    "district_id": "340305",
    "label": "Giring",
    "value": "Giring"
  },
  {
    "id": "3403062001",
    "district_id": "340306",
    "label": "Girikarto",
    "value": "Girikarto"
  },
  {
    "id": "3403062002",
    "district_id": "340306",
    "label": "Girisekar",
    "value": "Girisekar"
  },
  {
    "id": "3403062003",
    "district_id": "340306",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3403062004",
    "district_id": "340306",
    "label": "Giriwungu",
    "value": "Giriwungu"
  },
  {
    "id": "3403062005",
    "district_id": "340306",
    "label": "Giriharjo",
    "value": "Giriharjo"
  },
  {
    "id": "3403062006",
    "district_id": "340306",
    "label": "Girisuko",
    "value": "Girisuko"
  },
  {
    "id": "3403072001",
    "district_id": "340307",
    "label": "Giripanggung",
    "value": "Giripanggung"
  },
  {
    "id": "3403072002",
    "district_id": "340307",
    "label": "Sumberwungu",
    "value": "Sumberwungu"
  },
  {
    "id": "3403072003",
    "district_id": "340307",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3403072004",
    "district_id": "340307",
    "label": "Tepus",
    "value": "Tepus"
  },
  {
    "id": "3403072005",
    "district_id": "340307",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3403082001",
    "district_id": "340308",
    "label": "Ngeposari",
    "value": "Ngeposari"
  },
  {
    "id": "3403082002",
    "district_id": "340308",
    "label": "Semanu",
    "value": "Semanu"
  },
  {
    "id": "3403082003",
    "district_id": "340308",
    "label": "Pacarejo",
    "value": "Pacarejo"
  },
  {
    "id": "3403082004",
    "district_id": "340308",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3403082005",
    "district_id": "340308",
    "label": "Dadapayu",
    "value": "Dadapayu"
  },
  {
    "id": "3403092001",
    "district_id": "340309",
    "label": "Bejiharjo",
    "value": "Bejiharjo"
  },
  {
    "id": "3403092002",
    "district_id": "340309",
    "label": "Wiladeg",
    "value": "Wiladeg"
  },
  {
    "id": "3403092003",
    "district_id": "340309",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3403092004",
    "district_id": "340309",
    "label": "Kelor",
    "value": "Kelor"
  },
  {
    "id": "3403092005",
    "district_id": "340309",
    "label": "Ngipak",
    "value": "Ngipak"
  },
  {
    "id": "3403092006",
    "district_id": "340309",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3403092007",
    "district_id": "340309",
    "label": "Gedangrejo",
    "value": "Gedangrejo"
  },
  {
    "id": "3403092008",
    "district_id": "340309",
    "label": "Ngawis",
    "value": "Ngawis"
  },
  {
    "id": "3403092009",
    "district_id": "340309",
    "label": "Jatiayu",
    "value": "Jatiayu"
  },
  {
    "id": "3403102001",
    "district_id": "340310",
    "label": "Umbulrejo",
    "value": "Umbulrejo"
  },
  {
    "id": "3403102002",
    "district_id": "340310",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3403102003",
    "district_id": "340310",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "3403102004",
    "district_id": "340310",
    "label": "Kenteng",
    "value": "Kenteng"
  },
  {
    "id": "3403102005",
    "district_id": "340310",
    "label": "Sumbergiri",
    "value": "Sumbergiri"
  },
  {
    "id": "3403102006",
    "district_id": "340310",
    "label": "Genjahan",
    "value": "Genjahan"
  },
  {
    "id": "3403102007",
    "district_id": "340310",
    "label": "Ponjong",
    "value": "Ponjong"
  },
  {
    "id": "3403102008",
    "district_id": "340310",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3403102009",
    "district_id": "340310",
    "label": "Bedoyo",
    "value": "Bedoyo"
  },
  {
    "id": "3403102010",
    "district_id": "340310",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3403102011",
    "district_id": "340310",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3403112001",
    "district_id": "340311",
    "label": "Bohol",
    "value": "Bohol"
  },
  {
    "id": "3403112002",
    "district_id": "340311",
    "label": "Pringombo",
    "value": "Pringombo"
  },
  {
    "id": "3403112003",
    "district_id": "340311",
    "label": "Botodayaan",
    "value": "Botodayaan"
  },
  {
    "id": "3403112004",
    "district_id": "340311",
    "label": "Petir",
    "value": "Petir"
  },
  {
    "id": "3403112005",
    "district_id": "340311",
    "label": "Pucanganom",
    "value": "Pucanganom"
  },
  {
    "id": "3403112006",
    "district_id": "340311",
    "label": "Semugih",
    "value": "Semugih"
  },
  {
    "id": "3403112007",
    "district_id": "340311",
    "label": "Melikan",
    "value": "Melikan"
  },
  {
    "id": "3403112008",
    "district_id": "340311",
    "label": "Karangwuni",
    "value": "Karangwuni"
  },
  {
    "id": "3403122001",
    "district_id": "340312",
    "label": "Kalitekuk",
    "value": "Kalitekuk"
  },
  {
    "id": "3403122002",
    "district_id": "340312",
    "label": "Kemejing",
    "value": "Kemejing"
  },
  {
    "id": "3403122003",
    "district_id": "340312",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3403122004",
    "district_id": "340312",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3403122005",
    "district_id": "340312",
    "label": "Bendung",
    "value": "Bendung"
  },
  {
    "id": "3403122006",
    "district_id": "340312",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3403122007",
    "district_id": "340312",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3403122008",
    "district_id": "340312",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3403122009",
    "district_id": "340312",
    "label": "Pundungsari",
    "value": "Pundungsari"
  },
  {
    "id": "3403122010",
    "district_id": "340312",
    "label": "Semin",
    "value": "Semin"
  },
  {
    "id": "3403132001",
    "district_id": "340313",
    "label": "Tancep",
    "value": "Tancep"
  },
  {
    "id": "3403132002",
    "district_id": "340313",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3403132003",
    "district_id": "340313",
    "label": "Jurangjero",
    "value": "Jurangjero"
  },
  {
    "id": "3403132004",
    "district_id": "340313",
    "label": "Kampung",
    "value": "Kampung"
  },
  {
    "id": "3403132005",
    "district_id": "340313",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3403132006",
    "district_id": "340313",
    "label": "Watusigar",
    "value": "Watusigar"
  },
  {
    "id": "3403142001",
    "district_id": "340314",
    "label": "Hargomulyo",
    "value": "Hargomulyo"
  },
  {
    "id": "3403142002",
    "district_id": "340314",
    "label": "Mertelu",
    "value": "Mertelu"
  },
  {
    "id": "3403142003",
    "district_id": "340314",
    "label": "Watugajah",
    "value": "Watugajah"
  },
  {
    "id": "3403142004",
    "district_id": "340314",
    "label": "Sampang",
    "value": "Sampang"
  },
  {
    "id": "3403142005",
    "district_id": "340314",
    "label": "Serut",
    "value": "Serut"
  },
  {
    "id": "3403142006",
    "district_id": "340314",
    "label": "Ngalang",
    "value": "Ngalang"
  },
  {
    "id": "3403142007",
    "district_id": "340314",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3403152001",
    "district_id": "340315",
    "label": "Krambilsawit",
    "value": "Krambilsawit"
  },
  {
    "id": "3403152002",
    "district_id": "340315",
    "label": "Ngloro",
    "value": "Ngloro"
  },
  {
    "id": "3403152003",
    "district_id": "340315",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3403152004",
    "district_id": "340315",
    "label": "Kepek",
    "value": "Kepek"
  },
  {
    "id": "3403152005",
    "district_id": "340315",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3403152006",
    "district_id": "340315",
    "label": "Monggol",
    "value": "Monggol"
  },
  {
    "id": "3403152007",
    "district_id": "340315",
    "label": "Planjan",
    "value": "Planjan"
  },
  {
    "id": "3403162001",
    "district_id": "340316",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3403162002",
    "district_id": "340316",
    "label": "Jepitu",
    "value": "Jepitu"
  },
  {
    "id": "3403162003",
    "district_id": "340316",
    "label": "Karangawen",
    "value": "Karangawen"
  },
  {
    "id": "3403162004",
    "district_id": "340316",
    "label": "Nglindur",
    "value": "Nglindur"
  },
  {
    "id": "3403162005",
    "district_id": "340316",
    "label": "Jerukwudel",
    "value": "Jerukwudel"
  },
  {
    "id": "3403162006",
    "district_id": "340316",
    "label": "Tileng",
    "value": "Tileng"
  },
  {
    "id": "3403162007",
    "district_id": "340316",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3403162008",
    "district_id": "340316",
    "label": "Songbanyu",
    "value": "Songbanyu"
  },
  {
    "id": "3403172001",
    "district_id": "340317",
    "label": "Hargosari",
    "value": "Hargosari"
  },
  {
    "id": "3403172002",
    "district_id": "340317",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3403172003",
    "district_id": "340317",
    "label": "Kemadang",
    "value": "Kemadang"
  },
  {
    "id": "3403172004",
    "district_id": "340317",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3403172005",
    "district_id": "340317",
    "label": "Ngestirejo",
    "value": "Ngestirejo"
  },
  {
    "id": "3403182001",
    "district_id": "340318",
    "label": "Giripurwo",
    "value": "Giripurwo"
  },
  {
    "id": "3403182002",
    "district_id": "340318",
    "label": "Giricahyo",
    "value": "Giricahyo"
  },
  {
    "id": "3403182003",
    "district_id": "340318",
    "label": "Girijati",
    "value": "Girijati"
  },
  {
    "id": "3403182004",
    "district_id": "340318",
    "label": "Giriasih",
    "value": "Giriasih"
  },
  {
    "id": "3403182005",
    "district_id": "340318",
    "label": "Giritirto",
    "value": "Giritirto"
  },
  {
    "id": "3404012001",
    "district_id": "340401",
    "label": "Balecatur",
    "value": "Balecatur"
  },
  {
    "id": "3404012002",
    "district_id": "340401",
    "label": "Ambarketawang",
    "value": "Ambarketawang"
  },
  {
    "id": "3404012003",
    "district_id": "340401",
    "label": "Banyuraden",
    "value": "Banyuraden"
  },
  {
    "id": "3404012004",
    "district_id": "340401",
    "label": "Nogotirto",
    "value": "Nogotirto"
  },
  {
    "id": "3404012005",
    "district_id": "340401",
    "label": "Trihanggo",
    "value": "Trihanggo"
  },
  {
    "id": "3404022001",
    "district_id": "340402",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3404022002",
    "district_id": "340402",
    "label": "Sidoluhur",
    "value": "Sidoluhur"
  },
  {
    "id": "3404022003",
    "district_id": "340402",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3404022004",
    "district_id": "340402",
    "label": "Sidoagung",
    "value": "Sidoagung"
  },
  {
    "id": "3404022005",
    "district_id": "340402",
    "label": "Sidokarto",
    "value": "Sidokarto"
  },
  {
    "id": "3404022006",
    "district_id": "340402",
    "label": "Sidoarum",
    "value": "Sidoarum"
  },
  {
    "id": "3404022007",
    "district_id": "340402",
    "label": "Sidomoyo",
    "value": "Sidomoyo"
  },
  {
    "id": "3404032001",
    "district_id": "340403",
    "label": "Sumberrahayu",
    "value": "Sumberrahayu"
  },
  {
    "id": "3404032002",
    "district_id": "340403",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3404032003",
    "district_id": "340403",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3404032004",
    "district_id": "340403",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3404042001",
    "district_id": "340404",
    "label": "Sendangarum",
    "value": "Sendangarum"
  },
  {
    "id": "3404042002",
    "district_id": "340404",
    "label": "Sendangmulyo",
    "value": "Sendangmulyo"
  },
  {
    "id": "3404042003",
    "district_id": "340404",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3404042004",
    "district_id": "340404",
    "label": "Sendangsari",
    "value": "Sendangsari"
  },
  {
    "id": "3404042005",
    "district_id": "340404",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3404052001",
    "district_id": "340405",
    "label": "Margoluwih",
    "value": "Margoluwih"
  },
  {
    "id": "3404052002",
    "district_id": "340405",
    "label": "Margodadi",
    "value": "Margodadi"
  },
  {
    "id": "3404052003",
    "district_id": "340405",
    "label": "Margokaton",
    "value": "Margokaton"
  },
  {
    "id": "3404052004",
    "district_id": "340405",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3404052005",
    "district_id": "340405",
    "label": "Margoagung",
    "value": "Margoagung"
  },
  {
    "id": "3404062001",
    "district_id": "340406",
    "label": "Sinduadi",
    "value": "Sinduadi"
  },
  {
    "id": "3404062002",
    "district_id": "340406",
    "label": "Sendangadi",
    "value": "Sendangadi"
  },
  {
    "id": "3404062003",
    "district_id": "340406",
    "label": "Tlogoadi",
    "value": "Tlogoadi"
  },
  {
    "id": "3404062004",
    "district_id": "340406",
    "label": "Tirtoadi",
    "value": "Tirtoadi"
  },
  {
    "id": "3404062005",
    "district_id": "340406",
    "label": "Sumberadi",
    "value": "Sumberadi"
  },
  {
    "id": "3404072001",
    "district_id": "340407",
    "label": "Caturtunggal",
    "value": "Caturtunggal"
  },
  {
    "id": "3404072002",
    "district_id": "340407",
    "label": "Maguwoharjo",
    "value": "Maguwoharjo"
  },
  {
    "id": "3404072003",
    "district_id": "340407",
    "label": "Condongcatur",
    "value": "Condongcatur"
  },
  {
    "id": "3404082001",
    "district_id": "340408",
    "label": "Sendangtirto",
    "value": "Sendangtirto"
  },
  {
    "id": "3404082002",
    "district_id": "340408",
    "label": "Tegaltirto",
    "value": "Tegaltirto"
  },
  {
    "id": "3404082003",
    "district_id": "340408",
    "label": "Kalitirto",
    "value": "Kalitirto"
  },
  {
    "id": "3404082004",
    "district_id": "340408",
    "label": "Jogotirto",
    "value": "Jogotirto"
  },
  {
    "id": "3404092001",
    "district_id": "340409",
    "label": "Sumberharjo",
    "value": "Sumberharjo"
  },
  {
    "id": "3404092002",
    "district_id": "340409",
    "label": "Wukirharjo",
    "value": "Wukirharjo"
  },
  {
    "id": "3404092003",
    "district_id": "340409",
    "label": "Gayamharjo",
    "value": "Gayamharjo"
  },
  {
    "id": "3404092004",
    "district_id": "340409",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3404092005",
    "district_id": "340409",
    "label": "Madurejo",
    "value": "Madurejo"
  },
  {
    "id": "3404092006",
    "district_id": "340409",
    "label": "Bokoharjo",
    "value": "Bokoharjo"
  },
  {
    "id": "3404102001",
    "district_id": "340410",
    "label": "Purwomartani",
    "value": "Purwomartani"
  },
  {
    "id": "3404102002",
    "district_id": "340410",
    "label": "Tirtomartani",
    "value": "Tirtomartani"
  },
  {
    "id": "3404102003",
    "district_id": "340410",
    "label": "Tamanmartani",
    "value": "Tamanmartani"
  },
  {
    "id": "3404102004",
    "district_id": "340410",
    "label": "Selomartani",
    "value": "Selomartani"
  },
  {
    "id": "3404112001",
    "district_id": "340411",
    "label": "Sindumartani",
    "value": "Sindumartani"
  },
  {
    "id": "3404112002",
    "district_id": "340411",
    "label": "Bimomartani",
    "value": "Bimomartani"
  },
  {
    "id": "3404112003",
    "district_id": "340411",
    "label": "Widodomartani",
    "value": "Widodomartani"
  },
  {
    "id": "3404112004",
    "district_id": "340411",
    "label": "Wedomartani",
    "value": "Wedomartani"
  },
  {
    "id": "3404112005",
    "district_id": "340411",
    "label": "Umbulmartani",
    "value": "Umbulmartani"
  },
  {
    "id": "3404122001",
    "district_id": "340412",
    "label": "Sariharjo",
    "value": "Sariharjo"
  },
  {
    "id": "3404122002",
    "district_id": "340412",
    "label": "Minomartani",
    "value": "Minomartani"
  },
  {
    "id": "3404122003",
    "district_id": "340412",
    "label": "Sinduharjo",
    "value": "Sinduharjo"
  },
  {
    "id": "3404122004",
    "district_id": "340412",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3404122005",
    "district_id": "340412",
    "label": "Sardonoharjo",
    "value": "Sardonoharjo"
  },
  {
    "id": "3404122006",
    "district_id": "340412",
    "label": "Donoharjo",
    "value": "Donoharjo"
  },
  {
    "id": "3404132001",
    "district_id": "340413",
    "label": "Caturharjo",
    "value": "Caturharjo"
  },
  {
    "id": "3404132002",
    "district_id": "340413",
    "label": "Triharjo",
    "value": "Triharjo"
  },
  {
    "id": "3404132003",
    "district_id": "340413",
    "label": "Tridadi",
    "value": "Tridadi"
  },
  {
    "id": "3404132004",
    "district_id": "340413",
    "label": "Pandowoharjo",
    "value": "Pandowoharjo"
  },
  {
    "id": "3404132005",
    "district_id": "340413",
    "label": "Trimulyo",
    "value": "Trimulyo"
  },
  {
    "id": "3404142001",
    "district_id": "340414",
    "label": "Banyurejo",
    "value": "Banyurejo"
  },
  {
    "id": "3404142002",
    "district_id": "340414",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3404142003",
    "district_id": "340414",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3404142004",
    "district_id": "340414",
    "label": "Pondokrejo",
    "value": "Pondokrejo"
  },
  {
    "id": "3404142005",
    "district_id": "340414",
    "label": "Mororejo",
    "value": "Mororejo"
  },
  {
    "id": "3404142006",
    "district_id": "340414",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3404142007",
    "district_id": "340414",
    "label": "Lumbungrejo",
    "value": "Lumbungrejo"
  },
  {
    "id": "3404142008",
    "district_id": "340414",
    "label": "Merdikorejo",
    "value": "Merdikorejo"
  },
  {
    "id": "3404152001",
    "district_id": "340415",
    "label": "Bangunkerto",
    "value": "Bangunkerto"
  },
  {
    "id": "3404152002",
    "district_id": "340415",
    "label": "Donokerto",
    "value": "Donokerto"
  },
  {
    "id": "3404152003",
    "district_id": "340415",
    "label": "Girikerto",
    "value": "Girikerto"
  },
  {
    "id": "3404152004",
    "district_id": "340415",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3404162001",
    "district_id": "340416",
    "label": "Purwobinangun",
    "value": "Purwobinangun"
  },
  {
    "id": "3404162002",
    "district_id": "340416",
    "label": "Candibinangun",
    "value": "Candibinangun"
  },
  {
    "id": "3404162003",
    "district_id": "340416",
    "label": "Harjobinangun",
    "value": "Harjobinangun"
  },
  {
    "id": "3404162004",
    "district_id": "340416",
    "label": "Pakembinangun",
    "value": "Pakembinangun"
  },
  {
    "id": "3404162005",
    "district_id": "340416",
    "label": "Hargobinangun",
    "value": "Hargobinangun"
  },
  {
    "id": "3404172001",
    "district_id": "340417",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "3404172002",
    "district_id": "340417",
    "label": "Wukirsari",
    "value": "Wukirsari"
  },
  {
    "id": "3404172003",
    "district_id": "340417",
    "label": "Glagaharjo",
    "value": "Glagaharjo"
  },
  {
    "id": "3404172004",
    "district_id": "340417",
    "label": "Kepuharjo",
    "value": "Kepuharjo"
  },
  {
    "id": "3404172005",
    "district_id": "340417",
    "label": "Umbulharjo",
    "value": "Umbulharjo"
  },
  {
    "id": "3471011001",
    "district_id": "347101",
    "label": "Kricak",
    "value": "Kricak"
  },
  {
    "id": "3471011002",
    "district_id": "347101",
    "label": "Karangwaru",
    "value": "Karangwaru"
  },
  {
    "id": "3471011003",
    "district_id": "347101",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3471011004",
    "district_id": "347101",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3471021001",
    "district_id": "347102",
    "label": "Bumijo",
    "value": "Bumijo"
  },
  {
    "id": "3471021002",
    "district_id": "347102",
    "label": "Cokrodiningratan",
    "value": "Cokrodiningratan"
  },
  {
    "id": "3471021003",
    "district_id": "347102",
    "label": "Gowongan",
    "value": "Gowongan"
  },
  {
    "id": "3471031001",
    "district_id": "347103",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3471031002",
    "district_id": "347103",
    "label": "Kotabaru",
    "value": "Kotabaru"
  },
  {
    "id": "3471031003",
    "district_id": "347103",
    "label": "Klitren",
    "value": "Klitren"
  },
  {
    "id": "3471031004",
    "district_id": "347103",
    "label": "Baciro",
    "value": "Baciro"
  },
  {
    "id": "3471031005",
    "district_id": "347103",
    "label": "Terban",
    "value": "Terban"
  },
  {
    "id": "3471041001",
    "district_id": "347104",
    "label": "Suryatmajan",
    "value": "Suryatmajan"
  },
  {
    "id": "3471041002",
    "district_id": "347104",
    "label": "Tegalpanggung",
    "value": "Tegalpanggung"
  },
  {
    "id": "3471041003",
    "district_id": "347104",
    "label": "Bausasran",
    "value": "Bausasran"
  },
  {
    "id": "3471051001",
    "district_id": "347105",
    "label": "Sosromenduran",
    "value": "Sosromenduran"
  },
  {
    "id": "3471051002",
    "district_id": "347105",
    "label": "Pringgokusuman",
    "value": "Pringgokusuman"
  },
  {
    "id": "3471061001",
    "district_id": "347106",
    "label": "Ngampilan",
    "value": "Ngampilan"
  },
  {
    "id": "3471061002",
    "district_id": "347106",
    "label": "Notoprajan",
    "value": "Notoprajan"
  },
  {
    "id": "3471071001",
    "district_id": "347107",
    "label": "Pakuncen",
    "value": "Pakuncen"
  },
  {
    "id": "3471071002",
    "district_id": "347107",
    "label": "Wirobrajan",
    "value": "Wirobrajan"
  },
  {
    "id": "3471071003",
    "district_id": "347107",
    "label": "Patangpuluhan",
    "value": "Patangpuluhan"
  },
  {
    "id": "3471081001",
    "district_id": "347108",
    "label": "Gedongkiwo",
    "value": "Gedongkiwo"
  },
  {
    "id": "3471081002",
    "district_id": "347108",
    "label": "Suryodiningratan",
    "value": "Suryodiningratan"
  },
  {
    "id": "3471081003",
    "district_id": "347108",
    "label": "Mantrijeron",
    "value": "Mantrijeron"
  },
  {
    "id": "3471091001",
    "district_id": "347109",
    "label": "Patehan",
    "value": "Patehan"
  },
  {
    "id": "3471091002",
    "district_id": "347109",
    "label": "Panembahan",
    "value": "Panembahan"
  },
  {
    "id": "3471091003",
    "district_id": "347109",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3471101001",
    "district_id": "347110",
    "label": "Ngupasan",
    "value": "Ngupasan"
  },
  {
    "id": "3471101002",
    "district_id": "347110",
    "label": "Prawirodirjan",
    "value": "Prawirodirjan"
  },
  {
    "id": "3471111001",
    "district_id": "347111",
    "label": "Purwokinanti",
    "value": "Purwokinanti"
  },
  {
    "id": "3471111002",
    "district_id": "347111",
    "label": "Gunungketur",
    "value": "Gunungketur"
  },
  {
    "id": "3471121001",
    "district_id": "347112",
    "label": "Keparakan",
    "value": "Keparakan"
  },
  {
    "id": "3471121002",
    "district_id": "347112",
    "label": "Wirogunan",
    "value": "Wirogunan"
  },
  {
    "id": "3471121003",
    "district_id": "347112",
    "label": "Brontokusuman",
    "value": "Brontokusuman"
  },
  {
    "id": "3471131001",
    "district_id": "347113",
    "label": "Semaki",
    "value": "Semaki"
  },
  {
    "id": "3471131002",
    "district_id": "347113",
    "label": "Muja Muju",
    "value": "Muja Muju"
  },
  {
    "id": "3471131003",
    "district_id": "347113",
    "label": "Tahunan",
    "value": "Tahunan"
  },
  {
    "id": "3471131004",
    "district_id": "347113",
    "label": "Warungboto",
    "value": "Warungboto"
  },
  {
    "id": "3471131005",
    "district_id": "347113",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3471131006",
    "district_id": "347113",
    "label": "Sorosutan",
    "value": "Sorosutan"
  },
  {
    "id": "3471131007",
    "district_id": "347113",
    "label": "Giwangan",
    "value": "Giwangan"
  },
  {
    "id": "3471141001",
    "district_id": "347114",
    "label": "Rejowinangun",
    "value": "Rejowinangun"
  },
  {
    "id": "3471141002",
    "district_id": "347114",
    "label": "Prenggan",
    "value": "Prenggan"
  },
  {
    "id": "3471141003",
    "district_id": "347114",
    "label": "Purbayan",
    "value": "Purbayan"
  },
  {
    "id": "3501012001",
    "district_id": "350101",
    "label": "Widoro",
    "value": "Widoro"
  },
  {
    "id": "3501012002",
    "district_id": "350101",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3501012003",
    "district_id": "350101",
    "label": "Kalak",
    "value": "Kalak"
  },
  {
    "id": "3501012004",
    "district_id": "350101",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3501012005",
    "district_id": "350101",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3501012006",
    "district_id": "350101",
    "label": "Gedompol",
    "value": "Gedompol"
  },
  {
    "id": "3501012007",
    "district_id": "350101",
    "label": "Cemeng",
    "value": "Cemeng"
  },
  {
    "id": "3501012008",
    "district_id": "350101",
    "label": "Gendaran",
    "value": "Gendaran"
  },
  {
    "id": "3501012009",
    "district_id": "350101",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3501012010",
    "district_id": "350101",
    "label": "Sekar",
    "value": "Sekar"
  },
  {
    "id": "3501012011",
    "district_id": "350101",
    "label": "Donorojo",
    "value": "Donorojo"
  },
  {
    "id": "3501012012",
    "district_id": "350101",
    "label": "Belah",
    "value": "Belah"
  },
  {
    "id": "3501022001",
    "district_id": "350102",
    "label": "Dersono",
    "value": "Dersono"
  },
  {
    "id": "3501022002",
    "district_id": "350102",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3501022003",
    "district_id": "350102",
    "label": "Watukarung",
    "value": "Watukarung"
  },
  {
    "id": "3501022004",
    "district_id": "350102",
    "label": "Jlubang",
    "value": "Jlubang"
  },
  {
    "id": "3501022005",
    "district_id": "350102",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3501022006",
    "district_id": "350102",
    "label": "Poko",
    "value": "Poko"
  },
  {
    "id": "3501022007",
    "district_id": "350102",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3501022008",
    "district_id": "350102",
    "label": "Pringkuku",
    "value": "Pringkuku"
  },
  {
    "id": "3501022009",
    "district_id": "350102",
    "label": "Sobo",
    "value": "Sobo"
  },
  {
    "id": "3501022010",
    "district_id": "350102",
    "label": "Ngadirejan",
    "value": "Ngadirejan"
  },
  {
    "id": "3501022011",
    "district_id": "350102",
    "label": "Glinggangan",
    "value": "Glinggangan"
  },
  {
    "id": "3501022012",
    "district_id": "350102",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3501022013",
    "district_id": "350102",
    "label": "Tamanasri",
    "value": "Tamanasri"
  },
  {
    "id": "3501032001",
    "district_id": "350103",
    "label": "Bomo",
    "value": "Bomo"
  },
  {
    "id": "3501032002",
    "district_id": "350103",
    "label": "Sooka",
    "value": "Sooka"
  },
  {
    "id": "3501032003",
    "district_id": "350103",
    "label": "Punung",
    "value": "Punung"
  },
  {
    "id": "3501032004",
    "district_id": "350103",
    "label": "Mendolo Kidul",
    "value": "Mendolo Kidul"
  },
  {
    "id": "3501032005",
    "district_id": "350103",
    "label": "Piton",
    "value": "Piton"
  },
  {
    "id": "3501032006",
    "district_id": "350103",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "3501032007",
    "district_id": "350103",
    "label": "Wareng",
    "value": "Wareng"
  },
  {
    "id": "3501032008",
    "district_id": "350103",
    "label": "Mantren",
    "value": "Mantren"
  },
  {
    "id": "3501032009",
    "district_id": "350103",
    "label": "Mendolo Lor",
    "value": "Mendolo Lor"
  },
  {
    "id": "3501032010",
    "district_id": "350103",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3501032011",
    "district_id": "350103",
    "label": "Gondosari",
    "value": "Gondosari"
  },
  {
    "id": "3501032012",
    "district_id": "350103",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3501032013",
    "district_id": "350103",
    "label": "Tinatar",
    "value": "Tinatar"
  },
  {
    "id": "3501041001",
    "district_id": "350104",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3501041002",
    "district_id": "350104",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3501041008",
    "district_id": "350104",
    "label": "Baleharjo",
    "value": "Baleharjo"
  },
  {
    "id": "3501041012",
    "district_id": "350104",
    "label": "Pucangsewu",
    "value": "Pucangsewu"
  },
  {
    "id": "3501041013",
    "district_id": "350104",
    "label": "Pacitan",
    "value": "Pacitan"
  },
  {
    "id": "3501042003",
    "district_id": "350104",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3501042004",
    "district_id": "350104",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3501042005",
    "district_id": "350104",
    "label": "Kayen",
    "value": "Kayen"
  },
  {
    "id": "3501042006",
    "district_id": "350104",
    "label": "Sirnoboyo",
    "value": "Sirnoboyo"
  },
  {
    "id": "3501042007",
    "district_id": "350104",
    "label": "Arjowinangun",
    "value": "Arjowinangun"
  },
  {
    "id": "3501042009",
    "district_id": "350104",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3501042010",
    "district_id": "350104",
    "label": "Sedeng",
    "value": "Sedeng"
  },
  {
    "id": "3501042011",
    "district_id": "350104",
    "label": "Sumberharjo",
    "value": "Sumberharjo"
  },
  {
    "id": "3501042014",
    "district_id": "350104",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3501042015",
    "district_id": "350104",
    "label": "Menadi",
    "value": "Menadi"
  },
  {
    "id": "3501042016",
    "district_id": "350104",
    "label": "Mentoro",
    "value": "Mentoro"
  },
  {
    "id": "3501042017",
    "district_id": "350104",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3501042018",
    "district_id": "350104",
    "label": "Nanggungan",
    "value": "Nanggungan"
  },
  {
    "id": "3501042019",
    "district_id": "350104",
    "label": "Widoro",
    "value": "Widoro"
  },
  {
    "id": "3501042020",
    "district_id": "350104",
    "label": "Semanten",
    "value": "Semanten"
  },
  {
    "id": "3501042021",
    "district_id": "350104",
    "label": "Sambong",
    "value": "Sambong"
  },
  {
    "id": "3501042022",
    "district_id": "350104",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3501042023",
    "district_id": "350104",
    "label": "Bolosingo",
    "value": "Bolosingo"
  },
  {
    "id": "3501042024",
    "district_id": "350104",
    "label": "Ponggok",
    "value": "Ponggok"
  },
  {
    "id": "3501042025",
    "district_id": "350104",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3501052001",
    "district_id": "350105",
    "label": "Plumbungan",
    "value": "Plumbungan"
  },
  {
    "id": "3501052002",
    "district_id": "350105",
    "label": "Karangnongko",
    "value": "Karangnongko"
  },
  {
    "id": "3501052003",
    "district_id": "350105",
    "label": "Kalipelus",
    "value": "Kalipelus"
  },
  {
    "id": "3501052004",
    "district_id": "350105",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3501052005",
    "district_id": "350105",
    "label": "Katipugal",
    "value": "Katipugal"
  },
  {
    "id": "3501052006",
    "district_id": "350105",
    "label": "Klesem",
    "value": "Klesem"
  },
  {
    "id": "3501052007",
    "district_id": "350105",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3501052008",
    "district_id": "350105",
    "label": "Worawari",
    "value": "Worawari"
  },
  {
    "id": "3501052009",
    "district_id": "350105",
    "label": "Mantren",
    "value": "Mantren"
  },
  {
    "id": "3501052010",
    "district_id": "350105",
    "label": "Gawang",
    "value": "Gawang"
  },
  {
    "id": "3501052011",
    "district_id": "350105",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3501052012",
    "district_id": "350105",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "3501052013",
    "district_id": "350105",
    "label": "Banjarjo",
    "value": "Banjarjo"
  },
  {
    "id": "3501052014",
    "district_id": "350105",
    "label": "Gembuk",
    "value": "Gembuk"
  },
  {
    "id": "3501052015",
    "district_id": "350105",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3501052016",
    "district_id": "350105",
    "label": "Punjung",
    "value": "Punjung"
  },
  {
    "id": "3501052017",
    "district_id": "350105",
    "label": "Wonogondo",
    "value": "Wonogondo"
  },
  {
    "id": "3501052018",
    "district_id": "350105",
    "label": "Ketepung",
    "value": "Ketepung"
  },
  {
    "id": "3501052019",
    "district_id": "350105",
    "label": "Ketro",
    "value": "Ketro"
  },
  {
    "id": "3501062001",
    "district_id": "350106",
    "label": "Mlati",
    "value": "Mlati"
  },
  {
    "id": "3501062002",
    "district_id": "350106",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3501062003",
    "district_id": "350106",
    "label": "Tremas",
    "value": "Tremas"
  },
  {
    "id": "3501062004",
    "district_id": "350106",
    "label": "Arjosari",
    "value": "Arjosari"
  },
  {
    "id": "3501062005",
    "district_id": "350106",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3501062006",
    "district_id": "350106",
    "label": "Pagutan",
    "value": "Pagutan"
  },
  {
    "id": "3501062007",
    "district_id": "350106",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "3501062008",
    "district_id": "350106",
    "label": "Borang",
    "value": "Borang"
  },
  {
    "id": "3501062009",
    "district_id": "350106",
    "label": "Gegeran",
    "value": "Gegeran"
  },
  {
    "id": "3501062010",
    "district_id": "350106",
    "label": "Kedungbendo",
    "value": "Kedungbendo"
  },
  {
    "id": "3501062011",
    "district_id": "350106",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3501062012",
    "district_id": "350106",
    "label": "Jetiskidul",
    "value": "Jetiskidul"
  },
  {
    "id": "3501062013",
    "district_id": "350106",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3501062014",
    "district_id": "350106",
    "label": "Karanggede",
    "value": "Karanggede"
  },
  {
    "id": "3501062015",
    "district_id": "350106",
    "label": "Gayuhan",
    "value": "Gayuhan"
  },
  {
    "id": "3501062016",
    "district_id": "350106",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3501062017",
    "district_id": "350106",
    "label": "Jatimalang",
    "value": "Jatimalang"
  },
  {
    "id": "3501072001",
    "district_id": "350107",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3501072002",
    "district_id": "350107",
    "label": "Mujing",
    "value": "Mujing"
  },
  {
    "id": "3501072003",
    "district_id": "350107",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3501072004",
    "district_id": "350107",
    "label": "Nawangan",
    "value": "Nawangan"
  },
  {
    "id": "3501072005",
    "district_id": "350107",
    "label": "Tokawi",
    "value": "Tokawi"
  },
  {
    "id": "3501072006",
    "district_id": "350107",
    "label": "Jetis Lor",
    "value": "Jetis Lor"
  },
  {
    "id": "3501072007",
    "district_id": "350107",
    "label": "Penggung",
    "value": "Penggung"
  },
  {
    "id": "3501072008",
    "district_id": "350107",
    "label": "Pakis Baru",
    "value": "Pakis Baru"
  },
  {
    "id": "3501072009",
    "district_id": "350107",
    "label": "Ngromo",
    "value": "Ngromo"
  },
  {
    "id": "3501082001",
    "district_id": "350108",
    "label": "Petungsinarang",
    "value": "Petungsinarang"
  },
  {
    "id": "3501082002",
    "district_id": "350108",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3501082003",
    "district_id": "350108",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "3501082004",
    "district_id": "350108",
    "label": "Kledung",
    "value": "Kledung"
  },
  {
    "id": "3501082005",
    "district_id": "350108",
    "label": "Tumpuk",
    "value": "Tumpuk"
  },
  {
    "id": "3501082006",
    "district_id": "350108",
    "label": "Watupatok",
    "value": "Watupatok"
  },
  {
    "id": "3501082007",
    "district_id": "350108",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3501082008",
    "district_id": "350108",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3501092001",
    "district_id": "350109",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3501092002",
    "district_id": "350109",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3501092003",
    "district_id": "350109",
    "label": "Ngreco",
    "value": "Ngreco"
  },
  {
    "id": "3501092004",
    "district_id": "350109",
    "label": "Kasihan",
    "value": "Kasihan"
  },
  {
    "id": "3501092005",
    "district_id": "350109",
    "label": "Pucangombo",
    "value": "Pucangombo"
  },
  {
    "id": "3501092006",
    "district_id": "350109",
    "label": "Gemaharjo",
    "value": "Gemaharjo"
  },
  {
    "id": "3501092007",
    "district_id": "350109",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3501092008",
    "district_id": "350109",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "3501092009",
    "district_id": "350109",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3501092010",
    "district_id": "350109",
    "label": "Tahunan",
    "value": "Tahunan"
  },
  {
    "id": "3501092011",
    "district_id": "350109",
    "label": "Tahunan Baru",
    "value": "Tahunan Baru"
  },
  {
    "id": "3501102001",
    "district_id": "350110",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3501102002",
    "district_id": "350110",
    "label": "Nglaran",
    "value": "Nglaran"
  },
  {
    "id": "3501102003",
    "district_id": "350110",
    "label": "Wonoanti",
    "value": "Wonoanti"
  },
  {
    "id": "3501102004",
    "district_id": "350110",
    "label": "Padi",
    "value": "Padi"
  },
  {
    "id": "3501102005",
    "district_id": "350110",
    "label": "Kluwih",
    "value": "Kluwih"
  },
  {
    "id": "3501102006",
    "district_id": "350110",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "3501102007",
    "district_id": "350110",
    "label": "Tulakan",
    "value": "Tulakan"
  },
  {
    "id": "3501102008",
    "district_id": "350110",
    "label": "Jatigunung",
    "value": "Jatigunung"
  },
  {
    "id": "3501102009",
    "district_id": "350110",
    "label": "Gasang",
    "value": "Gasang"
  },
  {
    "id": "3501102010",
    "district_id": "350110",
    "label": "Kalikuning",
    "value": "Kalikuning"
  },
  {
    "id": "3501102011",
    "district_id": "350110",
    "label": "Ngile",
    "value": "Ngile"
  },
  {
    "id": "3501102012",
    "district_id": "350110",
    "label": "Bubakan",
    "value": "Bubakan"
  },
  {
    "id": "3501102013",
    "district_id": "350110",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3501102014",
    "district_id": "350110",
    "label": "Ngumbul",
    "value": "Ngumbul"
  },
  {
    "id": "3501102015",
    "district_id": "350110",
    "label": "Wonosidi",
    "value": "Wonosidi"
  },
  {
    "id": "3501102016",
    "district_id": "350110",
    "label": "Ketro",
    "value": "Ketro"
  },
  {
    "id": "3501112001",
    "district_id": "350111",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3501112002",
    "district_id": "350111",
    "label": "Hadiwarno",
    "value": "Hadiwarno"
  },
  {
    "id": "3501112003",
    "district_id": "350111",
    "label": "Tanjungpuro",
    "value": "Tanjungpuro"
  },
  {
    "id": "3501112004",
    "district_id": "350111",
    "label": "Hadiluwih",
    "value": "Hadiluwih"
  },
  {
    "id": "3501112005",
    "district_id": "350111",
    "label": "Pagerejo",
    "value": "Pagerejo"
  },
  {
    "id": "3501112006",
    "district_id": "350111",
    "label": "Wiyoro",
    "value": "Wiyoro"
  },
  {
    "id": "3501112007",
    "district_id": "350111",
    "label": "Ngadirojo",
    "value": "Ngadirojo"
  },
  {
    "id": "3501112008",
    "district_id": "350111",
    "label": "Bogoharjo",
    "value": "Bogoharjo"
  },
  {
    "id": "3501112009",
    "district_id": "350111",
    "label": "Cokrokembang",
    "value": "Cokrokembang"
  },
  {
    "id": "3501112010",
    "district_id": "350111",
    "label": "Bodag",
    "value": "Bodag"
  },
  {
    "id": "3501112011",
    "district_id": "350111",
    "label": "Tanjunglor",
    "value": "Tanjunglor"
  },
  {
    "id": "3501112012",
    "district_id": "350111",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  {
    "id": "3501112013",
    "district_id": "350111",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3501112014",
    "district_id": "350111",
    "label": "Wonodadi Wetan",
    "value": "Wonodadi Wetan"
  },
  {
    "id": "3501112015",
    "district_id": "350111",
    "label": "Wonodadi Kulon",
    "value": "Wonodadi Kulon"
  },
  {
    "id": "3501112016",
    "district_id": "350111",
    "label": "Wonokarto",
    "value": "Wonokarto"
  },
  {
    "id": "3501112017",
    "district_id": "350111",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "3501112018",
    "district_id": "350111",
    "label": "Wonoasri",
    "value": "Wonoasri"
  },
  {
    "id": "3501122001",
    "district_id": "350112",
    "label": "Pagerkidul",
    "value": "Pagerkidul"
  },
  {
    "id": "3501122002",
    "district_id": "350112",
    "label": "Pagerlor",
    "value": "Pagerlor"
  },
  {
    "id": "3501122003",
    "district_id": "350112",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3501122004",
    "district_id": "350112",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3501122005",
    "district_id": "350112",
    "label": "Ketanggung",
    "value": "Ketanggung"
  },
  {
    "id": "3501122006",
    "district_id": "350112",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3501122007",
    "district_id": "350112",
    "label": "Sembowo",
    "value": "Sembowo"
  },
  {
    "id": "3501122008",
    "district_id": "350112",
    "label": "Karangmulyo",
    "value": "Karangmulyo"
  },
  {
    "id": "3501122009",
    "district_id": "350112",
    "label": "Gunungrejo",
    "value": "Gunungrejo"
  },
  {
    "id": "3501122010",
    "district_id": "350112",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3502012001",
    "district_id": "350201",
    "label": "Tugurejo",
    "value": "Tugurejo"
  },
  {
    "id": "3502012002",
    "district_id": "350201",
    "label": "Senepo",
    "value": "Senepo"
  },
  {
    "id": "3502012003",
    "district_id": "350201",
    "label": "Slahung",
    "value": "Slahung"
  },
  {
    "id": "3502012004",
    "district_id": "350201",
    "label": "Caluk",
    "value": "Caluk"
  },
  {
    "id": "3502012005",
    "district_id": "350201",
    "label": "Broto",
    "value": "Broto"
  },
  {
    "id": "3502012006",
    "district_id": "350201",
    "label": "Menggare",
    "value": "Menggare"
  },
  {
    "id": "3502012007",
    "district_id": "350201",
    "label": "Kambeng",
    "value": "Kambeng"
  },
  {
    "id": "3502012008",
    "district_id": "350201",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3502012009",
    "district_id": "350201",
    "label": "Ngilo-Ilo",
    "value": "Ngilo-Ilo"
  },
  {
    "id": "3502012010",
    "district_id": "350201",
    "label": "Duri",
    "value": "Duri"
  },
  {
    "id": "3502012011",
    "district_id": "350201",
    "label": "Ngloning",
    "value": "Ngloning"
  },
  {
    "id": "3502012012",
    "district_id": "350201",
    "label": "Plancungan",
    "value": "Plancungan"
  },
  {
    "id": "3502012013",
    "district_id": "350201",
    "label": "Jebeng",
    "value": "Jebeng"
  },
  {
    "id": "3502012014",
    "district_id": "350201",
    "label": "Galak",
    "value": "Galak"
  },
  {
    "id": "3502012015",
    "district_id": "350201",
    "label": "Truneng",
    "value": "Truneng"
  },
  {
    "id": "3502012016",
    "district_id": "350201",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3502012017",
    "district_id": "350201",
    "label": "Crabak",
    "value": "Crabak"
  },
  {
    "id": "3502012018",
    "district_id": "350201",
    "label": "Mojopitu",
    "value": "Mojopitu"
  },
  {
    "id": "3502012019",
    "district_id": "350201",
    "label": "Gundik",
    "value": "Gundik"
  },
  {
    "id": "3502012020",
    "district_id": "350201",
    "label": "Nailan",
    "value": "Nailan"
  },
  {
    "id": "3502012021",
    "district_id": "350201",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3502012022",
    "district_id": "350201",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3502022001",
    "district_id": "350202",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3502022002",
    "district_id": "350202",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3502022003",
    "district_id": "350202",
    "label": "Baosan Kidul",
    "value": "Baosan Kidul"
  },
  {
    "id": "3502022004",
    "district_id": "350202",
    "label": "Binade",
    "value": "Binade"
  },
  {
    "id": "3502022005",
    "district_id": "350202",
    "label": "Mrayan",
    "value": "Mrayan"
  },
  {
    "id": "3502022006",
    "district_id": "350202",
    "label": "Baosan Lor",
    "value": "Baosan Lor"
  },
  {
    "id": "3502022007",
    "district_id": "350202",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3502022008",
    "district_id": "350202",
    "label": "Selur",
    "value": "Selur"
  },
  {
    "id": "3502022009",
    "district_id": "350202",
    "label": "Ngrayun",
    "value": "Ngrayun"
  },
  {
    "id": "3502022010",
    "district_id": "350202",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3502022011",
    "district_id": "350202",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3502032001",
    "district_id": "350203",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3502032002",
    "district_id": "350203",
    "label": "Koripan",
    "value": "Koripan"
  },
  {
    "id": "3502032003",
    "district_id": "350203",
    "label": "Bekare",
    "value": "Bekare"
  },
  {
    "id": "3502032004",
    "district_id": "350203",
    "label": "Nambak",
    "value": "Nambak"
  },
  {
    "id": "3502032005",
    "district_id": "350203",
    "label": "Kalisat",
    "value": "Kalisat"
  },
  {
    "id": "3502032006",
    "district_id": "350203",
    "label": "Munggu",
    "value": "Munggu"
  },
  {
    "id": "3502032007",
    "district_id": "350203",
    "label": "Pager",
    "value": "Pager"
  },
  {
    "id": "3502032008",
    "district_id": "350203",
    "label": "Belang",
    "value": "Belang"
  },
  {
    "id": "3502032009",
    "district_id": "350203",
    "label": "Bungkal",
    "value": "Bungkal"
  },
  {
    "id": "3502032010",
    "district_id": "350203",
    "label": "Ketonggo",
    "value": "Ketonggo"
  },
  {
    "id": "3502032011",
    "district_id": "350203",
    "label": "Kunti",
    "value": "Kunti"
  },
  {
    "id": "3502032012",
    "district_id": "350203",
    "label": "Bancar",
    "value": "Bancar"
  },
  {
    "id": "3502032013",
    "district_id": "350203",
    "label": "Padas",
    "value": "Padas"
  },
  {
    "id": "3502032014",
    "district_id": "350203",
    "label": "Bungu",
    "value": "Bungu"
  },
  {
    "id": "3502032015",
    "district_id": "350203",
    "label": "Kupuk",
    "value": "Kupuk"
  },
  {
    "id": "3502032016",
    "district_id": "350203",
    "label": "Sambilawang",
    "value": "Sambilawang"
  },
  {
    "id": "3502032017",
    "district_id": "350203",
    "label": "Kwajon",
    "value": "Kwajon"
  },
  {
    "id": "3502032018",
    "district_id": "350203",
    "label": "Bediwetan",
    "value": "Bediwetan"
  },
  {
    "id": "3502032019",
    "district_id": "350203",
    "label": "Bedikulon",
    "value": "Bedikulon"
  },
  {
    "id": "3502042001",
    "district_id": "350204",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "3502042002",
    "district_id": "350204",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3502042003",
    "district_id": "350204",
    "label": "Ngadisanan",
    "value": "Ngadisanan"
  },
  {
    "id": "3502042004",
    "district_id": "350204",
    "label": "Maguwan",
    "value": "Maguwan"
  },
  {
    "id": "3502042005",
    "district_id": "350204",
    "label": "Nglewan",
    "value": "Nglewan"
  },
  {
    "id": "3502042006",
    "district_id": "350204",
    "label": "Bedingin",
    "value": "Bedingin"
  },
  {
    "id": "3502042007",
    "district_id": "350204",
    "label": "Bancangan",
    "value": "Bancangan"
  },
  {
    "id": "3502042008",
    "district_id": "350204",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3502042009",
    "district_id": "350204",
    "label": "Campursari",
    "value": "Campursari"
  },
  {
    "id": "3502042010",
    "district_id": "350204",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3502042011",
    "district_id": "350204",
    "label": "Sambit",
    "value": "Sambit"
  },
  {
    "id": "3502042012",
    "district_id": "350204",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3502042013",
    "district_id": "350204",
    "label": "Wilangan",
    "value": "Wilangan"
  },
  {
    "id": "3502042014",
    "district_id": "350204",
    "label": "Bangsalan",
    "value": "Bangsalan"
  },
  {
    "id": "3502042015",
    "district_id": "350204",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3502042016",
    "district_id": "350204",
    "label": "Jrakah",
    "value": "Jrakah"
  },
  {
    "id": "3502052001",
    "district_id": "350205",
    "label": "Tumpuk",
    "value": "Tumpuk"
  },
  {
    "id": "3502052002",
    "district_id": "350205",
    "label": "Pangkal",
    "value": "Pangkal"
  },
  {
    "id": "3502052003",
    "district_id": "350205",
    "label": "Tumpak Pelem",
    "value": "Tumpak Pelem"
  },
  {
    "id": "3502052004",
    "district_id": "350205",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3502052005",
    "district_id": "350205",
    "label": "Sriti",
    "value": "Sriti"
  },
  {
    "id": "3502052006",
    "district_id": "350205",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3502052007",
    "district_id": "350205",
    "label": "Sawoo",
    "value": "Sawoo"
  },
  {
    "id": "3502052008",
    "district_id": "350205",
    "label": "Prayungan",
    "value": "Prayungan"
  },
  {
    "id": "3502052009",
    "district_id": "350205",
    "label": "Tugurejo",
    "value": "Tugurejo"
  },
  {
    "id": "3502052010",
    "district_id": "350205",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3502052011",
    "district_id": "350205",
    "label": "Ketro",
    "value": "Ketro"
  },
  {
    "id": "3502052012",
    "district_id": "350205",
    "label": "Kori",
    "value": "Kori"
  },
  {
    "id": "3502052013",
    "district_id": "350205",
    "label": "Bondrang",
    "value": "Bondrang"
  },
  {
    "id": "3502052014",
    "district_id": "350205",
    "label": "Ngindeng",
    "value": "Ngindeng"
  },
  {
    "id": "3502062001",
    "district_id": "350206",
    "label": "Ngadirojo",
    "value": "Ngadirojo"
  },
  {
    "id": "3502062002",
    "district_id": "350206",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3502062003",
    "district_id": "350206",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "3502062004",
    "district_id": "350206",
    "label": "Sooko",
    "value": "Sooko"
  },
  {
    "id": "3502062005",
    "district_id": "350206",
    "label": "Bedoho",
    "value": "Bedoho"
  },
  {
    "id": "3502062006",
    "district_id": "350206",
    "label": "Jurug",
    "value": "Jurug"
  },
  {
    "id": "3502072001",
    "district_id": "350207",
    "label": "Karangpatihan",
    "value": "Karangpatihan"
  },
  {
    "id": "3502072002",
    "district_id": "350207",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3502072003",
    "district_id": "350207",
    "label": "Bedrug",
    "value": "Bedrug"
  },
  {
    "id": "3502072004",
    "district_id": "350207",
    "label": "Wagir Kidul",
    "value": "Wagir Kidul"
  },
  {
    "id": "3502072005",
    "district_id": "350207",
    "label": "Singgahan",
    "value": "Singgahan"
  },
  {
    "id": "3502072006",
    "district_id": "350207",
    "label": "Patik",
    "value": "Patik"
  },
  {
    "id": "3502072007",
    "district_id": "350207",
    "label": "Pulung",
    "value": "Pulung"
  },
  {
    "id": "3502072008",
    "district_id": "350207",
    "label": "Pulung Merdiko",
    "value": "Pulung Merdiko"
  },
  {
    "id": "3502072009",
    "district_id": "350207",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3502072010",
    "district_id": "350207",
    "label": "Wotan",
    "value": "Wotan"
  },
  {
    "id": "3502072011",
    "district_id": "350207",
    "label": "Plunturan",
    "value": "Plunturan"
  },
  {
    "id": "3502072012",
    "district_id": "350207",
    "label": "Pomahan",
    "value": "Pomahan"
  },
  {
    "id": "3502072013",
    "district_id": "350207",
    "label": "Kesugihan",
    "value": "Kesugihan"
  },
  {
    "id": "3502072014",
    "district_id": "350207",
    "label": "Serag",
    "value": "Serag"
  },
  {
    "id": "3502072015",
    "district_id": "350207",
    "label": "Wayang",
    "value": "Wayang"
  },
  {
    "id": "3502072016",
    "district_id": "350207",
    "label": "Munggung",
    "value": "Munggung"
  },
  {
    "id": "3502072017",
    "district_id": "350207",
    "label": "Bekiring",
    "value": "Bekiring"
  },
  {
    "id": "3502072018",
    "district_id": "350207",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3502082001",
    "district_id": "350208",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3502082002",
    "district_id": "350208",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3502082003",
    "district_id": "350208",
    "label": "Totokan",
    "value": "Totokan"
  },
  {
    "id": "3502082004",
    "district_id": "350208",
    "label": "Ngrukem",
    "value": "Ngrukem"
  },
  {
    "id": "3502082005",
    "district_id": "350208",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "3502082006",
    "district_id": "350208",
    "label": "Joresan",
    "value": "Joresan"
  },
  {
    "id": "3502082007",
    "district_id": "350208",
    "label": "Nglumpang",
    "value": "Nglumpang"
  },
  {
    "id": "3502082008",
    "district_id": "350208",
    "label": "Gontor",
    "value": "Gontor"
  },
  {
    "id": "3502082009",
    "district_id": "350208",
    "label": "Gandu",
    "value": "Gandu"
  },
  {
    "id": "3502082010",
    "district_id": "350208",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3502082011",
    "district_id": "350208",
    "label": "Bajang",
    "value": "Bajang"
  },
  {
    "id": "3502082012",
    "district_id": "350208",
    "label": "Mlarak",
    "value": "Mlarak"
  },
  {
    "id": "3502082013",
    "district_id": "350208",
    "label": "Serangan",
    "value": "Serangan"
  },
  {
    "id": "3502082014",
    "district_id": "350208",
    "label": "Suren",
    "value": "Suren"
  },
  {
    "id": "3502082015",
    "district_id": "350208",
    "label": "Kaponan",
    "value": "Kaponan"
  },
  {
    "id": "3502092001",
    "district_id": "350209",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3502092002",
    "district_id": "350209",
    "label": "Kutu Kulon",
    "value": "Kutu Kulon"
  },
  {
    "id": "3502092003",
    "district_id": "350209",
    "label": "Kutu Wetan",
    "value": "Kutu Wetan"
  },
  {
    "id": "3502092004",
    "district_id": "350209",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3502092005",
    "district_id": "350209",
    "label": "Mojomati",
    "value": "Mojomati"
  },
  {
    "id": "3502092006",
    "district_id": "350209",
    "label": "Coper",
    "value": "Coper"
  },
  {
    "id": "3502092007",
    "district_id": "350209",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3502092008",
    "district_id": "350209",
    "label": "Karanggebang",
    "value": "Karanggebang"
  },
  {
    "id": "3502092009",
    "district_id": "350209",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3502092010",
    "district_id": "350209",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3502092011",
    "district_id": "350209",
    "label": "Wonoketro",
    "value": "Wonoketro"
  },
  {
    "id": "3502092012",
    "district_id": "350209",
    "label": "Josari",
    "value": "Josari"
  },
  {
    "id": "3502092013",
    "district_id": "350209",
    "label": "Turi",
    "value": "Turi"
  },
  {
    "id": "3502092014",
    "district_id": "350209",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3502101017",
    "district_id": "350210",
    "label": "Ronowijayan",
    "value": "Ronowijayan"
  },
  {
    "id": "3502101018",
    "district_id": "350210",
    "label": "Mangunsuman",
    "value": "Mangunsuman"
  },
  {
    "id": "3502102001",
    "district_id": "350210",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3502102002",
    "district_id": "350210",
    "label": "Ngabar",
    "value": "Ngabar"
  },
  {
    "id": "3502102003",
    "district_id": "350210",
    "label": "Madusari",
    "value": "Madusari"
  },
  {
    "id": "3502102004",
    "district_id": "350210",
    "label": "Beton",
    "value": "Beton"
  },
  {
    "id": "3502102005",
    "district_id": "350210",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3502102006",
    "district_id": "350210",
    "label": "Brahu",
    "value": "Brahu"
  },
  {
    "id": "3502102007",
    "district_id": "350210",
    "label": "Kepuhrubuh",
    "value": "Kepuhrubuh"
  },
  {
    "id": "3502102008",
    "district_id": "350210",
    "label": "Sawuh",
    "value": "Sawuh"
  },
  {
    "id": "3502102009",
    "district_id": "350210",
    "label": "Jarak",
    "value": "Jarak"
  },
  {
    "id": "3502102010",
    "district_id": "350210",
    "label": "Tranjang",
    "value": "Tranjang"
  },
  {
    "id": "3502102011",
    "district_id": "350210",
    "label": "Pijeran",
    "value": "Pijeran"
  },
  {
    "id": "3502102012",
    "district_id": "350210",
    "label": "Manuk",
    "value": "Manuk"
  },
  {
    "id": "3502102013",
    "district_id": "350210",
    "label": "Siman",
    "value": "Siman"
  },
  {
    "id": "3502102014",
    "district_id": "350210",
    "label": "Patihan Kidul",
    "value": "Patihan Kidul"
  },
  {
    "id": "3502102015",
    "district_id": "350210",
    "label": "Ronosentanan",
    "value": "Ronosentanan"
  },
  {
    "id": "3502102016",
    "district_id": "350210",
    "label": "Tajug",
    "value": "Tajug"
  },
  {
    "id": "3502112001",
    "district_id": "350211",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "3502112002",
    "district_id": "350211",
    "label": "Bulu Kidul",
    "value": "Bulu Kidul"
  },
  {
    "id": "3502112003",
    "district_id": "350211",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3502112004",
    "district_id": "350211",
    "label": "Ngendut",
    "value": "Ngendut"
  },
  {
    "id": "3502112005",
    "district_id": "350211",
    "label": "Karangpatihan",
    "value": "Karangpatihan"
  },
  {
    "id": "3502112006",
    "district_id": "350211",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3502112007",
    "district_id": "350211",
    "label": "Ngumpul",
    "value": "Ngumpul"
  },
  {
    "id": "3502112008",
    "district_id": "350211",
    "label": "Ngraket",
    "value": "Ngraket"
  },
  {
    "id": "3502112009",
    "district_id": "350211",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3502112010",
    "district_id": "350211",
    "label": "Singkil",
    "value": "Singkil"
  },
  {
    "id": "3502112011",
    "district_id": "350211",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "3502112012",
    "district_id": "350211",
    "label": "Bajang",
    "value": "Bajang"
  },
  {
    "id": "3502112013",
    "district_id": "350211",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "3502112014",
    "district_id": "350211",
    "label": "Jalen",
    "value": "Jalen"
  },
  {
    "id": "3502112015",
    "district_id": "350211",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3502112016",
    "district_id": "350211",
    "label": "Sedarat",
    "value": "Sedarat"
  },
  {
    "id": "3502112017",
    "district_id": "350211",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3502112018",
    "district_id": "350211",
    "label": "Tatung",
    "value": "Tatung"
  },
  {
    "id": "3502112019",
    "district_id": "350211",
    "label": "Muneng",
    "value": "Muneng"
  },
  {
    "id": "3502112020",
    "district_id": "350211",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3502122001",
    "district_id": "350212",
    "label": "Tegalombo",
    "value": "Tegalombo"
  },
  {
    "id": "3502122002",
    "district_id": "350212",
    "label": "Nongkodono",
    "value": "Nongkodono"
  },
  {
    "id": "3502122003",
    "district_id": "350212",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3502122004",
    "district_id": "350212",
    "label": "Ngrandu",
    "value": "Ngrandu"
  },
  {
    "id": "3502122005",
    "district_id": "350212",
    "label": "Nglarangan",
    "value": "Nglarangan"
  },
  {
    "id": "3502122006",
    "district_id": "350212",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3502122007",
    "district_id": "350212",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3502122008",
    "district_id": "350212",
    "label": "Gabel",
    "value": "Gabel"
  },
  {
    "id": "3502122009",
    "district_id": "350212",
    "label": "Ciluk",
    "value": "Ciluk"
  },
  {
    "id": "3502122010",
    "district_id": "350212",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "3502122011",
    "district_id": "350212",
    "label": "Tosanan",
    "value": "Tosanan"
  },
  {
    "id": "3502122012",
    "district_id": "350212",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3502122013",
    "district_id": "350212",
    "label": "Somoroto",
    "value": "Somoroto"
  },
  {
    "id": "3502122014",
    "district_id": "350212",
    "label": "Plosojenar",
    "value": "Plosojenar"
  },
  {
    "id": "3502122015",
    "district_id": "350212",
    "label": "Carat",
    "value": "Carat"
  },
  {
    "id": "3502122016",
    "district_id": "350212",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3502132001",
    "district_id": "350213",
    "label": "Dayakan",
    "value": "Dayakan"
  },
  {
    "id": "3502132002",
    "district_id": "350213",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "3502132003",
    "district_id": "350213",
    "label": "Tanjunggunung",
    "value": "Tanjunggunung"
  },
  {
    "id": "3502132004",
    "district_id": "350213",
    "label": "Karangjoho",
    "value": "Karangjoho"
  },
  {
    "id": "3502132005",
    "district_id": "350213",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3502132006",
    "district_id": "350213",
    "label": "Bandaralim",
    "value": "Bandaralim"
  },
  {
    "id": "3502132007",
    "district_id": "350213",
    "label": "Kapuran",
    "value": "Kapuran"
  },
  {
    "id": "3502132008",
    "district_id": "350213",
    "label": "Watubonang",
    "value": "Watubonang"
  },
  {
    "id": "3502132009",
    "district_id": "350213",
    "label": "Biting",
    "value": "Biting"
  },
  {
    "id": "3502132010",
    "district_id": "350213",
    "label": "Badegan",
    "value": "Badegan"
  },
  {
    "id": "3502142001",
    "district_id": "350214",
    "label": "Sampung",
    "value": "Sampung"
  },
  {
    "id": "3502142002",
    "district_id": "350214",
    "label": "Pohijo",
    "value": "Pohijo"
  },
  {
    "id": "3502142003",
    "district_id": "350214",
    "label": "Jenangan",
    "value": "Jenangan"
  },
  {
    "id": "3502142004",
    "district_id": "350214",
    "label": "Nglurup",
    "value": "Nglurup"
  },
  {
    "id": "3502142005",
    "district_id": "350214",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "3502142006",
    "district_id": "350214",
    "label": "Carangrejo",
    "value": "Carangrejo"
  },
  {
    "id": "3502142007",
    "district_id": "350214",
    "label": "Pagerukir",
    "value": "Pagerukir"
  },
  {
    "id": "3502142008",
    "district_id": "350214",
    "label": "Kunti",
    "value": "Kunti"
  },
  {
    "id": "3502142009",
    "district_id": "350214",
    "label": "Glinggang",
    "value": "Glinggang"
  },
  {
    "id": "3502142010",
    "district_id": "350214",
    "label": "Gelangkulon",
    "value": "Gelangkulon"
  },
  {
    "id": "3502142011",
    "district_id": "350214",
    "label": "Karangwaluh",
    "value": "Karangwaluh"
  },
  {
    "id": "3502142012",
    "district_id": "350214",
    "label": "Ringinputih",
    "value": "Ringinputih"
  },
  {
    "id": "3502152001",
    "district_id": "350215",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3502152002",
    "district_id": "350215",
    "label": "Bangunrejo",
    "value": "Bangunrejo"
  },
  {
    "id": "3502152003",
    "district_id": "350215",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3502152004",
    "district_id": "350215",
    "label": "Nambangrejo",
    "value": "Nambangrejo"
  },
  {
    "id": "3502152005",
    "district_id": "350215",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3502152006",
    "district_id": "350215",
    "label": "Gelang Lor",
    "value": "Gelang Lor"
  },
  {
    "id": "3502152007",
    "district_id": "350215",
    "label": "Serangan",
    "value": "Serangan"
  },
  {
    "id": "3502152008",
    "district_id": "350215",
    "label": "Nampan",
    "value": "Nampan"
  },
  {
    "id": "3502152009",
    "district_id": "350215",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3502152010",
    "district_id": "350215",
    "label": "Golan",
    "value": "Golan"
  },
  {
    "id": "3502152011",
    "district_id": "350215",
    "label": "Prajegan",
    "value": "Prajegan"
  },
  {
    "id": "3502152012",
    "district_id": "350215",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3502152013",
    "district_id": "350215",
    "label": "Karanglo Lor",
    "value": "Karanglo Lor"
  },
  {
    "id": "3502152014",
    "district_id": "350215",
    "label": "Gegeran",
    "value": "Gegeran"
  },
  {
    "id": "3502152015",
    "district_id": "350215",
    "label": "Gandukepuh",
    "value": "Gandukepuh"
  },
  {
    "id": "3502152016",
    "district_id": "350215",
    "label": "Kalimalang",
    "value": "Kalimalang"
  },
  {
    "id": "3502152017",
    "district_id": "350215",
    "label": "Sragi",
    "value": "Sragi"
  },
  {
    "id": "3502152018",
    "district_id": "350215",
    "label": "Morosari",
    "value": "Morosari"
  },
  {
    "id": "3502161001",
    "district_id": "350216",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3502161003",
    "district_id": "350216",
    "label": "Patihan Wetan",
    "value": "Patihan Wetan"
  },
  {
    "id": "3502161005",
    "district_id": "350216",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3502162002",
    "district_id": "350216",
    "label": "Cekok",
    "value": "Cekok"
  },
  {
    "id": "3502162004",
    "district_id": "350216",
    "label": "Japan",
    "value": "Japan"
  },
  {
    "id": "3502162006",
    "district_id": "350216",
    "label": "Gupolo",
    "value": "Gupolo"
  },
  {
    "id": "3502162007",
    "district_id": "350216",
    "label": "Polorejo",
    "value": "Polorejo"
  },
  {
    "id": "3502162008",
    "district_id": "350216",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3502162009",
    "district_id": "350216",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3502162010",
    "district_id": "350216",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3502162011",
    "district_id": "350216",
    "label": "Lembah",
    "value": "Lembah"
  },
  {
    "id": "3502162012",
    "district_id": "350216",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "3502162013",
    "district_id": "350216",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3502162014",
    "district_id": "350216",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3502162015",
    "district_id": "350216",
    "label": "Trisono",
    "value": "Trisono"
  },
  {
    "id": "3502171001",
    "district_id": "350217",
    "label": "Paju",
    "value": "Paju"
  },
  {
    "id": "3502171002",
    "district_id": "350217",
    "label": "Brotonegaran",
    "value": "Brotonegaran"
  },
  {
    "id": "3502171003",
    "district_id": "350217",
    "label": "Pakunden",
    "value": "Pakunden"
  },
  {
    "id": "3502171004",
    "district_id": "350217",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3502171005",
    "district_id": "350217",
    "label": "Surodikraman",
    "value": "Surodikraman"
  },
  {
    "id": "3502171006",
    "district_id": "350217",
    "label": "Purbosuman",
    "value": "Purbosuman"
  },
  {
    "id": "3502171007",
    "district_id": "350217",
    "label": "Tonatan",
    "value": "Tonatan"
  },
  {
    "id": "3502171008",
    "district_id": "350217",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3502171009",
    "district_id": "350217",
    "label": "Tamanarum",
    "value": "Tamanarum"
  },
  {
    "id": "3502171010",
    "district_id": "350217",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3502171011",
    "district_id": "350217",
    "label": "Tambakbayan",
    "value": "Tambakbayan"
  },
  {
    "id": "3502171012",
    "district_id": "350217",
    "label": "Pinggirsari",
    "value": "Pinggirsari"
  },
  {
    "id": "3502171013",
    "district_id": "350217",
    "label": "Mangkujayan",
    "value": "Mangkujayan"
  },
  {
    "id": "3502171014",
    "district_id": "350217",
    "label": "Banyudono",
    "value": "Banyudono"
  },
  {
    "id": "3502171015",
    "district_id": "350217",
    "label": "Nologaten",
    "value": "Nologaten"
  },
  {
    "id": "3502171016",
    "district_id": "350217",
    "label": "Cokromenggalan",
    "value": "Cokromenggalan"
  },
  {
    "id": "3502171017",
    "district_id": "350217",
    "label": "Keniten",
    "value": "Keniten"
  },
  {
    "id": "3502171018",
    "district_id": "350217",
    "label": "Jingglong",
    "value": "Jingglong"
  },
  {
    "id": "3502171019",
    "district_id": "350217",
    "label": "Beduri",
    "value": "Beduri"
  },
  {
    "id": "3502181001",
    "district_id": "350218",
    "label": "Setono",
    "value": "Setono"
  },
  {
    "id": "3502181002",
    "district_id": "350218",
    "label": "Singosaren",
    "value": "Singosaren"
  },
  {
    "id": "3502182003",
    "district_id": "350218",
    "label": "Mrican",
    "value": "Mrican"
  },
  {
    "id": "3502182004",
    "district_id": "350218",
    "label": "Plalangan",
    "value": "Plalangan"
  },
  {
    "id": "3502182005",
    "district_id": "350218",
    "label": "Nglayang",
    "value": "Nglayang"
  },
  {
    "id": "3502182006",
    "district_id": "350218",
    "label": "Jenangan",
    "value": "Jenangan"
  },
  {
    "id": "3502182007",
    "district_id": "350218",
    "label": "Jimbe",
    "value": "Jimbe"
  },
  {
    "id": "3502182008",
    "district_id": "350218",
    "label": "Ngrupit",
    "value": "Ngrupit"
  },
  {
    "id": "3502182009",
    "district_id": "350218",
    "label": "Pintu",
    "value": "Pintu"
  },
  {
    "id": "3502182010",
    "district_id": "350218",
    "label": "Sedah",
    "value": "Sedah"
  },
  {
    "id": "3502182011",
    "district_id": "350218",
    "label": "Panjeng",
    "value": "Panjeng"
  },
  {
    "id": "3502182012",
    "district_id": "350218",
    "label": "Sraten",
    "value": "Sraten"
  },
  {
    "id": "3502182013",
    "district_id": "350218",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "3502182014",
    "district_id": "350218",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3502182015",
    "district_id": "350218",
    "label": "Paringan",
    "value": "Paringan"
  },
  {
    "id": "3502182016",
    "district_id": "350218",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3502182017",
    "district_id": "350218",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3502192001",
    "district_id": "350219",
    "label": "Ngrogung",
    "value": "Ngrogung"
  },
  {
    "id": "3502192002",
    "district_id": "350219",
    "label": "Sahang",
    "value": "Sahang"
  },
  {
    "id": "3502192003",
    "district_id": "350219",
    "label": "Wagir Lor",
    "value": "Wagir Lor"
  },
  {
    "id": "3502192004",
    "district_id": "350219",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3502192005",
    "district_id": "350219",
    "label": "Gondowido",
    "value": "Gondowido"
  },
  {
    "id": "3502192006",
    "district_id": "350219",
    "label": "Pupus",
    "value": "Pupus"
  },
  {
    "id": "3502192007",
    "district_id": "350219",
    "label": "Ngebel",
    "value": "Ngebel"
  },
  {
    "id": "3502192008",
    "district_id": "350219",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3502202001",
    "district_id": "350220",
    "label": "Jonggol",
    "value": "Jonggol"
  },
  {
    "id": "3502202002",
    "district_id": "350220",
    "label": "Poko",
    "value": "Poko"
  },
  {
    "id": "3502202003",
    "district_id": "350220",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3502202004",
    "district_id": "350220",
    "label": "Bringinan",
    "value": "Bringinan"
  },
  {
    "id": "3502202005",
    "district_id": "350220",
    "label": "Karanglo Kidul",
    "value": "Karanglo Kidul"
  },
  {
    "id": "3502202006",
    "district_id": "350220",
    "label": "Krebet",
    "value": "Krebet"
  },
  {
    "id": "3502202007",
    "district_id": "350220",
    "label": "Bulu Lor",
    "value": "Bulu Lor"
  },
  {
    "id": "3502202008",
    "district_id": "350220",
    "label": "Jambon",
    "value": "Jambon"
  },
  {
    "id": "3502202009",
    "district_id": "350220",
    "label": "Blembem",
    "value": "Blembem"
  },
  {
    "id": "3502202010",
    "district_id": "350220",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3502202011",
    "district_id": "350220",
    "label": "Menang",
    "value": "Menang"
  },
  {
    "id": "3502202012",
    "district_id": "350220",
    "label": "Srandil",
    "value": "Srandil"
  },
  {
    "id": "3502202013",
    "district_id": "350220",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3502212001",
    "district_id": "350221",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3502212002",
    "district_id": "350221",
    "label": "Tambang",
    "value": "Tambang"
  },
  {
    "id": "3502212003",
    "district_id": "350221",
    "label": "Krisik",
    "value": "Krisik"
  },
  {
    "id": "3502212004",
    "district_id": "350221",
    "label": "Pudak Wetan",
    "value": "Pudak Wetan"
  },
  {
    "id": "3502212005",
    "district_id": "350221",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3502212006",
    "district_id": "350221",
    "label": "Pudak Kulon",
    "value": "Pudak Kulon"
  },
  {
    "id": "3503012001",
    "district_id": "350301",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3503012002",
    "district_id": "350301",
    "label": "Terbis",
    "value": "Terbis"
  },
  {
    "id": "3503012003",
    "district_id": "350301",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3503012004",
    "district_id": "350301",
    "label": "Wonocoyo",
    "value": "Wonocoyo"
  },
  {
    "id": "3503012005",
    "district_id": "350301",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3503012006",
    "district_id": "350301",
    "label": "Kertosono",
    "value": "Kertosono"
  },
  {
    "id": "3503012007",
    "district_id": "350301",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3503012008",
    "district_id": "350301",
    "label": "Panggul",
    "value": "Panggul"
  },
  {
    "id": "3503012009",
    "district_id": "350301",
    "label": "Bodag",
    "value": "Bodag"
  },
  {
    "id": "3503012010",
    "district_id": "350301",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3503012011",
    "district_id": "350301",
    "label": "Nglebeng",
    "value": "Nglebeng"
  },
  {
    "id": "3503012012",
    "district_id": "350301",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3503012013",
    "district_id": "350301",
    "label": "Ngrencak",
    "value": "Ngrencak"
  },
  {
    "id": "3503012014",
    "district_id": "350301",
    "label": "Barang",
    "value": "Barang"
  },
  {
    "id": "3503012015",
    "district_id": "350301",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3503012016",
    "district_id": "350301",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3503012017",
    "district_id": "350301",
    "label": "Ngrambingan",
    "value": "Ngrambingan"
  },
  {
    "id": "3503022001",
    "district_id": "350302",
    "label": "Ngulungkulon",
    "value": "Ngulungkulon"
  },
  {
    "id": "3503022002",
    "district_id": "350302",
    "label": "Sobo",
    "value": "Sobo"
  },
  {
    "id": "3503022003",
    "district_id": "350302",
    "label": "Ngulungwetan",
    "value": "Ngulungwetan"
  },
  {
    "id": "3503022004",
    "district_id": "350302",
    "label": "Craken",
    "value": "Craken"
  },
  {
    "id": "3503022005",
    "district_id": "350302",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3503022006",
    "district_id": "350302",
    "label": "Tawing",
    "value": "Tawing"
  },
  {
    "id": "3503022007",
    "district_id": "350302",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3503022008",
    "district_id": "350302",
    "label": "Munjungan",
    "value": "Munjungan"
  },
  {
    "id": "3503022009",
    "district_id": "350302",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3503022010",
    "district_id": "350302",
    "label": "Bendoroto",
    "value": "Bendoroto"
  },
  {
    "id": "3503022011",
    "district_id": "350302",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "3503032001",
    "district_id": "350303",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3503032002",
    "district_id": "350303",
    "label": "Puyung",
    "value": "Puyung"
  },
  {
    "id": "3503032003",
    "district_id": "350303",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3503032004",
    "district_id": "350303",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3503032005",
    "district_id": "350303",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3503032006",
    "district_id": "350303",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3503032007",
    "district_id": "350303",
    "label": "Tanggaran",
    "value": "Tanggaran"
  },
  {
    "id": "3503032008",
    "district_id": "350303",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3503032009",
    "district_id": "350303",
    "label": "Jombok",
    "value": "Jombok"
  },
  {
    "id": "3503032010",
    "district_id": "350303",
    "label": "Sukokidul",
    "value": "Sukokidul"
  },
  {
    "id": "3503042001",
    "district_id": "350304",
    "label": "Cakul",
    "value": "Cakul"
  },
  {
    "id": "3503042002",
    "district_id": "350304",
    "label": "Siki",
    "value": "Siki"
  },
  {
    "id": "3503042003",
    "district_id": "350304",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3503042004",
    "district_id": "350304",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3503042005",
    "district_id": "350304",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3503042006",
    "district_id": "350304",
    "label": "Salamwates",
    "value": "Salamwates"
  },
  {
    "id": "3503042007",
    "district_id": "350304",
    "label": "Dongko",
    "value": "Dongko"
  },
  {
    "id": "3503042008",
    "district_id": "350304",
    "label": "Sumberbening",
    "value": "Sumberbening"
  },
  {
    "id": "3503042009",
    "district_id": "350304",
    "label": "Ngerdani",
    "value": "Ngerdani"
  },
  {
    "id": "3503042010",
    "district_id": "350304",
    "label": "Pringapus",
    "value": "Pringapus"
  },
  {
    "id": "3503052001",
    "district_id": "350305",
    "label": "Nglinggis",
    "value": "Nglinggis"
  },
  {
    "id": "3503052002",
    "district_id": "350305",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3503052003",
    "district_id": "350305",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3503052004",
    "district_id": "350305",
    "label": "Pucanganak",
    "value": "Pucanganak"
  },
  {
    "id": "3503052005",
    "district_id": "350305",
    "label": "Ngepeh",
    "value": "Ngepeh"
  },
  {
    "id": "3503052006",
    "district_id": "350305",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3503052007",
    "district_id": "350305",
    "label": "Dermosari",
    "value": "Dermosari"
  },
  {
    "id": "3503052008",
    "district_id": "350305",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3503052009",
    "district_id": "350305",
    "label": "Tumpuk",
    "value": "Tumpuk"
  },
  {
    "id": "3503052010",
    "district_id": "350305",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3503052011",
    "district_id": "350305",
    "label": "Tegaren",
    "value": "Tegaren"
  },
  {
    "id": "3503052012",
    "district_id": "350305",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3503052013",
    "district_id": "350305",
    "label": "Nglongsor",
    "value": "Nglongsor"
  },
  {
    "id": "3503052014",
    "district_id": "350305",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3503052015",
    "district_id": "350305",
    "label": "Prambon",
    "value": "Prambon"
  },
  {
    "id": "3503062001",
    "district_id": "350306",
    "label": "Kayen",
    "value": "Kayen"
  },
  {
    "id": "3503062002",
    "district_id": "350306",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3503062003",
    "district_id": "350306",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "3503062004",
    "district_id": "350306",
    "label": "Kerjo",
    "value": "Kerjo"
  },
  {
    "id": "3503062005",
    "district_id": "350306",
    "label": "Kedungsigit",
    "value": "Kedungsigit"
  },
  {
    "id": "3503062006",
    "district_id": "350306",
    "label": "Salamrejo",
    "value": "Salamrejo"
  },
  {
    "id": "3503062007",
    "district_id": "350306",
    "label": "Sukowetan",
    "value": "Sukowetan"
  },
  {
    "id": "3503062008",
    "district_id": "350306",
    "label": "Ngentrong",
    "value": "Ngentrong"
  },
  {
    "id": "3503062009",
    "district_id": "350306",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3503062010",
    "district_id": "350306",
    "label": "Buluagung",
    "value": "Buluagung"
  },
  {
    "id": "3503062011",
    "district_id": "350306",
    "label": "Sumberingin",
    "value": "Sumberingin"
  },
  {
    "id": "3503062012",
    "district_id": "350306",
    "label": "Jatiprahu",
    "value": "Jatiprahu"
  },
  {
    "id": "3503072001",
    "district_id": "350307",
    "label": "Ngadimulyo",
    "value": "Ngadimulyo"
  },
  {
    "id": "3503072002",
    "district_id": "350307",
    "label": "Bogoran",
    "value": "Bogoran"
  },
  {
    "id": "3503072003",
    "district_id": "350307",
    "label": "Timahan",
    "value": "Timahan"
  },
  {
    "id": "3503072004",
    "district_id": "350307",
    "label": "Bendoagung",
    "value": "Bendoagung"
  },
  {
    "id": "3503072005",
    "district_id": "350307",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3503072006",
    "district_id": "350307",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3503072007",
    "district_id": "350307",
    "label": "Senden",
    "value": "Senden"
  },
  {
    "id": "3503082001",
    "district_id": "350308",
    "label": "Karanggandu",
    "value": "Karanggandu"
  },
  {
    "id": "3503082002",
    "district_id": "350308",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3503082003",
    "district_id": "350308",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3503082004",
    "district_id": "350308",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3503082005",
    "district_id": "350308",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3503082006",
    "district_id": "350308",
    "label": "Ngembel",
    "value": "Ngembel"
  },
  {
    "id": "3503082007",
    "district_id": "350308",
    "label": "Slawe",
    "value": "Slawe"
  },
  {
    "id": "3503082008",
    "district_id": "350308",
    "label": "Gemaharjo",
    "value": "Gemaharjo"
  },
  {
    "id": "3503082009",
    "district_id": "350308",
    "label": "Watulimo",
    "value": "Watulimo"
  },
  {
    "id": "3503082010",
    "district_id": "350308",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3503082011",
    "district_id": "350308",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3503082012",
    "district_id": "350308",
    "label": "Tasikmadu",
    "value": "Tasikmadu"
  },
  {
    "id": "3503092001",
    "district_id": "350309",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3503092002",
    "district_id": "350309",
    "label": "Sengon",
    "value": "Sengon"
  },
  {
    "id": "3503092003",
    "district_id": "350309",
    "label": "Surenlor",
    "value": "Surenlor"
  },
  {
    "id": "3503092004",
    "district_id": "350309",
    "label": "Sumurup",
    "value": "Sumurup"
  },
  {
    "id": "3503092005",
    "district_id": "350309",
    "label": "Srabah",
    "value": "Srabah"
  },
  {
    "id": "3503092006",
    "district_id": "350309",
    "label": "Dompyong",
    "value": "Dompyong"
  },
  {
    "id": "3503092007",
    "district_id": "350309",
    "label": "Depok",
    "value": "Depok"
  },
  {
    "id": "3503092008",
    "district_id": "350309",
    "label": "Botoputih",
    "value": "Botoputih"
  },
  {
    "id": "3503102001",
    "district_id": "350310",
    "label": "Ngrayung",
    "value": "Ngrayung"
  },
  {
    "id": "3503102002",
    "district_id": "350310",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3503102003",
    "district_id": "350310",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3503102004",
    "district_id": "350310",
    "label": "Wonoanti",
    "value": "Wonoanti"
  },
  {
    "id": "3503102005",
    "district_id": "350310",
    "label": "Jajar",
    "value": "Jajar"
  },
  {
    "id": "3503102006",
    "district_id": "350310",
    "label": "Gandusari",
    "value": "Gandusari"
  },
  {
    "id": "3503102007",
    "district_id": "350310",
    "label": "Widoro",
    "value": "Widoro"
  },
  {
    "id": "3503102008",
    "district_id": "350310",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3503102009",
    "district_id": "350310",
    "label": "Melis",
    "value": "Melis"
  },
  {
    "id": "3503102010",
    "district_id": "350310",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3503102011",
    "district_id": "350310",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3503111002",
    "district_id": "350311",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "3503111003",
    "district_id": "350311",
    "label": "Ngantru",
    "value": "Ngantru"
  },
  {
    "id": "3503111005",
    "district_id": "350311",
    "label": "Kelutan",
    "value": "Kelutan"
  },
  {
    "id": "3503111006",
    "district_id": "350311",
    "label": "Surodakan",
    "value": "Surodakan"
  },
  {
    "id": "3503111007",
    "district_id": "350311",
    "label": "Sumbergedong",
    "value": "Sumbergedong"
  },
  {
    "id": "3503112001",
    "district_id": "350311",
    "label": "Ngares",
    "value": "Ngares"
  },
  {
    "id": "3503112004",
    "district_id": "350311",
    "label": "Karangsoko",
    "value": "Karangsoko"
  },
  {
    "id": "3503112008",
    "district_id": "350311",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3503112009",
    "district_id": "350311",
    "label": "Sumberdadi",
    "value": "Sumberdadi"
  },
  {
    "id": "3503112010",
    "district_id": "350311",
    "label": "Rejowinangun",
    "value": "Rejowinangun"
  },
  {
    "id": "3503112011",
    "district_id": "350311",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3503112012",
    "district_id": "350311",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3503112013",
    "district_id": "350311",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3503122001",
    "district_id": "350312",
    "label": "Wonocoyo",
    "value": "Wonocoyo"
  },
  {
    "id": "3503122002",
    "district_id": "350312",
    "label": "Ngetal",
    "value": "Ngetal"
  },
  {
    "id": "3503122003",
    "district_id": "350312",
    "label": "Ngadirenggo",
    "value": "Ngadirenggo"
  },
  {
    "id": "3503122004",
    "district_id": "350312",
    "label": "Pogalan",
    "value": "Pogalan"
  },
  {
    "id": "3503122005",
    "district_id": "350312",
    "label": "Ngulankulon",
    "value": "Ngulankulon"
  },
  {
    "id": "3503122006",
    "district_id": "350312",
    "label": "Bendorejo",
    "value": "Bendorejo"
  },
  {
    "id": "3503122007",
    "district_id": "350312",
    "label": "Gembleb",
    "value": "Gembleb"
  },
  {
    "id": "3503122008",
    "district_id": "350312",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3503122009",
    "district_id": "350312",
    "label": "Ngulanwetan",
    "value": "Ngulanwetan"
  },
  {
    "id": "3503122010",
    "district_id": "350312",
    "label": "Kedunglurah",
    "value": "Kedunglurah"
  },
  {
    "id": "3503132001",
    "district_id": "350313",
    "label": "Ngadisuko",
    "value": "Ngadisuko"
  },
  {
    "id": "3503132002",
    "district_id": "350313",
    "label": "Gador",
    "value": "Gador"
  },
  {
    "id": "3503132003",
    "district_id": "350313",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3503132004",
    "district_id": "350313",
    "label": "Semarum",
    "value": "Semarum"
  },
  {
    "id": "3503132005",
    "district_id": "350313",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3503132006",
    "district_id": "350313",
    "label": "Durenan",
    "value": "Durenan"
  },
  {
    "id": "3503132007",
    "district_id": "350313",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3503132008",
    "district_id": "350313",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3503132009",
    "district_id": "350313",
    "label": "Panggungsari",
    "value": "Panggungsari"
  },
  {
    "id": "3503132010",
    "district_id": "350313",
    "label": "Sumbergayam",
    "value": "Sumbergayam"
  },
  {
    "id": "3503132011",
    "district_id": "350313",
    "label": "Kamulan",
    "value": "Kamulan"
  },
  {
    "id": "3503132012",
    "district_id": "350313",
    "label": "Malasan",
    "value": "Malasan"
  },
  {
    "id": "3503132013",
    "district_id": "350313",
    "label": "Baruharjo",
    "value": "Baruharjo"
  },
  {
    "id": "3503132014",
    "district_id": "350313",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3503142001",
    "district_id": "350314",
    "label": "Puru",
    "value": "Puru"
  },
  {
    "id": "3503142002",
    "district_id": "350314",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3503142003",
    "district_id": "350314",
    "label": "Nglebo",
    "value": "Nglebo"
  },
  {
    "id": "3503142004",
    "district_id": "350314",
    "label": "Ngrandu",
    "value": "Ngrandu"
  },
  {
    "id": "3503142005",
    "district_id": "350314",
    "label": "Gamping",
    "value": "Gamping"
  },
  {
    "id": "3503142006",
    "district_id": "350314",
    "label": "Suruh",
    "value": "Suruh"
  },
  {
    "id": "3503142007",
    "district_id": "350314",
    "label": "Mlinjon",
    "value": "Mlinjon"
  },
  {
    "id": "3504011001",
    "district_id": "350401",
    "label": "Kedungsoko",
    "value": "Kedungsoko"
  },
  {
    "id": "3504011002",
    "district_id": "350401",
    "label": "Tertek",
    "value": "Tertek"
  },
  {
    "id": "3504011003",
    "district_id": "350401",
    "label": "Karangwaru",
    "value": "Karangwaru"
  },
  {
    "id": "3504011004",
    "district_id": "350401",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "3504011005",
    "district_id": "350401",
    "label": "Jepun",
    "value": "Jepun"
  },
  {
    "id": "3504011006",
    "district_id": "350401",
    "label": "Bago",
    "value": "Bago"
  },
  {
    "id": "3504011007",
    "district_id": "350401",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3504011008",
    "district_id": "350401",
    "label": "Kenayan",
    "value": "Kenayan"
  },
  {
    "id": "3504011009",
    "district_id": "350401",
    "label": "Kampungdalem",
    "value": "Kampungdalem"
  },
  {
    "id": "3504011010",
    "district_id": "350401",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3504011011",
    "district_id": "350401",
    "label": "Kutoanyar",
    "value": "Kutoanyar"
  },
  {
    "id": "3504011012",
    "district_id": "350401",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3504011013",
    "district_id": "350401",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "3504011014",
    "district_id": "350401",
    "label": "Botoran",
    "value": "Botoran"
  },
  {
    "id": "3504022001",
    "district_id": "350402",
    "label": "Wajak Kidul",
    "value": "Wajak Kidul"
  },
  {
    "id": "3504022002",
    "district_id": "350402",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3504022003",
    "district_id": "350402",
    "label": "Pucung Kidul",
    "value": "Pucung Kidul"
  },
  {
    "id": "3504022004",
    "district_id": "350402",
    "label": "Boyolangu",
    "value": "Boyolangu"
  },
  {
    "id": "3504022005",
    "district_id": "350402",
    "label": "Ngranti",
    "value": "Ngranti"
  },
  {
    "id": "3504022006",
    "district_id": "350402",
    "label": "Kendalbulur",
    "value": "Kendalbulur"
  },
  {
    "id": "3504022007",
    "district_id": "350402",
    "label": "Bono",
    "value": "Bono"
  },
  {
    "id": "3504022008",
    "district_id": "350402",
    "label": "Waung",
    "value": "Waung"
  },
  {
    "id": "3504022009",
    "district_id": "350402",
    "label": "Moyoketen",
    "value": "Moyoketen"
  },
  {
    "id": "3504022010",
    "district_id": "350402",
    "label": "Wajak Lor",
    "value": "Wajak Lor"
  },
  {
    "id": "3504022011",
    "district_id": "350402",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3504022012",
    "district_id": "350402",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3504022013",
    "district_id": "350402",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3504022014",
    "district_id": "350402",
    "label": "Serut",
    "value": "Serut"
  },
  {
    "id": "3504022015",
    "district_id": "350402",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3504022016",
    "district_id": "350402",
    "label": "Sobontoro",
    "value": "Sobontoro"
  },
  {
    "id": "3504022017",
    "district_id": "350402",
    "label": "Gedangsewu",
    "value": "Gedangsewu"
  },
  {
    "id": "3504032001",
    "district_id": "350403",
    "label": "Plosokandang",
    "value": "Plosokandang"
  },
  {
    "id": "3504032002",
    "district_id": "350403",
    "label": "Tunggulsari",
    "value": "Tunggulsari"
  },
  {
    "id": "3504032003",
    "district_id": "350403",
    "label": "Ringinpitu",
    "value": "Ringinpitu"
  },
  {
    "id": "3504032004",
    "district_id": "350403",
    "label": "Loderesan",
    "value": "Loderesan"
  },
  {
    "id": "3504032005",
    "district_id": "350403",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3504032006",
    "district_id": "350403",
    "label": "Bangoan",
    "value": "Bangoan"
  },
  {
    "id": "3504032007",
    "district_id": "350403",
    "label": "Boro",
    "value": "Boro"
  },
  {
    "id": "3504032008",
    "district_id": "350403",
    "label": "Tapan",
    "value": "Tapan"
  },
  {
    "id": "3504032009",
    "district_id": "350403",
    "label": "Rejoagung",
    "value": "Rejoagung"
  },
  {
    "id": "3504032010",
    "district_id": "350403",
    "label": "Kedungwaru",
    "value": "Kedungwaru"
  },
  {
    "id": "3504032011",
    "district_id": "350403",
    "label": "Plandaan",
    "value": "Plandaan"
  },
  {
    "id": "3504032012",
    "district_id": "350403",
    "label": "Ketanon",
    "value": "Ketanon"
  },
  {
    "id": "3504032013",
    "district_id": "350403",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3504032014",
    "district_id": "350403",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3504032015",
    "district_id": "350403",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3504032016",
    "district_id": "350403",
    "label": "Majan",
    "value": "Majan"
  },
  {
    "id": "3504032017",
    "district_id": "350403",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3504032018",
    "district_id": "350403",
    "label": "Gendingan",
    "value": "Gendingan"
  },
  {
    "id": "3504032019",
    "district_id": "350403",
    "label": "Ngujang",
    "value": "Ngujang"
  },
  {
    "id": "3504042001",
    "district_id": "350404",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3504042002",
    "district_id": "350404",
    "label": "Pucung Lor",
    "value": "Pucung Lor"
  },
  {
    "id": "3504042003",
    "district_id": "350404",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "3504042004",
    "district_id": "350404",
    "label": "Padangan",
    "value": "Padangan"
  },
  {
    "id": "3504042005",
    "district_id": "350404",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3504042006",
    "district_id": "350404",
    "label": "Pulerejo",
    "value": "Pulerejo"
  },
  {
    "id": "3504042007",
    "district_id": "350404",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3504042008",
    "district_id": "350404",
    "label": "Ngantru",
    "value": "Ngantru"
  },
  {
    "id": "3504042009",
    "district_id": "350404",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3504042010",
    "district_id": "350404",
    "label": "Batokan",
    "value": "Batokan"
  },
  {
    "id": "3504042011",
    "district_id": "350404",
    "label": "Kepuhrejo",
    "value": "Kepuhrejo"
  },
  {
    "id": "3504042012",
    "district_id": "350404",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3504042013",
    "district_id": "350404",
    "label": "Pinggirsari",
    "value": "Pinggirsari"
  },
  {
    "id": "3504052001",
    "district_id": "350405",
    "label": "Bolorejo",
    "value": "Bolorejo"
  },
  {
    "id": "3504052002",
    "district_id": "350405",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3504052003",
    "district_id": "350405",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3504052004",
    "district_id": "350405",
    "label": "Batangsaren",
    "value": "Batangsaren"
  },
  {
    "id": "3504052005",
    "district_id": "350405",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "3504052006",
    "district_id": "350405",
    "label": "Kalangbret",
    "value": "Kalangbret"
  },
  {
    "id": "3504052007",
    "district_id": "350405",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3504052008",
    "district_id": "350405",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3504052009",
    "district_id": "350405",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3504052010",
    "district_id": "350405",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3504052011",
    "district_id": "350405",
    "label": "Kates",
    "value": "Kates"
  },
  {
    "id": "3504052012",
    "district_id": "350405",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3504052013",
    "district_id": "350405",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3504062001",
    "district_id": "350406",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3504062002",
    "district_id": "350406",
    "label": "Kedungcangkring",
    "value": "Kedungcangkring"
  },
  {
    "id": "3504062003",
    "district_id": "350406",
    "label": "Mulyosari",
    "value": "Mulyosari"
  },
  {
    "id": "3504062004",
    "district_id": "350406",
    "label": "Segawe",
    "value": "Segawe"
  },
  {
    "id": "3504062005",
    "district_id": "350406",
    "label": "Samar",
    "value": "Samar"
  },
  {
    "id": "3504062006",
    "district_id": "350406",
    "label": "Penjor",
    "value": "Penjor"
  },
  {
    "id": "3504062007",
    "district_id": "350406",
    "label": "Pagerwojo",
    "value": "Pagerwojo"
  },
  {
    "id": "3504062008",
    "district_id": "350406",
    "label": "Kradinan",
    "value": "Kradinan"
  },
  {
    "id": "3504062009",
    "district_id": "350406",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3504062010",
    "district_id": "350406",
    "label": "Gondanggunung",
    "value": "Gondanggunung"
  },
  {
    "id": "3504062011",
    "district_id": "350406",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  {
    "id": "3504072001",
    "district_id": "350407",
    "label": "Kedoyo",
    "value": "Kedoyo"
  },
  {
    "id": "3504072002",
    "district_id": "350407",
    "label": "Nglutung",
    "value": "Nglutung"
  },
  {
    "id": "3504072003",
    "district_id": "350407",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "3504072004",
    "district_id": "350407",
    "label": "Krosok",
    "value": "Krosok"
  },
  {
    "id": "3504072005",
    "district_id": "350407",
    "label": "Dono",
    "value": "Dono"
  },
  {
    "id": "3504072006",
    "district_id": "350407",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3504072007",
    "district_id": "350407",
    "label": "Picisan",
    "value": "Picisan"
  },
  {
    "id": "3504072008",
    "district_id": "350407",
    "label": "Nyawangan",
    "value": "Nyawangan"
  },
  {
    "id": "3504072009",
    "district_id": "350407",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3504072010",
    "district_id": "350407",
    "label": "Nglurup",
    "value": "Nglurup"
  },
  {
    "id": "3504072011",
    "district_id": "350407",
    "label": "Geger",
    "value": "Geger"
  },
  {
    "id": "3504082001",
    "district_id": "350408",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "3504082002",
    "district_id": "350408",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3504082003",
    "district_id": "350408",
    "label": "Sukowiyono",
    "value": "Sukowiyono"
  },
  {
    "id": "3504082004",
    "district_id": "350408",
    "label": "Sembon",
    "value": "Sembon"
  },
  {
    "id": "3504082005",
    "district_id": "350408",
    "label": "Sukowidodo",
    "value": "Sukowidodo"
  },
  {
    "id": "3504082006",
    "district_id": "350408",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3504082007",
    "district_id": "350408",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3504082008",
    "district_id": "350408",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3504082009",
    "district_id": "350408",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3504082010",
    "district_id": "350408",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3504082011",
    "district_id": "350408",
    "label": "Punjul",
    "value": "Punjul"
  },
  {
    "id": "3504082012",
    "district_id": "350408",
    "label": "Jeli",
    "value": "Jeli"
  },
  {
    "id": "3504082013",
    "district_id": "350408",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3504092001",
    "district_id": "350409",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "3504092002",
    "district_id": "350409",
    "label": "Tawing",
    "value": "Tawing"
  },
  {
    "id": "3504092003",
    "district_id": "350409",
    "label": "Gondosuli",
    "value": "Gondosuli"
  },
  {
    "id": "3504092004",
    "district_id": "350409",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3504092005",
    "district_id": "350409",
    "label": "Sepatan",
    "value": "Sepatan"
  },
  {
    "id": "3504092006",
    "district_id": "350409",
    "label": "Macanbang",
    "value": "Macanbang"
  },
  {
    "id": "3504092007",
    "district_id": "350409",
    "label": "Kiping",
    "value": "Kiping"
  },
  {
    "id": "3504092008",
    "district_id": "350409",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3504092009",
    "district_id": "350409",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3504092010",
    "district_id": "350409",
    "label": "Ngrendeng",
    "value": "Ngrendeng"
  },
  {
    "id": "3504092011",
    "district_id": "350409",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3504092012",
    "district_id": "350409",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3504092013",
    "district_id": "350409",
    "label": "Notorejo",
    "value": "Notorejo"
  },
  {
    "id": "3504092014",
    "district_id": "350409",
    "label": "Sidem",
    "value": "Sidem"
  },
  {
    "id": "3504092015",
    "district_id": "350409",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3504092016",
    "district_id": "350409",
    "label": "Blendis",
    "value": "Blendis"
  },
  {
    "id": "3504092017",
    "district_id": "350409",
    "label": "Mojoarum",
    "value": "Mojoarum"
  },
  {
    "id": "3504092018",
    "district_id": "350409",
    "label": "Tiudan",
    "value": "Tiudan"
  },
  {
    "id": "3504092019",
    "district_id": "350409",
    "label": "Jarakan",
    "value": "Jarakan"
  },
  {
    "id": "3504092020",
    "district_id": "350409",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "3504102001",
    "district_id": "350410",
    "label": "Sambidoplang",
    "value": "Sambidoplang"
  },
  {
    "id": "3504102002",
    "district_id": "350410",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3504102003",
    "district_id": "350410",
    "label": "Mirigambar",
    "value": "Mirigambar"
  },
  {
    "id": "3504102004",
    "district_id": "350410",
    "label": "Trenceng",
    "value": "Trenceng"
  },
  {
    "id": "3504102005",
    "district_id": "350410",
    "label": "Bendilwungu",
    "value": "Bendilwungu"
  },
  {
    "id": "3504102006",
    "district_id": "350410",
    "label": "Sambijajar",
    "value": "Sambijajar"
  },
  {
    "id": "3504102007",
    "district_id": "350410",
    "label": "Podorejo",
    "value": "Podorejo"
  },
  {
    "id": "3504102008",
    "district_id": "350410",
    "label": "Doroampel",
    "value": "Doroampel"
  },
  {
    "id": "3504102009",
    "district_id": "350410",
    "label": "Junjung",
    "value": "Junjung"
  },
  {
    "id": "3504102010",
    "district_id": "350410",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3504102011",
    "district_id": "350410",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3504102012",
    "district_id": "350410",
    "label": "Bendiljati Kulon",
    "value": "Bendiljati Kulon"
  },
  {
    "id": "3504102013",
    "district_id": "350410",
    "label": "Bendiljati Wetan",
    "value": "Bendiljati Wetan"
  },
  {
    "id": "3504102014",
    "district_id": "350410",
    "label": "Sumberdadi",
    "value": "Sumberdadi"
  },
  {
    "id": "3504102015",
    "district_id": "350410",
    "label": "Jabalsari",
    "value": "Jabalsari"
  },
  {
    "id": "3504102016",
    "district_id": "350410",
    "label": "Sambirobyong",
    "value": "Sambirobyong"
  },
  {
    "id": "3504102017",
    "district_id": "350410",
    "label": "Bukur",
    "value": "Bukur"
  },
  {
    "id": "3504112001",
    "district_id": "350411",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3504112002",
    "district_id": "350411",
    "label": "Samir",
    "value": "Samir"
  },
  {
    "id": "3504112003",
    "district_id": "350411",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3504112004",
    "district_id": "350411",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3504112005",
    "district_id": "350411",
    "label": "Balesono",
    "value": "Balesono"
  },
  {
    "id": "3504112006",
    "district_id": "350411",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3504112007",
    "district_id": "350411",
    "label": "Sumberingin Kulon",
    "value": "Sumberingin Kulon"
  },
  {
    "id": "3504112008",
    "district_id": "350411",
    "label": "Sumberingin Kidul",
    "value": "Sumberingin Kidul"
  },
  {
    "id": "3504112009",
    "district_id": "350411",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3504112010",
    "district_id": "350411",
    "label": "Sumberejo Wetan",
    "value": "Sumberejo Wetan"
  },
  {
    "id": "3504112011",
    "district_id": "350411",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3504112012",
    "district_id": "350411",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "3504112013",
    "district_id": "350411",
    "label": "Gilang",
    "value": "Gilang"
  },
  {
    "id": "3504112014",
    "district_id": "350411",
    "label": "Sumberejo Kulon",
    "value": "Sumberejo Kulon"
  },
  {
    "id": "3504112015",
    "district_id": "350411",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3504112016",
    "district_id": "350411",
    "label": "Kromasan",
    "value": "Kromasan"
  },
  {
    "id": "3504112017",
    "district_id": "350411",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3504112018",
    "district_id": "350411",
    "label": "Pulotondo",
    "value": "Pulotondo"
  },
  {
    "id": "3504122001",
    "district_id": "350412",
    "label": "Pucanglaban",
    "value": "Pucanglaban"
  },
  {
    "id": "3504122002",
    "district_id": "350412",
    "label": "Kalidawe",
    "value": "Kalidawe"
  },
  {
    "id": "3504122003",
    "district_id": "350412",
    "label": "Panggungkalak",
    "value": "Panggungkalak"
  },
  {
    "id": "3504122004",
    "district_id": "350412",
    "label": "Kaligentong",
    "value": "Kaligentong"
  },
  {
    "id": "3504122005",
    "district_id": "350412",
    "label": "Sumberbendo",
    "value": "Sumberbendo"
  },
  {
    "id": "3504122006",
    "district_id": "350412",
    "label": "Manding",
    "value": "Manding"
  },
  {
    "id": "3504122007",
    "district_id": "350412",
    "label": "Panggunguni",
    "value": "Panggunguni"
  },
  {
    "id": "3504122008",
    "district_id": "350412",
    "label": "Sumberdadap",
    "value": "Sumberdadap"
  },
  {
    "id": "3504122009",
    "district_id": "350412",
    "label": "Demuk",
    "value": "Demuk"
  },
  {
    "id": "3504132001",
    "district_id": "350413",
    "label": "Tenggur",
    "value": "Tenggur"
  },
  {
    "id": "3504132002",
    "district_id": "350413",
    "label": "Panjerejo",
    "value": "Panjerejo"
  },
  {
    "id": "3504132003",
    "district_id": "350413",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3504132004",
    "district_id": "350413",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3504132005",
    "district_id": "350413",
    "label": "Sukorejo Wetan",
    "value": "Sukorejo Wetan"
  },
  {
    "id": "3504132006",
    "district_id": "350413",
    "label": "Jatidowo",
    "value": "Jatidowo"
  },
  {
    "id": "3504132007",
    "district_id": "350413",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3504132008",
    "district_id": "350413",
    "label": "Tanen",
    "value": "Tanen"
  },
  {
    "id": "3504132009",
    "district_id": "350413",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3504132010",
    "district_id": "350413",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3504132011",
    "district_id": "350413",
    "label": "Rejotangan",
    "value": "Rejotangan"
  },
  {
    "id": "3504132012",
    "district_id": "350413",
    "label": "Pakisrejo",
    "value": "Pakisrejo"
  },
  {
    "id": "3504132013",
    "district_id": "350413",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3504132014",
    "district_id": "350413",
    "label": "Ariyojeding",
    "value": "Ariyojeding"
  },
  {
    "id": "3504132015",
    "district_id": "350413",
    "label": "Tenggong",
    "value": "Tenggong"
  },
  {
    "id": "3504132016",
    "district_id": "350413",
    "label": "Buntaran",
    "value": "Buntaran"
  },
  {
    "id": "3504142001",
    "district_id": "350414",
    "label": "Kalibatur",
    "value": "Kalibatur"
  },
  {
    "id": "3504142002",
    "district_id": "350414",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3504142003",
    "district_id": "350414",
    "label": "Sukorejo Kulon",
    "value": "Sukorejo Kulon"
  },
  {
    "id": "3504142004",
    "district_id": "350414",
    "label": "Kalidawir",
    "value": "Kalidawir"
  },
  {
    "id": "3504142005",
    "district_id": "350414",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3504142006",
    "district_id": "350414",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3504142007",
    "district_id": "350414",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3504142008",
    "district_id": "350414",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3504142009",
    "district_id": "350414",
    "label": "Pakisaji",
    "value": "Pakisaji"
  },
  {
    "id": "3504142010",
    "district_id": "350414",
    "label": "Jabon",
    "value": "Jabon"
  },
  {
    "id": "3504142011",
    "district_id": "350414",
    "label": "Tunggangri",
    "value": "Tunggangri"
  },
  {
    "id": "3504142012",
    "district_id": "350414",
    "label": "Salakkembang",
    "value": "Salakkembang"
  },
  {
    "id": "3504142013",
    "district_id": "350414",
    "label": "Ngubalan",
    "value": "Ngubalan"
  },
  {
    "id": "3504142014",
    "district_id": "350414",
    "label": "Domasan",
    "value": "Domasan"
  },
  {
    "id": "3504142015",
    "district_id": "350414",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3504142016",
    "district_id": "350414",
    "label": "Betak",
    "value": "Betak"
  },
  {
    "id": "3504142017",
    "district_id": "350414",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3504152001",
    "district_id": "350415",
    "label": "Besole",
    "value": "Besole"
  },
  {
    "id": "3504152002",
    "district_id": "350415",
    "label": "Tanggulwelahan",
    "value": "Tanggulwelahan"
  },
  {
    "id": "3504152003",
    "district_id": "350415",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3504152004",
    "district_id": "350415",
    "label": "Keboireng",
    "value": "Keboireng"
  },
  {
    "id": "3504152005",
    "district_id": "350415",
    "label": "Tanggulturus",
    "value": "Tanggulturus"
  },
  {
    "id": "3504152006",
    "district_id": "350415",
    "label": "Sedayugunung",
    "value": "Sedayugunung"
  },
  {
    "id": "3504152007",
    "district_id": "350415",
    "label": "Tanggulkundung",
    "value": "Tanggulkundung"
  },
  {
    "id": "3504152008",
    "district_id": "350415",
    "label": "Wateskroyo",
    "value": "Wateskroyo"
  },
  {
    "id": "3504152009",
    "district_id": "350415",
    "label": "Sitoyobagus",
    "value": "Sitoyobagus"
  },
  {
    "id": "3504152010",
    "district_id": "350415",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3504162001",
    "district_id": "350416",
    "label": "Ngentrong",
    "value": "Ngentrong"
  },
  {
    "id": "3504162002",
    "district_id": "350416",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3504162003",
    "district_id": "350416",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "3504162004",
    "district_id": "350416",
    "label": "Gamping",
    "value": "Gamping"
  },
  {
    "id": "3504162005",
    "district_id": "350416",
    "label": "Campurdarat",
    "value": "Campurdarat"
  },
  {
    "id": "3504162006",
    "district_id": "350416",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3504162007",
    "district_id": "350416",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3504162008",
    "district_id": "350416",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3504162009",
    "district_id": "350416",
    "label": "Tanggung",
    "value": "Tanggung"
  },
  {
    "id": "3504172001",
    "district_id": "350417",
    "label": "Nglampir",
    "value": "Nglampir"
  },
  {
    "id": "3504172002",
    "district_id": "350417",
    "label": "Talun Kulon",
    "value": "Talun Kulon"
  },
  {
    "id": "3504172003",
    "district_id": "350417",
    "label": "Bantengan",
    "value": "Bantengan"
  },
  {
    "id": "3504172004",
    "district_id": "350417",
    "label": "Kedungwilut",
    "value": "Kedungwilut"
  },
  {
    "id": "3504172005",
    "district_id": "350417",
    "label": "Suwaru",
    "value": "Suwaru"
  },
  {
    "id": "3504172006",
    "district_id": "350417",
    "label": "Ngunggahan",
    "value": "Ngunggahan"
  },
  {
    "id": "3504172007",
    "district_id": "350417",
    "label": "Suruhan Kidul",
    "value": "Suruhan Kidul"
  },
  {
    "id": "3504172008",
    "district_id": "350417",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3504172009",
    "district_id": "350417",
    "label": "Mergayu",
    "value": "Mergayu"
  },
  {
    "id": "3504172010",
    "district_id": "350417",
    "label": "Sebalor",
    "value": "Sebalor"
  },
  {
    "id": "3504172011",
    "district_id": "350417",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3504172012",
    "district_id": "350417",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3504172013",
    "district_id": "350417",
    "label": "Singgit",
    "value": "Singgit"
  },
  {
    "id": "3504172014",
    "district_id": "350417",
    "label": "Ngepeh",
    "value": "Ngepeh"
  },
  {
    "id": "3504172015",
    "district_id": "350417",
    "label": "Suruhan Lor",
    "value": "Suruhan Lor"
  },
  {
    "id": "3504172016",
    "district_id": "350417",
    "label": "Bulus",
    "value": "Bulus"
  },
  {
    "id": "3504172017",
    "district_id": "350417",
    "label": "Kesambi",
    "value": "Kesambi"
  },
  {
    "id": "3504172018",
    "district_id": "350417",
    "label": "Gandong",
    "value": "Gandong"
  },
  {
    "id": "3504182001",
    "district_id": "350418",
    "label": "Sambitan",
    "value": "Sambitan"
  },
  {
    "id": "3504182002",
    "district_id": "350418",
    "label": "Bono",
    "value": "Bono"
  },
  {
    "id": "3504182003",
    "district_id": "350418",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3504182004",
    "district_id": "350418",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3504182005",
    "district_id": "350418",
    "label": "Tamban",
    "value": "Tamban"
  },
  {
    "id": "3504182006",
    "district_id": "350418",
    "label": "Ngebong",
    "value": "Ngebong"
  },
  {
    "id": "3504182007",
    "district_id": "350418",
    "label": "Sodo",
    "value": "Sodo"
  },
  {
    "id": "3504182008",
    "district_id": "350418",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "3504182009",
    "district_id": "350418",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3504182010",
    "district_id": "350418",
    "label": "Suwaluh",
    "value": "Suwaluh"
  },
  {
    "id": "3504182011",
    "district_id": "350418",
    "label": "Pecuk",
    "value": "Pecuk"
  },
  {
    "id": "3504182012",
    "district_id": "350418",
    "label": "Bangunmulyo",
    "value": "Bangunmulyo"
  },
  {
    "id": "3504182013",
    "district_id": "350418",
    "label": "Kasreman",
    "value": "Kasreman"
  },
  {
    "id": "3504182014",
    "district_id": "350418",
    "label": "Sanan",
    "value": "Sanan"
  },
  {
    "id": "3504182015",
    "district_id": "350418",
    "label": "Bangunjaya",
    "value": "Bangunjaya"
  },
  {
    "id": "3504182016",
    "district_id": "350418",
    "label": "Ngrance",
    "value": "Ngrance"
  },
  {
    "id": "3504182017",
    "district_id": "350418",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3504182018",
    "district_id": "350418",
    "label": "Gesikan",
    "value": "Gesikan"
  },
  {
    "id": "3504182019",
    "district_id": "350418",
    "label": "Gempolan",
    "value": "Gempolan"
  },
  {
    "id": "3504192001",
    "district_id": "350419",
    "label": "Kresikan",
    "value": "Kresikan"
  },
  {
    "id": "3504192002",
    "district_id": "350419",
    "label": "Jengglungharjo",
    "value": "Jengglungharjo"
  },
  {
    "id": "3504192003",
    "district_id": "350419",
    "label": "Ngrejo",
    "value": "Ngrejo"
  },
  {
    "id": "3504192004",
    "district_id": "350419",
    "label": "Tanggunggunung",
    "value": "Tanggunggunung"
  },
  {
    "id": "3504192005",
    "district_id": "350419",
    "label": "Ngepoh",
    "value": "Ngepoh"
  },
  {
    "id": "3504192006",
    "district_id": "350419",
    "label": "Tenggarejo",
    "value": "Tenggarejo"
  },
  {
    "id": "3504192007",
    "district_id": "350419",
    "label": "Pakisrejo",
    "value": "Pakisrejo"
  },
  {
    "id": "3505012001",
    "district_id": "350501",
    "label": "Jaten",
    "value": "Jaten"
  },
  {
    "id": "3505012002",
    "district_id": "350501",
    "label": "Salam",
    "value": "Salam"
  },
  {
    "id": "3505012003",
    "district_id": "350501",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3505012004",
    "district_id": "350501",
    "label": "Kaliboto",
    "value": "Kaliboto"
  },
  {
    "id": "3505012005",
    "district_id": "350501",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3505012006",
    "district_id": "350501",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3505012007",
    "district_id": "350501",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3505012008",
    "district_id": "350501",
    "label": "Pikatan",
    "value": "Pikatan"
  },
  {
    "id": "3505012009",
    "district_id": "350501",
    "label": "Gandekan",
    "value": "Gandekan"
  },
  {
    "id": "3505012010",
    "district_id": "350501",
    "label": "Kunir",
    "value": "Kunir"
  },
  {
    "id": "3505012011",
    "district_id": "350501",
    "label": "Kolomayan",
    "value": "Kolomayan"
  },
  {
    "id": "3505022001",
    "district_id": "350502",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3505022002",
    "district_id": "350502",
    "label": "Temenggungan",
    "value": "Temenggungan"
  },
  {
    "id": "3505022003",
    "district_id": "350502",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3505022004",
    "district_id": "350502",
    "label": "Tunjung",
    "value": "Tunjung"
  },
  {
    "id": "3505022005",
    "district_id": "350502",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3505022006",
    "district_id": "350502",
    "label": "Mangunan",
    "value": "Mangunan"
  },
  {
    "id": "3505022007",
    "district_id": "350502",
    "label": "Karanggondang",
    "value": "Karanggondang"
  },
  {
    "id": "3505022008",
    "district_id": "350502",
    "label": "Ringinanom",
    "value": "Ringinanom"
  },
  {
    "id": "3505022009",
    "district_id": "350502",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3505022010",
    "district_id": "350502",
    "label": "Slemanan",
    "value": "Slemanan"
  },
  {
    "id": "3505022011",
    "district_id": "350502",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3505022012",
    "district_id": "350502",
    "label": "Bendorejo",
    "value": "Bendorejo"
  },
  {
    "id": "3505031004",
    "district_id": "350503",
    "label": "Togogan",
    "value": "Togogan"
  },
  {
    "id": "3505031007",
    "district_id": "350503",
    "label": "Srengat",
    "value": "Srengat"
  },
  {
    "id": "3505031009",
    "district_id": "350503",
    "label": "Dandong",
    "value": "Dandong"
  },
  {
    "id": "3505031010",
    "district_id": "350503",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3505032001",
    "district_id": "350503",
    "label": "Karanggayam",
    "value": "Karanggayam"
  },
  {
    "id": "3505032002",
    "district_id": "350503",
    "label": "Dermojayan",
    "value": "Dermojayan"
  },
  {
    "id": "3505032003",
    "district_id": "350503",
    "label": "Kerjen",
    "value": "Kerjen"
  },
  {
    "id": "3505032005",
    "district_id": "350503",
    "label": "Pakisrejo",
    "value": "Pakisrejo"
  },
  {
    "id": "3505032006",
    "district_id": "350503",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3505032008",
    "district_id": "350503",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3505032011",
    "district_id": "350503",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3505032012",
    "district_id": "350503",
    "label": "Bagelenan",
    "value": "Bagelenan"
  },
  {
    "id": "3505032013",
    "district_id": "350503",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3505032014",
    "district_id": "350503",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3505032015",
    "district_id": "350503",
    "label": "Selokajang",
    "value": "Selokajang"
  },
  {
    "id": "3505032016",
    "district_id": "350503",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3505041011",
    "district_id": "350504",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3505042001",
    "district_id": "350504",
    "label": "Panggungduwet",
    "value": "Panggungduwet"
  },
  {
    "id": "3505042002",
    "district_id": "350504",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3505042003",
    "district_id": "350504",
    "label": "Pakisaji",
    "value": "Pakisaji"
  },
  {
    "id": "3505042004",
    "district_id": "350504",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3505042005",
    "district_id": "350504",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3505042006",
    "district_id": "350504",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3505042007",
    "district_id": "350504",
    "label": "Suruhwadang",
    "value": "Suruhwadang"
  },
  {
    "id": "3505042008",
    "district_id": "350504",
    "label": "Plumpungrejo",
    "value": "Plumpungrejo"
  },
  {
    "id": "3505042009",
    "district_id": "350504",
    "label": "Jimbe",
    "value": "Jimbe"
  },
  {
    "id": "3505042010",
    "district_id": "350504",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3505042012",
    "district_id": "350504",
    "label": "Sumberjati",
    "value": "Sumberjati"
  },
  {
    "id": "3505042013",
    "district_id": "350504",
    "label": "Rejowinangun",
    "value": "Rejowinangun"
  },
  {
    "id": "3505042014",
    "district_id": "350504",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3505042015",
    "district_id": "350504",
    "label": "Darungan",
    "value": "Darungan"
  },
  {
    "id": "3505052001",
    "district_id": "350505",
    "label": "Pulerejo",
    "value": "Pulerejo"
  },
  {
    "id": "3505052002",
    "district_id": "350505",
    "label": "Tumpakoyot",
    "value": "Tumpakoyot"
  },
  {
    "id": "3505052003",
    "district_id": "350505",
    "label": "Plandirejo",
    "value": "Plandirejo"
  },
  {
    "id": "3505052004",
    "district_id": "350505",
    "label": "Sumberdadi",
    "value": "Sumberdadi"
  },
  {
    "id": "3505052005",
    "district_id": "350505",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3505052006",
    "district_id": "350505",
    "label": "Ngrejo",
    "value": "Ngrejo"
  },
  {
    "id": "3505052007",
    "district_id": "350505",
    "label": "Bululawang",
    "value": "Bululawang"
  },
  {
    "id": "3505052008",
    "district_id": "350505",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3505052009",
    "district_id": "350505",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3505052010",
    "district_id": "350505",
    "label": "Lorejo",
    "value": "Lorejo"
  },
  {
    "id": "3505052011",
    "district_id": "350505",
    "label": "Tumpakkepuh",
    "value": "Tumpakkepuh"
  },
  {
    "id": "3505062001",
    "district_id": "350506",
    "label": "Dadaplangu",
    "value": "Dadaplangu"
  },
  {
    "id": "3505062002",
    "district_id": "350506",
    "label": "Langon",
    "value": "Langon"
  },
  {
    "id": "3505062003",
    "district_id": "350506",
    "label": "Gembongan",
    "value": "Gembongan"
  },
  {
    "id": "3505062004",
    "district_id": "350506",
    "label": "Ringinanyar",
    "value": "Ringinanyar"
  },
  {
    "id": "3505062005",
    "district_id": "350506",
    "label": "Kebonduren",
    "value": "Kebonduren"
  },
  {
    "id": "3505062006",
    "district_id": "350506",
    "label": "Bacem",
    "value": "Bacem"
  },
  {
    "id": "3505062007",
    "district_id": "350506",
    "label": "Ponggok",
    "value": "Ponggok"
  },
  {
    "id": "3505062008",
    "district_id": "350506",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3505062009",
    "district_id": "350506",
    "label": "Kawedusan",
    "value": "Kawedusan"
  },
  {
    "id": "3505062010",
    "district_id": "350506",
    "label": "Jatilengger",
    "value": "Jatilengger"
  },
  {
    "id": "3505062011",
    "district_id": "350506",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3505062012",
    "district_id": "350506",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3505062013",
    "district_id": "350506",
    "label": "Maliran",
    "value": "Maliran"
  },
  {
    "id": "3505062014",
    "district_id": "350506",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3505062015",
    "district_id": "350506",
    "label": "Karangbendo",
    "value": "Karangbendo"
  },
  {
    "id": "3505072001",
    "district_id": "350507",
    "label": "Kalipucung",
    "value": "Kalipucung"
  },
  {
    "id": "3505072002",
    "district_id": "350507",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3505072003",
    "district_id": "350507",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3505072004",
    "district_id": "350507",
    "label": "Sanankulon",
    "value": "Sanankulon"
  },
  {
    "id": "3505072005",
    "district_id": "350507",
    "label": "Sumberingin",
    "value": "Sumberingin"
  },
  {
    "id": "3505072006",
    "district_id": "350507",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3505072007",
    "district_id": "350507",
    "label": "Bendowulung",
    "value": "Bendowulung"
  },
  {
    "id": "3505072008",
    "district_id": "350507",
    "label": "Tuliskriyo",
    "value": "Tuliskriyo"
  },
  {
    "id": "3505072009",
    "district_id": "350507",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3505072010",
    "district_id": "350507",
    "label": "Gledug",
    "value": "Gledug"
  },
  {
    "id": "3505072011",
    "district_id": "350507",
    "label": "Jeding",
    "value": "Jeding"
  },
  {
    "id": "3505072012",
    "district_id": "350507",
    "label": "Plosoarang",
    "value": "Plosoarang"
  },
  {
    "id": "3505082001",
    "district_id": "350508",
    "label": "Pasiraman",
    "value": "Pasiraman"
  },
  {
    "id": "3505082002",
    "district_id": "350508",
    "label": "Kaligrenjeng",
    "value": "Kaligrenjeng"
  },
  {
    "id": "3505082003",
    "district_id": "350508",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3505082004",
    "district_id": "350508",
    "label": "Sumberboto",
    "value": "Sumberboto"
  },
  {
    "id": "3505082005",
    "district_id": "350508",
    "label": "Gununggede",
    "value": "Gununggede"
  },
  {
    "id": "3505082006",
    "district_id": "350508",
    "label": "Wonotirto",
    "value": "Wonotirto"
  },
  {
    "id": "3505082007",
    "district_id": "350508",
    "label": "Ngeni",
    "value": "Ngeni"
  },
  {
    "id": "3505082008",
    "district_id": "350508",
    "label": "Ngadipuro",
    "value": "Ngadipuro"
  },
  {
    "id": "3505091009",
    "district_id": "350509",
    "label": "Nglegok",
    "value": "Nglegok"
  },
  {
    "id": "3505092001",
    "district_id": "350509",
    "label": "Sumberasri",
    "value": "Sumberasri"
  },
  {
    "id": "3505092002",
    "district_id": "350509",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3505092003",
    "district_id": "350509",
    "label": "Ngoran",
    "value": "Ngoran"
  },
  {
    "id": "3505092004",
    "district_id": "350509",
    "label": "Dayu",
    "value": "Dayu"
  },
  {
    "id": "3505092005",
    "district_id": "350509",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3505092006",
    "district_id": "350509",
    "label": "Kemloko",
    "value": "Kemloko"
  },
  {
    "id": "3505092007",
    "district_id": "350509",
    "label": "Penataran",
    "value": "Penataran"
  },
  {
    "id": "3505092008",
    "district_id": "350509",
    "label": "Krenceng",
    "value": "Krenceng"
  },
  {
    "id": "3505092010",
    "district_id": "350509",
    "label": "Jiwut",
    "value": "Jiwut"
  },
  {
    "id": "3505092011",
    "district_id": "350509",
    "label": "Modangan",
    "value": "Modangan"
  },
  {
    "id": "3505101008",
    "district_id": "350510",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3505101010",
    "district_id": "350510",
    "label": "Satreyan",
    "value": "Satreyan"
  },
  {
    "id": "3505102001",
    "district_id": "350510",
    "label": "Minggirsari",
    "value": "Minggirsari"
  },
  {
    "id": "3505102002",
    "district_id": "350510",
    "label": "Jatinom",
    "value": "Jatinom"
  },
  {
    "id": "3505102003",
    "district_id": "350510",
    "label": "Gogodeso",
    "value": "Gogodeso"
  },
  {
    "id": "3505102004",
    "district_id": "350510",
    "label": "Gaprang",
    "value": "Gaprang"
  },
  {
    "id": "3505102005",
    "district_id": "350510",
    "label": "Papungan",
    "value": "Papungan"
  },
  {
    "id": "3505102006",
    "district_id": "350510",
    "label": "Tlogo",
    "value": "Tlogo"
  },
  {
    "id": "3505102007",
    "district_id": "350510",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3505102009",
    "district_id": "350510",
    "label": "Banggle",
    "value": "Banggle"
  },
  {
    "id": "3505102011",
    "district_id": "350510",
    "label": "Sawentar",
    "value": "Sawentar"
  },
  {
    "id": "3505102012",
    "district_id": "350510",
    "label": "Kuningan",
    "value": "Kuningan"
  },
  {
    "id": "3505111002",
    "district_id": "350511",
    "label": "Sumberdiren",
    "value": "Sumberdiren"
  },
  {
    "id": "3505111003",
    "district_id": "350511",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3505111004",
    "district_id": "350511",
    "label": "Garum",
    "value": "Garum"
  },
  {
    "id": "3505111006",
    "district_id": "350511",
    "label": "Bence",
    "value": "Bence"
  },
  {
    "id": "3505112001",
    "district_id": "350511",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3505112005",
    "district_id": "350511",
    "label": "Tingal",
    "value": "Tingal"
  },
  {
    "id": "3505112007",
    "district_id": "350511",
    "label": "Slorok",
    "value": "Slorok"
  },
  {
    "id": "3505112008",
    "district_id": "350511",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3505112009",
    "district_id": "350511",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3505121002",
    "district_id": "350512",
    "label": "Kedungbunder",
    "value": "Kedungbunder"
  },
  {
    "id": "3505121003",
    "district_id": "350512",
    "label": "Kembangarum",
    "value": "Kembangarum"
  },
  {
    "id": "3505121004",
    "district_id": "350512",
    "label": "Kalipang",
    "value": "Kalipang"
  },
  {
    "id": "3505121005",
    "district_id": "350512",
    "label": "Sutojayan",
    "value": "Sutojayan"
  },
  {
    "id": "3505121006",
    "district_id": "350512",
    "label": "Jingglong",
    "value": "Jingglong"
  },
  {
    "id": "3505121007",
    "district_id": "350512",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3505121008",
    "district_id": "350512",
    "label": "Jegu",
    "value": "Jegu"
  },
  {
    "id": "3505122001",
    "district_id": "350512",
    "label": "Pandanarum",
    "value": "Pandanarum"
  },
  {
    "id": "3505122009",
    "district_id": "350512",
    "label": "Bacem",
    "value": "Bacem"
  },
  {
    "id": "3505122010",
    "district_id": "350512",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3505122011",
    "district_id": "350512",
    "label": "Kaulon",
    "value": "Kaulon"
  },
  {
    "id": "3505132001",
    "district_id": "350513",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3505132002",
    "district_id": "350513",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3505132003",
    "district_id": "350513",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3505132004",
    "district_id": "350513",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "3505132005",
    "district_id": "350513",
    "label": "Sumbersih",
    "value": "Sumbersih"
  },
  {
    "id": "3505132006",
    "district_id": "350513",
    "label": "Kaligambir",
    "value": "Kaligambir"
  },
  {
    "id": "3505132007",
    "district_id": "350513",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3505132008",
    "district_id": "350513",
    "label": "Panggungasri",
    "value": "Panggungasri"
  },
  {
    "id": "3505132009",
    "district_id": "350513",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3505132010",
    "district_id": "350513",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3505141008",
    "district_id": "350514",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3505141009",
    "district_id": "350514",
    "label": "Kamulan",
    "value": "Kamulan"
  },
  {
    "id": "3505141013",
    "district_id": "350514",
    "label": "Bajang",
    "value": "Bajang"
  },
  {
    "id": "3505141014",
    "district_id": "350514",
    "label": "Kaweron",
    "value": "Kaweron"
  },
  {
    "id": "3505142001",
    "district_id": "350514",
    "label": "Tumpang",
    "value": "Tumpang"
  },
  {
    "id": "3505142002",
    "district_id": "350514",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3505142003",
    "district_id": "350514",
    "label": "Pasirharjo",
    "value": "Pasirharjo"
  },
  {
    "id": "3505142004",
    "district_id": "350514",
    "label": "Jeblog",
    "value": "Jeblog"
  },
  {
    "id": "3505142005",
    "district_id": "350514",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3505142006",
    "district_id": "350514",
    "label": "Bendosewu",
    "value": "Bendosewu"
  },
  {
    "id": "3505142007",
    "district_id": "350514",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3505142010",
    "district_id": "350514",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3505142011",
    "district_id": "350514",
    "label": "Sragi",
    "value": "Sragi"
  },
  {
    "id": "3505142012",
    "district_id": "350514",
    "label": "Jajar",
    "value": "Jajar"
  },
  {
    "id": "3505152001",
    "district_id": "350515",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3505152002",
    "district_id": "350515",
    "label": "Gadungan",
    "value": "Gadungan"
  },
  {
    "id": "3505152003",
    "district_id": "350515",
    "label": "Kotes",
    "value": "Kotes"
  },
  {
    "id": "3505152004",
    "district_id": "350515",
    "label": "Sukosewu",
    "value": "Sukosewu"
  },
  {
    "id": "3505152005",
    "district_id": "350515",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3505152006",
    "district_id": "350515",
    "label": "Tambakan",
    "value": "Tambakan"
  },
  {
    "id": "3505152007",
    "district_id": "350515",
    "label": "Gandusari",
    "value": "Gandusari"
  },
  {
    "id": "3505152008",
    "district_id": "350515",
    "label": "Butun",
    "value": "Butun"
  },
  {
    "id": "3505152009",
    "district_id": "350515",
    "label": "Ngaringan",
    "value": "Ngaringan"
  },
  {
    "id": "3505152010",
    "district_id": "350515",
    "label": "Soso",
    "value": "Soso"
  },
  {
    "id": "3505152011",
    "district_id": "350515",
    "label": "Slumbung",
    "value": "Slumbung"
  },
  {
    "id": "3505152012",
    "district_id": "350515",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3505152013",
    "district_id": "350515",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3505152014",
    "district_id": "350515",
    "label": "Krisik",
    "value": "Krisik"
  },
  {
    "id": "3505162001",
    "district_id": "350516",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3505162002",
    "district_id": "350516",
    "label": "Salamrejo",
    "value": "Salamrejo"
  },
  {
    "id": "3505162003",
    "district_id": "350516",
    "label": "Sumberkembar",
    "value": "Sumberkembar"
  },
  {
    "id": "3505162004",
    "district_id": "350516",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3505162005",
    "district_id": "350516",
    "label": "Rejoso",
    "value": "Rejoso"
  },
  {
    "id": "3505162006",
    "district_id": "350516",
    "label": "Ngembul",
    "value": "Ngembul"
  },
  {
    "id": "3505162007",
    "district_id": "350516",
    "label": "Sambigede",
    "value": "Sambigede"
  },
  {
    "id": "3505162008",
    "district_id": "350516",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3505162009",
    "district_id": "350516",
    "label": "Umbuldamar",
    "value": "Umbuldamar"
  },
  {
    "id": "3505162010",
    "district_id": "350516",
    "label": "Ngadri",
    "value": "Ngadri"
  },
  {
    "id": "3505162011",
    "district_id": "350516",
    "label": "Birowo",
    "value": "Birowo"
  },
  {
    "id": "3505162012",
    "district_id": "350516",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3505171001",
    "district_id": "350517",
    "label": "Beru",
    "value": "Beru"
  },
  {
    "id": "3505171002",
    "district_id": "350517",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3505171003",
    "district_id": "350517",
    "label": "Tangkil",
    "value": "Tangkil"
  },
  {
    "id": "3505171004",
    "district_id": "350517",
    "label": "Wlingi",
    "value": "Wlingi"
  },
  {
    "id": "3505171006",
    "district_id": "350517",
    "label": "Klemunan",
    "value": "Klemunan"
  },
  {
    "id": "3505172005",
    "district_id": "350517",
    "label": "Tembalang",
    "value": "Tembalang"
  },
  {
    "id": "3505172007",
    "district_id": "350517",
    "label": "Ngadirenggo",
    "value": "Ngadirenggo"
  },
  {
    "id": "3505172008",
    "district_id": "350517",
    "label": "Tegalasri",
    "value": "Tegalasri"
  },
  {
    "id": "3505172009",
    "district_id": "350517",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3505182001",
    "district_id": "350518",
    "label": "Slorok",
    "value": "Slorok"
  },
  {
    "id": "3505182002",
    "district_id": "350518",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "3505182003",
    "district_id": "350518",
    "label": "Plumbangan",
    "value": "Plumbangan"
  },
  {
    "id": "3505182004",
    "district_id": "350518",
    "label": "Sumberurip",
    "value": "Sumberurip"
  },
  {
    "id": "3505182005",
    "district_id": "350518",
    "label": "Genengan",
    "value": "Genengan"
  },
  {
    "id": "3505182006",
    "district_id": "350518",
    "label": "Doko",
    "value": "Doko"
  },
  {
    "id": "3505182007",
    "district_id": "350518",
    "label": "Jambepawon",
    "value": "Jambepawon"
  },
  {
    "id": "3505182008",
    "district_id": "350518",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3505182009",
    "district_id": "350518",
    "label": "Resapombo",
    "value": "Resapombo"
  },
  {
    "id": "3505182010",
    "district_id": "350518",
    "label": "Kalimanis",
    "value": "Kalimanis"
  },
  {
    "id": "3505192001",
    "district_id": "350519",
    "label": "Siraman",
    "value": "Siraman"
  },
  {
    "id": "3505192002",
    "district_id": "350519",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  {
    "id": "3505192003",
    "district_id": "350519",
    "label": "Jugo",
    "value": "Jugo"
  },
  {
    "id": "3505192004",
    "district_id": "350519",
    "label": "Pagerwojo",
    "value": "Pagerwojo"
  },
  {
    "id": "3505192005",
    "district_id": "350519",
    "label": "Pagergunung",
    "value": "Pagergunung"
  },
  {
    "id": "3505192006",
    "district_id": "350519",
    "label": "Tepas",
    "value": "Tepas"
  },
  {
    "id": "3505192007",
    "district_id": "350519",
    "label": "Kemirigede",
    "value": "Kemirigede"
  },
  {
    "id": "3505192008",
    "district_id": "350519",
    "label": "Tapakrejo",
    "value": "Tapakrejo"
  },
  {
    "id": "3505192009",
    "district_id": "350519",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3505192010",
    "district_id": "350519",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3505202001",
    "district_id": "350520",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3505202002",
    "district_id": "350520",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3505202003",
    "district_id": "350520",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3505202004",
    "district_id": "350520",
    "label": "Ringinrejo",
    "value": "Ringinrejo"
  },
  {
    "id": "3505202005",
    "district_id": "350520",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3505202006",
    "district_id": "350520",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3505202007",
    "district_id": "350520",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3505202008",
    "district_id": "350520",
    "label": "Tugurejo",
    "value": "Tugurejo"
  },
  {
    "id": "3505212001",
    "district_id": "350521",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3505212002",
    "district_id": "350521",
    "label": "Pohgajih",
    "value": "Pohgajih"
  },
  {
    "id": "3505212003",
    "district_id": "350521",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3505212004",
    "district_id": "350521",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3505212005",
    "district_id": "350521",
    "label": "Ngrendeng",
    "value": "Ngrendeng"
  },
  {
    "id": "3505212006",
    "district_id": "350521",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3505212007",
    "district_id": "350521",
    "label": "Ampelgading",
    "value": "Ampelgading"
  },
  {
    "id": "3505212008",
    "district_id": "350521",
    "label": "Olak Alen",
    "value": "Olak Alen"
  },
  {
    "id": "3505212009",
    "district_id": "350521",
    "label": "Boro",
    "value": "Boro"
  },
  {
    "id": "3505212010",
    "district_id": "350521",
    "label": "Ngreco",
    "value": "Ngreco"
  },
  {
    "id": "3505222001",
    "district_id": "350522",
    "label": "Mandesan",
    "value": "Mandesan"
  },
  {
    "id": "3505222002",
    "district_id": "350522",
    "label": "Jatitengah",
    "value": "Jatitengah"
  },
  {
    "id": "3505222003",
    "district_id": "350522",
    "label": "Jambewangi",
    "value": "Jambewangi"
  },
  {
    "id": "3505222004",
    "district_id": "350522",
    "label": "Selopuro",
    "value": "Selopuro"
  },
  {
    "id": "3505222005",
    "district_id": "350522",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3505222006",
    "district_id": "350522",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3505222007",
    "district_id": "350522",
    "label": "Popoh",
    "value": "Popoh"
  },
  {
    "id": "3505222008",
    "district_id": "350522",
    "label": "Mronjo",
    "value": "Mronjo"
  },
  {
    "id": "3506012001",
    "district_id": "350601",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3506012002",
    "district_id": "350601",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3506012003",
    "district_id": "350601",
    "label": "Puhrubuh",
    "value": "Puhrubuh"
  },
  {
    "id": "3506012004",
    "district_id": "350601",
    "label": "Selopanggung",
    "value": "Selopanggung"
  },
  {
    "id": "3506012005",
    "district_id": "350601",
    "label": "Kanyoran",
    "value": "Kanyoran"
  },
  {
    "id": "3506012006",
    "district_id": "350601",
    "label": "Puhsarang",
    "value": "Puhsarang"
  },
  {
    "id": "3506012007",
    "district_id": "350601",
    "label": "Bobang",
    "value": "Bobang"
  },
  {
    "id": "3506012008",
    "district_id": "350601",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3506012009",
    "district_id": "350601",
    "label": "Titik",
    "value": "Titik"
  },
  {
    "id": "3506012010",
    "district_id": "350601",
    "label": "Kedak",
    "value": "Kedak"
  },
  {
    "id": "3506012011",
    "district_id": "350601",
    "label": "Pagung",
    "value": "Pagung"
  },
  {
    "id": "3506012012",
    "district_id": "350601",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3506022001",
    "district_id": "350602",
    "label": "Jugo",
    "value": "Jugo"
  },
  {
    "id": "3506022002",
    "district_id": "350602",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3506022003",
    "district_id": "350602",
    "label": "Pamongan",
    "value": "Pamongan"
  },
  {
    "id": "3506022004",
    "district_id": "350602",
    "label": "Petungroto",
    "value": "Petungroto"
  },
  {
    "id": "3506022005",
    "district_id": "350602",
    "label": "Ngetrep",
    "value": "Ngetrep"
  },
  {
    "id": "3506022006",
    "district_id": "350602",
    "label": "Ponggok",
    "value": "Ponggok"
  },
  {
    "id": "3506022007",
    "district_id": "350602",
    "label": "Ngadi",
    "value": "Ngadi"
  },
  {
    "id": "3506022008",
    "district_id": "350602",
    "label": "Kranding",
    "value": "Kranding"
  },
  {
    "id": "3506022009",
    "district_id": "350602",
    "label": "Maesan",
    "value": "Maesan"
  },
  {
    "id": "3506022010",
    "district_id": "350602",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3506022011",
    "district_id": "350602",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3506022012",
    "district_id": "350602",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3506022013",
    "district_id": "350602",
    "label": "Tambibendo",
    "value": "Tambibendo"
  },
  {
    "id": "3506022014",
    "district_id": "350602",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3506022015",
    "district_id": "350602",
    "label": "Mlati",
    "value": "Mlati"
  },
  {
    "id": "3506022016",
    "district_id": "350602",
    "label": "Surat",
    "value": "Surat"
  },
  {
    "id": "3506022017",
    "district_id": "350602",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3506022018",
    "district_id": "350602",
    "label": "Keniten",
    "value": "Keniten"
  },
  {
    "id": "3506022019",
    "district_id": "350602",
    "label": "Mondo",
    "value": "Mondo"
  },
  {
    "id": "3506022020",
    "district_id": "350602",
    "label": "Petok",
    "value": "Petok"
  },
  {
    "id": "3506032001",
    "district_id": "350603",
    "label": "Setonorejo",
    "value": "Setonorejo"
  },
  {
    "id": "3506032002",
    "district_id": "350603",
    "label": "Pelas",
    "value": "Pelas"
  },
  {
    "id": "3506032003",
    "district_id": "350603",
    "label": "Bleber",
    "value": "Bleber"
  },
  {
    "id": "3506032004",
    "district_id": "350603",
    "label": "Butuh",
    "value": "Butuh"
  },
  {
    "id": "3506032005",
    "district_id": "350603",
    "label": "Jabang",
    "value": "Jabang"
  },
  {
    "id": "3506032006",
    "district_id": "350603",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3506032007",
    "district_id": "350603",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3506032008",
    "district_id": "350603",
    "label": "Karangtalun",
    "value": "Karangtalun"
  },
  {
    "id": "3506032009",
    "district_id": "350603",
    "label": "Nyawangan",
    "value": "Nyawangan"
  },
  {
    "id": "3506032010",
    "district_id": "350603",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3506032011",
    "district_id": "350603",
    "label": "Kras",
    "value": "Kras"
  },
  {
    "id": "3506032012",
    "district_id": "350603",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3506032013",
    "district_id": "350603",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3506032014",
    "district_id": "350603",
    "label": "Krandang",
    "value": "Krandang"
  },
  {
    "id": "3506032015",
    "district_id": "350603",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3506032016",
    "district_id": "350603",
    "label": "Jambean",
    "value": "Jambean"
  },
  {
    "id": "3506042001",
    "district_id": "350604",
    "label": "Tales",
    "value": "Tales"
  },
  {
    "id": "3506042002",
    "district_id": "350604",
    "label": "Seketi",
    "value": "Seketi"
  },
  {
    "id": "3506042003",
    "district_id": "350604",
    "label": "Banggle",
    "value": "Banggle"
  },
  {
    "id": "3506042004",
    "district_id": "350604",
    "label": "Branggahan",
    "value": "Branggahan"
  },
  {
    "id": "3506042005",
    "district_id": "350604",
    "label": "Slumbung",
    "value": "Slumbung"
  },
  {
    "id": "3506042006",
    "district_id": "350604",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3506042007",
    "district_id": "350604",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3506042008",
    "district_id": "350604",
    "label": "Ngadiluwih",
    "value": "Ngadiluwih"
  },
  {
    "id": "3506042009",
    "district_id": "350604",
    "label": "Badal",
    "value": "Badal"
  },
  {
    "id": "3506042010",
    "district_id": "350604",
    "label": "Badal Pandean",
    "value": "Badal Pandean"
  },
  {
    "id": "3506042011",
    "district_id": "350604",
    "label": "Rembang",
    "value": "Rembang"
  },
  {
    "id": "3506042012",
    "district_id": "350604",
    "label": "Rembangkepuh",
    "value": "Rembangkepuh"
  },
  {
    "id": "3506042013",
    "district_id": "350604",
    "label": "Bedug",
    "value": "Bedug"
  },
  {
    "id": "3506042014",
    "district_id": "350604",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3506042015",
    "district_id": "350604",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3506042016",
    "district_id": "350604",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3506052001",
    "district_id": "350605",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3506052002",
    "district_id": "350605",
    "label": "Selosari",
    "value": "Selosari"
  },
  {
    "id": "3506052003",
    "district_id": "350605",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3506052004",
    "district_id": "350605",
    "label": "Kandat",
    "value": "Kandat"
  },
  {
    "id": "3506052005",
    "district_id": "350605",
    "label": "Cendono",
    "value": "Cendono"
  },
  {
    "id": "3506052006",
    "district_id": "350605",
    "label": "Ringinsari",
    "value": "Ringinsari"
  },
  {
    "id": "3506052007",
    "district_id": "350605",
    "label": "Tegalan",
    "value": "Tegalan"
  },
  {
    "id": "3506052008",
    "district_id": "350605",
    "label": "Ngletih",
    "value": "Ngletih"
  },
  {
    "id": "3506052009",
    "district_id": "350605",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3506052010",
    "district_id": "350605",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3506052011",
    "district_id": "350605",
    "label": "Blabak",
    "value": "Blabak"
  },
  {
    "id": "3506052012",
    "district_id": "350605",
    "label": "Ngreco",
    "value": "Ngreco"
  },
  {
    "id": "3506062001",
    "district_id": "350606",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3506062002",
    "district_id": "350606",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3506062003",
    "district_id": "350606",
    "label": "Segaran",
    "value": "Segaran"
  },
  {
    "id": "3506062004",
    "district_id": "350606",
    "label": "Gadungan",
    "value": "Gadungan"
  },
  {
    "id": "3506062005",
    "district_id": "350606",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3506062006",
    "district_id": "350606",
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "id": "3506062007",
    "district_id": "350606",
    "label": "Jajar",
    "value": "Jajar"
  },
  {
    "id": "3506062008",
    "district_id": "350606",
    "label": "Tunge",
    "value": "Tunge"
  },
  {
    "id": "3506062009",
    "district_id": "350606",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3506062010",
    "district_id": "350606",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3506062011",
    "district_id": "350606",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3506062012",
    "district_id": "350606",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3506062013",
    "district_id": "350606",
    "label": "Pagu",
    "value": "Pagu"
  },
  {
    "id": "3506062014",
    "district_id": "350606",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3506062015",
    "district_id": "350606",
    "label": "Silir",
    "value": "Silir"
  },
  {
    "id": "3506062016",
    "district_id": "350606",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3506062017",
    "district_id": "350606",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3506062018",
    "district_id": "350606",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3506072001",
    "district_id": "350607",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3506072002",
    "district_id": "350607",
    "label": "Pandantoyo",
    "value": "Pandantoyo"
  },
  {
    "id": "3506072003",
    "district_id": "350607",
    "label": "Margourip",
    "value": "Margourip"
  },
  {
    "id": "3506072004",
    "district_id": "350607",
    "label": "Bedali",
    "value": "Bedali"
  },
  {
    "id": "3506072005",
    "district_id": "350607",
    "label": "Kunjang",
    "value": "Kunjang"
  },
  {
    "id": "3506072006",
    "district_id": "350607",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3506072007",
    "district_id": "350607",
    "label": "Ngancar",
    "value": "Ngancar"
  },
  {
    "id": "3506072008",
    "district_id": "350607",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3506072009",
    "district_id": "350607",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3506072010",
    "district_id": "350607",
    "label": "Jagul",
    "value": "Jagul"
  },
  {
    "id": "3506082001",
    "district_id": "350608",
    "label": "Puncu",
    "value": "Puncu"
  },
  {
    "id": "3506082002",
    "district_id": "350608",
    "label": "Asmorobangun",
    "value": "Asmorobangun"
  },
  {
    "id": "3506082003",
    "district_id": "350608",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "3506082004",
    "district_id": "350608",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3506082005",
    "district_id": "350608",
    "label": "Watugede",
    "value": "Watugede"
  },
  {
    "id": "3506082006",
    "district_id": "350608",
    "label": "Gadungan",
    "value": "Gadungan"
  },
  {
    "id": "3506082007",
    "district_id": "350608",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3506082008",
    "district_id": "350608",
    "label": "Satak",
    "value": "Satak"
  },
  {
    "id": "3506092001",
    "district_id": "350609",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3506092002",
    "district_id": "350609",
    "label": "Kayunan",
    "value": "Kayunan"
  },
  {
    "id": "3506092003",
    "district_id": "350609",
    "label": "Panjer",
    "value": "Panjer"
  },
  {
    "id": "3506092004",
    "district_id": "350609",
    "label": "Jarak",
    "value": "Jarak"
  },
  {
    "id": "3506092005",
    "district_id": "350609",
    "label": "Ploso Kidul",
    "value": "Ploso Kidul"
  },
  {
    "id": "3506092006",
    "district_id": "350609",
    "label": "Ploso Lor",
    "value": "Ploso Lor"
  },
  {
    "id": "3506092007",
    "district_id": "350609",
    "label": "Brenggolo",
    "value": "Brenggolo"
  },
  {
    "id": "3506092008",
    "district_id": "350609",
    "label": "Donganti",
    "value": "Donganti"
  },
  {
    "id": "3506092009",
    "district_id": "350609",
    "label": "Kawedusan",
    "value": "Kawedusan"
  },
  {
    "id": "3506092010",
    "district_id": "350609",
    "label": "Klanderan",
    "value": "Klanderan"
  },
  {
    "id": "3506092011",
    "district_id": "350609",
    "label": "Punjul",
    "value": "Punjul"
  },
  {
    "id": "3506092012",
    "district_id": "350609",
    "label": "Pranggang",
    "value": "Pranggang"
  },
  {
    "id": "3506092013",
    "district_id": "350609",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3506092014",
    "district_id": "350609",
    "label": "Wonorejo Trisulo",
    "value": "Wonorejo Trisulo"
  },
  {
    "id": "3506092015",
    "district_id": "350609",
    "label": "Sepawon",
    "value": "Sepawon"
  },
  {
    "id": "3506102001",
    "district_id": "350610",
    "label": "Nglumbang",
    "value": "Nglumbang"
  },
  {
    "id": "3506102002",
    "district_id": "350610",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3506102003",
    "district_id": "350610",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3506102004",
    "district_id": "350610",
    "label": "Bogem",
    "value": "Bogem"
  },
  {
    "id": "3506102005",
    "district_id": "350610",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3506102006",
    "district_id": "350610",
    "label": "Turus",
    "value": "Turus"
  },
  {
    "id": "3506102007",
    "district_id": "350610",
    "label": "Gabru",
    "value": "Gabru"
  },
  {
    "id": "3506102008",
    "district_id": "350610",
    "label": "Wonojoyo",
    "value": "Wonojoyo"
  },
  {
    "id": "3506102009",
    "district_id": "350610",
    "label": "Bangkok",
    "value": "Bangkok"
  },
  {
    "id": "3506102010",
    "district_id": "350610",
    "label": "Gurah",
    "value": "Gurah"
  },
  {
    "id": "3506102011",
    "district_id": "350610",
    "label": "Banyuanyar",
    "value": "Banyuanyar"
  },
  {
    "id": "3506102012",
    "district_id": "350610",
    "label": "Besuk",
    "value": "Besuk"
  },
  {
    "id": "3506102013",
    "district_id": "350610",
    "label": "Sumbercangkring",
    "value": "Sumbercangkring"
  },
  {
    "id": "3506102014",
    "district_id": "350610",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3506102015",
    "district_id": "350610",
    "label": "Kerkep",
    "value": "Kerkep"
  },
  {
    "id": "3506102016",
    "district_id": "350610",
    "label": "Gempolan",
    "value": "Gempolan"
  },
  {
    "id": "3506102017",
    "district_id": "350610",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3506102018",
    "district_id": "350610",
    "label": "Tiru Kidul",
    "value": "Tiru Kidul"
  },
  {
    "id": "3506102019",
    "district_id": "350610",
    "label": "Tiru Lor",
    "value": "Tiru Lor"
  },
  {
    "id": "3506102020",
    "district_id": "350610",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3506102021",
    "district_id": "350610",
    "label": "Adan-adan",
    "value": "Adan-adan"
  },
  {
    "id": "3506112001",
    "district_id": "350611",
    "label": "Bulupasar",
    "value": "Bulupasar"
  },
  {
    "id": "3506112002",
    "district_id": "350611",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3506112003",
    "district_id": "350611",
    "label": "Kambingan",
    "value": "Kambingan"
  },
  {
    "id": "3506112004",
    "district_id": "350611",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3506112005",
    "district_id": "350611",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3506112006",
    "district_id": "350611",
    "label": "Menang",
    "value": "Menang"
  },
  {
    "id": "3506112007",
    "district_id": "350611",
    "label": "Tengger Kidul",
    "value": "Tengger Kidul"
  },
  {
    "id": "3506112008",
    "district_id": "350611",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "3506112009",
    "district_id": "350611",
    "label": "Sitimerto",
    "value": "Sitimerto"
  },
  {
    "id": "3506112010",
    "district_id": "350611",
    "label": "Pagu",
    "value": "Pagu"
  },
  {
    "id": "3506112011",
    "district_id": "350611",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3506112012",
    "district_id": "350611",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3506112016",
    "district_id": "350611",
    "label": "Jagung",
    "value": "Jagung"
  },
  {
    "id": "3506122010",
    "district_id": "350612",
    "label": "Jongbiru",
    "value": "Jongbiru"
  },
  {
    "id": "3506122011",
    "district_id": "350612",
    "label": "Putih",
    "value": "Putih"
  },
  {
    "id": "3506122012",
    "district_id": "350612",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3506122016",
    "district_id": "350612",
    "label": "Kepuhrejo",
    "value": "Kepuhrejo"
  },
  {
    "id": "3506122017",
    "district_id": "350612",
    "label": "Kalibelo",
    "value": "Kalibelo"
  },
  {
    "id": "3506122018",
    "district_id": "350612",
    "label": "Plosorejo",
    "value": "Plosorejo"
  },
  {
    "id": "3506122019",
    "district_id": "350612",
    "label": "Turus",
    "value": "Turus"
  },
  {
    "id": "3506122020",
    "district_id": "350612",
    "label": "Sambiresik",
    "value": "Sambiresik"
  },
  {
    "id": "3506122021",
    "district_id": "350612",
    "label": "Gampeng",
    "value": "Gampeng"
  },
  {
    "id": "3506122022",
    "district_id": "350612",
    "label": "Ngebrak",
    "value": "Ngebrak"
  },
  {
    "id": "3506122023",
    "district_id": "350612",
    "label": "Wanengpaten",
    "value": "Wanengpaten"
  },
  {
    "id": "3506132001",
    "district_id": "350613",
    "label": "Kalipang",
    "value": "Kalipang"
  },
  {
    "id": "3506132002",
    "district_id": "350613",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3506132003",
    "district_id": "350613",
    "label": "Cerme",
    "value": "Cerme"
  },
  {
    "id": "3506132004",
    "district_id": "350613",
    "label": "Wonoasri",
    "value": "Wonoasri"
  },
  {
    "id": "3506132005",
    "district_id": "350613",
    "label": "Sonorejo",
    "value": "Sonorejo"
  },
  {
    "id": "3506132006",
    "district_id": "350613",
    "label": "Datengan",
    "value": "Datengan"
  },
  {
    "id": "3506132007",
    "district_id": "350613",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3506132008",
    "district_id": "350613",
    "label": "Gambyok",
    "value": "Gambyok"
  },
  {
    "id": "3506132009",
    "district_id": "350613",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3506142001",
    "district_id": "350614",
    "label": "Purwotengah",
    "value": "Purwotengah"
  },
  {
    "id": "3506142002",
    "district_id": "350614",
    "label": "Kwaron",
    "value": "Kwaron"
  },
  {
    "id": "3506142003",
    "district_id": "350614",
    "label": "Minggiran",
    "value": "Minggiran"
  },
  {
    "id": "3506142004",
    "district_id": "350614",
    "label": "Pehkulon",
    "value": "Pehkulon"
  },
  {
    "id": "3506142005",
    "district_id": "350614",
    "label": "Pehwetan",
    "value": "Pehwetan"
  },
  {
    "id": "3506142006",
    "district_id": "350614",
    "label": "Dawuhan Kidul",
    "value": "Dawuhan Kidul"
  },
  {
    "id": "3506142007",
    "district_id": "350614",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3506142008",
    "district_id": "350614",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3506142009",
    "district_id": "350614",
    "label": "Kedungmalang",
    "value": "Kedungmalang"
  },
  {
    "id": "3506142010",
    "district_id": "350614",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3506142011",
    "district_id": "350614",
    "label": "Maduretno",
    "value": "Maduretno"
  },
  {
    "id": "3506142012",
    "district_id": "350614",
    "label": "Papar",
    "value": "Papar"
  },
  {
    "id": "3506142013",
    "district_id": "350614",
    "label": "Tanon",
    "value": "Tanon"
  },
  {
    "id": "3506142014",
    "district_id": "350614",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "3506142015",
    "district_id": "350614",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3506142016",
    "district_id": "350614",
    "label": "Sukomoro",
    "value": "Sukomoro"
  },
  {
    "id": "3506142017",
    "district_id": "350614",
    "label": "Puhjajar",
    "value": "Puhjajar"
  },
  {
    "id": "3506152001",
    "district_id": "350615",
    "label": "Jantok",
    "value": "Jantok"
  },
  {
    "id": "3506152002",
    "district_id": "350615",
    "label": "Pesing",
    "value": "Pesing"
  },
  {
    "id": "3506152003",
    "district_id": "350615",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "3506152004",
    "district_id": "350615",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "3506152005",
    "district_id": "350615",
    "label": "Wonotengah",
    "value": "Wonotengah"
  },
  {
    "id": "3506152006",
    "district_id": "350615",
    "label": "Ketawang",
    "value": "Ketawang"
  },
  {
    "id": "3506152007",
    "district_id": "350615",
    "label": "Belor",
    "value": "Belor"
  },
  {
    "id": "3506152008",
    "district_id": "350615",
    "label": "Blawe",
    "value": "Blawe"
  },
  {
    "id": "3506152009",
    "district_id": "350615",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3506152010",
    "district_id": "350615",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3506152011",
    "district_id": "350615",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3506152012",
    "district_id": "350615",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3506152013",
    "district_id": "350615",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3506152014",
    "district_id": "350615",
    "label": "Klampitan",
    "value": "Klampitan"
  },
  {
    "id": "3506152015",
    "district_id": "350615",
    "label": "Woromarto",
    "value": "Woromarto"
  },
  {
    "id": "3506152016",
    "district_id": "350615",
    "label": "Muneng",
    "value": "Muneng"
  },
  {
    "id": "3506152017",
    "district_id": "350615",
    "label": "Merjoyo",
    "value": "Merjoyo"
  },
  {
    "id": "3506152018",
    "district_id": "350615",
    "label": "Mekikis",
    "value": "Mekikis"
  },
  {
    "id": "3506152019",
    "district_id": "350615",
    "label": "Karangpakis",
    "value": "Karangpakis"
  },
  {
    "id": "3506152020",
    "district_id": "350615",
    "label": "Dayu",
    "value": "Dayu"
  },
  {
    "id": "3506152021",
    "district_id": "350615",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3506152022",
    "district_id": "350615",
    "label": "Kempleng",
    "value": "Kempleng"
  },
  {
    "id": "3506152023",
    "district_id": "350615",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3506162001",
    "district_id": "350616",
    "label": "Langenharjo",
    "value": "Langenharjo"
  },
  {
    "id": "3506162002",
    "district_id": "350616",
    "label": "Tegowangi",
    "value": "Tegowangi"
  },
  {
    "id": "3506162003",
    "district_id": "350616",
    "label": "Puhjarak",
    "value": "Puhjarak"
  },
  {
    "id": "3506162004",
    "district_id": "350616",
    "label": "Mejono",
    "value": "Mejono"
  },
  {
    "id": "3506162005",
    "district_id": "350616",
    "label": "Payaman",
    "value": "Payaman"
  },
  {
    "id": "3506162006",
    "district_id": "350616",
    "label": "Bogokidul",
    "value": "Bogokidul"
  },
  {
    "id": "3506162007",
    "district_id": "350616",
    "label": "Kayen Lor",
    "value": "Kayen Lor"
  },
  {
    "id": "3506162008",
    "district_id": "350616",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3506162009",
    "district_id": "350616",
    "label": "Ngino",
    "value": "Ngino"
  },
  {
    "id": "3506162010",
    "district_id": "350616",
    "label": "Sebet",
    "value": "Sebet"
  },
  {
    "id": "3506162011",
    "district_id": "350616",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3506162012",
    "district_id": "350616",
    "label": "Sidowarek",
    "value": "Sidowarek"
  },
  {
    "id": "3506162013",
    "district_id": "350616",
    "label": "Ringinpitu",
    "value": "Ringinpitu"
  },
  {
    "id": "3506162014",
    "district_id": "350616",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3506162015",
    "district_id": "350616",
    "label": "Mojoayu",
    "value": "Mojoayu"
  },
  {
    "id": "3506162016",
    "district_id": "350616",
    "label": "Plemahan",
    "value": "Plemahan"
  },
  {
    "id": "3506162017",
    "district_id": "350616",
    "label": "Mojokerep",
    "value": "Mojokerep"
  },
  {
    "id": "3506171009",
    "district_id": "350617",
    "label": "Pare",
    "value": "Pare"
  },
  {
    "id": "3506172001",
    "district_id": "350617",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3506172002",
    "district_id": "350617",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3506172003",
    "district_id": "350617",
    "label": "Gedangsewu",
    "value": "Gedangsewu"
  },
  {
    "id": "3506172004",
    "district_id": "350617",
    "label": "Sumberbendo",
    "value": "Sumberbendo"
  },
  {
    "id": "3506172005",
    "district_id": "350617",
    "label": "Darungan",
    "value": "Darungan"
  },
  {
    "id": "3506172006",
    "district_id": "350617",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3506172007",
    "district_id": "350617",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3506172008",
    "district_id": "350617",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3506172010",
    "district_id": "350617",
    "label": "Tertek",
    "value": "Tertek"
  },
  {
    "id": "3506182001",
    "district_id": "350618",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3506182002",
    "district_id": "350618",
    "label": "Kampungbaru",
    "value": "Kampungbaru"
  },
  {
    "id": "3506182003",
    "district_id": "350618",
    "label": "Besowo",
    "value": "Besowo"
  },
  {
    "id": "3506182004",
    "district_id": "350618",
    "label": "Siman",
    "value": "Siman"
  },
  {
    "id": "3506182005",
    "district_id": "350618",
    "label": "Brumbung",
    "value": "Brumbung"
  },
  {
    "id": "3506182006",
    "district_id": "350618",
    "label": "Kepung",
    "value": "Kepung"
  },
  {
    "id": "3506182007",
    "district_id": "350618",
    "label": "Krenceng",
    "value": "Krenceng"
  },
  {
    "id": "3506182008",
    "district_id": "350618",
    "label": "Kencong",
    "value": "Kencong"
  },
  {
    "id": "3506182009",
    "district_id": "350618",
    "label": "Keling",
    "value": "Keling"
  },
  {
    "id": "3506182010",
    "district_id": "350618",
    "label": "Damarwulan",
    "value": "Damarwulan"
  },
  {
    "id": "3506192001",
    "district_id": "350619",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3506192002",
    "district_id": "350619",
    "label": "Klampisan",
    "value": "Klampisan"
  },
  {
    "id": "3506192003",
    "district_id": "350619",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3506192004",
    "district_id": "350619",
    "label": "Kasreman",
    "value": "Kasreman"
  },
  {
    "id": "3506192005",
    "district_id": "350619",
    "label": "Jerukwangi",
    "value": "Jerukwangi"
  },
  {
    "id": "3506192006",
    "district_id": "350619",
    "label": "Jerukgulung",
    "value": "Jerukgulung"
  },
  {
    "id": "3506192007",
    "district_id": "350619",
    "label": "Jlumbang",
    "value": "Jlumbang"
  },
  {
    "id": "3506192008",
    "district_id": "350619",
    "label": "Bukur",
    "value": "Bukur"
  },
  {
    "id": "3506192009",
    "district_id": "350619",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3506192010",
    "district_id": "350619",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3506192011",
    "district_id": "350619",
    "label": "Mlancu",
    "value": "Mlancu"
  },
  {
    "id": "3506192012",
    "district_id": "350619",
    "label": "Medowo",
    "value": "Medowo"
  },
  {
    "id": "3506202001",
    "district_id": "350620",
    "label": "Kalirong",
    "value": "Kalirong"
  },
  {
    "id": "3506202002",
    "district_id": "350620",
    "label": "Kaliboto",
    "value": "Kaliboto"
  },
  {
    "id": "3506202003",
    "district_id": "350620",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3506202004",
    "district_id": "350620",
    "label": "Tarokan",
    "value": "Tarokan"
  },
  {
    "id": "3506202005",
    "district_id": "350620",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3506202006",
    "district_id": "350620",
    "label": "Sumberduren",
    "value": "Sumberduren"
  },
  {
    "id": "3506202007",
    "district_id": "350620",
    "label": "Kerep",
    "value": "Kerep"
  },
  {
    "id": "3506202008",
    "district_id": "350620",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3506202009",
    "district_id": "350620",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3506202010",
    "district_id": "350620",
    "label": "Cengkok",
    "value": "Cengkok"
  },
  {
    "id": "3506212001",
    "district_id": "350621",
    "label": "Kuwik",
    "value": "Kuwik"
  },
  {
    "id": "3506212002",
    "district_id": "350621",
    "label": "Balongjeruk",
    "value": "Balongjeruk"
  },
  {
    "id": "3506212003",
    "district_id": "350621",
    "label": "Kapas",
    "value": "Kapas"
  },
  {
    "id": "3506212004",
    "district_id": "350621",
    "label": "Klepek",
    "value": "Klepek"
  },
  {
    "id": "3506212005",
    "district_id": "350621",
    "label": "Kunjang",
    "value": "Kunjang"
  },
  {
    "id": "3506212006",
    "district_id": "350621",
    "label": "Kapi",
    "value": "Kapi"
  },
  {
    "id": "3506212007",
    "district_id": "350621",
    "label": "Tengger Lor",
    "value": "Tengger Lor"
  },
  {
    "id": "3506212008",
    "district_id": "350621",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3506212009",
    "district_id": "350621",
    "label": "Dungus",
    "value": "Dungus"
  },
  {
    "id": "3506212010",
    "district_id": "350621",
    "label": "Juwet",
    "value": "Juwet"
  },
  {
    "id": "3506212011",
    "district_id": "350621",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3506212012",
    "district_id": "350621",
    "label": "Parelor",
    "value": "Parelor"
  },
  {
    "id": "3506222001",
    "district_id": "350622",
    "label": "Banyakan",
    "value": "Banyakan"
  },
  {
    "id": "3506222002",
    "district_id": "350622",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3506222003",
    "district_id": "350622",
    "label": "Manyaran",
    "value": "Manyaran"
  },
  {
    "id": "3506222004",
    "district_id": "350622",
    "label": "Tiron",
    "value": "Tiron"
  },
  {
    "id": "3506222005",
    "district_id": "350622",
    "label": "Parang",
    "value": "Parang"
  },
  {
    "id": "3506222006",
    "district_id": "350622",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3506222007",
    "district_id": "350622",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3506222008",
    "district_id": "350622",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "3506222009",
    "district_id": "350622",
    "label": "Jabon",
    "value": "Jabon"
  },
  {
    "id": "3506232001",
    "district_id": "350623",
    "label": "Ringinrejo",
    "value": "Ringinrejo"
  },
  {
    "id": "3506232002",
    "district_id": "350623",
    "label": "Batuaji",
    "value": "Batuaji"
  },
  {
    "id": "3506232003",
    "district_id": "350623",
    "label": "Deyeng",
    "value": "Deyeng"
  },
  {
    "id": "3506232004",
    "district_id": "350623",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3506232005",
    "district_id": "350623",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3506232006",
    "district_id": "350623",
    "label": "Sambi",
    "value": "Sambi"
  },
  {
    "id": "3506232007",
    "district_id": "350623",
    "label": "Susuhbango",
    "value": "Susuhbango"
  },
  {
    "id": "3506232008",
    "district_id": "350623",
    "label": "Selodono",
    "value": "Selodono"
  },
  {
    "id": "3506232009",
    "district_id": "350623",
    "label": "Nambakan",
    "value": "Nambakan"
  },
  {
    "id": "3506232010",
    "district_id": "350623",
    "label": "Srikaton",
    "value": "Srikaton"
  },
  {
    "id": "3506232011",
    "district_id": "350623",
    "label": "Jemekan",
    "value": "Jemekan"
  },
  {
    "id": "3506242001",
    "district_id": "350624",
    "label": "Baye",
    "value": "Baye"
  },
  {
    "id": "3506242002",
    "district_id": "350624",
    "label": "Bangsongan",
    "value": "Bangsongan"
  },
  {
    "id": "3506242003",
    "district_id": "350624",
    "label": "Senden",
    "value": "Senden"
  },
  {
    "id": "3506242004",
    "district_id": "350624",
    "label": "Sambirobyong",
    "value": "Sambirobyong"
  },
  {
    "id": "3506242005",
    "district_id": "350624",
    "label": "Mukuh",
    "value": "Mukuh"
  },
  {
    "id": "3506242006",
    "district_id": "350624",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3506242007",
    "district_id": "350624",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3506242008",
    "district_id": "350624",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3506242009",
    "district_id": "350624",
    "label": "Padangan",
    "value": "Padangan"
  },
  {
    "id": "3506242010",
    "district_id": "350624",
    "label": "Nanggungan",
    "value": "Nanggungan"
  },
  {
    "id": "3506242011",
    "district_id": "350624",
    "label": "Semambung",
    "value": "Semambung"
  },
  {
    "id": "3506242012",
    "district_id": "350624",
    "label": "Kayen Kidul",
    "value": "Kayen Kidul"
  },
  {
    "id": "3506252001",
    "district_id": "350625",
    "label": "Tugurejo",
    "value": "Tugurejo"
  },
  {
    "id": "3506252002",
    "district_id": "350625",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3506252003",
    "district_id": "350625",
    "label": "Paron",
    "value": "Paron"
  },
  {
    "id": "3506252004",
    "district_id": "350625",
    "label": "Gogorante",
    "value": "Gogorante"
  },
  {
    "id": "3506252005",
    "district_id": "350625",
    "label": "Doko",
    "value": "Doko"
  },
  {
    "id": "3506252006",
    "district_id": "350625",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3506252007",
    "district_id": "350625",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3506252008",
    "district_id": "350625",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3506252009",
    "district_id": "350625",
    "label": "Kwadungan",
    "value": "Kwadungan"
  },
  {
    "id": "3506252010",
    "district_id": "350625",
    "label": "Nambaan",
    "value": "Nambaan"
  },
  {
    "id": "3506252011",
    "district_id": "350625",
    "label": "Toyoresmi",
    "value": "Toyoresmi"
  },
  {
    "id": "3506252012",
    "district_id": "350625",
    "label": "Wonocatur",
    "value": "Wonocatur"
  },
  {
    "id": "3506262001",
    "district_id": "350626",
    "label": "Canggu",
    "value": "Canggu"
  },
  {
    "id": "3506262002",
    "district_id": "350626",
    "label": "Lamong",
    "value": "Lamong"
  },
  {
    "id": "3506262003",
    "district_id": "350626",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3506262004",
    "district_id": "350626",
    "label": "Sekoto",
    "value": "Sekoto"
  },
  {
    "id": "3506262005",
    "district_id": "350626",
    "label": "Tunglur",
    "value": "Tunglur"
  },
  {
    "id": "3506262006",
    "district_id": "350626",
    "label": "Krecek",
    "value": "Krecek"
  },
  {
    "id": "3506262007",
    "district_id": "350626",
    "label": "Blaru",
    "value": "Blaru"
  },
  {
    "id": "3506262008",
    "district_id": "350626",
    "label": "Badas",
    "value": "Badas"
  },
  {
    "id": "3507012001",
    "district_id": "350701",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3507012002",
    "district_id": "350701",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3507012003",
    "district_id": "350701",
    "label": "Kedungsalam",
    "value": "Kedungsalam"
  },
  {
    "id": "3507012004",
    "district_id": "350701",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3507012005",
    "district_id": "350701",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3507012006",
    "district_id": "350701",
    "label": "Donomulyo",
    "value": "Donomulyo"
  },
  {
    "id": "3507012007",
    "district_id": "350701",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3507012008",
    "district_id": "350701",
    "label": "Sumberoto",
    "value": "Sumberoto"
  },
  {
    "id": "3507012009",
    "district_id": "350701",
    "label": "Mentaraman",
    "value": "Mentaraman"
  },
  {
    "id": "3507012010",
    "district_id": "350701",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3507022001",
    "district_id": "350702",
    "label": "Sumbermanjing Kulon",
    "value": "Sumbermanjing Kulon"
  },
  {
    "id": "3507022002",
    "district_id": "350702",
    "label": "Pandanrejo",
    "value": "Pandanrejo"
  },
  {
    "id": "3507022003",
    "district_id": "350702",
    "label": "Sumberkerto",
    "value": "Sumberkerto"
  },
  {
    "id": "3507022004",
    "district_id": "350702",
    "label": "Sempol",
    "value": "Sempol"
  },
  {
    "id": "3507022005",
    "district_id": "350702",
    "label": "Pagak",
    "value": "Pagak"
  },
  {
    "id": "3507022006",
    "district_id": "350702",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3507022007",
    "district_id": "350702",
    "label": "Gampingan",
    "value": "Gampingan"
  },
  {
    "id": "3507022008",
    "district_id": "350702",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3507032001",
    "district_id": "350703",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3507032002",
    "district_id": "350703",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3507032003",
    "district_id": "350703",
    "label": "Bantur",
    "value": "Bantur"
  },
  {
    "id": "3507032004",
    "district_id": "350703",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3507032005",
    "district_id": "350703",
    "label": "Srigonco",
    "value": "Srigonco"
  },
  {
    "id": "3507032006",
    "district_id": "350703",
    "label": "Sumberbening",
    "value": "Sumberbening"
  },
  {
    "id": "3507032007",
    "district_id": "350703",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3507032008",
    "district_id": "350703",
    "label": "Pringgondani",
    "value": "Pringgondani"
  },
  {
    "id": "3507032009",
    "district_id": "350703",
    "label": "Rejoyoso",
    "value": "Rejoyoso"
  },
  {
    "id": "3507032010",
    "district_id": "350703",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3507042001",
    "district_id": "350704",
    "label": "Tambakasri",
    "value": "Tambakasri"
  },
  {
    "id": "3507042002",
    "district_id": "350704",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3507042003",
    "district_id": "350704",
    "label": "Sekarbanyu",
    "value": "Sekarbanyu"
  },
  {
    "id": "3507042004",
    "district_id": "350704",
    "label": "Klepu",
    "value": "Klepu"
  },
  {
    "id": "3507042005",
    "district_id": "350704",
    "label": "Ringinkembar",
    "value": "Ringinkembar"
  },
  {
    "id": "3507042006",
    "district_id": "350704",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3507042007",
    "district_id": "350704",
    "label": "Sitiarjo",
    "value": "Sitiarjo"
  },
  {
    "id": "3507042008",
    "district_id": "350704",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3507042009",
    "district_id": "350704",
    "label": "Argotirto",
    "value": "Argotirto"
  },
  {
    "id": "3507042010",
    "district_id": "350704",
    "label": "Harjokuncaran",
    "value": "Harjokuncaran"
  },
  {
    "id": "3507042011",
    "district_id": "350704",
    "label": "Sumbermanjing Wetan",
    "value": "Sumbermanjing Wetan"
  },
  {
    "id": "3507042012",
    "district_id": "350704",
    "label": "Ringinsari",
    "value": "Ringinsari"
  },
  {
    "id": "3507042013",
    "district_id": "350704",
    "label": "Druju",
    "value": "Druju"
  },
  {
    "id": "3507042014",
    "district_id": "350704",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3507042015",
    "district_id": "350704",
    "label": "Sidoasri",
    "value": "Sidoasri"
  },
  {
    "id": "3507051007",
    "district_id": "350705",
    "label": "Dampit",
    "value": "Dampit"
  },
  {
    "id": "3507052001",
    "district_id": "350705",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3507052002",
    "district_id": "350705",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3507052003",
    "district_id": "350705",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "3507052004",
    "district_id": "350705",
    "label": "Baturetno",
    "value": "Baturetno"
  },
  {
    "id": "3507052005",
    "district_id": "350705",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3507052006",
    "district_id": "350705",
    "label": "Amandanom",
    "value": "Amandanom"
  },
  {
    "id": "3507052008",
    "district_id": "350705",
    "label": "Pamotan",
    "value": "Pamotan"
  },
  {
    "id": "3507052009",
    "district_id": "350705",
    "label": "Majangtengah",
    "value": "Majangtengah"
  },
  {
    "id": "3507052010",
    "district_id": "350705",
    "label": "Rembun",
    "value": "Rembun"
  },
  {
    "id": "3507052011",
    "district_id": "350705",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3507052012",
    "district_id": "350705",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3507062001",
    "district_id": "350706",
    "label": "Lebakharjo",
    "value": "Lebakharjo"
  },
  {
    "id": "3507062002",
    "district_id": "350706",
    "label": "Wirotaman",
    "value": "Wirotaman"
  },
  {
    "id": "3507062003",
    "district_id": "350706",
    "label": "Tamanasri",
    "value": "Tamanasri"
  },
  {
    "id": "3507062004",
    "district_id": "350706",
    "label": "Tirtomarto",
    "value": "Tirtomarto"
  },
  {
    "id": "3507062005",
    "district_id": "350706",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "3507062006",
    "district_id": "350706",
    "label": "Sidorenggo",
    "value": "Sidorenggo"
  },
  {
    "id": "3507062007",
    "district_id": "350706",
    "label": "Tirtomoyo",
    "value": "Tirtomoyo"
  },
  {
    "id": "3507062008",
    "district_id": "350706",
    "label": "Argoyuwono",
    "value": "Argoyuwono"
  },
  {
    "id": "3507062009",
    "district_id": "350706",
    "label": "Mulyoasri",
    "value": "Mulyoasri"
  },
  {
    "id": "3507062010",
    "district_id": "350706",
    "label": "Tawangagung",
    "value": "Tawangagung"
  },
  {
    "id": "3507062011",
    "district_id": "350706",
    "label": "Simojayan",
    "value": "Simojayan"
  },
  {
    "id": "3507062012",
    "district_id": "350706",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3507062013",
    "district_id": "350706",
    "label": "Sonowangi",
    "value": "Sonowangi"
  },
  {
    "id": "3507072001",
    "district_id": "350707",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3507072002",
    "district_id": "350707",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3507072003",
    "district_id": "350707",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3507072004",
    "district_id": "350707",
    "label": "Ngadireso",
    "value": "Ngadireso"
  },
  {
    "id": "3507072005",
    "district_id": "350707",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3507072006",
    "district_id": "350707",
    "label": "Karangnongko",
    "value": "Karangnongko"
  },
  {
    "id": "3507072007",
    "district_id": "350707",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3507072008",
    "district_id": "350707",
    "label": "Jambesari",
    "value": "Jambesari"
  },
  {
    "id": "3507072009",
    "district_id": "350707",
    "label": "Ngebruk",
    "value": "Ngebruk"
  },
  {
    "id": "3507072010",
    "district_id": "350707",
    "label": "Pajaran",
    "value": "Pajaran"
  },
  {
    "id": "3507072011",
    "district_id": "350707",
    "label": "Argosuko",
    "value": "Argosuko"
  },
  {
    "id": "3507072012",
    "district_id": "350707",
    "label": "Wonomulyo",
    "value": "Wonomulyo"
  },
  {
    "id": "3507072013",
    "district_id": "350707",
    "label": "Belung",
    "value": "Belung"
  },
  {
    "id": "3507072014",
    "district_id": "350707",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3507072015",
    "district_id": "350707",
    "label": "Poncokusumo",
    "value": "Poncokusumo"
  },
  {
    "id": "3507072016",
    "district_id": "350707",
    "label": "Gubugklakah",
    "value": "Gubugklakah"
  },
  {
    "id": "3507072017",
    "district_id": "350707",
    "label": "Ngadas",
    "value": "Ngadas"
  },
  {
    "id": "3507082001",
    "district_id": "350708",
    "label": "Sumberputih",
    "value": "Sumberputih"
  },
  {
    "id": "3507082002",
    "district_id": "350708",
    "label": "Wonoayu",
    "value": "Wonoayu"
  },
  {
    "id": "3507082003",
    "district_id": "350708",
    "label": "Bambang",
    "value": "Bambang"
  },
  {
    "id": "3507082004",
    "district_id": "350708",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3507082005",
    "district_id": "350708",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3507082006",
    "district_id": "350708",
    "label": "Patokpicis",
    "value": "Patokpicis"
  },
  {
    "id": "3507082007",
    "district_id": "350708",
    "label": "Blayu",
    "value": "Blayu"
  },
  {
    "id": "3507082008",
    "district_id": "350708",
    "label": "Codo",
    "value": "Codo"
  },
  {
    "id": "3507082009",
    "district_id": "350708",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3507082010",
    "district_id": "350708",
    "label": "Kidangbang",
    "value": "Kidangbang"
  },
  {
    "id": "3507082011",
    "district_id": "350708",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3507082012",
    "district_id": "350708",
    "label": "Wajak",
    "value": "Wajak"
  },
  {
    "id": "3507082013",
    "district_id": "350708",
    "label": "Ngembal",
    "value": "Ngembal"
  },
  {
    "id": "3507091008",
    "district_id": "350709",
    "label": "Sedayu",
    "value": "Sedayu"
  },
  {
    "id": "3507091011",
    "district_id": "350709",
    "label": "Turen",
    "value": "Turen"
  },
  {
    "id": "3507092001",
    "district_id": "350709",
    "label": "Tawangrejeni",
    "value": "Tawangrejeni"
  },
  {
    "id": "3507092002",
    "district_id": "350709",
    "label": "Kemulan",
    "value": "Kemulan"
  },
  {
    "id": "3507092003",
    "district_id": "350709",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3507092004",
    "district_id": "350709",
    "label": "Undaan",
    "value": "Undaan"
  },
  {
    "id": "3507092005",
    "district_id": "350709",
    "label": "Gedok Kulon",
    "value": "Gedok Kulon"
  },
  {
    "id": "3507092006",
    "district_id": "350709",
    "label": "Gedok Wetan",
    "value": "Gedok Wetan"
  },
  {
    "id": "3507092007",
    "district_id": "350709",
    "label": "Talok",
    "value": "Talok"
  },
  {
    "id": "3507092009",
    "district_id": "350709",
    "label": "Tanggung",
    "value": "Tanggung"
  },
  {
    "id": "3507092010",
    "district_id": "350709",
    "label": "Jeru",
    "value": "Jeru"
  },
  {
    "id": "3507092012",
    "district_id": "350709",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "3507092013",
    "district_id": "350709",
    "label": "Sanankerto",
    "value": "Sanankerto"
  },
  {
    "id": "3507092014",
    "district_id": "350709",
    "label": "Sananrejo",
    "value": "Sananrejo"
  },
  {
    "id": "3507092015",
    "district_id": "350709",
    "label": "Kedok",
    "value": "Kedok"
  },
  {
    "id": "3507092016",
    "district_id": "350709",
    "label": "Talangsuko",
    "value": "Talangsuko"
  },
  {
    "id": "3507092017",
    "district_id": "350709",
    "label": "Tumpukrenteng",
    "value": "Tumpukrenteng"
  },
  {
    "id": "3507102001",
    "district_id": "350710",
    "label": "Putat Kidul",
    "value": "Putat Kidul"
  },
  {
    "id": "3507102002",
    "district_id": "350710",
    "label": "Gondanglegi Kulon",
    "value": "Gondanglegi Kulon"
  },
  {
    "id": "3507102003",
    "district_id": "350710",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3507102004",
    "district_id": "350710",
    "label": "Gondanglegi Wetan",
    "value": "Gondanglegi Wetan"
  },
  {
    "id": "3507102005",
    "district_id": "350710",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3507102006",
    "district_id": "350710",
    "label": "Bulupitu",
    "value": "Bulupitu"
  },
  {
    "id": "3507102007",
    "district_id": "350710",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "3507102008",
    "district_id": "350710",
    "label": "Ganjaran",
    "value": "Ganjaran"
  },
  {
    "id": "3507102009",
    "district_id": "350710",
    "label": "Putat Lor",
    "value": "Putat Lor"
  },
  {
    "id": "3507102010",
    "district_id": "350710",
    "label": "Urek - Urek",
    "value": "Urek - Urek"
  },
  {
    "id": "3507102011",
    "district_id": "350710",
    "label": "Ketawang",
    "value": "Ketawang"
  },
  {
    "id": "3507102012",
    "district_id": "350710",
    "label": "Putukrejo",
    "value": "Putukrejo"
  },
  {
    "id": "3507102013",
    "district_id": "350710",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3507102014",
    "district_id": "350710",
    "label": "Sepanjang",
    "value": "Sepanjang"
  },
  {
    "id": "3507112001",
    "district_id": "350711",
    "label": "Putukrejo",
    "value": "Putukrejo"
  },
  {
    "id": "3507112002",
    "district_id": "350711",
    "label": "Kalipare",
    "value": "Kalipare"
  },
  {
    "id": "3507112003",
    "district_id": "350711",
    "label": "Sumberpetung",
    "value": "Sumberpetung"
  },
  {
    "id": "3507112004",
    "district_id": "350711",
    "label": "Sukowilangun",
    "value": "Sukowilangun"
  },
  {
    "id": "3507112005",
    "district_id": "350711",
    "label": "Tumpakrejo",
    "value": "Tumpakrejo"
  },
  {
    "id": "3507112006",
    "district_id": "350711",
    "label": "Arjosari",
    "value": "Arjosari"
  },
  {
    "id": "3507112007",
    "district_id": "350711",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3507112008",
    "district_id": "350711",
    "label": "Arjowilangun",
    "value": "Arjowilangun"
  },
  {
    "id": "3507112009",
    "district_id": "350711",
    "label": "Kaliasri",
    "value": "Kaliasri"
  },
  {
    "id": "3507122001",
    "district_id": "350712",
    "label": "Ternyang",
    "value": "Ternyang"
  },
  {
    "id": "3507122002",
    "district_id": "350712",
    "label": "Senggreng",
    "value": "Senggreng"
  },
  {
    "id": "3507122003",
    "district_id": "350712",
    "label": "Sambigede",
    "value": "Sambigede"
  },
  {
    "id": "3507122004",
    "district_id": "350712",
    "label": "Ngebrug",
    "value": "Ngebrug"
  },
  {
    "id": "3507122005",
    "district_id": "350712",
    "label": "Jatiguwi",
    "value": "Jatiguwi"
  },
  {
    "id": "3507122006",
    "district_id": "350712",
    "label": "Sumberpucung",
    "value": "Sumberpucung"
  },
  {
    "id": "3507122007",
    "district_id": "350712",
    "label": "Karangkates",
    "value": "Karangkates"
  },
  {
    "id": "3507131007",
    "district_id": "350713",
    "label": "Panarukan",
    "value": "Panarukan"
  },
  {
    "id": "3507131009",
    "district_id": "350713",
    "label": "Cepokomulyo",
    "value": "Cepokomulyo"
  },
  {
    "id": "3507131010",
    "district_id": "350713",
    "label": "Kepanjen",
    "value": "Kepanjen"
  },
  {
    "id": "3507131013",
    "district_id": "350713",
    "label": "Ardirejo",
    "value": "Ardirejo"
  },
  {
    "id": "3507132001",
    "district_id": "350713",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3507132002",
    "district_id": "350713",
    "label": "Sengguruh",
    "value": "Sengguruh"
  },
  {
    "id": "3507132003",
    "district_id": "350713",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3507132004",
    "district_id": "350713",
    "label": "Jenggolo",
    "value": "Jenggolo"
  },
  {
    "id": "3507132005",
    "district_id": "350713",
    "label": "Kedungpedaringan",
    "value": "Kedungpedaringan"
  },
  {
    "id": "3507132006",
    "district_id": "350713",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3507132008",
    "district_id": "350713",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "3507132011",
    "district_id": "350713",
    "label": "Talangagung",
    "value": "Talangagung"
  },
  {
    "id": "3507132012",
    "district_id": "350713",
    "label": "Dilem",
    "value": "Dilem"
  },
  {
    "id": "3507132014",
    "district_id": "350713",
    "label": "Sukoraharjo",
    "value": "Sukoraharjo"
  },
  {
    "id": "3507132015",
    "district_id": "350713",
    "label": "Curungrejo",
    "value": "Curungrejo"
  },
  {
    "id": "3507132016",
    "district_id": "350713",
    "label": "Jatirejoyoso",
    "value": "Jatirejoyoso"
  },
  {
    "id": "3507132017",
    "district_id": "350713",
    "label": "Ngadilangkung",
    "value": "Ngadilangkung"
  },
  {
    "id": "3507132018",
    "district_id": "350713",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3507142001",
    "district_id": "350714",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3507142002",
    "district_id": "350714",
    "label": "Kasri",
    "value": "Kasri"
  },
  {
    "id": "3507142003",
    "district_id": "350714",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3507142004",
    "district_id": "350714",
    "label": "Krebet",
    "value": "Krebet"
  },
  {
    "id": "3507142005",
    "district_id": "350714",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3507142006",
    "district_id": "350714",
    "label": "Sukonolo",
    "value": "Sukonolo"
  },
  {
    "id": "3507142007",
    "district_id": "350714",
    "label": "Lumbangsari",
    "value": "Lumbangsari"
  },
  {
    "id": "3507142008",
    "district_id": "350714",
    "label": "Wandanpuro",
    "value": "Wandanpuro"
  },
  {
    "id": "3507142009",
    "district_id": "350714",
    "label": "Sempalwadak",
    "value": "Sempalwadak"
  },
  {
    "id": "3507142010",
    "district_id": "350714",
    "label": "Bululawang",
    "value": "Bululawang"
  },
  {
    "id": "3507142011",
    "district_id": "350714",
    "label": "Krebet Senggrong",
    "value": "Krebet Senggrong"
  },
  {
    "id": "3507142012",
    "district_id": "350714",
    "label": "Kuwolu",
    "value": "Kuwolu"
  },
  {
    "id": "3507142013",
    "district_id": "350714",
    "label": "Kasembon",
    "value": "Kasembon"
  },
  {
    "id": "3507142014",
    "district_id": "350714",
    "label": "Pringu",
    "value": "Pringu"
  },
  {
    "id": "3507152001",
    "district_id": "350715",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3507152002",
    "district_id": "350715",
    "label": "Gunungronggo",
    "value": "Gunungronggo"
  },
  {
    "id": "3507152003",
    "district_id": "350715",
    "label": "Purwosekar",
    "value": "Purwosekar"
  },
  {
    "id": "3507152004",
    "district_id": "350715",
    "label": "Ngawonggo",
    "value": "Ngawonggo"
  },
  {
    "id": "3507152005",
    "district_id": "350715",
    "label": "Pandanmulyo",
    "value": "Pandanmulyo"
  },
  {
    "id": "3507152006",
    "district_id": "350715",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3507152007",
    "district_id": "350715",
    "label": "Tajinan",
    "value": "Tajinan"
  },
  {
    "id": "3507152008",
    "district_id": "350715",
    "label": "Randugading",
    "value": "Randugading"
  },
  {
    "id": "3507152009",
    "district_id": "350715",
    "label": "Jambearjo",
    "value": "Jambearjo"
  },
  {
    "id": "3507152010",
    "district_id": "350715",
    "label": "Tangkilsari",
    "value": "Tangkilsari"
  },
  {
    "id": "3507152011",
    "district_id": "350715",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3507152012",
    "district_id": "350715",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3507162001",
    "district_id": "350716",
    "label": "Ngingit",
    "value": "Ngingit"
  },
  {
    "id": "3507162002",
    "district_id": "350716",
    "label": "Kidal",
    "value": "Kidal"
  },
  {
    "id": "3507162003",
    "district_id": "350716",
    "label": "Kambingan",
    "value": "Kambingan"
  },
  {
    "id": "3507162004",
    "district_id": "350716",
    "label": "Pandanajeng",
    "value": "Pandanajeng"
  },
  {
    "id": "3507162005",
    "district_id": "350716",
    "label": "Pulungdowo",
    "value": "Pulungdowo"
  },
  {
    "id": "3507162006",
    "district_id": "350716",
    "label": "Bokor",
    "value": "Bokor"
  },
  {
    "id": "3507162007",
    "district_id": "350716",
    "label": "Slamet",
    "value": "Slamet"
  },
  {
    "id": "3507162008",
    "district_id": "350716",
    "label": "Wringinsongo",
    "value": "Wringinsongo"
  },
  {
    "id": "3507162009",
    "district_id": "350716",
    "label": "Jeru",
    "value": "Jeru"
  },
  {
    "id": "3507162010",
    "district_id": "350716",
    "label": "Malangsuko",
    "value": "Malangsuko"
  },
  {
    "id": "3507162011",
    "district_id": "350716",
    "label": "Tumpang",
    "value": "Tumpang"
  },
  {
    "id": "3507162012",
    "district_id": "350716",
    "label": "Tulusbesar",
    "value": "Tulusbesar"
  },
  {
    "id": "3507162013",
    "district_id": "350716",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3507162014",
    "district_id": "350716",
    "label": "Benjor",
    "value": "Benjor"
  },
  {
    "id": "3507162015",
    "district_id": "350716",
    "label": "Duwet Kerajan",
    "value": "Duwet Kerajan"
  },
  {
    "id": "3507172001",
    "district_id": "350717",
    "label": "Taji",
    "value": "Taji"
  },
  {
    "id": "3507172002",
    "district_id": "350717",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3507172003",
    "district_id": "350717",
    "label": "Kenongo",
    "value": "Kenongo"
  },
  {
    "id": "3507172004",
    "district_id": "350717",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3507172005",
    "district_id": "350717",
    "label": "Sukopuro",
    "value": "Sukopuro"
  },
  {
    "id": "3507172006",
    "district_id": "350717",
    "label": "Pandansari Lor",
    "value": "Pandansari Lor"
  },
  {
    "id": "3507172007",
    "district_id": "350717",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3507172008",
    "district_id": "350717",
    "label": "Gadingkembar",
    "value": "Gadingkembar"
  },
  {
    "id": "3507172009",
    "district_id": "350717",
    "label": "Argosari",
    "value": "Argosari"
  },
  {
    "id": "3507172010",
    "district_id": "350717",
    "label": "Kemantren",
    "value": "Kemantren"
  },
  {
    "id": "3507172011",
    "district_id": "350717",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3507172012",
    "district_id": "350717",
    "label": "Gunungjati",
    "value": "Gunungjati"
  },
  {
    "id": "3507172013",
    "district_id": "350717",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3507172014",
    "district_id": "350717",
    "label": "Slamparejo",
    "value": "Slamparejo"
  },
  {
    "id": "3507172015",
    "district_id": "350717",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3507182001",
    "district_id": "350718",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3507182002",
    "district_id": "350718",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3507182003",
    "district_id": "350718",
    "label": "Pucangsongo",
    "value": "Pucangsongo"
  },
  {
    "id": "3507182004",
    "district_id": "350718",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3507182005",
    "district_id": "350718",
    "label": "Sumberpasir",
    "value": "Sumberpasir"
  },
  {
    "id": "3507182006",
    "district_id": "350718",
    "label": "Pakiskembar",
    "value": "Pakiskembar"
  },
  {
    "id": "3507182007",
    "district_id": "350718",
    "label": "Sumberkradenan",
    "value": "Sumberkradenan"
  },
  {
    "id": "3507182008",
    "district_id": "350718",
    "label": "Ampeldento",
    "value": "Ampeldento"
  },
  {
    "id": "3507182009",
    "district_id": "350718",
    "label": "Sekarpuro",
    "value": "Sekarpuro"
  },
  {
    "id": "3507182010",
    "district_id": "350718",
    "label": "Mangliawan",
    "value": "Mangliawan"
  },
  {
    "id": "3507182011",
    "district_id": "350718",
    "label": "Tirtomoyo",
    "value": "Tirtomoyo"
  },
  {
    "id": "3507182012",
    "district_id": "350718",
    "label": "Saptorenggo",
    "value": "Saptorenggo"
  },
  {
    "id": "3507182013",
    "district_id": "350718",
    "label": "Asrikaton",
    "value": "Asrikaton"
  },
  {
    "id": "3507182014",
    "district_id": "350718",
    "label": "Bunutwetan",
    "value": "Bunutwetan"
  },
  {
    "id": "3507182015",
    "district_id": "350718",
    "label": "Pakisjajar",
    "value": "Pakisjajar"
  },
  {
    "id": "3507192001",
    "district_id": "350719",
    "label": "Permanu",
    "value": "Permanu"
  },
  {
    "id": "3507192002",
    "district_id": "350719",
    "label": "Karangpandan",
    "value": "Karangpandan"
  },
  {
    "id": "3507192003",
    "district_id": "350719",
    "label": "Gelanggang",
    "value": "Gelanggang"
  },
  {
    "id": "3507192004",
    "district_id": "350719",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3507192005",
    "district_id": "350719",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3507192006",
    "district_id": "350719",
    "label": "Sutojayan",
    "value": "Sutojayan"
  },
  {
    "id": "3507192007",
    "district_id": "350719",
    "label": "Pakisaji",
    "value": "Pakisaji"
  },
  {
    "id": "3507192008",
    "district_id": "350719",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3507192009",
    "district_id": "350719",
    "label": "Wadung",
    "value": "Wadung"
  },
  {
    "id": "3507192010",
    "district_id": "350719",
    "label": "Genengan",
    "value": "Genengan"
  },
  {
    "id": "3507192011",
    "district_id": "350719",
    "label": "Kendalpayak",
    "value": "Kendalpayak"
  },
  {
    "id": "3507192012",
    "district_id": "350719",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3507202001",
    "district_id": "350720",
    "label": "Ngajum",
    "value": "Ngajum"
  },
  {
    "id": "3507202002",
    "district_id": "350720",
    "label": "Palaan",
    "value": "Palaan"
  },
  {
    "id": "3507202003",
    "district_id": "350720",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3507202004",
    "district_id": "350720",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3507202005",
    "district_id": "350720",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3507202006",
    "district_id": "350720",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  {
    "id": "3507202007",
    "district_id": "350720",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3507202008",
    "district_id": "350720",
    "label": "Balesari",
    "value": "Balesari"
  },
  {
    "id": "3507202009",
    "district_id": "350720",
    "label": "Maguan",
    "value": "Maguan"
  },
  {
    "id": "3507212001",
    "district_id": "350721",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3507212002",
    "district_id": "350721",
    "label": "Mendalanwangi",
    "value": "Mendalanwangi"
  },
  {
    "id": "3507212003",
    "district_id": "350721",
    "label": "Sitirejo",
    "value": "Sitirejo"
  },
  {
    "id": "3507212004",
    "district_id": "350721",
    "label": "Parangargo",
    "value": "Parangargo"
  },
  {
    "id": "3507212005",
    "district_id": "350721",
    "label": "Gondowangi",
    "value": "Gondowangi"
  },
  {
    "id": "3507212006",
    "district_id": "350721",
    "label": "Pandanrejo",
    "value": "Pandanrejo"
  },
  {
    "id": "3507212007",
    "district_id": "350721",
    "label": "Petungsewu",
    "value": "Petungsewu"
  },
  {
    "id": "3507212008",
    "district_id": "350721",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3507212009",
    "district_id": "350721",
    "label": "Sidorahayu",
    "value": "Sidorahayu"
  },
  {
    "id": "3507212010",
    "district_id": "350721",
    "label": "Jedong",
    "value": "Jedong"
  },
  {
    "id": "3507212011",
    "district_id": "350721",
    "label": "Dalisodo",
    "value": "Dalisodo"
  },
  {
    "id": "3507212012",
    "district_id": "350721",
    "label": "Pandanlandung",
    "value": "Pandanlandung"
  },
  {
    "id": "3507222001",
    "district_id": "350722",
    "label": "Kucur",
    "value": "Kucur"
  },
  {
    "id": "3507222002",
    "district_id": "350722",
    "label": "Kalisongo",
    "value": "Kalisongo"
  },
  {
    "id": "3507222003",
    "district_id": "350722",
    "label": "Karangwidoro",
    "value": "Karangwidoro"
  },
  {
    "id": "3507222004",
    "district_id": "350722",
    "label": "Petungsewu",
    "value": "Petungsewu"
  },
  {
    "id": "3507222005",
    "district_id": "350722",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3507222006",
    "district_id": "350722",
    "label": "Tegalweru",
    "value": "Tegalweru"
  },
  {
    "id": "3507222007",
    "district_id": "350722",
    "label": "Landungsari",
    "value": "Landungsari"
  },
  {
    "id": "3507222008",
    "district_id": "350722",
    "label": "Mulyoagung",
    "value": "Mulyoagung"
  },
  {
    "id": "3507222009",
    "district_id": "350722",
    "label": "Gadingkulon",
    "value": "Gadingkulon"
  },
  {
    "id": "3507222010",
    "district_id": "350722",
    "label": "Sumbersekar",
    "value": "Sumbersekar"
  },
  {
    "id": "3507232001",
    "district_id": "350723",
    "label": "Tegalgondo",
    "value": "Tegalgondo"
  },
  {
    "id": "3507232002",
    "district_id": "350723",
    "label": "Kepuharjo",
    "value": "Kepuharjo"
  },
  {
    "id": "3507232003",
    "district_id": "350723",
    "label": "Ngenep",
    "value": "Ngenep"
  },
  {
    "id": "3507232004",
    "district_id": "350723",
    "label": "Ngijo",
    "value": "Ngijo"
  },
  {
    "id": "3507232005",
    "district_id": "350723",
    "label": "Ampeldento",
    "value": "Ampeldento"
  },
  {
    "id": "3507232006",
    "district_id": "350723",
    "label": "Girimoyo",
    "value": "Girimoyo"
  },
  {
    "id": "3507232007",
    "district_id": "350723",
    "label": "Bocek",
    "value": "Bocek"
  },
  {
    "id": "3507232008",
    "district_id": "350723",
    "label": "Donowarih",
    "value": "Donowarih"
  },
  {
    "id": "3507232009",
    "district_id": "350723",
    "label": "Tawangargo",
    "value": "Tawangargo"
  },
  {
    "id": "3507241011",
    "district_id": "350724",
    "label": "Pagentan",
    "value": "Pagentan"
  },
  {
    "id": "3507241014",
    "district_id": "350724",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3507241015",
    "district_id": "350724",
    "label": "Candirenggo",
    "value": "Candirenggo"
  },
  {
    "id": "3507242001",
    "district_id": "350724",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3507242002",
    "district_id": "350724",
    "label": "Dengkol",
    "value": "Dengkol"
  },
  {
    "id": "3507242003",
    "district_id": "350724",
    "label": "Baturetno",
    "value": "Baturetno"
  },
  {
    "id": "3507242004",
    "district_id": "350724",
    "label": "Watugede",
    "value": "Watugede"
  },
  {
    "id": "3507242005",
    "district_id": "350724",
    "label": "Banjararum",
    "value": "Banjararum"
  },
  {
    "id": "3507242006",
    "district_id": "350724",
    "label": "Tunjungtirto",
    "value": "Tunjungtirto"
  },
  {
    "id": "3507242007",
    "district_id": "350724",
    "label": "Lang - Lang",
    "value": "Lang - Lang"
  },
  {
    "id": "3507242008",
    "district_id": "350724",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "3507242009",
    "district_id": "350724",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3507242010",
    "district_id": "350724",
    "label": "Gunungrejo",
    "value": "Gunungrejo"
  },
  {
    "id": "3507242012",
    "district_id": "350724",
    "label": "Tamanhardjo",
    "value": "Tamanhardjo"
  },
  {
    "id": "3507242013",
    "district_id": "350724",
    "label": "Ardimulyo",
    "value": "Ardimulyo"
  },
  {
    "id": "3507242016",
    "district_id": "350724",
    "label": "Toyomarto",
    "value": "Toyomarto"
  },
  {
    "id": "3507242017",
    "district_id": "350724",
    "label": "Randuagung",
    "value": "Randuagung"
  },
  {
    "id": "3507251005",
    "district_id": "350725",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3507251010",
    "district_id": "350725",
    "label": "Lawang",
    "value": "Lawang"
  },
  {
    "id": "3507252001",
    "district_id": "350725",
    "label": "Sidoluhur",
    "value": "Sidoluhur"
  },
  {
    "id": "3507252002",
    "district_id": "350725",
    "label": "Srigading",
    "value": "Srigading"
  },
  {
    "id": "3507252003",
    "district_id": "350725",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3507252004",
    "district_id": "350725",
    "label": "Bedali",
    "value": "Bedali"
  },
  {
    "id": "3507252006",
    "district_id": "350725",
    "label": "Mulyoarjo",
    "value": "Mulyoarjo"
  },
  {
    "id": "3507252007",
    "district_id": "350725",
    "label": "Sumberngepoh",
    "value": "Sumberngepoh"
  },
  {
    "id": "3507252008",
    "district_id": "350725",
    "label": "Sumberporong",
    "value": "Sumberporong"
  },
  {
    "id": "3507252009",
    "district_id": "350725",
    "label": "Turirejo",
    "value": "Turirejo"
  },
  {
    "id": "3507252011",
    "district_id": "350725",
    "label": "Ketindan",
    "value": "Ketindan"
  },
  {
    "id": "3507252012",
    "district_id": "350725",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3507262001",
    "district_id": "350726",
    "label": "Bendosari",
    "value": "Bendosari"
  },
  {
    "id": "3507262002",
    "district_id": "350726",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3507262003",
    "district_id": "350726",
    "label": "Pujon Kidul",
    "value": "Pujon Kidul"
  },
  {
    "id": "3507262004",
    "district_id": "350726",
    "label": "Pandesari",
    "value": "Pandesari"
  },
  {
    "id": "3507262005",
    "district_id": "350726",
    "label": "Pujon Lor",
    "value": "Pujon Lor"
  },
  {
    "id": "3507262006",
    "district_id": "350726",
    "label": "Ngroto",
    "value": "Ngroto"
  },
  {
    "id": "3507262007",
    "district_id": "350726",
    "label": "Ngabab",
    "value": "Ngabab"
  },
  {
    "id": "3507262008",
    "district_id": "350726",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3507262009",
    "district_id": "350726",
    "label": "Madiredo",
    "value": "Madiredo"
  },
  {
    "id": "3507262010",
    "district_id": "350726",
    "label": "Wiyurejo",
    "value": "Wiyurejo"
  },
  {
    "id": "3507272001",
    "district_id": "350727",
    "label": "Pagersari",
    "value": "Pagersari"
  },
  {
    "id": "3507272002",
    "district_id": "350727",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3507272003",
    "district_id": "350727",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3507272004",
    "district_id": "350727",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3507272005",
    "district_id": "350727",
    "label": "Ngantru",
    "value": "Ngantru"
  },
  {
    "id": "3507272006",
    "district_id": "350727",
    "label": "Banturejo",
    "value": "Banturejo"
  },
  {
    "id": "3507272007",
    "district_id": "350727",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3507272008",
    "district_id": "350727",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3507272009",
    "district_id": "350727",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3507272010",
    "district_id": "350727",
    "label": "Kaumrejo",
    "value": "Kaumrejo"
  },
  {
    "id": "3507272011",
    "district_id": "350727",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3507272012",
    "district_id": "350727",
    "label": "Waturejo",
    "value": "Waturejo"
  },
  {
    "id": "3507272013",
    "district_id": "350727",
    "label": "Jombok",
    "value": "Jombok"
  },
  {
    "id": "3507282001",
    "district_id": "350728",
    "label": "Pondokagung",
    "value": "Pondokagung"
  },
  {
    "id": "3507282002",
    "district_id": "350728",
    "label": "Bayem",
    "value": "Bayem"
  },
  {
    "id": "3507282003",
    "district_id": "350728",
    "label": "Pait",
    "value": "Pait"
  },
  {
    "id": "3507282004",
    "district_id": "350728",
    "label": "Wonoagung",
    "value": "Wonoagung"
  },
  {
    "id": "3507282005",
    "district_id": "350728",
    "label": "Kasembon",
    "value": "Kasembon"
  },
  {
    "id": "3507282006",
    "district_id": "350728",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3507292001",
    "district_id": "350729",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3507292002",
    "district_id": "350729",
    "label": "Gajahrejo",
    "value": "Gajahrejo"
  },
  {
    "id": "3507292003",
    "district_id": "350729",
    "label": "Sindurejo",
    "value": "Sindurejo"
  },
  {
    "id": "3507292004",
    "district_id": "350729",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3507292005",
    "district_id": "350729",
    "label": "Segaran",
    "value": "Segaran"
  },
  {
    "id": "3507292006",
    "district_id": "350729",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3507292007",
    "district_id": "350729",
    "label": "Tumpakrejo",
    "value": "Tumpakrejo"
  },
  {
    "id": "3507292008",
    "district_id": "350729",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3507302001",
    "district_id": "350730",
    "label": "Pujiharjo",
    "value": "Pujiharjo"
  },
  {
    "id": "3507302002",
    "district_id": "350730",
    "label": "Sumbertangkil",
    "value": "Sumbertangkil"
  },
  {
    "id": "3507302003",
    "district_id": "350730",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3507302004",
    "district_id": "350730",
    "label": "Jogomulyan",
    "value": "Jogomulyan"
  },
  {
    "id": "3507302005",
    "district_id": "350730",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3507302006",
    "district_id": "350730",
    "label": "Tirtoyudo",
    "value": "Tirtoyudo"
  },
  {
    "id": "3507302007",
    "district_id": "350730",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3507302008",
    "district_id": "350730",
    "label": "Ampelgading",
    "value": "Ampelgading"
  },
  {
    "id": "3507302009",
    "district_id": "350730",
    "label": "Tamankuncaran",
    "value": "Tamankuncaran"
  },
  {
    "id": "3507302010",
    "district_id": "350730",
    "label": "Gadungsari",
    "value": "Gadungsari"
  },
  {
    "id": "3507302011",
    "district_id": "350730",
    "label": "Wonoagung",
    "value": "Wonoagung"
  },
  {
    "id": "3507302012",
    "district_id": "350730",
    "label": "Tamansatriyan",
    "value": "Tamansatriyan"
  },
  {
    "id": "3507302013",
    "district_id": "350730",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3507312001",
    "district_id": "350731",
    "label": "Slorok",
    "value": "Slorok"
  },
  {
    "id": "3507312002",
    "district_id": "350731",
    "label": "Jatikerto",
    "value": "Jatikerto"
  },
  {
    "id": "3507312003",
    "district_id": "350731",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3507312004",
    "district_id": "350731",
    "label": "Kromengan",
    "value": "Kromengan"
  },
  {
    "id": "3507312005",
    "district_id": "350731",
    "label": "Peniwen",
    "value": "Peniwen"
  },
  {
    "id": "3507312006",
    "district_id": "350731",
    "label": "Jambuwer",
    "value": "Jambuwer"
  },
  {
    "id": "3507312007",
    "district_id": "350731",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3507322001",
    "district_id": "350732",
    "label": "Kluwut",
    "value": "Kluwut"
  },
  {
    "id": "3507322002",
    "district_id": "350732",
    "label": "Plandi",
    "value": "Plandi"
  },
  {
    "id": "3507322003",
    "district_id": "350732",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3507322004",
    "district_id": "350732",
    "label": "Kebobang",
    "value": "Kebobang"
  },
  {
    "id": "3507322005",
    "district_id": "350732",
    "label": "Sumbertempur",
    "value": "Sumbertempur"
  },
  {
    "id": "3507322006",
    "district_id": "350732",
    "label": "Sumberdem",
    "value": "Sumberdem"
  },
  {
    "id": "3507322007",
    "district_id": "350732",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3507322008",
    "district_id": "350732",
    "label": "Bangelan",
    "value": "Bangelan"
  },
  {
    "id": "3507332001",
    "district_id": "350733",
    "label": "Clumprit",
    "value": "Clumprit"
  },
  {
    "id": "3507332002",
    "district_id": "350733",
    "label": "Suwaru",
    "value": "Suwaru"
  },
  {
    "id": "3507332003",
    "district_id": "350733",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3507332004",
    "district_id": "350733",
    "label": "Balearjo",
    "value": "Balearjo"
  },
  {
    "id": "3507332005",
    "district_id": "350733",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3507332006",
    "district_id": "350733",
    "label": "Brongkal",
    "value": "Brongkal"
  },
  {
    "id": "3507332007",
    "district_id": "350733",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3507332008",
    "district_id": "350733",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3507332009",
    "district_id": "350733",
    "label": "Karangsuko",
    "value": "Karangsuko"
  },
  {
    "id": "3507332010",
    "district_id": "350733",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3508012001",
    "district_id": "350801",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3508012002",
    "district_id": "350801",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3508012003",
    "district_id": "350801",
    "label": "Purorejo",
    "value": "Purorejo"
  },
  {
    "id": "3508012004",
    "district_id": "350801",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3508012005",
    "district_id": "350801",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3508012006",
    "district_id": "350801",
    "label": "Pundungsari",
    "value": "Pundungsari"
  },
  {
    "id": "3508012007",
    "district_id": "350801",
    "label": "Kaliuling",
    "value": "Kaliuling"
  },
  {
    "id": "3508022001",
    "district_id": "350802",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3508022002",
    "district_id": "350802",
    "label": "Pronojiwo",
    "value": "Pronojiwo"
  },
  {
    "id": "3508022003",
    "district_id": "350802",
    "label": "Tamanayu",
    "value": "Tamanayu"
  },
  {
    "id": "3508022004",
    "district_id": "350802",
    "label": "Sumberurip",
    "value": "Sumberurip"
  },
  {
    "id": "3508022005",
    "district_id": "350802",
    "label": "Oro-oro Ombo",
    "value": "Oro-oro Ombo"
  },
  {
    "id": "3508022006",
    "district_id": "350802",
    "label": "Supiturang",
    "value": "Supiturang"
  },
  {
    "id": "3508032001",
    "district_id": "350803",
    "label": "Jugosari",
    "value": "Jugosari"
  },
  {
    "id": "3508032002",
    "district_id": "350803",
    "label": "Jarit",
    "value": "Jarit"
  },
  {
    "id": "3508032003",
    "district_id": "350803",
    "label": "Candipuro",
    "value": "Candipuro"
  },
  {
    "id": "3508032004",
    "district_id": "350803",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3508032005",
    "district_id": "350803",
    "label": "Sumber Wuluh",
    "value": "Sumber Wuluh"
  },
  {
    "id": "3508032006",
    "district_id": "350803",
    "label": "Sumber Mujur",
    "value": "Sumber Mujur"
  },
  {
    "id": "3508032007",
    "district_id": "350803",
    "label": "Penanggal",
    "value": "Penanggal"
  },
  {
    "id": "3508032008",
    "district_id": "350803",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3508032009",
    "district_id": "350803",
    "label": "Klopo Sawit",
    "value": "Klopo Sawit"
  },
  {
    "id": "3508032010",
    "district_id": "350803",
    "label": "Tumpeng",
    "value": "Tumpeng"
  },
  {
    "id": "3508042001",
    "district_id": "350804",
    "label": "Gondoruso",
    "value": "Gondoruso"
  },
  {
    "id": "3508042002",
    "district_id": "350804",
    "label": "Kalibendo",
    "value": "Kalibendo"
  },
  {
    "id": "3508042003",
    "district_id": "350804",
    "label": "Bades",
    "value": "Bades"
  },
  {
    "id": "3508042004",
    "district_id": "350804",
    "label": "Bago",
    "value": "Bago"
  },
  {
    "id": "3508042005",
    "district_id": "350804",
    "label": "Selok Awar - Awar",
    "value": "Selok Awar - Awar"
  },
  {
    "id": "3508042006",
    "district_id": "350804",
    "label": "Condro",
    "value": "Condro"
  },
  {
    "id": "3508042007",
    "district_id": "350804",
    "label": "Madurejo",
    "value": "Madurejo"
  },
  {
    "id": "3508042008",
    "district_id": "350804",
    "label": "Pasirian",
    "value": "Pasirian"
  },
  {
    "id": "3508042009",
    "district_id": "350804",
    "label": "Sememu",
    "value": "Sememu"
  },
  {
    "id": "3508042010",
    "district_id": "350804",
    "label": "Nguter",
    "value": "Nguter"
  },
  {
    "id": "3508042011",
    "district_id": "350804",
    "label": "Selokanyar",
    "value": "Selokanyar"
  },
  {
    "id": "3508052001",
    "district_id": "350805",
    "label": "Pandanwangi",
    "value": "Pandanwangi"
  },
  {
    "id": "3508052002",
    "district_id": "350805",
    "label": "Sumberjati",
    "value": "Sumberjati"
  },
  {
    "id": "3508052003",
    "district_id": "350805",
    "label": "Tempeh Kidul",
    "value": "Tempeh Kidul"
  },
  {
    "id": "3508052004",
    "district_id": "350805",
    "label": "Lempeni",
    "value": "Lempeni"
  },
  {
    "id": "3508052005",
    "district_id": "350805",
    "label": "Tempeh Tengah",
    "value": "Tempeh Tengah"
  },
  {
    "id": "3508052006",
    "district_id": "350805",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3508052007",
    "district_id": "350805",
    "label": "Tempeh Lor",
    "value": "Tempeh Lor"
  },
  {
    "id": "3508052008",
    "district_id": "350805",
    "label": "Besuk",
    "value": "Besuk"
  },
  {
    "id": "3508052009",
    "district_id": "350805",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3508052010",
    "district_id": "350805",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "3508052011",
    "district_id": "350805",
    "label": "Gesang",
    "value": "Gesang"
  },
  {
    "id": "3508052012",
    "district_id": "350805",
    "label": "Jokarto",
    "value": "Jokarto"
  },
  {
    "id": "3508052013",
    "district_id": "350805",
    "label": "Pandanarum",
    "value": "Pandanarum"
  },
  {
    "id": "3508062001",
    "district_id": "350806",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3508062002",
    "district_id": "350806",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3508062003",
    "district_id": "350806",
    "label": "Jatigono",
    "value": "Jatigono"
  },
  {
    "id": "3508062004",
    "district_id": "350806",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3508062005",
    "district_id": "350806",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3508062006",
    "district_id": "350806",
    "label": "Kunir Kidul",
    "value": "Kunir Kidul"
  },
  {
    "id": "3508062007",
    "district_id": "350806",
    "label": "Kunir Lor",
    "value": "Kunir Lor"
  },
  {
    "id": "3508062008",
    "district_id": "350806",
    "label": "Kedungmoro",
    "value": "Kedungmoro"
  },
  {
    "id": "3508062009",
    "district_id": "350806",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3508062010",
    "district_id": "350806",
    "label": "Kabuaran",
    "value": "Kabuaran"
  },
  {
    "id": "3508062011",
    "district_id": "350806",
    "label": "Dorogowok",
    "value": "Dorogowok"
  },
  {
    "id": "3508072001",
    "district_id": "350807",
    "label": "Darungan",
    "value": "Darungan"
  },
  {
    "id": "3508072002",
    "district_id": "350807",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3508072003",
    "district_id": "350807",
    "label": "Wotgalih",
    "value": "Wotgalih"
  },
  {
    "id": "3508072004",
    "district_id": "350807",
    "label": "Tunjungrejo",
    "value": "Tunjungrejo"
  },
  {
    "id": "3508072005",
    "district_id": "350807",
    "label": "Yosowilangun Kidul",
    "value": "Yosowilangun Kidul"
  },
  {
    "id": "3508072006",
    "district_id": "350807",
    "label": "Yosowilangun Lor",
    "value": "Yosowilangun Lor"
  },
  {
    "id": "3508072007",
    "district_id": "350807",
    "label": "Krai",
    "value": "Krai"
  },
  {
    "id": "3508072008",
    "district_id": "350807",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3508072009",
    "district_id": "350807",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3508072010",
    "district_id": "350807",
    "label": "Munder",
    "value": "Munder"
  },
  {
    "id": "3508072011",
    "district_id": "350807",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3508072012",
    "district_id": "350807",
    "label": "Kalipepe",
    "value": "Kalipepe"
  },
  {
    "id": "3508082001",
    "district_id": "350808",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  {
    "id": "3508082002",
    "district_id": "350808",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3508082003",
    "district_id": "350808",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3508082004",
    "district_id": "350808",
    "label": "Rowokangkung",
    "value": "Rowokangkung"
  },
  {
    "id": "3508082005",
    "district_id": "350808",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3508082006",
    "district_id": "350808",
    "label": "Dawuhan Wetan",
    "value": "Dawuhan Wetan"
  },
  {
    "id": "3508082007",
    "district_id": "350808",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3508092001",
    "district_id": "350809",
    "label": "Wonogriyo",
    "value": "Wonogriyo"
  },
  {
    "id": "3508092002",
    "district_id": "350809",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3508092003",
    "district_id": "350809",
    "label": "Mangunsari",
    "value": "Mangunsari"
  },
  {
    "id": "3508092004",
    "district_id": "350809",
    "label": "Tekung",
    "value": "Tekung"
  },
  {
    "id": "3508092005",
    "district_id": "350809",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3508092006",
    "district_id": "350809",
    "label": "Tukum",
    "value": "Tukum"
  },
  {
    "id": "3508092007",
    "district_id": "350809",
    "label": "Karangbendo",
    "value": "Karangbendo"
  },
  {
    "id": "3508092008",
    "district_id": "350809",
    "label": "Klampokarum",
    "value": "Klampokarum"
  },
  {
    "id": "3508101003",
    "district_id": "350810",
    "label": "Citrodiwangsan",
    "value": "Citrodiwangsan"
  },
  {
    "id": "3508101004",
    "district_id": "350810",
    "label": "Ditotrunan",
    "value": "Ditotrunan"
  },
  {
    "id": "3508101005",
    "district_id": "350810",
    "label": "Jogotrunan",
    "value": "Jogotrunan"
  },
  {
    "id": "3508101009",
    "district_id": "350810",
    "label": "Jogoyudan",
    "value": "Jogoyudan"
  },
  {
    "id": "3508101010",
    "district_id": "350810",
    "label": "Rogotrunan",
    "value": "Rogotrunan"
  },
  {
    "id": "3508101011",
    "district_id": "350810",
    "label": "Tompokersan",
    "value": "Tompokersan"
  },
  {
    "id": "3508101012",
    "district_id": "350810",
    "label": "Kepuharjo",
    "value": "Kepuharjo"
  },
  {
    "id": "3508102001",
    "district_id": "350810",
    "label": "Banjarwaru",
    "value": "Banjarwaru"
  },
  {
    "id": "3508102002",
    "district_id": "350810",
    "label": "Labruk Lor",
    "value": "Labruk Lor"
  },
  {
    "id": "3508102006",
    "district_id": "350810",
    "label": "Denok",
    "value": "Denok"
  },
  {
    "id": "3508102007",
    "district_id": "350810",
    "label": "Blukon",
    "value": "Blukon"
  },
  {
    "id": "3508102008",
    "district_id": "350810",
    "label": "Boreng",
    "value": "Boreng"
  },
  {
    "id": "3508112001",
    "district_id": "350811",
    "label": "Pasrujambe",
    "value": "Pasrujambe"
  },
  {
    "id": "3508112002",
    "district_id": "350811",
    "label": "Jambekumbu",
    "value": "Jambekumbu"
  },
  {
    "id": "3508112003",
    "district_id": "350811",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3508112004",
    "district_id": "350811",
    "label": "Jambearum",
    "value": "Jambearum"
  },
  {
    "id": "3508112005",
    "district_id": "350811",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3508112006",
    "district_id": "350811",
    "label": "Pagowan",
    "value": "Pagowan"
  },
  {
    "id": "3508112007",
    "district_id": "350811",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3508122001",
    "district_id": "350812",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3508122002",
    "district_id": "350812",
    "label": "Sarikemuning",
    "value": "Sarikemuning"
  },
  {
    "id": "3508122003",
    "district_id": "350812",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3508122004",
    "district_id": "350812",
    "label": "Senduro",
    "value": "Senduro"
  },
  {
    "id": "3508122005",
    "district_id": "350812",
    "label": "Burno",
    "value": "Burno"
  },
  {
    "id": "3508122006",
    "district_id": "350812",
    "label": "Kandangtepus",
    "value": "Kandangtepus"
  },
  {
    "id": "3508122007",
    "district_id": "350812",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3508122008",
    "district_id": "350812",
    "label": "Bedayu",
    "value": "Bedayu"
  },
  {
    "id": "3508122009",
    "district_id": "350812",
    "label": "Bedayutalang",
    "value": "Bedayutalang"
  },
  {
    "id": "3508122010",
    "district_id": "350812",
    "label": "Wonocepokoayu",
    "value": "Wonocepokoayu"
  },
  {
    "id": "3508122011",
    "district_id": "350812",
    "label": "Argosari",
    "value": "Argosari"
  },
  {
    "id": "3508122012",
    "district_id": "350812",
    "label": "Ranupani",
    "value": "Ranupani"
  },
  {
    "id": "3508132001",
    "district_id": "350813",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3508132002",
    "district_id": "350813",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3508132003",
    "district_id": "350813",
    "label": "Kenongo",
    "value": "Kenongo"
  },
  {
    "id": "3508132004",
    "district_id": "350813",
    "label": "Gucialit",
    "value": "Gucialit"
  },
  {
    "id": "3508132005",
    "district_id": "350813",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3508132006",
    "district_id": "350813",
    "label": "Kertowono",
    "value": "Kertowono"
  },
  {
    "id": "3508132007",
    "district_id": "350813",
    "label": "Tunjung",
    "value": "Tunjung"
  },
  {
    "id": "3508132008",
    "district_id": "350813",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3508132009",
    "district_id": "350813",
    "label": "Sombo",
    "value": "Sombo"
  },
  {
    "id": "3508142001",
    "district_id": "350814",
    "label": "Barat",
    "value": "Barat"
  },
  {
    "id": "3508142002",
    "district_id": "350814",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3508142003",
    "district_id": "350814",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3508142004",
    "district_id": "350814",
    "label": "Bodang",
    "value": "Bodang"
  },
  {
    "id": "3508142005",
    "district_id": "350814",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3508142006",
    "district_id": "350814",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "3508142007",
    "district_id": "350814",
    "label": "Kalisemut",
    "value": "Kalisemut"
  },
  {
    "id": "3508142008",
    "district_id": "350814",
    "label": "Merakan",
    "value": "Merakan"
  },
  {
    "id": "3508142009",
    "district_id": "350814",
    "label": "Tanggung",
    "value": "Tanggung"
  },
  {
    "id": "3508152001",
    "district_id": "350815",
    "label": "Klanting",
    "value": "Klanting"
  },
  {
    "id": "3508152002",
    "district_id": "350815",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3508152003",
    "district_id": "350815",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3508152004",
    "district_id": "350815",
    "label": "Dawuhan Lor",
    "value": "Dawuhan Lor"
  },
  {
    "id": "3508152005",
    "district_id": "350815",
    "label": "Kutorenon",
    "value": "Kutorenon"
  },
  {
    "id": "3508152006",
    "district_id": "350815",
    "label": "Selokbesuki",
    "value": "Selokbesuki"
  },
  {
    "id": "3508152007",
    "district_id": "350815",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3508152008",
    "district_id": "350815",
    "label": "Uranggantung",
    "value": "Uranggantung"
  },
  {
    "id": "3508152009",
    "district_id": "350815",
    "label": "Selokgondang",
    "value": "Selokgondang"
  },
  {
    "id": "3508152010",
    "district_id": "350815",
    "label": "Bondoyudo",
    "value": "Bondoyudo"
  },
  {
    "id": "3508162001",
    "district_id": "350816",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3508162002",
    "district_id": "350816",
    "label": "Krasak",
    "value": "Krasak"
  },
  {
    "id": "3508162003",
    "district_id": "350816",
    "label": "Kedungjajang",
    "value": "Kedungjajang"
  },
  {
    "id": "3508162004",
    "district_id": "350816",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3508162005",
    "district_id": "350816",
    "label": "Umbul",
    "value": "Umbul"
  },
  {
    "id": "3508162006",
    "district_id": "350816",
    "label": "Curahpetung",
    "value": "Curahpetung"
  },
  {
    "id": "3508162007",
    "district_id": "350816",
    "label": "Grobogan",
    "value": "Grobogan"
  },
  {
    "id": "3508162008",
    "district_id": "350816",
    "label": "Bence",
    "value": "Bence"
  },
  {
    "id": "3508162009",
    "district_id": "350816",
    "label": "jatisari",
    "value": "jatisari"
  },
  {
    "id": "3508162010",
    "district_id": "350816",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3508162011",
    "district_id": "350816",
    "label": "Bandaran",
    "value": "Bandaran"
  },
  {
    "id": "3508162012",
    "district_id": "350816",
    "label": "Sawaran Kulon",
    "value": "Sawaran Kulon"
  },
  {
    "id": "3508172001",
    "district_id": "350817",
    "label": "Banyuputih Kidul",
    "value": "Banyuputih Kidul"
  },
  {
    "id": "3508172002",
    "district_id": "350817",
    "label": "Rojopolo",
    "value": "Rojopolo"
  },
  {
    "id": "3508172003",
    "district_id": "350817",
    "label": "Kaliboto Kidul",
    "value": "Kaliboto Kidul"
  },
  {
    "id": "3508172004",
    "district_id": "350817",
    "label": "Kaliboto Lor",
    "value": "Kaliboto Lor"
  },
  {
    "id": "3508172005",
    "district_id": "350817",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3508172006",
    "district_id": "350817",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "3508182001",
    "district_id": "350818",
    "label": "Banyuputih Lor",
    "value": "Banyuputih Lor"
  },
  {
    "id": "3508182002",
    "district_id": "350818",
    "label": "Kalidilem",
    "value": "Kalidilem"
  },
  {
    "id": "3508182003",
    "district_id": "350818",
    "label": "Tunjung",
    "value": "Tunjung"
  },
  {
    "id": "3508182004",
    "district_id": "350818",
    "label": "Gedangmas",
    "value": "Gedangmas"
  },
  {
    "id": "3508182005",
    "district_id": "350818",
    "label": "Kalipenggung",
    "value": "Kalipenggung"
  },
  {
    "id": "3508182006",
    "district_id": "350818",
    "label": "Ranulogong",
    "value": "Ranulogong"
  },
  {
    "id": "3508182007",
    "district_id": "350818",
    "label": "Randuagung",
    "value": "Randuagung"
  },
  {
    "id": "3508182008",
    "district_id": "350818",
    "label": "Ledoktempuro",
    "value": "Ledoktempuro"
  },
  {
    "id": "3508182009",
    "district_id": "350818",
    "label": "Pajarakan",
    "value": "Pajarakan"
  },
  {
    "id": "3508182010",
    "district_id": "350818",
    "label": "Buwek",
    "value": "Buwek"
  },
  {
    "id": "3508182011",
    "district_id": "350818",
    "label": "Ranuwurung",
    "value": "Ranuwurung"
  },
  {
    "id": "3508182012",
    "district_id": "350818",
    "label": "Salak",
    "value": "Salak"
  },
  {
    "id": "3508192001",
    "district_id": "350819",
    "label": "Kebonan",
    "value": "Kebonan"
  },
  {
    "id": "3508192002",
    "district_id": "350819",
    "label": "Kudus",
    "value": "Kudus"
  },
  {
    "id": "3508192003",
    "district_id": "350819",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3508192004",
    "district_id": "350819",
    "label": "Sumberwringin",
    "value": "Sumberwringin"
  },
  {
    "id": "3508192005",
    "district_id": "350819",
    "label": "Papringan",
    "value": "Papringan"
  },
  {
    "id": "3508192006",
    "district_id": "350819",
    "label": "Ranupakis",
    "value": "Ranupakis"
  },
  {
    "id": "3508192007",
    "district_id": "350819",
    "label": "Tegalrandu",
    "value": "Tegalrandu"
  },
  {
    "id": "3508192008",
    "district_id": "350819",
    "label": "Klakah",
    "value": "Klakah"
  },
  {
    "id": "3508192009",
    "district_id": "350819",
    "label": "Mlawang",
    "value": "Mlawang"
  },
  {
    "id": "3508192010",
    "district_id": "350819",
    "label": "Sruni",
    "value": "Sruni"
  },
  {
    "id": "3508192011",
    "district_id": "350819",
    "label": "Tegalciut",
    "value": "Tegalciut"
  },
  {
    "id": "3508192012",
    "district_id": "350819",
    "label": "Sawaran Lor",
    "value": "Sawaran Lor"
  },
  {
    "id": "3508202001",
    "district_id": "350820",
    "label": "Jenggrong",
    "value": "Jenggrong"
  },
  {
    "id": "3508202002",
    "district_id": "350820",
    "label": "Meninjo",
    "value": "Meninjo"
  },
  {
    "id": "3508202003",
    "district_id": "350820",
    "label": "Tegalbangsri",
    "value": "Tegalbangsri"
  },
  {
    "id": "3508202004",
    "district_id": "350820",
    "label": "Sumberpetung",
    "value": "Sumberpetung"
  },
  {
    "id": "3508202005",
    "district_id": "350820",
    "label": "Alun-alun",
    "value": "Alun-alun"
  },
  {
    "id": "3508202006",
    "district_id": "350820",
    "label": "Ranubedali",
    "value": "Ranubedali"
  },
  {
    "id": "3508202007",
    "district_id": "350820",
    "label": "Ranuyoso",
    "value": "Ranuyoso"
  },
  {
    "id": "3508202008",
    "district_id": "350820",
    "label": "Wonoayu",
    "value": "Wonoayu"
  },
  {
    "id": "3508202009",
    "district_id": "350820",
    "label": "Penawungan",
    "value": "Penawungan"
  },
  {
    "id": "3508202010",
    "district_id": "350820",
    "label": "Wates Kulon",
    "value": "Wates Kulon"
  },
  {
    "id": "3508202011",
    "district_id": "350820",
    "label": "Wates Wetan",
    "value": "Wates Wetan"
  },
  {
    "id": "3508212001",
    "district_id": "350821",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3508212002",
    "district_id": "350821",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3508212003",
    "district_id": "350821",
    "label": "Grati",
    "value": "Grati"
  },
  {
    "id": "3508212004",
    "district_id": "350821",
    "label": "Labruk Kidul",
    "value": "Labruk Kidul"
  },
  {
    "id": "3508212005",
    "district_id": "350821",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3508212006",
    "district_id": "350821",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3508212007",
    "district_id": "350821",
    "label": "Purwosono",
    "value": "Purwosono"
  },
  {
    "id": "3508212008",
    "district_id": "350821",
    "label": "Petahunan",
    "value": "Petahunan"
  },
  {
    "id": "3509012001",
    "district_id": "350901",
    "label": "Padomasan",
    "value": "Padomasan"
  },
  {
    "id": "3509012002",
    "district_id": "350901",
    "label": "Keting",
    "value": "Keting"
  },
  {
    "id": "3509012003",
    "district_id": "350901",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "3509012004",
    "district_id": "350901",
    "label": "Ngampelrejo",
    "value": "Ngampelrejo"
  },
  {
    "id": "3509012005",
    "district_id": "350901",
    "label": "Wringinagung",
    "value": "Wringinagung"
  },
  {
    "id": "3509012006",
    "district_id": "350901",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "3509022001",
    "district_id": "350902",
    "label": "Cakru",
    "value": "Cakru"
  },
  {
    "id": "3509022002",
    "district_id": "350902",
    "label": "Paseban",
    "value": "Paseban"
  },
  {
    "id": "3509022003",
    "district_id": "350902",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3509022004",
    "district_id": "350902",
    "label": "Kencong",
    "value": "Kencong"
  },
  {
    "id": "3509022005",
    "district_id": "350902",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3509032001",
    "district_id": "350903",
    "label": "Jamintoro",
    "value": "Jamintoro"
  },
  {
    "id": "3509032002",
    "district_id": "350903",
    "label": "Jatiroto",
    "value": "Jatiroto"
  },
  {
    "id": "3509032003",
    "district_id": "350903",
    "label": "Kaliglagah",
    "value": "Kaliglagah"
  },
  {
    "id": "3509032004",
    "district_id": "350903",
    "label": "Jambesari",
    "value": "Jambesari"
  },
  {
    "id": "3509032005",
    "district_id": "350903",
    "label": "Yosorati",
    "value": "Yosorati"
  },
  {
    "id": "3509032006",
    "district_id": "350903",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3509032007",
    "district_id": "350903",
    "label": "Gelang",
    "value": "Gelang"
  },
  {
    "id": "3509032008",
    "district_id": "350903",
    "label": "Rowotengah",
    "value": "Rowotengah"
  },
  {
    "id": "3509032009",
    "district_id": "350903",
    "label": "Pringgowirawan",
    "value": "Pringgowirawan"
  },
  {
    "id": "3509032010",
    "district_id": "350903",
    "label": "Karangbayat",
    "value": "Karangbayat"
  },
  {
    "id": "3509042001",
    "district_id": "350904",
    "label": "Kepanjen",
    "value": "Kepanjen"
  },
  {
    "id": "3509042002",
    "district_id": "350904",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "3509042003",
    "district_id": "350904",
    "label": "Gumukmas",
    "value": "Gumukmas"
  },
  {
    "id": "3509042004",
    "district_id": "350904",
    "label": "Menampu",
    "value": "Menampu"
  },
  {
    "id": "3509042005",
    "district_id": "350904",
    "label": "Tembokrejo",
    "value": "Tembokrejo"
  },
  {
    "id": "3509042006",
    "district_id": "350904",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "3509042007",
    "district_id": "350904",
    "label": "Bagorejo",
    "value": "Bagorejo"
  },
  {
    "id": "3509042008",
    "district_id": "350904",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3509052001",
    "district_id": "350905",
    "label": "Sukoreno",
    "value": "Sukoreno"
  },
  {
    "id": "3509052002",
    "district_id": "350905",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3509052003",
    "district_id": "350905",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3509052004",
    "district_id": "350905",
    "label": "Gadingrejo",
    "value": "Gadingrejo"
  },
  {
    "id": "3509052005",
    "district_id": "350905",
    "label": "Umbulrejo",
    "value": "Umbulrejo"
  },
  {
    "id": "3509052006",
    "district_id": "350905",
    "label": "Umbulsari",
    "value": "Umbulsari"
  },
  {
    "id": "3509052007",
    "district_id": "350905",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3509052008",
    "district_id": "350905",
    "label": "Tegalwangi",
    "value": "Tegalwangi"
  },
  {
    "id": "3509052009",
    "district_id": "350905",
    "label": "Paleran",
    "value": "Paleran"
  },
  {
    "id": "3509052010",
    "district_id": "350905",
    "label": "Mundurejo",
    "value": "Mundurejo"
  },
  {
    "id": "3509062001",
    "district_id": "350906",
    "label": "Tanggulkulon",
    "value": "Tanggulkulon"
  },
  {
    "id": "3509062002",
    "district_id": "350906",
    "label": "Tanggulwetan",
    "value": "Tanggulwetan"
  },
  {
    "id": "3509062003",
    "district_id": "350906",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3509062004",
    "district_id": "350906",
    "label": "Darungan",
    "value": "Darungan"
  },
  {
    "id": "3509062005",
    "district_id": "350906",
    "label": "Manggisan",
    "value": "Manggisan"
  },
  {
    "id": "3509062006",
    "district_id": "350906",
    "label": "Selodakon",
    "value": "Selodakon"
  },
  {
    "id": "3509062007",
    "district_id": "350906",
    "label": "Kramat Sukoharjo",
    "value": "Kramat Sukoharjo"
  },
  {
    "id": "3509062008",
    "district_id": "350906",
    "label": "Klatakan",
    "value": "Klatakan"
  },
  {
    "id": "3509072001",
    "district_id": "350907",
    "label": "Pondokjoyo",
    "value": "Pondokjoyo"
  },
  {
    "id": "3509072002",
    "district_id": "350907",
    "label": "Pondokdalem",
    "value": "Pondokdalem"
  },
  {
    "id": "3509072003",
    "district_id": "350907",
    "label": "Rejoagung",
    "value": "Rejoagung"
  },
  {
    "id": "3509072004",
    "district_id": "350907",
    "label": "Semboro",
    "value": "Semboro"
  },
  {
    "id": "3509072005",
    "district_id": "350907",
    "label": "Sidomekar",
    "value": "Sidomekar"
  },
  {
    "id": "3509072006",
    "district_id": "350907",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3509082001",
    "district_id": "350908",
    "label": "Mlokorejo",
    "value": "Mlokorejo"
  },
  {
    "id": "3509082002",
    "district_id": "350908",
    "label": "Mojomulyo",
    "value": "Mojomulyo"
  },
  {
    "id": "3509082003",
    "district_id": "350908",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3509082004",
    "district_id": "350908",
    "label": "Pugerkulon",
    "value": "Pugerkulon"
  },
  {
    "id": "3509082005",
    "district_id": "350908",
    "label": "Wringintelu",
    "value": "Wringintelu"
  },
  {
    "id": "3509082006",
    "district_id": "350908",
    "label": "Kasiyan",
    "value": "Kasiyan"
  },
  {
    "id": "3509082007",
    "district_id": "350908",
    "label": "Bagon",
    "value": "Bagon"
  },
  {
    "id": "3509082008",
    "district_id": "350908",
    "label": "Kasiyan Timur",
    "value": "Kasiyan Timur"
  },
  {
    "id": "3509082009",
    "district_id": "350908",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3509082010",
    "district_id": "350908",
    "label": "Jambearum",
    "value": "Jambearum"
  },
  {
    "id": "3509082011",
    "district_id": "350908",
    "label": "Grenden",
    "value": "Grenden"
  },
  {
    "id": "3509082012",
    "district_id": "350908",
    "label": "Pugerwetan",
    "value": "Pugerwetan"
  },
  {
    "id": "3509092001",
    "district_id": "350909",
    "label": "Curahkalong",
    "value": "Curahkalong"
  },
  {
    "id": "3509092002",
    "district_id": "350909",
    "label": "Gambirono",
    "value": "Gambirono"
  },
  {
    "id": "3509092003",
    "district_id": "350909",
    "label": "Bangsalsari",
    "value": "Bangsalsari"
  },
  {
    "id": "3509092004",
    "district_id": "350909",
    "label": "Tugusari",
    "value": "Tugusari"
  },
  {
    "id": "3509092005",
    "district_id": "350909",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3509092006",
    "district_id": "350909",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3509092007",
    "district_id": "350909",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "3509092008",
    "district_id": "350909",
    "label": "Tisnogambar",
    "value": "Tisnogambar"
  },
  {
    "id": "3509092009",
    "district_id": "350909",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3509092010",
    "district_id": "350909",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3509092011",
    "district_id": "350909",
    "label": "Badean",
    "value": "Badean"
  },
  {
    "id": "3509102001",
    "district_id": "350910",
    "label": "Karangduren",
    "value": "Karangduren"
  },
  {
    "id": "3509102002",
    "district_id": "350910",
    "label": "Karang Semanding",
    "value": "Karang Semanding"
  },
  {
    "id": "3509102003",
    "district_id": "350910",
    "label": "Tutul",
    "value": "Tutul"
  },
  {
    "id": "3509102004",
    "district_id": "350910",
    "label": "Balungkulon",
    "value": "Balungkulon"
  },
  {
    "id": "3509102005",
    "district_id": "350910",
    "label": "Balunglor",
    "value": "Balunglor"
  },
  {
    "id": "3509102006",
    "district_id": "350910",
    "label": "Balungkidul",
    "value": "Balungkidul"
  },
  {
    "id": "3509102007",
    "district_id": "350910",
    "label": "Curahlele",
    "value": "Curahlele"
  },
  {
    "id": "3509102008",
    "district_id": "350910",
    "label": "Gumelar",
    "value": "Gumelar"
  },
  {
    "id": "3509112001",
    "district_id": "350911",
    "label": "Lojejer",
    "value": "Lojejer"
  },
  {
    "id": "3509112002",
    "district_id": "350911",
    "label": "Ampel",
    "value": "Ampel"
  },
  {
    "id": "3509112003",
    "district_id": "350911",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3509112004",
    "district_id": "350911",
    "label": "Dukuhdempok",
    "value": "Dukuhdempok"
  },
  {
    "id": "3509112005",
    "district_id": "350911",
    "label": "Glundengan",
    "value": "Glundengan"
  },
  {
    "id": "3509112006",
    "district_id": "350911",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3509112007",
    "district_id": "350911",
    "label": "Kesilir",
    "value": "Kesilir"
  },
  {
    "id": "3509122001",
    "district_id": "350912",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3509122002",
    "district_id": "350912",
    "label": "Sabrang",
    "value": "Sabrang"
  },
  {
    "id": "3509122003",
    "district_id": "350912",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3509122004",
    "district_id": "350912",
    "label": "Ambulu",
    "value": "Ambulu"
  },
  {
    "id": "3509122005",
    "district_id": "350912",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3509122006",
    "district_id": "350912",
    "label": "Andongsari",
    "value": "Andongsari"
  },
  {
    "id": "3509122007",
    "district_id": "350912",
    "label": "Pontang",
    "value": "Pontang"
  },
  {
    "id": "3509132001",
    "district_id": "350913",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  {
    "id": "3509132002",
    "district_id": "350913",
    "label": "Curahmalang",
    "value": "Curahmalang"
  },
  {
    "id": "3509132003",
    "district_id": "350913",
    "label": "Rowotamtu",
    "value": "Rowotamtu"
  },
  {
    "id": "3509132004",
    "district_id": "350913",
    "label": "Kaliwining",
    "value": "Kaliwining"
  },
  {
    "id": "3509132005",
    "district_id": "350913",
    "label": "Pecoro",
    "value": "Pecoro"
  },
  {
    "id": "3509132006",
    "district_id": "350913",
    "label": "Rambipuji",
    "value": "Rambipuji"
  },
  {
    "id": "3509132007",
    "district_id": "350913",
    "label": "Gugut",
    "value": "Gugut"
  },
  {
    "id": "3509132008",
    "district_id": "350913",
    "label": "Rambigundam",
    "value": "Rambigundam"
  },
  {
    "id": "3509142001",
    "district_id": "350914",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3509142002",
    "district_id": "350914",
    "label": "Kemuning Sari Lor",
    "value": "Kemuning Sari Lor"
  },
  {
    "id": "3509142003",
    "district_id": "350914",
    "label": "Panti",
    "value": "Panti"
  },
  {
    "id": "3509142004",
    "district_id": "350914",
    "label": "Glagahwero",
    "value": "Glagahwero"
  },
  {
    "id": "3509142005",
    "district_id": "350914",
    "label": "Suci",
    "value": "Suci"
  },
  {
    "id": "3509142006",
    "district_id": "350914",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3509142007",
    "district_id": "350914",
    "label": "Serut",
    "value": "Serut"
  },
  {
    "id": "3509152001",
    "district_id": "350915",
    "label": "Jubung",
    "value": "Jubung"
  },
  {
    "id": "3509152002",
    "district_id": "350915",
    "label": "Dukuhmencek",
    "value": "Dukuhmencek"
  },
  {
    "id": "3509152003",
    "district_id": "350915",
    "label": "Sukorambi",
    "value": "Sukorambi"
  },
  {
    "id": "3509152004",
    "district_id": "350915",
    "label": "Karangpring",
    "value": "Karangpring"
  },
  {
    "id": "3509152005",
    "district_id": "350915",
    "label": "Klungkung",
    "value": "Klungkung"
  },
  {
    "id": "3509162001",
    "district_id": "350916",
    "label": "Kemuningsarikidul",
    "value": "Kemuningsarikidul"
  },
  {
    "id": "3509162002",
    "district_id": "350916",
    "label": "Wonojati",
    "value": "Wonojati"
  },
  {
    "id": "3509162003",
    "district_id": "350916",
    "label": "Jenggawah",
    "value": "Jenggawah"
  },
  {
    "id": "3509162004",
    "district_id": "350916",
    "label": "Kertonegoro",
    "value": "Kertonegoro"
  },
  {
    "id": "3509162005",
    "district_id": "350916",
    "label": "Sruni",
    "value": "Sruni"
  },
  {
    "id": "3509162006",
    "district_id": "350916",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3509162007",
    "district_id": "350916",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3509162008",
    "district_id": "350916",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3509172001",
    "district_id": "350917",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "3509172002",
    "district_id": "350917",
    "label": "Mangaran",
    "value": "Mangaran"
  },
  {
    "id": "3509172003",
    "district_id": "350917",
    "label": "Pancakarya",
    "value": "Pancakarya"
  },
  {
    "id": "3509172004",
    "district_id": "350917",
    "label": "Ajung",
    "value": "Ajung"
  },
  {
    "id": "3509172005",
    "district_id": "350917",
    "label": "Klompangan",
    "value": "Klompangan"
  },
  {
    "id": "3509172006",
    "district_id": "350917",
    "label": "Wirowongso",
    "value": "Wirowongso"
  },
  {
    "id": "3509172007",
    "district_id": "350917",
    "label": "Rowoindah",
    "value": "Rowoindah"
  },
  {
    "id": "3509182001",
    "district_id": "350918",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3509182002",
    "district_id": "350918",
    "label": "Tempurejo",
    "value": "Tempurejo"
  },
  {
    "id": "3509182003",
    "district_id": "350918",
    "label": "Andongrejo",
    "value": "Andongrejo"
  },
  {
    "id": "3509182004",
    "district_id": "350918",
    "label": "Pondokrejo",
    "value": "Pondokrejo"
  },
  {
    "id": "3509182005",
    "district_id": "350918",
    "label": "Wonoasri",
    "value": "Wonoasri"
  },
  {
    "id": "3509182006",
    "district_id": "350918",
    "label": "Curahnongko",
    "value": "Curahnongko"
  },
  {
    "id": "3509182007",
    "district_id": "350918",
    "label": "Curahtakir",
    "value": "Curahtakir"
  },
  {
    "id": "3509182008",
    "district_id": "350918",
    "label": "Sanenrejo",
    "value": "Sanenrejo"
  },
  {
    "id": "3509191001",
    "district_id": "350919",
    "label": "Mangli",
    "value": "Mangli"
  },
  {
    "id": "3509191002",
    "district_id": "350919",
    "label": "Sempusari",
    "value": "Sempusari"
  },
  {
    "id": "3509191003",
    "district_id": "350919",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3509191004",
    "district_id": "350919",
    "label": "Kaliwates",
    "value": "Kaliwates"
  },
  {
    "id": "3509191005",
    "district_id": "350919",
    "label": "Jemberkidul",
    "value": "Jemberkidul"
  },
  {
    "id": "3509191006",
    "district_id": "350919",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3509191007",
    "district_id": "350919",
    "label": "Tegalbesar",
    "value": "Tegalbesar"
  },
  {
    "id": "3509201001",
    "district_id": "350920",
    "label": "Banjarsengon",
    "value": "Banjarsengon"
  },
  {
    "id": "3509201002",
    "district_id": "350920",
    "label": "Jumerto",
    "value": "Jumerto"
  },
  {
    "id": "3509201003",
    "district_id": "350920",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3509201004",
    "district_id": "350920",
    "label": "Slawu",
    "value": "Slawu"
  },
  {
    "id": "3509201005",
    "district_id": "350920",
    "label": "Bintoro",
    "value": "Bintoro"
  },
  {
    "id": "3509201006",
    "district_id": "350920",
    "label": "Jemberlor",
    "value": "Jemberlor"
  },
  {
    "id": "3509201007",
    "district_id": "350920",
    "label": "Patrang",
    "value": "Patrang"
  },
  {
    "id": "3509201008",
    "district_id": "350920",
    "label": "Baratan",
    "value": "Baratan"
  },
  {
    "id": "3509211001",
    "district_id": "350921",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3509211002",
    "district_id": "350921",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3509211003",
    "district_id": "350921",
    "label": "Kranjingan",
    "value": "Kranjingan"
  },
  {
    "id": "3509211004",
    "district_id": "350921",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3509211005",
    "district_id": "350921",
    "label": "Tegalgede",
    "value": "Tegalgede"
  },
  {
    "id": "3509211006",
    "district_id": "350921",
    "label": "Wirolegi",
    "value": "Wirolegi"
  },
  {
    "id": "3509211007",
    "district_id": "350921",
    "label": "Antirogo",
    "value": "Antirogo"
  },
  {
    "id": "3509222001",
    "district_id": "350922",
    "label": "Kemuninglor",
    "value": "Kemuninglor"
  },
  {
    "id": "3509222002",
    "district_id": "350922",
    "label": "Darsono",
    "value": "Darsono"
  },
  {
    "id": "3509222003",
    "district_id": "350922",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "3509222004",
    "district_id": "350922",
    "label": "Candijati",
    "value": "Candijati"
  },
  {
    "id": "3509222005",
    "district_id": "350922",
    "label": "Biting",
    "value": "Biting"
  },
  {
    "id": "3509222006",
    "district_id": "350922",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "3509232001",
    "district_id": "350923",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3509232002",
    "district_id": "350923",
    "label": "Kawangrejo",
    "value": "Kawangrejo"
  },
  {
    "id": "3509232003",
    "district_id": "350923",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3509232004",
    "district_id": "350923",
    "label": "Mumbulsari",
    "value": "Mumbulsari"
  },
  {
    "id": "3509232005",
    "district_id": "350923",
    "label": "Suco",
    "value": "Suco"
  },
  {
    "id": "3509232006",
    "district_id": "350923",
    "label": "Lampeji",
    "value": "Lampeji"
  },
  {
    "id": "3509232007",
    "district_id": "350923",
    "label": "Karang Kedawung",
    "value": "Karang Kedawung"
  },
  {
    "id": "3509242001",
    "district_id": "350924",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3509242002",
    "district_id": "350924",
    "label": "Bedadung",
    "value": "Bedadung"
  },
  {
    "id": "3509242003",
    "district_id": "350924",
    "label": "Sumberpinang",
    "value": "Sumberpinang"
  },
  {
    "id": "3509242004",
    "district_id": "350924",
    "label": "Subo",
    "value": "Subo"
  },
  {
    "id": "3509242005",
    "district_id": "350924",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3509242006",
    "district_id": "350924",
    "label": "Jatian",
    "value": "Jatian"
  },
  {
    "id": "3509242007",
    "district_id": "350924",
    "label": "Pakusari",
    "value": "Pakusari"
  },
  {
    "id": "3509252001",
    "district_id": "350925",
    "label": "Sucopangepok",
    "value": "Sucopangepok"
  },
  {
    "id": "3509252002",
    "district_id": "350925",
    "label": "Panduman",
    "value": "Panduman"
  },
  {
    "id": "3509252003",
    "district_id": "350925",
    "label": "Sukojember",
    "value": "Sukojember"
  },
  {
    "id": "3509252004",
    "district_id": "350925",
    "label": "Jelbuk",
    "value": "Jelbuk"
  },
  {
    "id": "3509252005",
    "district_id": "350925",
    "label": "Sukowiryo",
    "value": "Sukowiryo"
  },
  {
    "id": "3509252006",
    "district_id": "350925",
    "label": "Sugerkidul",
    "value": "Sugerkidul"
  },
  {
    "id": "3509262001",
    "district_id": "350926",
    "label": "Mrawan",
    "value": "Mrawan"
  },
  {
    "id": "3509262002",
    "district_id": "350926",
    "label": "Mayang",
    "value": "Mayang"
  },
  {
    "id": "3509262003",
    "district_id": "350926",
    "label": "Seputih",
    "value": "Seputih"
  },
  {
    "id": "3509262004",
    "district_id": "350926",
    "label": "Tegalwaru",
    "value": "Tegalwaru"
  },
  {
    "id": "3509262005",
    "district_id": "350926",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3509262006",
    "district_id": "350926",
    "label": "Sumberkejayan",
    "value": "Sumberkejayan"
  },
  {
    "id": "3509262007",
    "district_id": "350926",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3509272001",
    "district_id": "350927",
    "label": "Gumuksari",
    "value": "Gumuksari"
  },
  {
    "id": "3509272002",
    "district_id": "350927",
    "label": "Sukoreno",
    "value": "Sukoreno"
  },
  {
    "id": "3509272003",
    "district_id": "350927",
    "label": "Patempuran",
    "value": "Patempuran"
  },
  {
    "id": "3509272004",
    "district_id": "350927",
    "label": "Sumberkalong",
    "value": "Sumberkalong"
  },
  {
    "id": "3509272005",
    "district_id": "350927",
    "label": "Sumberjeruk",
    "value": "Sumberjeruk"
  },
  {
    "id": "3509272006",
    "district_id": "350927",
    "label": "Glagahwero",
    "value": "Glagahwero"
  },
  {
    "id": "3509272007",
    "district_id": "350927",
    "label": "Kalisat",
    "value": "Kalisat"
  },
  {
    "id": "3509272008",
    "district_id": "350927",
    "label": "Ajung",
    "value": "Ajung"
  },
  {
    "id": "3509272009",
    "district_id": "350927",
    "label": "Plalangan",
    "value": "Plalangan"
  },
  {
    "id": "3509272010",
    "district_id": "350927",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  {
    "id": "3509272011",
    "district_id": "350927",
    "label": "Sumberketempa",
    "value": "Sumberketempa"
  },
  {
    "id": "3509272012",
    "district_id": "350927",
    "label": "Sebanen",
    "value": "Sebanen"
  },
  {
    "id": "3509282001",
    "district_id": "350928",
    "label": "Lembengan",
    "value": "Lembengan"
  },
  {
    "id": "3509282002",
    "district_id": "350928",
    "label": "Suren",
    "value": "Suren"
  },
  {
    "id": "3509282003",
    "district_id": "350928",
    "label": "Karangpaiton",
    "value": "Karangpaiton"
  },
  {
    "id": "3509282004",
    "district_id": "350928",
    "label": "Sumberanget",
    "value": "Sumberanget"
  },
  {
    "id": "3509282005",
    "district_id": "350928",
    "label": "Sukogidri",
    "value": "Sukogidri"
  },
  {
    "id": "3509282006",
    "district_id": "350928",
    "label": "Ledokombo",
    "value": "Ledokombo"
  },
  {
    "id": "3509282007",
    "district_id": "350928",
    "label": "Sumberlesung",
    "value": "Sumberlesung"
  },
  {
    "id": "3509282008",
    "district_id": "350928",
    "label": "Sumbersalak",
    "value": "Sumbersalak"
  },
  {
    "id": "3509282009",
    "district_id": "350928",
    "label": "Slateng",
    "value": "Slateng"
  },
  {
    "id": "3509282010",
    "district_id": "350928",
    "label": "Sumberbulus",
    "value": "Sumberbulus"
  },
  {
    "id": "3509292001",
    "district_id": "350929",
    "label": "Sumberwringin",
    "value": "Sumberwringin"
  },
  {
    "id": "3509292002",
    "district_id": "350929",
    "label": "Sukokerto",
    "value": "Sukokerto"
  },
  {
    "id": "3509292003",
    "district_id": "350929",
    "label": "Sumberwaru",
    "value": "Sumberwaru"
  },
  {
    "id": "3509292004",
    "district_id": "350929",
    "label": "Sukowono",
    "value": "Sukowono"
  },
  {
    "id": "3509292005",
    "district_id": "350929",
    "label": "Baletbaru",
    "value": "Baletbaru"
  },
  {
    "id": "3509292006",
    "district_id": "350929",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3509292007",
    "district_id": "350929",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3509292008",
    "district_id": "350929",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "3509292009",
    "district_id": "350929",
    "label": "Sumberdanti",
    "value": "Sumberdanti"
  },
  {
    "id": "3509292010",
    "district_id": "350929",
    "label": "Pocangan",
    "value": "Pocangan"
  },
  {
    "id": "3509292011",
    "district_id": "350929",
    "label": "Dawuhanmangli",
    "value": "Dawuhanmangli"
  },
  {
    "id": "3509292012",
    "district_id": "350929",
    "label": "Mojogemi",
    "value": "Mojogemi"
  },
  {
    "id": "3509302001",
    "district_id": "350930",
    "label": "Sempolan",
    "value": "Sempolan"
  },
  {
    "id": "3509302002",
    "district_id": "350930",
    "label": "Harjomulyo",
    "value": "Harjomulyo"
  },
  {
    "id": "3509302003",
    "district_id": "350930",
    "label": "Karangharjo",
    "value": "Karangharjo"
  },
  {
    "id": "3509302004",
    "district_id": "350930",
    "label": "Silo",
    "value": "Silo"
  },
  {
    "id": "3509302005",
    "district_id": "350930",
    "label": "Pace",
    "value": "Pace"
  },
  {
    "id": "3509302006",
    "district_id": "350930",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3509302007",
    "district_id": "350930",
    "label": "Sumberjati",
    "value": "Sumberjati"
  },
  {
    "id": "3509302008",
    "district_id": "350930",
    "label": "Garahan",
    "value": "Garahan"
  },
  {
    "id": "3509302009",
    "district_id": "350930",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3509312001",
    "district_id": "350931",
    "label": "Plerean",
    "value": "Plerean"
  },
  {
    "id": "3509312002",
    "district_id": "350931",
    "label": "Sumberpakem",
    "value": "Sumberpakem"
  },
  {
    "id": "3509312003",
    "district_id": "350931",
    "label": "Pringgondani",
    "value": "Pringgondani"
  },
  {
    "id": "3509312004",
    "district_id": "350931",
    "label": "Randuagung",
    "value": "Randuagung"
  },
  {
    "id": "3509312005",
    "district_id": "350931",
    "label": "Cumedak",
    "value": "Cumedak"
  },
  {
    "id": "3509312006",
    "district_id": "350931",
    "label": "Sumberjambe",
    "value": "Sumberjambe"
  },
  {
    "id": "3509312007",
    "district_id": "350931",
    "label": "Gunungmalang",
    "value": "Gunungmalang"
  },
  {
    "id": "3509312008",
    "district_id": "350931",
    "label": "Jambearum",
    "value": "Jambearum"
  },
  {
    "id": "3509312009",
    "district_id": "350931",
    "label": "Rowosari",
    "value": "Rowosari"
  },
  {
    "id": "3510012001",
    "district_id": "351001",
    "label": "Sarongan",
    "value": "Sarongan"
  },
  {
    "id": "3510012002",
    "district_id": "351001",
    "label": "Pesanggaran",
    "value": "Pesanggaran"
  },
  {
    "id": "3510012004",
    "district_id": "351001",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3510012009",
    "district_id": "351001",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3510012010",
    "district_id": "351001",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3510022001",
    "district_id": "351002",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3510022002",
    "district_id": "351002",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3510022003",
    "district_id": "351002",
    "label": "Temurejo",
    "value": "Temurejo"
  },
  {
    "id": "3510022004",
    "district_id": "351002",
    "label": "Bangorejo",
    "value": "Bangorejo"
  },
  {
    "id": "3510022005",
    "district_id": "351002",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3510022006",
    "district_id": "351002",
    "label": "Sambimulyo",
    "value": "Sambimulyo"
  },
  {
    "id": "3510022007",
    "district_id": "351002",
    "label": "Ringintelu",
    "value": "Ringintelu"
  },
  {
    "id": "3510032001",
    "district_id": "351003",
    "label": "Grajagan",
    "value": "Grajagan"
  },
  {
    "id": "3510032002",
    "district_id": "351003",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3510032003",
    "district_id": "351003",
    "label": "Glagahagung",
    "value": "Glagahagung"
  },
  {
    "id": "3510032004",
    "district_id": "351003",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3510032005",
    "district_id": "351003",
    "label": "Purwoharjo",
    "value": "Purwoharjo"
  },
  {
    "id": "3510032006",
    "district_id": "351003",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3510032007",
    "district_id": "351003",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3510032008",
    "district_id": "351003",
    "label": "Karetan",
    "value": "Karetan"
  },
  {
    "id": "3510042001",
    "district_id": "351004",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "3510042002",
    "district_id": "351004",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3510042003",
    "district_id": "351004",
    "label": "Kedungasri",
    "value": "Kedungasri"
  },
  {
    "id": "3510042004",
    "district_id": "351004",
    "label": "Kedungwungu",
    "value": "Kedungwungu"
  },
  {
    "id": "3510042005",
    "district_id": "351004",
    "label": "Tegaldlimo",
    "value": "Tegaldlimo"
  },
  {
    "id": "3510042006",
    "district_id": "351004",
    "label": "Wringinpitu",
    "value": "Wringinpitu"
  },
  {
    "id": "3510042007",
    "district_id": "351004",
    "label": "Kedunggebang",
    "value": "Kedunggebang"
  },
  {
    "id": "3510042008",
    "district_id": "351004",
    "label": "Purwoagung",
    "value": "Purwoagung"
  },
  {
    "id": "3510042009",
    "district_id": "351004",
    "label": "Kalipait",
    "value": "Kalipait"
  },
  {
    "id": "3510052001",
    "district_id": "351005",
    "label": "Sumberberas",
    "value": "Sumberberas"
  },
  {
    "id": "3510052002",
    "district_id": "351005",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3510052003",
    "district_id": "351005",
    "label": "Tembokrejo",
    "value": "Tembokrejo"
  },
  {
    "id": "3510052004",
    "district_id": "351005",
    "label": "Sumbersewu",
    "value": "Sumbersewu"
  },
  {
    "id": "3510052005",
    "district_id": "351005",
    "label": "Blambangan",
    "value": "Blambangan"
  },
  {
    "id": "3510052006",
    "district_id": "351005",
    "label": "Tapanrejo",
    "value": "Tapanrejo"
  },
  {
    "id": "3510052007",
    "district_id": "351005",
    "label": "Wringinputih",
    "value": "Wringinputih"
  },
  {
    "id": "3510052008",
    "district_id": "351005",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3510052009",
    "district_id": "351005",
    "label": "Kedungringin",
    "value": "Kedungringin"
  },
  {
    "id": "3510052010",
    "district_id": "351005",
    "label": "Kumendung",
    "value": "Kumendung"
  },
  {
    "id": "3510062001",
    "district_id": "351006",
    "label": "Plampangrejo",
    "value": "Plampangrejo"
  },
  {
    "id": "3510062002",
    "district_id": "351006",
    "label": "Tampo",
    "value": "Tampo"
  },
  {
    "id": "3510062003",
    "district_id": "351006",
    "label": "Sembulung",
    "value": "Sembulung"
  },
  {
    "id": "3510062004",
    "district_id": "351006",
    "label": "Cluring",
    "value": "Cluring"
  },
  {
    "id": "3510062005",
    "district_id": "351006",
    "label": "Benculuk",
    "value": "Benculuk"
  },
  {
    "id": "3510062006",
    "district_id": "351006",
    "label": "Sraten",
    "value": "Sraten"
  },
  {
    "id": "3510062007",
    "district_id": "351006",
    "label": "Tamanagung",
    "value": "Tamanagung"
  },
  {
    "id": "3510062008",
    "district_id": "351006",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "3510062009",
    "district_id": "351006",
    "label": "Kaliploso",
    "value": "Kaliploso"
  },
  {
    "id": "3510072001",
    "district_id": "351007",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3510072002",
    "district_id": "351007",
    "label": "Jajag",
    "value": "Jajag"
  },
  {
    "id": "3510072007",
    "district_id": "351007",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  {
    "id": "3510072008",
    "district_id": "351007",
    "label": "Yosomulyo",
    "value": "Yosomulyo"
  },
  {
    "id": "3510072009",
    "district_id": "351007",
    "label": "Wringinrejo",
    "value": "Wringinrejo"
  },
  {
    "id": "3510072010",
    "district_id": "351007",
    "label": "Wringinagung",
    "value": "Wringinagung"
  },
  {
    "id": "3510082001",
    "district_id": "351008",
    "label": "Bangorejo",
    "value": "Bangorejo"
  },
  {
    "id": "3510082002",
    "district_id": "351008",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "3510082003",
    "district_id": "351008",
    "label": "Sukonatar",
    "value": "Sukonatar"
  },
  {
    "id": "3510082004",
    "district_id": "351008",
    "label": "Kebaman",
    "value": "Kebaman"
  },
  {
    "id": "3510082005",
    "district_id": "351008",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3510082006",
    "district_id": "351008",
    "label": "Parijatah Wetan",
    "value": "Parijatah Wetan"
  },
  {
    "id": "3510082007",
    "district_id": "351008",
    "label": "Parijatah Kulon",
    "value": "Parijatah Kulon"
  },
  {
    "id": "3510082008",
    "district_id": "351008",
    "label": "Rejoagung",
    "value": "Rejoagung"
  },
  {
    "id": "3510082009",
    "district_id": "351008",
    "label": "Kepundungan",
    "value": "Kepundungan"
  },
  {
    "id": "3510082010",
    "district_id": "351008",
    "label": "Sukomaju",
    "value": "Sukomaju"
  },
  {
    "id": "3510092001",
    "district_id": "351009",
    "label": "Kembiritan",
    "value": "Kembiritan"
  },
  {
    "id": "3510092002",
    "district_id": "351009",
    "label": "Gentengwetan",
    "value": "Gentengwetan"
  },
  {
    "id": "3510092003",
    "district_id": "351009",
    "label": "Gentengkulon",
    "value": "Gentengkulon"
  },
  {
    "id": "3510092004",
    "district_id": "351009",
    "label": "Setail",
    "value": "Setail"
  },
  {
    "id": "3510092005",
    "district_id": "351009",
    "label": "Kaligondo",
    "value": "Kaligondo"
  },
  {
    "id": "3510102001",
    "district_id": "351010",
    "label": "Tegalharjo",
    "value": "Tegalharjo"
  },
  {
    "id": "3510102002",
    "district_id": "351010",
    "label": "Sepanjang",
    "value": "Sepanjang"
  },
  {
    "id": "3510102003",
    "district_id": "351010",
    "label": "Karangharjo",
    "value": "Karangharjo"
  },
  {
    "id": "3510102004",
    "district_id": "351010",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3510102005",
    "district_id": "351010",
    "label": "Sumbergondo",
    "value": "Sumbergondo"
  },
  {
    "id": "3510102006",
    "district_id": "351010",
    "label": "Bumiharjo",
    "value": "Bumiharjo"
  },
  {
    "id": "3510102007",
    "district_id": "351010",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3510112001",
    "district_id": "351011",
    "label": "Kalibarukulon",
    "value": "Kalibarukulon"
  },
  {
    "id": "3510112002",
    "district_id": "351011",
    "label": "Kalibarumanis",
    "value": "Kalibarumanis"
  },
  {
    "id": "3510112003",
    "district_id": "351011",
    "label": "Kalibaruwetan",
    "value": "Kalibaruwetan"
  },
  {
    "id": "3510112004",
    "district_id": "351011",
    "label": "Kajarharjo",
    "value": "Kajarharjo"
  },
  {
    "id": "3510112005",
    "district_id": "351011",
    "label": "Banyuanyar",
    "value": "Banyuanyar"
  },
  {
    "id": "3510112006",
    "district_id": "351011",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3510122001",
    "district_id": "351012",
    "label": "Gambor",
    "value": "Gambor"
  },
  {
    "id": "3510122002",
    "district_id": "351012",
    "label": "Alasmalang",
    "value": "Alasmalang"
  },
  {
    "id": "3510122003",
    "district_id": "351012",
    "label": "Benelan Kidul",
    "value": "Benelan Kidul"
  },
  {
    "id": "3510122004",
    "district_id": "351012",
    "label": "Lemahbang Kulon",
    "value": "Lemahbang Kulon"
  },
  {
    "id": "3510122005",
    "district_id": "351012",
    "label": "Singojuruh",
    "value": "Singojuruh"
  },
  {
    "id": "3510122006",
    "district_id": "351012",
    "label": "Gumirih",
    "value": "Gumirih"
  },
  {
    "id": "3510122007",
    "district_id": "351012",
    "label": "Cantuk",
    "value": "Cantuk"
  },
  {
    "id": "3510122008",
    "district_id": "351012",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "3510122009",
    "district_id": "351012",
    "label": "Singolatren",
    "value": "Singolatren"
  },
  {
    "id": "3510122010",
    "district_id": "351012",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3510122011",
    "district_id": "351012",
    "label": "Sumberbaru",
    "value": "Sumberbaru"
  },
  {
    "id": "3510132001",
    "district_id": "351013",
    "label": "Aliyan",
    "value": "Aliyan"
  },
  {
    "id": "3510132002",
    "district_id": "351013",
    "label": "Mangir",
    "value": "Mangir"
  },
  {
    "id": "3510132006",
    "district_id": "351013",
    "label": "Gladag",
    "value": "Gladag"
  },
  {
    "id": "3510132007",
    "district_id": "351013",
    "label": "Bubuk",
    "value": "Bubuk"
  },
  {
    "id": "3510132008",
    "district_id": "351013",
    "label": "Lemahbangdewo",
    "value": "Lemahbangdewo"
  },
  {
    "id": "3510132010",
    "district_id": "351013",
    "label": "Gitik",
    "value": "Gitik"
  },
  {
    "id": "3510132012",
    "district_id": "351013",
    "label": "Karangbendo",
    "value": "Karangbendo"
  },
  {
    "id": "3510132013",
    "district_id": "351013",
    "label": "Rogojampi",
    "value": "Rogojampi"
  },
  {
    "id": "3510132014",
    "district_id": "351013",
    "label": "Pengatigan",
    "value": "Pengatigan"
  },
  {
    "id": "3510132018",
    "district_id": "351013",
    "label": "Kedaleman",
    "value": "Kedaleman"
  },
  {
    "id": "3510142001",
    "district_id": "351014",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3510142002",
    "district_id": "351014",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3510142003",
    "district_id": "351014",
    "label": "Gombolirang",
    "value": "Gombolirang"
  },
  {
    "id": "3510142004",
    "district_id": "351014",
    "label": "Benelanlor",
    "value": "Benelanlor"
  },
  {
    "id": "3510142005",
    "district_id": "351014",
    "label": "Labanasem",
    "value": "Labanasem"
  },
  {
    "id": "3510142006",
    "district_id": "351014",
    "label": "Pakistaji",
    "value": "Pakistaji"
  },
  {
    "id": "3510142009",
    "district_id": "351014",
    "label": "Pondoknongko",
    "value": "Pondoknongko"
  },
  {
    "id": "3510142010",
    "district_id": "351014",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3510142011",
    "district_id": "351014",
    "label": "Kedayunan",
    "value": "Kedayunan"
  },
  {
    "id": "3510142012",
    "district_id": "351014",
    "label": "Kabat",
    "value": "Kabat"
  },
  {
    "id": "3510142013",
    "district_id": "351014",
    "label": "Macan Putih",
    "value": "Macan Putih"
  },
  {
    "id": "3510142014",
    "district_id": "351014",
    "label": "Tambong",
    "value": "Tambong"
  },
  {
    "id": "3510142015",
    "district_id": "351014",
    "label": "Pendarungan",
    "value": "Pendarungan"
  },
  {
    "id": "3510142016",
    "district_id": "351014",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3510151002",
    "district_id": "351015",
    "label": "Bakungan",
    "value": "Bakungan"
  },
  {
    "id": "3510151003",
    "district_id": "351015",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3510152001",
    "district_id": "351015",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3510152004",
    "district_id": "351015",
    "label": "Kemiren",
    "value": "Kemiren"
  },
  {
    "id": "3510152005",
    "district_id": "351015",
    "label": "Olehsari",
    "value": "Olehsari"
  },
  {
    "id": "3510152006",
    "district_id": "351015",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "3510152007",
    "district_id": "351015",
    "label": "Paspan",
    "value": "Paspan"
  },
  {
    "id": "3510152010",
    "district_id": "351015",
    "label": "Tamansuruh",
    "value": "Tamansuruh"
  },
  {
    "id": "3510152011",
    "district_id": "351015",
    "label": "Kenjo",
    "value": "Kenjo"
  },
  {
    "id": "3510152012",
    "district_id": "351015",
    "label": "Kampunganyar",
    "value": "Kampunganyar"
  },
  {
    "id": "3510161001",
    "district_id": "351016",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3510161002",
    "district_id": "351016",
    "label": "Sobo",
    "value": "Sobo"
  },
  {
    "id": "3510161003",
    "district_id": "351016",
    "label": "Kebalenan",
    "value": "Kebalenan"
  },
  {
    "id": "3510161004",
    "district_id": "351016",
    "label": "Penganjuran",
    "value": "Penganjuran"
  },
  {
    "id": "3510161005",
    "district_id": "351016",
    "label": "Tukangkayu",
    "value": "Tukangkayu"
  },
  {
    "id": "3510161006",
    "district_id": "351016",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3510161007",
    "district_id": "351016",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3510161008",
    "district_id": "351016",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3510161009",
    "district_id": "351016",
    "label": "Panderejo",
    "value": "Panderejo"
  },
  {
    "id": "3510161010",
    "district_id": "351016",
    "label": "Singonegaran",
    "value": "Singonegaran"
  },
  {
    "id": "3510161011",
    "district_id": "351016",
    "label": "Temenggungan",
    "value": "Temenggungan"
  },
  {
    "id": "3510161012",
    "district_id": "351016",
    "label": "Kampungmelayu",
    "value": "Kampungmelayu"
  },
  {
    "id": "3510161013",
    "district_id": "351016",
    "label": "Kampungmandar",
    "value": "Kampungmandar"
  },
  {
    "id": "3510161014",
    "district_id": "351016",
    "label": "Lateng",
    "value": "Lateng"
  },
  {
    "id": "3510161015",
    "district_id": "351016",
    "label": "Singotrunan",
    "value": "Singotrunan"
  },
  {
    "id": "3510161016",
    "district_id": "351016",
    "label": "Pengantigan",
    "value": "Pengantigan"
  },
  {
    "id": "3510161017",
    "district_id": "351016",
    "label": "Tamanbaru",
    "value": "Tamanbaru"
  },
  {
    "id": "3510161018",
    "district_id": "351016",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3510171002",
    "district_id": "351017",
    "label": "Boyolangu",
    "value": "Boyolangu"
  },
  {
    "id": "3510171003",
    "district_id": "351017",
    "label": "Mojopanggung",
    "value": "Mojopanggung"
  },
  {
    "id": "3510171004",
    "district_id": "351017",
    "label": "Penataban",
    "value": "Penataban"
  },
  {
    "id": "3510171005",
    "district_id": "351017",
    "label": "Giri",
    "value": "Giri"
  },
  {
    "id": "3510172001",
    "district_id": "351017",
    "label": "Jambesari",
    "value": "Jambesari"
  },
  {
    "id": "3510172006",
    "district_id": "351017",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3510182001",
    "district_id": "351018",
    "label": "Bangsring",
    "value": "Bangsring"
  },
  {
    "id": "3510182002",
    "district_id": "351018",
    "label": "Bengkak",
    "value": "Bengkak"
  },
  {
    "id": "3510182003",
    "district_id": "351018",
    "label": "Alasbuluh",
    "value": "Alasbuluh"
  },
  {
    "id": "3510182004",
    "district_id": "351018",
    "label": "Wongsorejo",
    "value": "Wongsorejo"
  },
  {
    "id": "3510182005",
    "district_id": "351018",
    "label": "Sumberkencono",
    "value": "Sumberkencono"
  },
  {
    "id": "3510182006",
    "district_id": "351018",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3510182007",
    "district_id": "351018",
    "label": "Bajulmati",
    "value": "Bajulmati"
  },
  {
    "id": "3510182008",
    "district_id": "351018",
    "label": "Watukebo",
    "value": "Watukebo"
  },
  {
    "id": "3510182009",
    "district_id": "351018",
    "label": "Alas Rejo",
    "value": "Alas Rejo"
  },
  {
    "id": "3510182010",
    "district_id": "351018",
    "label": "Sidowangi",
    "value": "Sidowangi"
  },
  {
    "id": "3510182011",
    "district_id": "351018",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3510182012",
    "district_id": "351018",
    "label": "Bimorejo",
    "value": "Bimorejo"
  },
  {
    "id": "3510192001",
    "district_id": "351019",
    "label": "Songgon",
    "value": "Songgon"
  },
  {
    "id": "3510192002",
    "district_id": "351019",
    "label": "Balak",
    "value": "Balak"
  },
  {
    "id": "3510192003",
    "district_id": "351019",
    "label": "Sragi",
    "value": "Sragi"
  },
  {
    "id": "3510192004",
    "district_id": "351019",
    "label": "Parangharjo",
    "value": "Parangharjo"
  },
  {
    "id": "3510192005",
    "district_id": "351019",
    "label": "Bedewang",
    "value": "Bedewang"
  },
  {
    "id": "3510192006",
    "district_id": "351019",
    "label": "Bayu",
    "value": "Bayu"
  },
  {
    "id": "3510192007",
    "district_id": "351019",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3510192008",
    "district_id": "351019",
    "label": "Sumberbulu",
    "value": "Sumberbulu"
  },
  {
    "id": "3510192009",
    "district_id": "351019",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3510202001",
    "district_id": "351020",
    "label": "Sempu",
    "value": "Sempu"
  },
  {
    "id": "3510202002",
    "district_id": "351020",
    "label": "Jambewangi",
    "value": "Jambewangi"
  },
  {
    "id": "3510202003",
    "district_id": "351020",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3510202004",
    "district_id": "351020",
    "label": "Temuguruh",
    "value": "Temuguruh"
  },
  {
    "id": "3510202005",
    "district_id": "351020",
    "label": "Gendoh",
    "value": "Gendoh"
  },
  {
    "id": "3510202006",
    "district_id": "351020",
    "label": "Temuasri",
    "value": "Temuasri"
  },
  {
    "id": "3510202007",
    "district_id": "351020",
    "label": "Tegalarum",
    "value": "Tegalarum"
  },
  {
    "id": "3510211001",
    "district_id": "351021",
    "label": "Kalipuro",
    "value": "Kalipuro"
  },
  {
    "id": "3510211002",
    "district_id": "351021",
    "label": "Klatak",
    "value": "Klatak"
  },
  {
    "id": "3510211006",
    "district_id": "351021",
    "label": "Gombengsari",
    "value": "Gombengsari"
  },
  {
    "id": "3510211007",
    "district_id": "351021",
    "label": "Bulusan",
    "value": "Bulusan"
  },
  {
    "id": "3510212003",
    "district_id": "351021",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3510212004",
    "district_id": "351021",
    "label": "Pesucen",
    "value": "Pesucen"
  },
  {
    "id": "3510212005",
    "district_id": "351021",
    "label": "Kelir",
    "value": "Kelir"
  },
  {
    "id": "3510212008",
    "district_id": "351021",
    "label": "Telemung",
    "value": "Telemung"
  },
  {
    "id": "3510212009",
    "district_id": "351021",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3510222001",
    "district_id": "351022",
    "label": "Buluagung",
    "value": "Buluagung"
  },
  {
    "id": "3510222002",
    "district_id": "351022",
    "label": "Siliragung",
    "value": "Siliragung"
  },
  {
    "id": "3510222003",
    "district_id": "351022",
    "label": "Kesilir",
    "value": "Kesilir"
  },
  {
    "id": "3510222004",
    "district_id": "351022",
    "label": "Sineporejo",
    "value": "Sineporejo"
  },
  {
    "id": "3510222005",
    "district_id": "351022",
    "label": "Barurejo",
    "value": "Barurejo"
  },
  {
    "id": "3510232001",
    "district_id": "351023",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3510232002",
    "district_id": "351023",
    "label": "Karangdoro",
    "value": "Karangdoro"
  },
  {
    "id": "3510232003",
    "district_id": "351023",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3510232004",
    "district_id": "351023",
    "label": "Dasri",
    "value": "Dasri"
  },
  {
    "id": "3510232005",
    "district_id": "351023",
    "label": "Karangmulyo",
    "value": "Karangmulyo"
  },
  {
    "id": "3510232006",
    "district_id": "351023",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3510242001",
    "district_id": "351024",
    "label": "Gumuk",
    "value": "Gumuk"
  },
  {
    "id": "3510242002",
    "district_id": "351024",
    "label": "Jelun",
    "value": "Jelun"
  },
  {
    "id": "3510242003",
    "district_id": "351024",
    "label": "Licin",
    "value": "Licin"
  },
  {
    "id": "3510242004",
    "district_id": "351024",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3510242005",
    "district_id": "351024",
    "label": "Segobang",
    "value": "Segobang"
  },
  {
    "id": "3510242006",
    "district_id": "351024",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3510242007",
    "district_id": "351024",
    "label": "Kluncing",
    "value": "Kluncing"
  },
  {
    "id": "3510242008",
    "district_id": "351024",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3510252001",
    "district_id": "351025",
    "label": "Blimbingsari",
    "value": "Blimbingsari"
  },
  {
    "id": "3510252002",
    "district_id": "351025",
    "label": "Kaotan",
    "value": "Kaotan"
  },
  {
    "id": "3510252003",
    "district_id": "351025",
    "label": "Watukebo",
    "value": "Watukebo"
  },
  {
    "id": "3510252004",
    "district_id": "351025",
    "label": "Gintangan",
    "value": "Gintangan"
  },
  {
    "id": "3510252005",
    "district_id": "351025",
    "label": "Bomo",
    "value": "Bomo"
  },
  {
    "id": "3510252006",
    "district_id": "351025",
    "label": "Patoman",
    "value": "Patoman"
  },
  {
    "id": "3510252007",
    "district_id": "351025",
    "label": "Kaligung",
    "value": "Kaligung"
  },
  {
    "id": "3510252008",
    "district_id": "351025",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3510252009",
    "district_id": "351025",
    "label": "Badean",
    "value": "Badean"
  },
  {
    "id": "3510252010",
    "district_id": "351025",
    "label": "Sukojati",
    "value": "Sukojati"
  },
  {
    "id": "3511012001",
    "district_id": "351101",
    "label": "Sucolor",
    "value": "Sucolor"
  },
  {
    "id": "3511012002",
    "district_id": "351101",
    "label": "Pujer Baru",
    "value": "Pujer Baru"
  },
  {
    "id": "3511012003",
    "district_id": "351101",
    "label": "Tanahwulan",
    "value": "Tanahwulan"
  },
  {
    "id": "3511012004",
    "district_id": "351101",
    "label": "Maesan",
    "value": "Maesan"
  },
  {
    "id": "3511012005",
    "district_id": "351101",
    "label": "Gambangan",
    "value": "Gambangan"
  },
  {
    "id": "3511012006",
    "district_id": "351101",
    "label": "Suger Lor",
    "value": "Suger Lor"
  },
  {
    "id": "3511012007",
    "district_id": "351101",
    "label": "Sumber Pakem",
    "value": "Sumber Pakem"
  },
  {
    "id": "3511012008",
    "district_id": "351101",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3511012009",
    "district_id": "351101",
    "label": "Sumber Anyar",
    "value": "Sumber Anyar"
  },
  {
    "id": "3511012010",
    "district_id": "351101",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "3511012011",
    "district_id": "351101",
    "label": "Pakuniran",
    "value": "Pakuniran"
  },
  {
    "id": "3511012012",
    "district_id": "351101",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3511022001",
    "district_id": "351102",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3511022002",
    "district_id": "351102",
    "label": "Karang Melok",
    "value": "Karang Melok"
  },
  {
    "id": "3511022003",
    "district_id": "351102",
    "label": "Mengen",
    "value": "Mengen"
  },
  {
    "id": "3511022004",
    "district_id": "351102",
    "label": "Kemirian",
    "value": "Kemirian"
  },
  {
    "id": "3511022005",
    "district_id": "351102",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "3511022006",
    "district_id": "351102",
    "label": "Wonosuko",
    "value": "Wonosuko"
  },
  {
    "id": "3511022007",
    "district_id": "351102",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3511022012",
    "district_id": "351102",
    "label": "Sumber Kemuning",
    "value": "Sumber Kemuning"
  },
  {
    "id": "3511022014",
    "district_id": "351102",
    "label": "Sumber Anom",
    "value": "Sumber Anom"
  },
  {
    "id": "3511032001",
    "district_id": "351103",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3511032002",
    "district_id": "351103",
    "label": "Gunosari",
    "value": "Gunosari"
  },
  {
    "id": "3511032003",
    "district_id": "351103",
    "label": "Trotosari",
    "value": "Trotosari"
  },
  {
    "id": "3511032004",
    "district_id": "351103",
    "label": "Jebung Kidul",
    "value": "Jebung Kidul"
  },
  {
    "id": "3511032005",
    "district_id": "351103",
    "label": "Sulek",
    "value": "Sulek"
  },
  {
    "id": "3511032006",
    "district_id": "351103",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3511032007",
    "district_id": "351103",
    "label": "Pakisan",
    "value": "Pakisan"
  },
  {
    "id": "3511032008",
    "district_id": "351103",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3511032009",
    "district_id": "351103",
    "label": "Jebung Lor",
    "value": "Jebung Lor"
  },
  {
    "id": "3511032010",
    "district_id": "351103",
    "label": "Brambang Darussalam",
    "value": "Brambang Darussalam"
  },
  {
    "id": "3511042001",
    "district_id": "351104",
    "label": "Sukosari Lor",
    "value": "Sukosari Lor"
  },
  {
    "id": "3511042002",
    "district_id": "351104",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  {
    "id": "3511042003",
    "district_id": "351104",
    "label": "Pecalongan",
    "value": "Pecalongan"
  },
  {
    "id": "3511042004",
    "district_id": "351104",
    "label": "Kerang",
    "value": "Kerang"
  },
  {
    "id": "3511052001",
    "district_id": "351105",
    "label": "Alas Sumur",
    "value": "Alas Sumur"
  },
  {
    "id": "3511052002",
    "district_id": "351105",
    "label": "Kejayan",
    "value": "Kejayan"
  },
  {
    "id": "3511052003",
    "district_id": "351105",
    "label": "Mangli",
    "value": "Mangli"
  },
  {
    "id": "3511052004",
    "district_id": "351105",
    "label": "Maskuning Kulon",
    "value": "Maskuning Kulon"
  },
  {
    "id": "3511052005",
    "district_id": "351105",
    "label": "Maskuning Wetan",
    "value": "Maskuning Wetan"
  },
  {
    "id": "3511052006",
    "district_id": "351105",
    "label": "Mengok",
    "value": "Mengok"
  },
  {
    "id": "3511052007",
    "district_id": "351105",
    "label": "Padasan",
    "value": "Padasan"
  },
  {
    "id": "3511052009",
    "district_id": "351105",
    "label": "Randu Cangkring",
    "value": "Randu Cangkring"
  },
  {
    "id": "3511052010",
    "district_id": "351105",
    "label": "Suko Kerto",
    "value": "Suko Kerto"
  },
  {
    "id": "3511052011",
    "district_id": "351105",
    "label": "Sukowono",
    "value": "Sukowono"
  },
  {
    "id": "3511052012",
    "district_id": "351105",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3511062002",
    "district_id": "351106",
    "label": "Tegal Mijin",
    "value": "Tegal Mijin"
  },
  {
    "id": "3511062003",
    "district_id": "351106",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3511062004",
    "district_id": "351106",
    "label": "Sumber Pandan",
    "value": "Sumber Pandan"
  },
  {
    "id": "3511062005",
    "district_id": "351106",
    "label": "Wanisodo",
    "value": "Wanisodo"
  },
  {
    "id": "3511062006",
    "district_id": "351106",
    "label": "Kabuaran",
    "value": "Kabuaran"
  },
  {
    "id": "3511062007",
    "district_id": "351106",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3511062008",
    "district_id": "351106",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3511062009",
    "district_id": "351106",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3511062010",
    "district_id": "351106",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3511062011",
    "district_id": "351106",
    "label": "Grujugan Kidul",
    "value": "Grujugan Kidul"
  },
  {
    "id": "3511062013",
    "district_id": "351106",
    "label": "Kejawan",
    "value": "Kejawan"
  },
  {
    "id": "3511071009",
    "district_id": "351107",
    "label": "Curahdami",
    "value": "Curahdami"
  },
  {
    "id": "3511072001",
    "district_id": "351107",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3511072002",
    "district_id": "351107",
    "label": "Pakuwesi",
    "value": "Pakuwesi"
  },
  {
    "id": "3511072003",
    "district_id": "351107",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "3511072004",
    "district_id": "351107",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3511072005",
    "district_id": "351107",
    "label": "Panambangan",
    "value": "Panambangan"
  },
  {
    "id": "3511072006",
    "district_id": "351107",
    "label": "Curahpoh",
    "value": "Curahpoh"
  },
  {
    "id": "3511072007",
    "district_id": "351107",
    "label": "Poncogati",
    "value": "Poncogati"
  },
  {
    "id": "3511072008",
    "district_id": "351107",
    "label": "Sumber Suko",
    "value": "Sumber Suko"
  },
  {
    "id": "3511072010",
    "district_id": "351107",
    "label": "Selolembu",
    "value": "Selolembu"
  },
  {
    "id": "3511072011",
    "district_id": "351107",
    "label": "Locare",
    "value": "Locare"
  },
  {
    "id": "3511072012",
    "district_id": "351107",
    "label": "Sumber Salak",
    "value": "Sumber Salak"
  },
  {
    "id": "3511081011",
    "district_id": "351108",
    "label": "Tenggarang",
    "value": "Tenggarang"
  },
  {
    "id": "3511082001",
    "district_id": "351108",
    "label": "Kesemek",
    "value": "Kesemek"
  },
  {
    "id": "3511082002",
    "district_id": "351108",
    "label": "Lojajar",
    "value": "Lojajar"
  },
  {
    "id": "3511082003",
    "district_id": "351108",
    "label": "Pekalangan",
    "value": "Pekalangan"
  },
  {
    "id": "3511082004",
    "district_id": "351108",
    "label": "Kajar",
    "value": "Kajar"
  },
  {
    "id": "3511082005",
    "district_id": "351108",
    "label": "Sumber Salam",
    "value": "Sumber Salam"
  },
  {
    "id": "3511082006",
    "district_id": "351108",
    "label": "Koncer Kidul",
    "value": "Koncer Kidul"
  },
  {
    "id": "3511082007",
    "district_id": "351108",
    "label": "Bataan",
    "value": "Bataan"
  },
  {
    "id": "3511082008",
    "district_id": "351108",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3511082009",
    "district_id": "351108",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3511082010",
    "district_id": "351108",
    "label": "Tangsil Kulon",
    "value": "Tangsil Kulon"
  },
  {
    "id": "3511082012",
    "district_id": "351108",
    "label": "Koncer Darul Aman",
    "value": "Koncer Darul Aman"
  },
  {
    "id": "3511092001",
    "district_id": "351109",
    "label": "Lombok Kulon",
    "value": "Lombok Kulon"
  },
  {
    "id": "3511092002",
    "district_id": "351109",
    "label": "Lombok Wetan",
    "value": "Lombok Wetan"
  },
  {
    "id": "3511092003",
    "district_id": "351109",
    "label": "Tumpeng",
    "value": "Tumpeng"
  },
  {
    "id": "3511092004",
    "district_id": "351109",
    "label": "Jumpong",
    "value": "Jumpong"
  },
  {
    "id": "3511092005",
    "district_id": "351109",
    "label": "Tangsil Wetan",
    "value": "Tangsil Wetan"
  },
  {
    "id": "3511092006",
    "district_id": "351109",
    "label": "Pasarejo",
    "value": "Pasarejo"
  },
  {
    "id": "3511092007",
    "district_id": "351109",
    "label": "Bendoarum",
    "value": "Bendoarum"
  },
  {
    "id": "3511092008",
    "district_id": "351109",
    "label": "Kapuran",
    "value": "Kapuran"
  },
  {
    "id": "3511092009",
    "district_id": "351109",
    "label": "Sumberkalong",
    "value": "Sumberkalong"
  },
  {
    "id": "3511092010",
    "district_id": "351109",
    "label": "Traktakan",
    "value": "Traktakan"
  },
  {
    "id": "3511092011",
    "district_id": "351109",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3511092012",
    "district_id": "351109",
    "label": "Pelalangan",
    "value": "Pelalangan"
  },
  {
    "id": "3511102001",
    "district_id": "351110",
    "label": "Wonokusumo",
    "value": "Wonokusumo"
  },
  {
    "id": "3511102002",
    "district_id": "351110",
    "label": "Mangli Wetan",
    "value": "Mangli Wetan"
  },
  {
    "id": "3511102003",
    "district_id": "351110",
    "label": "Taal",
    "value": "Taal"
  },
  {
    "id": "3511102004",
    "district_id": "351110",
    "label": "Mrawan",
    "value": "Mrawan"
  },
  {
    "id": "3511102005",
    "district_id": "351110",
    "label": "Gunung Anyar",
    "value": "Gunung Anyar"
  },
  {
    "id": "3511102006",
    "district_id": "351110",
    "label": "Jurang Sapi",
    "value": "Jurang Sapi"
  },
  {
    "id": "3511102007",
    "district_id": "351110",
    "label": "Cindogo",
    "value": "Cindogo"
  },
  {
    "id": "3511102008",
    "district_id": "351110",
    "label": "Kalitapen",
    "value": "Kalitapen"
  },
  {
    "id": "3511102009",
    "district_id": "351110",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "3511111004",
    "district_id": "351111",
    "label": "Nangkaan",
    "value": "Nangkaan"
  },
  {
    "id": "3511111005",
    "district_id": "351111",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3511111006",
    "district_id": "351111",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3511111007",
    "district_id": "351111",
    "label": "Dabasah",
    "value": "Dabasah"
  },
  {
    "id": "3511111008",
    "district_id": "351111",
    "label": "Badean",
    "value": "Badean"
  },
  {
    "id": "3511111009",
    "district_id": "351111",
    "label": "Kotakulon",
    "value": "Kotakulon"
  },
  {
    "id": "3511111010",
    "district_id": "351111",
    "label": "Blindungan",
    "value": "Blindungan"
  },
  {
    "id": "3511112001",
    "district_id": "351111",
    "label": "Pancoran",
    "value": "Pancoran"
  },
  {
    "id": "3511112002",
    "district_id": "351111",
    "label": "Sukowiryo",
    "value": "Sukowiryo"
  },
  {
    "id": "3511112003",
    "district_id": "351111",
    "label": "Kembang",
    "value": "Kembang"
  },
  {
    "id": "3511112011",
    "district_id": "351111",
    "label": "Pejaten",
    "value": "Pejaten"
  },
  {
    "id": "3511122001",
    "district_id": "351112",
    "label": "Ambulu",
    "value": "Ambulu"
  },
  {
    "id": "3511122002",
    "district_id": "351112",
    "label": "Bukor",
    "value": "Bukor"
  },
  {
    "id": "3511122003",
    "district_id": "351112",
    "label": "Sumbermalang",
    "value": "Sumbermalang"
  },
  {
    "id": "3511122004",
    "district_id": "351112",
    "label": "Jambewungu",
    "value": "Jambewungu"
  },
  {
    "id": "3511122005",
    "district_id": "351112",
    "label": "Gubrih",
    "value": "Gubrih"
  },
  {
    "id": "3511122006",
    "district_id": "351112",
    "label": "Ampelan",
    "value": "Ampelan"
  },
  {
    "id": "3511122007",
    "district_id": "351112",
    "label": "Jatitamban",
    "value": "Jatitamban"
  },
  {
    "id": "3511122008",
    "district_id": "351112",
    "label": "Banyuwulu",
    "value": "Banyuwulu"
  },
  {
    "id": "3511122009",
    "district_id": "351112",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3511122010",
    "district_id": "351112",
    "label": "Glingseran",
    "value": "Glingseran"
  },
  {
    "id": "3511122011",
    "district_id": "351112",
    "label": "Banyuputih",
    "value": "Banyuputih"
  },
  {
    "id": "3511122012",
    "district_id": "351112",
    "label": "Wringin",
    "value": "Wringin"
  },
  {
    "id": "3511122013",
    "district_id": "351112",
    "label": "Sumbercanting",
    "value": "Sumbercanting"
  },
  {
    "id": "3511131005",
    "district_id": "351113",
    "label": "Sekarputih",
    "value": "Sekarputih"
  },
  {
    "id": "3511132001",
    "district_id": "351113",
    "label": "Klabang",
    "value": "Klabang"
  },
  {
    "id": "3511132002",
    "district_id": "351113",
    "label": "Mandiro",
    "value": "Mandiro"
  },
  {
    "id": "3511132003",
    "district_id": "351113",
    "label": "Tanggulangin",
    "value": "Tanggulangin"
  },
  {
    "id": "3511132004",
    "district_id": "351113",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3511132006",
    "district_id": "351113",
    "label": "Tegalampel",
    "value": "Tegalampel"
  },
  {
    "id": "3511132014",
    "district_id": "351113",
    "label": "Klabang Agung",
    "value": "Klabang Agung"
  },
  {
    "id": "3511132015",
    "district_id": "351113",
    "label": "Purnama",
    "value": "Purnama"
  },
  {
    "id": "3511142005",
    "district_id": "351114",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3511142006",
    "district_id": "351114",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3511142007",
    "district_id": "351114",
    "label": "Karang Sengon",
    "value": "Karang Sengon"
  },
  {
    "id": "3511142008",
    "district_id": "351114",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3511142009",
    "district_id": "351114",
    "label": "Klabang",
    "value": "Klabang"
  },
  {
    "id": "3511142010",
    "district_id": "351114",
    "label": "Klampokan",
    "value": "Klampokan"
  },
  {
    "id": "3511142011",
    "district_id": "351114",
    "label": "Sumber Suko",
    "value": "Sumber Suko"
  },
  {
    "id": "3511142012",
    "district_id": "351114",
    "label": "Besuk",
    "value": "Besuk"
  },
  {
    "id": "3511142013",
    "district_id": "351114",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "3511142014",
    "district_id": "351114",
    "label": "Leprak",
    "value": "Leprak"
  },
  {
    "id": "3511142015",
    "district_id": "351114",
    "label": "Wonoboyo",
    "value": "Wonoboyo"
  },
  {
    "id": "3511152001",
    "district_id": "351115",
    "label": "Solor",
    "value": "Solor"
  },
  {
    "id": "3511152002",
    "district_id": "351115",
    "label": "Kladi",
    "value": "Kladi"
  },
  {
    "id": "3511152003",
    "district_id": "351115",
    "label": "Bercak",
    "value": "Bercak"
  },
  {
    "id": "3511152004",
    "district_id": "351115",
    "label": "Suling Wetan",
    "value": "Suling Wetan"
  },
  {
    "id": "3511152005",
    "district_id": "351115",
    "label": "Suling Kulon",
    "value": "Suling Kulon"
  },
  {
    "id": "3511152006",
    "district_id": "351115",
    "label": "Cermee",
    "value": "Cermee"
  },
  {
    "id": "3511152007",
    "district_id": "351115",
    "label": "Ramban Wetan",
    "value": "Ramban Wetan"
  },
  {
    "id": "3511152008",
    "district_id": "351115",
    "label": "Grujugan",
    "value": "Grujugan"
  },
  {
    "id": "3511152009",
    "district_id": "351115",
    "label": "Ramban Kulon",
    "value": "Ramban Kulon"
  },
  {
    "id": "3511152010",
    "district_id": "351115",
    "label": "Bajuran",
    "value": "Bajuran"
  },
  {
    "id": "3511152011",
    "district_id": "351115",
    "label": "Jirek Mas",
    "value": "Jirek Mas"
  },
  {
    "id": "3511152012",
    "district_id": "351115",
    "label": "Batu Salang",
    "value": "Batu Salang"
  },
  {
    "id": "3511152013",
    "district_id": "351115",
    "label": "Palalangan",
    "value": "Palalangan"
  },
  {
    "id": "3511152014",
    "district_id": "351115",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "3511152015",
    "district_id": "351115",
    "label": "Bercak Asri",
    "value": "Bercak Asri"
  },
  {
    "id": "3511162001",
    "district_id": "351116",
    "label": "Bandilan",
    "value": "Bandilan"
  },
  {
    "id": "3511162002",
    "district_id": "351116",
    "label": "Sempol",
    "value": "Sempol"
  },
  {
    "id": "3511162003",
    "district_id": "351116",
    "label": "Tarum",
    "value": "Tarum"
  },
  {
    "id": "3511162005",
    "district_id": "351116",
    "label": "Prajekan Lor",
    "value": "Prajekan Lor"
  },
  {
    "id": "3511162006",
    "district_id": "351116",
    "label": "Prajekan Kidul",
    "value": "Prajekan Kidul"
  },
  {
    "id": "3511162007",
    "district_id": "351116",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3511162008",
    "district_id": "351116",
    "label": "Walidono",
    "value": "Walidono"
  },
  {
    "id": "3511172001",
    "district_id": "351117",
    "label": "Andungsari",
    "value": "Andungsari"
  },
  {
    "id": "3511172002",
    "district_id": "351117",
    "label": "Ardisaeng",
    "value": "Ardisaeng"
  },
  {
    "id": "3511172003",
    "district_id": "351117",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "3511172004",
    "district_id": "351117",
    "label": "Gadingsari",
    "value": "Gadingsari"
  },
  {
    "id": "3511172005",
    "district_id": "351117",
    "label": "Pakem",
    "value": "Pakem"
  },
  {
    "id": "3511172006",
    "district_id": "351117",
    "label": "Sumberdumpyong",
    "value": "Sumberdumpyong"
  },
  {
    "id": "3511172007",
    "district_id": "351117",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3511172008",
    "district_id": "351117",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3511182001",
    "district_id": "351118",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3511182002",
    "district_id": "351118",
    "label": "Sumber Gading",
    "value": "Sumber Gading"
  },
  {
    "id": "3511182003",
    "district_id": "351118",
    "label": "Sukosari Kidul",
    "value": "Sukosari Kidul"
  },
  {
    "id": "3511182004",
    "district_id": "351118",
    "label": "Tegaljati",
    "value": "Tegaljati"
  },
  {
    "id": "3511182005",
    "district_id": "351118",
    "label": "Rejo Agung",
    "value": "Rejo Agung"
  },
  {
    "id": "3511182006",
    "district_id": "351118",
    "label": "Sumberwringin",
    "value": "Sumberwringin"
  },
  {
    "id": "3511192001",
    "district_id": "351119",
    "label": "Sempol",
    "value": "Sempol"
  },
  {
    "id": "3511192002",
    "district_id": "351119",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3511192003",
    "district_id": "351119",
    "label": "Jampit",
    "value": "Jampit"
  },
  {
    "id": "3511192004",
    "district_id": "351119",
    "label": "Kalisat",
    "value": "Kalisat"
  },
  {
    "id": "3511192005",
    "district_id": "351119",
    "label": "Kali Gedang",
    "value": "Kali Gedang"
  },
  {
    "id": "3511192006",
    "district_id": "351119",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "3511202001",
    "district_id": "351120",
    "label": "Gadingsari",
    "value": "Gadingsari"
  },
  {
    "id": "3511202002",
    "district_id": "351120",
    "label": "Sumber Waru",
    "value": "Sumber Waru"
  },
  {
    "id": "3511202003",
    "district_id": "351120",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3511202004",
    "district_id": "351120",
    "label": "Baratan",
    "value": "Baratan"
  },
  {
    "id": "3511202005",
    "district_id": "351120",
    "label": "Binakal",
    "value": "Binakal"
  },
  {
    "id": "3511202006",
    "district_id": "351120",
    "label": "Jeruksoksok",
    "value": "Jeruksoksok"
  },
  {
    "id": "3511202007",
    "district_id": "351120",
    "label": "Sumber Tengah",
    "value": "Sumber Tengah"
  },
  {
    "id": "3511202008",
    "district_id": "351120",
    "label": "Bendelan",
    "value": "Bendelan"
  },
  {
    "id": "3511212001",
    "district_id": "351121",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3511212002",
    "district_id": "351121",
    "label": "Gentong",
    "value": "Gentong"
  },
  {
    "id": "3511212003",
    "district_id": "351121",
    "label": "Kemuningan",
    "value": "Kemuningan"
  },
  {
    "id": "3511212004",
    "district_id": "351121",
    "label": "Trebungan",
    "value": "Trebungan"
  },
  {
    "id": "3511212005",
    "district_id": "351121",
    "label": "Sumberkokap",
    "value": "Sumberkokap"
  },
  {
    "id": "3511212006",
    "district_id": "351121",
    "label": "Paguan",
    "value": "Paguan"
  },
  {
    "id": "3511212007",
    "district_id": "351121",
    "label": "Kretek",
    "value": "Kretek"
  },
  {
    "id": "3511222001",
    "district_id": "351122",
    "label": "Lumutan",
    "value": "Lumutan"
  },
  {
    "id": "3511222002",
    "district_id": "351122",
    "label": "Botolinggo",
    "value": "Botolinggo"
  },
  {
    "id": "3511222003",
    "district_id": "351122",
    "label": "Lanas",
    "value": "Lanas"
  },
  {
    "id": "3511222004",
    "district_id": "351122",
    "label": "Penang",
    "value": "Penang"
  },
  {
    "id": "3511222005",
    "district_id": "351122",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3511222006",
    "district_id": "351122",
    "label": "Klekehan",
    "value": "Klekehan"
  },
  {
    "id": "3511222007",
    "district_id": "351122",
    "label": "Sumber Canting",
    "value": "Sumber Canting"
  },
  {
    "id": "3511222008",
    "district_id": "351122",
    "label": "Gayam Lor",
    "value": "Gayam Lor"
  },
  {
    "id": "3511232001",
    "district_id": "351123",
    "label": "Jambesari",
    "value": "Jambesari"
  },
  {
    "id": "3511232002",
    "district_id": "351123",
    "label": "Jambeanom",
    "value": "Jambeanom"
  },
  {
    "id": "3511232003",
    "district_id": "351123",
    "label": "Pucanganom",
    "value": "Pucanganom"
  },
  {
    "id": "3511232004",
    "district_id": "351123",
    "label": "Sumberjeruk",
    "value": "Sumberjeruk"
  },
  {
    "id": "3511232005",
    "district_id": "351123",
    "label": "Tegalpasir",
    "value": "Tegalpasir"
  },
  {
    "id": "3511232006",
    "district_id": "351123",
    "label": "Pengarang",
    "value": "Pengarang"
  },
  {
    "id": "3511232007",
    "district_id": "351123",
    "label": "Grujugan Lor",
    "value": "Grujugan Lor"
  },
  {
    "id": "3511232008",
    "district_id": "351123",
    "label": "Pejagan",
    "value": "Pejagan"
  },
  {
    "id": "3511232009",
    "district_id": "351123",
    "label": "Sumber Anyar",
    "value": "Sumber Anyar"
  },
  {
    "id": "3512012001",
    "district_id": "351201",
    "label": "Pategalan",
    "value": "Pategalan"
  },
  {
    "id": "3512012002",
    "district_id": "351201",
    "label": "Semambung",
    "value": "Semambung"
  },
  {
    "id": "3512012003",
    "district_id": "351201",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3512012004",
    "district_id": "351201",
    "label": "Jatibanteng",
    "value": "Jatibanteng"
  },
  {
    "id": "3512012005",
    "district_id": "351201",
    "label": "Curahsuri",
    "value": "Curahsuri"
  },
  {
    "id": "3512012006",
    "district_id": "351201",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3512012007",
    "district_id": "351201",
    "label": "Kembangsari",
    "value": "Kembangsari"
  },
  {
    "id": "3512012008",
    "district_id": "351201",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3512022001",
    "district_id": "351202",
    "label": "Widoropayung",
    "value": "Widoropayung"
  },
  {
    "id": "3512022002",
    "district_id": "351202",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3512022003",
    "district_id": "351202",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3512022004",
    "district_id": "351202",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3512022005",
    "district_id": "351202",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "3512022006",
    "district_id": "351202",
    "label": "Bloro",
    "value": "Bloro"
  },
  {
    "id": "3512022007",
    "district_id": "351202",
    "label": "Pesisir",
    "value": "Pesisir"
  },
  {
    "id": "3512022008",
    "district_id": "351202",
    "label": "Kalimas",
    "value": "Kalimas"
  },
  {
    "id": "3512022009",
    "district_id": "351202",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3512022010",
    "district_id": "351202",
    "label": "Demung",
    "value": "Demung"
  },
  {
    "id": "3512032001",
    "district_id": "351203",
    "label": "Cemara",
    "value": "Cemara"
  },
  {
    "id": "3512032002",
    "district_id": "351203",
    "label": "Mojodungkul",
    "value": "Mojodungkul"
  },
  {
    "id": "3512032003",
    "district_id": "351203",
    "label": "Gunung Malang",
    "value": "Gunung Malang"
  },
  {
    "id": "3512032004",
    "district_id": "351203",
    "label": "Gunung Putri",
    "value": "Gunung Putri"
  },
  {
    "id": "3512032005",
    "district_id": "351203",
    "label": "Suboh",
    "value": "Suboh"
  },
  {
    "id": "3512032006",
    "district_id": "351203",
    "label": "Dawuan",
    "value": "Dawuan"
  },
  {
    "id": "3512032007",
    "district_id": "351203",
    "label": "Buduan",
    "value": "Buduan"
  },
  {
    "id": "3512032008",
    "district_id": "351203",
    "label": "Ketah",
    "value": "Ketah"
  },
  {
    "id": "3512042001",
    "district_id": "351204",
    "label": "Alas Bayur",
    "value": "Alas Bayur"
  },
  {
    "id": "3512042002",
    "district_id": "351204",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3512042003",
    "district_id": "351204",
    "label": "Campoan",
    "value": "Campoan"
  },
  {
    "id": "3512042004",
    "district_id": "351204",
    "label": "Trebungan",
    "value": "Trebungan"
  },
  {
    "id": "3512042005",
    "district_id": "351204",
    "label": "Sumber Pinang",
    "value": "Sumber Pinang"
  },
  {
    "id": "3512042006",
    "district_id": "351204",
    "label": "Selomukti",
    "value": "Selomukti"
  },
  {
    "id": "3512042007",
    "district_id": "351204",
    "label": "Mlandingan Kulon",
    "value": "Mlandingan Kulon"
  },
  {
    "id": "3512052001",
    "district_id": "351205",
    "label": "Bugeman",
    "value": "Bugeman"
  },
  {
    "id": "3512052002",
    "district_id": "351205",
    "label": "Kendit",
    "value": "Kendit"
  },
  {
    "id": "3512052003",
    "district_id": "351205",
    "label": "Balung",
    "value": "Balung"
  },
  {
    "id": "3512052004",
    "district_id": "351205",
    "label": "Tambak Ukir",
    "value": "Tambak Ukir"
  },
  {
    "id": "3512052005",
    "district_id": "351205",
    "label": "Rajekwesi",
    "value": "Rajekwesi"
  },
  {
    "id": "3512052006",
    "district_id": "351205",
    "label": "Kukusan",
    "value": "Kukusan"
  },
  {
    "id": "3512052007",
    "district_id": "351205",
    "label": "Klatakan",
    "value": "Klatakan"
  },
  {
    "id": "3512062001",
    "district_id": "351206",
    "label": "Paowan",
    "value": "Paowan"
  },
  {
    "id": "3512062002",
    "district_id": "351206",
    "label": "Sumberkolak",
    "value": "Sumberkolak"
  },
  {
    "id": "3512062003",
    "district_id": "351206",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3512062004",
    "district_id": "351206",
    "label": "Kilensari",
    "value": "Kilensari"
  },
  {
    "id": "3512062005",
    "district_id": "351206",
    "label": "Peleyan",
    "value": "Peleyan"
  },
  {
    "id": "3512062006",
    "district_id": "351206",
    "label": "Alasmalang",
    "value": "Alasmalang"
  },
  {
    "id": "3512062007",
    "district_id": "351206",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3512062008",
    "district_id": "351206",
    "label": "Gelung",
    "value": "Gelung"
  },
  {
    "id": "3512071002",
    "district_id": "351207",
    "label": "Patokan",
    "value": "Patokan"
  },
  {
    "id": "3512071003",
    "district_id": "351207",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3512072001",
    "district_id": "351207",
    "label": "Kalibagor",
    "value": "Kalibagor"
  },
  {
    "id": "3512072004",
    "district_id": "351207",
    "label": "Kotakan",
    "value": "Kotakan"
  },
  {
    "id": "3512072005",
    "district_id": "351207",
    "label": "Talkandang",
    "value": "Talkandang"
  },
  {
    "id": "3512072006",
    "district_id": "351207",
    "label": "Olean",
    "value": "Olean"
  },
  {
    "id": "3512081002",
    "district_id": "351208",
    "label": "Ardirejo",
    "value": "Ardirejo"
  },
  {
    "id": "3512081007",
    "district_id": "351208",
    "label": "Mimbaan",
    "value": "Mimbaan"
  },
  {
    "id": "3512082001",
    "district_id": "351208",
    "label": "Sliwung",
    "value": "Sliwung"
  },
  {
    "id": "3512082003",
    "district_id": "351208",
    "label": "Battal",
    "value": "Battal"
  },
  {
    "id": "3512082004",
    "district_id": "351208",
    "label": "Klampokan",
    "value": "Klampokan"
  },
  {
    "id": "3512082005",
    "district_id": "351208",
    "label": "Juglangan",
    "value": "Juglangan"
  },
  {
    "id": "3512082006",
    "district_id": "351208",
    "label": "Panji Kidul",
    "value": "Panji Kidul"
  },
  {
    "id": "3512082008",
    "district_id": "351208",
    "label": "Panji Lor",
    "value": "Panji Lor"
  },
  {
    "id": "3512082009",
    "district_id": "351208",
    "label": "Tokelan",
    "value": "Tokelan"
  },
  {
    "id": "3512082010",
    "district_id": "351208",
    "label": "Curah Jeru",
    "value": "Curah Jeru"
  },
  {
    "id": "3512082011",
    "district_id": "351208",
    "label": "Tenggir",
    "value": "Tenggir"
  },
  {
    "id": "3512082012",
    "district_id": "351208",
    "label": "Kayu Putih",
    "value": "Kayu Putih"
  },
  {
    "id": "3512092001",
    "district_id": "351209",
    "label": "Tanjung Glugur",
    "value": "Tanjung Glugur"
  },
  {
    "id": "3512092002",
    "district_id": "351209",
    "label": "Mangaran",
    "value": "Mangaran"
  },
  {
    "id": "3512092003",
    "district_id": "351209",
    "label": "Tanjung Kamal",
    "value": "Tanjung Kamal"
  },
  {
    "id": "3512092004",
    "district_id": "351209",
    "label": "Semiring",
    "value": "Semiring"
  },
  {
    "id": "3512092005",
    "district_id": "351209",
    "label": "Tanjung Pecinan",
    "value": "Tanjung Pecinan"
  },
  {
    "id": "3512092006",
    "district_id": "351209",
    "label": "Trebungan",
    "value": "Trebungan"
  },
  {
    "id": "3512102001",
    "district_id": "351210",
    "label": "Kandang",
    "value": "Kandang"
  },
  {
    "id": "3512102002",
    "district_id": "351210",
    "label": "Curah Cotok",
    "value": "Curah Cotok"
  },
  {
    "id": "3512102003",
    "district_id": "351210",
    "label": "Peleyan",
    "value": "Peleyan"
  },
  {
    "id": "3512102004",
    "district_id": "351210",
    "label": "Wonokoyo",
    "value": "Wonokoyo"
  },
  {
    "id": "3512102005",
    "district_id": "351210",
    "label": "Seletreng",
    "value": "Seletreng"
  },
  {
    "id": "3512102006",
    "district_id": "351210",
    "label": "Landangan",
    "value": "Landangan"
  },
  {
    "id": "3512102007",
    "district_id": "351210",
    "label": "Kapongan",
    "value": "Kapongan"
  },
  {
    "id": "3512102008",
    "district_id": "351210",
    "label": "Kesambirampak",
    "value": "Kesambirampak"
  },
  {
    "id": "3512102009",
    "district_id": "351210",
    "label": "Gebangan",
    "value": "Gebangan"
  },
  {
    "id": "3512102010",
    "district_id": "351210",
    "label": "Pokaan",
    "value": "Pokaan"
  },
  {
    "id": "3512112001",
    "district_id": "351211",
    "label": "Kayumas",
    "value": "Kayumas"
  },
  {
    "id": "3512112002",
    "district_id": "351211",
    "label": "Bayeman",
    "value": "Bayeman"
  },
  {
    "id": "3512112003",
    "district_id": "351211",
    "label": "Ketowan",
    "value": "Ketowan"
  },
  {
    "id": "3512112004",
    "district_id": "351211",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3512112005",
    "district_id": "351211",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3512112006",
    "district_id": "351211",
    "label": "Curah Tatal",
    "value": "Curah Tatal"
  },
  {
    "id": "3512112007",
    "district_id": "351211",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "3512112008",
    "district_id": "351211",
    "label": "Lamongan",
    "value": "Lamongan"
  },
  {
    "id": "3512122001",
    "district_id": "351212",
    "label": "Sopet",
    "value": "Sopet"
  },
  {
    "id": "3512122002",
    "district_id": "351212",
    "label": "Curah Kalak",
    "value": "Curah Kalak"
  },
  {
    "id": "3512122003",
    "district_id": "351212",
    "label": "Palangan",
    "value": "Palangan"
  },
  {
    "id": "3512122004",
    "district_id": "351212",
    "label": "Jangkar",
    "value": "Jangkar"
  },
  {
    "id": "3512122005",
    "district_id": "351212",
    "label": "Gadingan",
    "value": "Gadingan"
  },
  {
    "id": "3512122006",
    "district_id": "351212",
    "label": "Kumbangsari",
    "value": "Kumbangsari"
  },
  {
    "id": "3512122007",
    "district_id": "351212",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3512122008",
    "district_id": "351212",
    "label": "Agel",
    "value": "Agel"
  },
  {
    "id": "3512132001",
    "district_id": "351213",
    "label": "Kedunglo",
    "value": "Kedunglo"
  },
  {
    "id": "3512132002",
    "district_id": "351213",
    "label": "Bantal",
    "value": "Bantal"
  },
  {
    "id": "3512132003",
    "district_id": "351213",
    "label": "Awar-awar",
    "value": "Awar-awar"
  },
  {
    "id": "3512132004",
    "district_id": "351213",
    "label": "Parante",
    "value": "Parante"
  },
  {
    "id": "3512132005",
    "district_id": "351213",
    "label": "Trigonco",
    "value": "Trigonco"
  },
  {
    "id": "3512132006",
    "district_id": "351213",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3512132007",
    "district_id": "351213",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3512132008",
    "district_id": "351213",
    "label": "Asembagus",
    "value": "Asembagus"
  },
  {
    "id": "3512132009",
    "district_id": "351213",
    "label": "Gudang",
    "value": "Gudang"
  },
  {
    "id": "3512132010",
    "district_id": "351213",
    "label": "Wringin Anom",
    "value": "Wringin Anom"
  },
  {
    "id": "3512142001",
    "district_id": "351214",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3512142002",
    "district_id": "351214",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3512142003",
    "district_id": "351214",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3512142004",
    "district_id": "351214",
    "label": "Banyuputih",
    "value": "Banyuputih"
  },
  {
    "id": "3512142005",
    "district_id": "351214",
    "label": "Sumberwaru",
    "value": "Sumberwaru"
  },
  {
    "id": "3512152001",
    "district_id": "351215",
    "label": "Tamankursi",
    "value": "Tamankursi"
  },
  {
    "id": "3512152002",
    "district_id": "351215",
    "label": "Sumberargo",
    "value": "Sumberargo"
  },
  {
    "id": "3512152003",
    "district_id": "351215",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3512152004",
    "district_id": "351215",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3512152005",
    "district_id": "351215",
    "label": "Baderan",
    "value": "Baderan"
  },
  {
    "id": "3512152006",
    "district_id": "351215",
    "label": "Alastengah",
    "value": "Alastengah"
  },
  {
    "id": "3512152007",
    "district_id": "351215",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3512152008",
    "district_id": "351215",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3512152009",
    "district_id": "351215",
    "label": "Plalangan",
    "value": "Plalangan"
  },
  {
    "id": "3512162001",
    "district_id": "351216",
    "label": "Lubawang",
    "value": "Lubawang"
  },
  {
    "id": "3512162002",
    "district_id": "351216",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3512162003",
    "district_id": "351216",
    "label": "Tepos",
    "value": "Tepos"
  },
  {
    "id": "3512162004",
    "district_id": "351216",
    "label": "Selabanteng",
    "value": "Selabanteng"
  },
  {
    "id": "3512162005",
    "district_id": "351216",
    "label": "Banyuglugur",
    "value": "Banyuglugur"
  },
  {
    "id": "3512162006",
    "district_id": "351216",
    "label": "Telempong",
    "value": "Telempong"
  },
  {
    "id": "3512162007",
    "district_id": "351216",
    "label": "Kalianget",
    "value": "Kalianget"
  },
  {
    "id": "3512172001",
    "district_id": "351217",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3512172002",
    "district_id": "351217",
    "label": "Sumbertengah",
    "value": "Sumbertengah"
  },
  {
    "id": "3512172003",
    "district_id": "351217",
    "label": "Selowogo",
    "value": "Selowogo"
  },
  {
    "id": "3512172004",
    "district_id": "351217",
    "label": "Mlandingan Wetan",
    "value": "Mlandingan Wetan"
  },
  {
    "id": "3512172005",
    "district_id": "351217",
    "label": "Bungatan",
    "value": "Bungatan"
  },
  {
    "id": "3512172006",
    "district_id": "351217",
    "label": "Bletok",
    "value": "Bletok"
  },
  {
    "id": "3512172007",
    "district_id": "351217",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "3513012001",
    "district_id": "351301",
    "label": "Ngadisari",
    "value": "Ngadisari"
  },
  {
    "id": "3513012002",
    "district_id": "351301",
    "label": "Wonotoro",
    "value": "Wonotoro"
  },
  {
    "id": "3513012003",
    "district_id": "351301",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3513012004",
    "district_id": "351301",
    "label": "Ngadas",
    "value": "Ngadas"
  },
  {
    "id": "3513012005",
    "district_id": "351301",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3513012006",
    "district_id": "351301",
    "label": "Sariwani",
    "value": "Sariwani"
  },
  {
    "id": "3513012007",
    "district_id": "351301",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3513012008",
    "district_id": "351301",
    "label": "Sapikerep",
    "value": "Sapikerep"
  },
  {
    "id": "3513012009",
    "district_id": "351301",
    "label": "Sukapura",
    "value": "Sukapura"
  },
  {
    "id": "3513012010",
    "district_id": "351301",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3513012011",
    "district_id": "351301",
    "label": "Kedasih",
    "value": "Kedasih"
  },
  {
    "id": "3513012012",
    "district_id": "351301",
    "label": "Ngepung",
    "value": "Ngepung"
  },
  {
    "id": "3513022001",
    "district_id": "351302",
    "label": "Ledokombo",
    "value": "Ledokombo"
  },
  {
    "id": "3513022002",
    "district_id": "351302",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3513022003",
    "district_id": "351302",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3513022004",
    "district_id": "351302",
    "label": "Wonokerso",
    "value": "Wonokerso"
  },
  {
    "id": "3513022005",
    "district_id": "351302",
    "label": "Gemito",
    "value": "Gemito"
  },
  {
    "id": "3513022006",
    "district_id": "351302",
    "label": "Tukul",
    "value": "Tukul"
  },
  {
    "id": "3513022007",
    "district_id": "351302",
    "label": "Sumberanom",
    "value": "Sumberanom"
  },
  {
    "id": "3513022008",
    "district_id": "351302",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3513022009",
    "district_id": "351302",
    "label": "Remba'an",
    "value": "Remba'an"
  },
  {
    "id": "3513032001",
    "district_id": "351303",
    "label": "Wonoasri",
    "value": "Wonoasri"
  },
  {
    "id": "3513032002",
    "district_id": "351303",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3513032003",
    "district_id": "351303",
    "label": "Kedawung",
    "value": "Kedawung"
  },
  {
    "id": "3513032004",
    "district_id": "351303",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3513032005",
    "district_id": "351303",
    "label": "Resongo",
    "value": "Resongo"
  },
  {
    "id": "3513032006",
    "district_id": "351303",
    "label": "Menyono",
    "value": "Menyono"
  },
  {
    "id": "3513032007",
    "district_id": "351303",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3513042001",
    "district_id": "351304",
    "label": "Gunungtugel",
    "value": "Gunungtugel"
  },
  {
    "id": "3513042002",
    "district_id": "351304",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3513042003",
    "district_id": "351304",
    "label": "Patokan",
    "value": "Patokan"
  },
  {
    "id": "3513042004",
    "district_id": "351304",
    "label": "Bantaran",
    "value": "Bantaran"
  },
  {
    "id": "3513042005",
    "district_id": "351304",
    "label": "Legundi",
    "value": "Legundi"
  },
  {
    "id": "3513042006",
    "district_id": "351304",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3513042007",
    "district_id": "351304",
    "label": "Kropak",
    "value": "Kropak"
  },
  {
    "id": "3513042008",
    "district_id": "351304",
    "label": "Besuk",
    "value": "Besuk"
  },
  {
    "id": "3513042009",
    "district_id": "351304",
    "label": "Kramatagung",
    "value": "Kramatagung"
  },
  {
    "id": "3513042010",
    "district_id": "351304",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3513052001",
    "district_id": "351305",
    "label": "Malasankulon",
    "value": "Malasankulon"
  },
  {
    "id": "3513052002",
    "district_id": "351305",
    "label": "Tigasan Wetan",
    "value": "Tigasan Wetan"
  },
  {
    "id": "3513052003",
    "district_id": "351305",
    "label": "Tigasan Kulon",
    "value": "Tigasan Kulon"
  },
  {
    "id": "3513052004",
    "district_id": "351305",
    "label": "Pondok Wuluh",
    "value": "Pondok Wuluh"
  },
  {
    "id": "3513052005",
    "district_id": "351305",
    "label": "Leces",
    "value": "Leces"
  },
  {
    "id": "3513052006",
    "district_id": "351305",
    "label": "Sumberkedawung",
    "value": "Sumberkedawung"
  },
  {
    "id": "3513052007",
    "district_id": "351305",
    "label": "Kerpangan",
    "value": "Kerpangan"
  },
  {
    "id": "3513052008",
    "district_id": "351305",
    "label": "Clarak",
    "value": "Clarak"
  },
  {
    "id": "3513052009",
    "district_id": "351305",
    "label": "Jorongan",
    "value": "Jorongan"
  },
  {
    "id": "3513052010",
    "district_id": "351305",
    "label": "Warujinggo",
    "value": "Warujinggo"
  },
  {
    "id": "3513062001",
    "district_id": "351306",
    "label": "Sentulan",
    "value": "Sentulan"
  },
  {
    "id": "3513062002",
    "district_id": "351306",
    "label": "Gadingkulon",
    "value": "Gadingkulon"
  },
  {
    "id": "3513062003",
    "district_id": "351306",
    "label": "Klenangkidul",
    "value": "Klenangkidul"
  },
  {
    "id": "3513062004",
    "district_id": "351306",
    "label": "Klenanglor",
    "value": "Klenanglor"
  },
  {
    "id": "3513062005",
    "district_id": "351306",
    "label": "Alassapi",
    "value": "Alassapi"
  },
  {
    "id": "3513062006",
    "district_id": "351306",
    "label": "Pendil",
    "value": "Pendil"
  },
  {
    "id": "3513062007",
    "district_id": "351306",
    "label": "Tarokan",
    "value": "Tarokan"
  },
  {
    "id": "3513062008",
    "district_id": "351306",
    "label": "Liprak Wetan",
    "value": "Liprak Wetan"
  },
  {
    "id": "3513062009",
    "district_id": "351306",
    "label": "Liprak Kidul",
    "value": "Liprak Kidul"
  },
  {
    "id": "3513062010",
    "district_id": "351306",
    "label": "Liprak Kulon",
    "value": "Liprak Kulon"
  },
  {
    "id": "3513062011",
    "district_id": "351306",
    "label": "Banyuanyar Tengah",
    "value": "Banyuanyar Tengah"
  },
  {
    "id": "3513062012",
    "district_id": "351306",
    "label": "Banyuanyar Kidul",
    "value": "Banyuanyar Kidul"
  },
  {
    "id": "3513062013",
    "district_id": "351306",
    "label": "Gununggeni",
    "value": "Gununggeni"
  },
  {
    "id": "3513062014",
    "district_id": "351306",
    "label": "Blado Wetan",
    "value": "Blado Wetan"
  },
  {
    "id": "3513072001",
    "district_id": "351307",
    "label": "Andungbiru",
    "value": "Andungbiru"
  },
  {
    "id": "3513072002",
    "district_id": "351307",
    "label": "Tlogoargo",
    "value": "Tlogoargo"
  },
  {
    "id": "3513072003",
    "district_id": "351307",
    "label": "Andungsari",
    "value": "Andungsari"
  },
  {
    "id": "3513072004",
    "district_id": "351307",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3513072005",
    "district_id": "351307",
    "label": "Ranugedang",
    "value": "Ranugedang"
  },
  {
    "id": "3513072006",
    "district_id": "351307",
    "label": "Tiris",
    "value": "Tiris"
  },
  {
    "id": "3513072007",
    "district_id": "351307",
    "label": "Segaran",
    "value": "Segaran"
  },
  {
    "id": "3513072008",
    "district_id": "351307",
    "label": "Ranuagung",
    "value": "Ranuagung"
  },
  {
    "id": "3513072009",
    "district_id": "351307",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  {
    "id": "3513072010",
    "district_id": "351307",
    "label": "Wedusan",
    "value": "Wedusan"
  },
  {
    "id": "3513072011",
    "district_id": "351307",
    "label": "Racek",
    "value": "Racek"
  },
  {
    "id": "3513072012",
    "district_id": "351307",
    "label": "Pesawahan",
    "value": "Pesawahan"
  },
  {
    "id": "3513072013",
    "district_id": "351307",
    "label": "Pedagangan",
    "value": "Pedagangan"
  },
  {
    "id": "3513072014",
    "district_id": "351307",
    "label": "Rejing",
    "value": "Rejing"
  },
  {
    "id": "3513072015",
    "district_id": "351307",
    "label": "Tegalwatu",
    "value": "Tegalwatu"
  },
  {
    "id": "3513072016",
    "district_id": "351307",
    "label": "Tuluparari",
    "value": "Tuluparari"
  },
  {
    "id": "3513082001",
    "district_id": "351308",
    "label": "Sumberduren",
    "value": "Sumberduren"
  },
  {
    "id": "3513082002",
    "district_id": "351308",
    "label": "Roto",
    "value": "Roto"
  },
  {
    "id": "3513082003",
    "district_id": "351308",
    "label": "Kertosuko",
    "value": "Kertosuko"
  },
  {
    "id": "3513082004",
    "district_id": "351308",
    "label": "Tambelang",
    "value": "Tambelang"
  },
  {
    "id": "3513082005",
    "district_id": "351308",
    "label": "Betek",
    "value": "Betek"
  },
  {
    "id": "3513082006",
    "district_id": "351308",
    "label": "Krucil",
    "value": "Krucil"
  },
  {
    "id": "3513082007",
    "district_id": "351308",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3513082008",
    "district_id": "351308",
    "label": "Watupanjang",
    "value": "Watupanjang"
  },
  {
    "id": "3513082009",
    "district_id": "351308",
    "label": "Bermi",
    "value": "Bermi"
  },
  {
    "id": "3513082010",
    "district_id": "351308",
    "label": "Kalianan",
    "value": "Kalianan"
  },
  {
    "id": "3513082011",
    "district_id": "351308",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3513082012",
    "district_id": "351308",
    "label": "Pandanaras",
    "value": "Pandanaras"
  },
  {
    "id": "3513082013",
    "district_id": "351308",
    "label": "Seneng",
    "value": "Seneng"
  },
  {
    "id": "3513082014",
    "district_id": "351308",
    "label": "Krobungan",
    "value": "Krobungan"
  },
  {
    "id": "3513092001",
    "district_id": "351309",
    "label": "Batur",
    "value": "Batur"
  },
  {
    "id": "3513092002",
    "district_id": "351309",
    "label": "Betek Taman",
    "value": "Betek Taman"
  },
  {
    "id": "3513092003",
    "district_id": "351309",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3513092004",
    "district_id": "351309",
    "label": "Dandang",
    "value": "Dandang"
  },
  {
    "id": "3513092005",
    "district_id": "351309",
    "label": "Kertosono",
    "value": "Kertosono"
  },
  {
    "id": "3513092006",
    "district_id": "351309",
    "label": "Prasi",
    "value": "Prasi"
  },
  {
    "id": "3513092007",
    "district_id": "351309",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3513092008",
    "district_id": "351309",
    "label": "Renteng",
    "value": "Renteng"
  },
  {
    "id": "3513092009",
    "district_id": "351309",
    "label": "Bulupadak",
    "value": "Bulupadak"
  },
  {
    "id": "3513092010",
    "district_id": "351309",
    "label": "Keben",
    "value": "Keben"
  },
  {
    "id": "3513092011",
    "district_id": "351309",
    "label": "Gadingwetan",
    "value": "Gadingwetan"
  },
  {
    "id": "3513092012",
    "district_id": "351309",
    "label": "Wangkal",
    "value": "Wangkal"
  },
  {
    "id": "3513092013",
    "district_id": "351309",
    "label": "Nogosaren",
    "value": "Nogosaren"
  },
  {
    "id": "3513092014",
    "district_id": "351309",
    "label": "Mojolegi",
    "value": "Mojolegi"
  },
  {
    "id": "3513092015",
    "district_id": "351309",
    "label": "Sumbersecang",
    "value": "Sumbersecang"
  },
  {
    "id": "3513092016",
    "district_id": "351309",
    "label": "Condong",
    "value": "Condong"
  },
  {
    "id": "3513092017",
    "district_id": "351309",
    "label": "Jurangjero",
    "value": "Jurangjero"
  },
  {
    "id": "3513092018",
    "district_id": "351309",
    "label": "Kaliancar",
    "value": "Kaliancar"
  },
  {
    "id": "3513092019",
    "district_id": "351309",
    "label": "Ranuwurung",
    "value": "Ranuwurung"
  },
  {
    "id": "3513102001",
    "district_id": "351310",
    "label": "Ranon",
    "value": "Ranon"
  },
  {
    "id": "3513102002",
    "district_id": "351310",
    "label": "Kedungsumur",
    "value": "Kedungsumur"
  },
  {
    "id": "3513102003",
    "district_id": "351310",
    "label": "Gunggungan Kidul",
    "value": "Gunggungan Kidul"
  },
  {
    "id": "3513102004",
    "district_id": "351310",
    "label": "Gunggungan Lor",
    "value": "Gunggungan Lor"
  },
  {
    "id": "3513102005",
    "district_id": "351310",
    "label": "Petemon Kulon",
    "value": "Petemon Kulon"
  },
  {
    "id": "3513102006",
    "district_id": "351310",
    "label": "Pakuniran",
    "value": "Pakuniran"
  },
  {
    "id": "3513102007",
    "district_id": "351310",
    "label": "Alaspandan",
    "value": "Alaspandan"
  },
  {
    "id": "3513102008",
    "district_id": "351310",
    "label": "Sumberkembar",
    "value": "Sumberkembar"
  },
  {
    "id": "3513102009",
    "district_id": "351310",
    "label": "Sogaan",
    "value": "Sogaan"
  },
  {
    "id": "3513102010",
    "district_id": "351310",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "3513102011",
    "district_id": "351310",
    "label": "Bucor Kulon",
    "value": "Bucor Kulon"
  },
  {
    "id": "3513102012",
    "district_id": "351310",
    "label": "Bucor Wetan",
    "value": "Bucor Wetan"
  },
  {
    "id": "3513102013",
    "district_id": "351310",
    "label": "Bimo",
    "value": "Bimo"
  },
  {
    "id": "3513102014",
    "district_id": "351310",
    "label": "Kertonegoro",
    "value": "Kertonegoro"
  },
  {
    "id": "3513102015",
    "district_id": "351310",
    "label": "Gondosuli",
    "value": "Gondosuli"
  },
  {
    "id": "3513102016",
    "district_id": "351310",
    "label": "Kalidandan",
    "value": "Kalidandan"
  },
  {
    "id": "3513102017",
    "district_id": "351310",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3513112001",
    "district_id": "351311",
    "label": "Sumber Centeng",
    "value": "Sumber Centeng"
  },
  {
    "id": "3513112002",
    "district_id": "351311",
    "label": "Sambirampak Kidul",
    "value": "Sambirampak Kidul"
  },
  {
    "id": "3513112003",
    "district_id": "351311",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3513112004",
    "district_id": "351311",
    "label": "Tambakukir",
    "value": "Tambakukir"
  },
  {
    "id": "3513112005",
    "district_id": "351311",
    "label": "Curahtemu",
    "value": "Curahtemu"
  },
  {
    "id": "3513112006",
    "district_id": "351311",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3513112007",
    "district_id": "351311",
    "label": "Sambirampak Lor",
    "value": "Sambirampak Lor"
  },
  {
    "id": "3513112008",
    "district_id": "351311",
    "label": "Kedungrejoso",
    "value": "Kedungrejoso"
  },
  {
    "id": "3513112009",
    "district_id": "351311",
    "label": "Talkandang",
    "value": "Talkandang"
  },
  {
    "id": "3513112010",
    "district_id": "351311",
    "label": "Triwungan",
    "value": "Triwungan"
  },
  {
    "id": "3513112011",
    "district_id": "351311",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3513112012",
    "district_id": "351311",
    "label": "Pasembon",
    "value": "Pasembon"
  },
  {
    "id": "3513112013",
    "district_id": "351311",
    "label": "Kotaanyar",
    "value": "Kotaanyar"
  },
  {
    "id": "3513122001",
    "district_id": "351312",
    "label": "Jabungsisir",
    "value": "Jabungsisir"
  },
  {
    "id": "3513122002",
    "district_id": "351312",
    "label": "Jabungcandi",
    "value": "Jabungcandi"
  },
  {
    "id": "3513122003",
    "district_id": "351312",
    "label": "Jabung Wetan",
    "value": "Jabung Wetan"
  },
  {
    "id": "3513122004",
    "district_id": "351312",
    "label": "Kalikajar Kulon",
    "value": "Kalikajar Kulon"
  },
  {
    "id": "3513122005",
    "district_id": "351312",
    "label": "Kalikajar Wetan",
    "value": "Kalikajar Wetan"
  },
  {
    "id": "3513122006",
    "district_id": "351312",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3513122007",
    "district_id": "351312",
    "label": "Alastengah",
    "value": "Alastengah"
  },
  {
    "id": "3513122008",
    "district_id": "351312",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3513122009",
    "district_id": "351312",
    "label": "Randumerak",
    "value": "Randumerak"
  },
  {
    "id": "3513122010",
    "district_id": "351312",
    "label": "Randutatah",
    "value": "Randutatah"
  },
  {
    "id": "3513122011",
    "district_id": "351312",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3513122012",
    "district_id": "351312",
    "label": "Plampang",
    "value": "Plampang"
  },
  {
    "id": "3513122013",
    "district_id": "351312",
    "label": "Petunjungan",
    "value": "Petunjungan"
  },
  {
    "id": "3513122014",
    "district_id": "351312",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3513122015",
    "district_id": "351312",
    "label": "Paiton",
    "value": "Paiton"
  },
  {
    "id": "3513122016",
    "district_id": "351312",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3513122017",
    "district_id": "351312",
    "label": "Podokkelor",
    "value": "Podokkelor"
  },
  {
    "id": "3513122018",
    "district_id": "351312",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3513122019",
    "district_id": "351312",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3513122020",
    "district_id": "351312",
    "label": "Bhinor",
    "value": "Bhinor"
  },
  {
    "id": "3513132001",
    "district_id": "351313",
    "label": "Bago",
    "value": "Bago"
  },
  {
    "id": "3513132002",
    "district_id": "351313",
    "label": "Kecik",
    "value": "Kecik"
  },
  {
    "id": "3513132003",
    "district_id": "351313",
    "label": "Alasnyiur",
    "value": "Alasnyiur"
  },
  {
    "id": "3513132004",
    "district_id": "351313",
    "label": "Sindetlami",
    "value": "Sindetlami"
  },
  {
    "id": "3513132005",
    "district_id": "351313",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3513132006",
    "district_id": "351313",
    "label": "Klampokan",
    "value": "Klampokan"
  },
  {
    "id": "3513132007",
    "district_id": "351313",
    "label": "Matekan",
    "value": "Matekan"
  },
  {
    "id": "3513132008",
    "district_id": "351313",
    "label": "Krampilan",
    "value": "Krampilan"
  },
  {
    "id": "3513132009",
    "district_id": "351313",
    "label": "Besukagung",
    "value": "Besukagung"
  },
  {
    "id": "3513132010",
    "district_id": "351313",
    "label": "Besukkidul",
    "value": "Besukkidul"
  },
  {
    "id": "3513132011",
    "district_id": "351313",
    "label": "Sumurdalam",
    "value": "Sumurdalam"
  },
  {
    "id": "3513132012",
    "district_id": "351313",
    "label": "Sindetanyar",
    "value": "Sindetanyar"
  },
  {
    "id": "3513132013",
    "district_id": "351313",
    "label": "Randujalak",
    "value": "Randujalak"
  },
  {
    "id": "3513132014",
    "district_id": "351313",
    "label": "Alastengah",
    "value": "Alastengah"
  },
  {
    "id": "3513132015",
    "district_id": "351313",
    "label": "Alaskandang",
    "value": "Alaskandang"
  },
  {
    "id": "3513132016",
    "district_id": "351313",
    "label": "Alassumurlor",
    "value": "Alassumurlor"
  },
  {
    "id": "3513132017",
    "district_id": "351313",
    "label": "Sumberan",
    "value": "Sumberan"
  },
  {
    "id": "3513141003",
    "district_id": "351314",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3513141005",
    "district_id": "351314",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3513141007",
    "district_id": "351314",
    "label": "Kraksaan Wetan",
    "value": "Kraksaan Wetan"
  },
  {
    "id": "3513141008",
    "district_id": "351314",
    "label": "Kandang Jati Kulon",
    "value": "Kandang Jati Kulon"
  },
  {
    "id": "3513141017",
    "district_id": "351314",
    "label": "Patokan",
    "value": "Patokan"
  },
  {
    "id": "3513142001",
    "district_id": "351314",
    "label": "Kregenan",
    "value": "Kregenan"
  },
  {
    "id": "3513142002",
    "district_id": "351314",
    "label": "Rondokuning",
    "value": "Rondokuning"
  },
  {
    "id": "3513142004",
    "district_id": "351314",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3513142006",
    "district_id": "351314",
    "label": "Rangkang",
    "value": "Rangkang"
  },
  {
    "id": "3513142009",
    "district_id": "351314",
    "label": "Kandang Jati Wetan",
    "value": "Kandang Jati Wetan"
  },
  {
    "id": "3513142010",
    "district_id": "351314",
    "label": "Alassumur Kulon",
    "value": "Alassumur Kulon"
  },
  {
    "id": "3513142011",
    "district_id": "351314",
    "label": "Sumberlele",
    "value": "Sumberlele"
  },
  {
    "id": "3513142012",
    "district_id": "351314",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3513142013",
    "district_id": "351314",
    "label": "Asembakor",
    "value": "Asembakor"
  },
  {
    "id": "3513142014",
    "district_id": "351314",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3513142015",
    "district_id": "351314",
    "label": "Sidopekso",
    "value": "Sidopekso"
  },
  {
    "id": "3513142016",
    "district_id": "351314",
    "label": "Kalibuntu",
    "value": "Kalibuntu"
  },
  {
    "id": "3513142018",
    "district_id": "351314",
    "label": "Asembagus",
    "value": "Asembagus"
  },
  {
    "id": "3513152001",
    "district_id": "351315",
    "label": "Temenggungan",
    "value": "Temenggungan"
  },
  {
    "id": "3513152002",
    "district_id": "351315",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3513152003",
    "district_id": "351315",
    "label": "Jatiurip",
    "value": "Jatiurip"
  },
  {
    "id": "3513152004",
    "district_id": "351315",
    "label": "Opo Opo",
    "value": "Opo Opo"
  },
  {
    "id": "3513152005",
    "district_id": "351315",
    "label": "Kamalkuning",
    "value": "Kamalkuning"
  },
  {
    "id": "3513152006",
    "district_id": "351315",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3513152007",
    "district_id": "351315",
    "label": "Krejengan",
    "value": "Krejengan"
  },
  {
    "id": "3513152008",
    "district_id": "351315",
    "label": "Sentong",
    "value": "Sentong"
  },
  {
    "id": "3513152009",
    "district_id": "351315",
    "label": "Sumberkatimoho",
    "value": "Sumberkatimoho"
  },
  {
    "id": "3513152010",
    "district_id": "351315",
    "label": "Karangren",
    "value": "Karangren"
  },
  {
    "id": "3513152011",
    "district_id": "351315",
    "label": "Rawan",
    "value": "Rawan"
  },
  {
    "id": "3513152012",
    "district_id": "351315",
    "label": "Seboro",
    "value": "Seboro"
  },
  {
    "id": "3513152013",
    "district_id": "351315",
    "label": "Kedungcaluk",
    "value": "Kedungcaluk"
  },
  {
    "id": "3513152014",
    "district_id": "351315",
    "label": "Widoro",
    "value": "Widoro"
  },
  {
    "id": "3513152015",
    "district_id": "351315",
    "label": "Gebangan",
    "value": "Gebangan"
  },
  {
    "id": "3513152016",
    "district_id": "351315",
    "label": "Duwuhan",
    "value": "Duwuhan"
  },
  {
    "id": "3513152017",
    "district_id": "351315",
    "label": "Soka'an",
    "value": "Soka'an"
  },
  {
    "id": "3513162001",
    "district_id": "351316",
    "label": "Selogudig Kulon",
    "value": "Selogudig Kulon"
  },
  {
    "id": "3513162002",
    "district_id": "351316",
    "label": "Selogudig Wetan",
    "value": "Selogudig Wetan"
  },
  {
    "id": "3513162003",
    "district_id": "351316",
    "label": "Ketompen",
    "value": "Ketompen"
  },
  {
    "id": "3513162004",
    "district_id": "351316",
    "label": "Karangbong",
    "value": "Karangbong"
  },
  {
    "id": "3513162005",
    "district_id": "351316",
    "label": "Karangpranti",
    "value": "Karangpranti"
  },
  {
    "id": "3513162006",
    "district_id": "351316",
    "label": "Gejugan",
    "value": "Gejugan"
  },
  {
    "id": "3513162007",
    "district_id": "351316",
    "label": "Karanggeger",
    "value": "Karanggeger"
  },
  {
    "id": "3513162008",
    "district_id": "351316",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3513162009",
    "district_id": "351316",
    "label": "Pejarakan Kulon",
    "value": "Pejarakan Kulon"
  },
  {
    "id": "3513162010",
    "district_id": "351316",
    "label": "Sukokerto",
    "value": "Sukokerto"
  },
  {
    "id": "3513162011",
    "district_id": "351316",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3513162012",
    "district_id": "351316",
    "label": "Penambangan",
    "value": "Penambangan"
  },
  {
    "id": "3513172001",
    "district_id": "351317",
    "label": "Brabe",
    "value": "Brabe"
  },
  {
    "id": "3513172002",
    "district_id": "351317",
    "label": "Gerongan",
    "value": "Gerongan"
  },
  {
    "id": "3513172003",
    "district_id": "351317",
    "label": "Maron Kidul",
    "value": "Maron Kidul"
  },
  {
    "id": "3513172004",
    "district_id": "351317",
    "label": "Sumberdawe",
    "value": "Sumberdawe"
  },
  {
    "id": "3513172005",
    "district_id": "351317",
    "label": "Sumberpoh",
    "value": "Sumberpoh"
  },
  {
    "id": "3513172006",
    "district_id": "351317",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3513172007",
    "district_id": "351317",
    "label": "Maron Kulon",
    "value": "Maron Kulon"
  },
  {
    "id": "3513172008",
    "district_id": "351317",
    "label": "Maron Wetan",
    "value": "Maron Wetan"
  },
  {
    "id": "3513172009",
    "district_id": "351317",
    "label": "Brani Kulon",
    "value": "Brani Kulon"
  },
  {
    "id": "3513172010",
    "district_id": "351317",
    "label": "Satreyan",
    "value": "Satreyan"
  },
  {
    "id": "3513172011",
    "district_id": "351317",
    "label": "Brani Wetan",
    "value": "Brani Wetan"
  },
  {
    "id": "3513172012",
    "district_id": "351317",
    "label": "Puspan",
    "value": "Puspan"
  },
  {
    "id": "3513172013",
    "district_id": "351317",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3513172014",
    "district_id": "351317",
    "label": "Brumbungan Kidul",
    "value": "Brumbungan Kidul"
  },
  {
    "id": "3513172015",
    "district_id": "351317",
    "label": "Pegalangan Kidul",
    "value": "Pegalangan Kidul"
  },
  {
    "id": "3513172016",
    "district_id": "351317",
    "label": "Suko",
    "value": "Suko"
  },
  {
    "id": "3513172017",
    "district_id": "351317",
    "label": "Ganting Kulon",
    "value": "Ganting Kulon"
  },
  {
    "id": "3513172018",
    "district_id": "351317",
    "label": "Ganting Wetan",
    "value": "Ganting Wetan"
  },
  {
    "id": "3513182001",
    "district_id": "351318",
    "label": "Brumbungan Lor",
    "value": "Brumbungan Lor"
  },
  {
    "id": "3513182002",
    "district_id": "351318",
    "label": "Jatiadi",
    "value": "Jatiadi"
  },
  {
    "id": "3513182003",
    "district_id": "351318",
    "label": "Klaseman",
    "value": "Klaseman"
  },
  {
    "id": "3513182004",
    "district_id": "351318",
    "label": "Pesisir",
    "value": "Pesisir"
  },
  {
    "id": "3513182005",
    "district_id": "351318",
    "label": "Bulang",
    "value": "Bulang"
  },
  {
    "id": "3513182006",
    "district_id": "351318",
    "label": "Randupitu",
    "value": "Randupitu"
  },
  {
    "id": "3513182007",
    "district_id": "351318",
    "label": "Pikatan",
    "value": "Pikatan"
  },
  {
    "id": "3513182008",
    "district_id": "351318",
    "label": "Sebaung",
    "value": "Sebaung"
  },
  {
    "id": "3513182009",
    "district_id": "351318",
    "label": "Sumberkerang",
    "value": "Sumberkerang"
  },
  {
    "id": "3513182010",
    "district_id": "351318",
    "label": "Banyuanyar Lor",
    "value": "Banyuanyar Lor"
  },
  {
    "id": "3513182011",
    "district_id": "351318",
    "label": "Curahsawo",
    "value": "Curahsawo"
  },
  {
    "id": "3513182012",
    "district_id": "351318",
    "label": "Pajurangan",
    "value": "Pajurangan"
  },
  {
    "id": "3513182013",
    "district_id": "351318",
    "label": "Gending",
    "value": "Gending"
  },
  {
    "id": "3513192001",
    "district_id": "351319",
    "label": "Watuwungkuk",
    "value": "Watuwungkuk"
  },
  {
    "id": "3513192002",
    "district_id": "351319",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3513192003",
    "district_id": "351319",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3513192004",
    "district_id": "351319",
    "label": "Ngepoh",
    "value": "Ngepoh"
  },
  {
    "id": "3513192005",
    "district_id": "351319",
    "label": "Mrangonlawang",
    "value": "Mrangonlawang"
  },
  {
    "id": "3513192006",
    "district_id": "351319",
    "label": "Sekarkare",
    "value": "Sekarkare"
  },
  {
    "id": "3513192007",
    "district_id": "351319",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3513192008",
    "district_id": "351319",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3513192009",
    "district_id": "351319",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3513192010",
    "district_id": "351319",
    "label": "Kedungdalem",
    "value": "Kedungdalem"
  },
  {
    "id": "3513192011",
    "district_id": "351319",
    "label": "Kalisalam",
    "value": "Kalisalam"
  },
  {
    "id": "3513192012",
    "district_id": "351319",
    "label": "Randuputih",
    "value": "Randuputih"
  },
  {
    "id": "3513192013",
    "district_id": "351319",
    "label": "Dringu",
    "value": "Dringu"
  },
  {
    "id": "3513192014",
    "district_id": "351319",
    "label": "Pabean",
    "value": "Pabean"
  },
  {
    "id": "3513202001",
    "district_id": "351320",
    "label": "Malesanwetan",
    "value": "Malesanwetan"
  },
  {
    "id": "3513202002",
    "district_id": "351320",
    "label": "Gunungbekel",
    "value": "Gunungbekel"
  },
  {
    "id": "3513202003",
    "district_id": "351320",
    "label": "Tegalsono",
    "value": "Tegalsono"
  },
  {
    "id": "3513202004",
    "district_id": "351320",
    "label": "Bulujarankidul",
    "value": "Bulujarankidul"
  },
  {
    "id": "3513202005",
    "district_id": "351320",
    "label": "Bulujaranlor",
    "value": "Bulujaranlor"
  },
  {
    "id": "3513202006",
    "district_id": "351320",
    "label": "Paras",
    "value": "Paras"
  },
  {
    "id": "3513202007",
    "district_id": "351320",
    "label": "Tegalsiwalan",
    "value": "Tegalsiwalan"
  },
  {
    "id": "3513202008",
    "district_id": "351320",
    "label": "Banjarsawah",
    "value": "Banjarsawah"
  },
  {
    "id": "3513202009",
    "district_id": "351320",
    "label": "Sumberbulu",
    "value": "Sumberbulu"
  },
  {
    "id": "3513202010",
    "district_id": "351320",
    "label": "Sumberkledung",
    "value": "Sumberkledung"
  },
  {
    "id": "3513202011",
    "district_id": "351320",
    "label": "Bladokulon",
    "value": "Bladokulon"
  },
  {
    "id": "3513202012",
    "district_id": "351320",
    "label": "Tegalmojo",
    "value": "Tegalmojo"
  },
  {
    "id": "3513212001",
    "district_id": "351321",
    "label": "Sumberbendo",
    "value": "Sumberbendo"
  },
  {
    "id": "3513212002",
    "district_id": "351321",
    "label": "Jangur",
    "value": "Jangur"
  },
  {
    "id": "3513212003",
    "district_id": "351321",
    "label": "Muneng",
    "value": "Muneng"
  },
  {
    "id": "3513212004",
    "district_id": "351321",
    "label": "Munengkidul",
    "value": "Munengkidul"
  },
  {
    "id": "3513212005",
    "district_id": "351321",
    "label": "Pohsangitleres",
    "value": "Pohsangitleres"
  },
  {
    "id": "3513212006",
    "district_id": "351321",
    "label": "Laweyan",
    "value": "Laweyan"
  },
  {
    "id": "3513212007",
    "district_id": "351321",
    "label": "Sumurmati",
    "value": "Sumurmati"
  },
  {
    "id": "3513212008",
    "district_id": "351321",
    "label": "Mentor",
    "value": "Mentor"
  },
  {
    "id": "3513212009",
    "district_id": "351321",
    "label": "Ambulu",
    "value": "Ambulu"
  },
  {
    "id": "3513212010",
    "district_id": "351321",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3513212011",
    "district_id": "351321",
    "label": "Lemahkembar",
    "value": "Lemahkembar"
  },
  {
    "id": "3513212012",
    "district_id": "351321",
    "label": "Pesisir",
    "value": "Pesisir"
  },
  {
    "id": "3513212013",
    "district_id": "351321",
    "label": "Giliketapang",
    "value": "Giliketapang"
  },
  {
    "id": "3513222001",
    "district_id": "351322",
    "label": "Sumberkare",
    "value": "Sumberkare"
  },
  {
    "id": "3513222002",
    "district_id": "351322",
    "label": "Patalan",
    "value": "Patalan"
  },
  {
    "id": "3513222003",
    "district_id": "351322",
    "label": "Jrebeng",
    "value": "Jrebeng"
  },
  {
    "id": "3513222004",
    "district_id": "351322",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3513222005",
    "district_id": "351322",
    "label": "Tunggakcerme",
    "value": "Tunggakcerme"
  },
  {
    "id": "3513222006",
    "district_id": "351322",
    "label": "Pohsangit Tengah",
    "value": "Pohsangit Tengah"
  },
  {
    "id": "3513222007",
    "district_id": "351322",
    "label": "Pohsangit Lor",
    "value": "Pohsangit Lor"
  },
  {
    "id": "3513222008",
    "district_id": "351322",
    "label": "Pohsangit Ngisor",
    "value": "Pohsangit Ngisor"
  },
  {
    "id": "3513222009",
    "district_id": "351322",
    "label": "Sepuhgembol",
    "value": "Sepuhgembol"
  },
  {
    "id": "3513222010",
    "district_id": "351322",
    "label": "Kareng Kidul",
    "value": "Kareng Kidul"
  },
  {
    "id": "3513222011",
    "district_id": "351322",
    "label": "Kedungsupit",
    "value": "Kedungsupit"
  },
  {
    "id": "3513232001",
    "district_id": "351323",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3513232002",
    "district_id": "351323",
    "label": "Sumendi",
    "value": "Sumendi"
  },
  {
    "id": "3513232003",
    "district_id": "351323",
    "label": "Bayeman",
    "value": "Bayeman"
  },
  {
    "id": "3513232004",
    "district_id": "351323",
    "label": "Dungun",
    "value": "Dungun"
  },
  {
    "id": "3513232005",
    "district_id": "351323",
    "label": "Curahdringu",
    "value": "Curahdringu"
  },
  {
    "id": "3513232006",
    "district_id": "351323",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3513232007",
    "district_id": "351323",
    "label": "Sumberkramat",
    "value": "Sumberkramat"
  },
  {
    "id": "3513232008",
    "district_id": "351323",
    "label": "Tongaswetan",
    "value": "Tongaswetan"
  },
  {
    "id": "3513232009",
    "district_id": "351323",
    "label": "Pamatan",
    "value": "Pamatan"
  },
  {
    "id": "3513232010",
    "district_id": "351323",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3513232011",
    "district_id": "351323",
    "label": "Tongaskulon",
    "value": "Tongaskulon"
  },
  {
    "id": "3513232012",
    "district_id": "351323",
    "label": "Curahtulis",
    "value": "Curahtulis"
  },
  {
    "id": "3513232013",
    "district_id": "351323",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3513232014",
    "district_id": "351323",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3513242001",
    "district_id": "351324",
    "label": "Sapih",
    "value": "Sapih"
  },
  {
    "id": "3513242002",
    "district_id": "351324",
    "label": "Negororejo",
    "value": "Negororejo"
  },
  {
    "id": "3513242003",
    "district_id": "351324",
    "label": "Branggah",
    "value": "Branggah"
  },
  {
    "id": "3513242004",
    "district_id": "351324",
    "label": "Lambangkuning",
    "value": "Lambangkuning"
  },
  {
    "id": "3513242005",
    "district_id": "351324",
    "label": "Wonogoro",
    "value": "Wonogoro"
  },
  {
    "id": "3513242006",
    "district_id": "351324",
    "label": "Palangbesi",
    "value": "Palangbesi"
  },
  {
    "id": "3513242007",
    "district_id": "351324",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3513242008",
    "district_id": "351324",
    "label": "Lumbang",
    "value": "Lumbang"
  },
  {
    "id": "3513242009",
    "district_id": "351324",
    "label": "Tandonsentul",
    "value": "Tandonsentul"
  },
  {
    "id": "3513242010",
    "district_id": "351324",
    "label": "Purut",
    "value": "Purut"
  },
  {
    "id": "3514012001",
    "district_id": "351401",
    "label": "Gerbo",
    "value": "Gerbo"
  },
  {
    "id": "3514012002",
    "district_id": "351401",
    "label": "Dawuhansengon",
    "value": "Dawuhansengon"
  },
  {
    "id": "3514012003",
    "district_id": "351401",
    "label": "Lebak Rejo",
    "value": "Lebak Rejo"
  },
  {
    "id": "3514012004",
    "district_id": "351401",
    "label": "Cowek",
    "value": "Cowek"
  },
  {
    "id": "3514012005",
    "district_id": "351401",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3514012006",
    "district_id": "351401",
    "label": "Parerejo",
    "value": "Parerejo"
  },
  {
    "id": "3514012007",
    "district_id": "351401",
    "label": "Gajahrejo",
    "value": "Gajahrejo"
  },
  {
    "id": "3514012008",
    "district_id": "351401",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3514012009",
    "district_id": "351401",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3514012010",
    "district_id": "351401",
    "label": "Tambak Sari",
    "value": "Tambak Sari"
  },
  {
    "id": "3514012011",
    "district_id": "351401",
    "label": "Pucang Sari",
    "value": "Pucang Sari"
  },
  {
    "id": "3514012012",
    "district_id": "351401",
    "label": "Semut",
    "value": "Semut"
  },
  {
    "id": "3514012013",
    "district_id": "351401",
    "label": "Capang",
    "value": "Capang"
  },
  {
    "id": "3514022001",
    "district_id": "351402",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3514022002",
    "district_id": "351402",
    "label": "Blarang",
    "value": "Blarang"
  },
  {
    "id": "3514022003",
    "district_id": "351402",
    "label": "Kayu Kebek",
    "value": "Kayu Kebek"
  },
  {
    "id": "3514022004",
    "district_id": "351402",
    "label": "Andono Sari",
    "value": "Andono Sari"
  },
  {
    "id": "3514022005",
    "district_id": "351402",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3514022006",
    "district_id": "351402",
    "label": "Gendro",
    "value": "Gendro"
  },
  {
    "id": "3514022007",
    "district_id": "351402",
    "label": "Tlogosari",
    "value": "Tlogosari"
  },
  {
    "id": "3514022008",
    "district_id": "351402",
    "label": "Tutur",
    "value": "Tutur"
  },
  {
    "id": "3514022009",
    "district_id": "351402",
    "label": "Pungging",
    "value": "Pungging"
  },
  {
    "id": "3514022010",
    "district_id": "351402",
    "label": "Kalipucang",
    "value": "Kalipucang"
  },
  {
    "id": "3514022011",
    "district_id": "351402",
    "label": "Sumber Pitu",
    "value": "Sumber Pitu"
  },
  {
    "id": "3514022012",
    "district_id": "351402",
    "label": "Ngembal",
    "value": "Ngembal"
  },
  {
    "id": "3514032001",
    "district_id": "351403",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3514032002",
    "district_id": "351403",
    "label": "Janjangwulung",
    "value": "Janjangwulung"
  },
  {
    "id": "3514032003",
    "district_id": "351403",
    "label": "Palangsari",
    "value": "Palangsari"
  },
  {
    "id": "3514032004",
    "district_id": "351403",
    "label": "Puspo",
    "value": "Puspo"
  },
  {
    "id": "3514032005",
    "district_id": "351403",
    "label": "Jimbaran",
    "value": "Jimbaran"
  },
  {
    "id": "3514032006",
    "district_id": "351403",
    "label": "Pusung Malang",
    "value": "Pusung Malang"
  },
  {
    "id": "3514032007",
    "district_id": "351403",
    "label": "Keduwung",
    "value": "Keduwung"
  },
  {
    "id": "3514042001",
    "district_id": "351404",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3514042002",
    "district_id": "351404",
    "label": "Banjarimbo",
    "value": "Banjarimbo"
  },
  {
    "id": "3514042003",
    "district_id": "351404",
    "label": "Welulang",
    "value": "Welulang"
  },
  {
    "id": "3514042004",
    "district_id": "351404",
    "label": "Watu Lumbung",
    "value": "Watu Lumbung"
  },
  {
    "id": "3514042005",
    "district_id": "351404",
    "label": "Panditan",
    "value": "Panditan"
  },
  {
    "id": "3514042006",
    "district_id": "351404",
    "label": "Bulu Kandang",
    "value": "Bulu Kandang"
  },
  {
    "id": "3514042007",
    "district_id": "351404",
    "label": "Lumbang",
    "value": "Lumbang"
  },
  {
    "id": "3514042008",
    "district_id": "351404",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "3514042009",
    "district_id": "351404",
    "label": "Kronto",
    "value": "Kronto"
  },
  {
    "id": "3514042010",
    "district_id": "351404",
    "label": "Karang Asem",
    "value": "Karang Asem"
  },
  {
    "id": "3514042011",
    "district_id": "351404",
    "label": "Cukur Guling",
    "value": "Cukur Guling"
  },
  {
    "id": "3514042012",
    "district_id": "351404",
    "label": "Karang Jati",
    "value": "Karang Jati"
  },
  {
    "id": "3514052001",
    "district_id": "351405",
    "label": "Ngantungan",
    "value": "Ngantungan"
  },
  {
    "id": "3514052002",
    "district_id": "351405",
    "label": "Galih",
    "value": "Galih"
  },
  {
    "id": "3514052003",
    "district_id": "351405",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3514052004",
    "district_id": "351405",
    "label": "Klakah",
    "value": "Klakah"
  },
  {
    "id": "3514052005",
    "district_id": "351405",
    "label": "Sibon",
    "value": "Sibon"
  },
  {
    "id": "3514052006",
    "district_id": "351405",
    "label": "Mangguan",
    "value": "Mangguan"
  },
  {
    "id": "3514052007",
    "district_id": "351405",
    "label": "Ampel Sari",
    "value": "Ampel Sari"
  },
  {
    "id": "3514052008",
    "district_id": "351405",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3514052009",
    "district_id": "351405",
    "label": "Sapulante",
    "value": "Sapulante"
  },
  {
    "id": "3514052010",
    "district_id": "351405",
    "label": "Pohgedang",
    "value": "Pohgedang"
  },
  {
    "id": "3514052011",
    "district_id": "351405",
    "label": "Pasrepan",
    "value": "Pasrepan"
  },
  {
    "id": "3514052012",
    "district_id": "351405",
    "label": "Rejosalam",
    "value": "Rejosalam"
  },
  {
    "id": "3514052013",
    "district_id": "351405",
    "label": "Cengkrong",
    "value": "Cengkrong"
  },
  {
    "id": "3514052014",
    "district_id": "351405",
    "label": "Lemahbang",
    "value": "Lemahbang"
  },
  {
    "id": "3514052015",
    "district_id": "351405",
    "label": "Tambak Rejo",
    "value": "Tambak Rejo"
  },
  {
    "id": "3514052016",
    "district_id": "351405",
    "label": "Pohgading",
    "value": "Pohgading"
  },
  {
    "id": "3514052017",
    "district_id": "351405",
    "label": "Jogorepuh",
    "value": "Jogorepuh"
  },
  {
    "id": "3514061024",
    "district_id": "351406",
    "label": "Kejayan",
    "value": "Kejayan"
  },
  {
    "id": "3514062001",
    "district_id": "351406",
    "label": "Oro Oro Puleh",
    "value": "Oro Oro Puleh"
  },
  {
    "id": "3514062002",
    "district_id": "351406",
    "label": "Kedungpengaron",
    "value": "Kedungpengaron"
  },
  {
    "id": "3514062003",
    "district_id": "351406",
    "label": "Benerwojo",
    "value": "Benerwojo"
  },
  {
    "id": "3514062004",
    "district_id": "351406",
    "label": "Cobanjoyo",
    "value": "Cobanjoyo"
  },
  {
    "id": "3514062005",
    "district_id": "351406",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3514062006",
    "district_id": "351406",
    "label": "Lorokan",
    "value": "Lorokan"
  },
  {
    "id": "3514062007",
    "district_id": "351406",
    "label": "Klangrong",
    "value": "Klangrong"
  },
  {
    "id": "3514062008",
    "district_id": "351406",
    "label": "Linggo",
    "value": "Linggo"
  },
  {
    "id": "3514062009",
    "district_id": "351406",
    "label": "Ambal Ambil",
    "value": "Ambal Ambil"
  },
  {
    "id": "3514062010",
    "district_id": "351406",
    "label": "Kedemungan",
    "value": "Kedemungan"
  },
  {
    "id": "3514062011",
    "district_id": "351406",
    "label": "Wrati",
    "value": "Wrati"
  },
  {
    "id": "3514062012",
    "district_id": "351406",
    "label": "Pacar Keling",
    "value": "Pacar Keling"
  },
  {
    "id": "3514062013",
    "district_id": "351406",
    "label": "Luwuk",
    "value": "Luwuk"
  },
  {
    "id": "3514062014",
    "district_id": "351406",
    "label": "Sumber Suko",
    "value": "Sumber Suko"
  },
  {
    "id": "3514062015",
    "district_id": "351406",
    "label": "Sumber Banteng",
    "value": "Sumber Banteng"
  },
  {
    "id": "3514062016",
    "district_id": "351406",
    "label": "Kurung",
    "value": "Kurung"
  },
  {
    "id": "3514062017",
    "district_id": "351406",
    "label": "Tanggulangin",
    "value": "Tanggulangin"
  },
  {
    "id": "3514062018",
    "district_id": "351406",
    "label": "Randugong",
    "value": "Randugong"
  },
  {
    "id": "3514062019",
    "district_id": "351406",
    "label": "Wangkalwetan",
    "value": "Wangkalwetan"
  },
  {
    "id": "3514062020",
    "district_id": "351406",
    "label": "Klinter",
    "value": "Klinter"
  },
  {
    "id": "3514062021",
    "district_id": "351406",
    "label": "Tundosuro/Tundosoro",
    "value": "Tundosuro/Tundosoro"
  },
  {
    "id": "3514062022",
    "district_id": "351406",
    "label": "Ketangirejo",
    "value": "Ketangirejo"
  },
  {
    "id": "3514062023",
    "district_id": "351406",
    "label": "Patebon",
    "value": "Patebon"
  },
  {
    "id": "3514062025",
    "district_id": "351406",
    "label": "Sladi",
    "value": "Sladi"
  },
  {
    "id": "3514072001",
    "district_id": "351407",
    "label": "Karangmenggah",
    "value": "Karangmenggah"
  },
  {
    "id": "3514072002",
    "district_id": "351407",
    "label": "Karangjatianyar",
    "value": "Karangjatianyar"
  },
  {
    "id": "3514072003",
    "district_id": "351407",
    "label": "Pakijangan",
    "value": "Pakijangan"
  },
  {
    "id": "3514072004",
    "district_id": "351407",
    "label": "Cobanblimbing",
    "value": "Cobanblimbing"
  },
  {
    "id": "3514072005",
    "district_id": "351407",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3514072006",
    "district_id": "351407",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3514072007",
    "district_id": "351407",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3514072008",
    "district_id": "351407",
    "label": "Jati Gunting",
    "value": "Jati Gunting"
  },
  {
    "id": "3514072009",
    "district_id": "351407",
    "label": "Rebono",
    "value": "Rebono"
  },
  {
    "id": "3514072010",
    "district_id": "351407",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3514072011",
    "district_id": "351407",
    "label": "Kendangdukuh",
    "value": "Kendangdukuh"
  },
  {
    "id": "3514072012",
    "district_id": "351407",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3514072013",
    "district_id": "351407",
    "label": "Kluwut",
    "value": "Kluwut"
  },
  {
    "id": "3514072014",
    "district_id": "351407",
    "label": "Sambisirah",
    "value": "Sambisirah"
  },
  {
    "id": "3514072015",
    "district_id": "351407",
    "label": "Lebak Sari",
    "value": "Lebak Sari"
  },
  {
    "id": "3514081006",
    "district_id": "351408",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3514082001",
    "district_id": "351408",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3514082002",
    "district_id": "351408",
    "label": "Sekarmojo",
    "value": "Sekarmojo"
  },
  {
    "id": "3514082003",
    "district_id": "351408",
    "label": "Tejowangi",
    "value": "Tejowangi"
  },
  {
    "id": "3514082004",
    "district_id": "351408",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3514082005",
    "district_id": "351408",
    "label": "Martopuro",
    "value": "Martopuro"
  },
  {
    "id": "3514082007",
    "district_id": "351408",
    "label": "Sengonagung",
    "value": "Sengonagung"
  },
  {
    "id": "3514082008",
    "district_id": "351408",
    "label": "Pager",
    "value": "Pager"
  },
  {
    "id": "3514082009",
    "district_id": "351408",
    "label": "Cendono",
    "value": "Cendono"
  },
  {
    "id": "3514082010",
    "district_id": "351408",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3514082011",
    "district_id": "351408",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3514082012",
    "district_id": "351408",
    "label": "Pucang Sari",
    "value": "Pucang Sari"
  },
  {
    "id": "3514082013",
    "district_id": "351408",
    "label": "Kayoman",
    "value": "Kayoman"
  },
  {
    "id": "3514082014",
    "district_id": "351408",
    "label": "Sukodermo",
    "value": "Sukodermo"
  },
  {
    "id": "3514082015",
    "district_id": "351408",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3514092001",
    "district_id": "351409",
    "label": "Gunting",
    "value": "Gunting"
  },
  {
    "id": "3514092002",
    "district_id": "351409",
    "label": "Pakukerto",
    "value": "Pakukerto"
  },
  {
    "id": "3514092003",
    "district_id": "351409",
    "label": "Glagahsari",
    "value": "Glagahsari"
  },
  {
    "id": "3514092004",
    "district_id": "351409",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3514092005",
    "district_id": "351409",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3514092006",
    "district_id": "351409",
    "label": "Sebandung",
    "value": "Sebandung"
  },
  {
    "id": "3514092007",
    "district_id": "351409",
    "label": "Dukuhsari",
    "value": "Dukuhsari"
  },
  {
    "id": "3514092008",
    "district_id": "351409",
    "label": "Lecari",
    "value": "Lecari"
  },
  {
    "id": "3514092009",
    "district_id": "351409",
    "label": "Lemahbang",
    "value": "Lemahbang"
  },
  {
    "id": "3514092010",
    "district_id": "351409",
    "label": "Ngadimulyo",
    "value": "Ngadimulyo"
  },
  {
    "id": "3514092011",
    "district_id": "351409",
    "label": "Tanjungarum",
    "value": "Tanjungarum"
  },
  {
    "id": "3514092012",
    "district_id": "351409",
    "label": "Suwayuwo",
    "value": "Suwayuwo"
  },
  {
    "id": "3514092013",
    "district_id": "351409",
    "label": "Mojotengah",
    "value": "Mojotengah"
  },
  {
    "id": "3514092014",
    "district_id": "351409",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3514092015",
    "district_id": "351409",
    "label": "Candibinangun",
    "value": "Candibinangun"
  },
  {
    "id": "3514092016",
    "district_id": "351409",
    "label": "Kenduruan",
    "value": "Kenduruan"
  },
  {
    "id": "3514092017",
    "district_id": "351409",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3514092018",
    "district_id": "351409",
    "label": "Curahrejo",
    "value": "Curahrejo"
  },
  {
    "id": "3514092019",
    "district_id": "351409",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3514101007",
    "district_id": "351410",
    "label": "Ledug",
    "value": "Ledug"
  },
  {
    "id": "3514101008",
    "district_id": "351410",
    "label": "Pecalukan",
    "value": "Pecalukan"
  },
  {
    "id": "3514101009",
    "district_id": "351410",
    "label": "Prigen",
    "value": "Prigen"
  },
  {
    "id": "3514102001",
    "district_id": "351410",
    "label": "Jatiarjo",
    "value": "Jatiarjo"
  },
  {
    "id": "3514102002",
    "district_id": "351410",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3514102003",
    "district_id": "351410",
    "label": "Dayurejo",
    "value": "Dayurejo"
  },
  {
    "id": "3514102004",
    "district_id": "351410",
    "label": "Bulukandang",
    "value": "Bulukandang"
  },
  {
    "id": "3514102005",
    "district_id": "351410",
    "label": "Ketanireng",
    "value": "Ketanireng"
  },
  {
    "id": "3514102006",
    "district_id": "351410",
    "label": "Sukolelo",
    "value": "Sukolelo"
  },
  {
    "id": "3514102010",
    "district_id": "351410",
    "label": "Lumbangrejo",
    "value": "Lumbangrejo"
  },
  {
    "id": "3514102011",
    "district_id": "351410",
    "label": "Sukoreno",
    "value": "Sukoreno"
  },
  {
    "id": "3514102012",
    "district_id": "351410",
    "label": "Sekarjoho",
    "value": "Sekarjoho"
  },
  {
    "id": "3514102013",
    "district_id": "351410",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  {
    "id": "3514102014",
    "district_id": "351410",
    "label": "Candiwates",
    "value": "Candiwates"
  },
  {
    "id": "3514111006",
    "district_id": "351411",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "3514111007",
    "district_id": "351411",
    "label": "Jogosari",
    "value": "Jogosari"
  },
  {
    "id": "3514111008",
    "district_id": "351411",
    "label": "Petungasri",
    "value": "Petungasri"
  },
  {
    "id": "3514111011",
    "district_id": "351411",
    "label": "Pandaan",
    "value": "Pandaan"
  },
  {
    "id": "3514112001",
    "district_id": "351411",
    "label": "Plintahan",
    "value": "Plintahan"
  },
  {
    "id": "3514112002",
    "district_id": "351411",
    "label": "Durensewu",
    "value": "Durensewu"
  },
  {
    "id": "3514112003",
    "district_id": "351411",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3514112004",
    "district_id": "351411",
    "label": "Wedoro",
    "value": "Wedoro"
  },
  {
    "id": "3514112005",
    "district_id": "351411",
    "label": "Tunggulwulung",
    "value": "Tunggulwulung"
  },
  {
    "id": "3514112009",
    "district_id": "351411",
    "label": "Sumbergedang",
    "value": "Sumbergedang"
  },
  {
    "id": "3514112010",
    "district_id": "351411",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3514112012",
    "district_id": "351411",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  {
    "id": "3514112013",
    "district_id": "351411",
    "label": "Kebonwaris",
    "value": "Kebonwaris"
  },
  {
    "id": "3514112014",
    "district_id": "351411",
    "label": "Sebani",
    "value": "Sebani"
  },
  {
    "id": "3514112015",
    "district_id": "351411",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3514112016",
    "district_id": "351411",
    "label": "Banjarkejen",
    "value": "Banjarkejen"
  },
  {
    "id": "3514112017",
    "district_id": "351411",
    "label": "Kemirisewu",
    "value": "Kemirisewu"
  },
  {
    "id": "3514112018",
    "district_id": "351411",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3514122001",
    "district_id": "351412",
    "label": "Wonosunyo",
    "value": "Wonosunyo"
  },
  {
    "id": "3514122002",
    "district_id": "351412",
    "label": "Sumbersuko",
    "value": "Sumbersuko"
  },
  {
    "id": "3514122003",
    "district_id": "351412",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3514122004",
    "district_id": "351412",
    "label": "Kepulungan",
    "value": "Kepulungan"
  },
  {
    "id": "3514122005",
    "district_id": "351412",
    "label": "Randupitu",
    "value": "Randupitu"
  },
  {
    "id": "3514122006",
    "district_id": "351412",
    "label": "Ngerong",
    "value": "Ngerong"
  },
  {
    "id": "3514122007",
    "district_id": "351412",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3514122008",
    "district_id": "351412",
    "label": "Bulusari",
    "value": "Bulusari"
  },
  {
    "id": "3514122009",
    "district_id": "351412",
    "label": "Jeruk Purut",
    "value": "Jeruk Purut"
  },
  {
    "id": "3514122010",
    "district_id": "351412",
    "label": "Watukosek",
    "value": "Watukosek"
  },
  {
    "id": "3514122011",
    "district_id": "351412",
    "label": "Carat",
    "value": "Carat"
  },
  {
    "id": "3514122012",
    "district_id": "351412",
    "label": "Kejapanan",
    "value": "Kejapanan"
  },
  {
    "id": "3514122013",
    "district_id": "351412",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3514122014",
    "district_id": "351412",
    "label": "Legok",
    "value": "Legok"
  },
  {
    "id": "3514122015",
    "district_id": "351412",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3514131013",
    "district_id": "351413",
    "label": "Glanggang",
    "value": "Glanggang"
  },
  {
    "id": "3514131014",
    "district_id": "351413",
    "label": "Pagak",
    "value": "Pagak"
  },
  {
    "id": "3514132001",
    "district_id": "351413",
    "label": "Baujeng",
    "value": "Baujeng"
  },
  {
    "id": "3514132002",
    "district_id": "351413",
    "label": "Ngembe",
    "value": "Ngembe"
  },
  {
    "id": "3514132003",
    "district_id": "351413",
    "label": "Kenep",
    "value": "Kenep"
  },
  {
    "id": "3514132004",
    "district_id": "351413",
    "label": "Sidowayah",
    "value": "Sidowayah"
  },
  {
    "id": "3514132005",
    "district_id": "351413",
    "label": "Gajahbendo",
    "value": "Gajahbendo"
  },
  {
    "id": "3514132006",
    "district_id": "351413",
    "label": "Gunung Gangsir",
    "value": "Gunung Gangsir"
  },
  {
    "id": "3514132007",
    "district_id": "351413",
    "label": "Wonokoyo",
    "value": "Wonokoyo"
  },
  {
    "id": "3514132008",
    "district_id": "351413",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3514132009",
    "district_id": "351413",
    "label": "Cangkringmalang",
    "value": "Cangkringmalang"
  },
  {
    "id": "3514132010",
    "district_id": "351413",
    "label": "Kedungringin",
    "value": "Kedungringin"
  },
  {
    "id": "3514132011",
    "district_id": "351413",
    "label": "Kedungboto",
    "value": "Kedungboto"
  },
  {
    "id": "3514132012",
    "district_id": "351413",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3514141001",
    "district_id": "351414",
    "label": "Kolursari",
    "value": "Kolursari"
  },
  {
    "id": "3514141002",
    "district_id": "351414",
    "label": "Kiduldalem",
    "value": "Kiduldalem"
  },
  {
    "id": "3514141003",
    "district_id": "351414",
    "label": "Pogar",
    "value": "Pogar"
  },
  {
    "id": "3514141004",
    "district_id": "351414",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3514141005",
    "district_id": "351414",
    "label": "Bendomungal",
    "value": "Bendomungal"
  },
  {
    "id": "3514141006",
    "district_id": "351414",
    "label": "Kersikan",
    "value": "Kersikan"
  },
  {
    "id": "3514141007",
    "district_id": "351414",
    "label": "Gempeng",
    "value": "Gempeng"
  },
  {
    "id": "3514141008",
    "district_id": "351414",
    "label": "Dermo",
    "value": "Dermo"
  },
  {
    "id": "3514141009",
    "district_id": "351414",
    "label": "Latek",
    "value": "Latek"
  },
  {
    "id": "3514141012",
    "district_id": "351414",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3514141014",
    "district_id": "351414",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3514142010",
    "district_id": "351414",
    "label": "Masangan",
    "value": "Masangan"
  },
  {
    "id": "3514142011",
    "district_id": "351414",
    "label": "Raci",
    "value": "Raci"
  },
  {
    "id": "3514142013",
    "district_id": "351414",
    "label": "Manaruwi",
    "value": "Manaruwi"
  },
  {
    "id": "3514142015",
    "district_id": "351414",
    "label": "Tambakan",
    "value": "Tambakan"
  },
  {
    "id": "3514152001",
    "district_id": "351415",
    "label": "Kalisat",
    "value": "Kalisat"
  },
  {
    "id": "3514152002",
    "district_id": "351415",
    "label": "Tampung",
    "value": "Tampung"
  },
  {
    "id": "3514152003",
    "district_id": "351415",
    "label": "Pajaran",
    "value": "Pajaran"
  },
  {
    "id": "3514152004",
    "district_id": "351415",
    "label": "Siyar",
    "value": "Siyar"
  },
  {
    "id": "3514152005",
    "district_id": "351415",
    "label": "Genengwaru",
    "value": "Genengwaru"
  },
  {
    "id": "3514152006",
    "district_id": "351415",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3514152007",
    "district_id": "351415",
    "label": "Sumberglagah",
    "value": "Sumberglagah"
  },
  {
    "id": "3514152008",
    "district_id": "351415",
    "label": "Krengih",
    "value": "Krengih"
  },
  {
    "id": "3514152009",
    "district_id": "351415",
    "label": "Rembang",
    "value": "Rembang"
  },
  {
    "id": "3514152010",
    "district_id": "351415",
    "label": "Oro-Orobulu",
    "value": "Oro-Orobulu"
  },
  {
    "id": "3514152011",
    "district_id": "351415",
    "label": "Kedung Banteng",
    "value": "Kedung Banteng"
  },
  {
    "id": "3514152012",
    "district_id": "351415",
    "label": "Oro-Oro Ombowetan",
    "value": "Oro-Oro Ombowetan"
  },
  {
    "id": "3514152013",
    "district_id": "351415",
    "label": "Oro-Oro Ombokulon",
    "value": "Oro-Oro Ombokulon"
  },
  {
    "id": "3514152014",
    "district_id": "351415",
    "label": "Pekoren",
    "value": "Pekoren"
  },
  {
    "id": "3514152015",
    "district_id": "351415",
    "label": "Pejangkungan",
    "value": "Pejangkungan"
  },
  {
    "id": "3514152016",
    "district_id": "351415",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3514152017",
    "district_id": "351415",
    "label": "Mojoparon",
    "value": "Mojoparon"
  },
  {
    "id": "3514162001",
    "district_id": "351416",
    "label": "Pukul",
    "value": "Pukul"
  },
  {
    "id": "3514162002",
    "district_id": "351416",
    "label": "Gambirkuning",
    "value": "Gambirkuning"
  },
  {
    "id": "3514162003",
    "district_id": "351416",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3514162004",
    "district_id": "351416",
    "label": "Kebotohan",
    "value": "Kebotohan"
  },
  {
    "id": "3514162005",
    "district_id": "351416",
    "label": "Ngabar",
    "value": "Ngabar"
  },
  {
    "id": "3514162006",
    "district_id": "351416",
    "label": "Slambrit",
    "value": "Slambrit"
  },
  {
    "id": "3514162007",
    "district_id": "351416",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3514162008",
    "district_id": "351416",
    "label": "Klampisrejo",
    "value": "Klampisrejo"
  },
  {
    "id": "3514162009",
    "district_id": "351416",
    "label": "Plinggisan",
    "value": "Plinggisan"
  },
  {
    "id": "3514162010",
    "district_id": "351416",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3514162011",
    "district_id": "351416",
    "label": "Dhompo",
    "value": "Dhompo"
  },
  {
    "id": "3514162012",
    "district_id": "351416",
    "label": "Ngempit",
    "value": "Ngempit"
  },
  {
    "id": "3514162013",
    "district_id": "351416",
    "label": "Sidogiri",
    "value": "Sidogiri"
  },
  {
    "id": "3514162014",
    "district_id": "351416",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3514162015",
    "district_id": "351416",
    "label": "Selotambak",
    "value": "Selotambak"
  },
  {
    "id": "3514162016",
    "district_id": "351416",
    "label": "Curahdukuh",
    "value": "Curahdukuh"
  },
  {
    "id": "3514162017",
    "district_id": "351416",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3514162018",
    "district_id": "351416",
    "label": "Asem Kandang",
    "value": "Asem Kandang"
  },
  {
    "id": "3514162019",
    "district_id": "351416",
    "label": "Tambak Rejo",
    "value": "Tambak Rejo"
  },
  {
    "id": "3514162020",
    "district_id": "351416",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3514162021",
    "district_id": "351416",
    "label": "Semare",
    "value": "Semare"
  },
  {
    "id": "3514162022",
    "district_id": "351416",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3514162023",
    "district_id": "351416",
    "label": "Pulokerto",
    "value": "Pulokerto"
  },
  {
    "id": "3514162024",
    "district_id": "351416",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3514162025",
    "district_id": "351416",
    "label": "Gerongan",
    "value": "Gerongan"
  },
  {
    "id": "3514172001",
    "district_id": "351417",
    "label": "Susukan Rejo",
    "value": "Susukan Rejo"
  },
  {
    "id": "3514172002",
    "district_id": "351417",
    "label": "Warungdowo",
    "value": "Warungdowo"
  },
  {
    "id": "3514172003",
    "district_id": "351417",
    "label": "Pleret",
    "value": "Pleret"
  },
  {
    "id": "3514172004",
    "district_id": "351417",
    "label": "Parasrejo",
    "value": "Parasrejo"
  },
  {
    "id": "3514172005",
    "district_id": "351417",
    "label": "Logowok",
    "value": "Logowok"
  },
  {
    "id": "3514172006",
    "district_id": "351417",
    "label": "Tidu",
    "value": "Tidu"
  },
  {
    "id": "3514172007",
    "district_id": "351417",
    "label": "Sungiwetan",
    "value": "Sungiwetan"
  },
  {
    "id": "3514172008",
    "district_id": "351417",
    "label": "Sungikulon",
    "value": "Sungikulon"
  },
  {
    "id": "3514172009",
    "district_id": "351417",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3514181016",
    "district_id": "351418",
    "label": "Gondang Wetan",
    "value": "Gondang Wetan"
  },
  {
    "id": "3514182001",
    "district_id": "351418",
    "label": "Tebas",
    "value": "Tebas"
  },
  {
    "id": "3514182002",
    "district_id": "351418",
    "label": "Brambang",
    "value": "Brambang"
  },
  {
    "id": "3514182003",
    "district_id": "351418",
    "label": "Bayeman",
    "value": "Bayeman"
  },
  {
    "id": "3514182004",
    "district_id": "351418",
    "label": "Kebon Candi",
    "value": "Kebon Candi"
  },
  {
    "id": "3514182005",
    "district_id": "351418",
    "label": "Tenggilis Rejo",
    "value": "Tenggilis Rejo"
  },
  {
    "id": "3514182006",
    "district_id": "351418",
    "label": "Wonojati",
    "value": "Wonojati"
  },
  {
    "id": "3514182007",
    "district_id": "351418",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3514182008",
    "district_id": "351418",
    "label": "Kersikan",
    "value": "Kersikan"
  },
  {
    "id": "3514182009",
    "district_id": "351418",
    "label": "Karangsentul",
    "value": "Karangsentul"
  },
  {
    "id": "3514182010",
    "district_id": "351418",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3514182011",
    "district_id": "351418",
    "label": "Lajuk",
    "value": "Lajuk"
  },
  {
    "id": "3514182012",
    "district_id": "351418",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3514182013",
    "district_id": "351418",
    "label": "Pateguhan",
    "value": "Pateguhan"
  },
  {
    "id": "3514182014",
    "district_id": "351418",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3514182015",
    "district_id": "351418",
    "label": "Pekangkungan",
    "value": "Pekangkungan"
  },
  {
    "id": "3514182017",
    "district_id": "351418",
    "label": "Ranggeh",
    "value": "Ranggeh"
  },
  {
    "id": "3514182018",
    "district_id": "351418",
    "label": "Sekar Putih",
    "value": "Sekar Putih"
  },
  {
    "id": "3514182019",
    "district_id": "351418",
    "label": "Bajangan",
    "value": "Bajangan"
  },
  {
    "id": "3514182020",
    "district_id": "351418",
    "label": "Gondangrejo",
    "value": "Gondangrejo"
  },
  {
    "id": "3514192001",
    "district_id": "351419",
    "label": "Minggir",
    "value": "Minggir"
  },
  {
    "id": "3514192002",
    "district_id": "351419",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3514192003",
    "district_id": "351419",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3514192004",
    "district_id": "351419",
    "label": "Umbulan",
    "value": "Umbulan"
  },
  {
    "id": "3514192005",
    "district_id": "351419",
    "label": "Sidepan",
    "value": "Sidepan"
  },
  {
    "id": "3514192006",
    "district_id": "351419",
    "label": "Sruwi",
    "value": "Sruwi"
  },
  {
    "id": "3514192007",
    "district_id": "351419",
    "label": "Jeladri",
    "value": "Jeladri"
  },
  {
    "id": "3514192008",
    "district_id": "351419",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3514192009",
    "district_id": "351419",
    "label": "Prodo",
    "value": "Prodo"
  },
  {
    "id": "3514192010",
    "district_id": "351419",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3514192011",
    "district_id": "351419",
    "label": "Menyarik",
    "value": "Menyarik"
  },
  {
    "id": "3514192012",
    "district_id": "351419",
    "label": "Kandung",
    "value": "Kandung"
  },
  {
    "id": "3514192013",
    "district_id": "351419",
    "label": "Mendalan",
    "value": "Mendalan"
  },
  {
    "id": "3514192014",
    "district_id": "351419",
    "label": "Penataan",
    "value": "Penataan"
  },
  {
    "id": "3514192015",
    "district_id": "351419",
    "label": "Winongan Kidul",
    "value": "Winongan Kidul"
  },
  {
    "id": "3514192016",
    "district_id": "351419",
    "label": "Bandaran",
    "value": "Bandaran"
  },
  {
    "id": "3514192017",
    "district_id": "351419",
    "label": "Winongan Lor",
    "value": "Winongan Lor"
  },
  {
    "id": "3514192018",
    "district_id": "351419",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3514201005",
    "district_id": "351420",
    "label": "Gratitunon",
    "value": "Gratitunon"
  },
  {
    "id": "3514202001",
    "district_id": "351420",
    "label": "Kebonrejo",
    "value": "Kebonrejo"
  },
  {
    "id": "3514202002",
    "district_id": "351420",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3514202003",
    "district_id": "351420",
    "label": "Rebalas",
    "value": "Rebalas"
  },
  {
    "id": "3514202004",
    "district_id": "351420",
    "label": "Plososari",
    "value": "Plososari"
  },
  {
    "id": "3514202006",
    "district_id": "351420",
    "label": "Kalipang",
    "value": "Kalipang"
  },
  {
    "id": "3514202007",
    "district_id": "351420",
    "label": "Trewung",
    "value": "Trewung"
  },
  {
    "id": "3514202008",
    "district_id": "351420",
    "label": "Kambinganrejo",
    "value": "Kambinganrejo"
  },
  {
    "id": "3514202009",
    "district_id": "351420",
    "label": "Karangkliwon",
    "value": "Karangkliwon"
  },
  {
    "id": "3514202010",
    "district_id": "351420",
    "label": "Kedawungkulon",
    "value": "Kedawungkulon"
  },
  {
    "id": "3514202011",
    "district_id": "351420",
    "label": "Kedawungwetan",
    "value": "Kedawungwetan"
  },
  {
    "id": "3514202012",
    "district_id": "351420",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3514202013",
    "district_id": "351420",
    "label": "Ranuklindungan",
    "value": "Ranuklindungan"
  },
  {
    "id": "3514202014",
    "district_id": "351420",
    "label": "Sumberdawesari",
    "value": "Sumberdawesari"
  },
  {
    "id": "3514202015",
    "district_id": "351420",
    "label": "Cukurgondang",
    "value": "Cukurgondang"
  },
  {
    "id": "3514212001",
    "district_id": "351421",
    "label": "Sanganom",
    "value": "Sanganom"
  },
  {
    "id": "3514212002",
    "district_id": "351421",
    "label": "Sebalong",
    "value": "Sebalong"
  },
  {
    "id": "3514212003",
    "district_id": "351421",
    "label": "Wotgalih",
    "value": "Wotgalih"
  },
  {
    "id": "3514212004",
    "district_id": "351421",
    "label": "Watestani",
    "value": "Watestani"
  },
  {
    "id": "3514212005",
    "district_id": "351421",
    "label": "Nguling",
    "value": "Nguling"
  },
  {
    "id": "3514212006",
    "district_id": "351421",
    "label": "Sedarum",
    "value": "Sedarum"
  },
  {
    "id": "3514212007",
    "district_id": "351421",
    "label": "Dandanggendis",
    "value": "Dandanggendis"
  },
  {
    "id": "3514212008",
    "district_id": "351421",
    "label": "Sumberanyar",
    "value": "Sumberanyar"
  },
  {
    "id": "3514212009",
    "district_id": "351421",
    "label": "Sudimulyo",
    "value": "Sudimulyo"
  },
  {
    "id": "3514212010",
    "district_id": "351421",
    "label": "Penunggul",
    "value": "Penunggul"
  },
  {
    "id": "3514212011",
    "district_id": "351421",
    "label": "Mlaten",
    "value": "Mlaten"
  },
  {
    "id": "3514212012",
    "district_id": "351421",
    "label": "Kedawang",
    "value": "Kedawang"
  },
  {
    "id": "3514212013",
    "district_id": "351421",
    "label": "Randuati",
    "value": "Randuati"
  },
  {
    "id": "3514212014",
    "district_id": "351421",
    "label": "Kapasan",
    "value": "Kapasan"
  },
  {
    "id": "3514212015",
    "district_id": "351421",
    "label": "Watuprapat",
    "value": "Watuprapat"
  },
  {
    "id": "3514222001",
    "district_id": "351422",
    "label": "Rowogempol",
    "value": "Rowogempol"
  },
  {
    "id": "3514222002",
    "district_id": "351422",
    "label": "Gejugjati",
    "value": "Gejugjati"
  },
  {
    "id": "3514222003",
    "district_id": "351422",
    "label": "Alas Tlogo",
    "value": "Alas Tlogo"
  },
  {
    "id": "3514222004",
    "district_id": "351422",
    "label": "Balonganyar",
    "value": "Balonganyar"
  },
  {
    "id": "3514222005",
    "district_id": "351422",
    "label": "Branang",
    "value": "Branang"
  },
  {
    "id": "3514222006",
    "district_id": "351422",
    "label": "Tampung",
    "value": "Tampung"
  },
  {
    "id": "3514222007",
    "district_id": "351422",
    "label": "Tambaklekok",
    "value": "Tambaklekok"
  },
  {
    "id": "3514222008",
    "district_id": "351422",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3514222009",
    "district_id": "351422",
    "label": "Pasinan",
    "value": "Pasinan"
  },
  {
    "id": "3514222010",
    "district_id": "351422",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3514222011",
    "district_id": "351422",
    "label": "Semedusari",
    "value": "Semedusari"
  },
  {
    "id": "3514232001",
    "district_id": "351423",
    "label": "Sadengrejo",
    "value": "Sadengrejo"
  },
  {
    "id": "3514232002",
    "district_id": "351423",
    "label": "Pandanrejo",
    "value": "Pandanrejo"
  },
  {
    "id": "3514232003",
    "district_id": "351423",
    "label": "Ketegan",
    "value": "Ketegan"
  },
  {
    "id": "3514232004",
    "district_id": "351423",
    "label": "Toyaning",
    "value": "Toyaning"
  },
  {
    "id": "3514232005",
    "district_id": "351423",
    "label": "Arjosari",
    "value": "Arjosari"
  },
  {
    "id": "3514232006",
    "district_id": "351423",
    "label": "Kawisrejo",
    "value": "Kawisrejo"
  },
  {
    "id": "3514232007",
    "district_id": "351423",
    "label": "Rejoso Kidul",
    "value": "Rejoso Kidul"
  },
  {
    "id": "3514232008",
    "district_id": "351423",
    "label": "Manikrejo",
    "value": "Manikrejo"
  },
  {
    "id": "3514232009",
    "district_id": "351423",
    "label": "Karangpandan",
    "value": "Karangpandan"
  },
  {
    "id": "3514232010",
    "district_id": "351423",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3514232011",
    "district_id": "351423",
    "label": "Kedungbako",
    "value": "Kedungbako"
  },
  {
    "id": "3514232012",
    "district_id": "351423",
    "label": "Rejoso Lor",
    "value": "Rejoso Lor"
  },
  {
    "id": "3514232013",
    "district_id": "351423",
    "label": "Patuguhan",
    "value": "Patuguhan"
  },
  {
    "id": "3514232014",
    "district_id": "351423",
    "label": "Kemantren Rejo",
    "value": "Kemantren Rejo"
  },
  {
    "id": "3514232015",
    "district_id": "351423",
    "label": "Segoropuro",
    "value": "Segoropuro"
  },
  {
    "id": "3514232016",
    "district_id": "351423",
    "label": "Jarangan",
    "value": "Jarangan"
  },
  {
    "id": "3514242001",
    "district_id": "351424",
    "label": "Mororejo",
    "value": "Mororejo"
  },
  {
    "id": "3514242002",
    "district_id": "351424",
    "label": "Podokoyo",
    "value": "Podokoyo"
  },
  {
    "id": "3514242003",
    "district_id": "351424",
    "label": "Ngadiwono",
    "value": "Ngadiwono"
  },
  {
    "id": "3514242004",
    "district_id": "351424",
    "label": "Tosari",
    "value": "Tosari"
  },
  {
    "id": "3514242005",
    "district_id": "351424",
    "label": "Wonokitri",
    "value": "Wonokitri"
  },
  {
    "id": "3514242006",
    "district_id": "351424",
    "label": "Baledono",
    "value": "Baledono"
  },
  {
    "id": "3514242007",
    "district_id": "351424",
    "label": "Sedaeng",
    "value": "Sedaeng"
  },
  {
    "id": "3514242008",
    "district_id": "351424",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3515012001",
    "district_id": "351501",
    "label": "Miriprowo",
    "value": "Miriprowo"
  },
  {
    "id": "3515012002",
    "district_id": "351501",
    "label": "Kedungbocok",
    "value": "Kedungbocok"
  },
  {
    "id": "3515012003",
    "district_id": "351501",
    "label": "Singogalih",
    "value": "Singogalih"
  },
  {
    "id": "3515012004",
    "district_id": "351501",
    "label": "Tarik",
    "value": "Tarik"
  },
  {
    "id": "3515012005",
    "district_id": "351501",
    "label": "Mergobener",
    "value": "Mergobener"
  },
  {
    "id": "3515012006",
    "district_id": "351501",
    "label": "Mergosari",
    "value": "Mergosari"
  },
  {
    "id": "3515012007",
    "district_id": "351501",
    "label": "Kendalsewu",
    "value": "Kendalsewu"
  },
  {
    "id": "3515012008",
    "district_id": "351501",
    "label": "Klantingsari",
    "value": "Klantingsari"
  },
  {
    "id": "3515012009",
    "district_id": "351501",
    "label": "Kalimati",
    "value": "Kalimati"
  },
  {
    "id": "3515012010",
    "district_id": "351501",
    "label": "Gempolklutuk",
    "value": "Gempolklutuk"
  },
  {
    "id": "3515012011",
    "district_id": "351501",
    "label": "Banjarwungu",
    "value": "Banjarwungu"
  },
  {
    "id": "3515012012",
    "district_id": "351501",
    "label": "Balongmacekan",
    "value": "Balongmacekan"
  },
  {
    "id": "3515012013",
    "district_id": "351501",
    "label": "Gampingrowo",
    "value": "Gampingrowo"
  },
  {
    "id": "3515012014",
    "district_id": "351501",
    "label": "Sebani",
    "value": "Sebani"
  },
  {
    "id": "3515012015",
    "district_id": "351501",
    "label": "Kramattemanggung",
    "value": "Kramattemanggung"
  },
  {
    "id": "3515012016",
    "district_id": "351501",
    "label": "Mindugading",
    "value": "Mindugading"
  },
  {
    "id": "3515012017",
    "district_id": "351501",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3515012018",
    "district_id": "351501",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3515012019",
    "district_id": "351501",
    "label": "Segodobancang",
    "value": "Segodobancang"
  },
  {
    "id": "3515012020",
    "district_id": "351501",
    "label": "Kedinding",
    "value": "Kedinding"
  },
  {
    "id": "3515022001",
    "district_id": "351502",
    "label": "Prambon",
    "value": "Prambon"
  },
  {
    "id": "3515022002",
    "district_id": "351502",
    "label": "Kajartengguli",
    "value": "Kajartengguli"
  },
  {
    "id": "3515022003",
    "district_id": "351502",
    "label": "Gedangrowo",
    "value": "Gedangrowo"
  },
  {
    "id": "3515022004",
    "district_id": "351502",
    "label": "Wirobiting",
    "value": "Wirobiting"
  },
  {
    "id": "3515022005",
    "district_id": "351502",
    "label": "Simpang",
    "value": "Simpang"
  },
  {
    "id": "3515022006",
    "district_id": "351502",
    "label": "Bulang",
    "value": "Bulang"
  },
  {
    "id": "3515022007",
    "district_id": "351502",
    "label": "Gampang",
    "value": "Gampang"
  },
  {
    "id": "3515022008",
    "district_id": "351502",
    "label": "Jatikalang",
    "value": "Jatikalang"
  },
  {
    "id": "3515022009",
    "district_id": "351502",
    "label": "Jatialun-alun",
    "value": "Jatialun-alun"
  },
  {
    "id": "3515022010",
    "district_id": "351502",
    "label": "Pejangkungan",
    "value": "Pejangkungan"
  },
  {
    "id": "3515022011",
    "district_id": "351502",
    "label": "Kedungsugo",
    "value": "Kedungsugo"
  },
  {
    "id": "3515022012",
    "district_id": "351502",
    "label": "Kedungwonokerto",
    "value": "Kedungwonokerto"
  },
  {
    "id": "3515022013",
    "district_id": "351502",
    "label": "Bendotretek",
    "value": "Bendotretek"
  },
  {
    "id": "3515022014",
    "district_id": "351502",
    "label": "Wonoplintahan",
    "value": "Wonoplintahan"
  },
  {
    "id": "3515022015",
    "district_id": "351502",
    "label": "Kedungkembar",
    "value": "Kedungkembar"
  },
  {
    "id": "3515022016",
    "district_id": "351502",
    "label": "Jedongcangkring",
    "value": "Jedongcangkring"
  },
  {
    "id": "3515022017",
    "district_id": "351502",
    "label": "Cangkringturi",
    "value": "Cangkringturi"
  },
  {
    "id": "3515022018",
    "district_id": "351502",
    "label": "Simogirang",
    "value": "Simogirang"
  },
  {
    "id": "3515022019",
    "district_id": "351502",
    "label": "Temu",
    "value": "Temu"
  },
  {
    "id": "3515022020",
    "district_id": "351502",
    "label": "Watutulis",
    "value": "Watutulis"
  },
  {
    "id": "3515032001",
    "district_id": "351503",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3515032002",
    "district_id": "351503",
    "label": "Keper",
    "value": "Keper"
  },
  {
    "id": "3515032003",
    "district_id": "351503",
    "label": "Kedungsumur",
    "value": "Kedungsumur"
  },
  {
    "id": "3515032004",
    "district_id": "351503",
    "label": "Kedungrawan",
    "value": "Kedungrawan"
  },
  {
    "id": "3515032005",
    "district_id": "351503",
    "label": "Tanjekwagir",
    "value": "Tanjekwagir"
  },
  {
    "id": "3515032006",
    "district_id": "351503",
    "label": "Mojoruntut",
    "value": "Mojoruntut"
  },
  {
    "id": "3515032007",
    "district_id": "351503",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3515032008",
    "district_id": "351503",
    "label": "Wangkal",
    "value": "Wangkal"
  },
  {
    "id": "3515032009",
    "district_id": "351503",
    "label": "Jenggot",
    "value": "Jenggot"
  },
  {
    "id": "3515032010",
    "district_id": "351503",
    "label": "Waung",
    "value": "Waung"
  },
  {
    "id": "3515032011",
    "district_id": "351503",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3515032012",
    "district_id": "351503",
    "label": "Rejeni",
    "value": "Rejeni"
  },
  {
    "id": "3515032013",
    "district_id": "351503",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3515032014",
    "district_id": "351503",
    "label": "Krembung",
    "value": "Krembung"
  },
  {
    "id": "3515032015",
    "district_id": "351503",
    "label": "Lemujut",
    "value": "Lemujut"
  },
  {
    "id": "3515032016",
    "district_id": "351503",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3515032017",
    "district_id": "351503",
    "label": "Keret",
    "value": "Keret"
  },
  {
    "id": "3515032018",
    "district_id": "351503",
    "label": "Wonomlati",
    "value": "Wonomlati"
  },
  {
    "id": "3515032019",
    "district_id": "351503",
    "label": "Balonggarut",
    "value": "Balonggarut"
  },
  {
    "id": "3515041003",
    "district_id": "351504",
    "label": "Porong",
    "value": "Porong"
  },
  {
    "id": "3515041004",
    "district_id": "351504",
    "label": "Juwetkenongo",
    "value": "Juwetkenongo"
  },
  {
    "id": "3515041005",
    "district_id": "351504",
    "label": "Mindi",
    "value": "Mindi"
  },
  {
    "id": "3515041006",
    "district_id": "351504",
    "label": "Gedang",
    "value": "Gedang"
  },
  {
    "id": "3515041013",
    "district_id": "351504",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3515041017",
    "district_id": "351504",
    "label": "Siring",
    "value": "Siring"
  },
  {
    "id": "3515042001",
    "district_id": "351504",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3515042002",
    "district_id": "351504",
    "label": "Kedungsolo",
    "value": "Kedungsolo"
  },
  {
    "id": "3515042007",
    "district_id": "351504",
    "label": "Kesambi",
    "value": "Kesambi"
  },
  {
    "id": "3515042008",
    "district_id": "351504",
    "label": "Kebakalan",
    "value": "Kebakalan"
  },
  {
    "id": "3515042009",
    "district_id": "351504",
    "label": "Lajuk",
    "value": "Lajuk"
  },
  {
    "id": "3515042010",
    "district_id": "351504",
    "label": "Kedungboto",
    "value": "Kedungboto"
  },
  {
    "id": "3515042011",
    "district_id": "351504",
    "label": "Candipari",
    "value": "Candipari"
  },
  {
    "id": "3515042012",
    "district_id": "351504",
    "label": "Pamotan",
    "value": "Pamotan"
  },
  {
    "id": "3515042014",
    "district_id": "351504",
    "label": "Renokenongo",
    "value": "Renokenongo"
  },
  {
    "id": "3515042015",
    "district_id": "351504",
    "label": "Glagahharum",
    "value": "Glagahharum"
  },
  {
    "id": "3515042016",
    "district_id": "351504",
    "label": "Plumbon",
    "value": "Plumbon"
  },
  {
    "id": "3515042018",
    "district_id": "351504",
    "label": "Wunut",
    "value": "Wunut"
  },
  {
    "id": "3515042019",
    "district_id": "351504",
    "label": "Pesawahan",
    "value": "Pesawahan"
  },
  {
    "id": "3515052001",
    "district_id": "351505",
    "label": "Panggreh",
    "value": "Panggreh"
  },
  {
    "id": "3515052002",
    "district_id": "351505",
    "label": "Trompoasri",
    "value": "Trompoasri"
  },
  {
    "id": "3515052003",
    "district_id": "351505",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3515052004",
    "district_id": "351505",
    "label": "Semambung",
    "value": "Semambung"
  },
  {
    "id": "3515052005",
    "district_id": "351505",
    "label": "Kedungpandan",
    "value": "Kedungpandan"
  },
  {
    "id": "3515052006",
    "district_id": "351505",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "3515052007",
    "district_id": "351505",
    "label": "Tambakkalisogo",
    "value": "Tambakkalisogo"
  },
  {
    "id": "3515052008",
    "district_id": "351505",
    "label": "Balongtani",
    "value": "Balongtani"
  },
  {
    "id": "3515052009",
    "district_id": "351505",
    "label": "Jemirahan",
    "value": "Jemirahan"
  },
  {
    "id": "3515052010",
    "district_id": "351505",
    "label": "Dukuhsari",
    "value": "Dukuhsari"
  },
  {
    "id": "3515052011",
    "district_id": "351505",
    "label": "Kedungcangkring",
    "value": "Kedungcangkring"
  },
  {
    "id": "3515052012",
    "district_id": "351505",
    "label": "Pajarakan",
    "value": "Pajarakan"
  },
  {
    "id": "3515052013",
    "district_id": "351505",
    "label": "Besuki",
    "value": "Besuki"
  },
  {
    "id": "3515052014",
    "district_id": "351505",
    "label": "Keboguyang",
    "value": "Keboguyang"
  },
  {
    "id": "3515052015",
    "district_id": "351505",
    "label": "Permisan",
    "value": "Permisan"
  },
  {
    "id": "3515062001",
    "district_id": "351506",
    "label": "Kalisampurno",
    "value": "Kalisampurno"
  },
  {
    "id": "3515062002",
    "district_id": "351506",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3515062003",
    "district_id": "351506",
    "label": "Kedungbendo",
    "value": "Kedungbendo"
  },
  {
    "id": "3515062004",
    "district_id": "351506",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3515062005",
    "district_id": "351506",
    "label": "Gempolsari",
    "value": "Gempolsari"
  },
  {
    "id": "3515062006",
    "district_id": "351506",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3515062007",
    "district_id": "351506",
    "label": "Penatarsewu",
    "value": "Penatarsewu"
  },
  {
    "id": "3515062008",
    "district_id": "351506",
    "label": "Banjarasri",
    "value": "Banjarasri"
  },
  {
    "id": "3515062009",
    "district_id": "351506",
    "label": "Banjarpanji",
    "value": "Banjarpanji"
  },
  {
    "id": "3515062010",
    "district_id": "351506",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3515062011",
    "district_id": "351506",
    "label": "Kalidawir",
    "value": "Kalidawir"
  },
  {
    "id": "3515062012",
    "district_id": "351506",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "3515062013",
    "district_id": "351506",
    "label": "Ngaban",
    "value": "Ngaban"
  },
  {
    "id": "3515062014",
    "district_id": "351506",
    "label": "Kludan",
    "value": "Kludan"
  },
  {
    "id": "3515062015",
    "district_id": "351506",
    "label": "Boro",
    "value": "Boro"
  },
  {
    "id": "3515062016",
    "district_id": "351506",
    "label": "Kedensari",
    "value": "Kedensari"
  },
  {
    "id": "3515062017",
    "district_id": "351506",
    "label": "Ketegan",
    "value": "Ketegan"
  },
  {
    "id": "3515062018",
    "district_id": "351506",
    "label": "Ganggangpanjang",
    "value": "Ganggangpanjang"
  },
  {
    "id": "3515062019",
    "district_id": "351506",
    "label": "Randegan",
    "value": "Randegan"
  },
  {
    "id": "3515072001",
    "district_id": "351507",
    "label": "Karangtanjung",
    "value": "Karangtanjung"
  },
  {
    "id": "3515072002",
    "district_id": "351507",
    "label": "Sumorame",
    "value": "Sumorame"
  },
  {
    "id": "3515072003",
    "district_id": "351507",
    "label": "Ngampelsari",
    "value": "Ngampelsari"
  },
  {
    "id": "3515072004",
    "district_id": "351507",
    "label": "Balonggabus",
    "value": "Balonggabus"
  },
  {
    "id": "3515072005",
    "district_id": "351507",
    "label": "Balongdowo",
    "value": "Balongdowo"
  },
  {
    "id": "3515072006",
    "district_id": "351507",
    "label": "Kendalpecabean",
    "value": "Kendalpecabean"
  },
  {
    "id": "3515072007",
    "district_id": "351507",
    "label": "Kedungpeluk",
    "value": "Kedungpeluk"
  },
  {
    "id": "3515072008",
    "district_id": "351507",
    "label": "Kalipecabean",
    "value": "Kalipecabean"
  },
  {
    "id": "3515072009",
    "district_id": "351507",
    "label": "Klurak",
    "value": "Klurak"
  },
  {
    "id": "3515072010",
    "district_id": "351507",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3515072011",
    "district_id": "351507",
    "label": "Gelam",
    "value": "Gelam"
  },
  {
    "id": "3515072012",
    "district_id": "351507",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3515072013",
    "district_id": "351507",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3515072014",
    "district_id": "351507",
    "label": "Kedungkendo",
    "value": "Kedungkendo"
  },
  {
    "id": "3515072015",
    "district_id": "351507",
    "label": "Durungbanjar",
    "value": "Durungbanjar"
  },
  {
    "id": "3515072016",
    "district_id": "351507",
    "label": "Durungbedug",
    "value": "Durungbedug"
  },
  {
    "id": "3515072017",
    "district_id": "351507",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3515072018",
    "district_id": "351507",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3515072019",
    "district_id": "351507",
    "label": "Sepande",
    "value": "Sepande"
  },
  {
    "id": "3515072020",
    "district_id": "351507",
    "label": "Sumokali",
    "value": "Sumokali"
  },
  {
    "id": "3515072021",
    "district_id": "351507",
    "label": "Tenggulunan",
    "value": "Tenggulunan"
  },
  {
    "id": "3515072022",
    "district_id": "351507",
    "label": "Bligo",
    "value": "Bligo"
  },
  {
    "id": "3515072023",
    "district_id": "351507",
    "label": "Wedoroklurak",
    "value": "Wedoroklurak"
  },
  {
    "id": "3515072024",
    "district_id": "351507",
    "label": "Larangan",
    "value": "Larangan"
  },
  {
    "id": "3515081004",
    "district_id": "351508",
    "label": "Sidokare",
    "value": "Sidokare"
  },
  {
    "id": "3515081005",
    "district_id": "351508",
    "label": "Celep",
    "value": "Celep"
  },
  {
    "id": "3515081006",
    "district_id": "351508",
    "label": "Sekardangan",
    "value": "Sekardangan"
  },
  {
    "id": "3515081007",
    "district_id": "351508",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3515081009",
    "district_id": "351508",
    "label": "Bulusidokare",
    "value": "Bulusidokare"
  },
  {
    "id": "3515081010",
    "district_id": "351508",
    "label": "Pucanganom",
    "value": "Pucanganom"
  },
  {
    "id": "3515081011",
    "district_id": "351508",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3515081012",
    "district_id": "351508",
    "label": "Lemahputro",
    "value": "Lemahputro"
  },
  {
    "id": "3515081013",
    "district_id": "351508",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3515081014",
    "district_id": "351508",
    "label": "Sidoklumpuk",
    "value": "Sidoklumpuk"
  },
  {
    "id": "3515081017",
    "district_id": "351508",
    "label": "Pucang",
    "value": "Pucang"
  },
  {
    "id": "3515081018",
    "district_id": "351508",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3515081020",
    "district_id": "351508",
    "label": "Cemenkalang",
    "value": "Cemenkalang"
  },
  {
    "id": "3515081022",
    "district_id": "351508",
    "label": "Urangagung",
    "value": "Urangagung"
  },
  {
    "id": "3515082001",
    "district_id": "351508",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "3515082002",
    "district_id": "351508",
    "label": "Suko",
    "value": "Suko"
  },
  {
    "id": "3515082003",
    "district_id": "351508",
    "label": "Banjarbendo",
    "value": "Banjarbendo"
  },
  {
    "id": "3515082008",
    "district_id": "351508",
    "label": "Rangkahkidul",
    "value": "Rangkahkidul"
  },
  {
    "id": "3515082015",
    "district_id": "351508",
    "label": "Blurukidul",
    "value": "Blurukidul"
  },
  {
    "id": "3515082016",
    "district_id": "351508",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3515082019",
    "district_id": "351508",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3515082021",
    "district_id": "351508",
    "label": "Cemengbakalan",
    "value": "Cemengbakalan"
  },
  {
    "id": "3515082023",
    "district_id": "351508",
    "label": "Sarirogo",
    "value": "Sarirogo"
  },
  {
    "id": "3515082024",
    "district_id": "351508",
    "label": "Sumput",
    "value": "Sumput"
  },
  {
    "id": "3515092001",
    "district_id": "351509",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3515092002",
    "district_id": "351509",
    "label": "Kebaron",
    "value": "Kebaron"
  },
  {
    "id": "3515092003",
    "district_id": "351509",
    "label": "Kenongo",
    "value": "Kenongo"
  },
  {
    "id": "3515092004",
    "district_id": "351509",
    "label": "Gelang",
    "value": "Gelang"
  },
  {
    "id": "3515092005",
    "district_id": "351509",
    "label": "Jiken",
    "value": "Jiken"
  },
  {
    "id": "3515092006",
    "district_id": "351509",
    "label": "Pangkemiri",
    "value": "Pangkemiri"
  },
  {
    "id": "3515092007",
    "district_id": "351509",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3515092008",
    "district_id": "351509",
    "label": "Tulangan",
    "value": "Tulangan"
  },
  {
    "id": "3515092009",
    "district_id": "351509",
    "label": "Kepadangan",
    "value": "Kepadangan"
  },
  {
    "id": "3515092010",
    "district_id": "351509",
    "label": "Tlasih",
    "value": "Tlasih"
  },
  {
    "id": "3515092011",
    "district_id": "351509",
    "label": "Kajeksan",
    "value": "Kajeksan"
  },
  {
    "id": "3515092012",
    "district_id": "351509",
    "label": "Singopadu",
    "value": "Singopadu"
  },
  {
    "id": "3515092013",
    "district_id": "351509",
    "label": "Kemantren",
    "value": "Kemantren"
  },
  {
    "id": "3515092014",
    "district_id": "351509",
    "label": "Kepunten",
    "value": "Kepunten"
  },
  {
    "id": "3515092015",
    "district_id": "351509",
    "label": "Kepuhkemiri",
    "value": "Kepuhkemiri"
  },
  {
    "id": "3515092016",
    "district_id": "351509",
    "label": "Grinting",
    "value": "Grinting"
  },
  {
    "id": "3515092017",
    "district_id": "351509",
    "label": "Modong",
    "value": "Modong"
  },
  {
    "id": "3515092018",
    "district_id": "351509",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3515092019",
    "district_id": "351509",
    "label": "Medalem",
    "value": "Medalem"
  },
  {
    "id": "3515092020",
    "district_id": "351509",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3515092021",
    "district_id": "351509",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "3515092022",
    "district_id": "351509",
    "label": "Grabagan",
    "value": "Grabagan"
  },
  {
    "id": "3515102001",
    "district_id": "351510",
    "label": "Tanggul",
    "value": "Tanggul"
  },
  {
    "id": "3515102002",
    "district_id": "351510",
    "label": "Simoketawang",
    "value": "Simoketawang"
  },
  {
    "id": "3515102003",
    "district_id": "351510",
    "label": "Popoh",
    "value": "Popoh"
  },
  {
    "id": "3515102004",
    "district_id": "351510",
    "label": "Jimbarankulon",
    "value": "Jimbarankulon"
  },
  {
    "id": "3515102005",
    "district_id": "351510",
    "label": "Jimbaranwetan",
    "value": "Jimbaranwetan"
  },
  {
    "id": "3515102006",
    "district_id": "351510",
    "label": "Ketimang",
    "value": "Ketimang"
  },
  {
    "id": "3515102007",
    "district_id": "351510",
    "label": "Pilang",
    "value": "Pilang"
  },
  {
    "id": "3515102008",
    "district_id": "351510",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3515102009",
    "district_id": "351510",
    "label": "Mojorangagung",
    "value": "Mojorangagung"
  },
  {
    "id": "3515102010",
    "district_id": "351510",
    "label": "Wonokasian",
    "value": "Wonokasian"
  },
  {
    "id": "3515102011",
    "district_id": "351510",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3515102012",
    "district_id": "351510",
    "label": "Mulyodadi",
    "value": "Mulyodadi"
  },
  {
    "id": "3515102013",
    "district_id": "351510",
    "label": "Wonoayu",
    "value": "Wonoayu"
  },
  {
    "id": "3515102014",
    "district_id": "351510",
    "label": "Semambung",
    "value": "Semambung"
  },
  {
    "id": "3515102015",
    "district_id": "351510",
    "label": "Simoangin-angin",
    "value": "Simoangin-angin"
  },
  {
    "id": "3515102016",
    "district_id": "351510",
    "label": "Wonokalang",
    "value": "Wonokalang"
  },
  {
    "id": "3515102017",
    "district_id": "351510",
    "label": "Pagerngumbuk",
    "value": "Pagerngumbuk"
  },
  {
    "id": "3515102018",
    "district_id": "351510",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3515102019",
    "district_id": "351510",
    "label": "Lambangan",
    "value": "Lambangan"
  },
  {
    "id": "3515102020",
    "district_id": "351510",
    "label": "Sawocangkring",
    "value": "Sawocangkring"
  },
  {
    "id": "3515102021",
    "district_id": "351510",
    "label": "Becirongengor",
    "value": "Becirongengor"
  },
  {
    "id": "3515102022",
    "district_id": "351510",
    "label": "Karangpuri",
    "value": "Karangpuri"
  },
  {
    "id": "3515102023",
    "district_id": "351510",
    "label": "Candinegoro",
    "value": "Candinegoro"
  },
  {
    "id": "3515111013",
    "district_id": "351511",
    "label": "Kemasan",
    "value": "Kemasan"
  },
  {
    "id": "3515111015",
    "district_id": "351511",
    "label": "Tambakkemerakan",
    "value": "Tambakkemerakan"
  },
  {
    "id": "3515111016",
    "district_id": "351511",
    "label": "Krian",
    "value": "Krian"
  },
  {
    "id": "3515112001",
    "district_id": "351511",
    "label": "Tropodo",
    "value": "Tropodo"
  },
  {
    "id": "3515112002",
    "district_id": "351511",
    "label": "Sedenganmijen",
    "value": "Sedenganmijen"
  },
  {
    "id": "3515112003",
    "district_id": "351511",
    "label": "Katerungan",
    "value": "Katerungan"
  },
  {
    "id": "3515112004",
    "district_id": "351511",
    "label": "Jerukgamping",
    "value": "Jerukgamping"
  },
  {
    "id": "3515112005",
    "district_id": "351511",
    "label": "Gamping",
    "value": "Gamping"
  },
  {
    "id": "3515112006",
    "district_id": "351511",
    "label": "Terik",
    "value": "Terik"
  },
  {
    "id": "3515112007",
    "district_id": "351511",
    "label": "Junwangi",
    "value": "Junwangi"
  },
  {
    "id": "3515112008",
    "district_id": "351511",
    "label": "Terungkulon",
    "value": "Terungkulon"
  },
  {
    "id": "3515112009",
    "district_id": "351511",
    "label": "Terungwetan",
    "value": "Terungwetan"
  },
  {
    "id": "3515112010",
    "district_id": "351511",
    "label": "Jatikalang",
    "value": "Jatikalang"
  },
  {
    "id": "3515112011",
    "district_id": "351511",
    "label": "Keboharan",
    "value": "Keboharan"
  },
  {
    "id": "3515112012",
    "district_id": "351511",
    "label": "Ponokawan",
    "value": "Ponokawan"
  },
  {
    "id": "3515112014",
    "district_id": "351511",
    "label": "Sidomojo",
    "value": "Sidomojo"
  },
  {
    "id": "3515112017",
    "district_id": "351511",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3515112018",
    "district_id": "351511",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3515112019",
    "district_id": "351511",
    "label": "Tempel",
    "value": "Tempel"
  },
  {
    "id": "3515112020",
    "district_id": "351511",
    "label": "Watugolong",
    "value": "Watugolong"
  },
  {
    "id": "3515112021",
    "district_id": "351511",
    "label": "Barengkrajan",
    "value": "Barengkrajan"
  },
  {
    "id": "3515112022",
    "district_id": "351511",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3515122001",
    "district_id": "351512",
    "label": "Wonokupang",
    "value": "Wonokupang"
  },
  {
    "id": "3515122002",
    "district_id": "351512",
    "label": "Sumokembangsri",
    "value": "Sumokembangsri"
  },
  {
    "id": "3515122003",
    "district_id": "351512",
    "label": "Singkalan",
    "value": "Singkalan"
  },
  {
    "id": "3515122004",
    "district_id": "351512",
    "label": "Bakungpringgodani",
    "value": "Bakungpringgodani"
  },
  {
    "id": "3515122005",
    "district_id": "351512",
    "label": "Wonokarang",
    "value": "Wonokarang"
  },
  {
    "id": "3515122006",
    "district_id": "351512",
    "label": "Seduri",
    "value": "Seduri"
  },
  {
    "id": "3515122007",
    "district_id": "351512",
    "label": "Bakalanwringinpitu",
    "value": "Bakalanwringinpitu"
  },
  {
    "id": "3515122008",
    "district_id": "351512",
    "label": "Gagangkepuhsari",
    "value": "Gagangkepuhsari"
  },
  {
    "id": "3515122009",
    "district_id": "351512",
    "label": "Suwaluh",
    "value": "Suwaluh"
  },
  {
    "id": "3515122010",
    "district_id": "351512",
    "label": "Watesari",
    "value": "Watesari"
  },
  {
    "id": "3515122011",
    "district_id": "351512",
    "label": "Seketi",
    "value": "Seketi"
  },
  {
    "id": "3515122012",
    "district_id": "351512",
    "label": "Kemangsen",
    "value": "Kemangsen"
  },
  {
    "id": "3515122013",
    "district_id": "351512",
    "label": "Jabaran",
    "value": "Jabaran"
  },
  {
    "id": "3515122014",
    "district_id": "351512",
    "label": "Balongbendo",
    "value": "Balongbendo"
  },
  {
    "id": "3515122015",
    "district_id": "351512",
    "label": "Jeruklegi",
    "value": "Jeruklegi"
  },
  {
    "id": "3515122016",
    "district_id": "351512",
    "label": "Penambangan",
    "value": "Penambangan"
  },
  {
    "id": "3515122017",
    "district_id": "351512",
    "label": "Waruberon",
    "value": "Waruberon"
  },
  {
    "id": "3515122018",
    "district_id": "351512",
    "label": "Bogempinggir",
    "value": "Bogempinggir"
  },
  {
    "id": "3515122019",
    "district_id": "351512",
    "label": "Kedungsukodani",
    "value": "Kedungsukodani"
  },
  {
    "id": "3515122020",
    "district_id": "351512",
    "label": "Bakungtemenggungan",
    "value": "Bakungtemenggungan"
  },
  {
    "id": "3515131005",
    "district_id": "351513",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3515131015",
    "district_id": "351513",
    "label": "Geluran",
    "value": "Geluran"
  },
  {
    "id": "3515131017",
    "district_id": "351513",
    "label": "Kalijaten",
    "value": "Kalijaten"
  },
  {
    "id": "3515131018",
    "district_id": "351513",
    "label": "Ketegan",
    "value": "Ketegan"
  },
  {
    "id": "3515131019",
    "district_id": "351513",
    "label": "Sepanjang",
    "value": "Sepanjang"
  },
  {
    "id": "3515131020",
    "district_id": "351513",
    "label": "Bebekan",
    "value": "Bebekan"
  },
  {
    "id": "3515131021",
    "district_id": "351513",
    "label": "Wonocolo",
    "value": "Wonocolo"
  },
  {
    "id": "3515131022",
    "district_id": "351513",
    "label": "Ngelom",
    "value": "Ngelom"
  },
  {
    "id": "3515132001",
    "district_id": "351513",
    "label": "Bohar",
    "value": "Bohar"
  },
  {
    "id": "3515132002",
    "district_id": "351513",
    "label": "Wage",
    "value": "Wage"
  },
  {
    "id": "3515132003",
    "district_id": "351513",
    "label": "Kedungturi",
    "value": "Kedungturi"
  },
  {
    "id": "3515132004",
    "district_id": "351513",
    "label": "Jemundo",
    "value": "Jemundo"
  },
  {
    "id": "3515132006",
    "district_id": "351513",
    "label": "Sadang",
    "value": "Sadang"
  },
  {
    "id": "3515132007",
    "district_id": "351513",
    "label": "Sambibulu",
    "value": "Sambibulu"
  },
  {
    "id": "3515132008",
    "district_id": "351513",
    "label": "Bringinbendo",
    "value": "Bringinbendo"
  },
  {
    "id": "3515132009",
    "district_id": "351513",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3515132010",
    "district_id": "351513",
    "label": "Kramatjegu",
    "value": "Kramatjegu"
  },
  {
    "id": "3515132011",
    "district_id": "351513",
    "label": "Trosobo",
    "value": "Trosobo"
  },
  {
    "id": "3515132012",
    "district_id": "351513",
    "label": "Pertapanmaduretno",
    "value": "Pertapanmaduretno"
  },
  {
    "id": "3515132013",
    "district_id": "351513",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3515132014",
    "district_id": "351513",
    "label": "Gilang",
    "value": "Gilang"
  },
  {
    "id": "3515132016",
    "district_id": "351513",
    "label": "Kletek",
    "value": "Kletek"
  },
  {
    "id": "3515132023",
    "district_id": "351513",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3515132024",
    "district_id": "351513",
    "label": "Krembangan",
    "value": "Krembangan"
  },
  {
    "id": "3515142001",
    "district_id": "351514",
    "label": "Wilayut",
    "value": "Wilayut"
  },
  {
    "id": "3515142002",
    "district_id": "351514",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3515142003",
    "district_id": "351514",
    "label": "Anggaswangi",
    "value": "Anggaswangi"
  },
  {
    "id": "3515142004",
    "district_id": "351514",
    "label": "Jumputrejo",
    "value": "Jumputrejo"
  },
  {
    "id": "3515142005",
    "district_id": "351514",
    "label": "Suruh",
    "value": "Suruh"
  },
  {
    "id": "3515142006",
    "district_id": "351514",
    "label": "Pekarungan",
    "value": "Pekarungan"
  },
  {
    "id": "3515142007",
    "district_id": "351514",
    "label": "Pademonegoro",
    "value": "Pademonegoro"
  },
  {
    "id": "3515142008",
    "district_id": "351514",
    "label": "Cangkringsari",
    "value": "Cangkringsari"
  },
  {
    "id": "3515142009",
    "district_id": "351514",
    "label": "Jogosatru",
    "value": "Jogosatru"
  },
  {
    "id": "3515142010",
    "district_id": "351514",
    "label": "Ngaresrejo",
    "value": "Ngaresrejo"
  },
  {
    "id": "3515142011",
    "district_id": "351514",
    "label": "Sambungrejo",
    "value": "Sambungrejo"
  },
  {
    "id": "3515142012",
    "district_id": "351514",
    "label": "Plumbungan",
    "value": "Plumbungan"
  },
  {
    "id": "3515142013",
    "district_id": "351514",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3515142014",
    "district_id": "351514",
    "label": "Kloposepuluh",
    "value": "Kloposepuluh"
  },
  {
    "id": "3515142015",
    "district_id": "351514",
    "label": "Masanganwetan",
    "value": "Masanganwetan"
  },
  {
    "id": "3515142016",
    "district_id": "351514",
    "label": "Suko",
    "value": "Suko"
  },
  {
    "id": "3515142017",
    "district_id": "351514",
    "label": "Masangankulon",
    "value": "Masangankulon"
  },
  {
    "id": "3515142018",
    "district_id": "351514",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3515142019",
    "district_id": "351514",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3515152001",
    "district_id": "351515",
    "label": "Entalsewu",
    "value": "Entalsewu"
  },
  {
    "id": "3515152002",
    "district_id": "351515",
    "label": "Pagerwojo",
    "value": "Pagerwojo"
  },
  {
    "id": "3515152003",
    "district_id": "351515",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "3515152004",
    "district_id": "351515",
    "label": "Buduran",
    "value": "Buduran"
  },
  {
    "id": "3515152005",
    "district_id": "351515",
    "label": "Siwalanpanji",
    "value": "Siwalanpanji"
  },
  {
    "id": "3515152006",
    "district_id": "351515",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3515152007",
    "district_id": "351515",
    "label": "Prasung",
    "value": "Prasung"
  },
  {
    "id": "3515152008",
    "district_id": "351515",
    "label": "Sawohan",
    "value": "Sawohan"
  },
  {
    "id": "3515152009",
    "district_id": "351515",
    "label": "Damarsi",
    "value": "Damarsi"
  },
  {
    "id": "3515152010",
    "district_id": "351515",
    "label": "Dukuhtengah",
    "value": "Dukuhtengah"
  },
  {
    "id": "3515152011",
    "district_id": "351515",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3515152012",
    "district_id": "351515",
    "label": "Wadungasih",
    "value": "Wadungasih"
  },
  {
    "id": "3515152013",
    "district_id": "351515",
    "label": "Banjarkemantren",
    "value": "Banjarkemantren"
  },
  {
    "id": "3515152014",
    "district_id": "351515",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3515152015",
    "district_id": "351515",
    "label": "Sidokepung",
    "value": "Sidokepung"
  },
  {
    "id": "3515162001",
    "district_id": "351516",
    "label": "Ganting",
    "value": "Ganting"
  },
  {
    "id": "3515162002",
    "district_id": "351516",
    "label": "Karangbong",
    "value": "Karangbong"
  },
  {
    "id": "3515162003",
    "district_id": "351516",
    "label": "Tebel",
    "value": "Tebel"
  },
  {
    "id": "3515162004",
    "district_id": "351516",
    "label": "Kragan",
    "value": "Kragan"
  },
  {
    "id": "3515162005",
    "district_id": "351516",
    "label": "Gemurung",
    "value": "Gemurung"
  },
  {
    "id": "3515162006",
    "district_id": "351516",
    "label": "Punggul",
    "value": "Punggul"
  },
  {
    "id": "3515162007",
    "district_id": "351516",
    "label": "Wedi",
    "value": "Wedi"
  },
  {
    "id": "3515162008",
    "district_id": "351516",
    "label": "Ketajen",
    "value": "Ketajen"
  },
  {
    "id": "3515162009",
    "district_id": "351516",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3515162010",
    "district_id": "351516",
    "label": "Sruni",
    "value": "Sruni"
  },
  {
    "id": "3515162011",
    "district_id": "351516",
    "label": "Keboansikep",
    "value": "Keboansikep"
  },
  {
    "id": "3515162012",
    "district_id": "351516",
    "label": "Keboananom",
    "value": "Keboananom"
  },
  {
    "id": "3515162013",
    "district_id": "351516",
    "label": "Bangah",
    "value": "Bangah"
  },
  {
    "id": "3515162014",
    "district_id": "351516",
    "label": "Sawotratap",
    "value": "Sawotratap"
  },
  {
    "id": "3515162015",
    "district_id": "351516",
    "label": "Semambung",
    "value": "Semambung"
  },
  {
    "id": "3515172001",
    "district_id": "351517",
    "label": "Kwangsan",
    "value": "Kwangsan"
  },
  {
    "id": "3515172002",
    "district_id": "351517",
    "label": "Pepe",
    "value": "Pepe"
  },
  {
    "id": "3515172003",
    "district_id": "351517",
    "label": "Buncitan",
    "value": "Buncitan"
  },
  {
    "id": "3515172004",
    "district_id": "351517",
    "label": "Kalanganyar",
    "value": "Kalanganyar"
  },
  {
    "id": "3515172005",
    "district_id": "351517",
    "label": "Tambakcemandi",
    "value": "Tambakcemandi"
  },
  {
    "id": "3515172006",
    "district_id": "351517",
    "label": "Gisik Cemandi",
    "value": "Gisik Cemandi"
  },
  {
    "id": "3515172007",
    "district_id": "351517",
    "label": "Cemandi",
    "value": "Cemandi"
  },
  {
    "id": "3515172008",
    "district_id": "351517",
    "label": "Pulungan",
    "value": "Pulungan"
  },
  {
    "id": "3515172009",
    "district_id": "351517",
    "label": "Betro",
    "value": "Betro"
  },
  {
    "id": "3515172010",
    "district_id": "351517",
    "label": "Sedatiagung",
    "value": "Sedatiagung"
  },
  {
    "id": "3515172011",
    "district_id": "351517",
    "label": "Sedatigede",
    "value": "Sedatigede"
  },
  {
    "id": "3515172012",
    "district_id": "351517",
    "label": "Pabean",
    "value": "Pabean"
  },
  {
    "id": "3515172013",
    "district_id": "351517",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3515172014",
    "district_id": "351517",
    "label": "Pranti",
    "value": "Pranti"
  },
  {
    "id": "3515172015",
    "district_id": "351517",
    "label": "Segorotambak",
    "value": "Segorotambak"
  },
  {
    "id": "3515172016",
    "district_id": "351517",
    "label": "Banjarkemuning",
    "value": "Banjarkemuning"
  },
  {
    "id": "3515182001",
    "district_id": "351518",
    "label": "Pepelegi",
    "value": "Pepelegi"
  },
  {
    "id": "3515182002",
    "district_id": "351518",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "3515182003",
    "district_id": "351518",
    "label": "Kureksari",
    "value": "Kureksari"
  },
  {
    "id": "3515182004",
    "district_id": "351518",
    "label": "Ngingas",
    "value": "Ngingas"
  },
  {
    "id": "3515182005",
    "district_id": "351518",
    "label": "Tropodo",
    "value": "Tropodo"
  },
  {
    "id": "3515182006",
    "district_id": "351518",
    "label": "Tambaksawah",
    "value": "Tambaksawah"
  },
  {
    "id": "3515182007",
    "district_id": "351518",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3515182008",
    "district_id": "351518",
    "label": "Tambakoso",
    "value": "Tambakoso"
  },
  {
    "id": "3515182009",
    "district_id": "351518",
    "label": "Tambaksumur",
    "value": "Tambaksumur"
  },
  {
    "id": "3515182010",
    "district_id": "351518",
    "label": "Wadungasri",
    "value": "Wadungasri"
  },
  {
    "id": "3515182011",
    "district_id": "351518",
    "label": "Kepuhkiriman",
    "value": "Kepuhkiriman"
  },
  {
    "id": "3515182012",
    "district_id": "351518",
    "label": "Berbek",
    "value": "Berbek"
  },
  {
    "id": "3515182013",
    "district_id": "351518",
    "label": "Wedoro",
    "value": "Wedoro"
  },
  {
    "id": "3515182014",
    "district_id": "351518",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3515182015",
    "district_id": "351518",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3515182016",
    "district_id": "351518",
    "label": "Medaeng",
    "value": "Medaeng"
  },
  {
    "id": "3515182017",
    "district_id": "351518",
    "label": "Bungurasih",
    "value": "Bungurasih"
  },
  {
    "id": "3516012001",
    "district_id": "351601",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3516012002",
    "district_id": "351601",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3516012003",
    "district_id": "351601",
    "label": "Jembul",
    "value": "Jembul"
  },
  {
    "id": "3516012004",
    "district_id": "351601",
    "label": "Manting",
    "value": "Manting"
  },
  {
    "id": "3516012005",
    "district_id": "351601",
    "label": "Sumberjati",
    "value": "Sumberjati"
  },
  {
    "id": "3516012006",
    "district_id": "351601",
    "label": "Lebakjabung",
    "value": "Lebakjabung"
  },
  {
    "id": "3516012007",
    "district_id": "351601",
    "label": "Bleberan",
    "value": "Bleberan"
  },
  {
    "id": "3516012008",
    "district_id": "351601",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3516012009",
    "district_id": "351601",
    "label": "Baureno",
    "value": "Baureno"
  },
  {
    "id": "3516012010",
    "district_id": "351601",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3516012011",
    "district_id": "351601",
    "label": "Dukuhngarjo",
    "value": "Dukuhngarjo"
  },
  {
    "id": "3516012012",
    "district_id": "351601",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3516012013",
    "district_id": "351601",
    "label": "Kumitir",
    "value": "Kumitir"
  },
  {
    "id": "3516012014",
    "district_id": "351601",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3516012015",
    "district_id": "351601",
    "label": "Gebangsari",
    "value": "Gebangsari"
  },
  {
    "id": "3516012016",
    "district_id": "351601",
    "label": "Dinoyo",
    "value": "Dinoyo"
  },
  {
    "id": "3516012017",
    "district_id": "351601",
    "label": "Padangasri",
    "value": "Padangasri"
  },
  {
    "id": "3516012018",
    "district_id": "351601",
    "label": "Mojogeneng",
    "value": "Mojogeneng"
  },
  {
    "id": "3516012019",
    "district_id": "351601",
    "label": "Karangjeruk",
    "value": "Karangjeruk"
  },
  {
    "id": "3516022001",
    "district_id": "351602",
    "label": "Gumeng",
    "value": "Gumeng"
  },
  {
    "id": "3516022002",
    "district_id": "351602",
    "label": "Begaganlimo",
    "value": "Begaganlimo"
  },
  {
    "id": "3516022003",
    "district_id": "351602",
    "label": "Kalikatir",
    "value": "Kalikatir"
  },
  {
    "id": "3516022004",
    "district_id": "351602",
    "label": "Dilem",
    "value": "Dilem"
  },
  {
    "id": "3516022005",
    "district_id": "351602",
    "label": "Ngembat",
    "value": "Ngembat"
  },
  {
    "id": "3516022006",
    "district_id": "351602",
    "label": "Jatidukuh",
    "value": "Jatidukuh"
  },
  {
    "id": "3516022007",
    "district_id": "351602",
    "label": "Bening",
    "value": "Bening"
  },
  {
    "id": "3516022008",
    "district_id": "351602",
    "label": "Karangkuten",
    "value": "Karangkuten"
  },
  {
    "id": "3516022009",
    "district_id": "351602",
    "label": "Tawar",
    "value": "Tawar"
  },
  {
    "id": "3516022010",
    "district_id": "351602",
    "label": "Pohjejer",
    "value": "Pohjejer"
  },
  {
    "id": "3516022011",
    "district_id": "351602",
    "label": "Wonoploso",
    "value": "Wonoploso"
  },
  {
    "id": "3516022012",
    "district_id": "351602",
    "label": "Pugeran",
    "value": "Pugeran"
  },
  {
    "id": "3516022013",
    "district_id": "351602",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3516022014",
    "district_id": "351602",
    "label": "Kebontunggul",
    "value": "Kebontunggul"
  },
  {
    "id": "3516022015",
    "district_id": "351602",
    "label": "Kemasantani",
    "value": "Kemasantani"
  },
  {
    "id": "3516022016",
    "district_id": "351602",
    "label": "Padi",
    "value": "Padi"
  },
  {
    "id": "3516022017",
    "district_id": "351602",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3516022018",
    "district_id": "351602",
    "label": "Centong",
    "value": "Centong"
  },
  {
    "id": "3516032001",
    "district_id": "351603",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3516032002",
    "district_id": "351603",
    "label": "Wiyu",
    "value": "Wiyu"
  },
  {
    "id": "3516032003",
    "district_id": "351603",
    "label": "Kesimantengah",
    "value": "Kesimantengah"
  },
  {
    "id": "3516032004",
    "district_id": "351603",
    "label": "Sajen",
    "value": "Sajen"
  },
  {
    "id": "3516032005",
    "district_id": "351603",
    "label": "Pacet",
    "value": "Pacet"
  },
  {
    "id": "3516032006",
    "district_id": "351603",
    "label": "Padusan",
    "value": "Padusan"
  },
  {
    "id": "3516032007",
    "district_id": "351603",
    "label": "Cepokolimo",
    "value": "Cepokolimo"
  },
  {
    "id": "3516032008",
    "district_id": "351603",
    "label": "Claket",
    "value": "Claket"
  },
  {
    "id": "3516032009",
    "district_id": "351603",
    "label": "Cembor",
    "value": "Cembor"
  },
  {
    "id": "3516032010",
    "district_id": "351603",
    "label": "Nogosari",
    "value": "Nogosari"
  },
  {
    "id": "3516032011",
    "district_id": "351603",
    "label": "Kembangbelor",
    "value": "Kembangbelor"
  },
  {
    "id": "3516032012",
    "district_id": "351603",
    "label": "Mojokembang",
    "value": "Mojokembang"
  },
  {
    "id": "3516032013",
    "district_id": "351603",
    "label": "Bendunganjati",
    "value": "Bendunganjati"
  },
  {
    "id": "3516032014",
    "district_id": "351603",
    "label": "Petak",
    "value": "Petak"
  },
  {
    "id": "3516032015",
    "district_id": "351603",
    "label": "Candiwatu",
    "value": "Candiwatu"
  },
  {
    "id": "3516032016",
    "district_id": "351603",
    "label": "Warugunung",
    "value": "Warugunung"
  },
  {
    "id": "3516032017",
    "district_id": "351603",
    "label": "Tanjungkenongo",
    "value": "Tanjungkenongo"
  },
  {
    "id": "3516032018",
    "district_id": "351603",
    "label": "Sumberkembar",
    "value": "Sumberkembar"
  },
  {
    "id": "3516032019",
    "district_id": "351603",
    "label": "Kuripansari",
    "value": "Kuripansari"
  },
  {
    "id": "3516032020",
    "district_id": "351603",
    "label": "Pandanarum",
    "value": "Pandanarum"
  },
  {
    "id": "3516042001",
    "district_id": "351604",
    "label": "Ketapanrame",
    "value": "Ketapanrame"
  },
  {
    "id": "3516042002",
    "district_id": "351604",
    "label": "Trawas",
    "value": "Trawas"
  },
  {
    "id": "3516042003",
    "district_id": "351604",
    "label": "Selotapak",
    "value": "Selotapak"
  },
  {
    "id": "3516042004",
    "district_id": "351604",
    "label": "Tamiajeng",
    "value": "Tamiajeng"
  },
  {
    "id": "3516042005",
    "district_id": "351604",
    "label": "Kesiman",
    "value": "Kesiman"
  },
  {
    "id": "3516042006",
    "district_id": "351604",
    "label": "Belik",
    "value": "Belik"
  },
  {
    "id": "3516042007",
    "district_id": "351604",
    "label": "Duyung",
    "value": "Duyung"
  },
  {
    "id": "3516042008",
    "district_id": "351604",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "3516042009",
    "district_id": "351604",
    "label": "Kedungudi",
    "value": "Kedungudi"
  },
  {
    "id": "3516042010",
    "district_id": "351604",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3516042011",
    "district_id": "351604",
    "label": "Jatijejer",
    "value": "Jatijejer"
  },
  {
    "id": "3516042012",
    "district_id": "351604",
    "label": "Sugeng",
    "value": "Sugeng"
  },
  {
    "id": "3516042013",
    "district_id": "351604",
    "label": "Seloliman",
    "value": "Seloliman"
  },
  {
    "id": "3516052001",
    "district_id": "351605",
    "label": "Srigading",
    "value": "Srigading"
  },
  {
    "id": "3516052002",
    "district_id": "351605",
    "label": "Kesemen",
    "value": "Kesemen"
  },
  {
    "id": "3516052003",
    "district_id": "351605",
    "label": "Kutogirang",
    "value": "Kutogirang"
  },
  {
    "id": "3516052004",
    "district_id": "351605",
    "label": "Lolawang",
    "value": "Lolawang"
  },
  {
    "id": "3516052005",
    "district_id": "351605",
    "label": "Wotanmasjedong",
    "value": "Wotanmasjedong"
  },
  {
    "id": "3516052006",
    "district_id": "351605",
    "label": "Tanjangrono",
    "value": "Tanjangrono"
  },
  {
    "id": "3516052007",
    "district_id": "351605",
    "label": "Kunjorowesi",
    "value": "Kunjorowesi"
  },
  {
    "id": "3516052008",
    "district_id": "351605",
    "label": "Manduro Manggunggajah",
    "value": "Manduro Manggunggajah"
  },
  {
    "id": "3516052009",
    "district_id": "351605",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3516052010",
    "district_id": "351605",
    "label": "Ngoro",
    "value": "Ngoro"
  },
  {
    "id": "3516052011",
    "district_id": "351605",
    "label": "Sedati",
    "value": "Sedati"
  },
  {
    "id": "3516052012",
    "district_id": "351605",
    "label": "Purwojati",
    "value": "Purwojati"
  },
  {
    "id": "3516052013",
    "district_id": "351605",
    "label": "Jasem",
    "value": "Jasem"
  },
  {
    "id": "3516052014",
    "district_id": "351605",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3516052015",
    "district_id": "351605",
    "label": "Bandarasri",
    "value": "Bandarasri"
  },
  {
    "id": "3516052016",
    "district_id": "351605",
    "label": "Kembangsri",
    "value": "Kembangsri"
  },
  {
    "id": "3516052017",
    "district_id": "351605",
    "label": "Candiharjo",
    "value": "Candiharjo"
  },
  {
    "id": "3516052018",
    "district_id": "351605",
    "label": "Watesnegoro",
    "value": "Watesnegoro"
  },
  {
    "id": "3516052019",
    "district_id": "351605",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3516062001",
    "district_id": "351606",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3516062002",
    "district_id": "351606",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3516062003",
    "district_id": "351606",
    "label": "Curahmojo",
    "value": "Curahmojo"
  },
  {
    "id": "3516062004",
    "district_id": "351606",
    "label": "Sekargadung",
    "value": "Sekargadung"
  },
  {
    "id": "3516062005",
    "district_id": "351606",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3516062006",
    "district_id": "351606",
    "label": "Jatilangkung",
    "value": "Jatilangkung"
  },
  {
    "id": "3516062007",
    "district_id": "351606",
    "label": "Banjartanggul",
    "value": "Banjartanggul"
  },
  {
    "id": "3516062008",
    "district_id": "351606",
    "label": "Kalipuro",
    "value": "Kalipuro"
  },
  {
    "id": "3516062009",
    "district_id": "351606",
    "label": "Randuharjo",
    "value": "Randuharjo"
  },
  {
    "id": "3516062010",
    "district_id": "351606",
    "label": "Kembangringgit",
    "value": "Kembangringgit"
  },
  {
    "id": "3516062011",
    "district_id": "351606",
    "label": "Pungging",
    "value": "Pungging"
  },
  {
    "id": "3516062012",
    "district_id": "351606",
    "label": "Lebaksono",
    "value": "Lebaksono"
  },
  {
    "id": "3516062013",
    "district_id": "351606",
    "label": "Tunggalpager",
    "value": "Tunggalpager"
  },
  {
    "id": "3516062014",
    "district_id": "351606",
    "label": "Balongmasin",
    "value": "Balongmasin"
  },
  {
    "id": "3516062015",
    "district_id": "351606",
    "label": "Jabontegal",
    "value": "Jabontegal"
  },
  {
    "id": "3516062016",
    "district_id": "351606",
    "label": "Kedungmungal",
    "value": "Kedungmungal"
  },
  {
    "id": "3516062017",
    "district_id": "351606",
    "label": "Watukenongo",
    "value": "Watukenongo"
  },
  {
    "id": "3516062018",
    "district_id": "351606",
    "label": "Ngrame",
    "value": "Ngrame"
  },
  {
    "id": "3516062019",
    "district_id": "351606",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "3516072001",
    "district_id": "351607",
    "label": "Payungrejo",
    "value": "Payungrejo"
  },
  {
    "id": "3516072002",
    "district_id": "351607",
    "label": "Simbaringin",
    "value": "Simbaringin"
  },
  {
    "id": "3516072003",
    "district_id": "351607",
    "label": "Sampangagung",
    "value": "Sampangagung"
  },
  {
    "id": "3516072004",
    "district_id": "351607",
    "label": "Jiyu",
    "value": "Jiyu"
  },
  {
    "id": "3516072005",
    "district_id": "351607",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3516072006",
    "district_id": "351607",
    "label": "Windurejo",
    "value": "Windurejo"
  },
  {
    "id": "3516072007",
    "district_id": "351607",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3516072008",
    "district_id": "351607",
    "label": "Kepuharum",
    "value": "Kepuharum"
  },
  {
    "id": "3516072009",
    "district_id": "351607",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3516072010",
    "district_id": "351607",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "3516072011",
    "district_id": "351607",
    "label": "Karangdiyeng",
    "value": "Karangdiyeng"
  },
  {
    "id": "3516072012",
    "district_id": "351607",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "3516072013",
    "district_id": "351607",
    "label": "Wonodadi",
    "value": "Wonodadi"
  },
  {
    "id": "3516072014",
    "district_id": "351607",
    "label": "Singowangi",
    "value": "Singowangi"
  },
  {
    "id": "3516072015",
    "district_id": "351607",
    "label": "Kepuhpandak",
    "value": "Kepuhpandak"
  },
  {
    "id": "3516072016",
    "district_id": "351607",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3516072017",
    "district_id": "351607",
    "label": "Kaligoro",
    "value": "Kaligoro"
  },
  {
    "id": "3516081004",
    "district_id": "351608",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3516081005",
    "district_id": "351608",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3516081006",
    "district_id": "351608",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3516081007",
    "district_id": "351608",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3516081008",
    "district_id": "351608",
    "label": "Wonokusumo",
    "value": "Wonokusumo"
  },
  {
    "id": "3516082001",
    "district_id": "351608",
    "label": "Sumbertanggul",
    "value": "Sumbertanggul"
  },
  {
    "id": "3516082002",
    "district_id": "351608",
    "label": "Belahantengah",
    "value": "Belahantengah"
  },
  {
    "id": "3516082003",
    "district_id": "351608",
    "label": "Awang-awang",
    "value": "Awang-awang"
  },
  {
    "id": "3516082009",
    "district_id": "351608",
    "label": "Seduri",
    "value": "Seduri"
  },
  {
    "id": "3516082010",
    "district_id": "351608",
    "label": "Mojosulur",
    "value": "Mojosulur"
  },
  {
    "id": "3516082011",
    "district_id": "351608",
    "label": "Randubango",
    "value": "Randubango"
  },
  {
    "id": "3516082012",
    "district_id": "351608",
    "label": "Jotangan",
    "value": "Jotangan"
  },
  {
    "id": "3516082013",
    "district_id": "351608",
    "label": "Menanggal",
    "value": "Menanggal"
  },
  {
    "id": "3516082014",
    "district_id": "351608",
    "label": "Pekukuhan",
    "value": "Pekukuhan"
  },
  {
    "id": "3516082015",
    "district_id": "351608",
    "label": "Modopuro",
    "value": "Modopuro"
  },
  {
    "id": "3516082016",
    "district_id": "351608",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3516082017",
    "district_id": "351608",
    "label": "Kedunggempol",
    "value": "Kedunggempol"
  },
  {
    "id": "3516082018",
    "district_id": "351608",
    "label": "Ngimbangan",
    "value": "Ngimbangan"
  },
  {
    "id": "3516082019",
    "district_id": "351608",
    "label": "Leminggir",
    "value": "Leminggir"
  },
  {
    "id": "3516092001",
    "district_id": "351609",
    "label": "Punggul",
    "value": "Punggul"
  },
  {
    "id": "3516092002",
    "district_id": "351609",
    "label": "Kalen",
    "value": "Kalen"
  },
  {
    "id": "3516092003",
    "district_id": "351609",
    "label": "Kedunggede",
    "value": "Kedunggede"
  },
  {
    "id": "3516092004",
    "district_id": "351609",
    "label": "Mojokarang",
    "value": "Mojokarang"
  },
  {
    "id": "3516092005",
    "district_id": "351609",
    "label": "Segunung",
    "value": "Segunung"
  },
  {
    "id": "3516092006",
    "district_id": "351609",
    "label": "Talok",
    "value": "Talok"
  },
  {
    "id": "3516092007",
    "district_id": "351609",
    "label": "Sumbersono",
    "value": "Sumbersono"
  },
  {
    "id": "3516092008",
    "district_id": "351609",
    "label": "Sambilawang",
    "value": "Sambilawang"
  },
  {
    "id": "3516092009",
    "district_id": "351609",
    "label": "Jrambe",
    "value": "Jrambe"
  },
  {
    "id": "3516092010",
    "district_id": "351609",
    "label": "Randugenengan",
    "value": "Randugenengan"
  },
  {
    "id": "3516092011",
    "district_id": "351609",
    "label": "Pohkecik",
    "value": "Pohkecik"
  },
  {
    "id": "3516092012",
    "district_id": "351609",
    "label": "Dlanggu",
    "value": "Dlanggu"
  },
  {
    "id": "3516092013",
    "district_id": "351609",
    "label": "Ngembeh",
    "value": "Ngembeh"
  },
  {
    "id": "3516092014",
    "district_id": "351609",
    "label": "Sumberkarang",
    "value": "Sumberkarang"
  },
  {
    "id": "3516092015",
    "district_id": "351609",
    "label": "Kedunglengkong",
    "value": "Kedunglengkong"
  },
  {
    "id": "3516092016",
    "district_id": "351609",
    "label": "Tumapel",
    "value": "Tumapel"
  },
  {
    "id": "3516102001",
    "district_id": "351610",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3516102002",
    "district_id": "351610",
    "label": "Sumberwono",
    "value": "Sumberwono"
  },
  {
    "id": "3516102003",
    "district_id": "351610",
    "label": "Kedunguneng",
    "value": "Kedunguneng"
  },
  {
    "id": "3516102004",
    "district_id": "351610",
    "label": "Kutoporong",
    "value": "Kutoporong"
  },
  {
    "id": "3516102005",
    "district_id": "351610",
    "label": "Ngastemi",
    "value": "Ngastemi"
  },
  {
    "id": "3516102006",
    "district_id": "351610",
    "label": "Peterongan",
    "value": "Peterongan"
  },
  {
    "id": "3516102007",
    "district_id": "351610",
    "label": "Bangsal",
    "value": "Bangsal"
  },
  {
    "id": "3516102008",
    "district_id": "351610",
    "label": "Sumbertebu",
    "value": "Sumbertebu"
  },
  {
    "id": "3516102009",
    "district_id": "351610",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3516102010",
    "district_id": "351610",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3516102011",
    "district_id": "351610",
    "label": "Tinggarbuntut",
    "value": "Tinggarbuntut"
  },
  {
    "id": "3516102012",
    "district_id": "351610",
    "label": "Pekuwon",
    "value": "Pekuwon"
  },
  {
    "id": "3516102013",
    "district_id": "351610",
    "label": "Salen",
    "value": "Salen"
  },
  {
    "id": "3516102014",
    "district_id": "351610",
    "label": "Mejoyo",
    "value": "Mejoyo"
  },
  {
    "id": "3516102015",
    "district_id": "351610",
    "label": "Ngrowo",
    "value": "Ngrowo"
  },
  {
    "id": "3516102016",
    "district_id": "351610",
    "label": "Puloniti",
    "value": "Puloniti"
  },
  {
    "id": "3516102017",
    "district_id": "351610",
    "label": "Mojotamping",
    "value": "Mojotamping"
  },
  {
    "id": "3516112001",
    "district_id": "351611",
    "label": "Tampungrejo",
    "value": "Tampungrejo"
  },
  {
    "id": "3516112002",
    "district_id": "351611",
    "label": "Plososari",
    "value": "Plososari"
  },
  {
    "id": "3516112003",
    "district_id": "351611",
    "label": "Kintelan",
    "value": "Kintelan"
  },
  {
    "id": "3516112004",
    "district_id": "351611",
    "label": "Brayung",
    "value": "Brayung"
  },
  {
    "id": "3516112005",
    "district_id": "351611",
    "label": "Ketemasdungus",
    "value": "Ketemasdungus"
  },
  {
    "id": "3516112006",
    "district_id": "351611",
    "label": "Puri",
    "value": "Puri"
  },
  {
    "id": "3516112007",
    "district_id": "351611",
    "label": "Tangunan",
    "value": "Tangunan"
  },
  {
    "id": "3516112008",
    "district_id": "351611",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3516112009",
    "district_id": "351611",
    "label": "Sumbergirang",
    "value": "Sumbergirang"
  },
  {
    "id": "3516112010",
    "district_id": "351611",
    "label": "Mlaten",
    "value": "Mlaten"
  },
  {
    "id": "3516112011",
    "district_id": "351611",
    "label": "Medali",
    "value": "Medali"
  },
  {
    "id": "3516112012",
    "district_id": "351611",
    "label": "Balongmojo",
    "value": "Balongmojo"
  },
  {
    "id": "3516112013",
    "district_id": "351611",
    "label": "Sumolawang",
    "value": "Sumolawang"
  },
  {
    "id": "3516112014",
    "district_id": "351611",
    "label": "Tambakagung",
    "value": "Tambakagung"
  },
  {
    "id": "3516112016",
    "district_id": "351611",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3516112017",
    "district_id": "351611",
    "label": "Kenanten",
    "value": "Kenanten"
  },
  {
    "id": "3516122001",
    "district_id": "351612",
    "label": "Jambuwok",
    "value": "Jambuwok"
  },
  {
    "id": "3516122002",
    "district_id": "351612",
    "label": "Domas",
    "value": "Domas"
  },
  {
    "id": "3516122003",
    "district_id": "351612",
    "label": "Beloh",
    "value": "Beloh"
  },
  {
    "id": "3516122004",
    "district_id": "351612",
    "label": "Temon",
    "value": "Temon"
  },
  {
    "id": "3516122005",
    "district_id": "351612",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3516122006",
    "district_id": "351612",
    "label": "Sentonorejo",
    "value": "Sentonorejo"
  },
  {
    "id": "3516122007",
    "district_id": "351612",
    "label": "Trowulan",
    "value": "Trowulan"
  },
  {
    "id": "3516122008",
    "district_id": "351612",
    "label": "Bejijong",
    "value": "Bejijong"
  },
  {
    "id": "3516122009",
    "district_id": "351612",
    "label": "Kejagan",
    "value": "Kejagan"
  },
  {
    "id": "3516122010",
    "district_id": "351612",
    "label": "Jatipasar",
    "value": "Jatipasar"
  },
  {
    "id": "3516122011",
    "district_id": "351612",
    "label": "Watesumpak",
    "value": "Watesumpak"
  },
  {
    "id": "3516122012",
    "district_id": "351612",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3516122013",
    "district_id": "351612",
    "label": "Panggih",
    "value": "Panggih"
  },
  {
    "id": "3516122014",
    "district_id": "351612",
    "label": "Tawangsari",
    "value": "Tawangsari"
  },
  {
    "id": "3516122015",
    "district_id": "351612",
    "label": "Bicak",
    "value": "Bicak"
  },
  {
    "id": "3516122016",
    "district_id": "351612",
    "label": "Balongwono",
    "value": "Balongwono"
  },
  {
    "id": "3516132001",
    "district_id": "351613",
    "label": "Gemekan",
    "value": "Gemekan"
  },
  {
    "id": "3516132002",
    "district_id": "351613",
    "label": "Blimbingsari",
    "value": "Blimbingsari"
  },
  {
    "id": "3516132003",
    "district_id": "351613",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3516132004",
    "district_id": "351613",
    "label": "Kedungmaling",
    "value": "Kedungmaling"
  },
  {
    "id": "3516132005",
    "district_id": "351613",
    "label": "Klinterejo",
    "value": "Klinterejo"
  },
  {
    "id": "3516132006",
    "district_id": "351613",
    "label": "Modongan",
    "value": "Modongan"
  },
  {
    "id": "3516132007",
    "district_id": "351613",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3516132008",
    "district_id": "351613",
    "label": "Jampirogo",
    "value": "Jampirogo"
  },
  {
    "id": "3516132009",
    "district_id": "351613",
    "label": "Japan",
    "value": "Japan"
  },
  {
    "id": "3516132010",
    "district_id": "351613",
    "label": "Sooko",
    "value": "Sooko"
  },
  {
    "id": "3516132011",
    "district_id": "351613",
    "label": "Wringinrejo",
    "value": "Wringinrejo"
  },
  {
    "id": "3516132012",
    "district_id": "351613",
    "label": "Karangkedawang",
    "value": "Karangkedawang"
  },
  {
    "id": "3516132013",
    "district_id": "351613",
    "label": "Mojoranu",
    "value": "Mojoranu"
  },
  {
    "id": "3516132014",
    "district_id": "351613",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3516132015",
    "district_id": "351613",
    "label": "Ngingasrembyong",
    "value": "Ngingasrembyong"
  },
  {
    "id": "3516142001",
    "district_id": "351614",
    "label": "Ngareskidul",
    "value": "Ngareskidul"
  },
  {
    "id": "3516142002",
    "district_id": "351614",
    "label": "Gembongan",
    "value": "Gembongan"
  },
  {
    "id": "3516142003",
    "district_id": "351614",
    "label": "Gempolkerep",
    "value": "Gempolkerep"
  },
  {
    "id": "3516142004",
    "district_id": "351614",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3516142005",
    "district_id": "351614",
    "label": "Gedeg",
    "value": "Gedeg"
  },
  {
    "id": "3516142006",
    "district_id": "351614",
    "label": "Pagerluyung",
    "value": "Pagerluyung"
  },
  {
    "id": "3516142007",
    "district_id": "351614",
    "label": "Kemantren",
    "value": "Kemantren"
  },
  {
    "id": "3516142008",
    "district_id": "351614",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "3516142009",
    "district_id": "351614",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3516142010",
    "district_id": "351614",
    "label": "Balongsari",
    "value": "Balongsari"
  },
  {
    "id": "3516142011",
    "district_id": "351614",
    "label": "Batankrajan",
    "value": "Batankrajan"
  },
  {
    "id": "3516142012",
    "district_id": "351614",
    "label": "Pagerjo",
    "value": "Pagerjo"
  },
  {
    "id": "3516142013",
    "district_id": "351614",
    "label": "Jerukseger",
    "value": "Jerukseger"
  },
  {
    "id": "3516142014",
    "district_id": "351614",
    "label": "Beratwetan",
    "value": "Beratwetan"
  },
  {
    "id": "3516152001",
    "district_id": "351615",
    "label": "Watesprojo",
    "value": "Watesprojo"
  },
  {
    "id": "3516152002",
    "district_id": "351615",
    "label": "Betro",
    "value": "Betro"
  },
  {
    "id": "3516152003",
    "district_id": "351615",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3516152004",
    "district_id": "351615",
    "label": "Mojojajar",
    "value": "Mojojajar"
  },
  {
    "id": "3516152005",
    "district_id": "351615",
    "label": "Beratkulon",
    "value": "Beratkulon"
  },
  {
    "id": "3516152006",
    "district_id": "351615",
    "label": "Mojosarirejo",
    "value": "Mojosarirejo"
  },
  {
    "id": "3516152007",
    "district_id": "351615",
    "label": "Mojowiryo",
    "value": "Mojowiryo"
  },
  {
    "id": "3516152008",
    "district_id": "351615",
    "label": "Mojowatesrejo",
    "value": "Mojowatesrejo"
  },
  {
    "id": "3516152009",
    "district_id": "351615",
    "label": "Mojogebang",
    "value": "Mojogebang"
  },
  {
    "id": "3516152010",
    "district_id": "351615",
    "label": "Mojopilang",
    "value": "Mojopilang"
  },
  {
    "id": "3516152011",
    "district_id": "351615",
    "label": "Mojokusumo",
    "value": "Mojokusumo"
  },
  {
    "id": "3516152012",
    "district_id": "351615",
    "label": "Japanan",
    "value": "Japanan"
  },
  {
    "id": "3516152013",
    "district_id": "351615",
    "label": "Mojokumpul",
    "value": "Mojokumpul"
  },
  {
    "id": "3516152014",
    "district_id": "351615",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "3516152015",
    "district_id": "351615",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3516152016",
    "district_id": "351615",
    "label": "Mojodadi",
    "value": "Mojodadi"
  },
  {
    "id": "3516152017",
    "district_id": "351615",
    "label": "Kemlagi",
    "value": "Kemlagi"
  },
  {
    "id": "3516152018",
    "district_id": "351615",
    "label": "Mojowono",
    "value": "Mojowono"
  },
  {
    "id": "3516152019",
    "district_id": "351615",
    "label": "Pandankrajan",
    "value": "Pandankrajan"
  },
  {
    "id": "3516152020",
    "district_id": "351615",
    "label": "Mojodowo",
    "value": "Mojodowo"
  },
  {
    "id": "3516162001",
    "district_id": "351616",
    "label": "Mlirip",
    "value": "Mlirip"
  },
  {
    "id": "3516162002",
    "district_id": "351616",
    "label": "Penompo",
    "value": "Penompo"
  },
  {
    "id": "3516162003",
    "district_id": "351616",
    "label": "Canggu",
    "value": "Canggu"
  },
  {
    "id": "3516162004",
    "district_id": "351616",
    "label": "Ngabar",
    "value": "Ngabar"
  },
  {
    "id": "3516162005",
    "district_id": "351616",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3516162006",
    "district_id": "351616",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "3516162007",
    "district_id": "351616",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3516162008",
    "district_id": "351616",
    "label": "Jolotundo",
    "value": "Jolotundo"
  },
  {
    "id": "3516162009",
    "district_id": "351616",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "3516162010",
    "district_id": "351616",
    "label": "Bendung",
    "value": "Bendung"
  },
  {
    "id": "3516162011",
    "district_id": "351616",
    "label": "Mojolebak",
    "value": "Mojolebak"
  },
  {
    "id": "3516162012",
    "district_id": "351616",
    "label": "Parengan",
    "value": "Parengan"
  },
  {
    "id": "3516162013",
    "district_id": "351616",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3516162014",
    "district_id": "351616",
    "label": "Perning",
    "value": "Perning"
  },
  {
    "id": "3516162015",
    "district_id": "351616",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3516162016",
    "district_id": "351616",
    "label": "Lakardowo",
    "value": "Lakardowo"
  },
  {
    "id": "3516172001",
    "district_id": "351617",
    "label": "Cendoro",
    "value": "Cendoro"
  },
  {
    "id": "3516172002",
    "district_id": "351617",
    "label": "Simongagrok",
    "value": "Simongagrok"
  },
  {
    "id": "3516172003",
    "district_id": "351617",
    "label": "Sumberwuluh",
    "value": "Sumberwuluh"
  },
  {
    "id": "3516172004",
    "district_id": "351617",
    "label": "Talunblandong",
    "value": "Talunblandong"
  },
  {
    "id": "3516172005",
    "district_id": "351617",
    "label": "Cinandang",
    "value": "Cinandang"
  },
  {
    "id": "3516172006",
    "district_id": "351617",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3516172007",
    "district_id": "351617",
    "label": "Dawarblandong",
    "value": "Dawarblandong"
  },
  {
    "id": "3516172008",
    "district_id": "351617",
    "label": "Pulorejo",
    "value": "Pulorejo"
  },
  {
    "id": "3516172009",
    "district_id": "351617",
    "label": "Jatirowo",
    "value": "Jatirowo"
  },
  {
    "id": "3516172010",
    "district_id": "351617",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "3516172011",
    "district_id": "351617",
    "label": "Bangeran",
    "value": "Bangeran"
  },
  {
    "id": "3516172012",
    "district_id": "351617",
    "label": "Pucuk",
    "value": "Pucuk"
  },
  {
    "id": "3516172013",
    "district_id": "351617",
    "label": "Banyulegi",
    "value": "Banyulegi"
  },
  {
    "id": "3516172014",
    "district_id": "351617",
    "label": "Gunungan",
    "value": "Gunungan"
  },
  {
    "id": "3516172015",
    "district_id": "351617",
    "label": "Brayublandong",
    "value": "Brayublandong"
  },
  {
    "id": "3516172016",
    "district_id": "351617",
    "label": "Madureso",
    "value": "Madureso"
  },
  {
    "id": "3516172017",
    "district_id": "351617",
    "label": "Temuireng",
    "value": "Temuireng"
  },
  {
    "id": "3516172018",
    "district_id": "351617",
    "label": "Randegan",
    "value": "Randegan"
  },
  {
    "id": "3516182001",
    "district_id": "351618",
    "label": "Sadartengah",
    "value": "Sadartengah"
  },
  {
    "id": "3516182002",
    "district_id": "351618",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3516182003",
    "district_id": "351618",
    "label": "Kepuhanyar",
    "value": "Kepuhanyar"
  },
  {
    "id": "3516182004",
    "district_id": "351618",
    "label": "Sumberjati",
    "value": "Sumberjati"
  },
  {
    "id": "3516182005",
    "district_id": "351618",
    "label": "Gebangmalang",
    "value": "Gebangmalang"
  },
  {
    "id": "3516182006",
    "district_id": "351618",
    "label": "Jabon",
    "value": "Jabon"
  },
  {
    "id": "3516182008",
    "district_id": "351618",
    "label": "Ngarjo",
    "value": "Ngarjo"
  },
  {
    "id": "3516182009",
    "district_id": "351618",
    "label": "Wunut",
    "value": "Wunut"
  },
  {
    "id": "3516182010",
    "district_id": "351618",
    "label": "Kwedenkembar",
    "value": "Kwedenkembar"
  },
  {
    "id": "3516182011",
    "district_id": "351618",
    "label": "Jumeneng",
    "value": "Jumeneng"
  },
  {
    "id": "3516182012",
    "district_id": "351618",
    "label": "Kwatu",
    "value": "Kwatu"
  },
  {
    "id": "3516182013",
    "district_id": "351618",
    "label": "Gayaman",
    "value": "Gayaman"
  },
  {
    "id": "3517012001",
    "district_id": "351701",
    "label": "Jantiganggong",
    "value": "Jantiganggong"
  },
  {
    "id": "3517012002",
    "district_id": "351701",
    "label": "Kepuhkajang",
    "value": "Kepuhkajang"
  },
  {
    "id": "3517012003",
    "district_id": "351701",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3517012004",
    "district_id": "351701",
    "label": "Pagerwojo",
    "value": "Pagerwojo"
  },
  {
    "id": "3517012005",
    "district_id": "351701",
    "label": "Perak",
    "value": "Perak"
  },
  {
    "id": "3517012006",
    "district_id": "351701",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3517012007",
    "district_id": "351701",
    "label": "Glagahan",
    "value": "Glagahan"
  },
  {
    "id": "3517012008",
    "district_id": "351701",
    "label": "Kalangsemanding",
    "value": "Kalangsemanding"
  },
  {
    "id": "3517012009",
    "district_id": "351701",
    "label": "Gadingmangu",
    "value": "Gadingmangu"
  },
  {
    "id": "3517012010",
    "district_id": "351701",
    "label": "Plosogenuk",
    "value": "Plosogenuk"
  },
  {
    "id": "3517012011",
    "district_id": "351701",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3517012012",
    "district_id": "351701",
    "label": "Temuwulan",
    "value": "Temuwulan"
  },
  {
    "id": "3517012013",
    "district_id": "351701",
    "label": "Cangkringrandu",
    "value": "Cangkringrandu"
  },
  {
    "id": "3517022001",
    "district_id": "351702",
    "label": "Pucangro",
    "value": "Pucangro"
  },
  {
    "id": "3517022002",
    "district_id": "351702",
    "label": "Kedungturi",
    "value": "Kedungturi"
  },
  {
    "id": "3517022003",
    "district_id": "351702",
    "label": "Japanan",
    "value": "Japanan"
  },
  {
    "id": "3517022004",
    "district_id": "351702",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3517022005",
    "district_id": "351702",
    "label": "Mentaos",
    "value": "Mentaos"
  },
  {
    "id": "3517022006",
    "district_id": "351702",
    "label": "Sukoiber",
    "value": "Sukoiber"
  },
  {
    "id": "3517022007",
    "district_id": "351702",
    "label": "Sukopinggir",
    "value": "Sukopinggir"
  },
  {
    "id": "3517022008",
    "district_id": "351702",
    "label": "Bugasur Kedaleman",
    "value": "Bugasur Kedaleman"
  },
  {
    "id": "3517022009",
    "district_id": "351702",
    "label": "Gudo",
    "value": "Gudo"
  },
  {
    "id": "3517022010",
    "district_id": "351702",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3517022011",
    "district_id": "351702",
    "label": "Wangkalkepuh",
    "value": "Wangkalkepuh"
  },
  {
    "id": "3517022012",
    "district_id": "351702",
    "label": "Krembangan",
    "value": "Krembangan"
  },
  {
    "id": "3517022013",
    "district_id": "351702",
    "label": "Sepanyul",
    "value": "Sepanyul"
  },
  {
    "id": "3517022014",
    "district_id": "351702",
    "label": "Godong",
    "value": "Godong"
  },
  {
    "id": "3517022015",
    "district_id": "351702",
    "label": "Mejoyolosari",
    "value": "Mejoyolosari"
  },
  {
    "id": "3517022016",
    "district_id": "351702",
    "label": "Plumbon Gambang",
    "value": "Plumbon Gambang"
  },
  {
    "id": "3517022017",
    "district_id": "351702",
    "label": "Gempollegundi",
    "value": "Gempollegundi"
  },
  {
    "id": "3517022018",
    "district_id": "351702",
    "label": "Tanggungan",
    "value": "Tanggungan"
  },
  {
    "id": "3517032001",
    "district_id": "351703",
    "label": "Jombok",
    "value": "Jombok"
  },
  {
    "id": "3517032002",
    "district_id": "351703",
    "label": "Genukwatu",
    "value": "Genukwatu"
  },
  {
    "id": "3517032003",
    "district_id": "351703",
    "label": "Rejoagung",
    "value": "Rejoagung"
  },
  {
    "id": "3517032004",
    "district_id": "351703",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3517032005",
    "district_id": "351703",
    "label": "Ngoro",
    "value": "Ngoro"
  },
  {
    "id": "3517032006",
    "district_id": "351703",
    "label": "Badang",
    "value": "Badang"
  },
  {
    "id": "3517032007",
    "district_id": "351703",
    "label": "Pulorejo",
    "value": "Pulorejo"
  },
  {
    "id": "3517032008",
    "district_id": "351703",
    "label": "Banyuarang",
    "value": "Banyuarang"
  },
  {
    "id": "3517032009",
    "district_id": "351703",
    "label": "Sidowarek",
    "value": "Sidowarek"
  },
  {
    "id": "3517032010",
    "district_id": "351703",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "3517032011",
    "district_id": "351703",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  {
    "id": "3517032012",
    "district_id": "351703",
    "label": "Kertorejo",
    "value": "Kertorejo"
  },
  {
    "id": "3517032013",
    "district_id": "351703",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3517042001",
    "district_id": "351704",
    "label": "Kebondalem",
    "value": "Kebondalem"
  },
  {
    "id": "3517042002",
    "district_id": "351704",
    "label": "Mundusewu",
    "value": "Mundusewu"
  },
  {
    "id": "3517042003",
    "district_id": "351704",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3517042004",
    "district_id": "351704",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "3517042005",
    "district_id": "351704",
    "label": "Ngampungan",
    "value": "Ngampungan"
  },
  {
    "id": "3517042006",
    "district_id": "351704",
    "label": "Jenisgelaran",
    "value": "Jenisgelaran"
  },
  {
    "id": "3517042007",
    "district_id": "351704",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3517042008",
    "district_id": "351704",
    "label": "Tebel",
    "value": "Tebel"
  },
  {
    "id": "3517042009",
    "district_id": "351704",
    "label": "Mojotengah",
    "value": "Mojotengah"
  },
  {
    "id": "3517042010",
    "district_id": "351704",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3517042011",
    "district_id": "351704",
    "label": "Nglebak",
    "value": "Nglebak"
  },
  {
    "id": "3517042012",
    "district_id": "351704",
    "label": "Ngrimbi",
    "value": "Ngrimbi"
  },
  {
    "id": "3517042013",
    "district_id": "351704",
    "label": "Pulosari",
    "value": "Pulosari"
  },
  {
    "id": "3517052001",
    "district_id": "351705",
    "label": "Galengdowo",
    "value": "Galengdowo"
  },
  {
    "id": "3517052002",
    "district_id": "351705",
    "label": "Wonomerto",
    "value": "Wonomerto"
  },
  {
    "id": "3517052003",
    "district_id": "351705",
    "label": "Jarak",
    "value": "Jarak"
  },
  {
    "id": "3517052004",
    "district_id": "351705",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3517052005",
    "district_id": "351705",
    "label": "Wonosalam",
    "value": "Wonosalam"
  },
  {
    "id": "3517052006",
    "district_id": "351705",
    "label": "Carangwulung",
    "value": "Carangwulung"
  },
  {
    "id": "3517052007",
    "district_id": "351705",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3517052008",
    "district_id": "351705",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3517052009",
    "district_id": "351705",
    "label": "Panglungan",
    "value": "Panglungan"
  },
  {
    "id": "3517062001",
    "district_id": "351706",
    "label": "Kedunglumpang",
    "value": "Kedunglumpang"
  },
  {
    "id": "3517062002",
    "district_id": "351706",
    "label": "Dukuhmojo",
    "value": "Dukuhmojo"
  },
  {
    "id": "3517062003",
    "district_id": "351706",
    "label": "Karangwinongan",
    "value": "Karangwinongan"
  },
  {
    "id": "3517062004",
    "district_id": "351706",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3517062005",
    "district_id": "351706",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3517062006",
    "district_id": "351706",
    "label": "Tejo",
    "value": "Tejo"
  },
  {
    "id": "3517062007",
    "district_id": "351706",
    "label": "Gambiran",
    "value": "Gambiran"
  },
  {
    "id": "3517062008",
    "district_id": "351706",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3517062009",
    "district_id": "351706",
    "label": "Mojotrisno",
    "value": "Mojotrisno"
  },
  {
    "id": "3517062010",
    "district_id": "351706",
    "label": "Tanggalrejo",
    "value": "Tanggalrejo"
  },
  {
    "id": "3517062011",
    "district_id": "351706",
    "label": "Dukuhdimoro",
    "value": "Dukuhdimoro"
  },
  {
    "id": "3517062012",
    "district_id": "351706",
    "label": "Miagan",
    "value": "Miagan"
  },
  {
    "id": "3517062013",
    "district_id": "351706",
    "label": "Mancilan",
    "value": "Mancilan"
  },
  {
    "id": "3517062014",
    "district_id": "351706",
    "label": "Betek",
    "value": "Betek"
  },
  {
    "id": "3517062015",
    "district_id": "351706",
    "label": "Karobelah",
    "value": "Karobelah"
  },
  {
    "id": "3517062016",
    "district_id": "351706",
    "label": "Murukan",
    "value": "Murukan"
  },
  {
    "id": "3517062017",
    "district_id": "351706",
    "label": "Johowinong",
    "value": "Johowinong"
  },
  {
    "id": "3517062018",
    "district_id": "351706",
    "label": "Seketi",
    "value": "Seketi"
  },
  {
    "id": "3517072001",
    "district_id": "351707",
    "label": "Kedungpari",
    "value": "Kedungpari"
  },
  {
    "id": "3517072002",
    "district_id": "351707",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3517072003",
    "district_id": "351707",
    "label": "Latsari",
    "value": "Latsari"
  },
  {
    "id": "3517072004",
    "district_id": "351707",
    "label": "Mojowarno",
    "value": "Mojowarno"
  },
  {
    "id": "3517072005",
    "district_id": "351707",
    "label": "Penggaron",
    "value": "Penggaron"
  },
  {
    "id": "3517072006",
    "district_id": "351707",
    "label": "Mojoduwur",
    "value": "Mojoduwur"
  },
  {
    "id": "3517072007",
    "district_id": "351707",
    "label": "Mojowangi",
    "value": "Mojowangi"
  },
  {
    "id": "3517072008",
    "district_id": "351707",
    "label": "Gondek",
    "value": "Gondek"
  },
  {
    "id": "3517072009",
    "district_id": "351707",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3517072010",
    "district_id": "351707",
    "label": "Mojojejer",
    "value": "Mojojejer"
  },
  {
    "id": "3517072011",
    "district_id": "351707",
    "label": "Japanan",
    "value": "Japanan"
  },
  {
    "id": "3517072012",
    "district_id": "351707",
    "label": "Menganto",
    "value": "Menganto"
  },
  {
    "id": "3517072013",
    "district_id": "351707",
    "label": "Grobogan",
    "value": "Grobogan"
  },
  {
    "id": "3517072014",
    "district_id": "351707",
    "label": "Rejoslamet",
    "value": "Rejoslamet"
  },
  {
    "id": "3517072015",
    "district_id": "351707",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3517072016",
    "district_id": "351707",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "3517072017",
    "district_id": "351707",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3517072018",
    "district_id": "351707",
    "label": "Catakgayam",
    "value": "Catakgayam"
  },
  {
    "id": "3517072019",
    "district_id": "351707",
    "label": "Wringinpitu",
    "value": "Wringinpitu"
  },
  {
    "id": "3517082001",
    "district_id": "351708",
    "label": "Kayangan",
    "value": "Kayangan"
  },
  {
    "id": "3517082002",
    "district_id": "351708",
    "label": "Puton",
    "value": "Puton"
  },
  {
    "id": "3517082003",
    "district_id": "351708",
    "label": "Bendet",
    "value": "Bendet"
  },
  {
    "id": "3517082004",
    "district_id": "351708",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3517082005",
    "district_id": "351708",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3517082006",
    "district_id": "351708",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3517082007",
    "district_id": "351708",
    "label": "Cukir",
    "value": "Cukir"
  },
  {
    "id": "3517082008",
    "district_id": "351708",
    "label": "Kwaron",
    "value": "Kwaron"
  },
  {
    "id": "3517082009",
    "district_id": "351708",
    "label": "Watugaluh",
    "value": "Watugaluh"
  },
  {
    "id": "3517082010",
    "district_id": "351708",
    "label": "Pundong",
    "value": "Pundong"
  },
  {
    "id": "3517082011",
    "district_id": "351708",
    "label": "Diwek",
    "value": "Diwek"
  },
  {
    "id": "3517082012",
    "district_id": "351708",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3517082013",
    "district_id": "351708",
    "label": "Kedawong",
    "value": "Kedawong"
  },
  {
    "id": "3517082014",
    "district_id": "351708",
    "label": "Ngudirejo",
    "value": "Ngudirejo"
  },
  {
    "id": "3517082015",
    "district_id": "351708",
    "label": "Ceweng",
    "value": "Ceweng"
  },
  {
    "id": "3517082016",
    "district_id": "351708",
    "label": "Balongbesuk",
    "value": "Balongbesuk"
  },
  {
    "id": "3517082017",
    "district_id": "351708",
    "label": "Pandanwangi",
    "value": "Pandanwangi"
  },
  {
    "id": "3517082018",
    "district_id": "351708",
    "label": "Brambang",
    "value": "Brambang"
  },
  {
    "id": "3517082019",
    "district_id": "351708",
    "label": "Jatipelem",
    "value": "Jatipelem"
  },
  {
    "id": "3517082020",
    "district_id": "351708",
    "label": "Keras",
    "value": "Keras"
  },
  {
    "id": "3517091002",
    "district_id": "351709",
    "label": "Jombatan",
    "value": "Jombatan"
  },
  {
    "id": "3517091004",
    "district_id": "351709",
    "label": "Kaliwungu",
    "value": "Kaliwungu"
  },
  {
    "id": "3517091005",
    "district_id": "351709",
    "label": "Jelakombo",
    "value": "Jelakombo"
  },
  {
    "id": "3517091006",
    "district_id": "351709",
    "label": "Kepanjen",
    "value": "Kepanjen"
  },
  {
    "id": "3517092001",
    "district_id": "351709",
    "label": "Mojongapit",
    "value": "Mojongapit"
  },
  {
    "id": "3517092003",
    "district_id": "351709",
    "label": "Plandi",
    "value": "Plandi"
  },
  {
    "id": "3517092007",
    "district_id": "351709",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3517092008",
    "district_id": "351709",
    "label": "Pulolor",
    "value": "Pulolor"
  },
  {
    "id": "3517092009",
    "district_id": "351709",
    "label": "Sengon",
    "value": "Sengon"
  },
  {
    "id": "3517092010",
    "district_id": "351709",
    "label": "Tunggorono",
    "value": "Tunggorono"
  },
  {
    "id": "3517092011",
    "district_id": "351709",
    "label": "Denanyar",
    "value": "Denanyar"
  },
  {
    "id": "3517092012",
    "district_id": "351709",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "3517092013",
    "district_id": "351709",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3517092014",
    "district_id": "351709",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3517092015",
    "district_id": "351709",
    "label": "Banjardowo",
    "value": "Banjardowo"
  },
  {
    "id": "3517092016",
    "district_id": "351709",
    "label": "Sambongdukuh",
    "value": "Sambongdukuh"
  },
  {
    "id": "3517092017",
    "district_id": "351709",
    "label": "Dapurkejambon",
    "value": "Dapurkejambon"
  },
  {
    "id": "3517092018",
    "district_id": "351709",
    "label": "Jabon",
    "value": "Jabon"
  },
  {
    "id": "3517092019",
    "district_id": "351709",
    "label": "Plosogeneng",
    "value": "Plosogeneng"
  },
  {
    "id": "3517092020",
    "district_id": "351709",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3517102001",
    "district_id": "351710",
    "label": "Peterongan",
    "value": "Peterongan"
  },
  {
    "id": "3517102002",
    "district_id": "351710",
    "label": "Keplaksari",
    "value": "Keplaksari"
  },
  {
    "id": "3517102003",
    "district_id": "351710",
    "label": "Kepuhkembeng",
    "value": "Kepuhkembeng"
  },
  {
    "id": "3517102004",
    "district_id": "351710",
    "label": "Mancar",
    "value": "Mancar"
  },
  {
    "id": "3517102005",
    "district_id": "351710",
    "label": "Tugusumberjo",
    "value": "Tugusumberjo"
  },
  {
    "id": "3517102006",
    "district_id": "351710",
    "label": "Morosunggingan",
    "value": "Morosunggingan"
  },
  {
    "id": "3517102007",
    "district_id": "351710",
    "label": "Kebontemu",
    "value": "Kebontemu"
  },
  {
    "id": "3517102008",
    "district_id": "351710",
    "label": "Dukuhklopo",
    "value": "Dukuhklopo"
  },
  {
    "id": "3517102009",
    "district_id": "351710",
    "label": "Tanjunggunung",
    "value": "Tanjunggunung"
  },
  {
    "id": "3517102010",
    "district_id": "351710",
    "label": "Bongkot",
    "value": "Bongkot"
  },
  {
    "id": "3517102011",
    "district_id": "351710",
    "label": "Senden",
    "value": "Senden"
  },
  {
    "id": "3517102012",
    "district_id": "351710",
    "label": "Ngrandulor",
    "value": "Ngrandulor"
  },
  {
    "id": "3517102013",
    "district_id": "351710",
    "label": "Tengaran",
    "value": "Tengaran"
  },
  {
    "id": "3517102014",
    "district_id": "351710",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3517112001",
    "district_id": "351711",
    "label": "Plosokerep",
    "value": "Plosokerep"
  },
  {
    "id": "3517112002",
    "district_id": "351711",
    "label": "Jogoloyo",
    "value": "Jogoloyo"
  },
  {
    "id": "3517112003",
    "district_id": "351711",
    "label": "Palrejo",
    "value": "Palrejo"
  },
  {
    "id": "3517112004",
    "district_id": "351711",
    "label": "Plemahan",
    "value": "Plemahan"
  },
  {
    "id": "3517112005",
    "district_id": "351711",
    "label": "Brudu",
    "value": "Brudu"
  },
  {
    "id": "3517112006",
    "district_id": "351711",
    "label": "Badas",
    "value": "Badas"
  },
  {
    "id": "3517112007",
    "district_id": "351711",
    "label": "Nglele",
    "value": "Nglele"
  },
  {
    "id": "3517112008",
    "district_id": "351711",
    "label": "Trawasan",
    "value": "Trawasan"
  },
  {
    "id": "3517112009",
    "district_id": "351711",
    "label": "Sebani",
    "value": "Sebani"
  },
  {
    "id": "3517112010",
    "district_id": "351711",
    "label": "Mlaras",
    "value": "Mlaras"
  },
  {
    "id": "3517112011",
    "district_id": "351711",
    "label": "Segodorejo",
    "value": "Segodorejo"
  },
  {
    "id": "3517112012",
    "district_id": "351711",
    "label": "Kedung Papar",
    "value": "Kedung Papar"
  },
  {
    "id": "3517112013",
    "district_id": "351711",
    "label": "Sumobito",
    "value": "Sumobito"
  },
  {
    "id": "3517112014",
    "district_id": "351711",
    "label": "Curahmalang",
    "value": "Curahmalang"
  },
  {
    "id": "3517112015",
    "district_id": "351711",
    "label": "Budugsidorejo",
    "value": "Budugsidorejo"
  },
  {
    "id": "3517112016",
    "district_id": "351711",
    "label": "Kendalsari",
    "value": "Kendalsari"
  },
  {
    "id": "3517112017",
    "district_id": "351711",
    "label": "Talunkidul",
    "value": "Talunkidul"
  },
  {
    "id": "3517112018",
    "district_id": "351711",
    "label": "Madiopuro",
    "value": "Madiopuro"
  },
  {
    "id": "3517112019",
    "district_id": "351711",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3517112020",
    "district_id": "351711",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3517112021",
    "district_id": "351711",
    "label": "Mentoro",
    "value": "Mentoro"
  },
  {
    "id": "3517122001",
    "district_id": "351712",
    "label": "Kedungbetik",
    "value": "Kedungbetik"
  },
  {
    "id": "3517122002",
    "district_id": "351712",
    "label": "Kedungmlati",
    "value": "Kedungmlati"
  },
  {
    "id": "3517122003",
    "district_id": "351712",
    "label": "Watudakon",
    "value": "Watudakon"
  },
  {
    "id": "3517122004",
    "district_id": "351712",
    "label": "Carangrejo",
    "value": "Carangrejo"
  },
  {
    "id": "3517122005",
    "district_id": "351712",
    "label": "Jombok",
    "value": "Jombok"
  },
  {
    "id": "3517122006",
    "district_id": "351712",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3517122007",
    "district_id": "351712",
    "label": "Wuluh",
    "value": "Wuluh"
  },
  {
    "id": "3517122008",
    "district_id": "351712",
    "label": "Pojokrejo",
    "value": "Pojokrejo"
  },
  {
    "id": "3517122009",
    "district_id": "351712",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  {
    "id": "3517122010",
    "district_id": "351712",
    "label": "Podoroto",
    "value": "Podoroto"
  },
  {
    "id": "3517122011",
    "district_id": "351712",
    "label": "Jombatan",
    "value": "Jombatan"
  },
  {
    "id": "3517122012",
    "district_id": "351712",
    "label": "Pojokkulon",
    "value": "Pojokkulon"
  },
  {
    "id": "3517122013",
    "district_id": "351712",
    "label": "Gumulan",
    "value": "Gumulan"
  },
  {
    "id": "3517122014",
    "district_id": "351712",
    "label": "Jatiduwur",
    "value": "Jatiduwur"
  },
  {
    "id": "3517132001",
    "district_id": "351713",
    "label": "Mojokrapak",
    "value": "Mojokrapak"
  },
  {
    "id": "3517132002",
    "district_id": "351713",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3517132003",
    "district_id": "351713",
    "label": "Tampingmojo",
    "value": "Tampingmojo"
  },
  {
    "id": "3517132004",
    "district_id": "351713",
    "label": "Kalikejambon",
    "value": "Kalikejambon"
  },
  {
    "id": "3517132005",
    "district_id": "351713",
    "label": "Kedunglosari",
    "value": "Kedunglosari"
  },
  {
    "id": "3517132006",
    "district_id": "351713",
    "label": "Kedungotok",
    "value": "Kedungotok"
  },
  {
    "id": "3517132007",
    "district_id": "351713",
    "label": "Tembelang",
    "value": "Tembelang"
  },
  {
    "id": "3517132008",
    "district_id": "351713",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3517132009",
    "district_id": "351713",
    "label": "Gabusbanaran",
    "value": "Gabusbanaran"
  },
  {
    "id": "3517132010",
    "district_id": "351713",
    "label": "Pulorejo",
    "value": "Pulorejo"
  },
  {
    "id": "3517132011",
    "district_id": "351713",
    "label": "Rejosopinggir",
    "value": "Rejosopinggir"
  },
  {
    "id": "3517132012",
    "district_id": "351713",
    "label": "Jatiwates",
    "value": "Jatiwates"
  },
  {
    "id": "3517132013",
    "district_id": "351713",
    "label": "Kepuhdoko",
    "value": "Kepuhdoko"
  },
  {
    "id": "3517132014",
    "district_id": "351713",
    "label": "Pulogedang",
    "value": "Pulogedang"
  },
  {
    "id": "3517132015",
    "district_id": "351713",
    "label": "Bedahlawak",
    "value": "Bedahlawak"
  },
  {
    "id": "3517142001",
    "district_id": "351714",
    "label": "Tanggungkramat",
    "value": "Tanggungkramat"
  },
  {
    "id": "3517142002",
    "district_id": "351714",
    "label": "Rejoagung",
    "value": "Rejoagung"
  },
  {
    "id": "3517142003",
    "district_id": "351714",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3517142004",
    "district_id": "351714",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3517142005",
    "district_id": "351714",
    "label": "Jatigedong",
    "value": "Jatigedong"
  },
  {
    "id": "3517142006",
    "district_id": "351714",
    "label": "Daditunggal",
    "value": "Daditunggal"
  },
  {
    "id": "3517142007",
    "district_id": "351714",
    "label": "Gedungombo",
    "value": "Gedungombo"
  },
  {
    "id": "3517142008",
    "district_id": "351714",
    "label": "Jatibanjar",
    "value": "Jatibanjar"
  },
  {
    "id": "3517142009",
    "district_id": "351714",
    "label": "Pagertanjung",
    "value": "Pagertanjung"
  },
  {
    "id": "3517142010",
    "district_id": "351714",
    "label": "Pandanblole",
    "value": "Pandanblole"
  },
  {
    "id": "3517142011",
    "district_id": "351714",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3517142012",
    "district_id": "351714",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3517142013",
    "district_id": "351714",
    "label": "Bawangan",
    "value": "Bawangan"
  },
  {
    "id": "3517152001",
    "district_id": "351715",
    "label": "Klitih",
    "value": "Klitih"
  },
  {
    "id": "3517152002",
    "district_id": "351715",
    "label": "Plabuhan",
    "value": "Plabuhan"
  },
  {
    "id": "3517152003",
    "district_id": "351715",
    "label": "Kampungbaru",
    "value": "Kampungbaru"
  },
  {
    "id": "3517152004",
    "district_id": "351715",
    "label": "Gebangbunder",
    "value": "Gebangbunder"
  },
  {
    "id": "3517152005",
    "district_id": "351715",
    "label": "Jatimlerek",
    "value": "Jatimlerek"
  },
  {
    "id": "3517152006",
    "district_id": "351715",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3517152007",
    "district_id": "351715",
    "label": "Plandaan",
    "value": "Plandaan"
  },
  {
    "id": "3517152008",
    "district_id": "351715",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3517152009",
    "district_id": "351715",
    "label": "Purisemanding",
    "value": "Purisemanding"
  },
  {
    "id": "3517152010",
    "district_id": "351715",
    "label": "Tondowulan",
    "value": "Tondowulan"
  },
  {
    "id": "3517152011",
    "district_id": "351715",
    "label": "Darurejo",
    "value": "Darurejo"
  },
  {
    "id": "3517152012",
    "district_id": "351715",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3517152013",
    "district_id": "351715",
    "label": "Jiporapah",
    "value": "Jiporapah"
  },
  {
    "id": "3517162001",
    "district_id": "351716",
    "label": "Marmoyo",
    "value": "Marmoyo"
  },
  {
    "id": "3517162002",
    "district_id": "351716",
    "label": "Tanjungwadung",
    "value": "Tanjungwadung"
  },
  {
    "id": "3517162003",
    "district_id": "351716",
    "label": "Mangunan",
    "value": "Mangunan"
  },
  {
    "id": "3517162004",
    "district_id": "351716",
    "label": "Kabuh",
    "value": "Kabuh"
  },
  {
    "id": "3517162005",
    "district_id": "351716",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "3517162006",
    "district_id": "351716",
    "label": "Banjardowo",
    "value": "Banjardowo"
  },
  {
    "id": "3517162007",
    "district_id": "351716",
    "label": "Karangpakis",
    "value": "Karangpakis"
  },
  {
    "id": "3517162008",
    "district_id": "351716",
    "label": "Sumberingin",
    "value": "Sumberingin"
  },
  {
    "id": "3517162009",
    "district_id": "351716",
    "label": "Sumbergondang",
    "value": "Sumbergondang"
  },
  {
    "id": "3517162010",
    "district_id": "351716",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3517162011",
    "district_id": "351716",
    "label": "Munungkerep",
    "value": "Munungkerep"
  },
  {
    "id": "3517162012",
    "district_id": "351716",
    "label": "Genenganjasem",
    "value": "Genenganjasem"
  },
  {
    "id": "3517162013",
    "district_id": "351716",
    "label": "Manduro",
    "value": "Manduro"
  },
  {
    "id": "3517162014",
    "district_id": "351716",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3517162015",
    "district_id": "351716",
    "label": "Pengampon",
    "value": "Pengampon"
  },
  {
    "id": "3517162016",
    "district_id": "351716",
    "label": "Sumberaji",
    "value": "Sumberaji"
  },
  {
    "id": "3517172001",
    "district_id": "351717",
    "label": "Sidokaton",
    "value": "Sidokaton"
  },
  {
    "id": "3517172002",
    "district_id": "351717",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "3517172003",
    "district_id": "351717",
    "label": "Bakalanrayung",
    "value": "Bakalanrayung"
  },
  {
    "id": "3517172004",
    "district_id": "351717",
    "label": "Randuwatang",
    "value": "Randuwatang"
  },
  {
    "id": "3517172005",
    "district_id": "351717",
    "label": "Sumberteguh",
    "value": "Sumberteguh"
  },
  {
    "id": "3517172006",
    "district_id": "351717",
    "label": "Menturus",
    "value": "Menturus"
  },
  {
    "id": "3517172007",
    "district_id": "351717",
    "label": "Kudubanjar",
    "value": "Kudubanjar"
  },
  {
    "id": "3517172008",
    "district_id": "351717",
    "label": "Made",
    "value": "Made"
  },
  {
    "id": "3517172009",
    "district_id": "351717",
    "label": "Kepuhrejo",
    "value": "Kepuhrejo"
  },
  {
    "id": "3517172010",
    "district_id": "351717",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3517172011",
    "district_id": "351717",
    "label": "Katemas",
    "value": "Katemas"
  },
  {
    "id": "3517182001",
    "district_id": "351718",
    "label": "Bandarkedungmulyo",
    "value": "Bandarkedungmulyo"
  },
  {
    "id": "3517182002",
    "district_id": "351718",
    "label": "Mojokambang",
    "value": "Mojokambang"
  },
  {
    "id": "3517182003",
    "district_id": "351718",
    "label": "Barongsawahan",
    "value": "Barongsawahan"
  },
  {
    "id": "3517182004",
    "district_id": "351718",
    "label": "Kayen",
    "value": "Kayen"
  },
  {
    "id": "3517182005",
    "district_id": "351718",
    "label": "Gondangmanis",
    "value": "Gondangmanis"
  },
  {
    "id": "3517182006",
    "district_id": "351718",
    "label": "Brodot",
    "value": "Brodot"
  },
  {
    "id": "3517182007",
    "district_id": "351718",
    "label": "Pucangsimo",
    "value": "Pucangsimo"
  },
  {
    "id": "3517182008",
    "district_id": "351718",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3517182009",
    "district_id": "351718",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3517182010",
    "district_id": "351718",
    "label": "Karangdagangan",
    "value": "Karangdagangan"
  },
  {
    "id": "3517182011",
    "district_id": "351718",
    "label": "Tinggar",
    "value": "Tinggar"
  },
  {
    "id": "3517192001",
    "district_id": "351719",
    "label": "Jogoroto",
    "value": "Jogoroto"
  },
  {
    "id": "3517192002",
    "district_id": "351719",
    "label": "Jarakkulon",
    "value": "Jarakkulon"
  },
  {
    "id": "3517192003",
    "district_id": "351719",
    "label": "Alang-alang Caruban",
    "value": "Alang-alang Caruban"
  },
  {
    "id": "3517192004",
    "district_id": "351719",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3517192005",
    "district_id": "351719",
    "label": "Sawiji",
    "value": "Sawiji"
  },
  {
    "id": "3517192006",
    "district_id": "351719",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "3517192007",
    "district_id": "351719",
    "label": "Sumbermulyo",
    "value": "Sumbermulyo"
  },
  {
    "id": "3517192008",
    "district_id": "351719",
    "label": "Ngumpul",
    "value": "Ngumpul"
  },
  {
    "id": "3517192009",
    "district_id": "351719",
    "label": "Tambar",
    "value": "Tambar"
  },
  {
    "id": "3517192010",
    "district_id": "351719",
    "label": "Janti",
    "value": "Janti"
  },
  {
    "id": "3517192011",
    "district_id": "351719",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3517202001",
    "district_id": "351720",
    "label": "Turipinggir",
    "value": "Turipinggir"
  },
  {
    "id": "3517202002",
    "district_id": "351720",
    "label": "Gongseng",
    "value": "Gongseng"
  },
  {
    "id": "3517202003",
    "district_id": "351720",
    "label": "Megaluh",
    "value": "Megaluh"
  },
  {
    "id": "3517202004",
    "district_id": "351720",
    "label": "Sudimoro",
    "value": "Sudimoro"
  },
  {
    "id": "3517202005",
    "district_id": "351720",
    "label": "Balongsari",
    "value": "Balongsari"
  },
  {
    "id": "3517202006",
    "district_id": "351720",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3517202007",
    "district_id": "351720",
    "label": "Ngogri",
    "value": "Ngogri"
  },
  {
    "id": "3517202008",
    "district_id": "351720",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3517202009",
    "district_id": "351720",
    "label": "Balonggemek",
    "value": "Balonggemek"
  },
  {
    "id": "3517202010",
    "district_id": "351720",
    "label": "Dukuharum",
    "value": "Dukuharum"
  },
  {
    "id": "3517202011",
    "district_id": "351720",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3517202012",
    "district_id": "351720",
    "label": "Pacarpeluk",
    "value": "Pacarpeluk"
  },
  {
    "id": "3517202013",
    "district_id": "351720",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3517212001",
    "district_id": "351721",
    "label": "Ketapangkuning",
    "value": "Ketapangkuning"
  },
  {
    "id": "3517212002",
    "district_id": "351721",
    "label": "Keboan",
    "value": "Keboan"
  },
  {
    "id": "3517212003",
    "district_id": "351721",
    "label": "Kedungbogo",
    "value": "Kedungbogo"
  },
  {
    "id": "3517212004",
    "district_id": "351721",
    "label": "Ngusikan",
    "value": "Ngusikan"
  },
  {
    "id": "3517212005",
    "district_id": "351721",
    "label": "Sumbernongko",
    "value": "Sumbernongko"
  },
  {
    "id": "3517212006",
    "district_id": "351721",
    "label": "Cupak",
    "value": "Cupak"
  },
  {
    "id": "3517212007",
    "district_id": "351721",
    "label": "Manunggal",
    "value": "Manunggal"
  },
  {
    "id": "3517212008",
    "district_id": "351721",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3517212009",
    "district_id": "351721",
    "label": "Mojodanu",
    "value": "Mojodanu"
  },
  {
    "id": "3517212010",
    "district_id": "351721",
    "label": "Kromong",
    "value": "Kromong"
  },
  {
    "id": "3517212011",
    "district_id": "351721",
    "label": "Asemgede",
    "value": "Asemgede"
  },
  {
    "id": "3518012001",
    "district_id": "351801",
    "label": "Bendolo",
    "value": "Bendolo"
  },
  {
    "id": "3518012002",
    "district_id": "351801",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3518012003",
    "district_id": "351801",
    "label": "Ngliman",
    "value": "Ngliman"
  },
  {
    "id": "3518012004",
    "district_id": "351801",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3518012005",
    "district_id": "351801",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3518012006",
    "district_id": "351801",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3518012007",
    "district_id": "351801",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "3518012008",
    "district_id": "351801",
    "label": "Margopatut",
    "value": "Margopatut"
  },
  {
    "id": "3518012009",
    "district_id": "351801",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3518022001",
    "district_id": "351802",
    "label": "Kuncir",
    "value": "Kuncir"
  },
  {
    "id": "3518022002",
    "district_id": "351802",
    "label": "Kepel",
    "value": "Kepel"
  },
  {
    "id": "3518022003",
    "district_id": "351802",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "3518022004",
    "district_id": "351802",
    "label": "Ngetos",
    "value": "Ngetos"
  },
  {
    "id": "3518022005",
    "district_id": "351802",
    "label": "Klodan",
    "value": "Klodan"
  },
  {
    "id": "3518022006",
    "district_id": "351802",
    "label": "Mojoduwur",
    "value": "Mojoduwur"
  },
  {
    "id": "3518022007",
    "district_id": "351802",
    "label": "Kweden",
    "value": "Kweden"
  },
  {
    "id": "3518022008",
    "district_id": "351802",
    "label": "Blongko",
    "value": "Blongko"
  },
  {
    "id": "3518022009",
    "district_id": "351802",
    "label": "Oro-oro Ombo",
    "value": "Oro-oro Ombo"
  },
  {
    "id": "3518032001",
    "district_id": "351803",
    "label": "Salamrojo",
    "value": "Salamrojo"
  },
  {
    "id": "3518032002",
    "district_id": "351803",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3518032003",
    "district_id": "351803",
    "label": "Maguan",
    "value": "Maguan"
  },
  {
    "id": "3518032004",
    "district_id": "351803",
    "label": "Semare",
    "value": "Semare"
  },
  {
    "id": "3518032005",
    "district_id": "351803",
    "label": "Berbek",
    "value": "Berbek"
  },
  {
    "id": "3518032006",
    "district_id": "351803",
    "label": "Mlilir",
    "value": "Mlilir"
  },
  {
    "id": "3518032007",
    "district_id": "351803",
    "label": "Bendungrejo",
    "value": "Bendungrejo"
  },
  {
    "id": "3518032008",
    "district_id": "351803",
    "label": "Sengkut",
    "value": "Sengkut"
  },
  {
    "id": "3518032009",
    "district_id": "351803",
    "label": "Ngrawan",
    "value": "Ngrawan"
  },
  {
    "id": "3518032010",
    "district_id": "351803",
    "label": "Sumberwindu",
    "value": "Sumberwindu"
  },
  {
    "id": "3518032011",
    "district_id": "351803",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3518032012",
    "district_id": "351803",
    "label": "Sendangbumen",
    "value": "Sendangbumen"
  },
  {
    "id": "3518032013",
    "district_id": "351803",
    "label": "Patranrejo",
    "value": "Patranrejo"
  },
  {
    "id": "3518032014",
    "district_id": "351803",
    "label": "Sumberurip",
    "value": "Sumberurip"
  },
  {
    "id": "3518032015",
    "district_id": "351803",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3518032016",
    "district_id": "351803",
    "label": "Grojogan",
    "value": "Grojogan"
  },
  {
    "id": "3518032017",
    "district_id": "351803",
    "label": "Tiripan",
    "value": "Tiripan"
  },
  {
    "id": "3518032018",
    "district_id": "351803",
    "label": "Balongrejo",
    "value": "Balongrejo"
  },
  {
    "id": "3518032019",
    "district_id": "351803",
    "label": "Sonopatik",
    "value": "Sonopatik"
  },
  {
    "id": "3518042001",
    "district_id": "351804",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3518042002",
    "district_id": "351804",
    "label": "Sombron",
    "value": "Sombron"
  },
  {
    "id": "3518042003",
    "district_id": "351804",
    "label": "Genjeng",
    "value": "Genjeng"
  },
  {
    "id": "3518042004",
    "district_id": "351804",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3518042005",
    "district_id": "351804",
    "label": "Ngepeh",
    "value": "Ngepeh"
  },
  {
    "id": "3518042006",
    "district_id": "351804",
    "label": "Godean",
    "value": "Godean"
  },
  {
    "id": "3518042007",
    "district_id": "351804",
    "label": "Patihan",
    "value": "Patihan"
  },
  {
    "id": "3518042008",
    "district_id": "351804",
    "label": "Mungkung",
    "value": "Mungkung"
  },
  {
    "id": "3518042009",
    "district_id": "351804",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3518042010",
    "district_id": "351804",
    "label": "Loceret",
    "value": "Loceret"
  },
  {
    "id": "3518042011",
    "district_id": "351804",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3518042012",
    "district_id": "351804",
    "label": "Tempel Wetan",
    "value": "Tempel Wetan"
  },
  {
    "id": "3518042013",
    "district_id": "351804",
    "label": "Tekenglagahan",
    "value": "Tekenglagahan"
  },
  {
    "id": "3518042014",
    "district_id": "351804",
    "label": "Gejagan",
    "value": "Gejagan"
  },
  {
    "id": "3518042015",
    "district_id": "351804",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3518042016",
    "district_id": "351804",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3518042017",
    "district_id": "351804",
    "label": "Kwagean",
    "value": "Kwagean"
  },
  {
    "id": "3518042018",
    "district_id": "351804",
    "label": "Kenep",
    "value": "Kenep"
  },
  {
    "id": "3518042019",
    "district_id": "351804",
    "label": "Putukrejo",
    "value": "Putukrejo"
  },
  {
    "id": "3518042020",
    "district_id": "351804",
    "label": "Nglaban",
    "value": "Nglaban"
  },
  {
    "id": "3518042021",
    "district_id": "351804",
    "label": "Macanan",
    "value": "Macanan"
  },
  {
    "id": "3518042022",
    "district_id": "351804",
    "label": "Bajulan",
    "value": "Bajulan"
  },
  {
    "id": "3518052001",
    "district_id": "351805",
    "label": "Jampes",
    "value": "Jampes"
  },
  {
    "id": "3518052002",
    "district_id": "351805",
    "label": "Mlandangan",
    "value": "Mlandangan"
  },
  {
    "id": "3518052003",
    "district_id": "351805",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3518052004",
    "district_id": "351805",
    "label": "Jatigreges",
    "value": "Jatigreges"
  },
  {
    "id": "3518052005",
    "district_id": "351805",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3518052006",
    "district_id": "351805",
    "label": "Sanan",
    "value": "Sanan"
  },
  {
    "id": "3518052007",
    "district_id": "351805",
    "label": "Pacekulon",
    "value": "Pacekulon"
  },
  {
    "id": "3518052008",
    "district_id": "351805",
    "label": "Cerme",
    "value": "Cerme"
  },
  {
    "id": "3518052009",
    "district_id": "351805",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3518052010",
    "district_id": "351805",
    "label": "Batembat",
    "value": "Batembat"
  },
  {
    "id": "3518052011",
    "district_id": "351805",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3518052012",
    "district_id": "351805",
    "label": "Bodor",
    "value": "Bodor"
  },
  {
    "id": "3518052013",
    "district_id": "351805",
    "label": "Pace Wetan",
    "value": "Pace Wetan"
  },
  {
    "id": "3518052014",
    "district_id": "351805",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3518052015",
    "district_id": "351805",
    "label": "Gemenggeng",
    "value": "Gemenggeng"
  },
  {
    "id": "3518052016",
    "district_id": "351805",
    "label": "Kecubung",
    "value": "Kecubung"
  },
  {
    "id": "3518052017",
    "district_id": "351805",
    "label": "Plosoharjo",
    "value": "Plosoharjo"
  },
  {
    "id": "3518052018",
    "district_id": "351805",
    "label": "Kepanjen",
    "value": "Kepanjen"
  },
  {
    "id": "3518062001",
    "district_id": "351806",
    "label": "Gondanglegi",
    "value": "Gondanglegi"
  },
  {
    "id": "3518062002",
    "district_id": "351806",
    "label": "Singkalanyar",
    "value": "Singkalanyar"
  },
  {
    "id": "3518062003",
    "district_id": "351806",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3518062004",
    "district_id": "351806",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3518062005",
    "district_id": "351806",
    "label": "Nglawak",
    "value": "Nglawak"
  },
  {
    "id": "3518062006",
    "district_id": "351806",
    "label": "Baleturi",
    "value": "Baleturi"
  },
  {
    "id": "3518062007",
    "district_id": "351806",
    "label": "Tegaron",
    "value": "Tegaron"
  },
  {
    "id": "3518062008",
    "district_id": "351806",
    "label": "Tanjungtani",
    "value": "Tanjungtani"
  },
  {
    "id": "3518062009",
    "district_id": "351806",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3518062010",
    "district_id": "351806",
    "label": "Rowoharjo",
    "value": "Rowoharjo"
  },
  {
    "id": "3518062011",
    "district_id": "351806",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3518062012",
    "district_id": "351806",
    "label": "Watudandang",
    "value": "Watudandang"
  },
  {
    "id": "3518062013",
    "district_id": "351806",
    "label": "Sono Ageng",
    "value": "Sono Ageng"
  },
  {
    "id": "3518062014",
    "district_id": "351806",
    "label": "Kurungrejo",
    "value": "Kurungrejo"
  },
  {
    "id": "3518072001",
    "district_id": "351807",
    "label": "Cengkok",
    "value": "Cengkok"
  },
  {
    "id": "3518072002",
    "district_id": "351807",
    "label": "Tanjungkalang",
    "value": "Tanjungkalang"
  },
  {
    "id": "3518072003",
    "district_id": "351807",
    "label": "Juwet",
    "value": "Juwet"
  },
  {
    "id": "3518072004",
    "district_id": "351807",
    "label": "Kelutan",
    "value": "Kelutan"
  },
  {
    "id": "3518072005",
    "district_id": "351807",
    "label": "Ngronggot",
    "value": "Ngronggot"
  },
  {
    "id": "3518072006",
    "district_id": "351807",
    "label": "Mojokendil",
    "value": "Mojokendil"
  },
  {
    "id": "3518072007",
    "district_id": "351807",
    "label": "Betet",
    "value": "Betet"
  },
  {
    "id": "3518072008",
    "district_id": "351807",
    "label": "Kaloran",
    "value": "Kaloran"
  },
  {
    "id": "3518072009",
    "district_id": "351807",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3518072010",
    "district_id": "351807",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3518072011",
    "district_id": "351807",
    "label": "Trayang",
    "value": "Trayang"
  },
  {
    "id": "3518072012",
    "district_id": "351807",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3518072013",
    "district_id": "351807",
    "label": "Klurahan",
    "value": "Klurahan"
  },
  {
    "id": "3518081011",
    "district_id": "351808",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3518082001",
    "district_id": "351808",
    "label": "Drenges",
    "value": "Drenges"
  },
  {
    "id": "3518082002",
    "district_id": "351808",
    "label": "Yuwono",
    "value": "Yuwono"
  },
  {
    "id": "3518082003",
    "district_id": "351808",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3518082004",
    "district_id": "351808",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3518082005",
    "district_id": "351808",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3518082006",
    "district_id": "351808",
    "label": "Nglawak",
    "value": "Nglawak"
  },
  {
    "id": "3518082007",
    "district_id": "351808",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3518082008",
    "district_id": "351808",
    "label": "Tembarak",
    "value": "Tembarak"
  },
  {
    "id": "3518082009",
    "district_id": "351808",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3518082010",
    "district_id": "351808",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "3518082012",
    "district_id": "351808",
    "label": "Lambangkuning",
    "value": "Lambangkuning"
  },
  {
    "id": "3518082013",
    "district_id": "351808",
    "label": "Pandantoyo",
    "value": "Pandantoyo"
  },
  {
    "id": "3518082014",
    "district_id": "351808",
    "label": "Kudu",
    "value": "Kudu"
  },
  {
    "id": "3518092001",
    "district_id": "351809",
    "label": "Bukur",
    "value": "Bukur"
  },
  {
    "id": "3518092002",
    "district_id": "351809",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3518092003",
    "district_id": "351809",
    "label": "Ngepung",
    "value": "Ngepung"
  },
  {
    "id": "3518092004",
    "district_id": "351809",
    "label": "Pisang",
    "value": "Pisang"
  },
  {
    "id": "3518092005",
    "district_id": "351809",
    "label": "Lestari",
    "value": "Lestari"
  },
  {
    "id": "3518092006",
    "district_id": "351809",
    "label": "Pecuk",
    "value": "Pecuk"
  },
  {
    "id": "3518092007",
    "district_id": "351809",
    "label": "Patianrowo",
    "value": "Patianrowo"
  },
  {
    "id": "3518092008",
    "district_id": "351809",
    "label": "Pakuncen",
    "value": "Pakuncen"
  },
  {
    "id": "3518092009",
    "district_id": "351809",
    "label": "Rowomarto",
    "value": "Rowomarto"
  },
  {
    "id": "3518092010",
    "district_id": "351809",
    "label": "Ngrombot",
    "value": "Ngrombot"
  },
  {
    "id": "3518092011",
    "district_id": "351809",
    "label": "Tirtobinangun",
    "value": "Tirtobinangun"
  },
  {
    "id": "3518102001",
    "district_id": "351810",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3518102002",
    "district_id": "351810",
    "label": "Gebangkerep",
    "value": "Gebangkerep"
  },
  {
    "id": "3518102003",
    "district_id": "351810",
    "label": "Baron",
    "value": "Baron"
  },
  {
    "id": "3518102004",
    "district_id": "351810",
    "label": "Waung",
    "value": "Waung"
  },
  {
    "id": "3518102005",
    "district_id": "351810",
    "label": "Kemlokolegi",
    "value": "Kemlokolegi"
  },
  {
    "id": "3518102006",
    "district_id": "351810",
    "label": "Kemaduh",
    "value": "Kemaduh"
  },
  {
    "id": "3518102007",
    "district_id": "351810",
    "label": "Garu",
    "value": "Garu"
  },
  {
    "id": "3518102008",
    "district_id": "351810",
    "label": "Jekek",
    "value": "Jekek"
  },
  {
    "id": "3518102009",
    "district_id": "351810",
    "label": "Katerban",
    "value": "Katerban"
  },
  {
    "id": "3518102010",
    "district_id": "351810",
    "label": "Mabung",
    "value": "Mabung"
  },
  {
    "id": "3518102011",
    "district_id": "351810",
    "label": "Jambi",
    "value": "Jambi"
  },
  {
    "id": "3518111011",
    "district_id": "351811",
    "label": "Tanjunganom",
    "value": "Tanjunganom"
  },
  {
    "id": "3518111013",
    "district_id": "351811",
    "label": "Warujayeng",
    "value": "Warujayeng"
  },
  {
    "id": "3518112001",
    "district_id": "351811",
    "label": "Kedungombo",
    "value": "Kedungombo"
  },
  {
    "id": "3518112002",
    "district_id": "351811",
    "label": "Sumberkepuh",
    "value": "Sumberkepuh"
  },
  {
    "id": "3518112003",
    "district_id": "351811",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3518112004",
    "district_id": "351811",
    "label": "Malangsari",
    "value": "Malangsari"
  },
  {
    "id": "3518112005",
    "district_id": "351811",
    "label": "Getas",
    "value": "Getas"
  },
  {
    "id": "3518112006",
    "district_id": "351811",
    "label": "Sonobekel",
    "value": "Sonobekel"
  },
  {
    "id": "3518112007",
    "district_id": "351811",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3518112008",
    "district_id": "351811",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3518112009",
    "district_id": "351811",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3518112010",
    "district_id": "351811",
    "label": "Kampungbaru",
    "value": "Kampungbaru"
  },
  {
    "id": "3518112012",
    "district_id": "351811",
    "label": "Jogomerto",
    "value": "Jogomerto"
  },
  {
    "id": "3518112014",
    "district_id": "351811",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3518112015",
    "district_id": "351811",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3518112016",
    "district_id": "351811",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3518121002",
    "district_id": "351812",
    "label": "Kapas",
    "value": "Kapas"
  },
  {
    "id": "3518121007",
    "district_id": "351812",
    "label": "Sokomoro",
    "value": "Sokomoro"
  },
  {
    "id": "3518122001",
    "district_id": "351812",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3518122003",
    "district_id": "351812",
    "label": "Blitaran",
    "value": "Blitaran"
  },
  {
    "id": "3518122004",
    "district_id": "351812",
    "label": "Kedungsoko",
    "value": "Kedungsoko"
  },
  {
    "id": "3518122005",
    "district_id": "351812",
    "label": "Ngludo",
    "value": "Ngludo"
  },
  {
    "id": "3518122006",
    "district_id": "351812",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "3518122008",
    "district_id": "351812",
    "label": "Pehserut",
    "value": "Pehserut"
  },
  {
    "id": "3518122009",
    "district_id": "351812",
    "label": "Ngrami",
    "value": "Ngrami"
  },
  {
    "id": "3518122010",
    "district_id": "351812",
    "label": "Bagor Wetan",
    "value": "Bagor Wetan"
  },
  {
    "id": "3518122011",
    "district_id": "351812",
    "label": "Putren",
    "value": "Putren"
  },
  {
    "id": "3518122012",
    "district_id": "351812",
    "label": "Ngrengket",
    "value": "Ngrengket"
  },
  {
    "id": "3518131001",
    "district_id": "351813",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3518131002",
    "district_id": "351813",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3518131003",
    "district_id": "351813",
    "label": "Payaman",
    "value": "Payaman"
  },
  {
    "id": "3518131004",
    "district_id": "351813",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3518131005",
    "district_id": "351813",
    "label": "Ganung Kidul",
    "value": "Ganung Kidul"
  },
  {
    "id": "3518131006",
    "district_id": "351813",
    "label": "Werungotok",
    "value": "Werungotok"
  },
  {
    "id": "3518131007",
    "district_id": "351813",
    "label": "Mangundikaran",
    "value": "Mangundikaran"
  },
  {
    "id": "3518131008",
    "district_id": "351813",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3518131009",
    "district_id": "351813",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "3518131010",
    "district_id": "351813",
    "label": "Cangkriman",
    "value": "Cangkriman"
  },
  {
    "id": "3518131011",
    "district_id": "351813",
    "label": "Bogo",
    "value": "Bogo"
  },
  {
    "id": "3518131012",
    "district_id": "351813",
    "label": "Ringinanom",
    "value": "Ringinanom"
  },
  {
    "id": "3518131013",
    "district_id": "351813",
    "label": "Begadung",
    "value": "Begadung"
  },
  {
    "id": "3518132014",
    "district_id": "351813",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3518132015",
    "district_id": "351813",
    "label": "Balongpacul",
    "value": "Balongpacul"
  },
  {
    "id": "3518141014",
    "district_id": "351814",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3518141015",
    "district_id": "351814",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "3518142001",
    "district_id": "351814",
    "label": "Balongrejo",
    "value": "Balongrejo"
  },
  {
    "id": "3518142002",
    "district_id": "351814",
    "label": "Girirejo",
    "value": "Girirejo"
  },
  {
    "id": "3518142003",
    "district_id": "351814",
    "label": "Pesudukuh",
    "value": "Pesudukuh"
  },
  {
    "id": "3518142004",
    "district_id": "351814",
    "label": "Sekarputih",
    "value": "Sekarputih"
  },
  {
    "id": "3518142005",
    "district_id": "351814",
    "label": "Buduran",
    "value": "Buduran"
  },
  {
    "id": "3518142006",
    "district_id": "351814",
    "label": "Ngumpul",
    "value": "Ngumpul"
  },
  {
    "id": "3518142007",
    "district_id": "351814",
    "label": "Bagor Kulon",
    "value": "Bagor Kulon"
  },
  {
    "id": "3518142008",
    "district_id": "351814",
    "label": "Petak",
    "value": "Petak"
  },
  {
    "id": "3518142009",
    "district_id": "351814",
    "label": "Paron",
    "value": "Paron"
  },
  {
    "id": "3518142010",
    "district_id": "351814",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3518142011",
    "district_id": "351814",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3518142012",
    "district_id": "351814",
    "label": "Gandu",
    "value": "Gandu"
  },
  {
    "id": "3518142013",
    "district_id": "351814",
    "label": "Kerepkidul",
    "value": "Kerepkidul"
  },
  {
    "id": "3518142016",
    "district_id": "351814",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "3518142017",
    "district_id": "351814",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3518142018",
    "district_id": "351814",
    "label": "Gemenggeng",
    "value": "Gemenggeng"
  },
  {
    "id": "3518142019",
    "district_id": "351814",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3518142020",
    "district_id": "351814",
    "label": "Banaran Kulon",
    "value": "Banaran Kulon"
  },
  {
    "id": "3518142021",
    "district_id": "351814",
    "label": "Banaran Wetan",
    "value": "Banaran Wetan"
  },
  {
    "id": "3518152001",
    "district_id": "351815",
    "label": "Sudimoroharjo",
    "value": "Sudimoroharjo"
  },
  {
    "id": "3518152002",
    "district_id": "351815",
    "label": "Wilangan",
    "value": "Wilangan"
  },
  {
    "id": "3518152003",
    "district_id": "351815",
    "label": "Ngadipiro",
    "value": "Ngadipiro"
  },
  {
    "id": "3518152004",
    "district_id": "351815",
    "label": "Mancon",
    "value": "Mancon"
  },
  {
    "id": "3518152005",
    "district_id": "351815",
    "label": "Ngudikan",
    "value": "Ngudikan"
  },
  {
    "id": "3518152006",
    "district_id": "351815",
    "label": "Sokoharjo",
    "value": "Sokoharjo"
  },
  {
    "id": "3518162001",
    "district_id": "351816",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3518162002",
    "district_id": "351816",
    "label": "Mungkung",
    "value": "Mungkung"
  },
  {
    "id": "3518162003",
    "district_id": "351816",
    "label": "Setren",
    "value": "Setren"
  },
  {
    "id": "3518162004",
    "district_id": "351816",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3518162005",
    "district_id": "351816",
    "label": "Sidokare",
    "value": "Sidokare"
  },
  {
    "id": "3518162006",
    "district_id": "351816",
    "label": "Mojorembun",
    "value": "Mojorembun"
  },
  {
    "id": "3518162007",
    "district_id": "351816",
    "label": "Ngadiboyo",
    "value": "Ngadiboyo"
  },
  {
    "id": "3518162008",
    "district_id": "351816",
    "label": "Rejoso",
    "value": "Rejoso"
  },
  {
    "id": "3518162009",
    "district_id": "351816",
    "label": "Klagen",
    "value": "Klagen"
  },
  {
    "id": "3518162010",
    "district_id": "351816",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3518162011",
    "district_id": "351816",
    "label": "Mlorah",
    "value": "Mlorah"
  },
  {
    "id": "3518162012",
    "district_id": "351816",
    "label": "Puhkerep",
    "value": "Puhkerep"
  },
  {
    "id": "3518162013",
    "district_id": "351816",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3518162014",
    "district_id": "351816",
    "label": "Ngangkatan",
    "value": "Ngangkatan"
  },
  {
    "id": "3518162015",
    "district_id": "351816",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "3518162016",
    "district_id": "351816",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3518162017",
    "district_id": "351816",
    "label": "Sambikerep",
    "value": "Sambikerep"
  },
  {
    "id": "3518162018",
    "district_id": "351816",
    "label": "Musir Lor",
    "value": "Musir Lor"
  },
  {
    "id": "3518162019",
    "district_id": "351816",
    "label": "Musir Kidul",
    "value": "Musir Kidul"
  },
  {
    "id": "3518162020",
    "district_id": "351816",
    "label": "Wengkal",
    "value": "Wengkal"
  },
  {
    "id": "3518162021",
    "district_id": "351816",
    "label": "Jintel",
    "value": "Jintel"
  },
  {
    "id": "3518162022",
    "district_id": "351816",
    "label": "Kedungpadang",
    "value": "Kedungpadang"
  },
  {
    "id": "3518162023",
    "district_id": "351816",
    "label": "Tritik",
    "value": "Tritik"
  },
  {
    "id": "3518162024",
    "district_id": "351816",
    "label": "Bendoasri",
    "value": "Bendoasri"
  },
  {
    "id": "3518172001",
    "district_id": "351817",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3518172002",
    "district_id": "351817",
    "label": "Nglinggo",
    "value": "Nglinggo"
  },
  {
    "id": "3518172003",
    "district_id": "351817",
    "label": "Mojoseto",
    "value": "Mojoseto"
  },
  {
    "id": "3518172004",
    "district_id": "351817",
    "label": "Karangsemi",
    "value": "Karangsemi"
  },
  {
    "id": "3518172005",
    "district_id": "351817",
    "label": "Senjayan",
    "value": "Senjayan"
  },
  {
    "id": "3518172006",
    "district_id": "351817",
    "label": "Kedungglugu",
    "value": "Kedungglugu"
  },
  {
    "id": "3518172007",
    "district_id": "351817",
    "label": "Ja'an",
    "value": "Ja'an"
  },
  {
    "id": "3518172008",
    "district_id": "351817",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3518172009",
    "district_id": "351817",
    "label": "Ketawang",
    "value": "Ketawang"
  },
  {
    "id": "3518172010",
    "district_id": "351817",
    "label": "Ngunjung",
    "value": "Ngunjung"
  },
  {
    "id": "3518172011",
    "district_id": "351817",
    "label": "Sanggrahan",
    "value": "Sanggrahan"
  },
  {
    "id": "3518172012",
    "district_id": "351817",
    "label": "Balonggebang",
    "value": "Balonggebang"
  },
  {
    "id": "3518172013",
    "district_id": "351817",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3518172014",
    "district_id": "351817",
    "label": "Campur",
    "value": "Campur"
  },
  {
    "id": "3518172015",
    "district_id": "351817",
    "label": "Gondangkulon",
    "value": "Gondangkulon"
  },
  {
    "id": "3518172016",
    "district_id": "351817",
    "label": "Senggowar",
    "value": "Senggowar"
  },
  {
    "id": "3518172017",
    "district_id": "351817",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "3518182001",
    "district_id": "351818",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3518182002",
    "district_id": "351818",
    "label": "Ngluyu",
    "value": "Ngluyu"
  },
  {
    "id": "3518182003",
    "district_id": "351818",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3518182004",
    "district_id": "351818",
    "label": "Lengkong Lor",
    "value": "Lengkong Lor"
  },
  {
    "id": "3518182005",
    "district_id": "351818",
    "label": "Gampeng",
    "value": "Gampeng"
  },
  {
    "id": "3518182006",
    "district_id": "351818",
    "label": "Bajang",
    "value": "Bajang"
  },
  {
    "id": "3518192001",
    "district_id": "351819",
    "label": "Banjardowo",
    "value": "Banjardowo"
  },
  {
    "id": "3518192002",
    "district_id": "351819",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3518192003",
    "district_id": "351819",
    "label": "Jegreg",
    "value": "Jegreg"
  },
  {
    "id": "3518192004",
    "district_id": "351819",
    "label": "Kedungmlaten",
    "value": "Kedungmlaten"
  },
  {
    "id": "3518192005",
    "district_id": "351819",
    "label": "Prayungan",
    "value": "Prayungan"
  },
  {
    "id": "3518192006",
    "district_id": "351819",
    "label": "Sumbersono",
    "value": "Sumbersono"
  },
  {
    "id": "3518192007",
    "district_id": "351819",
    "label": "Balongasem",
    "value": "Balongasem"
  },
  {
    "id": "3518192008",
    "district_id": "351819",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3518192009",
    "district_id": "351819",
    "label": "Ngringin",
    "value": "Ngringin"
  },
  {
    "id": "3518192010",
    "district_id": "351819",
    "label": "Jatipunggur",
    "value": "Jatipunggur"
  },
  {
    "id": "3518192011",
    "district_id": "351819",
    "label": "Ketandan",
    "value": "Ketandan"
  },
  {
    "id": "3518192012",
    "district_id": "351819",
    "label": "Sumberkepuh",
    "value": "Sumberkepuh"
  },
  {
    "id": "3518192013",
    "district_id": "351819",
    "label": "Ngepung",
    "value": "Ngepung"
  },
  {
    "id": "3518192014",
    "district_id": "351819",
    "label": "Pinggir",
    "value": "Pinggir"
  },
  {
    "id": "3518192015",
    "district_id": "351819",
    "label": "Bangle",
    "value": "Bangle"
  },
  {
    "id": "3518192016",
    "district_id": "351819",
    "label": "Sumbermiri",
    "value": "Sumbermiri"
  },
  {
    "id": "3518202001",
    "district_id": "351820",
    "label": "Perning",
    "value": "Perning"
  },
  {
    "id": "3518202002",
    "district_id": "351820",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3518202003",
    "district_id": "351820",
    "label": "Gondang Wetan",
    "value": "Gondang Wetan"
  },
  {
    "id": "3518202004",
    "district_id": "351820",
    "label": "Jatikalen",
    "value": "Jatikalen"
  },
  {
    "id": "3518202005",
    "district_id": "351820",
    "label": "Begendeng",
    "value": "Begendeng"
  },
  {
    "id": "3518202006",
    "district_id": "351820",
    "label": "Lumpangkuwik",
    "value": "Lumpangkuwik"
  },
  {
    "id": "3518202007",
    "district_id": "351820",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3518202008",
    "district_id": "351820",
    "label": "Munung",
    "value": "Munung"
  },
  {
    "id": "3518202009",
    "district_id": "351820",
    "label": "Pulowetan",
    "value": "Pulowetan"
  },
  {
    "id": "3518202010",
    "district_id": "351820",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3518202011",
    "district_id": "351820",
    "label": "Dlururejo",
    "value": "Dlururejo"
  },
  {
    "id": "3519012001",
    "district_id": "351901",
    "label": "Tambakmas",
    "value": "Tambakmas"
  },
  {
    "id": "3519012002",
    "district_id": "351901",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3519012003",
    "district_id": "351901",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3519012004",
    "district_id": "351901",
    "label": "Pucanganom",
    "value": "Pucanganom"
  },
  {
    "id": "3519012005",
    "district_id": "351901",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3519012006",
    "district_id": "351901",
    "label": "Singgahan",
    "value": "Singgahan"
  },
  {
    "id": "3519012007",
    "district_id": "351901",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3519012008",
    "district_id": "351901",
    "label": "Palur",
    "value": "Palur"
  },
  {
    "id": "3519012009",
    "district_id": "351901",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3519012010",
    "district_id": "351901",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3519012011",
    "district_id": "351901",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3519012012",
    "district_id": "351901",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3519012013",
    "district_id": "351901",
    "label": "Kendondong",
    "value": "Kendondong"
  },
  {
    "id": "3519012014",
    "district_id": "351901",
    "label": "Bacem",
    "value": "Bacem"
  },
  {
    "id": "3519021002",
    "district_id": "351902",
    "label": "Mlilir",
    "value": "Mlilir"
  },
  {
    "id": "3519021012",
    "district_id": "351902",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3519022001",
    "district_id": "351902",
    "label": "Lembah",
    "value": "Lembah"
  },
  {
    "id": "3519022003",
    "district_id": "351902",
    "label": "Kradinan",
    "value": "Kradinan"
  },
  {
    "id": "3519022004",
    "district_id": "351902",
    "label": "Suluk",
    "value": "Suluk"
  },
  {
    "id": "3519022005",
    "district_id": "351902",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3519022006",
    "district_id": "351902",
    "label": "Bader",
    "value": "Bader"
  },
  {
    "id": "3519022007",
    "district_id": "351902",
    "label": "Candimulyo",
    "value": "Candimulyo"
  },
  {
    "id": "3519022008",
    "district_id": "351902",
    "label": "Glonggong",
    "value": "Glonggong"
  },
  {
    "id": "3519022009",
    "district_id": "351902",
    "label": "Dolopo",
    "value": "Dolopo"
  },
  {
    "id": "3519022010",
    "district_id": "351902",
    "label": "Doho",
    "value": "Doho"
  },
  {
    "id": "3519022011",
    "district_id": "351902",
    "label": "Ketawang",
    "value": "Ketawang"
  },
  {
    "id": "3519032001",
    "district_id": "351903",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3519032002",
    "district_id": "351903",
    "label": "Klorogan",
    "value": "Klorogan"
  },
  {
    "id": "3519032003",
    "district_id": "351903",
    "label": "Slambur",
    "value": "Slambur"
  },
  {
    "id": "3519032004",
    "district_id": "351903",
    "label": "Geger",
    "value": "Geger"
  },
  {
    "id": "3519032005",
    "district_id": "351903",
    "label": "Sareng",
    "value": "Sareng"
  },
  {
    "id": "3519032006",
    "district_id": "351903",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3519032007",
    "district_id": "351903",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3519032008",
    "district_id": "351903",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3519032009",
    "district_id": "351903",
    "label": "Uteran",
    "value": "Uteran"
  },
  {
    "id": "3519032010",
    "district_id": "351903",
    "label": "Pagotan",
    "value": "Pagotan"
  },
  {
    "id": "3519032011",
    "district_id": "351903",
    "label": "Sangen",
    "value": "Sangen"
  },
  {
    "id": "3519032012",
    "district_id": "351903",
    "label": "Jogodayuh",
    "value": "Jogodayuh"
  },
  {
    "id": "3519032013",
    "district_id": "351903",
    "label": "Nglandung",
    "value": "Nglandung"
  },
  {
    "id": "3519032014",
    "district_id": "351903",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3519032015",
    "district_id": "351903",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "3519032016",
    "district_id": "351903",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3519032017",
    "district_id": "351903",
    "label": "Kaibon",
    "value": "Kaibon"
  },
  {
    "id": "3519032018",
    "district_id": "351903",
    "label": "Kertobanyon",
    "value": "Kertobanyon"
  },
  {
    "id": "3519032019",
    "district_id": "351903",
    "label": "Kertosari",
    "value": "Kertosari"
  },
  {
    "id": "3519042001",
    "district_id": "351904",
    "label": "Ketandan",
    "value": "Ketandan"
  },
  {
    "id": "3519042002",
    "district_id": "351904",
    "label": "Tileng",
    "value": "Tileng"
  },
  {
    "id": "3519042003",
    "district_id": "351904",
    "label": "Mendak",
    "value": "Mendak"
  },
  {
    "id": "3519042004",
    "district_id": "351904",
    "label": "Segulung",
    "value": "Segulung"
  },
  {
    "id": "3519042005",
    "district_id": "351904",
    "label": "Ngranget",
    "value": "Ngranget"
  },
  {
    "id": "3519042006",
    "district_id": "351904",
    "label": "Padas",
    "value": "Padas"
  },
  {
    "id": "3519042007",
    "district_id": "351904",
    "label": "Dagangan",
    "value": "Dagangan"
  },
  {
    "id": "3519042008",
    "district_id": "351904",
    "label": "Joho",
    "value": "Joho"
  },
  {
    "id": "3519042009",
    "district_id": "351904",
    "label": "Kepet",
    "value": "Kepet"
  },
  {
    "id": "3519042010",
    "district_id": "351904",
    "label": "Sewulan",
    "value": "Sewulan"
  },
  {
    "id": "3519042011",
    "district_id": "351904",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3519042012",
    "district_id": "351904",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3519042013",
    "district_id": "351904",
    "label": "Mruwak",
    "value": "Mruwak"
  },
  {
    "id": "3519042014",
    "district_id": "351904",
    "label": "Prambon",
    "value": "Prambon"
  },
  {
    "id": "3519042015",
    "district_id": "351904",
    "label": "Banjarsari Wetan",
    "value": "Banjarsari Wetan"
  },
  {
    "id": "3519042016",
    "district_id": "351904",
    "label": "Banjarsari Kulon",
    "value": "Banjarsari Kulon"
  },
  {
    "id": "3519042017",
    "district_id": "351904",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3519052001",
    "district_id": "351905",
    "label": "Kare",
    "value": "Kare"
  },
  {
    "id": "3519052002",
    "district_id": "351905",
    "label": "Kepel",
    "value": "Kepel"
  },
  {
    "id": "3519052003",
    "district_id": "351905",
    "label": "Bodag",
    "value": "Bodag"
  },
  {
    "id": "3519052004",
    "district_id": "351905",
    "label": "Bolo",
    "value": "Bolo"
  },
  {
    "id": "3519052005",
    "district_id": "351905",
    "label": "Kuwiran",
    "value": "Kuwiran"
  },
  {
    "id": "3519052006",
    "district_id": "351905",
    "label": "Randualas",
    "value": "Randualas"
  },
  {
    "id": "3519052007",
    "district_id": "351905",
    "label": "Cermo",
    "value": "Cermo"
  },
  {
    "id": "3519052008",
    "district_id": "351905",
    "label": "Morang",
    "value": "Morang"
  },
  {
    "id": "3519062001",
    "district_id": "351906",
    "label": "Batok",
    "value": "Batok"
  },
  {
    "id": "3519062002",
    "district_id": "351906",
    "label": "Durenan",
    "value": "Durenan"
  },
  {
    "id": "3519062003",
    "district_id": "351906",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3519062004",
    "district_id": "351906",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3519062005",
    "district_id": "351906",
    "label": "Gemarang",
    "value": "Gemarang"
  },
  {
    "id": "3519062006",
    "district_id": "351906",
    "label": "Sebayi",
    "value": "Sebayi"
  },
  {
    "id": "3519062007",
    "district_id": "351906",
    "label": "Nampu",
    "value": "Nampu"
  },
  {
    "id": "3519071003",
    "district_id": "351907",
    "label": "Munggut",
    "value": "Munggut"
  },
  {
    "id": "3519071009",
    "district_id": "351907",
    "label": "Wungu",
    "value": "Wungu"
  },
  {
    "id": "3519072001",
    "district_id": "351907",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3519072002",
    "district_id": "351907",
    "label": "Mojopurno",
    "value": "Mojopurno"
  },
  {
    "id": "3519072004",
    "district_id": "351907",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3519072005",
    "district_id": "351907",
    "label": "Brumbun",
    "value": "Brumbun"
  },
  {
    "id": "3519072006",
    "district_id": "351907",
    "label": "Kresek",
    "value": "Kresek"
  },
  {
    "id": "3519072007",
    "district_id": "351907",
    "label": "Mojorayung",
    "value": "Mojorayung"
  },
  {
    "id": "3519072008",
    "district_id": "351907",
    "label": "Bantengan",
    "value": "Bantengan"
  },
  {
    "id": "3519072010",
    "district_id": "351907",
    "label": "Pilangrejo",
    "value": "Pilangrejo"
  },
  {
    "id": "3519072011",
    "district_id": "351907",
    "label": "Tempursari",
    "value": "Tempursari"
  },
  {
    "id": "3519072012",
    "district_id": "351907",
    "label": "Nglanduk",
    "value": "Nglanduk"
  },
  {
    "id": "3519072013",
    "district_id": "351907",
    "label": "Nglambangan",
    "value": "Nglambangan"
  },
  {
    "id": "3519072014",
    "district_id": "351907",
    "label": "Sobrah",
    "value": "Sobrah"
  },
  {
    "id": "3519081001",
    "district_id": "351908",
    "label": "Nglames",
    "value": "Nglames"
  },
  {
    "id": "3519082002",
    "district_id": "351908",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3519082003",
    "district_id": "351908",
    "label": "Dempelan",
    "value": "Dempelan"
  },
  {
    "id": "3519082004",
    "district_id": "351908",
    "label": "Betek",
    "value": "Betek"
  },
  {
    "id": "3519082005",
    "district_id": "351908",
    "label": "Sirapan",
    "value": "Sirapan"
  },
  {
    "id": "3519082006",
    "district_id": "351908",
    "label": "Dimong",
    "value": "Dimong"
  },
  {
    "id": "3519082007",
    "district_id": "351908",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3519082008",
    "district_id": "351908",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3519082009",
    "district_id": "351908",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3519082010",
    "district_id": "351908",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3519082011",
    "district_id": "351908",
    "label": "Tiron",
    "value": "Tiron"
  },
  {
    "id": "3519082012",
    "district_id": "351908",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3519082013",
    "district_id": "351908",
    "label": "Bagi",
    "value": "Bagi"
  },
  {
    "id": "3519092001",
    "district_id": "351909",
    "label": "Bukur",
    "value": "Bukur"
  },
  {
    "id": "3519092002",
    "district_id": "351909",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3519092003",
    "district_id": "351909",
    "label": "Metesih",
    "value": "Metesih"
  },
  {
    "id": "3519092004",
    "district_id": "351909",
    "label": "Jiwan",
    "value": "Jiwan"
  },
  {
    "id": "3519092005",
    "district_id": "351909",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3519092006",
    "district_id": "351909",
    "label": "Kincang Wetan",
    "value": "Kincang Wetan"
  },
  {
    "id": "3519092007",
    "district_id": "351909",
    "label": "Kwangsen",
    "value": "Kwangsen"
  },
  {
    "id": "3519092008",
    "district_id": "351909",
    "label": "Grobogan",
    "value": "Grobogan"
  },
  {
    "id": "3519092009",
    "district_id": "351909",
    "label": "Wayut",
    "value": "Wayut"
  },
  {
    "id": "3519092010",
    "district_id": "351909",
    "label": "Klagenserut",
    "value": "Klagenserut"
  },
  {
    "id": "3519092011",
    "district_id": "351909",
    "label": "Teguhan",
    "value": "Teguhan"
  },
  {
    "id": "3519092012",
    "district_id": "351909",
    "label": "Ngetrep",
    "value": "Ngetrep"
  },
  {
    "id": "3519092013",
    "district_id": "351909",
    "label": "Bedoho",
    "value": "Bedoho"
  },
  {
    "id": "3519092014",
    "district_id": "351909",
    "label": "Bibrik",
    "value": "Bibrik"
  },
  {
    "id": "3519102001",
    "district_id": "351910",
    "label": "Garon",
    "value": "Garon"
  },
  {
    "id": "3519102002",
    "district_id": "351910",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3519102003",
    "district_id": "351910",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3519102004",
    "district_id": "351910",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3519102005",
    "district_id": "351910",
    "label": "Sumberbening",
    "value": "Sumberbening"
  },
  {
    "id": "3519102006",
    "district_id": "351910",
    "label": "Bulakrejo",
    "value": "Bulakrejo"
  },
  {
    "id": "3519102007",
    "district_id": "351910",
    "label": "Tapelan",
    "value": "Tapelan"
  },
  {
    "id": "3519102008",
    "district_id": "351910",
    "label": "Babadan Lor",
    "value": "Babadan Lor"
  },
  {
    "id": "3519102009",
    "district_id": "351910",
    "label": "Warurejo",
    "value": "Warurejo"
  },
  {
    "id": "3519102010",
    "district_id": "351910",
    "label": "Jerukgulung",
    "value": "Jerukgulung"
  },
  {
    "id": "3519102011",
    "district_id": "351910",
    "label": "Kedungjati",
    "value": "Kedungjati"
  },
  {
    "id": "3519102012",
    "district_id": "351910",
    "label": "Glonggong",
    "value": "Glonggong"
  },
  {
    "id": "3519102013",
    "district_id": "351910",
    "label": "Sogo",
    "value": "Sogo"
  },
  {
    "id": "3519102014",
    "district_id": "351910",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3519102015",
    "district_id": "351910",
    "label": "Pacinan",
    "value": "Pacinan"
  },
  {
    "id": "3519102016",
    "district_id": "351910",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3519102017",
    "district_id": "351910",
    "label": "Kuwu",
    "value": "Kuwu"
  },
  {
    "id": "3519102018",
    "district_id": "351910",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3519111012",
    "district_id": "351911",
    "label": "Bangunsari",
    "value": "Bangunsari"
  },
  {
    "id": "3519111013",
    "district_id": "351911",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3519111014",
    "district_id": "351911",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3519112001",
    "district_id": "351911",
    "label": "Kaliabu",
    "value": "Kaliabu"
  },
  {
    "id": "3519112002",
    "district_id": "351911",
    "label": "Klecorejo",
    "value": "Klecorejo"
  },
  {
    "id": "3519112003",
    "district_id": "351911",
    "label": "Blabakan",
    "value": "Blabakan"
  },
  {
    "id": "3519112004",
    "district_id": "351911",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3519112005",
    "district_id": "351911",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3519112006",
    "district_id": "351911",
    "label": "Darmorejo",
    "value": "Darmorejo"
  },
  {
    "id": "3519112007",
    "district_id": "351911",
    "label": "Kaligunting",
    "value": "Kaligunting"
  },
  {
    "id": "3519112008",
    "district_id": "351911",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3519112009",
    "district_id": "351911",
    "label": "Kuncen",
    "value": "Kuncen"
  },
  {
    "id": "3519112010",
    "district_id": "351911",
    "label": "Mejayan",
    "value": "Mejayan"
  },
  {
    "id": "3519112011",
    "district_id": "351911",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3519122001",
    "district_id": "351912",
    "label": "Bandungan",
    "value": "Bandungan"
  },
  {
    "id": "3519122002",
    "district_id": "351912",
    "label": "Pajaran",
    "value": "Pajaran"
  },
  {
    "id": "3519122003",
    "district_id": "351912",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3519122004",
    "district_id": "351912",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3519122005",
    "district_id": "351912",
    "label": "Bongsopotro",
    "value": "Bongsopotro"
  },
  {
    "id": "3519122006",
    "district_id": "351912",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3519122007",
    "district_id": "351912",
    "label": "Ngepeh",
    "value": "Ngepeh"
  },
  {
    "id": "3519122008",
    "district_id": "351912",
    "label": "Bajulan",
    "value": "Bajulan"
  },
  {
    "id": "3519122009",
    "district_id": "351912",
    "label": "Bener",
    "value": "Bener"
  },
  {
    "id": "3519122010",
    "district_id": "351912",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3519122011",
    "district_id": "351912",
    "label": "Klumutan",
    "value": "Klumutan"
  },
  {
    "id": "3519122012",
    "district_id": "351912",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3519122013",
    "district_id": "351912",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "3519122014",
    "district_id": "351912",
    "label": "Sumberbendo",
    "value": "Sumberbendo"
  },
  {
    "id": "3519122015",
    "district_id": "351912",
    "label": "Klangon",
    "value": "Klangon"
  },
  {
    "id": "3519132001",
    "district_id": "351913",
    "label": "Muneng",
    "value": "Muneng"
  },
  {
    "id": "3519132002",
    "district_id": "351913",
    "label": "Pulerejo",
    "value": "Pulerejo"
  },
  {
    "id": "3519132003",
    "district_id": "351913",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3519132004",
    "district_id": "351913",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3519132005",
    "district_id": "351913",
    "label": "Wonoayu",
    "value": "Wonoayu"
  },
  {
    "id": "3519132006",
    "district_id": "351913",
    "label": "Pilangkenceng",
    "value": "Pilangkenceng"
  },
  {
    "id": "3519132007",
    "district_id": "351913",
    "label": "Sumbergandu",
    "value": "Sumbergandu"
  },
  {
    "id": "3519132008",
    "district_id": "351913",
    "label": "Kedungmaron",
    "value": "Kedungmaron"
  },
  {
    "id": "3519132009",
    "district_id": "351913",
    "label": "Duren",
    "value": "Duren"
  },
  {
    "id": "3519132010",
    "district_id": "351913",
    "label": "Dawuhan",
    "value": "Dawuhan"
  },
  {
    "id": "3519132011",
    "district_id": "351913",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3519132012",
    "district_id": "351913",
    "label": "Kenongorejo",
    "value": "Kenongorejo"
  },
  {
    "id": "3519132013",
    "district_id": "351913",
    "label": "Ngengor",
    "value": "Ngengor"
  },
  {
    "id": "3519132014",
    "district_id": "351913",
    "label": "Gandul",
    "value": "Gandul"
  },
  {
    "id": "3519132015",
    "district_id": "351913",
    "label": "Luworo",
    "value": "Luworo"
  },
  {
    "id": "3519132016",
    "district_id": "351913",
    "label": "Kedungbanteng",
    "value": "Kedungbanteng"
  },
  {
    "id": "3519132017",
    "district_id": "351913",
    "label": "Krebet",
    "value": "Krebet"
  },
  {
    "id": "3519132018",
    "district_id": "351913",
    "label": "Ngale",
    "value": "Ngale"
  },
  {
    "id": "3519142001",
    "district_id": "351914",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3519142002",
    "district_id": "351914",
    "label": "Kanung",
    "value": "Kanung"
  },
  {
    "id": "3519142003",
    "district_id": "351914",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3519142004",
    "district_id": "351914",
    "label": "Pucangrejo",
    "value": "Pucangrejo"
  },
  {
    "id": "3519142005",
    "district_id": "351914",
    "label": "Krokeh",
    "value": "Krokeh"
  },
  {
    "id": "3519142006",
    "district_id": "351914",
    "label": "Lebakayu",
    "value": "Lebakayu"
  },
  {
    "id": "3519142007",
    "district_id": "351914",
    "label": "Bakur",
    "value": "Bakur"
  },
  {
    "id": "3519142008",
    "district_id": "351914",
    "label": "Golan",
    "value": "Golan"
  },
  {
    "id": "3519142009",
    "district_id": "351914",
    "label": "Cabean",
    "value": "Cabean"
  },
  {
    "id": "3519142010",
    "district_id": "351914",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3519142011",
    "district_id": "351914",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3519142012",
    "district_id": "351914",
    "label": "Kajang",
    "value": "Kajang"
  },
  {
    "id": "3519142013",
    "district_id": "351914",
    "label": "Klumpit",
    "value": "Klumpit"
  },
  {
    "id": "3519152001",
    "district_id": "351915",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3519152002",
    "district_id": "351915",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3519152003",
    "district_id": "351915",
    "label": "Banyukambang",
    "value": "Banyukambang"
  },
  {
    "id": "3519152004",
    "district_id": "351915",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3519152005",
    "district_id": "351915",
    "label": "Wonoasri",
    "value": "Wonoasri"
  },
  {
    "id": "3519152006",
    "district_id": "351915",
    "label": "Plumpungrejo",
    "value": "Plumpungrejo"
  },
  {
    "id": "3519152007",
    "district_id": "351915",
    "label": "Bancong",
    "value": "Bancong"
  },
  {
    "id": "3519152008",
    "district_id": "351915",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3519152009",
    "district_id": "351915",
    "label": "Klitik",
    "value": "Klitik"
  },
  {
    "id": "3519152010",
    "district_id": "351915",
    "label": "Buduran",
    "value": "Buduran"
  },
  {
    "id": "3520011005",
    "district_id": "352001",
    "label": "Alastuwo",
    "value": "Alastuwo"
  },
  {
    "id": "3520012001",
    "district_id": "352001",
    "label": "Poncol",
    "value": "Poncol"
  },
  {
    "id": "3520012002",
    "district_id": "352001",
    "label": "Gonggang",
    "value": "Gonggang"
  },
  {
    "id": "3520012003",
    "district_id": "352001",
    "label": "Janggan",
    "value": "Janggan"
  },
  {
    "id": "3520012004",
    "district_id": "352001",
    "label": "Genilangit",
    "value": "Genilangit"
  },
  {
    "id": "3520012006",
    "district_id": "352001",
    "label": "Plangkrongan",
    "value": "Plangkrongan"
  },
  {
    "id": "3520012007",
    "district_id": "352001",
    "label": "Cileng",
    "value": "Cileng"
  },
  {
    "id": "3520012008",
    "district_id": "352001",
    "label": "Sombo",
    "value": "Sombo"
  },
  {
    "id": "3520021008",
    "district_id": "352002",
    "label": "Parang",
    "value": "Parang"
  },
  {
    "id": "3520022001",
    "district_id": "352002",
    "label": "Sayutan",
    "value": "Sayutan"
  },
  {
    "id": "3520022002",
    "district_id": "352002",
    "label": "Nglopang",
    "value": "Nglopang"
  },
  {
    "id": "3520022003",
    "district_id": "352002",
    "label": "Mategal",
    "value": "Mategal"
  },
  {
    "id": "3520022004",
    "district_id": "352002",
    "label": "Bungkuk",
    "value": "Bungkuk"
  },
  {
    "id": "3520022005",
    "district_id": "352002",
    "label": "Trosono",
    "value": "Trosono"
  },
  {
    "id": "3520022006",
    "district_id": "352002",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3520022007",
    "district_id": "352002",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3520022009",
    "district_id": "352002",
    "label": "Tamanarum",
    "value": "Tamanarum"
  },
  {
    "id": "3520022010",
    "district_id": "352002",
    "label": "Pragak",
    "value": "Pragak"
  },
  {
    "id": "3520022011",
    "district_id": "352002",
    "label": "Sundul",
    "value": "Sundul"
  },
  {
    "id": "3520022012",
    "district_id": "352002",
    "label": "Jokerto",
    "value": "Jokerto"
  },
  {
    "id": "3520022013",
    "district_id": "352002",
    "label": "Krajan",
    "value": "Krajan"
  },
  {
    "id": "3520031002",
    "district_id": "352003",
    "label": "Lembeyan Kulon",
    "value": "Lembeyan Kulon"
  },
  {
    "id": "3520032001",
    "district_id": "352003",
    "label": "Kediren",
    "value": "Kediren"
  },
  {
    "id": "3520032003",
    "district_id": "352003",
    "label": "Lembeyan Wetan",
    "value": "Lembeyan Wetan"
  },
  {
    "id": "3520032004",
    "district_id": "352003",
    "label": "Tunggur",
    "value": "Tunggur"
  },
  {
    "id": "3520032005",
    "district_id": "352003",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3520032006",
    "district_id": "352003",
    "label": "Kedungpanji",
    "value": "Kedungpanji"
  },
  {
    "id": "3520032007",
    "district_id": "352003",
    "label": "Nguri",
    "value": "Nguri"
  },
  {
    "id": "3520032008",
    "district_id": "352003",
    "label": "Pupus",
    "value": "Pupus"
  },
  {
    "id": "3520032009",
    "district_id": "352003",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "3520032010",
    "district_id": "352003",
    "label": "Krowe",
    "value": "Krowe"
  },
  {
    "id": "3520041013",
    "district_id": "352004",
    "label": "Takeran",
    "value": "Takeran"
  },
  {
    "id": "3520042009",
    "district_id": "352004",
    "label": "Kiringan",
    "value": "Kiringan"
  },
  {
    "id": "3520042010",
    "district_id": "352004",
    "label": "Duyung",
    "value": "Duyung"
  },
  {
    "id": "3520042011",
    "district_id": "352004",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3520042012",
    "district_id": "352004",
    "label": "Sawojajar",
    "value": "Sawojajar"
  },
  {
    "id": "3520042014",
    "district_id": "352004",
    "label": "Kuwonharjo",
    "value": "Kuwonharjo"
  },
  {
    "id": "3520042015",
    "district_id": "352004",
    "label": "Kepuhrejo",
    "value": "Kepuhrejo"
  },
  {
    "id": "3520042016",
    "district_id": "352004",
    "label": "Kerik",
    "value": "Kerik"
  },
  {
    "id": "3520042017",
    "district_id": "352004",
    "label": "Waduk",
    "value": "Waduk"
  },
  {
    "id": "3520042018",
    "district_id": "352004",
    "label": "Jomblang",
    "value": "Jomblang"
  },
  {
    "id": "3520042019",
    "district_id": "352004",
    "label": "Kerang",
    "value": "Kerang"
  },
  {
    "id": "3520042020",
    "district_id": "352004",
    "label": "Madigondo",
    "value": "Madigondo"
  },
  {
    "id": "3520051010",
    "district_id": "352005",
    "label": "Kawedanan",
    "value": "Kawedanan"
  },
  {
    "id": "3520051011",
    "district_id": "352005",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3520051012",
    "district_id": "352005",
    "label": "Sampung",
    "value": "Sampung"
  },
  {
    "id": "3520052001",
    "district_id": "352005",
    "label": "Mangunrejo",
    "value": "Mangunrejo"
  },
  {
    "id": "3520052002",
    "district_id": "352005",
    "label": "Giripurno",
    "value": "Giripurno"
  },
  {
    "id": "3520052003",
    "district_id": "352005",
    "label": "Ngentep",
    "value": "Ngentep"
  },
  {
    "id": "3520052004",
    "district_id": "352005",
    "label": "Balerejo",
    "value": "Balerejo"
  },
  {
    "id": "3520052005",
    "district_id": "352005",
    "label": "Garon",
    "value": "Garon"
  },
  {
    "id": "3520052006",
    "district_id": "352005",
    "label": "Tladan",
    "value": "Tladan"
  },
  {
    "id": "3520052007",
    "district_id": "352005",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3520052008",
    "district_id": "352005",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3520052009",
    "district_id": "352005",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "3520052013",
    "district_id": "352005",
    "label": "Bogem",
    "value": "Bogem"
  },
  {
    "id": "3520052014",
    "district_id": "352005",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3520052015",
    "district_id": "352005",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3520052016",
    "district_id": "352005",
    "label": "Sugihrejo",
    "value": "Sugihrejo"
  },
  {
    "id": "3520052017",
    "district_id": "352005",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3520052018",
    "district_id": "352005",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3520052019",
    "district_id": "352005",
    "label": "Genengan",
    "value": "Genengan"
  },
  {
    "id": "3520052020",
    "district_id": "352005",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3520061002",
    "district_id": "352006",
    "label": "Mangkujayan",
    "value": "Mangkujayan"
  },
  {
    "id": "3520061003",
    "district_id": "352006",
    "label": "Bulukerto",
    "value": "Bulukerto"
  },
  {
    "id": "3520061004",
    "district_id": "352006",
    "label": "Magetan",
    "value": "Magetan"
  },
  {
    "id": "3520061010",
    "district_id": "352006",
    "label": "Selosari",
    "value": "Selosari"
  },
  {
    "id": "3520061011",
    "district_id": "352006",
    "label": "Tawanganom",
    "value": "Tawanganom"
  },
  {
    "id": "3520061012",
    "district_id": "352006",
    "label": "Kepolorejo",
    "value": "Kepolorejo"
  },
  {
    "id": "3520061013",
    "district_id": "352006",
    "label": "Sukowinangun",
    "value": "Sukowinangun"
  },
  {
    "id": "3520061014",
    "district_id": "352006",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3520061015",
    "district_id": "352006",
    "label": "Tambran",
    "value": "Tambran"
  },
  {
    "id": "3520062001",
    "district_id": "352006",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3520062005",
    "district_id": "352006",
    "label": "Ringinagung",
    "value": "Ringinagung"
  },
  {
    "id": "3520062009",
    "district_id": "352006",
    "label": "Candirejo",
    "value": "Candirejo"
  },
  {
    "id": "3520062016",
    "district_id": "352006",
    "label": "Baron",
    "value": "Baron"
  },
  {
    "id": "3520062017",
    "district_id": "352006",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3520071011",
    "district_id": "352007",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3520071013",
    "district_id": "352007",
    "label": "Sarangan",
    "value": "Sarangan"
  },
  {
    "id": "3520072001",
    "district_id": "352007",
    "label": "Ngancar",
    "value": "Ngancar"
  },
  {
    "id": "3520072002",
    "district_id": "352007",
    "label": "Plumpung",
    "value": "Plumpung"
  },
  {
    "id": "3520072003",
    "district_id": "352007",
    "label": "Puntukdoro",
    "value": "Puntukdoro"
  },
  {
    "id": "3520072004",
    "district_id": "352007",
    "label": "Bulugunung",
    "value": "Bulugunung"
  },
  {
    "id": "3520072005",
    "district_id": "352007",
    "label": "Bogoarum",
    "value": "Bogoarum"
  },
  {
    "id": "3520072006",
    "district_id": "352007",
    "label": "Randugede",
    "value": "Randugede"
  },
  {
    "id": "3520072007",
    "district_id": "352007",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3520072008",
    "district_id": "352007",
    "label": "Nitikan",
    "value": "Nitikan"
  },
  {
    "id": "3520072009",
    "district_id": "352007",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3520072010",
    "district_id": "352007",
    "label": "Buluharjo",
    "value": "Buluharjo"
  },
  {
    "id": "3520072012",
    "district_id": "352007",
    "label": "Dadi",
    "value": "Dadi"
  },
  {
    "id": "3520072014",
    "district_id": "352007",
    "label": "Pacalan",
    "value": "Pacalan"
  },
  {
    "id": "3520072015",
    "district_id": "352007",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3520081009",
    "district_id": "352008",
    "label": "Panekan",
    "value": "Panekan"
  },
  {
    "id": "3520082004",
    "district_id": "352008",
    "label": "Terung",
    "value": "Terung"
  },
  {
    "id": "3520082005",
    "district_id": "352008",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3520082006",
    "district_id": "352008",
    "label": "Milangasri",
    "value": "Milangasri"
  },
  {
    "id": "3520082007",
    "district_id": "352008",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3520082008",
    "district_id": "352008",
    "label": "Sidowayah",
    "value": "Sidowayah"
  },
  {
    "id": "3520082010",
    "district_id": "352008",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3520082011",
    "district_id": "352008",
    "label": "Sumberdodol",
    "value": "Sumberdodol"
  },
  {
    "id": "3520082012",
    "district_id": "352008",
    "label": "Manjung",
    "value": "Manjung"
  },
  {
    "id": "3520082013",
    "district_id": "352008",
    "label": "Tapak",
    "value": "Tapak"
  },
  {
    "id": "3520082014",
    "district_id": "352008",
    "label": "Sukowidi",
    "value": "Sukowidi"
  },
  {
    "id": "3520082015",
    "district_id": "352008",
    "label": "Bedagung",
    "value": "Bedagung"
  },
  {
    "id": "3520082016",
    "district_id": "352008",
    "label": "Ngiliran",
    "value": "Ngiliran"
  },
  {
    "id": "3520082017",
    "district_id": "352008",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3520082018",
    "district_id": "352008",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3520082019",
    "district_id": "352008",
    "label": "Turi",
    "value": "Turi"
  },
  {
    "id": "3520082020",
    "district_id": "352008",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3520091009",
    "district_id": "352009",
    "label": "Tinap",
    "value": "Tinap"
  },
  {
    "id": "3520092001",
    "district_id": "352009",
    "label": "Kalangketi",
    "value": "Kalangketi"
  },
  {
    "id": "3520092002",
    "district_id": "352009",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "3520092003",
    "district_id": "352009",
    "label": "Tambakmas",
    "value": "Tambakmas"
  },
  {
    "id": "3520092004",
    "district_id": "352009",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "3520092005",
    "district_id": "352009",
    "label": "Bibis",
    "value": "Bibis"
  },
  {
    "id": "3520092006",
    "district_id": "352009",
    "label": "Sukomoro",
    "value": "Sukomoro"
  },
  {
    "id": "3520092007",
    "district_id": "352009",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3520092008",
    "district_id": "352009",
    "label": "Pojoksari",
    "value": "Pojoksari"
  },
  {
    "id": "3520092010",
    "district_id": "352009",
    "label": "Kedungguwo",
    "value": "Kedungguwo"
  },
  {
    "id": "3520092011",
    "district_id": "352009",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3520092012",
    "district_id": "352009",
    "label": "Kentangan",
    "value": "Kentangan"
  },
  {
    "id": "3520092013",
    "district_id": "352009",
    "label": "Bogem",
    "value": "Bogem"
  },
  {
    "id": "3520092014",
    "district_id": "352009",
    "label": "Truneng",
    "value": "Truneng"
  },
  {
    "id": "3520101012",
    "district_id": "352010",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3520102001",
    "district_id": "352010",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3520102002",
    "district_id": "352010",
    "label": "Belotan",
    "value": "Belotan"
  },
  {
    "id": "3520102003",
    "district_id": "352010",
    "label": "Pingkuk",
    "value": "Pingkuk"
  },
  {
    "id": "3520102004",
    "district_id": "352010",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3520102005",
    "district_id": "352010",
    "label": "Tegalarum",
    "value": "Tegalarum"
  },
  {
    "id": "3520102006",
    "district_id": "352010",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3520102007",
    "district_id": "352010",
    "label": "Kinandang",
    "value": "Kinandang"
  },
  {
    "id": "3520102008",
    "district_id": "352010",
    "label": "Soco",
    "value": "Soco"
  },
  {
    "id": "3520102009",
    "district_id": "352010",
    "label": "Lemahbang",
    "value": "Lemahbang"
  },
  {
    "id": "3520102010",
    "district_id": "352010",
    "label": "Kledokan",
    "value": "Kledokan"
  },
  {
    "id": "3520102011",
    "district_id": "352010",
    "label": "Carikan",
    "value": "Carikan"
  },
  {
    "id": "3520102013",
    "district_id": "352010",
    "label": "Bulugledeg",
    "value": "Bulugledeg"
  },
  {
    "id": "3520102014",
    "district_id": "352010",
    "label": "Duwet",
    "value": "Duwet"
  },
  {
    "id": "3520102015",
    "district_id": "352010",
    "label": "Setren",
    "value": "Setren"
  },
  {
    "id": "3520102016",
    "district_id": "352010",
    "label": "Kleco",
    "value": "Kleco"
  },
  {
    "id": "3520111005",
    "district_id": "352011",
    "label": "Maospati",
    "value": "Maospati"
  },
  {
    "id": "3520111013",
    "district_id": "352011",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3520111014",
    "district_id": "352011",
    "label": "Mranggen",
    "value": "Mranggen"
  },
  {
    "id": "3520112001",
    "district_id": "352011",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3520112002",
    "district_id": "352011",
    "label": "Tanjungsepreh",
    "value": "Tanjungsepreh"
  },
  {
    "id": "3520112003",
    "district_id": "352011",
    "label": "Gulun",
    "value": "Gulun"
  },
  {
    "id": "3520112004",
    "district_id": "352011",
    "label": "Malang",
    "value": "Malang"
  },
  {
    "id": "3520112006",
    "district_id": "352011",
    "label": "Klagen Gambiran",
    "value": "Klagen Gambiran"
  },
  {
    "id": "3520112007",
    "district_id": "352011",
    "label": "Pandeyan",
    "value": "Pandeyan"
  },
  {
    "id": "3520112008",
    "district_id": "352011",
    "label": "Suratmajan",
    "value": "Suratmajan"
  },
  {
    "id": "3520112009",
    "district_id": "352011",
    "label": "Ronowijayan",
    "value": "Ronowijayan"
  },
  {
    "id": "3520112010",
    "district_id": "352011",
    "label": "Ngujung",
    "value": "Ngujung"
  },
  {
    "id": "3520112011",
    "district_id": "352011",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3520112012",
    "district_id": "352011",
    "label": "Pesu",
    "value": "Pesu"
  },
  {
    "id": "3520112015",
    "district_id": "352011",
    "label": "Sempol",
    "value": "Sempol"
  },
  {
    "id": "3520121005",
    "district_id": "352012",
    "label": "Mangge",
    "value": "Mangge"
  },
  {
    "id": "3520121006",
    "district_id": "352012",
    "label": "Tebon",
    "value": "Tebon"
  },
  {
    "id": "3520122001",
    "district_id": "352012",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3520122002",
    "district_id": "352012",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3520122003",
    "district_id": "352012",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3520122004",
    "district_id": "352012",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3520122007",
    "district_id": "352012",
    "label": "Manjung",
    "value": "Manjung"
  },
  {
    "id": "3520122008",
    "district_id": "352012",
    "label": "Ngumpul",
    "value": "Ngumpul"
  },
  {
    "id": "3520122009",
    "district_id": "352012",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "3520122010",
    "district_id": "352012",
    "label": "Blaran",
    "value": "Blaran"
  },
  {
    "id": "3520122011",
    "district_id": "352012",
    "label": "Jonggrang",
    "value": "Jonggrang"
  },
  {
    "id": "3520122012",
    "district_id": "352012",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3520122013",
    "district_id": "352012",
    "label": "Bangunasri",
    "value": "Bangunasri"
  },
  {
    "id": "3520122014",
    "district_id": "352012",
    "label": "Klagen",
    "value": "Klagen"
  },
  {
    "id": "3520131001",
    "district_id": "352013",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3520131006",
    "district_id": "352013",
    "label": "Manisrejo",
    "value": "Manisrejo"
  },
  {
    "id": "3520132002",
    "district_id": "352013",
    "label": "Mantren",
    "value": "Mantren"
  },
  {
    "id": "3520132003",
    "district_id": "352013",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3520132004",
    "district_id": "352013",
    "label": "Sambirembe",
    "value": "Sambirembe"
  },
  {
    "id": "3520132005",
    "district_id": "352013",
    "label": "Patihan",
    "value": "Patihan"
  },
  {
    "id": "3520132007",
    "district_id": "352013",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3520132008",
    "district_id": "352013",
    "label": "Baluk",
    "value": "Baluk"
  },
  {
    "id": "3520132009",
    "district_id": "352013",
    "label": "Gebyog",
    "value": "Gebyog"
  },
  {
    "id": "3520132010",
    "district_id": "352013",
    "label": "Maron",
    "value": "Maron"
  },
  {
    "id": "3520132011",
    "district_id": "352013",
    "label": "Prampelan",
    "value": "Prampelan"
  },
  {
    "id": "3520132012",
    "district_id": "352013",
    "label": "Grabahan",
    "value": "Grabahan"
  },
  {
    "id": "3520132013",
    "district_id": "352013",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3520142001",
    "district_id": "352014",
    "label": "Karas",
    "value": "Karas"
  },
  {
    "id": "3520142002",
    "district_id": "352014",
    "label": "Sobontoro",
    "value": "Sobontoro"
  },
  {
    "id": "3520142003",
    "district_id": "352014",
    "label": "Sumursongo",
    "value": "Sumursongo"
  },
  {
    "id": "3520142004",
    "district_id": "352014",
    "label": "Taji",
    "value": "Taji"
  },
  {
    "id": "3520142005",
    "district_id": "352014",
    "label": "Kuwon",
    "value": "Kuwon"
  },
  {
    "id": "3520142006",
    "district_id": "352014",
    "label": "Jungke",
    "value": "Jungke"
  },
  {
    "id": "3520142007",
    "district_id": "352014",
    "label": "Temboro",
    "value": "Temboro"
  },
  {
    "id": "3520142008",
    "district_id": "352014",
    "label": "Temenggungan",
    "value": "Temenggungan"
  },
  {
    "id": "3520142009",
    "district_id": "352014",
    "label": "Geplak",
    "value": "Geplak"
  },
  {
    "id": "3520142010",
    "district_id": "352014",
    "label": "Botok",
    "value": "Botok"
  },
  {
    "id": "3520142011",
    "district_id": "352014",
    "label": "Ginuk",
    "value": "Ginuk"
  },
  {
    "id": "3520152001",
    "district_id": "352015",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "3520152002",
    "district_id": "352015",
    "label": "Mrahu",
    "value": "Mrahu"
  },
  {
    "id": "3520152003",
    "district_id": "352015",
    "label": "Klurahan",
    "value": "Klurahan"
  },
  {
    "id": "3520152004",
    "district_id": "352015",
    "label": "Pencol",
    "value": "Pencol"
  },
  {
    "id": "3520152005",
    "district_id": "352015",
    "label": "Sukowidi",
    "value": "Sukowidi"
  },
  {
    "id": "3520152006",
    "district_id": "352015",
    "label": "Ngelang",
    "value": "Ngelang"
  },
  {
    "id": "3520152007",
    "district_id": "352015",
    "label": "Jajar",
    "value": "Jajar"
  },
  {
    "id": "3520152008",
    "district_id": "352015",
    "label": "Gunungan",
    "value": "Gunungan"
  },
  {
    "id": "3520152009",
    "district_id": "352015",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3520152010",
    "district_id": "352015",
    "label": "Bayemwetan",
    "value": "Bayemwetan"
  },
  {
    "id": "3520152011",
    "district_id": "352015",
    "label": "Bayemtaman",
    "value": "Bayemtaman"
  },
  {
    "id": "3520152012",
    "district_id": "352015",
    "label": "Karangmojo",
    "value": "Karangmojo"
  },
  {
    "id": "3520162001",
    "district_id": "352016",
    "label": "Selotinatah",
    "value": "Selotinatah"
  },
  {
    "id": "3520162002",
    "district_id": "352016",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "3520162003",
    "district_id": "352016",
    "label": "Bangsri",
    "value": "Bangsri"
  },
  {
    "id": "3520162004",
    "district_id": "352016",
    "label": "Selopanggung",
    "value": "Selopanggung"
  },
  {
    "id": "3520162005",
    "district_id": "352016",
    "label": "Sumberdukun",
    "value": "Sumberdukun"
  },
  {
    "id": "3520162006",
    "district_id": "352016",
    "label": "Baleasri",
    "value": "Baleasri"
  },
  {
    "id": "3520162007",
    "district_id": "352016",
    "label": "Balegondo",
    "value": "Balegondo"
  },
  {
    "id": "3520162008",
    "district_id": "352016",
    "label": "Ngariboyo",
    "value": "Ngariboyo"
  },
  {
    "id": "3520162009",
    "district_id": "352016",
    "label": "Mojopurno",
    "value": "Mojopurno"
  },
  {
    "id": "3520162010",
    "district_id": "352016",
    "label": "Banyudono",
    "value": "Banyudono"
  },
  {
    "id": "3520162011",
    "district_id": "352016",
    "label": "Banjarpanjang",
    "value": "Banjarpanjang"
  },
  {
    "id": "3520162012",
    "district_id": "352016",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3520172001",
    "district_id": "352017",
    "label": "Sukowidi",
    "value": "Sukowidi"
  },
  {
    "id": "3520172002",
    "district_id": "352017",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3520172003",
    "district_id": "352017",
    "label": "Goranggareng",
    "value": "Goranggareng"
  },
  {
    "id": "3520172004",
    "district_id": "352017",
    "label": "Petungrejo",
    "value": "Petungrejo"
  },
  {
    "id": "3520172005",
    "district_id": "352017",
    "label": "Nguntoronadi",
    "value": "Nguntoronadi"
  },
  {
    "id": "3520172006",
    "district_id": "352017",
    "label": "Simbatan",
    "value": "Simbatan"
  },
  {
    "id": "3520172007",
    "district_id": "352017",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3520172008",
    "district_id": "352017",
    "label": "Kenongomulyo",
    "value": "Kenongomulyo"
  },
  {
    "id": "3520172009",
    "district_id": "352017",
    "label": "Driyorejo",
    "value": "Driyorejo"
  },
  {
    "id": "3520182001",
    "district_id": "352018",
    "label": "Sambirobyong",
    "value": "Sambirobyong"
  },
  {
    "id": "3520182002",
    "district_id": "352018",
    "label": "Campursari",
    "value": "Campursari"
  },
  {
    "id": "3520182003",
    "district_id": "352018",
    "label": "Kalang",
    "value": "Kalang"
  },
  {
    "id": "3520182004",
    "district_id": "352018",
    "label": "Durenan",
    "value": "Durenan"
  },
  {
    "id": "3520182005",
    "district_id": "352018",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3520182006",
    "district_id": "352018",
    "label": "Getasanyar",
    "value": "Getasanyar"
  },
  {
    "id": "3520182007",
    "district_id": "352018",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3520182008",
    "district_id": "352018",
    "label": "Sumbersawit",
    "value": "Sumbersawit"
  },
  {
    "id": "3520182009",
    "district_id": "352018",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "3520182010",
    "district_id": "352018",
    "label": "Widorokandang",
    "value": "Widorokandang"
  },
  {
    "id": "3521012001",
    "district_id": "352101",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3521012002",
    "district_id": "352101",
    "label": "Pandansari",
    "value": "Pandansari"
  },
  {
    "id": "3521012003",
    "district_id": "352101",
    "label": "Girikerto",
    "value": "Girikerto"
  },
  {
    "id": "3521012004",
    "district_id": "352101",
    "label": "Ngrendeng",
    "value": "Ngrendeng"
  },
  {
    "id": "3521012005",
    "district_id": "352101",
    "label": "Hargosari",
    "value": "Hargosari"
  },
  {
    "id": "3521012006",
    "district_id": "352101",
    "label": "Pocol",
    "value": "Pocol"
  },
  {
    "id": "3521012007",
    "district_id": "352101",
    "label": "Gendol",
    "value": "Gendol"
  },
  {
    "id": "3521012008",
    "district_id": "352101",
    "label": "Sine",
    "value": "Sine"
  },
  {
    "id": "3521012009",
    "district_id": "352101",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3521012010",
    "district_id": "352101",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3521012011",
    "district_id": "352101",
    "label": "Kuniran",
    "value": "Kuniran"
  },
  {
    "id": "3521012012",
    "district_id": "352101",
    "label": "Tulakan",
    "value": "Tulakan"
  },
  {
    "id": "3521012013",
    "district_id": "352101",
    "label": "Ketanggung",
    "value": "Ketanggung"
  },
  {
    "id": "3521012014",
    "district_id": "352101",
    "label": "Jagir",
    "value": "Jagir"
  },
  {
    "id": "3521012015",
    "district_id": "352101",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3521022001",
    "district_id": "352102",
    "label": "Hargomulyo",
    "value": "Hargomulyo"
  },
  {
    "id": "3521022002",
    "district_id": "352102",
    "label": "Giriharjo",
    "value": "Giriharjo"
  },
  {
    "id": "3521022003",
    "district_id": "352102",
    "label": "Setono",
    "value": "Setono"
  },
  {
    "id": "3521022004",
    "district_id": "352102",
    "label": "Wakah",
    "value": "Wakah"
  },
  {
    "id": "3521022005",
    "district_id": "352102",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3521022006",
    "district_id": "352102",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3521022007",
    "district_id": "352102",
    "label": "Manisharjo",
    "value": "Manisharjo"
  },
  {
    "id": "3521022008",
    "district_id": "352102",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3521022009",
    "district_id": "352102",
    "label": "Ngrambe",
    "value": "Ngrambe"
  },
  {
    "id": "3521022010",
    "district_id": "352102",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3521022011",
    "district_id": "352102",
    "label": "Krandegan",
    "value": "Krandegan"
  },
  {
    "id": "3521022012",
    "district_id": "352102",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3521022013",
    "district_id": "352102",
    "label": "Cepoko",
    "value": "Cepoko"
  },
  {
    "id": "3521022014",
    "district_id": "352102",
    "label": "Mendiro",
    "value": "Mendiro"
  },
  {
    "id": "3521032001",
    "district_id": "352103",
    "label": "Umbulrejo",
    "value": "Umbulrejo"
  },
  {
    "id": "3521032002",
    "district_id": "352103",
    "label": "Kletekan",
    "value": "Kletekan"
  },
  {
    "id": "3521032003",
    "district_id": "352103",
    "label": "Jaten",
    "value": "Jaten"
  },
  {
    "id": "3521032004",
    "district_id": "352103",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "3521032005",
    "district_id": "352103",
    "label": "Ngrayudan",
    "value": "Ngrayudan"
  },
  {
    "id": "3521032006",
    "district_id": "352103",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "3521032007",
    "district_id": "352103",
    "label": "Macanan",
    "value": "Macanan"
  },
  {
    "id": "3521032008",
    "district_id": "352103",
    "label": "Brubuh",
    "value": "Brubuh"
  },
  {
    "id": "3521032009",
    "district_id": "352103",
    "label": "Jogorogo",
    "value": "Jogorogo"
  },
  {
    "id": "3521032010",
    "district_id": "352103",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3521032011",
    "district_id": "352103",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3521032012",
    "district_id": "352103",
    "label": "Soco",
    "value": "Soco"
  },
  {
    "id": "3521042001",
    "district_id": "352104",
    "label": "Karanggupito",
    "value": "Karanggupito"
  },
  {
    "id": "3521042002",
    "district_id": "352104",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3521042003",
    "district_id": "352104",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3521042004",
    "district_id": "352104",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3521042005",
    "district_id": "352104",
    "label": "Majasem",
    "value": "Majasem"
  },
  {
    "id": "3521042006",
    "district_id": "352104",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "3521042007",
    "district_id": "352104",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3521042008",
    "district_id": "352104",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3521042009",
    "district_id": "352104",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3521042010",
    "district_id": "352104",
    "label": "Patalan",
    "value": "Patalan"
  },
  {
    "id": "3521052005",
    "district_id": "352105",
    "label": "Keraswetan",
    "value": "Keraswetan"
  },
  {
    "id": "3521052006",
    "district_id": "352105",
    "label": "Keniten",
    "value": "Keniten"
  },
  {
    "id": "3521052007",
    "district_id": "352105",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "3521052009",
    "district_id": "352105",
    "label": "Tepas",
    "value": "Tepas"
  },
  {
    "id": "3521052010",
    "district_id": "352105",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3521052011",
    "district_id": "352105",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3521052012",
    "district_id": "352105",
    "label": "Baderan",
    "value": "Baderan"
  },
  {
    "id": "3521052013",
    "district_id": "352105",
    "label": "Kasreman",
    "value": "Kasreman"
  },
  {
    "id": "3521052014",
    "district_id": "352105",
    "label": "Kersikan",
    "value": "Kersikan"
  },
  {
    "id": "3521052015",
    "district_id": "352105",
    "label": "Dempel",
    "value": "Dempel"
  },
  {
    "id": "3521052016",
    "district_id": "352105",
    "label": "Klitik",
    "value": "Klitik"
  },
  {
    "id": "3521052017",
    "district_id": "352105",
    "label": "Kersoharjo",
    "value": "Kersoharjo"
  },
  {
    "id": "3521052018",
    "district_id": "352105",
    "label": "Klampisan",
    "value": "Klampisan"
  },
  {
    "id": "3521062001",
    "district_id": "352106",
    "label": "Kendung",
    "value": "Kendung"
  },
  {
    "id": "3521062002",
    "district_id": "352106",
    "label": "Dinden",
    "value": "Dinden"
  },
  {
    "id": "3521062003",
    "district_id": "352106",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3521062004",
    "district_id": "352106",
    "label": "Jenangan",
    "value": "Jenangan"
  },
  {
    "id": "3521062005",
    "district_id": "352106",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3521062006",
    "district_id": "352106",
    "label": "Tirak",
    "value": "Tirak"
  },
  {
    "id": "3521062007",
    "district_id": "352106",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3521062008",
    "district_id": "352106",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3521062009",
    "district_id": "352106",
    "label": "Warukkalong",
    "value": "Warukkalong"
  },
  {
    "id": "3521062010",
    "district_id": "352106",
    "label": "Kwadungan",
    "value": "Kwadungan"
  },
  {
    "id": "3521062011",
    "district_id": "352106",
    "label": "Budug",
    "value": "Budug"
  },
  {
    "id": "3521062012",
    "district_id": "352106",
    "label": "Mojomanis",
    "value": "Mojomanis"
  },
  {
    "id": "3521062013",
    "district_id": "352106",
    "label": "Banget",
    "value": "Banget"
  },
  {
    "id": "3521062014",
    "district_id": "352106",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3521072001",
    "district_id": "352107",
    "label": "Campurasri",
    "value": "Campurasri"
  },
  {
    "id": "3521072002",
    "district_id": "352107",
    "label": "Danguk",
    "value": "Danguk"
  },
  {
    "id": "3521072003",
    "district_id": "352107",
    "label": "Gempol",
    "value": "Gempol"
  },
  {
    "id": "3521072004",
    "district_id": "352107",
    "label": "Ringinanom",
    "value": "Ringinanom"
  },
  {
    "id": "3521072005",
    "district_id": "352107",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3521072006",
    "district_id": "352107",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3521072007",
    "district_id": "352107",
    "label": "Dungmiri",
    "value": "Dungmiri"
  },
  {
    "id": "3521072008",
    "district_id": "352107",
    "label": "Brangol",
    "value": "Brangol"
  },
  {
    "id": "3521072009",
    "district_id": "352107",
    "label": "Sidokerto",
    "value": "Sidokerto"
  },
  {
    "id": "3521072010",
    "district_id": "352107",
    "label": "Jatipuro",
    "value": "Jatipuro"
  },
  {
    "id": "3521072011",
    "district_id": "352107",
    "label": "Puhti",
    "value": "Puhti"
  },
  {
    "id": "3521072012",
    "district_id": "352107",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "3521072013",
    "district_id": "352107",
    "label": "Karangjati",
    "value": "Karangjati"
  },
  {
    "id": "3521072014",
    "district_id": "352107",
    "label": "Legundi",
    "value": "Legundi"
  },
  {
    "id": "3521072015",
    "district_id": "352107",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3521072016",
    "district_id": "352107",
    "label": "Rejuno",
    "value": "Rejuno"
  },
  {
    "id": "3521072017",
    "district_id": "352107",
    "label": "Plosolor",
    "value": "Plosolor"
  },
  {
    "id": "3521082001",
    "district_id": "352108",
    "label": "Banjaransari",
    "value": "Banjaransari"
  },
  {
    "id": "3521082002",
    "district_id": "352108",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3521082003",
    "district_id": "352108",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "3521082004",
    "district_id": "352108",
    "label": "Tungkulrejo",
    "value": "Tungkulrejo"
  },
  {
    "id": "3521082005",
    "district_id": "352108",
    "label": "Bintoyo",
    "value": "Bintoyo"
  },
  {
    "id": "3521082006",
    "district_id": "352108",
    "label": "Sukowiyono",
    "value": "Sukowiyono"
  },
  {
    "id": "3521082007",
    "district_id": "352108",
    "label": "Munggut",
    "value": "Munggut"
  },
  {
    "id": "3521082011",
    "district_id": "352108",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3521082012",
    "district_id": "352108",
    "label": "Padas",
    "value": "Padas"
  },
  {
    "id": "3521082013",
    "district_id": "352108",
    "label": "Kedungprahu",
    "value": "Kedungprahu"
  },
  {
    "id": "3521082014",
    "district_id": "352108",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3521082015",
    "district_id": "352108",
    "label": "Kwadungan Lor",
    "value": "Kwadungan Lor"
  },
  {
    "id": "3521091013",
    "district_id": "352109",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3521091014",
    "district_id": "352109",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3521091015",
    "district_id": "352109",
    "label": "Ketanggi",
    "value": "Ketanggi"
  },
  {
    "id": "3521091016",
    "district_id": "352109",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3521092001",
    "district_id": "352109",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3521092002",
    "district_id": "352109",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3521092003",
    "district_id": "352109",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "3521092004",
    "district_id": "352109",
    "label": "Karangasri",
    "value": "Karangasri"
  },
  {
    "id": "3521092005",
    "district_id": "352109",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3521092006",
    "district_id": "352109",
    "label": "Beran",
    "value": "Beran"
  },
  {
    "id": "3521092007",
    "district_id": "352109",
    "label": "Jururejo",
    "value": "Jururejo"
  },
  {
    "id": "3521092008",
    "district_id": "352109",
    "label": "Grudo",
    "value": "Grudo"
  },
  {
    "id": "3521092009",
    "district_id": "352109",
    "label": "Watualang",
    "value": "Watualang"
  },
  {
    "id": "3521092010",
    "district_id": "352109",
    "label": "Ngawi",
    "value": "Ngawi"
  },
  {
    "id": "3521092011",
    "district_id": "352109",
    "label": "Karangtengah Prandon",
    "value": "Karangtengah Prandon"
  },
  {
    "id": "3521092012",
    "district_id": "352109",
    "label": "Kerek",
    "value": "Kerek"
  },
  {
    "id": "3521102001",
    "district_id": "352110",
    "label": "Gentong",
    "value": "Gentong"
  },
  {
    "id": "3521102002",
    "district_id": "352110",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3521102003",
    "district_id": "352110",
    "label": "Semen",
    "value": "Semen"
  },
  {
    "id": "3521102004",
    "district_id": "352110",
    "label": "Kedungputri",
    "value": "Kedungputri"
  },
  {
    "id": "3521102005",
    "district_id": "352110",
    "label": "Tempuran",
    "value": "Tempuran"
  },
  {
    "id": "3521102006",
    "district_id": "352110",
    "label": "Dawu",
    "value": "Dawu"
  },
  {
    "id": "3521102007",
    "district_id": "352110",
    "label": "Paron",
    "value": "Paron"
  },
  {
    "id": "3521102008",
    "district_id": "352110",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3521102009",
    "district_id": "352110",
    "label": "Teguhan",
    "value": "Teguhan"
  },
  {
    "id": "3521102010",
    "district_id": "352110",
    "label": "Sirigan",
    "value": "Sirigan"
  },
  {
    "id": "3521102011",
    "district_id": "352110",
    "label": "Jeblogan",
    "value": "Jeblogan"
  },
  {
    "id": "3521102012",
    "district_id": "352110",
    "label": "Ngale",
    "value": "Ngale"
  },
  {
    "id": "3521102013",
    "district_id": "352110",
    "label": "Gelung",
    "value": "Gelung"
  },
  {
    "id": "3521102014",
    "district_id": "352110",
    "label": "Kebon",
    "value": "Kebon"
  },
  {
    "id": "3521112001",
    "district_id": "352111",
    "label": "Begal",
    "value": "Begal"
  },
  {
    "id": "3521112002",
    "district_id": "352111",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3521112003",
    "district_id": "352111",
    "label": "Katikan",
    "value": "Katikan"
  },
  {
    "id": "3521112004",
    "district_id": "352111",
    "label": "Pelangkidul",
    "value": "Pelangkidul"
  },
  {
    "id": "3521112005",
    "district_id": "352111",
    "label": "Kedunggalar",
    "value": "Kedunggalar"
  },
  {
    "id": "3521112006",
    "district_id": "352111",
    "label": "Jatigembol",
    "value": "Jatigembol"
  },
  {
    "id": "3521112007",
    "district_id": "352111",
    "label": "Pelanglor",
    "value": "Pelanglor"
  },
  {
    "id": "3521112008",
    "district_id": "352111",
    "label": "Bangunrejo Kidul",
    "value": "Bangunrejo Kidul"
  },
  {
    "id": "3521112009",
    "district_id": "352111",
    "label": "Jenggrik",
    "value": "Jenggrik"
  },
  {
    "id": "3521112010",
    "district_id": "352111",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3521112011",
    "district_id": "352111",
    "label": "Gemarang",
    "value": "Gemarang"
  },
  {
    "id": "3521112012",
    "district_id": "352111",
    "label": "Kawu",
    "value": "Kawu"
  },
  {
    "id": "3521122001",
    "district_id": "352112",
    "label": "Banyubiru",
    "value": "Banyubiru"
  },
  {
    "id": "3521122002",
    "district_id": "352112",
    "label": "Kedunggudel",
    "value": "Kedunggudel"
  },
  {
    "id": "3521122003",
    "district_id": "352112",
    "label": "Sekaralas",
    "value": "Sekaralas"
  },
  {
    "id": "3521122004",
    "district_id": "352112",
    "label": "Sekarputih",
    "value": "Sekarputih"
  },
  {
    "id": "3521122005",
    "district_id": "352112",
    "label": "Kayutrejo",
    "value": "Kayutrejo"
  },
  {
    "id": "3521122006",
    "district_id": "352112",
    "label": "Sidolaju",
    "value": "Sidolaju"
  },
  {
    "id": "3521122007",
    "district_id": "352112",
    "label": "Karangbanyu",
    "value": "Karangbanyu"
  },
  {
    "id": "3521122008",
    "district_id": "352112",
    "label": "Widodaren",
    "value": "Widodaren"
  },
  {
    "id": "3521122009",
    "district_id": "352112",
    "label": "Gendingan",
    "value": "Gendingan"
  },
  {
    "id": "3521122010",
    "district_id": "352112",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3521122011",
    "district_id": "352112",
    "label": "Sidomakmur",
    "value": "Sidomakmur"
  },
  {
    "id": "3521122012",
    "district_id": "352112",
    "label": "Walikukun",
    "value": "Walikukun"
  },
  {
    "id": "3521132001",
    "district_id": "352113",
    "label": "Sambirejo",
    "value": "Sambirejo"
  },
  {
    "id": "3521132002",
    "district_id": "352113",
    "label": "Mantingan",
    "value": "Mantingan"
  },
  {
    "id": "3521132003",
    "district_id": "352113",
    "label": "Kedungharjo",
    "value": "Kedungharjo"
  },
  {
    "id": "3521132004",
    "district_id": "352113",
    "label": "Pakah",
    "value": "Pakah"
  },
  {
    "id": "3521132005",
    "district_id": "352113",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "3521132006",
    "district_id": "352113",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3521132007",
    "district_id": "352113",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3521142001",
    "district_id": "352114",
    "label": "Babadan",
    "value": "Babadan"
  },
  {
    "id": "3521142002",
    "district_id": "352114",
    "label": "Pohkonyal",
    "value": "Pohkonyal"
  },
  {
    "id": "3521142003",
    "district_id": "352114",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3521142004",
    "district_id": "352114",
    "label": "Gandri",
    "value": "Gandri"
  },
  {
    "id": "3521142005",
    "district_id": "352114",
    "label": "Pleset",
    "value": "Pleset"
  },
  {
    "id": "3521142006",
    "district_id": "352114",
    "label": "Warutengah",
    "value": "Warutengah"
  },
  {
    "id": "3521142007",
    "district_id": "352114",
    "label": "Ngompro",
    "value": "Ngompro"
  },
  {
    "id": "3521142008",
    "district_id": "352114",
    "label": "Pangkur",
    "value": "Pangkur"
  },
  {
    "id": "3521142009",
    "district_id": "352114",
    "label": "Paras",
    "value": "Paras"
  },
  {
    "id": "3521152001",
    "district_id": "352115",
    "label": "Sumberbening",
    "value": "Sumberbening"
  },
  {
    "id": "3521152002",
    "district_id": "352115",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3521152003",
    "district_id": "352115",
    "label": "Krompol",
    "value": "Krompol"
  },
  {
    "id": "3521152004",
    "district_id": "352115",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3521152005",
    "district_id": "352115",
    "label": "Dero",
    "value": "Dero"
  },
  {
    "id": "3521152006",
    "district_id": "352115",
    "label": "Legowetan",
    "value": "Legowetan"
  },
  {
    "id": "3521152007",
    "district_id": "352115",
    "label": "Gandong",
    "value": "Gandong"
  },
  {
    "id": "3521152008",
    "district_id": "352115",
    "label": "Dampit",
    "value": "Dampit"
  },
  {
    "id": "3521152009",
    "district_id": "352115",
    "label": "Suruh",
    "value": "Suruh"
  },
  {
    "id": "3521152010",
    "district_id": "352115",
    "label": "Kenongorejo",
    "value": "Kenongorejo"
  },
  {
    "id": "3521162001",
    "district_id": "352116",
    "label": "Kalang",
    "value": "Kalang"
  },
  {
    "id": "3521162002",
    "district_id": "352116",
    "label": "Pitu",
    "value": "Pitu"
  },
  {
    "id": "3521162003",
    "district_id": "352116",
    "label": "Dumplengan",
    "value": "Dumplengan"
  },
  {
    "id": "3521162004",
    "district_id": "352116",
    "label": "Selopuro",
    "value": "Selopuro"
  },
  {
    "id": "3521162005",
    "district_id": "352116",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "3521162006",
    "district_id": "352116",
    "label": "Ngancar",
    "value": "Ngancar"
  },
  {
    "id": "3521162007",
    "district_id": "352116",
    "label": "Cantel",
    "value": "Cantel"
  },
  {
    "id": "3521162008",
    "district_id": "352116",
    "label": "Papungan",
    "value": "Papungan"
  },
  {
    "id": "3521162009",
    "district_id": "352116",
    "label": "Banjarbanggi",
    "value": "Banjarbanggi"
  },
  {
    "id": "3521162010",
    "district_id": "352116",
    "label": "Bangunrejo Lor",
    "value": "Bangunrejo Lor"
  },
  {
    "id": "3521172001",
    "district_id": "352117",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3521172002",
    "district_id": "352117",
    "label": "Mengger",
    "value": "Mengger"
  },
  {
    "id": "3521172003",
    "district_id": "352117",
    "label": "Gembol",
    "value": "Gembol"
  },
  {
    "id": "3521172004",
    "district_id": "352117",
    "label": "Sriwedari",
    "value": "Sriwedari"
  },
  {
    "id": "3521172005",
    "district_id": "352117",
    "label": "Bangunrejo",
    "value": "Bangunrejo"
  },
  {
    "id": "3521172006",
    "district_id": "352117",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3521172007",
    "district_id": "352117",
    "label": "Sekarjati",
    "value": "Sekarjati"
  },
  {
    "id": "3521182001",
    "district_id": "352118",
    "label": "Randusongo",
    "value": "Randusongo"
  },
  {
    "id": "3521182002",
    "district_id": "352118",
    "label": "Widodaren",
    "value": "Widodaren"
  },
  {
    "id": "3521182003",
    "district_id": "352118",
    "label": "Gerih",
    "value": "Gerih"
  },
  {
    "id": "3521182004",
    "district_id": "352118",
    "label": "Keraskulon",
    "value": "Keraskulon"
  },
  {
    "id": "3521182005",
    "district_id": "352118",
    "label": "Guyung",
    "value": "Guyung"
  },
  {
    "id": "3521192001",
    "district_id": "352119",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3521192002",
    "district_id": "352119",
    "label": "Cangakan",
    "value": "Cangakan"
  },
  {
    "id": "3521192003",
    "district_id": "352119",
    "label": "Karangmalang",
    "value": "Karangmalang"
  },
  {
    "id": "3521192004",
    "district_id": "352119",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3521192005",
    "district_id": "352119",
    "label": "Kasreman",
    "value": "Kasreman"
  },
  {
    "id": "3521192006",
    "district_id": "352119",
    "label": "Legokulon",
    "value": "Legokulon"
  },
  {
    "id": "3521192007",
    "district_id": "352119",
    "label": "Tawun",
    "value": "Tawun"
  },
  {
    "id": "3521192008",
    "district_id": "352119",
    "label": "Kyonten",
    "value": "Kyonten"
  },
  {
    "id": "3522012001",
    "district_id": "352201",
    "label": "Luwihaji",
    "value": "Luwihaji"
  },
  {
    "id": "3522012002",
    "district_id": "352201",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3522012003",
    "district_id": "352201",
    "label": "Nganti",
    "value": "Nganti"
  },
  {
    "id": "3522012004",
    "district_id": "352201",
    "label": "Jumok",
    "value": "Jumok"
  },
  {
    "id": "3522012005",
    "district_id": "352201",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3522012006",
    "district_id": "352201",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3522012007",
    "district_id": "352201",
    "label": "Ngraho",
    "value": "Ngraho"
  },
  {
    "id": "3522012008",
    "district_id": "352201",
    "label": "Blimbinggede",
    "value": "Blimbinggede"
  },
  {
    "id": "3522012009",
    "district_id": "352201",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3522012010",
    "district_id": "352201",
    "label": "Tapelan",
    "value": "Tapelan"
  },
  {
    "id": "3522012011",
    "district_id": "352201",
    "label": "Tanggungan",
    "value": "Tanggungan"
  },
  {
    "id": "3522012012",
    "district_id": "352201",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "3522012013",
    "district_id": "352201",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3522012014",
    "district_id": "352201",
    "label": "Payaman",
    "value": "Payaman"
  },
  {
    "id": "3522012015",
    "district_id": "352201",
    "label": "Bancer",
    "value": "Bancer"
  },
  {
    "id": "3522012016",
    "district_id": "352201",
    "label": "Klempun",
    "value": "Klempun"
  },
  {
    "id": "3522022001",
    "district_id": "352202",
    "label": "Napis",
    "value": "Napis"
  },
  {
    "id": "3522022002",
    "district_id": "352202",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3522022003",
    "district_id": "352202",
    "label": "Ngrancang",
    "value": "Ngrancang"
  },
  {
    "id": "3522022004",
    "district_id": "352202",
    "label": "Turi",
    "value": "Turi"
  },
  {
    "id": "3522022005",
    "district_id": "352202",
    "label": "Malingmati",
    "value": "Malingmati"
  },
  {
    "id": "3522022006",
    "district_id": "352202",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3522022007",
    "district_id": "352202",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3522022008",
    "district_id": "352202",
    "label": "Jawik",
    "value": "Jawik"
  },
  {
    "id": "3522022009",
    "district_id": "352202",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3522022010",
    "district_id": "352202",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3522022011",
    "district_id": "352202",
    "label": "Pengkol",
    "value": "Pengkol"
  },
  {
    "id": "3522022012",
    "district_id": "352202",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3522022013",
    "district_id": "352202",
    "label": "Gamongan",
    "value": "Gamongan"
  },
  {
    "id": "3522022014",
    "district_id": "352202",
    "label": "Kalisumber",
    "value": "Kalisumber"
  },
  {
    "id": "3522022015",
    "district_id": "352202",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3522022016",
    "district_id": "352202",
    "label": "Dolokgede",
    "value": "Dolokgede"
  },
  {
    "id": "3522022017",
    "district_id": "352202",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3522022018",
    "district_id": "352202",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3522032001",
    "district_id": "352203",
    "label": "Nglamping",
    "value": "Nglamping"
  },
  {
    "id": "3522032002",
    "district_id": "352203",
    "label": "Karangmangu",
    "value": "Karangmangu"
  },
  {
    "id": "3522032003",
    "district_id": "352203",
    "label": "Ngambon",
    "value": "Ngambon"
  },
  {
    "id": "3522032004",
    "district_id": "352203",
    "label": "Sengon",
    "value": "Sengon"
  },
  {
    "id": "3522032005",
    "district_id": "352203",
    "label": "Bondol",
    "value": "Bondol"
  },
  {
    "id": "3522042001",
    "district_id": "352204",
    "label": "Butoh",
    "value": "Butoh"
  },
  {
    "id": "3522042002",
    "district_id": "352204",
    "label": "Trenggulunan",
    "value": "Trenggulunan"
  },
  {
    "id": "3522042003",
    "district_id": "352204",
    "label": "Setren",
    "value": "Setren"
  },
  {
    "id": "3522042004",
    "district_id": "352204",
    "label": "Mediyunan",
    "value": "Mediyunan"
  },
  {
    "id": "3522042005",
    "district_id": "352204",
    "label": "Kolong",
    "value": "Kolong"
  },
  {
    "id": "3522042006",
    "district_id": "352204",
    "label": "Sendangharjo",
    "value": "Sendangharjo"
  },
  {
    "id": "3522042007",
    "district_id": "352204",
    "label": "Ngadiluwih",
    "value": "Ngadiluwih"
  },
  {
    "id": "3522042008",
    "district_id": "352204",
    "label": "Ngasem",
    "value": "Ngasem"
  },
  {
    "id": "3522042009",
    "district_id": "352204",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3522042010",
    "district_id": "352204",
    "label": "Tengger",
    "value": "Tengger"
  },
  {
    "id": "3522042011",
    "district_id": "352204",
    "label": "Ngantru",
    "value": "Ngantru"
  },
  {
    "id": "3522042012",
    "district_id": "352204",
    "label": "Sambong",
    "value": "Sambong"
  },
  {
    "id": "3522042013",
    "district_id": "352204",
    "label": "Dukohkidul",
    "value": "Dukohkidul"
  },
  {
    "id": "3522042014",
    "district_id": "352204",
    "label": "Wadang",
    "value": "Wadang"
  },
  {
    "id": "3522042015",
    "district_id": "352204",
    "label": "Jampet",
    "value": "Jampet"
  },
  {
    "id": "3522042016",
    "district_id": "352204",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3522042017",
    "district_id": "352204",
    "label": "Jelu",
    "value": "Jelu"
  },
  {
    "id": "3522052001",
    "district_id": "352205",
    "label": "Bubulan",
    "value": "Bubulan"
  },
  {
    "id": "3522052002",
    "district_id": "352205",
    "label": "Cancung",
    "value": "Cancung"
  },
  {
    "id": "3522052003",
    "district_id": "352205",
    "label": "Clebung",
    "value": "Clebung"
  },
  {
    "id": "3522052004",
    "district_id": "352205",
    "label": "Sumberbendo",
    "value": "Sumberbendo"
  },
  {
    "id": "3522052005",
    "district_id": "352205",
    "label": "Ngorogunung",
    "value": "Ngorogunung"
  },
  {
    "id": "3522062001",
    "district_id": "352206",
    "label": "Ngunut",
    "value": "Ngunut"
  },
  {
    "id": "3522062002",
    "district_id": "352206",
    "label": "Dander",
    "value": "Dander"
  },
  {
    "id": "3522062003",
    "district_id": "352206",
    "label": "Growok",
    "value": "Growok"
  },
  {
    "id": "3522062004",
    "district_id": "352206",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3522062005",
    "district_id": "352206",
    "label": "Kunci",
    "value": "Kunci"
  },
  {
    "id": "3522062006",
    "district_id": "352206",
    "label": "Jatiblimbing",
    "value": "Jatiblimbing"
  },
  {
    "id": "3522062007",
    "district_id": "352206",
    "label": "Ngraseh",
    "value": "Ngraseh"
  },
  {
    "id": "3522062008",
    "district_id": "352206",
    "label": "Mojoranu",
    "value": "Mojoranu"
  },
  {
    "id": "3522062009",
    "district_id": "352206",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3522062010",
    "district_id": "352206",
    "label": "Karangsono",
    "value": "Karangsono"
  },
  {
    "id": "3522062011",
    "district_id": "352206",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3522062012",
    "district_id": "352206",
    "label": "Somodikaran",
    "value": "Somodikaran"
  },
  {
    "id": "3522062013",
    "district_id": "352206",
    "label": "Ngumpakdalem",
    "value": "Ngumpakdalem"
  },
  {
    "id": "3522062014",
    "district_id": "352206",
    "label": "Sumbertlaseh",
    "value": "Sumbertlaseh"
  },
  {
    "id": "3522062015",
    "district_id": "352206",
    "label": "Ngulanan",
    "value": "Ngulanan"
  },
  {
    "id": "3522062016",
    "district_id": "352206",
    "label": "Ngablak",
    "value": "Ngablak"
  },
  {
    "id": "3522072001",
    "district_id": "352207",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3522072002",
    "district_id": "352207",
    "label": "Drenges",
    "value": "Drenges"
  },
  {
    "id": "3522072003",
    "district_id": "352207",
    "label": "Wedoro",
    "value": "Wedoro"
  },
  {
    "id": "3522072004",
    "district_id": "352207",
    "label": "Panunggalan",
    "value": "Panunggalan"
  },
  {
    "id": "3522072005",
    "district_id": "352207",
    "label": "Alasgung",
    "value": "Alasgung"
  },
  {
    "id": "3522072006",
    "district_id": "352207",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "3522072007",
    "district_id": "352207",
    "label": "Glagahan",
    "value": "Glagahan"
  },
  {
    "id": "3522072008",
    "district_id": "352207",
    "label": "Panemon",
    "value": "Panemon"
  },
  {
    "id": "3522072009",
    "district_id": "352207",
    "label": "Jatitengah",
    "value": "Jatitengah"
  },
  {
    "id": "3522072010",
    "district_id": "352207",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3522072011",
    "district_id": "352207",
    "label": "Trate",
    "value": "Trate"
  },
  {
    "id": "3522072012",
    "district_id": "352207",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3522072013",
    "district_id": "352207",
    "label": "Nglajang",
    "value": "Nglajang"
  },
  {
    "id": "3522072014",
    "district_id": "352207",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3522072015",
    "district_id": "352207",
    "label": "Glagahwangi",
    "value": "Glagahwangi"
  },
  {
    "id": "3522072016",
    "district_id": "352207",
    "label": "Balongrejo",
    "value": "Balongrejo"
  },
  {
    "id": "3522072017",
    "district_id": "352207",
    "label": "Genjor",
    "value": "Genjor"
  },
  {
    "id": "3522082001",
    "district_id": "352208",
    "label": "Babad",
    "value": "Babad"
  },
  {
    "id": "3522082002",
    "district_id": "352208",
    "label": "Pejok",
    "value": "Pejok"
  },
  {
    "id": "3522082003",
    "district_id": "352208",
    "label": "Dayukidul",
    "value": "Dayukidul"
  },
  {
    "id": "3522082004",
    "district_id": "352208",
    "label": "Panjang",
    "value": "Panjang"
  },
  {
    "id": "3522082005",
    "district_id": "352208",
    "label": "Tondomulo",
    "value": "Tondomulo"
  },
  {
    "id": "3522082006",
    "district_id": "352208",
    "label": "Kesongo",
    "value": "Kesongo"
  },
  {
    "id": "3522082007",
    "district_id": "352208",
    "label": "Kendung",
    "value": "Kendung"
  },
  {
    "id": "3522082008",
    "district_id": "352208",
    "label": "Mlideg",
    "value": "Mlideg"
  },
  {
    "id": "3522082009",
    "district_id": "352208",
    "label": "Tumbrasanom",
    "value": "Tumbrasanom"
  },
  {
    "id": "3522082010",
    "district_id": "352208",
    "label": "Kedungadem",
    "value": "Kedungadem"
  },
  {
    "id": "3522082011",
    "district_id": "352208",
    "label": "Duwel",
    "value": "Duwel"
  },
  {
    "id": "3522082012",
    "district_id": "352208",
    "label": "Kepohkidul",
    "value": "Kepohkidul"
  },
  {
    "id": "3522082013",
    "district_id": "352208",
    "label": "Geger",
    "value": "Geger"
  },
  {
    "id": "3522082014",
    "district_id": "352208",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3522082015",
    "district_id": "352208",
    "label": "Megale",
    "value": "Megale"
  },
  {
    "id": "3522082016",
    "district_id": "352208",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3522082017",
    "district_id": "352208",
    "label": "Drokilo",
    "value": "Drokilo"
  },
  {
    "id": "3522082018",
    "district_id": "352208",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3522082019",
    "district_id": "352208",
    "label": "Jamberejo",
    "value": "Jamberejo"
  },
  {
    "id": "3522082020",
    "district_id": "352208",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3522082021",
    "district_id": "352208",
    "label": "Tlogoagung",
    "value": "Tlogoagung"
  },
  {
    "id": "3522082022",
    "district_id": "352208",
    "label": "Ngrandu",
    "value": "Ngrandu"
  },
  {
    "id": "3522082023",
    "district_id": "352208",
    "label": "Balongcabe",
    "value": "Balongcabe"
  },
  {
    "id": "3522092001",
    "district_id": "352209",
    "label": "Pejok",
    "value": "Pejok"
  },
  {
    "id": "3522092002",
    "district_id": "352209",
    "label": "Cengkir",
    "value": "Cengkir"
  },
  {
    "id": "3522092003",
    "district_id": "352209",
    "label": "Kepoh",
    "value": "Kepoh"
  },
  {
    "id": "3522092004",
    "district_id": "352209",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3522092005",
    "district_id": "352209",
    "label": "Simorejo",
    "value": "Simorejo"
  },
  {
    "id": "3522092006",
    "district_id": "352209",
    "label": "Krangkong",
    "value": "Krangkong"
  },
  {
    "id": "3522092007",
    "district_id": "352209",
    "label": "Nglumber",
    "value": "Nglumber"
  },
  {
    "id": "3522092008",
    "district_id": "352209",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3522092009",
    "district_id": "352209",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3522092010",
    "district_id": "352209",
    "label": "Balongdowo",
    "value": "Balongdowo"
  },
  {
    "id": "3522092011",
    "district_id": "352209",
    "label": "Suberoto",
    "value": "Suberoto"
  },
  {
    "id": "3522092012",
    "district_id": "352209",
    "label": "Pohwates",
    "value": "Pohwates"
  },
  {
    "id": "3522092013",
    "district_id": "352209",
    "label": "Turigede",
    "value": "Turigede"
  },
  {
    "id": "3522092014",
    "district_id": "352209",
    "label": "Bayemgede",
    "value": "Bayemgede"
  },
  {
    "id": "3522092015",
    "district_id": "352209",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3522092016",
    "district_id": "352209",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3522092017",
    "district_id": "352209",
    "label": "Woro",
    "value": "Woro"
  },
  {
    "id": "3522092018",
    "district_id": "352209",
    "label": "Bumirejo",
    "value": "Bumirejo"
  },
  {
    "id": "3522092019",
    "district_id": "352209",
    "label": "Betet",
    "value": "Betet"
  },
  {
    "id": "3522092020",
    "district_id": "352209",
    "label": "Jipo",
    "value": "Jipo"
  },
  {
    "id": "3522092021",
    "district_id": "352209",
    "label": "Ngranggonanyar",
    "value": "Ngranggonanyar"
  },
  {
    "id": "3522092022",
    "district_id": "352209",
    "label": "Mudung",
    "value": "Mudung"
  },
  {
    "id": "3522092023",
    "district_id": "352209",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "3522092024",
    "district_id": "352209",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3522092025",
    "district_id": "352209",
    "label": "Sumbergede",
    "value": "Sumbergede"
  },
  {
    "id": "3522102001",
    "district_id": "352210",
    "label": "Drajat",
    "value": "Drajat"
  },
  {
    "id": "3522102002",
    "district_id": "352210",
    "label": "Banjaranyar",
    "value": "Banjaranyar"
  },
  {
    "id": "3522102003",
    "district_id": "352210",
    "label": "Ngemplak",
    "value": "Ngemplak"
  },
  {
    "id": "3522102004",
    "district_id": "352210",
    "label": "Sraturejo",
    "value": "Sraturejo"
  },
  {
    "id": "3522102005",
    "district_id": "352210",
    "label": "Blongsong",
    "value": "Blongsong"
  },
  {
    "id": "3522102006",
    "district_id": "352210",
    "label": "Baureno",
    "value": "Baureno"
  },
  {
    "id": "3522102007",
    "district_id": "352210",
    "label": "Trojalu",
    "value": "Trojalu"
  },
  {
    "id": "3522102008",
    "district_id": "352210",
    "label": "Tulungagung",
    "value": "Tulungagung"
  },
  {
    "id": "3522102009",
    "district_id": "352210",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3522102010",
    "district_id": "352210",
    "label": "Tlogoagung",
    "value": "Tlogoagung"
  },
  {
    "id": "3522102011",
    "district_id": "352210",
    "label": "Sumuragung",
    "value": "Sumuragung"
  },
  {
    "id": "3522102012",
    "district_id": "352210",
    "label": "Gajah",
    "value": "Gajah"
  },
  {
    "id": "3522102013",
    "district_id": "352210",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3522102014",
    "district_id": "352210",
    "label": "Tanggungan",
    "value": "Tanggungan"
  },
  {
    "id": "3522102015",
    "district_id": "352210",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3522102016",
    "district_id": "352210",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3522102017",
    "district_id": "352210",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3522102018",
    "district_id": "352210",
    "label": "Karangdayu",
    "value": "Karangdayu"
  },
  {
    "id": "3522102019",
    "district_id": "352210",
    "label": "Pasinan",
    "value": "Pasinan"
  },
  {
    "id": "3522102020",
    "district_id": "352210",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3522102021",
    "district_id": "352210",
    "label": "Sembunglor",
    "value": "Sembunglor"
  },
  {
    "id": "3522102022",
    "district_id": "352210",
    "label": "Pomahan",
    "value": "Pomahan"
  },
  {
    "id": "3522102023",
    "district_id": "352210",
    "label": "Pucungarum",
    "value": "Pucungarum"
  },
  {
    "id": "3522102024",
    "district_id": "352210",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3522102025",
    "district_id": "352210",
    "label": "Lebaksari",
    "value": "Lebaksari"
  },
  {
    "id": "3522112001",
    "district_id": "352211",
    "label": "Semambung",
    "value": "Semambung"
  },
  {
    "id": "3522112002",
    "district_id": "352211",
    "label": "Kanor",
    "value": "Kanor"
  },
  {
    "id": "3522112003",
    "district_id": "352211",
    "label": "Tambahrejo",
    "value": "Tambahrejo"
  },
  {
    "id": "3522112004",
    "district_id": "352211",
    "label": "Piyak",
    "value": "Piyak"
  },
  {
    "id": "3522112005",
    "district_id": "352211",
    "label": "Kabalan",
    "value": "Kabalan"
  },
  {
    "id": "3522112006",
    "district_id": "352211",
    "label": "Cangaan",
    "value": "Cangaan"
  },
  {
    "id": "3522112007",
    "district_id": "352211",
    "label": "Sarangan",
    "value": "Sarangan"
  },
  {
    "id": "3522112008",
    "district_id": "352211",
    "label": "Tejo",
    "value": "Tejo"
  },
  {
    "id": "3522112009",
    "district_id": "352211",
    "label": "Simbatan",
    "value": "Simbatan"
  },
  {
    "id": "3522112010",
    "district_id": "352211",
    "label": "Pesen",
    "value": "Pesen"
  },
  {
    "id": "3522112011",
    "district_id": "352211",
    "label": "Samberan",
    "value": "Samberan"
  },
  {
    "id": "3522112012",
    "district_id": "352211",
    "label": "Palembon",
    "value": "Palembon"
  },
  {
    "id": "3522112013",
    "district_id": "352211",
    "label": "Sedeng",
    "value": "Sedeng"
  },
  {
    "id": "3522112014",
    "district_id": "352211",
    "label": "Caruban",
    "value": "Caruban"
  },
  {
    "id": "3522112015",
    "district_id": "352211",
    "label": "Sumberwangi",
    "value": "Sumberwangi"
  },
  {
    "id": "3522112016",
    "district_id": "352211",
    "label": "Prigi",
    "value": "Prigi"
  },
  {
    "id": "3522112017",
    "district_id": "352211",
    "label": "Pilang",
    "value": "Pilang"
  },
  {
    "id": "3522112018",
    "district_id": "352211",
    "label": "Gedongarum",
    "value": "Gedongarum"
  },
  {
    "id": "3522112019",
    "district_id": "352211",
    "label": "Kedungprimpen",
    "value": "Kedungprimpen"
  },
  {
    "id": "3522112020",
    "district_id": "352211",
    "label": "Temu",
    "value": "Temu"
  },
  {
    "id": "3522112021",
    "district_id": "352211",
    "label": "Simorejo",
    "value": "Simorejo"
  },
  {
    "id": "3522112022",
    "district_id": "352211",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "3522112023",
    "district_id": "352211",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3522112024",
    "district_id": "352211",
    "label": "Nglarangan",
    "value": "Nglarangan"
  },
  {
    "id": "3522112025",
    "district_id": "352211",
    "label": "Sroyo",
    "value": "Sroyo"
  },
  {
    "id": "3522122001",
    "district_id": "352212",
    "label": "Tlogohaji",
    "value": "Tlogohaji"
  },
  {
    "id": "3522122002",
    "district_id": "352212",
    "label": "Ngampal",
    "value": "Ngampal"
  },
  {
    "id": "3522122003",
    "district_id": "352212",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3522122004",
    "district_id": "352212",
    "label": "Mlinjeng",
    "value": "Mlinjeng"
  },
  {
    "id": "3522122005",
    "district_id": "352212",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3522122006",
    "district_id": "352212",
    "label": "Sumberrejo",
    "value": "Sumberrejo"
  },
  {
    "id": "3522122007",
    "district_id": "352212",
    "label": "Kayulemah",
    "value": "Kayulemah"
  },
  {
    "id": "3522122008",
    "district_id": "352212",
    "label": "Teleng",
    "value": "Teleng"
  },
  {
    "id": "3522122009",
    "district_id": "352212",
    "label": "Wotan",
    "value": "Wotan"
  },
  {
    "id": "3522122010",
    "district_id": "352212",
    "label": "Sambongrejo",
    "value": "Sambongrejo"
  },
  {
    "id": "3522122011",
    "district_id": "352212",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3522122012",
    "district_id": "352212",
    "label": "Deru",
    "value": "Deru"
  },
  {
    "id": "3522122013",
    "district_id": "352212",
    "label": "Pekuwon",
    "value": "Pekuwon"
  },
  {
    "id": "3522122014",
    "district_id": "352212",
    "label": "Karangdowo",
    "value": "Karangdowo"
  },
  {
    "id": "3522122015",
    "district_id": "352212",
    "label": "Pejambon",
    "value": "Pejambon"
  },
  {
    "id": "3522122016",
    "district_id": "352212",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3522122017",
    "district_id": "352212",
    "label": "Karangdinoyo",
    "value": "Karangdinoyo"
  },
  {
    "id": "3522122018",
    "district_id": "352212",
    "label": "Butoh",
    "value": "Butoh"
  },
  {
    "id": "3522122019",
    "district_id": "352212",
    "label": "Mejuwet",
    "value": "Mejuwet"
  },
  {
    "id": "3522122020",
    "district_id": "352212",
    "label": "Prayungan",
    "value": "Prayungan"
  },
  {
    "id": "3522122021",
    "district_id": "352212",
    "label": "Sumuragung",
    "value": "Sumuragung"
  },
  {
    "id": "3522122022",
    "district_id": "352212",
    "label": "Jatigede",
    "value": "Jatigede"
  },
  {
    "id": "3522122023",
    "district_id": "352212",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3522122024",
    "district_id": "352212",
    "label": "Bogangin",
    "value": "Bogangin"
  },
  {
    "id": "3522122025",
    "district_id": "352212",
    "label": "Sumberharjo",
    "value": "Sumberharjo"
  },
  {
    "id": "3522122026",
    "district_id": "352212",
    "label": "Margoagung",
    "value": "Margoagung"
  },
  {
    "id": "3522132001",
    "district_id": "352213",
    "label": "Sidobandung",
    "value": "Sidobandung"
  },
  {
    "id": "3522132002",
    "district_id": "352213",
    "label": "Mayangkawis",
    "value": "Mayangkawis"
  },
  {
    "id": "3522132003",
    "district_id": "352213",
    "label": "Kenep",
    "value": "Kenep"
  },
  {
    "id": "3522132004",
    "district_id": "352213",
    "label": "Pohbogo",
    "value": "Pohbogo"
  },
  {
    "id": "3522132005",
    "district_id": "352213",
    "label": "Penganten",
    "value": "Penganten"
  },
  {
    "id": "3522132006",
    "district_id": "352213",
    "label": "Bulaklo",
    "value": "Bulaklo"
  },
  {
    "id": "3522132007",
    "district_id": "352213",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "3522132008",
    "district_id": "352213",
    "label": "Kemamang",
    "value": "Kemamang"
  },
  {
    "id": "3522132009",
    "district_id": "352213",
    "label": "Ngadiluhur",
    "value": "Ngadiluhur"
  },
  {
    "id": "3522132010",
    "district_id": "352213",
    "label": "Kabunan",
    "value": "Kabunan"
  },
  {
    "id": "3522132011",
    "district_id": "352213",
    "label": "Sobontoro",
    "value": "Sobontoro"
  },
  {
    "id": "3522132012",
    "district_id": "352213",
    "label": "Suwaloh",
    "value": "Suwaloh"
  },
  {
    "id": "3522132013",
    "district_id": "352213",
    "label": "Balenrejo",
    "value": "Balenrejo"
  },
  {
    "id": "3522132014",
    "district_id": "352213",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3522132015",
    "district_id": "352213",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3522132016",
    "district_id": "352213",
    "label": "Mulyoagung",
    "value": "Mulyoagung"
  },
  {
    "id": "3522132017",
    "district_id": "352213",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3522132018",
    "district_id": "352213",
    "label": "Prambatan",
    "value": "Prambatan"
  },
  {
    "id": "3522132019",
    "district_id": "352213",
    "label": "Kedungdowo",
    "value": "Kedungdowo"
  },
  {
    "id": "3522132020",
    "district_id": "352213",
    "label": "Kedungbendo",
    "value": "Kedungbendo"
  },
  {
    "id": "3522132021",
    "district_id": "352213",
    "label": "Pilanggede",
    "value": "Pilanggede"
  },
  {
    "id": "3522132022",
    "district_id": "352213",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3522132023",
    "district_id": "352213",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3522142001",
    "district_id": "352214",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3522142002",
    "district_id": "352214",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3522142003",
    "district_id": "352214",
    "label": "Padangmentoyo",
    "value": "Padangmentoyo"
  },
  {
    "id": "3522142004",
    "district_id": "352214",
    "label": "Tapelan",
    "value": "Tapelan"
  },
  {
    "id": "3522142005",
    "district_id": "352214",
    "label": "Bangilan",
    "value": "Bangilan"
  },
  {
    "id": "3522142006",
    "district_id": "352214",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3522142007",
    "district_id": "352214",
    "label": "Tanjungharjo",
    "value": "Tanjungharjo"
  },
  {
    "id": "3522142008",
    "district_id": "352214",
    "label": "Wedi",
    "value": "Wedi"
  },
  {
    "id": "3522142009",
    "district_id": "352214",
    "label": "Plesungan",
    "value": "Plesungan"
  },
  {
    "id": "3522142010",
    "district_id": "352214",
    "label": "Kedaton",
    "value": "Kedaton"
  },
  {
    "id": "3522142011",
    "district_id": "352214",
    "label": "Kapas",
    "value": "Kapas"
  },
  {
    "id": "3522142012",
    "district_id": "352214",
    "label": "Semenpinggir",
    "value": "Semenpinggir"
  },
  {
    "id": "3522142013",
    "district_id": "352214",
    "label": "Bogo",
    "value": "Bogo"
  },
  {
    "id": "3522142014",
    "district_id": "352214",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3522142015",
    "district_id": "352214",
    "label": "Mojodeso",
    "value": "Mojodeso"
  },
  {
    "id": "3522142016",
    "district_id": "352214",
    "label": "Sukowati",
    "value": "Sukowati"
  },
  {
    "id": "3522142017",
    "district_id": "352214",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "3522142018",
    "district_id": "352214",
    "label": "Tikusan",
    "value": "Tikusan"
  },
  {
    "id": "3522142019",
    "district_id": "352214",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3522142020",
    "district_id": "352214",
    "label": "Sambiroto",
    "value": "Sambiroto"
  },
  {
    "id": "3522142021",
    "district_id": "352214",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3522151001",
    "district_id": "352215",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3522151004",
    "district_id": "352215",
    "label": "Mojokampung",
    "value": "Mojokampung"
  },
  {
    "id": "3522151005",
    "district_id": "352215",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3522151006",
    "district_id": "352215",
    "label": "Sumbang",
    "value": "Sumbang"
  },
  {
    "id": "3522151007",
    "district_id": "352215",
    "label": "Klangon",
    "value": "Klangon"
  },
  {
    "id": "3522151009",
    "district_id": "352215",
    "label": "Ledok Kulon",
    "value": "Ledok Kulon"
  },
  {
    "id": "3522151010",
    "district_id": "352215",
    "label": "Ledok Wetan",
    "value": "Ledok Wetan"
  },
  {
    "id": "3522151011",
    "district_id": "352215",
    "label": "Kadipaten",
    "value": "Kadipaten"
  },
  {
    "id": "3522151012",
    "district_id": "352215",
    "label": "Ngrowo",
    "value": "Ngrowo"
  },
  {
    "id": "3522151013",
    "district_id": "352215",
    "label": "Karang pacar",
    "value": "Karang pacar"
  },
  {
    "id": "3522151014",
    "district_id": "352215",
    "label": "Banjarrejo",
    "value": "Banjarrejo"
  },
  {
    "id": "3522152002",
    "district_id": "352215",
    "label": "Pacul",
    "value": "Pacul"
  },
  {
    "id": "3522152003",
    "district_id": "352215",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3522152008",
    "district_id": "352215",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3522152015",
    "district_id": "352215",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3522152016",
    "district_id": "352215",
    "label": "Mulyoagung",
    "value": "Mulyoagung"
  },
  {
    "id": "3522152017",
    "district_id": "352215",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3522152018",
    "district_id": "352215",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "3522162006",
    "district_id": "352216",
    "label": "Kalitidu",
    "value": "Kalitidu"
  },
  {
    "id": "3522162007",
    "district_id": "352216",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3522162008",
    "district_id": "352216",
    "label": "Mlaten",
    "value": "Mlaten"
  },
  {
    "id": "3522162009",
    "district_id": "352216",
    "label": "Talok",
    "value": "Talok"
  },
  {
    "id": "3522162010",
    "district_id": "352216",
    "label": "Brenggolo",
    "value": "Brenggolo"
  },
  {
    "id": "3522162011",
    "district_id": "352216",
    "label": "Grebegan",
    "value": "Grebegan"
  },
  {
    "id": "3522162012",
    "district_id": "352216",
    "label": "Wotanngare",
    "value": "Wotanngare"
  },
  {
    "id": "3522162013",
    "district_id": "352216",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3522162014",
    "district_id": "352216",
    "label": "Mayanggeneng",
    "value": "Mayanggeneng"
  },
  {
    "id": "3522162015",
    "district_id": "352216",
    "label": "Mayangrejo",
    "value": "Mayangrejo"
  },
  {
    "id": "3522162016",
    "district_id": "352216",
    "label": "Pilangsari",
    "value": "Pilangsari"
  },
  {
    "id": "3522162017",
    "district_id": "352216",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3522162018",
    "district_id": "352216",
    "label": "Pungpungan",
    "value": "Pungpungan"
  },
  {
    "id": "3522162019",
    "district_id": "352216",
    "label": "Ngujo",
    "value": "Ngujo"
  },
  {
    "id": "3522162020",
    "district_id": "352216",
    "label": "Leran",
    "value": "Leran"
  },
  {
    "id": "3522162021",
    "district_id": "352216",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3522162022",
    "district_id": "352216",
    "label": "Ngiringinrejo",
    "value": "Ngiringinrejo"
  },
  {
    "id": "3522162023",
    "district_id": "352216",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3522172001",
    "district_id": "352217",
    "label": "Dukohlor",
    "value": "Dukohlor"
  },
  {
    "id": "3522172002",
    "district_id": "352217",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3522172003",
    "district_id": "352217",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3522172004",
    "district_id": "352217",
    "label": "Petak",
    "value": "Petak"
  },
  {
    "id": "3522172005",
    "district_id": "352217",
    "label": "Sudah",
    "value": "Sudah"
  },
  {
    "id": "3522172006",
    "district_id": "352217",
    "label": "Rendeng",
    "value": "Rendeng"
  },
  {
    "id": "3522172007",
    "district_id": "352217",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3522172008",
    "district_id": "352217",
    "label": "Ngujung",
    "value": "Ngujung"
  },
  {
    "id": "3522172009",
    "district_id": "352217",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3522172010",
    "district_id": "352217",
    "label": "Tambakromo",
    "value": "Tambakromo"
  },
  {
    "id": "3522172011",
    "district_id": "352217",
    "label": "Tinawun",
    "value": "Tinawun"
  },
  {
    "id": "3522172012",
    "district_id": "352217",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3522172013",
    "district_id": "352217",
    "label": "Ketileng",
    "value": "Ketileng"
  },
  {
    "id": "3522172014",
    "district_id": "352217",
    "label": "Malo",
    "value": "Malo"
  },
  {
    "id": "3522172015",
    "district_id": "352217",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3522172016",
    "district_id": "352217",
    "label": "Kliteh",
    "value": "Kliteh"
  },
  {
    "id": "3522172017",
    "district_id": "352217",
    "label": "Trembes",
    "value": "Trembes"
  },
  {
    "id": "3522172018",
    "district_id": "352217",
    "label": "Semlaran",
    "value": "Semlaran"
  },
  {
    "id": "3522172019",
    "district_id": "352217",
    "label": "Tanggir",
    "value": "Tanggir"
  },
  {
    "id": "3522172020",
    "district_id": "352217",
    "label": "Tulungagung",
    "value": "Tulungagung"
  },
  {
    "id": "3522182001",
    "district_id": "352218",
    "label": "Pelem",
    "value": "Pelem"
  },
  {
    "id": "3522182002",
    "district_id": "352218",
    "label": "Ngrejeng",
    "value": "Ngrejeng"
  },
  {
    "id": "3522182003",
    "district_id": "352218",
    "label": "Tlatah",
    "value": "Tlatah"
  },
  {
    "id": "3522182004",
    "district_id": "352218",
    "label": "Kaliombo",
    "value": "Kaliombo"
  },
  {
    "id": "3522182005",
    "district_id": "352218",
    "label": "Tinumpuk",
    "value": "Tinumpuk"
  },
  {
    "id": "3522182006",
    "district_id": "352218",
    "label": "Punggur",
    "value": "Punggur"
  },
  {
    "id": "3522182007",
    "district_id": "352218",
    "label": "Sedahkidul",
    "value": "Sedahkidul"
  },
  {
    "id": "3522182008",
    "district_id": "352218",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3522182009",
    "district_id": "352218",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "3522182010",
    "district_id": "352218",
    "label": "Gapluk",
    "value": "Gapluk"
  },
  {
    "id": "3522182011",
    "district_id": "352218",
    "label": "Kuniran",
    "value": "Kuniran"
  },
  {
    "id": "3522182012",
    "district_id": "352218",
    "label": "Donan",
    "value": "Donan"
  },
  {
    "id": "3522192001",
    "district_id": "352219",
    "label": "Tebon",
    "value": "Tebon"
  },
  {
    "id": "3522192002",
    "district_id": "352219",
    "label": "Prangi",
    "value": "Prangi"
  },
  {
    "id": "3522192003",
    "district_id": "352219",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3522192004",
    "district_id": "352219",
    "label": "Ngeper",
    "value": "Ngeper"
  },
  {
    "id": "3522192005",
    "district_id": "352219",
    "label": "Ngasinan",
    "value": "Ngasinan"
  },
  {
    "id": "3522192006",
    "district_id": "352219",
    "label": "Cendono",
    "value": "Cendono"
  },
  {
    "id": "3522192007",
    "district_id": "352219",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3522192008",
    "district_id": "352219",
    "label": "Nguken",
    "value": "Nguken"
  },
  {
    "id": "3522192009",
    "district_id": "352219",
    "label": "Dengok",
    "value": "Dengok"
  },
  {
    "id": "3522192010",
    "district_id": "352219",
    "label": "Padangan",
    "value": "Padangan"
  },
  {
    "id": "3522192011",
    "district_id": "352219",
    "label": "Kuncen",
    "value": "Kuncen"
  },
  {
    "id": "3522192012",
    "district_id": "352219",
    "label": "Sonorejo",
    "value": "Sonorejo"
  },
  {
    "id": "3522192013",
    "district_id": "352219",
    "label": "Ngradin",
    "value": "Ngradin"
  },
  {
    "id": "3522192014",
    "district_id": "352219",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3522192015",
    "district_id": "352219",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3522192016",
    "district_id": "352219",
    "label": "Kendung",
    "value": "Kendung"
  },
  {
    "id": "3522202001",
    "district_id": "352220",
    "label": "Batokan",
    "value": "Batokan"
  },
  {
    "id": "3522202002",
    "district_id": "352220",
    "label": "Betet",
    "value": "Betet"
  },
  {
    "id": "3522202003",
    "district_id": "352220",
    "label": "Tembeling",
    "value": "Tembeling"
  },
  {
    "id": "3522202004",
    "district_id": "352220",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3522202005",
    "district_id": "352220",
    "label": "Basah",
    "value": "Basah"
  },
  {
    "id": "3522202006",
    "district_id": "352220",
    "label": "Sambeng",
    "value": "Sambeng"
  },
  {
    "id": "3522202007",
    "district_id": "352220",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3522202008",
    "district_id": "352220",
    "label": "Kasiman",
    "value": "Kasiman"
  },
  {
    "id": "3522202009",
    "district_id": "352220",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3522202010",
    "district_id": "352220",
    "label": "Tambakmerak",
    "value": "Tambakmerak"
  },
  {
    "id": "3522212001",
    "district_id": "352221",
    "label": "Kedungsumber",
    "value": "Kedungsumber"
  },
  {
    "id": "3522212002",
    "district_id": "352221",
    "label": "Kedungsari",
    "value": "Kedungsari"
  },
  {
    "id": "3522212003",
    "district_id": "352221",
    "label": "Papringan",
    "value": "Papringan"
  },
  {
    "id": "3522212004",
    "district_id": "352221",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3522212005",
    "district_id": "352221",
    "label": "Pandantoyo",
    "value": "Pandantoyo"
  },
  {
    "id": "3522212006",
    "district_id": "352221",
    "label": "Belun",
    "value": "Belun"
  },
  {
    "id": "3522212007",
    "district_id": "352221",
    "label": "Temayang",
    "value": "Temayang"
  },
  {
    "id": "3522212008",
    "district_id": "352221",
    "label": "Bakulan",
    "value": "Bakulan"
  },
  {
    "id": "3522212009",
    "district_id": "352221",
    "label": "Jono",
    "value": "Jono"
  },
  {
    "id": "3522212010",
    "district_id": "352221",
    "label": "Ngujung",
    "value": "Ngujung"
  },
  {
    "id": "3522212011",
    "district_id": "352221",
    "label": "Buntalan",
    "value": "Buntalan"
  },
  {
    "id": "3522212012",
    "district_id": "352221",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "3522222001",
    "district_id": "352222",
    "label": "Kalangan",
    "value": "Kalangan"
  },
  {
    "id": "3522222002",
    "district_id": "352222",
    "label": "Ngelo",
    "value": "Ngelo"
  },
  {
    "id": "3522222003",
    "district_id": "352222",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3522222004",
    "district_id": "352222",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3522222005",
    "district_id": "352222",
    "label": "Meduri",
    "value": "Meduri"
  },
  {
    "id": "3522222006",
    "district_id": "352222",
    "label": "Geneng",
    "value": "Geneng"
  },
  {
    "id": "3522232001",
    "district_id": "352223",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3522232002",
    "district_id": "352223",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3522232003",
    "district_id": "352223",
    "label": "Trucuk",
    "value": "Trucuk"
  },
  {
    "id": "3522232004",
    "district_id": "352223",
    "label": "Mori",
    "value": "Mori"
  },
  {
    "id": "3522232005",
    "district_id": "352223",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "3522232006",
    "district_id": "352223",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3522232007",
    "district_id": "352223",
    "label": "Guyangan",
    "value": "Guyangan"
  },
  {
    "id": "3522232008",
    "district_id": "352223",
    "label": "Sranak",
    "value": "Sranak"
  },
  {
    "id": "3522232009",
    "district_id": "352223",
    "label": "Sumbangtimun",
    "value": "Sumbangtimun"
  },
  {
    "id": "3522232010",
    "district_id": "352223",
    "label": "Kanten",
    "value": "Kanten"
  },
  {
    "id": "3522232011",
    "district_id": "352223",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3522232012",
    "district_id": "352223",
    "label": "Pagerwesi",
    "value": "Pagerwesi"
  },
  {
    "id": "3522242001",
    "district_id": "352224",
    "label": "Semawot",
    "value": "Semawot"
  },
  {
    "id": "3522242002",
    "district_id": "352224",
    "label": "Kalicilik",
    "value": "Kalicilik"
  },
  {
    "id": "3522242003",
    "district_id": "352224",
    "label": "Sukosewu",
    "value": "Sukosewu"
  },
  {
    "id": "3522242004",
    "district_id": "352224",
    "label": "Klepek",
    "value": "Klepek"
  },
  {
    "id": "3522242005",
    "district_id": "352224",
    "label": "Tegalkodo",
    "value": "Tegalkodo"
  },
  {
    "id": "3522242006",
    "district_id": "352224",
    "label": "Sitiaji",
    "value": "Sitiaji"
  },
  {
    "id": "3522242007",
    "district_id": "352224",
    "label": "Purwoasri",
    "value": "Purwoasri"
  },
  {
    "id": "3522242008",
    "district_id": "352224",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3522242009",
    "district_id": "352224",
    "label": "Jumput",
    "value": "Jumput"
  },
  {
    "id": "3522242010",
    "district_id": "352224",
    "label": "Duyungan",
    "value": "Duyungan"
  },
  {
    "id": "3522242011",
    "district_id": "352224",
    "label": "Semenkidul",
    "value": "Semenkidul"
  },
  {
    "id": "3522242012",
    "district_id": "352224",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3522242013",
    "district_id": "352224",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3522242014",
    "district_id": "352224",
    "label": "Sumberejo Kidul",
    "value": "Sumberejo Kidul"
  },
  {
    "id": "3522252001",
    "district_id": "352225",
    "label": "Kawengan",
    "value": "Kawengan"
  },
  {
    "id": "3522252002",
    "district_id": "352225",
    "label": "Wonocolo",
    "value": "Wonocolo"
  },
  {
    "id": "3522252003",
    "district_id": "352225",
    "label": "Hargomulyo",
    "value": "Hargomulyo"
  },
  {
    "id": "3522252004",
    "district_id": "352225",
    "label": "Kedewan",
    "value": "Kedewan"
  },
  {
    "id": "3522252005",
    "district_id": "352225",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3522262001",
    "district_id": "352226",
    "label": "Krondonan",
    "value": "Krondonan"
  },
  {
    "id": "3522262002",
    "district_id": "352226",
    "label": "Jari",
    "value": "Jari"
  },
  {
    "id": "3522262003",
    "district_id": "352226",
    "label": "Sambongrejo",
    "value": "Sambongrejo"
  },
  {
    "id": "3522262004",
    "district_id": "352226",
    "label": "Pajeng",
    "value": "Pajeng"
  },
  {
    "id": "3522262005",
    "district_id": "352226",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "3522262006",
    "district_id": "352226",
    "label": "Senganten",
    "value": "Senganten"
  },
  {
    "id": "3522262007",
    "district_id": "352226",
    "label": "Pragelan",
    "value": "Pragelan"
  },
  {
    "id": "3522272001",
    "district_id": "352227",
    "label": "Bobol",
    "value": "Bobol"
  },
  {
    "id": "3522272002",
    "district_id": "352227",
    "label": "Miyono",
    "value": "Miyono"
  },
  {
    "id": "3522272003",
    "district_id": "352227",
    "label": "Sekar",
    "value": "Sekar"
  },
  {
    "id": "3522272004",
    "district_id": "352227",
    "label": "Klino",
    "value": "Klino"
  },
  {
    "id": "3522272005",
    "district_id": "352227",
    "label": "Deling",
    "value": "Deling"
  },
  {
    "id": "3522272006",
    "district_id": "352227",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3522282001",
    "district_id": "352228",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3522282002",
    "district_id": "352228",
    "label": "Begadon",
    "value": "Begadon"
  },
  {
    "id": "3522282003",
    "district_id": "352228",
    "label": "Ringintunggal",
    "value": "Ringintunggal"
  },
  {
    "id": "3522282004",
    "district_id": "352228",
    "label": "Mojodelik",
    "value": "Mojodelik"
  },
  {
    "id": "3522282005",
    "district_id": "352228",
    "label": "Brabowan",
    "value": "Brabowan"
  },
  {
    "id": "3522282006",
    "district_id": "352228",
    "label": "Bonorejo",
    "value": "Bonorejo"
  },
  {
    "id": "3522282007",
    "district_id": "352228",
    "label": "Beged",
    "value": "Beged"
  },
  {
    "id": "3522282008",
    "district_id": "352228",
    "label": "Katur",
    "value": "Katur"
  },
  {
    "id": "3522282009",
    "district_id": "352228",
    "label": "Ngraho",
    "value": "Ngraho"
  },
  {
    "id": "3522282010",
    "district_id": "352228",
    "label": "Sudu",
    "value": "Sudu"
  },
  {
    "id": "3522282011",
    "district_id": "352228",
    "label": "Cengungklung",
    "value": "Cengungklung"
  },
  {
    "id": "3522282012",
    "district_id": "352228",
    "label": "Manukan",
    "value": "Manukan"
  },
  {
    "id": "3523012001",
    "district_id": "352301",
    "label": "Jlodro",
    "value": "Jlodro"
  },
  {
    "id": "3523012002",
    "district_id": "352301",
    "label": "Sokogunung",
    "value": "Sokogunung"
  },
  {
    "id": "3523012003",
    "district_id": "352301",
    "label": "Jamprong",
    "value": "Jamprong"
  },
  {
    "id": "3523012004",
    "district_id": "352301",
    "label": "Tawaran",
    "value": "Tawaran"
  },
  {
    "id": "3523012005",
    "district_id": "352301",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3523012006",
    "district_id": "352301",
    "label": "Bendonglateng",
    "value": "Bendonglateng"
  },
  {
    "id": "3523012007",
    "district_id": "352301",
    "label": "Sidohasri",
    "value": "Sidohasri"
  },
  {
    "id": "3523012008",
    "district_id": "352301",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3523012009",
    "district_id": "352301",
    "label": "Sokogrenjeng",
    "value": "Sokogrenjeng"
  },
  {
    "id": "3523022001",
    "district_id": "352302",
    "label": "Kebonharjo",
    "value": "Kebonharjo"
  },
  {
    "id": "3523022002",
    "district_id": "352302",
    "label": "Wangi",
    "value": "Wangi"
  },
  {
    "id": "3523022003",
    "district_id": "352302",
    "label": "Ketodan",
    "value": "Ketodan"
  },
  {
    "id": "3523022004",
    "district_id": "352302",
    "label": "Karangtengah",
    "value": "Karangtengah"
  },
  {
    "id": "3523022005",
    "district_id": "352302",
    "label": "Bader",
    "value": "Bader"
  },
  {
    "id": "3523022006",
    "district_id": "352302",
    "label": "Paseyan",
    "value": "Paseyan"
  },
  {
    "id": "3523022007",
    "district_id": "352302",
    "label": "Besowo",
    "value": "Besowo"
  },
  {
    "id": "3523022008",
    "district_id": "352302",
    "label": "Jombok",
    "value": "Jombok"
  },
  {
    "id": "3523022009",
    "district_id": "352302",
    "label": "Sadang",
    "value": "Sadang"
  },
  {
    "id": "3523022010",
    "district_id": "352302",
    "label": "Wotsogo",
    "value": "Wotsogo"
  },
  {
    "id": "3523022011",
    "district_id": "352302",
    "label": "Ngepon",
    "value": "Ngepon"
  },
  {
    "id": "3523022012",
    "district_id": "352302",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3523022013",
    "district_id": "352302",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3523022014",
    "district_id": "352302",
    "label": "Kedungmakam",
    "value": "Kedungmakam"
  },
  {
    "id": "3523022015",
    "district_id": "352302",
    "label": "Demit",
    "value": "Demit"
  },
  {
    "id": "3523022016",
    "district_id": "352302",
    "label": "Jatiklabang",
    "value": "Jatiklabang"
  },
  {
    "id": "3523022017",
    "district_id": "352302",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3523022018",
    "district_id": "352302",
    "label": "Dingil",
    "value": "Dingil"
  },
  {
    "id": "3523032001",
    "district_id": "352303",
    "label": "Klakeh",
    "value": "Klakeh"
  },
  {
    "id": "3523032002",
    "district_id": "352303",
    "label": "Bate",
    "value": "Bate"
  },
  {
    "id": "3523032003",
    "district_id": "352303",
    "label": "Sidotentrem",
    "value": "Sidotentrem"
  },
  {
    "id": "3523032004",
    "district_id": "352303",
    "label": "Kedungjambangan",
    "value": "Kedungjambangan"
  },
  {
    "id": "3523032005",
    "district_id": "352303",
    "label": "Kablukan",
    "value": "Kablukan"
  },
  {
    "id": "3523032006",
    "district_id": "352303",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3523032007",
    "district_id": "352303",
    "label": "Bangilan",
    "value": "Bangilan"
  },
  {
    "id": "3523032008",
    "district_id": "352303",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3523032009",
    "district_id": "352303",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3523032010",
    "district_id": "352303",
    "label": "Banjarworo",
    "value": "Banjarworo"
  },
  {
    "id": "3523032011",
    "district_id": "352303",
    "label": "Ngrojo",
    "value": "Ngrojo"
  },
  {
    "id": "3523032012",
    "district_id": "352303",
    "label": "Weden",
    "value": "Weden"
  },
  {
    "id": "3523032013",
    "district_id": "352303",
    "label": "Kedungharjo",
    "value": "Kedungharjo"
  },
  {
    "id": "3523032014",
    "district_id": "352303",
    "label": "Kedungmulyo",
    "value": "Kedungmulyo"
  },
  {
    "id": "3523042001",
    "district_id": "352304",
    "label": "Tlogoagung",
    "value": "Tlogoagung"
  },
  {
    "id": "3523042002",
    "district_id": "352304",
    "label": "Ngujuran",
    "value": "Ngujuran"
  },
  {
    "id": "3523042003",
    "district_id": "352304",
    "label": "Latsari",
    "value": "Latsari"
  },
  {
    "id": "3523042004",
    "district_id": "352304",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3523042005",
    "district_id": "352304",
    "label": "Tenggerkulon",
    "value": "Tenggerkulon"
  },
  {
    "id": "3523042006",
    "district_id": "352304",
    "label": "Bulujowo",
    "value": "Bulujowo"
  },
  {
    "id": "3523042007",
    "district_id": "352304",
    "label": "Siding",
    "value": "Siding"
  },
  {
    "id": "3523042008",
    "district_id": "352304",
    "label": "Tergambang",
    "value": "Tergambang"
  },
  {
    "id": "3523042009",
    "district_id": "352304",
    "label": "Bulumeduro",
    "value": "Bulumeduro"
  },
  {
    "id": "3523042010",
    "district_id": "352304",
    "label": "Banjarjo",
    "value": "Banjarjo"
  },
  {
    "id": "3523042011",
    "district_id": "352304",
    "label": "Boncong",
    "value": "Boncong"
  },
  {
    "id": "3523042012",
    "district_id": "352304",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3523042013",
    "district_id": "352304",
    "label": "Sumberan",
    "value": "Sumberan"
  },
  {
    "id": "3523042014",
    "district_id": "352304",
    "label": "Kayen",
    "value": "Kayen"
  },
  {
    "id": "3523042015",
    "district_id": "352304",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3523042016",
    "district_id": "352304",
    "label": "Sembungin",
    "value": "Sembungin"
  },
  {
    "id": "3523042017",
    "district_id": "352304",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3523042018",
    "district_id": "352304",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3523042019",
    "district_id": "352304",
    "label": "Pugoh",
    "value": "Pugoh"
  },
  {
    "id": "3523042020",
    "district_id": "352304",
    "label": "Bancar",
    "value": "Bancar"
  },
  {
    "id": "3523042021",
    "district_id": "352304",
    "label": "Ngampelrejo",
    "value": "Ngampelrejo"
  },
  {
    "id": "3523042022",
    "district_id": "352304",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3523042023",
    "district_id": "352304",
    "label": "Margosuko",
    "value": "Margosuko"
  },
  {
    "id": "3523042024",
    "district_id": "352304",
    "label": "Cingklung",
    "value": "Cingklung"
  },
  {
    "id": "3523052001",
    "district_id": "352305",
    "label": "Medalem",
    "value": "Medalem"
  },
  {
    "id": "3523052002",
    "district_id": "352305",
    "label": "Jatisari",
    "value": "Jatisari"
  },
  {
    "id": "3523052003",
    "district_id": "352305",
    "label": "Kaligede",
    "value": "Kaligede"
  },
  {
    "id": "3523052004",
    "district_id": "352305",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3523052005",
    "district_id": "352305",
    "label": "Leran",
    "value": "Leran"
  },
  {
    "id": "3523052006",
    "district_id": "352305",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "3523052007",
    "district_id": "352305",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3523052008",
    "district_id": "352305",
    "label": "Wanglukulon",
    "value": "Wanglukulon"
  },
  {
    "id": "3523052009",
    "district_id": "352305",
    "label": "Wangluwetan",
    "value": "Wangluwetan"
  },
  {
    "id": "3523052010",
    "district_id": "352305",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3523052011",
    "district_id": "352305",
    "label": "Rayung",
    "value": "Rayung"
  },
  {
    "id": "3523052012",
    "district_id": "352305",
    "label": "Katerban",
    "value": "Katerban"
  },
  {
    "id": "3523062001",
    "district_id": "352306",
    "label": "Dikir",
    "value": "Dikir"
  },
  {
    "id": "3523062002",
    "district_id": "352306",
    "label": "Ngulahan",
    "value": "Ngulahan"
  },
  {
    "id": "3523062003",
    "district_id": "352306",
    "label": "Plajan",
    "value": "Plajan"
  },
  {
    "id": "3523062004",
    "district_id": "352306",
    "label": "Mander",
    "value": "Mander"
  },
  {
    "id": "3523062005",
    "district_id": "352306",
    "label": "Belikanget",
    "value": "Belikanget"
  },
  {
    "id": "3523062006",
    "district_id": "352306",
    "label": "Cokrowati",
    "value": "Cokrowati"
  },
  {
    "id": "3523062007",
    "district_id": "352306",
    "label": "Pulogede",
    "value": "Pulogede"
  },
  {
    "id": "3523062008",
    "district_id": "352306",
    "label": "Gadon",
    "value": "Gadon"
  },
  {
    "id": "3523062009",
    "district_id": "352306",
    "label": "Sotang",
    "value": "Sotang"
  },
  {
    "id": "3523062010",
    "district_id": "352306",
    "label": "Pabeyan",
    "value": "Pabeyan"
  },
  {
    "id": "3523062011",
    "district_id": "352306",
    "label": "Klutuk",
    "value": "Klutuk"
  },
  {
    "id": "3523062012",
    "district_id": "352306",
    "label": "Tambakboyo",
    "value": "Tambakboyo"
  },
  {
    "id": "3523062013",
    "district_id": "352306",
    "label": "Dasin",
    "value": "Dasin"
  },
  {
    "id": "3523062014",
    "district_id": "352306",
    "label": "Kenanti",
    "value": "Kenanti"
  },
  {
    "id": "3523062015",
    "district_id": "352306",
    "label": "Sawir",
    "value": "Sawir"
  },
  {
    "id": "3523062016",
    "district_id": "352306",
    "label": "Sobontoro",
    "value": "Sobontoro"
  },
  {
    "id": "3523062017",
    "district_id": "352306",
    "label": "Merkawang",
    "value": "Merkawang"
  },
  {
    "id": "3523062018",
    "district_id": "352306",
    "label": "Glondonggede",
    "value": "Glondonggede"
  },
  {
    "id": "3523072001",
    "district_id": "352307",
    "label": "Lajo Kidul",
    "value": "Lajo Kidul"
  },
  {
    "id": "3523072002",
    "district_id": "352307",
    "label": "Lajo Lor",
    "value": "Lajo Lor"
  },
  {
    "id": "3523072003",
    "district_id": "352307",
    "label": "Tanggir",
    "value": "Tanggir"
  },
  {
    "id": "3523072004",
    "district_id": "352307",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3523072005",
    "district_id": "352307",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3523072006",
    "district_id": "352307",
    "label": "Mergosari",
    "value": "Mergosari"
  },
  {
    "id": "3523072007",
    "district_id": "352307",
    "label": "Mulyoagung",
    "value": "Mulyoagung"
  },
  {
    "id": "3523072008",
    "district_id": "352307",
    "label": "Tunggulrejo",
    "value": "Tunggulrejo"
  },
  {
    "id": "3523072009",
    "district_id": "352307",
    "label": "Tingkis",
    "value": "Tingkis"
  },
  {
    "id": "3523072010",
    "district_id": "352307",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3523072011",
    "district_id": "352307",
    "label": "Saringembat",
    "value": "Saringembat"
  },
  {
    "id": "3523072012",
    "district_id": "352307",
    "label": "Kedungjambe",
    "value": "Kedungjambe"
  },
  {
    "id": "3523082001",
    "district_id": "352308",
    "label": "Gemulung",
    "value": "Gemulung"
  },
  {
    "id": "3523082002",
    "district_id": "352308",
    "label": "Sidonganti",
    "value": "Sidonganti"
  },
  {
    "id": "3523082003",
    "district_id": "352308",
    "label": "Trantang",
    "value": "Trantang"
  },
  {
    "id": "3523082004",
    "district_id": "352308",
    "label": "Wolutengah",
    "value": "Wolutengah"
  },
  {
    "id": "3523082005",
    "district_id": "352308",
    "label": "Tenggerwetan",
    "value": "Tenggerwetan"
  },
  {
    "id": "3523082006",
    "district_id": "352308",
    "label": "Gaji",
    "value": "Gaji"
  },
  {
    "id": "3523082007",
    "district_id": "352308",
    "label": "Hargoretno",
    "value": "Hargoretno"
  },
  {
    "id": "3523082008",
    "district_id": "352308",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3523082009",
    "district_id": "352308",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3523082010",
    "district_id": "352308",
    "label": "Jarorejo",
    "value": "Jarorejo"
  },
  {
    "id": "3523082011",
    "district_id": "352308",
    "label": "Mliwang",
    "value": "Mliwang"
  },
  {
    "id": "3523082012",
    "district_id": "352308",
    "label": "Kasiman",
    "value": "Kasiman"
  },
  {
    "id": "3523082013",
    "district_id": "352308",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "3523082014",
    "district_id": "352308",
    "label": "Temayang",
    "value": "Temayang"
  },
  {
    "id": "3523082015",
    "district_id": "352308",
    "label": "Sumberarum",
    "value": "Sumberarum"
  },
  {
    "id": "3523082016",
    "district_id": "352308",
    "label": "Karanglo",
    "value": "Karanglo"
  },
  {
    "id": "3523082017",
    "district_id": "352308",
    "label": "Padasan",
    "value": "Padasan"
  },
  {
    "id": "3523092001",
    "district_id": "352309",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3523092002",
    "district_id": "352309",
    "label": "Kemlaten",
    "value": "Kemlaten"
  },
  {
    "id": "3523092003",
    "district_id": "352309",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3523092004",
    "district_id": "352309",
    "label": "Ngawun",
    "value": "Ngawun"
  },
  {
    "id": "3523092005",
    "district_id": "352309",
    "label": "Mergoasri",
    "value": "Mergoasri"
  },
  {
    "id": "3523092006",
    "district_id": "352309",
    "label": "Kumpulrejo",
    "value": "Kumpulrejo"
  },
  {
    "id": "3523092007",
    "district_id": "352309",
    "label": "Parangbatu",
    "value": "Parangbatu"
  },
  {
    "id": "3523092008",
    "district_id": "352309",
    "label": "Wukirharjo",
    "value": "Wukirharjo"
  },
  {
    "id": "3523092009",
    "district_id": "352309",
    "label": "Cengkong",
    "value": "Cengkong"
  },
  {
    "id": "3523092010",
    "district_id": "352309",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3523092011",
    "district_id": "352309",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3523092012",
    "district_id": "352309",
    "label": "Dagangan",
    "value": "Dagangan"
  },
  {
    "id": "3523092013",
    "district_id": "352309",
    "label": "Suciharjo",
    "value": "Suciharjo"
  },
  {
    "id": "3523092014",
    "district_id": "352309",
    "label": "Pacing",
    "value": "Pacing"
  },
  {
    "id": "3523092015",
    "district_id": "352309",
    "label": "Selogabus",
    "value": "Selogabus"
  },
  {
    "id": "3523092016",
    "district_id": "352309",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3523092017",
    "district_id": "352309",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3523092018",
    "district_id": "352309",
    "label": "Mojomalang",
    "value": "Mojomalang"
  },
  {
    "id": "3523102001",
    "district_id": "352310",
    "label": "Guwoterus",
    "value": "Guwoterus"
  },
  {
    "id": "3523102002",
    "district_id": "352310",
    "label": "Nguluhan",
    "value": "Nguluhan"
  },
  {
    "id": "3523102003",
    "district_id": "352310",
    "label": "Tanggulangin",
    "value": "Tanggulangin"
  },
  {
    "id": "3523102004",
    "district_id": "352310",
    "label": "Manjung",
    "value": "Manjung"
  },
  {
    "id": "3523102005",
    "district_id": "352310",
    "label": "Talangkembar",
    "value": "Talangkembar"
  },
  {
    "id": "3523102006",
    "district_id": "352310",
    "label": "Montongsekar",
    "value": "Montongsekar"
  },
  {
    "id": "3523102007",
    "district_id": "352310",
    "label": "Pakel",
    "value": "Pakel"
  },
  {
    "id": "3523102008",
    "district_id": "352310",
    "label": "Sumurgung",
    "value": "Sumurgung"
  },
  {
    "id": "3523102009",
    "district_id": "352310",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3523102010",
    "district_id": "352310",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3523102011",
    "district_id": "352310",
    "label": "Maindu",
    "value": "Maindu"
  },
  {
    "id": "3523102012",
    "district_id": "352310",
    "label": "Jetak",
    "value": "Jetak"
  },
  {
    "id": "3523102013",
    "district_id": "352310",
    "label": "Talun",
    "value": "Talun"
  },
  {
    "id": "3523112001",
    "district_id": "352311",
    "label": "Tluwe",
    "value": "Tluwe"
  },
  {
    "id": "3523112002",
    "district_id": "352311",
    "label": "Wadung",
    "value": "Wadung"
  },
  {
    "id": "3523112003",
    "district_id": "352311",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3523112004",
    "district_id": "352311",
    "label": "Menilo",
    "value": "Menilo"
  },
  {
    "id": "3523112005",
    "district_id": "352311",
    "label": "Cekalang",
    "value": "Cekalang"
  },
  {
    "id": "3523112006",
    "district_id": "352311",
    "label": "Pandanagung",
    "value": "Pandanagung"
  },
  {
    "id": "3523112007",
    "district_id": "352311",
    "label": "Prambontergayang",
    "value": "Prambontergayang"
  },
  {
    "id": "3523112008",
    "district_id": "352311",
    "label": "Klumpit",
    "value": "Klumpit"
  },
  {
    "id": "3523112009",
    "district_id": "352311",
    "label": "Simo",
    "value": "Simo"
  },
  {
    "id": "3523112010",
    "district_id": "352311",
    "label": "Mentoro",
    "value": "Mentoro"
  },
  {
    "id": "3523112012",
    "district_id": "352311",
    "label": "Kendalrejo",
    "value": "Kendalrejo"
  },
  {
    "id": "3523112013",
    "district_id": "352311",
    "label": "Nguruan",
    "value": "Nguruan"
  },
  {
    "id": "3523112014",
    "district_id": "352311",
    "label": "Jegulo",
    "value": "Jegulo"
  },
  {
    "id": "3523112015",
    "district_id": "352311",
    "label": "Sokosari",
    "value": "Sokosari"
  },
  {
    "id": "3523112016",
    "district_id": "352311",
    "label": "Bangunrejo",
    "value": "Bangunrejo"
  },
  {
    "id": "3523112017",
    "district_id": "352311",
    "label": "Mojoagung",
    "value": "Mojoagung"
  },
  {
    "id": "3523112019",
    "district_id": "352311",
    "label": "Gununganyar",
    "value": "Gununganyar"
  },
  {
    "id": "3523112020",
    "district_id": "352311",
    "label": "Sumurcinde",
    "value": "Sumurcinde"
  },
  {
    "id": "3523112021",
    "district_id": "352311",
    "label": "Pandanwangi",
    "value": "Pandanwangi"
  },
  {
    "id": "3523112022",
    "district_id": "352311",
    "label": "Sandingrowo",
    "value": "Sandingrowo"
  },
  {
    "id": "3523112023",
    "district_id": "352311",
    "label": "Rahayu",
    "value": "Rahayu"
  },
  {
    "id": "3523112024",
    "district_id": "352311",
    "label": "Glagahsari",
    "value": "Glagahsari"
  },
  {
    "id": "3523112025",
    "district_id": "352311",
    "label": "Kenongosari",
    "value": "Kenongosari"
  },
  {
    "id": "3523122001",
    "district_id": "352312",
    "label": "Karangasem",
    "value": "Karangasem"
  },
  {
    "id": "3523122002",
    "district_id": "352312",
    "label": "Socorejo",
    "value": "Socorejo"
  },
  {
    "id": "3523122003",
    "district_id": "352312",
    "label": "Temaji",
    "value": "Temaji"
  },
  {
    "id": "3523122004",
    "district_id": "352312",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3523122005",
    "district_id": "352312",
    "label": "Tasikharjo",
    "value": "Tasikharjo"
  },
  {
    "id": "3523122006",
    "district_id": "352312",
    "label": "Sumurgeneng",
    "value": "Sumurgeneng"
  },
  {
    "id": "3523122007",
    "district_id": "352312",
    "label": "Suwalan",
    "value": "Suwalan"
  },
  {
    "id": "3523122008",
    "district_id": "352312",
    "label": "Remen",
    "value": "Remen"
  },
  {
    "id": "3523122009",
    "district_id": "352312",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3523122010",
    "district_id": "352312",
    "label": "Wadung",
    "value": "Wadung"
  },
  {
    "id": "3523122011",
    "district_id": "352312",
    "label": "Rawasan",
    "value": "Rawasan"
  },
  {
    "id": "3523122012",
    "district_id": "352312",
    "label": "Mentoso",
    "value": "Mentoso"
  },
  {
    "id": "3523122013",
    "district_id": "352312",
    "label": "Jenggolo",
    "value": "Jenggolo"
  },
  {
    "id": "3523122014",
    "district_id": "352312",
    "label": "Kaliuntu",
    "value": "Kaliuntu"
  },
  {
    "id": "3523122015",
    "district_id": "352312",
    "label": "Sekardadi",
    "value": "Sekardadi"
  },
  {
    "id": "3523122016",
    "district_id": "352312",
    "label": "Jenu",
    "value": "Jenu"
  },
  {
    "id": "3523122017",
    "district_id": "352312",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3523132001",
    "district_id": "352313",
    "label": "Tlogowaru",
    "value": "Tlogowaru"
  },
  {
    "id": "3523132002",
    "district_id": "352313",
    "label": "Tobo",
    "value": "Tobo"
  },
  {
    "id": "3523132003",
    "district_id": "352313",
    "label": "Temandang",
    "value": "Temandang"
  },
  {
    "id": "3523132004",
    "district_id": "352313",
    "label": "Tuwiri Wetan",
    "value": "Tuwiri Wetan"
  },
  {
    "id": "3523132005",
    "district_id": "352313",
    "label": "Pongpongan",
    "value": "Pongpongan"
  },
  {
    "id": "3523132006",
    "district_id": "352313",
    "label": "Tuwiri Kulon",
    "value": "Tuwiri Kulon"
  },
  {
    "id": "3523132007",
    "district_id": "352313",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3523132008",
    "district_id": "352313",
    "label": "Senori",
    "value": "Senori"
  },
  {
    "id": "3523132009",
    "district_id": "352313",
    "label": "Sembungrejo",
    "value": "Sembungrejo"
  },
  {
    "id": "3523132010",
    "district_id": "352313",
    "label": "Kapu",
    "value": "Kapu"
  },
  {
    "id": "3523132011",
    "district_id": "352313",
    "label": "Tahulu",
    "value": "Tahulu"
  },
  {
    "id": "3523132012",
    "district_id": "352313",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3523132013",
    "district_id": "352313",
    "label": "Borehbangle",
    "value": "Borehbangle"
  },
  {
    "id": "3523132014",
    "district_id": "352313",
    "label": "Sambonggede",
    "value": "Sambonggede"
  },
  {
    "id": "3523132015",
    "district_id": "352313",
    "label": "Sumber",
    "value": "Sumber"
  },
  {
    "id": "3523132016",
    "district_id": "352313",
    "label": "Mandirejo",
    "value": "Mandirejo"
  },
  {
    "id": "3523132017",
    "district_id": "352313",
    "label": "Sendanghaji",
    "value": "Sendanghaji"
  },
  {
    "id": "3523132018",
    "district_id": "352313",
    "label": "Bogorejo",
    "value": "Bogorejo"
  },
  {
    "id": "3523132019",
    "district_id": "352313",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3523142004",
    "district_id": "352314",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3523142005",
    "district_id": "352314",
    "label": "Pekuwon",
    "value": "Pekuwon"
  },
  {
    "id": "3523142007",
    "district_id": "352314",
    "label": "Rengel",
    "value": "Rengel"
  },
  {
    "id": "3523142008",
    "district_id": "352314",
    "label": "Maibit",
    "value": "Maibit"
  },
  {
    "id": "3523142009",
    "district_id": "352314",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3523142010",
    "district_id": "352314",
    "label": "Karangtinoto",
    "value": "Karangtinoto"
  },
  {
    "id": "3523142011",
    "district_id": "352314",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3523142013",
    "district_id": "352314",
    "label": "Kanorejo",
    "value": "Kanorejo"
  },
  {
    "id": "3523142014",
    "district_id": "352314",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3523142016",
    "district_id": "352314",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3523142017",
    "district_id": "352314",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3523142018",
    "district_id": "352314",
    "label": "Punggulrejo",
    "value": "Punggulrejo"
  },
  {
    "id": "3523142019",
    "district_id": "352314",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3523142020",
    "district_id": "352314",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3523142021",
    "district_id": "352314",
    "label": "Banjararum",
    "value": "Banjararum"
  },
  {
    "id": "3523142022",
    "district_id": "352314",
    "label": "Prambonwetan",
    "value": "Prambonwetan"
  },
  {
    "id": "3523151006",
    "district_id": "352315",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3523151015",
    "district_id": "352315",
    "label": "Gedongombo",
    "value": "Gedongombo"
  },
  {
    "id": "3523152001",
    "district_id": "352315",
    "label": "Jadi",
    "value": "Jadi"
  },
  {
    "id": "3523152002",
    "district_id": "352315",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "3523152003",
    "district_id": "352315",
    "label": "Prunggahan Kulon",
    "value": "Prunggahan Kulon"
  },
  {
    "id": "3523152004",
    "district_id": "352315",
    "label": "Tegalagung",
    "value": "Tegalagung"
  },
  {
    "id": "3523152009",
    "district_id": "352315",
    "label": "Bektiharjo",
    "value": "Bektiharjo"
  },
  {
    "id": "3523152010",
    "district_id": "352315",
    "label": "Ngino",
    "value": "Ngino"
  },
  {
    "id": "3523152011",
    "district_id": "352315",
    "label": "Prunggahan Wetan",
    "value": "Prunggahan Wetan"
  },
  {
    "id": "3523152012",
    "district_id": "352315",
    "label": "Sambongrejo",
    "value": "Sambongrejo"
  },
  {
    "id": "3523152013",
    "district_id": "352315",
    "label": "Semanding",
    "value": "Semanding"
  },
  {
    "id": "3523152014",
    "district_id": "352315",
    "label": "Bejagung",
    "value": "Bejagung"
  },
  {
    "id": "3523152016",
    "district_id": "352315",
    "label": "Penambangan",
    "value": "Penambangan"
  },
  {
    "id": "3523152017",
    "district_id": "352315",
    "label": "Genaharjo",
    "value": "Genaharjo"
  },
  {
    "id": "3523152018",
    "district_id": "352315",
    "label": "Kowang",
    "value": "Kowang"
  },
  {
    "id": "3523152019",
    "district_id": "352315",
    "label": "Tunah",
    "value": "Tunah"
  },
  {
    "id": "3523152020",
    "district_id": "352315",
    "label": "Gesing",
    "value": "Gesing"
  },
  {
    "id": "3523161003",
    "district_id": "352316",
    "label": "Mondokan",
    "value": "Mondokan"
  },
  {
    "id": "3523161005",
    "district_id": "352316",
    "label": "Perbon",
    "value": "Perbon"
  },
  {
    "id": "3523161006",
    "district_id": "352316",
    "label": "Latsari",
    "value": "Latsari"
  },
  {
    "id": "3523161007",
    "district_id": "352316",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3523161008",
    "district_id": "352316",
    "label": "Kingking",
    "value": "Kingking"
  },
  {
    "id": "3523161009",
    "district_id": "352316",
    "label": "Ronggomulyo",
    "value": "Ronggomulyo"
  },
  {
    "id": "3523161010",
    "district_id": "352316",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3523161011",
    "district_id": "352316",
    "label": "Doromukti",
    "value": "Doromukti"
  },
  {
    "id": "3523161012",
    "district_id": "352316",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3523161013",
    "district_id": "352316",
    "label": "Kutorejo",
    "value": "Kutorejo"
  },
  {
    "id": "3523161014",
    "district_id": "352316",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3523161015",
    "district_id": "352316",
    "label": "Sendangharjo",
    "value": "Sendangharjo"
  },
  {
    "id": "3523161016",
    "district_id": "352316",
    "label": "Baturetno",
    "value": "Baturetno"
  },
  {
    "id": "3523161017",
    "district_id": "352316",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3523162001",
    "district_id": "352316",
    "label": "Sumurgung",
    "value": "Sumurgung"
  },
  {
    "id": "3523162002",
    "district_id": "352316",
    "label": "Sugiharjo",
    "value": "Sugiharjo"
  },
  {
    "id": "3523162004",
    "district_id": "352316",
    "label": "Kembangbilo",
    "value": "Kembangbilo"
  },
  {
    "id": "3523172001",
    "district_id": "352317",
    "label": "Trutup",
    "value": "Trutup"
  },
  {
    "id": "3523172002",
    "district_id": "352317",
    "label": "Kesamben",
    "value": "Kesamben"
  },
  {
    "id": "3523172003",
    "district_id": "352317",
    "label": "Kepohagung",
    "value": "Kepohagung"
  },
  {
    "id": "3523172004",
    "district_id": "352317",
    "label": "Kedungrojo",
    "value": "Kedungrojo"
  },
  {
    "id": "3523172005",
    "district_id": "352317",
    "label": "Sumurjalak",
    "value": "Sumurjalak"
  },
  {
    "id": "3523172006",
    "district_id": "352317",
    "label": "Sembungrejo",
    "value": "Sembungrejo"
  },
  {
    "id": "3523172007",
    "district_id": "352317",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3523172008",
    "district_id": "352317",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3523172009",
    "district_id": "352317",
    "label": "Plumpang",
    "value": "Plumpang"
  },
  {
    "id": "3523172010",
    "district_id": "352317",
    "label": "Plandirejo",
    "value": "Plandirejo"
  },
  {
    "id": "3523172011",
    "district_id": "352317",
    "label": "Ngrayung",
    "value": "Ngrayung"
  },
  {
    "id": "3523172012",
    "district_id": "352317",
    "label": "Bandungrejo",
    "value": "Bandungrejo"
  },
  {
    "id": "3523172013",
    "district_id": "352317",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3523172014",
    "district_id": "352317",
    "label": "Klotok",
    "value": "Klotok"
  },
  {
    "id": "3523172015",
    "district_id": "352317",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3523172016",
    "district_id": "352317",
    "label": "Kebomlati",
    "value": "Kebomlati"
  },
  {
    "id": "3523172017",
    "district_id": "352317",
    "label": "Penidon",
    "value": "Penidon"
  },
  {
    "id": "3523172018",
    "district_id": "352317",
    "label": "Kedungsoko",
    "value": "Kedungsoko"
  },
  {
    "id": "3523181001",
    "district_id": "352318",
    "label": "Payuran",
    "value": "Payuran"
  },
  {
    "id": "3523182002",
    "district_id": "352318",
    "label": "Tasikmadu",
    "value": "Tasikmadu"
  },
  {
    "id": "3523182003",
    "district_id": "352318",
    "label": "Tegalbang",
    "value": "Tegalbang"
  },
  {
    "id": "3523182004",
    "district_id": "352318",
    "label": "Sumurgung",
    "value": "Sumurgung"
  },
  {
    "id": "3523182005",
    "district_id": "352318",
    "label": "Kradenan",
    "value": "Kradenan"
  },
  {
    "id": "3523182006",
    "district_id": "352318",
    "label": "Dawung",
    "value": "Dawung"
  },
  {
    "id": "3523182007",
    "district_id": "352318",
    "label": "Cendoro",
    "value": "Cendoro"
  },
  {
    "id": "3523182008",
    "district_id": "352318",
    "label": "Ngimbang",
    "value": "Ngimbang"
  },
  {
    "id": "3523182009",
    "district_id": "352318",
    "label": "Pucangan",
    "value": "Pucangan"
  },
  {
    "id": "3523182010",
    "district_id": "352318",
    "label": "Gesikharjo",
    "value": "Gesikharjo"
  },
  {
    "id": "3523182011",
    "district_id": "352318",
    "label": "Palang",
    "value": "Palang"
  },
  {
    "id": "3523182012",
    "district_id": "352318",
    "label": "Glodog",
    "value": "Glodog"
  },
  {
    "id": "3523182013",
    "district_id": "352318",
    "label": "Leran Kulon",
    "value": "Leran Kulon"
  },
  {
    "id": "3523182014",
    "district_id": "352318",
    "label": "Leran Wetan",
    "value": "Leran Wetan"
  },
  {
    "id": "3523182015",
    "district_id": "352318",
    "label": "Wangun",
    "value": "Wangun"
  },
  {
    "id": "3523182016",
    "district_id": "352318",
    "label": "Karangagung",
    "value": "Karangagung"
  },
  {
    "id": "3523182017",
    "district_id": "352318",
    "label": "Pliwetan",
    "value": "Pliwetan"
  },
  {
    "id": "3523182018",
    "district_id": "352318",
    "label": "Cepokorejo",
    "value": "Cepokorejo"
  },
  {
    "id": "3523182019",
    "district_id": "352318",
    "label": "Ketambul",
    "value": "Ketambul"
  },
  {
    "id": "3523192001",
    "district_id": "352319",
    "label": "Patihan",
    "value": "Patihan"
  },
  {
    "id": "3523192002",
    "district_id": "352319",
    "label": "Minohorejo",
    "value": "Minohorejo"
  },
  {
    "id": "3523192003",
    "district_id": "352319",
    "label": "Mrutuk",
    "value": "Mrutuk"
  },
  {
    "id": "3523192004",
    "district_id": "352319",
    "label": "Compreng",
    "value": "Compreng"
  },
  {
    "id": "3523192005",
    "district_id": "352319",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3523192006",
    "district_id": "352319",
    "label": "Ngadipuro",
    "value": "Ngadipuro"
  },
  {
    "id": "3523192007",
    "district_id": "352319",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3523192008",
    "district_id": "352319",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "3523192009",
    "district_id": "352319",
    "label": "Widang",
    "value": "Widang"
  },
  {
    "id": "3523192010",
    "district_id": "352319",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3523192011",
    "district_id": "352319",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3523192012",
    "district_id": "352319",
    "label": "Mlangi",
    "value": "Mlangi"
  },
  {
    "id": "3523192013",
    "district_id": "352319",
    "label": "Simorejo",
    "value": "Simorejo"
  },
  {
    "id": "3523192014",
    "district_id": "352319",
    "label": "Kedungharjo",
    "value": "Kedungharjo"
  },
  {
    "id": "3523192015",
    "district_id": "352319",
    "label": "Kujung",
    "value": "Kujung"
  },
  {
    "id": "3523192016",
    "district_id": "352319",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "3523202001",
    "district_id": "352320",
    "label": "Ngarum",
    "value": "Ngarum"
  },
  {
    "id": "3523202002",
    "district_id": "352320",
    "label": "Ngrejeng",
    "value": "Ngrejeng"
  },
  {
    "id": "3523202003",
    "district_id": "352320",
    "label": "Grabagan",
    "value": "Grabagan"
  },
  {
    "id": "3523202004",
    "district_id": "352320",
    "label": "Waleran",
    "value": "Waleran"
  },
  {
    "id": "3523202005",
    "district_id": "352320",
    "label": "Banyubang",
    "value": "Banyubang"
  },
  {
    "id": "3523202006",
    "district_id": "352320",
    "label": "Gesikan",
    "value": "Gesikan"
  },
  {
    "id": "3523202007",
    "district_id": "352320",
    "label": "Ngandong",
    "value": "Ngandong"
  },
  {
    "id": "3523202008",
    "district_id": "352320",
    "label": "Dahor",
    "value": "Dahor"
  },
  {
    "id": "3523202009",
    "district_id": "352320",
    "label": "Dermawuharjo",
    "value": "Dermawuharjo"
  },
  {
    "id": "3523202010",
    "district_id": "352320",
    "label": "Menyunyur",
    "value": "Menyunyur"
  },
  {
    "id": "3523202011",
    "district_id": "352320",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3524012001",
    "district_id": "352401",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3524012002",
    "district_id": "352401",
    "label": "Banggle",
    "value": "Banggle"
  },
  {
    "id": "3524012003",
    "district_id": "352401",
    "label": "Kedungkumpul",
    "value": "Kedungkumpul"
  },
  {
    "id": "3524012004",
    "district_id": "352401",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3524012005",
    "district_id": "352401",
    "label": "Mragel",
    "value": "Mragel"
  },
  {
    "id": "3524012006",
    "district_id": "352401",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3524012007",
    "district_id": "352401",
    "label": "Sewor",
    "value": "Sewor"
  },
  {
    "id": "3524012008",
    "district_id": "352401",
    "label": "Wedoro",
    "value": "Wedoro"
  },
  {
    "id": "3524012009",
    "district_id": "352401",
    "label": "Pendowokumpul",
    "value": "Pendowokumpul"
  },
  {
    "id": "3524022001",
    "district_id": "352402",
    "label": "Talunrejo",
    "value": "Talunrejo"
  },
  {
    "id": "3524022002",
    "district_id": "352402",
    "label": "Banjargondang",
    "value": "Banjargondang"
  },
  {
    "id": "3524022003",
    "district_id": "352402",
    "label": "Bluluk",
    "value": "Bluluk"
  },
  {
    "id": "3524022004",
    "district_id": "352402",
    "label": "Cangkring",
    "value": "Cangkring"
  },
  {
    "id": "3524022005",
    "district_id": "352402",
    "label": "Bronjong",
    "value": "Bronjong"
  },
  {
    "id": "3524022006",
    "district_id": "352402",
    "label": "Songowareng",
    "value": "Songowareng"
  },
  {
    "id": "3524022007",
    "district_id": "352402",
    "label": "Kuwurejo",
    "value": "Kuwurejo"
  },
  {
    "id": "3524022008",
    "district_id": "352402",
    "label": "Sumberbanjar",
    "value": "Sumberbanjar"
  },
  {
    "id": "3524022009",
    "district_id": "352402",
    "label": "Primpen",
    "value": "Primpen"
  },
  {
    "id": "3524032001",
    "district_id": "352403",
    "label": "Jegreg",
    "value": "Jegreg"
  },
  {
    "id": "3524032002",
    "district_id": "352403",
    "label": "Kedungpengaron",
    "value": "Kedungpengaron"
  },
  {
    "id": "3524032003",
    "district_id": "352403",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3524032004",
    "district_id": "352403",
    "label": "Kedunglerep",
    "value": "Kedunglerep"
  },
  {
    "id": "3524032005",
    "district_id": "352403",
    "label": "Jatipayak",
    "value": "Jatipayak"
  },
  {
    "id": "3524032006",
    "district_id": "352403",
    "label": "Kedungwaras",
    "value": "Kedungwaras"
  },
  {
    "id": "3524032007",
    "district_id": "352403",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3524032008",
    "district_id": "352403",
    "label": "Sambangrejo",
    "value": "Sambangrejo"
  },
  {
    "id": "3524032009",
    "district_id": "352403",
    "label": "Medalem",
    "value": "Medalem"
  },
  {
    "id": "3524032010",
    "district_id": "352403",
    "label": "Sidodowo",
    "value": "Sidodowo"
  },
  {
    "id": "3524032011",
    "district_id": "352403",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3524032012",
    "district_id": "352403",
    "label": "Sambungrejo",
    "value": "Sambungrejo"
  },
  {
    "id": "3524032013",
    "district_id": "352403",
    "label": "Kedungrejo",
    "value": "Kedungrejo"
  },
  {
    "id": "3524032014",
    "district_id": "352403",
    "label": "Kacangan",
    "value": "Kacangan"
  },
  {
    "id": "3524032015",
    "district_id": "352403",
    "label": "Nguwok",
    "value": "Nguwok"
  },
  {
    "id": "3524032016",
    "district_id": "352403",
    "label": "Pule",
    "value": "Pule"
  },
  {
    "id": "3524032017",
    "district_id": "352403",
    "label": "Yungyang",
    "value": "Yungyang"
  },
  {
    "id": "3524042001",
    "district_id": "352404",
    "label": "Kedungmentawar",
    "value": "Kedungmentawar"
  },
  {
    "id": "3524042002",
    "district_id": "352404",
    "label": "Cerme",
    "value": "Cerme"
  },
  {
    "id": "3524042003",
    "district_id": "352404",
    "label": "Gebangangkrik",
    "value": "Gebangangkrik"
  },
  {
    "id": "3524042004",
    "district_id": "352404",
    "label": "Ngasemlemahbang",
    "value": "Ngasemlemahbang"
  },
  {
    "id": "3524042005",
    "district_id": "352404",
    "label": "Slaharwotan",
    "value": "Slaharwotan"
  },
  {
    "id": "3524042006",
    "district_id": "352404",
    "label": "Ganggangtingan",
    "value": "Ganggangtingan"
  },
  {
    "id": "3524042007",
    "district_id": "352404",
    "label": "Jejel",
    "value": "Jejel"
  },
  {
    "id": "3524042008",
    "district_id": "352404",
    "label": "Purwokerto",
    "value": "Purwokerto"
  },
  {
    "id": "3524042009",
    "district_id": "352404",
    "label": "Kakatpenjalin",
    "value": "Kakatpenjalin"
  },
  {
    "id": "3524042010",
    "district_id": "352404",
    "label": "Tlemang",
    "value": "Tlemang"
  },
  {
    "id": "3524042011",
    "district_id": "352404",
    "label": "Mendogo",
    "value": "Mendogo"
  },
  {
    "id": "3524042012",
    "district_id": "352404",
    "label": "Lawak",
    "value": "Lawak"
  },
  {
    "id": "3524042013",
    "district_id": "352404",
    "label": "Drujugurit",
    "value": "Drujugurit"
  },
  {
    "id": "3524042014",
    "district_id": "352404",
    "label": "Girik",
    "value": "Girik"
  },
  {
    "id": "3524042015",
    "district_id": "352404",
    "label": "Munungrejo",
    "value": "Munungrejo"
  },
  {
    "id": "3524042016",
    "district_id": "352404",
    "label": "Ngimbang",
    "value": "Ngimbang"
  },
  {
    "id": "3524042017",
    "district_id": "352404",
    "label": "Durikedungjero",
    "value": "Durikedungjero"
  },
  {
    "id": "3524042018",
    "district_id": "352404",
    "label": "Lamongrejo",
    "value": "Lamongrejo"
  },
  {
    "id": "3524042019",
    "district_id": "352404",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3524051001",
    "district_id": "352405",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3524051003",
    "district_id": "352405",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "3524052002",
    "district_id": "352405",
    "label": "Karangkembang",
    "value": "Karangkembang"
  },
  {
    "id": "3524052004",
    "district_id": "352405",
    "label": "Pucakwangi",
    "value": "Pucakwangi"
  },
  {
    "id": "3524052005",
    "district_id": "352405",
    "label": "Sogo",
    "value": "Sogo"
  },
  {
    "id": "3524052006",
    "district_id": "352405",
    "label": "Bedahan",
    "value": "Bedahan"
  },
  {
    "id": "3524052007",
    "district_id": "352405",
    "label": "Truni",
    "value": "Truni"
  },
  {
    "id": "3524052008",
    "district_id": "352405",
    "label": "Gendongkulon",
    "value": "Gendongkulon"
  },
  {
    "id": "3524052009",
    "district_id": "352405",
    "label": "Plaosan",
    "value": "Plaosan"
  },
  {
    "id": "3524052010",
    "district_id": "352405",
    "label": "Trepan",
    "value": "Trepan"
  },
  {
    "id": "3524052011",
    "district_id": "352405",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "3524052012",
    "district_id": "352405",
    "label": "Kebalanpelang",
    "value": "Kebalanpelang"
  },
  {
    "id": "3524052013",
    "district_id": "352405",
    "label": "Sumurgenuk",
    "value": "Sumurgenuk"
  },
  {
    "id": "3524052014",
    "district_id": "352405",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "3524052015",
    "district_id": "352405",
    "label": "Bulumargi",
    "value": "Bulumargi"
  },
  {
    "id": "3524052016",
    "district_id": "352405",
    "label": "Datinawong",
    "value": "Datinawong"
  },
  {
    "id": "3524052017",
    "district_id": "352405",
    "label": "Kebalandono",
    "value": "Kebalandono"
  },
  {
    "id": "3524052018",
    "district_id": "352405",
    "label": "Patihan",
    "value": "Patihan"
  },
  {
    "id": "3524052019",
    "district_id": "352405",
    "label": "Moropelang",
    "value": "Moropelang"
  },
  {
    "id": "3524052020",
    "district_id": "352405",
    "label": "Keyongan",
    "value": "Keyongan"
  },
  {
    "id": "3524052021",
    "district_id": "352405",
    "label": "Sambangan",
    "value": "Sambangan"
  },
  {
    "id": "3524052022",
    "district_id": "352405",
    "label": "Tritunggal",
    "value": "Tritunggal"
  },
  {
    "id": "3524052023",
    "district_id": "352405",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3524062001",
    "district_id": "352406",
    "label": "Dradahblumbang",
    "value": "Dradahblumbang"
  },
  {
    "id": "3524062002",
    "district_id": "352406",
    "label": "Kalen",
    "value": "Kalen"
  },
  {
    "id": "3524062003",
    "district_id": "352406",
    "label": "Mojodadi",
    "value": "Mojodadi"
  },
  {
    "id": "3524062004",
    "district_id": "352406",
    "label": "Gunungrejo",
    "value": "Gunungrejo"
  },
  {
    "id": "3524062005",
    "district_id": "352406",
    "label": "Warungring",
    "value": "Warungring"
  },
  {
    "id": "3524062006",
    "district_id": "352406",
    "label": "Jatidrojog",
    "value": "Jatidrojog"
  },
  {
    "id": "3524062007",
    "district_id": "352406",
    "label": "Kradenanrejo",
    "value": "Kradenanrejo"
  },
  {
    "id": "3524062008",
    "district_id": "352406",
    "label": "Kandangrejo",
    "value": "Kandangrejo"
  },
  {
    "id": "3524062009",
    "district_id": "352406",
    "label": "Kedungpring",
    "value": "Kedungpring"
  },
  {
    "id": "3524062010",
    "district_id": "352406",
    "label": "Tlanak",
    "value": "Tlanak"
  },
  {
    "id": "3524062011",
    "district_id": "352406",
    "label": "Sukomalo",
    "value": "Sukomalo"
  },
  {
    "id": "3524062012",
    "district_id": "352406",
    "label": "Mlati",
    "value": "Mlati"
  },
  {
    "id": "3524062013",
    "district_id": "352406",
    "label": "Karangcangkring",
    "value": "Karangcangkring"
  },
  {
    "id": "3524062014",
    "district_id": "352406",
    "label": "Mekanderejo",
    "value": "Mekanderejo"
  },
  {
    "id": "3524062015",
    "district_id": "352406",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3524062016",
    "district_id": "352406",
    "label": "Sidobangun",
    "value": "Sidobangun"
  },
  {
    "id": "3524062017",
    "district_id": "352406",
    "label": "Blawirejo",
    "value": "Blawirejo"
  },
  {
    "id": "3524062018",
    "district_id": "352406",
    "label": "Maindu",
    "value": "Maindu"
  },
  {
    "id": "3524062019",
    "district_id": "352406",
    "label": "Tenggerejo",
    "value": "Tenggerejo"
  },
  {
    "id": "3524062020",
    "district_id": "352406",
    "label": "Majenang",
    "value": "Majenang"
  },
  {
    "id": "3524062021",
    "district_id": "352406",
    "label": "Sidomlangean",
    "value": "Sidomlangean"
  },
  {
    "id": "3524062022",
    "district_id": "352406",
    "label": "Nglebur",
    "value": "Nglebur"
  },
  {
    "id": "3524062023",
    "district_id": "352406",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3524071010",
    "district_id": "352407",
    "label": "Brondong",
    "value": "Brondong"
  },
  {
    "id": "3524072001",
    "district_id": "352407",
    "label": "Lohgung",
    "value": "Lohgung"
  },
  {
    "id": "3524072002",
    "district_id": "352407",
    "label": "Labuhan",
    "value": "Labuhan"
  },
  {
    "id": "3524072003",
    "district_id": "352407",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3524072004",
    "district_id": "352407",
    "label": "Brengkok",
    "value": "Brengkok"
  },
  {
    "id": "3524072005",
    "district_id": "352407",
    "label": "Tlogoretno",
    "value": "Tlogoretno"
  },
  {
    "id": "3524072006",
    "district_id": "352407",
    "label": "Sendangharjo",
    "value": "Sendangharjo"
  },
  {
    "id": "3524072007",
    "district_id": "352407",
    "label": "Lembor",
    "value": "Lembor"
  },
  {
    "id": "3524072008",
    "district_id": "352407",
    "label": "Sedayulawas",
    "value": "Sedayulawas"
  },
  {
    "id": "3524072009",
    "district_id": "352407",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3524082001",
    "district_id": "352408",
    "label": "Dateng",
    "value": "Dateng"
  },
  {
    "id": "3524082002",
    "district_id": "352408",
    "label": "Jabung",
    "value": "Jabung"
  },
  {
    "id": "3524082003",
    "district_id": "352408",
    "label": "Keduyung",
    "value": "Keduyung"
  },
  {
    "id": "3524082004",
    "district_id": "352408",
    "label": "Centini",
    "value": "Centini"
  },
  {
    "id": "3524082005",
    "district_id": "352408",
    "label": "Durikulon",
    "value": "Durikulon"
  },
  {
    "id": "3524082006",
    "district_id": "352408",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3524082007",
    "district_id": "352408",
    "label": "Gelap",
    "value": "Gelap"
  },
  {
    "id": "3524082008",
    "district_id": "352408",
    "label": "Mojoasem",
    "value": "Mojoasem"
  },
  {
    "id": "3524082009",
    "district_id": "352408",
    "label": "Siser",
    "value": "Siser"
  },
  {
    "id": "3524082010",
    "district_id": "352408",
    "label": "Bulutigo",
    "value": "Bulutigo"
  },
  {
    "id": "3524082011",
    "district_id": "352408",
    "label": "Pelangwot",
    "value": "Pelangwot"
  },
  {
    "id": "3524082012",
    "district_id": "352408",
    "label": "Laren",
    "value": "Laren"
  },
  {
    "id": "3524082013",
    "district_id": "352408",
    "label": "Gampangsejati",
    "value": "Gampangsejati"
  },
  {
    "id": "3524082014",
    "district_id": "352408",
    "label": "Tamanprijeg",
    "value": "Tamanprijeg"
  },
  {
    "id": "3524082015",
    "district_id": "352408",
    "label": "Karangtawar",
    "value": "Karangtawar"
  },
  {
    "id": "3524082016",
    "district_id": "352408",
    "label": "Tejoasri",
    "value": "Tejoasri"
  },
  {
    "id": "3524082017",
    "district_id": "352408",
    "label": "Godog",
    "value": "Godog"
  },
  {
    "id": "3524082018",
    "district_id": "352408",
    "label": "Bulubrangsi",
    "value": "Bulubrangsi"
  },
  {
    "id": "3524082019",
    "district_id": "352408",
    "label": "Karangwungulor",
    "value": "Karangwungulor"
  },
  {
    "id": "3524082020",
    "district_id": "352408",
    "label": "Brangsi",
    "value": "Brangsi"
  },
  {
    "id": "3524092001",
    "district_id": "352409",
    "label": "Besur",
    "value": "Besur"
  },
  {
    "id": "3524092002",
    "district_id": "352409",
    "label": "Titik",
    "value": "Titik"
  },
  {
    "id": "3524092003",
    "district_id": "352409",
    "label": "Kendal",
    "value": "Kendal"
  },
  {
    "id": "3524092004",
    "district_id": "352409",
    "label": "Keting",
    "value": "Keting"
  },
  {
    "id": "3524092005",
    "district_id": "352409",
    "label": "Ngarum",
    "value": "Ngarum"
  },
  {
    "id": "3524092006",
    "district_id": "352409",
    "label": "Kebalankulon",
    "value": "Kebalankulon"
  },
  {
    "id": "3524092007",
    "district_id": "352409",
    "label": "Kudikan",
    "value": "Kudikan"
  },
  {
    "id": "3524092008",
    "district_id": "352409",
    "label": "Jugo",
    "value": "Jugo"
  },
  {
    "id": "3524092009",
    "district_id": "352409",
    "label": "Manyar",
    "value": "Manyar"
  },
  {
    "id": "3524092010",
    "district_id": "352409",
    "label": "Trosono",
    "value": "Trosono"
  },
  {
    "id": "3524092011",
    "district_id": "352409",
    "label": "Latek",
    "value": "Latek"
  },
  {
    "id": "3524092012",
    "district_id": "352409",
    "label": "Miru",
    "value": "Miru"
  },
  {
    "id": "3524092013",
    "district_id": "352409",
    "label": "Sekaran",
    "value": "Sekaran"
  },
  {
    "id": "3524092014",
    "district_id": "352409",
    "label": "Moro",
    "value": "Moro"
  },
  {
    "id": "3524092015",
    "district_id": "352409",
    "label": "Karang",
    "value": "Karang"
  },
  {
    "id": "3524092016",
    "district_id": "352409",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3524092017",
    "district_id": "352409",
    "label": "Siman",
    "value": "Siman"
  },
  {
    "id": "3524092018",
    "district_id": "352409",
    "label": "Bulutengger",
    "value": "Bulutengger"
  },
  {
    "id": "3524092019",
    "district_id": "352409",
    "label": "Porodeso",
    "value": "Porodeso"
  },
  {
    "id": "3524092020",
    "district_id": "352409",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3524092021",
    "district_id": "352409",
    "label": "Sungegeneng",
    "value": "Sungegeneng"
  },
  {
    "id": "3524102001",
    "district_id": "352410",
    "label": "Duriwetan",
    "value": "Duriwetan"
  },
  {
    "id": "3524102002",
    "district_id": "352410",
    "label": "Taji",
    "value": "Taji"
  },
  {
    "id": "3524102003",
    "district_id": "352410",
    "label": "Brumbun",
    "value": "Brumbun"
  },
  {
    "id": "3524102004",
    "district_id": "352410",
    "label": "Siwuran",
    "value": "Siwuran"
  },
  {
    "id": "3524102005",
    "district_id": "352410",
    "label": "Klagensrampat",
    "value": "Klagensrampat"
  },
  {
    "id": "3524102006",
    "district_id": "352410",
    "label": "Pangean",
    "value": "Pangean"
  },
  {
    "id": "3524102007",
    "district_id": "352410",
    "label": "Maduran",
    "value": "Maduran"
  },
  {
    "id": "3524102008",
    "district_id": "352410",
    "label": "Jangkungsomo",
    "value": "Jangkungsomo"
  },
  {
    "id": "3524102009",
    "district_id": "352410",
    "label": "Parengan",
    "value": "Parengan"
  },
  {
    "id": "3524102010",
    "district_id": "352410",
    "label": "Pangkatrejo",
    "value": "Pangkatrejo"
  },
  {
    "id": "3524102011",
    "district_id": "352410",
    "label": "Kanugrahan",
    "value": "Kanugrahan"
  },
  {
    "id": "3524102012",
    "district_id": "352410",
    "label": "Gumantuk",
    "value": "Gumantuk"
  },
  {
    "id": "3524102013",
    "district_id": "352410",
    "label": "Ngayung",
    "value": "Ngayung"
  },
  {
    "id": "3524102014",
    "district_id": "352410",
    "label": "Pringgoboyo",
    "value": "Pringgoboyo"
  },
  {
    "id": "3524102015",
    "district_id": "352410",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3524102016",
    "district_id": "352410",
    "label": "Turi",
    "value": "Turi"
  },
  {
    "id": "3524102017",
    "district_id": "352410",
    "label": "Blumbang",
    "value": "Blumbang"
  },
  {
    "id": "3524112001",
    "district_id": "352411",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3524112002",
    "district_id": "352411",
    "label": "Pasarlegi",
    "value": "Pasarlegi"
  },
  {
    "id": "3524112003",
    "district_id": "352411",
    "label": "Semampirejo",
    "value": "Semampirejo"
  },
  {
    "id": "3524112004",
    "district_id": "352411",
    "label": "Wateswinangun",
    "value": "Wateswinangun"
  },
  {
    "id": "3524112005",
    "district_id": "352411",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3524112006",
    "district_id": "352411",
    "label": "Pataan",
    "value": "Pataan"
  },
  {
    "id": "3524112007",
    "district_id": "352411",
    "label": "Tenggiring",
    "value": "Tenggiring"
  },
  {
    "id": "3524112008",
    "district_id": "352411",
    "label": "Garung",
    "value": "Garung"
  },
  {
    "id": "3524112009",
    "district_id": "352411",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3524112010",
    "district_id": "352411",
    "label": "Ardirejo",
    "value": "Ardirejo"
  },
  {
    "id": "3524112011",
    "district_id": "352411",
    "label": "Sekidang",
    "value": "Sekidang"
  },
  {
    "id": "3524112012",
    "district_id": "352411",
    "label": "Kedungwangi",
    "value": "Kedungwangi"
  },
  {
    "id": "3524112013",
    "district_id": "352411",
    "label": "Jatipandak",
    "value": "Jatipandak"
  },
  {
    "id": "3524112014",
    "district_id": "352411",
    "label": "Candisari",
    "value": "Candisari"
  },
  {
    "id": "3524112015",
    "district_id": "352411",
    "label": "Nogojatisari",
    "value": "Nogojatisari"
  },
  {
    "id": "3524112016",
    "district_id": "352411",
    "label": "Pamotan",
    "value": "Pamotan"
  },
  {
    "id": "3524112017",
    "district_id": "352411",
    "label": "Wudi",
    "value": "Wudi"
  },
  {
    "id": "3524112018",
    "district_id": "352411",
    "label": "Barurejo",
    "value": "Barurejo"
  },
  {
    "id": "3524112019",
    "district_id": "352411",
    "label": "Kedungbanjar",
    "value": "Kedungbanjar"
  },
  {
    "id": "3524112020",
    "district_id": "352411",
    "label": "Gempolmanis",
    "value": "Gempolmanis"
  },
  {
    "id": "3524112021",
    "district_id": "352411",
    "label": "Selorejo",
    "value": "Selorejo"
  },
  {
    "id": "3524112022",
    "district_id": "352411",
    "label": "Kreteranggon",
    "value": "Kreteranggon"
  },
  {
    "id": "3524122001",
    "district_id": "352412",
    "label": "Sidobogem",
    "value": "Sidobogem"
  },
  {
    "id": "3524122002",
    "district_id": "352412",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3524122003",
    "district_id": "352412",
    "label": "Kedungdadi",
    "value": "Kedungdadi"
  },
  {
    "id": "3524122004",
    "district_id": "352412",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3524122005",
    "district_id": "352412",
    "label": "Kedungbanjar",
    "value": "Kedungbanjar"
  },
  {
    "id": "3524122006",
    "district_id": "352412",
    "label": "Bedingin",
    "value": "Bedingin"
  },
  {
    "id": "3524122007",
    "district_id": "352412",
    "label": "Bakalrejo",
    "value": "Bakalrejo"
  },
  {
    "id": "3524122008",
    "district_id": "352412",
    "label": "Gondanglor",
    "value": "Gondanglor"
  },
  {
    "id": "3524122009",
    "district_id": "352412",
    "label": "Pangkatrejo",
    "value": "Pangkatrejo"
  },
  {
    "id": "3524122010",
    "district_id": "352412",
    "label": "Karangsambigalih",
    "value": "Karangsambigalih"
  },
  {
    "id": "3524122011",
    "district_id": "352412",
    "label": "Supenuh",
    "value": "Supenuh"
  },
  {
    "id": "3524122012",
    "district_id": "352412",
    "label": "Lebakadi",
    "value": "Lebakadi"
  },
  {
    "id": "3524122013",
    "district_id": "352412",
    "label": "Deketagung",
    "value": "Deketagung"
  },
  {
    "id": "3524122014",
    "district_id": "352412",
    "label": "Jubellor",
    "value": "Jubellor"
  },
  {
    "id": "3524122015",
    "district_id": "352412",
    "label": "Sugio",
    "value": "Sugio"
  },
  {
    "id": "3524122016",
    "district_id": "352412",
    "label": "Daliwangun",
    "value": "Daliwangun"
  },
  {
    "id": "3524122017",
    "district_id": "352412",
    "label": "Jubelkidul",
    "value": "Jubelkidul"
  },
  {
    "id": "3524122018",
    "district_id": "352412",
    "label": "Lawanganagung",
    "value": "Lawanganagung"
  },
  {
    "id": "3524122019",
    "district_id": "352412",
    "label": "Kalipang",
    "value": "Kalipang"
  },
  {
    "id": "3524122020",
    "district_id": "352412",
    "label": "German",
    "value": "German"
  },
  {
    "id": "3524122021",
    "district_id": "352412",
    "label": "Sekarbagus",
    "value": "Sekarbagus"
  },
  {
    "id": "3524132001",
    "district_id": "352413",
    "label": "Pucuk",
    "value": "Pucuk"
  },
  {
    "id": "3524132002",
    "district_id": "352413",
    "label": "Gempolpading",
    "value": "Gempolpading"
  },
  {
    "id": "3524132003",
    "district_id": "352413",
    "label": "Kesambi",
    "value": "Kesambi"
  },
  {
    "id": "3524132004",
    "district_id": "352413",
    "label": "Plososetro",
    "value": "Plososetro"
  },
  {
    "id": "3524132005",
    "district_id": "352413",
    "label": "Wanar",
    "value": "Wanar"
  },
  {
    "id": "3524132006",
    "district_id": "352413",
    "label": "Karangtinggil",
    "value": "Karangtinggil"
  },
  {
    "id": "3524132007",
    "district_id": "352413",
    "label": "Warukulon",
    "value": "Warukulon"
  },
  {
    "id": "3524132008",
    "district_id": "352413",
    "label": "Waruwetan",
    "value": "Waruwetan"
  },
  {
    "id": "3524132009",
    "district_id": "352413",
    "label": "Kedali",
    "value": "Kedali"
  },
  {
    "id": "3524132010",
    "district_id": "352413",
    "label": "Paji",
    "value": "Paji"
  },
  {
    "id": "3524132011",
    "district_id": "352413",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3524132012",
    "district_id": "352413",
    "label": "Cungkup",
    "value": "Cungkup"
  },
  {
    "id": "3524132013",
    "district_id": "352413",
    "label": "Bugoharjo",
    "value": "Bugoharjo"
  },
  {
    "id": "3524132014",
    "district_id": "352413",
    "label": "Ngambeg",
    "value": "Ngambeg"
  },
  {
    "id": "3524132015",
    "district_id": "352413",
    "label": "Babatkumpul",
    "value": "Babatkumpul"
  },
  {
    "id": "3524132016",
    "district_id": "352413",
    "label": "Tanggungan",
    "value": "Tanggungan"
  },
  {
    "id": "3524132017",
    "district_id": "352413",
    "label": "Padenganploso",
    "value": "Padenganploso"
  },
  {
    "id": "3524141001",
    "district_id": "352414",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3524142002",
    "district_id": "352414",
    "label": "Kandangsemangkon",
    "value": "Kandangsemangkon"
  },
  {
    "id": "3524142003",
    "district_id": "352414",
    "label": "Paciran",
    "value": "Paciran"
  },
  {
    "id": "3524142004",
    "district_id": "352414",
    "label": "Sumurgayam",
    "value": "Sumurgayam"
  },
  {
    "id": "3524142005",
    "district_id": "352414",
    "label": "Sendangagung",
    "value": "Sendangagung"
  },
  {
    "id": "3524142006",
    "district_id": "352414",
    "label": "Sendangduwur",
    "value": "Sendangduwur"
  },
  {
    "id": "3524142007",
    "district_id": "352414",
    "label": "Tunggul",
    "value": "Tunggul"
  },
  {
    "id": "3524142008",
    "district_id": "352414",
    "label": "Kranji",
    "value": "Kranji"
  },
  {
    "id": "3524142009",
    "district_id": "352414",
    "label": "Drajat",
    "value": "Drajat"
  },
  {
    "id": "3524142010",
    "district_id": "352414",
    "label": "Banjarwati",
    "value": "Banjarwati"
  },
  {
    "id": "3524142011",
    "district_id": "352414",
    "label": "Kemantren",
    "value": "Kemantren"
  },
  {
    "id": "3524142012",
    "district_id": "352414",
    "label": "Sidokelar",
    "value": "Sidokelar"
  },
  {
    "id": "3524142013",
    "district_id": "352414",
    "label": "Tlogosadang",
    "value": "Tlogosadang"
  },
  {
    "id": "3524142014",
    "district_id": "352414",
    "label": "Paloh",
    "value": "Paloh"
  },
  {
    "id": "3524142015",
    "district_id": "352414",
    "label": "Weru",
    "value": "Weru"
  },
  {
    "id": "3524142016",
    "district_id": "352414",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3524142017",
    "district_id": "352414",
    "label": "Warulor",
    "value": "Warulor"
  },
  {
    "id": "3524152001",
    "district_id": "352415",
    "label": "Dadapan",
    "value": "Dadapan"
  },
  {
    "id": "3524152002",
    "district_id": "352415",
    "label": "Tebluru",
    "value": "Tebluru"
  },
  {
    "id": "3524152003",
    "district_id": "352415",
    "label": "Sugihan",
    "value": "Sugihan"
  },
  {
    "id": "3524152004",
    "district_id": "352415",
    "label": "Tenggulun",
    "value": "Tenggulun"
  },
  {
    "id": "3524152005",
    "district_id": "352415",
    "label": "Payaman",
    "value": "Payaman"
  },
  {
    "id": "3524152006",
    "district_id": "352415",
    "label": "Solokuro",
    "value": "Solokuro"
  },
  {
    "id": "3524152007",
    "district_id": "352415",
    "label": "Takerharjo",
    "value": "Takerharjo"
  },
  {
    "id": "3524152008",
    "district_id": "352415",
    "label": "Dagan",
    "value": "Dagan"
  },
  {
    "id": "3524152009",
    "district_id": "352415",
    "label": "Banyubang",
    "value": "Banyubang"
  },
  {
    "id": "3524152010",
    "district_id": "352415",
    "label": "Bluri",
    "value": "Bluri"
  },
  {
    "id": "3524162001",
    "district_id": "352416",
    "label": "Sukobendu",
    "value": "Sukobendu"
  },
  {
    "id": "3524162002",
    "district_id": "352416",
    "label": "Tunggunjagir",
    "value": "Tunggunjagir"
  },
  {
    "id": "3524162003",
    "district_id": "352416",
    "label": "Sumberbendo",
    "value": "Sumberbendo"
  },
  {
    "id": "3524162004",
    "district_id": "352416",
    "label": "Mantup",
    "value": "Mantup"
  },
  {
    "id": "3524162005",
    "district_id": "352416",
    "label": "Kedukbembem",
    "value": "Kedukbembem"
  },
  {
    "id": "3524162006",
    "district_id": "352416",
    "label": "Sumberdadi",
    "value": "Sumberdadi"
  },
  {
    "id": "3524162007",
    "district_id": "352416",
    "label": "Kedungsoko",
    "value": "Kedungsoko"
  },
  {
    "id": "3524162008",
    "district_id": "352416",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3524162009",
    "district_id": "352416",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3524162010",
    "district_id": "352416",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3524162011",
    "district_id": "352416",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3524162012",
    "district_id": "352416",
    "label": "Mojosari",
    "value": "Mojosari"
  },
  {
    "id": "3524162013",
    "district_id": "352416",
    "label": "Plabuhanrejo",
    "value": "Plabuhanrejo"
  },
  {
    "id": "3524162014",
    "district_id": "352416",
    "label": "Sumberkerep",
    "value": "Sumberkerep"
  },
  {
    "id": "3524162015",
    "district_id": "352416",
    "label": "Rumpuk",
    "value": "Rumpuk"
  },
  {
    "id": "3524172001",
    "district_id": "352417",
    "label": "Siwalanrejo",
    "value": "Siwalanrejo"
  },
  {
    "id": "3524172002",
    "district_id": "352417",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3524172003",
    "district_id": "352417",
    "label": "Sukolilo",
    "value": "Sukolilo"
  },
  {
    "id": "3524172004",
    "district_id": "352417",
    "label": "Pajangan",
    "value": "Pajangan"
  },
  {
    "id": "3524172005",
    "district_id": "352417",
    "label": "Kadungrembug",
    "value": "Kadungrembug"
  },
  {
    "id": "3524172006",
    "district_id": "352417",
    "label": "Sumberagung",
    "value": "Sumberagung"
  },
  {
    "id": "3524172007",
    "district_id": "352417",
    "label": "Sukodadi",
    "value": "Sukodadi"
  },
  {
    "id": "3524172008",
    "district_id": "352417",
    "label": "Sumberaji",
    "value": "Sumberaji"
  },
  {
    "id": "3524172009",
    "district_id": "352417",
    "label": "Menongo",
    "value": "Menongo"
  },
  {
    "id": "3524172010",
    "district_id": "352417",
    "label": "Madulegi",
    "value": "Madulegi"
  },
  {
    "id": "3524172011",
    "district_id": "352417",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3524172012",
    "district_id": "352417",
    "label": "Plumpang",
    "value": "Plumpang"
  },
  {
    "id": "3524172013",
    "district_id": "352417",
    "label": "Bandungsari",
    "value": "Bandungsari"
  },
  {
    "id": "3524172014",
    "district_id": "352417",
    "label": "Sidogembul",
    "value": "Sidogembul"
  },
  {
    "id": "3524172015",
    "district_id": "352417",
    "label": "Balongtawun",
    "value": "Balongtawun"
  },
  {
    "id": "3524172016",
    "district_id": "352417",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3524172017",
    "district_id": "352417",
    "label": "Tlogorejo",
    "value": "Tlogorejo"
  },
  {
    "id": "3524172018",
    "district_id": "352417",
    "label": "Baturono",
    "value": "Baturono"
  },
  {
    "id": "3524172019",
    "district_id": "352417",
    "label": "Surabayan",
    "value": "Surabayan"
  },
  {
    "id": "3524172020",
    "district_id": "352417",
    "label": "Sugihrejo",
    "value": "Sugihrejo"
  },
  {
    "id": "3524182001",
    "district_id": "352418",
    "label": "Bantengputih",
    "value": "Bantengputih"
  },
  {
    "id": "3524182002",
    "district_id": "352418",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3524182003",
    "district_id": "352418",
    "label": "Latukan",
    "value": "Latukan"
  },
  {
    "id": "3524182004",
    "district_id": "352418",
    "label": "Guci",
    "value": "Guci"
  },
  {
    "id": "3524182005",
    "district_id": "352418",
    "label": "Kaligerman",
    "value": "Kaligerman"
  },
  {
    "id": "3524182006",
    "district_id": "352418",
    "label": "Sungelebak",
    "value": "Sungelebak"
  },
  {
    "id": "3524182007",
    "district_id": "352418",
    "label": "Prijekngablak",
    "value": "Prijekngablak"
  },
  {
    "id": "3524182008",
    "district_id": "352418",
    "label": "Tracal",
    "value": "Tracal"
  },
  {
    "id": "3524182009",
    "district_id": "352418",
    "label": "Sonoadi",
    "value": "Sonoadi"
  },
  {
    "id": "3524182010",
    "district_id": "352418",
    "label": "Kalanganyar",
    "value": "Kalanganyar"
  },
  {
    "id": "3524182011",
    "district_id": "352418",
    "label": "Banjarmadu",
    "value": "Banjarmadu"
  },
  {
    "id": "3524182012",
    "district_id": "352418",
    "label": "Kendalkemlagi",
    "value": "Kendalkemlagi"
  },
  {
    "id": "3524182013",
    "district_id": "352418",
    "label": "Kawistolegi",
    "value": "Kawistolegi"
  },
  {
    "id": "3524182014",
    "district_id": "352418",
    "label": "Jagran",
    "value": "Jagran"
  },
  {
    "id": "3524182015",
    "district_id": "352418",
    "label": "Karangwungu",
    "value": "Karangwungu"
  },
  {
    "id": "3524182016",
    "district_id": "352418",
    "label": "Sumberwudi",
    "value": "Sumberwudi"
  },
  {
    "id": "3524182017",
    "district_id": "352418",
    "label": "Karanggeneng",
    "value": "Karanggeneng"
  },
  {
    "id": "3524182018",
    "district_id": "352418",
    "label": "Mertani",
    "value": "Mertani"
  },
  {
    "id": "3524192001",
    "district_id": "352419",
    "label": "Tlogoagung",
    "value": "Tlogoagung"
  },
  {
    "id": "3524192002",
    "district_id": "352419",
    "label": "Kedungmegarih",
    "value": "Kedungmegarih"
  },
  {
    "id": "3524192003",
    "district_id": "352419",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3524192004",
    "district_id": "352419",
    "label": "Sukosongo",
    "value": "Sukosongo"
  },
  {
    "id": "3524192005",
    "district_id": "352419",
    "label": "Kaliwates",
    "value": "Kaliwates"
  },
  {
    "id": "3524192006",
    "district_id": "352419",
    "label": "Kedungasri",
    "value": "Kedungasri"
  },
  {
    "id": "3524192007",
    "district_id": "352419",
    "label": "Doyomulyo",
    "value": "Doyomulyo"
  },
  {
    "id": "3524192008",
    "district_id": "352419",
    "label": "Kembangbahu",
    "value": "Kembangbahu"
  },
  {
    "id": "3524192009",
    "district_id": "352419",
    "label": "Maor",
    "value": "Maor"
  },
  {
    "id": "3524192010",
    "district_id": "352419",
    "label": "Moronyamplung",
    "value": "Moronyamplung"
  },
  {
    "id": "3524192011",
    "district_id": "352419",
    "label": "Mangkujajar",
    "value": "Mangkujajar"
  },
  {
    "id": "3524192012",
    "district_id": "352419",
    "label": "Puter",
    "value": "Puter"
  },
  {
    "id": "3524192013",
    "district_id": "352419",
    "label": "Pelang",
    "value": "Pelang"
  },
  {
    "id": "3524192014",
    "district_id": "352419",
    "label": "Dumpiagung",
    "value": "Dumpiagung"
  },
  {
    "id": "3524192015",
    "district_id": "352419",
    "label": "Randubener",
    "value": "Randubener"
  },
  {
    "id": "3524192016",
    "district_id": "352419",
    "label": "Lopang",
    "value": "Lopang"
  },
  {
    "id": "3524192017",
    "district_id": "352419",
    "label": "Gintungan",
    "value": "Gintungan"
  },
  {
    "id": "3524192018",
    "district_id": "352419",
    "label": "Katemas",
    "value": "Katemas"
  },
  {
    "id": "3524202001",
    "district_id": "352420",
    "label": "Pucangro",
    "value": "Pucangro"
  },
  {
    "id": "3524202002",
    "district_id": "352420",
    "label": "Pucangtelu",
    "value": "Pucangtelu"
  },
  {
    "id": "3524202003",
    "district_id": "352420",
    "label": "Somosari",
    "value": "Somosari"
  },
  {
    "id": "3524202004",
    "district_id": "352420",
    "label": "Jelakcatur",
    "value": "Jelakcatur"
  },
  {
    "id": "3524202005",
    "district_id": "352420",
    "label": "Mungli",
    "value": "Mungli"
  },
  {
    "id": "3524202006",
    "district_id": "352420",
    "label": "Pengangsalan",
    "value": "Pengangsalan"
  },
  {
    "id": "3524202007",
    "district_id": "352420",
    "label": "Dibee",
    "value": "Dibee"
  },
  {
    "id": "3524202008",
    "district_id": "352420",
    "label": "Butungan",
    "value": "Butungan"
  },
  {
    "id": "3524202009",
    "district_id": "352420",
    "label": "Lukrejo",
    "value": "Lukrejo"
  },
  {
    "id": "3524202010",
    "district_id": "352420",
    "label": "Tiwet",
    "value": "Tiwet"
  },
  {
    "id": "3524202011",
    "district_id": "352420",
    "label": "Blajo",
    "value": "Blajo"
  },
  {
    "id": "3524202012",
    "district_id": "352420",
    "label": "Kalitengah",
    "value": "Kalitengah"
  },
  {
    "id": "3524202013",
    "district_id": "352420",
    "label": "Gambuhan",
    "value": "Gambuhan"
  },
  {
    "id": "3524202014",
    "district_id": "352420",
    "label": "Cluring",
    "value": "Cluring"
  },
  {
    "id": "3524202015",
    "district_id": "352420",
    "label": "Bojoasri",
    "value": "Bojoasri"
  },
  {
    "id": "3524202016",
    "district_id": "352420",
    "label": "Kediren",
    "value": "Kediren"
  },
  {
    "id": "3524202017",
    "district_id": "352420",
    "label": "Kuluran",
    "value": "Kuluran"
  },
  {
    "id": "3524202018",
    "district_id": "352420",
    "label": "Canditunggal",
    "value": "Canditunggal"
  },
  {
    "id": "3524202019",
    "district_id": "352420",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3524202020",
    "district_id": "352420",
    "label": "Tunjungmekar",
    "value": "Tunjungmekar"
  },
  {
    "id": "3524212001",
    "district_id": "352421",
    "label": "Geger",
    "value": "Geger"
  },
  {
    "id": "3524212002",
    "district_id": "352421",
    "label": "Badurame",
    "value": "Badurame"
  },
  {
    "id": "3524212003",
    "district_id": "352421",
    "label": "Karangwedoro",
    "value": "Karangwedoro"
  },
  {
    "id": "3524212004",
    "district_id": "352421",
    "label": "Wangunrejo",
    "value": "Wangunrejo"
  },
  {
    "id": "3524212005",
    "district_id": "352421",
    "label": "Putatkumpul",
    "value": "Putatkumpul"
  },
  {
    "id": "3524212006",
    "district_id": "352421",
    "label": "Keben",
    "value": "Keben"
  },
  {
    "id": "3524212007",
    "district_id": "352421",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3524212008",
    "district_id": "352421",
    "label": "Kemlagigede",
    "value": "Kemlagigede"
  },
  {
    "id": "3524212009",
    "district_id": "352421",
    "label": "Turi",
    "value": "Turi"
  },
  {
    "id": "3524212010",
    "district_id": "352421",
    "label": "Kemlagilor",
    "value": "Kemlagilor"
  },
  {
    "id": "3524212011",
    "district_id": "352421",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3524212012",
    "district_id": "352421",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3524212013",
    "district_id": "352421",
    "label": "Bambang",
    "value": "Bambang"
  },
  {
    "id": "3524212014",
    "district_id": "352421",
    "label": "Pomahanjanggan",
    "value": "Pomahanjanggan"
  },
  {
    "id": "3524212015",
    "district_id": "352421",
    "label": "Tambakploso",
    "value": "Tambakploso"
  },
  {
    "id": "3524212016",
    "district_id": "352421",
    "label": "Balun",
    "value": "Balun"
  },
  {
    "id": "3524212017",
    "district_id": "352421",
    "label": "Ngujungrejo",
    "value": "Ngujungrejo"
  },
  {
    "id": "3524212018",
    "district_id": "352421",
    "label": "Kepudibener",
    "value": "Kepudibener"
  },
  {
    "id": "3524212019",
    "district_id": "352421",
    "label": "Gedungboyountung",
    "value": "Gedungboyountung"
  },
  {
    "id": "3524221012",
    "district_id": "352422",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3524221013",
    "district_id": "352422",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3524221014",
    "district_id": "352422",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "3524221015",
    "district_id": "352422",
    "label": "Tumenggungan",
    "value": "Tumenggungan"
  },
  {
    "id": "3524221017",
    "district_id": "352422",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3524221018",
    "district_id": "352422",
    "label": "Tlogoanyar",
    "value": "Tlogoanyar"
  },
  {
    "id": "3524221019",
    "district_id": "352422",
    "label": "Jetis",
    "value": "Jetis"
  },
  {
    "id": "3524221020",
    "district_id": "352422",
    "label": "Banjarmendalan",
    "value": "Banjarmendalan"
  },
  {
    "id": "3524222001",
    "district_id": "352422",
    "label": "Rancangkencono",
    "value": "Rancangkencono"
  },
  {
    "id": "3524222002",
    "district_id": "352422",
    "label": "Karanglangit",
    "value": "Karanglangit"
  },
  {
    "id": "3524222003",
    "district_id": "352422",
    "label": "Pangkatrejo",
    "value": "Pangkatrejo"
  },
  {
    "id": "3524222004",
    "district_id": "352422",
    "label": "Kebet",
    "value": "Kebet"
  },
  {
    "id": "3524222005",
    "district_id": "352422",
    "label": "Sendangrejo",
    "value": "Sendangrejo"
  },
  {
    "id": "3524222006",
    "district_id": "352422",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3524222007",
    "district_id": "352422",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3524222008",
    "district_id": "352422",
    "label": "Plosowahyu",
    "value": "Plosowahyu"
  },
  {
    "id": "3524222009",
    "district_id": "352422",
    "label": "Wajik",
    "value": "Wajik"
  },
  {
    "id": "3524222010",
    "district_id": "352422",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3524222011",
    "district_id": "352422",
    "label": "Made",
    "value": "Made"
  },
  {
    "id": "3524222016",
    "district_id": "352422",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3524232001",
    "district_id": "352423",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "3524232002",
    "district_id": "352423",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3524232003",
    "district_id": "352423",
    "label": "Guminingrejo",
    "value": "Guminingrejo"
  },
  {
    "id": "3524232004",
    "district_id": "352423",
    "label": "Bakalanpule",
    "value": "Bakalanpule"
  },
  {
    "id": "3524232005",
    "district_id": "352423",
    "label": "Takeranklating",
    "value": "Takeranklating"
  },
  {
    "id": "3524232006",
    "district_id": "352423",
    "label": "Kelorarum",
    "value": "Kelorarum"
  },
  {
    "id": "3524232007",
    "district_id": "352423",
    "label": "Jotosanur",
    "value": "Jotosanur"
  },
  {
    "id": "3524232008",
    "district_id": "352423",
    "label": "Pengumbulanadi",
    "value": "Pengumbulanadi"
  },
  {
    "id": "3524232009",
    "district_id": "352423",
    "label": "Jatirejo",
    "value": "Jatirejo"
  },
  {
    "id": "3524232010",
    "district_id": "352423",
    "label": "Dukuhagung",
    "value": "Dukuhagung"
  },
  {
    "id": "3524232011",
    "district_id": "352423",
    "label": "Tambakrigadung",
    "value": "Tambakrigadung"
  },
  {
    "id": "3524232012",
    "district_id": "352423",
    "label": "Botoputih",
    "value": "Botoputih"
  },
  {
    "id": "3524232013",
    "district_id": "352423",
    "label": "Balongwangi",
    "value": "Balongwangi"
  },
  {
    "id": "3524242001",
    "district_id": "352424",
    "label": "Waruk",
    "value": "Waruk"
  },
  {
    "id": "3524242002",
    "district_id": "352424",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3524242003",
    "district_id": "352424",
    "label": "Karanganom",
    "value": "Karanganom"
  },
  {
    "id": "3524242004",
    "district_id": "352424",
    "label": "Somowinangun",
    "value": "Somowinangun"
  },
  {
    "id": "3524242005",
    "district_id": "352424",
    "label": "Ketapangtelu",
    "value": "Ketapangtelu"
  },
  {
    "id": "3524242006",
    "district_id": "352424",
    "label": "Mayong",
    "value": "Mayong"
  },
  {
    "id": "3524242007",
    "district_id": "352424",
    "label": "Palangan",
    "value": "Palangan"
  },
  {
    "id": "3524242008",
    "district_id": "352424",
    "label": "Blawi",
    "value": "Blawi"
  },
  {
    "id": "3524242009",
    "district_id": "352424",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3524242010",
    "district_id": "352424",
    "label": "Putatbangah",
    "value": "Putatbangah"
  },
  {
    "id": "3524242011",
    "district_id": "352424",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3524242012",
    "district_id": "352424",
    "label": "Pendowolimo",
    "value": "Pendowolimo"
  },
  {
    "id": "3524242013",
    "district_id": "352424",
    "label": "Baranggayam",
    "value": "Baranggayam"
  },
  {
    "id": "3524242014",
    "district_id": "352424",
    "label": "Watangpanjang",
    "value": "Watangpanjang"
  },
  {
    "id": "3524242015",
    "district_id": "352424",
    "label": "Sambopinggir",
    "value": "Sambopinggir"
  },
  {
    "id": "3524242016",
    "district_id": "352424",
    "label": "Priyoso",
    "value": "Priyoso"
  },
  {
    "id": "3524242017",
    "district_id": "352424",
    "label": "Windu",
    "value": "Windu"
  },
  {
    "id": "3524242018",
    "district_id": "352424",
    "label": "Bogobabadan",
    "value": "Bogobabadan"
  },
  {
    "id": "3524242019",
    "district_id": "352424",
    "label": "Gawerejo",
    "value": "Gawerejo"
  },
  {
    "id": "3524242020",
    "district_id": "352424",
    "label": "Kuro",
    "value": "Kuro"
  },
  {
    "id": "3524242021",
    "district_id": "352424",
    "label": "Karangbinangun",
    "value": "Karangbinangun"
  },
  {
    "id": "3524252001",
    "district_id": "352425",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3524252002",
    "district_id": "352425",
    "label": "Dlanggu",
    "value": "Dlanggu"
  },
  {
    "id": "3524252003",
    "district_id": "352425",
    "label": "Laladan",
    "value": "Laladan"
  },
  {
    "id": "3524252004",
    "district_id": "352425",
    "label": "Deketkulon",
    "value": "Deketkulon"
  },
  {
    "id": "3524252005",
    "district_id": "352425",
    "label": "Deketwetan",
    "value": "Deketwetan"
  },
  {
    "id": "3524252006",
    "district_id": "352425",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "3524252007",
    "district_id": "352425",
    "label": "Dinoyo",
    "value": "Dinoyo"
  },
  {
    "id": "3524252008",
    "district_id": "352425",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3524252009",
    "district_id": "352425",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "3524252010",
    "district_id": "352425",
    "label": "Pandanpancur",
    "value": "Pandanpancur"
  },
  {
    "id": "3524252011",
    "district_id": "352425",
    "label": "Sidobinangun",
    "value": "Sidobinangun"
  },
  {
    "id": "3524252012",
    "district_id": "352425",
    "label": "Babatagung",
    "value": "Babatagung"
  },
  {
    "id": "3524252013",
    "district_id": "352425",
    "label": "Tukkerto",
    "value": "Tukkerto"
  },
  {
    "id": "3524252014",
    "district_id": "352425",
    "label": "Weduni",
    "value": "Weduni"
  },
  {
    "id": "3524252015",
    "district_id": "352425",
    "label": "Rejotengah",
    "value": "Rejotengah"
  },
  {
    "id": "3524252016",
    "district_id": "352425",
    "label": "Srirande",
    "value": "Srirande"
  },
  {
    "id": "3524252017",
    "district_id": "352425",
    "label": "Plosobuden",
    "value": "Plosobuden"
  },
  {
    "id": "3524262001",
    "district_id": "352426",
    "label": "Soko",
    "value": "Soko"
  },
  {
    "id": "3524262002",
    "district_id": "352426",
    "label": "Morocalan",
    "value": "Morocalan"
  },
  {
    "id": "3524262003",
    "district_id": "352426",
    "label": "Gempolpendowo",
    "value": "Gempolpendowo"
  },
  {
    "id": "3524262004",
    "district_id": "352426",
    "label": "Pasi",
    "value": "Pasi"
  },
  {
    "id": "3524262005",
    "district_id": "352426",
    "label": "Rayunggumuk",
    "value": "Rayunggumuk"
  },
  {
    "id": "3524262006",
    "district_id": "352426",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "3524262007",
    "district_id": "352426",
    "label": "Margoanyar",
    "value": "Margoanyar"
  },
  {
    "id": "3524262008",
    "district_id": "352426",
    "label": "Began",
    "value": "Began"
  },
  {
    "id": "3524262009",
    "district_id": "352426",
    "label": "Mendogo",
    "value": "Mendogo"
  },
  {
    "id": "3524262010",
    "district_id": "352426",
    "label": "Kentong",
    "value": "Kentong"
  },
  {
    "id": "3524262011",
    "district_id": "352426",
    "label": "Sudangan",
    "value": "Sudangan"
  },
  {
    "id": "3524262012",
    "district_id": "352426",
    "label": "Medang",
    "value": "Medang"
  },
  {
    "id": "3524262013",
    "district_id": "352426",
    "label": "Duduklor",
    "value": "Duduklor"
  },
  {
    "id": "3524262014",
    "district_id": "352426",
    "label": "Glagah",
    "value": "Glagah"
  },
  {
    "id": "3524262015",
    "district_id": "352426",
    "label": "Wangen",
    "value": "Wangen"
  },
  {
    "id": "3524262016",
    "district_id": "352426",
    "label": "Tanggungprigel",
    "value": "Tanggungprigel"
  },
  {
    "id": "3524262017",
    "district_id": "352426",
    "label": "Karangagung",
    "value": "Karangagung"
  },
  {
    "id": "3524262018",
    "district_id": "352426",
    "label": "Bangkok",
    "value": "Bangkok"
  },
  {
    "id": "3524262019",
    "district_id": "352426",
    "label": "Jatirenggo",
    "value": "Jatirenggo"
  },
  {
    "id": "3524262020",
    "district_id": "352426",
    "label": "Bapuhbaru",
    "value": "Bapuhbaru"
  },
  {
    "id": "3524262021",
    "district_id": "352426",
    "label": "Bapuhbandung",
    "value": "Bapuhbandung"
  },
  {
    "id": "3524262022",
    "district_id": "352426",
    "label": "Meluntur",
    "value": "Meluntur"
  },
  {
    "id": "3524262023",
    "district_id": "352426",
    "label": "Konang",
    "value": "Konang"
  },
  {
    "id": "3524262024",
    "district_id": "352426",
    "label": "Dukuhtunggal",
    "value": "Dukuhtunggal"
  },
  {
    "id": "3524262025",
    "district_id": "352426",
    "label": "Panggang",
    "value": "Panggang"
  },
  {
    "id": "3524262026",
    "district_id": "352426",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3524262027",
    "district_id": "352426",
    "label": "Wedoro",
    "value": "Wedoro"
  },
  {
    "id": "3524262028",
    "district_id": "352426",
    "label": "Karangturi",
    "value": "Karangturi"
  },
  {
    "id": "3524262029",
    "district_id": "352426",
    "label": "Meluwur",
    "value": "Meluwur"
  },
  {
    "id": "3524272001",
    "district_id": "352427",
    "label": "Kedungkumpul",
    "value": "Kedungkumpul"
  },
  {
    "id": "3524272002",
    "district_id": "352427",
    "label": "Dermolemahbang",
    "value": "Dermolemahbang"
  },
  {
    "id": "3524272003",
    "district_id": "352427",
    "label": "Simbatan",
    "value": "Simbatan"
  },
  {
    "id": "3524272004",
    "district_id": "352427",
    "label": "Sumberjo",
    "value": "Sumberjo"
  },
  {
    "id": "3524272005",
    "district_id": "352427",
    "label": "Canggah",
    "value": "Canggah"
  },
  {
    "id": "3524272006",
    "district_id": "352427",
    "label": "Beru",
    "value": "Beru"
  },
  {
    "id": "3524272007",
    "district_id": "352427",
    "label": "Tambakmenjangan",
    "value": "Tambakmenjangan"
  },
  {
    "id": "3524272008",
    "district_id": "352427",
    "label": "Gempoltukmloko",
    "value": "Gempoltukmloko"
  },
  {
    "id": "3524272009",
    "district_id": "352427",
    "label": "Sarirejo",
    "value": "Sarirejo"
  },
  {
    "id": "3525012001",
    "district_id": "352501",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "3525012002",
    "district_id": "352501",
    "label": "Karangcangkring",
    "value": "Karangcangkring"
  },
  {
    "id": "3525012003",
    "district_id": "352501",
    "label": "Gedongkedo'an",
    "value": "Gedongkedo'an"
  },
  {
    "id": "3525012004",
    "district_id": "352501",
    "label": "Bulangan",
    "value": "Bulangan"
  },
  {
    "id": "3525012005",
    "district_id": "352501",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "3525012006",
    "district_id": "352501",
    "label": "Bangeran",
    "value": "Bangeran"
  },
  {
    "id": "3525012007",
    "district_id": "352501",
    "label": "Lowayu",
    "value": "Lowayu"
  },
  {
    "id": "3525012008",
    "district_id": "352501",
    "label": "Petiyin Tunggal",
    "value": "Petiyin Tunggal"
  },
  {
    "id": "3525012009",
    "district_id": "352501",
    "label": "Tirem Enggal",
    "value": "Tirem Enggal"
  },
  {
    "id": "3525012010",
    "district_id": "352501",
    "label": "Tebuwung",
    "value": "Tebuwung"
  },
  {
    "id": "3525012011",
    "district_id": "352501",
    "label": "Dukuh Kembar",
    "value": "Dukuh Kembar"
  },
  {
    "id": "3525012012",
    "district_id": "352501",
    "label": "Madumulyorejo",
    "value": "Madumulyorejo"
  },
  {
    "id": "3525012013",
    "district_id": "352501",
    "label": "Mentaras",
    "value": "Mentaras"
  },
  {
    "id": "3525012014",
    "district_id": "352501",
    "label": "Baron",
    "value": "Baron"
  },
  {
    "id": "3525012015",
    "district_id": "352501",
    "label": "Jrebeng",
    "value": "Jrebeng"
  },
  {
    "id": "3525012016",
    "district_id": "352501",
    "label": "Mojopetung",
    "value": "Mojopetung"
  },
  {
    "id": "3525012017",
    "district_id": "352501",
    "label": "Sekargadung",
    "value": "Sekargadung"
  },
  {
    "id": "3525012018",
    "district_id": "352501",
    "label": "Ima'an",
    "value": "Ima'an"
  },
  {
    "id": "3525012019",
    "district_id": "352501",
    "label": "Babakbawo",
    "value": "Babakbawo"
  },
  {
    "id": "3525012020",
    "district_id": "352501",
    "label": "Babaksari",
    "value": "Babaksari"
  },
  {
    "id": "3525012021",
    "district_id": "352501",
    "label": "Sambogunung",
    "value": "Sambogunung"
  },
  {
    "id": "3525012022",
    "district_id": "352501",
    "label": "Sembungan Kidul",
    "value": "Sembungan Kidul"
  },
  {
    "id": "3525012023",
    "district_id": "352501",
    "label": "Sembunganyar",
    "value": "Sembunganyar"
  },
  {
    "id": "3525012024",
    "district_id": "352501",
    "label": "Kalirejo",
    "value": "Kalirejo"
  },
  {
    "id": "3525012025",
    "district_id": "352501",
    "label": "Padang Bandung",
    "value": "Padang Bandung"
  },
  {
    "id": "3525012026",
    "district_id": "352501",
    "label": "Dukunanyar",
    "value": "Dukunanyar"
  },
  {
    "id": "3525022001",
    "district_id": "352502",
    "label": "Jombangdelik",
    "value": "Jombangdelik"
  },
  {
    "id": "3525022002",
    "district_id": "352502",
    "label": "Brangkal",
    "value": "Brangkal"
  },
  {
    "id": "3525022003",
    "district_id": "352502",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3525022004",
    "district_id": "352502",
    "label": "Bandungsekaran",
    "value": "Bandungsekaran"
  },
  {
    "id": "3525022005",
    "district_id": "352502",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "3525022006",
    "district_id": "352502",
    "label": "Tanahlandean",
    "value": "Tanahlandean"
  },
  {
    "id": "3525022007",
    "district_id": "352502",
    "label": "Kedungsumber",
    "value": "Kedungsumber"
  },
  {
    "id": "3525022008",
    "district_id": "352502",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3525022009",
    "district_id": "352502",
    "label": "Dapet",
    "value": "Dapet"
  },
  {
    "id": "3525022010",
    "district_id": "352502",
    "label": "Dohoagung",
    "value": "Dohoagung"
  },
  {
    "id": "3525022011",
    "district_id": "352502",
    "label": "Mojogede",
    "value": "Mojogede"
  },
  {
    "id": "3525022012",
    "district_id": "352502",
    "label": "Tenggor",
    "value": "Tenggor"
  },
  {
    "id": "3525022013",
    "district_id": "352502",
    "label": "Wahas",
    "value": "Wahas"
  },
  {
    "id": "3525022014",
    "district_id": "352502",
    "label": "Kedungpring",
    "value": "Kedungpring"
  },
  {
    "id": "3525022015",
    "district_id": "352502",
    "label": "Sekarputih",
    "value": "Sekarputih"
  },
  {
    "id": "3525022016",
    "district_id": "352502",
    "label": "Pinggir",
    "value": "Pinggir"
  },
  {
    "id": "3525022017",
    "district_id": "352502",
    "label": "Karangsemanding",
    "value": "Karangsemanding"
  },
  {
    "id": "3525022018",
    "district_id": "352502",
    "label": "Pacuh",
    "value": "Pacuh"
  },
  {
    "id": "3525022019",
    "district_id": "352502",
    "label": "Balongpanggang",
    "value": "Balongpanggang"
  },
  {
    "id": "3525022020",
    "district_id": "352502",
    "label": "Wotansari",
    "value": "Wotansari"
  },
  {
    "id": "3525022021",
    "district_id": "352502",
    "label": "Pucung",
    "value": "Pucung"
  },
  {
    "id": "3525022022",
    "district_id": "352502",
    "label": "Klotok",
    "value": "Klotok"
  },
  {
    "id": "3525022023",
    "district_id": "352502",
    "label": "Ngasin",
    "value": "Ngasin"
  },
  {
    "id": "3525022024",
    "district_id": "352502",
    "label": "Banjaragung",
    "value": "Banjaragung"
  },
  {
    "id": "3525022025",
    "district_id": "352502",
    "label": "Ganggang",
    "value": "Ganggang"
  },
  {
    "id": "3525032001",
    "district_id": "352503",
    "label": "Pantenan",
    "value": "Pantenan"
  },
  {
    "id": "3525032002",
    "district_id": "352503",
    "label": "Sumurber",
    "value": "Sumurber"
  },
  {
    "id": "3525032003",
    "district_id": "352503",
    "label": "Siwalan",
    "value": "Siwalan"
  },
  {
    "id": "3525032004",
    "district_id": "352503",
    "label": "Ketanen",
    "value": "Ketanen"
  },
  {
    "id": "3525032005",
    "district_id": "352503",
    "label": "Banyutengah",
    "value": "Banyutengah"
  },
  {
    "id": "3525032006",
    "district_id": "352503",
    "label": "Serah",
    "value": "Serah"
  },
  {
    "id": "3525032007",
    "district_id": "352503",
    "label": "Surowiti",
    "value": "Surowiti"
  },
  {
    "id": "3525032008",
    "district_id": "352503",
    "label": "Prupuh",
    "value": "Prupuh"
  },
  {
    "id": "3525032009",
    "district_id": "352503",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3525032010",
    "district_id": "352503",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3525032011",
    "district_id": "352503",
    "label": "Dalegan",
    "value": "Dalegan"
  },
  {
    "id": "3525032012",
    "district_id": "352503",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "3525032013",
    "district_id": "352503",
    "label": "Wotan",
    "value": "Wotan"
  },
  {
    "id": "3525032014",
    "district_id": "352503",
    "label": "Doudo",
    "value": "Doudo"
  },
  {
    "id": "3525042001",
    "district_id": "352504",
    "label": "Karangankidul",
    "value": "Karangankidul"
  },
  {
    "id": "3525042002",
    "district_id": "352504",
    "label": "Sedapur Klagen",
    "value": "Sedapur Klagen"
  },
  {
    "id": "3525042003",
    "district_id": "352504",
    "label": "Deliksumber",
    "value": "Deliksumber"
  },
  {
    "id": "3525042004",
    "district_id": "352504",
    "label": "Lundo",
    "value": "Lundo"
  },
  {
    "id": "3525042005",
    "district_id": "352504",
    "label": "Kalipadang",
    "value": "Kalipadang"
  },
  {
    "id": "3525042006",
    "district_id": "352504",
    "label": "Kedungrukem",
    "value": "Kedungrukem"
  },
  {
    "id": "3525042007",
    "district_id": "352504",
    "label": "Balungtunjung",
    "value": "Balungtunjung"
  },
  {
    "id": "3525042008",
    "district_id": "352504",
    "label": "Munggugebang",
    "value": "Munggugebang"
  },
  {
    "id": "3525042009",
    "district_id": "352504",
    "label": "Balongmojo",
    "value": "Balongmojo"
  },
  {
    "id": "3525042010",
    "district_id": "352504",
    "label": "Bulangkulon",
    "value": "Bulangkulon"
  },
  {
    "id": "3525042011",
    "district_id": "352504",
    "label": "Banter",
    "value": "Banter"
  },
  {
    "id": "3525042012",
    "district_id": "352504",
    "label": "Sirnoboyo",
    "value": "Sirnoboyo"
  },
  {
    "id": "3525042013",
    "district_id": "352504",
    "label": "Munggugianti",
    "value": "Munggugianti"
  },
  {
    "id": "3525042014",
    "district_id": "352504",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3525042015",
    "district_id": "352504",
    "label": "Metatu",
    "value": "Metatu"
  },
  {
    "id": "3525042016",
    "district_id": "352504",
    "label": "Bengkelolor",
    "value": "Bengkelolor"
  },
  {
    "id": "3525042017",
    "district_id": "352504",
    "label": "Gurangploso",
    "value": "Gurangploso"
  },
  {
    "id": "3525042018",
    "district_id": "352504",
    "label": "Kedungsekar",
    "value": "Kedungsekar"
  },
  {
    "id": "3525042019",
    "district_id": "352504",
    "label": "Dermo",
    "value": "Dermo"
  },
  {
    "id": "3525042020",
    "district_id": "352504",
    "label": "Jogodalu",
    "value": "Jogodalu"
  },
  {
    "id": "3525042021",
    "district_id": "352504",
    "label": "Jatirembe",
    "value": "Jatirembe"
  },
  {
    "id": "3525042022",
    "district_id": "352504",
    "label": "Bulurejo",
    "value": "Bulurejo"
  },
  {
    "id": "3525042023",
    "district_id": "352504",
    "label": "Punduttrate",
    "value": "Punduttrate"
  },
  {
    "id": "3525052001",
    "district_id": "352505",
    "label": "Panjunan",
    "value": "Panjunan"
  },
  {
    "id": "3525052002",
    "district_id": "352505",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3525052003",
    "district_id": "352505",
    "label": "Pandanan",
    "value": "Pandanan"
  },
  {
    "id": "3525052004",
    "district_id": "352505",
    "label": "Tumapel",
    "value": "Tumapel"
  },
  {
    "id": "3525052005",
    "district_id": "352505",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3525052006",
    "district_id": "352505",
    "label": "Glanggang",
    "value": "Glanggang"
  },
  {
    "id": "3525052007",
    "district_id": "352505",
    "label": "Palebon",
    "value": "Palebon"
  },
  {
    "id": "3525052008",
    "district_id": "352505",
    "label": "Setrohadi",
    "value": "Setrohadi"
  },
  {
    "id": "3525052009",
    "district_id": "352505",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3525052010",
    "district_id": "352505",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3525052011",
    "district_id": "352505",
    "label": "Wadak Lor",
    "value": "Wadak Lor"
  },
  {
    "id": "3525052012",
    "district_id": "352505",
    "label": "Wadak Kidul",
    "value": "Wadak Kidul"
  },
  {
    "id": "3525052013",
    "district_id": "352505",
    "label": "Petisbenem",
    "value": "Petisbenem"
  },
  {
    "id": "3525052014",
    "district_id": "352505",
    "label": "Duduksampeyan",
    "value": "Duduksampeyan"
  },
  {
    "id": "3525052015",
    "district_id": "352505",
    "label": "Gredek",
    "value": "Gredek"
  },
  {
    "id": "3525052016",
    "district_id": "352505",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3525052017",
    "district_id": "352505",
    "label": "Sumari",
    "value": "Sumari"
  },
  {
    "id": "3525052018",
    "district_id": "352505",
    "label": "Samirplapan",
    "value": "Samirplapan"
  },
  {
    "id": "3525052019",
    "district_id": "352505",
    "label": "Kawistowindu",
    "value": "Kawistowindu"
  },
  {
    "id": "3525052020",
    "district_id": "352505",
    "label": "Kemudi",
    "value": "Kemudi"
  },
  {
    "id": "3525052021",
    "district_id": "352505",
    "label": "Tebaloan",
    "value": "Tebaloan"
  },
  {
    "id": "3525052022",
    "district_id": "352505",
    "label": "Tirem",
    "value": "Tirem"
  },
  {
    "id": "3525052023",
    "district_id": "352505",
    "label": "Ambeng - ambeng Watangrejo",
    "value": "Ambeng - ambeng Watangrejo"
  },
  {
    "id": "3525062001",
    "district_id": "352506",
    "label": "Kepuhklagen",
    "value": "Kepuhklagen"
  },
  {
    "id": "3525062002",
    "district_id": "352506",
    "label": "Sumbergede",
    "value": "Sumbergede"
  },
  {
    "id": "3525062003",
    "district_id": "352506",
    "label": "Mondoluku",
    "value": "Mondoluku"
  },
  {
    "id": "3525062004",
    "district_id": "352506",
    "label": "Kedunganyar",
    "value": "Kedunganyar"
  },
  {
    "id": "3525062005",
    "district_id": "352506",
    "label": "Sumberwaru",
    "value": "Sumberwaru"
  },
  {
    "id": "3525062006",
    "district_id": "352506",
    "label": "Kesambenkulon",
    "value": "Kesambenkulon"
  },
  {
    "id": "3525062007",
    "district_id": "352506",
    "label": "Sumberame",
    "value": "Sumberame"
  },
  {
    "id": "3525062008",
    "district_id": "352506",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "3525062009",
    "district_id": "352506",
    "label": "Sooko",
    "value": "Sooko"
  },
  {
    "id": "3525062010",
    "district_id": "352506",
    "label": "Wringinanom",
    "value": "Wringinanom"
  },
  {
    "id": "3525062011",
    "district_id": "352506",
    "label": "Pedagangan",
    "value": "Pedagangan"
  },
  {
    "id": "3525062012",
    "district_id": "352506",
    "label": "Lebanisuko",
    "value": "Lebanisuko"
  },
  {
    "id": "3525062013",
    "district_id": "352506",
    "label": "Lebaniwaras",
    "value": "Lebaniwaras"
  },
  {
    "id": "3525062014",
    "district_id": "352506",
    "label": "Watestanjung",
    "value": "Watestanjung"
  },
  {
    "id": "3525062015",
    "district_id": "352506",
    "label": "Sumengko",
    "value": "Sumengko"
  },
  {
    "id": "3525062016",
    "district_id": "352506",
    "label": "Pasinanlemahputih",
    "value": "Pasinanlemahputih"
  },
  {
    "id": "3525072001",
    "district_id": "352507",
    "label": "Pangkahkulon",
    "value": "Pangkahkulon"
  },
  {
    "id": "3525072002",
    "district_id": "352507",
    "label": "Cangaan",
    "value": "Cangaan"
  },
  {
    "id": "3525072003",
    "district_id": "352507",
    "label": "Ngemboh",
    "value": "Ngemboh"
  },
  {
    "id": "3525072004",
    "district_id": "352507",
    "label": "Sekapuk",
    "value": "Sekapuk"
  },
  {
    "id": "3525072005",
    "district_id": "352507",
    "label": "Gosari",
    "value": "Gosari"
  },
  {
    "id": "3525072006",
    "district_id": "352507",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3525072007",
    "district_id": "352507",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3525072008",
    "district_id": "352507",
    "label": "Pangkahwetan",
    "value": "Pangkahwetan"
  },
  {
    "id": "3525072009",
    "district_id": "352507",
    "label": "Bolo",
    "value": "Bolo"
  },
  {
    "id": "3525072010",
    "district_id": "352507",
    "label": "Glatik",
    "value": "Glatik"
  },
  {
    "id": "3525072011",
    "district_id": "352507",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3525072012",
    "district_id": "352507",
    "label": "Ketapanglor",
    "value": "Ketapanglor"
  },
  {
    "id": "3525072013",
    "district_id": "352507",
    "label": "Tanjangawan",
    "value": "Tanjangawan"
  },
  {
    "id": "3525082001",
    "district_id": "352508",
    "label": "Mojowuku",
    "value": "Mojowuku"
  },
  {
    "id": "3525082002",
    "district_id": "352508",
    "label": "Glindah",
    "value": "Glindah"
  },
  {
    "id": "3525082003",
    "district_id": "352508",
    "label": "Sidoraharjo",
    "value": "Sidoraharjo"
  },
  {
    "id": "3525082004",
    "district_id": "352508",
    "label": "Tulung",
    "value": "Tulung"
  },
  {
    "id": "3525082005",
    "district_id": "352508",
    "label": "Cermen",
    "value": "Cermen"
  },
  {
    "id": "3525082006",
    "district_id": "352508",
    "label": "Lampah",
    "value": "Lampah"
  },
  {
    "id": "3525082007",
    "district_id": "352508",
    "label": "Slempit",
    "value": "Slempit"
  },
  {
    "id": "3525082008",
    "district_id": "352508",
    "label": "Turirejo",
    "value": "Turirejo"
  },
  {
    "id": "3525082009",
    "district_id": "352508",
    "label": "Belahanrejo",
    "value": "Belahanrejo"
  },
  {
    "id": "3525082010",
    "district_id": "352508",
    "label": "Menunggal",
    "value": "Menunggal"
  },
  {
    "id": "3525082011",
    "district_id": "352508",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3525082012",
    "district_id": "352508",
    "label": "Katimoho",
    "value": "Katimoho"
  },
  {
    "id": "3525082013",
    "district_id": "352508",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "3525082014",
    "district_id": "352508",
    "label": "Kedamean",
    "value": "Kedamean"
  },
  {
    "id": "3525082015",
    "district_id": "352508",
    "label": "Ngepung",
    "value": "Ngepung"
  },
  {
    "id": "3525092001",
    "district_id": "352509",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3525092002",
    "district_id": "352509",
    "label": "Gedangan",
    "value": "Gedangan"
  },
  {
    "id": "3525092003",
    "district_id": "352509",
    "label": "Wadeng",
    "value": "Wadeng"
  },
  {
    "id": "3525092004",
    "district_id": "352509",
    "label": "Lasem",
    "value": "Lasem"
  },
  {
    "id": "3525092005",
    "district_id": "352509",
    "label": "Kertosono",
    "value": "Kertosono"
  },
  {
    "id": "3525092006",
    "district_id": "352509",
    "label": "Sambipondok",
    "value": "Sambipondok"
  },
  {
    "id": "3525092007",
    "district_id": "352509",
    "label": "Raci Kulon",
    "value": "Raci Kulon"
  },
  {
    "id": "3525092008",
    "district_id": "352509",
    "label": "Golokan",
    "value": "Golokan"
  },
  {
    "id": "3525092009",
    "district_id": "352509",
    "label": "Raci Tengah",
    "value": "Raci Tengah"
  },
  {
    "id": "3525092010",
    "district_id": "352509",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3525092011",
    "district_id": "352509",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3525092012",
    "district_id": "352509",
    "label": "Srowo",
    "value": "Srowo"
  },
  {
    "id": "3525092013",
    "district_id": "352509",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3525092014",
    "district_id": "352509",
    "label": "Bunderan",
    "value": "Bunderan"
  },
  {
    "id": "3525092015",
    "district_id": "352509",
    "label": "Asempapak",
    "value": "Asempapak"
  },
  {
    "id": "3525092016",
    "district_id": "352509",
    "label": "Mriyunan",
    "value": "Mriyunan"
  },
  {
    "id": "3525092017",
    "district_id": "352509",
    "label": "Mojoasem",
    "value": "Mojoasem"
  },
  {
    "id": "3525092018",
    "district_id": "352509",
    "label": "Sedagaran",
    "value": "Sedagaran"
  },
  {
    "id": "3525092019",
    "district_id": "352509",
    "label": "Ngawen",
    "value": "Ngawen"
  },
  {
    "id": "3525092020",
    "district_id": "352509",
    "label": "Pengulu",
    "value": "Pengulu"
  },
  {
    "id": "3525092021",
    "district_id": "352509",
    "label": "Randuboto",
    "value": "Randuboto"
  },
  {
    "id": "3525102001",
    "district_id": "352510",
    "label": "Tanggulrejo",
    "value": "Tanggulrejo"
  },
  {
    "id": "3525102002",
    "district_id": "352510",
    "label": "Morobakung",
    "value": "Morobakung"
  },
  {
    "id": "3525102003",
    "district_id": "352510",
    "label": "Pejangganan",
    "value": "Pejangganan"
  },
  {
    "id": "3525102004",
    "district_id": "352510",
    "label": "Gumeno",
    "value": "Gumeno"
  },
  {
    "id": "3525102005",
    "district_id": "352510",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3525102006",
    "district_id": "352510",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3525102007",
    "district_id": "352510",
    "label": "Betoyokauman",
    "value": "Betoyokauman"
  },
  {
    "id": "3525102008",
    "district_id": "352510",
    "label": "Sembayat",
    "value": "Sembayat"
  },
  {
    "id": "3525102009",
    "district_id": "352510",
    "label": "Betoyoguci",
    "value": "Betoyoguci"
  },
  {
    "id": "3525102010",
    "district_id": "352510",
    "label": "Leran",
    "value": "Leran"
  },
  {
    "id": "3525102011",
    "district_id": "352510",
    "label": "Tebalo",
    "value": "Tebalo"
  },
  {
    "id": "3525102012",
    "district_id": "352510",
    "label": "Karangrejo",
    "value": "Karangrejo"
  },
  {
    "id": "3525102013",
    "district_id": "352510",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "3525102014",
    "district_id": "352510",
    "label": "Manyarejo",
    "value": "Manyarejo"
  },
  {
    "id": "3525102015",
    "district_id": "352510",
    "label": "Manyar Sidomukti",
    "value": "Manyar Sidomukti"
  },
  {
    "id": "3525102016",
    "district_id": "352510",
    "label": "Manyar Sidorukun",
    "value": "Manyar Sidorukun"
  },
  {
    "id": "3525102017",
    "district_id": "352510",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3525102018",
    "district_id": "352510",
    "label": "Suci",
    "value": "Suci"
  },
  {
    "id": "3525102019",
    "district_id": "352510",
    "label": "Paganden",
    "value": "Paganden"
  },
  {
    "id": "3525102020",
    "district_id": "352510",
    "label": "Pongangan",
    "value": "Pongangan"
  },
  {
    "id": "3525102021",
    "district_id": "352510",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "3525102022",
    "district_id": "352510",
    "label": "Yosowilangun",
    "value": "Yosowilangun"
  },
  {
    "id": "3525102023",
    "district_id": "352510",
    "label": "Roomo",
    "value": "Roomo"
  },
  {
    "id": "3525112001",
    "district_id": "352511",
    "label": "Dadapkuning",
    "value": "Dadapkuning"
  },
  {
    "id": "3525112002",
    "district_id": "352511",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "3525112003",
    "district_id": "352511",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3525112004",
    "district_id": "352511",
    "label": "Dooro",
    "value": "Dooro"
  },
  {
    "id": "3525112005",
    "district_id": "352511",
    "label": "Dampaan",
    "value": "Dampaan"
  },
  {
    "id": "3525112006",
    "district_id": "352511",
    "label": "Ngembung",
    "value": "Ngembung"
  },
  {
    "id": "3525112007",
    "district_id": "352511",
    "label": "Sukoanyar",
    "value": "Sukoanyar"
  },
  {
    "id": "3525112008",
    "district_id": "352511",
    "label": "Gedangkulud",
    "value": "Gedangkulud"
  },
  {
    "id": "3525112009",
    "district_id": "352511",
    "label": "Wedani",
    "value": "Wedani"
  },
  {
    "id": "3525112010",
    "district_id": "352511",
    "label": "Dungus",
    "value": "Dungus"
  },
  {
    "id": "3525112011",
    "district_id": "352511",
    "label": "Guranganyar",
    "value": "Guranganyar"
  },
  {
    "id": "3525112012",
    "district_id": "352511",
    "label": "Kambingan",
    "value": "Kambingan"
  },
  {
    "id": "3525112013",
    "district_id": "352511",
    "label": "Morowudi",
    "value": "Morowudi"
  },
  {
    "id": "3525112014",
    "district_id": "352511",
    "label": "Betiting",
    "value": "Betiting"
  },
  {
    "id": "3525112015",
    "district_id": "352511",
    "label": "Ngabetan",
    "value": "Ngabetan"
  },
  {
    "id": "3525112016",
    "district_id": "352511",
    "label": "Padeg",
    "value": "Padeg"
  },
  {
    "id": "3525112017",
    "district_id": "352511",
    "label": "Iker - iker Geger",
    "value": "Iker - iker Geger"
  },
  {
    "id": "3525112018",
    "district_id": "352511",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3525112019",
    "district_id": "352511",
    "label": "Cagakagung",
    "value": "Cagakagung"
  },
  {
    "id": "3525112020",
    "district_id": "352511",
    "label": "Cerme Kidul",
    "value": "Cerme Kidul"
  },
  {
    "id": "3525112021",
    "district_id": "352511",
    "label": "Cerme Lor",
    "value": "Cerme Lor"
  },
  {
    "id": "3525112022",
    "district_id": "352511",
    "label": "Pandu",
    "value": "Pandu"
  },
  {
    "id": "3525112023",
    "district_id": "352511",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3525112024",
    "district_id": "352511",
    "label": "Tambakberas",
    "value": "Tambakberas"
  },
  {
    "id": "3525112025",
    "district_id": "352511",
    "label": "Jono",
    "value": "Jono"
  },
  {
    "id": "3525122001",
    "district_id": "352512",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3525122002",
    "district_id": "352512",
    "label": "Mojopurogede",
    "value": "Mojopurogede"
  },
  {
    "id": "3525122003",
    "district_id": "352512",
    "label": "Mojopurowetan",
    "value": "Mojopurowetan"
  },
  {
    "id": "3525122004",
    "district_id": "352512",
    "label": "Melirang",
    "value": "Melirang"
  },
  {
    "id": "3525122005",
    "district_id": "352512",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "3525122006",
    "district_id": "352512",
    "label": "Raciwetan",
    "value": "Raciwetan"
  },
  {
    "id": "3525122007",
    "district_id": "352512",
    "label": "Masangan",
    "value": "Masangan"
  },
  {
    "id": "3525122008",
    "district_id": "352512",
    "label": "Sidokumpul",
    "value": "Sidokumpul"
  },
  {
    "id": "3525122009",
    "district_id": "352512",
    "label": "Pegundan",
    "value": "Pegundan"
  },
  {
    "id": "3525122010",
    "district_id": "352512",
    "label": "Sukowati",
    "value": "Sukowati"
  },
  {
    "id": "3525122011",
    "district_id": "352512",
    "label": "Abar- abir",
    "value": "Abar- abir"
  },
  {
    "id": "3525122012",
    "district_id": "352512",
    "label": "Bungah",
    "value": "Bungah"
  },
  {
    "id": "3525122013",
    "district_id": "352512",
    "label": "Kemangi",
    "value": "Kemangi"
  },
  {
    "id": "3525122014",
    "district_id": "352512",
    "label": "Kisik",
    "value": "Kisik"
  },
  {
    "id": "3525122015",
    "district_id": "352512",
    "label": "Gumeng",
    "value": "Gumeng"
  },
  {
    "id": "3525122016",
    "district_id": "352512",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3525122017",
    "district_id": "352512",
    "label": "Indrodelik",
    "value": "Indrodelik"
  },
  {
    "id": "3525122018",
    "district_id": "352512",
    "label": "Bedanten",
    "value": "Bedanten"
  },
  {
    "id": "3525122019",
    "district_id": "352512",
    "label": "Sungonlegowo",
    "value": "Sungonlegowo"
  },
  {
    "id": "3525122020",
    "district_id": "352512",
    "label": "Tanjung Widoro",
    "value": "Tanjung Widoro"
  },
  {
    "id": "3525122021",
    "district_id": "352512",
    "label": "Watuagung",
    "value": "Watuagung"
  },
  {
    "id": "3525122022",
    "district_id": "352512",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3525132001",
    "district_id": "352513",
    "label": "Beton",
    "value": "Beton"
  },
  {
    "id": "3525132002",
    "district_id": "352513",
    "label": "Pranti",
    "value": "Pranti"
  },
  {
    "id": "3525132003",
    "district_id": "352513",
    "label": "Gadingwatu",
    "value": "Gadingwatu"
  },
  {
    "id": "3525132004",
    "district_id": "352513",
    "label": "Bringkang",
    "value": "Bringkang"
  },
  {
    "id": "3525132005",
    "district_id": "352513",
    "label": "Boteng",
    "value": "Boteng"
  },
  {
    "id": "3525132006",
    "district_id": "352513",
    "label": "Putatlor",
    "value": "Putatlor"
  },
  {
    "id": "3525132007",
    "district_id": "352513",
    "label": "Domas",
    "value": "Domas"
  },
  {
    "id": "3525132008",
    "district_id": "352513",
    "label": "Mojotengah",
    "value": "Mojotengah"
  },
  {
    "id": "3525132009",
    "district_id": "352513",
    "label": "Boboh",
    "value": "Boboh"
  },
  {
    "id": "3525132010",
    "district_id": "352513",
    "label": "Sidojangkung",
    "value": "Sidojangkung"
  },
  {
    "id": "3525132011",
    "district_id": "352513",
    "label": "Hendrosari",
    "value": "Hendrosari"
  },
  {
    "id": "3525132012",
    "district_id": "352513",
    "label": "Pelemwatu",
    "value": "Pelemwatu"
  },
  {
    "id": "3525132013",
    "district_id": "352513",
    "label": "Menganti",
    "value": "Menganti"
  },
  {
    "id": "3525132014",
    "district_id": "352513",
    "label": "Hulaan",
    "value": "Hulaan"
  },
  {
    "id": "3525132015",
    "district_id": "352513",
    "label": "Kepatihan",
    "value": "Kepatihan"
  },
  {
    "id": "3525132016",
    "district_id": "352513",
    "label": "Gempolkurung",
    "value": "Gempolkurung"
  },
  {
    "id": "3525132017",
    "district_id": "352513",
    "label": "Drancang",
    "value": "Drancang"
  },
  {
    "id": "3525132018",
    "district_id": "352513",
    "label": "Sidowungu",
    "value": "Sidowungu"
  },
  {
    "id": "3525132019",
    "district_id": "352513",
    "label": "Randupadangan",
    "value": "Randupadangan"
  },
  {
    "id": "3525132020",
    "district_id": "352513",
    "label": "Setro",
    "value": "Setro"
  },
  {
    "id": "3525132021",
    "district_id": "352513",
    "label": "Pengalangan",
    "value": "Pengalangan"
  },
  {
    "id": "3525132022",
    "district_id": "352513",
    "label": "Laban",
    "value": "Laban"
  },
  {
    "id": "3525141006",
    "district_id": "352514",
    "label": "Gulomantung",
    "value": "Gulomantung"
  },
  {
    "id": "3525141008",
    "district_id": "352514",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "3525141010",
    "district_id": "352514",
    "label": "Kebomas",
    "value": "Kebomas"
  },
  {
    "id": "3525141011",
    "district_id": "352514",
    "label": "Kawis Anyar",
    "value": "Kawis Anyar"
  },
  {
    "id": "3525141012",
    "district_id": "352514",
    "label": "Ngargosari",
    "value": "Ngargosari"
  },
  {
    "id": "3525141014",
    "district_id": "352514",
    "label": "Gending",
    "value": "Gending"
  },
  {
    "id": "3525141015",
    "district_id": "352514",
    "label": "Sidomoro",
    "value": "Sidomoro"
  },
  {
    "id": "3525141016",
    "district_id": "352514",
    "label": "Tenggulunan",
    "value": "Tenggulunan"
  },
  {
    "id": "3525141017",
    "district_id": "352514",
    "label": "Singosari",
    "value": "Singosari"
  },
  {
    "id": "3525141019",
    "district_id": "352514",
    "label": "Indro",
    "value": "Indro"
  },
  {
    "id": "3525142001",
    "district_id": "352514",
    "label": "Dahanrejo",
    "value": "Dahanrejo"
  },
  {
    "id": "3525142002",
    "district_id": "352514",
    "label": "Kembangan",
    "value": "Kembangan"
  },
  {
    "id": "3525142003",
    "district_id": "352514",
    "label": "Kedayang",
    "value": "Kedayang"
  },
  {
    "id": "3525142004",
    "district_id": "352514",
    "label": "Randuagung",
    "value": "Randuagung"
  },
  {
    "id": "3525142005",
    "district_id": "352514",
    "label": "Klangonan",
    "value": "Klangonan"
  },
  {
    "id": "3525142007",
    "district_id": "352514",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3525142009",
    "district_id": "352514",
    "label": "Giri",
    "value": "Giri"
  },
  {
    "id": "3525142013",
    "district_id": "352514",
    "label": "Segoromadu",
    "value": "Segoromadu"
  },
  {
    "id": "3525142018",
    "district_id": "352514",
    "label": "Karangkiring",
    "value": "Karangkiring"
  },
  {
    "id": "3525142020",
    "district_id": "352514",
    "label": "Prambangan",
    "value": "Prambangan"
  },
  {
    "id": "3525142021",
    "district_id": "352514",
    "label": "Sekarkurung",
    "value": "Sekarkurung"
  },
  {
    "id": "3525152001",
    "district_id": "352515",
    "label": "Karangandong",
    "value": "Karangandong"
  },
  {
    "id": "3525152002",
    "district_id": "352515",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3525152003",
    "district_id": "352515",
    "label": "Krikilan",
    "value": "Krikilan"
  },
  {
    "id": "3525152004",
    "district_id": "352515",
    "label": "Mojosari Rejo",
    "value": "Mojosari Rejo"
  },
  {
    "id": "3525152005",
    "district_id": "352515",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "3525152006",
    "district_id": "352515",
    "label": "Anom",
    "value": "Anom"
  },
  {
    "id": "3525152007",
    "district_id": "352515",
    "label": "Sumput",
    "value": "Sumput"
  },
  {
    "id": "3525152008",
    "district_id": "352515",
    "label": "Kesamben Wetan",
    "value": "Kesamben Wetan"
  },
  {
    "id": "3525152009",
    "district_id": "352515",
    "label": "Randegansari",
    "value": "Randegansari"
  },
  {
    "id": "3525152010",
    "district_id": "352515",
    "label": "Tenaru",
    "value": "Tenaru"
  },
  {
    "id": "3525152011",
    "district_id": "352515",
    "label": "Driyorejo",
    "value": "Driyorejo"
  },
  {
    "id": "3525152012",
    "district_id": "352515",
    "label": "Petiken",
    "value": "Petiken"
  },
  {
    "id": "3525152013",
    "district_id": "352515",
    "label": "Cangkir",
    "value": "Cangkir"
  },
  {
    "id": "3525152014",
    "district_id": "352515",
    "label": "Gadung",
    "value": "Gadung"
  },
  {
    "id": "3525152015",
    "district_id": "352515",
    "label": "Mulung",
    "value": "Mulung"
  },
  {
    "id": "3525152016",
    "district_id": "352515",
    "label": "Bambe",
    "value": "Bambe"
  },
  {
    "id": "3525161001",
    "district_id": "352516",
    "label": "Ngipik",
    "value": "Ngipik"
  },
  {
    "id": "3525161002",
    "district_id": "352516",
    "label": "Tlogo Pojok",
    "value": "Tlogo Pojok"
  },
  {
    "id": "3525161003",
    "district_id": "352516",
    "label": "Karang Turi",
    "value": "Karang Turi"
  },
  {
    "id": "3525161004",
    "district_id": "352516",
    "label": "Tlogo Patut",
    "value": "Tlogo Patut"
  },
  {
    "id": "3525161005",
    "district_id": "352516",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3525161006",
    "district_id": "352516",
    "label": "Lumpur",
    "value": "Lumpur"
  },
  {
    "id": "3525161007",
    "district_id": "352516",
    "label": "Karang Poh",
    "value": "Karang Poh"
  },
  {
    "id": "3525161008",
    "district_id": "352516",
    "label": "Trate",
    "value": "Trate"
  },
  {
    "id": "3525161010",
    "district_id": "352516",
    "label": "Sido Kumpul",
    "value": "Sido Kumpul"
  },
  {
    "id": "3525161011",
    "district_id": "352516",
    "label": "Bedilan",
    "value": "Bedilan"
  },
  {
    "id": "3525161012",
    "district_id": "352516",
    "label": "Sukodono",
    "value": "Sukodono"
  },
  {
    "id": "3525161013",
    "district_id": "352516",
    "label": "Kemuteran",
    "value": "Kemuteran"
  },
  {
    "id": "3525161014",
    "district_id": "352516",
    "label": "Pekelingan",
    "value": "Pekelingan"
  },
  {
    "id": "3525161015",
    "district_id": "352516",
    "label": "Kroman",
    "value": "Kroman"
  },
  {
    "id": "3525161016",
    "district_id": "352516",
    "label": "Kebungson",
    "value": "Kebungson"
  },
  {
    "id": "3525161017",
    "district_id": "352516",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "3525162009",
    "district_id": "352516",
    "label": "Tlogobendung",
    "value": "Tlogobendung"
  },
  {
    "id": "3525162018",
    "district_id": "352516",
    "label": "Gapuro Sukolilo",
    "value": "Gapuro Sukolilo"
  },
  {
    "id": "3525162019",
    "district_id": "352516",
    "label": "Pulopancikan",
    "value": "Pulopancikan"
  },
  {
    "id": "3525162020",
    "district_id": "352516",
    "label": "Kramatinggil",
    "value": "Kramatinggil"
  },
  {
    "id": "3525162021",
    "district_id": "352516",
    "label": "Sidorukun",
    "value": "Sidorukun"
  },
  {
    "id": "3525172001",
    "district_id": "352517",
    "label": "Dekatagung",
    "value": "Dekatagung"
  },
  {
    "id": "3525172002",
    "district_id": "352517",
    "label": "Kumalasa",
    "value": "Kumalasa"
  },
  {
    "id": "3525172003",
    "district_id": "352517",
    "label": "Suwari",
    "value": "Suwari"
  },
  {
    "id": "3525172004",
    "district_id": "352517",
    "label": "Pudakitbarat",
    "value": "Pudakitbarat"
  },
  {
    "id": "3525172005",
    "district_id": "352517",
    "label": "Pudakittimur",
    "value": "Pudakittimur"
  },
  {
    "id": "3525172006",
    "district_id": "352517",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3525172007",
    "district_id": "352517",
    "label": "Bululanjang",
    "value": "Bululanjang"
  },
  {
    "id": "3525172008",
    "district_id": "352517",
    "label": "Patarselamat",
    "value": "Patarselamat"
  },
  {
    "id": "3525172009",
    "district_id": "352517",
    "label": "Kotakusuma",
    "value": "Kotakusuma"
  },
  {
    "id": "3525172010",
    "district_id": "352517",
    "label": "Sungaiteluk",
    "value": "Sungaiteluk"
  },
  {
    "id": "3525172011",
    "district_id": "352517",
    "label": "Gunungteguh",
    "value": "Gunungteguh"
  },
  {
    "id": "3525172012",
    "district_id": "352517",
    "label": "Sawahmulya",
    "value": "Sawahmulya"
  },
  {
    "id": "3525172013",
    "district_id": "352517",
    "label": "Sungairujing",
    "value": "Sungairujing"
  },
  {
    "id": "3525172014",
    "district_id": "352517",
    "label": "Balikterus",
    "value": "Balikterus"
  },
  {
    "id": "3525172015",
    "district_id": "352517",
    "label": "Kebontelukdalam",
    "value": "Kebontelukdalam"
  },
  {
    "id": "3525172016",
    "district_id": "352517",
    "label": "Sidogedungbatu",
    "value": "Sidogedungbatu"
  },
  {
    "id": "3525172017",
    "district_id": "352517",
    "label": "Daun",
    "value": "Daun"
  },
  {
    "id": "3525182001",
    "district_id": "352518",
    "label": "Gelam",
    "value": "Gelam"
  },
  {
    "id": "3525182002",
    "district_id": "352518",
    "label": "Sukaoneng",
    "value": "Sukaoneng"
  },
  {
    "id": "3525182003",
    "district_id": "352518",
    "label": "Sukalela",
    "value": "Sukalela"
  },
  {
    "id": "3525182004",
    "district_id": "352518",
    "label": "Pekalongan",
    "value": "Pekalongan"
  },
  {
    "id": "3525182005",
    "district_id": "352518",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3525182006",
    "district_id": "352518",
    "label": "Telukjatidawang",
    "value": "Telukjatidawang"
  },
  {
    "id": "3525182007",
    "district_id": "352518",
    "label": "Klumpanggubug",
    "value": "Klumpanggubug"
  },
  {
    "id": "3525182008",
    "district_id": "352518",
    "label": "Grejeg",
    "value": "Grejeg"
  },
  {
    "id": "3525182009",
    "district_id": "352518",
    "label": "Tanjungori",
    "value": "Tanjungori"
  },
  {
    "id": "3525182010",
    "district_id": "352518",
    "label": "Peromaan",
    "value": "Peromaan"
  },
  {
    "id": "3525182011",
    "district_id": "352518",
    "label": "Diponggo",
    "value": "Diponggo"
  },
  {
    "id": "3525182012",
    "district_id": "352518",
    "label": "Kepuhteluk",
    "value": "Kepuhteluk"
  },
  {
    "id": "3525182013",
    "district_id": "352518",
    "label": "Kepuhlegundi",
    "value": "Kepuhlegundi"
  },
  {
    "id": "3526011001",
    "district_id": "352601",
    "label": "Mlajah",
    "value": "Mlajah"
  },
  {
    "id": "3526011002",
    "district_id": "352601",
    "label": "Kemayoran",
    "value": "Kemayoran"
  },
  {
    "id": "3526011003",
    "district_id": "352601",
    "label": "Pangeranan",
    "value": "Pangeranan"
  },
  {
    "id": "3526011004",
    "district_id": "352601",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3526011005",
    "district_id": "352601",
    "label": "Kraton",
    "value": "Kraton"
  },
  {
    "id": "3526011006",
    "district_id": "352601",
    "label": "Pejagan",
    "value": "Pejagan"
  },
  {
    "id": "3526011007",
    "district_id": "352601",
    "label": "Bancaran",
    "value": "Bancaran"
  },
  {
    "id": "3526012008",
    "district_id": "352601",
    "label": "Sembilangan",
    "value": "Sembilangan"
  },
  {
    "id": "3526012009",
    "district_id": "352601",
    "label": "Ujung Piring",
    "value": "Ujung Piring"
  },
  {
    "id": "3526012010",
    "district_id": "352601",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3526012011",
    "district_id": "352601",
    "label": "Martajasah",
    "value": "Martajasah"
  },
  {
    "id": "3526012012",
    "district_id": "352601",
    "label": "Sabiyan",
    "value": "Sabiyan"
  },
  {
    "id": "3526012013",
    "district_id": "352601",
    "label": "Gebang",
    "value": "Gebang"
  },
  {
    "id": "3526022001",
    "district_id": "352602",
    "label": "Socah",
    "value": "Socah"
  },
  {
    "id": "3526022002",
    "district_id": "352602",
    "label": "Keleyan",
    "value": "Keleyan"
  },
  {
    "id": "3526022003",
    "district_id": "352602",
    "label": "Bilaporah",
    "value": "Bilaporah"
  },
  {
    "id": "3526022004",
    "district_id": "352602",
    "label": "Parseh",
    "value": "Parseh"
  },
  {
    "id": "3526022005",
    "district_id": "352602",
    "label": "Jaddih",
    "value": "Jaddih"
  },
  {
    "id": "3526022006",
    "district_id": "352602",
    "label": "Buluh",
    "value": "Buluh"
  },
  {
    "id": "3526022007",
    "district_id": "352602",
    "label": "Junganyar",
    "value": "Junganyar"
  },
  {
    "id": "3526022008",
    "district_id": "352602",
    "label": "Dakiring",
    "value": "Dakiring"
  },
  {
    "id": "3526022009",
    "district_id": "352602",
    "label": "Petaonan",
    "value": "Petaonan"
  },
  {
    "id": "3526022010",
    "district_id": "352602",
    "label": "Pernajuh",
    "value": "Pernajuh"
  },
  {
    "id": "3526022011",
    "district_id": "352602",
    "label": "Saggra Agung",
    "value": "Saggra Agung"
  },
  {
    "id": "3526031005",
    "district_id": "352603",
    "label": "Tunjung",
    "value": "Tunjung"
  },
  {
    "id": "3526032001",
    "district_id": "352603",
    "label": "Burneh",
    "value": "Burneh"
  },
  {
    "id": "3526032002",
    "district_id": "352603",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "3526032003",
    "district_id": "352603",
    "label": "Banangkah",
    "value": "Banangkah"
  },
  {
    "id": "3526032004",
    "district_id": "352603",
    "label": "Alaskembang",
    "value": "Alaskembang"
  },
  {
    "id": "3526032006",
    "district_id": "352603",
    "label": "Arok",
    "value": "Arok"
  },
  {
    "id": "3526032007",
    "district_id": "352603",
    "label": "Kapor",
    "value": "Kapor"
  },
  {
    "id": "3526032008",
    "district_id": "352603",
    "label": "Sobih",
    "value": "Sobih"
  },
  {
    "id": "3526032009",
    "district_id": "352603",
    "label": "Pangolangan",
    "value": "Pangolangan"
  },
  {
    "id": "3526032010",
    "district_id": "352603",
    "label": "Binoh",
    "value": "Binoh"
  },
  {
    "id": "3526032011",
    "district_id": "352603",
    "label": "Perreng",
    "value": "Perreng"
  },
  {
    "id": "3526032012",
    "district_id": "352603",
    "label": "Jambuh",
    "value": "Jambuh"
  },
  {
    "id": "3526042001",
    "district_id": "352604",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "3526042002",
    "district_id": "352604",
    "label": "Banyuajuh",
    "value": "Banyuajuh"
  },
  {
    "id": "3526042003",
    "district_id": "352604",
    "label": "Gili Barat",
    "value": "Gili Barat"
  },
  {
    "id": "3526042004",
    "district_id": "352604",
    "label": "Gili Timur",
    "value": "Gili Timur"
  },
  {
    "id": "3526042005",
    "district_id": "352604",
    "label": "Gili Anyar",
    "value": "Gili Anyar"
  },
  {
    "id": "3526042006",
    "district_id": "352604",
    "label": "Telang",
    "value": "Telang"
  },
  {
    "id": "3526042007",
    "district_id": "352604",
    "label": "Pendabah",
    "value": "Pendabah"
  },
  {
    "id": "3526042008",
    "district_id": "352604",
    "label": "Tajungan",
    "value": "Tajungan"
  },
  {
    "id": "3526042009",
    "district_id": "352604",
    "label": "Kebun",
    "value": "Kebun"
  },
  {
    "id": "3526042010",
    "district_id": "352604",
    "label": "Tangjungjati",
    "value": "Tangjungjati"
  },
  {
    "id": "3526052001",
    "district_id": "352605",
    "label": "Arosbaya",
    "value": "Arosbaya"
  },
  {
    "id": "3526052002",
    "district_id": "352605",
    "label": "Tengket",
    "value": "Tengket"
  },
  {
    "id": "3526052003",
    "district_id": "352605",
    "label": "Lajing",
    "value": "Lajing"
  },
  {
    "id": "3526052004",
    "district_id": "352605",
    "label": "Balung",
    "value": "Balung"
  },
  {
    "id": "3526052005",
    "district_id": "352605",
    "label": "Glagga",
    "value": "Glagga"
  },
  {
    "id": "3526052006",
    "district_id": "352605",
    "label": "Pandan Lanjang",
    "value": "Pandan Lanjang"
  },
  {
    "id": "3526052007",
    "district_id": "352605",
    "label": "Batonaong",
    "value": "Batonaong"
  },
  {
    "id": "3526052008",
    "district_id": "352605",
    "label": "Ombul",
    "value": "Ombul"
  },
  {
    "id": "3526052009",
    "district_id": "352605",
    "label": "Karang Duwak",
    "value": "Karang Duwak"
  },
  {
    "id": "3526052010",
    "district_id": "352605",
    "label": "Berbeluk",
    "value": "Berbeluk"
  },
  {
    "id": "3526052011",
    "district_id": "352605",
    "label": "Buduran",
    "value": "Buduran"
  },
  {
    "id": "3526052012",
    "district_id": "352605",
    "label": "Tambegan",
    "value": "Tambegan"
  },
  {
    "id": "3526052013",
    "district_id": "352605",
    "label": "Cendagah",
    "value": "Cendagah"
  },
  {
    "id": "3526052014",
    "district_id": "352605",
    "label": "Mangkon",
    "value": "Mangkon"
  },
  {
    "id": "3526052015",
    "district_id": "352605",
    "label": "Karang Pao",
    "value": "Karang Pao"
  },
  {
    "id": "3526052016",
    "district_id": "352605",
    "label": "Makam Agung",
    "value": "Makam Agung"
  },
  {
    "id": "3526052017",
    "district_id": "352605",
    "label": "Dlemer",
    "value": "Dlemer"
  },
  {
    "id": "3526052018",
    "district_id": "352605",
    "label": "Plakaran",
    "value": "Plakaran"
  },
  {
    "id": "3526062001",
    "district_id": "352606",
    "label": "Kombangan",
    "value": "Kombangan"
  },
  {
    "id": "3526062002",
    "district_id": "352606",
    "label": "Banyoneng Laok",
    "value": "Banyoneng Laok"
  },
  {
    "id": "3526062003",
    "district_id": "352606",
    "label": "Katol Barat",
    "value": "Katol Barat"
  },
  {
    "id": "3526062004",
    "district_id": "352606",
    "label": "Dabung",
    "value": "Dabung"
  },
  {
    "id": "3526062005",
    "district_id": "352606",
    "label": "Lerpak",
    "value": "Lerpak"
  },
  {
    "id": "3526062006",
    "district_id": "352606",
    "label": "Geger",
    "value": "Geger"
  },
  {
    "id": "3526062007",
    "district_id": "352606",
    "label": "Togubang",
    "value": "Togubang"
  },
  {
    "id": "3526062008",
    "district_id": "352606",
    "label": "Batobella",
    "value": "Batobella"
  },
  {
    "id": "3526062009",
    "district_id": "352606",
    "label": "Kampak",
    "value": "Kampak"
  },
  {
    "id": "3526062010",
    "district_id": "352606",
    "label": "Campor",
    "value": "Campor"
  },
  {
    "id": "3526062011",
    "district_id": "352606",
    "label": "Kompol",
    "value": "Kompol"
  },
  {
    "id": "3526062012",
    "district_id": "352606",
    "label": "Tegar Priyah",
    "value": "Tegar Priyah"
  },
  {
    "id": "3526062013",
    "district_id": "352606",
    "label": "Banyoneng Dajah",
    "value": "Banyoneng Dajah"
  },
  {
    "id": "3526072001",
    "district_id": "352607",
    "label": "Klampis Barat",
    "value": "Klampis Barat"
  },
  {
    "id": "3526072002",
    "district_id": "352607",
    "label": "Bulukagung",
    "value": "Bulukagung"
  },
  {
    "id": "3526072003",
    "district_id": "352607",
    "label": "Mrandung",
    "value": "Mrandung"
  },
  {
    "id": "3526072004",
    "district_id": "352607",
    "label": "Tobaddung",
    "value": "Tobaddung"
  },
  {
    "id": "3526072005",
    "district_id": "352607",
    "label": "Tolbuk",
    "value": "Tolbuk"
  },
  {
    "id": "3526072006",
    "district_id": "352607",
    "label": "Moarah",
    "value": "Moarah"
  },
  {
    "id": "3526072007",
    "district_id": "352607",
    "label": "Ra'as",
    "value": "Ra'as"
  },
  {
    "id": "3526072008",
    "district_id": "352607",
    "label": "Polongan",
    "value": "Polongan"
  },
  {
    "id": "3526072009",
    "district_id": "352607",
    "label": "Karang Asem",
    "value": "Karang Asem"
  },
  {
    "id": "3526072010",
    "district_id": "352607",
    "label": "Trogan",
    "value": "Trogan"
  },
  {
    "id": "3526072011",
    "district_id": "352607",
    "label": "Bulung",
    "value": "Bulung"
  },
  {
    "id": "3526072012",
    "district_id": "352607",
    "label": "Banteyan",
    "value": "Banteyan"
  },
  {
    "id": "3526072013",
    "district_id": "352607",
    "label": "Bragang",
    "value": "Bragang"
  },
  {
    "id": "3526072014",
    "district_id": "352607",
    "label": "Lergunong",
    "value": "Lergunong"
  },
  {
    "id": "3526072015",
    "district_id": "352607",
    "label": "Panyaksagan",
    "value": "Panyaksagan"
  },
  {
    "id": "3526072016",
    "district_id": "352607",
    "label": "Manonggal",
    "value": "Manonggal"
  },
  {
    "id": "3526072017",
    "district_id": "352607",
    "label": "Bator",
    "value": "Bator"
  },
  {
    "id": "3526072018",
    "district_id": "352607",
    "label": "Klampis Timur",
    "value": "Klampis Timur"
  },
  {
    "id": "3526072019",
    "district_id": "352607",
    "label": "Tenggun Dajah",
    "value": "Tenggun Dajah"
  },
  {
    "id": "3526072020",
    "district_id": "352607",
    "label": "Larangan Sorjan",
    "value": "Larangan Sorjan"
  },
  {
    "id": "3526072021",
    "district_id": "352607",
    "label": "Larangan Glintong",
    "value": "Larangan Glintong"
  },
  {
    "id": "3526072022",
    "district_id": "352607",
    "label": "Ko'ol",
    "value": "Ko'ol"
  },
  {
    "id": "3526082001",
    "district_id": "352608",
    "label": "Sepulu",
    "value": "Sepulu"
  },
  {
    "id": "3526082002",
    "district_id": "352608",
    "label": "Maneron",
    "value": "Maneron"
  },
  {
    "id": "3526082003",
    "district_id": "352608",
    "label": "Gangseyan",
    "value": "Gangseyan"
  },
  {
    "id": "3526082004",
    "district_id": "352608",
    "label": "Klapayan",
    "value": "Klapayan"
  },
  {
    "id": "3526082005",
    "district_id": "352608",
    "label": "Bangsereh",
    "value": "Bangsereh"
  },
  {
    "id": "3526082006",
    "district_id": "352608",
    "label": "Kelbung",
    "value": "Kelbung"
  },
  {
    "id": "3526082007",
    "district_id": "352608",
    "label": "Saplasah",
    "value": "Saplasah"
  },
  {
    "id": "3526082008",
    "district_id": "352608",
    "label": "Genelap",
    "value": "Genelap"
  },
  {
    "id": "3526082009",
    "district_id": "352608",
    "label": "Klabetan",
    "value": "Klabetan"
  },
  {
    "id": "3526082010",
    "district_id": "352608",
    "label": "Banyior",
    "value": "Banyior"
  },
  {
    "id": "3526082011",
    "district_id": "352608",
    "label": "Prancak",
    "value": "Prancak"
  },
  {
    "id": "3526082012",
    "district_id": "352608",
    "label": "Labuhan",
    "value": "Labuhan"
  },
  {
    "id": "3526082013",
    "district_id": "352608",
    "label": "Tanagura Barat",
    "value": "Tanagura Barat"
  },
  {
    "id": "3526082014",
    "district_id": "352608",
    "label": "Lembung Paseser",
    "value": "Lembung Paseser"
  },
  {
    "id": "3526082015",
    "district_id": "352608",
    "label": "Tanagura Timur",
    "value": "Tanagura Timur"
  },
  {
    "id": "3526092001",
    "district_id": "352609",
    "label": "Paseseh",
    "value": "Paseseh"
  },
  {
    "id": "3526092002",
    "district_id": "352609",
    "label": "Bumi Anyar",
    "value": "Bumi Anyar"
  },
  {
    "id": "3526092003",
    "district_id": "352609",
    "label": "Tambak Pocok",
    "value": "Tambak Pocok"
  },
  {
    "id": "3526092004",
    "district_id": "352609",
    "label": "Larangan Timur",
    "value": "Larangan Timur"
  },
  {
    "id": "3526092005",
    "district_id": "352609",
    "label": "Bungkeng",
    "value": "Bungkeng"
  },
  {
    "id": "3526092006",
    "district_id": "352609",
    "label": "Bandang Dajah",
    "value": "Bandang Dajah"
  },
  {
    "id": "3526092007",
    "district_id": "352609",
    "label": "Tagungguh",
    "value": "Tagungguh"
  },
  {
    "id": "3526092008",
    "district_id": "352609",
    "label": "Planggiran",
    "value": "Planggiran"
  },
  {
    "id": "3526092009",
    "district_id": "352609",
    "label": "Aeng Taber",
    "value": "Aeng Taber"
  },
  {
    "id": "3526092010",
    "district_id": "352609",
    "label": "Banyusangka",
    "value": "Banyusangka"
  },
  {
    "id": "3526092011",
    "district_id": "352609",
    "label": "Tlangoh",
    "value": "Tlangoh"
  },
  {
    "id": "3526092012",
    "district_id": "352609",
    "label": "Macajah",
    "value": "Macajah"
  },
  {
    "id": "3526092013",
    "district_id": "352609",
    "label": "Tanjung Bumi",
    "value": "Tanjung Bumi"
  },
  {
    "id": "3526092014",
    "district_id": "352609",
    "label": "Telaga Biru",
    "value": "Telaga Biru"
  },
  {
    "id": "3526102001",
    "district_id": "352610",
    "label": "Dupok",
    "value": "Dupok"
  },
  {
    "id": "3526102002",
    "district_id": "352610",
    "label": "Bandasoleh",
    "value": "Bandasoleh"
  },
  {
    "id": "3526102003",
    "district_id": "352610",
    "label": "Batokorogan",
    "value": "Batokorogan"
  },
  {
    "id": "3526102004",
    "district_id": "352610",
    "label": "Tramok",
    "value": "Tramok"
  },
  {
    "id": "3526102005",
    "district_id": "352610",
    "label": "Katol Timur",
    "value": "Katol Timur"
  },
  {
    "id": "3526102006",
    "district_id": "352610",
    "label": "Lembung Gunong",
    "value": "Lembung Gunong"
  },
  {
    "id": "3526102007",
    "district_id": "352610",
    "label": "Ampara'an",
    "value": "Ampara'an"
  },
  {
    "id": "3526102008",
    "district_id": "352610",
    "label": "Kokop",
    "value": "Kokop"
  },
  {
    "id": "3526102009",
    "district_id": "352610",
    "label": "Tlokoh",
    "value": "Tlokoh"
  },
  {
    "id": "3526102010",
    "district_id": "352610",
    "label": "Durjan",
    "value": "Durjan"
  },
  {
    "id": "3526102011",
    "district_id": "352610",
    "label": "Mano'an",
    "value": "Mano'an"
  },
  {
    "id": "3526102012",
    "district_id": "352610",
    "label": "Mandung",
    "value": "Mandung"
  },
  {
    "id": "3526102013",
    "district_id": "352610",
    "label": "Bandang Laok",
    "value": "Bandang Laok"
  },
  {
    "id": "3526112001",
    "district_id": "352611",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3526112002",
    "district_id": "352611",
    "label": "Kwanyar Barat",
    "value": "Kwanyar Barat"
  },
  {
    "id": "3526112003",
    "district_id": "352611",
    "label": "Ketetang",
    "value": "Ketetang"
  },
  {
    "id": "3526112004",
    "district_id": "352611",
    "label": "Dlemer",
    "value": "Dlemer"
  },
  {
    "id": "3526112005",
    "district_id": "352611",
    "label": "Morombuh",
    "value": "Morombuh"
  },
  {
    "id": "3526112006",
    "district_id": "352611",
    "label": "Tebul",
    "value": "Tebul"
  },
  {
    "id": "3526112007",
    "district_id": "352611",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3526112008",
    "district_id": "352611",
    "label": "Janteh",
    "value": "Janteh"
  },
  {
    "id": "3526112009",
    "district_id": "352611",
    "label": "Somor Koneng",
    "value": "Somor Koneng"
  },
  {
    "id": "3526112010",
    "district_id": "352611",
    "label": "Paoran",
    "value": "Paoran"
  },
  {
    "id": "3526112011",
    "district_id": "352611",
    "label": "Karang Entang",
    "value": "Karang Entang"
  },
  {
    "id": "3526112012",
    "district_id": "352611",
    "label": "Batah Barat",
    "value": "Batah Barat"
  },
  {
    "id": "3526112013",
    "district_id": "352611",
    "label": "Batah Timur",
    "value": "Batah Timur"
  },
  {
    "id": "3526112014",
    "district_id": "352611",
    "label": "Pandanan",
    "value": "Pandanan"
  },
  {
    "id": "3526112015",
    "district_id": "352611",
    "label": "Gunung Sereng",
    "value": "Gunung Sereng"
  },
  {
    "id": "3526112016",
    "district_id": "352611",
    "label": "Duwek Buter",
    "value": "Duwek Buter"
  },
  {
    "id": "3526122001",
    "district_id": "352612",
    "label": "Kesek",
    "value": "Kesek"
  },
  {
    "id": "3526122002",
    "district_id": "352612",
    "label": "Labang",
    "value": "Labang"
  },
  {
    "id": "3526122003",
    "district_id": "352612",
    "label": "Jukong",
    "value": "Jukong"
  },
  {
    "id": "3526122004",
    "district_id": "352612",
    "label": "Pangpong",
    "value": "Pangpong"
  },
  {
    "id": "3526122005",
    "district_id": "352612",
    "label": "Sukolilo Timur",
    "value": "Sukolilo Timur"
  },
  {
    "id": "3526122006",
    "district_id": "352612",
    "label": "Sukolilo Barat",
    "value": "Sukolilo Barat"
  },
  {
    "id": "3526122007",
    "district_id": "352612",
    "label": "Bringen",
    "value": "Bringen"
  },
  {
    "id": "3526122008",
    "district_id": "352612",
    "label": "Ba'engas",
    "value": "Ba'engas"
  },
  {
    "id": "3526122009",
    "district_id": "352612",
    "label": "Bunajih",
    "value": "Bunajih"
  },
  {
    "id": "3526122010",
    "district_id": "352612",
    "label": "Sendang Laok",
    "value": "Sendang Laok"
  },
  {
    "id": "3526122011",
    "district_id": "352612",
    "label": "Sendang Dajah",
    "value": "Sendang Dajah"
  },
  {
    "id": "3526122012",
    "district_id": "352612",
    "label": "Petapan",
    "value": "Petapan"
  },
  {
    "id": "3526122013",
    "district_id": "352612",
    "label": "Morkepek",
    "value": "Morkepek"
  },
  {
    "id": "3526132001",
    "district_id": "352613",
    "label": "Tanah Merah Dajah",
    "value": "Tanah Merah Dajah"
  },
  {
    "id": "3526132002",
    "district_id": "352613",
    "label": "Tanah Merah Laok",
    "value": "Tanah Merah Laok"
  },
  {
    "id": "3526132003",
    "district_id": "352613",
    "label": "Petrah",
    "value": "Petrah"
  },
  {
    "id": "3526132004",
    "district_id": "352613",
    "label": "Jangkar",
    "value": "Jangkar"
  },
  {
    "id": "3526132005",
    "district_id": "352613",
    "label": "Pettong",
    "value": "Pettong"
  },
  {
    "id": "3526132006",
    "district_id": "352613",
    "label": "Kendaban",
    "value": "Kendaban"
  },
  {
    "id": "3526132007",
    "district_id": "352613",
    "label": "Dumajah",
    "value": "Dumajah"
  },
  {
    "id": "3526132008",
    "district_id": "352613",
    "label": "Pacentan",
    "value": "Pacentan"
  },
  {
    "id": "3526132009",
    "district_id": "352613",
    "label": "Baipajung",
    "value": "Baipajung"
  },
  {
    "id": "3526132010",
    "district_id": "352613",
    "label": "Tlomar",
    "value": "Tlomar"
  },
  {
    "id": "3526132011",
    "district_id": "352613",
    "label": "Rongdurin",
    "value": "Rongdurin"
  },
  {
    "id": "3526132012",
    "district_id": "352613",
    "label": "Landak",
    "value": "Landak"
  },
  {
    "id": "3526132013",
    "district_id": "352613",
    "label": "Basanah",
    "value": "Basanah"
  },
  {
    "id": "3526132014",
    "district_id": "352613",
    "label": "Poter",
    "value": "Poter"
  },
  {
    "id": "3526132015",
    "district_id": "352613",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3526132016",
    "district_id": "352613",
    "label": "Dlambah Dajah",
    "value": "Dlambah Dajah"
  },
  {
    "id": "3526132017",
    "district_id": "352613",
    "label": "Dlambah Laok",
    "value": "Dlambah Laok"
  },
  {
    "id": "3526132018",
    "district_id": "352613",
    "label": "Mrecah",
    "value": "Mrecah"
  },
  {
    "id": "3526132019",
    "district_id": "352613",
    "label": "Buddan",
    "value": "Buddan"
  },
  {
    "id": "3526132020",
    "district_id": "352613",
    "label": "Batangan",
    "value": "Batangan"
  },
  {
    "id": "3526132021",
    "district_id": "352613",
    "label": "Padurungan",
    "value": "Padurungan"
  },
  {
    "id": "3526132022",
    "district_id": "352613",
    "label": "Pangeleyan",
    "value": "Pangeleyan"
  },
  {
    "id": "3526132023",
    "district_id": "352613",
    "label": "Kranggan Barat",
    "value": "Kranggan Barat"
  },
  {
    "id": "3526142001",
    "district_id": "352614",
    "label": "Soket Laok",
    "value": "Soket Laok"
  },
  {
    "id": "3526142002",
    "district_id": "352614",
    "label": "Soket Dajah",
    "value": "Soket Dajah"
  },
  {
    "id": "3526142003",
    "district_id": "352614",
    "label": "Pamorah",
    "value": "Pamorah"
  },
  {
    "id": "3526142004",
    "district_id": "352614",
    "label": "Banyubeseh",
    "value": "Banyubeseh"
  },
  {
    "id": "3526142005",
    "district_id": "352614",
    "label": "Pocong",
    "value": "Pocong"
  },
  {
    "id": "3526142006",
    "district_id": "352614",
    "label": "Tragah",
    "value": "Tragah"
  },
  {
    "id": "3526142007",
    "district_id": "352614",
    "label": "Karang Leman",
    "value": "Karang Leman"
  },
  {
    "id": "3526142008",
    "district_id": "352614",
    "label": "Duko Tambin",
    "value": "Duko Tambin"
  },
  {
    "id": "3526142009",
    "district_id": "352614",
    "label": "Keteleng",
    "value": "Keteleng"
  },
  {
    "id": "3526142010",
    "district_id": "352614",
    "label": "Alang Alang",
    "value": "Alang Alang"
  },
  {
    "id": "3526142011",
    "district_id": "352614",
    "label": "Kemoning",
    "value": "Kemoning"
  },
  {
    "id": "3526142012",
    "district_id": "352614",
    "label": "Tambin",
    "value": "Tambin"
  },
  {
    "id": "3526142013",
    "district_id": "352614",
    "label": "Bajeman",
    "value": "Bajeman"
  },
  {
    "id": "3526142014",
    "district_id": "352614",
    "label": "Jaddung",
    "value": "Jaddung"
  },
  {
    "id": "3526142015",
    "district_id": "352614",
    "label": "Pacangan",
    "value": "Pacangan"
  },
  {
    "id": "3526142016",
    "district_id": "352614",
    "label": "Bancang",
    "value": "Bancang"
  },
  {
    "id": "3526142017",
    "district_id": "352614",
    "label": "Ja'ah",
    "value": "Ja'ah"
  },
  {
    "id": "3526142018",
    "district_id": "352614",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3526152001",
    "district_id": "352615",
    "label": "Blega",
    "value": "Blega"
  },
  {
    "id": "3526152002",
    "district_id": "352615",
    "label": "Rosep",
    "value": "Rosep"
  },
  {
    "id": "3526152003",
    "district_id": "352615",
    "label": "Nyor Manes",
    "value": "Nyor Manes"
  },
  {
    "id": "3526152004",
    "district_id": "352615",
    "label": "Alas Rajah",
    "value": "Alas Rajah"
  },
  {
    "id": "3526152005",
    "district_id": "352615",
    "label": "Blega Oloh",
    "value": "Blega Oloh"
  },
  {
    "id": "3526152006",
    "district_id": "352615",
    "label": "Karang Nangkah",
    "value": "Karang Nangkah"
  },
  {
    "id": "3526152007",
    "district_id": "352615",
    "label": "Karang Panasan",
    "value": "Karang Panasan"
  },
  {
    "id": "3526152008",
    "district_id": "352615",
    "label": "Karang Gayam",
    "value": "Karang Gayam"
  },
  {
    "id": "3526152009",
    "district_id": "352615",
    "label": "Lomaer",
    "value": "Lomaer"
  },
  {
    "id": "3526152010",
    "district_id": "352615",
    "label": "Bates",
    "value": "Bates"
  },
  {
    "id": "3526152011",
    "district_id": "352615",
    "label": "Lombang Laok",
    "value": "Lombang Laok"
  },
  {
    "id": "3526152012",
    "district_id": "352615",
    "label": "Lombang Dajah",
    "value": "Lombang Dajah"
  },
  {
    "id": "3526152013",
    "district_id": "352615",
    "label": "Karpote",
    "value": "Karpote"
  },
  {
    "id": "3526152014",
    "district_id": "352615",
    "label": "Kajjan",
    "value": "Kajjan"
  },
  {
    "id": "3526152015",
    "district_id": "352615",
    "label": "Panjalinan",
    "value": "Panjalinan"
  },
  {
    "id": "3526152016",
    "district_id": "352615",
    "label": "Pangeran Gadungan",
    "value": "Pangeran Gadungan"
  },
  {
    "id": "3526152017",
    "district_id": "352615",
    "label": "Kampao",
    "value": "Kampao"
  },
  {
    "id": "3526152018",
    "district_id": "352615",
    "label": "Ko'olan",
    "value": "Ko'olan"
  },
  {
    "id": "3526152019",
    "district_id": "352615",
    "label": "Gigir",
    "value": "Gigir"
  },
  {
    "id": "3526162001",
    "district_id": "352616",
    "label": "Patereman",
    "value": "Patereman"
  },
  {
    "id": "3526162002",
    "district_id": "352616",
    "label": "Paeng",
    "value": "Paeng"
  },
  {
    "id": "3526162003",
    "district_id": "352616",
    "label": "Kolla",
    "value": "Kolla"
  },
  {
    "id": "3526162004",
    "district_id": "352616",
    "label": "Neroh",
    "value": "Neroh"
  },
  {
    "id": "3526162005",
    "district_id": "352616",
    "label": "Langpanggang",
    "value": "Langpanggang"
  },
  {
    "id": "3526162006",
    "district_id": "352616",
    "label": "Patenteng",
    "value": "Patenteng"
  },
  {
    "id": "3526162007",
    "district_id": "352616",
    "label": "Srabi Barat",
    "value": "Srabi Barat"
  },
  {
    "id": "3526162008",
    "district_id": "352616",
    "label": "Srabi Timur",
    "value": "Srabi Timur"
  },
  {
    "id": "3526162009",
    "district_id": "352616",
    "label": "Pangpajung",
    "value": "Pangpajung"
  },
  {
    "id": "3526162010",
    "district_id": "352616",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3526162011",
    "district_id": "352616",
    "label": "Alas Kokon",
    "value": "Alas Kokon"
  },
  {
    "id": "3526162012",
    "district_id": "352616",
    "label": "Pakong",
    "value": "Pakong"
  },
  {
    "id": "3526162013",
    "district_id": "352616",
    "label": "Glisgis",
    "value": "Glisgis"
  },
  {
    "id": "3526162014",
    "district_id": "352616",
    "label": "Brakas Dajah",
    "value": "Brakas Dajah"
  },
  {
    "id": "3526162015",
    "district_id": "352616",
    "label": "Modung",
    "value": "Modung"
  },
  {
    "id": "3526162016",
    "district_id": "352616",
    "label": "Suwa'an",
    "value": "Suwa'an"
  },
  {
    "id": "3526162017",
    "district_id": "352616",
    "label": "Mangga'an",
    "value": "Mangga'an"
  },
  {
    "id": "3526172001",
    "district_id": "352617",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3526172002",
    "district_id": "352617",
    "label": "Konang",
    "value": "Konang"
  },
  {
    "id": "3526172003",
    "district_id": "352617",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "3526172004",
    "district_id": "352617",
    "label": "Pakes",
    "value": "Pakes"
  },
  {
    "id": "3526172005",
    "district_id": "352617",
    "label": "Cangkareman",
    "value": "Cangkareman"
  },
  {
    "id": "3526172006",
    "district_id": "352617",
    "label": "Sen Asen",
    "value": "Sen Asen"
  },
  {
    "id": "3526172007",
    "district_id": "352617",
    "label": "Sambiyan",
    "value": "Sambiyan"
  },
  {
    "id": "3526172008",
    "district_id": "352617",
    "label": "Campor",
    "value": "Campor"
  },
  {
    "id": "3526172009",
    "district_id": "352617",
    "label": "Galis Dajah",
    "value": "Galis Dajah"
  },
  {
    "id": "3526172010",
    "district_id": "352617",
    "label": "Kanegarah",
    "value": "Kanegarah"
  },
  {
    "id": "3526172011",
    "district_id": "352617",
    "label": "Durin Barat",
    "value": "Durin Barat"
  },
  {
    "id": "3526172012",
    "district_id": "352617",
    "label": "Durin Timur",
    "value": "Durin Timur"
  },
  {
    "id": "3526172013",
    "district_id": "352617",
    "label": "Batokaban",
    "value": "Batokaban"
  },
  {
    "id": "3526182001",
    "district_id": "352618",
    "label": "Tellok",
    "value": "Tellok"
  },
  {
    "id": "3526182002",
    "district_id": "352618",
    "label": "Daleman",
    "value": "Daleman"
  },
  {
    "id": "3526182003",
    "district_id": "352618",
    "label": "Blateran",
    "value": "Blateran"
  },
  {
    "id": "3526182004",
    "district_id": "352618",
    "label": "Paterongan",
    "value": "Paterongan"
  },
  {
    "id": "3526182005",
    "district_id": "352618",
    "label": "Kajuanak",
    "value": "Kajuanak"
  },
  {
    "id": "3526182006",
    "district_id": "352618",
    "label": "Longkek",
    "value": "Longkek"
  },
  {
    "id": "3526182007",
    "district_id": "352618",
    "label": "Galis",
    "value": "Galis"
  },
  {
    "id": "3526182008",
    "district_id": "352618",
    "label": "Pekadan",
    "value": "Pekadan"
  },
  {
    "id": "3526182009",
    "district_id": "352618",
    "label": "Bangpendah",
    "value": "Bangpendah"
  },
  {
    "id": "3526182010",
    "district_id": "352618",
    "label": "Paka'an Dajah",
    "value": "Paka'an Dajah"
  },
  {
    "id": "3526182011",
    "district_id": "352618",
    "label": "Paka'an Laok",
    "value": "Paka'an Laok"
  },
  {
    "id": "3526182012",
    "district_id": "352618",
    "label": "Kranggan Timur",
    "value": "Kranggan Timur"
  },
  {
    "id": "3526182013",
    "district_id": "352618",
    "label": "Separah",
    "value": "Separah"
  },
  {
    "id": "3526182014",
    "district_id": "352618",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3526182015",
    "district_id": "352618",
    "label": "Tlagah",
    "value": "Tlagah"
  },
  {
    "id": "3526182016",
    "district_id": "352618",
    "label": "Lantek Barat",
    "value": "Lantek Barat"
  },
  {
    "id": "3526182017",
    "district_id": "352618",
    "label": "Lantek Timor",
    "value": "Lantek Timor"
  },
  {
    "id": "3526182018",
    "district_id": "352618",
    "label": "Kelbung",
    "value": "Kelbung"
  },
  {
    "id": "3526182019",
    "district_id": "352618",
    "label": "Banyu Bunih",
    "value": "Banyu Bunih"
  },
  {
    "id": "3526182020",
    "district_id": "352618",
    "label": "Sadah",
    "value": "Sadah"
  },
  {
    "id": "3526182021",
    "district_id": "352618",
    "label": "Sorpa",
    "value": "Sorpa"
  },
  {
    "id": "3527012001",
    "district_id": "352701",
    "label": "Noreh",
    "value": "Noreh"
  },
  {
    "id": "3527012002",
    "district_id": "352701",
    "label": "Labuhan",
    "value": "Labuhan"
  },
  {
    "id": "3527012003",
    "district_id": "352701",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3527012004",
    "district_id": "352701",
    "label": "Sreseh",
    "value": "Sreseh"
  },
  {
    "id": "3527012005",
    "district_id": "352701",
    "label": "Disanah",
    "value": "Disanah"
  },
  {
    "id": "3527012006",
    "district_id": "352701",
    "label": "Marparan",
    "value": "Marparan"
  },
  {
    "id": "3527012007",
    "district_id": "352701",
    "label": "Klobur",
    "value": "Klobur"
  },
  {
    "id": "3527012008",
    "district_id": "352701",
    "label": "Labang",
    "value": "Labang"
  },
  {
    "id": "3527012009",
    "district_id": "352701",
    "label": "Bundah",
    "value": "Bundah"
  },
  {
    "id": "3527012010",
    "district_id": "352701",
    "label": "Bangsah",
    "value": "Bangsah"
  },
  {
    "id": "3527012011",
    "district_id": "352701",
    "label": "Plasah",
    "value": "Plasah"
  },
  {
    "id": "3527012012",
    "district_id": "352701",
    "label": "Junok",
    "value": "Junok"
  },
  {
    "id": "3527022007",
    "district_id": "352702",
    "label": "Dulang",
    "value": "Dulang"
  },
  {
    "id": "3527022008",
    "district_id": "352702",
    "label": "Patarongan",
    "value": "Patarongan"
  },
  {
    "id": "3527022009",
    "district_id": "352702",
    "label": "Pangongsean",
    "value": "Pangongsean"
  },
  {
    "id": "3527022010",
    "district_id": "352702",
    "label": "Krampon",
    "value": "Krampon"
  },
  {
    "id": "3527022011",
    "district_id": "352702",
    "label": "Bringinnonggal",
    "value": "Bringinnonggal"
  },
  {
    "id": "3527022012",
    "district_id": "352702",
    "label": "Torjun",
    "value": "Torjun"
  },
  {
    "id": "3527022013",
    "district_id": "352702",
    "label": "Patapan",
    "value": "Patapan"
  },
  {
    "id": "3527022014",
    "district_id": "352702",
    "label": "Jeruk Porot",
    "value": "Jeruk Porot"
  },
  {
    "id": "3527022015",
    "district_id": "352702",
    "label": "Kodak",
    "value": "Kodak"
  },
  {
    "id": "3527022016",
    "district_id": "352702",
    "label": "Kanjar",
    "value": "Kanjar"
  },
  {
    "id": "3527022017",
    "district_id": "352702",
    "label": "Kara",
    "value": "Kara"
  },
  {
    "id": "3527022018",
    "district_id": "352702",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "3527031003",
    "district_id": "352703",
    "label": "Polagan",
    "value": "Polagan"
  },
  {
    "id": "3527031004",
    "district_id": "352703",
    "label": "Banyuanyar",
    "value": "Banyuanyar"
  },
  {
    "id": "3527031006",
    "district_id": "352703",
    "label": "Rongtengah",
    "value": "Rongtengah"
  },
  {
    "id": "3527031007",
    "district_id": "352703",
    "label": "Karang Dalam",
    "value": "Karang Dalam"
  },
  {
    "id": "3527031008",
    "district_id": "352703",
    "label": "Gunung Sekar",
    "value": "Gunung Sekar"
  },
  {
    "id": "3527031009",
    "district_id": "352703",
    "label": "Dalpenang",
    "value": "Dalpenang"
  },
  {
    "id": "3527032001",
    "district_id": "352703",
    "label": "P. Mandingan",
    "value": "P. Mandingan"
  },
  {
    "id": "3527032002",
    "district_id": "352703",
    "label": "Aengsareh",
    "value": "Aengsareh"
  },
  {
    "id": "3527032005",
    "district_id": "352703",
    "label": "Gunung Maddah",
    "value": "Gunung Maddah"
  },
  {
    "id": "3527032010",
    "district_id": "352703",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "3527032011",
    "district_id": "352703",
    "label": "Taman Sareh",
    "value": "Taman Sareh"
  },
  {
    "id": "3527032012",
    "district_id": "352703",
    "label": "Pakalongan",
    "value": "Pakalongan"
  },
  {
    "id": "3527032013",
    "district_id": "352703",
    "label": "Tanggumong",
    "value": "Tanggumong"
  },
  {
    "id": "3527032014",
    "district_id": "352703",
    "label": "Kamuning",
    "value": "Kamuning"
  },
  {
    "id": "3527032015",
    "district_id": "352703",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "3527032016",
    "district_id": "352703",
    "label": "Pangelen",
    "value": "Pangelen"
  },
  {
    "id": "3527032017",
    "district_id": "352703",
    "label": "Paseyan",
    "value": "Paseyan"
  },
  {
    "id": "3527032018",
    "district_id": "352703",
    "label": "Baruh",
    "value": "Baruh"
  },
  {
    "id": "3527042001",
    "district_id": "352704",
    "label": "Taddan",
    "value": "Taddan"
  },
  {
    "id": "3527042002",
    "district_id": "352704",
    "label": "Banjar Talela",
    "value": "Banjar Talela"
  },
  {
    "id": "3527042003",
    "district_id": "352704",
    "label": "Tamba'an",
    "value": "Tamba'an"
  },
  {
    "id": "3527042004",
    "district_id": "352704",
    "label": "Prajjan",
    "value": "Prajjan"
  },
  {
    "id": "3527042005",
    "district_id": "352704",
    "label": "Dharma Camplong",
    "value": "Dharma Camplong"
  },
  {
    "id": "3527042006",
    "district_id": "352704",
    "label": "Batukarang",
    "value": "Batukarang"
  },
  {
    "id": "3527042007",
    "district_id": "352704",
    "label": "Sejati",
    "value": "Sejati"
  },
  {
    "id": "3527042008",
    "district_id": "352704",
    "label": "Dharma Tanjung",
    "value": "Dharma Tanjung"
  },
  {
    "id": "3527042009",
    "district_id": "352704",
    "label": "Rabasan",
    "value": "Rabasan"
  },
  {
    "id": "3527042010",
    "district_id": "352704",
    "label": "Banjartabulu",
    "value": "Banjartabulu"
  },
  {
    "id": "3527042011",
    "district_id": "352704",
    "label": "Anggersek",
    "value": "Anggersek"
  },
  {
    "id": "3527042012",
    "district_id": "352704",
    "label": "Madupat",
    "value": "Madupat"
  },
  {
    "id": "3527042013",
    "district_id": "352704",
    "label": "Pamola'an",
    "value": "Pamola'an"
  },
  {
    "id": "3527042014",
    "district_id": "352704",
    "label": "Plampa'an",
    "value": "Plampa'an"
  },
  {
    "id": "3527052001",
    "district_id": "352705",
    "label": "Kebun Sareh",
    "value": "Kebun Sareh"
  },
  {
    "id": "3527052002",
    "district_id": "352705",
    "label": "Karangnangger",
    "value": "Karangnangger"
  },
  {
    "id": "3527052003",
    "district_id": "352705",
    "label": "Rapa Laok",
    "value": "Rapa Laok"
  },
  {
    "id": "3527052004",
    "district_id": "352705",
    "label": "Rapa Daya",
    "value": "Rapa Daya"
  },
  {
    "id": "3527052005",
    "district_id": "352705",
    "label": "Astapah",
    "value": "Astapah"
  },
  {
    "id": "3527052006",
    "district_id": "352705",
    "label": "Sogian",
    "value": "Sogian"
  },
  {
    "id": "3527052007",
    "district_id": "352705",
    "label": "Gersempal",
    "value": "Gersempal"
  },
  {
    "id": "3527052008",
    "district_id": "352705",
    "label": "Temoran",
    "value": "Temoran"
  },
  {
    "id": "3527052009",
    "district_id": "352705",
    "label": "Meteng",
    "value": "Meteng"
  },
  {
    "id": "3527052010",
    "district_id": "352705",
    "label": "Madulang",
    "value": "Madulang"
  },
  {
    "id": "3527052011",
    "district_id": "352705",
    "label": "Kamondung",
    "value": "Kamondung"
  },
  {
    "id": "3527052012",
    "district_id": "352705",
    "label": "Omben",
    "value": "Omben"
  },
  {
    "id": "3527052013",
    "district_id": "352705",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3527052014",
    "district_id": "352705",
    "label": "Angsokah",
    "value": "Angsokah"
  },
  {
    "id": "3527052015",
    "district_id": "352705",
    "label": "Jranguan",
    "value": "Jranguan"
  },
  {
    "id": "3527052016",
    "district_id": "352705",
    "label": "Rongdalam",
    "value": "Rongdalam"
  },
  {
    "id": "3527052017",
    "district_id": "352705",
    "label": "Napo Laok",
    "value": "Napo Laok"
  },
  {
    "id": "3527052018",
    "district_id": "352705",
    "label": "Napo Daya",
    "value": "Napo Daya"
  },
  {
    "id": "3527052019",
    "district_id": "352705",
    "label": "Karanggayam",
    "value": "Karanggayam"
  },
  {
    "id": "3527052020",
    "district_id": "352705",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "3527062001",
    "district_id": "352706",
    "label": "Banyukapah",
    "value": "Banyukapah"
  },
  {
    "id": "3527062002",
    "district_id": "352706",
    "label": "Rabasan",
    "value": "Rabasan"
  },
  {
    "id": "3527062003",
    "district_id": "352706",
    "label": "Rohayu",
    "value": "Rohayu"
  },
  {
    "id": "3527062004",
    "district_id": "352706",
    "label": "Kedungdung",
    "value": "Kedungdung"
  },
  {
    "id": "3527062005",
    "district_id": "352706",
    "label": "Komis",
    "value": "Komis"
  },
  {
    "id": "3527062006",
    "district_id": "352706",
    "label": "Bajrasoka",
    "value": "Bajrasoka"
  },
  {
    "id": "3527062007",
    "district_id": "352706",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3527062008",
    "district_id": "352706",
    "label": "Nyeloh",
    "value": "Nyeloh"
  },
  {
    "id": "3527062009",
    "district_id": "352706",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3527062010",
    "district_id": "352706",
    "label": "Ombul",
    "value": "Ombul"
  },
  {
    "id": "3527062011",
    "district_id": "352706",
    "label": "Pajeruan",
    "value": "Pajeruan"
  },
  {
    "id": "3527062012",
    "district_id": "352706",
    "label": "Moktesareh",
    "value": "Moktesareh"
  },
  {
    "id": "3527062013",
    "district_id": "352706",
    "label": "Batoporo Barat",
    "value": "Batoporo Barat"
  },
  {
    "id": "3527062014",
    "district_id": "352706",
    "label": "Batoporo Timur",
    "value": "Batoporo Timur"
  },
  {
    "id": "3527062015",
    "district_id": "352706",
    "label": "Gunungeleh",
    "value": "Gunungeleh"
  },
  {
    "id": "3527062016",
    "district_id": "352706",
    "label": "Daleman",
    "value": "Daleman"
  },
  {
    "id": "3527062017",
    "district_id": "352706",
    "label": "Pasarenan",
    "value": "Pasarenan"
  },
  {
    "id": "3527062018",
    "district_id": "352706",
    "label": "Palenggian",
    "value": "Palenggian"
  },
  {
    "id": "3527072001",
    "district_id": "352707",
    "label": "Asemraja",
    "value": "Asemraja"
  },
  {
    "id": "3527072002",
    "district_id": "352707",
    "label": "Plakaran",
    "value": "Plakaran"
  },
  {
    "id": "3527072003",
    "district_id": "352707",
    "label": "Asemnonggal",
    "value": "Asemnonggal"
  },
  {
    "id": "3527072004",
    "district_id": "352707",
    "label": "Majangan",
    "value": "Majangan"
  },
  {
    "id": "3527072005",
    "district_id": "352707",
    "label": "Margantoko",
    "value": "Margantoko"
  },
  {
    "id": "3527072006",
    "district_id": "352707",
    "label": "Penyepen",
    "value": "Penyepen"
  },
  {
    "id": "3527072007",
    "district_id": "352707",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3527072008",
    "district_id": "352707",
    "label": "Jrengik",
    "value": "Jrengik"
  },
  {
    "id": "3527072009",
    "district_id": "352707",
    "label": "Kalangan Prao",
    "value": "Kalangan Prao"
  },
  {
    "id": "3527072010",
    "district_id": "352707",
    "label": "Kotah",
    "value": "Kotah"
  },
  {
    "id": "3527072011",
    "district_id": "352707",
    "label": "Jungkarang",
    "value": "Jungkarang"
  },
  {
    "id": "3527072012",
    "district_id": "352707",
    "label": "Mlakah",
    "value": "Mlakah"
  },
  {
    "id": "3527072013",
    "district_id": "352707",
    "label": "Bancelok",
    "value": "Bancelok"
  },
  {
    "id": "3527072014",
    "district_id": "352707",
    "label": "Buker",
    "value": "Buker"
  },
  {
    "id": "3527082001",
    "district_id": "352708",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3527082002",
    "district_id": "352708",
    "label": "Baturasang",
    "value": "Baturasang"
  },
  {
    "id": "3527082003",
    "district_id": "352708",
    "label": "Mambulu Barat",
    "value": "Mambulu Barat"
  },
  {
    "id": "3527082004",
    "district_id": "352708",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3527082005",
    "district_id": "352708",
    "label": "Samaran",
    "value": "Samaran"
  },
  {
    "id": "3527082006",
    "district_id": "352708",
    "label": "Tambelangan",
    "value": "Tambelangan"
  },
  {
    "id": "3527082007",
    "district_id": "352708",
    "label": "Barunggagah",
    "value": "Barunggagah"
  },
  {
    "id": "3527082008",
    "district_id": "352708",
    "label": "Banjarbillah",
    "value": "Banjarbillah"
  },
  {
    "id": "3527082009",
    "district_id": "352708",
    "label": "Somber",
    "value": "Somber"
  },
  {
    "id": "3527082010",
    "district_id": "352708",
    "label": "Birem",
    "value": "Birem"
  },
  {
    "id": "3527092001",
    "district_id": "352709",
    "label": "Olor",
    "value": "Olor"
  },
  {
    "id": "3527092002",
    "district_id": "352709",
    "label": "Planggaran Barat",
    "value": "Planggaran Barat"
  },
  {
    "id": "3527092003",
    "district_id": "352709",
    "label": "Planggaran Timur",
    "value": "Planggaran Timur"
  },
  {
    "id": "3527092004",
    "district_id": "352709",
    "label": "Tolang",
    "value": "Tolang"
  },
  {
    "id": "3527092005",
    "district_id": "352709",
    "label": "Lar Lar",
    "value": "Lar Lar"
  },
  {
    "id": "3527092006",
    "district_id": "352709",
    "label": "Tlagah",
    "value": "Tlagah"
  },
  {
    "id": "3527092007",
    "district_id": "352709",
    "label": "Nagasareh",
    "value": "Nagasareh"
  },
  {
    "id": "3527092008",
    "district_id": "352709",
    "label": "Trapang",
    "value": "Trapang"
  },
  {
    "id": "3527092009",
    "district_id": "352709",
    "label": "Terosan",
    "value": "Terosan"
  },
  {
    "id": "3527092010",
    "district_id": "352709",
    "label": "Asem Jaran",
    "value": "Asem Jaran"
  },
  {
    "id": "3527092011",
    "district_id": "352709",
    "label": "Kembang Jeruk",
    "value": "Kembang Jeruk"
  },
  {
    "id": "3527092012",
    "district_id": "352709",
    "label": "Morbatoh",
    "value": "Morbatoh"
  },
  {
    "id": "3527092013",
    "district_id": "352709",
    "label": "Montor",
    "value": "Montor"
  },
  {
    "id": "3527092014",
    "district_id": "352709",
    "label": "Tebanah",
    "value": "Tebanah"
  },
  {
    "id": "3527092015",
    "district_id": "352709",
    "label": "Nepa",
    "value": "Nepa"
  },
  {
    "id": "3527092016",
    "district_id": "352709",
    "label": "Batioh",
    "value": "Batioh"
  },
  {
    "id": "3527092017",
    "district_id": "352709",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3527092018",
    "district_id": "352709",
    "label": "Banyuates",
    "value": "Banyuates"
  },
  {
    "id": "3527092019",
    "district_id": "352709",
    "label": "Jatra Timur",
    "value": "Jatra Timur"
  },
  {
    "id": "3527092020",
    "district_id": "352709",
    "label": "Tapa'an",
    "value": "Tapa'an"
  },
  {
    "id": "3527102008",
    "district_id": "352710",
    "label": "Lepelle",
    "value": "Lepelle"
  },
  {
    "id": "3527102009",
    "district_id": "352710",
    "label": "Bapelle",
    "value": "Bapelle"
  },
  {
    "id": "3527102010",
    "district_id": "352710",
    "label": "Sawah Tengah",
    "value": "Sawah Tengah"
  },
  {
    "id": "3527102011",
    "district_id": "352710",
    "label": "Torjunan",
    "value": "Torjunan"
  },
  {
    "id": "3527102012",
    "district_id": "352710",
    "label": "Tragih",
    "value": "Tragih"
  },
  {
    "id": "3527102013",
    "district_id": "352710",
    "label": "Jelgung",
    "value": "Jelgung"
  },
  {
    "id": "3527102014",
    "district_id": "352710",
    "label": "Robatal",
    "value": "Robatal"
  },
  {
    "id": "3527102015",
    "district_id": "352710",
    "label": "Gunung Rancak",
    "value": "Gunung Rancak"
  },
  {
    "id": "3527102016",
    "district_id": "352710",
    "label": "Pandiyangan",
    "value": "Pandiyangan"
  },
  {
    "id": "3527112001",
    "district_id": "352711",
    "label": "Tobai Barat",
    "value": "Tobai Barat"
  },
  {
    "id": "3527112002",
    "district_id": "352711",
    "label": "Tobai Tengah",
    "value": "Tobai Tengah"
  },
  {
    "id": "3527112003",
    "district_id": "352711",
    "label": "Tobai Timur",
    "value": "Tobai Timur"
  },
  {
    "id": "3527112004",
    "district_id": "352711",
    "label": "Bira Tengah",
    "value": "Bira Tengah"
  },
  {
    "id": "3527112005",
    "district_id": "352711",
    "label": "Bira Timur",
    "value": "Bira Timur"
  },
  {
    "id": "3527112006",
    "district_id": "352711",
    "label": "Sokobanah Laok",
    "value": "Sokobanah Laok"
  },
  {
    "id": "3527112007",
    "district_id": "352711",
    "label": "Tamberu Laok",
    "value": "Tamberu Laok"
  },
  {
    "id": "3527112008",
    "district_id": "352711",
    "label": "Tamberu Daya",
    "value": "Tamberu Daya"
  },
  {
    "id": "3527112009",
    "district_id": "352711",
    "label": "Sokobanah Tengah",
    "value": "Sokobanah Tengah"
  },
  {
    "id": "3527112010",
    "district_id": "352711",
    "label": "Sokobanah Daya",
    "value": "Sokobanah Daya"
  },
  {
    "id": "3527112011",
    "district_id": "352711",
    "label": "Tamberu Barat",
    "value": "Tamberu Barat"
  },
  {
    "id": "3527112012",
    "district_id": "352711",
    "label": "Tamberu Timur",
    "value": "Tamberu Timur"
  },
  {
    "id": "3527122001",
    "district_id": "352712",
    "label": "Paopale Laok",
    "value": "Paopale Laok"
  },
  {
    "id": "3527122002",
    "district_id": "352712",
    "label": "Bunten Barat",
    "value": "Bunten Barat"
  },
  {
    "id": "3527122003",
    "district_id": "352712",
    "label": "Bunten Timur",
    "value": "Bunten Timur"
  },
  {
    "id": "3527122004",
    "district_id": "352712",
    "label": "Pancor",
    "value": "Pancor"
  },
  {
    "id": "3527122005",
    "district_id": "352712",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3527122006",
    "district_id": "352712",
    "label": "Pangereman",
    "value": "Pangereman"
  },
  {
    "id": "3527122007",
    "district_id": "352712",
    "label": "Bira Barat",
    "value": "Bira Barat"
  },
  {
    "id": "3527122008",
    "district_id": "352712",
    "label": "Ketapang Timur",
    "value": "Ketapang Timur"
  },
  {
    "id": "3527122009",
    "district_id": "352712",
    "label": "Ketapang Daya",
    "value": "Ketapang Daya"
  },
  {
    "id": "3527122010",
    "district_id": "352712",
    "label": "Ketapang Laok",
    "value": "Ketapang Laok"
  },
  {
    "id": "3527122011",
    "district_id": "352712",
    "label": "Ketapang Barat",
    "value": "Ketapang Barat"
  },
  {
    "id": "3527122012",
    "district_id": "352712",
    "label": "Paopale Daya",
    "value": "Paopale Daya"
  },
  {
    "id": "3527122013",
    "district_id": "352712",
    "label": "Rabiyan",
    "value": "Rabiyan"
  },
  {
    "id": "3527122014",
    "district_id": "352712",
    "label": "Banyusokah",
    "value": "Banyusokah"
  },
  {
    "id": "3527132001",
    "district_id": "352713",
    "label": "Pangarengan",
    "value": "Pangarengan"
  },
  {
    "id": "3527132002",
    "district_id": "352713",
    "label": "Apa an",
    "value": "Apa an"
  },
  {
    "id": "3527132003",
    "district_id": "352713",
    "label": "Gulbung",
    "value": "Gulbung"
  },
  {
    "id": "3527132004",
    "district_id": "352713",
    "label": "Panyirangan",
    "value": "Panyirangan"
  },
  {
    "id": "3527132005",
    "district_id": "352713",
    "label": "Pacangga an",
    "value": "Pacangga an"
  },
  {
    "id": "3527132006",
    "district_id": "352713",
    "label": "Ragung",
    "value": "Ragung"
  },
  {
    "id": "3527142001",
    "district_id": "352714",
    "label": "Blu uran",
    "value": "Blu uran"
  },
  {
    "id": "3527142002",
    "district_id": "352714",
    "label": "Tlambah",
    "value": "Tlambah"
  },
  {
    "id": "3527142003",
    "district_id": "352714",
    "label": "Bulmatet",
    "value": "Bulmatet"
  },
  {
    "id": "3527142004",
    "district_id": "352714",
    "label": "Poreh",
    "value": "Poreh"
  },
  {
    "id": "3527142005",
    "district_id": "352714",
    "label": "Karangpenang Oloh",
    "value": "Karangpenang Oloh"
  },
  {
    "id": "3527142006",
    "district_id": "352714",
    "label": "Karangpenang Onjur",
    "value": "Karangpenang Onjur"
  },
  {
    "id": "3527142007",
    "district_id": "352714",
    "label": "Gunung Kesan",
    "value": "Gunung Kesan"
  },
  {
    "id": "3528012001",
    "district_id": "352801",
    "label": "Dabuan",
    "value": "Dabuan"
  },
  {
    "id": "3528012002",
    "district_id": "352801",
    "label": "Terrak",
    "value": "Terrak"
  },
  {
    "id": "3528012003",
    "district_id": "352801",
    "label": "Mangar",
    "value": "Mangar"
  },
  {
    "id": "3528012004",
    "district_id": "352801",
    "label": "Bandaran",
    "value": "Bandaran"
  },
  {
    "id": "3528012005",
    "district_id": "352801",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3528012006",
    "district_id": "352801",
    "label": "Ambat",
    "value": "Ambat"
  },
  {
    "id": "3528012007",
    "district_id": "352801",
    "label": "Branta Pesisir",
    "value": "Branta Pesisir"
  },
  {
    "id": "3528012008",
    "district_id": "352801",
    "label": "Tlanakan",
    "value": "Tlanakan"
  },
  {
    "id": "3528012009",
    "district_id": "352801",
    "label": "Branta Tinggi",
    "value": "Branta Tinggi"
  },
  {
    "id": "3528012010",
    "district_id": "352801",
    "label": "Tlesah",
    "value": "Tlesah"
  },
  {
    "id": "3528012011",
    "district_id": "352801",
    "label": "Larangan Tokol",
    "value": "Larangan Tokol"
  },
  {
    "id": "3528012012",
    "district_id": "352801",
    "label": "Ceguk",
    "value": "Ceguk"
  },
  {
    "id": "3528012013",
    "district_id": "352801",
    "label": "Panglegur",
    "value": "Panglegur"
  },
  {
    "id": "3528012014",
    "district_id": "352801",
    "label": "Bukek",
    "value": "Bukek"
  },
  {
    "id": "3528012015",
    "district_id": "352801",
    "label": "Gugul",
    "value": "Gugul"
  },
  {
    "id": "3528012016",
    "district_id": "352801",
    "label": "Larangan Slampar",
    "value": "Larangan Slampar"
  },
  {
    "id": "3528012017",
    "district_id": "352801",
    "label": "Taro'an",
    "value": "Taro'an"
  },
  {
    "id": "3528021018",
    "district_id": "352802",
    "label": "Barurambat Timur",
    "value": "Barurambat Timur"
  },
  {
    "id": "3528021019",
    "district_id": "352802",
    "label": "Lawangan Daya",
    "value": "Lawangan Daya"
  },
  {
    "id": "3528022001",
    "district_id": "352802",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3528022002",
    "district_id": "352802",
    "label": "Padelegan",
    "value": "Padelegan"
  },
  {
    "id": "3528022003",
    "district_id": "352802",
    "label": "Majungan",
    "value": "Majungan"
  },
  {
    "id": "3528022004",
    "district_id": "352802",
    "label": "Pagagan",
    "value": "Pagagan"
  },
  {
    "id": "3528022005",
    "district_id": "352802",
    "label": "Jarin",
    "value": "Jarin"
  },
  {
    "id": "3528022006",
    "district_id": "352802",
    "label": "Baddurih",
    "value": "Baddurih"
  },
  {
    "id": "3528022007",
    "district_id": "352802",
    "label": "Buddih",
    "value": "Buddih"
  },
  {
    "id": "3528022008",
    "district_id": "352802",
    "label": "Sopa'ah",
    "value": "Sopa'ah"
  },
  {
    "id": "3528022009",
    "district_id": "352802",
    "label": "Prekbun",
    "value": "Prekbun"
  },
  {
    "id": "3528022010",
    "district_id": "352802",
    "label": "Durbuk",
    "value": "Durbuk"
  },
  {
    "id": "3528022011",
    "district_id": "352802",
    "label": "Pademawu Barat",
    "value": "Pademawu Barat"
  },
  {
    "id": "3528022012",
    "district_id": "352802",
    "label": "Pademawu Timur",
    "value": "Pademawu Timur"
  },
  {
    "id": "3528022013",
    "district_id": "352802",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3528022014",
    "district_id": "352802",
    "label": "Dasok",
    "value": "Dasok"
  },
  {
    "id": "3528022015",
    "district_id": "352802",
    "label": "Murtajih",
    "value": "Murtajih"
  },
  {
    "id": "3528022016",
    "district_id": "352802",
    "label": "Sumedangan",
    "value": "Sumedangan"
  },
  {
    "id": "3528022017",
    "district_id": "352802",
    "label": "Lemper",
    "value": "Lemper"
  },
  {
    "id": "3528022020",
    "district_id": "352802",
    "label": "Sentol",
    "value": "Sentol"
  },
  {
    "id": "3528022021",
    "district_id": "352802",
    "label": "Tambung",
    "value": "Tambung"
  },
  {
    "id": "3528022022",
    "district_id": "352802",
    "label": "Buddagan",
    "value": "Buddagan"
  },
  {
    "id": "3528032001",
    "district_id": "352803",
    "label": "Tobungan",
    "value": "Tobungan"
  },
  {
    "id": "3528032002",
    "district_id": "352803",
    "label": "Konang",
    "value": "Konang"
  },
  {
    "id": "3528032003",
    "district_id": "352803",
    "label": "Pandan",
    "value": "Pandan"
  },
  {
    "id": "3528032004",
    "district_id": "352803",
    "label": "Lembung",
    "value": "Lembung"
  },
  {
    "id": "3528032005",
    "district_id": "352803",
    "label": "Galis",
    "value": "Galis"
  },
  {
    "id": "3528032006",
    "district_id": "352803",
    "label": "Bulay",
    "value": "Bulay"
  },
  {
    "id": "3528032007",
    "district_id": "352803",
    "label": "Pagendingan",
    "value": "Pagendingan"
  },
  {
    "id": "3528032008",
    "district_id": "352803",
    "label": "Ponteh",
    "value": "Ponteh"
  },
  {
    "id": "3528032009",
    "district_id": "352803",
    "label": "Polagan",
    "value": "Polagan"
  },
  {
    "id": "3528032010",
    "district_id": "352803",
    "label": "Artodung",
    "value": "Artodung"
  },
  {
    "id": "3528041001",
    "district_id": "352804",
    "label": "Kangenan",
    "value": "Kangenan"
  },
  {
    "id": "3528041003",
    "district_id": "352804",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "3528041011",
    "district_id": "352804",
    "label": "Bugih",
    "value": "Bugih"
  },
  {
    "id": "3528041012",
    "district_id": "352804",
    "label": "Jungcang Cang",
    "value": "Jungcang Cang"
  },
  {
    "id": "3528041013",
    "district_id": "352804",
    "label": "Parteker",
    "value": "Parteker"
  },
  {
    "id": "3528041014",
    "district_id": "352804",
    "label": "Barurambat Kota",
    "value": "Barurambat Kota"
  },
  {
    "id": "3528041015",
    "district_id": "352804",
    "label": "Geladak Anyar",
    "value": "Geladak Anyar"
  },
  {
    "id": "3528041016",
    "district_id": "352804",
    "label": "Kolpajung",
    "value": "Kolpajung"
  },
  {
    "id": "3528041017",
    "district_id": "352804",
    "label": "Kowel",
    "value": "Kowel"
  },
  {
    "id": "3528042002",
    "district_id": "352804",
    "label": "Panempan",
    "value": "Panempan"
  },
  {
    "id": "3528042004",
    "district_id": "352804",
    "label": "Laden",
    "value": "Laden"
  },
  {
    "id": "3528042005",
    "district_id": "352804",
    "label": "Jalmak",
    "value": "Jalmak"
  },
  {
    "id": "3528042006",
    "district_id": "352804",
    "label": "Teja Barat",
    "value": "Teja Barat"
  },
  {
    "id": "3528042007",
    "district_id": "352804",
    "label": "Teja Timur",
    "value": "Teja Timur"
  },
  {
    "id": "3528042008",
    "district_id": "352804",
    "label": "Bettet",
    "value": "Bettet"
  },
  {
    "id": "3528042009",
    "district_id": "352804",
    "label": "Nyalabu Laok",
    "value": "Nyalabu Laok"
  },
  {
    "id": "3528042010",
    "district_id": "352804",
    "label": "Nyalabu Daja",
    "value": "Nyalabu Daja"
  },
  {
    "id": "3528042018",
    "district_id": "352804",
    "label": "Toronan",
    "value": "Toronan"
  },
  {
    "id": "3528052001",
    "district_id": "352805",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3528052002",
    "district_id": "352805",
    "label": "Toket",
    "value": "Toket"
  },
  {
    "id": "3528052003",
    "district_id": "352805",
    "label": "Candi Burung",
    "value": "Candi Burung"
  },
  {
    "id": "3528052004",
    "district_id": "352805",
    "label": "Bato Kalangan",
    "value": "Bato Kalangan"
  },
  {
    "id": "3528052005",
    "district_id": "352805",
    "label": "Gro'om",
    "value": "Gro'om"
  },
  {
    "id": "3528052006",
    "district_id": "352805",
    "label": "Srambah",
    "value": "Srambah"
  },
  {
    "id": "3528052007",
    "district_id": "352805",
    "label": "Pangtonggal",
    "value": "Pangtonggal"
  },
  {
    "id": "3528052008",
    "district_id": "352805",
    "label": "Samiran",
    "value": "Samiran"
  },
  {
    "id": "3528052009",
    "district_id": "352805",
    "label": "Kodik",
    "value": "Kodik"
  },
  {
    "id": "3528052010",
    "district_id": "352805",
    "label": "Klampar",
    "value": "Klampar"
  },
  {
    "id": "3528052011",
    "district_id": "352805",
    "label": "Samatan",
    "value": "Samatan"
  },
  {
    "id": "3528052012",
    "district_id": "352805",
    "label": "Lenteng",
    "value": "Lenteng"
  },
  {
    "id": "3528052013",
    "district_id": "352805",
    "label": "Talangoh",
    "value": "Talangoh"
  },
  {
    "id": "3528052014",
    "district_id": "352805",
    "label": "Billa'an",
    "value": "Billa'an"
  },
  {
    "id": "3528052015",
    "district_id": "352805",
    "label": "Rangperang Laok",
    "value": "Rangperang Laok"
  },
  {
    "id": "3528052016",
    "district_id": "352805",
    "label": "Rangperang Daja",
    "value": "Rangperang Daja"
  },
  {
    "id": "3528052017",
    "district_id": "352805",
    "label": "Banyubulu",
    "value": "Banyubulu"
  },
  {
    "id": "3528052018",
    "district_id": "352805",
    "label": "Pangorayan",
    "value": "Pangorayan"
  },
  {
    "id": "3528052019",
    "district_id": "352805",
    "label": "Panglemah",
    "value": "Panglemah"
  },
  {
    "id": "3528052020",
    "district_id": "352805",
    "label": "Mapper",
    "value": "Mapper"
  },
  {
    "id": "3528052021",
    "district_id": "352805",
    "label": "Proppo",
    "value": "Proppo"
  },
  {
    "id": "3528052022",
    "district_id": "352805",
    "label": "Jambringin",
    "value": "Jambringin"
  },
  {
    "id": "3528052023",
    "district_id": "352805",
    "label": "Campor",
    "value": "Campor"
  },
  {
    "id": "3528052024",
    "district_id": "352805",
    "label": "Panaguan",
    "value": "Panaguan"
  },
  {
    "id": "3528052025",
    "district_id": "352805",
    "label": "Pangbatok",
    "value": "Pangbatok"
  },
  {
    "id": "3528052026",
    "district_id": "352805",
    "label": "Tattangoh",
    "value": "Tattangoh"
  },
  {
    "id": "3528052027",
    "district_id": "352805",
    "label": "Badung",
    "value": "Badung"
  },
  {
    "id": "3528062001",
    "district_id": "352806",
    "label": "Banyupelle",
    "value": "Banyupelle"
  },
  {
    "id": "3528062002",
    "district_id": "352806",
    "label": "Rek Kerrek",
    "value": "Rek Kerrek"
  },
  {
    "id": "3528062003",
    "district_id": "352806",
    "label": "Angsanah",
    "value": "Angsanah"
  },
  {
    "id": "3528062004",
    "district_id": "352806",
    "label": "Akkor",
    "value": "Akkor"
  },
  {
    "id": "3528062005",
    "district_id": "352806",
    "label": "Larangan Badung",
    "value": "Larangan Badung"
  },
  {
    "id": "3528062006",
    "district_id": "352806",
    "label": "Panaan",
    "value": "Panaan"
  },
  {
    "id": "3528062007",
    "district_id": "352806",
    "label": "Patoan Laok",
    "value": "Patoan Laok"
  },
  {
    "id": "3528062008",
    "district_id": "352806",
    "label": "Patoan Dajah",
    "value": "Patoan Dajah"
  },
  {
    "id": "3528062009",
    "district_id": "352806",
    "label": "Kacok",
    "value": "Kacok"
  },
  {
    "id": "3528062010",
    "district_id": "352806",
    "label": "Rombuh",
    "value": "Rombuh"
  },
  {
    "id": "3528062011",
    "district_id": "352806",
    "label": "Palengaan Laok",
    "value": "Palengaan Laok"
  },
  {
    "id": "3528062012",
    "district_id": "352806",
    "label": "Palengaan Dajah",
    "value": "Palengaan Dajah"
  },
  {
    "id": "3528072001",
    "district_id": "352807",
    "label": "Plakpak",
    "value": "Plakpak"
  },
  {
    "id": "3528072002",
    "district_id": "352807",
    "label": "Palesanggar",
    "value": "Palesanggar"
  },
  {
    "id": "3528072003",
    "district_id": "352807",
    "label": "Pegantenan",
    "value": "Pegantenan"
  },
  {
    "id": "3528072004",
    "district_id": "352807",
    "label": "Bulangan Branta",
    "value": "Bulangan Branta"
  },
  {
    "id": "3528072005",
    "district_id": "352807",
    "label": "Bulangan Barat",
    "value": "Bulangan Barat"
  },
  {
    "id": "3528072006",
    "district_id": "352807",
    "label": "Bulangan Haji",
    "value": "Bulangan Haji"
  },
  {
    "id": "3528072007",
    "district_id": "352807",
    "label": "Tlagah",
    "value": "Tlagah"
  },
  {
    "id": "3528072008",
    "district_id": "352807",
    "label": "Bulangan Timur",
    "value": "Bulangan Timur"
  },
  {
    "id": "3528072009",
    "district_id": "352807",
    "label": "Tebul Timur",
    "value": "Tebul Timur"
  },
  {
    "id": "3528072010",
    "district_id": "352807",
    "label": "Tebul Barat",
    "value": "Tebul Barat"
  },
  {
    "id": "3528072011",
    "district_id": "352807",
    "label": "Ambender",
    "value": "Ambender"
  },
  {
    "id": "3528072012",
    "district_id": "352807",
    "label": "Pasanggar",
    "value": "Pasanggar"
  },
  {
    "id": "3528072013",
    "district_id": "352807",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3528082001",
    "district_id": "352808",
    "label": "Peltong",
    "value": "Peltong"
  },
  {
    "id": "3528082002",
    "district_id": "352808",
    "label": "Blumbungan",
    "value": "Blumbungan"
  },
  {
    "id": "3528082003",
    "district_id": "352808",
    "label": "Trasak",
    "value": "Trasak"
  },
  {
    "id": "3528082004",
    "district_id": "352808",
    "label": "Tentenan Barat",
    "value": "Tentenan Barat"
  },
  {
    "id": "3528082005",
    "district_id": "352808",
    "label": "Tentenan Timur",
    "value": "Tentenan Timur"
  },
  {
    "id": "3528082006",
    "district_id": "352808",
    "label": "Grujugan",
    "value": "Grujugan"
  },
  {
    "id": "3528082007",
    "district_id": "352808",
    "label": "Larangan Luar",
    "value": "Larangan Luar"
  },
  {
    "id": "3528082008",
    "district_id": "352808",
    "label": "Larangan Dalam",
    "value": "Larangan Dalam"
  },
  {
    "id": "3528082009",
    "district_id": "352808",
    "label": "Panaguan",
    "value": "Panaguan"
  },
  {
    "id": "3528082010",
    "district_id": "352808",
    "label": "Montok",
    "value": "Montok"
  },
  {
    "id": "3528082011",
    "district_id": "352808",
    "label": "Taraban",
    "value": "Taraban"
  },
  {
    "id": "3528082012",
    "district_id": "352808",
    "label": "Duko Timur",
    "value": "Duko Timur"
  },
  {
    "id": "3528082013",
    "district_id": "352808",
    "label": "Lancar",
    "value": "Lancar"
  },
  {
    "id": "3528082014",
    "district_id": "352808",
    "label": "Kaduara Barat",
    "value": "Kaduara Barat"
  },
  {
    "id": "3528092001",
    "district_id": "352809",
    "label": "Bicorong",
    "value": "Bicorong"
  },
  {
    "id": "3528092002",
    "district_id": "352809",
    "label": "Klompang Barat",
    "value": "Klompang Barat"
  },
  {
    "id": "3528092003",
    "district_id": "352809",
    "label": "Klompang Timur",
    "value": "Klompang Timur"
  },
  {
    "id": "3528092004",
    "district_id": "352809",
    "label": "Cenlecen",
    "value": "Cenlecen"
  },
  {
    "id": "3528092005",
    "district_id": "352809",
    "label": "Bajang",
    "value": "Bajang"
  },
  {
    "id": "3528092006",
    "district_id": "352809",
    "label": "Banban",
    "value": "Banban"
  },
  {
    "id": "3528092007",
    "district_id": "352809",
    "label": "Somalang",
    "value": "Somalang"
  },
  {
    "id": "3528092008",
    "district_id": "352809",
    "label": "Palalang",
    "value": "Palalang"
  },
  {
    "id": "3528092009",
    "district_id": "352809",
    "label": "Seddur",
    "value": "Seddur"
  },
  {
    "id": "3528092010",
    "district_id": "352809",
    "label": "Pakong",
    "value": "Pakong"
  },
  {
    "id": "3528092011",
    "district_id": "352809",
    "label": "Bandungan",
    "value": "Bandungan"
  },
  {
    "id": "3528092012",
    "district_id": "352809",
    "label": "Lebbek",
    "value": "Lebbek"
  },
  {
    "id": "3528102001",
    "district_id": "352810",
    "label": "Bajur",
    "value": "Bajur"
  },
  {
    "id": "3528102002",
    "district_id": "352810",
    "label": "Sumber Waru",
    "value": "Sumber Waru"
  },
  {
    "id": "3528102003",
    "district_id": "352810",
    "label": "Tampojung Pregih",
    "value": "Tampojung Pregih"
  },
  {
    "id": "3528102004",
    "district_id": "352810",
    "label": "Tampojung Guwa",
    "value": "Tampojung Guwa"
  },
  {
    "id": "3528102005",
    "district_id": "352810",
    "label": "Tampojung Tengah",
    "value": "Tampojung Tengah"
  },
  {
    "id": "3528102006",
    "district_id": "352810",
    "label": "Tampojung Tenggina",
    "value": "Tampojung Tenggina"
  },
  {
    "id": "3528102007",
    "district_id": "352810",
    "label": "Ragang",
    "value": "Ragang"
  },
  {
    "id": "3528102008",
    "district_id": "352810",
    "label": "Sana Laok",
    "value": "Sana Laok"
  },
  {
    "id": "3528102009",
    "district_id": "352810",
    "label": "Waru Timur",
    "value": "Waru Timur"
  },
  {
    "id": "3528102010",
    "district_id": "352810",
    "label": "Waru Barat",
    "value": "Waru Barat"
  },
  {
    "id": "3528102011",
    "district_id": "352810",
    "label": "Tlonto Ares",
    "value": "Tlonto Ares"
  },
  {
    "id": "3528102012",
    "district_id": "352810",
    "label": "Tagangser Laok",
    "value": "Tagangser Laok"
  },
  {
    "id": "3528112001",
    "district_id": "352811",
    "label": "Bujur Barat",
    "value": "Bujur Barat"
  },
  {
    "id": "3528112002",
    "district_id": "352811",
    "label": "Pangereman",
    "value": "Pangereman"
  },
  {
    "id": "3528112003",
    "district_id": "352811",
    "label": "Bangserreh",
    "value": "Bangserreh"
  },
  {
    "id": "3528112004",
    "district_id": "352811",
    "label": "Lesong Laok",
    "value": "Lesong Laok"
  },
  {
    "id": "3528112005",
    "district_id": "352811",
    "label": "Ponjanan Barat",
    "value": "Ponjanan Barat"
  },
  {
    "id": "3528112006",
    "district_id": "352811",
    "label": "Ponjanan Timur",
    "value": "Ponjanan Timur"
  },
  {
    "id": "3528112007",
    "district_id": "352811",
    "label": "Kapong",
    "value": "Kapong"
  },
  {
    "id": "3528112008",
    "district_id": "352811",
    "label": "Lesong Daja",
    "value": "Lesong Daja"
  },
  {
    "id": "3528112009",
    "district_id": "352811",
    "label": "Batu Bintang",
    "value": "Batu Bintang"
  },
  {
    "id": "3528112010",
    "district_id": "352811",
    "label": "Blaban",
    "value": "Blaban"
  },
  {
    "id": "3528112011",
    "district_id": "352811",
    "label": "Tamberu",
    "value": "Tamberu"
  },
  {
    "id": "3528112012",
    "district_id": "352811",
    "label": "Bujur Tengah",
    "value": "Bujur Tengah"
  },
  {
    "id": "3528112013",
    "district_id": "352811",
    "label": "Bujur Timur",
    "value": "Bujur Timur"
  },
  {
    "id": "3528122001",
    "district_id": "352812",
    "label": "Pamaroh",
    "value": "Pamaroh"
  },
  {
    "id": "3528122002",
    "district_id": "352812",
    "label": "Pamoroh",
    "value": "Pamoroh"
  },
  {
    "id": "3528122003",
    "district_id": "352812",
    "label": "Sokolelah",
    "value": "Sokolelah"
  },
  {
    "id": "3528122004",
    "district_id": "352812",
    "label": "Gagah",
    "value": "Gagah"
  },
  {
    "id": "3528122005",
    "district_id": "352812",
    "label": "Kertagena Laok",
    "value": "Kertagena Laok"
  },
  {
    "id": "3528122006",
    "district_id": "352812",
    "label": "Kertagena Tengah",
    "value": "Kertagena Tengah"
  },
  {
    "id": "3528122007",
    "district_id": "352812",
    "label": "Bungbaruh",
    "value": "Bungbaruh"
  },
  {
    "id": "3528122008",
    "district_id": "352812",
    "label": "Kadur",
    "value": "Kadur"
  },
  {
    "id": "3528122009",
    "district_id": "352812",
    "label": "Bangkes",
    "value": "Bangkes"
  },
  {
    "id": "3528122010",
    "district_id": "352812",
    "label": "Kertagena Dajah",
    "value": "Kertagena Dajah"
  },
  {
    "id": "3528132001",
    "district_id": "352813",
    "label": "Sana Tengah",
    "value": "Sana Tengah"
  },
  {
    "id": "3528132002",
    "district_id": "352813",
    "label": "Sana Dajah",
    "value": "Sana Dajah"
  },
  {
    "id": "3528132003",
    "district_id": "352813",
    "label": "Tagangser Dajah",
    "value": "Tagangser Dajah"
  },
  {
    "id": "3528132004",
    "district_id": "352813",
    "label": "Sotabar",
    "value": "Sotabar"
  },
  {
    "id": "3528132005",
    "district_id": "352813",
    "label": "Tlonto Raja",
    "value": "Tlonto Raja"
  },
  {
    "id": "3528132006",
    "district_id": "352813",
    "label": "Dempo Barat",
    "value": "Dempo Barat"
  },
  {
    "id": "3528132007",
    "district_id": "352813",
    "label": "Dempo Timur",
    "value": "Dempo Timur"
  },
  {
    "id": "3528132008",
    "district_id": "352813",
    "label": "Bindang",
    "value": "Bindang"
  },
  {
    "id": "3528132009",
    "district_id": "352813",
    "label": "Batukerbuy",
    "value": "Batukerbuy"
  },
  {
    "id": "3529011015",
    "district_id": "352901",
    "label": "Kepanjin",
    "value": "Kepanjin"
  },
  {
    "id": "3529011016",
    "district_id": "352901",
    "label": "Pajagalan",
    "value": "Pajagalan"
  },
  {
    "id": "3529011017",
    "district_id": "352901",
    "label": "Bangselok",
    "value": "Bangselok"
  },
  {
    "id": "3529011018",
    "district_id": "352901",
    "label": "Karangduak",
    "value": "Karangduak"
  },
  {
    "id": "3529012005",
    "district_id": "352901",
    "label": "Kolor",
    "value": "Kolor"
  },
  {
    "id": "3529012006",
    "district_id": "352901",
    "label": "Pabian",
    "value": "Pabian"
  },
  {
    "id": "3529012007",
    "district_id": "352901",
    "label": "Marengan Daya",
    "value": "Marengan Daya"
  },
  {
    "id": "3529012008",
    "district_id": "352901",
    "label": "Kacongan",
    "value": "Kacongan"
  },
  {
    "id": "3529012009",
    "district_id": "352901",
    "label": "Paberasan",
    "value": "Paberasan"
  },
  {
    "id": "3529012010",
    "district_id": "352901",
    "label": "Parsanga",
    "value": "Parsanga"
  },
  {
    "id": "3529012011",
    "district_id": "352901",
    "label": "Bangkal",
    "value": "Bangkal"
  },
  {
    "id": "3529012012",
    "district_id": "352901",
    "label": "Kebunan",
    "value": "Kebunan"
  },
  {
    "id": "3529012013",
    "district_id": "352901",
    "label": "Pamolokan",
    "value": "Pamolokan"
  },
  {
    "id": "3529012014",
    "district_id": "352901",
    "label": "Pangarangan",
    "value": "Pangarangan"
  },
  {
    "id": "3529012019",
    "district_id": "352901",
    "label": "Pandian",
    "value": "Pandian"
  },
  {
    "id": "3529012020",
    "district_id": "352901",
    "label": "Kebunagung",
    "value": "Kebunagung"
  },
  {
    "id": "3529022001",
    "district_id": "352902",
    "label": "Pinggirpapas",
    "value": "Pinggirpapas"
  },
  {
    "id": "3529022002",
    "district_id": "352902",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3529022003",
    "district_id": "352902",
    "label": "Marengan Laok",
    "value": "Marengan Laok"
  },
  {
    "id": "3529022004",
    "district_id": "352902",
    "label": "Kertasada",
    "value": "Kertasada"
  },
  {
    "id": "3529022005",
    "district_id": "352902",
    "label": "Kalimook",
    "value": "Kalimook"
  },
  {
    "id": "3529022006",
    "district_id": "352902",
    "label": "Kalianget Barat",
    "value": "Kalianget Barat"
  },
  {
    "id": "3529022007",
    "district_id": "352902",
    "label": "Kalianget Timur",
    "value": "Kalianget Timur"
  },
  {
    "id": "3529032001",
    "district_id": "352903",
    "label": "Kasengan",
    "value": "Kasengan"
  },
  {
    "id": "3529032002",
    "district_id": "352903",
    "label": "Lalangon",
    "value": "Lalangon"
  },
  {
    "id": "3529032003",
    "district_id": "352903",
    "label": "Tenonan",
    "value": "Tenonan"
  },
  {
    "id": "3529032004",
    "district_id": "352903",
    "label": "Lanjuk",
    "value": "Lanjuk"
  },
  {
    "id": "3529032005",
    "district_id": "352903",
    "label": "Gadding",
    "value": "Gadding"
  },
  {
    "id": "3529032006",
    "district_id": "352903",
    "label": "Giring",
    "value": "Giring"
  },
  {
    "id": "3529032007",
    "district_id": "352903",
    "label": "Gunung Kembar",
    "value": "Gunung Kembar"
  },
  {
    "id": "3529032008",
    "district_id": "352903",
    "label": "Jaba'an",
    "value": "Jaba'an"
  },
  {
    "id": "3529032009",
    "district_id": "352903",
    "label": "Manding Laok",
    "value": "Manding Laok"
  },
  {
    "id": "3529032010",
    "district_id": "352903",
    "label": "Manding Timur",
    "value": "Manding Timur"
  },
  {
    "id": "3529032011",
    "district_id": "352903",
    "label": "Manding Daya",
    "value": "Manding Daya"
  },
  {
    "id": "3529042001",
    "district_id": "352904",
    "label": "Talango",
    "value": "Talango"
  },
  {
    "id": "3529042002",
    "district_id": "352904",
    "label": "Padike",
    "value": "Padike"
  },
  {
    "id": "3529042003",
    "district_id": "352904",
    "label": "Cabbiya",
    "value": "Cabbiya"
  },
  {
    "id": "3529042004",
    "district_id": "352904",
    "label": "Essang",
    "value": "Essang"
  },
  {
    "id": "3529042005",
    "district_id": "352904",
    "label": "Kombang",
    "value": "Kombang"
  },
  {
    "id": "3529042006",
    "district_id": "352904",
    "label": "Poteran",
    "value": "Poteran"
  },
  {
    "id": "3529042007",
    "district_id": "352904",
    "label": "Palasa",
    "value": "Palasa"
  },
  {
    "id": "3529042008",
    "district_id": "352904",
    "label": "Gapurana",
    "value": "Gapurana"
  },
  {
    "id": "3529052001",
    "district_id": "352905",
    "label": "Guluk Manjung",
    "value": "Guluk Manjung"
  },
  {
    "id": "3529052002",
    "district_id": "352905",
    "label": "Kapedi",
    "value": "Kapedi"
  },
  {
    "id": "3529052003",
    "district_id": "352905",
    "label": "Pakandangan Barat",
    "value": "Pakandangan Barat"
  },
  {
    "id": "3529052004",
    "district_id": "352905",
    "label": "Pakandangan Tengah",
    "value": "Pakandangan Tengah"
  },
  {
    "id": "3529052005",
    "district_id": "352905",
    "label": "Pakandangan Sangra",
    "value": "Pakandangan Sangra"
  },
  {
    "id": "3529052006",
    "district_id": "352905",
    "label": "Aeng Dake",
    "value": "Aeng Dake"
  },
  {
    "id": "3529052007",
    "district_id": "352905",
    "label": "Aeng Baja Kenek",
    "value": "Aeng Baja Kenek"
  },
  {
    "id": "3529052008",
    "district_id": "352905",
    "label": "Bluto",
    "value": "Bluto"
  },
  {
    "id": "3529052009",
    "district_id": "352905",
    "label": "Lobuk",
    "value": "Lobuk"
  },
  {
    "id": "3529052010",
    "district_id": "352905",
    "label": "Bumbungan",
    "value": "Bumbungan"
  },
  {
    "id": "3529052011",
    "district_id": "352905",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "3529052012",
    "district_id": "352905",
    "label": "Palongan",
    "value": "Palongan"
  },
  {
    "id": "3529052013",
    "district_id": "352905",
    "label": "Aeng Baja Raja",
    "value": "Aeng Baja Raja"
  },
  {
    "id": "3529052014",
    "district_id": "352905",
    "label": "Karang Campaka",
    "value": "Karang Campaka"
  },
  {
    "id": "3529052015",
    "district_id": "352905",
    "label": "Sera Barat",
    "value": "Sera Barat"
  },
  {
    "id": "3529052016",
    "district_id": "352905",
    "label": "Sera Tengah",
    "value": "Sera Tengah"
  },
  {
    "id": "3529052017",
    "district_id": "352905",
    "label": "Sera Timur",
    "value": "Sera Timur"
  },
  {
    "id": "3529052018",
    "district_id": "352905",
    "label": "Gingging",
    "value": "Gingging"
  },
  {
    "id": "3529052019",
    "district_id": "352905",
    "label": "Errabu",
    "value": "Errabu"
  },
  {
    "id": "3529052020",
    "district_id": "352905",
    "label": "Gilang",
    "value": "Gilang"
  },
  {
    "id": "3529062001",
    "district_id": "352906",
    "label": "Pagar Batu",
    "value": "Pagar Batu"
  },
  {
    "id": "3529062002",
    "district_id": "352906",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "3529062003",
    "district_id": "352906",
    "label": "Kebundadap Timur",
    "value": "Kebundadap Timur"
  },
  {
    "id": "3529062004",
    "district_id": "352906",
    "label": "Langsar",
    "value": "Langsar"
  },
  {
    "id": "3529062005",
    "district_id": "352906",
    "label": "Kebundadap Barat",
    "value": "Kebundadap Barat"
  },
  {
    "id": "3529062006",
    "district_id": "352906",
    "label": "Saroka",
    "value": "Saroka"
  },
  {
    "id": "3529062007",
    "district_id": "352906",
    "label": "Tanamerah",
    "value": "Tanamerah"
  },
  {
    "id": "3529062008",
    "district_id": "352906",
    "label": "Nambakor",
    "value": "Nambakor"
  },
  {
    "id": "3529062009",
    "district_id": "352906",
    "label": "Saronggi",
    "value": "Saronggi"
  },
  {
    "id": "3529062010",
    "district_id": "352906",
    "label": "Moangan",
    "value": "Moangan"
  },
  {
    "id": "3529062011",
    "district_id": "352906",
    "label": "Juluk",
    "value": "Juluk"
  },
  {
    "id": "3529062012",
    "district_id": "352906",
    "label": "Aengtongtong",
    "value": "Aengtongtong"
  },
  {
    "id": "3529062013",
    "district_id": "352906",
    "label": "Talang",
    "value": "Talang"
  },
  {
    "id": "3529062014",
    "district_id": "352906",
    "label": "Kambingan Timur",
    "value": "Kambingan Timur"
  },
  {
    "id": "3529072001",
    "district_id": "352907",
    "label": "Moncek Barat",
    "value": "Moncek Barat"
  },
  {
    "id": "3529072002",
    "district_id": "352907",
    "label": "Moncek Tengah",
    "value": "Moncek Tengah"
  },
  {
    "id": "3529072003",
    "district_id": "352907",
    "label": "Moncek Timur",
    "value": "Moncek Timur"
  },
  {
    "id": "3529072004",
    "district_id": "352907",
    "label": "Banaresep Timur",
    "value": "Banaresep Timur"
  },
  {
    "id": "3529072005",
    "district_id": "352907",
    "label": "Tarogan",
    "value": "Tarogan"
  },
  {
    "id": "3529072006",
    "district_id": "352907",
    "label": "Kambingan Barat",
    "value": "Kambingan Barat"
  },
  {
    "id": "3529072007",
    "district_id": "352907",
    "label": "Poreh",
    "value": "Poreh"
  },
  {
    "id": "3529072008",
    "district_id": "352907",
    "label": "Lenteng Timur",
    "value": "Lenteng Timur"
  },
  {
    "id": "3529072009",
    "district_id": "352907",
    "label": "Lembung Timur",
    "value": "Lembung Timur"
  },
  {
    "id": "3529072010",
    "district_id": "352907",
    "label": "Lenteng Barat",
    "value": "Lenteng Barat"
  },
  {
    "id": "3529072011",
    "district_id": "352907",
    "label": "Banaresep Barat",
    "value": "Banaresep Barat"
  },
  {
    "id": "3529072012",
    "district_id": "352907",
    "label": "Billapora Rebba",
    "value": "Billapora Rebba"
  },
  {
    "id": "3529072013",
    "district_id": "352907",
    "label": "Lembung Barat",
    "value": "Lembung Barat"
  },
  {
    "id": "3529072014",
    "district_id": "352907",
    "label": "Ellak Laok",
    "value": "Ellak Laok"
  },
  {
    "id": "3529072015",
    "district_id": "352907",
    "label": "Ellak Daya",
    "value": "Ellak Daya"
  },
  {
    "id": "3529072016",
    "district_id": "352907",
    "label": "Daramista",
    "value": "Daramista"
  },
  {
    "id": "3529072017",
    "district_id": "352907",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "3529072018",
    "district_id": "352907",
    "label": "Cangkreng",
    "value": "Cangkreng"
  },
  {
    "id": "3529072019",
    "district_id": "352907",
    "label": "Meddelan",
    "value": "Meddelan"
  },
  {
    "id": "3529072020",
    "district_id": "352907",
    "label": "Sendir",
    "value": "Sendir"
  },
  {
    "id": "3529082001",
    "district_id": "352908",
    "label": "Galis",
    "value": "Galis"
  },
  {
    "id": "3529082002",
    "district_id": "352908",
    "label": "Gedugan",
    "value": "Gedugan"
  },
  {
    "id": "3529082003",
    "district_id": "352908",
    "label": "Bringsang",
    "value": "Bringsang"
  },
  {
    "id": "3529082004",
    "district_id": "352908",
    "label": "Aenganyar",
    "value": "Aenganyar"
  },
  {
    "id": "3529082005",
    "district_id": "352908",
    "label": "Lombang",
    "value": "Lombang"
  },
  {
    "id": "3529082006",
    "district_id": "352908",
    "label": "Jate",
    "value": "Jate"
  },
  {
    "id": "3529082007",
    "district_id": "352908",
    "label": "Banbaru",
    "value": "Banbaru"
  },
  {
    "id": "3529082008",
    "district_id": "352908",
    "label": "Banmaleng",
    "value": "Banmaleng"
  },
  {
    "id": "3529092001",
    "district_id": "352909",
    "label": "Bakeong",
    "value": "Bakeong"
  },
  {
    "id": "3529092002",
    "district_id": "352909",
    "label": "Payudan Dundang",
    "value": "Payudan Dundang"
  },
  {
    "id": "3529092003",
    "district_id": "352909",
    "label": "Pordapor",
    "value": "Pordapor"
  },
  {
    "id": "3529092004",
    "district_id": "352909",
    "label": "Guluk-guluk",
    "value": "Guluk-guluk"
  },
  {
    "id": "3529092005",
    "district_id": "352909",
    "label": "Ketawang Laok",
    "value": "Ketawang Laok"
  },
  {
    "id": "3529092006",
    "district_id": "352909",
    "label": "Pananggungan",
    "value": "Pananggungan"
  },
  {
    "id": "3529092007",
    "district_id": "352909",
    "label": "Bragung",
    "value": "Bragung"
  },
  {
    "id": "3529092008",
    "district_id": "352909",
    "label": "Tambuko",
    "value": "Tambuko"
  },
  {
    "id": "3529092009",
    "district_id": "352909",
    "label": "Payudan Nangger",
    "value": "Payudan Nangger"
  },
  {
    "id": "3529092010",
    "district_id": "352909",
    "label": "Payudan Daleman",
    "value": "Payudan Daleman"
  },
  {
    "id": "3529092011",
    "district_id": "352909",
    "label": "Payudan Karangsokon",
    "value": "Payudan Karangsokon"
  },
  {
    "id": "3529092012",
    "district_id": "352909",
    "label": "Batuampar",
    "value": "Batuampar"
  },
  {
    "id": "3529102001",
    "district_id": "352910",
    "label": "Bataal Barat",
    "value": "Bataal Barat"
  },
  {
    "id": "3529102002",
    "district_id": "352910",
    "label": "Bataal Timur",
    "value": "Bataal Timur"
  },
  {
    "id": "3529102003",
    "district_id": "352910",
    "label": "Rombiya Barat",
    "value": "Rombiya Barat"
  },
  {
    "id": "3529102004",
    "district_id": "352910",
    "label": "Rombiya Timur",
    "value": "Rombiya Timur"
  },
  {
    "id": "3529102005",
    "district_id": "352910",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3529102006",
    "district_id": "352910",
    "label": "Billapora Barat",
    "value": "Billapora Barat"
  },
  {
    "id": "3529102007",
    "district_id": "352910",
    "label": "Billapora Timur",
    "value": "Billapora Timur"
  },
  {
    "id": "3529102008",
    "district_id": "352910",
    "label": "Ganding",
    "value": "Ganding"
  },
  {
    "id": "3529102009",
    "district_id": "352910",
    "label": "Gadu Timur",
    "value": "Gadu Timur"
  },
  {
    "id": "3529102010",
    "district_id": "352910",
    "label": "Gadu Barat",
    "value": "Gadu Barat"
  },
  {
    "id": "3529102011",
    "district_id": "352910",
    "label": "Ketawang Larangan",
    "value": "Ketawang Larangan"
  },
  {
    "id": "3529102012",
    "district_id": "352910",
    "label": "Ketawang Parebaan",
    "value": "Ketawang Parebaan"
  },
  {
    "id": "3529102013",
    "district_id": "352910",
    "label": "Ketawang Daleman",
    "value": "Ketawang Daleman"
  },
  {
    "id": "3529102014",
    "district_id": "352910",
    "label": "Ketawang Karay",
    "value": "Ketawang Karay"
  },
  {
    "id": "3529112001",
    "district_id": "352911",
    "label": "Kaduara Timur",
    "value": "Kaduara Timur"
  },
  {
    "id": "3529112002",
    "district_id": "352911",
    "label": "Sendang",
    "value": "Sendang"
  },
  {
    "id": "3529112003",
    "district_id": "352911",
    "label": "Rombasan",
    "value": "Rombasan"
  },
  {
    "id": "3529112004",
    "district_id": "352911",
    "label": "Sentol Laok",
    "value": "Sentol Laok"
  },
  {
    "id": "3529112005",
    "district_id": "352911",
    "label": "Larangan Perreng",
    "value": "Larangan Perreng"
  },
  {
    "id": "3529112006",
    "district_id": "352911",
    "label": "Sentol Daya",
    "value": "Sentol Daya"
  },
  {
    "id": "3529112007",
    "district_id": "352911",
    "label": "Pakamban Daya",
    "value": "Pakamban Daya"
  },
  {
    "id": "3529112008",
    "district_id": "352911",
    "label": "Pakamban Laok",
    "value": "Pakamban Laok"
  },
  {
    "id": "3529112009",
    "district_id": "352911",
    "label": "Jaddung",
    "value": "Jaddung"
  },
  {
    "id": "3529112010",
    "district_id": "352911",
    "label": "Pragaan Laok",
    "value": "Pragaan Laok"
  },
  {
    "id": "3529112011",
    "district_id": "352911",
    "label": "Pragaan Daya",
    "value": "Pragaan Daya"
  },
  {
    "id": "3529112012",
    "district_id": "352911",
    "label": "Prenduan",
    "value": "Prenduan"
  },
  {
    "id": "3529112013",
    "district_id": "352911",
    "label": "Aeng Panas",
    "value": "Aeng Panas"
  },
  {
    "id": "3529112014",
    "district_id": "352911",
    "label": "Karduluk",
    "value": "Karduluk"
  },
  {
    "id": "3529122001",
    "district_id": "352912",
    "label": "Ambunten Barat",
    "value": "Ambunten Barat"
  },
  {
    "id": "3529122002",
    "district_id": "352912",
    "label": "Ambunten Tengah",
    "value": "Ambunten Tengah"
  },
  {
    "id": "3529122003",
    "district_id": "352912",
    "label": "Ambunten Timur",
    "value": "Ambunten Timur"
  },
  {
    "id": "3529122004",
    "district_id": "352912",
    "label": "Tambaagung Barat",
    "value": "Tambaagung Barat"
  },
  {
    "id": "3529122005",
    "district_id": "352912",
    "label": "Tambaagung Tengah",
    "value": "Tambaagung Tengah"
  },
  {
    "id": "3529122006",
    "district_id": "352912",
    "label": "Tambaagung Ares",
    "value": "Tambaagung Ares"
  },
  {
    "id": "3529122007",
    "district_id": "352912",
    "label": "Sogian",
    "value": "Sogian"
  },
  {
    "id": "3529122008",
    "district_id": "352912",
    "label": "Tambaagung Timur",
    "value": "Tambaagung Timur"
  },
  {
    "id": "3529122009",
    "district_id": "352912",
    "label": "Bukabu",
    "value": "Bukabu"
  },
  {
    "id": "3529122010",
    "district_id": "352912",
    "label": "Campor Barat",
    "value": "Campor Barat"
  },
  {
    "id": "3529122011",
    "district_id": "352912",
    "label": "Keles",
    "value": "Keles"
  },
  {
    "id": "3529122012",
    "district_id": "352912",
    "label": "Campor Timur",
    "value": "Campor Timur"
  },
  {
    "id": "3529122013",
    "district_id": "352912",
    "label": "Belluk Ares",
    "value": "Belluk Ares"
  },
  {
    "id": "3529122014",
    "district_id": "352912",
    "label": "Belluk Kenek",
    "value": "Belluk Kenek"
  },
  {
    "id": "3529122015",
    "district_id": "352912",
    "label": "Belluk Raja",
    "value": "Belluk Raja"
  },
  {
    "id": "3529132001",
    "district_id": "352913",
    "label": "Montorna",
    "value": "Montorna"
  },
  {
    "id": "3529132002",
    "district_id": "352913",
    "label": "Prancak",
    "value": "Prancak"
  },
  {
    "id": "3529132003",
    "district_id": "352913",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3529132004",
    "district_id": "352913",
    "label": "Rajun",
    "value": "Rajun"
  },
  {
    "id": "3529132005",
    "district_id": "352913",
    "label": "Lebeng Timur",
    "value": "Lebeng Timur"
  },
  {
    "id": "3529132006",
    "district_id": "352913",
    "label": "Lebeng Barat",
    "value": "Lebeng Barat"
  },
  {
    "id": "3529132007",
    "district_id": "352913",
    "label": "Soddara",
    "value": "Soddara"
  },
  {
    "id": "3529132008",
    "district_id": "352913",
    "label": "Pasongsongan",
    "value": "Pasongsongan"
  },
  {
    "id": "3529132009",
    "district_id": "352913",
    "label": "Padangdangan",
    "value": "Padangdangan"
  },
  {
    "id": "3529132010",
    "district_id": "352913",
    "label": "Panaongan",
    "value": "Panaongan"
  },
  {
    "id": "3529142001",
    "district_id": "352914",
    "label": "Slopeng",
    "value": "Slopeng"
  },
  {
    "id": "3529142002",
    "district_id": "352914",
    "label": "Semaan",
    "value": "Semaan"
  },
  {
    "id": "3529142003",
    "district_id": "352914",
    "label": "Batubellah Barat",
    "value": "Batubellah Barat"
  },
  {
    "id": "3529142004",
    "district_id": "352914",
    "label": "Batubellah Timur",
    "value": "Batubellah Timur"
  },
  {
    "id": "3529142005",
    "district_id": "352914",
    "label": "Kecer",
    "value": "Kecer"
  },
  {
    "id": "3529142006",
    "district_id": "352914",
    "label": "Bates",
    "value": "Bates"
  },
  {
    "id": "3529142007",
    "district_id": "352914",
    "label": "Bringin",
    "value": "Bringin"
  },
  {
    "id": "3529142008",
    "district_id": "352914",
    "label": "Jelbudan",
    "value": "Jelbudan"
  },
  {
    "id": "3529142009",
    "district_id": "352914",
    "label": "Kerta Barat",
    "value": "Kerta Barat"
  },
  {
    "id": "3529142010",
    "district_id": "352914",
    "label": "Kerta Timur",
    "value": "Kerta Timur"
  },
  {
    "id": "3529142011",
    "district_id": "352914",
    "label": "Nyapar",
    "value": "Nyapar"
  },
  {
    "id": "3529142012",
    "district_id": "352914",
    "label": "Dasuk Barat",
    "value": "Dasuk Barat"
  },
  {
    "id": "3529142013",
    "district_id": "352914",
    "label": "Dasuk Timur",
    "value": "Dasuk Timur"
  },
  {
    "id": "3529142014",
    "district_id": "352914",
    "label": "Dasuk Laok",
    "value": "Dasuk Laok"
  },
  {
    "id": "3529142015",
    "district_id": "352914",
    "label": "Mantajun",
    "value": "Mantajun"
  },
  {
    "id": "3529152001",
    "district_id": "352915",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "3529152002",
    "district_id": "352915",
    "label": "Pakondang",
    "value": "Pakondang"
  },
  {
    "id": "3529152003",
    "district_id": "352915",
    "label": "Matanair",
    "value": "Matanair"
  },
  {
    "id": "3529152004",
    "district_id": "352915",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3529152005",
    "district_id": "352915",
    "label": "Banasare",
    "value": "Banasare"
  },
  {
    "id": "3529152006",
    "district_id": "352915",
    "label": "Bunbarat",
    "value": "Bunbarat"
  },
  {
    "id": "3529152007",
    "district_id": "352915",
    "label": "Karang Nangka",
    "value": "Karang Nangka"
  },
  {
    "id": "3529152008",
    "district_id": "352915",
    "label": "Basoka",
    "value": "Basoka"
  },
  {
    "id": "3529152009",
    "district_id": "352915",
    "label": "Duko",
    "value": "Duko"
  },
  {
    "id": "3529152010",
    "district_id": "352915",
    "label": "Rubaru",
    "value": "Rubaru"
  },
  {
    "id": "3529152011",
    "district_id": "352915",
    "label": "Kalebengan",
    "value": "Kalebengan"
  },
  {
    "id": "3529162001",
    "district_id": "352916",
    "label": "Kolpo",
    "value": "Kolpo"
  },
  {
    "id": "3529162002",
    "district_id": "352916",
    "label": "Tamidung",
    "value": "Tamidung"
  },
  {
    "id": "3529162003",
    "district_id": "352916",
    "label": "Batang-batang Laok",
    "value": "Batang-batang Laok"
  },
  {
    "id": "3529162004",
    "district_id": "352916",
    "label": "Batang-Batang Daya",
    "value": "Batang-Batang Daya"
  },
  {
    "id": "3529162005",
    "district_id": "352916",
    "label": "Totosan",
    "value": "Totosan"
  },
  {
    "id": "3529162006",
    "district_id": "352916",
    "label": "Banuaju Barat",
    "value": "Banuaju Barat"
  },
  {
    "id": "3529162007",
    "district_id": "352916",
    "label": "Banuaju Timur",
    "value": "Banuaju Timur"
  },
  {
    "id": "3529162008",
    "district_id": "352916",
    "label": "Jenangger",
    "value": "Jenangger"
  },
  {
    "id": "3529162009",
    "district_id": "352916",
    "label": "Nyabakan Timur",
    "value": "Nyabakan Timur"
  },
  {
    "id": "3529162010",
    "district_id": "352916",
    "label": "Nyabakan Barat",
    "value": "Nyabakan Barat"
  },
  {
    "id": "3529162011",
    "district_id": "352916",
    "label": "Jangkong",
    "value": "Jangkong"
  },
  {
    "id": "3529162012",
    "district_id": "352916",
    "label": "Legung Barat",
    "value": "Legung Barat"
  },
  {
    "id": "3529162013",
    "district_id": "352916",
    "label": "Legung Timur",
    "value": "Legung Timur"
  },
  {
    "id": "3529162014",
    "district_id": "352916",
    "label": "Dapenda",
    "value": "Dapenda"
  },
  {
    "id": "3529162015",
    "district_id": "352916",
    "label": "Bilangan",
    "value": "Bilangan"
  },
  {
    "id": "3529162016",
    "district_id": "352916",
    "label": "Lombang",
    "value": "Lombang"
  },
  {
    "id": "3529172001",
    "district_id": "352917",
    "label": "Aengmerah",
    "value": "Aengmerah"
  },
  {
    "id": "3529172002",
    "district_id": "352917",
    "label": "Tengedan",
    "value": "Tengedan"
  },
  {
    "id": "3529172003",
    "district_id": "352917",
    "label": "Juruan Laok",
    "value": "Juruan Laok"
  },
  {
    "id": "3529172004",
    "district_id": "352917",
    "label": "Juruan Daya",
    "value": "Juruan Daya"
  },
  {
    "id": "3529172005",
    "district_id": "352917",
    "label": "Badur",
    "value": "Badur"
  },
  {
    "id": "3529172006",
    "district_id": "352917",
    "label": "Gedang-Gedang",
    "value": "Gedang-Gedang"
  },
  {
    "id": "3529172007",
    "district_id": "352917",
    "label": "Batuputih Kenek",
    "value": "Batuputih Kenek"
  },
  {
    "id": "3529172008",
    "district_id": "352917",
    "label": "Batuputih Laok",
    "value": "Batuputih Laok"
  },
  {
    "id": "3529172009",
    "district_id": "352917",
    "label": "Batuputih Daya",
    "value": "Batuputih Daya"
  },
  {
    "id": "3529172010",
    "district_id": "352917",
    "label": "Bantelan",
    "value": "Bantelan"
  },
  {
    "id": "3529172011",
    "district_id": "352917",
    "label": "Larangan Barma",
    "value": "Larangan Barma"
  },
  {
    "id": "3529172012",
    "district_id": "352917",
    "label": "Larangan Kerta",
    "value": "Larangan Kerta"
  },
  {
    "id": "3529172013",
    "district_id": "352917",
    "label": "Bulla'an",
    "value": "Bulla'an"
  },
  {
    "id": "3529172014",
    "district_id": "352917",
    "label": "Sergang",
    "value": "Sergang"
  },
  {
    "id": "3529182001",
    "district_id": "352918",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3529182002",
    "district_id": "352918",
    "label": "Jadung",
    "value": "Jadung"
  },
  {
    "id": "3529182003",
    "district_id": "352918",
    "label": "Romben Barat",
    "value": "Romben Barat"
  },
  {
    "id": "3529182004",
    "district_id": "352918",
    "label": "Romben Rana",
    "value": "Romben Rana"
  },
  {
    "id": "3529182005",
    "district_id": "352918",
    "label": "Romben Guna",
    "value": "Romben Guna"
  },
  {
    "id": "3529182006",
    "district_id": "352918",
    "label": "Taman Sare",
    "value": "Taman Sare"
  },
  {
    "id": "3529182007",
    "district_id": "352918",
    "label": "Bicabbi",
    "value": "Bicabbi"
  },
  {
    "id": "3529182008",
    "district_id": "352918",
    "label": "Dungkek",
    "value": "Dungkek"
  },
  {
    "id": "3529182009",
    "district_id": "352918",
    "label": "Bunpenang",
    "value": "Bunpenang"
  },
  {
    "id": "3529182010",
    "district_id": "352918",
    "label": "Bungin-bungin",
    "value": "Bungin-bungin"
  },
  {
    "id": "3529182011",
    "district_id": "352918",
    "label": "Lapa Taman",
    "value": "Lapa Taman"
  },
  {
    "id": "3529182012",
    "district_id": "352918",
    "label": "Lapa Laok",
    "value": "Lapa Laok"
  },
  {
    "id": "3529182013",
    "district_id": "352918",
    "label": "Lapa Daya",
    "value": "Lapa Daya"
  },
  {
    "id": "3529182014",
    "district_id": "352918",
    "label": "Bancamara",
    "value": "Bancamara"
  },
  {
    "id": "3529182015",
    "district_id": "352918",
    "label": "Banra'as",
    "value": "Banra'as"
  },
  {
    "id": "3529192001",
    "district_id": "352919",
    "label": "Grujugan",
    "value": "Grujugan"
  },
  {
    "id": "3529192002",
    "district_id": "352919",
    "label": "Longos",
    "value": "Longos"
  },
  {
    "id": "3529192003",
    "district_id": "352919",
    "label": "Andulang",
    "value": "Andulang"
  },
  {
    "id": "3529192004",
    "district_id": "352919",
    "label": "Gapura Timur",
    "value": "Gapura Timur"
  },
  {
    "id": "3529192005",
    "district_id": "352919",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "3529192006",
    "district_id": "352919",
    "label": "Gersik Putih",
    "value": "Gersik Putih"
  },
  {
    "id": "3529192007",
    "district_id": "352919",
    "label": "Gapura Tengah",
    "value": "Gapura Tengah"
  },
  {
    "id": "3529192008",
    "district_id": "352919",
    "label": "Gapura Barat",
    "value": "Gapura Barat"
  },
  {
    "id": "3529192009",
    "district_id": "352919",
    "label": "Panagan",
    "value": "Panagan"
  },
  {
    "id": "3529192010",
    "district_id": "352919",
    "label": "Palo'lo'an",
    "value": "Palo'lo'an"
  },
  {
    "id": "3529192011",
    "district_id": "352919",
    "label": "Banjar Timur",
    "value": "Banjar Timur"
  },
  {
    "id": "3529192012",
    "district_id": "352919",
    "label": "Banjar Barat",
    "value": "Banjar Barat"
  },
  {
    "id": "3529192013",
    "district_id": "352919",
    "label": "Batudinding",
    "value": "Batudinding"
  },
  {
    "id": "3529192014",
    "district_id": "352919",
    "label": "Baban",
    "value": "Baban"
  },
  {
    "id": "3529192015",
    "district_id": "352919",
    "label": "Karangbudi",
    "value": "Karangbudi"
  },
  {
    "id": "3529192016",
    "district_id": "352919",
    "label": "Braji",
    "value": "Braji"
  },
  {
    "id": "3529192017",
    "district_id": "352919",
    "label": "Poja",
    "value": "Poja"
  },
  {
    "id": "3529202001",
    "district_id": "352920",
    "label": "Pancor",
    "value": "Pancor"
  },
  {
    "id": "3529202002",
    "district_id": "352920",
    "label": "Prambanan",
    "value": "Prambanan"
  },
  {
    "id": "3529202003",
    "district_id": "352920",
    "label": "Gendang Timur",
    "value": "Gendang Timur"
  },
  {
    "id": "3529202004",
    "district_id": "352920",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3529202005",
    "district_id": "352920",
    "label": "Jambuir",
    "value": "Jambuir"
  },
  {
    "id": "3529202006",
    "district_id": "352920",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3529202007",
    "district_id": "352920",
    "label": "Kalowang",
    "value": "Kalowang"
  },
  {
    "id": "3529202008",
    "district_id": "352920",
    "label": "Nyamplong",
    "value": "Nyamplong"
  },
  {
    "id": "3529202009",
    "district_id": "352920",
    "label": "Gendang Barat",
    "value": "Gendang Barat"
  },
  {
    "id": "3529202010",
    "district_id": "352920",
    "label": "Tarebung",
    "value": "Tarebung"
  },
  {
    "id": "3529212001",
    "district_id": "352921",
    "label": "Nonggunong",
    "value": "Nonggunong"
  },
  {
    "id": "3529212002",
    "district_id": "352921",
    "label": "Somber",
    "value": "Somber"
  },
  {
    "id": "3529212003",
    "district_id": "352921",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "3529212004",
    "district_id": "352921",
    "label": "Sokaramme Timur",
    "value": "Sokaramme Timur"
  },
  {
    "id": "3529212005",
    "district_id": "352921",
    "label": "Sokarami Paseser",
    "value": "Sokarami Paseser"
  },
  {
    "id": "3529212006",
    "district_id": "352921",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3529212007",
    "district_id": "352921",
    "label": "Rosong",
    "value": "Rosong"
  },
  {
    "id": "3529212008",
    "district_id": "352921",
    "label": "Sonok",
    "value": "Sonok"
  },
  {
    "id": "3529222001",
    "district_id": "352922",
    "label": "Ketupat",
    "value": "Ketupat"
  },
  {
    "id": "3529222002",
    "district_id": "352922",
    "label": "Jungkat",
    "value": "Jungkat"
  },
  {
    "id": "3529222003",
    "district_id": "352922",
    "label": "Kropoh",
    "value": "Kropoh"
  },
  {
    "id": "3529222004",
    "district_id": "352922",
    "label": "Karangnangka",
    "value": "Karangnangka"
  },
  {
    "id": "3529222005",
    "district_id": "352922",
    "label": "Alas Malang",
    "value": "Alas Malang"
  },
  {
    "id": "3529222006",
    "district_id": "352922",
    "label": "Poteran",
    "value": "Poteran"
  },
  {
    "id": "3529222007",
    "district_id": "352922",
    "label": "Brakas",
    "value": "Brakas"
  },
  {
    "id": "3529222008",
    "district_id": "352922",
    "label": "Tonduk",
    "value": "Tonduk"
  },
  {
    "id": "3529222009",
    "district_id": "352922",
    "label": "Guwa-Guwa",
    "value": "Guwa-Guwa"
  },
  {
    "id": "3529232001",
    "district_id": "352923",
    "label": "Sukajeruk",
    "value": "Sukajeruk"
  },
  {
    "id": "3529232002",
    "district_id": "352923",
    "label": "Masalima",
    "value": "Masalima"
  },
  {
    "id": "3529232003",
    "district_id": "352923",
    "label": "Masakambing",
    "value": "Masakambing"
  },
  {
    "id": "3529232004",
    "district_id": "352923",
    "label": "Kramian",
    "value": "Kramian"
  },
  {
    "id": "3529242001",
    "district_id": "352924",
    "label": "Pajanangger",
    "value": "Pajanangger"
  },
  {
    "id": "3529242008",
    "district_id": "352924",
    "label": "Sawah Sumur",
    "value": "Sawah Sumur"
  },
  {
    "id": "3529242009",
    "district_id": "352924",
    "label": "Paseraman",
    "value": "Paseraman"
  },
  {
    "id": "3529242010",
    "district_id": "352924",
    "label": "Gelaman",
    "value": "Gelaman"
  },
  {
    "id": "3529242011",
    "district_id": "352924",
    "label": "Buddi",
    "value": "Buddi"
  },
  {
    "id": "3529242012",
    "district_id": "352924",
    "label": "Kolo-kolo",
    "value": "Kolo-kolo"
  },
  {
    "id": "3529242013",
    "district_id": "352924",
    "label": "Angkatan",
    "value": "Angkatan"
  },
  {
    "id": "3529242014",
    "district_id": "352924",
    "label": "Kalisangka",
    "value": "Kalisangka"
  },
  {
    "id": "3529242015",
    "district_id": "352924",
    "label": "Bilis-bilis",
    "value": "Bilis-bilis"
  },
  {
    "id": "3529242016",
    "district_id": "352924",
    "label": "Laok Jang-Jang",
    "value": "Laok Jang-Jang"
  },
  {
    "id": "3529242017",
    "district_id": "352924",
    "label": "Sumbernangka",
    "value": "Sumbernangka"
  },
  {
    "id": "3529242018",
    "district_id": "352924",
    "label": "Duko",
    "value": "Duko"
  },
  {
    "id": "3529242019",
    "district_id": "352924",
    "label": "Kalikatak",
    "value": "Kalikatak"
  },
  {
    "id": "3529242020",
    "district_id": "352924",
    "label": "Arjasa",
    "value": "Arjasa"
  },
  {
    "id": "3529242021",
    "district_id": "352924",
    "label": "Kalinganyar",
    "value": "Kalinganyar"
  },
  {
    "id": "3529242022",
    "district_id": "352924",
    "label": "Angon-angon",
    "value": "Angon-angon"
  },
  {
    "id": "3529242023",
    "district_id": "352924",
    "label": "Sambakati",
    "value": "Sambakati"
  },
  {
    "id": "3529242024",
    "district_id": "352924",
    "label": "Pandeman",
    "value": "Pandeman"
  },
  {
    "id": "3529242025",
    "district_id": "352924",
    "label": "Pabean",
    "value": "Pabean"
  },
  {
    "id": "3529252001",
    "district_id": "352925",
    "label": "Sepanjang",
    "value": "Sepanjang"
  },
  {
    "id": "3529252002",
    "district_id": "352925",
    "label": "Tanjung Kiaok",
    "value": "Tanjung Kiaok"
  },
  {
    "id": "3529252003",
    "district_id": "352925",
    "label": "Sakala",
    "value": "Sakala"
  },
  {
    "id": "3529252004",
    "district_id": "352925",
    "label": "Pagerungan Besar",
    "value": "Pagerungan Besar"
  },
  {
    "id": "3529252005",
    "district_id": "352925",
    "label": "Pagerungan Kecil",
    "value": "Pagerungan Kecil"
  },
  {
    "id": "3529252006",
    "district_id": "352925",
    "label": "Saseel",
    "value": "Saseel"
  },
  {
    "id": "3529252007",
    "district_id": "352925",
    "label": "Sapeken",
    "value": "Sapeken"
  },
  {
    "id": "3529252008",
    "district_id": "352925",
    "label": "Sabunten",
    "value": "Sabunten"
  },
  {
    "id": "3529252009",
    "district_id": "352925",
    "label": "Paliat",
    "value": "Paliat"
  },
  {
    "id": "3529252010",
    "district_id": "352925",
    "label": "Sadulang",
    "value": "Sadulang"
  },
  {
    "id": "3529252011",
    "district_id": "352925",
    "label": "Saur Saebus",
    "value": "Saur Saebus"
  },
  {
    "id": "3529262001",
    "district_id": "352926",
    "label": "Patean",
    "value": "Patean"
  },
  {
    "id": "3529262002",
    "district_id": "352926",
    "label": "Babbalan",
    "value": "Babbalan"
  },
  {
    "id": "3529262003",
    "district_id": "352926",
    "label": "Gedungan",
    "value": "Gedungan"
  },
  {
    "id": "3529262004",
    "district_id": "352926",
    "label": "Gunggung",
    "value": "Gunggung"
  },
  {
    "id": "3529262005",
    "district_id": "352926",
    "label": "Batuan",
    "value": "Batuan"
  },
  {
    "id": "3529262006",
    "district_id": "352926",
    "label": "Torbang",
    "value": "Torbang"
  },
  {
    "id": "3529262007",
    "district_id": "352926",
    "label": "Gelugur",
    "value": "Gelugur"
  },
  {
    "id": "3529272001",
    "district_id": "352927",
    "label": "Saobi",
    "value": "Saobi"
  },
  {
    "id": "3529272002",
    "district_id": "352927",
    "label": "Kangayan",
    "value": "Kangayan"
  },
  {
    "id": "3529272003",
    "district_id": "352927",
    "label": "Torjek",
    "value": "Torjek"
  },
  {
    "id": "3529272004",
    "district_id": "352927",
    "label": "Cangkramaan",
    "value": "Cangkramaan"
  },
  {
    "id": "3529272005",
    "district_id": "352927",
    "label": "Tembayangan",
    "value": "Tembayangan"
  },
  {
    "id": "3529272006",
    "district_id": "352927",
    "label": "Batuputih",
    "value": "Batuputih"
  },
  {
    "id": "3529272007",
    "district_id": "352927",
    "label": "Daandung",
    "value": "Daandung"
  },
  {
    "id": "3529272008",
    "district_id": "352927",
    "label": "Timur Jang-Jang",
    "value": "Timur Jang-Jang"
  },
  {
    "id": "3529272009",
    "district_id": "352927",
    "label": "Jukong-jukong",
    "value": "Jukong-jukong"
  },
  {
    "id": "3571011001",
    "district_id": "357101",
    "label": "Bandar Lor",
    "value": "Bandar Lor"
  },
  {
    "id": "3571011002",
    "district_id": "357101",
    "label": "Bandar Kidul",
    "value": "Bandar Kidul"
  },
  {
    "id": "3571011003",
    "district_id": "357101",
    "label": "Banjar Mlati",
    "value": "Banjar Mlati"
  },
  {
    "id": "3571011004",
    "district_id": "357101",
    "label": "Pojok",
    "value": "Pojok"
  },
  {
    "id": "3571011005",
    "district_id": "357101",
    "label": "Sukorame",
    "value": "Sukorame"
  },
  {
    "id": "3571011006",
    "district_id": "357101",
    "label": "Bujel",
    "value": "Bujel"
  },
  {
    "id": "3571011007",
    "district_id": "357101",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "3571011008",
    "district_id": "357101",
    "label": "Mrican",
    "value": "Mrican"
  },
  {
    "id": "3571011009",
    "district_id": "357101",
    "label": "Dermo",
    "value": "Dermo"
  },
  {
    "id": "3571011010",
    "district_id": "357101",
    "label": "Ngampel",
    "value": "Ngampel"
  },
  {
    "id": "3571011011",
    "district_id": "357101",
    "label": "Mojoroto",
    "value": "Mojoroto"
  },
  {
    "id": "3571011012",
    "district_id": "357101",
    "label": "Campurejo",
    "value": "Campurejo"
  },
  {
    "id": "3571011013",
    "district_id": "357101",
    "label": "Lirboyo",
    "value": "Lirboyo"
  },
  {
    "id": "3571011014",
    "district_id": "357101",
    "label": "Tamanan",
    "value": "Tamanan"
  },
  {
    "id": "3571021001",
    "district_id": "357102",
    "label": "Semampir",
    "value": "Semampir"
  },
  {
    "id": "3571021002",
    "district_id": "357102",
    "label": "Balowerti",
    "value": "Balowerti"
  },
  {
    "id": "3571021003",
    "district_id": "357102",
    "label": "Dandangan",
    "value": "Dandangan"
  },
  {
    "id": "3571021004",
    "district_id": "357102",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3571021005",
    "district_id": "357102",
    "label": "Kampung Dalem",
    "value": "Kampung Dalem"
  },
  {
    "id": "3571021006",
    "district_id": "357102",
    "label": "Setonopande",
    "value": "Setonopande"
  },
  {
    "id": "3571021007",
    "district_id": "357102",
    "label": "Ringinanom",
    "value": "Ringinanom"
  },
  {
    "id": "3571021008",
    "district_id": "357102",
    "label": "Pakelan",
    "value": "Pakelan"
  },
  {
    "id": "3571021009",
    "district_id": "357102",
    "label": "Setonogedong",
    "value": "Setonogedong"
  },
  {
    "id": "3571021010",
    "district_id": "357102",
    "label": "Kemasan",
    "value": "Kemasan"
  },
  {
    "id": "3571021011",
    "district_id": "357102",
    "label": "Jagalan",
    "value": "Jagalan"
  },
  {
    "id": "3571021012",
    "district_id": "357102",
    "label": "Banjaran",
    "value": "Banjaran"
  },
  {
    "id": "3571021013",
    "district_id": "357102",
    "label": "Kaliombo",
    "value": "Kaliombo"
  },
  {
    "id": "3571021014",
    "district_id": "357102",
    "label": "Ngronggo",
    "value": "Ngronggo"
  },
  {
    "id": "3571021015",
    "district_id": "357102",
    "label": "Manisrenggo",
    "value": "Manisrenggo"
  },
  {
    "id": "3571021016",
    "district_id": "357102",
    "label": "Pocanan",
    "value": "Pocanan"
  },
  {
    "id": "3571021017",
    "district_id": "357102",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3571031001",
    "district_id": "357103",
    "label": "Bangsal",
    "value": "Bangsal"
  },
  {
    "id": "3571031002",
    "district_id": "357103",
    "label": "Pakunden",
    "value": "Pakunden"
  },
  {
    "id": "3571031003",
    "district_id": "357103",
    "label": "Tosaren",
    "value": "Tosaren"
  },
  {
    "id": "3571031004",
    "district_id": "357103",
    "label": "Jamsaren",
    "value": "Jamsaren"
  },
  {
    "id": "3571031005",
    "district_id": "357103",
    "label": "Singonegaran",
    "value": "Singonegaran"
  },
  {
    "id": "3571031006",
    "district_id": "357103",
    "label": "Blabak",
    "value": "Blabak"
  },
  {
    "id": "3571031007",
    "district_id": "357103",
    "label": "Betet",
    "value": "Betet"
  },
  {
    "id": "3571031008",
    "district_id": "357103",
    "label": "Tinalan",
    "value": "Tinalan"
  },
  {
    "id": "3571031009",
    "district_id": "357103",
    "label": "Bawang",
    "value": "Bawang"
  },
  {
    "id": "3571031010",
    "district_id": "357103",
    "label": "Ngletih",
    "value": "Ngletih"
  },
  {
    "id": "3571031011",
    "district_id": "357103",
    "label": "Tampurejo",
    "value": "Tampurejo"
  },
  {
    "id": "3571031012",
    "district_id": "357103",
    "label": "Ketami",
    "value": "Ketami"
  },
  {
    "id": "3571031013",
    "district_id": "357103",
    "label": "Pesantren",
    "value": "Pesantren"
  },
  {
    "id": "3571031014",
    "district_id": "357103",
    "label": "Banaran",
    "value": "Banaran"
  },
  {
    "id": "3571031015",
    "district_id": "357103",
    "label": "Burengan",
    "value": "Burengan"
  },
  {
    "id": "3572011001",
    "district_id": "357201",
    "label": "Kepanjenkidul",
    "value": "Kepanjenkidul"
  },
  {
    "id": "3572011002",
    "district_id": "357201",
    "label": "Ngadirejo",
    "value": "Ngadirejo"
  },
  {
    "id": "3572011003",
    "district_id": "357201",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3572011004",
    "district_id": "357201",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3572011005",
    "district_id": "357201",
    "label": "Tanggung",
    "value": "Tanggung"
  },
  {
    "id": "3572011006",
    "district_id": "357201",
    "label": "Bendo",
    "value": "Bendo"
  },
  {
    "id": "3572011007",
    "district_id": "357201",
    "label": "Kepanjenlor",
    "value": "Kepanjenlor"
  },
  {
    "id": "3572021001",
    "district_id": "357202",
    "label": "Pakunden",
    "value": "Pakunden"
  },
  {
    "id": "3572021002",
    "district_id": "357202",
    "label": "Blitar",
    "value": "Blitar"
  },
  {
    "id": "3572021003",
    "district_id": "357202",
    "label": "Tlumpu",
    "value": "Tlumpu"
  },
  {
    "id": "3572021004",
    "district_id": "357202",
    "label": "Turi",
    "value": "Turi"
  },
  {
    "id": "3572021005",
    "district_id": "357202",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3572021006",
    "district_id": "357202",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "3572021007",
    "district_id": "357202",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3572031001",
    "district_id": "357203",
    "label": "Gedog",
    "value": "Gedog"
  },
  {
    "id": "3572031002",
    "district_id": "357203",
    "label": "Plosokerep",
    "value": "Plosokerep"
  },
  {
    "id": "3572031003",
    "district_id": "357203",
    "label": "Klampok",
    "value": "Klampok"
  },
  {
    "id": "3572031004",
    "district_id": "357203",
    "label": "Sananwetan",
    "value": "Sananwetan"
  },
  {
    "id": "3572031005",
    "district_id": "357203",
    "label": "Rembang",
    "value": "Rembang"
  },
  {
    "id": "3572031006",
    "district_id": "357203",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3572031007",
    "district_id": "357203",
    "label": "Bendogerit",
    "value": "Bendogerit"
  },
  {
    "id": "3573011001",
    "district_id": "357301",
    "label": "Balearjosari",
    "value": "Balearjosari"
  },
  {
    "id": "3573011002",
    "district_id": "357301",
    "label": "Arjosari",
    "value": "Arjosari"
  },
  {
    "id": "3573011003",
    "district_id": "357301",
    "label": "Polowijen",
    "value": "Polowijen"
  },
  {
    "id": "3573011004",
    "district_id": "357301",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "3573011005",
    "district_id": "357301",
    "label": "Blimbing",
    "value": "Blimbing"
  },
  {
    "id": "3573011006",
    "district_id": "357301",
    "label": "Pandanwangi",
    "value": "Pandanwangi"
  },
  {
    "id": "3573011007",
    "district_id": "357301",
    "label": "Purwantoro",
    "value": "Purwantoro"
  },
  {
    "id": "3573011008",
    "district_id": "357301",
    "label": "Bunulrejo",
    "value": "Bunulrejo"
  },
  {
    "id": "3573011009",
    "district_id": "357301",
    "label": "Kesatrian",
    "value": "Kesatrian"
  },
  {
    "id": "3573011010",
    "district_id": "357301",
    "label": "Polehan",
    "value": "Polehan"
  },
  {
    "id": "3573011011",
    "district_id": "357301",
    "label": "Jodipan",
    "value": "Jodipan"
  },
  {
    "id": "3573021001",
    "district_id": "357302",
    "label": "Klojen",
    "value": "Klojen"
  },
  {
    "id": "3573021002",
    "district_id": "357302",
    "label": "Rampalcelaket",
    "value": "Rampalcelaket"
  },
  {
    "id": "3573021003",
    "district_id": "357302",
    "label": "Samaan",
    "value": "Samaan"
  },
  {
    "id": "3573021004",
    "district_id": "357302",
    "label": "Kiduldalem",
    "value": "Kiduldalem"
  },
  {
    "id": "3573021005",
    "district_id": "357302",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3573021006",
    "district_id": "357302",
    "label": "Kasin",
    "value": "Kasin"
  },
  {
    "id": "3573021007",
    "district_id": "357302",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3573021008",
    "district_id": "357302",
    "label": "Oro-oro Dowo",
    "value": "Oro-oro Dowo"
  },
  {
    "id": "3573021009",
    "district_id": "357302",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "3573021010",
    "district_id": "357302",
    "label": "Gading Kasri",
    "value": "Gading Kasri"
  },
  {
    "id": "3573021011",
    "district_id": "357302",
    "label": "Penanggungan",
    "value": "Penanggungan"
  },
  {
    "id": "3573031001",
    "district_id": "357303",
    "label": "Kotalama",
    "value": "Kotalama"
  },
  {
    "id": "3573031002",
    "district_id": "357303",
    "label": "Mergosono",
    "value": "Mergosono"
  },
  {
    "id": "3573031003",
    "district_id": "357303",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "3573031004",
    "district_id": "357303",
    "label": "Wonokoyo",
    "value": "Wonokoyo"
  },
  {
    "id": "3573031005",
    "district_id": "357303",
    "label": "Buring",
    "value": "Buring"
  },
  {
    "id": "3573031006",
    "district_id": "357303",
    "label": "Kedungkandang",
    "value": "Kedungkandang"
  },
  {
    "id": "3573031007",
    "district_id": "357303",
    "label": "Lesanpuro",
    "value": "Lesanpuro"
  },
  {
    "id": "3573031008",
    "district_id": "357303",
    "label": "Sawojajar",
    "value": "Sawojajar"
  },
  {
    "id": "3573031009",
    "district_id": "357303",
    "label": "Madyopuro",
    "value": "Madyopuro"
  },
  {
    "id": "3573031010",
    "district_id": "357303",
    "label": "Cemorokandang",
    "value": "Cemorokandang"
  },
  {
    "id": "3573031011",
    "district_id": "357303",
    "label": "Arjowinangun",
    "value": "Arjowinangun"
  },
  {
    "id": "3573031012",
    "district_id": "357303",
    "label": "Tlogowaru",
    "value": "Tlogowaru"
  },
  {
    "id": "3573041001",
    "district_id": "357304",
    "label": "Ciptomulyo",
    "value": "Ciptomulyo"
  },
  {
    "id": "3573041002",
    "district_id": "357304",
    "label": "Gadang",
    "value": "Gadang"
  },
  {
    "id": "3573041003",
    "district_id": "357304",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3573041004",
    "district_id": "357304",
    "label": "Bandungrejosari",
    "value": "Bandungrejosari"
  },
  {
    "id": "3573041005",
    "district_id": "357304",
    "label": "Sukun",
    "value": "Sukun"
  },
  {
    "id": "3573041006",
    "district_id": "357304",
    "label": "Tanjungrejo",
    "value": "Tanjungrejo"
  },
  {
    "id": "3573041007",
    "district_id": "357304",
    "label": "Pisangcandi",
    "value": "Pisangcandi"
  },
  {
    "id": "3573041008",
    "district_id": "357304",
    "label": "Bandulan",
    "value": "Bandulan"
  },
  {
    "id": "3573041009",
    "district_id": "357304",
    "label": "Karangbesuki",
    "value": "Karangbesuki"
  },
  {
    "id": "3573041010",
    "district_id": "357304",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3573041011",
    "district_id": "357304",
    "label": "Bakalankrajan",
    "value": "Bakalankrajan"
  },
  {
    "id": "3573051001",
    "district_id": "357305",
    "label": "Tunggulwulung",
    "value": "Tunggulwulung"
  },
  {
    "id": "3573051002",
    "district_id": "357305",
    "label": "Merjosari",
    "value": "Merjosari"
  },
  {
    "id": "3573051003",
    "district_id": "357305",
    "label": "Tlogomas",
    "value": "Tlogomas"
  },
  {
    "id": "3573051004",
    "district_id": "357305",
    "label": "Dinoyo",
    "value": "Dinoyo"
  },
  {
    "id": "3573051005",
    "district_id": "357305",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "3573051006",
    "district_id": "357305",
    "label": "Ketawanggede",
    "value": "Ketawanggede"
  },
  {
    "id": "3573051007",
    "district_id": "357305",
    "label": "Jatimulyo",
    "value": "Jatimulyo"
  },
  {
    "id": "3573051008",
    "district_id": "357305",
    "label": "Tunjungsekar",
    "value": "Tunjungsekar"
  },
  {
    "id": "3573051009",
    "district_id": "357305",
    "label": "Mojolangu",
    "value": "Mojolangu"
  },
  {
    "id": "3573051010",
    "district_id": "357305",
    "label": "Tulusrejo",
    "value": "Tulusrejo"
  },
  {
    "id": "3573051011",
    "district_id": "357305",
    "label": "Lowokwaru",
    "value": "Lowokwaru"
  },
  {
    "id": "3573051012",
    "district_id": "357305",
    "label": "Tasik Madu",
    "value": "Tasik Madu"
  },
  {
    "id": "3574011001",
    "district_id": "357401",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3574011002",
    "district_id": "357401",
    "label": "Triwung Lor",
    "value": "Triwung Lor"
  },
  {
    "id": "3574011003",
    "district_id": "357401",
    "label": "Triwung Kidul",
    "value": "Triwung Kidul"
  },
  {
    "id": "3574011007",
    "district_id": "357401",
    "label": "Pohsangit Kidul",
    "value": "Pohsangit Kidul"
  },
  {
    "id": "3574011008",
    "district_id": "357401",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3574011009",
    "district_id": "357401",
    "label": "Pilang",
    "value": "Pilang"
  },
  {
    "id": "3574021001",
    "district_id": "357402",
    "label": "Jrebeng Kidul",
    "value": "Jrebeng Kidul"
  },
  {
    "id": "3574021002",
    "district_id": "357402",
    "label": "Pakistaji",
    "value": "Pakistaji"
  },
  {
    "id": "3574021003",
    "district_id": "357402",
    "label": "Kedunggaleng",
    "value": "Kedunggaleng"
  },
  {
    "id": "3574021005",
    "district_id": "357402",
    "label": "Kedung Asem",
    "value": "Kedung Asem"
  },
  {
    "id": "3574021006",
    "district_id": "357402",
    "label": "Sumber Taman",
    "value": "Sumber Taman"
  },
  {
    "id": "3574021007",
    "district_id": "357402",
    "label": "Wonoasih",
    "value": "Wonoasih"
  },
  {
    "id": "3574031001",
    "district_id": "357403",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "3574031002",
    "district_id": "357403",
    "label": "Mangunharjo",
    "value": "Mangunharjo"
  },
  {
    "id": "3574031004",
    "district_id": "357403",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "3574031007",
    "district_id": "357403",
    "label": "Sukabumi",
    "value": "Sukabumi"
  },
  {
    "id": "3574031010",
    "district_id": "357403",
    "label": "Wiroborang",
    "value": "Wiroborang"
  },
  {
    "id": "3574041001",
    "district_id": "357404",
    "label": "Tisnonegaran",
    "value": "Tisnonegaran"
  },
  {
    "id": "3574041002",
    "district_id": "357404",
    "label": "Sukoharjo",
    "value": "Sukoharjo"
  },
  {
    "id": "3574041003",
    "district_id": "357404",
    "label": "Kanigaran",
    "value": "Kanigaran"
  },
  {
    "id": "3574041004",
    "district_id": "357404",
    "label": "Kebonsari Wetan",
    "value": "Kebonsari Wetan"
  },
  {
    "id": "3574041005",
    "district_id": "357404",
    "label": "Curahgrinting",
    "value": "Curahgrinting"
  },
  {
    "id": "3574041006",
    "district_id": "357404",
    "label": "Kebonsari Kulon",
    "value": "Kebonsari Kulon"
  },
  {
    "id": "3574051001",
    "district_id": "357405",
    "label": "Jrebeng Kulon",
    "value": "Jrebeng Kulon"
  },
  {
    "id": "3574051002",
    "district_id": "357405",
    "label": "Kareng Lor",
    "value": "Kareng Lor"
  },
  {
    "id": "3574051003",
    "district_id": "357405",
    "label": "Sumber Wetan",
    "value": "Sumber Wetan"
  },
  {
    "id": "3574051004",
    "district_id": "357405",
    "label": "Jrebeng Lor",
    "value": "Jrebeng Lor"
  },
  {
    "id": "3574051005",
    "district_id": "357405",
    "label": "Kedopok",
    "value": "Kedopok"
  },
  {
    "id": "3574051006",
    "district_id": "357405",
    "label": "Jrebeng Wetan",
    "value": "Jrebeng Wetan"
  },
  {
    "id": "3575011001",
    "district_id": "357501",
    "label": "Karangketug",
    "value": "Karangketug"
  },
  {
    "id": "3575011002",
    "district_id": "357501",
    "label": "Gentong",
    "value": "Gentong"
  },
  {
    "id": "3575011003",
    "district_id": "357501",
    "label": "Sebani",
    "value": "Sebani"
  },
  {
    "id": "3575011004",
    "district_id": "357501",
    "label": "Petahunan",
    "value": "Petahunan"
  },
  {
    "id": "3575011005",
    "district_id": "357501",
    "label": "Bukir",
    "value": "Bukir"
  },
  {
    "id": "3575011006",
    "district_id": "357501",
    "label": "Randusari",
    "value": "Randusari"
  },
  {
    "id": "3575011007",
    "district_id": "357501",
    "label": "Krapyakrejo",
    "value": "Krapyakrejo"
  },
  {
    "id": "3575011009",
    "district_id": "357501",
    "label": "Gadingrejo",
    "value": "Gadingrejo"
  },
  {
    "id": "3575021001",
    "district_id": "357502",
    "label": "Pohjentrek",
    "value": "Pohjentrek"
  },
  {
    "id": "3575021002",
    "district_id": "357502",
    "label": "Wirogunan",
    "value": "Wirogunan"
  },
  {
    "id": "3575021003",
    "district_id": "357502",
    "label": "Tembokrejo",
    "value": "Tembokrejo"
  },
  {
    "id": "3575021004",
    "district_id": "357502",
    "label": "Purutrejo",
    "value": "Purutrejo"
  },
  {
    "id": "3575021005",
    "district_id": "357502",
    "label": "Kebonagung",
    "value": "Kebonagung"
  },
  {
    "id": "3575021006",
    "district_id": "357502",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "3575021011",
    "district_id": "357502",
    "label": "Sekargadung",
    "value": "Sekargadung"
  },
  {
    "id": "3575031001",
    "district_id": "357503",
    "label": "Blandongan",
    "value": "Blandongan"
  },
  {
    "id": "3575031002",
    "district_id": "357503",
    "label": "Kepel",
    "value": "Kepel"
  },
  {
    "id": "3575031003",
    "district_id": "357503",
    "label": "Tapa'an",
    "value": "Tapa'an"
  },
  {
    "id": "3575031005",
    "district_id": "357503",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "3575031006",
    "district_id": "357503",
    "label": "Krampyangan",
    "value": "Krampyangan"
  },
  {
    "id": "3575031009",
    "district_id": "357503",
    "label": "Bugul Kidul",
    "value": "Bugul Kidul"
  },
  {
    "id": "3575041001",
    "district_id": "357504",
    "label": "Karanganyar",
    "value": "Karanganyar"
  },
  {
    "id": "3575041002",
    "district_id": "357504",
    "label": "Tamba'an",
    "value": "Tamba'an"
  },
  {
    "id": "3575041003",
    "district_id": "357504",
    "label": "Trajeng",
    "value": "Trajeng"
  },
  {
    "id": "3575041004",
    "district_id": "357504",
    "label": "Bangilan",
    "value": "Bangilan"
  },
  {
    "id": "3575041005",
    "district_id": "357504",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3575041006",
    "district_id": "357504",
    "label": "Mayangan",
    "value": "Mayangan"
  },
  {
    "id": "3575041007",
    "district_id": "357504",
    "label": "Ngemplakrejo",
    "value": "Ngemplakrejo"
  },
  {
    "id": "3575041008",
    "district_id": "357504",
    "label": "Petamanan",
    "value": "Petamanan"
  },
  {
    "id": "3575041009",
    "district_id": "357504",
    "label": "Pekuncen",
    "value": "Pekuncen"
  },
  {
    "id": "3575041010",
    "district_id": "357504",
    "label": "Bugul Lor",
    "value": "Bugul Lor"
  },
  {
    "id": "3575041011",
    "district_id": "357504",
    "label": "Kandangsapi",
    "value": "Kandangsapi"
  },
  {
    "id": "3575041012",
    "district_id": "357504",
    "label": "Panggungrejo",
    "value": "Panggungrejo"
  },
  {
    "id": "3575041013",
    "district_id": "357504",
    "label": "Mandaranrejo",
    "value": "Mandaranrejo"
  },
  {
    "id": "3576011003",
    "district_id": "357601",
    "label": "Mentikan",
    "value": "Mentikan"
  },
  {
    "id": "3576011004",
    "district_id": "357601",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "3576011005",
    "district_id": "357601",
    "label": "Pulorejo",
    "value": "Pulorejo"
  },
  {
    "id": "3576011006",
    "district_id": "357601",
    "label": "Prajuritkulon",
    "value": "Prajuritkulon"
  },
  {
    "id": "3576011007",
    "district_id": "357601",
    "label": "Surodinawan",
    "value": "Surodinawan"
  },
  {
    "id": "3576011008",
    "district_id": "357601",
    "label": "Blooto",
    "value": "Blooto"
  },
  {
    "id": "3576021001",
    "district_id": "357602",
    "label": "Gununggedangan",
    "value": "Gununggedangan"
  },
  {
    "id": "3576021003",
    "district_id": "357602",
    "label": "Magersari",
    "value": "Magersari"
  },
  {
    "id": "3576021004",
    "district_id": "357602",
    "label": "Gedongan",
    "value": "Gedongan"
  },
  {
    "id": "3576021008",
    "district_id": "357602",
    "label": "Balongsari",
    "value": "Balongsari"
  },
  {
    "id": "3576021009",
    "district_id": "357602",
    "label": "Kedundung",
    "value": "Kedundung"
  },
  {
    "id": "3576021010",
    "district_id": "357602",
    "label": "Wates",
    "value": "Wates"
  },
  {
    "id": "3576031001",
    "district_id": "357603",
    "label": "Kranggan",
    "value": "Kranggan"
  },
  {
    "id": "3576031002",
    "district_id": "357603",
    "label": "Miji",
    "value": "Miji"
  },
  {
    "id": "3576031003",
    "district_id": "357603",
    "label": "Meri",
    "value": "Meri"
  },
  {
    "id": "3576031004",
    "district_id": "357603",
    "label": "Jagalan",
    "value": "Jagalan"
  },
  {
    "id": "3576031005",
    "district_id": "357603",
    "label": "Sentanan",
    "value": "Sentanan"
  },
  {
    "id": "3576031006",
    "district_id": "357603",
    "label": "Purwotengah",
    "value": "Purwotengah"
  },
  {
    "id": "3577011001",
    "district_id": "357701",
    "label": "Oro-oro Ombo",
    "value": "Oro-oro Ombo"
  },
  {
    "id": "3577011002",
    "district_id": "357701",
    "label": "Sukosari",
    "value": "Sukosari"
  },
  {
    "id": "3577011003",
    "district_id": "357701",
    "label": "Klegen",
    "value": "Klegen"
  },
  {
    "id": "3577011004",
    "district_id": "357701",
    "label": "Rejomulyo",
    "value": "Rejomulyo"
  },
  {
    "id": "3577011005",
    "district_id": "357701",
    "label": "Pilangbango",
    "value": "Pilangbango"
  },
  {
    "id": "3577011006",
    "district_id": "357701",
    "label": "Tawangrejo",
    "value": "Tawangrejo"
  },
  {
    "id": "3577011007",
    "district_id": "357701",
    "label": "Kanigoro",
    "value": "Kanigoro"
  },
  {
    "id": "3577011008",
    "district_id": "357701",
    "label": "Kartoharjo",
    "value": "Kartoharjo"
  },
  {
    "id": "3577011009",
    "district_id": "357701",
    "label": "Kelun",
    "value": "Kelun"
  },
  {
    "id": "3577021001",
    "district_id": "357702",
    "label": "Manguharjo",
    "value": "Manguharjo"
  },
  {
    "id": "3577021002",
    "district_id": "357702",
    "label": "Sogaten",
    "value": "Sogaten"
  },
  {
    "id": "3577021003",
    "district_id": "357702",
    "label": "Patihan",
    "value": "Patihan"
  },
  {
    "id": "3577021004",
    "district_id": "357702",
    "label": "Ngegong",
    "value": "Ngegong"
  },
  {
    "id": "3577021005",
    "district_id": "357702",
    "label": "Winongo",
    "value": "Winongo"
  },
  {
    "id": "3577021006",
    "district_id": "357702",
    "label": "Madiun Lor",
    "value": "Madiun Lor"
  },
  {
    "id": "3577021007",
    "district_id": "357702",
    "label": "Pangongangan",
    "value": "Pangongangan"
  },
  {
    "id": "3577021008",
    "district_id": "357702",
    "label": "Nambangan Lor",
    "value": "Nambangan Lor"
  },
  {
    "id": "3577021009",
    "district_id": "357702",
    "label": "Nambangan Kidul",
    "value": "Nambangan Kidul"
  },
  {
    "id": "3577031001",
    "district_id": "357703",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3577031002",
    "district_id": "357703",
    "label": "Pandean",
    "value": "Pandean"
  },
  {
    "id": "3577031003",
    "district_id": "357703",
    "label": "Banjarejo",
    "value": "Banjarejo"
  },
  {
    "id": "3577031004",
    "district_id": "357703",
    "label": "Kuncen",
    "value": "Kuncen"
  },
  {
    "id": "3577031005",
    "district_id": "357703",
    "label": "Manisrejo",
    "value": "Manisrejo"
  },
  {
    "id": "3577031006",
    "district_id": "357703",
    "label": "Kejuron",
    "value": "Kejuron"
  },
  {
    "id": "3577031007",
    "district_id": "357703",
    "label": "Josenan",
    "value": "Josenan"
  },
  {
    "id": "3577031008",
    "district_id": "357703",
    "label": "Demangan",
    "value": "Demangan"
  },
  {
    "id": "3577031009",
    "district_id": "357703",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "3578011001",
    "district_id": "357801",
    "label": "Karang Pilang",
    "value": "Karang Pilang"
  },
  {
    "id": "3578011002",
    "district_id": "357801",
    "label": "Kebraon",
    "value": "Kebraon"
  },
  {
    "id": "3578011003",
    "district_id": "357801",
    "label": "Kedurus",
    "value": "Kedurus"
  },
  {
    "id": "3578011004",
    "district_id": "357801",
    "label": "Waru Gunung",
    "value": "Waru Gunung"
  },
  {
    "id": "3578021001",
    "district_id": "357802",
    "label": "Sidosermo",
    "value": "Sidosermo"
  },
  {
    "id": "3578021002",
    "district_id": "357802",
    "label": "Bendul Merisi",
    "value": "Bendul Merisi"
  },
  {
    "id": "3578021003",
    "district_id": "357802",
    "label": "Margorejo",
    "value": "Margorejo"
  },
  {
    "id": "3578021004",
    "district_id": "357802",
    "label": "Jemur Wonosari",
    "value": "Jemur Wonosari"
  },
  {
    "id": "3578021005",
    "district_id": "357802",
    "label": "Siwalankerto",
    "value": "Siwalankerto"
  },
  {
    "id": "3578031001",
    "district_id": "357803",
    "label": "Kalirungkut",
    "value": "Kalirungkut"
  },
  {
    "id": "3578031002",
    "district_id": "357803",
    "label": "Rungkut Kidul",
    "value": "Rungkut Kidul"
  },
  {
    "id": "3578031003",
    "district_id": "357803",
    "label": "Kedung Baruk",
    "value": "Kedung Baruk"
  },
  {
    "id": "3578031004",
    "district_id": "357803",
    "label": "Penjaringansari",
    "value": "Penjaringansari"
  },
  {
    "id": "3578031005",
    "district_id": "357803",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3578031006",
    "district_id": "357803",
    "label": "Medokan Ayu",
    "value": "Medokan Ayu"
  },
  {
    "id": "3578041001",
    "district_id": "357804",
    "label": "Wonokromo",
    "value": "Wonokromo"
  },
  {
    "id": "3578041002",
    "district_id": "357804",
    "label": "Jagir",
    "value": "Jagir"
  },
  {
    "id": "3578041003",
    "district_id": "357804",
    "label": "Ngagel",
    "value": "Ngagel"
  },
  {
    "id": "3578041004",
    "district_id": "357804",
    "label": "Ngagel Rejo",
    "value": "Ngagel Rejo"
  },
  {
    "id": "3578041005",
    "district_id": "357804",
    "label": "Darmo",
    "value": "Darmo"
  },
  {
    "id": "3578041006",
    "district_id": "357804",
    "label": "Sawunggaling",
    "value": "Sawunggaling"
  },
  {
    "id": "3578051001",
    "district_id": "357805",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3578051002",
    "district_id": "357805",
    "label": "Dr. Soetomo",
    "value": "Dr. Soetomo"
  },
  {
    "id": "3578051003",
    "district_id": "357805",
    "label": "Kedungdoro",
    "value": "Kedungdoro"
  },
  {
    "id": "3578051004",
    "district_id": "357805",
    "label": "Keputran",
    "value": "Keputran"
  },
  {
    "id": "3578051005",
    "district_id": "357805",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "3578061001",
    "district_id": "357806",
    "label": "Petemon",
    "value": "Petemon"
  },
  {
    "id": "3578061002",
    "district_id": "357806",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "3578061003",
    "district_id": "357806",
    "label": "Banyu Urip",
    "value": "Banyu Urip"
  },
  {
    "id": "3578061004",
    "district_id": "357806",
    "label": "Putat Jaya",
    "value": "Putat Jaya"
  },
  {
    "id": "3578061005",
    "district_id": "357806",
    "label": "Kupang Krajan",
    "value": "Kupang Krajan"
  },
  {
    "id": "3578061006",
    "district_id": "357806",
    "label": "Pakis",
    "value": "Pakis"
  },
  {
    "id": "3578071001",
    "district_id": "357807",
    "label": "Embong Kaliasin",
    "value": "Embong Kaliasin"
  },
  {
    "id": "3578071002",
    "district_id": "357807",
    "label": "Genteng",
    "value": "Genteng"
  },
  {
    "id": "3578071003",
    "district_id": "357807",
    "label": "Kapasari",
    "value": "Kapasari"
  },
  {
    "id": "3578071004",
    "district_id": "357807",
    "label": "Ketabang",
    "value": "Ketabang"
  },
  {
    "id": "3578071005",
    "district_id": "357807",
    "label": "Peneleh",
    "value": "Peneleh"
  },
  {
    "id": "3578081001",
    "district_id": "357808",
    "label": "Gubeng",
    "value": "Gubeng"
  },
  {
    "id": "3578081002",
    "district_id": "357808",
    "label": "Mojo",
    "value": "Mojo"
  },
  {
    "id": "3578081003",
    "district_id": "357808",
    "label": "Airlangga",
    "value": "Airlangga"
  },
  {
    "id": "3578081004",
    "district_id": "357808",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3578081005",
    "district_id": "357808",
    "label": "Baratajaya",
    "value": "Baratajaya"
  },
  {
    "id": "3578081006",
    "district_id": "357808",
    "label": "Pucang Sewu",
    "value": "Pucang Sewu"
  },
  {
    "id": "3578091001",
    "district_id": "357809",
    "label": "Keputih",
    "value": "Keputih"
  },
  {
    "id": "3578091002",
    "district_id": "357809",
    "label": "Gebang Putih",
    "value": "Gebang Putih"
  },
  {
    "id": "3578091003",
    "district_id": "357809",
    "label": "Klampis Ngasem",
    "value": "Klampis Ngasem"
  },
  {
    "id": "3578091004",
    "district_id": "357809",
    "label": "Menur Pumpungan",
    "value": "Menur Pumpungan"
  },
  {
    "id": "3578091005",
    "district_id": "357809",
    "label": "Nginden Jangkungan",
    "value": "Nginden Jangkungan"
  },
  {
    "id": "3578091006",
    "district_id": "357809",
    "label": "Semolowaru",
    "value": "Semolowaru"
  },
  {
    "id": "3578091007",
    "district_id": "357809",
    "label": "Medokan Semampir",
    "value": "Medokan Semampir"
  },
  {
    "id": "3578101001",
    "district_id": "357810",
    "label": "Tambaksari",
    "value": "Tambaksari"
  },
  {
    "id": "3578101002",
    "district_id": "357810",
    "label": "Ploso",
    "value": "Ploso"
  },
  {
    "id": "3578101003",
    "district_id": "357810",
    "label": "Gading",
    "value": "Gading"
  },
  {
    "id": "3578101004",
    "district_id": "357810",
    "label": "Pacarkembang",
    "value": "Pacarkembang"
  },
  {
    "id": "3578101005",
    "district_id": "357810",
    "label": "Rangkah",
    "value": "Rangkah"
  },
  {
    "id": "3578101006",
    "district_id": "357810",
    "label": "Pacarkeling",
    "value": "Pacarkeling"
  },
  {
    "id": "3578101007",
    "district_id": "357810",
    "label": "Kapasmadya Baru",
    "value": "Kapasmadya Baru"
  },
  {
    "id": "3578101008",
    "district_id": "357810",
    "label": "Dukuh Setro",
    "value": "Dukuh Setro"
  },
  {
    "id": "3578111001",
    "district_id": "357811",
    "label": "Simokerto",
    "value": "Simokerto"
  },
  {
    "id": "3578111002",
    "district_id": "357811",
    "label": "Kapasan",
    "value": "Kapasan"
  },
  {
    "id": "3578111003",
    "district_id": "357811",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "3578111004",
    "district_id": "357811",
    "label": "Simolawang",
    "value": "Simolawang"
  },
  {
    "id": "3578111005",
    "district_id": "357811",
    "label": "Tambakrejo",
    "value": "Tambakrejo"
  },
  {
    "id": "3578121001",
    "district_id": "357812",
    "label": "Bongkaran",
    "value": "Bongkaran"
  },
  {
    "id": "3578121002",
    "district_id": "357812",
    "label": "Nyamplungan",
    "value": "Nyamplungan"
  },
  {
    "id": "3578121003",
    "district_id": "357812",
    "label": "Krembangan Utara",
    "value": "Krembangan Utara"
  },
  {
    "id": "3578121004",
    "district_id": "357812",
    "label": "Perak Timur",
    "value": "Perak Timur"
  },
  {
    "id": "3578121005",
    "district_id": "357812",
    "label": "Perak Utara",
    "value": "Perak Utara"
  },
  {
    "id": "3578131001",
    "district_id": "357813",
    "label": "Alun-alun Contong",
    "value": "Alun-alun Contong"
  },
  {
    "id": "3578131002",
    "district_id": "357813",
    "label": "Bubutan",
    "value": "Bubutan"
  },
  {
    "id": "3578131003",
    "district_id": "357813",
    "label": "Gundih",
    "value": "Gundih"
  },
  {
    "id": "3578131004",
    "district_id": "357813",
    "label": "Jepara",
    "value": "Jepara"
  },
  {
    "id": "3578131005",
    "district_id": "357813",
    "label": "Tembok Dukuh",
    "value": "Tembok Dukuh"
  },
  {
    "id": "3578141002",
    "district_id": "357814",
    "label": "Tandes",
    "value": "Tandes"
  },
  {
    "id": "3578141006",
    "district_id": "357814",
    "label": "Karang Poh",
    "value": "Karang Poh"
  },
  {
    "id": "3578141007",
    "district_id": "357814",
    "label": "Balongsari",
    "value": "Balongsari"
  },
  {
    "id": "3578141009",
    "district_id": "357814",
    "label": "Manukan Kulon",
    "value": "Manukan Kulon"
  },
  {
    "id": "3578141011",
    "district_id": "357814",
    "label": "Manukan Wetan",
    "value": "Manukan Wetan"
  },
  {
    "id": "3578141012",
    "district_id": "357814",
    "label": "Banjar Sugihan",
    "value": "Banjar Sugihan"
  },
  {
    "id": "3578151001",
    "district_id": "357815",
    "label": "Krembangan Selatan",
    "value": "Krembangan Selatan"
  },
  {
    "id": "3578151002",
    "district_id": "357815",
    "label": "Kemayoran",
    "value": "Kemayoran"
  },
  {
    "id": "3578151003",
    "district_id": "357815",
    "label": "Perak Barat",
    "value": "Perak Barat"
  },
  {
    "id": "3578151004",
    "district_id": "357815",
    "label": "Dupak",
    "value": "Dupak"
  },
  {
    "id": "3578151005",
    "district_id": "357815",
    "label": "Morokrembangan",
    "value": "Morokrembangan"
  },
  {
    "id": "3578161001",
    "district_id": "357816",
    "label": "Ampel",
    "value": "Ampel"
  },
  {
    "id": "3578161002",
    "district_id": "357816",
    "label": "Pegirian",
    "value": "Pegirian"
  },
  {
    "id": "3578161003",
    "district_id": "357816",
    "label": "Wonokusumo",
    "value": "Wonokusumo"
  },
  {
    "id": "3578161004",
    "district_id": "357816",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "3578161005",
    "district_id": "357816",
    "label": "Sidotopo",
    "value": "Sidotopo"
  },
  {
    "id": "3578171001",
    "district_id": "357817",
    "label": "Tanah Kali Kedinding",
    "value": "Tanah Kali Kedinding"
  },
  {
    "id": "3578171002",
    "district_id": "357817",
    "label": "Sidotopo Wetan",
    "value": "Sidotopo Wetan"
  },
  {
    "id": "3578171003",
    "district_id": "357817",
    "label": "Bulak Banteng",
    "value": "Bulak Banteng"
  },
  {
    "id": "3578171004",
    "district_id": "357817",
    "label": "Tambak Wedi",
    "value": "Tambak Wedi"
  },
  {
    "id": "3578181001",
    "district_id": "357818",
    "label": "Bangkingan",
    "value": "Bangkingan"
  },
  {
    "id": "3578181002",
    "district_id": "357818",
    "label": "Jeruk",
    "value": "Jeruk"
  },
  {
    "id": "3578181003",
    "district_id": "357818",
    "label": "Lakarsantri",
    "value": "Lakarsantri"
  },
  {
    "id": "3578181004",
    "district_id": "357818",
    "label": "Lidah kulon",
    "value": "Lidah kulon"
  },
  {
    "id": "3578181005",
    "district_id": "357818",
    "label": "Lidah Wetan",
    "value": "Lidah Wetan"
  },
  {
    "id": "3578181006",
    "district_id": "357818",
    "label": "Sumurwelut",
    "value": "Sumurwelut"
  },
  {
    "id": "3578191001",
    "district_id": "357819",
    "label": "Kandangan",
    "value": "Kandangan"
  },
  {
    "id": "3578191003",
    "district_id": "357819",
    "label": "Sememi",
    "value": "Sememi"
  },
  {
    "id": "3578191004",
    "district_id": "357819",
    "label": "Tambakoso Wilangun",
    "value": "Tambakoso Wilangun"
  },
  {
    "id": "3578191005",
    "district_id": "357819",
    "label": "Romokalisari",
    "value": "Romokalisari"
  },
  {
    "id": "3578201001",
    "district_id": "357820",
    "label": "Wiyung",
    "value": "Wiyung"
  },
  {
    "id": "3578201002",
    "district_id": "357820",
    "label": "Jajar Tunggal",
    "value": "Jajar Tunggal"
  },
  {
    "id": "3578201003",
    "district_id": "357820",
    "label": "Babatan",
    "value": "Babatan"
  },
  {
    "id": "3578201004",
    "district_id": "357820",
    "label": "Balas Klumprik",
    "value": "Balas Klumprik"
  },
  {
    "id": "3578211001",
    "district_id": "357821",
    "label": "Dukuh Pakis",
    "value": "Dukuh Pakis"
  },
  {
    "id": "3578211002",
    "district_id": "357821",
    "label": "Dukuh Kupang",
    "value": "Dukuh Kupang"
  },
  {
    "id": "3578211003",
    "district_id": "357821",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "3578211004",
    "district_id": "357821",
    "label": "Pradah Kalikendal",
    "value": "Pradah Kalikendal"
  },
  {
    "id": "3578221001",
    "district_id": "357822",
    "label": "Gayungan",
    "value": "Gayungan"
  },
  {
    "id": "3578221002",
    "district_id": "357822",
    "label": "Menanggal",
    "value": "Menanggal"
  },
  {
    "id": "3578221003",
    "district_id": "357822",
    "label": "Dukuh Menanggal",
    "value": "Dukuh Menanggal"
  },
  {
    "id": "3578221004",
    "district_id": "357822",
    "label": "Ketintang",
    "value": "Ketintang"
  },
  {
    "id": "3578231001",
    "district_id": "357823",
    "label": "Jambangan",
    "value": "Jambangan"
  },
  {
    "id": "3578231002",
    "district_id": "357823",
    "label": "Karah",
    "value": "Karah"
  },
  {
    "id": "3578231003",
    "district_id": "357823",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3578231004",
    "district_id": "357823",
    "label": "Pagesangan",
    "value": "Pagesangan"
  },
  {
    "id": "3578241001",
    "district_id": "357824",
    "label": "Kutisari",
    "value": "Kutisari"
  },
  {
    "id": "3578241002",
    "district_id": "357824",
    "label": "Kendangsari",
    "value": "Kendangsari"
  },
  {
    "id": "3578241003",
    "district_id": "357824",
    "label": "Tengilis Mejoyo",
    "value": "Tengilis Mejoyo"
  },
  {
    "id": "3578241004",
    "district_id": "357824",
    "label": "Panjang Jiwo",
    "value": "Panjang Jiwo"
  },
  {
    "id": "3578251001",
    "district_id": "357825",
    "label": "Gunung Anyar",
    "value": "Gunung Anyar"
  },
  {
    "id": "3578251002",
    "district_id": "357825",
    "label": "Rungkut Tengah",
    "value": "Rungkut Tengah"
  },
  {
    "id": "3578251003",
    "district_id": "357825",
    "label": "Rungkut Menanggal",
    "value": "Rungkut Menanggal"
  },
  {
    "id": "3578251004",
    "district_id": "357825",
    "label": "Gunung Anyar",
    "value": "Gunung Anyar"
  },
  {
    "id": "3578261001",
    "district_id": "357826",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "3578261002",
    "district_id": "357826",
    "label": "Manyar Sabrangan",
    "value": "Manyar Sabrangan"
  },
  {
    "id": "3578261003",
    "district_id": "357826",
    "label": "Kejawaan Putih",
    "value": "Kejawaan Putih"
  },
  {
    "id": "3578261004",
    "district_id": "357826",
    "label": "Kalisari",
    "value": "Kalisari"
  },
  {
    "id": "3578261005",
    "district_id": "357826",
    "label": "Dukuh Sutorejo",
    "value": "Dukuh Sutorejo"
  },
  {
    "id": "3578261006",
    "district_id": "357826",
    "label": "Kalijudan",
    "value": "Kalijudan"
  },
  {
    "id": "3578271001",
    "district_id": "357827",
    "label": "Sukomanunggal",
    "value": "Sukomanunggal"
  },
  {
    "id": "3578271002",
    "district_id": "357827",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3578271003",
    "district_id": "357827",
    "label": "Sonokwijenan",
    "value": "Sonokwijenan"
  },
  {
    "id": "3578271004",
    "district_id": "357827",
    "label": "Putat Gede",
    "value": "Putat Gede"
  },
  {
    "id": "3578271005",
    "district_id": "357827",
    "label": "Simomulyo",
    "value": "Simomulyo"
  },
  {
    "id": "3578271006",
    "district_id": "357827",
    "label": "Simomulyo Baru",
    "value": "Simomulyo Baru"
  },
  {
    "id": "3578281001",
    "district_id": "357828",
    "label": "Asem Rowo",
    "value": "Asem Rowo"
  },
  {
    "id": "3578281002",
    "district_id": "357828",
    "label": "Genting Kalianak",
    "value": "Genting Kalianak"
  },
  {
    "id": "3578281005",
    "district_id": "357828",
    "label": "Tambak Sarioso",
    "value": "Tambak Sarioso"
  },
  {
    "id": "3578291001",
    "district_id": "357829",
    "label": "Kedung Cowek",
    "value": "Kedung Cowek"
  },
  {
    "id": "3578291003",
    "district_id": "357829",
    "label": "Kenjeran",
    "value": "Kenjeran"
  },
  {
    "id": "3578291004",
    "district_id": "357829",
    "label": "Bulak",
    "value": "Bulak"
  },
  {
    "id": "3578291005",
    "district_id": "357829",
    "label": "Sukolilo Baru",
    "value": "Sukolilo Baru"
  },
  {
    "id": "3578301001",
    "district_id": "357830",
    "label": "Pakal",
    "value": "Pakal"
  },
  {
    "id": "3578301002",
    "district_id": "357830",
    "label": "Babat Jerawat",
    "value": "Babat Jerawat"
  },
  {
    "id": "3578301004",
    "district_id": "357830",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "3578301005",
    "district_id": "357830",
    "label": "Benowo",
    "value": "Benowo"
  },
  {
    "id": "3578311001",
    "district_id": "357831",
    "label": "Sambikerep",
    "value": "Sambikerep"
  },
  {
    "id": "3578311002",
    "district_id": "357831",
    "label": "Made",
    "value": "Made"
  },
  {
    "id": "3578311003",
    "district_id": "357831",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "3578311004",
    "district_id": "357831",
    "label": "Lontar",
    "value": "Lontar"
  },
  {
    "id": "3579011001",
    "district_id": "357901",
    "label": "Temas",
    "value": "Temas"
  },
  {
    "id": "3579011002",
    "district_id": "357901",
    "label": "Ngaglik",
    "value": "Ngaglik"
  },
  {
    "id": "3579011003",
    "district_id": "357901",
    "label": "Songgokerto",
    "value": "Songgokerto"
  },
  {
    "id": "3579011004",
    "district_id": "357901",
    "label": "Sisir",
    "value": "Sisir"
  },
  {
    "id": "3579012005",
    "district_id": "357901",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "3579012006",
    "district_id": "357901",
    "label": "Oro-oro Ombo",
    "value": "Oro-oro Ombo"
  },
  {
    "id": "3579012007",
    "district_id": "357901",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "3579012008",
    "district_id": "357901",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "3579022001",
    "district_id": "357902",
    "label": "Punten",
    "value": "Punten"
  },
  {
    "id": "3579022002",
    "district_id": "357902",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3579022003",
    "district_id": "357902",
    "label": "Tulungrejo",
    "value": "Tulungrejo"
  },
  {
    "id": "3579022004",
    "district_id": "357902",
    "label": "Sumbergondo",
    "value": "Sumbergondo"
  },
  {
    "id": "3579022005",
    "district_id": "357902",
    "label": "Pandanrejo",
    "value": "Pandanrejo"
  },
  {
    "id": "3579022006",
    "district_id": "357902",
    "label": "Bumiaji",
    "value": "Bumiaji"
  },
  {
    "id": "3579022007",
    "district_id": "357902",
    "label": "Giripurno",
    "value": "Giripurno"
  },
  {
    "id": "3579022008",
    "district_id": "357902",
    "label": "Bulukerto",
    "value": "Bulukerto"
  },
  {
    "id": "3579022009",
    "district_id": "357902",
    "label": "Sumberbrantas",
    "value": "Sumberbrantas"
  },
  {
    "id": "3579031001",
    "district_id": "357903",
    "label": "Dadaprejo",
    "value": "Dadaprejo"
  },
  {
    "id": "3579032002",
    "district_id": "357903",
    "label": "Beji",
    "value": "Beji"
  },
  {
    "id": "3579032003",
    "district_id": "357903",
    "label": "Junrejo",
    "value": "Junrejo"
  },
  {
    "id": "3579032004",
    "district_id": "357903",
    "label": "Tlekung",
    "value": "Tlekung"
  },
  {
    "id": "3579032005",
    "district_id": "357903",
    "label": "Mojorejo",
    "value": "Mojorejo"
  },
  {
    "id": "3579032006",
    "district_id": "357903",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "3579032007",
    "district_id": "357903",
    "label": "Torongrejo",
    "value": "Torongrejo"
  },
  {
    "id": "3601012001",
    "district_id": "360101",
    "label": "Sumberjaya",
    "value": "Sumberjaya"
  },
  {
    "id": "3601012002",
    "district_id": "360101",
    "label": "Kertajaya",
    "value": "Kertajaya"
  },
  {
    "id": "3601012003",
    "district_id": "360101",
    "label": "Kertamukti",
    "value": "Kertamukti"
  },
  {
    "id": "3601012004",
    "district_id": "360101",
    "label": "Tunggaljaya",
    "value": "Tunggaljaya"
  },
  {
    "id": "3601012005",
    "district_id": "360101",
    "label": "Cigorondong",
    "value": "Cigorondong"
  },
  {
    "id": "3601012006",
    "district_id": "360101",
    "label": "Tamanjaya",
    "value": "Tamanjaya"
  },
  {
    "id": "3601012007",
    "district_id": "360101",
    "label": "Ujungjaya",
    "value": "Ujungjaya"
  },
  {
    "id": "3601022001",
    "district_id": "360102",
    "label": "Rancapinang",
    "value": "Rancapinang"
  },
  {
    "id": "3601022002",
    "district_id": "360102",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3601022003",
    "district_id": "360102",
    "label": "Tugu",
    "value": "Tugu"
  },
  {
    "id": "3601022004",
    "district_id": "360102",
    "label": "Batuhideung",
    "value": "Batuhideung"
  },
  {
    "id": "3601022005",
    "district_id": "360102",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3601022006",
    "district_id": "360102",
    "label": "Ciburial",
    "value": "Ciburial"
  },
  {
    "id": "3601022007",
    "district_id": "360102",
    "label": "Cijaralang",
    "value": "Cijaralang"
  },
  {
    "id": "3601022008",
    "district_id": "360102",
    "label": "Cimanggu",
    "value": "Cimanggu"
  },
  {
    "id": "3601022009",
    "district_id": "360102",
    "label": "Mangkualam",
    "value": "Mangkualam"
  },
  {
    "id": "3601022010",
    "district_id": "360102",
    "label": "Tangkil Sari",
    "value": "Tangkil Sari"
  },
  {
    "id": "3601022011",
    "district_id": "360102",
    "label": "Waringin Kurung",
    "value": "Waringin Kurung"
  },
  {
    "id": "3601022012",
    "district_id": "360102",
    "label": "Kramatjaya",
    "value": "Kramatjaya"
  },
  {
    "id": "3601032001",
    "district_id": "360103",
    "label": "Mendung",
    "value": "Mendung"
  },
  {
    "id": "3601032006",
    "district_id": "360103",
    "label": "Sudimanik",
    "value": "Sudimanik"
  },
  {
    "id": "3601032007",
    "district_id": "360103",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3601032008",
    "district_id": "360103",
    "label": "Cibingbin",
    "value": "Cibingbin"
  },
  {
    "id": "3601032009",
    "district_id": "360103",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3601032011",
    "district_id": "360103",
    "label": "Mahendra",
    "value": "Mahendra"
  },
  {
    "id": "3601032012",
    "district_id": "360103",
    "label": "Cibaliung",
    "value": "Cibaliung"
  },
  {
    "id": "3601032015",
    "district_id": "360103",
    "label": "Cihanjuang",
    "value": "Cihanjuang"
  },
  {
    "id": "3601032016",
    "district_id": "360103",
    "label": "Sorongan",
    "value": "Sorongan"
  },
  {
    "id": "3601042001",
    "district_id": "360104",
    "label": "Tanjungan",
    "value": "Tanjungan"
  },
  {
    "id": "3601042002",
    "district_id": "360104",
    "label": "Sumurbatu",
    "value": "Sumurbatu"
  },
  {
    "id": "3601042003",
    "district_id": "360104",
    "label": "Sukawaris",
    "value": "Sukawaris"
  },
  {
    "id": "3601042004",
    "district_id": "360104",
    "label": "Rancaseneng",
    "value": "Rancaseneng"
  },
  {
    "id": "3601042005",
    "district_id": "360104",
    "label": "Parungkokosan",
    "value": "Parungkokosan"
  },
  {
    "id": "3601042006",
    "district_id": "360104",
    "label": "Nanggala",
    "value": "Nanggala"
  },
  {
    "id": "3601042007",
    "district_id": "360104",
    "label": "Cikeusik",
    "value": "Cikeusik"
  },
  {
    "id": "3601042008",
    "district_id": "360104",
    "label": "Cikadongdong",
    "value": "Cikadongdong"
  },
  {
    "id": "3601042009",
    "district_id": "360104",
    "label": "Curugciung",
    "value": "Curugciung"
  },
  {
    "id": "3601042010",
    "district_id": "360104",
    "label": "Leuwibalang",
    "value": "Leuwibalang"
  },
  {
    "id": "3601042011",
    "district_id": "360104",
    "label": "Sukaseneng",
    "value": "Sukaseneng"
  },
  {
    "id": "3601042012",
    "district_id": "360104",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3601042013",
    "district_id": "360104",
    "label": "Umbulan",
    "value": "Umbulan"
  },
  {
    "id": "3601042014",
    "district_id": "360104",
    "label": "Cikiruhwetan",
    "value": "Cikiruhwetan"
  },
  {
    "id": "3601052001",
    "district_id": "360105",
    "label": "Karangbolong",
    "value": "Karangbolong"
  },
  {
    "id": "3601052002",
    "district_id": "360105",
    "label": "Katumbiri",
    "value": "Katumbiri"
  },
  {
    "id": "3601052003",
    "district_id": "360105",
    "label": "Waringinjaya",
    "value": "Waringinjaya"
  },
  {
    "id": "3601052004",
    "district_id": "360105",
    "label": "Karyabuana",
    "value": "Karyabuana"
  },
  {
    "id": "3601052005",
    "district_id": "360105",
    "label": "Tarumanagara",
    "value": "Tarumanagara"
  },
  {
    "id": "3601052006",
    "district_id": "360105",
    "label": "Ciseureuheun",
    "value": "Ciseureuheun"
  },
  {
    "id": "3601052007",
    "district_id": "360105",
    "label": "Banyuasih",
    "value": "Banyuasih"
  },
  {
    "id": "3601052009",
    "district_id": "360105",
    "label": "Cigeulis",
    "value": "Cigeulis"
  },
  {
    "id": "3601052010",
    "district_id": "360105",
    "label": "Sinarjaya",
    "value": "Sinarjaya"
  },
  {
    "id": "3601062001",
    "district_id": "360106",
    "label": "Panimbangjaya",
    "value": "Panimbangjaya"
  },
  {
    "id": "3601062002",
    "district_id": "360106",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3601062004",
    "district_id": "360106",
    "label": "Gombong",
    "value": "Gombong"
  },
  {
    "id": "3601062009",
    "district_id": "360106",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "3601062011",
    "district_id": "360106",
    "label": "Citeureup",
    "value": "Citeureup"
  },
  {
    "id": "3601062012",
    "district_id": "360106",
    "label": "Tanjungjaya",
    "value": "Tanjungjaya"
  },
  {
    "id": "3601072001",
    "district_id": "360107",
    "label": "Angsana",
    "value": "Angsana"
  },
  {
    "id": "3601072002",
    "district_id": "360107",
    "label": "Padaherang",
    "value": "Padaherang"
  },
  {
    "id": "3601072003",
    "district_id": "360107",
    "label": "Padamulya",
    "value": "Padamulya"
  },
  {
    "id": "3601072004",
    "district_id": "360107",
    "label": "Karangsari",
    "value": "Karangsari"
  },
  {
    "id": "3601072005",
    "district_id": "360107",
    "label": "Cikayas",
    "value": "Cikayas"
  },
  {
    "id": "3601072006",
    "district_id": "360107",
    "label": "Sumurlaban",
    "value": "Sumurlaban"
  },
  {
    "id": "3601072007",
    "district_id": "360107",
    "label": "Kadubadak",
    "value": "Kadubadak"
  },
  {
    "id": "3601072009",
    "district_id": "360107",
    "label": "Cipinang",
    "value": "Cipinang"
  },
  {
    "id": "3601072010",
    "district_id": "360107",
    "label": "Kramatmanik",
    "value": "Kramatmanik"
  },
  {
    "id": "3601082001",
    "district_id": "360108",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3601082002",
    "district_id": "360108",
    "label": "Sukasaba",
    "value": "Sukasaba"
  },
  {
    "id": "3601082005",
    "district_id": "360108",
    "label": "Gunungbatu",
    "value": "Gunungbatu"
  },
  {
    "id": "3601082006",
    "district_id": "360108",
    "label": "Panacaran",
    "value": "Panacaran"
  },
  {
    "id": "3601082007",
    "district_id": "360108",
    "label": "Curuglanglang",
    "value": "Curuglanglang"
  },
  {
    "id": "3601082008",
    "district_id": "360108",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3601082009",
    "district_id": "360108",
    "label": "Cibitung",
    "value": "Cibitung"
  },
  {
    "id": "3601082011",
    "district_id": "360108",
    "label": "Kotadukuh",
    "value": "Kotadukuh"
  },
  {
    "id": "3601082018",
    "district_id": "360108",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3601092001",
    "district_id": "360109",
    "label": "Sukadame",
    "value": "Sukadame"
  },
  {
    "id": "3601092002",
    "district_id": "360109",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3601092003",
    "district_id": "360109",
    "label": "Surakarta",
    "value": "Surakarta"
  },
  {
    "id": "3601092004",
    "district_id": "360109",
    "label": "Montor",
    "value": "Montor"
  },
  {
    "id": "3601092005",
    "district_id": "360109",
    "label": "Kertasana",
    "value": "Kertasana"
  },
  {
    "id": "3601092006",
    "district_id": "360109",
    "label": "Bama",
    "value": "Bama"
  },
  {
    "id": "3601092007",
    "district_id": "360109",
    "label": "Margasana",
    "value": "Margasana"
  },
  {
    "id": "3601092008",
    "district_id": "360109",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3601092009",
    "district_id": "360109",
    "label": "Tegalpapak",
    "value": "Tegalpapak"
  },
  {
    "id": "3601092010",
    "district_id": "360109",
    "label": "Bulagor",
    "value": "Bulagor"
  },
  {
    "id": "3601092011",
    "district_id": "360109",
    "label": "Harapankarya",
    "value": "Harapankarya"
  },
  {
    "id": "3601092012",
    "district_id": "360109",
    "label": "Senangsari",
    "value": "Senangsari"
  },
  {
    "id": "3601092013",
    "district_id": "360109",
    "label": "Margagiri",
    "value": "Margagiri"
  },
  {
    "id": "3601102001",
    "district_id": "360110",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3601102002",
    "district_id": "360110",
    "label": "Cijakan",
    "value": "Cijakan"
  },
  {
    "id": "3601102003",
    "district_id": "360110",
    "label": "Geredug",
    "value": "Geredug"
  },
  {
    "id": "3601102004",
    "district_id": "360110",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3601102005",
    "district_id": "360110",
    "label": "Banyumas",
    "value": "Banyumas"
  },
  {
    "id": "3601102006",
    "district_id": "360110",
    "label": "Citumenggung",
    "value": "Citumenggung"
  },
  {
    "id": "3601102007",
    "district_id": "360110",
    "label": "Manggungjaya",
    "value": "Manggungjaya"
  },
  {
    "id": "3601102008",
    "district_id": "360110",
    "label": "Cahayamekar",
    "value": "Cahayamekar"
  },
  {
    "id": "3601112001",
    "district_id": "360111",
    "label": "Pasirsedang",
    "value": "Pasirsedang"
  },
  {
    "id": "3601112002",
    "district_id": "360111",
    "label": "Cililitan",
    "value": "Cililitan"
  },
  {
    "id": "3601112003",
    "district_id": "360111",
    "label": "Kadupandak",
    "value": "Kadupandak"
  },
  {
    "id": "3601112004",
    "district_id": "360111",
    "label": "Kolelet",
    "value": "Kolelet"
  },
  {
    "id": "3601112005",
    "district_id": "360111",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3601112006",
    "district_id": "360111",
    "label": "Bungurcopong",
    "value": "Bungurcopong"
  },
  {
    "id": "3601112007",
    "district_id": "360111",
    "label": "Pasirpanjang",
    "value": "Pasirpanjang"
  },
  {
    "id": "3601112008",
    "district_id": "360111",
    "label": "Kadubera",
    "value": "Kadubera"
  },
  {
    "id": "3601112009",
    "district_id": "360111",
    "label": "Ganggaeng",
    "value": "Ganggaeng"
  },
  {
    "id": "3601122001",
    "district_id": "360112",
    "label": "Rancateureup",
    "value": "Rancateureup"
  },
  {
    "id": "3601122002",
    "district_id": "360112",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "3601122003",
    "district_id": "360112",
    "label": "Banyubiru",
    "value": "Banyubiru"
  },
  {
    "id": "3601122004",
    "district_id": "360112",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3601122010",
    "district_id": "360112",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "3601122012",
    "district_id": "360112",
    "label": "Cigondang",
    "value": "Cigondang"
  },
  {
    "id": "3601122013",
    "district_id": "360112",
    "label": "Kalanganyar",
    "value": "Kalanganyar"
  },
  {
    "id": "3601122014",
    "district_id": "360112",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3601122015",
    "district_id": "360112",
    "label": "Banyumekar",
    "value": "Banyumekar"
  },
  {
    "id": "3601132001",
    "district_id": "360113",
    "label": "Alaswangi",
    "value": "Alaswangi"
  },
  {
    "id": "3601132002",
    "district_id": "360113",
    "label": "Tegalwangi",
    "value": "Tegalwangi"
  },
  {
    "id": "3601132003",
    "district_id": "360113",
    "label": "Kananga",
    "value": "Kananga"
  },
  {
    "id": "3601132004",
    "district_id": "360113",
    "label": "Cigandeng",
    "value": "Cigandeng"
  },
  {
    "id": "3601132005",
    "district_id": "360113",
    "label": "Menes",
    "value": "Menes"
  },
  {
    "id": "3601132006",
    "district_id": "360113",
    "label": "Muruy",
    "value": "Muruy"
  },
  {
    "id": "3601132010",
    "district_id": "360113",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3601132011",
    "district_id": "360113",
    "label": "Purwaraja",
    "value": "Purwaraja"
  },
  {
    "id": "3601132012",
    "district_id": "360113",
    "label": "Sindangkarya",
    "value": "Sindangkarya"
  },
  {
    "id": "3601132013",
    "district_id": "360113",
    "label": "Cilabanbulan",
    "value": "Cilabanbulan"
  },
  {
    "id": "3601132015",
    "district_id": "360113",
    "label": "Kadupayung",
    "value": "Kadupayung"
  },
  {
    "id": "3601132016",
    "district_id": "360113",
    "label": "Ramaya",
    "value": "Ramaya"
  },
  {
    "id": "3601142001",
    "district_id": "360114",
    "label": "Langensari",
    "value": "Langensari"
  },
  {
    "id": "3601142002",
    "district_id": "360114",
    "label": "Medalsari",
    "value": "Medalsari"
  },
  {
    "id": "3601142003",
    "district_id": "360114",
    "label": "Majau",
    "value": "Majau"
  },
  {
    "id": "3601142004",
    "district_id": "360114",
    "label": "Sodong",
    "value": "Sodong"
  },
  {
    "id": "3601142005",
    "district_id": "360114",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3601142006",
    "district_id": "360114",
    "label": "Ciandur",
    "value": "Ciandur"
  },
  {
    "id": "3601142007",
    "district_id": "360114",
    "label": "Kadudampit",
    "value": "Kadudampit"
  },
  {
    "id": "3601142008",
    "district_id": "360114",
    "label": "Wanagiri",
    "value": "Wanagiri"
  },
  {
    "id": "3601142009",
    "district_id": "360114",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "3601142010",
    "district_id": "360114",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3601142011",
    "district_id": "360114",
    "label": "Saketi",
    "value": "Saketi"
  },
  {
    "id": "3601142012",
    "district_id": "360114",
    "label": "Girijaya",
    "value": "Girijaya"
  },
  {
    "id": "3601142013",
    "district_id": "360114",
    "label": "Sindanghayu",
    "value": "Sindanghayu"
  },
  {
    "id": "3601142014",
    "district_id": "360114",
    "label": "Sukalangu",
    "value": "Sukalangu"
  },
  {
    "id": "3601152001",
    "district_id": "360115",
    "label": "Palanyar",
    "value": "Palanyar"
  },
  {
    "id": "3601152002",
    "district_id": "360115",
    "label": "Baturanjang",
    "value": "Baturanjang"
  },
  {
    "id": "3601152003",
    "district_id": "360115",
    "label": "Cikadueun",
    "value": "Cikadueun"
  },
  {
    "id": "3601152004",
    "district_id": "360115",
    "label": "Koncang",
    "value": "Koncang"
  },
  {
    "id": "3601152005",
    "district_id": "360115",
    "label": "Kadugadung",
    "value": "Kadugadung"
  },
  {
    "id": "3601152006",
    "district_id": "360115",
    "label": "Parumasan",
    "value": "Parumasan"
  },
  {
    "id": "3601152007",
    "district_id": "360115",
    "label": "Curugbarang",
    "value": "Curugbarang"
  },
  {
    "id": "3601152008",
    "district_id": "360115",
    "label": "Kalanggunung",
    "value": "Kalanggunung"
  },
  {
    "id": "3601152009",
    "district_id": "360115",
    "label": "Pasireurih",
    "value": "Pasireurih"
  },
  {
    "id": "3601152010",
    "district_id": "360115",
    "label": "Pasirmae",
    "value": "Pasirmae"
  },
  {
    "id": "3601162001",
    "district_id": "360116",
    "label": "Pamarayan",
    "value": "Pamarayan"
  },
  {
    "id": "3601162002",
    "district_id": "360116",
    "label": "Jiput",
    "value": "Jiput"
  },
  {
    "id": "3601162003",
    "district_id": "360116",
    "label": "Sukacai",
    "value": "Sukacai"
  },
  {
    "id": "3601162004",
    "district_id": "360116",
    "label": "Tenjolahang",
    "value": "Tenjolahang"
  },
  {
    "id": "3601162005",
    "district_id": "360116",
    "label": "Janaka",
    "value": "Janaka"
  },
  {
    "id": "3601162006",
    "district_id": "360116",
    "label": "Sikulan",
    "value": "Sikulan"
  },
  {
    "id": "3601162007",
    "district_id": "360116",
    "label": "Citaman",
    "value": "Citaman"
  },
  {
    "id": "3601162008",
    "district_id": "360116",
    "label": "Jayamekar",
    "value": "Jayamekar"
  },
  {
    "id": "3601162011",
    "district_id": "360116",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3601162012",
    "district_id": "360116",
    "label": "Salapraya",
    "value": "Salapraya"
  },
  {
    "id": "3601162013",
    "district_id": "360116",
    "label": "Banyuresmi",
    "value": "Banyuresmi"
  },
  {
    "id": "3601162014",
    "district_id": "360116",
    "label": "Sampang Bitung",
    "value": "Sampang Bitung"
  },
  {
    "id": "3601162015",
    "district_id": "360116",
    "label": "Babadsari",
    "value": "Babadsari"
  },
  {
    "id": "3601172001",
    "district_id": "360117",
    "label": "Pandat",
    "value": "Pandat"
  },
  {
    "id": "3601172002",
    "district_id": "360117",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3601172003",
    "district_id": "360117",
    "label": "Nembol",
    "value": "Nembol"
  },
  {
    "id": "3601172004",
    "district_id": "360117",
    "label": "Kurungkambing",
    "value": "Kurungkambing"
  },
  {
    "id": "3601172005",
    "district_id": "360117",
    "label": "Mandalawangi",
    "value": "Mandalawangi"
  },
  {
    "id": "3601172006",
    "district_id": "360117",
    "label": "Sinarjaya",
    "value": "Sinarjaya"
  },
  {
    "id": "3601172007",
    "district_id": "360117",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3601172008",
    "district_id": "360117",
    "label": "Cikumbuen",
    "value": "Cikumbuen"
  },
  {
    "id": "3601172009",
    "district_id": "360117",
    "label": "Ramea",
    "value": "Ramea"
  },
  {
    "id": "3601172010",
    "district_id": "360117",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3601172011",
    "district_id": "360117",
    "label": "Panjangjaya",
    "value": "Panjangjaya"
  },
  {
    "id": "3601172012",
    "district_id": "360117",
    "label": "Giripawana",
    "value": "Giripawana"
  },
  {
    "id": "3601172013",
    "district_id": "360117",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3601172014",
    "district_id": "360117",
    "label": "Curuglemo",
    "value": "Curuglemo"
  },
  {
    "id": "3601172015",
    "district_id": "360117",
    "label": "Pari",
    "value": "Pari"
  },
  {
    "id": "3601182001",
    "district_id": "360118",
    "label": "Cimanuk",
    "value": "Cimanuk"
  },
  {
    "id": "3601182002",
    "district_id": "360118",
    "label": "Batubantar",
    "value": "Batubantar"
  },
  {
    "id": "3601182003",
    "district_id": "360118",
    "label": "Kadubungbang",
    "value": "Kadubungbang"
  },
  {
    "id": "3601182004",
    "district_id": "360118",
    "label": "Kupahandap",
    "value": "Kupahandap"
  },
  {
    "id": "3601182005",
    "district_id": "360118",
    "label": "Dalembalar",
    "value": "Dalembalar"
  },
  {
    "id": "3601182006",
    "district_id": "360118",
    "label": "Kadumadang",
    "value": "Kadumadang"
  },
  {
    "id": "3601182007",
    "district_id": "360118",
    "label": "Rocek",
    "value": "Rocek"
  },
  {
    "id": "3601182008",
    "district_id": "360118",
    "label": "Kadudodol",
    "value": "Kadudodol"
  },
  {
    "id": "3601182010",
    "district_id": "360118",
    "label": "Gunungdatar",
    "value": "Gunungdatar"
  },
  {
    "id": "3601182013",
    "district_id": "360118",
    "label": "Sekong",
    "value": "Sekong"
  },
  {
    "id": "3601182015",
    "district_id": "360118",
    "label": "Gunungcupu",
    "value": "Gunungcupu"
  },
  {
    "id": "3601192001",
    "district_id": "360119",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "3601192002",
    "district_id": "360119",
    "label": "Banyumundu",
    "value": "Banyumundu"
  },
  {
    "id": "3601192003",
    "district_id": "360119",
    "label": "Mandalasari",
    "value": "Mandalasari"
  },
  {
    "id": "3601192004",
    "district_id": "360119",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3601192005",
    "district_id": "360119",
    "label": "Saninten",
    "value": "Saninten"
  },
  {
    "id": "3601192006",
    "district_id": "360119",
    "label": "Palurahan",
    "value": "Palurahan"
  },
  {
    "id": "3601192007",
    "district_id": "360119",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3601192008",
    "district_id": "360119",
    "label": "Kadugemblo",
    "value": "Kadugemblo"
  },
  {
    "id": "3601192009",
    "district_id": "360119",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3601192010",
    "district_id": "360119",
    "label": "Ciputri",
    "value": "Ciputri"
  },
  {
    "id": "3601202002",
    "district_id": "360120",
    "label": "Citalahab",
    "value": "Citalahab"
  },
  {
    "id": "3601202004",
    "district_id": "360120",
    "label": "Kadulimus",
    "value": "Kadulimus"
  },
  {
    "id": "3601202005",
    "district_id": "360120",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3601202006",
    "district_id": "360120",
    "label": "Mogana",
    "value": "Mogana"
  },
  {
    "id": "3601202007",
    "district_id": "360120",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "3601202008",
    "district_id": "360120",
    "label": "Gunungputri",
    "value": "Gunungputri"
  },
  {
    "id": "3601202010",
    "district_id": "360120",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3601202011",
    "district_id": "360120",
    "label": "Kadubale",
    "value": "Kadubale"
  },
  {
    "id": "3601202012",
    "district_id": "360120",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3601202013",
    "district_id": "360120",
    "label": "Kadumaneuh",
    "value": "Kadumaneuh"
  },
  {
    "id": "3601202014",
    "district_id": "360120",
    "label": "Pasirawi",
    "value": "Pasirawi"
  },
  {
    "id": "3601211002",
    "district_id": "360121",
    "label": "Kadomas",
    "value": "Kadomas"
  },
  {
    "id": "3601211003",
    "district_id": "360121",
    "label": "Babakan Kalanganyar",
    "value": "Babakan Kalanganyar"
  },
  {
    "id": "3601211004",
    "district_id": "360121",
    "label": "Kabayan",
    "value": "Kabayan"
  },
  {
    "id": "3601211009",
    "district_id": "360121",
    "label": "Pandeglang",
    "value": "Pandeglang"
  },
  {
    "id": "3601222001",
    "district_id": "360122",
    "label": "Kaduengang",
    "value": "Kaduengang"
  },
  {
    "id": "3601222002",
    "district_id": "360122",
    "label": "Pasirpeuteuy",
    "value": "Pasirpeuteuy"
  },
  {
    "id": "3601222003",
    "district_id": "360122",
    "label": "Kurungdahu",
    "value": "Kurungdahu"
  },
  {
    "id": "3601222004",
    "district_id": "360122",
    "label": "Kaduela",
    "value": "Kaduela"
  },
  {
    "id": "3601222005",
    "district_id": "360122",
    "label": "Koranji",
    "value": "Koranji"
  },
  {
    "id": "3601222006",
    "district_id": "360122",
    "label": "Tapos",
    "value": "Tapos"
  },
  {
    "id": "3601222007",
    "district_id": "360122",
    "label": "Cadasari",
    "value": "Cadasari"
  },
  {
    "id": "3601222008",
    "district_id": "360122",
    "label": "Ciinjuk",
    "value": "Ciinjuk"
  },
  {
    "id": "3601222009",
    "district_id": "360122",
    "label": "Kaungcaang",
    "value": "Kaungcaang"
  },
  {
    "id": "3601222010",
    "district_id": "360122",
    "label": "Cikentrung",
    "value": "Cikentrung"
  },
  {
    "id": "3601222013",
    "district_id": "360122",
    "label": "Tanagara",
    "value": "Tanagara"
  },
  {
    "id": "3601232001",
    "district_id": "360123",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "3601232002",
    "district_id": "360123",
    "label": "Pasireurih",
    "value": "Pasireurih"
  },
  {
    "id": "3601232003",
    "district_id": "360123",
    "label": "Kaduronyok",
    "value": "Kaduronyok"
  },
  {
    "id": "3601232004",
    "district_id": "360123",
    "label": "Cibarani",
    "value": "Cibarani"
  },
  {
    "id": "3601232005",
    "district_id": "360123",
    "label": "Cisereh",
    "value": "Cisereh"
  },
  {
    "id": "3601232006",
    "district_id": "360123",
    "label": "Kubangkondang",
    "value": "Kubangkondang"
  },
  {
    "id": "3601232007",
    "district_id": "360123",
    "label": "Kondangjaya",
    "value": "Kondangjaya"
  },
  {
    "id": "3601232008",
    "district_id": "360123",
    "label": "Ciherangjaya",
    "value": "Ciherangjaya"
  },
  {
    "id": "3601232009",
    "district_id": "360123",
    "label": "Palembang",
    "value": "Palembang"
  },
  {
    "id": "3601242006",
    "district_id": "360124",
    "label": "Turus",
    "value": "Turus"
  },
  {
    "id": "3601242008",
    "district_id": "360124",
    "label": "Patia",
    "value": "Patia"
  },
  {
    "id": "3601242009",
    "district_id": "360124",
    "label": "Pasirgadung",
    "value": "Pasirgadung"
  },
  {
    "id": "3601242010",
    "district_id": "360124",
    "label": "Cimoyan",
    "value": "Cimoyan"
  },
  {
    "id": "3601242011",
    "district_id": "360124",
    "label": "Idaman",
    "value": "Idaman"
  },
  {
    "id": "3601242014",
    "district_id": "360124",
    "label": "Babakankeusik",
    "value": "Babakankeusik"
  },
  {
    "id": "3601242015",
    "district_id": "360124",
    "label": "Surianeun",
    "value": "Surianeun"
  },
  {
    "id": "3601242017",
    "district_id": "360124",
    "label": "Rahayu",
    "value": "Rahayu"
  },
  {
    "id": "3601242018",
    "district_id": "360124",
    "label": "Ciawi",
    "value": "Ciawi"
  },
  {
    "id": "3601242020",
    "district_id": "360124",
    "label": "Simpangtiga",
    "value": "Simpangtiga"
  },
  {
    "id": "3601251001",
    "district_id": "360125",
    "label": "Kadumerak",
    "value": "Kadumerak"
  },
  {
    "id": "3601251002",
    "district_id": "360125",
    "label": "Cigadung",
    "value": "Cigadung"
  },
  {
    "id": "3601251003",
    "district_id": "360125",
    "label": "Pagadungan",
    "value": "Pagadungan"
  },
  {
    "id": "3601251014",
    "district_id": "360125",
    "label": "Juhut",
    "value": "Juhut"
  },
  {
    "id": "3601262001",
    "district_id": "360126",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "3601262002",
    "district_id": "360126",
    "label": "Tegal",
    "value": "Tegal"
  },
  {
    "id": "3601262003",
    "district_id": "360126",
    "label": "Cipicung",
    "value": "Cipicung"
  },
  {
    "id": "3601262004",
    "district_id": "360126",
    "label": "Dahu",
    "value": "Dahu"
  },
  {
    "id": "3601262005",
    "district_id": "360126",
    "label": "Babakanlor",
    "value": "Babakanlor"
  },
  {
    "id": "3601262006",
    "district_id": "360126",
    "label": "Karyautama",
    "value": "Karyautama"
  },
  {
    "id": "3601262007",
    "district_id": "360126",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3601262008",
    "district_id": "360126",
    "label": "Padahayu",
    "value": "Padahayu"
  },
  {
    "id": "3601262009",
    "district_id": "360126",
    "label": "Cening",
    "value": "Cening"
  },
  {
    "id": "3601262010",
    "district_id": "360126",
    "label": "Bangkuyung",
    "value": "Bangkuyung"
  },
  {
    "id": "3601272001",
    "district_id": "360127",
    "label": "Sindangkerta",
    "value": "Sindangkerta"
  },
  {
    "id": "3601272002",
    "district_id": "360127",
    "label": "Manglid",
    "value": "Manglid"
  },
  {
    "id": "3601272003",
    "district_id": "360127",
    "label": "Cikiruh",
    "value": "Cikiruh"
  },
  {
    "id": "3601272004",
    "district_id": "360127",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3601272005",
    "district_id": "360127",
    "label": "Kiarapayung",
    "value": "Kiarapayung"
  },
  {
    "id": "3601272006",
    "district_id": "360127",
    "label": "Kutakarang",
    "value": "Kutakarang"
  },
  {
    "id": "3601272007",
    "district_id": "360127",
    "label": "Citeluk",
    "value": "Citeluk"
  },
  {
    "id": "3601272008",
    "district_id": "360127",
    "label": "Kiarajangkung",
    "value": "Kiarajangkung"
  },
  {
    "id": "3601272009",
    "district_id": "360127",
    "label": "Cikalong",
    "value": "Cikalong"
  },
  {
    "id": "3601272010",
    "district_id": "360127",
    "label": "Malangnengah",
    "value": "Malangnengah"
  },
  {
    "id": "3601282001",
    "district_id": "360128",
    "label": "Banjarmasin",
    "value": "Banjarmasin"
  },
  {
    "id": "3601282002",
    "district_id": "360128",
    "label": "Carita",
    "value": "Carita"
  },
  {
    "id": "3601282003",
    "district_id": "360128",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3601282004",
    "district_id": "360128",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "3601282005",
    "district_id": "360128",
    "label": "Cinoyong",
    "value": "Cinoyong"
  },
  {
    "id": "3601282006",
    "district_id": "360128",
    "label": "Pejamben",
    "value": "Pejamben"
  },
  {
    "id": "3601282007",
    "district_id": "360128",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3601282008",
    "district_id": "360128",
    "label": "Kawoyang",
    "value": "Kawoyang"
  },
  {
    "id": "3601282009",
    "district_id": "360128",
    "label": "Tembong",
    "value": "Tembong"
  },
  {
    "id": "3601282010",
    "district_id": "360128",
    "label": "Sindanglaut",
    "value": "Sindanglaut"
  },
  {
    "id": "3601292001",
    "district_id": "360129",
    "label": "Seuseupan",
    "value": "Seuseupan"
  },
  {
    "id": "3601292002",
    "district_id": "360129",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "3601292003",
    "district_id": "360129",
    "label": "Perdana",
    "value": "Perdana"
  },
  {
    "id": "3601292004",
    "district_id": "360129",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3601292005",
    "district_id": "360129",
    "label": "Pasirkadu",
    "value": "Pasirkadu"
  },
  {
    "id": "3601292006",
    "district_id": "360129",
    "label": "Kubangkampil",
    "value": "Kubangkampil"
  },
  {
    "id": "3601292007",
    "district_id": "360129",
    "label": "Sidamukti",
    "value": "Sidamukti"
  },
  {
    "id": "3601292008",
    "district_id": "360129",
    "label": "Cibungur",
    "value": "Cibungur"
  },
  {
    "id": "3601292009",
    "district_id": "360129",
    "label": "Weru",
    "value": "Weru"
  },
  {
    "id": "3601292010",
    "district_id": "360129",
    "label": "Cikuya",
    "value": "Cikuya"
  },
  {
    "id": "3601302001",
    "district_id": "360130",
    "label": "Kadubelang",
    "value": "Kadubelang"
  },
  {
    "id": "3601302002",
    "district_id": "360130",
    "label": "Pareang",
    "value": "Pareang"
  },
  {
    "id": "3601302003",
    "district_id": "360130",
    "label": "Warasinga",
    "value": "Warasinga"
  },
  {
    "id": "3601302004",
    "district_id": "360130",
    "label": "Rancabugel",
    "value": "Rancabugel"
  },
  {
    "id": "3601302005",
    "district_id": "360130",
    "label": "Kadujangkung",
    "value": "Kadujangkung"
  },
  {
    "id": "3601302006",
    "district_id": "360130",
    "label": "Medong",
    "value": "Medong"
  },
  {
    "id": "3601302007",
    "district_id": "360130",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3601302008",
    "district_id": "360130",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3601312001",
    "district_id": "360131",
    "label": "Ciodeng",
    "value": "Ciodeng"
  },
  {
    "id": "3601312002",
    "district_id": "360131",
    "label": "Pasirtenjo",
    "value": "Pasirtenjo"
  },
  {
    "id": "3601312003",
    "district_id": "360131",
    "label": "Bojongmanik",
    "value": "Bojongmanik"
  },
  {
    "id": "3601312004",
    "district_id": "360131",
    "label": "Cempakawarna",
    "value": "Cempakawarna"
  },
  {
    "id": "3601312005",
    "district_id": "360131",
    "label": "Pasirloa",
    "value": "Pasirloa"
  },
  {
    "id": "3601312006",
    "district_id": "360131",
    "label": "Pasirlancar",
    "value": "Pasirlancar"
  },
  {
    "id": "3601312007",
    "district_id": "360131",
    "label": "Sidangresmi",
    "value": "Sidangresmi"
  },
  {
    "id": "3601312008",
    "district_id": "360131",
    "label": "Pasirdurung",
    "value": "Pasirdurung"
  },
  {
    "id": "3601312009",
    "district_id": "360131",
    "label": "Kadumalati",
    "value": "Kadumalati"
  },
  {
    "id": "3601322001",
    "district_id": "360132",
    "label": "Koranji",
    "value": "Koranji"
  },
  {
    "id": "3601322002",
    "district_id": "360132",
    "label": "Banjarwangi",
    "value": "Banjarwangi"
  },
  {
    "id": "3601322003",
    "district_id": "360132",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3601322004",
    "district_id": "360132",
    "label": "Karyawangi",
    "value": "Karyawangi"
  },
  {
    "id": "3601322005",
    "district_id": "360132",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3601322006",
    "district_id": "360132",
    "label": "Kaduhejo",
    "value": "Kaduhejo"
  },
  {
    "id": "3601322007",
    "district_id": "360132",
    "label": "Banjarnegara",
    "value": "Banjarnegara"
  },
  {
    "id": "3601322008",
    "district_id": "360132",
    "label": "Sanghiangdengdek",
    "value": "Sanghiangdengdek"
  },
  {
    "id": "3601322009",
    "district_id": "360132",
    "label": "Cilentung",
    "value": "Cilentung"
  },
  {
    "id": "3601332001",
    "district_id": "360133",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3601332002",
    "district_id": "360133",
    "label": "Awilega",
    "value": "Awilega"
  },
  {
    "id": "3601332003",
    "district_id": "360133",
    "label": "Gerendong",
    "value": "Gerendong"
  },
  {
    "id": "3601332004",
    "district_id": "360133",
    "label": "Koroncong",
    "value": "Koroncong"
  },
  {
    "id": "3601332005",
    "district_id": "360133",
    "label": "Pakuluran",
    "value": "Pakuluran"
  },
  {
    "id": "3601332006",
    "district_id": "360133",
    "label": "Setrajaya",
    "value": "Setrajaya"
  },
  {
    "id": "3601332007",
    "district_id": "360133",
    "label": "Karangsetra",
    "value": "Karangsetra"
  },
  {
    "id": "3601332008",
    "district_id": "360133",
    "label": "Paniis",
    "value": "Paniis"
  },
  {
    "id": "3601332009",
    "district_id": "360133",
    "label": "Bangkonol",
    "value": "Bangkonol"
  },
  {
    "id": "3601332010",
    "district_id": "360133",
    "label": "Pasirjaksa",
    "value": "Pasirjaksa"
  },
  {
    "id": "3601332011",
    "district_id": "360133",
    "label": "Pasirkarag",
    "value": "Pasirkarag"
  },
  {
    "id": "3601332012",
    "district_id": "360133",
    "label": "Tegalongok",
    "value": "Tegalongok"
  },
  {
    "id": "3601341001",
    "district_id": "360134",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3601341002",
    "district_id": "360134",
    "label": "Karaton",
    "value": "Karaton"
  },
  {
    "id": "3601341003",
    "district_id": "360134",
    "label": "Saruni",
    "value": "Saruni"
  },
  {
    "id": "3601341004",
    "district_id": "360134",
    "label": "Pagerbatu",
    "value": "Pagerbatu"
  },
  {
    "id": "3601341005",
    "district_id": "360134",
    "label": "Cilaja",
    "value": "Cilaja"
  },
  {
    "id": "3601352001",
    "district_id": "360135",
    "label": "Cimanis",
    "value": "Cimanis"
  },
  {
    "id": "3601352002",
    "district_id": "360135",
    "label": "Teluklada",
    "value": "Teluklada"
  },
  {
    "id": "3601352003",
    "district_id": "360135",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3601352004",
    "district_id": "360135",
    "label": "Bojen",
    "value": "Bojen"
  },
  {
    "id": "3601352005",
    "district_id": "360135",
    "label": "Kutamekar",
    "value": "Kutamekar"
  },
  {
    "id": "3601352006",
    "district_id": "360135",
    "label": "Sobang",
    "value": "Sobang"
  },
  {
    "id": "3601352007",
    "district_id": "360135",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3601352008",
    "district_id": "360135",
    "label": "Bojenwetan",
    "value": "Bojenwetan"
  },
  {
    "id": "3602012002",
    "district_id": "360201",
    "label": "Cilangkahan",
    "value": "Cilangkahan"
  },
  {
    "id": "3602012003",
    "district_id": "360201",
    "label": "Pagelaran",
    "value": "Pagelaran"
  },
  {
    "id": "3602012004",
    "district_id": "360201",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3602012005",
    "district_id": "360201",
    "label": "Malingping Utara",
    "value": "Malingping Utara"
  },
  {
    "id": "3602012006",
    "district_id": "360201",
    "label": "Bolang",
    "value": "Bolang"
  },
  {
    "id": "3602012009",
    "district_id": "360201",
    "label": "Cipeundeuy",
    "value": "Cipeundeuy"
  },
  {
    "id": "3602012013",
    "district_id": "360201",
    "label": "Rahong",
    "value": "Rahong"
  },
  {
    "id": "3602012014",
    "district_id": "360201",
    "label": "Kadujajar",
    "value": "Kadujajar"
  },
  {
    "id": "3602012016",
    "district_id": "360201",
    "label": "Kersaratu",
    "value": "Kersaratu"
  },
  {
    "id": "3602012018",
    "district_id": "360201",
    "label": "Malingping Selatan",
    "value": "Malingping Selatan"
  },
  {
    "id": "3602012019",
    "district_id": "360201",
    "label": "Sumberwaras",
    "value": "Sumberwaras"
  },
  {
    "id": "3602012023",
    "district_id": "360201",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3602012024",
    "district_id": "360201",
    "label": "Senanghati",
    "value": "Senanghati"
  },
  {
    "id": "3602012026",
    "district_id": "360201",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3602022002",
    "district_id": "360202",
    "label": "Panggarangan",
    "value": "Panggarangan"
  },
  {
    "id": "3602022003",
    "district_id": "360202",
    "label": "Jatake",
    "value": "Jatake"
  },
  {
    "id": "3602022004",
    "district_id": "360202",
    "label": "Sogong",
    "value": "Sogong"
  },
  {
    "id": "3602022005",
    "district_id": "360202",
    "label": "Cimandiri",
    "value": "Cimandiri"
  },
  {
    "id": "3602022009",
    "district_id": "360202",
    "label": "Gununggede",
    "value": "Gununggede"
  },
  {
    "id": "3602022010",
    "district_id": "360202",
    "label": "Situregen",
    "value": "Situregen"
  },
  {
    "id": "3602022011",
    "district_id": "360202",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3602022014",
    "district_id": "360202",
    "label": "Sindangratu",
    "value": "Sindangratu"
  },
  {
    "id": "3602022017",
    "district_id": "360202",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3602022018",
    "district_id": "360202",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3602022019",
    "district_id": "360202",
    "label": "Cibarengkok",
    "value": "Cibarengkok"
  },
  {
    "id": "3602032001",
    "district_id": "360203",
    "label": "Bayah Barat",
    "value": "Bayah Barat"
  },
  {
    "id": "3602032002",
    "district_id": "360203",
    "label": "Sawarna",
    "value": "Sawarna"
  },
  {
    "id": "3602032006",
    "district_id": "360203",
    "label": "Cidikit",
    "value": "Cidikit"
  },
  {
    "id": "3602032007",
    "district_id": "360203",
    "label": "Suwakan",
    "value": "Suwakan"
  },
  {
    "id": "3602032008",
    "district_id": "360203",
    "label": "Cimancak",
    "value": "Cimancak"
  },
  {
    "id": "3602032011",
    "district_id": "360203",
    "label": "Darmasari",
    "value": "Darmasari"
  },
  {
    "id": "3602032013",
    "district_id": "360203",
    "label": "Bayah Timur",
    "value": "Bayah Timur"
  },
  {
    "id": "3602032014",
    "district_id": "360203",
    "label": "Cisuren",
    "value": "Cisuren"
  },
  {
    "id": "3602032016",
    "district_id": "360203",
    "label": "Pasirgombong",
    "value": "Pasirgombong"
  },
  {
    "id": "3602032017",
    "district_id": "360203",
    "label": "Sawarna Timur",
    "value": "Sawarna Timur"
  },
  {
    "id": "3602032018",
    "district_id": "360203",
    "label": "Pamubulan",
    "value": "Pamubulan"
  },
  {
    "id": "3602042002",
    "district_id": "360204",
    "label": "Cipanas",
    "value": "Cipanas"
  },
  {
    "id": "3602042003",
    "district_id": "360204",
    "label": "Girilaya",
    "value": "Girilaya"
  },
  {
    "id": "3602042004",
    "district_id": "360204",
    "label": "Malangsari",
    "value": "Malangsari"
  },
  {
    "id": "3602042005",
    "district_id": "360204",
    "label": "Bintangresmi",
    "value": "Bintangresmi"
  },
  {
    "id": "3602042006",
    "district_id": "360204",
    "label": "Talagahiyang",
    "value": "Talagahiyang"
  },
  {
    "id": "3602042007",
    "district_id": "360204",
    "label": "Luhurjaya",
    "value": "Luhurjaya"
  },
  {
    "id": "3602042008",
    "district_id": "360204",
    "label": "Haurgajrug",
    "value": "Haurgajrug"
  },
  {
    "id": "3602042009",
    "district_id": "360204",
    "label": "Giriharja",
    "value": "Giriharja"
  },
  {
    "id": "3602042010",
    "district_id": "360204",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3602042013",
    "district_id": "360204",
    "label": "Jayapura",
    "value": "Jayapura"
  },
  {
    "id": "3602042014",
    "district_id": "360204",
    "label": "Sipayung",
    "value": "Sipayung"
  },
  {
    "id": "3602042015",
    "district_id": "360204",
    "label": "Bintangsari",
    "value": "Bintangsari"
  },
  {
    "id": "3602042016",
    "district_id": "360204",
    "label": "Pasirhaur",
    "value": "Pasirhaur"
  },
  {
    "id": "3602042020",
    "district_id": "360204",
    "label": "Harumsari",
    "value": "Harumsari"
  },
  {
    "id": "3602052003",
    "district_id": "360205",
    "label": "Pasireurih",
    "value": "Pasireurih"
  },
  {
    "id": "3602052004",
    "district_id": "360205",
    "label": "Ciminyak",
    "value": "Ciminyak"
  },
  {
    "id": "3602052005",
    "district_id": "360205",
    "label": "Muncang",
    "value": "Muncang"
  },
  {
    "id": "3602052006",
    "district_id": "360205",
    "label": "Leuwicoo",
    "value": "Leuwicoo"
  },
  {
    "id": "3602052007",
    "district_id": "360205",
    "label": "Sindangwangi",
    "value": "Sindangwangi"
  },
  {
    "id": "3602052008",
    "district_id": "360205",
    "label": "Cikarang",
    "value": "Cikarang"
  },
  {
    "id": "3602052012",
    "district_id": "360205",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3602052013",
    "district_id": "360205",
    "label": "Pasirnangka",
    "value": "Pasirnangka"
  },
  {
    "id": "3602052018",
    "district_id": "360205",
    "label": "Jagaraksa",
    "value": "Jagaraksa"
  },
  {
    "id": "3602052019",
    "district_id": "360205",
    "label": "Tanjungwangi",
    "value": "Tanjungwangi"
  },
  {
    "id": "3602052020",
    "district_id": "360205",
    "label": "Mekarwangi",
    "value": "Mekarwangi"
  },
  {
    "id": "3602052021",
    "district_id": "360205",
    "label": "Girijagabaya",
    "value": "Girijagabaya"
  },
  {
    "id": "3602062001",
    "district_id": "360206",
    "label": "Kanekes",
    "value": "Kanekes"
  },
  {
    "id": "3602062002",
    "district_id": "360206",
    "label": "Cisimeut",
    "value": "Cisimeut"
  },
  {
    "id": "3602062003",
    "district_id": "360206",
    "label": "Cibungur",
    "value": "Cibungur"
  },
  {
    "id": "3602062004",
    "district_id": "360206",
    "label": "Leuwidamar",
    "value": "Leuwidamar"
  },
  {
    "id": "3602062005",
    "district_id": "360206",
    "label": "Lebakparahiang",
    "value": "Lebakparahiang"
  },
  {
    "id": "3602062006",
    "district_id": "360206",
    "label": "Sangkanwangi",
    "value": "Sangkanwangi"
  },
  {
    "id": "3602062007",
    "district_id": "360206",
    "label": "Nayagati",
    "value": "Nayagati"
  },
  {
    "id": "3602062008",
    "district_id": "360206",
    "label": "Bojongmenteng",
    "value": "Bojongmenteng"
  },
  {
    "id": "3602062009",
    "district_id": "360206",
    "label": "Margawangi",
    "value": "Margawangi"
  },
  {
    "id": "3602062010",
    "district_id": "360206",
    "label": "Wantisari",
    "value": "Wantisari"
  },
  {
    "id": "3602062011",
    "district_id": "360206",
    "label": "Jalupangmulya",
    "value": "Jalupangmulya"
  },
  {
    "id": "3602062012",
    "district_id": "360206",
    "label": "Cisimeut Raya",
    "value": "Cisimeut Raya"
  },
  {
    "id": "3602072004",
    "district_id": "360207",
    "label": "Bojongmanik",
    "value": "Bojongmanik"
  },
  {
    "id": "3602072007",
    "district_id": "360207",
    "label": "Harjawana",
    "value": "Harjawana"
  },
  {
    "id": "3602072008",
    "district_id": "360207",
    "label": "Kadurahayu",
    "value": "Kadurahayu"
  },
  {
    "id": "3602072009",
    "district_id": "360207",
    "label": "Cimayang",
    "value": "Cimayang"
  },
  {
    "id": "3602072010",
    "district_id": "360207",
    "label": "Parakanbeusi",
    "value": "Parakanbeusi"
  },
  {
    "id": "3602072012",
    "district_id": "360207",
    "label": "Keboncau",
    "value": "Keboncau"
  },
  {
    "id": "3602072014",
    "district_id": "360207",
    "label": "Mekarmanik",
    "value": "Mekarmanik"
  },
  {
    "id": "3602072016",
    "district_id": "360207",
    "label": "Mekar Rahayu",
    "value": "Mekar Rahayu"
  },
  {
    "id": "3602072017",
    "district_id": "360207",
    "label": "Pasirbitung",
    "value": "Pasirbitung"
  },
  {
    "id": "3602082001",
    "district_id": "360208",
    "label": "Gunungkencana",
    "value": "Gunungkencana"
  },
  {
    "id": "3602082002",
    "district_id": "360208",
    "label": "Cimanyangray",
    "value": "Cimanyangray"
  },
  {
    "id": "3602082003",
    "district_id": "360208",
    "label": "Gunungkendeng",
    "value": "Gunungkendeng"
  },
  {
    "id": "3602082004",
    "district_id": "360208",
    "label": "Cisampang",
    "value": "Cisampang"
  },
  {
    "id": "3602082005",
    "district_id": "360208",
    "label": "Ciginggang",
    "value": "Ciginggang"
  },
  {
    "id": "3602082006",
    "district_id": "360208",
    "label": "Ciakar",
    "value": "Ciakar"
  },
  {
    "id": "3602082007",
    "district_id": "360208",
    "label": "Cicaringin",
    "value": "Cicaringin"
  },
  {
    "id": "3602082008",
    "district_id": "360208",
    "label": "Bulakan",
    "value": "Bulakan"
  },
  {
    "id": "3602082009",
    "district_id": "360208",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "3602082010",
    "district_id": "360208",
    "label": "Bojongkoneng",
    "value": "Bojongkoneng"
  },
  {
    "id": "3602082011",
    "district_id": "360208",
    "label": "Kramatjaya",
    "value": "Kramatjaya"
  },
  {
    "id": "3602082012",
    "district_id": "360208",
    "label": "Tanjungsari Indah",
    "value": "Tanjungsari Indah"
  },
  {
    "id": "3602092001",
    "district_id": "360209",
    "label": "Kerta",
    "value": "Kerta"
  },
  {
    "id": "3602092002",
    "district_id": "360209",
    "label": "Bojongjuruh",
    "value": "Bojongjuruh"
  },
  {
    "id": "3602092003",
    "district_id": "360209",
    "label": "Leuwiipuh",
    "value": "Leuwiipuh"
  },
  {
    "id": "3602092004",
    "district_id": "360209",
    "label": "Lebakkeusik",
    "value": "Lebakkeusik"
  },
  {
    "id": "3602092005",
    "district_id": "360209",
    "label": "Cilegongilir",
    "value": "Cilegongilir"
  },
  {
    "id": "3602092006",
    "district_id": "360209",
    "label": "Keusik",
    "value": "Keusik"
  },
  {
    "id": "3602092007",
    "district_id": "360209",
    "label": "Cibaturkeusik",
    "value": "Cibaturkeusik"
  },
  {
    "id": "3602092009",
    "district_id": "360209",
    "label": "Kumpay",
    "value": "Kumpay"
  },
  {
    "id": "3602092010",
    "district_id": "360209",
    "label": "Jalupanggirang",
    "value": "Jalupanggirang"
  },
  {
    "id": "3602092011",
    "district_id": "360209",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3602092012",
    "district_id": "360209",
    "label": "Kertaraharja",
    "value": "Kertaraharja"
  },
  {
    "id": "3602092013",
    "district_id": "360209",
    "label": "Cisampih",
    "value": "Cisampih"
  },
  {
    "id": "3602092014",
    "district_id": "360209",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3602092015",
    "district_id": "360209",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3602092016",
    "district_id": "360209",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3602092017",
    "district_id": "360209",
    "label": "Ciruji",
    "value": "Ciruji"
  },
  {
    "id": "3602092018",
    "district_id": "360209",
    "label": "Kaduhauk",
    "value": "Kaduhauk"
  },
  {
    "id": "3602092019",
    "district_id": "360209",
    "label": "Labanjaya",
    "value": "Labanjaya"
  },
  {
    "id": "3602092020",
    "district_id": "360209",
    "label": "Umbuljaya",
    "value": "Umbuljaya"
  },
  {
    "id": "3602092021",
    "district_id": "360209",
    "label": "Kertarahayu",
    "value": "Kertarahayu"
  },
  {
    "id": "3602102001",
    "district_id": "360210",
    "label": "Pasindangan",
    "value": "Pasindangan"
  },
  {
    "id": "3602102002",
    "district_id": "360210",
    "label": "Parungkujang",
    "value": "Parungkujang"
  },
  {
    "id": "3602102003",
    "district_id": "360210",
    "label": "Cileles",
    "value": "Cileles"
  },
  {
    "id": "3602102004",
    "district_id": "360210",
    "label": "Cikareo",
    "value": "Cikareo"
  },
  {
    "id": "3602102005",
    "district_id": "360210",
    "label": "Cipadang",
    "value": "Cipadang"
  },
  {
    "id": "3602102006",
    "district_id": "360210",
    "label": "Gumuruh",
    "value": "Gumuruh"
  },
  {
    "id": "3602102007",
    "district_id": "360210",
    "label": "Prabugantungan",
    "value": "Prabugantungan"
  },
  {
    "id": "3602102008",
    "district_id": "360210",
    "label": "Daroyon",
    "value": "Daroyon"
  },
  {
    "id": "3602102009",
    "district_id": "360210",
    "label": "Margamulya",
    "value": "Margamulya"
  },
  {
    "id": "3602102010",
    "district_id": "360210",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3602102011",
    "district_id": "360210",
    "label": "Kujangsari",
    "value": "Kujangsari"
  },
  {
    "id": "3602102012",
    "district_id": "360210",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3602112001",
    "district_id": "360211",
    "label": "Sarageni",
    "value": "Sarageni"
  },
  {
    "id": "3602112002",
    "district_id": "360211",
    "label": "Gununganten",
    "value": "Gununganten"
  },
  {
    "id": "3602112003",
    "district_id": "360211",
    "label": "Sudamanik",
    "value": "Sudamanik"
  },
  {
    "id": "3602112004",
    "district_id": "360211",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3602112005",
    "district_id": "360211",
    "label": "Cimarga",
    "value": "Cimarga"
  },
  {
    "id": "3602112006",
    "district_id": "360211",
    "label": "Karyajaya",
    "value": "Karyajaya"
  },
  {
    "id": "3602112007",
    "district_id": "360211",
    "label": "Margajaya",
    "value": "Margajaya"
  },
  {
    "id": "3602112008",
    "district_id": "360211",
    "label": "Jayamanik",
    "value": "Jayamanik"
  },
  {
    "id": "3602112009",
    "district_id": "360211",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3602112010",
    "district_id": "360211",
    "label": "Margatirta",
    "value": "Margatirta"
  },
  {
    "id": "3602112011",
    "district_id": "360211",
    "label": "Intenjaya",
    "value": "Intenjaya"
  },
  {
    "id": "3602112012",
    "district_id": "360211",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3602112013",
    "district_id": "360211",
    "label": "Jayasari",
    "value": "Jayasari"
  },
  {
    "id": "3602112014",
    "district_id": "360211",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3602112015",
    "district_id": "360211",
    "label": "Sangkanmanik",
    "value": "Sangkanmanik"
  },
  {
    "id": "3602112016",
    "district_id": "360211",
    "label": "Sangiangjaya",
    "value": "Sangiangjaya"
  },
  {
    "id": "3602112017",
    "district_id": "360211",
    "label": "Mekarmulya",
    "value": "Mekarmulya"
  },
  {
    "id": "3602122001",
    "district_id": "360212",
    "label": "Maraya",
    "value": "Maraya"
  },
  {
    "id": "3602122002",
    "district_id": "360212",
    "label": "Sajira",
    "value": "Sajira"
  },
  {
    "id": "3602122003",
    "district_id": "360212",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3602122004",
    "district_id": "360212",
    "label": "Calungbungur",
    "value": "Calungbungur"
  },
  {
    "id": "3602122005",
    "district_id": "360212",
    "label": "Parungsari",
    "value": "Parungsari"
  },
  {
    "id": "3602122006",
    "district_id": "360212",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3602122007",
    "district_id": "360212",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3602122008",
    "district_id": "360212",
    "label": "Sukamarga",
    "value": "Sukamarga"
  },
  {
    "id": "3602122009",
    "district_id": "360212",
    "label": "Pajagan",
    "value": "Pajagan"
  },
  {
    "id": "3602122010",
    "district_id": "360212",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3602122011",
    "district_id": "360212",
    "label": "Sajira Mekar",
    "value": "Sajira Mekar"
  },
  {
    "id": "3602122012",
    "district_id": "360212",
    "label": "Paja",
    "value": "Paja"
  },
  {
    "id": "3602122013",
    "district_id": "360212",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3602122014",
    "district_id": "360212",
    "label": "Bungurmekar",
    "value": "Bungurmekar"
  },
  {
    "id": "3602122015",
    "district_id": "360212",
    "label": "Ciuyah",
    "value": "Ciuyah"
  },
  {
    "id": "3602132005",
    "district_id": "360213",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3602132006",
    "district_id": "360213",
    "label": "Maja",
    "value": "Maja"
  },
  {
    "id": "3602132007",
    "district_id": "360213",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3602132008",
    "district_id": "360213",
    "label": "Binong",
    "value": "Binong"
  },
  {
    "id": "3602132009",
    "district_id": "360213",
    "label": "Gubugan Cibeureum",
    "value": "Gubugan Cibeureum"
  },
  {
    "id": "3602132011",
    "district_id": "360213",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3602132012",
    "district_id": "360213",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3602132015",
    "district_id": "360213",
    "label": "Curugbadak",
    "value": "Curugbadak"
  },
  {
    "id": "3602132016",
    "district_id": "360213",
    "label": "Sindangmulya",
    "value": "Sindangmulya"
  },
  {
    "id": "3602132018",
    "district_id": "360213",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3602132020",
    "district_id": "360213",
    "label": "Pasirkembang",
    "value": "Pasirkembang"
  },
  {
    "id": "3602132021",
    "district_id": "360213",
    "label": "Pasirkecapi",
    "value": "Pasirkecapi"
  },
  {
    "id": "3602132022",
    "district_id": "360213",
    "label": "Buyut Mekar",
    "value": "Buyut Mekar"
  },
  {
    "id": "3602132023",
    "district_id": "360213",
    "label": "Maja Baru",
    "value": "Maja Baru"
  },
  {
    "id": "3602141002",
    "district_id": "360214",
    "label": "Rangkasbitung Barat",
    "value": "Rangkasbitung Barat"
  },
  {
    "id": "3602141006",
    "district_id": "360214",
    "label": "Cijoro Lebak",
    "value": "Cijoro Lebak"
  },
  {
    "id": "3602141007",
    "district_id": "360214",
    "label": "Muara Ciujung Barat",
    "value": "Muara Ciujung Barat"
  },
  {
    "id": "3602141008",
    "district_id": "360214",
    "label": "Cijoro Pasir",
    "value": "Cijoro Pasir"
  },
  {
    "id": "3602141012",
    "district_id": "360214",
    "label": "Muara Ciujung Timur",
    "value": "Muara Ciujung Timur"
  },
  {
    "id": "3602142001",
    "district_id": "360214",
    "label": "Pasirtanjung",
    "value": "Pasirtanjung"
  },
  {
    "id": "3602142009",
    "district_id": "360214",
    "label": "Citeras",
    "value": "Citeras"
  },
  {
    "id": "3602142010",
    "district_id": "360214",
    "label": "Nameng",
    "value": "Nameng"
  },
  {
    "id": "3602142011",
    "district_id": "360214",
    "label": "Kolelet Wetan",
    "value": "Kolelet Wetan"
  },
  {
    "id": "3602142013",
    "district_id": "360214",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "3602142014",
    "district_id": "360214",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3602142016",
    "district_id": "360214",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3602142017",
    "district_id": "360214",
    "label": "Rangkasbitung Timur",
    "value": "Rangkasbitung Timur"
  },
  {
    "id": "3602142019",
    "district_id": "360214",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3602142021",
    "district_id": "360214",
    "label": "Cimangeunteung",
    "value": "Cimangeunteung"
  },
  {
    "id": "3602142023",
    "district_id": "360214",
    "label": "Narimbang Mulia",
    "value": "Narimbang Mulia"
  },
  {
    "id": "3602152001",
    "district_id": "360215",
    "label": "Sukarendah",
    "value": "Sukarendah"
  },
  {
    "id": "3602152002",
    "district_id": "360215",
    "label": "Warunggunung",
    "value": "Warunggunung"
  },
  {
    "id": "3602152003",
    "district_id": "360215",
    "label": "Cibuah",
    "value": "Cibuah"
  },
  {
    "id": "3602152004",
    "district_id": "360215",
    "label": "Pasir Tangkil",
    "value": "Pasir Tangkil"
  },
  {
    "id": "3602152005",
    "district_id": "360215",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "3602152006",
    "district_id": "360215",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3602152007",
    "district_id": "360215",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3602152008",
    "district_id": "360215",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3602152009",
    "district_id": "360215",
    "label": "Jagabaya",
    "value": "Jagabaya"
  },
  {
    "id": "3602152010",
    "district_id": "360215",
    "label": "Selaraja",
    "value": "Selaraja"
  },
  {
    "id": "3602152011",
    "district_id": "360215",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "3602152012",
    "district_id": "360215",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3602162004",
    "district_id": "360216",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "3602162006",
    "district_id": "360216",
    "label": "Cipalabuh",
    "value": "Cipalabuh"
  },
  {
    "id": "3602162007",
    "district_id": "360216",
    "label": "Cijaku",
    "value": "Cijaku"
  },
  {
    "id": "3602162008",
    "district_id": "360216",
    "label": "Cibeureum",
    "value": "Cibeureum"
  },
  {
    "id": "3602162012",
    "district_id": "360216",
    "label": "Ciapus",
    "value": "Ciapus"
  },
  {
    "id": "3602162013",
    "district_id": "360216",
    "label": "Kandangsapi",
    "value": "Kandangsapi"
  },
  {
    "id": "3602162014",
    "district_id": "360216",
    "label": "Cihujan",
    "value": "Cihujan"
  },
  {
    "id": "3602162015",
    "district_id": "360216",
    "label": "Cimenga",
    "value": "Cimenga"
  },
  {
    "id": "3602162016",
    "district_id": "360216",
    "label": "Kapunduhan",
    "value": "Kapunduhan"
  },
  {
    "id": "3602162017",
    "district_id": "360216",
    "label": "Sukasenang",
    "value": "Sukasenang"
  },
  {
    "id": "3602162018",
    "district_id": "360216",
    "label": "Cikaratuan",
    "value": "Cikaratuan"
  },
  {
    "id": "3602172001",
    "district_id": "360217",
    "label": "Anggalan",
    "value": "Anggalan"
  },
  {
    "id": "3602172002",
    "district_id": "360217",
    "label": "Muncangkopong",
    "value": "Muncangkopong"
  },
  {
    "id": "3602172003",
    "district_id": "360217",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3602172004",
    "district_id": "360217",
    "label": "Tamanjaya",
    "value": "Tamanjaya"
  },
  {
    "id": "3602172005",
    "district_id": "360217",
    "label": "Cigoong Utara",
    "value": "Cigoong Utara"
  },
  {
    "id": "3602172006",
    "district_id": "360217",
    "label": "Muaradua",
    "value": "Muaradua"
  },
  {
    "id": "3602172007",
    "district_id": "360217",
    "label": "Cikulur",
    "value": "Cikulur"
  },
  {
    "id": "3602172008",
    "district_id": "360217",
    "label": "Curugpanjang",
    "value": "Curugpanjang"
  },
  {
    "id": "3602172009",
    "district_id": "360217",
    "label": "Cigoong Selatan",
    "value": "Cigoong Selatan"
  },
  {
    "id": "3602172010",
    "district_id": "360217",
    "label": "Sumurbandung",
    "value": "Sumurbandung"
  },
  {
    "id": "3602172011",
    "district_id": "360217",
    "label": "Parage",
    "value": "Parage"
  },
  {
    "id": "3602172012",
    "district_id": "360217",
    "label": "Sukadaya",
    "value": "Sukadaya"
  },
  {
    "id": "3602172013",
    "district_id": "360217",
    "label": "Pasirgintung",
    "value": "Pasirgintung"
  },
  {
    "id": "3602182001",
    "district_id": "360218",
    "label": "Tambakbaya",
    "value": "Tambakbaya"
  },
  {
    "id": "3602182002",
    "district_id": "360218",
    "label": "Kaduagung Timur",
    "value": "Kaduagung Timur"
  },
  {
    "id": "3602182003",
    "district_id": "360218",
    "label": "Panancangan",
    "value": "Panancangan"
  },
  {
    "id": "3602182004",
    "district_id": "360218",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3602182005",
    "district_id": "360218",
    "label": "Asem",
    "value": "Asem"
  },
  {
    "id": "3602182006",
    "district_id": "360218",
    "label": "Cisangu",
    "value": "Cisangu"
  },
  {
    "id": "3602182007",
    "district_id": "360218",
    "label": "Kaduagung Barat",
    "value": "Kaduagung Barat"
  },
  {
    "id": "3602182008",
    "district_id": "360218",
    "label": "Bojongcae",
    "value": "Bojongcae"
  },
  {
    "id": "3602182009",
    "district_id": "360218",
    "label": "Malabar",
    "value": "Malabar"
  },
  {
    "id": "3602182010",
    "district_id": "360218",
    "label": "Pasar Keong",
    "value": "Pasar Keong"
  },
  {
    "id": "3602182011",
    "district_id": "360218",
    "label": "Bojong Leles",
    "value": "Bojong Leles"
  },
  {
    "id": "3602182012",
    "district_id": "360218",
    "label": "Cimenteng Jaya",
    "value": "Cimenteng Jaya"
  },
  {
    "id": "3602182013",
    "district_id": "360218",
    "label": "Mekar Agung",
    "value": "Mekar Agung"
  },
  {
    "id": "3602182014",
    "district_id": "360218",
    "label": "Asem Margaluyu",
    "value": "Asem Margaluyu"
  },
  {
    "id": "3602182015",
    "district_id": "360218",
    "label": "Kaduagung Tengah",
    "value": "Kaduagung Tengah"
  },
  {
    "id": "3602192001",
    "district_id": "360219",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3602192002",
    "district_id": "360219",
    "label": "Citorek Tengah",
    "value": "Citorek Tengah"
  },
  {
    "id": "3602192003",
    "district_id": "360219",
    "label": "Cisungsang",
    "value": "Cisungsang"
  },
  {
    "id": "3602192004",
    "district_id": "360219",
    "label": "Kujangjaya",
    "value": "Kujangjaya"
  },
  {
    "id": "3602192005",
    "district_id": "360219",
    "label": "Kujangsari",
    "value": "Kujangsari"
  },
  {
    "id": "3602192006",
    "district_id": "360219",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3602192007",
    "district_id": "360219",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3602192008",
    "district_id": "360219",
    "label": "Cikotok",
    "value": "Cikotok"
  },
  {
    "id": "3602192009",
    "district_id": "360219",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3602192010",
    "district_id": "360219",
    "label": "Citorek Timur",
    "value": "Citorek Timur"
  },
  {
    "id": "3602192011",
    "district_id": "360219",
    "label": "Warungbanten",
    "value": "Warungbanten"
  },
  {
    "id": "3602192013",
    "district_id": "360219",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "3602192014",
    "district_id": "360219",
    "label": "Situmulya",
    "value": "Situmulya"
  },
  {
    "id": "3602192015",
    "district_id": "360219",
    "label": "Citorek Kidul",
    "value": "Citorek Kidul"
  },
  {
    "id": "3602192016",
    "district_id": "360219",
    "label": "Cikadu",
    "value": "Cikadu"
  },
  {
    "id": "3602192017",
    "district_id": "360219",
    "label": "Sirnagalih",
    "value": "Sirnagalih"
  },
  {
    "id": "3602192018",
    "district_id": "360219",
    "label": "Cihambali",
    "value": "Cihambali"
  },
  {
    "id": "3602192019",
    "district_id": "360219",
    "label": "Citorek Barat",
    "value": "Citorek Barat"
  },
  {
    "id": "3602192020",
    "district_id": "360219",
    "label": "Gunungwangun",
    "value": "Gunungwangun"
  },
  {
    "id": "3602192021",
    "district_id": "360219",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "3602192022",
    "district_id": "360219",
    "label": "Citorek Sabrang",
    "value": "Citorek Sabrang"
  },
  {
    "id": "3602192023",
    "district_id": "360219",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3602202001",
    "district_id": "360220",
    "label": "Cilograng",
    "value": "Cilograng"
  },
  {
    "id": "3602202002",
    "district_id": "360220",
    "label": "Cibareno",
    "value": "Cibareno"
  },
  {
    "id": "3602202003",
    "district_id": "360220",
    "label": "Cikamunding",
    "value": "Cikamunding"
  },
  {
    "id": "3602202004",
    "district_id": "360220",
    "label": "Cijengkol",
    "value": "Cijengkol"
  },
  {
    "id": "3602202005",
    "district_id": "360220",
    "label": "Pasirbungur",
    "value": "Pasirbungur"
  },
  {
    "id": "3602202006",
    "district_id": "360220",
    "label": "Lebaktipar",
    "value": "Lebaktipar"
  },
  {
    "id": "3602202007",
    "district_id": "360220",
    "label": "Cikatomas",
    "value": "Cikatomas"
  },
  {
    "id": "3602202008",
    "district_id": "360220",
    "label": "Girimukti",
    "value": "Girimukti"
  },
  {
    "id": "3602202009",
    "district_id": "360220",
    "label": "Cireundeu",
    "value": "Cireundeu"
  },
  {
    "id": "3602202010",
    "district_id": "360220",
    "label": "Gunungbatu",
    "value": "Gunungbatu"
  },
  {
    "id": "3602212001",
    "district_id": "360221",
    "label": "Wanasalam",
    "value": "Wanasalam"
  },
  {
    "id": "3602212002",
    "district_id": "360221",
    "label": "Bejod",
    "value": "Bejod"
  },
  {
    "id": "3602212003",
    "district_id": "360221",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3602212004",
    "district_id": "360221",
    "label": "Cipeucang",
    "value": "Cipeucang"
  },
  {
    "id": "3602212005",
    "district_id": "360221",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "3602212006",
    "district_id": "360221",
    "label": "Parungpanjang",
    "value": "Parungpanjang"
  },
  {
    "id": "3602212007",
    "district_id": "360221",
    "label": "Cikeusik",
    "value": "Cikeusik"
  },
  {
    "id": "3602212008",
    "district_id": "360221",
    "label": "Katapang",
    "value": "Katapang"
  },
  {
    "id": "3602212009",
    "district_id": "360221",
    "label": "Cisarap",
    "value": "Cisarap"
  },
  {
    "id": "3602212010",
    "district_id": "360221",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3602212011",
    "district_id": "360221",
    "label": "Cipedang",
    "value": "Cipedang"
  },
  {
    "id": "3602212012",
    "district_id": "360221",
    "label": "Parungsari",
    "value": "Parungsari"
  },
  {
    "id": "3602212013",
    "district_id": "360221",
    "label": "Karangpamindangan",
    "value": "Karangpamindangan"
  },
  {
    "id": "3602222001",
    "district_id": "360222",
    "label": "Ciparasi",
    "value": "Ciparasi"
  },
  {
    "id": "3602222002",
    "district_id": "360222",
    "label": "Sobang",
    "value": "Sobang"
  },
  {
    "id": "3602222003",
    "district_id": "360222",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3602222004",
    "district_id": "360222",
    "label": "Sindanglaya",
    "value": "Sindanglaya"
  },
  {
    "id": "3602222005",
    "district_id": "360222",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3602222006",
    "district_id": "360222",
    "label": "Hariang",
    "value": "Hariang"
  },
  {
    "id": "3602222007",
    "district_id": "360222",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3602222008",
    "district_id": "360222",
    "label": "Sinar Jaya",
    "value": "Sinar Jaya"
  },
  {
    "id": "3602222009",
    "district_id": "360222",
    "label": "Cirompang",
    "value": "Cirompang"
  },
  {
    "id": "3602222010",
    "district_id": "360222",
    "label": "Sukaresmi",
    "value": "Sukaresmi"
  },
  {
    "id": "3602232001",
    "district_id": "360223",
    "label": "Guradog",
    "value": "Guradog"
  },
  {
    "id": "3602232002",
    "district_id": "360223",
    "label": "Curugbitung",
    "value": "Curugbitung"
  },
  {
    "id": "3602232003",
    "district_id": "360223",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "3602232004",
    "district_id": "360223",
    "label": "Mayak",
    "value": "Mayak"
  },
  {
    "id": "3602232005",
    "district_id": "360223",
    "label": "Cipining",
    "value": "Cipining"
  },
  {
    "id": "3602232006",
    "district_id": "360223",
    "label": "Cilayang",
    "value": "Cilayang"
  },
  {
    "id": "3602232007",
    "district_id": "360223",
    "label": "Ciburuy",
    "value": "Ciburuy"
  },
  {
    "id": "3602232008",
    "district_id": "360223",
    "label": "Sekarwangi",
    "value": "Sekarwangi"
  },
  {
    "id": "3602232009",
    "district_id": "360223",
    "label": "Cidadap",
    "value": "Cidadap"
  },
  {
    "id": "3602232010",
    "district_id": "360223",
    "label": "Lebakasih",
    "value": "Lebakasih"
  },
  {
    "id": "3602242001",
    "district_id": "360224",
    "label": "Kalanganyar",
    "value": "Kalanganyar"
  },
  {
    "id": "3602242002",
    "district_id": "360224",
    "label": "Pasirkupa",
    "value": "Pasirkupa"
  },
  {
    "id": "3602242003",
    "district_id": "360224",
    "label": "Cilangkap",
    "value": "Cilangkap"
  },
  {
    "id": "3602242004",
    "district_id": "360224",
    "label": "Aweh",
    "value": "Aweh"
  },
  {
    "id": "3602242005",
    "district_id": "360224",
    "label": "Sangiangtanjung",
    "value": "Sangiangtanjung"
  },
  {
    "id": "3602242006",
    "district_id": "360224",
    "label": "Sukamekarsari",
    "value": "Sukamekarsari"
  },
  {
    "id": "3602242007",
    "district_id": "360224",
    "label": "Cikatapis",
    "value": "Cikatapis"
  },
  {
    "id": "3602252001",
    "district_id": "360225",
    "label": "Banjaririgasi",
    "value": "Banjaririgasi"
  },
  {
    "id": "3602252002",
    "district_id": "360225",
    "label": "Ciladaeun",
    "value": "Ciladaeun"
  },
  {
    "id": "3602252003",
    "district_id": "360225",
    "label": "Lebakgedong",
    "value": "Lebakgedong"
  },
  {
    "id": "3602252004",
    "district_id": "360225",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3602252005",
    "district_id": "360225",
    "label": "Lebaksitu",
    "value": "Lebaksitu"
  },
  {
    "id": "3602252006",
    "district_id": "360225",
    "label": "Lebaksangka",
    "value": "Lebaksangka"
  },
  {
    "id": "3602262001",
    "district_id": "360226",
    "label": "Panyaungan",
    "value": "Panyaungan"
  },
  {
    "id": "3602262002",
    "district_id": "360226",
    "label": "Cihara",
    "value": "Cihara"
  },
  {
    "id": "3602262003",
    "district_id": "360226",
    "label": "Ciparahu",
    "value": "Ciparahu"
  },
  {
    "id": "3602262004",
    "district_id": "360226",
    "label": "Pondokpanjang",
    "value": "Pondokpanjang"
  },
  {
    "id": "3602262005",
    "district_id": "360226",
    "label": "Citeupuseun",
    "value": "Citeupuseun"
  },
  {
    "id": "3602262006",
    "district_id": "360226",
    "label": "Lebakpeundeuy",
    "value": "Lebakpeundeuy"
  },
  {
    "id": "3602262007",
    "district_id": "360226",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3602262008",
    "district_id": "360226",
    "label": "Karangkamulyan",
    "value": "Karangkamulyan"
  },
  {
    "id": "3602262009",
    "district_id": "360226",
    "label": "Barunai",
    "value": "Barunai"
  },
  {
    "id": "3602272001",
    "district_id": "360227",
    "label": "Datarcae",
    "value": "Datarcae"
  },
  {
    "id": "3602272002",
    "district_id": "360227",
    "label": "Cirinten",
    "value": "Cirinten"
  },
  {
    "id": "3602272003",
    "district_id": "360227",
    "label": "Karangnunggal",
    "value": "Karangnunggal"
  },
  {
    "id": "3602272004",
    "district_id": "360227",
    "label": "Kadudamas",
    "value": "Kadudamas"
  },
  {
    "id": "3602272005",
    "district_id": "360227",
    "label": "Badur",
    "value": "Badur"
  },
  {
    "id": "3602272006",
    "district_id": "360227",
    "label": "Parakanlima",
    "value": "Parakanlima"
  },
  {
    "id": "3602272007",
    "district_id": "360227",
    "label": "Nanggerang",
    "value": "Nanggerang"
  },
  {
    "id": "3602272008",
    "district_id": "360227",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "3602272009",
    "district_id": "360227",
    "label": "Karoya",
    "value": "Karoya"
  },
  {
    "id": "3602272010",
    "district_id": "360227",
    "label": "Cibarani",
    "value": "Cibarani"
  },
  {
    "id": "3602282001",
    "district_id": "360228",
    "label": "Peucangpari",
    "value": "Peucangpari"
  },
  {
    "id": "3602282002",
    "district_id": "360228",
    "label": "Cibungur",
    "value": "Cibungur"
  },
  {
    "id": "3602282003",
    "district_id": "360228",
    "label": "Mugijaya",
    "value": "Mugijaya"
  },
  {
    "id": "3602282004",
    "district_id": "360228",
    "label": "Cikate",
    "value": "Cikate"
  },
  {
    "id": "3602282005",
    "district_id": "360228",
    "label": "Cigemblong",
    "value": "Cigemblong"
  },
  {
    "id": "3602282006",
    "district_id": "360228",
    "label": "Cikadongdong",
    "value": "Cikadongdong"
  },
  {
    "id": "3602282007",
    "district_id": "360228",
    "label": "Cikaret",
    "value": "Cikaret"
  },
  {
    "id": "3602282008",
    "district_id": "360228",
    "label": "Wangunjaya",
    "value": "Wangunjaya"
  },
  {
    "id": "3603011001",
    "district_id": "360301",
    "label": "Balaraja",
    "value": "Balaraja"
  },
  {
    "id": "3603012003",
    "district_id": "360301",
    "label": "Cangkudu",
    "value": "Cangkudu"
  },
  {
    "id": "3603012005",
    "district_id": "360301",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3603012009",
    "district_id": "360301",
    "label": "Tobat",
    "value": "Tobat"
  },
  {
    "id": "3603012010",
    "district_id": "360301",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3603012011",
    "district_id": "360301",
    "label": "Gembong",
    "value": "Gembong"
  },
  {
    "id": "3603012013",
    "district_id": "360301",
    "label": "Sukamurni",
    "value": "Sukamurni"
  },
  {
    "id": "3603012014",
    "district_id": "360301",
    "label": "Saga",
    "value": "Saga"
  },
  {
    "id": "3603012016",
    "district_id": "360301",
    "label": "Sentul Jaya",
    "value": "Sentul Jaya"
  },
  {
    "id": "3603022001",
    "district_id": "360302",
    "label": "Pangkat",
    "value": "Pangkat"
  },
  {
    "id": "3603022002",
    "district_id": "360302",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3603022004",
    "district_id": "360302",
    "label": "Pasir Muncang",
    "value": "Pasir Muncang"
  },
  {
    "id": "3603022005",
    "district_id": "360302",
    "label": "Sumur Bandung",
    "value": "Sumur Bandung"
  },
  {
    "id": "3603022006",
    "district_id": "360302",
    "label": "Jayanti",
    "value": "Jayanti"
  },
  {
    "id": "3603022007",
    "district_id": "360302",
    "label": "Dangdeur",
    "value": "Dangdeur"
  },
  {
    "id": "3603022008",
    "district_id": "360302",
    "label": "Cikande",
    "value": "Cikande"
  },
  {
    "id": "3603022009",
    "district_id": "360302",
    "label": "Pasir Gintung",
    "value": "Pasir Gintung"
  },
  {
    "id": "3603031001",
    "district_id": "360303",
    "label": "Tigaraksa",
    "value": "Tigaraksa"
  },
  {
    "id": "3603031007",
    "district_id": "360303",
    "label": "Kadu Agung",
    "value": "Kadu Agung"
  },
  {
    "id": "3603032002",
    "district_id": "360303",
    "label": "Pasir Bolang",
    "value": "Pasir Bolang"
  },
  {
    "id": "3603032003",
    "district_id": "360303",
    "label": "Matagara",
    "value": "Matagara"
  },
  {
    "id": "3603032004",
    "district_id": "360303",
    "label": "Pasir Nangka",
    "value": "Pasir Nangka"
  },
  {
    "id": "3603032005",
    "district_id": "360303",
    "label": "Pete",
    "value": "Pete"
  },
  {
    "id": "3603032006",
    "district_id": "360303",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3603032008",
    "district_id": "360303",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "3603032009",
    "district_id": "360303",
    "label": "Cisereh",
    "value": "Cisereh"
  },
  {
    "id": "3603032010",
    "district_id": "360303",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3603032011",
    "district_id": "360303",
    "label": "Cileles",
    "value": "Cileles"
  },
  {
    "id": "3603032012",
    "district_id": "360303",
    "label": "Sodong",
    "value": "Sodong"
  },
  {
    "id": "3603032013",
    "district_id": "360303",
    "label": "Tapos",
    "value": "Tapos"
  },
  {
    "id": "3603032014",
    "district_id": "360303",
    "label": "Bantar Panjang",
    "value": "Bantar Panjang"
  },
  {
    "id": "3603042001",
    "district_id": "360304",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3603042002",
    "district_id": "360304",
    "label": "Jambe",
    "value": "Jambe"
  },
  {
    "id": "3603042003",
    "district_id": "360304",
    "label": "Tipar Raya",
    "value": "Tipar Raya"
  },
  {
    "id": "3603042004",
    "district_id": "360304",
    "label": "Taban",
    "value": "Taban"
  },
  {
    "id": "3603042005",
    "district_id": "360304",
    "label": "Daru",
    "value": "Daru"
  },
  {
    "id": "3603042006",
    "district_id": "360304",
    "label": "Kutruk",
    "value": "Kutruk"
  },
  {
    "id": "3603042007",
    "district_id": "360304",
    "label": "Ranca Buaya",
    "value": "Ranca Buaya"
  },
  {
    "id": "3603042008",
    "district_id": "360304",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3603042009",
    "district_id": "360304",
    "label": "Ancol Pasir",
    "value": "Ancol Pasir"
  },
  {
    "id": "3603042010",
    "district_id": "360304",
    "label": "Pasir Barat",
    "value": "Pasir Barat"
  },
  {
    "id": "3603052001",
    "district_id": "360305",
    "label": "Cisoka",
    "value": "Cisoka"
  },
  {
    "id": "3603052002",
    "district_id": "360305",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3603052003",
    "district_id": "360305",
    "label": "Selapajang",
    "value": "Selapajang"
  },
  {
    "id": "3603052004",
    "district_id": "360305",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3603052006",
    "district_id": "360305",
    "label": "Bojong Loa",
    "value": "Bojong Loa"
  },
  {
    "id": "3603052007",
    "district_id": "360305",
    "label": "Cibugel",
    "value": "Cibugel"
  },
  {
    "id": "3603052008",
    "district_id": "360305",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "3603052009",
    "district_id": "360305",
    "label": "Carenang",
    "value": "Carenang"
  },
  {
    "id": "3603052014",
    "district_id": "360305",
    "label": "Karang Harja",
    "value": "Karang Harja"
  },
  {
    "id": "3603052017",
    "district_id": "360305",
    "label": "Jeungjing",
    "value": "Jeungjing"
  },
  {
    "id": "3603062001",
    "district_id": "360306",
    "label": "Pasir Ampo",
    "value": "Pasir Ampo"
  },
  {
    "id": "3603062003",
    "district_id": "360306",
    "label": "Rancailat",
    "value": "Rancailat"
  },
  {
    "id": "3603062007",
    "district_id": "360306",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3603062009",
    "district_id": "360306",
    "label": "Renged",
    "value": "Renged"
  },
  {
    "id": "3603062011",
    "district_id": "360306",
    "label": "Talok",
    "value": "Talok"
  },
  {
    "id": "3603062013",
    "district_id": "360306",
    "label": "Koper",
    "value": "Koper"
  },
  {
    "id": "3603062014",
    "district_id": "360306",
    "label": "Jengkol",
    "value": "Jengkol"
  },
  {
    "id": "3603062015",
    "district_id": "360306",
    "label": "Patrasana",
    "value": "Patrasana"
  },
  {
    "id": "3603062018",
    "district_id": "360306",
    "label": "Kresek",
    "value": "Kresek"
  },
  {
    "id": "3603072001",
    "district_id": "360307",
    "label": "Kronjo",
    "value": "Kronjo"
  },
  {
    "id": "3603072002",
    "district_id": "360307",
    "label": "Pagenjahan",
    "value": "Pagenjahan"
  },
  {
    "id": "3603072006",
    "district_id": "360307",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "3603072007",
    "district_id": "360307",
    "label": "Muncung",
    "value": "Muncung"
  },
  {
    "id": "3603072008",
    "district_id": "360307",
    "label": "Pagedangan Ilir",
    "value": "Pagedangan Ilir"
  },
  {
    "id": "3603072009",
    "district_id": "360307",
    "label": "Pagedangan Udik",
    "value": "Pagedangan Udik"
  },
  {
    "id": "3603072010",
    "district_id": "360307",
    "label": "Pasilian",
    "value": "Pasilian"
  },
  {
    "id": "3603072013",
    "district_id": "360307",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3603072015",
    "district_id": "360307",
    "label": "Blukbuk",
    "value": "Blukbuk"
  },
  {
    "id": "3603072017",
    "district_id": "360307",
    "label": "Cirumpak",
    "value": "Cirumpak"
  },
  {
    "id": "3603081002",
    "district_id": "360308",
    "label": "Mauk Timur",
    "value": "Mauk Timur"
  },
  {
    "id": "3603082001",
    "district_id": "360308",
    "label": "Mauk Barat",
    "value": "Mauk Barat"
  },
  {
    "id": "3603082003",
    "district_id": "360308",
    "label": "Tegal Kunir Kidul",
    "value": "Tegal Kunir Kidul"
  },
  {
    "id": "3603082004",
    "district_id": "360308",
    "label": "Tegal Kunir Lor",
    "value": "Tegal Kunir Lor"
  },
  {
    "id": "3603082005",
    "district_id": "360308",
    "label": "Sasak",
    "value": "Sasak"
  },
  {
    "id": "3603082006",
    "district_id": "360308",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "3603082007",
    "district_id": "360308",
    "label": "Kedung Dalem",
    "value": "Kedung Dalem"
  },
  {
    "id": "3603082008",
    "district_id": "360308",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "3603082009",
    "district_id": "360308",
    "label": "Tanjung Anom",
    "value": "Tanjung Anom"
  },
  {
    "id": "3603082010",
    "district_id": "360308",
    "label": "Jatiwaringin",
    "value": "Jatiwaringin"
  },
  {
    "id": "3603082011",
    "district_id": "360308",
    "label": "Banyu Asih",
    "value": "Banyu Asih"
  },
  {
    "id": "3603082012",
    "district_id": "360308",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3603092001",
    "district_id": "360309",
    "label": "Patramanggala",
    "value": "Patramanggala"
  },
  {
    "id": "3603092002",
    "district_id": "360309",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3603092003",
    "district_id": "360309",
    "label": "Lontar",
    "value": "Lontar"
  },
  {
    "id": "3603092004",
    "district_id": "360309",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "3603092005",
    "district_id": "360309",
    "label": "Ranca Labuh",
    "value": "Ranca Labuh"
  },
  {
    "id": "3603092006",
    "district_id": "360309",
    "label": "Klebet",
    "value": "Klebet"
  },
  {
    "id": "3603092007",
    "district_id": "360309",
    "label": "Legok Suka Maju",
    "value": "Legok Suka Maju"
  },
  {
    "id": "3603102001",
    "district_id": "360310",
    "label": "Sukadiri",
    "value": "Sukadiri"
  },
  {
    "id": "3603102002",
    "district_id": "360310",
    "label": "Buaran Jati",
    "value": "Buaran Jati"
  },
  {
    "id": "3603102003",
    "district_id": "360310",
    "label": "Rawa Kidang",
    "value": "Rawa Kidang"
  },
  {
    "id": "3603102004",
    "district_id": "360310",
    "label": "Pekayon",
    "value": "Pekayon"
  },
  {
    "id": "3603102005",
    "district_id": "360310",
    "label": "Karang Serang",
    "value": "Karang Serang"
  },
  {
    "id": "3603102006",
    "district_id": "360310",
    "label": "Kosambi",
    "value": "Kosambi"
  },
  {
    "id": "3603102007",
    "district_id": "360310",
    "label": "Mekar Kondang",
    "value": "Mekar Kondang"
  },
  {
    "id": "3603102008",
    "district_id": "360310",
    "label": "Gintung",
    "value": "Gintung"
  },
  {
    "id": "3603111010",
    "district_id": "360311",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3603112001",
    "district_id": "360311",
    "label": "Rajeg",
    "value": "Rajeg"
  },
  {
    "id": "3603112002",
    "district_id": "360311",
    "label": "Rajeg Mulya",
    "value": "Rajeg Mulya"
  },
  {
    "id": "3603112003",
    "district_id": "360311",
    "label": "Pangarengan",
    "value": "Pangarengan"
  },
  {
    "id": "3603112005",
    "district_id": "360311",
    "label": "Jambu Karya",
    "value": "Jambu Karya"
  },
  {
    "id": "3603112006",
    "district_id": "360311",
    "label": "Lembangsari",
    "value": "Lembangsari"
  },
  {
    "id": "3603112007",
    "district_id": "360311",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3603112008",
    "district_id": "360311",
    "label": "Tanjakan",
    "value": "Tanjakan"
  },
  {
    "id": "3603112009",
    "district_id": "360311",
    "label": "Tanjakan Mekar",
    "value": "Tanjakan Mekar"
  },
  {
    "id": "3603112011",
    "district_id": "360311",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3603112012",
    "district_id": "360311",
    "label": "Ranca Bango",
    "value": "Ranca Bango"
  },
  {
    "id": "3603112013",
    "district_id": "360311",
    "label": "Daon",
    "value": "Daon"
  },
  {
    "id": "3603112014",
    "district_id": "360311",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3603121005",
    "district_id": "360312",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3603121010",
    "district_id": "360312",
    "label": "Kutabumi",
    "value": "Kutabumi"
  },
  {
    "id": "3603121012",
    "district_id": "360312",
    "label": "Kuta Jaya",
    "value": "Kuta Jaya"
  },
  {
    "id": "3603121014",
    "district_id": "360312",
    "label": "Kuta Baru",
    "value": "Kuta Baru"
  },
  {
    "id": "3603122001",
    "district_id": "360312",
    "label": "Pasar Kemis",
    "value": "Pasar Kemis"
  },
  {
    "id": "3603122008",
    "district_id": "360312",
    "label": "Sukamantri",
    "value": "Sukamantri"
  },
  {
    "id": "3603122011",
    "district_id": "360312",
    "label": "Pangadegan",
    "value": "Pangadegan"
  },
  {
    "id": "3603122013",
    "district_id": "360312",
    "label": "Gelam Jaya",
    "value": "Gelam Jaya"
  },
  {
    "id": "3603122015",
    "district_id": "360312",
    "label": "Suka Asih",
    "value": "Suka Asih"
  },
  {
    "id": "3603132001",
    "district_id": "360313",
    "label": "Teluknaga",
    "value": "Teluknaga"
  },
  {
    "id": "3603132002",
    "district_id": "360313",
    "label": "Bojong Renged",
    "value": "Bojong Renged"
  },
  {
    "id": "3603132003",
    "district_id": "360313",
    "label": "Babakan Asem",
    "value": "Babakan Asem"
  },
  {
    "id": "3603132004",
    "district_id": "360313",
    "label": "Keboncau",
    "value": "Keboncau"
  },
  {
    "id": "3603132005",
    "district_id": "360313",
    "label": "Pangkalan",
    "value": "Pangkalan"
  },
  {
    "id": "3603132006",
    "district_id": "360313",
    "label": "Kampung Melayu Timur",
    "value": "Kampung Melayu Timur"
  },
  {
    "id": "3603132007",
    "district_id": "360313",
    "label": "Kampung Melayu Barat",
    "value": "Kampung Melayu Barat"
  },
  {
    "id": "3603132008",
    "district_id": "360313",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "3603132009",
    "district_id": "360313",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "3603132010",
    "district_id": "360313",
    "label": "Tanjung Pasir",
    "value": "Tanjung Pasir"
  },
  {
    "id": "3603132011",
    "district_id": "360313",
    "label": "Tegal Angus",
    "value": "Tegal Angus"
  },
  {
    "id": "3603132012",
    "district_id": "360313",
    "label": "Tanjung Burung",
    "value": "Tanjung Burung"
  },
  {
    "id": "3603132013",
    "district_id": "360313",
    "label": "Kampung Besar",
    "value": "Kampung Besar"
  },
  {
    "id": "3603141001",
    "district_id": "360314",
    "label": "Kosambi Barat",
    "value": "Kosambi Barat"
  },
  {
    "id": "3603141003",
    "district_id": "360314",
    "label": "Salembaran Jaya",
    "value": "Salembaran Jaya"
  },
  {
    "id": "3603141010",
    "district_id": "360314",
    "label": "Dadap",
    "value": "Dadap"
  },
  {
    "id": "3603142002",
    "district_id": "360314",
    "label": "Kosambi Timur",
    "value": "Kosambi Timur"
  },
  {
    "id": "3603142004",
    "district_id": "360314",
    "label": "Salembaran Jati",
    "value": "Salembaran Jati"
  },
  {
    "id": "3603142005",
    "district_id": "360314",
    "label": "Ranga Rengas",
    "value": "Ranga Rengas"
  },
  {
    "id": "3603142006",
    "district_id": "360314",
    "label": "Rawa Burung",
    "value": "Rawa Burung"
  },
  {
    "id": "3603142007",
    "district_id": "360314",
    "label": "Cengklong",
    "value": "Cengklong"
  },
  {
    "id": "3603142008",
    "district_id": "360314",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "3603142009",
    "district_id": "360314",
    "label": "Jati Mulya",
    "value": "Jati Mulya"
  },
  {
    "id": "3603151001",
    "district_id": "360315",
    "label": "Pakuhaji",
    "value": "Pakuhaji"
  },
  {
    "id": "3603152002",
    "district_id": "360315",
    "label": "Paku Alam",
    "value": "Paku Alam"
  },
  {
    "id": "3603152003",
    "district_id": "360315",
    "label": "Bonasari",
    "value": "Bonasari"
  },
  {
    "id": "3603152004",
    "district_id": "360315",
    "label": "Rawa Boni",
    "value": "Rawa Boni"
  },
  {
    "id": "3603152005",
    "district_id": "360315",
    "label": "Buaran Mangga",
    "value": "Buaran Mangga"
  },
  {
    "id": "3603152006",
    "district_id": "360315",
    "label": "Buaran Bambu",
    "value": "Buaran Bambu"
  },
  {
    "id": "3603152007",
    "district_id": "360315",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "3603152008",
    "district_id": "360315",
    "label": "Kohod",
    "value": "Kohod"
  },
  {
    "id": "3603152009",
    "district_id": "360315",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "3603152010",
    "district_id": "360315",
    "label": "Sukawali",
    "value": "Sukawali"
  },
  {
    "id": "3603152011",
    "district_id": "360315",
    "label": "Surya Bahari",
    "value": "Surya Bahari"
  },
  {
    "id": "3603152012",
    "district_id": "360315",
    "label": "Kiara Payung",
    "value": "Kiara Payung"
  },
  {
    "id": "3603152013",
    "district_id": "360315",
    "label": "Laksana",
    "value": "Laksana"
  },
  {
    "id": "3603152014",
    "district_id": "360315",
    "label": "Gaga",
    "value": "Gaga"
  },
  {
    "id": "3603161001",
    "district_id": "360316",
    "label": "Sepatan",
    "value": "Sepatan"
  },
  {
    "id": "3603162002",
    "district_id": "360316",
    "label": "Karet",
    "value": "Karet"
  },
  {
    "id": "3603162003",
    "district_id": "360316",
    "label": "Kayu Agung",
    "value": "Kayu Agung"
  },
  {
    "id": "3603162004",
    "district_id": "360316",
    "label": "Kayu Bongkok",
    "value": "Kayu Bongkok"
  },
  {
    "id": "3603162008",
    "district_id": "360316",
    "label": "Pondok Jaya",
    "value": "Pondok Jaya"
  },
  {
    "id": "3603162011",
    "district_id": "360316",
    "label": "Pisangan Jaya",
    "value": "Pisangan Jaya"
  },
  {
    "id": "3603162012",
    "district_id": "360316",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "3603162015",
    "district_id": "360316",
    "label": "Sarakan",
    "value": "Sarakan"
  },
  {
    "id": "3603171001",
    "district_id": "360317",
    "label": "Curug Kulon",
    "value": "Curug Kulon"
  },
  {
    "id": "3603171005",
    "district_id": "360317",
    "label": "Sukabakti",
    "value": "Sukabakti"
  },
  {
    "id": "3603171006",
    "district_id": "360317",
    "label": "Binong",
    "value": "Binong"
  },
  {
    "id": "3603172002",
    "district_id": "360317",
    "label": "Curug Wetan",
    "value": "Curug Wetan"
  },
  {
    "id": "3603172003",
    "district_id": "360317",
    "label": "Kadu",
    "value": "Kadu"
  },
  {
    "id": "3603172004",
    "district_id": "360317",
    "label": "Kadu Jaya",
    "value": "Kadu Jaya"
  },
  {
    "id": "3603172010",
    "district_id": "360317",
    "label": "Cukanggalih",
    "value": "Cukanggalih"
  },
  {
    "id": "3603181001",
    "district_id": "360318",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3603181003",
    "district_id": "360318",
    "label": "Bunder",
    "value": "Bunder"
  },
  {
    "id": "3603182002",
    "district_id": "360318",
    "label": "Cibadak",
    "value": "Cibadak"
  },
  {
    "id": "3603182004",
    "district_id": "360318",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3603182005",
    "district_id": "360318",
    "label": "Talagasari",
    "value": "Talagasari"
  },
  {
    "id": "3603182006",
    "district_id": "360318",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3603182007",
    "district_id": "360318",
    "label": "Cikupa",
    "value": "Cikupa"
  },
  {
    "id": "3603182008",
    "district_id": "360318",
    "label": "Sukanagara",
    "value": "Sukanagara"
  },
  {
    "id": "3603182009",
    "district_id": "360318",
    "label": "Bitung Jaya",
    "value": "Bitung Jaya"
  },
  {
    "id": "3603182010",
    "district_id": "360318",
    "label": "Pasir Gadung",
    "value": "Pasir Gadung"
  },
  {
    "id": "3603182011",
    "district_id": "360318",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "3603182012",
    "district_id": "360318",
    "label": "Pasir Jaya",
    "value": "Pasir Jaya"
  },
  {
    "id": "3603182013",
    "district_id": "360318",
    "label": "Budi Mulya",
    "value": "Budi Mulya"
  },
  {
    "id": "3603182014",
    "district_id": "360318",
    "label": "Bojong",
    "value": "Bojong"
  },
  {
    "id": "3603191002",
    "district_id": "360319",
    "label": "Mekar Bakti",
    "value": "Mekar Bakti"
  },
  {
    "id": "3603192001",
    "district_id": "360319",
    "label": "Ranca Iyuh",
    "value": "Ranca Iyuh"
  },
  {
    "id": "3603192003",
    "district_id": "360319",
    "label": "Peusar",
    "value": "Peusar"
  },
  {
    "id": "3603192004",
    "district_id": "360319",
    "label": "Ranca Kalapa",
    "value": "Ranca Kalapa"
  },
  {
    "id": "3603192005",
    "district_id": "360319",
    "label": "Serdang Kulon",
    "value": "Serdang Kulon"
  },
  {
    "id": "3603192006",
    "district_id": "360319",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "3603192007",
    "district_id": "360319",
    "label": "Ciakar",
    "value": "Ciakar"
  },
  {
    "id": "3603192008",
    "district_id": "360319",
    "label": "Panongan",
    "value": "Panongan"
  },
  {
    "id": "3603201011",
    "district_id": "360320",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3603202002",
    "district_id": "360320",
    "label": "Caringin",
    "value": "Caringin"
  },
  {
    "id": "3603202003",
    "district_id": "360320",
    "label": "Serdang Wetan",
    "value": "Serdang Wetan"
  },
  {
    "id": "3603202004",
    "district_id": "360320",
    "label": "Babat",
    "value": "Babat"
  },
  {
    "id": "3603202005",
    "district_id": "360320",
    "label": "Ciangir",
    "value": "Ciangir"
  },
  {
    "id": "3603202006",
    "district_id": "360320",
    "label": "Legok",
    "value": "Legok"
  },
  {
    "id": "3603202007",
    "district_id": "360320",
    "label": "Palasari",
    "value": "Palasari"
  },
  {
    "id": "3603202008",
    "district_id": "360320",
    "label": "Bojongkamal",
    "value": "Bojongkamal"
  },
  {
    "id": "3603202009",
    "district_id": "360320",
    "label": "Rancagong",
    "value": "Rancagong"
  },
  {
    "id": "3603202010",
    "district_id": "360320",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3603202012",
    "district_id": "360320",
    "label": "Cirarab",
    "value": "Cirarab"
  },
  {
    "id": "3603221004",
    "district_id": "360322",
    "label": "Medang",
    "value": "Medang"
  },
  {
    "id": "3603222002",
    "district_id": "360322",
    "label": "Cicalengka",
    "value": "Cicalengka"
  },
  {
    "id": "3603222003",
    "district_id": "360322",
    "label": "Pagedangan",
    "value": "Pagedangan"
  },
  {
    "id": "3603222005",
    "district_id": "360322",
    "label": "Cijantra",
    "value": "Cijantra"
  },
  {
    "id": "3603222006",
    "district_id": "360322",
    "label": "Lengkong Kulon",
    "value": "Lengkong Kulon"
  },
  {
    "id": "3603222007",
    "district_id": "360322",
    "label": "Situ Gadung",
    "value": "Situ Gadung"
  },
  {
    "id": "3603222008",
    "district_id": "360322",
    "label": "Jatake",
    "value": "Jatake"
  },
  {
    "id": "3603222009",
    "district_id": "360322",
    "label": "Cihuni",
    "value": "Cihuni"
  },
  {
    "id": "3603222010",
    "district_id": "360322",
    "label": "Kadu Sirung",
    "value": "Kadu Sirung"
  },
  {
    "id": "3603222011",
    "district_id": "360322",
    "label": "Malang Nengah",
    "value": "Malang Nengah"
  },
  {
    "id": "3603222012",
    "district_id": "360322",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3603231001",
    "district_id": "360323",
    "label": "Cisauk",
    "value": "Cisauk"
  },
  {
    "id": "3603232003",
    "district_id": "360323",
    "label": "Mekar Wangi",
    "value": "Mekar Wangi"
  },
  {
    "id": "3603232005",
    "district_id": "360323",
    "label": "Suradita",
    "value": "Suradita"
  },
  {
    "id": "3603232006",
    "district_id": "360323",
    "label": "Sampora",
    "value": "Sampora"
  },
  {
    "id": "3603232009",
    "district_id": "360323",
    "label": "Dangdang",
    "value": "Dangdang"
  },
  {
    "id": "3603232011",
    "district_id": "360323",
    "label": "Cibogo",
    "value": "Cibogo"
  },
  {
    "id": "3603272001",
    "district_id": "360327",
    "label": "Benda",
    "value": "Benda"
  },
  {
    "id": "3603272002",
    "district_id": "360327",
    "label": "Sukamulya",
    "value": "Sukamulya"
  },
  {
    "id": "3603272003",
    "district_id": "360327",
    "label": "Kaliasin",
    "value": "Kaliasin"
  },
  {
    "id": "3603272004",
    "district_id": "360327",
    "label": "Buniayu",
    "value": "Buniayu"
  },
  {
    "id": "3603272005",
    "district_id": "360327",
    "label": "Parahu",
    "value": "Parahu"
  },
  {
    "id": "3603272006",
    "district_id": "360327",
    "label": "Merak",
    "value": "Merak"
  },
  {
    "id": "3603272007",
    "district_id": "360327",
    "label": "Bunar",
    "value": "Bunar"
  },
  {
    "id": "3603272008",
    "district_id": "360327",
    "label": "Kubang",
    "value": "Kubang"
  },
  {
    "id": "3603281001",
    "district_id": "360328",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "3603281002",
    "district_id": "360328",
    "label": "Bencongan",
    "value": "Bencongan"
  },
  {
    "id": "3603281003",
    "district_id": "360328",
    "label": "Bencongan Indah",
    "value": "Bencongan Indah"
  },
  {
    "id": "3603281004",
    "district_id": "360328",
    "label": "Pakulonan Barat",
    "value": "Pakulonan Barat"
  },
  {
    "id": "3603281005",
    "district_id": "360328",
    "label": "Bojong Nangka",
    "value": "Bojong Nangka"
  },
  {
    "id": "3603282006",
    "district_id": "360328",
    "label": "Curug Sangereng",
    "value": "Curug Sangereng"
  },
  {
    "id": "3603292001",
    "district_id": "360329",
    "label": "Sindang Jaya",
    "value": "Sindang Jaya"
  },
  {
    "id": "3603292002",
    "district_id": "360329",
    "label": "Wanakerta",
    "value": "Wanakerta"
  },
  {
    "id": "3603292003",
    "district_id": "360329",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "3603292004",
    "district_id": "360329",
    "label": "Sindangasih",
    "value": "Sindangasih"
  },
  {
    "id": "3603292005",
    "district_id": "360329",
    "label": "Sindangpanon",
    "value": "Sindangpanon"
  },
  {
    "id": "3603292006",
    "district_id": "360329",
    "label": "Sindangsono",
    "value": "Sindangsono"
  },
  {
    "id": "3603292007",
    "district_id": "360329",
    "label": "Badak Anom",
    "value": "Badak Anom"
  },
  {
    "id": "3603302001",
    "district_id": "360330",
    "label": "Kedaung Barat",
    "value": "Kedaung Barat"
  },
  {
    "id": "3603302002",
    "district_id": "360330",
    "label": "Lebak Wangi",
    "value": "Lebak Wangi"
  },
  {
    "id": "3603302003",
    "district_id": "360330",
    "label": "Jati Mulya",
    "value": "Jati Mulya"
  },
  {
    "id": "3603302004",
    "district_id": "360330",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3603302005",
    "district_id": "360330",
    "label": "Gempol Sari",
    "value": "Gempol Sari"
  },
  {
    "id": "3603302006",
    "district_id": "360330",
    "label": "Kampung Kelor",
    "value": "Kampung Kelor"
  },
  {
    "id": "3603302007",
    "district_id": "360330",
    "label": "Pondok Kelor",
    "value": "Pondok Kelor"
  },
  {
    "id": "3603302008",
    "district_id": "360330",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "3603312001",
    "district_id": "360331",
    "label": "Solear",
    "value": "Solear"
  },
  {
    "id": "3603312002",
    "district_id": "360331",
    "label": "Cikuya",
    "value": "Cikuya"
  },
  {
    "id": "3603312003",
    "district_id": "360331",
    "label": "Cikasungka",
    "value": "Cikasungka"
  },
  {
    "id": "3603312004",
    "district_id": "360331",
    "label": "Cireundeu",
    "value": "Cireundeu"
  },
  {
    "id": "3603312005",
    "district_id": "360331",
    "label": "Cikareo",
    "value": "Cikareo"
  },
  {
    "id": "3603312006",
    "district_id": "360331",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3603312007",
    "district_id": "360331",
    "label": "Munjul",
    "value": "Munjul"
  },
  {
    "id": "3603322001",
    "district_id": "360332",
    "label": "Gunung Kaler",
    "value": "Gunung Kaler"
  },
  {
    "id": "3603322002",
    "district_id": "360332",
    "label": "Sidoko",
    "value": "Sidoko"
  },
  {
    "id": "3603322003",
    "district_id": "360332",
    "label": "Rancagede",
    "value": "Rancagede"
  },
  {
    "id": "3603322004",
    "district_id": "360332",
    "label": "Kedung",
    "value": "Kedung"
  },
  {
    "id": "3603322005",
    "district_id": "360332",
    "label": "Cipaeh",
    "value": "Cipaeh"
  },
  {
    "id": "3603322006",
    "district_id": "360332",
    "label": "Onyam",
    "value": "Onyam"
  },
  {
    "id": "3603322007",
    "district_id": "360332",
    "label": "Tamiang",
    "value": "Tamiang"
  },
  {
    "id": "3603322008",
    "district_id": "360332",
    "label": "Kandawati",
    "value": "Kandawati"
  },
  {
    "id": "3603322009",
    "district_id": "360332",
    "label": "Cibetok",
    "value": "Cibetok"
  },
  {
    "id": "3603332001",
    "district_id": "360333",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "3603332002",
    "district_id": "360333",
    "label": "Kedaung",
    "value": "Kedaung"
  },
  {
    "id": "3603332003",
    "district_id": "360333",
    "label": "Cijeruk",
    "value": "Cijeruk"
  },
  {
    "id": "3603332004",
    "district_id": "360333",
    "label": "Waliwis",
    "value": "Waliwis"
  },
  {
    "id": "3603332005",
    "district_id": "360333",
    "label": "Klutuk",
    "value": "Klutuk"
  },
  {
    "id": "3603332006",
    "district_id": "360333",
    "label": "Jenggot",
    "value": "Jenggot"
  },
  {
    "id": "3603332007",
    "district_id": "360333",
    "label": "Kosambi Dalam",
    "value": "Kosambi Dalam"
  },
  {
    "id": "3603332008",
    "district_id": "360333",
    "label": "Gandaria",
    "value": "Gandaria"
  },
  {
    "id": "3604052001",
    "district_id": "360405",
    "label": "Kramatwatu",
    "value": "Kramatwatu"
  },
  {
    "id": "3604052002",
    "district_id": "360405",
    "label": "Margasana",
    "value": "Margasana"
  },
  {
    "id": "3604052003",
    "district_id": "360405",
    "label": "Pejaten",
    "value": "Pejaten"
  },
  {
    "id": "3604052004",
    "district_id": "360405",
    "label": "Toyomerto",
    "value": "Toyomerto"
  },
  {
    "id": "3604052005",
    "district_id": "360405",
    "label": "Harjatani",
    "value": "Harjatani"
  },
  {
    "id": "3604052006",
    "district_id": "360405",
    "label": "Serdang",
    "value": "Serdang"
  },
  {
    "id": "3604052007",
    "district_id": "360405",
    "label": "Terate",
    "value": "Terate"
  },
  {
    "id": "3604052008",
    "district_id": "360405",
    "label": "Tonjong",
    "value": "Tonjong"
  },
  {
    "id": "3604052009",
    "district_id": "360405",
    "label": "Pamengkang",
    "value": "Pamengkang"
  },
  {
    "id": "3604052010",
    "district_id": "360405",
    "label": "Pegadingan",
    "value": "Pegadingan"
  },
  {
    "id": "3604052011",
    "district_id": "360405",
    "label": "Lebakwana",
    "value": "Lebakwana"
  },
  {
    "id": "3604052012",
    "district_id": "360405",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "3604052013",
    "district_id": "360405",
    "label": "Pelamunan",
    "value": "Pelamunan"
  },
  {
    "id": "3604052014",
    "district_id": "360405",
    "label": "Teluk Terate",
    "value": "Teluk Terate"
  },
  {
    "id": "3604052015",
    "district_id": "360405",
    "label": "Margatani",
    "value": "Margatani"
  },
  {
    "id": "3604062001",
    "district_id": "360406",
    "label": "Waringinkurung",
    "value": "Waringinkurung"
  },
  {
    "id": "3604062002",
    "district_id": "360406",
    "label": "Talaga Luhur",
    "value": "Talaga Luhur"
  },
  {
    "id": "3604062003",
    "district_id": "360406",
    "label": "Binangun",
    "value": "Binangun"
  },
  {
    "id": "3604062004",
    "district_id": "360406",
    "label": "Melati",
    "value": "Melati"
  },
  {
    "id": "3604062005",
    "district_id": "360406",
    "label": "Sasahan",
    "value": "Sasahan"
  },
  {
    "id": "3604062006",
    "district_id": "360406",
    "label": "Suka Dalem",
    "value": "Suka Dalem"
  },
  {
    "id": "3604062007",
    "district_id": "360406",
    "label": "Sukabares",
    "value": "Sukabares"
  },
  {
    "id": "3604062008",
    "district_id": "360406",
    "label": "Sambilawang",
    "value": "Sambilawang"
  },
  {
    "id": "3604062009",
    "district_id": "360406",
    "label": "Sampir",
    "value": "Sampir"
  },
  {
    "id": "3604062010",
    "district_id": "360406",
    "label": "Cokopsulanjana",
    "value": "Cokopsulanjana"
  },
  {
    "id": "3604062011",
    "district_id": "360406",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3604072001",
    "district_id": "360407",
    "label": "Bojonegara",
    "value": "Bojonegara"
  },
  {
    "id": "3604072002",
    "district_id": "360407",
    "label": "Mangkunegara",
    "value": "Mangkunegara"
  },
  {
    "id": "3604072003",
    "district_id": "360407",
    "label": "Wanakarta",
    "value": "Wanakarta"
  },
  {
    "id": "3604072004",
    "district_id": "360407",
    "label": "Karang Kepuh",
    "value": "Karang Kepuh"
  },
  {
    "id": "3604072005",
    "district_id": "360407",
    "label": "Lambangsari",
    "value": "Lambangsari"
  },
  {
    "id": "3604072006",
    "district_id": "360407",
    "label": "Kertasana",
    "value": "Kertasana"
  },
  {
    "id": "3604072007",
    "district_id": "360407",
    "label": "Margagiri",
    "value": "Margagiri"
  },
  {
    "id": "3604072008",
    "district_id": "360407",
    "label": "Ukirsari",
    "value": "Ukirsari"
  },
  {
    "id": "3604072009",
    "district_id": "360407",
    "label": "Pakuncen",
    "value": "Pakuncen"
  },
  {
    "id": "3604072010",
    "district_id": "360407",
    "label": "Pangarengan",
    "value": "Pangarengan"
  },
  {
    "id": "3604072011",
    "district_id": "360407",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "3604082001",
    "district_id": "360408",
    "label": "Pulo Ampel",
    "value": "Pulo Ampel"
  },
  {
    "id": "3604082002",
    "district_id": "360408",
    "label": "Sumuranja",
    "value": "Sumuranja"
  },
  {
    "id": "3604082003",
    "district_id": "360408",
    "label": "Mangunreja",
    "value": "Mangunreja"
  },
  {
    "id": "3604082004",
    "district_id": "360408",
    "label": "Gedung Soka",
    "value": "Gedung Soka"
  },
  {
    "id": "3604082005",
    "district_id": "360408",
    "label": "Salira",
    "value": "Salira"
  },
  {
    "id": "3604082006",
    "district_id": "360408",
    "label": "Argawana",
    "value": "Argawana"
  },
  {
    "id": "3604082007",
    "district_id": "360408",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "3604082008",
    "district_id": "360408",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3604082009",
    "district_id": "360408",
    "label": "Pulo Panjang",
    "value": "Pulo Panjang"
  },
  {
    "id": "3604092001",
    "district_id": "360409",
    "label": "Ciruas",
    "value": "Ciruas"
  },
  {
    "id": "3604092002",
    "district_id": "360409",
    "label": "Citerep",
    "value": "Citerep"
  },
  {
    "id": "3604092003",
    "district_id": "360409",
    "label": "Pulo",
    "value": "Pulo"
  },
  {
    "id": "3604092004",
    "district_id": "360409",
    "label": "Kadikaran",
    "value": "Kadikaran"
  },
  {
    "id": "3604092005",
    "district_id": "360409",
    "label": "Kepandean",
    "value": "Kepandean"
  },
  {
    "id": "3604092006",
    "district_id": "360409",
    "label": "Gosara",
    "value": "Gosara"
  },
  {
    "id": "3604092009",
    "district_id": "360409",
    "label": "Bumijaya",
    "value": "Bumijaya"
  },
  {
    "id": "3604092010",
    "district_id": "360409",
    "label": "Penggalang",
    "value": "Penggalang"
  },
  {
    "id": "3604092011",
    "district_id": "360409",
    "label": "Pamong",
    "value": "Pamong"
  },
  {
    "id": "3604092012",
    "district_id": "360409",
    "label": "Cigelam",
    "value": "Cigelam"
  },
  {
    "id": "3604092013",
    "district_id": "360409",
    "label": "Singamerta",
    "value": "Singamerta"
  },
  {
    "id": "3604092014",
    "district_id": "360409",
    "label": "Ranjeng",
    "value": "Ranjeng"
  },
  {
    "id": "3604092015",
    "district_id": "360409",
    "label": "Beberan",
    "value": "Beberan"
  },
  {
    "id": "3604092016",
    "district_id": "360409",
    "label": "Kaserangan",
    "value": "Kaserangan"
  },
  {
    "id": "3604092017",
    "district_id": "360409",
    "label": "Pelawad",
    "value": "Pelawad"
  },
  {
    "id": "3604112001",
    "district_id": "360411",
    "label": "Kragilan",
    "value": "Kragilan"
  },
  {
    "id": "3604112002",
    "district_id": "360411",
    "label": "Silebu",
    "value": "Silebu"
  },
  {
    "id": "3604112003",
    "district_id": "360411",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "3604112004",
    "district_id": "360411",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "3604112005",
    "district_id": "360411",
    "label": "Undar Andir",
    "value": "Undar Andir"
  },
  {
    "id": "3604112006",
    "district_id": "360411",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3604112007",
    "district_id": "360411",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "3604112008",
    "district_id": "360411",
    "label": "Jeruk Tipis",
    "value": "Jeruk Tipis"
  },
  {
    "id": "3604112011",
    "district_id": "360411",
    "label": "Kendayakan",
    "value": "Kendayakan"
  },
  {
    "id": "3604112012",
    "district_id": "360411",
    "label": "Tegalmaja",
    "value": "Tegalmaja"
  },
  {
    "id": "3604112013",
    "district_id": "360411",
    "label": "Cisait",
    "value": "Cisait"
  },
  {
    "id": "3604112014",
    "district_id": "360411",
    "label": "Kramatjati",
    "value": "Kramatjati"
  },
  {
    "id": "3604122001",
    "district_id": "360412",
    "label": "Pontang",
    "value": "Pontang"
  },
  {
    "id": "3604122002",
    "district_id": "360412",
    "label": "Sukanegara",
    "value": "Sukanegara"
  },
  {
    "id": "3604122003",
    "district_id": "360412",
    "label": "Linduk",
    "value": "Linduk"
  },
  {
    "id": "3604122004",
    "district_id": "360412",
    "label": "Pulokencana",
    "value": "Pulokencana"
  },
  {
    "id": "3604122006",
    "district_id": "360412",
    "label": "Kelapian",
    "value": "Kelapian"
  },
  {
    "id": "3604122008",
    "district_id": "360412",
    "label": "Kubang Puji",
    "value": "Kubang Puji"
  },
  {
    "id": "3604122009",
    "district_id": "360412",
    "label": "Domas",
    "value": "Domas"
  },
  {
    "id": "3604122010",
    "district_id": "360412",
    "label": "Singarajan",
    "value": "Singarajan"
  },
  {
    "id": "3604122011",
    "district_id": "360412",
    "label": "Kaserangan",
    "value": "Kaserangan"
  },
  {
    "id": "3604122012",
    "district_id": "360412",
    "label": "Wanayasa",
    "value": "Wanayasa"
  },
  {
    "id": "3604122013",
    "district_id": "360412",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "3604132001",
    "district_id": "360413",
    "label": "Tirtayasa",
    "value": "Tirtayasa"
  },
  {
    "id": "3604132002",
    "district_id": "360413",
    "label": "Samparwadi",
    "value": "Samparwadi"
  },
  {
    "id": "3604132003",
    "district_id": "360413",
    "label": "Kamanisan",
    "value": "Kamanisan"
  },
  {
    "id": "3604132004",
    "district_id": "360413",
    "label": "Pontang Legon",
    "value": "Pontang Legon"
  },
  {
    "id": "3604132005",
    "district_id": "360413",
    "label": "Kebon",
    "value": "Kebon"
  },
  {
    "id": "3604132006",
    "district_id": "360413",
    "label": "Sujung",
    "value": "Sujung"
  },
  {
    "id": "3604132007",
    "district_id": "360413",
    "label": "Lontar",
    "value": "Lontar"
  },
  {
    "id": "3604132008",
    "district_id": "360413",
    "label": "Susukan",
    "value": "Susukan"
  },
  {
    "id": "3604132009",
    "district_id": "360413",
    "label": "Wargasara",
    "value": "Wargasara"
  },
  {
    "id": "3604132010",
    "district_id": "360413",
    "label": "Laban",
    "value": "Laban"
  },
  {
    "id": "3604132011",
    "district_id": "360413",
    "label": "Tengkurak",
    "value": "Tengkurak"
  },
  {
    "id": "3604132012",
    "district_id": "360413",
    "label": "Alang-alang",
    "value": "Alang-alang"
  },
  {
    "id": "3604132013",
    "district_id": "360413",
    "label": "Kebuyutan",
    "value": "Kebuyutan"
  },
  {
    "id": "3604132014",
    "district_id": "360413",
    "label": "Puser",
    "value": "Puser"
  },
  {
    "id": "3604142001",
    "district_id": "360414",
    "label": "Tanara",
    "value": "Tanara"
  },
  {
    "id": "3604142002",
    "district_id": "360414",
    "label": "Cerukcuk",
    "value": "Cerukcuk"
  },
  {
    "id": "3604142003",
    "district_id": "360414",
    "label": "Tenjoayu",
    "value": "Tenjoayu"
  },
  {
    "id": "3604142004",
    "district_id": "360414",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3604142005",
    "district_id": "360414",
    "label": "Lempuyang",
    "value": "Lempuyang"
  },
  {
    "id": "3604142006",
    "district_id": "360414",
    "label": "Siremen",
    "value": "Siremen"
  },
  {
    "id": "3604142007",
    "district_id": "360414",
    "label": "Bendung",
    "value": "Bendung"
  },
  {
    "id": "3604142008",
    "district_id": "360414",
    "label": "Pedaleman",
    "value": "Pedaleman"
  },
  {
    "id": "3604142009",
    "district_id": "360414",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3604152001",
    "district_id": "360415",
    "label": "Cikande",
    "value": "Cikande"
  },
  {
    "id": "3604152002",
    "district_id": "360415",
    "label": "Leuwi Limus",
    "value": "Leuwi Limus"
  },
  {
    "id": "3604152003",
    "district_id": "360415",
    "label": "Nambo Udik",
    "value": "Nambo Udik"
  },
  {
    "id": "3604152004",
    "district_id": "360415",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "3604152005",
    "district_id": "360415",
    "label": "Koper",
    "value": "Koper"
  },
  {
    "id": "3604152006",
    "district_id": "360415",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "3604152007",
    "district_id": "360415",
    "label": "Julang",
    "value": "Julang"
  },
  {
    "id": "3604152008",
    "district_id": "360415",
    "label": "Sukatani",
    "value": "Sukatani"
  },
  {
    "id": "3604152009",
    "district_id": "360415",
    "label": "Situterate",
    "value": "Situterate"
  },
  {
    "id": "3604152010",
    "district_id": "360415",
    "label": "Kamurang",
    "value": "Kamurang"
  },
  {
    "id": "3604152011",
    "district_id": "360415",
    "label": "Gembor Udik",
    "value": "Gembor Udik"
  },
  {
    "id": "3604152012",
    "district_id": "360415",
    "label": "Songgomjaya",
    "value": "Songgomjaya"
  },
  {
    "id": "3604152013",
    "district_id": "360415",
    "label": "Cikande Permai",
    "value": "Cikande Permai"
  },
  {
    "id": "3604162001",
    "district_id": "360416",
    "label": "Kibin",
    "value": "Kibin"
  },
  {
    "id": "3604162002",
    "district_id": "360416",
    "label": "Ketos",
    "value": "Ketos"
  },
  {
    "id": "3604162003",
    "district_id": "360416",
    "label": "Cijeruk",
    "value": "Cijeruk"
  },
  {
    "id": "3604162004",
    "district_id": "360416",
    "label": "Nagara",
    "value": "Nagara"
  },
  {
    "id": "3604162005",
    "district_id": "360416",
    "label": "Nambo Ilir",
    "value": "Nambo Ilir"
  },
  {
    "id": "3604162006",
    "district_id": "360416",
    "label": "Barengkok",
    "value": "Barengkok"
  },
  {
    "id": "3604162007",
    "district_id": "360416",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3604162008",
    "district_id": "360416",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "3604162009",
    "district_id": "360416",
    "label": "Ciagel",
    "value": "Ciagel"
  },
  {
    "id": "3604172001",
    "district_id": "360417",
    "label": "Carenang",
    "value": "Carenang"
  },
  {
    "id": "3604172002",
    "district_id": "360417",
    "label": "Pamanuk",
    "value": "Pamanuk"
  },
  {
    "id": "3604172003",
    "district_id": "360417",
    "label": "Mandaya",
    "value": "Mandaya"
  },
  {
    "id": "3604172004",
    "district_id": "360417",
    "label": "Teras",
    "value": "Teras"
  },
  {
    "id": "3604172006",
    "district_id": "360417",
    "label": "Ragasmasigit",
    "value": "Ragasmasigit"
  },
  {
    "id": "3604172007",
    "district_id": "360417",
    "label": "Walikukun",
    "value": "Walikukun"
  },
  {
    "id": "3604172009",
    "district_id": "360417",
    "label": "Panenjoan",
    "value": "Panenjoan"
  },
  {
    "id": "3604172010",
    "district_id": "360417",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3604182001",
    "district_id": "360418",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "3604182002",
    "district_id": "360418",
    "label": "Cakung",
    "value": "Cakung"
  },
  {
    "id": "3604182003",
    "district_id": "360418",
    "label": "Renged",
    "value": "Renged"
  },
  {
    "id": "3604182004",
    "district_id": "360418",
    "label": "Gembor",
    "value": "Gembor"
  },
  {
    "id": "3604182005",
    "district_id": "360418",
    "label": "Warakas",
    "value": "Warakas"
  },
  {
    "id": "3604182006",
    "district_id": "360418",
    "label": "Sukamampir",
    "value": "Sukamampir"
  },
  {
    "id": "3604182007",
    "district_id": "360418",
    "label": "Lamaran",
    "value": "Lamaran"
  },
  {
    "id": "3604192001",
    "district_id": "360419",
    "label": "Petir",
    "value": "Petir"
  },
  {
    "id": "3604192002",
    "district_id": "360419",
    "label": "Cirangkong",
    "value": "Cirangkong"
  },
  {
    "id": "3604192003",
    "district_id": "360419",
    "label": "Tambiluk",
    "value": "Tambiluk"
  },
  {
    "id": "3604192004",
    "district_id": "360419",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3604192005",
    "district_id": "360419",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3604192006",
    "district_id": "360419",
    "label": "Seuat",
    "value": "Seuat"
  },
  {
    "id": "3604192007",
    "district_id": "360419",
    "label": "Nagarapadang",
    "value": "Nagarapadang"
  },
  {
    "id": "3604192008",
    "district_id": "360419",
    "label": "Kadugenep",
    "value": "Kadugenep"
  },
  {
    "id": "3604192009",
    "district_id": "360419",
    "label": "Cirendeu",
    "value": "Cirendeu"
  },
  {
    "id": "3604192010",
    "district_id": "360419",
    "label": "Sanding",
    "value": "Sanding"
  },
  {
    "id": "3604192011",
    "district_id": "360419",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "3604192012",
    "district_id": "360419",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "3604192013",
    "district_id": "360419",
    "label": "Seuat Jaya",
    "value": "Seuat Jaya"
  },
  {
    "id": "3604192014",
    "district_id": "360419",
    "label": "Kubang Jaya",
    "value": "Kubang Jaya"
  },
  {
    "id": "3604192015",
    "district_id": "360419",
    "label": "Bojong Nangka",
    "value": "Bojong Nangka"
  },
  {
    "id": "3604202001",
    "district_id": "360420",
    "label": "Tanjung Teja",
    "value": "Tanjung Teja"
  },
  {
    "id": "3604202002",
    "district_id": "360420",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3604202003",
    "district_id": "360420",
    "label": "Panunggulan",
    "value": "Panunggulan"
  },
  {
    "id": "3604202004",
    "district_id": "360420",
    "label": "Malanggah",
    "value": "Malanggah"
  },
  {
    "id": "3604202005",
    "district_id": "360420",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "3604202006",
    "district_id": "360420",
    "label": "Bojong Menteng",
    "value": "Bojong Menteng"
  },
  {
    "id": "3604202007",
    "district_id": "360420",
    "label": "Bojong Catang",
    "value": "Bojong Catang"
  },
  {
    "id": "3604202008",
    "district_id": "360420",
    "label": "Bojong Pandan",
    "value": "Bojong Pandan"
  },
  {
    "id": "3604202009",
    "district_id": "360420",
    "label": "Pancaregang",
    "value": "Pancaregang"
  },
  {
    "id": "3604222001",
    "district_id": "360422",
    "label": "Baros",
    "value": "Baros"
  },
  {
    "id": "3604222002",
    "district_id": "360422",
    "label": "Tejamari",
    "value": "Tejamari"
  },
  {
    "id": "3604222003",
    "district_id": "360422",
    "label": "Panyirapan",
    "value": "Panyirapan"
  },
  {
    "id": "3604222004",
    "district_id": "360422",
    "label": "Sidamukti",
    "value": "Sidamukti"
  },
  {
    "id": "3604222005",
    "district_id": "360422",
    "label": "Sukacai",
    "value": "Sukacai"
  },
  {
    "id": "3604222006",
    "district_id": "360422",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3604222007",
    "district_id": "360422",
    "label": "Sindangmandi",
    "value": "Sindangmandi"
  },
  {
    "id": "3604222008",
    "district_id": "360422",
    "label": "Cisalam",
    "value": "Cisalam"
  },
  {
    "id": "3604222009",
    "district_id": "360422",
    "label": "Sukamanah",
    "value": "Sukamanah"
  },
  {
    "id": "3604222010",
    "district_id": "360422",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3604222011",
    "district_id": "360422",
    "label": "Curugagung",
    "value": "Curugagung"
  },
  {
    "id": "3604222012",
    "district_id": "360422",
    "label": "Padasuka",
    "value": "Padasuka"
  },
  {
    "id": "3604222013",
    "district_id": "360422",
    "label": "Sinarmukti",
    "value": "Sinarmukti"
  },
  {
    "id": "3604222014",
    "district_id": "360422",
    "label": "Suka Indah",
    "value": "Suka Indah"
  },
  {
    "id": "3604232001",
    "district_id": "360423",
    "label": "Cikeusal",
    "value": "Cikeusal"
  },
  {
    "id": "3604232002",
    "district_id": "360423",
    "label": "Dahu",
    "value": "Dahu"
  },
  {
    "id": "3604232003",
    "district_id": "360423",
    "label": "Katulisan",
    "value": "Katulisan"
  },
  {
    "id": "3604232004",
    "district_id": "360423",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "3604232005",
    "district_id": "360423",
    "label": "Cilayang",
    "value": "Cilayang"
  },
  {
    "id": "3604232006",
    "district_id": "360423",
    "label": "Sukamenak",
    "value": "Sukamenak"
  },
  {
    "id": "3604232007",
    "district_id": "360423",
    "label": "Cimaung",
    "value": "Cimaung"
  },
  {
    "id": "3604232008",
    "district_id": "360423",
    "label": "Panyabrangan",
    "value": "Panyabrangan"
  },
  {
    "id": "3604232009",
    "district_id": "360423",
    "label": "Gandayasa",
    "value": "Gandayasa"
  },
  {
    "id": "3604232010",
    "district_id": "360423",
    "label": "Bantar Panjang",
    "value": "Bantar Panjang"
  },
  {
    "id": "3604232011",
    "district_id": "360423",
    "label": "Sukaratu",
    "value": "Sukaratu"
  },
  {
    "id": "3604232012",
    "district_id": "360423",
    "label": "Harundang",
    "value": "Harundang"
  },
  {
    "id": "3604232013",
    "district_id": "360423",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "3604232014",
    "district_id": "360423",
    "label": "Panosogan",
    "value": "Panosogan"
  },
  {
    "id": "3604232015",
    "district_id": "360423",
    "label": "Mongpok",
    "value": "Mongpok"
  },
  {
    "id": "3604232016",
    "district_id": "360423",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "3604232017",
    "district_id": "360423",
    "label": "Cilayang Guha",
    "value": "Cilayang Guha"
  },
  {
    "id": "3604242001",
    "district_id": "360424",
    "label": "Pamarayan",
    "value": "Pamarayan"
  },
  {
    "id": "3604242002",
    "district_id": "360424",
    "label": "Damping",
    "value": "Damping"
  },
  {
    "id": "3604242003",
    "district_id": "360424",
    "label": "Wirana",
    "value": "Wirana"
  },
  {
    "id": "3604242004",
    "district_id": "360424",
    "label": "Keboncau",
    "value": "Keboncau"
  },
  {
    "id": "3604242005",
    "district_id": "360424",
    "label": "Pudar",
    "value": "Pudar"
  },
  {
    "id": "3604242006",
    "district_id": "360424",
    "label": "Binong",
    "value": "Binong"
  },
  {
    "id": "3604242007",
    "district_id": "360424",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3604242008",
    "district_id": "360424",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "3604242010",
    "district_id": "360424",
    "label": "Pasirlimus",
    "value": "Pasirlimus"
  },
  {
    "id": "3604242018",
    "district_id": "360424",
    "label": "Pasir Kembang",
    "value": "Pasir Kembang"
  },
  {
    "id": "3604252001",
    "district_id": "360425",
    "label": "Kopo",
    "value": "Kopo"
  },
  {
    "id": "3604252002",
    "district_id": "360425",
    "label": "Garut",
    "value": "Garut"
  },
  {
    "id": "3604252003",
    "district_id": "360425",
    "label": "Nanggung",
    "value": "Nanggung"
  },
  {
    "id": "3604252004",
    "district_id": "360425",
    "label": "Cidahu",
    "value": "Cidahu"
  },
  {
    "id": "3604252005",
    "district_id": "360425",
    "label": "Nyompok",
    "value": "Nyompok"
  },
  {
    "id": "3604252006",
    "district_id": "360425",
    "label": "Gabus",
    "value": "Gabus"
  },
  {
    "id": "3604252007",
    "district_id": "360425",
    "label": "Carenang Udik",
    "value": "Carenang Udik"
  },
  {
    "id": "3604252008",
    "district_id": "360425",
    "label": "Rancasumur",
    "value": "Rancasumur"
  },
  {
    "id": "3604252009",
    "district_id": "360425",
    "label": "Babakanjaya",
    "value": "Babakanjaya"
  },
  {
    "id": "3604252010",
    "district_id": "360425",
    "label": "Mekarbaru",
    "value": "Mekarbaru"
  },
  {
    "id": "3604262001",
    "district_id": "360426",
    "label": "Jawilan",
    "value": "Jawilan"
  },
  {
    "id": "3604262002",
    "district_id": "360426",
    "label": "Bojot",
    "value": "Bojot"
  },
  {
    "id": "3604262003",
    "district_id": "360426",
    "label": "Cemplang",
    "value": "Cemplang"
  },
  {
    "id": "3604262004",
    "district_id": "360426",
    "label": "Pagintungan",
    "value": "Pagintungan"
  },
  {
    "id": "3604262005",
    "district_id": "360426",
    "label": "Pasirbuyut",
    "value": "Pasirbuyut"
  },
  {
    "id": "3604262006",
    "district_id": "360426",
    "label": "Majasari",
    "value": "Majasari"
  },
  {
    "id": "3604262007",
    "district_id": "360426",
    "label": "Parakan",
    "value": "Parakan"
  },
  {
    "id": "3604262008",
    "district_id": "360426",
    "label": "Kareo",
    "value": "Kareo"
  },
  {
    "id": "3604262009",
    "district_id": "360426",
    "label": "Junti",
    "value": "Junti"
  },
  {
    "id": "3604272001",
    "district_id": "360427",
    "label": "Ujungtebu",
    "value": "Ujungtebu"
  },
  {
    "id": "3604272002",
    "district_id": "360427",
    "label": "Siketug",
    "value": "Siketug"
  },
  {
    "id": "3604272003",
    "district_id": "360427",
    "label": "Lebak",
    "value": "Lebak"
  },
  {
    "id": "3604272004",
    "district_id": "360427",
    "label": "Pondok Kaharu",
    "value": "Pondok Kaharu"
  },
  {
    "id": "3604272005",
    "district_id": "360427",
    "label": "Sukabares",
    "value": "Sukabares"
  },
  {
    "id": "3604272006",
    "district_id": "360427",
    "label": "Sukarena",
    "value": "Sukarena"
  },
  {
    "id": "3604272007",
    "district_id": "360427",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "3604272008",
    "district_id": "360427",
    "label": "Cemplang",
    "value": "Cemplang"
  },
  {
    "id": "3604272009",
    "district_id": "360427",
    "label": "Cisitu",
    "value": "Cisitu"
  },
  {
    "id": "3604272010",
    "district_id": "360427",
    "label": "Citaman",
    "value": "Citaman"
  },
  {
    "id": "3604272011",
    "district_id": "360427",
    "label": "Panyaungan Jaya",
    "value": "Panyaungan Jaya"
  },
  {
    "id": "3604282001",
    "district_id": "360428",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3604282006",
    "district_id": "360428",
    "label": "Kadubeureum",
    "value": "Kadubeureum"
  },
  {
    "id": "3604282007",
    "district_id": "360428",
    "label": "Tanjungsari",
    "value": "Tanjungsari"
  },
  {
    "id": "3604282009",
    "district_id": "360428",
    "label": "Pancanegara",
    "value": "Pancanegara"
  },
  {
    "id": "3604282010",
    "district_id": "360428",
    "label": "Sindangsari",
    "value": "Sindangsari"
  },
  {
    "id": "3604282012",
    "district_id": "360428",
    "label": "Sindangheula",
    "value": "Sindangheula"
  },
  {
    "id": "3604282013",
    "district_id": "360428",
    "label": "Pasanggrahan",
    "value": "Pasanggrahan"
  },
  {
    "id": "3604282015",
    "district_id": "360428",
    "label": "Talaga Warna",
    "value": "Talaga Warna"
  },
  {
    "id": "3604292001",
    "district_id": "360429",
    "label": "Padarincang",
    "value": "Padarincang"
  },
  {
    "id": "3604292002",
    "district_id": "360429",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3604292003",
    "district_id": "360429",
    "label": "Cibojong",
    "value": "Cibojong"
  },
  {
    "id": "3604292004",
    "district_id": "360429",
    "label": "Citasuk",
    "value": "Citasuk"
  },
  {
    "id": "3604292005",
    "district_id": "360429",
    "label": "Cisaat",
    "value": "Cisaat"
  },
  {
    "id": "3604292006",
    "district_id": "360429",
    "label": "Ciomas",
    "value": "Ciomas"
  },
  {
    "id": "3604292007",
    "district_id": "360429",
    "label": "Barugbug",
    "value": "Barugbug"
  },
  {
    "id": "3604292008",
    "district_id": "360429",
    "label": "Batu Kuwung",
    "value": "Batu Kuwung"
  },
  {
    "id": "3604292009",
    "district_id": "360429",
    "label": "Kramatlaban",
    "value": "Kramatlaban"
  },
  {
    "id": "3604292010",
    "district_id": "360429",
    "label": "Kelumpang",
    "value": "Kelumpang"
  },
  {
    "id": "3604292011",
    "district_id": "360429",
    "label": "Kadubeureum",
    "value": "Kadubeureum"
  },
  {
    "id": "3604292012",
    "district_id": "360429",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "3604292013",
    "district_id": "360429",
    "label": "Curug Goong",
    "value": "Curug Goong"
  },
  {
    "id": "3604292014",
    "district_id": "360429",
    "label": "Kadu Kempong",
    "value": "Kadu Kempong"
  },
  {
    "id": "3604302001",
    "district_id": "360430",
    "label": "Anyar",
    "value": "Anyar"
  },
  {
    "id": "3604302002",
    "district_id": "360430",
    "label": "Sindang Mandi",
    "value": "Sindang Mandi"
  },
  {
    "id": "3604302003",
    "district_id": "360430",
    "label": "Cikoneng",
    "value": "Cikoneng"
  },
  {
    "id": "3604302004",
    "district_id": "360430",
    "label": "Tanjung Manis",
    "value": "Tanjung Manis"
  },
  {
    "id": "3604302005",
    "district_id": "360430",
    "label": "Bandulu",
    "value": "Bandulu"
  },
  {
    "id": "3604302006",
    "district_id": "360430",
    "label": "Bunihara",
    "value": "Bunihara"
  },
  {
    "id": "3604302007",
    "district_id": "360430",
    "label": "Kosambironyok",
    "value": "Kosambironyok"
  },
  {
    "id": "3604302008",
    "district_id": "360430",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3604302009",
    "district_id": "360430",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3604302010",
    "district_id": "360430",
    "label": "Sindangkarya",
    "value": "Sindangkarya"
  },
  {
    "id": "3604302011",
    "district_id": "360430",
    "label": "Tambang Ayam",
    "value": "Tambang Ayam"
  },
  {
    "id": "3604302012",
    "district_id": "360430",
    "label": "Grogol Indah",
    "value": "Grogol Indah"
  },
  {
    "id": "3604312001",
    "district_id": "360431",
    "label": "Cinangka",
    "value": "Cinangka"
  },
  {
    "id": "3604312002",
    "district_id": "360431",
    "label": "Bantar Waru",
    "value": "Bantar Waru"
  },
  {
    "id": "3604312003",
    "district_id": "360431",
    "label": "Pasauran",
    "value": "Pasauran"
  },
  {
    "id": "3604312004",
    "district_id": "360431",
    "label": "Bulakan",
    "value": "Bulakan"
  },
  {
    "id": "3604312005",
    "district_id": "360431",
    "label": "Karang Suraga",
    "value": "Karang Suraga"
  },
  {
    "id": "3604312006",
    "district_id": "360431",
    "label": "Umbul Tanjung",
    "value": "Umbul Tanjung"
  },
  {
    "id": "3604312007",
    "district_id": "360431",
    "label": "Kubang Baros",
    "value": "Kubang Baros"
  },
  {
    "id": "3604312008",
    "district_id": "360431",
    "label": "Ranca Sanggal",
    "value": "Ranca Sanggal"
  },
  {
    "id": "3604312009",
    "district_id": "360431",
    "label": "Cikolelet",
    "value": "Cikolelet"
  },
  {
    "id": "3604312010",
    "district_id": "360431",
    "label": "Sindang Laya",
    "value": "Sindang Laya"
  },
  {
    "id": "3604312011",
    "district_id": "360431",
    "label": "Kamasan",
    "value": "Kamasan"
  },
  {
    "id": "3604312012",
    "district_id": "360431",
    "label": "Bantar Wangi",
    "value": "Bantar Wangi"
  },
  {
    "id": "3604312013",
    "district_id": "360431",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3604312014",
    "district_id": "360431",
    "label": "Baros Jaya",
    "value": "Baros Jaya"
  },
  {
    "id": "3604322001",
    "district_id": "360432",
    "label": "Mancak",
    "value": "Mancak"
  },
  {
    "id": "3604322002",
    "district_id": "360432",
    "label": "Ciwarna",
    "value": "Ciwarna"
  },
  {
    "id": "3604322003",
    "district_id": "360432",
    "label": "Angsana",
    "value": "Angsana"
  },
  {
    "id": "3604322004",
    "district_id": "360432",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "3604322005",
    "district_id": "360432",
    "label": "Cikedung",
    "value": "Cikedung"
  },
  {
    "id": "3604322006",
    "district_id": "360432",
    "label": "Sigedong",
    "value": "Sigedong"
  },
  {
    "id": "3604322007",
    "district_id": "360432",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "3604322008",
    "district_id": "360432",
    "label": "Pasirwaru",
    "value": "Pasirwaru"
  },
  {
    "id": "3604322009",
    "district_id": "360432",
    "label": "Waringin",
    "value": "Waringin"
  },
  {
    "id": "3604322010",
    "district_id": "360432",
    "label": "Winong",
    "value": "Winong"
  },
  {
    "id": "3604322011",
    "district_id": "360432",
    "label": "Batukuda",
    "value": "Batukuda"
  },
  {
    "id": "3604322012",
    "district_id": "360432",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "3604322013",
    "district_id": "360432",
    "label": "Bale Kambang",
    "value": "Bale Kambang"
  },
  {
    "id": "3604322014",
    "district_id": "360432",
    "label": "Bale Kencana",
    "value": "Bale Kencana"
  },
  {
    "id": "3604332001",
    "district_id": "360433",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "3604332002",
    "district_id": "360433",
    "label": "Ciherang",
    "value": "Ciherang"
  },
  {
    "id": "3604332003",
    "district_id": "360433",
    "label": "Tamiang",
    "value": "Tamiang"
  },
  {
    "id": "3604332004",
    "district_id": "360433",
    "label": "Sukalaba",
    "value": "Sukalaba"
  },
  {
    "id": "3604332005",
    "district_id": "360433",
    "label": "Kadu Agung",
    "value": "Kadu Agung"
  },
  {
    "id": "3604332006",
    "district_id": "360433",
    "label": "Luwuk",
    "value": "Luwuk"
  },
  {
    "id": "3604332007",
    "district_id": "360433",
    "label": "Curug Sulanjana",
    "value": "Curug Sulanjana"
  },
  {
    "id": "3604342001",
    "district_id": "360434",
    "label": "Bandung",
    "value": "Bandung"
  },
  {
    "id": "3604342002",
    "district_id": "360434",
    "label": "Mander",
    "value": "Mander"
  },
  {
    "id": "3604342003",
    "district_id": "360434",
    "label": "Penamping",
    "value": "Penamping"
  },
  {
    "id": "3604342004",
    "district_id": "360434",
    "label": "Pangawinan",
    "value": "Pangawinan"
  },
  {
    "id": "3604342005",
    "district_id": "360434",
    "label": "Malabar",
    "value": "Malabar"
  },
  {
    "id": "3604342006",
    "district_id": "360434",
    "label": "Blokang",
    "value": "Blokang"
  },
  {
    "id": "3604342007",
    "district_id": "360434",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3604342008",
    "district_id": "360434",
    "label": "Pringwulung",
    "value": "Pringwulung"
  },
  {
    "id": "3604352001",
    "district_id": "360435",
    "label": "Kamaruton",
    "value": "Kamaruton"
  },
  {
    "id": "3604352002",
    "district_id": "360435",
    "label": "Teras Bendung",
    "value": "Teras Bendung"
  },
  {
    "id": "3604352003",
    "district_id": "360435",
    "label": "Lebak Wangi",
    "value": "Lebak Wangi"
  },
  {
    "id": "3604352004",
    "district_id": "360435",
    "label": "Lebak Kepuh",
    "value": "Lebak Kepuh"
  },
  {
    "id": "3604352005",
    "district_id": "360435",
    "label": "Kencana Harapan",
    "value": "Kencana Harapan"
  },
  {
    "id": "3604352006",
    "district_id": "360435",
    "label": "Pegandikan",
    "value": "Pegandikan"
  },
  {
    "id": "3604352007",
    "district_id": "360435",
    "label": "Purwadadi",
    "value": "Purwadadi"
  },
  {
    "id": "3604352008",
    "district_id": "360435",
    "label": "Bolang",
    "value": "Bolang"
  },
  {
    "id": "3604352009",
    "district_id": "360435",
    "label": "Tirem",
    "value": "Tirem"
  },
  {
    "id": "3604352010",
    "district_id": "360435",
    "label": "Kebonratu",
    "value": "Kebonratu"
  },
  {
    "id": "3671011001",
    "district_id": "367101",
    "label": "Sukarasa",
    "value": "Sukarasa"
  },
  {
    "id": "3671011002",
    "district_id": "367101",
    "label": "Sukaasih",
    "value": "Sukaasih"
  },
  {
    "id": "3671011003",
    "district_id": "367101",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "3671011004",
    "district_id": "367101",
    "label": "Buaran Indah",
    "value": "Buaran Indah"
  },
  {
    "id": "3671011005",
    "district_id": "367101",
    "label": "Cikokol",
    "value": "Cikokol"
  },
  {
    "id": "3671011006",
    "district_id": "367101",
    "label": "Kelapa Indah",
    "value": "Kelapa Indah"
  },
  {
    "id": "3671011007",
    "district_id": "367101",
    "label": "Sukasari",
    "value": "Sukasari"
  },
  {
    "id": "3671011008",
    "district_id": "367101",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3671021001",
    "district_id": "367102",
    "label": "Keroncong",
    "value": "Keroncong"
  },
  {
    "id": "3671021002",
    "district_id": "367102",
    "label": "Jatake",
    "value": "Jatake"
  },
  {
    "id": "3671021003",
    "district_id": "367102",
    "label": "Pasir Jaya",
    "value": "Pasir Jaya"
  },
  {
    "id": "3671021004",
    "district_id": "367102",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "3671021005",
    "district_id": "367102",
    "label": "Manis Jaya",
    "value": "Manis Jaya"
  },
  {
    "id": "3671021006",
    "district_id": "367102",
    "label": "Alam Jaya",
    "value": "Alam Jaya"
  },
  {
    "id": "3671031001",
    "district_id": "367103",
    "label": "Batuceper",
    "value": "Batuceper"
  },
  {
    "id": "3671031002",
    "district_id": "367103",
    "label": "Batujaya",
    "value": "Batujaya"
  },
  {
    "id": "3671031003",
    "district_id": "367103",
    "label": "Poris Gaga",
    "value": "Poris Gaga"
  },
  {
    "id": "3671031004",
    "district_id": "367103",
    "label": "Poris Gaga Baru",
    "value": "Poris Gaga Baru"
  },
  {
    "id": "3671031005",
    "district_id": "367103",
    "label": "Kebon Besar",
    "value": "Kebon Besar"
  },
  {
    "id": "3671031006",
    "district_id": "367103",
    "label": "Batusari",
    "value": "Batusari"
  },
  {
    "id": "3671031007",
    "district_id": "367103",
    "label": "Poris Jaya",
    "value": "Poris Jaya"
  },
  {
    "id": "3671041001",
    "district_id": "367104",
    "label": "Belendung",
    "value": "Belendung"
  },
  {
    "id": "3671041002",
    "district_id": "367104",
    "label": "Jurumudi",
    "value": "Jurumudi"
  },
  {
    "id": "3671041003",
    "district_id": "367104",
    "label": "Benda",
    "value": "Benda"
  },
  {
    "id": "3671041004",
    "district_id": "367104",
    "label": "Pajang",
    "value": "Pajang"
  },
  {
    "id": "3671041005",
    "district_id": "367104",
    "label": "Jurumudi Baru",
    "value": "Jurumudi Baru"
  },
  {
    "id": "3671051001",
    "district_id": "367105",
    "label": "Cipondoh",
    "value": "Cipondoh"
  },
  {
    "id": "3671051002",
    "district_id": "367105",
    "label": "Cipondoh Makmur",
    "value": "Cipondoh Makmur"
  },
  {
    "id": "3671051003",
    "district_id": "367105",
    "label": "Cipondoh Indah",
    "value": "Cipondoh Indah"
  },
  {
    "id": "3671051004",
    "district_id": "367105",
    "label": "Gondrong",
    "value": "Gondrong"
  },
  {
    "id": "3671051005",
    "district_id": "367105",
    "label": "Kenanga",
    "value": "Kenanga"
  },
  {
    "id": "3671051006",
    "district_id": "367105",
    "label": "Petir",
    "value": "Petir"
  },
  {
    "id": "3671051007",
    "district_id": "367105",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "3671051008",
    "district_id": "367105",
    "label": "Poris Plawad",
    "value": "Poris Plawad"
  },
  {
    "id": "3671051009",
    "district_id": "367105",
    "label": "Poris Plawad Utara",
    "value": "Poris Plawad Utara"
  },
  {
    "id": "3671051010",
    "district_id": "367105",
    "label": "Poris Plawad Indah",
    "value": "Poris Plawad Indah"
  },
  {
    "id": "3671061001",
    "district_id": "367106",
    "label": "Paninggilan",
    "value": "Paninggilan"
  },
  {
    "id": "3671061002",
    "district_id": "367106",
    "label": "Sudimara Barat",
    "value": "Sudimara Barat"
  },
  {
    "id": "3671061003",
    "district_id": "367106",
    "label": "Sudimara Timur",
    "value": "Sudimara Timur"
  },
  {
    "id": "3671061004",
    "district_id": "367106",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "3671061005",
    "district_id": "367106",
    "label": "Parung Serab",
    "value": "Parung Serab"
  },
  {
    "id": "3671061006",
    "district_id": "367106",
    "label": "Sudimara Jaya",
    "value": "Sudimara Jaya"
  },
  {
    "id": "3671061007",
    "district_id": "367106",
    "label": "Sudimara Selatan",
    "value": "Sudimara Selatan"
  },
  {
    "id": "3671061008",
    "district_id": "367106",
    "label": "Paninggilan Utara",
    "value": "Paninggilan Utara"
  },
  {
    "id": "3671071001",
    "district_id": "367107",
    "label": "Karawaci",
    "value": "Karawaci"
  },
  {
    "id": "3671071002",
    "district_id": "367107",
    "label": "Bojong Jaya",
    "value": "Bojong Jaya"
  },
  {
    "id": "3671071003",
    "district_id": "367107",
    "label": "Karawaci Baru",
    "value": "Karawaci Baru"
  },
  {
    "id": "3671071004",
    "district_id": "367107",
    "label": "Nusa Jaya",
    "value": "Nusa Jaya"
  },
  {
    "id": "3671071005",
    "district_id": "367107",
    "label": "Cimone",
    "value": "Cimone"
  },
  {
    "id": "3671071006",
    "district_id": "367107",
    "label": "Cimone Jaya",
    "value": "Cimone Jaya"
  },
  {
    "id": "3671071007",
    "district_id": "367107",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3671071008",
    "district_id": "367107",
    "label": "Sumur Pacing",
    "value": "Sumur Pacing"
  },
  {
    "id": "3671071009",
    "district_id": "367107",
    "label": "Bugel",
    "value": "Bugel"
  },
  {
    "id": "3671071010",
    "district_id": "367107",
    "label": "Margasari",
    "value": "Margasari"
  },
  {
    "id": "3671071011",
    "district_id": "367107",
    "label": "Pabuaran Tumpeng",
    "value": "Pabuaran Tumpeng"
  },
  {
    "id": "3671071012",
    "district_id": "367107",
    "label": "Nambo Jaya",
    "value": "Nambo Jaya"
  },
  {
    "id": "3671071013",
    "district_id": "367107",
    "label": "Gerendeng",
    "value": "Gerendeng"
  },
  {
    "id": "3671071014",
    "district_id": "367107",
    "label": "Sukajadi",
    "value": "Sukajadi"
  },
  {
    "id": "3671071015",
    "district_id": "367107",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "3671071016",
    "district_id": "367107",
    "label": "Koang Jaya",
    "value": "Koang Jaya"
  },
  {
    "id": "3671081001",
    "district_id": "367108",
    "label": "Periuk",
    "value": "Periuk"
  },
  {
    "id": "3671081002",
    "district_id": "367108",
    "label": "Gembor",
    "value": "Gembor"
  },
  {
    "id": "3671081003",
    "district_id": "367108",
    "label": "Gebang Raya",
    "value": "Gebang Raya"
  },
  {
    "id": "3671081004",
    "district_id": "367108",
    "label": "Sangiang Jaya",
    "value": "Sangiang Jaya"
  },
  {
    "id": "3671081005",
    "district_id": "367108",
    "label": "Periuk Jaya",
    "value": "Periuk Jaya"
  },
  {
    "id": "3671091001",
    "district_id": "367109",
    "label": "Cibodas",
    "value": "Cibodas"
  },
  {
    "id": "3671091002",
    "district_id": "367109",
    "label": "Cibodasari",
    "value": "Cibodasari"
  },
  {
    "id": "3671091003",
    "district_id": "367109",
    "label": "Cibodas Baru",
    "value": "Cibodas Baru"
  },
  {
    "id": "3671091004",
    "district_id": "367109",
    "label": "Panunggangan Barat",
    "value": "Panunggangan Barat"
  },
  {
    "id": "3671091005",
    "district_id": "367109",
    "label": "Uwung Jaya",
    "value": "Uwung Jaya"
  },
  {
    "id": "3671091006",
    "district_id": "367109",
    "label": "Jatiuwung",
    "value": "Jatiuwung"
  },
  {
    "id": "3671101001",
    "district_id": "367110",
    "label": "Neglasari",
    "value": "Neglasari"
  },
  {
    "id": "3671101002",
    "district_id": "367110",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "3671101003",
    "district_id": "367110",
    "label": "Selapajang Jaya",
    "value": "Selapajang Jaya"
  },
  {
    "id": "3671101004",
    "district_id": "367110",
    "label": "Kedaung Wetan",
    "value": "Kedaung Wetan"
  },
  {
    "id": "3671101005",
    "district_id": "367110",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3671101006",
    "district_id": "367110",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "3671101007",
    "district_id": "367110",
    "label": "Kedaung Baru",
    "value": "Kedaung Baru"
  },
  {
    "id": "3671111001",
    "district_id": "367111",
    "label": "Pinang",
    "value": "Pinang"
  },
  {
    "id": "3671111002",
    "district_id": "367111",
    "label": "Sudimara Pinang",
    "value": "Sudimara Pinang"
  },
  {
    "id": "3671111003",
    "district_id": "367111",
    "label": "Nerogtog",
    "value": "Nerogtog"
  },
  {
    "id": "3671111004",
    "district_id": "367111",
    "label": "Kunciran",
    "value": "Kunciran"
  },
  {
    "id": "3671111005",
    "district_id": "367111",
    "label": "Kunciran Indah",
    "value": "Kunciran Indah"
  },
  {
    "id": "3671111006",
    "district_id": "367111",
    "label": "Kunciran Jaya",
    "value": "Kunciran Jaya"
  },
  {
    "id": "3671111007",
    "district_id": "367111",
    "label": "Cipete",
    "value": "Cipete"
  },
  {
    "id": "3671111008",
    "district_id": "367111",
    "label": "Pakojan",
    "value": "Pakojan"
  },
  {
    "id": "3671111009",
    "district_id": "367111",
    "label": "Panunggangan",
    "value": "Panunggangan"
  },
  {
    "id": "3671111010",
    "district_id": "367111",
    "label": "Panunggangan Utara",
    "value": "Panunggangan Utara"
  },
  {
    "id": "3671111011",
    "district_id": "367111",
    "label": "Panunggangan Timur",
    "value": "Panunggangan Timur"
  },
  {
    "id": "3671121001",
    "district_id": "367112",
    "label": "Karang Tengah",
    "value": "Karang Tengah"
  },
  {
    "id": "3671121002",
    "district_id": "367112",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "3671121003",
    "district_id": "367112",
    "label": "Pondok Bahar",
    "value": "Pondok Bahar"
  },
  {
    "id": "3671121004",
    "district_id": "367112",
    "label": "Pondok Pucung",
    "value": "Pondok Pucung"
  },
  {
    "id": "3671121005",
    "district_id": "367112",
    "label": "Karang Timur",
    "value": "Karang Timur"
  },
  {
    "id": "3671121006",
    "district_id": "367112",
    "label": "Padurenan",
    "value": "Padurenan"
  },
  {
    "id": "3671121007",
    "district_id": "367112",
    "label": "Parung Jaya",
    "value": "Parung Jaya"
  },
  {
    "id": "3671131001",
    "district_id": "367113",
    "label": "Larangan Utara",
    "value": "Larangan Utara"
  },
  {
    "id": "3671131002",
    "district_id": "367113",
    "label": "Larangan Selatan",
    "value": "Larangan Selatan"
  },
  {
    "id": "3671131003",
    "district_id": "367113",
    "label": "Cipadu",
    "value": "Cipadu"
  },
  {
    "id": "3671131004",
    "district_id": "367113",
    "label": "Kreo",
    "value": "Kreo"
  },
  {
    "id": "3671131005",
    "district_id": "367113",
    "label": "Larangan Indah",
    "value": "Larangan Indah"
  },
  {
    "id": "3671131006",
    "district_id": "367113",
    "label": "Gaga",
    "value": "Gaga"
  },
  {
    "id": "3671131007",
    "district_id": "367113",
    "label": "Cipadu Jaya",
    "value": "Cipadu Jaya"
  },
  {
    "id": "3671131008",
    "district_id": "367113",
    "label": "Kreo Selatan",
    "value": "Kreo Selatan"
  },
  {
    "id": "3672011001",
    "district_id": "367201",
    "label": "Cibeber",
    "value": "Cibeber"
  },
  {
    "id": "3672011002",
    "district_id": "367201",
    "label": "Kedaleman",
    "value": "Kedaleman"
  },
  {
    "id": "3672011003",
    "district_id": "367201",
    "label": "Bulakan",
    "value": "Bulakan"
  },
  {
    "id": "3672011004",
    "district_id": "367201",
    "label": "Cikerai",
    "value": "Cikerai"
  },
  {
    "id": "3672011005",
    "district_id": "367201",
    "label": "Karang Asem",
    "value": "Karang Asem"
  },
  {
    "id": "3672011006",
    "district_id": "367201",
    "label": "Kalitimbang",
    "value": "Kalitimbang"
  },
  {
    "id": "3672021001",
    "district_id": "367202",
    "label": "Bagendung",
    "value": "Bagendung"
  },
  {
    "id": "3672021002",
    "district_id": "367202",
    "label": "Ciwedus",
    "value": "Ciwedus"
  },
  {
    "id": "3672021003",
    "district_id": "367202",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "3672021004",
    "district_id": "367202",
    "label": "Ketileng",
    "value": "Ketileng"
  },
  {
    "id": "3672021005",
    "district_id": "367202",
    "label": "Ciwaduk",
    "value": "Ciwaduk"
  },
  {
    "id": "3672031001",
    "district_id": "367203",
    "label": "Tamansari",
    "value": "Tamansari"
  },
  {
    "id": "3672031002",
    "district_id": "367203",
    "label": "Lebakgede",
    "value": "Lebakgede"
  },
  {
    "id": "3672031003",
    "district_id": "367203",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "3672031004",
    "district_id": "367203",
    "label": "Suralaya",
    "value": "Suralaya"
  },
  {
    "id": "3672041001",
    "district_id": "367204",
    "label": "Banjar Negara",
    "value": "Banjar Negara"
  },
  {
    "id": "3672041002",
    "district_id": "367204",
    "label": "Tegal Ratu",
    "value": "Tegal Ratu"
  },
  {
    "id": "3672041003",
    "district_id": "367204",
    "label": "Kubangsari",
    "value": "Kubangsari"
  },
  {
    "id": "3672041004",
    "district_id": "367204",
    "label": "Gunung Sugih",
    "value": "Gunung Sugih"
  },
  {
    "id": "3672041005",
    "district_id": "367204",
    "label": "Kepuh",
    "value": "Kepuh"
  },
  {
    "id": "3672041006",
    "district_id": "367204",
    "label": "Randakari",
    "value": "Randakari"
  },
  {
    "id": "3672051001",
    "district_id": "367205",
    "label": "Sukmajaya",
    "value": "Sukmajaya"
  },
  {
    "id": "3672051002",
    "district_id": "367205",
    "label": "Jombang Wetan",
    "value": "Jombang Wetan"
  },
  {
    "id": "3672051003",
    "district_id": "367205",
    "label": "Masigit",
    "value": "Masigit"
  },
  {
    "id": "3672051004",
    "district_id": "367205",
    "label": "Panggung Rawi",
    "value": "Panggung Rawi"
  },
  {
    "id": "3672051005",
    "district_id": "367205",
    "label": "Gedong Dalem",
    "value": "Gedong Dalem"
  },
  {
    "id": "3672061001",
    "district_id": "367206",
    "label": "Kotasari",
    "value": "Kotasari"
  },
  {
    "id": "3672061002",
    "district_id": "367206",
    "label": "Grogol",
    "value": "Grogol"
  },
  {
    "id": "3672061003",
    "district_id": "367206",
    "label": "Rawa Arum",
    "value": "Rawa Arum"
  },
  {
    "id": "3672061004",
    "district_id": "367206",
    "label": "Gerem",
    "value": "Gerem"
  },
  {
    "id": "3672071001",
    "district_id": "367207",
    "label": "Ramanuju",
    "value": "Ramanuju"
  },
  {
    "id": "3672071002",
    "district_id": "367207",
    "label": "Kotabumi",
    "value": "Kotabumi"
  },
  {
    "id": "3672071003",
    "district_id": "367207",
    "label": "Kebon Dalem",
    "value": "Kebon Dalem"
  },
  {
    "id": "3672071004",
    "district_id": "367207",
    "label": "Purwakarta",
    "value": "Purwakarta"
  },
  {
    "id": "3672071005",
    "district_id": "367207",
    "label": "Tegal Bunder",
    "value": "Tegal Bunder"
  },
  {
    "id": "3672071006",
    "district_id": "367207",
    "label": "Pabean",
    "value": "Pabean"
  },
  {
    "id": "3672081001",
    "district_id": "367208",
    "label": "Warnasari",
    "value": "Warnasari"
  },
  {
    "id": "3672081002",
    "district_id": "367208",
    "label": "Dringo",
    "value": "Dringo"
  },
  {
    "id": "3672081003",
    "district_id": "367208",
    "label": "Lebak Denok",
    "value": "Lebak Denok"
  },
  {
    "id": "3672081004",
    "district_id": "367208",
    "label": "Taman Baru",
    "value": "Taman Baru"
  },
  {
    "id": "3672081005",
    "district_id": "367208",
    "label": "Kebonsari",
    "value": "Kebonsari"
  },
  {
    "id": "3672081006",
    "district_id": "367208",
    "label": "Samangraya",
    "value": "Samangraya"
  },
  {
    "id": "3672081007",
    "district_id": "367208",
    "label": "Citangkil",
    "value": "Citangkil"
  },
  {
    "id": "3673011001",
    "district_id": "367301",
    "label": "Serang",
    "value": "Serang"
  },
  {
    "id": "3673011002",
    "district_id": "367301",
    "label": "Cipare",
    "value": "Cipare"
  },
  {
    "id": "3673011003",
    "district_id": "367301",
    "label": "Sumur Pecung",
    "value": "Sumur Pecung"
  },
  {
    "id": "3673011004",
    "district_id": "367301",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "3673011005",
    "district_id": "367301",
    "label": "Lopang",
    "value": "Lopang"
  },
  {
    "id": "3673011006",
    "district_id": "367301",
    "label": "Cimuncang",
    "value": "Cimuncang"
  },
  {
    "id": "3673011007",
    "district_id": "367301",
    "label": "Unyur",
    "value": "Unyur"
  },
  {
    "id": "3673011008",
    "district_id": "367301",
    "label": "Sukawana",
    "value": "Sukawana"
  },
  {
    "id": "3673011009",
    "district_id": "367301",
    "label": "Lontarbaru",
    "value": "Lontarbaru"
  },
  {
    "id": "3673011010",
    "district_id": "367301",
    "label": "Kaligandu",
    "value": "Kaligandu"
  },
  {
    "id": "3673011011",
    "district_id": "367301",
    "label": "Terondol",
    "value": "Terondol"
  },
  {
    "id": "3673011012",
    "district_id": "367301",
    "label": "Kagungan",
    "value": "Kagungan"
  },
  {
    "id": "3673021001",
    "district_id": "367302",
    "label": "Kasemen",
    "value": "Kasemen"
  },
  {
    "id": "3673021002",
    "district_id": "367302",
    "label": "Mesjid Priyayi",
    "value": "Mesjid Priyayi"
  },
  {
    "id": "3673021003",
    "district_id": "367302",
    "label": "Terumbu",
    "value": "Terumbu"
  },
  {
    "id": "3673021004",
    "district_id": "367302",
    "label": "Warung Jaud",
    "value": "Warung Jaud"
  },
  {
    "id": "3673021006",
    "district_id": "367302",
    "label": "Bendung",
    "value": "Bendung"
  },
  {
    "id": "3673021007",
    "district_id": "367302",
    "label": "Banten",
    "value": "Banten"
  },
  {
    "id": "3673021008",
    "district_id": "367302",
    "label": "Sawah Luhur",
    "value": "Sawah Luhur"
  },
  {
    "id": "3673021009",
    "district_id": "367302",
    "label": "Kilasah",
    "value": "Kilasah"
  },
  {
    "id": "3673021010",
    "district_id": "367302",
    "label": "Kasunyatan",
    "value": "Kasunyatan"
  },
  {
    "id": "3673021011",
    "district_id": "367302",
    "label": "Margaluyu",
    "value": "Margaluyu"
  },
  {
    "id": "3673031001",
    "district_id": "367303",
    "label": "Walantaka",
    "value": "Walantaka"
  },
  {
    "id": "3673031002",
    "district_id": "367303",
    "label": "Cigoong",
    "value": "Cigoong"
  },
  {
    "id": "3673031003",
    "district_id": "367303",
    "label": "Nyapah",
    "value": "Nyapah"
  },
  {
    "id": "3673031004",
    "district_id": "367303",
    "label": "Pangampelan",
    "value": "Pangampelan"
  },
  {
    "id": "3673031006",
    "district_id": "367303",
    "label": "Kiara",
    "value": "Kiara"
  },
  {
    "id": "3673031007",
    "district_id": "367303",
    "label": "Pager Agung",
    "value": "Pager Agung"
  },
  {
    "id": "3673031008",
    "district_id": "367303",
    "label": "Kalodran",
    "value": "Kalodran"
  },
  {
    "id": "3673031009",
    "district_id": "367303",
    "label": "Kapuren",
    "value": "Kapuren"
  },
  {
    "id": "3673031010",
    "district_id": "367303",
    "label": "Teritih",
    "value": "Teritih"
  },
  {
    "id": "3673031011",
    "district_id": "367303",
    "label": "Pabuaran",
    "value": "Pabuaran"
  },
  {
    "id": "3673031012",
    "district_id": "367303",
    "label": "Pasuluhan",
    "value": "Pasuluhan"
  },
  {
    "id": "3673031013",
    "district_id": "367303",
    "label": "Tegalsari",
    "value": "Tegalsari"
  },
  {
    "id": "3673031014",
    "district_id": "367303",
    "label": "Pipitan",
    "value": "Pipitan"
  },
  {
    "id": "3673031016",
    "district_id": "367303",
    "label": "Lebakwangi",
    "value": "Lebakwangi"
  },
  {
    "id": "3673041001",
    "district_id": "367304",
    "label": "Curug",
    "value": "Curug"
  },
  {
    "id": "3673041002",
    "district_id": "367304",
    "label": "Tinggar",
    "value": "Tinggar"
  },
  {
    "id": "3673041003",
    "district_id": "367304",
    "label": "Kemanisan",
    "value": "Kemanisan"
  },
  {
    "id": "3673041004",
    "district_id": "367304",
    "label": "Cipete",
    "value": "Cipete"
  },
  {
    "id": "3673041005",
    "district_id": "367304",
    "label": "Cilaku",
    "value": "Cilaku"
  },
  {
    "id": "3673041006",
    "district_id": "367304",
    "label": "Pancalaksana",
    "value": "Pancalaksana"
  },
  {
    "id": "3673041007",
    "district_id": "367304",
    "label": "Sukawana",
    "value": "Sukawana"
  },
  {
    "id": "3673041008",
    "district_id": "367304",
    "label": "Sukalaksana",
    "value": "Sukalaksana"
  },
  {
    "id": "3673041009",
    "district_id": "367304",
    "label": "Curug Manis",
    "value": "Curug Manis"
  },
  {
    "id": "3673041010",
    "district_id": "367304",
    "label": "Sukajaya",
    "value": "Sukajaya"
  },
  {
    "id": "3673051001",
    "district_id": "367305",
    "label": "Cipocok Jaya",
    "value": "Cipocok Jaya"
  },
  {
    "id": "3673051002",
    "district_id": "367305",
    "label": "Karundang",
    "value": "Karundang"
  },
  {
    "id": "3673051003",
    "district_id": "367305",
    "label": "Panancangan",
    "value": "Panancangan"
  },
  {
    "id": "3673051004",
    "district_id": "367305",
    "label": "Banjar Agung",
    "value": "Banjar Agung"
  },
  {
    "id": "3673051005",
    "district_id": "367305",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "3673051006",
    "district_id": "367305",
    "label": "Tembong",
    "value": "Tembong"
  },
  {
    "id": "3673051007",
    "district_id": "367305",
    "label": "Dalung",
    "value": "Dalung"
  },
  {
    "id": "3673051008",
    "district_id": "367305",
    "label": "Gelam",
    "value": "Gelam"
  },
  {
    "id": "3673061001",
    "district_id": "367306",
    "label": "Taktakan",
    "value": "Taktakan"
  },
  {
    "id": "3673061002",
    "district_id": "367306",
    "label": "Sayar",
    "value": "Sayar"
  },
  {
    "id": "3673061003",
    "district_id": "367306",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "3673061004",
    "district_id": "367306",
    "label": "Kuranji",
    "value": "Kuranji"
  },
  {
    "id": "3673061005",
    "district_id": "367306",
    "label": "Kalanganyar",
    "value": "Kalanganyar"
  },
  {
    "id": "3673061006",
    "district_id": "367306",
    "label": "Cilowong",
    "value": "Cilowong"
  },
  {
    "id": "3673061007",
    "district_id": "367306",
    "label": "Panggungjati",
    "value": "Panggungjati"
  },
  {
    "id": "3673061008",
    "district_id": "367306",
    "label": "Drangong",
    "value": "Drangong"
  },
  {
    "id": "3673061009",
    "district_id": "367306",
    "label": "Umbul Tengah",
    "value": "Umbul Tengah"
  },
  {
    "id": "3673061010",
    "district_id": "367306",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "3673061011",
    "district_id": "367306",
    "label": "Lialang",
    "value": "Lialang"
  },
  {
    "id": "3673061012",
    "district_id": "367306",
    "label": "Taman Baru",
    "value": "Taman Baru"
  },
  {
    "id": "3674011001",
    "district_id": "367401",
    "label": "Ciater",
    "value": "Ciater"
  },
  {
    "id": "3674011002",
    "district_id": "367401",
    "label": "Rawabuntu",
    "value": "Rawabuntu"
  },
  {
    "id": "3674011003",
    "district_id": "367401",
    "label": "Rawa Mekarjaya",
    "value": "Rawa Mekarjaya"
  },
  {
    "id": "3674011004",
    "district_id": "367401",
    "label": "Lengkong Gudang",
    "value": "Lengkong Gudang"
  },
  {
    "id": "3674011005",
    "district_id": "367401",
    "label": "Lengkong Wetan",
    "value": "Lengkong Wetan"
  },
  {
    "id": "3674011006",
    "district_id": "367401",
    "label": "Buaran",
    "value": "Buaran"
  },
  {
    "id": "3674011007",
    "district_id": "367401",
    "label": "Lengkong Gudang Timur",
    "value": "Lengkong Gudang Timur"
  },
  {
    "id": "3674011008",
    "district_id": "367401",
    "label": "Cilenggang",
    "value": "Cilenggang"
  },
  {
    "id": "3674011009",
    "district_id": "367401",
    "label": "Serpong",
    "value": "Serpong"
  },
  {
    "id": "3674021001",
    "district_id": "367402",
    "label": "Pakulonan",
    "value": "Pakulonan"
  },
  {
    "id": "3674021002",
    "district_id": "367402",
    "label": "Pakualam",
    "value": "Pakualam"
  },
  {
    "id": "3674021003",
    "district_id": "367402",
    "label": "Pakujaya",
    "value": "Pakujaya"
  },
  {
    "id": "3674021004",
    "district_id": "367402",
    "label": "Pondok Jagung",
    "value": "Pondok Jagung"
  },
  {
    "id": "3674021005",
    "district_id": "367402",
    "label": "Pondok Jagung Timur",
    "value": "Pondok Jagung Timur"
  },
  {
    "id": "3674021006",
    "district_id": "367402",
    "label": "Jelupang",
    "value": "Jelupang"
  },
  {
    "id": "3674021007",
    "district_id": "367402",
    "label": "Lengkong Karya",
    "value": "Lengkong Karya"
  },
  {
    "id": "3674031001",
    "district_id": "367403",
    "label": "Pondok Betung",
    "value": "Pondok Betung"
  },
  {
    "id": "3674031002",
    "district_id": "367403",
    "label": "Pondok Pucung",
    "value": "Pondok Pucung"
  },
  {
    "id": "3674031003",
    "district_id": "367403",
    "label": "Pondok Karya",
    "value": "Pondok Karya"
  },
  {
    "id": "3674031004",
    "district_id": "367403",
    "label": "Pondok Jaya",
    "value": "Pondok Jaya"
  },
  {
    "id": "3674031005",
    "district_id": "367403",
    "label": "Pondok Aren",
    "value": "Pondok Aren"
  },
  {
    "id": "3674031006",
    "district_id": "367403",
    "label": "Pondok Kacang Barat",
    "value": "Pondok Kacang Barat"
  },
  {
    "id": "3674031007",
    "district_id": "367403",
    "label": "Pondok Kacang Timur",
    "value": "Pondok Kacang Timur"
  },
  {
    "id": "3674031008",
    "district_id": "367403",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "3674031009",
    "district_id": "367403",
    "label": "Parigi Baru",
    "value": "Parigi Baru"
  },
  {
    "id": "3674031010",
    "district_id": "367403",
    "label": "Jurangmangu Barat",
    "value": "Jurangmangu Barat"
  },
  {
    "id": "3674031011",
    "district_id": "367403",
    "label": "Jurangmangu Timur",
    "value": "Jurangmangu Timur"
  },
  {
    "id": "3674041001",
    "district_id": "367404",
    "label": "Sawah Baru",
    "value": "Sawah Baru"
  },
  {
    "id": "3674041002",
    "district_id": "367404",
    "label": "Serua",
    "value": "Serua"
  },
  {
    "id": "3674041003",
    "district_id": "367404",
    "label": "Ciputat",
    "value": "Ciputat"
  },
  {
    "id": "3674041004",
    "district_id": "367404",
    "label": "Sawah Lama",
    "value": "Sawah Lama"
  },
  {
    "id": "3674041005",
    "district_id": "367404",
    "label": "Serua Indah",
    "value": "Serua Indah"
  },
  {
    "id": "3674041006",
    "district_id": "367404",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "3674041007",
    "district_id": "367404",
    "label": "Cipayung",
    "value": "Cipayung"
  },
  {
    "id": "3674051001",
    "district_id": "367405",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "3674051002",
    "district_id": "367405",
    "label": "Pondok Ranji",
    "value": "Pondok Ranji"
  },
  {
    "id": "3674051003",
    "district_id": "367405",
    "label": "Pisangan",
    "value": "Pisangan"
  },
  {
    "id": "3674051004",
    "district_id": "367405",
    "label": "Cireundeu",
    "value": "Cireundeu"
  },
  {
    "id": "3674051005",
    "district_id": "367405",
    "label": "Rempoa",
    "value": "Rempoa"
  },
  {
    "id": "3674051006",
    "district_id": "367405",
    "label": "Rengas",
    "value": "Rengas"
  },
  {
    "id": "3674061001",
    "district_id": "367406",
    "label": "Pamulang Barat",
    "value": "Pamulang Barat"
  },
  {
    "id": "3674061002",
    "district_id": "367406",
    "label": "Benda Baru",
    "value": "Benda Baru"
  },
  {
    "id": "3674061003",
    "district_id": "367406",
    "label": "Pondok Benda",
    "value": "Pondok Benda"
  },
  {
    "id": "3674061004",
    "district_id": "367406",
    "label": "Pondok Cabe Udik",
    "value": "Pondok Cabe Udik"
  },
  {
    "id": "3674061005",
    "district_id": "367406",
    "label": "Pondok Cabe Ilir",
    "value": "Pondok Cabe Ilir"
  },
  {
    "id": "3674061006",
    "district_id": "367406",
    "label": "Kedaung",
    "value": "Kedaung"
  },
  {
    "id": "3674061007",
    "district_id": "367406",
    "label": "Bambuapus",
    "value": "Bambuapus"
  },
  {
    "id": "3674061008",
    "district_id": "367406",
    "label": "Pamulang Timur",
    "value": "Pamulang Timur"
  },
  {
    "id": "3674071001",
    "district_id": "367407",
    "label": "Muncul",
    "value": "Muncul"
  },
  {
    "id": "3674071002",
    "district_id": "367407",
    "label": "Setu",
    "value": "Setu"
  },
  {
    "id": "3674071003",
    "district_id": "367407",
    "label": "Keranggan",
    "value": "Keranggan"
  },
  {
    "id": "3674071004",
    "district_id": "367407",
    "label": "Kademangan",
    "value": "Kademangan"
  },
  {
    "id": "3674071005",
    "district_id": "367407",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "3674071006",
    "district_id": "367407",
    "label": "Bakti Jaya",
    "value": "Bakti Jaya"
  },
  {
    "id": "5101011006",
    "district_id": "510101",
    "label": "Baler Bale Agung",
    "value": "Baler Bale Agung"
  },
  {
    "id": "5101011008",
    "district_id": "510101",
    "label": "Banjar Tengah",
    "value": "Banjar Tengah"
  },
  {
    "id": "5101011013",
    "district_id": "510101",
    "label": "Lelateng",
    "value": "Lelateng"
  },
  {
    "id": "5101011014",
    "district_id": "510101",
    "label": "Loloan Barat",
    "value": "Loloan Barat"
  },
  {
    "id": "5101012001",
    "district_id": "510101",
    "label": "Cupel",
    "value": "Cupel"
  },
  {
    "id": "5101012002",
    "district_id": "510101",
    "label": "Baluk",
    "value": "Baluk"
  },
  {
    "id": "5101012003",
    "district_id": "510101",
    "label": "Banyu Biru",
    "value": "Banyu Biru"
  },
  {
    "id": "5101012004",
    "district_id": "510101",
    "label": "Kaliakah",
    "value": "Kaliakah"
  },
  {
    "id": "5101012005",
    "district_id": "510101",
    "label": "Berangbang",
    "value": "Berangbang"
  },
  {
    "id": "5101012009",
    "district_id": "510101",
    "label": "Tegal Badeng Timur",
    "value": "Tegal Badeng Timur"
  },
  {
    "id": "5101012010",
    "district_id": "510101",
    "label": "Tegal Badeng Barat",
    "value": "Tegal Badeng Barat"
  },
  {
    "id": "5101012011",
    "district_id": "510101",
    "label": "Pengambengan",
    "value": "Pengambengan"
  },
  {
    "id": "5101021006",
    "district_id": "510102",
    "label": "Tegalcangkring",
    "value": "Tegalcangkring"
  },
  {
    "id": "5101022001",
    "district_id": "510102",
    "label": "Mendoyo Dauh Tukad",
    "value": "Mendoyo Dauh Tukad"
  },
  {
    "id": "5101022002",
    "district_id": "510102",
    "label": "Pohsanten",
    "value": "Pohsanten"
  },
  {
    "id": "5101022003",
    "district_id": "510102",
    "label": "Mendoyo Dangin Tukad",
    "value": "Mendoyo Dangin Tukad"
  },
  {
    "id": "5101022004",
    "district_id": "510102",
    "label": "Pergung",
    "value": "Pergung"
  },
  {
    "id": "5101022005",
    "district_id": "510102",
    "label": "Delodberawah",
    "value": "Delodberawah"
  },
  {
    "id": "5101022007",
    "district_id": "510102",
    "label": "Penyaringan",
    "value": "Penyaringan"
  },
  {
    "id": "5101022008",
    "district_id": "510102",
    "label": "Yehembang",
    "value": "Yehembang"
  },
  {
    "id": "5101022009",
    "district_id": "510102",
    "label": "Yeh Sumbul",
    "value": "Yeh Sumbul"
  },
  {
    "id": "5101022010",
    "district_id": "510102",
    "label": "Yehembang Kauh",
    "value": "Yehembang Kauh"
  },
  {
    "id": "5101022011",
    "district_id": "510102",
    "label": "Yehembang Kangin",
    "value": "Yehembang Kangin"
  },
  {
    "id": "5101032001",
    "district_id": "510103",
    "label": "Medewi",
    "value": "Medewi"
  },
  {
    "id": "5101032002",
    "district_id": "510103",
    "label": "Pulukan",
    "value": "Pulukan"
  },
  {
    "id": "5101032003",
    "district_id": "510103",
    "label": "Asahduren",
    "value": "Asahduren"
  },
  {
    "id": "5101032004",
    "district_id": "510103",
    "label": "Pekutatan",
    "value": "Pekutatan"
  },
  {
    "id": "5101032005",
    "district_id": "510103",
    "label": "Pangyangan",
    "value": "Pangyangan"
  },
  {
    "id": "5101032006",
    "district_id": "510103",
    "label": "Gumbrih",
    "value": "Gumbrih"
  },
  {
    "id": "5101032007",
    "district_id": "510103",
    "label": "Manggissari",
    "value": "Manggissari"
  },
  {
    "id": "5101032008",
    "district_id": "510103",
    "label": "Pengragoan",
    "value": "Pengragoan"
  },
  {
    "id": "5101041001",
    "district_id": "510104",
    "label": "Gilimanuk",
    "value": "Gilimanuk"
  },
  {
    "id": "5101042002",
    "district_id": "510104",
    "label": "Melaya",
    "value": "Melaya"
  },
  {
    "id": "5101042003",
    "district_id": "510104",
    "label": "Belimbingsari",
    "value": "Belimbingsari"
  },
  {
    "id": "5101042004",
    "district_id": "510104",
    "label": "Ekasari",
    "value": "Ekasari"
  },
  {
    "id": "5101042005",
    "district_id": "510104",
    "label": "Nusasari",
    "value": "Nusasari"
  },
  {
    "id": "5101042006",
    "district_id": "510104",
    "label": "Warnasari",
    "value": "Warnasari"
  },
  {
    "id": "5101042007",
    "district_id": "510104",
    "label": "Candikusuma",
    "value": "Candikusuma"
  },
  {
    "id": "5101042008",
    "district_id": "510104",
    "label": "Tuwed",
    "value": "Tuwed"
  },
  {
    "id": "5101042009",
    "district_id": "510104",
    "label": "Tukadaya",
    "value": "Tukadaya"
  },
  {
    "id": "5101042010",
    "district_id": "510104",
    "label": "Manistutu",
    "value": "Manistutu"
  },
  {
    "id": "5101051001",
    "district_id": "510105",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "5101051003",
    "district_id": "510105",
    "label": "Loloan Timur",
    "value": "Loloan Timur"
  },
  {
    "id": "5101051005",
    "district_id": "510105",
    "label": "Dauhwaru",
    "value": "Dauhwaru"
  },
  {
    "id": "5101051009",
    "district_id": "510105",
    "label": "Sangkaragung",
    "value": "Sangkaragung"
  },
  {
    "id": "5101052002",
    "district_id": "510105",
    "label": "Perancak",
    "value": "Perancak"
  },
  {
    "id": "5101052004",
    "district_id": "510105",
    "label": "Batu Agung",
    "value": "Batu Agung"
  },
  {
    "id": "5101052006",
    "district_id": "510105",
    "label": "Budeng",
    "value": "Budeng"
  },
  {
    "id": "5101052007",
    "district_id": "510105",
    "label": "Air Kuning",
    "value": "Air Kuning"
  },
  {
    "id": "5101052008",
    "district_id": "510105",
    "label": "Yehkuning",
    "value": "Yehkuning"
  },
  {
    "id": "5101052010",
    "district_id": "510105",
    "label": "Dangin Tukadaya",
    "value": "Dangin Tukadaya"
  },
  {
    "id": "5102012001",
    "district_id": "510201",
    "label": "Bajera",
    "value": "Bajera"
  },
  {
    "id": "5102012002",
    "district_id": "510201",
    "label": "Wanagiri",
    "value": "Wanagiri"
  },
  {
    "id": "5102012003",
    "district_id": "510201",
    "label": "Pupuan Sawah",
    "value": "Pupuan Sawah"
  },
  {
    "id": "5102012004",
    "district_id": "510201",
    "label": "Berembeng",
    "value": "Berembeng"
  },
  {
    "id": "5102012005",
    "district_id": "510201",
    "label": "Selemadeg",
    "value": "Selemadeg"
  },
  {
    "id": "5102012006",
    "district_id": "510201",
    "label": "Sarampingan",
    "value": "Sarampingan"
  },
  {
    "id": "5102012007",
    "district_id": "510201",
    "label": "Antap",
    "value": "Antap"
  },
  {
    "id": "5102012008",
    "district_id": "510201",
    "label": "Wanagiri Kauh",
    "value": "Wanagiri Kauh"
  },
  {
    "id": "5102012009",
    "district_id": "510201",
    "label": "Manikyang",
    "value": "Manikyang"
  },
  {
    "id": "5102012010",
    "district_id": "510201",
    "label": "Bajera Utara",
    "value": "Bajera Utara"
  },
  {
    "id": "5102022001",
    "district_id": "510202",
    "label": "Gunung Salak",
    "value": "Gunung Salak"
  },
  {
    "id": "5102022002",
    "district_id": "510202",
    "label": "Gadungan",
    "value": "Gadungan"
  },
  {
    "id": "5102022003",
    "district_id": "510202",
    "label": "Bantas",
    "value": "Bantas"
  },
  {
    "id": "5102022004",
    "district_id": "510202",
    "label": "Mambang",
    "value": "Mambang"
  },
  {
    "id": "5102022005",
    "district_id": "510202",
    "label": "Megati",
    "value": "Megati"
  },
  {
    "id": "5102022006",
    "district_id": "510202",
    "label": "Tangguntiti",
    "value": "Tangguntiti"
  },
  {
    "id": "5102022007",
    "district_id": "510202",
    "label": "Beraban",
    "value": "Beraban"
  },
  {
    "id": "5102022008",
    "district_id": "510202",
    "label": "Tegal Mengkeb",
    "value": "Tegal Mengkeb"
  },
  {
    "id": "5102022009",
    "district_id": "510202",
    "label": "Dalang",
    "value": "Dalang"
  },
  {
    "id": "5102022010",
    "district_id": "510202",
    "label": "Gadungsari",
    "value": "Gadungsari"
  },
  {
    "id": "5102032001",
    "district_id": "510203",
    "label": "Mundeh",
    "value": "Mundeh"
  },
  {
    "id": "5102032002",
    "district_id": "510203",
    "label": "Mundeh Kangin",
    "value": "Mundeh Kangin"
  },
  {
    "id": "5102032003",
    "district_id": "510203",
    "label": "Lalanglinggah",
    "value": "Lalanglinggah"
  },
  {
    "id": "5102032004",
    "district_id": "510203",
    "label": "Antosari",
    "value": "Antosari"
  },
  {
    "id": "5102032005",
    "district_id": "510203",
    "label": "Lumbung",
    "value": "Lumbung"
  },
  {
    "id": "5102032006",
    "district_id": "510203",
    "label": "Lumbung Kauh",
    "value": "Lumbung Kauh"
  },
  {
    "id": "5102032007",
    "district_id": "510203",
    "label": "Tiing Gading",
    "value": "Tiing Gading"
  },
  {
    "id": "5102032008",
    "district_id": "510203",
    "label": "Mundeh Kauh",
    "value": "Mundeh Kauh"
  },
  {
    "id": "5102032009",
    "district_id": "510203",
    "label": "Angkah",
    "value": "Angkah"
  },
  {
    "id": "5102032010",
    "district_id": "510203",
    "label": "Selabih",
    "value": "Selabih"
  },
  {
    "id": "5102032011",
    "district_id": "510203",
    "label": "Bengkel Sari",
    "value": "Bengkel Sari"
  },
  {
    "id": "5102042001",
    "district_id": "510204",
    "label": "Tibubiu",
    "value": "Tibubiu"
  },
  {
    "id": "5102042002",
    "district_id": "510204",
    "label": "Kelating",
    "value": "Kelating"
  },
  {
    "id": "5102042003",
    "district_id": "510204",
    "label": "Penarukan",
    "value": "Penarukan"
  },
  {
    "id": "5102042004",
    "district_id": "510204",
    "label": "Belumbang",
    "value": "Belumbang"
  },
  {
    "id": "5102042005",
    "district_id": "510204",
    "label": "Tista",
    "value": "Tista"
  },
  {
    "id": "5102042006",
    "district_id": "510204",
    "label": "Kerambitan",
    "value": "Kerambitan"
  },
  {
    "id": "5102042007",
    "district_id": "510204",
    "label": "Pangkung Karung",
    "value": "Pangkung Karung"
  },
  {
    "id": "5102042008",
    "district_id": "510204",
    "label": "Samsam",
    "value": "Samsam"
  },
  {
    "id": "5102042009",
    "district_id": "510204",
    "label": "Kukuh",
    "value": "Kukuh"
  },
  {
    "id": "5102042010",
    "district_id": "510204",
    "label": "Baturiti",
    "value": "Baturiti"
  },
  {
    "id": "5102042011",
    "district_id": "510204",
    "label": "Meliling",
    "value": "Meliling"
  },
  {
    "id": "5102042012",
    "district_id": "510204",
    "label": "Sembung Gede",
    "value": "Sembung Gede"
  },
  {
    "id": "5102042013",
    "district_id": "510204",
    "label": "Batuaji",
    "value": "Batuaji"
  },
  {
    "id": "5102042014",
    "district_id": "510204",
    "label": "Kesiut",
    "value": "Kesiut"
  },
  {
    "id": "5102042015",
    "district_id": "510204",
    "label": "Timpag",
    "value": "Timpag"
  },
  {
    "id": "5102052001",
    "district_id": "510205",
    "label": "Sudimara",
    "value": "Sudimara"
  },
  {
    "id": "5102052002",
    "district_id": "510205",
    "label": "Gubug",
    "value": "Gubug"
  },
  {
    "id": "5102052003",
    "district_id": "510205",
    "label": "Bongan",
    "value": "Bongan"
  },
  {
    "id": "5102052004",
    "district_id": "510205",
    "label": "Delod Peken",
    "value": "Delod Peken"
  },
  {
    "id": "5102052005",
    "district_id": "510205",
    "label": "Dauh Peken",
    "value": "Dauh Peken"
  },
  {
    "id": "5102052006",
    "district_id": "510205",
    "label": "Dajan Peken",
    "value": "Dajan Peken"
  },
  {
    "id": "5102052007",
    "district_id": "510205",
    "label": "Denbantas",
    "value": "Denbantas"
  },
  {
    "id": "5102052008",
    "district_id": "510205",
    "label": "Subamia",
    "value": "Subamia"
  },
  {
    "id": "5102052009",
    "district_id": "510205",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "5102052010",
    "district_id": "510205",
    "label": "Tunjuk",
    "value": "Tunjuk"
  },
  {
    "id": "5102052011",
    "district_id": "510205",
    "label": "Buahan",
    "value": "Buahan"
  },
  {
    "id": "5102052012",
    "district_id": "510205",
    "label": "Sedandan",
    "value": "Sedandan"
  },
  {
    "id": "5102062001",
    "district_id": "510206",
    "label": "Bengkel",
    "value": "Bengkel"
  },
  {
    "id": "5102062002",
    "district_id": "510206",
    "label": "Pangkung Tibah",
    "value": "Pangkung Tibah"
  },
  {
    "id": "5102062003",
    "district_id": "510206",
    "label": "Belalang",
    "value": "Belalang"
  },
  {
    "id": "5102062004",
    "district_id": "510206",
    "label": "Beraban",
    "value": "Beraban"
  },
  {
    "id": "5102062005",
    "district_id": "510206",
    "label": "Buwit",
    "value": "Buwit"
  },
  {
    "id": "5102062006",
    "district_id": "510206",
    "label": "Cepaka",
    "value": "Cepaka"
  },
  {
    "id": "5102062007",
    "district_id": "510206",
    "label": "Kaba-kaba",
    "value": "Kaba-kaba"
  },
  {
    "id": "5102062008",
    "district_id": "510206",
    "label": "Nyambu",
    "value": "Nyambu"
  },
  {
    "id": "5102062009",
    "district_id": "510206",
    "label": "Pandak Bandung",
    "value": "Pandak Bandung"
  },
  {
    "id": "5102062010",
    "district_id": "510206",
    "label": "Pandak Gede",
    "value": "Pandak Gede"
  },
  {
    "id": "5102062011",
    "district_id": "510206",
    "label": "Nyitdah",
    "value": "Nyitdah"
  },
  {
    "id": "5102062012",
    "district_id": "510206",
    "label": "Pejaten",
    "value": "Pejaten"
  },
  {
    "id": "5102062013",
    "district_id": "510206",
    "label": "Kediri",
    "value": "Kediri"
  },
  {
    "id": "5102062014",
    "district_id": "510206",
    "label": "Abian Tuwung",
    "value": "Abian Tuwung"
  },
  {
    "id": "5102062015",
    "district_id": "510206",
    "label": "Banjar Anyar",
    "value": "Banjar Anyar"
  },
  {
    "id": "5102072001",
    "district_id": "510207",
    "label": "Kukuh",
    "value": "Kukuh"
  },
  {
    "id": "5102072002",
    "district_id": "510207",
    "label": "Beringkit",
    "value": "Beringkit"
  },
  {
    "id": "5102072003",
    "district_id": "510207",
    "label": "Peken",
    "value": "Peken"
  },
  {
    "id": "5102072004",
    "district_id": "510207",
    "label": "Batannyuh",
    "value": "Batannyuh"
  },
  {
    "id": "5102072005",
    "district_id": "510207",
    "label": "Tegaljadi",
    "value": "Tegaljadi"
  },
  {
    "id": "5102072006",
    "district_id": "510207",
    "label": "Kuwum",
    "value": "Kuwum"
  },
  {
    "id": "5102072007",
    "district_id": "510207",
    "label": "Selanbawak",
    "value": "Selanbawak"
  },
  {
    "id": "5102072008",
    "district_id": "510207",
    "label": "Marga",
    "value": "Marga"
  },
  {
    "id": "5102072009",
    "district_id": "510207",
    "label": "Petiga",
    "value": "Petiga"
  },
  {
    "id": "5102072010",
    "district_id": "510207",
    "label": "Cau Belayu",
    "value": "Cau Belayu"
  },
  {
    "id": "5102072011",
    "district_id": "510207",
    "label": "Tua",
    "value": "Tua"
  },
  {
    "id": "5102072012",
    "district_id": "510207",
    "label": "Payangan",
    "value": "Payangan"
  },
  {
    "id": "5102072013",
    "district_id": "510207",
    "label": "Marga Dajan Puri",
    "value": "Marga Dajan Puri"
  },
  {
    "id": "5102072014",
    "district_id": "510207",
    "label": "Marga Dauh Puri",
    "value": "Marga Dauh Puri"
  },
  {
    "id": "5102072015",
    "district_id": "510207",
    "label": "Geluntung",
    "value": "Geluntung"
  },
  {
    "id": "5102072016",
    "district_id": "510207",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "5102082001",
    "district_id": "510208",
    "label": "Rejasa",
    "value": "Rejasa"
  },
  {
    "id": "5102082002",
    "district_id": "510208",
    "label": "Jegu",
    "value": "Jegu"
  },
  {
    "id": "5102082003",
    "district_id": "510208",
    "label": "Riang Gede",
    "value": "Riang Gede"
  },
  {
    "id": "5102082004",
    "district_id": "510208",
    "label": "Buruan",
    "value": "Buruan"
  },
  {
    "id": "5102082005",
    "district_id": "510208",
    "label": "Biaung",
    "value": "Biaung"
  },
  {
    "id": "5102082006",
    "district_id": "510208",
    "label": "Pitra",
    "value": "Pitra"
  },
  {
    "id": "5102082007",
    "district_id": "510208",
    "label": "Penatahan",
    "value": "Penatahan"
  },
  {
    "id": "5102082008",
    "district_id": "510208",
    "label": "Tengkudak",
    "value": "Tengkudak"
  },
  {
    "id": "5102082009",
    "district_id": "510208",
    "label": "Mengesta",
    "value": "Mengesta"
  },
  {
    "id": "5102082010",
    "district_id": "510208",
    "label": "Penebel",
    "value": "Penebel"
  },
  {
    "id": "5102082011",
    "district_id": "510208",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "5102082012",
    "district_id": "510208",
    "label": "Senganan",
    "value": "Senganan"
  },
  {
    "id": "5102082013",
    "district_id": "510208",
    "label": "Jatiluwih",
    "value": "Jatiluwih"
  },
  {
    "id": "5102082014",
    "district_id": "510208",
    "label": "Wongaya Gede",
    "value": "Wongaya Gede"
  },
  {
    "id": "5102082015",
    "district_id": "510208",
    "label": "Tajen",
    "value": "Tajen"
  },
  {
    "id": "5102082016",
    "district_id": "510208",
    "label": "Tegallinggah",
    "value": "Tegallinggah"
  },
  {
    "id": "5102082017",
    "district_id": "510208",
    "label": "Pesagi",
    "value": "Pesagi"
  },
  {
    "id": "5102082018",
    "district_id": "510208",
    "label": "Sangketan",
    "value": "Sangketan"
  },
  {
    "id": "5102092001",
    "district_id": "510209",
    "label": "Perean",
    "value": "Perean"
  },
  {
    "id": "5102092002",
    "district_id": "510209",
    "label": "Luwus",
    "value": "Luwus"
  },
  {
    "id": "5102092003",
    "district_id": "510209",
    "label": "Apuan",
    "value": "Apuan"
  },
  {
    "id": "5102092004",
    "district_id": "510209",
    "label": "Angseri",
    "value": "Angseri"
  },
  {
    "id": "5102092005",
    "district_id": "510209",
    "label": "Bangli",
    "value": "Bangli"
  },
  {
    "id": "5102092006",
    "district_id": "510209",
    "label": "Baturiti",
    "value": "Baturiti"
  },
  {
    "id": "5102092007",
    "district_id": "510209",
    "label": "Antapan",
    "value": "Antapan"
  },
  {
    "id": "5102092008",
    "district_id": "510209",
    "label": "Candikuning",
    "value": "Candikuning"
  },
  {
    "id": "5102092009",
    "district_id": "510209",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "5102092010",
    "district_id": "510209",
    "label": "Batunya",
    "value": "Batunya"
  },
  {
    "id": "5102092011",
    "district_id": "510209",
    "label": "Perean Tengah",
    "value": "Perean Tengah"
  },
  {
    "id": "5102092012",
    "district_id": "510209",
    "label": "Perean Kangin",
    "value": "Perean Kangin"
  },
  {
    "id": "5102102001",
    "district_id": "510210",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "5102102002",
    "district_id": "510210",
    "label": "Sanda",
    "value": "Sanda"
  },
  {
    "id": "5102102003",
    "district_id": "510210",
    "label": "Batungsel",
    "value": "Batungsel"
  },
  {
    "id": "5102102004",
    "district_id": "510210",
    "label": "Kebon Padangan",
    "value": "Kebon Padangan"
  },
  {
    "id": "5102102005",
    "district_id": "510210",
    "label": "Munduk Temu",
    "value": "Munduk Temu"
  },
  {
    "id": "5102102006",
    "district_id": "510210",
    "label": "Pujungan",
    "value": "Pujungan"
  },
  {
    "id": "5102102007",
    "district_id": "510210",
    "label": "Pupuan",
    "value": "Pupuan"
  },
  {
    "id": "5102102008",
    "district_id": "510210",
    "label": "Bantiran",
    "value": "Bantiran"
  },
  {
    "id": "5102102009",
    "district_id": "510210",
    "label": "Padangan",
    "value": "Padangan"
  },
  {
    "id": "5102102010",
    "district_id": "510210",
    "label": "Jelijih Punggung",
    "value": "Jelijih Punggung"
  },
  {
    "id": "5102102011",
    "district_id": "510210",
    "label": "Belatungan",
    "value": "Belatungan"
  },
  {
    "id": "5102102012",
    "district_id": "510210",
    "label": "Pajahan",
    "value": "Pajahan"
  },
  {
    "id": "5102102013",
    "district_id": "510210",
    "label": "Karyasari",
    "value": "Karyasari"
  },
  {
    "id": "5102102014",
    "district_id": "510210",
    "label": "Sai",
    "value": "Sai"
  },
  {
    "id": "5103011001",
    "district_id": "510301",
    "label": "Tuban",
    "value": "Tuban"
  },
  {
    "id": "5103011002",
    "district_id": "510301",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "5103011003",
    "district_id": "510301",
    "label": "Kedonganan",
    "value": "Kedonganan"
  },
  {
    "id": "5103011004",
    "district_id": "510301",
    "label": "Legian",
    "value": "Legian"
  },
  {
    "id": "5103011005",
    "district_id": "510301",
    "label": "Seminyak",
    "value": "Seminyak"
  },
  {
    "id": "5103021004",
    "district_id": "510302",
    "label": "Kapal",
    "value": "Kapal"
  },
  {
    "id": "5103021005",
    "district_id": "510302",
    "label": "Sempidi",
    "value": "Sempidi"
  },
  {
    "id": "5103021014",
    "district_id": "510302",
    "label": "Abianbase",
    "value": "Abianbase"
  },
  {
    "id": "5103021015",
    "district_id": "510302",
    "label": "Sading",
    "value": "Sading"
  },
  {
    "id": "5103021016",
    "district_id": "510302",
    "label": "Lukluk",
    "value": "Lukluk"
  },
  {
    "id": "5103022001",
    "district_id": "510302",
    "label": "Munggu",
    "value": "Munggu"
  },
  {
    "id": "5103022002",
    "district_id": "510302",
    "label": "Buduk",
    "value": "Buduk"
  },
  {
    "id": "5103022003",
    "district_id": "510302",
    "label": "Mengwitani",
    "value": "Mengwitani"
  },
  {
    "id": "5103022006",
    "district_id": "510302",
    "label": "Penarungan",
    "value": "Penarungan"
  },
  {
    "id": "5103022007",
    "district_id": "510302",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "5103022008",
    "district_id": "510302",
    "label": "Baha",
    "value": "Baha"
  },
  {
    "id": "5103022009",
    "district_id": "510302",
    "label": "Mengwi",
    "value": "Mengwi"
  },
  {
    "id": "5103022010",
    "district_id": "510302",
    "label": "Kekeran",
    "value": "Kekeran"
  },
  {
    "id": "5103022011",
    "district_id": "510302",
    "label": "Sobangan",
    "value": "Sobangan"
  },
  {
    "id": "5103022012",
    "district_id": "510302",
    "label": "Gulingan",
    "value": "Gulingan"
  },
  {
    "id": "5103022013",
    "district_id": "510302",
    "label": "Werdi Bhuwana",
    "value": "Werdi Bhuwana"
  },
  {
    "id": "5103022017",
    "district_id": "510302",
    "label": "Cemagi",
    "value": "Cemagi"
  },
  {
    "id": "5103022018",
    "district_id": "510302",
    "label": "Pererenan",
    "value": "Pererenan"
  },
  {
    "id": "5103022019",
    "district_id": "510302",
    "label": "Tumbakbayuh",
    "value": "Tumbakbayuh"
  },
  {
    "id": "5103022020",
    "district_id": "510302",
    "label": "Kuwun",
    "value": "Kuwun"
  },
  {
    "id": "5103032001",
    "district_id": "510303",
    "label": "Darmasaba",
    "value": "Darmasaba"
  },
  {
    "id": "5103032002",
    "district_id": "510303",
    "label": "Sibangkaja",
    "value": "Sibangkaja"
  },
  {
    "id": "5103032003",
    "district_id": "510303",
    "label": "Sibanggede",
    "value": "Sibanggede"
  },
  {
    "id": "5103032004",
    "district_id": "510303",
    "label": "Jagapati",
    "value": "Jagapati"
  },
  {
    "id": "5103032005",
    "district_id": "510303",
    "label": "Angantaka",
    "value": "Angantaka"
  },
  {
    "id": "5103032006",
    "district_id": "510303",
    "label": "Sedang",
    "value": "Sedang"
  },
  {
    "id": "5103032007",
    "district_id": "510303",
    "label": "Mambal",
    "value": "Mambal"
  },
  {
    "id": "5103032008",
    "district_id": "510303",
    "label": "Abiansemal",
    "value": "Abiansemal"
  },
  {
    "id": "5103032009",
    "district_id": "510303",
    "label": "Bongkasa",
    "value": "Bongkasa"
  },
  {
    "id": "5103032010",
    "district_id": "510303",
    "label": "Taman",
    "value": "Taman"
  },
  {
    "id": "5103032011",
    "district_id": "510303",
    "label": "Blahkiuh",
    "value": "Blahkiuh"
  },
  {
    "id": "5103032012",
    "district_id": "510303",
    "label": "Ayunan",
    "value": "Ayunan"
  },
  {
    "id": "5103032013",
    "district_id": "510303",
    "label": "Sangeh",
    "value": "Sangeh"
  },
  {
    "id": "5103032014",
    "district_id": "510303",
    "label": "Punggul",
    "value": "Punggul"
  },
  {
    "id": "5103032015",
    "district_id": "510303",
    "label": "Mekar Bhuwana",
    "value": "Mekar Bhuwana"
  },
  {
    "id": "5103032016",
    "district_id": "510303",
    "label": "Abiansemal Dauh Yeh Cani",
    "value": "Abiansemal Dauh Yeh Cani"
  },
  {
    "id": "5103032017",
    "district_id": "510303",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "5103032018",
    "district_id": "510303",
    "label": "Bongkasa Pertiwi",
    "value": "Bongkasa Pertiwi"
  },
  {
    "id": "5103042001",
    "district_id": "510304",
    "label": "Carangsari",
    "value": "Carangsari"
  },
  {
    "id": "5103042002",
    "district_id": "510304",
    "label": "Petang",
    "value": "Petang"
  },
  {
    "id": "5103042003",
    "district_id": "510304",
    "label": "Belok/Sidan",
    "value": "Belok/Sidan"
  },
  {
    "id": "5103042004",
    "district_id": "510304",
    "label": "Pelaga",
    "value": "Pelaga"
  },
  {
    "id": "5103042005",
    "district_id": "510304",
    "label": "Getasan",
    "value": "Getasan"
  },
  {
    "id": "5103042006",
    "district_id": "510304",
    "label": "Pangsan",
    "value": "Pangsan"
  },
  {
    "id": "5103042007",
    "district_id": "510304",
    "label": "Sulangai",
    "value": "Sulangai"
  },
  {
    "id": "5103051004",
    "district_id": "510305",
    "label": "Benoa",
    "value": "Benoa"
  },
  {
    "id": "5103051005",
    "district_id": "510305",
    "label": "Tanjung Benoa",
    "value": "Tanjung Benoa"
  },
  {
    "id": "5103051006",
    "district_id": "510305",
    "label": "Jimbaran",
    "value": "Jimbaran"
  },
  {
    "id": "5103052001",
    "district_id": "510305",
    "label": "Pecatu",
    "value": "Pecatu"
  },
  {
    "id": "5103052002",
    "district_id": "510305",
    "label": "Ungasan",
    "value": "Ungasan"
  },
  {
    "id": "5103052003",
    "district_id": "510305",
    "label": "Kutuh",
    "value": "Kutuh"
  },
  {
    "id": "5103061001",
    "district_id": "510306",
    "label": "Kerobokan Kelod",
    "value": "Kerobokan Kelod"
  },
  {
    "id": "5103061002",
    "district_id": "510306",
    "label": "Kerobokan",
    "value": "Kerobokan"
  },
  {
    "id": "5103061003",
    "district_id": "510306",
    "label": "Kerobokan Kaja",
    "value": "Kerobokan Kaja"
  },
  {
    "id": "5103062004",
    "district_id": "510306",
    "label": "Tibubeneng",
    "value": "Tibubeneng"
  },
  {
    "id": "5103062005",
    "district_id": "510306",
    "label": "Canggu",
    "value": "Canggu"
  },
  {
    "id": "5103062006",
    "district_id": "510306",
    "label": "Dalung",
    "value": "Dalung"
  },
  {
    "id": "5104012001",
    "district_id": "510401",
    "label": "Batubulan",
    "value": "Batubulan"
  },
  {
    "id": "5104012002",
    "district_id": "510401",
    "label": "Ketewel",
    "value": "Ketewel"
  },
  {
    "id": "5104012003",
    "district_id": "510401",
    "label": "Guwang",
    "value": "Guwang"
  },
  {
    "id": "5104012004",
    "district_id": "510401",
    "label": "Sukawati",
    "value": "Sukawati"
  },
  {
    "id": "5104012005",
    "district_id": "510401",
    "label": "Celuk",
    "value": "Celuk"
  },
  {
    "id": "5104012006",
    "district_id": "510401",
    "label": "Singapadu",
    "value": "Singapadu"
  },
  {
    "id": "5104012007",
    "district_id": "510401",
    "label": "Batuan",
    "value": "Batuan"
  },
  {
    "id": "5104012008",
    "district_id": "510401",
    "label": "Kemenuh",
    "value": "Kemenuh"
  },
  {
    "id": "5104012009",
    "district_id": "510401",
    "label": "Batubulan Kangin",
    "value": "Batubulan Kangin"
  },
  {
    "id": "5104012010",
    "district_id": "510401",
    "label": "Singapadu Tengah",
    "value": "Singapadu Tengah"
  },
  {
    "id": "5104012011",
    "district_id": "510401",
    "label": "Singapadu Kaler",
    "value": "Singapadu Kaler"
  },
  {
    "id": "5104012012",
    "district_id": "510401",
    "label": "Batuan Kaler",
    "value": "Batuan Kaler"
  },
  {
    "id": "5104022001",
    "district_id": "510402",
    "label": "Saba",
    "value": "Saba"
  },
  {
    "id": "5104022002",
    "district_id": "510402",
    "label": "Pering",
    "value": "Pering"
  },
  {
    "id": "5104022003",
    "district_id": "510402",
    "label": "Keramas",
    "value": "Keramas"
  },
  {
    "id": "5104022004",
    "district_id": "510402",
    "label": "Belega",
    "value": "Belega"
  },
  {
    "id": "5104022005",
    "district_id": "510402",
    "label": "Blahbatuh",
    "value": "Blahbatuh"
  },
  {
    "id": "5104022006",
    "district_id": "510402",
    "label": "Buruan",
    "value": "Buruan"
  },
  {
    "id": "5104022007",
    "district_id": "510402",
    "label": "Bedulu",
    "value": "Bedulu"
  },
  {
    "id": "5104022008",
    "district_id": "510402",
    "label": "Medahan",
    "value": "Medahan"
  },
  {
    "id": "5104022009",
    "district_id": "510402",
    "label": "Bona",
    "value": "Bona"
  },
  {
    "id": "5104031003",
    "district_id": "510403",
    "label": "Samplangan",
    "value": "Samplangan"
  },
  {
    "id": "5104031005",
    "district_id": "510403",
    "label": "Abianbase",
    "value": "Abianbase"
  },
  {
    "id": "5104031006",
    "district_id": "510403",
    "label": "Gianyar",
    "value": "Gianyar"
  },
  {
    "id": "5104031007",
    "district_id": "510403",
    "label": "Bitera",
    "value": "Bitera"
  },
  {
    "id": "5104031008",
    "district_id": "510403",
    "label": "Beng",
    "value": "Beng"
  },
  {
    "id": "5104032001",
    "district_id": "510403",
    "label": "Tulikup",
    "value": "Tulikup"
  },
  {
    "id": "5104032002",
    "district_id": "510403",
    "label": "Sidan",
    "value": "Sidan"
  },
  {
    "id": "5104032004",
    "district_id": "510403",
    "label": "Lebih",
    "value": "Lebih"
  },
  {
    "id": "5104032009",
    "district_id": "510403",
    "label": "Bakbakan",
    "value": "Bakbakan"
  },
  {
    "id": "5104032010",
    "district_id": "510403",
    "label": "Siangan",
    "value": "Siangan"
  },
  {
    "id": "5104032011",
    "district_id": "510403",
    "label": "Suwat",
    "value": "Suwat"
  },
  {
    "id": "5104032012",
    "district_id": "510403",
    "label": "Petak",
    "value": "Petak"
  },
  {
    "id": "5104032013",
    "district_id": "510403",
    "label": "Serongga",
    "value": "Serongga"
  },
  {
    "id": "5104032014",
    "district_id": "510403",
    "label": "Petak Kaja",
    "value": "Petak Kaja"
  },
  {
    "id": "5104032015",
    "district_id": "510403",
    "label": "Temesi",
    "value": "Temesi"
  },
  {
    "id": "5104032016",
    "district_id": "510403",
    "label": "Sumita",
    "value": "Sumita"
  },
  {
    "id": "5104032017",
    "district_id": "510403",
    "label": "Tegal Tugu",
    "value": "Tegal Tugu"
  },
  {
    "id": "5104042001",
    "district_id": "510404",
    "label": "Pejeng",
    "value": "Pejeng"
  },
  {
    "id": "5104042002",
    "district_id": "510404",
    "label": "Sanding",
    "value": "Sanding"
  },
  {
    "id": "5104042003",
    "district_id": "510404",
    "label": "Tampaksiring",
    "value": "Tampaksiring"
  },
  {
    "id": "5104042004",
    "district_id": "510404",
    "label": "Manukaya",
    "value": "Manukaya"
  },
  {
    "id": "5104042005",
    "district_id": "510404",
    "label": "Pejeng Kawan",
    "value": "Pejeng Kawan"
  },
  {
    "id": "5104042006",
    "district_id": "510404",
    "label": "Pejeng Kaja",
    "value": "Pejeng Kaja"
  },
  {
    "id": "5104042007",
    "district_id": "510404",
    "label": "Pejeng Kangin",
    "value": "Pejeng Kangin"
  },
  {
    "id": "5104042008",
    "district_id": "510404",
    "label": "Pejeng Kelod",
    "value": "Pejeng Kelod"
  },
  {
    "id": "5104051005",
    "district_id": "510405",
    "label": "Ubud",
    "value": "Ubud"
  },
  {
    "id": "5104052001",
    "district_id": "510405",
    "label": "Lodtunduh",
    "value": "Lodtunduh"
  },
  {
    "id": "5104052002",
    "district_id": "510405",
    "label": "Mas",
    "value": "Mas"
  },
  {
    "id": "5104052003",
    "district_id": "510405",
    "label": "Singakerta",
    "value": "Singakerta"
  },
  {
    "id": "5104052004",
    "district_id": "510405",
    "label": "Kedewatan",
    "value": "Kedewatan"
  },
  {
    "id": "5104052006",
    "district_id": "510405",
    "label": "Peliatan",
    "value": "Peliatan"
  },
  {
    "id": "5104052007",
    "district_id": "510405",
    "label": "Petulu",
    "value": "Petulu"
  },
  {
    "id": "5104052008",
    "district_id": "510405",
    "label": "Sayan",
    "value": "Sayan"
  },
  {
    "id": "5104062001",
    "district_id": "510406",
    "label": "Keliki",
    "value": "Keliki"
  },
  {
    "id": "5104062002",
    "district_id": "510406",
    "label": "Tegallalang",
    "value": "Tegallalang"
  },
  {
    "id": "5104062003",
    "district_id": "510406",
    "label": "Kenderan",
    "value": "Kenderan"
  },
  {
    "id": "5104062004",
    "district_id": "510406",
    "label": "Kedisan",
    "value": "Kedisan"
  },
  {
    "id": "5104062005",
    "district_id": "510406",
    "label": "Pupuan",
    "value": "Pupuan"
  },
  {
    "id": "5104062006",
    "district_id": "510406",
    "label": "Sebatu",
    "value": "Sebatu"
  },
  {
    "id": "5104062007",
    "district_id": "510406",
    "label": "Taro",
    "value": "Taro"
  },
  {
    "id": "5104072001",
    "district_id": "510407",
    "label": "Melinggih",
    "value": "Melinggih"
  },
  {
    "id": "5104072002",
    "district_id": "510407",
    "label": "Kelusa",
    "value": "Kelusa"
  },
  {
    "id": "5104072003",
    "district_id": "510407",
    "label": "Bukian",
    "value": "Bukian"
  },
  {
    "id": "5104072004",
    "district_id": "510407",
    "label": "Puhu",
    "value": "Puhu"
  },
  {
    "id": "5104072005",
    "district_id": "510407",
    "label": "Buahan",
    "value": "Buahan"
  },
  {
    "id": "5104072006",
    "district_id": "510407",
    "label": "Kerta",
    "value": "Kerta"
  },
  {
    "id": "5104072007",
    "district_id": "510407",
    "label": "Melinggih Kelod",
    "value": "Melinggih Kelod"
  },
  {
    "id": "5104072008",
    "district_id": "510407",
    "label": "Buahan Kaja",
    "value": "Buahan Kaja"
  },
  {
    "id": "5104072009",
    "district_id": "510407",
    "label": "Bresela",
    "value": "Bresela"
  },
  {
    "id": "5105012001",
    "district_id": "510501",
    "label": "Sakti",
    "value": "Sakti"
  },
  {
    "id": "5105012002",
    "district_id": "510501",
    "label": "Batumadeg",
    "value": "Batumadeg"
  },
  {
    "id": "5105012003",
    "district_id": "510501",
    "label": "Klumpu",
    "value": "Klumpu"
  },
  {
    "id": "5105012004",
    "district_id": "510501",
    "label": "Batukandik",
    "value": "Batukandik"
  },
  {
    "id": "5105012005",
    "district_id": "510501",
    "label": "Sekartaji",
    "value": "Sekartaji"
  },
  {
    "id": "5105012006",
    "district_id": "510501",
    "label": "Tanglad",
    "value": "Tanglad"
  },
  {
    "id": "5105012007",
    "district_id": "510501",
    "label": "Suana",
    "value": "Suana"
  },
  {
    "id": "5105012008",
    "district_id": "510501",
    "label": "Batununggul",
    "value": "Batununggul"
  },
  {
    "id": "5105012009",
    "district_id": "510501",
    "label": "Kutampi",
    "value": "Kutampi"
  },
  {
    "id": "5105012010",
    "district_id": "510501",
    "label": "Ped",
    "value": "Ped"
  },
  {
    "id": "5105012011",
    "district_id": "510501",
    "label": "Kampung Toyapakeh",
    "value": "Kampung Toyapakeh"
  },
  {
    "id": "5105012012",
    "district_id": "510501",
    "label": "Lembongan",
    "value": "Lembongan"
  },
  {
    "id": "5105012013",
    "district_id": "510501",
    "label": "Jungutbatu",
    "value": "Jungutbatu"
  },
  {
    "id": "5105012014",
    "district_id": "510501",
    "label": "Pejukutan",
    "value": "Pejukutan"
  },
  {
    "id": "5105012015",
    "district_id": "510501",
    "label": "Kutampi Kaler",
    "value": "Kutampi Kaler"
  },
  {
    "id": "5105012016",
    "district_id": "510501",
    "label": "Bunga Mekar",
    "value": "Bunga Mekar"
  },
  {
    "id": "5105022001",
    "district_id": "510502",
    "label": "Negari",
    "value": "Negari"
  },
  {
    "id": "5105022002",
    "district_id": "510502",
    "label": "Takmung",
    "value": "Takmung"
  },
  {
    "id": "5105022003",
    "district_id": "510502",
    "label": "Banjarangkan",
    "value": "Banjarangkan"
  },
  {
    "id": "5105022004",
    "district_id": "510502",
    "label": "Tusan",
    "value": "Tusan"
  },
  {
    "id": "5105022005",
    "district_id": "510502",
    "label": "Bakas",
    "value": "Bakas"
  },
  {
    "id": "5105022006",
    "district_id": "510502",
    "label": "Getakan",
    "value": "Getakan"
  },
  {
    "id": "5105022007",
    "district_id": "510502",
    "label": "Tihingan",
    "value": "Tihingan"
  },
  {
    "id": "5105022008",
    "district_id": "510502",
    "label": "Aan",
    "value": "Aan"
  },
  {
    "id": "5105022009",
    "district_id": "510502",
    "label": "Nyalian",
    "value": "Nyalian"
  },
  {
    "id": "5105022010",
    "district_id": "510502",
    "label": "Bungbungan",
    "value": "Bungbungan"
  },
  {
    "id": "5105022011",
    "district_id": "510502",
    "label": "Timuhun",
    "value": "Timuhun"
  },
  {
    "id": "5105022012",
    "district_id": "510502",
    "label": "Nyanglan",
    "value": "Nyanglan"
  },
  {
    "id": "5105022013",
    "district_id": "510502",
    "label": "Tohpati",
    "value": "Tohpati"
  },
  {
    "id": "5105031008",
    "district_id": "510503",
    "label": "Semarapura Kaja",
    "value": "Semarapura Kaja"
  },
  {
    "id": "5105031009",
    "district_id": "510503",
    "label": "Semarapura Kauh",
    "value": "Semarapura Kauh"
  },
  {
    "id": "5105031010",
    "district_id": "510503",
    "label": "Semarapura Tengah",
    "value": "Semarapura Tengah"
  },
  {
    "id": "5105031011",
    "district_id": "510503",
    "label": "Semarapura Kangin",
    "value": "Semarapura Kangin"
  },
  {
    "id": "5105031012",
    "district_id": "510503",
    "label": "Semarapura Kelod Kangin",
    "value": "Semarapura Kelod Kangin"
  },
  {
    "id": "5105031013",
    "district_id": "510503",
    "label": "Semarapura Kelod",
    "value": "Semarapura Kelod"
  },
  {
    "id": "5105032001",
    "district_id": "510503",
    "label": "Satra",
    "value": "Satra"
  },
  {
    "id": "5105032002",
    "district_id": "510503",
    "label": "Tojan",
    "value": "Tojan"
  },
  {
    "id": "5105032003",
    "district_id": "510503",
    "label": "Gelgel",
    "value": "Gelgel"
  },
  {
    "id": "5105032004",
    "district_id": "510503",
    "label": "Kampung Gelgel",
    "value": "Kampung Gelgel"
  },
  {
    "id": "5105032005",
    "district_id": "510503",
    "label": "Jumpai",
    "value": "Jumpai"
  },
  {
    "id": "5105032006",
    "district_id": "510503",
    "label": "Tangkas",
    "value": "Tangkas"
  },
  {
    "id": "5105032007",
    "district_id": "510503",
    "label": "Kamasan",
    "value": "Kamasan"
  },
  {
    "id": "5105032014",
    "district_id": "510503",
    "label": "Akah",
    "value": "Akah"
  },
  {
    "id": "5105032015",
    "district_id": "510503",
    "label": "Manduang",
    "value": "Manduang"
  },
  {
    "id": "5105032016",
    "district_id": "510503",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "5105032017",
    "district_id": "510503",
    "label": "Tegak",
    "value": "Tegak"
  },
  {
    "id": "5105032018",
    "district_id": "510503",
    "label": "Selisihan",
    "value": "Selisihan"
  },
  {
    "id": "5105042001",
    "district_id": "510504",
    "label": "Paksebali",
    "value": "Paksebali"
  },
  {
    "id": "5105042002",
    "district_id": "510504",
    "label": "Sampalan Tengah",
    "value": "Sampalan Tengah"
  },
  {
    "id": "5105042003",
    "district_id": "510504",
    "label": "Sampalan Kelod",
    "value": "Sampalan Kelod"
  },
  {
    "id": "5105042004",
    "district_id": "510504",
    "label": "Sulang",
    "value": "Sulang"
  },
  {
    "id": "5105042005",
    "district_id": "510504",
    "label": "Gunaksa",
    "value": "Gunaksa"
  },
  {
    "id": "5105042006",
    "district_id": "510504",
    "label": "Kusamba",
    "value": "Kusamba"
  },
  {
    "id": "5105042007",
    "district_id": "510504",
    "label": "Kampung Kusamba",
    "value": "Kampung Kusamba"
  },
  {
    "id": "5105042008",
    "district_id": "510504",
    "label": "Pesinggahan",
    "value": "Pesinggahan"
  },
  {
    "id": "5105042009",
    "district_id": "510504",
    "label": "Dawan Kelod",
    "value": "Dawan Kelod"
  },
  {
    "id": "5105042010",
    "district_id": "510504",
    "label": "Pikat",
    "value": "Pikat"
  },
  {
    "id": "5105042011",
    "district_id": "510504",
    "label": "Dawan Kaler",
    "value": "Dawan Kaler"
  },
  {
    "id": "5105042012",
    "district_id": "510504",
    "label": "Besan",
    "value": "Besan"
  },
  {
    "id": "5106012001",
    "district_id": "510601",
    "label": "Apuan",
    "value": "Apuan"
  },
  {
    "id": "5106012002",
    "district_id": "510601",
    "label": "Demulih",
    "value": "Demulih"
  },
  {
    "id": "5106012003",
    "district_id": "510601",
    "label": "Abuan",
    "value": "Abuan"
  },
  {
    "id": "5106012004",
    "district_id": "510601",
    "label": "Susut",
    "value": "Susut"
  },
  {
    "id": "5106012005",
    "district_id": "510601",
    "label": "Sulahan",
    "value": "Sulahan"
  },
  {
    "id": "5106012006",
    "district_id": "510601",
    "label": "Penglumbaran",
    "value": "Penglumbaran"
  },
  {
    "id": "5106012007",
    "district_id": "510601",
    "label": "Tiga",
    "value": "Tiga"
  },
  {
    "id": "5106012008",
    "district_id": "510601",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "5106012009",
    "district_id": "510601",
    "label": "Pengiangan",
    "value": "Pengiangan"
  },
  {
    "id": "5106021003",
    "district_id": "510602",
    "label": "Bebalang",
    "value": "Bebalang"
  },
  {
    "id": "5106021004",
    "district_id": "510602",
    "label": "Kawan",
    "value": "Kawan"
  },
  {
    "id": "5106021005",
    "district_id": "510602",
    "label": "Cempaga",
    "value": "Cempaga"
  },
  {
    "id": "5106021006",
    "district_id": "510602",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "5106022001",
    "district_id": "510602",
    "label": "Bunutin",
    "value": "Bunutin"
  },
  {
    "id": "5106022002",
    "district_id": "510602",
    "label": "Tamanbali",
    "value": "Tamanbali"
  },
  {
    "id": "5106022007",
    "district_id": "510602",
    "label": "Kayubihi",
    "value": "Kayubihi"
  },
  {
    "id": "5106022008",
    "district_id": "510602",
    "label": "Pengotan",
    "value": "Pengotan"
  },
  {
    "id": "5106022009",
    "district_id": "510602",
    "label": "Landih",
    "value": "Landih"
  },
  {
    "id": "5106032001",
    "district_id": "510603",
    "label": "Jehem",
    "value": "Jehem"
  },
  {
    "id": "5106032002",
    "district_id": "510603",
    "label": "Tembuku",
    "value": "Tembuku"
  },
  {
    "id": "5106032003",
    "district_id": "510603",
    "label": "Yangapi",
    "value": "Yangapi"
  },
  {
    "id": "5106032004",
    "district_id": "510603",
    "label": "Undisan",
    "value": "Undisan"
  },
  {
    "id": "5106032005",
    "district_id": "510603",
    "label": "Bangbang",
    "value": "Bangbang"
  },
  {
    "id": "5106032006",
    "district_id": "510603",
    "label": "Peninjoan",
    "value": "Peninjoan"
  },
  {
    "id": "5106042001",
    "district_id": "510604",
    "label": "Mengani",
    "value": "Mengani"
  },
  {
    "id": "5106042002",
    "district_id": "510604",
    "label": "Binyan",
    "value": "Binyan"
  },
  {
    "id": "5106042003",
    "district_id": "510604",
    "label": "Ulian",
    "value": "Ulian"
  },
  {
    "id": "5106042004",
    "district_id": "510604",
    "label": "Bunutin",
    "value": "Bunutin"
  },
  {
    "id": "5106042005",
    "district_id": "510604",
    "label": "Langgahan",
    "value": "Langgahan"
  },
  {
    "id": "5106042006",
    "district_id": "510604",
    "label": "Lembean",
    "value": "Lembean"
  },
  {
    "id": "5106042007",
    "district_id": "510604",
    "label": "Manikliyu",
    "value": "Manikliyu"
  },
  {
    "id": "5106042008",
    "district_id": "510604",
    "label": "Bayung Cerik",
    "value": "Bayung Cerik"
  },
  {
    "id": "5106042009",
    "district_id": "510604",
    "label": "Mangguh",
    "value": "Mangguh"
  },
  {
    "id": "5106042010",
    "district_id": "510604",
    "label": "Belancan",
    "value": "Belancan"
  },
  {
    "id": "5106042011",
    "district_id": "510604",
    "label": "Katung",
    "value": "Katung"
  },
  {
    "id": "5106042012",
    "district_id": "510604",
    "label": "Banua",
    "value": "Banua"
  },
  {
    "id": "5106042013",
    "district_id": "510604",
    "label": "Abuan",
    "value": "Abuan"
  },
  {
    "id": "5106042014",
    "district_id": "510604",
    "label": "Bonyoh",
    "value": "Bonyoh"
  },
  {
    "id": "5106042015",
    "district_id": "510604",
    "label": "Sekaan",
    "value": "Sekaan"
  },
  {
    "id": "5106042016",
    "district_id": "510604",
    "label": "Bayung Gede",
    "value": "Bayung Gede"
  },
  {
    "id": "5106042017",
    "district_id": "510604",
    "label": "Sekardadi",
    "value": "Sekardadi"
  },
  {
    "id": "5106042018",
    "district_id": "510604",
    "label": "Kedisan",
    "value": "Kedisan"
  },
  {
    "id": "5106042019",
    "district_id": "510604",
    "label": "Buahan",
    "value": "Buahan"
  },
  {
    "id": "5106042020",
    "district_id": "510604",
    "label": "Abangsongan",
    "value": "Abangsongan"
  },
  {
    "id": "5106042021",
    "district_id": "510604",
    "label": "Suter",
    "value": "Suter"
  },
  {
    "id": "5106042022",
    "district_id": "510604",
    "label": "Batudinding",
    "value": "Batudinding"
  },
  {
    "id": "5106042023",
    "district_id": "510604",
    "label": "Terunyan",
    "value": "Terunyan"
  },
  {
    "id": "5106042024",
    "district_id": "510604",
    "label": "Songan A",
    "value": "Songan A"
  },
  {
    "id": "5106042025",
    "district_id": "510604",
    "label": "Songan B",
    "value": "Songan B"
  },
  {
    "id": "5106042026",
    "district_id": "510604",
    "label": "Batur Selatan",
    "value": "Batur Selatan"
  },
  {
    "id": "5106042027",
    "district_id": "510604",
    "label": "Batur Tengah",
    "value": "Batur Tengah"
  },
  {
    "id": "5106042028",
    "district_id": "510604",
    "label": "Batur Utara",
    "value": "Batur Utara"
  },
  {
    "id": "5106042029",
    "district_id": "510604",
    "label": "Kintamani",
    "value": "Kintamani"
  },
  {
    "id": "5106042030",
    "district_id": "510604",
    "label": "Serai",
    "value": "Serai"
  },
  {
    "id": "5106042031",
    "district_id": "510604",
    "label": "Daup",
    "value": "Daup"
  },
  {
    "id": "5106042032",
    "district_id": "510604",
    "label": "Awan",
    "value": "Awan"
  },
  {
    "id": "5106042033",
    "district_id": "510604",
    "label": "Gunungbau",
    "value": "Gunungbau"
  },
  {
    "id": "5106042034",
    "district_id": "510604",
    "label": "Belanga",
    "value": "Belanga"
  },
  {
    "id": "5106042035",
    "district_id": "510604",
    "label": "Batukaang",
    "value": "Batukaang"
  },
  {
    "id": "5106042036",
    "district_id": "510604",
    "label": "Belantih",
    "value": "Belantih"
  },
  {
    "id": "5106042037",
    "district_id": "510604",
    "label": "Catur",
    "value": "Catur"
  },
  {
    "id": "5106042038",
    "district_id": "510604",
    "label": "Pengejaran",
    "value": "Pengejaran"
  },
  {
    "id": "5106042039",
    "district_id": "510604",
    "label": "Selulung",
    "value": "Selulung"
  },
  {
    "id": "5106042040",
    "district_id": "510604",
    "label": "Satra",
    "value": "Satra"
  },
  {
    "id": "5106042041",
    "district_id": "510604",
    "label": "Dausa",
    "value": "Dausa"
  },
  {
    "id": "5106042042",
    "district_id": "510604",
    "label": "Bantang",
    "value": "Bantang"
  },
  {
    "id": "5106042043",
    "district_id": "510604",
    "label": "Sukawana",
    "value": "Sukawana"
  },
  {
    "id": "5106042044",
    "district_id": "510604",
    "label": "Kutuh",
    "value": "Kutuh"
  },
  {
    "id": "5106042045",
    "district_id": "510604",
    "label": "Subaya",
    "value": "Subaya"
  },
  {
    "id": "5106042046",
    "district_id": "510604",
    "label": "Siakin",
    "value": "Siakin"
  },
  {
    "id": "5106042047",
    "district_id": "510604",
    "label": "Pinggan",
    "value": "Pinggan"
  },
  {
    "id": "5106042048",
    "district_id": "510604",
    "label": "Belandingan",
    "value": "Belandingan"
  },
  {
    "id": "5107012001",
    "district_id": "510701",
    "label": "Nongan",
    "value": "Nongan"
  },
  {
    "id": "5107012002",
    "district_id": "510701",
    "label": "Rendang",
    "value": "Rendang"
  },
  {
    "id": "5107012003",
    "district_id": "510701",
    "label": "Menanga",
    "value": "Menanga"
  },
  {
    "id": "5107012004",
    "district_id": "510701",
    "label": "Besakih",
    "value": "Besakih"
  },
  {
    "id": "5107012005",
    "district_id": "510701",
    "label": "Pempatan",
    "value": "Pempatan"
  },
  {
    "id": "5107012006",
    "district_id": "510701",
    "label": "Pesaban",
    "value": "Pesaban"
  },
  {
    "id": "5107022001",
    "district_id": "510702",
    "label": "Tangkup",
    "value": "Tangkup"
  },
  {
    "id": "5107022002",
    "district_id": "510702",
    "label": "Talibeng",
    "value": "Talibeng"
  },
  {
    "id": "5107022003",
    "district_id": "510702",
    "label": "Sidemen",
    "value": "Sidemen"
  },
  {
    "id": "5107022004",
    "district_id": "510702",
    "label": "Sangkan Gunung",
    "value": "Sangkan Gunung"
  },
  {
    "id": "5107022005",
    "district_id": "510702",
    "label": "Telaga Tawang",
    "value": "Telaga Tawang"
  },
  {
    "id": "5107022006",
    "district_id": "510702",
    "label": "Sinduwati",
    "value": "Sinduwati"
  },
  {
    "id": "5107022007",
    "district_id": "510702",
    "label": "Tri Eka Buana",
    "value": "Tri Eka Buana"
  },
  {
    "id": "5107022008",
    "district_id": "510702",
    "label": "Kerta Buana",
    "value": "Kerta Buana"
  },
  {
    "id": "5107022009",
    "district_id": "510702",
    "label": "Lakasari",
    "value": "Lakasari"
  },
  {
    "id": "5107022010",
    "district_id": "510702",
    "label": "Wismakerta",
    "value": "Wismakerta"
  },
  {
    "id": "5107032001",
    "district_id": "510703",
    "label": "Gegelang",
    "value": "Gegelang"
  },
  {
    "id": "5107032002",
    "district_id": "510703",
    "label": "Antiga",
    "value": "Antiga"
  },
  {
    "id": "5107032003",
    "district_id": "510703",
    "label": "Ulakan",
    "value": "Ulakan"
  },
  {
    "id": "5107032004",
    "district_id": "510703",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "5107032005",
    "district_id": "510703",
    "label": "Nyuh Tebel",
    "value": "Nyuh Tebel"
  },
  {
    "id": "5107032006",
    "district_id": "510703",
    "label": "Tenganan",
    "value": "Tenganan"
  },
  {
    "id": "5107032007",
    "district_id": "510703",
    "label": "Ngis",
    "value": "Ngis"
  },
  {
    "id": "5107032008",
    "district_id": "510703",
    "label": "Selumbung",
    "value": "Selumbung"
  },
  {
    "id": "5107032009",
    "district_id": "510703",
    "label": "Padangbai",
    "value": "Padangbai"
  },
  {
    "id": "5107032010",
    "district_id": "510703",
    "label": "Antiga Kelod",
    "value": "Antiga Kelod"
  },
  {
    "id": "5107032011",
    "district_id": "510703",
    "label": "Pasedahan",
    "value": "Pasedahan"
  },
  {
    "id": "5107032012",
    "district_id": "510703",
    "label": "Sengkidu",
    "value": "Sengkidu"
  },
  {
    "id": "5107041002",
    "district_id": "510704",
    "label": "Subagan",
    "value": "Subagan"
  },
  {
    "id": "5107041003",
    "district_id": "510704",
    "label": "Padang Kerta",
    "value": "Padang Kerta"
  },
  {
    "id": "5107041004",
    "district_id": "510704",
    "label": "Karang asem",
    "value": "Karang asem"
  },
  {
    "id": "5107042001",
    "district_id": "510704",
    "label": "Bugbug",
    "value": "Bugbug"
  },
  {
    "id": "5107042005",
    "district_id": "510704",
    "label": "Tumbu",
    "value": "Tumbu"
  },
  {
    "id": "5107042006",
    "district_id": "510704",
    "label": "Seraya",
    "value": "Seraya"
  },
  {
    "id": "5107042007",
    "district_id": "510704",
    "label": "Seraya Barat",
    "value": "Seraya Barat"
  },
  {
    "id": "5107042008",
    "district_id": "510704",
    "label": "Seraya Timur",
    "value": "Seraya Timur"
  },
  {
    "id": "5107042009",
    "district_id": "510704",
    "label": "Pertima",
    "value": "Pertima"
  },
  {
    "id": "5107042010",
    "district_id": "510704",
    "label": "Tegalinggah",
    "value": "Tegalinggah"
  },
  {
    "id": "5107042011",
    "district_id": "510704",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "5107052001",
    "district_id": "510705",
    "label": "Ababi",
    "value": "Ababi"
  },
  {
    "id": "5107052002",
    "district_id": "510705",
    "label": "Tiying Tali",
    "value": "Tiying Tali"
  },
  {
    "id": "5107052003",
    "district_id": "510705",
    "label": "Bunutan",
    "value": "Bunutan"
  },
  {
    "id": "5107052004",
    "district_id": "510705",
    "label": "Tista",
    "value": "Tista"
  },
  {
    "id": "5107052005",
    "district_id": "510705",
    "label": "Abang",
    "value": "Abang"
  },
  {
    "id": "5107052006",
    "district_id": "510705",
    "label": "Pidpid",
    "value": "Pidpid"
  },
  {
    "id": "5107052007",
    "district_id": "510705",
    "label": "Datah",
    "value": "Datah"
  },
  {
    "id": "5107052008",
    "district_id": "510705",
    "label": "Culik",
    "value": "Culik"
  },
  {
    "id": "5107052009",
    "district_id": "510705",
    "label": "Purwakerti",
    "value": "Purwakerti"
  },
  {
    "id": "5107052010",
    "district_id": "510705",
    "label": "Kerta Mandala",
    "value": "Kerta Mandala"
  },
  {
    "id": "5107052011",
    "district_id": "510705",
    "label": "Labasari",
    "value": "Labasari"
  },
  {
    "id": "5107052012",
    "district_id": "510705",
    "label": "Nawa Kerti",
    "value": "Nawa Kerti"
  },
  {
    "id": "5107052013",
    "district_id": "510705",
    "label": "Kesimpar",
    "value": "Kesimpar"
  },
  {
    "id": "5107052014",
    "district_id": "510705",
    "label": "Tribuana",
    "value": "Tribuana"
  },
  {
    "id": "5107062001",
    "district_id": "510706",
    "label": "Bungaya",
    "value": "Bungaya"
  },
  {
    "id": "5107062002",
    "district_id": "510706",
    "label": "Budekeling",
    "value": "Budekeling"
  },
  {
    "id": "5107062003",
    "district_id": "510706",
    "label": "Bebanden",
    "value": "Bebanden"
  },
  {
    "id": "5107062004",
    "district_id": "510706",
    "label": "Sibetan",
    "value": "Sibetan"
  },
  {
    "id": "5107062005",
    "district_id": "510706",
    "label": "Jungutan",
    "value": "Jungutan"
  },
  {
    "id": "5107062006",
    "district_id": "510706",
    "label": "Bungaya Kangin",
    "value": "Bungaya Kangin"
  },
  {
    "id": "5107062007",
    "district_id": "510706",
    "label": "Buana Giri",
    "value": "Buana Giri"
  },
  {
    "id": "5107062008",
    "district_id": "510706",
    "label": "Macang",
    "value": "Macang"
  },
  {
    "id": "5107072001",
    "district_id": "510707",
    "label": "Muncan",
    "value": "Muncan"
  },
  {
    "id": "5107072002",
    "district_id": "510707",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "5107072003",
    "district_id": "510707",
    "label": "Duda",
    "value": "Duda"
  },
  {
    "id": "5107072004",
    "district_id": "510707",
    "label": "Sebudi",
    "value": "Sebudi"
  },
  {
    "id": "5107072005",
    "district_id": "510707",
    "label": "Duda Utara",
    "value": "Duda Utara"
  },
  {
    "id": "5107072006",
    "district_id": "510707",
    "label": "Duda Timur",
    "value": "Duda Timur"
  },
  {
    "id": "5107072007",
    "district_id": "510707",
    "label": "Pering Sari",
    "value": "Pering Sari"
  },
  {
    "id": "5107072008",
    "district_id": "510707",
    "label": "Amerta Bhuana",
    "value": "Amerta Bhuana"
  },
  {
    "id": "5107082001",
    "district_id": "510708",
    "label": "Ban",
    "value": "Ban"
  },
  {
    "id": "5107082002",
    "district_id": "510708",
    "label": "Dukuh",
    "value": "Dukuh"
  },
  {
    "id": "5107082003",
    "district_id": "510708",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "5107082004",
    "district_id": "510708",
    "label": "Tianyar",
    "value": "Tianyar"
  },
  {
    "id": "5107082005",
    "district_id": "510708",
    "label": "Tianyar Barat",
    "value": "Tianyar Barat"
  },
  {
    "id": "5107082006",
    "district_id": "510708",
    "label": "Tianyar Tengah",
    "value": "Tianyar Tengah"
  },
  {
    "id": "5107082007",
    "district_id": "510708",
    "label": "Tulamben",
    "value": "Tulamben"
  },
  {
    "id": "5107082008",
    "district_id": "510708",
    "label": "Baturinggit",
    "value": "Baturinggit"
  },
  {
    "id": "5107082009",
    "district_id": "510708",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "5108012001",
    "district_id": "510801",
    "label": "Sumberklampok",
    "value": "Sumberklampok"
  },
  {
    "id": "5108012002",
    "district_id": "510801",
    "label": "Pejarakan",
    "value": "Pejarakan"
  },
  {
    "id": "5108012003",
    "district_id": "510801",
    "label": "Sumberkima",
    "value": "Sumberkima"
  },
  {
    "id": "5108012004",
    "district_id": "510801",
    "label": "Pemuteran",
    "value": "Pemuteran"
  },
  {
    "id": "5108012005",
    "district_id": "510801",
    "label": "Banyupoh",
    "value": "Banyupoh"
  },
  {
    "id": "5108012006",
    "district_id": "510801",
    "label": "Penyambangan",
    "value": "Penyambangan"
  },
  {
    "id": "5108012007",
    "district_id": "510801",
    "label": "Musi",
    "value": "Musi"
  },
  {
    "id": "5108012008",
    "district_id": "510801",
    "label": "Sanggalangit",
    "value": "Sanggalangit"
  },
  {
    "id": "5108012009",
    "district_id": "510801",
    "label": "Gerokgak",
    "value": "Gerokgak"
  },
  {
    "id": "5108012010",
    "district_id": "510801",
    "label": "Patas",
    "value": "Patas"
  },
  {
    "id": "5108012011",
    "district_id": "510801",
    "label": "Pengulon",
    "value": "Pengulon"
  },
  {
    "id": "5108012012",
    "district_id": "510801",
    "label": "Tinga-tinga",
    "value": "Tinga-tinga"
  },
  {
    "id": "5108012013",
    "district_id": "510801",
    "label": "Celukanbawang",
    "value": "Celukanbawang"
  },
  {
    "id": "5108012014",
    "district_id": "510801",
    "label": "Tukadsumaga",
    "value": "Tukadsumaga"
  },
  {
    "id": "5108021015",
    "district_id": "510802",
    "label": "Seririt",
    "value": "Seririt"
  },
  {
    "id": "5108022001",
    "district_id": "510802",
    "label": "Unggahan",
    "value": "Unggahan"
  },
  {
    "id": "5108022002",
    "district_id": "510802",
    "label": "Ularan",
    "value": "Ularan"
  },
  {
    "id": "5108022003",
    "district_id": "510802",
    "label": "Ringdikit",
    "value": "Ringdikit"
  },
  {
    "id": "5108022004",
    "district_id": "510802",
    "label": "Rangdu",
    "value": "Rangdu"
  },
  {
    "id": "5108022005",
    "district_id": "510802",
    "label": "Mayong",
    "value": "Mayong"
  },
  {
    "id": "5108022006",
    "district_id": "510802",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "5108022007",
    "district_id": "510802",
    "label": "Munduk Bestala",
    "value": "Munduk Bestala"
  },
  {
    "id": "5108022008",
    "district_id": "510802",
    "label": "Bestala",
    "value": "Bestala"
  },
  {
    "id": "5108022009",
    "district_id": "510802",
    "label": "Kalianget",
    "value": "Kalianget"
  },
  {
    "id": "5108022010",
    "district_id": "510802",
    "label": "Joanyar",
    "value": "Joanyar"
  },
  {
    "id": "5108022011",
    "district_id": "510802",
    "label": "Tangguwisia",
    "value": "Tangguwisia"
  },
  {
    "id": "5108022012",
    "district_id": "510802",
    "label": "Sulanyah",
    "value": "Sulanyah"
  },
  {
    "id": "5108022013",
    "district_id": "510802",
    "label": "Bubunan",
    "value": "Bubunan"
  },
  {
    "id": "5108022014",
    "district_id": "510802",
    "label": "Patemon",
    "value": "Patemon"
  },
  {
    "id": "5108022016",
    "district_id": "510802",
    "label": "Pengastulan",
    "value": "Pengastulan"
  },
  {
    "id": "5108022017",
    "district_id": "510802",
    "label": "Lokapaksa",
    "value": "Lokapaksa"
  },
  {
    "id": "5108022018",
    "district_id": "510802",
    "label": "Pangkungparuk",
    "value": "Pangkungparuk"
  },
  {
    "id": "5108022019",
    "district_id": "510802",
    "label": "Banjarasem",
    "value": "Banjarasem"
  },
  {
    "id": "5108022020",
    "district_id": "510802",
    "label": "Kalisada",
    "value": "Kalisada"
  },
  {
    "id": "5108022021",
    "district_id": "510802",
    "label": "Umeanyar",
    "value": "Umeanyar"
  },
  {
    "id": "5108032001",
    "district_id": "510803",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "5108032002",
    "district_id": "510803",
    "label": "Tista",
    "value": "Tista"
  },
  {
    "id": "5108032003",
    "district_id": "510803",
    "label": "Bongancina",
    "value": "Bongancina"
  },
  {
    "id": "5108032004",
    "district_id": "510803",
    "label": "Pucaksari",
    "value": "Pucaksari"
  },
  {
    "id": "5108032005",
    "district_id": "510803",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "5108032006",
    "district_id": "510803",
    "label": "Titab",
    "value": "Titab"
  },
  {
    "id": "5108032007",
    "district_id": "510803",
    "label": "Subuk",
    "value": "Subuk"
  },
  {
    "id": "5108032008",
    "district_id": "510803",
    "label": "Tinggarsari",
    "value": "Tinggarsari"
  },
  {
    "id": "5108032009",
    "district_id": "510803",
    "label": "Kedis",
    "value": "Kedis"
  },
  {
    "id": "5108032010",
    "district_id": "510803",
    "label": "Kekeran",
    "value": "Kekeran"
  },
  {
    "id": "5108032011",
    "district_id": "510803",
    "label": "Busungbiu",
    "value": "Busungbiu"
  },
  {
    "id": "5108032012",
    "district_id": "510803",
    "label": "Pelapuan",
    "value": "Pelapuan"
  },
  {
    "id": "5108032013",
    "district_id": "510803",
    "label": "Bengkel",
    "value": "Bengkel"
  },
  {
    "id": "5108032014",
    "district_id": "510803",
    "label": "Umejero",
    "value": "Umejero"
  },
  {
    "id": "5108032015",
    "district_id": "510803",
    "label": "Sepang Kelod",
    "value": "Sepang Kelod"
  },
  {
    "id": "5108042001",
    "district_id": "510804",
    "label": "Banyuseri",
    "value": "Banyuseri"
  },
  {
    "id": "5108042002",
    "district_id": "510804",
    "label": "Tirtasari",
    "value": "Tirtasari"
  },
  {
    "id": "5108042003",
    "district_id": "510804",
    "label": "Kayuputih",
    "value": "Kayuputih"
  },
  {
    "id": "5108042004",
    "district_id": "510804",
    "label": "Banyuatis",
    "value": "Banyuatis"
  },
  {
    "id": "5108042005",
    "district_id": "510804",
    "label": "Gesing",
    "value": "Gesing"
  },
  {
    "id": "5108042006",
    "district_id": "510804",
    "label": "Munduk",
    "value": "Munduk"
  },
  {
    "id": "5108042007",
    "district_id": "510804",
    "label": "Gobleg",
    "value": "Gobleg"
  },
  {
    "id": "5108042008",
    "district_id": "510804",
    "label": "Pedawa",
    "value": "Pedawa"
  },
  {
    "id": "5108042009",
    "district_id": "510804",
    "label": "Cempaga",
    "value": "Cempaga"
  },
  {
    "id": "5108042010",
    "district_id": "510804",
    "label": "Sidetapa",
    "value": "Sidetapa"
  },
  {
    "id": "5108042011",
    "district_id": "510804",
    "label": "Tampekan",
    "value": "Tampekan"
  },
  {
    "id": "5108042012",
    "district_id": "510804",
    "label": "Banjar Tegeha",
    "value": "Banjar Tegeha"
  },
  {
    "id": "5108042013",
    "district_id": "510804",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "5108042014",
    "district_id": "510804",
    "label": "Dencarik",
    "value": "Dencarik"
  },
  {
    "id": "5108042015",
    "district_id": "510804",
    "label": "Temukus",
    "value": "Temukus"
  },
  {
    "id": "5108042016",
    "district_id": "510804",
    "label": "Tigawasa",
    "value": "Tigawasa"
  },
  {
    "id": "5108042017",
    "district_id": "510804",
    "label": "Kaliasem",
    "value": "Kaliasem"
  },
  {
    "id": "5108051009",
    "district_id": "510805",
    "label": "Sukasada",
    "value": "Sukasada"
  },
  {
    "id": "5108052001",
    "district_id": "510805",
    "label": "Pancasari",
    "value": "Pancasari"
  },
  {
    "id": "5108052002",
    "district_id": "510805",
    "label": "Wanagiri",
    "value": "Wanagiri"
  },
  {
    "id": "5108052003",
    "district_id": "510805",
    "label": "Ambengan",
    "value": "Ambengan"
  },
  {
    "id": "5108052004",
    "district_id": "510805",
    "label": "Gitgit",
    "value": "Gitgit"
  },
  {
    "id": "5108052005",
    "district_id": "510805",
    "label": "Pegayaman",
    "value": "Pegayaman"
  },
  {
    "id": "5108052006",
    "district_id": "510805",
    "label": "Silangjana",
    "value": "Silangjana"
  },
  {
    "id": "5108052007",
    "district_id": "510805",
    "label": "Pegadungan",
    "value": "Pegadungan"
  },
  {
    "id": "5108052008",
    "district_id": "510805",
    "label": "Padangbulia",
    "value": "Padangbulia"
  },
  {
    "id": "5108052010",
    "district_id": "510805",
    "label": "Sambangan",
    "value": "Sambangan"
  },
  {
    "id": "5108052011",
    "district_id": "510805",
    "label": "Panji",
    "value": "Panji"
  },
  {
    "id": "5108052012",
    "district_id": "510805",
    "label": "Panji Anom",
    "value": "Panji Anom"
  },
  {
    "id": "5108052013",
    "district_id": "510805",
    "label": "Tegallinggah",
    "value": "Tegallinggah"
  },
  {
    "id": "5108052014",
    "district_id": "510805",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "5108052015",
    "district_id": "510805",
    "label": "Kayu Putih",
    "value": "Kayu Putih"
  },
  {
    "id": "5108061006",
    "district_id": "510806",
    "label": "Banyuasri",
    "value": "Banyuasri"
  },
  {
    "id": "5108061007",
    "district_id": "510806",
    "label": "Banjar Tegal",
    "value": "Banjar Tegal"
  },
  {
    "id": "5108061008",
    "district_id": "510806",
    "label": "Kendran",
    "value": "Kendran"
  },
  {
    "id": "5108061009",
    "district_id": "510806",
    "label": "Paket Agung",
    "value": "Paket Agung"
  },
  {
    "id": "5108061010",
    "district_id": "510806",
    "label": "Kampung Singaraja",
    "value": "Kampung Singaraja"
  },
  {
    "id": "5108061011",
    "district_id": "510806",
    "label": "Liligundi",
    "value": "Liligundi"
  },
  {
    "id": "5108061012",
    "district_id": "510806",
    "label": "Beratan",
    "value": "Beratan"
  },
  {
    "id": "5108061019",
    "district_id": "510806",
    "label": "Banyuning",
    "value": "Banyuning"
  },
  {
    "id": "5108061020",
    "district_id": "510806",
    "label": "Penarukan",
    "value": "Penarukan"
  },
  {
    "id": "5108061021",
    "district_id": "510806",
    "label": "Kampung Kajanan",
    "value": "Kampung Kajanan"
  },
  {
    "id": "5108061022",
    "district_id": "510806",
    "label": "Astina",
    "value": "Astina"
  },
  {
    "id": "5108061023",
    "district_id": "510806",
    "label": "Banjar Jawa",
    "value": "Banjar Jawa"
  },
  {
    "id": "5108061024",
    "district_id": "510806",
    "label": "Kaliuntu",
    "value": "Kaliuntu"
  },
  {
    "id": "5108061025",
    "district_id": "510806",
    "label": "Kampung Anyar",
    "value": "Kampung Anyar"
  },
  {
    "id": "5108061026",
    "district_id": "510806",
    "label": "Kampung Bugis",
    "value": "Kampung Bugis"
  },
  {
    "id": "5108061027",
    "district_id": "510806",
    "label": "Banjar Bali",
    "value": "Banjar Bali"
  },
  {
    "id": "5108061029",
    "district_id": "510806",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "5108062001",
    "district_id": "510806",
    "label": "Kalibukbuk",
    "value": "Kalibukbuk"
  },
  {
    "id": "5108062002",
    "district_id": "510806",
    "label": "Anturan",
    "value": "Anturan"
  },
  {
    "id": "5108062003",
    "district_id": "510806",
    "label": "Tukadmungga",
    "value": "Tukadmungga"
  },
  {
    "id": "5108062004",
    "district_id": "510806",
    "label": "Pemaron",
    "value": "Pemaron"
  },
  {
    "id": "5108062005",
    "district_id": "510806",
    "label": "Baktiseraga",
    "value": "Baktiseraga"
  },
  {
    "id": "5108062013",
    "district_id": "510806",
    "label": "Sarimekar",
    "value": "Sarimekar"
  },
  {
    "id": "5108062014",
    "district_id": "510806",
    "label": "Nagasepaha",
    "value": "Nagasepaha"
  },
  {
    "id": "5108062015",
    "district_id": "510806",
    "label": "Petandakan",
    "value": "Petandakan"
  },
  {
    "id": "5108062016",
    "district_id": "510806",
    "label": "Alasangker",
    "value": "Alasangker"
  },
  {
    "id": "5108062017",
    "district_id": "510806",
    "label": "Poh Bergong",
    "value": "Poh Bergong"
  },
  {
    "id": "5108062018",
    "district_id": "510806",
    "label": "Jinangdalem",
    "value": "Jinangdalem"
  },
  {
    "id": "5108062028",
    "district_id": "510806",
    "label": "Penglatan",
    "value": "Penglatan"
  },
  {
    "id": "5108072001",
    "district_id": "510807",
    "label": "Lemukih",
    "value": "Lemukih"
  },
  {
    "id": "5108072002",
    "district_id": "510807",
    "label": "Galungan",
    "value": "Galungan"
  },
  {
    "id": "5108072003",
    "district_id": "510807",
    "label": "Sekumpul",
    "value": "Sekumpul"
  },
  {
    "id": "5108072004",
    "district_id": "510807",
    "label": "Bebetin",
    "value": "Bebetin"
  },
  {
    "id": "5108072005",
    "district_id": "510807",
    "label": "Sudaji",
    "value": "Sudaji"
  },
  {
    "id": "5108072006",
    "district_id": "510807",
    "label": "Sawan",
    "value": "Sawan"
  },
  {
    "id": "5108072007",
    "district_id": "510807",
    "label": "Menyali",
    "value": "Menyali"
  },
  {
    "id": "5108072008",
    "district_id": "510807",
    "label": "Suwug",
    "value": "Suwug"
  },
  {
    "id": "5108072009",
    "district_id": "510807",
    "label": "Jagaraga",
    "value": "Jagaraga"
  },
  {
    "id": "5108072010",
    "district_id": "510807",
    "label": "Sinabun",
    "value": "Sinabun"
  },
  {
    "id": "5108072011",
    "district_id": "510807",
    "label": "Kerobokan",
    "value": "Kerobokan"
  },
  {
    "id": "5108072012",
    "district_id": "510807",
    "label": "Sangsit",
    "value": "Sangsit"
  },
  {
    "id": "5108072013",
    "district_id": "510807",
    "label": "Bungkulan",
    "value": "Bungkulan"
  },
  {
    "id": "5108072014",
    "district_id": "510807",
    "label": "Giri Emas",
    "value": "Giri Emas"
  },
  {
    "id": "5108082001",
    "district_id": "510808",
    "label": "Tambakan",
    "value": "Tambakan"
  },
  {
    "id": "5108082002",
    "district_id": "510808",
    "label": "Pakisan",
    "value": "Pakisan"
  },
  {
    "id": "5108082003",
    "district_id": "510808",
    "label": "Bontihing",
    "value": "Bontihing"
  },
  {
    "id": "5108082004",
    "district_id": "510808",
    "label": "Tajun",
    "value": "Tajun"
  },
  {
    "id": "5108082005",
    "district_id": "510808",
    "label": "Tunjung",
    "value": "Tunjung"
  },
  {
    "id": "5108082006",
    "district_id": "510808",
    "label": "Depeha",
    "value": "Depeha"
  },
  {
    "id": "5108082007",
    "district_id": "510808",
    "label": "Tamblang",
    "value": "Tamblang"
  },
  {
    "id": "5108082008",
    "district_id": "510808",
    "label": "Bulian",
    "value": "Bulian"
  },
  {
    "id": "5108082009",
    "district_id": "510808",
    "label": "Bila",
    "value": "Bila"
  },
  {
    "id": "5108082010",
    "district_id": "510808",
    "label": "Bengkala",
    "value": "Bengkala"
  },
  {
    "id": "5108082011",
    "district_id": "510808",
    "label": "Kubutambahan",
    "value": "Kubutambahan"
  },
  {
    "id": "5108082012",
    "district_id": "510808",
    "label": "Bukti",
    "value": "Bukti"
  },
  {
    "id": "5108082013",
    "district_id": "510808",
    "label": "Mengening",
    "value": "Mengening"
  },
  {
    "id": "5108092001",
    "district_id": "510809",
    "label": "Sembiran",
    "value": "Sembiran"
  },
  {
    "id": "5108092002",
    "district_id": "510809",
    "label": "Pacung",
    "value": "Pacung"
  },
  {
    "id": "5108092003",
    "district_id": "510809",
    "label": "Julah",
    "value": "Julah"
  },
  {
    "id": "5108092004",
    "district_id": "510809",
    "label": "Madenan",
    "value": "Madenan"
  },
  {
    "id": "5108092005",
    "district_id": "510809",
    "label": "Bondalem",
    "value": "Bondalem"
  },
  {
    "id": "5108092006",
    "district_id": "510809",
    "label": "Tejakula",
    "value": "Tejakula"
  },
  {
    "id": "5108092007",
    "district_id": "510809",
    "label": "Les",
    "value": "Les"
  },
  {
    "id": "5108092008",
    "district_id": "510809",
    "label": "Penuktukan",
    "value": "Penuktukan"
  },
  {
    "id": "5108092009",
    "district_id": "510809",
    "label": "Sambirenteng",
    "value": "Sambirenteng"
  },
  {
    "id": "5108092010",
    "district_id": "510809",
    "label": "Tembok",
    "value": "Tembok"
  },
  {
    "id": "5171011001",
    "district_id": "517101",
    "label": "Serangan",
    "value": "Serangan"
  },
  {
    "id": "5171011002",
    "district_id": "517101",
    "label": "Pedungan",
    "value": "Pedungan"
  },
  {
    "id": "5171011003",
    "district_id": "517101",
    "label": "Sesetan",
    "value": "Sesetan"
  },
  {
    "id": "5171011004",
    "district_id": "517101",
    "label": "Panjer",
    "value": "Panjer"
  },
  {
    "id": "5171011005",
    "district_id": "517101",
    "label": "Renon",
    "value": "Renon"
  },
  {
    "id": "5171011006",
    "district_id": "517101",
    "label": "Sanur",
    "value": "Sanur"
  },
  {
    "id": "5171012007",
    "district_id": "517101",
    "label": "Sidakarya",
    "value": "Sidakarya"
  },
  {
    "id": "5171012008",
    "district_id": "517101",
    "label": "Pemogan",
    "value": "Pemogan"
  },
  {
    "id": "5171012009",
    "district_id": "517101",
    "label": "Sanur Kaja",
    "value": "Sanur Kaja"
  },
  {
    "id": "5171012010",
    "district_id": "517101",
    "label": "Sanur Kauh",
    "value": "Sanur Kauh"
  },
  {
    "id": "5171021003",
    "district_id": "517102",
    "label": "Kesiman",
    "value": "Kesiman"
  },
  {
    "id": "5171021006",
    "district_id": "517102",
    "label": "Sumerta",
    "value": "Sumerta"
  },
  {
    "id": "5171021010",
    "district_id": "517102",
    "label": "Dangin Puri",
    "value": "Dangin Puri"
  },
  {
    "id": "5171021014",
    "district_id": "517102",
    "label": "Penatih",
    "value": "Penatih"
  },
  {
    "id": "5171022001",
    "district_id": "517102",
    "label": "Dangin Puri Kelod",
    "value": "Dangin Puri Kelod"
  },
  {
    "id": "5171022002",
    "district_id": "517102",
    "label": "Sumerta Kelod",
    "value": "Sumerta Kelod"
  },
  {
    "id": "5171022004",
    "district_id": "517102",
    "label": "Kesiman Petilan",
    "value": "Kesiman Petilan"
  },
  {
    "id": "5171022005",
    "district_id": "517102",
    "label": "Kesiman Kertalangu",
    "value": "Kesiman Kertalangu"
  },
  {
    "id": "5171022007",
    "district_id": "517102",
    "label": "Sumerta Kaja",
    "value": "Sumerta Kaja"
  },
  {
    "id": "5171022008",
    "district_id": "517102",
    "label": "Sumerta Kauh",
    "value": "Sumerta Kauh"
  },
  {
    "id": "5171022015",
    "district_id": "517102",
    "label": "Penatih Dangin Puri",
    "value": "Penatih Dangin Puri"
  },
  {
    "id": "5171031005",
    "district_id": "517103",
    "label": "Dauh Puri",
    "value": "Dauh Puri"
  },
  {
    "id": "5171031007",
    "district_id": "517103",
    "label": "Pemecutan",
    "value": "Pemecutan"
  },
  {
    "id": "5171031010",
    "district_id": "517103",
    "label": "Padangsambian",
    "value": "Padangsambian"
  },
  {
    "id": "5171032001",
    "district_id": "517103",
    "label": "Padangsambian Kelod",
    "value": "Padangsambian Kelod"
  },
  {
    "id": "5171032002",
    "district_id": "517103",
    "label": "Pemecutan Kelod",
    "value": "Pemecutan Kelod"
  },
  {
    "id": "5171032003",
    "district_id": "517103",
    "label": "Dauh Puri Kauh",
    "value": "Dauh Puri Kauh"
  },
  {
    "id": "5171032004",
    "district_id": "517103",
    "label": "Dauh Puri Kelod",
    "value": "Dauh Puri Kelod"
  },
  {
    "id": "5171032006",
    "district_id": "517103",
    "label": "Dauh Puri Kangin",
    "value": "Dauh Puri Kangin"
  },
  {
    "id": "5171032008",
    "district_id": "517103",
    "label": "Tegal Harum",
    "value": "Tegal Harum"
  },
  {
    "id": "5171032009",
    "district_id": "517103",
    "label": "Tegal Kertha",
    "value": "Tegal Kertha"
  },
  {
    "id": "5171032011",
    "district_id": "517103",
    "label": "Padang Sambian Kaja",
    "value": "Padang Sambian Kaja"
  },
  {
    "id": "5171041004",
    "district_id": "517104",
    "label": "Tonja",
    "value": "Tonja"
  },
  {
    "id": "5171041007",
    "district_id": "517104",
    "label": "Ubung",
    "value": "Ubung"
  },
  {
    "id": "5171041009",
    "district_id": "517104",
    "label": "Peguyangan",
    "value": "Peguyangan"
  },
  {
    "id": "5171042001",
    "district_id": "517104",
    "label": "Dangin Puri Kangin",
    "value": "Dangin Puri Kangin"
  },
  {
    "id": "5171042002",
    "district_id": "517104",
    "label": "Dangin Puri Kauh",
    "value": "Dangin Puri Kauh"
  },
  {
    "id": "5171042003",
    "district_id": "517104",
    "label": "Dangin Puri Kaja",
    "value": "Dangin Puri Kaja"
  },
  {
    "id": "5171042005",
    "district_id": "517104",
    "label": "Pemecutan Kaja",
    "value": "Pemecutan Kaja"
  },
  {
    "id": "5171042006",
    "district_id": "517104",
    "label": "Dauh Puri Kaja",
    "value": "Dauh Puri Kaja"
  },
  {
    "id": "5171042008",
    "district_id": "517104",
    "label": "Ubung Kaja",
    "value": "Ubung Kaja"
  },
  {
    "id": "5171042010",
    "district_id": "517104",
    "label": "Peguyangan Kaja",
    "value": "Peguyangan Kaja"
  },
  {
    "id": "5171042011",
    "district_id": "517104",
    "label": "Peguyangan Kangin",
    "value": "Peguyangan Kangin"
  },
  {
    "id": "5201011001",
    "district_id": "520101",
    "label": "Gerung Utara",
    "value": "Gerung Utara"
  },
  {
    "id": "5201011004",
    "district_id": "520101",
    "label": "Dasan Geres",
    "value": "Dasan Geres"
  },
  {
    "id": "5201011011",
    "district_id": "520101",
    "label": "Gerung Selatan",
    "value": "Gerung Selatan"
  },
  {
    "id": "5201012002",
    "district_id": "520101",
    "label": "Kebon Ayu",
    "value": "Kebon Ayu"
  },
  {
    "id": "5201012003",
    "district_id": "520101",
    "label": "Gapuk",
    "value": "Gapuk"
  },
  {
    "id": "5201012005",
    "district_id": "520101",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "5201012006",
    "district_id": "520101",
    "label": "Banyu Urip",
    "value": "Banyu Urip"
  },
  {
    "id": "5201012007",
    "district_id": "520101",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "5201012008",
    "district_id": "520101",
    "label": "Dasan Tapen",
    "value": "Dasan Tapen"
  },
  {
    "id": "5201012009",
    "district_id": "520101",
    "label": "Beleke",
    "value": "Beleke"
  },
  {
    "id": "5201012010",
    "district_id": "520101",
    "label": "Tempos",
    "value": "Tempos"
  },
  {
    "id": "5201012012",
    "district_id": "520101",
    "label": "Mesanggok",
    "value": "Mesanggok"
  },
  {
    "id": "5201012013",
    "district_id": "520101",
    "label": "Taman Ayu",
    "value": "Taman Ayu"
  },
  {
    "id": "5201012014",
    "district_id": "520101",
    "label": "Giri Tembesi",
    "value": "Giri Tembesi"
  },
  {
    "id": "5201022001",
    "district_id": "520102",
    "label": "Kediri",
    "value": "Kediri"
  },
  {
    "id": "5201022002",
    "district_id": "520102",
    "label": "Montong Are",
    "value": "Montong Are"
  },
  {
    "id": "5201022003",
    "district_id": "520102",
    "label": "Jagaraga Indah",
    "value": "Jagaraga Indah"
  },
  {
    "id": "5201022004",
    "district_id": "520102",
    "label": "Gelogor",
    "value": "Gelogor"
  },
  {
    "id": "5201022005",
    "district_id": "520102",
    "label": "Rumak",
    "value": "Rumak"
  },
  {
    "id": "5201022006",
    "district_id": "520102",
    "label": "Banyumulek",
    "value": "Banyumulek"
  },
  {
    "id": "5201022007",
    "district_id": "520102",
    "label": "Ombe Baru",
    "value": "Ombe Baru"
  },
  {
    "id": "5201022008",
    "district_id": "520102",
    "label": "Dasan Baru",
    "value": "Dasan Baru"
  },
  {
    "id": "5201022009",
    "district_id": "520102",
    "label": "Kediri Selatan",
    "value": "Kediri Selatan"
  },
  {
    "id": "5201022010",
    "district_id": "520102",
    "label": "Lelede",
    "value": "Lelede"
  },
  {
    "id": "5201032001",
    "district_id": "520103",
    "label": "Lembuak",
    "value": "Lembuak"
  },
  {
    "id": "5201032002",
    "district_id": "520103",
    "label": "Nyur Lembang",
    "value": "Nyur Lembang"
  },
  {
    "id": "5201032003",
    "district_id": "520103",
    "label": "Peresak",
    "value": "Peresak"
  },
  {
    "id": "5201032004",
    "district_id": "520103",
    "label": "Keru",
    "value": "Keru"
  },
  {
    "id": "5201032005",
    "district_id": "520103",
    "label": "Batu Kuta",
    "value": "Batu Kuta"
  },
  {
    "id": "5201032006",
    "district_id": "520103",
    "label": "Tanak Beak",
    "value": "Tanak Beak"
  },
  {
    "id": "5201032007",
    "district_id": "520103",
    "label": "Sedau",
    "value": "Sedau"
  },
  {
    "id": "5201032008",
    "district_id": "520103",
    "label": "Suranadi",
    "value": "Suranadi"
  },
  {
    "id": "5201032009",
    "district_id": "520103",
    "label": "Selat",
    "value": "Selat"
  },
  {
    "id": "5201032010",
    "district_id": "520103",
    "label": "Lebah Sempage",
    "value": "Lebah Sempage"
  },
  {
    "id": "5201032011",
    "district_id": "520103",
    "label": "Sesaot",
    "value": "Sesaot"
  },
  {
    "id": "5201032012",
    "district_id": "520103",
    "label": "Dasan Tereng",
    "value": "Dasan Tereng"
  },
  {
    "id": "5201032013",
    "district_id": "520103",
    "label": "Badrain",
    "value": "Badrain"
  },
  {
    "id": "5201032014",
    "district_id": "520103",
    "label": "Sembung",
    "value": "Sembung"
  },
  {
    "id": "5201032015",
    "district_id": "520103",
    "label": "Kramat Jaya",
    "value": "Kramat Jaya"
  },
  {
    "id": "5201032016",
    "district_id": "520103",
    "label": "Grimax Indah",
    "value": "Grimax Indah"
  },
  {
    "id": "5201032017",
    "district_id": "520103",
    "label": "Pakuan",
    "value": "Pakuan"
  },
  {
    "id": "5201032018",
    "district_id": "520103",
    "label": "Golong",
    "value": "Golong"
  },
  {
    "id": "5201032019",
    "district_id": "520103",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "5201032020",
    "district_id": "520103",
    "label": "Buwun Sejati",
    "value": "Buwun Sejati"
  },
  {
    "id": "5201032021",
    "district_id": "520103",
    "label": "Narmada",
    "value": "Narmada"
  },
  {
    "id": "5201072001",
    "district_id": "520107",
    "label": "Sekotong Tengah",
    "value": "Sekotong Tengah"
  },
  {
    "id": "5201072002",
    "district_id": "520107",
    "label": "Sekotong Barat",
    "value": "Sekotong Barat"
  },
  {
    "id": "5201072003",
    "district_id": "520107",
    "label": "Pelangan",
    "value": "Pelangan"
  },
  {
    "id": "5201072004",
    "district_id": "520107",
    "label": "Buwun Mas",
    "value": "Buwun Mas"
  },
  {
    "id": "5201072005",
    "district_id": "520107",
    "label": "Kedaro",
    "value": "Kedaro"
  },
  {
    "id": "5201072006",
    "district_id": "520107",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "5201072007",
    "district_id": "520107",
    "label": "Gili Gede Indah",
    "value": "Gili Gede Indah"
  },
  {
    "id": "5201072008",
    "district_id": "520107",
    "label": "Cendi Manik",
    "value": "Cendi Manik"
  },
  {
    "id": "5201072009",
    "district_id": "520107",
    "label": "Taman Baru",
    "value": "Taman Baru"
  },
  {
    "id": "5201082001",
    "district_id": "520108",
    "label": "Bengkel",
    "value": "Bengkel"
  },
  {
    "id": "5201082002",
    "district_id": "520108",
    "label": "Merembu",
    "value": "Merembu"
  },
  {
    "id": "5201082003",
    "district_id": "520108",
    "label": "Bagik Polak",
    "value": "Bagik Polak"
  },
  {
    "id": "5201082004",
    "district_id": "520108",
    "label": "Telagawaru",
    "value": "Telagawaru"
  },
  {
    "id": "5201082005",
    "district_id": "520108",
    "label": "Perampuan",
    "value": "Perampuan"
  },
  {
    "id": "5201082006",
    "district_id": "520108",
    "label": "Bajur",
    "value": "Bajur"
  },
  {
    "id": "5201082007",
    "district_id": "520108",
    "label": "Terong Tawah",
    "value": "Terong Tawah"
  },
  {
    "id": "5201082008",
    "district_id": "520108",
    "label": "Kuranji",
    "value": "Kuranji"
  },
  {
    "id": "5201082009",
    "district_id": "520108",
    "label": "Karang Bongkot",
    "value": "Karang Bongkot"
  },
  {
    "id": "5201082010",
    "district_id": "520108",
    "label": "Labuapi",
    "value": "Labuapi"
  },
  {
    "id": "5201082011",
    "district_id": "520108",
    "label": "Bagik Polak Barat",
    "value": "Bagik Polak Barat"
  },
  {
    "id": "5201082012",
    "district_id": "520108",
    "label": "Kuranji Dalang",
    "value": "Kuranji Dalang"
  },
  {
    "id": "5201092001",
    "district_id": "520109",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "5201092002",
    "district_id": "520109",
    "label": "Midang",
    "value": "Midang"
  },
  {
    "id": "5201092003",
    "district_id": "520109",
    "label": "Sesele",
    "value": "Sesele"
  },
  {
    "id": "5201092004",
    "district_id": "520109",
    "label": "Kekait",
    "value": "Kekait"
  },
  {
    "id": "5201092005",
    "district_id": "520109",
    "label": "Kekeri",
    "value": "Kekeri"
  },
  {
    "id": "5201092006",
    "district_id": "520109",
    "label": "Mambalan",
    "value": "Mambalan"
  },
  {
    "id": "5201092007",
    "district_id": "520109",
    "label": "Dopang",
    "value": "Dopang"
  },
  {
    "id": "5201092008",
    "district_id": "520109",
    "label": "Penimbung",
    "value": "Penimbung"
  },
  {
    "id": "5201092009",
    "district_id": "520109",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "5201092010",
    "district_id": "520109",
    "label": "Jatisela",
    "value": "Jatisela"
  },
  {
    "id": "5201092011",
    "district_id": "520109",
    "label": "Guntur Macan",
    "value": "Guntur Macan"
  },
  {
    "id": "5201092012",
    "district_id": "520109",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "5201092013",
    "district_id": "520109",
    "label": "Ranjok",
    "value": "Ranjok"
  },
  {
    "id": "5201092014",
    "district_id": "520109",
    "label": "Gelangsar",
    "value": "Gelangsar"
  },
  {
    "id": "5201092015",
    "district_id": "520109",
    "label": "Jeringo",
    "value": "Jeringo"
  },
  {
    "id": "5201092016",
    "district_id": "520109",
    "label": "Bukittinggi",
    "value": "Bukittinggi"
  },
  {
    "id": "5201122001",
    "district_id": "520112",
    "label": "Lingsar",
    "value": "Lingsar"
  },
  {
    "id": "5201122002",
    "district_id": "520112",
    "label": "Batu Kumbang",
    "value": "Batu Kumbang"
  },
  {
    "id": "5201122003",
    "district_id": "520112",
    "label": "Sigerongan",
    "value": "Sigerongan"
  },
  {
    "id": "5201122004",
    "district_id": "520112",
    "label": "Duman",
    "value": "Duman"
  },
  {
    "id": "5201122005",
    "district_id": "520112",
    "label": "Karang Bayan",
    "value": "Karang Bayan"
  },
  {
    "id": "5201122006",
    "district_id": "520112",
    "label": "Langko",
    "value": "Langko"
  },
  {
    "id": "5201122007",
    "district_id": "520112",
    "label": "Dasan Geria",
    "value": "Dasan Geria"
  },
  {
    "id": "5201122008",
    "district_id": "520112",
    "label": "Peteluan Indah",
    "value": "Peteluan Indah"
  },
  {
    "id": "5201122009",
    "district_id": "520112",
    "label": "Gegerung",
    "value": "Gegerung"
  },
  {
    "id": "5201122010",
    "district_id": "520112",
    "label": "Batu Mekar",
    "value": "Batu Mekar"
  },
  {
    "id": "5201122011",
    "district_id": "520112",
    "label": "Giri Madia",
    "value": "Giri Madia"
  },
  {
    "id": "5201122012",
    "district_id": "520112",
    "label": "Bug-Bug",
    "value": "Bug-Bug"
  },
  {
    "id": "5201122013",
    "district_id": "520112",
    "label": "Gegelang",
    "value": "Gegelang"
  },
  {
    "id": "5201122014",
    "district_id": "520112",
    "label": "Saribaye",
    "value": "Saribaye"
  },
  {
    "id": "5201122015",
    "district_id": "520112",
    "label": "Gontoran",
    "value": "Gontoran"
  },
  {
    "id": "5201132001",
    "district_id": "520113",
    "label": "Lembar",
    "value": "Lembar"
  },
  {
    "id": "5201132002",
    "district_id": "520113",
    "label": "Jembatan Kembar",
    "value": "Jembatan Kembar"
  },
  {
    "id": "5201132003",
    "district_id": "520113",
    "label": "Mareje",
    "value": "Mareje"
  },
  {
    "id": "5201132004",
    "district_id": "520113",
    "label": "Sekotong Timur",
    "value": "Sekotong Timur"
  },
  {
    "id": "5201132005",
    "district_id": "520113",
    "label": "Labuan Tereng",
    "value": "Labuan Tereng"
  },
  {
    "id": "5201132006",
    "district_id": "520113",
    "label": "Jembatan Gantung",
    "value": "Jembatan Gantung"
  },
  {
    "id": "5201132007",
    "district_id": "520113",
    "label": "Lembar Selatan",
    "value": "Lembar Selatan"
  },
  {
    "id": "5201132008",
    "district_id": "520113",
    "label": "Mareje Timur",
    "value": "Mareje Timur"
  },
  {
    "id": "5201132009",
    "district_id": "520113",
    "label": "Jembatan Kembar Timur",
    "value": "Jembatan Kembar Timur"
  },
  {
    "id": "5201132010",
    "district_id": "520113",
    "label": "Eyat Mayang",
    "value": "Eyat Mayang"
  },
  {
    "id": "5201142001",
    "district_id": "520114",
    "label": "Batulayar",
    "value": "Batulayar"
  },
  {
    "id": "5201142002",
    "district_id": "520114",
    "label": "Meninting",
    "value": "Meninting"
  },
  {
    "id": "5201142003",
    "district_id": "520114",
    "label": "Sandik",
    "value": "Sandik"
  },
  {
    "id": "5201142004",
    "district_id": "520114",
    "label": "Lembah Sari",
    "value": "Lembah Sari"
  },
  {
    "id": "5201142005",
    "district_id": "520114",
    "label": "Senteluk",
    "value": "Senteluk"
  },
  {
    "id": "5201142006",
    "district_id": "520114",
    "label": "Senggigi",
    "value": "Senggigi"
  },
  {
    "id": "5201142007",
    "district_id": "520114",
    "label": "Bengkaung",
    "value": "Bengkaung"
  },
  {
    "id": "5201142008",
    "district_id": "520114",
    "label": "Pusuk Lestari",
    "value": "Pusuk Lestari"
  },
  {
    "id": "5201142009",
    "district_id": "520114",
    "label": "Batu Layar Barat",
    "value": "Batu Layar Barat"
  },
  {
    "id": "5201152001",
    "district_id": "520115",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "5201152002",
    "district_id": "520115",
    "label": "Jagaraga",
    "value": "Jagaraga"
  },
  {
    "id": "5201152003",
    "district_id": "520115",
    "label": "Kuripan Utara",
    "value": "Kuripan Utara"
  },
  {
    "id": "5201152004",
    "district_id": "520115",
    "label": "Kuripan Selatan",
    "value": "Kuripan Selatan"
  },
  {
    "id": "5201152005",
    "district_id": "520115",
    "label": "Kuripan Timur",
    "value": "Kuripan Timur"
  },
  {
    "id": "5201152006",
    "district_id": "520115",
    "label": "Giri Sasak",
    "value": "Giri Sasak"
  },
  {
    "id": "5202011001",
    "district_id": "520201",
    "label": "Praya",
    "value": "Praya"
  },
  {
    "id": "5202011002",
    "district_id": "520201",
    "label": "Leneng",
    "value": "Leneng"
  },
  {
    "id": "5202011003",
    "district_id": "520201",
    "label": "Gerunung",
    "value": "Gerunung"
  },
  {
    "id": "5202011004",
    "district_id": "520201",
    "label": "Semayan",
    "value": "Semayan"
  },
  {
    "id": "5202011005",
    "district_id": "520201",
    "label": "Perapen",
    "value": "Perapen"
  },
  {
    "id": "5202011006",
    "district_id": "520201",
    "label": "Tiwugalih",
    "value": "Tiwugalih"
  },
  {
    "id": "5202011007",
    "district_id": "520201",
    "label": "Gonjak",
    "value": "Gonjak"
  },
  {
    "id": "5202011008",
    "district_id": "520201",
    "label": "Panji Sari",
    "value": "Panji Sari"
  },
  {
    "id": "5202011009",
    "district_id": "520201",
    "label": "Renteng",
    "value": "Renteng"
  },
  {
    "id": "5202012010",
    "district_id": "520201",
    "label": "Mertak Tombok",
    "value": "Mertak Tombok"
  },
  {
    "id": "5202012011",
    "district_id": "520201",
    "label": "Aik Mual",
    "value": "Aik Mual"
  },
  {
    "id": "5202012012",
    "district_id": "520201",
    "label": "Montong Terep",
    "value": "Montong Terep"
  },
  {
    "id": "5202012013",
    "district_id": "520201",
    "label": "Jago",
    "value": "Jago"
  },
  {
    "id": "5202012014",
    "district_id": "520201",
    "label": "Bunut Baok",
    "value": "Bunut Baok"
  },
  {
    "id": "5202012015",
    "district_id": "520201",
    "label": "Mekar Damai",
    "value": "Mekar Damai"
  },
  {
    "id": "5202022001",
    "district_id": "520202",
    "label": "Barejulat",
    "value": "Barejulat"
  },
  {
    "id": "5202022002",
    "district_id": "520202",
    "label": "Ubung",
    "value": "Ubung"
  },
  {
    "id": "5202022003",
    "district_id": "520202",
    "label": "Jelantik",
    "value": "Jelantik"
  },
  {
    "id": "5202022004",
    "district_id": "520202",
    "label": "Labulia",
    "value": "Labulia"
  },
  {
    "id": "5202022005",
    "district_id": "520202",
    "label": "Batu Tulis",
    "value": "Batu Tulis"
  },
  {
    "id": "5202022006",
    "district_id": "520202",
    "label": "Perina",
    "value": "Perina"
  },
  {
    "id": "5202022007",
    "district_id": "520202",
    "label": "Pengenjek",
    "value": "Pengenjek"
  },
  {
    "id": "5202022008",
    "district_id": "520202",
    "label": "Puyung",
    "value": "Puyung"
  },
  {
    "id": "5202022009",
    "district_id": "520202",
    "label": "Nyerot",
    "value": "Nyerot"
  },
  {
    "id": "5202022010",
    "district_id": "520202",
    "label": "Sukarara",
    "value": "Sukarara"
  },
  {
    "id": "5202022011",
    "district_id": "520202",
    "label": "Gemel",
    "value": "Gemel"
  },
  {
    "id": "5202022012",
    "district_id": "520202",
    "label": "Bonjeruk",
    "value": "Bonjeruk"
  },
  {
    "id": "5202022013",
    "district_id": "520202",
    "label": "Bunkate",
    "value": "Bunkate"
  },
  {
    "id": "5202032001",
    "district_id": "520203",
    "label": "Bujak",
    "value": "Bujak"
  },
  {
    "id": "5202032002",
    "district_id": "520203",
    "label": "Selebung",
    "value": "Selebung"
  },
  {
    "id": "5202032003",
    "district_id": "520203",
    "label": "Peresak",
    "value": "Peresak"
  },
  {
    "id": "5202032004",
    "district_id": "520203",
    "label": "Mantang",
    "value": "Mantang"
  },
  {
    "id": "5202032005",
    "district_id": "520203",
    "label": "Aik Darek",
    "value": "Aik Darek"
  },
  {
    "id": "5202032006",
    "district_id": "520203",
    "label": "Tampak Siring",
    "value": "Tampak Siring"
  },
  {
    "id": "5202032007",
    "district_id": "520203",
    "label": "Barabali",
    "value": "Barabali"
  },
  {
    "id": "5202032008",
    "district_id": "520203",
    "label": "Beber",
    "value": "Beber"
  },
  {
    "id": "5202032009",
    "district_id": "520203",
    "label": "Pagutan",
    "value": "Pagutan"
  },
  {
    "id": "5202032010",
    "district_id": "520203",
    "label": "Mekar Bersatu",
    "value": "Mekar Bersatu"
  },
  {
    "id": "5202042001",
    "district_id": "520204",
    "label": "Sengkol",
    "value": "Sengkol"
  },
  {
    "id": "5202042002",
    "district_id": "520204",
    "label": "Segala Anyar",
    "value": "Segala Anyar"
  },
  {
    "id": "5202042003",
    "district_id": "520204",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "5202042004",
    "district_id": "520204",
    "label": "Teruwai",
    "value": "Teruwai"
  },
  {
    "id": "5202042005",
    "district_id": "520204",
    "label": "Pengengat",
    "value": "Pengengat"
  },
  {
    "id": "5202042006",
    "district_id": "520204",
    "label": "Kawo",
    "value": "Kawo"
  },
  {
    "id": "5202042007",
    "district_id": "520204",
    "label": "Gapura",
    "value": "Gapura"
  },
  {
    "id": "5202042008",
    "district_id": "520204",
    "label": "Rembitan",
    "value": "Rembitan"
  },
  {
    "id": "5202042009",
    "district_id": "520204",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "5202042010",
    "district_id": "520204",
    "label": "Pengembur",
    "value": "Pengembur"
  },
  {
    "id": "5202042011",
    "district_id": "520204",
    "label": "Tumpak",
    "value": "Tumpak"
  },
  {
    "id": "5202042012",
    "district_id": "520204",
    "label": "Mertak",
    "value": "Mertak"
  },
  {
    "id": "5202042013",
    "district_id": "520204",
    "label": "Prabu",
    "value": "Prabu"
  },
  {
    "id": "5202042014",
    "district_id": "520204",
    "label": "Tanak Awu",
    "value": "Tanak Awu"
  },
  {
    "id": "5202042015",
    "district_id": "520204",
    "label": "Ketara",
    "value": "Ketara"
  },
  {
    "id": "5202042016",
    "district_id": "520204",
    "label": "Bangket Parak",
    "value": "Bangket Parak"
  },
  {
    "id": "5202052001",
    "district_id": "520205",
    "label": "Bonder",
    "value": "Bonder"
  },
  {
    "id": "5202052002",
    "district_id": "520205",
    "label": "Banyu Urip",
    "value": "Banyu Urip"
  },
  {
    "id": "5202052003",
    "district_id": "520205",
    "label": "Mangkung",
    "value": "Mangkung"
  },
  {
    "id": "5202052004",
    "district_id": "520205",
    "label": "Kateng",
    "value": "Kateng"
  },
  {
    "id": "5202052005",
    "district_id": "520205",
    "label": "Setanggor",
    "value": "Setanggor"
  },
  {
    "id": "5202052006",
    "district_id": "520205",
    "label": "Penujak",
    "value": "Penujak"
  },
  {
    "id": "5202052007",
    "district_id": "520205",
    "label": "Selong Blanak",
    "value": "Selong Blanak"
  },
  {
    "id": "5202052008",
    "district_id": "520205",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "5202052009",
    "district_id": "520205",
    "label": "Batujai",
    "value": "Batujai"
  },
  {
    "id": "5202052010",
    "district_id": "520205",
    "label": "Tanak Rarang",
    "value": "Tanak Rarang"
  },
  {
    "id": "5202062001",
    "district_id": "520206",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "5202062002",
    "district_id": "520206",
    "label": "Beleka",
    "value": "Beleka"
  },
  {
    "id": "5202062003",
    "district_id": "520206",
    "label": "Semoyang",
    "value": "Semoyang"
  },
  {
    "id": "5202062004",
    "district_id": "520206",
    "label": "Mujur",
    "value": "Mujur"
  },
  {
    "id": "5202062005",
    "district_id": "520206",
    "label": "Landah",
    "value": "Landah"
  },
  {
    "id": "5202062006",
    "district_id": "520206",
    "label": "Sengkerang",
    "value": "Sengkerang"
  },
  {
    "id": "5202062007",
    "district_id": "520206",
    "label": "Bilelando",
    "value": "Bilelando"
  },
  {
    "id": "5202062008",
    "district_id": "520206",
    "label": "Ganti",
    "value": "Ganti"
  },
  {
    "id": "5202062009",
    "district_id": "520206",
    "label": "Marong",
    "value": "Marong"
  },
  {
    "id": "5202062010",
    "district_id": "520206",
    "label": "Kidang",
    "value": "Kidang"
  },
  {
    "id": "5202072001",
    "district_id": "520207",
    "label": "Lekor",
    "value": "Lekor"
  },
  {
    "id": "5202072002",
    "district_id": "520207",
    "label": "Langko",
    "value": "Langko"
  },
  {
    "id": "5202072003",
    "district_id": "520207",
    "label": "Janapria",
    "value": "Janapria"
  },
  {
    "id": "5202072004",
    "district_id": "520207",
    "label": "Loang Maka",
    "value": "Loang Maka"
  },
  {
    "id": "5202072005",
    "district_id": "520207",
    "label": "Saba",
    "value": "Saba"
  },
  {
    "id": "5202072006",
    "district_id": "520207",
    "label": "Bakan",
    "value": "Bakan"
  },
  {
    "id": "5202072007",
    "district_id": "520207",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "5202072008",
    "district_id": "520207",
    "label": "Pendem",
    "value": "Pendem"
  },
  {
    "id": "5202072009",
    "district_id": "520207",
    "label": "Selebung Rembiga",
    "value": "Selebung Rembiga"
  },
  {
    "id": "5202072010",
    "district_id": "520207",
    "label": "Kerembong",
    "value": "Kerembong"
  },
  {
    "id": "5202072011",
    "district_id": "520207",
    "label": "Jango",
    "value": "Jango"
  },
  {
    "id": "5202072012",
    "district_id": "520207",
    "label": "Setuta",
    "value": "Setuta"
  },
  {
    "id": "5202082001",
    "district_id": "520208",
    "label": "Pringarata",
    "value": "Pringarata"
  },
  {
    "id": "5202082002",
    "district_id": "520208",
    "label": "Sepakek",
    "value": "Sepakek"
  },
  {
    "id": "5202082003",
    "district_id": "520208",
    "label": "Murbaya",
    "value": "Murbaya"
  },
  {
    "id": "5202082004",
    "district_id": "520208",
    "label": "Bagu",
    "value": "Bagu"
  },
  {
    "id": "5202082005",
    "district_id": "520208",
    "label": "Sintung",
    "value": "Sintung"
  },
  {
    "id": "5202082006",
    "district_id": "520208",
    "label": "Bilebante",
    "value": "Bilebante"
  },
  {
    "id": "5202082007",
    "district_id": "520208",
    "label": "Pemepek",
    "value": "Pemepek"
  },
  {
    "id": "5202082008",
    "district_id": "520208",
    "label": "Menemeng",
    "value": "Menemeng"
  },
  {
    "id": "5202082009",
    "district_id": "520208",
    "label": "Arjangka",
    "value": "Arjangka"
  },
  {
    "id": "5202082010",
    "district_id": "520208",
    "label": "Taman Indah",
    "value": "Taman Indah"
  },
  {
    "id": "5202082011",
    "district_id": "520208",
    "label": "Sisik",
    "value": "Sisik"
  },
  {
    "id": "5202092001",
    "district_id": "520209",
    "label": "Lendang Are",
    "value": "Lendang Are"
  },
  {
    "id": "5202092002",
    "district_id": "520209",
    "label": "Monggas",
    "value": "Monggas"
  },
  {
    "id": "5202092003",
    "district_id": "520209",
    "label": "Muncan",
    "value": "Muncan"
  },
  {
    "id": "5202092004",
    "district_id": "520209",
    "label": "Bebuak",
    "value": "Bebuak"
  },
  {
    "id": "5202092005",
    "district_id": "520209",
    "label": "Kopang Rembiga",
    "value": "Kopang Rembiga"
  },
  {
    "id": "5202092006",
    "district_id": "520209",
    "label": "Dasan Baru",
    "value": "Dasan Baru"
  },
  {
    "id": "5202092007",
    "district_id": "520209",
    "label": "Montong Gamang",
    "value": "Montong Gamang"
  },
  {
    "id": "5202092008",
    "district_id": "520209",
    "label": "Darmaji",
    "value": "Darmaji"
  },
  {
    "id": "5202092009",
    "district_id": "520209",
    "label": "Wajageseng",
    "value": "Wajageseng"
  },
  {
    "id": "5202092010",
    "district_id": "520209",
    "label": "Aik Bual",
    "value": "Aik Bual"
  },
  {
    "id": "5202092011",
    "district_id": "520209",
    "label": "Semparu",
    "value": "Semparu"
  },
  {
    "id": "5202101001",
    "district_id": "520210",
    "label": "Gerantung",
    "value": "Gerantung"
  },
  {
    "id": "5202101002",
    "district_id": "520210",
    "label": "Jontlak",
    "value": "Jontlak"
  },
  {
    "id": "5202101003",
    "district_id": "520210",
    "label": "Sasake",
    "value": "Sasake"
  },
  {
    "id": "5202102004",
    "district_id": "520210",
    "label": "Jurang Jaler",
    "value": "Jurang Jaler"
  },
  {
    "id": "5202102005",
    "district_id": "520210",
    "label": "Beraim",
    "value": "Beraim"
  },
  {
    "id": "5202102006",
    "district_id": "520210",
    "label": "Batunyala",
    "value": "Batunyala"
  },
  {
    "id": "5202102007",
    "district_id": "520210",
    "label": "Lajut",
    "value": "Lajut"
  },
  {
    "id": "5202102008",
    "district_id": "520210",
    "label": "Pengadang",
    "value": "Pengadang"
  },
  {
    "id": "5202102009",
    "district_id": "520210",
    "label": "Kelebuh",
    "value": "Kelebuh"
  },
  {
    "id": "5202102010",
    "district_id": "520210",
    "label": "Pejanggik",
    "value": "Pejanggik"
  },
  {
    "id": "5202102011",
    "district_id": "520210",
    "label": "Dakung",
    "value": "Dakung"
  },
  {
    "id": "5202102012",
    "district_id": "520210",
    "label": "Prai Meke",
    "value": "Prai Meke"
  },
  {
    "id": "5202112001",
    "district_id": "520211",
    "label": "Montong Sapah",
    "value": "Montong Sapah"
  },
  {
    "id": "5202112002",
    "district_id": "520211",
    "label": "Ungga",
    "value": "Ungga"
  },
  {
    "id": "5202112003",
    "district_id": "520211",
    "label": "Kabul",
    "value": "Kabul"
  },
  {
    "id": "5202112004",
    "district_id": "520211",
    "label": "Pelambik",
    "value": "Pelambik"
  },
  {
    "id": "5202112005",
    "district_id": "520211",
    "label": "Darek",
    "value": "Darek"
  },
  {
    "id": "5202112006",
    "district_id": "520211",
    "label": "Ranggagata",
    "value": "Ranggagata"
  },
  {
    "id": "5202112007",
    "district_id": "520211",
    "label": "Pandan Indah",
    "value": "Pandan Indah"
  },
  {
    "id": "5202112008",
    "district_id": "520211",
    "label": "Serage",
    "value": "Serage"
  },
  {
    "id": "5202112009",
    "district_id": "520211",
    "label": "Montong Ajan",
    "value": "Montong Ajan"
  },
  {
    "id": "5202112010",
    "district_id": "520211",
    "label": "Batu Jangkih",
    "value": "Batu Jangkih"
  },
  {
    "id": "5202112011",
    "district_id": "520211",
    "label": "Teduh",
    "value": "Teduh"
  },
  {
    "id": "5202122001",
    "district_id": "520212",
    "label": "Lantan",
    "value": "Lantan"
  },
  {
    "id": "5202122002",
    "district_id": "520212",
    "label": "Setiling",
    "value": "Setiling"
  },
  {
    "id": "5202122003",
    "district_id": "520212",
    "label": "Tanak Beak",
    "value": "Tanak Beak"
  },
  {
    "id": "5202122004",
    "district_id": "520212",
    "label": "Aik Bukaq",
    "value": "Aik Bukaq"
  },
  {
    "id": "5202122005",
    "district_id": "520212",
    "label": "Teratak",
    "value": "Teratak"
  },
  {
    "id": "5202122006",
    "district_id": "520212",
    "label": "Aik Berik",
    "value": "Aik Berik"
  },
  {
    "id": "5202122007",
    "district_id": "520212",
    "label": "Mas-mas",
    "value": "Mas-mas"
  },
  {
    "id": "5202122008",
    "district_id": "520212",
    "label": "Karang Sidemen",
    "value": "Karang Sidemen"
  },
  {
    "id": "5203012001",
    "district_id": "520301",
    "label": "Tanjung Luar",
    "value": "Tanjung Luar"
  },
  {
    "id": "5203012002",
    "district_id": "520301",
    "label": "Pijot",
    "value": "Pijot"
  },
  {
    "id": "5203012003",
    "district_id": "520301",
    "label": "Selebung Ketangga",
    "value": "Selebung Ketangga"
  },
  {
    "id": "5203012004",
    "district_id": "520301",
    "label": "Sepit",
    "value": "Sepit"
  },
  {
    "id": "5203012005",
    "district_id": "520301",
    "label": "Batu Putik",
    "value": "Batu Putik"
  },
  {
    "id": "5203012006",
    "district_id": "520301",
    "label": "Senyiur",
    "value": "Senyiur"
  },
  {
    "id": "5203012007",
    "district_id": "520301",
    "label": "Keruak",
    "value": "Keruak"
  },
  {
    "id": "5203012008",
    "district_id": "520301",
    "label": "Ketapang Raya",
    "value": "Ketapang Raya"
  },
  {
    "id": "5203012009",
    "district_id": "520301",
    "label": "Pijot Utara",
    "value": "Pijot Utara"
  },
  {
    "id": "5203012010",
    "district_id": "520301",
    "label": "Dane Rase",
    "value": "Dane Rase"
  },
  {
    "id": "5203012011",
    "district_id": "520301",
    "label": "Ketangga Jeraeng",
    "value": "Ketangga Jeraeng"
  },
  {
    "id": "5203012012",
    "district_id": "520301",
    "label": "Mendana Raya",
    "value": "Mendana Raya"
  },
  {
    "id": "5203012013",
    "district_id": "520301",
    "label": "Setungkep Lingsar",
    "value": "Setungkep Lingsar"
  },
  {
    "id": "5203012014",
    "district_id": "520301",
    "label": "Montong Belae",
    "value": "Montong Belae"
  },
  {
    "id": "5203012015",
    "district_id": "520301",
    "label": "Pulau Maringkik",
    "value": "Pulau Maringkik"
  },
  {
    "id": "5203022001",
    "district_id": "520302",
    "label": "Suwangi",
    "value": "Suwangi"
  },
  {
    "id": "5203022002",
    "district_id": "520302",
    "label": "Sakra",
    "value": "Sakra"
  },
  {
    "id": "5203022003",
    "district_id": "520302",
    "label": "Kabar",
    "value": "Kabar"
  },
  {
    "id": "5203022004",
    "district_id": "520302",
    "label": "Rumbuk",
    "value": "Rumbuk"
  },
  {
    "id": "5203022005",
    "district_id": "520302",
    "label": "Keselet",
    "value": "Keselet"
  },
  {
    "id": "5203022006",
    "district_id": "520302",
    "label": "Sakra Selatan",
    "value": "Sakra Selatan"
  },
  {
    "id": "5203022007",
    "district_id": "520302",
    "label": "Rumbuk Timur",
    "value": "Rumbuk Timur"
  },
  {
    "id": "5203022008",
    "district_id": "520302",
    "label": "Suwangi Timur",
    "value": "Suwangi Timur"
  },
  {
    "id": "5203022009",
    "district_id": "520302",
    "label": "Moyot",
    "value": "Moyot"
  },
  {
    "id": "5203022010",
    "district_id": "520302",
    "label": "Songak",
    "value": "Songak"
  },
  {
    "id": "5203022011",
    "district_id": "520302",
    "label": "Peresak",
    "value": "Peresak"
  },
  {
    "id": "5203022012",
    "district_id": "520302",
    "label": "Kuang Baru",
    "value": "Kuang Baru"
  },
  {
    "id": "5203032001",
    "district_id": "520303",
    "label": "Jenggik",
    "value": "Jenggik"
  },
  {
    "id": "5203032002",
    "district_id": "520303",
    "label": "Rarang",
    "value": "Rarang"
  },
  {
    "id": "5203032003",
    "district_id": "520303",
    "label": "Suradadi",
    "value": "Suradadi"
  },
  {
    "id": "5203032004",
    "district_id": "520303",
    "label": "Santong",
    "value": "Santong"
  },
  {
    "id": "5203032005",
    "district_id": "520303",
    "label": "Terara",
    "value": "Terara"
  },
  {
    "id": "5203032006",
    "district_id": "520303",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "5203032007",
    "district_id": "520303",
    "label": "Rarang Selatan",
    "value": "Rarang Selatan"
  },
  {
    "id": "5203032008",
    "district_id": "520303",
    "label": "Leming",
    "value": "Leming"
  },
  {
    "id": "5203032009",
    "district_id": "520303",
    "label": "Lando",
    "value": "Lando"
  },
  {
    "id": "5203032010",
    "district_id": "520303",
    "label": "Rarang Tengah",
    "value": "Rarang Tengah"
  },
  {
    "id": "5203032011",
    "district_id": "520303",
    "label": "Embung Raja",
    "value": "Embung Raja"
  },
  {
    "id": "5203032012",
    "district_id": "520303",
    "label": "Selagik",
    "value": "Selagik"
  },
  {
    "id": "5203032013",
    "district_id": "520303",
    "label": "Embung Kandong",
    "value": "Embung Kandong"
  },
  {
    "id": "5203032014",
    "district_id": "520303",
    "label": "Kalianyar",
    "value": "Kalianyar"
  },
  {
    "id": "5203032015",
    "district_id": "520303",
    "label": "Rarang Batas",
    "value": "Rarang Batas"
  },
  {
    "id": "5203032016",
    "district_id": "520303",
    "label": "Pandan Duri",
    "value": "Pandan Duri"
  },
  {
    "id": "5203042001",
    "district_id": "520304",
    "label": "Semaya",
    "value": "Semaya"
  },
  {
    "id": "5203042002",
    "district_id": "520304",
    "label": "Sikur",
    "value": "Sikur"
  },
  {
    "id": "5203042003",
    "district_id": "520304",
    "label": "Montong Baan",
    "value": "Montong Baan"
  },
  {
    "id": "5203042004",
    "district_id": "520304",
    "label": "Loyok",
    "value": "Loyok"
  },
  {
    "id": "5203042005",
    "district_id": "520304",
    "label": "Kota Raja",
    "value": "Kota Raja"
  },
  {
    "id": "5203042006",
    "district_id": "520304",
    "label": "Tete Batu",
    "value": "Tete Batu"
  },
  {
    "id": "5203042007",
    "district_id": "520304",
    "label": "Kembang Kuning",
    "value": "Kembang Kuning"
  },
  {
    "id": "5203042008",
    "district_id": "520304",
    "label": "Montong Baan Selatan",
    "value": "Montong Baan Selatan"
  },
  {
    "id": "5203042009",
    "district_id": "520304",
    "label": "Gelora",
    "value": "Gelora"
  },
  {
    "id": "5203042010",
    "district_id": "520304",
    "label": "Darmasari",
    "value": "Darmasari"
  },
  {
    "id": "5203042011",
    "district_id": "520304",
    "label": "Tetebatu Selatan",
    "value": "Tetebatu Selatan"
  },
  {
    "id": "5203042012",
    "district_id": "520304",
    "label": "Jeruk Manis",
    "value": "Jeruk Manis"
  },
  {
    "id": "5203042013",
    "district_id": "520304",
    "label": "Sikur Selatan",
    "value": "Sikur Selatan"
  },
  {
    "id": "5203042014",
    "district_id": "520304",
    "label": "Sikur Barat",
    "value": "Sikur Barat"
  },
  {
    "id": "5203052001",
    "district_id": "520305",
    "label": "Kesik",
    "value": "Kesik"
  },
  {
    "id": "5203052002",
    "district_id": "520305",
    "label": "Paokmotong",
    "value": "Paokmotong"
  },
  {
    "id": "5203052003",
    "district_id": "520305",
    "label": "Masbagik Selatan",
    "value": "Masbagik Selatan"
  },
  {
    "id": "5203052004",
    "district_id": "520305",
    "label": "Masbagik Timur",
    "value": "Masbagik Timur"
  },
  {
    "id": "5203052005",
    "district_id": "520305",
    "label": "Masbagik Utara",
    "value": "Masbagik Utara"
  },
  {
    "id": "5203052006",
    "district_id": "520305",
    "label": "Danger",
    "value": "Danger"
  },
  {
    "id": "5203052007",
    "district_id": "520305",
    "label": "Lendang Nangka",
    "value": "Lendang Nangka"
  },
  {
    "id": "5203052008",
    "district_id": "520305",
    "label": "Masbagik Utara Baru",
    "value": "Masbagik Utara Baru"
  },
  {
    "id": "5203052009",
    "district_id": "520305",
    "label": "Lendang Nangka Utara",
    "value": "Lendang Nangka Utara"
  },
  {
    "id": "5203052010",
    "district_id": "520305",
    "label": "Kumbang",
    "value": "Kumbang"
  },
  {
    "id": "5203062001",
    "district_id": "520306",
    "label": "Setanggor",
    "value": "Setanggor"
  },
  {
    "id": "5203062002",
    "district_id": "520306",
    "label": "Jantuk",
    "value": "Jantuk"
  },
  {
    "id": "5203062003",
    "district_id": "520306",
    "label": "Padamara",
    "value": "Padamara"
  },
  {
    "id": "5203062004",
    "district_id": "520306",
    "label": "Dasan Lekong",
    "value": "Dasan Lekong"
  },
  {
    "id": "5203062005",
    "district_id": "520306",
    "label": "Sukamulia",
    "value": "Sukamulia"
  },
  {
    "id": "5203062006",
    "district_id": "520306",
    "label": "Paok Pampang",
    "value": "Paok Pampang"
  },
  {
    "id": "5203062007",
    "district_id": "520306",
    "label": "Sukamulia Timur",
    "value": "Sukamulia Timur"
  },
  {
    "id": "5203062008",
    "district_id": "520306",
    "label": "Nyiur Tebel",
    "value": "Nyiur Tebel"
  },
  {
    "id": "5203062009",
    "district_id": "520306",
    "label": "Setanggor Selatan",
    "value": "Setanggor Selatan"
  },
  {
    "id": "5203071001",
    "district_id": "520307",
    "label": "Pancor",
    "value": "Pancor"
  },
  {
    "id": "5203071002",
    "district_id": "520307",
    "label": "Selong",
    "value": "Selong"
  },
  {
    "id": "5203071003",
    "district_id": "520307",
    "label": "Kelayu Selatan",
    "value": "Kelayu Selatan"
  },
  {
    "id": "5203071004",
    "district_id": "520307",
    "label": "Denggen",
    "value": "Denggen"
  },
  {
    "id": "5203071005",
    "district_id": "520307",
    "label": "Sekarteja",
    "value": "Sekarteja"
  },
  {
    "id": "5203071006",
    "district_id": "520307",
    "label": "Majidi",
    "value": "Majidi"
  },
  {
    "id": "5203071007",
    "district_id": "520307",
    "label": "Rakam",
    "value": "Rakam"
  },
  {
    "id": "5203071008",
    "district_id": "520307",
    "label": "Sandu Baya",
    "value": "Sandu Baya"
  },
  {
    "id": "5203071009",
    "district_id": "520307",
    "label": "Kembang Sari",
    "value": "Kembang Sari"
  },
  {
    "id": "5203071010",
    "district_id": "520307",
    "label": "Kelayu Utara",
    "value": "Kelayu Utara"
  },
  {
    "id": "5203071011",
    "district_id": "520307",
    "label": "Kelaju Jorong",
    "value": "Kelaju Jorong"
  },
  {
    "id": "5203072012",
    "district_id": "520307",
    "label": "Danggen Timur",
    "value": "Danggen Timur"
  },
  {
    "id": "5203082001",
    "district_id": "520308",
    "label": "Bagik Papan",
    "value": "Bagik Papan"
  },
  {
    "id": "5203082002",
    "district_id": "520308",
    "label": "Apit Aik",
    "value": "Apit Aik"
  },
  {
    "id": "5203082003",
    "district_id": "520308",
    "label": "Kerumut",
    "value": "Kerumut"
  },
  {
    "id": "5203082004",
    "district_id": "520308",
    "label": "Pohgading",
    "value": "Pohgading"
  },
  {
    "id": "5203082005",
    "district_id": "520308",
    "label": "Batuyang",
    "value": "Batuyang"
  },
  {
    "id": "5203082006",
    "district_id": "520308",
    "label": "Pringgabaya",
    "value": "Pringgabaya"
  },
  {
    "id": "5203082007",
    "district_id": "520308",
    "label": "Labuhan Lombok",
    "value": "Labuhan Lombok"
  },
  {
    "id": "5203082008",
    "district_id": "520308",
    "label": "Pringgabaya Utara",
    "value": "Pringgabaya Utara"
  },
  {
    "id": "5203082009",
    "district_id": "520308",
    "label": "Pohgading Timur",
    "value": "Pohgading Timur"
  },
  {
    "id": "5203082010",
    "district_id": "520308",
    "label": "Teko",
    "value": "Teko"
  },
  {
    "id": "5203082011",
    "district_id": "520308",
    "label": "Anggaraksa",
    "value": "Anggaraksa"
  },
  {
    "id": "5203082012",
    "district_id": "520308",
    "label": "Tanak Gadang",
    "value": "Tanak Gadang"
  },
  {
    "id": "5203082013",
    "district_id": "520308",
    "label": "Gunung Malang",
    "value": "Gunung Malang"
  },
  {
    "id": "5203082014",
    "district_id": "520308",
    "label": "Seruni Mumbul",
    "value": "Seruni Mumbul"
  },
  {
    "id": "5203082015",
    "district_id": "520308",
    "label": "Telaga Waru",
    "value": "Telaga Waru"
  },
  {
    "id": "5203092002",
    "district_id": "520309",
    "label": "Aikmel",
    "value": "Aikmel"
  },
  {
    "id": "5203092003",
    "district_id": "520309",
    "label": "Kalijaga",
    "value": "Kalijaga"
  },
  {
    "id": "5203092004",
    "district_id": "520309",
    "label": "Kembang Kerang",
    "value": "Kembang Kerang"
  },
  {
    "id": "5203092007",
    "district_id": "520309",
    "label": "Aikmel Utara",
    "value": "Aikmel Utara"
  },
  {
    "id": "5203092008",
    "district_id": "520309",
    "label": "Kalijaga Timur",
    "value": "Kalijaga Timur"
  },
  {
    "id": "5203092009",
    "district_id": "520309",
    "label": "Kalijaga Selatan",
    "value": "Kalijaga Selatan"
  },
  {
    "id": "5203092010",
    "district_id": "520309",
    "label": "Aikmel Barat",
    "value": "Aikmel Barat"
  },
  {
    "id": "5203092011",
    "district_id": "520309",
    "label": "Kembang Kerang Daya",
    "value": "Kembang Kerang Daya"
  },
  {
    "id": "5203092014",
    "district_id": "520309",
    "label": "Toya",
    "value": "Toya"
  },
  {
    "id": "5203092019",
    "district_id": "520309",
    "label": "Kalijaga Tengah",
    "value": "Kalijaga Tengah"
  },
  {
    "id": "5203092020",
    "district_id": "520309",
    "label": "Bagik Nyaka Santri",
    "value": "Bagik Nyaka Santri"
  },
  {
    "id": "5203092021",
    "district_id": "520309",
    "label": "Aik Prapa",
    "value": "Aik Prapa"
  },
  {
    "id": "5203092023",
    "district_id": "520309",
    "label": "Aikmel Timur",
    "value": "Aikmel Timur"
  },
  {
    "id": "5203092024",
    "district_id": "520309",
    "label": "Keroya",
    "value": "Keroya"
  },
  {
    "id": "5203102001",
    "district_id": "520310",
    "label": "Obel-obel",
    "value": "Obel-obel"
  },
  {
    "id": "5203102002",
    "district_id": "520310",
    "label": "Belanting",
    "value": "Belanting"
  },
  {
    "id": "5203102003",
    "district_id": "520310",
    "label": "Sambalia",
    "value": "Sambalia"
  },
  {
    "id": "5203102004",
    "district_id": "520310",
    "label": "Labuhan Pandan",
    "value": "Labuhan Pandan"
  },
  {
    "id": "5203102005",
    "district_id": "520310",
    "label": "Sugian",
    "value": "Sugian"
  },
  {
    "id": "5203102006",
    "district_id": "520310",
    "label": "Darakunci",
    "value": "Darakunci"
  },
  {
    "id": "5203102007",
    "district_id": "520310",
    "label": "Bagik Manis",
    "value": "Bagik Manis"
  },
  {
    "id": "5203102008",
    "district_id": "520310",
    "label": "Dadap",
    "value": "Dadap"
  },
  {
    "id": "5203102009",
    "district_id": "520310",
    "label": "Madayin",
    "value": "Madayin"
  },
  {
    "id": "5203102010",
    "district_id": "520310",
    "label": "Senanggalih",
    "value": "Senanggalih"
  },
  {
    "id": "5203102011",
    "district_id": "520310",
    "label": "Padak Guar",
    "value": "Padak Guar"
  },
  {
    "id": "5203112001",
    "district_id": "520311",
    "label": "Pringga Jurang",
    "value": "Pringga Jurang"
  },
  {
    "id": "5203112002",
    "district_id": "520311",
    "label": "Montong Betok",
    "value": "Montong Betok"
  },
  {
    "id": "5203112003",
    "district_id": "520311",
    "label": "Kilang",
    "value": "Kilang"
  },
  {
    "id": "5203112004",
    "district_id": "520311",
    "label": "Perian",
    "value": "Perian"
  },
  {
    "id": "5203112005",
    "district_id": "520311",
    "label": "Jenggik Utara",
    "value": "Jenggik Utara"
  },
  {
    "id": "5203112006",
    "district_id": "520311",
    "label": "Pringgajurang Utara",
    "value": "Pringgajurang Utara"
  },
  {
    "id": "5203112007",
    "district_id": "520311",
    "label": "Pesanggrahan",
    "value": "Pesanggrahan"
  },
  {
    "id": "5203112008",
    "district_id": "520311",
    "label": "Lendang Belo",
    "value": "Lendang Belo"
  },
  {
    "id": "5203122001",
    "district_id": "520312",
    "label": "Rempung",
    "value": "Rempung"
  },
  {
    "id": "5203122002",
    "district_id": "520312",
    "label": "Pringgasela",
    "value": "Pringgasela"
  },
  {
    "id": "5203122003",
    "district_id": "520312",
    "label": "Jurit",
    "value": "Jurit"
  },
  {
    "id": "5203122004",
    "district_id": "520312",
    "label": "Pengadangan",
    "value": "Pengadangan"
  },
  {
    "id": "5203122005",
    "district_id": "520312",
    "label": "Aik Dewa",
    "value": "Aik Dewa"
  },
  {
    "id": "5203122006",
    "district_id": "520312",
    "label": "Jurit Baru",
    "value": "Jurit Baru"
  },
  {
    "id": "5203122007",
    "district_id": "520312",
    "label": "Pringgasela Selatan",
    "value": "Pringgasela Selatan"
  },
  {
    "id": "5203122008",
    "district_id": "520312",
    "label": "Pengadangan Barat",
    "value": "Pengadangan Barat"
  },
  {
    "id": "5203122009",
    "district_id": "520312",
    "label": "Timbanuh",
    "value": "Timbanuh"
  },
  {
    "id": "5203122010",
    "district_id": "520312",
    "label": "Pringgasela Timur",
    "value": "Pringgasela Timur"
  },
  {
    "id": "5203132001",
    "district_id": "520313",
    "label": "Anjani",
    "value": "Anjani"
  },
  {
    "id": "5203132002",
    "district_id": "520313",
    "label": "Tebaban",
    "value": "Tebaban"
  },
  {
    "id": "5203132003",
    "district_id": "520313",
    "label": "Kerongkong",
    "value": "Kerongkong"
  },
  {
    "id": "5203132004",
    "district_id": "520313",
    "label": "Bagik Payung",
    "value": "Bagik Payung"
  },
  {
    "id": "5203132005",
    "district_id": "520313",
    "label": "Suralaga",
    "value": "Suralaga"
  },
  {
    "id": "5203132006",
    "district_id": "520313",
    "label": "Bagik Payung Selatan",
    "value": "Bagik Payung Selatan"
  },
  {
    "id": "5203132007",
    "district_id": "520313",
    "label": "Tumbuh Mulia",
    "value": "Tumbuh Mulia"
  },
  {
    "id": "5203132008",
    "district_id": "520313",
    "label": "Dasan Borok",
    "value": "Dasan Borok"
  },
  {
    "id": "5203132009",
    "district_id": "520313",
    "label": "Gerung Permai",
    "value": "Gerung Permai"
  },
  {
    "id": "5203132010",
    "district_id": "520313",
    "label": "Dames Damai",
    "value": "Dames Damai"
  },
  {
    "id": "5203132011",
    "district_id": "520313",
    "label": "Bagik Payung Timur",
    "value": "Bagik Payung Timur"
  },
  {
    "id": "5203132012",
    "district_id": "520313",
    "label": "Waringin",
    "value": "Waringin"
  },
  {
    "id": "5203132013",
    "district_id": "520313",
    "label": "Gapuk",
    "value": "Gapuk"
  },
  {
    "id": "5203132014",
    "district_id": "520313",
    "label": "Bintang Rinjani",
    "value": "Bintang Rinjani"
  },
  {
    "id": "5203132015",
    "district_id": "520313",
    "label": "Paok Lombok",
    "value": "Paok Lombok"
  },
  {
    "id": "5203142001",
    "district_id": "520314",
    "label": "Mamben Daya",
    "value": "Mamben Daya"
  },
  {
    "id": "5203142002",
    "district_id": "520314",
    "label": "Mamben Lauk",
    "value": "Mamben Lauk"
  },
  {
    "id": "5203142003",
    "district_id": "520314",
    "label": "Wanasaba",
    "value": "Wanasaba"
  },
  {
    "id": "5203142004",
    "district_id": "520314",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "5203142005",
    "district_id": "520314",
    "label": "Bebidas",
    "value": "Bebidas"
  },
  {
    "id": "5203142006",
    "district_id": "520314",
    "label": "Tembeng Putik",
    "value": "Tembeng Putik"
  },
  {
    "id": "5203142007",
    "district_id": "520314",
    "label": "Wanasaba Lauk",
    "value": "Wanasaba Lauk"
  },
  {
    "id": "5203142008",
    "district_id": "520314",
    "label": "Beriri Jarak",
    "value": "Beriri Jarak"
  },
  {
    "id": "5203142009",
    "district_id": "520314",
    "label": "Bandok",
    "value": "Bandok"
  },
  {
    "id": "5203142010",
    "district_id": "520314",
    "label": "Mamben Baru",
    "value": "Mamben Baru"
  },
  {
    "id": "5203142011",
    "district_id": "520314",
    "label": "Otak Rarangan",
    "value": "Otak Rarangan"
  },
  {
    "id": "5203142012",
    "district_id": "520314",
    "label": "Jineng",
    "value": "Jineng"
  },
  {
    "id": "5203142013",
    "district_id": "520314",
    "label": "Wanasaba Daya",
    "value": "Wanasaba Daya"
  },
  {
    "id": "5203142014",
    "district_id": "520314",
    "label": "Karang Baru Timur",
    "value": "Karang Baru Timur"
  },
  {
    "id": "5203152001",
    "district_id": "520315",
    "label": "Sembalun Bumbung",
    "value": "Sembalun Bumbung"
  },
  {
    "id": "5203152002",
    "district_id": "520315",
    "label": "Sembalun Lawang",
    "value": "Sembalun Lawang"
  },
  {
    "id": "5203152003",
    "district_id": "520315",
    "label": "Sajang",
    "value": "Sajang"
  },
  {
    "id": "5203152004",
    "district_id": "520315",
    "label": "Bilok Petung",
    "value": "Bilok Petung"
  },
  {
    "id": "5203152005",
    "district_id": "520315",
    "label": "Sembalun",
    "value": "Sembalun"
  },
  {
    "id": "5203152006",
    "district_id": "520315",
    "label": "Sembalun Timba Gading",
    "value": "Sembalun Timba Gading"
  },
  {
    "id": "5203162001",
    "district_id": "520316",
    "label": "Selaparang",
    "value": "Selaparang"
  },
  {
    "id": "5203162002",
    "district_id": "520316",
    "label": "Ketangga",
    "value": "Ketangga"
  },
  {
    "id": "5203162003",
    "district_id": "520316",
    "label": "Suntalangu",
    "value": "Suntalangu"
  },
  {
    "id": "5203162004",
    "district_id": "520316",
    "label": "Suela",
    "value": "Suela"
  },
  {
    "id": "5203162005",
    "district_id": "520316",
    "label": "Sapit",
    "value": "Sapit"
  },
  {
    "id": "5203162006",
    "district_id": "520316",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "5203162007",
    "district_id": "520316",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "5203162008",
    "district_id": "520316",
    "label": "Puncak Jeringo",
    "value": "Puncak Jeringo"
  },
  {
    "id": "5203171004",
    "district_id": "520317",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "5203171005",
    "district_id": "520317",
    "label": "Suryawangi",
    "value": "Suryawangi"
  },
  {
    "id": "5203171007",
    "district_id": "520317",
    "label": "Ijobalit",
    "value": "Ijobalit"
  },
  {
    "id": "5203171012",
    "district_id": "520317",
    "label": "Geres",
    "value": "Geres"
  },
  {
    "id": "5203172001",
    "district_id": "520317",
    "label": "Labuhan Haji",
    "value": "Labuhan Haji"
  },
  {
    "id": "5203172002",
    "district_id": "520317",
    "label": "Peneda Gandor",
    "value": "Peneda Gandor"
  },
  {
    "id": "5203172003",
    "district_id": "520317",
    "label": "Teros",
    "value": "Teros"
  },
  {
    "id": "5203172006",
    "district_id": "520317",
    "label": "Korleko",
    "value": "Korleko"
  },
  {
    "id": "5203172008",
    "district_id": "520317",
    "label": "Kertasari",
    "value": "Kertasari"
  },
  {
    "id": "5203172009",
    "district_id": "520317",
    "label": "Tirtanadi",
    "value": "Tirtanadi"
  },
  {
    "id": "5203172010",
    "district_id": "520317",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "5203172011",
    "district_id": "520317",
    "label": "Korleko Selatan",
    "value": "Korleko Selatan"
  },
  {
    "id": "5203182001",
    "district_id": "520318",
    "label": "Gelanggang",
    "value": "Gelanggang"
  },
  {
    "id": "5203182002",
    "district_id": "520318",
    "label": "Surabaya",
    "value": "Surabaya"
  },
  {
    "id": "5203182003",
    "district_id": "520318",
    "label": "Lepak",
    "value": "Lepak"
  },
  {
    "id": "5203182004",
    "district_id": "520318",
    "label": "Gereneng",
    "value": "Gereneng"
  },
  {
    "id": "5203182005",
    "district_id": "520318",
    "label": "Montong Tangi",
    "value": "Montong Tangi"
  },
  {
    "id": "5203182006",
    "district_id": "520318",
    "label": "Menceh",
    "value": "Menceh"
  },
  {
    "id": "5203182007",
    "district_id": "520318",
    "label": "Lepak Timur",
    "value": "Lepak Timur"
  },
  {
    "id": "5203182008",
    "district_id": "520318",
    "label": "Surabaya Utara",
    "value": "Surabaya Utara"
  },
  {
    "id": "5203182009",
    "district_id": "520318",
    "label": "Gereneng Timur",
    "value": "Gereneng Timur"
  },
  {
    "id": "5203182010",
    "district_id": "520318",
    "label": "Lenting",
    "value": "Lenting"
  },
  {
    "id": "5203192001",
    "district_id": "520319",
    "label": "Sukarara",
    "value": "Sukarara"
  },
  {
    "id": "5203192002",
    "district_id": "520319",
    "label": "Gunung Rajak",
    "value": "Gunung Rajak"
  },
  {
    "id": "5203192003",
    "district_id": "520319",
    "label": "Rensing",
    "value": "Rensing"
  },
  {
    "id": "5203192004",
    "district_id": "520319",
    "label": "Bungtiang",
    "value": "Bungtiang"
  },
  {
    "id": "5203192005",
    "district_id": "520319",
    "label": "Pengkelak Mas",
    "value": "Pengkelak Mas"
  },
  {
    "id": "5203192006",
    "district_id": "520319",
    "label": "Borok Toyang",
    "value": "Borok Toyang"
  },
  {
    "id": "5203192007",
    "district_id": "520319",
    "label": "Rensing Raya",
    "value": "Rensing Raya"
  },
  {
    "id": "5203192008",
    "district_id": "520319",
    "label": "Pematung",
    "value": "Pematung"
  },
  {
    "id": "5203192009",
    "district_id": "520319",
    "label": "Jerogunung",
    "value": "Jerogunung"
  },
  {
    "id": "5203192010",
    "district_id": "520319",
    "label": "Pejaring",
    "value": "Pejaring"
  },
  {
    "id": "5203192011",
    "district_id": "520319",
    "label": "Boyemare",
    "value": "Boyemare"
  },
  {
    "id": "5203192012",
    "district_id": "520319",
    "label": "Montong Beter",
    "value": "Montong Beter"
  },
  {
    "id": "5203192013",
    "district_id": "520319",
    "label": "Mengkuru",
    "value": "Mengkuru"
  },
  {
    "id": "5203192014",
    "district_id": "520319",
    "label": "Gadungmas",
    "value": "Gadungmas"
  },
  {
    "id": "5203192015",
    "district_id": "520319",
    "label": "Kembang Are Sampai",
    "value": "Kembang Are Sampai"
  },
  {
    "id": "5203192016",
    "district_id": "520319",
    "label": "Rensing Bat",
    "value": "Rensing Bat"
  },
  {
    "id": "5203192017",
    "district_id": "520319",
    "label": "Tanak Kaken",
    "value": "Tanak Kaken"
  },
  {
    "id": "5203192018",
    "district_id": "520319",
    "label": "Gerisak Semanggeleng",
    "value": "Gerisak Semanggeleng"
  },
  {
    "id": "5203202001",
    "district_id": "520320",
    "label": "Batu Nampar",
    "value": "Batu Nampar"
  },
  {
    "id": "5203202002",
    "district_id": "520320",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "5203202003",
    "district_id": "520320",
    "label": "Jerowaru",
    "value": "Jerowaru"
  },
  {
    "id": "5203202004",
    "district_id": "520320",
    "label": "Pemongkong",
    "value": "Pemongkong"
  },
  {
    "id": "5203202005",
    "district_id": "520320",
    "label": "Sekaroh",
    "value": "Sekaroh"
  },
  {
    "id": "5203202006",
    "district_id": "520320",
    "label": "Pandan Wangi",
    "value": "Pandan Wangi"
  },
  {
    "id": "5203202007",
    "district_id": "520320",
    "label": "Wakan",
    "value": "Wakan"
  },
  {
    "id": "5203202008",
    "district_id": "520320",
    "label": "Kwang Rundun",
    "value": "Kwang Rundun"
  },
  {
    "id": "5203202009",
    "district_id": "520320",
    "label": "Ekas Buana",
    "value": "Ekas Buana"
  },
  {
    "id": "5203202010",
    "district_id": "520320",
    "label": "Sepapan",
    "value": "Sepapan"
  },
  {
    "id": "5203202011",
    "district_id": "520320",
    "label": "Seriwe",
    "value": "Seriwe"
  },
  {
    "id": "5203202012",
    "district_id": "520320",
    "label": "Pene",
    "value": "Pene"
  },
  {
    "id": "5203202013",
    "district_id": "520320",
    "label": "Batunampar Selatan",
    "value": "Batunampar Selatan"
  },
  {
    "id": "5203202014",
    "district_id": "520320",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "5203202015",
    "district_id": "520320",
    "label": "Pare Mas",
    "value": "Pare Mas"
  },
  {
    "id": "5203212001",
    "district_id": "520321",
    "label": "Lenek",
    "value": "Lenek"
  },
  {
    "id": "5203212002",
    "district_id": "520321",
    "label": "Lenek Lauk",
    "value": "Lenek Lauk"
  },
  {
    "id": "5203212003",
    "district_id": "520321",
    "label": "Lenek Daya",
    "value": "Lenek Daya"
  },
  {
    "id": "5203212004",
    "district_id": "520321",
    "label": "Lenek Baru",
    "value": "Lenek Baru"
  },
  {
    "id": "5203212005",
    "district_id": "520321",
    "label": "Lenek Kalibambang",
    "value": "Lenek Kalibambang"
  },
  {
    "id": "5203212006",
    "district_id": "520321",
    "label": "Lenek Pasiraman",
    "value": "Lenek Pasiraman"
  },
  {
    "id": "5203212007",
    "district_id": "520321",
    "label": "Lenek Ramban Biak",
    "value": "Lenek Ramban Biak"
  },
  {
    "id": "5203212008",
    "district_id": "520321",
    "label": "Lenek Duren",
    "value": "Lenek Duren"
  },
  {
    "id": "5203212009",
    "district_id": "520321",
    "label": "Sukarema",
    "value": "Sukarema"
  },
  {
    "id": "5203212010",
    "district_id": "520321",
    "label": "Kalijaga Baru",
    "value": "Kalijaga Baru"
  },
  {
    "id": "5204022001",
    "district_id": "520402",
    "label": "Lunyuk Rea",
    "value": "Lunyuk Rea"
  },
  {
    "id": "5204022002",
    "district_id": "520402",
    "label": "Lunyuk Ode",
    "value": "Lunyuk Ode"
  },
  {
    "id": "5204022003",
    "district_id": "520402",
    "label": "Jamu",
    "value": "Jamu"
  },
  {
    "id": "5204022007",
    "district_id": "520402",
    "label": "Pada Suka",
    "value": "Pada Suka"
  },
  {
    "id": "5204022008",
    "district_id": "520402",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "5204022009",
    "district_id": "520402",
    "label": "Perung",
    "value": "Perung"
  },
  {
    "id": "5204022010",
    "district_id": "520402",
    "label": "Emang Lestari",
    "value": "Emang Lestari"
  },
  {
    "id": "5204052001",
    "district_id": "520405",
    "label": "Luar",
    "value": "Luar"
  },
  {
    "id": "5204052002",
    "district_id": "520405",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "5204052003",
    "district_id": "520405",
    "label": "Kalimango",
    "value": "Kalimango"
  },
  {
    "id": "5204052004",
    "district_id": "520405",
    "label": "Marente",
    "value": "Marente"
  },
  {
    "id": "5204052005",
    "district_id": "520405",
    "label": "Juran Alas",
    "value": "Juran Alas"
  },
  {
    "id": "5204052006",
    "district_id": "520405",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "5204052007",
    "district_id": "520405",
    "label": "Pulau Bungin",
    "value": "Pulau Bungin"
  },
  {
    "id": "5204052012",
    "district_id": "520405",
    "label": "Labuhan Alas",
    "value": "Labuhan Alas"
  },
  {
    "id": "5204062001",
    "district_id": "520406",
    "label": "Stowe Brang",
    "value": "Stowe Brang"
  },
  {
    "id": "5204062002",
    "district_id": "520406",
    "label": "Labuhan Bajo",
    "value": "Labuhan Bajo"
  },
  {
    "id": "5204062003",
    "district_id": "520406",
    "label": "Pukat",
    "value": "Pukat"
  },
  {
    "id": "5204062004",
    "district_id": "520406",
    "label": "Orong Bawa",
    "value": "Orong Bawa"
  },
  {
    "id": "5204062005",
    "district_id": "520406",
    "label": "Jorok",
    "value": "Jorok"
  },
  {
    "id": "5204062006",
    "district_id": "520406",
    "label": "Motong",
    "value": "Motong"
  },
  {
    "id": "5204062007",
    "district_id": "520406",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "5204062008",
    "district_id": "520406",
    "label": "Sebedo",
    "value": "Sebedo"
  },
  {
    "id": "5204062012",
    "district_id": "520406",
    "label": "Bale Brang",
    "value": "Bale Brang"
  },
  {
    "id": "5204072001",
    "district_id": "520407",
    "label": "Batu Rotok",
    "value": "Batu Rotok"
  },
  {
    "id": "5204072002",
    "district_id": "520407",
    "label": "Tangkam Pulit",
    "value": "Tangkam Pulit"
  },
  {
    "id": "5204072003",
    "district_id": "520407",
    "label": "Bao Desa",
    "value": "Bao Desa"
  },
  {
    "id": "5204072004",
    "district_id": "520407",
    "label": "Tepal",
    "value": "Tepal"
  },
  {
    "id": "5204072005",
    "district_id": "520407",
    "label": "Batu Dulang",
    "value": "Batu Dulang"
  },
  {
    "id": "5204072006",
    "district_id": "520407",
    "label": "Kelungkung",
    "value": "Kelungkung"
  },
  {
    "id": "5204081001",
    "district_id": "520408",
    "label": "Samapuin",
    "value": "Samapuin"
  },
  {
    "id": "5204081002",
    "district_id": "520408",
    "label": "Brang Bara",
    "value": "Brang Bara"
  },
  {
    "id": "5204081003",
    "district_id": "520408",
    "label": "Pekat",
    "value": "Pekat"
  },
  {
    "id": "5204081004",
    "district_id": "520408",
    "label": "Seketeng",
    "value": "Seketeng"
  },
  {
    "id": "5204081005",
    "district_id": "520408",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "5204081006",
    "district_id": "520408",
    "label": "Lempeh",
    "value": "Lempeh"
  },
  {
    "id": "5204081007",
    "district_id": "520408",
    "label": "Brang Biji",
    "value": "Brang Biji"
  },
  {
    "id": "5204081008",
    "district_id": "520408",
    "label": "Uma Sima",
    "value": "Uma Sima"
  },
  {
    "id": "5204092001",
    "district_id": "520409",
    "label": "Moyo",
    "value": "Moyo"
  },
  {
    "id": "5204092002",
    "district_id": "520409",
    "label": "Kakiang",
    "value": "Kakiang"
  },
  {
    "id": "5204092003",
    "district_id": "520409",
    "label": "Ngeru",
    "value": "Ngeru"
  },
  {
    "id": "5204092004",
    "district_id": "520409",
    "label": "Berare",
    "value": "Berare"
  },
  {
    "id": "5204092005",
    "district_id": "520409",
    "label": "Poto",
    "value": "Poto"
  },
  {
    "id": "5204092009",
    "district_id": "520409",
    "label": "Serading",
    "value": "Serading"
  },
  {
    "id": "5204092010",
    "district_id": "520409",
    "label": "Olat Rawa",
    "value": "Olat Rawa"
  },
  {
    "id": "5204092011",
    "district_id": "520409",
    "label": "Batu Bangka",
    "value": "Batu Bangka"
  },
  {
    "id": "5204092013",
    "district_id": "520409",
    "label": "Moyo Mekar",
    "value": "Moyo Mekar"
  },
  {
    "id": "5204092014",
    "district_id": "520409",
    "label": "Labuhan Ijuk",
    "value": "Labuhan Ijuk"
  },
  {
    "id": "5204102001",
    "district_id": "520410",
    "label": "Sempe",
    "value": "Sempe"
  },
  {
    "id": "5204102002",
    "district_id": "520410",
    "label": "Semamung",
    "value": "Semamung"
  },
  {
    "id": "5204102003",
    "district_id": "520410",
    "label": "Sebasang",
    "value": "Sebasang"
  },
  {
    "id": "5204102004",
    "district_id": "520410",
    "label": "Batu Tering",
    "value": "Batu Tering"
  },
  {
    "id": "5204102005",
    "district_id": "520410",
    "label": "Batu Bulan",
    "value": "Batu Bulan"
  },
  {
    "id": "5204102006",
    "district_id": "520410",
    "label": "Mokong",
    "value": "Mokong"
  },
  {
    "id": "5204102007",
    "district_id": "520410",
    "label": "Pernek",
    "value": "Pernek"
  },
  {
    "id": "5204102008",
    "district_id": "520410",
    "label": "Leseng",
    "value": "Leseng"
  },
  {
    "id": "5204102009",
    "district_id": "520410",
    "label": "Lito",
    "value": "Lito"
  },
  {
    "id": "5204102010",
    "district_id": "520410",
    "label": "Marga Karya",
    "value": "Marga Karya"
  },
  {
    "id": "5204102011",
    "district_id": "520410",
    "label": "Maman",
    "value": "Maman"
  },
  {
    "id": "5204102012",
    "district_id": "520410",
    "label": "Brang Rea",
    "value": "Brang Rea"
  },
  {
    "id": "5204112002",
    "district_id": "520411",
    "label": "Lebin",
    "value": "Lebin"
  },
  {
    "id": "5204112003",
    "district_id": "520411",
    "label": "Lebangkar",
    "value": "Lebangkar"
  },
  {
    "id": "5204112008",
    "district_id": "520411",
    "label": "Ropang",
    "value": "Ropang"
  },
  {
    "id": "5204112009",
    "district_id": "520411",
    "label": "Lawin",
    "value": "Lawin"
  },
  {
    "id": "5204112010",
    "district_id": "520411",
    "label": "Ranan",
    "value": "Ranan"
  },
  {
    "id": "5204122003",
    "district_id": "520412",
    "label": "Lape",
    "value": "Lape"
  },
  {
    "id": "5204122005",
    "district_id": "520412",
    "label": "Labuhan Kuris",
    "value": "Labuhan Kuris"
  },
  {
    "id": "5204122006",
    "district_id": "520412",
    "label": "Dete",
    "value": "Dete"
  },
  {
    "id": "5204122009",
    "district_id": "520412",
    "label": "Hijrah",
    "value": "Hijrah"
  },
  {
    "id": "5204132003",
    "district_id": "520413",
    "label": "Muer",
    "value": "Muer"
  },
  {
    "id": "5204132004",
    "district_id": "520413",
    "label": "Plampang",
    "value": "Plampang"
  },
  {
    "id": "5204132005",
    "district_id": "520413",
    "label": "Teluk Santong",
    "value": "Teluk Santong"
  },
  {
    "id": "5204132006",
    "district_id": "520413",
    "label": "Sepayung",
    "value": "Sepayung"
  },
  {
    "id": "5204132007",
    "district_id": "520413",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "5204132008",
    "district_id": "520413",
    "label": "UPT. Perode SP I",
    "value": "UPT. Perode SP I"
  },
  {
    "id": "5204132009",
    "district_id": "520413",
    "label": "UPT. Perode SP II",
    "value": "UPT. Perode SP II"
  },
  {
    "id": "5204132010",
    "district_id": "520413",
    "label": "UPT Perode SP III",
    "value": "UPT Perode SP III"
  },
  {
    "id": "5204132012",
    "district_id": "520413",
    "label": "Selanteh",
    "value": "Selanteh"
  },
  {
    "id": "5204132013",
    "district_id": "520413",
    "label": "Brang Kolong",
    "value": "Brang Kolong"
  },
  {
    "id": "5204132014",
    "district_id": "520413",
    "label": "Usar",
    "value": "Usar"
  },
  {
    "id": "5204142001",
    "district_id": "520414",
    "label": "Boal",
    "value": "Boal"
  },
  {
    "id": "5204142002",
    "district_id": "520414",
    "label": "Jotang",
    "value": "Jotang"
  },
  {
    "id": "5204142003",
    "district_id": "520414",
    "label": "Empang Atas",
    "value": "Empang Atas"
  },
  {
    "id": "5204142004",
    "district_id": "520414",
    "label": "Empang Bawa",
    "value": "Empang Bawa"
  },
  {
    "id": "5204142005",
    "district_id": "520414",
    "label": "Ongko",
    "value": "Ongko"
  },
  {
    "id": "5204142012",
    "district_id": "520414",
    "label": "Lamenta",
    "value": "Lamenta"
  },
  {
    "id": "5204142013",
    "district_id": "520414",
    "label": "Gapit",
    "value": "Gapit"
  },
  {
    "id": "5204142014",
    "district_id": "520414",
    "label": "Pamanto",
    "value": "Pamanto"
  },
  {
    "id": "5204142015",
    "district_id": "520414",
    "label": "Bunga Eja",
    "value": "Bunga Eja"
  },
  {
    "id": "5204142016",
    "district_id": "520414",
    "label": "Jotang Beru",
    "value": "Jotang Beru"
  },
  {
    "id": "5204172001",
    "district_id": "520417",
    "label": "Mapin Rea",
    "value": "Mapin Rea"
  },
  {
    "id": "5204172002",
    "district_id": "520417",
    "label": "Mapin Kebak",
    "value": "Mapin Kebak"
  },
  {
    "id": "5204172003",
    "district_id": "520417",
    "label": "Labuhan Mapin",
    "value": "Labuhan Mapin"
  },
  {
    "id": "5204172004",
    "district_id": "520417",
    "label": "Lekong",
    "value": "Lekong"
  },
  {
    "id": "5204172005",
    "district_id": "520417",
    "label": "Gontar",
    "value": "Gontar"
  },
  {
    "id": "5204172006",
    "district_id": "520417",
    "label": "Usar Mapin",
    "value": "Usar Mapin"
  },
  {
    "id": "5204172007",
    "district_id": "520417",
    "label": "Gontar Baru",
    "value": "Gontar Baru"
  },
  {
    "id": "5204172008",
    "district_id": "520417",
    "label": "Mapin Beru",
    "value": "Mapin Beru"
  },
  {
    "id": "5204182001",
    "district_id": "520418",
    "label": "Labuhan Badas",
    "value": "Labuhan Badas"
  },
  {
    "id": "5204182002",
    "district_id": "520418",
    "label": "Karang Dima",
    "value": "Karang Dima"
  },
  {
    "id": "5204182003",
    "district_id": "520418",
    "label": "Labuhan Sumbawa",
    "value": "Labuhan Sumbawa"
  },
  {
    "id": "5204182004",
    "district_id": "520418",
    "label": "Labuhan Aji",
    "value": "Labuhan Aji"
  },
  {
    "id": "5204182005",
    "district_id": "520418",
    "label": "Sebotok",
    "value": "Sebotok"
  },
  {
    "id": "5204182006",
    "district_id": "520418",
    "label": "Bajo Medang",
    "value": "Bajo Medang"
  },
  {
    "id": "5204182007",
    "district_id": "520418",
    "label": "Bugis Medang",
    "value": "Bugis Medang"
  },
  {
    "id": "5204192001",
    "district_id": "520419",
    "label": "Labangka",
    "value": "Labangka"
  },
  {
    "id": "5204192002",
    "district_id": "520419",
    "label": "Sekokat",
    "value": "Sekokat"
  },
  {
    "id": "5204192003",
    "district_id": "520419",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "5204192004",
    "district_id": "520419",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "5204192005",
    "district_id": "520419",
    "label": "Jaya Makmur",
    "value": "Jaya Makmur"
  },
  {
    "id": "5204202001",
    "district_id": "520420",
    "label": "Tarusa",
    "value": "Tarusa"
  },
  {
    "id": "5204202002",
    "district_id": "520420",
    "label": "Juru Mapin",
    "value": "Juru Mapin"
  },
  {
    "id": "5204202003",
    "district_id": "520420",
    "label": "Kalabeso",
    "value": "Kalabeso"
  },
  {
    "id": "5204202004",
    "district_id": "520420",
    "label": "Labuhan Burung",
    "value": "Labuhan Burung"
  },
  {
    "id": "5204202005",
    "district_id": "520420",
    "label": "Pulau Kaung",
    "value": "Pulau Kaung"
  },
  {
    "id": "5204202006",
    "district_id": "520420",
    "label": "Buin Baru",
    "value": "Buin Baru"
  },
  {
    "id": "5204212001",
    "district_id": "520421",
    "label": "Rhee",
    "value": "Rhee"
  },
  {
    "id": "5204212002",
    "district_id": "520421",
    "label": "Sampe",
    "value": "Sampe"
  },
  {
    "id": "5204212003",
    "district_id": "520421",
    "label": "Luk",
    "value": "Luk"
  },
  {
    "id": "5204212004",
    "district_id": "520421",
    "label": "Rhee Loka",
    "value": "Rhee Loka"
  },
  {
    "id": "5204222001",
    "district_id": "520422",
    "label": "Pelat",
    "value": "Pelat"
  },
  {
    "id": "5204222002",
    "district_id": "520422",
    "label": "Kerekeh",
    "value": "Kerekeh"
  },
  {
    "id": "5204222003",
    "district_id": "520422",
    "label": "Boak",
    "value": "Boak"
  },
  {
    "id": "5204222004",
    "district_id": "520422",
    "label": "Jorok",
    "value": "Jorok"
  },
  {
    "id": "5204222005",
    "district_id": "520422",
    "label": "Kerato",
    "value": "Kerato"
  },
  {
    "id": "5204222006",
    "district_id": "520422",
    "label": "Pungka",
    "value": "Pungka"
  },
  {
    "id": "5204222007",
    "district_id": "520422",
    "label": "Uma Beringin",
    "value": "Uma Beringin"
  },
  {
    "id": "5204222008",
    "district_id": "520422",
    "label": "Nijang",
    "value": "Nijang"
  },
  {
    "id": "5204232001",
    "district_id": "520423",
    "label": "Pungkit",
    "value": "Pungkit"
  },
  {
    "id": "5204232002",
    "district_id": "520423",
    "label": "Sebewe",
    "value": "Sebewe"
  },
  {
    "id": "5204232003",
    "district_id": "520423",
    "label": "Penyaring",
    "value": "Penyaring"
  },
  {
    "id": "5204232004",
    "district_id": "520423",
    "label": "Kukin",
    "value": "Kukin"
  },
  {
    "id": "5204232005",
    "district_id": "520423",
    "label": "Baru Tahan",
    "value": "Baru Tahan"
  },
  {
    "id": "5204232006",
    "district_id": "520423",
    "label": "Songkar",
    "value": "Songkar"
  },
  {
    "id": "5204242001",
    "district_id": "520424",
    "label": "Simu",
    "value": "Simu"
  },
  {
    "id": "5204242002",
    "district_id": "520424",
    "label": "Maronge",
    "value": "Maronge"
  },
  {
    "id": "5204242003",
    "district_id": "520424",
    "label": "Pamasar",
    "value": "Pamasar"
  },
  {
    "id": "5204242004",
    "district_id": "520424",
    "label": "Labuhan Sangoro",
    "value": "Labuhan Sangoro"
  },
  {
    "id": "5204252001",
    "district_id": "520425",
    "label": "Bantulante",
    "value": "Bantulante"
  },
  {
    "id": "5204252002",
    "district_id": "520425",
    "label": "Labuhan Bontong",
    "value": "Labuhan Bontong"
  },
  {
    "id": "5204252003",
    "district_id": "520425",
    "label": "Labuhan Aji",
    "value": "Labuhan Aji"
  },
  {
    "id": "5204252004",
    "district_id": "520425",
    "label": "Labuhan Jambu",
    "value": "Labuhan Jambu"
  },
  {
    "id": "5204252005",
    "district_id": "520425",
    "label": "Mata",
    "value": "Mata"
  },
  {
    "id": "5204252006",
    "district_id": "520425",
    "label": "Tolo Oi",
    "value": "Tolo Oi"
  },
  {
    "id": "5204252007",
    "district_id": "520425",
    "label": "Banda",
    "value": "Banda"
  },
  {
    "id": "5204252008",
    "district_id": "520425",
    "label": "Labuhan Pidang",
    "value": "Labuhan Pidang"
  },
  {
    "id": "5204262001",
    "district_id": "520426",
    "label": "Pungkit",
    "value": "Pungkit"
  },
  {
    "id": "5204262002",
    "district_id": "520426",
    "label": "Berora",
    "value": "Berora"
  },
  {
    "id": "5204262003",
    "district_id": "520426",
    "label": "Lopok",
    "value": "Lopok"
  },
  {
    "id": "5204262004",
    "district_id": "520426",
    "label": "Langam",
    "value": "Langam"
  },
  {
    "id": "5204262005",
    "district_id": "520426",
    "label": "Mamak",
    "value": "Mamak"
  },
  {
    "id": "5204262006",
    "district_id": "520426",
    "label": "Lopok Beru",
    "value": "Lopok Beru"
  },
  {
    "id": "5204262007",
    "district_id": "520426",
    "label": "Tatede",
    "value": "Tatede"
  },
  {
    "id": "5204272001",
    "district_id": "520427",
    "label": "Tatebal",
    "value": "Tatebal"
  },
  {
    "id": "5204272002",
    "district_id": "520427",
    "label": "Lenangguar",
    "value": "Lenangguar"
  },
  {
    "id": "5204272003",
    "district_id": "520427",
    "label": "Ledang",
    "value": "Ledang"
  },
  {
    "id": "5204272004",
    "district_id": "520427",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "5204282001",
    "district_id": "520428",
    "label": "Senawang",
    "value": "Senawang"
  },
  {
    "id": "5204282002",
    "district_id": "520428",
    "label": "Kelawis",
    "value": "Kelawis"
  },
  {
    "id": "5204282003",
    "district_id": "520428",
    "label": "Mungkin",
    "value": "Mungkin"
  },
  {
    "id": "5204282004",
    "district_id": "520428",
    "label": "Sebeok",
    "value": "Sebeok"
  },
  {
    "id": "5204292001",
    "district_id": "520429",
    "label": "Lantung",
    "value": "Lantung"
  },
  {
    "id": "5204292002",
    "district_id": "520429",
    "label": "Sepukur",
    "value": "Sepukur"
  },
  {
    "id": "5204292003",
    "district_id": "520429",
    "label": "Ai Mual",
    "value": "Ai Mual"
  },
  {
    "id": "5204292004",
    "district_id": "520429",
    "label": "Padesa",
    "value": "Padesa"
  },
  {
    "id": "5205011001",
    "district_id": "520501",
    "label": "Bada",
    "value": "Bada"
  },
  {
    "id": "5205011002",
    "district_id": "520501",
    "label": "Bali",
    "value": "Bali"
  },
  {
    "id": "5205011003",
    "district_id": "520501",
    "label": "Potu",
    "value": "Potu"
  },
  {
    "id": "5205011004",
    "district_id": "520501",
    "label": "Karijawa",
    "value": "Karijawa"
  },
  {
    "id": "5205011005",
    "district_id": "520501",
    "label": "Dora Tangga",
    "value": "Dora Tangga"
  },
  {
    "id": "5205011006",
    "district_id": "520501",
    "label": "Kandai I",
    "value": "Kandai I"
  },
  {
    "id": "5205012007",
    "district_id": "520501",
    "label": "O'o",
    "value": "O'o"
  },
  {
    "id": "5205012008",
    "district_id": "520501",
    "label": "Katua",
    "value": "Katua"
  },
  {
    "id": "5205012009",
    "district_id": "520501",
    "label": "Karamabura",
    "value": "Karamabura"
  },
  {
    "id": "5205012010",
    "district_id": "520501",
    "label": "Mbawi",
    "value": "Mbawi"
  },
  {
    "id": "5205012011",
    "district_id": "520501",
    "label": "Dore Bara",
    "value": "Dore Bara"
  },
  {
    "id": "5205012012",
    "district_id": "520501",
    "label": "Kareke",
    "value": "Kareke"
  },
  {
    "id": "5205012013",
    "district_id": "520501",
    "label": "Mangge Nae",
    "value": "Mangge Nae"
  },
  {
    "id": "5205012014",
    "district_id": "520501",
    "label": "Manggeasi",
    "value": "Manggeasi"
  },
  {
    "id": "5205012015",
    "district_id": "520501",
    "label": "Sori Sakolo",
    "value": "Sori Sakolo"
  },
  {
    "id": "5205022001",
    "district_id": "520502",
    "label": "Kempo",
    "value": "Kempo"
  },
  {
    "id": "5205022002",
    "district_id": "520502",
    "label": "Ta'a",
    "value": "Ta'a"
  },
  {
    "id": "5205022003",
    "district_id": "520502",
    "label": "Soro",
    "value": "Soro"
  },
  {
    "id": "5205022004",
    "district_id": "520502",
    "label": "Konte",
    "value": "Konte"
  },
  {
    "id": "5205022005",
    "district_id": "520502",
    "label": "Tolo Kalo",
    "value": "Tolo Kalo"
  },
  {
    "id": "5205022006",
    "district_id": "520502",
    "label": "Songgaja",
    "value": "Songgaja"
  },
  {
    "id": "5205022007",
    "district_id": "520502",
    "label": "Doro Kobo",
    "value": "Doro Kobo"
  },
  {
    "id": "5205022008",
    "district_id": "520502",
    "label": "Soro Barat",
    "value": "Soro Barat"
  },
  {
    "id": "5205032001",
    "district_id": "520503",
    "label": "Rasa Bou",
    "value": "Rasa Bou"
  },
  {
    "id": "5205032002",
    "district_id": "520503",
    "label": "Daha",
    "value": "Daha"
  },
  {
    "id": "5205032003",
    "district_id": "520503",
    "label": "Hu'u",
    "value": "Hu'u"
  },
  {
    "id": "5205032004",
    "district_id": "520503",
    "label": "Adu",
    "value": "Adu"
  },
  {
    "id": "5205032005",
    "district_id": "520503",
    "label": "Cempi Jaya",
    "value": "Cempi Jaya"
  },
  {
    "id": "5205032006",
    "district_id": "520503",
    "label": "Merada",
    "value": "Merada"
  },
  {
    "id": "5205032007",
    "district_id": "520503",
    "label": "Jala",
    "value": "Jala"
  },
  {
    "id": "5205032008",
    "district_id": "520503",
    "label": "Sawe",
    "value": "Sawe"
  },
  {
    "id": "5205042001",
    "district_id": "520504",
    "label": "Malaju",
    "value": "Malaju"
  },
  {
    "id": "5205042002",
    "district_id": "520504",
    "label": "Lasi",
    "value": "Lasi"
  },
  {
    "id": "5205042003",
    "district_id": "520504",
    "label": "Mbuju",
    "value": "Mbuju"
  },
  {
    "id": "5205042004",
    "district_id": "520504",
    "label": "Kiwu",
    "value": "Kiwu"
  },
  {
    "id": "5205042005",
    "district_id": "520504",
    "label": "Taropo",
    "value": "Taropo"
  },
  {
    "id": "5205042006",
    "district_id": "520504",
    "label": "Karama",
    "value": "Karama"
  },
  {
    "id": "5205051001",
    "district_id": "520505",
    "label": "Monta Baru",
    "value": "Monta Baru"
  },
  {
    "id": "5205051002",
    "district_id": "520505",
    "label": "Kandai II",
    "value": "Kandai II"
  },
  {
    "id": "5205051003",
    "district_id": "520505",
    "label": "Simpasai",
    "value": "Simpasai"
  },
  {
    "id": "5205052004",
    "district_id": "520505",
    "label": "Saneo",
    "value": "Saneo"
  },
  {
    "id": "5205052005",
    "district_id": "520505",
    "label": "Wawonduru",
    "value": "Wawonduru"
  },
  {
    "id": "5205052006",
    "district_id": "520505",
    "label": "Matua",
    "value": "Matua"
  },
  {
    "id": "5205052007",
    "district_id": "520505",
    "label": "Nowa",
    "value": "Nowa"
  },
  {
    "id": "5205052008",
    "district_id": "520505",
    "label": "Bara",
    "value": "Bara"
  },
  {
    "id": "5205052009",
    "district_id": "520505",
    "label": "Mada Prama",
    "value": "Mada Prama"
  },
  {
    "id": "5205052010",
    "district_id": "520505",
    "label": "Riwo",
    "value": "Riwo"
  },
  {
    "id": "5205052011",
    "district_id": "520505",
    "label": "Mumbu",
    "value": "Mumbu"
  },
  {
    "id": "5205052012",
    "district_id": "520505",
    "label": "Sera Kapi",
    "value": "Sera Kapi"
  },
  {
    "id": "5205052013",
    "district_id": "520505",
    "label": "Raba Baka",
    "value": "Raba Baka"
  },
  {
    "id": "5205052014",
    "district_id": "520505",
    "label": "Baka Jaya",
    "value": "Baka Jaya"
  },
  {
    "id": "5205062001",
    "district_id": "520506",
    "label": "Pekat",
    "value": "Pekat"
  },
  {
    "id": "5205062002",
    "district_id": "520506",
    "label": "Nangamiro",
    "value": "Nangamiro"
  },
  {
    "id": "5205062003",
    "district_id": "520506",
    "label": "Kadindi",
    "value": "Kadindi"
  },
  {
    "id": "5205062004",
    "district_id": "520506",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "5205062005",
    "district_id": "520506",
    "label": "Sori Nomo",
    "value": "Sori Nomo"
  },
  {
    "id": "5205062006",
    "district_id": "520506",
    "label": "Tambora",
    "value": "Tambora"
  },
  {
    "id": "5205062007",
    "district_id": "520506",
    "label": "Doro Peti",
    "value": "Doro Peti"
  },
  {
    "id": "5205062008",
    "district_id": "520506",
    "label": "Karombo",
    "value": "Karombo"
  },
  {
    "id": "5205062009",
    "district_id": "520506",
    "label": "Kadindi Barat",
    "value": "Kadindi Barat"
  },
  {
    "id": "5205062010",
    "district_id": "520506",
    "label": "Nangakara",
    "value": "Nangakara"
  },
  {
    "id": "5205062011",
    "district_id": "520506",
    "label": "Sori Tatanga",
    "value": "Sori Tatanga"
  },
  {
    "id": "5205062012",
    "district_id": "520506",
    "label": "Calabai",
    "value": "Calabai"
  },
  {
    "id": "5205072001",
    "district_id": "520507",
    "label": "Soriutu",
    "value": "Soriutu"
  },
  {
    "id": "5205072002",
    "district_id": "520507",
    "label": "Banggo",
    "value": "Banggo"
  },
  {
    "id": "5205072003",
    "district_id": "520507",
    "label": "Kwangko",
    "value": "Kwangko"
  },
  {
    "id": "5205072004",
    "district_id": "520507",
    "label": "Lanci Jaya",
    "value": "Lanci Jaya"
  },
  {
    "id": "5205072005",
    "district_id": "520507",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "5205072006",
    "district_id": "520507",
    "label": "Nusa Jaya",
    "value": "Nusa Jaya"
  },
  {
    "id": "5205072007",
    "district_id": "520507",
    "label": "Doromelo",
    "value": "Doromelo"
  },
  {
    "id": "5205072008",
    "district_id": "520507",
    "label": "Nangatumpu",
    "value": "Nangatumpu"
  },
  {
    "id": "5205072009",
    "district_id": "520507",
    "label": "Kampasi Meci",
    "value": "Kampasi Meci"
  },
  {
    "id": "5205072010",
    "district_id": "520507",
    "label": "Teka Sire",
    "value": "Teka Sire"
  },
  {
    "id": "5205072011",
    "district_id": "520507",
    "label": "Anamina",
    "value": "Anamina"
  },
  {
    "id": "5205072012",
    "district_id": "520507",
    "label": "Tanju",
    "value": "Tanju"
  },
  {
    "id": "5205082001",
    "district_id": "520508",
    "label": "Ranggo",
    "value": "Ranggo"
  },
  {
    "id": "5205082002",
    "district_id": "520508",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "5205082003",
    "district_id": "520508",
    "label": "Lepadi",
    "value": "Lepadi"
  },
  {
    "id": "5205082004",
    "district_id": "520508",
    "label": "Lune",
    "value": "Lune"
  },
  {
    "id": "5205082005",
    "district_id": "520508",
    "label": "Woko",
    "value": "Woko"
  },
  {
    "id": "5205082006",
    "district_id": "520508",
    "label": "Tembal Lae",
    "value": "Tembal Lae"
  },
  {
    "id": "5206012005",
    "district_id": "520601",
    "label": "Tolotangga",
    "value": "Tolotangga"
  },
  {
    "id": "5206012006",
    "district_id": "520601",
    "label": "Sondo",
    "value": "Sondo"
  },
  {
    "id": "5206012007",
    "district_id": "520601",
    "label": "Simpasai",
    "value": "Simpasai"
  },
  {
    "id": "5206012008",
    "district_id": "520601",
    "label": "Sie",
    "value": "Sie"
  },
  {
    "id": "5206012009",
    "district_id": "520601",
    "label": "Tangga",
    "value": "Tangga"
  },
  {
    "id": "5206012010",
    "district_id": "520601",
    "label": "Monta",
    "value": "Monta"
  },
  {
    "id": "5206012011",
    "district_id": "520601",
    "label": "Sakuru",
    "value": "Sakuru"
  },
  {
    "id": "5206012012",
    "district_id": "520601",
    "label": "Baralau",
    "value": "Baralau"
  },
  {
    "id": "5206012014",
    "district_id": "520601",
    "label": "Tolouwi",
    "value": "Tolouwi"
  },
  {
    "id": "5206012015",
    "district_id": "520601",
    "label": "Wilamaci",
    "value": "Wilamaci"
  },
  {
    "id": "5206012016",
    "district_id": "520601",
    "label": "Pela",
    "value": "Pela"
  },
  {
    "id": "5206012017",
    "district_id": "520601",
    "label": "Tangga Baru",
    "value": "Tangga Baru"
  },
  {
    "id": "5206012018",
    "district_id": "520601",
    "label": "Nontotera",
    "value": "Nontotera"
  },
  {
    "id": "5206012019",
    "district_id": "520601",
    "label": "Waro",
    "value": "Waro"
  },
  {
    "id": "5206022001",
    "district_id": "520602",
    "label": "Tambe",
    "value": "Tambe"
  },
  {
    "id": "5206022002",
    "district_id": "520602",
    "label": "Rasabau",
    "value": "Rasabau"
  },
  {
    "id": "5206022003",
    "district_id": "520602",
    "label": "Rato",
    "value": "Rato"
  },
  {
    "id": "5206022004",
    "district_id": "520602",
    "label": "Kananga",
    "value": "Kananga"
  },
  {
    "id": "5206022005",
    "district_id": "520602",
    "label": "Leu",
    "value": "Leu"
  },
  {
    "id": "5206022006",
    "district_id": "520602",
    "label": "Timu",
    "value": "Timu"
  },
  {
    "id": "5206022007",
    "district_id": "520602",
    "label": "Bontokape",
    "value": "Bontokape"
  },
  {
    "id": "5206022008",
    "district_id": "520602",
    "label": "Sondosia",
    "value": "Sondosia"
  },
  {
    "id": "5206022009",
    "district_id": "520602",
    "label": "Nggembe",
    "value": "Nggembe"
  },
  {
    "id": "5206022010",
    "district_id": "520602",
    "label": "Sanolo",
    "value": "Sanolo"
  },
  {
    "id": "5206022011",
    "district_id": "520602",
    "label": "Tumpu",
    "value": "Tumpu"
  },
  {
    "id": "5206022012",
    "district_id": "520602",
    "label": "Rada",
    "value": "Rada"
  },
  {
    "id": "5206022013",
    "district_id": "520602",
    "label": "Kara",
    "value": "Kara"
  },
  {
    "id": "5206022014",
    "district_id": "520602",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "5206032001",
    "district_id": "520603",
    "label": "Keli",
    "value": "Keli"
  },
  {
    "id": "5206032002",
    "district_id": "520603",
    "label": "Tenga",
    "value": "Tenga"
  },
  {
    "id": "5206032003",
    "district_id": "520603",
    "label": "Tente",
    "value": "Tente"
  },
  {
    "id": "5206032004",
    "district_id": "520603",
    "label": "Rabakodo",
    "value": "Rabakodo"
  },
  {
    "id": "5206032005",
    "district_id": "520603",
    "label": "Samili",
    "value": "Samili"
  },
  {
    "id": "5206032006",
    "district_id": "520603",
    "label": "Kalampa",
    "value": "Kalampa"
  },
  {
    "id": "5206032007",
    "district_id": "520603",
    "label": "Risa",
    "value": "Risa"
  },
  {
    "id": "5206032008",
    "district_id": "520603",
    "label": "Pandai",
    "value": "Pandai"
  },
  {
    "id": "5206032009",
    "district_id": "520603",
    "label": "Donggobolo",
    "value": "Donggobolo"
  },
  {
    "id": "5206032010",
    "district_id": "520603",
    "label": "Dadibou",
    "value": "Dadibou"
  },
  {
    "id": "5206032011",
    "district_id": "520603",
    "label": "Talabiu",
    "value": "Talabiu"
  },
  {
    "id": "5206032012",
    "district_id": "520603",
    "label": "Naru",
    "value": "Naru"
  },
  {
    "id": "5206032013",
    "district_id": "520603",
    "label": "Nisa",
    "value": "Nisa"
  },
  {
    "id": "5206032014",
    "district_id": "520603",
    "label": "Waduwani",
    "value": "Waduwani"
  },
  {
    "id": "5206032015",
    "district_id": "520603",
    "label": "Penapali",
    "value": "Penapali"
  },
  {
    "id": "5206042001",
    "district_id": "520604",
    "label": "Ncera",
    "value": "Ncera"
  },
  {
    "id": "5206042002",
    "district_id": "520604",
    "label": "Lido",
    "value": "Lido"
  },
  {
    "id": "5206042003",
    "district_id": "520604",
    "label": "Ngali",
    "value": "Ngali"
  },
  {
    "id": "5206042004",
    "district_id": "520604",
    "label": "Renda",
    "value": "Renda"
  },
  {
    "id": "5206042005",
    "district_id": "520604",
    "label": "Cenggu",
    "value": "Cenggu"
  },
  {
    "id": "5206042006",
    "district_id": "520604",
    "label": "Runggu",
    "value": "Runggu"
  },
  {
    "id": "5206042014",
    "district_id": "520604",
    "label": "Soki",
    "value": "Soki"
  },
  {
    "id": "5206042015",
    "district_id": "520604",
    "label": "Roka",
    "value": "Roka"
  },
  {
    "id": "5206042016",
    "district_id": "520604",
    "label": "Diha",
    "value": "Diha"
  },
  {
    "id": "5206052001",
    "district_id": "520605",
    "label": "Tarlawi",
    "value": "Tarlawi"
  },
  {
    "id": "5206052005",
    "district_id": "520605",
    "label": "Ntori",
    "value": "Ntori"
  },
  {
    "id": "5206052006",
    "district_id": "520605",
    "label": "Maria",
    "value": "Maria"
  },
  {
    "id": "5206052007",
    "district_id": "520605",
    "label": "Pesa",
    "value": "Pesa"
  },
  {
    "id": "5206052008",
    "district_id": "520605",
    "label": "Raba",
    "value": "Raba"
  },
  {
    "id": "5206052011",
    "district_id": "520605",
    "label": "Kambilo",
    "value": "Kambilo"
  },
  {
    "id": "5206052012",
    "district_id": "520605",
    "label": "Maria Utara",
    "value": "Maria Utara"
  },
  {
    "id": "5206052013",
    "district_id": "520605",
    "label": "Kombo",
    "value": "Kombo"
  },
  {
    "id": "5206052014",
    "district_id": "520605",
    "label": "Rianmau",
    "value": "Rianmau"
  },
  {
    "id": "5206062001",
    "district_id": "520606",
    "label": "Sari",
    "value": "Sari"
  },
  {
    "id": "5206062002",
    "district_id": "520606",
    "label": "Boke",
    "value": "Boke"
  },
  {
    "id": "5206062003",
    "district_id": "520606",
    "label": "Jia",
    "value": "Jia"
  },
  {
    "id": "5206062004",
    "district_id": "520606",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "5206062005",
    "district_id": "520606",
    "label": "Naru",
    "value": "Naru"
  },
  {
    "id": "5206062006",
    "district_id": "520606",
    "label": "Rasabou",
    "value": "Rasabou"
  },
  {
    "id": "5206062007",
    "district_id": "520606",
    "label": "Sangia",
    "value": "Sangia"
  },
  {
    "id": "5206062008",
    "district_id": "520606",
    "label": "Na'e",
    "value": "Na'e"
  },
  {
    "id": "5206062009",
    "district_id": "520606",
    "label": "Rai O'i",
    "value": "Rai O'i"
  },
  {
    "id": "5206062010",
    "district_id": "520606",
    "label": "Parangina",
    "value": "Parangina"
  },
  {
    "id": "5206062011",
    "district_id": "520606",
    "label": "Kowo",
    "value": "Kowo"
  },
  {
    "id": "5206062012",
    "district_id": "520606",
    "label": "Buncu",
    "value": "Buncu"
  },
  {
    "id": "5206062013",
    "district_id": "520606",
    "label": "Poja",
    "value": "Poja"
  },
  {
    "id": "5206062014",
    "district_id": "520606",
    "label": "Bajo Pulo",
    "value": "Bajo Pulo"
  },
  {
    "id": "5206062015",
    "district_id": "520606",
    "label": "Naru Barat",
    "value": "Naru Barat"
  },
  {
    "id": "5206062016",
    "district_id": "520606",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "5206062017",
    "district_id": "520606",
    "label": "Lamera",
    "value": "Lamera"
  },
  {
    "id": "5206062018",
    "district_id": "520606",
    "label": "Oi Maci",
    "value": "Oi Maci"
  },
  {
    "id": "5206072001",
    "district_id": "520607",
    "label": "Ntoke",
    "value": "Ntoke"
  },
  {
    "id": "5206072002",
    "district_id": "520607",
    "label": "Pai",
    "value": "Pai"
  },
  {
    "id": "5206072003",
    "district_id": "520607",
    "label": "Nunggi",
    "value": "Nunggi"
  },
  {
    "id": "5206072004",
    "district_id": "520607",
    "label": "Tawali",
    "value": "Tawali"
  },
  {
    "id": "5206072005",
    "district_id": "520607",
    "label": "Hadirasa",
    "value": "Hadirasa"
  },
  {
    "id": "5206072006",
    "district_id": "520607",
    "label": "Wora",
    "value": "Wora"
  },
  {
    "id": "5206072007",
    "district_id": "520607",
    "label": "Sangiang",
    "value": "Sangiang"
  },
  {
    "id": "5206072008",
    "district_id": "520607",
    "label": "Bala",
    "value": "Bala"
  },
  {
    "id": "5206072009",
    "district_id": "520607",
    "label": "Oi Tui",
    "value": "Oi Tui"
  },
  {
    "id": "5206072010",
    "district_id": "520607",
    "label": "Tadewa",
    "value": "Tadewa"
  },
  {
    "id": "5206072011",
    "district_id": "520607",
    "label": "Nanga Wera",
    "value": "Nanga Wera"
  },
  {
    "id": "5206072012",
    "district_id": "520607",
    "label": "Ranggasolo",
    "value": "Ranggasolo"
  },
  {
    "id": "5206072013",
    "district_id": "520607",
    "label": "Kalajena",
    "value": "Kalajena"
  },
  {
    "id": "5206072014",
    "district_id": "520607",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "5206082005",
    "district_id": "520608",
    "label": "Dori Dungga",
    "value": "Dori Dungga"
  },
  {
    "id": "5206082006",
    "district_id": "520608",
    "label": "Kala",
    "value": "Kala"
  },
  {
    "id": "5206082007",
    "district_id": "520608",
    "label": "O'o",
    "value": "O'o"
  },
  {
    "id": "5206082008",
    "district_id": "520608",
    "label": "Mbawa",
    "value": "Mbawa"
  },
  {
    "id": "5206082009",
    "district_id": "520608",
    "label": "Palama",
    "value": "Palama"
  },
  {
    "id": "5206082010",
    "district_id": "520608",
    "label": "Rora",
    "value": "Rora"
  },
  {
    "id": "5206082012",
    "district_id": "520608",
    "label": "Mpili",
    "value": "Mpili"
  },
  {
    "id": "5206082013",
    "district_id": "520608",
    "label": "Bumi Pajo",
    "value": "Bumi Pajo"
  },
  {
    "id": "5206082014",
    "district_id": "520608",
    "label": "Ndano Na'e",
    "value": "Ndano Na'e"
  },
  {
    "id": "5206092001",
    "district_id": "520609",
    "label": "Piong",
    "value": "Piong"
  },
  {
    "id": "5206092002",
    "district_id": "520609",
    "label": "Boro",
    "value": "Boro"
  },
  {
    "id": "5206092003",
    "district_id": "520609",
    "label": "Kore",
    "value": "Kore"
  },
  {
    "id": "5206092004",
    "district_id": "520609",
    "label": "Taloko",
    "value": "Taloko"
  },
  {
    "id": "5206092005",
    "district_id": "520609",
    "label": "Oi Saro",
    "value": "Oi Saro"
  },
  {
    "id": "5206092006",
    "district_id": "520609",
    "label": "Sandue",
    "value": "Sandue"
  },
  {
    "id": "5206102001",
    "district_id": "520610",
    "label": "Nipa",
    "value": "Nipa"
  },
  {
    "id": "5206102002",
    "district_id": "520610",
    "label": "Tolowata",
    "value": "Tolowata"
  },
  {
    "id": "5206102003",
    "district_id": "520610",
    "label": "Rite",
    "value": "Rite"
  },
  {
    "id": "5206102004",
    "district_id": "520610",
    "label": "Mawu",
    "value": "Mawu"
  },
  {
    "id": "5206102005",
    "district_id": "520610",
    "label": "Talapiti",
    "value": "Talapiti"
  },
  {
    "id": "5206102006",
    "district_id": "520610",
    "label": "Kole",
    "value": "Kole"
  },
  {
    "id": "5206112001",
    "district_id": "520611",
    "label": "Waworada",
    "value": "Waworada"
  },
  {
    "id": "5206112002",
    "district_id": "520611",
    "label": "Karumbu",
    "value": "Karumbu"
  },
  {
    "id": "5206112003",
    "district_id": "520611",
    "label": "Rupe",
    "value": "Rupe"
  },
  {
    "id": "5206112004",
    "district_id": "520611",
    "label": "Kalodu",
    "value": "Kalodu"
  },
  {
    "id": "5206112005",
    "district_id": "520611",
    "label": "Kawuwu",
    "value": "Kawuwu"
  },
  {
    "id": "5206112006",
    "district_id": "520611",
    "label": "Doro O'o",
    "value": "Doro O'o"
  },
  {
    "id": "5206112007",
    "district_id": "520611",
    "label": "Laju",
    "value": "Laju"
  },
  {
    "id": "5206112008",
    "district_id": "520611",
    "label": "Kangga",
    "value": "Kangga"
  },
  {
    "id": "5206112009",
    "district_id": "520611",
    "label": "Karampi",
    "value": "Karampi"
  },
  {
    "id": "5206112010",
    "district_id": "520611",
    "label": "Waduruka",
    "value": "Waduruka"
  },
  {
    "id": "5206112011",
    "district_id": "520611",
    "label": "Dumu",
    "value": "Dumu"
  },
  {
    "id": "5206112012",
    "district_id": "520611",
    "label": "Rompo",
    "value": "Rompo"
  },
  {
    "id": "5206112013",
    "district_id": "520611",
    "label": "Sarae Ruma",
    "value": "Sarae Ruma"
  },
  {
    "id": "5206112014",
    "district_id": "520611",
    "label": "Pusu",
    "value": "Pusu"
  },
  {
    "id": "5206112015",
    "district_id": "520611",
    "label": "Sambane",
    "value": "Sambane"
  },
  {
    "id": "5206122001",
    "district_id": "520612",
    "label": "Kaleo",
    "value": "Kaleo"
  },
  {
    "id": "5206122002",
    "district_id": "520612",
    "label": "Simpasai",
    "value": "Simpasai"
  },
  {
    "id": "5206122003",
    "district_id": "520612",
    "label": "Mangge",
    "value": "Mangge"
  },
  {
    "id": "5206122004",
    "district_id": "520612",
    "label": "Lanta",
    "value": "Lanta"
  },
  {
    "id": "5206122005",
    "district_id": "520612",
    "label": "Nggelu",
    "value": "Nggelu"
  },
  {
    "id": "5206122006",
    "district_id": "520612",
    "label": "Rato",
    "value": "Rato"
  },
  {
    "id": "5206122007",
    "district_id": "520612",
    "label": "Sumi",
    "value": "Sumi"
  },
  {
    "id": "5206122008",
    "district_id": "520612",
    "label": "Soro",
    "value": "Soro"
  },
  {
    "id": "5206122009",
    "district_id": "520612",
    "label": "Lambu",
    "value": "Lambu"
  },
  {
    "id": "5206122010",
    "district_id": "520612",
    "label": "Hidirasa",
    "value": "Hidirasa"
  },
  {
    "id": "5206122011",
    "district_id": "520612",
    "label": "Lanta Barat",
    "value": "Lanta Barat"
  },
  {
    "id": "5206122012",
    "district_id": "520612",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "5206122013",
    "district_id": "520612",
    "label": "Sangga",
    "value": "Sangga"
  },
  {
    "id": "5206122014",
    "district_id": "520612",
    "label": "Monta Baru",
    "value": "Monta Baru"
  },
  {
    "id": "5206132001",
    "district_id": "520613",
    "label": "Woro",
    "value": "Woro"
  },
  {
    "id": "5206132002",
    "district_id": "520613",
    "label": "Campa",
    "value": "Campa"
  },
  {
    "id": "5206132003",
    "district_id": "520613",
    "label": "Mpuri",
    "value": "Mpuri"
  },
  {
    "id": "5206132004",
    "district_id": "520613",
    "label": "Ndano",
    "value": "Ndano"
  },
  {
    "id": "5206132005",
    "district_id": "520613",
    "label": "Monggo",
    "value": "Monggo"
  },
  {
    "id": "5206132006",
    "district_id": "520613",
    "label": "Dena",
    "value": "Dena"
  },
  {
    "id": "5206132007",
    "district_id": "520613",
    "label": "Rade",
    "value": "Rade"
  },
  {
    "id": "5206132008",
    "district_id": "520613",
    "label": "Tonda",
    "value": "Tonda"
  },
  {
    "id": "5206132009",
    "district_id": "520613",
    "label": "Bolo",
    "value": "Bolo"
  },
  {
    "id": "5206132010",
    "district_id": "520613",
    "label": "Madawau",
    "value": "Madawau"
  },
  {
    "id": "5206132011",
    "district_id": "520613",
    "label": "Ncandi",
    "value": "Ncandi"
  },
  {
    "id": "5206142001",
    "district_id": "520614",
    "label": "Labuhan Kenanga",
    "value": "Labuhan Kenanga"
  },
  {
    "id": "5206142002",
    "district_id": "520614",
    "label": "Kawinda Nae",
    "value": "Kawinda Nae"
  },
  {
    "id": "5206142003",
    "district_id": "520614",
    "label": "Kawinda Toi",
    "value": "Kawinda Toi"
  },
  {
    "id": "5206142004",
    "district_id": "520614",
    "label": "Oi Panihi",
    "value": "Oi Panihi"
  },
  {
    "id": "5206142005",
    "district_id": "520614",
    "label": "Oi Bura",
    "value": "Oi Bura"
  },
  {
    "id": "5206142006",
    "district_id": "520614",
    "label": "Oi Katupa",
    "value": "Oi Katupa"
  },
  {
    "id": "5206142007",
    "district_id": "520614",
    "label": "Rasabou",
    "value": "Rasabou"
  },
  {
    "id": "5206152001",
    "district_id": "520615",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "5206152002",
    "district_id": "520615",
    "label": "Punti",
    "value": "Punti"
  },
  {
    "id": "5206152003",
    "district_id": "520615",
    "label": "Wadukopa",
    "value": "Wadukopa"
  },
  {
    "id": "5206152004",
    "district_id": "520615",
    "label": "Kanata",
    "value": "Kanata"
  },
  {
    "id": "5206152005",
    "district_id": "520615",
    "label": "Sai",
    "value": "Sai"
  },
  {
    "id": "5206152006",
    "district_id": "520615",
    "label": "Sampungu",
    "value": "Sampungu"
  },
  {
    "id": "5206152007",
    "district_id": "520615",
    "label": "Lewintana",
    "value": "Lewintana"
  },
  {
    "id": "5206162001",
    "district_id": "520616",
    "label": "Parado Wane",
    "value": "Parado Wane"
  },
  {
    "id": "5206162002",
    "district_id": "520616",
    "label": "Parado Rato",
    "value": "Parado Rato"
  },
  {
    "id": "5206162003",
    "district_id": "520616",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "5206162004",
    "district_id": "520616",
    "label": "Kanca",
    "value": "Kanca"
  },
  {
    "id": "5206162005",
    "district_id": "520616",
    "label": "Lere",
    "value": "Lere"
  },
  {
    "id": "5206172001",
    "district_id": "520617",
    "label": "Kabaro",
    "value": "Kabaro"
  },
  {
    "id": "5206172002",
    "district_id": "520617",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "5206172003",
    "district_id": "520617",
    "label": "Sambori",
    "value": "Sambori"
  },
  {
    "id": "5206172004",
    "district_id": "520617",
    "label": "Teta",
    "value": "Teta"
  },
  {
    "id": "5206172005",
    "district_id": "520617",
    "label": "Ka'owa",
    "value": "Ka'owa"
  },
  {
    "id": "5206172006",
    "district_id": "520617",
    "label": "Londu",
    "value": "Londu"
  },
  {
    "id": "5206182001",
    "district_id": "520618",
    "label": "Panda",
    "value": "Panda"
  },
  {
    "id": "5206182002",
    "district_id": "520618",
    "label": "Belo",
    "value": "Belo"
  },
  {
    "id": "5206182003",
    "district_id": "520618",
    "label": "Tolongondoa",
    "value": "Tolongondoa"
  },
  {
    "id": "5206182004",
    "district_id": "520618",
    "label": "Tonggorisa",
    "value": "Tonggorisa"
  },
  {
    "id": "5206182005",
    "district_id": "520618",
    "label": "Teke",
    "value": "Teke"
  },
  {
    "id": "5206182006",
    "district_id": "520618",
    "label": "Ntonggu",
    "value": "Ntonggu"
  },
  {
    "id": "5206182007",
    "district_id": "520618",
    "label": "Nata",
    "value": "Nata"
  },
  {
    "id": "5206182008",
    "district_id": "520618",
    "label": "Dore",
    "value": "Dore"
  },
  {
    "id": "5206182009",
    "district_id": "520618",
    "label": "Roi",
    "value": "Roi"
  },
  {
    "id": "5206182011",
    "district_id": "520618",
    "label": "Ragi",
    "value": "Ragi"
  },
  {
    "id": "5206182012",
    "district_id": "520618",
    "label": "Padolo",
    "value": "Padolo"
  },
  {
    "id": "5206182013",
    "district_id": "520618",
    "label": "Bre",
    "value": "Bre"
  },
  {
    "id": "5207012001",
    "district_id": "520701",
    "label": "Goa",
    "value": "Goa"
  },
  {
    "id": "5207012002",
    "district_id": "520701",
    "label": "Belo",
    "value": "Belo"
  },
  {
    "id": "5207012003",
    "district_id": "520701",
    "label": "Beru",
    "value": "Beru"
  },
  {
    "id": "5207012009",
    "district_id": "520701",
    "label": "Dasan Anyar",
    "value": "Dasan Anyar"
  },
  {
    "id": "5207021004",
    "district_id": "520702",
    "label": "Menala",
    "value": "Menala"
  },
  {
    "id": "5207021005",
    "district_id": "520702",
    "label": "Kuang",
    "value": "Kuang"
  },
  {
    "id": "5207021006",
    "district_id": "520702",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "5207021007",
    "district_id": "520702",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "5207021008",
    "district_id": "520702",
    "label": "Sampir",
    "value": "Sampir"
  },
  {
    "id": "5207021012",
    "district_id": "520702",
    "label": "Telaga Bertong",
    "value": "Telaga Bertong"
  },
  {
    "id": "5207021019",
    "district_id": "520702",
    "label": "Arab Kenangan",
    "value": "Arab Kenangan"
  },
  {
    "id": "5207022001",
    "district_id": "520702",
    "label": "Labuhan Lalar",
    "value": "Labuhan Lalar"
  },
  {
    "id": "5207022009",
    "district_id": "520702",
    "label": "Lalar Liang",
    "value": "Lalar Liang"
  },
  {
    "id": "5207022010",
    "district_id": "520702",
    "label": "Labuhan Kertasari",
    "value": "Labuhan Kertasari"
  },
  {
    "id": "5207022011",
    "district_id": "520702",
    "label": "Seloto",
    "value": "Seloto"
  },
  {
    "id": "5207022013",
    "district_id": "520702",
    "label": "Tamekan",
    "value": "Tamekan"
  },
  {
    "id": "5207022014",
    "district_id": "520702",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "5207022015",
    "district_id": "520702",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "5207022020",
    "district_id": "520702",
    "label": "Sermong",
    "value": "Sermong"
  },
  {
    "id": "5207032001",
    "district_id": "520703",
    "label": "Meraran",
    "value": "Meraran"
  },
  {
    "id": "5207032002",
    "district_id": "520703",
    "label": "Air Suning",
    "value": "Air Suning"
  },
  {
    "id": "5207032003",
    "district_id": "520703",
    "label": "Rempe",
    "value": "Rempe"
  },
  {
    "id": "5207032004",
    "district_id": "520703",
    "label": "Seteluk Atas",
    "value": "Seteluk Atas"
  },
  {
    "id": "5207032005",
    "district_id": "520703",
    "label": "Seteluk Tengah",
    "value": "Seteluk Tengah"
  },
  {
    "id": "5207032008",
    "district_id": "520703",
    "label": "Kelanir",
    "value": "Kelanir"
  },
  {
    "id": "5207032011",
    "district_id": "520703",
    "label": "Tapir",
    "value": "Tapir"
  },
  {
    "id": "5207032013",
    "district_id": "520703",
    "label": "Lamusung",
    "value": "Lamusung"
  },
  {
    "id": "5207032014",
    "district_id": "520703",
    "label": "Seran",
    "value": "Seran"
  },
  {
    "id": "5207032015",
    "district_id": "520703",
    "label": "Desaloka",
    "value": "Desaloka"
  },
  {
    "id": "5207042001",
    "district_id": "520704",
    "label": "Sekongkang Atas",
    "value": "Sekongkang Atas"
  },
  {
    "id": "5207042002",
    "district_id": "520704",
    "label": "Sekongkang Bawah",
    "value": "Sekongkang Bawah"
  },
  {
    "id": "5207042003",
    "district_id": "520704",
    "label": "Tongo",
    "value": "Tongo"
  },
  {
    "id": "5207042004",
    "district_id": "520704",
    "label": "Ai Kangkung",
    "value": "Ai Kangkung"
  },
  {
    "id": "5207042005",
    "district_id": "520704",
    "label": "Tatar",
    "value": "Tatar"
  },
  {
    "id": "5207042006",
    "district_id": "520704",
    "label": "Talonang Baru",
    "value": "Talonang Baru"
  },
  {
    "id": "5207042007",
    "district_id": "520704",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "5207052001",
    "district_id": "520705",
    "label": "Desa Beru",
    "value": "Desa Beru"
  },
  {
    "id": "5207052002",
    "district_id": "520705",
    "label": "Tepas",
    "value": "Tepas"
  },
  {
    "id": "5207052003",
    "district_id": "520705",
    "label": "Bangkat Monteh",
    "value": "Bangkat Monteh"
  },
  {
    "id": "5207052004",
    "district_id": "520705",
    "label": "Sapugara Bree",
    "value": "Sapugara Bree"
  },
  {
    "id": "5207052005",
    "district_id": "520705",
    "label": "Tepas Sepakat",
    "value": "Tepas Sepakat"
  },
  {
    "id": "5207052006",
    "district_id": "520705",
    "label": "Lamuntet",
    "value": "Lamuntet"
  },
  {
    "id": "5207052007",
    "district_id": "520705",
    "label": "Rarak Ronges",
    "value": "Rarak Ronges"
  },
  {
    "id": "5207052008",
    "district_id": "520705",
    "label": "Moteng",
    "value": "Moteng"
  },
  {
    "id": "5207052009",
    "district_id": "520705",
    "label": "Seminar Salit",
    "value": "Seminar Salit"
  },
  {
    "id": "5207062001",
    "district_id": "520706",
    "label": "Senayan",
    "value": "Senayan"
  },
  {
    "id": "5207062002",
    "district_id": "520706",
    "label": "Mantar",
    "value": "Mantar"
  },
  {
    "id": "5207062003",
    "district_id": "520706",
    "label": "Kiantar",
    "value": "Kiantar"
  },
  {
    "id": "5207062004",
    "district_id": "520706",
    "label": "Poto Tano",
    "value": "Poto Tano"
  },
  {
    "id": "5207062005",
    "district_id": "520706",
    "label": "UPT Tambak Sari",
    "value": "UPT Tambak Sari"
  },
  {
    "id": "5207062006",
    "district_id": "520706",
    "label": "Kokarlian",
    "value": "Kokarlian"
  },
  {
    "id": "5207062007",
    "district_id": "520706",
    "label": "Tebo",
    "value": "Tebo"
  },
  {
    "id": "5207062008",
    "district_id": "520706",
    "label": "Tuananga",
    "value": "Tuananga"
  },
  {
    "id": "5207072001",
    "district_id": "520707",
    "label": "Mura",
    "value": "Mura"
  },
  {
    "id": "5207072002",
    "district_id": "520707",
    "label": "Kalimantong",
    "value": "Kalimantong"
  },
  {
    "id": "5207072003",
    "district_id": "520707",
    "label": "Lampok",
    "value": "Lampok"
  },
  {
    "id": "5207072004",
    "district_id": "520707",
    "label": "Manemeng",
    "value": "Manemeng"
  },
  {
    "id": "5207072005",
    "district_id": "520707",
    "label": "Mujahiddin",
    "value": "Mujahiddin"
  },
  {
    "id": "5207072006",
    "district_id": "520707",
    "label": "Mataiyang",
    "value": "Mataiyang"
  },
  {
    "id": "5207082001",
    "district_id": "520708",
    "label": "Maluk",
    "value": "Maluk"
  },
  {
    "id": "5207082002",
    "district_id": "520708",
    "label": "Benete",
    "value": "Benete"
  },
  {
    "id": "5207082003",
    "district_id": "520708",
    "label": "Bukit Damai",
    "value": "Bukit Damai"
  },
  {
    "id": "5207082004",
    "district_id": "520708",
    "label": "Mantun",
    "value": "Mantun"
  },
  {
    "id": "5207082005",
    "district_id": "520708",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "5208012001",
    "district_id": "520801",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "5208012002",
    "district_id": "520801",
    "label": "Sokong",
    "value": "Sokong"
  },
  {
    "id": "5208012003",
    "district_id": "520801",
    "label": "Jenggala",
    "value": "Jenggala"
  },
  {
    "id": "5208012004",
    "district_id": "520801",
    "label": "Tegal Maja",
    "value": "Tegal Maja"
  },
  {
    "id": "5208012005",
    "district_id": "520801",
    "label": "Sigar Penjalin",
    "value": "Sigar Penjalin"
  },
  {
    "id": "5208012006",
    "district_id": "520801",
    "label": "Medana",
    "value": "Medana"
  },
  {
    "id": "5208012007",
    "district_id": "520801",
    "label": "Teniga",
    "value": "Teniga"
  },
  {
    "id": "5208022001",
    "district_id": "520802",
    "label": "Gondang",
    "value": "Gondang"
  },
  {
    "id": "5208022002",
    "district_id": "520802",
    "label": "Bentek",
    "value": "Bentek"
  },
  {
    "id": "5208022003",
    "district_id": "520802",
    "label": "Genggelang",
    "value": "Genggelang"
  },
  {
    "id": "5208022004",
    "district_id": "520802",
    "label": "Rempek",
    "value": "Rempek"
  },
  {
    "id": "5208022005",
    "district_id": "520802",
    "label": "Sambik Bangkol",
    "value": "Sambik Bangkol"
  },
  {
    "id": "5208032001",
    "district_id": "520803",
    "label": "Kayangan",
    "value": "Kayangan"
  },
  {
    "id": "5208032002",
    "district_id": "520803",
    "label": "Santong",
    "value": "Santong"
  },
  {
    "id": "5208032003",
    "district_id": "520803",
    "label": "Selengen",
    "value": "Selengen"
  },
  {
    "id": "5208032004",
    "district_id": "520803",
    "label": "Sesait",
    "value": "Sesait"
  },
  {
    "id": "5208032005",
    "district_id": "520803",
    "label": "Gumantar",
    "value": "Gumantar"
  },
  {
    "id": "5208032006",
    "district_id": "520803",
    "label": "Salut",
    "value": "Salut"
  },
  {
    "id": "5208032007",
    "district_id": "520803",
    "label": "Dangiang",
    "value": "Dangiang"
  },
  {
    "id": "5208032008",
    "district_id": "520803",
    "label": "Pendua",
    "value": "Pendua"
  },
  {
    "id": "5208042001",
    "district_id": "520804",
    "label": "Bayan",
    "value": "Bayan"
  },
  {
    "id": "5208042002",
    "district_id": "520804",
    "label": "Loloan",
    "value": "Loloan"
  },
  {
    "id": "5208042003",
    "district_id": "520804",
    "label": "Anyar",
    "value": "Anyar"
  },
  {
    "id": "5208042004",
    "district_id": "520804",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "5208042005",
    "district_id": "520804",
    "label": "Akat Akar",
    "value": "Akat Akar"
  },
  {
    "id": "5208042006",
    "district_id": "520804",
    "label": "Senaru",
    "value": "Senaru"
  },
  {
    "id": "5208042007",
    "district_id": "520804",
    "label": "Mumbul Sari",
    "value": "Mumbul Sari"
  },
  {
    "id": "5208042008",
    "district_id": "520804",
    "label": "Sambik Elen",
    "value": "Sambik Elen"
  },
  {
    "id": "5208042009",
    "district_id": "520804",
    "label": "Karang Bajo",
    "value": "Karang Bajo"
  },
  {
    "id": "5208052001",
    "district_id": "520805",
    "label": "Pemenang Timur",
    "value": "Pemenang Timur"
  },
  {
    "id": "5208052002",
    "district_id": "520805",
    "label": "Pemenang Barat",
    "value": "Pemenang Barat"
  },
  {
    "id": "5208052003",
    "district_id": "520805",
    "label": "Gili Indah",
    "value": "Gili Indah"
  },
  {
    "id": "5208052004",
    "district_id": "520805",
    "label": "Malaka",
    "value": "Malaka"
  },
  {
    "id": "5271011004",
    "district_id": "527101",
    "label": "Ampenan Selatan",
    "value": "Ampenan Selatan"
  },
  {
    "id": "5271011005",
    "district_id": "527101",
    "label": "Ampenan Tengah",
    "value": "Ampenan Tengah"
  },
  {
    "id": "5271011006",
    "district_id": "527101",
    "label": "Pejeruk",
    "value": "Pejeruk"
  },
  {
    "id": "5271011007",
    "district_id": "527101",
    "label": "Ampenan Utara",
    "value": "Ampenan Utara"
  },
  {
    "id": "5271011008",
    "district_id": "527101",
    "label": "Taman Sari",
    "value": "Taman Sari"
  },
  {
    "id": "5271011009",
    "district_id": "527101",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "5271011010",
    "district_id": "527101",
    "label": "Kebun Sari",
    "value": "Kebun Sari"
  },
  {
    "id": "5271011011",
    "district_id": "527101",
    "label": "Pajarakan Karya",
    "value": "Pajarakan Karya"
  },
  {
    "id": "5271011012",
    "district_id": "527101",
    "label": "Dayan Peken",
    "value": "Dayan Peken"
  },
  {
    "id": "5271011013",
    "district_id": "527101",
    "label": "Bintaro",
    "value": "Bintaro"
  },
  {
    "id": "5271021001",
    "district_id": "527102",
    "label": "Pagesangan",
    "value": "Pagesangan"
  },
  {
    "id": "5271021002",
    "district_id": "527102",
    "label": "Mataram Timur",
    "value": "Mataram Timur"
  },
  {
    "id": "5271021008",
    "district_id": "527102",
    "label": "Pagutan",
    "value": "Pagutan"
  },
  {
    "id": "5271021009",
    "district_id": "527102",
    "label": "Pagesangan Barat",
    "value": "Pagesangan Barat"
  },
  {
    "id": "5271021010",
    "district_id": "527102",
    "label": "Pagesangan Timur",
    "value": "Pagesangan Timur"
  },
  {
    "id": "5271021011",
    "district_id": "527102",
    "label": "Pejanggik",
    "value": "Pejanggik"
  },
  {
    "id": "5271021012",
    "district_id": "527102",
    "label": "Punia",
    "value": "Punia"
  },
  {
    "id": "5271021013",
    "district_id": "527102",
    "label": "Pagutan Timur",
    "value": "Pagutan Timur"
  },
  {
    "id": "5271021014",
    "district_id": "527102",
    "label": "Pagutan Barat",
    "value": "Pagutan Barat"
  },
  {
    "id": "5271031004",
    "district_id": "527103",
    "label": "Cakranegara Selatan",
    "value": "Cakranegara Selatan"
  },
  {
    "id": "5271031005",
    "district_id": "527103",
    "label": "Cakranegara Barat",
    "value": "Cakranegara Barat"
  },
  {
    "id": "5271031006",
    "district_id": "527103",
    "label": "Cakranegara Timur",
    "value": "Cakranegara Timur"
  },
  {
    "id": "5271031007",
    "district_id": "527103",
    "label": "Cakranegara Utara",
    "value": "Cakranegara Utara"
  },
  {
    "id": "5271031009",
    "district_id": "527103",
    "label": "Sayang-sayang",
    "value": "Sayang-sayang"
  },
  {
    "id": "5271031010",
    "district_id": "527103",
    "label": "Cakranegara Selatan Baru",
    "value": "Cakranegara Selatan Baru"
  },
  {
    "id": "5271031011",
    "district_id": "527103",
    "label": "Sapta Marga",
    "value": "Sapta Marga"
  },
  {
    "id": "5271031012",
    "district_id": "527103",
    "label": "Cilinaya",
    "value": "Cilinaya"
  },
  {
    "id": "5271031013",
    "district_id": "527103",
    "label": "Mayura",
    "value": "Mayura"
  },
  {
    "id": "5271031014",
    "district_id": "527103",
    "label": "Karang Taliwang",
    "value": "Karang Taliwang"
  },
  {
    "id": "5271041001",
    "district_id": "527104",
    "label": "Karang Pule",
    "value": "Karang Pule"
  },
  {
    "id": "5271041002",
    "district_id": "527104",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "5271041003",
    "district_id": "527104",
    "label": "Jempong Baru",
    "value": "Jempong Baru"
  },
  {
    "id": "5271041004",
    "district_id": "527104",
    "label": "Tanjung Karang Permai",
    "value": "Tanjung Karang Permai"
  },
  {
    "id": "5271041005",
    "district_id": "527104",
    "label": "Kekalik Jaya",
    "value": "Kekalik Jaya"
  },
  {
    "id": "5271051001",
    "district_id": "527105",
    "label": "Mataram Barat",
    "value": "Mataram Barat"
  },
  {
    "id": "5271051002",
    "district_id": "527105",
    "label": "Dasan Agung",
    "value": "Dasan Agung"
  },
  {
    "id": "5271051003",
    "district_id": "527105",
    "label": "Monjok",
    "value": "Monjok"
  },
  {
    "id": "5271051004",
    "district_id": "527105",
    "label": "Karang Baru",
    "value": "Karang Baru"
  },
  {
    "id": "5271051005",
    "district_id": "527105",
    "label": "Rembiga",
    "value": "Rembiga"
  },
  {
    "id": "5271051006",
    "district_id": "527105",
    "label": "Gomong",
    "value": "Gomong"
  },
  {
    "id": "5271051007",
    "district_id": "527105",
    "label": "Dasan Agung Baru",
    "value": "Dasan Agung Baru"
  },
  {
    "id": "5271051008",
    "district_id": "527105",
    "label": "Monjok Timur",
    "value": "Monjok Timur"
  },
  {
    "id": "5271051009",
    "district_id": "527105",
    "label": "Monjok Barat",
    "value": "Monjok Barat"
  },
  {
    "id": "5271061001",
    "district_id": "527106",
    "label": "Dasan Cermen",
    "value": "Dasan Cermen"
  },
  {
    "id": "5271061002",
    "district_id": "527106",
    "label": "Babakan",
    "value": "Babakan"
  },
  {
    "id": "5271061003",
    "district_id": "527106",
    "label": "Bertais",
    "value": "Bertais"
  },
  {
    "id": "5271061004",
    "district_id": "527106",
    "label": "Selagalas",
    "value": "Selagalas"
  },
  {
    "id": "5271061005",
    "district_id": "527106",
    "label": "Abian Tubuh Baru",
    "value": "Abian Tubuh Baru"
  },
  {
    "id": "5271061006",
    "district_id": "527106",
    "label": "Turida",
    "value": "Turida"
  },
  {
    "id": "5271061007",
    "district_id": "527106",
    "label": "Mandalika",
    "value": "Mandalika"
  },
  {
    "id": "5272011001",
    "district_id": "527201",
    "label": "Paruga",
    "value": "Paruga"
  },
  {
    "id": "5272011002",
    "district_id": "527201",
    "label": "NaE",
    "value": "NaE"
  },
  {
    "id": "5272011003",
    "district_id": "527201",
    "label": "SaraE",
    "value": "SaraE"
  },
  {
    "id": "5272011004",
    "district_id": "527201",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "5272011013",
    "district_id": "527201",
    "label": "Pane",
    "value": "Pane"
  },
  {
    "id": "5272011014",
    "district_id": "527201",
    "label": "Dara",
    "value": "Dara"
  },
  {
    "id": "5272021004",
    "district_id": "527202",
    "label": "Kumbe",
    "value": "Kumbe"
  },
  {
    "id": "5272021008",
    "district_id": "527202",
    "label": "Lampe",
    "value": "Lampe"
  },
  {
    "id": "5272021009",
    "district_id": "527202",
    "label": "Dodu",
    "value": "Dodu"
  },
  {
    "id": "5272021011",
    "district_id": "527202",
    "label": "Nungga",
    "value": "Nungga"
  },
  {
    "id": "5272021018",
    "district_id": "527202",
    "label": "Kodo",
    "value": "Kodo"
  },
  {
    "id": "5272021019",
    "district_id": "527202",
    "label": "Oi Fo'o",
    "value": "Oi Fo'o"
  },
  {
    "id": "5272021020",
    "district_id": "527202",
    "label": "Lelamase",
    "value": "Lelamase"
  },
  {
    "id": "5272021021",
    "district_id": "527202",
    "label": "Oimbo",
    "value": "Oimbo"
  },
  {
    "id": "5272031001",
    "district_id": "527203",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "5272031002",
    "district_id": "527203",
    "label": "Jatiwangi",
    "value": "Jatiwangi"
  },
  {
    "id": "5272031003",
    "district_id": "527203",
    "label": "Jatibaru",
    "value": "Jatibaru"
  },
  {
    "id": "5272031004",
    "district_id": "527203",
    "label": "Kolo",
    "value": "Kolo"
  },
  {
    "id": "5272031005",
    "district_id": "527203",
    "label": "Jatibaru Timur",
    "value": "Jatibaru Timur"
  },
  {
    "id": "5272031006",
    "district_id": "527203",
    "label": "Ule",
    "value": "Ule"
  },
  {
    "id": "5272041001",
    "district_id": "527204",
    "label": "Penaraga",
    "value": "Penaraga"
  },
  {
    "id": "5272041002",
    "district_id": "527204",
    "label": "Rontu",
    "value": "Rontu"
  },
  {
    "id": "5272041003",
    "district_id": "527204",
    "label": "PenanaE",
    "value": "PenanaE"
  },
  {
    "id": "5272041004",
    "district_id": "527204",
    "label": "Kendo",
    "value": "Kendo"
  },
  {
    "id": "5272041005",
    "district_id": "527204",
    "label": "Ntobo",
    "value": "Ntobo"
  },
  {
    "id": "5272041006",
    "district_id": "527204",
    "label": "Nitu",
    "value": "Nitu"
  },
  {
    "id": "5272041007",
    "district_id": "527204",
    "label": "Rabangodu Selatan",
    "value": "Rabangodu Selatan"
  },
  {
    "id": "5272041008",
    "district_id": "527204",
    "label": "Rabadompu Timur",
    "value": "Rabadompu Timur"
  },
  {
    "id": "5272041009",
    "district_id": "527204",
    "label": "Rite",
    "value": "Rite"
  },
  {
    "id": "5272041010",
    "district_id": "527204",
    "label": "Rabadompu Barat",
    "value": "Rabadompu Barat"
  },
  {
    "id": "5272041011",
    "district_id": "527204",
    "label": "Rabangodu Utara",
    "value": "Rabangodu Utara"
  },
  {
    "id": "5272051001",
    "district_id": "527205",
    "label": "Monggonao",
    "value": "Monggonao"
  },
  {
    "id": "5272051002",
    "district_id": "527205",
    "label": "Sadia",
    "value": "Sadia"
  },
  {
    "id": "5272051003",
    "district_id": "527205",
    "label": "Santi",
    "value": "Santi"
  },
  {
    "id": "5272051004",
    "district_id": "527205",
    "label": "Sambinae",
    "value": "Sambinae"
  },
  {
    "id": "5272051005",
    "district_id": "527205",
    "label": "Penatoi",
    "value": "Penatoi"
  },
  {
    "id": "5272051006",
    "district_id": "527205",
    "label": "Lewirato",
    "value": "Lewirato"
  },
  {
    "id": "5272051007",
    "district_id": "527205",
    "label": "Mande",
    "value": "Mande"
  },
  {
    "id": "5272051008",
    "district_id": "527205",
    "label": "Panggi",
    "value": "Panggi"
  },
  {
    "id": "5272051009",
    "district_id": "527205",
    "label": "Manggemaci",
    "value": "Manggemaci"
  },
  {
    "id": "5272051010",
    "district_id": "527205",
    "label": "Matakando",
    "value": "Matakando"
  },
  {
    "id": "5301042003",
    "district_id": "530104",
    "label": "Bokonusan",
    "value": "Bokonusan"
  },
  {
    "id": "5301042004",
    "district_id": "530104",
    "label": "Otan",
    "value": "Otan"
  },
  {
    "id": "5301042005",
    "district_id": "530104",
    "label": "Uitao",
    "value": "Uitao"
  },
  {
    "id": "5301042006",
    "district_id": "530104",
    "label": "Uiasa",
    "value": "Uiasa"
  },
  {
    "id": "5301042008",
    "district_id": "530104",
    "label": "Hansisi",
    "value": "Hansisi"
  },
  {
    "id": "5301042009",
    "district_id": "530104",
    "label": "Huilelot",
    "value": "Huilelot"
  },
  {
    "id": "5301042011",
    "district_id": "530104",
    "label": "Batuinan",
    "value": "Batuinan"
  },
  {
    "id": "5301042013",
    "district_id": "530104",
    "label": "Letbaun",
    "value": "Letbaun"
  },
  {
    "id": "5301051001",
    "district_id": "530105",
    "label": "Oenesu",
    "value": "Oenesu"
  },
  {
    "id": "5301051002",
    "district_id": "530105",
    "label": "Batakte",
    "value": "Batakte"
  },
  {
    "id": "5301052003",
    "district_id": "530105",
    "label": "Manulai I",
    "value": "Manulai I"
  },
  {
    "id": "5301052004",
    "district_id": "530105",
    "label": "Sumlili",
    "value": "Sumlili"
  },
  {
    "id": "5301052005",
    "district_id": "530105",
    "label": "Lifuleo",
    "value": "Lifuleo"
  },
  {
    "id": "5301052006",
    "district_id": "530105",
    "label": "Tesabela",
    "value": "Tesabela"
  },
  {
    "id": "5301052007",
    "district_id": "530105",
    "label": "Oematnunu",
    "value": "Oematnunu"
  },
  {
    "id": "5301052008",
    "district_id": "530105",
    "label": "Kuanheun",
    "value": "Kuanheun"
  },
  {
    "id": "5301052009",
    "district_id": "530105",
    "label": "Nitneo",
    "value": "Nitneo"
  },
  {
    "id": "5301052010",
    "district_id": "530105",
    "label": "Bolok",
    "value": "Bolok"
  },
  {
    "id": "5301052011",
    "district_id": "530105",
    "label": "Tablolong",
    "value": "Tablolong"
  },
  {
    "id": "5301052012",
    "district_id": "530105",
    "label": "Oenaek",
    "value": "Oenaek"
  },
  {
    "id": "5301061001",
    "district_id": "530106",
    "label": "Babau",
    "value": "Babau"
  },
  {
    "id": "5301061003",
    "district_id": "530106",
    "label": "Naibonat",
    "value": "Naibonat"
  },
  {
    "id": "5301061004",
    "district_id": "530106",
    "label": "Oesao",
    "value": "Oesao"
  },
  {
    "id": "5301061005",
    "district_id": "530106",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "5301061008",
    "district_id": "530106",
    "label": "Tuatuka",
    "value": "Tuatuka"
  },
  {
    "id": "5301062002",
    "district_id": "530106",
    "label": "Nunkurus",
    "value": "Nunkurus"
  },
  {
    "id": "5301062006",
    "district_id": "530106",
    "label": "Tuapukan",
    "value": "Tuapukan"
  },
  {
    "id": "5301062007",
    "district_id": "530106",
    "label": "Oefafi",
    "value": "Oefafi"
  },
  {
    "id": "5301062011",
    "district_id": "530106",
    "label": "Pukdale",
    "value": "Pukdale"
  },
  {
    "id": "5301062015",
    "district_id": "530106",
    "label": "Oesao",
    "value": "Oesao"
  },
  {
    "id": "5301062016",
    "district_id": "530106",
    "label": "Manusak",
    "value": "Manusak"
  },
  {
    "id": "5301062017",
    "district_id": "530106",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "5301062018",
    "district_id": "530106",
    "label": "Oelatimo",
    "value": "Oelatimo"
  },
  {
    "id": "5301071001",
    "district_id": "530107",
    "label": "Sulamu",
    "value": "Sulamu"
  },
  {
    "id": "5301072002",
    "district_id": "530107",
    "label": "Pitai",
    "value": "Pitai"
  },
  {
    "id": "5301072003",
    "district_id": "530107",
    "label": "Pariti",
    "value": "Pariti"
  },
  {
    "id": "5301072004",
    "district_id": "530107",
    "label": "Oeteta",
    "value": "Oeteta"
  },
  {
    "id": "5301072005",
    "district_id": "530107",
    "label": "Bipolo",
    "value": "Bipolo"
  },
  {
    "id": "5301072006",
    "district_id": "530107",
    "label": "Pantai Beringin",
    "value": "Pantai Beringin"
  },
  {
    "id": "5301072007",
    "district_id": "530107",
    "label": "Pantulan",
    "value": "Pantulan"
  },
  {
    "id": "5301081007",
    "district_id": "530108",
    "label": "Tarus",
    "value": "Tarus"
  },
  {
    "id": "5301082006",
    "district_id": "530108",
    "label": "Oelnasi",
    "value": "Oelnasi"
  },
  {
    "id": "5301082008",
    "district_id": "530108",
    "label": "Noelbaki",
    "value": "Noelbaki"
  },
  {
    "id": "5301082009",
    "district_id": "530108",
    "label": "Oelpuah",
    "value": "Oelpuah"
  },
  {
    "id": "5301082010",
    "district_id": "530108",
    "label": "Oebelo",
    "value": "Oebelo"
  },
  {
    "id": "5301082011",
    "district_id": "530108",
    "label": "Penfui Timur",
    "value": "Penfui Timur"
  },
  {
    "id": "5301082012",
    "district_id": "530108",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "5301082013",
    "district_id": "530108",
    "label": "Mata Air",
    "value": "Mata Air"
  },
  {
    "id": "5301091002",
    "district_id": "530109",
    "label": "Nonbes",
    "value": "Nonbes"
  },
  {
    "id": "5301092001",
    "district_id": "530109",
    "label": "Oesena",
    "value": "Oesena"
  },
  {
    "id": "5301092003",
    "district_id": "530109",
    "label": "Ponain",
    "value": "Ponain"
  },
  {
    "id": "5301092004",
    "district_id": "530109",
    "label": "Kotabes",
    "value": "Kotabes"
  },
  {
    "id": "5301092005",
    "district_id": "530109",
    "label": "Tesbatan",
    "value": "Tesbatan"
  },
  {
    "id": "5301092006",
    "district_id": "530109",
    "label": "Oenoni",
    "value": "Oenoni"
  },
  {
    "id": "5301092007",
    "district_id": "530109",
    "label": "Apren",
    "value": "Apren"
  },
  {
    "id": "5301092008",
    "district_id": "530109",
    "label": "Oenoni II",
    "value": "Oenoni II"
  },
  {
    "id": "5301092009",
    "district_id": "530109",
    "label": "Tesbatan II",
    "value": "Tesbatan II"
  },
  {
    "id": "5301101001",
    "district_id": "530110",
    "label": "Camplong I",
    "value": "Camplong I"
  },
  {
    "id": "5301102007",
    "district_id": "530110",
    "label": "Camplong II",
    "value": "Camplong II"
  },
  {
    "id": "5301102008",
    "district_id": "530110",
    "label": "Naunnu",
    "value": "Naunnu"
  },
  {
    "id": "5301102009",
    "district_id": "530110",
    "label": "Oebola",
    "value": "Oebola"
  },
  {
    "id": "5301102010",
    "district_id": "530110",
    "label": "Sillu",
    "value": "Sillu"
  },
  {
    "id": "5301102011",
    "district_id": "530110",
    "label": "Ekateta",
    "value": "Ekateta"
  },
  {
    "id": "5301102012",
    "district_id": "530110",
    "label": "Kuimasi",
    "value": "Kuimasi"
  },
  {
    "id": "5301102013",
    "district_id": "530110",
    "label": "Tolnaku",
    "value": "Tolnaku"
  },
  {
    "id": "5301102014",
    "district_id": "530110",
    "label": "Oebola Dalam",
    "value": "Oebola Dalam"
  },
  {
    "id": "5301102015",
    "district_id": "530110",
    "label": "Kiuoni",
    "value": "Kiuoni"
  },
  {
    "id": "5301111007",
    "district_id": "530111",
    "label": "Takari",
    "value": "Takari"
  },
  {
    "id": "5301112001",
    "district_id": "530111",
    "label": "Noelmina",
    "value": "Noelmina"
  },
  {
    "id": "5301112002",
    "district_id": "530111",
    "label": "Benu",
    "value": "Benu"
  },
  {
    "id": "5301112003",
    "district_id": "530111",
    "label": "Hueknutu",
    "value": "Hueknutu"
  },
  {
    "id": "5301112004",
    "district_id": "530111",
    "label": "Oelnaineno",
    "value": "Oelnaineno"
  },
  {
    "id": "5301112005",
    "district_id": "530111",
    "label": "Tanini",
    "value": "Tanini"
  },
  {
    "id": "5301112006",
    "district_id": "530111",
    "label": "Kauniki",
    "value": "Kauniki"
  },
  {
    "id": "5301112008",
    "district_id": "530111",
    "label": "Tuapanaf",
    "value": "Tuapanaf"
  },
  {
    "id": "5301112009",
    "district_id": "530111",
    "label": "Fatukona",
    "value": "Fatukona"
  },
  {
    "id": "5301112010",
    "district_id": "530111",
    "label": "Oesusu",
    "value": "Oesusu"
  },
  {
    "id": "5301121003",
    "district_id": "530112",
    "label": "Lelogama",
    "value": "Lelogama"
  },
  {
    "id": "5301122001",
    "district_id": "530112",
    "label": "Fatusuki",
    "value": "Fatusuki"
  },
  {
    "id": "5301122002",
    "district_id": "530112",
    "label": "Oelbanu",
    "value": "Oelbanu"
  },
  {
    "id": "5301122004",
    "district_id": "530112",
    "label": "Fatumetan",
    "value": "Fatumetan"
  },
  {
    "id": "5301122005",
    "district_id": "530112",
    "label": "Oh'aem",
    "value": "Oh'aem"
  },
  {
    "id": "5301122008",
    "district_id": "530112",
    "label": "Leloboko",
    "value": "Leloboko"
  },
  {
    "id": "5301122011",
    "district_id": "530112",
    "label": "Oh'aem II",
    "value": "Oh'aem II"
  },
  {
    "id": "5301131002",
    "district_id": "530113",
    "label": "Naikliu",
    "value": "Naikliu"
  },
  {
    "id": "5301132001",
    "district_id": "530113",
    "label": "Afoan",
    "value": "Afoan"
  },
  {
    "id": "5301132003",
    "district_id": "530113",
    "label": "Fatunaus",
    "value": "Fatunaus"
  },
  {
    "id": "5301132004",
    "district_id": "530113",
    "label": "Kolabe",
    "value": "Kolabe"
  },
  {
    "id": "5301132005",
    "district_id": "530113",
    "label": "Bakuin",
    "value": "Bakuin"
  },
  {
    "id": "5301132010",
    "district_id": "530113",
    "label": "Lilmus",
    "value": "Lilmus"
  },
  {
    "id": "5301162001",
    "district_id": "530116",
    "label": "Oelomin",
    "value": "Oelomin"
  },
  {
    "id": "5301162002",
    "district_id": "530116",
    "label": "Tasikona",
    "value": "Tasikona"
  },
  {
    "id": "5301162003",
    "district_id": "530116",
    "label": "Oemasi",
    "value": "Oemasi"
  },
  {
    "id": "5301162004",
    "district_id": "530116",
    "label": "Usapi Sonbai",
    "value": "Usapi Sonbai"
  },
  {
    "id": "5301162005",
    "district_id": "530116",
    "label": "Oenif",
    "value": "Oenif"
  },
  {
    "id": "5301162006",
    "district_id": "530116",
    "label": "Tunfeu",
    "value": "Tunfeu"
  },
  {
    "id": "5301162007",
    "district_id": "530116",
    "label": "Oepaha",
    "value": "Oepaha"
  },
  {
    "id": "5301162008",
    "district_id": "530116",
    "label": "Bone",
    "value": "Bone"
  },
  {
    "id": "5301162009",
    "district_id": "530116",
    "label": "Taloetan",
    "value": "Taloetan"
  },
  {
    "id": "5301162010",
    "district_id": "530116",
    "label": "Oben",
    "value": "Oben"
  },
  {
    "id": "5301162011",
    "district_id": "530116",
    "label": "Bismarak",
    "value": "Bismarak"
  },
  {
    "id": "5301171001",
    "district_id": "530117",
    "label": "Teunbaun",
    "value": "Teunbaun"
  },
  {
    "id": "5301172002",
    "district_id": "530117",
    "label": "Soba",
    "value": "Soba"
  },
  {
    "id": "5301172003",
    "district_id": "530117",
    "label": "Toobaun",
    "value": "Toobaun"
  },
  {
    "id": "5301172004",
    "district_id": "530117",
    "label": "Niukbaun",
    "value": "Niukbaun"
  },
  {
    "id": "5301172005",
    "district_id": "530117",
    "label": "Tunbaun",
    "value": "Tunbaun"
  },
  {
    "id": "5301172006",
    "district_id": "530117",
    "label": "Nekbaun",
    "value": "Nekbaun"
  },
  {
    "id": "5301172007",
    "district_id": "530117",
    "label": "Merbaun",
    "value": "Merbaun"
  },
  {
    "id": "5301172008",
    "district_id": "530117",
    "label": "Erbaun",
    "value": "Erbaun"
  },
  {
    "id": "5301181001",
    "district_id": "530118",
    "label": "Buraen",
    "value": "Buraen"
  },
  {
    "id": "5301181002",
    "district_id": "530118",
    "label": "Sonraen",
    "value": "Sonraen"
  },
  {
    "id": "5301182003",
    "district_id": "530118",
    "label": "Nekmese",
    "value": "Nekmese"
  },
  {
    "id": "5301182004",
    "district_id": "530118",
    "label": "Retraen",
    "value": "Retraen"
  },
  {
    "id": "5301182005",
    "district_id": "530118",
    "label": "Sahraen",
    "value": "Sahraen"
  },
  {
    "id": "5301192001",
    "district_id": "530119",
    "label": "Oebesi",
    "value": "Oebesi"
  },
  {
    "id": "5301192002",
    "district_id": "530119",
    "label": "Pakubaun",
    "value": "Pakubaun"
  },
  {
    "id": "5301192003",
    "district_id": "530119",
    "label": "Rabeka",
    "value": "Rabeka"
  },
  {
    "id": "5301192004",
    "district_id": "530119",
    "label": "Enoraen",
    "value": "Enoraen"
  },
  {
    "id": "5301202001",
    "district_id": "530120",
    "label": "Seki",
    "value": "Seki"
  },
  {
    "id": "5301202002",
    "district_id": "530120",
    "label": "Oemofa",
    "value": "Oemofa"
  },
  {
    "id": "5301202003",
    "district_id": "530120",
    "label": "Nunmafo",
    "value": "Nunmafo"
  },
  {
    "id": "5301202004",
    "district_id": "530120",
    "label": "Muke",
    "value": "Muke"
  },
  {
    "id": "5301202005",
    "district_id": "530120",
    "label": "Pathau",
    "value": "Pathau"
  },
  {
    "id": "5301202006",
    "district_id": "530120",
    "label": "Oeniko",
    "value": "Oeniko"
  },
  {
    "id": "5301202007",
    "district_id": "530120",
    "label": "Oenuntono",
    "value": "Oenuntono"
  },
  {
    "id": "5301202008",
    "district_id": "530120",
    "label": "Oemolo",
    "value": "Oemolo"
  },
  {
    "id": "5301202009",
    "district_id": "530120",
    "label": "Enolanan",
    "value": "Enolanan"
  },
  {
    "id": "5301202010",
    "district_id": "530120",
    "label": "Oenaunu",
    "value": "Oenaunu"
  },
  {
    "id": "5301212001",
    "district_id": "530121",
    "label": "Manubelon",
    "value": "Manubelon"
  },
  {
    "id": "5301212002",
    "district_id": "530121",
    "label": "Letkole",
    "value": "Letkole"
  },
  {
    "id": "5301212003",
    "district_id": "530121",
    "label": "Nefoneut",
    "value": "Nefoneut"
  },
  {
    "id": "5301212004",
    "district_id": "530121",
    "label": "Bioba Baru",
    "value": "Bioba Baru"
  },
  {
    "id": "5301222001",
    "district_id": "530122",
    "label": "Soliu",
    "value": "Soliu"
  },
  {
    "id": "5301222002",
    "district_id": "530122",
    "label": "Saukibe",
    "value": "Saukibe"
  },
  {
    "id": "5301222003",
    "district_id": "530122",
    "label": "Oelfatu",
    "value": "Oelfatu"
  },
  {
    "id": "5301222004",
    "district_id": "530122",
    "label": "Timau",
    "value": "Timau"
  },
  {
    "id": "5301222005",
    "district_id": "530122",
    "label": "Honuk",
    "value": "Honuk"
  },
  {
    "id": "5301222006",
    "district_id": "530122",
    "label": "Faumes",
    "value": "Faumes"
  },
  {
    "id": "5301232001",
    "district_id": "530123",
    "label": "Akle",
    "value": "Akle"
  },
  {
    "id": "5301232002",
    "district_id": "530123",
    "label": "Uitiuh Ana",
    "value": "Uitiuh Ana"
  },
  {
    "id": "5301232003",
    "district_id": "530123",
    "label": "Onansila",
    "value": "Onansila"
  },
  {
    "id": "5301232004",
    "district_id": "530123",
    "label": "Uitiuh Tuan",
    "value": "Uitiuh Tuan"
  },
  {
    "id": "5301232005",
    "district_id": "530123",
    "label": "Naikean",
    "value": "Naikean"
  },
  {
    "id": "5301232006",
    "district_id": "530123",
    "label": "Uiboa",
    "value": "Uiboa"
  },
  {
    "id": "5301242001",
    "district_id": "530124",
    "label": "Oeltuah",
    "value": "Oeltuah"
  },
  {
    "id": "5301242002",
    "district_id": "530124",
    "label": "Baumata",
    "value": "Baumata"
  },
  {
    "id": "5301242003",
    "district_id": "530124",
    "label": "Kuaklalo",
    "value": "Kuaklalo"
  },
  {
    "id": "5301242004",
    "district_id": "530124",
    "label": "Oeletsala",
    "value": "Oeletsala"
  },
  {
    "id": "5301242005",
    "district_id": "530124",
    "label": "Bokong",
    "value": "Bokong"
  },
  {
    "id": "5301242006",
    "district_id": "530124",
    "label": "Baumata Utara",
    "value": "Baumata Utara"
  },
  {
    "id": "5301242007",
    "district_id": "530124",
    "label": "Baumata Timur",
    "value": "Baumata Timur"
  },
  {
    "id": "5301242008",
    "district_id": "530124",
    "label": "Baumata Barat",
    "value": "Baumata Barat"
  },
  {
    "id": "5301252001",
    "district_id": "530125",
    "label": "Fatukanutu",
    "value": "Fatukanutu"
  },
  {
    "id": "5301252002",
    "district_id": "530125",
    "label": "Kairane",
    "value": "Kairane"
  },
  {
    "id": "5301252003",
    "district_id": "530125",
    "label": "Raknamo",
    "value": "Raknamo"
  },
  {
    "id": "5301252004",
    "district_id": "530125",
    "label": "Kuanheum",
    "value": "Kuanheum"
  },
  {
    "id": "5301252005",
    "district_id": "530125",
    "label": "Fatuteta",
    "value": "Fatuteta"
  },
  {
    "id": "5301252006",
    "district_id": "530125",
    "label": "Oefeto",
    "value": "Oefeto"
  },
  {
    "id": "5301252007",
    "district_id": "530125",
    "label": "Niunbaun",
    "value": "Niunbaun"
  },
  {
    "id": "5301262001",
    "district_id": "530126",
    "label": "Nunuanah",
    "value": "Nunuanah"
  },
  {
    "id": "5301262002",
    "district_id": "530126",
    "label": "Kifu",
    "value": "Kifu"
  },
  {
    "id": "5301262003",
    "district_id": "530126",
    "label": "Netemnanu Selatan",
    "value": "Netemnanu Selatan"
  },
  {
    "id": "5301262004",
    "district_id": "530126",
    "label": "Netemnanu Utara",
    "value": "Netemnanu Utara"
  },
  {
    "id": "5301262005",
    "district_id": "530126",
    "label": "Netemnanu",
    "value": "Netemnanu"
  },
  {
    "id": "5301272001",
    "district_id": "530127",
    "label": "Poto",
    "value": "Poto"
  },
  {
    "id": "5301272002",
    "district_id": "530127",
    "label": "Nuataus",
    "value": "Nuataus"
  },
  {
    "id": "5301272003",
    "district_id": "530127",
    "label": "Kalali",
    "value": "Kalali"
  },
  {
    "id": "5301272004",
    "district_id": "530127",
    "label": "Tuakau",
    "value": "Tuakau"
  },
  {
    "id": "5301272005",
    "district_id": "530127",
    "label": "Naitae",
    "value": "Naitae"
  },
  {
    "id": "5301282001",
    "district_id": "530128",
    "label": "Oelbiteno",
    "value": "Oelbiteno"
  },
  {
    "id": "5301282002",
    "district_id": "530128",
    "label": "Nonbaun",
    "value": "Nonbaun"
  },
  {
    "id": "5301282003",
    "district_id": "530128",
    "label": "Nunsaen",
    "value": "Nunsaen"
  },
  {
    "id": "5301282004",
    "district_id": "530128",
    "label": "Passi",
    "value": "Passi"
  },
  {
    "id": "5301302001",
    "district_id": "530130",
    "label": "Fatumonas",
    "value": "Fatumonas"
  },
  {
    "id": "5301302002",
    "district_id": "530130",
    "label": "Binafun",
    "value": "Binafun"
  },
  {
    "id": "5301302003",
    "district_id": "530130",
    "label": "Bitobe",
    "value": "Bitobe"
  },
  {
    "id": "5301302004",
    "district_id": "530130",
    "label": "Bonmuti",
    "value": "Bonmuti"
  },
  {
    "id": "5302011001",
    "district_id": "530201",
    "label": "SoE",
    "value": "SoE"
  },
  {
    "id": "5302011002",
    "district_id": "530201",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "5302011003",
    "district_id": "530201",
    "label": "Oebesa",
    "value": "Oebesa"
  },
  {
    "id": "5302011004",
    "district_id": "530201",
    "label": "Taubneno",
    "value": "Taubneno"
  },
  {
    "id": "5302011005",
    "district_id": "530201",
    "label": "Nonohonis",
    "value": "Nonohonis"
  },
  {
    "id": "5302011006",
    "district_id": "530201",
    "label": "Karang Sirih",
    "value": "Karang Sirih"
  },
  {
    "id": "5302011007",
    "district_id": "530201",
    "label": "Nunumeu",
    "value": "Nunumeu"
  },
  {
    "id": "5302011008",
    "district_id": "530201",
    "label": "Kobekamusa",
    "value": "Kobekamusa"
  },
  {
    "id": "5302011009",
    "district_id": "530201",
    "label": "Oe Kefan",
    "value": "Oe Kefan"
  },
  {
    "id": "5302011010",
    "district_id": "530201",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "5302011011",
    "district_id": "530201",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "5302012012",
    "district_id": "530201",
    "label": "Kuatae",
    "value": "Kuatae"
  },
  {
    "id": "5302012013",
    "district_id": "530201",
    "label": "Noemeto",
    "value": "Noemeto"
  },
  {
    "id": "5302022001",
    "district_id": "530202",
    "label": "Biloto",
    "value": "Biloto"
  },
  {
    "id": "5302022003",
    "district_id": "530202",
    "label": "Kesetnana",
    "value": "Kesetnana"
  },
  {
    "id": "5302022006",
    "district_id": "530202",
    "label": "Tuasene",
    "value": "Tuasene"
  },
  {
    "id": "5302022008",
    "district_id": "530202",
    "label": "Oinlasi",
    "value": "Oinlasi"
  },
  {
    "id": "5302022010",
    "district_id": "530202",
    "label": "Bisene",
    "value": "Bisene"
  },
  {
    "id": "5302022012",
    "district_id": "530202",
    "label": "Noinbila",
    "value": "Noinbila"
  },
  {
    "id": "5302022013",
    "district_id": "530202",
    "label": "Bikekneno",
    "value": "Bikekneno"
  },
  {
    "id": "5302032001",
    "district_id": "530203",
    "label": "O'besi",
    "value": "O'besi"
  },
  {
    "id": "5302032002",
    "district_id": "530203",
    "label": "Eonbesi",
    "value": "Eonbesi"
  },
  {
    "id": "5302032003",
    "district_id": "530203",
    "label": "Bosen",
    "value": "Bosen"
  },
  {
    "id": "5302032004",
    "district_id": "530203",
    "label": "Sebot",
    "value": "Sebot"
  },
  {
    "id": "5302032007",
    "district_id": "530203",
    "label": "Ajaobaki",
    "value": "Ajaobaki"
  },
  {
    "id": "5302032008",
    "district_id": "530203",
    "label": "Netpala",
    "value": "Netpala"
  },
  {
    "id": "5302032009",
    "district_id": "530203",
    "label": "Tunua",
    "value": "Tunua"
  },
  {
    "id": "5302032010",
    "district_id": "530203",
    "label": "Bijaepunu",
    "value": "Bijaepunu"
  },
  {
    "id": "5302032011",
    "district_id": "530203",
    "label": "Nefokoko",
    "value": "Nefokoko"
  },
  {
    "id": "5302032012",
    "district_id": "530203",
    "label": "Leloboko",
    "value": "Leloboko"
  },
  {
    "id": "5302032013",
    "district_id": "530203",
    "label": "Lelobatan",
    "value": "Lelobatan"
  },
  {
    "id": "5302032014",
    "district_id": "530203",
    "label": "Halmei",
    "value": "Halmei"
  },
  {
    "id": "5302032015",
    "district_id": "530203",
    "label": "Fatukoto",
    "value": "Fatukoto"
  },
  {
    "id": "5302032016",
    "district_id": "530203",
    "label": "Kokfe'u",
    "value": "Kokfe'u"
  },
  {
    "id": "5302032017",
    "district_id": "530203",
    "label": "To'fen",
    "value": "To'fen"
  },
  {
    "id": "5302032018",
    "district_id": "530203",
    "label": "Taiftob",
    "value": "Taiftob"
  },
  {
    "id": "5302032019",
    "district_id": "530203",
    "label": "Iusmolo",
    "value": "Iusmolo"
  },
  {
    "id": "5302032020",
    "district_id": "530203",
    "label": "To'manat",
    "value": "To'manat"
  },
  {
    "id": "5302042001",
    "district_id": "530204",
    "label": "Billa",
    "value": "Billa"
  },
  {
    "id": "5302042002",
    "district_id": "530204",
    "label": "Oelet",
    "value": "Oelet"
  },
  {
    "id": "5302042004",
    "district_id": "530204",
    "label": "Pisan",
    "value": "Pisan"
  },
  {
    "id": "5302042005",
    "district_id": "530204",
    "label": "Mauleum",
    "value": "Mauleum"
  },
  {
    "id": "5302042007",
    "district_id": "530204",
    "label": "Nifukiu",
    "value": "Nifukiu"
  },
  {
    "id": "5302042008",
    "district_id": "530204",
    "label": "Mnelaanen",
    "value": "Mnelaanen"
  },
  {
    "id": "5302042009",
    "district_id": "530204",
    "label": "Teluk",
    "value": "Teluk"
  },
  {
    "id": "5302042012",
    "district_id": "530204",
    "label": "Oe'Ekam",
    "value": "Oe'Ekam"
  },
  {
    "id": "5302042016",
    "district_id": "530204",
    "label": "Sini",
    "value": "Sini"
  },
  {
    "id": "5302042017",
    "district_id": "530204",
    "label": "Tliu",
    "value": "Tliu"
  },
  {
    "id": "5302051010",
    "district_id": "530205",
    "label": "Niki-Niki",
    "value": "Niki-Niki"
  },
  {
    "id": "5302052001",
    "district_id": "530205",
    "label": "Taebesa",
    "value": "Taebesa"
  },
  {
    "id": "5302052002",
    "district_id": "530205",
    "label": "Nakfunu",
    "value": "Nakfunu"
  },
  {
    "id": "5302052003",
    "district_id": "530205",
    "label": "Oe'ekam",
    "value": "Oe'ekam"
  },
  {
    "id": "5302052004",
    "district_id": "530205",
    "label": "Baki",
    "value": "Baki"
  },
  {
    "id": "5302052005",
    "district_id": "530205",
    "label": "Tumu",
    "value": "Tumu"
  },
  {
    "id": "5302052006",
    "district_id": "530205",
    "label": "Noebesa",
    "value": "Noebesa"
  },
  {
    "id": "5302052007",
    "district_id": "530205",
    "label": "Nobi-Nobi",
    "value": "Nobi-Nobi"
  },
  {
    "id": "5302052008",
    "district_id": "530205",
    "label": "Sopo",
    "value": "Sopo"
  },
  {
    "id": "5302052009",
    "district_id": "530205",
    "label": "Bone",
    "value": "Bone"
  },
  {
    "id": "5302052011",
    "district_id": "530205",
    "label": "Maunum Niki-Niki",
    "value": "Maunum Niki-Niki"
  },
  {
    "id": "5302062001",
    "district_id": "530206",
    "label": "Pollo",
    "value": "Pollo"
  },
  {
    "id": "5302062002",
    "district_id": "530206",
    "label": "Oekiu",
    "value": "Oekiu"
  },
  {
    "id": "5302062003",
    "district_id": "530206",
    "label": "Oebelo",
    "value": "Oebelo"
  },
  {
    "id": "5302062004",
    "district_id": "530206",
    "label": "Noemuke",
    "value": "Noemuke"
  },
  {
    "id": "5302062010",
    "district_id": "530206",
    "label": "Kiubaat",
    "value": "Kiubaat"
  },
  {
    "id": "5302062011",
    "district_id": "530206",
    "label": "Linamnutu",
    "value": "Linamnutu"
  },
  {
    "id": "5302062014",
    "district_id": "530206",
    "label": "Mio",
    "value": "Mio"
  },
  {
    "id": "5302062015",
    "district_id": "530206",
    "label": "Batnun",
    "value": "Batnun"
  },
  {
    "id": "5302062018",
    "district_id": "530206",
    "label": "Bena",
    "value": "Bena"
  },
  {
    "id": "5302062019",
    "district_id": "530206",
    "label": "Eno Neten",
    "value": "Eno Neten"
  },
  {
    "id": "5302072007",
    "district_id": "530207",
    "label": "Tublopo",
    "value": "Tublopo"
  },
  {
    "id": "5302072008",
    "district_id": "530207",
    "label": "Nule",
    "value": "Nule"
  },
  {
    "id": "5302072009",
    "district_id": "530207",
    "label": "Nusa",
    "value": "Nusa"
  },
  {
    "id": "5302072010",
    "district_id": "530207",
    "label": "Mnelalete",
    "value": "Mnelalete"
  },
  {
    "id": "5302072011",
    "district_id": "530207",
    "label": "Pusu",
    "value": "Pusu"
  },
  {
    "id": "5302072012",
    "district_id": "530207",
    "label": "Tubuhue",
    "value": "Tubuhue"
  },
  {
    "id": "5302072013",
    "district_id": "530207",
    "label": "Haumeni Baki",
    "value": "Haumeni Baki"
  },
  {
    "id": "5302072015",
    "district_id": "530207",
    "label": "Nifukani",
    "value": "Nifukani"
  },
  {
    "id": "5302082001",
    "district_id": "530208",
    "label": "Oinlasi",
    "value": "Oinlasi"
  },
  {
    "id": "5302082002",
    "district_id": "530208",
    "label": "Nunle'u",
    "value": "Nunle'u"
  },
  {
    "id": "5302082003",
    "district_id": "530208",
    "label": "Kokoi",
    "value": "Kokoi"
  },
  {
    "id": "5302082004",
    "district_id": "530208",
    "label": "Fenun",
    "value": "Fenun"
  },
  {
    "id": "5302082005",
    "district_id": "530208",
    "label": "Kuale'u",
    "value": "Kuale'u"
  },
  {
    "id": "5302082006",
    "district_id": "530208",
    "label": "Lanu",
    "value": "Lanu"
  },
  {
    "id": "5302082007",
    "district_id": "530208",
    "label": "Sunu",
    "value": "Sunu"
  },
  {
    "id": "5302082008",
    "district_id": "530208",
    "label": "Anin",
    "value": "Anin"
  },
  {
    "id": "5302082009",
    "district_id": "530208",
    "label": "To'i",
    "value": "To'i"
  },
  {
    "id": "5302082010",
    "district_id": "530208",
    "label": "Nifuleo",
    "value": "Nifuleo"
  },
  {
    "id": "5302082011",
    "district_id": "530208",
    "label": "Fatulunu",
    "value": "Fatulunu"
  },
  {
    "id": "5302082012",
    "district_id": "530208",
    "label": "Fae",
    "value": "Fae"
  },
  {
    "id": "5302082013",
    "district_id": "530208",
    "label": "Netutnana",
    "value": "Netutnana"
  },
  {
    "id": "5302092001",
    "district_id": "530209",
    "label": "Lilo",
    "value": "Lilo"
  },
  {
    "id": "5302092002",
    "district_id": "530209",
    "label": "Fotilo",
    "value": "Fotilo"
  },
  {
    "id": "5302092003",
    "district_id": "530209",
    "label": "Nasi",
    "value": "Nasi"
  },
  {
    "id": "5302092004",
    "district_id": "530209",
    "label": "Snok",
    "value": "Snok"
  },
  {
    "id": "5302092005",
    "district_id": "530209",
    "label": "Tumu",
    "value": "Tumu"
  },
  {
    "id": "5302092006",
    "district_id": "530209",
    "label": "Fatu Oni",
    "value": "Fatu Oni"
  },
  {
    "id": "5302092007",
    "district_id": "530209",
    "label": "Sono",
    "value": "Sono"
  },
  {
    "id": "5302092009",
    "district_id": "530209",
    "label": "Tauanas",
    "value": "Tauanas"
  },
  {
    "id": "5302092015",
    "district_id": "530209",
    "label": "Muna",
    "value": "Muna"
  },
  {
    "id": "5302102001",
    "district_id": "530210",
    "label": "Pilli",
    "value": "Pilli"
  },
  {
    "id": "5302102002",
    "district_id": "530210",
    "label": "Falas",
    "value": "Falas"
  },
  {
    "id": "5302102003",
    "district_id": "530210",
    "label": "Oenai",
    "value": "Oenai"
  },
  {
    "id": "5302102004",
    "district_id": "530210",
    "label": "Belle",
    "value": "Belle"
  },
  {
    "id": "5302102005",
    "district_id": "530210",
    "label": "Napi",
    "value": "Napi"
  },
  {
    "id": "5302102006",
    "district_id": "530210",
    "label": "Boti",
    "value": "Boti"
  },
  {
    "id": "5302102007",
    "district_id": "530210",
    "label": "Fatuulan",
    "value": "Fatuulan"
  },
  {
    "id": "5302102008",
    "district_id": "530210",
    "label": "Tesiayofanu",
    "value": "Tesiayofanu"
  },
  {
    "id": "5302102009",
    "district_id": "530210",
    "label": "Oinlasi",
    "value": "Oinlasi"
  },
  {
    "id": "5302102010",
    "district_id": "530210",
    "label": "Nekmese",
    "value": "Nekmese"
  },
  {
    "id": "5302102011",
    "district_id": "530210",
    "label": "Eno Napi",
    "value": "Eno Napi"
  },
  {
    "id": "5302102012",
    "district_id": "530210",
    "label": "Fatukusi",
    "value": "Fatukusi"
  },
  {
    "id": "5302102013",
    "district_id": "530210",
    "label": "Naile'u",
    "value": "Naile'u"
  },
  {
    "id": "5302112001",
    "district_id": "530211",
    "label": "Olais",
    "value": "Olais"
  },
  {
    "id": "5302112002",
    "district_id": "530211",
    "label": "Lasi",
    "value": "Lasi"
  },
  {
    "id": "5302112003",
    "district_id": "530211",
    "label": "Kakan",
    "value": "Kakan"
  },
  {
    "id": "5302112005",
    "district_id": "530211",
    "label": "Basmuti",
    "value": "Basmuti"
  },
  {
    "id": "5302112006",
    "district_id": "530211",
    "label": "Kusi",
    "value": "Kusi"
  },
  {
    "id": "5302112007",
    "district_id": "530211",
    "label": "Kuanfatu",
    "value": "Kuanfatu"
  },
  {
    "id": "5302112008",
    "district_id": "530211",
    "label": "Kelle",
    "value": "Kelle"
  },
  {
    "id": "5302112009",
    "district_id": "530211",
    "label": "Taupi",
    "value": "Taupi"
  },
  {
    "id": "5302112010",
    "district_id": "530211",
    "label": "Oebo",
    "value": "Oebo"
  },
  {
    "id": "5302112011",
    "district_id": "530211",
    "label": "Kusi Utara",
    "value": "Kusi Utara"
  },
  {
    "id": "5302112012",
    "district_id": "530211",
    "label": "Kelle Tunan",
    "value": "Kelle Tunan"
  },
  {
    "id": "5302112013",
    "district_id": "530211",
    "label": "Noebeba",
    "value": "Noebeba"
  },
  {
    "id": "5302112014",
    "district_id": "530211",
    "label": "Oehan",
    "value": "Oehan"
  },
  {
    "id": "5302122001",
    "district_id": "530212",
    "label": "Nenas",
    "value": "Nenas"
  },
  {
    "id": "5302122004",
    "district_id": "530212",
    "label": "Fatumnasi",
    "value": "Fatumnasi"
  },
  {
    "id": "5302122005",
    "district_id": "530212",
    "label": "Nuapin",
    "value": "Nuapin"
  },
  {
    "id": "5302122009",
    "district_id": "530212",
    "label": "Kuanoel",
    "value": "Kuanoel"
  },
  {
    "id": "5302122010",
    "district_id": "530212",
    "label": "Mutis",
    "value": "Mutis"
  },
  {
    "id": "5302132001",
    "district_id": "530213",
    "label": "Konbaki",
    "value": "Konbaki"
  },
  {
    "id": "5302132002",
    "district_id": "530213",
    "label": "Fatumnutu",
    "value": "Fatumnutu"
  },
  {
    "id": "5302132003",
    "district_id": "530213",
    "label": "Bijeli",
    "value": "Bijeli"
  },
  {
    "id": "5302132004",
    "district_id": "530213",
    "label": "Loli",
    "value": "Loli"
  },
  {
    "id": "5302132005",
    "district_id": "530213",
    "label": "Usapimnasi",
    "value": "Usapimnasi"
  },
  {
    "id": "5302132006",
    "district_id": "530213",
    "label": "Mnesatbubuk",
    "value": "Mnesatbubuk"
  },
  {
    "id": "5302132007",
    "district_id": "530213",
    "label": "Puna",
    "value": "Puna"
  },
  {
    "id": "5302132008",
    "district_id": "530213",
    "label": "Laob",
    "value": "Laob"
  },
  {
    "id": "5302132009",
    "district_id": "530213",
    "label": "Oelnunuh",
    "value": "Oelnunuh"
  },
  {
    "id": "5302132010",
    "district_id": "530213",
    "label": "Balu",
    "value": "Balu"
  },
  {
    "id": "5302132011",
    "district_id": "530213",
    "label": "Sainoni",
    "value": "Sainoni"
  },
  {
    "id": "5302142001",
    "district_id": "530214",
    "label": "Benlutu",
    "value": "Benlutu"
  },
  {
    "id": "5302142002",
    "district_id": "530214",
    "label": "Boentuka",
    "value": "Boentuka"
  },
  {
    "id": "5302142003",
    "district_id": "530214",
    "label": "Hane",
    "value": "Hane"
  },
  {
    "id": "5302142004",
    "district_id": "530214",
    "label": "Oebobo",
    "value": "Oebobo"
  },
  {
    "id": "5302142005",
    "district_id": "530214",
    "label": "Tupan",
    "value": "Tupan"
  },
  {
    "id": "5302142006",
    "district_id": "530214",
    "label": "Tuakole",
    "value": "Tuakole"
  },
  {
    "id": "5302142007",
    "district_id": "530214",
    "label": "Oehela",
    "value": "Oehela"
  },
  {
    "id": "5302152006",
    "district_id": "530215",
    "label": "Sabun",
    "value": "Sabun"
  },
  {
    "id": "5302152007",
    "district_id": "530215",
    "label": "Nano",
    "value": "Nano"
  },
  {
    "id": "5302152009",
    "district_id": "530215",
    "label": "Boking",
    "value": "Boking"
  },
  {
    "id": "5302152010",
    "district_id": "530215",
    "label": "Baus",
    "value": "Baus"
  },
  {
    "id": "5302152011",
    "district_id": "530215",
    "label": "Meusin",
    "value": "Meusin"
  },
  {
    "id": "5302152013",
    "district_id": "530215",
    "label": "Leonmeni",
    "value": "Leonmeni"
  },
  {
    "id": "5302152014",
    "district_id": "530215",
    "label": "Fatu Manufui",
    "value": "Fatu Manufui"
  },
  {
    "id": "5302162001",
    "district_id": "530216",
    "label": "Toianas",
    "value": "Toianas"
  },
  {
    "id": "5302162002",
    "district_id": "530216",
    "label": "Skinu",
    "value": "Skinu"
  },
  {
    "id": "5302162003",
    "district_id": "530216",
    "label": "Oeleu",
    "value": "Oeleu"
  },
  {
    "id": "5302162004",
    "district_id": "530216",
    "label": "Sanbet",
    "value": "Sanbet"
  },
  {
    "id": "5302162005",
    "district_id": "530216",
    "label": "Bokong",
    "value": "Bokong"
  },
  {
    "id": "5302162006",
    "district_id": "530216",
    "label": "Tuataum",
    "value": "Tuataum"
  },
  {
    "id": "5302162007",
    "district_id": "530216",
    "label": "Lobus",
    "value": "Lobus"
  },
  {
    "id": "5302162008",
    "district_id": "530216",
    "label": "Noeolin",
    "value": "Noeolin"
  },
  {
    "id": "5302162009",
    "district_id": "530216",
    "label": "Milli",
    "value": "Milli"
  },
  { "id": "5302172001", "district_id": "530217", "label": "OP", "value": "OP" },
  {
    "id": "5302172002",
    "district_id": "530217",
    "label": "Sahan",
    "value": "Sahan"
  },
  {
    "id": "5302172003",
    "district_id": "530217",
    "label": "Nenoat",
    "value": "Nenoat"
  },
  {
    "id": "5302172004",
    "district_id": "530217",
    "label": "Haineno",
    "value": "Haineno"
  },
  {
    "id": "5302172005",
    "district_id": "530217",
    "label": "Nunkolo",
    "value": "Nunkolo"
  },
  {
    "id": "5302172006",
    "district_id": "530217",
    "label": "Saenam",
    "value": "Saenam"
  },
  {
    "id": "5302172007",
    "district_id": "530217",
    "label": "Fat",
    "value": "Fat"
  },
  {
    "id": "5302172008",
    "district_id": "530217",
    "label": "Haumeni",
    "value": "Haumeni"
  },
  {
    "id": "5302172009",
    "district_id": "530217",
    "label": "Putun",
    "value": "Putun"
  },
  {
    "id": "5302182001",
    "district_id": "530218",
    "label": "Neke",
    "value": "Neke"
  },
  {
    "id": "5302182002",
    "district_id": "530218",
    "label": "Pene Utara",
    "value": "Pene Utara"
  },
  {
    "id": "5302182003",
    "district_id": "530218",
    "label": "Noenoni",
    "value": "Noenoni"
  },
  {
    "id": "5302182004",
    "district_id": "530218",
    "label": "Hoi",
    "value": "Hoi"
  },
  {
    "id": "5302182005",
    "district_id": "530218",
    "label": "Niki Niki Un",
    "value": "Niki Niki Un"
  },
  {
    "id": "5302182006",
    "district_id": "530218",
    "label": "Oenino",
    "value": "Oenino"
  },
  {
    "id": "5302182007",
    "district_id": "530218",
    "label": "Abi",
    "value": "Abi"
  },
  {
    "id": "5302192001",
    "district_id": "530219",
    "label": "Kolbano",
    "value": "Kolbano"
  },
  {
    "id": "5302192002",
    "district_id": "530219",
    "label": "Noesiu",
    "value": "Noesiu"
  },
  {
    "id": "5302192003",
    "district_id": "530219",
    "label": "Pene Selatan",
    "value": "Pene Selatan"
  },
  {
    "id": "5302192004",
    "district_id": "530219",
    "label": "Oetuke",
    "value": "Oetuke"
  },
  {
    "id": "5302192005",
    "district_id": "530219",
    "label": "Babuin",
    "value": "Babuin"
  },
  {
    "id": "5302192006",
    "district_id": "530219",
    "label": "Oeleu",
    "value": "Oeleu"
  },
  {
    "id": "5302192007",
    "district_id": "530219",
    "label": "Se'i",
    "value": "Se'i"
  },
  {
    "id": "5302192008",
    "district_id": "530219",
    "label": "Nununamat",
    "value": "Nununamat"
  },
  {
    "id": "5302192009",
    "district_id": "530219",
    "label": "Pana",
    "value": "Pana"
  },
  {
    "id": "5302192010",
    "district_id": "530219",
    "label": "Haunobenak",
    "value": "Haunobenak"
  },
  {
    "id": "5302192011",
    "district_id": "530219",
    "label": "Ofu",
    "value": "Ofu"
  },
  {
    "id": "5302192012",
    "district_id": "530219",
    "label": "Spaha",
    "value": "Spaha"
  },
  {
    "id": "5302202001",
    "district_id": "530220",
    "label": "Kot'olin",
    "value": "Kot'olin"
  },
  {
    "id": "5302202002",
    "district_id": "530220",
    "label": "Nunbena",
    "value": "Nunbena"
  },
  {
    "id": "5302202003",
    "district_id": "530220",
    "label": "Fatuat",
    "value": "Fatuat"
  },
  {
    "id": "5302202004",
    "district_id": "530220",
    "label": "Hoibeti",
    "value": "Hoibeti"
  },
  {
    "id": "5302202005",
    "district_id": "530220",
    "label": "Nualunat",
    "value": "Nualunat"
  },
  {
    "id": "5302202006",
    "district_id": "530220",
    "label": "Binenok",
    "value": "Binenok"
  },
  {
    "id": "5302202007",
    "district_id": "530220",
    "label": "Ponite",
    "value": "Ponite"
  },
  {
    "id": "5302202008",
    "district_id": "530220",
    "label": "O'obibi",
    "value": "O'obibi"
  },
  {
    "id": "5302212001",
    "district_id": "530221",
    "label": "Kualin",
    "value": "Kualin"
  },
  {
    "id": "5302212002",
    "district_id": "530221",
    "label": "Oni",
    "value": "Oni"
  },
  {
    "id": "5302212003",
    "district_id": "530221",
    "label": "Nunusunu",
    "value": "Nunusunu"
  },
  {
    "id": "5302212004",
    "district_id": "530221",
    "label": "Tuapakas",
    "value": "Tuapakas"
  },
  {
    "id": "5302212005",
    "district_id": "530221",
    "label": "Toineke",
    "value": "Toineke"
  },
  {
    "id": "5302212006",
    "district_id": "530221",
    "label": "Tuafanu",
    "value": "Tuafanu"
  },
  {
    "id": "5302212007",
    "district_id": "530221",
    "label": "Kiufatu",
    "value": "Kiufatu"
  },
  {
    "id": "5302212008",
    "district_id": "530221",
    "label": "Oemaman",
    "value": "Oemaman"
  },
  {
    "id": "5302222001",
    "district_id": "530222",
    "label": "Besana",
    "value": "Besana"
  },
  {
    "id": "5302222002",
    "district_id": "530222",
    "label": "Fatukoko",
    "value": "Fatukoko"
  },
  {
    "id": "5302222003",
    "district_id": "530222",
    "label": "Koa",
    "value": "Koa"
  },
  {
    "id": "5302222004",
    "district_id": "530222",
    "label": "Oel Uban",
    "value": "Oel Uban"
  },
  {
    "id": "5302222005",
    "district_id": "530222",
    "label": "Salbait",
    "value": "Salbait"
  },
  {
    "id": "5302232001",
    "district_id": "530223",
    "label": "Lotas",
    "value": "Lotas"
  },
  {
    "id": "5302232002",
    "district_id": "530223",
    "label": "Sabnala",
    "value": "Sabnala"
  },
  {
    "id": "5302232003",
    "district_id": "530223",
    "label": "Koloto",
    "value": "Koloto"
  },
  {
    "id": "5302232004",
    "district_id": "530223",
    "label": "Niti",
    "value": "Niti"
  },
  {
    "id": "5302232005",
    "district_id": "530223",
    "label": "Obaki",
    "value": "Obaki"
  },
  {
    "id": "5302232006",
    "district_id": "530223",
    "label": "Benehe",
    "value": "Benehe"
  },
  {
    "id": "5302242001",
    "district_id": "530224",
    "label": "Noebana",
    "value": "Noebana"
  },
  {
    "id": "5302242002",
    "district_id": "530224",
    "label": "Fatumnasi",
    "value": "Fatumnasi"
  },
  {
    "id": "5302242003",
    "district_id": "530224",
    "label": "Suni",
    "value": "Suni"
  },
  {
    "id": "5302242004",
    "district_id": "530224",
    "label": "Mella",
    "value": "Mella"
  },
  {
    "id": "5302242005",
    "district_id": "530224",
    "label": "Mnelapetu",
    "value": "Mnelapetu"
  },
  {
    "id": "5302252001",
    "district_id": "530225",
    "label": "Santian",
    "value": "Santian"
  },
  {
    "id": "5302252002",
    "district_id": "530225",
    "label": "Poli",
    "value": "Poli"
  },
  {
    "id": "5302252003",
    "district_id": "530225",
    "label": "Manufui",
    "value": "Manufui"
  },
  {
    "id": "5302252004",
    "district_id": "530225",
    "label": "Nenotes",
    "value": "Nenotes"
  },
  {
    "id": "5302252005",
    "district_id": "530225",
    "label": "Naifatu",
    "value": "Naifatu"
  },
  {
    "id": "5302262001",
    "district_id": "530226",
    "label": "Naip",
    "value": "Naip"
  },
  {
    "id": "5302262002",
    "district_id": "530226",
    "label": "Fatutnana",
    "value": "Fatutnana"
  },
  {
    "id": "5302262003",
    "district_id": "530226",
    "label": "Oepliki",
    "value": "Oepliki"
  },
  {
    "id": "5302262004",
    "district_id": "530226",
    "label": "Oe'ekam",
    "value": "Oe'ekam"
  },
  {
    "id": "5302262005",
    "district_id": "530226",
    "label": "Teas",
    "value": "Teas"
  },
  {
    "id": "5302262006",
    "district_id": "530226",
    "label": "Oebaki",
    "value": "Oebaki"
  },
  {
    "id": "5302262007",
    "district_id": "530226",
    "label": "Eno Nabuasa",
    "value": "Eno Nabuasa"
  },
  {
    "id": "5302272001",
    "district_id": "530227",
    "label": "Tetaf",
    "value": "Tetaf"
  },
  {
    "id": "5302272002",
    "district_id": "530227",
    "label": "Naukae",
    "value": "Naukae"
  },
  {
    "id": "5302272003",
    "district_id": "530227",
    "label": "Supul",
    "value": "Supul"
  },
  {
    "id": "5302272004",
    "district_id": "530227",
    "label": "Lakat",
    "value": "Lakat"
  },
  {
    "id": "5302272005",
    "district_id": "530227",
    "label": "Enoneontes",
    "value": "Enoneontes"
  },
  {
    "id": "5302272006",
    "district_id": "530227",
    "label": "O'of",
    "value": "O'of"
  },
  {
    "id": "5302272007",
    "district_id": "530227",
    "label": "Tubmonas",
    "value": "Tubmonas"
  },
  {
    "id": "5302272008",
    "district_id": "530227",
    "label": "Oe Oe",
    "value": "Oe Oe"
  },
  {
    "id": "5302282001",
    "district_id": "530228",
    "label": "Sillu",
    "value": "Sillu"
  },
  {
    "id": "5302282002",
    "district_id": "530228",
    "label": "Nunukhniti",
    "value": "Nunukhniti"
  },
  {
    "id": "5302282003",
    "district_id": "530228",
    "label": "Kaeneno",
    "value": "Kaeneno"
  },
  {
    "id": "5302282004",
    "district_id": "530228",
    "label": "Oeleon",
    "value": "Oeleon"
  },
  {
    "id": "5302282005",
    "district_id": "530228",
    "label": "Tunis",
    "value": "Tunis"
  },
  {
    "id": "5302282006",
    "district_id": "530228",
    "label": "Bileon",
    "value": "Bileon"
  },
  {
    "id": "5302282007",
    "district_id": "530228",
    "label": "Besle'u",
    "value": "Besle'u"
  },
  {
    "id": "5302292001",
    "district_id": "530229",
    "label": "Fatukopa",
    "value": "Fatukopa"
  },
  {
    "id": "5302292002",
    "district_id": "530229",
    "label": "Besnam",
    "value": "Besnam"
  },
  {
    "id": "5302292003",
    "district_id": "530229",
    "label": "Taebone",
    "value": "Taebone"
  },
  {
    "id": "5302292004",
    "district_id": "530229",
    "label": "Nunfutu",
    "value": "Nunfutu"
  },
  {
    "id": "5302292005",
    "district_id": "530229",
    "label": "Kiki",
    "value": "Kiki"
  },
  {
    "id": "5302292006",
    "district_id": "530229",
    "label": "Ello",
    "value": "Ello"
  },
  {
    "id": "5302292007",
    "district_id": "530229",
    "label": "Nifulinah",
    "value": "Nifulinah"
  },
  {
    "id": "5302302001",
    "district_id": "530230",
    "label": "Oelbubuk",
    "value": "Oelbubuk"
  },
  {
    "id": "5302302002",
    "district_id": "530230",
    "label": "Nekemunifeto",
    "value": "Nekemunifeto"
  },
  {
    "id": "5302302003",
    "district_id": "530230",
    "label": "Binaus",
    "value": "Binaus"
  },
  {
    "id": "5302302004",
    "district_id": "530230",
    "label": "Oel'Ekam",
    "value": "Oel'Ekam"
  },
  {
    "id": "5302302005",
    "district_id": "530230",
    "label": "Kualeu",
    "value": "Kualeu"
  },
  {
    "id": "5302302006",
    "district_id": "530230",
    "label": "Pika",
    "value": "Pika"
  },
  {
    "id": "5302312001",
    "district_id": "530231",
    "label": "Tobu",
    "value": "Tobu"
  },
  {
    "id": "5302312002",
    "district_id": "530231",
    "label": "Tutem",
    "value": "Tutem"
  },
  {
    "id": "5302312003",
    "district_id": "530231",
    "label": "Tune",
    "value": "Tune"
  },
  {
    "id": "5302312004",
    "district_id": "530231",
    "label": "Bonleu",
    "value": "Bonleu"
  },
  {
    "id": "5302312005",
    "district_id": "530231",
    "label": "Pubasu",
    "value": "Pubasu"
  },
  {
    "id": "5302312006",
    "district_id": "530231",
    "label": "Huetalan",
    "value": "Huetalan"
  },
  {
    "id": "5302312007",
    "district_id": "530231",
    "label": "Saubalan",
    "value": "Saubalan"
  },
  {
    "id": "5302312008",
    "district_id": "530231",
    "label": "Bestobe",
    "value": "Bestobe"
  },
  {
    "id": "5302322001",
    "district_id": "530232",
    "label": "Lill ana",
    "value": "Lill ana"
  },
  {
    "id": "5302322002",
    "district_id": "530232",
    "label": "Nunbena",
    "value": "Nunbena"
  },
  {
    "id": "5302322003",
    "district_id": "530232",
    "label": "Noebesi",
    "value": "Noebesi"
  },
  {
    "id": "5302322004",
    "district_id": "530232",
    "label": "Taneotob",
    "value": "Taneotob"
  },
  {
    "id": "5302322005",
    "district_id": "530232",
    "label": "Feto Mone",
    "value": "Feto Mone"
  },
  {
    "id": "5302322006",
    "district_id": "530232",
    "label": "Tunbes",
    "value": "Tunbes"
  },
  {
    "id": "5303011006",
    "district_id": "530301",
    "label": "Bitefa",
    "value": "Bitefa"
  },
  {
    "id": "5303011020",
    "district_id": "530301",
    "label": "Oesena",
    "value": "Oesena"
  },
  {
    "id": "5303012005",
    "district_id": "530301",
    "label": "Bokon",
    "value": "Bokon"
  },
  {
    "id": "5303012007",
    "district_id": "530301",
    "label": "Keanbaun",
    "value": "Keanbaun"
  },
  {
    "id": "5303012018",
    "district_id": "530301",
    "label": "Amol",
    "value": "Amol"
  },
  {
    "id": "5303012019",
    "district_id": "530301",
    "label": "Fatusene",
    "value": "Fatusene"
  },
  {
    "id": "5303012021",
    "district_id": "530301",
    "label": "Jak",
    "value": "Jak"
  },
  {
    "id": "5303012022",
    "district_id": "530301",
    "label": "Tuntun",
    "value": "Tuntun"
  },
  {
    "id": "5303012023",
    "district_id": "530301",
    "label": "Tunoe",
    "value": "Tunoe"
  },
  {
    "id": "5303012024",
    "district_id": "530301",
    "label": "Femnasi",
    "value": "Femnasi"
  },
  {
    "id": "5303012025",
    "district_id": "530301",
    "label": "Taekas",
    "value": "Taekas"
  },
  {
    "id": "5303021026",
    "district_id": "530302",
    "label": "Eban",
    "value": "Eban"
  },
  {
    "id": "5303021027",
    "district_id": "530302",
    "label": "Sallu",
    "value": "Sallu"
  },
  {
    "id": "5303022001",
    "district_id": "530302",
    "label": "Noepesu",
    "value": "Noepesu"
  },
  {
    "id": "5303022002",
    "district_id": "530302",
    "label": "Fatuneno",
    "value": "Fatuneno"
  },
  {
    "id": "5303022003",
    "district_id": "530302",
    "label": "Suanae",
    "value": "Suanae"
  },
  {
    "id": "5303022004",
    "district_id": "530302",
    "label": "Lemon",
    "value": "Lemon"
  },
  {
    "id": "5303022005",
    "district_id": "530302",
    "label": "Fatunisuan",
    "value": "Fatunisuan"
  },
  {
    "id": "5303022006",
    "district_id": "530302",
    "label": "Haulasi",
    "value": "Haulasi"
  },
  {
    "id": "5303022018",
    "district_id": "530302",
    "label": "Neotoko",
    "value": "Neotoko"
  },
  {
    "id": "5303022019",
    "district_id": "530302",
    "label": "Fatutasu",
    "value": "Fatutasu"
  },
  {
    "id": "5303022020",
    "district_id": "530302",
    "label": "Manusasi",
    "value": "Manusasi"
  },
  {
    "id": "5303022025",
    "district_id": "530302",
    "label": "Saenam",
    "value": "Saenam"
  },
  {
    "id": "5303022028",
    "district_id": "530302",
    "label": "Sa'tab",
    "value": "Sa'tab"
  },
  {
    "id": "5303031003",
    "district_id": "530303",
    "label": "Up Faon",
    "value": "Up Faon"
  },
  {
    "id": "5303031011",
    "district_id": "530303",
    "label": "Supun",
    "value": "Supun"
  },
  {
    "id": "5303032001",
    "district_id": "530303",
    "label": "Pantae",
    "value": "Pantae"
  },
  {
    "id": "5303032002",
    "district_id": "530303",
    "label": "Oenaem",
    "value": "Oenaem"
  },
  {
    "id": "5303032007",
    "district_id": "530303",
    "label": "Tautpah",
    "value": "Tautpah"
  },
  {
    "id": "5303032009",
    "district_id": "530303",
    "label": "Tokbesi",
    "value": "Tokbesi"
  },
  {
    "id": "5303032010",
    "district_id": "530303",
    "label": "Sainiup",
    "value": "Sainiup"
  },
  {
    "id": "5303032012",
    "district_id": "530303",
    "label": "Tunbaen",
    "value": "Tunbaen"
  },
  {
    "id": "5303041011",
    "district_id": "530304",
    "label": "Oenak",
    "value": "Oenak"
  },
  {
    "id": "5303041012",
    "district_id": "530304",
    "label": "Nifuboke",
    "value": "Nifuboke"
  },
  {
    "id": "5303041013",
    "district_id": "530304",
    "label": "Noemuti",
    "value": "Noemuti"
  },
  {
    "id": "5303041014",
    "district_id": "530304",
    "label": "Fatumuti",
    "value": "Fatumuti"
  },
  {
    "id": "5303041015",
    "district_id": "530304",
    "label": "Kiuola",
    "value": "Kiuola"
  },
  {
    "id": "5303042001",
    "district_id": "530304",
    "label": "Bijeli",
    "value": "Bijeli"
  },
  {
    "id": "5303042002",
    "district_id": "530304",
    "label": "Noebaun",
    "value": "Noebaun"
  },
  {
    "id": "5303042003",
    "district_id": "530304",
    "label": "Seo",
    "value": "Seo"
  },
  {
    "id": "5303042004",
    "district_id": "530304",
    "label": "Popnam",
    "value": "Popnam"
  },
  {
    "id": "5303042009",
    "district_id": "530304",
    "label": "Nibaaf",
    "value": "Nibaaf"
  },
  {
    "id": "5303042010",
    "district_id": "530304",
    "label": "Banfanu",
    "value": "Banfanu"
  },
  {
    "id": "5303042016",
    "district_id": "530304",
    "label": "Oeperigi",
    "value": "Oeperigi"
  },
  {
    "id": "5303051001",
    "district_id": "530305",
    "label": "Kefamenanu Utara",
    "value": "Kefamenanu Utara"
  },
  {
    "id": "5303051002",
    "district_id": "530305",
    "label": "Aplasi",
    "value": "Aplasi"
  },
  {
    "id": "5303051003",
    "district_id": "530305",
    "label": "Kefamenanu Tengah",
    "value": "Kefamenanu Tengah"
  },
  {
    "id": "5303051004",
    "district_id": "530305",
    "label": "Bansone",
    "value": "Bansone"
  },
  {
    "id": "5303051005",
    "district_id": "530305",
    "label": "Benpasi",
    "value": "Benpasi"
  },
  {
    "id": "5303051006",
    "district_id": "530305",
    "label": "Kefamenanu Selatan",
    "value": "Kefamenanu Selatan"
  },
  {
    "id": "5303051007",
    "district_id": "530305",
    "label": "Tubuhue",
    "value": "Tubuhue"
  },
  {
    "id": "5303051008",
    "district_id": "530305",
    "label": "Maubeli",
    "value": "Maubeli"
  },
  {
    "id": "5303051009",
    "district_id": "530305",
    "label": "Sasi",
    "value": "Sasi"
  },
  {
    "id": "5303061001",
    "district_id": "530306",
    "label": "Boronubaen",
    "value": "Boronubaen"
  },
  {
    "id": "5303061015",
    "district_id": "530306",
    "label": "Boronubaen Timur",
    "value": "Boronubaen Timur"
  },
  {
    "id": "5303062002",
    "district_id": "530306",
    "label": "Hautaes",
    "value": "Hautaes"
  },
  {
    "id": "5303062003",
    "district_id": "530306",
    "label": "Tualena",
    "value": "Tualena"
  },
  {
    "id": "5303062004",
    "district_id": "530306",
    "label": "Biloe",
    "value": "Biloe"
  },
  {
    "id": "5303062005",
    "district_id": "530306",
    "label": "Taunbaen",
    "value": "Taunbaen"
  },
  {
    "id": "5303062006",
    "district_id": "530306",
    "label": "Sapaen",
    "value": "Sapaen"
  },
  {
    "id": "5303062012",
    "district_id": "530306",
    "label": "Lokomea",
    "value": "Lokomea"
  },
  {
    "id": "5303062013",
    "district_id": "530306",
    "label": "Taunbaen Timur",
    "value": "Taunbaen Timur"
  },
  {
    "id": "5303062014",
    "district_id": "530306",
    "label": "Hauteas Barat",
    "value": "Hauteas Barat"
  },
  {
    "id": "5303071007",
    "district_id": "530307",
    "label": "Ponu",
    "value": "Ponu"
  },
  {
    "id": "5303072001",
    "district_id": "530307",
    "label": "Motadik",
    "value": "Motadik"
  },
  {
    "id": "5303072002",
    "district_id": "530307",
    "label": "Nonotbatan",
    "value": "Nonotbatan"
  },
  {
    "id": "5303072003",
    "district_id": "530307",
    "label": "Sifaniha",
    "value": "Sifaniha"
  },
  {
    "id": "5303072004",
    "district_id": "530307",
    "label": "Maukabatan",
    "value": "Maukabatan"
  },
  {
    "id": "5303072005",
    "district_id": "530307",
    "label": "Kotafoun",
    "value": "Kotafoun"
  },
  {
    "id": "5303072006",
    "district_id": "530307",
    "label": "Tuamese",
    "value": "Tuamese"
  },
  {
    "id": "5303072008",
    "district_id": "530307",
    "label": "Oemanu",
    "value": "Oemanu"
  },
  {
    "id": "5303072009",
    "district_id": "530307",
    "label": "Nifutasi",
    "value": "Nifutasi"
  },
  {
    "id": "5303081015",
    "district_id": "530308",
    "label": "Ainiut",
    "value": "Ainiut"
  },
  {
    "id": "5303081016",
    "district_id": "530308",
    "label": "Nunmafo",
    "value": "Nunmafo"
  },
  {
    "id": "5303081018",
    "district_id": "530308",
    "label": "Manunain A",
    "value": "Manunain A"
  },
  {
    "id": "5303081024",
    "district_id": "530308",
    "label": "Fatoin",
    "value": "Fatoin"
  },
  {
    "id": "5303081029",
    "district_id": "530308",
    "label": "Bitauni",
    "value": "Bitauni"
  },
  {
    "id": "5303082005",
    "district_id": "530308",
    "label": "Nansean",
    "value": "Nansean"
  },
  {
    "id": "5303082006",
    "district_id": "530308",
    "label": "Susulaku",
    "value": "Susulaku"
  },
  {
    "id": "5303082007",
    "district_id": "530308",
    "label": "Loeram",
    "value": "Loeram"
  },
  {
    "id": "5303082008",
    "district_id": "530308",
    "label": "Manunain B",
    "value": "Manunain B"
  },
  {
    "id": "5303082010",
    "district_id": "530308",
    "label": "Tapenpah",
    "value": "Tapenpah"
  },
  {
    "id": "5303082011",
    "district_id": "530308",
    "label": "Sekon",
    "value": "Sekon"
  },
  {
    "id": "5303082017",
    "district_id": "530308",
    "label": "Oenbit",
    "value": "Oenbit"
  },
  {
    "id": "5303082022",
    "district_id": "530308",
    "label": "Susulaku B",
    "value": "Susulaku B"
  },
  {
    "id": "5303082025",
    "district_id": "530308",
    "label": "Keun",
    "value": "Keun"
  },
  {
    "id": "5303082026",
    "district_id": "530308",
    "label": "Fatu'Ana",
    "value": "Fatu'Ana"
  },
  {
    "id": "5303082027",
    "district_id": "530308",
    "label": "Botof",
    "value": "Botof"
  },
  {
    "id": "5303082028",
    "district_id": "530308",
    "label": "Nansean Timur",
    "value": "Nansean Timur"
  },
  {
    "id": "5303091010",
    "district_id": "530309",
    "label": "Humusu C",
    "value": "Humusu C"
  },
  {
    "id": "5303092006",
    "district_id": "530309",
    "label": "Fatumtasa",
    "value": "Fatumtasa"
  },
  {
    "id": "5303092007",
    "district_id": "530309",
    "label": "Oesoko",
    "value": "Oesoko"
  },
  {
    "id": "5303092008",
    "district_id": "530309",
    "label": "Humusu Sainiup",
    "value": "Humusu Sainiup"
  },
  {
    "id": "5303092009",
    "district_id": "530309",
    "label": "Humusu Oekolo",
    "value": "Humusu Oekolo"
  },
  {
    "id": "5303102001",
    "district_id": "530310",
    "label": "Naob",
    "value": "Naob"
  },
  {
    "id": "5303102002",
    "district_id": "530310",
    "label": "Haekto",
    "value": "Haekto"
  },
  {
    "id": "5303102003",
    "district_id": "530310",
    "label": "Manikin",
    "value": "Manikin"
  },
  {
    "id": "5303102004",
    "district_id": "530310",
    "label": "Kuaken",
    "value": "Kuaken"
  },
  {
    "id": "5303112001",
    "district_id": "530311",
    "label": "Tuabatan",
    "value": "Tuabatan"
  },
  {
    "id": "5303112002",
    "district_id": "530311",
    "label": "Akomi",
    "value": "Akomi"
  },
  {
    "id": "5303112003",
    "district_id": "530311",
    "label": "Bijaepasu",
    "value": "Bijaepasu"
  },
  {
    "id": "5303112004",
    "district_id": "530311",
    "label": "Neonasi",
    "value": "Neonasi"
  },
  {
    "id": "5303112005",
    "district_id": "530311",
    "label": "Nian",
    "value": "Nian"
  },
  {
    "id": "5303112006",
    "district_id": "530311",
    "label": "Tuabatan Barat",
    "value": "Tuabatan Barat"
  },
  {
    "id": "5303122001",
    "district_id": "530312",
    "label": "Oelneke",
    "value": "Oelneke"
  },
  {
    "id": "5303122002",
    "district_id": "530312",
    "label": "Oetulu",
    "value": "Oetulu"
  },
  {
    "id": "5303122003",
    "district_id": "530312",
    "label": "Oeolo",
    "value": "Oeolo"
  },
  {
    "id": "5303122004",
    "district_id": "530312",
    "label": "Ainan",
    "value": "Ainan"
  },
  {
    "id": "5303122005",
    "district_id": "530312",
    "label": "Bisafe",
    "value": "Bisafe"
  },
  {
    "id": "5303122006",
    "district_id": "530312",
    "label": "Batnes",
    "value": "Batnes"
  },
  {
    "id": "5303132001",
    "district_id": "530313",
    "label": "Tasinifu",
    "value": "Tasinifu"
  },
  {
    "id": "5303132002",
    "district_id": "530313",
    "label": "Naekake A",
    "value": "Naekake A"
  },
  {
    "id": "5303132003",
    "district_id": "530313",
    "label": "Naekake B",
    "value": "Naekake B"
  },
  {
    "id": "5303132004",
    "district_id": "530313",
    "label": "Noelelo",
    "value": "Noelelo"
  },
  {
    "id": "5303141001",
    "district_id": "530314",
    "label": "Oelami",
    "value": "Oelami"
  },
  {
    "id": "5303141006",
    "district_id": "530314",
    "label": "Tublopo",
    "value": "Tublopo"
  },
  {
    "id": "5303142002",
    "district_id": "530314",
    "label": "Kiusili",
    "value": "Kiusili"
  },
  {
    "id": "5303142003",
    "district_id": "530314",
    "label": "Naiola",
    "value": "Naiola"
  },
  {
    "id": "5303142004",
    "district_id": "530314",
    "label": "Maurisu",
    "value": "Maurisu"
  },
  {
    "id": "5303142005",
    "district_id": "530314",
    "label": "Oetalus",
    "value": "Oetalus"
  },
  {
    "id": "5303142007",
    "district_id": "530314",
    "label": "Maurisu Utara",
    "value": "Maurisu Utara"
  },
  {
    "id": "5303142008",
    "district_id": "530314",
    "label": "Maurisu Selatan",
    "value": "Maurisu Selatan"
  },
  {
    "id": "5303142009",
    "district_id": "530314",
    "label": "Maurisu Tengah",
    "value": "Maurisu Tengah"
  },
  {
    "id": "5303142010",
    "district_id": "530314",
    "label": "Naiola Timur",
    "value": "Naiola Timur"
  },
  {
    "id": "5303152001",
    "district_id": "530315",
    "label": "Buk",
    "value": "Buk"
  },
  {
    "id": "5303152002",
    "district_id": "530315",
    "label": "Oenenu",
    "value": "Oenenu"
  },
  {
    "id": "5303152003",
    "district_id": "530315",
    "label": "Nimasi",
    "value": "Nimasi"
  },
  {
    "id": "5303152004",
    "district_id": "530315",
    "label": "Oelbonak",
    "value": "Oelbonak"
  },
  {
    "id": "5303152005",
    "district_id": "530315",
    "label": "Kuenak",
    "value": "Kuenak"
  },
  {
    "id": "5303152006",
    "district_id": "530315",
    "label": "Oenino",
    "value": "Oenino"
  },
  {
    "id": "5303152007",
    "district_id": "530315",
    "label": "Oenenu Utara",
    "value": "Oenenu Utara"
  },
  {
    "id": "5303152008",
    "district_id": "530315",
    "label": "Oenenu Selatan",
    "value": "Oenenu Selatan"
  },
  {
    "id": "5303152009",
    "district_id": "530315",
    "label": "Sono",
    "value": "Sono"
  },
  {
    "id": "5303162001",
    "district_id": "530316",
    "label": "Inbate",
    "value": "Inbate"
  },
  {
    "id": "5303162002",
    "district_id": "530316",
    "label": "Sunkaen",
    "value": "Sunkaen"
  },
  {
    "id": "5303162003",
    "district_id": "530316",
    "label": "Nainaban",
    "value": "Nainaban"
  },
  {
    "id": "5303162004",
    "district_id": "530316",
    "label": "Haumeni Ana",
    "value": "Haumeni Ana"
  },
  {
    "id": "5303162005",
    "district_id": "530316",
    "label": "Nilulat",
    "value": "Nilulat"
  },
  {
    "id": "5303162006",
    "district_id": "530316",
    "label": "Tubu",
    "value": "Tubu"
  },
  {
    "id": "5303172001",
    "district_id": "530317",
    "label": "Faenake",
    "value": "Faenake"
  },
  {
    "id": "5303172002",
    "district_id": "530317",
    "label": "Banain A",
    "value": "Banain A"
  },
  {
    "id": "5303172003",
    "district_id": "530317",
    "label": "Banain B",
    "value": "Banain B"
  },
  {
    "id": "5303172004",
    "district_id": "530317",
    "label": "Banain C",
    "value": "Banain C"
  },
  {
    "id": "5303172005",
    "district_id": "530317",
    "label": "Sainoni",
    "value": "Sainoni"
  },
  {
    "id": "5303172006",
    "district_id": "530317",
    "label": "Tes",
    "value": "Tes"
  },
  {
    "id": "5303172007",
    "district_id": "530317",
    "label": "Napan",
    "value": "Napan"
  },
  {
    "id": "5303172008",
    "district_id": "530317",
    "label": "Haumeni",
    "value": "Haumeni"
  },
  {
    "id": "5303172009",
    "district_id": "530317",
    "label": "Baas",
    "value": "Baas"
  },
  {
    "id": "5303182001",
    "district_id": "530318",
    "label": "Manamas",
    "value": "Manamas"
  },
  {
    "id": "5303182002",
    "district_id": "530318",
    "label": "Benus",
    "value": "Benus"
  },
  {
    "id": "5303182003",
    "district_id": "530318",
    "label": "Bakitolas",
    "value": "Bakitolas"
  },
  {
    "id": "5303182004",
    "district_id": "530318",
    "label": "Sunsea",
    "value": "Sunsea"
  },
  {
    "id": "5303192001",
    "district_id": "530319",
    "label": "Fafinesu A",
    "value": "Fafinesu A"
  },
  {
    "id": "5303192002",
    "district_id": "530319",
    "label": "Fafinesu B",
    "value": "Fafinesu B"
  },
  {
    "id": "5303192003",
    "district_id": "530319",
    "label": "Fafinesu C",
    "value": "Fafinesu C"
  },
  {
    "id": "5303192004",
    "district_id": "530319",
    "label": "Oenain",
    "value": "Oenain"
  },
  {
    "id": "5303192005",
    "district_id": "530319",
    "label": "Baunuan",
    "value": "Baunuan"
  },
  {
    "id": "5303192006",
    "district_id": "530319",
    "label": "Fafinesu",
    "value": "Fafinesu"
  },
  {
    "id": "5303201006",
    "district_id": "530320",
    "label": "Atmen",
    "value": "Atmen"
  },
  {
    "id": "5303202001",
    "district_id": "530320",
    "label": "Subun",
    "value": "Subun"
  },
  {
    "id": "5303202002",
    "district_id": "530320",
    "label": "Usapinonot",
    "value": "Usapinonot"
  },
  {
    "id": "5303202003",
    "district_id": "530320",
    "label": "Lapeom",
    "value": "Lapeom"
  },
  {
    "id": "5303202004",
    "district_id": "530320",
    "label": "Letneo",
    "value": "Letneo"
  },
  {
    "id": "5303202005",
    "district_id": "530320",
    "label": "Bannae",
    "value": "Bannae"
  },
  {
    "id": "5303202007",
    "district_id": "530320",
    "label": "Atmen",
    "value": "Atmen"
  },
  {
    "id": "5303202008",
    "district_id": "530320",
    "label": "Letneo Selatan",
    "value": "Letneo Selatan"
  },
  {
    "id": "5303202009",
    "district_id": "530320",
    "label": "Oabikase",
    "value": "Oabikase"
  },
  {
    "id": "5303202010",
    "district_id": "530320",
    "label": "Nifunenas",
    "value": "Nifunenas"
  },
  {
    "id": "5303202011",
    "district_id": "530320",
    "label": "Subun Bestobe",
    "value": "Subun Bestobe"
  },
  {
    "id": "5303202012",
    "district_id": "530320",
    "label": "Subun Tualele",
    "value": "Subun Tualele"
  },
  {
    "id": "5303211004",
    "district_id": "530321",
    "label": "Maubesi",
    "value": "Maubesi"
  },
  {
    "id": "5303212001",
    "district_id": "530321",
    "label": "Lanaus",
    "value": "Lanaus"
  },
  {
    "id": "5303212002",
    "district_id": "530321",
    "label": "Letmafo",
    "value": "Letmafo"
  },
  {
    "id": "5303212003",
    "district_id": "530321",
    "label": "Tainsala",
    "value": "Tainsala"
  },
  {
    "id": "5303212005",
    "district_id": "530321",
    "label": "Sone",
    "value": "Sone"
  },
  {
    "id": "5303212006",
    "district_id": "530321",
    "label": "Letmafo Timur",
    "value": "Letmafo Timur"
  },
  {
    "id": "5303212007",
    "district_id": "530321",
    "label": "Oehalo",
    "value": "Oehalo"
  },
  {
    "id": "5303222001",
    "district_id": "530322",
    "label": "Teba",
    "value": "Teba"
  },
  {
    "id": "5303222002",
    "district_id": "530322",
    "label": "Oerinbesi",
    "value": "Oerinbesi"
  },
  {
    "id": "5303222003",
    "district_id": "530322",
    "label": "Oekopa",
    "value": "Oekopa"
  },
  {
    "id": "5303222004",
    "district_id": "530322",
    "label": "T'Eba Timur",
    "value": "T'Eba Timur"
  },
  {
    "id": "5303232001",
    "district_id": "530323",
    "label": "Tunbes",
    "value": "Tunbes"
  },
  {
    "id": "5303232002",
    "district_id": "530323",
    "label": "Luniup",
    "value": "Luniup"
  },
  {
    "id": "5303232003",
    "district_id": "530323",
    "label": "Matabesi",
    "value": "Matabesi"
  },
  {
    "id": "5303232004",
    "district_id": "530323",
    "label": "Oepuah",
    "value": "Oepuah"
  },
  {
    "id": "5303232005",
    "district_id": "530323",
    "label": "Kaubele",
    "value": "Kaubele"
  },
  {
    "id": "5303232006",
    "district_id": "530323",
    "label": "Oepuah Utara",
    "value": "Oepuah Utara"
  },
  {
    "id": "5303232007",
    "district_id": "530323",
    "label": "Oepuah Selatan",
    "value": "Oepuah Selatan"
  },
  {
    "id": "5303242001",
    "district_id": "530324",
    "label": "Naku",
    "value": "Naku"
  },
  {
    "id": "5303242002",
    "district_id": "530324",
    "label": "Birunatun",
    "value": "Birunatun"
  },
  {
    "id": "5303242003",
    "district_id": "530324",
    "label": "Makun",
    "value": "Makun"
  },
  {
    "id": "5303242004",
    "district_id": "530324",
    "label": "Manumean",
    "value": "Manumean"
  },
  {
    "id": "5303242005",
    "district_id": "530324",
    "label": "Kuluan",
    "value": "Kuluan"
  },
  {
    "id": "5304012001",
    "district_id": "530401",
    "label": "Kewar",
    "value": "Kewar"
  },
  {
    "id": "5304012002",
    "district_id": "530401",
    "label": "Fulur",
    "value": "Fulur"
  },
  {
    "id": "5304012004",
    "district_id": "530401",
    "label": "Duarato",
    "value": "Duarato"
  },
  {
    "id": "5304012005",
    "district_id": "530401",
    "label": "Makir",
    "value": "Makir"
  },
  {
    "id": "5304012006",
    "district_id": "530401",
    "label": "Lamaksenulu",
    "value": "Lamaksenulu"
  },
  {
    "id": "5304012008",
    "district_id": "530401",
    "label": "Dirun",
    "value": "Dirun"
  },
  {
    "id": "5304012009",
    "district_id": "530401",
    "label": "Leowalu",
    "value": "Leowalu"
  },
  {
    "id": "5304012014",
    "district_id": "530401",
    "label": "Maudemu",
    "value": "Maudemu"
  },
  {
    "id": "5304012015",
    "district_id": "530401",
    "label": "Mahuitas",
    "value": "Mahuitas"
  },
  {
    "id": "5304022001",
    "district_id": "530402",
    "label": "Silawan",
    "value": "Silawan"
  },
  {
    "id": "5304022002",
    "district_id": "530402",
    "label": "Tulakadi",
    "value": "Tulakadi"
  },
  {
    "id": "5304022003",
    "district_id": "530402",
    "label": "Sadi",
    "value": "Sadi"
  },
  {
    "id": "5304022004",
    "district_id": "530402",
    "label": "Umaklaran",
    "value": "Umaklaran"
  },
  {
    "id": "5304022005",
    "district_id": "530402",
    "label": "Manleten",
    "value": "Manleten"
  },
  {
    "id": "5304022006",
    "district_id": "530402",
    "label": "Fatuba'a",
    "value": "Fatuba'a"
  },
  {
    "id": "5304022007",
    "district_id": "530402",
    "label": "Dafala",
    "value": "Dafala"
  },
  {
    "id": "5304022008",
    "district_id": "530402",
    "label": "Takirin",
    "value": "Takirin"
  },
  {
    "id": "5304022009",
    "district_id": "530402",
    "label": "Bauho",
    "value": "Bauho"
  },
  {
    "id": "5304022010",
    "district_id": "530402",
    "label": "Sarabau",
    "value": "Sarabau"
  },
  {
    "id": "5304022018",
    "district_id": "530402",
    "label": "Tialai",
    "value": "Tialai"
  },
  {
    "id": "5304022019",
    "district_id": "530402",
    "label": "Halimodok",
    "value": "Halimodok"
  },
  {
    "id": "5304032001",
    "district_id": "530403",
    "label": "Asumanu",
    "value": "Asumanu"
  },
  {
    "id": "5304032002",
    "district_id": "530403",
    "label": "Tohe",
    "value": "Tohe"
  },
  {
    "id": "5304032003",
    "district_id": "530403",
    "label": "Maumutin",
    "value": "Maumutin"
  },
  {
    "id": "5304032004",
    "district_id": "530403",
    "label": "Raifatus",
    "value": "Raifatus"
  },
  {
    "id": "5304032005",
    "district_id": "530403",
    "label": "Aitoun",
    "value": "Aitoun"
  },
  {
    "id": "5304032006",
    "district_id": "530403",
    "label": "Tohe Leten",
    "value": "Tohe Leten"
  },
  {
    "id": "5304042001",
    "district_id": "530404",
    "label": "Tukuneno",
    "value": "Tukuneno"
  },
  {
    "id": "5304042002",
    "district_id": "530404",
    "label": "Naekasa",
    "value": "Naekasa"
  },
  {
    "id": "5304042003",
    "district_id": "530404",
    "label": "Lookeu",
    "value": "Lookeu"
  },
  {
    "id": "5304042004",
    "district_id": "530404",
    "label": "Derokfaturene",
    "value": "Derokfaturene"
  },
  {
    "id": "5304042005",
    "district_id": "530404",
    "label": "Bakustulama",
    "value": "Bakustulama"
  },
  {
    "id": "5304042006",
    "district_id": "530404",
    "label": "Rinbesihat",
    "value": "Rinbesihat"
  },
  {
    "id": "5304042007",
    "district_id": "530404",
    "label": "Naitimu",
    "value": "Naitimu"
  },
  {
    "id": "5304042009",
    "district_id": "530404",
    "label": "Lawalutolus",
    "value": "Lawalutolus"
  },
  {
    "id": "5304052001",
    "district_id": "530405",
    "label": "Fatuketi",
    "value": "Fatuketi"
  },
  {
    "id": "5304052002",
    "district_id": "530405",
    "label": "Dualaus",
    "value": "Dualaus"
  },
  {
    "id": "5304052003",
    "district_id": "530405",
    "label": "Jenilu",
    "value": "Jenilu"
  },
  {
    "id": "5304052004",
    "district_id": "530405",
    "label": "Kenebibi",
    "value": "Kenebibi"
  },
  {
    "id": "5304052005",
    "district_id": "530405",
    "label": "Leosama",
    "value": "Leosama"
  },
  {
    "id": "5304052006",
    "district_id": "530405",
    "label": "Kabuna",
    "value": "Kabuna"
  },
  {
    "id": "5304121001",
    "district_id": "530412",
    "label": "Kota Atambua",
    "value": "Kota Atambua"
  },
  {
    "id": "5304121002",
    "district_id": "530412",
    "label": "Tenukiik",
    "value": "Tenukiik"
  },
  {
    "id": "5304121003",
    "district_id": "530412",
    "label": "Manumutin",
    "value": "Manumutin"
  },
  {
    "id": "5304121010",
    "district_id": "530412",
    "label": "Fatubenao",
    "value": "Fatubenao"
  },
  {
    "id": "5304132001",
    "district_id": "530413",
    "label": "Mandeu Raimanus",
    "value": "Mandeu Raimanus"
  },
  {
    "id": "5304132002",
    "district_id": "530413",
    "label": "Renrua",
    "value": "Renrua"
  },
  {
    "id": "5304132003",
    "district_id": "530413",
    "label": "Dua Koran",
    "value": "Dua Koran"
  },
  {
    "id": "5304132004",
    "district_id": "530413",
    "label": "Mandeu",
    "value": "Mandeu"
  },
  {
    "id": "5304132005",
    "district_id": "530413",
    "label": "Rafae",
    "value": "Rafae"
  },
  {
    "id": "5304132006",
    "district_id": "530413",
    "label": "Faturika",
    "value": "Faturika"
  },
  {
    "id": "5304132007",
    "district_id": "530413",
    "label": "Leuntolu",
    "value": "Leuntolu"
  },
  {
    "id": "5304132008",
    "district_id": "530413",
    "label": "Teun",
    "value": "Teun"
  },
  {
    "id": "5304132009",
    "district_id": "530413",
    "label": "Tasain",
    "value": "Tasain"
  },
  {
    "id": "5304172001",
    "district_id": "530417",
    "label": "Lasiolat",
    "value": "Lasiolat"
  },
  {
    "id": "5304172002",
    "district_id": "530417",
    "label": "Maneikun",
    "value": "Maneikun"
  },
  {
    "id": "5304172003",
    "district_id": "530417",
    "label": "Lakan Mau",
    "value": "Lakan Mau"
  },
  {
    "id": "5304172004",
    "district_id": "530417",
    "label": "Dualasi Rai Ulun",
    "value": "Dualasi Rai Ulun"
  },
  {
    "id": "5304172005",
    "district_id": "530417",
    "label": "Dualasi",
    "value": "Dualasi"
  },
  {
    "id": "5304172006",
    "district_id": "530417",
    "label": "Fatulotu",
    "value": "Fatulotu"
  },
  {
    "id": "5304172007",
    "district_id": "530417",
    "label": "Baudaok",
    "value": "Baudaok"
  },
  {
    "id": "5304182001",
    "district_id": "530418",
    "label": "Ekin",
    "value": "Ekin"
  },
  {
    "id": "5304182002",
    "district_id": "530418",
    "label": "Loonuna",
    "value": "Loonuna"
  },
  {
    "id": "5304182003",
    "district_id": "530418",
    "label": "Nualain",
    "value": "Nualain"
  },
  {
    "id": "5304182004",
    "district_id": "530418",
    "label": "Lakmaras",
    "value": "Lakmaras"
  },
  {
    "id": "5304182005",
    "district_id": "530418",
    "label": "Henes",
    "value": "Henes"
  },
  {
    "id": "5304182006",
    "district_id": "530418",
    "label": "Debululik",
    "value": "Debululik"
  },
  {
    "id": "5304182007",
    "district_id": "530418",
    "label": "Sisi Fatuberal",
    "value": "Sisi Fatuberal"
  },
  {
    "id": "5304182008",
    "district_id": "530418",
    "label": "Lutha Rato",
    "value": "Lutha Rato"
  },
  {
    "id": "5304211001",
    "district_id": "530421",
    "label": "Bardao",
    "value": "Bardao"
  },
  {
    "id": "5304211002",
    "district_id": "530421",
    "label": "Tulamalae",
    "value": "Tulamalae"
  },
  {
    "id": "5304211003",
    "district_id": "530421",
    "label": "Umanen",
    "value": "Umanen"
  },
  {
    "id": "5304211004",
    "district_id": "530421",
    "label": "Beirafu",
    "value": "Beirafu"
  },
  {
    "id": "5304221001",
    "district_id": "530422",
    "label": "Manuaman",
    "value": "Manuaman"
  },
  {
    "id": "5304221002",
    "district_id": "530422",
    "label": "Rinbesi",
    "value": "Rinbesi"
  },
  {
    "id": "5304221003",
    "district_id": "530422",
    "label": "Lidak",
    "value": "Lidak"
  },
  {
    "id": "5304221004",
    "district_id": "530422",
    "label": "Fatukbot",
    "value": "Fatukbot"
  },
  {
    "id": "5304232001",
    "district_id": "530423",
    "label": "Dubesi",
    "value": "Dubesi"
  },
  {
    "id": "5304232002",
    "district_id": "530423",
    "label": "Nanaet",
    "value": "Nanaet"
  },
  {
    "id": "5304232003",
    "district_id": "530423",
    "label": "Fohoeka",
    "value": "Fohoeka"
  },
  {
    "id": "5304232004",
    "district_id": "530423",
    "label": "Nanaenoe",
    "value": "Nanaenoe"
  },
  {
    "id": "5305011001",
    "district_id": "530501",
    "label": "Kalabahi Barat",
    "value": "Kalabahi Barat"
  },
  {
    "id": "5305011002",
    "district_id": "530501",
    "label": "Kalabahi Kota",
    "value": "Kalabahi Kota"
  },
  {
    "id": "5305011003",
    "district_id": "530501",
    "label": "Kalabahi Tengah",
    "value": "Kalabahi Tengah"
  },
  {
    "id": "5305011004",
    "district_id": "530501",
    "label": "Kalabahi Timur",
    "value": "Kalabahi Timur"
  },
  {
    "id": "5305011005",
    "district_id": "530501",
    "label": "Binongko",
    "value": "Binongko"
  },
  {
    "id": "5305011006",
    "district_id": "530501",
    "label": "Nusa Kenari",
    "value": "Nusa Kenari"
  },
  {
    "id": "5305011008",
    "district_id": "530501",
    "label": "Welai Barat",
    "value": "Welai Barat"
  },
  {
    "id": "5305011009",
    "district_id": "530501",
    "label": "Welai Timur",
    "value": "Welai Timur"
  },
  {
    "id": "5305011010",
    "district_id": "530501",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "5305011011",
    "district_id": "530501",
    "label": "Wetabua",
    "value": "Wetabua"
  },
  {
    "id": "5305012012",
    "district_id": "530501",
    "label": "Lendola",
    "value": "Lendola"
  },
  {
    "id": "5305012013",
    "district_id": "530501",
    "label": "Fanating",
    "value": "Fanating"
  },
  {
    "id": "5305012014",
    "district_id": "530501",
    "label": "Motongbang",
    "value": "Motongbang"
  },
  {
    "id": "5305012015",
    "district_id": "530501",
    "label": "Air Kenari",
    "value": "Air Kenari"
  },
  {
    "id": "5305012018",
    "district_id": "530501",
    "label": "Teluk Kenari",
    "value": "Teluk Kenari"
  },
  {
    "id": "5305012019",
    "district_id": "530501",
    "label": "Adang Buom",
    "value": "Adang Buom"
  },
  {
    "id": "5305021002",
    "district_id": "530502",
    "label": "Adang",
    "value": "Adang"
  },
  {
    "id": "5305022003",
    "district_id": "530502",
    "label": "Alor Kecil",
    "value": "Alor Kecil"
  },
  {
    "id": "5305022004",
    "district_id": "530502",
    "label": "Alor Besar",
    "value": "Alor Besar"
  },
  {
    "id": "5305022005",
    "district_id": "530502",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "5305022006",
    "district_id": "530502",
    "label": "Dulolong",
    "value": "Dulolong"
  },
  {
    "id": "5305022007",
    "district_id": "530502",
    "label": "Aimoli",
    "value": "Aimoli"
  },
  {
    "id": "5305022008",
    "district_id": "530502",
    "label": "Alila",
    "value": "Alila"
  },
  {
    "id": "5305022009",
    "district_id": "530502",
    "label": "Otvai",
    "value": "Otvai"
  },
  {
    "id": "5305022012",
    "district_id": "530502",
    "label": "Ternate",
    "value": "Ternate"
  },
  {
    "id": "5305022013",
    "district_id": "530502",
    "label": "Ternate Selatan",
    "value": "Ternate Selatan"
  },
  {
    "id": "5305022014",
    "district_id": "530502",
    "label": "Alila Selatan",
    "value": "Alila Selatan"
  },
  {
    "id": "5305022019",
    "district_id": "530502",
    "label": "Pulau Buaya",
    "value": "Pulau Buaya"
  },
  {
    "id": "5305022020",
    "district_id": "530502",
    "label": "Hulnani",
    "value": "Hulnani"
  },
  {
    "id": "5305022022",
    "district_id": "530502",
    "label": "O'a Mate",
    "value": "O'a Mate"
  },
  {
    "id": "5305022023",
    "district_id": "530502",
    "label": "Ala'ang",
    "value": "Ala'ang"
  },
  {
    "id": "5305022024",
    "district_id": "530502",
    "label": "Lefokisu",
    "value": "Lefokisu"
  },
  {
    "id": "5305022025",
    "district_id": "530502",
    "label": "Bampalola",
    "value": "Bampalola"
  },
  {
    "id": "5305022026",
    "district_id": "530502",
    "label": "Lewalu",
    "value": "Lewalu"
  },
  {
    "id": "5305022027",
    "district_id": "530502",
    "label": "Dulolong Barat",
    "value": "Dulolong Barat"
  },
  {
    "id": "5305031001",
    "district_id": "530503",
    "label": "Moru",
    "value": "Moru"
  },
  {
    "id": "5305032002",
    "district_id": "530503",
    "label": "Probur",
    "value": "Probur"
  },
  {
    "id": "5305032003",
    "district_id": "530503",
    "label": "Tribur",
    "value": "Tribur"
  },
  {
    "id": "5305032004",
    "district_id": "530503",
    "label": "Wakapsir",
    "value": "Wakapsir"
  },
  {
    "id": "5305032007",
    "district_id": "530503",
    "label": "Morba",
    "value": "Morba"
  },
  {
    "id": "5305032008",
    "district_id": "530503",
    "label": "Pintumas",
    "value": "Pintumas"
  },
  {
    "id": "5305032009",
    "district_id": "530503",
    "label": "Wolwal",
    "value": "Wolwal"
  },
  {
    "id": "5305032010",
    "district_id": "530503",
    "label": "Halerman",
    "value": "Halerman"
  },
  {
    "id": "5305032013",
    "district_id": "530503",
    "label": "Kafelulang",
    "value": "Kafelulang"
  },
  {
    "id": "5305032014",
    "district_id": "530503",
    "label": "Moramam",
    "value": "Moramam"
  },
  {
    "id": "5305032015",
    "district_id": "530503",
    "label": "Pailelang",
    "value": "Pailelang"
  },
  {
    "id": "5305032016",
    "district_id": "530503",
    "label": "Wolwal Barat",
    "value": "Wolwal Barat"
  },
  {
    "id": "5305032017",
    "district_id": "530503",
    "label": "Wolwal Tengah",
    "value": "Wolwal Tengah"
  },
  {
    "id": "5305032018",
    "district_id": "530503",
    "label": "Wolwal Selatan",
    "value": "Wolwal Selatan"
  },
  {
    "id": "5305032019",
    "district_id": "530503",
    "label": "Probur Utara",
    "value": "Probur Utara"
  },
  {
    "id": "5305032020",
    "district_id": "530503",
    "label": "Manatang",
    "value": "Manatang"
  },
  {
    "id": "5305032021",
    "district_id": "530503",
    "label": "Orgen",
    "value": "Orgen"
  },
  {
    "id": "5305032022",
    "district_id": "530503",
    "label": "Wakapsir Timur",
    "value": "Wakapsir Timur"
  },
  {
    "id": "5305032023",
    "district_id": "530503",
    "label": "Kuifana",
    "value": "Kuifana"
  },
  {
    "id": "5305032027",
    "district_id": "530503",
    "label": "Margeta",
    "value": "Margeta"
  },
  {
    "id": "5305041001",
    "district_id": "530504",
    "label": "Kelaisi Timur",
    "value": "Kelaisi Timur"
  },
  {
    "id": "5305042002",
    "district_id": "530504",
    "label": "Kelaisi Barat",
    "value": "Kelaisi Barat"
  },
  {
    "id": "5305042003",
    "district_id": "530504",
    "label": "Sidabui",
    "value": "Sidabui"
  },
  {
    "id": "5305042004",
    "district_id": "530504",
    "label": "Padang Alang",
    "value": "Padang Alang"
  },
  {
    "id": "5305042005",
    "district_id": "530504",
    "label": "Kuneman",
    "value": "Kuneman"
  },
  {
    "id": "5305042006",
    "district_id": "530504",
    "label": "Silaipui",
    "value": "Silaipui"
  },
  {
    "id": "5305042007",
    "district_id": "530504",
    "label": "Kelaisi Tengah",
    "value": "Kelaisi Tengah"
  },
  {
    "id": "5305042008",
    "district_id": "530504",
    "label": "Kiraman",
    "value": "Kiraman"
  },
  {
    "id": "5305042009",
    "district_id": "530504",
    "label": "Maikang",
    "value": "Maikang"
  },
  {
    "id": "5305042010",
    "district_id": "530504",
    "label": "Malaipea",
    "value": "Malaipea"
  },
  {
    "id": "5305042011",
    "district_id": "530504",
    "label": "Tamanapui",
    "value": "Tamanapui"
  },
  {
    "id": "5305042012",
    "district_id": "530504",
    "label": "Lella",
    "value": "Lella"
  },
  {
    "id": "5305042013",
    "district_id": "530504",
    "label": "Manmas",
    "value": "Manmas"
  },
  {
    "id": "5305042014",
    "district_id": "530504",
    "label": "Subo",
    "value": "Subo"
  },
  {
    "id": "5305051001",
    "district_id": "530505",
    "label": "Kolana Utara",
    "value": "Kolana Utara"
  },
  {
    "id": "5305052004",
    "district_id": "530505",
    "label": "Tanglapui",
    "value": "Tanglapui"
  },
  {
    "id": "5305052005",
    "district_id": "530505",
    "label": "Kolana Selatan",
    "value": "Kolana Selatan"
  },
  {
    "id": "5305052007",
    "district_id": "530505",
    "label": "Padang Panjang",
    "value": "Padang Panjang"
  },
  {
    "id": "5305052008",
    "district_id": "530505",
    "label": "Maritaing",
    "value": "Maritaing"
  },
  {
    "id": "5305052010",
    "district_id": "530505",
    "label": "Elok",
    "value": "Elok"
  },
  {
    "id": "5305052011",
    "district_id": "530505",
    "label": "Tanglapui Timur",
    "value": "Tanglapui Timur"
  },
  {
    "id": "5305052012",
    "district_id": "530505",
    "label": "Mausamang",
    "value": "Mausamang"
  },
  {
    "id": "5305052013",
    "district_id": "530505",
    "label": "Belemana",
    "value": "Belemana"
  },
  {
    "id": "5305052014",
    "district_id": "530505",
    "label": "Maukuru",
    "value": "Maukuru"
  },
  {
    "id": "5305061001",
    "district_id": "530506",
    "label": "Kabir",
    "value": "Kabir"
  },
  {
    "id": "5305062005",
    "district_id": "530506",
    "label": "Pandai",
    "value": "Pandai"
  },
  {
    "id": "5305062009",
    "district_id": "530506",
    "label": "Bandar",
    "value": "Bandar"
  },
  {
    "id": "5305062010",
    "district_id": "530506",
    "label": "Baolang",
    "value": "Baolang"
  },
  {
    "id": "5305062011",
    "district_id": "530506",
    "label": "Munaseli",
    "value": "Munaseli"
  },
  {
    "id": "5305062012",
    "district_id": "530506",
    "label": "Helangdohi",
    "value": "Helangdohi"
  },
  {
    "id": "5305062013",
    "district_id": "530506",
    "label": "Wailawar",
    "value": "Wailawar"
  },
  {
    "id": "5305062014",
    "district_id": "530506",
    "label": "Bana",
    "value": "Bana"
  },
  {
    "id": "5305062016",
    "district_id": "530506",
    "label": "Bouweli",
    "value": "Bouweli"
  },
  {
    "id": "5305062017",
    "district_id": "530506",
    "label": "Bukit Mas",
    "value": "Bukit Mas"
  },
  {
    "id": "5305062019",
    "district_id": "530506",
    "label": "Madar",
    "value": "Madar"
  },
  {
    "id": "5305072001",
    "district_id": "530507",
    "label": "Lembur Barat",
    "value": "Lembur Barat"
  },
  {
    "id": "5305072002",
    "district_id": "530507",
    "label": "Lembur Tengah",
    "value": "Lembur Tengah"
  },
  {
    "id": "5305072004",
    "district_id": "530507",
    "label": "Likwatang",
    "value": "Likwatang"
  },
  {
    "id": "5305072007",
    "district_id": "530507",
    "label": "Fungafeng",
    "value": "Fungafeng"
  },
  {
    "id": "5305072008",
    "district_id": "530507",
    "label": "Fuisama",
    "value": "Fuisama"
  },
  {
    "id": "5305072009",
    "district_id": "530507",
    "label": "Nur Benlelang",
    "value": "Nur Benlelang"
  },
  {
    "id": "5305072010",
    "district_id": "530507",
    "label": "Kafakbeka",
    "value": "Kafakbeka"
  },
  {
    "id": "5305072011",
    "district_id": "530507",
    "label": "Manetwati",
    "value": "Manetwati"
  },
  {
    "id": "5305072012",
    "district_id": "530507",
    "label": "Lakwati",
    "value": "Lakwati"
  },
  {
    "id": "5305072014",
    "district_id": "530507",
    "label": "Dapitau",
    "value": "Dapitau"
  },
  {
    "id": "5305072017",
    "district_id": "530507",
    "label": "Alim Mebung",
    "value": "Alim Mebung"
  },
  {
    "id": "5305072018",
    "district_id": "530507",
    "label": "Petleng",
    "value": "Petleng"
  },
  {
    "id": "5305072019",
    "district_id": "530507",
    "label": "Welai Selatan",
    "value": "Welai Selatan"
  },
  {
    "id": "5305072020",
    "district_id": "530507",
    "label": "Tominuku",
    "value": "Tominuku"
  },
  {
    "id": "5305082001",
    "district_id": "530508",
    "label": "Pido",
    "value": "Pido"
  },
  {
    "id": "5305082002",
    "district_id": "530508",
    "label": "Waisika",
    "value": "Waisika"
  },
  {
    "id": "5305082003",
    "district_id": "530508",
    "label": "Kamot",
    "value": "Kamot"
  },
  {
    "id": "5305082004",
    "district_id": "530508",
    "label": "Taramana",
    "value": "Taramana"
  },
  {
    "id": "5305082005",
    "district_id": "530508",
    "label": "Nailang",
    "value": "Nailang"
  },
  {
    "id": "5305082006",
    "district_id": "530508",
    "label": "Lippang",
    "value": "Lippang"
  },
  {
    "id": "5305082007",
    "district_id": "530508",
    "label": "Kenarimbala",
    "value": "Kenarimbala"
  },
  {
    "id": "5305082008",
    "district_id": "530508",
    "label": "Air Mancur",
    "value": "Air Mancur"
  },
  {
    "id": "5305092002",
    "district_id": "530509",
    "label": "Baraler",
    "value": "Baraler"
  },
  {
    "id": "5305092003",
    "district_id": "530509",
    "label": "Kalondama",
    "value": "Kalondama"
  },
  {
    "id": "5305092007",
    "district_id": "530509",
    "label": "Leer",
    "value": "Leer"
  },
  {
    "id": "5305092018",
    "district_id": "530509",
    "label": "Baranusa",
    "value": "Baranusa"
  },
  {
    "id": "5305092019",
    "district_id": "530509",
    "label": "Blang Merang",
    "value": "Blang Merang"
  },
  {
    "id": "5305092020",
    "district_id": "530509",
    "label": "Illu",
    "value": "Illu"
  },
  {
    "id": "5305092024",
    "district_id": "530509",
    "label": "Piringsina",
    "value": "Piringsina"
  },
  {
    "id": "5305101001",
    "district_id": "530510",
    "label": "Kabola",
    "value": "Kabola"
  },
  {
    "id": "5305102002",
    "district_id": "530510",
    "label": "Kopidil",
    "value": "Kopidil"
  },
  {
    "id": "5305102003",
    "district_id": "530510",
    "label": "Pante Deere",
    "value": "Pante Deere"
  },
  {
    "id": "5305102004",
    "district_id": "530510",
    "label": "Lawahing",
    "value": "Lawahing"
  },
  {
    "id": "5305102005",
    "district_id": "530510",
    "label": "Alila Timur",
    "value": "Alila Timur"
  },
  {
    "id": "5305111001",
    "district_id": "530511",
    "label": "Pura",
    "value": "Pura"
  },
  {
    "id": "5305112002",
    "district_id": "530511",
    "label": "Maru",
    "value": "Maru"
  },
  {
    "id": "5305112003",
    "district_id": "530511",
    "label": "Pura Utara",
    "value": "Pura Utara"
  },
  {
    "id": "5305112004",
    "district_id": "530511",
    "label": "Pura Selatan",
    "value": "Pura Selatan"
  },
  {
    "id": "5305112005",
    "district_id": "530511",
    "label": "Pura Timur",
    "value": "Pura Timur"
  },
  {
    "id": "5305112006",
    "district_id": "530511",
    "label": "Pura Barat",
    "value": "Pura Barat"
  },
  {
    "id": "5305122001",
    "district_id": "530512",
    "label": "Mataru Selatan",
    "value": "Mataru Selatan"
  },
  {
    "id": "5305122002",
    "district_id": "530512",
    "label": "Mataru Utara",
    "value": "Mataru Utara"
  },
  {
    "id": "5305122003",
    "district_id": "530512",
    "label": "Lakatuli",
    "value": "Lakatuli"
  },
  {
    "id": "5305122004",
    "district_id": "530512",
    "label": "Mataru Timur",
    "value": "Mataru Timur"
  },
  {
    "id": "5305122005",
    "district_id": "530512",
    "label": "Kamaifui",
    "value": "Kamaifui"
  },
  {
    "id": "5305122006",
    "district_id": "530512",
    "label": "Mataru Barat",
    "value": "Mataru Barat"
  },
  {
    "id": "5305122007",
    "district_id": "530512",
    "label": "Taman Mataru",
    "value": "Taman Mataru"
  },
  {
    "id": "5305132001",
    "district_id": "530513",
    "label": "Langkuru",
    "value": "Langkuru"
  },
  {
    "id": "5305132002",
    "district_id": "530513",
    "label": "Purnama",
    "value": "Purnama"
  },
  {
    "id": "5305132003",
    "district_id": "530513",
    "label": "Kailesa",
    "value": "Kailesa"
  },
  {
    "id": "5305132004",
    "district_id": "530513",
    "label": "Langkuru Utara",
    "value": "Langkuru Utara"
  },
  {
    "id": "5305142001",
    "district_id": "530514",
    "label": "Nule",
    "value": "Nule"
  },
  {
    "id": "5305142002",
    "district_id": "530514",
    "label": "Kaleb",
    "value": "Kaleb"
  },
  {
    "id": "5305142003",
    "district_id": "530514",
    "label": "Batu",
    "value": "Batu"
  },
  {
    "id": "5305142004",
    "district_id": "530514",
    "label": "Kaera",
    "value": "Kaera"
  },
  {
    "id": "5305142005",
    "district_id": "530514",
    "label": "Mawar",
    "value": "Mawar"
  },
  {
    "id": "5305142006",
    "district_id": "530514",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "5305142007",
    "district_id": "530514",
    "label": "Ombay",
    "value": "Ombay"
  },
  {
    "id": "5305142008",
    "district_id": "530514",
    "label": "Lekom",
    "value": "Lekom"
  },
  {
    "id": "5305142009",
    "district_id": "530514",
    "label": "Bungabali",
    "value": "Bungabali"
  },
  {
    "id": "5305142010",
    "district_id": "530514",
    "label": "Tereweng",
    "value": "Tereweng"
  },
  {
    "id": "5305142011",
    "district_id": "530514",
    "label": "Lalafang",
    "value": "Lalafang"
  },
  {
    "id": "5305152001",
    "district_id": "530515",
    "label": "Lembur Timur",
    "value": "Lembur Timur"
  },
  {
    "id": "5305152002",
    "district_id": "530515",
    "label": "Luba",
    "value": "Luba"
  },
  {
    "id": "5305152003",
    "district_id": "530515",
    "label": "Talwai",
    "value": "Talwai"
  },
  {
    "id": "5305152004",
    "district_id": "530515",
    "label": "Tulleng",
    "value": "Tulleng"
  },
  {
    "id": "5305152005",
    "district_id": "530515",
    "label": "Waimi",
    "value": "Waimi"
  },
  {
    "id": "5305152006",
    "district_id": "530515",
    "label": "Tasi",
    "value": "Tasi"
  },
  {
    "id": "5305162001",
    "district_id": "530516",
    "label": "Tude",
    "value": "Tude"
  },
  {
    "id": "5305162002",
    "district_id": "530516",
    "label": "Mauta",
    "value": "Mauta"
  },
  {
    "id": "5305162003",
    "district_id": "530516",
    "label": "Muriabang",
    "value": "Muriabang"
  },
  {
    "id": "5305162004",
    "district_id": "530516",
    "label": "Tubbe",
    "value": "Tubbe"
  },
  {
    "id": "5305162005",
    "district_id": "530516",
    "label": "Tamakh",
    "value": "Tamakh"
  },
  {
    "id": "5305162006",
    "district_id": "530516",
    "label": "Bagang",
    "value": "Bagang"
  },
  {
    "id": "5305162007",
    "district_id": "530516",
    "label": "Toang",
    "value": "Toang"
  },
  {
    "id": "5305162008",
    "district_id": "530516",
    "label": "Eka Jaya",
    "value": "Eka Jaya"
  },
  {
    "id": "5305162009",
    "district_id": "530516",
    "label": "Aramaba",
    "value": "Aramaba"
  },
  {
    "id": "5305162010",
    "district_id": "530516",
    "label": "Delaki",
    "value": "Delaki"
  },
  {
    "id": "5305172001",
    "district_id": "530517",
    "label": "Kayang",
    "value": "Kayang"
  },
  {
    "id": "5305172002",
    "district_id": "530517",
    "label": "Marisa",
    "value": "Marisa"
  },
  {
    "id": "5305172003",
    "district_id": "530517",
    "label": "Lamma",
    "value": "Lamma"
  },
  {
    "id": "5305172004",
    "district_id": "530517",
    "label": "Allumang",
    "value": "Allumang"
  },
  {
    "id": "5305172005",
    "district_id": "530517",
    "label": "Beangonong",
    "value": "Beangonong"
  },
  {
    "id": "5305172006",
    "district_id": "530517",
    "label": "Kalondama Barat",
    "value": "Kalondama Barat"
  },
  {
    "id": "5305172007",
    "district_id": "530517",
    "label": "Kalondama Tengah",
    "value": "Kalondama Tengah"
  },
  {
    "id": "5306012001",
    "district_id": "530601",
    "label": "Ojandetun",
    "value": "Ojandetun"
  },
  {
    "id": "5306012002",
    "district_id": "530601",
    "label": "Hewa",
    "value": "Hewa"
  },
  {
    "id": "5306012003",
    "district_id": "530601",
    "label": "Waiula",
    "value": "Waiula"
  },
  {
    "id": "5306012007",
    "district_id": "530601",
    "label": "Nawokote",
    "value": "Nawokote"
  },
  {
    "id": "5306012008",
    "district_id": "530601",
    "label": "Boru",
    "value": "Boru"
  },
  {
    "id": "5306012009",
    "district_id": "530601",
    "label": "Pululera",
    "value": "Pululera"
  },
  {
    "id": "5306012011",
    "district_id": "530601",
    "label": "Nileknoheng",
    "value": "Nileknoheng"
  },
  {
    "id": "5306012012",
    "district_id": "530601",
    "label": "Hokeng Jaya",
    "value": "Hokeng Jaya"
  },
  {
    "id": "5306012013",
    "district_id": "530601",
    "label": "Pantai Oa",
    "value": "Pantai Oa"
  },
  {
    "id": "5306012014",
    "district_id": "530601",
    "label": "Boru Kedang",
    "value": "Boru Kedang"
  },
  {
    "id": "5306012021",
    "district_id": "530601",
    "label": "Klatanlo",
    "value": "Klatanlo"
  },
  {
    "id": "5306022001",
    "district_id": "530602",
    "label": "Kobasoma",
    "value": "Kobasoma"
  },
  {
    "id": "5306022002",
    "district_id": "530602",
    "label": "Lewolaga",
    "value": "Lewolaga"
  },
  {
    "id": "5306022003",
    "district_id": "530602",
    "label": "Lewoingu",
    "value": "Lewoingu"
  },
  {
    "id": "5306022004",
    "district_id": "530602",
    "label": "Tuakepa",
    "value": "Tuakepa"
  },
  {
    "id": "5306022005",
    "district_id": "530602",
    "label": "Tenawahang",
    "value": "Tenawahang"
  },
  {
    "id": "5306022006",
    "district_id": "530602",
    "label": "Leraboleng",
    "value": "Leraboleng"
  },
  {
    "id": "5306022007",
    "district_id": "530602",
    "label": "Serinuho",
    "value": "Serinuho"
  },
  {
    "id": "5306022008",
    "district_id": "530602",
    "label": "Watowara",
    "value": "Watowara"
  },
  {
    "id": "5306022009",
    "district_id": "530602",
    "label": "Konga",
    "value": "Konga"
  },
  {
    "id": "5306022010",
    "district_id": "530602",
    "label": "Ile Gerong",
    "value": "Ile Gerong"
  },
  {
    "id": "5306022011",
    "district_id": "530602",
    "label": "Adabang",
    "value": "Adabang"
  },
  {
    "id": "5306022012",
    "district_id": "530602",
    "label": "Dun Tana Lewoingu",
    "value": "Dun Tana Lewoingu"
  },
  {
    "id": "5306022013",
    "district_id": "530602",
    "label": "Duli Jaya",
    "value": "Duli Jaya"
  },
  {
    "id": "5306022014",
    "district_id": "530602",
    "label": "Bokang Wolomatang",
    "value": "Bokang Wolomatang"
  },
  {
    "id": "5306031001",
    "district_id": "530603",
    "label": "Waibalun",
    "value": "Waibalun"
  },
  {
    "id": "5306031002",
    "district_id": "530603",
    "label": "Lewolere",
    "value": "Lewolere"
  },
  {
    "id": "5306031003",
    "district_id": "530603",
    "label": "Pantai Besar",
    "value": "Pantai Besar"
  },
  {
    "id": "5306031004",
    "district_id": "530603",
    "label": "Larantuka",
    "value": "Larantuka"
  },
  {
    "id": "5306031005",
    "district_id": "530603",
    "label": "Balela",
    "value": "Balela"
  },
  {
    "id": "5306031006",
    "district_id": "530603",
    "label": "Pohon Sirih",
    "value": "Pohon Sirih"
  },
  {
    "id": "5306031007",
    "district_id": "530603",
    "label": "Lohayong",
    "value": "Lohayong"
  },
  {
    "id": "5306031008",
    "district_id": "530603",
    "label": "Lokea",
    "value": "Lokea"
  },
  {
    "id": "5306031009",
    "district_id": "530603",
    "label": "Postoh",
    "value": "Postoh"
  },
  {
    "id": "5306031010",
    "district_id": "530603",
    "label": "Amagarapati",
    "value": "Amagarapati"
  },
  {
    "id": "5306031011",
    "district_id": "530603",
    "label": "Ekasapta",
    "value": "Ekasapta"
  },
  {
    "id": "5306031012",
    "district_id": "530603",
    "label": "Sarotari",
    "value": "Sarotari"
  },
  {
    "id": "5306031013",
    "district_id": "530603",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "5306031014",
    "district_id": "530603",
    "label": "Pukentobi Wangin Bao",
    "value": "Pukentobi Wangin Bao"
  },
  {
    "id": "5306031021",
    "district_id": "530603",
    "label": "Waihali",
    "value": "Waihali"
  },
  {
    "id": "5306031022",
    "district_id": "530603",
    "label": "Pohon Bao",
    "value": "Pohon Bao"
  },
  {
    "id": "5306031023",
    "district_id": "530603",
    "label": "Sarotari Tengah",
    "value": "Sarotari Tengah"
  },
  {
    "id": "5306031024",
    "district_id": "530603",
    "label": "Sarotari Timur",
    "value": "Sarotari Timur"
  },
  {
    "id": "5306032019",
    "district_id": "530603",
    "label": "Mokantarak",
    "value": "Mokantarak"
  },
  {
    "id": "5306032020",
    "district_id": "530603",
    "label": "Lamawalang",
    "value": "Lamawalang"
  },
  {
    "id": "5306042001",
    "district_id": "530604",
    "label": "Wailolong",
    "value": "Wailolong"
  },
  {
    "id": "5306042002",
    "district_id": "530604",
    "label": "Lewoloba",
    "value": "Lewoloba"
  },
  {
    "id": "5306042003",
    "district_id": "530604",
    "label": "Tiwatobi",
    "value": "Tiwatobi"
  },
  {
    "id": "5306042004",
    "district_id": "530604",
    "label": "Watotutu",
    "value": "Watotutu"
  },
  {
    "id": "5306042005",
    "district_id": "530604",
    "label": "Riangkemie",
    "value": "Riangkemie"
  },
  {
    "id": "5306042006",
    "district_id": "530604",
    "label": "Lewohala",
    "value": "Lewohala"
  },
  {
    "id": "5306042007",
    "district_id": "530604",
    "label": "Halakodanuan",
    "value": "Halakodanuan"
  },
  {
    "id": "5306042008",
    "district_id": "530604",
    "label": "Mudakaputu",
    "value": "Mudakaputu"
  },
  {
    "id": "5306052006",
    "district_id": "530605",
    "label": "Lewobunga",
    "value": "Lewobunga"
  },
  {
    "id": "5306052007",
    "district_id": "530605",
    "label": "Sinamalaka",
    "value": "Sinamalaka"
  },
  {
    "id": "5306052008",
    "district_id": "530605",
    "label": "Ratulodong",
    "value": "Ratulodong"
  },
  {
    "id": "5306052009",
    "district_id": "530605",
    "label": "Sinarhadigala",
    "value": "Sinarhadigala"
  },
  {
    "id": "5306052010",
    "district_id": "530605",
    "label": "Bahinga",
    "value": "Bahinga"
  },
  {
    "id": "5306052011",
    "district_id": "530605",
    "label": "Waibao",
    "value": "Waibao"
  },
  {
    "id": "5306052012",
    "district_id": "530605",
    "label": "Lamatutu",
    "value": "Lamatutu"
  },
  {
    "id": "5306052013",
    "district_id": "530605",
    "label": "Latonliwo",
    "value": "Latonliwo"
  },
  {
    "id": "5306052015",
    "district_id": "530605",
    "label": "Kolaka",
    "value": "Kolaka"
  },
  {
    "id": "5306052016",
    "district_id": "530605",
    "label": "Bandona",
    "value": "Bandona"
  },
  {
    "id": "5306052017",
    "district_id": "530605",
    "label": "Nusa Nipa",
    "value": "Nusa Nipa"
  },
  {
    "id": "5306052018",
    "district_id": "530605",
    "label": "Patisira Walang",
    "value": "Patisira Walang"
  },
  {
    "id": "5306052020",
    "district_id": "530605",
    "label": "Gekengderan",
    "value": "Gekengderan"
  },
  {
    "id": "5306052021",
    "district_id": "530605",
    "label": "Aransina",
    "value": "Aransina"
  },
  {
    "id": "5306052022",
    "district_id": "530605",
    "label": "Latonliwo Dua",
    "value": "Latonliwo Dua"
  },
  {
    "id": "5306052023",
    "district_id": "530605",
    "label": "Lamanabi",
    "value": "Lamanabi"
  },
  {
    "id": "5306061001",
    "district_id": "530606",
    "label": "Ritaebang",
    "value": "Ritaebang"
  },
  {
    "id": "5306062002",
    "district_id": "530606",
    "label": "Tanah Lein",
    "value": "Tanah Lein"
  },
  {
    "id": "5306062003",
    "district_id": "530606",
    "label": "Lamaole",
    "value": "Lamaole"
  },
  {
    "id": "5306062004",
    "district_id": "530606",
    "label": "Kalelu",
    "value": "Kalelu"
  },
  {
    "id": "5306062005",
    "district_id": "530606",
    "label": "Nusadani",
    "value": "Nusadani"
  },
  {
    "id": "5306062007",
    "district_id": "530606",
    "label": "Balaweling II",
    "value": "Balaweling II"
  },
  {
    "id": "5306062008",
    "district_id": "530606",
    "label": "Balaweling I",
    "value": "Balaweling I"
  },
  {
    "id": "5306062009",
    "district_id": "530606",
    "label": "Pamakayo",
    "value": "Pamakayo"
  },
  {
    "id": "5306062012",
    "district_id": "530606",
    "label": "Ongalereng",
    "value": "Ongalereng"
  },
  {
    "id": "5306062013",
    "district_id": "530606",
    "label": "Karawatung",
    "value": "Karawatung"
  },
  {
    "id": "5306062014",
    "district_id": "530606",
    "label": "Lamawalang",
    "value": "Lamawalang"
  },
  {
    "id": "5306062015",
    "district_id": "530606",
    "label": "Daniwato",
    "value": "Daniwato"
  },
  {
    "id": "5306062017",
    "district_id": "530606",
    "label": "Lewotanah Ole",
    "value": "Lewotanah Ole"
  },
  {
    "id": "5306062019",
    "district_id": "530606",
    "label": "Titehena",
    "value": "Titehena"
  },
  {
    "id": "5306062020",
    "district_id": "530606",
    "label": "Lewonama",
    "value": "Lewonama"
  },
  {
    "id": "5306072002",
    "district_id": "530607",
    "label": "Watanhura",
    "value": "Watanhura"
  },
  {
    "id": "5306072003",
    "district_id": "530607",
    "label": "Lebao",
    "value": "Lebao"
  },
  {
    "id": "5306072004",
    "district_id": "530607",
    "label": "Watohari",
    "value": "Watohari"
  },
  {
    "id": "5306072005",
    "district_id": "530607",
    "label": "Motonwutun",
    "value": "Motonwutun"
  },
  {
    "id": "5306072006",
    "district_id": "530607",
    "label": "Watobuku",
    "value": "Watobuku"
  },
  {
    "id": "5306072007",
    "district_id": "530607",
    "label": "Labelen",
    "value": "Labelen"
  },
  {
    "id": "5306072008",
    "district_id": "530607",
    "label": "Menanga",
    "value": "Menanga"
  },
  {
    "id": "5306072009",
    "district_id": "530607",
    "label": "Lohayong I",
    "value": "Lohayong I"
  },
  {
    "id": "5306072010",
    "district_id": "530607",
    "label": "Wulublolong",
    "value": "Wulublolong"
  },
  {
    "id": "5306072011",
    "district_id": "530607",
    "label": "Liwo",
    "value": "Liwo"
  },
  {
    "id": "5306072012",
    "district_id": "530607",
    "label": "Tanah Werang",
    "value": "Tanah Werang"
  },
  {
    "id": "5306072013",
    "district_id": "530607",
    "label": "Lewohedo",
    "value": "Lewohedo"
  },
  {
    "id": "5306072014",
    "district_id": "530607",
    "label": "Lohayong II",
    "value": "Lohayong II"
  },
  {
    "id": "5306072016",
    "district_id": "530607",
    "label": "Watanhura II",
    "value": "Watanhura II"
  },
  {
    "id": "5306072017",
    "district_id": "530607",
    "label": "Lewogeka",
    "value": "Lewogeka"
  },
  {
    "id": "5306072018",
    "district_id": "530607",
    "label": "Lamawai",
    "value": "Lamawai"
  },
  {
    "id": "5306072019",
    "district_id": "530607",
    "label": "Kawuta",
    "value": "Kawuta"
  },
  {
    "id": "5306082008",
    "district_id": "530608",
    "label": "Duanur",
    "value": "Duanur"
  },
  {
    "id": "5306082010",
    "district_id": "530608",
    "label": "Bukit Seburi I",
    "value": "Bukit Seburi I"
  },
  {
    "id": "5306082011",
    "district_id": "530608",
    "label": "Waiwadan",
    "value": "Waiwadan"
  },
  {
    "id": "5306082012",
    "district_id": "530608",
    "label": "Danibao",
    "value": "Danibao"
  },
  {
    "id": "5306082013",
    "district_id": "530608",
    "label": "Pajinian",
    "value": "Pajinian"
  },
  {
    "id": "5306082014",
    "district_id": "530608",
    "label": "Ile Pati",
    "value": "Ile Pati"
  },
  {
    "id": "5306082015",
    "district_id": "530608",
    "label": "Wureh",
    "value": "Wureh"
  },
  {
    "id": "5306082016",
    "district_id": "530608",
    "label": "Tonuwotan",
    "value": "Tonuwotan"
  },
  {
    "id": "5306082017",
    "district_id": "530608",
    "label": "Hurung",
    "value": "Hurung"
  },
  {
    "id": "5306082019",
    "district_id": "530608",
    "label": "Bungalima",
    "value": "Bungalima"
  },
  {
    "id": "5306082021",
    "district_id": "530608",
    "label": "Homa",
    "value": "Homa"
  },
  {
    "id": "5306082024",
    "district_id": "530608",
    "label": "Bukit Seburi II",
    "value": "Bukit Seburi II"
  },
  {
    "id": "5306082026",
    "district_id": "530608",
    "label": "Kimakamak",
    "value": "Kimakamak"
  },
  {
    "id": "5306082030",
    "district_id": "530608",
    "label": "Watobaya",
    "value": "Watobaya"
  },
  {
    "id": "5306082032",
    "district_id": "530608",
    "label": "Nimun Danibao",
    "value": "Nimun Danibao"
  },
  {
    "id": "5306082033",
    "district_id": "530608",
    "label": "Waitukan",
    "value": "Waitukan"
  },
  {
    "id": "5306082035",
    "district_id": "530608",
    "label": "Wolokibang",
    "value": "Wolokibang"
  },
  {
    "id": "5306082036",
    "district_id": "530608",
    "label": "Riangpadu",
    "value": "Riangpadu"
  },
  {
    "id": "5306092001",
    "district_id": "530609",
    "label": "Wailebe",
    "value": "Wailebe"
  },
  {
    "id": "5306092002",
    "district_id": "530609",
    "label": "Wotanulumado",
    "value": "Wotanulumado"
  },
  {
    "id": "5306092003",
    "district_id": "530609",
    "label": "Nayubaya",
    "value": "Nayubaya"
  },
  {
    "id": "5306092004",
    "district_id": "530609",
    "label": "Oyangbarang",
    "value": "Oyangbarang"
  },
  {
    "id": "5306092005",
    "district_id": "530609",
    "label": "Kawela",
    "value": "Kawela"
  },
  {
    "id": "5306092006",
    "district_id": "530609",
    "label": "Klukengnuking",
    "value": "Klukengnuking"
  },
  {
    "id": "5306092007",
    "district_id": "530609",
    "label": "Pandai",
    "value": "Pandai"
  },
  {
    "id": "5306092008",
    "district_id": "530609",
    "label": "Bliko",
    "value": "Bliko"
  },
  {
    "id": "5306092009",
    "district_id": "530609",
    "label": "Samsoge",
    "value": "Samsoge"
  },
  {
    "id": "5306092011",
    "district_id": "530609",
    "label": "Tobilota",
    "value": "Tobilota"
  },
  {
    "id": "5306092012",
    "district_id": "530609",
    "label": "Tana Tukan",
    "value": "Tana Tukan"
  },
  {
    "id": "5306092013",
    "district_id": "530609",
    "label": "Demondei",
    "value": "Demondei"
  },
  {
    "id": "5306101001",
    "district_id": "530610",
    "label": "Waiwerang Kota",
    "value": "Waiwerang Kota"
  },
  {
    "id": "5306101002",
    "district_id": "530610",
    "label": "Lamatwelu",
    "value": "Lamatwelu"
  },
  {
    "id": "5306102003",
    "district_id": "530610",
    "label": "Dawataa",
    "value": "Dawataa"
  },
  {
    "id": "5306102004",
    "district_id": "530610",
    "label": "Teromh",
    "value": "Teromh"
  },
  {
    "id": "5306102005",
    "district_id": "530610",
    "label": "Bilal",
    "value": "Bilal"
  },
  {
    "id": "5306102006",
    "district_id": "530610",
    "label": "Lamahala Jaya",
    "value": "Lamahala Jaya"
  },
  {
    "id": "5306102007",
    "district_id": "530610",
    "label": "Waiburak",
    "value": "Waiburak"
  },
  {
    "id": "5306102008",
    "district_id": "530610",
    "label": "Narasaosina",
    "value": "Narasaosina"
  },
  {
    "id": "5306102009",
    "district_id": "530610",
    "label": "Karinglamalouk",
    "value": "Karinglamalouk"
  },
  {
    "id": "5306102010",
    "district_id": "530610",
    "label": "Tuawolo",
    "value": "Tuawolo"
  },
  {
    "id": "5306102011",
    "district_id": "530610",
    "label": "Kiwangona",
    "value": "Kiwangona"
  },
  {
    "id": "5306102012",
    "district_id": "530610",
    "label": "Tapobali",
    "value": "Tapobali"
  },
  {
    "id": "5306102013",
    "district_id": "530610",
    "label": "Lewobunga",
    "value": "Lewobunga"
  },
  {
    "id": "5306102014",
    "district_id": "530610",
    "label": "Belota",
    "value": "Belota"
  },
  {
    "id": "5306102015",
    "district_id": "530610",
    "label": "Ipi Ebang",
    "value": "Ipi Ebang"
  },
  {
    "id": "5306102016",
    "district_id": "530610",
    "label": "Lamalata",
    "value": "Lamalata"
  },
  {
    "id": "5306102017",
    "district_id": "530610",
    "label": "Puhu",
    "value": "Puhu"
  },
  {
    "id": "5306102018",
    "district_id": "530610",
    "label": "Kwaelaga Lamawato",
    "value": "Kwaelaga Lamawato"
  },
  {
    "id": "5306102019",
    "district_id": "530610",
    "label": "Gelong",
    "value": "Gelong"
  },
  {
    "id": "5306102020",
    "district_id": "530610",
    "label": "Lelen Bala",
    "value": "Lelen Bala"
  },
  {
    "id": "5306102021",
    "district_id": "530610",
    "label": "Saosina",
    "value": "Saosina"
  },
  {
    "id": "5306112001",
    "district_id": "530611",
    "label": "Rodentena",
    "value": "Rodentena"
  },
  {
    "id": "5306112002",
    "district_id": "530611",
    "label": "Adolaba",
    "value": "Adolaba"
  },
  {
    "id": "5306112003",
    "district_id": "530611",
    "label": "Hinga",
    "value": "Hinga"
  },
  {
    "id": "5306112004",
    "district_id": "530611",
    "label": "Lamapaha",
    "value": "Lamapaha"
  },
  {
    "id": "5306112005",
    "district_id": "530611",
    "label": "Sukutokan",
    "value": "Sukutokan"
  },
  {
    "id": "5306112006",
    "district_id": "530611",
    "label": "Nisakarang",
    "value": "Nisakarang"
  },
  {
    "id": "5306112007",
    "district_id": "530611",
    "label": "Muda",
    "value": "Muda"
  },
  {
    "id": "5306112008",
    "district_id": "530611",
    "label": "Horinara",
    "value": "Horinara"
  },
  {
    "id": "5306112009",
    "district_id": "530611",
    "label": "Pepakelu",
    "value": "Pepakelu"
  },
  {
    "id": "5306112010",
    "district_id": "530611",
    "label": "Lambunga",
    "value": "Lambunga"
  },
  {
    "id": "5306112012",
    "district_id": "530611",
    "label": "Mangaaleng",
    "value": "Mangaaleng"
  },
  {
    "id": "5306112019",
    "district_id": "530611",
    "label": "Keluwain",
    "value": "Keluwain"
  },
  {
    "id": "5306122001",
    "district_id": "530612",
    "label": "Watololong",
    "value": "Watololong"
  },
  {
    "id": "5306122002",
    "district_id": "530612",
    "label": "Tuagoetobi",
    "value": "Tuagoetobi"
  },
  {
    "id": "5306122003",
    "district_id": "530612",
    "label": "Weranggere",
    "value": "Weranggere"
  },
  {
    "id": "5306122004",
    "district_id": "530612",
    "label": "Oringbele",
    "value": "Oringbele"
  },
  {
    "id": "5306122005",
    "district_id": "530612",
    "label": "Watoone",
    "value": "Watoone"
  },
  {
    "id": "5306122006",
    "district_id": "530612",
    "label": "Lamabelawa",
    "value": "Lamabelawa"
  },
  {
    "id": "5306122007",
    "district_id": "530612",
    "label": "Pledo",
    "value": "Pledo"
  },
  {
    "id": "5306122008",
    "district_id": "530612",
    "label": "Lewopulo",
    "value": "Lewopulo"
  },
  {
    "id": "5306122009",
    "district_id": "530612",
    "label": "Sandosi",
    "value": "Sandosi"
  },
  {
    "id": "5306122010",
    "district_id": "530612",
    "label": "Balaweling",
    "value": "Balaweling"
  },
  {
    "id": "5306122011",
    "district_id": "530612",
    "label": "Tobitika",
    "value": "Tobitika"
  },
  {
    "id": "5306122012",
    "district_id": "530612",
    "label": "Riangduli",
    "value": "Riangduli"
  },
  {
    "id": "5306122013",
    "district_id": "530612",
    "label": "Waiwuring",
    "value": "Waiwuring"
  },
  {
    "id": "5306122014",
    "district_id": "530612",
    "label": "Baobage",
    "value": "Baobage"
  },
  {
    "id": "5306122015",
    "district_id": "530612",
    "label": "Lamaleka",
    "value": "Lamaleka"
  },
  {
    "id": "5306122016",
    "district_id": "530612",
    "label": "Balaweling Noten",
    "value": "Balaweling Noten"
  },
  {
    "id": "5306132001",
    "district_id": "530613",
    "label": "Bedalewun",
    "value": "Bedalewun"
  },
  {
    "id": "5306132002",
    "district_id": "530613",
    "label": "Lebanuba",
    "value": "Lebanuba"
  },
  {
    "id": "5306132003",
    "district_id": "530613",
    "label": "Rianwale",
    "value": "Rianwale"
  },
  {
    "id": "5306132004",
    "district_id": "530613",
    "label": "Bungalawan",
    "value": "Bungalawan"
  },
  {
    "id": "5306132005",
    "district_id": "530613",
    "label": "Lamawolo",
    "value": "Lamawolo"
  },
  {
    "id": "5306132006",
    "district_id": "530613",
    "label": "Helanlangowuyo",
    "value": "Helanlangowuyo"
  },
  {
    "id": "5306132007",
    "district_id": "530613",
    "label": "Lewopao",
    "value": "Lewopao"
  },
  {
    "id": "5306132008",
    "district_id": "530613",
    "label": "Nelereren",
    "value": "Nelereren"
  },
  {
    "id": "5306132009",
    "district_id": "530613",
    "label": "Boleng",
    "value": "Boleng"
  },
  {
    "id": "5306132010",
    "district_id": "530613",
    "label": "Neleblolong",
    "value": "Neleblolong"
  },
  {
    "id": "5306132011",
    "district_id": "530613",
    "label": "Duablolong",
    "value": "Duablolong"
  },
  {
    "id": "5306132012",
    "district_id": "530613",
    "label": "Lewokeleng",
    "value": "Lewokeleng"
  },
  {
    "id": "5306132013",
    "district_id": "530613",
    "label": "Nelelamawangi",
    "value": "Nelelamawangi"
  },
  {
    "id": "5306132014",
    "district_id": "530613",
    "label": "Harubala",
    "value": "Harubala"
  },
  {
    "id": "5306132015",
    "district_id": "530613",
    "label": "Nelelamadike",
    "value": "Nelelamadike"
  },
  {
    "id": "5306132016",
    "district_id": "530613",
    "label": "Lamabayung",
    "value": "Lamabayung"
  },
  {
    "id": "5306132017",
    "district_id": "530613",
    "label": "Lewat",
    "value": "Lewat"
  },
  {
    "id": "5306132018",
    "district_id": "530613",
    "label": "Dokeng",
    "value": "Dokeng"
  },
  {
    "id": "5306132019",
    "district_id": "530613",
    "label": "Bayuntaa",
    "value": "Bayuntaa"
  },
  {
    "id": "5306132020",
    "district_id": "530613",
    "label": "Nobo",
    "value": "Nobo"
  },
  {
    "id": "5306132021",
    "district_id": "530613",
    "label": "Nelelamawangi Dua",
    "value": "Nelelamawangi Dua"
  },
  {
    "id": "5306142001",
    "district_id": "530614",
    "label": "Lamika",
    "value": "Lamika"
  },
  {
    "id": "5306142002",
    "district_id": "530614",
    "label": "Watotika Ile",
    "value": "Watotika Ile"
  },
  {
    "id": "5306142003",
    "district_id": "530614",
    "label": "Lewokluok",
    "value": "Lewokluok"
  },
  {
    "id": "5306142004",
    "district_id": "530614",
    "label": "Blepanawa",
    "value": "Blepanawa"
  },
  {
    "id": "5306142005",
    "district_id": "530614",
    "label": "Kawalelo",
    "value": "Kawalelo"
  },
  {
    "id": "5306142006",
    "district_id": "530614",
    "label": "Bama",
    "value": "Bama"
  },
  {
    "id": "5306142007",
    "district_id": "530614",
    "label": "Lewomuda",
    "value": "Lewomuda"
  },
  {
    "id": "5306152001",
    "district_id": "530615",
    "label": "Ile Padung",
    "value": "Ile Padung"
  },
  {
    "id": "5306152002",
    "district_id": "530615",
    "label": "Bantala",
    "value": "Bantala"
  },
  {
    "id": "5306152003",
    "district_id": "530615",
    "label": "Sinar Hading",
    "value": "Sinar Hading"
  },
  {
    "id": "5306152004",
    "district_id": "530615",
    "label": "Paingnapang",
    "value": "Paingnapang"
  },
  {
    "id": "5306152005",
    "district_id": "530615",
    "label": "Baluk Herin",
    "value": "Baluk Herin"
  },
  {
    "id": "5306152006",
    "district_id": "530615",
    "label": "Riangkotek",
    "value": "Riangkotek"
  },
  {
    "id": "5306152007",
    "district_id": "530615",
    "label": "Lewobelen",
    "value": "Lewobelen"
  },
  {
    "id": "5306162001",
    "district_id": "530616",
    "label": "Riangbura",
    "value": "Riangbura"
  },
  {
    "id": "5306162002",
    "district_id": "530616",
    "label": "Birawan",
    "value": "Birawan"
  },
  {
    "id": "5306162003",
    "district_id": "530616",
    "label": "Nurri",
    "value": "Nurri"
  },
  {
    "id": "5306162004",
    "district_id": "530616",
    "label": "Nobokonga",
    "value": "Nobokonga"
  },
  {
    "id": "5306162005",
    "district_id": "530616",
    "label": "Lewoawang",
    "value": "Lewoawang"
  },
  {
    "id": "5306162006",
    "district_id": "530616",
    "label": "Dulipali",
    "value": "Dulipali"
  },
  {
    "id": "5306162007",
    "district_id": "530616",
    "label": "Riang Rita",
    "value": "Riang Rita"
  },
  {
    "id": "5306172001",
    "district_id": "530617",
    "label": "Sagu",
    "value": "Sagu"
  },
  {
    "id": "5306172002",
    "district_id": "530617",
    "label": "Kolimasang",
    "value": "Kolimasang"
  },
  {
    "id": "5306172003",
    "district_id": "530617",
    "label": "Kolilanang",
    "value": "Kolilanang"
  },
  {
    "id": "5306172004",
    "district_id": "530617",
    "label": "Tikatukan",
    "value": "Tikatukan"
  },
  {
    "id": "5306172005",
    "district_id": "530617",
    "label": "Nisa Nulan",
    "value": "Nisa Nulan"
  },
  {
    "id": "5306172006",
    "district_id": "530617",
    "label": "Lamahoda",
    "value": "Lamahoda"
  },
  {
    "id": "5306172007",
    "district_id": "530617",
    "label": "Adonara",
    "value": "Adonara"
  },
  {
    "id": "5306172008",
    "district_id": "530617",
    "label": "Kolipetung",
    "value": "Kolipetung"
  },
  {
    "id": "5306182001",
    "district_id": "530618",
    "label": "Horowura",
    "value": "Horowura"
  },
  {
    "id": "5306182002",
    "district_id": "530618",
    "label": "Kokotobo",
    "value": "Kokotobo"
  },
  {
    "id": "5306182003",
    "district_id": "530618",
    "label": "Lite",
    "value": "Lite"
  },
  {
    "id": "5306182004",
    "district_id": "530618",
    "label": "Kenotan",
    "value": "Kenotan"
  },
  {
    "id": "5306182005",
    "district_id": "530618",
    "label": "Nubalema",
    "value": "Nubalema"
  },
  {
    "id": "5306182006",
    "district_id": "530618",
    "label": "Wewit",
    "value": "Wewit"
  },
  {
    "id": "5306182007",
    "district_id": "530618",
    "label": "Lewobele",
    "value": "Lewobele"
  },
  {
    "id": "5306182008",
    "district_id": "530618",
    "label": "Baya",
    "value": "Baya"
  },
  {
    "id": "5306182009",
    "district_id": "530618",
    "label": "Bidara",
    "value": "Bidara"
  },
  {
    "id": "5306182010",
    "district_id": "530618",
    "label": "Oesayang",
    "value": "Oesayang"
  },
  {
    "id": "5306182011",
    "district_id": "530618",
    "label": "Hoko Horowura",
    "value": "Hoko Horowura"
  },
  {
    "id": "5306182012",
    "district_id": "530618",
    "label": "Lewopao",
    "value": "Lewopao"
  },
  {
    "id": "5306182013",
    "district_id": "530618",
    "label": "Nubalema Dua",
    "value": "Nubalema Dua"
  },
  {
    "id": "5306192001",
    "district_id": "530619",
    "label": "Kelike",
    "value": "Kelike"
  },
  {
    "id": "5306192002",
    "district_id": "530619",
    "label": "Kelike Aimatan",
    "value": "Kelike Aimatan"
  },
  {
    "id": "5306192003",
    "district_id": "530619",
    "label": "Sulengwaseng",
    "value": "Sulengwaseng"
  },
  {
    "id": "5306192004",
    "district_id": "530619",
    "label": "Kenere",
    "value": "Kenere"
  },
  {
    "id": "5306192005",
    "district_id": "530619",
    "label": "Lemanu",
    "value": "Lemanu"
  },
  {
    "id": "5306192006",
    "district_id": "530619",
    "label": "Lewograran",
    "value": "Lewograran"
  },
  {
    "id": "5306192007",
    "district_id": "530619",
    "label": "Bubuatagamu",
    "value": "Bubuatagamu"
  },
  {
    "id": "5307012001",
    "district_id": "530701",
    "label": "Masabewa",
    "value": "Masabewa"
  },
  {
    "id": "5307012003",
    "district_id": "530701",
    "label": "Wolowiro",
    "value": "Wolowiro"
  },
  {
    "id": "5307012004",
    "district_id": "530701",
    "label": "Mbengu",
    "value": "Mbengu"
  },
  {
    "id": "5307012005",
    "district_id": "530701",
    "label": "Paga",
    "value": "Paga"
  },
  {
    "id": "5307012008",
    "district_id": "530701",
    "label": "Lenandareta",
    "value": "Lenandareta"
  },
  {
    "id": "5307012012",
    "district_id": "530701",
    "label": "Mauloo",
    "value": "Mauloo"
  },
  {
    "id": "5307012013",
    "district_id": "530701",
    "label": "Wolorega",
    "value": "Wolorega"
  },
  {
    "id": "5307012015",
    "district_id": "530701",
    "label": "Wolowona",
    "value": "Wolowona"
  },
  {
    "id": "5307022001",
    "district_id": "530702",
    "label": "Bhera",
    "value": "Bhera"
  },
  {
    "id": "5307022002",
    "district_id": "530702",
    "label": "Dobo",
    "value": "Dobo"
  },
  {
    "id": "5307022003",
    "district_id": "530702",
    "label": "Gera",
    "value": "Gera"
  },
  {
    "id": "5307022004",
    "district_id": "530702",
    "label": "Liakutu",
    "value": "Liakutu"
  },
  {
    "id": "5307022005",
    "district_id": "530702",
    "label": "Parabubu",
    "value": "Parabubu"
  },
  {
    "id": "5307022006",
    "district_id": "530702",
    "label": "Wolodhesa",
    "value": "Wolodhesa"
  },
  {
    "id": "5307022007",
    "district_id": "530702",
    "label": "Korobhera",
    "value": "Korobhera"
  },
  {
    "id": "5307022008",
    "district_id": "530702",
    "label": "Napugera",
    "value": "Napugera"
  },
  {
    "id": "5307022009",
    "district_id": "530702",
    "label": "Kowi",
    "value": "Kowi"
  },
  {
    "id": "5307022010",
    "district_id": "530702",
    "label": "Dobo Nuapuu",
    "value": "Dobo Nuapuu"
  },
  {
    "id": "5307032001",
    "district_id": "530703",
    "label": "Lela",
    "value": "Lela"
  },
  {
    "id": "5307032002",
    "district_id": "530703",
    "label": "Watutedang",
    "value": "Watutedang"
  },
  {
    "id": "5307032003",
    "district_id": "530703",
    "label": "Korowuwu",
    "value": "Korowuwu"
  },
  {
    "id": "5307032004",
    "district_id": "530703",
    "label": "Kolidetung",
    "value": "Kolidetung"
  },
  {
    "id": "5307032005",
    "district_id": "530703",
    "label": "Sikka",
    "value": "Sikka"
  },
  {
    "id": "5307032006",
    "district_id": "530703",
    "label": "Iligai",
    "value": "Iligai"
  },
  {
    "id": "5307032007",
    "district_id": "530703",
    "label": "Hepang",
    "value": "Hepang"
  },
  {
    "id": "5307032008",
    "district_id": "530703",
    "label": "Baopaat",
    "value": "Baopaat"
  },
  { "id": "5307032009", "district_id": "530703", "label": "Du", "value": "Du" },
  {
    "id": "5307042001",
    "district_id": "530704",
    "label": "Nita",
    "value": "Nita"
  },
  {
    "id": "5307042002",
    "district_id": "530704",
    "label": "Takaplager",
    "value": "Takaplager"
  },
  {
    "id": "5307042003",
    "district_id": "530704",
    "label": "Tebuk",
    "value": "Tebuk"
  },
  {
    "id": "5307042004",
    "district_id": "530704",
    "label": "Ladogahar",
    "value": "Ladogahar"
  },
  {
    "id": "5307042005",
    "district_id": "530704",
    "label": "Nitakloang",
    "value": "Nitakloang"
  },
  {
    "id": "5307042006",
    "district_id": "530704",
    "label": "Wuliwutik",
    "value": "Wuliwutik"
  },
  {
    "id": "5307042007",
    "district_id": "530704",
    "label": "Lusitada",
    "value": "Lusitada"
  },
  {
    "id": "5307042008",
    "district_id": "530704",
    "label": "Bloro",
    "value": "Bloro"
  },
  {
    "id": "5307042009",
    "district_id": "530704",
    "label": "Riit",
    "value": "Riit"
  },
  {
    "id": "5307042010",
    "district_id": "530704",
    "label": "Tilang",
    "value": "Tilang"
  },
  {
    "id": "5307042011",
    "district_id": "530704",
    "label": "Nirangkliung",
    "value": "Nirangkliung"
  },
  {
    "id": "5307042017",
    "district_id": "530704",
    "label": "Mahebora",
    "value": "Mahebora"
  },
  {
    "id": "5307051006",
    "district_id": "530705",
    "label": "Madawat",
    "value": "Madawat"
  },
  {
    "id": "5307051007",
    "district_id": "530705",
    "label": "Nangalimang",
    "value": "Nangalimang"
  },
  {
    "id": "5307051008",
    "district_id": "530705",
    "label": "Kabor",
    "value": "Kabor"
  },
  {
    "id": "5307051009",
    "district_id": "530705",
    "label": "Kota Uneng",
    "value": "Kota Uneng"
  },
  {
    "id": "5307052014",
    "district_id": "530705",
    "label": "Pemana",
    "value": "Pemana"
  },
  {
    "id": "5307052015",
    "district_id": "530705",
    "label": "Samparong",
    "value": "Samparong"
  },
  {
    "id": "5307052016",
    "district_id": "530705",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "5307062001",
    "district_id": "530706",
    "label": "Maluriwu",
    "value": "Maluriwu"
  },
  {
    "id": "5307062002",
    "district_id": "530706",
    "label": "Reruwairere",
    "value": "Reruwairere"
  },
  {
    "id": "5307062003",
    "district_id": "530706",
    "label": "Tuanggeo",
    "value": "Tuanggeo"
  },
  {
    "id": "5307062004",
    "district_id": "530706",
    "label": "Ladolaka",
    "value": "Ladolaka"
  },
  {
    "id": "5307062005",
    "district_id": "530706",
    "label": "Nitunglea",
    "value": "Nitunglea"
  },
  {
    "id": "5307062006",
    "district_id": "530706",
    "label": "Rokirole",
    "value": "Rokirole"
  },
  {
    "id": "5307062007",
    "district_id": "530706",
    "label": "Kesokoja",
    "value": "Kesokoja"
  },
  {
    "id": "5307062008",
    "district_id": "530706",
    "label": "Lidi",
    "value": "Lidi"
  },
  {
    "id": "5307072002",
    "district_id": "530707",
    "label": "Nelle Urung",
    "value": "Nelle Urung"
  },
  {
    "id": "5307072003",
    "district_id": "530707",
    "label": "Nelle Wutung",
    "value": "Nelle Wutung"
  },
  {
    "id": "5307072004",
    "district_id": "530707",
    "label": "Nelle Barat",
    "value": "Nelle Barat"
  },
  {
    "id": "5307072005",
    "district_id": "530707",
    "label": "Nella Lorang",
    "value": "Nella Lorang"
  },
  {
    "id": "5307072006",
    "district_id": "530707",
    "label": "Manubura",
    "value": "Manubura"
  },
  {
    "id": "5307082001",
    "district_id": "530708",
    "label": "Darat Gunung",
    "value": "Darat Gunung"
  },
  {
    "id": "5307082002",
    "district_id": "530708",
    "label": "Ojang",
    "value": "Ojang"
  },
  {
    "id": "5307082003",
    "district_id": "530708",
    "label": "Hikong",
    "value": "Hikong"
  },
  {
    "id": "5307082006",
    "district_id": "530708",
    "label": "Nebe",
    "value": "Nebe"
  },
  {
    "id": "5307082007",
    "district_id": "530708",
    "label": "Wailamung",
    "value": "Wailamung"
  },
  {
    "id": "5307082008",
    "district_id": "530708",
    "label": "Lewomada",
    "value": "Lewomada"
  },
  {
    "id": "5307082010",
    "district_id": "530708",
    "label": "Nangahale",
    "value": "Nangahale"
  },
  {
    "id": "5307082011",
    "district_id": "530708",
    "label": "Darat Pantai",
    "value": "Darat Pantai"
  },
  {
    "id": "5307082013",
    "district_id": "530708",
    "label": "Talibura",
    "value": "Talibura"
  },
  {
    "id": "5307082014",
    "district_id": "530708",
    "label": "Kringa",
    "value": "Kringa"
  },
  {
    "id": "5307082015",
    "district_id": "530708",
    "label": "Bangkoor",
    "value": "Bangkoor"
  },
  {
    "id": "5307082017",
    "district_id": "530708",
    "label": "Timutawa",
    "value": "Timutawa"
  },
  {
    "id": "5307092001",
    "district_id": "530709",
    "label": "Runut",
    "value": "Runut"
  },
  {
    "id": "5307092002",
    "district_id": "530709",
    "label": "Egon",
    "value": "Egon"
  },
  {
    "id": "5307092003",
    "district_id": "530709",
    "label": "Pogon",
    "value": "Pogon"
  },
  {
    "id": "5307092004",
    "district_id": "530709",
    "label": "Aibura",
    "value": "Aibura"
  },
  {
    "id": "5307092005",
    "district_id": "530709",
    "label": "Wairbleler",
    "value": "Wairbleler"
  },
  {
    "id": "5307092006",
    "district_id": "530709",
    "label": "Hoder",
    "value": "Hoder"
  },
  {
    "id": "5307092007",
    "district_id": "530709",
    "label": "Wairterang",
    "value": "Wairterang"
  },
  {
    "id": "5307092008",
    "district_id": "530709",
    "label": "Nangatobong",
    "value": "Nangatobong"
  },
  {
    "id": "5307092009",
    "district_id": "530709",
    "label": "Watudiran",
    "value": "Watudiran"
  },
  {
    "id": "5307102005",
    "district_id": "530710",
    "label": "Seusina",
    "value": "Seusina"
  },
  {
    "id": "5307102007",
    "district_id": "530710",
    "label": "Kopong",
    "value": "Kopong"
  },
  {
    "id": "5307102008",
    "district_id": "530710",
    "label": "Namangkewa",
    "value": "Namangkewa"
  },
  {
    "id": "5307102009",
    "district_id": "530710",
    "label": "Iantena",
    "value": "Iantena"
  },
  {
    "id": "5307102016",
    "district_id": "530710",
    "label": "Umagera",
    "value": "Umagera"
  },
  {
    "id": "5307102020",
    "district_id": "530710",
    "label": "Geliting",
    "value": "Geliting"
  },
  {
    "id": "5307102021",
    "district_id": "530710",
    "label": "Waiara",
    "value": "Waiara"
  },
  {
    "id": "5307102024",
    "district_id": "530710",
    "label": "Wairkoja",
    "value": "Wairkoja"
  },
  {
    "id": "5307112001",
    "district_id": "530711",
    "label": "Hokor",
    "value": "Hokor"
  },
  {
    "id": "5307112002",
    "district_id": "530711",
    "label": "Wolonwalu",
    "value": "Wolonwalu"
  },
  {
    "id": "5307112003",
    "district_id": "530711",
    "label": "Wolokoli",
    "value": "Wolokoli"
  },
  {
    "id": "5307112004",
    "district_id": "530711",
    "label": "Bola",
    "value": "Bola"
  },
  {
    "id": "5307112005",
    "district_id": "530711",
    "label": "Umauta",
    "value": "Umauta"
  },
  {
    "id": "5307112006",
    "district_id": "530711",
    "label": "Ipir",
    "value": "Ipir"
  },
  {
    "id": "5307122001",
    "district_id": "530712",
    "label": "Magepanda",
    "value": "Magepanda"
  },
  {
    "id": "5307122002",
    "district_id": "530712",
    "label": "Reroroja",
    "value": "Reroroja"
  },
  {
    "id": "5307122003",
    "district_id": "530712",
    "label": "Done",
    "value": "Done"
  },
  {
    "id": "5307122004",
    "district_id": "530712",
    "label": "Kolisia",
    "value": "Kolisia"
  },
  {
    "id": "5307122005",
    "district_id": "530712",
    "label": "Kolisia B",
    "value": "Kolisia B"
  },
  {
    "id": "5307132001",
    "district_id": "530713",
    "label": "Natarmage",
    "value": "Natarmage"
  },
  {
    "id": "5307132002",
    "district_id": "530713",
    "label": "Tanarawa",
    "value": "Tanarawa"
  },
  {
    "id": "5307132003",
    "district_id": "530713",
    "label": "Pruda",
    "value": "Pruda"
  },
  {
    "id": "5307132004",
    "district_id": "530713",
    "label": "Werang",
    "value": "Werang"
  },
  {
    "id": "5307132005",
    "district_id": "530713",
    "label": "Tua Bao",
    "value": "Tua Bao"
  },
  {
    "id": "5307132006",
    "district_id": "530713",
    "label": "Ilinmedo",
    "value": "Ilinmedo"
  },
  {
    "id": "5307141001",
    "district_id": "530714",
    "label": "Hewuli",
    "value": "Hewuli"
  },
  {
    "id": "5307141002",
    "district_id": "530714",
    "label": "Wailiti",
    "value": "Wailiti"
  },
  {
    "id": "5307141003",
    "district_id": "530714",
    "label": "Wolomarang",
    "value": "Wolomarang"
  },
  {
    "id": "5307141004",
    "district_id": "530714",
    "label": "Wuring",
    "value": "Wuring"
  },
  {
    "id": "5307151001",
    "district_id": "530715",
    "label": "Beru",
    "value": "Beru"
  },
  {
    "id": "5307151002",
    "district_id": "530715",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "5307151003",
    "district_id": "530715",
    "label": "Nangameting",
    "value": "Nangameting"
  },
  {
    "id": "5307151004",
    "district_id": "530715",
    "label": "Waioti",
    "value": "Waioti"
  },
  {
    "id": "5307151005",
    "district_id": "530715",
    "label": "Wairotang",
    "value": "Wairotang"
  },
  {
    "id": "5307152006",
    "district_id": "530715",
    "label": "Koja Doi",
    "value": "Koja Doi"
  },
  {
    "id": "5307152007",
    "district_id": "530715",
    "label": "Kojagete",
    "value": "Kojagete"
  },
  {
    "id": "5307152008",
    "district_id": "530715",
    "label": "Lepo Lima",
    "value": "Lepo Lima"
  },
  {
    "id": "5307152009",
    "district_id": "530715",
    "label": "Perumaan",
    "value": "Perumaan"
  },
  {
    "id": "5307152010",
    "district_id": "530715",
    "label": "Watugong",
    "value": "Watugong"
  },
  {
    "id": "5307162001",
    "district_id": "530716",
    "label": "Koting A",
    "value": "Koting A"
  },
  {
    "id": "5307162002",
    "district_id": "530716",
    "label": "Koting B",
    "value": "Koting B"
  },
  {
    "id": "5307162003",
    "district_id": "530716",
    "label": "Koting C",
    "value": "Koting C"
  },
  {
    "id": "5307162004",
    "district_id": "530716",
    "label": "Koting D",
    "value": "Koting D"
  },
  {
    "id": "5307162005",
    "district_id": "530716",
    "label": "Paubekor",
    "value": "Paubekor"
  },
  {
    "id": "5307162006",
    "district_id": "530716",
    "label": "Ribang",
    "value": "Ribang"
  },
  {
    "id": "5307172001",
    "district_id": "530717",
    "label": "Bu Selatan",
    "value": "Bu Selatan"
  },
  {
    "id": "5307172002",
    "district_id": "530717",
    "label": "Bu Utara",
    "value": "Bu Utara"
  },
  {
    "id": "5307172003",
    "district_id": "530717",
    "label": "Bu Watuweti",
    "value": "Bu Watuweti"
  },
  {
    "id": "5307172004",
    "district_id": "530717",
    "label": "Loke",
    "value": "Loke"
  },
  {
    "id": "5307172005",
    "district_id": "530717",
    "label": "Renggarasi",
    "value": "Renggarasi"
  },
  {
    "id": "5307172006",
    "district_id": "530717",
    "label": "Detubinga",
    "value": "Detubinga"
  },
  {
    "id": "5307172007",
    "district_id": "530717",
    "label": "Tuwa",
    "value": "Tuwa"
  },
  {
    "id": "5307172008",
    "district_id": "530717",
    "label": "Poma",
    "value": "Poma"
  },
  {
    "id": "5307182001",
    "district_id": "530718",
    "label": "Rubit",
    "value": "Rubit"
  },
  {
    "id": "5307182002",
    "district_id": "530718",
    "label": "Wolomapa",
    "value": "Wolomapa"
  },
  {
    "id": "5307182003",
    "district_id": "530718",
    "label": "Baomekot",
    "value": "Baomekot"
  },
  {
    "id": "5307182004",
    "district_id": "530718",
    "label": "Kajowair",
    "value": "Kajowair"
  },
  {
    "id": "5307182005",
    "district_id": "530718",
    "label": "Hewokloang",
    "value": "Hewokloang"
  },
  {
    "id": "5307182006",
    "district_id": "530718",
    "label": "Heopuat",
    "value": "Heopuat"
  },
  {
    "id": "5307182007",
    "district_id": "530718",
    "label": "Munerana",
    "value": "Munerana"
  },
  {
    "id": "5307192001",
    "district_id": "530719",
    "label": "Teka Iku",
    "value": "Teka Iku"
  },
  {
    "id": "5307192002",
    "district_id": "530719",
    "label": "Mekendetung",
    "value": "Mekendetung"
  },
  {
    "id": "5307192003",
    "district_id": "530719",
    "label": "Kokowahor",
    "value": "Kokowahor"
  },
  {
    "id": "5307192004",
    "district_id": "530719",
    "label": "Balatatatin",
    "value": "Balatatatin"
  },
  {
    "id": "5307192005",
    "district_id": "530719",
    "label": "Watuliwung",
    "value": "Watuliwung"
  },
  {
    "id": "5307192006",
    "district_id": "530719",
    "label": "Tana Duen",
    "value": "Tana Duen"
  },
  {
    "id": "5307192007",
    "district_id": "530719",
    "label": "Watumilok",
    "value": "Watumilok"
  },
  {
    "id": "5307192008",
    "district_id": "530719",
    "label": "Habi",
    "value": "Habi"
  },
  {
    "id": "5307192009",
    "district_id": "530719",
    "label": "Langir",
    "value": "Langir"
  },
  {
    "id": "5307202001",
    "district_id": "530720",
    "label": "Waihawa",
    "value": "Waihawa"
  },
  {
    "id": "5307202002",
    "district_id": "530720",
    "label": "Nenbura",
    "value": "Nenbura"
  },
  {
    "id": "5307202003",
    "district_id": "530720",
    "label": "Wogalirit",
    "value": "Wogalirit"
  },
  {
    "id": "5307202004",
    "district_id": "530720",
    "label": "Watumerak",
    "value": "Watumerak"
  },
  {
    "id": "5307202005",
    "district_id": "530720",
    "label": "Wolonterang",
    "value": "Wolonterang"
  },
  {
    "id": "5307202006",
    "district_id": "530720",
    "label": "Kloangpopot",
    "value": "Kloangpopot"
  },
  {
    "id": "5307202007",
    "district_id": "530720",
    "label": "Wolomotong",
    "value": "Wolomotong"
  },
  {
    "id": "5307212001",
    "district_id": "530721",
    "label": "Hale",
    "value": "Hale"
  },
  {
    "id": "5307212002",
    "district_id": "530721",
    "label": "Hebing",
    "value": "Hebing"
  },
  {
    "id": "5307212003",
    "district_id": "530721",
    "label": "Natakoli",
    "value": "Natakoli"
  },
  {
    "id": "5307212004",
    "district_id": "530721",
    "label": "Egon Gahar",
    "value": "Egon Gahar"
  },
  {
    "id": "5308011001",
    "district_id": "530801",
    "label": "Ndorurea",
    "value": "Ndorurea"
  },
  {
    "id": "5308012002",
    "district_id": "530801",
    "label": "Ondorea",
    "value": "Ondorea"
  },
  {
    "id": "5308012003",
    "district_id": "530801",
    "label": "Watumite",
    "value": "Watumite"
  },
  {
    "id": "5308012004",
    "district_id": "530801",
    "label": "Tendarea",
    "value": "Tendarea"
  },
  {
    "id": "5308012005",
    "district_id": "530801",
    "label": "Kerirea",
    "value": "Kerirea"
  },
  {
    "id": "5308012006",
    "district_id": "530801",
    "label": "Tendambepa",
    "value": "Tendambepa"
  },
  {
    "id": "5308012007",
    "district_id": "530801",
    "label": "Zozozea",
    "value": "Zozozea"
  },
  {
    "id": "5308012008",
    "district_id": "530801",
    "label": "Rapowawo",
    "value": "Rapowawo"
  },
  {
    "id": "5308012009",
    "district_id": "530801",
    "label": "Nggorea",
    "value": "Nggorea"
  },
  {
    "id": "5308012010",
    "district_id": "530801",
    "label": "Raporendu",
    "value": "Raporendu"
  },
  {
    "id": "5308012011",
    "district_id": "530801",
    "label": "Bheramari",
    "value": "Bheramari"
  },
  {
    "id": "5308012012",
    "district_id": "530801",
    "label": "Titwerea",
    "value": "Titwerea"
  },
  {
    "id": "5308012013",
    "district_id": "530801",
    "label": "Sanggarhorho",
    "value": "Sanggarhorho"
  },
  {
    "id": "5308012014",
    "district_id": "530801",
    "label": "Ndeturea",
    "value": "Ndeturea"
  },
  {
    "id": "5308012015",
    "district_id": "530801",
    "label": "Ndorurea I",
    "value": "Ndorurea I"
  },
  {
    "id": "5308012016",
    "district_id": "530801",
    "label": "Penggajawa",
    "value": "Penggajawa"
  },
  {
    "id": "5308012017",
    "district_id": "530801",
    "label": "Jegharangga",
    "value": "Jegharangga"
  },
  {
    "id": "5308012018",
    "district_id": "530801",
    "label": "Ondorea Barat",
    "value": "Ondorea Barat"
  },
  {
    "id": "5308012019",
    "district_id": "530801",
    "label": "Romarea",
    "value": "Romarea"
  },
  {
    "id": "5308012020",
    "district_id": "530801",
    "label": "Timbaria",
    "value": "Timbaria"
  },
  {
    "id": "5308012021",
    "district_id": "530801",
    "label": "Mbobhenga",
    "value": "Mbobhenga"
  },
  {
    "id": "5308012022",
    "district_id": "530801",
    "label": "Malawaru",
    "value": "Malawaru"
  },
  {
    "id": "5308012023",
    "district_id": "530801",
    "label": "Anaraja",
    "value": "Anaraja"
  },
  {
    "id": "5308012024",
    "district_id": "530801",
    "label": "Kekandere",
    "value": "Kekandere"
  },
  {
    "id": "5308012025",
    "district_id": "530801",
    "label": "Tanazozo",
    "value": "Tanazozo"
  },
  {
    "id": "5308012026",
    "district_id": "530801",
    "label": "Tenda Ondo",
    "value": "Tenda Ondo"
  },
  {
    "id": "5308012027",
    "district_id": "530801",
    "label": "Jemburea",
    "value": "Jemburea"
  },
  {
    "id": "5308012028",
    "district_id": "530801",
    "label": "Embuzozo",
    "value": "Embuzozo"
  },
  {
    "id": "5308012029",
    "district_id": "530801",
    "label": "Uzuzozo",
    "value": "Uzuzozo"
  },
  {
    "id": "5308022001",
    "district_id": "530802",
    "label": "Aejeti",
    "value": "Aejeti"
  },
  {
    "id": "5308022002",
    "district_id": "530802",
    "label": "Puutara",
    "value": "Puutara"
  },
  {
    "id": "5308022003",
    "district_id": "530802",
    "label": "Paderape",
    "value": "Paderape"
  },
  {
    "id": "5308022004",
    "district_id": "530802",
    "label": "Ndoriwoi",
    "value": "Ndoriwoi"
  },
  {
    "id": "5308022005",
    "district_id": "530802",
    "label": "Rorurangga",
    "value": "Rorurangga"
  },
  {
    "id": "5308022006",
    "district_id": "530802",
    "label": "Rendoraterua",
    "value": "Rendoraterua"
  },
  {
    "id": "5308022007",
    "district_id": "530802",
    "label": "Redorory",
    "value": "Redorory"
  },
  {
    "id": "5308022008",
    "district_id": "530802",
    "label": "Kazo Kapo",
    "value": "Kazo Kapo"
  },
  {
    "id": "5308022009",
    "district_id": "530802",
    "label": "Renga Menge",
    "value": "Renga Menge"
  },
  {
    "id": "5308032001",
    "district_id": "530803",
    "label": "Raburia",
    "value": "Raburia"
  },
  {
    "id": "5308032002",
    "district_id": "530803",
    "label": "Riaraja",
    "value": "Riaraja"
  },
  {
    "id": "5308032003",
    "district_id": "530803",
    "label": "Mbotutenda",
    "value": "Mbotutenda"
  },
  {
    "id": "5308032004",
    "district_id": "530803",
    "label": "Wologai",
    "value": "Wologai"
  },
  {
    "id": "5308032007",
    "district_id": "530803",
    "label": "Ndetundora I",
    "value": "Ndetundora I"
  },
  {
    "id": "5308032008",
    "district_id": "530803",
    "label": "Ndetundora II",
    "value": "Ndetundora II"
  },
  {
    "id": "5308032009",
    "district_id": "530803",
    "label": "Tomberabu I",
    "value": "Tomberabu I"
  },
  {
    "id": "5308032010",
    "district_id": "530803",
    "label": "Tomberabu II",
    "value": "Tomberabu II"
  },
  {
    "id": "5308032011",
    "district_id": "530803",
    "label": "Nakuramba",
    "value": "Nakuramba"
  },
  {
    "id": "5308032012",
    "district_id": "530803",
    "label": "Wolokaro",
    "value": "Wolokaro"
  },
  {
    "id": "5308032013",
    "district_id": "530803",
    "label": "Ja Moke Asa",
    "value": "Ja Moke Asa"
  },
  {
    "id": "5308032014",
    "district_id": "530803",
    "label": "Emburia",
    "value": "Emburia"
  },
  {
    "id": "5308032016",
    "district_id": "530803",
    "label": "Randotonda",
    "value": "Randotonda"
  },
  {
    "id": "5308032017",
    "district_id": "530803",
    "label": "Rukuramba",
    "value": "Rukuramba"
  },
  {
    "id": "5308032018",
    "district_id": "530803",
    "label": "Tinabani",
    "value": "Tinabani"
  },
  {
    "id": "5308032019",
    "district_id": "530803",
    "label": "Ndetundora III",
    "value": "Ndetundora III"
  },
  {
    "id": "5308032020",
    "district_id": "530803",
    "label": "Uzuramba",
    "value": "Uzuramba"
  },
  {
    "id": "5308032021",
    "district_id": "530803",
    "label": "Tonggopapa",
    "value": "Tonggopapa"
  },
  {
    "id": "5308032022",
    "district_id": "530803",
    "label": "Wologai Dua",
    "value": "Wologai Dua"
  },
  {
    "id": "5308032023",
    "district_id": "530803",
    "label": "Worhopapa",
    "value": "Worhopapa"
  },
  {
    "id": "5308032024",
    "district_id": "530803",
    "label": "Wawonato",
    "value": "Wawonato"
  },
  {
    "id": "5308032025",
    "district_id": "530803",
    "label": "Jejaraja",
    "value": "Jejaraja"
  },
  {
    "id": "5308032026",
    "district_id": "530803",
    "label": "Tendambonggi",
    "value": "Tendambonggi"
  },
  {
    "id": "5308032027",
    "district_id": "530803",
    "label": "Randorama",
    "value": "Randorama"
  },
  {
    "id": "5308032028",
    "district_id": "530803",
    "label": "Ranoramba",
    "value": "Ranoramba"
  },
  {
    "id": "5308032029",
    "district_id": "530803",
    "label": "Peozakaramba",
    "value": "Peozakaramba"
  },
  {
    "id": "5308032030",
    "district_id": "530803",
    "label": "Embutheru",
    "value": "Embutheru"
  },
  {
    "id": "5308032031",
    "district_id": "530803",
    "label": "Nuaja",
    "value": "Nuaja"
  },
  {
    "id": "5308032032",
    "district_id": "530803",
    "label": "Wajakea Jaya",
    "value": "Wajakea Jaya"
  },
  {
    "id": "5308032033",
    "district_id": "530803",
    "label": "Nemboramba",
    "value": "Nemboramba"
  },
  {
    "id": "5308032034",
    "district_id": "530803",
    "label": "Embu Ngena",
    "value": "Embu Ngena"
  },
  {
    "id": "5308032035",
    "district_id": "530803",
    "label": "Uzuramba Barat",
    "value": "Uzuramba Barat"
  },
  {
    "id": "5308041003",
    "district_id": "530804",
    "label": "Mbongawani",
    "value": "Mbongawani"
  },
  {
    "id": "5308041004",
    "district_id": "530804",
    "label": "Rukun Lima",
    "value": "Rukun Lima"
  },
  {
    "id": "5308041006",
    "district_id": "530804",
    "label": "Paupanda",
    "value": "Paupanda"
  },
  {
    "id": "5308041008",
    "district_id": "530804",
    "label": "Tetandara",
    "value": "Tetandara"
  },
  {
    "id": "5308041014",
    "district_id": "530804",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "5308051001",
    "district_id": "530805",
    "label": "Onelako",
    "value": "Onelako"
  },
  {
    "id": "5308051002",
    "district_id": "530805",
    "label": "Lokoboko",
    "value": "Lokoboko"
  },
  {
    "id": "5308052003",
    "district_id": "530805",
    "label": "Wolotopo",
    "value": "Wolotopo"
  },
  {
    "id": "5308052004",
    "district_id": "530805",
    "label": "Wolotolo Timur",
    "value": "Wolotolo Timur"
  },
  {
    "id": "5308052006",
    "district_id": "530805",
    "label": "Puutuga",
    "value": "Puutuga"
  },
  {
    "id": "5308052007",
    "district_id": "530805",
    "label": "Ngalupolo",
    "value": "Ngalupolo"
  },
  {
    "id": "5308052012",
    "district_id": "530805",
    "label": "Ngaluroga",
    "value": "Ngaluroga"
  },
  {
    "id": "5308052013",
    "district_id": "530805",
    "label": "Nila",
    "value": "Nila"
  },
  {
    "id": "5308052014",
    "district_id": "530805",
    "label": "Kekasewa",
    "value": "Kekasewa"
  },
  {
    "id": "5308052015",
    "district_id": "530805",
    "label": "Manulando",
    "value": "Manulando"
  },
  {
    "id": "5308052016",
    "district_id": "530805",
    "label": "Nanganesa",
    "value": "Nanganesa"
  },
  {
    "id": "5308052017",
    "district_id": "530805",
    "label": "Wolokota",
    "value": "Wolokota"
  },
  {
    "id": "5308052018",
    "district_id": "530805",
    "label": "Kelikiku",
    "value": "Kelikiku"
  },
  {
    "id": "5308052020",
    "district_id": "530805",
    "label": "Reka",
    "value": "Reka"
  },
  {
    "id": "5308061001",
    "district_id": "530806",
    "label": "Detusoko",
    "value": "Detusoko"
  },
  {
    "id": "5308062002",
    "district_id": "530806",
    "label": "Detusoko Barat",
    "value": "Detusoko Barat"
  },
  {
    "id": "5308062003",
    "district_id": "530806",
    "label": "Wolotolo",
    "value": "Wolotolo"
  },
  {
    "id": "5308062004",
    "district_id": "530806",
    "label": "Wolotolo Tengah",
    "value": "Wolotolo Tengah"
  },
  {
    "id": "5308062005",
    "district_id": "530806",
    "label": "Roa",
    "value": "Roa"
  },
  {
    "id": "5308062006",
    "district_id": "530806",
    "label": "Saga",
    "value": "Saga"
  },
  {
    "id": "5308062007",
    "district_id": "530806",
    "label": "Niowula",
    "value": "Niowula"
  },
  {
    "id": "5308062008",
    "district_id": "530806",
    "label": "Wolofeo",
    "value": "Wolofeo"
  },
  {
    "id": "5308062009",
    "district_id": "530806",
    "label": "Nuaone",
    "value": "Nuaone"
  },
  {
    "id": "5308062010",
    "district_id": "530806",
    "label": "Wologai",
    "value": "Wologai"
  },
  {
    "id": "5308062011",
    "district_id": "530806",
    "label": "Wologai Tengah",
    "value": "Wologai Tengah"
  },
  {
    "id": "5308062013",
    "district_id": "530806",
    "label": "Wolomage",
    "value": "Wolomage"
  },
  {
    "id": "5308062015",
    "district_id": "530806",
    "label": "Randoria",
    "value": "Randoria"
  },
  {
    "id": "5308062016",
    "district_id": "530806",
    "label": "Wolomasi",
    "value": "Wolomasi"
  },
  {
    "id": "5308062017",
    "district_id": "530806",
    "label": "Rateroru",
    "value": "Rateroru"
  },
  {
    "id": "5308062018",
    "district_id": "530806",
    "label": "Dile",
    "value": "Dile"
  },
  {
    "id": "5308062019",
    "district_id": "530806",
    "label": "Ndito",
    "value": "Ndito"
  },
  {
    "id": "5308062020",
    "district_id": "530806",
    "label": "Sipijena",
    "value": "Sipijena"
  },
  {
    "id": "5308062021",
    "district_id": "530806",
    "label": "Golulada",
    "value": "Golulada"
  },
  {
    "id": "5308062022",
    "district_id": "530806",
    "label": "Ranga",
    "value": "Ranga"
  },
  {
    "id": "5308062024",
    "district_id": "530806",
    "label": "Turunalu",
    "value": "Turunalu"
  },
  {
    "id": "5308072001",
    "district_id": "530807",
    "label": "Welamosa",
    "value": "Welamosa"
  },
  {
    "id": "5308072002",
    "district_id": "530807",
    "label": "Nuangenda",
    "value": "Nuangenda"
  },
  {
    "id": "5308072003",
    "district_id": "530807",
    "label": "Mautenda",
    "value": "Mautenda"
  },
  {
    "id": "5308072004",
    "district_id": "530807",
    "label": "Mautenda Barat",
    "value": "Mautenda Barat"
  },
  {
    "id": "5308072005",
    "district_id": "530807",
    "label": "Wewaria",
    "value": "Wewaria"
  },
  {
    "id": "5308072006",
    "district_id": "530807",
    "label": "Tanali",
    "value": "Tanali"
  },
  {
    "id": "5308072007",
    "district_id": "530807",
    "label": "Detubela",
    "value": "Detubela"
  },
  {
    "id": "5308072008",
    "district_id": "530807",
    "label": "Fataatu",
    "value": "Fataatu"
  },
  {
    "id": "5308072009",
    "district_id": "530807",
    "label": "Fataatu Timur",
    "value": "Fataatu Timur"
  },
  {
    "id": "5308072010",
    "district_id": "530807",
    "label": "Mukusaki",
    "value": "Mukusaki"
  },
  {
    "id": "5308072011",
    "district_id": "530807",
    "label": "Ekoae",
    "value": "Ekoae"
  },
  {
    "id": "5308072012",
    "district_id": "530807",
    "label": "Aemuri",
    "value": "Aemuri"
  },
  {
    "id": "5308072013",
    "district_id": "530807",
    "label": "Ratewati",
    "value": "Ratewati"
  },
  {
    "id": "5308072014",
    "district_id": "530807",
    "label": "Mbotulaka",
    "value": "Mbotulaka"
  },
  {
    "id": "5308072015",
    "district_id": "530807",
    "label": "Numba",
    "value": "Numba"
  },
  {
    "id": "5308072017",
    "district_id": "530807",
    "label": "Kalitembu",
    "value": "Kalitembu"
  },
  {
    "id": "5308072018",
    "district_id": "530807",
    "label": "Aelipo",
    "value": "Aelipo"
  },
  {
    "id": "5308072019",
    "district_id": "530807",
    "label": "Ratewati Selatan",
    "value": "Ratewati Selatan"
  },
  {
    "id": "5308072020",
    "district_id": "530807",
    "label": "Waka",
    "value": "Waka"
  },
  {
    "id": "5308072021",
    "district_id": "530807",
    "label": "Wolooja",
    "value": "Wolooja"
  },
  {
    "id": "5308072022",
    "district_id": "530807",
    "label": "Ekolea",
    "value": "Ekolea"
  },
  {
    "id": "5308072023",
    "district_id": "530807",
    "label": "Ae Ndoko",
    "value": "Ae Ndoko"
  },
  {
    "id": "5308081001",
    "district_id": "530808",
    "label": "Bokasape",
    "value": "Bokasape"
  },
  {
    "id": "5308082002",
    "district_id": "530808",
    "label": "Bokasape Timur",
    "value": "Bokasape Timur"
  },
  {
    "id": "5308082010",
    "district_id": "530808",
    "label": "Mbuliloo",
    "value": "Mbuliloo"
  },
  {
    "id": "5308082011",
    "district_id": "530808",
    "label": "Rindiwawo",
    "value": "Rindiwawo"
  },
  {
    "id": "5308082012",
    "district_id": "530808",
    "label": "Jopu",
    "value": "Jopu"
  },
  {
    "id": "5308082013",
    "district_id": "530808",
    "label": "Wolokoli",
    "value": "Wolokoli"
  },
  {
    "id": "5308082014",
    "district_id": "530808",
    "label": "Mbuliwaralau",
    "value": "Mbuliwaralau"
  },
  {
    "id": "5308082015",
    "district_id": "530808",
    "label": "Mbuliwaralau Utara",
    "value": "Mbuliwaralau Utara"
  },
  {
    "id": "5308082016",
    "district_id": "530808",
    "label": "Lisedetu",
    "value": "Lisedetu"
  },
  {
    "id": "5308082017",
    "district_id": "530808",
    "label": "Nualise",
    "value": "Nualise"
  },
  {
    "id": "5308082018",
    "district_id": "530808",
    "label": "Wolosoko",
    "value": "Wolosoko"
  },
  {
    "id": "5308082019",
    "district_id": "530808",
    "label": "Liselowobora",
    "value": "Liselowobora"
  },
  {
    "id": "5308082020",
    "district_id": "530808",
    "label": "Likanaka",
    "value": "Likanaka"
  },
  {
    "id": "5308082021",
    "district_id": "530808",
    "label": "Nakambara",
    "value": "Nakambara"
  },
  {
    "id": "5308082022",
    "district_id": "530808",
    "label": "Niramesi",
    "value": "Niramesi"
  },
  {
    "id": "5308082023",
    "district_id": "530808",
    "label": "Tana Lo'o",
    "value": "Tana Lo'o"
  },
  {
    "id": "5308082024",
    "district_id": "530808",
    "label": "Lise Pu'u",
    "value": "Lise Pu'u"
  },
  {
    "id": "5308091001",
    "district_id": "530809",
    "label": "Wolojita",
    "value": "Wolojita"
  },
  {
    "id": "5308092002",
    "district_id": "530809",
    "label": "Tenda",
    "value": "Tenda"
  },
  {
    "id": "5308092003",
    "district_id": "530809",
    "label": "Wiwipemo",
    "value": "Wiwipemo"
  },
  {
    "id": "5308092004",
    "district_id": "530809",
    "label": "Pora",
    "value": "Pora"
  },
  {
    "id": "5308092005",
    "district_id": "530809",
    "label": "Nuamulu",
    "value": "Nuamulu"
  },
  {
    "id": "5308092006",
    "district_id": "530809",
    "label": "Nggela",
    "value": "Nggela"
  },
  {
    "id": "5308102001",
    "district_id": "530810",
    "label": "Maurole",
    "value": "Maurole"
  },
  {
    "id": "5308102003",
    "district_id": "530810",
    "label": "Aewora",
    "value": "Aewora"
  },
  {
    "id": "5308102005",
    "district_id": "530810",
    "label": "Mausambi",
    "value": "Mausambi"
  },
  {
    "id": "5308102006",
    "district_id": "530810",
    "label": "Ranakolo",
    "value": "Ranakolo"
  },
  {
    "id": "5308102007",
    "district_id": "530810",
    "label": "Watukamba",
    "value": "Watukamba"
  },
  {
    "id": "5308102008",
    "district_id": "530810",
    "label": "Ranakolo Selatan",
    "value": "Ranakolo Selatan"
  },
  {
    "id": "5308102009",
    "district_id": "530810",
    "label": "Keliwumbu",
    "value": "Keliwumbu"
  },
  {
    "id": "5308102018",
    "district_id": "530810",
    "label": "Detuwulu",
    "value": "Detuwulu"
  },
  {
    "id": "5308102019",
    "district_id": "530810",
    "label": "Otogedu",
    "value": "Otogedu"
  },
  {
    "id": "5308102020",
    "district_id": "530810",
    "label": "Woloau",
    "value": "Woloau"
  },
  {
    "id": "5308102021",
    "district_id": "530810",
    "label": "Ngalukoja",
    "value": "Ngalukoja"
  },
  {
    "id": "5308102022",
    "district_id": "530810",
    "label": "Niranusa",
    "value": "Niranusa"
  },
  {
    "id": "5308102023",
    "district_id": "530810",
    "label": "Uludala",
    "value": "Uludala"
  },
  {
    "id": "5308112001",
    "district_id": "530811",
    "label": "Kebirangga",
    "value": "Kebirangga"
  },
  {
    "id": "5308112002",
    "district_id": "530811",
    "label": "Kebirangga Selatan",
    "value": "Kebirangga Selatan"
  },
  {
    "id": "5308112003",
    "district_id": "530811",
    "label": "Kamubheka",
    "value": "Kamubheka"
  },
  {
    "id": "5308112004",
    "district_id": "530811",
    "label": "Magekapa",
    "value": "Magekapa"
  },
  {
    "id": "5308112005",
    "district_id": "530811",
    "label": "Boafeo",
    "value": "Boafeo"
  },
  {
    "id": "5308112006",
    "district_id": "530811",
    "label": "Nabe",
    "value": "Nabe"
  },
  {
    "id": "5308112007",
    "district_id": "530811",
    "label": "Kobaleba",
    "value": "Kobaleba"
  },
  {
    "id": "5308112008",
    "district_id": "530811",
    "label": "Mundinggasa",
    "value": "Mundinggasa"
  },
  {
    "id": "5308112009",
    "district_id": "530811",
    "label": "Kolikapa",
    "value": "Kolikapa"
  },
  {
    "id": "5308112010",
    "district_id": "530811",
    "label": "Kebirangga Tengah",
    "value": "Kebirangga Tengah"
  },
  {
    "id": "5308112011",
    "district_id": "530811",
    "label": "Natanangge",
    "value": "Natanangge"
  },
  {
    "id": "5308121001",
    "district_id": "530812",
    "label": "Watuneso",
    "value": "Watuneso"
  },
  {
    "id": "5308122002",
    "district_id": "530812",
    "label": "Hobatua",
    "value": "Hobatua"
  },
  {
    "id": "5308122003",
    "district_id": "530812",
    "label": "Wolosambi",
    "value": "Wolosambi"
  },
  {
    "id": "5308122004",
    "district_id": "530812",
    "label": "Woloara",
    "value": "Woloara"
  },
  {
    "id": "5308122005",
    "district_id": "530812",
    "label": "Detupera",
    "value": "Detupera"
  },
  {
    "id": "5308122006",
    "district_id": "530812",
    "label": "Wololela A",
    "value": "Wololela A"
  },
  {
    "id": "5308122007",
    "district_id": "530812",
    "label": "Liabeke",
    "value": "Liabeke"
  },
  {
    "id": "5308122013",
    "district_id": "530812",
    "label": "Fatamari",
    "value": "Fatamari"
  },
  {
    "id": "5308122014",
    "district_id": "530812",
    "label": "Ranggatalo",
    "value": "Ranggatalo"
  },
  {
    "id": "5308122015",
    "district_id": "530812",
    "label": "Nua Lima",
    "value": "Nua Lima"
  },
  {
    "id": "5308122016",
    "district_id": "530812",
    "label": "Tanaroga",
    "value": "Tanaroga"
  },
  {
    "id": "5308122017",
    "district_id": "530812",
    "label": "Bu Tanalagu",
    "value": "Bu Tanalagu"
  },
  {
    "id": "5308122018",
    "district_id": "530812",
    "label": "Mbewawora",
    "value": "Mbewawora"
  },
  {
    "id": "5308132001",
    "district_id": "530813",
    "label": "Kotabaru",
    "value": "Kotabaru"
  },
  {
    "id": "5308132002",
    "district_id": "530813",
    "label": "Tou",
    "value": "Tou"
  },
  {
    "id": "5308132003",
    "district_id": "530813",
    "label": "Tou Timur",
    "value": "Tou Timur"
  },
  {
    "id": "5308132004",
    "district_id": "530813",
    "label": "Loboniki",
    "value": "Loboniki"
  },
  {
    "id": "5308132005",
    "district_id": "530813",
    "label": "Ndondo",
    "value": "Ndondo"
  },
  {
    "id": "5308132006",
    "district_id": "530813",
    "label": "Niopanda",
    "value": "Niopanda"
  },
  {
    "id": "5308132007",
    "district_id": "530813",
    "label": "Hangalande",
    "value": "Hangalande"
  },
  {
    "id": "5308132012",
    "district_id": "530813",
    "label": "Tou Barat",
    "value": "Tou Barat"
  },
  {
    "id": "5308132013",
    "district_id": "530813",
    "label": "Liselande",
    "value": "Liselande"
  },
  {
    "id": "5308132014",
    "district_id": "530813",
    "label": "Rangalaka",
    "value": "Rangalaka"
  },
  {
    "id": "5308132015",
    "district_id": "530813",
    "label": "Pise",
    "value": "Pise"
  },
  {
    "id": "5308132016",
    "district_id": "530813",
    "label": "Neotonda",
    "value": "Neotonda"
  },
  {
    "id": "5308132017",
    "district_id": "530813",
    "label": "Nuanaga",
    "value": "Nuanaga"
  },
  {
    "id": "5308142002",
    "district_id": "530814",
    "label": "Nduaria",
    "value": "Nduaria"
  },
  {
    "id": "5308142003",
    "district_id": "530814",
    "label": "Nuamuri",
    "value": "Nuamuri"
  },
  {
    "id": "5308142004",
    "district_id": "530814",
    "label": "Nuamuri Barat",
    "value": "Nuamuri Barat"
  },
  {
    "id": "5308142005",
    "district_id": "530814",
    "label": "Koanara",
    "value": "Koanara"
  },
  {
    "id": "5308142006",
    "district_id": "530814",
    "label": "Woloara",
    "value": "Woloara"
  },
  {
    "id": "5308142007",
    "district_id": "530814",
    "label": "Pemo",
    "value": "Pemo"
  },
  {
    "id": "5308142009",
    "district_id": "530814",
    "label": "Detuena",
    "value": "Detuena"
  },
  {
    "id": "5308142010",
    "district_id": "530814",
    "label": "Wolokelo",
    "value": "Wolokelo"
  },
  {
    "id": "5308142011",
    "district_id": "530814",
    "label": "Waturaka",
    "value": "Waturaka"
  },
  {
    "id": "5308152001",
    "district_id": "530815",
    "label": "Nggesa",
    "value": "Nggesa"
  },
  {
    "id": "5308152002",
    "district_id": "530815",
    "label": "Detukeli",
    "value": "Detukeli"
  },
  {
    "id": "5308152003",
    "district_id": "530815",
    "label": "Kebesani",
    "value": "Kebesani"
  },
  {
    "id": "5308152004",
    "district_id": "530815",
    "label": "Wolomuku",
    "value": "Wolomuku"
  },
  {
    "id": "5308152005",
    "district_id": "530815",
    "label": "Watunggere",
    "value": "Watunggere"
  },
  {
    "id": "5308152006",
    "district_id": "530815",
    "label": "Nida",
    "value": "Nida"
  },
  {
    "id": "5308152007",
    "district_id": "530815",
    "label": "Maurole Selatan",
    "value": "Maurole Selatan"
  },
  {
    "id": "5308152008",
    "district_id": "530815",
    "label": "Detumbewa",
    "value": "Detumbewa"
  },
  {
    "id": "5308152009",
    "district_id": "530815",
    "label": "Kanganara",
    "value": "Kanganara"
  },
  {
    "id": "5308152011",
    "district_id": "530815",
    "label": "Unggu",
    "value": "Unggu"
  },
  {
    "id": "5308152012",
    "district_id": "530815",
    "label": "Nggesabiri",
    "value": "Nggesabiri"
  },
  {
    "id": "5308152013",
    "district_id": "530815",
    "label": "Watunggere Marilonga",
    "value": "Watunggere Marilonga"
  },
  {
    "id": "5308152014",
    "district_id": "530815",
    "label": "Jeo Du'a",
    "value": "Jeo Du'a"
  },
  {
    "id": "5308162001",
    "district_id": "530816",
    "label": "Kurulimbu",
    "value": "Kurulimbu"
  },
  {
    "id": "5308162002",
    "district_id": "530816",
    "label": "Sokoria",
    "value": "Sokoria"
  },
  {
    "id": "5308162003",
    "district_id": "530816",
    "label": "Demulaka",
    "value": "Demulaka"
  },
  {
    "id": "5308162004",
    "district_id": "530816",
    "label": "Roga",
    "value": "Roga"
  },
  {
    "id": "5308162005",
    "district_id": "530816",
    "label": "Kurulimbu Selatan",
    "value": "Kurulimbu Selatan"
  },
  {
    "id": "5308162006",
    "district_id": "530816",
    "label": "Ngguwa",
    "value": "Ngguwa"
  },
  {
    "id": "5308162007",
    "district_id": "530816",
    "label": "Sokoria Selatan",
    "value": "Sokoria Selatan"
  },
  {
    "id": "5308172001",
    "district_id": "530817",
    "label": "Maubasa",
    "value": "Maubasa"
  },
  {
    "id": "5308172002",
    "district_id": "530817",
    "label": "Maubasa Timur",
    "value": "Maubasa Timur"
  },
  {
    "id": "5308172003",
    "district_id": "530817",
    "label": "Wonda",
    "value": "Wonda"
  },
  {
    "id": "5308172004",
    "district_id": "530817",
    "label": "Serandori",
    "value": "Serandori"
  },
  {
    "id": "5308172005",
    "district_id": "530817",
    "label": "Kelisamba",
    "value": "Kelisamba"
  },
  {
    "id": "5308172006",
    "district_id": "530817",
    "label": "Mole",
    "value": "Mole"
  },
  {
    "id": "5308172007",
    "district_id": "530817",
    "label": "Lungaria",
    "value": "Lungaria"
  },
  {
    "id": "5308172008",
    "district_id": "530817",
    "label": "Aebara",
    "value": "Aebara"
  },
  {
    "id": "5308172009",
    "district_id": "530817",
    "label": "Ratemangga",
    "value": "Ratemangga"
  },
  {
    "id": "5308172010",
    "district_id": "530817",
    "label": "Maubasa Barat",
    "value": "Maubasa Barat"
  },
  {
    "id": "5308181001",
    "district_id": "530818",
    "label": "Kota Raja",
    "value": "Kota Raja"
  },
  {
    "id": "5308181002",
    "district_id": "530818",
    "label": "Kota Ratu",
    "value": "Kota Ratu"
  },
  {
    "id": "5308181003",
    "district_id": "530818",
    "label": "Roworena",
    "value": "Roworena"
  },
  {
    "id": "5308181007",
    "district_id": "530818",
    "label": "Roworena Barat",
    "value": "Roworena Barat"
  },
  {
    "id": "5308182004",
    "district_id": "530818",
    "label": "Gheoghoma",
    "value": "Gheoghoma"
  },
  {
    "id": "5308182005",
    "district_id": "530818",
    "label": "Borokanda",
    "value": "Borokanda"
  },
  {
    "id": "5308182006",
    "district_id": "530818",
    "label": "Watusipi",
    "value": "Watusipi"
  },
  {
    "id": "5308182008",
    "district_id": "530818",
    "label": "Mbomba",
    "value": "Mbomba"
  },
  {
    "id": "5308182009",
    "district_id": "530818",
    "label": "Embundoa",
    "value": "Embundoa"
  },
  {
    "id": "5308182010",
    "district_id": "530818",
    "label": "Raterua",
    "value": "Raterua"
  },
  {
    "id": "5308191001",
    "district_id": "530819",
    "label": "Potulando",
    "value": "Potulando"
  },
  {
    "id": "5308191002",
    "district_id": "530819",
    "label": "Onekore",
    "value": "Onekore"
  },
  {
    "id": "5308191003",
    "district_id": "530819",
    "label": "Kelimutu",
    "value": "Kelimutu"
  },
  {
    "id": "5308191004",
    "district_id": "530819",
    "label": "Paupire",
    "value": "Paupire"
  },
  {
    "id": "5308201001",
    "district_id": "530820",
    "label": "Mautapaga",
    "value": "Mautapaga"
  },
  {
    "id": "5308201002",
    "district_id": "530820",
    "label": "Rewarangga",
    "value": "Rewarangga"
  },
  {
    "id": "5308201006",
    "district_id": "530820",
    "label": "Rewarangga Selatan",
    "value": "Rewarangga Selatan"
  },
  {
    "id": "5308202003",
    "district_id": "530820",
    "label": "Kedebodu",
    "value": "Kedebodu"
  },
  {
    "id": "5308202004",
    "district_id": "530820",
    "label": "Ndungga",
    "value": "Ndungga"
  },
  {
    "id": "5308202005",
    "district_id": "530820",
    "label": "Tiwutewa",
    "value": "Tiwutewa"
  },
  {
    "id": "5308212001",
    "district_id": "530821",
    "label": "Nggumbelaka",
    "value": "Nggumbelaka"
  },
  {
    "id": "5308212002",
    "district_id": "530821",
    "label": "Mukureku",
    "value": "Mukureku"
  },
  {
    "id": "5308212003",
    "district_id": "530821",
    "label": "Wologai Timur",
    "value": "Wologai Timur"
  },
  {
    "id": "5308212004",
    "district_id": "530821",
    "label": "Kuru",
    "value": "Kuru"
  },
  {
    "id": "5308212005",
    "district_id": "530821",
    "label": "Ndenggarongge",
    "value": "Ndenggarongge"
  },
  {
    "id": "5308212006",
    "district_id": "530821",
    "label": "Ndikosapu",
    "value": "Ndikosapu"
  },
  {
    "id": "5308212007",
    "district_id": "530821",
    "label": "Taniwoda",
    "value": "Taniwoda"
  },
  {
    "id": "5308212008",
    "district_id": "530821",
    "label": "Tanalangi",
    "value": "Tanalangi"
  },
  {
    "id": "5308212009",
    "district_id": "530821",
    "label": "Detuara",
    "value": "Detuara"
  },
  {
    "id": "5308212010",
    "district_id": "530821",
    "label": "Tiwusora",
    "value": "Tiwusora"
  },
  {
    "id": "5308212011",
    "district_id": "530821",
    "label": "Lisekuru",
    "value": "Lisekuru"
  },
  {
    "id": "5308212012",
    "district_id": "530821",
    "label": "Rutujeja",
    "value": "Rutujeja"
  },
  {
    "id": "5308212013",
    "district_id": "530821",
    "label": "Mukureku Sa'ate",
    "value": "Mukureku Sa'ate"
  },
  {
    "id": "5308212014",
    "district_id": "530821",
    "label": "Kurusare",
    "value": "Kurusare"
  },
  {
    "id": "5309011002",
    "district_id": "530901",
    "label": "Aimere",
    "value": "Aimere"
  },
  {
    "id": "5309011003",
    "district_id": "530901",
    "label": "Foa",
    "value": "Foa"
  },
  {
    "id": "5309012001",
    "district_id": "530901",
    "label": "Keligejo",
    "value": "Keligejo"
  },
  {
    "id": "5309012014",
    "district_id": "530901",
    "label": "Aimere Timur",
    "value": "Aimere Timur"
  },
  {
    "id": "5309012018",
    "district_id": "530901",
    "label": "Heawea",
    "value": "Heawea"
  },
  {
    "id": "5309012019",
    "district_id": "530901",
    "label": "Legelapu",
    "value": "Legelapu"
  },
  {
    "id": "5309012020",
    "district_id": "530901",
    "label": "Lekogoko",
    "value": "Lekogoko"
  },
  {
    "id": "5309012021",
    "district_id": "530901",
    "label": "Binawali",
    "value": "Binawali"
  },
  {
    "id": "5309012022",
    "district_id": "530901",
    "label": "Kila",
    "value": "Kila"
  },
  {
    "id": "5309012023",
    "district_id": "530901",
    "label": "Waesae",
    "value": "Waesae"
  },
  {
    "id": "5309021008",
    "district_id": "530902",
    "label": "Todabelu",
    "value": "Todabelu"
  },
  {
    "id": "5309021017",
    "district_id": "530902",
    "label": "Mataloko",
    "value": "Mataloko"
  },
  {
    "id": "5309022002",
    "district_id": "530902",
    "label": "Were I",
    "value": "Were I"
  },
  {
    "id": "5309022009",
    "district_id": "530902",
    "label": "Sangadeto",
    "value": "Sangadeto"
  },
  {
    "id": "5309022010",
    "district_id": "530902",
    "label": "Ratogesa",
    "value": "Ratogesa"
  },
  {
    "id": "5309022011",
    "district_id": "530902",
    "label": "Radabata",
    "value": "Radabata"
  },
  {
    "id": "5309022018",
    "district_id": "530902",
    "label": "Dadawea",
    "value": "Dadawea"
  },
  {
    "id": "5309022019",
    "district_id": "530902",
    "label": "Sarasedu",
    "value": "Sarasedu"
  },
  {
    "id": "5309022020",
    "district_id": "530902",
    "label": "Malanuza",
    "value": "Malanuza"
  },
  {
    "id": "5309022025",
    "district_id": "530902",
    "label": "Were",
    "value": "Were"
  },
  {
    "id": "5309022026",
    "district_id": "530902",
    "label": "Were IV",
    "value": "Were IV"
  },
  {
    "id": "5309022031",
    "district_id": "530902",
    "label": "Malanuza Satu",
    "value": "Malanuza Satu"
  },
  {
    "id": "5309022032",
    "district_id": "530902",
    "label": "Sarasedu Satu",
    "value": "Sarasedu Satu"
  },
  {
    "id": "5309022036",
    "district_id": "530902",
    "label": "Ulu Belu",
    "value": "Ulu Belu"
  },
  {
    "id": "5309022037",
    "district_id": "530902",
    "label": "Ekoroka",
    "value": "Ekoroka"
  },
  {
    "id": "5309022038",
    "district_id": "530902",
    "label": "Wae La",
    "value": "Wae La"
  },
  {
    "id": "5309061013",
    "district_id": "530906",
    "label": "Bajawa",
    "value": "Bajawa"
  },
  {
    "id": "5309061014",
    "district_id": "530906",
    "label": "Tanalodu",
    "value": "Tanalodu"
  },
  {
    "id": "5309061015",
    "district_id": "530906",
    "label": "Kisanata",
    "value": "Kisanata"
  },
  {
    "id": "5309061016",
    "district_id": "530906",
    "label": "Jawameze",
    "value": "Jawameze"
  },
  {
    "id": "5309061017",
    "district_id": "530906",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "5309061018",
    "district_id": "530906",
    "label": "Ngedukelu",
    "value": "Ngedukelu"
  },
  {
    "id": "5309061019",
    "district_id": "530906",
    "label": "Lebijaga",
    "value": "Lebijaga"
  },
  {
    "id": "5309061020",
    "district_id": "530906",
    "label": "Faobata",
    "value": "Faobata"
  },
  {
    "id": "5309061021",
    "district_id": "530906",
    "label": "Susu",
    "value": "Susu"
  },
  {
    "id": "5309062001",
    "district_id": "530906",
    "label": "Beja",
    "value": "Beja"
  },
  {
    "id": "5309062002",
    "district_id": "530906",
    "label": "Bomari",
    "value": "Bomari"
  },
  {
    "id": "5309062003",
    "district_id": "530906",
    "label": "Wawowae",
    "value": "Wawowae"
  },
  {
    "id": "5309062004",
    "district_id": "530906",
    "label": "Ubedolumolo",
    "value": "Ubedolumolo"
  },
  {
    "id": "5309062005",
    "district_id": "530906",
    "label": "Naru",
    "value": "Naru"
  },
  {
    "id": "5309062009",
    "district_id": "530906",
    "label": "Beiwali",
    "value": "Beiwali"
  },
  {
    "id": "5309062022",
    "district_id": "530906",
    "label": "Borani",
    "value": "Borani"
  },
  {
    "id": "5309062023",
    "district_id": "530906",
    "label": "Langagedha",
    "value": "Langagedha"
  },
  {
    "id": "5309062024",
    "district_id": "530906",
    "label": "Pape",
    "value": "Pape"
  },
  {
    "id": "5309062025",
    "district_id": "530906",
    "label": "Bowali",
    "value": "Bowali"
  },
  {
    "id": "5309062026",
    "district_id": "530906",
    "label": "Ngoranale",
    "value": "Ngoranale"
  },
  {
    "id": "5309062027",
    "district_id": "530906",
    "label": "Ubedolumolo Satu",
    "value": "Ubedolumolo Satu"
  },
  {
    "id": "5309062028",
    "district_id": "530906",
    "label": "Bela",
    "value": "Bela"
  },
  {
    "id": "5309072001",
    "district_id": "530907",
    "label": "Waepana",
    "value": "Waepana"
  },
  {
    "id": "5309072004",
    "district_id": "530907",
    "label": "Masumeli",
    "value": "Masumeli"
  },
  {
    "id": "5309072005",
    "district_id": "530907",
    "label": "Piga",
    "value": "Piga"
  },
  {
    "id": "5309072006",
    "district_id": "530907",
    "label": "Loa",
    "value": "Loa"
  },
  {
    "id": "5309072007",
    "district_id": "530907",
    "label": "Tarawaja",
    "value": "Tarawaja"
  },
  {
    "id": "5309072008",
    "district_id": "530907",
    "label": "Seso",
    "value": "Seso"
  },
  {
    "id": "5309072010",
    "district_id": "530907",
    "label": "Mangeruda",
    "value": "Mangeruda"
  },
  {
    "id": "5309072011",
    "district_id": "530907",
    "label": "Masu Kedhi",
    "value": "Masu Kedhi"
  },
  {
    "id": "5309072012",
    "district_id": "530907",
    "label": "Libunio",
    "value": "Libunio"
  },
  {
    "id": "5309072013",
    "district_id": "530907",
    "label": "Meli Waru",
    "value": "Meli Waru"
  },
  {
    "id": "5309072014",
    "district_id": "530907",
    "label": "Ngabheo",
    "value": "Ngabheo"
  },
  {
    "id": "5309072016",
    "district_id": "530907",
    "label": "Bogoboa",
    "value": "Bogoboa"
  },
  {
    "id": "5309072017",
    "district_id": "530907",
    "label": "Piga Satu",
    "value": "Piga Satu"
  },
  {
    "id": "5309072018",
    "district_id": "530907",
    "label": "Tarawali",
    "value": "Tarawali"
  },
  {
    "id": "5309091010",
    "district_id": "530909",
    "label": "Benteng Tengah",
    "value": "Benteng Tengah"
  },
  {
    "id": "5309091011",
    "district_id": "530909",
    "label": "Nangamese",
    "value": "Nangamese"
  },
  {
    "id": "5309092005",
    "district_id": "530909",
    "label": "Taenterong",
    "value": "Taenterong"
  },
  {
    "id": "5309092006",
    "district_id": "530909",
    "label": "Wangka",
    "value": "Wangka"
  },
  {
    "id": "5309092008",
    "district_id": "530909",
    "label": "Lengkosambi",
    "value": "Lengkosambi"
  },
  {
    "id": "5309092009",
    "district_id": "530909",
    "label": "Tadho",
    "value": "Tadho"
  },
  {
    "id": "5309092012",
    "district_id": "530909",
    "label": "Sambinasi",
    "value": "Sambinasi"
  },
  {
    "id": "5309092013",
    "district_id": "530909",
    "label": "Latung",
    "value": "Latung"
  },
  {
    "id": "5309092014",
    "district_id": "530909",
    "label": "Rawangkalo",
    "value": "Rawangkalo"
  },
  {
    "id": "5309092016",
    "district_id": "530909",
    "label": "Wangka Selatan",
    "value": "Wangka Selatan"
  },
  {
    "id": "5309092017",
    "district_id": "530909",
    "label": "Lengkosambi Timur",
    "value": "Lengkosambi Timur"
  },
  {
    "id": "5309092018",
    "district_id": "530909",
    "label": "Lengkosambi Barat",
    "value": "Lengkosambi Barat"
  },
  {
    "id": "5309092019",
    "district_id": "530909",
    "label": "Sambinasi Barat",
    "value": "Sambinasi Barat"
  },
  {
    "id": "5309092020",
    "district_id": "530909",
    "label": "Lengkosambi Utara",
    "value": "Lengkosambi Utara"
  },
  {
    "id": "5309092021",
    "district_id": "530909",
    "label": "Taen Terong Satu",
    "value": "Taen Terong Satu"
  },
  {
    "id": "5309092022",
    "district_id": "530909",
    "label": "Taen Terong Dua",
    "value": "Taen Terong Dua"
  },
  {
    "id": "5309122001",
    "district_id": "530912",
    "label": "Watumanu",
    "value": "Watumanu"
  },
  {
    "id": "5309122002",
    "district_id": "530912",
    "label": "Tiworiwu",
    "value": "Tiworiwu"
  },
  {
    "id": "5309122003",
    "district_id": "530912",
    "label": "Dariwali",
    "value": "Dariwali"
  },
  {
    "id": "5309122004",
    "district_id": "530912",
    "label": "Naruwolo",
    "value": "Naruwolo"
  },
  {
    "id": "5309122005",
    "district_id": "530912",
    "label": "Nenowea",
    "value": "Nenowea"
  },
  {
    "id": "5309122007",
    "district_id": "530912",
    "label": "Naruwolo I",
    "value": "Naruwolo I"
  },
  {
    "id": "5309122008",
    "district_id": "530912",
    "label": "Naruwolo II",
    "value": "Naruwolo II"
  },
  {
    "id": "5309122009",
    "district_id": "530912",
    "label": "Dariwali I",
    "value": "Dariwali I"
  },
  {
    "id": "5309122010",
    "district_id": "530912",
    "label": "Nio Lewa",
    "value": "Nio Lewa"
  },
  {
    "id": "5309122011",
    "district_id": "530912",
    "label": "Tiworiwu I",
    "value": "Tiworiwu I"
  },
  {
    "id": "5309122012",
    "district_id": "530912",
    "label": "Tiworiwu II",
    "value": "Tiworiwu II"
  },
  {
    "id": "5309122015",
    "district_id": "530912",
    "label": "Batajawa",
    "value": "Batajawa"
  },
  {
    "id": "5309142001",
    "district_id": "530914",
    "label": "Benteng Tawa",
    "value": "Benteng Tawa"
  },
  {
    "id": "5309142002",
    "district_id": "530914",
    "label": "Lanamai",
    "value": "Lanamai"
  },
  {
    "id": "5309142003",
    "district_id": "530914",
    "label": "Wolomeze",
    "value": "Wolomeze"
  },
  {
    "id": "5309142004",
    "district_id": "530914",
    "label": "Ria",
    "value": "Ria"
  },
  {
    "id": "5309142005",
    "district_id": "530914",
    "label": "Ngara",
    "value": "Ngara"
  },
  {
    "id": "5309142006",
    "district_id": "530914",
    "label": "Ria I",
    "value": "Ria I"
  },
  {
    "id": "5309142007",
    "district_id": "530914",
    "label": "Wolomeze I",
    "value": "Wolomeze I"
  },
  {
    "id": "5309142008",
    "district_id": "530914",
    "label": "Benteng Tawa I",
    "value": "Benteng Tawa I"
  },
  {
    "id": "5309142009",
    "district_id": "530914",
    "label": "Lanamai I",
    "value": "Lanamai I"
  },
  {
    "id": "5309142010",
    "district_id": "530914",
    "label": "Wolomese Dua",
    "value": "Wolomese Dua"
  },
  {
    "id": "5309152001",
    "district_id": "530915",
    "label": "Uluwae",
    "value": "Uluwae"
  },
  {
    "id": "5309152002",
    "district_id": "530915",
    "label": "Inelika",
    "value": "Inelika"
  },
  {
    "id": "5309152003",
    "district_id": "530915",
    "label": "Inegena",
    "value": "Inegena"
  },
  {
    "id": "5309152004",
    "district_id": "530915",
    "label": "Wololika",
    "value": "Wololika"
  },
  {
    "id": "5309152005",
    "district_id": "530915",
    "label": "Watukapu",
    "value": "Watukapu"
  },
  {
    "id": "5309152006",
    "district_id": "530915",
    "label": "Waewea",
    "value": "Waewea"
  },
  {
    "id": "5309152007",
    "district_id": "530915",
    "label": "Nabelena",
    "value": "Nabelena"
  },
  {
    "id": "5309152008",
    "district_id": "530915",
    "label": "Genamere",
    "value": "Genamere"
  },
  {
    "id": "5309152009",
    "district_id": "530915",
    "label": "Uluwae I",
    "value": "Uluwae I"
  },
  {
    "id": "5309152010",
    "district_id": "530915",
    "label": "Uluwae II",
    "value": "Uluwae II"
  },
  {
    "id": "5309152011",
    "district_id": "530915",
    "label": "Tura Muri",
    "value": "Tura Muri"
  },
  {
    "id": "5309162001",
    "district_id": "530916",
    "label": "Nginamanu",
    "value": "Nginamanu"
  },
  {
    "id": "5309162002",
    "district_id": "530916",
    "label": "Denatana",
    "value": "Denatana"
  },
  {
    "id": "5309162003",
    "district_id": "530916",
    "label": "Mainai",
    "value": "Mainai"
  },
  {
    "id": "5309162004",
    "district_id": "530916",
    "label": "Turaloa",
    "value": "Turaloa"
  },
  {
    "id": "5309162005",
    "district_id": "530916",
    "label": "Nginamanu Selatan",
    "value": "Nginamanu Selatan"
  },
  {
    "id": "5309162006",
    "district_id": "530916",
    "label": "Nginamanu Barat",
    "value": "Nginamanu Barat"
  },
  {
    "id": "5309162007",
    "district_id": "530916",
    "label": "Wue",
    "value": "Wue"
  },
  {
    "id": "5309162008",
    "district_id": "530916",
    "label": "Denatana Timur",
    "value": "Denatana Timur"
  },
  {
    "id": "5309182001",
    "district_id": "530918",
    "label": "Bawarani",
    "value": "Bawarani"
  },
  {
    "id": "5309182002",
    "district_id": "530918",
    "label": "Wogowela",
    "value": "Wogowela"
  },
  {
    "id": "5309182003",
    "district_id": "530918",
    "label": "Watu Sipi",
    "value": "Watu Sipi"
  },
  {
    "id": "5309182004",
    "district_id": "530918",
    "label": "Boba",
    "value": "Boba"
  },
  {
    "id": "5309182005",
    "district_id": "530918",
    "label": "Boba Satu",
    "value": "Boba Satu"
  },
  {
    "id": "5309182006",
    "district_id": "530918",
    "label": "Were Tiga",
    "value": "Were Tiga"
  },
  {
    "id": "5309182007",
    "district_id": "530918",
    "label": "Kezewea",
    "value": "Kezewea"
  },
  {
    "id": "5309182008",
    "district_id": "530918",
    "label": "Nirmala",
    "value": "Nirmala"
  },
  {
    "id": "5309182009",
    "district_id": "530918",
    "label": "Sadha",
    "value": "Sadha"
  },
  {
    "id": "5309182010",
    "district_id": "530918",
    "label": "Takatunga",
    "value": "Takatunga"
  },
  {
    "id": "5309182011",
    "district_id": "530918",
    "label": "Radamasa",
    "value": "Radamasa"
  },
  {
    "id": "5309182012",
    "district_id": "530918",
    "label": "Were Dua",
    "value": "Were Dua"
  },
  {
    "id": "5309191001",
    "district_id": "530919",
    "label": "Mangulewa",
    "value": "Mangulewa"
  },
  {
    "id": "5309192002",
    "district_id": "530919",
    "label": "Turekisa",
    "value": "Turekisa"
  },
  {
    "id": "5309192003",
    "district_id": "530919",
    "label": "Rakalaba",
    "value": "Rakalaba"
  },
  {
    "id": "5309192004",
    "district_id": "530919",
    "label": "Sobo",
    "value": "Sobo"
  },
  {
    "id": "5309192005",
    "district_id": "530919",
    "label": "Sobo Satu",
    "value": "Sobo Satu"
  },
  {
    "id": "5309192006",
    "district_id": "530919",
    "label": "Rakateda Satu",
    "value": "Rakateda Satu"
  },
  {
    "id": "5309192007",
    "district_id": "530919",
    "label": "Rakateda Dua",
    "value": "Rakateda Dua"
  },
  {
    "id": "5309192008",
    "district_id": "530919",
    "label": "Watunay",
    "value": "Watunay"
  },
  {
    "id": "5309192009",
    "district_id": "530919",
    "label": "Dizi Gedha",
    "value": "Dizi Gedha"
  },
  {
    "id": "5309192010",
    "district_id": "530919",
    "label": "Bewapawe",
    "value": "Bewapawe"
  },
  {
    "id": "5309202001",
    "district_id": "530920",
    "label": "Paupaga",
    "value": "Paupaga"
  },
  {
    "id": "5309202002",
    "district_id": "530920",
    "label": "Sebowuli",
    "value": "Sebowuli"
  },
  {
    "id": "5309202003",
    "district_id": "530920",
    "label": "Inerie",
    "value": "Inerie"
  },
  {
    "id": "5309202004",
    "district_id": "530920",
    "label": "Warupele Satu",
    "value": "Warupele Satu"
  },
  {
    "id": "5309202005",
    "district_id": "530920",
    "label": "Warupele Dua",
    "value": "Warupele Dua"
  },
  {
    "id": "5309202006",
    "district_id": "530920",
    "label": "Kelitei",
    "value": "Kelitei"
  },
  {
    "id": "5309202007",
    "district_id": "530920",
    "label": "Waebela",
    "value": "Waebela"
  },
  {
    "id": "5309202008",
    "district_id": "530920",
    "label": "Tiwurana",
    "value": "Tiwurana"
  },
  {
    "id": "5309202009",
    "district_id": "530920",
    "label": "Legeriwu",
    "value": "Legeriwu"
  },
  {
    "id": "5309202010",
    "district_id": "530920",
    "label": "Manubhara",
    "value": "Manubhara"
  },
  {
    "id": "5310012001",
    "district_id": "531001",
    "label": "Satar Ngkeling",
    "value": "Satar Ngkeling"
  },
  {
    "id": "5310012002",
    "district_id": "531001",
    "label": "Lalong",
    "value": "Lalong"
  },
  {
    "id": "5310012003",
    "district_id": "531001",
    "label": "Poco",
    "value": "Poco"
  },
  {
    "id": "5310012004",
    "district_id": "531001",
    "label": "Wae Mulu",
    "value": "Wae Mulu"
  },
  {
    "id": "5310012005",
    "district_id": "531001",
    "label": "Ndehes",
    "value": "Ndehes"
  },
  {
    "id": "5310012006",
    "district_id": "531001",
    "label": "Golo Cador",
    "value": "Golo Cador"
  },
  {
    "id": "5310012007",
    "district_id": "531001",
    "label": "Longko",
    "value": "Longko"
  },
  {
    "id": "5310012008",
    "district_id": "531001",
    "label": "Ranggi",
    "value": "Ranggi"
  },
  {
    "id": "5310012009",
    "district_id": "531001",
    "label": "Compang Ndehes",
    "value": "Compang Ndehes"
  },
  {
    "id": "5310012010",
    "district_id": "531001",
    "label": "Bangka Jong",
    "value": "Bangka Jong"
  },
  {
    "id": "5310012012",
    "district_id": "531001",
    "label": "Bangka Kenda",
    "value": "Bangka Kenda"
  },
  {
    "id": "5310012013",
    "district_id": "531001",
    "label": "Golo Mendo",
    "value": "Golo Mendo"
  },
  {
    "id": "5310012014",
    "district_id": "531001",
    "label": "Golo Wuas",
    "value": "Golo Wuas"
  },
  {
    "id": "5310012015",
    "district_id": "531001",
    "label": "Wae Rii",
    "value": "Wae Rii"
  },
  {
    "id": "5310012016",
    "district_id": "531001",
    "label": "Ranaka",
    "value": "Ranaka"
  },
  {
    "id": "5310012017",
    "district_id": "531001",
    "label": "Golo Watu",
    "value": "Golo Watu"
  },
  {
    "id": "5310012018",
    "district_id": "531001",
    "label": "Benteng Poco",
    "value": "Benteng Poco"
  },
  {
    "id": "5310031005",
    "district_id": "531003",
    "label": "Wae Belang",
    "value": "Wae Belang"
  },
  {
    "id": "5310032002",
    "district_id": "531003",
    "label": "Cumbi",
    "value": "Cumbi"
  },
  {
    "id": "5310032003",
    "district_id": "531003",
    "label": "Kakor",
    "value": "Kakor"
  },
  {
    "id": "5310032004",
    "district_id": "531003",
    "label": "Pong Murung",
    "value": "Pong Murung"
  },
  {
    "id": "5310032006",
    "district_id": "531003",
    "label": "Bulan",
    "value": "Bulan"
  },
  {
    "id": "5310032010",
    "district_id": "531003",
    "label": "Pong Lao",
    "value": "Pong Lao"
  },
  {
    "id": "5310032011",
    "district_id": "531003",
    "label": "Beo Rahong",
    "value": "Beo Rahong"
  },
  {
    "id": "5310032019",
    "district_id": "531003",
    "label": "Golo Worok",
    "value": "Golo Worok"
  },
  {
    "id": "5310032020",
    "district_id": "531003",
    "label": "Meler",
    "value": "Meler"
  },
  {
    "id": "5310032021",
    "district_id": "531003",
    "label": "Benteng Kuwu",
    "value": "Benteng Kuwu"
  },
  {
    "id": "5310032022",
    "district_id": "531003",
    "label": "Belang Turi",
    "value": "Belang Turi"
  },
  {
    "id": "5310032023",
    "district_id": "531003",
    "label": "Pong Leko",
    "value": "Pong Leko"
  },
  {
    "id": "5310032024",
    "district_id": "531003",
    "label": "Rai",
    "value": "Rai"
  },
  {
    "id": "5310032025",
    "district_id": "531003",
    "label": "Poco Likang",
    "value": "Poco Likang"
  },
  {
    "id": "5310032029",
    "district_id": "531003",
    "label": "Bangka Lao",
    "value": "Bangka Lao"
  },
  {
    "id": "5310032030",
    "district_id": "531003",
    "label": "Compang Dalo",
    "value": "Compang Dalo"
  },
  {
    "id": "5310032031",
    "district_id": "531003",
    "label": "Bea Kakor",
    "value": "Bea Kakor"
  },
  {
    "id": "5310032032",
    "district_id": "531003",
    "label": "Compang Namut",
    "value": "Compang Namut"
  },
  {
    "id": "5310032033",
    "district_id": "531003",
    "label": "Pong Lale",
    "value": "Pong Lale"
  },
  {
    "id": "5310052003",
    "district_id": "531005",
    "label": "Jaong",
    "value": "Jaong"
  },
  {
    "id": "5310052004",
    "district_id": "531005",
    "label": "Pongkor",
    "value": "Pongkor"
  },
  {
    "id": "5310052005",
    "district_id": "531005",
    "label": "Wae Ajang",
    "value": "Wae Ajang"
  },
  {
    "id": "5310052018",
    "district_id": "531005",
    "label": "Papang",
    "value": "Papang"
  },
  {
    "id": "5310052019",
    "district_id": "531005",
    "label": "Wewo",
    "value": "Wewo"
  },
  {
    "id": "5310052020",
    "district_id": "531005",
    "label": "Lungar",
    "value": "Lungar"
  },
  {
    "id": "5310052021",
    "district_id": "531005",
    "label": "Mocok",
    "value": "Mocok"
  },
  {
    "id": "5310052022",
    "district_id": "531005",
    "label": "Koak",
    "value": "Koak"
  },
  {
    "id": "5310052023",
    "district_id": "531005",
    "label": "Langgo",
    "value": "Langgo"
  },
  {
    "id": "5310052024",
    "district_id": "531005",
    "label": "Legu",
    "value": "Legu"
  },
  {
    "id": "5310052025",
    "district_id": "531005",
    "label": "Paka",
    "value": "Paka"
  },
  {
    "id": "5310052028",
    "district_id": "531005",
    "label": "Tal",
    "value": "Tal"
  },
  {
    "id": "5310052031",
    "district_id": "531005",
    "label": "Golo Lambo",
    "value": "Golo Lambo"
  },
  {
    "id": "5310052032",
    "district_id": "531005",
    "label": "Ngkaer",
    "value": "Ngkaer"
  },
  {
    "id": "5310052033",
    "district_id": "531005",
    "label": "Umung",
    "value": "Umung"
  },
  {
    "id": "5310052034",
    "district_id": "531005",
    "label": "Tado",
    "value": "Tado"
  },
  {
    "id": "5310052035",
    "district_id": "531005",
    "label": "Gara",
    "value": "Gara"
  },
  {
    "id": "5310052036",
    "district_id": "531005",
    "label": "Golo Muntas",
    "value": "Golo Muntas"
  },
  {
    "id": "5310052038",
    "district_id": "531005",
    "label": "Ponggeok",
    "value": "Ponggeok"
  },
  {
    "id": "5310052039",
    "district_id": "531005",
    "label": "Iteng",
    "value": "Iteng"
  },
  {
    "id": "5310052040",
    "district_id": "531005",
    "label": "Satar Loung",
    "value": "Satar Loung"
  },
  {
    "id": "5310052041",
    "district_id": "531005",
    "label": "Lolang",
    "value": "Lolang"
  },
  {
    "id": "5310052042",
    "district_id": "531005",
    "label": "Ulu Belang",
    "value": "Ulu Belang"
  },
  {
    "id": "5310061026",
    "district_id": "531006",
    "label": "Pagal",
    "value": "Pagal"
  },
  {
    "id": "5310062001",
    "district_id": "531006",
    "label": "Nenu",
    "value": "Nenu"
  },
  {
    "id": "5310062002",
    "district_id": "531006",
    "label": "Rado",
    "value": "Rado"
  },
  {
    "id": "5310062006",
    "district_id": "531006",
    "label": "Wudi",
    "value": "Wudi"
  },
  {
    "id": "5310062007",
    "district_id": "531006",
    "label": "Welu",
    "value": "Welu"
  },
  {
    "id": "5310062008",
    "district_id": "531006",
    "label": "Golo",
    "value": "Golo"
  },
  {
    "id": "5310062009",
    "district_id": "531006",
    "label": "Barang",
    "value": "Barang"
  },
  {
    "id": "5310062010",
    "district_id": "531006",
    "label": "Perak",
    "value": "Perak"
  },
  {
    "id": "5310062011",
    "district_id": "531006",
    "label": "Bea Mese",
    "value": "Bea Mese"
  },
  {
    "id": "5310062012",
    "district_id": "531006",
    "label": "Langkas",
    "value": "Langkas"
  },
  {
    "id": "5310062013",
    "district_id": "531006",
    "label": "Ladur",
    "value": "Ladur"
  },
  {
    "id": "5310062014",
    "district_id": "531006",
    "label": "Kentol",
    "value": "Kentol"
  },
  {
    "id": "5310062015",
    "district_id": "531006",
    "label": "Riung",
    "value": "Riung"
  },
  {
    "id": "5310062022",
    "district_id": "531006",
    "label": "Pinggang",
    "value": "Pinggang"
  },
  {
    "id": "5310062024",
    "district_id": "531006",
    "label": "Lando",
    "value": "Lando"
  },
  {
    "id": "5310062025",
    "district_id": "531006",
    "label": "Gapong",
    "value": "Gapong"
  },
  {
    "id": "5310062027",
    "district_id": "531006",
    "label": "Golo Ncuang",
    "value": "Golo Ncuang"
  },
  {
    "id": "5310111017",
    "district_id": "531011",
    "label": "Reo",
    "value": "Reo"
  },
  {
    "id": "5310111018",
    "district_id": "531011",
    "label": "Mata Air",
    "value": "Mata Air"
  },
  {
    "id": "5310111019",
    "district_id": "531011",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "5310111020",
    "district_id": "531011",
    "label": "Wangkung",
    "value": "Wangkung"
  },
  {
    "id": "5310112001",
    "district_id": "531011",
    "label": "Robek",
    "value": "Robek"
  },
  {
    "id": "5310112009",
    "district_id": "531011",
    "label": "Ruis",
    "value": "Ruis"
  },
  {
    "id": "5310112010",
    "district_id": "531011",
    "label": "Bajak",
    "value": "Bajak"
  },
  {
    "id": "5310112011",
    "district_id": "531011",
    "label": "Watu Baur",
    "value": "Watu Baur"
  },
  {
    "id": "5310112012",
    "district_id": "531011",
    "label": "Salama",
    "value": "Salama"
  },
  {
    "id": "5310112016",
    "district_id": "531011",
    "label": "Watu Tango",
    "value": "Watu Tango"
  },
  {
    "id": "5310121001",
    "district_id": "531012",
    "label": "Golo Dukal",
    "value": "Golo Dukal"
  },
  {
    "id": "5310121002",
    "district_id": "531012",
    "label": "Wali",
    "value": "Wali"
  },
  {
    "id": "5310121003",
    "district_id": "531012",
    "label": "Karot",
    "value": "Karot"
  },
  {
    "id": "5310121004",
    "district_id": "531012",
    "label": "Pitak",
    "value": "Pitak"
  },
  {
    "id": "5310121005",
    "district_id": "531012",
    "label": "Lawir",
    "value": "Lawir"
  },
  {
    "id": "5310121006",
    "district_id": "531012",
    "label": "Pau",
    "value": "Pau"
  },
  {
    "id": "5310121007",
    "district_id": "531012",
    "label": "Waso",
    "value": "Waso"
  },
  {
    "id": "5310121008",
    "district_id": "531012",
    "label": "Watu",
    "value": "Watu"
  },
  {
    "id": "5310121009",
    "district_id": "531012",
    "label": "Mbaumuku",
    "value": "Mbaumuku"
  },
  {
    "id": "5310121010",
    "district_id": "531012",
    "label": "Tenda",
    "value": "Tenda"
  },
  {
    "id": "5310121011",
    "district_id": "531012",
    "label": "Carep",
    "value": "Carep"
  },
  {
    "id": "5310121012",
    "district_id": "531012",
    "label": "Bangka Nekang",
    "value": "Bangka Nekang"
  },
  {
    "id": "5310121013",
    "district_id": "531012",
    "label": "Satar Tacik",
    "value": "Satar Tacik"
  },
  {
    "id": "5310121014",
    "district_id": "531012",
    "label": "Poco Mal",
    "value": "Poco Mal"
  },
  {
    "id": "5310121015",
    "district_id": "531012",
    "label": "Compang Carep",
    "value": "Compang Carep"
  },
  {
    "id": "5310121016",
    "district_id": "531012",
    "label": "Laci Carep",
    "value": "Laci Carep"
  },
  {
    "id": "5310121017",
    "district_id": "531012",
    "label": "Tadong",
    "value": "Tadong"
  },
  {
    "id": "5310121018",
    "district_id": "531012",
    "label": "Rowang",
    "value": "Rowang"
  },
  {
    "id": "5310121019",
    "district_id": "531012",
    "label": "Bangka Leda",
    "value": "Bangka Leda"
  },
  {
    "id": "5310121020",
    "district_id": "531012",
    "label": "Compang Tuke",
    "value": "Compang Tuke"
  },
  {
    "id": "5310132002",
    "district_id": "531013",
    "label": "Hilihintir",
    "value": "Hilihintir"
  },
  {
    "id": "5310132005",
    "district_id": "531013",
    "label": "Bea Kondo",
    "value": "Bea Kondo"
  },
  {
    "id": "5310132012",
    "district_id": "531013",
    "label": "Golo Ropong",
    "value": "Golo Ropong"
  },
  {
    "id": "5310132013",
    "district_id": "531013",
    "label": "Terong",
    "value": "Terong"
  },
  {
    "id": "5310132014",
    "district_id": "531013",
    "label": "Ceka Luju",
    "value": "Ceka Luju"
  },
  {
    "id": "5310132015",
    "district_id": "531013",
    "label": "Satar Luju",
    "value": "Satar Luju"
  },
  {
    "id": "5310132016",
    "district_id": "531013",
    "label": "Satar Lenda",
    "value": "Satar Lenda"
  },
  {
    "id": "5310132017",
    "district_id": "531013",
    "label": "Satar Ruwuk",
    "value": "Satar Ruwuk"
  },
  {
    "id": "5310132018",
    "district_id": "531013",
    "label": "Nuca Molas",
    "value": "Nuca Molas"
  },
  {
    "id": "5310132019",
    "district_id": "531013",
    "label": "Borik",
    "value": "Borik"
  },
  {
    "id": "5310132021",
    "district_id": "531013",
    "label": "Cambir Leca",
    "value": "Cambir Leca"
  },
  {
    "id": "5310132022",
    "district_id": "531013",
    "label": "Wongka",
    "value": "Wongka"
  },
  {
    "id": "5310142001",
    "district_id": "531014",
    "label": "Buar",
    "value": "Buar"
  },
  {
    "id": "5310142002",
    "district_id": "531014",
    "label": "Benteng Tubi",
    "value": "Benteng Tubi"
  },
  {
    "id": "5310142003",
    "district_id": "531014",
    "label": "Compang Dari",
    "value": "Compang Dari"
  },
  {
    "id": "5310142004",
    "district_id": "531014",
    "label": "Dimpong",
    "value": "Dimpong"
  },
  {
    "id": "5310142005",
    "district_id": "531014",
    "label": "Bangka Ajang",
    "value": "Bangka Ajang"
  },
  {
    "id": "5310142006",
    "district_id": "531014",
    "label": "Liang Bua",
    "value": "Liang Bua"
  },
  {
    "id": "5310142007",
    "district_id": "531014",
    "label": "Tengku Lese",
    "value": "Tengku Lese"
  },
  {
    "id": "5310142008",
    "district_id": "531014",
    "label": "Golo Langkok",
    "value": "Golo Langkok"
  },
  {
    "id": "5310142009",
    "district_id": "531014",
    "label": "Pong Lengor",
    "value": "Pong Lengor"
  },
  {
    "id": "5310142010",
    "district_id": "531014",
    "label": "Manong",
    "value": "Manong"
  },
  {
    "id": "5310142011",
    "district_id": "531014",
    "label": "Bangka Ruang",
    "value": "Bangka Ruang"
  },
  {
    "id": "5310142012",
    "district_id": "531014",
    "label": "Wae Mantang",
    "value": "Wae Mantang"
  },
  {
    "id": "5310152001",
    "district_id": "531015",
    "label": "Bangka Lelak",
    "value": "Bangka Lelak"
  },
  {
    "id": "5310152002",
    "district_id": "531015",
    "label": "Gelong",
    "value": "Gelong"
  },
  {
    "id": "5310152003",
    "district_id": "531015",
    "label": "Urang",
    "value": "Urang"
  },
  {
    "id": "5310152004",
    "district_id": "531015",
    "label": "Ketang",
    "value": "Ketang"
  },
  {
    "id": "5310152005",
    "district_id": "531015",
    "label": "Pong Umpu",
    "value": "Pong Umpu"
  },
  {
    "id": "5310152006",
    "district_id": "531015",
    "label": "Bangka Dese",
    "value": "Bangka Dese"
  },
  {
    "id": "5310152007",
    "district_id": "531015",
    "label": "Nati",
    "value": "Nati"
  },
  {
    "id": "5310152008",
    "district_id": "531015",
    "label": "Ndiwar",
    "value": "Ndiwar"
  },
  {
    "id": "5310152009",
    "district_id": "531015",
    "label": "Bangka Tonggur",
    "value": "Bangka Tonggur"
  },
  {
    "id": "5310152010",
    "district_id": "531015",
    "label": "Lentang",
    "value": "Lentang"
  },
  {
    "id": "5310162001",
    "district_id": "531016",
    "label": "Kajong",
    "value": "Kajong"
  },
  {
    "id": "5310162002",
    "district_id": "531016",
    "label": "Lante",
    "value": "Lante"
  },
  {
    "id": "5310162003",
    "district_id": "531016",
    "label": "Toe",
    "value": "Toe"
  },
  {
    "id": "5310162004",
    "district_id": "531016",
    "label": "Sambi",
    "value": "Sambi"
  },
  {
    "id": "5310162005",
    "district_id": "531016",
    "label": "Nggalak",
    "value": "Nggalak"
  },
  {
    "id": "5310162006",
    "district_id": "531016",
    "label": "Loce",
    "value": "Loce"
  },
  {
    "id": "5310162007",
    "district_id": "531016",
    "label": "Lemarang",
    "value": "Lemarang"
  },
  {
    "id": "5310162008",
    "district_id": "531016",
    "label": "Torong Koe",
    "value": "Torong Koe"
  },
  {
    "id": "5310162009",
    "district_id": "531016",
    "label": "Para Lando",
    "value": "Para Lando"
  },
  {
    "id": "5310162010",
    "district_id": "531016",
    "label": "Rura",
    "value": "Rura"
  },
  {
    "id": "5310172001",
    "district_id": "531017",
    "label": "Bere",
    "value": "Bere"
  },
  {
    "id": "5310172002",
    "district_id": "531017",
    "label": "Wae Codi",
    "value": "Wae Codi"
  },
  {
    "id": "5310172003",
    "district_id": "531017",
    "label": "Golo Woi",
    "value": "Golo Woi"
  },
  {
    "id": "5310172004",
    "district_id": "531017",
    "label": "Golo Lanak",
    "value": "Golo Lanak"
  },
  {
    "id": "5310172005",
    "district_id": "531017",
    "label": "Latung",
    "value": "Latung"
  },
  {
    "id": "5310172006",
    "district_id": "531017",
    "label": "Wae Renca",
    "value": "Wae Renca"
  },
  {
    "id": "5310172007",
    "district_id": "531017",
    "label": "Timbu",
    "value": "Timbu"
  },
  {
    "id": "5310172008",
    "district_id": "531017",
    "label": "Bangka Ara",
    "value": "Bangka Ara"
  },
  {
    "id": "5310172009",
    "district_id": "531017",
    "label": "Compang Cibal",
    "value": "Compang Cibal"
  },
  {
    "id": "5310172010",
    "district_id": "531017",
    "label": "Lenda",
    "value": "Lenda"
  },
  {
    "id": "5310182001",
    "district_id": "531018",
    "label": "Renda",
    "value": "Renda"
  },
  {
    "id": "5310182002",
    "district_id": "531018",
    "label": "Todo",
    "value": "Todo"
  },
  {
    "id": "5310182003",
    "district_id": "531018",
    "label": "Gulung",
    "value": "Gulung"
  },
  {
    "id": "5310182004",
    "district_id": "531018",
    "label": "Popo",
    "value": "Popo"
  },
  {
    "id": "5310182005",
    "district_id": "531018",
    "label": "Lia",
    "value": "Lia"
  },
  {
    "id": "5310182006",
    "district_id": "531018",
    "label": "Kole",
    "value": "Kole"
  },
  {
    "id": "5310182007",
    "district_id": "531018",
    "label": "Ling",
    "value": "Ling"
  },
  {
    "id": "5310182008",
    "district_id": "531018",
    "label": "Nao",
    "value": "Nao"
  },
  {
    "id": "5310182009",
    "district_id": "531018",
    "label": "Cireng",
    "value": "Cireng"
  },
  {
    "id": "5310182010",
    "district_id": "531018",
    "label": "Ruang",
    "value": "Ruang"
  },
  {
    "id": "5310182011",
    "district_id": "531018",
    "label": "Mata Wae",
    "value": "Mata Wae"
  },
  {
    "id": "5311011001",
    "district_id": "531101",
    "label": "Kambajawa",
    "value": "Kambajawa"
  },
  {
    "id": "5311011002",
    "district_id": "531101",
    "label": "Hambala",
    "value": "Hambala"
  },
  {
    "id": "5311011003",
    "district_id": "531101",
    "label": "Matawai",
    "value": "Matawai"
  },
  {
    "id": "5311011004",
    "district_id": "531101",
    "label": "Kamalaputi",
    "value": "Kamalaputi"
  },
  {
    "id": "5311012012",
    "district_id": "531101",
    "label": "Lukukamaru",
    "value": "Lukukamaru"
  },
  {
    "id": "5311012013",
    "district_id": "531101",
    "label": "Mbatakapidu",
    "value": "Mbatakapidu"
  },
  {
    "id": "5311012014",
    "district_id": "531101",
    "label": "Pambotandjara",
    "value": "Pambotandjara"
  },
  {
    "id": "5311022003",
    "district_id": "531102",
    "label": "Rambangaru",
    "value": "Rambangaru"
  },
  {
    "id": "5311022006",
    "district_id": "531102",
    "label": "Praibakul",
    "value": "Praibakul"
  },
  {
    "id": "5311022007",
    "district_id": "531102",
    "label": "Wunga",
    "value": "Wunga"
  },
  {
    "id": "5311022008",
    "district_id": "531102",
    "label": "Kadahang",
    "value": "Kadahang"
  },
  {
    "id": "5311022009",
    "district_id": "531102",
    "label": "Napu",
    "value": "Napu"
  },
  {
    "id": "5311022010",
    "district_id": "531102",
    "label": "Mbatapuhu",
    "value": "Mbatapuhu"
  },
  {
    "id": "5311022012",
    "district_id": "531102",
    "label": "Kalamba",
    "value": "Kalamba"
  },
  {
    "id": "5311031001",
    "district_id": "531103",
    "label": "Lewa Paku",
    "value": "Lewa Paku"
  },
  {
    "id": "5311032002",
    "district_id": "531103",
    "label": "Kambuhapang",
    "value": "Kambuhapang"
  },
  {
    "id": "5311032003",
    "district_id": "531103",
    "label": "Kambata Wundut",
    "value": "Kambata Wundut"
  },
  {
    "id": "5311032004",
    "district_id": "531103",
    "label": "Kondamara",
    "value": "Kondamara"
  },
  {
    "id": "5311032005",
    "district_id": "531103",
    "label": "Matawai Pawali",
    "value": "Matawai Pawali"
  },
  {
    "id": "5311032006",
    "district_id": "531103",
    "label": "Rakawatu",
    "value": "Rakawatu"
  },
  {
    "id": "5311032007",
    "district_id": "531103",
    "label": "Tana Rara",
    "value": "Tana Rara"
  },
  {
    "id": "5311032013",
    "district_id": "531103",
    "label": "Bidi Hunga",
    "value": "Bidi Hunga"
  },
  {
    "id": "5311042001",
    "district_id": "531104",
    "label": "Makamenggit",
    "value": "Makamenggit"
  },
  {
    "id": "5311042002",
    "district_id": "531104",
    "label": "Tandula Jangga",
    "value": "Tandula Jangga"
  },
  {
    "id": "5311042003",
    "district_id": "531104",
    "label": "Praipaha",
    "value": "Praipaha"
  },
  {
    "id": "5311042004",
    "district_id": "531104",
    "label": "Kahiri",
    "value": "Kahiri"
  },
  {
    "id": "5311042005",
    "district_id": "531104",
    "label": "Pulu Panjang",
    "value": "Pulu Panjang"
  },
  {
    "id": "5311042010",
    "district_id": "531104",
    "label": "Praikarang",
    "value": "Praikarang"
  },
  {
    "id": "5311042011",
    "district_id": "531104",
    "label": "Tanatuku",
    "value": "Tanatuku"
  },
  {
    "id": "5311042012",
    "district_id": "531104",
    "label": "Ngadulanggi",
    "value": "Ngadulanggi"
  },
  {
    "id": "5311052001",
    "district_id": "531105",
    "label": "Billa",
    "value": "Billa"
  },
  {
    "id": "5311052002",
    "district_id": "531105",
    "label": "Karita",
    "value": "Karita"
  },
  {
    "id": "5311052003",
    "district_id": "531105",
    "label": "Kuki Talu",
    "value": "Kuki Talu"
  },
  {
    "id": "5311052004",
    "district_id": "531105",
    "label": "Tapil",
    "value": "Tapil"
  },
  {
    "id": "5311052005",
    "district_id": "531105",
    "label": "Tarimbang",
    "value": "Tarimbang"
  },
  {
    "id": "5311052006",
    "district_id": "531105",
    "label": "Waikanabu",
    "value": "Waikanabu"
  },
  {
    "id": "5311052007",
    "district_id": "531105",
    "label": "Pindu Hurani",
    "value": "Pindu Hurani"
  },
  {
    "id": "5311052008",
    "district_id": "531105",
    "label": "Praing Kareha",
    "value": "Praing Kareha"
  },
  {
    "id": "5311052009",
    "district_id": "531105",
    "label": "Wudi Pandak",
    "value": "Wudi Pandak"
  },
  {
    "id": "5311052011",
    "district_id": "531105",
    "label": "Bangga Watu",
    "value": "Bangga Watu"
  },
  {
    "id": "5311062001",
    "district_id": "531106",
    "label": "Ramuk",
    "value": "Ramuk"
  },
  {
    "id": "5311062002",
    "district_id": "531106",
    "label": "Wangga Mbewa",
    "value": "Wangga Mbewa"
  },
  {
    "id": "5311062003",
    "district_id": "531106",
    "label": "Lailunggi",
    "value": "Lailunggi"
  },
  {
    "id": "5311062004",
    "district_id": "531106",
    "label": "Tawui",
    "value": "Tawui"
  },
  {
    "id": "5311062005",
    "district_id": "531106",
    "label": "Wahang",
    "value": "Wahang"
  },
  {
    "id": "5311062006",
    "district_id": "531106",
    "label": "Mahaniwa",
    "value": "Mahaniwa"
  },
  {
    "id": "5311071001",
    "district_id": "531107",
    "label": "Kawangu",
    "value": "Kawangu"
  },
  {
    "id": "5311071002",
    "district_id": "531107",
    "label": "Watumbaka",
    "value": "Watumbaka"
  },
  {
    "id": "5311072003",
    "district_id": "531107",
    "label": "Kambatatana",
    "value": "Kambatatana"
  },
  {
    "id": "5311072007",
    "district_id": "531107",
    "label": "Palaka Hembi",
    "value": "Palaka Hembi"
  },
  {
    "id": "5311072008",
    "district_id": "531107",
    "label": "Kadumbul",
    "value": "Kadumbul"
  },
  {
    "id": "5311072009",
    "district_id": "531107",
    "label": "Maubokul",
    "value": "Maubokul"
  },
  {
    "id": "5311072012",
    "district_id": "531107",
    "label": "Laindeha",
    "value": "Laindeha"
  },
  {
    "id": "5311081001",
    "district_id": "531108",
    "label": "Lumbukore",
    "value": "Lumbukore"
  },
  {
    "id": "5311082002",
    "district_id": "531108",
    "label": "Umalulu",
    "value": "Umalulu"
  },
  {
    "id": "5311082003",
    "district_id": "531108",
    "label": "Patawang",
    "value": "Patawang"
  },
  {
    "id": "5311082004",
    "district_id": "531108",
    "label": "Wanga",
    "value": "Wanga"
  },
  {
    "id": "5311082005",
    "district_id": "531108",
    "label": "Matawai Atu",
    "value": "Matawai Atu"
  },
  {
    "id": "5311082006",
    "district_id": "531108",
    "label": "Mutunggeding",
    "value": "Mutunggeding"
  },
  {
    "id": "5311082007",
    "district_id": "531108",
    "label": "Lairuru",
    "value": "Lairuru"
  },
  {
    "id": "5311082008",
    "district_id": "531108",
    "label": "Watuhadang",
    "value": "Watuhadang"
  },
  {
    "id": "5311082009",
    "district_id": "531108",
    "label": "Watupuda",
    "value": "Watupuda"
  },
  {
    "id": "5311082010",
    "district_id": "531108",
    "label": "Ngaru Kanoru",
    "value": "Ngaru Kanoru"
  },
  {
    "id": "5311092001",
    "district_id": "531109",
    "label": "Rindi",
    "value": "Rindi"
  },
  {
    "id": "5311092002",
    "district_id": "531109",
    "label": "Tanaraing",
    "value": "Tanaraing"
  },
  {
    "id": "5311092003",
    "district_id": "531109",
    "label": "Haikatapu",
    "value": "Haikatapu"
  },
  {
    "id": "5311092004",
    "district_id": "531109",
    "label": "Kabaru",
    "value": "Kabaru"
  },
  {
    "id": "5311092005",
    "district_id": "531109",
    "label": "Hanggaroru",
    "value": "Hanggaroru"
  },
  {
    "id": "5311092006",
    "district_id": "531109",
    "label": "Lailanjang",
    "value": "Lailanjang"
  },
  {
    "id": "5311092007",
    "district_id": "531109",
    "label": "Tamburi",
    "value": "Tamburi"
  },
  {
    "id": "5311092008",
    "district_id": "531109",
    "label": "Kayuri",
    "value": "Kayuri"
  },
  {
    "id": "5311102001",
    "district_id": "531110",
    "label": "Kaliuda",
    "value": "Kaliuda"
  },
  {
    "id": "5311102002",
    "district_id": "531110",
    "label": "Kuruwaki",
    "value": "Kuruwaki"
  },
  {
    "id": "5311102003",
    "district_id": "531110",
    "label": "Tana Manang",
    "value": "Tana Manang"
  },
  {
    "id": "5311102004",
    "district_id": "531110",
    "label": "Mburukulu",
    "value": "Mburukulu"
  },
  {
    "id": "5311102005",
    "district_id": "531110",
    "label": "Pamburu",
    "value": "Pamburu"
  },
  {
    "id": "5311102006",
    "district_id": "531110",
    "label": "Lambakara",
    "value": "Lambakara"
  },
  {
    "id": "5311102007",
    "district_id": "531110",
    "label": "Tamma",
    "value": "Tamma"
  },
  {
    "id": "5311102009",
    "district_id": "531110",
    "label": "Palanggai",
    "value": "Palanggai"
  },
  {
    "id": "5311112001",
    "district_id": "531111",
    "label": "Wulla",
    "value": "Wulla"
  },
  {
    "id": "5311112002",
    "district_id": "531111",
    "label": "Hadakamali",
    "value": "Hadakamali"
  },
  {
    "id": "5311112003",
    "district_id": "531111",
    "label": "Lumbu Manggit",
    "value": "Lumbu Manggit"
  },
  {
    "id": "5311112004",
    "district_id": "531111",
    "label": "Latena",
    "value": "Latena"
  },
  {
    "id": "5311112005",
    "district_id": "531111",
    "label": "Lainjanji",
    "value": "Lainjanji"
  },
  {
    "id": "5311112006",
    "district_id": "531111",
    "label": "Laipandak",
    "value": "Laipandak"
  },
  {
    "id": "5311112008",
    "district_id": "531111",
    "label": "Paranda",
    "value": "Paranda"
  },
  {
    "id": "5311122001",
    "district_id": "531112",
    "label": "Kananggar",
    "value": "Kananggar"
  },
  {
    "id": "5311122002",
    "district_id": "531112",
    "label": "Mehang Mata",
    "value": "Mehang Mata"
  },
  {
    "id": "5311122003",
    "district_id": "531112",
    "label": "Karera Jangga",
    "value": "Karera Jangga"
  },
  {
    "id": "5311122004",
    "district_id": "531112",
    "label": "Praimbana",
    "value": "Praimbana"
  },
  {
    "id": "5311122005",
    "district_id": "531112",
    "label": "Pabera Manera",
    "value": "Pabera Manera"
  },
  {
    "id": "5311122010",
    "district_id": "531112",
    "label": "Winumuru",
    "value": "Winumuru"
  },
  {
    "id": "5311122011",
    "district_id": "531112",
    "label": "Laitaku",
    "value": "Laitaku"
  },
  {
    "id": "5311132001",
    "district_id": "531113",
    "label": "Nggongi",
    "value": "Nggongi"
  },
  {
    "id": "5311132002",
    "district_id": "531113",
    "label": "Praimadita",
    "value": "Praimadita"
  },
  {
    "id": "5311132003",
    "district_id": "531113",
    "label": "Praisalura",
    "value": "Praisalura"
  },
  {
    "id": "5311132004",
    "district_id": "531113",
    "label": "Tandula Jangga",
    "value": "Tandula Jangga"
  },
  {
    "id": "5311132005",
    "district_id": "531113",
    "label": "Nangga",
    "value": "Nangga"
  },
  {
    "id": "5311132006",
    "district_id": "531113",
    "label": "Janggamangu",
    "value": "Janggamangu"
  },
  {
    "id": "5311132011",
    "district_id": "531113",
    "label": "Ananjaki",
    "value": "Ananjaki"
  },
  {
    "id": "5311142001",
    "district_id": "531114",
    "label": "Kamanggih",
    "value": "Kamanggih"
  },
  {
    "id": "5311142002",
    "district_id": "531114",
    "label": "Kambatabundung",
    "value": "Kambatabundung"
  },
  {
    "id": "5311142003",
    "district_id": "531114",
    "label": "Meurumba",
    "value": "Meurumba"
  },
  {
    "id": "5311142004",
    "district_id": "531114",
    "label": "Laimbonga",
    "value": "Laimbonga"
  },
  {
    "id": "5311142005",
    "district_id": "531114",
    "label": "Kota Kawau",
    "value": "Kota Kawau"
  },
  {
    "id": "5311142006",
    "district_id": "531114",
    "label": "Matawai Katingga",
    "value": "Matawai Katingga"
  },
  {
    "id": "5311142007",
    "district_id": "531114",
    "label": "Kataka",
    "value": "Kataka"
  },
  {
    "id": "5311142008",
    "district_id": "531114",
    "label": "Matawai Maringgu",
    "value": "Matawai Maringgu"
  },
  {
    "id": "5311142009",
    "district_id": "531114",
    "label": "Mauramba",
    "value": "Mauramba"
  },
  {
    "id": "5311152001",
    "district_id": "531115",
    "label": "Prai Bakul",
    "value": "Prai Bakul"
  },
  {
    "id": "5311152002",
    "district_id": "531115",
    "label": "Katikutana",
    "value": "Katikutana"
  },
  {
    "id": "5311152003",
    "district_id": "531115",
    "label": "Karipi",
    "value": "Karipi"
  },
  {
    "id": "5311152004",
    "district_id": "531115",
    "label": "Wangga Meti",
    "value": "Wangga Meti"
  },
  {
    "id": "5311152005",
    "district_id": "531115",
    "label": "Katiku Wai",
    "value": "Katiku Wai"
  },
  {
    "id": "5311152006",
    "district_id": "531115",
    "label": "Katiku Luku",
    "value": "Katiku Luku"
  },
  {
    "id": "5311161001",
    "district_id": "531116",
    "label": "Wangga",
    "value": "Wangga"
  },
  {
    "id": "5311161002",
    "district_id": "531116",
    "label": "Prailiu",
    "value": "Prailiu"
  },
  {
    "id": "5311161003",
    "district_id": "531116",
    "label": "Kambaniru",
    "value": "Kambaniru"
  },
  {
    "id": "5311161004",
    "district_id": "531116",
    "label": "Mauliru",
    "value": "Mauliru"
  },
  {
    "id": "5311161005",
    "district_id": "531116",
    "label": "Mau Hau",
    "value": "Mau Hau"
  },
  {
    "id": "5311161006",
    "district_id": "531116",
    "label": "Malumbi",
    "value": "Malumbi"
  },
  {
    "id": "5311161007",
    "district_id": "531116",
    "label": "Lambanapu",
    "value": "Lambanapu"
  },
  {
    "id": "5311162008",
    "district_id": "531116",
    "label": "Kiritana",
    "value": "Kiritana"
  },
  {
    "id": "5311172001",
    "district_id": "531117",
    "label": "Lukuwingir",
    "value": "Lukuwingir"
  },
  {
    "id": "5311172002",
    "district_id": "531117",
    "label": "Waimbidi",
    "value": "Waimbidi"
  },
  {
    "id": "5311172003",
    "district_id": "531117",
    "label": "Laimeta",
    "value": "Laimeta"
  },
  {
    "id": "5311172004",
    "district_id": "531117",
    "label": "Mahubokul",
    "value": "Mahubokul"
  },
  {
    "id": "5311172005",
    "district_id": "531117",
    "label": "Marada Mundi",
    "value": "Marada Mundi"
  },
  {
    "id": "5311172006",
    "district_id": "531117",
    "label": "Maidang",
    "value": "Maidang"
  },
  {
    "id": "5311182001",
    "district_id": "531118",
    "label": "Watumbelar",
    "value": "Watumbelar"
  },
  {
    "id": "5311182002",
    "district_id": "531118",
    "label": "Bidipraing",
    "value": "Bidipraing"
  },
  {
    "id": "5311182003",
    "district_id": "531118",
    "label": "Umamanu",
    "value": "Umamanu"
  },
  {
    "id": "5311182004",
    "district_id": "531118",
    "label": "Mondulambi",
    "value": "Mondulambi"
  },
  {
    "id": "5311182005",
    "district_id": "531118",
    "label": "Kangeli",
    "value": "Kangeli"
  },
  {
    "id": "5311182006",
    "district_id": "531118",
    "label": "Laihau",
    "value": "Laihau"
  },
  {
    "id": "5311192001",
    "district_id": "531119",
    "label": "Kompa Pari",
    "value": "Kompa Pari"
  },
  {
    "id": "5311192002",
    "district_id": "531119",
    "label": "Matawai Amahu",
    "value": "Matawai Amahu"
  },
  {
    "id": "5311192003",
    "district_id": "531119",
    "label": "Mandahu",
    "value": "Mandahu"
  },
  {
    "id": "5311192004",
    "district_id": "531119",
    "label": "Lailara",
    "value": "Lailara"
  },
  {
    "id": "5311192005",
    "district_id": "531119",
    "label": "Praibakul",
    "value": "Praibakul"
  },
  {
    "id": "5311201001",
    "district_id": "531120",
    "label": "Temu",
    "value": "Temu"
  },
  {
    "id": "5311202002",
    "district_id": "531120",
    "label": "Kuta",
    "value": "Kuta"
  },
  {
    "id": "5311202003",
    "district_id": "531120",
    "label": "Hambapraing",
    "value": "Hambapraing"
  },
  {
    "id": "5311202004",
    "district_id": "531120",
    "label": "Mondu",
    "value": "Mondu"
  },
  {
    "id": "5311202005",
    "district_id": "531120",
    "label": "Ndapayami",
    "value": "Ndapayami"
  },
  {
    "id": "5311212001",
    "district_id": "531121",
    "label": "Praiwitu",
    "value": "Praiwitu"
  },
  {
    "id": "5311212002",
    "district_id": "531121",
    "label": "Kakaha",
    "value": "Kakaha"
  },
  {
    "id": "5311212003",
    "district_id": "531121",
    "label": "Hambawutang",
    "value": "Hambawutang"
  },
  {
    "id": "5311212004",
    "district_id": "531121",
    "label": "Kabanda",
    "value": "Kabanda"
  },
  {
    "id": "5311212005",
    "district_id": "531121",
    "label": "Prauraming",
    "value": "Prauraming"
  },
  {
    "id": "5311222001",
    "district_id": "531122",
    "label": "Lahiru",
    "value": "Lahiru"
  },
  {
    "id": "5311222002",
    "district_id": "531122",
    "label": "Patamawai",
    "value": "Patamawai"
  },
  {
    "id": "5311222003",
    "district_id": "531122",
    "label": "Wairara",
    "value": "Wairara"
  },
  {
    "id": "5311222004",
    "district_id": "531122",
    "label": "Praikalala",
    "value": "Praikalala"
  },
  {
    "id": "5311222005",
    "district_id": "531122",
    "label": "Lulundilu",
    "value": "Lulundilu"
  },
  {
    "id": "5311222006",
    "district_id": "531122",
    "label": "Haray",
    "value": "Haray"
  },
  {
    "id": "5312042001",
    "district_id": "531204",
    "label": "Loko Ry",
    "value": "Loko Ry"
  },
  {
    "id": "5312042002",
    "district_id": "531204",
    "label": "Lolo Wano",
    "value": "Lolo Wano"
  },
  {
    "id": "5312042003",
    "district_id": "531204",
    "label": "Malata",
    "value": "Malata"
  },
  {
    "id": "5312042004",
    "district_id": "531204",
    "label": "Ngadu Pada",
    "value": "Ngadu Pada"
  },
  {
    "id": "5312042005",
    "district_id": "531204",
    "label": "Lingu Lango",
    "value": "Lingu Lango"
  },
  {
    "id": "5312042006",
    "district_id": "531204",
    "label": "Karaka Nduku",
    "value": "Karaka Nduku"
  },
  {
    "id": "5312042007",
    "district_id": "531204",
    "label": "Wano Kasa",
    "value": "Wano Kasa"
  },
  {
    "id": "5312042008",
    "district_id": "531204",
    "label": "Wee Patola",
    "value": "Wee Patola"
  },
  {
    "id": "5312042009",
    "district_id": "531204",
    "label": "Zala Kadu",
    "value": "Zala Kadu"
  },
  {
    "id": "5312042010",
    "district_id": "531204",
    "label": "Bondo Tera",
    "value": "Bondo Tera"
  },
  {
    "id": "5312042011",
    "district_id": "531204",
    "label": "Manu Kuku",
    "value": "Manu Kuku"
  },
  {
    "id": "5312042012",
    "district_id": "531204",
    "label": "Lolo Tana",
    "value": "Lolo Tana"
  },
  {
    "id": "5312042013",
    "district_id": "531204",
    "label": "Kareka Nduku Utara",
    "value": "Kareka Nduku Utara"
  },
  {
    "id": "5312042014",
    "district_id": "531204",
    "label": "Kareka Nduku Selatan",
    "value": "Kareka Nduku Selatan"
  },
  {
    "id": "5312042015",
    "district_id": "531204",
    "label": "Manu Mada",
    "value": "Manu Mada"
  },
  {
    "id": "5312042016",
    "district_id": "531204",
    "label": "Elu Loda",
    "value": "Elu Loda"
  },
  {
    "id": "5312042017",
    "district_id": "531204",
    "label": "Kalebu Ana Kaka",
    "value": "Kalebu Ana Kaka"
  },
  {
    "id": "5312042018",
    "district_id": "531204",
    "label": "Tarona",
    "value": "Tarona"
  },
  {
    "id": "5312101007",
    "district_id": "531210",
    "label": "Wee Karou",
    "value": "Wee Karou"
  },
  {
    "id": "5312101008",
    "district_id": "531210",
    "label": "Sobawawi",
    "value": "Sobawawi"
  },
  {
    "id": "5312101011",
    "district_id": "531210",
    "label": "Loda Pare",
    "value": "Loda Pare"
  },
  {
    "id": "5312101012",
    "district_id": "531210",
    "label": "Wee Dabo",
    "value": "Wee Dabo"
  },
  {
    "id": "5312101013",
    "district_id": "531210",
    "label": "Dira Tana",
    "value": "Dira Tana"
  },
  {
    "id": "5312102001",
    "district_id": "531210",
    "label": "Bera Dolu",
    "value": "Bera Dolu"
  },
  {
    "id": "5312102002",
    "district_id": "531210",
    "label": "Doka Kaka",
    "value": "Doka Kaka"
  },
  {
    "id": "5312102003",
    "district_id": "531210",
    "label": "Tana Rara",
    "value": "Tana Rara"
  },
  {
    "id": "5312102004",
    "district_id": "531210",
    "label": "Bali Ledo",
    "value": "Bali Ledo"
  },
  {
    "id": "5312102005",
    "district_id": "531210",
    "label": "Dedekadu",
    "value": "Dedekadu"
  },
  {
    "id": "5312102006",
    "district_id": "531210",
    "label": "Ubu Pede",
    "value": "Ubu Pede"
  },
  {
    "id": "5312102009",
    "district_id": "531210",
    "label": "Tema Tana",
    "value": "Tema Tana"
  },
  {
    "id": "5312102010",
    "district_id": "531210",
    "label": "Ubu Raya",
    "value": "Ubu Raya"
  },
  {
    "id": "5312102014",
    "district_id": "531210",
    "label": "Manola",
    "value": "Manola"
  },
  {
    "id": "5312112001",
    "district_id": "531211",
    "label": "Katiku Loku",
    "value": "Katiku Loku"
  },
  {
    "id": "5312112002",
    "district_id": "531211",
    "label": "Hupu Mada",
    "value": "Hupu Mada"
  },
  {
    "id": "5312112003",
    "district_id": "531211",
    "label": "Praibakul",
    "value": "Praibakul"
  },
  {
    "id": "5312112004",
    "district_id": "531211",
    "label": "Hoba Wawi",
    "value": "Hoba Wawi"
  },
  {
    "id": "5312112005",
    "district_id": "531211",
    "label": "Waihura",
    "value": "Waihura"
  },
  {
    "id": "5312112006",
    "district_id": "531211",
    "label": "Pahola",
    "value": "Pahola"
  },
  {
    "id": "5312112007",
    "district_id": "531211",
    "label": "Bali Loku",
    "value": "Bali Loku"
  },
  {
    "id": "5312112008",
    "district_id": "531211",
    "label": "Tara Manu",
    "value": "Tara Manu"
  },
  {
    "id": "5312112009",
    "district_id": "531211",
    "label": "Mamodu",
    "value": "Mamodu"
  },
  {
    "id": "5312112010",
    "district_id": "531211",
    "label": "Rua",
    "value": "Rua"
  },
  {
    "id": "5312112011",
    "district_id": "531211",
    "label": "Rewa Rara",
    "value": "Rewa Rara"
  },
  {
    "id": "5312112012",
    "district_id": "531211",
    "label": "Wei Mangoma",
    "value": "Wei Mangoma"
  },
  {
    "id": "5312112013",
    "district_id": "531211",
    "label": "Ana Wolu",
    "value": "Ana Wolu"
  },
  {
    "id": "5312112014",
    "district_id": "531211",
    "label": "Pari Rara",
    "value": "Pari Rara"
  },
  {
    "id": "5312122004",
    "district_id": "531212",
    "label": "Patiala Bawa",
    "value": "Patiala Bawa"
  },
  {
    "id": "5312122005",
    "district_id": "531212",
    "label": "Wailibo",
    "value": "Wailibo"
  },
  {
    "id": "5312122006",
    "district_id": "531212",
    "label": "Lamboya Bawah",
    "value": "Lamboya Bawah"
  },
  {
    "id": "5312122007",
    "district_id": "531212",
    "label": "Watu Karere",
    "value": "Watu Karere"
  },
  {
    "id": "5312122008",
    "district_id": "531212",
    "label": "Kabu Karudi",
    "value": "Kabu Karudi"
  },
  {
    "id": "5312122009",
    "district_id": "531212",
    "label": "Rajaka",
    "value": "Rajaka"
  },
  {
    "id": "5312122010",
    "district_id": "531212",
    "label": "Sodana",
    "value": "Sodana"
  },
  {
    "id": "5312122011",
    "district_id": "531212",
    "label": "Laboya Dete",
    "value": "Laboya Dete"
  },
  {
    "id": "5312122012",
    "district_id": "531212",
    "label": "Ringu Rara",
    "value": "Ringu Rara"
  },
  {
    "id": "5312122014",
    "district_id": "531212",
    "label": "Bodo Hula",
    "value": "Bodo Hula"
  },
  {
    "id": "5312122015",
    "district_id": "531212",
    "label": "Pala Moko",
    "value": "Pala Moko"
  },
  {
    "id": "5312151001",
    "district_id": "531215",
    "label": "Wailiang",
    "value": "Wailiang"
  },
  {
    "id": "5312151002",
    "district_id": "531215",
    "label": "Komerda",
    "value": "Komerda"
  },
  {
    "id": "5312151003",
    "district_id": "531215",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "5312151004",
    "district_id": "531215",
    "label": "Kampung Sawah",
    "value": "Kampung Sawah"
  },
  {
    "id": "5312151008",
    "district_id": "531215",
    "label": "Pada Eweta",
    "value": "Pada Eweta"
  },
  {
    "id": "5312151009",
    "district_id": "531215",
    "label": "Maliti",
    "value": "Maliti"
  },
  {
    "id": "5312152005",
    "district_id": "531215",
    "label": "Kodaka",
    "value": "Kodaka"
  },
  {
    "id": "5312152006",
    "district_id": "531215",
    "label": "Tebara",
    "value": "Tebara"
  },
  {
    "id": "5312152007",
    "district_id": "531215",
    "label": "Kalembu Kuni",
    "value": "Kalembu Kuni"
  },
  {
    "id": "5312152010",
    "district_id": "531215",
    "label": "Soba Rade",
    "value": "Soba Rade"
  },
  {
    "id": "5312152011",
    "district_id": "531215",
    "label": "Lapale",
    "value": "Lapale"
  },
  {
    "id": "5312152012",
    "district_id": "531215",
    "label": "Modu Waimaringu",
    "value": "Modu Waimaringu"
  },
  {
    "id": "5312152013",
    "district_id": "531215",
    "label": "Puu Mawo",
    "value": "Puu Mawo"
  },
  {
    "id": "5312182001",
    "district_id": "531218",
    "label": "Wee Tana",
    "value": "Wee Tana"
  },
  {
    "id": "5312182002",
    "district_id": "531218",
    "label": "Gaura",
    "value": "Gaura"
  },
  {
    "id": "5312182003",
    "district_id": "531218",
    "label": "Patiala Dete",
    "value": "Patiala Dete"
  },
  {
    "id": "5312182004",
    "district_id": "531218",
    "label": "Harona Kalla",
    "value": "Harona Kalla"
  },
  {
    "id": "5313012001",
    "district_id": "531301",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "5313012002",
    "district_id": "531301",
    "label": "Duawutun",
    "value": "Duawutun"
  },
  {
    "id": "5313012003",
    "district_id": "531301",
    "label": "Atawai",
    "value": "Atawai"
  },
  {
    "id": "5313012004",
    "district_id": "531301",
    "label": "Lusiduawutun",
    "value": "Lusiduawutun"
  },
  {
    "id": "5313012005",
    "district_id": "531301",
    "label": "Labalimut",
    "value": "Labalimut"
  },
  {
    "id": "5313012006",
    "district_id": "531301",
    "label": "Ileboli",
    "value": "Ileboli"
  },
  {
    "id": "5313012007",
    "district_id": "531301",
    "label": "Babokerong",
    "value": "Babokerong"
  },
  {
    "id": "5313012008",
    "district_id": "531301",
    "label": "Penikenek",
    "value": "Penikenek"
  },
  {
    "id": "5313012009",
    "district_id": "531301",
    "label": "Belobaja",
    "value": "Belobaja"
  },
  {
    "id": "5313012010",
    "district_id": "531301",
    "label": "Lolong",
    "value": "Lolong"
  },
  {
    "id": "5313012011",
    "district_id": "531301",
    "label": "Wuakoreng",
    "value": "Wuakoreng"
  },
  {
    "id": "5313012012",
    "district_id": "531301",
    "label": "Baobolak",
    "value": "Baobolak"
  },
  {
    "id": "5313012013",
    "district_id": "531301",
    "label": "Tewaowutung",
    "value": "Tewaowutung"
  },
  {
    "id": "5313012014",
    "district_id": "531301",
    "label": "Boli Bean",
    "value": "Boli Bean"
  },
  {
    "id": "5313012015",
    "district_id": "531301",
    "label": "Warawatung",
    "value": "Warawatung"
  },
  {
    "id": "5313012016",
    "district_id": "531301",
    "label": "Idalolong",
    "value": "Idalolong"
  },
  {
    "id": "5313012017",
    "district_id": "531301",
    "label": "Ria Bao",
    "value": "Ria Bao"
  },
  {
    "id": "5313012018",
    "district_id": "531301",
    "label": "Liwulagang",
    "value": "Liwulagang"
  },
  {
    "id": "5313022001",
    "district_id": "531302",
    "label": "Atakera",
    "value": "Atakera"
  },
  {
    "id": "5313022002",
    "district_id": "531302",
    "label": "Lusilame",
    "value": "Lusilame"
  },
  {
    "id": "5313022004",
    "district_id": "531302",
    "label": "Lerek",
    "value": "Lerek"
  },
  {
    "id": "5313022005",
    "district_id": "531302",
    "label": "Nubahaeraka",
    "value": "Nubahaeraka"
  },
  {
    "id": "5313022006",
    "district_id": "531302",
    "label": "Tubukrajan",
    "value": "Tubukrajan"
  },
  {
    "id": "5313022007",
    "district_id": "531302",
    "label": "Lebaata",
    "value": "Lebaata"
  },
  {
    "id": "5313022008",
    "district_id": "531302",
    "label": "Ile Kimok",
    "value": "Ile Kimok"
  },
  {
    "id": "5313022009",
    "district_id": "531302",
    "label": "Katakeja",
    "value": "Katakeja"
  },
  {
    "id": "5313022010",
    "district_id": "531302",
    "label": "Ile Kerbau",
    "value": "Ile Kerbau"
  },
  {
    "id": "5313022011",
    "district_id": "531302",
    "label": "Dulir",
    "value": "Dulir"
  },
  {
    "id": "5313022012",
    "district_id": "531302",
    "label": "Dori Pewut",
    "value": "Dori Pewut"
  },
  {
    "id": "5313022013",
    "district_id": "531302",
    "label": "Nuba Atalojo",
    "value": "Nuba Atalojo"
  },
  {
    "id": "5313022014",
    "district_id": "531302",
    "label": "Nogo Doni",
    "value": "Nogo Doni"
  },
  {
    "id": "5313022015",
    "district_id": "531302",
    "label": "Nubaboli",
    "value": "Nubaboli"
  },
  {
    "id": "5313022016",
    "district_id": "531302",
    "label": "Lewogroma",
    "value": "Lewogroma"
  },
  {
    "id": "5313032001",
    "district_id": "531303",
    "label": "Kolipadan",
    "value": "Kolipadan"
  },
  {
    "id": "5313032002",
    "district_id": "531303",
    "label": "Dulitukan",
    "value": "Dulitukan"
  },
  {
    "id": "5313032003",
    "district_id": "531303",
    "label": "Tagawiti",
    "value": "Tagawiti"
  },
  {
    "id": "5313032004",
    "district_id": "531303",
    "label": "Waowala",
    "value": "Waowala"
  },
  {
    "id": "5313032005",
    "district_id": "531303",
    "label": "Amakaka",
    "value": "Amakaka"
  },
  {
    "id": "5313032006",
    "district_id": "531303",
    "label": "Bungamuda",
    "value": "Bungamuda"
  },
  {
    "id": "5313032007",
    "district_id": "531303",
    "label": "Napasabok",
    "value": "Napasabok"
  },
  {
    "id": "5313032012",
    "district_id": "531303",
    "label": "Petuntawa",
    "value": "Petuntawa"
  },
  {
    "id": "5313032013",
    "district_id": "531303",
    "label": "Kolontobo",
    "value": "Kolontobo"
  },
  {
    "id": "5313032014",
    "district_id": "531303",
    "label": "Laranwutun",
    "value": "Laranwutun"
  },
  {
    "id": "5313032015",
    "district_id": "531303",
    "label": "Watodiri",
    "value": "Watodiri"
  },
  {
    "id": "5313032016",
    "district_id": "531303",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "5313032018",
    "district_id": "531303",
    "label": "Muruona",
    "value": "Muruona"
  },
  {
    "id": "5313032022",
    "district_id": "531303",
    "label": "Palilolon",
    "value": "Palilolon"
  },
  {
    "id": "5313032024",
    "district_id": "531303",
    "label": "Lamawara",
    "value": "Lamawara"
  },
  {
    "id": "5313032025",
    "district_id": "531303",
    "label": "Beutaran",
    "value": "Beutaran"
  },
  {
    "id": "5313032026",
    "district_id": "531303",
    "label": "Riangbao",
    "value": "Riangbao"
  },
  {
    "id": "5313042001",
    "district_id": "531304",
    "label": "Hadakewa",
    "value": "Hadakewa"
  },
  {
    "id": "5313042002",
    "district_id": "531304",
    "label": "Lamatuka",
    "value": "Lamatuka"
  },
  {
    "id": "5313042003",
    "district_id": "531304",
    "label": "Seranggorang",
    "value": "Seranggorang"
  },
  {
    "id": "5313042004",
    "district_id": "531304",
    "label": "Lodotodokowa",
    "value": "Lodotodokowa"
  },
  {
    "id": "5313042005",
    "district_id": "531304",
    "label": "Baopana",
    "value": "Baopana"
  },
  {
    "id": "5313042006",
    "district_id": "531304",
    "label": "Waienga",
    "value": "Waienga"
  },
  {
    "id": "5313042007",
    "district_id": "531304",
    "label": "Lewoeleng",
    "value": "Lewoeleng"
  },
  {
    "id": "5313042008",
    "district_id": "531304",
    "label": "Dikesare",
    "value": "Dikesare"
  },
  {
    "id": "5313042009",
    "district_id": "531304",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "5313042010",
    "district_id": "531304",
    "label": "Lerahinga",
    "value": "Lerahinga"
  },
  {
    "id": "5313042011",
    "district_id": "531304",
    "label": "Tapobarang",
    "value": "Tapobarang"
  },
  {
    "id": "5313042012",
    "district_id": "531304",
    "label": "Balurebong",
    "value": "Balurebong"
  },
  {
    "id": "5313042013",
    "district_id": "531304",
    "label": "Lamadale",
    "value": "Lamadale"
  },
  {
    "id": "5313042014",
    "district_id": "531304",
    "label": "Tapolangu",
    "value": "Tapolangu"
  },
  {
    "id": "5313042015",
    "district_id": "531304",
    "label": "Lamalela",
    "value": "Lamalela"
  },
  {
    "id": "5313042016",
    "district_id": "531304",
    "label": "Atakowa",
    "value": "Atakowa"
  },
  {
    "id": "5313042017",
    "district_id": "531304",
    "label": "Banitobo",
    "value": "Banitobo"
  },
  {
    "id": "5313051001",
    "district_id": "531305",
    "label": "Lewoleba",
    "value": "Lewoleba"
  },
  {
    "id": "5313051002",
    "district_id": "531305",
    "label": "Lewoleba Timur",
    "value": "Lewoleba Timur"
  },
  {
    "id": "5313051003",
    "district_id": "531305",
    "label": "Lewoleba Tengah",
    "value": "Lewoleba Tengah"
  },
  {
    "id": "5313051004",
    "district_id": "531305",
    "label": "Lewoleba Utara",
    "value": "Lewoleba Utara"
  },
  {
    "id": "5313051015",
    "district_id": "531305",
    "label": "Selandoro",
    "value": "Selandoro"
  },
  {
    "id": "5313051016",
    "district_id": "531305",
    "label": "Lewoleba Selatan",
    "value": "Lewoleba Selatan"
  },
  {
    "id": "5313051017",
    "district_id": "531305",
    "label": "Lewoleba Barat",
    "value": "Lewoleba Barat"
  },
  {
    "id": "5313052006",
    "district_id": "531305",
    "label": "Baolangu",
    "value": "Baolangu"
  },
  {
    "id": "5313052007",
    "district_id": "531305",
    "label": "Nubamado",
    "value": "Nubamado"
  },
  {
    "id": "5313052008",
    "district_id": "531305",
    "label": "Watokobu",
    "value": "Watokobu"
  },
  {
    "id": "5313052009",
    "district_id": "531305",
    "label": "Belobatang",
    "value": "Belobatang"
  },
  {
    "id": "5313052010",
    "district_id": "531305",
    "label": "Waijarang",
    "value": "Waijarang"
  },
  {
    "id": "5313052011",
    "district_id": "531305",
    "label": "Udak Melomata",
    "value": "Udak Melomata"
  },
  {
    "id": "5313052012",
    "district_id": "531305",
    "label": "Paubokol",
    "value": "Paubokol"
  },
  {
    "id": "5313052013",
    "district_id": "531305",
    "label": "Pada",
    "value": "Pada"
  },
  {
    "id": "5313052014",
    "district_id": "531305",
    "label": "Lite Ulu Mado",
    "value": "Lite Ulu Mado"
  },
  {
    "id": "5313052018",
    "district_id": "531305",
    "label": "Bakalerek",
    "value": "Bakalerek"
  },
  {
    "id": "5313052019",
    "district_id": "531305",
    "label": "Bour",
    "value": "Bour"
  },
  {
    "id": "5313062001",
    "district_id": "531306",
    "label": "Balauring",
    "value": "Balauring"
  },
  {
    "id": "5313062002",
    "district_id": "531306",
    "label": "Nilanapo",
    "value": "Nilanapo"
  },
  {
    "id": "5313062003",
    "district_id": "531306",
    "label": "Leubatang",
    "value": "Leubatang"
  },
  {
    "id": "5313062004",
    "district_id": "531306",
    "label": "Walang Sawah",
    "value": "Walang Sawah"
  },
  {
    "id": "5313062005",
    "district_id": "531306",
    "label": "Mahal I",
    "value": "Mahal I"
  },
  {
    "id": "5313062006",
    "district_id": "531306",
    "label": "Aramengi",
    "value": "Aramengi"
  },
  {
    "id": "5313062007",
    "district_id": "531306",
    "label": "Meluwiting",
    "value": "Meluwiting"
  },
  {
    "id": "5313062008",
    "district_id": "531306",
    "label": "Hingalamamengi",
    "value": "Hingalamamengi"
  },
  {
    "id": "5313062009",
    "district_id": "531306",
    "label": "Dolulolong",
    "value": "Dolulolong"
  },
  {
    "id": "5313062010",
    "district_id": "531306",
    "label": "Hoelea I",
    "value": "Hoelea I"
  },
  {
    "id": "5313062011",
    "district_id": "531306",
    "label": "Roma",
    "value": "Roma"
  },
  {
    "id": "5313062012",
    "district_id": "531306",
    "label": "Leuwayang",
    "value": "Leuwayang"
  },
  {
    "id": "5313062013",
    "district_id": "531306",
    "label": "Leudanung",
    "value": "Leudanung"
  },
  {
    "id": "5313062014",
    "district_id": "531306",
    "label": "Normal",
    "value": "Normal"
  },
  {
    "id": "5313062015",
    "district_id": "531306",
    "label": "Wailolong",
    "value": "Wailolong"
  },
  {
    "id": "5313062016",
    "district_id": "531306",
    "label": "Hoelea II",
    "value": "Hoelea II"
  },
  {
    "id": "5313062017",
    "district_id": "531306",
    "label": "Lebewala",
    "value": "Lebewala"
  },
  {
    "id": "5313062018",
    "district_id": "531306",
    "label": "Mahal II",
    "value": "Mahal II"
  },
  {
    "id": "5313062019",
    "district_id": "531306",
    "label": "Peusawa",
    "value": "Peusawa"
  },
  {
    "id": "5313062020",
    "district_id": "531306",
    "label": "Wowong",
    "value": "Wowong"
  },
  {
    "id": "5313062021",
    "district_id": "531306",
    "label": "Normal I",
    "value": "Normal I"
  },
  {
    "id": "5313062022",
    "district_id": "531306",
    "label": "Meluwiting I",
    "value": "Meluwiting I"
  },
  {
    "id": "5313072001",
    "district_id": "531307",
    "label": "Panama",
    "value": "Panama"
  },
  {
    "id": "5313072002",
    "district_id": "531307",
    "label": "Benihading I",
    "value": "Benihading I"
  },
  {
    "id": "5313072003",
    "district_id": "531307",
    "label": "Atulaleng",
    "value": "Atulaleng"
  },
  {
    "id": "5313072004",
    "district_id": "531307",
    "label": "Roho",
    "value": "Roho"
  },
  {
    "id": "5313072005",
    "district_id": "531307",
    "label": "Loyobohor",
    "value": "Loyobohor"
  },
  {
    "id": "5313072006",
    "district_id": "531307",
    "label": "Leuburi",
    "value": "Leuburi"
  },
  {
    "id": "5313072007",
    "district_id": "531307",
    "label": "Kaohua",
    "value": "Kaohua"
  },
  {
    "id": "5313072008",
    "district_id": "531307",
    "label": "Kalikur WL.",
    "value": "Kalikur WL."
  },
  {
    "id": "5313072009",
    "district_id": "531307",
    "label": "Umaleu",
    "value": "Umaleu"
  },
  {
    "id": "5313072010",
    "district_id": "531307",
    "label": "Buriwutung",
    "value": "Buriwutung"
  },
  {
    "id": "5313072011",
    "district_id": "531307",
    "label": "Mampir",
    "value": "Mampir"
  },
  {
    "id": "5313072012",
    "district_id": "531307",
    "label": "Leuwohung",
    "value": "Leuwohung"
  },
  {
    "id": "5313072013",
    "district_id": "531307",
    "label": "Bareng",
    "value": "Bareng"
  },
  {
    "id": "5313072014",
    "district_id": "531307",
    "label": "Kalikur",
    "value": "Kalikur"
  },
  {
    "id": "5313072015",
    "district_id": "531307",
    "label": "Bean",
    "value": "Bean"
  },
  {
    "id": "5313072016",
    "district_id": "531307",
    "label": "Benihading II",
    "value": "Benihading II"
  },
  {
    "id": "5313072017",
    "district_id": "531307",
    "label": "Tobotani",
    "value": "Tobotani"
  },
  {
    "id": "5313072018",
    "district_id": "531307",
    "label": "Tubung Walang",
    "value": "Tubung Walang"
  },
  {
    "id": "5313072019",
    "district_id": "531307",
    "label": "Rumang",
    "value": "Rumang"
  },
  {
    "id": "5313072020",
    "district_id": "531307",
    "label": "Atu Wa'lupang",
    "value": "Atu Wa'lupang"
  },
  {
    "id": "5313082001",
    "district_id": "531308",
    "label": "Alap Atadei",
    "value": "Alap Atadei"
  },
  {
    "id": "5313082002",
    "district_id": "531308",
    "label": "Atakera",
    "value": "Atakera"
  },
  {
    "id": "5313082003",
    "district_id": "531308",
    "label": "Leworaja",
    "value": "Leworaja"
  },
  {
    "id": "5313082004",
    "district_id": "531308",
    "label": "Pantai Harapan",
    "value": "Pantai Harapan"
  },
  {
    "id": "5313082005",
    "district_id": "531308",
    "label": "Puor",
    "value": "Puor"
  },
  {
    "id": "5313082006",
    "district_id": "531308",
    "label": "Imu Lolong",
    "value": "Imu Lolong"
  },
  {
    "id": "5313082007",
    "district_id": "531308",
    "label": "Lamalera B",
    "value": "Lamalera B"
  },
  {
    "id": "5313082008",
    "district_id": "531308",
    "label": "Lamalera A",
    "value": "Lamalera A"
  },
  {
    "id": "5313082009",
    "district_id": "531308",
    "label": "Lelata",
    "value": "Lelata"
  },
  {
    "id": "5313082010",
    "district_id": "531308",
    "label": "Wulandoni",
    "value": "Wulandoni"
  },
  {
    "id": "5313082011",
    "district_id": "531308",
    "label": "Belobao",
    "value": "Belobao"
  },
  {
    "id": "5313082012",
    "district_id": "531308",
    "label": "Posiwatu",
    "value": "Posiwatu"
  },
  {
    "id": "5313082013",
    "district_id": "531308",
    "label": "Puor B",
    "value": "Puor B"
  },
  {
    "id": "5313082014",
    "district_id": "531308",
    "label": "Ataili",
    "value": "Ataili"
  },
  {
    "id": "5313082015",
    "district_id": "531308",
    "label": "Tapobali",
    "value": "Tapobali"
  },
  {
    "id": "5313092001",
    "district_id": "531309",
    "label": "Todanara",
    "value": "Todanara"
  },
  {
    "id": "5313092002",
    "district_id": "531309",
    "label": "Jontona",
    "value": "Jontona"
  },
  {
    "id": "5313092003",
    "district_id": "531309",
    "label": "Lamawolo",
    "value": "Lamawolo"
  },
  {
    "id": "5313092004",
    "district_id": "531309",
    "label": "Lamatokan",
    "value": "Lamatokan"
  },
  {
    "id": "5313092005",
    "district_id": "531309",
    "label": "Bao Lali Duli",
    "value": "Bao Lali Duli"
  },
  {
    "id": "5313092006",
    "district_id": "531309",
    "label": "Lamaau",
    "value": "Lamaau"
  },
  {
    "id": "5313092007",
    "district_id": "531309",
    "label": "Aulesa",
    "value": "Aulesa"
  },
  {
    "id": "5313092008",
    "district_id": "531309",
    "label": "Waimatan",
    "value": "Waimatan"
  },
  {
    "id": "5313092009",
    "district_id": "531309",
    "label": "Lamagute",
    "value": "Lamagute"
  },
  {
    "id": "5314012005",
    "district_id": "531401",
    "label": "Oeseli",
    "value": "Oeseli"
  },
  {
    "id": "5314012006",
    "district_id": "531401",
    "label": "Oebou",
    "value": "Oebou"
  },
  {
    "id": "5314012007",
    "district_id": "531401",
    "label": "Lalukoen",
    "value": "Lalukoen"
  },
  {
    "id": "5314012008",
    "district_id": "531401",
    "label": "Oehandi",
    "value": "Oehandi"
  },
  {
    "id": "5314012009",
    "district_id": "531401",
    "label": "Oetefu",
    "value": "Oetefu"
  },
  {
    "id": "5314012010",
    "district_id": "531401",
    "label": "Batutua",
    "value": "Batutua"
  },
  {
    "id": "5314012011",
    "district_id": "531401",
    "label": "Meoain",
    "value": "Meoain"
  },
  {
    "id": "5314012012",
    "district_id": "531401",
    "label": "Oebafok",
    "value": "Oebafok"
  },
  {
    "id": "5314012013",
    "district_id": "531401",
    "label": "Oebatu",
    "value": "Oebatu"
  },
  {
    "id": "5314012014",
    "district_id": "531401",
    "label": "Mbokak",
    "value": "Mbokak"
  },
  {
    "id": "5314012015",
    "district_id": "531401",
    "label": "Lekik",
    "value": "Lekik"
  },
  {
    "id": "5314012016",
    "district_id": "531401",
    "label": "Dolasi",
    "value": "Dolasi"
  },
  {
    "id": "5314012017",
    "district_id": "531401",
    "label": "Oelasin",
    "value": "Oelasin"
  },
  {
    "id": "5314012018",
    "district_id": "531401",
    "label": "Landu",
    "value": "Landu"
  },
  {
    "id": "5314012019",
    "district_id": "531401",
    "label": "Lentera",
    "value": "Lentera"
  },
  {
    "id": "5314012020",
    "district_id": "531401",
    "label": "Sanggandolu",
    "value": "Sanggandolu"
  },
  {
    "id": "5314012021",
    "district_id": "531401",
    "label": "Sakubatun",
    "value": "Sakubatun"
  },
  {
    "id": "5314012022",
    "district_id": "531401",
    "label": "Fuafuni",
    "value": "Fuafuni"
  },
  {
    "id": "5314012023",
    "district_id": "531401",
    "label": "Dalek Esa",
    "value": "Dalek Esa"
  },
  {
    "id": "5314021008",
    "district_id": "531402",
    "label": "Busalangga",
    "value": "Busalangga"
  },
  {
    "id": "5314022004",
    "district_id": "531402",
    "label": "Temas",
    "value": "Temas"
  },
  {
    "id": "5314022005",
    "district_id": "531402",
    "label": "Boni",
    "value": "Boni"
  },
  {
    "id": "5314022006",
    "district_id": "531402",
    "label": "Oelua",
    "value": "Oelua"
  },
  {
    "id": "5314022007",
    "district_id": "531402",
    "label": "Modosinal",
    "value": "Modosinal"
  },
  {
    "id": "5314022009",
    "district_id": "531402",
    "label": "Netenaen",
    "value": "Netenaen"
  },
  {
    "id": "5314022010",
    "district_id": "531402",
    "label": "Lidor",
    "value": "Lidor"
  },
  {
    "id": "5314022011",
    "district_id": "531402",
    "label": "Tolama",
    "value": "Tolama"
  },
  {
    "id": "5314022012",
    "district_id": "531402",
    "label": "Ingguinak",
    "value": "Ingguinak"
  },
  {
    "id": "5314022013",
    "district_id": "531402",
    "label": "Oetutulu",
    "value": "Oetutulu"
  },
  {
    "id": "5314022014",
    "district_id": "531402",
    "label": "Daudolu",
    "value": "Daudolu"
  },
  {
    "id": "5314022015",
    "district_id": "531402",
    "label": "Oebela",
    "value": "Oebela"
  },
  {
    "id": "5314022017",
    "district_id": "531402",
    "label": "Tualima",
    "value": "Tualima"
  },
  {
    "id": "5314022018",
    "district_id": "531402",
    "label": "Holulai",
    "value": "Holulai"
  },
  {
    "id": "5314022019",
    "district_id": "531402",
    "label": "Hundihuk",
    "value": "Hundihuk"
  },
  {
    "id": "5314022020",
    "district_id": "531402",
    "label": "Oebole",
    "value": "Oebole"
  },
  {
    "id": "5314022021",
    "district_id": "531402",
    "label": "Saindule",
    "value": "Saindule"
  },
  {
    "id": "5314022022",
    "district_id": "531402",
    "label": "Tasilo",
    "value": "Tasilo"
  },
  {
    "id": "5314022023",
    "district_id": "531402",
    "label": "Balaoli",
    "value": "Balaoli"
  },
  {
    "id": "5314022024",
    "district_id": "531402",
    "label": "Mundek",
    "value": "Mundek"
  },
  {
    "id": "5314022025",
    "district_id": "531402",
    "label": "Busalangga Timur",
    "value": "Busalangga Timur"
  },
  {
    "id": "5314022026",
    "district_id": "531402",
    "label": "Busalangga Barat",
    "value": "Busalangga Barat"
  },
  {
    "id": "5314031001",
    "district_id": "531403",
    "label": "Namodale",
    "value": "Namodale"
  },
  {
    "id": "5314031009",
    "district_id": "531403",
    "label": "Mokdale",
    "value": "Mokdale"
  },
  {
    "id": "5314031011",
    "district_id": "531403",
    "label": "Metina",
    "value": "Metina"
  },
  {
    "id": "5314032002",
    "district_id": "531403",
    "label": "Kuli",
    "value": "Kuli"
  },
  {
    "id": "5314032003",
    "district_id": "531403",
    "label": "Bebalain",
    "value": "Bebalain"
  },
  {
    "id": "5314032004",
    "district_id": "531403",
    "label": "Kolobolon",
    "value": "Kolobolon"
  },
  {
    "id": "5314032005",
    "district_id": "531403",
    "label": "Helebeik",
    "value": "Helebeik"
  },
  {
    "id": "5314032006",
    "district_id": "531403",
    "label": "Sanggaoen",
    "value": "Sanggaoen"
  },
  {
    "id": "5314032007",
    "district_id": "531403",
    "label": "Holoama",
    "value": "Holoama"
  },
  {
    "id": "5314032008",
    "district_id": "531403",
    "label": "Tuanatuk",
    "value": "Tuanatuk"
  },
  {
    "id": "5314032010",
    "district_id": "531403",
    "label": "Oelunggu",
    "value": "Oelunggu"
  },
  {
    "id": "5314032012",
    "district_id": "531403",
    "label": "Oematamboli",
    "value": "Oematamboli"
  },
  {
    "id": "5314032013",
    "district_id": "531403",
    "label": "Suelain",
    "value": "Suelain"
  },
  {
    "id": "5314032014",
    "district_id": "531403",
    "label": "Baadale",
    "value": "Baadale"
  },
  {
    "id": "5314032015",
    "district_id": "531403",
    "label": "Lekunik",
    "value": "Lekunik"
  },
  {
    "id": "5314032016",
    "district_id": "531403",
    "label": "Loleoen",
    "value": "Loleoen"
  },
  {
    "id": "5314032017",
    "district_id": "531403",
    "label": "Kuli Aisele",
    "value": "Kuli Aisele"
  },
  {
    "id": "5314032018",
    "district_id": "531403",
    "label": "Oeleka",
    "value": "Oeleka"
  },
  {
    "id": "5314041002",
    "district_id": "531404",
    "label": "Onatali",
    "value": "Onatali"
  },
  {
    "id": "5314042001",
    "district_id": "531404",
    "label": "Maubesi",
    "value": "Maubesi"
  },
  {
    "id": "5314042003",
    "district_id": "531404",
    "label": "Nggodimeda",
    "value": "Nggodimeda"
  },
  {
    "id": "5314042004",
    "district_id": "531404",
    "label": "Limakoli",
    "value": "Limakoli"
  },
  {
    "id": "5314042005",
    "district_id": "531404",
    "label": "Lidamanu",
    "value": "Lidamanu"
  },
  {
    "id": "5314042010",
    "district_id": "531404",
    "label": "Suebela",
    "value": "Suebela"
  },
  {
    "id": "5314042011",
    "district_id": "531404",
    "label": "Lidabesi",
    "value": "Lidabesi"
  },
  {
    "id": "5314042013",
    "district_id": "531404",
    "label": "Siomeda",
    "value": "Siomeda"
  },
  {
    "id": "5314051009",
    "district_id": "531405",
    "label": "Olafulihaa",
    "value": "Olafulihaa"
  },
  {
    "id": "5314052001",
    "district_id": "531405",
    "label": "Nusakdale",
    "value": "Nusakdale"
  },
  {
    "id": "5314052002",
    "district_id": "531405",
    "label": "Batulilok",
    "value": "Batulilok"
  },
  {
    "id": "5314052003",
    "district_id": "531405",
    "label": "Lenupetu",
    "value": "Lenupetu"
  },
  {
    "id": "5314052004",
    "district_id": "531405",
    "label": "Sonimanu",
    "value": "Sonimanu"
  },
  {
    "id": "5314052005",
    "district_id": "531405",
    "label": "Oebau",
    "value": "Oebau"
  },
  {
    "id": "5314052006",
    "district_id": "531405",
    "label": "Oeledo",
    "value": "Oeledo"
  },
  {
    "id": "5314052007",
    "district_id": "531405",
    "label": "Keoen",
    "value": "Keoen"
  },
  {
    "id": "5314052008",
    "district_id": "531405",
    "label": "Edalode",
    "value": "Edalode"
  },
  {
    "id": "5314052010",
    "district_id": "531405",
    "label": "Tungganamo",
    "value": "Tungganamo"
  },
  {
    "id": "5314052011",
    "district_id": "531405",
    "label": "Tesa Bela",
    "value": "Tesa Bela"
  },
  {
    "id": "5314052012",
    "district_id": "531405",
    "label": "Lekona",
    "value": "Lekona"
  },
  {
    "id": "5314052013",
    "district_id": "531405",
    "label": "Oenggae",
    "value": "Oenggae"
  },
  {
    "id": "5314052014",
    "district_id": "531405",
    "label": "Fatelilo",
    "value": "Fatelilo"
  },
  {
    "id": "5314052015",
    "district_id": "531405",
    "label": "Ofalangga",
    "value": "Ofalangga"
  },
  {
    "id": "5314061004",
    "district_id": "531406",
    "label": "Londalusi",
    "value": "Londalusi"
  },
  {
    "id": "5314062001",
    "district_id": "531406",
    "label": "Mukekuku",
    "value": "Mukekuku"
  },
  {
    "id": "5314062002",
    "district_id": "531406",
    "label": "Faifua",
    "value": "Faifua"
  },
  {
    "id": "5314062003",
    "district_id": "531406",
    "label": "Hundihopo",
    "value": "Hundihopo"
  },
  {
    "id": "5314062005",
    "district_id": "531406",
    "label": "Serubeba",
    "value": "Serubeba"
  },
  {
    "id": "5314062006",
    "district_id": "531406",
    "label": "Lakamola",
    "value": "Lakamola"
  },
  {
    "id": "5314062007",
    "district_id": "531406",
    "label": "Matasio",
    "value": "Matasio"
  },
  {
    "id": "5314062014",
    "district_id": "531406",
    "label": "Pengodua",
    "value": "Pengodua"
  },
  {
    "id": "5314062015",
    "district_id": "531406",
    "label": "Batefalu",
    "value": "Batefalu"
  },
  {
    "id": "5314062016",
    "district_id": "531406",
    "label": "Papela",
    "value": "Papela"
  },
  {
    "id": "5314062017",
    "district_id": "531406",
    "label": "Matanae",
    "value": "Matanae"
  },
  {
    "id": "5314072001",
    "district_id": "531407",
    "label": "Sedeoen",
    "value": "Sedeoen"
  },
  {
    "id": "5314072002",
    "district_id": "531407",
    "label": "Nemberala",
    "value": "Nemberala"
  },
  {
    "id": "5314072003",
    "district_id": "531407",
    "label": "Oenggaut",
    "value": "Oenggaut"
  },
  {
    "id": "5314072004",
    "district_id": "531407",
    "label": "Bo'a",
    "value": "Bo'a"
  },
  {
    "id": "5314072005",
    "district_id": "531407",
    "label": "Oenitas",
    "value": "Oenitas"
  },
  {
    "id": "5314072006",
    "district_id": "531407",
    "label": "Oelolok",
    "value": "Oelolok"
  },
  {
    "id": "5314072007",
    "district_id": "531407",
    "label": "Mbueain",
    "value": "Mbueain"
  },
  {
    "id": "5314082001",
    "district_id": "531408",
    "label": "Lenguselu",
    "value": "Lenguselu"
  },
  {
    "id": "5314082002",
    "district_id": "531408",
    "label": "Daleholu",
    "value": "Daleholu"
  },
  {
    "id": "5314082003",
    "district_id": "531408",
    "label": "Dodaek",
    "value": "Dodaek"
  },
  {
    "id": "5314082004",
    "district_id": "531408",
    "label": "Tebole",
    "value": "Tebole"
  },
  {
    "id": "5314082005",
    "district_id": "531408",
    "label": "Inaoe",
    "value": "Inaoe"
  },
  {
    "id": "5314082006",
    "district_id": "531408",
    "label": "Nggelodae",
    "value": "Nggelodae"
  },
  {
    "id": "5314082007",
    "district_id": "531408",
    "label": "Pilasue",
    "value": "Pilasue"
  },
  {
    "id": "5314092001",
    "district_id": "531409",
    "label": "Mbali Lendeiki",
    "value": "Mbali Lendeiki"
  },
  {
    "id": "5314092002",
    "district_id": "531409",
    "label": "Anarae",
    "value": "Anarae"
  },
  {
    "id": "5314092003",
    "district_id": "531409",
    "label": "Mbiu Lombo",
    "value": "Mbiu Lombo"
  },
  {
    "id": "5314092004",
    "district_id": "531409",
    "label": "Nuse",
    "value": "Nuse"
  },
  {
    "id": "5314092005",
    "district_id": "531409",
    "label": "Ndaonuse",
    "value": "Ndaonuse"
  },
  {
    "id": "5314102001",
    "district_id": "531410",
    "label": "Bolatena",
    "value": "Bolatena"
  },
  {
    "id": "5314102002",
    "district_id": "531410",
    "label": "Lifuleo",
    "value": "Lifuleo"
  },
  {
    "id": "5314102003",
    "district_id": "531410",
    "label": "Sotimori",
    "value": "Sotimori"
  },
  {
    "id": "5314102004",
    "district_id": "531410",
    "label": "Daeurendale",
    "value": "Daeurendale"
  },
  {
    "id": "5314102005",
    "district_id": "531410",
    "label": "Daiama",
    "value": "Daiama"
  },
  {
    "id": "5314102006",
    "district_id": "531410",
    "label": "Pukuafu",
    "value": "Pukuafu"
  },
  {
    "id": "5314102007",
    "district_id": "531410",
    "label": "Tena Lai",
    "value": "Tena Lai"
  },
  {
    "id": "5315012001",
    "district_id": "531501",
    "label": "Bari",
    "value": "Bari"
  },
  {
    "id": "5315012002",
    "district_id": "531501",
    "label": "Rokap",
    "value": "Rokap"
  },
  {
    "id": "5315012003",
    "district_id": "531501",
    "label": "Mbakung",
    "value": "Mbakung"
  },
  {
    "id": "5315012004",
    "district_id": "531501",
    "label": "Nggilat",
    "value": "Nggilat"
  },
  {
    "id": "5315012005",
    "district_id": "531501",
    "label": "Rego",
    "value": "Rego"
  },
  {
    "id": "5315012006",
    "district_id": "531501",
    "label": "Wontong",
    "value": "Wontong"
  },
  {
    "id": "5315012007",
    "district_id": "531501",
    "label": "Raba",
    "value": "Raba"
  },
  {
    "id": "5315012008",
    "district_id": "531501",
    "label": "Nanga Kantor",
    "value": "Nanga Kantor"
  },
  {
    "id": "5315012014",
    "district_id": "531501",
    "label": "Watubaru",
    "value": "Watubaru"
  },
  {
    "id": "5315012015",
    "district_id": "531501",
    "label": "Lewat",
    "value": "Lewat"
  },
  {
    "id": "5315012016",
    "district_id": "531501",
    "label": "Watu Manggar",
    "value": "Watu Manggar"
  },
  {
    "id": "5315012025",
    "district_id": "531501",
    "label": "Sarae Naru",
    "value": "Sarae Naru"
  },
  {
    "id": "5315012026",
    "district_id": "531501",
    "label": "Nanga Kantor Barat",
    "value": "Nanga Kantor Barat"
  },
  {
    "id": "5315021022",
    "district_id": "531502",
    "label": "Golo Ru'u",
    "value": "Golo Ru'u"
  },
  {
    "id": "5315021023",
    "district_id": "531502",
    "label": "Nantal",
    "value": "Nantal"
  },
  {
    "id": "5315022002",
    "district_id": "531502",
    "label": "Coal",
    "value": "Coal"
  },
  {
    "id": "5315022004",
    "district_id": "531502",
    "label": "Pangga",
    "value": "Pangga"
  },
  {
    "id": "5315022010",
    "district_id": "531502",
    "label": "Lewur",
    "value": "Lewur"
  },
  {
    "id": "5315022011",
    "district_id": "531502",
    "label": "Lawi",
    "value": "Lawi"
  },
  {
    "id": "5315022017",
    "district_id": "531502",
    "label": "Compang Suka",
    "value": "Compang Suka"
  },
  {
    "id": "5315022018",
    "district_id": "531502",
    "label": "Benteng Suru",
    "value": "Benteng Suru"
  },
  {
    "id": "5315022021",
    "district_id": "531502",
    "label": "Sama",
    "value": "Sama"
  },
  {
    "id": "5315022030",
    "district_id": "531502",
    "label": "Bangka Lewat",
    "value": "Bangka Lewat"
  },
  {
    "id": "5315022031",
    "district_id": "531502",
    "label": "Suka Kiong",
    "value": "Suka Kiong"
  },
  {
    "id": "5315022032",
    "district_id": "531502",
    "label": "Golo Pua",
    "value": "Golo Pua"
  },
  {
    "id": "5315031032",
    "district_id": "531503",
    "label": "Tangge",
    "value": "Tangge"
  },
  {
    "id": "5315032003",
    "district_id": "531503",
    "label": "Wae Wako",
    "value": "Wae Wako"
  },
  {
    "id": "5315032010",
    "district_id": "531503",
    "label": "Wae Kanta",
    "value": "Wae Kanta"
  },
  {
    "id": "5315032011",
    "district_id": "531503",
    "label": "Ponto Ara",
    "value": "Ponto Ara"
  },
  {
    "id": "5315032012",
    "district_id": "531503",
    "label": "Wae Bangka",
    "value": "Wae Bangka"
  },
  {
    "id": "5315032013",
    "district_id": "531503",
    "label": "Pong Majok",
    "value": "Pong Majok"
  },
  {
    "id": "5315032014",
    "district_id": "531503",
    "label": "Daleng",
    "value": "Daleng"
  },
  {
    "id": "5315032021",
    "district_id": "531503",
    "label": "Siru",
    "value": "Siru"
  },
  {
    "id": "5315032022",
    "district_id": "531503",
    "label": "Pondo",
    "value": "Pondo"
  },
  {
    "id": "5315032023",
    "district_id": "531503",
    "label": "Poco Rutang",
    "value": "Poco Rutang"
  },
  {
    "id": "5315032025",
    "district_id": "531503",
    "label": "Ngancar",
    "value": "Ngancar"
  },
  {
    "id": "5315032033",
    "district_id": "531503",
    "label": "LIang Sola",
    "value": "LIang Sola"
  },
  {
    "id": "5315032034",
    "district_id": "531503",
    "label": "Wae Mowol",
    "value": "Wae Mowol"
  },
  {
    "id": "5315032035",
    "district_id": "531503",
    "label": "Golo Ndeweng",
    "value": "Golo Ndeweng"
  },
  {
    "id": "5315032036",
    "district_id": "531503",
    "label": "Poco Dedeng",
    "value": "Poco Dedeng"
  },
  {
    "id": "5315042001",
    "district_id": "531504",
    "label": "Golo Ndaring",
    "value": "Golo Ndaring"
  },
  {
    "id": "5315042005",
    "district_id": "531504",
    "label": "Golo Kempo",
    "value": "Golo Kempo"
  },
  {
    "id": "5315042008",
    "district_id": "531504",
    "label": "Golo Manting",
    "value": "Golo Manting"
  },
  {
    "id": "5315042009",
    "district_id": "531504",
    "label": "Matawae",
    "value": "Matawae"
  },
  {
    "id": "5315042011",
    "district_id": "531504",
    "label": "Nampar Macing",
    "value": "Nampar Macing"
  },
  {
    "id": "5315042014",
    "district_id": "531504",
    "label": "Wae Sano",
    "value": "Wae Sano"
  },
  {
    "id": "5315042016",
    "district_id": "531504",
    "label": "Golo Mbu",
    "value": "Golo Mbu"
  },
  {
    "id": "5315042017",
    "district_id": "531504",
    "label": "Golo Leleng",
    "value": "Golo Leleng"
  },
  {
    "id": "5315042018",
    "district_id": "531504",
    "label": "Sano Nggoang",
    "value": "Sano Nggoang"
  },
  {
    "id": "5315042020",
    "district_id": "531504",
    "label": "Wae Lolos",
    "value": "Wae Lolos"
  },
  {
    "id": "5315042024",
    "district_id": "531504",
    "label": "Golo Sengang",
    "value": "Golo Sengang"
  },
  {
    "id": "5315042025",
    "district_id": "531504",
    "label": "Watu Panggal",
    "value": "Watu Panggal"
  },
  {
    "id": "5315042026",
    "district_id": "531504",
    "label": "Golo Kondeng",
    "value": "Golo Kondeng"
  },
  {
    "id": "5315042027",
    "district_id": "531504",
    "label": "Poco Golo Kempo",
    "value": "Poco Golo Kempo"
  },
  {
    "id": "5315042028",
    "district_id": "531504",
    "label": "Pulau Nuncung",
    "value": "Pulau Nuncung"
  },
  {
    "id": "5315051024",
    "district_id": "531505",
    "label": "Wae Kelambu",
    "value": "Wae Kelambu"
  },
  {
    "id": "5315051025",
    "district_id": "531505",
    "label": "Labuan Bajo",
    "value": "Labuan Bajo"
  },
  {
    "id": "5315052001",
    "district_id": "531505",
    "label": "Komodo",
    "value": "Komodo"
  },
  {
    "id": "5315052002",
    "district_id": "531505",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "5315052003",
    "district_id": "531505",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "5315052004",
    "district_id": "531505",
    "label": "Golo Mori",
    "value": "Golo Mori"
  },
  {
    "id": "5315052005",
    "district_id": "531505",
    "label": "Warloka",
    "value": "Warloka"
  },
  {
    "id": "5315052007",
    "district_id": "531505",
    "label": "Golo Bilas",
    "value": "Golo Bilas"
  },
  {
    "id": "5315052008",
    "district_id": "531505",
    "label": "Macang Tanggar",
    "value": "Macang Tanggar"
  },
  {
    "id": "5315052009",
    "district_id": "531505",
    "label": "Watu Nggelek",
    "value": "Watu Nggelek"
  },
  {
    "id": "5315052015",
    "district_id": "531505",
    "label": "Golo Pongkor",
    "value": "Golo Pongkor"
  },
  {
    "id": "5315052016",
    "district_id": "531505",
    "label": "Nggorang",
    "value": "Nggorang"
  },
  {
    "id": "5315052018",
    "district_id": "531505",
    "label": "Papa Garang",
    "value": "Papa Garang"
  },
  {
    "id": "5315052019",
    "district_id": "531505",
    "label": "Batu Cermin",
    "value": "Batu Cermin"
  },
  {
    "id": "5315052020",
    "district_id": "531505",
    "label": "Gorontalo",
    "value": "Gorontalo"
  },
  {
    "id": "5315052022",
    "district_id": "531505",
    "label": "Tiwu Nampar",
    "value": "Tiwu Nampar"
  },
  {
    "id": "5315052026",
    "district_id": "531505",
    "label": "Seraya Marannu",
    "value": "Seraya Marannu"
  },
  {
    "id": "5315052027",
    "district_id": "531505",
    "label": "Compang Longgo",
    "value": "Compang Longgo"
  },
  {
    "id": "5315052028",
    "district_id": "531505",
    "label": "Pantar",
    "value": "Pantar"
  },
  {
    "id": "5315062001",
    "district_id": "531506",
    "label": "Mbuit",
    "value": "Mbuit"
  },
  {
    "id": "5315062002",
    "district_id": "531506",
    "label": "Golo Ketak",
    "value": "Golo Ketak"
  },
  {
    "id": "5315062003",
    "district_id": "531506",
    "label": "Pota Wangka",
    "value": "Pota Wangka"
  },
  {
    "id": "5315062004",
    "district_id": "531506",
    "label": "Tanjung Boleng",
    "value": "Tanjung Boleng"
  },
  {
    "id": "5315062005",
    "district_id": "531506",
    "label": "Golo Sepang",
    "value": "Golo Sepang"
  },
  {
    "id": "5315062006",
    "district_id": "531506",
    "label": "Pontianak",
    "value": "Pontianak"
  },
  {
    "id": "5315062007",
    "district_id": "531506",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "5315062008",
    "district_id": "531506",
    "label": "Golo Lujang",
    "value": "Golo Lujang"
  },
  {
    "id": "5315062009",
    "district_id": "531506",
    "label": "Batu Tiga",
    "value": "Batu Tiga"
  },
  {
    "id": "5315062020",
    "district_id": "531506",
    "label": "Golo Nobo",
    "value": "Golo Nobo"
  },
  {
    "id": "5315062021",
    "district_id": "531506",
    "label": "Beo Sepang",
    "value": "Beo Sepang"
  },
  {
    "id": "5315072001",
    "district_id": "531507",
    "label": "Golo Ronggot",
    "value": "Golo Ronggot"
  },
  {
    "id": "5315072002",
    "district_id": "531507",
    "label": "Semang",
    "value": "Semang"
  },
  {
    "id": "5315072003",
    "district_id": "531507",
    "label": "Dunta",
    "value": "Dunta"
  },
  {
    "id": "5315072004",
    "district_id": "531507",
    "label": "Gurung",
    "value": "Gurung"
  },
  {
    "id": "5315072005",
    "district_id": "531507",
    "label": "Orong",
    "value": "Orong"
  },
  {
    "id": "5315072006",
    "district_id": "531507",
    "label": "Pong Welak",
    "value": "Pong Welak"
  },
  {
    "id": "5315072007",
    "district_id": "531507",
    "label": "Galang",
    "value": "Galang"
  },
  {
    "id": "5315072008",
    "district_id": "531507",
    "label": "Rehak",
    "value": "Rehak"
  },
  {
    "id": "5315072009",
    "district_id": "531507",
    "label": "Watu Umpu",
    "value": "Watu Umpu"
  },
  {
    "id": "5315072010",
    "district_id": "531507",
    "label": "Pengka",
    "value": "Pengka"
  },
  {
    "id": "5315072011",
    "district_id": "531507",
    "label": "Lale",
    "value": "Lale"
  },
  {
    "id": "5315072015",
    "district_id": "531507",
    "label": "Racang Welak",
    "value": "Racang Welak"
  },
  {
    "id": "5315072016",
    "district_id": "531507",
    "label": "Sewar",
    "value": "Sewar"
  },
  {
    "id": "5315072017",
    "district_id": "531507",
    "label": "Robo",
    "value": "Robo"
  },
  {
    "id": "5315072018",
    "district_id": "531507",
    "label": "Wewa",
    "value": "Wewa"
  },
  {
    "id": "5315072019",
    "district_id": "531507",
    "label": "Golo Ndari",
    "value": "Golo Ndari"
  },
  {
    "id": "5315082001",
    "district_id": "531508",
    "label": "Golo Poleng",
    "value": "Golo Poleng"
  },
  {
    "id": "5315082002",
    "district_id": "531508",
    "label": "Tentang",
    "value": "Tentang"
  },
  {
    "id": "5315082003",
    "district_id": "531508",
    "label": "Raka",
    "value": "Raka"
  },
  {
    "id": "5315082004",
    "district_id": "531508",
    "label": "Kasong",
    "value": "Kasong"
  },
  {
    "id": "5315082005",
    "district_id": "531508",
    "label": "Ndoso",
    "value": "Ndoso"
  },
  {
    "id": "5315082006",
    "district_id": "531508",
    "label": "Waning",
    "value": "Waning"
  },
  {
    "id": "5315082007",
    "district_id": "531508",
    "label": "Wae Buka",
    "value": "Wae Buka"
  },
  {
    "id": "5315082008",
    "district_id": "531508",
    "label": "Momol",
    "value": "Momol"
  },
  {
    "id": "5315082009",
    "district_id": "531508",
    "label": "Lumut",
    "value": "Lumut"
  },
  {
    "id": "5315082010",
    "district_id": "531508",
    "label": "Pong Narang",
    "value": "Pong Narang"
  },
  {
    "id": "5315082011",
    "district_id": "531508",
    "label": "Golo Bore",
    "value": "Golo Bore"
  },
  {
    "id": "5315082012",
    "district_id": "531508",
    "label": "Pateng Lesuh",
    "value": "Pateng Lesuh"
  },
  {
    "id": "5315082013",
    "district_id": "531508",
    "label": "Tehong",
    "value": "Tehong"
  },
  {
    "id": "5315082014",
    "district_id": "531508",
    "label": "Golo Ru'a",
    "value": "Golo Ru'a"
  },
  {
    "id": "5315082015",
    "district_id": "531508",
    "label": "Golo Keli",
    "value": "Golo Keli"
  },
  {
    "id": "5315092001",
    "district_id": "531509",
    "label": "Repi",
    "value": "Repi"
  },
  {
    "id": "5315092002",
    "district_id": "531509",
    "label": "Watu Waja",
    "value": "Watu Waja"
  },
  {
    "id": "5315092003",
    "district_id": "531509",
    "label": "Surunumbeng",
    "value": "Surunumbeng"
  },
  {
    "id": "5315092004",
    "district_id": "531509",
    "label": "Munting",
    "value": "Munting"
  },
  {
    "id": "5315092005",
    "district_id": "531509",
    "label": "Kakor",
    "value": "Kakor"
  },
  {
    "id": "5315092006",
    "district_id": "531509",
    "label": "Lalong",
    "value": "Lalong"
  },
  {
    "id": "5315092007",
    "district_id": "531509",
    "label": "Wae Mose",
    "value": "Wae Mose"
  },
  {
    "id": "5315092008",
    "district_id": "531509",
    "label": "Nanga Lili",
    "value": "Nanga Lili"
  },
  {
    "id": "5315092009",
    "district_id": "531509",
    "label": "Nanga Bere",
    "value": "Nanga Bere"
  },
  {
    "id": "5315092010",
    "district_id": "531509",
    "label": "Benteng Dewa",
    "value": "Benteng Dewa"
  },
  {
    "id": "5315092011",
    "district_id": "531509",
    "label": "Watu Rambung",
    "value": "Watu Rambung"
  },
  {
    "id": "5315092012",
    "district_id": "531509",
    "label": "Lendong",
    "value": "Lendong"
  },
  {
    "id": "5315092013",
    "district_id": "531509",
    "label": "Watu Tiri",
    "value": "Watu Tiri"
  },
  {
    "id": "5315092014",
    "district_id": "531509",
    "label": "Benteng Tado",
    "value": "Benteng Tado"
  },
  {
    "id": "5315092015",
    "district_id": "531509",
    "label": "Modo",
    "value": "Modo"
  },
  {
    "id": "5315102001",
    "district_id": "531510",
    "label": "Liang Ndara",
    "value": "Liang Ndara"
  },
  {
    "id": "5315102002",
    "district_id": "531510",
    "label": "Tandong Belang",
    "value": "Tandong Belang"
  },
  {
    "id": "5315102003",
    "district_id": "531510",
    "label": "Cunca Lolos",
    "value": "Cunca Lolos"
  },
  {
    "id": "5315102004",
    "district_id": "531510",
    "label": "Cunca Wulang",
    "value": "Cunca Wulang"
  },
  {
    "id": "5315102005",
    "district_id": "531510",
    "label": "Golo Damu",
    "value": "Golo Damu"
  },
  {
    "id": "5315102006",
    "district_id": "531510",
    "label": "Golo Ndoal",
    "value": "Golo Ndoal"
  },
  {
    "id": "5315102007",
    "district_id": "531510",
    "label": "Golo Sembea",
    "value": "Golo Sembea"
  },
  {
    "id": "5315102008",
    "district_id": "531510",
    "label": "Golo Desat",
    "value": "Golo Desat"
  },
  {
    "id": "5315102009",
    "district_id": "531510",
    "label": "Kempo",
    "value": "Kempo"
  },
  {
    "id": "5315102010",
    "district_id": "531510",
    "label": "Wae Jare",
    "value": "Wae Jare"
  },
  {
    "id": "5315102011",
    "district_id": "531510",
    "label": "Golo Tantong",
    "value": "Golo Tantong"
  },
  {
    "id": "5315102012",
    "district_id": "531510",
    "label": "Tiwi Riwung",
    "value": "Tiwi Riwung"
  },
  {
    "id": "5315102013",
    "district_id": "531510",
    "label": "Watu Wangka",
    "value": "Watu Wangka"
  },
  {
    "id": "5315102014",
    "district_id": "531510",
    "label": "Compang Liang Ndara",
    "value": "Compang Liang Ndara"
  },
  {
    "id": "5315102015",
    "district_id": "531510",
    "label": "Watu Galang",
    "value": "Watu Galang"
  },
  {
    "id": "5315112001",
    "district_id": "531511",
    "label": "Pacar",
    "value": "Pacar"
  },
  {
    "id": "5315112002",
    "district_id": "531511",
    "label": "Manong",
    "value": "Manong"
  },
  {
    "id": "5315112003",
    "district_id": "531511",
    "label": "Romang",
    "value": "Romang"
  },
  {
    "id": "5315112004",
    "district_id": "531511",
    "label": "Compang",
    "value": "Compang"
  },
  {
    "id": "5315112005",
    "district_id": "531511",
    "label": "Waka",
    "value": "Waka"
  },
  {
    "id": "5315112006",
    "district_id": "531511",
    "label": "Pong Kolong",
    "value": "Pong Kolong"
  },
  {
    "id": "5315112007",
    "district_id": "531511",
    "label": "Loha",
    "value": "Loha"
  },
  {
    "id": "5315112008",
    "district_id": "531511",
    "label": "Benteng Ndope",
    "value": "Benteng Ndope"
  },
  {
    "id": "5315112009",
    "district_id": "531511",
    "label": "Golo Lajang",
    "value": "Golo Lajang"
  },
  {
    "id": "5315112010",
    "district_id": "531511",
    "label": "Golo Lajang Barat",
    "value": "Golo Lajang Barat"
  },
  {
    "id": "5315112011",
    "district_id": "531511",
    "label": "Kombo",
    "value": "Kombo"
  },
  {
    "id": "5315112012",
    "district_id": "531511",
    "label": "Kombo Selatan",
    "value": "Kombo Selatan"
  },
  {
    "id": "5315112013",
    "district_id": "531511",
    "label": "Kombo Tengah",
    "value": "Kombo Tengah"
  },
  {
    "id": "5315122001",
    "district_id": "531512",
    "label": "Golo Lewe",
    "value": "Golo Lewe"
  },
  {
    "id": "5315122002",
    "district_id": "531512",
    "label": "Tueng",
    "value": "Tueng"
  },
  {
    "id": "5315122003",
    "district_id": "531512",
    "label": "Sompang Kolang",
    "value": "Sompang Kolang"
  },
  {
    "id": "5315122004",
    "district_id": "531512",
    "label": "Wajur",
    "value": "Wajur"
  },
  {
    "id": "5315122005",
    "district_id": "531512",
    "label": "Kolang",
    "value": "Kolang"
  },
  {
    "id": "5315122006",
    "district_id": "531512",
    "label": "Golo Riwu",
    "value": "Golo Riwu"
  },
  {
    "id": "5315122007",
    "district_id": "531512",
    "label": "Compang Kules",
    "value": "Compang Kules"
  },
  {
    "id": "5315122008",
    "district_id": "531512",
    "label": "Tengku",
    "value": "Tengku"
  },
  {
    "id": "5315122009",
    "district_id": "531512",
    "label": "Golo Wedong",
    "value": "Golo Wedong"
  },
  {
    "id": "5315122010",
    "district_id": "531512",
    "label": "Ranggu",
    "value": "Ranggu"
  },
  {
    "id": "5316011001",
    "district_id": "531601",
    "label": "Dhawe",
    "value": "Dhawe"
  },
  {
    "id": "5316011003",
    "district_id": "531601",
    "label": "Mbay I",
    "value": "Mbay I"
  },
  {
    "id": "5316011005",
    "district_id": "531601",
    "label": "Towak",
    "value": "Towak"
  },
  {
    "id": "5316011007",
    "district_id": "531601",
    "label": "Mbay II",
    "value": "Mbay II"
  },
  {
    "id": "5316011009",
    "district_id": "531601",
    "label": "Danga",
    "value": "Danga"
  },
  {
    "id": "5316011011",
    "district_id": "531601",
    "label": "Lape",
    "value": "Lape"
  },
  {
    "id": "5316012002",
    "district_id": "531601",
    "label": "Labolewa",
    "value": "Labolewa"
  },
  {
    "id": "5316012004",
    "district_id": "531601",
    "label": "Tedamude",
    "value": "Tedamude"
  },
  {
    "id": "5316012006",
    "district_id": "531601",
    "label": "Nggolonio",
    "value": "Nggolonio"
  },
  {
    "id": "5316012008",
    "district_id": "531601",
    "label": "Olaia",
    "value": "Olaia"
  },
  {
    "id": "5316012010",
    "district_id": "531601",
    "label": "Aerama",
    "value": "Aerama"
  },
  {
    "id": "5316012012",
    "district_id": "531601",
    "label": "Marapokot",
    "value": "Marapokot"
  },
  {
    "id": "5316012013",
    "district_id": "531601",
    "label": "Tonggurambang",
    "value": "Tonggurambang"
  },
  {
    "id": "5316012014",
    "district_id": "531601",
    "label": "Waekokak",
    "value": "Waekokak"
  },
  {
    "id": "5316012015",
    "district_id": "531601",
    "label": "Ngegedhawe",
    "value": "Ngegedhawe"
  },
  {
    "id": "5316012016",
    "district_id": "531601",
    "label": "Tadakisa",
    "value": "Tadakisa"
  },
  {
    "id": "5316012017",
    "district_id": "531601",
    "label": "Nggolombay",
    "value": "Nggolombay"
  },
  {
    "id": "5316012018",
    "district_id": "531601",
    "label": "Nangadhero",
    "value": "Nangadhero"
  },
  {
    "id": "5316021001",
    "district_id": "531602",
    "label": "Nangaroro",
    "value": "Nangaroro"
  },
  {
    "id": "5316022002",
    "district_id": "531602",
    "label": "Podenura",
    "value": "Podenura"
  },
  {
    "id": "5316022003",
    "district_id": "531602",
    "label": "Tonggo",
    "value": "Tonggo"
  },
  {
    "id": "5316022004",
    "district_id": "531602",
    "label": "Wokodekororo",
    "value": "Wokodekororo"
  },
  {
    "id": "5316022005",
    "district_id": "531602",
    "label": "Riti",
    "value": "Riti"
  },
  {
    "id": "5316022006",
    "district_id": "531602",
    "label": "Kotakeo",
    "value": "Kotakeo"
  },
  {
    "id": "5316022007",
    "district_id": "531602",
    "label": "Pagomogo",
    "value": "Pagomogo"
  },
  {
    "id": "5316022008",
    "district_id": "531602",
    "label": "Degalea",
    "value": "Degalea"
  },
  {
    "id": "5316022009",
    "district_id": "531602",
    "label": "Ulupulu",
    "value": "Ulupulu"
  },
  {
    "id": "5316022010",
    "district_id": "531602",
    "label": "Bidoa",
    "value": "Bidoa"
  },
  {
    "id": "5316022011",
    "district_id": "531602",
    "label": "Utetoto",
    "value": "Utetoto"
  },
  {
    "id": "5316022012",
    "district_id": "531602",
    "label": "Nataute",
    "value": "Nataute"
  },
  {
    "id": "5316022013",
    "district_id": "531602",
    "label": "Woedoa",
    "value": "Woedoa"
  },
  {
    "id": "5316022014",
    "district_id": "531602",
    "label": "Woewutu",
    "value": "Woewutu"
  },
  {
    "id": "5316022015",
    "district_id": "531602",
    "label": "Ulupulu I",
    "value": "Ulupulu I"
  },
  {
    "id": "5316022016",
    "district_id": "531602",
    "label": "Wokowoe",
    "value": "Wokowoe"
  },
  {
    "id": "5316022017",
    "district_id": "531602",
    "label": "Kotakeo Satu",
    "value": "Kotakeo Satu"
  },
  {
    "id": "5316022018",
    "district_id": "531602",
    "label": "Kotakeo Dua",
    "value": "Kotakeo Dua"
  },
  {
    "id": "5316022019",
    "district_id": "531602",
    "label": "Kodaute",
    "value": "Kodaute"
  },
  {
    "id": "5316031001",
    "district_id": "531603",
    "label": "Natanage",
    "value": "Natanage"
  },
  {
    "id": "5316031003",
    "district_id": "531603",
    "label": "Nagesapadhi",
    "value": "Nagesapadhi"
  },
  {
    "id": "5316031005",
    "district_id": "531603",
    "label": "Nageoga",
    "value": "Nageoga"
  },
  {
    "id": "5316031007",
    "district_id": "531603",
    "label": "Wolopogo",
    "value": "Wolopogo"
  },
  {
    "id": "5316031009",
    "district_id": "531603",
    "label": "Rega",
    "value": "Rega"
  },
  {
    "id": "5316031011",
    "district_id": "531603",
    "label": "Ratongamobo",
    "value": "Ratongamobo"
  },
  {
    "id": "5316031013",
    "district_id": "531603",
    "label": "Olakile",
    "value": "Olakile"
  },
  {
    "id": "5316031021",
    "district_id": "531603",
    "label": "Natanage Timur",
    "value": "Natanage Timur"
  },
  {
    "id": "5316032002",
    "district_id": "531603",
    "label": "Rowa",
    "value": "Rowa"
  },
  {
    "id": "5316032004",
    "district_id": "531603",
    "label": "Solo",
    "value": "Solo"
  },
  {
    "id": "5316032006",
    "district_id": "531603",
    "label": "Legederu",
    "value": "Legederu"
  },
  {
    "id": "5316032008",
    "district_id": "531603",
    "label": "Nagerawe",
    "value": "Nagerawe"
  },
  {
    "id": "5316032010",
    "district_id": "531603",
    "label": "Kelewae",
    "value": "Kelewae"
  },
  {
    "id": "5316032012",
    "district_id": "531603",
    "label": "Kelimado",
    "value": "Kelimado"
  },
  {
    "id": "5316032014",
    "district_id": "531603",
    "label": "Mulakoli",
    "value": "Mulakoli"
  },
  {
    "id": "5316032015",
    "district_id": "531603",
    "label": "Wea Au",
    "value": "Wea Au"
  },
  {
    "id": "5316032016",
    "district_id": "531603",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "5316032017",
    "district_id": "531603",
    "label": "Wolowea",
    "value": "Wolowea"
  },
  {
    "id": "5316032018",
    "district_id": "531603",
    "label": "Gerodhere",
    "value": "Gerodhere"
  },
  {
    "id": "5316032019",
    "district_id": "531603",
    "label": "Dhereisa",
    "value": "Dhereisa"
  },
  {
    "id": "5316032020",
    "district_id": "531603",
    "label": "Rigi",
    "value": "Rigi"
  },
  {
    "id": "5316032022",
    "district_id": "531603",
    "label": "Alorawe",
    "value": "Alorawe"
  },
  {
    "id": "5316032023",
    "district_id": "531603",
    "label": "Focolodorawe",
    "value": "Focolodorawe"
  },
  {
    "id": "5316032024",
    "district_id": "531603",
    "label": "Wolowea Timur",
    "value": "Wolowea Timur"
  },
  {
    "id": "5316032025",
    "district_id": "531603",
    "label": "Wolowea Barat",
    "value": "Wolowea Barat"
  },
  {
    "id": "5316032026",
    "district_id": "531603",
    "label": "Raja Timur",
    "value": "Raja Timur"
  },
  {
    "id": "5316032027",
    "district_id": "531603",
    "label": "Raja Selatan",
    "value": "Raja Selatan"
  },
  {
    "id": "5316041001",
    "district_id": "531604",
    "label": "Mauponggo",
    "value": "Mauponggo"
  },
  {
    "id": "5316042002",
    "district_id": "531604",
    "label": "Bela",
    "value": "Bela"
  },
  {
    "id": "5316042003",
    "district_id": "531604",
    "label": "Wolokisa",
    "value": "Wolokisa"
  },
  {
    "id": "5316042004",
    "district_id": "531604",
    "label": "Wuliwalo",
    "value": "Wuliwalo"
  },
  {
    "id": "5316042005",
    "district_id": "531604",
    "label": "Maukeli",
    "value": "Maukeli"
  },
  {
    "id": "5316042006",
    "district_id": "531604",
    "label": "Aewoe",
    "value": "Aewoe"
  },
  {
    "id": "5316042007",
    "district_id": "531604",
    "label": "Wolotelu",
    "value": "Wolotelu"
  },
  {
    "id": "5316042008",
    "district_id": "531604",
    "label": "Sawu",
    "value": "Sawu"
  },
  {
    "id": "5316042009",
    "district_id": "531604",
    "label": "Jawapogo",
    "value": "Jawapogo"
  },
  {
    "id": "5316042010",
    "district_id": "531604",
    "label": "Ululoga",
    "value": "Ululoga"
  },
  {
    "id": "5316042011",
    "district_id": "531604",
    "label": "Lodaolo",
    "value": "Lodaolo"
  },
  {
    "id": "5316042012",
    "district_id": "531604",
    "label": "Woloede",
    "value": "Woloede"
  },
  {
    "id": "5316042013",
    "district_id": "531604",
    "label": "Woewolo",
    "value": "Woewolo"
  },
  {
    "id": "5316042014",
    "district_id": "531604",
    "label": "Salalejo",
    "value": "Salalejo"
  },
  { "id": "5316042015", "district_id": "531604", "label": "Ua", "value": "Ua" },
  {
    "id": "5316042016",
    "district_id": "531604",
    "label": "Kotagana",
    "value": "Kotagana"
  },
  {
    "id": "5316042017",
    "district_id": "531604",
    "label": "Lokalaba",
    "value": "Lokalaba"
  },
  {
    "id": "5316042018",
    "district_id": "531604",
    "label": "Lajawajo",
    "value": "Lajawajo"
  },
  {
    "id": "5316042019",
    "district_id": "531604",
    "label": "Keliwatulewa",
    "value": "Keliwatulewa"
  },
  {
    "id": "5316042020",
    "district_id": "531604",
    "label": "Wololelu",
    "value": "Wololelu"
  },
  {
    "id": "5316042021",
    "district_id": "531604",
    "label": "Selalejo Timur",
    "value": "Selalejo Timur"
  },
  {
    "id": "5316052001",
    "district_id": "531605",
    "label": "Tendatoto",
    "value": "Tendatoto"
  },
  {
    "id": "5316052002",
    "district_id": "531605",
    "label": "Totomala",
    "value": "Totomala"
  },
  {
    "id": "5316052003",
    "district_id": "531605",
    "label": "Tendakinde",
    "value": "Tendakinde"
  },
  {
    "id": "5316052004",
    "district_id": "531605",
    "label": "Anakoli",
    "value": "Anakoli"
  },
  {
    "id": "5316052005",
    "district_id": "531605",
    "label": "Natatoto",
    "value": "Natatoto"
  },
  {
    "id": "5316062001",
    "district_id": "531606",
    "label": "Witurombaua",
    "value": "Witurombaua"
  },
  {
    "id": "5316062002",
    "district_id": "531606",
    "label": "Lewangera",
    "value": "Lewangera"
  },
  {
    "id": "5316062003",
    "district_id": "531606",
    "label": "Wajo",
    "value": "Wajo"
  },
  {
    "id": "5316062004",
    "district_id": "531606",
    "label": "Kotowuji Timur",
    "value": "Kotowuji Timur"
  },
  {
    "id": "5316062005",
    "district_id": "531606",
    "label": "Kotowuji Barat",
    "value": "Kotowuji Barat"
  },
  {
    "id": "5316062006",
    "district_id": "531606",
    "label": "Mbaenuamuri",
    "value": "Mbaenuamuri"
  },
  {
    "id": "5316062007",
    "district_id": "531606",
    "label": "Udiworowatu",
    "value": "Udiworowatu"
  },
  {
    "id": "5316062008",
    "district_id": "531606",
    "label": "Kotodirumali",
    "value": "Kotodirumali"
  },
  {
    "id": "5316062009",
    "district_id": "531606",
    "label": "Pautola",
    "value": "Pautola"
  },
  {
    "id": "5316062010",
    "district_id": "531606",
    "label": "Ladolima",
    "value": "Ladolima"
  },
  {
    "id": "5316062011",
    "district_id": "531606",
    "label": "Keli",
    "value": "Keli"
  },
  {
    "id": "5316062012",
    "district_id": "531606",
    "label": "Ngera",
    "value": "Ngera"
  },
  {
    "id": "5316062013",
    "district_id": "531606",
    "label": "Ladolima Timur",
    "value": "Ladolima Timur"
  },
  {
    "id": "5316062014",
    "district_id": "531606",
    "label": "Ladolima Utara",
    "value": "Ladolima Utara"
  },
  {
    "id": "5316062015",
    "district_id": "531606",
    "label": "Wajo Timur",
    "value": "Wajo Timur"
  },
  {
    "id": "5316062016",
    "district_id": "531606",
    "label": "Paumali",
    "value": "Paumali"
  },
  {
    "id": "5316072001",
    "district_id": "531607",
    "label": "Rendubutowe",
    "value": "Rendubutowe"
  },
  {
    "id": "5316072002",
    "district_id": "531607",
    "label": "Tengatiba",
    "value": "Tengatiba"
  },
  {
    "id": "5316072003",
    "district_id": "531607",
    "label": "Langedhawe",
    "value": "Langedhawe"
  },
  {
    "id": "5316072004",
    "district_id": "531607",
    "label": "Renduteno",
    "value": "Renduteno"
  },
  {
    "id": "5316072005",
    "district_id": "531607",
    "label": "Renduwawo",
    "value": "Renduwawo"
  },
  {
    "id": "5316072006",
    "district_id": "531607",
    "label": "Wajomara",
    "value": "Wajomara"
  },
  {
    "id": "5316072007",
    "district_id": "531607",
    "label": "Rendut Tutubhada",
    "value": "Rendut Tutubhada"
  },
  {
    "id": "5317012001",
    "district_id": "531701",
    "label": "Makata Keri",
    "value": "Makata Keri"
  },
  {
    "id": "5317012003",
    "district_id": "531701",
    "label": "Kabela Wuntu",
    "value": "Kabela Wuntu"
  },
  {
    "id": "5317012004",
    "district_id": "531701",
    "label": "Mata Woga",
    "value": "Mata Woga"
  },
  {
    "id": "5317012019",
    "district_id": "531701",
    "label": "Umbu Riri",
    "value": "Umbu Riri"
  },
  {
    "id": "5317012023",
    "district_id": "531701",
    "label": "Anakalang",
    "value": "Anakalang"
  },
  {
    "id": "5317012024",
    "district_id": "531701",
    "label": "Dewa Jara",
    "value": "Dewa Jara"
  },
  {
    "id": "5317012025",
    "district_id": "531701",
    "label": "Mata Redi",
    "value": "Mata Redi"
  },
  {
    "id": "5317022001",
    "district_id": "531702",
    "label": "Wangga Waiyengu",
    "value": "Wangga Waiyengu"
  },
  {
    "id": "5317022002",
    "district_id": "531702",
    "label": "Praimadeta",
    "value": "Praimadeta"
  },
  {
    "id": "5317022003",
    "district_id": "531702",
    "label": "Pondok",
    "value": "Pondok"
  },
  {
    "id": "5317022004",
    "district_id": "531702",
    "label": "Maderi",
    "value": "Maderi"
  },
  {
    "id": "5317022005",
    "district_id": "531702",
    "label": "Anajiaka",
    "value": "Anajiaka"
  },
  {
    "id": "5317022006",
    "district_id": "531702",
    "label": "Wairasa",
    "value": "Wairasa"
  },
  {
    "id": "5317022007",
    "district_id": "531702",
    "label": "Umbu Kawolu",
    "value": "Umbu Kawolu"
  },
  {
    "id": "5317022008",
    "district_id": "531702",
    "label": "Umbu Mamijuk",
    "value": "Umbu Mamijuk"
  },
  {
    "id": "5317022009",
    "district_id": "531702",
    "label": "Umbu Pabal",
    "value": "Umbu Pabal"
  },
  {
    "id": "5317022010",
    "district_id": "531702",
    "label": "Umbu Langang",
    "value": "Umbu Langang"
  },
  {
    "id": "5317022011",
    "district_id": "531702",
    "label": "Sambali Loku",
    "value": "Sambali Loku"
  },
  {
    "id": "5317022021",
    "district_id": "531702",
    "label": "Umbu Pabal Selatan",
    "value": "Umbu Pabal Selatan"
  },
  {
    "id": "5317022022",
    "district_id": "531702",
    "label": "Umbu Jodu",
    "value": "Umbu Jodu"
  },
  {
    "id": "5317022023",
    "district_id": "531702",
    "label": "Anapalu",
    "value": "Anapalu"
  },
  {
    "id": "5317022024",
    "district_id": "531702",
    "label": "Daha Elu",
    "value": "Daha Elu"
  },
  {
    "id": "5317022025",
    "district_id": "531702",
    "label": "Dewa Tana",
    "value": "Dewa Tana"
  },
  {
    "id": "5317022026",
    "district_id": "531702",
    "label": "Holur Kambata",
    "value": "Holur Kambata"
  },
  {
    "id": "5317022027",
    "district_id": "531702",
    "label": "Mata Waikajawi",
    "value": "Mata Waikajawi"
  },
  {
    "id": "5317032001",
    "district_id": "531703",
    "label": "Wendewa Timur",
    "value": "Wendewa Timur"
  },
  {
    "id": "5317032002",
    "district_id": "531703",
    "label": "Wendewa Selatan",
    "value": "Wendewa Selatan"
  },
  {
    "id": "5317032003",
    "district_id": "531703",
    "label": "Wendewa Utara",
    "value": "Wendewa Utara"
  },
  {
    "id": "5317032004",
    "district_id": "531703",
    "label": "Wendewa Barat",
    "value": "Wendewa Barat"
  },
  {
    "id": "5317032005",
    "district_id": "531703",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "5317032006",
    "district_id": "531703",
    "label": "Wee Luri",
    "value": "Wee Luri"
  },
  {
    "id": "5317032007",
    "district_id": "531703",
    "label": "Ole Ate",
    "value": "Ole Ate"
  },
  {
    "id": "5317032008",
    "district_id": "531703",
    "label": "Manu Wolu",
    "value": "Manu Wolu"
  },
  {
    "id": "5317032009",
    "district_id": "531703",
    "label": "Watu Asa",
    "value": "Watu Asa"
  },
  {
    "id": "5317032010",
    "district_id": "531703",
    "label": "Bondo Sulla",
    "value": "Bondo Sulla"
  },
  {
    "id": "5317032011",
    "district_id": "531703",
    "label": "Susu Wendewa",
    "value": "Susu Wendewa"
  },
  {
    "id": "5317032012",
    "district_id": "531703",
    "label": "Ole Dewa",
    "value": "Ole Dewa"
  },
  {
    "id": "5317032013",
    "district_id": "531703",
    "label": "Cendana Barat",
    "value": "Cendana Barat"
  },
  {
    "id": "5317042001",
    "district_id": "531704",
    "label": "Mbilur Pangadu",
    "value": "Mbilur Pangadu"
  },
  {
    "id": "5317042002",
    "district_id": "531704",
    "label": "Soru",
    "value": "Soru"
  },
  {
    "id": "5317042003",
    "district_id": "531704",
    "label": "Praikoruku Jangga",
    "value": "Praikoruku Jangga"
  },
  {
    "id": "5317042004",
    "district_id": "531704",
    "label": "Bolu Bokat",
    "value": "Bolu Bokat"
  },
  {
    "id": "5317042005",
    "district_id": "531704",
    "label": "Mara Desa",
    "value": "Mara Desa"
  },
  {
    "id": "5317042006",
    "district_id": "531704",
    "label": "Weluk Praimemang",
    "value": "Weluk Praimemang"
  },
  {
    "id": "5317042007",
    "district_id": "531704",
    "label": "Lenang",
    "value": "Lenang"
  },
  {
    "id": "5317042008",
    "district_id": "531704",
    "label": "Tana Mbanas",
    "value": "Tana Mbanas"
  },
  {
    "id": "5317042009",
    "district_id": "531704",
    "label": "Padira Tana",
    "value": "Padira Tana"
  },
  {
    "id": "5317042010",
    "district_id": "531704",
    "label": "Ngadu Bolu",
    "value": "Ngadu Bolu"
  },
  {
    "id": "5317042011",
    "district_id": "531704",
    "label": "Bolu Bokat Utara",
    "value": "Bolu Bokat Utara"
  },
  {
    "id": "5317042012",
    "district_id": "531704",
    "label": "Maradesa Timur",
    "value": "Maradesa Timur"
  },
  {
    "id": "5317042013",
    "district_id": "531704",
    "label": "Maradesa Selatan",
    "value": "Maradesa Selatan"
  },
  {
    "id": "5317042014",
    "district_id": "531704",
    "label": "Tana Mbanas Selatan",
    "value": "Tana Mbanas Selatan"
  },
  {
    "id": "5317042015",
    "district_id": "531704",
    "label": "Tana Mbanas Barat",
    "value": "Tana Mbanas Barat"
  },
  {
    "id": "5317042016",
    "district_id": "531704",
    "label": "Ngadu Olu",
    "value": "Ngadu Olu"
  },
  {
    "id": "5317042017",
    "district_id": "531704",
    "label": "Lenang Selatan",
    "value": "Lenang Selatan"
  },
  {
    "id": "5317042018",
    "district_id": "531704",
    "label": "Bolubokat Barat",
    "value": "Bolubokat Barat"
  },
  {
    "id": "5317052001",
    "district_id": "531705",
    "label": "Konda Maloba",
    "value": "Konda Maloba"
  },
  {
    "id": "5317052002",
    "district_id": "531705",
    "label": "Tana Modu",
    "value": "Tana Modu"
  },
  {
    "id": "5317052003",
    "district_id": "531705",
    "label": "Malinjak",
    "value": "Malinjak"
  },
  {
    "id": "5317052004",
    "district_id": "531705",
    "label": "Wailawa",
    "value": "Wailawa"
  },
  {
    "id": "5317052005",
    "district_id": "531705",
    "label": "Dameka",
    "value": "Dameka"
  },
  {
    "id": "5317052006",
    "district_id": "531705",
    "label": "Waimanu",
    "value": "Waimanu"
  },
  {
    "id": "5317052007",
    "district_id": "531705",
    "label": "Manurara",
    "value": "Manurara"
  },
  {
    "id": "5317052008",
    "district_id": "531705",
    "label": "Elu",
    "value": "Elu"
  },
  {
    "id": "5317052009",
    "district_id": "531705",
    "label": "Oka Wacu",
    "value": "Oka Wacu"
  },
  {
    "id": "5318012001",
    "district_id": "531801",
    "label": "Bondo Boghila",
    "value": "Bondo Boghila"
  },
  {
    "id": "5318012002",
    "district_id": "531801",
    "label": "Lete Konda",
    "value": "Lete Konda"
  },
  {
    "id": "5318012003",
    "district_id": "531801",
    "label": "Karuni",
    "value": "Karuni"
  },
  {
    "id": "5318012004",
    "district_id": "531801",
    "label": "Totok",
    "value": "Totok"
  },
  {
    "id": "5318012009",
    "district_id": "531801",
    "label": "Rama Dana",
    "value": "Rama Dana"
  },
  {
    "id": "5318012010",
    "district_id": "531801",
    "label": "Wee Mananda",
    "value": "Wee Mananda"
  },
  {
    "id": "5318012013",
    "district_id": "531801",
    "label": "Pogo Tena",
    "value": "Pogo Tena"
  },
  {
    "id": "5318012014",
    "district_id": "531801",
    "label": "Payola Umbu",
    "value": "Payola Umbu"
  },
  {
    "id": "5318012015",
    "district_id": "531801",
    "label": "Wee Kambala",
    "value": "Wee Kambala"
  },
  {
    "id": "5318012016",
    "district_id": "531801",
    "label": "Loko Kalada",
    "value": "Loko Kalada"
  },
  {
    "id": "5318012017",
    "district_id": "531801",
    "label": "Lete Konda Selatan",
    "value": "Lete Konda Selatan"
  },
  {
    "id": "5318022001",
    "district_id": "531802",
    "label": "Mali Mada",
    "value": "Mali Mada"
  },
  {
    "id": "5318022002",
    "district_id": "531802",
    "label": "Wano Talla",
    "value": "Wano Talla"
  },
  {
    "id": "5318022003",
    "district_id": "531802",
    "label": "Wee Paboba",
    "value": "Wee Paboba"
  },
  {
    "id": "5318022004",
    "district_id": "531802",
    "label": "Mata Loko",
    "value": "Mata Loko"
  },
  {
    "id": "5318022005",
    "district_id": "531802",
    "label": "Wee Namba",
    "value": "Wee Namba"
  },
  {
    "id": "5318022006",
    "district_id": "531802",
    "label": "Puu Potto",
    "value": "Puu Potto"
  },
  {
    "id": "5318022007",
    "district_id": "531802",
    "label": "Bodo Ponda",
    "value": "Bodo Ponda"
  },
  {
    "id": "5318022008",
    "district_id": "531802",
    "label": "Reda Wano",
    "value": "Reda Wano"
  },
  {
    "id": "5318022009",
    "district_id": "531802",
    "label": "Odi Paurata",
    "value": "Odi Paurata"
  },
  {
    "id": "5318022010",
    "district_id": "531802",
    "label": "Pandua Tana",
    "value": "Pandua Tana"
  },
  {
    "id": "5318022011",
    "district_id": "531802",
    "label": "Djela Manu",
    "value": "Djela Manu"
  },
  {
    "id": "5318022012",
    "district_id": "531802",
    "label": "Mawo Maliti",
    "value": "Mawo Maliti"
  },
  {
    "id": "5318032001",
    "district_id": "531803",
    "label": "Kalembu Ndara Mane",
    "value": "Kalembu Ndara Mane"
  },
  {
    "id": "5318032002",
    "district_id": "531803",
    "label": "Tema Tana",
    "value": "Tema Tana"
  },
  {
    "id": "5318032005",
    "district_id": "531803",
    "label": "Mareda Kalada",
    "value": "Mareda Kalada"
  },
  {
    "id": "5318032006",
    "district_id": "531803",
    "label": "Pada Eweta",
    "value": "Pada Eweta"
  },
  {
    "id": "5318032012",
    "district_id": "531803",
    "label": "Wee Limbu",
    "value": "Wee Limbu"
  },
  {
    "id": "5318032013",
    "district_id": "531803",
    "label": "Lete Kamouna",
    "value": "Lete Kamouna"
  },
  {
    "id": "5318032014",
    "district_id": "531803",
    "label": "Mata Pyawu",
    "value": "Mata Pyawu"
  },
  {
    "id": "5318032015",
    "district_id": "531803",
    "label": "Wee Lima",
    "value": "Wee Lima"
  },
  {
    "id": "5318032018",
    "district_id": "531803",
    "label": "Dikira",
    "value": "Dikira"
  },
  {
    "id": "5318032020",
    "district_id": "531803",
    "label": "Dangga Mangu",
    "value": "Dangga Mangu"
  },
  {
    "id": "5318032021",
    "district_id": "531803",
    "label": "Mainda Ole",
    "value": "Mainda Ole"
  },
  {
    "id": "5318032022",
    "district_id": "531803",
    "label": "Kadi Wano",
    "value": "Kadi Wano"
  },
  {
    "id": "5318032023",
    "district_id": "531803",
    "label": "Nyura Lele",
    "value": "Nyura Lele"
  },
  {
    "id": "5318032024",
    "district_id": "531803",
    "label": "Lele Maya",
    "value": "Lele Maya"
  },
  {
    "id": "5318032025",
    "district_id": "531803",
    "label": "Maliti Dari",
    "value": "Maliti Dari"
  },
  {
    "id": "5318032026",
    "district_id": "531803",
    "label": "Mawo Dana",
    "value": "Mawo Dana"
  },
  {
    "id": "5318032027",
    "district_id": "531803",
    "label": "Dede Pada",
    "value": "Dede Pada"
  },
  {
    "id": "5318032028",
    "district_id": "531803",
    "label": "Mata Wee Lima",
    "value": "Mata Wee Lima"
  },
  {
    "id": "5318032029",
    "district_id": "531803",
    "label": "Kadi Wone",
    "value": "Kadi Wone"
  },
  {
    "id": "5318042001",
    "district_id": "531804",
    "label": "Waimangura",
    "value": "Waimangura"
  },
  {
    "id": "5318042002",
    "district_id": "531804",
    "label": "Kalembu Weri",
    "value": "Kalembu Weri"
  },
  {
    "id": "5318042003",
    "district_id": "531804",
    "label": "Wali Ate",
    "value": "Wali Ate"
  },
  {
    "id": "5318042004",
    "district_id": "531804",
    "label": "Kabali Dana",
    "value": "Kabali Dana"
  },
  {
    "id": "5318042005",
    "district_id": "531804",
    "label": "Wee Kombaka",
    "value": "Wee Kombaka"
  },
  {
    "id": "5318042006",
    "district_id": "531804",
    "label": "Marokota",
    "value": "Marokota"
  },
  {
    "id": "5318042007",
    "district_id": "531804",
    "label": "Watu Labara",
    "value": "Watu Labara"
  },
  {
    "id": "5318042008",
    "district_id": "531804",
    "label": "Kalimbu Tillu",
    "value": "Kalimbu Tillu"
  },
  {
    "id": "5318042011",
    "district_id": "531804",
    "label": "Menne Ate",
    "value": "Menne Ate"
  },
  {
    "id": "5318042012",
    "district_id": "531804",
    "label": "Reda Pada",
    "value": "Reda Pada"
  },
  {
    "id": "5318042013",
    "district_id": "531804",
    "label": "Raba Ege",
    "value": "Raba Ege"
  },
  {
    "id": "5318042015",
    "district_id": "531804",
    "label": "Kalaki Kambe",
    "value": "Kalaki Kambe"
  },
  {
    "id": "5318042016",
    "district_id": "531804",
    "label": "Kalembu Kanaika",
    "value": "Kalembu Kanaika"
  },
  {
    "id": "5318042017",
    "district_id": "531804",
    "label": "Laga Lete",
    "value": "Laga Lete"
  },
  {
    "id": "5318042018",
    "district_id": "531804",
    "label": "Wee Kura",
    "value": "Wee Kura"
  },
  {
    "id": "5318042019",
    "district_id": "531804",
    "label": "Lua Koba",
    "value": "Lua Koba"
  },
  {
    "id": "5318042020",
    "district_id": "531804",
    "label": "Tawo Rara",
    "value": "Tawo Rara"
  },
  {
    "id": "5318042021",
    "district_id": "531804",
    "label": "Sangu Ate",
    "value": "Sangu Ate"
  },
  {
    "id": "5318042022",
    "district_id": "531804",
    "label": "Lolo Ole",
    "value": "Lolo Ole"
  },
  {
    "id": "5318042023",
    "district_id": "531804",
    "label": "Pero",
    "value": "Pero"
  },
  {
    "id": "5318052001",
    "district_id": "531805",
    "label": "Buru Deilo",
    "value": "Buru Deilo"
  },
  {
    "id": "5318052002",
    "district_id": "531805",
    "label": "Weri Lolo",
    "value": "Weri Lolo"
  },
  {
    "id": "5318052003",
    "district_id": "531805",
    "label": "Buru Kaghu",
    "value": "Buru Kaghu"
  },
  {
    "id": "5318052004",
    "district_id": "531805",
    "label": "Denduka",
    "value": "Denduka"
  },
  {
    "id": "5318052005",
    "district_id": "531805",
    "label": "Bondo Bela",
    "value": "Bondo Bela"
  },
  {
    "id": "5318052006",
    "district_id": "531805",
    "label": "Delo",
    "value": "Delo"
  },
  {
    "id": "5318052007",
    "district_id": "531805",
    "label": "Tena Teke",
    "value": "Tena Teke"
  },
  {
    "id": "5318052008",
    "district_id": "531805",
    "label": "Bondo Ukka",
    "value": "Bondo Ukka"
  },
  {
    "id": "5318052009",
    "district_id": "531805",
    "label": "Umbu Wangu",
    "value": "Umbu Wangu"
  },
  {
    "id": "5318052010",
    "district_id": "531805",
    "label": "Milla Ate",
    "value": "Milla Ate"
  },
  {
    "id": "5318052011",
    "district_id": "531805",
    "label": "Rita Baru",
    "value": "Rita Baru"
  },
  {
    "id": "5318052012",
    "district_id": "531805",
    "label": "Mandungo",
    "value": "Mandungo"
  },
  {
    "id": "5318052013",
    "district_id": "531805",
    "label": "Wee Wulla",
    "value": "Wee Wulla"
  },
  {
    "id": "5318052014",
    "district_id": "531805",
    "label": "Wee Baghe",
    "value": "Wee Baghe"
  },
  {
    "id": "5318062001",
    "district_id": "531806",
    "label": "Dinjo",
    "value": "Dinjo"
  },
  {
    "id": "5318062002",
    "district_id": "531806",
    "label": "Lete Loko",
    "value": "Lete Loko"
  },
  {
    "id": "5318062003",
    "district_id": "531806",
    "label": "Walla Ndimu",
    "value": "Walla Ndimu"
  },
  {
    "id": "5318062006",
    "district_id": "531806",
    "label": "Waikadada",
    "value": "Waikadada"
  },
  {
    "id": "5318062010",
    "district_id": "531806",
    "label": "Rada Loko",
    "value": "Rada Loko"
  },
  {
    "id": "5318062011",
    "district_id": "531806",
    "label": "Mata Kapore",
    "value": "Mata Kapore"
  },
  {
    "id": "5318062012",
    "district_id": "531806",
    "label": "Umbu Ngedo",
    "value": "Umbu Ngedo"
  },
  {
    "id": "5318062014",
    "district_id": "531806",
    "label": "Waimakaha",
    "value": "Waimakaha"
  },
  {
    "id": "5318062015",
    "district_id": "531806",
    "label": "Waimaringi",
    "value": "Waimaringi"
  },
  {
    "id": "5318062016",
    "district_id": "531806",
    "label": "Tana Mete",
    "value": "Tana Mete"
  },
  {
    "id": "5318062017",
    "district_id": "531806",
    "label": "Waipaddi",
    "value": "Waipaddi"
  },
  {
    "id": "5318062018",
    "district_id": "531806",
    "label": "Manu Toghi",
    "value": "Manu Toghi"
  },
  {
    "id": "5318062019",
    "district_id": "531806",
    "label": "Karang Indah",
    "value": "Karang Indah"
  },
  {
    "id": "5318062020",
    "district_id": "531806",
    "label": "Rada Malando",
    "value": "Rada Malando"
  },
  {
    "id": "5318062021",
    "district_id": "531806",
    "label": "Waikaninyo",
    "value": "Waikaninyo"
  },
  {
    "id": "5318062022",
    "district_id": "531806",
    "label": "Ana Goka",
    "value": "Ana Goka"
  },
  {
    "id": "5318062023",
    "district_id": "531806",
    "label": "Delu Depa",
    "value": "Delu Depa"
  },
  {
    "id": "5318062024",
    "district_id": "531806",
    "label": "Mere Kehe",
    "value": "Mere Kehe"
  },
  {
    "id": "5318062025",
    "district_id": "531806",
    "label": "Bondo Balla",
    "value": "Bondo Balla"
  },
  {
    "id": "5318062026",
    "district_id": "531806",
    "label": "Maliti Bondo Ate",
    "value": "Maliti Bondo Ate"
  },
  {
    "id": "5318062027",
    "district_id": "531806",
    "label": "Ana Lewe",
    "value": "Ana Lewe"
  },
  {
    "id": "5318072001",
    "district_id": "531807",
    "label": "Bondo Kodi",
    "value": "Bondo Kodi"
  },
  {
    "id": "5318072002",
    "district_id": "531807",
    "label": "Ate Dalo",
    "value": "Ate Dalo"
  },
  {
    "id": "5318072003",
    "district_id": "531807",
    "label": "Hamonggo Lele",
    "value": "Hamonggo Lele"
  },
  {
    "id": "5318072004",
    "district_id": "531807",
    "label": "Homba Rande",
    "value": "Homba Rande"
  },
  {
    "id": "5318072005",
    "district_id": "531807",
    "label": "Pero Batang",
    "value": "Pero Batang"
  },
  {
    "id": "5318072006",
    "district_id": "531807",
    "label": "Wura Homba",
    "value": "Wura Homba"
  },
  {
    "id": "5318072007",
    "district_id": "531807",
    "label": "Koki",
    "value": "Koki"
  },
  {
    "id": "5318072008",
    "district_id": "531807",
    "label": "Kapaka Madeta",
    "value": "Kapaka Madeta"
  },
  {
    "id": "5318072009",
    "district_id": "531807",
    "label": "Kawango Hari",
    "value": "Kawango Hari"
  },
  {
    "id": "5318072010",
    "district_id": "531807",
    "label": "Onggol",
    "value": "Onggol"
  },
  {
    "id": "5318072011",
    "district_id": "531807",
    "label": "Mali lha",
    "value": "Mali lha"
  },
  {
    "id": "5318072012",
    "district_id": "531807",
    "label": "Watu Wona",
    "value": "Watu Wona"
  },
  {
    "id": "5318072013",
    "district_id": "531807",
    "label": "Tanjung Karoso",
    "value": "Tanjung Karoso"
  },
  {
    "id": "5318072014",
    "district_id": "531807",
    "label": "Pero Konda",
    "value": "Pero Konda"
  },
  {
    "id": "5318072015",
    "district_id": "531807",
    "label": "Homba Rica",
    "value": "Homba Rica"
  },
  {
    "id": "5318072016",
    "district_id": "531807",
    "label": "Ana Kaka",
    "value": "Ana Kaka"
  },
  {
    "id": "5318072017",
    "district_id": "531807",
    "label": "Ole Ate",
    "value": "Ole Ate"
  },
  {
    "id": "5318072018",
    "district_id": "531807",
    "label": "Kadoki Horo",
    "value": "Kadoki Horo"
  },
  {
    "id": "5318072019",
    "district_id": "531807",
    "label": "Ana Engge",
    "value": "Ana Engge"
  },
  {
    "id": "5318082001",
    "district_id": "531808",
    "label": "Hoha Wungo",
    "value": "Hoha Wungo"
  },
  {
    "id": "5318082002",
    "district_id": "531808",
    "label": "Homba Karipit",
    "value": "Homba Karipit"
  },
  {
    "id": "5318082003",
    "district_id": "531808",
    "label": "Wailabubur",
    "value": "Wailabubur"
  },
  {
    "id": "5318082004",
    "district_id": "531808",
    "label": "Kori",
    "value": "Kori"
  },
  {
    "id": "5318082005",
    "district_id": "531808",
    "label": "Kalena Rongo",
    "value": "Kalena Rongo"
  },
  {
    "id": "5318082006",
    "district_id": "531808",
    "label": "Waiholo",
    "value": "Waiholo"
  },
  {
    "id": "5318082007",
    "district_id": "531808",
    "label": "Noha",
    "value": "Noha"
  },
  {
    "id": "5318082008",
    "district_id": "531808",
    "label": "Mangganipi",
    "value": "Mangganipi"
  },
  {
    "id": "5318082009",
    "district_id": "531808",
    "label": "Kendu Wela",
    "value": "Kendu Wela"
  },
  {
    "id": "5318082010",
    "district_id": "531808",
    "label": "Bila Cenge",
    "value": "Bila Cenge"
  },
  {
    "id": "5318082011",
    "district_id": "531808",
    "label": "Bukambero",
    "value": "Bukambero"
  },
  {
    "id": "5318082013",
    "district_id": "531808",
    "label": "Homba Pare",
    "value": "Homba Pare"
  },
  {
    "id": "5318082014",
    "district_id": "531808",
    "label": "Magho Linyo",
    "value": "Magho Linyo"
  },
  {
    "id": "5318082015",
    "district_id": "531808",
    "label": "Wee Wella",
    "value": "Wee Wella"
  },
  {
    "id": "5318082016",
    "district_id": "531808",
    "label": "Kadu Eta",
    "value": "Kadu Eta"
  },
  {
    "id": "5318082017",
    "district_id": "531808",
    "label": "Kadaghu Tana",
    "value": "Kadaghu Tana"
  },
  {
    "id": "5318082018",
    "district_id": "531808",
    "label": "Hameli Ate",
    "value": "Hameli Ate"
  },
  {
    "id": "5318082019",
    "district_id": "531808",
    "label": "Waitaru",
    "value": "Waitaru"
  },
  {
    "id": "5318082020",
    "district_id": "531808",
    "label": "Nangga Mutu",
    "value": "Nangga Mutu"
  },
  {
    "id": "5318082021",
    "district_id": "531808",
    "label": "Limbu Kembe",
    "value": "Limbu Kembe"
  },
  {
    "id": "5318082022",
    "district_id": "531808",
    "label": "Moro Manduyo",
    "value": "Moro Manduyo"
  },
  {
    "id": "5318091003",
    "district_id": "531809",
    "label": "Langga Lero",
    "value": "Langga Lero"
  },
  {
    "id": "5318091004",
    "district_id": "531809",
    "label": "Waitabula",
    "value": "Waitabula"
  },
  {
    "id": "5318092001",
    "district_id": "531809",
    "label": "Rada Mata",
    "value": "Rada Mata"
  },
  {
    "id": "5318092002",
    "district_id": "531809",
    "label": "Kalena Wano",
    "value": "Kalena Wano"
  },
  {
    "id": "5318092005",
    "district_id": "531809",
    "label": "Wee Londa",
    "value": "Wee Londa"
  },
  {
    "id": "5318092006",
    "district_id": "531809",
    "label": "Wee Pangali",
    "value": "Wee Pangali"
  },
  {
    "id": "5318092007",
    "district_id": "531809",
    "label": "Kadi Pada",
    "value": "Kadi Pada"
  },
  {
    "id": "5318092008",
    "district_id": "531809",
    "label": "Watu Kawula",
    "value": "Watu Kawula"
  },
  {
    "id": "5318092009",
    "district_id": "531809",
    "label": "Wee Rena",
    "value": "Wee Rena"
  },
  {
    "id": "5318092010",
    "district_id": "531809",
    "label": "Kalembu Kaha",
    "value": "Kalembu Kaha"
  },
  {
    "id": "5318102001",
    "district_id": "531810",
    "label": "Kanelu",
    "value": "Kanelu"
  },
  {
    "id": "5318102002",
    "district_id": "531810",
    "label": "Tanggaba",
    "value": "Tanggaba"
  },
  {
    "id": "5318102003",
    "district_id": "531810",
    "label": "Wee Kokora",
    "value": "Wee Kokora"
  },
  {
    "id": "5318102004",
    "district_id": "531810",
    "label": "Wee Rame",
    "value": "Wee Rame"
  },
  {
    "id": "5318102005",
    "district_id": "531810",
    "label": "Lombu",
    "value": "Lombu"
  },
  {
    "id": "5318102006",
    "district_id": "531810",
    "label": "Eka Pata",
    "value": "Eka Pata"
  },
  {
    "id": "5318102007",
    "district_id": "531810",
    "label": "Bolora",
    "value": "Bolora"
  },
  {
    "id": "5318102008",
    "district_id": "531810",
    "label": "Omba Rade",
    "value": "Omba Rade"
  },
  {
    "id": "5318102009",
    "district_id": "531810",
    "label": "Kalingara",
    "value": "Kalingara"
  },
  {
    "id": "5318102010",
    "district_id": "531810",
    "label": "Wee Patando",
    "value": "Wee Patando"
  },
  {
    "id": "5318102011",
    "district_id": "531810",
    "label": "Mereda Wuni",
    "value": "Mereda Wuni"
  },
  {
    "id": "5318102012",
    "district_id": "531810",
    "label": "Mata Wee Karoro",
    "value": "Mata Wee Karoro"
  },
  {
    "id": "5318102013",
    "district_id": "531810",
    "label": "Gollu Sapi",
    "value": "Gollu Sapi"
  },
  {
    "id": "5318102014",
    "district_id": "531810",
    "label": "Mata Lombu",
    "value": "Mata Lombu"
  },
  {
    "id": "5318102015",
    "district_id": "531810",
    "label": "Kadi Roma",
    "value": "Kadi Roma"
  },
  {
    "id": "5318102016",
    "district_id": "531810",
    "label": "Bondo Delo",
    "value": "Bondo Delo"
  },
  {
    "id": "5318102017",
    "district_id": "531810",
    "label": "Lete Wungana",
    "value": "Lete Wungana"
  },
  {
    "id": "5318102018",
    "district_id": "531810",
    "label": "Limbu Watu",
    "value": "Limbu Watu"
  },
  {
    "id": "5318102019",
    "district_id": "531810",
    "label": "Kiku Booko",
    "value": "Kiku Booko"
  },
  {
    "id": "5318102020",
    "district_id": "531810",
    "label": "Tarra Mata",
    "value": "Tarra Mata"
  },
  {
    "id": "5318112001",
    "district_id": "531811",
    "label": "Wailangira",
    "value": "Wailangira"
  },
  {
    "id": "5318112002",
    "district_id": "531811",
    "label": "Kahale",
    "value": "Kahale"
  },
  {
    "id": "5318112003",
    "district_id": "531811",
    "label": "Waikarara",
    "value": "Waikarara"
  },
  {
    "id": "5318112004",
    "district_id": "531811",
    "label": "Panenggo Ede",
    "value": "Panenggo Ede"
  },
  {
    "id": "5318112005",
    "district_id": "531811",
    "label": "Waiha",
    "value": "Waiha"
  },
  {
    "id": "5318112006",
    "district_id": "531811",
    "label": "Wainyapu",
    "value": "Wainyapu"
  },
  {
    "id": "5318112007",
    "district_id": "531811",
    "label": "Loko Tali",
    "value": "Loko Tali"
  },
  {
    "id": "5318112008",
    "district_id": "531811",
    "label": "Waipakolo",
    "value": "Waipakolo"
  },
  {
    "id": "5319011020",
    "district_id": "531901",
    "label": "Kota Ndora",
    "value": "Kota Ndora"
  },
  {
    "id": "5319011021",
    "district_id": "531901",
    "label": "Rana Loba",
    "value": "Rana Loba"
  },
  {
    "id": "5319011039",
    "district_id": "531901",
    "label": "Satar Peot",
    "value": "Satar Peot"
  },
  {
    "id": "5319012001",
    "district_id": "531901",
    "label": "Nanga Labang",
    "value": "Nanga Labang"
  },
  {
    "id": "5319012004",
    "district_id": "531901",
    "label": "Gurung Liwut",
    "value": "Gurung Liwut"
  },
  {
    "id": "5319012005",
    "district_id": "531901",
    "label": "Benteng Riwu",
    "value": "Benteng Riwu"
  },
  {
    "id": "5319012006",
    "district_id": "531901",
    "label": "Ngampang Mas",
    "value": "Ngampang Mas"
  },
  {
    "id": "5319012009",
    "district_id": "531901",
    "label": "Rana Masak",
    "value": "Rana Masak"
  },
  {
    "id": "5319012010",
    "district_id": "531901",
    "label": "Benteng Raja",
    "value": "Benteng Raja"
  },
  {
    "id": "5319012014",
    "district_id": "531901",
    "label": "Golo Lalong",
    "value": "Golo Lalong"
  },
  {
    "id": "5319012017",
    "district_id": "531901",
    "label": "Golo Kantar",
    "value": "Golo Kantar"
  },
  {
    "id": "5319012018",
    "district_id": "531901",
    "label": "Poco Rii",
    "value": "Poco Rii"
  },
  {
    "id": "5319012025",
    "district_id": "531901",
    "label": "Balus Permai",
    "value": "Balus Permai"
  },
  {
    "id": "5319012031",
    "district_id": "531901",
    "label": "Waling",
    "value": "Waling"
  },
  {
    "id": "5319012032",
    "district_id": "531901",
    "label": "Golo Leda",
    "value": "Golo Leda"
  },
  {
    "id": "5319012034",
    "district_id": "531901",
    "label": "Compang Tenda",
    "value": "Compang Tenda"
  },
  {
    "id": "5319012035",
    "district_id": "531901",
    "label": "Bangka Kantar",
    "value": "Bangka Kantar"
  },
  {
    "id": "5319012038",
    "district_id": "531901",
    "label": "Compang Ndejing",
    "value": "Compang Ndejing"
  },
  {
    "id": "5319021027",
    "district_id": "531902",
    "label": "Mandosawu",
    "value": "Mandosawu"
  },
  {
    "id": "5319021028",
    "district_id": "531902",
    "label": "Nggalak Leleng",
    "value": "Nggalak Leleng"
  },
  {
    "id": "5319021042",
    "district_id": "531902",
    "label": "Bangka Leleng",
    "value": "Bangka Leleng"
  },
  {
    "id": "5319022001",
    "district_id": "531902",
    "label": "Poco Lia",
    "value": "Poco Lia"
  },
  {
    "id": "5319022002",
    "district_id": "531902",
    "label": "Pocong",
    "value": "Pocong"
  },
  {
    "id": "5319022003",
    "district_id": "531902",
    "label": "Lenang",
    "value": "Lenang"
  },
  {
    "id": "5319022004",
    "district_id": "531902",
    "label": "Melo",
    "value": "Melo"
  },
  {
    "id": "5319022005",
    "district_id": "531902",
    "label": "Leong",
    "value": "Leong"
  },
  {
    "id": "5319022011",
    "district_id": "531902",
    "label": "Bea Waek",
    "value": "Bea Waek"
  },
  {
    "id": "5319022013",
    "district_id": "531902",
    "label": "Golo Lobos",
    "value": "Golo Lobos"
  },
  {
    "id": "5319022014",
    "district_id": "531902",
    "label": "Bangka Kuleng",
    "value": "Bangka Kuleng"
  },
  {
    "id": "5319022015",
    "district_id": "531902",
    "label": "Bangka Pau",
    "value": "Bangka Pau"
  },
  {
    "id": "5319022016",
    "district_id": "531902",
    "label": "Golo Nderu",
    "value": "Golo Nderu"
  },
  {
    "id": "5319022017",
    "district_id": "531902",
    "label": "Satar Tesem",
    "value": "Satar Tesem"
  },
  {
    "id": "5319022018",
    "district_id": "531902",
    "label": "Gurung Turi",
    "value": "Gurung Turi"
  },
  {
    "id": "5319022019",
    "district_id": "531902",
    "label": "Compang Laho",
    "value": "Compang Laho"
  },
  {
    "id": "5319022020",
    "district_id": "531902",
    "label": "Watu Lanur",
    "value": "Watu Lanur"
  },
  {
    "id": "5319022026",
    "district_id": "531902",
    "label": "Golo Ndari",
    "value": "Golo Ndari"
  },
  {
    "id": "5319022029",
    "district_id": "531902",
    "label": "Compang Wesang",
    "value": "Compang Wesang"
  },
  {
    "id": "5319022030",
    "district_id": "531902",
    "label": "Golo Wune",
    "value": "Golo Wune"
  },
  {
    "id": "5319022031",
    "district_id": "531902",
    "label": "Lento",
    "value": "Lento"
  },
  {
    "id": "5319022032",
    "district_id": "531902",
    "label": "Compang Weluk",
    "value": "Compang Weluk"
  },
  {
    "id": "5319022033",
    "district_id": "531902",
    "label": "Golo Rengket",
    "value": "Golo Rengket"
  },
  {
    "id": "5319022037",
    "district_id": "531902",
    "label": "Deno",
    "value": "Deno"
  },
  {
    "id": "5319032001",
    "district_id": "531903",
    "label": "Tengku Leda",
    "value": "Tengku Leda"
  },
  {
    "id": "5319032002",
    "district_id": "531903",
    "label": "Tengku Lawar",
    "value": "Tengku Lawar"
  },
  {
    "id": "5319032003",
    "district_id": "531903",
    "label": "Goreng Meni",
    "value": "Goreng Meni"
  },
  {
    "id": "5319032004",
    "district_id": "531903",
    "label": "Golo Munga",
    "value": "Golo Munga"
  },
  {
    "id": "5319032005",
    "district_id": "531903",
    "label": "Nampar Tabang",
    "value": "Nampar Tabang"
  },
  {
    "id": "5319032006",
    "district_id": "531903",
    "label": "Satar Punda",
    "value": "Satar Punda"
  },
  {
    "id": "5319032007",
    "district_id": "531903",
    "label": "Golo Mangung",
    "value": "Golo Mangung"
  },
  {
    "id": "5319032008",
    "district_id": "531903",
    "label": "Satar Padut",
    "value": "Satar Padut"
  },
  {
    "id": "5319032009",
    "district_id": "531903",
    "label": "Golo Rentung",
    "value": "Golo Rentung"
  },
  {
    "id": "5319032010",
    "district_id": "531903",
    "label": "Golo Lembur",
    "value": "Golo Lembur"
  },
  {
    "id": "5319032011",
    "district_id": "531903",
    "label": "Compang Necak",
    "value": "Compang Necak"
  },
  {
    "id": "5319032012",
    "district_id": "531903",
    "label": "Lencur",
    "value": "Lencur"
  },
  {
    "id": "5319032013",
    "district_id": "531903",
    "label": "Compang Mekar",
    "value": "Compang Mekar"
  },
  {
    "id": "5319032014",
    "district_id": "531903",
    "label": "Compang Deru",
    "value": "Compang Deru"
  },
  {
    "id": "5319032015",
    "district_id": "531903",
    "label": "Liang Deruk",
    "value": "Liang Deruk"
  },
  {
    "id": "5319032016",
    "district_id": "531903",
    "label": "Golo Nimbung",
    "value": "Golo Nimbung"
  },
  {
    "id": "5319032017",
    "district_id": "531903",
    "label": "Keli",
    "value": "Keli"
  },
  {
    "id": "5319032018",
    "district_id": "531903",
    "label": "Satar Kampas",
    "value": "Satar Kampas"
  },
  {
    "id": "5319032019",
    "district_id": "531903",
    "label": "Haju Wangi",
    "value": "Haju Wangi"
  },
  {
    "id": "5319032020",
    "district_id": "531903",
    "label": "Golo Munga Barat",
    "value": "Golo Munga Barat"
  },
  {
    "id": "5319032021",
    "district_id": "531903",
    "label": "Goreng Meni Utara",
    "value": "Goreng Meni Utara"
  },
  {
    "id": "5319032022",
    "district_id": "531903",
    "label": "Golo Wontong",
    "value": "Golo Wontong"
  },
  {
    "id": "5319032023",
    "district_id": "531903",
    "label": "Golo Paleng",
    "value": "Golo Paleng"
  },
  {
    "id": "5319032024",
    "district_id": "531903",
    "label": "Satar Punda Barat",
    "value": "Satar Punda Barat"
  },
  {
    "id": "5319041009",
    "district_id": "531904",
    "label": "Pota",
    "value": "Pota"
  },
  {
    "id": "5319041010",
    "district_id": "531904",
    "label": "Golo Wangkung",
    "value": "Golo Wangkung"
  },
  {
    "id": "5319041011",
    "district_id": "531904",
    "label": "Nanga Baras",
    "value": "Nanga Baras"
  },
  {
    "id": "5319041018",
    "district_id": "531904",
    "label": "Ulung Baras",
    "value": "Ulung Baras"
  },
  {
    "id": "5319041019",
    "district_id": "531904",
    "label": "Golo Wangkung Barat",
    "value": "Golo Wangkung Barat"
  },
  {
    "id": "5319041020",
    "district_id": "531904",
    "label": "Golo Wangkung Utara",
    "value": "Golo Wangkung Utara"
  },
  {
    "id": "5319042001",
    "district_id": "531904",
    "label": "Nanga Baur",
    "value": "Nanga Baur"
  },
  {
    "id": "5319042002",
    "district_id": "531904",
    "label": "Nanga Mbaling",
    "value": "Nanga Mbaling"
  },
  {
    "id": "5319042003",
    "district_id": "531904",
    "label": "Buti",
    "value": "Buti"
  },
  {
    "id": "5319042004",
    "district_id": "531904",
    "label": "Golo Ngawan",
    "value": "Golo Ngawan"
  },
  {
    "id": "5319042005",
    "district_id": "531904",
    "label": "Satar Nawang",
    "value": "Satar Nawang"
  },
  {
    "id": "5319042006",
    "district_id": "531904",
    "label": "Rana Mese",
    "value": "Rana Mese"
  },
  {
    "id": "5319042007",
    "district_id": "531904",
    "label": "Lada Mese",
    "value": "Lada Mese"
  },
  {
    "id": "5319042008",
    "district_id": "531904",
    "label": "Compang Congkar",
    "value": "Compang Congkar"
  },
  {
    "id": "5319042012",
    "district_id": "531904",
    "label": "Nampar Sepang",
    "value": "Nampar Sepang"
  },
  {
    "id": "5319042013",
    "district_id": "531904",
    "label": "Kembang Mekar",
    "value": "Kembang Mekar"
  },
  {
    "id": "5319042014",
    "district_id": "531904",
    "label": "Wela Lada",
    "value": "Wela Lada"
  },
  {
    "id": "5319042015",
    "district_id": "531904",
    "label": "Golo Pari",
    "value": "Golo Pari"
  },
  {
    "id": "5319042016",
    "district_id": "531904",
    "label": "Compang Lawi",
    "value": "Compang Lawi"
  },
  {
    "id": "5319042017",
    "district_id": "531904",
    "label": "Wea",
    "value": "Wea"
  },
  {
    "id": "5319051020",
    "district_id": "531905",
    "label": "Tiwu Kondo",
    "value": "Tiwu Kondo"
  },
  {
    "id": "5319052001",
    "district_id": "531905",
    "label": "Lengko Namut",
    "value": "Lengko Namut"
  },
  {
    "id": "5319052002",
    "district_id": "531905",
    "label": "Rana Kulan",
    "value": "Rana Kulan"
  },
  {
    "id": "5319052003",
    "district_id": "531905",
    "label": "Golo Munde",
    "value": "Golo Munde"
  },
  {
    "id": "5319052004",
    "district_id": "531905",
    "label": "Golo Lebo",
    "value": "Golo Lebo"
  },
  {
    "id": "5319052005",
    "district_id": "531905",
    "label": "Golo Lijun",
    "value": "Golo Lijun"
  },
  {
    "id": "5319052009",
    "district_id": "531905",
    "label": "Sisir",
    "value": "Sisir"
  },
  {
    "id": "5319052015",
    "district_id": "531905",
    "label": "Haju Ngendong",
    "value": "Haju Ngendong"
  },
  {
    "id": "5319052016",
    "district_id": "531905",
    "label": "Legur Lai",
    "value": "Legur Lai"
  },
  {
    "id": "5319052017",
    "district_id": "531905",
    "label": "Rana Gapang",
    "value": "Rana Gapang"
  },
  {
    "id": "5319052018",
    "district_id": "531905",
    "label": "Biting",
    "value": "Biting"
  },
  {
    "id": "5319052025",
    "district_id": "531905",
    "label": "Compang Soba",
    "value": "Compang Soba"
  },
  {
    "id": "5319052026",
    "district_id": "531905",
    "label": "Compang Teo",
    "value": "Compang Teo"
  },
  {
    "id": "5319052028",
    "district_id": "531905",
    "label": "Kaju Wangi",
    "value": "Kaju Wangi"
  },
  {
    "id": "5319052029",
    "district_id": "531905",
    "label": "Wae Lokom",
    "value": "Wae Lokom"
  },
  {
    "id": "5319061001",
    "district_id": "531906",
    "label": "Watu Nggene",
    "value": "Watu Nggene"
  },
  {
    "id": "5319061002",
    "district_id": "531906",
    "label": "Rongga Koe",
    "value": "Rongga Koe"
  },
  {
    "id": "5319061017",
    "district_id": "531906",
    "label": "Tanah Rata",
    "value": "Tanah Rata"
  },
  {
    "id": "5319062003",
    "district_id": "531906",
    "label": "Lembur",
    "value": "Lembur"
  },
  {
    "id": "5319062004",
    "district_id": "531906",
    "label": "Ruan",
    "value": "Ruan"
  },
  {
    "id": "5319062005",
    "district_id": "531906",
    "label": "Golo Tolang",
    "value": "Golo Tolang"
  },
  {
    "id": "5319062006",
    "district_id": "531906",
    "label": "Mbengan",
    "value": "Mbengan"
  },
  {
    "id": "5319062007",
    "district_id": "531906",
    "label": "Rana Kolong",
    "value": "Rana Kolong"
  },
  {
    "id": "5319062008",
    "district_id": "531906",
    "label": "Gunung",
    "value": "Gunung"
  },
  {
    "id": "5319062009",
    "district_id": "531906",
    "label": "Mokel",
    "value": "Mokel"
  },
  {
    "id": "5319062010",
    "district_id": "531906",
    "label": "Golo Meni",
    "value": "Golo Meni"
  },
  {
    "id": "5319062011",
    "district_id": "531906",
    "label": "Rana Mbeling",
    "value": "Rana Mbeling"
  },
  {
    "id": "5319062012",
    "district_id": "531906",
    "label": "Golo Nderu",
    "value": "Golo Nderu"
  },
  {
    "id": "5319062013",
    "district_id": "531906",
    "label": "Komba",
    "value": "Komba"
  },
  {
    "id": "5319062014",
    "district_id": "531906",
    "label": "Bamo",
    "value": "Bamo"
  },
  {
    "id": "5319062015",
    "district_id": "531906",
    "label": "Pong Ruan",
    "value": "Pong Ruan"
  },
  {
    "id": "5319062016",
    "district_id": "531906",
    "label": "Paan Leleng",
    "value": "Paan Leleng"
  },
  {
    "id": "5319062018",
    "district_id": "531906",
    "label": "Rana Mbata",
    "value": "Rana Mbata"
  },
  {
    "id": "5319062019",
    "district_id": "531906",
    "label": "Mokel Morid",
    "value": "Mokel Morid"
  },
  {
    "id": "5319062020",
    "district_id": "531906",
    "label": "Pari",
    "value": "Pari"
  },
  {
    "id": "5319062021",
    "district_id": "531906",
    "label": "Golo Ndele",
    "value": "Golo Ndele"
  },
  {
    "id": "5319062022",
    "district_id": "531906",
    "label": "Gunung Baru",
    "value": "Gunung Baru"
  },
  {
    "id": "5319072001",
    "district_id": "531907",
    "label": "Bea Ngencung",
    "value": "Bea Ngencung"
  },
  {
    "id": "5319072002",
    "district_id": "531907",
    "label": "Satar Lahing",
    "value": "Satar Lahing"
  },
  {
    "id": "5319072003",
    "district_id": "531907",
    "label": "Torok Golo",
    "value": "Torok Golo"
  },
  {
    "id": "5319072004",
    "district_id": "531907",
    "label": "Golo Ros",
    "value": "Golo Ros"
  },
  {
    "id": "5319072005",
    "district_id": "531907",
    "label": "Rondo Woing",
    "value": "Rondo Woing"
  },
  {
    "id": "5319072006",
    "district_id": "531907",
    "label": "Sano Lokom",
    "value": "Sano Lokom"
  },
  {
    "id": "5319072007",
    "district_id": "531907",
    "label": "Golo Loni",
    "value": "Golo Loni"
  },
  {
    "id": "5319072008",
    "district_id": "531907",
    "label": "Sita",
    "value": "Sita"
  },
  {
    "id": "5319072009",
    "district_id": "531907",
    "label": "Golo Rutuk",
    "value": "Golo Rutuk"
  },
  {
    "id": "5319072010",
    "district_id": "531907",
    "label": "Golo Meleng",
    "value": "Golo Meleng"
  },
  {
    "id": "5319072011",
    "district_id": "531907",
    "label": "Wae Nggori",
    "value": "Wae Nggori"
  },
  {
    "id": "5319072012",
    "district_id": "531907",
    "label": "Compang Kantar",
    "value": "Compang Kantar"
  },
  {
    "id": "5319072013",
    "district_id": "531907",
    "label": "Watu Mori",
    "value": "Watu Mori"
  },
  {
    "id": "5319072014",
    "district_id": "531907",
    "label": "Bangka Kempo",
    "value": "Bangka Kempo"
  },
  {
    "id": "5319072015",
    "district_id": "531907",
    "label": "Compang Teber",
    "value": "Compang Teber"
  },
  {
    "id": "5319072016",
    "district_id": "531907",
    "label": "Satar Lenda",
    "value": "Satar Lenda"
  },
  {
    "id": "5319072017",
    "district_id": "531907",
    "label": "Lidi",
    "value": "Lidi"
  },
  {
    "id": "5319072018",
    "district_id": "531907",
    "label": "Bangka Masa",
    "value": "Bangka Masa"
  },
  {
    "id": "5319072019",
    "district_id": "531907",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "5319072020",
    "district_id": "531907",
    "label": "Compang Loni",
    "value": "Compang Loni"
  },
  {
    "id": "5319072021",
    "district_id": "531907",
    "label": "Compang Kempo",
    "value": "Compang Kempo"
  },
  {
    "id": "5319082001",
    "district_id": "531908",
    "label": "Watu Arus",
    "value": "Watu Arus"
  },
  {
    "id": "5319082002",
    "district_id": "531908",
    "label": "Arus",
    "value": "Arus"
  },
  {
    "id": "5319082003",
    "district_id": "531908",
    "label": "Golo Lero",
    "value": "Golo Lero"
  },
  {
    "id": "5319082004",
    "district_id": "531908",
    "label": "Rengkam",
    "value": "Rengkam"
  },
  {
    "id": "5319082005",
    "district_id": "531908",
    "label": "Compang Wunis",
    "value": "Compang Wunis"
  },
  {
    "id": "5319082006",
    "district_id": "531908",
    "label": "Benteng Rampas",
    "value": "Benteng Rampas"
  },
  {
    "id": "5319082007",
    "district_id": "531908",
    "label": "Ngkiong Dora",
    "value": "Ngkiong Dora"
  },
  {
    "id": "5319082008",
    "district_id": "531908",
    "label": "Ulu Wae",
    "value": "Ulu Wae"
  },
  {
    "id": "5319082009",
    "district_id": "531908",
    "label": "Rende Nao",
    "value": "Rende Nao"
  },
  {
    "id": "5319082010",
    "district_id": "531908",
    "label": "Wangkar Weli",
    "value": "Wangkar Weli"
  },
  {
    "id": "5319082011",
    "district_id": "531908",
    "label": "Tango Molas",
    "value": "Tango Molas"
  },
  {
    "id": "5319082012",
    "district_id": "531908",
    "label": "Urung Dora",
    "value": "Urung Dora"
  },
  {
    "id": "5319082013",
    "district_id": "531908",
    "label": "Compang Raci",
    "value": "Compang Raci"
  },
  {
    "id": "5319082014",
    "district_id": "531908",
    "label": "Wejang Mawe",
    "value": "Wejang Mawe"
  },
  {
    "id": "5319082015",
    "district_id": "531908",
    "label": "Bangka Arus",
    "value": "Bangka Arus"
  },
  {
    "id": "5319082016",
    "district_id": "531908",
    "label": "Colol",
    "value": "Colol"
  },
  {
    "id": "5319082017",
    "district_id": "531908",
    "label": "Benteng Wunis",
    "value": "Benteng Wunis"
  },
  {
    "id": "5319082018",
    "district_id": "531908",
    "label": "Wejang Mali",
    "value": "Wejang Mali"
  },
  {
    "id": "5319091001",
    "district_id": "531909",
    "label": "Lempang Paji",
    "value": "Lempang Paji"
  },
  {
    "id": "5319092002",
    "district_id": "531909",
    "label": "Sangan Kalo",
    "value": "Sangan Kalo"
  },
  {
    "id": "5319092003",
    "district_id": "531909",
    "label": "Sipi",
    "value": "Sipi"
  },
  {
    "id": "5319092004",
    "district_id": "531909",
    "label": "Gising",
    "value": "Gising"
  },
  {
    "id": "5319092005",
    "district_id": "531909",
    "label": "Langga Sai",
    "value": "Langga Sai"
  },
  {
    "id": "5319092006",
    "district_id": "531909",
    "label": "Teno Mese",
    "value": "Teno Mese"
  },
  {
    "id": "5319092007",
    "district_id": "531909",
    "label": "Golo Wuas",
    "value": "Golo Wuas"
  },
  {
    "id": "5319092008",
    "district_id": "531909",
    "label": "Nanga Meje",
    "value": "Nanga Meje"
  },
  {
    "id": "5319092009",
    "district_id": "531909",
    "label": "Paan Waru",
    "value": "Paan Waru"
  },
  {
    "id": "5319092010",
    "district_id": "531909",
    "label": "Golo Linus",
    "value": "Golo Linus"
  },
  {
    "id": "5319092011",
    "district_id": "531909",
    "label": "Benteng Pau",
    "value": "Benteng Pau"
  },
  {
    "id": "5319092012",
    "district_id": "531909",
    "label": "Wae Rasan",
    "value": "Wae Rasan"
  },
  {
    "id": "5319092013",
    "district_id": "531909",
    "label": "Mosi Ngaran",
    "value": "Mosi Ngaran"
  },
  {
    "id": "5319092014",
    "district_id": "531909",
    "label": "Nanga Puun",
    "value": "Nanga Puun"
  },
  {
    "id": "5320011001",
    "district_id": "532001",
    "label": "Mebba",
    "value": "Mebba"
  },
  {
    "id": "5320012002",
    "district_id": "532001",
    "label": "Menia",
    "value": "Menia"
  },
  {
    "id": "5320012003",
    "district_id": "532001",
    "label": "Raeloro",
    "value": "Raeloro"
  },
  {
    "id": "5320012004",
    "district_id": "532001",
    "label": "Naawawi",
    "value": "Naawawi"
  },
  {
    "id": "5320012005",
    "district_id": "532001",
    "label": "Teriwu",
    "value": "Teriwu"
  },
  {
    "id": "5320012006",
    "district_id": "532001",
    "label": "Raenalulu",
    "value": "Raenalulu"
  },
  {
    "id": "5320012007",
    "district_id": "532001",
    "label": "Raedewa",
    "value": "Raedewa"
  },
  {
    "id": "5320012008",
    "district_id": "532001",
    "label": "Ledeana",
    "value": "Ledeana"
  },
  {
    "id": "5320012009",
    "district_id": "532001",
    "label": "Depe",
    "value": "Depe"
  },
  {
    "id": "5320012010",
    "district_id": "532001",
    "label": "Raemude",
    "value": "Raemude"
  },
  {
    "id": "5320012011",
    "district_id": "532001",
    "label": "Raekore",
    "value": "Raekore"
  },
  {
    "id": "5320012012",
    "district_id": "532001",
    "label": "Raenyale",
    "value": "Raenyale"
  },
  {
    "id": "5320012013",
    "district_id": "532001",
    "label": "Raemadia",
    "value": "Raemadia"
  },
  {
    "id": "5320012014",
    "district_id": "532001",
    "label": "Delo",
    "value": "Delo"
  },
  {
    "id": "5320012015",
    "district_id": "532001",
    "label": "Roboaba",
    "value": "Roboaba"
  },
  {
    "id": "5320012016",
    "district_id": "532001",
    "label": "Djadu",
    "value": "Djadu"
  },
  {
    "id": "5320012017",
    "district_id": "532001",
    "label": "Ledekepaka",
    "value": "Ledekepaka"
  },
  {
    "id": "5320012018",
    "district_id": "532001",
    "label": "Titinalede",
    "value": "Titinalede"
  },
  {
    "id": "5320022001",
    "district_id": "532002",
    "label": "Bebae",
    "value": "Bebae"
  },
  {
    "id": "5320022002",
    "district_id": "532002",
    "label": "Tada",
    "value": "Tada"
  },
  {
    "id": "5320022003",
    "district_id": "532002",
    "label": "Matei",
    "value": "Matei"
  },
  {
    "id": "5320022004",
    "district_id": "532002",
    "label": "Loboaju",
    "value": "Loboaju"
  },
  {
    "id": "5320022005",
    "district_id": "532002",
    "label": "Eimadake",
    "value": "Eimadake"
  },
  {
    "id": "5320022006",
    "district_id": "532002",
    "label": "Jiwuwu",
    "value": "Jiwuwu"
  },
  {
    "id": "5320022007",
    "district_id": "532002",
    "label": "Eilode",
    "value": "Eilode"
  },
  {
    "id": "5320022008",
    "district_id": "532002",
    "label": "Eimau",
    "value": "Eimau"
  },
  {
    "id": "5320031001",
    "district_id": "532003",
    "label": "Bolou",
    "value": "Bolou"
  },
  {
    "id": "5320031002",
    "district_id": "532003",
    "label": "Limaggu",
    "value": "Limaggu"
  },
  {
    "id": "5320032003",
    "district_id": "532003",
    "label": "Lobodei",
    "value": "Lobodei"
  },
  {
    "id": "5320032004",
    "district_id": "532003",
    "label": "Bodae",
    "value": "Bodae"
  },
  {
    "id": "5320032005",
    "district_id": "532003",
    "label": "Keduru",
    "value": "Keduru"
  },
  {
    "id": "5320032006",
    "district_id": "532003",
    "label": "Kuji Ratu",
    "value": "Kuji Ratu"
  },
  {
    "id": "5320032007",
    "district_id": "532003",
    "label": "Loborai",
    "value": "Loborai"
  },
  {
    "id": "5320032008",
    "district_id": "532003",
    "label": "Huwaga",
    "value": "Huwaga"
  },
  {
    "id": "5320032009",
    "district_id": "532003",
    "label": "Eiada",
    "value": "Eiada"
  },
  {
    "id": "5320032010",
    "district_id": "532003",
    "label": "Keliha",
    "value": "Keliha"
  },
  {
    "id": "5320042001",
    "district_id": "532004",
    "label": "Raerobo",
    "value": "Raerobo"
  },
  {
    "id": "5320042002",
    "district_id": "532004",
    "label": "Mehona",
    "value": "Mehona"
  },
  {
    "id": "5320042003",
    "district_id": "532004",
    "label": "Waduwalla",
    "value": "Waduwalla"
  },
  {
    "id": "5320042004",
    "district_id": "532004",
    "label": "Ledeke",
    "value": "Ledeke"
  },
  {
    "id": "5320042005",
    "district_id": "532004",
    "label": "Eilogo",
    "value": "Eilogo"
  },
  {
    "id": "5320042006",
    "district_id": "532004",
    "label": "Deme",
    "value": "Deme"
  },
  {
    "id": "5320042007",
    "district_id": "532004",
    "label": "Kotahawu",
    "value": "Kotahawu"
  },
  {
    "id": "5320042008",
    "district_id": "532004",
    "label": "Dainao",
    "value": "Dainao"
  },
  {
    "id": "5320042009",
    "district_id": "532004",
    "label": "Eikare",
    "value": "Eikare"
  },
  {
    "id": "5320042010",
    "district_id": "532004",
    "label": "Loborui",
    "value": "Loborui"
  },
  {
    "id": "5320042011",
    "district_id": "532004",
    "label": "Halla Paji",
    "value": "Halla Paji"
  },
  {
    "id": "5320042012",
    "district_id": "532004",
    "label": "Ledetalo",
    "value": "Ledetalo"
  },
  {
    "id": "5320052001",
    "district_id": "532005",
    "label": "Lobohede",
    "value": "Lobohede"
  },
  {
    "id": "5320052002",
    "district_id": "532005",
    "label": "Molie",
    "value": "Molie"
  },
  {
    "id": "5320052003",
    "district_id": "532005",
    "label": "Daieko",
    "value": "Daieko"
  },
  {
    "id": "5320052004",
    "district_id": "532005",
    "label": "Pedarro",
    "value": "Pedarro"
  },
  {
    "id": "5320052005",
    "district_id": "532005",
    "label": "Tanajawa",
    "value": "Tanajawa"
  },
  {
    "id": "5320052006",
    "district_id": "532005",
    "label": "Ledeae",
    "value": "Ledeae"
  },
  {
    "id": "5320052007",
    "district_id": "532005",
    "label": "Wadumaddi",
    "value": "Wadumaddi"
  },
  {
    "id": "5320052008",
    "district_id": "532005",
    "label": "Lederaga",
    "value": "Lederaga"
  },
  {
    "id": "5320052009",
    "district_id": "532005",
    "label": "Gurimonearu",
    "value": "Gurimonearu"
  },
  {
    "id": "5320052010",
    "district_id": "532005",
    "label": "Ramedue",
    "value": "Ramedue"
  },
  {
    "id": "5320061001",
    "district_id": "532006",
    "label": "Ledeke",
    "value": "Ledeke"
  },
  {
    "id": "5320061002",
    "district_id": "532006",
    "label": "Ledeunu",
    "value": "Ledeunu"
  },
  {
    "id": "5320062003",
    "district_id": "532006",
    "label": "Bolua",
    "value": "Bolua"
  },
  {
    "id": "5320062004",
    "district_id": "532006",
    "label": "Kolorae",
    "value": "Kolorae"
  },
  {
    "id": "5320062005",
    "district_id": "532006",
    "label": "Ballu",
    "value": "Ballu"
  },
  {
    "id": "5321012001",
    "district_id": "532101",
    "label": "Barene",
    "value": "Barene"
  },
  {
    "id": "5321012002",
    "district_id": "532101",
    "label": "Kakaniuk",
    "value": "Kakaniuk"
  },
  {
    "id": "5321012003",
    "district_id": "532101",
    "label": "Kateri",
    "value": "Kateri"
  },
  {
    "id": "5321012004",
    "district_id": "532101",
    "label": "Bakiruk",
    "value": "Bakiruk"
  },
  {
    "id": "5321012005",
    "district_id": "532101",
    "label": "Kamanasa",
    "value": "Kamanasa"
  },
  {
    "id": "5321012006",
    "district_id": "532101",
    "label": "Wehali",
    "value": "Wehali"
  },
  {
    "id": "5321012007",
    "district_id": "532101",
    "label": "Umakatahan",
    "value": "Umakatahan"
  },
  {
    "id": "5321012008",
    "district_id": "532101",
    "label": "Umanen Lawalu",
    "value": "Umanen Lawalu"
  },
  {
    "id": "5321012009",
    "district_id": "532101",
    "label": "Kletek",
    "value": "Kletek"
  },
  {
    "id": "5321012010",
    "district_id": "532101",
    "label": "Naimana",
    "value": "Naimana"
  },
  {
    "id": "5321012011",
    "district_id": "532101",
    "label": "Fahiluka",
    "value": "Fahiluka"
  },
  {
    "id": "5321012012",
    "district_id": "532101",
    "label": "Lawalu",
    "value": "Lawalu"
  },
  {
    "id": "5321012013",
    "district_id": "532101",
    "label": "Harekakae",
    "value": "Harekakae"
  },
  {
    "id": "5321012014",
    "district_id": "532101",
    "label": "Barada",
    "value": "Barada"
  },
  {
    "id": "5321012015",
    "district_id": "532101",
    "label": "Bereliku",
    "value": "Bereliku"
  },
  {
    "id": "5321012016",
    "district_id": "532101",
    "label": "Railor Tahak",
    "value": "Railor Tahak"
  },
  {
    "id": "5321012017",
    "district_id": "532101",
    "label": "Suai",
    "value": "Suai"
  },
  {
    "id": "5321022001",
    "district_id": "532102",
    "label": "Motaulun",
    "value": "Motaulun"
  },
  {
    "id": "5321022002",
    "district_id": "532102",
    "label": "Sikun",
    "value": "Sikun"
  },
  {
    "id": "5321022003",
    "district_id": "532102",
    "label": "Fafoe",
    "value": "Fafoe"
  },
  {
    "id": "5321022004",
    "district_id": "532102",
    "label": "Lasaen",
    "value": "Lasaen"
  },
  {
    "id": "5321022005",
    "district_id": "532102",
    "label": "Umatoos",
    "value": "Umatoos"
  },
  {
    "id": "5321022006",
    "district_id": "532102",
    "label": "Rabasahain",
    "value": "Rabasahain"
  },
  {
    "id": "5321022007",
    "district_id": "532102",
    "label": "Umalor",
    "value": "Umalor"
  },
  {
    "id": "5321022008",
    "district_id": "532102",
    "label": "Besikama",
    "value": "Besikama"
  },
  {
    "id": "5321022009",
    "district_id": "532102",
    "label": "Maktihan",
    "value": "Maktihan"
  },
  {
    "id": "5321022010",
    "district_id": "532102",
    "label": "Loofoun",
    "value": "Loofoun"
  },
  {
    "id": "5321022011",
    "district_id": "532102",
    "label": "Rabasa",
    "value": "Rabasa"
  },
  {
    "id": "5321022012",
    "district_id": "532102",
    "label": "Rabasa Haerain",
    "value": "Rabasa Haerain"
  },
  {
    "id": "5321022013",
    "district_id": "532102",
    "label": "Motaain",
    "value": "Motaain"
  },
  {
    "id": "5321022014",
    "district_id": "532102",
    "label": "Oan Mane",
    "value": "Oan Mane"
  },
  {
    "id": "5321022015",
    "district_id": "532102",
    "label": "Raimataus",
    "value": "Raimataus"
  },
  {
    "id": "5321022016",
    "district_id": "532102",
    "label": "Naas",
    "value": "Naas"
  },
  {
    "id": "5321032001",
    "district_id": "532103",
    "label": "Halibasar",
    "value": "Halibasar"
  },
  {
    "id": "5321032002",
    "district_id": "532103",
    "label": "Weulun",
    "value": "Weulun"
  },
  {
    "id": "5321032003",
    "district_id": "532103",
    "label": "Webriamata",
    "value": "Webriamata"
  },
  {
    "id": "5321032004",
    "district_id": "532103",
    "label": "Badarai",
    "value": "Badarai"
  },
  {
    "id": "5321032005",
    "district_id": "532103",
    "label": "Weoe",
    "value": "Weoe"
  },
  {
    "id": "5321032006",
    "district_id": "532103",
    "label": "Lorotolus",
    "value": "Lorotolus"
  },
  {
    "id": "5321032007",
    "district_id": "532103",
    "label": "Seserai",
    "value": "Seserai"
  },
  {
    "id": "5321032008",
    "district_id": "532103",
    "label": "Alkani",
    "value": "Alkani"
  },
  {
    "id": "5321032009",
    "district_id": "532103",
    "label": "Lamea",
    "value": "Lamea"
  },
  {
    "id": "5321032010",
    "district_id": "532103",
    "label": "Rabasa Biris",
    "value": "Rabasa Biris"
  },
  {
    "id": "5321032011",
    "district_id": "532103",
    "label": "Biris",
    "value": "Biris"
  },
  {
    "id": "5321032012",
    "district_id": "532103",
    "label": "Weseben",
    "value": "Weseben"
  },
  {
    "id": "5321042001",
    "district_id": "532104",
    "label": "Wesey",
    "value": "Wesey"
  },
  {
    "id": "5321042002",
    "district_id": "532104",
    "label": "Haitimuk",
    "value": "Haitimuk"
  },
  {
    "id": "5321042003",
    "district_id": "532104",
    "label": "Laleten",
    "value": "Laleten"
  },
  {
    "id": "5321042004",
    "district_id": "532104",
    "label": "Kleseleon",
    "value": "Kleseleon"
  },
  {
    "id": "5321042005",
    "district_id": "532104",
    "label": "Angkaes",
    "value": "Angkaes"
  },
  {
    "id": "5321042006",
    "district_id": "532104",
    "label": "Wederok",
    "value": "Wederok"
  },
  {
    "id": "5321042007",
    "district_id": "532104",
    "label": "Lamudur",
    "value": "Lamudur"
  },
  {
    "id": "5321042008",
    "district_id": "532104",
    "label": "Forekmodok",
    "value": "Forekmodok"
  },
  {
    "id": "5321042009",
    "district_id": "532104",
    "label": "Umalawain",
    "value": "Umalawain"
  },
  {
    "id": "5321042010",
    "district_id": "532104",
    "label": "Lakulo",
    "value": "Lakulo"
  },
  {
    "id": "5321042011",
    "district_id": "532104",
    "label": "Leunklot",
    "value": "Leunklot"
  },
  {
    "id": "5321042012",
    "district_id": "532104",
    "label": "Haliklaran",
    "value": "Haliklaran"
  },
  {
    "id": "5321042013",
    "district_id": "532104",
    "label": "Bonetasea",
    "value": "Bonetasea"
  },
  {
    "id": "5321042014",
    "district_id": "532104",
    "label": "Taaba",
    "value": "Taaba"
  },
  {
    "id": "5321052001",
    "district_id": "532105",
    "label": "Saenama",
    "value": "Saenama"
  },
  {
    "id": "5321052002",
    "district_id": "532105",
    "label": "Wekmidar",
    "value": "Wekmidar"
  },
  {
    "id": "5321052003",
    "district_id": "532105",
    "label": "Lotas",
    "value": "Lotas"
  },
  {
    "id": "5321052004",
    "district_id": "532105",
    "label": "Nanin",
    "value": "Nanin"
  },
  {
    "id": "5321052005",
    "district_id": "532105",
    "label": "Alala",
    "value": "Alala"
  },
  {
    "id": "5321052006",
    "district_id": "532105",
    "label": "Oekmurak",
    "value": "Oekmurak"
  },
  {
    "id": "5321052007",
    "district_id": "532105",
    "label": "Webetun",
    "value": "Webetun"
  },
  {
    "id": "5321052008",
    "district_id": "532105",
    "label": "Naet",
    "value": "Naet"
  },
  {
    "id": "5321052009",
    "district_id": "532105",
    "label": "Biudukfoho",
    "value": "Biudukfoho"
  },
  {
    "id": "5321052010",
    "district_id": "532105",
    "label": "Niti",
    "value": "Niti"
  },
  {
    "id": "5321052011",
    "district_id": "532105",
    "label": "Tafuli",
    "value": "Tafuli"
  },
  {
    "id": "5321052012",
    "district_id": "532105",
    "label": "Boen",
    "value": "Boen"
  },
  {
    "id": "5321052013",
    "district_id": "532105",
    "label": "Wekeke",
    "value": "Wekeke"
  },
  {
    "id": "5321052014",
    "district_id": "532105",
    "label": "Raisamane",
    "value": "Raisamane"
  },
  {
    "id": "5321052015",
    "district_id": "532105",
    "label": "Weain",
    "value": "Weain"
  },
  {
    "id": "5321052016",
    "district_id": "532105",
    "label": "Nabutaek",
    "value": "Nabutaek"
  },
  {
    "id": "5321052017",
    "district_id": "532105",
    "label": "Tafuli I",
    "value": "Tafuli I"
  },
  {
    "id": "5321052018",
    "district_id": "532105",
    "label": "Nanebot",
    "value": "Nanebot"
  },
  {
    "id": "5321052019",
    "district_id": "532105",
    "label": "Muke",
    "value": "Muke"
  },
  {
    "id": "5321052020",
    "district_id": "532105",
    "label": "Naiusu",
    "value": "Naiusu"
  },
  {
    "id": "5321062001",
    "district_id": "532106",
    "label": "Biau",
    "value": "Biau"
  },
  {
    "id": "5321062002",
    "district_id": "532106",
    "label": "Kufeu",
    "value": "Kufeu"
  },
  {
    "id": "5321062003",
    "district_id": "532106",
    "label": "Tunmat",
    "value": "Tunmat"
  },
  {
    "id": "5321062004",
    "district_id": "532106",
    "label": "Tunabesi",
    "value": "Tunabesi"
  },
  {
    "id": "5321062005",
    "district_id": "532106",
    "label": "Bani-Bani",
    "value": "Bani-Bani"
  },
  {
    "id": "5321062006",
    "district_id": "532106",
    "label": "Fatoin",
    "value": "Fatoin"
  },
  {
    "id": "5321062007",
    "district_id": "532106",
    "label": "Ikan Tuanbeis",
    "value": "Ikan Tuanbeis"
  },
  {
    "id": "5321072001",
    "district_id": "532107",
    "label": "As Manulea",
    "value": "As Manulea"
  },
  {
    "id": "5321072002",
    "district_id": "532107",
    "label": "Manulea",
    "value": "Manulea"
  },
  {
    "id": "5321072003",
    "district_id": "532107",
    "label": "Naibone",
    "value": "Naibone"
  },
  {
    "id": "5321072004",
    "district_id": "532107",
    "label": "Fatuaruin",
    "value": "Fatuaruin"
  },
  {
    "id": "5321072005",
    "district_id": "532107",
    "label": "Builaran",
    "value": "Builaran"
  },
  {
    "id": "5321072006",
    "district_id": "532107",
    "label": "Manumutin Silole",
    "value": "Manumutin Silole"
  },
  {
    "id": "5321072007",
    "district_id": "532107",
    "label": "Umutnana",
    "value": "Umutnana"
  },
  {
    "id": "5321072008",
    "district_id": "532107",
    "label": "Beaneno",
    "value": "Beaneno"
  },
  {
    "id": "5321072009",
    "district_id": "532107",
    "label": "Naisau",
    "value": "Naisau"
  },
  {
    "id": "5321082001",
    "district_id": "532108",
    "label": "Meotroi",
    "value": "Meotroi"
  },
  {
    "id": "5321082002",
    "district_id": "532108",
    "label": "Tesa",
    "value": "Tesa"
  },
  {
    "id": "5321082003",
    "district_id": "532108",
    "label": "Kapitan Meo",
    "value": "Kapitan Meo"
  },
  {
    "id": "5321082004",
    "district_id": "532108",
    "label": "Tniumanu",
    "value": "Tniumanu"
  },
  {
    "id": "5321082005",
    "district_id": "532108",
    "label": "Uabau",
    "value": "Uabau"
  },
  {
    "id": "5321082006",
    "district_id": "532108",
    "label": "Boni Bais",
    "value": "Boni Bais"
  },
  {
    "id": "5321082007",
    "district_id": "532108",
    "label": "Nauke Kusa",
    "value": "Nauke Kusa"
  },
  {
    "id": "5321082008",
    "district_id": "532108",
    "label": "Bisesmus",
    "value": "Bisesmus"
  },
  {
    "id": "5321082009",
    "district_id": "532108",
    "label": "Oenaek",
    "value": "Oenaek"
  },
  {
    "id": "5321092001",
    "district_id": "532109",
    "label": "Raiulun",
    "value": "Raiulun"
  },
  {
    "id": "5321092002",
    "district_id": "532109",
    "label": "Wemeda",
    "value": "Wemeda"
  },
  {
    "id": "5321092003",
    "district_id": "532109",
    "label": "Kusa",
    "value": "Kusa"
  },
  {
    "id": "5321092004",
    "district_id": "532109",
    "label": "Numponi",
    "value": "Numponi"
  },
  {
    "id": "5321092005",
    "district_id": "532109",
    "label": "Dirma",
    "value": "Dirma"
  },
  {
    "id": "5321092006",
    "district_id": "532109",
    "label": "Sanleo",
    "value": "Sanleo"
  },
  {
    "id": "5321102001",
    "district_id": "532110",
    "label": "Alas Utara",
    "value": "Alas Utara"
  },
  {
    "id": "5321102002",
    "district_id": "532110",
    "label": "Kotabiru",
    "value": "Kotabiru"
  },
  {
    "id": "5321102003",
    "district_id": "532110",
    "label": "Alas",
    "value": "Alas"
  },
  {
    "id": "5321102004",
    "district_id": "532110",
    "label": "Alas Selatan",
    "value": "Alas Selatan"
  },
  {
    "id": "5321112001",
    "district_id": "532111",
    "label": "Babulu",
    "value": "Babulu"
  },
  {
    "id": "5321112002",
    "district_id": "532111",
    "label": "Babulu Selatan",
    "value": "Babulu Selatan"
  },
  {
    "id": "5321112003",
    "district_id": "532111",
    "label": "Sisi",
    "value": "Sisi"
  },
  {
    "id": "5321112004",
    "district_id": "532111",
    "label": "Rainawe",
    "value": "Rainawe"
  },
  {
    "id": "5321112005",
    "district_id": "532111",
    "label": "Litamali",
    "value": "Litamali"
  },
  {
    "id": "5321112006",
    "district_id": "532111",
    "label": "Lakekun Utara",
    "value": "Lakekun Utara"
  },
  {
    "id": "5321112007",
    "district_id": "532111",
    "label": "Lakekun",
    "value": "Lakekun"
  },
  {
    "id": "5321112008",
    "district_id": "532111",
    "label": "Lakekun Barat",
    "value": "Lakekun Barat"
  },
  {
    "id": "5321122001",
    "district_id": "532112",
    "label": "Babotin",
    "value": "Babotin"
  },
  {
    "id": "5321122002",
    "district_id": "532112",
    "label": "Kereana",
    "value": "Kereana"
  },
  {
    "id": "5321122003",
    "district_id": "532112",
    "label": "Babotin Maemina",
    "value": "Babotin Maemina"
  },
  {
    "id": "5321122004",
    "district_id": "532112",
    "label": "Babotin Selatan",
    "value": "Babotin Selatan"
  },
  {
    "id": "5321122005",
    "district_id": "532112",
    "label": "Takarai",
    "value": "Takarai"
  },
  {
    "id": "5371011001",
    "district_id": "537101",
    "label": "Namosain",
    "value": "Namosain"
  },
  {
    "id": "5371011002",
    "district_id": "537101",
    "label": "Nunbaun Sabu",
    "value": "Nunbaun Sabu"
  },
  {
    "id": "5371011003",
    "district_id": "537101",
    "label": "Nunbaun Delha",
    "value": "Nunbaun Delha"
  },
  {
    "id": "5371011004",
    "district_id": "537101",
    "label": "Nunhila",
    "value": "Nunhila"
  },
  {
    "id": "5371011005",
    "district_id": "537101",
    "label": "Fatufeto",
    "value": "Fatufeto"
  },
  {
    "id": "5371011006",
    "district_id": "537101",
    "label": "Manutapen",
    "value": "Manutapen"
  },
  {
    "id": "5371011007",
    "district_id": "537101",
    "label": "Mantasi",
    "value": "Mantasi"
  },
  {
    "id": "5371011008",
    "district_id": "537101",
    "label": "Alak",
    "value": "Alak"
  },
  {
    "id": "5371011009",
    "district_id": "537101",
    "label": "Batu Plat",
    "value": "Batu Plat"
  },
  {
    "id": "5371011010",
    "district_id": "537101",
    "label": "Manulai II",
    "value": "Manulai II"
  },
  {
    "id": "5371011011",
    "district_id": "537101",
    "label": "Naioni",
    "value": "Naioni"
  },
  {
    "id": "5371011012",
    "district_id": "537101",
    "label": "Penkase Oeleta",
    "value": "Penkase Oeleta"
  },
  {
    "id": "5371021001",
    "district_id": "537102",
    "label": "Oepura",
    "value": "Oepura"
  },
  {
    "id": "5371021002",
    "district_id": "537102",
    "label": "Maulafa",
    "value": "Maulafa"
  },
  {
    "id": "5371021003",
    "district_id": "537102",
    "label": "Penfui",
    "value": "Penfui"
  },
  {
    "id": "5371021004",
    "district_id": "537102",
    "label": "Naimata",
    "value": "Naimata"
  },
  {
    "id": "5371021005",
    "district_id": "537102",
    "label": "Bello",
    "value": "Bello"
  },
  {
    "id": "5371021006",
    "district_id": "537102",
    "label": "Fatukoa",
    "value": "Fatukoa"
  },
  {
    "id": "5371021007",
    "district_id": "537102",
    "label": "Kolhua",
    "value": "Kolhua"
  },
  {
    "id": "5371021008",
    "district_id": "537102",
    "label": "Sikumana",
    "value": "Sikumana"
  },
  {
    "id": "5371021009",
    "district_id": "537102",
    "label": "Naikolan",
    "value": "Naikolan"
  },
  {
    "id": "5371031001",
    "district_id": "537103",
    "label": "Kelapa Lima",
    "value": "Kelapa Lima"
  },
  {
    "id": "5371031010",
    "district_id": "537103",
    "label": "Oesapa",
    "value": "Oesapa"
  },
  {
    "id": "5371031011",
    "district_id": "537103",
    "label": "Lasiana",
    "value": "Lasiana"
  },
  {
    "id": "5371031014",
    "district_id": "537103",
    "label": "Oesapa Barat",
    "value": "Oesapa Barat"
  },
  {
    "id": "5371031015",
    "district_id": "537103",
    "label": "Oesapa Selatan",
    "value": "Oesapa Selatan"
  },
  {
    "id": "5371041001",
    "district_id": "537104",
    "label": "Oebobo",
    "value": "Oebobo"
  },
  {
    "id": "5371041008",
    "district_id": "537104",
    "label": "Oetete",
    "value": "Oetete"
  },
  {
    "id": "5371041009",
    "district_id": "537104",
    "label": "Oebufu",
    "value": "Oebufu"
  },
  {
    "id": "5371041010",
    "district_id": "537104",
    "label": "Liliba",
    "value": "Liliba"
  },
  {
    "id": "5371041011",
    "district_id": "537104",
    "label": "Fatululi",
    "value": "Fatululi"
  },
  {
    "id": "5371041013",
    "district_id": "537104",
    "label": "Kayu Putih",
    "value": "Kayu Putih"
  },
  {
    "id": "5371041014",
    "district_id": "537104",
    "label": "Tuak Daun Merah",
    "value": "Tuak Daun Merah"
  },
  {
    "id": "5371051001",
    "district_id": "537105",
    "label": "Bakunase",
    "value": "Bakunase"
  },
  {
    "id": "5371051002",
    "district_id": "537105",
    "label": "Bakunase Dua",
    "value": "Bakunase Dua"
  },
  {
    "id": "5371051003",
    "district_id": "537105",
    "label": "Airnona",
    "value": "Airnona"
  },
  {
    "id": "5371051004",
    "district_id": "537105",
    "label": "Naikoten Satu",
    "value": "Naikoten Satu"
  },
  {
    "id": "5371051005",
    "district_id": "537105",
    "label": "Naikoten Dua",
    "value": "Naikoten Dua"
  },
  {
    "id": "5371051006",
    "district_id": "537105",
    "label": "Kuanino",
    "value": "Kuanino"
  },
  {
    "id": "5371051007",
    "district_id": "537105",
    "label": "Nunleu",
    "value": "Nunleu"
  },
  {
    "id": "5371051008",
    "district_id": "537105",
    "label": "Fontein",
    "value": "Fontein"
  },
  {
    "id": "5371061001",
    "district_id": "537106",
    "label": "Airmata",
    "value": "Airmata"
  },
  {
    "id": "5371061002",
    "district_id": "537106",
    "label": "Lai Lai Bisi Kopan",
    "value": "Lai Lai Bisi Kopan"
  },
  {
    "id": "5371061003",
    "district_id": "537106",
    "label": "Bonipoi",
    "value": "Bonipoi"
  },
  {
    "id": "5371061004",
    "district_id": "537106",
    "label": "Solor",
    "value": "Solor"
  },
  {
    "id": "5371061005",
    "district_id": "537106",
    "label": "Tode Kisar",
    "value": "Tode Kisar"
  },
  {
    "id": "5371061006",
    "district_id": "537106",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "5371061007",
    "district_id": "537106",
    "label": "Oeba",
    "value": "Oeba"
  },
  {
    "id": "5371061008",
    "district_id": "537106",
    "label": "Fatubesi",
    "value": "Fatubesi"
  },
  {
    "id": "5371061009",
    "district_id": "537106",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "5371061010",
    "district_id": "537106",
    "label": "Nefonaek",
    "value": "Nefonaek"
  },
  {
    "id": "6101012001",
    "district_id": "610101",
    "label": "Dalam Kaum",
    "value": "Dalam Kaum"
  },
  {
    "id": "6101012002",
    "district_id": "610101",
    "label": "Lubuk Dagang",
    "value": "Lubuk Dagang"
  },
  {
    "id": "6101012003",
    "district_id": "610101",
    "label": "Tanjung Bugis",
    "value": "Tanjung Bugis"
  },
  {
    "id": "6101012004",
    "district_id": "610101",
    "label": "Pendawan",
    "value": "Pendawan"
  },
  {
    "id": "6101012005",
    "district_id": "610101",
    "label": "Pasar Melayu",
    "value": "Pasar Melayu"
  },
  {
    "id": "6101012006",
    "district_id": "610101",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "6101012007",
    "district_id": "610101",
    "label": "Lorong",
    "value": "Lorong"
  },
  {
    "id": "6101012008",
    "district_id": "610101",
    "label": "Jagur",
    "value": "Jagur"
  },
  {
    "id": "6101012009",
    "district_id": "610101",
    "label": "Tumuk Manggis",
    "value": "Tumuk Manggis"
  },
  {
    "id": "6101012010",
    "district_id": "610101",
    "label": "Tanjung Mekar",
    "value": "Tanjung Mekar"
  },
  {
    "id": "6101012011",
    "district_id": "610101",
    "label": "Sebayan",
    "value": "Sebayan"
  },
  {
    "id": "6101012012",
    "district_id": "610101",
    "label": "Kartiasa",
    "value": "Kartiasa"
  },
  {
    "id": "6101012013",
    "district_id": "610101",
    "label": "Saing Rambi",
    "value": "Saing Rambi"
  },
  {
    "id": "6101012014",
    "district_id": "610101",
    "label": "Lumbang",
    "value": "Lumbang"
  },
  {
    "id": "6101012015",
    "district_id": "610101",
    "label": "Sungai Rambah",
    "value": "Sungai Rambah"
  },
  {
    "id": "6101012023",
    "district_id": "610101",
    "label": "Gapura",
    "value": "Gapura"
  },
  {
    "id": "6101012024",
    "district_id": "610101",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "6101012029",
    "district_id": "610101",
    "label": "Semangau",
    "value": "Semangau"
  },
  {
    "id": "6101022001",
    "district_id": "610102",
    "label": "Sungai Kumpai",
    "value": "Sungai Kumpai"
  },
  {
    "id": "6101022002",
    "district_id": "610102",
    "label": "Sekura",
    "value": "Sekura"
  },
  {
    "id": "6101022003",
    "district_id": "610102",
    "label": "Tri Mandayan",
    "value": "Tri Mandayan"
  },
  {
    "id": "6101022004",
    "district_id": "610102",
    "label": "Pedada",
    "value": "Pedada"
  },
  {
    "id": "6101022005",
    "district_id": "610102",
    "label": "Lela",
    "value": "Lela"
  },
  {
    "id": "6101022006",
    "district_id": "610102",
    "label": "Puringan",
    "value": "Puringan"
  },
  {
    "id": "6101022007",
    "district_id": "610102",
    "label": "Berlimang",
    "value": "Berlimang"
  },
  {
    "id": "6101022008",
    "district_id": "610102",
    "label": "Sungai Baru",
    "value": "Sungai Baru"
  },
  {
    "id": "6101022009",
    "district_id": "610102",
    "label": "Sengawang",
    "value": "Sengawang"
  },
  {
    "id": "6101022010",
    "district_id": "610102",
    "label": "Teluk Kaseh",
    "value": "Teluk Kaseh"
  },
  {
    "id": "6101022011",
    "district_id": "610102",
    "label": "Sepadu",
    "value": "Sepadu"
  },
  {
    "id": "6101022012",
    "district_id": "610102",
    "label": "Tambatan",
    "value": "Tambatan"
  },
  {
    "id": "6101022013",
    "district_id": "610102",
    "label": "Kubangga",
    "value": "Kubangga"
  },
  {
    "id": "6101022020",
    "district_id": "610102",
    "label": "Sungai Serabek",
    "value": "Sungai Serabek"
  },
  {
    "id": "6101022021",
    "district_id": "610102",
    "label": "Sayang Sedayu",
    "value": "Sayang Sedayu"
  },
  {
    "id": "6101022022",
    "district_id": "610102",
    "label": "Pipit Teja",
    "value": "Pipit Teja"
  },
  {
    "id": "6101022024",
    "district_id": "610102",
    "label": "Matang Segantar",
    "value": "Matang Segantar"
  },
  {
    "id": "6101022025",
    "district_id": "610102",
    "label": "Mulia",
    "value": "Mulia"
  },
  {
    "id": "6101022026",
    "district_id": "610102",
    "label": "Teluk Kumbang",
    "value": "Teluk Kumbang"
  },
  {
    "id": "6101022027",
    "district_id": "610102",
    "label": "Samustida",
    "value": "Samustida"
  },
  {
    "id": "6101022028",
    "district_id": "610102",
    "label": "Tanjung Kerucut",
    "value": "Tanjung Kerucut"
  },
  {
    "id": "6101022029",
    "district_id": "610102",
    "label": "Sebagu",
    "value": "Sebagu"
  },
  {
    "id": "6101022030",
    "district_id": "610102",
    "label": "Mekar Sekuntum",
    "value": "Mekar Sekuntum"
  },
  {
    "id": "6101022031",
    "district_id": "610102",
    "label": "Kuala Pangkalan Keramat",
    "value": "Kuala Pangkalan Keramat"
  },
  {
    "id": "6101022032",
    "district_id": "610102",
    "label": "Sabing",
    "value": "Sabing"
  },
  {
    "id": "6101032001",
    "district_id": "610103",
    "label": "Sarang Burung Danau",
    "value": "Sarang Burung Danau"
  },
  {
    "id": "6101032002",
    "district_id": "610103",
    "label": "Sungai Nilam",
    "value": "Sungai Nilam"
  },
  {
    "id": "6101032003",
    "district_id": "610103",
    "label": "Sarang Burung Kolam",
    "value": "Sarang Burung Kolam"
  },
  {
    "id": "6101032004",
    "district_id": "610103",
    "label": "Sarang Burung Usrat",
    "value": "Sarang Burung Usrat"
  },
  {
    "id": "6101032005",
    "district_id": "610103",
    "label": "Sarang Burung Kuala",
    "value": "Sarang Burung Kuala"
  },
  {
    "id": "6101032006",
    "district_id": "610103",
    "label": "Pelimpaan",
    "value": "Pelimpaan"
  },
  {
    "id": "6101032007",
    "district_id": "610103",
    "label": "Parit Setia",
    "value": "Parit Setia"
  },
  {
    "id": "6101032008",
    "district_id": "610103",
    "label": "Bakau",
    "value": "Bakau"
  },
  {
    "id": "6101032009",
    "district_id": "610103",
    "label": "Sungai Nyirih",
    "value": "Sungai Nyirih"
  },
  {
    "id": "6101032010",
    "district_id": "610103",
    "label": "Sentebang",
    "value": "Sentebang"
  },
  {
    "id": "6101032011",
    "district_id": "610103",
    "label": "Dungun Laut",
    "value": "Dungun Laut"
  },
  {
    "id": "6101032021",
    "district_id": "610103",
    "label": "Lambau",
    "value": "Lambau"
  },
  {
    "id": "6101032022",
    "district_id": "610103",
    "label": "Mutus Darussalam",
    "value": "Mutus Darussalam"
  },
  {
    "id": "6101042001",
    "district_id": "610104",
    "label": "Tebas Kuala",
    "value": "Tebas Kuala"
  },
  {
    "id": "6101042002",
    "district_id": "610104",
    "label": "Tebas Sungai",
    "value": "Tebas Sungai"
  },
  {
    "id": "6101042003",
    "district_id": "610104",
    "label": "Sempalai",
    "value": "Sempalai"
  },
  {
    "id": "6101042004",
    "district_id": "610104",
    "label": "Bekut",
    "value": "Bekut"
  },
  {
    "id": "6101042005",
    "district_id": "610104",
    "label": "Seberkat",
    "value": "Seberkat"
  },
  {
    "id": "6101042006",
    "district_id": "610104",
    "label": "Sejiram",
    "value": "Sejiram"
  },
  {
    "id": "6101042007",
    "district_id": "610104",
    "label": "Makrampai",
    "value": "Makrampai"
  },
  {
    "id": "6101042008",
    "district_id": "610104",
    "label": "Mekar Sekuntum",
    "value": "Mekar Sekuntum"
  },
  {
    "id": "6101042009",
    "district_id": "610104",
    "label": "Mensere",
    "value": "Mensere"
  },
  {
    "id": "6101042010",
    "district_id": "610104",
    "label": "Pusaka",
    "value": "Pusaka"
  },
  {
    "id": "6101042011",
    "district_id": "610104",
    "label": "Segedong",
    "value": "Segedong"
  },
  {
    "id": "6101042012",
    "district_id": "610104",
    "label": "Sungai Kelambu",
    "value": "Sungai Kelambu"
  },
  {
    "id": "6101042013",
    "district_id": "610104",
    "label": "Serumpun Buluh",
    "value": "Serumpun Buluh"
  },
  {
    "id": "6101042014",
    "district_id": "610104",
    "label": "Pangkalan Kongsi",
    "value": "Pangkalan Kongsi"
  },
  {
    "id": "6101042015",
    "district_id": "610104",
    "label": "Dungun Perapakan",
    "value": "Dungun Perapakan"
  },
  {
    "id": "6101042016",
    "district_id": "610104",
    "label": "Batu Makjage",
    "value": "Batu Makjage"
  },
  {
    "id": "6101042017",
    "district_id": "610104",
    "label": "Serindang",
    "value": "Serindang"
  },
  {
    "id": "6101042018",
    "district_id": "610104",
    "label": "Bukit Sigoler",
    "value": "Bukit Sigoler"
  },
  {
    "id": "6101042019",
    "district_id": "610104",
    "label": "Matang Labong",
    "value": "Matang Labong"
  },
  {
    "id": "6101042020",
    "district_id": "610104",
    "label": "Maktangguk",
    "value": "Maktangguk"
  },
  {
    "id": "6101042021",
    "district_id": "610104",
    "label": "Segarau Parit",
    "value": "Segarau Parit"
  },
  {
    "id": "6101042022",
    "district_id": "610104",
    "label": "Maribas",
    "value": "Maribas"
  },
  {
    "id": "6101042023",
    "district_id": "610104",
    "label": "Seret Ayon",
    "value": "Seret Ayon"
  },
  {
    "id": "6101052001",
    "district_id": "610105",
    "label": "Pemangkat Kota",
    "value": "Pemangkat Kota"
  },
  {
    "id": "6101052002",
    "district_id": "610105",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "6101052003",
    "district_id": "610105",
    "label": "Penjajap",
    "value": "Penjajap"
  },
  {
    "id": "6101052005",
    "district_id": "610105",
    "label": "Jelutung",
    "value": "Jelutung"
  },
  {
    "id": "6101052013",
    "district_id": "610105",
    "label": "Perapakan",
    "value": "Perapakan"
  },
  {
    "id": "6101052016",
    "district_id": "610105",
    "label": "Sebatuan",
    "value": "Sebatuan"
  },
  {
    "id": "6101052017",
    "district_id": "610105",
    "label": "Gugah Sejahtera",
    "value": "Gugah Sejahtera"
  },
  {
    "id": "6101052018",
    "district_id": "610105",
    "label": "Lonam",
    "value": "Lonam"
  },
  {
    "id": "6101062001",
    "district_id": "610106",
    "label": "Sulung",
    "value": "Sulung"
  },
  {
    "id": "6101062002",
    "district_id": "610106",
    "label": "Penakalan",
    "value": "Penakalan"
  },
  {
    "id": "6101062003",
    "district_id": "610106",
    "label": "Sekuduk",
    "value": "Sekuduk"
  },
  {
    "id": "6101062004",
    "district_id": "610106",
    "label": "Setalik",
    "value": "Setalik"
  },
  {
    "id": "6101062005",
    "district_id": "610106",
    "label": "Parit Raja",
    "value": "Parit Raja"
  },
  {
    "id": "6101062006",
    "district_id": "610106",
    "label": "Piantus",
    "value": "Piantus"
  },
  {
    "id": "6101062007",
    "district_id": "610106",
    "label": "Perigi Landu",
    "value": "Perigi Landu"
  },
  {
    "id": "6101062008",
    "district_id": "610106",
    "label": "Sendoyan",
    "value": "Sendoyan"
  },
  {
    "id": "6101062009",
    "district_id": "610106",
    "label": "Senujuh",
    "value": "Senujuh"
  },
  {
    "id": "6101062010",
    "district_id": "610106",
    "label": "Perigi Limus",
    "value": "Perigi Limus"
  },
  {
    "id": "6101062011",
    "district_id": "610106",
    "label": "Semangga",
    "value": "Semangga"
  },
  {
    "id": "6101062012",
    "district_id": "610106",
    "label": "Sepantai",
    "value": "Sepantai"
  },
  {
    "id": "6101072001",
    "district_id": "610107",
    "label": "Semelagi Besar",
    "value": "Semelagi Besar"
  },
  {
    "id": "6101072002",
    "district_id": "610107",
    "label": "Sungai Daun",
    "value": "Sungai Daun"
  },
  {
    "id": "6101072003",
    "district_id": "610107",
    "label": "Sungai Rusa",
    "value": "Sungai Rusa"
  },
  {
    "id": "6101072004",
    "district_id": "610107",
    "label": "Sungai Nyirih",
    "value": "Sungai Nyirih"
  },
  {
    "id": "6101072005",
    "district_id": "610107",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "6101072006",
    "district_id": "610107",
    "label": "Parit Baru",
    "value": "Parit Baru"
  },
  {
    "id": "6101072007",
    "district_id": "610107",
    "label": "Twi Mentibar",
    "value": "Twi Mentibar"
  },
  {
    "id": "6101072008",
    "district_id": "610107",
    "label": "Bentunai",
    "value": "Bentunai"
  },
  {
    "id": "6101072013",
    "district_id": "610107",
    "label": "Pangkalan Bemban",
    "value": "Pangkalan Bemban"
  },
  {
    "id": "6101072014",
    "district_id": "610107",
    "label": "Parit Kongsi",
    "value": "Parit Kongsi"
  },
  {
    "id": "6101072015",
    "district_id": "610107",
    "label": "Gayung Bersambut",
    "value": "Gayung Bersambut"
  },
  {
    "id": "6101082001",
    "district_id": "610108",
    "label": "Sebubus",
    "value": "Sebubus"
  },
  {
    "id": "6101082002",
    "district_id": "610108",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "6101082003",
    "district_id": "610108",
    "label": "Malek",
    "value": "Malek"
  },
  {
    "id": "6101082004",
    "district_id": "610108",
    "label": "Tanah Hitam",
    "value": "Tanah Hitam"
  },
  {
    "id": "6101082005",
    "district_id": "610108",
    "label": "Matang Danau",
    "value": "Matang Danau"
  },
  {
    "id": "6101082006",
    "district_id": "610108",
    "label": "Kalimantan",
    "value": "Kalimantan"
  },
  {
    "id": "6101082007",
    "district_id": "610108",
    "label": "Temajuk",
    "value": "Temajuk"
  },
  {
    "id": "6101082008",
    "district_id": "610108",
    "label": "Mentibar",
    "value": "Mentibar"
  },
  {
    "id": "6101092001",
    "district_id": "610109",
    "label": "Kaliau'",
    "value": "Kaliau'"
  },
  {
    "id": "6101092002",
    "district_id": "610109",
    "label": "Sebunga",
    "value": "Sebunga"
  },
  {
    "id": "6101092003",
    "district_id": "610109",
    "label": "Santaban",
    "value": "Santaban"
  },
  {
    "id": "6101092004",
    "district_id": "610109",
    "label": "Senatab",
    "value": "Senatab"
  },
  {
    "id": "6101092005",
    "district_id": "610109",
    "label": "Sungai Bening",
    "value": "Sungai Bening"
  },
  {
    "id": "6101102001",
    "district_id": "610110",
    "label": "Balai Gemuruh",
    "value": "Balai Gemuruh"
  },
  {
    "id": "6101102002",
    "district_id": "610110",
    "label": "Sungai Sapa'",
    "value": "Sungai Sapa'"
  },
  {
    "id": "6101102003",
    "district_id": "610110",
    "label": "Madak",
    "value": "Madak"
  },
  {
    "id": "6101102004",
    "district_id": "610110",
    "label": "Sabung",
    "value": "Sabung"
  },
  {
    "id": "6101102005",
    "district_id": "610110",
    "label": "Tebuah Elok",
    "value": "Tebuah Elok"
  },
  {
    "id": "6101102006",
    "district_id": "610110",
    "label": "Sungai Deden",
    "value": "Sungai Deden"
  },
  {
    "id": "6101102007",
    "district_id": "610110",
    "label": "Bukit Mulya",
    "value": "Bukit Mulya"
  },
  {
    "id": "6101102008",
    "district_id": "610110",
    "label": "Mukti Raharja",
    "value": "Mukti Raharja"
  },
  {
    "id": "6101102009",
    "district_id": "610110",
    "label": "Sempurna",
    "value": "Sempurna"
  },
  {
    "id": "6101102010",
    "district_id": "610110",
    "label": "Mensade",
    "value": "Mensade"
  },
  {
    "id": "6101102011",
    "district_id": "610110",
    "label": "Karaban Jaya",
    "value": "Karaban Jaya"
  },
  {
    "id": "6101112001",
    "district_id": "610111",
    "label": "Sagu",
    "value": "Sagu"
  },
  {
    "id": "6101112002",
    "district_id": "610111",
    "label": "Sungai Palah",
    "value": "Sungai Palah"
  },
  {
    "id": "6101112003",
    "district_id": "610111",
    "label": "Galing",
    "value": "Galing"
  },
  {
    "id": "6101112004",
    "district_id": "610111",
    "label": "Tempapan Kuala",
    "value": "Tempapan Kuala"
  },
  {
    "id": "6101112005",
    "district_id": "610111",
    "label": "Tempapan Hulu",
    "value": "Tempapan Hulu"
  },
  {
    "id": "6101112006",
    "district_id": "610111",
    "label": "Ratu Sepudak",
    "value": "Ratu Sepudak"
  },
  {
    "id": "6101112007",
    "district_id": "610111",
    "label": "Tri Kembang",
    "value": "Tri Kembang"
  },
  {
    "id": "6101112008",
    "district_id": "610111",
    "label": "Tri Gadu",
    "value": "Tri Gadu"
  },
  {
    "id": "6101112009",
    "district_id": "610111",
    "label": "Teluk Pandan",
    "value": "Teluk Pandan"
  },
  {
    "id": "6101112010",
    "district_id": "610111",
    "label": "Sijang",
    "value": "Sijang"
  },
  {
    "id": "6101122001",
    "district_id": "610112",
    "label": "Tekarang",
    "value": "Tekarang"
  },
  {
    "id": "6101122002",
    "district_id": "610112",
    "label": "Merubung",
    "value": "Merubung"
  },
  {
    "id": "6101122003",
    "district_id": "610112",
    "label": "Cepala",
    "value": "Cepala"
  },
  {
    "id": "6101122004",
    "district_id": "610112",
    "label": "Sari Makmur",
    "value": "Sari Makmur"
  },
  {
    "id": "6101122005",
    "district_id": "610112",
    "label": "Rambayan",
    "value": "Rambayan"
  },
  {
    "id": "6101122006",
    "district_id": "610112",
    "label": "Sempadian",
    "value": "Sempadian"
  },
  {
    "id": "6101122007",
    "district_id": "610112",
    "label": "Matang Segarau",
    "value": "Matang Segarau"
  },
  {
    "id": "6101132001",
    "district_id": "610113",
    "label": "Singa Raya",
    "value": "Singa Raya"
  },
  {
    "id": "6101132002",
    "district_id": "610113",
    "label": "Semparuk",
    "value": "Semparuk"
  },
  {
    "id": "6101132003",
    "district_id": "610113",
    "label": "Sepinggan",
    "value": "Sepinggan"
  },
  {
    "id": "6101132004",
    "district_id": "610113",
    "label": "Sepadu",
    "value": "Sepadu"
  },
  {
    "id": "6101132005",
    "district_id": "610113",
    "label": "Seburing",
    "value": "Seburing"
  },
  {
    "id": "6101142001",
    "district_id": "610114",
    "label": "Jirak",
    "value": "Jirak"
  },
  {
    "id": "6101142002",
    "district_id": "610114",
    "label": "Tengguli",
    "value": "Tengguli"
  },
  {
    "id": "6101142003",
    "district_id": "610114",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6101142004",
    "district_id": "610114",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6101152001",
    "district_id": "610115",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6101152002",
    "district_id": "610115",
    "label": "Tebing Batu",
    "value": "Tebing Batu"
  },
  {
    "id": "6101152003",
    "district_id": "610115",
    "label": "Sebawi",
    "value": "Sebawi"
  },
  {
    "id": "6101152004",
    "district_id": "610115",
    "label": "Sepuk Tanjung",
    "value": "Sepuk Tanjung"
  },
  {
    "id": "6101152005",
    "district_id": "610115",
    "label": "Sebangun",
    "value": "Sebangun"
  },
  {
    "id": "6101152006",
    "district_id": "610115",
    "label": "Sempalai Sebedang",
    "value": "Sempalai Sebedang"
  },
  {
    "id": "6101152007",
    "district_id": "610115",
    "label": "Tempatan",
    "value": "Tempatan"
  },
  {
    "id": "6101162001",
    "district_id": "610116",
    "label": "Jawai Laut",
    "value": "Jawai Laut"
  },
  {
    "id": "6101162002",
    "district_id": "610116",
    "label": "Jelu Air",
    "value": "Jelu Air"
  },
  {
    "id": "6101162003",
    "district_id": "610116",
    "label": "Matang Terap",
    "value": "Matang Terap"
  },
  {
    "id": "6101162004",
    "district_id": "610116",
    "label": "Suah Api",
    "value": "Suah Api"
  },
  {
    "id": "6101162005",
    "district_id": "610116",
    "label": "Sari Laba A",
    "value": "Sari Laba A"
  },
  {
    "id": "6101162006",
    "district_id": "610116",
    "label": "Sari Laba B",
    "value": "Sari Laba B"
  },
  {
    "id": "6101162007",
    "district_id": "610116",
    "label": "Semperiuk A",
    "value": "Semperiuk A"
  },
  {
    "id": "6101162008",
    "district_id": "610116",
    "label": "Semperiuk B",
    "value": "Semperiuk B"
  },
  {
    "id": "6101162009",
    "district_id": "610116",
    "label": "Sabaran",
    "value": "Sabaran"
  },
  {
    "id": "6101172001",
    "district_id": "610117",
    "label": "Tangaran",
    "value": "Tangaran"
  },
  {
    "id": "6101172002",
    "district_id": "610117",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "6101172003",
    "district_id": "610117",
    "label": "Merabuan",
    "value": "Merabuan"
  },
  {
    "id": "6101172004",
    "district_id": "610117",
    "label": "Semata",
    "value": "Semata"
  },
  {
    "id": "6101172005",
    "district_id": "610117",
    "label": "Merpati",
    "value": "Merpati"
  },
  {
    "id": "6101172006",
    "district_id": "610117",
    "label": "Pancur",
    "value": "Pancur"
  },
  {
    "id": "6101172007",
    "district_id": "610117",
    "label": "Arung Parak",
    "value": "Arung Parak"
  },
  {
    "id": "6101172008",
    "district_id": "610117",
    "label": "Arung Medang",
    "value": "Arung Medang"
  },
  {
    "id": "6101182001",
    "district_id": "610118",
    "label": "Parit Baru",
    "value": "Parit Baru"
  },
  {
    "id": "6101182002",
    "district_id": "610118",
    "label": "Sungai Toman",
    "value": "Sungai Toman"
  },
  {
    "id": "6101182003",
    "district_id": "610118",
    "label": "Serunai",
    "value": "Serunai"
  },
  {
    "id": "6101182004",
    "district_id": "610118",
    "label": "Serumpun",
    "value": "Serumpun"
  },
  {
    "id": "6101182005",
    "district_id": "610118",
    "label": "Salatiga",
    "value": "Salatiga"
  },
  {
    "id": "6101192001",
    "district_id": "610119",
    "label": "Gelik",
    "value": "Gelik"
  },
  {
    "id": "6101192002",
    "district_id": "610119",
    "label": "Seranggam",
    "value": "Seranggam"
  },
  {
    "id": "6101192003",
    "district_id": "610119",
    "label": "Selakau Tua",
    "value": "Selakau Tua"
  },
  {
    "id": "6101192004",
    "district_id": "610119",
    "label": "Buduk Sempadang",
    "value": "Buduk Sempadang"
  },
  {
    "id": "6102011006",
    "district_id": "610201",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6102011007",
    "district_id": "610201",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "6102011009",
    "district_id": "610201",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "6102012001",
    "district_id": "610201",
    "label": "Sengkubang",
    "value": "Sengkubang"
  },
  {
    "id": "6102012002",
    "district_id": "610201",
    "label": "Penibung",
    "value": "Penibung"
  },
  {
    "id": "6102012003",
    "district_id": "610201",
    "label": "Pasir",
    "value": "Pasir"
  },
  {
    "id": "6102012013",
    "district_id": "610201",
    "label": "Kuala Secapah",
    "value": "Kuala Secapah"
  },
  {
    "id": "6102012015",
    "district_id": "610201",
    "label": "Malikian",
    "value": "Malikian"
  },
  {
    "id": "6102062001",
    "district_id": "610206",
    "label": "Toho Ilir",
    "value": "Toho Ilir"
  },
  {
    "id": "6102062002",
    "district_id": "610206",
    "label": "Pak Laheng",
    "value": "Pak Laheng"
  },
  {
    "id": "6102062003",
    "district_id": "610206",
    "label": "Kecurit",
    "value": "Kecurit"
  },
  {
    "id": "6102062004",
    "district_id": "610206",
    "label": "Terap",
    "value": "Terap"
  },
  {
    "id": "6102062005",
    "district_id": "610206",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "6102062006",
    "district_id": "610206",
    "label": "Pak Utan",
    "value": "Pak Utan"
  },
  {
    "id": "6102062007",
    "district_id": "610206",
    "label": "Benuang",
    "value": "Benuang"
  },
  {
    "id": "6102062008",
    "district_id": "610206",
    "label": "Sambora",
    "value": "Sambora"
  },
  {
    "id": "6102071011",
    "district_id": "610207",
    "label": "Sungai Pinyuh",
    "value": "Sungai Pinyuh"
  },
  {
    "id": "6102072001",
    "district_id": "610207",
    "label": "Sungai Rasau",
    "value": "Sungai Rasau"
  },
  {
    "id": "6102072002",
    "district_id": "610207",
    "label": "Sungai Bakau Besar Darat",
    "value": "Sungai Bakau Besar Darat"
  },
  {
    "id": "6102072003",
    "district_id": "610207",
    "label": "Sungai Purun Kecil",
    "value": "Sungai Purun Kecil"
  },
  {
    "id": "6102072004",
    "district_id": "610207",
    "label": "Sungai Bakau Besar Laut",
    "value": "Sungai Bakau Besar Laut"
  },
  {
    "id": "6102072005",
    "district_id": "610207",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  {
    "id": "6102072006",
    "district_id": "610207",
    "label": "Peniraman",
    "value": "Peniraman"
  },
  {
    "id": "6102072007",
    "district_id": "610207",
    "label": "Nusapati",
    "value": "Nusapati"
  },
  {
    "id": "6102072008",
    "district_id": "610207",
    "label": "Galang",
    "value": "Galang"
  },
  {
    "id": "6102082001",
    "district_id": "610208",
    "label": "Wajok Hilir",
    "value": "Wajok Hilir"
  },
  {
    "id": "6102082002",
    "district_id": "610208",
    "label": "Sungai Nipah",
    "value": "Sungai Nipah"
  },
  {
    "id": "6102082006",
    "district_id": "610208",
    "label": "Peniti Luar",
    "value": "Peniti Luar"
  },
  {
    "id": "6102082009",
    "district_id": "610208",
    "label": "Wajok Hulu",
    "value": "Wajok Hulu"
  },
  {
    "id": "6102082010",
    "district_id": "610208",
    "label": "Jungkat",
    "value": "Jungkat"
  },
  {
    "id": "6102122001",
    "district_id": "610212",
    "label": "Sungai Duri I",
    "value": "Sungai Duri I"
  },
  {
    "id": "6102122002",
    "district_id": "610212",
    "label": "Sungai Duri II",
    "value": "Sungai Duri II"
  },
  {
    "id": "6102122003",
    "district_id": "610212",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "6102122004",
    "district_id": "610212",
    "label": "Sungai Bundung Laut",
    "value": "Sungai Bundung Laut"
  },
  {
    "id": "6102122005",
    "district_id": "610212",
    "label": "Sungai Kunyit Laut",
    "value": "Sungai Kunyit Laut"
  },
  {
    "id": "6102122006",
    "district_id": "610212",
    "label": "Sungai Kunyit Dalam",
    "value": "Sungai Kunyit Dalam"
  },
  {
    "id": "6102122007",
    "district_id": "610212",
    "label": "Sungai Kunyit Hulu",
    "value": "Sungai Kunyit Hulu"
  },
  {
    "id": "6102122008",
    "district_id": "610212",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "6102122009",
    "district_id": "610212",
    "label": "Sungai Dungun",
    "value": "Sungai Dungun"
  },
  {
    "id": "6102122010",
    "district_id": "610212",
    "label": "Mendalok",
    "value": "Mendalok"
  },
  {
    "id": "6102122011",
    "district_id": "610212",
    "label": "Semparong Parit Raden",
    "value": "Semparong Parit Raden"
  },
  {
    "id": "6102122012",
    "district_id": "610212",
    "label": "Semudun",
    "value": "Semudun"
  },
  {
    "id": "6102152001",
    "district_id": "610215",
    "label": "Peniti Besar",
    "value": "Peniti Besar"
  },
  {
    "id": "6102152002",
    "district_id": "610215",
    "label": "Sungai Purun Besar",
    "value": "Sungai Purun Besar"
  },
  {
    "id": "6102152003",
    "district_id": "610215",
    "label": "Parit Bugis",
    "value": "Parit Bugis"
  },
  {
    "id": "6102152004",
    "district_id": "610215",
    "label": "Peniti Dalam I",
    "value": "Peniti Dalam I"
  },
  {
    "id": "6102152005",
    "district_id": "610215",
    "label": "Peniti Dalam II",
    "value": "Peniti Dalam II"
  },
  {
    "id": "6102152006",
    "district_id": "610215",
    "label": "Sungai Burung",
    "value": "Sungai Burung"
  },
  {
    "id": "6102161001",
    "district_id": "610216",
    "label": "Anjungan Melancar",
    "value": "Anjungan Melancar"
  },
  {
    "id": "6102162002",
    "district_id": "610216",
    "label": "Anjungan Dalam",
    "value": "Anjungan Dalam"
  },
  {
    "id": "6102162003",
    "district_id": "610216",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "6102162004",
    "district_id": "610216",
    "label": "Pak Bulu",
    "value": "Pak Bulu"
  },
  {
    "id": "6102162005",
    "district_id": "610216",
    "label": "Dema",
    "value": "Dema"
  },
  {
    "id": "6102172001",
    "district_id": "610217",
    "label": "Sekabuk",
    "value": "Sekabuk"
  },
  {
    "id": "6102172002",
    "district_id": "610217",
    "label": "Pentek",
    "value": "Pentek"
  },
  {
    "id": "6102172003",
    "district_id": "610217",
    "label": "Bum-bun",
    "value": "Bum-bun"
  },
  {
    "id": "6102172004",
    "district_id": "610217",
    "label": "Amawang",
    "value": "Amawang"
  },
  {
    "id": "6102172005",
    "district_id": "610217",
    "label": "Ansiap",
    "value": "Ansiap"
  },
  {
    "id": "6102172006",
    "district_id": "610217",
    "label": "Suak Barangan",
    "value": "Suak Barangan"
  },
  {
    "id": "6102181001",
    "district_id": "610218",
    "label": "Pulau Pedalaman",
    "value": "Pulau Pedalaman"
  },
  {
    "id": "6102181002",
    "district_id": "610218",
    "label": "Pasir Wan Salim",
    "value": "Pasir Wan Salim"
  },
  {
    "id": "6102182003",
    "district_id": "610218",
    "label": "Antibar",
    "value": "Antibar"
  },
  {
    "id": "6102182004",
    "district_id": "610218",
    "label": "Sejegi",
    "value": "Sejegi"
  },
  {
    "id": "6102182005",
    "district_id": "610218",
    "label": "Pasir Palembang",
    "value": "Pasir Palembang"
  },
  {
    "id": "6102182006",
    "district_id": "610218",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "6102182007",
    "district_id": "610218",
    "label": "Sungai Bakau Kecil",
    "value": "Sungai Bakau Kecil"
  },
  {
    "id": "6102182008",
    "district_id": "610218",
    "label": "Parit Banjar",
    "value": "Parit Banjar"
  },
  {
    "id": "6103011001",
    "district_id": "610301",
    "label": "Tanjung Kapuas",
    "value": "Tanjung Kapuas"
  },
  {
    "id": "6103011002",
    "district_id": "610301",
    "label": "Tanjung Sekayam",
    "value": "Tanjung Sekayam"
  },
  {
    "id": "6103011003",
    "district_id": "610301",
    "label": "Sungai Sengkuang",
    "value": "Sungai Sengkuang"
  },
  {
    "id": "6103011004",
    "district_id": "610301",
    "label": "Ilir Kota",
    "value": "Ilir Kota"
  },
  {
    "id": "6103011005",
    "district_id": "610301",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6103011006",
    "district_id": "610301",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "6103012007",
    "district_id": "610301",
    "label": "Penyeladi",
    "value": "Penyeladi"
  },
  {
    "id": "6103012008",
    "district_id": "610301",
    "label": "Nanga Biang",
    "value": "Nanga Biang"
  },
  {
    "id": "6103012009",
    "district_id": "610301",
    "label": "Rambin",
    "value": "Rambin"
  },
  {
    "id": "6103012010",
    "district_id": "610301",
    "label": "Entakai",
    "value": "Entakai"
  },
  {
    "id": "6103012011",
    "district_id": "610301",
    "label": "Pana",
    "value": "Pana"
  },
  {
    "id": "6103012012",
    "district_id": "610301",
    "label": "Mengkiang",
    "value": "Mengkiang"
  },
  {
    "id": "6103012013",
    "district_id": "610301",
    "label": "Kambong",
    "value": "Kambong"
  },
  {
    "id": "6103012014",
    "district_id": "610301",
    "label": "Sungai Mawang",
    "value": "Sungai Mawang"
  },
  {
    "id": "6103012015",
    "district_id": "610301",
    "label": "Lape",
    "value": "Lape"
  },
  {
    "id": "6103012016",
    "district_id": "610301",
    "label": "Penyelimau",
    "value": "Penyelimau"
  },
  {
    "id": "6103012017",
    "district_id": "610301",
    "label": "Sungai Alai",
    "value": "Sungai Alai"
  },
  {
    "id": "6103012018",
    "district_id": "610301",
    "label": "Semerangkai",
    "value": "Semerangkai"
  },
  {
    "id": "6103012019",
    "district_id": "610301",
    "label": "Sungai Batu",
    "value": "Sungai Batu"
  },
  {
    "id": "6103012020",
    "district_id": "610301",
    "label": "Sungai Muntik",
    "value": "Sungai Muntik"
  },
  {
    "id": "6103012021",
    "district_id": "610301",
    "label": "Lintang Kapuas",
    "value": "Lintang Kapuas"
  },
  {
    "id": "6103012022",
    "district_id": "610301",
    "label": "Lintang Pelaman",
    "value": "Lintang Pelaman"
  },
  {
    "id": "6103012023",
    "district_id": "610301",
    "label": "Belangin",
    "value": "Belangin"
  },
  {
    "id": "6103012024",
    "district_id": "610301",
    "label": "Penyelimau Jaya",
    "value": "Penyelimau Jaya"
  },
  {
    "id": "6103012025",
    "district_id": "610301",
    "label": "Tapang Dulang",
    "value": "Tapang Dulang"
  },
  {
    "id": "6103012026",
    "district_id": "610301",
    "label": "Botuh Lintang",
    "value": "Botuh Lintang"
  },
  {
    "id": "6103022001",
    "district_id": "610302",
    "label": "Kedukul",
    "value": "Kedukul"
  },
  {
    "id": "6103022002",
    "district_id": "610302",
    "label": "Semuntai",
    "value": "Semuntai"
  },
  {
    "id": "6103022003",
    "district_id": "610302",
    "label": "Engkode",
    "value": "Engkode"
  },
  {
    "id": "6103022004",
    "district_id": "610302",
    "label": "Sungai Mawang",
    "value": "Sungai Mawang"
  },
  {
    "id": "6103022005",
    "district_id": "610302",
    "label": "Inggis",
    "value": "Inggis"
  },
  {
    "id": "6103022006",
    "district_id": "610302",
    "label": "Layak Omang",
    "value": "Layak Omang"
  },
  {
    "id": "6103022007",
    "district_id": "610302",
    "label": "Semanggis Raya",
    "value": "Semanggis Raya"
  },
  {
    "id": "6103022008",
    "district_id": "610302",
    "label": "Tri Mulya",
    "value": "Tri Mulya"
  },
  {
    "id": "6103022009",
    "district_id": "610302",
    "label": "Serambai Jaya",
    "value": "Serambai Jaya"
  },
  {
    "id": "6103032001",
    "district_id": "610303",
    "label": "Noyan",
    "value": "Noyan"
  },
  {
    "id": "6103032002",
    "district_id": "610303",
    "label": "Empoto",
    "value": "Empoto"
  },
  {
    "id": "6103032003",
    "district_id": "610303",
    "label": "Semongan",
    "value": "Semongan"
  },
  {
    "id": "6103032004",
    "district_id": "610303",
    "label": "Sungai Dangin",
    "value": "Sungai Dangin"
  },
  {
    "id": "6103032005",
    "district_id": "610303",
    "label": "Idas",
    "value": "Idas"
  },
  {
    "id": "6103042001",
    "district_id": "610304",
    "label": "Balai Sebut",
    "value": "Balai Sebut"
  },
  {
    "id": "6103042002",
    "district_id": "610304",
    "label": "Jangkang Benua",
    "value": "Jangkang Benua"
  },
  {
    "id": "6103042003",
    "district_id": "610304",
    "label": "Tanggung",
    "value": "Tanggung"
  },
  {
    "id": "6103042004",
    "district_id": "610304",
    "label": "Empiyang",
    "value": "Empiyang"
  },
  {
    "id": "6103042005",
    "district_id": "610304",
    "label": "Pisang",
    "value": "Pisang"
  },
  {
    "id": "6103042006",
    "district_id": "610304",
    "label": "Ketori",
    "value": "Ketori"
  },
  {
    "id": "6103042007",
    "district_id": "610304",
    "label": "Semombat",
    "value": "Semombat"
  },
  {
    "id": "6103042008",
    "district_id": "610304",
    "label": "Terati",
    "value": "Terati"
  },
  {
    "id": "6103042009",
    "district_id": "610304",
    "label": "Sape",
    "value": "Sape"
  },
  {
    "id": "6103042010",
    "district_id": "610304",
    "label": "Selampung",
    "value": "Selampung"
  },
  {
    "id": "6103042011",
    "district_id": "610304",
    "label": "Semirau",
    "value": "Semirau"
  },
  {
    "id": "6103052001",
    "district_id": "610305",
    "label": "Bonti",
    "value": "Bonti"
  },
  {
    "id": "6103052002",
    "district_id": "610305",
    "label": "Sami",
    "value": "Sami"
  },
  {
    "id": "6103052003",
    "district_id": "610305",
    "label": "Bahta",
    "value": "Bahta"
  },
  {
    "id": "6103052004",
    "district_id": "610305",
    "label": "Upe",
    "value": "Upe"
  },
  {
    "id": "6103052005",
    "district_id": "610305",
    "label": "Tunggul Boyok",
    "value": "Tunggul Boyok"
  },
  {
    "id": "6103052006",
    "district_id": "610305",
    "label": "Empodis",
    "value": "Empodis"
  },
  {
    "id": "6103052007",
    "district_id": "610305",
    "label": "Kampuh",
    "value": "Kampuh"
  },
  {
    "id": "6103052008",
    "district_id": "610305",
    "label": "Bantai",
    "value": "Bantai"
  },
  {
    "id": "6103052009",
    "district_id": "610305",
    "label": "Majel",
    "value": "Majel"
  },
  {
    "id": "6103062001",
    "district_id": "610306",
    "label": "Kasro Mego",
    "value": "Kasro Mego"
  },
  {
    "id": "6103062002",
    "district_id": "610306",
    "label": "Bereng Berkawat",
    "value": "Bereng Berkawat"
  },
  {
    "id": "6103062003",
    "district_id": "610306",
    "label": "Sungai Ilai",
    "value": "Sungai Ilai"
  },
  {
    "id": "6103062004",
    "district_id": "610306",
    "label": "Thang Raya",
    "value": "Thang Raya"
  },
  {
    "id": "6103062005",
    "district_id": "610306",
    "label": "Mawang Muda",
    "value": "Mawang Muda"
  },
  {
    "id": "6103072001",
    "district_id": "610307",
    "label": "Balai Karangan",
    "value": "Balai Karangan"
  },
  {
    "id": "6103072002",
    "district_id": "610307",
    "label": "Pengadang",
    "value": "Pengadang"
  },
  {
    "id": "6103072003",
    "district_id": "610307",
    "label": "Sotok",
    "value": "Sotok"
  },
  {
    "id": "6103072004",
    "district_id": "610307",
    "label": "Kenaman",
    "value": "Kenaman"
  },
  {
    "id": "6103072005",
    "district_id": "610307",
    "label": "Raut Muara",
    "value": "Raut Muara"
  },
  {
    "id": "6103072006",
    "district_id": "610307",
    "label": "Engkahan",
    "value": "Engkahan"
  },
  {
    "id": "6103072007",
    "district_id": "610307",
    "label": "Bungkang",
    "value": "Bungkang"
  },
  {
    "id": "6103072008",
    "district_id": "610307",
    "label": "Lubuk Sabuk",
    "value": "Lubuk Sabuk"
  },
  {
    "id": "6103072009",
    "district_id": "610307",
    "label": "Sangai Tekam",
    "value": "Sangai Tekam"
  },
  {
    "id": "6103072010",
    "district_id": "610307",
    "label": "Melenggang",
    "value": "Melenggang"
  },
  {
    "id": "6103082001",
    "district_id": "610308",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "6103082002",
    "district_id": "610308",
    "label": "Semayang",
    "value": "Semayang"
  },
  {
    "id": "6103082003",
    "district_id": "610308",
    "label": "Sebongkuh",
    "value": "Sebongkuh"
  },
  {
    "id": "6103082004",
    "district_id": "610308",
    "label": "Kuala Dua",
    "value": "Kuala Dua"
  },
  {
    "id": "6103082005",
    "district_id": "610308",
    "label": "Tanjung Merpati",
    "value": "Tanjung Merpati"
  },
  {
    "id": "6103082006",
    "district_id": "610308",
    "label": "Sejuah",
    "value": "Sejuah"
  },
  {
    "id": "6103082007",
    "district_id": "610308",
    "label": "Mobui",
    "value": "Mobui"
  },
  {
    "id": "6103082008",
    "district_id": "610308",
    "label": "Tanap",
    "value": "Tanap"
  },
  {
    "id": "6103082009",
    "district_id": "610308",
    "label": "Sebuduh",
    "value": "Sebuduh"
  },
  {
    "id": "6103082010",
    "district_id": "610308",
    "label": "Kelompu",
    "value": "Kelompu"
  },
  {
    "id": "6103082011",
    "district_id": "610308",
    "label": "Tunggal Bhakti",
    "value": "Tunggal Bhakti"
  },
  {
    "id": "6103092001",
    "district_id": "610309",
    "label": "Pusat Damai",
    "value": "Pusat Damai"
  },
  {
    "id": "6103092002",
    "district_id": "610309",
    "label": "Maju Karya",
    "value": "Maju Karya"
  },
  {
    "id": "6103092003",
    "district_id": "610309",
    "label": "Sebara",
    "value": "Sebara"
  },
  {
    "id": "6103092004",
    "district_id": "610309",
    "label": "Pandu Raya",
    "value": "Pandu Raya"
  },
  {
    "id": "6103092005",
    "district_id": "610309",
    "label": "Embala",
    "value": "Embala"
  },
  {
    "id": "6103092006",
    "district_id": "610309",
    "label": "Hibun",
    "value": "Hibun"
  },
  {
    "id": "6103092007",
    "district_id": "610309",
    "label": "Maringin Jaya",
    "value": "Maringin Jaya"
  },
  {
    "id": "6103092008",
    "district_id": "610309",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "6103092009",
    "district_id": "610309",
    "label": "Palem Jaya",
    "value": "Palem Jaya"
  },
  {
    "id": "6103092010",
    "district_id": "610309",
    "label": "Rahayu",
    "value": "Rahayu"
  },
  {
    "id": "6103092011",
    "district_id": "610309",
    "label": "Dosan",
    "value": "Dosan"
  },
  {
    "id": "6103092012",
    "district_id": "610309",
    "label": "Suka Gerundi",
    "value": "Suka Gerundi"
  },
  {
    "id": "6103092013",
    "district_id": "610309",
    "label": "Gunam",
    "value": "Gunam"
  },
  {
    "id": "6103092014",
    "district_id": "610309",
    "label": "Marita",
    "value": "Marita"
  },
  {
    "id": "6103102001",
    "district_id": "610310",
    "label": "Sosok",
    "value": "Sosok"
  },
  {
    "id": "6103102002",
    "district_id": "610310",
    "label": "Peruan Dalam",
    "value": "Peruan Dalam"
  },
  {
    "id": "6103102003",
    "district_id": "610310",
    "label": "Menyabo",
    "value": "Menyabo"
  },
  {
    "id": "6103102004",
    "district_id": "610310",
    "label": "Mandong",
    "value": "Mandong"
  },
  {
    "id": "6103102005",
    "district_id": "610310",
    "label": "Janjang",
    "value": "Janjang"
  },
  {
    "id": "6103102006",
    "district_id": "610310",
    "label": "Riyai",
    "value": "Riyai"
  },
  {
    "id": "6103102007",
    "district_id": "610310",
    "label": "Berakak",
    "value": "Berakak"
  },
  {
    "id": "6103102008",
    "district_id": "610310",
    "label": "Engkasan",
    "value": "Engkasan"
  },
  {
    "id": "6103102009",
    "district_id": "610310",
    "label": "Pandan Sembuat",
    "value": "Pandan Sembuat"
  },
  {
    "id": "6103102010",
    "district_id": "610310",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "6103102011",
    "district_id": "610310",
    "label": "Kedakas",
    "value": "Kedakas"
  },
  {
    "id": "6103112001",
    "district_id": "610311",
    "label": "Pulau Tayan Utara",
    "value": "Pulau Tayan Utara"
  },
  {
    "id": "6103112002",
    "district_id": "610311",
    "label": "Kawat",
    "value": "Kawat"
  },
  {
    "id": "6103112003",
    "district_id": "610311",
    "label": "Pedalaman",
    "value": "Pedalaman"
  },
  {
    "id": "6103112004",
    "district_id": "610311",
    "label": "Balai Ingin",
    "value": "Balai Ingin"
  },
  {
    "id": "6103112005",
    "district_id": "610311",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "6103112006",
    "district_id": "610311",
    "label": "Cempedak",
    "value": "Cempedak"
  },
  {
    "id": "6103112007",
    "district_id": "610311",
    "label": "Melugai",
    "value": "Melugai"
  },
  {
    "id": "6103112008",
    "district_id": "610311",
    "label": "Emberas",
    "value": "Emberas"
  },
  {
    "id": "6103112009",
    "district_id": "610311",
    "label": "Beginjan",
    "value": "Beginjan"
  },
  {
    "id": "6103112010",
    "district_id": "610311",
    "label": "Sejotang",
    "value": "Sejotang"
  },
  {
    "id": "6103112011",
    "district_id": "610311",
    "label": "Sebemban",
    "value": "Sebemban"
  },
  {
    "id": "6103112012",
    "district_id": "610311",
    "label": "Subah",
    "value": "Subah"
  },
  {
    "id": "6103112013",
    "district_id": "610311",
    "label": "Sungai Jaman",
    "value": "Sungai Jaman"
  },
  {
    "id": "6103112014",
    "district_id": "610311",
    "label": "Tanjung Bunut",
    "value": "Tanjung Bunut"
  },
  {
    "id": "6103112015",
    "district_id": "610311",
    "label": "Tebang Benua",
    "value": "Tebang Benua"
  },
  {
    "id": "6103122001",
    "district_id": "610312",
    "label": "Hilir",
    "value": "Hilir"
  },
  {
    "id": "6103122002",
    "district_id": "610312",
    "label": "Padi Kaye",
    "value": "Padi Kaye"
  },
  {
    "id": "6103122003",
    "district_id": "610312",
    "label": "Temiang Mali",
    "value": "Temiang Mali"
  },
  {
    "id": "6103122004",
    "district_id": "610312",
    "label": "Cowet",
    "value": "Cowet"
  },
  {
    "id": "6103122005",
    "district_id": "610312",
    "label": "Kebadu",
    "value": "Kebadu"
  },
  {
    "id": "6103122006",
    "district_id": "610312",
    "label": "Mak Kawing",
    "value": "Mak Kawing"
  },
  {
    "id": "6103122007",
    "district_id": "610312",
    "label": "Tae",
    "value": "Tae"
  },
  {
    "id": "6103122008",
    "district_id": "610312",
    "label": "Semoncol",
    "value": "Semoncol"
  },
  {
    "id": "6103122009",
    "district_id": "610312",
    "label": "Empirang Ujung",
    "value": "Empirang Ujung"
  },
  {
    "id": "6103122010",
    "district_id": "610312",
    "label": "Bulu Bala",
    "value": "Bulu Bala"
  },
  {
    "id": "6103122011",
    "district_id": "610312",
    "label": "Temiang Taba",
    "value": "Temiang Taba"
  },
  {
    "id": "6103122012",
    "district_id": "610312",
    "label": "Senyabang",
    "value": "Senyabang"
  },
  {
    "id": "6103132001",
    "district_id": "610313",
    "label": "Teraju",
    "value": "Teraju"
  },
  {
    "id": "6103132002",
    "district_id": "610313",
    "label": "Belungai Dalam",
    "value": "Belungai Dalam"
  },
  {
    "id": "6103132003",
    "district_id": "610313",
    "label": "Sansat",
    "value": "Sansat"
  },
  {
    "id": "6103132004",
    "district_id": "610313",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6103132005",
    "district_id": "610313",
    "label": "Bagan Asam",
    "value": "Bagan Asam"
  },
  {
    "id": "6103132006",
    "district_id": "610313",
    "label": "Lumut",
    "value": "Lumut"
  },
  {
    "id": "6103132007",
    "district_id": "610313",
    "label": "Balai Belungai",
    "value": "Balai Belungai"
  },
  {
    "id": "6103202001",
    "district_id": "610320",
    "label": "Meliau Hilir",
    "value": "Meliau Hilir"
  },
  {
    "id": "6103202002",
    "district_id": "610320",
    "label": "Meliau Hulu",
    "value": "Meliau Hulu"
  },
  {
    "id": "6103202003",
    "district_id": "610320",
    "label": "Kuala Buayan",
    "value": "Kuala Buayan"
  },
  {
    "id": "6103202004",
    "district_id": "610320",
    "label": "Meranggau",
    "value": "Meranggau"
  },
  {
    "id": "6103202005",
    "district_id": "610320",
    "label": "Melobok",
    "value": "Melobok"
  },
  {
    "id": "6103202006",
    "district_id": "610320",
    "label": "Sungai Mayam",
    "value": "Sungai Mayam"
  },
  {
    "id": "6103202007",
    "district_id": "610320",
    "label": "Pampang Dua",
    "value": "Pampang Dua"
  },
  {
    "id": "6103202008",
    "district_id": "610320",
    "label": "Kuala Rosan",
    "value": "Kuala Rosan"
  },
  {
    "id": "6103202009",
    "district_id": "610320",
    "label": "Sungai Kembayau",
    "value": "Sungai Kembayau"
  },
  {
    "id": "6103202010",
    "district_id": "610320",
    "label": "Kunyil",
    "value": "Kunyil"
  },
  {
    "id": "6103202011",
    "district_id": "610320",
    "label": "Cupang",
    "value": "Cupang"
  },
  {
    "id": "6103202012",
    "district_id": "610320",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "6103202013",
    "district_id": "610320",
    "label": "Baru Lombak",
    "value": "Baru Lombak"
  },
  {
    "id": "6103202014",
    "district_id": "610320",
    "label": "Enggadai",
    "value": "Enggadai"
  },
  {
    "id": "6103202015",
    "district_id": "610320",
    "label": "Balai Tinggi",
    "value": "Balai Tinggi"
  },
  {
    "id": "6103202017",
    "district_id": "610320",
    "label": "Bhakti Jaya",
    "value": "Bhakti Jaya"
  },
  {
    "id": "6103202018",
    "district_id": "610320",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "6103202019",
    "district_id": "610320",
    "label": "Melawi Makmur",
    "value": "Melawi Makmur"
  },
  {
    "id": "6103202020",
    "district_id": "610320",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "6103212001",
    "district_id": "610321",
    "label": "Nekan",
    "value": "Nekan"
  },
  {
    "id": "6103212002",
    "district_id": "610321",
    "label": "Semanget",
    "value": "Semanget"
  },
  {
    "id": "6103212003",
    "district_id": "610321",
    "label": "Entikong",
    "value": "Entikong"
  },
  {
    "id": "6103212004",
    "district_id": "610321",
    "label": "Pala Pasang",
    "value": "Pala Pasang"
  },
  {
    "id": "6103212005",
    "district_id": "610321",
    "label": "Suruh Tembawang",
    "value": "Suruh Tembawang"
  },
  {
    "id": "6104012011",
    "district_id": "610401",
    "label": "Sungai Putri",
    "value": "Sungai Putri"
  },
  {
    "id": "6104012012",
    "district_id": "610401",
    "label": "Tanjung Baik Budi",
    "value": "Tanjung Baik Budi"
  },
  {
    "id": "6104012013",
    "district_id": "610401",
    "label": "Kuala Tolak",
    "value": "Kuala Tolak"
  },
  {
    "id": "6104012014",
    "district_id": "610401",
    "label": "Kuala Satong",
    "value": "Kuala Satong"
  },
  {
    "id": "6104012015",
    "district_id": "610401",
    "label": "Lamon Satong",
    "value": "Lamon Satong"
  },
  {
    "id": "6104022001",
    "district_id": "610402",
    "label": "Suka Karya",
    "value": "Suka Karya"
  },
  {
    "id": "6104022002",
    "district_id": "610402",
    "label": "Randai",
    "value": "Randai"
  },
  {
    "id": "6104022003",
    "district_id": "610402",
    "label": "Belaban",
    "value": "Belaban"
  },
  {
    "id": "6104022004",
    "district_id": "610402",
    "label": "Runjai Jaya",
    "value": "Runjai Jaya"
  },
  {
    "id": "6104022008",
    "district_id": "610402",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "6104022020",
    "district_id": "610402",
    "label": "Riam Batu Gading",
    "value": "Riam Batu Gading"
  },
  {
    "id": "6104022021",
    "district_id": "610402",
    "label": "Batu Payung Dua",
    "value": "Batu Payung Dua"
  },
  {
    "id": "6104022022",
    "district_id": "610402",
    "label": "Bantan Sari",
    "value": "Bantan Sari"
  },
  {
    "id": "6104022023",
    "district_id": "610402",
    "label": "Pelanjau Jaya",
    "value": "Pelanjau Jaya"
  },
  {
    "id": "6104022024",
    "district_id": "610402",
    "label": "Rangkung",
    "value": "Rangkung"
  },
  {
    "id": "6104032001",
    "district_id": "610403",
    "label": "Manis Mata",
    "value": "Manis Mata"
  },
  {
    "id": "6104032002",
    "district_id": "610403",
    "label": "Air Dekakah",
    "value": "Air Dekakah"
  },
  {
    "id": "6104032003",
    "district_id": "610403",
    "label": "Suka Ramai",
    "value": "Suka Ramai"
  },
  {
    "id": "6104032004",
    "district_id": "610403",
    "label": "Suak Burung",
    "value": "Suak Burung"
  },
  {
    "id": "6104032005",
    "district_id": "610403",
    "label": "Silat",
    "value": "Silat"
  },
  {
    "id": "6104032006",
    "district_id": "610403",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "6104032007",
    "district_id": "610403",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "6104032008",
    "district_id": "610403",
    "label": "Kelampai",
    "value": "Kelampai"
  },
  {
    "id": "6104032009",
    "district_id": "610403",
    "label": "Bukit Gajah",
    "value": "Bukit Gajah"
  },
  {
    "id": "6104032010",
    "district_id": "610403",
    "label": "Lembah Mukti",
    "value": "Lembah Mukti"
  },
  {
    "id": "6104032011",
    "district_id": "610403",
    "label": "Asam Besar",
    "value": "Asam Besar"
  },
  {
    "id": "6104032012",
    "district_id": "610403",
    "label": "Batu Sedau",
    "value": "Batu Sedau"
  },
  {
    "id": "6104032013",
    "district_id": "610403",
    "label": "Jambi",
    "value": "Jambi"
  },
  {
    "id": "6104032014",
    "district_id": "610403",
    "label": "Seguling",
    "value": "Seguling"
  },
  {
    "id": "6104032015",
    "district_id": "610403",
    "label": "Kalimantan",
    "value": "Kalimantan"
  },
  {
    "id": "6104032016",
    "district_id": "610403",
    "label": "Pelempangan",
    "value": "Pelempangan"
  },
  {
    "id": "6104032017",
    "district_id": "610403",
    "label": "Sengkuang Merabong",
    "value": "Sengkuang Merabong"
  },
  {
    "id": "6104032018",
    "district_id": "610403",
    "label": "Pakit Selaba",
    "value": "Pakit Selaba"
  },
  {
    "id": "6104032019",
    "district_id": "610403",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "6104032020",
    "district_id": "610403",
    "label": "Ratu Elok",
    "value": "Ratu Elok"
  },
  {
    "id": "6104032021",
    "district_id": "610403",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6104032022",
    "district_id": "610403",
    "label": "Tribun Jaya",
    "value": "Tribun Jaya"
  },
  {
    "id": "6104042001",
    "district_id": "610404",
    "label": "Kendawangan Kiri",
    "value": "Kendawangan Kiri"
  },
  {
    "id": "6104042002",
    "district_id": "610404",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "6104042003",
    "district_id": "610404",
    "label": "Kendawangan Kanan",
    "value": "Kendawangan Kanan"
  },
  {
    "id": "6104042004",
    "district_id": "610404",
    "label": "Pangkalan Batu",
    "value": "Pangkalan Batu"
  },
  {
    "id": "6104042005",
    "district_id": "610404",
    "label": "Bangkal Serai",
    "value": "Bangkal Serai"
  },
  {
    "id": "6104042006",
    "district_id": "610404",
    "label": "Air Hitam Besar",
    "value": "Air Hitam Besar"
  },
  {
    "id": "6104042007",
    "district_id": "610404",
    "label": "Suka Harapan",
    "value": "Suka Harapan"
  },
  {
    "id": "6104042008",
    "district_id": "610404",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6104042009",
    "district_id": "610404",
    "label": "Selimantan Jaya",
    "value": "Selimantan Jaya"
  },
  {
    "id": "6104042010",
    "district_id": "610404",
    "label": "Danau Buntar",
    "value": "Danau Buntar"
  },
  {
    "id": "6104042011",
    "district_id": "610404",
    "label": "Mekar Utama",
    "value": "Mekar Utama"
  },
  {
    "id": "6104042012",
    "district_id": "610404",
    "label": "Pembedilan",
    "value": "Pembedilan"
  },
  {
    "id": "6104042013",
    "district_id": "610404",
    "label": "Airhitam Hulu",
    "value": "Airhitam Hulu"
  },
  {
    "id": "6104042014",
    "district_id": "610404",
    "label": "Natai Kuini",
    "value": "Natai Kuini"
  },
  {
    "id": "6104042015",
    "district_id": "610404",
    "label": "Keramat Jaya",
    "value": "Keramat Jaya"
  },
  {
    "id": "6104042016",
    "district_id": "610404",
    "label": "Seriam",
    "value": "Seriam"
  },
  {
    "id": "6104042017",
    "district_id": "610404",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "6104042018",
    "district_id": "610404",
    "label": "Sungai Jelayan",
    "value": "Sungai Jelayan"
  },
  {
    "id": "6104042019",
    "district_id": "610404",
    "label": "Air Tarap",
    "value": "Air Tarap"
  },
  {
    "id": "6104052001",
    "district_id": "610405",
    "label": "Sandai",
    "value": "Sandai"
  },
  {
    "id": "6104052002",
    "district_id": "610405",
    "label": "Randau Jungkal",
    "value": "Randau Jungkal"
  },
  {
    "id": "6104052006",
    "district_id": "610405",
    "label": "Penjawaan",
    "value": "Penjawaan"
  },
  {
    "id": "6104052007",
    "district_id": "610405",
    "label": "Muara Jekak",
    "value": "Muara Jekak"
  },
  {
    "id": "6104052008",
    "district_id": "610405",
    "label": "Petai Patah",
    "value": "Petai Patah"
  },
  {
    "id": "6104052010",
    "district_id": "610405",
    "label": "Randau",
    "value": "Randau"
  },
  {
    "id": "6104052011",
    "district_id": "610405",
    "label": "Sandai Kiri",
    "value": "Sandai Kiri"
  },
  {
    "id": "6104052012",
    "district_id": "610405",
    "label": "Demit",
    "value": "Demit"
  },
  {
    "id": "6104052013",
    "district_id": "610405",
    "label": "Pendamaran Indah",
    "value": "Pendamaran Indah"
  },
  {
    "id": "6104052014",
    "district_id": "610405",
    "label": "Merimbang Jaya",
    "value": "Merimbang Jaya"
  },
  {
    "id": "6104052015",
    "district_id": "610405",
    "label": "Alam Pakuan",
    "value": "Alam Pakuan"
  },
  {
    "id": "6104052016",
    "district_id": "610405",
    "label": "Jago Bersatu",
    "value": "Jago Bersatu"
  },
  {
    "id": "6104052017",
    "district_id": "610405",
    "label": "Istana",
    "value": "Istana"
  },
  {
    "id": "6104072001",
    "district_id": "610407",
    "label": "Riam Bunut",
    "value": "Riam Bunut"
  },
  {
    "id": "6104072002",
    "district_id": "610407",
    "label": "Sempurna",
    "value": "Sempurna"
  },
  {
    "id": "6104072003",
    "district_id": "610407",
    "label": "Bengaras",
    "value": "Bengaras"
  },
  {
    "id": "6104072004",
    "district_id": "610407",
    "label": "Sepotong",
    "value": "Sepotong"
  },
  {
    "id": "6104072005",
    "district_id": "610407",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "6104072006",
    "district_id": "610407",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "6104072007",
    "district_id": "610407",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6104072008",
    "district_id": "610407",
    "label": "Randau Limat",
    "value": "Randau Limat"
  },
  {
    "id": "6104072009",
    "district_id": "610407",
    "label": "Bayun Sari",
    "value": "Bayun Sari"
  },
  {
    "id": "6104072010",
    "district_id": "610407",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "6104072011",
    "district_id": "610407",
    "label": "Tanjung Maju",
    "value": "Tanjung Maju"
  },
  {
    "id": "6104072012",
    "district_id": "610407",
    "label": "Sungai Daka",
    "value": "Sungai Daka"
  },
  {
    "id": "6104072013",
    "district_id": "610407",
    "label": "Mekar Harapan",
    "value": "Mekar Harapan"
  },
  {
    "id": "6104072014",
    "district_id": "610407",
    "label": "Sinar Kuri",
    "value": "Sinar Kuri"
  },
  {
    "id": "6104072015",
    "district_id": "610407",
    "label": "Selangkut Raya",
    "value": "Selangkut Raya"
  },
  {
    "id": "6104072016",
    "district_id": "610407",
    "label": "Lanjut Mekar Sari",
    "value": "Lanjut Mekar Sari"
  },
  {
    "id": "6104072017",
    "district_id": "610407",
    "label": "Kepari",
    "value": "Kepari"
  },
  {
    "id": "6104072018",
    "district_id": "610407",
    "label": "Teluk Mutiara",
    "value": "Teluk Mutiara"
  },
  {
    "id": "6104072019",
    "district_id": "610407",
    "label": "Merabu Jaya",
    "value": "Merabu Jaya"
  },
  {
    "id": "6104082001",
    "district_id": "610408",
    "label": "Balai Pinang",
    "value": "Balai Pinang"
  },
  {
    "id": "6104082002",
    "district_id": "610408",
    "label": "Semandang Kiri",
    "value": "Semandang Kiri"
  },
  {
    "id": "6104082003",
    "district_id": "610408",
    "label": "Kualan Hulu",
    "value": "Kualan Hulu"
  },
  {
    "id": "6104082005",
    "district_id": "610408",
    "label": "Semandang Hulu",
    "value": "Semandang Hulu"
  },
  {
    "id": "6104082006",
    "district_id": "610408",
    "label": "Merawa",
    "value": "Merawa"
  },
  {
    "id": "6104082009",
    "district_id": "610408",
    "label": "Kualan Tengah",
    "value": "Kualan Tengah"
  },
  {
    "id": "6104082010",
    "district_id": "610408",
    "label": "Kualan Hilir",
    "value": "Kualan Hilir"
  },
  {
    "id": "6104082011",
    "district_id": "610408",
    "label": "Sekucing Labai",
    "value": "Sekucing Labai"
  },
  {
    "id": "6104082012",
    "district_id": "610408",
    "label": "Paoh Concong",
    "value": "Paoh Concong"
  },
  {
    "id": "6104082013",
    "district_id": "610408",
    "label": "Legong",
    "value": "Legong"
  },
  {
    "id": "6104082014",
    "district_id": "610408",
    "label": "Kenanga",
    "value": "Kenanga"
  },
  {
    "id": "6104082015",
    "district_id": "610408",
    "label": "Balai Pinang Hulu",
    "value": "Balai Pinang Hulu"
  },
  {
    "id": "6104082016",
    "district_id": "610408",
    "label": "Sekucing Kualan",
    "value": "Sekucing Kualan"
  },
  {
    "id": "6104082017",
    "district_id": "610408",
    "label": "Labai Hilir",
    "value": "Labai Hilir"
  },
  {
    "id": "6104082018",
    "district_id": "610408",
    "label": "Botuh Bosi",
    "value": "Botuh Bosi"
  },
  {
    "id": "6104112001",
    "district_id": "610411",
    "label": "Nanga Tayap",
    "value": "Nanga Tayap"
  },
  {
    "id": "6104112002",
    "district_id": "610411",
    "label": "Pangkalan Telok",
    "value": "Pangkalan Telok"
  },
  {
    "id": "6104112003",
    "district_id": "610411",
    "label": "Pangkalan Suka",
    "value": "Pangkalan Suka"
  },
  {
    "id": "6104112004",
    "district_id": "610411",
    "label": "Betenung",
    "value": "Betenung"
  },
  {
    "id": "6104112005",
    "district_id": "610411",
    "label": "Batu Mas",
    "value": "Batu Mas"
  },
  {
    "id": "6104112006",
    "district_id": "610411",
    "label": "Sungai Kelik",
    "value": "Sungai Kelik"
  },
  {
    "id": "6104112007",
    "district_id": "610411",
    "label": "Lembah Hijau Satu",
    "value": "Lembah Hijau Satu"
  },
  {
    "id": "6104112008",
    "district_id": "610411",
    "label": "Lembah Hijau Dua",
    "value": "Lembah Hijau Dua"
  },
  {
    "id": "6104112009",
    "district_id": "610411",
    "label": "Siantau Raya",
    "value": "Siantau Raya"
  },
  {
    "id": "6104112010",
    "district_id": "610411",
    "label": "Sebadak Raya",
    "value": "Sebadak Raya"
  },
  {
    "id": "6104112011",
    "district_id": "610411",
    "label": "Simpang Tiga Sembelangaan",
    "value": "Simpang Tiga Sembelangaan"
  },
  {
    "id": "6104112012",
    "district_id": "610411",
    "label": "Mensubang",
    "value": "Mensubang"
  },
  {
    "id": "6104112013",
    "district_id": "610411",
    "label": "Tajok Kayong",
    "value": "Tajok Kayong"
  },
  {
    "id": "6104112014",
    "district_id": "610411",
    "label": "Kayong Utara",
    "value": "Kayong Utara"
  },
  {
    "id": "6104112015",
    "district_id": "610411",
    "label": "Kayong Hulu",
    "value": "Kayong Hulu"
  },
  {
    "id": "6104112016",
    "district_id": "610411",
    "label": "Sepakat Jaya",
    "value": "Sepakat Jaya"
  },
  {
    "id": "6104112017",
    "district_id": "610411",
    "label": "Cegolak",
    "value": "Cegolak"
  },
  {
    "id": "6104112018",
    "district_id": "610411",
    "label": "Pateh Benteng",
    "value": "Pateh Benteng"
  },
  {
    "id": "6104112019",
    "district_id": "610411",
    "label": "Tanjung Medan",
    "value": "Tanjung Medan"
  },
  {
    "id": "6104112020",
    "district_id": "610411",
    "label": "Kayung Tuhe",
    "value": "Kayung Tuhe"
  },
  {
    "id": "6104122006",
    "district_id": "610412",
    "label": "Sungai Jawi",
    "value": "Sungai Jawi"
  },
  {
    "id": "6104122007",
    "district_id": "610412",
    "label": "Pesaguan Kiri",
    "value": "Pesaguan Kiri"
  },
  {
    "id": "6104122008",
    "district_id": "610412",
    "label": "Sungai Pelang",
    "value": "Sungai Pelang"
  },
  {
    "id": "6104122012",
    "district_id": "610412",
    "label": "Sungai Bakau",
    "value": "Sungai Bakau"
  },
  {
    "id": "6104122013",
    "district_id": "610412",
    "label": "Pematang Gadung",
    "value": "Pematang Gadung"
  },
  {
    "id": "6104122014",
    "district_id": "610412",
    "label": "Pesaguan Kanan",
    "value": "Pesaguan Kanan"
  },
  {
    "id": "6104122015",
    "district_id": "610412",
    "label": "Sungai Besar",
    "value": "Sungai Besar"
  },
  {
    "id": "6104122016",
    "district_id": "610412",
    "label": "Sungai Nanjung",
    "value": "Sungai Nanjung"
  },
  {
    "id": "6104122017",
    "district_id": "610412",
    "label": "Kemuning Biutak",
    "value": "Kemuning Biutak"
  },
  {
    "id": "6104122018",
    "district_id": "610412",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6104122019",
    "district_id": "610412",
    "label": "Pagar Mentimun",
    "value": "Pagar Mentimun"
  },
  {
    "id": "6104132001",
    "district_id": "610413",
    "label": "Tumbang Titi",
    "value": "Tumbang Titi"
  },
  {
    "id": "6104132002",
    "district_id": "610413",
    "label": "Natai Panjang",
    "value": "Natai Panjang"
  },
  {
    "id": "6104132003",
    "district_id": "610413",
    "label": "Serengkah",
    "value": "Serengkah"
  },
  {
    "id": "6104132004",
    "district_id": "610413",
    "label": "Mahawa",
    "value": "Mahawa"
  },
  {
    "id": "6104132005",
    "district_id": "610413",
    "label": "Nanga Kelampai",
    "value": "Nanga Kelampai"
  },
  {
    "id": "6104132006",
    "district_id": "610413",
    "label": "Batu Tajam",
    "value": "Batu Tajam"
  },
  {
    "id": "6104132009",
    "district_id": "610413",
    "label": "Kalimas Baru",
    "value": "Kalimas Baru"
  },
  {
    "id": "6104132013",
    "district_id": "610413",
    "label": "Pemuatan Jaya",
    "value": "Pemuatan Jaya"
  },
  {
    "id": "6104132016",
    "district_id": "610413",
    "label": "Sengkaharak",
    "value": "Sengkaharak"
  },
  {
    "id": "6104132020",
    "district_id": "610413",
    "label": "Belaban Tujuh",
    "value": "Belaban Tujuh"
  },
  {
    "id": "6104132023",
    "district_id": "610413",
    "label": "Segar Wangi",
    "value": "Segar Wangi"
  },
  {
    "id": "6104132024",
    "district_id": "610413",
    "label": "Petebang Jaya",
    "value": "Petebang Jaya"
  },
  {
    "id": "6104132025",
    "district_id": "610413",
    "label": "Aur Gading",
    "value": "Aur Gading"
  },
  {
    "id": "6104132026",
    "district_id": "610413",
    "label": "Beringin Rayo",
    "value": "Beringin Rayo"
  },
  {
    "id": "6104132027",
    "district_id": "610413",
    "label": "Titi Baru",
    "value": "Titi Baru"
  },
  {
    "id": "6104132028",
    "district_id": "610413",
    "label": "Tanjung Beulang",
    "value": "Tanjung Beulang"
  },
  {
    "id": "6104132029",
    "district_id": "610413",
    "label": "Batu Beransak",
    "value": "Batu Beransak"
  },
  {
    "id": "6104132030",
    "district_id": "610413",
    "label": "Serengkah Kanan",
    "value": "Serengkah Kanan"
  },
  {
    "id": "6104132031",
    "district_id": "610413",
    "label": "Sepauhan Raya",
    "value": "Sepauhan Raya"
  },
  {
    "id": "6104132032",
    "district_id": "610413",
    "label": "Jungkal",
    "value": "Jungkal"
  },
  {
    "id": "6104132033",
    "district_id": "610413",
    "label": "Jelayan",
    "value": "Jelayan"
  },
  {
    "id": "6104132034",
    "district_id": "610413",
    "label": "Pengatapan Raya",
    "value": "Pengatapan Raya"
  },
  {
    "id": "6104132035",
    "district_id": "610413",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6104132036",
    "district_id": "610413",
    "label": "Tanjung Maloi",
    "value": "Tanjung Maloi"
  },
  {
    "id": "6104132037",
    "district_id": "610413",
    "label": "Sepuring Indah",
    "value": "Sepuring Indah"
  },
  {
    "id": "6104142001",
    "district_id": "610414",
    "label": "Periangan",
    "value": "Periangan"
  },
  {
    "id": "6104142002",
    "district_id": "610414",
    "label": "Tanggerang",
    "value": "Tanggerang"
  },
  {
    "id": "6104142003",
    "district_id": "610414",
    "label": "Kesuma Jaya",
    "value": "Kesuma Jaya"
  },
  {
    "id": "6104142004",
    "district_id": "610414",
    "label": "Penyarang",
    "value": "Penyarang"
  },
  {
    "id": "6104142005",
    "district_id": "610414",
    "label": "Biku Sarana",
    "value": "Biku Sarana"
  },
  {
    "id": "6104142006",
    "district_id": "610414",
    "label": "Semantun",
    "value": "Semantun"
  },
  {
    "id": "6104142007",
    "district_id": "610414",
    "label": "Riam Danau Kanan",
    "value": "Riam Danau Kanan"
  },
  {
    "id": "6104142008",
    "district_id": "610414",
    "label": "Limpang",
    "value": "Limpang"
  },
  {
    "id": "6104142009",
    "district_id": "610414",
    "label": "Pasir Mayang",
    "value": "Pasir Mayang"
  },
  {
    "id": "6104142010",
    "district_id": "610414",
    "label": "Tebing Berseri",
    "value": "Tebing Berseri"
  },
  {
    "id": "6104142011",
    "district_id": "610414",
    "label": "Deranuk",
    "value": "Deranuk"
  },
  {
    "id": "6104142012",
    "district_id": "610414",
    "label": "Rangga Intan",
    "value": "Rangga Intan"
  },
  {
    "id": "6104142013",
    "district_id": "610414",
    "label": "Pangkalan Suka",
    "value": "Pangkalan Suka"
  },
  {
    "id": "6104142014",
    "district_id": "610414",
    "label": "Teluk Runjai",
    "value": "Teluk Runjai"
  },
  {
    "id": "6104142015",
    "district_id": "610414",
    "label": "Asam Jelai",
    "value": "Asam Jelai"
  },
  {
    "id": "6104142016",
    "district_id": "610414",
    "label": "Air Dua",
    "value": "Air Dua"
  },
  {
    "id": "6104142017",
    "district_id": "610414",
    "label": "Sidahari",
    "value": "Sidahari"
  },
  {
    "id": "6104142018",
    "district_id": "610414",
    "label": "Kusik Batu Lapu",
    "value": "Kusik Batu Lapu"
  },
  {
    "id": "6104142019",
    "district_id": "610414",
    "label": "Karang Dangin",
    "value": "Karang Dangin"
  },
  {
    "id": "6104142020",
    "district_id": "610414",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "6104142021",
    "district_id": "610414",
    "label": "Bayam Raya",
    "value": "Bayam Raya"
  },
  {
    "id": "6104142022",
    "district_id": "610414",
    "label": "Pangkalan Paket",
    "value": "Pangkalan Paket"
  },
  {
    "id": "6104161001",
    "district_id": "610416",
    "label": "Kantor",
    "value": "Kantor"
  },
  {
    "id": "6104161002",
    "district_id": "610416",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "6104161003",
    "district_id": "610416",
    "label": "Mulia Baru",
    "value": "Mulia Baru"
  },
  {
    "id": "6104161004",
    "district_id": "610416",
    "label": "Sampit",
    "value": "Sampit"
  },
  {
    "id": "6104161005",
    "district_id": "610416",
    "label": "Suka Harja",
    "value": "Suka Harja"
  },
  {
    "id": "6104162006",
    "district_id": "610416",
    "label": "Suka Bangun",
    "value": "Suka Bangun"
  },
  {
    "id": "6104162007",
    "district_id": "610416",
    "label": "Kali Nilam",
    "value": "Kali Nilam"
  },
  {
    "id": "6104162008",
    "district_id": "610416",
    "label": "Paya Kumang",
    "value": "Paya Kumang"
  },
  {
    "id": "6104162009",
    "district_id": "610416",
    "label": "Suka Bangun Dalam",
    "value": "Suka Bangun Dalam"
  },
  {
    "id": "6104172001",
    "district_id": "610417",
    "label": "Sungai Awan Kanan",
    "value": "Sungai Awan Kanan"
  },
  {
    "id": "6104172002",
    "district_id": "610417",
    "label": "Sungai Awan Kiri",
    "value": "Sungai Awan Kiri"
  },
  {
    "id": "6104172003",
    "district_id": "610417",
    "label": "Tempurukan",
    "value": "Tempurukan"
  },
  {
    "id": "6104172004",
    "district_id": "610417",
    "label": "Ulak Medang",
    "value": "Ulak Medang"
  },
  {
    "id": "6104172005",
    "district_id": "610417",
    "label": "Tanjung Pura",
    "value": "Tanjung Pura"
  },
  {
    "id": "6104172006",
    "district_id": "610417",
    "label": "Mayak",
    "value": "Mayak"
  },
  {
    "id": "6104172007",
    "district_id": "610417",
    "label": "Tanjung Pasar",
    "value": "Tanjung Pasar"
  },
  {
    "id": "6104172008",
    "district_id": "610417",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6104181001",
    "district_id": "610418",
    "label": "Kauman",
    "value": "Kauman"
  },
  {
    "id": "6104181002",
    "district_id": "610418",
    "label": "Mulia Kerta",
    "value": "Mulia Kerta"
  },
  {
    "id": "6104181004",
    "district_id": "610418",
    "label": "Tuan-Tuan",
    "value": "Tuan-Tuan"
  },
  {
    "id": "6104181009",
    "district_id": "610418",
    "label": "Banjar",
    "value": "Banjar"
  },
  {
    "id": "6104182003",
    "district_id": "610418",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "6104182005",
    "district_id": "610418",
    "label": "Sungai Kinjil",
    "value": "Sungai Kinjil"
  },
  {
    "id": "6104182006",
    "district_id": "610418",
    "label": "Suka Baru",
    "value": "Suka Baru"
  },
  {
    "id": "6104182007",
    "district_id": "610418",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6104182008",
    "district_id": "610418",
    "label": "Negeri Baru",
    "value": "Negeri Baru"
  },
  {
    "id": "6104182010",
    "district_id": "610418",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6104182011",
    "district_id": "610418",
    "label": "Kinjil Pesisir",
    "value": "Kinjil Pesisir"
  },
  {
    "id": "6104192001",
    "district_id": "610419",
    "label": "Menyumbung",
    "value": "Menyumbung"
  },
  {
    "id": "6104192002",
    "district_id": "610419",
    "label": "Senduruhan",
    "value": "Senduruhan"
  },
  {
    "id": "6104192003",
    "district_id": "610419",
    "label": "Cinta Manis",
    "value": "Cinta Manis"
  },
  {
    "id": "6104192004",
    "district_id": "610419",
    "label": "Beginci Darat",
    "value": "Beginci Darat"
  },
  {
    "id": "6104192005",
    "district_id": "610419",
    "label": "Benua Krio",
    "value": "Benua Krio"
  },
  {
    "id": "6104192006",
    "district_id": "610419",
    "label": "Riam Dadap",
    "value": "Riam Dadap"
  },
  {
    "id": "6104192007",
    "district_id": "610419",
    "label": "Sekukun",
    "value": "Sekukun"
  },
  {
    "id": "6104192008",
    "district_id": "610419",
    "label": "Batu Lapis",
    "value": "Batu Lapis"
  },
  {
    "id": "6104192009",
    "district_id": "610419",
    "label": "Krio Hulu",
    "value": "Krio Hulu"
  },
  {
    "id": "6104192010",
    "district_id": "610419",
    "label": "Kenyabur",
    "value": "Kenyabur"
  },
  {
    "id": "6104192011",
    "district_id": "610419",
    "label": "Sunge Bengaras",
    "value": "Sunge Bengaras"
  },
  {
    "id": "6104192012",
    "district_id": "610419",
    "label": "Lubuk Kakap",
    "value": "Lubuk Kakap"
  },
  {
    "id": "6104202001",
    "district_id": "610420",
    "label": "Semandang Kanan",
    "value": "Semandang Kanan"
  },
  {
    "id": "6104202002",
    "district_id": "610420",
    "label": "Gema",
    "value": "Gema"
  },
  {
    "id": "6104202003",
    "district_id": "610420",
    "label": "Mekar Raya",
    "value": "Mekar Raya"
  },
  {
    "id": "6104202004",
    "district_id": "610420",
    "label": "Kampar Sebomban",
    "value": "Kampar Sebomban"
  },
  {
    "id": "6104202005",
    "district_id": "610420",
    "label": "Batu Daya",
    "value": "Batu Daya"
  },
  {
    "id": "6104202006",
    "district_id": "610420",
    "label": "Kamora",
    "value": "Kamora"
  },
  {
    "id": "6104212001",
    "district_id": "610421",
    "label": "Air Upas",
    "value": "Air Upas"
  },
  {
    "id": "6104212002",
    "district_id": "610421",
    "label": "Sari Bekayas",
    "value": "Sari Bekayas"
  },
  {
    "id": "6104212003",
    "district_id": "610421",
    "label": "Air Durian Jaya",
    "value": "Air Durian Jaya"
  },
  {
    "id": "6104212004",
    "district_id": "610421",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6104212005",
    "district_id": "610421",
    "label": "Suka Ria",
    "value": "Suka Ria"
  },
  {
    "id": "6104212006",
    "district_id": "610421",
    "label": "Banda Sari",
    "value": "Banda Sari"
  },
  {
    "id": "6104212007",
    "district_id": "610421",
    "label": "Membuluh Baru",
    "value": "Membuluh Baru"
  },
  {
    "id": "6104212008",
    "district_id": "610421",
    "label": "Gahang",
    "value": "Gahang"
  },
  {
    "id": "6104212009",
    "district_id": "610421",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6104222001",
    "district_id": "610422",
    "label": "Suka Mulia",
    "value": "Suka Mulia"
  },
  {
    "id": "6104222002",
    "district_id": "610422",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "6104222003",
    "district_id": "610422",
    "label": "Bukit Kelambing",
    "value": "Bukit Kelambing"
  },
  {
    "id": "6104222004",
    "district_id": "610422",
    "label": "Muntai",
    "value": "Muntai"
  },
  {
    "id": "6104222005",
    "district_id": "610422",
    "label": "Suka Sari",
    "value": "Suka Sari"
  },
  {
    "id": "6104222006",
    "district_id": "610422",
    "label": "Sukaharja",
    "value": "Sukaharja"
  },
  {
    "id": "6104222007",
    "district_id": "610422",
    "label": "Tanah Hitam",
    "value": "Tanah Hitam"
  },
  {
    "id": "6104222008",
    "district_id": "610422",
    "label": "Pantai Ketikal",
    "value": "Pantai Ketikal"
  },
  {
    "id": "6104242001",
    "district_id": "610424",
    "label": "Pebihingan",
    "value": "Pebihingan"
  },
  {
    "id": "6104242002",
    "district_id": "610424",
    "label": "Lalang Panjang",
    "value": "Lalang Panjang"
  },
  {
    "id": "6104242003",
    "district_id": "610424",
    "label": "Semayok Baru",
    "value": "Semayok Baru"
  },
  {
    "id": "6104242004",
    "district_id": "610424",
    "label": "Muara Gerunggang",
    "value": "Muara Gerunggang"
  },
  {
    "id": "6104242005",
    "district_id": "610424",
    "label": "Usaha Baru",
    "value": "Usaha Baru"
  },
  {
    "id": "6104242006",
    "district_id": "610424",
    "label": "Kerta Baru",
    "value": "Kerta Baru"
  },
  {
    "id": "6104242007",
    "district_id": "610424",
    "label": "Muara Semayok",
    "value": "Muara Semayok"
  },
  {
    "id": "6104252001",
    "district_id": "610425",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "6104252002",
    "district_id": "610425",
    "label": "Sungai Melayu Baru",
    "value": "Sungai Melayu Baru"
  },
  {
    "id": "6104252003",
    "district_id": "610425",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "6104252004",
    "district_id": "610425",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "6104252005",
    "district_id": "610425",
    "label": "Piansak",
    "value": "Piansak"
  },
  {
    "id": "6104252006",
    "district_id": "610425",
    "label": "Jairan Jaya",
    "value": "Jairan Jaya"
  },
  {
    "id": "6104252007",
    "district_id": "610425",
    "label": "Kepuluk",
    "value": "Kepuluk"
  },
  {
    "id": "6104252008",
    "district_id": "610425",
    "label": "Sungai Melayu Jaya",
    "value": "Sungai Melayu Jaya"
  },
  {
    "id": "6104252009",
    "district_id": "610425",
    "label": "Sungai Melayu",
    "value": "Sungai Melayu"
  },
  {
    "id": "6104252010",
    "district_id": "610425",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6104252011",
    "district_id": "610425",
    "label": "Makmur Abadi",
    "value": "Makmur Abadi"
  },
  {
    "id": "6105011001",
    "district_id": "610501",
    "label": "Ladang",
    "value": "Ladang"
  },
  {
    "id": "6105011002",
    "district_id": "610501",
    "label": "Kapuas Kanan Hulu",
    "value": "Kapuas Kanan Hulu"
  },
  {
    "id": "6105011003",
    "district_id": "610501",
    "label": "Tanjung Puri",
    "value": "Tanjung Puri"
  },
  {
    "id": "6105011004",
    "district_id": "610501",
    "label": "Kapuas Kanan Hilir",
    "value": "Kapuas Kanan Hilir"
  },
  {
    "id": "6105011005",
    "district_id": "610501",
    "label": "Kapuas Kiri Hilir",
    "value": "Kapuas Kiri Hilir"
  },
  {
    "id": "6105011006",
    "district_id": "610501",
    "label": "Kapuas Kiri Hulu",
    "value": "Kapuas Kiri Hulu"
  },
  {
    "id": "6105011016",
    "district_id": "610501",
    "label": "Akcaya",
    "value": "Akcaya"
  },
  {
    "id": "6105011017",
    "district_id": "610501",
    "label": "Alai",
    "value": "Alai"
  },
  {
    "id": "6105011018",
    "district_id": "610501",
    "label": "Rawa Mambok",
    "value": "Rawa Mambok"
  },
  {
    "id": "6105011019",
    "district_id": "610501",
    "label": "Sengkuang",
    "value": "Sengkuang"
  },
  {
    "id": "6105011020",
    "district_id": "610501",
    "label": "Mengkurai",
    "value": "Mengkurai"
  },
  {
    "id": "6105011021",
    "district_id": "610501",
    "label": "Kedabang",
    "value": "Kedabang"
  },
  {
    "id": "6105011022",
    "district_id": "610501",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6105011023",
    "district_id": "610501",
    "label": "Batu Lalau",
    "value": "Batu Lalau"
  },
  {
    "id": "6105011024",
    "district_id": "610501",
    "label": "Menyumbung Tengah",
    "value": "Menyumbung Tengah"
  },
  {
    "id": "6105011025",
    "district_id": "610501",
    "label": "Ulak Jaya",
    "value": "Ulak Jaya"
  },
  {
    "id": "6105012007",
    "district_id": "610501",
    "label": "Baning Kota",
    "value": "Baning Kota"
  },
  {
    "id": "6105012008",
    "district_id": "610501",
    "label": "Tertung",
    "value": "Tertung"
  },
  {
    "id": "6105012009",
    "district_id": "610501",
    "label": "Mungguk Bantok",
    "value": "Mungguk Bantok"
  },
  {
    "id": "6105012010",
    "district_id": "610501",
    "label": "Teluk Kelansam",
    "value": "Teluk Kelansam"
  },
  {
    "id": "6105012011",
    "district_id": "610501",
    "label": "Sungai Ana",
    "value": "Sungai Ana"
  },
  {
    "id": "6105012012",
    "district_id": "610501",
    "label": "Merti Guna",
    "value": "Merti Guna"
  },
  {
    "id": "6105012013",
    "district_id": "610501",
    "label": "Tanjung Kelansam",
    "value": "Tanjung Kelansam"
  },
  {
    "id": "6105012014",
    "district_id": "610501",
    "label": "Anggah Jaya",
    "value": "Anggah Jaya"
  },
  {
    "id": "6105012015",
    "district_id": "610501",
    "label": "Lalang Baru",
    "value": "Lalang Baru"
  },
  {
    "id": "6105012026",
    "district_id": "610501",
    "label": "Tebing Raya",
    "value": "Tebing Raya"
  },
  {
    "id": "6105012027",
    "district_id": "610501",
    "label": "Mail Jampong",
    "value": "Mail Jampong"
  },
  {
    "id": "6105012028",
    "district_id": "610501",
    "label": "Jerora Satu",
    "value": "Jerora Satu"
  },
  {
    "id": "6105012029",
    "district_id": "610501",
    "label": "Kebiau Baru",
    "value": "Kebiau Baru"
  },
  {
    "id": "6105022001",
    "district_id": "610502",
    "label": "Nanga Tempunak",
    "value": "Nanga Tempunak"
  },
  {
    "id": "6105022002",
    "district_id": "610502",
    "label": "Mensiap Baru",
    "value": "Mensiap Baru"
  },
  {
    "id": "6105022003",
    "district_id": "610502",
    "label": "Tanjung Perada",
    "value": "Tanjung Perada"
  },
  {
    "id": "6105022004",
    "district_id": "610502",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "6105022005",
    "district_id": "610502",
    "label": "Mengkurat Baru",
    "value": "Mengkurat Baru"
  },
  {
    "id": "6105022006",
    "district_id": "610502",
    "label": "Peribang Baru",
    "value": "Peribang Baru"
  },
  {
    "id": "6105022007",
    "district_id": "610502",
    "label": "Repak Sari",
    "value": "Repak Sari"
  },
  {
    "id": "6105022008",
    "district_id": "610502",
    "label": "Pagal Baru",
    "value": "Pagal Baru"
  },
  {
    "id": "6105022009",
    "district_id": "610502",
    "label": "Kenyabur Baru",
    "value": "Kenyabur Baru"
  },
  {
    "id": "6105022010",
    "district_id": "610502",
    "label": "Benua Baru",
    "value": "Benua Baru"
  },
  {
    "id": "6105022011",
    "district_id": "610502",
    "label": "Tinum Baru",
    "value": "Tinum Baru"
  },
  {
    "id": "6105022012",
    "district_id": "610502",
    "label": "Pangkal Baru",
    "value": "Pangkal Baru"
  },
  {
    "id": "6105022013",
    "district_id": "610502",
    "label": "Pulau Jaya",
    "value": "Pulau Jaya"
  },
  {
    "id": "6105022014",
    "district_id": "610502",
    "label": "Kuala Tiga",
    "value": "Kuala Tiga"
  },
  {
    "id": "6105022015",
    "district_id": "610502",
    "label": "Gurung Mali",
    "value": "Gurung Mali"
  },
  {
    "id": "6105022016",
    "district_id": "610502",
    "label": "Merti Jaya",
    "value": "Merti Jaya"
  },
  {
    "id": "6105022017",
    "district_id": "610502",
    "label": "Benua Kencana",
    "value": "Benua Kencana"
  },
  {
    "id": "6105022018",
    "district_id": "610502",
    "label": "Balai Harapan",
    "value": "Balai Harapan"
  },
  {
    "id": "6105022019",
    "district_id": "610502",
    "label": "Tempunak Kapuas",
    "value": "Tempunak Kapuas"
  },
  {
    "id": "6105022020",
    "district_id": "610502",
    "label": "Riam Batu",
    "value": "Riam Batu"
  },
  {
    "id": "6105022021",
    "district_id": "610502",
    "label": "Pudau Bersatu",
    "value": "Pudau Bersatu"
  },
  {
    "id": "6105022022",
    "district_id": "610502",
    "label": "Kupan Jaya",
    "value": "Kupan Jaya"
  },
  {
    "id": "6105022023",
    "district_id": "610502",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "6105022024",
    "district_id": "610502",
    "label": "Jaya Mentari",
    "value": "Jaya Mentari"
  },
  {
    "id": "6105022025",
    "district_id": "610502",
    "label": "Pekulai Bersatu",
    "value": "Pekulai Bersatu"
  },
  {
    "id": "6105022026",
    "district_id": "610502",
    "label": "Mensiap Jaya",
    "value": "Mensiap Jaya"
  },
  {
    "id": "6105032001",
    "district_id": "610503",
    "label": "Nanga Sepauk",
    "value": "Nanga Sepauk"
  },
  {
    "id": "6105032002",
    "district_id": "610503",
    "label": "Tanjung Ria",
    "value": "Tanjung Ria"
  },
  {
    "id": "6105032003",
    "district_id": "610503",
    "label": "Sekubang",
    "value": "Sekubang"
  },
  {
    "id": "6105032004",
    "district_id": "610503",
    "label": "Kenyauk",
    "value": "Kenyauk"
  },
  {
    "id": "6105032005",
    "district_id": "610503",
    "label": "Lengkenat",
    "value": "Lengkenat"
  },
  {
    "id": "6105032006",
    "district_id": "610503",
    "label": "Manis Raya",
    "value": "Manis Raya"
  },
  {
    "id": "6105032007",
    "district_id": "610503",
    "label": "Temiang Kapuas",
    "value": "Temiang Kapuas"
  },
  {
    "id": "6105032008",
    "district_id": "610503",
    "label": "Ensabang",
    "value": "Ensabang"
  },
  {
    "id": "6105032009",
    "district_id": "610503",
    "label": "Mait Hilir",
    "value": "Mait Hilir"
  },
  {
    "id": "6105032010",
    "district_id": "610503",
    "label": "Sekujam Timbai",
    "value": "Sekujam Timbai"
  },
  {
    "id": "6105032011",
    "district_id": "610503",
    "label": "Semuntai",
    "value": "Semuntai"
  },
  {
    "id": "6105032012",
    "district_id": "610503",
    "label": "Sirang Setambang",
    "value": "Sirang Setambang"
  },
  {
    "id": "6105032013",
    "district_id": "610503",
    "label": "Nanga Libau",
    "value": "Nanga Libau"
  },
  {
    "id": "6105032014",
    "district_id": "610503",
    "label": "Bernayau",
    "value": "Bernayau"
  },
  {
    "id": "6105032015",
    "district_id": "610503",
    "label": "Sinar Pekayau",
    "value": "Sinar Pekayau"
  },
  {
    "id": "6105032016",
    "district_id": "610503",
    "label": "Nanga Pari",
    "value": "Nanga Pari"
  },
  {
    "id": "6105032017",
    "district_id": "610503",
    "label": "Buluh Kuning",
    "value": "Buluh Kuning"
  },
  {
    "id": "6105032018",
    "district_id": "610503",
    "label": "Temawang Muntai",
    "value": "Temawang Muntai"
  },
  {
    "id": "6105032019",
    "district_id": "610503",
    "label": "Tawang Sari",
    "value": "Tawang Sari"
  },
  {
    "id": "6105032020",
    "district_id": "610503",
    "label": "Gernis Jaya",
    "value": "Gernis Jaya"
  },
  {
    "id": "6105032021",
    "district_id": "610503",
    "label": "Paoh Benua",
    "value": "Paoh Benua"
  },
  {
    "id": "6105032022",
    "district_id": "610503",
    "label": "Bedayan",
    "value": "Bedayan"
  },
  {
    "id": "6105032023",
    "district_id": "610503",
    "label": "Tanjung Hulu",
    "value": "Tanjung Hulu"
  },
  {
    "id": "6105032024",
    "district_id": "610503",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "6105032025",
    "district_id": "610503",
    "label": "Landau Panjang",
    "value": "Landau Panjang"
  },
  {
    "id": "6105032026",
    "district_id": "610503",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "6105032027",
    "district_id": "610503",
    "label": "Sukau Bersatu",
    "value": "Sukau Bersatu"
  },
  {
    "id": "6105032028",
    "district_id": "610503",
    "label": "Kemantan",
    "value": "Kemantan"
  },
  {
    "id": "6105032029",
    "district_id": "610503",
    "label": "Peninsung",
    "value": "Peninsung"
  },
  {
    "id": "6105032030",
    "district_id": "610503",
    "label": "Sepulut",
    "value": "Sepulut"
  },
  {
    "id": "6105032031",
    "district_id": "610503",
    "label": "Temawang Bulai",
    "value": "Temawang Bulai"
  },
  {
    "id": "6105032032",
    "district_id": "610503",
    "label": "Sungai Segak",
    "value": "Sungai Segak"
  },
  {
    "id": "6105032033",
    "district_id": "610503",
    "label": "Tanjung Balai",
    "value": "Tanjung Balai"
  },
  {
    "id": "6105032034",
    "district_id": "610503",
    "label": "Sinar Harapan",
    "value": "Sinar Harapan"
  },
  {
    "id": "6105032035",
    "district_id": "610503",
    "label": "Riam Kempadik",
    "value": "Riam Kempadik"
  },
  {
    "id": "6105032036",
    "district_id": "610503",
    "label": "Nanga Layung",
    "value": "Nanga Layung"
  },
  {
    "id": "6105032037",
    "district_id": "610503",
    "label": "Limau Bakti",
    "value": "Limau Bakti"
  },
  {
    "id": "6105032038",
    "district_id": "610503",
    "label": "Tanjung Mawang",
    "value": "Tanjung Mawang"
  },
  {
    "id": "6105032040",
    "district_id": "610503",
    "label": "Sungai Jaung",
    "value": "Sungai Jaung"
  },
  {
    "id": "6105042001",
    "district_id": "610504",
    "label": "Nanga Ketungau",
    "value": "Nanga Ketungau"
  },
  {
    "id": "6105042002",
    "district_id": "610504",
    "label": "Setungkup",
    "value": "Setungkup"
  },
  {
    "id": "6105042004",
    "district_id": "610504",
    "label": "Sungai Mali",
    "value": "Sungai Mali"
  },
  {
    "id": "6105042005",
    "district_id": "610504",
    "label": "Air Nyuruk",
    "value": "Air Nyuruk"
  },
  {
    "id": "6105042006",
    "district_id": "610504",
    "label": "Nanga Merkak",
    "value": "Nanga Merkak"
  },
  {
    "id": "6105042007",
    "district_id": "610504",
    "label": "Sungai Deras",
    "value": "Sungai Deras"
  },
  {
    "id": "6105042008",
    "district_id": "610504",
    "label": "Nanga Sejirak",
    "value": "Nanga Sejirak"
  },
  {
    "id": "6105042009",
    "district_id": "610504",
    "label": "Semuntai",
    "value": "Semuntai"
  },
  {
    "id": "6105042010",
    "district_id": "610504",
    "label": "Senibung",
    "value": "Senibung"
  },
  {
    "id": "6105042011",
    "district_id": "610504",
    "label": "Tanjung Baung",
    "value": "Tanjung Baung"
  },
  {
    "id": "6105042012",
    "district_id": "610504",
    "label": "Baung Sengatap",
    "value": "Baung Sengatap"
  },
  {
    "id": "6105042013",
    "district_id": "610504",
    "label": "Semajau Mekar",
    "value": "Semajau Mekar"
  },
  {
    "id": "6105042014",
    "district_id": "610504",
    "label": "Beluh Mulyo",
    "value": "Beluh Mulyo"
  },
  {
    "id": "6105042015",
    "district_id": "610504",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6105042016",
    "district_id": "610504",
    "label": "Bukit Sidin Permai",
    "value": "Bukit Sidin Permai"
  },
  {
    "id": "6105042017",
    "district_id": "610504",
    "label": "Betung Permai",
    "value": "Betung Permai"
  },
  {
    "id": "6105042018",
    "district_id": "610504",
    "label": "Kenuak",
    "value": "Kenuak"
  },
  {
    "id": "6105042019",
    "district_id": "610504",
    "label": "Jentawang Hilir",
    "value": "Jentawang Hilir"
  },
  {
    "id": "6105042020",
    "district_id": "610504",
    "label": "Batu Nyadi",
    "value": "Batu Nyadi"
  },
  {
    "id": "6105042021",
    "district_id": "610504",
    "label": "Ratu Damai",
    "value": "Ratu Damai"
  },
  {
    "id": "6105042022",
    "district_id": "610504",
    "label": "Lepung Pantak",
    "value": "Lepung Pantak"
  },
  {
    "id": "6105042023",
    "district_id": "610504",
    "label": "Pampang Dua",
    "value": "Pampang Dua"
  },
  {
    "id": "6105042024",
    "district_id": "610504",
    "label": "Mungguk Kelapa",
    "value": "Mungguk Kelapa"
  },
  {
    "id": "6105042025",
    "district_id": "610504",
    "label": "Maung",
    "value": "Maung"
  },
  {
    "id": "6105052001",
    "district_id": "610505",
    "label": "Wirayuda",
    "value": "Wirayuda"
  },
  {
    "id": "6105052002",
    "district_id": "610505",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "6105052003",
    "district_id": "610505",
    "label": "Panding Jaya",
    "value": "Panding Jaya"
  },
  {
    "id": "6105052004",
    "district_id": "610505",
    "label": "Tirta Karya",
    "value": "Tirta Karya"
  },
  {
    "id": "6105052005",
    "district_id": "610505",
    "label": "Begelang Jaya",
    "value": "Begelang Jaya"
  },
  {
    "id": "6105052006",
    "district_id": "610505",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6105052007",
    "district_id": "610505",
    "label": "Panggi Agung",
    "value": "Panggi Agung"
  },
  {
    "id": "6105052008",
    "district_id": "610505",
    "label": "Kerta Sari",
    "value": "Kerta Sari"
  },
  {
    "id": "6105052009",
    "district_id": "610505",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "6105052010",
    "district_id": "610505",
    "label": "Wana Bhakti",
    "value": "Wana Bhakti"
  },
  {
    "id": "6105052011",
    "district_id": "610505",
    "label": "Swadaya",
    "value": "Swadaya"
  },
  {
    "id": "6105052012",
    "district_id": "610505",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "6105052013",
    "district_id": "610505",
    "label": "Gut Jaya Bhakti",
    "value": "Gut Jaya Bhakti"
  },
  {
    "id": "6105052014",
    "district_id": "610505",
    "label": "Landau Buaya",
    "value": "Landau Buaya"
  },
  {
    "id": "6105052015",
    "district_id": "610505",
    "label": "Kayu Dujung",
    "value": "Kayu Dujung"
  },
  {
    "id": "6105052016",
    "district_id": "610505",
    "label": "Senangan Kecil",
    "value": "Senangan Kecil"
  },
  {
    "id": "6105052017",
    "district_id": "610505",
    "label": "Sungai Areh",
    "value": "Sungai Areh"
  },
  {
    "id": "6105052018",
    "district_id": "610505",
    "label": "Mungguk Gelombang",
    "value": "Mungguk Gelombang"
  },
  {
    "id": "6105052019",
    "district_id": "610505",
    "label": "Mungguk Lawang",
    "value": "Mungguk Lawang"
  },
  {
    "id": "6105052020",
    "district_id": "610505",
    "label": "Nanga Kelapan",
    "value": "Nanga Kelapan"
  },
  {
    "id": "6105052021",
    "district_id": "610505",
    "label": "Panggi Ruguk",
    "value": "Panggi Ruguk"
  },
  {
    "id": "6105052022",
    "district_id": "610505",
    "label": "Bakti Senabung",
    "value": "Bakti Senabung"
  },
  {
    "id": "6105052023",
    "district_id": "610505",
    "label": "Engkitan",
    "value": "Engkitan"
  },
  {
    "id": "6105052024",
    "district_id": "610505",
    "label": "Landau Temiang",
    "value": "Landau Temiang"
  },
  {
    "id": "6105052025",
    "district_id": "610505",
    "label": "Padung Kumang",
    "value": "Padung Kumang"
  },
  {
    "id": "6105052026",
    "district_id": "610505",
    "label": "Radin Jaya",
    "value": "Radin Jaya"
  },
  {
    "id": "6105052027",
    "district_id": "610505",
    "label": "Senangan Jaya",
    "value": "Senangan Jaya"
  },
  {
    "id": "6105052028",
    "district_id": "610505",
    "label": "Kubu Berangan",
    "value": "Kubu Berangan"
  },
  {
    "id": "6105052029",
    "district_id": "610505",
    "label": "Semareh",
    "value": "Semareh"
  },
  {
    "id": "6105062001",
    "district_id": "610506",
    "label": "Senaning",
    "value": "Senaning"
  },
  {
    "id": "6105062002",
    "district_id": "610506",
    "label": "Empura",
    "value": "Empura"
  },
  {
    "id": "6105062003",
    "district_id": "610506",
    "label": "Sebadak",
    "value": "Sebadak"
  },
  {
    "id": "6105062004",
    "district_id": "610506",
    "label": "Sepiluk",
    "value": "Sepiluk"
  },
  {
    "id": "6105062005",
    "district_id": "610506",
    "label": "Sungai Seria",
    "value": "Sungai Seria"
  },
  {
    "id": "6105062006",
    "district_id": "610506",
    "label": "Suak Medang",
    "value": "Suak Medang"
  },
  {
    "id": "6105062007",
    "district_id": "610506",
    "label": "Nanga Bayan",
    "value": "Nanga Bayan"
  },
  {
    "id": "6105062008",
    "district_id": "610506",
    "label": "Rasau",
    "value": "Rasau"
  },
  {
    "id": "6105062009",
    "district_id": "610506",
    "label": "Jasa",
    "value": "Jasa"
  },
  {
    "id": "6105062010",
    "district_id": "610506",
    "label": "Sungai Bugau",
    "value": "Sungai Bugau"
  },
  {
    "id": "6105062011",
    "district_id": "610506",
    "label": "Nanga Bugau",
    "value": "Nanga Bugau"
  },
  {
    "id": "6105062012",
    "district_id": "610506",
    "label": "Empunak Tapang Keladan",
    "value": "Empunak Tapang Keladan"
  },
  {
    "id": "6105062013",
    "district_id": "610506",
    "label": "Sebetung Paluk",
    "value": "Sebetung Paluk"
  },
  {
    "id": "6105062014",
    "district_id": "610506",
    "label": "Muakan Petinggi",
    "value": "Muakan Petinggi"
  },
  {
    "id": "6105062015",
    "district_id": "610506",
    "label": "Nanga Sebawang",
    "value": "Nanga Sebawang"
  },
  {
    "id": "6105062016",
    "district_id": "610506",
    "label": "Sekaih",
    "value": "Sekaih"
  },
  {
    "id": "6105062017",
    "district_id": "610506",
    "label": "Bekuan Luyang",
    "value": "Bekuan Luyang"
  },
  {
    "id": "6105062018",
    "district_id": "610506",
    "label": "Sungai Pisau",
    "value": "Sungai Pisau"
  },
  {
    "id": "6105062019",
    "district_id": "610506",
    "label": "Mungguk Entawak",
    "value": "Mungguk Entawak"
  },
  {
    "id": "6105062020",
    "district_id": "610506",
    "label": "Embaleh",
    "value": "Embaleh"
  },
  {
    "id": "6105062021",
    "district_id": "610506",
    "label": "Sebuluh",
    "value": "Sebuluh"
  },
  {
    "id": "6105062022",
    "district_id": "610506",
    "label": "Riam Sejawak",
    "value": "Riam Sejawak"
  },
  {
    "id": "6105062023",
    "district_id": "610506",
    "label": "Engkeruh",
    "value": "Engkeruh"
  },
  {
    "id": "6105062024",
    "district_id": "610506",
    "label": "Sungai Kelik",
    "value": "Sungai Kelik"
  },
  {
    "id": "6105062025",
    "district_id": "610506",
    "label": "Idai",
    "value": "Idai"
  },
  {
    "id": "6105062026",
    "district_id": "610506",
    "label": "Sungai Mawang",
    "value": "Sungai Mawang"
  },
  {
    "id": "6105062027",
    "district_id": "610506",
    "label": "Ujung Kempas",
    "value": "Ujung Kempas"
  },
  {
    "id": "6105062028",
    "district_id": "610506",
    "label": "Neraci Jaya",
    "value": "Neraci Jaya"
  },
  {
    "id": "6105062029",
    "district_id": "610506",
    "label": "Sejawak",
    "value": "Sejawak"
  },
  {
    "id": "6105072001",
    "district_id": "610507",
    "label": "Nanga Jetak",
    "value": "Nanga Jetak"
  },
  {
    "id": "6105072002",
    "district_id": "610507",
    "label": "Nanga Dedai",
    "value": "Nanga Dedai"
  },
  {
    "id": "6105072003",
    "district_id": "610507",
    "label": "Gandis",
    "value": "Gandis"
  },
  {
    "id": "6105072004",
    "district_id": "610507",
    "label": "Sungai Mali",
    "value": "Sungai Mali"
  },
  {
    "id": "6105072005",
    "district_id": "610507",
    "label": "Riguk",
    "value": "Riguk"
  },
  {
    "id": "6105072006",
    "district_id": "610507",
    "label": "Pengkadan Baru",
    "value": "Pengkadan Baru"
  },
  {
    "id": "6105072007",
    "district_id": "610507",
    "label": "Taok",
    "value": "Taok"
  },
  {
    "id": "6105072008",
    "district_id": "610507",
    "label": "Empaci",
    "value": "Empaci"
  },
  {
    "id": "6105072009",
    "district_id": "610507",
    "label": "Baras",
    "value": "Baras"
  },
  {
    "id": "6105072010",
    "district_id": "610507",
    "label": "Pangkadan Sungai Rupa",
    "value": "Pangkadan Sungai Rupa"
  },
  {
    "id": "6105072011",
    "district_id": "610507",
    "label": "Kumpang",
    "value": "Kumpang"
  },
  {
    "id": "6105072012",
    "district_id": "610507",
    "label": "Mangat Baru",
    "value": "Mangat Baru"
  },
  {
    "id": "6105072013",
    "district_id": "610507",
    "label": "Manaong Baru",
    "value": "Manaong Baru"
  },
  {
    "id": "6105072014",
    "district_id": "610507",
    "label": "Emparu Baru",
    "value": "Emparu Baru"
  },
  {
    "id": "6105072015",
    "district_id": "610507",
    "label": "Lundang Baru",
    "value": "Lundang Baru"
  },
  {
    "id": "6105072016",
    "district_id": "610507",
    "label": "Penyak Lalang",
    "value": "Penyak Lalang"
  },
  {
    "id": "6105072017",
    "district_id": "610507",
    "label": "Umin Jaya",
    "value": "Umin Jaya"
  },
  {
    "id": "6105072018",
    "district_id": "610507",
    "label": "Merempit Baru",
    "value": "Merempit Baru"
  },
  {
    "id": "6105072019",
    "district_id": "610507",
    "label": "Dedai Kanan",
    "value": "Dedai Kanan"
  },
  {
    "id": "6105072020",
    "district_id": "610507",
    "label": "Sungai Tapang",
    "value": "Sungai Tapang"
  },
  {
    "id": "6105072021",
    "district_id": "610507",
    "label": "Gandis Hulu",
    "value": "Gandis Hulu"
  },
  {
    "id": "6105072022",
    "district_id": "610507",
    "label": "Hulu Dedai",
    "value": "Hulu Dedai"
  },
  {
    "id": "6105072023",
    "district_id": "610507",
    "label": "Apin Baru",
    "value": "Apin Baru"
  },
  {
    "id": "6105072024",
    "district_id": "610507",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  {
    "id": "6105072025",
    "district_id": "610507",
    "label": "Belinyuk Sibau",
    "value": "Belinyuk Sibau"
  },
  {
    "id": "6105072026",
    "district_id": "610507",
    "label": "Mengkirai Jaya",
    "value": "Mengkirai Jaya"
  },
  {
    "id": "6105072027",
    "district_id": "610507",
    "label": "Terusan",
    "value": "Terusan"
  },
  {
    "id": "6105072028",
    "district_id": "610507",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6105072029",
    "district_id": "610507",
    "label": "Batu Landung",
    "value": "Batu Landung"
  },
  {
    "id": "6105072030",
    "district_id": "610507",
    "label": "Samak",
    "value": "Samak"
  },
  {
    "id": "6105072031",
    "district_id": "610507",
    "label": "Manyam",
    "value": "Manyam"
  },
  {
    "id": "6105082001",
    "district_id": "610508",
    "label": "Nanga Mau",
    "value": "Nanga Mau"
  },
  {
    "id": "6105082002",
    "district_id": "610508",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "6105082003",
    "district_id": "610508",
    "label": "Tertung Mau",
    "value": "Tertung Mau"
  },
  {
    "id": "6105082004",
    "district_id": "610508",
    "label": "Mentunai",
    "value": "Mentunai"
  },
  {
    "id": "6105082005",
    "district_id": "610508",
    "label": "Tuguk",
    "value": "Tuguk"
  },
  {
    "id": "6105082006",
    "district_id": "610508",
    "label": "Natai Tebedak",
    "value": "Natai Tebedak"
  },
  {
    "id": "6105082007",
    "district_id": "610508",
    "label": "Nyangkom",
    "value": "Nyangkom"
  },
  {
    "id": "6105082008",
    "district_id": "610508",
    "label": "Melingkat",
    "value": "Melingkat"
  },
  {
    "id": "6105082009",
    "district_id": "610508",
    "label": "Nanga Tikan",
    "value": "Nanga Tikan"
  },
  {
    "id": "6105082010",
    "district_id": "610508",
    "label": "Pelaik",
    "value": "Pelaik"
  },
  {
    "id": "6105082011",
    "district_id": "610508",
    "label": "Kerapa Sepan",
    "value": "Kerapa Sepan"
  },
  {
    "id": "6105082012",
    "district_id": "610508",
    "label": "Sungai Buaya",
    "value": "Sungai Buaya"
  },
  {
    "id": "6105082013",
    "district_id": "610508",
    "label": "Pakak",
    "value": "Pakak"
  },
  {
    "id": "6105082014",
    "district_id": "610508",
    "label": "Sungai Sintang",
    "value": "Sungai Sintang"
  },
  {
    "id": "6105082015",
    "district_id": "610508",
    "label": "Sungai Garong",
    "value": "Sungai Garong"
  },
  {
    "id": "6105082016",
    "district_id": "610508",
    "label": "Paoh Desa",
    "value": "Paoh Desa"
  },
  {
    "id": "6105082017",
    "district_id": "610508",
    "label": "Jaya Sakti",
    "value": "Jaya Sakti"
  },
  {
    "id": "6105082018",
    "district_id": "610508",
    "label": "Mekar Mandiri",
    "value": "Mekar Mandiri"
  },
  {
    "id": "6105082019",
    "district_id": "610508",
    "label": "Landau Beringin",
    "value": "Landau Beringin"
  },
  {
    "id": "6105082020",
    "district_id": "610508",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "6105082021",
    "district_id": "610508",
    "label": "Bukit Segaloh",
    "value": "Bukit Segaloh"
  },
  {
    "id": "6105082022",
    "district_id": "610508",
    "label": "Engkerangan",
    "value": "Engkerangan"
  },
  {
    "id": "6105082023",
    "district_id": "610508",
    "label": "Lalang Inggar",
    "value": "Lalang Inggar"
  },
  {
    "id": "6105082024",
    "district_id": "610508",
    "label": "Linggam Permai",
    "value": "Linggam Permai"
  },
  {
    "id": "6105082025",
    "district_id": "610508",
    "label": "Sungai Menuang",
    "value": "Sungai Menuang"
  },
  {
    "id": "6105082026",
    "district_id": "610508",
    "label": "Natai Lesung",
    "value": "Natai Lesung"
  },
  {
    "id": "6105082027",
    "district_id": "610508",
    "label": "Begendang Mal",
    "value": "Begendang Mal"
  },
  {
    "id": "6105082028",
    "district_id": "610508",
    "label": "Sungai Ranap",
    "value": "Sungai Ranap"
  },
  {
    "id": "6105082029",
    "district_id": "610508",
    "label": "Lengkong Bindu",
    "value": "Lengkong Bindu"
  },
  {
    "id": "6105082030",
    "district_id": "610508",
    "label": "Nanga Lidau",
    "value": "Nanga Lidau"
  },
  {
    "id": "6105082031",
    "district_id": "610508",
    "label": "Mengkirai",
    "value": "Mengkirai"
  },
  {
    "id": "6105082032",
    "district_id": "610508",
    "label": "Tanjung Keliling",
    "value": "Tanjung Keliling"
  },
  {
    "id": "6105082033",
    "district_id": "610508",
    "label": "Buluk Jegara",
    "value": "Buluk Jegara"
  },
  {
    "id": "6105082034",
    "district_id": "610508",
    "label": "Buluk Panjang",
    "value": "Buluk Panjang"
  },
  {
    "id": "6105082035",
    "district_id": "610508",
    "label": "Tanjung Putar",
    "value": "Tanjung Putar"
  },
  {
    "id": "6105082036",
    "district_id": "610508",
    "label": "Sungai Emang",
    "value": "Sungai Emang"
  },
  {
    "id": "6105082037",
    "district_id": "610508",
    "label": "Sungai Meraya",
    "value": "Sungai Meraya"
  },
  {
    "id": "6105082038",
    "district_id": "610508",
    "label": "Kempas Raya",
    "value": "Kempas Raya"
  },
  {
    "id": "6105082039",
    "district_id": "610508",
    "label": "Ipoh Emang",
    "value": "Ipoh Emang"
  },
  {
    "id": "6105082040",
    "district_id": "610508",
    "label": "Sungai Pengga",
    "value": "Sungai Pengga"
  },
  {
    "id": "6105082041",
    "district_id": "610508",
    "label": "Batu Netak",
    "value": "Batu Netak"
  },
  {
    "id": "6105082042",
    "district_id": "610508",
    "label": "Neran Baya",
    "value": "Neran Baya"
  },
  {
    "id": "6105082043",
    "district_id": "610508",
    "label": "Monbai Begununk",
    "value": "Monbai Begununk"
  },
  {
    "id": "6105092001",
    "district_id": "610509",
    "label": "Nanga Tebidah",
    "value": "Nanga Tebidah"
  },
  {
    "id": "6105092002",
    "district_id": "610509",
    "label": "Nanga Payak",
    "value": "Nanga Payak"
  },
  {
    "id": "6105092003",
    "district_id": "610509",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "6105092004",
    "district_id": "610509",
    "label": "Nanga Toran",
    "value": "Nanga Toran"
  },
  {
    "id": "6105092005",
    "district_id": "610509",
    "label": "Riam Panjang",
    "value": "Riam Panjang"
  },
  {
    "id": "6105092006",
    "district_id": "610509",
    "label": "Entogong",
    "value": "Entogong"
  },
  {
    "id": "6105092007",
    "district_id": "610509",
    "label": "Nanga Tonggoi",
    "value": "Nanga Tonggoi"
  },
  {
    "id": "6105092008",
    "district_id": "610509",
    "label": "Tanjung Lalau",
    "value": "Tanjung Lalau"
  },
  {
    "id": "6105092009",
    "district_id": "610509",
    "label": "Lintang Tambuk",
    "value": "Lintang Tambuk"
  },
  {
    "id": "6105092010",
    "district_id": "610509",
    "label": "Nanga Masau",
    "value": "Nanga Masau"
  },
  {
    "id": "6105092011",
    "district_id": "610509",
    "label": "Nanga Ungai",
    "value": "Nanga Ungai"
  },
  {
    "id": "6105092012",
    "district_id": "610509",
    "label": "Nanga Abai",
    "value": "Nanga Abai"
  },
  {
    "id": "6105092013",
    "district_id": "610509",
    "label": "Nanga Laar",
    "value": "Nanga Laar"
  },
  {
    "id": "6105092014",
    "district_id": "610509",
    "label": "Riam Muntik",
    "value": "Riam Muntik"
  },
  {
    "id": "6105092015",
    "district_id": "610509",
    "label": "Nanga Oran",
    "value": "Nanga Oran"
  },
  {
    "id": "6105092016",
    "district_id": "610509",
    "label": "Tonak Goneh",
    "value": "Tonak Goneh"
  },
  {
    "id": "6105092017",
    "district_id": "610509",
    "label": "Nangkak Lestari",
    "value": "Nangkak Lestari"
  },
  {
    "id": "6105092018",
    "district_id": "610509",
    "label": "Topan Nanga",
    "value": "Topan Nanga"
  },
  {
    "id": "6105092019",
    "district_id": "610509",
    "label": "Mapan Jaya",
    "value": "Mapan Jaya"
  },
  {
    "id": "6105092020",
    "district_id": "610509",
    "label": "Tanjung Miru",
    "value": "Tanjung Miru"
  },
  {
    "id": "6105092021",
    "district_id": "610509",
    "label": "Buluh Merindu",
    "value": "Buluh Merindu"
  },
  {
    "id": "6105092022",
    "district_id": "610509",
    "label": "Empakan",
    "value": "Empakan"
  },
  {
    "id": "6105092023",
    "district_id": "610509",
    "label": "Emponyang",
    "value": "Emponyang"
  },
  {
    "id": "6105092024",
    "district_id": "610509",
    "label": "Merahau Permai",
    "value": "Merahau Permai"
  },
  {
    "id": "6105092025",
    "district_id": "610509",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "6105092026",
    "district_id": "610509",
    "label": "Landau Bara",
    "value": "Landau Bara"
  },
  {
    "id": "6105092027",
    "district_id": "610509",
    "label": "Kerapuk Jaya",
    "value": "Kerapuk Jaya"
  },
  {
    "id": "6105092028",
    "district_id": "610509",
    "label": "Merah Arai",
    "value": "Merah Arai"
  },
  {
    "id": "6105092029",
    "district_id": "610509",
    "label": "Tapang Manua",
    "value": "Tapang Manua"
  },
  {
    "id": "6105092030",
    "district_id": "610509",
    "label": "Nanga Tampang",
    "value": "Nanga Tampang"
  },
  {
    "id": "6105092031",
    "district_id": "610509",
    "label": "Kebarau",
    "value": "Kebarau"
  },
  {
    "id": "6105142001",
    "district_id": "610514",
    "label": "Nanga Serawai",
    "value": "Nanga Serawai"
  },
  {
    "id": "6105142002",
    "district_id": "610514",
    "label": "Nanga Mentatai",
    "value": "Nanga Mentatai"
  },
  {
    "id": "6105142003",
    "district_id": "610514",
    "label": "Bedaha",
    "value": "Bedaha"
  },
  {
    "id": "6105142004",
    "district_id": "610514",
    "label": "Begori",
    "value": "Begori"
  },
  {
    "id": "6105142005",
    "district_id": "610514",
    "label": "Pagar Lebata",
    "value": "Pagar Lebata"
  },
  {
    "id": "6105142006",
    "district_id": "610514",
    "label": "Tontang",
    "value": "Tontang"
  },
  {
    "id": "6105142007",
    "district_id": "610514",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "6105142008",
    "district_id": "610514",
    "label": "Nanga Riyoi",
    "value": "Nanga Riyoi"
  },
  {
    "id": "6105142009",
    "district_id": "610514",
    "label": "Buntut Ponte",
    "value": "Buntut Ponte"
  },
  {
    "id": "6105142010",
    "district_id": "610514",
    "label": "Nanga Segulang",
    "value": "Nanga Segulang"
  },
  {
    "id": "6105142011",
    "district_id": "610514",
    "label": "Baras Nabun",
    "value": "Baras Nabun"
  },
  {
    "id": "6105142012",
    "district_id": "610514",
    "label": "Nanga Jelundung",
    "value": "Nanga Jelundung"
  },
  {
    "id": "6105142013",
    "district_id": "610514",
    "label": "Tanjung Raya",
    "value": "Tanjung Raya"
  },
  {
    "id": "6105142014",
    "district_id": "610514",
    "label": "Tahai Permai",
    "value": "Tahai Permai"
  },
  {
    "id": "6105142015",
    "district_id": "610514",
    "label": "Merako Jaya",
    "value": "Merako Jaya"
  },
  {
    "id": "6105142016",
    "district_id": "610514",
    "label": "Sawang Senghiang",
    "value": "Sawang Senghiang"
  },
  {
    "id": "6105142017",
    "district_id": "610514",
    "label": "Tunas Harapan",
    "value": "Tunas Harapan"
  },
  {
    "id": "6105142018",
    "district_id": "610514",
    "label": "Teluk Harapan",
    "value": "Teluk Harapan"
  },
  {
    "id": "6105142019",
    "district_id": "610514",
    "label": "Gurung Senghiang",
    "value": "Gurung Senghiang"
  },
  {
    "id": "6105142020",
    "district_id": "610514",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6105142021",
    "district_id": "610514",
    "label": "Rantau Malam",
    "value": "Rantau Malam"
  },
  {
    "id": "6105142022",
    "district_id": "610514",
    "label": "Mentajoi",
    "value": "Mentajoi"
  },
  {
    "id": "6105142023",
    "district_id": "610514",
    "label": "Nanga Bihe",
    "value": "Nanga Bihe"
  },
  {
    "id": "6105142024",
    "district_id": "610514",
    "label": "Nanga Tekungai",
    "value": "Nanga Tekungai"
  },
  {
    "id": "6105142025",
    "district_id": "610514",
    "label": "Talian Sahabung",
    "value": "Talian Sahabung"
  },
  {
    "id": "6105142026",
    "district_id": "610514",
    "label": "Batu Ketebung",
    "value": "Batu Ketebung"
  },
  {
    "id": "6105142027",
    "district_id": "610514",
    "label": "Muara Kota",
    "value": "Muara Kota"
  },
  {
    "id": "6105142028",
    "district_id": "610514",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6105142029",
    "district_id": "610514",
    "label": "Tanjung Baru",
    "value": "Tanjung Baru"
  },
  {
    "id": "6105142030",
    "district_id": "610514",
    "label": "Nusa Tujuh",
    "value": "Nusa Tujuh"
  },
  {
    "id": "6105142031",
    "district_id": "610514",
    "label": "Nanga Ruhan",
    "value": "Nanga Ruhan"
  },
  {
    "id": "6105142032",
    "district_id": "610514",
    "label": "Meroboi",
    "value": "Meroboi"
  },
  {
    "id": "6105142033",
    "district_id": "610514",
    "label": "Panekasan",
    "value": "Panekasan"
  },
  {
    "id": "6105142034",
    "district_id": "610514",
    "label": "Tamakung",
    "value": "Tamakung"
  },
  {
    "id": "6105142035",
    "district_id": "610514",
    "label": "Limbur Bernaung Lestari",
    "value": "Limbur Bernaung Lestari"
  },
  {
    "id": "6105142036",
    "district_id": "610514",
    "label": "Mensulung Bio",
    "value": "Mensulung Bio"
  },
  {
    "id": "6105142037",
    "district_id": "610514",
    "label": "Sabhang Landan",
    "value": "Sabhang Landan"
  },
  {
    "id": "6105142038",
    "district_id": "610514",
    "label": "Nanga Tangoi",
    "value": "Nanga Tangoi"
  },
  {
    "id": "6105152001",
    "district_id": "610515",
    "label": "Nanga Kemangai",
    "value": "Nanga Kemangai"
  },
  {
    "id": "6105152002",
    "district_id": "610515",
    "label": "Nanga Ambalau",
    "value": "Nanga Ambalau"
  },
  {
    "id": "6105152003",
    "district_id": "610515",
    "label": "Nanga Kesange",
    "value": "Nanga Kesange"
  },
  {
    "id": "6105152004",
    "district_id": "610515",
    "label": "Nanga Menakon",
    "value": "Nanga Menakon"
  },
  {
    "id": "6105152005",
    "district_id": "610515",
    "label": "Nanga Sake",
    "value": "Nanga Sake"
  },
  {
    "id": "6105152006",
    "district_id": "610515",
    "label": "Buntut Pimpin",
    "value": "Buntut Pimpin"
  },
  {
    "id": "6105152007",
    "district_id": "610515",
    "label": "Kepala Jungai",
    "value": "Kepala Jungai"
  },
  {
    "id": "6105152008",
    "district_id": "610515",
    "label": "Tanjung Andan",
    "value": "Tanjung Andan"
  },
  {
    "id": "6105152009",
    "district_id": "610515",
    "label": "Buntut Sabon",
    "value": "Buntut Sabon"
  },
  {
    "id": "6105152010",
    "district_id": "610515",
    "label": "Nanga Keremoi",
    "value": "Nanga Keremoi"
  },
  {
    "id": "6105152011",
    "district_id": "610515",
    "label": "Nanga Menantak",
    "value": "Nanga Menantak"
  },
  {
    "id": "6105152012",
    "district_id": "610515",
    "label": "Buntut Purun",
    "value": "Buntut Purun"
  },
  {
    "id": "6105152013",
    "district_id": "610515",
    "label": "Nanga Sakai",
    "value": "Nanga Sakai"
  },
  {
    "id": "6105152014",
    "district_id": "610515",
    "label": "Nanga Mentomoi",
    "value": "Nanga Mentomoi"
  },
  {
    "id": "6105152015",
    "district_id": "610515",
    "label": "Sungai Tambun",
    "value": "Sungai Tambun"
  },
  {
    "id": "6105152016",
    "district_id": "610515",
    "label": "Riam Sabon",
    "value": "Riam Sabon"
  },
  {
    "id": "6105152017",
    "district_id": "610515",
    "label": "Nanga Ukai",
    "value": "Nanga Ukai"
  },
  {
    "id": "6105152018",
    "district_id": "610515",
    "label": "Mensuang",
    "value": "Mensuang"
  },
  {
    "id": "6105152019",
    "district_id": "610515",
    "label": "Dahtah Bungai",
    "value": "Dahtah Bungai"
  },
  {
    "id": "6105152020",
    "district_id": "610515",
    "label": "Lunjan Tingang",
    "value": "Lunjan Tingang"
  },
  {
    "id": "6105152021",
    "district_id": "610515",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "6105152022",
    "district_id": "610515",
    "label": "Bukai Tukun",
    "value": "Bukai Tukun"
  },
  {
    "id": "6105152023",
    "district_id": "610515",
    "label": "Nusa Koring",
    "value": "Nusa Koring"
  },
  {
    "id": "6105152024",
    "district_id": "610515",
    "label": "Purut Beribit",
    "value": "Purut Beribit"
  },
  {
    "id": "6105152025",
    "district_id": "610515",
    "label": "Pulou Sabhang",
    "value": "Pulou Sabhang"
  },
  {
    "id": "6105152026",
    "district_id": "610515",
    "label": "Patih Jepara",
    "value": "Patih Jepara"
  },
  {
    "id": "6105152027",
    "district_id": "610515",
    "label": "Korong Daso",
    "value": "Korong Daso"
  },
  {
    "id": "6105152028",
    "district_id": "610515",
    "label": "Luting Mingan",
    "value": "Luting Mingan"
  },
  {
    "id": "6105152029",
    "district_id": "610515",
    "label": "Kolangan Juoi",
    "value": "Kolangan Juoi"
  },
  {
    "id": "6105152030",
    "district_id": "610515",
    "label": "Nanga Rade",
    "value": "Nanga Rade"
  },
  {
    "id": "6105152031",
    "district_id": "610515",
    "label": "Jengkarang",
    "value": "Jengkarang"
  },
  {
    "id": "6105152032",
    "district_id": "610515",
    "label": "Deme",
    "value": "Deme"
  },
  {
    "id": "6105152033",
    "district_id": "610515",
    "label": "Nanga Pahangan",
    "value": "Nanga Pahangan"
  },
  {
    "id": "6105192001",
    "district_id": "610519",
    "label": "Nanga Lebang",
    "value": "Nanga Lebang"
  },
  {
    "id": "6105192002",
    "district_id": "610519",
    "label": "Merpak",
    "value": "Merpak"
  },
  {
    "id": "6105192003",
    "district_id": "610519",
    "label": "Kebong",
    "value": "Kebong"
  },
  {
    "id": "6105192004",
    "district_id": "610519",
    "label": "Baning Panjang",
    "value": "Baning Panjang"
  },
  {
    "id": "6105192005",
    "district_id": "610519",
    "label": "Ensaid Panjang",
    "value": "Ensaid Panjang"
  },
  {
    "id": "6105192006",
    "district_id": "610519",
    "label": "Bengkuang",
    "value": "Bengkuang"
  },
  {
    "id": "6105192007",
    "district_id": "610519",
    "label": "Pelimping",
    "value": "Pelimping"
  },
  {
    "id": "6105192008",
    "district_id": "610519",
    "label": "Sungai Pukat",
    "value": "Sungai Pukat"
  },
  {
    "id": "6105192009",
    "district_id": "610519",
    "label": "Sungai Maram",
    "value": "Sungai Maram"
  },
  {
    "id": "6105192010",
    "district_id": "610519",
    "label": "Sepan Lebang",
    "value": "Sepan Lebang"
  },
  {
    "id": "6105192011",
    "district_id": "610519",
    "label": "Gemba Raya",
    "value": "Gemba Raya"
  },
  {
    "id": "6105192012",
    "district_id": "610519",
    "label": "Karya Jaya Bhakti",
    "value": "Karya Jaya Bhakti"
  },
  {
    "id": "6105192013",
    "district_id": "610519",
    "label": "Mandiri Jaya",
    "value": "Mandiri Jaya"
  },
  {
    "id": "6105192014",
    "district_id": "610519",
    "label": "Landau Kodam",
    "value": "Landau Kodam"
  },
  {
    "id": "6105192015",
    "district_id": "610519",
    "label": "Sungai Labi",
    "value": "Sungai Labi"
  },
  {
    "id": "6105192016",
    "district_id": "610519",
    "label": "Sungai Lais",
    "value": "Sungai Lais"
  },
  {
    "id": "6105192017",
    "district_id": "610519",
    "label": "Kelam Sejahtera",
    "value": "Kelam Sejahtera"
  },
  {
    "id": "6105202001",
    "district_id": "610520",
    "label": "Ransi Dakan",
    "value": "Ransi Dakan"
  },
  {
    "id": "6105202002",
    "district_id": "610520",
    "label": "Sungai Ukoi",
    "value": "Sungai Ukoi"
  },
  {
    "id": "6105202003",
    "district_id": "610520",
    "label": "Manter",
    "value": "Manter"
  },
  {
    "id": "6105202004",
    "district_id": "610520",
    "label": "Merarai Satu",
    "value": "Merarai Satu"
  },
  {
    "id": "6105202005",
    "district_id": "610520",
    "label": "Merarai Dua",
    "value": "Merarai Dua"
  },
  {
    "id": "6105202006",
    "district_id": "610520",
    "label": "Kajang Baru",
    "value": "Kajang Baru"
  },
  {
    "id": "6105202007",
    "district_id": "610520",
    "label": "Gurung Kempadik",
    "value": "Gurung Kempadik"
  },
  {
    "id": "6105202008",
    "district_id": "610520",
    "label": "Nobal",
    "value": "Nobal"
  },
  {
    "id": "6105202009",
    "district_id": "610520",
    "label": "Baya Betung",
    "value": "Baya Betung"
  },
  {
    "id": "6105202010",
    "district_id": "610520",
    "label": "Bonet Engkabang",
    "value": "Bonet Engkabang"
  },
  {
    "id": "6105202011",
    "district_id": "610520",
    "label": "Bonet Lama",
    "value": "Bonet Lama"
  },
  {
    "id": "6105202012",
    "district_id": "610520",
    "label": "Bancoh",
    "value": "Bancoh"
  },
  {
    "id": "6105202013",
    "district_id": "610520",
    "label": "Perembang",
    "value": "Perembang"
  },
  {
    "id": "6105202014",
    "district_id": "610520",
    "label": "Solam Raya",
    "value": "Solam Raya"
  },
  {
    "id": "6105202015",
    "district_id": "610520",
    "label": "Lebak Ubah",
    "value": "Lebak Ubah"
  },
  {
    "id": "6105202016",
    "district_id": "610520",
    "label": "Melayang Sari",
    "value": "Melayang Sari"
  },
  {
    "id": "6105202017",
    "district_id": "610520",
    "label": "Sarai",
    "value": "Sarai"
  },
  {
    "id": "6105202018",
    "district_id": "610520",
    "label": "Rarai",
    "value": "Rarai"
  },
  {
    "id": "6105202019",
    "district_id": "610520",
    "label": "Penjernang",
    "value": "Penjernang"
  },
  {
    "id": "6105202020",
    "district_id": "610520",
    "label": "Penjernang Hulu",
    "value": "Penjernang Hulu"
  },
  {
    "id": "6105202021",
    "district_id": "610520",
    "label": "Riam Kijang",
    "value": "Riam Kijang"
  },
  {
    "id": "6105202022",
    "district_id": "610520",
    "label": "Baya Mulya",
    "value": "Baya Mulya"
  },
  {
    "id": "6105202023",
    "district_id": "610520",
    "label": "Laman Raya",
    "value": "Laman Raya"
  },
  {
    "id": "6105202024",
    "district_id": "610520",
    "label": "Sabang Surai",
    "value": "Sabang Surai"
  },
  {
    "id": "6105202025",
    "district_id": "610520",
    "label": "Balai Agung",
    "value": "Balai Agung"
  },
  {
    "id": "6105202026",
    "district_id": "610520",
    "label": "Kunyai",
    "value": "Kunyai"
  },
  {
    "id": "6105212001",
    "district_id": "610521",
    "label": "Ampar Bedang",
    "value": "Ampar Bedang"
  },
  {
    "id": "6105212002",
    "district_id": "610521",
    "label": "Telaga Satu",
    "value": "Telaga Satu"
  },
  {
    "id": "6105212003",
    "district_id": "610521",
    "label": "Telaga Dua",
    "value": "Telaga Dua"
  },
  {
    "id": "6105212004",
    "district_id": "610521",
    "label": "Binjai Hilir",
    "value": "Binjai Hilir"
  },
  {
    "id": "6105212005",
    "district_id": "610521",
    "label": "Binjai Hulu",
    "value": "Binjai Hulu"
  },
  {
    "id": "6105212006",
    "district_id": "610521",
    "label": "Sungai Risap",
    "value": "Sungai Risap"
  },
  {
    "id": "6105212007",
    "district_id": "610521",
    "label": "Dak Jaya",
    "value": "Dak Jaya"
  },
  {
    "id": "6105212008",
    "district_id": "610521",
    "label": "Mensiku",
    "value": "Mensiku"
  },
  {
    "id": "6105212009",
    "district_id": "610521",
    "label": "Empaka Kabiau Raya",
    "value": "Empaka Kabiau Raya"
  },
  {
    "id": "6105212010",
    "district_id": "610521",
    "label": "Sungai Risap Mensiku Bersatu",
    "value": "Sungai Risap Mensiku Bersatu"
  },
  {
    "id": "6105212011",
    "district_id": "610521",
    "label": "Simba Raya",
    "value": "Simba Raya"
  },
  {
    "id": "6106011001",
    "district_id": "610601",
    "label": "Putussibau Kota",
    "value": "Putussibau Kota"
  },
  {
    "id": "6106011002",
    "district_id": "610601",
    "label": "Hilir Kantor",
    "value": "Hilir Kantor"
  },
  {
    "id": "6106012004",
    "district_id": "610601",
    "label": "Pala Pulau",
    "value": "Pala Pulau"
  },
  {
    "id": "6106012005",
    "district_id": "610601",
    "label": "Sibau Hilir",
    "value": "Sibau Hilir"
  },
  {
    "id": "6106012006",
    "district_id": "610601",
    "label": "Padua Mendalam",
    "value": "Padua Mendalam"
  },
  {
    "id": "6106012007",
    "district_id": "610601",
    "label": "Datah Dian",
    "value": "Datah Dian"
  },
  {
    "id": "6106012008",
    "district_id": "610601",
    "label": "Sibau Hulu",
    "value": "Sibau Hulu"
  },
  {
    "id": "6106012009",
    "district_id": "610601",
    "label": "Nanga Nyabau",
    "value": "Nanga Nyabau"
  },
  {
    "id": "6106012010",
    "district_id": "610601",
    "label": "Nanga Awin",
    "value": "Nanga Awin"
  },
  {
    "id": "6106012011",
    "district_id": "610601",
    "label": "Ariung Mendalam",
    "value": "Ariung Mendalam"
  },
  {
    "id": "6106012012",
    "district_id": "610601",
    "label": "Nanga Sambus",
    "value": "Nanga Sambus"
  },
  {
    "id": "6106012013",
    "district_id": "610601",
    "label": "Banua Tanga",
    "value": "Banua Tanga"
  },
  {
    "id": "6106012014",
    "district_id": "610601",
    "label": "Tanjung Beruang",
    "value": "Tanjung Beruang"
  },
  {
    "id": "6106012015",
    "district_id": "610601",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "6106012016",
    "district_id": "610601",
    "label": "Seluan",
    "value": "Seluan"
  },
  {
    "id": "6106012017",
    "district_id": "610601",
    "label": "Sungai Uluk Palin",
    "value": "Sungai Uluk Palin"
  },
  {
    "id": "6106012018",
    "district_id": "610601",
    "label": "Tanjung Lasa",
    "value": "Tanjung Lasa"
  },
  {
    "id": "6106012019",
    "district_id": "610601",
    "label": "Lauk",
    "value": "Lauk"
  },
  {
    "id": "6106012020",
    "district_id": "610601",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  {
    "id": "6106022001",
    "district_id": "610602",
    "label": "Teluk Sindur",
    "value": "Teluk Sindur"
  },
  {
    "id": "6106022002",
    "district_id": "610602",
    "label": "Bika",
    "value": "Bika"
  },
  {
    "id": "6106022003",
    "district_id": "610602",
    "label": "Jelemuk",
    "value": "Jelemuk"
  },
  {
    "id": "6106022008",
    "district_id": "610602",
    "label": "Bika Hulu",
    "value": "Bika Hulu"
  },
  {
    "id": "6106022009",
    "district_id": "610602",
    "label": "Nanga Manday",
    "value": "Nanga Manday"
  },
  {
    "id": "6106022010",
    "district_id": "610602",
    "label": "Melapi Manday",
    "value": "Melapi Manday"
  },
  {
    "id": "6106022011",
    "district_id": "610602",
    "label": "Penyeluang",
    "value": "Penyeluang"
  },
  {
    "id": "6106022012",
    "district_id": "610602",
    "label": "Jongkong Manday",
    "value": "Jongkong Manday"
  },
  {
    "id": "6106032001",
    "district_id": "610603",
    "label": "Nanga Embaloh",
    "value": "Nanga Embaloh"
  },
  {
    "id": "6106032002",
    "district_id": "610603",
    "label": "Keliling Semulung",
    "value": "Keliling Semulung"
  },
  {
    "id": "6106032006",
    "district_id": "610603",
    "label": "Lawik",
    "value": "Lawik"
  },
  {
    "id": "6106032007",
    "district_id": "610603",
    "label": "Nanga Lauk",
    "value": "Nanga Lauk"
  },
  {
    "id": "6106032008",
    "district_id": "610603",
    "label": "Nanga Palin",
    "value": "Nanga Palin"
  },
  {
    "id": "6106032009",
    "district_id": "610603",
    "label": "Kirin Nangka",
    "value": "Kirin Nangka"
  },
  {
    "id": "6106032010",
    "district_id": "610603",
    "label": "Pala Pintas",
    "value": "Pala Pintas"
  },
  {
    "id": "6106032011",
    "district_id": "610603",
    "label": "Ujung Bayur",
    "value": "Ujung Bayur"
  },
  {
    "id": "6106032012",
    "district_id": "610603",
    "label": "Belatung",
    "value": "Belatung"
  },
  {
    "id": "6106042001",
    "district_id": "610604",
    "label": "Banua Martinus",
    "value": "Banua Martinus"
  },
  {
    "id": "6106042002",
    "district_id": "610604",
    "label": "Menua Sadap",
    "value": "Menua Sadap"
  },
  {
    "id": "6106042003",
    "district_id": "610604",
    "label": "Pulau Manak",
    "value": "Pulau Manak"
  },
  {
    "id": "6106042004",
    "district_id": "610604",
    "label": "Banua Ujung",
    "value": "Banua Ujung"
  },
  {
    "id": "6106042005",
    "district_id": "610604",
    "label": "Saujung Giling Manik",
    "value": "Saujung Giling Manik"
  },
  {
    "id": "6106042006",
    "district_id": "610604",
    "label": "Ulak Pauk",
    "value": "Ulak Pauk"
  },
  {
    "id": "6106042007",
    "district_id": "610604",
    "label": "Langan Baru",
    "value": "Langan Baru"
  },
  {
    "id": "6106042008",
    "district_id": "610604",
    "label": "Batu Lintang",
    "value": "Batu Lintang"
  },
  {
    "id": "6106042009",
    "district_id": "610604",
    "label": "Rantau Prapat",
    "value": "Rantau Prapat"
  },
  {
    "id": "6106042010",
    "district_id": "610604",
    "label": "Tamao",
    "value": "Tamao"
  },
  {
    "id": "6106052001",
    "district_id": "610605",
    "label": "Bunut Hilir",
    "value": "Bunut Hilir"
  },
  {
    "id": "6106052002",
    "district_id": "610605",
    "label": "Bunut Hulu",
    "value": "Bunut Hulu"
  },
  {
    "id": "6106052003",
    "district_id": "610605",
    "label": "Teluk Aur",
    "value": "Teluk Aur"
  },
  {
    "id": "6106052004",
    "district_id": "610605",
    "label": "Nanga Tuan",
    "value": "Nanga Tuan"
  },
  {
    "id": "6106052006",
    "district_id": "610605",
    "label": "Ujung Pandang",
    "value": "Ujung Pandang"
  },
  {
    "id": "6106052007",
    "district_id": "610605",
    "label": "Empangau",
    "value": "Empangau"
  },
  {
    "id": "6106052008",
    "district_id": "610605",
    "label": "Tembang",
    "value": "Tembang"
  },
  {
    "id": "6106052009",
    "district_id": "610605",
    "label": "Bunut Tengah",
    "value": "Bunut Tengah"
  },
  {
    "id": "6106052010",
    "district_id": "610605",
    "label": "Entibab",
    "value": "Entibab"
  },
  {
    "id": "6106052011",
    "district_id": "610605",
    "label": "Kapuas Raya",
    "value": "Kapuas Raya"
  },
  {
    "id": "6106052012",
    "district_id": "610605",
    "label": "Empangau Hilir",
    "value": "Empangau Hilir"
  },
  {
    "id": "6106062001",
    "district_id": "610606",
    "label": "Nanga Suruk",
    "value": "Nanga Suruk"
  },
  {
    "id": "6106062002",
    "district_id": "610606",
    "label": "Temuyuk",
    "value": "Temuyuk"
  },
  {
    "id": "6106062003",
    "district_id": "610606",
    "label": "Nanga Semangut",
    "value": "Nanga Semangut"
  },
  {
    "id": "6106062004",
    "district_id": "610606",
    "label": "Nanga Dua",
    "value": "Nanga Dua"
  },
  {
    "id": "6106062005",
    "district_id": "610606",
    "label": "Selaup",
    "value": "Selaup"
  },
  {
    "id": "6106062006",
    "district_id": "610606",
    "label": "Riam Piyang",
    "value": "Riam Piyang"
  },
  {
    "id": "6106062007",
    "district_id": "610606",
    "label": "Nanga Payang",
    "value": "Nanga Payang"
  },
  {
    "id": "6106062008",
    "district_id": "610606",
    "label": "Batu Tiga",
    "value": "Batu Tiga"
  },
  {
    "id": "6106062009",
    "district_id": "610606",
    "label": "Semangut Utara",
    "value": "Semangut Utara"
  },
  {
    "id": "6106062010",
    "district_id": "610606",
    "label": "Segitak",
    "value": "Segitak"
  },
  {
    "id": "6106062011",
    "district_id": "610606",
    "label": "Nanga Kelibang",
    "value": "Nanga Kelibang"
  },
  {
    "id": "6106062012",
    "district_id": "610606",
    "label": "Sungai Besar",
    "value": "Sungai Besar"
  },
  {
    "id": "6106062013",
    "district_id": "610606",
    "label": "Bakong Permai",
    "value": "Bakong Permai"
  },
  {
    "id": "6106062014",
    "district_id": "610606",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6106062015",
    "district_id": "610606",
    "label": "Pantas Bersatu",
    "value": "Pantas Bersatu"
  },
  {
    "id": "6106072001",
    "district_id": "610607",
    "label": "Jongkong Kiri Hilir",
    "value": "Jongkong Kiri Hilir"
  },
  {
    "id": "6106072002",
    "district_id": "610607",
    "label": "Jongkong Kanan",
    "value": "Jongkong Kanan"
  },
  {
    "id": "6106072003",
    "district_id": "610607",
    "label": "Jongkong Kiri Hulu",
    "value": "Jongkong Kiri Hulu"
  },
  {
    "id": "6106072004",
    "district_id": "610607",
    "label": "Jongkong Pasar",
    "value": "Jongkong Pasar"
  },
  {
    "id": "6106072005",
    "district_id": "610607",
    "label": "Ujung Said",
    "value": "Ujung Said"
  },
  {
    "id": "6106072006",
    "district_id": "610607",
    "label": "Temenang",
    "value": "Temenang"
  },
  {
    "id": "6106072007",
    "district_id": "610607",
    "label": "Ujung Jambu",
    "value": "Ujung Jambu"
  },
  {
    "id": "6106072008",
    "district_id": "610607",
    "label": "Bontai",
    "value": "Bontai"
  },
  {
    "id": "6106072009",
    "district_id": "610607",
    "label": "Jongkong Kiri Tengah",
    "value": "Jongkong Kiri Tengah"
  },
  {
    "id": "6106072010",
    "district_id": "610607",
    "label": "Jongkong Tanjung",
    "value": "Jongkong Tanjung"
  },
  {
    "id": "6106072011",
    "district_id": "610607",
    "label": "Kandung Suli",
    "value": "Kandung Suli"
  },
  {
    "id": "6106072012",
    "district_id": "610607",
    "label": "Penepian Raya",
    "value": "Penepian Raya"
  },
  {
    "id": "6106072013",
    "district_id": "610607",
    "label": "Karya baru",
    "value": "Karya baru"
  },
  {
    "id": "6106072014",
    "district_id": "610607",
    "label": "Nanga Serian",
    "value": "Nanga Serian"
  },
  {
    "id": "6106082001",
    "district_id": "610608",
    "label": "Nanga Tepuai",
    "value": "Nanga Tepuai"
  },
  {
    "id": "6106082002",
    "district_id": "610608",
    "label": "Landau Kumpang",
    "value": "Landau Kumpang"
  },
  {
    "id": "6106082003",
    "district_id": "610608",
    "label": "Nanga Yen",
    "value": "Nanga Yen"
  },
  {
    "id": "6106082004",
    "district_id": "610608",
    "label": "Lubuk Antuk",
    "value": "Lubuk Antuk"
  },
  {
    "id": "6106082005",
    "district_id": "610608",
    "label": "Parang",
    "value": "Parang"
  },
  {
    "id": "6106082006",
    "district_id": "610608",
    "label": "Kelakar",
    "value": "Kelakar"
  },
  {
    "id": "6106082007",
    "district_id": "610608",
    "label": "Bugang",
    "value": "Bugang"
  },
  {
    "id": "6106082008",
    "district_id": "610608",
    "label": "Mentawit",
    "value": "Mentawit"
  },
  {
    "id": "6106082009",
    "district_id": "610608",
    "label": "Tani Makmur",
    "value": "Tani Makmur"
  },
  {
    "id": "6106082010",
    "district_id": "610608",
    "label": "Simpang Senara",
    "value": "Simpang Senara"
  },
  {
    "id": "6106082011",
    "district_id": "610608",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6106082012",
    "district_id": "610608",
    "label": "Sejahtera Mandiri",
    "value": "Sejahtera Mandiri"
  },
  {
    "id": "6106082013",
    "district_id": "610608",
    "label": "Mubung",
    "value": "Mubung"
  },
  {
    "id": "6106082014",
    "district_id": "610608",
    "label": "Tunas Muda",
    "value": "Tunas Muda"
  },
  {
    "id": "6106082015",
    "district_id": "610608",
    "label": "Karya Mandiri",
    "value": "Karya Mandiri"
  },
  {
    "id": "6106092001",
    "district_id": "610609",
    "label": "Gudang Hulu",
    "value": "Gudang Hulu"
  },
  {
    "id": "6106092002",
    "district_id": "610609",
    "label": "Gudang Hilir",
    "value": "Gudang Hilir"
  },
  {
    "id": "6106092003",
    "district_id": "610609",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "6106092004",
    "district_id": "610609",
    "label": "Nibung",
    "value": "Nibung"
  },
  {
    "id": "6106092005",
    "district_id": "610609",
    "label": "Piasak Hilir",
    "value": "Piasak Hilir"
  },
  {
    "id": "6106092006",
    "district_id": "610609",
    "label": "Engkerengas",
    "value": "Engkerengas"
  },
  {
    "id": "6106092007",
    "district_id": "610609",
    "label": "Gerayau",
    "value": "Gerayau"
  },
  {
    "id": "6106092008",
    "district_id": "610609",
    "label": "Benuis",
    "value": "Benuis"
  },
  {
    "id": "6106092012",
    "district_id": "610609",
    "label": "Titian Kuala",
    "value": "Titian Kuala"
  },
  {
    "id": "6106092013",
    "district_id": "610609",
    "label": "Sekubah",
    "value": "Sekubah"
  },
  {
    "id": "6106092014",
    "district_id": "610609",
    "label": "Piasak Hulu",
    "value": "Piasak Hulu"
  },
  {
    "id": "6106092015",
    "district_id": "610609",
    "label": "Sekulat",
    "value": "Sekulat"
  },
  {
    "id": "6106092016",
    "district_id": "610609",
    "label": "Nanga Leboyan",
    "value": "Nanga Leboyan"
  },
  {
    "id": "6106092017",
    "district_id": "610609",
    "label": "Vega",
    "value": "Vega"
  },
  {
    "id": "6106092018",
    "district_id": "610609",
    "label": "Mawan",
    "value": "Mawan"
  },
  {
    "id": "6106092019",
    "district_id": "610609",
    "label": "Semalah",
    "value": "Semalah"
  },
  {
    "id": "6106092020",
    "district_id": "610609",
    "label": "Tempurau",
    "value": "Tempurau"
  },
  {
    "id": "6106102001",
    "district_id": "610610",
    "label": "Semitau Hulu",
    "value": "Semitau Hulu"
  },
  {
    "id": "6106102002",
    "district_id": "610610",
    "label": "Kenerak",
    "value": "Kenerak"
  },
  {
    "id": "6106102003",
    "district_id": "610610",
    "label": "Nanga Seberuang",
    "value": "Nanga Seberuang"
  },
  {
    "id": "6106102004",
    "district_id": "610610",
    "label": "Entipan",
    "value": "Entipan"
  },
  {
    "id": "6106102005",
    "district_id": "610610",
    "label": "Kenepai Komplek",
    "value": "Kenepai Komplek"
  },
  {
    "id": "6106102006",
    "district_id": "610610",
    "label": "Semitau Hilir",
    "value": "Semitau Hilir"
  },
  {
    "id": "6106102007",
    "district_id": "610610",
    "label": "Nanga Kenepai",
    "value": "Nanga Kenepai"
  },
  {
    "id": "6106102008",
    "district_id": "610610",
    "label": "Tua' Abang",
    "value": "Tua' Abang"
  },
  {
    "id": "6106102009",
    "district_id": "610610",
    "label": "Nanga Lemedak",
    "value": "Nanga Lemedak"
  },
  {
    "id": "6106102010",
    "district_id": "610610",
    "label": "Marsedan Raya",
    "value": "Marsedan Raya"
  },
  {
    "id": "6106102011",
    "district_id": "610610",
    "label": "Padung Kumang",
    "value": "Padung Kumang"
  },
  {
    "id": "6106102012",
    "district_id": "610610",
    "label": "Sekedau",
    "value": "Sekedau"
  },
  {
    "id": "6106112001",
    "district_id": "610611",
    "label": "Sejiram",
    "value": "Sejiram"
  },
  {
    "id": "6106112002",
    "district_id": "610611",
    "label": "Beluis Harum",
    "value": "Beluis Harum"
  },
  {
    "id": "6106112003",
    "district_id": "610611",
    "label": "Gurung",
    "value": "Gurung"
  },
  {
    "id": "6106112004",
    "district_id": "610611",
    "label": "Bati",
    "value": "Bati"
  },
  {
    "id": "6106112005",
    "district_id": "610611",
    "label": "Seneban",
    "value": "Seneban"
  },
  {
    "id": "6106112006",
    "district_id": "610611",
    "label": "Belikai",
    "value": "Belikai"
  },
  {
    "id": "6106112007",
    "district_id": "610611",
    "label": "Ranyai",
    "value": "Ranyai"
  },
  {
    "id": "6106112008",
    "district_id": "610611",
    "label": "Emperiang",
    "value": "Emperiang"
  },
  {
    "id": "6106112009",
    "district_id": "610611",
    "label": "Pala Kota",
    "value": "Pala Kota"
  },
  {
    "id": "6106112010",
    "district_id": "610611",
    "label": "Nanga Lot",
    "value": "Nanga Lot"
  },
  {
    "id": "6106112011",
    "district_id": "610611",
    "label": "Tanjung Keliling",
    "value": "Tanjung Keliling"
  },
  {
    "id": "6106112012",
    "district_id": "610611",
    "label": "Tajau Mada",
    "value": "Tajau Mada"
  },
  {
    "id": "6106112013",
    "district_id": "610611",
    "label": "Jerenjang",
    "value": "Jerenjang"
  },
  {
    "id": "6106112014",
    "district_id": "610611",
    "label": "Bekuan",
    "value": "Bekuan"
  },
  {
    "id": "6106112015",
    "district_id": "610611",
    "label": "Nanga Pala",
    "value": "Nanga Pala"
  },
  {
    "id": "6106122001",
    "district_id": "610612",
    "label": "Setulang",
    "value": "Setulang"
  },
  {
    "id": "6106122002",
    "district_id": "610612",
    "label": "Sepandan",
    "value": "Sepandan"
  },
  {
    "id": "6106122003",
    "district_id": "610612",
    "label": "Sungai Ajung",
    "value": "Sungai Ajung"
  },
  {
    "id": "6106122004",
    "district_id": "610612",
    "label": "Sungai Abau",
    "value": "Sungai Abau"
  },
  {
    "id": "6106122005",
    "district_id": "610612",
    "label": "Labian",
    "value": "Labian"
  },
  {
    "id": "6106122006",
    "district_id": "610612",
    "label": "Mensiau",
    "value": "Mensiau"
  },
  {
    "id": "6106122007",
    "district_id": "610612",
    "label": "Melemba",
    "value": "Melemba"
  },
  {
    "id": "6106122008",
    "district_id": "610612",
    "label": "Lanjak Deras",
    "value": "Lanjak Deras"
  },
  {
    "id": "6106122009",
    "district_id": "610612",
    "label": "Sungai Senunuk",
    "value": "Sungai Senunuk"
  },
  {
    "id": "6106122010",
    "district_id": "610612",
    "label": "Labian Ira'ang",
    "value": "Labian Ira'ang"
  },
  {
    "id": "6106132001",
    "district_id": "610613",
    "label": "Keling Panggau",
    "value": "Keling Panggau"
  },
  {
    "id": "6106132002",
    "district_id": "610613",
    "label": "Nanga Kantuk",
    "value": "Nanga Kantuk"
  },
  {
    "id": "6106132003",
    "district_id": "610613",
    "label": "Tintin Peninjau",
    "value": "Tintin Peninjau"
  },
  {
    "id": "6106132004",
    "district_id": "610613",
    "label": "Bajau Andai",
    "value": "Bajau Andai"
  },
  {
    "id": "6106132005",
    "district_id": "610613",
    "label": "Kumang Jaya",
    "value": "Kumang Jaya"
  },
  {
    "id": "6106132006",
    "district_id": "610613",
    "label": "Laja Sandang",
    "value": "Laja Sandang"
  },
  {
    "id": "6106142001",
    "district_id": "610614",
    "label": "Badau",
    "value": "Badau"
  },
  {
    "id": "6106142002",
    "district_id": "610614",
    "label": "Janting",
    "value": "Janting"
  },
  {
    "id": "6106142003",
    "district_id": "610614",
    "label": "Semuntik",
    "value": "Semuntik"
  },
  {
    "id": "6106142004",
    "district_id": "610614",
    "label": "Kekurak",
    "value": "Kekurak"
  },
  {
    "id": "6106142005",
    "district_id": "610614",
    "label": "Pulau Majang",
    "value": "Pulau Majang"
  },
  {
    "id": "6106142006",
    "district_id": "610614",
    "label": "Seriang",
    "value": "Seriang"
  },
  {
    "id": "6106142007",
    "district_id": "610614",
    "label": "Sebindang",
    "value": "Sebindang"
  },
  {
    "id": "6106142008",
    "district_id": "610614",
    "label": "Tinting Seligi",
    "value": "Tinting Seligi"
  },
  {
    "id": "6106142009",
    "district_id": "610614",
    "label": "Tajum",
    "value": "Tajum"
  },
  {
    "id": "6106152001",
    "district_id": "610615",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "6106152002",
    "district_id": "610615",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6106152003",
    "district_id": "610615",
    "label": "Pangeran",
    "value": "Pangeran"
  },
  {
    "id": "6106152004",
    "district_id": "610615",
    "label": "Miau Merah",
    "value": "Miau Merah"
  },
  {
    "id": "6106152005",
    "district_id": "610615",
    "label": "Setunggul",
    "value": "Setunggul"
  },
  {
    "id": "6106152006",
    "district_id": "610615",
    "label": "Sungai Sena",
    "value": "Sungai Sena"
  },
  {
    "id": "6106152007",
    "district_id": "610615",
    "label": "Penai",
    "value": "Penai"
  },
  {
    "id": "6106152008",
    "district_id": "610615",
    "label": "Nanga Nuar",
    "value": "Nanga Nuar"
  },
  {
    "id": "6106152009",
    "district_id": "610615",
    "label": "Bongkong",
    "value": "Bongkong"
  },
  {
    "id": "6106152010",
    "district_id": "610615",
    "label": "Seberu",
    "value": "Seberu"
  },
  {
    "id": "6106152011",
    "district_id": "610615",
    "label": "Sentabai",
    "value": "Sentabai"
  },
  {
    "id": "6106152012",
    "district_id": "610615",
    "label": "Rumbih",
    "value": "Rumbih"
  },
  {
    "id": "6106152013",
    "district_id": "610615",
    "label": "Bukit Penai",
    "value": "Bukit Penai"
  },
  {
    "id": "6106162001",
    "district_id": "610616",
    "label": "Nanga Dangkan",
    "value": "Nanga Dangkan"
  },
  {
    "id": "6106162002",
    "district_id": "610616",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "6106162003",
    "district_id": "610616",
    "label": "Nanga Ngeri",
    "value": "Nanga Ngeri"
  },
  {
    "id": "6106162004",
    "district_id": "610616",
    "label": "Landau Badai",
    "value": "Landau Badai"
  },
  {
    "id": "6106162005",
    "district_id": "610616",
    "label": "Nanga Lungu",
    "value": "Nanga Lungu"
  },
  {
    "id": "6106162006",
    "district_id": "610616",
    "label": "Nanga Luan",
    "value": "Nanga Luan"
  },
  {
    "id": "6106162007",
    "district_id": "610616",
    "label": "Riam Tapang",
    "value": "Riam Tapang"
  },
  {
    "id": "6106162008",
    "district_id": "610616",
    "label": "Lebak Najah",
    "value": "Lebak Najah"
  },
  {
    "id": "6106162009",
    "district_id": "610616",
    "label": "Dangkan Kota",
    "value": "Dangkan Kota"
  },
  {
    "id": "6106162010",
    "district_id": "610616",
    "label": "Perjuk",
    "value": "Perjuk"
  },
  {
    "id": "6106162011",
    "district_id": "610616",
    "label": "Landau Rantau",
    "value": "Landau Rantau"
  },
  {
    "id": "6106162012",
    "district_id": "610616",
    "label": "Entebi",
    "value": "Entebi"
  },
  {
    "id": "6106162013",
    "district_id": "610616",
    "label": "Selangkai",
    "value": "Selangkai"
  },
  {
    "id": "6106162014",
    "district_id": "610616",
    "label": "Selimu",
    "value": "Selimu"
  },
  {
    "id": "6106171001",
    "district_id": "610617",
    "label": "Kedamin Hulu",
    "value": "Kedamin Hulu"
  },
  {
    "id": "6106171002",
    "district_id": "610617",
    "label": "Kedamin Hilir",
    "value": "Kedamin Hilir"
  },
  {
    "id": "6106172003",
    "district_id": "610617",
    "label": "Sungai Uluk",
    "value": "Sungai Uluk"
  },
  {
    "id": "6106172004",
    "district_id": "610617",
    "label": "Jaras",
    "value": "Jaras"
  },
  {
    "id": "6106172005",
    "district_id": "610617",
    "label": "Melapi",
    "value": "Melapi"
  },
  {
    "id": "6106172014",
    "district_id": "610617",
    "label": "Kedamin Darat",
    "value": "Kedamin Darat"
  },
  {
    "id": "6106172015",
    "district_id": "610617",
    "label": "Tanjung Jati",
    "value": "Tanjung Jati"
  },
  {
    "id": "6106172016",
    "district_id": "610617",
    "label": "Sayut",
    "value": "Sayut"
  },
  {
    "id": "6106172017",
    "district_id": "610617",
    "label": "Urang Unsa",
    "value": "Urang Unsa"
  },
  {
    "id": "6106172018",
    "district_id": "610617",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6106172019",
    "district_id": "610617",
    "label": "Cempaka Baru",
    "value": "Cempaka Baru"
  },
  {
    "id": "6106172020",
    "district_id": "610617",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "6106172021",
    "district_id": "610617",
    "label": "Bungan Jaya",
    "value": "Bungan Jaya"
  },
  {
    "id": "6106172022",
    "district_id": "610617",
    "label": "Tanjunglokang",
    "value": "Tanjunglokang"
  },
  {
    "id": "6106172023",
    "district_id": "610617",
    "label": "Kereho",
    "value": "Kereho"
  },
  {
    "id": "6106172024",
    "district_id": "610617",
    "label": "Ingko' Tambe",
    "value": "Ingko' Tambe"
  },
  {
    "id": "6106182001",
    "district_id": "610618",
    "label": "Nanga Sebintang",
    "value": "Nanga Sebintang"
  },
  {
    "id": "6106182002",
    "district_id": "610618",
    "label": "Nanga Lebangan",
    "value": "Nanga Lebangan"
  },
  {
    "id": "6106182003",
    "district_id": "610618",
    "label": "Nanga Kalis",
    "value": "Nanga Kalis"
  },
  {
    "id": "6106182004",
    "district_id": "610618",
    "label": "Nanga Raun",
    "value": "Nanga Raun"
  },
  {
    "id": "6106182005",
    "district_id": "610618",
    "label": "Bahenap",
    "value": "Bahenap"
  },
  {
    "id": "6106182006",
    "district_id": "610618",
    "label": "Nanga Tubuk",
    "value": "Nanga Tubuk"
  },
  {
    "id": "6106182007",
    "district_id": "610618",
    "label": "Nanga Danau",
    "value": "Nanga Danau"
  },
  {
    "id": "6106182008",
    "district_id": "610618",
    "label": "Kensuray",
    "value": "Kensuray"
  },
  {
    "id": "6106182009",
    "district_id": "610618",
    "label": "Rantau Kalis",
    "value": "Rantau Kalis"
  },
  {
    "id": "6106182010",
    "district_id": "610618",
    "label": "Kalis Raya",
    "value": "Kalis Raya"
  },
  {
    "id": "6106182011",
    "district_id": "610618",
    "label": "Tekudak",
    "value": "Tekudak"
  },
  {
    "id": "6106182012",
    "district_id": "610618",
    "label": "Semerantau",
    "value": "Semerantau"
  },
  {
    "id": "6106182013",
    "district_id": "610618",
    "label": "Tapang Da'an",
    "value": "Tapang Da'an"
  },
  {
    "id": "6106182014",
    "district_id": "610618",
    "label": "Segiam",
    "value": "Segiam"
  },
  {
    "id": "6106182016",
    "district_id": "610618",
    "label": "Ribang Kadeng",
    "value": "Ribang Kadeng"
  },
  {
    "id": "6106182017",
    "district_id": "610618",
    "label": "Rantau Bumbun",
    "value": "Rantau Bumbun"
  },
  {
    "id": "6106182018",
    "district_id": "610618",
    "label": "Peniung",
    "value": "Peniung"
  },
  {
    "id": "6106192001",
    "district_id": "610619",
    "label": "Nanga Danau",
    "value": "Nanga Danau"
  },
  {
    "id": "6106192002",
    "district_id": "610619",
    "label": "Boyan Tanjung",
    "value": "Boyan Tanjung"
  },
  {
    "id": "6106192003",
    "district_id": "610619",
    "label": "Riam Mengelai",
    "value": "Riam Mengelai"
  },
  {
    "id": "6106192004",
    "district_id": "610619",
    "label": "Nanga Sangan",
    "value": "Nanga Sangan"
  },
  {
    "id": "6106192005",
    "district_id": "610619",
    "label": "Sri Wangi",
    "value": "Sri Wangi"
  },
  {
    "id": "6106192006",
    "district_id": "610619",
    "label": "Nanga Boyan",
    "value": "Nanga Boyan"
  },
  {
    "id": "6106192007",
    "district_id": "610619",
    "label": "Nanga Betung",
    "value": "Nanga Betung"
  },
  {
    "id": "6106192008",
    "district_id": "610619",
    "label": "Mujan",
    "value": "Mujan"
  },
  {
    "id": "6106192009",
    "district_id": "610619",
    "label": "Nanga Jemah",
    "value": "Nanga Jemah"
  },
  {
    "id": "6106192010",
    "district_id": "610619",
    "label": "Tubang Jaya",
    "value": "Tubang Jaya"
  },
  {
    "id": "6106192011",
    "district_id": "610619",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "6106192012",
    "district_id": "610619",
    "label": "Landau Mentail",
    "value": "Landau Mentail"
  },
  {
    "id": "6106192013",
    "district_id": "610619",
    "label": "Delintas Karya",
    "value": "Delintas Karya"
  },
  {
    "id": "6106192014",
    "district_id": "610619",
    "label": "Teluk Geruguk",
    "value": "Teluk Geruguk"
  },
  {
    "id": "6106192015",
    "district_id": "610619",
    "label": "Nanga Ret",
    "value": "Nanga Ret"
  },
  {
    "id": "6106192016",
    "district_id": "610619",
    "label": "Pemawan",
    "value": "Pemawan"
  },
  {
    "id": "6106202001",
    "district_id": "610620",
    "label": "Nanga Mentebah",
    "value": "Nanga Mentebah"
  },
  {
    "id": "6106202002",
    "district_id": "610620",
    "label": "Tekalong",
    "value": "Tekalong"
  },
  {
    "id": "6106202003",
    "district_id": "610620",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6106202004",
    "district_id": "610620",
    "label": "Kepala Gurung",
    "value": "Kepala Gurung"
  },
  {
    "id": "6106202005",
    "district_id": "610620",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6106202006",
    "district_id": "610620",
    "label": "Tanjung Intan",
    "value": "Tanjung Intan"
  },
  {
    "id": "6106202007",
    "district_id": "610620",
    "label": "Tangai Jaya",
    "value": "Tangai Jaya"
  },
  {
    "id": "6106202008",
    "district_id": "610620",
    "label": "Menaren",
    "value": "Menaren"
  },
  {
    "id": "6106212001",
    "district_id": "610621",
    "label": "Kerangan Panjang",
    "value": "Kerangan Panjang"
  },
  {
    "id": "6106212002",
    "district_id": "610621",
    "label": "Mawan",
    "value": "Mawan"
  },
  {
    "id": "6106212003",
    "district_id": "610621",
    "label": "Marta Dana",
    "value": "Marta Dana"
  },
  {
    "id": "6106212004",
    "district_id": "610621",
    "label": "Buak Limbang",
    "value": "Buak Limbang"
  },
  {
    "id": "6106212005",
    "district_id": "610621",
    "label": "Riam Panjang",
    "value": "Riam Panjang"
  },
  {
    "id": "6106212006",
    "district_id": "610621",
    "label": "Permata",
    "value": "Permata"
  },
  {
    "id": "6106212007",
    "district_id": "610621",
    "label": "Hulu Pengkadan",
    "value": "Hulu Pengkadan"
  },
  {
    "id": "6106212008",
    "district_id": "610621",
    "label": "Pinang Laka",
    "value": "Pinang Laka"
  },
  {
    "id": "6106212009",
    "district_id": "610621",
    "label": "Sasan",
    "value": "Sasan"
  },
  {
    "id": "6106212010",
    "district_id": "610621",
    "label": "Pengkadan Hilir",
    "value": "Pengkadan Hilir"
  },
  {
    "id": "6106212011",
    "district_id": "610621",
    "label": "Sira Jaya",
    "value": "Sira Jaya"
  },
  {
    "id": "6106222001",
    "district_id": "610622",
    "label": "Mensusai",
    "value": "Mensusai"
  },
  {
    "id": "6106222003",
    "district_id": "610622",
    "label": "Kerengas",
    "value": "Kerengas"
  },
  {
    "id": "6106222004",
    "district_id": "610622",
    "label": "Mantan",
    "value": "Mantan"
  },
  {
    "id": "6106222005",
    "district_id": "610622",
    "label": "Nanga Suhaid",
    "value": "Nanga Suhaid"
  },
  {
    "id": "6106222006",
    "district_id": "610622",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6106222007",
    "district_id": "610622",
    "label": "Madang Permai",
    "value": "Madang Permai"
  },
  {
    "id": "6106222008",
    "district_id": "610622",
    "label": "Laut Tawang",
    "value": "Laut Tawang"
  },
  {
    "id": "6106222009",
    "district_id": "610622",
    "label": "Jongkong Hulu",
    "value": "Jongkong Hulu"
  },
  {
    "id": "6106222010",
    "district_id": "610622",
    "label": "Menapar",
    "value": "Menapar"
  },
  {
    "id": "6106222011",
    "district_id": "610622",
    "label": "Lubuk Pengail",
    "value": "Lubuk Pengail"
  },
  {
    "id": "6106222012",
    "district_id": "610622",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6106232001",
    "district_id": "610623",
    "label": "Sungai Antu",
    "value": "Sungai Antu"
  },
  {
    "id": "6106232002",
    "district_id": "610623",
    "label": "Merakai Panjang",
    "value": "Merakai Panjang"
  },
  {
    "id": "6106232003",
    "district_id": "610623",
    "label": "Kantuk Asam",
    "value": "Kantuk Asam"
  },
  {
    "id": "6106232004",
    "district_id": "610623",
    "label": "Kantuk Bunut",
    "value": "Kantuk Bunut"
  },
  {
    "id": "6106232005",
    "district_id": "610623",
    "label": "Sungai Mawang",
    "value": "Sungai Mawang"
  },
  {
    "id": "6106232006",
    "district_id": "610623",
    "label": "Langau",
    "value": "Langau"
  },
  {
    "id": "6107012001",
    "district_id": "610701",
    "label": "Sungai Duri",
    "value": "Sungai Duri"
  },
  {
    "id": "6107012004",
    "district_id": "610701",
    "label": "Sungai Jaga A",
    "value": "Sungai Jaga A"
  },
  {
    "id": "6107012005",
    "district_id": "610701",
    "label": "Sungai Jaga B",
    "value": "Sungai Jaga B"
  },
  {
    "id": "6107012006",
    "district_id": "610701",
    "label": "Sungai Pangkalan I",
    "value": "Sungai Pangkalan I"
  },
  {
    "id": "6107012007",
    "district_id": "610701",
    "label": "Sungai Pangkalan II",
    "value": "Sungai Pangkalan II"
  },
  {
    "id": "6107022001",
    "district_id": "610702",
    "label": "Samalantan",
    "value": "Samalantan"
  },
  {
    "id": "6107022002",
    "district_id": "610702",
    "label": "Saba'u",
    "value": "Saba'u"
  },
  {
    "id": "6107022003",
    "district_id": "610702",
    "label": "Tumiang",
    "value": "Tumiang"
  },
  {
    "id": "6107022004",
    "district_id": "610702",
    "label": "Babane",
    "value": "Babane"
  },
  {
    "id": "6107022005",
    "district_id": "610702",
    "label": "Pasti Jaya",
    "value": "Pasti Jaya"
  },
  {
    "id": "6107022006",
    "district_id": "610702",
    "label": "Bukit Serayan",
    "value": "Bukit Serayan"
  },
  {
    "id": "6107022007",
    "district_id": "610702",
    "label": "Marunsu",
    "value": "Marunsu"
  },
  {
    "id": "6107032001",
    "district_id": "610703",
    "label": "Lesabela",
    "value": "Lesabela"
  },
  {
    "id": "6107032002",
    "district_id": "610703",
    "label": "Semangat",
    "value": "Semangat"
  },
  {
    "id": "6107032003",
    "district_id": "610703",
    "label": "Serangkat",
    "value": "Serangkat"
  },
  {
    "id": "6107032004",
    "district_id": "610703",
    "label": "Rodaya",
    "value": "Rodaya"
  },
  {
    "id": "6107032005",
    "district_id": "610703",
    "label": "Dayung",
    "value": "Dayung"
  },
  {
    "id": "6107032007",
    "district_id": "610703",
    "label": "Jesape",
    "value": "Jesape"
  },
  {
    "id": "6107032011",
    "district_id": "610703",
    "label": "Lomba Karya",
    "value": "Lomba Karya"
  },
  {
    "id": "6107032012",
    "district_id": "610703",
    "label": "Sidai",
    "value": "Sidai"
  },
  {
    "id": "6107032013",
    "district_id": "610703",
    "label": "Seles",
    "value": "Seles"
  },
  {
    "id": "6107032018",
    "district_id": "610703",
    "label": "Tebuah Marong",
    "value": "Tebuah Marong"
  },
  {
    "id": "6107032019",
    "district_id": "610703",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6107032021",
    "district_id": "610703",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "6107041001",
    "district_id": "610704",
    "label": "Bumi Emas",
    "value": "Bumi Emas"
  },
  {
    "id": "6107041010",
    "district_id": "610704",
    "label": "Sebalo",
    "value": "Sebalo"
  },
  {
    "id": "6107042003",
    "district_id": "610704",
    "label": "Tirta Kencana",
    "value": "Tirta Kencana"
  },
  {
    "id": "6107042004",
    "district_id": "610704",
    "label": "Bhakti Mulya",
    "value": "Bhakti Mulya"
  },
  {
    "id": "6107042006",
    "district_id": "610704",
    "label": "Bani Amas",
    "value": "Bani Amas"
  },
  {
    "id": "6107042009",
    "district_id": "610704",
    "label": "Setia Budi",
    "value": "Setia Budi"
  },
  {
    "id": "6107052001",
    "district_id": "610705",
    "label": "Seluas",
    "value": "Seluas"
  },
  {
    "id": "6107052002",
    "district_id": "610705",
    "label": "Bengkawan",
    "value": "Bengkawan"
  },
  {
    "id": "6107052003",
    "district_id": "610705",
    "label": "Sahan",
    "value": "Sahan"
  },
  {
    "id": "6107052004",
    "district_id": "610705",
    "label": "Mayak",
    "value": "Mayak"
  },
  {
    "id": "6107052005",
    "district_id": "610705",
    "label": "Kalon",
    "value": "Kalon"
  },
  {
    "id": "6107052006",
    "district_id": "610705",
    "label": "Sentangau Jaya",
    "value": "Sentangau Jaya"
  },
  {
    "id": "6107062001",
    "district_id": "610706",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "6107062002",
    "district_id": "610706",
    "label": "Gua",
    "value": "Gua"
  },
  {
    "id": "6107062003",
    "district_id": "610706",
    "label": "Bange",
    "value": "Bange"
  },
  {
    "id": "6107062004",
    "district_id": "610706",
    "label": "Danti",
    "value": "Danti"
  },
  {
    "id": "6107062005",
    "district_id": "610706",
    "label": "Sango",
    "value": "Sango"
  },
  {
    "id": "6107072001",
    "district_id": "610707",
    "label": "Jagoi",
    "value": "Jagoi"
  },
  {
    "id": "6107072002",
    "district_id": "610707",
    "label": "Kumba",
    "value": "Kumba"
  },
  {
    "id": "6107072006",
    "district_id": "610707",
    "label": "Sekida",
    "value": "Sekida"
  },
  {
    "id": "6107072007",
    "district_id": "610707",
    "label": "Gersik",
    "value": "Gersik"
  },
  {
    "id": "6107072008",
    "district_id": "610707",
    "label": "Semunying Jaya",
    "value": "Semunying Jaya"
  },
  {
    "id": "6107072009",
    "district_id": "610707",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "6107082002",
    "district_id": "610708",
    "label": "Gerantung",
    "value": "Gerantung"
  },
  {
    "id": "6107082003",
    "district_id": "610708",
    "label": "Monterado",
    "value": "Monterado"
  },
  {
    "id": "6107082004",
    "district_id": "610708",
    "label": "Goa Boma",
    "value": "Goa Boma"
  },
  {
    "id": "6107082006",
    "district_id": "610708",
    "label": "Nek Ginap",
    "value": "Nek Ginap"
  },
  {
    "id": "6107082007",
    "district_id": "610708",
    "label": "Beringin Baru",
    "value": "Beringin Baru"
  },
  {
    "id": "6107082008",
    "district_id": "610708",
    "label": "Sendoreng",
    "value": "Sendoreng"
  },
  {
    "id": "6107082009",
    "district_id": "610708",
    "label": "Rantau",
    "value": "Rantau"
  },
  {
    "id": "6107082010",
    "district_id": "610708",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "6107082011",
    "district_id": "610708",
    "label": "Jahandung",
    "value": "Jahandung"
  },
  {
    "id": "6107082012",
    "district_id": "610708",
    "label": "Serindu",
    "value": "Serindu"
  },
  {
    "id": "6107082013",
    "district_id": "610708",
    "label": "Siaga",
    "value": "Siaga"
  },
  {
    "id": "6107092001",
    "district_id": "610709",
    "label": "Dharma Bhakti",
    "value": "Dharma Bhakti"
  },
  {
    "id": "6107092002",
    "district_id": "610709",
    "label": "Sumber Karya",
    "value": "Sumber Karya"
  },
  {
    "id": "6107092003",
    "district_id": "610709",
    "label": "Setia Jaya",
    "value": "Setia Jaya"
  },
  {
    "id": "6107092004",
    "district_id": "610709",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "6107092005",
    "district_id": "610709",
    "label": "Sebetung Menyala",
    "value": "Sebetung Menyala"
  },
  {
    "id": "6107092006",
    "district_id": "610709",
    "label": "Malo Jelayan",
    "value": "Malo Jelayan"
  },
  {
    "id": "6107092007",
    "district_id": "610709",
    "label": "Ampar Benteng",
    "value": "Ampar Benteng"
  },
  {
    "id": "6107092008",
    "district_id": "610709",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6107092009",
    "district_id": "610709",
    "label": "Telidik",
    "value": "Telidik"
  },
  {
    "id": "6107092010",
    "district_id": "610709",
    "label": "Puteng",
    "value": "Puteng"
  },
  {
    "id": "6107092011",
    "district_id": "610709",
    "label": "Sekaruh",
    "value": "Sekaruh"
  },
  {
    "id": "6107092012",
    "district_id": "610709",
    "label": "Tubajur",
    "value": "Tubajur"
  },
  {
    "id": "6107092013",
    "district_id": "610709",
    "label": "Lulang",
    "value": "Lulang"
  },
  {
    "id": "6107092014",
    "district_id": "610709",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "6107092015",
    "district_id": "610709",
    "label": "Sebente",
    "value": "Sebente"
  },
  {
    "id": "6107092016",
    "district_id": "610709",
    "label": "Teriak",
    "value": "Teriak"
  },
  {
    "id": "6107092017",
    "district_id": "610709",
    "label": "Temia Sio",
    "value": "Temia Sio"
  },
  {
    "id": "6107092018",
    "district_id": "610709",
    "label": "Bana",
    "value": "Bana"
  },
  {
    "id": "6107102001",
    "district_id": "610710",
    "label": "Nangka",
    "value": "Nangka"
  },
  {
    "id": "6107102002",
    "district_id": "610710",
    "label": "Muhi Bersatu",
    "value": "Muhi Bersatu"
  },
  {
    "id": "6107102003",
    "district_id": "610710",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6107102004",
    "district_id": "610710",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "6107102005",
    "district_id": "610710",
    "label": "Kiung",
    "value": "Kiung"
  },
  {
    "id": "6107102006",
    "district_id": "610710",
    "label": "Kelayuk",
    "value": "Kelayuk"
  },
  {
    "id": "6107102007",
    "district_id": "610710",
    "label": "Suti Semarang",
    "value": "Suti Semarang"
  },
  {
    "id": "6107102008",
    "district_id": "610710",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "6107112001",
    "district_id": "610711",
    "label": "Capkala",
    "value": "Capkala"
  },
  {
    "id": "6107112002",
    "district_id": "610711",
    "label": "Mandor",
    "value": "Mandor"
  },
  {
    "id": "6107112003",
    "district_id": "610711",
    "label": "Setanduk",
    "value": "Setanduk"
  },
  {
    "id": "6107112004",
    "district_id": "610711",
    "label": "Aris",
    "value": "Aris"
  },
  {
    "id": "6107112005",
    "district_id": "610711",
    "label": "Sebandut",
    "value": "Sebandut"
  },
  {
    "id": "6107112006",
    "district_id": "610711",
    "label": "Pawangi",
    "value": "Pawangi"
  },
  {
    "id": "6107122002",
    "district_id": "610712",
    "label": "Sungkung I",
    "value": "Sungkung I"
  },
  {
    "id": "6107122004",
    "district_id": "610712",
    "label": "Siding",
    "value": "Siding"
  },
  {
    "id": "6107122005",
    "district_id": "610712",
    "label": "Hli Buei",
    "value": "Hli Buei"
  },
  {
    "id": "6107122006",
    "district_id": "610712",
    "label": "Tangguh",
    "value": "Tangguh"
  },
  {
    "id": "6107122007",
    "district_id": "610712",
    "label": "Sungkung II",
    "value": "Sungkung II"
  },
  {
    "id": "6107122008",
    "district_id": "610712",
    "label": "Sungkung III",
    "value": "Sungkung III"
  },
  {
    "id": "6107122009",
    "district_id": "610712",
    "label": "Tamong",
    "value": "Tamong"
  },
  {
    "id": "6107122010",
    "district_id": "610712",
    "label": "Tawang",
    "value": "Tawang"
  },
  {
    "id": "6107132001",
    "district_id": "610713",
    "label": "Magmagan Karya",
    "value": "Magmagan Karya"
  },
  {
    "id": "6107132002",
    "district_id": "610713",
    "label": "Tiga Berkat",
    "value": "Tiga Berkat"
  },
  {
    "id": "6107132003",
    "district_id": "610713",
    "label": "Seren Selimbau",
    "value": "Seren Selimbau"
  },
  {
    "id": "6107132004",
    "district_id": "610713",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "6107132005",
    "district_id": "610713",
    "label": "Lamolda",
    "value": "Lamolda"
  },
  {
    "id": "6107142001",
    "district_id": "610714",
    "label": "Cipta Karya",
    "value": "Cipta Karya"
  },
  {
    "id": "6107142002",
    "district_id": "610714",
    "label": "Suka Bangun",
    "value": "Suka Bangun"
  },
  {
    "id": "6107142003",
    "district_id": "610714",
    "label": "Karya Bhakti",
    "value": "Karya Bhakti"
  },
  {
    "id": "6107142004",
    "district_id": "610714",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6107152001",
    "district_id": "610715",
    "label": "Rukma Jaya",
    "value": "Rukma Jaya"
  },
  {
    "id": "6107152002",
    "district_id": "610715",
    "label": "Sungai Keran",
    "value": "Sungai Keran"
  },
  {
    "id": "6107152003",
    "district_id": "610715",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "6107152004",
    "district_id": "610715",
    "label": "Karimunting",
    "value": "Karimunting"
  },
  {
    "id": "6107152005",
    "district_id": "610715",
    "label": "Pulau Lemukutan",
    "value": "Pulau Lemukutan"
  },
  {
    "id": "6107162001",
    "district_id": "610716",
    "label": "Saka Taru",
    "value": "Saka Taru"
  },
  {
    "id": "6107162002",
    "district_id": "610716",
    "label": "Lembah Bawang",
    "value": "Lembah Bawang"
  },
  {
    "id": "6107162003",
    "district_id": "610716",
    "label": "Janyat",
    "value": "Janyat"
  },
  {
    "id": "6107162004",
    "district_id": "610716",
    "label": "Tempapan",
    "value": "Tempapan"
  },
  {
    "id": "6107162005",
    "district_id": "610716",
    "label": "Papan Uduk",
    "value": "Papan Uduk"
  },
  {
    "id": "6107162006",
    "district_id": "610716",
    "label": "Papan Tembawang",
    "value": "Papan Tembawang"
  },
  {
    "id": "6107162007",
    "district_id": "610716",
    "label": "Godang Damar",
    "value": "Godang Damar"
  },
  {
    "id": "6107162008",
    "district_id": "610716",
    "label": "Kinande",
    "value": "Kinande"
  },
  {
    "id": "6107172001",
    "district_id": "610717",
    "label": "Sinar Tebudak",
    "value": "Sinar Tebudak"
  },
  {
    "id": "6107172002",
    "district_id": "610717",
    "label": "Kamuh",
    "value": "Kamuh"
  },
  {
    "id": "6107172003",
    "district_id": "610717",
    "label": "Bengkilu",
    "value": "Bengkilu"
  },
  {
    "id": "6107172004",
    "district_id": "610717",
    "label": "Pisak",
    "value": "Pisak"
  },
  {
    "id": "6108012001",
    "district_id": "610801",
    "label": "Hilir Kantor",
    "value": "Hilir Kantor"
  },
  {
    "id": "6108012002",
    "district_id": "610801",
    "label": "Hilir Tengah",
    "value": "Hilir Tengah"
  },
  {
    "id": "6108012003",
    "district_id": "610801",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "6108012004",
    "district_id": "610801",
    "label": "Mungguk",
    "value": "Mungguk"
  },
  {
    "id": "6108012005",
    "district_id": "610801",
    "label": "Amboyo Utara",
    "value": "Amboyo Utara"
  },
  {
    "id": "6108012006",
    "district_id": "610801",
    "label": "Sungai Keli",
    "value": "Sungai Keli"
  },
  {
    "id": "6108012007",
    "district_id": "610801",
    "label": "Rasan",
    "value": "Rasan"
  },
  {
    "id": "6108012008",
    "district_id": "610801",
    "label": "Mu'un",
    "value": "Mu'un"
  },
  {
    "id": "6108012009",
    "district_id": "610801",
    "label": "Ambarang",
    "value": "Ambarang"
  },
  {
    "id": "6108012010",
    "district_id": "610801",
    "label": "Engkadu",
    "value": "Engkadu"
  },
  {
    "id": "6108012011",
    "district_id": "610801",
    "label": "Amang",
    "value": "Amang"
  },
  {
    "id": "6108012012",
    "district_id": "610801",
    "label": "Penyaho Dangku",
    "value": "Penyaho Dangku"
  },
  {
    "id": "6108012013",
    "district_id": "610801",
    "label": "Antan Rayan",
    "value": "Antan Rayan"
  },
  {
    "id": "6108012014",
    "district_id": "610801",
    "label": "Amboyo Inti",
    "value": "Amboyo Inti"
  },
  {
    "id": "6108012015",
    "district_id": "610801",
    "label": "Tebedak",
    "value": "Tebedak"
  },
  {
    "id": "6108012016",
    "district_id": "610801",
    "label": "Temiang Sawi",
    "value": "Temiang Sawi"
  },
  {
    "id": "6108012017",
    "district_id": "610801",
    "label": "Amboyo Selatan",
    "value": "Amboyo Selatan"
  },
  {
    "id": "6108012018",
    "district_id": "610801",
    "label": "Sebirang",
    "value": "Sebirang"
  },
  {
    "id": "6108012019",
    "district_id": "610801",
    "label": "Pak Mayam",
    "value": "Pak Mayam"
  },
  {
    "id": "6108022001",
    "district_id": "610802",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "6108022002",
    "district_id": "610802",
    "label": "Sailo",
    "value": "Sailo"
  },
  {
    "id": "6108022003",
    "district_id": "610802",
    "label": "Mentonyek",
    "value": "Mentonyek"
  },
  {
    "id": "6108022004",
    "district_id": "610802",
    "label": "Sampuro",
    "value": "Sampuro"
  },
  {
    "id": "6108022005",
    "district_id": "610802",
    "label": "Sala'as",
    "value": "Sala'as"
  },
  {
    "id": "6108022006",
    "district_id": "610802",
    "label": "Sabaka",
    "value": "Sabaka"
  },
  {
    "id": "6108022007",
    "district_id": "610802",
    "label": "Tunang",
    "value": "Tunang"
  },
  {
    "id": "6108022009",
    "district_id": "610802",
    "label": "Sungai Laki",
    "value": "Sungai Laki"
  },
  {
    "id": "6108022014",
    "district_id": "610802",
    "label": "Tiang Tanjung",
    "value": "Tiang Tanjung"
  },
  {
    "id": "6108022017",
    "district_id": "610802",
    "label": "Pahokng",
    "value": "Pahokng"
  },
  {
    "id": "6108022018",
    "district_id": "610802",
    "label": "Garu",
    "value": "Garu"
  },
  {
    "id": "6108022019",
    "district_id": "610802",
    "label": "Bilayuk",
    "value": "Bilayuk"
  },
  {
    "id": "6108022020",
    "district_id": "610802",
    "label": "Caokng",
    "value": "Caokng"
  },
  {
    "id": "6108022021",
    "district_id": "610802",
    "label": "Salumang",
    "value": "Salumang"
  },
  {
    "id": "6108022022",
    "district_id": "610802",
    "label": "Ansolok",
    "value": "Ansolok"
  },
  {
    "id": "6108022023",
    "district_id": "610802",
    "label": "Babatn",
    "value": "Babatn"
  },
  {
    "id": "6108022024",
    "district_id": "610802",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "6108032001",
    "district_id": "610803",
    "label": "Sepahat",
    "value": "Sepahat"
  },
  {
    "id": "6108032002",
    "district_id": "610803",
    "label": "Lamoanak",
    "value": "Lamoanak"
  },
  {
    "id": "6108032003",
    "district_id": "610803",
    "label": "Bengkawe",
    "value": "Bengkawe"
  },
  {
    "id": "6108032004",
    "district_id": "610803",
    "label": "Tempoak",
    "value": "Tempoak"
  },
  {
    "id": "6108032005",
    "district_id": "610803",
    "label": "Re'es",
    "value": "Re'es"
  },
  {
    "id": "6108032006",
    "district_id": "610803",
    "label": "Raba",
    "value": "Raba"
  },
  {
    "id": "6108032007",
    "district_id": "610803",
    "label": "Nangka",
    "value": "Nangka"
  },
  {
    "id": "6108032008",
    "district_id": "610803",
    "label": "Menjalin",
    "value": "Menjalin"
  },
  {
    "id": "6108042001",
    "district_id": "610804",
    "label": "Sebadu",
    "value": "Sebadu"
  },
  {
    "id": "6108042002",
    "district_id": "610804",
    "label": "Semenok",
    "value": "Semenok"
  },
  {
    "id": "6108042003",
    "district_id": "610804",
    "label": "Mengkunyit",
    "value": "Mengkunyit"
  },
  {
    "id": "6108042004",
    "district_id": "610804",
    "label": "Bebatung",
    "value": "Bebatung"
  },
  {
    "id": "6108042005",
    "district_id": "610804",
    "label": "Kerohok",
    "value": "Kerohok"
  },
  {
    "id": "6108042006",
    "district_id": "610804",
    "label": "Sumsum",
    "value": "Sumsum"
  },
  {
    "id": "6108042007",
    "district_id": "610804",
    "label": "Sekilap",
    "value": "Sekilap"
  },
  {
    "id": "6108042008",
    "district_id": "610804",
    "label": "Manggang",
    "value": "Manggang"
  },
  {
    "id": "6108042009",
    "district_id": "610804",
    "label": "Keramas",
    "value": "Keramas"
  },
  {
    "id": "6108042010",
    "district_id": "610804",
    "label": "Pongok",
    "value": "Pongok"
  },
  {
    "id": "6108042011",
    "district_id": "610804",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "6108042012",
    "district_id": "610804",
    "label": "Selutung",
    "value": "Selutung"
  },
  {
    "id": "6108042013",
    "district_id": "610804",
    "label": "Mandor",
    "value": "Mandor"
  },
  {
    "id": "6108042014",
    "district_id": "610804",
    "label": "Simpang Kasturi",
    "value": "Simpang Kasturi"
  },
  {
    "id": "6108042015",
    "district_id": "610804",
    "label": "Salatiga",
    "value": "Salatiga"
  },
  {
    "id": "6108042016",
    "district_id": "610804",
    "label": "Kayu Tanam",
    "value": "Kayu Tanam"
  },
  {
    "id": "6108042017",
    "district_id": "610804",
    "label": "Ngarak",
    "value": "Ngarak"
  },
  {
    "id": "6108052001",
    "district_id": "610805",
    "label": "Serimbu",
    "value": "Serimbu"
  },
  {
    "id": "6108052002",
    "district_id": "610805",
    "label": "Engkangin",
    "value": "Engkangin"
  },
  {
    "id": "6108052003",
    "district_id": "610805",
    "label": "Jambu Tembawang",
    "value": "Jambu Tembawang"
  },
  {
    "id": "6108052004",
    "district_id": "610805",
    "label": "Sepangah",
    "value": "Sepangah"
  },
  {
    "id": "6108052005",
    "district_id": "610805",
    "label": "Merayuh",
    "value": "Merayuh"
  },
  {
    "id": "6108052006",
    "district_id": "610805",
    "label": "Nyari",
    "value": "Nyari"
  },
  {
    "id": "6108052007",
    "district_id": "610805",
    "label": "Sekendal",
    "value": "Sekendal"
  },
  {
    "id": "6108052008",
    "district_id": "610805",
    "label": "Semuntik",
    "value": "Semuntik"
  },
  {
    "id": "6108052009",
    "district_id": "610805",
    "label": "Temoyok",
    "value": "Temoyok"
  },
  {
    "id": "6108052010",
    "district_id": "610805",
    "label": "Engkadik Pade",
    "value": "Engkadik Pade"
  },
  {
    "id": "6108052011",
    "district_id": "610805",
    "label": "Dange Aji",
    "value": "Dange Aji"
  },
  {
    "id": "6108052012",
    "district_id": "610805",
    "label": "Tenguwe",
    "value": "Tenguwe"
  },
  {
    "id": "6108052013",
    "district_id": "610805",
    "label": "Parek",
    "value": "Parek"
  },
  {
    "id": "6108052014",
    "district_id": "610805",
    "label": "Bentiang Madomang",
    "value": "Bentiang Madomang"
  },
  {
    "id": "6108052015",
    "district_id": "610805",
    "label": "Tengon",
    "value": "Tengon"
  },
  {
    "id": "6108052016",
    "district_id": "610805",
    "label": "Sempatung Lawek",
    "value": "Sempatung Lawek"
  },
  {
    "id": "6108062001",
    "district_id": "610806",
    "label": "Darit",
    "value": "Darit"
  },
  {
    "id": "6108062002",
    "district_id": "610806",
    "label": "Mamek",
    "value": "Mamek"
  },
  {
    "id": "6108062003",
    "district_id": "610806",
    "label": "Ansang",
    "value": "Ansang"
  },
  {
    "id": "6108062004",
    "district_id": "610806",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "6108062005",
    "district_id": "610806",
    "label": "Angkaras",
    "value": "Angkaras"
  },
  {
    "id": "6108062006",
    "district_id": "610806",
    "label": "Ladangan",
    "value": "Ladangan"
  },
  {
    "id": "6108062009",
    "district_id": "610806",
    "label": "Songga",
    "value": "Songga"
  },
  {
    "id": "6108062010",
    "district_id": "610806",
    "label": "Sidan",
    "value": "Sidan"
  },
  {
    "id": "6108062011",
    "district_id": "610806",
    "label": "Berinang Mayun",
    "value": "Berinang Mayun"
  },
  {
    "id": "6108062013",
    "district_id": "610806",
    "label": "Lintah Betung",
    "value": "Lintah Betung"
  },
  {
    "id": "6108062014",
    "district_id": "610806",
    "label": "Anik Dingir",
    "value": "Anik Dingir"
  },
  {
    "id": "6108062015",
    "district_id": "610806",
    "label": "Bagak",
    "value": "Bagak"
  },
  {
    "id": "6108062016",
    "district_id": "610806",
    "label": "Tolok",
    "value": "Tolok"
  },
  {
    "id": "6108062017",
    "district_id": "610806",
    "label": "Sungai Lubang",
    "value": "Sungai Lubang"
  },
  {
    "id": "6108062018",
    "district_id": "610806",
    "label": "Ta'as",
    "value": "Ta'as"
  },
  {
    "id": "6108062019",
    "district_id": "610806",
    "label": "Ongkol Padang",
    "value": "Ongkol Padang"
  },
  {
    "id": "6108072001",
    "district_id": "610807",
    "label": "Pahauman",
    "value": "Pahauman"
  },
  {
    "id": "6108072002",
    "district_id": "610807",
    "label": "Paloan",
    "value": "Paloan"
  },
  {
    "id": "6108072003",
    "district_id": "610807",
    "label": "Sebatih",
    "value": "Sebatih"
  },
  {
    "id": "6108072004",
    "district_id": "610807",
    "label": "Saham",
    "value": "Saham"
  },
  {
    "id": "6108072005",
    "district_id": "610807",
    "label": "Andeng",
    "value": "Andeng"
  },
  {
    "id": "6108072006",
    "district_id": "610807",
    "label": "Senakin",
    "value": "Senakin"
  },
  {
    "id": "6108072007",
    "district_id": "610807",
    "label": "Tonang",
    "value": "Tonang"
  },
  {
    "id": "6108072008",
    "district_id": "610807",
    "label": "Banying",
    "value": "Banying"
  },
  {
    "id": "6108072009",
    "district_id": "610807",
    "label": "Sidas",
    "value": "Sidas"
  },
  {
    "id": "6108072010",
    "district_id": "610807",
    "label": "Keranji Mancal",
    "value": "Keranji Mancal"
  },
  {
    "id": "6108072011",
    "district_id": "610807",
    "label": "Keranji Paidang",
    "value": "Keranji Paidang"
  },
  {
    "id": "6108072012",
    "district_id": "610807",
    "label": "Aur Sampuk",
    "value": "Aur Sampuk"
  },
  {
    "id": "6108072013",
    "district_id": "610807",
    "label": "Gombang",
    "value": "Gombang"
  },
  {
    "id": "6108072014",
    "district_id": "610807",
    "label": "Rabak",
    "value": "Rabak"
  },
  {
    "id": "6108082001",
    "district_id": "610808",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "6108082002",
    "district_id": "610808",
    "label": "Kelampai Setolo",
    "value": "Kelampai Setolo"
  },
  {
    "id": "6108082003",
    "district_id": "610808",
    "label": "Moro Betung",
    "value": "Moro Betung"
  },
  {
    "id": "6108082004",
    "district_id": "610808",
    "label": "Selange",
    "value": "Selange"
  },
  {
    "id": "6108082005",
    "district_id": "610808",
    "label": "Ampadi",
    "value": "Ampadi"
  },
  {
    "id": "6108082006",
    "district_id": "610808",
    "label": "Tahu",
    "value": "Tahu"
  },
  {
    "id": "6108092001",
    "district_id": "610809",
    "label": "Semedang",
    "value": "Semedang"
  },
  {
    "id": "6108092002",
    "district_id": "610809",
    "label": "Kuala Behe",
    "value": "Kuala Behe"
  },
  {
    "id": "6108092003",
    "district_id": "610809",
    "label": "Nyayum",
    "value": "Nyayum"
  },
  {
    "id": "6108092004",
    "district_id": "610809",
    "label": "Paku Raya",
    "value": "Paku Raya"
  },
  {
    "id": "6108092005",
    "district_id": "610809",
    "label": "Permiit",
    "value": "Permiit"
  },
  {
    "id": "6108092006",
    "district_id": "610809",
    "label": "Tanjung Balai",
    "value": "Tanjung Balai"
  },
  {
    "id": "6108092007",
    "district_id": "610809",
    "label": "Angkanyar",
    "value": "Angkanyar"
  },
  {
    "id": "6108092008",
    "district_id": "610809",
    "label": "Kedama",
    "value": "Kedama"
  },
  {
    "id": "6108092009",
    "district_id": "610809",
    "label": "Bengawan Ampar",
    "value": "Bengawan Ampar"
  },
  {
    "id": "6108092010",
    "district_id": "610809",
    "label": "Sehe Lusur",
    "value": "Sehe Lusur"
  },
  {
    "id": "6108092011",
    "district_id": "610809",
    "label": "Sejowet",
    "value": "Sejowet"
  },
  {
    "id": "6108102001",
    "district_id": "610810",
    "label": "Sebangki",
    "value": "Sebangki"
  },
  {
    "id": "6108102002",
    "district_id": "610810",
    "label": "Kumpang Tengah",
    "value": "Kumpang Tengah"
  },
  {
    "id": "6108102003",
    "district_id": "610810",
    "label": "Agak",
    "value": "Agak"
  },
  {
    "id": "6108102004",
    "district_id": "610810",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6108102005",
    "district_id": "610810",
    "label": "Sungai Segak",
    "value": "Sungai Segak"
  },
  {
    "id": "6108112001",
    "district_id": "610811",
    "label": "Temahar",
    "value": "Temahar"
  },
  {
    "id": "6108112002",
    "district_id": "610811",
    "label": "Kersik Belantian",
    "value": "Kersik Belantian"
  },
  {
    "id": "6108112003",
    "district_id": "610811",
    "label": "Nyi'in",
    "value": "Nyi'in"
  },
  {
    "id": "6108112004",
    "district_id": "610811",
    "label": "Papung",
    "value": "Papung"
  },
  {
    "id": "6108112005",
    "district_id": "610811",
    "label": "Sekais",
    "value": "Sekais"
  },
  {
    "id": "6108112006",
    "district_id": "610811",
    "label": "Mandor Kiru",
    "value": "Mandor Kiru"
  },
  {
    "id": "6108112007",
    "district_id": "610811",
    "label": "Jelimpo",
    "value": "Jelimpo"
  },
  {
    "id": "6108112008",
    "district_id": "610811",
    "label": "Tubang Raeng",
    "value": "Tubang Raeng"
  },
  {
    "id": "6108112009",
    "district_id": "610811",
    "label": "Kayu Ara",
    "value": "Kayu Ara"
  },
  {
    "id": "6108112010",
    "district_id": "610811",
    "label": "Pawis Hilir",
    "value": "Pawis Hilir"
  },
  {
    "id": "6108112011",
    "district_id": "610811",
    "label": "Angan Tembawang",
    "value": "Angan Tembawang"
  },
  {
    "id": "6108112012",
    "district_id": "610811",
    "label": "Balai Peluntan",
    "value": "Balai Peluntan"
  },
  {
    "id": "6108112013",
    "district_id": "610811",
    "label": "Dara Itam I",
    "value": "Dara Itam I"
  },
  {
    "id": "6108122001",
    "district_id": "610812",
    "label": "Padang Pio",
    "value": "Padang Pio"
  },
  {
    "id": "6108122002",
    "district_id": "610812",
    "label": "Tembawang Bale",
    "value": "Tembawang Bale"
  },
  {
    "id": "6108122003",
    "district_id": "610812",
    "label": "Kampet",
    "value": "Kampet"
  },
  {
    "id": "6108122004",
    "district_id": "610812",
    "label": "Untang",
    "value": "Untang"
  },
  {
    "id": "6108122005",
    "district_id": "610812",
    "label": "Ringo Lojok",
    "value": "Ringo Lojok"
  },
  {
    "id": "6108122006",
    "district_id": "610812",
    "label": "Gamang",
    "value": "Gamang"
  },
  {
    "id": "6108122007",
    "district_id": "610812",
    "label": "Semade",
    "value": "Semade"
  },
  {
    "id": "6108132001",
    "district_id": "610813",
    "label": "Sompak",
    "value": "Sompak"
  },
  {
    "id": "6108132002",
    "district_id": "610813",
    "label": "Pauh",
    "value": "Pauh"
  },
  {
    "id": "6108132003",
    "district_id": "610813",
    "label": "Lingkonong",
    "value": "Lingkonong"
  },
  {
    "id": "6108132004",
    "district_id": "610813",
    "label": "Galar",
    "value": "Galar"
  },
  {
    "id": "6108132005",
    "district_id": "610813",
    "label": "Amawakng",
    "value": "Amawakng"
  },
  {
    "id": "6108132006",
    "district_id": "610813",
    "label": "Tapakng",
    "value": "Tapakng"
  },
  {
    "id": "6108132007",
    "district_id": "610813",
    "label": "Pakumbang",
    "value": "Pakumbang"
  },
  {
    "id": "6109012001",
    "district_id": "610901",
    "label": "Sungai Ringin",
    "value": "Sungai Ringin"
  },
  {
    "id": "6109012002",
    "district_id": "610901",
    "label": "Mungguk",
    "value": "Mungguk"
  },
  {
    "id": "6109012003",
    "district_id": "610901",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6109012004",
    "district_id": "610901",
    "label": "Merapi",
    "value": "Merapi"
  },
  {
    "id": "6109012005",
    "district_id": "610901",
    "label": "Seraras",
    "value": "Seraras"
  },
  {
    "id": "6109012006",
    "district_id": "610901",
    "label": "Semabi",
    "value": "Semabi"
  },
  {
    "id": "6109012007",
    "district_id": "610901",
    "label": "Seberang Kapuas",
    "value": "Seberang Kapuas"
  },
  {
    "id": "6109012008",
    "district_id": "610901",
    "label": "Landau Kodah",
    "value": "Landau Kodah"
  },
  {
    "id": "6109012009",
    "district_id": "610901",
    "label": "Timpuk",
    "value": "Timpuk"
  },
  {
    "id": "6109012010",
    "district_id": "610901",
    "label": "Sungai Kunyit",
    "value": "Sungai Kunyit"
  },
  {
    "id": "6109012011",
    "district_id": "610901",
    "label": "Peniti",
    "value": "Peniti"
  },
  {
    "id": "6109012012",
    "district_id": "610901",
    "label": "Gonis Tekam",
    "value": "Gonis Tekam"
  },
  {
    "id": "6109012013",
    "district_id": "610901",
    "label": "Tapang Semadak",
    "value": "Tapang Semadak"
  },
  {
    "id": "6109012014",
    "district_id": "610901",
    "label": "Engkersik",
    "value": "Engkersik"
  },
  {
    "id": "6109012015",
    "district_id": "610901",
    "label": "Ensalang",
    "value": "Ensalang"
  },
  {
    "id": "6109012016",
    "district_id": "610901",
    "label": "Bokak Sebumbun",
    "value": "Bokak Sebumbun"
  },
  {
    "id": "6109012017",
    "district_id": "610901",
    "label": "Selalong",
    "value": "Selalong"
  },
  {
    "id": "6109022001",
    "district_id": "610902",
    "label": "Rawak Hilir",
    "value": "Rawak Hilir"
  },
  {
    "id": "6109022002",
    "district_id": "610902",
    "label": "Rawak Hulu",
    "value": "Rawak Hulu"
  },
  {
    "id": "6109022003",
    "district_id": "610902",
    "label": "Nanga Menterap",
    "value": "Nanga Menterap"
  },
  {
    "id": "6109022004",
    "district_id": "610902",
    "label": "Boti",
    "value": "Boti"
  },
  {
    "id": "6109022005",
    "district_id": "610902",
    "label": "Perongkan",
    "value": "Perongkan"
  },
  {
    "id": "6109022006",
    "district_id": "610902",
    "label": "Tinting Boyok",
    "value": "Tinting Boyok"
  },
  {
    "id": "6109022007",
    "district_id": "610902",
    "label": "Sekonau",
    "value": "Sekonau"
  },
  {
    "id": "6109022008",
    "district_id": "610902",
    "label": "Setawar",
    "value": "Setawar"
  },
  {
    "id": "6109022009",
    "district_id": "610902",
    "label": "Nanga Pembubuh",
    "value": "Nanga Pembubuh"
  },
  {
    "id": "6109022010",
    "district_id": "610902",
    "label": "Tapang Perodah",
    "value": "Tapang Perodah"
  },
  {
    "id": "6109022011",
    "district_id": "610902",
    "label": "Sungai Sambang",
    "value": "Sungai Sambang"
  },
  {
    "id": "6109022012",
    "district_id": "610902",
    "label": "Mondi",
    "value": "Mondi"
  },
  {
    "id": "6109022013",
    "district_id": "610902",
    "label": "Cupang Gading",
    "value": "Cupang Gading"
  },
  {
    "id": "6109022014",
    "district_id": "610902",
    "label": "Nanga Biaban",
    "value": "Nanga Biaban"
  },
  {
    "id": "6109022015",
    "district_id": "610902",
    "label": "Sunsong",
    "value": "Sunsong"
  },
  {
    "id": "6109032001",
    "district_id": "610903",
    "label": "Nanga Taman",
    "value": "Nanga Taman"
  },
  {
    "id": "6109032002",
    "district_id": "610903",
    "label": "Rirang Jati",
    "value": "Rirang Jati"
  },
  {
    "id": "6109032003",
    "district_id": "610903",
    "label": "Senangak",
    "value": "Senangak"
  },
  {
    "id": "6109032004",
    "district_id": "610903",
    "label": "Nanga Kiungkang",
    "value": "Nanga Kiungkang"
  },
  {
    "id": "6109032005",
    "district_id": "610903",
    "label": "Sungai Lawak",
    "value": "Sungai Lawak"
  },
  {
    "id": "6109032006",
    "district_id": "610903",
    "label": "Tapang Tingang",
    "value": "Tapang Tingang"
  },
  {
    "id": "6109032007",
    "district_id": "610903",
    "label": "Nanga Mongko",
    "value": "Nanga Mongko"
  },
  {
    "id": "6109032008",
    "district_id": "610903",
    "label": "Nanga Engkulun",
    "value": "Nanga Engkulun"
  },
  {
    "id": "6109032009",
    "district_id": "610903",
    "label": "Nanga Koman",
    "value": "Nanga Koman"
  },
  {
    "id": "6109032010",
    "district_id": "610903",
    "label": "Lubuk Tajau",
    "value": "Lubuk Tajau"
  },
  {
    "id": "6109032011",
    "district_id": "610903",
    "label": "Pantok",
    "value": "Pantok"
  },
  {
    "id": "6109032012",
    "district_id": "610903",
    "label": "Meragun",
    "value": "Meragun"
  },
  {
    "id": "6109032013",
    "district_id": "610903",
    "label": "Nanga Mentukak",
    "value": "Nanga Mentukak"
  },
  {
    "id": "6109042001",
    "district_id": "610904",
    "label": "Nanga Mahap",
    "value": "Nanga Mahap"
  },
  {
    "id": "6109042002",
    "district_id": "610904",
    "label": "Batu Pahat",
    "value": "Batu Pahat"
  },
  {
    "id": "6109042003",
    "district_id": "610904",
    "label": "Lembah Beringin",
    "value": "Lembah Beringin"
  },
  {
    "id": "6109042004",
    "district_id": "610904",
    "label": "Teluk Kebau",
    "value": "Teluk Kebau"
  },
  {
    "id": "6109042005",
    "district_id": "610904",
    "label": "Landau Apin",
    "value": "Landau Apin"
  },
  {
    "id": "6109042006",
    "district_id": "610904",
    "label": "Tembaga",
    "value": "Tembaga"
  },
  {
    "id": "6109042007",
    "district_id": "610904",
    "label": "Cenayan",
    "value": "Cenayan"
  },
  {
    "id": "6109042008",
    "district_id": "610904",
    "label": "Landau Kumpai",
    "value": "Landau Kumpai"
  },
  {
    "id": "6109042009",
    "district_id": "610904",
    "label": "Nanga Suri",
    "value": "Nanga Suri"
  },
  {
    "id": "6109042010",
    "district_id": "610904",
    "label": "Sebabas",
    "value": "Sebabas"
  },
  {
    "id": "6109042011",
    "district_id": "610904",
    "label": "Karang Betung",
    "value": "Karang Betung"
  },
  {
    "id": "6109042012",
    "district_id": "610904",
    "label": "Tamang",
    "value": "Tamang"
  },
  {
    "id": "6109042013",
    "district_id": "610904",
    "label": "Tembesuk",
    "value": "Tembesuk"
  },
  {
    "id": "6109052001",
    "district_id": "610905",
    "label": "Sungai Ayak Satu",
    "value": "Sungai Ayak Satu"
  },
  {
    "id": "6109052002",
    "district_id": "610905",
    "label": "Sungai Ayak Dua",
    "value": "Sungai Ayak Dua"
  },
  {
    "id": "6109052003",
    "district_id": "610905",
    "label": "Entabuk",
    "value": "Entabuk"
  },
  {
    "id": "6109052004",
    "district_id": "610905",
    "label": "Tapang Pulau",
    "value": "Tapang Pulau"
  },
  {
    "id": "6109052005",
    "district_id": "610905",
    "label": "Kumpang Bis",
    "value": "Kumpang Bis"
  },
  {
    "id": "6109052006",
    "district_id": "610905",
    "label": "Menawai Tekam",
    "value": "Menawai Tekam"
  },
  {
    "id": "6109052007",
    "district_id": "610905",
    "label": "Semadu",
    "value": "Semadu"
  },
  {
    "id": "6109052008",
    "district_id": "610905",
    "label": "Merbang",
    "value": "Merbang"
  },
  {
    "id": "6109052009",
    "district_id": "610905",
    "label": "Empajak",
    "value": "Empajak"
  },
  {
    "id": "6109062001",
    "district_id": "610906",
    "label": "Sebetung",
    "value": "Sebetung"
  },
  {
    "id": "6109062002",
    "district_id": "610906",
    "label": "Seburuk Satu",
    "value": "Seburuk Satu"
  },
  {
    "id": "6109062003",
    "district_id": "610906",
    "label": "Sungai Tapah",
    "value": "Sungai Tapah"
  },
  {
    "id": "6109062004",
    "district_id": "610906",
    "label": "Ijuk",
    "value": "Ijuk"
  },
  {
    "id": "6109062005",
    "district_id": "610906",
    "label": "Mengaret",
    "value": "Mengaret"
  },
  {
    "id": "6109062006",
    "district_id": "610906",
    "label": "Kumpang Ilong",
    "value": "Kumpang Ilong"
  },
  {
    "id": "6109062007",
    "district_id": "610906",
    "label": "Sungai Antu Hulu",
    "value": "Sungai Antu Hulu"
  },
  {
    "id": "6109062008",
    "district_id": "610906",
    "label": "Terduk Dampak",
    "value": "Terduk Dampak"
  },
  {
    "id": "6109062009",
    "district_id": "610906",
    "label": "Pakit Mulau",
    "value": "Pakit Mulau"
  },
  {
    "id": "6109062010",
    "district_id": "610906",
    "label": "Tabuk Hulu",
    "value": "Tabuk Hulu"
  },
  {
    "id": "6109062011",
    "district_id": "610906",
    "label": "Batuk Mulau",
    "value": "Batuk Mulau"
  },
  {
    "id": "6109062012",
    "district_id": "610906",
    "label": "Balai Sepuak",
    "value": "Balai Sepuak"
  },
  {
    "id": "6109062013",
    "district_id": "610906",
    "label": "Bukit Rambat",
    "value": "Bukit Rambat"
  },
  {
    "id": "6109072001",
    "district_id": "610907",
    "label": "Nanga Ansar",
    "value": "Nanga Ansar"
  },
  {
    "id": "6109072002",
    "district_id": "610907",
    "label": "Padak",
    "value": "Padak"
  },
  {
    "id": "6109072003",
    "district_id": "610907",
    "label": "Setuntung",
    "value": "Setuntung"
  },
  {
    "id": "6109072004",
    "district_id": "610907",
    "label": "Belitang Satu",
    "value": "Belitang Satu"
  },
  {
    "id": "6109072005",
    "district_id": "610907",
    "label": "Belitang Dua",
    "value": "Belitang Dua"
  },
  {
    "id": "6109072006",
    "district_id": "610907",
    "label": "Menua Prama",
    "value": "Menua Prama"
  },
  {
    "id": "6109072007",
    "district_id": "610907",
    "label": "Maboh Permai",
    "value": "Maboh Permai"
  },
  {
    "id": "6110012001",
    "district_id": "611001",
    "label": "Pemuar",
    "value": "Pemuar"
  },
  {
    "id": "6110012002",
    "district_id": "611001",
    "label": "Nusa Kenyikap",
    "value": "Nusa Kenyikap"
  },
  {
    "id": "6110012003",
    "district_id": "611001",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6110012007",
    "district_id": "611001",
    "label": "Balai Lagas",
    "value": "Balai Lagas"
  },
  {
    "id": "6110012008",
    "district_id": "611001",
    "label": "Upit",
    "value": "Upit"
  },
  {
    "id": "6110012009",
    "district_id": "611001",
    "label": "Langan",
    "value": "Langan"
  },
  {
    "id": "6110012010",
    "district_id": "611001",
    "label": "Batu Buil",
    "value": "Batu Buil"
  },
  {
    "id": "6110012011",
    "district_id": "611001",
    "label": "Guhung",
    "value": "Guhung"
  },
  {
    "id": "6110012013",
    "district_id": "611001",
    "label": "Batu Nanta",
    "value": "Batu Nanta"
  },
  {
    "id": "6110012014",
    "district_id": "611001",
    "label": "Belonsat",
    "value": "Belonsat"
  },
  {
    "id": "6110012016",
    "district_id": "611001",
    "label": "Laman Bukit",
    "value": "Laman Bukit"
  },
  {
    "id": "6110012017",
    "district_id": "611001",
    "label": "Tekaban",
    "value": "Tekaban"
  },
  {
    "id": "6110012019",
    "district_id": "611001",
    "label": "Labang",
    "value": "Labang"
  },
  {
    "id": "6110012020",
    "district_id": "611001",
    "label": "Nanga Pau",
    "value": "Nanga Pau"
  },
  {
    "id": "6110012021",
    "district_id": "611001",
    "label": "Nanga Entebah",
    "value": "Nanga Entebah"
  },
  {
    "id": "6110012023",
    "district_id": "611001",
    "label": "Nanga Menunuk",
    "value": "Nanga Menunuk"
  },
  {
    "id": "6110012024",
    "district_id": "611001",
    "label": "Sepan Tonak",
    "value": "Sepan Tonak"
  },
  {
    "id": "6110022001",
    "district_id": "611002",
    "label": "Tanjung Niaga",
    "value": "Tanjung Niaga"
  },
  {
    "id": "6110022002",
    "district_id": "611002",
    "label": "Paal",
    "value": "Paal"
  },
  {
    "id": "6110022003",
    "district_id": "611002",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6110022004",
    "district_id": "611002",
    "label": "Tembawang Panjang",
    "value": "Tembawang Panjang"
  },
  {
    "id": "6110022005",
    "district_id": "611002",
    "label": "Poring",
    "value": "Poring"
  },
  {
    "id": "6110022007",
    "district_id": "611002",
    "label": "Nanga Kebebu",
    "value": "Nanga Kebebu"
  },
  {
    "id": "6110022008",
    "district_id": "611002",
    "label": "Nanga Kayan",
    "value": "Nanga Kayan"
  },
  {
    "id": "6110022013",
    "district_id": "611002",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6110022021",
    "district_id": "611002",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "6110022023",
    "district_id": "611002",
    "label": "Tanjung Lay",
    "value": "Tanjung Lay"
  },
  {
    "id": "6110022024",
    "district_id": "611002",
    "label": "Labai Mandiri",
    "value": "Labai Mandiri"
  },
  {
    "id": "6110022026",
    "district_id": "611002",
    "label": "Nusa Pandau",
    "value": "Nusa Pandau"
  },
  {
    "id": "6110022032",
    "district_id": "611002",
    "label": "Kelakik",
    "value": "Kelakik"
  },
  {
    "id": "6110022033",
    "district_id": "611002",
    "label": "Semadin Lengkong",
    "value": "Semadin Lengkong"
  },
  {
    "id": "6110022038",
    "district_id": "611002",
    "label": "Tebing Karangan",
    "value": "Tebing Karangan"
  },
  {
    "id": "6110022040",
    "district_id": "611002",
    "label": "Kenual",
    "value": "Kenual"
  },
  {
    "id": "6110022042",
    "district_id": "611002",
    "label": "Tanjung Tengang",
    "value": "Tanjung Tengang"
  },
  {
    "id": "6110032001",
    "district_id": "611003",
    "label": "Nanga Ella Hilir",
    "value": "Nanga Ella Hilir"
  },
  {
    "id": "6110032002",
    "district_id": "611003",
    "label": "Lengkong Nyadom",
    "value": "Lengkong Nyadom"
  },
  {
    "id": "6110032003",
    "district_id": "611003",
    "label": "Nanga Nuak",
    "value": "Nanga Nuak"
  },
  {
    "id": "6110032004",
    "district_id": "611003",
    "label": "Nanga Kalan",
    "value": "Nanga Kalan"
  },
  {
    "id": "6110032005",
    "district_id": "611003",
    "label": "Nanga Nyuruh",
    "value": "Nanga Nyuruh"
  },
  {
    "id": "6110032006",
    "district_id": "611003",
    "label": "Nanga Kempangai",
    "value": "Nanga Kempangai"
  },
  {
    "id": "6110032007",
    "district_id": "611003",
    "label": "Kerangan Kora",
    "value": "Kerangan Kora"
  },
  {
    "id": "6110032008",
    "district_id": "611003",
    "label": "Penyuguk",
    "value": "Penyuguk"
  },
  {
    "id": "6110032009",
    "district_id": "611003",
    "label": "Popai",
    "value": "Popai"
  },
  {
    "id": "6110032010",
    "district_id": "611003",
    "label": "Kahiya",
    "value": "Kahiya"
  },
  {
    "id": "6110032011",
    "district_id": "611003",
    "label": "Bemban Permai",
    "value": "Bemban Permai"
  },
  {
    "id": "6110032012",
    "district_id": "611003",
    "label": "Perembang Nyuruh",
    "value": "Perembang Nyuruh"
  },
  {
    "id": "6110032013",
    "district_id": "611003",
    "label": "Sungai Labuk",
    "value": "Sungai Labuk"
  },
  {
    "id": "6110032014",
    "district_id": "611003",
    "label": "Sungai Mentoba",
    "value": "Sungai Mentoba"
  },
  {
    "id": "6110032015",
    "district_id": "611003",
    "label": "Natai Compa",
    "value": "Natai Compa"
  },
  {
    "id": "6110032016",
    "district_id": "611003",
    "label": "Pelempai Jaya",
    "value": "Pelempai Jaya"
  },
  {
    "id": "6110032017",
    "district_id": "611003",
    "label": "Domet Permai",
    "value": "Domet Permai"
  },
  {
    "id": "6110032018",
    "district_id": "611003",
    "label": "Nyanggau",
    "value": "Nyanggau"
  },
  {
    "id": "6110032019",
    "district_id": "611003",
    "label": "Jabai",
    "value": "Jabai"
  },
  {
    "id": "6110042001",
    "district_id": "611004",
    "label": "Menukung Kota",
    "value": "Menukung Kota"
  },
  {
    "id": "6110042002",
    "district_id": "611004",
    "label": "Nanga Ella Hulu",
    "value": "Nanga Ella Hulu"
  },
  {
    "id": "6110042003",
    "district_id": "611004",
    "label": "Sungai Sampuk",
    "value": "Sungai Sampuk"
  },
  {
    "id": "6110042004",
    "district_id": "611004",
    "label": "Landau Leban",
    "value": "Landau Leban"
  },
  {
    "id": "6110042005",
    "district_id": "611004",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "6110042006",
    "district_id": "611004",
    "label": "Nanga Melona Satu",
    "value": "Nanga Melona Satu"
  },
  {
    "id": "6110042007",
    "district_id": "611004",
    "label": "Nanga Siyai",
    "value": "Nanga Siyai"
  },
  {
    "id": "6110042008",
    "district_id": "611004",
    "label": "Nanga Mawang Mentatai",
    "value": "Nanga Mawang Mentatai"
  },
  {
    "id": "6110042009",
    "district_id": "611004",
    "label": "Laman Mumbung",
    "value": "Laman Mumbung"
  },
  {
    "id": "6110042010",
    "district_id": "611004",
    "label": "Belaban Ella",
    "value": "Belaban Ella"
  },
  {
    "id": "6110042011",
    "district_id": "611004",
    "label": "Pelaik Keruap",
    "value": "Pelaik Keruap"
  },
  {
    "id": "6110042012",
    "district_id": "611004",
    "label": "Nanga Keruap",
    "value": "Nanga Keruap"
  },
  {
    "id": "6110042013",
    "district_id": "611004",
    "label": "Batas Nangka",
    "value": "Batas Nangka"
  },
  {
    "id": "6110042014",
    "district_id": "611004",
    "label": "Nusa Poring",
    "value": "Nusa Poring"
  },
  {
    "id": "6110042015",
    "district_id": "611004",
    "label": "Batu Onap",
    "value": "Batu Onap"
  },
  {
    "id": "6110042016",
    "district_id": "611004",
    "label": "Batu Badak",
    "value": "Batu Badak"
  },
  {
    "id": "6110042017",
    "district_id": "611004",
    "label": "Oyah",
    "value": "Oyah"
  },
  {
    "id": "6110042018",
    "district_id": "611004",
    "label": "Lihai",
    "value": "Lihai"
  },
  {
    "id": "6110042019",
    "district_id": "611004",
    "label": "Sampak",
    "value": "Sampak"
  },
  {
    "id": "6110052001",
    "district_id": "611005",
    "label": "Nanga Sayan",
    "value": "Nanga Sayan"
  },
  {
    "id": "6110052002",
    "district_id": "611005",
    "label": "Pekawai",
    "value": "Pekawai"
  },
  {
    "id": "6110052003",
    "district_id": "611005",
    "label": "Landau Sandak",
    "value": "Landau Sandak"
  },
  {
    "id": "6110052004",
    "district_id": "611005",
    "label": "Madya Raya",
    "value": "Madya Raya"
  },
  {
    "id": "6110052005",
    "district_id": "611005",
    "label": "Karangan Purun",
    "value": "Karangan Purun"
  },
  {
    "id": "6110052006",
    "district_id": "611005",
    "label": "Mekar Pelita",
    "value": "Mekar Pelita"
  },
  {
    "id": "6110052007",
    "district_id": "611005",
    "label": "Bora",
    "value": "Bora"
  },
  {
    "id": "6110052008",
    "district_id": "611005",
    "label": "Meta Bersatu",
    "value": "Meta Bersatu"
  },
  {
    "id": "6110052009",
    "district_id": "611005",
    "label": "Nanga Kasai",
    "value": "Nanga Kasai"
  },
  {
    "id": "6110052010",
    "district_id": "611005",
    "label": "Nanga Kompi",
    "value": "Nanga Kompi"
  },
  {
    "id": "6110052011",
    "district_id": "611005",
    "label": "Nanga Mancur",
    "value": "Nanga Mancur"
  },
  {
    "id": "6110052012",
    "district_id": "611005",
    "label": "Siling Permai",
    "value": "Siling Permai"
  },
  {
    "id": "6110052013",
    "district_id": "611005",
    "label": "Lingkar Indah",
    "value": "Lingkar Indah"
  },
  {
    "id": "6110052014",
    "district_id": "611005",
    "label": "Sayan Jaya",
    "value": "Sayan Jaya"
  },
  {
    "id": "6110052015",
    "district_id": "611005",
    "label": "Nanga Pak",
    "value": "Nanga Pak"
  },
  {
    "id": "6110052016",
    "district_id": "611005",
    "label": "Tumbak Raya",
    "value": "Tumbak Raya"
  },
  {
    "id": "6110052017",
    "district_id": "611005",
    "label": "Berobai Permai",
    "value": "Berobai Permai"
  },
  {
    "id": "6110052018",
    "district_id": "611005",
    "label": "Nanga Raku",
    "value": "Nanga Raku"
  },
  {
    "id": "6110062001",
    "district_id": "611006",
    "label": "Batu Begigi",
    "value": "Batu Begigi"
  },
  {
    "id": "6110062002",
    "district_id": "611006",
    "label": "Loka Jaya",
    "value": "Loka Jaya"
  },
  {
    "id": "6110062003",
    "district_id": "611006",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6110062004",
    "district_id": "611006",
    "label": "Bina Jaya",
    "value": "Bina Jaya"
  },
  {
    "id": "6110062005",
    "district_id": "611006",
    "label": "Bina Karya",
    "value": "Bina Karya"
  },
  {
    "id": "6110062006",
    "district_id": "611006",
    "label": "Madong Jaya",
    "value": "Madong Jaya"
  },
  {
    "id": "6110062016",
    "district_id": "611006",
    "label": "Bata Luar",
    "value": "Bata Luar"
  },
  {
    "id": "6110062017",
    "district_id": "611006",
    "label": "Maris Permai",
    "value": "Maris Permai"
  },
  {
    "id": "6110062019",
    "district_id": "611006",
    "label": "Pelita Kenaya",
    "value": "Pelita Kenaya"
  },
  {
    "id": "6110062020",
    "district_id": "611006",
    "label": "Tanjung Beringin Raya",
    "value": "Tanjung Beringin Raya"
  },
  {
    "id": "6110062021",
    "district_id": "611006",
    "label": "Tanjung Gunung",
    "value": "Tanjung Gunung"
  },
  {
    "id": "6110062022",
    "district_id": "611006",
    "label": "Keranjik",
    "value": "Keranjik"
  },
  {
    "id": "6110072001",
    "district_id": "611007",
    "label": "Nanga Sokan",
    "value": "Nanga Sokan"
  },
  {
    "id": "6110072002",
    "district_id": "611007",
    "label": "Tanjung Sokan",
    "value": "Tanjung Sokan"
  },
  {
    "id": "6110072003",
    "district_id": "611007",
    "label": "Sijau",
    "value": "Sijau"
  },
  {
    "id": "6110072004",
    "district_id": "611007",
    "label": "Keluing Taja",
    "value": "Keluing Taja"
  },
  {
    "id": "6110072005",
    "district_id": "611007",
    "label": "Nanga Betangai",
    "value": "Nanga Betangai"
  },
  {
    "id": "6110072006",
    "district_id": "611007",
    "label": "Gelata",
    "value": "Gelata"
  },
  {
    "id": "6110072007",
    "district_id": "611007",
    "label": "Nanga Ora",
    "value": "Nanga Ora"
  },
  {
    "id": "6110072008",
    "district_id": "611007",
    "label": "Nanga Libas",
    "value": "Nanga Libas"
  },
  {
    "id": "6110072009",
    "district_id": "611007",
    "label": "Telaga Raya",
    "value": "Telaga Raya"
  },
  {
    "id": "6110072010",
    "district_id": "611007",
    "label": "Teluk Pongkal",
    "value": "Teluk Pongkal"
  },
  {
    "id": "6110072011",
    "district_id": "611007",
    "label": "Nanga Tangkit",
    "value": "Nanga Tangkit"
  },
  {
    "id": "6110072012",
    "district_id": "611007",
    "label": "Penyengkuang",
    "value": "Penyengkuang"
  },
  {
    "id": "6110072013",
    "district_id": "611007",
    "label": "Melana",
    "value": "Melana"
  },
  {
    "id": "6110072014",
    "district_id": "611007",
    "label": "Nanga Potai",
    "value": "Nanga Potai"
  },
  {
    "id": "6110072015",
    "district_id": "611007",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "6110072016",
    "district_id": "611007",
    "label": "Muara Tanjung",
    "value": "Muara Tanjung"
  },
  {
    "id": "6110072017",
    "district_id": "611007",
    "label": "Landau Kabu",
    "value": "Landau Kabu"
  },
  {
    "id": "6110072018",
    "district_id": "611007",
    "label": "Tanjung Mahung",
    "value": "Tanjung Mahung"
  },
  {
    "id": "6110082001",
    "district_id": "611008",
    "label": "Tekelak",
    "value": "Tekelak"
  },
  {
    "id": "6110082002",
    "district_id": "611008",
    "label": "Manding",
    "value": "Manding"
  },
  {
    "id": "6110082003",
    "district_id": "611008",
    "label": "Nanga Belimbing",
    "value": "Nanga Belimbing"
  },
  {
    "id": "6110082004",
    "district_id": "611008",
    "label": "Tengkajau",
    "value": "Tengkajau"
  },
  {
    "id": "6110082005",
    "district_id": "611008",
    "label": "Tanjung Paoh",
    "value": "Tanjung Paoh"
  },
  {
    "id": "6110082006",
    "district_id": "611008",
    "label": "Nanga Man",
    "value": "Nanga Man"
  },
  {
    "id": "6110082007",
    "district_id": "611008",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "6110082008",
    "district_id": "611008",
    "label": "Engkurai",
    "value": "Engkurai"
  },
  {
    "id": "6110082009",
    "district_id": "611008",
    "label": "Merpak",
    "value": "Merpak"
  },
  {
    "id": "6110082010",
    "district_id": "611008",
    "label": "Senibung",
    "value": "Senibung"
  },
  {
    "id": "6110082011",
    "district_id": "611008",
    "label": "Melamut Bersatu",
    "value": "Melamut Bersatu"
  },
  {
    "id": "6110082012",
    "district_id": "611008",
    "label": "Natai Panjang",
    "value": "Natai Panjang"
  },
  {
    "id": "6110082013",
    "district_id": "611008",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "6110082014",
    "district_id": "611008",
    "label": "Merah Arai",
    "value": "Merah Arai"
  },
  {
    "id": "6110082015",
    "district_id": "611008",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6110082016",
    "district_id": "611008",
    "label": "Tanjung Arak",
    "value": "Tanjung Arak"
  },
  {
    "id": "6110082017",
    "district_id": "611008",
    "label": "Melawi Kiri Hilir",
    "value": "Melawi Kiri Hilir"
  },
  {
    "id": "6110082018",
    "district_id": "611008",
    "label": "Kompas Raya",
    "value": "Kompas Raya"
  },
  {
    "id": "6110082019",
    "district_id": "611008",
    "label": "Kayan Semapau",
    "value": "Kayan Semapau"
  },
  {
    "id": "6110092001",
    "district_id": "611009",
    "label": "Manggala",
    "value": "Manggala"
  },
  {
    "id": "6110092002",
    "district_id": "611009",
    "label": "Senempak",
    "value": "Senempak"
  },
  {
    "id": "6110092003",
    "district_id": "611009",
    "label": "Nanga Pintas",
    "value": "Nanga Pintas"
  },
  {
    "id": "6110092004",
    "district_id": "611009",
    "label": "Landau Garong",
    "value": "Landau Garong"
  },
  {
    "id": "6110092005",
    "district_id": "611009",
    "label": "Bina Jaya",
    "value": "Bina Jaya"
  },
  {
    "id": "6110092006",
    "district_id": "611009",
    "label": "Mandau Baru",
    "value": "Mandau Baru"
  },
  {
    "id": "6110092007",
    "district_id": "611009",
    "label": "Pelinggang",
    "value": "Pelinggang"
  },
  {
    "id": "6110092008",
    "district_id": "611009",
    "label": "Nyanggai",
    "value": "Nyanggai"
  },
  {
    "id": "6110092009",
    "district_id": "611009",
    "label": "Sungai Bakah",
    "value": "Sungai Bakah"
  },
  {
    "id": "6110092010",
    "district_id": "611009",
    "label": "Bayur Raya",
    "value": "Bayur Raya"
  },
  {
    "id": "6110092011",
    "district_id": "611009",
    "label": "Landau Tubun",
    "value": "Landau Tubun"
  },
  {
    "id": "6110092012",
    "district_id": "611009",
    "label": "Nanga Kelawai",
    "value": "Nanga Kelawai"
  },
  {
    "id": "6110102001",
    "district_id": "611010",
    "label": "Tiong Keranji",
    "value": "Tiong Keranji"
  },
  {
    "id": "6110102002",
    "district_id": "611010",
    "label": "Nanga Tikan",
    "value": "Nanga Tikan"
  },
  {
    "id": "6110102003",
    "district_id": "611010",
    "label": "Beloyang",
    "value": "Beloyang"
  },
  {
    "id": "6110102004",
    "district_id": "611010",
    "label": "Nanga Raya",
    "value": "Nanga Raya"
  },
  {
    "id": "6110102005",
    "district_id": "611010",
    "label": "Junjung Permai",
    "value": "Junjung Permai"
  },
  {
    "id": "6110102006",
    "district_id": "611010",
    "label": "Nanga Keberak",
    "value": "Nanga Keberak"
  },
  {
    "id": "6110102007",
    "district_id": "611010",
    "label": "Piawas",
    "value": "Piawas"
  },
  {
    "id": "6110102008",
    "district_id": "611010",
    "label": "Kayu Bunga",
    "value": "Kayu Bunga"
  },
  {
    "id": "6110112001",
    "district_id": "611011",
    "label": "Ulak Muid",
    "value": "Ulak Muid"
  },
  {
    "id": "6110112002",
    "district_id": "611011",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "6110112003",
    "district_id": "611011",
    "label": "Durian Jaya",
    "value": "Durian Jaya"
  },
  {
    "id": "6110112004",
    "district_id": "611011",
    "label": "Togan Baru",
    "value": "Togan Baru"
  },
  {
    "id": "6110112005",
    "district_id": "611011",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "6110112006",
    "district_id": "611011",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "6110112007",
    "district_id": "611011",
    "label": "Lintah Taum",
    "value": "Lintah Taum"
  },
  {
    "id": "6110112008",
    "district_id": "611011",
    "label": "Laja",
    "value": "Laja"
  },
  {
    "id": "6110112009",
    "district_id": "611011",
    "label": "Keluas Hulu",
    "value": "Keluas Hulu"
  },
  {
    "id": "6110112010",
    "district_id": "611011",
    "label": "Ganjang",
    "value": "Ganjang"
  },
  {
    "id": "6111012001",
    "district_id": "611101",
    "label": "Sutera",
    "value": "Sutera"
  },
  {
    "id": "6111012002",
    "district_id": "611101",
    "label": "Pangkalan Buton",
    "value": "Pangkalan Buton"
  },
  {
    "id": "6111012003",
    "district_id": "611101",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "6111012004",
    "district_id": "611101",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "6111012005",
    "district_id": "611101",
    "label": "Benawai Agung",
    "value": "Benawai Agung"
  },
  {
    "id": "6111012006",
    "district_id": "611101",
    "label": "Harapan Mulia",
    "value": "Harapan Mulia"
  },
  {
    "id": "6111012007",
    "district_id": "611101",
    "label": "Pampang Harapan",
    "value": "Pampang Harapan"
  },
  {
    "id": "6111012008",
    "district_id": "611101",
    "label": "Sedahan Jaya",
    "value": "Sedahan Jaya"
  },
  {
    "id": "6111012009",
    "district_id": "611101",
    "label": "Gunungsembilan",
    "value": "Gunungsembilan"
  },
  {
    "id": "6111012010",
    "district_id": "611101",
    "label": "Riam Berasap Jaya",
    "value": "Riam Berasap Jaya"
  },
  {
    "id": "6111022001",
    "district_id": "611102",
    "label": "Telukmelano",
    "value": "Telukmelano"
  },
  {
    "id": "6111022002",
    "district_id": "611102",
    "label": "Nipah Kuning",
    "value": "Nipah Kuning"
  },
  {
    "id": "6111022003",
    "district_id": "611102",
    "label": "Pemangkat",
    "value": "Pemangkat"
  },
  {
    "id": "6111022004",
    "district_id": "611102",
    "label": "Padu Banjar",
    "value": "Padu Banjar"
  },
  {
    "id": "6111022005",
    "district_id": "611102",
    "label": "Penjalaan",
    "value": "Penjalaan"
  },
  {
    "id": "6111022006",
    "district_id": "611102",
    "label": "Sungaimata-mata",
    "value": "Sungaimata-mata"
  },
  {
    "id": "6111022007",
    "district_id": "611102",
    "label": "Batu Barat",
    "value": "Batu Barat"
  },
  {
    "id": "6111022008",
    "district_id": "611102",
    "label": "Pulaukumbang",
    "value": "Pulaukumbang"
  },
  {
    "id": "6111022009",
    "district_id": "611102",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6111022010",
    "district_id": "611102",
    "label": "Matan Jaya",
    "value": "Matan Jaya"
  },
  {
    "id": "6111022011",
    "district_id": "611102",
    "label": "Medan Jaya",
    "value": "Medan Jaya"
  },
  {
    "id": "6111022012",
    "district_id": "611102",
    "label": "Lubuk Batu",
    "value": "Lubuk Batu"
  },
  {
    "id": "6111032001",
    "district_id": "611103",
    "label": "Mas Bangun",
    "value": "Mas Bangun"
  },
  {
    "id": "6111032002",
    "district_id": "611103",
    "label": "Alur Bandung",
    "value": "Alur Bandung"
  },
  {
    "id": "6111032003",
    "district_id": "611103",
    "label": "Telukbatang",
    "value": "Telukbatang"
  },
  {
    "id": "6111032004",
    "district_id": "611103",
    "label": "Sungaipaduan",
    "value": "Sungaipaduan"
  },
  {
    "id": "6111032005",
    "district_id": "611103",
    "label": "Banyu Abang",
    "value": "Banyu Abang"
  },
  {
    "id": "6111032006",
    "district_id": "611103",
    "label": "Telukbatang Selatan",
    "value": "Telukbatang Selatan"
  },
  {
    "id": "6111032007",
    "district_id": "611103",
    "label": "Telukbatang Utara",
    "value": "Telukbatang Utara"
  },
  {
    "id": "6111042001",
    "district_id": "611104",
    "label": "Tanjungsatai",
    "value": "Tanjungsatai"
  },
  {
    "id": "6111042002",
    "district_id": "611104",
    "label": "Kemboja",
    "value": "Kemboja"
  },
  {
    "id": "6111042003",
    "district_id": "611104",
    "label": "Dusun Kecil",
    "value": "Dusun Kecil"
  },
  {
    "id": "6111042004",
    "district_id": "611104",
    "label": "Dusun Besar",
    "value": "Dusun Besar"
  },
  {
    "id": "6111042006",
    "district_id": "611104",
    "label": "Satai Lestari",
    "value": "Satai Lestari"
  },
  {
    "id": "6111052001",
    "district_id": "611105",
    "label": "Seponti Jaya",
    "value": "Seponti Jaya"
  },
  {
    "id": "6111052002",
    "district_id": "611105",
    "label": "Telaga Arum",
    "value": "Telaga Arum"
  },
  {
    "id": "6111052003",
    "district_id": "611105",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "6111052004",
    "district_id": "611105",
    "label": "Podorukun",
    "value": "Podorukun"
  },
  {
    "id": "6111052005",
    "district_id": "611105",
    "label": "Sungai Sepeti",
    "value": "Sungai Sepeti"
  },
  {
    "id": "6111052006",
    "district_id": "611105",
    "label": "Durian Sebatang",
    "value": "Durian Sebatang"
  },
  {
    "id": "6111062001",
    "district_id": "611106",
    "label": "Pelapis",
    "value": "Pelapis"
  },
  {
    "id": "6111062002",
    "district_id": "611106",
    "label": "Betok Jaya",
    "value": "Betok Jaya"
  },
  {
    "id": "6111062003",
    "district_id": "611106",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "6112012001",
    "district_id": "611201",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "6112012002",
    "district_id": "611201",
    "label": "Sungai Ambangah",
    "value": "Sungai Ambangah"
  },
  {
    "id": "6112012003",
    "district_id": "611201",
    "label": "Arang Limbung",
    "value": "Arang Limbung"
  },
  {
    "id": "6112012004",
    "district_id": "611201",
    "label": "Kuala Dua",
    "value": "Kuala Dua"
  },
  {
    "id": "6112012005",
    "district_id": "611201",
    "label": "Tebang Kacang",
    "value": "Tebang Kacang"
  },
  {
    "id": "6112012006",
    "district_id": "611201",
    "label": "Sungai Asam",
    "value": "Sungai Asam"
  },
  {
    "id": "6112012007",
    "district_id": "611201",
    "label": "Pulau Limbung",
    "value": "Pulau Limbung"
  },
  {
    "id": "6112012008",
    "district_id": "611201",
    "label": "Kapur",
    "value": "Kapur"
  },
  {
    "id": "6112012009",
    "district_id": "611201",
    "label": "Gunung Tamang",
    "value": "Gunung Tamang"
  },
  {
    "id": "6112012010",
    "district_id": "611201",
    "label": "Sungai Bulan",
    "value": "Sungai Bulan"
  },
  {
    "id": "6112012011",
    "district_id": "611201",
    "label": "Limbung",
    "value": "Limbung"
  },
  {
    "id": "6112012012",
    "district_id": "611201",
    "label": "Teluk Kapuas",
    "value": "Teluk Kapuas"
  },
  {
    "id": "6112012013",
    "district_id": "611201",
    "label": "Madu Sari",
    "value": "Madu Sari"
  },
  {
    "id": "6112012014",
    "district_id": "611201",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6112012015",
    "district_id": "611201",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "6112012016",
    "district_id": "611201",
    "label": "Sungai Raya Dalam",
    "value": "Sungai Raya Dalam"
  },
  {
    "id": "6112012017",
    "district_id": "611201",
    "label": "Parit Baru",
    "value": "Parit Baru"
  },
  {
    "id": "6112012018",
    "district_id": "611201",
    "label": "Pulau Jambu",
    "value": "Pulau Jambu"
  },
  {
    "id": "6112012019",
    "district_id": "611201",
    "label": "Kalibandung",
    "value": "Kalibandung"
  },
  {
    "id": "6112012020",
    "district_id": "611201",
    "label": "Muara Baru",
    "value": "Muara Baru"
  },
  {
    "id": "6112022001",
    "district_id": "611202",
    "label": "Kuala Mandor B",
    "value": "Kuala Mandor B"
  },
  {
    "id": "6112022002",
    "district_id": "611202",
    "label": "Kubu Padi",
    "value": "Kubu Padi"
  },
  {
    "id": "6112022003",
    "district_id": "611202",
    "label": "Kuala Mandor A",
    "value": "Kuala Mandor A"
  },
  {
    "id": "6112022004",
    "district_id": "611202",
    "label": "Retok",
    "value": "Retok"
  },
  {
    "id": "6112022005",
    "district_id": "611202",
    "label": "Sungai Enau",
    "value": "Sungai Enau"
  },
  {
    "id": "6112032001",
    "district_id": "611203",
    "label": "Simpang Kanan",
    "value": "Simpang Kanan"
  },
  {
    "id": "6112032002",
    "district_id": "611203",
    "label": "Mega Timur",
    "value": "Mega Timur"
  },
  {
    "id": "6112032003",
    "district_id": "611203",
    "label": "Bengkarek",
    "value": "Bengkarek"
  },
  {
    "id": "6112032004",
    "district_id": "611203",
    "label": "Lingga",
    "value": "Lingga"
  },
  {
    "id": "6112032005",
    "district_id": "611203",
    "label": "Sungai Ambawang Kuala",
    "value": "Sungai Ambawang Kuala"
  },
  {
    "id": "6112032006",
    "district_id": "611203",
    "label": "Jawa Tengah",
    "value": "Jawa Tengah"
  },
  {
    "id": "6112032007",
    "district_id": "611203",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "6112032008",
    "district_id": "611203",
    "label": "Pancaroba",
    "value": "Pancaroba"
  },
  {
    "id": "6112032009",
    "district_id": "611203",
    "label": "Puguk",
    "value": "Puguk"
  },
  {
    "id": "6112032010",
    "district_id": "611203",
    "label": "Korek",
    "value": "Korek"
  },
  {
    "id": "6112032011",
    "district_id": "611203",
    "label": "Pasak",
    "value": "Pasak"
  },
  {
    "id": "6112032012",
    "district_id": "611203",
    "label": "Pasak Piang",
    "value": "Pasak Piang"
  },
  {
    "id": "6112032013",
    "district_id": "611203",
    "label": "Teluk Bakung",
    "value": "Teluk Bakung"
  },
  {
    "id": "6112032015",
    "district_id": "611203",
    "label": "Sungai Malaya",
    "value": "Sungai Malaya"
  },
  {
    "id": "6112042002",
    "district_id": "611204",
    "label": "Teluk Empening",
    "value": "Teluk Empening"
  },
  {
    "id": "6112042003",
    "district_id": "611204",
    "label": "Terentang Hilir",
    "value": "Terentang Hilir"
  },
  {
    "id": "6112042004",
    "district_id": "611204",
    "label": "Terentang Hulu",
    "value": "Terentang Hulu"
  },
  {
    "id": "6112042005",
    "district_id": "611204",
    "label": "Permata",
    "value": "Permata"
  },
  {
    "id": "6112042006",
    "district_id": "611204",
    "label": "Betuah",
    "value": "Betuah"
  },
  {
    "id": "6112042007",
    "district_id": "611204",
    "label": "Sungai Radak Satu",
    "value": "Sungai Radak Satu"
  },
  {
    "id": "6112042008",
    "district_id": "611204",
    "label": "Sungai Radak Dua",
    "value": "Sungai Radak Dua"
  },
  {
    "id": "6112042009",
    "district_id": "611204",
    "label": "Sungai Dungun",
    "value": "Sungai Dungun"
  },
  {
    "id": "6112042010",
    "district_id": "611204",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "6112042011",
    "district_id": "611204",
    "label": "Radak Baru",
    "value": "Radak Baru"
  },
  {
    "id": "6112052001",
    "district_id": "611205",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "6112052002",
    "district_id": "611205",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6112052003",
    "district_id": "611205",
    "label": "Teluk Nibung",
    "value": "Teluk Nibung"
  },
  {
    "id": "6112052004",
    "district_id": "611205",
    "label": "Padang Tikar Satu",
    "value": "Padang Tikar Satu"
  },
  {
    "id": "6112052005",
    "district_id": "611205",
    "label": "Padang Tikar Dua",
    "value": "Padang Tikar Dua"
  },
  {
    "id": "6112052006",
    "district_id": "611205",
    "label": "Tasik Malaya",
    "value": "Tasik Malaya"
  },
  {
    "id": "6112052007",
    "district_id": "611205",
    "label": "Sungai Besar",
    "value": "Sungai Besar"
  },
  {
    "id": "6112052008",
    "district_id": "611205",
    "label": "Sungai Jawi",
    "value": "Sungai Jawi"
  },
  {
    "id": "6112052009",
    "district_id": "611205",
    "label": "Nipah Panjang",
    "value": "Nipah Panjang"
  },
  {
    "id": "6112052010",
    "district_id": "611205",
    "label": "Ambarawa",
    "value": "Ambarawa"
  },
  {
    "id": "6112052011",
    "district_id": "611205",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6112052012",
    "district_id": "611205",
    "label": "Sungai Kerawang",
    "value": "Sungai Kerawang"
  },
  {
    "id": "6112052013",
    "district_id": "611205",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "6112052014",
    "district_id": "611205",
    "label": "Muara Tiga",
    "value": "Muara Tiga"
  },
  {
    "id": "6112052015",
    "district_id": "611205",
    "label": "Medan Mas",
    "value": "Medan Mas"
  },
  {
    "id": "6112062001",
    "district_id": "611206",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "6112062002",
    "district_id": "611206",
    "label": "Olak-Olak Kubu",
    "value": "Olak-Olak Kubu"
  },
  {
    "id": "6112062003",
    "district_id": "611206",
    "label": "Sungai Selamat",
    "value": "Sungai Selamat"
  },
  {
    "id": "6112062004",
    "district_id": "611206",
    "label": "Sungai Bemban",
    "value": "Sungai Bemban"
  },
  {
    "id": "6112062005",
    "district_id": "611206",
    "label": "Pinang Luar",
    "value": "Pinang Luar"
  },
  {
    "id": "6112062006",
    "district_id": "611206",
    "label": "Seruat Dua",
    "value": "Seruat Dua"
  },
  {
    "id": "6112062007",
    "district_id": "611206",
    "label": "Seruat Tiga",
    "value": "Seruat Tiga"
  },
  {
    "id": "6112062008",
    "district_id": "611206",
    "label": "Dabong",
    "value": "Dabong"
  },
  {
    "id": "6112062009",
    "district_id": "611206",
    "label": "Ambawang",
    "value": "Ambawang"
  },
  {
    "id": "6112062010",
    "district_id": "611206",
    "label": "Sepakat Baru",
    "value": "Sepakat Baru"
  },
  {
    "id": "6112062011",
    "district_id": "611206",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6112062012",
    "district_id": "611206",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "6112062013",
    "district_id": "611206",
    "label": "Teluk Nangka",
    "value": "Teluk Nangka"
  },
  {
    "id": "6112062014",
    "district_id": "611206",
    "label": "Jangkang Satu",
    "value": "Jangkang Satu"
  },
  {
    "id": "6112062015",
    "district_id": "611206",
    "label": "Jangkang Dua",
    "value": "Jangkang Dua"
  },
  {
    "id": "6112062016",
    "district_id": "611206",
    "label": "Pinang Dalam",
    "value": "Pinang Dalam"
  },
  {
    "id": "6112062017",
    "district_id": "611206",
    "label": "Sungai Terus",
    "value": "Sungai Terus"
  },
  {
    "id": "6112062018",
    "district_id": "611206",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "6112062019",
    "district_id": "611206",
    "label": "Mengkalang",
    "value": "Mengkalang"
  },
  {
    "id": "6112062020",
    "district_id": "611206",
    "label": "Mengkalang Jambu",
    "value": "Mengkalang Jambu"
  },
  {
    "id": "6112072001",
    "district_id": "611207",
    "label": "Rasau Jaya Umum",
    "value": "Rasau Jaya Umum"
  },
  {
    "id": "6112072002",
    "district_id": "611207",
    "label": "Rasau Jaya Satu",
    "value": "Rasau Jaya Satu"
  },
  {
    "id": "6112072003",
    "district_id": "611207",
    "label": "Rasau Jaya Dua",
    "value": "Rasau Jaya Dua"
  },
  {
    "id": "6112072004",
    "district_id": "611207",
    "label": "Rasau Jaya Tiga",
    "value": "Rasau Jaya Tiga"
  },
  {
    "id": "6112072005",
    "district_id": "611207",
    "label": "Bintang Mas",
    "value": "Bintang Mas"
  },
  {
    "id": "6112072006",
    "district_id": "611207",
    "label": "Pematang Tujuh",
    "value": "Pematang Tujuh"
  },
  {
    "id": "6112082001",
    "district_id": "611208",
    "label": "Teluk Pakedai Hulu",
    "value": "Teluk Pakedai Hulu"
  },
  {
    "id": "6112082002",
    "district_id": "611208",
    "label": "Teluk Pakedai Satu",
    "value": "Teluk Pakedai Satu"
  },
  {
    "id": "6112082003",
    "district_id": "611208",
    "label": "Teluk Pakedai Dua",
    "value": "Teluk Pakedai Dua"
  },
  {
    "id": "6112082004",
    "district_id": "611208",
    "label": "Kuala Karang",
    "value": "Kuala Karang"
  },
  {
    "id": "6112082005",
    "district_id": "611208",
    "label": "Seruat Satu",
    "value": "Seruat Satu"
  },
  {
    "id": "6112082006",
    "district_id": "611208",
    "label": "Sungai Deras",
    "value": "Sungai Deras"
  },
  {
    "id": "6112082007",
    "district_id": "611208",
    "label": "Selat Remis",
    "value": "Selat Remis"
  },
  {
    "id": "6112082008",
    "district_id": "611208",
    "label": "Madura",
    "value": "Madura"
  },
  {
    "id": "6112082009",
    "district_id": "611208",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "6112082010",
    "district_id": "611208",
    "label": "Teluk Gelam",
    "value": "Teluk Gelam"
  },
  {
    "id": "6112082011",
    "district_id": "611208",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "6112082012",
    "district_id": "611208",
    "label": "Sungai Nibung",
    "value": "Sungai Nibung"
  },
  {
    "id": "6112082013",
    "district_id": "611208",
    "label": "Arus Deras",
    "value": "Arus Deras"
  },
  {
    "id": "6112082014",
    "district_id": "611208",
    "label": "Sungai Nipah",
    "value": "Sungai Nipah"
  },
  {
    "id": "6112092001",
    "district_id": "611209",
    "label": "Sungai Kakap",
    "value": "Sungai Kakap"
  },
  {
    "id": "6112092002",
    "district_id": "611209",
    "label": "Sungai Itik",
    "value": "Sungai Itik"
  },
  {
    "id": "6112092003",
    "district_id": "611209",
    "label": "Jeruju Besar",
    "value": "Jeruju Besar"
  },
  {
    "id": "6112092004",
    "district_id": "611209",
    "label": "Sungai Kupah",
    "value": "Sungai Kupah"
  },
  {
    "id": "6112092005",
    "district_id": "611209",
    "label": "Sungai Rengas",
    "value": "Sungai Rengas"
  },
  {
    "id": "6112092006",
    "district_id": "611209",
    "label": "Pal Sembilan",
    "value": "Pal Sembilan"
  },
  {
    "id": "6112092007",
    "district_id": "611209",
    "label": "Sungai Belidak",
    "value": "Sungai Belidak"
  },
  {
    "id": "6112092008",
    "district_id": "611209",
    "label": "Kalimas",
    "value": "Kalimas"
  },
  {
    "id": "6112092009",
    "district_id": "611209",
    "label": "Pungur Kecil",
    "value": "Pungur Kecil"
  },
  {
    "id": "6112092010",
    "district_id": "611209",
    "label": "Pungur Besar",
    "value": "Pungur Besar"
  },
  {
    "id": "6112092011",
    "district_id": "611209",
    "label": "Tanjung Saleh",
    "value": "Tanjung Saleh"
  },
  {
    "id": "6112092012",
    "district_id": "611209",
    "label": "Sepuk Laut",
    "value": "Sepuk Laut"
  },
  {
    "id": "6112092013",
    "district_id": "611209",
    "label": "Punggur Kapuas",
    "value": "Punggur Kapuas"
  },
  {
    "id": "6171011002",
    "district_id": "617101",
    "label": "Benuamelayu Laut",
    "value": "Benuamelayu Laut"
  },
  {
    "id": "6171011003",
    "district_id": "617101",
    "label": "Benuamelayu Darat",
    "value": "Benuamelayu Darat"
  },
  {
    "id": "6171011004",
    "district_id": "617101",
    "label": "Parittokaya",
    "value": "Parittokaya"
  },
  {
    "id": "6171011005",
    "district_id": "617101",
    "label": "Kotabaru",
    "value": "Kotabaru"
  },
  {
    "id": "6171011006",
    "district_id": "617101",
    "label": "Akcaya",
    "value": "Akcaya"
  },
  {
    "id": "6171021001",
    "district_id": "617102",
    "label": "Paritmayor",
    "value": "Paritmayor"
  },
  {
    "id": "6171021002",
    "district_id": "617102",
    "label": "Banjar Serasan",
    "value": "Banjar Serasan"
  },
  {
    "id": "6171021003",
    "district_id": "617102",
    "label": "Saigon",
    "value": "Saigon"
  },
  {
    "id": "6171021004",
    "district_id": "617102",
    "label": "Tanjunghulu",
    "value": "Tanjunghulu"
  },
  {
    "id": "6171021005",
    "district_id": "617102",
    "label": "Tanjunghilir",
    "value": "Tanjunghilir"
  },
  {
    "id": "6171021006",
    "district_id": "617102",
    "label": "Dalambugis",
    "value": "Dalambugis"
  },
  {
    "id": "6171021007",
    "district_id": "617102",
    "label": "Tambelansampit",
    "value": "Tambelansampit"
  },
  {
    "id": "6171031001",
    "district_id": "617103",
    "label": "Pallima",
    "value": "Pallima"
  },
  {
    "id": "6171031002",
    "district_id": "617103",
    "label": "Sungaijawi Dalam",
    "value": "Sungaijawi Dalam"
  },
  {
    "id": "6171031003",
    "district_id": "617103",
    "label": "Sungaijawi Luar",
    "value": "Sungaijawi Luar"
  },
  {
    "id": "6171031004",
    "district_id": "617103",
    "label": "Sungaibeliung",
    "value": "Sungaibeliung"
  },
  {
    "id": "6171041001",
    "district_id": "617104",
    "label": "Siantan Hulu",
    "value": "Siantan Hulu"
  },
  {
    "id": "6171041002",
    "district_id": "617104",
    "label": "Siantan Tengah",
    "value": "Siantan Tengah"
  },
  {
    "id": "6171041003",
    "district_id": "617104",
    "label": "Siantan Hilir",
    "value": "Siantan Hilir"
  },
  {
    "id": "6171041004",
    "district_id": "617104",
    "label": "Batulayang",
    "value": "Batulayang"
  },
  {
    "id": "6171051001",
    "district_id": "617105",
    "label": "Sungaibangkong",
    "value": "Sungaibangkong"
  },
  {
    "id": "6171051002",
    "district_id": "617105",
    "label": "Daratsekip",
    "value": "Daratsekip"
  },
  {
    "id": "6171051003",
    "district_id": "617105",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "6171051004",
    "district_id": "617105",
    "label": "Mariana",
    "value": "Mariana"
  },
  {
    "id": "6171051005",
    "district_id": "617105",
    "label": "Sungaijawi",
    "value": "Sungaijawi"
  },
  {
    "id": "6171061001",
    "district_id": "617106",
    "label": "Bansir Laut",
    "value": "Bansir Laut"
  },
  {
    "id": "6171061002",
    "district_id": "617106",
    "label": "Bansir Darat",
    "value": "Bansir Darat"
  },
  {
    "id": "6171061003",
    "district_id": "617106",
    "label": "Bangka Belitung Laut",
    "value": "Bangka Belitung Laut"
  },
  {
    "id": "6171061004",
    "district_id": "617106",
    "label": "Bangka Belitung Darat",
    "value": "Bangka Belitung Darat"
  },
  {
    "id": "6172011001",
    "district_id": "617201",
    "label": "Roban",
    "value": "Roban"
  },
  {
    "id": "6172011002",
    "district_id": "617201",
    "label": "Condong",
    "value": "Condong"
  },
  {
    "id": "6172011003",
    "district_id": "617201",
    "label": "Sekip Lama",
    "value": "Sekip Lama"
  },
  {
    "id": "6172011004",
    "district_id": "617201",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "6172011005",
    "district_id": "617201",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "6172011006",
    "district_id": "617201",
    "label": "Sungai Wie",
    "value": "Sungai Wie"
  },
  {
    "id": "6172021001",
    "district_id": "617202",
    "label": "Pasiran",
    "value": "Pasiran"
  },
  {
    "id": "6172021002",
    "district_id": "617202",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "6172021003",
    "district_id": "617202",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "6172021004",
    "district_id": "617202",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "6172031001",
    "district_id": "617203",
    "label": "Pajintan",
    "value": "Pajintan"
  },
  {
    "id": "6172031002",
    "district_id": "617203",
    "label": "Nyarumkop",
    "value": "Nyarumkop"
  },
  {
    "id": "6172031003",
    "district_id": "617203",
    "label": "Maya Sopa",
    "value": "Maya Sopa"
  },
  {
    "id": "6172031004",
    "district_id": "617203",
    "label": "Bagak Sahwa",
    "value": "Bagak Sahwa"
  },
  {
    "id": "6172031005",
    "district_id": "617203",
    "label": "Sanggau Kulor",
    "value": "Sanggau Kulor"
  },
  {
    "id": "6172041001",
    "district_id": "617204",
    "label": "Sungai Garam Hilir",
    "value": "Sungai Garam Hilir"
  },
  {
    "id": "6172041002",
    "district_id": "617204",
    "label": "Naram",
    "value": "Naram"
  },
  {
    "id": "6172041003",
    "district_id": "617204",
    "label": "Sungai Bulan",
    "value": "Sungai Bulan"
  },
  {
    "id": "6172041004",
    "district_id": "617204",
    "label": "Sungai Rasau",
    "value": "Sungai Rasau"
  },
  {
    "id": "6172041005",
    "district_id": "617204",
    "label": "Setapuk Kecil",
    "value": "Setapuk Kecil"
  },
  {
    "id": "6172041006",
    "district_id": "617204",
    "label": "Setapuk Besar",
    "value": "Setapuk Besar"
  },
  {
    "id": "6172041007",
    "district_id": "617204",
    "label": "Semelagi Kecil",
    "value": "Semelagi Kecil"
  },
  {
    "id": "6172051001",
    "district_id": "617205",
    "label": "Sedau",
    "value": "Sedau"
  },
  {
    "id": "6172051002",
    "district_id": "617205",
    "label": "Sagatani",
    "value": "Sagatani"
  },
  {
    "id": "6172051003",
    "district_id": "617205",
    "label": "Sijangkung",
    "value": "Sijangkung"
  },
  {
    "id": "6172051004",
    "district_id": "617205",
    "label": "Pangmilang",
    "value": "Pangmilang"
  },
  {
    "id": "6201011011",
    "district_id": "620101",
    "label": "Candi",
    "value": "Candi"
  },
  {
    "id": "6201011012",
    "district_id": "620101",
    "label": "Kumai Hulu",
    "value": "Kumai Hulu"
  },
  {
    "id": "6201011013",
    "district_id": "620101",
    "label": "Kumai Hilir",
    "value": "Kumai Hilir"
  },
  {
    "id": "6201012001",
    "district_id": "620101",
    "label": "Sungai Cabang",
    "value": "Sungai Cabang"
  },
  {
    "id": "6201012002",
    "district_id": "620101",
    "label": "Teluk Pulai",
    "value": "Teluk Pulai"
  },
  {
    "id": "6201012003",
    "district_id": "620101",
    "label": "Sabuai",
    "value": "Sabuai"
  },
  {
    "id": "6201012004",
    "district_id": "620101",
    "label": "Keraya",
    "value": "Keraya"
  },
  {
    "id": "6201012005",
    "district_id": "620101",
    "label": "Teluk Bogam",
    "value": "Teluk Bogam"
  },
  {
    "id": "6201012006",
    "district_id": "620101",
    "label": "Sungai Bakau",
    "value": "Sungai Bakau"
  },
  {
    "id": "6201012007",
    "district_id": "620101",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "6201012008",
    "district_id": "620101",
    "label": "Sungai Kapitan",
    "value": "Sungai Kapitan"
  },
  {
    "id": "6201012009",
    "district_id": "620101",
    "label": "Batu Belaman",
    "value": "Batu Belaman"
  },
  {
    "id": "6201012010",
    "district_id": "620101",
    "label": "Sungai Tendang",
    "value": "Sungai Tendang"
  },
  {
    "id": "6201012014",
    "district_id": "620101",
    "label": "Sungai Sekonyer",
    "value": "Sungai Sekonyer"
  },
  {
    "id": "6201012015",
    "district_id": "620101",
    "label": "Sungai Bedaun",
    "value": "Sungai Bedaun"
  },
  {
    "id": "6201012017",
    "district_id": "620101",
    "label": "Bumi Harjo",
    "value": "Bumi Harjo"
  },
  {
    "id": "6201012032",
    "district_id": "620101",
    "label": "Pangkalan Satu",
    "value": "Pangkalan Satu"
  },
  {
    "id": "6201012034",
    "district_id": "620101",
    "label": "Sabuai Timur",
    "value": "Sabuai Timur"
  },
  {
    "id": "6201021002",
    "district_id": "620102",
    "label": "Mendawai Seberang",
    "value": "Mendawai Seberang"
  },
  {
    "id": "6201021003",
    "district_id": "620102",
    "label": "Mendawai",
    "value": "Mendawai"
  },
  {
    "id": "6201021005",
    "district_id": "620102",
    "label": "Madurejo",
    "value": "Madurejo"
  },
  {
    "id": "6201021006",
    "district_id": "620102",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "6201021007",
    "district_id": "620102",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "6201021008",
    "district_id": "620102",
    "label": "Raja Seberang",
    "value": "Raja Seberang"
  },
  {
    "id": "6201021009",
    "district_id": "620102",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6201022001",
    "district_id": "620102",
    "label": "Tanjung Putri",
    "value": "Tanjung Putri"
  },
  {
    "id": "6201022004",
    "district_id": "620102",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "6201022010",
    "district_id": "620102",
    "label": "Rangda",
    "value": "Rangda"
  },
  {
    "id": "6201022011",
    "district_id": "620102",
    "label": "Kenambui",
    "value": "Kenambui"
  },
  {
    "id": "6201022012",
    "district_id": "620102",
    "label": "Runtu",
    "value": "Runtu"
  },
  {
    "id": "6201022013",
    "district_id": "620102",
    "label": "Umpang",
    "value": "Umpang"
  },
  {
    "id": "6201022014",
    "district_id": "620102",
    "label": "Kumpai Batu Bawah",
    "value": "Kumpai Batu Bawah"
  },
  {
    "id": "6201022015",
    "district_id": "620102",
    "label": "Kumpai Batu Atas",
    "value": "Kumpai Batu Atas"
  },
  {
    "id": "6201022016",
    "district_id": "620102",
    "label": "Natai Raya",
    "value": "Natai Raya"
  },
  {
    "id": "6201022017",
    "district_id": "620102",
    "label": "Medang Sari",
    "value": "Medang Sari"
  },
  {
    "id": "6201022018",
    "district_id": "620102",
    "label": "Natai Baru",
    "value": "Natai Baru"
  },
  {
    "id": "6201022019",
    "district_id": "620102",
    "label": "Tanjung Terantang",
    "value": "Tanjung Terantang"
  },
  {
    "id": "6201022020",
    "district_id": "620102",
    "label": "Sulung",
    "value": "Sulung"
  },
  {
    "id": "6201031007",
    "district_id": "620103",
    "label": "Kotawaringin Hulu",
    "value": "Kotawaringin Hulu"
  },
  {
    "id": "6201031008",
    "district_id": "620103",
    "label": "Kotawaringin Hilir",
    "value": "Kotawaringin Hilir"
  },
  {
    "id": "6201032001",
    "district_id": "620103",
    "label": "Babual Baboti",
    "value": "Babual Baboti"
  },
  {
    "id": "6201032002",
    "district_id": "620103",
    "label": "Tempayung",
    "value": "Tempayung"
  },
  {
    "id": "6201032003",
    "district_id": "620103",
    "label": "Sakabulin",
    "value": "Sakabulin"
  },
  {
    "id": "6201032004",
    "district_id": "620103",
    "label": "Kinjil",
    "value": "Kinjil"
  },
  {
    "id": "6201032005",
    "district_id": "620103",
    "label": "Dawak",
    "value": "Dawak"
  },
  {
    "id": "6201032006",
    "district_id": "620103",
    "label": "Riam Durian",
    "value": "Riam Durian"
  },
  {
    "id": "6201032009",
    "district_id": "620103",
    "label": "Lalang",
    "value": "Lalang"
  },
  {
    "id": "6201032010",
    "district_id": "620103",
    "label": "Rungun",
    "value": "Rungun"
  },
  {
    "id": "6201032011",
    "district_id": "620103",
    "label": "Kondang",
    "value": "Kondang"
  },
  {
    "id": "6201032012",
    "district_id": "620103",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "6201032013",
    "district_id": "620103",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "6201032014",
    "district_id": "620103",
    "label": "Sumber Mukti",
    "value": "Sumber Mukti"
  },
  {
    "id": "6201032015",
    "district_id": "620103",
    "label": "Palih Baru",
    "value": "Palih Baru"
  },
  {
    "id": "6201032016",
    "district_id": "620103",
    "label": "Ipuh Bangun Jaya",
    "value": "Ipuh Bangun Jaya"
  },
  {
    "id": "6201032017",
    "district_id": "620103",
    "label": "Sagu Suka Mulya",
    "value": "Sagu Suka Mulya"
  },
  {
    "id": "6201041002",
    "district_id": "620104",
    "label": "Pangkut",
    "value": "Pangkut"
  },
  {
    "id": "6201042001",
    "district_id": "620104",
    "label": "Nanga Mua",
    "value": "Nanga Mua"
  },
  {
    "id": "6201042003",
    "district_id": "620104",
    "label": "Sukarami",
    "value": "Sukarami"
  },
  {
    "id": "6201042004",
    "district_id": "620104",
    "label": "Gandis",
    "value": "Gandis"
  },
  {
    "id": "6201042005",
    "district_id": "620104",
    "label": "Kerabu",
    "value": "Kerabu"
  },
  {
    "id": "6201042006",
    "district_id": "620104",
    "label": "Sambi",
    "value": "Sambi"
  },
  {
    "id": "6201042007",
    "district_id": "620104",
    "label": "Penyombaan",
    "value": "Penyombaan"
  },
  {
    "id": "6201042008",
    "district_id": "620104",
    "label": "Pandau",
    "value": "Pandau"
  },
  {
    "id": "6201042009",
    "district_id": "620104",
    "label": "Riam",
    "value": "Riam"
  },
  {
    "id": "6201042010",
    "district_id": "620104",
    "label": "Panahan",
    "value": "Panahan"
  },
  {
    "id": "6201042011",
    "district_id": "620104",
    "label": "Sungai Dau",
    "value": "Sungai Dau"
  },
  {
    "id": "6201052001",
    "district_id": "620105",
    "label": "Pangkalan Tiga",
    "value": "Pangkalan Tiga"
  },
  {
    "id": "6201052002",
    "district_id": "620105",
    "label": "Pandu Sanjaya",
    "value": "Pandu Sanjaya"
  },
  {
    "id": "6201052003",
    "district_id": "620105",
    "label": "Lada Mandala Jaya",
    "value": "Lada Mandala Jaya"
  },
  {
    "id": "6201052004",
    "district_id": "620105",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "6201052005",
    "district_id": "620105",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "6201052006",
    "district_id": "620105",
    "label": "Purbasari",
    "value": "Purbasari"
  },
  {
    "id": "6201052007",
    "district_id": "620105",
    "label": "Sungai Rangit Jaya",
    "value": "Sungai Rangit Jaya"
  },
  {
    "id": "6201052008",
    "district_id": "620105",
    "label": "Pangkalan Dewa",
    "value": "Pangkalan Dewa"
  },
  {
    "id": "6201052009",
    "district_id": "620105",
    "label": "Kadipi Atas",
    "value": "Kadipi Atas"
  },
  {
    "id": "6201052010",
    "district_id": "620105",
    "label": "Sungai Melawen",
    "value": "Sungai Melawen"
  },
  {
    "id": "6201052011",
    "district_id": "620105",
    "label": "Pangkalan Durin",
    "value": "Pangkalan Durin"
  },
  {
    "id": "6201062001",
    "district_id": "620106",
    "label": "Pangkalan Banteng",
    "value": "Pangkalan Banteng"
  },
  {
    "id": "6201062002",
    "district_id": "620106",
    "label": "Mulya Jadi",
    "value": "Mulya Jadi"
  },
  {
    "id": "6201062003",
    "district_id": "620106",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "6201062004",
    "district_id": "620106",
    "label": "Kebun Agung",
    "value": "Kebun Agung"
  },
  {
    "id": "6201062005",
    "district_id": "620106",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "6201062006",
    "district_id": "620106",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "6201062007",
    "district_id": "620106",
    "label": "Amin Jaya",
    "value": "Amin Jaya"
  },
  {
    "id": "6201062008",
    "district_id": "620106",
    "label": "Arga Mulya",
    "value": "Arga Mulya"
  },
  {
    "id": "6201062009",
    "district_id": "620106",
    "label": "Natai Kerbau",
    "value": "Natai Kerbau"
  },
  {
    "id": "6201062010",
    "district_id": "620106",
    "label": "Simpang Berambai",
    "value": "Simpang Berambai"
  },
  {
    "id": "6201062011",
    "district_id": "620106",
    "label": "Sungai Hijau",
    "value": "Sungai Hijau"
  },
  {
    "id": "6201062012",
    "district_id": "620106",
    "label": "Sungai Pakit",
    "value": "Sungai Pakit"
  },
  {
    "id": "6201062013",
    "district_id": "620106",
    "label": "Berambai Makmur",
    "value": "Berambai Makmur"
  },
  {
    "id": "6201062014",
    "district_id": "620106",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "6201062015",
    "district_id": "620106",
    "label": "Sungai Pulau",
    "value": "Sungai Pulau"
  },
  {
    "id": "6201062016",
    "district_id": "620106",
    "label": "Sungai Bengkuang",
    "value": "Sungai Bengkuang"
  },
  {
    "id": "6201062017",
    "district_id": "620106",
    "label": "Sungai Kuning",
    "value": "Sungai Kuning"
  },
  {
    "id": "6202011009",
    "district_id": "620201",
    "label": "Kota Besi Hulu",
    "value": "Kota Besi Hulu"
  },
  {
    "id": "6202011010",
    "district_id": "620201",
    "label": "Kota Besi Hilir",
    "value": "Kota Besi Hilir"
  },
  {
    "id": "6202012003",
    "district_id": "620201",
    "label": "Palangan",
    "value": "Palangan"
  },
  {
    "id": "6202012004",
    "district_id": "620201",
    "label": "Hanjalipan",
    "value": "Hanjalipan"
  },
  {
    "id": "6202012005",
    "district_id": "620201",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "6202012006",
    "district_id": "620201",
    "label": "Pamalian",
    "value": "Pamalian"
  },
  {
    "id": "6202012007",
    "district_id": "620201",
    "label": "Camba",
    "value": "Camba"
  },
  {
    "id": "6202012008",
    "district_id": "620201",
    "label": "Kandan",
    "value": "Kandan"
  },
  {
    "id": "6202012012",
    "district_id": "620201",
    "label": "Bajarum",
    "value": "Bajarum"
  },
  {
    "id": "6202012015",
    "district_id": "620201",
    "label": "Rasau Tumbuh",
    "value": "Rasau Tumbuh"
  },
  {
    "id": "6202012017",
    "district_id": "620201",
    "label": "Soren",
    "value": "Soren"
  },
  {
    "id": "6202022004",
    "district_id": "620202",
    "label": "Rubung Buyung",
    "value": "Rubung Buyung"
  },
  {
    "id": "6202022005",
    "district_id": "620202",
    "label": "Patai",
    "value": "Patai"
  },
  {
    "id": "6202022006",
    "district_id": "620202",
    "label": "Luwuk Ranggan",
    "value": "Luwuk Ranggan"
  },
  {
    "id": "6202022007",
    "district_id": "620202",
    "label": "Jemaras",
    "value": "Jemaras"
  },
  {
    "id": "6202022008",
    "district_id": "620202",
    "label": "Cempaka Mulia Timur",
    "value": "Cempaka Mulia Timur"
  },
  {
    "id": "6202022009",
    "district_id": "620202",
    "label": "Cempaka Mulia Barat",
    "value": "Cempaka Mulia Barat"
  },
  {
    "id": "6202022010",
    "district_id": "620202",
    "label": "Luwuk Bunter",
    "value": "Luwuk Bunter"
  },
  {
    "id": "6202022011",
    "district_id": "620202",
    "label": "Sungai Paring",
    "value": "Sungai Paring"
  },
  {
    "id": "6202031012",
    "district_id": "620203",
    "label": "Kuala Kuayan",
    "value": "Kuala Kuayan"
  },
  {
    "id": "6202032001",
    "district_id": "620203",
    "label": "Tangar",
    "value": "Tangar"
  },
  {
    "id": "6202032002",
    "district_id": "620203",
    "label": "Baampah",
    "value": "Baampah"
  },
  {
    "id": "6202032003",
    "district_id": "620203",
    "label": "Kawan Batu",
    "value": "Kawan Batu"
  },
  {
    "id": "6202032004",
    "district_id": "620203",
    "label": "Tanjung Bantur",
    "value": "Tanjung Bantur"
  },
  {
    "id": "6202032005",
    "district_id": "620203",
    "label": "Penda Durian",
    "value": "Penda Durian"
  },
  {
    "id": "6202032006",
    "district_id": "620203",
    "label": "Pahirangan",
    "value": "Pahirangan"
  },
  {
    "id": "6202032007",
    "district_id": "620203",
    "label": "Satiung",
    "value": "Satiung"
  },
  {
    "id": "6202032008",
    "district_id": "620203",
    "label": "Santilik",
    "value": "Santilik"
  },
  {
    "id": "6202032009",
    "district_id": "620203",
    "label": "Tangka Robah",
    "value": "Tangka Robah"
  },
  {
    "id": "6202032010",
    "district_id": "620203",
    "label": "Pemantang",
    "value": "Pemantang"
  },
  {
    "id": "6202032011",
    "district_id": "620203",
    "label": "Tumbang Sapiri",
    "value": "Tumbang Sapiri"
  },
  {
    "id": "6202032014",
    "district_id": "620203",
    "label": "Bawan",
    "value": "Bawan"
  },
  {
    "id": "6202032015",
    "district_id": "620203",
    "label": "Tanjung Jariangau",
    "value": "Tanjung Jariangau"
  },
  {
    "id": "6202032031",
    "district_id": "620203",
    "label": "Kapuk",
    "value": "Kapuk"
  },
  {
    "id": "6202032032",
    "district_id": "620203",
    "label": "Pantap",
    "value": "Pantap"
  },
  {
    "id": "6202041004",
    "district_id": "620204",
    "label": "Parenggean",
    "value": "Parenggean"
  },
  {
    "id": "6202042001",
    "district_id": "620204",
    "label": "Tehang",
    "value": "Tehang"
  },
  {
    "id": "6202042002",
    "district_id": "620204",
    "label": "Kabuau",
    "value": "Kabuau"
  },
  {
    "id": "6202042007",
    "district_id": "620204",
    "label": "Barunang Miri",
    "value": "Barunang Miri"
  },
  {
    "id": "6202042009",
    "district_id": "620204",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "6202042010",
    "district_id": "620204",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6202042011",
    "district_id": "620204",
    "label": "Karang Tunggal",
    "value": "Karang Tunggal"
  },
  {
    "id": "6202042012",
    "district_id": "620204",
    "label": "Bandar Agung",
    "value": "Bandar Agung"
  },
  {
    "id": "6202042013",
    "district_id": "620204",
    "label": "Beringin Tunggal Jaya",
    "value": "Beringin Tunggal Jaya"
  },
  {
    "id": "6202042015",
    "district_id": "620204",
    "label": "Sari Harapan",
    "value": "Sari Harapan"
  },
  {
    "id": "6202042016",
    "district_id": "620204",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "6202042019",
    "district_id": "620204",
    "label": "Bajarau",
    "value": "Bajarau"
  },
  {
    "id": "6202042023",
    "district_id": "620204",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "6202042024",
    "district_id": "620204",
    "label": "Manjalin",
    "value": "Manjalin"
  },
  {
    "id": "6202042025",
    "district_id": "620204",
    "label": "Karya Bersama",
    "value": "Karya Bersama"
  },
  {
    "id": "6202051001",
    "district_id": "620205",
    "label": "Baamang Hilir",
    "value": "Baamang Hilir"
  },
  {
    "id": "6202051002",
    "district_id": "620205",
    "label": "Baamang Tengah",
    "value": "Baamang Tengah"
  },
  {
    "id": "6202051003",
    "district_id": "620205",
    "label": "Baamang Hulu",
    "value": "Baamang Hulu"
  },
  {
    "id": "6202051007",
    "district_id": "620205",
    "label": "Tanah Mas",
    "value": "Tanah Mas"
  },
  {
    "id": "6202051008",
    "district_id": "620205",
    "label": "Baamang Barat",
    "value": "Baamang Barat"
  },
  {
    "id": "6202052006",
    "district_id": "620205",
    "label": "Tinduk",
    "value": "Tinduk"
  },
  {
    "id": "6202061001",
    "district_id": "620206",
    "label": "Mentawa Baru Hulu",
    "value": "Mentawa Baru Hulu"
  },
  {
    "id": "6202061002",
    "district_id": "620206",
    "label": "Mentawa Baru Hilir",
    "value": "Mentawa Baru Hilir"
  },
  {
    "id": "6202061003",
    "district_id": "620206",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "6202061007",
    "district_id": "620206",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "6202061009",
    "district_id": "620206",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "6202062005",
    "district_id": "620206",
    "label": "Pelangsian",
    "value": "Pelangsian"
  },
  {
    "id": "6202062006",
    "district_id": "620206",
    "label": "Bapeang",
    "value": "Bapeang"
  },
  {
    "id": "6202062008",
    "district_id": "620206",
    "label": "Eka Bahurui",
    "value": "Eka Bahurui"
  },
  {
    "id": "6202062010",
    "district_id": "620206",
    "label": "Telaga baru",
    "value": "Telaga baru"
  },
  {
    "id": "6202062011",
    "district_id": "620206",
    "label": "Bangkuang Makmur",
    "value": "Bangkuang Makmur"
  },
  {
    "id": "6202062012",
    "district_id": "620206",
    "label": "Bapanggang Raya",
    "value": "Bapanggang Raya"
  },
  {
    "id": "6202072001",
    "district_id": "620207",
    "label": "Pondok Damar",
    "value": "Pondok Damar"
  },
  {
    "id": "6202072002",
    "district_id": "620207",
    "label": "Bagendang Hilir",
    "value": "Bagendang Hilir"
  },
  {
    "id": "6202072003",
    "district_id": "620207",
    "label": "Bagendang Tengah",
    "value": "Bagendang Tengah"
  },
  {
    "id": "6202072004",
    "district_id": "620207",
    "label": "Natai Baru",
    "value": "Natai Baru"
  },
  {
    "id": "6202072005",
    "district_id": "620207",
    "label": "Bagendang Hulu",
    "value": "Bagendang Hulu"
  },
  {
    "id": "6202072006",
    "district_id": "620207",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "6202072007",
    "district_id": "620207",
    "label": "Bagendang Permai",
    "value": "Bagendang Permai"
  },
  {
    "id": "6202081008",
    "district_id": "620208",
    "label": "Samuda Kota",
    "value": "Samuda Kota"
  },
  {
    "id": "6202081009",
    "district_id": "620208",
    "label": "Basirih Hilir",
    "value": "Basirih Hilir"
  },
  {
    "id": "6202082005",
    "district_id": "620208",
    "label": "Sebamban",
    "value": "Sebamban"
  },
  {
    "id": "6202082006",
    "district_id": "620208",
    "label": "Samuda Besar",
    "value": "Samuda Besar"
  },
  {
    "id": "6202082007",
    "district_id": "620208",
    "label": "Samuda Kecil",
    "value": "Samuda Kecil"
  },
  {
    "id": "6202082010",
    "district_id": "620208",
    "label": "Jaya Kelapa",
    "value": "Jaya Kelapa"
  },
  {
    "id": "6202082011",
    "district_id": "620208",
    "label": "Basirih Hulu",
    "value": "Basirih Hulu"
  },
  {
    "id": "6202082012",
    "district_id": "620208",
    "label": "Jaya Karet",
    "value": "Jaya Karet"
  },
  {
    "id": "6202082013",
    "district_id": "620208",
    "label": "Handil Sohor",
    "value": "Handil Sohor"
  },
  {
    "id": "6202082014",
    "district_id": "620208",
    "label": "Sei Ijum Raya",
    "value": "Sei Ijum Raya"
  },
  {
    "id": "6202092001",
    "district_id": "620209",
    "label": "Bapinang Hulu",
    "value": "Bapinang Hulu"
  },
  {
    "id": "6202092002",
    "district_id": "620209",
    "label": "Bapinang Hilir",
    "value": "Bapinang Hilir"
  },
  {
    "id": "6202092003",
    "district_id": "620209",
    "label": "Bapinang Hilir Laut",
    "value": "Bapinang Hilir Laut"
  },
  {
    "id": "6202092004",
    "district_id": "620209",
    "label": "Satiruk",
    "value": "Satiruk"
  },
  {
    "id": "6202092005",
    "district_id": "620209",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "6202092006",
    "district_id": "620209",
    "label": "Rawa Sari",
    "value": "Rawa Sari"
  },
  {
    "id": "6202092007",
    "district_id": "620209",
    "label": "Hanaut",
    "value": "Hanaut"
  },
  {
    "id": "6202092008",
    "district_id": "620209",
    "label": "Babirah",
    "value": "Babirah"
  },
  {
    "id": "6202092009",
    "district_id": "620209",
    "label": "Serambut",
    "value": "Serambut"
  },
  {
    "id": "6202092010",
    "district_id": "620209",
    "label": "Babaung",
    "value": "Babaung"
  },
  {
    "id": "6202092011",
    "district_id": "620209",
    "label": "Bamadu",
    "value": "Bamadu"
  },
  {
    "id": "6202092012",
    "district_id": "620209",
    "label": "Penyaguan",
    "value": "Penyaguan"
  },
  {
    "id": "6202092013",
    "district_id": "620209",
    "label": "Hantipan",
    "value": "Hantipan"
  },
  {
    "id": "6202092014",
    "district_id": "620209",
    "label": "Bantian",
    "value": "Bantian"
  },
  {
    "id": "6202102001",
    "district_id": "620210",
    "label": "Tumbang Kalang",
    "value": "Tumbang Kalang"
  },
  {
    "id": "6202102002",
    "district_id": "620210",
    "label": "Kuluk Telawang",
    "value": "Kuluk Telawang"
  },
  {
    "id": "6202102003",
    "district_id": "620210",
    "label": "Sungai Puring",
    "value": "Sungai Puring"
  },
  {
    "id": "6202102004",
    "district_id": "620210",
    "label": "Tumbang Ngahan",
    "value": "Tumbang Ngahan"
  },
  {
    "id": "6202102005",
    "district_id": "620210",
    "label": "Tumbang Ramei",
    "value": "Tumbang Ramei"
  },
  {
    "id": "6202102006",
    "district_id": "620210",
    "label": "Tumbang Hejan",
    "value": "Tumbang Hejan"
  },
  {
    "id": "6202102007",
    "district_id": "620210",
    "label": "Sungai Hanya",
    "value": "Sungai Hanya"
  },
  {
    "id": "6202102008",
    "district_id": "620210",
    "label": "Tumbang Sepayang",
    "value": "Tumbang Sepayang"
  },
  {
    "id": "6202102016",
    "district_id": "620210",
    "label": "Buntut Nusa",
    "value": "Buntut Nusa"
  },
  {
    "id": "6202102017",
    "district_id": "620210",
    "label": "Tumbang Gagu",
    "value": "Tumbang Gagu"
  },
  {
    "id": "6202102021",
    "district_id": "620210",
    "label": "Tumbang Manya",
    "value": "Tumbang Manya"
  },
  {
    "id": "6202102025",
    "district_id": "620210",
    "label": "Gunung Makmur",
    "value": "Gunung Makmur"
  },
  {
    "id": "6202102027",
    "district_id": "620210",
    "label": "Mulya Agung",
    "value": "Mulya Agung"
  },
  {
    "id": "6202102028",
    "district_id": "620210",
    "label": "Bhakti Karya",
    "value": "Bhakti Karya"
  },
  {
    "id": "6202102029",
    "district_id": "620210",
    "label": "Waringin Agung",
    "value": "Waringin Agung"
  },
  {
    "id": "6202112001",
    "district_id": "620211",
    "label": "Parebok",
    "value": "Parebok"
  },
  {
    "id": "6202112002",
    "district_id": "620211",
    "label": "Basawang",
    "value": "Basawang"
  },
  {
    "id": "6202112003",
    "district_id": "620211",
    "label": "Lampuyang",
    "value": "Lampuyang"
  },
  {
    "id": "6202112004",
    "district_id": "620211",
    "label": "Ujung Pandaran",
    "value": "Ujung Pandaran"
  },
  {
    "id": "6202112005",
    "district_id": "620211",
    "label": "Regei Lestari",
    "value": "Regei Lestari"
  },
  {
    "id": "6202112006",
    "district_id": "620211",
    "label": "Kuin Permai",
    "value": "Kuin Permai"
  },
  {
    "id": "6202121001",
    "district_id": "620212",
    "label": "Mentaya Seberang",
    "value": "Mentaya Seberang"
  },
  {
    "id": "6202122002",
    "district_id": "620212",
    "label": "Terantang",
    "value": "Terantang"
  },
  {
    "id": "6202122003",
    "district_id": "620212",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6202122004",
    "district_id": "620212",
    "label": "Terantang Hilir",
    "value": "Terantang Hilir"
  },
  {
    "id": "6202122005",
    "district_id": "620212",
    "label": "Ganepo",
    "value": "Ganepo"
  },
  {
    "id": "6202122006",
    "district_id": "620212",
    "label": "Seragam Jaya",
    "value": "Seragam Jaya"
  },
  {
    "id": "6202132001",
    "district_id": "620213",
    "label": "Pundu",
    "value": "Pundu"
  },
  {
    "id": "6202132002",
    "district_id": "620213",
    "label": "Keruing",
    "value": "Keruing"
  },
  {
    "id": "6202132003",
    "district_id": "620213",
    "label": "Parit",
    "value": "Parit"
  },
  {
    "id": "6202132004",
    "district_id": "620213",
    "label": "Pantai Harapan",
    "value": "Pantai Harapan"
  },
  {
    "id": "6202132005",
    "district_id": "620213",
    "label": "Pelantaran",
    "value": "Pelantaran"
  },
  {
    "id": "6202132006",
    "district_id": "620213",
    "label": "Tumbang Koling",
    "value": "Tumbang Koling"
  },
  {
    "id": "6202132007",
    "district_id": "620213",
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "id": "6202132008",
    "district_id": "620213",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "6202132009",
    "district_id": "620213",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "6202132010",
    "district_id": "620213",
    "label": "Selucing",
    "value": "Selucing"
  },
  {
    "id": "6202132011",
    "district_id": "620213",
    "label": "Sungai Ubar Mandiiri",
    "value": "Sungai Ubar Mandiiri"
  },
  {
    "id": "6202142001",
    "district_id": "620214",
    "label": "Sebabi",
    "value": "Sebabi"
  },
  {
    "id": "6202142002",
    "district_id": "620214",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "6202142003",
    "district_id": "620214",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "6202142004",
    "district_id": "620214",
    "label": "Kenyala",
    "value": "Kenyala"
  },
  {
    "id": "6202142005",
    "district_id": "620214",
    "label": "Penyang",
    "value": "Penyang"
  },
  {
    "id": "6202142006",
    "district_id": "620214",
    "label": "Biru Maju",
    "value": "Biru Maju"
  },
  {
    "id": "6202152001",
    "district_id": "620215",
    "label": "Tumbang Tilap",
    "value": "Tumbang Tilap"
  },
  {
    "id": "6202152002",
    "district_id": "620215",
    "label": "Tumbang Kaminting",
    "value": "Tumbang Kaminting"
  },
  {
    "id": "6202152003",
    "district_id": "620215",
    "label": "Tanah Haluan",
    "value": "Tanah Haluan"
  },
  {
    "id": "6202152004",
    "district_id": "620215",
    "label": "Tumbang Panyahuan",
    "value": "Tumbang Panyahuan"
  },
  {
    "id": "6202152005",
    "district_id": "620215",
    "label": "Tumbang Sapia",
    "value": "Tumbang Sapia"
  },
  {
    "id": "6202152006",
    "district_id": "620215",
    "label": "Tumbang Getas",
    "value": "Tumbang Getas"
  },
  {
    "id": "6202152007",
    "district_id": "620215",
    "label": "Tewai Hara",
    "value": "Tewai Hara"
  },
  {
    "id": "6202152008",
    "district_id": "620215",
    "label": "Tumbang Payang",
    "value": "Tumbang Payang"
  },
  {
    "id": "6202152009",
    "district_id": "620215",
    "label": "Tumbang Kania",
    "value": "Tumbang Kania"
  },
  {
    "id": "6202152010",
    "district_id": "620215",
    "label": "Tumbang Tawan",
    "value": "Tumbang Tawan"
  },
  {
    "id": "6202152011",
    "district_id": "620215",
    "label": "Lunuk Bagantung",
    "value": "Lunuk Bagantung"
  },
  {
    "id": "6202152012",
    "district_id": "620215",
    "label": "Tumbang Torung",
    "value": "Tumbang Torung"
  },
  {
    "id": "6202152013",
    "district_id": "620215",
    "label": "Tumbang Batu",
    "value": "Tumbang Batu"
  },
  {
    "id": "6202152014",
    "district_id": "620215",
    "label": "Tumbang Saluang",
    "value": "Tumbang Saluang"
  },
  {
    "id": "6202162001",
    "district_id": "620216",
    "label": "Luwuk Sampun",
    "value": "Luwuk Sampun"
  },
  {
    "id": "6202162002",
    "district_id": "620216",
    "label": "Tumbang Mujam",
    "value": "Tumbang Mujam"
  },
  {
    "id": "6202162003",
    "district_id": "620216",
    "label": "Merah",
    "value": "Merah"
  },
  {
    "id": "6202162004",
    "district_id": "620216",
    "label": "Tanjung Jorong",
    "value": "Tanjung Jorong"
  },
  {
    "id": "6202162005",
    "district_id": "620216",
    "label": "Sebungsu",
    "value": "Sebungsu"
  },
  {
    "id": "6202162006",
    "district_id": "620216",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "6202162007",
    "district_id": "620216",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "6202162008",
    "district_id": "620216",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6202162009",
    "district_id": "620216",
    "label": "Damar Makmur",
    "value": "Damar Makmur"
  },
  {
    "id": "6202162010",
    "district_id": "620216",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "6202162011",
    "district_id": "620216",
    "label": "Jati Waringin",
    "value": "Jati Waringin"
  },
  {
    "id": "6202172001",
    "district_id": "620217",
    "label": "Tumbang Boloi",
    "value": "Tumbang Boloi"
  },
  {
    "id": "6202172002",
    "district_id": "620217",
    "label": "Luwuk Kowan",
    "value": "Luwuk Kowan"
  },
  {
    "id": "6202172003",
    "district_id": "620217",
    "label": "Rantau Tampang",
    "value": "Rantau Tampang"
  },
  {
    "id": "6202172004",
    "district_id": "620217",
    "label": "Tumbang Mangkup",
    "value": "Tumbang Mangkup"
  },
  {
    "id": "6202172005",
    "district_id": "620217",
    "label": "Rantau Katang",
    "value": "Rantau Katang"
  },
  {
    "id": "6202172006",
    "district_id": "620217",
    "label": "Tumbang Sangai",
    "value": "Tumbang Sangai"
  },
  {
    "id": "6202172007",
    "district_id": "620217",
    "label": "Tukang Langit",
    "value": "Tukang Langit"
  },
  {
    "id": "6202172008",
    "district_id": "620217",
    "label": "Beringin Agung",
    "value": "Beringin Agung"
  },
  {
    "id": "6202172009",
    "district_id": "620217",
    "label": "Agung Mulya",
    "value": "Agung Mulya"
  },
  {
    "id": "6202172010",
    "district_id": "620217",
    "label": "Batu Agung",
    "value": "Batu Agung"
  },
  {
    "id": "6202172011",
    "district_id": "620217",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "6202172012",
    "district_id": "620217",
    "label": "Tumbang Puan",
    "value": "Tumbang Puan"
  },
  {
    "id": "6202172013",
    "district_id": "620217",
    "label": "Rantau Suang",
    "value": "Rantau Suang"
  },
  {
    "id": "6202172014",
    "district_id": "620217",
    "label": "Rantau Sawang",
    "value": "Rantau Sawang"
  },
  {
    "id": "6202172015",
    "district_id": "620217",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6202172016",
    "district_id": "620217",
    "label": "Buana Mustika",
    "value": "Buana Mustika"
  },
  {
    "id": "6202172017",
    "district_id": "620217",
    "label": "Tri Buana",
    "value": "Tri Buana"
  },
  {
    "id": "6202172018",
    "district_id": "620217",
    "label": "Tumbang Bajanei",
    "value": "Tumbang Bajanei"
  },
  {
    "id": "6203011006",
    "district_id": "620301",
    "label": "Murung Keramat",
    "value": "Murung Keramat"
  },
  {
    "id": "6203011007",
    "district_id": "620301",
    "label": "Selat Hilir",
    "value": "Selat Hilir"
  },
  {
    "id": "6203011008",
    "district_id": "620301",
    "label": "Selat Tengah",
    "value": "Selat Tengah"
  },
  {
    "id": "6203011009",
    "district_id": "620301",
    "label": "Selat Hulu",
    "value": "Selat Hulu"
  },
  {
    "id": "6203011010",
    "district_id": "620301",
    "label": "Selat Dalam",
    "value": "Selat Dalam"
  },
  {
    "id": "6203011021",
    "district_id": "620301",
    "label": "Selat Utara",
    "value": "Selat Utara"
  },
  {
    "id": "6203011022",
    "district_id": "620301",
    "label": "Selat Barat",
    "value": "Selat Barat"
  },
  {
    "id": "6203011023",
    "district_id": "620301",
    "label": "Panamas",
    "value": "Panamas"
  },
  {
    "id": "6203012011",
    "district_id": "620301",
    "label": "Pulau Telo",
    "value": "Pulau Telo"
  },
  {
    "id": "6203012020",
    "district_id": "620301",
    "label": "Pulau Telo Baru",
    "value": "Pulau Telo Baru"
  },
  {
    "id": "6203021001",
    "district_id": "620302",
    "label": "Mambulau",
    "value": "Mambulau"
  },
  {
    "id": "6203021002",
    "district_id": "620302",
    "label": "Hampatung",
    "value": "Hampatung"
  },
  {
    "id": "6203021003",
    "district_id": "620302",
    "label": "Dahirang",
    "value": "Dahirang"
  },
  {
    "id": "6203021004",
    "district_id": "620302",
    "label": "Barimba",
    "value": "Barimba"
  },
  {
    "id": "6203021005",
    "district_id": "620302",
    "label": "Sei Pasah",
    "value": "Sei Pasah"
  },
  {
    "id": "6203022006",
    "district_id": "620302",
    "label": "Bakungin",
    "value": "Bakungin"
  },
  {
    "id": "6203022007",
    "district_id": "620302",
    "label": "Sei Asam",
    "value": "Sei Asam"
  },
  {
    "id": "6203022008",
    "district_id": "620302",
    "label": "Saka Batur",
    "value": "Saka Batur"
  },
  {
    "id": "6203032001",
    "district_id": "620303",
    "label": "Anjir Serapat Timur",
    "value": "Anjir Serapat Timur"
  },
  {
    "id": "6203032002",
    "district_id": "620303",
    "label": "Anjir Serapat Tengah",
    "value": "Anjir Serapat Tengah"
  },
  {
    "id": "6203032003",
    "district_id": "620303",
    "label": "Anjir Serapat Barat",
    "value": "Anjir Serapat Barat"
  },
  {
    "id": "6203032004",
    "district_id": "620303",
    "label": "Anjir Mambulau Timur",
    "value": "Anjir Mambulau Timur"
  },
  {
    "id": "6203032005",
    "district_id": "620303",
    "label": "Anjir Mambulau Tengah",
    "value": "Anjir Mambulau Tengah"
  },
  {
    "id": "6203032006",
    "district_id": "620303",
    "label": "Anjir Mambulau Barat",
    "value": "Anjir Mambulau Barat"
  },
  {
    "id": "6203032007",
    "district_id": "620303",
    "label": "Anjir Serapat Baru",
    "value": "Anjir Serapat Baru"
  },
  {
    "id": "6203042001",
    "district_id": "620304",
    "label": "Batanjung",
    "value": "Batanjung"
  },
  {
    "id": "6203042002",
    "district_id": "620304",
    "label": "Cemara Labat",
    "value": "Cemara Labat"
  },
  {
    "id": "6203042003",
    "district_id": "620304",
    "label": "Palampai",
    "value": "Palampai"
  },
  {
    "id": "6203042004",
    "district_id": "620304",
    "label": "Sei Teras",
    "value": "Sei Teras"
  },
  {
    "id": "6203042005",
    "district_id": "620304",
    "label": "Lupak Dalam",
    "value": "Lupak Dalam"
  },
  {
    "id": "6203042008",
    "district_id": "620304",
    "label": "Tamban Baru Selatan",
    "value": "Tamban Baru Selatan"
  },
  {
    "id": "6203042011",
    "district_id": "620304",
    "label": "Tamban Lupak",
    "value": "Tamban Lupak"
  },
  {
    "id": "6203042014",
    "district_id": "620304",
    "label": "Lupak Timur",
    "value": "Lupak Timur"
  },
  {
    "id": "6203042015",
    "district_id": "620304",
    "label": "Wargo Mulyo",
    "value": "Wargo Mulyo"
  },
  {
    "id": "6203042016",
    "district_id": "620304",
    "label": "Sei Bakut",
    "value": "Sei Bakut"
  },
  {
    "id": "6203042018",
    "district_id": "620304",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "6203042019",
    "district_id": "620304",
    "label": "Simpang Bunga Tanjung",
    "value": "Simpang Bunga Tanjung"
  },
  {
    "id": "6203042020",
    "district_id": "620304",
    "label": "Baranggau",
    "value": "Baranggau"
  },
  {
    "id": "6203051004",
    "district_id": "620305",
    "label": "Mandomai",
    "value": "Mandomai"
  },
  {
    "id": "6203052001",
    "district_id": "620305",
    "label": "Sei Kayu",
    "value": "Sei Kayu"
  },
  {
    "id": "6203052002",
    "district_id": "620305",
    "label": "Saka Mangkahai",
    "value": "Saka Mangkahai"
  },
  {
    "id": "6203052003",
    "district_id": "620305",
    "label": "Anjir Kalampan",
    "value": "Anjir Kalampan"
  },
  {
    "id": "6203052005",
    "district_id": "620305",
    "label": "Pantai",
    "value": "Pantai"
  },
  {
    "id": "6203052006",
    "district_id": "620305",
    "label": "Saka Tamiang",
    "value": "Saka Tamiang"
  },
  {
    "id": "6203052007",
    "district_id": "620305",
    "label": "Penda Katapi",
    "value": "Penda Katapi"
  },
  {
    "id": "6203052008",
    "district_id": "620305",
    "label": "Teluk Hiri",
    "value": "Teluk Hiri"
  },
  {
    "id": "6203052009",
    "district_id": "620305",
    "label": "Sei Dusun",
    "value": "Sei Dusun"
  },
  {
    "id": "6203052010",
    "district_id": "620305",
    "label": "Sei Pitung",
    "value": "Sei Pitung"
  },
  {
    "id": "6203052011",
    "district_id": "620305",
    "label": "Maju Bersama",
    "value": "Maju Bersama"
  },
  {
    "id": "6203052012",
    "district_id": "620305",
    "label": "Basuta Raya",
    "value": "Basuta Raya"
  },
  {
    "id": "6203062001",
    "district_id": "620306",
    "label": "Saka Lagon",
    "value": "Saka Lagon"
  },
  {
    "id": "6203062002",
    "district_id": "620306",
    "label": "Bunga Mawar",
    "value": "Bunga Mawar"
  },
  {
    "id": "6203062003",
    "district_id": "620306",
    "label": "Sei Tatas",
    "value": "Sei Tatas"
  },
  {
    "id": "6203062004",
    "district_id": "620306",
    "label": "Narahan",
    "value": "Narahan"
  },
  {
    "id": "6203062005",
    "district_id": "620306",
    "label": "Handiwung",
    "value": "Handiwung"
  },
  {
    "id": "6203062006",
    "district_id": "620306",
    "label": "Anjir Palambang",
    "value": "Anjir Palambang"
  },
  {
    "id": "6203062007",
    "district_id": "620306",
    "label": "Palangkai",
    "value": "Palangkai"
  },
  {
    "id": "6203062008",
    "district_id": "620306",
    "label": "Teluk Palinget",
    "value": "Teluk Palinget"
  },
  {
    "id": "6203062009",
    "district_id": "620306",
    "label": "Sei Tatas Hilir",
    "value": "Sei Tatas Hilir"
  },
  {
    "id": "6203062010",
    "district_id": "620306",
    "label": "Mawar Mekar",
    "value": "Mawar Mekar"
  },
  {
    "id": "6203062011",
    "district_id": "620306",
    "label": "Banama",
    "value": "Banama"
  },
  {
    "id": "6203062012",
    "district_id": "620306",
    "label": "Narahan Baru",
    "value": "Narahan Baru"
  },
  {
    "id": "6203071001",
    "district_id": "620307",
    "label": "Palingkau Baru",
    "value": "Palingkau Baru"
  },
  {
    "id": "6203071002",
    "district_id": "620307",
    "label": "Palingkau Lama",
    "value": "Palingkau Lama"
  },
  {
    "id": "6203072003",
    "district_id": "620307",
    "label": "Tajepan",
    "value": "Tajepan"
  },
  {
    "id": "6203072004",
    "district_id": "620307",
    "label": "Mampai",
    "value": "Mampai"
  },
  {
    "id": "6203072005",
    "district_id": "620307",
    "label": "Muara Dadahup",
    "value": "Muara Dadahup"
  },
  {
    "id": "6203072006",
    "district_id": "620307",
    "label": "Belawang",
    "value": "Belawang"
  },
  {
    "id": "6203072008",
    "district_id": "620307",
    "label": "Palangkau Lama",
    "value": "Palangkau Lama"
  },
  {
    "id": "6203072010",
    "district_id": "620307",
    "label": "Palangkau Baru",
    "value": "Palangkau Baru"
  },
  {
    "id": "6203072014",
    "district_id": "620307",
    "label": "Talekung Punei",
    "value": "Talekung Punei"
  },
  {
    "id": "6203072015",
    "district_id": "620307",
    "label": "Karya Bersama",
    "value": "Karya Bersama"
  },
  {
    "id": "6203072016",
    "district_id": "620307",
    "label": "Palingkau Jaya",
    "value": "Palingkau Jaya"
  },
  {
    "id": "6203072017",
    "district_id": "620307",
    "label": "Palingkau Asri",
    "value": "Palingkau Asri"
  },
  {
    "id": "6203072018",
    "district_id": "620307",
    "label": "Palingkau Sejahtera",
    "value": "Palingkau Sejahtera"
  },
  {
    "id": "6203072019",
    "district_id": "620307",
    "label": "Saka Binjai",
    "value": "Saka Binjai"
  },
  {
    "id": "6203072020",
    "district_id": "620307",
    "label": "Bina Sejahtera",
    "value": "Bina Sejahtera"
  },
  {
    "id": "6203072021",
    "district_id": "620307",
    "label": "Suka Reja",
    "value": "Suka Reja"
  },
  {
    "id": "6203072022",
    "district_id": "620307",
    "label": "Suka Mukti",
    "value": "Suka Mukti"
  },
  {
    "id": "6203072023",
    "district_id": "620307",
    "label": "Bina Karya",
    "value": "Bina Karya"
  },
  {
    "id": "6203072024",
    "district_id": "620307",
    "label": "Bina Mekar",
    "value": "Bina Mekar"
  },
  {
    "id": "6203072025",
    "district_id": "620307",
    "label": "Rawa Subur",
    "value": "Rawa Subur"
  },
  {
    "id": "6203072026",
    "district_id": "620307",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "6203072027",
    "district_id": "620307",
    "label": "Bumi Rahayu",
    "value": "Bumi Rahayu"
  },
  {
    "id": "6203072028",
    "district_id": "620307",
    "label": "Manggala Permai",
    "value": "Manggala Permai"
  },
  {
    "id": "6203082001",
    "district_id": "620308",
    "label": "Pangkalan Rekan",
    "value": "Pangkalan Rekan"
  },
  {
    "id": "6203082002",
    "district_id": "620308",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6203082003",
    "district_id": "620308",
    "label": "Basarang",
    "value": "Basarang"
  },
  {
    "id": "6203082004",
    "district_id": "620308",
    "label": "Basungkai",
    "value": "Basungkai"
  },
  {
    "id": "6203082005",
    "district_id": "620308",
    "label": "Maluen",
    "value": "Maluen"
  },
  {
    "id": "6203082006",
    "district_id": "620308",
    "label": "Pangkalan Sari",
    "value": "Pangkalan Sari"
  },
  {
    "id": "6203082007",
    "district_id": "620308",
    "label": "Lunuk Ramba",
    "value": "Lunuk Ramba"
  },
  {
    "id": "6203082008",
    "district_id": "620308",
    "label": "Batu Nindan",
    "value": "Batu Nindan"
  },
  {
    "id": "6203082009",
    "district_id": "620308",
    "label": "Basarang Jaya",
    "value": "Basarang Jaya"
  },
  {
    "id": "6203082010",
    "district_id": "620308",
    "label": "Bungai Jaya",
    "value": "Bungai Jaya"
  },
  {
    "id": "6203082011",
    "district_id": "620308",
    "label": "Panarung",
    "value": "Panarung"
  },
  {
    "id": "6203082012",
    "district_id": "620308",
    "label": "Tarung Manuah",
    "value": "Tarung Manuah"
  },
  {
    "id": "6203082013",
    "district_id": "620308",
    "label": "Tambun Raya",
    "value": "Tambun Raya"
  },
  {
    "id": "6203082014",
    "district_id": "620308",
    "label": "Naning",
    "value": "Naning"
  },
  {
    "id": "6203092001",
    "district_id": "620309",
    "label": "Manusup",
    "value": "Manusup"
  },
  {
    "id": "6203092002",
    "district_id": "620309",
    "label": "Sei Kapar",
    "value": "Sei Kapar"
  },
  {
    "id": "6203092003",
    "district_id": "620309",
    "label": "Tarantang",
    "value": "Tarantang"
  },
  {
    "id": "6203092004",
    "district_id": "620309",
    "label": "Lamunti",
    "value": "Lamunti"
  },
  {
    "id": "6203092005",
    "district_id": "620309",
    "label": "Pulau Kaladan",
    "value": "Pulau Kaladan"
  },
  {
    "id": "6203092006",
    "district_id": "620309",
    "label": "Mantangai Hilir",
    "value": "Mantangai Hilir"
  },
  {
    "id": "6203092007",
    "district_id": "620309",
    "label": "Mantangai Tengah",
    "value": "Mantangai Tengah"
  },
  {
    "id": "6203092008",
    "district_id": "620309",
    "label": "Mantangai Hulu",
    "value": "Mantangai Hulu"
  },
  {
    "id": "6203092009",
    "district_id": "620309",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "6203092010",
    "district_id": "620309",
    "label": "Sei Ahas",
    "value": "Sei Ahas"
  },
  {
    "id": "6203092011",
    "district_id": "620309",
    "label": "Katunjung",
    "value": "Katunjung"
  },
  {
    "id": "6203092012",
    "district_id": "620309",
    "label": "Lahei Mangkutup",
    "value": "Lahei Mangkutup"
  },
  {
    "id": "6203092013",
    "district_id": "620309",
    "label": "Tumbang Muroi",
    "value": "Tumbang Muroi"
  },
  {
    "id": "6203092014",
    "district_id": "620309",
    "label": "Danau Rawah",
    "value": "Danau Rawah"
  },
  {
    "id": "6203092015",
    "district_id": "620309",
    "label": "Muroi Raya",
    "value": "Muroi Raya"
  },
  {
    "id": "6203092016",
    "district_id": "620309",
    "label": "Katimpun",
    "value": "Katimpun"
  },
  {
    "id": "6203092017",
    "district_id": "620309",
    "label": "Manusup Hilir",
    "value": "Manusup Hilir"
  },
  {
    "id": "6203092018",
    "district_id": "620309",
    "label": "Sei Gita",
    "value": "Sei Gita"
  },
  {
    "id": "6203092019",
    "district_id": "620309",
    "label": "Bukit Batu",
    "value": "Bukit Batu"
  },
  {
    "id": "6203092020",
    "district_id": "620309",
    "label": "Sei Gawing",
    "value": "Sei Gawing"
  },
  {
    "id": "6203092021",
    "district_id": "620309",
    "label": "Humbang Raya",
    "value": "Humbang Raya"
  },
  {
    "id": "6203092022",
    "district_id": "620309",
    "label": "Tabore",
    "value": "Tabore"
  },
  {
    "id": "6203092023",
    "district_id": "620309",
    "label": "Tumbang Mangkutup",
    "value": "Tumbang Mangkutup"
  },
  {
    "id": "6203092024",
    "district_id": "620309",
    "label": "Lapetan",
    "value": "Lapetan"
  },
  {
    "id": "6203092025",
    "district_id": "620309",
    "label": "Lamunti Permai",
    "value": "Lamunti Permai"
  },
  {
    "id": "6203092026",
    "district_id": "620309",
    "label": "Manyahi",
    "value": "Manyahi"
  },
  {
    "id": "6203092027",
    "district_id": "620309",
    "label": "Sekata Makmur",
    "value": "Sekata Makmur"
  },
  {
    "id": "6203092028",
    "district_id": "620309",
    "label": "Kaladan Jaya",
    "value": "Kaladan Jaya"
  },
  {
    "id": "6203092029",
    "district_id": "620309",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "6203092030",
    "district_id": "620309",
    "label": "Warga Mulya",
    "value": "Warga Mulya"
  },
  {
    "id": "6203092031",
    "district_id": "620309",
    "label": "Lamunti Baru",
    "value": "Lamunti Baru"
  },
  {
    "id": "6203092032",
    "district_id": "620309",
    "label": "Sriwidadi",
    "value": "Sriwidadi"
  },
  {
    "id": "6203092033",
    "district_id": "620309",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "6203092034",
    "district_id": "620309",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6203092035",
    "district_id": "620309",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "6203092036",
    "district_id": "620309",
    "label": "Sekata Bangun",
    "value": "Sekata Bangun"
  },
  {
    "id": "6203092037",
    "district_id": "620309",
    "label": "Sari Makmur",
    "value": "Sari Makmur"
  },
  {
    "id": "6203092038",
    "district_id": "620309",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6203102001",
    "district_id": "620310",
    "label": "Petak Puti",
    "value": "Petak Puti"
  },
  {
    "id": "6203102002",
    "district_id": "620310",
    "label": "Aruk",
    "value": "Aruk"
  },
  {
    "id": "6203102003",
    "district_id": "620310",
    "label": "Lawang Kajang",
    "value": "Lawang Kajang"
  },
  {
    "id": "6203102004",
    "district_id": "620310",
    "label": "Timpah",
    "value": "Timpah"
  },
  {
    "id": "6203102005",
    "district_id": "620310",
    "label": "Lungkuh Layang",
    "value": "Lungkuh Layang"
  },
  {
    "id": "6203102006",
    "district_id": "620310",
    "label": "Lawang Kamah",
    "value": "Lawang Kamah"
  },
  {
    "id": "6203102007",
    "district_id": "620310",
    "label": "Tumbang Randang",
    "value": "Tumbang Randang"
  },
  {
    "id": "6203102008",
    "district_id": "620310",
    "label": "Batapah",
    "value": "Batapah"
  },
  {
    "id": "6203102009",
    "district_id": "620310",
    "label": "Danau Pantau",
    "value": "Danau Pantau"
  },
  {
    "id": "6203112001",
    "district_id": "620311",
    "label": "Masaran",
    "value": "Masaran"
  },
  {
    "id": "6203112002",
    "district_id": "620311",
    "label": "Kayu Bulan",
    "value": "Kayu Bulan"
  },
  {
    "id": "6203112003",
    "district_id": "620311",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "6203112004",
    "district_id": "620311",
    "label": "Penda Muntei",
    "value": "Penda Muntei"
  },
  {
    "id": "6203112005",
    "district_id": "620311",
    "label": "Tapen",
    "value": "Tapen"
  },
  {
    "id": "6203112006",
    "district_id": "620311",
    "label": "Pujon",
    "value": "Pujon"
  },
  {
    "id": "6203112007",
    "district_id": "620311",
    "label": "Marapit",
    "value": "Marapit"
  },
  {
    "id": "6203112008",
    "district_id": "620311",
    "label": "Manis",
    "value": "Manis"
  },
  {
    "id": "6203112009",
    "district_id": "620311",
    "label": "Karukus",
    "value": "Karukus"
  },
  {
    "id": "6203112010",
    "district_id": "620311",
    "label": "Bajuh",
    "value": "Bajuh"
  },
  {
    "id": "6203112011",
    "district_id": "620311",
    "label": "Barunang",
    "value": "Barunang"
  },
  {
    "id": "6203112019",
    "district_id": "620311",
    "label": "Buhut Jaya",
    "value": "Buhut Jaya"
  },
  {
    "id": "6203112021",
    "district_id": "620311",
    "label": "Hurung Pukung",
    "value": "Hurung Pukung"
  },
  {
    "id": "6203122001",
    "district_id": "620312",
    "label": "Sei Hanyo",
    "value": "Sei Hanyo"
  },
  {
    "id": "6203122002",
    "district_id": "620312",
    "label": "Hurung Tabengan",
    "value": "Hurung Tabengan"
  },
  {
    "id": "6203122003",
    "district_id": "620312",
    "label": "Rahung Bungai",
    "value": "Rahung Bungai"
  },
  {
    "id": "6203122005",
    "district_id": "620312",
    "label": "Bulau Ngandung",
    "value": "Bulau Ngandung"
  },
  {
    "id": "6203122006",
    "district_id": "620312",
    "label": "Tumbang Puroh",
    "value": "Tumbang Puroh"
  },
  {
    "id": "6203122008",
    "district_id": "620312",
    "label": "Tangirang",
    "value": "Tangirang"
  },
  {
    "id": "6203122013",
    "district_id": "620312",
    "label": "Barunang II",
    "value": "Barunang II"
  },
  {
    "id": "6203122014",
    "district_id": "620312",
    "label": "Hurung Tampang",
    "value": "Hurung Tampang"
  },
  {
    "id": "6203122015",
    "district_id": "620312",
    "label": "Supang",
    "value": "Supang"
  },
  {
    "id": "6203122016",
    "district_id": "620312",
    "label": "Katanjung",
    "value": "Katanjung"
  },
  {
    "id": "6203122018",
    "district_id": "620312",
    "label": "Jakatan Pari",
    "value": "Jakatan Pari"
  },
  {
    "id": "6203122019",
    "district_id": "620312",
    "label": "Tumbang Sirat",
    "value": "Tumbang Sirat"
  },
  {
    "id": "6203122021",
    "district_id": "620312",
    "label": "Dirung Koram",
    "value": "Dirung Koram"
  },
  {
    "id": "6203122022",
    "district_id": "620312",
    "label": "Mampai Jaya",
    "value": "Mampai Jaya"
  },
  {
    "id": "6203132001",
    "district_id": "620313",
    "label": "Tamban Baru Timur",
    "value": "Tamban Baru Timur"
  },
  {
    "id": "6203132002",
    "district_id": "620313",
    "label": "Tamban Baru Tengah",
    "value": "Tamban Baru Tengah"
  },
  {
    "id": "6203132003",
    "district_id": "620313",
    "label": "Tamban Baru Mekar",
    "value": "Tamban Baru Mekar"
  },
  {
    "id": "6203132004",
    "district_id": "620313",
    "label": "Bandaraya",
    "value": "Bandaraya"
  },
  {
    "id": "6203132005",
    "district_id": "620313",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "6203132006",
    "district_id": "620313",
    "label": "Warna Sari",
    "value": "Warna Sari"
  },
  {
    "id": "6203132007",
    "district_id": "620313",
    "label": "Bandar Mekar",
    "value": "Bandar Mekar"
  },
  {
    "id": "6203132008",
    "district_id": "620313",
    "label": "Tamban Makmur",
    "value": "Tamban Makmur"
  },
  {
    "id": "6203132009",
    "district_id": "620313",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6203132010",
    "district_id": "620313",
    "label": "Tamban Jaya",
    "value": "Tamban Jaya"
  },
  {
    "id": "6203142001",
    "district_id": "620314",
    "label": "Jangkang",
    "value": "Jangkang"
  },
  {
    "id": "6203142002",
    "district_id": "620314",
    "label": "Tumbang Tukun",
    "value": "Tumbang Tukun"
  },
  {
    "id": "6203142003",
    "district_id": "620314",
    "label": "Sei Ringin",
    "value": "Sei Ringin"
  },
  {
    "id": "6203142004",
    "district_id": "620314",
    "label": "Kaburan",
    "value": "Kaburan"
  },
  {
    "id": "6203142005",
    "district_id": "620314",
    "label": "Balai Banjang",
    "value": "Balai Banjang"
  },
  {
    "id": "6203142006",
    "district_id": "620314",
    "label": "Tumbang Diring",
    "value": "Tumbang Diring"
  },
  {
    "id": "6203142007",
    "district_id": "620314",
    "label": "Dandang",
    "value": "Dandang"
  },
  {
    "id": "6203142008",
    "district_id": "620314",
    "label": "Hurung Kampin",
    "value": "Hurung Kampin"
  },
  {
    "id": "6203142009",
    "district_id": "620314",
    "label": "Batu Sambung",
    "value": "Batu Sambung"
  },
  {
    "id": "6203142010",
    "district_id": "620314",
    "label": "Tumbang Nusa",
    "value": "Tumbang Nusa"
  },
  {
    "id": "6203152001",
    "district_id": "620315",
    "label": "Masaha",
    "value": "Masaha"
  },
  {
    "id": "6203152002",
    "district_id": "620315",
    "label": "Sei Pinang",
    "value": "Sei Pinang"
  },
  {
    "id": "6203152003",
    "district_id": "620315",
    "label": "Lawang Tamang",
    "value": "Lawang Tamang"
  },
  {
    "id": "6203152004",
    "district_id": "620315",
    "label": "Karetau Manta'a",
    "value": "Karetau Manta'a"
  },
  {
    "id": "6203152005",
    "district_id": "620315",
    "label": "Tumbang Bukoi",
    "value": "Tumbang Bukoi"
  },
  {
    "id": "6203152006",
    "district_id": "620315",
    "label": "Tumbang Tihis",
    "value": "Tumbang Tihis"
  },
  {
    "id": "6203152007",
    "district_id": "620315",
    "label": "Tumbang Manyarung",
    "value": "Tumbang Manyarung"
  },
  {
    "id": "6203152008",
    "district_id": "620315",
    "label": "Tanjung Rendan",
    "value": "Tanjung Rendan"
  },
  {
    "id": "6203152009",
    "district_id": "620315",
    "label": "Jakatan Masaha",
    "value": "Jakatan Masaha"
  },
  {
    "id": "6203152010",
    "district_id": "620315",
    "label": "Masupa Ria",
    "value": "Masupa Ria"
  },
  {
    "id": "6203162001",
    "district_id": "620316",
    "label": "Dadahup",
    "value": "Dadahup"
  },
  {
    "id": "6203162002",
    "district_id": "620316",
    "label": "Tambak Bajai",
    "value": "Tambak Bajai"
  },
  {
    "id": "6203162003",
    "district_id": "620316",
    "label": "Bina Jaya",
    "value": "Bina Jaya"
  },
  {
    "id": "6203162004",
    "district_id": "620316",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6203162005",
    "district_id": "620316",
    "label": "Bentuk Jaya",
    "value": "Bentuk Jaya"
  },
  {
    "id": "6203162006",
    "district_id": "620316",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "6203162007",
    "district_id": "620316",
    "label": "Petak Batuah",
    "value": "Petak Batuah"
  },
  {
    "id": "6203162008",
    "district_id": "620316",
    "label": "Manuntung",
    "value": "Manuntung"
  },
  {
    "id": "6203162009",
    "district_id": "620316",
    "label": "Sumber Alaska",
    "value": "Sumber Alaska"
  },
  {
    "id": "6203162010",
    "district_id": "620316",
    "label": "Menteng Karya",
    "value": "Menteng Karya"
  },
  {
    "id": "6203162011",
    "district_id": "620316",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6203162012",
    "district_id": "620316",
    "label": "Kahuripan Permai",
    "value": "Kahuripan Permai"
  },
  {
    "id": "6203162013",
    "district_id": "620316",
    "label": "Dadahup Raya",
    "value": "Dadahup Raya"
  },
  {
    "id": "6203171001",
    "district_id": "620317",
    "label": "Pulau Kupang",
    "value": "Pulau Kupang"
  },
  {
    "id": "6203172002",
    "district_id": "620317",
    "label": "Pulau Mambulau",
    "value": "Pulau Mambulau"
  },
  {
    "id": "6203172003",
    "district_id": "620317",
    "label": "Sei Lunuk",
    "value": "Sei Lunuk"
  },
  {
    "id": "6203172004",
    "district_id": "620317",
    "label": "Sei Jangkit",
    "value": "Sei Jangkit"
  },
  {
    "id": "6203172005",
    "district_id": "620317",
    "label": "Tamban Luar",
    "value": "Tamban Luar"
  },
  {
    "id": "6203172006",
    "district_id": "620317",
    "label": "Terusan Raya",
    "value": "Terusan Raya"
  },
  {
    "id": "6203172007",
    "district_id": "620317",
    "label": "Terusan Karya",
    "value": "Terusan Karya"
  },
  {
    "id": "6203172008",
    "district_id": "620317",
    "label": "Terusan Makmur",
    "value": "Terusan Makmur"
  },
  {
    "id": "6203172009",
    "district_id": "620317",
    "label": "Terusan Mulya",
    "value": "Terusan Mulya"
  },
  {
    "id": "6203172010",
    "district_id": "620317",
    "label": "Bamban Raya",
    "value": "Bamban Raya"
  },
  {
    "id": "6203172011",
    "district_id": "620317",
    "label": "Bangun Harjo",
    "value": "Bangun Harjo"
  },
  {
    "id": "6203172012",
    "district_id": "620317",
    "label": "Terusan Baguntan Raya",
    "value": "Terusan Baguntan Raya"
  },
  {
    "id": "6203172013",
    "district_id": "620317",
    "label": "Terusan Raya Barat",
    "value": "Terusan Raya Barat"
  },
  {
    "id": "6203172014",
    "district_id": "620317",
    "label": "Terusan Raya Hulu",
    "value": "Terusan Raya Hulu"
  },
  {
    "id": "6203172015",
    "district_id": "620317",
    "label": "Budi Mufakat",
    "value": "Budi Mufakat"
  },
  {
    "id": "6204011003",
    "district_id": "620401",
    "label": "Rantau Kujang",
    "value": "Rantau Kujang"
  },
  {
    "id": "6204012001",
    "district_id": "620401",
    "label": "Tampulang",
    "value": "Tampulang"
  },
  {
    "id": "6204012002",
    "district_id": "620401",
    "label": "Rantau Bahuang",
    "value": "Rantau Bahuang"
  },
  {
    "id": "6204012004",
    "district_id": "620401",
    "label": "Rangga Ilung",
    "value": "Rangga Ilung"
  },
  {
    "id": "6204012005",
    "district_id": "620401",
    "label": "Tabatan",
    "value": "Tabatan"
  },
  {
    "id": "6204021003",
    "district_id": "620402",
    "label": "Mengkatip",
    "value": "Mengkatip"
  },
  {
    "id": "6204022001",
    "district_id": "620402",
    "label": "Sungai Jaya",
    "value": "Sungai Jaya"
  },
  {
    "id": "6204022002",
    "district_id": "620402",
    "label": "Mahajandau",
    "value": "Mahajandau"
  },
  {
    "id": "6204022004",
    "district_id": "620402",
    "label": "Kalanis",
    "value": "Kalanis"
  },
  {
    "id": "6204022005",
    "district_id": "620402",
    "label": "Lehai",
    "value": "Lehai"
  },
  {
    "id": "6204022006",
    "district_id": "620402",
    "label": "Damparan",
    "value": "Damparan"
  },
  {
    "id": "6204022007",
    "district_id": "620402",
    "label": "Teluk Timbau",
    "value": "Teluk Timbau"
  },
  {
    "id": "6204022008",
    "district_id": "620402",
    "label": "Batilap",
    "value": "Batilap"
  },
  {
    "id": "6204022009",
    "district_id": "620402",
    "label": "Batampang",
    "value": "Batampang"
  },
  {
    "id": "6204022010",
    "district_id": "620402",
    "label": "Mangkatir",
    "value": "Mangkatir"
  },
  {
    "id": "6204031002",
    "district_id": "620403",
    "label": "Bangkuang",
    "value": "Bangkuang"
  },
  {
    "id": "6204032001",
    "district_id": "620403",
    "label": "Salat Baru",
    "value": "Salat Baru"
  },
  {
    "id": "6204032003",
    "district_id": "620403",
    "label": "Teluk Betung",
    "value": "Teluk Betung"
  },
  {
    "id": "6204032004",
    "district_id": "620403",
    "label": "Babai",
    "value": "Babai"
  },
  {
    "id": "6204032005",
    "district_id": "620403",
    "label": "Janggi",
    "value": "Janggi"
  },
  {
    "id": "6204032006",
    "district_id": "620403",
    "label": "Malitin",
    "value": "Malitin"
  },
  {
    "id": "6204032007",
    "district_id": "620403",
    "label": "Muara Arai",
    "value": "Muara Arai"
  },
  {
    "id": "6204032008",
    "district_id": "620403",
    "label": "Talio",
    "value": "Talio"
  },
  {
    "id": "6204032009",
    "district_id": "620403",
    "label": "Tampijak",
    "value": "Tampijak"
  },
  {
    "id": "6204032010",
    "district_id": "620403",
    "label": "Bintang Kurung",
    "value": "Bintang Kurung"
  },
  {
    "id": "6204032011",
    "district_id": "620403",
    "label": "Teluk Sampudau",
    "value": "Teluk Sampudau"
  },
  {
    "id": "6204041005",
    "district_id": "620404",
    "label": "Pendang",
    "value": "Pendang"
  },
  {
    "id": "6204042001",
    "district_id": "620404",
    "label": "Sungai Telang",
    "value": "Sungai Telang"
  },
  {
    "id": "6204042002",
    "district_id": "620404",
    "label": "Majundre",
    "value": "Majundre"
  },
  {
    "id": "6204042003",
    "district_id": "620404",
    "label": "Bundar",
    "value": "Bundar"
  },
  {
    "id": "6204042004",
    "district_id": "620404",
    "label": "Talekoi",
    "value": "Talekoi"
  },
  {
    "id": "6204042006",
    "district_id": "620404",
    "label": "Marawan Lama",
    "value": "Marawan Lama"
  },
  {
    "id": "6204042007",
    "district_id": "620404",
    "label": "Marawan Baru",
    "value": "Marawan Baru"
  },
  {
    "id": "6204042008",
    "district_id": "620404",
    "label": "Reong",
    "value": "Reong"
  },
  {
    "id": "6204042009",
    "district_id": "620404",
    "label": "Tarusan",
    "value": "Tarusan"
  },
  {
    "id": "6204042010",
    "district_id": "620404",
    "label": "Panarukan",
    "value": "Panarukan"
  },
  {
    "id": "6204042011",
    "district_id": "620404",
    "label": "Maruga",
    "value": "Maruga"
  },
  {
    "id": "6204042012",
    "district_id": "620404",
    "label": "Hingan",
    "value": "Hingan"
  },
  {
    "id": "6204042013",
    "district_id": "620404",
    "label": "Danau Bambure",
    "value": "Danau Bambure"
  },
  {
    "id": "6204042014",
    "district_id": "620404",
    "label": "Tamparak",
    "value": "Tamparak"
  },
  {
    "id": "6204042015",
    "district_id": "620404",
    "label": "Hulu Tampang",
    "value": "Hulu Tampang"
  },
  {
    "id": "6204042016",
    "district_id": "620404",
    "label": "Bantai Bambure",
    "value": "Bantai Bambure"
  },
  {
    "id": "6204042017",
    "district_id": "620404",
    "label": "Rampa Mea",
    "value": "Rampa Mea"
  },
  {
    "id": "6204042018",
    "district_id": "620404",
    "label": "Gunung Rantau",
    "value": "Gunung Rantau"
  },
  {
    "id": "6204042019",
    "district_id": "620404",
    "label": "Tamparak Layung",
    "value": "Tamparak Layung"
  },
  {
    "id": "6204052001",
    "district_id": "620405",
    "label": "Baruang",
    "value": "Baruang"
  },
  {
    "id": "6204052002",
    "district_id": "620405",
    "label": "Kayumban",
    "value": "Kayumban"
  },
  {
    "id": "6204052003",
    "district_id": "620405",
    "label": "Tabak Kanilan",
    "value": "Tabak Kanilan"
  },
  {
    "id": "6204052004",
    "district_id": "620405",
    "label": "Sarimbuah",
    "value": "Sarimbuah"
  },
  {
    "id": "6204052005",
    "district_id": "620405",
    "label": "Gagutur",
    "value": "Gagutur"
  },
  {
    "id": "6204052006",
    "district_id": "620405",
    "label": "Muka Haji",
    "value": "Muka Haji"
  },
  {
    "id": "6204052007",
    "district_id": "620405",
    "label": "Sire",
    "value": "Sire"
  },
  {
    "id": "6204052008",
    "district_id": "620405",
    "label": "Muara Singan",
    "value": "Muara Singan"
  },
  {
    "id": "6204052009",
    "district_id": "620405",
    "label": "Patas I",
    "value": "Patas I"
  },
  {
    "id": "6204052010",
    "district_id": "620405",
    "label": "Patas II",
    "value": "Patas II"
  },
  {
    "id": "6204052011",
    "district_id": "620405",
    "label": "Bintang Ara",
    "value": "Bintang Ara"
  },
  {
    "id": "6204052012",
    "district_id": "620405",
    "label": "Ugang Sayu",
    "value": "Ugang Sayu"
  },
  {
    "id": "6204052013",
    "district_id": "620405",
    "label": "Ngurit",
    "value": "Ngurit"
  },
  {
    "id": "6204052014",
    "district_id": "620405",
    "label": "Wungkur Baru",
    "value": "Wungkur Baru"
  },
  {
    "id": "6204052015",
    "district_id": "620405",
    "label": "Bipak Kali",
    "value": "Bipak Kali"
  },
  {
    "id": "6204052016",
    "district_id": "620405",
    "label": "Malungai Raya",
    "value": "Malungai Raya"
  },
  {
    "id": "6204052017",
    "district_id": "620405",
    "label": "Sei Paken",
    "value": "Sei Paken"
  },
  {
    "id": "6204052018",
    "district_id": "620405",
    "label": "Palu Rejo",
    "value": "Palu Rejo"
  },
  {
    "id": "6204052019",
    "district_id": "620405",
    "label": "Wayun",
    "value": "Wayun"
  },
  {
    "id": "6204052020",
    "district_id": "620405",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "6204052021",
    "district_id": "620405",
    "label": "Ruhing Raya",
    "value": "Ruhing Raya"
  },
  {
    "id": "6204061006",
    "district_id": "620406",
    "label": "Jelapat",
    "value": "Jelapat"
  },
  {
    "id": "6204061007",
    "district_id": "620406",
    "label": "Hilir Seper",
    "value": "Hilir Seper"
  },
  {
    "id": "6204061008",
    "district_id": "620406",
    "label": "Buntok Kota",
    "value": "Buntok Kota"
  },
  {
    "id": "6204062001",
    "district_id": "620406",
    "label": "Danau Ganting",
    "value": "Danau Ganting"
  },
  {
    "id": "6204062002",
    "district_id": "620406",
    "label": "Muara Talang",
    "value": "Muara Talang"
  },
  {
    "id": "6204062003",
    "district_id": "620406",
    "label": "Teluk Telaga",
    "value": "Teluk Telaga"
  },
  {
    "id": "6204062004",
    "district_id": "620406",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6204062005",
    "district_id": "620406",
    "label": "Danau Sadar",
    "value": "Danau Sadar"
  },
  {
    "id": "6204062009",
    "district_id": "620406",
    "label": "Pamait",
    "value": "Pamait"
  },
  {
    "id": "6204062010",
    "district_id": "620406",
    "label": "Sababilah",
    "value": "Sababilah"
  },
  {
    "id": "6204062011",
    "district_id": "620406",
    "label": "Mangaris",
    "value": "Mangaris"
  },
  {
    "id": "6204062012",
    "district_id": "620406",
    "label": "Tetei Lanan",
    "value": "Tetei Lanan"
  },
  {
    "id": "6204062013",
    "district_id": "620406",
    "label": "Pamangka",
    "value": "Pamangka"
  },
  {
    "id": "6204062014",
    "district_id": "620406",
    "label": "Sanggu",
    "value": "Sanggu"
  },
  {
    "id": "6204062015",
    "district_id": "620406",
    "label": "Lembeng",
    "value": "Lembeng"
  },
  {
    "id": "6204062016",
    "district_id": "620406",
    "label": "Muara Ripung",
    "value": "Muara Ripung"
  },
  {
    "id": "6204062017",
    "district_id": "620406",
    "label": "Madara",
    "value": "Madara"
  },
  {
    "id": "6204062018",
    "district_id": "620406",
    "label": "Murung Paken",
    "value": "Murung Paken"
  },
  {
    "id": "6204062019",
    "district_id": "620406",
    "label": "Mabuan",
    "value": "Mabuan"
  },
  {
    "id": "6204062020",
    "district_id": "620406",
    "label": "Kalahien",
    "value": "Kalahien"
  },
  {
    "id": "6204062021",
    "district_id": "620406",
    "label": "Penda Asam",
    "value": "Penda Asam"
  },
  {
    "id": "6204062022",
    "district_id": "620406",
    "label": "Pararapak",
    "value": "Pararapak"
  },
  {
    "id": "6204062023",
    "district_id": "620406",
    "label": "Teluk Mampun",
    "value": "Teluk Mampun"
  },
  {
    "id": "6204062024",
    "district_id": "620406",
    "label": "Tanjung Jawa",
    "value": "Tanjung Jawa"
  },
  {
    "id": "6204062025",
    "district_id": "620406",
    "label": "Telang Andrau",
    "value": "Telang Andrau"
  },
  {
    "id": "6204062026",
    "district_id": "620406",
    "label": "Dangka",
    "value": "Dangka"
  },
  {
    "id": "6204062027",
    "district_id": "620406",
    "label": "Danau Masura",
    "value": "Danau Masura"
  },
  {
    "id": "6205011001",
    "district_id": "620501",
    "label": "Montallat I",
    "value": "Montallat I"
  },
  {
    "id": "6205011002",
    "district_id": "620501",
    "label": "Montallat II",
    "value": "Montallat II"
  },
  {
    "id": "6205011003",
    "district_id": "620501",
    "label": "Tumpung Laung I",
    "value": "Tumpung Laung I"
  },
  {
    "id": "6205011004",
    "district_id": "620501",
    "label": "Tumpung Laung II",
    "value": "Tumpung Laung II"
  },
  {
    "id": "6205012005",
    "district_id": "620501",
    "label": "Sikan",
    "value": "Sikan"
  },
  {
    "id": "6205012006",
    "district_id": "620501",
    "label": "Rubei",
    "value": "Rubei"
  },
  {
    "id": "6205012007",
    "district_id": "620501",
    "label": "Pepas",
    "value": "Pepas"
  },
  {
    "id": "6205012008",
    "district_id": "620501",
    "label": "Ruji",
    "value": "Ruji"
  },
  {
    "id": "6205012009",
    "district_id": "620501",
    "label": "Paring Lahung",
    "value": "Paring Lahung"
  },
  {
    "id": "6205012010",
    "district_id": "620501",
    "label": "Kamawen",
    "value": "Kamawen"
  },
  {
    "id": "6205022001",
    "district_id": "620502",
    "label": "Malungai",
    "value": "Malungai"
  },
  {
    "id": "6205022002",
    "district_id": "620502",
    "label": "Rarawa",
    "value": "Rarawa"
  },
  {
    "id": "6205022003",
    "district_id": "620502",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "6205022004",
    "district_id": "620502",
    "label": "Walur",
    "value": "Walur"
  },
  {
    "id": "6205022005",
    "district_id": "620502",
    "label": "Baliti",
    "value": "Baliti"
  },
  {
    "id": "6205022006",
    "district_id": "620502",
    "label": "Majangkan",
    "value": "Majangkan"
  },
  {
    "id": "6205022007",
    "district_id": "620502",
    "label": "Kandui",
    "value": "Kandui"
  },
  {
    "id": "6205022008",
    "district_id": "620502",
    "label": "Payang Ara",
    "value": "Payang Ara"
  },
  {
    "id": "6205022009",
    "district_id": "620502",
    "label": "Jaman",
    "value": "Jaman"
  },
  {
    "id": "6205022010",
    "district_id": "620502",
    "label": "Pelari",
    "value": "Pelari"
  },
  {
    "id": "6205022011",
    "district_id": "620502",
    "label": "Sangkorang",
    "value": "Sangkorang"
  },
  {
    "id": "6205022012",
    "district_id": "620502",
    "label": "Siwau",
    "value": "Siwau"
  },
  {
    "id": "6205022013",
    "district_id": "620502",
    "label": "Tongka",
    "value": "Tongka"
  },
  {
    "id": "6205022014",
    "district_id": "620502",
    "label": "Batu Raya I",
    "value": "Batu Raya I"
  },
  {
    "id": "6205022015",
    "district_id": "620502",
    "label": "Batu Raya II",
    "value": "Batu Raya II"
  },
  {
    "id": "6205022016",
    "district_id": "620502",
    "label": "Tapen Raya",
    "value": "Tapen Raya"
  },
  {
    "id": "6205032001",
    "district_id": "620503",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6205032002",
    "district_id": "620503",
    "label": "Linon Besi I",
    "value": "Linon Besi I"
  },
  {
    "id": "6205032003",
    "district_id": "620503",
    "label": "Linon Besi II",
    "value": "Linon Besi II"
  },
  {
    "id": "6205032004",
    "district_id": "620503",
    "label": "Muara Mea",
    "value": "Muara Mea"
  },
  {
    "id": "6205032005",
    "district_id": "620503",
    "label": "Lawarang",
    "value": "Lawarang"
  },
  {
    "id": "6205032006",
    "district_id": "620503",
    "label": "Lampeong I",
    "value": "Lampeong I"
  },
  {
    "id": "6205032007",
    "district_id": "620503",
    "label": "Lampeong II",
    "value": "Lampeong II"
  },
  {
    "id": "6205032008",
    "district_id": "620503",
    "label": "Payang",
    "value": "Payang"
  },
  {
    "id": "6205032009",
    "district_id": "620503",
    "label": "Berong",
    "value": "Berong"
  },
  {
    "id": "6205032010",
    "district_id": "620503",
    "label": "Baok",
    "value": "Baok"
  },
  {
    "id": "6205032011",
    "district_id": "620503",
    "label": "Tambaba",
    "value": "Tambaba"
  },
  {
    "id": "6205042001",
    "district_id": "620504",
    "label": "Sampirang I",
    "value": "Sampirang I"
  },
  {
    "id": "6205042002",
    "district_id": "620504",
    "label": "Sampirang II",
    "value": "Sampirang II"
  },
  {
    "id": "6205042003",
    "district_id": "620504",
    "label": "Liju",
    "value": "Liju"
  },
  {
    "id": "6205042004",
    "district_id": "620504",
    "label": "Benangin III",
    "value": "Benangin III"
  },
  {
    "id": "6205042005",
    "district_id": "620504",
    "label": "Benangin II",
    "value": "Benangin II"
  },
  {
    "id": "6205042006",
    "district_id": "620504",
    "label": "Benangin V",
    "value": "Benangin V"
  },
  {
    "id": "6205042007",
    "district_id": "620504",
    "label": "Benangin I",
    "value": "Benangin I"
  },
  {
    "id": "6205042008",
    "district_id": "620504",
    "label": "Muara Wakat",
    "value": "Muara Wakat"
  },
  {
    "id": "6205042012",
    "district_id": "620504",
    "label": "Mampuak I",
    "value": "Mampuak I"
  },
  {
    "id": "6205042013",
    "district_id": "620504",
    "label": "Jamut",
    "value": "Jamut"
  },
  {
    "id": "6205042014",
    "district_id": "620504",
    "label": "Sei Liju",
    "value": "Sei Liju"
  },
  {
    "id": "6205042015",
    "district_id": "620504",
    "label": "Mampuak II",
    "value": "Mampuak II"
  },
  {
    "id": "6205051013",
    "district_id": "620505",
    "label": "Lanjas",
    "value": "Lanjas"
  },
  {
    "id": "6205051014",
    "district_id": "620505",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "6205052010",
    "district_id": "620505",
    "label": "Lemo I",
    "value": "Lemo I"
  },
  {
    "id": "6205052011",
    "district_id": "620505",
    "label": "Lemo II",
    "value": "Lemo II"
  },
  {
    "id": "6205052012",
    "district_id": "620505",
    "label": "Pendreh",
    "value": "Pendreh"
  },
  {
    "id": "6205052017",
    "district_id": "620505",
    "label": "Rimba Sari",
    "value": "Rimba Sari"
  },
  {
    "id": "6205052018",
    "district_id": "620505",
    "label": "Sei Rahayu I",
    "value": "Sei Rahayu I"
  },
  {
    "id": "6205052022",
    "district_id": "620505",
    "label": "Sei Rahayu II",
    "value": "Sei Rahayu II"
  },
  {
    "id": "6205052024",
    "district_id": "620505",
    "label": "Beringin Raya",
    "value": "Beringin Raya"
  },
  {
    "id": "6205052025",
    "district_id": "620505",
    "label": "Datai Nirui",
    "value": "Datai Nirui"
  },
  {
    "id": "6205061015",
    "district_id": "620506",
    "label": "Lahei I",
    "value": "Lahei I"
  },
  {
    "id": "6205061016",
    "district_id": "620506",
    "label": "Lahei II",
    "value": "Lahei II"
  },
  {
    "id": "6205062001",
    "district_id": "620506",
    "label": "Ipu",
    "value": "Ipu"
  },
  {
    "id": "6205062002",
    "district_id": "620506",
    "label": "Mukut",
    "value": "Mukut"
  },
  {
    "id": "6205062014",
    "district_id": "620506",
    "label": "Muara Bakah",
    "value": "Muara Bakah"
  },
  {
    "id": "6205062017",
    "district_id": "620506",
    "label": "Juju Baru",
    "value": "Juju Baru"
  },
  {
    "id": "6205062018",
    "district_id": "620506",
    "label": "Muara Inu",
    "value": "Muara Inu"
  },
  {
    "id": "6205062019",
    "district_id": "620506",
    "label": "Bengahon",
    "value": "Bengahon"
  },
  {
    "id": "6205062020",
    "district_id": "620506",
    "label": "Rahaden",
    "value": "Rahaden"
  },
  {
    "id": "6205062021",
    "district_id": "620506",
    "label": "Muara Pari",
    "value": "Muara Pari"
  },
  {
    "id": "6205062022",
    "district_id": "620506",
    "label": "Haragandang",
    "value": "Haragandang"
  },
  {
    "id": "6205062023",
    "district_id": "620506",
    "label": "Karendan",
    "value": "Karendan"
  },
  {
    "id": "6205062024",
    "district_id": "620506",
    "label": "Hurung Enep",
    "value": "Hurung Enep"
  },
  {
    "id": "6205071001",
    "district_id": "620507",
    "label": "Jingah",
    "value": "Jingah"
  },
  {
    "id": "6205071002",
    "district_id": "620507",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "6205072003",
    "district_id": "620507",
    "label": "Liang Naga",
    "value": "Liang Naga"
  },
  {
    "id": "6205072004",
    "district_id": "620507",
    "label": "Sabuh",
    "value": "Sabuh"
  },
  {
    "id": "6205072005",
    "district_id": "620507",
    "label": "Hajak",
    "value": "Hajak"
  },
  {
    "id": "6205072006",
    "district_id": "620507",
    "label": "Malawaken",
    "value": "Malawaken"
  },
  {
    "id": "6205072007",
    "district_id": "620507",
    "label": "Sikui",
    "value": "Sikui"
  },
  {
    "id": "6205072008",
    "district_id": "620507",
    "label": "Panaen",
    "value": "Panaen"
  },
  {
    "id": "6205072009",
    "district_id": "620507",
    "label": "Liang Buah",
    "value": "Liang Buah"
  },
  {
    "id": "6205072010",
    "district_id": "620507",
    "label": "Gandring",
    "value": "Gandring"
  },
  {
    "id": "6205082001",
    "district_id": "620508",
    "label": "Trahean",
    "value": "Trahean"
  },
  {
    "id": "6205082002",
    "district_id": "620508",
    "label": "Trinsing",
    "value": "Trinsing"
  },
  {
    "id": "6205082003",
    "district_id": "620508",
    "label": "Bintang Ninggi I",
    "value": "Bintang Ninggi I"
  },
  {
    "id": "6205082004",
    "district_id": "620508",
    "label": "Bintang Ninggi II",
    "value": "Bintang Ninggi II"
  },
  {
    "id": "6205082005",
    "district_id": "620508",
    "label": "Butong",
    "value": "Butong"
  },
  {
    "id": "6205082006",
    "district_id": "620508",
    "label": "Buntok Baru",
    "value": "Buntok Baru"
  },
  {
    "id": "6205082007",
    "district_id": "620508",
    "label": "Bukit Sawit",
    "value": "Bukit Sawit"
  },
  {
    "id": "6205082008",
    "district_id": "620508",
    "label": "Tawan Jaya",
    "value": "Tawan Jaya"
  },
  {
    "id": "6205082009",
    "district_id": "620508",
    "label": "Pandran Raya",
    "value": "Pandran Raya"
  },
  {
    "id": "6205082010",
    "district_id": "620508",
    "label": "Pandran Permai",
    "value": "Pandran Permai"
  },
  {
    "id": "6205092001",
    "district_id": "620509",
    "label": "Nihan Hilir",
    "value": "Nihan Hilir"
  },
  {
    "id": "6205092002",
    "district_id": "620509",
    "label": "Nihan Hulu",
    "value": "Nihan Hulu"
  },
  {
    "id": "6205092003",
    "district_id": "620509",
    "label": "Luwe Hilir",
    "value": "Luwe Hilir"
  },
  {
    "id": "6205092004",
    "district_id": "620509",
    "label": "Luwe Hulu",
    "value": "Luwe Hulu"
  },
  {
    "id": "6205092005",
    "district_id": "620509",
    "label": "Jangkang Baru",
    "value": "Jangkang Baru"
  },
  {
    "id": "6205092006",
    "district_id": "620509",
    "label": "Jangkang Lama",
    "value": "Jangkang Lama"
  },
  {
    "id": "6205092007",
    "district_id": "620509",
    "label": "Papar Pujung",
    "value": "Papar Pujung"
  },
  {
    "id": "6205092008",
    "district_id": "620509",
    "label": "Teluk Malewai",
    "value": "Teluk Malewai"
  },
  {
    "id": "6205092009",
    "district_id": "620509",
    "label": "Benao Hilir",
    "value": "Benao Hilir"
  },
  {
    "id": "6205092010",
    "district_id": "620509",
    "label": "Benao Hulu",
    "value": "Benao Hulu"
  },
  {
    "id": "6205092011",
    "district_id": "620509",
    "label": "Karamuan",
    "value": "Karamuan"
  },
  {
    "id": "6206012001",
    "district_id": "620601",
    "label": "Galinggang",
    "value": "Galinggang"
  },
  {
    "id": "6206012002",
    "district_id": "620601",
    "label": "Tampelas",
    "value": "Tampelas"
  },
  {
    "id": "6206012003",
    "district_id": "620601",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "6206012004",
    "district_id": "620601",
    "label": "Parupuk",
    "value": "Parupuk"
  },
  {
    "id": "6206012005",
    "district_id": "620601",
    "label": "Karuing",
    "value": "Karuing"
  },
  {
    "id": "6206012006",
    "district_id": "620601",
    "label": "Jahanjang",
    "value": "Jahanjang"
  },
  {
    "id": "6206012007",
    "district_id": "620601",
    "label": "Tumbang Runen",
    "value": "Tumbang Runen"
  },
  {
    "id": "6206012008",
    "district_id": "620601",
    "label": "Baun Bango",
    "value": "Baun Bango"
  },
  {
    "id": "6206012009",
    "district_id": "620601",
    "label": "Asem Kumbang",
    "value": "Asem Kumbang"
  },
  {
    "id": "6206021003",
    "district_id": "620602",
    "label": "Kasongan Baru",
    "value": "Kasongan Baru"
  },
  {
    "id": "6206021004",
    "district_id": "620602",
    "label": "Kasongan Lama",
    "value": "Kasongan Lama"
  },
  {
    "id": "6206022001",
    "district_id": "620602",
    "label": "Tewang Kadamba",
    "value": "Tewang Kadamba"
  },
  {
    "id": "6206022002",
    "district_id": "620602",
    "label": "Tumbang Liting",
    "value": "Tumbang Liting"
  },
  {
    "id": "6206022005",
    "district_id": "620602",
    "label": "Talian Kereng",
    "value": "Talian Kereng"
  },
  {
    "id": "6206022006",
    "district_id": "620602",
    "label": "Banut Kalanaman",
    "value": "Banut Kalanaman"
  },
  {
    "id": "6206022007",
    "district_id": "620602",
    "label": "Talangkah",
    "value": "Talangkah"
  },
  {
    "id": "6206022008",
    "district_id": "620602",
    "label": "Hampalit",
    "value": "Hampalit"
  },
  {
    "id": "6206031006",
    "district_id": "620603",
    "label": "Pendahara",
    "value": "Pendahara"
  },
  {
    "id": "6206032001",
    "district_id": "620603",
    "label": "Tewang Baringin",
    "value": "Tewang Baringin"
  },
  {
    "id": "6206032002",
    "district_id": "620603",
    "label": "Hapalam",
    "value": "Hapalam"
  },
  {
    "id": "6206032003",
    "district_id": "620603",
    "label": "Tewang Rangas",
    "value": "Tewang Rangas"
  },
  {
    "id": "6206032004",
    "district_id": "620603",
    "label": "Bangkuang",
    "value": "Bangkuang"
  },
  {
    "id": "6206032005",
    "district_id": "620603",
    "label": "Tarusan Danum",
    "value": "Tarusan Danum"
  },
  {
    "id": "6206032007",
    "district_id": "620603",
    "label": "Tumbang Tarusan",
    "value": "Tumbang Tarusan"
  },
  {
    "id": "6206032008",
    "district_id": "620603",
    "label": "Tewang Rangkang",
    "value": "Tewang Rangkang"
  },
  {
    "id": "6206032009",
    "district_id": "620603",
    "label": "Tewang Manyangen",
    "value": "Tewang Manyangen"
  },
  {
    "id": "6206032010",
    "district_id": "620603",
    "label": "Karya Unggang",
    "value": "Karya Unggang"
  },
  {
    "id": "6206042001",
    "district_id": "620604",
    "label": "Tewang Papari",
    "value": "Tewang Papari"
  },
  {
    "id": "6206042002",
    "district_id": "620604",
    "label": "Tewang Darayu",
    "value": "Tewang Darayu"
  },
  {
    "id": "6206042003",
    "district_id": "620604",
    "label": "Buntut Bali",
    "value": "Buntut Bali"
  },
  {
    "id": "6206042004",
    "district_id": "620604",
    "label": "Kuluk Bali",
    "value": "Kuluk Bali"
  },
  {
    "id": "6206042005",
    "district_id": "620604",
    "label": "Manduing Taheta",
    "value": "Manduing Taheta"
  },
  {
    "id": "6206042006",
    "district_id": "620604",
    "label": "Manduing Lama",
    "value": "Manduing Lama"
  },
  {
    "id": "6206042007",
    "district_id": "620604",
    "label": "Tumbang Banjang",
    "value": "Tumbang Banjang"
  },
  {
    "id": "6206042008",
    "district_id": "620604",
    "label": "Tumbang Lawang",
    "value": "Tumbang Lawang"
  },
  {
    "id": "6206042009",
    "district_id": "620604",
    "label": "Dahian Tunggal",
    "value": "Dahian Tunggal"
  },
  {
    "id": "6206042010",
    "district_id": "620604",
    "label": "Tewang Karangan",
    "value": "Tewang Karangan"
  },
  {
    "id": "6206042011",
    "district_id": "620604",
    "label": "Tumbang Tungku",
    "value": "Tumbang Tungku"
  },
  {
    "id": "6206042012",
    "district_id": "620604",
    "label": "Geragu",
    "value": "Geragu"
  },
  {
    "id": "6206042013",
    "district_id": "620604",
    "label": "Tumbang Tanjung",
    "value": "Tumbang Tanjung"
  },
  {
    "id": "6206042014",
    "district_id": "620604",
    "label": "Tura",
    "value": "Tura"
  },
  {
    "id": "6206051009",
    "district_id": "620605",
    "label": "Samba Kahayan",
    "value": "Samba Kahayan"
  },
  {
    "id": "6206052001",
    "district_id": "620605",
    "label": "Tumbang Lahang",
    "value": "Tumbang Lahang"
  },
  {
    "id": "6206052002",
    "district_id": "620605",
    "label": "Tewang Panjang",
    "value": "Tewang Panjang"
  },
  {
    "id": "6206052003",
    "district_id": "620605",
    "label": "Petak Puti",
    "value": "Petak Puti"
  },
  {
    "id": "6206052004",
    "district_id": "620605",
    "label": "Telok",
    "value": "Telok"
  },
  {
    "id": "6206052005",
    "district_id": "620605",
    "label": "Samba Danum",
    "value": "Samba Danum"
  },
  {
    "id": "6206052006",
    "district_id": "620605",
    "label": "Samba Bakumpai",
    "value": "Samba Bakumpai"
  },
  {
    "id": "6206052007",
    "district_id": "620605",
    "label": "Samba Katung",
    "value": "Samba Katung"
  },
  {
    "id": "6206052008",
    "district_id": "620605",
    "label": "Napu Sahur",
    "value": "Napu Sahur"
  },
  {
    "id": "6206052010",
    "district_id": "620605",
    "label": "Batu Badinding",
    "value": "Batu Badinding"
  },
  {
    "id": "6206052011",
    "district_id": "620605",
    "label": "Rantau Asem",
    "value": "Rantau Asem"
  },
  {
    "id": "6206052012",
    "district_id": "620605",
    "label": "Mirah Kalanaman",
    "value": "Mirah Kalanaman"
  },
  {
    "id": "6206052013",
    "district_id": "620605",
    "label": "Tumbang Kalemei",
    "value": "Tumbang Kalemei"
  },
  {
    "id": "6206052014",
    "district_id": "620605",
    "label": "Tumbang Marak",
    "value": "Tumbang Marak"
  },
  {
    "id": "6206052015",
    "district_id": "620605",
    "label": "Tumbang Hangei",
    "value": "Tumbang Hangei"
  },
  {
    "id": "6206052016",
    "district_id": "620605",
    "label": "Tumbang Pariyei",
    "value": "Tumbang Pariyei"
  },
  {
    "id": "6206062001",
    "district_id": "620606",
    "label": "Dehes",
    "value": "Dehes"
  },
  {
    "id": "6206062002",
    "district_id": "620606",
    "label": "Tumbang Labehu",
    "value": "Tumbang Labehu"
  },
  {
    "id": "6206062003",
    "district_id": "620606",
    "label": "Tumbang Kaman",
    "value": "Tumbang Kaman"
  },
  {
    "id": "6206062004",
    "district_id": "620606",
    "label": "Tumbang Manggo",
    "value": "Tumbang Manggo"
  },
  {
    "id": "6206062005",
    "district_id": "620606",
    "label": "Kamanto",
    "value": "Kamanto"
  },
  {
    "id": "6206062006",
    "district_id": "620606",
    "label": "Kuluk Habuhus",
    "value": "Kuluk Habuhus"
  },
  {
    "id": "6206062007",
    "district_id": "620606",
    "label": "Tumbang Kanei",
    "value": "Tumbang Kanei"
  },
  {
    "id": "6206062008",
    "district_id": "620606",
    "label": "Tumbang Taranei",
    "value": "Tumbang Taranei"
  },
  {
    "id": "6206062009",
    "district_id": "620606",
    "label": "Tumbang Kawei",
    "value": "Tumbang Kawei"
  },
  {
    "id": "6206062010",
    "district_id": "620606",
    "label": "Tumbang Mangara",
    "value": "Tumbang Mangara"
  },
  {
    "id": "6206062011",
    "district_id": "620606",
    "label": "Tumbang Pangka",
    "value": "Tumbang Pangka"
  },
  {
    "id": "6206062012",
    "district_id": "620606",
    "label": "Tumbang Atei",
    "value": "Tumbang Atei"
  },
  {
    "id": "6206062020",
    "district_id": "620606",
    "label": "Daya Manunggal",
    "value": "Daya Manunggal"
  },
  {
    "id": "6206062021",
    "district_id": "620606",
    "label": "Rantau Bangkiang",
    "value": "Rantau Bangkiang"
  },
  {
    "id": "6206072001",
    "district_id": "620607",
    "label": "Tumbang Mandurei",
    "value": "Tumbang Mandurei"
  },
  {
    "id": "6206072002",
    "district_id": "620607",
    "label": "Tumbang Paku",
    "value": "Tumbang Paku"
  },
  {
    "id": "6206072003",
    "district_id": "620607",
    "label": "Buntut Leleng",
    "value": "Buntut Leleng"
  },
  {
    "id": "6206072004",
    "district_id": "620607",
    "label": "Kuluk Leleng",
    "value": "Kuluk Leleng"
  },
  {
    "id": "6206072005",
    "district_id": "620607",
    "label": "Sabaung",
    "value": "Sabaung"
  },
  {
    "id": "6206072006",
    "district_id": "620607",
    "label": "Tumbang Malawan",
    "value": "Tumbang Malawan"
  },
  {
    "id": "6206072007",
    "district_id": "620607",
    "label": "Tumbang Taei",
    "value": "Tumbang Taei"
  },
  {
    "id": "6206072008",
    "district_id": "620607",
    "label": "Rangan Surai",
    "value": "Rangan Surai"
  },
  {
    "id": "6206072009",
    "district_id": "620607",
    "label": "Tumbang Hiran",
    "value": "Tumbang Hiran"
  },
  {
    "id": "6206072010",
    "district_id": "620607",
    "label": "Tumbang Pahanei",
    "value": "Tumbang Pahanei"
  },
  {
    "id": "6206072011",
    "district_id": "620607",
    "label": "Tumbang Dakei",
    "value": "Tumbang Dakei"
  },
  {
    "id": "6206072012",
    "district_id": "620607",
    "label": "Rangan Burih",
    "value": "Rangan Burih"
  },
  {
    "id": "6206072013",
    "district_id": "620607",
    "label": "Tumbang Bemban",
    "value": "Tumbang Bemban"
  },
  {
    "id": "6206072014",
    "district_id": "620607",
    "label": "Tumbang Lambi",
    "value": "Tumbang Lambi"
  },
  {
    "id": "6206072015",
    "district_id": "620607",
    "label": "Rangan Tangko",
    "value": "Rangan Tangko"
  },
  {
    "id": "6206072016",
    "district_id": "620607",
    "label": "Tumbang Tundu",
    "value": "Tumbang Tundu"
  },
  {
    "id": "6206072017",
    "district_id": "620607",
    "label": "Batu Panahan",
    "value": "Batu Panahan"
  },
  {
    "id": "6206072018",
    "district_id": "620607",
    "label": "Tumbang Tabulus",
    "value": "Tumbang Tabulus"
  },
  {
    "id": "6206081014",
    "district_id": "620608",
    "label": "Tumbang Sanamang",
    "value": "Tumbang Sanamang"
  },
  {
    "id": "6206082001",
    "district_id": "620608",
    "label": "Kiham Batang",
    "value": "Kiham Batang"
  },
  {
    "id": "6206082002",
    "district_id": "620608",
    "label": "Tumbang Kabayan",
    "value": "Tumbang Kabayan"
  },
  {
    "id": "6206082003",
    "district_id": "620608",
    "label": "Rangan Kawit",
    "value": "Rangan Kawit"
  },
  {
    "id": "6206082004",
    "district_id": "620608",
    "label": "Dehes Asem",
    "value": "Dehes Asem"
  },
  {
    "id": "6206082005",
    "district_id": "620608",
    "label": "Kuluk Sapangi",
    "value": "Kuluk Sapangi"
  },
  {
    "id": "6206082006",
    "district_id": "620608",
    "label": "Tumbang Kuai",
    "value": "Tumbang Kuai"
  },
  {
    "id": "6206082007",
    "district_id": "620608",
    "label": "Tumbang Salaman",
    "value": "Tumbang Salaman"
  },
  {
    "id": "6206082008",
    "district_id": "620608",
    "label": "Rantau Puka",
    "value": "Rantau Puka"
  },
  {
    "id": "6206082009",
    "district_id": "620608",
    "label": "Rantau Bahai",
    "value": "Rantau Bahai"
  },
  {
    "id": "6206082010",
    "district_id": "620608",
    "label": "Rangan Ranjing",
    "value": "Rangan Ranjing"
  },
  {
    "id": "6206082011",
    "district_id": "620608",
    "label": "Tumbang Labaning",
    "value": "Tumbang Labaning"
  },
  {
    "id": "6206082012",
    "district_id": "620608",
    "label": "Batu Bango",
    "value": "Batu Bango"
  },
  {
    "id": "6206082013",
    "district_id": "620608",
    "label": "Tumbang Hangei II",
    "value": "Tumbang Hangei II"
  },
  {
    "id": "6206082015",
    "district_id": "620608",
    "label": "Sei Nanjan",
    "value": "Sei Nanjan"
  },
  {
    "id": "6206082016",
    "district_id": "620608",
    "label": "Tumbang Jiga",
    "value": "Tumbang Jiga"
  },
  {
    "id": "6206082017",
    "district_id": "620608",
    "label": "Tumbang Mangketai",
    "value": "Tumbang Mangketai"
  },
  {
    "id": "6206082019",
    "district_id": "620608",
    "label": "Tumbang Mahop",
    "value": "Tumbang Mahop"
  },
  {
    "id": "6206082020",
    "district_id": "620608",
    "label": "Tumbang Sabetung",
    "value": "Tumbang Sabetung"
  },
  {
    "id": "6206082030",
    "district_id": "620608",
    "label": "Penda Tanggaring Lama",
    "value": "Penda Tanggaring Lama"
  },
  {
    "id": "6206082031",
    "district_id": "620608",
    "label": "Penda Tanggaring Baru",
    "value": "Penda Tanggaring Baru"
  },
  {
    "id": "6206082032",
    "district_id": "620608",
    "label": "Teluk Tampang",
    "value": "Teluk Tampang"
  },
  {
    "id": "6206082033",
    "district_id": "620608",
    "label": "Tumbang Manangei",
    "value": "Tumbang Manangei"
  },
  {
    "id": "6206092001",
    "district_id": "620609",
    "label": "Mendawai",
    "value": "Mendawai"
  },
  {
    "id": "6206092002",
    "district_id": "620609",
    "label": "Teluk Sebulu",
    "value": "Teluk Sebulu"
  },
  {
    "id": "6206092003",
    "district_id": "620609",
    "label": "Kampung Melayu",
    "value": "Kampung Melayu"
  },
  {
    "id": "6206092004",
    "district_id": "620609",
    "label": "Tewang Kampung",
    "value": "Tewang Kampung"
  },
  {
    "id": "6206092005",
    "district_id": "620609",
    "label": "Perigi",
    "value": "Perigi"
  },
  {
    "id": "6206092006",
    "district_id": "620609",
    "label": "Mekar Tani",
    "value": "Mekar Tani"
  },
  {
    "id": "6206092007",
    "district_id": "620609",
    "label": "Tumbang Bulan",
    "value": "Tumbang Bulan"
  },
  {
    "id": "6206101001",
    "district_id": "620610",
    "label": "Pagatan Hilir",
    "value": "Pagatan Hilir"
  },
  {
    "id": "6206101002",
    "district_id": "620610",
    "label": "Pagatan Hulu",
    "value": "Pagatan Hulu"
  },
  {
    "id": "6206102003",
    "district_id": "620610",
    "label": "Kampung Keramat",
    "value": "Kampung Keramat"
  },
  {
    "id": "6206102004",
    "district_id": "620610",
    "label": "Kampung Tengah",
    "value": "Kampung Tengah"
  },
  {
    "id": "6206102005",
    "district_id": "620610",
    "label": "Jaya Makmur",
    "value": "Jaya Makmur"
  },
  {
    "id": "6206102006",
    "district_id": "620610",
    "label": "Subur Indah",
    "value": "Subur Indah"
  },
  {
    "id": "6206102007",
    "district_id": "620610",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6206102008",
    "district_id": "620610",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "6206102009",
    "district_id": "620610",
    "label": "Setia Mulia",
    "value": "Setia Mulia"
  },
  {
    "id": "6206102010",
    "district_id": "620610",
    "label": "Singam Raya",
    "value": "Singam Raya"
  },
  {
    "id": "6206102011",
    "district_id": "620610",
    "label": "Sebangau Jaya",
    "value": "Sebangau Jaya"
  },
  {
    "id": "6206102012",
    "district_id": "620610",
    "label": "Sungai Kaki",
    "value": "Sungai Kaki"
  },
  {
    "id": "6206102013",
    "district_id": "620610",
    "label": "Selat Baning",
    "value": "Selat Baning"
  },
  {
    "id": "6206102014",
    "district_id": "620610",
    "label": "Makmur Utama",
    "value": "Makmur Utama"
  },
  {
    "id": "6206102015",
    "district_id": "620610",
    "label": "Bumi Subur",
    "value": "Bumi Subur"
  },
  {
    "id": "6206102016",
    "district_id": "620610",
    "label": "Bakung Raya",
    "value": "Bakung Raya"
  },
  {
    "id": "6206112001",
    "district_id": "620611",
    "label": "Talingke",
    "value": "Talingke"
  },
  {
    "id": "6206112002",
    "district_id": "620611",
    "label": "Hiyang Bana",
    "value": "Hiyang Bana"
  },
  {
    "id": "6206112003",
    "district_id": "620611",
    "label": "Petak Bahandang",
    "value": "Petak Bahandang"
  },
  {
    "id": "6206112004",
    "district_id": "620611",
    "label": "Handiwung",
    "value": "Handiwung"
  },
  {
    "id": "6206112005",
    "district_id": "620611",
    "label": "Tumbang Panggo",
    "value": "Tumbang Panggo"
  },
  {
    "id": "6206112006",
    "district_id": "620611",
    "label": "Tewang Tampang",
    "value": "Tewang Tampang"
  },
  {
    "id": "6206112007",
    "district_id": "620611",
    "label": "Luwuk Kanan",
    "value": "Luwuk Kanan"
  },
  {
    "id": "6206112008",
    "district_id": "620611",
    "label": "Luwuk Kiri",
    "value": "Luwuk Kiri"
  },
  {
    "id": "6206122001",
    "district_id": "620612",
    "label": "Tumbang Baraoi",
    "value": "Tumbang Baraoi"
  },
  {
    "id": "6206122002",
    "district_id": "620612",
    "label": "Tumbang Jala",
    "value": "Tumbang Jala"
  },
  {
    "id": "6206122003",
    "district_id": "620612",
    "label": "Batu Badak",
    "value": "Batu Badak"
  },
  {
    "id": "6206122004",
    "district_id": "620612",
    "label": "Batu Tukan",
    "value": "Batu Tukan"
  },
  {
    "id": "6206122005",
    "district_id": "620612",
    "label": "Tumbang Tangoi",
    "value": "Tumbang Tangoi"
  },
  {
    "id": "6206122006",
    "district_id": "620612",
    "label": "Nusa Kutau",
    "value": "Nusa Kutau"
  },
  {
    "id": "6206122007",
    "district_id": "620612",
    "label": "Tumbang Habangoi",
    "value": "Tumbang Habangoi"
  },
  {
    "id": "6206132001",
    "district_id": "620613",
    "label": "Tumbang Gaei",
    "value": "Tumbang Gaei"
  },
  {
    "id": "6206132002",
    "district_id": "620613",
    "label": "Tumbang Kataei",
    "value": "Tumbang Kataei"
  },
  {
    "id": "6206132003",
    "district_id": "620613",
    "label": "Tumbang Karuei",
    "value": "Tumbang Karuei"
  },
  {
    "id": "6206132004",
    "district_id": "620613",
    "label": "Tumbang Dahuei",
    "value": "Tumbang Dahuei"
  },
  {
    "id": "6206132005",
    "district_id": "620613",
    "label": "Rantau Pandan",
    "value": "Rantau Pandan"
  },
  {
    "id": "6206132006",
    "district_id": "620613",
    "label": "Rangan Rondan",
    "value": "Rangan Rondan"
  },
  {
    "id": "6206132007",
    "district_id": "620613",
    "label": "Rangan Bahekang",
    "value": "Rangan Bahekang"
  },
  {
    "id": "6206132008",
    "district_id": "620613",
    "label": "Tumbang Kajamei",
    "value": "Tumbang Kajamei"
  },
  {
    "id": "6206132009",
    "district_id": "620613",
    "label": "Tumbang Kaburai",
    "value": "Tumbang Kaburai"
  },
  {
    "id": "6206132010",
    "district_id": "620613",
    "label": "Tanjung Batik",
    "value": "Tanjung Batik"
  },
  {
    "id": "6206132011",
    "district_id": "620613",
    "label": "Penda Nange",
    "value": "Penda Nange"
  },
  {
    "id": "6207011001",
    "district_id": "620701",
    "label": "Kuala Pembuang I",
    "value": "Kuala Pembuang I"
  },
  {
    "id": "6207011002",
    "district_id": "620701",
    "label": "Kuala Pembuang II",
    "value": "Kuala Pembuang II"
  },
  {
    "id": "6207012005",
    "district_id": "620701",
    "label": "Pematang Limau",
    "value": "Pematang Limau"
  },
  {
    "id": "6207012006",
    "district_id": "620701",
    "label": "Sungai Perlu",
    "value": "Sungai Perlu"
  },
  {
    "id": "6207012007",
    "district_id": "620701",
    "label": "Tanjung Rangas",
    "value": "Tanjung Rangas"
  },
  {
    "id": "6207012008",
    "district_id": "620701",
    "label": "Muara Dua",
    "value": "Muara Dua"
  },
  {
    "id": "6207012009",
    "district_id": "620701",
    "label": "Jahitan",
    "value": "Jahitan"
  },
  {
    "id": "6207012010",
    "district_id": "620701",
    "label": "Baung",
    "value": "Baung"
  },
  {
    "id": "6207012015",
    "district_id": "620701",
    "label": "Persil Raya",
    "value": "Persil Raya"
  },
  {
    "id": "6207012016",
    "district_id": "620701",
    "label": "Sungai Undang",
    "value": "Sungai Undang"
  },
  {
    "id": "6207021016",
    "district_id": "620702",
    "label": "Rantau Pulut",
    "value": "Rantau Pulut"
  },
  {
    "id": "6207022008",
    "district_id": "620702",
    "label": "Gantung Pangayuh",
    "value": "Gantung Pangayuh"
  },
  {
    "id": "6207022009",
    "district_id": "620702",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "6207022010",
    "district_id": "620702",
    "label": "Suka Mandang",
    "value": "Suka Mandang"
  },
  {
    "id": "6207022011",
    "district_id": "620702",
    "label": "Ayawan",
    "value": "Ayawan"
  },
  {
    "id": "6207022012",
    "district_id": "620702",
    "label": "Mugi Panyuhu",
    "value": "Mugi Panyuhu"
  },
  {
    "id": "6207022013",
    "district_id": "620702",
    "label": "Bukit Buluh",
    "value": "Bukit Buluh"
  },
  {
    "id": "6207022014",
    "district_id": "620702",
    "label": "Tumbang Bai",
    "value": "Tumbang Bai"
  },
  {
    "id": "6207022015",
    "district_id": "620702",
    "label": "Durian Tunggal",
    "value": "Durian Tunggal"
  },
  {
    "id": "6207022017",
    "district_id": "620702",
    "label": "Pangke",
    "value": "Pangke"
  },
  {
    "id": "6207022018",
    "district_id": "620702",
    "label": "Panyumpa",
    "value": "Panyumpa"
  },
  {
    "id": "6207022022",
    "district_id": "620702",
    "label": "Sukorejo",
    "value": "Sukorejo"
  },
  {
    "id": "6207022023",
    "district_id": "620702",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "6207022024",
    "district_id": "620702",
    "label": "Suka Jaya",
    "value": "Suka Jaya"
  },
  {
    "id": "6207022026",
    "district_id": "620702",
    "label": "Panca Jaya",
    "value": "Panca Jaya"
  },
  {
    "id": "6207022029",
    "district_id": "620702",
    "label": "Tangga Batu",
    "value": "Tangga Batu"
  },
  {
    "id": "6207022030",
    "district_id": "620702",
    "label": "Ringin Agung",
    "value": "Ringin Agung"
  },
  {
    "id": "6207022031",
    "district_id": "620702",
    "label": "Batu Agung",
    "value": "Batu Agung"
  },
  {
    "id": "6207022032",
    "district_id": "620702",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "6207022033",
    "district_id": "620702",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6207032001",
    "district_id": "620703",
    "label": "Telaga Pulang",
    "value": "Telaga Pulang"
  },
  {
    "id": "6207032002",
    "district_id": "620703",
    "label": "Cempaka Baru",
    "value": "Cempaka Baru"
  },
  {
    "id": "6207032003",
    "district_id": "620703",
    "label": "Palingkau",
    "value": "Palingkau"
  },
  {
    "id": "6207032004",
    "district_id": "620703",
    "label": "Ulak Batu",
    "value": "Ulak Batu"
  },
  {
    "id": "6207032005",
    "district_id": "620703",
    "label": "Paren",
    "value": "Paren"
  },
  {
    "id": "6207032006",
    "district_id": "620703",
    "label": "Banua Usang",
    "value": "Banua Usang"
  },
  {
    "id": "6207032013",
    "district_id": "620703",
    "label": "Sembuluh I",
    "value": "Sembuluh I"
  },
  {
    "id": "6207032014",
    "district_id": "620703",
    "label": "Sembuluh II",
    "value": "Sembuluh II"
  },
  {
    "id": "6207042001",
    "district_id": "620704",
    "label": "Tanjung Hanau",
    "value": "Tanjung Hanau"
  },
  {
    "id": "6207042002",
    "district_id": "620704",
    "label": "Parang Batang",
    "value": "Parang Batang"
  },
  {
    "id": "6207042003",
    "district_id": "620704",
    "label": "Bahaur",
    "value": "Bahaur"
  },
  {
    "id": "6207042004",
    "district_id": "620704",
    "label": "Pembuang Hulu I",
    "value": "Pembuang Hulu I"
  },
  {
    "id": "6207042005",
    "district_id": "620704",
    "label": "Pembuang Hulu II",
    "value": "Pembuang Hulu II"
  },
  {
    "id": "6207042006",
    "district_id": "620704",
    "label": "Derangga",
    "value": "Derangga"
  },
  {
    "id": "6207042011",
    "district_id": "620704",
    "label": "Paring Raya",
    "value": "Paring Raya"
  },
  {
    "id": "6207052020",
    "district_id": "620705",
    "label": "Tumbang Bahan",
    "value": "Tumbang Bahan"
  },
  {
    "id": "6207052021",
    "district_id": "620705",
    "label": "Tumbang Kalang",
    "value": "Tumbang Kalang"
  },
  {
    "id": "6207052022",
    "district_id": "620705",
    "label": "Tusuk Belawan",
    "value": "Tusuk Belawan"
  },
  {
    "id": "6207052023",
    "district_id": "620705",
    "label": "Marandang",
    "value": "Marandang"
  },
  {
    "id": "6207052024",
    "district_id": "620705",
    "label": "Tumbang Suei",
    "value": "Tumbang Suei"
  },
  {
    "id": "6207052025",
    "district_id": "620705",
    "label": "Tumbang Manjul",
    "value": "Tumbang Manjul"
  },
  {
    "id": "6207052026",
    "district_id": "620705",
    "label": "Mojang Baru",
    "value": "Mojang Baru"
  },
  {
    "id": "6207052027",
    "district_id": "620705",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6207052028",
    "district_id": "620705",
    "label": "Mongoh Juoi",
    "value": "Mongoh Juoi"
  },
  {
    "id": "6207052030",
    "district_id": "620705",
    "label": "Sepundu Hantu",
    "value": "Sepundu Hantu"
  },
  {
    "id": "6207052031",
    "district_id": "620705",
    "label": "Tumbang Kubang",
    "value": "Tumbang Kubang"
  },
  {
    "id": "6207052032",
    "district_id": "620705",
    "label": "Tumbang Laku",
    "value": "Tumbang Laku"
  },
  {
    "id": "6207052033",
    "district_id": "620705",
    "label": "Tanjung Paku",
    "value": "Tanjung Paku"
  },
  {
    "id": "6207052034",
    "district_id": "620705",
    "label": "Buntut Sapau",
    "value": "Buntut Sapau"
  },
  {
    "id": "6207052035",
    "district_id": "620705",
    "label": "Tumbang Taberau",
    "value": "Tumbang Taberau"
  },
  {
    "id": "6207052036",
    "district_id": "620705",
    "label": "Riam Batang",
    "value": "Riam Batang"
  },
  {
    "id": "6207052037",
    "district_id": "620705",
    "label": "Tumbang Sepan",
    "value": "Tumbang Sepan"
  },
  {
    "id": "6207052038",
    "district_id": "620705",
    "label": "Tumbang Setawai",
    "value": "Tumbang Setawai"
  },
  {
    "id": "6207052039",
    "district_id": "620705",
    "label": "Tumbang Kasai",
    "value": "Tumbang Kasai"
  },
  {
    "id": "6207052040",
    "district_id": "620705",
    "label": "Tumbang Darap",
    "value": "Tumbang Darap"
  },
  {
    "id": "6207062001",
    "district_id": "620706",
    "label": "Mekar Indah",
    "value": "Mekar Indah"
  },
  {
    "id": "6207062002",
    "district_id": "620706",
    "label": "Halimaung Jaya",
    "value": "Halimaung Jaya"
  },
  {
    "id": "6207062003",
    "district_id": "620706",
    "label": "Bangun Harja",
    "value": "Bangun Harja"
  },
  {
    "id": "6207062004",
    "district_id": "620706",
    "label": "Kartika Bhakti",
    "value": "Kartika Bhakti"
  },
  {
    "id": "6207062005",
    "district_id": "620706",
    "label": "Pematang Panjang",
    "value": "Pematang Panjang"
  },
  {
    "id": "6207062006",
    "district_id": "620706",
    "label": "Sungai Bakau",
    "value": "Sungai Bakau"
  },
  {
    "id": "6207072001",
    "district_id": "620707",
    "label": "Terawan",
    "value": "Terawan"
  },
  {
    "id": "6207072002",
    "district_id": "620707",
    "label": "Selunuk",
    "value": "Selunuk"
  },
  {
    "id": "6207072003",
    "district_id": "620707",
    "label": "Lanpasa",
    "value": "Lanpasa"
  },
  {
    "id": "6207072005",
    "district_id": "620707",
    "label": "Bangkal",
    "value": "Bangkal"
  },
  {
    "id": "6207072006",
    "district_id": "620707",
    "label": "Tabiku",
    "value": "Tabiku"
  },
  {
    "id": "6207082001",
    "district_id": "620708",
    "label": "Asam Baru",
    "value": "Asam Baru"
  },
  {
    "id": "6207082002",
    "district_id": "620708",
    "label": "Tanjung Hara",
    "value": "Tanjung Hara"
  },
  {
    "id": "6207082003",
    "district_id": "620708",
    "label": "Tanjung Paring",
    "value": "Tanjung Paring"
  },
  {
    "id": "6207082004",
    "district_id": "620708",
    "label": "Tanjung Rangas II",
    "value": "Tanjung Rangas II"
  },
  {
    "id": "6207082005",
    "district_id": "620708",
    "label": "Panimba Raya",
    "value": "Panimba Raya"
  },
  {
    "id": "6207082006",
    "district_id": "620708",
    "label": "Rungau Raya",
    "value": "Rungau Raya"
  },
  {
    "id": "6207092001",
    "district_id": "620709",
    "label": "Sebabi",
    "value": "Sebabi"
  },
  {
    "id": "6207092002",
    "district_id": "620709",
    "label": "Sandul",
    "value": "Sandul"
  },
  {
    "id": "6207092003",
    "district_id": "620709",
    "label": "Kalang",
    "value": "Kalang"
  },
  {
    "id": "6207092004",
    "district_id": "620709",
    "label": "Derawa",
    "value": "Derawa"
  },
  {
    "id": "6207092005",
    "district_id": "620709",
    "label": "Wana Tirta",
    "value": "Wana Tirta"
  },
  {
    "id": "6207092006",
    "district_id": "620709",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "6207092007",
    "district_id": "620709",
    "label": "Durian Kait",
    "value": "Durian Kait"
  },
  {
    "id": "6207092008",
    "district_id": "620709",
    "label": "Sahabu",
    "value": "Sahabu"
  },
  {
    "id": "6207092009",
    "district_id": "620709",
    "label": "Batu Menangis",
    "value": "Batu Menangis"
  },
  {
    "id": "6207102001",
    "district_id": "620710",
    "label": "Tumbang Magin",
    "value": "Tumbang Magin"
  },
  {
    "id": "6207102002",
    "district_id": "620710",
    "label": "Tumbang Setoli",
    "value": "Tumbang Setoli"
  },
  {
    "id": "6207102003",
    "district_id": "620710",
    "label": "Tumbang Hentas",
    "value": "Tumbang Hentas"
  },
  {
    "id": "6207102004",
    "district_id": "620710",
    "label": "Tumbang Langkai",
    "value": "Tumbang Langkai"
  },
  {
    "id": "6207102005",
    "district_id": "620710",
    "label": "Tumbang Salau",
    "value": "Tumbang Salau"
  },
  {
    "id": "6207102006",
    "district_id": "620710",
    "label": "Tumbang Gugup",
    "value": "Tumbang Gugup"
  },
  {
    "id": "6207102007",
    "district_id": "620710",
    "label": "Rangkang Munduk",
    "value": "Rangkang Munduk"
  },
  {
    "id": "6207102008",
    "district_id": "620710",
    "label": "Tanjung Tukal",
    "value": "Tanjung Tukal"
  },
  {
    "id": "6207102009",
    "district_id": "620710",
    "label": "Rantau Betung",
    "value": "Rantau Betung"
  },
  {
    "id": "6208011003",
    "district_id": "620801",
    "label": "Mendawai",
    "value": "Mendawai"
  },
  {
    "id": "6208011004",
    "district_id": "620801",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "6208012001",
    "district_id": "620801",
    "label": "Natai Sedawak",
    "value": "Natai Sedawak"
  },
  {
    "id": "6208012002",
    "district_id": "620801",
    "label": "Pudu",
    "value": "Pudu"
  },
  {
    "id": "6208012005",
    "district_id": "620801",
    "label": "Kartamulia",
    "value": "Kartamulia"
  },
  {
    "id": "6208012006",
    "district_id": "620801",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "6208012007",
    "district_id": "620801",
    "label": "Pangkalan Muntai",
    "value": "Pangkalan Muntai"
  },
  {
    "id": "6208012008",
    "district_id": "620801",
    "label": "Petarikan",
    "value": "Petarikan"
  },
  {
    "id": "6208021001",
    "district_id": "620802",
    "label": "Kuala Jelai",
    "value": "Kuala Jelai"
  },
  {
    "id": "6208022002",
    "district_id": "620802",
    "label": "Pulau Nibung",
    "value": "Pulau Nibung"
  },
  {
    "id": "6208022003",
    "district_id": "620802",
    "label": "Sungai Baru",
    "value": "Sungai Baru"
  },
  {
    "id": "6208022004",
    "district_id": "620802",
    "label": "Sungai Bundung",
    "value": "Sungai Bundung"
  },
  {
    "id": "6208022005",
    "district_id": "620802",
    "label": "Sungai Raja",
    "value": "Sungai Raja"
  },
  {
    "id": "6208032001",
    "district_id": "620803",
    "label": "Jihing",
    "value": "Jihing"
  },
  {
    "id": "6208032002",
    "district_id": "620803",
    "label": "Air Dua",
    "value": "Air Dua"
  },
  {
    "id": "6208032005",
    "district_id": "620803",
    "label": "Lupu Peruca",
    "value": "Lupu Peruca"
  },
  {
    "id": "6208032006",
    "district_id": "620803",
    "label": "Balai Riam",
    "value": "Balai Riam"
  },
  {
    "id": "6208032007",
    "district_id": "620803",
    "label": "Pempaning",
    "value": "Pempaning"
  },
  {
    "id": "6208032011",
    "district_id": "620803",
    "label": "Sekuningan Baru",
    "value": "Sekuningan Baru"
  },
  {
    "id": "6208032012",
    "district_id": "620803",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "6208032013",
    "district_id": "620803",
    "label": "Bukit Sungkai",
    "value": "Bukit Sungkai"
  },
  {
    "id": "6208042001",
    "district_id": "620804",
    "label": "Sungai Damar",
    "value": "Sungai Damar"
  },
  {
    "id": "6208042002",
    "district_id": "620804",
    "label": "Sungai Tabuk",
    "value": "Sungai Tabuk"
  },
  {
    "id": "6208042003",
    "district_id": "620804",
    "label": "Sungai Cabang Barat",
    "value": "Sungai Cabang Barat"
  },
  {
    "id": "6208042004",
    "district_id": "620804",
    "label": "Sungai Pasir",
    "value": "Sungai Pasir"
  },
  {
    "id": "6208052001",
    "district_id": "620805",
    "label": "Kenawan",
    "value": "Kenawan"
  },
  {
    "id": "6208052002",
    "district_id": "620805",
    "label": "Laman Baru",
    "value": "Laman Baru"
  },
  {
    "id": "6208052003",
    "district_id": "620805",
    "label": "Ajang",
    "value": "Ajang"
  },
  {
    "id": "6208052004",
    "district_id": "620805",
    "label": "Semantun",
    "value": "Semantun"
  },
  {
    "id": "6208052005",
    "district_id": "620805",
    "label": "Nibung Terjun",
    "value": "Nibung Terjun"
  },
  {
    "id": "6208052006",
    "district_id": "620805",
    "label": "Natai Kondang",
    "value": "Natai Kondang"
  },
  {
    "id": "6208052007",
    "district_id": "620805",
    "label": "Sembikuan",
    "value": "Sembikuan"
  },
  {
    "id": "6209011012",
    "district_id": "620901",
    "label": "Tapin Bini",
    "value": "Tapin Bini"
  },
  {
    "id": "6209012001",
    "district_id": "620901",
    "label": "Sungai Tuat",
    "value": "Sungai Tuat"
  },
  {
    "id": "6209012002",
    "district_id": "620901",
    "label": "Tanjung Beringin",
    "value": "Tanjung Beringin"
  },
  {
    "id": "6209012003",
    "district_id": "620901",
    "label": "Cuhai",
    "value": "Cuhai"
  },
  {
    "id": "6209012004",
    "district_id": "620901",
    "label": "Kawa",
    "value": "Kawa"
  },
  {
    "id": "6209012005",
    "district_id": "620901",
    "label": "Karang Taba",
    "value": "Karang Taba"
  },
  {
    "id": "6209012006",
    "district_id": "620901",
    "label": "Penopa",
    "value": "Penopa"
  },
  {
    "id": "6209012007",
    "district_id": "620901",
    "label": "Suja",
    "value": "Suja"
  },
  {
    "id": "6209012008",
    "district_id": "620901",
    "label": "Sekoban",
    "value": "Sekoban"
  },
  {
    "id": "6209012009",
    "district_id": "620901",
    "label": "Bakonsu",
    "value": "Bakonsu"
  },
  {
    "id": "6209012022",
    "district_id": "620901",
    "label": "Samu Jaya",
    "value": "Samu Jaya"
  },
  {
    "id": "6209021017",
    "district_id": "620902",
    "label": "Kudangan",
    "value": "Kudangan"
  },
  {
    "id": "6209022004",
    "district_id": "620902",
    "label": "Riam Panahan",
    "value": "Riam Panahan"
  },
  {
    "id": "6209022005",
    "district_id": "620902",
    "label": "Sepoyu",
    "value": "Sepoyu"
  },
  {
    "id": "6209022010",
    "district_id": "620902",
    "label": "Nyalang",
    "value": "Nyalang"
  },
  {
    "id": "6209022011",
    "district_id": "620902",
    "label": "Riam Tinggi",
    "value": "Riam Tinggi"
  },
  {
    "id": "6209022012",
    "district_id": "620902",
    "label": "Landau Kantu",
    "value": "Landau Kantu"
  },
  {
    "id": "6209022013",
    "district_id": "620902",
    "label": "Lopus",
    "value": "Lopus"
  },
  {
    "id": "6209022014",
    "district_id": "620902",
    "label": "Kubung",
    "value": "Kubung"
  },
  {
    "id": "6209022015",
    "district_id": "620902",
    "label": "Sekombulan",
    "value": "Sekombulan"
  },
  {
    "id": "6209022016",
    "district_id": "620902",
    "label": "Penyombaan",
    "value": "Penyombaan"
  },
  {
    "id": "6209022020",
    "district_id": "620902",
    "label": "Hulu Jojabo",
    "value": "Hulu Jojabo"
  },
  {
    "id": "6209031005",
    "district_id": "620903",
    "label": "Nanga Bulik",
    "value": "Nanga Bulik"
  },
  {
    "id": "6209032001",
    "district_id": "620903",
    "label": "Sungai Mentawa",
    "value": "Sungai Mentawa"
  },
  {
    "id": "6209032002",
    "district_id": "620903",
    "label": "Guci",
    "value": "Guci"
  },
  {
    "id": "6209032003",
    "district_id": "620903",
    "label": "Batu Kotam",
    "value": "Batu Kotam"
  },
  {
    "id": "6209032004",
    "district_id": "620903",
    "label": "Kujan",
    "value": "Kujan"
  },
  {
    "id": "6209032006",
    "district_id": "620903",
    "label": "Bunut",
    "value": "Bunut"
  },
  {
    "id": "6209032007",
    "district_id": "620903",
    "label": "Beruta",
    "value": "Beruta"
  },
  {
    "id": "6209032008",
    "district_id": "620903",
    "label": "Tamiang",
    "value": "Tamiang"
  },
  {
    "id": "6209032031",
    "district_id": "620903",
    "label": "Bumi Agung",
    "value": "Bumi Agung"
  },
  {
    "id": "6209032032",
    "district_id": "620903",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "6209032036",
    "district_id": "620903",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "6209032037",
    "district_id": "620903",
    "label": "Arga Mulya",
    "value": "Arga Mulya"
  },
  {
    "id": "6209032043",
    "district_id": "620903",
    "label": "Perigi Raya",
    "value": "Perigi Raya"
  },
  {
    "id": "6209032044",
    "district_id": "620903",
    "label": "Nanga Pamalontian",
    "value": "Nanga Pamalontian"
  },
  {
    "id": "6209042001",
    "district_id": "620904",
    "label": "Nanga Palikodan",
    "value": "Nanga Palikodan"
  },
  {
    "id": "6209042002",
    "district_id": "620904",
    "label": "Sungkup",
    "value": "Sungkup"
  },
  {
    "id": "6209042003",
    "district_id": "620904",
    "label": "Nuangan",
    "value": "Nuangan"
  },
  {
    "id": "6209042004",
    "district_id": "620904",
    "label": "Nanga Koring",
    "value": "Nanga Koring"
  },
  {
    "id": "6209042005",
    "district_id": "620904",
    "label": "Toka",
    "value": "Toka"
  },
  {
    "id": "6209042006",
    "district_id": "620904",
    "label": "Sepondam",
    "value": "Sepondam"
  },
  {
    "id": "6209042007",
    "district_id": "620904",
    "label": "Merambang",
    "value": "Merambang"
  },
  {
    "id": "6209042008",
    "district_id": "620904",
    "label": "Pedongatan",
    "value": "Pedongatan"
  },
  {
    "id": "6209042009",
    "district_id": "620904",
    "label": "Batu Tunggal",
    "value": "Batu Tunggal"
  },
  {
    "id": "6209042010",
    "district_id": "620904",
    "label": "Nanga Kemujan",
    "value": "Nanga Kemujan"
  },
  {
    "id": "6209042011",
    "district_id": "620904",
    "label": "Bukit Jaya",
    "value": "Bukit Jaya"
  },
  {
    "id": "6209042012",
    "district_id": "620904",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6209052001",
    "district_id": "620905",
    "label": "Melata",
    "value": "Melata"
  },
  {
    "id": "6209052002",
    "district_id": "620905",
    "label": "Nanuah",
    "value": "Nanuah"
  },
  {
    "id": "6209052003",
    "district_id": "620905",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6209052004",
    "district_id": "620905",
    "label": "Lubuk Hiju",
    "value": "Lubuk Hiju"
  },
  {
    "id": "6209052005",
    "district_id": "620905",
    "label": "Topalan",
    "value": "Topalan"
  },
  {
    "id": "6209052006",
    "district_id": "620905",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "6209052007",
    "district_id": "620905",
    "label": "Modang Mas",
    "value": "Modang Mas"
  },
  {
    "id": "6209052008",
    "district_id": "620905",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "6209052009",
    "district_id": "620905",
    "label": "Mukti Manunggal",
    "value": "Mukti Manunggal"
  },
  {
    "id": "6209052010",
    "district_id": "620905",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "6209052011",
    "district_id": "620905",
    "label": "Bukit Harum",
    "value": "Bukit Harum"
  },
  {
    "id": "6209062001",
    "district_id": "620906",
    "label": "Bina Bhakti",
    "value": "Bina Bhakti"
  },
  {
    "id": "6209062002",
    "district_id": "620906",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "6209062003",
    "district_id": "620906",
    "label": "Mekar Mulya",
    "value": "Mekar Mulya"
  },
  {
    "id": "6209062004",
    "district_id": "620906",
    "label": "Jangkar Prima",
    "value": "Jangkar Prima"
  },
  {
    "id": "6209062005",
    "district_id": "620906",
    "label": "Purwareja",
    "value": "Purwareja"
  },
  {
    "id": "6209062006",
    "district_id": "620906",
    "label": "Tri Tunggal",
    "value": "Tri Tunggal"
  },
  {
    "id": "6209062007",
    "district_id": "620906",
    "label": "Batu Hambawang",
    "value": "Batu Hambawang"
  },
  {
    "id": "6209062008",
    "district_id": "620906",
    "label": "Rimba Jaya",
    "value": "Rimba Jaya"
  },
  {
    "id": "6209072001",
    "district_id": "620907",
    "label": "Nanga Belantikan",
    "value": "Nanga Belantikan"
  },
  {
    "id": "6209072002",
    "district_id": "620907",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "6209072003",
    "district_id": "620907",
    "label": "Tangga Batu",
    "value": "Tangga Batu"
  },
  {
    "id": "6209072004",
    "district_id": "620907",
    "label": "Belibi",
    "value": "Belibi"
  },
  {
    "id": "6209072005",
    "district_id": "620907",
    "label": "Bayat",
    "value": "Bayat"
  },
  {
    "id": "6209072006",
    "district_id": "620907",
    "label": "Karang Besi",
    "value": "Karang Besi"
  },
  {
    "id": "6209072007",
    "district_id": "620907",
    "label": "Benuatan",
    "value": "Benuatan"
  },
  {
    "id": "6209072008",
    "district_id": "620907",
    "label": "Kahingai",
    "value": "Kahingai"
  },
  {
    "id": "6209072009",
    "district_id": "620907",
    "label": "Nanga Matu",
    "value": "Nanga Matu"
  },
  {
    "id": "6209072010",
    "district_id": "620907",
    "label": "Bintang Mangalih",
    "value": "Bintang Mangalih"
  },
  {
    "id": "6209072011",
    "district_id": "620907",
    "label": "Petarikan",
    "value": "Petarikan"
  },
  {
    "id": "6209072012",
    "district_id": "620907",
    "label": "Sumber Cahaya",
    "value": "Sumber Cahaya"
  },
  {
    "id": "6209082001",
    "district_id": "620908",
    "label": "Batu Tambun",
    "value": "Batu Tambun"
  },
  {
    "id": "6209082002",
    "district_id": "620908",
    "label": "Kinipan",
    "value": "Kinipan"
  },
  {
    "id": "6209082003",
    "district_id": "620908",
    "label": "Ginih",
    "value": "Ginih"
  },
  {
    "id": "6209082004",
    "district_id": "620908",
    "label": "Benakitan",
    "value": "Benakitan"
  },
  {
    "id": "6209082005",
    "district_id": "620908",
    "label": "Liku",
    "value": "Liku"
  },
  {
    "id": "6209082006",
    "district_id": "620908",
    "label": "Mengkalang",
    "value": "Mengkalang"
  },
  {
    "id": "6209082007",
    "district_id": "620908",
    "label": "Karang Mas",
    "value": "Karang Mas"
  },
  {
    "id": "6209082008",
    "district_id": "620908",
    "label": "Kina",
    "value": "Kina"
  },
  {
    "id": "6209082009",
    "district_id": "620908",
    "label": "Jamuat",
    "value": "Jamuat"
  },
  {
    "id": "6210011001",
    "district_id": "621001",
    "label": "Sepang Simin",
    "value": "Sepang Simin"
  },
  {
    "id": "6210012002",
    "district_id": "621001",
    "label": "Sepang Kota",
    "value": "Sepang Kota"
  },
  {
    "id": "6210012003",
    "district_id": "621001",
    "label": "Tewai Baru",
    "value": "Tewai Baru"
  },
  {
    "id": "6210012004",
    "district_id": "621001",
    "label": "Tanjung Karitak",
    "value": "Tanjung Karitak"
  },
  {
    "id": "6210012011",
    "district_id": "621001",
    "label": "Pamatang Limau",
    "value": "Pamatang Limau"
  },
  {
    "id": "6210012012",
    "district_id": "621001",
    "label": "Tampelas",
    "value": "Tampelas"
  },
  {
    "id": "6210012014",
    "district_id": "621001",
    "label": "Rabauh",
    "value": "Rabauh"
  },
  {
    "id": "6210021001",
    "district_id": "621002",
    "label": "Tampang Tumbang Anjir",
    "value": "Tampang Tumbang Anjir"
  },
  {
    "id": "6210021012",
    "district_id": "621002",
    "label": "Kurun",
    "value": "Kurun"
  },
  {
    "id": "6210022002",
    "district_id": "621002",
    "label": "Petak Bahandang",
    "value": "Petak Bahandang"
  },
  {
    "id": "6210022003",
    "district_id": "621002",
    "label": "Tanjung Riu",
    "value": "Tanjung Riu"
  },
  {
    "id": "6210022004",
    "district_id": "621002",
    "label": "Teluk Nyatu",
    "value": "Teluk Nyatu"
  },
  {
    "id": "6210022005",
    "district_id": "621002",
    "label": "Tumbang Lampahung",
    "value": "Tumbang Lampahung"
  },
  {
    "id": "6210022006",
    "district_id": "621002",
    "label": "Tewang Pajangan",
    "value": "Tewang Pajangan"
  },
  {
    "id": "6210022007",
    "district_id": "621002",
    "label": "Tumbang Tariak",
    "value": "Tumbang Tariak"
  },
  {
    "id": "6210022008",
    "district_id": "621002",
    "label": "Tumbang Miwan",
    "value": "Tumbang Miwan"
  },
  {
    "id": "6210022009",
    "district_id": "621002",
    "label": "Hurung Bunut",
    "value": "Hurung Bunut"
  },
  {
    "id": "6210022010",
    "district_id": "621002",
    "label": "Tumbang Hakau",
    "value": "Tumbang Hakau"
  },
  {
    "id": "6210022011",
    "district_id": "621002",
    "label": "Pilang Munduk",
    "value": "Pilang Munduk"
  },
  {
    "id": "6210022013",
    "district_id": "621002",
    "label": "Tumbang Manyangan",
    "value": "Tumbang Manyangan"
  },
  {
    "id": "6210022014",
    "district_id": "621002",
    "label": "Penda Pilang",
    "value": "Penda Pilang"
  },
  {
    "id": "6210022015",
    "district_id": "621002",
    "label": "Tumbang Tambirah",
    "value": "Tumbang Tambirah"
  },
  {
    "id": "6210031005",
    "district_id": "621003",
    "label": "Tewah",
    "value": "Tewah"
  },
  {
    "id": "6210032001",
    "district_id": "621003",
    "label": "Sare Rangan",
    "value": "Sare Rangan"
  },
  {
    "id": "6210032002",
    "district_id": "621003",
    "label": "Tumbang Pajangei",
    "value": "Tumbang Pajangei"
  },
  {
    "id": "6210032003",
    "district_id": "621003",
    "label": "Kasintu",
    "value": "Kasintu"
  },
  {
    "id": "6210032004",
    "district_id": "621003",
    "label": "Batu Nyiwuh",
    "value": "Batu Nyiwuh"
  },
  {
    "id": "6210032006",
    "district_id": "621003",
    "label": "Tumbang Habaon",
    "value": "Tumbang Habaon"
  },
  {
    "id": "6210032007",
    "district_id": "621003",
    "label": "Sei Riang",
    "value": "Sei Riang"
  },
  {
    "id": "6210032008",
    "district_id": "621003",
    "label": "Sandung Tambun",
    "value": "Sandung Tambun"
  },
  {
    "id": "6210032009",
    "district_id": "621003",
    "label": "Tanjung Untung",
    "value": "Tanjung Untung"
  },
  {
    "id": "6210032010",
    "district_id": "621003",
    "label": "Upon Batu",
    "value": "Upon Batu"
  },
  {
    "id": "6210032011",
    "district_id": "621003",
    "label": "Sumur Mas",
    "value": "Sumur Mas"
  },
  {
    "id": "6210032012",
    "district_id": "621003",
    "label": "Batu Nyapau",
    "value": "Batu Nyapau"
  },
  {
    "id": "6210032013",
    "district_id": "621003",
    "label": "Taja Urap",
    "value": "Taja Urap"
  },
  {
    "id": "6210032014",
    "district_id": "621003",
    "label": "Teluk Lawah",
    "value": "Teluk Lawah"
  },
  {
    "id": "6210032015",
    "district_id": "621003",
    "label": "Karason Raya",
    "value": "Karason Raya"
  },
  {
    "id": "6210032016",
    "district_id": "621003",
    "label": "Rangan Mihing",
    "value": "Rangan Mihing"
  },
  {
    "id": "6210041001",
    "district_id": "621004",
    "label": "Tumbang Miri",
    "value": "Tumbang Miri"
  },
  {
    "id": "6210042002",
    "district_id": "621004",
    "label": "Batu Tangkui",
    "value": "Batu Tangkui"
  },
  {
    "id": "6210042005",
    "district_id": "621004",
    "label": "Penda Rangas",
    "value": "Penda Rangas"
  },
  {
    "id": "6210042006",
    "district_id": "621004",
    "label": "Tumbang Sian",
    "value": "Tumbang Sian"
  },
  {
    "id": "6210042008",
    "district_id": "621004",
    "label": "Tumbang Pasangon",
    "value": "Tumbang Pasangon"
  },
  {
    "id": "6210042012",
    "district_id": "621004",
    "label": "Tumbang Korik",
    "value": "Tumbang Korik"
  },
  {
    "id": "6210042014",
    "district_id": "621004",
    "label": "Tumbang Ponyoi",
    "value": "Tumbang Ponyoi"
  },
  {
    "id": "6210042019",
    "district_id": "621004",
    "label": "Dandang",
    "value": "Dandang"
  },
  {
    "id": "6210042020",
    "district_id": "621004",
    "label": "Tumbang Hamputung",
    "value": "Tumbang Hamputung"
  },
  {
    "id": "6210042021",
    "district_id": "621004",
    "label": "Tumbang Tajungan",
    "value": "Tumbang Tajungan"
  },
  {
    "id": "6210042028",
    "district_id": "621004",
    "label": "Tumbang Takaoi",
    "value": "Tumbang Takaoi"
  },
  {
    "id": "6210042029",
    "district_id": "621004",
    "label": "Teluk Kanduri",
    "value": "Teluk Kanduri"
  },
  {
    "id": "6210051021",
    "district_id": "621005",
    "label": "Jakatan Raya",
    "value": "Jakatan Raya"
  },
  {
    "id": "6210052001",
    "district_id": "621005",
    "label": "Tumbang Jutuh",
    "value": "Tumbang Jutuh"
  },
  {
    "id": "6210052002",
    "district_id": "621005",
    "label": "Tumbang Baringei",
    "value": "Tumbang Baringei"
  },
  {
    "id": "6210052009",
    "district_id": "621005",
    "label": "Tumbang Malahoi",
    "value": "Tumbang Malahoi"
  },
  {
    "id": "6210052014",
    "district_id": "621005",
    "label": "Tumbang Bunut",
    "value": "Tumbang Bunut"
  },
  {
    "id": "6210052015",
    "district_id": "621005",
    "label": "Tumbang Kajuei",
    "value": "Tumbang Kajuei"
  },
  {
    "id": "6210052016",
    "district_id": "621005",
    "label": "Luwuk Kantor",
    "value": "Luwuk Kantor"
  },
  {
    "id": "6210052017",
    "district_id": "621005",
    "label": "Luwuk Langkuas",
    "value": "Luwuk Langkuas"
  },
  {
    "id": "6210052018",
    "district_id": "621005",
    "label": "Talangkah",
    "value": "Talangkah"
  },
  {
    "id": "6210052019",
    "district_id": "621005",
    "label": "Parempei",
    "value": "Parempei"
  },
  {
    "id": "6210052020",
    "district_id": "621005",
    "label": "Linau",
    "value": "Linau"
  },
  {
    "id": "6210052022",
    "district_id": "621005",
    "label": "Bereng Baru",
    "value": "Bereng Baru"
  },
  {
    "id": "6210052023",
    "district_id": "621005",
    "label": "Bereng Malaka",
    "value": "Bereng Malaka"
  },
  {
    "id": "6210052027",
    "district_id": "621005",
    "label": "Karya Bhakti",
    "value": "Karya Bhakti"
  },
  {
    "id": "6210061001",
    "district_id": "621006",
    "label": "Tumbang Talaken",
    "value": "Tumbang Talaken"
  },
  {
    "id": "6210062002",
    "district_id": "621006",
    "label": "Tumbang Sepan",
    "value": "Tumbang Sepan"
  },
  {
    "id": "6210062003",
    "district_id": "621006",
    "label": "Tangki Dahuyan",
    "value": "Tangki Dahuyan"
  },
  {
    "id": "6210062004",
    "district_id": "621006",
    "label": "Bereng Balawan",
    "value": "Bereng Balawan"
  },
  {
    "id": "6210062005",
    "district_id": "621006",
    "label": "Bereng Jun",
    "value": "Bereng Jun"
  },
  {
    "id": "6210062006",
    "district_id": "621006",
    "label": "Takaras",
    "value": "Takaras"
  },
  {
    "id": "6210062012",
    "district_id": "621006",
    "label": "Belawan Mulya",
    "value": "Belawan Mulya"
  },
  {
    "id": "6210062013",
    "district_id": "621006",
    "label": "Taringen",
    "value": "Taringen"
  },
  {
    "id": "6210062014",
    "district_id": "621006",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "6210062015",
    "district_id": "621006",
    "label": "Fajar Harapan",
    "value": "Fajar Harapan"
  },
  {
    "id": "6210062017",
    "district_id": "621006",
    "label": "Guhung",
    "value": "Guhung"
  },
  {
    "id": "6210062018",
    "district_id": "621006",
    "label": "Tumbang Jalemu",
    "value": "Tumbang Jalemu"
  },
  {
    "id": "6210071005",
    "district_id": "621007",
    "label": "Kampuri",
    "value": "Kampuri"
  },
  {
    "id": "6210072001",
    "district_id": "621007",
    "label": "Tuyun",
    "value": "Tuyun"
  },
  {
    "id": "6210072002",
    "district_id": "621007",
    "label": "Tumbang Empas",
    "value": "Tumbang Empas"
  },
  {
    "id": "6210072003",
    "district_id": "621007",
    "label": "Rangan Tate",
    "value": "Rangan Tate"
  },
  {
    "id": "6210072004",
    "district_id": "621007",
    "label": "Dahian Tambuk",
    "value": "Dahian Tambuk"
  },
  {
    "id": "6210072006",
    "district_id": "621007",
    "label": "Tumbang Danau",
    "value": "Tumbang Danau"
  },
  {
    "id": "6210081008",
    "district_id": "621008",
    "label": "Tumbang Marikoi",
    "value": "Tumbang Marikoi"
  },
  {
    "id": "6210082001",
    "district_id": "621008",
    "label": "Lawang Kanji",
    "value": "Lawang Kanji"
  },
  {
    "id": "6210082002",
    "district_id": "621008",
    "label": "Tumbang Mahuroi",
    "value": "Tumbang Mahuroi"
  },
  {
    "id": "6210082003",
    "district_id": "621008",
    "label": "Karetau Rambangun",
    "value": "Karetau Rambangun"
  },
  {
    "id": "6210082004",
    "district_id": "621008",
    "label": "Karetau Sarian",
    "value": "Karetau Sarian"
  },
  {
    "id": "6210082005",
    "district_id": "621008",
    "label": "Tumbang Posu",
    "value": "Tumbang Posu"
  },
  {
    "id": "6210082006",
    "district_id": "621008",
    "label": "Tumbang Maraya",
    "value": "Tumbang Maraya"
  },
  {
    "id": "6210082007",
    "district_id": "621008",
    "label": "Tumbang Anoi",
    "value": "Tumbang Anoi"
  },
  {
    "id": "6210091003",
    "district_id": "621009",
    "label": "Tumbang Napoi",
    "value": "Tumbang Napoi"
  },
  {
    "id": "6210092001",
    "district_id": "621009",
    "label": "Tumbang Siruk",
    "value": "Tumbang Siruk"
  },
  {
    "id": "6210092002",
    "district_id": "621009",
    "label": "Mangkuhung",
    "value": "Mangkuhung"
  },
  {
    "id": "6210092004",
    "district_id": "621009",
    "label": "Tumbang Masukih",
    "value": "Tumbang Masukih"
  },
  {
    "id": "6210092005",
    "district_id": "621009",
    "label": "Rangan Hiran",
    "value": "Rangan Hiran"
  },
  {
    "id": "6210092006",
    "district_id": "621009",
    "label": "Harowo",
    "value": "Harowo"
  },
  {
    "id": "6210092007",
    "district_id": "621009",
    "label": "Tumbang Manyoi",
    "value": "Tumbang Manyoi"
  },
  {
    "id": "6210092008",
    "district_id": "621009",
    "label": "Tumbang Lapan",
    "value": "Tumbang Lapan"
  },
  {
    "id": "6210092009",
    "district_id": "621009",
    "label": "Buntoi",
    "value": "Buntoi"
  },
  {
    "id": "6210092010",
    "district_id": "621009",
    "label": "Tumbang Koroi",
    "value": "Tumbang Koroi"
  },
  {
    "id": "6210092011",
    "district_id": "621009",
    "label": "Tumbang Hatung",
    "value": "Tumbang Hatung"
  },
  {
    "id": "6210101004",
    "district_id": "621010",
    "label": "Tumbang Rahuyan",
    "value": "Tumbang Rahuyan"
  },
  {
    "id": "6210102001",
    "district_id": "621010",
    "label": "Jangkit",
    "value": "Jangkit"
  },
  {
    "id": "6210102002",
    "district_id": "621010",
    "label": "Tumbang Lapan",
    "value": "Tumbang Lapan"
  },
  {
    "id": "6210102003",
    "district_id": "621010",
    "label": "Batu Puter",
    "value": "Batu Puter"
  },
  {
    "id": "6210102005",
    "district_id": "621010",
    "label": "Sei Antai",
    "value": "Sei Antai"
  },
  {
    "id": "6210102006",
    "district_id": "621010",
    "label": "Hantapang",
    "value": "Hantapang"
  },
  {
    "id": "6210102007",
    "district_id": "621010",
    "label": "Sangal",
    "value": "Sangal"
  },
  {
    "id": "6210102008",
    "district_id": "621010",
    "label": "Tumbang Tuwe",
    "value": "Tumbang Tuwe"
  },
  {
    "id": "6210102012",
    "district_id": "621010",
    "label": "Tumbang Mujai",
    "value": "Tumbang Mujai"
  },
  {
    "id": "6210111005",
    "district_id": "621011",
    "label": "Tehang",
    "value": "Tehang"
  },
  {
    "id": "6210112002",
    "district_id": "621011",
    "label": "Tumbang Samui",
    "value": "Tumbang Samui"
  },
  {
    "id": "6210112003",
    "district_id": "621011",
    "label": "Tumbang Oroi",
    "value": "Tumbang Oroi"
  },
  {
    "id": "6210112004",
    "district_id": "621011",
    "label": "Luwuk Tukau",
    "value": "Luwuk Tukau"
  },
  {
    "id": "6210112006",
    "district_id": "621011",
    "label": "Putat Durei",
    "value": "Putat Durei"
  },
  {
    "id": "6210112007",
    "district_id": "621011",
    "label": "Tumbang Mantuhe",
    "value": "Tumbang Mantuhe"
  },
  {
    "id": "6210121006",
    "district_id": "621012",
    "label": "Rabambang",
    "value": "Rabambang"
  },
  {
    "id": "6210122001",
    "district_id": "621012",
    "label": "Hujung Pata",
    "value": "Hujung Pata"
  },
  {
    "id": "6210122002",
    "district_id": "621012",
    "label": "Tumbang Jalemu Kajuei",
    "value": "Tumbang Jalemu Kajuei"
  },
  {
    "id": "6210122003",
    "district_id": "621012",
    "label": "Jalemu Raya",
    "value": "Jalemu Raya"
  },
  {
    "id": "6210122004",
    "district_id": "621012",
    "label": "Jalemu Masulan",
    "value": "Jalemu Masulan"
  },
  {
    "id": "6210122005",
    "district_id": "621012",
    "label": "Mangkawuk",
    "value": "Mangkawuk"
  },
  {
    "id": "6210122007",
    "district_id": "621012",
    "label": "Tajah Antang Raya",
    "value": "Tajah Antang Raya"
  },
  {
    "id": "6210122008",
    "district_id": "621012",
    "label": "Tumbang Kuayan",
    "value": "Tumbang Kuayan"
  },
  {
    "id": "6210122009",
    "district_id": "621012",
    "label": "Tumbang Langgah",
    "value": "Tumbang Langgah"
  },
  {
    "id": "6210122010",
    "district_id": "621012",
    "label": "Tusang Raya",
    "value": "Tusang Raya"
  },
  {
    "id": "6210122011",
    "district_id": "621012",
    "label": "Tumbang Bahanei",
    "value": "Tumbang Bahanei"
  },
  {
    "id": "6211012001",
    "district_id": "621101",
    "label": "Dandang",
    "value": "Dandang"
  },
  {
    "id": "6211012002",
    "district_id": "621101",
    "label": "Talio",
    "value": "Talio"
  },
  {
    "id": "6211012003",
    "district_id": "621101",
    "label": "Pangkoh Hilir",
    "value": "Pangkoh Hilir"
  },
  {
    "id": "6211012004",
    "district_id": "621101",
    "label": "Pangkoh Hulu",
    "value": "Pangkoh Hulu"
  },
  {
    "id": "6211012005",
    "district_id": "621101",
    "label": "Kantan Muara",
    "value": "Kantan Muara"
  },
  {
    "id": "6211012006",
    "district_id": "621101",
    "label": "Talio Muara",
    "value": "Talio Muara"
  },
  {
    "id": "6211012007",
    "district_id": "621101",
    "label": "Talio Hulu",
    "value": "Talio Hulu"
  },
  {
    "id": "6211012008",
    "district_id": "621101",
    "label": "Pangkoh Sari",
    "value": "Pangkoh Sari"
  },
  {
    "id": "6211012009",
    "district_id": "621101",
    "label": "Sanggang",
    "value": "Sanggang"
  },
  {
    "id": "6211012010",
    "district_id": "621101",
    "label": "Pantik",
    "value": "Pantik"
  },
  {
    "id": "6211012011",
    "district_id": "621101",
    "label": "Belanti Siam",
    "value": "Belanti Siam"
  },
  {
    "id": "6211012012",
    "district_id": "621101",
    "label": "Gadabung",
    "value": "Gadabung"
  },
  {
    "id": "6211012013",
    "district_id": "621101",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "6211012014",
    "district_id": "621101",
    "label": "Kantan Dalam",
    "value": "Kantan Dalam"
  },
  {
    "id": "6211012015",
    "district_id": "621101",
    "label": "Kantan Atas",
    "value": "Kantan Atas"
  },
  {
    "id": "6211012016",
    "district_id": "621101",
    "label": "Karya Bersama",
    "value": "Karya Bersama"
  },
  {
    "id": "6211021018",
    "district_id": "621102",
    "label": "Bahaur Basantan",
    "value": "Bahaur Basantan"
  },
  {
    "id": "6211022001",
    "district_id": "621102",
    "label": "Cemantan",
    "value": "Cemantan"
  },
  {
    "id": "6211022002",
    "district_id": "621102",
    "label": "Papuyu II Sei. Barunai",
    "value": "Papuyu II Sei. Barunai"
  },
  {
    "id": "6211022003",
    "district_id": "621102",
    "label": "Kiapak",
    "value": "Kiapak"
  },
  {
    "id": "6211022005",
    "district_id": "621102",
    "label": "Papuyu I Sei. Pasanan",
    "value": "Papuyu I Sei. Pasanan"
  },
  {
    "id": "6211022006",
    "district_id": "621102",
    "label": "Sei. Rungun",
    "value": "Sei. Rungun"
  },
  {
    "id": "6211022007",
    "district_id": "621102",
    "label": "Bahaur Hilir",
    "value": "Bahaur Hilir"
  },
  {
    "id": "6211022008",
    "district_id": "621102",
    "label": "Bahaur Tengah",
    "value": "Bahaur Tengah"
  },
  {
    "id": "6211022009",
    "district_id": "621102",
    "label": "Bahaur Hulu",
    "value": "Bahaur Hulu"
  },
  {
    "id": "6211022014",
    "district_id": "621102",
    "label": "Papuyu III Sei. Pudak",
    "value": "Papuyu III Sei. Pudak"
  },
  {
    "id": "6211022015",
    "district_id": "621102",
    "label": "Tanjung Perawan",
    "value": "Tanjung Perawan"
  },
  {
    "id": "6211022016",
    "district_id": "621102",
    "label": "Bahaur Hulu Permai",
    "value": "Bahaur Hulu Permai"
  },
  {
    "id": "6211022017",
    "district_id": "621102",
    "label": "Bahaur Batu Raya",
    "value": "Bahaur Batu Raya"
  },
  {
    "id": "6211032001",
    "district_id": "621103",
    "label": "Tanjung Sangalang",
    "value": "Tanjung Sangalang"
  },
  {
    "id": "6211032002",
    "district_id": "621103",
    "label": "Penda Barania",
    "value": "Penda Barania"
  },
  {
    "id": "6211032003",
    "district_id": "621103",
    "label": "Bukit Rawi",
    "value": "Bukit Rawi"
  },
  {
    "id": "6211032004",
    "district_id": "621103",
    "label": "Tuwung",
    "value": "Tuwung"
  },
  {
    "id": "6211032005",
    "district_id": "621103",
    "label": "Sigi",
    "value": "Sigi"
  },
  {
    "id": "6211032006",
    "district_id": "621103",
    "label": "Petuk Liti",
    "value": "Petuk Liti"
  },
  {
    "id": "6211032007",
    "district_id": "621103",
    "label": "Bukit Liti",
    "value": "Bukit Liti"
  },
  {
    "id": "6211032008",
    "district_id": "621103",
    "label": "Bahu Palawa",
    "value": "Bahu Palawa"
  },
  {
    "id": "6211032009",
    "district_id": "621103",
    "label": "Pamarunan",
    "value": "Pamarunan"
  },
  {
    "id": "6211032010",
    "district_id": "621103",
    "label": "Balukon",
    "value": "Balukon"
  },
  {
    "id": "6211032011",
    "district_id": "621103",
    "label": "Bukit Bamba",
    "value": "Bukit Bamba"
  },
  {
    "id": "6211032012",
    "district_id": "621103",
    "label": "Tahawa",
    "value": "Tahawa"
  },
  {
    "id": "6211032013",
    "district_id": "621103",
    "label": "Parahangan",
    "value": "Parahangan"
  },
  {
    "id": "6211032014",
    "district_id": "621103",
    "label": "Bereng Rambang",
    "value": "Bereng Rambang"
  },
  {
    "id": "6211042001",
    "district_id": "621104",
    "label": "Manen Paduran",
    "value": "Manen Paduran"
  },
  {
    "id": "6211042002",
    "district_id": "621104",
    "label": "Manen Kaleka",
    "value": "Manen Kaleka"
  },
  {
    "id": "6211042003",
    "district_id": "621104",
    "label": "Lawang Uru",
    "value": "Lawang Uru"
  },
  {
    "id": "6211042004",
    "district_id": "621104",
    "label": "Hurung",
    "value": "Hurung"
  },
  {
    "id": "6211042005",
    "district_id": "621104",
    "label": "Hanua",
    "value": "Hanua"
  },
  {
    "id": "6211042006",
    "district_id": "621104",
    "label": "Ramang",
    "value": "Ramang"
  },
  {
    "id": "6211042007",
    "district_id": "621104",
    "label": "Tambak",
    "value": "Tambak"
  },
  {
    "id": "6211042008",
    "district_id": "621104",
    "label": "Pahawan",
    "value": "Pahawan"
  },
  {
    "id": "6211042009",
    "district_id": "621104",
    "label": "Goha",
    "value": "Goha"
  },
  {
    "id": "6211042010",
    "district_id": "621104",
    "label": "Bawan",
    "value": "Bawan"
  },
  {
    "id": "6211042011",
    "district_id": "621104",
    "label": "Tumbang Tarusan",
    "value": "Tumbang Tarusan"
  },
  {
    "id": "6211042012",
    "district_id": "621104",
    "label": "Pandawei",
    "value": "Pandawei"
  },
  {
    "id": "6211042013",
    "district_id": "621104",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "6211042014",
    "district_id": "621104",
    "label": "Tangkahen",
    "value": "Tangkahen"
  },
  {
    "id": "6211042015",
    "district_id": "621104",
    "label": "Kasali Baru",
    "value": "Kasali Baru"
  },
  {
    "id": "6211051005",
    "district_id": "621105",
    "label": "Kahayan Hilir",
    "value": "Kahayan Hilir"
  },
  {
    "id": "6211051015",
    "district_id": "621105",
    "label": "Kalawa",
    "value": "Kalawa"
  },
  {
    "id": "6211051016",
    "district_id": "621105",
    "label": "Bereng",
    "value": "Bereng"
  },
  {
    "id": "6211052001",
    "district_id": "621105",
    "label": "Buntoi",
    "value": "Buntoi"
  },
  {
    "id": "6211052002",
    "district_id": "621105",
    "label": "Mintin",
    "value": "Mintin"
  },
  {
    "id": "6211052003",
    "district_id": "621105",
    "label": "Mantaren I",
    "value": "Mantaren I"
  },
  {
    "id": "6211052004",
    "district_id": "621105",
    "label": "Anjir Pulang Pisau",
    "value": "Anjir Pulang Pisau"
  },
  {
    "id": "6211052006",
    "district_id": "621105",
    "label": "Gohong",
    "value": "Gohong"
  },
  {
    "id": "6211052014",
    "district_id": "621105",
    "label": "Mantaren II",
    "value": "Mantaren II"
  },
  {
    "id": "6211052017",
    "district_id": "621105",
    "label": "Hanjak Maju",
    "value": "Hanjak Maju"
  },
  {
    "id": "6211062001",
    "district_id": "621106",
    "label": "Tahai Jaya",
    "value": "Tahai Jaya"
  },
  {
    "id": "6211062002",
    "district_id": "621106",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "6211062003",
    "district_id": "621106",
    "label": "Wono Agung",
    "value": "Wono Agung"
  },
  {
    "id": "6211062004",
    "district_id": "621106",
    "label": "Kanamit Barat",
    "value": "Kanamit Barat"
  },
  {
    "id": "6211062005",
    "district_id": "621106",
    "label": "Sei Baru Tewu",
    "value": "Sei Baru Tewu"
  },
  {
    "id": "6211062006",
    "district_id": "621106",
    "label": "Kanamit",
    "value": "Kanamit"
  },
  {
    "id": "6211062007",
    "district_id": "621106",
    "label": "Garantung",
    "value": "Garantung"
  },
  {
    "id": "6211062008",
    "district_id": "621106",
    "label": "Gandang",
    "value": "Gandang"
  },
  {
    "id": "6211062009",
    "district_id": "621106",
    "label": "Badirih",
    "value": "Badirih"
  },
  {
    "id": "6211062010",
    "district_id": "621106",
    "label": "Tahai Baru",
    "value": "Tahai Baru"
  },
  {
    "id": "6211062011",
    "district_id": "621106",
    "label": "Maliku Baru",
    "value": "Maliku Baru"
  },
  {
    "id": "6211062012",
    "district_id": "621106",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "6211062013",
    "district_id": "621106",
    "label": "Kanamit Jaya",
    "value": "Kanamit Jaya"
  },
  {
    "id": "6211062014",
    "district_id": "621106",
    "label": "Gandang Barat",
    "value": "Gandang Barat"
  },
  {
    "id": "6211062015",
    "district_id": "621106",
    "label": "Maliku Mulia",
    "value": "Maliku Mulia"
  },
  {
    "id": "6211072001",
    "district_id": "621107",
    "label": "Garung",
    "value": "Garung"
  },
  {
    "id": "6211072002",
    "district_id": "621107",
    "label": "Henda",
    "value": "Henda"
  },
  {
    "id": "6211072003",
    "district_id": "621107",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "6211072004",
    "district_id": "621107",
    "label": "Sakakajang",
    "value": "Sakakajang"
  },
  {
    "id": "6211072005",
    "district_id": "621107",
    "label": "Jabiren",
    "value": "Jabiren"
  },
  {
    "id": "6211072006",
    "district_id": "621107",
    "label": "Pilang",
    "value": "Pilang"
  },
  {
    "id": "6211072007",
    "district_id": "621107",
    "label": "Tumbang Nusa",
    "value": "Tumbang Nusa"
  },
  {
    "id": "6211072008",
    "district_id": "621107",
    "label": "Tanjung Taruna",
    "value": "Tanjung Taruna"
  },
  {
    "id": "6211082001",
    "district_id": "621108",
    "label": "Paduran Sebangau",
    "value": "Paduran Sebangau"
  },
  {
    "id": "6211082002",
    "district_id": "621108",
    "label": "Paduran Mulya",
    "value": "Paduran Mulya"
  },
  {
    "id": "6211082003",
    "district_id": "621108",
    "label": "Sebangau Mulya",
    "value": "Sebangau Mulya"
  },
  {
    "id": "6211082004",
    "district_id": "621108",
    "label": "Sebangau Permai",
    "value": "Sebangau Permai"
  },
  {
    "id": "6211082005",
    "district_id": "621108",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6211082006",
    "district_id": "621108",
    "label": "Sebangau Jaya",
    "value": "Sebangau Jaya"
  },
  {
    "id": "6211082007",
    "district_id": "621108",
    "label": "Sei Hambawang",
    "value": "Sei Hambawang"
  },
  {
    "id": "6211082008",
    "district_id": "621108",
    "label": "Sei Bakau",
    "value": "Sei Bakau"
  },
  {
    "id": "6212011012",
    "district_id": "621201",
    "label": "Puruk Cahu",
    "value": "Puruk Cahu"
  },
  {
    "id": "6212011013",
    "district_id": "621201",
    "label": "Beriwit",
    "value": "Beriwit"
  },
  {
    "id": "6212012001",
    "district_id": "621201",
    "label": "Dirung",
    "value": "Dirung"
  },
  {
    "id": "6212012002",
    "district_id": "621201",
    "label": "Malasan",
    "value": "Malasan"
  },
  {
    "id": "6212012003",
    "district_id": "621201",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "6212012004",
    "district_id": "621201",
    "label": "Mangkahui",
    "value": "Mangkahui"
  },
  {
    "id": "6212012005",
    "district_id": "621201",
    "label": "Panu'ut",
    "value": "Panu'ut"
  },
  {
    "id": "6212012006",
    "district_id": "621201",
    "label": "Muara Untu",
    "value": "Muara Untu"
  },
  {
    "id": "6212012007",
    "district_id": "621201",
    "label": "Muara Ja'an",
    "value": "Muara Ja'an"
  },
  {
    "id": "6212012008",
    "district_id": "621201",
    "label": "Bahitom",
    "value": "Bahitom"
  },
  {
    "id": "6212012009",
    "district_id": "621201",
    "label": "Danau Usung",
    "value": "Danau Usung"
  },
  {
    "id": "6212012010",
    "district_id": "621201",
    "label": "Juking Pajang",
    "value": "Juking Pajang"
  },
  {
    "id": "6212012011",
    "district_id": "621201",
    "label": "Muara Sumpoi",
    "value": "Muara Sumpoi"
  },
  {
    "id": "6212012014",
    "district_id": "621201",
    "label": "Muara Bumban",
    "value": "Muara Bumban"
  },
  {
    "id": "6212012015",
    "district_id": "621201",
    "label": "Penyang",
    "value": "Penyang"
  },
  {
    "id": "6212021014",
    "district_id": "621202",
    "label": "Saripoi",
    "value": "Saripoi"
  },
  {
    "id": "6212022007",
    "district_id": "621202",
    "label": "Sungai Lunuk",
    "value": "Sungai Lunuk"
  },
  {
    "id": "6212022008",
    "district_id": "621202",
    "label": "Konut",
    "value": "Konut"
  },
  {
    "id": "6212022009",
    "district_id": "621202",
    "label": "Belawan",
    "value": "Belawan"
  },
  {
    "id": "6212022010",
    "district_id": "621202",
    "label": "Mangkolisoi",
    "value": "Mangkolisoi"
  },
  {
    "id": "6212022011",
    "district_id": "621202",
    "label": "Kalang Kaluh",
    "value": "Kalang Kaluh"
  },
  {
    "id": "6212022012",
    "district_id": "621202",
    "label": "Mantiat Pari",
    "value": "Mantiat Pari"
  },
  {
    "id": "6212022013",
    "district_id": "621202",
    "label": "Olung Ulu",
    "value": "Olung Ulu"
  },
  {
    "id": "6212022015",
    "district_id": "621202",
    "label": "Puruk Batu",
    "value": "Puruk Batu"
  },
  {
    "id": "6212022016",
    "district_id": "621202",
    "label": "Olung Siron",
    "value": "Olung Siron"
  },
  {
    "id": "6212022017",
    "district_id": "621202",
    "label": "Tino Talih",
    "value": "Tino Talih"
  },
  {
    "id": "6212022018",
    "district_id": "621202",
    "label": "Olung Nango",
    "value": "Olung Nango"
  },
  {
    "id": "6212022019",
    "district_id": "621202",
    "label": "Mahanyan",
    "value": "Mahanyan"
  },
  {
    "id": "6212022020",
    "district_id": "621202",
    "label": "Dirung Bakung",
    "value": "Dirung Bakung"
  },
  {
    "id": "6212022021",
    "district_id": "621202",
    "label": "Tabulang",
    "value": "Tabulang"
  },
  {
    "id": "6212022022",
    "district_id": "621202",
    "label": "Cangkang",
    "value": "Cangkang"
  },
  {
    "id": "6212022023",
    "district_id": "621202",
    "label": "Olung Dojou",
    "value": "Olung Dojou"
  },
  {
    "id": "6212022024",
    "district_id": "621202",
    "label": "Doan Arung",
    "value": "Doan Arung"
  },
  {
    "id": "6212022025",
    "district_id": "621202",
    "label": "Muwun",
    "value": "Muwun"
  },
  {
    "id": "6212022026",
    "district_id": "621202",
    "label": "Nono Kliwon",
    "value": "Nono Kliwon"
  },
  {
    "id": "6212022027",
    "district_id": "621202",
    "label": "Kolam",
    "value": "Kolam"
  },
  {
    "id": "6212022028",
    "district_id": "621202",
    "label": "Saruhung",
    "value": "Saruhung"
  },
  {
    "id": "6212022029",
    "district_id": "621202",
    "label": "Olung Soloi",
    "value": "Olung Soloi"
  },
  {
    "id": "6212022030",
    "district_id": "621202",
    "label": "Tokung",
    "value": "Tokung"
  },
  {
    "id": "6212022031",
    "district_id": "621202",
    "label": "Olung Balo",
    "value": "Olung Balo"
  },
  {
    "id": "6212022032",
    "district_id": "621202",
    "label": "Karali",
    "value": "Karali"
  },
  {
    "id": "6212022033",
    "district_id": "621202",
    "label": "Osom Tompok",
    "value": "Osom Tompok"
  },
  {
    "id": "6212031003",
    "district_id": "621203",
    "label": "Muara Laung I",
    "value": "Muara Laung I"
  },
  {
    "id": "6212031006",
    "district_id": "621203",
    "label": "Muara Tuhup",
    "value": "Muara Tuhup"
  },
  {
    "id": "6212031031",
    "district_id": "621203",
    "label": "Batu Bua I",
    "value": "Batu Bua I"
  },
  {
    "id": "6212032001",
    "district_id": "621203",
    "label": "Batu Tuhup",
    "value": "Batu Tuhup"
  },
  {
    "id": "6212032002",
    "district_id": "621203",
    "label": "Tumbang Bahan",
    "value": "Tumbang Bahan"
  },
  {
    "id": "6212032004",
    "district_id": "621203",
    "label": "Muara Laung II",
    "value": "Muara Laung II"
  },
  {
    "id": "6212032005",
    "district_id": "621203",
    "label": "Beras Balange",
    "value": "Beras Balange"
  },
  {
    "id": "6212032010",
    "district_id": "621203",
    "label": "Pelaci",
    "value": "Pelaci"
  },
  {
    "id": "6212032011",
    "district_id": "621203",
    "label": "Biha",
    "value": "Biha"
  },
  {
    "id": "6212032012",
    "district_id": "621203",
    "label": "Dirung Pundu",
    "value": "Dirung Pundu"
  },
  {
    "id": "6212032013",
    "district_id": "621203",
    "label": "Dirung Pinang",
    "value": "Dirung Pinang"
  },
  {
    "id": "6212032014",
    "district_id": "621203",
    "label": "Muara Tupuh",
    "value": "Muara Tupuh"
  },
  {
    "id": "6212032015",
    "district_id": "621203",
    "label": "Narui",
    "value": "Narui"
  },
  {
    "id": "6212032016",
    "district_id": "621203",
    "label": "Tumbang Bana",
    "value": "Tumbang Bana"
  },
  {
    "id": "6212032017",
    "district_id": "621203",
    "label": "Lakutan",
    "value": "Lakutan"
  },
  {
    "id": "6212032018",
    "district_id": "621203",
    "label": "Tawai Haui",
    "value": "Tawai Haui"
  },
  {
    "id": "6212032026",
    "district_id": "621203",
    "label": "Penda Siron",
    "value": "Penda Siron"
  },
  {
    "id": "6212032027",
    "district_id": "621203",
    "label": "Muara Maruwei I",
    "value": "Muara Maruwei I"
  },
  {
    "id": "6212032028",
    "district_id": "621203",
    "label": "Muara Maruwei II",
    "value": "Muara Maruwei II"
  },
  {
    "id": "6212032029",
    "district_id": "621203",
    "label": "Beralang",
    "value": "Beralang"
  },
  {
    "id": "6212032030",
    "district_id": "621203",
    "label": "Kalang Dohong",
    "value": "Kalang Dohong"
  },
  {
    "id": "6212032032",
    "district_id": "621203",
    "label": "Batu Bua II",
    "value": "Batu Bua II"
  },
  {
    "id": "6212032033",
    "district_id": "621203",
    "label": "Tahujan Laung",
    "value": "Tahujan Laung"
  },
  {
    "id": "6212032034",
    "district_id": "621203",
    "label": "Tumbang Bondang",
    "value": "Tumbang Bondang"
  },
  {
    "id": "6212032035",
    "district_id": "621203",
    "label": "Tumbang Tonduk",
    "value": "Tumbang Tonduk"
  },
  {
    "id": "6212032037",
    "district_id": "621203",
    "label": "Batu Karang",
    "value": "Batu Karang"
  },
  {
    "id": "6212041004",
    "district_id": "621204",
    "label": "Muara Bakanon",
    "value": "Muara Bakanon"
  },
  {
    "id": "6212041006",
    "district_id": "621204",
    "label": "Tumbang Lahung",
    "value": "Tumbang Lahung"
  },
  {
    "id": "6212042001",
    "district_id": "621204",
    "label": "Sungai Gula",
    "value": "Sungai Gula"
  },
  {
    "id": "6212042002",
    "district_id": "621204",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  {
    "id": "6212042003",
    "district_id": "621204",
    "label": "Tumbang Salio",
    "value": "Tumbang Salio"
  },
  {
    "id": "6212042005",
    "district_id": "621204",
    "label": "Purnama",
    "value": "Purnama"
  },
  {
    "id": "6212042007",
    "district_id": "621204",
    "label": "Sungai Lobang",
    "value": "Sungai Lobang"
  },
  {
    "id": "6212042008",
    "district_id": "621204",
    "label": "Pantai Laga",
    "value": "Pantai Laga"
  },
  {
    "id": "6212042009",
    "district_id": "621204",
    "label": "Baratu",
    "value": "Baratu"
  },
  {
    "id": "6212042010",
    "district_id": "621204",
    "label": "Juking Sopan",
    "value": "Juking Sopan"
  },
  {
    "id": "6212042011",
    "district_id": "621204",
    "label": "Muara Babuat",
    "value": "Muara Babuat"
  },
  {
    "id": "6212042018",
    "district_id": "621204",
    "label": "Sungai Bakanon",
    "value": "Sungai Bakanon"
  },
  {
    "id": "6212051004",
    "district_id": "621205",
    "label": "Tumbang Kunyi",
    "value": "Tumbang Kunyi"
  },
  {
    "id": "6212052001",
    "district_id": "621205",
    "label": "Kalapeh Baru",
    "value": "Kalapeh Baru"
  },
  {
    "id": "6212052002",
    "district_id": "621205",
    "label": "Tumbang Masao",
    "value": "Tumbang Masao"
  },
  {
    "id": "6212052003",
    "district_id": "621205",
    "label": "Batu Makap",
    "value": "Batu Makap"
  },
  {
    "id": "6212052005",
    "district_id": "621205",
    "label": "Olong Liko",
    "value": "Olong Liko"
  },
  {
    "id": "6212052006",
    "district_id": "621205",
    "label": "Teluk Jolo",
    "value": "Teluk Jolo"
  },
  {
    "id": "6212052007",
    "district_id": "621205",
    "label": "Laas Baru",
    "value": "Laas Baru"
  },
  {
    "id": "6212052018",
    "district_id": "621205",
    "label": "Tumbang Molut",
    "value": "Tumbang Molut"
  },
  {
    "id": "6212052020",
    "district_id": "621205",
    "label": "Tumbang Tuan",
    "value": "Tumbang Tuan"
  },
  {
    "id": "6212062001",
    "district_id": "621206",
    "label": "Bumban Tuhup",
    "value": "Bumban Tuhup"
  },
  {
    "id": "6212062002",
    "district_id": "621206",
    "label": "Makunjung",
    "value": "Makunjung"
  },
  {
    "id": "6212062003",
    "district_id": "621206",
    "label": "Dirung Sararong",
    "value": "Dirung Sararong"
  },
  {
    "id": "6212062004",
    "district_id": "621206",
    "label": "Kohong",
    "value": "Kohong"
  },
  {
    "id": "6212062005",
    "district_id": "621206",
    "label": "Hingan Tokung",
    "value": "Hingan Tokung"
  },
  {
    "id": "6212062006",
    "district_id": "621206",
    "label": "Liang Nyaling",
    "value": "Liang Nyaling"
  },
  {
    "id": "6212062007",
    "district_id": "621206",
    "label": "Tumbang Bauh",
    "value": "Tumbang Bauh"
  },
  {
    "id": "6212062008",
    "district_id": "621206",
    "label": "Tumbang Masalo",
    "value": "Tumbang Masalo"
  },
  {
    "id": "6212062009",
    "district_id": "621206",
    "label": "Batu Tojah",
    "value": "Batu Tojah"
  },
  {
    "id": "6212062010",
    "district_id": "621206",
    "label": "Tumbang Baloi",
    "value": "Tumbang Baloi"
  },
  {
    "id": "6212062011",
    "district_id": "621206",
    "label": "Cinta Budiman",
    "value": "Cinta Budiman"
  },
  {
    "id": "6212072001",
    "district_id": "621207",
    "label": "Oreng",
    "value": "Oreng"
  },
  {
    "id": "6212072002",
    "district_id": "621207",
    "label": "Olung Muro",
    "value": "Olung Muro"
  },
  {
    "id": "6212072003",
    "district_id": "621207",
    "label": "Olung Hanangan",
    "value": "Olung Hanangan"
  },
  {
    "id": "6212072004",
    "district_id": "621207",
    "label": "Dirung Lingkin",
    "value": "Dirung Lingkin"
  },
  {
    "id": "6212072005",
    "district_id": "621207",
    "label": "Datah Kotou",
    "value": "Datah Kotou"
  },
  {
    "id": "6212072006",
    "district_id": "621207",
    "label": "Tahujan Ontu",
    "value": "Tahujan Ontu"
  },
  {
    "id": "6212072007",
    "district_id": "621207",
    "label": "Puruk Kambang",
    "value": "Puruk Kambang"
  },
  {
    "id": "6212082001",
    "district_id": "621208",
    "label": "Batu Mirau",
    "value": "Batu Mirau"
  },
  {
    "id": "6212082002",
    "district_id": "621208",
    "label": "Tambelum",
    "value": "Tambelum"
  },
  {
    "id": "6212082003",
    "district_id": "621208",
    "label": "Tumbang Bantian",
    "value": "Tumbang Bantian"
  },
  {
    "id": "6212082004",
    "district_id": "621208",
    "label": "Tumbang Saan",
    "value": "Tumbang Saan"
  },
  {
    "id": "6212082005",
    "district_id": "621208",
    "label": "Tumbang Kolon",
    "value": "Tumbang Kolon"
  },
  {
    "id": "6212082006",
    "district_id": "621208",
    "label": "Tumbang Apat",
    "value": "Tumbang Apat"
  },
  {
    "id": "6212092001",
    "district_id": "621209",
    "label": "Tumbang Tohan",
    "value": "Tumbang Tohan"
  },
  {
    "id": "6212092002",
    "district_id": "621209",
    "label": "Tumbang Naan",
    "value": "Tumbang Naan"
  },
  {
    "id": "6212092003",
    "district_id": "621209",
    "label": "Muara Joloi I",
    "value": "Muara Joloi I"
  },
  {
    "id": "6212092004",
    "district_id": "621209",
    "label": "Muara Joloi II",
    "value": "Muara Joloi II"
  },
  {
    "id": "6212092005",
    "district_id": "621209",
    "label": "Parahau",
    "value": "Parahau"
  },
  {
    "id": "6212092006",
    "district_id": "621209",
    "label": "Tumbang Jojang",
    "value": "Tumbang Jojang"
  },
  {
    "id": "6212092007",
    "district_id": "621209",
    "label": "Takajung",
    "value": "Takajung"
  },
  {
    "id": "6212102001",
    "district_id": "621210",
    "label": "Tumbang Tujang",
    "value": "Tumbang Tujang"
  },
  {
    "id": "6212102002",
    "district_id": "621210",
    "label": "Tumbang Olong I",
    "value": "Tumbang Olong I"
  },
  {
    "id": "6212102003",
    "district_id": "621210",
    "label": "Kalasin",
    "value": "Kalasin"
  },
  {
    "id": "6212102004",
    "district_id": "621210",
    "label": "Tumbang Topus",
    "value": "Tumbang Topus"
  },
  {
    "id": "6212102005",
    "district_id": "621210",
    "label": "Tumbang Olong II",
    "value": "Tumbang Olong II"
  },
  {
    "id": "6213011012",
    "district_id": "621301",
    "label": "Tamiang Layang",
    "value": "Tamiang Layang"
  },
  {
    "id": "6213012003",
    "district_id": "621301",
    "label": "Pulau Patai",
    "value": "Pulau Patai"
  },
  {
    "id": "6213012004",
    "district_id": "621301",
    "label": "Sarapat",
    "value": "Sarapat"
  },
  {
    "id": "6213012005",
    "district_id": "621301",
    "label": "Magantis",
    "value": "Magantis"
  },
  {
    "id": "6213012006",
    "district_id": "621301",
    "label": "Jaar",
    "value": "Jaar"
  },
  {
    "id": "6213012007",
    "district_id": "621301",
    "label": "Matabu",
    "value": "Matabu"
  },
  {
    "id": "6213012008",
    "district_id": "621301",
    "label": "Dorong",
    "value": "Dorong"
  },
  {
    "id": "6213012009",
    "district_id": "621301",
    "label": "Didi",
    "value": "Didi"
  },
  {
    "id": "6213012010",
    "district_id": "621301",
    "label": "Karang Langit",
    "value": "Karang Langit"
  },
  {
    "id": "6213012011",
    "district_id": "621301",
    "label": "Haringen",
    "value": "Haringen"
  },
  {
    "id": "6213012018",
    "district_id": "621301",
    "label": "Jaweten",
    "value": "Jaweten"
  },
  {
    "id": "6213012019",
    "district_id": "621301",
    "label": "Matarah",
    "value": "Matarah"
  },
  {
    "id": "6213012020",
    "district_id": "621301",
    "label": "Maragut",
    "value": "Maragut"
  },
  {
    "id": "6213012021",
    "district_id": "621301",
    "label": "Mangkarap",
    "value": "Mangkarap"
  },
  {
    "id": "6213012022",
    "district_id": "621301",
    "label": "Gumpa",
    "value": "Gumpa"
  },
  {
    "id": "6213012023",
    "district_id": "621301",
    "label": "Sumur",
    "value": "Sumur"
  },
  {
    "id": "6213012024",
    "district_id": "621301",
    "label": "Harara",
    "value": "Harara"
  },
  {
    "id": "6213021001",
    "district_id": "621302",
    "label": "Pasar Panas/Taniran",
    "value": "Pasar Panas/Taniran"
  },
  {
    "id": "6213022002",
    "district_id": "621302",
    "label": "Kandris",
    "value": "Kandris"
  },
  {
    "id": "6213022003",
    "district_id": "621302",
    "label": "Banyu Landas",
    "value": "Banyu Landas"
  },
  {
    "id": "6213022004",
    "district_id": "621302",
    "label": "Bagok",
    "value": "Bagok"
  },
  {
    "id": "6213022005",
    "district_id": "621302",
    "label": "Bamban",
    "value": "Bamban"
  },
  {
    "id": "6213022006",
    "district_id": "621302",
    "label": "Tewah Pupuh",
    "value": "Tewah Pupuh"
  },
  {
    "id": "6213022007",
    "district_id": "621302",
    "label": "Gudang Seng",
    "value": "Gudang Seng"
  },
  {
    "id": "6213032001",
    "district_id": "621303",
    "label": "Pulau Padang",
    "value": "Pulau Padang"
  },
  {
    "id": "6213032002",
    "district_id": "621303",
    "label": "Kambitin",
    "value": "Kambitin"
  },
  {
    "id": "6213032003",
    "district_id": "621303",
    "label": "Ramania",
    "value": "Ramania"
  },
  {
    "id": "6213032004",
    "district_id": "621303",
    "label": "Bentot",
    "value": "Bentot"
  },
  {
    "id": "6213032005",
    "district_id": "621303",
    "label": "Ampari Bura",
    "value": "Ampari Bura"
  },
  {
    "id": "6213032006",
    "district_id": "621303",
    "label": "Kotam",
    "value": "Kotam"
  },
  {
    "id": "6213032008",
    "district_id": "621303",
    "label": "Betang Nalong",
    "value": "Betang Nalong"
  },
  {
    "id": "6213032009",
    "district_id": "621303",
    "label": "Mawani",
    "value": "Mawani"
  },
  {
    "id": "6213032010",
    "district_id": "621303",
    "label": "Jango",
    "value": "Jango"
  },
  {
    "id": "6213032011",
    "district_id": "621303",
    "label": "Lalap",
    "value": "Lalap"
  },
  {
    "id": "6213042001",
    "district_id": "621304",
    "label": "Janah Jari",
    "value": "Janah Jari"
  },
  {
    "id": "6213042002",
    "district_id": "621304",
    "label": "Bangkirayen",
    "value": "Bangkirayen"
  },
  {
    "id": "6213042003",
    "district_id": "621304",
    "label": "Hayaping",
    "value": "Hayaping"
  },
  {
    "id": "6213042004",
    "district_id": "621304",
    "label": "Tangkan",
    "value": "Tangkan"
  },
  {
    "id": "6213042005",
    "district_id": "621304",
    "label": "Wungkur Nanakan",
    "value": "Wungkur Nanakan"
  },
  {
    "id": "6213042006",
    "district_id": "621304",
    "label": "Ampari",
    "value": "Ampari"
  },
  {
    "id": "6213042007",
    "district_id": "621304",
    "label": "Apar Batu",
    "value": "Apar Batu"
  },
  {
    "id": "6213042008",
    "district_id": "621304",
    "label": "Biwan",
    "value": "Biwan"
  },
  {
    "id": "6213042009",
    "district_id": "621304",
    "label": "Janah Mansiwui",
    "value": "Janah Mansiwui"
  },
  {
    "id": "6213042010",
    "district_id": "621304",
    "label": "Danau",
    "value": "Danau"
  },
  {
    "id": "6213042011",
    "district_id": "621304",
    "label": "Pianggu",
    "value": "Pianggu"
  },
  {
    "id": "6213051011",
    "district_id": "621305",
    "label": "Ampah Kota",
    "value": "Ampah Kota"
  },
  {
    "id": "6213052008",
    "district_id": "621305",
    "label": "Saing",
    "value": "Saing"
  },
  {
    "id": "6213052009",
    "district_id": "621305",
    "label": "Rodok",
    "value": "Rodok"
  },
  {
    "id": "6213052010",
    "district_id": "621305",
    "label": "Ampah II",
    "value": "Ampah II"
  },
  {
    "id": "6213052012",
    "district_id": "621305",
    "label": "Putai",
    "value": "Putai"
  },
  {
    "id": "6213052018",
    "district_id": "621305",
    "label": "Netampin",
    "value": "Netampin"
  },
  {
    "id": "6213052024",
    "district_id": "621305",
    "label": "Sumber Garunggung",
    "value": "Sumber Garunggung"
  },
  {
    "id": "6213052025",
    "district_id": "621305",
    "label": "Muara Awang",
    "value": "Muara Awang"
  },
  {
    "id": "6213062001",
    "district_id": "621306",
    "label": "Muara Palantau",
    "value": "Muara Palantau"
  },
  {
    "id": "6213062002",
    "district_id": "621306",
    "label": "Ketab",
    "value": "Ketab"
  },
  {
    "id": "6213062003",
    "district_id": "621306",
    "label": "Kupang Bersih",
    "value": "Kupang Bersih"
  },
  {
    "id": "6213062004",
    "district_id": "621306",
    "label": "Tuyau",
    "value": "Tuyau"
  },
  {
    "id": "6213062005",
    "district_id": "621306",
    "label": "Pinang Tunggal",
    "value": "Pinang Tunggal"
  },
  {
    "id": "6213062006",
    "district_id": "621306",
    "label": "Nagaleah",
    "value": "Nagaleah"
  },
  {
    "id": "6213062007",
    "district_id": "621306",
    "label": "Lampeong",
    "value": "Lampeong"
  },
  {
    "id": "6213062008",
    "district_id": "621306",
    "label": "Bararawa",
    "value": "Bararawa"
  },
  {
    "id": "6213062009",
    "district_id": "621306",
    "label": "Bambulung",
    "value": "Bambulung"
  },
  {
    "id": "6213062010",
    "district_id": "621306",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "6213062011",
    "district_id": "621306",
    "label": "Muru Duyung",
    "value": "Muru Duyung"
  },
  {
    "id": "6213062012",
    "district_id": "621306",
    "label": "Tumpung Ulung",
    "value": "Tumpung Ulung"
  },
  {
    "id": "6213062013",
    "district_id": "621306",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "6213072001",
    "district_id": "621307",
    "label": "Juru Banu",
    "value": "Juru Banu"
  },
  {
    "id": "6213072002",
    "district_id": "621307",
    "label": "Telang Baru",
    "value": "Telang Baru"
  },
  {
    "id": "6213072003",
    "district_id": "621307",
    "label": "Tampu Langit",
    "value": "Tampu Langit"
  },
  {
    "id": "6213072004",
    "district_id": "621307",
    "label": "Telang",
    "value": "Telang"
  },
  {
    "id": "6213072005",
    "district_id": "621307",
    "label": "Maipe",
    "value": "Maipe"
  },
  {
    "id": "6213072006",
    "district_id": "621307",
    "label": "Murutuwu",
    "value": "Murutuwu"
  },
  {
    "id": "6213072007",
    "district_id": "621307",
    "label": "Balawa",
    "value": "Balawa"
  },
  {
    "id": "6213072008",
    "district_id": "621307",
    "label": "Kali Napu",
    "value": "Kali Napu"
  },
  {
    "id": "6213072009",
    "district_id": "621307",
    "label": "Siong",
    "value": "Siong"
  },
  {
    "id": "6213082001",
    "district_id": "621308",
    "label": "Puri",
    "value": "Puri"
  },
  {
    "id": "6213082002",
    "district_id": "621308",
    "label": "Lenggang",
    "value": "Lenggang"
  },
  {
    "id": "6213082003",
    "district_id": "621308",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6213082004",
    "district_id": "621308",
    "label": "Unsum",
    "value": "Unsum"
  },
  {
    "id": "6213082005",
    "district_id": "621308",
    "label": "Baruyan",
    "value": "Baruyan"
  },
  {
    "id": "6213082006",
    "district_id": "621308",
    "label": "Turan Amis",
    "value": "Turan Amis"
  },
  {
    "id": "6213082007",
    "district_id": "621308",
    "label": "Malintut",
    "value": "Malintut"
  },
  {
    "id": "6213082008",
    "district_id": "621308",
    "label": "Tangkum",
    "value": "Tangkum"
  },
  {
    "id": "6213082009",
    "district_id": "621308",
    "label": "Sibung",
    "value": "Sibung"
  },
  {
    "id": "6213092001",
    "district_id": "621309",
    "label": "Tampa",
    "value": "Tampa"
  },
  {
    "id": "6213092002",
    "district_id": "621309",
    "label": "Kalamus",
    "value": "Kalamus"
  },
  {
    "id": "6213092003",
    "district_id": "621309",
    "label": "Simpang Bingkuang",
    "value": "Simpang Bingkuang"
  },
  {
    "id": "6213092004",
    "district_id": "621309",
    "label": "Patung",
    "value": "Patung"
  },
  {
    "id": "6213092005",
    "district_id": "621309",
    "label": "Runggu Raya",
    "value": "Runggu Raya"
  },
  {
    "id": "6213092006",
    "district_id": "621309",
    "label": "Bantai Napu",
    "value": "Bantai Napu"
  },
  {
    "id": "6213092007",
    "district_id": "621309",
    "label": "Tarinsing",
    "value": "Tarinsing"
  },
  {
    "id": "6213092008",
    "district_id": "621309",
    "label": "Paku Beto",
    "value": "Paku Beto"
  },
  {
    "id": "6213092009",
    "district_id": "621309",
    "label": "Gandrung",
    "value": "Gandrung"
  },
  {
    "id": "6213092010",
    "district_id": "621309",
    "label": "Pangkan",
    "value": "Pangkan"
  },
  {
    "id": "6213092011",
    "district_id": "621309",
    "label": "Kupang Baru",
    "value": "Kupang Baru"
  },
  {
    "id": "6213092012",
    "district_id": "621309",
    "label": "Luau Jawuk",
    "value": "Luau Jawuk"
  },
  {
    "id": "6213102001",
    "district_id": "621310",
    "label": "Dayu",
    "value": "Dayu"
  },
  {
    "id": "6213102002",
    "district_id": "621310",
    "label": "Wuran",
    "value": "Wuran"
  },
  {
    "id": "6213102003",
    "district_id": "621310",
    "label": "Ipu Mea",
    "value": "Ipu Mea"
  },
  {
    "id": "6213102004",
    "district_id": "621310",
    "label": "Lagan",
    "value": "Lagan"
  },
  {
    "id": "6213102005",
    "district_id": "621310",
    "label": "Putut Tawuluh",
    "value": "Putut Tawuluh"
  },
  {
    "id": "6213102006",
    "district_id": "621310",
    "label": "Simpang Naneng",
    "value": "Simpang Naneng"
  },
  {
    "id": "6213102007",
    "district_id": "621310",
    "label": "Kandris",
    "value": "Kandris"
  },
  {
    "id": "6271011001",
    "district_id": "627101",
    "label": "Pahandut",
    "value": "Pahandut"
  },
  {
    "id": "6271011002",
    "district_id": "627101",
    "label": "Panarung",
    "value": "Panarung"
  },
  {
    "id": "6271011003",
    "district_id": "627101",
    "label": "Langkai",
    "value": "Langkai"
  },
  {
    "id": "6271011004",
    "district_id": "627101",
    "label": "Tumbang Rungan",
    "value": "Tumbang Rungan"
  },
  {
    "id": "6271011005",
    "district_id": "627101",
    "label": "Pahandut Seberang",
    "value": "Pahandut Seberang"
  },
  {
    "id": "6271011006",
    "district_id": "627101",
    "label": "Tanjung Pinang",
    "value": "Tanjung Pinang"
  },
  {
    "id": "6271021001",
    "district_id": "627102",
    "label": "Marang",
    "value": "Marang"
  },
  {
    "id": "6271021002",
    "district_id": "627102",
    "label": "Tumbang Tahai",
    "value": "Tumbang Tahai"
  },
  {
    "id": "6271021003",
    "district_id": "627102",
    "label": "Banturung",
    "value": "Banturung"
  },
  {
    "id": "6271021004",
    "district_id": "627102",
    "label": "Tangkiling",
    "value": "Tangkiling"
  },
  {
    "id": "6271021005",
    "district_id": "627102",
    "label": "Sei Gohong",
    "value": "Sei Gohong"
  },
  {
    "id": "6271021006",
    "district_id": "627102",
    "label": "Kanarakan",
    "value": "Kanarakan"
  },
  {
    "id": "6271021007",
    "district_id": "627102",
    "label": "Habaring Hurung",
    "value": "Habaring Hurung"
  },
  {
    "id": "6271031001",
    "district_id": "627103",
    "label": "Palangka",
    "value": "Palangka"
  },
  {
    "id": "6271031002",
    "district_id": "627103",
    "label": "Menteng",
    "value": "Menteng"
  },
  {
    "id": "6271031003",
    "district_id": "627103",
    "label": "Bukit Tunggal",
    "value": "Bukit Tunggal"
  },
  {
    "id": "6271031004",
    "district_id": "627103",
    "label": "Petuk Katimpun",
    "value": "Petuk Katimpun"
  },
  {
    "id": "6271041001",
    "district_id": "627104",
    "label": "Bereng Bengkel",
    "value": "Bereng Bengkel"
  },
  {
    "id": "6271041002",
    "district_id": "627104",
    "label": "Kalampangan",
    "value": "Kalampangan"
  },
  {
    "id": "6271041003",
    "district_id": "627104",
    "label": "Kereng Bangkirai",
    "value": "Kereng Bangkirai"
  },
  {
    "id": "6271041004",
    "district_id": "627104",
    "label": "Kameloh Baru",
    "value": "Kameloh Baru"
  },
  {
    "id": "6271041005",
    "district_id": "627104",
    "label": "Danau Tundai",
    "value": "Danau Tundai"
  },
  {
    "id": "6271041006",
    "district_id": "627104",
    "label": "Sabaru",
    "value": "Sabaru"
  },
  {
    "id": "6271051001",
    "district_id": "627105",
    "label": "Petuk Bukit",
    "value": "Petuk Bukit"
  },
  {
    "id": "6271051002",
    "district_id": "627105",
    "label": "Panjehang",
    "value": "Panjehang"
  },
  {
    "id": "6271051003",
    "district_id": "627105",
    "label": "Petuk Barunai",
    "value": "Petuk Barunai"
  },
  {
    "id": "6271051004",
    "district_id": "627105",
    "label": "Mungku Baru",
    "value": "Mungku Baru"
  },
  {
    "id": "6271051005",
    "district_id": "627105",
    "label": "Pager",
    "value": "Pager"
  },
  {
    "id": "6271051006",
    "district_id": "627105",
    "label": "Gaung Baru",
    "value": "Gaung Baru"
  },
  {
    "id": "6271051007",
    "district_id": "627105",
    "label": "Bukit Sua",
    "value": "Bukit Sua"
  },
  {
    "id": "6301012001",
    "district_id": "630101",
    "label": "Banua lawas",
    "value": "Banua lawas"
  },
  {
    "id": "6301012002",
    "district_id": "630101",
    "label": "Tabanio",
    "value": "Tabanio"
  },
  {
    "id": "6301012003",
    "district_id": "630101",
    "label": "Kuala Tambangan",
    "value": "Kuala Tambangan"
  },
  {
    "id": "6301012004",
    "district_id": "630101",
    "label": "Takisung",
    "value": "Takisung"
  },
  {
    "id": "6301012005",
    "district_id": "630101",
    "label": "Gunung Makmur",
    "value": "Gunung Makmur"
  },
  {
    "id": "6301012006",
    "district_id": "630101",
    "label": "Banua Tengah",
    "value": "Banua Tengah"
  },
  {
    "id": "6301012007",
    "district_id": "630101",
    "label": "Ranggang",
    "value": "Ranggang"
  },
  {
    "id": "6301012008",
    "district_id": "630101",
    "label": "Pagatan Besar",
    "value": "Pagatan Besar"
  },
  {
    "id": "6301012009",
    "district_id": "630101",
    "label": "Batilai",
    "value": "Batilai"
  },
  {
    "id": "6301012010",
    "district_id": "630101",
    "label": "Ranggang Dalam",
    "value": "Ranggang Dalam"
  },
  {
    "id": "6301012011",
    "district_id": "630101",
    "label": "Telaga langsat",
    "value": "Telaga langsat"
  },
  {
    "id": "6301012012",
    "district_id": "630101",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "6301022001",
    "district_id": "630102",
    "label": "Sabuhur",
    "value": "Sabuhur"
  },
  {
    "id": "6301022002",
    "district_id": "630102",
    "label": "Jorong",
    "value": "Jorong"
  },
  {
    "id": "6301022003",
    "district_id": "630102",
    "label": "Asam-Asam",
    "value": "Asam-Asam"
  },
  {
    "id": "6301022004",
    "district_id": "630102",
    "label": "Batalang",
    "value": "Batalang"
  },
  {
    "id": "6301022005",
    "district_id": "630102",
    "label": "Swarangan",
    "value": "Swarangan"
  },
  {
    "id": "6301022006",
    "district_id": "630102",
    "label": "Muara Asam-Asam",
    "value": "Muara Asam-Asam"
  },
  {
    "id": "6301022007",
    "district_id": "630102",
    "label": "Alur",
    "value": "Alur"
  },
  {
    "id": "6301022008",
    "district_id": "630102",
    "label": "Asri Mulya",
    "value": "Asri Mulya"
  },
  {
    "id": "6301022009",
    "district_id": "630102",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "6301022010",
    "district_id": "630102",
    "label": "Asam Jaya",
    "value": "Asam Jaya"
  },
  {
    "id": "6301022011",
    "district_id": "630102",
    "label": "Simpang Empat Sungai Baru",
    "value": "Simpang Empat Sungai Baru"
  },
  {
    "id": "6301031001",
    "district_id": "630103",
    "label": "Sarang Halang",
    "value": "Sarang Halang"
  },
  {
    "id": "6301031002",
    "district_id": "630103",
    "label": "Karang Taruna",
    "value": "Karang Taruna"
  },
  {
    "id": "6301031003",
    "district_id": "630103",
    "label": "Pelaihari",
    "value": "Pelaihari"
  },
  {
    "id": "6301031004",
    "district_id": "630103",
    "label": "Angsau",
    "value": "Angsau"
  },
  {
    "id": "6301031005",
    "district_id": "630103",
    "label": "Pabahanan",
    "value": "Pabahanan"
  },
  {
    "id": "6301032006",
    "district_id": "630103",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "6301032008",
    "district_id": "630103",
    "label": "Sungai Riam",
    "value": "Sungai Riam"
  },
  {
    "id": "6301032009",
    "district_id": "630103",
    "label": "Tampang",
    "value": "Tampang"
  },
  {
    "id": "6301032010",
    "district_id": "630103",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "6301032011",
    "district_id": "630103",
    "label": "Panjaratan",
    "value": "Panjaratan"
  },
  {
    "id": "6301032012",
    "district_id": "630103",
    "label": "Atu-atu",
    "value": "Atu-atu"
  },
  {
    "id": "6301032015",
    "district_id": "630103",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "6301032016",
    "district_id": "630103",
    "label": "Tungkaran",
    "value": "Tungkaran"
  },
  {
    "id": "6301032017",
    "district_id": "630103",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "6301032020",
    "district_id": "630103",
    "label": "Panggung Baru",
    "value": "Panggung Baru"
  },
  {
    "id": "6301032024",
    "district_id": "630103",
    "label": "Ambungan",
    "value": "Ambungan"
  },
  {
    "id": "6301032025",
    "district_id": "630103",
    "label": "Guntung Besar",
    "value": "Guntung Besar"
  },
  {
    "id": "6301032026",
    "district_id": "630103",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6301032027",
    "district_id": "630103",
    "label": "Sumber Mulia",
    "value": "Sumber Mulia"
  },
  {
    "id": "6301032029",
    "district_id": "630103",
    "label": "Pemuda",
    "value": "Pemuda"
  },
  {
    "id": "6301042001",
    "district_id": "630104",
    "label": "Sungai Bakau",
    "value": "Sungai Bakau"
  },
  {
    "id": "6301042002",
    "district_id": "630104",
    "label": "Maluka Baulin",
    "value": "Maluka Baulin"
  },
  {
    "id": "6301042003",
    "district_id": "630104",
    "label": "Bawah Layung",
    "value": "Bawah Layung"
  },
  {
    "id": "6301042004",
    "district_id": "630104",
    "label": "Tambak Sarinah",
    "value": "Tambak Sarinah"
  },
  {
    "id": "6301042005",
    "district_id": "630104",
    "label": "Kali Besar",
    "value": "Kali Besar"
  },
  {
    "id": "6301042006",
    "district_id": "630104",
    "label": "Handil Negara",
    "value": "Handil Negara"
  },
  {
    "id": "6301042007",
    "district_id": "630104",
    "label": "Padang Luas",
    "value": "Padang Luas"
  },
  {
    "id": "6301042008",
    "district_id": "630104",
    "label": "Kurau",
    "value": "Kurau"
  },
  {
    "id": "6301042017",
    "district_id": "630104",
    "label": "Tambak Karya",
    "value": "Tambak Karya"
  },
  {
    "id": "6301042018",
    "district_id": "630104",
    "label": "Raden",
    "value": "Raden"
  },
  {
    "id": "6301042019",
    "district_id": "630104",
    "label": "Sarikandi",
    "value": "Sarikandi"
  },
  {
    "id": "6301052001",
    "district_id": "630105",
    "label": "Benua Raya",
    "value": "Benua Raya"
  },
  {
    "id": "6301052002",
    "district_id": "630105",
    "label": "Bati-Bati",
    "value": "Bati-Bati"
  },
  {
    "id": "6301052003",
    "district_id": "630105",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "6301052004",
    "district_id": "630105",
    "label": "Liang Anggang",
    "value": "Liang Anggang"
  },
  {
    "id": "6301052005",
    "district_id": "630105",
    "label": "Bentok Kampung",
    "value": "Bentok Kampung"
  },
  {
    "id": "6301052006",
    "district_id": "630105",
    "label": "Bentok Darat",
    "value": "Bentok Darat"
  },
  {
    "id": "6301052007",
    "district_id": "630105",
    "label": "Banyu Irang",
    "value": "Banyu Irang"
  },
  {
    "id": "6301052008",
    "district_id": "630105",
    "label": "Nusa Indah",
    "value": "Nusa Indah"
  },
  {
    "id": "6301052009",
    "district_id": "630105",
    "label": "Pandahan",
    "value": "Pandahan"
  },
  {
    "id": "6301052010",
    "district_id": "630105",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "6301052011",
    "district_id": "630105",
    "label": "Ujung Baru",
    "value": "Ujung Baru"
  },
  {
    "id": "6301052012",
    "district_id": "630105",
    "label": "Sambangan",
    "value": "Sambangan"
  },
  {
    "id": "6301052013",
    "district_id": "630105",
    "label": "Kait-kait",
    "value": "Kait-kait"
  },
  {
    "id": "6301052014",
    "district_id": "630105",
    "label": "Kait-kait Baru",
    "value": "Kait-kait Baru"
  },
  {
    "id": "6301062001",
    "district_id": "630106",
    "label": "Batu Tungku",
    "value": "Batu Tungku"
  },
  {
    "id": "6301062002",
    "district_id": "630106",
    "label": "Panyipatan",
    "value": "Panyipatan"
  },
  {
    "id": "6301062003",
    "district_id": "630106",
    "label": "Kandangan Baru",
    "value": "Kandangan Baru"
  },
  {
    "id": "6301062004",
    "district_id": "630106",
    "label": "Kandangan Lama",
    "value": "Kandangan Lama"
  },
  {
    "id": "6301062005",
    "district_id": "630106",
    "label": "Batakan",
    "value": "Batakan"
  },
  {
    "id": "6301062006",
    "district_id": "630106",
    "label": "Kuringkit",
    "value": "Kuringkit"
  },
  {
    "id": "6301062007",
    "district_id": "630106",
    "label": "Tanjung Dewa",
    "value": "Tanjung Dewa"
  },
  {
    "id": "6301062008",
    "district_id": "630106",
    "label": "Suka Ramah",
    "value": "Suka Ramah"
  },
  {
    "id": "6301062009",
    "district_id": "630106",
    "label": "Batu Mulya",
    "value": "Batu Mulya"
  },
  {
    "id": "6301062010",
    "district_id": "630106",
    "label": "Bumi Asih",
    "value": "Bumi Asih"
  },
  {
    "id": "6301072001",
    "district_id": "630107",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "6301072002",
    "district_id": "630107",
    "label": "Kintap",
    "value": "Kintap"
  },
  {
    "id": "6301072003",
    "district_id": "630107",
    "label": "Kintapura",
    "value": "Kintapura"
  },
  {
    "id": "6301072004",
    "district_id": "630107",
    "label": "Sungai Cuka",
    "value": "Sungai Cuka"
  },
  {
    "id": "6301072005",
    "district_id": "630107",
    "label": "Riam Adungan",
    "value": "Riam Adungan"
  },
  {
    "id": "6301072006",
    "district_id": "630107",
    "label": "Muara Kintap",
    "value": "Muara Kintap"
  },
  {
    "id": "6301072007",
    "district_id": "630107",
    "label": "Salaman",
    "value": "Salaman"
  },
  {
    "id": "6301072008",
    "district_id": "630107",
    "label": "Kintap Kecil",
    "value": "Kintap Kecil"
  },
  {
    "id": "6301072009",
    "district_id": "630107",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "6301072010",
    "district_id": "630107",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "6301072011",
    "district_id": "630107",
    "label": "Bukit Mulia",
    "value": "Bukit Mulia"
  },
  {
    "id": "6301072012",
    "district_id": "630107",
    "label": "Kebun Raya",
    "value": "Kebun Raya"
  },
  {
    "id": "6301072013",
    "district_id": "630107",
    "label": "Mekar Raya",
    "value": "Mekar Raya"
  },
  {
    "id": "6301072014",
    "district_id": "630107",
    "label": "Sebamban Baru",
    "value": "Sebamban Baru"
  },
  {
    "id": "6301082001",
    "district_id": "630108",
    "label": "Tambang Ulang",
    "value": "Tambang Ulang"
  },
  {
    "id": "6301082002",
    "district_id": "630108",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "6301082003",
    "district_id": "630108",
    "label": "Martadah",
    "value": "Martadah"
  },
  {
    "id": "6301082004",
    "district_id": "630108",
    "label": "Sungai Jelai",
    "value": "Sungai Jelai"
  },
  {
    "id": "6301082005",
    "district_id": "630108",
    "label": "Bingkulu",
    "value": "Bingkulu"
  },
  {
    "id": "6301082006",
    "district_id": "630108",
    "label": "Gunung Raja",
    "value": "Gunung Raja"
  },
  {
    "id": "6301082007",
    "district_id": "630108",
    "label": "Pulau Sari",
    "value": "Pulau Sari"
  },
  {
    "id": "6301082008",
    "district_id": "630108",
    "label": "Kayu Abang",
    "value": "Kayu Abang"
  },
  {
    "id": "6301082009",
    "district_id": "630108",
    "label": "Martadah Baru",
    "value": "Martadah Baru"
  },
  {
    "id": "6301092001",
    "district_id": "630109",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6301092002",
    "district_id": "630109",
    "label": "Gunung Mas",
    "value": "Gunung Mas"
  },
  {
    "id": "6301092003",
    "district_id": "630109",
    "label": "Tajau Mulya",
    "value": "Tajau Mulya"
  },
  {
    "id": "6301092004",
    "district_id": "630109",
    "label": "Jilatan",
    "value": "Jilatan"
  },
  {
    "id": "6301092005",
    "district_id": "630109",
    "label": "Durian Bungkuk",
    "value": "Durian Bungkuk"
  },
  {
    "id": "6301092006",
    "district_id": "630109",
    "label": "Ambawang",
    "value": "Ambawang"
  },
  {
    "id": "6301092007",
    "district_id": "630109",
    "label": "Damit",
    "value": "Damit"
  },
  {
    "id": "6301092008",
    "district_id": "630109",
    "label": "Gunung Melati",
    "value": "Gunung Melati"
  },
  {
    "id": "6301092009",
    "district_id": "630109",
    "label": "Bluru",
    "value": "Bluru"
  },
  {
    "id": "6301092010",
    "district_id": "630109",
    "label": "Pantai Linuh",
    "value": "Pantai Linuh"
  },
  {
    "id": "6301092011",
    "district_id": "630109",
    "label": "Damit Hulu",
    "value": "Damit Hulu"
  },
  {
    "id": "6301092012",
    "district_id": "630109",
    "label": "Jilatan Alur",
    "value": "Jilatan Alur"
  },
  {
    "id": "6301092013",
    "district_id": "630109",
    "label": "Damar Lima",
    "value": "Damar Lima"
  },
  {
    "id": "6301092014",
    "district_id": "630109",
    "label": "Tajau Pecah",
    "value": "Tajau Pecah"
  },
  {
    "id": "6301102001",
    "district_id": "630110",
    "label": "Bajuin",
    "value": "Bajuin"
  },
  {
    "id": "6301102002",
    "district_id": "630110",
    "label": "Sungai Bakar",
    "value": "Sungai Bakar"
  },
  {
    "id": "6301102003",
    "district_id": "630110",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "6301102004",
    "district_id": "630110",
    "label": "Tirta Jaya",
    "value": "Tirta Jaya"
  },
  {
    "id": "6301102005",
    "district_id": "630110",
    "label": "Galam",
    "value": "Galam"
  },
  {
    "id": "6301102006",
    "district_id": "630110",
    "label": "Pemalongan",
    "value": "Pemalongan"
  },
  {
    "id": "6301102007",
    "district_id": "630110",
    "label": "Kunyit",
    "value": "Kunyit"
  },
  {
    "id": "6301102008",
    "district_id": "630110",
    "label": "Tebing Siring",
    "value": "Tebing Siring"
  },
  {
    "id": "6301102009",
    "district_id": "630110",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6301112001",
    "district_id": "630111",
    "label": "Handil Babirik",
    "value": "Handil Babirik"
  },
  {
    "id": "6301112002",
    "district_id": "630111",
    "label": "Kurau Utara",
    "value": "Kurau Utara"
  },
  {
    "id": "6301112003",
    "district_id": "630111",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "6301112004",
    "district_id": "630111",
    "label": "Sungai Rasau",
    "value": "Sungai Rasau"
  },
  {
    "id": "6301112005",
    "district_id": "630111",
    "label": "Pantai Harapan",
    "value": "Pantai Harapan"
  },
  {
    "id": "6301112006",
    "district_id": "630111",
    "label": "Handil Suruk",
    "value": "Handil Suruk"
  },
  {
    "id": "6301112007",
    "district_id": "630111",
    "label": "Handil Gayam",
    "value": "Handil Gayam"
  },
  {
    "id": "6301112008",
    "district_id": "630111",
    "label": "Handil Birayang Atas",
    "value": "Handil Birayang Atas"
  },
  {
    "id": "6301112009",
    "district_id": "630111",
    "label": "Handil Birayang Bawah",
    "value": "Handil Birayang Bawah"
  },
  {
    "id": "6301112010",
    "district_id": "630111",
    "label": "Handil Maluka",
    "value": "Handil Maluka"
  },
  {
    "id": "6301112011",
    "district_id": "630111",
    "label": "Handil Labuan Amas",
    "value": "Handil Labuan Amas"
  },
  {
    "id": "6302012001",
    "district_id": "630201",
    "label": "Labuan Barat",
    "value": "Labuan Barat"
  },
  {
    "id": "6302012002",
    "district_id": "630201",
    "label": "Teluk Sungai",
    "value": "Teluk Sungai"
  },
  {
    "id": "6302012003",
    "district_id": "630201",
    "label": "Maradapan",
    "value": "Maradapan"
  },
  {
    "id": "6302012004",
    "district_id": "630201",
    "label": "Tengah",
    "value": "Tengah"
  },
  {
    "id": "6302012005",
    "district_id": "630201",
    "label": "Tanjung Nyiur",
    "value": "Tanjung Nyiur"
  },
  {
    "id": "6302022005",
    "district_id": "630202",
    "label": "Lontar Selatan",
    "value": "Lontar Selatan"
  },
  {
    "id": "6302022006",
    "district_id": "630202",
    "label": "Lontar Timur",
    "value": "Lontar Timur"
  },
  {
    "id": "6302022007",
    "district_id": "630202",
    "label": "Sebanti",
    "value": "Sebanti"
  },
  {
    "id": "6302022008",
    "district_id": "630202",
    "label": "Sepagar",
    "value": "Sepagar"
  },
  {
    "id": "6302022009",
    "district_id": "630202",
    "label": "Semaras",
    "value": "Semaras"
  },
  {
    "id": "6302022012",
    "district_id": "630202",
    "label": "Terangkih",
    "value": "Terangkih"
  },
  {
    "id": "6302022015",
    "district_id": "630202",
    "label": "Gemuruh",
    "value": "Gemuruh"
  },
  {
    "id": "6302022017",
    "district_id": "630202",
    "label": "Tapian Balai",
    "value": "Tapian Balai"
  },
  {
    "id": "6302022018",
    "district_id": "630202",
    "label": "Lontar Utara",
    "value": "Lontar Utara"
  },
  {
    "id": "6302022021",
    "district_id": "630202",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6302022022",
    "district_id": "630202",
    "label": "Subur Makmur",
    "value": "Subur Makmur"
  },
  {
    "id": "6302032006",
    "district_id": "630203",
    "label": "Teluk Sirih",
    "value": "Teluk Sirih"
  },
  {
    "id": "6302032007",
    "district_id": "630203",
    "label": "Sungai Bahim",
    "value": "Sungai Bahim"
  },
  {
    "id": "6302032008",
    "district_id": "630203",
    "label": "Tanjung Seloka",
    "value": "Tanjung Seloka"
  },
  {
    "id": "6302032009",
    "district_id": "630203",
    "label": "Tanjung Serudung",
    "value": "Tanjung Serudung"
  },
  {
    "id": "6302032011",
    "district_id": "630203",
    "label": "Alle-Alle",
    "value": "Alle-Alle"
  },
  {
    "id": "6302032013",
    "district_id": "630203",
    "label": "Sungai Bulan",
    "value": "Sungai Bulan"
  },
  {
    "id": "6302032015",
    "district_id": "630203",
    "label": "Labuan Mas",
    "value": "Labuan Mas"
  },
  {
    "id": "6302032016",
    "district_id": "630203",
    "label": "Tanjung Seloka Utara",
    "value": "Tanjung Seloka Utara"
  },
  {
    "id": "6302042001",
    "district_id": "630204",
    "label": "Batu Tunau",
    "value": "Batu Tunau"
  },
  {
    "id": "6302042002",
    "district_id": "630204",
    "label": "Sejakah",
    "value": "Sejakah"
  },
  {
    "id": "6302042003",
    "district_id": "630204",
    "label": "Bekambit",
    "value": "Bekambit"
  },
  {
    "id": "6302042004",
    "district_id": "630204",
    "label": "Langkang Baru",
    "value": "Langkang Baru"
  },
  {
    "id": "6302042005",
    "district_id": "630204",
    "label": "Langkang Lama",
    "value": "Langkang Lama"
  },
  {
    "id": "6302042006",
    "district_id": "630204",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "6302042007",
    "district_id": "630204",
    "label": "Berangas",
    "value": "Berangas"
  },
  {
    "id": "6302042008",
    "district_id": "630204",
    "label": "Tanjung Pengharapan",
    "value": "Tanjung Pengharapan"
  },
  {
    "id": "6302042009",
    "district_id": "630204",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "6302042010",
    "district_id": "630204",
    "label": "Teluk Mesjid",
    "value": "Teluk Mesjid"
  },
  {
    "id": "6302042011",
    "district_id": "630204",
    "label": "Teluk Gosong",
    "value": "Teluk Gosong"
  },
  {
    "id": "6302042012",
    "district_id": "630204",
    "label": "Kulipak",
    "value": "Kulipak"
  },
  {
    "id": "6302042013",
    "district_id": "630204",
    "label": "Karangsari Indah",
    "value": "Karangsari Indah"
  },
  {
    "id": "6302042014",
    "district_id": "630204",
    "label": "Bekambit Asri",
    "value": "Bekambit Asri"
  },
  {
    "id": "6302052001",
    "district_id": "630205",
    "label": "Sekapung",
    "value": "Sekapung"
  },
  {
    "id": "6302052002",
    "district_id": "630205",
    "label": "Kanibungan",
    "value": "Kanibungan"
  },
  {
    "id": "6302052003",
    "district_id": "630205",
    "label": "Mandin",
    "value": "Mandin"
  },
  {
    "id": "6302052004",
    "district_id": "630205",
    "label": "Serakaman",
    "value": "Serakaman"
  },
  {
    "id": "6302052005",
    "district_id": "630205",
    "label": "Sungai Bali",
    "value": "Sungai Bali"
  },
  {
    "id": "6302052006",
    "district_id": "630205",
    "label": "Balambus",
    "value": "Balambus"
  },
  {
    "id": "6302052007",
    "district_id": "630205",
    "label": "Rampa",
    "value": "Rampa"
  },
  {
    "id": "6302052008",
    "district_id": "630205",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "6302061001",
    "district_id": "630206",
    "label": "Kotabaru Hulu",
    "value": "Kotabaru Hulu"
  },
  {
    "id": "6302061002",
    "district_id": "630206",
    "label": "Baharu Selatan",
    "value": "Baharu Selatan"
  },
  {
    "id": "6302061003",
    "district_id": "630206",
    "label": "Kotabaru Tengah",
    "value": "Kotabaru Tengah"
  },
  {
    "id": "6302061004",
    "district_id": "630206",
    "label": "Kotabaru Hilir",
    "value": "Kotabaru Hilir"
  },
  {
    "id": "6302062009",
    "district_id": "630206",
    "label": "Megasari",
    "value": "Megasari"
  },
  {
    "id": "6302062010",
    "district_id": "630206",
    "label": "Sebelimbingan",
    "value": "Sebelimbingan"
  },
  {
    "id": "6302062011",
    "district_id": "630206",
    "label": "Stagen",
    "value": "Stagen"
  },
  {
    "id": "6302062012",
    "district_id": "630206",
    "label": "Rampa",
    "value": "Rampa"
  },
  {
    "id": "6302062013",
    "district_id": "630206",
    "label": "Sungai Taib",
    "value": "Sungai Taib"
  },
  {
    "id": "6302062014",
    "district_id": "630206",
    "label": "Semayap",
    "value": "Semayap"
  },
  {
    "id": "6302062015",
    "district_id": "630206",
    "label": "Dirgahayu",
    "value": "Dirgahayu"
  },
  {
    "id": "6302062016",
    "district_id": "630206",
    "label": "Sebatung",
    "value": "Sebatung"
  },
  {
    "id": "6302062017",
    "district_id": "630206",
    "label": "Baharu Utara",
    "value": "Baharu Utara"
  },
  {
    "id": "6302062018",
    "district_id": "630206",
    "label": "Tirawan",
    "value": "Tirawan"
  },
  {
    "id": "6302062019",
    "district_id": "630206",
    "label": "Hilir Muara",
    "value": "Hilir Muara"
  },
  {
    "id": "6302062020",
    "district_id": "630206",
    "label": "Sigam",
    "value": "Sigam"
  },
  {
    "id": "6302062021",
    "district_id": "630206",
    "label": "Sarang Tiung",
    "value": "Sarang Tiung"
  },
  {
    "id": "6302062022",
    "district_id": "630206",
    "label": "Gunung Ulin",
    "value": "Gunung Ulin"
  },
  {
    "id": "6302062024",
    "district_id": "630206",
    "label": "Gedambaan",
    "value": "Gedambaan"
  },
  {
    "id": "6302062025",
    "district_id": "630206",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "6302062026",
    "district_id": "630206",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6302072004",
    "district_id": "630207",
    "label": "Tanjung Pangga",
    "value": "Tanjung Pangga"
  },
  {
    "id": "6302072005",
    "district_id": "630207",
    "label": "Pembelacanan",
    "value": "Pembelacanan"
  },
  {
    "id": "6302072006",
    "district_id": "630207",
    "label": "Sungai Nipah",
    "value": "Sungai Nipah"
  },
  {
    "id": "6302072007",
    "district_id": "630207",
    "label": "Pantai",
    "value": "Pantai"
  },
  {
    "id": "6302072009",
    "district_id": "630207",
    "label": "Sangking Baru",
    "value": "Sangking Baru"
  },
  {
    "id": "6302072014",
    "district_id": "630207",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6302072015",
    "district_id": "630207",
    "label": "Sungai Kupang Jaya",
    "value": "Sungai Kupang Jaya"
  },
  {
    "id": "6302072016",
    "district_id": "630207",
    "label": "Pantai Baru",
    "value": "Pantai Baru"
  },
  {
    "id": "6302072017",
    "district_id": "630207",
    "label": "Bumi Asih",
    "value": "Bumi Asih"
  },
  {
    "id": "6302082001",
    "district_id": "630208",
    "label": "Cantung Kiri Hilir",
    "value": "Cantung Kiri Hilir"
  },
  {
    "id": "6302082002",
    "district_id": "630208",
    "label": "Karang Payau",
    "value": "Karang Payau"
  },
  {
    "id": "6302082003",
    "district_id": "630208",
    "label": "Banua Lawas",
    "value": "Banua Lawas"
  },
  {
    "id": "6302082004",
    "district_id": "630208",
    "label": "Sungai Kupang",
    "value": "Sungai Kupang"
  },
  {
    "id": "6302082005",
    "district_id": "630208",
    "label": "Bangkalaan Melayu",
    "value": "Bangkalaan Melayu"
  },
  {
    "id": "6302082006",
    "district_id": "630208",
    "label": "Bangkalaan Dayak",
    "value": "Bangkalaan Dayak"
  },
  {
    "id": "6302082007",
    "district_id": "630208",
    "label": "Laburan",
    "value": "Laburan"
  },
  {
    "id": "6302082008",
    "district_id": "630208",
    "label": "Karang Liwar",
    "value": "Karang Liwar"
  },
  {
    "id": "6302082009",
    "district_id": "630208",
    "label": "Mangkirana",
    "value": "Mangkirana"
  },
  {
    "id": "6302082010",
    "district_id": "630208",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6302092001",
    "district_id": "630209",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "6302092002",
    "district_id": "630209",
    "label": "Sungai Punggawa",
    "value": "Sungai Punggawa"
  },
  {
    "id": "6302092003",
    "district_id": "630209",
    "label": "Tanah Rata",
    "value": "Tanah Rata"
  },
  {
    "id": "6302092004",
    "district_id": "630209",
    "label": "Sebuli",
    "value": "Sebuli"
  },
  {
    "id": "6302092005",
    "district_id": "630209",
    "label": "Sembilang",
    "value": "Sembilang"
  },
  {
    "id": "6302092006",
    "district_id": "630209",
    "label": "Tamiang Bakung",
    "value": "Tamiang Bakung"
  },
  {
    "id": "6302092007",
    "district_id": "630209",
    "label": "Sang-Sang",
    "value": "Sang-Sang"
  },
  {
    "id": "6302092011",
    "district_id": "630209",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "6302092012",
    "district_id": "630209",
    "label": "Tanjung Selayar",
    "value": "Tanjung Selayar"
  },
  {
    "id": "6302092013",
    "district_id": "630209",
    "label": "Senakin Seberang",
    "value": "Senakin Seberang"
  },
  {
    "id": "6302092014",
    "district_id": "630209",
    "label": "Senakin",
    "value": "Senakin"
  },
  {
    "id": "6302092016",
    "district_id": "630209",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "6302092017",
    "district_id": "630209",
    "label": "Geronggang",
    "value": "Geronggang"
  },
  {
    "id": "6302102001",
    "district_id": "630210",
    "label": "Sungai Hanyar",
    "value": "Sungai Hanyar"
  },
  {
    "id": "6302102002",
    "district_id": "630210",
    "label": "Sungai Seluang",
    "value": "Sungai Seluang"
  },
  {
    "id": "6302102003",
    "district_id": "630210",
    "label": "Pudi",
    "value": "Pudi"
  },
  {
    "id": "6302102004",
    "district_id": "630210",
    "label": "Mangga",
    "value": "Mangga"
  },
  {
    "id": "6302102005",
    "district_id": "630210",
    "label": "Wilas",
    "value": "Wilas"
  },
  {
    "id": "6302102006",
    "district_id": "630210",
    "label": "Pudi Seberang",
    "value": "Pudi Seberang"
  },
  {
    "id": "6302102007",
    "district_id": "630210",
    "label": "Sulangkit",
    "value": "Sulangkit"
  },
  {
    "id": "6302112001",
    "district_id": "630211",
    "label": "Sekandis",
    "value": "Sekandis"
  },
  {
    "id": "6302112002",
    "district_id": "630211",
    "label": "Gunung Calang",
    "value": "Gunung Calang"
  },
  {
    "id": "6302112003",
    "district_id": "630211",
    "label": "Talusi",
    "value": "Talusi"
  },
  {
    "id": "6302112004",
    "district_id": "630211",
    "label": "Sakalimau",
    "value": "Sakalimau"
  },
  {
    "id": "6302112005",
    "district_id": "630211",
    "label": "Tanjung Samalantakan",
    "value": "Tanjung Samalantakan"
  },
  {
    "id": "6302112006",
    "district_id": "630211",
    "label": "Sakadoyan",
    "value": "Sakadoyan"
  },
  {
    "id": "6302112007",
    "district_id": "630211",
    "label": "Rampa Cengal",
    "value": "Rampa Cengal"
  },
  {
    "id": "6302112008",
    "district_id": "630211",
    "label": "Sesulung",
    "value": "Sesulung"
  },
  {
    "id": "6302112009",
    "district_id": "630211",
    "label": "Pondok Labu",
    "value": "Pondok Labu"
  },
  {
    "id": "6302112010",
    "district_id": "630211",
    "label": "Mulyodadi",
    "value": "Mulyodadi"
  },
  {
    "id": "6302112011",
    "district_id": "630211",
    "label": "Sukadana",
    "value": "Sukadana"
  },
  {
    "id": "6302122001",
    "district_id": "630212",
    "label": "Sepapah",
    "value": "Sepapah"
  },
  {
    "id": "6302122002",
    "district_id": "630212",
    "label": "Sungai Betung",
    "value": "Sungai Betung"
  },
  {
    "id": "6302122003",
    "district_id": "630212",
    "label": "Basuang",
    "value": "Basuang"
  },
  {
    "id": "6302122004",
    "district_id": "630212",
    "label": "Gunung Batu Besar",
    "value": "Gunung Batu Besar"
  },
  {
    "id": "6302122005",
    "district_id": "630212",
    "label": "Sampanahan",
    "value": "Sampanahan"
  },
  {
    "id": "6302122006",
    "district_id": "630212",
    "label": "Rampa Manunggul",
    "value": "Rampa Manunggul"
  },
  {
    "id": "6302122007",
    "district_id": "630212",
    "label": "Papaan",
    "value": "Papaan"
  },
  {
    "id": "6302122008",
    "district_id": "630212",
    "label": "Sampanahan Hilir",
    "value": "Sampanahan Hilir"
  },
  {
    "id": "6302122009",
    "district_id": "630212",
    "label": "Banjarsari",
    "value": "Banjarsari"
  },
  {
    "id": "6302122010",
    "district_id": "630212",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "6302132002",
    "district_id": "630213",
    "label": "Bepara",
    "value": "Bepara"
  },
  {
    "id": "6302132004",
    "district_id": "630213",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "6302132005",
    "district_id": "630213",
    "label": "Sekayu Baru",
    "value": "Sekayu Baru"
  },
  {
    "id": "6302132006",
    "district_id": "630213",
    "label": "Bakau",
    "value": "Bakau"
  },
  {
    "id": "6302132007",
    "district_id": "630213",
    "label": "Binturung",
    "value": "Binturung"
  },
  {
    "id": "6302132008",
    "district_id": "630213",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6302132009",
    "district_id": "630213",
    "label": "Balaimea",
    "value": "Balaimea"
  },
  {
    "id": "6302132010",
    "district_id": "630213",
    "label": "Tamiang",
    "value": "Tamiang"
  },
  {
    "id": "6302132011",
    "district_id": "630213",
    "label": "Kalian",
    "value": "Kalian"
  },
  {
    "id": "6302132012",
    "district_id": "630213",
    "label": "Mulyoharjo",
    "value": "Mulyoharjo"
  },
  {
    "id": "6302132013",
    "district_id": "630213",
    "label": "Pamukan Indah",
    "value": "Pamukan Indah"
  },
  {
    "id": "6302132014",
    "district_id": "630213",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "6302132015",
    "district_id": "630213",
    "label": "Lintang Jaya",
    "value": "Lintang Jaya"
  },
  {
    "id": "6302142001",
    "district_id": "630214",
    "label": "Hampang",
    "value": "Hampang"
  },
  {
    "id": "6302142002",
    "district_id": "630214",
    "label": "Cantung Kiri Hulu",
    "value": "Cantung Kiri Hulu"
  },
  {
    "id": "6302142003",
    "district_id": "630214",
    "label": "Cantung Kanan",
    "value": "Cantung Kanan"
  },
  {
    "id": "6302142004",
    "district_id": "630214",
    "label": "Muara Orie",
    "value": "Muara Orie"
  },
  {
    "id": "6302142005",
    "district_id": "630214",
    "label": "Lalapin",
    "value": "Lalapin"
  },
  {
    "id": "6302142006",
    "district_id": "630214",
    "label": "Peramasan Dua Kali Sanga",
    "value": "Peramasan Dua Kali Sanga"
  },
  {
    "id": "6302142007",
    "district_id": "630214",
    "label": "Limbur",
    "value": "Limbur"
  },
  {
    "id": "6302142008",
    "district_id": "630214",
    "label": "Hulu Sampanahan",
    "value": "Hulu Sampanahan"
  },
  {
    "id": "6302142009",
    "district_id": "630214",
    "label": "Limbungan",
    "value": "Limbungan"
  },
  {
    "id": "6302152001",
    "district_id": "630215",
    "label": "Buluh Kuning",
    "value": "Buluh Kuning"
  },
  {
    "id": "6302152002",
    "district_id": "630215",
    "label": "Gendang Timburu",
    "value": "Gendang Timburu"
  },
  {
    "id": "6302152004",
    "district_id": "630215",
    "label": "Manunggul Lama",
    "value": "Manunggul Lama"
  },
  {
    "id": "6302152006",
    "district_id": "630215",
    "label": "Rantau Buda",
    "value": "Rantau Buda"
  },
  {
    "id": "6302152007",
    "district_id": "630215",
    "label": "Manunggul Baru",
    "value": "Manunggul Baru"
  },
  {
    "id": "6302152008",
    "district_id": "630215",
    "label": "Terombong Sari",
    "value": "Terombong Sari"
  },
  {
    "id": "6302152009",
    "district_id": "630215",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "6302162001",
    "district_id": "630216",
    "label": "Semisir",
    "value": "Semisir"
  },
  {
    "id": "6302162002",
    "district_id": "630216",
    "label": "Sungai Pasir",
    "value": "Sungai Pasir"
  },
  {
    "id": "6302162003",
    "district_id": "630216",
    "label": "Mekarpura",
    "value": "Mekarpura"
  },
  {
    "id": "6302162004",
    "district_id": "630216",
    "label": "Selaru",
    "value": "Selaru"
  },
  {
    "id": "6302162005",
    "district_id": "630216",
    "label": "Sungup Kanan",
    "value": "Sungup Kanan"
  },
  {
    "id": "6302162006",
    "district_id": "630216",
    "label": "Pantai Baru",
    "value": "Pantai Baru"
  },
  {
    "id": "6302162007",
    "district_id": "630216",
    "label": "Salino",
    "value": "Salino"
  },
  {
    "id": "6302172001",
    "district_id": "630217",
    "label": "Serongga",
    "value": "Serongga"
  },
  {
    "id": "6302172002",
    "district_id": "630217",
    "label": "Tarjun",
    "value": "Tarjun"
  },
  {
    "id": "6302172003",
    "district_id": "630217",
    "label": "Langadai",
    "value": "Langadai"
  },
  {
    "id": "6302172004",
    "district_id": "630217",
    "label": "Pulau Panci",
    "value": "Pulau Panci"
  },
  {
    "id": "6302172005",
    "district_id": "630217",
    "label": "Pelajau Baru",
    "value": "Pelajau Baru"
  },
  {
    "id": "6302172006",
    "district_id": "630217",
    "label": "Tegal Rejo",
    "value": "Tegal Rejo"
  },
  {
    "id": "6302172007",
    "district_id": "630217",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "6302172008",
    "district_id": "630217",
    "label": "Telagasari",
    "value": "Telagasari"
  },
  {
    "id": "6302172009",
    "district_id": "630217",
    "label": "Sahapi",
    "value": "Sahapi"
  },
  {
    "id": "6302182001",
    "district_id": "630218",
    "label": "Siayuh",
    "value": "Siayuh"
  },
  {
    "id": "6302182002",
    "district_id": "630218",
    "label": "Bungkukan",
    "value": "Bungkukan"
  },
  {
    "id": "6302182003",
    "district_id": "630218",
    "label": "Batang Kulur",
    "value": "Batang Kulur"
  },
  {
    "id": "6302182004",
    "district_id": "630218",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "6302182005",
    "district_id": "630218",
    "label": "Magalau Hilir",
    "value": "Magalau Hilir"
  },
  {
    "id": "6302182006",
    "district_id": "630218",
    "label": "Magalau Hulu",
    "value": "Magalau Hulu"
  },
  {
    "id": "6302192001",
    "district_id": "630219",
    "label": "Sengayam",
    "value": "Sengayam"
  },
  {
    "id": "6302192002",
    "district_id": "630219",
    "label": "Mayang Sari",
    "value": "Mayang Sari"
  },
  {
    "id": "6302192003",
    "district_id": "630219",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "6302192004",
    "district_id": "630219",
    "label": "Mangka",
    "value": "Mangka"
  },
  {
    "id": "6302192005",
    "district_id": "630219",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6302202001",
    "district_id": "630220",
    "label": "Tanjung Lalak Utara",
    "value": "Tanjung Lalak Utara"
  },
  {
    "id": "6302202002",
    "district_id": "630220",
    "label": "Tanjung Lalak Selatan",
    "value": "Tanjung Lalak Selatan"
  },
  {
    "id": "6302202003",
    "district_id": "630220",
    "label": "Pulau Kerayaan",
    "value": "Pulau Kerayaan"
  },
  {
    "id": "6302202004",
    "district_id": "630220",
    "label": "Kerayaan Utara",
    "value": "Kerayaan Utara"
  },
  {
    "id": "6302202005",
    "district_id": "630220",
    "label": "Pulau Kerasian",
    "value": "Pulau Kerasian"
  },
  {
    "id": "6302202006",
    "district_id": "630220",
    "label": "Pulau Kerumputan",
    "value": "Pulau Kerumputan"
  },
  {
    "id": "6302202007",
    "district_id": "630220",
    "label": "Teluk Aru",
    "value": "Teluk Aru"
  },
  {
    "id": "6302202008",
    "district_id": "630220",
    "label": "Teluk Kemuning",
    "value": "Teluk Kemuning"
  },
  {
    "id": "6302202009",
    "district_id": "630220",
    "label": "Oka-Oka",
    "value": "Oka-Oka"
  },
  {
    "id": "6302212001",
    "district_id": "630221",
    "label": "Tanjung Pelayar",
    "value": "Tanjung Pelayar"
  },
  {
    "id": "6302212002",
    "district_id": "630221",
    "label": "Tanjung Sungkai",
    "value": "Tanjung Sungkai"
  },
  {
    "id": "6302212003",
    "district_id": "630221",
    "label": "Tanjung Tengah",
    "value": "Tanjung Tengah"
  },
  {
    "id": "6302212004",
    "district_id": "630221",
    "label": "Tanjung Kunyit",
    "value": "Tanjung Kunyit"
  },
  {
    "id": "6302212005",
    "district_id": "630221",
    "label": "Teluk Tamiang",
    "value": "Teluk Tamiang"
  },
  {
    "id": "6302212006",
    "district_id": "630221",
    "label": "Gosong Panjang",
    "value": "Gosong Panjang"
  },
  {
    "id": "6302212007",
    "district_id": "630221",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6302212008",
    "district_id": "630221",
    "label": "Tata Mekar",
    "value": "Tata Mekar"
  },
  {
    "id": "6302212009",
    "district_id": "630221",
    "label": "Bandar Raya",
    "value": "Bandar Raya"
  },
  {
    "id": "6302212010",
    "district_id": "630221",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "6303012001",
    "district_id": "630301",
    "label": "Bakambat",
    "value": "Bakambat"
  },
  {
    "id": "6303012002",
    "district_id": "630301",
    "label": "Tanipah",
    "value": "Tanipah"
  },
  {
    "id": "6303012009",
    "district_id": "630301",
    "label": "Pemurus",
    "value": "Pemurus"
  },
  {
    "id": "6303012010",
    "district_id": "630301",
    "label": "Simpang Warga",
    "value": "Simpang Warga"
  },
  {
    "id": "6303012011",
    "district_id": "630301",
    "label": "Bunipah",
    "value": "Bunipah"
  },
  {
    "id": "6303012012",
    "district_id": "630301",
    "label": "Aluh Aluh Besar",
    "value": "Aluh Aluh Besar"
  },
  {
    "id": "6303012013",
    "district_id": "630301",
    "label": "Aluh Aluh Kecil",
    "value": "Aluh Aluh Kecil"
  },
  {
    "id": "6303012014",
    "district_id": "630301",
    "label": "Podok",
    "value": "Podok"
  },
  {
    "id": "6303012015",
    "district_id": "630301",
    "label": "Handil Bujur",
    "value": "Handil Bujur"
  },
  {
    "id": "6303012016",
    "district_id": "630301",
    "label": "Kuin Besar",
    "value": "Kuin Besar"
  },
  {
    "id": "6303012017",
    "district_id": "630301",
    "label": "Terapu",
    "value": "Terapu"
  },
  {
    "id": "6303012019",
    "district_id": "630301",
    "label": "Labat Muara",
    "value": "Labat Muara"
  },
  {
    "id": "6303012022",
    "district_id": "630301",
    "label": "Pulantan",
    "value": "Pulantan"
  },
  {
    "id": "6303012024",
    "district_id": "630301",
    "label": "Aluh Aluh Kecil Muara",
    "value": "Aluh Aluh Kecil Muara"
  },
  {
    "id": "6303012026",
    "district_id": "630301",
    "label": "Simpang Warga Dalam",
    "value": "Simpang Warga Dalam"
  },
  {
    "id": "6303012027",
    "district_id": "630301",
    "label": "Kuin Kecil",
    "value": "Kuin Kecil"
  },
  {
    "id": "6303012028",
    "district_id": "630301",
    "label": "Handil Baru",
    "value": "Handil Baru"
  },
  {
    "id": "6303012030",
    "district_id": "630301",
    "label": "Balimau",
    "value": "Balimau"
  },
  {
    "id": "6303012031",
    "district_id": "630301",
    "label": "Sungai Musang",
    "value": "Sungai Musang"
  },
  {
    "id": "6303021001",
    "district_id": "630302",
    "label": "Kertak Hanyar I",
    "value": "Kertak Hanyar I"
  },
  {
    "id": "6303021008",
    "district_id": "630302",
    "label": "Manarap Lama",
    "value": "Manarap Lama"
  },
  {
    "id": "6303021015",
    "district_id": "630302",
    "label": "Mandarsari",
    "value": "Mandarsari"
  },
  {
    "id": "6303022005",
    "district_id": "630302",
    "label": "Tatah Pemangkih Laut",
    "value": "Tatah Pemangkih Laut"
  },
  {
    "id": "6303022006",
    "district_id": "630302",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "6303022007",
    "district_id": "630302",
    "label": "Sungai Lakum",
    "value": "Sungai Lakum"
  },
  {
    "id": "6303022009",
    "district_id": "630302",
    "label": "Manarap Baru",
    "value": "Manarap Baru"
  },
  {
    "id": "6303022010",
    "district_id": "630302",
    "label": "Kertak Hanyar II",
    "value": "Kertak Hanyar II"
  },
  {
    "id": "6303022014",
    "district_id": "630302",
    "label": "Pasar Kamis",
    "value": "Pasar Kamis"
  },
  {
    "id": "6303022016",
    "district_id": "630302",
    "label": "Manarap Tengah",
    "value": "Manarap Tengah"
  },
  {
    "id": "6303022017",
    "district_id": "630302",
    "label": "Belayung Baru",
    "value": "Belayung Baru"
  },
  {
    "id": "6303022021",
    "district_id": "630302",
    "label": "Banua Hanyar",
    "value": "Banua Hanyar"
  },
  {
    "id": "6303022022",
    "district_id": "630302",
    "label": "Mekar Raya",
    "value": "Mekar Raya"
  },
  {
    "id": "6303031001",
    "district_id": "630303",
    "label": "Gambut",
    "value": "Gambut"
  },
  {
    "id": "6303031014",
    "district_id": "630303",
    "label": "Gambut Barat",
    "value": "Gambut Barat"
  },
  {
    "id": "6303032002",
    "district_id": "630303",
    "label": "Sungai Kupang",
    "value": "Sungai Kupang"
  },
  {
    "id": "6303032003",
    "district_id": "630303",
    "label": "Guntung Papuyu",
    "value": "Guntung Papuyu"
  },
  {
    "id": "6303032004",
    "district_id": "630303",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "6303032005",
    "district_id": "630303",
    "label": "Tambak Sirang Darat",
    "value": "Tambak Sirang Darat"
  },
  {
    "id": "6303032006",
    "district_id": "630303",
    "label": "Tambak Sirang Laut",
    "value": "Tambak Sirang Laut"
  },
  {
    "id": "6303032007",
    "district_id": "630303",
    "label": "Malintang",
    "value": "Malintang"
  },
  {
    "id": "6303032008",
    "district_id": "630303",
    "label": "Kayu Bawang",
    "value": "Kayu Bawang"
  },
  {
    "id": "6303032009",
    "district_id": "630303",
    "label": "Banyu Hirang",
    "value": "Banyu Hirang"
  },
  {
    "id": "6303032010",
    "district_id": "630303",
    "label": "Guntung Ujung",
    "value": "Guntung Ujung"
  },
  {
    "id": "6303032011",
    "district_id": "630303",
    "label": "Tambak Sirang Baru",
    "value": "Tambak Sirang Baru"
  },
  {
    "id": "6303032012",
    "district_id": "630303",
    "label": "Malintang Baru",
    "value": "Malintang Baru"
  },
  {
    "id": "6303032013",
    "district_id": "630303",
    "label": "Keladan Baru",
    "value": "Keladan Baru"
  },
  {
    "id": "6303041001",
    "district_id": "630304",
    "label": "Sungai Lulut",
    "value": "Sungai Lulut"
  },
  {
    "id": "6303042002",
    "district_id": "630304",
    "label": "Sungai Bakung",
    "value": "Sungai Bakung"
  },
  {
    "id": "6303042003",
    "district_id": "630304",
    "label": "Sungai Tandipah",
    "value": "Sungai Tandipah"
  },
  {
    "id": "6303042004",
    "district_id": "630304",
    "label": "Lok Baintan",
    "value": "Lok Baintan"
  },
  {
    "id": "6303042005",
    "district_id": "630304",
    "label": "Gudang Hirang",
    "value": "Gudang Hirang"
  },
  {
    "id": "6303042006",
    "district_id": "630304",
    "label": "Sungai Pinang Lama",
    "value": "Sungai Pinang Lama"
  },
  {
    "id": "6303042007",
    "district_id": "630304",
    "label": "Pembantanan",
    "value": "Pembantanan"
  },
  {
    "id": "6303042008",
    "district_id": "630304",
    "label": "Pemakuan",
    "value": "Pemakuan"
  },
  {
    "id": "6303042009",
    "district_id": "630304",
    "label": "Sungai Tabuk Kota",
    "value": "Sungai Tabuk Kota"
  },
  {
    "id": "6303042010",
    "district_id": "630304",
    "label": "Sungai Tabuk Keramat",
    "value": "Sungai Tabuk Keramat"
  },
  {
    "id": "6303042011",
    "district_id": "630304",
    "label": "Lok Buntar",
    "value": "Lok Buntar"
  },
  {
    "id": "6303042012",
    "district_id": "630304",
    "label": "Gudang Tengah",
    "value": "Gudang Tengah"
  },
  {
    "id": "6303042013",
    "district_id": "630304",
    "label": "Pejambuan",
    "value": "Pejambuan"
  },
  {
    "id": "6303042014",
    "district_id": "630304",
    "label": "Keliling Benteng Ilir",
    "value": "Keliling Benteng Ilir"
  },
  {
    "id": "6303042015",
    "district_id": "630304",
    "label": "Sungai Pinang Baru",
    "value": "Sungai Pinang Baru"
  },
  {
    "id": "6303042016",
    "district_id": "630304",
    "label": "Paku Alam",
    "value": "Paku Alam"
  },
  {
    "id": "6303042017",
    "district_id": "630304",
    "label": "Lok Baintan Dalam",
    "value": "Lok Baintan Dalam"
  },
  {
    "id": "6303042018",
    "district_id": "630304",
    "label": "Pematang Panjang",
    "value": "Pematang Panjang"
  },
  {
    "id": "6303042019",
    "district_id": "630304",
    "label": "Sungai Bangkal",
    "value": "Sungai Bangkal"
  },
  {
    "id": "6303042020",
    "district_id": "630304",
    "label": "Tajau Landung",
    "value": "Tajau Landung"
  },
  {
    "id": "6303042021",
    "district_id": "630304",
    "label": "Abumbun Jaya",
    "value": "Abumbun Jaya"
  },
  {
    "id": "6303051001",
    "district_id": "630305",
    "label": "Keraton",
    "value": "Keraton"
  },
  {
    "id": "6303051002",
    "district_id": "630305",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "6303051003",
    "district_id": "630305",
    "label": "Pasayangan",
    "value": "Pasayangan"
  },
  {
    "id": "6303051004",
    "district_id": "630305",
    "label": "Murung Keraton",
    "value": "Murung Keraton"
  },
  {
    "id": "6303051005",
    "district_id": "630305",
    "label": "Sungai Paring",
    "value": "Sungai Paring"
  },
  {
    "id": "6303051055",
    "district_id": "630305",
    "label": "Tanjung Rema Darat",
    "value": "Tanjung Rema Darat"
  },
  {
    "id": "6303051059",
    "district_id": "630305",
    "label": "Sekumpul",
    "value": "Sekumpul"
  },
  {
    "id": "6303052009",
    "district_id": "630305",
    "label": "Sungai Sipai",
    "value": "Sungai Sipai"
  },
  {
    "id": "6303052010",
    "district_id": "630305",
    "label": "Pasayangan Selatan",
    "value": "Pasayangan Selatan"
  },
  {
    "id": "6303052011",
    "district_id": "630305",
    "label": "Tanjung Rema",
    "value": "Tanjung Rema"
  },
  {
    "id": "6303052012",
    "district_id": "630305",
    "label": "Bincau",
    "value": "Bincau"
  },
  {
    "id": "6303052013",
    "district_id": "630305",
    "label": "Murung Kenanga",
    "value": "Murung Kenanga"
  },
  {
    "id": "6303052015",
    "district_id": "630305",
    "label": "Tunggul Irang",
    "value": "Tunggul Irang"
  },
  {
    "id": "6303052016",
    "district_id": "630305",
    "label": "Tambak Baru",
    "value": "Tambak Baru"
  },
  {
    "id": "6303052034",
    "district_id": "630305",
    "label": "Cindai Alus",
    "value": "Cindai Alus"
  },
  {
    "id": "6303052036",
    "district_id": "630305",
    "label": "Tungkaran",
    "value": "Tungkaran"
  },
  {
    "id": "6303052037",
    "district_id": "630305",
    "label": "Tambak Baru Ulu",
    "value": "Tambak Baru Ulu"
  },
  {
    "id": "6303052040",
    "district_id": "630305",
    "label": "Bincau Muara",
    "value": "Bincau Muara"
  },
  {
    "id": "6303052042",
    "district_id": "630305",
    "label": "Tunggul Irang Ilir",
    "value": "Tunggul Irang Ilir"
  },
  {
    "id": "6303052043",
    "district_id": "630305",
    "label": "Tunggul Irang Ulu",
    "value": "Tunggul Irang Ulu"
  },
  {
    "id": "6303052051",
    "district_id": "630305",
    "label": "Labuan Tabu",
    "value": "Labuan Tabu"
  },
  {
    "id": "6303052052",
    "district_id": "630305",
    "label": "Indrasari",
    "value": "Indrasari"
  },
  {
    "id": "6303052054",
    "district_id": "630305",
    "label": "Jawa Laut",
    "value": "Jawa Laut"
  },
  {
    "id": "6303052056",
    "district_id": "630305",
    "label": "Pasayangan Utara",
    "value": "Pasayangan Utara"
  },
  {
    "id": "6303052057",
    "district_id": "630305",
    "label": "Pasayangan Barat",
    "value": "Pasayangan Barat"
  },
  {
    "id": "6303052058",
    "district_id": "630305",
    "label": "Tambak Baru Ilir",
    "value": "Tambak Baru Ilir"
  },
  {
    "id": "6303062001",
    "district_id": "630306",
    "label": "Kiram",
    "value": "Kiram"
  },
  {
    "id": "6303062002",
    "district_id": "630306",
    "label": "Mandiangin Barat",
    "value": "Mandiangin Barat"
  },
  {
    "id": "6303062003",
    "district_id": "630306",
    "label": "Karang Intan",
    "value": "Karang Intan"
  },
  {
    "id": "6303062004",
    "district_id": "630306",
    "label": "Pandak Daun",
    "value": "Pandak Daun"
  },
  {
    "id": "6303062005",
    "district_id": "630306",
    "label": "Jingah Habang Ulu",
    "value": "Jingah Habang Ulu"
  },
  {
    "id": "6303062006",
    "district_id": "630306",
    "label": "Mali Mali",
    "value": "Mali Mali"
  },
  {
    "id": "6303062007",
    "district_id": "630306",
    "label": "Lok Tangga",
    "value": "Lok Tangga"
  },
  {
    "id": "6303062008",
    "district_id": "630306",
    "label": "Lihung",
    "value": "Lihung"
  },
  {
    "id": "6303062009",
    "district_id": "630306",
    "label": "Bi ih",
    "value": "Bi ih"
  },
  {
    "id": "6303062010",
    "district_id": "630306",
    "label": "Penyambaran",
    "value": "Penyambaran"
  },
  {
    "id": "6303062011",
    "district_id": "630306",
    "label": "Sungai Alang",
    "value": "Sungai Alang"
  },
  {
    "id": "6303062012",
    "district_id": "630306",
    "label": "Sungai Asam",
    "value": "Sungai Asam"
  },
  {
    "id": "6303062013",
    "district_id": "630306",
    "label": "Mandikapau Timur",
    "value": "Mandikapau Timur"
  },
  {
    "id": "6303062014",
    "district_id": "630306",
    "label": "Awang Bangkal Barat",
    "value": "Awang Bangkal Barat"
  },
  {
    "id": "6303062015",
    "district_id": "630306",
    "label": "Awang Bangkal Timur",
    "value": "Awang Bangkal Timur"
  },
  {
    "id": "6303062016",
    "district_id": "630306",
    "label": "Sungai Besar",
    "value": "Sungai Besar"
  },
  {
    "id": "6303062017",
    "district_id": "630306",
    "label": "Mandiangin Timur",
    "value": "Mandiangin Timur"
  },
  {
    "id": "6303062018",
    "district_id": "630306",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "6303062019",
    "district_id": "630306",
    "label": "Jingah Habang Ilir",
    "value": "Jingah Habang Ilir"
  },
  {
    "id": "6303062020",
    "district_id": "630306",
    "label": "Sungai Arfat",
    "value": "Sungai Arfat"
  },
  {
    "id": "6303062021",
    "district_id": "630306",
    "label": "Padang Panjang",
    "value": "Padang Panjang"
  },
  {
    "id": "6303062022",
    "district_id": "630306",
    "label": "Sungai Landas",
    "value": "Sungai Landas"
  },
  {
    "id": "6303062023",
    "district_id": "630306",
    "label": "Abirau",
    "value": "Abirau"
  },
  {
    "id": "6303062024",
    "district_id": "630306",
    "label": "Pulau Nyiur",
    "value": "Pulau Nyiur"
  },
  {
    "id": "6303062025",
    "district_id": "630306",
    "label": "Mandikapau Barat",
    "value": "Mandikapau Barat"
  },
  {
    "id": "6303062026",
    "district_id": "630306",
    "label": "Balau",
    "value": "Balau"
  },
  {
    "id": "6303072001",
    "district_id": "630307",
    "label": "Pingaran Ilir",
    "value": "Pingaran Ilir"
  },
  {
    "id": "6303072002",
    "district_id": "630307",
    "label": "Jati Baru",
    "value": "Jati Baru"
  },
  {
    "id": "6303072003",
    "district_id": "630307",
    "label": "Pasar Jati",
    "value": "Pasar Jati"
  },
  {
    "id": "6303072004",
    "district_id": "630307",
    "label": "Danau Salak",
    "value": "Danau Salak"
  },
  {
    "id": "6303072005",
    "district_id": "630307",
    "label": "Tambak Danau",
    "value": "Tambak Danau"
  },
  {
    "id": "6303072006",
    "district_id": "630307",
    "label": "Kaliukan",
    "value": "Kaliukan"
  },
  {
    "id": "6303072007",
    "district_id": "630307",
    "label": "Sungai Alat",
    "value": "Sungai Alat"
  },
  {
    "id": "6303072008",
    "district_id": "630307",
    "label": "Pingaran Ulu",
    "value": "Pingaran Ulu"
  },
  {
    "id": "6303072009",
    "district_id": "630307",
    "label": "Astambul Kota",
    "value": "Astambul Kota"
  },
  {
    "id": "6303072010",
    "district_id": "630307",
    "label": "Astambul Seberang",
    "value": "Astambul Seberang"
  },
  {
    "id": "6303072011",
    "district_id": "630307",
    "label": "Sungai Tuan Ulu",
    "value": "Sungai Tuan Ulu"
  },
  {
    "id": "6303072012",
    "district_id": "630307",
    "label": "Banua Anyar Sungai Tuan",
    "value": "Banua Anyar Sungai Tuan"
  },
  {
    "id": "6303072013",
    "district_id": "630307",
    "label": "Kelampaian Ilir",
    "value": "Kelampaian Ilir"
  },
  {
    "id": "6303072014",
    "district_id": "630307",
    "label": "Kelampaian Ulu",
    "value": "Kelampaian Ulu"
  },
  {
    "id": "6303072015",
    "district_id": "630307",
    "label": "Limamar",
    "value": "Limamar"
  },
  {
    "id": "6303072016",
    "district_id": "630307",
    "label": "Lok Gabang",
    "value": "Lok Gabang"
  },
  {
    "id": "6303072017",
    "district_id": "630307",
    "label": "Pematang Hambawang",
    "value": "Pematang Hambawang"
  },
  {
    "id": "6303072018",
    "district_id": "630307",
    "label": "Kelampaian Tengah",
    "value": "Kelampaian Tengah"
  },
  {
    "id": "6303072019",
    "district_id": "630307",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "6303072020",
    "district_id": "630307",
    "label": "Banua Anyar Danau Salak",
    "value": "Banua Anyar Danau Salak"
  },
  {
    "id": "6303072021",
    "district_id": "630307",
    "label": "Sungai Tuan Ilir",
    "value": "Sungai Tuan Ilir"
  },
  {
    "id": "6303072022",
    "district_id": "630307",
    "label": "Munggu Raya",
    "value": "Munggu Raya"
  },
  {
    "id": "6303082005",
    "district_id": "630308",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "6303082006",
    "district_id": "630308",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "6303082007",
    "district_id": "630308",
    "label": "Lok Cantung",
    "value": "Lok Cantung"
  },
  {
    "id": "6303082008",
    "district_id": "630308",
    "label": "Tanah Intan",
    "value": "Tanah Intan"
  },
  {
    "id": "6303082009",
    "district_id": "630308",
    "label": "Sungai Raya",
    "value": "Sungai Raya"
  },
  {
    "id": "6303082010",
    "district_id": "630308",
    "label": "Sungkai",
    "value": "Sungkai"
  },
  {
    "id": "6303082011",
    "district_id": "630308",
    "label": "Sungai Langsat",
    "value": "Sungai Langsat"
  },
  {
    "id": "6303082014",
    "district_id": "630308",
    "label": "Lawiran",
    "value": "Lawiran"
  },
  {
    "id": "6303082017",
    "district_id": "630308",
    "label": "Batu Balian",
    "value": "Batu Balian"
  },
  {
    "id": "6303082018",
    "district_id": "630308",
    "label": "Cabi",
    "value": "Cabi"
  },
  {
    "id": "6303082019",
    "district_id": "630308",
    "label": "Berkat Mulya",
    "value": "Berkat Mulya"
  },
  {
    "id": "6303082021",
    "district_id": "630308",
    "label": "Sungkai Baru",
    "value": "Sungkai Baru"
  },
  {
    "id": "6303082022",
    "district_id": "630308",
    "label": "Paring Tali",
    "value": "Paring Tali"
  },
  {
    "id": "6303082025",
    "district_id": "630308",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "6303082026",
    "district_id": "630308",
    "label": "Sungai Tabuk",
    "value": "Sungai Tabuk"
  },
  {
    "id": "6303092001",
    "district_id": "630309",
    "label": "Maniapun",
    "value": "Maniapun"
  },
  {
    "id": "6303092002",
    "district_id": "630309",
    "label": "Lok Tunggul",
    "value": "Lok Tunggul"
  },
  {
    "id": "6303092003",
    "district_id": "630309",
    "label": "Lobang Baru",
    "value": "Lobang Baru"
  },
  {
    "id": "6303092004",
    "district_id": "630309",
    "label": "Pengaron",
    "value": "Pengaron"
  },
  {
    "id": "6303092005",
    "district_id": "630309",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "6303092006",
    "district_id": "630309",
    "label": "Ati'im",
    "value": "Ati'im"
  },
  {
    "id": "6303092007",
    "district_id": "630309",
    "label": "Alimukim",
    "value": "Alimukim"
  },
  {
    "id": "6303092008",
    "district_id": "630309",
    "label": "Panyiuran",
    "value": "Panyiuran"
  },
  {
    "id": "6303092009",
    "district_id": "630309",
    "label": "Antaraku",
    "value": "Antaraku"
  },
  {
    "id": "6303092010",
    "district_id": "630309",
    "label": "Mangkauk",
    "value": "Mangkauk"
  },
  {
    "id": "6303092015",
    "district_id": "630309",
    "label": "Kertak Empat",
    "value": "Kertak Empat"
  },
  {
    "id": "6303092018",
    "district_id": "630309",
    "label": "Lumpangi",
    "value": "Lumpangi"
  },
  {
    "id": "6303102001",
    "district_id": "630310",
    "label": "Kupang Rejo",
    "value": "Kupang Rejo"
  },
  {
    "id": "6303102002",
    "district_id": "630310",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "6303102003",
    "district_id": "630310",
    "label": "Kahelaan",
    "value": "Kahelaan"
  },
  {
    "id": "6303102004",
    "district_id": "630310",
    "label": "Rantau Nangka",
    "value": "Rantau Nangka"
  },
  {
    "id": "6303102005",
    "district_id": "630310",
    "label": "Rantau Bakula",
    "value": "Rantau Bakula"
  },
  {
    "id": "6303102006",
    "district_id": "630310",
    "label": "Belimbing Lama",
    "value": "Belimbing Lama"
  },
  {
    "id": "6303102010",
    "district_id": "630310",
    "label": "Sumber Baru",
    "value": "Sumber Baru"
  },
  {
    "id": "6303102011",
    "district_id": "630310",
    "label": "Belimbing Baru",
    "value": "Belimbing Baru"
  },
  {
    "id": "6303102012",
    "district_id": "630310",
    "label": "Pakutik",
    "value": "Pakutik"
  },
  {
    "id": "6303102013",
    "district_id": "630310",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "6303102015",
    "district_id": "630310",
    "label": "Hakim Makmur",
    "value": "Hakim Makmur"
  },
  {
    "id": "6303112001",
    "district_id": "630311",
    "label": "Tiwingan Lama",
    "value": "Tiwingan Lama"
  },
  {
    "id": "6303112002",
    "district_id": "630311",
    "label": "Kalaan",
    "value": "Kalaan"
  },
  {
    "id": "6303112003",
    "district_id": "630311",
    "label": "Benua Riam",
    "value": "Benua Riam"
  },
  {
    "id": "6303112004",
    "district_id": "630311",
    "label": "Bunglai",
    "value": "Bunglai"
  },
  {
    "id": "6303112005",
    "district_id": "630311",
    "label": "Apuai",
    "value": "Apuai"
  },
  {
    "id": "6303112006",
    "district_id": "630311",
    "label": "Rantau Bujur",
    "value": "Rantau Bujur"
  },
  {
    "id": "6303112007",
    "district_id": "630311",
    "label": "Artain",
    "value": "Artain"
  },
  {
    "id": "6303112008",
    "district_id": "630311",
    "label": "Rantau Balai",
    "value": "Rantau Balai"
  },
  {
    "id": "6303112009",
    "district_id": "630311",
    "label": "Tiwingan Baru",
    "value": "Tiwingan Baru"
  },
  {
    "id": "6303112010",
    "district_id": "630311",
    "label": "Belangian",
    "value": "Belangian"
  },
  {
    "id": "6303112011",
    "district_id": "630311",
    "label": "Aranio",
    "value": "Aranio"
  },
  {
    "id": "6303112012",
    "district_id": "630311",
    "label": "Pa'au",
    "value": "Pa'au"
  },
  {
    "id": "6303122001",
    "district_id": "630312",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6303122002",
    "district_id": "630312",
    "label": "Bawahan Pasar",
    "value": "Bawahan Pasar"
  },
  {
    "id": "6303122003",
    "district_id": "630312",
    "label": "Bawahan Seberang",
    "value": "Bawahan Seberang"
  },
  {
    "id": "6303122004",
    "district_id": "630312",
    "label": "Pematang Danau",
    "value": "Pematang Danau"
  },
  {
    "id": "6303122005",
    "district_id": "630312",
    "label": "Surian",
    "value": "Surian"
  },
  {
    "id": "6303122006",
    "district_id": "630312",
    "label": "Mataraman",
    "value": "Mataraman"
  },
  {
    "id": "6303122007",
    "district_id": "630312",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "6303122008",
    "district_id": "630312",
    "label": "Bawahan Selan",
    "value": "Bawahan Selan"
  },
  {
    "id": "6303122009",
    "district_id": "630312",
    "label": "Takuti",
    "value": "Takuti"
  },
  {
    "id": "6303122010",
    "district_id": "630312",
    "label": "Pasiraman",
    "value": "Pasiraman"
  },
  {
    "id": "6303122011",
    "district_id": "630312",
    "label": "Lok Tamu",
    "value": "Lok Tamu"
  },
  {
    "id": "6303122012",
    "district_id": "630312",
    "label": "Sungai Jati",
    "value": "Sungai Jati"
  },
  {
    "id": "6303122013",
    "district_id": "630312",
    "label": "Mangkalawat",
    "value": "Mangkalawat"
  },
  {
    "id": "6303122014",
    "district_id": "630312",
    "label": "Gunung Ulin",
    "value": "Gunung Ulin"
  },
  {
    "id": "6303122015",
    "district_id": "630312",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "6303132001",
    "district_id": "630313",
    "label": "Pindahan Baru",
    "value": "Pindahan Baru"
  },
  {
    "id": "6303132002",
    "district_id": "630313",
    "label": "Handil Purai",
    "value": "Handil Purai"
  },
  {
    "id": "6303132003",
    "district_id": "630313",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6303132004",
    "district_id": "630313",
    "label": "Lawahan",
    "value": "Lawahan"
  },
  {
    "id": "6303132005",
    "district_id": "630313",
    "label": "Babirik",
    "value": "Babirik"
  },
  {
    "id": "6303132006",
    "district_id": "630313",
    "label": "Jambu Burung",
    "value": "Jambu Burung"
  },
  {
    "id": "6303132007",
    "district_id": "630313",
    "label": "Tambak Padi",
    "value": "Tambak Padi"
  },
  {
    "id": "6303132008",
    "district_id": "630313",
    "label": "Haur Kuning",
    "value": "Haur Kuning"
  },
  {
    "id": "6303132009",
    "district_id": "630313",
    "label": "Jambu Raya",
    "value": "Jambu Raya"
  },
  {
    "id": "6303132010",
    "district_id": "630313",
    "label": "Rumpiang",
    "value": "Rumpiang"
  },
  {
    "id": "6303132011",
    "district_id": "630313",
    "label": "Salat Makmur",
    "value": "Salat Makmur"
  },
  {
    "id": "6303132012",
    "district_id": "630313",
    "label": "Muara Halayung",
    "value": "Muara Halayung"
  },
  {
    "id": "6303142001",
    "district_id": "630314",
    "label": "Teluk Selong",
    "value": "Teluk Selong"
  },
  {
    "id": "6303142002",
    "district_id": "630314",
    "label": "Sungai Batang",
    "value": "Sungai Batang"
  },
  {
    "id": "6303142003",
    "district_id": "630314",
    "label": "Sungai Batang Ilir",
    "value": "Sungai Batang Ilir"
  },
  {
    "id": "6303142004",
    "district_id": "630314",
    "label": "Sungai Rangas",
    "value": "Sungai Rangas"
  },
  {
    "id": "6303142005",
    "district_id": "630314",
    "label": "Penggalaman",
    "value": "Penggalaman"
  },
  {
    "id": "6303142006",
    "district_id": "630314",
    "label": "Keliling Benteng Ulu",
    "value": "Keliling Benteng Ulu"
  },
  {
    "id": "6303142007",
    "district_id": "630314",
    "label": "Antasan Sutun",
    "value": "Antasan Sutun"
  },
  {
    "id": "6303142008",
    "district_id": "630314",
    "label": "Sungai Rangas Ulu",
    "value": "Sungai Rangas Ulu"
  },
  {
    "id": "6303142009",
    "district_id": "630314",
    "label": "Sungai Rangas Hambuku",
    "value": "Sungai Rangas Hambuku"
  },
  {
    "id": "6303142010",
    "district_id": "630314",
    "label": "Keliling Benteng Tengah",
    "value": "Keliling Benteng Tengah"
  },
  {
    "id": "6303142011",
    "district_id": "630314",
    "label": "Teluk Selong Ulu",
    "value": "Teluk Selong Ulu"
  },
  {
    "id": "6303142012",
    "district_id": "630314",
    "label": "Tangkas",
    "value": "Tangkas"
  },
  {
    "id": "6303142013",
    "district_id": "630314",
    "label": "Sungai Rangas Tengah",
    "value": "Sungai Rangas Tengah"
  },
  {
    "id": "6303152001",
    "district_id": "630315",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "6303152002",
    "district_id": "630315",
    "label": "Keramat",
    "value": "Keramat"
  },
  {
    "id": "6303152003",
    "district_id": "630315",
    "label": "Antasan Senor",
    "value": "Antasan Senor"
  },
  {
    "id": "6303152004",
    "district_id": "630315",
    "label": "Tambak Anyar",
    "value": "Tambak Anyar"
  },
  {
    "id": "6303152005",
    "district_id": "630315",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "6303152006",
    "district_id": "630315",
    "label": "Melayu Ilir",
    "value": "Melayu Ilir"
  },
  {
    "id": "6303152007",
    "district_id": "630315",
    "label": "Akar Bagantung",
    "value": "Akar Bagantung"
  },
  {
    "id": "6303152008",
    "district_id": "630315",
    "label": "Dalam Pagar",
    "value": "Dalam Pagar"
  },
  {
    "id": "6303152009",
    "district_id": "630315",
    "label": "Tambak Anyar Ulu",
    "value": "Tambak Anyar Ulu"
  },
  {
    "id": "6303152010",
    "district_id": "630315",
    "label": "Pematang Baru",
    "value": "Pematang Baru"
  },
  {
    "id": "6303152011",
    "district_id": "630315",
    "label": "Melayu Tengah",
    "value": "Melayu Tengah"
  },
  {
    "id": "6303152012",
    "district_id": "630315",
    "label": "Akar Baru",
    "value": "Akar Baru"
  },
  {
    "id": "6303152013",
    "district_id": "630315",
    "label": "Dalam Pagar Ulu",
    "value": "Dalam Pagar Ulu"
  },
  {
    "id": "6303152014",
    "district_id": "630315",
    "label": "Pekauman Ulu",
    "value": "Pekauman Ulu"
  },
  {
    "id": "6303152015",
    "district_id": "630315",
    "label": "Mekar",
    "value": "Mekar"
  },
  {
    "id": "6303152016",
    "district_id": "630315",
    "label": "Tambak Anyar Ilir",
    "value": "Tambak Anyar Ilir"
  },
  {
    "id": "6303152017",
    "district_id": "630315",
    "label": "Sungai Kitano",
    "value": "Sungai Kitano"
  },
  {
    "id": "6303152018",
    "district_id": "630315",
    "label": "Keramat Baru",
    "value": "Keramat Baru"
  },
  {
    "id": "6303152019",
    "district_id": "630315",
    "label": "Pekauman Dalam",
    "value": "Pekauman Dalam"
  },
  {
    "id": "6303152020",
    "district_id": "630315",
    "label": "Antasan Senor Ilir",
    "value": "Antasan Senor Ilir"
  },
  {
    "id": "6303162001",
    "district_id": "630316",
    "label": "Madurejo",
    "value": "Madurejo"
  },
  {
    "id": "6303162002",
    "district_id": "630316",
    "label": "Baliangin",
    "value": "Baliangin"
  },
  {
    "id": "6303162003",
    "district_id": "630316",
    "label": "Gunung Batu",
    "value": "Gunung Batu"
  },
  {
    "id": "6303162004",
    "district_id": "630316",
    "label": "Batang Banyu",
    "value": "Batang Banyu"
  },
  {
    "id": "6303162005",
    "district_id": "630316",
    "label": "Sungai Lurus",
    "value": "Sungai Lurus"
  },
  {
    "id": "6303162006",
    "district_id": "630316",
    "label": "Batu Tanam",
    "value": "Batu Tanam"
  },
  {
    "id": "6303162007",
    "district_id": "630316",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "6303172001",
    "district_id": "630317",
    "label": "Paramasan Atas",
    "value": "Paramasan Atas"
  },
  {
    "id": "6303172002",
    "district_id": "630317",
    "label": "Paramasan Bawah",
    "value": "Paramasan Bawah"
  },
  {
    "id": "6303172003",
    "district_id": "630317",
    "label": "Remo",
    "value": "Remo"
  },
  {
    "id": "6303172004",
    "district_id": "630317",
    "label": "Angkipih",
    "value": "Angkipih"
  },
  {
    "id": "6303182001",
    "district_id": "630318",
    "label": "Rantau Bujur",
    "value": "Rantau Bujur"
  },
  {
    "id": "6303182002",
    "district_id": "630318",
    "label": "Lok Tanah",
    "value": "Lok Tanah"
  },
  {
    "id": "6303182003",
    "district_id": "630318",
    "label": "Telaga Baru",
    "value": "Telaga Baru"
  },
  {
    "id": "6303182004",
    "district_id": "630318",
    "label": "Rampah",
    "value": "Rampah"
  },
  {
    "id": "6303192001",
    "district_id": "630319",
    "label": "Tatah Bangkal",
    "value": "Tatah Bangkal"
  },
  {
    "id": "6303192002",
    "district_id": "630319",
    "label": "Bangkal Tengah",
    "value": "Bangkal Tengah"
  },
  {
    "id": "6303192003",
    "district_id": "630319",
    "label": "Layap Baru",
    "value": "Layap Baru"
  },
  {
    "id": "6303192004",
    "district_id": "630319",
    "label": "Tatah Layap",
    "value": "Tatah Layap"
  },
  {
    "id": "6303192005",
    "district_id": "630319",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6303192006",
    "district_id": "630319",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "6303192007",
    "district_id": "630319",
    "label": "Tampang Awang",
    "value": "Tampang Awang"
  },
  {
    "id": "6303192008",
    "district_id": "630319",
    "label": "Pemangkih Darat",
    "value": "Pemangkih Darat"
  },
  {
    "id": "6303192009",
    "district_id": "630319",
    "label": "Pemangkih Baru",
    "value": "Pemangkih Baru"
  },
  {
    "id": "6303192010",
    "district_id": "630319",
    "label": "Pemangkih Tengah",
    "value": "Pemangkih Tengah"
  },
  {
    "id": "6303192011",
    "district_id": "630319",
    "label": "Tatah Jaruju",
    "value": "Tatah Jaruju"
  },
  {
    "id": "6303192012",
    "district_id": "630319",
    "label": "Jaruju Laut",
    "value": "Jaruju Laut"
  },
  {
    "id": "6303192013",
    "district_id": "630319",
    "label": "Thaibah Raya",
    "value": "Thaibah Raya"
  },
  {
    "id": "6303202001",
    "district_id": "630320",
    "label": "Keramat Mina",
    "value": "Keramat Mina"
  },
  {
    "id": "6303202002",
    "district_id": "630320",
    "label": "Garis Hanyar",
    "value": "Garis Hanyar"
  },
  {
    "id": "6303202003",
    "district_id": "630320",
    "label": "Cintapuri",
    "value": "Cintapuri"
  },
  {
    "id": "6303202004",
    "district_id": "630320",
    "label": "Surian Hanyar",
    "value": "Surian Hanyar"
  },
  {
    "id": "6303202005",
    "district_id": "630320",
    "label": "Benua Anyar",
    "value": "Benua Anyar"
  },
  {
    "id": "6303202006",
    "district_id": "630320",
    "label": "Alalak Padang",
    "value": "Alalak Padang"
  },
  {
    "id": "6303202007",
    "district_id": "630320",
    "label": "Makmur Karya",
    "value": "Makmur Karya"
  },
  {
    "id": "6303202008",
    "district_id": "630320",
    "label": "Simpang Lima",
    "value": "Simpang Lima"
  },
  {
    "id": "6303202009",
    "district_id": "630320",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "6303202010",
    "district_id": "630320",
    "label": "Sindang Jaya",
    "value": "Sindang Jaya"
  },
  {
    "id": "6303202011",
    "district_id": "630320",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6304012001",
    "district_id": "630401",
    "label": "Kuala Lupak",
    "value": "Kuala Lupak"
  },
  {
    "id": "6304012002",
    "district_id": "630401",
    "label": "Sungai Telan Besar",
    "value": "Sungai Telan Besar"
  },
  {
    "id": "6304012003",
    "district_id": "630401",
    "label": "Sungai Telan Kecil",
    "value": "Sungai Telan Kecil"
  },
  {
    "id": "6304012004",
    "district_id": "630401",
    "label": "Tabunganen Muara",
    "value": "Tabunganen Muara"
  },
  {
    "id": "6304012005",
    "district_id": "630401",
    "label": "Tabunganen Tengah",
    "value": "Tabunganen Tengah"
  },
  {
    "id": "6304012006",
    "district_id": "630401",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "6304012007",
    "district_id": "630401",
    "label": "Tabunganen Perumus",
    "value": "Tabunganen Perumus"
  },
  {
    "id": "6304012008",
    "district_id": "630401",
    "label": "Sungai Teras Dalam",
    "value": "Sungai Teras Dalam"
  },
  {
    "id": "6304012009",
    "district_id": "630401",
    "label": "Sungai Jingah Besar",
    "value": "Sungai Jingah Besar"
  },
  {
    "id": "6304012010",
    "district_id": "630401",
    "label": "Tabunganen Kecil",
    "value": "Tabunganen Kecil"
  },
  {
    "id": "6304012011",
    "district_id": "630401",
    "label": "Sei Teras Luar",
    "value": "Sei Teras Luar"
  },
  {
    "id": "6304012012",
    "district_id": "630401",
    "label": "Sei Telan Muara",
    "value": "Sei Telan Muara"
  },
  {
    "id": "6304012013",
    "district_id": "630401",
    "label": "Beringin Kencana",
    "value": "Beringin Kencana"
  },
  {
    "id": "6304012014",
    "district_id": "630401",
    "label": "Tanggul Rejo",
    "value": "Tanggul Rejo"
  },
  {
    "id": "6304022001",
    "district_id": "630402",
    "label": "Purwosari II",
    "value": "Purwosari II"
  },
  {
    "id": "6304022002",
    "district_id": "630402",
    "label": "Purwosari I",
    "value": "Purwosari I"
  },
  {
    "id": "6304022003",
    "district_id": "630402",
    "label": "Tamban Bangun",
    "value": "Tamban Bangun"
  },
  {
    "id": "6304022004",
    "district_id": "630402",
    "label": "Tamban Muara",
    "value": "Tamban Muara"
  },
  {
    "id": "6304022005",
    "district_id": "630402",
    "label": "Tamban Kecil",
    "value": "Tamban Kecil"
  },
  {
    "id": "6304022006",
    "district_id": "630402",
    "label": "Tinggiran II Luar",
    "value": "Tinggiran II Luar"
  },
  {
    "id": "6304022007",
    "district_id": "630402",
    "label": "Jelapat I",
    "value": "Jelapat I"
  },
  {
    "id": "6304022008",
    "district_id": "630402",
    "label": "Tamban Muara Baru",
    "value": "Tamban Muara Baru"
  },
  {
    "id": "6304022009",
    "district_id": "630402",
    "label": "Purwosari Baru",
    "value": "Purwosari Baru"
  },
  {
    "id": "6304022010",
    "district_id": "630402",
    "label": "Sekata Baru",
    "value": "Sekata Baru"
  },
  {
    "id": "6304022011",
    "district_id": "630402",
    "label": "Koanda",
    "value": "Koanda"
  },
  {
    "id": "6304022012",
    "district_id": "630402",
    "label": "Damsari",
    "value": "Damsari"
  },
  {
    "id": "6304022013",
    "district_id": "630402",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "6304022014",
    "district_id": "630402",
    "label": "Jelapat Baru",
    "value": "Jelapat Baru"
  },
  {
    "id": "6304022015",
    "district_id": "630402",
    "label": "Tamban Bangun Baru",
    "value": "Tamban Bangun Baru"
  },
  {
    "id": "6304022016",
    "district_id": "630402",
    "label": "Tamban Sari Baru",
    "value": "Tamban Sari Baru"
  },
  {
    "id": "6304032001",
    "district_id": "630403",
    "label": "Andaman",
    "value": "Andaman"
  },
  {
    "id": "6304032002",
    "district_id": "630403",
    "label": "Hilir Mesjid",
    "value": "Hilir Mesjid"
  },
  {
    "id": "6304032003",
    "district_id": "630403",
    "label": "Anjir Pasar Kota",
    "value": "Anjir Pasar Kota"
  },
  {
    "id": "6304032004",
    "district_id": "630403",
    "label": "Banyiur",
    "value": "Banyiur"
  },
  {
    "id": "6304032005",
    "district_id": "630403",
    "label": "Gandaraya",
    "value": "Gandaraya"
  },
  {
    "id": "6304032006",
    "district_id": "630403",
    "label": "Gandaria",
    "value": "Gandaria"
  },
  {
    "id": "6304032007",
    "district_id": "630403",
    "label": "Anjir Pasar Kota II",
    "value": "Anjir Pasar Kota II"
  },
  {
    "id": "6304032008",
    "district_id": "630403",
    "label": "Andaman II",
    "value": "Andaman II"
  },
  {
    "id": "6304032009",
    "district_id": "630403",
    "label": "Anjir Seberang Pasar II",
    "value": "Anjir Seberang Pasar II"
  },
  {
    "id": "6304032010",
    "district_id": "630403",
    "label": "Anjir Seberang Pasar I",
    "value": "Anjir Seberang Pasar I"
  },
  {
    "id": "6304032011",
    "district_id": "630403",
    "label": "Anjir Pasar Lama",
    "value": "Anjir Pasar Lama"
  },
  {
    "id": "6304032012",
    "district_id": "630403",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "6304032013",
    "district_id": "630403",
    "label": "Mentaren",
    "value": "Mentaren"
  },
  {
    "id": "6304032014",
    "district_id": "630403",
    "label": "Barunai Baru",
    "value": "Barunai Baru"
  },
  {
    "id": "6304032015",
    "district_id": "630403",
    "label": "Danau Karya",
    "value": "Danau Karya"
  },
  {
    "id": "6304042001",
    "district_id": "630404",
    "label": "Anjir Serapat Muara",
    "value": "Anjir Serapat Muara"
  },
  {
    "id": "6304042002",
    "district_id": "630404",
    "label": "Anjir Muara Kota",
    "value": "Anjir Muara Kota"
  },
  {
    "id": "6304042003",
    "district_id": "630404",
    "label": "Patih Muhur",
    "value": "Patih Muhur"
  },
  {
    "id": "6304042004",
    "district_id": "630404",
    "label": "Anjir Muara Kota Tengah",
    "value": "Anjir Muara Kota Tengah"
  },
  {
    "id": "6304042005",
    "district_id": "630404",
    "label": "Anjir Serapat Lama",
    "value": "Anjir Serapat Lama"
  },
  {
    "id": "6304042006",
    "district_id": "630404",
    "label": "Anjir Serapat Baru",
    "value": "Anjir Serapat Baru"
  },
  {
    "id": "6304042007",
    "district_id": "630404",
    "label": "Anjir Muara Lama",
    "value": "Anjir Muara Lama"
  },
  {
    "id": "6304042008",
    "district_id": "630404",
    "label": "Sungai Punggu",
    "value": "Sungai Punggu"
  },
  {
    "id": "6304042009",
    "district_id": "630404",
    "label": "Anjir Serapat Baru I",
    "value": "Anjir Serapat Baru I"
  },
  {
    "id": "6304042010",
    "district_id": "630404",
    "label": "Patih Muhur Baru",
    "value": "Patih Muhur Baru"
  },
  {
    "id": "6304042011",
    "district_id": "630404",
    "label": "Sei Punggu Baru",
    "value": "Sei Punggu Baru"
  },
  {
    "id": "6304042012",
    "district_id": "630404",
    "label": "Anjir Serapat Muara I",
    "value": "Anjir Serapat Muara I"
  },
  {
    "id": "6304042013",
    "district_id": "630404",
    "label": "Sepakat Barsama",
    "value": "Sepakat Barsama"
  },
  {
    "id": "6304042014",
    "district_id": "630404",
    "label": "Marabahan Baru",
    "value": "Marabahan Baru"
  },
  {
    "id": "6304042015",
    "district_id": "630404",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "6304051001",
    "district_id": "630405",
    "label": "Berangas Barat",
    "value": "Berangas Barat"
  },
  {
    "id": "6304051002",
    "district_id": "630405",
    "label": "Berangas",
    "value": "Berangas"
  },
  {
    "id": "6304051003",
    "district_id": "630405",
    "label": "Handil Bakti",
    "value": "Handil Bakti"
  },
  {
    "id": "6304052004",
    "district_id": "630405",
    "label": "Pulau Alalak",
    "value": "Pulau Alalak"
  },
  {
    "id": "6304052005",
    "district_id": "630405",
    "label": "Pulau Sewangi",
    "value": "Pulau Sewangi"
  },
  {
    "id": "6304052006",
    "district_id": "630405",
    "label": "Pulau Sugara",
    "value": "Pulau Sugara"
  },
  {
    "id": "6304052007",
    "district_id": "630405",
    "label": "Sungai Lumbah",
    "value": "Sungai Lumbah"
  },
  {
    "id": "6304052008",
    "district_id": "630405",
    "label": "Berangas Timur",
    "value": "Berangas Timur"
  },
  {
    "id": "6304052009",
    "district_id": "630405",
    "label": "Sei Semangat Bhakti",
    "value": "Sei Semangat Bhakti"
  },
  {
    "id": "6304052010",
    "district_id": "630405",
    "label": "Sungai Pitung",
    "value": "Sungai Pitung"
  },
  {
    "id": "6304052011",
    "district_id": "630405",
    "label": "Belandean Muara",
    "value": "Belandean Muara"
  },
  {
    "id": "6304052012",
    "district_id": "630405",
    "label": "Belandean",
    "value": "Belandean"
  },
  {
    "id": "6304052013",
    "district_id": "630405",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6304052014",
    "district_id": "630405",
    "label": "Semangat Dalam",
    "value": "Semangat Dalam"
  },
  {
    "id": "6304052015",
    "district_id": "630405",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6304052016",
    "district_id": "630405",
    "label": "Semangat Karya",
    "value": "Semangat Karya"
  },
  {
    "id": "6304052017",
    "district_id": "630405",
    "label": "Panca Karya",
    "value": "Panca Karya"
  },
  {
    "id": "6304052018",
    "district_id": "630405",
    "label": "Tatah Mesjid",
    "value": "Tatah Mesjid"
  },
  {
    "id": "6304062001",
    "district_id": "630406",
    "label": "Terantang",
    "value": "Terantang"
  },
  {
    "id": "6304062002",
    "district_id": "630406",
    "label": "Tanipah",
    "value": "Tanipah"
  },
  {
    "id": "6304062003",
    "district_id": "630406",
    "label": "Puntik Luar",
    "value": "Puntik Luar"
  },
  {
    "id": "6304062004",
    "district_id": "630406",
    "label": "Puntik Dalam",
    "value": "Puntik Dalam"
  },
  {
    "id": "6304062005",
    "district_id": "630406",
    "label": "Tabing Rimbah",
    "value": "Tabing Rimbah"
  },
  {
    "id": "6304062006",
    "district_id": "630406",
    "label": "Pantai Hambawang",
    "value": "Pantai Hambawang"
  },
  {
    "id": "6304062009",
    "district_id": "630406",
    "label": "Tatah Alayung",
    "value": "Tatah Alayung"
  },
  {
    "id": "6304062011",
    "district_id": "630406",
    "label": "Puntik Tengah",
    "value": "Puntik Tengah"
  },
  {
    "id": "6304062014",
    "district_id": "630406",
    "label": "Lokrawa",
    "value": "Lokrawa"
  },
  {
    "id": "6304062015",
    "district_id": "630406",
    "label": "Sei Ramania",
    "value": "Sei Ramania"
  },
  {
    "id": "6304062016",
    "district_id": "630406",
    "label": "Bangkit Baru",
    "value": "Bangkit Baru"
  },
  {
    "id": "6304062017",
    "district_id": "630406",
    "label": "Antasan Segera",
    "value": "Antasan Segera"
  },
  {
    "id": "6304062020",
    "district_id": "630406",
    "label": "Karang Bunga",
    "value": "Karang Bunga"
  },
  {
    "id": "6304062021",
    "district_id": "630406",
    "label": "Karang Indah",
    "value": "Karang Indah"
  },
  {
    "id": "6304072001",
    "district_id": "630407",
    "label": "Sungai Pantai",
    "value": "Sungai Pantai"
  },
  {
    "id": "6304072002",
    "district_id": "630407",
    "label": "Pindahan Baru",
    "value": "Pindahan Baru"
  },
  {
    "id": "6304072003",
    "district_id": "630407",
    "label": "Sungai Gampa Asahi",
    "value": "Sungai Gampa Asahi"
  },
  {
    "id": "6304072004",
    "district_id": "630407",
    "label": "Sungai Gampa",
    "value": "Sungai Gampa"
  },
  {
    "id": "6304072005",
    "district_id": "630407",
    "label": "Sungai Sahurai",
    "value": "Sungai Sahurai"
  },
  {
    "id": "6304072006",
    "district_id": "630407",
    "label": "Simpang Arya",
    "value": "Simpang Arya"
  },
  {
    "id": "6304072007",
    "district_id": "630407",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "6304072008",
    "district_id": "630407",
    "label": "Sungai Bamban",
    "value": "Sungai Bamban"
  },
  {
    "id": "6304072009",
    "district_id": "630407",
    "label": "Danda Jaya",
    "value": "Danda Jaya"
  },
  {
    "id": "6304082001",
    "district_id": "630408",
    "label": "Murung Keramat",
    "value": "Murung Keramat"
  },
  {
    "id": "6304082002",
    "district_id": "630408",
    "label": "Sungai Seluang",
    "value": "Sungai Seluang"
  },
  {
    "id": "6304082003",
    "district_id": "630408",
    "label": "Belawang",
    "value": "Belawang"
  },
  {
    "id": "6304082004",
    "district_id": "630408",
    "label": "Bambangin",
    "value": "Bambangin"
  },
  {
    "id": "6304082005",
    "district_id": "630408",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "6304082006",
    "district_id": "630408",
    "label": "Sungai Seluang Pasar",
    "value": "Sungai Seluang Pasar"
  },
  {
    "id": "6304082007",
    "district_id": "630408",
    "label": "Samuda",
    "value": "Samuda"
  },
  {
    "id": "6304082008",
    "district_id": "630408",
    "label": "Parimata",
    "value": "Parimata"
  },
  {
    "id": "6304082009",
    "district_id": "630408",
    "label": "Karang Dukuh",
    "value": "Karang Dukuh"
  },
  {
    "id": "6304082010",
    "district_id": "630408",
    "label": "Patih Selera",
    "value": "Patih Selera"
  },
  {
    "id": "6304082011",
    "district_id": "630408",
    "label": "Karang Buah",
    "value": "Karang Buah"
  },
  {
    "id": "6304082012",
    "district_id": "630408",
    "label": "Binaan Baru",
    "value": "Binaan Baru"
  },
  {
    "id": "6304082013",
    "district_id": "630408",
    "label": "Rangga Surya",
    "value": "Rangga Surya"
  },
  {
    "id": "6304092001",
    "district_id": "630409",
    "label": "Sungai Kambat",
    "value": "Sungai Kambat"
  },
  {
    "id": "6304092002",
    "district_id": "630409",
    "label": "Sungai Rasau",
    "value": "Sungai Rasau"
  },
  {
    "id": "6304092003",
    "district_id": "630409",
    "label": "Simpang Nungki",
    "value": "Simpang Nungki"
  },
  {
    "id": "6304092004",
    "district_id": "630409",
    "label": "Sawahan",
    "value": "Sawahan"
  },
  {
    "id": "6304092005",
    "district_id": "630409",
    "label": "Bantuil",
    "value": "Bantuil"
  },
  {
    "id": "6304092006",
    "district_id": "630409",
    "label": "Badandan",
    "value": "Badandan"
  },
  {
    "id": "6304092007",
    "district_id": "630409",
    "label": "Sei Tunjang",
    "value": "Sei Tunjang"
  },
  {
    "id": "6304092008",
    "district_id": "630409",
    "label": "Sei Raya",
    "value": "Sei Raya"
  },
  {
    "id": "6304101001",
    "district_id": "630410",
    "label": "Lepasan",
    "value": "Lepasan"
  },
  {
    "id": "6304102002",
    "district_id": "630410",
    "label": "Banua Anyar",
    "value": "Banua Anyar"
  },
  {
    "id": "6304102003",
    "district_id": "630410",
    "label": "Murung Raya",
    "value": "Murung Raya"
  },
  {
    "id": "6304102004",
    "district_id": "630410",
    "label": "Palingkau",
    "value": "Palingkau"
  },
  {
    "id": "6304102005",
    "district_id": "630410",
    "label": "Balukung",
    "value": "Balukung"
  },
  {
    "id": "6304102006",
    "district_id": "630410",
    "label": "Banitan",
    "value": "Banitan"
  },
  {
    "id": "6304102007",
    "district_id": "630410",
    "label": "Batik",
    "value": "Batik"
  },
  {
    "id": "6304102008",
    "district_id": "630410",
    "label": "Bahalayung",
    "value": "Bahalayung"
  },
  {
    "id": "6304102009",
    "district_id": "630410",
    "label": "Sungai Selirik",
    "value": "Sungai Selirik"
  },
  {
    "id": "6304112001",
    "district_id": "630411",
    "label": "Jambu Baru",
    "value": "Jambu Baru"
  },
  {
    "id": "6304112002",
    "district_id": "630411",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "6304112003",
    "district_id": "630411",
    "label": "Kabuau",
    "value": "Kabuau"
  },
  {
    "id": "6304112004",
    "district_id": "630411",
    "label": "Jarenang",
    "value": "Jarenang"
  },
  {
    "id": "6304112005",
    "district_id": "630411",
    "label": "Tabatan",
    "value": "Tabatan"
  },
  {
    "id": "6304112006",
    "district_id": "630411",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "6304112007",
    "district_id": "630411",
    "label": "Tabatan Baru",
    "value": "Tabatan Baru"
  },
  {
    "id": "6304112008",
    "district_id": "630411",
    "label": "Asia Baru",
    "value": "Asia Baru"
  },
  {
    "id": "6304112009",
    "district_id": "630411",
    "label": "Rimbun Tulang",
    "value": "Rimbun Tulang"
  },
  {
    "id": "6304122001",
    "district_id": "630412",
    "label": "Pantang Raya",
    "value": "Pantang Raya"
  },
  {
    "id": "6304122002",
    "district_id": "630412",
    "label": "Tabukan Raya",
    "value": "Tabukan Raya"
  },
  {
    "id": "6304122003",
    "district_id": "630412",
    "label": "Teluk Tamba",
    "value": "Teluk Tamba"
  },
  {
    "id": "6304122004",
    "district_id": "630412",
    "label": "Rantau Bamban",
    "value": "Rantau Bamban"
  },
  {
    "id": "6304122005",
    "district_id": "630412",
    "label": "Tamba Jaya",
    "value": "Tamba Jaya"
  },
  {
    "id": "6304122006",
    "district_id": "630412",
    "label": "Muara Pulau",
    "value": "Muara Pulau"
  },
  {
    "id": "6304122007",
    "district_id": "630412",
    "label": "Karya Indah",
    "value": "Karya Indah"
  },
  {
    "id": "6304122008",
    "district_id": "630412",
    "label": "Bandar Karya",
    "value": "Bandar Karya"
  },
  {
    "id": "6304122009",
    "district_id": "630412",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "6304122010",
    "district_id": "630412",
    "label": "Karya Jadi",
    "value": "Karya Jadi"
  },
  {
    "id": "6304122011",
    "district_id": "630412",
    "label": "Pantang Baru",
    "value": "Pantang Baru"
  },
  {
    "id": "6304132001",
    "district_id": "630413",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "6304132002",
    "district_id": "630413",
    "label": "Tamban Raya",
    "value": "Tamban Raya"
  },
  {
    "id": "6304132003",
    "district_id": "630413",
    "label": "Tinggiran Tengah",
    "value": "Tinggiran Tengah"
  },
  {
    "id": "6304132004",
    "district_id": "630413",
    "label": "Tinggiran Darat",
    "value": "Tinggiran Darat"
  },
  {
    "id": "6304132005",
    "district_id": "630413",
    "label": "Jelapat II",
    "value": "Jelapat II"
  },
  {
    "id": "6304132006",
    "district_id": "630413",
    "label": "Tamban Raya Baru",
    "value": "Tamban Raya Baru"
  },
  {
    "id": "6304132007",
    "district_id": "630413",
    "label": "Tinggiran Baru",
    "value": "Tinggiran Baru"
  },
  {
    "id": "6304132008",
    "district_id": "630413",
    "label": "Karang Mekar",
    "value": "Karang Mekar"
  },
  {
    "id": "6304132009",
    "district_id": "630413",
    "label": "Indah Sari",
    "value": "Indah Sari"
  },
  {
    "id": "6304142001",
    "district_id": "630414",
    "label": "Barambai",
    "value": "Barambai"
  },
  {
    "id": "6304142002",
    "district_id": "630414",
    "label": "Sungai Kali",
    "value": "Sungai Kali"
  },
  {
    "id": "6304142003",
    "district_id": "630414",
    "label": "Pendalaman",
    "value": "Pendalaman"
  },
  {
    "id": "6304142004",
    "district_id": "630414",
    "label": "Handil Barabai",
    "value": "Handil Barabai"
  },
  {
    "id": "6304142005",
    "district_id": "630414",
    "label": "Bagagap",
    "value": "Bagagap"
  },
  {
    "id": "6304142006",
    "district_id": "630414",
    "label": "Barambai Karya Tani",
    "value": "Barambai Karya Tani"
  },
  {
    "id": "6304142007",
    "district_id": "630414",
    "label": "Pendalaman Baru",
    "value": "Pendalaman Baru"
  },
  {
    "id": "6304142008",
    "district_id": "630414",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "6304142009",
    "district_id": "630414",
    "label": "Barambai Kolam Kiri",
    "value": "Barambai Kolam Kiri"
  },
  {
    "id": "6304142010",
    "district_id": "630414",
    "label": "Barambai Kolam Kanan",
    "value": "Barambai Kolam Kanan"
  },
  {
    "id": "6304142011",
    "district_id": "630414",
    "label": "Barambai Kolam Kiri Dalam",
    "value": "Barambai Kolam Kiri Dalam"
  },
  {
    "id": "6304151001",
    "district_id": "630415",
    "label": "Marabahan Kota",
    "value": "Marabahan Kota"
  },
  {
    "id": "6304151002",
    "district_id": "630415",
    "label": "Ulu Benteng",
    "value": "Ulu Benteng"
  },
  {
    "id": "6304152003",
    "district_id": "630415",
    "label": "Penghulu",
    "value": "Penghulu"
  },
  {
    "id": "6304152004",
    "district_id": "630415",
    "label": "Bagus",
    "value": "Bagus"
  },
  {
    "id": "6304152005",
    "district_id": "630415",
    "label": "Baliuk",
    "value": "Baliuk"
  },
  {
    "id": "6304152006",
    "district_id": "630415",
    "label": "Antar Baru",
    "value": "Antar Baru"
  },
  {
    "id": "6304152007",
    "district_id": "630415",
    "label": "Antar Jaya",
    "value": "Antar Jaya"
  },
  {
    "id": "6304152008",
    "district_id": "630415",
    "label": "Antar Raya",
    "value": "Antar Raya"
  },
  {
    "id": "6304152009",
    "district_id": "630415",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "6304152010",
    "district_id": "630415",
    "label": "Karya Maju",
    "value": "Karya Maju"
  },
  {
    "id": "6304162001",
    "district_id": "630416",
    "label": "Kolam Kiri",
    "value": "Kolam Kiri"
  },
  {
    "id": "6304162002",
    "district_id": "630416",
    "label": "Roham Raya",
    "value": "Roham Raya"
  },
  {
    "id": "6304162003",
    "district_id": "630416",
    "label": "Simpang Jaya",
    "value": "Simpang Jaya"
  },
  {
    "id": "6304162004",
    "district_id": "630416",
    "label": "Tumih",
    "value": "Tumih"
  },
  {
    "id": "6304162005",
    "district_id": "630416",
    "label": "Pinang Habang",
    "value": "Pinang Habang"
  },
  {
    "id": "6304162006",
    "district_id": "630416",
    "label": "Waringin Kencana",
    "value": "Waringin Kencana"
  },
  {
    "id": "6304162007",
    "district_id": "630416",
    "label": "Babat Raya",
    "value": "Babat Raya"
  },
  {
    "id": "6304162008",
    "district_id": "630416",
    "label": "Kolam Kanan",
    "value": "Kolam Kanan"
  },
  {
    "id": "6304162009",
    "district_id": "630416",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6304162010",
    "district_id": "630416",
    "label": "Kolam Makmur",
    "value": "Kolam Makmur"
  },
  {
    "id": "6304162011",
    "district_id": "630416",
    "label": "Surya Kanta",
    "value": "Surya Kanta"
  },
  {
    "id": "6304162012",
    "district_id": "630416",
    "label": "Sumber Rahayu",
    "value": "Sumber Rahayu"
  },
  {
    "id": "6304162013",
    "district_id": "630416",
    "label": "Dwipasari",
    "value": "Dwipasari"
  },
  {
    "id": "6304172001",
    "district_id": "630417",
    "label": "Sampurna",
    "value": "Sampurna"
  },
  {
    "id": "6304172002",
    "district_id": "630417",
    "label": "Jejangkit Barat",
    "value": "Jejangkit Barat"
  },
  {
    "id": "6304172003",
    "district_id": "630417",
    "label": "Bahandang",
    "value": "Bahandang"
  },
  {
    "id": "6304172004",
    "district_id": "630417",
    "label": "Jejangkit Timur",
    "value": "Jejangkit Timur"
  },
  {
    "id": "6304172005",
    "district_id": "630417",
    "label": "Cahaya Baru",
    "value": "Cahaya Baru"
  },
  {
    "id": "6304172006",
    "district_id": "630417",
    "label": "Jejangkit Pasar",
    "value": "Jejangkit Pasar"
  },
  {
    "id": "6304172007",
    "district_id": "630417",
    "label": "Jejangkit Muara",
    "value": "Jejangkit Muara"
  },
  {
    "id": "6305011001",
    "district_id": "630501",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "6305011017",
    "district_id": "630501",
    "label": "Karang Putih",
    "value": "Karang Putih"
  },
  {
    "id": "6305011018",
    "district_id": "630501",
    "label": "Raya Belanti",
    "value": "Raya Belanti"
  },
  {
    "id": "6305012003",
    "district_id": "630501",
    "label": "Tungkap",
    "value": "Tungkap"
  },
  {
    "id": "6305012006",
    "district_id": "630501",
    "label": "A. Yani Pura",
    "value": "A. Yani Pura"
  },
  {
    "id": "6305012007",
    "district_id": "630501",
    "label": "Pulaupinang",
    "value": "Pulaupinang"
  },
  {
    "id": "6305012008",
    "district_id": "630501",
    "label": "Pualam Sari",
    "value": "Pualam Sari"
  },
  {
    "id": "6305012009",
    "district_id": "630501",
    "label": "Gunungbatu",
    "value": "Gunungbatu"
  },
  {
    "id": "6305012012",
    "district_id": "630501",
    "label": "Pulaupinang Utara",
    "value": "Pulaupinang Utara"
  },
  {
    "id": "6305012016",
    "district_id": "630501",
    "label": "Padang Sari",
    "value": "Padang Sari"
  },
  {
    "id": "6305012019",
    "district_id": "630501",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "6305021004",
    "district_id": "630502",
    "label": "Tambarangan",
    "value": "Tambarangan"
  },
  {
    "id": "6305022001",
    "district_id": "630502",
    "label": "Tatakan",
    "value": "Tatakan"
  },
  {
    "id": "6305022002",
    "district_id": "630502",
    "label": "Suato Tatakan",
    "value": "Suato Tatakan"
  },
  {
    "id": "6305022005",
    "district_id": "630502",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "6305022006",
    "district_id": "630502",
    "label": "Lawahan",
    "value": "Lawahan"
  },
  {
    "id": "6305022007",
    "district_id": "630502",
    "label": "Timbaan",
    "value": "Timbaan"
  },
  {
    "id": "6305022009",
    "district_id": "630502",
    "label": "Rumintin",
    "value": "Rumintin"
  },
  {
    "id": "6305022011",
    "district_id": "630502",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "6305022012",
    "district_id": "630502",
    "label": "Harapan Masa",
    "value": "Harapan Masa"
  },
  {
    "id": "6305022016",
    "district_id": "630502",
    "label": "Tandui",
    "value": "Tandui"
  },
  {
    "id": "6305022017",
    "district_id": "630502",
    "label": "Hatiwin",
    "value": "Hatiwin"
  },
  {
    "id": "6305032001",
    "district_id": "630503",
    "label": "Pandulangan",
    "value": "Pandulangan"
  },
  {
    "id": "6305032002",
    "district_id": "630503",
    "label": "Labung",
    "value": "Labung"
  },
  {
    "id": "6305032003",
    "district_id": "630503",
    "label": "Mandurian",
    "value": "Mandurian"
  },
  {
    "id": "6305032004",
    "district_id": "630503",
    "label": "Serawi",
    "value": "Serawi"
  },
  {
    "id": "6305032005",
    "district_id": "630503",
    "label": "Pematang Karangan Hulu",
    "value": "Pematang Karangan Hulu"
  },
  {
    "id": "6305032006",
    "district_id": "630503",
    "label": "Pematang Karangan",
    "value": "Pematang Karangan"
  },
  {
    "id": "6305032007",
    "district_id": "630503",
    "label": "Pandahan",
    "value": "Pandahan"
  },
  {
    "id": "6305032008",
    "district_id": "630503",
    "label": "Pematang Karangan Hilir",
    "value": "Pematang Karangan Hilir"
  },
  {
    "id": "6305032009",
    "district_id": "630503",
    "label": "Hiyung",
    "value": "Hiyung"
  },
  {
    "id": "6305032010",
    "district_id": "630503",
    "label": "Andhika",
    "value": "Andhika"
  },
  {
    "id": "6305032011",
    "district_id": "630503",
    "label": "Sukaramai",
    "value": "Sukaramai"
  },
  {
    "id": "6305032012",
    "district_id": "630503",
    "label": "Tirik",
    "value": "Tirik"
  },
  {
    "id": "6305032013",
    "district_id": "630503",
    "label": "Kepayang",
    "value": "Kepayang"
  },
  {
    "id": "6305032014",
    "district_id": "630503",
    "label": "Batang Lantik",
    "value": "Batang Lantik"
  },
  {
    "id": "6305032015",
    "district_id": "630503",
    "label": "Mandurian Hilir",
    "value": "Mandurian Hilir"
  },
  {
    "id": "6305032016",
    "district_id": "630503",
    "label": "Sungai Bahalang",
    "value": "Sungai Bahalang"
  },
  {
    "id": "6305032017",
    "district_id": "630503",
    "label": "Papagan Makmur",
    "value": "Papagan Makmur"
  },
  {
    "id": "6305041001",
    "district_id": "630504",
    "label": "Rangda Malingkung",
    "value": "Rangda Malingkung"
  },
  {
    "id": "6305041002",
    "district_id": "630504",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "6305041003",
    "district_id": "630504",
    "label": "Rantau Kanan",
    "value": "Rantau Kanan"
  },
  {
    "id": "6305041004",
    "district_id": "630504",
    "label": "Rantau Kiwa",
    "value": "Rantau Kiwa"
  },
  {
    "id": "6305042005",
    "district_id": "630504",
    "label": "Keramat",
    "value": "Keramat"
  },
  {
    "id": "6305042006",
    "district_id": "630504",
    "label": "Antasari",
    "value": "Antasari"
  },
  {
    "id": "6305042007",
    "district_id": "630504",
    "label": "Jingah Babaris",
    "value": "Jingah Babaris"
  },
  {
    "id": "6305042008",
    "district_id": "630504",
    "label": "Banua Hanyar",
    "value": "Banua Hanyar"
  },
  {
    "id": "6305042009",
    "district_id": "630504",
    "label": "Banua Halat Kiri",
    "value": "Banua Halat Kiri"
  },
  {
    "id": "6305042010",
    "district_id": "630504",
    "label": "Banua Halat Kanan",
    "value": "Banua Halat Kanan"
  },
  {
    "id": "6305042011",
    "district_id": "630504",
    "label": "Perintis Raya",
    "value": "Perintis Raya"
  },
  {
    "id": "6305042012",
    "district_id": "630504",
    "label": "Kakaran",
    "value": "Kakaran"
  },
  {
    "id": "6305042013",
    "district_id": "630504",
    "label": "Antasan Hilir",
    "value": "Antasan Hilir"
  },
  {
    "id": "6305042014",
    "district_id": "630504",
    "label": "Lumbu Raya",
    "value": "Lumbu Raya"
  },
  {
    "id": "6305042015",
    "district_id": "630504",
    "label": "Banua Halar Hulu",
    "value": "Banua Halar Hulu"
  },
  {
    "id": "6305042016",
    "district_id": "630504",
    "label": "Badaun",
    "value": "Badaun"
  },
  {
    "id": "6305052001",
    "district_id": "630505",
    "label": "Margasari Hulu",
    "value": "Margasari Hulu"
  },
  {
    "id": "6305052002",
    "district_id": "630505",
    "label": "Candi Laras",
    "value": "Candi Laras"
  },
  {
    "id": "6305052003",
    "district_id": "630505",
    "label": "Baringin A.",
    "value": "Baringin A."
  },
  {
    "id": "6305052004",
    "district_id": "630505",
    "label": "Marampiau",
    "value": "Marampiau"
  },
  {
    "id": "6305052005",
    "district_id": "630505",
    "label": "Pabaungan Hilir",
    "value": "Pabaungan Hilir"
  },
  {
    "id": "6305052006",
    "district_id": "630505",
    "label": "Pabaungan Hulu",
    "value": "Pabaungan Hulu"
  },
  {
    "id": "6305052007",
    "district_id": "630505",
    "label": "Sungai Rutas",
    "value": "Sungai Rutas"
  },
  {
    "id": "6305052008",
    "district_id": "630505",
    "label": "Baringin B",
    "value": "Baringin B"
  },
  {
    "id": "6305052009",
    "district_id": "630505",
    "label": "Marampiau Hilir",
    "value": "Marampiau Hilir"
  },
  {
    "id": "6305052010",
    "district_id": "630505",
    "label": "Sungai Rutas Hulu",
    "value": "Sungai Rutas Hulu"
  },
  {
    "id": "6305052011",
    "district_id": "630505",
    "label": "Baulin",
    "value": "Baulin"
  },
  {
    "id": "6305052012",
    "district_id": "630505",
    "label": "Pabaungan Pantai",
    "value": "Pabaungan Pantai"
  },
  {
    "id": "6305062001",
    "district_id": "630506",
    "label": "Keladan",
    "value": "Keladan"
  },
  {
    "id": "6305062002",
    "district_id": "630506",
    "label": "Sungai Selai",
    "value": "Sungai Selai"
  },
  {
    "id": "6305062003",
    "district_id": "630506",
    "label": "Pariok",
    "value": "Pariok"
  },
  {
    "id": "6305062004",
    "district_id": "630506",
    "label": "Margasari Hilir",
    "value": "Margasari Hilir"
  },
  {
    "id": "6305062005",
    "district_id": "630506",
    "label": "Batalas",
    "value": "Batalas"
  },
  {
    "id": "6305062006",
    "district_id": "630506",
    "label": "Rawana",
    "value": "Rawana"
  },
  {
    "id": "6305062007",
    "district_id": "630506",
    "label": "Buas-buas",
    "value": "Buas-buas"
  },
  {
    "id": "6305062008",
    "district_id": "630506",
    "label": "Teluk Haur",
    "value": "Teluk Haur"
  },
  {
    "id": "6305062009",
    "district_id": "630506",
    "label": "Sungai Puting",
    "value": "Sungai Puting"
  },
  {
    "id": "6305062010",
    "district_id": "630506",
    "label": "Sawaja",
    "value": "Sawaja"
  },
  {
    "id": "6305062011",
    "district_id": "630506",
    "label": "Sungai Selai Hilir",
    "value": "Sungai Selai Hilir"
  },
  {
    "id": "6305062012",
    "district_id": "630506",
    "label": "Buas-buas Hilir",
    "value": "Buas-buas Hilir"
  },
  {
    "id": "6305062013",
    "district_id": "630506",
    "label": "Rawana Hulu",
    "value": "Rawana Hulu"
  },
  {
    "id": "6305072001",
    "district_id": "630507",
    "label": "Parigi Kecil",
    "value": "Parigi Kecil"
  },
  {
    "id": "6305072002",
    "district_id": "630507",
    "label": "Bakarangan",
    "value": "Bakarangan"
  },
  {
    "id": "6305072003",
    "district_id": "630507",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "6305072004",
    "district_id": "630507",
    "label": "Paul",
    "value": "Paul"
  },
  {
    "id": "6305072005",
    "district_id": "630507",
    "label": "Gadung",
    "value": "Gadung"
  },
  {
    "id": "6305072006",
    "district_id": "630507",
    "label": "Bundung",
    "value": "Bundung"
  },
  {
    "id": "6305072007",
    "district_id": "630507",
    "label": "Tangkawang",
    "value": "Tangkawang"
  },
  {
    "id": "6305072008",
    "district_id": "630507",
    "label": "Waringin",
    "value": "Waringin"
  },
  {
    "id": "6305072009",
    "district_id": "630507",
    "label": "Gadung Keramat",
    "value": "Gadung Keramat"
  },
  {
    "id": "6305072010",
    "district_id": "630507",
    "label": "Masta",
    "value": "Masta"
  },
  {
    "id": "6305072011",
    "district_id": "630507",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "6305072012",
    "district_id": "630507",
    "label": "Tangkawang Baru",
    "value": "Tangkawang Baru"
  },
  {
    "id": "6305082001",
    "district_id": "630508",
    "label": "Pipitak Jaya",
    "value": "Pipitak Jaya"
  },
  {
    "id": "6305082002",
    "district_id": "630508",
    "label": "Miawa",
    "value": "Miawa"
  },
  {
    "id": "6305082003",
    "district_id": "630508",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6305082004",
    "district_id": "630508",
    "label": "Harakit",
    "value": "Harakit"
  },
  {
    "id": "6305082005",
    "district_id": "630508",
    "label": "Batung",
    "value": "Batung"
  },
  {
    "id": "6305082006",
    "district_id": "630508",
    "label": "Balawaian",
    "value": "Balawaian"
  },
  {
    "id": "6305082007",
    "district_id": "630508",
    "label": "Baramban",
    "value": "Baramban"
  },
  {
    "id": "6305082008",
    "district_id": "630508",
    "label": "Buniin Jaya",
    "value": "Buniin Jaya"
  },
  {
    "id": "6305092001",
    "district_id": "630509",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "6305092002",
    "district_id": "630509",
    "label": "Banua Padang",
    "value": "Banua Padang"
  },
  {
    "id": "6305092003",
    "district_id": "630509",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "6305092004",
    "district_id": "630509",
    "label": "Banua Padang Hilir",
    "value": "Banua Padang Hilir"
  },
  {
    "id": "6305092005",
    "district_id": "630509",
    "label": "Shabah",
    "value": "Shabah"
  },
  {
    "id": "6305092006",
    "district_id": "630509",
    "label": "Hangui",
    "value": "Hangui"
  },
  {
    "id": "6305092007",
    "district_id": "630509",
    "label": "Rantau Bujur",
    "value": "Rantau Bujur"
  },
  {
    "id": "6305092008",
    "district_id": "630509",
    "label": "Purut",
    "value": "Purut"
  },
  {
    "id": "6305092009",
    "district_id": "630509",
    "label": "Bungur Baru",
    "value": "Bungur Baru"
  },
  {
    "id": "6305092010",
    "district_id": "630509",
    "label": "Timbung",
    "value": "Timbung"
  },
  {
    "id": "6305092011",
    "district_id": "630509",
    "label": "Paring Guling",
    "value": "Paring Guling"
  },
  {
    "id": "6305092012",
    "district_id": "630509",
    "label": "Linuh",
    "value": "Linuh"
  },
  {
    "id": "6305101007",
    "district_id": "630510",
    "label": "Bitahan",
    "value": "Bitahan"
  },
  {
    "id": "6305102001",
    "district_id": "630510",
    "label": "Binderang",
    "value": "Binderang"
  },
  {
    "id": "6305102002",
    "district_id": "630510",
    "label": "Parandakan",
    "value": "Parandakan"
  },
  {
    "id": "6305102003",
    "district_id": "630510",
    "label": "Lokpaikat",
    "value": "Lokpaikat"
  },
  {
    "id": "6305102004",
    "district_id": "630510",
    "label": "Bataratat",
    "value": "Bataratat"
  },
  {
    "id": "6305102005",
    "district_id": "630510",
    "label": "Bitahan Baru",
    "value": "Bitahan Baru"
  },
  {
    "id": "6305102006",
    "district_id": "630510",
    "label": "Puncak Harapan",
    "value": "Puncak Harapan"
  },
  {
    "id": "6305102008",
    "district_id": "630510",
    "label": "Budi Mulya",
    "value": "Budi Mulya"
  },
  {
    "id": "6305102009",
    "district_id": "630510",
    "label": "Ayunan Papan",
    "value": "Ayunan Papan"
  },
  {
    "id": "6305112001",
    "district_id": "630511",
    "label": "Salam Babaris",
    "value": "Salam Babaris"
  },
  {
    "id": "6305112002",
    "district_id": "630511",
    "label": "Suato Lama",
    "value": "Suato Lama"
  },
  {
    "id": "6305112003",
    "district_id": "630511",
    "label": "Kambang Habang Lama",
    "value": "Kambang Habang Lama"
  },
  {
    "id": "6305112004",
    "district_id": "630511",
    "label": "Pantai Cabe",
    "value": "Pantai Cabe"
  },
  {
    "id": "6305112005",
    "district_id": "630511",
    "label": "Suato Baru",
    "value": "Suato Baru"
  },
  {
    "id": "6305112006",
    "district_id": "630511",
    "label": "Kambang Habang Baru",
    "value": "Kambang Habang Baru"
  },
  {
    "id": "6305122001",
    "district_id": "630512",
    "label": "Tarungin",
    "value": "Tarungin"
  },
  {
    "id": "6305122002",
    "district_id": "630512",
    "label": "Matang Batas",
    "value": "Matang Batas"
  },
  {
    "id": "6305122003",
    "district_id": "630512",
    "label": "Hatungun",
    "value": "Hatungun"
  },
  {
    "id": "6305122004",
    "district_id": "630512",
    "label": "Burakai",
    "value": "Burakai"
  },
  {
    "id": "6305122005",
    "district_id": "630512",
    "label": "Batu Hapu",
    "value": "Batu Hapu"
  },
  {
    "id": "6305122006",
    "district_id": "630512",
    "label": "Kambang Kuning",
    "value": "Kambang Kuning"
  },
  {
    "id": "6305122007",
    "district_id": "630512",
    "label": "Asam Randah",
    "value": "Asam Randah"
  },
  {
    "id": "6305122008",
    "district_id": "630512",
    "label": "Bagak",
    "value": "Bagak"
  },
  {
    "id": "6306012001",
    "district_id": "630601",
    "label": "Hamalau",
    "value": "Hamalau"
  },
  {
    "id": "6306012002",
    "district_id": "630601",
    "label": "Telaga Bidadari",
    "value": "Telaga Bidadari"
  },
  {
    "id": "6306012003",
    "district_id": "630601",
    "label": "Karasikan",
    "value": "Karasikan"
  },
  {
    "id": "6306012004",
    "district_id": "630601",
    "label": "Sungai Raya Utara",
    "value": "Sungai Raya Utara"
  },
  {
    "id": "6306012005",
    "district_id": "630601",
    "label": "Sungai Raya Selatan",
    "value": "Sungai Raya Selatan"
  },
  {
    "id": "6306012006",
    "district_id": "630601",
    "label": "Hariti",
    "value": "Hariti"
  },
  {
    "id": "6306012007",
    "district_id": "630601",
    "label": "Ida Manggala",
    "value": "Ida Manggala"
  },
  {
    "id": "6306012008",
    "district_id": "630601",
    "label": "Bumi Berkat",
    "value": "Bumi Berkat"
  },
  {
    "id": "6306012009",
    "district_id": "630601",
    "label": "Batang Kulur Kiri",
    "value": "Batang Kulur Kiri"
  },
  {
    "id": "6306012010",
    "district_id": "630601",
    "label": "Batang Kulur Tengah",
    "value": "Batang Kulur Tengah"
  },
  {
    "id": "6306012011",
    "district_id": "630601",
    "label": "Batang Kulur Kanan",
    "value": "Batang Kulur Kanan"
  },
  {
    "id": "6306012012",
    "district_id": "630601",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6306012013",
    "district_id": "630601",
    "label": "Tamiyang",
    "value": "Tamiyang"
  },
  {
    "id": "6306012014",
    "district_id": "630601",
    "label": "Asam",
    "value": "Asam"
  },
  {
    "id": "6306012015",
    "district_id": "630601",
    "label": "Sungai Kali",
    "value": "Sungai Kali"
  },
  {
    "id": "6306012016",
    "district_id": "630601",
    "label": "Sarang Halang",
    "value": "Sarang Halang"
  },
  {
    "id": "6306012017",
    "district_id": "630601",
    "label": "Paring Agung",
    "value": "Paring Agung"
  },
  {
    "id": "6306012018",
    "district_id": "630601",
    "label": "Tanah Bangkang",
    "value": "Tanah Bangkang"
  },
  {
    "id": "6306022001",
    "district_id": "630602",
    "label": "Karang Jawa Muka",
    "value": "Karang Jawa Muka"
  },
  {
    "id": "6306022002",
    "district_id": "630602",
    "label": "Karang Jawa",
    "value": "Karang Jawa"
  },
  {
    "id": "6306022003",
    "district_id": "630602",
    "label": "Tabihi",
    "value": "Tabihi"
  },
  {
    "id": "6306022004",
    "district_id": "630602",
    "label": "Pandulangan",
    "value": "Pandulangan"
  },
  {
    "id": "6306022005",
    "district_id": "630602",
    "label": "Kaliring",
    "value": "Kaliring"
  },
  {
    "id": "6306022006",
    "district_id": "630602",
    "label": "Jambu Hulu",
    "value": "Jambu Hulu"
  },
  {
    "id": "6306022007",
    "district_id": "630602",
    "label": "Pahampangan",
    "value": "Pahampangan"
  },
  {
    "id": "6306022008",
    "district_id": "630602",
    "label": "Padang Batung",
    "value": "Padang Batung"
  },
  {
    "id": "6306022009",
    "district_id": "630602",
    "label": "Jembatan Merah",
    "value": "Jembatan Merah"
  },
  {
    "id": "6306022010",
    "district_id": "630602",
    "label": "Batu Bini",
    "value": "Batu Bini"
  },
  {
    "id": "6306022011",
    "district_id": "630602",
    "label": "Mawangi",
    "value": "Mawangi"
  },
  {
    "id": "6306022012",
    "district_id": "630602",
    "label": "Madang",
    "value": "Madang"
  },
  {
    "id": "6306022013",
    "district_id": "630602",
    "label": "Durian Rabung",
    "value": "Durian Rabung"
  },
  {
    "id": "6306022014",
    "district_id": "630602",
    "label": "Jelatang",
    "value": "Jelatang"
  },
  {
    "id": "6306022015",
    "district_id": "630602",
    "label": "Batu Laki",
    "value": "Batu Laki"
  },
  {
    "id": "6306022016",
    "district_id": "630602",
    "label": "Malutu",
    "value": "Malutu"
  },
  {
    "id": "6306022017",
    "district_id": "630602",
    "label": "Malilingin",
    "value": "Malilingin"
  },
  {
    "id": "6306032001",
    "district_id": "630603",
    "label": "Lok Binuang",
    "value": "Lok Binuang"
  },
  {
    "id": "6306032002",
    "district_id": "630603",
    "label": "Telaga Langsat",
    "value": "Telaga Langsat"
  },
  {
    "id": "6306032003",
    "district_id": "630603",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "6306032004",
    "district_id": "630603",
    "label": "Ambutun",
    "value": "Ambutun"
  },
  {
    "id": "6306032005",
    "district_id": "630603",
    "label": "Hamak",
    "value": "Hamak"
  },
  {
    "id": "6306032006",
    "district_id": "630603",
    "label": "Hamak Timur",
    "value": "Hamak Timur"
  },
  {
    "id": "6306032007",
    "district_id": "630603",
    "label": "Hamak Utara",
    "value": "Hamak Utara"
  },
  {
    "id": "6306032008",
    "district_id": "630603",
    "label": "Pakuan Timur",
    "value": "Pakuan Timur"
  },
  {
    "id": "6306032009",
    "district_id": "630603",
    "label": "Gumbil",
    "value": "Gumbil"
  },
  {
    "id": "6306032010",
    "district_id": "630603",
    "label": "Longawang",
    "value": "Longawang"
  },
  {
    "id": "6306032011",
    "district_id": "630603",
    "label": "Pandulangan",
    "value": "Pandulangan"
  },
  {
    "id": "6306042001",
    "district_id": "630604",
    "label": "Bamban Utara",
    "value": "Bamban Utara"
  },
  {
    "id": "6306042002",
    "district_id": "630604",
    "label": "Bamban",
    "value": "Bamban"
  },
  {
    "id": "6306042003",
    "district_id": "630604",
    "label": "Bamban Selatan",
    "value": "Bamban Selatan"
  },
  {
    "id": "6306042004",
    "district_id": "630604",
    "label": "Kayu Abang",
    "value": "Kayu Abang"
  },
  {
    "id": "6306042005",
    "district_id": "630604",
    "label": "Angkinang",
    "value": "Angkinang"
  },
  {
    "id": "6306042006",
    "district_id": "630604",
    "label": "Telaga Sili-Sili",
    "value": "Telaga Sili-Sili"
  },
  {
    "id": "6306042007",
    "district_id": "630604",
    "label": "Angkinang Selatan",
    "value": "Angkinang Selatan"
  },
  {
    "id": "6306042008",
    "district_id": "630604",
    "label": "Tawia",
    "value": "Tawia"
  },
  {
    "id": "6306042009",
    "district_id": "630604",
    "label": "Taniran Kubah",
    "value": "Taniran Kubah"
  },
  {
    "id": "6306042010",
    "district_id": "630604",
    "label": "Taniran Selatan",
    "value": "Taniran Selatan"
  },
  {
    "id": "6306042011",
    "district_id": "630604",
    "label": "Bakarung",
    "value": "Bakarung"
  },
  {
    "id": "6306051001",
    "district_id": "630605",
    "label": "Kandangan Kota",
    "value": "Kandangan Kota"
  },
  {
    "id": "6306051002",
    "district_id": "630605",
    "label": "Kandangan Utara",
    "value": "Kandangan Utara"
  },
  {
    "id": "6306051003",
    "district_id": "630605",
    "label": "Kandangan Barat",
    "value": "Kandangan Barat"
  },
  {
    "id": "6306051004",
    "district_id": "630605",
    "label": "Jambu Hilir",
    "value": "Jambu Hilir"
  },
  {
    "id": "6306052005",
    "district_id": "630605",
    "label": "Gambah Luar",
    "value": "Gambah Luar"
  },
  {
    "id": "6306052006",
    "district_id": "630605",
    "label": "Gambah Luar Muka",
    "value": "Gambah Luar Muka"
  },
  {
    "id": "6306052007",
    "district_id": "630605",
    "label": "Gambah Dalam",
    "value": "Gambah Dalam"
  },
  {
    "id": "6306052008",
    "district_id": "630605",
    "label": "Gambah Dalam Barat",
    "value": "Gambah Dalam Barat"
  },
  {
    "id": "6306052009",
    "district_id": "630605",
    "label": "Sungai Kupang",
    "value": "Sungai Kupang"
  },
  {
    "id": "6306052010",
    "district_id": "630605",
    "label": "Bangkau",
    "value": "Bangkau"
  },
  {
    "id": "6306052011",
    "district_id": "630605",
    "label": "Lungau",
    "value": "Lungau"
  },
  {
    "id": "6306052012",
    "district_id": "630605",
    "label": "Sungai Paring",
    "value": "Sungai Paring"
  },
  {
    "id": "6306052013",
    "district_id": "630605",
    "label": "Bariang",
    "value": "Bariang"
  },
  {
    "id": "6306052014",
    "district_id": "630605",
    "label": "Amawang Kiri",
    "value": "Amawang Kiri"
  },
  {
    "id": "6306052015",
    "district_id": "630605",
    "label": "Amawang Kiri Muka",
    "value": "Amawang Kiri Muka"
  },
  {
    "id": "6306052016",
    "district_id": "630605",
    "label": "Amawang Kanan",
    "value": "Amawang Kanan"
  },
  {
    "id": "6306052017",
    "district_id": "630605",
    "label": "Tibung Raya",
    "value": "Tibung Raya"
  },
  {
    "id": "6306052018",
    "district_id": "630605",
    "label": "Baluti",
    "value": "Baluti"
  },
  {
    "id": "6306062001",
    "district_id": "630606",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "6306062002",
    "district_id": "630606",
    "label": "Simpur",
    "value": "Simpur"
  },
  {
    "id": "6306062003",
    "district_id": "630606",
    "label": "Garunggang",
    "value": "Garunggang"
  },
  {
    "id": "6306062004",
    "district_id": "630606",
    "label": "Amparaya",
    "value": "Amparaya"
  },
  {
    "id": "6306062005",
    "district_id": "630606",
    "label": "Panjampang Bahagia",
    "value": "Panjampang Bahagia"
  },
  {
    "id": "6306062006",
    "district_id": "630606",
    "label": "Ulin",
    "value": "Ulin"
  },
  {
    "id": "6306062007",
    "district_id": "630606",
    "label": "Pantai Ulin",
    "value": "Pantai Ulin"
  },
  {
    "id": "6306062008",
    "district_id": "630606",
    "label": "Wasah Hulu",
    "value": "Wasah Hulu"
  },
  {
    "id": "6306062009",
    "district_id": "630606",
    "label": "Wasah Tengah",
    "value": "Wasah Tengah"
  },
  {
    "id": "6306062010",
    "district_id": "630606",
    "label": "Wasah Hilir",
    "value": "Wasah Hilir"
  },
  {
    "id": "6306062011",
    "district_id": "630606",
    "label": "Kapuh",
    "value": "Kapuh"
  },
  {
    "id": "6306072001",
    "district_id": "630607",
    "label": "Muning Baru",
    "value": "Muning Baru"
  },
  {
    "id": "6306072002",
    "district_id": "630607",
    "label": "Muning Dalam",
    "value": "Muning Dalam"
  },
  {
    "id": "6306072003",
    "district_id": "630607",
    "label": "Muning Tengah",
    "value": "Muning Tengah"
  },
  {
    "id": "6306072004",
    "district_id": "630607",
    "label": "Banjarbaru",
    "value": "Banjarbaru"
  },
  {
    "id": "6306072005",
    "district_id": "630607",
    "label": "Bayanan",
    "value": "Bayanan"
  },
  {
    "id": "6306072006",
    "district_id": "630607",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "6306072007",
    "district_id": "630607",
    "label": "Pihanin Raya",
    "value": "Pihanin Raya"
  },
  {
    "id": "6306072008",
    "district_id": "630607",
    "label": "Tumbukan Banyu",
    "value": "Tumbukan Banyu"
  },
  {
    "id": "6306072009",
    "district_id": "630607",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "6306072010",
    "district_id": "630607",
    "label": "Habirau",
    "value": "Habirau"
  },
  {
    "id": "6306072011",
    "district_id": "630607",
    "label": "Habirau Tengah",
    "value": "Habirau Tengah"
  },
  {
    "id": "6306072012",
    "district_id": "630607",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "6306072013",
    "district_id": "630607",
    "label": "Banua Hanyar",
    "value": "Banua Hanyar"
  },
  {
    "id": "6306072014",
    "district_id": "630607",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "6306072015",
    "district_id": "630607",
    "label": "Baruh Jaya",
    "value": "Baruh Jaya"
  },
  {
    "id": "6306072016",
    "district_id": "630607",
    "label": "Samuda",
    "value": "Samuda"
  },
  {
    "id": "6306082001",
    "district_id": "630608",
    "label": "Pakapuran Kecil",
    "value": "Pakapuran Kecil"
  },
  {
    "id": "6306082002",
    "district_id": "630608",
    "label": "Panggandingan",
    "value": "Panggandingan"
  },
  {
    "id": "6306082003",
    "district_id": "630608",
    "label": "Tambak Bitin",
    "value": "Tambak Bitin"
  },
  {
    "id": "6306082004",
    "district_id": "630608",
    "label": "Pakan Dalam",
    "value": "Pakan Dalam"
  },
  {
    "id": "6306082005",
    "district_id": "630608",
    "label": "Paramaian",
    "value": "Paramaian"
  },
  {
    "id": "6306082006",
    "district_id": "630608",
    "label": "Pandak Daun",
    "value": "Pandak Daun"
  },
  {
    "id": "6306082007",
    "district_id": "630608",
    "label": "Murung Raya",
    "value": "Murung Raya"
  },
  {
    "id": "6306082008",
    "district_id": "630608",
    "label": "Balah Paikat",
    "value": "Balah Paikat"
  },
  {
    "id": "6306082009",
    "district_id": "630608",
    "label": "Sungai Garuda",
    "value": "Sungai Garuda"
  },
  {
    "id": "6306082010",
    "district_id": "630608",
    "label": "Sungai Mandala",
    "value": "Sungai Mandala"
  },
  {
    "id": "6306082011",
    "district_id": "630608",
    "label": "Mdl. Murung Mesjid",
    "value": "Mdl. Murung Mesjid"
  },
  {
    "id": "6306082012",
    "district_id": "630608",
    "label": "Baruh Kembang",
    "value": "Baruh Kembang"
  },
  {
    "id": "6306082013",
    "district_id": "630608",
    "label": "Teluk Haur",
    "value": "Teluk Haur"
  },
  {
    "id": "6306082014",
    "district_id": "630608",
    "label": "Pasungkan",
    "value": "Pasungkan"
  },
  {
    "id": "6306082015",
    "district_id": "630608",
    "label": "Teluk Kabak",
    "value": "Teluk Kabak"
  },
  {
    "id": "6306082016",
    "district_id": "630608",
    "label": "Hamayung",
    "value": "Hamayung"
  },
  {
    "id": "6306082017",
    "district_id": "630608",
    "label": "Hamayung Utara",
    "value": "Hamayung Utara"
  },
  {
    "id": "6306082018",
    "district_id": "630608",
    "label": "Paharangan",
    "value": "Paharangan"
  },
  {
    "id": "6306082019",
    "district_id": "630608",
    "label": "Hakurung",
    "value": "Hakurung"
  },
  {
    "id": "6306092001",
    "district_id": "630609",
    "label": "Balimau",
    "value": "Balimau"
  },
  {
    "id": "6306092002",
    "district_id": "630609",
    "label": "Karang Paci",
    "value": "Karang Paci"
  },
  {
    "id": "6306092003",
    "district_id": "630609",
    "label": "Bago Tanggul",
    "value": "Bago Tanggul"
  },
  {
    "id": "6306092004",
    "district_id": "630609",
    "label": "Karang Bulan",
    "value": "Karang Bulan"
  },
  {
    "id": "6306092005",
    "district_id": "630609",
    "label": "Balanti",
    "value": "Balanti"
  },
  {
    "id": "6306092006",
    "district_id": "630609",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "6306092007",
    "district_id": "630609",
    "label": "Tambingkar",
    "value": "Tambingkar"
  },
  {
    "id": "6306092008",
    "district_id": "630609",
    "label": "Sirih",
    "value": "Sirih"
  },
  {
    "id": "6306092009",
    "district_id": "630609",
    "label": "Sirih Hulu",
    "value": "Sirih Hulu"
  },
  {
    "id": "6306102001",
    "district_id": "630610",
    "label": "Halunuk",
    "value": "Halunuk"
  },
  {
    "id": "6306102002",
    "district_id": "630610",
    "label": "Panggungan",
    "value": "Panggungan"
  },
  {
    "id": "6306102003",
    "district_id": "630610",
    "label": "Lumpangi",
    "value": "Lumpangi"
  },
  {
    "id": "6306102004",
    "district_id": "630610",
    "label": "Malinau",
    "value": "Malinau"
  },
  {
    "id": "6306102005",
    "district_id": "630610",
    "label": "Hulu Banyu",
    "value": "Hulu Banyu"
  },
  {
    "id": "6306102006",
    "district_id": "630610",
    "label": "Tumingki",
    "value": "Tumingki"
  },
  {
    "id": "6306102007",
    "district_id": "630610",
    "label": "Kamawakan",
    "value": "Kamawakan"
  },
  {
    "id": "6306102008",
    "district_id": "630610",
    "label": "Lok Lahung",
    "value": "Lok Lahung"
  },
  {
    "id": "6306102009",
    "district_id": "630610",
    "label": "Loksado",
    "value": "Loksado"
  },
  {
    "id": "6306102010",
    "district_id": "630610",
    "label": "Haratai",
    "value": "Haratai"
  },
  {
    "id": "6306102011",
    "district_id": "630610",
    "label": "Ulang",
    "value": "Ulang"
  },
  {
    "id": "6306112001",
    "district_id": "630611",
    "label": "Siang Gantung",
    "value": "Siang Gantung"
  },
  {
    "id": "6306112002",
    "district_id": "630611",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6306112003",
    "district_id": "630611",
    "label": "Tanjung Selor",
    "value": "Tanjung Selor"
  },
  {
    "id": "6306112004",
    "district_id": "630611",
    "label": "Badaun",
    "value": "Badaun"
  },
  {
    "id": "6306112005",
    "district_id": "630611",
    "label": "Bajayau",
    "value": "Bajayau"
  },
  {
    "id": "6306112006",
    "district_id": "630611",
    "label": "Bajayau Tengah",
    "value": "Bajayau Tengah"
  },
  {
    "id": "6306112007",
    "district_id": "630611",
    "label": "Bajayau Lama",
    "value": "Bajayau Lama"
  },
  {
    "id": "6307012001",
    "district_id": "630701",
    "label": "Pengambau Hilir Luar",
    "value": "Pengambau Hilir Luar"
  },
  {
    "id": "6307012002",
    "district_id": "630701",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "6307012003",
    "district_id": "630701",
    "label": "Barikin",
    "value": "Barikin"
  },
  {
    "id": "6307012004",
    "district_id": "630701",
    "label": "Andang",
    "value": "Andang"
  },
  {
    "id": "6307012005",
    "district_id": "630701",
    "label": "Pengambau Hilir Dalam",
    "value": "Pengambau Hilir Dalam"
  },
  {
    "id": "6307012006",
    "district_id": "630701",
    "label": "Haruyan",
    "value": "Haruyan"
  },
  {
    "id": "6307012007",
    "district_id": "630701",
    "label": "Haruyan Seberang",
    "value": "Haruyan Seberang"
  },
  {
    "id": "6307012008",
    "district_id": "630701",
    "label": "Pengambau Hulu",
    "value": "Pengambau Hulu"
  },
  {
    "id": "6307012009",
    "district_id": "630701",
    "label": "Sungai Harang",
    "value": "Sungai Harang"
  },
  {
    "id": "6307012010",
    "district_id": "630701",
    "label": "Batu Panggung",
    "value": "Batu Panggung"
  },
  {
    "id": "6307012011",
    "district_id": "630701",
    "label": "Mangunang",
    "value": "Mangunang"
  },
  {
    "id": "6307012012",
    "district_id": "630701",
    "label": "Hapulang",
    "value": "Hapulang"
  },
  {
    "id": "6307012013",
    "district_id": "630701",
    "label": "Lok Buntar",
    "value": "Lok Buntar"
  },
  {
    "id": "6307012014",
    "district_id": "630701",
    "label": "Tabat Padang",
    "value": "Tabat Padang"
  },
  {
    "id": "6307012015",
    "district_id": "630701",
    "label": "Mangunang Seberang",
    "value": "Mangunang Seberang"
  },
  {
    "id": "6307012016",
    "district_id": "630701",
    "label": "Pandanu",
    "value": "Pandanu"
  },
  {
    "id": "6307012017",
    "district_id": "630701",
    "label": "Teluk Mesjid",
    "value": "Teluk Mesjid"
  },
  {
    "id": "6307022001",
    "district_id": "630702",
    "label": "Murung A.",
    "value": "Murung A."
  },
  {
    "id": "6307022002",
    "district_id": "630702",
    "label": "Pagat",
    "value": "Pagat"
  },
  {
    "id": "6307022003",
    "district_id": "630702",
    "label": "Kalibaru",
    "value": "Kalibaru"
  },
  {
    "id": "6307022004",
    "district_id": "630702",
    "label": "Kahakan",
    "value": "Kahakan"
  },
  {
    "id": "6307022005",
    "district_id": "630702",
    "label": "Aluan Sumur",
    "value": "Aluan Sumur"
  },
  {
    "id": "6307022006",
    "district_id": "630702",
    "label": "Aluan Besar",
    "value": "Aluan Besar"
  },
  {
    "id": "6307022007",
    "district_id": "630702",
    "label": "Paya Besar",
    "value": "Paya Besar"
  },
  {
    "id": "6307022008",
    "district_id": "630702",
    "label": "Bakti",
    "value": "Bakti"
  },
  {
    "id": "6307022009",
    "district_id": "630702",
    "label": "Aluan",
    "value": "Aluan"
  },
  {
    "id": "6307022010",
    "district_id": "630702",
    "label": "Aluan Mati",
    "value": "Aluan Mati"
  },
  {
    "id": "6307022011",
    "district_id": "630702",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6307022013",
    "district_id": "630702",
    "label": "Pantai Batung",
    "value": "Pantai Batung"
  },
  {
    "id": "6307022014",
    "district_id": "630702",
    "label": "Layuh",
    "value": "Layuh"
  },
  {
    "id": "6307022015",
    "district_id": "630702",
    "label": "Haliau",
    "value": "Haliau"
  },
  {
    "id": "6307031001",
    "district_id": "630703",
    "label": "Pantai Hambawang Barat",
    "value": "Pantai Hambawang Barat"
  },
  {
    "id": "6307032002",
    "district_id": "630703",
    "label": "Mahang Baru",
    "value": "Mahang Baru"
  },
  {
    "id": "6307032003",
    "district_id": "630703",
    "label": "Mundar",
    "value": "Mundar"
  },
  {
    "id": "6307032004",
    "district_id": "630703",
    "label": "Tabudarat Hilir",
    "value": "Tabudarat Hilir"
  },
  {
    "id": "6307032005",
    "district_id": "630703",
    "label": "Tabudarat Hulu",
    "value": "Tabudarat Hulu"
  },
  {
    "id": "6307032006",
    "district_id": "630703",
    "label": "Pantai Hambawang Timur",
    "value": "Pantai Hambawang Timur"
  },
  {
    "id": "6307032007",
    "district_id": "630703",
    "label": "Banua Kepayang",
    "value": "Banua Kepayang"
  },
  {
    "id": "6307032008",
    "district_id": "630703",
    "label": "Ta'al",
    "value": "Ta'al"
  },
  {
    "id": "6307032009",
    "district_id": "630703",
    "label": "Durian Gantang",
    "value": "Durian Gantang"
  },
  {
    "id": "6307032010",
    "district_id": "630703",
    "label": "Guha",
    "value": "Guha"
  },
  {
    "id": "6307032011",
    "district_id": "630703",
    "label": "Bangkal",
    "value": "Bangkal"
  },
  {
    "id": "6307032012",
    "district_id": "630703",
    "label": "Panggang Marak",
    "value": "Panggang Marak"
  },
  {
    "id": "6307032013",
    "district_id": "630703",
    "label": "Jamil",
    "value": "Jamil"
  },
  {
    "id": "6307032014",
    "district_id": "630703",
    "label": "Taras Padang",
    "value": "Taras Padang"
  },
  {
    "id": "6307032015",
    "district_id": "630703",
    "label": "Murung Ta'al",
    "value": "Murung Ta'al"
  },
  {
    "id": "6307032016",
    "district_id": "630703",
    "label": "Sungai Rangas",
    "value": "Sungai Rangas"
  },
  {
    "id": "6307032017",
    "district_id": "630703",
    "label": "Batang Bahalang",
    "value": "Batang Bahalang"
  },
  {
    "id": "6307032018",
    "district_id": "630703",
    "label": "Sungai Jaranih",
    "value": "Sungai Jaranih"
  },
  {
    "id": "6307042001",
    "district_id": "630704",
    "label": "Parumahan",
    "value": "Parumahan"
  },
  {
    "id": "6307042002",
    "district_id": "630704",
    "label": "Kasarangan",
    "value": "Kasarangan"
  },
  {
    "id": "6307042003",
    "district_id": "630704",
    "label": "Banua Kupang",
    "value": "Banua Kupang"
  },
  {
    "id": "6307042004",
    "district_id": "630704",
    "label": "Rantau Keminting",
    "value": "Rantau Keminting"
  },
  {
    "id": "6307042005",
    "district_id": "630704",
    "label": "Pemangkih",
    "value": "Pemangkih"
  },
  {
    "id": "6307042006",
    "district_id": "630704",
    "label": "Pemangkih Seberang",
    "value": "Pemangkih Seberang"
  },
  {
    "id": "6307042007",
    "district_id": "630704",
    "label": "Binjai Pemangkih",
    "value": "Binjai Pemangkih"
  },
  {
    "id": "6307042008",
    "district_id": "630704",
    "label": "Samhurang",
    "value": "Samhurang"
  },
  {
    "id": "6307042009",
    "district_id": "630704",
    "label": "Pahalatan",
    "value": "Pahalatan"
  },
  {
    "id": "6307042010",
    "district_id": "630704",
    "label": "Mantaas",
    "value": "Mantaas"
  },
  {
    "id": "6307042011",
    "district_id": "630704",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "6307042012",
    "district_id": "630704",
    "label": "Kadundung",
    "value": "Kadundung"
  },
  {
    "id": "6307042013",
    "district_id": "630704",
    "label": "Tungkup",
    "value": "Tungkup"
  },
  {
    "id": "6307042014",
    "district_id": "630704",
    "label": "Tabat",
    "value": "Tabat"
  },
  {
    "id": "6307042015",
    "district_id": "630704",
    "label": "Rantau Bujur",
    "value": "Rantau Bujur"
  },
  {
    "id": "6307042016",
    "district_id": "630704",
    "label": "Binjai Pirua",
    "value": "Binjai Pirua"
  },
  {
    "id": "6307052001",
    "district_id": "630705",
    "label": "Jaranih",
    "value": "Jaranih"
  },
  {
    "id": "6307052002",
    "district_id": "630705",
    "label": "Banua Hanyar",
    "value": "Banua Hanyar"
  },
  {
    "id": "6307052003",
    "district_id": "630705",
    "label": "Palajau",
    "value": "Palajau"
  },
  {
    "id": "6307052004",
    "district_id": "630705",
    "label": "Banua Batung",
    "value": "Banua Batung"
  },
  {
    "id": "6307052005",
    "district_id": "630705",
    "label": "Jatuh",
    "value": "Jatuh"
  },
  {
    "id": "6307052006",
    "district_id": "630705",
    "label": "Pandawan",
    "value": "Pandawan"
  },
  {
    "id": "6307052007",
    "district_id": "630705",
    "label": "Mahang Matang Landung",
    "value": "Mahang Matang Landung"
  },
  {
    "id": "6307052008",
    "district_id": "630705",
    "label": "Kambat Selatan",
    "value": "Kambat Selatan"
  },
  {
    "id": "6307052009",
    "district_id": "630705",
    "label": "Kayu Rabah",
    "value": "Kayu Rabah"
  },
  {
    "id": "6307052010",
    "district_id": "630705",
    "label": "Setiap",
    "value": "Setiap"
  },
  {
    "id": "6307052011",
    "district_id": "630705",
    "label": "Kambat Utara",
    "value": "Kambat Utara"
  },
  {
    "id": "6307052012",
    "district_id": "630705",
    "label": "Banua Supanggal",
    "value": "Banua Supanggal"
  },
  {
    "id": "6307052013",
    "district_id": "630705",
    "label": "Hulu Rasau",
    "value": "Hulu Rasau"
  },
  {
    "id": "6307052014",
    "district_id": "630705",
    "label": "Banua Asam",
    "value": "Banua Asam"
  },
  {
    "id": "6307052015",
    "district_id": "630705",
    "label": "Masiraan",
    "value": "Masiraan"
  },
  {
    "id": "6307052016",
    "district_id": "630705",
    "label": "Mahang Putat",
    "value": "Mahang Putat"
  },
  {
    "id": "6307052017",
    "district_id": "630705",
    "label": "Mahang Sungai Hanyar",
    "value": "Mahang Sungai Hanyar"
  },
  {
    "id": "6307052018",
    "district_id": "630705",
    "label": "Hilir Banua",
    "value": "Hilir Banua"
  },
  {
    "id": "6307052019",
    "district_id": "630705",
    "label": "Buluan",
    "value": "Buluan"
  },
  {
    "id": "6307052020",
    "district_id": "630705",
    "label": "Matang Ginalun",
    "value": "Matang Ginalun"
  },
  {
    "id": "6307052021",
    "district_id": "630705",
    "label": "Walatung",
    "value": "Walatung"
  },
  {
    "id": "6307061001",
    "district_id": "630706",
    "label": "Barabai Darat",
    "value": "Barabai Darat"
  },
  {
    "id": "6307061002",
    "district_id": "630706",
    "label": "Barabai Timur",
    "value": "Barabai Timur"
  },
  {
    "id": "6307061003",
    "district_id": "630706",
    "label": "Barabai Selatan",
    "value": "Barabai Selatan"
  },
  {
    "id": "6307061004",
    "district_id": "630706",
    "label": "Barabai Utara",
    "value": "Barabai Utara"
  },
  {
    "id": "6307061005",
    "district_id": "630706",
    "label": "Barabai Barat",
    "value": "Barabai Barat"
  },
  {
    "id": "6307061006",
    "district_id": "630706",
    "label": "Bukat",
    "value": "Bukat"
  },
  {
    "id": "6307062007",
    "district_id": "630706",
    "label": "Gambah",
    "value": "Gambah"
  },
  {
    "id": "6307062008",
    "district_id": "630706",
    "label": "Kayu Bawang",
    "value": "Kayu Bawang"
  },
  {
    "id": "6307062009",
    "district_id": "630706",
    "label": "Benawa Tengah",
    "value": "Benawa Tengah"
  },
  {
    "id": "6307062010",
    "district_id": "630706",
    "label": "Mandingin",
    "value": "Mandingin"
  },
  {
    "id": "6307062011",
    "district_id": "630706",
    "label": "Pajukungan",
    "value": "Pajukungan"
  },
  {
    "id": "6307062012",
    "district_id": "630706",
    "label": "Banua Budi",
    "value": "Banua Budi"
  },
  {
    "id": "6307062013",
    "district_id": "630706",
    "label": "Banua Binjai",
    "value": "Banua Binjai"
  },
  {
    "id": "6307062014",
    "district_id": "630706",
    "label": "Banua Jingah",
    "value": "Banua Jingah"
  },
  {
    "id": "6307062015",
    "district_id": "630706",
    "label": "Ayuang",
    "value": "Ayuang"
  },
  {
    "id": "6307062016",
    "district_id": "630706",
    "label": "Babai",
    "value": "Babai"
  },
  {
    "id": "6307062017",
    "district_id": "630706",
    "label": "Awang Besar",
    "value": "Awang Besar"
  },
  {
    "id": "6307062018",
    "district_id": "630706",
    "label": "Bakapas",
    "value": "Bakapas"
  },
  {
    "id": "6307071001",
    "district_id": "630707",
    "label": "Birayang",
    "value": "Birayang"
  },
  {
    "id": "6307072002",
    "district_id": "630707",
    "label": "Kias",
    "value": "Kias"
  },
  {
    "id": "6307072003",
    "district_id": "630707",
    "label": "Kapar",
    "value": "Kapar"
  },
  {
    "id": "6307072004",
    "district_id": "630707",
    "label": "Tembok Bahalang",
    "value": "Tembok Bahalang"
  },
  {
    "id": "6307072005",
    "district_id": "630707",
    "label": "Limbar",
    "value": "Limbar"
  },
  {
    "id": "6307072006",
    "district_id": "630707",
    "label": "Lok Basar",
    "value": "Lok Basar"
  },
  {
    "id": "6307072007",
    "district_id": "630707",
    "label": "Paya",
    "value": "Paya"
  },
  {
    "id": "6307072008",
    "district_id": "630707",
    "label": "Cukan Lipai",
    "value": "Cukan Lipai"
  },
  {
    "id": "6307072009",
    "district_id": "630707",
    "label": "Birayang Surapati",
    "value": "Birayang Surapati"
  },
  {
    "id": "6307072010",
    "district_id": "630707",
    "label": "Mahela",
    "value": "Mahela"
  },
  {
    "id": "6307072011",
    "district_id": "630707",
    "label": "Rangas",
    "value": "Rangas"
  },
  {
    "id": "6307072012",
    "district_id": "630707",
    "label": "Wawai Gardu",
    "value": "Wawai Gardu"
  },
  {
    "id": "6307072013",
    "district_id": "630707",
    "label": "Labuhan",
    "value": "Labuhan"
  },
  {
    "id": "6307072014",
    "district_id": "630707",
    "label": "Birayang Timur",
    "value": "Birayang Timur"
  },
  {
    "id": "6307072015",
    "district_id": "630707",
    "label": "Tanah Habang",
    "value": "Tanah Habang"
  },
  {
    "id": "6307072016",
    "district_id": "630707",
    "label": "Banua Rantau",
    "value": "Banua Rantau"
  },
  {
    "id": "6307072017",
    "district_id": "630707",
    "label": "Anduhum",
    "value": "Anduhum"
  },
  {
    "id": "6307072018",
    "district_id": "630707",
    "label": "Wawai",
    "value": "Wawai"
  },
  {
    "id": "6307072019",
    "district_id": "630707",
    "label": "Lunjuk",
    "value": "Lunjuk"
  },
  {
    "id": "6307082001",
    "district_id": "630708",
    "label": "Sumanggi Seberang",
    "value": "Sumanggi Seberang"
  },
  {
    "id": "6307082002",
    "district_id": "630708",
    "label": "Sumanggi",
    "value": "Sumanggi"
  },
  {
    "id": "6307082003",
    "district_id": "630708",
    "label": "Ilung",
    "value": "Ilung"
  },
  {
    "id": "6307082004",
    "district_id": "630708",
    "label": "Maringgit",
    "value": "Maringgit"
  },
  {
    "id": "6307082005",
    "district_id": "630708",
    "label": "Telang",
    "value": "Telang"
  },
  {
    "id": "6307082008",
    "district_id": "630708",
    "label": "Labunganak",
    "value": "Labunganak"
  },
  {
    "id": "6307082009",
    "district_id": "630708",
    "label": "Hapingin",
    "value": "Hapingin"
  },
  {
    "id": "6307082010",
    "district_id": "630708",
    "label": "Dangu",
    "value": "Dangu"
  },
  {
    "id": "6307082013",
    "district_id": "630708",
    "label": "Ilung Tengah",
    "value": "Ilung Tengah"
  },
  {
    "id": "6307082014",
    "district_id": "630708",
    "label": "Haur Gading",
    "value": "Haur Gading"
  },
  {
    "id": "6307082018",
    "district_id": "630708",
    "label": "Awang",
    "value": "Awang"
  },
  {
    "id": "6307082019",
    "district_id": "630708",
    "label": "Awang Baru",
    "value": "Awang Baru"
  },
  {
    "id": "6307082020",
    "district_id": "630708",
    "label": "Muara Rintis",
    "value": "Muara Rintis"
  },
  {
    "id": "6307082021",
    "district_id": "630708",
    "label": "Ilung Pasar Lama",
    "value": "Ilung Pasar Lama"
  },
  {
    "id": "6307092001",
    "district_id": "630709",
    "label": "Hantakan",
    "value": "Hantakan"
  },
  {
    "id": "6307092002",
    "district_id": "630709",
    "label": "Alat",
    "value": "Alat"
  },
  {
    "id": "6307092003",
    "district_id": "630709",
    "label": "Murung B",
    "value": "Murung B"
  },
  {
    "id": "6307092004",
    "district_id": "630709",
    "label": "Bulayak",
    "value": "Bulayak"
  },
  {
    "id": "6307092005",
    "district_id": "630709",
    "label": "Batu Tunggal",
    "value": "Batu Tunggal"
  },
  {
    "id": "6307092006",
    "district_id": "630709",
    "label": "Pasting",
    "value": "Pasting"
  },
  {
    "id": "6307092007",
    "district_id": "630709",
    "label": "Tilahan",
    "value": "Tilahan"
  },
  {
    "id": "6307092008",
    "district_id": "630709",
    "label": "Haruyan Dayak",
    "value": "Haruyan Dayak"
  },
  {
    "id": "6307092009",
    "district_id": "630709",
    "label": "Kindingan",
    "value": "Kindingan"
  },
  {
    "id": "6307092010",
    "district_id": "630709",
    "label": "Datar Ajab",
    "value": "Datar Ajab"
  },
  {
    "id": "6307092011",
    "district_id": "630709",
    "label": "Hinas Kanan",
    "value": "Hinas Kanan"
  },
  {
    "id": "6307092012",
    "district_id": "630709",
    "label": "Patikalain",
    "value": "Patikalain"
  },
  {
    "id": "6307102001",
    "district_id": "630710",
    "label": "Batu Tangga",
    "value": "Batu Tangga"
  },
  {
    "id": "6307102002",
    "district_id": "630710",
    "label": "Hinas Kiri",
    "value": "Hinas Kiri"
  },
  {
    "id": "6307102003",
    "district_id": "630710",
    "label": "Pembakulan",
    "value": "Pembakulan"
  },
  {
    "id": "6307102004",
    "district_id": "630710",
    "label": "Nateh",
    "value": "Nateh"
  },
  {
    "id": "6307102005",
    "district_id": "630710",
    "label": "Tandilang",
    "value": "Tandilang"
  },
  {
    "id": "6307102006",
    "district_id": "630710",
    "label": "Muara Hungi",
    "value": "Muara Hungi"
  },
  {
    "id": "6307102007",
    "district_id": "630710",
    "label": "Atiran",
    "value": "Atiran"
  },
  {
    "id": "6307102008",
    "district_id": "630710",
    "label": "Batu Perahu",
    "value": "Batu Perahu"
  },
  {
    "id": "6307102009",
    "district_id": "630710",
    "label": "Aing Bantai",
    "value": "Aing Bantai"
  },
  {
    "id": "6307102010",
    "district_id": "630710",
    "label": "Juhu",
    "value": "Juhu"
  },
  {
    "id": "6307102011",
    "district_id": "630710",
    "label": "Datar Batung",
    "value": "Datar Batung"
  },
  {
    "id": "6307112001",
    "district_id": "630711",
    "label": "Tapuk",
    "value": "Tapuk"
  },
  {
    "id": "6307112002",
    "district_id": "630711",
    "label": "Karau",
    "value": "Karau"
  },
  {
    "id": "6307112003",
    "district_id": "630711",
    "label": "Limpasu",
    "value": "Limpasu"
  },
  {
    "id": "6307112004",
    "district_id": "630711",
    "label": "Kabang",
    "value": "Kabang"
  },
  {
    "id": "6307112005",
    "district_id": "630711",
    "label": "Abung Surapati",
    "value": "Abung Surapati"
  },
  {
    "id": "6307112006",
    "district_id": "630711",
    "label": "Karatungan",
    "value": "Karatungan"
  },
  {
    "id": "6307112007",
    "district_id": "630711",
    "label": "Pauh",
    "value": "Pauh"
  },
  {
    "id": "6307112008",
    "district_id": "630711",
    "label": "Abung",
    "value": "Abung"
  },
  {
    "id": "6307112009",
    "district_id": "630711",
    "label": "Hawang",
    "value": "Hawang"
  },
  {
    "id": "6308012003",
    "district_id": "630801",
    "label": "Sungai Namang",
    "value": "Sungai Namang"
  },
  {
    "id": "6308012004",
    "district_id": "630801",
    "label": "Danau Panggang",
    "value": "Danau Panggang"
  },
  {
    "id": "6308012008",
    "district_id": "630801",
    "label": "Pandamaan",
    "value": "Pandamaan"
  },
  {
    "id": "6308012009",
    "district_id": "630801",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6308012010",
    "district_id": "630801",
    "label": "Bitin",
    "value": "Bitin"
  },
  {
    "id": "6308012011",
    "district_id": "630801",
    "label": "Manarap",
    "value": "Manarap"
  },
  {
    "id": "6308012012",
    "district_id": "630801",
    "label": "Pararain",
    "value": "Pararain"
  },
  {
    "id": "6308012013",
    "district_id": "630801",
    "label": "Telaga Mas",
    "value": "Telaga Mas"
  },
  {
    "id": "6308012015",
    "district_id": "630801",
    "label": "Darussalam",
    "value": "Darussalam"
  },
  {
    "id": "6308012016",
    "district_id": "630801",
    "label": "Sarang Burung",
    "value": "Sarang Burung"
  },
  {
    "id": "6308012017",
    "district_id": "630801",
    "label": "Longkong",
    "value": "Longkong"
  },
  {
    "id": "6308012019",
    "district_id": "630801",
    "label": "Rintisan",
    "value": "Rintisan"
  },
  {
    "id": "6308012020",
    "district_id": "630801",
    "label": "Palukahan",
    "value": "Palukahan"
  },
  {
    "id": "6308012021",
    "district_id": "630801",
    "label": "Teluk Mesjid",
    "value": "Teluk Mesjid"
  },
  {
    "id": "6308012022",
    "district_id": "630801",
    "label": "Sungai Panangah",
    "value": "Sungai Panangah"
  },
  {
    "id": "6308012023",
    "district_id": "630801",
    "label": "Manarap Hulu",
    "value": "Manarap Hulu"
  },
  {
    "id": "6308022001",
    "district_id": "630802",
    "label": "Babirik Hilir",
    "value": "Babirik Hilir"
  },
  {
    "id": "6308022002",
    "district_id": "630802",
    "label": "Babirik Hulu",
    "value": "Babirik Hulu"
  },
  {
    "id": "6308022003",
    "district_id": "630802",
    "label": "Sungai Durait Hilir",
    "value": "Sungai Durait Hilir"
  },
  {
    "id": "6308022004",
    "district_id": "630802",
    "label": "Hambuku Hilir",
    "value": "Hambuku Hilir"
  },
  {
    "id": "6308022005",
    "district_id": "630802",
    "label": "Murung Panti Hulu",
    "value": "Murung Panti Hulu"
  },
  {
    "id": "6308022006",
    "district_id": "630802",
    "label": "Murung Panti Hilir",
    "value": "Murung Panti Hilir"
  },
  {
    "id": "6308022007",
    "district_id": "630802",
    "label": "Murung Kupang",
    "value": "Murung Kupang"
  },
  {
    "id": "6308022008",
    "district_id": "630802",
    "label": "Sungai Luang Hilir",
    "value": "Sungai Luang Hilir"
  },
  {
    "id": "6308022009",
    "district_id": "630802",
    "label": "Pajukungan Hilir",
    "value": "Pajukungan Hilir"
  },
  {
    "id": "6308022010",
    "district_id": "630802",
    "label": "Kalumpang Dalam",
    "value": "Kalumpang Dalam"
  },
  {
    "id": "6308022011",
    "district_id": "630802",
    "label": "Parupukan",
    "value": "Parupukan"
  },
  {
    "id": "6308022012",
    "district_id": "630802",
    "label": "Sungai Luang Hulu",
    "value": "Sungai Luang Hulu"
  },
  {
    "id": "6308022013",
    "district_id": "630802",
    "label": "Sungai Durait Hulu",
    "value": "Sungai Durait Hulu"
  },
  {
    "id": "6308022014",
    "district_id": "630802",
    "label": "Sungai Dalam",
    "value": "Sungai Dalam"
  },
  {
    "id": "6308022015",
    "district_id": "630802",
    "label": "Hambuku Baru",
    "value": "Hambuku Baru"
  },
  {
    "id": "6308022016",
    "district_id": "630802",
    "label": "Hambuku Lima",
    "value": "Hambuku Lima"
  },
  {
    "id": "6308022017",
    "district_id": "630802",
    "label": "Sungai Janjam",
    "value": "Sungai Janjam"
  },
  {
    "id": "6308022018",
    "district_id": "630802",
    "label": "Kalumpang Luar",
    "value": "Kalumpang Luar"
  },
  {
    "id": "6308022019",
    "district_id": "630802",
    "label": "Teluk Limbung",
    "value": "Teluk Limbung"
  },
  {
    "id": "6308022020",
    "district_id": "630802",
    "label": "Sungai Durait Tengah",
    "value": "Sungai Durait Tengah"
  },
  {
    "id": "6308022021",
    "district_id": "630802",
    "label": "Pajukungan Hulu",
    "value": "Pajukungan Hulu"
  },
  {
    "id": "6308022022",
    "district_id": "630802",
    "label": "Sungai Papuyu",
    "value": "Sungai Papuyu"
  },
  {
    "id": "6308022023",
    "district_id": "630802",
    "label": "Sungai Nyiur",
    "value": "Sungai Nyiur"
  },
  {
    "id": "6308032001",
    "district_id": "630803",
    "label": "Rantau Karau Hilir",
    "value": "Rantau Karau Hilir"
  },
  {
    "id": "6308032002",
    "district_id": "630803",
    "label": "Rantau Karau Hulu",
    "value": "Rantau Karau Hulu"
  },
  {
    "id": "6308032003",
    "district_id": "630803",
    "label": "Banyu Tajun Hilir",
    "value": "Banyu Tajun Hilir"
  },
  {
    "id": "6308032004",
    "district_id": "630803",
    "label": "Banyu Tajun Hulu",
    "value": "Banyu Tajun Hulu"
  },
  {
    "id": "6308032005",
    "district_id": "630803",
    "label": "Banyu Tajun Pangkalan",
    "value": "Banyu Tajun Pangkalan"
  },
  {
    "id": "6308032006",
    "district_id": "630803",
    "label": "Sungai Sandung",
    "value": "Sungai Sandung"
  },
  {
    "id": "6308032007",
    "district_id": "630803",
    "label": "Sungai Pandan Hilir",
    "value": "Sungai Pandan Hilir"
  },
  {
    "id": "6308032008",
    "district_id": "630803",
    "label": "Teluk Betung",
    "value": "Teluk Betung"
  },
  {
    "id": "6308032009",
    "district_id": "630803",
    "label": "Tambalang",
    "value": "Tambalang"
  },
  {
    "id": "6308032010",
    "district_id": "630803",
    "label": "Hambuku Hulu",
    "value": "Hambuku Hulu"
  },
  {
    "id": "6308032011",
    "district_id": "630803",
    "label": "Hambuku Tengah",
    "value": "Hambuku Tengah"
  },
  {
    "id": "6308032014",
    "district_id": "630803",
    "label": "Pandulangan",
    "value": "Pandulangan"
  },
  {
    "id": "6308032015",
    "district_id": "630803",
    "label": "Sungai Pandan Hulu",
    "value": "Sungai Pandan Hulu"
  },
  {
    "id": "6308032021",
    "district_id": "630803",
    "label": "Pondok Babaris",
    "value": "Pondok Babaris"
  },
  {
    "id": "6308032024",
    "district_id": "630803",
    "label": "Sungai Pinang",
    "value": "Sungai Pinang"
  },
  {
    "id": "6308032025",
    "district_id": "630803",
    "label": "Tambalang Kecil",
    "value": "Tambalang Kecil"
  },
  {
    "id": "6308032026",
    "district_id": "630803",
    "label": "Putat Atas",
    "value": "Putat Atas"
  },
  {
    "id": "6308032029",
    "district_id": "630803",
    "label": "Banyu Tajun Dalam",
    "value": "Banyu Tajun Dalam"
  },
  {
    "id": "6308032031",
    "district_id": "630803",
    "label": "Sungai Pandan",
    "value": "Sungai Pandan"
  },
  {
    "id": "6308032032",
    "district_id": "630803",
    "label": "Hambuku Raya",
    "value": "Hambuku Raya"
  },
  {
    "id": "6308032033",
    "district_id": "630803",
    "label": "Sungai Kuini",
    "value": "Sungai Kuini"
  },
  {
    "id": "6308032034",
    "district_id": "630803",
    "label": "Tapus Dalam",
    "value": "Tapus Dalam"
  },
  {
    "id": "6308032035",
    "district_id": "630803",
    "label": "Rantau KarauTengah",
    "value": "Rantau KarauTengah"
  },
  {
    "id": "6308032037",
    "district_id": "630803",
    "label": "Tambalang Tengah",
    "value": "Tambalang Tengah"
  },
  {
    "id": "6308032038",
    "district_id": "630803",
    "label": "Teluk Mesjid",
    "value": "Teluk Mesjid"
  },
  {
    "id": "6308032039",
    "district_id": "630803",
    "label": "Murung Asam",
    "value": "Murung Asam"
  },
  {
    "id": "6308032040",
    "district_id": "630803",
    "label": "Tatah Laban",
    "value": "Tatah Laban"
  },
  {
    "id": "6308032041",
    "district_id": "630803",
    "label": "Jalan Lurus",
    "value": "Jalan Lurus"
  },
  {
    "id": "6308032042",
    "district_id": "630803",
    "label": "Teluk Sinar",
    "value": "Teluk Sinar"
  },
  {
    "id": "6308032043",
    "district_id": "630803",
    "label": "Padang Bangkal",
    "value": "Padang Bangkal"
  },
  {
    "id": "6308032044",
    "district_id": "630803",
    "label": "Hambuku Pasar",
    "value": "Hambuku Pasar"
  },
  {
    "id": "6308032045",
    "district_id": "630803",
    "label": "Pangkalan Sari",
    "value": "Pangkalan Sari"
  },
  {
    "id": "6308032046",
    "district_id": "630803",
    "label": "Rantau Karau Raya",
    "value": "Rantau Karau Raya"
  },
  {
    "id": "6308042001",
    "district_id": "630804",
    "label": "Panyiuran",
    "value": "Panyiuran"
  },
  {
    "id": "6308042002",
    "district_id": "630804",
    "label": "Simpang Empat",
    "value": "Simpang Empat"
  },
  {
    "id": "6308042003",
    "district_id": "630804",
    "label": "Padang Darat",
    "value": "Padang Darat"
  },
  {
    "id": "6308042004",
    "district_id": "630804",
    "label": "Teluk Baru",
    "value": "Teluk Baru"
  },
  {
    "id": "6308042005",
    "district_id": "630804",
    "label": "Ilir Mesjid",
    "value": "Ilir Mesjid"
  },
  {
    "id": "6308042006",
    "district_id": "630804",
    "label": "Jarang Kuantan",
    "value": "Jarang Kuantan"
  },
  {
    "id": "6308042007",
    "district_id": "630804",
    "label": "Jumba",
    "value": "Jumba"
  },
  {
    "id": "6308042008",
    "district_id": "630804",
    "label": "Telaga Sari",
    "value": "Telaga Sari"
  },
  {
    "id": "6308042009",
    "district_id": "630804",
    "label": "Telaga Silaba",
    "value": "Telaga Silaba"
  },
  {
    "id": "6308042010",
    "district_id": "630804",
    "label": "Banyu Hirang",
    "value": "Banyu Hirang"
  },
  {
    "id": "6308042011",
    "district_id": "630804",
    "label": "Kayakah",
    "value": "Kayakah"
  },
  {
    "id": "6308042012",
    "district_id": "630804",
    "label": "Bajawit",
    "value": "Bajawit"
  },
  {
    "id": "6308042013",
    "district_id": "630804",
    "label": "Padang Tanggul",
    "value": "Padang Tanggul"
  },
  {
    "id": "6308042014",
    "district_id": "630804",
    "label": "Teluk Paring",
    "value": "Teluk Paring"
  },
  {
    "id": "6308042015",
    "district_id": "630804",
    "label": "Rukam Hilir",
    "value": "Rukam Hilir"
  },
  {
    "id": "6308042016",
    "district_id": "630804",
    "label": "Mamar",
    "value": "Mamar"
  },
  {
    "id": "6308042017",
    "district_id": "630804",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "6308042018",
    "district_id": "630804",
    "label": "Keramat",
    "value": "Keramat"
  },
  {
    "id": "6308042019",
    "district_id": "630804",
    "label": "Ujung Murung",
    "value": "Ujung Murung"
  },
  {
    "id": "6308042020",
    "district_id": "630804",
    "label": "Kota Raja",
    "value": "Kota Raja"
  },
  {
    "id": "6308042021",
    "district_id": "630804",
    "label": "Pulau Tambak",
    "value": "Pulau Tambak"
  },
  {
    "id": "6308042022",
    "district_id": "630804",
    "label": "Harusan Telaga",
    "value": "Harusan Telaga"
  },
  {
    "id": "6308042023",
    "district_id": "630804",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "6308042024",
    "district_id": "630804",
    "label": "Cangkering",
    "value": "Cangkering"
  },
  {
    "id": "6308042025",
    "district_id": "630804",
    "label": "Kutai Kecil",
    "value": "Kutai Kecil"
  },
  {
    "id": "6308042026",
    "district_id": "630804",
    "label": "Teluk Sari",
    "value": "Teluk Sari"
  },
  {
    "id": "6308042027",
    "district_id": "630804",
    "label": "Murung Panggang",
    "value": "Murung Panggang"
  },
  {
    "id": "6308042028",
    "district_id": "630804",
    "label": "Telaga Hanyar",
    "value": "Telaga Hanyar"
  },
  {
    "id": "6308042029",
    "district_id": "630804",
    "label": "Rukam Hulu",
    "value": "Rukam Hulu"
  },
  {
    "id": "6308042030",
    "district_id": "630804",
    "label": "Murung Sari",
    "value": "Murung Sari"
  },
  {
    "id": "6308051001",
    "district_id": "630805",
    "label": "Antasari",
    "value": "Antasari"
  },
  {
    "id": "6308051002",
    "district_id": "630805",
    "label": "Murung Sari",
    "value": "Murung Sari"
  },
  {
    "id": "6308051003",
    "district_id": "630805",
    "label": "Kebun Sari",
    "value": "Kebun Sari"
  },
  {
    "id": "6308051004",
    "district_id": "630805",
    "label": "Paliwara",
    "value": "Paliwara"
  },
  {
    "id": "6308051005",
    "district_id": "630805",
    "label": "Sungai Malang",
    "value": "Sungai Malang"
  },
  {
    "id": "6308052006",
    "district_id": "630805",
    "label": "Tapus",
    "value": "Tapus"
  },
  {
    "id": "6308052007",
    "district_id": "630805",
    "label": "Kandang Halang",
    "value": "Kandang Halang"
  },
  {
    "id": "6308052008",
    "district_id": "630805",
    "label": "Pasar Senin",
    "value": "Pasar Senin"
  },
  {
    "id": "6308052009",
    "district_id": "630805",
    "label": "Kota Raden Hulu",
    "value": "Kota Raden Hulu"
  },
  {
    "id": "6308052010",
    "district_id": "630805",
    "label": "Tangga Ulin Hilir",
    "value": "Tangga Ulin Hilir"
  },
  {
    "id": "6308052011",
    "district_id": "630805",
    "label": "Tambalangan",
    "value": "Tambalangan"
  },
  {
    "id": "6308052012",
    "district_id": "630805",
    "label": "Palampitan Hulu",
    "value": "Palampitan Hulu"
  },
  {
    "id": "6308052013",
    "district_id": "630805",
    "label": "Harus",
    "value": "Harus"
  },
  {
    "id": "6308052014",
    "district_id": "630805",
    "label": "Pinangkara",
    "value": "Pinangkara"
  },
  {
    "id": "6308052015",
    "district_id": "630805",
    "label": "Sungai Karias",
    "value": "Sungai Karias"
  },
  {
    "id": "6308052016",
    "district_id": "630805",
    "label": "Tigarun",
    "value": "Tigarun"
  },
  {
    "id": "6308052017",
    "district_id": "630805",
    "label": "Pinang Habang",
    "value": "Pinang Habang"
  },
  {
    "id": "6308052018",
    "district_id": "630805",
    "label": "Hulu Pasar",
    "value": "Hulu Pasar"
  },
  {
    "id": "6308052019",
    "district_id": "630805",
    "label": "Kota Raden Hilir",
    "value": "Kota Raden Hilir"
  },
  {
    "id": "6308052020",
    "district_id": "630805",
    "label": "Rantawan",
    "value": "Rantawan"
  },
  {
    "id": "6308052021",
    "district_id": "630805",
    "label": "Muara Tapus",
    "value": "Muara Tapus"
  },
  {
    "id": "6308052022",
    "district_id": "630805",
    "label": "Palampitan Hilir",
    "value": "Palampitan Hilir"
  },
  {
    "id": "6308052023",
    "district_id": "630805",
    "label": "Harusan",
    "value": "Harusan"
  },
  {
    "id": "6308052024",
    "district_id": "630805",
    "label": "Sungai Baring",
    "value": "Sungai Baring"
  },
  {
    "id": "6308052025",
    "district_id": "630805",
    "label": "Kembang Kuning",
    "value": "Kembang Kuning"
  },
  {
    "id": "6308052026",
    "district_id": "630805",
    "label": "Datu Kuning",
    "value": "Datu Kuning"
  },
  {
    "id": "6308052027",
    "district_id": "630805",
    "label": "Tangga Ulin Hulu",
    "value": "Tangga Ulin Hulu"
  },
  {
    "id": "6308052028",
    "district_id": "630805",
    "label": "Mawar Sari",
    "value": "Mawar Sari"
  },
  {
    "id": "6308052029",
    "district_id": "630805",
    "label": "Danau Cermin",
    "value": "Danau Cermin"
  },
  {
    "id": "6308062003",
    "district_id": "630806",
    "label": "Pakapuran",
    "value": "Pakapuran"
  },
  {
    "id": "6308062004",
    "district_id": "630806",
    "label": "Pakacangan",
    "value": "Pakacangan"
  },
  {
    "id": "6308062005",
    "district_id": "630806",
    "label": "Panangkalaan",
    "value": "Panangkalaan"
  },
  {
    "id": "6308062006",
    "district_id": "630806",
    "label": "Padang Basar",
    "value": "Padang Basar"
  },
  {
    "id": "6308062007",
    "district_id": "630806",
    "label": "Murung Karangan",
    "value": "Murung Karangan"
  },
  {
    "id": "6308062008",
    "district_id": "630806",
    "label": "Kamayahan",
    "value": "Kamayahan"
  },
  {
    "id": "6308062009",
    "district_id": "630806",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "6308062010",
    "district_id": "630806",
    "label": "Muara Baruh",
    "value": "Muara Baruh"
  },
  {
    "id": "6308062011",
    "district_id": "630806",
    "label": "Teluk Daun",
    "value": "Teluk Daun"
  },
  {
    "id": "6308062012",
    "district_id": "630806",
    "label": "Sungai Turak",
    "value": "Sungai Turak"
  },
  {
    "id": "6308062013",
    "district_id": "630806",
    "label": "Tabalong Mati",
    "value": "Tabalong Mati"
  },
  {
    "id": "6308062020",
    "district_id": "630806",
    "label": "Pimping",
    "value": "Pimping"
  },
  {
    "id": "6308062021",
    "district_id": "630806",
    "label": "Padang Luar",
    "value": "Padang Luar"
  },
  {
    "id": "6308062023",
    "district_id": "630806",
    "label": "Tayur",
    "value": "Tayur"
  },
  {
    "id": "6308062026",
    "district_id": "630806",
    "label": "Panangkalaan Hulu",
    "value": "Panangkalaan Hulu"
  },
  {
    "id": "6308062027",
    "district_id": "630806",
    "label": "Cakeru",
    "value": "Cakeru"
  },
  {
    "id": "6308062028",
    "district_id": "630806",
    "label": "Sungai Turak Dalam",
    "value": "Sungai Turak Dalam"
  },
  {
    "id": "6308062029",
    "district_id": "630806",
    "label": "Panyaungan",
    "value": "Panyaungan"
  },
  {
    "id": "6308062030",
    "district_id": "630806",
    "label": "Kuangan",
    "value": "Kuangan"
  },
  {
    "id": "6308062032",
    "district_id": "630806",
    "label": "Tabing Liring",
    "value": "Tabing Liring"
  },
  {
    "id": "6308062033",
    "district_id": "630806",
    "label": "Padang Basar Hilir",
    "value": "Padang Basar Hilir"
  },
  {
    "id": "6308062034",
    "district_id": "630806",
    "label": "Telaga Bamban",
    "value": "Telaga Bamban"
  },
  {
    "id": "6308062035",
    "district_id": "630806",
    "label": "Air Tawar",
    "value": "Air Tawar"
  },
  {
    "id": "6308062037",
    "district_id": "630806",
    "label": "Panangian",
    "value": "Panangian"
  },
  {
    "id": "6308062041",
    "district_id": "630806",
    "label": "Pamintangan",
    "value": "Pamintangan"
  },
  {
    "id": "6308062044",
    "district_id": "630806",
    "label": "Pandawanan",
    "value": "Pandawanan"
  },
  {
    "id": "6308072001",
    "district_id": "630807",
    "label": "Banjang",
    "value": "Banjang"
  },
  {
    "id": "6308072002",
    "district_id": "630807",
    "label": "Patarikan",
    "value": "Patarikan"
  },
  {
    "id": "6308072003",
    "district_id": "630807",
    "label": "Teluk Buluh",
    "value": "Teluk Buluh"
  },
  {
    "id": "6308072004",
    "district_id": "630807",
    "label": "Pandulangan",
    "value": "Pandulangan"
  },
  {
    "id": "6308072005",
    "district_id": "630807",
    "label": "Danau Terati",
    "value": "Danau Terati"
  },
  {
    "id": "6308072006",
    "district_id": "630807",
    "label": "Garunggang",
    "value": "Garunggang"
  },
  {
    "id": "6308072007",
    "district_id": "630807",
    "label": "Baruh Tabing",
    "value": "Baruh Tabing"
  },
  {
    "id": "6308072008",
    "district_id": "630807",
    "label": "Murung Padang",
    "value": "Murung Padang"
  },
  {
    "id": "6308072009",
    "district_id": "630807",
    "label": "Teluk Sarikat",
    "value": "Teluk Sarikat"
  },
  {
    "id": "6308072010",
    "district_id": "630807",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6308072011",
    "district_id": "630807",
    "label": "Kalintamui",
    "value": "Kalintamui"
  },
  {
    "id": "6308072012",
    "district_id": "630807",
    "label": "Pelanjungan Sari",
    "value": "Pelanjungan Sari"
  },
  {
    "id": "6308072013",
    "district_id": "630807",
    "label": "Lok Bangkai",
    "value": "Lok Bangkai"
  },
  {
    "id": "6308072014",
    "district_id": "630807",
    "label": "Sungai Bahadangan",
    "value": "Sungai Bahadangan"
  },
  {
    "id": "6308072015",
    "district_id": "630807",
    "label": "Karias Dalam",
    "value": "Karias Dalam"
  },
  {
    "id": "6308072016",
    "district_id": "630807",
    "label": "Rantau Bujur",
    "value": "Rantau Bujur"
  },
  {
    "id": "6308072017",
    "district_id": "630807",
    "label": "Kaludan Kecil",
    "value": "Kaludan Kecil"
  },
  {
    "id": "6308072018",
    "district_id": "630807",
    "label": "Kaludan Besar",
    "value": "Kaludan Besar"
  },
  {
    "id": "6308072019",
    "district_id": "630807",
    "label": "Pawalutan",
    "value": "Pawalutan"
  },
  {
    "id": "6308072020",
    "district_id": "630807",
    "label": "Pulau Damar",
    "value": "Pulau Damar"
  },
  {
    "id": "6308082001",
    "district_id": "630808",
    "label": "Palimbangan",
    "value": "Palimbangan"
  },
  {
    "id": "6308082002",
    "district_id": "630808",
    "label": "Palimbangan Gusti",
    "value": "Palimbangan Gusti"
  },
  {
    "id": "6308082003",
    "district_id": "630808",
    "label": "Palimbang Sari",
    "value": "Palimbang Sari"
  },
  {
    "id": "6308082004",
    "district_id": "630808",
    "label": "Bayur",
    "value": "Bayur"
  },
  {
    "id": "6308082005",
    "district_id": "630808",
    "label": "Lok Suga",
    "value": "Lok Suga"
  },
  {
    "id": "6308082006",
    "district_id": "630808",
    "label": "Sungai Limas",
    "value": "Sungai Limas"
  },
  {
    "id": "6308082007",
    "district_id": "630808",
    "label": "Pihaung",
    "value": "Pihaung"
  },
  {
    "id": "6308082008",
    "district_id": "630808",
    "label": "Sungai Binuang",
    "value": "Sungai Binuang"
  },
  {
    "id": "6308082009",
    "district_id": "630808",
    "label": "Jingah Bujur",
    "value": "Jingah Bujur"
  },
  {
    "id": "6308082010",
    "district_id": "630808",
    "label": "Haur Gading",
    "value": "Haur Gading"
  },
  {
    "id": "6308082011",
    "district_id": "630808",
    "label": "Keramat",
    "value": "Keramat"
  },
  {
    "id": "6308082012",
    "district_id": "630808",
    "label": "Tambak Sari panji",
    "value": "Tambak Sari panji"
  },
  {
    "id": "6308082013",
    "district_id": "630808",
    "label": "Pulantani",
    "value": "Pulantani"
  },
  {
    "id": "6308082014",
    "district_id": "630808",
    "label": "Waringin",
    "value": "Waringin"
  },
  {
    "id": "6308082015",
    "district_id": "630808",
    "label": "Tangkawang",
    "value": "Tangkawang"
  },
  {
    "id": "6308082016",
    "district_id": "630808",
    "label": "Tuhuran",
    "value": "Tuhuran"
  },
  {
    "id": "6308082017",
    "district_id": "630808",
    "label": "Teluk Haur",
    "value": "Teluk Haur"
  },
  {
    "id": "6308082018",
    "district_id": "630808",
    "label": "Panawakan",
    "value": "Panawakan"
  },
  {
    "id": "6308092001",
    "district_id": "630809",
    "label": "Paminggir",
    "value": "Paminggir"
  },
  {
    "id": "6308092002",
    "district_id": "630809",
    "label": "Paminggir Seberang",
    "value": "Paminggir Seberang"
  },
  {
    "id": "6308092003",
    "district_id": "630809",
    "label": "Ambahai",
    "value": "Ambahai"
  },
  {
    "id": "6308092004",
    "district_id": "630809",
    "label": "Sapala",
    "value": "Sapala"
  },
  {
    "id": "6308092005",
    "district_id": "630809",
    "label": "Bararawa",
    "value": "Bararawa"
  },
  {
    "id": "6308092006",
    "district_id": "630809",
    "label": "Pal Batu",
    "value": "Pal Batu"
  },
  {
    "id": "6308092007",
    "district_id": "630809",
    "label": "Tampakang",
    "value": "Tampakang"
  },
  {
    "id": "6308102001",
    "district_id": "630810",
    "label": "Sungai Tabukan",
    "value": "Sungai Tabukan"
  },
  {
    "id": "6308102002",
    "district_id": "630810",
    "label": "Nelayan",
    "value": "Nelayan"
  },
  {
    "id": "6308102003",
    "district_id": "630810",
    "label": "Galagah Hulu",
    "value": "Galagah Hulu"
  },
  {
    "id": "6308102004",
    "district_id": "630810",
    "label": "Galagah",
    "value": "Galagah"
  },
  {
    "id": "6308102005",
    "district_id": "630810",
    "label": "Teluk Cati",
    "value": "Teluk Cati"
  },
  {
    "id": "6308102006",
    "district_id": "630810",
    "label": "Pematang Benteng",
    "value": "Pematang Benteng"
  },
  {
    "id": "6308102007",
    "district_id": "630810",
    "label": "Pematang Benteng",
    "value": "Pematang Benteng"
  },
  {
    "id": "6308102008",
    "district_id": "630810",
    "label": "Pasar Sabtu",
    "value": "Pasar Sabtu"
  },
  {
    "id": "6308102009",
    "district_id": "630810",
    "label": "Sungai Haji",
    "value": "Sungai Haji"
  },
  {
    "id": "6308102010",
    "district_id": "630810",
    "label": "Hilir Mesjid",
    "value": "Hilir Mesjid"
  },
  {
    "id": "6308102011",
    "district_id": "630810",
    "label": "Gampa Raya",
    "value": "Gampa Raya"
  },
  {
    "id": "6308102012",
    "district_id": "630810",
    "label": "Banua Hanyar",
    "value": "Banua Hanyar"
  },
  {
    "id": "6308102013",
    "district_id": "630810",
    "label": "Rantau Bujur Hulu",
    "value": "Rantau Bujur Hulu"
  },
  {
    "id": "6308102014",
    "district_id": "630810",
    "label": "Rantau Bujur Tengah",
    "value": "Rantau Bujur Tengah"
  },
  {
    "id": "6308102015",
    "district_id": "630810",
    "label": "Rantau Bujur Hilir",
    "value": "Rantau Bujur Hilir"
  },
  {
    "id": "6308102016",
    "district_id": "630810",
    "label": "Rantau Bujur Darat",
    "value": "Rantau Bujur Darat"
  },
  {
    "id": "6308102017",
    "district_id": "630810",
    "label": "Tambalang Raya",
    "value": "Tambalang Raya"
  },
  {
    "id": "6309012001",
    "district_id": "630901",
    "label": "Hapalah",
    "value": "Hapalah"
  },
  {
    "id": "6309012002",
    "district_id": "630901",
    "label": "Bangkiling",
    "value": "Bangkiling"
  },
  {
    "id": "6309012003",
    "district_id": "630901",
    "label": "Sungai Durian",
    "value": "Sungai Durian"
  },
  {
    "id": "6309012004",
    "district_id": "630901",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "6309012005",
    "district_id": "630901",
    "label": "Hariang",
    "value": "Hariang"
  },
  {
    "id": "6309012006",
    "district_id": "630901",
    "label": "Banua Lawas",
    "value": "Banua Lawas"
  },
  {
    "id": "6309012007",
    "district_id": "630901",
    "label": "Habau",
    "value": "Habau"
  },
  {
    "id": "6309012008",
    "district_id": "630901",
    "label": "Banua Rantau",
    "value": "Banua Rantau"
  },
  {
    "id": "6309012009",
    "district_id": "630901",
    "label": "Purai",
    "value": "Purai"
  },
  {
    "id": "6309012010",
    "district_id": "630901",
    "label": "Batang Banyu",
    "value": "Batang Banyu"
  },
  {
    "id": "6309012011",
    "district_id": "630901",
    "label": "Habau Hulu",
    "value": "Habau Hulu"
  },
  {
    "id": "6309012012",
    "district_id": "630901",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "6309012013",
    "district_id": "630901",
    "label": "Bangkiling Raya",
    "value": "Bangkiling Raya"
  },
  {
    "id": "6309012014",
    "district_id": "630901",
    "label": "Talan",
    "value": "Talan"
  },
  {
    "id": "6309012015",
    "district_id": "630901",
    "label": "Sungai Anyar",
    "value": "Sungai Anyar"
  },
  {
    "id": "6309021001",
    "district_id": "630902",
    "label": "Pulau",
    "value": "Pulau"
  },
  {
    "id": "6309022002",
    "district_id": "630902",
    "label": "Ampukung",
    "value": "Ampukung"
  },
  {
    "id": "6309022003",
    "district_id": "630902",
    "label": "Telaga Itar",
    "value": "Telaga Itar"
  },
  {
    "id": "6309022004",
    "district_id": "630902",
    "label": "Sungai Buluh",
    "value": "Sungai Buluh"
  },
  {
    "id": "6309022005",
    "district_id": "630902",
    "label": "Binturu",
    "value": "Binturu"
  },
  {
    "id": "6309022006",
    "district_id": "630902",
    "label": "Pudak Setegal",
    "value": "Pudak Setegal"
  },
  {
    "id": "6309022007",
    "district_id": "630902",
    "label": "Pasar Panas",
    "value": "Pasar Panas"
  },
  {
    "id": "6309022008",
    "district_id": "630902",
    "label": "Masintan",
    "value": "Masintan"
  },
  {
    "id": "6309022009",
    "district_id": "630902",
    "label": "Takulat",
    "value": "Takulat"
  },
  {
    "id": "6309022010",
    "district_id": "630902",
    "label": "Paliat",
    "value": "Paliat"
  },
  {
    "id": "6309022011",
    "district_id": "630902",
    "label": "Karangan Putih",
    "value": "Karangan Putih"
  },
  {
    "id": "6309022012",
    "district_id": "630902",
    "label": "Bahungin",
    "value": "Bahungin"
  },
  {
    "id": "6309032001",
    "district_id": "630903",
    "label": "Murung Baru",
    "value": "Murung Baru"
  },
  {
    "id": "6309032002",
    "district_id": "630903",
    "label": "Lukbayur",
    "value": "Lukbayur"
  },
  {
    "id": "6309032003",
    "district_id": "630903",
    "label": "Walangkir",
    "value": "Walangkir"
  },
  {
    "id": "6309032004",
    "district_id": "630903",
    "label": "Warukin",
    "value": "Warukin"
  },
  {
    "id": "6309032005",
    "district_id": "630903",
    "label": "Barimbun",
    "value": "Barimbun"
  },
  {
    "id": "6309032006",
    "district_id": "630903",
    "label": "Mangkusip",
    "value": "Mangkusip"
  },
  {
    "id": "6309032007",
    "district_id": "630903",
    "label": "Pamarangan Kanan",
    "value": "Pamarangan Kanan"
  },
  {
    "id": "6309032008",
    "district_id": "630903",
    "label": "Pulau Ku'u",
    "value": "Pulau Ku'u"
  },
  {
    "id": "6309032009",
    "district_id": "630903",
    "label": "Tanta",
    "value": "Tanta"
  },
  {
    "id": "6309032010",
    "district_id": "630903",
    "label": "Padang Panjang",
    "value": "Padang Panjang"
  },
  {
    "id": "6309032011",
    "district_id": "630903",
    "label": "Puain Kanan",
    "value": "Puain Kanan"
  },
  {
    "id": "6309032012",
    "district_id": "630903",
    "label": "Padangin",
    "value": "Padangin"
  },
  {
    "id": "6309032013",
    "district_id": "630903",
    "label": "Tamiyang",
    "value": "Tamiyang"
  },
  {
    "id": "6309032014",
    "district_id": "630903",
    "label": "Tanta Hulu",
    "value": "Tanta Hulu"
  },
  {
    "id": "6309041001",
    "district_id": "630904",
    "label": "Jangkung",
    "value": "Jangkung"
  },
  {
    "id": "6309041002",
    "district_id": "630904",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "6309041003",
    "district_id": "630904",
    "label": "Agung",
    "value": "Agung"
  },
  {
    "id": "6309041004",
    "district_id": "630904",
    "label": "Hikun",
    "value": "Hikun"
  },
  {
    "id": "6309042005",
    "district_id": "630904",
    "label": "Banyu Tajun",
    "value": "Banyu Tajun"
  },
  {
    "id": "6309042006",
    "district_id": "630904",
    "label": "Pamarangan Kiwa",
    "value": "Pamarangan Kiwa"
  },
  {
    "id": "6309042007",
    "district_id": "630904",
    "label": "Puain Kiwa",
    "value": "Puain Kiwa"
  },
  {
    "id": "6309042008",
    "district_id": "630904",
    "label": "Juai",
    "value": "Juai"
  },
  {
    "id": "6309042009",
    "district_id": "630904",
    "label": "Mahe Seberang",
    "value": "Mahe Seberang"
  },
  {
    "id": "6309042010",
    "district_id": "630904",
    "label": "Kambitin",
    "value": "Kambitin"
  },
  {
    "id": "6309042011",
    "district_id": "630904",
    "label": "Wayau",
    "value": "Wayau"
  },
  {
    "id": "6309042012",
    "district_id": "630904",
    "label": "Garunggung",
    "value": "Garunggung"
  },
  {
    "id": "6309042013",
    "district_id": "630904",
    "label": "Kitang",
    "value": "Kitang"
  },
  {
    "id": "6309042014",
    "district_id": "630904",
    "label": "Sungai Pimping",
    "value": "Sungai Pimping"
  },
  {
    "id": "6309042015",
    "district_id": "630904",
    "label": "Kambitin Raya",
    "value": "Kambitin Raya"
  },
  {
    "id": "6309052001",
    "district_id": "630905",
    "label": "Marindi",
    "value": "Marindi"
  },
  {
    "id": "6309052002",
    "district_id": "630905",
    "label": "Wirang",
    "value": "Wirang"
  },
  {
    "id": "6309052003",
    "district_id": "630905",
    "label": "Bongkang",
    "value": "Bongkang"
  },
  {
    "id": "6309052004",
    "district_id": "630905",
    "label": "Nawin",
    "value": "Nawin"
  },
  {
    "id": "6309052005",
    "district_id": "630905",
    "label": "Halong",
    "value": "Halong"
  },
  {
    "id": "6309052007",
    "district_id": "630905",
    "label": "Hayup",
    "value": "Hayup"
  },
  {
    "id": "6309052011",
    "district_id": "630905",
    "label": "Kembang Kuning",
    "value": "Kembang Kuning"
  },
  {
    "id": "6309052012",
    "district_id": "630905",
    "label": "Seradang",
    "value": "Seradang"
  },
  {
    "id": "6309052013",
    "district_id": "630905",
    "label": "Mahe Pasar",
    "value": "Mahe Pasar"
  },
  {
    "id": "6309052014",
    "district_id": "630905",
    "label": "Lok Batu",
    "value": "Lok Batu"
  },
  {
    "id": "6309052015",
    "district_id": "630905",
    "label": "Suput",
    "value": "Suput"
  },
  {
    "id": "6309052018",
    "district_id": "630905",
    "label": "Surian",
    "value": "Surian"
  },
  {
    "id": "6309052021",
    "district_id": "630905",
    "label": "Catur Karya",
    "value": "Catur Karya"
  },
  {
    "id": "6309061001",
    "district_id": "630906",
    "label": "Belimbing Raya",
    "value": "Belimbing Raya"
  },
  {
    "id": "6309061002",
    "district_id": "630906",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "6309061003",
    "district_id": "630906",
    "label": "Sulingan",
    "value": "Sulingan"
  },
  {
    "id": "6309061006",
    "district_id": "630906",
    "label": "Mabu'un",
    "value": "Mabu'un"
  },
  {
    "id": "6309061009",
    "district_id": "630906",
    "label": "Pembataan",
    "value": "Pembataan"
  },
  {
    "id": "6309062004",
    "district_id": "630906",
    "label": "Maburai",
    "value": "Maburai"
  },
  {
    "id": "6309062005",
    "district_id": "630906",
    "label": "Kasiau",
    "value": "Kasiau"
  },
  {
    "id": "6309062007",
    "district_id": "630906",
    "label": "Kapar",
    "value": "Kapar"
  },
  {
    "id": "6309062008",
    "district_id": "630906",
    "label": "Masukau",
    "value": "Masukau"
  },
  {
    "id": "6309062010",
    "district_id": "630906",
    "label": "Kasiau Raya",
    "value": "Kasiau Raya"
  },
  {
    "id": "6309072001",
    "district_id": "630907",
    "label": "Kupang Nunding",
    "value": "Kupang Nunding"
  },
  {
    "id": "6309072002",
    "district_id": "630907",
    "label": "Mangkupum",
    "value": "Mangkupum"
  },
  {
    "id": "6309072003",
    "district_id": "630907",
    "label": "Pasar Batu",
    "value": "Pasar Batu"
  },
  {
    "id": "6309072004",
    "district_id": "630907",
    "label": "Uwie",
    "value": "Uwie"
  },
  {
    "id": "6309072005",
    "district_id": "630907",
    "label": "Muara Uya",
    "value": "Muara Uya"
  },
  {
    "id": "6309072006",
    "district_id": "630907",
    "label": "Lumbang",
    "value": "Lumbang"
  },
  {
    "id": "6309072007",
    "district_id": "630907",
    "label": "Santuun",
    "value": "Santuun"
  },
  {
    "id": "6309072008",
    "district_id": "630907",
    "label": "Simpung Layung",
    "value": "Simpung Layung"
  },
  {
    "id": "6309072009",
    "district_id": "630907",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "6309072010",
    "district_id": "630907",
    "label": "Palapi",
    "value": "Palapi"
  },
  {
    "id": "6309072011",
    "district_id": "630907",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6309072012",
    "district_id": "630907",
    "label": "Salikung",
    "value": "Salikung"
  },
  {
    "id": "6309072013",
    "district_id": "630907",
    "label": "Ribang",
    "value": "Ribang"
  },
  {
    "id": "6309072014",
    "district_id": "630907",
    "label": "Sungai Kumap",
    "value": "Sungai Kumap"
  },
  {
    "id": "6309082001",
    "district_id": "630908",
    "label": "Madang",
    "value": "Madang"
  },
  {
    "id": "6309082002",
    "district_id": "630908",
    "label": "Tantaringin",
    "value": "Tantaringin"
  },
  {
    "id": "6309082003",
    "district_id": "630908",
    "label": "Murung Karangan",
    "value": "Murung Karangan"
  },
  {
    "id": "6309082004",
    "district_id": "630908",
    "label": "Padangin",
    "value": "Padangin"
  },
  {
    "id": "6309082005",
    "district_id": "630908",
    "label": "Manduin",
    "value": "Manduin"
  },
  {
    "id": "6309082006",
    "district_id": "630908",
    "label": "Mantuil",
    "value": "Mantuil"
  },
  {
    "id": "6309082007",
    "district_id": "630908",
    "label": "Harus",
    "value": "Harus"
  },
  {
    "id": "6309092001",
    "district_id": "630909",
    "label": "Pugaan",
    "value": "Pugaan"
  },
  {
    "id": "6309092002",
    "district_id": "630909",
    "label": "Pampanan",
    "value": "Pampanan"
  },
  {
    "id": "6309092003",
    "district_id": "630909",
    "label": "Sungai Rukam II",
    "value": "Sungai Rukam II"
  },
  {
    "id": "6309092004",
    "district_id": "630909",
    "label": "Sungai Rukam I",
    "value": "Sungai Rukam I"
  },
  {
    "id": "6309092005",
    "district_id": "630909",
    "label": "Jirak",
    "value": "Jirak"
  },
  {
    "id": "6309092006",
    "district_id": "630909",
    "label": "Halangan",
    "value": "Halangan"
  },
  {
    "id": "6309092007",
    "district_id": "630909",
    "label": "Tamunti",
    "value": "Tamunti"
  },
  {
    "id": "6309102001",
    "district_id": "630910",
    "label": "Bilas",
    "value": "Bilas"
  },
  {
    "id": "6309102002",
    "district_id": "630910",
    "label": "Kaong",
    "value": "Kaong"
  },
  {
    "id": "6309102003",
    "district_id": "630910",
    "label": "Pangelak",
    "value": "Pangelak"
  },
  {
    "id": "6309102004",
    "district_id": "630910",
    "label": "Kinarum",
    "value": "Kinarum"
  },
  {
    "id": "6309102005",
    "district_id": "630910",
    "label": "Masingai II",
    "value": "Masingai II"
  },
  {
    "id": "6309102006",
    "district_id": "630910",
    "label": "Masingai I",
    "value": "Masingai I"
  },
  {
    "id": "6309112001",
    "district_id": "630911",
    "label": "Teratau",
    "value": "Teratau"
  },
  {
    "id": "6309112002",
    "district_id": "630911",
    "label": "Namun",
    "value": "Namun"
  },
  {
    "id": "6309112003",
    "district_id": "630911",
    "label": "Jaro",
    "value": "Jaro"
  },
  {
    "id": "6309112004",
    "district_id": "630911",
    "label": "Solan",
    "value": "Solan"
  },
  {
    "id": "6309112005",
    "district_id": "630911",
    "label": "Muang",
    "value": "Muang"
  },
  {
    "id": "6309112006",
    "district_id": "630911",
    "label": "Lano",
    "value": "Lano"
  },
  {
    "id": "6309112007",
    "district_id": "630911",
    "label": "Purui",
    "value": "Purui"
  },
  {
    "id": "6309112008",
    "district_id": "630911",
    "label": "Garagata",
    "value": "Garagata"
  },
  {
    "id": "6309112009",
    "district_id": "630911",
    "label": "Nalui",
    "value": "Nalui"
  },
  {
    "id": "6309122001",
    "district_id": "630912",
    "label": "Waling",
    "value": "Waling"
  },
  {
    "id": "6309122002",
    "district_id": "630912",
    "label": "Usih",
    "value": "Usih"
  },
  {
    "id": "6309122003",
    "district_id": "630912",
    "label": "Bintang Ara",
    "value": "Bintang Ara"
  },
  {
    "id": "6309122004",
    "district_id": "630912",
    "label": "Burum",
    "value": "Burum"
  },
  {
    "id": "6309122005",
    "district_id": "630912",
    "label": "Panaan",
    "value": "Panaan"
  },
  {
    "id": "6309122006",
    "district_id": "630912",
    "label": "Dambung Raya",
    "value": "Dambung Raya"
  },
  {
    "id": "6309122007",
    "district_id": "630912",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "6309122008",
    "district_id": "630912",
    "label": "Bumi Makmur",
    "value": "Bumi Makmur"
  },
  {
    "id": "6309122009",
    "district_id": "630912",
    "label": "Hegarmanah",
    "value": "Hegarmanah"
  },
  {
    "id": "6310011007",
    "district_id": "631001",
    "label": "Batulicin",
    "value": "Batulicin"
  },
  {
    "id": "6310011028",
    "district_id": "631001",
    "label": "Gunungtinggi",
    "value": "Gunungtinggi"
  },
  {
    "id": "6310012005",
    "district_id": "631001",
    "label": "Segumbang",
    "value": "Segumbang"
  },
  {
    "id": "6310012006",
    "district_id": "631001",
    "label": "Kersik Putih",
    "value": "Kersik Putih"
  },
  {
    "id": "6310012029",
    "district_id": "631001",
    "label": "Maju Makmur",
    "value": "Maju Makmur"
  },
  {
    "id": "6310012030",
    "district_id": "631001",
    "label": "Maju Bersama",
    "value": "Maju Bersama"
  },
  {
    "id": "6310012031",
    "district_id": "631001",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "6310012032",
    "district_id": "631001",
    "label": "Polewali Marajae",
    "value": "Polewali Marajae"
  },
  {
    "id": "6310012033",
    "district_id": "631001",
    "label": "Danauindah",
    "value": "Danauindah"
  },
  {
    "id": "6310021001",
    "district_id": "631002",
    "label": "Kota Pagatan",
    "value": "Kota Pagatan"
  },
  {
    "id": "6310022002",
    "district_id": "631002",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "6310022003",
    "district_id": "631002",
    "label": "Sungai Lembu",
    "value": "Sungai Lembu"
  },
  {
    "id": "6310022004",
    "district_id": "631002",
    "label": "Wiritasi",
    "value": "Wiritasi"
  },
  {
    "id": "6310022005",
    "district_id": "631002",
    "label": "Pejala",
    "value": "Pejala"
  },
  {
    "id": "6310022006",
    "district_id": "631002",
    "label": "Pagaruyung",
    "value": "Pagaruyung"
  },
  {
    "id": "6310022007",
    "district_id": "631002",
    "label": "Muara Pagatan Tengah",
    "value": "Muara Pagatan Tengah"
  },
  {
    "id": "6310022008",
    "district_id": "631002",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6310022009",
    "district_id": "631002",
    "label": "Pasar Baru",
    "value": "Pasar Baru"
  },
  {
    "id": "6310022010",
    "district_id": "631002",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6310022011",
    "district_id": "631002",
    "label": "Baru Gelang",
    "value": "Baru Gelang"
  },
  {
    "id": "6310022012",
    "district_id": "631002",
    "label": "Pulau Salak",
    "value": "Pulau Salak"
  },
  {
    "id": "6310022013",
    "district_id": "631002",
    "label": "Salimuran",
    "value": "Salimuran"
  },
  {
    "id": "6310022014",
    "district_id": "631002",
    "label": "Satiung",
    "value": "Satiung"
  },
  {
    "id": "6310022015",
    "district_id": "631002",
    "label": "Saring Sungai Binjai",
    "value": "Saring Sungai Binjai"
  },
  {
    "id": "6310022016",
    "district_id": "631002",
    "label": "Pulau Tanjung",
    "value": "Pulau Tanjung"
  },
  {
    "id": "6310022017",
    "district_id": "631002",
    "label": "Batarang",
    "value": "Batarang"
  },
  {
    "id": "6310022018",
    "district_id": "631002",
    "label": "Manurung",
    "value": "Manurung"
  },
  {
    "id": "6310022019",
    "district_id": "631002",
    "label": "Mudalang",
    "value": "Mudalang"
  },
  {
    "id": "6310022020",
    "district_id": "631002",
    "label": "Tanette",
    "value": "Tanette"
  },
  {
    "id": "6310022021",
    "district_id": "631002",
    "label": "Muara Pagatan",
    "value": "Muara Pagatan"
  },
  {
    "id": "6310022022",
    "district_id": "631002",
    "label": "Pulau Satu",
    "value": "Pulau Satu"
  },
  {
    "id": "6310022023",
    "district_id": "631002",
    "label": "Sepunggur",
    "value": "Sepunggur"
  },
  {
    "id": "6310022024",
    "district_id": "631002",
    "label": "Pakatellu",
    "value": "Pakatellu"
  },
  {
    "id": "6310022025",
    "district_id": "631002",
    "label": "Saring S. Bubu",
    "value": "Saring S. Bubu"
  },
  {
    "id": "6310022026",
    "district_id": "631002",
    "label": "Serdangan",
    "value": "Serdangan"
  },
  {
    "id": "6310022027",
    "district_id": "631002",
    "label": "Juku Eja",
    "value": "Juku Eja"
  },
  {
    "id": "6310022028",
    "district_id": "631002",
    "label": "Api-Api",
    "value": "Api-Api"
  },
  {
    "id": "6310022029",
    "district_id": "631002",
    "label": "Gusunge",
    "value": "Gusunge"
  },
  {
    "id": "6310022030",
    "district_id": "631002",
    "label": "Rantau Panjang Hulu",
    "value": "Rantau Panjang Hulu"
  },
  {
    "id": "6310022031",
    "district_id": "631002",
    "label": "Penyolongan",
    "value": "Penyolongan"
  },
  {
    "id": "6310022032",
    "district_id": "631002",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "6310022033",
    "district_id": "631002",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6310022034",
    "district_id": "631002",
    "label": "Rantau Panjang Hilir",
    "value": "Rantau Panjang Hilir"
  },
  {
    "id": "6310022035",
    "district_id": "631002",
    "label": "Upt. Karya Bakti",
    "value": "Upt. Karya Bakti"
  },
  {
    "id": "6310032001",
    "district_id": "631003",
    "label": "Sari Mulya",
    "value": "Sari Mulya"
  },
  {
    "id": "6310032002",
    "district_id": "631003",
    "label": "Sungailoban",
    "value": "Sungailoban"
  },
  {
    "id": "6310032003",
    "district_id": "631003",
    "label": "Sebamban Lama",
    "value": "Sebamban Lama"
  },
  {
    "id": "6310032004",
    "district_id": "631003",
    "label": "Sebamban Baru",
    "value": "Sebamban Baru"
  },
  {
    "id": "6310032005",
    "district_id": "631003",
    "label": "Sungaidua Laut",
    "value": "Sungaidua Laut"
  },
  {
    "id": "6310032006",
    "district_id": "631003",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "6310032007",
    "district_id": "631003",
    "label": "Sari Utama",
    "value": "Sari Utama"
  },
  {
    "id": "6310032008",
    "district_id": "631003",
    "label": "Tri Mulya",
    "value": "Tri Mulya"
  },
  {
    "id": "6310032010",
    "district_id": "631003",
    "label": "Dwi Marga Utama",
    "value": "Dwi Marga Utama"
  },
  {
    "id": "6310032011",
    "district_id": "631003",
    "label": "Kerta Buana",
    "value": "Kerta Buana"
  },
  {
    "id": "6310032012",
    "district_id": "631003",
    "label": "Batu Meranti",
    "value": "Batu Meranti"
  },
  {
    "id": "6310032014",
    "district_id": "631003",
    "label": "Tri Martani",
    "value": "Tri Martani"
  },
  {
    "id": "6310032015",
    "district_id": "631003",
    "label": "Sumber makmur",
    "value": "Sumber makmur"
  },
  {
    "id": "6310032016",
    "district_id": "631003",
    "label": "Biduri Bersujud",
    "value": "Biduri Bersujud"
  },
  {
    "id": "6310032017",
    "district_id": "631003",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6310032018",
    "district_id": "631003",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "6310032019",
    "district_id": "631003",
    "label": "Damar Indah",
    "value": "Damar Indah"
  },
  {
    "id": "6310042003",
    "district_id": "631004",
    "label": "Setarap",
    "value": "Setarap"
  },
  {
    "id": "6310042004",
    "district_id": "631004",
    "label": "Satui Timur",
    "value": "Satui Timur"
  },
  {
    "id": "6310042005",
    "district_id": "631004",
    "label": "Sungaicuka",
    "value": "Sungaicuka"
  },
  {
    "id": "6310042006",
    "district_id": "631004",
    "label": "Jombang",
    "value": "Jombang"
  },
  {
    "id": "6310042008",
    "district_id": "631004",
    "label": "Satui Barat",
    "value": "Satui Barat"
  },
  {
    "id": "6310042009",
    "district_id": "631004",
    "label": "Sekapuk",
    "value": "Sekapuk"
  },
  {
    "id": "6310042010",
    "district_id": "631004",
    "label": "Sungaidanau",
    "value": "Sungaidanau"
  },
  {
    "id": "6310042011",
    "district_id": "631004",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "6310042012",
    "district_id": "631004",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "6310042013",
    "district_id": "631004",
    "label": "Tegal Sari",
    "value": "Tegal Sari"
  },
  {
    "id": "6310042019",
    "district_id": "631004",
    "label": "Sumber Arum",
    "value": "Sumber Arum"
  },
  {
    "id": "6310042022",
    "district_id": "631004",
    "label": "Sejahtera Mulia",
    "value": "Sejahtera Mulia"
  },
  {
    "id": "6310042023",
    "district_id": "631004",
    "label": "Al Kautsar",
    "value": "Al Kautsar"
  },
  {
    "id": "6310042024",
    "district_id": "631004",
    "label": "Makmur Mulia",
    "value": "Makmur Mulia"
  },
  {
    "id": "6310042025",
    "district_id": "631004",
    "label": "Sinar Bulan",
    "value": "Sinar Bulan"
  },
  {
    "id": "6310042026",
    "district_id": "631004",
    "label": "Pandamaran Jaya",
    "value": "Pandamaran Jaya"
  },
  {
    "id": "6310052001",
    "district_id": "631005",
    "label": "Lasung",
    "value": "Lasung"
  },
  {
    "id": "6310052002",
    "district_id": "631005",
    "label": "Manuntung",
    "value": "Manuntung"
  },
  {
    "id": "6310052003",
    "district_id": "631005",
    "label": "Anjir Baru",
    "value": "Anjir Baru"
  },
  {
    "id": "6310052004",
    "district_id": "631005",
    "label": "Binawara",
    "value": "Binawara"
  },
  {
    "id": "6310052005",
    "district_id": "631005",
    "label": "Pacakan",
    "value": "Pacakan"
  },
  {
    "id": "6310052006",
    "district_id": "631005",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "6310052007",
    "district_id": "631005",
    "label": "Teluk Kepayang",
    "value": "Teluk Kepayang"
  },
  {
    "id": "6310052008",
    "district_id": "631005",
    "label": "Tapus",
    "value": "Tapus"
  },
  {
    "id": "6310052009",
    "district_id": "631005",
    "label": "Mangkalapi",
    "value": "Mangkalapi"
  },
  {
    "id": "6310052010",
    "district_id": "631005",
    "label": "Hati'if",
    "value": "Hati'if"
  },
  {
    "id": "6310052011",
    "district_id": "631005",
    "label": "Timbarau Panjang",
    "value": "Timbarau Panjang"
  },
  {
    "id": "6310052012",
    "district_id": "631005",
    "label": "Sungai Rukam",
    "value": "Sungai Rukam"
  },
  {
    "id": "6310052013",
    "district_id": "631005",
    "label": "Darasan Binjai",
    "value": "Darasan Binjai"
  },
  {
    "id": "6310052015",
    "district_id": "631005",
    "label": "Bakarangan",
    "value": "Bakarangan"
  },
  {
    "id": "6310052019",
    "district_id": "631005",
    "label": "Karang Mulya",
    "value": "Karang Mulya"
  },
  {
    "id": "6310052020",
    "district_id": "631005",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "6310052021",
    "district_id": "631005",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "6310052022",
    "district_id": "631005",
    "label": "Karang Sari",
    "value": "Karang Sari"
  },
  {
    "id": "6310052023",
    "district_id": "631005",
    "label": "Tamunih",
    "value": "Tamunih"
  },
  {
    "id": "6310052024",
    "district_id": "631005",
    "label": "Dadap Kusan Raya",
    "value": "Dadap Kusan Raya"
  },
  {
    "id": "6310052025",
    "district_id": "631005",
    "label": "Batu Bulan",
    "value": "Batu Bulan"
  },
  {
    "id": "6310061001",
    "district_id": "631006",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6310061002",
    "district_id": "631006",
    "label": "Tungkaran Pangeran",
    "value": "Tungkaran Pangeran"
  },
  {
    "id": "6310062003",
    "district_id": "631006",
    "label": "Sari Gadung",
    "value": "Sari Gadung"
  },
  {
    "id": "6310062004",
    "district_id": "631006",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6310062005",
    "district_id": "631006",
    "label": "Sungaidua",
    "value": "Sungaidua"
  },
  {
    "id": "6310062006",
    "district_id": "631006",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6310062007",
    "district_id": "631006",
    "label": "Gunungbesar",
    "value": "Gunungbesar"
  },
  {
    "id": "6310062008",
    "district_id": "631006",
    "label": "Pulau Burung",
    "value": "Pulau Burung"
  },
  {
    "id": "6310062009",
    "district_id": "631006",
    "label": "Baroqah",
    "value": "Baroqah"
  },
  {
    "id": "6310062010",
    "district_id": "631006",
    "label": "Bersujud",
    "value": "Bersujud"
  },
  {
    "id": "6310062011",
    "district_id": "631006",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "6310062012",
    "district_id": "631006",
    "label": "Gunungantasari",
    "value": "Gunungantasari"
  },
  {
    "id": "6310072001",
    "district_id": "631007",
    "label": "Karang Bintang",
    "value": "Karang Bintang"
  },
  {
    "id": "6310072002",
    "district_id": "631007",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "6310072003",
    "district_id": "631007",
    "label": "Rejo Winangun",
    "value": "Rejo Winangun"
  },
  {
    "id": "6310072004",
    "district_id": "631007",
    "label": "Selaselilau",
    "value": "Selaselilau"
  },
  {
    "id": "6310072005",
    "district_id": "631007",
    "label": "Pematang Ulin",
    "value": "Pematang Ulin"
  },
  {
    "id": "6310072006",
    "district_id": "631007",
    "label": "Batu Licin Irigasi",
    "value": "Batu Licin Irigasi"
  },
  {
    "id": "6310072007",
    "district_id": "631007",
    "label": "Manunggal",
    "value": "Manunggal"
  },
  {
    "id": "6310072009",
    "district_id": "631007",
    "label": "Sumber Wangi",
    "value": "Sumber Wangi"
  },
  {
    "id": "6310072010",
    "district_id": "631007",
    "label": "Madu Retno",
    "value": "Madu Retno"
  },
  {
    "id": "6310072011",
    "district_id": "631007",
    "label": "Maju Sejahtera",
    "value": "Maju Sejahtera"
  },
  {
    "id": "6310072012",
    "district_id": "631007",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "6310082001",
    "district_id": "631008",
    "label": "Mantewe",
    "value": "Mantewe"
  },
  {
    "id": "6310082002",
    "district_id": "631008",
    "label": "Dukuh Rejo",
    "value": "Dukuh Rejo"
  },
  {
    "id": "6310082003",
    "district_id": "631008",
    "label": "Rejosari",
    "value": "Rejosari"
  },
  {
    "id": "6310082004",
    "district_id": "631008",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6310082005",
    "district_id": "631008",
    "label": "Bulu Rejo",
    "value": "Bulu Rejo"
  },
  {
    "id": "6310082006",
    "district_id": "631008",
    "label": "Sido Mulyo",
    "value": "Sido Mulyo"
  },
  {
    "id": "6310082008",
    "district_id": "631008",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "6310082009",
    "district_id": "631008",
    "label": "Sari Mulya",
    "value": "Sari Mulya"
  },
  {
    "id": "6310082010",
    "district_id": "631008",
    "label": "Emil Baru",
    "value": "Emil Baru"
  },
  {
    "id": "6310082011",
    "district_id": "631008",
    "label": "Mentawakan Mulia",
    "value": "Mentawakan Mulia"
  },
  {
    "id": "6310082012",
    "district_id": "631008",
    "label": "Maju Mulyo",
    "value": "Maju Mulyo"
  },
  {
    "id": "6310082014",
    "district_id": "631008",
    "label": "Gunungraya",
    "value": "Gunungraya"
  },
  {
    "id": "6310092001",
    "district_id": "631009",
    "label": "Bunati",
    "value": "Bunati"
  },
  {
    "id": "6310092002",
    "district_id": "631009",
    "label": "Purwodadi",
    "value": "Purwodadi"
  },
  {
    "id": "6310092003",
    "district_id": "631009",
    "label": "Sumber Baru",
    "value": "Sumber Baru"
  },
  {
    "id": "6310092004",
    "district_id": "631009",
    "label": "Karang Indah",
    "value": "Karang Indah"
  },
  {
    "id": "6310092005",
    "district_id": "631009",
    "label": "Angsana",
    "value": "Angsana"
  },
  {
    "id": "6310092006",
    "district_id": "631009",
    "label": "Banjar Sari",
    "value": "Banjar Sari"
  },
  {
    "id": "6310092007",
    "district_id": "631009",
    "label": "Bayan Sari",
    "value": "Bayan Sari"
  },
  {
    "id": "6310092008",
    "district_id": "631009",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "6310092009",
    "district_id": "631009",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6310102001",
    "district_id": "631010",
    "label": "Giri Mulya",
    "value": "Giri Mulya"
  },
  {
    "id": "6310102002",
    "district_id": "631010",
    "label": "Kuranji",
    "value": "Kuranji"
  },
  {
    "id": "6310102003",
    "district_id": "631010",
    "label": "Waringin Tunggal",
    "value": "Waringin Tunggal"
  },
  {
    "id": "6310102004",
    "district_id": "631010",
    "label": "Mustika",
    "value": "Mustika"
  },
  {
    "id": "6310102005",
    "district_id": "631010",
    "label": "Indra Lokajaya",
    "value": "Indra Lokajaya"
  },
  {
    "id": "6310102006",
    "district_id": "631010",
    "label": "Karang Intan",
    "value": "Karang Intan"
  },
  {
    "id": "6310102007",
    "district_id": "631010",
    "label": "Ringkit",
    "value": "Ringkit"
  },
  {
    "id": "6311012001",
    "district_id": "631101",
    "label": "Muara Ninian",
    "value": "Muara Ninian"
  },
  {
    "id": "6311012002",
    "district_id": "631101",
    "label": "Hamarung",
    "value": "Hamarung"
  },
  {
    "id": "6311012003",
    "district_id": "631101",
    "label": "Juai",
    "value": "Juai"
  },
  {
    "id": "6311012004",
    "district_id": "631101",
    "label": "Buntu Karau",
    "value": "Buntu Karau"
  },
  {
    "id": "6311012005",
    "district_id": "631101",
    "label": "Bata",
    "value": "Bata"
  },
  {
    "id": "6311012006",
    "district_id": "631101",
    "label": "Galumbang",
    "value": "Galumbang"
  },
  {
    "id": "6311012007",
    "district_id": "631101",
    "label": "Sungai Batung",
    "value": "Sungai Batung"
  },
  {
    "id": "6311012008",
    "district_id": "631101",
    "label": "Sirap",
    "value": "Sirap"
  },
  {
    "id": "6311012009",
    "district_id": "631101",
    "label": "Tigarun",
    "value": "Tigarun"
  },
  {
    "id": "6311012010",
    "district_id": "631101",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "6311012011",
    "district_id": "631101",
    "label": "Pamurus",
    "value": "Pamurus"
  },
  {
    "id": "6311012012",
    "district_id": "631101",
    "label": "Marias",
    "value": "Marias"
  },
  {
    "id": "6311012013",
    "district_id": "631101",
    "label": "Lalayau",
    "value": "Lalayau"
  },
  {
    "id": "6311012014",
    "district_id": "631101",
    "label": "Mihu",
    "value": "Mihu"
  },
  {
    "id": "6311012015",
    "district_id": "631101",
    "label": "Hukai",
    "value": "Hukai"
  },
  {
    "id": "6311012016",
    "district_id": "631101",
    "label": "Tawahan",
    "value": "Tawahan"
  },
  {
    "id": "6311012017",
    "district_id": "631101",
    "label": "Gulinggang",
    "value": "Gulinggang"
  },
  {
    "id": "6311012018",
    "district_id": "631101",
    "label": "Mungkur Uyam",
    "value": "Mungkur Uyam"
  },
  {
    "id": "6311012019",
    "district_id": "631101",
    "label": "Panimbaan",
    "value": "Panimbaan"
  },
  {
    "id": "6311012020",
    "district_id": "631101",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "6311012021",
    "district_id": "631101",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "6311022001",
    "district_id": "631102",
    "label": "Hauwai",
    "value": "Hauwai"
  },
  {
    "id": "6311022002",
    "district_id": "631102",
    "label": "Bangkal",
    "value": "Bangkal"
  },
  {
    "id": "6311022003",
    "district_id": "631102",
    "label": "Mantuyan",
    "value": "Mantuyan"
  },
  {
    "id": "6311022004",
    "district_id": "631102",
    "label": "Tabuan",
    "value": "Tabuan"
  },
  {
    "id": "6311022005",
    "district_id": "631102",
    "label": "Halong",
    "value": "Halong"
  },
  {
    "id": "6311022008",
    "district_id": "631102",
    "label": "Puyun",
    "value": "Puyun"
  },
  {
    "id": "6311022009",
    "district_id": "631102",
    "label": "Buntu Pilanduk",
    "value": "Buntu Pilanduk"
  },
  {
    "id": "6311022010",
    "district_id": "631102",
    "label": "Gunung Riut",
    "value": "Gunung Riut"
  },
  {
    "id": "6311022011",
    "district_id": "631102",
    "label": "Kapul",
    "value": "Kapul"
  },
  {
    "id": "6311022012",
    "district_id": "631102",
    "label": "Mamantang",
    "value": "Mamantang"
  },
  {
    "id": "6311022013",
    "district_id": "631102",
    "label": "Binjai Punggal",
    "value": "Binjai Punggal"
  },
  {
    "id": "6311022014",
    "district_id": "631102",
    "label": "Liyu",
    "value": "Liyu"
  },
  {
    "id": "6311022015",
    "district_id": "631102",
    "label": "Binuang Santang",
    "value": "Binuang Santang"
  },
  {
    "id": "6311022016",
    "district_id": "631102",
    "label": "Aniungan",
    "value": "Aniungan"
  },
  {
    "id": "6311022017",
    "district_id": "631102",
    "label": "Binju",
    "value": "Binju"
  },
  {
    "id": "6311022018",
    "district_id": "631102",
    "label": "Karya",
    "value": "Karya"
  },
  {
    "id": "6311022019",
    "district_id": "631102",
    "label": "Uren",
    "value": "Uren"
  },
  {
    "id": "6311022020",
    "district_id": "631102",
    "label": "Marajai",
    "value": "Marajai"
  },
  {
    "id": "6311022021",
    "district_id": "631102",
    "label": "Suryatama",
    "value": "Suryatama"
  },
  {
    "id": "6311022022",
    "district_id": "631102",
    "label": "Baruh Panyambaran",
    "value": "Baruh Panyambaran"
  },
  {
    "id": "6311022023",
    "district_id": "631102",
    "label": "Mauya",
    "value": "Mauya"
  },
  {
    "id": "6311022024",
    "district_id": "631102",
    "label": "Padang Raya",
    "value": "Padang Raya"
  },
  {
    "id": "6311022025",
    "district_id": "631102",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "6311022026",
    "district_id": "631102",
    "label": "Mamigang",
    "value": "Mamigang"
  },
  {
    "id": "6311032004",
    "district_id": "631103",
    "label": "Bihara",
    "value": "Bihara"
  },
  {
    "id": "6311032005",
    "district_id": "631103",
    "label": "Pematang",
    "value": "Pematang"
  },
  {
    "id": "6311032006",
    "district_id": "631103",
    "label": "Merah",
    "value": "Merah"
  },
  {
    "id": "6311032007",
    "district_id": "631103",
    "label": "Awayan",
    "value": "Awayan"
  },
  {
    "id": "6311032008",
    "district_id": "631103",
    "label": "Pudak",
    "value": "Pudak"
  },
  {
    "id": "6311032009",
    "district_id": "631103",
    "label": "Badalungga",
    "value": "Badalungga"
  },
  {
    "id": "6311032010",
    "district_id": "631103",
    "label": "Tundakan",
    "value": "Tundakan"
  },
  {
    "id": "6311032011",
    "district_id": "631103",
    "label": "Sikontan",
    "value": "Sikontan"
  },
  {
    "id": "6311032012",
    "district_id": "631103",
    "label": "Pulantan",
    "value": "Pulantan"
  },
  {
    "id": "6311032013",
    "district_id": "631103",
    "label": "Tundi",
    "value": "Tundi"
  },
  {
    "id": "6311032014",
    "district_id": "631103",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "6311032016",
    "district_id": "631103",
    "label": "Bihara Hilir",
    "value": "Bihara Hilir"
  },
  {
    "id": "6311032018",
    "district_id": "631103",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "6311032020",
    "district_id": "631103",
    "label": "Awayan Hilir",
    "value": "Awayan Hilir"
  },
  {
    "id": "6311032021",
    "district_id": "631103",
    "label": "Putat Basiun",
    "value": "Putat Basiun"
  },
  {
    "id": "6311032022",
    "district_id": "631103",
    "label": "Sei Pumpung",
    "value": "Sei Pumpung"
  },
  {
    "id": "6311032024",
    "district_id": "631103",
    "label": "Badalungga Hilir",
    "value": "Badalungga Hilir"
  },
  {
    "id": "6311032025",
    "district_id": "631103",
    "label": "Nungka",
    "value": "Nungka"
  },
  {
    "id": "6311032026",
    "district_id": "631103",
    "label": "Tangalin",
    "value": "Tangalin"
  },
  {
    "id": "6311032027",
    "district_id": "631103",
    "label": "Kedondong",
    "value": "Kedondong"
  },
  {
    "id": "6311032029",
    "district_id": "631103",
    "label": "Baramban",
    "value": "Baramban"
  },
  {
    "id": "6311032031",
    "district_id": "631103",
    "label": "Ambakiang",
    "value": "Ambakiang"
  },
  {
    "id": "6311032032",
    "district_id": "631103",
    "label": "Piyait",
    "value": "Piyait"
  },
  {
    "id": "6311042001",
    "district_id": "631104",
    "label": "Tariwin",
    "value": "Tariwin"
  },
  {
    "id": "6311042002",
    "district_id": "631104",
    "label": "Lok Batu",
    "value": "Lok Batu"
  },
  {
    "id": "6311042003",
    "district_id": "631104",
    "label": "Munjung",
    "value": "Munjung"
  },
  {
    "id": "6311042004",
    "district_id": "631104",
    "label": "Pelajau",
    "value": "Pelajau"
  },
  {
    "id": "6311042005",
    "district_id": "631104",
    "label": "Batumandi",
    "value": "Batumandi"
  },
  {
    "id": "6311042006",
    "district_id": "631104",
    "label": "Riwa",
    "value": "Riwa"
  },
  {
    "id": "6311042007",
    "district_id": "631104",
    "label": "Mantimin",
    "value": "Mantimin"
  },
  {
    "id": "6311042008",
    "district_id": "631104",
    "label": "Mampari",
    "value": "Mampari"
  },
  {
    "id": "6311042009",
    "district_id": "631104",
    "label": "Bungur",
    "value": "Bungur"
  },
  {
    "id": "6311042010",
    "district_id": "631104",
    "label": "Teluk Mesjid",
    "value": "Teluk Mesjid"
  },
  {
    "id": "6311042011",
    "district_id": "631104",
    "label": "Timbun Tulang",
    "value": "Timbun Tulang"
  },
  {
    "id": "6311042012",
    "district_id": "631104",
    "label": "Banua Hanyar",
    "value": "Banua Hanyar"
  },
  {
    "id": "6311042013",
    "district_id": "631104",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "6311042014",
    "district_id": "631104",
    "label": "Karuh",
    "value": "Karuh"
  },
  {
    "id": "6311042015",
    "district_id": "631104",
    "label": "Guha",
    "value": "Guha"
  },
  {
    "id": "6311042016",
    "district_id": "631104",
    "label": "Gunung Manau",
    "value": "Gunung Manau"
  },
  {
    "id": "6311042017",
    "district_id": "631104",
    "label": "Hampa Raya",
    "value": "Hampa Raya"
  },
  {
    "id": "6311042018",
    "district_id": "631104",
    "label": "Kasai",
    "value": "Kasai"
  },
  {
    "id": "6311052001",
    "district_id": "631105",
    "label": "Tanah Habang Kiri",
    "value": "Tanah Habang Kiri"
  },
  {
    "id": "6311052002",
    "district_id": "631105",
    "label": "Panaitan",
    "value": "Panaitan"
  },
  {
    "id": "6311052003",
    "district_id": "631105",
    "label": "Tanah Habang Kanan",
    "value": "Tanah Habang Kanan"
  },
  {
    "id": "6311052004",
    "district_id": "631105",
    "label": "Batu Merah",
    "value": "Batu Merah"
  },
  {
    "id": "6311052005",
    "district_id": "631105",
    "label": "Lampihong Kanan",
    "value": "Lampihong Kanan"
  },
  {
    "id": "6311052006",
    "district_id": "631105",
    "label": "Lampihong Selatan",
    "value": "Lampihong Selatan"
  },
  {
    "id": "6311052007",
    "district_id": "631105",
    "label": "Lampihong Kiri",
    "value": "Lampihong Kiri"
  },
  {
    "id": "6311052008",
    "district_id": "631105",
    "label": "Lajar",
    "value": "Lajar"
  },
  {
    "id": "6311052009",
    "district_id": "631105",
    "label": "Kusambi Hulu",
    "value": "Kusambi Hulu"
  },
  {
    "id": "6311052010",
    "district_id": "631105",
    "label": "Kusambi Hilir",
    "value": "Kusambi Hilir"
  },
  {
    "id": "6311052011",
    "district_id": "631105",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "6311052012",
    "district_id": "631105",
    "label": "Matang Lurus",
    "value": "Matang Lurus"
  },
  {
    "id": "6311052013",
    "district_id": "631105",
    "label": "Lok Hamawang",
    "value": "Lok Hamawang"
  },
  {
    "id": "6311052014",
    "district_id": "631105",
    "label": "Kupang",
    "value": "Kupang"
  },
  {
    "id": "6311052015",
    "district_id": "631105",
    "label": "Tampang",
    "value": "Tampang"
  },
  {
    "id": "6311052016",
    "district_id": "631105",
    "label": "Matang Hanau",
    "value": "Matang Hanau"
  },
  {
    "id": "6311052017",
    "district_id": "631105",
    "label": "Lok Panginangan",
    "value": "Lok Panginangan"
  },
  {
    "id": "6311052018",
    "district_id": "631105",
    "label": "Jungkal",
    "value": "Jungkal"
  },
  {
    "id": "6311052019",
    "district_id": "631105",
    "label": "Sungai Tabuk",
    "value": "Sungai Tabuk"
  },
  {
    "id": "6311052020",
    "district_id": "631105",
    "label": "Jimamun",
    "value": "Jimamun"
  },
  {
    "id": "6311052021",
    "district_id": "631105",
    "label": "Pimping",
    "value": "Pimping"
  },
  {
    "id": "6311052022",
    "district_id": "631105",
    "label": "Hilir Pasar",
    "value": "Hilir Pasar"
  },
  {
    "id": "6311052023",
    "district_id": "631105",
    "label": "Teluk Karya",
    "value": "Teluk Karya"
  },
  {
    "id": "6311052024",
    "district_id": "631105",
    "label": "Pupuyuan",
    "value": "Pupuyuan"
  },
  {
    "id": "6311052025",
    "district_id": "631105",
    "label": "Sungai Awang",
    "value": "Sungai Awang"
  },
  {
    "id": "6311052026",
    "district_id": "631105",
    "label": "Kandang Jaya",
    "value": "Kandang Jaya"
  },
  {
    "id": "6311052027",
    "district_id": "631105",
    "label": "Mundar",
    "value": "Mundar"
  },
  {
    "id": "6311061019",
    "district_id": "631106",
    "label": "Paringin Timur",
    "value": "Paringin Timur"
  },
  {
    "id": "6311061023",
    "district_id": "631106",
    "label": "Paringin Kota",
    "value": "Paringin Kota"
  },
  {
    "id": "6311062005",
    "district_id": "631106",
    "label": "Balang",
    "value": "Balang"
  },
  {
    "id": "6311062006",
    "district_id": "631106",
    "label": "Kalahiang",
    "value": "Kalahiang"
  },
  {
    "id": "6311062007",
    "district_id": "631106",
    "label": "Lasung Batu",
    "value": "Lasung Batu"
  },
  {
    "id": "6311062008",
    "district_id": "631106",
    "label": "Paran",
    "value": "Paran"
  },
  {
    "id": "6311062009",
    "district_id": "631106",
    "label": "Layap",
    "value": "Layap"
  },
  {
    "id": "6311062012",
    "district_id": "631106",
    "label": "Murung Ilung",
    "value": "Murung Ilung"
  },
  {
    "id": "6311062013",
    "district_id": "631106",
    "label": "Mangkayahu",
    "value": "Mangkayahu"
  },
  {
    "id": "6311062014",
    "district_id": "631106",
    "label": "Lok Batung",
    "value": "Lok Batung"
  },
  {
    "id": "6311062015",
    "district_id": "631106",
    "label": "Lamida Bawah",
    "value": "Lamida Bawah"
  },
  {
    "id": "6311062017",
    "district_id": "631106",
    "label": "Dahai",
    "value": "Dahai"
  },
  {
    "id": "6311062022",
    "district_id": "631106",
    "label": "Hujan Mas",
    "value": "Hujan Mas"
  },
  {
    "id": "6311062024",
    "district_id": "631106",
    "label": "Babayau",
    "value": "Babayau"
  },
  {
    "id": "6311062034",
    "district_id": "631106",
    "label": "Balida",
    "value": "Balida"
  },
  {
    "id": "6311062038",
    "district_id": "631106",
    "label": "Sungai Ketapi",
    "value": "Sungai Ketapi"
  },
  {
    "id": "6311071001",
    "district_id": "631107",
    "label": "Batu Piring",
    "value": "Batu Piring"
  },
  {
    "id": "6311072002",
    "district_id": "631107",
    "label": "Baruh Bahinu Luar",
    "value": "Baruh Bahinu Luar"
  },
  {
    "id": "6311072003",
    "district_id": "631107",
    "label": "Inan",
    "value": "Inan"
  },
  {
    "id": "6311072004",
    "district_id": "631107",
    "label": "Baruh Bahinu Dalam",
    "value": "Baruh Bahinu Dalam"
  },
  {
    "id": "6311072005",
    "district_id": "631107",
    "label": "Panggung",
    "value": "Panggung"
  },
  {
    "id": "6311072006",
    "district_id": "631107",
    "label": "Galumbang",
    "value": "Galumbang"
  },
  {
    "id": "6311072007",
    "district_id": "631107",
    "label": "Halubau",
    "value": "Halubau"
  },
  {
    "id": "6311072008",
    "district_id": "631107",
    "label": "Binjai",
    "value": "Binjai"
  },
  {
    "id": "6311072009",
    "district_id": "631107",
    "label": "Murung Abuin",
    "value": "Murung Abuin"
  },
  {
    "id": "6311072010",
    "district_id": "631107",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "6311072011",
    "district_id": "631107",
    "label": "Maradap",
    "value": "Maradap"
  },
  {
    "id": "6311072012",
    "district_id": "631107",
    "label": "Halubau Utara",
    "value": "Halubau Utara"
  },
  {
    "id": "6311072013",
    "district_id": "631107",
    "label": "Murung Jambu",
    "value": "Murung Jambu"
  },
  {
    "id": "6311072014",
    "district_id": "631107",
    "label": "Telaga Purun",
    "value": "Telaga Purun"
  },
  {
    "id": "6311072015",
    "district_id": "631107",
    "label": "Lingsir",
    "value": "Lingsir"
  },
  {
    "id": "6311072016",
    "district_id": "631107",
    "label": "Tarangan",
    "value": "Tarangan"
  },
  {
    "id": "6311082001",
    "district_id": "631108",
    "label": "Dayak Pitap",
    "value": "Dayak Pitap"
  },
  {
    "id": "6311082002",
    "district_id": "631108",
    "label": "Tebing Tinggi",
    "value": "Tebing Tinggi"
  },
  {
    "id": "6311082003",
    "district_id": "631108",
    "label": "Sungsum",
    "value": "Sungsum"
  },
  {
    "id": "6311082004",
    "district_id": "631108",
    "label": "Ju'uh",
    "value": "Ju'uh"
  },
  {
    "id": "6311082005",
    "district_id": "631108",
    "label": "Mayanau",
    "value": "Mayanau"
  },
  {
    "id": "6311082006",
    "district_id": "631108",
    "label": "Simpang Bumbuan",
    "value": "Simpang Bumbuan"
  },
  {
    "id": "6311082007",
    "district_id": "631108",
    "label": "Auh",
    "value": "Auh"
  },
  {
    "id": "6311082008",
    "district_id": "631108",
    "label": "Gunung Batu",
    "value": "Gunung Batu"
  },
  {
    "id": "6311082009",
    "district_id": "631108",
    "label": "Langkap",
    "value": "Langkap"
  },
  {
    "id": "6311082010",
    "district_id": "631108",
    "label": "Simpang Nadong",
    "value": "Simpang Nadong"
  },
  {
    "id": "6311082011",
    "district_id": "631108",
    "label": "Ajung",
    "value": "Ajung"
  },
  {
    "id": "6311082012",
    "district_id": "631108",
    "label": "Kambiyain",
    "value": "Kambiyain"
  },
  {
    "id": "6371011001",
    "district_id": "637101",
    "label": "Mantuil",
    "value": "Mantuil"
  },
  {
    "id": "6371011002",
    "district_id": "637101",
    "label": "Kelayan Selatan",
    "value": "Kelayan Selatan"
  },
  {
    "id": "6371011003",
    "district_id": "637101",
    "label": "Pekauman",
    "value": "Pekauman"
  },
  {
    "id": "6371011004",
    "district_id": "637101",
    "label": "Kelayan Barat",
    "value": "Kelayan Barat"
  },
  {
    "id": "6371011005",
    "district_id": "637101",
    "label": "Kelayan Tengah",
    "value": "Kelayan Tengah"
  },
  {
    "id": "6371011006",
    "district_id": "637101",
    "label": "Kelayan Dalam",
    "value": "Kelayan Dalam"
  },
  {
    "id": "6371011007",
    "district_id": "637101",
    "label": "Murung Raya",
    "value": "Murung Raya"
  },
  {
    "id": "6371011008",
    "district_id": "637101",
    "label": "Kelayan Timur",
    "value": "Kelayan Timur"
  },
  {
    "id": "6371011009",
    "district_id": "637101",
    "label": "Tanjung Pagar",
    "value": "Tanjung Pagar"
  },
  {
    "id": "6371011010",
    "district_id": "637101",
    "label": "Pemurus Dalam",
    "value": "Pemurus Dalam"
  },
  {
    "id": "6371011011",
    "district_id": "637101",
    "label": "Pemurus Baru",
    "value": "Pemurus Baru"
  },
  {
    "id": "6371011012",
    "district_id": "637101",
    "label": "Basirih Selatan",
    "value": "Basirih Selatan"
  },
  {
    "id": "6371021001",
    "district_id": "637102",
    "label": "Kuripan",
    "value": "Kuripan"
  },
  {
    "id": "6371021002",
    "district_id": "637102",
    "label": "Kebun Bunga",
    "value": "Kebun Bunga"
  },
  {
    "id": "6371021003",
    "district_id": "637102",
    "label": "Karang Mekar",
    "value": "Karang Mekar"
  },
  {
    "id": "6371021004",
    "district_id": "637102",
    "label": "Sungai Bilu",
    "value": "Sungai Bilu"
  },
  {
    "id": "6371021005",
    "district_id": "637102",
    "label": "Sungai Lulut",
    "value": "Sungai Lulut"
  },
  {
    "id": "6371021006",
    "district_id": "637102",
    "label": "Banua Anyar",
    "value": "Banua Anyar"
  },
  {
    "id": "6371021007",
    "district_id": "637102",
    "label": "Pengambangan",
    "value": "Pengambangan"
  },
  {
    "id": "6371021008",
    "district_id": "637102",
    "label": "Pekapuran Raya",
    "value": "Pekapuran Raya"
  },
  {
    "id": "6371021009",
    "district_id": "637102",
    "label": "Pemurus Luar",
    "value": "Pemurus Luar"
  },
  {
    "id": "6371031001",
    "district_id": "637103",
    "label": "Belitung Utara",
    "value": "Belitung Utara"
  },
  {
    "id": "6371031002",
    "district_id": "637103",
    "label": "Belitung Selatan",
    "value": "Belitung Selatan"
  },
  {
    "id": "6371031003",
    "district_id": "637103",
    "label": "Pelambuan",
    "value": "Pelambuan"
  },
  {
    "id": "6371031004",
    "district_id": "637103",
    "label": "Telaga Biru",
    "value": "Telaga Biru"
  },
  {
    "id": "6371031005",
    "district_id": "637103",
    "label": "Telawang",
    "value": "Telawang"
  },
  {
    "id": "6371031006",
    "district_id": "637103",
    "label": "Teluk Tiram",
    "value": "Teluk Tiram"
  },
  {
    "id": "6371031007",
    "district_id": "637103",
    "label": "Kuin Selatan",
    "value": "Kuin Selatan"
  },
  {
    "id": "6371031008",
    "district_id": "637103",
    "label": "Kuin Cerucuk",
    "value": "Kuin Cerucuk"
  },
  {
    "id": "6371031009",
    "district_id": "637103",
    "label": "Basirih",
    "value": "Basirih"
  },
  {
    "id": "6371041001",
    "district_id": "637104",
    "label": "Alalak Tengah",
    "value": "Alalak Tengah"
  },
  {
    "id": "6371041002",
    "district_id": "637104",
    "label": "Alalak Utara",
    "value": "Alalak Utara"
  },
  {
    "id": "6371041003",
    "district_id": "637104",
    "label": "Alalak Selatan",
    "value": "Alalak Selatan"
  },
  {
    "id": "6371041004",
    "district_id": "637104",
    "label": "Sungai Jingah",
    "value": "Sungai Jingah"
  },
  {
    "id": "6371041005",
    "district_id": "637104",
    "label": "Sungai Miai",
    "value": "Sungai Miai"
  },
  {
    "id": "6371041006",
    "district_id": "637104",
    "label": "Surgi Mufti",
    "value": "Surgi Mufti"
  },
  {
    "id": "6371041007",
    "district_id": "637104",
    "label": "Pangeran",
    "value": "Pangeran"
  },
  {
    "id": "6371041008",
    "district_id": "637104",
    "label": "Antasan Kecil Timur",
    "value": "Antasan Kecil Timur"
  },
  {
    "id": "6371041009",
    "district_id": "637104",
    "label": "Kuin Utara",
    "value": "Kuin Utara"
  },
  {
    "id": "6371041010",
    "district_id": "637104",
    "label": "Sungai Andai",
    "value": "Sungai Andai"
  },
  {
    "id": "6371051001",
    "district_id": "637105",
    "label": "Kertak Baru Ilir",
    "value": "Kertak Baru Ilir"
  },
  {
    "id": "6371051002",
    "district_id": "637105",
    "label": "Kertak Baru Ulu",
    "value": "Kertak Baru Ulu"
  },
  {
    "id": "6371051003",
    "district_id": "637105",
    "label": "Mawar",
    "value": "Mawar"
  },
  {
    "id": "6371051004",
    "district_id": "637105",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "6371051005",
    "district_id": "637105",
    "label": "Antasan Besar",
    "value": "Antasan Besar"
  },
  {
    "id": "6371051006",
    "district_id": "637105",
    "label": "Pasar Lama",
    "value": "Pasar Lama"
  },
  {
    "id": "6371051007",
    "district_id": "637105",
    "label": "Seberang Mesjid",
    "value": "Seberang Mesjid"
  },
  {
    "id": "6371051008",
    "district_id": "637105",
    "label": "Gadang",
    "value": "Gadang"
  },
  {
    "id": "6371051009",
    "district_id": "637105",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "6371051010",
    "district_id": "637105",
    "label": "Sungai Baru",
    "value": "Sungai Baru"
  },
  {
    "id": "6371051011",
    "district_id": "637105",
    "label": "Pekapuran Laut",
    "value": "Pekapuran Laut"
  },
  {
    "id": "6371051012",
    "district_id": "637105",
    "label": "Kelayan Luar",
    "value": "Kelayan Luar"
  },
  {
    "id": "6372021003",
    "district_id": "637202",
    "label": "Landasan Ulin Timur",
    "value": "Landasan Ulin Timur"
  },
  {
    "id": "6372021004",
    "district_id": "637202",
    "label": "Guntung Payung",
    "value": "Guntung Payung"
  },
  {
    "id": "6372021005",
    "district_id": "637202",
    "label": "Guntung Manggis",
    "value": "Guntung Manggis"
  },
  {
    "id": "6372021006",
    "district_id": "637202",
    "label": "Syamsudin Noor",
    "value": "Syamsudin Noor"
  },
  {
    "id": "6372031001",
    "district_id": "637203",
    "label": "Palam",
    "value": "Palam"
  },
  {
    "id": "6372031002",
    "district_id": "637203",
    "label": "Bangkal",
    "value": "Bangkal"
  },
  {
    "id": "6372031003",
    "district_id": "637203",
    "label": "Sungai Tiung",
    "value": "Sungai Tiung"
  },
  {
    "id": "6372031004",
    "district_id": "637203",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "6372041001",
    "district_id": "637204",
    "label": "Loktabat Utara",
    "value": "Loktabat Utara"
  },
  {
    "id": "6372041002",
    "district_id": "637204",
    "label": "Mentaos",
    "value": "Mentaos"
  },
  {
    "id": "6372041003",
    "district_id": "637204",
    "label": "Komet",
    "value": "Komet"
  },
  {
    "id": "6372041004",
    "district_id": "637204",
    "label": "Sungai Ulin",
    "value": "Sungai Ulin"
  },
  {
    "id": "6372051001",
    "district_id": "637205",
    "label": "Sungai Besar",
    "value": "Sungai Besar"
  },
  {
    "id": "6372051002",
    "district_id": "637205",
    "label": "Laktabat Selatan",
    "value": "Laktabat Selatan"
  },
  {
    "id": "6372051003",
    "district_id": "637205",
    "label": "Kemuning",
    "value": "Kemuning"
  },
  {
    "id": "6372051004",
    "district_id": "637205",
    "label": "Guntung Paikat",
    "value": "Guntung Paikat"
  },
  {
    "id": "6372061001",
    "district_id": "637206",
    "label": "Landasan Ulin Barat",
    "value": "Landasan Ulin Barat"
  },
  {
    "id": "6372061002",
    "district_id": "637206",
    "label": "Landasan Ulin Tengah",
    "value": "Landasan Ulin Tengah"
  },
  {
    "id": "6372061003",
    "district_id": "637206",
    "label": "Landasan Ulin Utara",
    "value": "Landasan Ulin Utara"
  },
  {
    "id": "6372061004",
    "district_id": "637206",
    "label": "Landasan Ulin Selatan",
    "value": "Landasan Ulin Selatan"
  },
  {
    "id": "6401012009",
    "district_id": "640101",
    "label": "Samuranggau",
    "value": "Samuranggau"
  },
  {
    "id": "6401012010",
    "district_id": "640101",
    "label": "Busui",
    "value": "Busui"
  },
  {
    "id": "6401012011",
    "district_id": "640101",
    "label": "Batu Kajang",
    "value": "Batu Kajang"
  },
  {
    "id": "6401012012",
    "district_id": "640101",
    "label": "Legai",
    "value": "Legai"
  },
  {
    "id": "6401012013",
    "district_id": "640101",
    "label": "Sungai Terik",
    "value": "Sungai Terik"
  },
  {
    "id": "6401012014",
    "district_id": "640101",
    "label": "Kasungai",
    "value": "Kasungai"
  },
  {
    "id": "6401012015",
    "district_id": "640101",
    "label": "Rantau Buta",
    "value": "Rantau Buta"
  },
  {
    "id": "6401012016",
    "district_id": "640101",
    "label": "Rantau Layung",
    "value": "Rantau Layung"
  },
  {
    "id": "6401012017",
    "district_id": "640101",
    "label": "Songka",
    "value": "Songka"
  },
  {
    "id": "6401022004",
    "district_id": "640102",
    "label": "Keladen",
    "value": "Keladen"
  },
  {
    "id": "6401022006",
    "district_id": "640102",
    "label": "Tanjung Aru",
    "value": "Tanjung Aru"
  },
  {
    "id": "6401022007",
    "district_id": "640102",
    "label": "Labuangkallo",
    "value": "Labuangkallo"
  },
  {
    "id": "6401022012",
    "district_id": "640102",
    "label": "Lori",
    "value": "Lori"
  },
  {
    "id": "6401022013",
    "district_id": "640102",
    "label": "Selengot",
    "value": "Selengot"
  },
  {
    "id": "6401022014",
    "district_id": "640102",
    "label": "Random",
    "value": "Random"
  },
  {
    "id": "6401022015",
    "district_id": "640102",
    "label": "Senipah",
    "value": "Senipah"
  },
  {
    "id": "6401032001",
    "district_id": "640103",
    "label": "Lempesu",
    "value": "Lempesu"
  },
  {
    "id": "6401032002",
    "district_id": "640103",
    "label": "Bekoso",
    "value": "Bekoso"
  },
  {
    "id": "6401032003",
    "district_id": "640103",
    "label": "Damit",
    "value": "Damit"
  },
  {
    "id": "6401032004",
    "district_id": "640103",
    "label": "Suatang",
    "value": "Suatang"
  },
  {
    "id": "6401032005",
    "district_id": "640103",
    "label": "Suliliran",
    "value": "Suliliran"
  },
  {
    "id": "6401032006",
    "district_id": "640103",
    "label": "Pasir Belengkong",
    "value": "Pasir Belengkong"
  },
  {
    "id": "6401032007",
    "district_id": "640103",
    "label": "Sangkuriman",
    "value": "Sangkuriman"
  },
  {
    "id": "6401032008",
    "district_id": "640103",
    "label": "Laburan",
    "value": "Laburan"
  },
  {
    "id": "6401032009",
    "district_id": "640103",
    "label": "Suliliran Baru",
    "value": "Suliliran Baru"
  },
  {
    "id": "6401032010",
    "district_id": "640103",
    "label": "Keresik Bura",
    "value": "Keresik Bura"
  },
  {
    "id": "6401032011",
    "district_id": "640103",
    "label": "Laburan Baru",
    "value": "Laburan Baru"
  },
  {
    "id": "6401032012",
    "district_id": "640103",
    "label": "Seniung Jaya",
    "value": "Seniung Jaya"
  },
  {
    "id": "6401032013",
    "district_id": "640103",
    "label": "Sunge Batu",
    "value": "Sunge Batu"
  },
  {
    "id": "6401032014",
    "district_id": "640103",
    "label": "Olong Pinang",
    "value": "Olong Pinang"
  },
  {
    "id": "6401032015",
    "district_id": "640103",
    "label": "Suatang Keteban",
    "value": "Suatang Keteban"
  },
  {
    "id": "6401041001",
    "district_id": "640104",
    "label": "Tanah Grogot",
    "value": "Tanah Grogot"
  },
  {
    "id": "6401042002",
    "district_id": "640104",
    "label": "Janju",
    "value": "Janju"
  },
  {
    "id": "6401042003",
    "district_id": "640104",
    "label": "Tepian Batang",
    "value": "Tepian Batang"
  },
  {
    "id": "6401042004",
    "district_id": "640104",
    "label": "Tanah Periuk",
    "value": "Tanah Periuk"
  },
  {
    "id": "6401042005",
    "district_id": "640104",
    "label": "Pepara",
    "value": "Pepara"
  },
  {
    "id": "6401042006",
    "district_id": "640104",
    "label": "Sungai Tuak",
    "value": "Sungai Tuak"
  },
  {
    "id": "6401042007",
    "district_id": "640104",
    "label": "Sempulang",
    "value": "Sempulang"
  },
  {
    "id": "6401042008",
    "district_id": "640104",
    "label": "Jone",
    "value": "Jone"
  },
  {
    "id": "6401042009",
    "district_id": "640104",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6401042010",
    "district_id": "640104",
    "label": "Muara Pasir",
    "value": "Muara Pasir"
  },
  {
    "id": "6401042011",
    "district_id": "640104",
    "label": "Padang Pengrapat",
    "value": "Padang Pengrapat"
  },
  {
    "id": "6401042012",
    "district_id": "640104",
    "label": "Perepat",
    "value": "Perepat"
  },
  {
    "id": "6401042013",
    "district_id": "640104",
    "label": "Pulau Rantau",
    "value": "Pulau Rantau"
  },
  {
    "id": "6401042014",
    "district_id": "640104",
    "label": "Sungai Langir",
    "value": "Sungai Langir"
  },
  {
    "id": "6401042015",
    "district_id": "640104",
    "label": "Tapis",
    "value": "Tapis"
  },
  {
    "id": "6401042016",
    "district_id": "640104",
    "label": "Senaken",
    "value": "Senaken"
  },
  {
    "id": "6401051001",
    "district_id": "640105",
    "label": "Kuaro",
    "value": "Kuaro"
  },
  {
    "id": "6401052002",
    "district_id": "640105",
    "label": "Lolo",
    "value": "Lolo"
  },
  {
    "id": "6401052003",
    "district_id": "640105",
    "label": "Sandeley",
    "value": "Sandeley"
  },
  {
    "id": "6401052004",
    "district_id": "640105",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6401052005",
    "district_id": "640105",
    "label": "Rangan",
    "value": "Rangan"
  },
  {
    "id": "6401052006",
    "district_id": "640105",
    "label": "Modang",
    "value": "Modang"
  },
  {
    "id": "6401052007",
    "district_id": "640105",
    "label": "Pasir Mayang",
    "value": "Pasir Mayang"
  },
  {
    "id": "6401052008",
    "district_id": "640105",
    "label": "Kerta Bumi",
    "value": "Kerta Bumi"
  },
  {
    "id": "6401052009",
    "district_id": "640105",
    "label": "Pondang Baru",
    "value": "Pondang Baru"
  },
  {
    "id": "6401052010",
    "district_id": "640105",
    "label": "Padang Jaya",
    "value": "Padang Jaya"
  },
  {
    "id": "6401052011",
    "district_id": "640105",
    "label": "Kendarom",
    "value": "Kendarom"
  },
  {
    "id": "6401052012",
    "district_id": "640105",
    "label": "Klempang Sari",
    "value": "Klempang Sari"
  },
  {
    "id": "6401052013",
    "district_id": "640105",
    "label": "Keluang Paser Jaya",
    "value": "Keluang Paser Jaya"
  },
  {
    "id": "6401061001",
    "district_id": "640106",
    "label": "Long Ikis",
    "value": "Long Ikis"
  },
  {
    "id": "6401062002",
    "district_id": "640106",
    "label": "Muara Adang",
    "value": "Muara Adang"
  },
  {
    "id": "6401062003",
    "district_id": "640106",
    "label": "Teluk Waru",
    "value": "Teluk Waru"
  },
  {
    "id": "6401062004",
    "district_id": "640106",
    "label": "Tajur",
    "value": "Tajur"
  },
  {
    "id": "6401062005",
    "district_id": "640106",
    "label": "Samuntai",
    "value": "Samuntai"
  },
  {
    "id": "6401062006",
    "district_id": "640106",
    "label": "Lombok",
    "value": "Lombok"
  },
  {
    "id": "6401062007",
    "district_id": "640106",
    "label": "Pait",
    "value": "Pait"
  },
  {
    "id": "6401062008",
    "district_id": "640106",
    "label": "Olung",
    "value": "Olung"
  },
  {
    "id": "6401062009",
    "district_id": "640106",
    "label": "Kayungo",
    "value": "Kayungo"
  },
  {
    "id": "6401062010",
    "district_id": "640106",
    "label": "Jemparing",
    "value": "Jemparing"
  },
  {
    "id": "6401062011",
    "district_id": "640106",
    "label": "Tiwei",
    "value": "Tiwei"
  },
  {
    "id": "6401062012",
    "district_id": "640106",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "6401062013",
    "district_id": "640106",
    "label": "Long Gelang",
    "value": "Long Gelang"
  },
  {
    "id": "6401062014",
    "district_id": "640106",
    "label": "Krayan Jaya",
    "value": "Krayan Jaya"
  },
  {
    "id": "6401062015",
    "district_id": "640106",
    "label": "Bukit Saloka",
    "value": "Bukit Saloka"
  },
  {
    "id": "6401062016",
    "district_id": "640106",
    "label": "Krayan Sentosa",
    "value": "Krayan Sentosa"
  },
  {
    "id": "6401062017",
    "district_id": "640106",
    "label": "Krayan Makmur",
    "value": "Krayan Makmur"
  },
  {
    "id": "6401062018",
    "district_id": "640106",
    "label": "Kayungo Sari",
    "value": "Kayungo Sari"
  },
  {
    "id": "6401062019",
    "district_id": "640106",
    "label": "Krayan Bahagia",
    "value": "Krayan Bahagia"
  },
  {
    "id": "6401062020",
    "district_id": "640106",
    "label": "Sawit Jaya",
    "value": "Sawit Jaya"
  },
  {
    "id": "6401062021",
    "district_id": "640106",
    "label": "Sekurou Jaya",
    "value": "Sekurou Jaya"
  },
  {
    "id": "6401062022",
    "district_id": "640106",
    "label": "Kerta Bhakti",
    "value": "Kerta Bhakti"
  },
  {
    "id": "6401062023",
    "district_id": "640106",
    "label": "Adang Jaya",
    "value": "Adang Jaya"
  },
  {
    "id": "6401062024",
    "district_id": "640106",
    "label": "Tajer Mulya",
    "value": "Tajer Mulya"
  },
  {
    "id": "6401062025",
    "district_id": "640106",
    "label": "Brewe",
    "value": "Brewe"
  },
  {
    "id": "6401062026",
    "district_id": "640106",
    "label": "Atang Pait",
    "value": "Atang Pait"
  },
  {
    "id": "6401071001",
    "district_id": "640107",
    "label": "Muarakomam",
    "value": "Muarakomam"
  },
  {
    "id": "6401072002",
    "district_id": "640107",
    "label": "Batu Butok",
    "value": "Batu Butok"
  },
  {
    "id": "6401072003",
    "district_id": "640107",
    "label": "Uko",
    "value": "Uko"
  },
  {
    "id": "6401072004",
    "district_id": "640107",
    "label": "Muara Langon",
    "value": "Muara Langon"
  },
  {
    "id": "6401072005",
    "district_id": "640107",
    "label": "Binangon",
    "value": "Binangon"
  },
  {
    "id": "6401072006",
    "district_id": "640107",
    "label": "Muara Kuaro",
    "value": "Muara Kuaro"
  },
  {
    "id": "6401072007",
    "district_id": "640107",
    "label": "Prayon",
    "value": "Prayon"
  },
  {
    "id": "6401072008",
    "district_id": "640107",
    "label": "Long Sayo",
    "value": "Long Sayo"
  },
  {
    "id": "6401072009",
    "district_id": "640107",
    "label": "Muara Payang",
    "value": "Muara Payang"
  },
  {
    "id": "6401072010",
    "district_id": "640107",
    "label": "Lusan",
    "value": "Lusan"
  },
  {
    "id": "6401072011",
    "district_id": "640107",
    "label": "Swan Slutung",
    "value": "Swan Slutung"
  },
  {
    "id": "6401072012",
    "district_id": "640107",
    "label": "Sekuan Makmur",
    "value": "Sekuan Makmur"
  },
  {
    "id": "6401072013",
    "district_id": "640107",
    "label": "Selerong",
    "value": "Selerong"
  },
  {
    "id": "6401081001",
    "district_id": "640108",
    "label": "Long Kali",
    "value": "Long Kali"
  },
  {
    "id": "6401082002",
    "district_id": "640108",
    "label": "Muara Telake",
    "value": "Muara Telake"
  },
  {
    "id": "6401082003",
    "district_id": "640108",
    "label": "Sebakung",
    "value": "Sebakung"
  },
  {
    "id": "6401082004",
    "district_id": "640108",
    "label": "Bente Tualan",
    "value": "Bente Tualan"
  },
  {
    "id": "6401082005",
    "district_id": "640108",
    "label": "Mendik",
    "value": "Mendik"
  },
  {
    "id": "6401082006",
    "district_id": "640108",
    "label": "Munggu",
    "value": "Munggu"
  },
  {
    "id": "6401082007",
    "district_id": "640108",
    "label": "Muara Pias",
    "value": "Muara Pias"
  },
  {
    "id": "6401082008",
    "district_id": "640108",
    "label": "Muara Toyu",
    "value": "Muara Toyu"
  },
  {
    "id": "6401082009",
    "district_id": "640108",
    "label": "Perkuwen",
    "value": "Perkuwen"
  },
  {
    "id": "6401082010",
    "district_id": "640108",
    "label": "Pinang Jatus",
    "value": "Pinang Jatus"
  },
  {
    "id": "6401082011",
    "district_id": "640108",
    "label": "Muara Lambakan",
    "value": "Muara Lambakan"
  },
  {
    "id": "6401082012",
    "district_id": "640108",
    "label": "Kepala Telake",
    "value": "Kepala Telake"
  },
  {
    "id": "6401082013",
    "district_id": "640108",
    "label": "Mendik Makmur",
    "value": "Mendik Makmur"
  },
  {
    "id": "6401082014",
    "district_id": "640108",
    "label": "Mendik Karya",
    "value": "Mendik Karya"
  },
  {
    "id": "6401082015",
    "district_id": "640108",
    "label": "Mendik Bhakti",
    "value": "Mendik Bhakti"
  },
  {
    "id": "6401082016",
    "district_id": "640108",
    "label": "Sebakung Taka",
    "value": "Sebakung Taka"
  },
  {
    "id": "6401082017",
    "district_id": "640108",
    "label": "Maruat",
    "value": "Maruat"
  },
  {
    "id": "6401082018",
    "district_id": "640108",
    "label": "Sebakung Makmur",
    "value": "Sebakung Makmur"
  },
  {
    "id": "6401082019",
    "district_id": "640108",
    "label": "Petiku",
    "value": "Petiku"
  },
  {
    "id": "6401082020",
    "district_id": "640108",
    "label": "Muara Adang II",
    "value": "Muara Adang II"
  },
  {
    "id": "6401082021",
    "district_id": "640108",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "6401082022",
    "district_id": "640108",
    "label": "Gunung Putar",
    "value": "Gunung Putar"
  },
  {
    "id": "6401082023",
    "district_id": "640108",
    "label": "Putang",
    "value": "Putang"
  },
  {
    "id": "6401092001",
    "district_id": "640109",
    "label": "Kerang",
    "value": "Kerang"
  },
  {
    "id": "6401092002",
    "district_id": "640109",
    "label": "Lomu",
    "value": "Lomu"
  },
  {
    "id": "6401092003",
    "district_id": "640109",
    "label": "Segendang",
    "value": "Segendang"
  },
  {
    "id": "6401092004",
    "district_id": "640109",
    "label": "Mengkudu",
    "value": "Mengkudu"
  },
  {
    "id": "6401092005",
    "district_id": "640109",
    "label": "Riwang",
    "value": "Riwang"
  },
  {
    "id": "6401092006",
    "district_id": "640109",
    "label": "Langgai",
    "value": "Langgai"
  },
  {
    "id": "6401092007",
    "district_id": "640109",
    "label": "Petangis",
    "value": "Petangis"
  },
  {
    "id": "6401092008",
    "district_id": "640109",
    "label": "Tampakan",
    "value": "Tampakan"
  },
  {
    "id": "6401092009",
    "district_id": "640109",
    "label": "Kerang Dayo",
    "value": "Kerang Dayo"
  },
  {
    "id": "6401092010",
    "district_id": "640109",
    "label": "Saing Prupuk",
    "value": "Saing Prupuk"
  },
  {
    "id": "6401092011",
    "district_id": "640109",
    "label": "Bai Jaya",
    "value": "Bai Jaya"
  },
  {
    "id": "6401092012",
    "district_id": "640109",
    "label": "Pengguren Jaya",
    "value": "Pengguren Jaya"
  },
  {
    "id": "6401092013",
    "district_id": "640109",
    "label": "Tebru Paser Damai",
    "value": "Tebru Paser Damai"
  },
  {
    "id": "6401102001",
    "district_id": "640110",
    "label": "Tanjung Pinang",
    "value": "Tanjung Pinang"
  },
  {
    "id": "6401102002",
    "district_id": "640110",
    "label": "Rantau Atas",
    "value": "Rantau Atas"
  },
  {
    "id": "6401102003",
    "district_id": "640110",
    "label": "Libur Dinding",
    "value": "Libur Dinding"
  },
  {
    "id": "6401102004",
    "district_id": "640110",
    "label": "Luan",
    "value": "Luan"
  },
  {
    "id": "6401102005",
    "district_id": "640110",
    "label": "Suweto",
    "value": "Suweto"
  },
  {
    "id": "6401102006",
    "district_id": "640110",
    "label": "Muser",
    "value": "Muser"
  },
  {
    "id": "6401102007",
    "district_id": "640110",
    "label": "Biu",
    "value": "Biu"
  },
  {
    "id": "6401102008",
    "district_id": "640110",
    "label": "Rantau Bintungan",
    "value": "Rantau Bintungan"
  },
  {
    "id": "6401102009",
    "district_id": "640110",
    "label": "Muara Andeh",
    "value": "Muara Andeh"
  },
  {
    "id": "6402012001",
    "district_id": "640201",
    "label": "Perian",
    "value": "Perian"
  },
  {
    "id": "6402012002",
    "district_id": "640201",
    "label": "Muara Leka",
    "value": "Muara Leka"
  },
  {
    "id": "6402012003",
    "district_id": "640201",
    "label": "Muara Aloh",
    "value": "Muara Aloh"
  },
  {
    "id": "6402012004",
    "district_id": "640201",
    "label": "Jantur",
    "value": "Jantur"
  },
  {
    "id": "6402012005",
    "district_id": "640201",
    "label": "Batuq",
    "value": "Batuq"
  },
  {
    "id": "6402012006",
    "district_id": "640201",
    "label": "Rebaq Rinding",
    "value": "Rebaq Rinding"
  },
  {
    "id": "6402012007",
    "district_id": "640201",
    "label": "Muara Muntai Ulu",
    "value": "Muara Muntai Ulu"
  },
  {
    "id": "6402012008",
    "district_id": "640201",
    "label": "Muara Muntai Ilir",
    "value": "Muara Muntai Ilir"
  },
  {
    "id": "6402012009",
    "district_id": "640201",
    "label": "Kayu Batu",
    "value": "Kayu Batu"
  },
  {
    "id": "6402012010",
    "district_id": "640201",
    "label": "Jantur Selatan",
    "value": "Jantur Selatan"
  },
  {
    "id": "6402012011",
    "district_id": "640201",
    "label": "Tanjung Batuq Harapan",
    "value": "Tanjung Batuq Harapan"
  },
  {
    "id": "6402012012",
    "district_id": "640201",
    "label": "Pulau Harapan",
    "value": "Pulau Harapan"
  },
  {
    "id": "6402012013",
    "district_id": "640201",
    "label": "Jantur Baru",
    "value": "Jantur Baru"
  },
  {
    "id": "6402022001",
    "district_id": "640202",
    "label": "Jonggon Desa",
    "value": "Jonggon Desa"
  },
  {
    "id": "6402022002",
    "district_id": "640202",
    "label": "Sungai Payang",
    "value": "Sungai Payang"
  },
  {
    "id": "6402022003",
    "district_id": "640202",
    "label": "Jembayan",
    "value": "Jembayan"
  },
  {
    "id": "6402022004",
    "district_id": "640202",
    "label": "Loa Kulu Kota",
    "value": "Loa Kulu Kota"
  },
  {
    "id": "6402022005",
    "district_id": "640202",
    "label": "Loh Sumber",
    "value": "Loh Sumber"
  },
  {
    "id": "6402022006",
    "district_id": "640202",
    "label": "Ponoragan",
    "value": "Ponoragan"
  },
  {
    "id": "6402022007",
    "district_id": "640202",
    "label": "Rempanga",
    "value": "Rempanga"
  },
  {
    "id": "6402022008",
    "district_id": "640202",
    "label": "Margahayu",
    "value": "Margahayu"
  },
  {
    "id": "6402022009",
    "district_id": "640202",
    "label": "Karya Utama",
    "value": "Karya Utama"
  },
  {
    "id": "6402022010",
    "district_id": "640202",
    "label": "Lung Anai",
    "value": "Lung Anai"
  },
  {
    "id": "6402022011",
    "district_id": "640202",
    "label": "Jembayan Tengah",
    "value": "Jembayan Tengah"
  },
  {
    "id": "6402022012",
    "district_id": "640202",
    "label": "Jembayan Dalam",
    "value": "Jembayan Dalam"
  },
  {
    "id": "6402022013",
    "district_id": "640202",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "6402022014",
    "district_id": "640202",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6402022015",
    "district_id": "640202",
    "label": "Jongkang",
    "value": "Jongkang"
  },
  {
    "id": "6402032001",
    "district_id": "640203",
    "label": "Bakungan",
    "value": "Bakungan"
  },
  {
    "id": "6402032002",
    "district_id": "640203",
    "label": "Loa Duri Ulu",
    "value": "Loa Duri Ulu"
  },
  {
    "id": "6402032003",
    "district_id": "640203",
    "label": "Loa Janan Ulu",
    "value": "Loa Janan Ulu"
  },
  {
    "id": "6402032004",
    "district_id": "640203",
    "label": "Purwajaya",
    "value": "Purwajaya"
  },
  {
    "id": "6402032005",
    "district_id": "640203",
    "label": "Tani Bhakti",
    "value": "Tani Bhakti"
  },
  {
    "id": "6402032006",
    "district_id": "640203",
    "label": "Batuah",
    "value": "Batuah"
  },
  {
    "id": "6402032007",
    "district_id": "640203",
    "label": "Loa Duri Ilir",
    "value": "Loa Duri Ilir"
  },
  {
    "id": "6402032008",
    "district_id": "640203",
    "label": "Tani Harapan",
    "value": "Tani Harapan"
  },
  {
    "id": "6402042001",
    "district_id": "640204",
    "label": "Sepatin",
    "value": "Sepatin"
  },
  {
    "id": "6402042002",
    "district_id": "640204",
    "label": "Muara Pantuan",
    "value": "Muara Pantuan"
  },
  {
    "id": "6402042003",
    "district_id": "640204",
    "label": "Tani Baru",
    "value": "Tani Baru"
  },
  {
    "id": "6402042004",
    "district_id": "640204",
    "label": "Kutai Lama",
    "value": "Kutai Lama"
  },
  {
    "id": "6402042005",
    "district_id": "640204",
    "label": "Anggana",
    "value": "Anggana"
  },
  {
    "id": "6402042006",
    "district_id": "640204",
    "label": "Sungai Meriam",
    "value": "Sungai Meriam"
  },
  {
    "id": "6402042007",
    "district_id": "640204",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6402042008",
    "district_id": "640204",
    "label": "Handil Terusan",
    "value": "Handil Terusan"
  },
  {
    "id": "6402052001",
    "district_id": "640205",
    "label": "Saliki",
    "value": "Saliki"
  },
  {
    "id": "6402052002",
    "district_id": "640205",
    "label": "Salo Palai",
    "value": "Salo Palai"
  },
  {
    "id": "6402052003",
    "district_id": "640205",
    "label": "Muara Badak Ulu",
    "value": "Muara Badak Ulu"
  },
  {
    "id": "6402052004",
    "district_id": "640205",
    "label": "Muara Badak Ilir",
    "value": "Muara Badak Ilir"
  },
  {
    "id": "6402052005",
    "district_id": "640205",
    "label": "Tanjung Limau",
    "value": "Tanjung Limau"
  },
  {
    "id": "6402052006",
    "district_id": "640205",
    "label": "Tanah Datar",
    "value": "Tanah Datar"
  },
  {
    "id": "6402052007",
    "district_id": "640205",
    "label": "Badak Baru",
    "value": "Badak Baru"
  },
  {
    "id": "6402052008",
    "district_id": "640205",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6402052009",
    "district_id": "640205",
    "label": "Badak Mekar",
    "value": "Badak Mekar"
  },
  {
    "id": "6402052010",
    "district_id": "640205",
    "label": "Gas Alam Badak I",
    "value": "Gas Alam Badak I"
  },
  {
    "id": "6402052011",
    "district_id": "640205",
    "label": "Batu-Batu",
    "value": "Batu-Batu"
  },
  {
    "id": "6402052012",
    "district_id": "640205",
    "label": "Salo Cella",
    "value": "Salo Cella"
  },
  {
    "id": "6402052013",
    "district_id": "640205",
    "label": "Sungai Bawang",
    "value": "Sungai Bawang"
  },
  {
    "id": "6402061001",
    "district_id": "640206",
    "label": "Jahab",
    "value": "Jahab"
  },
  {
    "id": "6402061002",
    "district_id": "640206",
    "label": "Loa Ipuh",
    "value": "Loa Ipuh"
  },
  {
    "id": "6402061003",
    "district_id": "640206",
    "label": "Bukit Biru",
    "value": "Bukit Biru"
  },
  {
    "id": "6402061004",
    "district_id": "640206",
    "label": "Timbau",
    "value": "Timbau"
  },
  {
    "id": "6402061005",
    "district_id": "640206",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "6402061006",
    "district_id": "640206",
    "label": "Panji",
    "value": "Panji"
  },
  {
    "id": "6402061007",
    "district_id": "640206",
    "label": "Sukarame",
    "value": "Sukarame"
  },
  {
    "id": "6402061008",
    "district_id": "640206",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6402061009",
    "district_id": "640206",
    "label": "Loa Tebu",
    "value": "Loa Tebu"
  },
  {
    "id": "6402061010",
    "district_id": "640206",
    "label": "Mangkurawang",
    "value": "Mangkurawang"
  },
  {
    "id": "6402061011",
    "district_id": "640206",
    "label": "Maluhu",
    "value": "Maluhu"
  },
  {
    "id": "6402061013",
    "district_id": "640206",
    "label": "Loa Ipuh Darat",
    "value": "Loa Ipuh Darat"
  },
  {
    "id": "6402062012",
    "district_id": "640206",
    "label": "Rampak Lambur",
    "value": "Rampak Lambur"
  },
  {
    "id": "6402062014",
    "district_id": "640206",
    "label": "Bendang Raya",
    "value": "Bendang Raya"
  },
  {
    "id": "6402072001",
    "district_id": "640207",
    "label": "Selerong",
    "value": "Selerong"
  },
  {
    "id": "6402072002",
    "district_id": "640207",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6402072003",
    "district_id": "640207",
    "label": "Beloro",
    "value": "Beloro"
  },
  {
    "id": "6402072004",
    "district_id": "640207",
    "label": "Sebulu Ulu",
    "value": "Sebulu Ulu"
  },
  {
    "id": "6402072005",
    "district_id": "640207",
    "label": "Sebulu Ilir",
    "value": "Sebulu Ilir"
  },
  {
    "id": "6402072006",
    "district_id": "640207",
    "label": "Segihan",
    "value": "Segihan"
  },
  {
    "id": "6402072007",
    "district_id": "640207",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6402072008",
    "district_id": "640207",
    "label": "Manunggal Daya",
    "value": "Manunggal Daya"
  },
  {
    "id": "6402072009",
    "district_id": "640207",
    "label": "Giri Agung",
    "value": "Giri Agung"
  },
  {
    "id": "6402072010",
    "district_id": "640207",
    "label": "Senoni",
    "value": "Senoni"
  },
  {
    "id": "6402072011",
    "district_id": "640207",
    "label": "Sebulu Moderen",
    "value": "Sebulu Moderen"
  },
  {
    "id": "6402072012",
    "district_id": "640207",
    "label": "Sanggulan",
    "value": "Sanggulan"
  },
  {
    "id": "6402072013",
    "district_id": "640207",
    "label": "Lekaq Kidau",
    "value": "Lekaq Kidau"
  },
  {
    "id": "6402072014",
    "district_id": "640207",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "6402082001",
    "district_id": "640208",
    "label": "Kedang Ipil",
    "value": "Kedang Ipil"
  },
  {
    "id": "6402082002",
    "district_id": "640208",
    "label": "Benua Baru",
    "value": "Benua Baru"
  },
  {
    "id": "6402082003",
    "district_id": "640208",
    "label": "Sedulang",
    "value": "Sedulang"
  },
  {
    "id": "6402082004",
    "district_id": "640208",
    "label": "Loleng",
    "value": "Loleng"
  },
  {
    "id": "6402082005",
    "district_id": "640208",
    "label": "Kota Bangun Ulu",
    "value": "Kota Bangun Ulu"
  },
  {
    "id": "6402082006",
    "district_id": "640208",
    "label": "Kota Bangun Ilir",
    "value": "Kota Bangun Ilir"
  },
  {
    "id": "6402082007",
    "district_id": "640208",
    "label": "Liang",
    "value": "Liang"
  },
  {
    "id": "6402082008",
    "district_id": "640208",
    "label": "Muhuran",
    "value": "Muhuran"
  },
  {
    "id": "6402082009",
    "district_id": "640208",
    "label": "Pela",
    "value": "Pela"
  },
  {
    "id": "6402082010",
    "district_id": "640208",
    "label": "Kota Bangun I",
    "value": "Kota Bangun I"
  },
  {
    "id": "6402082011",
    "district_id": "640208",
    "label": "Kota Bangun II",
    "value": "Kota Bangun II"
  },
  {
    "id": "6402082012",
    "district_id": "640208",
    "label": "Kota Bangun III",
    "value": "Kota Bangun III"
  },
  {
    "id": "6402082013",
    "district_id": "640208",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6402082014",
    "district_id": "640208",
    "label": "Sari Nadi",
    "value": "Sari Nadi"
  },
  {
    "id": "6402082015",
    "district_id": "640208",
    "label": "Suka Bumi",
    "value": "Suka Bumi"
  },
  {
    "id": "6402082016",
    "district_id": "640208",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "6402082017",
    "district_id": "640208",
    "label": "Kedang Murung",
    "value": "Kedang Murung"
  },
  {
    "id": "6402082018",
    "district_id": "640208",
    "label": "Kota Bangun Seberang",
    "value": "Kota Bangun Seberang"
  },
  {
    "id": "6402082019",
    "district_id": "640208",
    "label": "Liang Ulu",
    "value": "Liang Ulu"
  },
  {
    "id": "6402082020",
    "district_id": "640208",
    "label": "Sebelimbingan",
    "value": "Sebelimbingan"
  },
  {
    "id": "6402082021",
    "district_id": "640208",
    "label": "Sangkuliman",
    "value": "Sangkuliman"
  },
  {
    "id": "6402092001",
    "district_id": "640209",
    "label": "Lamin Telihan",
    "value": "Lamin Telihan"
  },
  {
    "id": "6402092002",
    "district_id": "640209",
    "label": "Lamin Pulut",
    "value": "Lamin Pulut"
  },
  {
    "id": "6402092003",
    "district_id": "640209",
    "label": "Teluk Bingkai",
    "value": "Teluk Bingkai"
  },
  {
    "id": "6402092004",
    "district_id": "640209",
    "label": "Kahala",
    "value": "Kahala"
  },
  {
    "id": "6402092005",
    "district_id": "640209",
    "label": "Tubuhan",
    "value": "Tubuhan"
  },
  {
    "id": "6402092006",
    "district_id": "640209",
    "label": "Semayang",
    "value": "Semayang"
  },
  {
    "id": "6402092007",
    "district_id": "640209",
    "label": "Teluk Muda",
    "value": "Teluk Muda"
  },
  {
    "id": "6402092008",
    "district_id": "640209",
    "label": "Tuana Tuha",
    "value": "Tuana Tuha"
  },
  {
    "id": "6402092009",
    "district_id": "640209",
    "label": "Kahala Ilir",
    "value": "Kahala Ilir"
  },
  {
    "id": "6402102001",
    "district_id": "640210",
    "label": "Genting Tanah",
    "value": "Genting Tanah"
  },
  {
    "id": "6402102002",
    "district_id": "640210",
    "label": "Loa Sakoh",
    "value": "Loa Sakoh"
  },
  {
    "id": "6402102003",
    "district_id": "640210",
    "label": "Hambau",
    "value": "Hambau"
  },
  {
    "id": "6402102004",
    "district_id": "640210",
    "label": "Kembang Janggut",
    "value": "Kembang Janggut"
  },
  {
    "id": "6402102005",
    "district_id": "640210",
    "label": "Kelekat",
    "value": "Kelekat"
  },
  {
    "id": "6402102006",
    "district_id": "640210",
    "label": "Pulau Pinang",
    "value": "Pulau Pinang"
  },
  {
    "id": "6402102007",
    "district_id": "640210",
    "label": "Long Beleh Haloq",
    "value": "Long Beleh Haloq"
  },
  {
    "id": "6402102008",
    "district_id": "640210",
    "label": "Long Beleh Modang",
    "value": "Long Beleh Modang"
  },
  {
    "id": "6402102009",
    "district_id": "640210",
    "label": "Muai",
    "value": "Muai"
  },
  {
    "id": "6402102010",
    "district_id": "640210",
    "label": "Perdana",
    "value": "Perdana"
  },
  {
    "id": "6402102011",
    "district_id": "640210",
    "label": "Bukit Layang",
    "value": "Bukit Layang"
  },
  {
    "id": "6402112001",
    "district_id": "640211",
    "label": "Muara Kaman Ilir",
    "value": "Muara Kaman Ilir"
  },
  {
    "id": "6402112002",
    "district_id": "640211",
    "label": "Rantau Hempang",
    "value": "Rantau Hempang"
  },
  {
    "id": "6402112003",
    "district_id": "640211",
    "label": "Teratak",
    "value": "Teratak"
  },
  {
    "id": "6402112004",
    "district_id": "640211",
    "label": "Benua Puhun",
    "value": "Benua Puhun"
  },
  {
    "id": "6402112005",
    "district_id": "640211",
    "label": "Muara Kaman Ulu",
    "value": "Muara Kaman Ulu"
  },
  {
    "id": "6402112006",
    "district_id": "640211",
    "label": "Sabintulung",
    "value": "Sabintulung"
  },
  {
    "id": "6402112007",
    "district_id": "640211",
    "label": "Muara Siran",
    "value": "Muara Siran"
  },
  {
    "id": "6402112008",
    "district_id": "640211",
    "label": "Tunjungan",
    "value": "Tunjungan"
  },
  {
    "id": "6402112009",
    "district_id": "640211",
    "label": "Sedulang",
    "value": "Sedulang"
  },
  {
    "id": "6402112010",
    "district_id": "640211",
    "label": "Menamang Kiri",
    "value": "Menamang Kiri"
  },
  {
    "id": "6402112011",
    "district_id": "640211",
    "label": "Menamang Kanan",
    "value": "Menamang Kanan"
  },
  {
    "id": "6402112012",
    "district_id": "640211",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "6402112013",
    "district_id": "640211",
    "label": "Panca Jaya",
    "value": "Panca Jaya"
  },
  {
    "id": "6402112014",
    "district_id": "640211",
    "label": "Bunga Jadi",
    "value": "Bunga Jadi"
  },
  {
    "id": "6402112015",
    "district_id": "640211",
    "label": "Kupang Baru",
    "value": "Kupang Baru"
  },
  {
    "id": "6402112016",
    "district_id": "640211",
    "label": "Lebaho Ulaq",
    "value": "Lebaho Ulaq"
  },
  {
    "id": "6402112017",
    "district_id": "640211",
    "label": "Bukit Jering",
    "value": "Bukit Jering"
  },
  {
    "id": "6402112018",
    "district_id": "640211",
    "label": "Liang Buaya",
    "value": "Liang Buaya"
  },
  {
    "id": "6402112019",
    "district_id": "640211",
    "label": "Puan Cepak",
    "value": "Puan Cepak"
  },
  {
    "id": "6402112020",
    "district_id": "640211",
    "label": "Cipari Makmur",
    "value": "Cipari Makmur"
  },
  {
    "id": "6402122001",
    "district_id": "640212",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "6402122002",
    "district_id": "640212",
    "label": "Long Lalang",
    "value": "Long Lalang"
  },
  {
    "id": "6402122003",
    "district_id": "640212",
    "label": "Muara Ritan",
    "value": "Muara Ritan"
  },
  {
    "id": "6402122004",
    "district_id": "640212",
    "label": "Buluk Sen",
    "value": "Buluk Sen"
  },
  {
    "id": "6402122005",
    "district_id": "640212",
    "label": "Umaq Dian",
    "value": "Umaq Dian"
  },
  {
    "id": "6402122006",
    "district_id": "640212",
    "label": "Muara Pedohon",
    "value": "Muara Pedohon"
  },
  {
    "id": "6402122007",
    "district_id": "640212",
    "label": "Bila Talang",
    "value": "Bila Talang"
  },
  {
    "id": "6402122008",
    "district_id": "640212",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6402122009",
    "district_id": "640212",
    "label": "Umaq Tukung",
    "value": "Umaq Tukung"
  },
  {
    "id": "6402122010",
    "district_id": "640212",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6402122011",
    "district_id": "640212",
    "label": "Umaq Bekuay",
    "value": "Umaq Bekuay"
  },
  {
    "id": "6402122012",
    "district_id": "640212",
    "label": "Tabang Lama",
    "value": "Tabang Lama"
  },
  {
    "id": "6402122013",
    "district_id": "640212",
    "label": "Muara Tiq",
    "value": "Muara Tiq"
  },
  {
    "id": "6402122014",
    "district_id": "640212",
    "label": "Muara Salung",
    "value": "Muara Salung"
  },
  {
    "id": "6402122015",
    "district_id": "640212",
    "label": "Muara Kebaq",
    "value": "Muara Kebaq"
  },
  {
    "id": "6402122016",
    "district_id": "640212",
    "label": "Muara Belinau",
    "value": "Muara Belinau"
  },
  {
    "id": "6402122017",
    "district_id": "640212",
    "label": "Muara Tuboq",
    "value": "Muara Tuboq"
  },
  {
    "id": "6402122018",
    "district_id": "640212",
    "label": "Ritan Baru",
    "value": "Ritan Baru"
  },
  {
    "id": "6402122019",
    "district_id": "640212",
    "label": "Tukung Ritan",
    "value": "Tukung Ritan"
  },
  {
    "id": "6402131001",
    "district_id": "640213",
    "label": "Salok Api Barat",
    "value": "Salok Api Barat"
  },
  {
    "id": "6402131002",
    "district_id": "640213",
    "label": "Salok Api Laut",
    "value": "Salok Api Laut"
  },
  {
    "id": "6402131003",
    "district_id": "640213",
    "label": "Ambarawang Laut",
    "value": "Ambarawang Laut"
  },
  {
    "id": "6402131004",
    "district_id": "640213",
    "label": "Ambarawang Darat",
    "value": "Ambarawang Darat"
  },
  {
    "id": "6402131005",
    "district_id": "640213",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "6402131006",
    "district_id": "640213",
    "label": "Sungai Merdeka",
    "value": "Sungai Merdeka"
  },
  {
    "id": "6402131007",
    "district_id": "640213",
    "label": "Sungai Seluang",
    "value": "Sungai Seluang"
  },
  {
    "id": "6402131008",
    "district_id": "640213",
    "label": "Wonotirto",
    "value": "Wonotirto"
  },
  {
    "id": "6402131009",
    "district_id": "640213",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6402131010",
    "district_id": "640213",
    "label": "Samboja Kuala",
    "value": "Samboja Kuala"
  },
  {
    "id": "6402131011",
    "district_id": "640213",
    "label": "Sanipah",
    "value": "Sanipah"
  },
  {
    "id": "6402131012",
    "district_id": "640213",
    "label": "Handil Baru",
    "value": "Handil Baru"
  },
  {
    "id": "6402131013",
    "district_id": "640213",
    "label": "Muara Sembilang",
    "value": "Muara Sembilang"
  },
  {
    "id": "6402131015",
    "district_id": "640213",
    "label": "Bukit Merdeka",
    "value": "Bukit Merdeka"
  },
  {
    "id": "6402131017",
    "district_id": "640213",
    "label": "Argo Sari",
    "value": "Argo Sari"
  },
  {
    "id": "6402131020",
    "district_id": "640213",
    "label": "Karya Merdeka",
    "value": "Karya Merdeka"
  },
  {
    "id": "6402131021",
    "district_id": "640213",
    "label": "Teluk Pemedas",
    "value": "Teluk Pemedas"
  },
  {
    "id": "6402131022",
    "district_id": "640213",
    "label": "Kampung Lama",
    "value": "Kampung Lama"
  },
  {
    "id": "6402131023",
    "district_id": "640213",
    "label": "Handil Baru Darat",
    "value": "Handil Baru Darat"
  },
  {
    "id": "6402132014",
    "district_id": "640213",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "6402132016",
    "district_id": "640213",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "6402132018",
    "district_id": "640213",
    "label": "Tani Bhakti",
    "value": "Tani Bhakti"
  },
  {
    "id": "6402132019",
    "district_id": "640213",
    "label": "Beringin Agung",
    "value": "Beringin Agung"
  },
  {
    "id": "6402141001",
    "district_id": "640214",
    "label": "Muara Jawa Ilir",
    "value": "Muara Jawa Ilir"
  },
  {
    "id": "6402141002",
    "district_id": "640214",
    "label": "Muara Jawa Tengah",
    "value": "Muara Jawa Tengah"
  },
  {
    "id": "6402141003",
    "district_id": "640214",
    "label": "Muara Jawa Ulu",
    "value": "Muara Jawa Ulu"
  },
  {
    "id": "6402141004",
    "district_id": "640214",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "6402141005",
    "district_id": "640214",
    "label": "Dondang",
    "value": "Dondang"
  },
  {
    "id": "6402141006",
    "district_id": "640214",
    "label": "Tama Pole",
    "value": "Tama Pole"
  },
  {
    "id": "6402141007",
    "district_id": "640214",
    "label": "Muara Kembang",
    "value": "Muara Kembang"
  },
  {
    "id": "6402141008",
    "district_id": "640214",
    "label": "Muara Jawa",
    "value": "Muara Jawa"
  },
  {
    "id": "6402151001",
    "district_id": "640215",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "6402151002",
    "district_id": "640215",
    "label": "Pendingin",
    "value": "Pendingin"
  },
  {
    "id": "6402151003",
    "district_id": "640215",
    "label": "Sanga-Sanga Dalam",
    "value": "Sanga-Sanga Dalam"
  },
  {
    "id": "6402151004",
    "district_id": "640215",
    "label": "Sari Jaya",
    "value": "Sari Jaya"
  },
  {
    "id": "6402151005",
    "district_id": "640215",
    "label": "Sanga-Sanga Muara",
    "value": "Sanga-Sanga Muara"
  },
  {
    "id": "6402162001",
    "district_id": "640216",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "6402162002",
    "district_id": "640216",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "6402162003",
    "district_id": "640216",
    "label": "Embalut",
    "value": "Embalut"
  },
  {
    "id": "6402162004",
    "district_id": "640216",
    "label": "Bangun Rejo",
    "value": "Bangun Rejo"
  },
  {
    "id": "6402162005",
    "district_id": "640216",
    "label": "Kerta Buana",
    "value": "Kerta Buana"
  },
  {
    "id": "6402162006",
    "district_id": "640216",
    "label": "Separi",
    "value": "Separi"
  },
  {
    "id": "6402162007",
    "district_id": "640216",
    "label": "Bukit Pariaman",
    "value": "Bukit Pariaman"
  },
  {
    "id": "6402162008",
    "district_id": "640216",
    "label": "Buana Jaya",
    "value": "Buana Jaya"
  },
  {
    "id": "6402162009",
    "district_id": "640216",
    "label": "Mulawarman",
    "value": "Mulawarman"
  },
  {
    "id": "6402162010",
    "district_id": "640216",
    "label": "Loa Ulung",
    "value": "Loa Ulung"
  },
  {
    "id": "6402162011",
    "district_id": "640216",
    "label": "Loa Raya",
    "value": "Loa Raya"
  },
  {
    "id": "6402162012",
    "district_id": "640216",
    "label": "Perjiwa",
    "value": "Perjiwa"
  },
  {
    "id": "6402162013",
    "district_id": "640216",
    "label": "Teluk Dalam",
    "value": "Teluk Dalam"
  },
  {
    "id": "6402162014",
    "district_id": "640216",
    "label": "Loa Lepu",
    "value": "Loa Lepu"
  },
  {
    "id": "6402162015",
    "district_id": "640216",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "6402162016",
    "district_id": "640216",
    "label": "Loa Pari",
    "value": "Loa Pari"
  },
  {
    "id": "6402162017",
    "district_id": "640216",
    "label": "Karang Tunggal",
    "value": "Karang Tunggal"
  },
  {
    "id": "6402162018",
    "district_id": "640216",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "6402172001",
    "district_id": "640217",
    "label": "Sebuntal",
    "value": "Sebuntal"
  },
  {
    "id": "6402172002",
    "district_id": "640217",
    "label": "Santan Ulu",
    "value": "Santan Ulu"
  },
  {
    "id": "6402172003",
    "district_id": "640217",
    "label": "Santan Tengah",
    "value": "Santan Tengah"
  },
  {
    "id": "6402172004",
    "district_id": "640217",
    "label": "Santan Ilir",
    "value": "Santan Ilir"
  },
  {
    "id": "6402172005",
    "district_id": "640217",
    "label": "Kersik",
    "value": "Kersik"
  },
  {
    "id": "6402172006",
    "district_id": "640217",
    "label": "Bunga Putih",
    "value": "Bunga Putih"
  },
  {
    "id": "6402172007",
    "district_id": "640217",
    "label": "Makarti",
    "value": "Makarti"
  },
  {
    "id": "6402172008",
    "district_id": "640217",
    "label": "Prangat Selatan",
    "value": "Prangat Selatan"
  },
  {
    "id": "6402172009",
    "district_id": "640217",
    "label": "Prangat Baru",
    "value": "Prangat Baru"
  },
  {
    "id": "6402172010",
    "district_id": "640217",
    "label": "Semangko",
    "value": "Semangko"
  },
  {
    "id": "6402172011",
    "district_id": "640217",
    "label": "Sambera Baru",
    "value": "Sambera Baru"
  },
  {
    "id": "6402182001",
    "district_id": "640218",
    "label": "Muara Wis",
    "value": "Muara Wis"
  },
  {
    "id": "6402182002",
    "district_id": "640218",
    "label": "Sebemban",
    "value": "Sebemban"
  },
  {
    "id": "6402182003",
    "district_id": "640218",
    "label": "Melintang",
    "value": "Melintang"
  },
  {
    "id": "6402182004",
    "district_id": "640218",
    "label": "Enggelam",
    "value": "Enggelam"
  },
  {
    "id": "6402182005",
    "district_id": "640218",
    "label": "Lebak Mantan",
    "value": "Lebak Mantan"
  },
  {
    "id": "6402182006",
    "district_id": "640218",
    "label": "Lebak Cilong",
    "value": "Lebak Cilong"
  },
  {
    "id": "6402182007",
    "district_id": "640218",
    "label": "Muara Enggelam",
    "value": "Muara Enggelam"
  },
  {
    "id": "6403012001",
    "district_id": "640301",
    "label": "Merabu",
    "value": "Merabu"
  },
  {
    "id": "6403012002",
    "district_id": "640301",
    "label": "Panaan",
    "value": "Panaan"
  },
  {
    "id": "6403012003",
    "district_id": "640301",
    "label": "Merapun",
    "value": "Merapun"
  },
  {
    "id": "6403012004",
    "district_id": "640301",
    "label": "Muara Lesan",
    "value": "Muara Lesan"
  },
  {
    "id": "6403012005",
    "district_id": "640301",
    "label": "Merasa",
    "value": "Merasa"
  },
  {
    "id": "6403012006",
    "district_id": "640301",
    "label": "Lesan Dayak",
    "value": "Lesan Dayak"
  },
  {
    "id": "6403012007",
    "district_id": "640301",
    "label": "Long Beliu",
    "value": "Long Beliu"
  },
  {
    "id": "6403012008",
    "district_id": "640301",
    "label": "Long Duhung",
    "value": "Long Duhung"
  },
  {
    "id": "6403012009",
    "district_id": "640301",
    "label": "Long Lamcin",
    "value": "Long Lamcin"
  },
  {
    "id": "6403012010",
    "district_id": "640301",
    "label": "Long Keluh",
    "value": "Long Keluh"
  },
  {
    "id": "6403012011",
    "district_id": "640301",
    "label": "Long Pelay",
    "value": "Long Pelay"
  },
  {
    "id": "6403012012",
    "district_id": "640301",
    "label": "Mapulu",
    "value": "Mapulu"
  },
  {
    "id": "6403012013",
    "district_id": "640301",
    "label": "Long Sului",
    "value": "Long Sului"
  },
  {
    "id": "6403012014",
    "district_id": "640301",
    "label": "Sido Bangen",
    "value": "Sido Bangen"
  },
  {
    "id": "6403022002",
    "district_id": "640302",
    "label": "Dumaring",
    "value": "Dumaring"
  },
  {
    "id": "6403022003",
    "district_id": "640302",
    "label": "Talisayan",
    "value": "Talisayan"
  },
  {
    "id": "6403022014",
    "district_id": "640302",
    "label": "Campur Sari",
    "value": "Campur Sari"
  },
  {
    "id": "6403022015",
    "district_id": "640302",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "6403022016",
    "district_id": "640302",
    "label": "Tunggal Bumi",
    "value": "Tunggal Bumi"
  },
  {
    "id": "6403022017",
    "district_id": "640302",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "6403022018",
    "district_id": "640302",
    "label": "Suka Murya",
    "value": "Suka Murya"
  },
  {
    "id": "6403022019",
    "district_id": "640302",
    "label": "Purna Sari Jaya",
    "value": "Purna Sari Jaya"
  },
  {
    "id": "6403022021",
    "district_id": "640302",
    "label": "Eka Sapta",
    "value": "Eka Sapta"
  },
  {
    "id": "6403022023",
    "district_id": "640302",
    "label": "Capuak",
    "value": "Capuak"
  },
  {
    "id": "6403031013",
    "district_id": "640303",
    "label": "Sambaliung",
    "value": "Sambaliung"
  },
  {
    "id": "6403032001",
    "district_id": "640303",
    "label": "Long Lanuk",
    "value": "Long Lanuk"
  },
  {
    "id": "6403032002",
    "district_id": "640303",
    "label": "Tumbit Dayak",
    "value": "Tumbit Dayak"
  },
  {
    "id": "6403032004",
    "district_id": "640303",
    "label": "Inaran",
    "value": "Inaran"
  },
  {
    "id": "6403032005",
    "district_id": "640303",
    "label": "Pegat Bukur",
    "value": "Pegat Bukur"
  },
  {
    "id": "6403032006",
    "district_id": "640303",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6403032007",
    "district_id": "640303",
    "label": "Suaran",
    "value": "Suaran"
  },
  {
    "id": "6403032008",
    "district_id": "640303",
    "label": "Pilanjau",
    "value": "Pilanjau"
  },
  {
    "id": "6403032009",
    "district_id": "640303",
    "label": "Pesayan",
    "value": "Pesayan"
  },
  {
    "id": "6403032010",
    "district_id": "640303",
    "label": "Sei Bebanir Bangun",
    "value": "Sei Bebanir Bangun"
  },
  {
    "id": "6403032011",
    "district_id": "640303",
    "label": "Gurimbang",
    "value": "Gurimbang"
  },
  {
    "id": "6403032012",
    "district_id": "640303",
    "label": "Sukan Tengah",
    "value": "Sukan Tengah"
  },
  {
    "id": "6403032014",
    "district_id": "640303",
    "label": "Tanjung Perangat",
    "value": "Tanjung Perangat"
  },
  {
    "id": "6403032015",
    "district_id": "640303",
    "label": "Bena Baru",
    "value": "Bena Baru"
  },
  {
    "id": "6403042001",
    "district_id": "640304",
    "label": "Long La'ai",
    "value": "Long La'ai"
  },
  {
    "id": "6403042002",
    "district_id": "640304",
    "label": "Punan Segah",
    "value": "Punan Segah"
  },
  {
    "id": "6403042003",
    "district_id": "640304",
    "label": "Long Ayap",
    "value": "Long Ayap"
  },
  {
    "id": "6403042004",
    "district_id": "640304",
    "label": "Long Ayan",
    "value": "Long Ayan"
  },
  {
    "id": "6403042005",
    "district_id": "640304",
    "label": "Punan Malinau",
    "value": "Punan Malinau"
  },
  {
    "id": "6403042006",
    "district_id": "640304",
    "label": "Punan Mahkam",
    "value": "Punan Mahkam"
  },
  {
    "id": "6403042007",
    "district_id": "640304",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "6403042008",
    "district_id": "640304",
    "label": "Pandan Sari",
    "value": "Pandan Sari"
  },
  {
    "id": "6403042009",
    "district_id": "640304",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "6403042010",
    "district_id": "640304",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "6403042011",
    "district_id": "640304",
    "label": "Tepian Buah",
    "value": "Tepian Buah"
  },
  {
    "id": "6403042012",
    "district_id": "640304",
    "label": "Batu Rajang",
    "value": "Batu Rajang"
  },
  {
    "id": "6403042013",
    "district_id": "640304",
    "label": "Siduung Indah",
    "value": "Siduung Indah"
  },
  {
    "id": "6403051003",
    "district_id": "640305",
    "label": "Sungai Bedungun",
    "value": "Sungai Bedungun"
  },
  {
    "id": "6403051004",
    "district_id": "640305",
    "label": "Tanjung Redeb",
    "value": "Tanjung Redeb"
  },
  {
    "id": "6403051005",
    "district_id": "640305",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "6403051006",
    "district_id": "640305",
    "label": "Gayam",
    "value": "Gayam"
  },
  {
    "id": "6403051007",
    "district_id": "640305",
    "label": "Karang Ambun",
    "value": "Karang Ambun"
  },
  {
    "id": "6403051008",
    "district_id": "640305",
    "label": "Gunung Panjang",
    "value": "Gunung Panjang"
  },
  {
    "id": "6403061003",
    "district_id": "640306",
    "label": "Gunung Tabur",
    "value": "Gunung Tabur"
  },
  {
    "id": "6403062001",
    "district_id": "640306",
    "label": "Tasuk",
    "value": "Tasuk"
  },
  {
    "id": "6403062002",
    "district_id": "640306",
    "label": "Birang",
    "value": "Birang"
  },
  {
    "id": "6403062004",
    "district_id": "640306",
    "label": "Maluang",
    "value": "Maluang"
  },
  {
    "id": "6403062005",
    "district_id": "640306",
    "label": "Samburakat",
    "value": "Samburakat"
  },
  {
    "id": "6403062006",
    "district_id": "640306",
    "label": "Sambakungan",
    "value": "Sambakungan"
  },
  {
    "id": "6403062007",
    "district_id": "640306",
    "label": "Merancang Ulu",
    "value": "Merancang Ulu"
  },
  {
    "id": "6403062008",
    "district_id": "640306",
    "label": "Merancang Ilir",
    "value": "Merancang Ilir"
  },
  {
    "id": "6403062009",
    "district_id": "640306",
    "label": "Pulau Besing",
    "value": "Pulau Besing"
  },
  {
    "id": "6403062010",
    "district_id": "640306",
    "label": "Melati Jaya",
    "value": "Melati Jaya"
  },
  {
    "id": "6403062011",
    "district_id": "640306",
    "label": "Batu-Batu",
    "value": "Batu-Batu"
  },
  {
    "id": "6403072001",
    "district_id": "640307",
    "label": "Pegat Batumbuk",
    "value": "Pegat Batumbuk"
  },
  {
    "id": "6403072002",
    "district_id": "640307",
    "label": "Teluk Semanting",
    "value": "Teluk Semanting"
  },
  {
    "id": "6403072003",
    "district_id": "640307",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "6403072004",
    "district_id": "640307",
    "label": "Pulau Derawan",
    "value": "Pulau Derawan"
  },
  {
    "id": "6403072009",
    "district_id": "640307",
    "label": "Kasai",
    "value": "Kasai"
  },
  {
    "id": "6403082001",
    "district_id": "640308",
    "label": "Biduk-Biduk",
    "value": "Biduk-Biduk"
  },
  {
    "id": "6403082002",
    "district_id": "640308",
    "label": "Pantai Harapan",
    "value": "Pantai Harapan"
  },
  {
    "id": "6403082003",
    "district_id": "640308",
    "label": "Tanjung Prepat",
    "value": "Tanjung Prepat"
  },
  {
    "id": "6403082004",
    "district_id": "640308",
    "label": "Teluk Sumbang",
    "value": "Teluk Sumbang"
  },
  {
    "id": "6403082008",
    "district_id": "640308",
    "label": "Teluk Sulaiman",
    "value": "Teluk Sulaiman"
  },
  {
    "id": "6403082009",
    "district_id": "640308",
    "label": "Giring-Giring",
    "value": "Giring-Giring"
  },
  {
    "id": "6403091002",
    "district_id": "640309",
    "label": "Teluk Bayur",
    "value": "Teluk Bayur"
  },
  {
    "id": "6403091003",
    "district_id": "640309",
    "label": "Rinding",
    "value": "Rinding"
  },
  {
    "id": "6403092001",
    "district_id": "640309",
    "label": "Tumbit Melayu",
    "value": "Tumbit Melayu"
  },
  {
    "id": "6403092004",
    "district_id": "640309",
    "label": "Labanan Jaya",
    "value": "Labanan Jaya"
  },
  {
    "id": "6403092005",
    "district_id": "640309",
    "label": "Labanan Makmur",
    "value": "Labanan Makmur"
  },
  {
    "id": "6403092006",
    "district_id": "640309",
    "label": "Labanan Makarti",
    "value": "Labanan Makarti"
  },
  {
    "id": "6403102002",
    "district_id": "640310",
    "label": "Tabalar Muara",
    "value": "Tabalar Muara"
  },
  {
    "id": "6403102003",
    "district_id": "640310",
    "label": "Tubaan",
    "value": "Tubaan"
  },
  {
    "id": "6403102004",
    "district_id": "640310",
    "label": "Tabalar Ulu",
    "value": "Tabalar Ulu"
  },
  {
    "id": "6403102005",
    "district_id": "640310",
    "label": "Semurut",
    "value": "Semurut"
  },
  {
    "id": "6403102006",
    "district_id": "640310",
    "label": "Buyung-Buyung",
    "value": "Buyung-Buyung"
  },
  {
    "id": "6403102007",
    "district_id": "640310",
    "label": "Harapan Maju",
    "value": "Harapan Maju"
  },
  {
    "id": "6403112001",
    "district_id": "640311",
    "label": "Bohe Silian",
    "value": "Bohe Silian"
  },
  {
    "id": "6403112002",
    "district_id": "640311",
    "label": "Payung-Payung",
    "value": "Payung-Payung"
  },
  {
    "id": "6403112003",
    "district_id": "640311",
    "label": "Teluk Alulu",
    "value": "Teluk Alulu"
  },
  {
    "id": "6403112004",
    "district_id": "640311",
    "label": "Teluk Harapan",
    "value": "Teluk Harapan"
  },
  {
    "id": "6403122001",
    "district_id": "640312",
    "label": "Tembudan",
    "value": "Tembudan"
  },
  {
    "id": "6403122002",
    "district_id": "640312",
    "label": "Kayu Indah",
    "value": "Kayu Indah"
  },
  {
    "id": "6403122003",
    "district_id": "640312",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "6403122004",
    "district_id": "640312",
    "label": "Lobang Kelatak",
    "value": "Lobang Kelatak"
  },
  {
    "id": "6403122005",
    "district_id": "640312",
    "label": "Ampen Medang",
    "value": "Ampen Medang"
  },
  {
    "id": "6403122006",
    "district_id": "640312",
    "label": "Balikukup",
    "value": "Balikukup"
  },
  {
    "id": "6403122007",
    "district_id": "640312",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "6403132001",
    "district_id": "640313",
    "label": "Biatan Ulu",
    "value": "Biatan Ulu"
  },
  {
    "id": "6403132002",
    "district_id": "640313",
    "label": "Biatan Ilir",
    "value": "Biatan Ilir"
  },
  {
    "id": "6403132003",
    "district_id": "640313",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "6403132004",
    "district_id": "640313",
    "label": "Biatan Lempake",
    "value": "Biatan Lempake"
  },
  {
    "id": "6403132005",
    "district_id": "640313",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "6403132006",
    "district_id": "640313",
    "label": "Biatan Bapinang",
    "value": "Biatan Bapinang"
  },
  {
    "id": "6403132007",
    "district_id": "640313",
    "label": "Biatan Baru",
    "value": "Biatan Baru"
  },
  {
    "id": "6403132008",
    "district_id": "640313",
    "label": "Bukit Makmur Jaya",
    "value": "Bukit Makmur Jaya"
  },
  {
    "id": "6407052008",
    "district_id": "640705",
    "label": "Linggang Muara Leban",
    "value": "Linggang Muara Leban"
  },
  {
    "id": "6407052009",
    "district_id": "640705",
    "label": "Long Iram Seberang",
    "value": "Long Iram Seberang"
  },
  {
    "id": "6407052010",
    "district_id": "640705",
    "label": "Long Iram Ilir",
    "value": "Long Iram Ilir"
  },
  {
    "id": "6407052011",
    "district_id": "640705",
    "label": "Anah",
    "value": "Anah"
  },
  {
    "id": "6407052015",
    "district_id": "640705",
    "label": "Long Daliq",
    "value": "Long Daliq"
  },
  {
    "id": "6407052016",
    "district_id": "640705",
    "label": "Long Iram Kota",
    "value": "Long Iram Kota"
  },
  {
    "id": "6407052017",
    "district_id": "640705",
    "label": "Long Iram Bayan",
    "value": "Long Iram Bayan"
  },
  {
    "id": "6407052018",
    "district_id": "640705",
    "label": "Keliwai",
    "value": "Keliwai"
  },
  {
    "id": "6407052019",
    "district_id": "640705",
    "label": "Ujoh Halang",
    "value": "Ujoh Halang"
  },
  {
    "id": "6407052021",
    "district_id": "640705",
    "label": "Kelian Luar",
    "value": "Kelian Luar"
  },
  {
    "id": "6407052022",
    "district_id": "640705",
    "label": "Sukomulyo",
    "value": "Sukomulyo"
  },
  {
    "id": "6407061006",
    "district_id": "640706",
    "label": "Melak Ilir",
    "value": "Melak Ilir"
  },
  {
    "id": "6407061011",
    "district_id": "640706",
    "label": "Melak Ulu",
    "value": "Melak Ulu"
  },
  {
    "id": "6407062001",
    "district_id": "640706",
    "label": "Empas",
    "value": "Empas"
  },
  {
    "id": "6407062002",
    "district_id": "640706",
    "label": "Empakuq",
    "value": "Empakuq"
  },
  {
    "id": "6407062003",
    "district_id": "640706",
    "label": "Muara Bunyut",
    "value": "Muara Bunyut"
  },
  {
    "id": "6407062012",
    "district_id": "640706",
    "label": "Muara Benangaq",
    "value": "Muara Benangaq"
  },
  {
    "id": "6407071001",
    "district_id": "640707",
    "label": "Barong Tongkok",
    "value": "Barong Tongkok"
  },
  {
    "id": "6407071021",
    "district_id": "640707",
    "label": "Simpang Raya",
    "value": "Simpang Raya"
  },
  {
    "id": "6407072002",
    "district_id": "640707",
    "label": "Mencimai",
    "value": "Mencimai"
  },
  {
    "id": "6407072003",
    "district_id": "640707",
    "label": "Engkuni Pasek",
    "value": "Engkuni Pasek"
  },
  {
    "id": "6407072004",
    "district_id": "640707",
    "label": "Pepas Eheng",
    "value": "Pepas Eheng"
  },
  {
    "id": "6407072006",
    "district_id": "640707",
    "label": "Juhan Asa",
    "value": "Juhan Asa"
  },
  {
    "id": "6407072007",
    "district_id": "640707",
    "label": "Asa",
    "value": "Asa"
  },
  {
    "id": "6407072008",
    "district_id": "640707",
    "label": "Pepes Asa",
    "value": "Pepes Asa"
  },
  {
    "id": "6407072009",
    "district_id": "640707",
    "label": "Muara Asa",
    "value": "Muara Asa"
  },
  {
    "id": "6407072010",
    "district_id": "640707",
    "label": "Ongko Asa",
    "value": "Ongko Asa"
  },
  {
    "id": "6407072011",
    "district_id": "640707",
    "label": "Juaq Asa",
    "value": "Juaq Asa"
  },
  {
    "id": "6407072012",
    "district_id": "640707",
    "label": "Ombau Asa",
    "value": "Ombau Asa"
  },
  {
    "id": "6407072013",
    "district_id": "640707",
    "label": "Ngenyan Asa",
    "value": "Ngenyan Asa"
  },
  {
    "id": "6407072014",
    "district_id": "640707",
    "label": "Gemuhan Asa",
    "value": "Gemuhan Asa"
  },
  {
    "id": "6407072015",
    "district_id": "640707",
    "label": "Geleo Baru",
    "value": "Geleo Baru"
  },
  {
    "id": "6407072016",
    "district_id": "640707",
    "label": "Geleo Asa",
    "value": "Geleo Asa"
  },
  {
    "id": "6407072017",
    "district_id": "640707",
    "label": "Rejo Basuki",
    "value": "Rejo Basuki"
  },
  {
    "id": "6407072018",
    "district_id": "640707",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6407072019",
    "district_id": "640707",
    "label": "Sendawar",
    "value": "Sendawar"
  },
  {
    "id": "6407072020",
    "district_id": "640707",
    "label": "Balok Asa",
    "value": "Balok Asa"
  },
  {
    "id": "6407072022",
    "district_id": "640707",
    "label": "Belempung Ulaq",
    "value": "Belempung Ulaq"
  },
  {
    "id": "6407082001",
    "district_id": "640708",
    "label": "Besiq",
    "value": "Besiq"
  },
  {
    "id": "6407082002",
    "district_id": "640708",
    "label": "Bermai",
    "value": "Bermai"
  },
  {
    "id": "6407082003",
    "district_id": "640708",
    "label": "Muara Niliq",
    "value": "Muara Niliq"
  },
  {
    "id": "6407082004",
    "district_id": "640708",
    "label": "Mantar",
    "value": "Mantar"
  },
  {
    "id": "6407082005",
    "district_id": "640708",
    "label": "Muara Bomboy",
    "value": "Muara Bomboy"
  },
  {
    "id": "6407082006",
    "district_id": "640708",
    "label": "Damai Seberang",
    "value": "Damai Seberang"
  },
  {
    "id": "6407082007",
    "district_id": "640708",
    "label": "Mendika",
    "value": "Mendika"
  },
  {
    "id": "6407082008",
    "district_id": "640708",
    "label": "Damai Kota",
    "value": "Damai Kota"
  },
  {
    "id": "6407082009",
    "district_id": "640708",
    "label": "Lumpat Dahuq",
    "value": "Lumpat Dahuq"
  },
  {
    "id": "6407082010",
    "district_id": "640708",
    "label": "Muara Tokong",
    "value": "Muara Tokong"
  },
  {
    "id": "6407082011",
    "district_id": "640708",
    "label": "Jengan Danum",
    "value": "Jengan Danum"
  },
  {
    "id": "6407082012",
    "district_id": "640708",
    "label": "Keay",
    "value": "Keay"
  },
  {
    "id": "6407082013",
    "district_id": "640708",
    "label": "Tapulang",
    "value": "Tapulang"
  },
  {
    "id": "6407082014",
    "district_id": "640708",
    "label": "Benung",
    "value": "Benung"
  },
  {
    "id": "6407082020",
    "district_id": "640708",
    "label": "Muara Nyahing",
    "value": "Muara Nyahing"
  },
  {
    "id": "6407082021",
    "district_id": "640708",
    "label": "Sempatn",
    "value": "Sempatn"
  },
  {
    "id": "6407082022",
    "district_id": "640708",
    "label": "Kelian",
    "value": "Kelian"
  },
  {
    "id": "6407092001",
    "district_id": "640709",
    "label": "Muara Begai",
    "value": "Muara Begai"
  },
  {
    "id": "6407092002",
    "district_id": "640709",
    "label": "Lotaq",
    "value": "Lotaq"
  },
  {
    "id": "6407092003",
    "district_id": "640709",
    "label": "Payang",
    "value": "Payang"
  },
  {
    "id": "6407092004",
    "district_id": "640709",
    "label": "Dingin",
    "value": "Dingin"
  },
  {
    "id": "6407092005",
    "district_id": "640709",
    "label": "Muara Lawa",
    "value": "Muara Lawa"
  },
  {
    "id": "6407092006",
    "district_id": "640709",
    "label": "Lambing",
    "value": "Lambing"
  },
  {
    "id": "6407092007",
    "district_id": "640709",
    "label": "Benggeris",
    "value": "Benggeris"
  },
  {
    "id": "6407092008",
    "district_id": "640709",
    "label": "Cempedes",
    "value": "Cempedes"
  },
  {
    "id": "6407102015",
    "district_id": "640710",
    "label": "Jerang Dayak",
    "value": "Jerang Dayak"
  },
  {
    "id": "6407102016",
    "district_id": "640710",
    "label": "Mendung",
    "value": "Mendung"
  },
  {
    "id": "6407102017",
    "district_id": "640710",
    "label": "Jerang Melayu",
    "value": "Jerang Melayu"
  },
  {
    "id": "6407102018",
    "district_id": "640710",
    "label": "Dasaq",
    "value": "Dasaq"
  },
  {
    "id": "6407102020",
    "district_id": "640710",
    "label": "Tepian Ulaq",
    "value": "Tepian Ulaq"
  },
  {
    "id": "6407102021",
    "district_id": "640710",
    "label": "Sebelang",
    "value": "Sebelang"
  },
  {
    "id": "6407102022",
    "district_id": "640710",
    "label": "Muara Baroh",
    "value": "Muara Baroh"
  },
  {
    "id": "6407102023",
    "district_id": "640710",
    "label": "Teluk Tempudau",
    "value": "Teluk Tempudau"
  },
  {
    "id": "6407102024",
    "district_id": "640710",
    "label": "Tanjung Laong",
    "value": "Tanjung Laong"
  },
  {
    "id": "6407102025",
    "district_id": "640710",
    "label": "Tanjung Pagar",
    "value": "Tanjung Pagar"
  },
  {
    "id": "6407102029",
    "district_id": "640710",
    "label": "Gunung Bayan",
    "value": "Gunung Bayan"
  },
  {
    "id": "6407102030",
    "district_id": "640710",
    "label": "Muara Beloan",
    "value": "Muara Beloan"
  },
  {
    "id": "6407112001",
    "district_id": "640711",
    "label": "Pentat",
    "value": "Pentat"
  },
  {
    "id": "6407112002",
    "district_id": "640711",
    "label": "Lembonah",
    "value": "Lembonah"
  },
  {
    "id": "6407112003",
    "district_id": "640711",
    "label": "Muara Nayan",
    "value": "Muara Nayan"
  },
  {
    "id": "6407112004",
    "district_id": "640711",
    "label": "Mancong",
    "value": "Mancong"
  },
  {
    "id": "6407112005",
    "district_id": "640711",
    "label": "Perigiq",
    "value": "Perigiq"
  },
  {
    "id": "6407112006",
    "district_id": "640711",
    "label": "Muara Ohong",
    "value": "Muara Ohong"
  },
  {
    "id": "6407112007",
    "district_id": "640711",
    "label": "Tanjung Jone",
    "value": "Tanjung Jone"
  },
  {
    "id": "6407112008",
    "district_id": "640711",
    "label": "Tanjung Isuy",
    "value": "Tanjung Isuy"
  },
  {
    "id": "6407112009",
    "district_id": "640711",
    "label": "Tanjung Jan",
    "value": "Tanjung Jan"
  },
  {
    "id": "6407112010",
    "district_id": "640711",
    "label": "Pulau Lanting",
    "value": "Pulau Lanting"
  },
  {
    "id": "6407112011",
    "district_id": "640711",
    "label": "Muara Tae",
    "value": "Muara Tae"
  },
  {
    "id": "6407112012",
    "district_id": "640711",
    "label": "Bekokong Makmur",
    "value": "Bekokong Makmur"
  },
  {
    "id": "6407122001",
    "district_id": "640712",
    "label": "Gerungung",
    "value": "Gerungung"
  },
  {
    "id": "6407122002",
    "district_id": "640712",
    "label": "Pereng Taliq",
    "value": "Pereng Taliq"
  },
  {
    "id": "6407122003",
    "district_id": "640712",
    "label": "Tanjung Soke",
    "value": "Tanjung Soke"
  },
  {
    "id": "6407122004",
    "district_id": "640712",
    "label": "Deraya",
    "value": "Deraya"
  },
  {
    "id": "6407122005",
    "district_id": "640712",
    "label": "Lemper",
    "value": "Lemper"
  },
  {
    "id": "6407122006",
    "district_id": "640712",
    "label": "Muara Siram",
    "value": "Muara Siram"
  },
  {
    "id": "6407122007",
    "district_id": "640712",
    "label": "Resak",
    "value": "Resak"
  },
  {
    "id": "6407122008",
    "district_id": "640712",
    "label": "Jambuk",
    "value": "Jambuk"
  },
  {
    "id": "6407122009",
    "district_id": "640712",
    "label": "Muara Gusik",
    "value": "Muara Gusik"
  },
  {
    "id": "6407122010",
    "district_id": "640712",
    "label": "Penawai",
    "value": "Penawai"
  },
  {
    "id": "6407122011",
    "district_id": "640712",
    "label": "Muara Kedang",
    "value": "Muara Kedang"
  },
  {
    "id": "6407122012",
    "district_id": "640712",
    "label": "Jambuk Makmur",
    "value": "Jambuk Makmur"
  },
  {
    "id": "6407122014",
    "district_id": "640712",
    "label": "Siram Makmur",
    "value": "Siram Makmur"
  },
  {
    "id": "6407122015",
    "district_id": "640712",
    "label": "Siram Jaya",
    "value": "Siram Jaya"
  },
  {
    "id": "6407122016",
    "district_id": "640712",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "6407122019",
    "district_id": "640712",
    "label": "Tanjung Sari",
    "value": "Tanjung Sari"
  },
  {
    "id": "6407132001",
    "district_id": "640713",
    "label": "Loa Deras",
    "value": "Loa Deras"
  },
  {
    "id": "6407132002",
    "district_id": "640713",
    "label": "Minta",
    "value": "Minta"
  },
  {
    "id": "6407132003",
    "district_id": "640713",
    "label": "Tanjung Haur",
    "value": "Tanjung Haur"
  },
  {
    "id": "6407132004",
    "district_id": "640713",
    "label": "Penyinggahan Ilir",
    "value": "Penyinggahan Ilir"
  },
  {
    "id": "6407132005",
    "district_id": "640713",
    "label": "Penyinggahan Ulu",
    "value": "Penyinggahan Ulu"
  },
  {
    "id": "6407132006",
    "district_id": "640713",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "6407142001",
    "district_id": "640714",
    "label": "Randa Empas",
    "value": "Randa Empas"
  },
  {
    "id": "6407142002",
    "district_id": "640714",
    "label": "Tukuq",
    "value": "Tukuq"
  },
  {
    "id": "6407142003",
    "district_id": "640714",
    "label": "Tende",
    "value": "Tende"
  },
  {
    "id": "6407142004",
    "district_id": "640714",
    "label": "Sambung",
    "value": "Sambung"
  },
  {
    "id": "6407142005",
    "district_id": "640714",
    "label": "Anan Jaya",
    "value": "Anan Jaya"
  },
  {
    "id": "6407142006",
    "district_id": "640714",
    "label": "Jelmu Sibak",
    "value": "Jelmu Sibak"
  },
  {
    "id": "6407142007",
    "district_id": "640714",
    "label": "Suakong",
    "value": "Suakong"
  },
  {
    "id": "6407142008",
    "district_id": "640714",
    "label": "Penarung",
    "value": "Penarung"
  },
  {
    "id": "6407142009",
    "district_id": "640714",
    "label": "Dilang Puti",
    "value": "Dilang Puti"
  },
  {
    "id": "6407152001",
    "district_id": "640715",
    "label": "Linggang Melapeh Baru",
    "value": "Linggang Melapeh Baru"
  },
  {
    "id": "6407152002",
    "district_id": "640715",
    "label": "Linggang Bigung Baru",
    "value": "Linggang Bigung Baru"
  },
  {
    "id": "6407152003",
    "district_id": "640715",
    "label": "Linggang Tutung",
    "value": "Linggang Tutung"
  },
  {
    "id": "6407152004",
    "district_id": "640715",
    "label": "Linggang Melapeh",
    "value": "Linggang Melapeh"
  },
  {
    "id": "6407152005",
    "district_id": "640715",
    "label": "Linggang Mapan",
    "value": "Linggang Mapan"
  },
  {
    "id": "6407152006",
    "district_id": "640715",
    "label": "Linggang Bigung",
    "value": "Linggang Bigung"
  },
  {
    "id": "6407152007",
    "district_id": "640715",
    "label": "Linggang Amer",
    "value": "Linggang Amer"
  },
  {
    "id": "6407152008",
    "district_id": "640715",
    "label": "Linggang Bangunsari",
    "value": "Linggang Bangunsari"
  },
  {
    "id": "6407152009",
    "district_id": "640715",
    "label": "Linggang Purwodadi",
    "value": "Linggang Purwodadi"
  },
  {
    "id": "6407152010",
    "district_id": "640715",
    "label": "Linggang Mencelew",
    "value": "Linggang Mencelew"
  },
  {
    "id": "6407152011",
    "district_id": "640715",
    "label": "Linggang Kebut",
    "value": "Linggang Kebut"
  },
  {
    "id": "6407162001",
    "district_id": "640716",
    "label": "Dempar",
    "value": "Dempar"
  },
  {
    "id": "6407162002",
    "district_id": "640716",
    "label": "Temula",
    "value": "Temula"
  },
  {
    "id": "6407162003",
    "district_id": "640716",
    "label": "Jontai",
    "value": "Jontai"
  },
  {
    "id": "6407162004",
    "district_id": "640716",
    "label": "Sembuan",
    "value": "Sembuan"
  },
  {
    "id": "6407162005",
    "district_id": "640716",
    "label": "Intu Lingau",
    "value": "Intu Lingau"
  },
  {
    "id": "6407162006",
    "district_id": "640716",
    "label": "Mu'ut",
    "value": "Mu'ut"
  },
  {
    "id": "6407162007",
    "district_id": "640716",
    "label": "Terajuk",
    "value": "Terajuk"
  },
  {
    "id": "6407162008",
    "district_id": "640716",
    "label": "Lakan Bilem",
    "value": "Lakan Bilem"
  },
  {
    "id": "6407162009",
    "district_id": "640716",
    "label": "Sentalar",
    "value": "Sentalar"
  },
  {
    "id": "6407162010",
    "district_id": "640716",
    "label": "Awai",
    "value": "Awai"
  },
  {
    "id": "6407172001",
    "district_id": "640717",
    "label": "Tebisaq",
    "value": "Tebisaq"
  },
  {
    "id": "6407172002",
    "district_id": "640717",
    "label": "Kaliq",
    "value": "Kaliq"
  },
  {
    "id": "6407172003",
    "district_id": "640717",
    "label": "Tanah Mea",
    "value": "Tanah Mea"
  },
  {
    "id": "6407172004",
    "district_id": "640717",
    "label": "Sang-Sang",
    "value": "Sang-Sang"
  },
  {
    "id": "6407172005",
    "district_id": "640717",
    "label": "Muhur",
    "value": "Muhur"
  },
  {
    "id": "6407172006",
    "district_id": "640717",
    "label": "Muara Kelawit",
    "value": "Muara Kelawit"
  },
  {
    "id": "6407172007",
    "district_id": "640717",
    "label": "Bentas",
    "value": "Bentas"
  },
  {
    "id": "6407172008",
    "district_id": "640717",
    "label": "Betung",
    "value": "Betung"
  },
  {
    "id": "6407172009",
    "district_id": "640717",
    "label": "Kiaq",
    "value": "Kiaq"
  },
  {
    "id": "6407172010",
    "district_id": "640717",
    "label": "Tendiq",
    "value": "Tendiq"
  },
  {
    "id": "6407172011",
    "district_id": "640717",
    "label": "Penawang",
    "value": "Penawang"
  },
  {
    "id": "6407172012",
    "district_id": "640717",
    "label": "Lendian Liang Nayuq",
    "value": "Lendian Liang Nayuq"
  },
  {
    "id": "6407172013",
    "district_id": "640717",
    "label": "Kenyanyan",
    "value": "Kenyanyan"
  },
  {
    "id": "6407172014",
    "district_id": "640717",
    "label": "Rikong",
    "value": "Rikong"
  },
  {
    "id": "6407172015",
    "district_id": "640717",
    "label": "Kendisiq",
    "value": "Kendisiq"
  },
  {
    "id": "6407172016",
    "district_id": "640717",
    "label": "Muara Ponaq",
    "value": "Muara Ponaq"
  },
  {
    "id": "6407182001",
    "district_id": "640718",
    "label": "Sakaq Lotoq",
    "value": "Sakaq Lotoq"
  },
  {
    "id": "6407182002",
    "district_id": "640718",
    "label": "Sakaq Tada",
    "value": "Sakaq Tada"
  },
  {
    "id": "6407182003",
    "district_id": "640718",
    "label": "Gemuruh",
    "value": "Gemuruh"
  },
  {
    "id": "6407182004",
    "district_id": "640718",
    "label": "Karangan",
    "value": "Karangan"
  },
  {
    "id": "6407182005",
    "district_id": "640718",
    "label": "Merayaq",
    "value": "Merayaq"
  },
  {
    "id": "6407182006",
    "district_id": "640718",
    "label": "Linggang Marimun",
    "value": "Linggang Marimun"
  },
  {
    "id": "6407182007",
    "district_id": "640718",
    "label": "Kelumpang",
    "value": "Kelumpang"
  },
  {
    "id": "6407182008",
    "district_id": "640718",
    "label": "Gadur",
    "value": "Gadur"
  },
  {
    "id": "6407182009",
    "district_id": "640718",
    "label": "Linggang Muara Batuq",
    "value": "Linggang Muara Batuq"
  },
  {
    "id": "6407182010",
    "district_id": "640718",
    "label": "Muara Jawaq",
    "value": "Muara Jawaq"
  },
  {
    "id": "6407182011",
    "district_id": "640718",
    "label": "Abit",
    "value": "Abit"
  },
  {
    "id": "6407182012",
    "district_id": "640718",
    "label": "Rembayan",
    "value": "Rembayan"
  },
  {
    "id": "6407182014",
    "district_id": "640718",
    "label": "Gunung Rampah",
    "value": "Gunung Rampah"
  },
  {
    "id": "6407182015",
    "district_id": "640718",
    "label": "Jengan",
    "value": "Jengan"
  },
  {
    "id": "6407182016",
    "district_id": "640718",
    "label": "Muara Kalaq",
    "value": "Muara Kalaq"
  },
  {
    "id": "6407182017",
    "district_id": "640718",
    "label": "Tondoh",
    "value": "Tondoh"
  },
  {
    "id": "6407192001",
    "district_id": "640719",
    "label": "Tering Baru",
    "value": "Tering Baru"
  },
  {
    "id": "6407192002",
    "district_id": "640719",
    "label": "Tering Lama",
    "value": "Tering Lama"
  },
  {
    "id": "6407192003",
    "district_id": "640719",
    "label": "Linggang Tering Seberang",
    "value": "Linggang Tering Seberang"
  },
  {
    "id": "6407192004",
    "district_id": "640719",
    "label": "Tukul",
    "value": "Tukul"
  },
  {
    "id": "6407192005",
    "district_id": "640719",
    "label": "Linggang Kelubaq",
    "value": "Linggang Kelubaq"
  },
  {
    "id": "6407192006",
    "district_id": "640719",
    "label": "Linggang Purworejo",
    "value": "Linggang Purworejo"
  },
  {
    "id": "6407192007",
    "district_id": "640719",
    "label": "Linggang Jelemuq",
    "value": "Linggang Jelemuq"
  },
  {
    "id": "6407192008",
    "district_id": "640719",
    "label": "Kelian Dalam",
    "value": "Kelian Dalam"
  },
  {
    "id": "6407192009",
    "district_id": "640719",
    "label": "Linggang Muara Mujan",
    "value": "Linggang Muara Mujan"
  },
  {
    "id": "6407192010",
    "district_id": "640719",
    "label": "Muyub Ulu",
    "value": "Muyub Ulu"
  },
  {
    "id": "6407192011",
    "district_id": "640719",
    "label": "Muyub Aket",
    "value": "Muyub Aket"
  },
  {
    "id": "6407192013",
    "district_id": "640719",
    "label": "Linggang Muyub Ilir",
    "value": "Linggang Muyub Ilir"
  },
  {
    "id": "6407192014",
    "district_id": "640719",
    "label": "Gabung Baru",
    "value": "Gabung Baru"
  },
  {
    "id": "6407192015",
    "district_id": "640719",
    "label": "Linggang Banjarejo",
    "value": "Linggang Banjarejo"
  },
  {
    "id": "6407192016",
    "district_id": "640719",
    "label": "Tering Lama Ulu",
    "value": "Tering Lama Ulu"
  },
  {
    "id": "6407202001",
    "district_id": "640720",
    "label": "Sekolaq Joleq",
    "value": "Sekolaq Joleq"
  },
  {
    "id": "6407202002",
    "district_id": "640720",
    "label": "Sekolaq Muliaq",
    "value": "Sekolaq Muliaq"
  },
  {
    "id": "6407202003",
    "district_id": "640720",
    "label": "Sekolaq Oday",
    "value": "Sekolaq Oday"
  },
  {
    "id": "6407202004",
    "district_id": "640720",
    "label": "Srimulyo",
    "value": "Srimulyo"
  },
  {
    "id": "6407202005",
    "district_id": "640720",
    "label": "Sember Bangun",
    "value": "Sember Bangun"
  },
  {
    "id": "6407202006",
    "district_id": "640720",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "6407202007",
    "district_id": "640720",
    "label": "Sekolaq Darat",
    "value": "Sekolaq Darat"
  },
  {
    "id": "6407202008",
    "district_id": "640720",
    "label": "Leleng",
    "value": "Leleng"
  },
  {
    "id": "6408012001",
    "district_id": "640801",
    "label": "Senyiur",
    "value": "Senyiur"
  },
  {
    "id": "6408012002",
    "district_id": "640801",
    "label": "Kelinjau Ilir",
    "value": "Kelinjau Ilir"
  },
  {
    "id": "6408012003",
    "district_id": "640801",
    "label": "Kelinjau Ulu",
    "value": "Kelinjau Ulu"
  },
  {
    "id": "6408012004",
    "district_id": "640801",
    "label": "Long Nah",
    "value": "Long Nah"
  },
  {
    "id": "6408012006",
    "district_id": "640801",
    "label": "Long Tesak",
    "value": "Long Tesak"
  },
  {
    "id": "6408012009",
    "district_id": "640801",
    "label": "Gemar Baru",
    "value": "Gemar Baru"
  },
  {
    "id": "6408012012",
    "district_id": "640801",
    "label": "Long Poq Baru",
    "value": "Long Poq Baru"
  },
  {
    "id": "6408012013",
    "district_id": "640801",
    "label": "Muara Dun",
    "value": "Muara Dun"
  },
  {
    "id": "6408012014",
    "district_id": "640801",
    "label": "Teluk Baru",
    "value": "Teluk Baru"
  },
  {
    "id": "6408022001",
    "district_id": "640802",
    "label": "Jak Luay",
    "value": "Jak Luay"
  },
  {
    "id": "6408022002",
    "district_id": "640802",
    "label": "Nehes Liah Bing",
    "value": "Nehes Liah Bing"
  },
  {
    "id": "6408022003",
    "district_id": "640802",
    "label": "Muara Wahau",
    "value": "Muara Wahau"
  },
  {
    "id": "6408022004",
    "district_id": "640802",
    "label": "Dabeq",
    "value": "Dabeq"
  },
  {
    "id": "6408022005",
    "district_id": "640802",
    "label": "Diaq Lay",
    "value": "Diaq Lay"
  },
  {
    "id": "6408022006",
    "district_id": "640802",
    "label": "Benhes",
    "value": "Benhes"
  },
  {
    "id": "6408022007",
    "district_id": "640802",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "6408022008",
    "district_id": "640802",
    "label": "Wahau Baru",
    "value": "Wahau Baru"
  },
  {
    "id": "6408022009",
    "district_id": "640802",
    "label": "Karya Bhakti",
    "value": "Karya Bhakti"
  },
  {
    "id": "6408022010",
    "district_id": "640802",
    "label": "Long Wehea",
    "value": "Long Wehea"
  },
  {
    "id": "6408032001",
    "district_id": "640803",
    "label": "Senambah",
    "value": "Senambah"
  },
  {
    "id": "6408032002",
    "district_id": "640803",
    "label": "Ngayau",
    "value": "Ngayau"
  },
  {
    "id": "6408032003",
    "district_id": "640803",
    "label": "Muara Bengkal Ilir",
    "value": "Muara Bengkal Ilir"
  },
  {
    "id": "6408032004",
    "district_id": "640803",
    "label": "Muara Bengkal Ulu",
    "value": "Muara Bengkal Ulu"
  },
  {
    "id": "6408032005",
    "district_id": "640803",
    "label": "Benua Baru",
    "value": "Benua Baru"
  },
  {
    "id": "6408032013",
    "district_id": "640803",
    "label": "Mulupan",
    "value": "Mulupan"
  },
  {
    "id": "6408032014",
    "district_id": "640803",
    "label": "Batu Balai",
    "value": "Batu Balai"
  },
  {
    "id": "6408041010",
    "district_id": "640804",
    "label": "Teluk Lingga",
    "value": "Teluk Lingga"
  },
  {
    "id": "6408042001",
    "district_id": "640804",
    "label": "Sangatta Utara",
    "value": "Sangatta Utara"
  },
  {
    "id": "6408042011",
    "district_id": "640804",
    "label": "Singa Gembara",
    "value": "Singa Gembara"
  },
  {
    "id": "6408042012",
    "district_id": "640804",
    "label": "Swarga Bara",
    "value": "Swarga Bara"
  },
  {
    "id": "6408052001",
    "district_id": "640805",
    "label": "Kerayaan",
    "value": "Kerayaan"
  },
  {
    "id": "6408052002",
    "district_id": "640805",
    "label": "Tanjung Manis",
    "value": "Tanjung Manis"
  },
  {
    "id": "6408052003",
    "district_id": "640805",
    "label": "Peridan",
    "value": "Peridan"
  },
  {
    "id": "6408052004",
    "district_id": "640805",
    "label": "Saka",
    "value": "Saka"
  },
  {
    "id": "6408052005",
    "district_id": "640805",
    "label": "Mandu Dalam",
    "value": "Mandu Dalam"
  },
  {
    "id": "6408052006",
    "district_id": "640805",
    "label": "Benua Baru",
    "value": "Benua Baru"
  },
  {
    "id": "6408052011",
    "district_id": "640805",
    "label": "Sempayau",
    "value": "Sempayau"
  },
  {
    "id": "6408052012",
    "district_id": "640805",
    "label": "Pelawan",
    "value": "Pelawan"
  },
  {
    "id": "6408052013",
    "district_id": "640805",
    "label": "Tepian Terap",
    "value": "Tepian Terap"
  },
  {
    "id": "6408052015",
    "district_id": "640805",
    "label": "Maloy",
    "value": "Maloy"
  },
  {
    "id": "6408052016",
    "district_id": "640805",
    "label": "Benua Baru Ulu",
    "value": "Benua Baru Ulu"
  },
  {
    "id": "6408052017",
    "district_id": "640805",
    "label": "Kolek",
    "value": "Kolek"
  },
  {
    "id": "6408052018",
    "district_id": "640805",
    "label": "Pulau Miang",
    "value": "Pulau Miang"
  },
  {
    "id": "6408052019",
    "district_id": "640805",
    "label": "Perupuk",
    "value": "Perupuk"
  },
  {
    "id": "6408052020",
    "district_id": "640805",
    "label": "Mandu Pantai Sejahtera",
    "value": "Mandu Pantai Sejahtera"
  },
  {
    "id": "6408062001",
    "district_id": "640806",
    "label": "Long Bentuq",
    "value": "Long Bentuq"
  },
  {
    "id": "6408062002",
    "district_id": "640806",
    "label": "Long Pejeng",
    "value": "Long Pejeng"
  },
  {
    "id": "6408062003",
    "district_id": "640806",
    "label": "Long Lees",
    "value": "Long Lees"
  },
  {
    "id": "6408062004",
    "district_id": "640806",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "6408062005",
    "district_id": "640806",
    "label": "Rantau Sentosa",
    "value": "Rantau Sentosa"
  },
  {
    "id": "6408062006",
    "district_id": "640806",
    "label": "Long Nyelong",
    "value": "Long Nyelong"
  },
  {
    "id": "6408072001",
    "district_id": "640807",
    "label": "Marah Haloq",
    "value": "Marah Haloq"
  },
  {
    "id": "6408072002",
    "district_id": "640807",
    "label": "Lung Melah",
    "value": "Lung Melah"
  },
  {
    "id": "6408072003",
    "district_id": "640807",
    "label": "Juk Ayaq",
    "value": "Juk Ayaq"
  },
  {
    "id": "6408072004",
    "district_id": "640807",
    "label": "Long Segar",
    "value": "Long Segar"
  },
  {
    "id": "6408072005",
    "district_id": "640807",
    "label": "Long Noran",
    "value": "Long Noran"
  },
  {
    "id": "6408072006",
    "district_id": "640807",
    "label": "Muara Pantun",
    "value": "Muara Pantun"
  },
  {
    "id": "6408072007",
    "district_id": "640807",
    "label": "Rantau Panjang",
    "value": "Rantau Panjang"
  },
  {
    "id": "6408072008",
    "district_id": "640807",
    "label": "Kernyanyan",
    "value": "Kernyanyan"
  },
  {
    "id": "6408082001",
    "district_id": "640808",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "6408082002",
    "district_id": "640808",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "6408082003",
    "district_id": "640808",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "6408082004",
    "district_id": "640808",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6408082005",
    "district_id": "640808",
    "label": "Sri Pantun",
    "value": "Sri Pantun"
  },
  {
    "id": "6408082006",
    "district_id": "640808",
    "label": "Kombeng Indah",
    "value": "Kombeng Indah"
  },
  {
    "id": "6408082007",
    "district_id": "640808",
    "label": "Miau Baru",
    "value": "Miau Baru"
  },
  {
    "id": "6408092001",
    "district_id": "640809",
    "label": "Sepaso",
    "value": "Sepaso"
  },
  {
    "id": "6408092002",
    "district_id": "640809",
    "label": "Sekerat",
    "value": "Sekerat"
  },
  {
    "id": "6408092003",
    "district_id": "640809",
    "label": "Keraitan",
    "value": "Keraitan"
  },
  {
    "id": "6408092004",
    "district_id": "640809",
    "label": "Tepian Langsat",
    "value": "Tepian Langsat"
  },
  {
    "id": "6408092005",
    "district_id": "640809",
    "label": "Tebangan Lembak",
    "value": "Tebangan Lembak"
  },
  {
    "id": "6408092006",
    "district_id": "640809",
    "label": "Sepaso Timur",
    "value": "Sepaso Timur"
  },
  {
    "id": "6408092007",
    "district_id": "640809",
    "label": "Sepaso Selatan",
    "value": "Sepaso Selatan"
  },
  {
    "id": "6408092008",
    "district_id": "640809",
    "label": "Muara Bengalon",
    "value": "Muara Bengalon"
  },
  {
    "id": "6408092009",
    "district_id": "640809",
    "label": "Tepian Baru",
    "value": "Tepian Baru"
  },
  {
    "id": "6408092010",
    "district_id": "640809",
    "label": "Tepian Indah",
    "value": "Tepian Indah"
  },
  {
    "id": "6408092011",
    "district_id": "640809",
    "label": "Sepaso Barat",
    "value": "Sepaso Barat"
  },
  {
    "id": "6408102001",
    "district_id": "640810",
    "label": "Kaliorang",
    "value": "Kaliorang"
  },
  {
    "id": "6408102006",
    "district_id": "640810",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "6408102007",
    "district_id": "640810",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "6408102008",
    "district_id": "640810",
    "label": "Citra Manunggal Jaya",
    "value": "Citra Manunggal Jaya"
  },
  {
    "id": "6408102009",
    "district_id": "640810",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "6408102010",
    "district_id": "640810",
    "label": "Bumi Sejahtera",
    "value": "Bumi Sejahtera"
  },
  {
    "id": "6408102013",
    "district_id": "640810",
    "label": "Selangkau",
    "value": "Selangkau"
  },
  {
    "id": "6408112001",
    "district_id": "640811",
    "label": "Sandaran",
    "value": "Sandaran"
  },
  {
    "id": "6408112002",
    "district_id": "640811",
    "label": "Manubar",
    "value": "Manubar"
  },
  {
    "id": "6408112003",
    "district_id": "640811",
    "label": "Tadoan",
    "value": "Tadoan"
  },
  {
    "id": "6408112004",
    "district_id": "640811",
    "label": "Marukangan",
    "value": "Marukangan"
  },
  {
    "id": "6408112005",
    "district_id": "640811",
    "label": "Susuk Luar",
    "value": "Susuk Luar"
  },
  {
    "id": "6408112006",
    "district_id": "640811",
    "label": "Susuk Dalam",
    "value": "Susuk Dalam"
  },
  {
    "id": "6408112007",
    "district_id": "640811",
    "label": "Tanjung Mangkaliat",
    "value": "Tanjung Mangkaliat"
  },
  {
    "id": "6408112008",
    "district_id": "640811",
    "label": "Manubar Dalam",
    "value": "Manubar Dalam"
  },
  {
    "id": "6408112009",
    "district_id": "640811",
    "label": "Susuk Tengah",
    "value": "Susuk Tengah"
  },
  {
    "id": "6408121002",
    "district_id": "640812",
    "label": "Singa Geweh",
    "value": "Singa Geweh"
  },
  {
    "id": "6408122001",
    "district_id": "640812",
    "label": "Sangatta Selatan",
    "value": "Sangatta Selatan"
  },
  {
    "id": "6408122003",
    "district_id": "640812",
    "label": "Sangkima",
    "value": "Sangkima"
  },
  {
    "id": "6408122004",
    "district_id": "640812",
    "label": "Teluk Singkama",
    "value": "Teluk Singkama"
  },
  {
    "id": "6408132001",
    "district_id": "640813",
    "label": "Teluk Pandan",
    "value": "Teluk Pandan"
  },
  {
    "id": "6408132002",
    "district_id": "640813",
    "label": "Suka Rahmat",
    "value": "Suka Rahmat"
  },
  {
    "id": "6408132003",
    "district_id": "640813",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "6408132004",
    "district_id": "640813",
    "label": "Kandolo",
    "value": "Kandolo"
  },
  {
    "id": "6408132005",
    "district_id": "640813",
    "label": "Danau Redan",
    "value": "Danau Redan"
  },
  {
    "id": "6408132006",
    "district_id": "640813",
    "label": "Martadinata",
    "value": "Martadinata"
  },
  {
    "id": "6408142001",
    "district_id": "640814",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "6408142002",
    "district_id": "640814",
    "label": "Pulung Sari",
    "value": "Pulung Sari"
  },
  {
    "id": "6408142003",
    "district_id": "640814",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "6408142004",
    "district_id": "640814",
    "label": "Rantau Makmur",
    "value": "Rantau Makmur"
  },
  {
    "id": "6408142005",
    "district_id": "640814",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "6408142006",
    "district_id": "640814",
    "label": "Tanjung Labu",
    "value": "Tanjung Labu"
  },
  {
    "id": "6408142007",
    "district_id": "640814",
    "label": "Kebon Agung",
    "value": "Kebon Agung"
  },
  {
    "id": "6408142008",
    "district_id": "640814",
    "label": "Tepian Makmur",
    "value": "Tepian Makmur"
  },
  {
    "id": "6408142009",
    "district_id": "640814",
    "label": "Masalap Raya",
    "value": "Masalap Raya"
  },
  {
    "id": "6408152001",
    "district_id": "640815",
    "label": "Bumi Etam",
    "value": "Bumi Etam"
  },
  {
    "id": "6408152002",
    "district_id": "640815",
    "label": "Bumi Rapak",
    "value": "Bumi Rapak"
  },
  {
    "id": "6408152003",
    "district_id": "640815",
    "label": "Bumi Jaya",
    "value": "Bumi Jaya"
  },
  {
    "id": "6408152004",
    "district_id": "640815",
    "label": "Cipta Graha",
    "value": "Cipta Graha"
  },
  {
    "id": "6408152005",
    "district_id": "640815",
    "label": "Kadungan Jaya",
    "value": "Kadungan Jaya"
  },
  {
    "id": "6408152006",
    "district_id": "640815",
    "label": "Pengadan Baru",
    "value": "Pengadan Baru"
  },
  {
    "id": "6408152007",
    "district_id": "640815",
    "label": "Mata Air",
    "value": "Mata Air"
  },
  {
    "id": "6408152008",
    "district_id": "640815",
    "label": "Bukit Permata",
    "value": "Bukit Permata"
  },
  {
    "id": "6408162001",
    "district_id": "640816",
    "label": "Karangan Dalam",
    "value": "Karangan Dalam"
  },
  {
    "id": "6408162002",
    "district_id": "640816",
    "label": "Batu Lepoq",
    "value": "Batu Lepoq"
  },
  {
    "id": "6408162003",
    "district_id": "640816",
    "label": "Pengadan",
    "value": "Pengadan"
  },
  {
    "id": "6408162004",
    "district_id": "640816",
    "label": "Baay",
    "value": "Baay"
  },
  {
    "id": "6408162005",
    "district_id": "640816",
    "label": "Mukti Lestari",
    "value": "Mukti Lestari"
  },
  {
    "id": "6408162006",
    "district_id": "640816",
    "label": "Karangan Seberang",
    "value": "Karangan Seberang"
  },
  {
    "id": "6408162007",
    "district_id": "640816",
    "label": "Karangan Hilir",
    "value": "Karangan Hilir"
  },
  {
    "id": "6408172001",
    "district_id": "640817",
    "label": "Batu Timbau",
    "value": "Batu Timbau"
  },
  {
    "id": "6408172002",
    "district_id": "640817",
    "label": "Beno Harapan",
    "value": "Beno Harapan"
  },
  {
    "id": "6408172003",
    "district_id": "640817",
    "label": "Mugi Rahayu",
    "value": "Mugi Rahayu"
  },
  {
    "id": "6408172004",
    "district_id": "640817",
    "label": "Mawai Indah",
    "value": "Mawai Indah"
  },
  {
    "id": "6408172005",
    "district_id": "640817",
    "label": "Himba Lestari",
    "value": "Himba Lestari"
  },
  {
    "id": "6408172006",
    "district_id": "640817",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "6408172007",
    "district_id": "640817",
    "label": "Batu Timbau Ulu",
    "value": "Batu Timbau Ulu"
  },
  {
    "id": "6408182001",
    "district_id": "640818",
    "label": "Sika Makmur",
    "value": "Sika Makmur"
  },
  {
    "id": "6408182002",
    "district_id": "640818",
    "label": "Segoy Makmur",
    "value": "Segoy Makmur"
  },
  {
    "id": "6408182003",
    "district_id": "640818",
    "label": "Mukti Utama",
    "value": "Mukti Utama"
  },
  {
    "id": "6408182004",
    "district_id": "640818",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6408182005",
    "district_id": "640818",
    "label": "Melan",
    "value": "Melan"
  },
  {
    "id": "6408182006",
    "district_id": "640818",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "6408182007",
    "district_id": "640818",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "6409011001",
    "district_id": "640901",
    "label": "Tanjung Tengah",
    "value": "Tanjung Tengah"
  },
  {
    "id": "6409011002",
    "district_id": "640901",
    "label": "Salo Loang",
    "value": "Salo Loang"
  },
  {
    "id": "6409011003",
    "district_id": "640901",
    "label": "Petung",
    "value": "Petung"
  },
  {
    "id": "6409011004",
    "district_id": "640901",
    "label": "Lawe-lawe",
    "value": "Lawe-lawe"
  },
  {
    "id": "6409011005",
    "district_id": "640901",
    "label": "Pejala",
    "value": "Pejala"
  },
  {
    "id": "6409011006",
    "district_id": "640901",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6409011007",
    "district_id": "640901",
    "label": "Sesumpu",
    "value": "Sesumpu"
  },
  {
    "id": "6409011008",
    "district_id": "640901",
    "label": "Sungai Parit",
    "value": "Sungai Parit"
  },
  {
    "id": "6409011009",
    "district_id": "640901",
    "label": "Nipah-Nipah",
    "value": "Nipah-Nipah"
  },
  {
    "id": "6409011010",
    "district_id": "640901",
    "label": "Nenang",
    "value": "Nenang"
  },
  {
    "id": "6409011011",
    "district_id": "640901",
    "label": "Penajam",
    "value": "Penajam"
  },
  {
    "id": "6409011012",
    "district_id": "640901",
    "label": "Gunung Seteleng",
    "value": "Gunung Seteleng"
  },
  {
    "id": "6409011013",
    "district_id": "640901",
    "label": "Buluminung",
    "value": "Buluminung"
  },
  {
    "id": "6409011014",
    "district_id": "640901",
    "label": "Sotek",
    "value": "Sotek"
  },
  {
    "id": "6409011015",
    "district_id": "640901",
    "label": "Sepan",
    "value": "Sepan"
  },
  {
    "id": "6409011016",
    "district_id": "640901",
    "label": "Riko",
    "value": "Riko"
  },
  {
    "id": "6409011017",
    "district_id": "640901",
    "label": "Gersik",
    "value": "Gersik"
  },
  {
    "id": "6409011018",
    "district_id": "640901",
    "label": "Jenebora",
    "value": "Jenebora"
  },
  {
    "id": "6409011019",
    "district_id": "640901",
    "label": "Pantai Lango",
    "value": "Pantai Lango"
  },
  {
    "id": "6409012020",
    "district_id": "640901",
    "label": "Giri Mukti",
    "value": "Giri Mukti"
  },
  {
    "id": "6409012021",
    "district_id": "640901",
    "label": "Bukit Subur",
    "value": "Bukit Subur"
  },
  {
    "id": "6409012022",
    "district_id": "640901",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "6409012023",
    "district_id": "640901",
    "label": "Giri Purwa",
    "value": "Giri Purwa"
  },
  {
    "id": "6409021003",
    "district_id": "640902",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "6409022001",
    "district_id": "640902",
    "label": "Api-api",
    "value": "Api-api"
  },
  {
    "id": "6409022002",
    "district_id": "640902",
    "label": "Sesulu",
    "value": "Sesulu"
  },
  {
    "id": "6409022004",
    "district_id": "640902",
    "label": "Bangun Mulya",
    "value": "Bangun Mulya"
  },
  {
    "id": "6409032001",
    "district_id": "640903",
    "label": "Babulu Darat",
    "value": "Babulu Darat"
  },
  {
    "id": "6409032002",
    "district_id": "640903",
    "label": "Labangka",
    "value": "Labangka"
  },
  {
    "id": "6409032003",
    "district_id": "640903",
    "label": "Babulu Laut",
    "value": "Babulu Laut"
  },
  {
    "id": "6409032004",
    "district_id": "640903",
    "label": "Gunung Intan",
    "value": "Gunung Intan"
  },
  {
    "id": "6409032005",
    "district_id": "640903",
    "label": "Gunung Makmur",
    "value": "Gunung Makmur"
  },
  {
    "id": "6409032006",
    "district_id": "640903",
    "label": "Sebakung Jaya",
    "value": "Sebakung Jaya"
  },
  {
    "id": "6409032007",
    "district_id": "640903",
    "label": "Rawa Mulia",
    "value": "Rawa Mulia"
  },
  {
    "id": "6409032008",
    "district_id": "640903",
    "label": "Sri Raharja",
    "value": "Sri Raharja"
  },
  {
    "id": "6409032009",
    "district_id": "640903",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "6409032010",
    "district_id": "640903",
    "label": "Rintik",
    "value": "Rintik"
  },
  {
    "id": "6409032011",
    "district_id": "640903",
    "label": "Gunung Mulia",
    "value": "Gunung Mulia"
  },
  {
    "id": "6409032012",
    "district_id": "640903",
    "label": "Labangka Barat",
    "value": "Labangka Barat"
  },
  {
    "id": "6409041005",
    "district_id": "640904",
    "label": "Sepaku",
    "value": "Sepaku"
  },
  {
    "id": "6409041006",
    "district_id": "640904",
    "label": "Pemaluan",
    "value": "Pemaluan"
  },
  {
    "id": "6409041007",
    "district_id": "640904",
    "label": "Maridan",
    "value": "Maridan"
  },
  {
    "id": "6409041008",
    "district_id": "640904",
    "label": "Mentawir",
    "value": "Mentawir"
  },
  {
    "id": "6409042001",
    "district_id": "640904",
    "label": "Tengin Baru",
    "value": "Tengin Baru"
  },
  {
    "id": "6409042002",
    "district_id": "640904",
    "label": "Bukit Raya",
    "value": "Bukit Raya"
  },
  {
    "id": "6409042003",
    "district_id": "640904",
    "label": "Suka Raja",
    "value": "Suka Raja"
  },
  {
    "id": "6409042004",
    "district_id": "640904",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "6409042009",
    "district_id": "640904",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "6409042010",
    "district_id": "640904",
    "label": "Semoi Dua",
    "value": "Semoi Dua"
  },
  {
    "id": "6409042011",
    "district_id": "640904",
    "label": "Suko Mulyo",
    "value": "Suko Mulyo"
  },
  {
    "id": "6409042012",
    "district_id": "640904",
    "label": "Wono Sari",
    "value": "Wono Sari"
  },
  {
    "id": "6409042013",
    "district_id": "640904",
    "label": "Karang Jinawi",
    "value": "Karang Jinawi"
  },
  {
    "id": "6409042014",
    "district_id": "640904",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "6409042015",
    "district_id": "640904",
    "label": "Telemow",
    "value": "Telemow"
  },
  {
    "id": "6411012001",
    "district_id": "641101",
    "label": "Long Hurai",
    "value": "Long Hurai"
  },
  {
    "id": "6411012002",
    "district_id": "641101",
    "label": "Long Melaham",
    "value": "Long Melaham"
  },
  {
    "id": "6411012003",
    "district_id": "641101",
    "label": "Memahak Besar",
    "value": "Memahak Besar"
  },
  {
    "id": "6411012004",
    "district_id": "641101",
    "label": "Memahak Ulu",
    "value": "Memahak Ulu"
  },
  {
    "id": "6411012005",
    "district_id": "641101",
    "label": "Batu Majang",
    "value": "Batu Majang"
  },
  {
    "id": "6411012006",
    "district_id": "641101",
    "label": "Ujoh Bilang",
    "value": "Ujoh Bilang"
  },
  {
    "id": "6411012007",
    "district_id": "641101",
    "label": "Long Bagun Ilir",
    "value": "Long Bagun Ilir"
  },
  {
    "id": "6411012008",
    "district_id": "641101",
    "label": "Long Bagun Ulu",
    "value": "Long Bagun Ulu"
  },
  {
    "id": "6411012009",
    "district_id": "641101",
    "label": "Batoq Kelo",
    "value": "Batoq Kelo"
  },
  {
    "id": "6411012010",
    "district_id": "641101",
    "label": "Long Merah",
    "value": "Long Merah"
  },
  {
    "id": "6411012011",
    "district_id": "641101",
    "label": "Rukun Damai",
    "value": "Rukun Damai"
  },
  {
    "id": "6411022001",
    "district_id": "641102",
    "label": "Long Hubung",
    "value": "Long Hubung"
  },
  {
    "id": "6411022002",
    "district_id": "641102",
    "label": "Memahak Teboq",
    "value": "Memahak Teboq"
  },
  {
    "id": "6411022003",
    "district_id": "641102",
    "label": "Lutan",
    "value": "Lutan"
  },
  {
    "id": "6411022004",
    "district_id": "641102",
    "label": "Matalibaq",
    "value": "Matalibaq"
  },
  {
    "id": "6411022005",
    "district_id": "641102",
    "label": "Datah Bilang Ilir",
    "value": "Datah Bilang Ilir"
  },
  {
    "id": "6411022006",
    "district_id": "641102",
    "label": "Datah Bilang Ulu",
    "value": "Datah Bilang Ulu"
  },
  {
    "id": "6411022007",
    "district_id": "641102",
    "label": "Tri Pariq Makmur",
    "value": "Tri Pariq Makmur"
  },
  {
    "id": "6411022008",
    "district_id": "641102",
    "label": "Wana Pariq",
    "value": "Wana Pariq"
  },
  {
    "id": "6411022009",
    "district_id": "641102",
    "label": "Datah Bilang Baru",
    "value": "Datah Bilang Baru"
  },
  {
    "id": "6411022010",
    "district_id": "641102",
    "label": "Sirau",
    "value": "Sirau"
  },
  {
    "id": "6411022011",
    "district_id": "641102",
    "label": "Long Hubung Ulu",
    "value": "Long Hubung Ulu"
  },
  {
    "id": "6411032001",
    "district_id": "641103",
    "label": "Laham",
    "value": "Laham"
  },
  {
    "id": "6411032002",
    "district_id": "641103",
    "label": "Long Gelawang",
    "value": "Long Gelawang"
  },
  {
    "id": "6411032003",
    "district_id": "641103",
    "label": "Muara Ratah",
    "value": "Muara Ratah"
  },
  {
    "id": "6411032004",
    "district_id": "641103",
    "label": "Danum Paroy",
    "value": "Danum Paroy"
  },
  {
    "id": "6411032005",
    "district_id": "641103",
    "label": "Nyaribungan",
    "value": "Nyaribungan"
  },
  {
    "id": "6411042001",
    "district_id": "641104",
    "label": "Long Penaneh I",
    "value": "Long Penaneh I"
  },
  {
    "id": "6411042002",
    "district_id": "641104",
    "label": "Long Kerioq",
    "value": "Long Kerioq"
  },
  {
    "id": "6411042003",
    "district_id": "641104",
    "label": "Long Penaneh II",
    "value": "Long Penaneh II"
  },
  {
    "id": "6411042004",
    "district_id": "641104",
    "label": "Tiong Ohang",
    "value": "Tiong Ohang"
  },
  {
    "id": "6411042005",
    "district_id": "641104",
    "label": "Long Penaneh III",
    "value": "Long Penaneh III"
  },
  {
    "id": "6411042006",
    "district_id": "641104",
    "label": "Tiong Bu'u",
    "value": "Tiong Bu'u"
  },
  {
    "id": "6411042007",
    "district_id": "641104",
    "label": "Naha Buan",
    "value": "Naha Buan"
  },
  {
    "id": "6411042008",
    "district_id": "641104",
    "label": "Naha Tifab",
    "value": "Naha Tifab"
  },
  {
    "id": "6411042009",
    "district_id": "641104",
    "label": "Naha Silat",
    "value": "Naha Silat"
  },
  {
    "id": "6411042010",
    "district_id": "641104",
    "label": "Long Apari",
    "value": "Long Apari"
  },
  {
    "id": "6411052001",
    "district_id": "641105",
    "label": "Delang Kerohong",
    "value": "Delang Kerohong"
  },
  {
    "id": "6411052002",
    "district_id": "641105",
    "label": "Long Pakaq",
    "value": "Long Pakaq"
  },
  {
    "id": "6411052003",
    "district_id": "641105",
    "label": "Long Lunuk",
    "value": "Long Lunuk"
  },
  {
    "id": "6411052004",
    "district_id": "641105",
    "label": "Long Isun",
    "value": "Long Isun"
  },
  {
    "id": "6411052005",
    "district_id": "641105",
    "label": "Naha Aru",
    "value": "Naha Aru"
  },
  {
    "id": "6411052006",
    "district_id": "641105",
    "label": "Datah Naha",
    "value": "Datah Naha"
  },
  {
    "id": "6411052007",
    "district_id": "641105",
    "label": "Lirung Ubing",
    "value": "Lirung Ubing"
  },
  {
    "id": "6411052008",
    "district_id": "641105",
    "label": "Long Pahangai I",
    "value": "Long Pahangai I"
  },
  {
    "id": "6411052009",
    "district_id": "641105",
    "label": "Long Pahangai II",
    "value": "Long Pahangai II"
  },
  {
    "id": "6411052010",
    "district_id": "641105",
    "label": "Long Tuyoq",
    "value": "Long Tuyoq"
  },
  {
    "id": "6411052011",
    "district_id": "641105",
    "label": "Liu Mulang",
    "value": "Liu Mulang"
  },
  {
    "id": "6411052012",
    "district_id": "641105",
    "label": "Long Pakaq Baru",
    "value": "Long Pakaq Baru"
  },
  {
    "id": "6411052013",
    "district_id": "641105",
    "label": "Long Lunuk Baru",
    "value": "Long Lunuk Baru"
  },
  {
    "id": "6471011001",
    "district_id": "647101",
    "label": "Manggar",
    "value": "Manggar"
  },
  {
    "id": "6471011002",
    "district_id": "647101",
    "label": "Lamaru",
    "value": "Lamaru"
  },
  {
    "id": "6471011003",
    "district_id": "647101",
    "label": "Teritip",
    "value": "Teritip"
  },
  {
    "id": "6471011004",
    "district_id": "647101",
    "label": "Manggar Baru",
    "value": "Manggar Baru"
  },
  {
    "id": "6471021001",
    "district_id": "647102",
    "label": "Baru Ilir",
    "value": "Baru Ilir"
  },
  {
    "id": "6471021002",
    "district_id": "647102",
    "label": "Baru Tengah",
    "value": "Baru Tengah"
  },
  {
    "id": "6471021003",
    "district_id": "647102",
    "label": "Baru Ulu",
    "value": "Baru Ulu"
  },
  {
    "id": "6471021004",
    "district_id": "647102",
    "label": "Kariangau",
    "value": "Kariangau"
  },
  {
    "id": "6471021005",
    "district_id": "647102",
    "label": "Margo Mulyo",
    "value": "Margo Mulyo"
  },
  {
    "id": "6471021006",
    "district_id": "647102",
    "label": "Marga Sari",
    "value": "Marga Sari"
  },
  {
    "id": "6471031001",
    "district_id": "647103",
    "label": "Batu Ampar",
    "value": "Batu Ampar"
  },
  {
    "id": "6471031002",
    "district_id": "647103",
    "label": "Gunungsamarinda",
    "value": "Gunungsamarinda"
  },
  {
    "id": "6471031003",
    "district_id": "647103",
    "label": "Karang Joang",
    "value": "Karang Joang"
  },
  {
    "id": "6471031004",
    "district_id": "647103",
    "label": "Muararapak",
    "value": "Muararapak"
  },
  {
    "id": "6471031005",
    "district_id": "647103",
    "label": "Gunungsamarinda Baru",
    "value": "Gunungsamarinda Baru"
  },
  {
    "id": "6471031006",
    "district_id": "647103",
    "label": "Graha Indah",
    "value": "Graha Indah"
  },
  {
    "id": "6471041001",
    "district_id": "647104",
    "label": "Gunungsari Ulu",
    "value": "Gunungsari Ulu"
  },
  {
    "id": "6471041002",
    "district_id": "647104",
    "label": "Gunungsari Ilir",
    "value": "Gunungsari Ilir"
  },
  {
    "id": "6471041003",
    "district_id": "647104",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "6471041004",
    "district_id": "647104",
    "label": "Karang Jati",
    "value": "Karang Jati"
  },
  {
    "id": "6471041005",
    "district_id": "647104",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "6471041006",
    "district_id": "647104",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "6471051002",
    "district_id": "647105",
    "label": "Sepinggan",
    "value": "Sepinggan"
  },
  {
    "id": "6471051006",
    "district_id": "647105",
    "label": "Gunungbahagia",
    "value": "Gunungbahagia"
  },
  {
    "id": "6471051008",
    "district_id": "647105",
    "label": "Sepinggan Baru",
    "value": "Sepinggan Baru"
  },
  {
    "id": "6471051009",
    "district_id": "647105",
    "label": "Sepinggan Raya",
    "value": "Sepinggan Raya"
  },
  {
    "id": "6471051010",
    "district_id": "647105",
    "label": "Sungainangka",
    "value": "Sungainangka"
  },
  {
    "id": "6471051011",
    "district_id": "647105",
    "label": "Damai Baru",
    "value": "Damai Baru"
  },
  {
    "id": "6471051012",
    "district_id": "647105",
    "label": "Damai Bahagia",
    "value": "Damai Bahagia"
  },
  {
    "id": "6471061001",
    "district_id": "647106",
    "label": "Prapatan",
    "value": "Prapatan"
  },
  {
    "id": "6471061002",
    "district_id": "647106",
    "label": "Telaga Sari",
    "value": "Telaga Sari"
  },
  {
    "id": "6471061003",
    "district_id": "647106",
    "label": "Klandasan Ulu",
    "value": "Klandasan Ulu"
  },
  {
    "id": "6471061004",
    "district_id": "647106",
    "label": "Klandasan Ilir",
    "value": "Klandasan Ilir"
  },
  {
    "id": "6471061005",
    "district_id": "647106",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "6472011001",
    "district_id": "647201",
    "label": "Rawa Makmur",
    "value": "Rawa Makmur"
  },
  {
    "id": "6472011002",
    "district_id": "647201",
    "label": "Handil Bakti",
    "value": "Handil Bakti"
  },
  {
    "id": "6472011003",
    "district_id": "647201",
    "label": "Bukuan",
    "value": "Bukuan"
  },
  {
    "id": "6472011004",
    "district_id": "647201",
    "label": "Simpang Pasir",
    "value": "Simpang Pasir"
  },
  {
    "id": "6472011005",
    "district_id": "647201",
    "label": "Bantuas",
    "value": "Bantuas"
  },
  {
    "id": "6472021001",
    "district_id": "647202",
    "label": "Sungai Keledang",
    "value": "Sungai Keledang"
  },
  {
    "id": "6472021002",
    "district_id": "647202",
    "label": "Baqa",
    "value": "Baqa"
  },
  {
    "id": "6472021003",
    "district_id": "647202",
    "label": "Mesjid",
    "value": "Mesjid"
  },
  {
    "id": "6472021009",
    "district_id": "647202",
    "label": "Mangkupalas",
    "value": "Mangkupalas"
  },
  {
    "id": "6472021010",
    "district_id": "647202",
    "label": "Tenun Samarinda",
    "value": "Tenun Samarinda"
  },
  {
    "id": "6472021011",
    "district_id": "647202",
    "label": "Gunung Panjang",
    "value": "Gunung Panjang"
  },
  {
    "id": "6472031001",
    "district_id": "647203",
    "label": "Teluk Lerong Ilir",
    "value": "Teluk Lerong Ilir"
  },
  {
    "id": "6472031002",
    "district_id": "647203",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "6472031004",
    "district_id": "647203",
    "label": "Air Putih",
    "value": "Air Putih"
  },
  {
    "id": "6472031005",
    "district_id": "647203",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "6472031006",
    "district_id": "647203",
    "label": "Air Hitam",
    "value": "Air Hitam"
  },
  {
    "id": "6472031007",
    "district_id": "647203",
    "label": "Dadi Mulya",
    "value": "Dadi Mulya"
  },
  {
    "id": "6472031008",
    "district_id": "647203",
    "label": "Gunung Kelua",
    "value": "Gunung Kelua"
  },
  {
    "id": "6472031009",
    "district_id": "647203",
    "label": "Bukit Pinang",
    "value": "Bukit Pinang"
  },
  {
    "id": "6472041001",
    "district_id": "647204",
    "label": "Selili",
    "value": "Selili"
  },
  {
    "id": "6472041002",
    "district_id": "647204",
    "label": "Sungai Dama",
    "value": "Sungai Dama"
  },
  {
    "id": "6472041003",
    "district_id": "647204",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "6472041013",
    "district_id": "647204",
    "label": "Sidodamai",
    "value": "Sidodamai"
  },
  {
    "id": "6472041014",
    "district_id": "647204",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "6472051002",
    "district_id": "647205",
    "label": "Sempaja Selatan",
    "value": "Sempaja Selatan"
  },
  {
    "id": "6472051003",
    "district_id": "647205",
    "label": "Lempake",
    "value": "Lempake"
  },
  {
    "id": "6472051004",
    "district_id": "647205",
    "label": "Sungai Siring",
    "value": "Sungai Siring"
  },
  {
    "id": "6472051010",
    "district_id": "647205",
    "label": "Sempaja Utara",
    "value": "Sempaja Utara"
  },
  {
    "id": "6472051011",
    "district_id": "647205",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "6472051012",
    "district_id": "647205",
    "label": "Sempaja Barat",
    "value": "Sempaja Barat"
  },
  {
    "id": "6472051013",
    "district_id": "647205",
    "label": "Sempaja Timur",
    "value": "Sempaja Timur"
  },
  {
    "id": "6472051014",
    "district_id": "647205",
    "label": "Budaya Pampang",
    "value": "Budaya Pampang"
  },
  {
    "id": "6472061001",
    "district_id": "647206",
    "label": "Loa Bakung",
    "value": "Loa Bakung"
  },
  {
    "id": "6472061002",
    "district_id": "647206",
    "label": "Loa Buah",
    "value": "Loa Buah"
  },
  {
    "id": "6472061003",
    "district_id": "647206",
    "label": "Karang Asam Ulu",
    "value": "Karang Asam Ulu"
  },
  {
    "id": "6472061004",
    "district_id": "647206",
    "label": "Lok Bahu",
    "value": "Lok Bahu"
  },
  {
    "id": "6472061005",
    "district_id": "647206",
    "label": "Teluk Lerong Ulu",
    "value": "Teluk Lerong Ulu"
  },
  {
    "id": "6472061006",
    "district_id": "647206",
    "label": "Karang Asam Ilir",
    "value": "Karang Asam Ilir"
  },
  {
    "id": "6472061007",
    "district_id": "647206",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "6472071001",
    "district_id": "647207",
    "label": "Sungai Kapih",
    "value": "Sungai Kapih"
  },
  {
    "id": "6472071002",
    "district_id": "647207",
    "label": "Sambutan",
    "value": "Sambutan"
  },
  {
    "id": "6472071003",
    "district_id": "647207",
    "label": "Makroman",
    "value": "Makroman"
  },
  {
    "id": "6472071004",
    "district_id": "647207",
    "label": "Sindang Sari",
    "value": "Sindang Sari"
  },
  {
    "id": "6472071005",
    "district_id": "647207",
    "label": "Pulau Atas",
    "value": "Pulau Atas"
  },
  {
    "id": "6472081001",
    "district_id": "647208",
    "label": "Temindung Permai",
    "value": "Temindung Permai"
  },
  {
    "id": "6472081002",
    "district_id": "647208",
    "label": "Sungai Pinang Dalam",
    "value": "Sungai Pinang Dalam"
  },
  {
    "id": "6472081003",
    "district_id": "647208",
    "label": "Gunung Lingai",
    "value": "Gunung Lingai"
  },
  {
    "id": "6472081004",
    "district_id": "647208",
    "label": "Mugirejo",
    "value": "Mugirejo"
  },
  {
    "id": "6472081005",
    "district_id": "647208",
    "label": "Bandara",
    "value": "Bandara"
  },
  {
    "id": "6472091001",
    "district_id": "647209",
    "label": "Karang Mumus",
    "value": "Karang Mumus"
  },
  {
    "id": "6472091002",
    "district_id": "647209",
    "label": "Pelabuhan",
    "value": "Pelabuhan"
  },
  {
    "id": "6472091003",
    "district_id": "647209",
    "label": "Pasar Pagi",
    "value": "Pasar Pagi"
  },
  {
    "id": "6472091004",
    "district_id": "647209",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "6472091005",
    "district_id": "647209",
    "label": "Sungai Pinang Luar",
    "value": "Sungai Pinang Luar"
  },
  {
    "id": "6472101001",
    "district_id": "647210",
    "label": "Simpang Tiga",
    "value": "Simpang Tiga"
  },
  {
    "id": "6472101002",
    "district_id": "647210",
    "label": "Tani Aman",
    "value": "Tani Aman"
  },
  {
    "id": "6472101003",
    "district_id": "647210",
    "label": "Sengkotek",
    "value": "Sengkotek"
  },
  {
    "id": "6472101004",
    "district_id": "647210",
    "label": "Harapan Baru",
    "value": "Harapan Baru"
  },
  {
    "id": "6472101005",
    "district_id": "647210",
    "label": "Rapak Dalam",
    "value": "Rapak Dalam"
  },
  {
    "id": "6474011001",
    "district_id": "647401",
    "label": "Bontang Kuala",
    "value": "Bontang Kuala"
  },
  {
    "id": "6474011002",
    "district_id": "647401",
    "label": "Bontang Baru",
    "value": "Bontang Baru"
  },
  {
    "id": "6474011003",
    "district_id": "647401",
    "label": "Lok Tuan",
    "value": "Lok Tuan"
  },
  {
    "id": "6474011004",
    "district_id": "647401",
    "label": "Guntung",
    "value": "Guntung"
  },
  {
    "id": "6474011005",
    "district_id": "647401",
    "label": "Gunung Elai",
    "value": "Gunung Elai"
  },
  {
    "id": "6474011006",
    "district_id": "647401",
    "label": "Api-api",
    "value": "Api-api"
  },
  {
    "id": "6474021001",
    "district_id": "647402",
    "label": "Tanjung Laut",
    "value": "Tanjung Laut"
  },
  {
    "id": "6474021002",
    "district_id": "647402",
    "label": "Berbas Tengah",
    "value": "Berbas Tengah"
  },
  {
    "id": "6474021003",
    "district_id": "647402",
    "label": "Berbas Pantai",
    "value": "Berbas Pantai"
  },
  {
    "id": "6474021004",
    "district_id": "647402",
    "label": "Satimpo",
    "value": "Satimpo"
  },
  {
    "id": "6474021005",
    "district_id": "647402",
    "label": "Bontang Lestari",
    "value": "Bontang Lestari"
  },
  {
    "id": "6474021006",
    "district_id": "647402",
    "label": "Tanjung Laut Indah",
    "value": "Tanjung Laut Indah"
  },
  {
    "id": "6474031001",
    "district_id": "647403",
    "label": "Belimbing",
    "value": "Belimbing"
  },
  {
    "id": "6474031002",
    "district_id": "647403",
    "label": "Gunung Telihan",
    "value": "Gunung Telihan"
  },
  {
    "id": "6474031003",
    "district_id": "647403",
    "label": "Kanaan",
    "value": "Kanaan"
  },
  {
    "id": "6501011001",
    "district_id": "650101",
    "label": "Tanjung Palas Hulu",
    "value": "Tanjung Palas Hulu"
  },
  {
    "id": "6501011002",
    "district_id": "650101",
    "label": "Tanjung Palas Tengah",
    "value": "Tanjung Palas Tengah"
  },
  {
    "id": "6501011003",
    "district_id": "650101",
    "label": "Tanjung Palas Hilir",
    "value": "Tanjung Palas Hilir"
  },
  {
    "id": "6501011004",
    "district_id": "650101",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "6501012005",
    "district_id": "650101",
    "label": "Gunung Putih",
    "value": "Gunung Putih"
  },
  {
    "id": "6501012006",
    "district_id": "650101",
    "label": "Pejalin",
    "value": "Pejalin"
  },
  {
    "id": "6501012007",
    "district_id": "650101",
    "label": "Antutan",
    "value": "Antutan"
  },
  {
    "id": "6501012008",
    "district_id": "650101",
    "label": "Teras Baru",
    "value": "Teras Baru"
  },
  {
    "id": "6501012009",
    "district_id": "650101",
    "label": "Teras Nawang",
    "value": "Teras Nawang"
  },
  {
    "id": "6501022001",
    "district_id": "650102",
    "label": "Long Beluah",
    "value": "Long Beluah"
  },
  {
    "id": "6501022002",
    "district_id": "650102",
    "label": "Long Sam",
    "value": "Long Sam"
  },
  {
    "id": "6501022003",
    "district_id": "650102",
    "label": "Mara I",
    "value": "Mara I"
  },
  {
    "id": "6501022004",
    "district_id": "650102",
    "label": "Mara Hilir",
    "value": "Mara Hilir"
  },
  {
    "id": "6501022005",
    "district_id": "650102",
    "label": "Long Pari",
    "value": "Long Pari"
  },
  {
    "id": "6501032001",
    "district_id": "650103",
    "label": "Karang Agung",
    "value": "Karang Agung"
  },
  {
    "id": "6501032002",
    "district_id": "650103",
    "label": "Pimping",
    "value": "Pimping"
  },
  {
    "id": "6501032003",
    "district_id": "650103",
    "label": "Panca Agung",
    "value": "Panca Agung"
  },
  {
    "id": "6501032004",
    "district_id": "650103",
    "label": "Ruhui Rahayu",
    "value": "Ruhui Rahayu"
  },
  {
    "id": "6501032005",
    "district_id": "650103",
    "label": "Ardi Mulyo",
    "value": "Ardi Mulyo"
  },
  {
    "id": "6501032006",
    "district_id": "650103",
    "label": "Kelubir",
    "value": "Kelubir"
  },
  {
    "id": "6501042001",
    "district_id": "650104",
    "label": "Tanah Kuning",
    "value": "Tanah Kuning"
  },
  {
    "id": "6501042002",
    "district_id": "650104",
    "label": "Mangkupadi",
    "value": "Mangkupadi"
  },
  {
    "id": "6501042003",
    "district_id": "650104",
    "label": "Sajau",
    "value": "Sajau"
  },
  {
    "id": "6501042004",
    "district_id": "650104",
    "label": "Wonomulyo",
    "value": "Wonomulyo"
  },
  {
    "id": "6501042005",
    "district_id": "650104",
    "label": "Tanjung Agung",
    "value": "Tanjung Agung"
  },
  {
    "id": "6501042006",
    "district_id": "650104",
    "label": "Binai",
    "value": "Binai"
  },
  {
    "id": "6501042007",
    "district_id": "650104",
    "label": "Sajau Hilir",
    "value": "Sajau Hilir"
  },
  {
    "id": "6501042008",
    "district_id": "650104",
    "label": "Pura Sajau",
    "value": "Pura Sajau"
  },
  {
    "id": "6501051001",
    "district_id": "650105",
    "label": "Tanjung Selor Hulu",
    "value": "Tanjung Selor Hulu"
  },
  {
    "id": "6501051002",
    "district_id": "650105",
    "label": "Tanjung Selor Hilir",
    "value": "Tanjung Selor Hilir"
  },
  {
    "id": "6501051008",
    "district_id": "650105",
    "label": "Tanjung Selor Timur",
    "value": "Tanjung Selor Timur"
  },
  {
    "id": "6501052003",
    "district_id": "650105",
    "label": "Jelarai Selor",
    "value": "Jelarai Selor"
  },
  {
    "id": "6501052004",
    "district_id": "650105",
    "label": "Gunung Seriang",
    "value": "Gunung Seriang"
  },
  {
    "id": "6501052005",
    "district_id": "650105",
    "label": "Bumi Rahayu",
    "value": "Bumi Rahayu"
  },
  {
    "id": "6501052006",
    "district_id": "650105",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "6501052007",
    "district_id": "650105",
    "label": "Apung",
    "value": "Apung"
  },
  {
    "id": "6501052009",
    "district_id": "650105",
    "label": "Tengkapak",
    "value": "Tengkapak"
  },
  {
    "id": "6501062001",
    "district_id": "650106",
    "label": "Salimbatu",
    "value": "Salimbatu"
  },
  {
    "id": "6501062002",
    "district_id": "650106",
    "label": "Silva Rahayu",
    "value": "Silva Rahayu"
  },
  {
    "id": "6501062003",
    "district_id": "650106",
    "label": "Tanjung Buka",
    "value": "Tanjung Buka"
  },
  {
    "id": "6501072001",
    "district_id": "650107",
    "label": "Lepak Aru",
    "value": "Lepak Aru"
  },
  {
    "id": "6501072002",
    "district_id": "650107",
    "label": "Long Lasan",
    "value": "Long Lasan"
  },
  {
    "id": "6501072003",
    "district_id": "650107",
    "label": "Long Peso",
    "value": "Long Peso"
  },
  {
    "id": "6501072004",
    "district_id": "650107",
    "label": "Long Bia",
    "value": "Long Bia"
  },
  {
    "id": "6501072005",
    "district_id": "650107",
    "label": "Muara Pangean",
    "value": "Muara Pangean"
  },
  {
    "id": "6501072006",
    "district_id": "650107",
    "label": "Long Lian",
    "value": "Long Lian"
  },
  {
    "id": "6501072007",
    "district_id": "650107",
    "label": "Long Pelaah",
    "value": "Long Pelaah"
  },
  {
    "id": "6501072008",
    "district_id": "650107",
    "label": "Long Lejuh",
    "value": "Long Lejuh"
  },
  {
    "id": "6501072009",
    "district_id": "650107",
    "label": "Long Pelban",
    "value": "Long Pelban"
  },
  {
    "id": "6501072010",
    "district_id": "650107",
    "label": "Long Buang",
    "value": "Long Buang"
  },
  {
    "id": "6501082001",
    "district_id": "650108",
    "label": "Long Tungu",
    "value": "Long Tungu"
  },
  {
    "id": "6501082002",
    "district_id": "650108",
    "label": "Long Bang",
    "value": "Long Bang"
  },
  {
    "id": "6501082003",
    "district_id": "650108",
    "label": "Long Telenjau",
    "value": "Long Telenjau"
  },
  {
    "id": "6501082004",
    "district_id": "650108",
    "label": "Naha Aya",
    "value": "Naha Aya"
  },
  {
    "id": "6501082005",
    "district_id": "650108",
    "label": "Long Lembu",
    "value": "Long Lembu"
  },
  {
    "id": "6501082006",
    "district_id": "650108",
    "label": "Long Bang Hulu",
    "value": "Long Bang Hulu"
  },
  {
    "id": "6501092001",
    "district_id": "650109",
    "label": "Sekatak Buji",
    "value": "Sekatak Buji"
  },
  {
    "id": "6501092002",
    "district_id": "650109",
    "label": "Paru Abang",
    "value": "Paru Abang"
  },
  {
    "id": "6501092003",
    "district_id": "650109",
    "label": "Bunau",
    "value": "Bunau"
  },
  {
    "id": "6501092004",
    "district_id": "650109",
    "label": "Ujang",
    "value": "Ujang"
  },
  {
    "id": "6501092005",
    "district_id": "650109",
    "label": "Tenggiling",
    "value": "Tenggiling"
  },
  {
    "id": "6501092006",
    "district_id": "650109",
    "label": "Kelembunan",
    "value": "Kelembunan"
  },
  {
    "id": "6501092007",
    "district_id": "650109",
    "label": "Turung",
    "value": "Turung"
  },
  {
    "id": "6501092008",
    "district_id": "650109",
    "label": "Terindak",
    "value": "Terindak"
  },
  {
    "id": "6501092009",
    "district_id": "650109",
    "label": "Kelising",
    "value": "Kelising"
  },
  {
    "id": "6501092010",
    "district_id": "650109",
    "label": "Ambalat",
    "value": "Ambalat"
  },
  {
    "id": "6501092011",
    "district_id": "650109",
    "label": "Keriting",
    "value": "Keriting"
  },
  {
    "id": "6501092012",
    "district_id": "650109",
    "label": "Kendari",
    "value": "Kendari"
  },
  {
    "id": "6501092013",
    "district_id": "650109",
    "label": "Bambang",
    "value": "Bambang"
  },
  {
    "id": "6501092014",
    "district_id": "650109",
    "label": "Maritam",
    "value": "Maritam"
  },
  {
    "id": "6501092015",
    "district_id": "650109",
    "label": "Pentian",
    "value": "Pentian"
  },
  {
    "id": "6501092016",
    "district_id": "650109",
    "label": "Punan Dulau",
    "value": "Punan Dulau"
  },
  {
    "id": "6501092017",
    "district_id": "650109",
    "label": "Kelincauan",
    "value": "Kelincauan"
  },
  {
    "id": "6501092018",
    "district_id": "650109",
    "label": "Bekiliu",
    "value": "Bekiliu"
  },
  {
    "id": "6501092019",
    "district_id": "650109",
    "label": "Sekatak Bengara",
    "value": "Sekatak Bengara"
  },
  {
    "id": "6501092020",
    "district_id": "650109",
    "label": "Pungit",
    "value": "Pungit"
  },
  {
    "id": "6501092021",
    "district_id": "650109",
    "label": "Anjar Arip",
    "value": "Anjar Arip"
  },
  {
    "id": "6501092022",
    "district_id": "650109",
    "label": "Liagu",
    "value": "Liagu"
  },
  {
    "id": "6501102001",
    "district_id": "650110",
    "label": "Bunyu Barat",
    "value": "Bunyu Barat"
  },
  {
    "id": "6501102002",
    "district_id": "650110",
    "label": "Bunyu Selatan",
    "value": "Bunyu Selatan"
  },
  {
    "id": "6501102003",
    "district_id": "650110",
    "label": "Bunyu Timur",
    "value": "Bunyu Timur"
  },
  {
    "id": "6502012001",
    "district_id": "650201",
    "label": "Long Gafid",
    "value": "Long Gafid"
  },
  {
    "id": "6502012002",
    "district_id": "650201",
    "label": "Long Bisai",
    "value": "Long Bisai"
  },
  {
    "id": "6502012003",
    "district_id": "650201",
    "label": "Temalang",
    "value": "Temalang"
  },
  {
    "id": "6502012004",
    "district_id": "650201",
    "label": "Long Liku",
    "value": "Long Liku"
  },
  {
    "id": "6502012005",
    "district_id": "650201",
    "label": "Pulau Sapi",
    "value": "Pulau Sapi"
  },
  {
    "id": "6502012006",
    "district_id": "650201",
    "label": "Paking",
    "value": "Paking"
  },
  {
    "id": "6502012007",
    "district_id": "650201",
    "label": "Lidung Kemenci",
    "value": "Lidung Kemenci"
  },
  {
    "id": "6502012008",
    "district_id": "650201",
    "label": "Mentarang Baru",
    "value": "Mentarang Baru"
  },
  {
    "id": "6502012009",
    "district_id": "650201",
    "label": "Harapan Maju",
    "value": "Harapan Maju"
  },
  {
    "id": "6502022001",
    "district_id": "650202",
    "label": "Batu Lidung",
    "value": "Batu Lidung"
  },
  {
    "id": "6502022002",
    "district_id": "650202",
    "label": "Malinau Kota",
    "value": "Malinau Kota"
  },
  {
    "id": "6502022003",
    "district_id": "650202",
    "label": "Pelita Kanaan",
    "value": "Pelita Kanaan"
  },
  {
    "id": "6502022004",
    "district_id": "650202",
    "label": "Malinau Hulu",
    "value": "Malinau Hulu"
  },
  {
    "id": "6502022005",
    "district_id": "650202",
    "label": "Malinau Hilir",
    "value": "Malinau Hilir"
  },
  {
    "id": "6502022006",
    "district_id": "650202",
    "label": "Tanjung Keranjang",
    "value": "Tanjung Keranjang"
  },
  {
    "id": "6502032001",
    "district_id": "650203",
    "label": "Long Pujungan",
    "value": "Long Pujungan"
  },
  {
    "id": "6502032002",
    "district_id": "650203",
    "label": "Long Ketaman",
    "value": "Long Ketaman"
  },
  {
    "id": "6502032003",
    "district_id": "650203",
    "label": "Long Pua",
    "value": "Long Pua"
  },
  {
    "id": "6502032004",
    "district_id": "650203",
    "label": "Long Lame",
    "value": "Long Lame"
  },
  {
    "id": "6502032005",
    "district_id": "650203",
    "label": "Long Jelet",
    "value": "Long Jelet"
  },
  {
    "id": "6502032006",
    "district_id": "650203",
    "label": "Long Aran",
    "value": "Long Aran"
  },
  {
    "id": "6502032007",
    "district_id": "650203",
    "label": "Long Paliran",
    "value": "Long Paliran"
  },
  {
    "id": "6502032008",
    "district_id": "650203",
    "label": "Long Bena",
    "value": "Long Bena"
  },
  {
    "id": "6502032009",
    "district_id": "650203",
    "label": "Long Belaka Pitau",
    "value": "Long Belaka Pitau"
  },
  {
    "id": "6502042001",
    "district_id": "650204",
    "label": "Data Dian",
    "value": "Data Dian"
  },
  {
    "id": "6502042002",
    "district_id": "650204",
    "label": "Long Pipa",
    "value": "Long Pipa"
  },
  {
    "id": "6502042003",
    "district_id": "650204",
    "label": "Long Sule",
    "value": "Long Sule"
  },
  {
    "id": "6502042004",
    "district_id": "650204",
    "label": "Sungai Anai",
    "value": "Sungai Anai"
  },
  {
    "id": "6502042005",
    "district_id": "650204",
    "label": "Long Metun",
    "value": "Long Metun"
  },
  {
    "id": "6502052001",
    "district_id": "650205",
    "label": "Long Nawang",
    "value": "Long Nawang"
  },
  {
    "id": "6502052002",
    "district_id": "650205",
    "label": "Nawang Baru",
    "value": "Nawang Baru"
  },
  {
    "id": "6502052003",
    "district_id": "650205",
    "label": "Long Temuyat",
    "value": "Long Temuyat"
  },
  {
    "id": "6502052004",
    "district_id": "650205",
    "label": "Long Betaoh",
    "value": "Long Betaoh"
  },
  {
    "id": "6502052005",
    "district_id": "650205",
    "label": "Long Payau",
    "value": "Long Payau"
  },
  {
    "id": "6502062001",
    "district_id": "650206",
    "label": "Bila Bekayuk",
    "value": "Bila Bekayuk"
  },
  {
    "id": "6502062002",
    "district_id": "650206",
    "label": "Long Loreh",
    "value": "Long Loreh"
  },
  {
    "id": "6502062003",
    "district_id": "650206",
    "label": "Langap",
    "value": "Langap"
  },
  {
    "id": "6502062004",
    "district_id": "650206",
    "label": "Laban Nyarit",
    "value": "Laban Nyarit"
  },
  {
    "id": "6502062005",
    "district_id": "650206",
    "label": "Nunuk Tanah Kibang",
    "value": "Nunuk Tanah Kibang"
  },
  {
    "id": "6502062006",
    "district_id": "650206",
    "label": "Pelencau",
    "value": "Pelencau"
  },
  {
    "id": "6502062007",
    "district_id": "650206",
    "label": "Paya Seturan",
    "value": "Paya Seturan"
  },
  {
    "id": "6502062008",
    "district_id": "650206",
    "label": "Punan Rian",
    "value": "Punan Rian"
  },
  {
    "id": "6502062009",
    "district_id": "650206",
    "label": "Sengayan",
    "value": "Sengayan"
  },
  {
    "id": "6502072001",
    "district_id": "650207",
    "label": "Kaliamok",
    "value": "Kaliamok"
  },
  {
    "id": "6502072002",
    "district_id": "650207",
    "label": "Luso",
    "value": "Luso"
  },
  {
    "id": "6502072003",
    "district_id": "650207",
    "label": "Malinau Seberang",
    "value": "Malinau Seberang"
  },
  {
    "id": "6502072004",
    "district_id": "650207",
    "label": "Putat",
    "value": "Putat"
  },
  {
    "id": "6502072005",
    "district_id": "650207",
    "label": "Salap",
    "value": "Salap"
  },
  {
    "id": "6502072006",
    "district_id": "650207",
    "label": "Seruyung",
    "value": "Seruyung"
  },
  {
    "id": "6502072007",
    "district_id": "650207",
    "label": "Respen Tubu",
    "value": "Respen Tubu"
  },
  {
    "id": "6502072008",
    "district_id": "650207",
    "label": "Belayan",
    "value": "Belayan"
  },
  {
    "id": "6502072009",
    "district_id": "650207",
    "label": "Sembuak Warod",
    "value": "Sembuak Warod"
  },
  {
    "id": "6502072010",
    "district_id": "650207",
    "label": "Lubak Manis",
    "value": "Lubak Manis"
  },
  {
    "id": "6502072011",
    "district_id": "650207",
    "label": "Kelapis",
    "value": "Kelapis"
  },
  {
    "id": "6502072012",
    "district_id": "650207",
    "label": "Semengaris",
    "value": "Semengaris"
  },
  {
    "id": "6502082001",
    "district_id": "650208",
    "label": "Long Bila",
    "value": "Long Bila"
  },
  {
    "id": "6502082002",
    "district_id": "650208",
    "label": "Long Kenipe",
    "value": "Long Kenipe"
  },
  {
    "id": "6502082003",
    "district_id": "650208",
    "label": "Punan Bengalun",
    "value": "Punan Bengalun"
  },
  {
    "id": "6502082004",
    "district_id": "650208",
    "label": "Sesua",
    "value": "Sesua"
  },
  {
    "id": "6502082005",
    "district_id": "650208",
    "label": "Sentaban",
    "value": "Sentaban"
  },
  {
    "id": "6502082006",
    "district_id": "650208",
    "label": "Tanjung Lapang",
    "value": "Tanjung Lapang"
  },
  {
    "id": "6502082007",
    "district_id": "650208",
    "label": "Taras",
    "value": "Taras"
  },
  {
    "id": "6502082008",
    "district_id": "650208",
    "label": "Kuala Lapang",
    "value": "Kuala Lapang"
  },
  {
    "id": "6502082009",
    "district_id": "650208",
    "label": "Sempayang",
    "value": "Sempayang"
  },
  {
    "id": "6502092001",
    "district_id": "650209",
    "label": "Dumu Mahak",
    "value": "Dumu Mahak"
  },
  {
    "id": "6502092002",
    "district_id": "650209",
    "label": "Long Lebusan",
    "value": "Long Lebusan"
  },
  {
    "id": "6502092003",
    "district_id": "650209",
    "label": "Mahak Baru",
    "value": "Mahak Baru"
  },
  {
    "id": "6502092004",
    "district_id": "650209",
    "label": "Long Top",
    "value": "Long Top"
  },
  {
    "id": "6502092005",
    "district_id": "650209",
    "label": "Agung Baru",
    "value": "Agung Baru"
  },
  {
    "id": "6502092006",
    "district_id": "650209",
    "label": "Data Baru",
    "value": "Data Baru"
  },
  {
    "id": "6502102001",
    "district_id": "650210",
    "label": "Long Ampung",
    "value": "Long Ampung"
  },
  {
    "id": "6502102002",
    "district_id": "650210",
    "label": "Long Uro",
    "value": "Long Uro"
  },
  {
    "id": "6502102003",
    "district_id": "650210",
    "label": "Lidung Payau",
    "value": "Lidung Payau"
  },
  {
    "id": "6502102004",
    "district_id": "650210",
    "label": "Sungai Barang",
    "value": "Sungai Barang"
  },
  {
    "id": "6502102005",
    "district_id": "650210",
    "label": "Metulang",
    "value": "Metulang"
  },
  {
    "id": "6502112001",
    "district_id": "650211",
    "label": "Long Uli",
    "value": "Long Uli"
  },
  {
    "id": "6502112002",
    "district_id": "650211",
    "label": "Long Alango",
    "value": "Long Alango"
  },
  {
    "id": "6502112003",
    "district_id": "650211",
    "label": "Long Berini",
    "value": "Long Berini"
  },
  {
    "id": "6502112004",
    "district_id": "650211",
    "label": "Long Tebulo",
    "value": "Long Tebulo"
  },
  {
    "id": "6502112005",
    "district_id": "650211",
    "label": "Apau Ping",
    "value": "Apau Ping"
  },
  {
    "id": "6502112006",
    "district_id": "650211",
    "label": "Long Kemuat",
    "value": "Long Kemuat"
  },
  {
    "id": "6502122001",
    "district_id": "650212",
    "label": "Lung Fala",
    "value": "Lung Fala"
  },
  {
    "id": "6502122002",
    "district_id": "650212",
    "label": "Lung Kebinu",
    "value": "Lung Kebinu"
  },
  {
    "id": "6502122003",
    "district_id": "650212",
    "label": "Lung Mekatip",
    "value": "Lung Mekatip"
  },
  {
    "id": "6502122004",
    "district_id": "650212",
    "label": "Lung Simau",
    "value": "Lung Simau"
  },
  {
    "id": "6502122005",
    "district_id": "650212",
    "label": "Lung Barang",
    "value": "Lung Barang"
  },
  {
    "id": "6502122006",
    "district_id": "650212",
    "label": "Lung Sulit",
    "value": "Lung Sulit"
  },
  {
    "id": "6502122007",
    "district_id": "650212",
    "label": "Lung Semamu",
    "value": "Lung Semamu"
  },
  {
    "id": "6502132001",
    "district_id": "650213",
    "label": "Setulang",
    "value": "Setulang"
  },
  {
    "id": "6502132002",
    "district_id": "650213",
    "label": "Setarap",
    "value": "Setarap"
  },
  {
    "id": "6502132003",
    "district_id": "650213",
    "label": "Punan Setarap",
    "value": "Punan Setarap"
  },
  {
    "id": "6502132004",
    "district_id": "650213",
    "label": "Batu Kajang",
    "value": "Batu Kajang"
  },
  {
    "id": "6502132005",
    "district_id": "650213",
    "label": "Gong Solok",
    "value": "Gong Solok"
  },
  {
    "id": "6502132006",
    "district_id": "650213",
    "label": "Punan Gong Solok",
    "value": "Punan Gong Solok"
  },
  {
    "id": "6502132007",
    "district_id": "650213",
    "label": "Long Adiu",
    "value": "Long Adiu"
  },
  {
    "id": "6502132008",
    "district_id": "650213",
    "label": "Punan Long Adiu",
    "value": "Punan Long Adiu"
  },
  {
    "id": "6502142001",
    "district_id": "650214",
    "label": "Long Lake",
    "value": "Long Lake"
  },
  {
    "id": "6502142002",
    "district_id": "650214",
    "label": "Punan Mirau",
    "value": "Punan Mirau"
  },
  {
    "id": "6502142003",
    "district_id": "650214",
    "label": "Long Rat",
    "value": "Long Rat"
  },
  {
    "id": "6502142004",
    "district_id": "650214",
    "label": "Halanga",
    "value": "Halanga"
  },
  {
    "id": "6502142005",
    "district_id": "650214",
    "label": "Metut",
    "value": "Metut"
  },
  {
    "id": "6502142006",
    "district_id": "650214",
    "label": "Long Jalan",
    "value": "Long Jalan"
  },
  {
    "id": "6502142007",
    "district_id": "650214",
    "label": "Nahakramo",
    "value": "Nahakramo"
  },
  {
    "id": "6502142008",
    "district_id": "650214",
    "label": "Tanjung Nanga",
    "value": "Tanjung Nanga"
  },
  {
    "id": "6502152001",
    "district_id": "650215",
    "label": "Long Nyau",
    "value": "Long Nyau"
  },
  {
    "id": "6502152002",
    "district_id": "650215",
    "label": "Long Titi",
    "value": "Long Titi"
  },
  {
    "id": "6502152003",
    "district_id": "650215",
    "label": "Long Ranau",
    "value": "Long Ranau"
  },
  {
    "id": "6502152004",
    "district_id": "650215",
    "label": "Rian Tubu",
    "value": "Rian Tubu"
  },
  {
    "id": "6502152005",
    "district_id": "650215",
    "label": "Long Pada",
    "value": "Long Pada"
  },
  {
    "id": "6503012001",
    "district_id": "650301",
    "label": "Tanjungkarang",
    "value": "Tanjungkarang"
  },
  {
    "id": "6503012002",
    "district_id": "650301",
    "label": "Balansiku",
    "value": "Balansiku"
  },
  {
    "id": "6503012003",
    "district_id": "650301",
    "label": "Sungai Manurung",
    "value": "Sungai Manurung"
  },
  {
    "id": "6503012004",
    "district_id": "650301",
    "label": "Padaidi",
    "value": "Padaidi"
  },
  {
    "id": "6503021001",
    "district_id": "650302",
    "label": "Nunukan Timur",
    "value": "Nunukan Timur"
  },
  {
    "id": "6503021002",
    "district_id": "650302",
    "label": "Nunukan Barat",
    "value": "Nunukan Barat"
  },
  {
    "id": "6503021003",
    "district_id": "650302",
    "label": "Nunukan Utara",
    "value": "Nunukan Utara"
  },
  {
    "id": "6503021005",
    "district_id": "650302",
    "label": "Nunukan Tengah",
    "value": "Nunukan Tengah"
  },
  {
    "id": "6503022004",
    "district_id": "650302",
    "label": "Binusan",
    "value": "Binusan"
  },
  {
    "id": "6503032001",
    "district_id": "650303",
    "label": "Tagul",
    "value": "Tagul"
  },
  {
    "id": "6503032002",
    "district_id": "650303",
    "label": "Lubakan",
    "value": "Lubakan"
  },
  {
    "id": "6503032003",
    "district_id": "650303",
    "label": "Atap",
    "value": "Atap"
  },
  {
    "id": "6503032004",
    "district_id": "650303",
    "label": "Manuk Bungkul",
    "value": "Manuk Bungkul"
  },
  {
    "id": "6503032005",
    "district_id": "650303",
    "label": "Tujung",
    "value": "Tujung"
  },
  {
    "id": "6503032006",
    "district_id": "650303",
    "label": "Pagar",
    "value": "Pagar"
  },
  {
    "id": "6503032007",
    "district_id": "650303",
    "label": "Labuk",
    "value": "Labuk"
  },
  {
    "id": "6503032008",
    "district_id": "650303",
    "label": "Butas Bagu",
    "value": "Butas Bagu"
  },
  {
    "id": "6503032009",
    "district_id": "650303",
    "label": "Pelaju",
    "value": "Pelaju"
  },
  {
    "id": "6503032010",
    "district_id": "650303",
    "label": "Tepian",
    "value": "Tepian"
  },
  {
    "id": "6503042001",
    "district_id": "650304",
    "label": "Deralon",
    "value": "Deralon"
  },
  {
    "id": "6503042002",
    "district_id": "650304",
    "label": "Liang",
    "value": "Liang"
  },
  {
    "id": "6503042003",
    "district_id": "650304",
    "label": "Dabulon",
    "value": "Dabulon"
  },
  {
    "id": "6503042004",
    "district_id": "650304",
    "label": "Taluan",
    "value": "Taluan"
  },
  {
    "id": "6503042005",
    "district_id": "650304",
    "label": "Podong",
    "value": "Podong"
  },
  {
    "id": "6503042006",
    "district_id": "650304",
    "label": "Patal I",
    "value": "Patal I"
  },
  {
    "id": "6503042007",
    "district_id": "650304",
    "label": "Siawang",
    "value": "Siawang"
  },
  {
    "id": "6503042008",
    "district_id": "650304",
    "label": "Sapuyan",
    "value": "Sapuyan"
  },
  {
    "id": "6503042009",
    "district_id": "650304",
    "label": "Nainsid",
    "value": "Nainsid"
  },
  {
    "id": "6503042010",
    "district_id": "650304",
    "label": "Sasibu",
    "value": "Sasibu"
  },
  {
    "id": "6503042011",
    "district_id": "650304",
    "label": "Saludan",
    "value": "Saludan"
  },
  {
    "id": "6503042012",
    "district_id": "650304",
    "label": "Semalat",
    "value": "Semalat"
  },
  {
    "id": "6503042013",
    "district_id": "650304",
    "label": "Libang",
    "value": "Libang"
  },
  {
    "id": "6503042014",
    "district_id": "650304",
    "label": "Sedongon",
    "value": "Sedongon"
  },
  {
    "id": "6503042015",
    "district_id": "650304",
    "label": "Tanjung Hilir",
    "value": "Tanjung Hilir"
  },
  {
    "id": "6503042016",
    "district_id": "650304",
    "label": "Sumalumung",
    "value": "Sumalumung"
  },
  {
    "id": "6503042017",
    "district_id": "650304",
    "label": "Lintong",
    "value": "Lintong"
  },
  {
    "id": "6503042018",
    "district_id": "650304",
    "label": "Pulu Bulawan",
    "value": "Pulu Bulawan"
  },
  {
    "id": "6503042019",
    "district_id": "650304",
    "label": "Tanjung Hulu",
    "value": "Tanjung Hulu"
  },
  {
    "id": "6503042020",
    "district_id": "650304",
    "label": "Patal II",
    "value": "Patal II"
  },
  {
    "id": "6503042021",
    "district_id": "650304",
    "label": "Pa'loo",
    "value": "Pa'loo"
  },
  {
    "id": "6503042022",
    "district_id": "650304",
    "label": "Sangkub",
    "value": "Sangkub"
  },
  {
    "id": "6503042023",
    "district_id": "650304",
    "label": "Tubus",
    "value": "Tubus"
  },
  {
    "id": "6503042024",
    "district_id": "650304",
    "label": "Likos",
    "value": "Likos"
  },
  {
    "id": "6503042025",
    "district_id": "650304",
    "label": "Bulan-Bulan",
    "value": "Bulan-Bulan"
  },
  {
    "id": "6503042026",
    "district_id": "650304",
    "label": "Kalampising",
    "value": "Kalampising"
  },
  {
    "id": "6503042027",
    "district_id": "650304",
    "label": "Pa'lemumut",
    "value": "Pa'lemumut"
  },
  {
    "id": "6503042028",
    "district_id": "650304",
    "label": "Mansalong",
    "value": "Mansalong"
  },
  {
    "id": "6503052002",
    "district_id": "650305",
    "label": "Pa' Padi",
    "value": "Pa' Padi"
  },
  {
    "id": "6503052003",
    "district_id": "650305",
    "label": "Cinglat",
    "value": "Cinglat"
  },
  {
    "id": "6503052022",
    "district_id": "650305",
    "label": "Liang Butan",
    "value": "Liang Butan"
  },
  {
    "id": "6503052029",
    "district_id": "650305",
    "label": "Pa' Rupai",
    "value": "Pa' Rupai"
  },
  {
    "id": "6503052030",
    "district_id": "650305",
    "label": "Ba Sikor",
    "value": "Ba Sikor"
  },
  {
    "id": "6503052031",
    "district_id": "650305",
    "label": "Pa' Nado",
    "value": "Pa' Nado"
  },
  {
    "id": "6503052032",
    "district_id": "650305",
    "label": "Buduk Kinangan",
    "value": "Buduk Kinangan"
  },
  {
    "id": "6503052033",
    "district_id": "650305",
    "label": "Liang Tuer",
    "value": "Liang Tuer"
  },
  {
    "id": "6503052034",
    "district_id": "650305",
    "label": "Buduk Tumu",
    "value": "Buduk Tumu"
  },
  {
    "id": "6503052035",
    "district_id": "650305",
    "label": "Long Berayang",
    "value": "Long Berayang"
  },
  {
    "id": "6503052036",
    "district_id": "650305",
    "label": "Pa' Api",
    "value": "Pa' Api"
  },
  {
    "id": "6503052037",
    "district_id": "650305",
    "label": "Pa' Sire",
    "value": "Pa' Sire"
  },
  {
    "id": "6503052038",
    "district_id": "650305",
    "label": "Wa' Yanud",
    "value": "Wa' Yanud"
  },
  {
    "id": "6503052039",
    "district_id": "650305",
    "label": "Long Nawang",
    "value": "Long Nawang"
  },
  {
    "id": "6503052040",
    "district_id": "650305",
    "label": "Long Katung",
    "value": "Long Katung"
  },
  {
    "id": "6503052041",
    "district_id": "650305",
    "label": "Long Bawan",
    "value": "Long Bawan"
  },
  {
    "id": "6503052044",
    "district_id": "650305",
    "label": "Long Matung",
    "value": "Long Matung"
  },
  {
    "id": "6503052045",
    "district_id": "650305",
    "label": "Long Rupan",
    "value": "Long Rupan"
  },
  {
    "id": "6503052046",
    "district_id": "650305",
    "label": "Liang Biadung",
    "value": "Liang Biadung"
  },
  {
    "id": "6503052047",
    "district_id": "650305",
    "label": "Wa' Laya",
    "value": "Wa' Laya"
  },
  {
    "id": "6503052048",
    "district_id": "650305",
    "label": "Pa' Matung",
    "value": "Pa' Matung"
  },
  {
    "id": "6503052049",
    "district_id": "650305",
    "label": "Pa' Terutun",
    "value": "Pa' Terutun"
  },
  {
    "id": "6503052065",
    "district_id": "650305",
    "label": "Pa' Putuk",
    "value": "Pa' Putuk"
  },
  {
    "id": "6503062001",
    "district_id": "650306",
    "label": "Pembeliangan",
    "value": "Pembeliangan"
  },
  {
    "id": "6503062002",
    "district_id": "650306",
    "label": "Apas",
    "value": "Apas"
  },
  {
    "id": "6503062003",
    "district_id": "650306",
    "label": "Kunyit",
    "value": "Kunyit"
  },
  {
    "id": "6503062004",
    "district_id": "650306",
    "label": "Tetaban",
    "value": "Tetaban"
  },
  {
    "id": "6503062005",
    "district_id": "650306",
    "label": "Kekayap",
    "value": "Kekayap"
  },
  {
    "id": "6503062006",
    "district_id": "650306",
    "label": "Bebanas",
    "value": "Bebanas"
  },
  {
    "id": "6503062007",
    "district_id": "650306",
    "label": "Lulu",
    "value": "Lulu"
  },
  {
    "id": "6503062008",
    "district_id": "650306",
    "label": "Sujau",
    "value": "Sujau"
  },
  {
    "id": "6503062009",
    "district_id": "650306",
    "label": "Melasu Baru",
    "value": "Melasu Baru"
  },
  {
    "id": "6503062010",
    "district_id": "650306",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "6503072001",
    "district_id": "650307",
    "label": "Long Pasia",
    "value": "Long Pasia"
  },
  {
    "id": "6503072002",
    "district_id": "650307",
    "label": "Liang Lunuk",
    "value": "Liang Lunuk"
  },
  {
    "id": "6503072003",
    "district_id": "650307",
    "label": "Pa' Ibang",
    "value": "Pa' Ibang"
  },
  {
    "id": "6503072004",
    "district_id": "650307",
    "label": "Pa' Amai",
    "value": "Pa' Amai"
  },
  {
    "id": "6503072005",
    "district_id": "650307",
    "label": "Pa' Kaber",
    "value": "Pa' Kaber"
  },
  {
    "id": "6503072006",
    "district_id": "650307",
    "label": "Pa' Tera",
    "value": "Pa' Tera"
  },
  {
    "id": "6503072007",
    "district_id": "650307",
    "label": "Pa' Sing",
    "value": "Pa' Sing"
  },
  {
    "id": "6503072009",
    "district_id": "650307",
    "label": "Pa' Dalan",
    "value": "Pa' Dalan"
  },
  {
    "id": "6503072010",
    "district_id": "650307",
    "label": "Long Birar",
    "value": "Long Birar"
  },
  {
    "id": "6503072011",
    "district_id": "650307",
    "label": "Pa' Upan",
    "value": "Pa' Upan"
  },
  {
    "id": "6503072016",
    "district_id": "650307",
    "label": "Long Budung",
    "value": "Long Budung"
  },
  {
    "id": "6503072018",
    "district_id": "650307",
    "label": "Long Pupung",
    "value": "Long Pupung"
  },
  {
    "id": "6503072020",
    "district_id": "650307",
    "label": "Pa' Urang",
    "value": "Pa' Urang"
  },
  {
    "id": "6503082001",
    "district_id": "650308",
    "label": "Liang Bunyu",
    "value": "Liang Bunyu"
  },
  {
    "id": "6503082002",
    "district_id": "650308",
    "label": "Binalawan",
    "value": "Binalawan"
  },
  {
    "id": "6503082003",
    "district_id": "650308",
    "label": "Setabu",
    "value": "Setabu"
  },
  {
    "id": "6503082004",
    "district_id": "650308",
    "label": "Bambangan",
    "value": "Bambangan"
  },
  {
    "id": "6503091001",
    "district_id": "650309",
    "label": "Selisun",
    "value": "Selisun"
  },
  {
    "id": "6503091002",
    "district_id": "650309",
    "label": "Nunukan Selatan",
    "value": "Nunukan Selatan"
  },
  {
    "id": "6503091003",
    "district_id": "650309",
    "label": "Mansapa",
    "value": "Mansapa"
  },
  {
    "id": "6503091004",
    "district_id": "650309",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6503102001",
    "district_id": "650310",
    "label": "Sungai Nyamuk",
    "value": "Sungai Nyamuk"
  },
  {
    "id": "6503102002",
    "district_id": "650310",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "6503102003",
    "district_id": "650310",
    "label": "Bukit Aru Indah",
    "value": "Bukit Aru Indah"
  },
  {
    "id": "6503102004",
    "district_id": "650310",
    "label": "Tanjung Aru",
    "value": "Tanjung Aru"
  },
  {
    "id": "6503112001",
    "district_id": "650311",
    "label": "Sungai Pancang",
    "value": "Sungai Pancang"
  },
  {
    "id": "6503112002",
    "district_id": "650311",
    "label": "Lapri",
    "value": "Lapri"
  },
  {
    "id": "6503112003",
    "district_id": "650311",
    "label": "Seberang",
    "value": "Seberang"
  },
  {
    "id": "6503122001",
    "district_id": "650312",
    "label": "Sungai Limau",
    "value": "Sungai Limau"
  },
  {
    "id": "6503122002",
    "district_id": "650312",
    "label": "Maspul",
    "value": "Maspul"
  },
  {
    "id": "6503122003",
    "district_id": "650312",
    "label": "Aji Kuning",
    "value": "Aji Kuning"
  },
  {
    "id": "6503122004",
    "district_id": "650312",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "6503132001",
    "district_id": "650313",
    "label": "Srinanti",
    "value": "Srinanti"
  },
  {
    "id": "6503132002",
    "district_id": "650313",
    "label": "Tabur Lestari",
    "value": "Tabur Lestari"
  },
  {
    "id": "6503132003",
    "district_id": "650313",
    "label": "Samaenre Semaja",
    "value": "Samaenre Semaja"
  },
  {
    "id": "6503132004",
    "district_id": "650313",
    "label": "Sekaduyan Taka",
    "value": "Sekaduyan Taka"
  },
  {
    "id": "6503142001",
    "district_id": "650314",
    "label": "Sanur",
    "value": "Sanur"
  },
  {
    "id": "6503142002",
    "district_id": "650314",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "6503142003",
    "district_id": "650314",
    "label": "Semunad",
    "value": "Semunad"
  },
  {
    "id": "6503142004",
    "district_id": "650314",
    "label": "Sekikilan",
    "value": "Sekikilan"
  },
  {
    "id": "6503142005",
    "district_id": "650314",
    "label": "Kalunsayan",
    "value": "Kalunsayan"
  },
  {
    "id": "6503142006",
    "district_id": "650314",
    "label": "Tembalang",
    "value": "Tembalang"
  },
  {
    "id": "6503142007",
    "district_id": "650314",
    "label": "Salang",
    "value": "Salang"
  },
  {
    "id": "6503142008",
    "district_id": "650314",
    "label": "Tinampak I",
    "value": "Tinampak I"
  },
  {
    "id": "6503142009",
    "district_id": "650314",
    "label": "Tinampak II",
    "value": "Tinampak II"
  },
  {
    "id": "6503142010",
    "district_id": "650314",
    "label": "Naputi",
    "value": "Naputi"
  },
  {
    "id": "6503142011",
    "district_id": "650314",
    "label": "Tau Baru",
    "value": "Tau Baru"
  },
  {
    "id": "6503142012",
    "district_id": "650314",
    "label": "Balatikon",
    "value": "Balatikon"
  },
  {
    "id": "6503152001",
    "district_id": "650315",
    "label": "Payang",
    "value": "Payang"
  },
  {
    "id": "6503152002",
    "district_id": "650315",
    "label": "Suyadon",
    "value": "Suyadon"
  },
  {
    "id": "6503152003",
    "district_id": "650315",
    "label": "Bulu Mengolom",
    "value": "Bulu Mengolom"
  },
  {
    "id": "6503152004",
    "district_id": "650315",
    "label": "Tukulon",
    "value": "Tukulon"
  },
  {
    "id": "6503152005",
    "district_id": "650315",
    "label": "Ubol Sulok",
    "value": "Ubol Sulok"
  },
  {
    "id": "6503152006",
    "district_id": "650315",
    "label": "Batung",
    "value": "Batung"
  },
  {
    "id": "6503152007",
    "district_id": "650315",
    "label": "Ubol Alung",
    "value": "Ubol Alung"
  },
  {
    "id": "6503152008",
    "district_id": "650315",
    "label": "Nansapan",
    "value": "Nansapan"
  },
  {
    "id": "6503152009",
    "district_id": "650315",
    "label": "Samunti",
    "value": "Samunti"
  },
  {
    "id": "6503152010",
    "district_id": "650315",
    "label": "Semata",
    "value": "Semata"
  },
  {
    "id": "6503152011",
    "district_id": "650315",
    "label": "Sungoi",
    "value": "Sungoi"
  },
  {
    "id": "6503152012",
    "district_id": "650315",
    "label": "Salan",
    "value": "Salan"
  },
  {
    "id": "6503152013",
    "district_id": "650315",
    "label": "Sinampila I",
    "value": "Sinampila I"
  },
  {
    "id": "6503152014",
    "district_id": "650315",
    "label": "Paluan",
    "value": "Paluan"
  },
  {
    "id": "6503152015",
    "district_id": "650315",
    "label": "Sedalit",
    "value": "Sedalit"
  },
  {
    "id": "6503152016",
    "district_id": "650315",
    "label": "Tambalang Hilir",
    "value": "Tambalang Hilir"
  },
  {
    "id": "6503152017",
    "district_id": "650315",
    "label": "Tadungus",
    "value": "Tadungus"
  },
  {
    "id": "6503152018",
    "district_id": "650315",
    "label": "Sinampila II",
    "value": "Sinampila II"
  },
  {
    "id": "6503152019",
    "district_id": "650315",
    "label": "Kalambuku",
    "value": "Kalambuku"
  },
  {
    "id": "6503152020",
    "district_id": "650315",
    "label": "Jukup",
    "value": "Jukup"
  },
  {
    "id": "6503152021",
    "district_id": "650315",
    "label": "Long Bulu",
    "value": "Long Bulu"
  },
  {
    "id": "6503152022",
    "district_id": "650315",
    "label": "Sumentobol",
    "value": "Sumentobol"
  },
  {
    "id": "6503152023",
    "district_id": "650315",
    "label": "Linsayung",
    "value": "Linsayung"
  },
  {
    "id": "6503152024",
    "district_id": "650315",
    "label": "Tumantalas",
    "value": "Tumantalas"
  },
  {
    "id": "6503152025",
    "district_id": "650315",
    "label": "Sanal",
    "value": "Sanal"
  },
  {
    "id": "6503152026",
    "district_id": "650315",
    "label": "Limpakon",
    "value": "Limpakon"
  },
  {
    "id": "6503152027",
    "district_id": "650315",
    "label": "Labuk",
    "value": "Labuk"
  },
  {
    "id": "6503152028",
    "district_id": "650315",
    "label": "Nantukidan",
    "value": "Nantukidan"
  },
  {
    "id": "6503152029",
    "district_id": "650315",
    "label": "Labang",
    "value": "Labang"
  },
  {
    "id": "6503152030",
    "district_id": "650315",
    "label": "Sumantipal",
    "value": "Sumantipal"
  },
  {
    "id": "6503152031",
    "district_id": "650315",
    "label": "Ngawol",
    "value": "Ngawol"
  },
  {
    "id": "6503152032",
    "district_id": "650315",
    "label": "Bululaun Hilir",
    "value": "Bululaun Hilir"
  },
  {
    "id": "6503152033",
    "district_id": "650315",
    "label": "Lagas",
    "value": "Lagas"
  },
  {
    "id": "6503152034",
    "district_id": "650315",
    "label": "Panas",
    "value": "Panas"
  },
  {
    "id": "6503152035",
    "district_id": "650315",
    "label": "Langgason",
    "value": "Langgason"
  },
  {
    "id": "6503152036",
    "district_id": "650315",
    "label": "Tambalang Hulu",
    "value": "Tambalang Hulu"
  },
  {
    "id": "6503152037",
    "district_id": "650315",
    "label": "Kuyo",
    "value": "Kuyo"
  },
  {
    "id": "6503152038",
    "district_id": "650315",
    "label": "Bokok",
    "value": "Bokok"
  },
  {
    "id": "6503152039",
    "district_id": "650315",
    "label": "Tau Lumbis",
    "value": "Tau Lumbis"
  },
  {
    "id": "6503152040",
    "district_id": "650315",
    "label": "Bululaun Hulu",
    "value": "Bululaun Hulu"
  },
  {
    "id": "6503152041",
    "district_id": "650315",
    "label": "Kalisun",
    "value": "Kalisun"
  },
  {
    "id": "6503152042",
    "district_id": "650315",
    "label": "Mamasin",
    "value": "Mamasin"
  },
  {
    "id": "6503152043",
    "district_id": "650315",
    "label": "Sibalu",
    "value": "Sibalu"
  },
  {
    "id": "6503152044",
    "district_id": "650315",
    "label": "Duyan",
    "value": "Duyan"
  },
  {
    "id": "6503152045",
    "district_id": "650315",
    "label": "Tuntulibing",
    "value": "Tuntulibing"
  },
  {
    "id": "6503152046",
    "district_id": "650315",
    "label": "Tetagas",
    "value": "Tetagas"
  },
  {
    "id": "6503152047",
    "district_id": "650315",
    "label": "Kabungolor",
    "value": "Kabungolor"
  },
  {
    "id": "6503152048",
    "district_id": "650315",
    "label": "Lipaga",
    "value": "Lipaga"
  },
  {
    "id": "6503152049",
    "district_id": "650315",
    "label": "Tantalujuk",
    "value": "Tantalujuk"
  },
  {
    "id": "6503162001",
    "district_id": "650316",
    "label": "Lubok Buat",
    "value": "Lubok Buat"
  },
  {
    "id": "6503162002",
    "district_id": "650316",
    "label": "Katul",
    "value": "Katul"
  },
  {
    "id": "6503162003",
    "district_id": "650316",
    "label": "Mambulu",
    "value": "Mambulu"
  },
  {
    "id": "6503162004",
    "district_id": "650316",
    "label": "Pagaluyon",
    "value": "Pagaluyon"
  },
  {
    "id": "6503162005",
    "district_id": "650316",
    "label": "Saduman",
    "value": "Saduman"
  },
  {
    "id": "6503162006",
    "district_id": "650316",
    "label": "Tulang",
    "value": "Tulang"
  },
  {
    "id": "6503162007",
    "district_id": "650316",
    "label": "Sabuluan",
    "value": "Sabuluan"
  },
  {
    "id": "6503162008",
    "district_id": "650316",
    "label": "Pulau Keras",
    "value": "Pulau Keras"
  },
  {
    "id": "6503162009",
    "district_id": "650316",
    "label": "Liuk Bulu",
    "value": "Liuk Bulu"
  },
  {
    "id": "6503162010",
    "district_id": "650316",
    "label": "Binanun",
    "value": "Binanun"
  },
  {
    "id": "6503172001",
    "district_id": "650317",
    "label": "Tang Paye",
    "value": "Tang Paye"
  },
  {
    "id": "6503172002",
    "district_id": "650317",
    "label": "Long Rungan",
    "value": "Long Rungan"
  },
  {
    "id": "6503172003",
    "district_id": "650317",
    "label": "Long Kelupan",
    "value": "Long Kelupan"
  },
  {
    "id": "6503172004",
    "district_id": "650317",
    "label": "Long Padi",
    "value": "Long Padi"
  },
  {
    "id": "6503172005",
    "district_id": "650317",
    "label": "Tang Badui",
    "value": "Tang Badui"
  },
  {
    "id": "6503172006",
    "district_id": "650317",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "6503172007",
    "district_id": "650317",
    "label": "Long Mutan",
    "value": "Long Mutan"
  },
  {
    "id": "6503172008",
    "district_id": "650317",
    "label": "Pa' Milau",
    "value": "Pa' Milau"
  },
  {
    "id": "6503172009",
    "district_id": "650317",
    "label": "Ba' Liku",
    "value": "Ba' Liku"
  },
  {
    "id": "6503172010",
    "district_id": "650317",
    "label": "Long Rian",
    "value": "Long Rian"
  },
  {
    "id": "6503172011",
    "district_id": "650317",
    "label": "Pa' Yalau",
    "value": "Pa' Yalau"
  },
  {
    "id": "6503182001",
    "district_id": "650318",
    "label": "Pa' Betung",
    "value": "Pa' Betung"
  },
  {
    "id": "6503182002",
    "district_id": "650318",
    "label": "Long Sepayang",
    "value": "Long Sepayang"
  },
  {
    "id": "6503182003",
    "district_id": "650318",
    "label": "Pa' Pawan",
    "value": "Pa' Pawan"
  },
  {
    "id": "6503182004",
    "district_id": "650318",
    "label": "Pa' Melade",
    "value": "Pa' Melade"
  },
  {
    "id": "6503182005",
    "district_id": "650318",
    "label": "Pa' Kebuan",
    "value": "Pa' Kebuan"
  },
  {
    "id": "6503182006",
    "district_id": "650318",
    "label": "Pa' Umung",
    "value": "Pa' Umung"
  },
  {
    "id": "6503182007",
    "district_id": "650318",
    "label": "Pa' Rangeb",
    "value": "Pa' Rangeb"
  },
  {
    "id": "6503182008",
    "district_id": "650318",
    "label": "Long Umung",
    "value": "Long Umung"
  },
  {
    "id": "6503182009",
    "district_id": "650318",
    "label": "Long Tenem",
    "value": "Long Tenem"
  },
  {
    "id": "6503182010",
    "district_id": "650318",
    "label": "Long Nuat",
    "value": "Long Nuat"
  },
  {
    "id": "6503182011",
    "district_id": "650318",
    "label": "Pa' Pala",
    "value": "Pa' Pala"
  },
  {
    "id": "6503182012",
    "district_id": "650318",
    "label": "Sinar Baru",
    "value": "Sinar Baru"
  },
  {
    "id": "6503182013",
    "district_id": "650318",
    "label": "Pa' Lidung",
    "value": "Pa' Lidung"
  },
  {
    "id": "6503182014",
    "district_id": "650318",
    "label": "Pa' Raye",
    "value": "Pa' Raye"
  },
  {
    "id": "6503182015",
    "district_id": "650318",
    "label": "Bungayan",
    "value": "Bungayan"
  },
  {
    "id": "6503182016",
    "district_id": "650318",
    "label": "Wa' Yagung",
    "value": "Wa' Yagung"
  },
  {
    "id": "6503182017",
    "district_id": "650318",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "6503192001",
    "district_id": "650319",
    "label": "Pa' Mulak",
    "value": "Pa' Mulak"
  },
  {
    "id": "6503192002",
    "district_id": "650319",
    "label": "Long Puak",
    "value": "Long Puak"
  },
  {
    "id": "6503192003",
    "district_id": "650319",
    "label": "Long Mangan",
    "value": "Long Mangan"
  },
  {
    "id": "6503192004",
    "district_id": "650319",
    "label": "Buduk Kubul",
    "value": "Buduk Kubul"
  },
  {
    "id": "6503192005",
    "district_id": "650319",
    "label": "Long Kabid",
    "value": "Long Kabid"
  },
  {
    "id": "6503192006",
    "district_id": "650319",
    "label": "Pa' Inan",
    "value": "Pa' Inan"
  },
  {
    "id": "6503192007",
    "district_id": "650319",
    "label": "Lembudud",
    "value": "Lembudud"
  },
  {
    "id": "6503192008",
    "district_id": "650319",
    "label": "Long Tugul",
    "value": "Long Tugul"
  },
  {
    "id": "6503192009",
    "district_id": "650319",
    "label": "Pa' Butal",
    "value": "Pa' Butal"
  },
  {
    "id": "6503192010",
    "district_id": "650319",
    "label": "Pa' Delung",
    "value": "Pa' Delung"
  },
  {
    "id": "6503192011",
    "district_id": "650319",
    "label": "Pa' Urud",
    "value": "Pa' Urud"
  },
  {
    "id": "6503192012",
    "district_id": "650319",
    "label": "Pa' Kemut",
    "value": "Pa' Kemut"
  },
  {
    "id": "6503192013",
    "district_id": "650319",
    "label": "Pa' Kidang",
    "value": "Pa' Kidang"
  },
  {
    "id": "6503192014",
    "district_id": "650319",
    "label": "Lembada",
    "value": "Lembada"
  },
  {
    "id": "6503192015",
    "district_id": "650319",
    "label": "Pa' Payak",
    "value": "Pa' Payak"
  },
  {
    "id": "6503192016",
    "district_id": "650319",
    "label": "Pa' Pirit",
    "value": "Pa' Pirit"
  },
  {
    "id": "6503192017",
    "district_id": "650319",
    "label": "Liang Aliq",
    "value": "Liang Aliq"
  },
  {
    "id": "6503192018",
    "district_id": "650319",
    "label": "Sembudud",
    "value": "Sembudud"
  },
  {
    "id": "6503192019",
    "district_id": "650319",
    "label": "Liang Turan",
    "value": "Liang Turan"
  },
  {
    "id": "6503192020",
    "district_id": "650319",
    "label": "Liang Bua",
    "value": "Liang Bua"
  },
  {
    "id": "6503192021",
    "district_id": "650319",
    "label": "Lepatar",
    "value": "Lepatar"
  },
  {
    "id": "6503192022",
    "district_id": "650319",
    "label": "Pa' Mering",
    "value": "Pa' Mering"
  },
  {
    "id": "6503192023",
    "district_id": "650319",
    "label": "Pa' Pani",
    "value": "Pa' Pani"
  },
  {
    "id": "6503192024",
    "district_id": "650319",
    "label": "Pa' Lutut",
    "value": "Pa' Lutut"
  },
  {
    "id": "6503192025",
    "district_id": "650319",
    "label": "Ma' Libu",
    "value": "Ma' Libu"
  },
  {
    "id": "6504012001",
    "district_id": "650401",
    "label": "Tideng Pale",
    "value": "Tideng Pale"
  },
  {
    "id": "6504012002",
    "district_id": "650401",
    "label": "Limbu Sedulun",
    "value": "Limbu Sedulun"
  },
  {
    "id": "6504012003",
    "district_id": "650401",
    "label": "Sebidai",
    "value": "Sebidai"
  },
  {
    "id": "6504012004",
    "district_id": "650401",
    "label": "Sedulun",
    "value": "Sedulun"
  },
  {
    "id": "6504012005",
    "district_id": "650401",
    "label": "Tideng Pale Timur",
    "value": "Tideng Pale Timur"
  },
  {
    "id": "6504012006",
    "district_id": "650401",
    "label": "Gunawan",
    "value": "Gunawan"
  },
  {
    "id": "6504012007",
    "district_id": "650401",
    "label": "Sebawang",
    "value": "Sebawang"
  },
  {
    "id": "6504022001",
    "district_id": "650402",
    "label": "Sesayap",
    "value": "Sesayap"
  },
  {
    "id": "6504022002",
    "district_id": "650402",
    "label": "Sengkong",
    "value": "Sengkong"
  },
  {
    "id": "6504022003",
    "district_id": "650402",
    "label": "Bebatu",
    "value": "Bebatu"
  },
  {
    "id": "6504022004",
    "district_id": "650402",
    "label": "Bandan Bikis",
    "value": "Bandan Bikis"
  },
  {
    "id": "6504022005",
    "district_id": "650402",
    "label": "Sepala Dalung",
    "value": "Sepala Dalung"
  },
  {
    "id": "6504022006",
    "district_id": "650402",
    "label": "Seludau",
    "value": "Seludau"
  },
  {
    "id": "6504022007",
    "district_id": "650402",
    "label": "Menjelutung",
    "value": "Menjelutung"
  },
  {
    "id": "6504022008",
    "district_id": "650402",
    "label": "Sesayap Selor",
    "value": "Sesayap Selor"
  },
  {
    "id": "6504032001",
    "district_id": "650403",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "6504032002",
    "district_id": "650403",
    "label": "Tengku Dacing",
    "value": "Tengku Dacing"
  },
  {
    "id": "6504032003",
    "district_id": "650403",
    "label": "Sambungan",
    "value": "Sambungan"
  },
  {
    "id": "6504032004",
    "district_id": "650403",
    "label": "Tanah Merah Barat",
    "value": "Tanah Merah Barat"
  },
  {
    "id": "6504032005",
    "district_id": "650403",
    "label": "Sambungan Selatan",
    "value": "Sambungan Selatan"
  },
  {
    "id": "6504042001",
    "district_id": "650404",
    "label": "Buong Baru",
    "value": "Buong Baru"
  },
  {
    "id": "6504042002",
    "district_id": "650404",
    "label": "Bebakung",
    "value": "Bebakung"
  },
  {
    "id": "6504042003",
    "district_id": "650404",
    "label": "Kujau",
    "value": "Kujau"
  },
  {
    "id": "6504042004",
    "district_id": "650404",
    "label": "Mendupo",
    "value": "Mendupo"
  },
  {
    "id": "6504042005",
    "district_id": "650404",
    "label": "Maning",
    "value": "Maning"
  },
  {
    "id": "6504042006",
    "district_id": "650404",
    "label": "Periuk",
    "value": "Periuk"
  },
  {
    "id": "6504052001",
    "district_id": "650405",
    "label": "Seputuk",
    "value": "Seputuk"
  },
  {
    "id": "6504052002",
    "district_id": "650405",
    "label": "Rian",
    "value": "Rian"
  },
  {
    "id": "6504052003",
    "district_id": "650405",
    "label": "Balayan Ari",
    "value": "Balayan Ari"
  },
  {
    "id": "6504052004",
    "district_id": "650405",
    "label": "Rian Rayo",
    "value": "Rian Rayo"
  },
  {
    "id": "6504052005",
    "district_id": "650405",
    "label": "Kapuak",
    "value": "Kapuak"
  },
  {
    "id": "6504052006",
    "district_id": "650405",
    "label": "Sapari",
    "value": "Sapari"
  },
  {
    "id": "6571011001",
    "district_id": "657101",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "6571011002",
    "district_id": "657101",
    "label": "Karang Rejo",
    "value": "Karang Rejo"
  },
  {
    "id": "6571011003",
    "district_id": "657101",
    "label": "Karang Anyar Pantai",
    "value": "Karang Anyar Pantai"
  },
  {
    "id": "6571011004",
    "district_id": "657101",
    "label": "Karang Balik",
    "value": "Karang Balik"
  },
  {
    "id": "6571011005",
    "district_id": "657101",
    "label": "Karang Harapan",
    "value": "Karang Harapan"
  },
  {
    "id": "6571021001",
    "district_id": "657102",
    "label": "Kampung I Skip",
    "value": "Kampung I Skip"
  },
  {
    "id": "6571021002",
    "district_id": "657102",
    "label": "Pamusian",
    "value": "Pamusian"
  },
  {
    "id": "6571021003",
    "district_id": "657102",
    "label": "Sebengkok",
    "value": "Sebengkok"
  },
  {
    "id": "6571021004",
    "district_id": "657102",
    "label": "Selumit",
    "value": "Selumit"
  },
  {
    "id": "6571021005",
    "district_id": "657102",
    "label": "Selumit Pantai",
    "value": "Selumit Pantai"
  },
  {
    "id": "6571031001",
    "district_id": "657103",
    "label": "Lingkas Ujung",
    "value": "Lingkas Ujung"
  },
  {
    "id": "6571031002",
    "district_id": "657103",
    "label": "Gunung Lingkas",
    "value": "Gunung Lingkas"
  },
  {
    "id": "6571031003",
    "district_id": "657103",
    "label": "Kampung Empat",
    "value": "Kampung Empat"
  },
  {
    "id": "6571031004",
    "district_id": "657103",
    "label": "Kampung Enam",
    "value": "Kampung Enam"
  },
  {
    "id": "6571031005",
    "district_id": "657103",
    "label": "Mamburungan",
    "value": "Mamburungan"
  },
  {
    "id": "6571031006",
    "district_id": "657103",
    "label": "Pantai Amal",
    "value": "Pantai Amal"
  },
  {
    "id": "6571031007",
    "district_id": "657103",
    "label": "Mamburungan Timur",
    "value": "Mamburungan Timur"
  },
  {
    "id": "6571041001",
    "district_id": "657104",
    "label": "Juata Laut",
    "value": "Juata Laut"
  },
  {
    "id": "6571041002",
    "district_id": "657104",
    "label": "Juata Permai",
    "value": "Juata Permai"
  },
  {
    "id": "6571041003",
    "district_id": "657104",
    "label": "Juata Kerikil",
    "value": "Juata Kerikil"
  },
  {
    "id": "7101052001",
    "district_id": "710105",
    "label": "Ayong",
    "value": "Ayong"
  },
  {
    "id": "7101052002",
    "district_id": "710105",
    "label": "Babo",
    "value": "Babo"
  },
  {
    "id": "7101052003",
    "district_id": "710105",
    "label": "Bolangat",
    "value": "Bolangat"
  },
  {
    "id": "7101052004",
    "district_id": "710105",
    "label": "Maelang",
    "value": "Maelang"
  },
  {
    "id": "7101052005",
    "district_id": "710105",
    "label": "Domisil Moonow",
    "value": "Domisil Moonow"
  },
  {
    "id": "7101052006",
    "district_id": "710105",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7101052012",
    "district_id": "710105",
    "label": "Lolanan",
    "value": "Lolanan"
  },
  {
    "id": "7101052015",
    "district_id": "710105",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "7101052017",
    "district_id": "710105",
    "label": "Batu Merah",
    "value": "Batu Merah"
  },
  {
    "id": "7101052018",
    "district_id": "710105",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7101052019",
    "district_id": "710105",
    "label": "Pangi Timur",
    "value": "Pangi Timur"
  },
  {
    "id": "7101052020",
    "district_id": "710105",
    "label": "Bolangat Timur",
    "value": "Bolangat Timur"
  },
  {
    "id": "7101092001",
    "district_id": "710109",
    "label": "Matayangan",
    "value": "Matayangan"
  },
  {
    "id": "7101092002",
    "district_id": "710109",
    "label": "Uuan",
    "value": "Uuan"
  },
  {
    "id": "7101092003",
    "district_id": "710109",
    "label": "Ikhwan",
    "value": "Ikhwan"
  },
  {
    "id": "7101092004",
    "district_id": "710109",
    "label": "Doloduo",
    "value": "Doloduo"
  },
  {
    "id": "7101092005",
    "district_id": "710109",
    "label": "Wangga Baru",
    "value": "Wangga Baru"
  },
  {
    "id": "7101092010",
    "district_id": "710109",
    "label": "Totaut",
    "value": "Totaut"
  },
  {
    "id": "7101092011",
    "district_id": "710109",
    "label": "Mekaruo",
    "value": "Mekaruo"
  },
  {
    "id": "7101092017",
    "district_id": "710109",
    "label": "Toraut Utara",
    "value": "Toraut Utara"
  },
  {
    "id": "7101092018",
    "district_id": "710109",
    "label": "Doloduo Satu",
    "value": "Doloduo Satu"
  },
  {
    "id": "7101092019",
    "district_id": "710109",
    "label": "Doloduo Dua",
    "value": "Doloduo Dua"
  },
  {
    "id": "7101092020",
    "district_id": "710109",
    "label": "Doloduo Tiga",
    "value": "Doloduo Tiga"
  },
  {
    "id": "7101092022",
    "district_id": "710109",
    "label": "Toraut Tengah",
    "value": "Toraut Tengah"
  },
  {
    "id": "7101101003",
    "district_id": "710110",
    "label": "Imandi",
    "value": "Imandi"
  },
  {
    "id": "7101102001",
    "district_id": "710110",
    "label": "Tonom",
    "value": "Tonom"
  },
  {
    "id": "7101102002",
    "district_id": "710110",
    "label": "Mogoyunggung",
    "value": "Mogoyunggung"
  },
  {
    "id": "7101102004",
    "district_id": "710110",
    "label": "Modomang",
    "value": "Modomang"
  },
  {
    "id": "7101102005",
    "district_id": "710110",
    "label": "Kembang Mertha",
    "value": "Kembang Mertha"
  },
  {
    "id": "7101102006",
    "district_id": "710110",
    "label": "Dumoga",
    "value": "Dumoga"
  },
  {
    "id": "7101102013",
    "district_id": "710110",
    "label": "Pinonobatuan",
    "value": "Pinonobatuan"
  },
  {
    "id": "7101102017",
    "district_id": "710110",
    "label": "Dumoga II",
    "value": "Dumoga II"
  },
  {
    "id": "7101102018",
    "district_id": "710110",
    "label": "Pinonobatuan Barat",
    "value": "Pinonobatuan Barat"
  },
  {
    "id": "7101102019",
    "district_id": "710110",
    "label": "Amertha Sari",
    "value": "Amertha Sari"
  },
  {
    "id": "7101102020",
    "district_id": "710110",
    "label": "Kembang Sari",
    "value": "Kembang Sari"
  },
  {
    "id": "7101102021",
    "district_id": "710110",
    "label": "Amertha Buana",
    "value": "Amertha Buana"
  },
  {
    "id": "7101102023",
    "district_id": "710110",
    "label": "Mogoyunggung Satu",
    "value": "Mogoyunggung Satu"
  },
  {
    "id": "7101102024",
    "district_id": "710110",
    "label": "Mogoyunggung Dua",
    "value": "Mogoyunggung Dua"
  },
  {
    "id": "7101102025",
    "district_id": "710110",
    "label": "Dumoga Tiga",
    "value": "Dumoga Tiga"
  },
  {
    "id": "7101102026",
    "district_id": "710110",
    "label": "Dumoga Empat",
    "value": "Dumoga Empat"
  },
  {
    "id": "7101112001",
    "district_id": "710111",
    "label": "Tumokang Baru",
    "value": "Tumokang Baru"
  },
  {
    "id": "7101112002",
    "district_id": "710111",
    "label": "Mopugad Utara",
    "value": "Mopugad Utara"
  },
  {
    "id": "7101112003",
    "district_id": "710111",
    "label": "Mopugad Selatan",
    "value": "Mopugad Selatan"
  },
  {
    "id": "7101112004",
    "district_id": "710111",
    "label": "Mopuya Utara",
    "value": "Mopuya Utara"
  },
  {
    "id": "7101112005",
    "district_id": "710111",
    "label": "Mopuya Selatan",
    "value": "Mopuya Selatan"
  },
  {
    "id": "7101112007",
    "district_id": "710111",
    "label": "Dondomon",
    "value": "Dondomon"
  },
  {
    "id": "7101112014",
    "district_id": "710111",
    "label": "Mopugad Utara Satu",
    "value": "Mopugad Utara Satu"
  },
  {
    "id": "7101112015",
    "district_id": "710111",
    "label": "Mopugad Utara Dua",
    "value": "Mopugad Utara Dua"
  },
  {
    "id": "7101112016",
    "district_id": "710111",
    "label": "Mopugad Selatan Satu",
    "value": "Mopugad Selatan Satu"
  },
  {
    "id": "7101112017",
    "district_id": "710111",
    "label": "Mopuya Utara Satu",
    "value": "Mopuya Utara Satu"
  },
  {
    "id": "7101112018",
    "district_id": "710111",
    "label": "Mopuya Utara Dua",
    "value": "Mopuya Utara Dua"
  },
  {
    "id": "7101112019",
    "district_id": "710111",
    "label": "Mopuya Selatan Satu",
    "value": "Mopuya Selatan Satu"
  },
  {
    "id": "7101112020",
    "district_id": "710111",
    "label": "Mopuya Selatan Dua",
    "value": "Mopuya Selatan Dua"
  },
  {
    "id": "7101112024",
    "district_id": "710111",
    "label": "Dondomon Utara",
    "value": "Dondomon Utara"
  },
  {
    "id": "7101112025",
    "district_id": "710111",
    "label": "Dondomon Selatan",
    "value": "Dondomon Selatan"
  },
  {
    "id": "7101112026",
    "district_id": "710111",
    "label": "Tumokang Timur",
    "value": "Tumokang Timur"
  },
  {
    "id": "7101122001",
    "district_id": "710112",
    "label": "Totabuan",
    "value": "Totabuan"
  },
  {
    "id": "7101122002",
    "district_id": "710112",
    "label": "Pindol",
    "value": "Pindol"
  },
  {
    "id": "7101122003",
    "district_id": "710112",
    "label": "Solog",
    "value": "Solog"
  },
  {
    "id": "7101122004",
    "district_id": "710112",
    "label": "Tandu",
    "value": "Tandu"
  },
  {
    "id": "7101122005",
    "district_id": "710112",
    "label": "Lolak",
    "value": "Lolak"
  },
  {
    "id": "7101122006",
    "district_id": "710112",
    "label": "Motabang",
    "value": "Motabang"
  },
  {
    "id": "7101122007",
    "district_id": "710112",
    "label": "Mongkoinit",
    "value": "Mongkoinit"
  },
  {
    "id": "7101122008",
    "district_id": "710112",
    "label": "Labuan Uki",
    "value": "Labuan Uki"
  },
  {
    "id": "7101122009",
    "district_id": "710112",
    "label": "Pinogaluman",
    "value": "Pinogaluman"
  },
  {
    "id": "7101122010",
    "district_id": "710112",
    "label": "Sauk",
    "value": "Sauk"
  },
  {
    "id": "7101122011",
    "district_id": "710112",
    "label": "Lalow",
    "value": "Lalow"
  },
  {
    "id": "7101122012",
    "district_id": "710112",
    "label": "Buntalo",
    "value": "Buntalo"
  },
  {
    "id": "7101122013",
    "district_id": "710112",
    "label": "Bumbung",
    "value": "Bumbung"
  },
  {
    "id": "7101122014",
    "district_id": "710112",
    "label": "Baturapa",
    "value": "Baturapa"
  },
  {
    "id": "7101122015",
    "district_id": "710112",
    "label": "Tuyat",
    "value": "Tuyat"
  },
  {
    "id": "7101122016",
    "district_id": "710112",
    "label": "Pindolili",
    "value": "Pindolili"
  },
  {
    "id": "7101122017",
    "district_id": "710112",
    "label": "Lolak Tambolango",
    "value": "Lolak Tambolango"
  },
  {
    "id": "7101122018",
    "district_id": "710112",
    "label": "Lolak II",
    "value": "Lolak II"
  },
  {
    "id": "7101122019",
    "district_id": "710112",
    "label": "Buntalo Timur",
    "value": "Buntalo Timur"
  },
  {
    "id": "7101122020",
    "district_id": "710112",
    "label": "Buntalo Selatan",
    "value": "Buntalo Selatan"
  },
  {
    "id": "7101122021",
    "district_id": "710112",
    "label": "Baturapa II",
    "value": "Baturapa II"
  },
  {
    "id": "7101122022",
    "district_id": "710112",
    "label": "Diat",
    "value": "Diat"
  },
  {
    "id": "7101122023",
    "district_id": "710112",
    "label": "Pinogalum Timur",
    "value": "Pinogalum Timur"
  },
  {
    "id": "7101122024",
    "district_id": "710112",
    "label": "Mongkoinit Barat",
    "value": "Mongkoinit Barat"
  },
  {
    "id": "7101122025",
    "district_id": "710112",
    "label": "Dulangan",
    "value": "Dulangan"
  },
  {
    "id": "7101122026",
    "district_id": "710112",
    "label": "Padang Lalow",
    "value": "Padang Lalow"
  },
  {
    "id": "7101131006",
    "district_id": "710113",
    "label": "Inobonto",
    "value": "Inobonto"
  },
  {
    "id": "7101132001",
    "district_id": "710113",
    "label": "Komangaan",
    "value": "Komangaan"
  },
  {
    "id": "7101132002",
    "district_id": "710113",
    "label": "Solimandungan II",
    "value": "Solimandungan II"
  },
  {
    "id": "7101132003",
    "district_id": "710113",
    "label": "Solimandungan I",
    "value": "Solimandungan I"
  },
  {
    "id": "7101132004",
    "district_id": "710113",
    "label": "Bangomulunow",
    "value": "Bangomulunow"
  },
  {
    "id": "7101132005",
    "district_id": "710113",
    "label": "Langagon",
    "value": "Langagon"
  },
  {
    "id": "7101132007",
    "district_id": "710113",
    "label": "Inobonto II",
    "value": "Inobonto II"
  },
  {
    "id": "7101132014",
    "district_id": "710113",
    "label": "Inobonto",
    "value": "Inobonto"
  },
  {
    "id": "7101132015",
    "district_id": "710113",
    "label": "Langagon Satu",
    "value": "Langagon Satu"
  },
  {
    "id": "7101132016",
    "district_id": "710113",
    "label": "Langagon Dua",
    "value": "Langagon Dua"
  },
  {
    "id": "7101132017",
    "district_id": "710113",
    "label": "Solimandungan Baru",
    "value": "Solimandungan Baru"
  },
  {
    "id": "7101142001",
    "district_id": "710114",
    "label": "Bombanon",
    "value": "Bombanon"
  },
  {
    "id": "7101142002",
    "district_id": "710114",
    "label": "Abak",
    "value": "Abak"
  },
  {
    "id": "7101142003",
    "district_id": "710114",
    "label": "Tapa Aog",
    "value": "Tapa Aog"
  },
  {
    "id": "7101142004",
    "district_id": "710114",
    "label": "Mopusi",
    "value": "Mopusi"
  },
  {
    "id": "7101142005",
    "district_id": "710114",
    "label": "Matali Baru",
    "value": "Matali Baru"
  },
  {
    "id": "7101142006",
    "district_id": "710114",
    "label": "Bakan",
    "value": "Bakan"
  },
  {
    "id": "7101142007",
    "district_id": "710114",
    "label": "Sanoyan Selatan",
    "value": "Sanoyan Selatan"
  },
  {
    "id": "7101142008",
    "district_id": "710114",
    "label": "Tungoi I",
    "value": "Tungoi I"
  },
  {
    "id": "7101142009",
    "district_id": "710114",
    "label": "Lolayan",
    "value": "Lolayan"
  },
  {
    "id": "7101142010",
    "district_id": "710114",
    "label": "Mopait",
    "value": "Mopait"
  },
  {
    "id": "7101142017",
    "district_id": "710114",
    "label": "Kopandakan II",
    "value": "Kopandakan II"
  },
  {
    "id": "7101142019",
    "district_id": "710114",
    "label": "Tanoyan Utara",
    "value": "Tanoyan Utara"
  },
  {
    "id": "7101142020",
    "district_id": "710114",
    "label": "Tungoi II",
    "value": "Tungoi II"
  },
  {
    "id": "7101142021",
    "district_id": "710114",
    "label": "Mengkang",
    "value": "Mengkang"
  },
  {
    "id": "7101192001",
    "district_id": "710119",
    "label": "Muntoi",
    "value": "Muntoi"
  },
  {
    "id": "7101192002",
    "district_id": "710119",
    "label": "Poyuyanan",
    "value": "Poyuyanan"
  },
  {
    "id": "7101192003",
    "district_id": "710119",
    "label": "Lobong",
    "value": "Lobong"
  },
  {
    "id": "7101192004",
    "district_id": "710119",
    "label": "Passi",
    "value": "Passi"
  },
  {
    "id": "7101192017",
    "district_id": "710119",
    "label": "Bintau",
    "value": "Bintau"
  },
  {
    "id": "7101192018",
    "district_id": "710119",
    "label": "Bulud",
    "value": "Bulud"
  },
  {
    "id": "7101192019",
    "district_id": "710119",
    "label": "Otam",
    "value": "Otam"
  },
  {
    "id": "7101192020",
    "district_id": "710119",
    "label": "Wangga",
    "value": "Wangga"
  },
  {
    "id": "7101192022",
    "district_id": "710119",
    "label": "Inuai",
    "value": "Inuai"
  },
  {
    "id": "7101192023",
    "district_id": "710119",
    "label": "Passi II",
    "value": "Passi II"
  },
  {
    "id": "7101192024",
    "district_id": "710119",
    "label": "Muntoi Timur",
    "value": "Muntoi Timur"
  },
  {
    "id": "7101192025",
    "district_id": "710119",
    "label": "Otam Barat",
    "value": "Otam Barat"
  },
  {
    "id": "7101192026",
    "district_id": "710119",
    "label": "Wangga Satu",
    "value": "Wangga Satu"
  },
  {
    "id": "7101202001",
    "district_id": "710120",
    "label": "Mariri Lama",
    "value": "Mariri Lama"
  },
  {
    "id": "7101202002",
    "district_id": "710120",
    "label": "Mariri Baru",
    "value": "Mariri Baru"
  },
  {
    "id": "7101202003",
    "district_id": "710120",
    "label": "Nonapan II",
    "value": "Nonapan II"
  },
  {
    "id": "7101202004",
    "district_id": "710120",
    "label": "Nonapan I",
    "value": "Nonapan I"
  },
  {
    "id": "7101202005",
    "district_id": "710120",
    "label": "Wineru",
    "value": "Wineru"
  },
  {
    "id": "7101202006",
    "district_id": "710120",
    "label": "Gogaluman",
    "value": "Gogaluman"
  },
  {
    "id": "7101202007",
    "district_id": "710120",
    "label": "Poigar I",
    "value": "Poigar I"
  },
  {
    "id": "7101202008",
    "district_id": "710120",
    "label": "Nanasi",
    "value": "Nanasi"
  },
  {
    "id": "7101202009",
    "district_id": "710120",
    "label": "Pomoman",
    "value": "Pomoman"
  },
  {
    "id": "7101202010",
    "district_id": "710120",
    "label": "Tiberias",
    "value": "Tiberias"
  },
  {
    "id": "7101202011",
    "district_id": "710120",
    "label": "Mondatong",
    "value": "Mondatong"
  },
  {
    "id": "7101202012",
    "district_id": "710120",
    "label": "Poigar II",
    "value": "Poigar II"
  },
  {
    "id": "7101202013",
    "district_id": "710120",
    "label": "Poigar III",
    "value": "Poigar III"
  },
  {
    "id": "7101202014",
    "district_id": "710120",
    "label": "Mariri II",
    "value": "Mariri II"
  },
  {
    "id": "7101202015",
    "district_id": "710120",
    "label": "Mariri I",
    "value": "Mariri I"
  },
  {
    "id": "7101202016",
    "district_id": "710120",
    "label": "Nonapan Baru",
    "value": "Nonapan Baru"
  },
  {
    "id": "7101202017",
    "district_id": "710120",
    "label": "Nanasi Timur",
    "value": "Nanasi Timur"
  },
  {
    "id": "7101202018",
    "district_id": "710120",
    "label": "Nonapan",
    "value": "Nonapan"
  },
  {
    "id": "7101202019",
    "district_id": "710120",
    "label": "Tanjung Mariri",
    "value": "Tanjung Mariri"
  },
  {
    "id": "7101202020",
    "district_id": "710120",
    "label": "Mondatong Baru",
    "value": "Mondatong Baru"
  },
  {
    "id": "7101222003",
    "district_id": "710122",
    "label": "Pangian",
    "value": "Pangian"
  },
  {
    "id": "7101222004",
    "district_id": "710122",
    "label": "Poopo",
    "value": "Poopo"
  },
  {
    "id": "7101222005",
    "district_id": "710122",
    "label": "Manembo",
    "value": "Manembo"
  },
  {
    "id": "7101222006",
    "district_id": "710122",
    "label": "Sinsingon",
    "value": "Sinsingon"
  },
  {
    "id": "7101222007",
    "district_id": "710122",
    "label": "Insil",
    "value": "Insil"
  },
  {
    "id": "7101222009",
    "district_id": "710122",
    "label": "Mobuya",
    "value": "Mobuya"
  },
  {
    "id": "7101222012",
    "district_id": "710122",
    "label": "Insil Baru",
    "value": "Insil Baru"
  },
  {
    "id": "7101222013",
    "district_id": "710122",
    "label": "Pangian Tengah",
    "value": "Pangian Tengah"
  },
  {
    "id": "7101222014",
    "district_id": "710122",
    "label": "Pangian Barat",
    "value": "Pangian Barat"
  },
  {
    "id": "7101222015",
    "district_id": "710122",
    "label": "Poopo Barat",
    "value": "Poopo Barat"
  },
  {
    "id": "7101222016",
    "district_id": "710122",
    "label": "Poopo Selatan",
    "value": "Poopo Selatan"
  },
  {
    "id": "7101222017",
    "district_id": "710122",
    "label": "Sinsingon Barat",
    "value": "Sinsingon Barat"
  },
  {
    "id": "7101222018",
    "district_id": "710122",
    "label": "Sinsingon Timur",
    "value": "Sinsingon Timur"
  },
  {
    "id": "7101312001",
    "district_id": "710131",
    "label": "Ambang I",
    "value": "Ambang I"
  },
  {
    "id": "7101312002",
    "district_id": "710131",
    "label": "Ambang II",
    "value": "Ambang II"
  },
  {
    "id": "7101312003",
    "district_id": "710131",
    "label": "Tadoy",
    "value": "Tadoy"
  },
  {
    "id": "7101312004",
    "district_id": "710131",
    "label": "Bolaang",
    "value": "Bolaang"
  },
  {
    "id": "7101312005",
    "district_id": "710131",
    "label": "Bantik",
    "value": "Bantik"
  },
  {
    "id": "7101312006",
    "district_id": "710131",
    "label": "Lolan",
    "value": "Lolan"
  },
  {
    "id": "7101312007",
    "district_id": "710131",
    "label": "Tadoy I",
    "value": "Tadoy I"
  },
  {
    "id": "7101312008",
    "district_id": "710131",
    "label": "Bolaang Satu",
    "value": "Bolaang Satu"
  },
  {
    "id": "7101312009",
    "district_id": "710131",
    "label": "Lolan Dua",
    "value": "Lolan Dua"
  },
  {
    "id": "7101322001",
    "district_id": "710132",
    "label": "Bilalang III",
    "value": "Bilalang III"
  },
  {
    "id": "7101322002",
    "district_id": "710132",
    "label": "Bilalang IV",
    "value": "Bilalang IV"
  },
  {
    "id": "7101322003",
    "district_id": "710132",
    "label": "Tudu Aog",
    "value": "Tudu Aog"
  },
  {
    "id": "7101322004",
    "district_id": "710132",
    "label": "Tudu Aog Baru",
    "value": "Tudu Aog Baru"
  },
  {
    "id": "7101322005",
    "district_id": "710132",
    "label": "Kolinganga'an",
    "value": "Kolinganga'an"
  },
  {
    "id": "7101322006",
    "district_id": "710132",
    "label": "Bilalang III Utara",
    "value": "Bilalang III Utara"
  },
  {
    "id": "7101322007",
    "district_id": "710132",
    "label": "Bilalang Baru",
    "value": "Bilalang Baru"
  },
  {
    "id": "7101322008",
    "district_id": "710132",
    "label": "Apado",
    "value": "Apado"
  },
  {
    "id": "7101332001",
    "district_id": "710133",
    "label": "Serasi",
    "value": "Serasi"
  },
  {
    "id": "7101332002",
    "district_id": "710133",
    "label": "Kanaan",
    "value": "Kanaan"
  },
  {
    "id": "7101332003",
    "district_id": "710133",
    "label": "Toruakat",
    "value": "Toruakat"
  },
  {
    "id": "7101332004",
    "district_id": "710133",
    "label": "Pusian",
    "value": "Pusian"
  },
  {
    "id": "7101332005",
    "district_id": "710133",
    "label": "Ponompiaan",
    "value": "Ponompiaan"
  },
  {
    "id": "7101332006",
    "district_id": "710133",
    "label": "Mototabian",
    "value": "Mototabian"
  },
  {
    "id": "7101332007",
    "district_id": "710133",
    "label": "Bumbungon",
    "value": "Bumbungon"
  },
  {
    "id": "7101332008",
    "district_id": "710133",
    "label": "Siniyung",
    "value": "Siniyung"
  },
  {
    "id": "7101332009",
    "district_id": "710133",
    "label": "Siniyung Satu",
    "value": "Siniyung Satu"
  },
  {
    "id": "7101332010",
    "district_id": "710133",
    "label": "Dumoga Satu",
    "value": "Dumoga Satu"
  },
  {
    "id": "7101332011",
    "district_id": "710133",
    "label": "Pusian Selatan",
    "value": "Pusian Selatan"
  },
  {
    "id": "7101332012",
    "district_id": "710133",
    "label": "Pusian Barat",
    "value": "Pusian Barat"
  },
  {
    "id": "7101342001",
    "district_id": "710134",
    "label": "Bonawang",
    "value": "Bonawang"
  },
  {
    "id": "7101342002",
    "district_id": "710134",
    "label": "Tapadaka Satu",
    "value": "Tapadaka Satu"
  },
  {
    "id": "7101342003",
    "district_id": "710134",
    "label": "Tapadaka Utara",
    "value": "Tapadaka Utara"
  },
  {
    "id": "7101342004",
    "district_id": "710134",
    "label": "Tapadaka Timur",
    "value": "Tapadaka Timur"
  },
  {
    "id": "7101342005",
    "district_id": "710134",
    "label": "Konarom",
    "value": "Konarom"
  },
  {
    "id": "7101342006",
    "district_id": "710134",
    "label": "Konarom Barat",
    "value": "Konarom Barat"
  },
  {
    "id": "7101342007",
    "district_id": "710134",
    "label": "Konarom Utara",
    "value": "Konarom Utara"
  },
  {
    "id": "7101342008",
    "district_id": "710134",
    "label": "Osion",
    "value": "Osion"
  },
  {
    "id": "7101342009",
    "district_id": "710134",
    "label": "Ikuna",
    "value": "Ikuna"
  },
  {
    "id": "7101342010",
    "district_id": "710134",
    "label": "Dumara",
    "value": "Dumara"
  },
  {
    "id": "7101352001",
    "district_id": "710135",
    "label": "Ibolian",
    "value": "Ibolian"
  },
  {
    "id": "7101352002",
    "district_id": "710135",
    "label": "Ibolian Satu",
    "value": "Ibolian Satu"
  },
  {
    "id": "7101352003",
    "district_id": "710135",
    "label": "Kinomalingan",
    "value": "Kinomalingan"
  },
  {
    "id": "7101352004",
    "district_id": "710135",
    "label": "Kosio",
    "value": "Kosio"
  },
  {
    "id": "7101352005",
    "district_id": "710135",
    "label": "Kosio Timur",
    "value": "Kosio Timur"
  },
  {
    "id": "7101352006",
    "district_id": "710135",
    "label": "Kasio Barat",
    "value": "Kasio Barat"
  },
  {
    "id": "7101352007",
    "district_id": "710135",
    "label": "Werdhi Agung",
    "value": "Werdhi Agung"
  },
  {
    "id": "7101352008",
    "district_id": "710135",
    "label": "Werdhi Agung Selatan",
    "value": "Werdhi Agung Selatan"
  },
  {
    "id": "7101352009",
    "district_id": "710135",
    "label": "Werdhi Agung Utara",
    "value": "Werdhi Agung Utara"
  },
  {
    "id": "7101352010",
    "district_id": "710135",
    "label": "Werdhi Agung Timur",
    "value": "Werdhi Agung Timur"
  },
  {
    "id": "7102011007",
    "district_id": "710201",
    "label": "Masarang",
    "value": "Masarang"
  },
  {
    "id": "7102011008",
    "district_id": "710201",
    "label": "Rinegetan",
    "value": "Rinegetan"
  },
  {
    "id": "7102011009",
    "district_id": "710201",
    "label": "Roong",
    "value": "Roong"
  },
  {
    "id": "7102011010",
    "district_id": "710201",
    "label": "Tuutu",
    "value": "Tuutu"
  },
  {
    "id": "7102011011",
    "district_id": "710201",
    "label": "Tounkuramber",
    "value": "Tounkuramber"
  },
  {
    "id": "7102011012",
    "district_id": "710201",
    "label": "Wawalintouan",
    "value": "Wawalintouan"
  },
  {
    "id": "7102011013",
    "district_id": "710201",
    "label": "Rerewokan",
    "value": "Rerewokan"
  },
  {
    "id": "7102011014",
    "district_id": "710201",
    "label": "Watulambot",
    "value": "Watulambot"
  },
  {
    "id": "7102011015",
    "district_id": "710201",
    "label": "Wewelan",
    "value": "Wewelan"
  },
  {
    "id": "7102021001",
    "district_id": "710202",
    "label": "Luaan",
    "value": "Luaan"
  },
  {
    "id": "7102021005",
    "district_id": "710202",
    "label": "Ranowangko",
    "value": "Ranowangko"
  },
  {
    "id": "7102021006",
    "district_id": "710202",
    "label": "Wengkol",
    "value": "Wengkol"
  },
  {
    "id": "7102021007",
    "district_id": "710202",
    "label": "Kendis",
    "value": "Kendis"
  },
  {
    "id": "7102021008",
    "district_id": "710202",
    "label": "Katinggolan",
    "value": "Katinggolan"
  },
  {
    "id": "7102021009",
    "district_id": "710202",
    "label": "Liningaan",
    "value": "Liningaan"
  },
  {
    "id": "7102021010",
    "district_id": "710202",
    "label": "Taler",
    "value": "Taler"
  },
  {
    "id": "7102021011",
    "district_id": "710202",
    "label": "Kiniar",
    "value": "Kiniar"
  },
  {
    "id": "7102021012",
    "district_id": "710202",
    "label": "Touluor",
    "value": "Touluor"
  },
  {
    "id": "7102021013",
    "district_id": "710202",
    "label": "Papakelan",
    "value": "Papakelan"
  },
  {
    "id": "7102021014",
    "district_id": "710202",
    "label": "Makalounsow",
    "value": "Makalounsow"
  },
  {
    "id": "7102032001",
    "district_id": "710203",
    "label": "Telap",
    "value": "Telap"
  },
  {
    "id": "7102032002",
    "district_id": "710203",
    "label": "Watumea",
    "value": "Watumea"
  },
  {
    "id": "7102032003",
    "district_id": "710203",
    "label": "Eris",
    "value": "Eris"
  },
  {
    "id": "7102032004",
    "district_id": "710203",
    "label": "Maumbi",
    "value": "Maumbi"
  },
  {
    "id": "7102032005",
    "district_id": "710203",
    "label": "Tandengan",
    "value": "Tandengan"
  },
  {
    "id": "7102032006",
    "district_id": "710203",
    "label": "Ranomerut",
    "value": "Ranomerut"
  },
  {
    "id": "7102032007",
    "district_id": "710203",
    "label": "Touliang Oki",
    "value": "Touliang Oki"
  },
  {
    "id": "7102032008",
    "district_id": "710203",
    "label": "Tandengan Satu",
    "value": "Tandengan Satu"
  },
  {
    "id": "7102042001",
    "district_id": "710204",
    "label": "Tulap",
    "value": "Tulap"
  },
  {
    "id": "7102042002",
    "district_id": "710204",
    "label": "Lalumpe",
    "value": "Lalumpe"
  },
  {
    "id": "7102042003",
    "district_id": "710204",
    "label": "Kayubesi",
    "value": "Kayubesi"
  },
  {
    "id": "7102042004",
    "district_id": "710204",
    "label": "Ranowangko II",
    "value": "Ranowangko II"
  },
  {
    "id": "7102042005",
    "district_id": "710204",
    "label": "Kombi",
    "value": "Kombi"
  },
  {
    "id": "7102042006",
    "district_id": "710204",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "7102042007",
    "district_id": "710204",
    "label": "Kolongan",
    "value": "Kolongan"
  },
  {
    "id": "7102042008",
    "district_id": "710204",
    "label": "Rerer",
    "value": "Rerer"
  },
  {
    "id": "7102042009",
    "district_id": "710204",
    "label": "Kinaleosan",
    "value": "Kinaleosan"
  },
  {
    "id": "7102042010",
    "district_id": "710204",
    "label": "Makalisung",
    "value": "Makalisung"
  },
  {
    "id": "7102042011",
    "district_id": "710204",
    "label": "Kalawiran",
    "value": "Kalawiran"
  },
  {
    "id": "7102042012",
    "district_id": "710204",
    "label": "Kolongan I",
    "value": "Kolongan I"
  },
  {
    "id": "7102042013",
    "district_id": "710204",
    "label": "Rerer I",
    "value": "Rerer I"
  },
  {
    "id": "7102052001",
    "district_id": "710205",
    "label": "Kapataran",
    "value": "Kapataran"
  },
  {
    "id": "7102052002",
    "district_id": "710205",
    "label": "Seretan",
    "value": "Seretan"
  },
  {
    "id": "7102052003",
    "district_id": "710205",
    "label": "Atep Oki",
    "value": "Atep Oki"
  },
  {
    "id": "7102052004",
    "district_id": "710205",
    "label": "Karor",
    "value": "Karor"
  },
  {
    "id": "7102052005",
    "district_id": "710205",
    "label": "Kaleosan",
    "value": "Kaleosan"
  },
  {
    "id": "7102052006",
    "district_id": "710205",
    "label": "Watulaney",
    "value": "Watulaney"
  },
  {
    "id": "7102052007",
    "district_id": "710205",
    "label": "Kayuroya",
    "value": "Kayuroya"
  },
  {
    "id": "7102052008",
    "district_id": "710205",
    "label": "Seretan Timur",
    "value": "Seretan Timur"
  },
  {
    "id": "7102052009",
    "district_id": "710205",
    "label": "Parentek",
    "value": "Parentek"
  },
  {
    "id": "7102052010",
    "district_id": "710205",
    "label": "Kapataran Satu",
    "value": "Kapataran Satu"
  },
  {
    "id": "7102052011",
    "district_id": "710205",
    "label": "Watulaney Amian",
    "value": "Watulaney Amian"
  },
  {
    "id": "7102062003",
    "district_id": "710206",
    "label": "Kayuwatu",
    "value": "Kayuwatu"
  },
  {
    "id": "7102062004",
    "district_id": "710206",
    "label": "Wineru",
    "value": "Wineru"
  },
  {
    "id": "7102062005",
    "district_id": "710206",
    "label": "Rinondor",
    "value": "Rinondor"
  },
  {
    "id": "7102062006",
    "district_id": "710206",
    "label": "Sendangan",
    "value": "Sendangan"
  },
  {
    "id": "7102062015",
    "district_id": "710206",
    "label": "Pahaleten",
    "value": "Pahaleten"
  },
  {
    "id": "7102062016",
    "district_id": "710206",
    "label": "Talikuran",
    "value": "Talikuran"
  },
  {
    "id": "7102062017",
    "district_id": "710206",
    "label": "Tounelet",
    "value": "Tounelet"
  },
  {
    "id": "7102062018",
    "district_id": "710206",
    "label": "Paslaten",
    "value": "Paslaten"
  },
  {
    "id": "7102062019",
    "district_id": "710206",
    "label": "Kaweng",
    "value": "Kaweng"
  },
  {
    "id": "7102062020",
    "district_id": "710206",
    "label": "Toulimembet",
    "value": "Toulimembet"
  },
  {
    "id": "7102062021",
    "district_id": "710206",
    "label": "Makalelon",
    "value": "Makalelon"
  },
  {
    "id": "7102062022",
    "district_id": "710206",
    "label": "Tumpaan",
    "value": "Tumpaan"
  },
  {
    "id": "7102062023",
    "district_id": "710206",
    "label": "Mahembang",
    "value": "Mahembang"
  },
  {
    "id": "7102072004",
    "district_id": "710207",
    "label": "Tember",
    "value": "Tember"
  },
  {
    "id": "7102072005",
    "district_id": "710207",
    "label": "Kamanga",
    "value": "Kamanga"
  },
  {
    "id": "7102072006",
    "district_id": "710207",
    "label": "Sendangan",
    "value": "Sendangan"
  },
  {
    "id": "7102072007",
    "district_id": "710207",
    "label": "Talikuran",
    "value": "Talikuran"
  },
  {
    "id": "7102072009",
    "district_id": "710207",
    "label": "Tempok",
    "value": "Tempok"
  },
  {
    "id": "7102072010",
    "district_id": "710207",
    "label": "Liba",
    "value": "Liba"
  },
  {
    "id": "7102072011",
    "district_id": "710207",
    "label": "Tolok",
    "value": "Tolok"
  },
  {
    "id": "7102072012",
    "district_id": "710207",
    "label": "Kamanga Dua",
    "value": "Kamanga Dua"
  },
  {
    "id": "7102072016",
    "district_id": "710207",
    "label": "Tempok Selatan",
    "value": "Tempok Selatan"
  },
  {
    "id": "7102072017",
    "district_id": "710207",
    "label": "Tolok Satu",
    "value": "Tolok Satu"
  },
  {
    "id": "7102082001",
    "district_id": "710208",
    "label": "Kasuratan",
    "value": "Kasuratan"
  },
  {
    "id": "7102082002",
    "district_id": "710208",
    "label": "Parepei",
    "value": "Parepei"
  },
  {
    "id": "7102082003",
    "district_id": "710208",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "7102082004",
    "district_id": "710208",
    "label": "Sinuian",
    "value": "Sinuian"
  },
  {
    "id": "7102082005",
    "district_id": "710208",
    "label": "Kaima",
    "value": "Kaima"
  },
  {
    "id": "7102082006",
    "district_id": "710208",
    "label": "Sendangan",
    "value": "Sendangan"
  },
  {
    "id": "7102082007",
    "district_id": "710208",
    "label": "Timu",
    "value": "Timu"
  },
  {
    "id": "7102082008",
    "district_id": "710208",
    "label": "Talikuran",
    "value": "Talikuran"
  },
  {
    "id": "7102082009",
    "district_id": "710208",
    "label": "Tampusu",
    "value": "Tampusu"
  },
  {
    "id": "7102082010",
    "district_id": "710208",
    "label": "Paslaten",
    "value": "Paslaten"
  },
  {
    "id": "7102082011",
    "district_id": "710208",
    "label": "Leleko",
    "value": "Leleko"
  },
  {
    "id": "7102092004",
    "district_id": "710209",
    "label": "Wolaang",
    "value": "Wolaang"
  },
  {
    "id": "7102092005",
    "district_id": "710209",
    "label": "Teep",
    "value": "Teep"
  },
  {
    "id": "7102092006",
    "district_id": "710209",
    "label": "Karondoran",
    "value": "Karondoran"
  },
  {
    "id": "7102092007",
    "district_id": "710209",
    "label": "Waleure",
    "value": "Waleure"
  },
  {
    "id": "7102092008",
    "district_id": "710209",
    "label": "Amongena I",
    "value": "Amongena I"
  },
  {
    "id": "7102092009",
    "district_id": "710209",
    "label": "Amongena II",
    "value": "Amongena II"
  },
  {
    "id": "7102092010",
    "district_id": "710209",
    "label": "Sumarayar",
    "value": "Sumarayar"
  },
  {
    "id": "7102092018",
    "district_id": "710209",
    "label": "Amongena Tiga",
    "value": "Amongena Tiga"
  },
  {
    "id": "7102102001",
    "district_id": "710210",
    "label": "Koyawas",
    "value": "Koyawas"
  },
  {
    "id": "7102102002",
    "district_id": "710210",
    "label": "Walewangko",
    "value": "Walewangko"
  },
  {
    "id": "7102102003",
    "district_id": "710210",
    "label": "Noongan",
    "value": "Noongan"
  },
  {
    "id": "7102102004",
    "district_id": "710210",
    "label": "Raringis",
    "value": "Raringis"
  },
  {
    "id": "7102102005",
    "district_id": "710210",
    "label": "Ampreng",
    "value": "Ampreng"
  },
  {
    "id": "7102102006",
    "district_id": "710210",
    "label": "Tumaratas",
    "value": "Tumaratas"
  },
  {
    "id": "7102102009",
    "district_id": "710210",
    "label": "Paslaten",
    "value": "Paslaten"
  },
  {
    "id": "7102102010",
    "district_id": "710210",
    "label": "Lowian",
    "value": "Lowian"
  },
  {
    "id": "7102102011",
    "district_id": "710210",
    "label": "Tounelet",
    "value": "Tounelet"
  },
  {
    "id": "7102102014",
    "district_id": "710210",
    "label": "Kopiwangker",
    "value": "Kopiwangker"
  },
  {
    "id": "7102102015",
    "district_id": "710210",
    "label": "Noongan Dua",
    "value": "Noongan Dua"
  },
  {
    "id": "7102102016",
    "district_id": "710210",
    "label": "Noongan Tiga",
    "value": "Noongan Tiga"
  },
  {
    "id": "7102102017",
    "district_id": "710210",
    "label": "Tumaratas Dua",
    "value": "Tumaratas Dua"
  },
  {
    "id": "7102102018",
    "district_id": "710210",
    "label": "Raranon",
    "value": "Raranon"
  },
  {
    "id": "7102102019",
    "district_id": "710210",
    "label": "Raringis Utara",
    "value": "Raringis Utara"
  },
  {
    "id": "7102102020",
    "district_id": "710210",
    "label": "Raringis Selatan",
    "value": "Raringis Selatan"
  },
  {
    "id": "7102112001",
    "district_id": "710211",
    "label": "Leilem",
    "value": "Leilem"
  },
  {
    "id": "7102112002",
    "district_id": "710211",
    "label": "Kolongan Atas",
    "value": "Kolongan Atas"
  },
  {
    "id": "7102112003",
    "district_id": "710211",
    "label": "Tounelet Satu",
    "value": "Tounelet Satu"
  },
  {
    "id": "7102112004",
    "district_id": "710211",
    "label": "Talikuran",
    "value": "Talikuran"
  },
  {
    "id": "7102112005",
    "district_id": "710211",
    "label": "Kauneran",
    "value": "Kauneran"
  },
  {
    "id": "7102112006",
    "district_id": "710211",
    "label": "Sendangan",
    "value": "Sendangan"
  },
  {
    "id": "7102112007",
    "district_id": "710211",
    "label": "Rambunan",
    "value": "Rambunan"
  },
  {
    "id": "7102112008",
    "district_id": "710211",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "7102112009",
    "district_id": "710211",
    "label": "Tincep",
    "value": "Tincep"
  },
  {
    "id": "7102112010",
    "district_id": "710211",
    "label": "Timbukar",
    "value": "Timbukar"
  },
  {
    "id": "7102112011",
    "district_id": "710211",
    "label": "Leilem Dua",
    "value": "Leilem Dua"
  },
  {
    "id": "7102112012",
    "district_id": "710211",
    "label": "Leilem Tiga",
    "value": "Leilem Tiga"
  },
  {
    "id": "7102112013",
    "district_id": "710211",
    "label": "Kolongan Atas Dua",
    "value": "Kolongan Atas Dua"
  },
  {
    "id": "7102112014",
    "district_id": "710211",
    "label": "Kauneran Satu",
    "value": "Kauneran Satu"
  },
  {
    "id": "7102112015",
    "district_id": "710211",
    "label": "Rambunan Amian",
    "value": "Rambunan Amian"
  },
  {
    "id": "7102112016",
    "district_id": "710211",
    "label": "Sandangan Satu",
    "value": "Sandangan Satu"
  },
  {
    "id": "7102112017",
    "district_id": "710211",
    "label": "Talikuran Satu",
    "value": "Talikuran Satu"
  },
  {
    "id": "7102112018",
    "district_id": "710211",
    "label": "Tounelet",
    "value": "Tounelet"
  },
  {
    "id": "7102112019",
    "district_id": "710211",
    "label": "Kotongan Atas Satu",
    "value": "Kotongan Atas Satu"
  },
  {
    "id": "7102121005",
    "district_id": "710212",
    "label": "Sendangan",
    "value": "Sendangan"
  },
  {
    "id": "7102121011",
    "district_id": "710212",
    "label": "Kinali",
    "value": "Kinali"
  },
  {
    "id": "7102121019",
    "district_id": "710212",
    "label": "Sendangan Selatan",
    "value": "Sendangan Selatan"
  },
  {
    "id": "7102121020",
    "district_id": "710212",
    "label": "Sendangan Tengah",
    "value": "Sendangan Tengah"
  },
  {
    "id": "7102121028",
    "district_id": "710212",
    "label": "Uner Satu",
    "value": "Uner Satu"
  },
  {
    "id": "7102121029",
    "district_id": "710212",
    "label": "Kinali Satu",
    "value": "Kinali Satu"
  },
  {
    "id": "7102122012",
    "district_id": "710212",
    "label": "Tondegesan",
    "value": "Tondegesan"
  },
  {
    "id": "7102122016",
    "district_id": "710212",
    "label": "Kanonang Tiga",
    "value": "Kanonang Tiga"
  },
  {
    "id": "7102122030",
    "district_id": "710212",
    "label": "Tondegesan I",
    "value": "Tondegesan I"
  },
  {
    "id": "7102122031",
    "district_id": "710212",
    "label": "Tondegesan II",
    "value": "Tondegesan II"
  },
  {
    "id": "7102132001",
    "district_id": "710213",
    "label": "Pineleng I",
    "value": "Pineleng I"
  },
  {
    "id": "7102132002",
    "district_id": "710213",
    "label": "Pineleng II",
    "value": "Pineleng II"
  },
  {
    "id": "7102132003",
    "district_id": "710213",
    "label": "Sea I",
    "value": "Sea I"
  },
  {
    "id": "7102132004",
    "district_id": "710213",
    "label": "Sea II",
    "value": "Sea II"
  },
  {
    "id": "7102132005",
    "district_id": "710213",
    "label": "Winagun Atas",
    "value": "Winagun Atas"
  },
  {
    "id": "7102132006",
    "district_id": "710213",
    "label": "Warembungan",
    "value": "Warembungan"
  },
  {
    "id": "7102132007",
    "district_id": "710213",
    "label": "Sea",
    "value": "Sea"
  },
  {
    "id": "7102132014",
    "district_id": "710213",
    "label": "Kali",
    "value": "Kali"
  },
  {
    "id": "7102132016",
    "district_id": "710213",
    "label": "Kali Selatan",
    "value": "Kali Selatan"
  },
  {
    "id": "7102132020",
    "district_id": "710213",
    "label": "Pineleng Dua Indah",
    "value": "Pineleng Dua Indah"
  },
  {
    "id": "7102132021",
    "district_id": "710213",
    "label": "Lotta",
    "value": "Lotta"
  },
  {
    "id": "7102132022",
    "district_id": "710213",
    "label": "Sea Mitra",
    "value": "Sea Mitra"
  },
  {
    "id": "7102132023",
    "district_id": "710213",
    "label": "Sea Tumpengan",
    "value": "Sea Tumpengan"
  },
  {
    "id": "7102132024",
    "district_id": "710213",
    "label": "Pineleng Satu Timur",
    "value": "Pineleng Satu Timur"
  },
  {
    "id": "7102142001",
    "district_id": "710214",
    "label": "Kembes II",
    "value": "Kembes II"
  },
  {
    "id": "7102142002",
    "district_id": "710214",
    "label": "Kembes I",
    "value": "Kembes I"
  },
  {
    "id": "7102142003",
    "district_id": "710214",
    "label": "Toumbuluan",
    "value": "Toumbuluan"
  },
  {
    "id": "7102142004",
    "district_id": "710214",
    "label": "Koka",
    "value": "Koka"
  },
  {
    "id": "7102142005",
    "district_id": "710214",
    "label": "Suluan",
    "value": "Suluan"
  },
  {
    "id": "7102142006",
    "district_id": "710214",
    "label": "Kamangta",
    "value": "Kamangta"
  },
  {
    "id": "7102142007",
    "district_id": "710214",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "7102142008",
    "district_id": "710214",
    "label": "Rumengkor",
    "value": "Rumengkor"
  },
  {
    "id": "7102142009",
    "district_id": "710214",
    "label": "Tikela",
    "value": "Tikela"
  },
  {
    "id": "7102142010",
    "district_id": "710214",
    "label": "Rumengkor Satu",
    "value": "Rumengkor Satu"
  },
  {
    "id": "7102142011",
    "district_id": "710214",
    "label": "Rumengkor Dua",
    "value": "Rumengkor Dua"
  },
  {
    "id": "7102152001",
    "district_id": "710215",
    "label": "Kumu",
    "value": "Kumu"
  },
  {
    "id": "7102152002",
    "district_id": "710215",
    "label": "Teling",
    "value": "Teling"
  },
  {
    "id": "7102152003",
    "district_id": "710215",
    "label": "Poopoh",
    "value": "Poopoh"
  },
  {
    "id": "7102152004",
    "district_id": "710215",
    "label": "Ranowangko",
    "value": "Ranowangko"
  },
  {
    "id": "7102152005",
    "district_id": "710215",
    "label": "Senduk",
    "value": "Senduk"
  },
  {
    "id": "7102152007",
    "district_id": "710215",
    "label": "Pinasungkulan",
    "value": "Pinasungkulan"
  },
  {
    "id": "7102152009",
    "district_id": "710215",
    "label": "Mokupa",
    "value": "Mokupa"
  },
  {
    "id": "7102152010",
    "district_id": "710215",
    "label": "Sarani Matani",
    "value": "Sarani Matani"
  },
  {
    "id": "7102152011",
    "district_id": "710215",
    "label": "Borgo",
    "value": "Borgo"
  },
  {
    "id": "7102152012",
    "district_id": "710215",
    "label": "Tambala",
    "value": "Tambala"
  },
  {
    "id": "7102161001",
    "district_id": "710216",
    "label": "Sumalangka",
    "value": "Sumalangka"
  },
  {
    "id": "7102161002",
    "district_id": "710216",
    "label": "Sasaran",
    "value": "Sasaran"
  },
  {
    "id": "7102161003",
    "district_id": "710216",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "7102161004",
    "district_id": "710216",
    "label": "Wulauan",
    "value": "Wulauan"
  },
  {
    "id": "7102161005",
    "district_id": "710216",
    "label": "Marawas",
    "value": "Marawas"
  },
  {
    "id": "7102162006",
    "district_id": "710216",
    "label": "Tonsea Lama",
    "value": "Tonsea Lama"
  },
  {
    "id": "7102162007",
    "district_id": "710216",
    "label": "Kembuan",
    "value": "Kembuan"
  },
  {
    "id": "7102162008",
    "district_id": "710216",
    "label": "Kembuan Satu",
    "value": "Kembuan Satu"
  },
  {
    "id": "7102172001",
    "district_id": "710217",
    "label": "Palamba",
    "value": "Palamba"
  },
  {
    "id": "7102172002",
    "district_id": "710217",
    "label": "Atep",
    "value": "Atep"
  },
  {
    "id": "7102172003",
    "district_id": "710217",
    "label": "Manembo",
    "value": "Manembo"
  },
  {
    "id": "7102172004",
    "district_id": "710217",
    "label": "Temboan",
    "value": "Temboan"
  },
  {
    "id": "7102172005",
    "district_id": "710217",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "7102172006",
    "district_id": "710217",
    "label": "Winebetan",
    "value": "Winebetan"
  },
  {
    "id": "7102172007",
    "district_id": "710217",
    "label": "Kaayuran Atas",
    "value": "Kaayuran Atas"
  },
  {
    "id": "7102172008",
    "district_id": "710217",
    "label": "Kaayuran Bawah",
    "value": "Kaayuran Bawah"
  },
  {
    "id": "7102172009",
    "district_id": "710217",
    "label": "Kawatak",
    "value": "Kawatak"
  },
  {
    "id": "7102172010",
    "district_id": "710217",
    "label": "Atep Satu",
    "value": "Atep Satu"
  },
  {
    "id": "7102181001",
    "district_id": "710218",
    "label": "Urongo",
    "value": "Urongo"
  },
  {
    "id": "7102181002",
    "district_id": "710218",
    "label": "Tounsaru",
    "value": "Tounsaru"
  },
  {
    "id": "7102181003",
    "district_id": "710218",
    "label": "Tataaran II",
    "value": "Tataaran II"
  },
  {
    "id": "7102181004",
    "district_id": "710218",
    "label": "Tataaran I",
    "value": "Tataaran I"
  },
  {
    "id": "7102181005",
    "district_id": "710218",
    "label": "Koya",
    "value": "Koya"
  },
  {
    "id": "7102181006",
    "district_id": "710218",
    "label": "Paleloan",
    "value": "Paleloan"
  },
  {
    "id": "7102181007",
    "district_id": "710218",
    "label": "Tataaran Patar",
    "value": "Tataaran Patar"
  },
  {
    "id": "7102181008",
    "district_id": "710218",
    "label": "Maesa Unima",
    "value": "Maesa Unima"
  },
  {
    "id": "7102192001",
    "district_id": "710219",
    "label": "Walantakan",
    "value": "Walantakan"
  },
  {
    "id": "7102192002",
    "district_id": "710219",
    "label": "Taraitak",
    "value": "Taraitak"
  },
  {
    "id": "7102192003",
    "district_id": "710219",
    "label": "Karumenga",
    "value": "Karumenga"
  },
  {
    "id": "7102192004",
    "district_id": "710219",
    "label": "Toraget",
    "value": "Toraget"
  },
  {
    "id": "7102192005",
    "district_id": "710219",
    "label": "Tempang",
    "value": "Tempang"
  },
  {
    "id": "7102192006",
    "district_id": "710219",
    "label": "Tempang II",
    "value": "Tempang II"
  },
  {
    "id": "7102192007",
    "district_id": "710219",
    "label": "Tempang III",
    "value": "Tempang III"
  },
  {
    "id": "7102192008",
    "district_id": "710219",
    "label": "Taraitak Satu",
    "value": "Taraitak Satu"
  },
  {
    "id": "7102202001",
    "district_id": "710220",
    "label": "Wasian",
    "value": "Wasian"
  },
  {
    "id": "7102202002",
    "district_id": "710220",
    "label": "Panasen",
    "value": "Panasen"
  },
  {
    "id": "7102202003",
    "district_id": "710220",
    "label": "Tountimomor",
    "value": "Tountimomor"
  },
  {
    "id": "7102202004",
    "district_id": "710220",
    "label": "Totolan",
    "value": "Totolan"
  },
  {
    "id": "7102202005",
    "district_id": "710220",
    "label": "Passo",
    "value": "Passo"
  },
  {
    "id": "7102202006",
    "district_id": "710220",
    "label": "Kalawiran",
    "value": "Kalawiran"
  },
  {
    "id": "7102202007",
    "district_id": "710220",
    "label": "Touliang",
    "value": "Touliang"
  },
  {
    "id": "7102202008",
    "district_id": "710220",
    "label": "Simbel",
    "value": "Simbel"
  },
  {
    "id": "7102202009",
    "district_id": "710220",
    "label": "Wailang",
    "value": "Wailang"
  },
  {
    "id": "7102202010",
    "district_id": "710220",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "7102211001",
    "district_id": "710221",
    "label": "Talikuran",
    "value": "Talikuran"
  },
  {
    "id": "7102211002",
    "district_id": "710221",
    "label": "Talikuran Utara",
    "value": "Talikuran Utara"
  },
  {
    "id": "7102211003",
    "district_id": "710221",
    "label": "Talikuran Barat",
    "value": "Talikuran Barat"
  },
  {
    "id": "7102211004",
    "district_id": "710221",
    "label": "Uner",
    "value": "Uner"
  },
  {
    "id": "7102212005",
    "district_id": "710221",
    "label": "Kiawa Satu",
    "value": "Kiawa Satu"
  },
  {
    "id": "7102212006",
    "district_id": "710221",
    "label": "Kiawa Satu Utara",
    "value": "Kiawa Satu Utara"
  },
  {
    "id": "7102212007",
    "district_id": "710221",
    "label": "Kiawa Satu Barat",
    "value": "Kiawa Satu Barat"
  },
  {
    "id": "7102212008",
    "district_id": "710221",
    "label": "Kiawa Dua",
    "value": "Kiawa Dua"
  },
  {
    "id": "7102212009",
    "district_id": "710221",
    "label": "Kiawa Dua Timur",
    "value": "Kiawa Dua Timur"
  },
  {
    "id": "7102212010",
    "district_id": "710221",
    "label": "Kiawa Dua Barat",
    "value": "Kiawa Dua Barat"
  },
  {
    "id": "7102222001",
    "district_id": "710222",
    "label": "Kayuuwi",
    "value": "Kayuuwi"
  },
  {
    "id": "7102222002",
    "district_id": "710222",
    "label": "Kayuuwi Satu",
    "value": "Kayuuwi Satu"
  },
  {
    "id": "7102222003",
    "district_id": "710222",
    "label": "Kanonang Satu",
    "value": "Kanonang Satu"
  },
  {
    "id": "7102222004",
    "district_id": "710222",
    "label": "Kanonang Dua",
    "value": "Kanonang Dua"
  },
  {
    "id": "7102222005",
    "district_id": "710222",
    "label": "Kanonang Empat",
    "value": "Kanonang Empat"
  },
  {
    "id": "7102222006",
    "district_id": "710222",
    "label": "Kanonang Lima",
    "value": "Kanonang Lima"
  },
  {
    "id": "7102222007",
    "district_id": "710222",
    "label": "Tombasian Atas",
    "value": "Tombasian Atas"
  },
  {
    "id": "7102222008",
    "district_id": "710222",
    "label": "Tombasian Atas Satu",
    "value": "Tombasian Atas Satu"
  },
  {
    "id": "7102222009",
    "district_id": "710222",
    "label": "Tombasian Bawah",
    "value": "Tombasian Bawah"
  },
  {
    "id": "7102222010",
    "district_id": "710222",
    "label": "Ranolambot",
    "value": "Ranolambot"
  },
  {
    "id": "7102232001",
    "district_id": "710223",
    "label": "Kalasey Satu",
    "value": "Kalasey Satu"
  },
  {
    "id": "7102232002",
    "district_id": "710223",
    "label": "Kalasey Dua",
    "value": "Kalasey Dua"
  },
  {
    "id": "7102232003",
    "district_id": "710223",
    "label": "Tateli",
    "value": "Tateli"
  },
  {
    "id": "7102232004",
    "district_id": "710223",
    "label": "Tateli Weru",
    "value": "Tateli Weru"
  },
  {
    "id": "7102232005",
    "district_id": "710223",
    "label": "Tateli Satu",
    "value": "Tateli Satu"
  },
  {
    "id": "7102232006",
    "district_id": "710223",
    "label": "Tateli Dua",
    "value": "Tateli Dua"
  },
  {
    "id": "7102232007",
    "district_id": "710223",
    "label": "Tateli Tiga",
    "value": "Tateli Tiga"
  },
  {
    "id": "7102232008",
    "district_id": "710223",
    "label": "Koha",
    "value": "Koha"
  },
  {
    "id": "7102232009",
    "district_id": "710223",
    "label": "Koha Barat",
    "value": "Koha Barat"
  },
  {
    "id": "7102232010",
    "district_id": "710223",
    "label": "Koha Timur",
    "value": "Koha Timur"
  },
  {
    "id": "7102232011",
    "district_id": "710223",
    "label": "Koha Selatan",
    "value": "Koha Selatan"
  },
  {
    "id": "7102232012",
    "district_id": "710223",
    "label": "Agotey",
    "value": "Agotey"
  },
  {
    "id": "7102242001",
    "district_id": "710224",
    "label": "Lemoh",
    "value": "Lemoh"
  },
  {
    "id": "7102242002",
    "district_id": "710224",
    "label": "Lemoh Barat",
    "value": "Lemoh Barat"
  },
  {
    "id": "7102242003",
    "district_id": "710224",
    "label": "Lemoh Timur",
    "value": "Lemoh Timur"
  },
  {
    "id": "7102242004",
    "district_id": "710224",
    "label": "Lemoh Uner",
    "value": "Lemoh Uner"
  },
  {
    "id": "7102242005",
    "district_id": "710224",
    "label": "Lolah",
    "value": "Lolah"
  },
  {
    "id": "7102242006",
    "district_id": "710224",
    "label": "Lolah Satu",
    "value": "Lolah Satu"
  },
  {
    "id": "7102242007",
    "district_id": "710224",
    "label": "Lolah Dua",
    "value": "Lolah Dua"
  },
  {
    "id": "7102242008",
    "district_id": "710224",
    "label": "Lolah Tiga",
    "value": "Lolah Tiga"
  },
  {
    "id": "7102242009",
    "district_id": "710224",
    "label": "Ranotongkor",
    "value": "Ranotongkor"
  },
  {
    "id": "7102242010",
    "district_id": "710224",
    "label": "Ranotongkor Timur",
    "value": "Ranotongkor Timur"
  },
  {
    "id": "7102252001",
    "district_id": "710225",
    "label": "Penaesaan",
    "value": "Penaesaan"
  },
  {
    "id": "7102252002",
    "district_id": "710225",
    "label": "Tompaso Dua",
    "value": "Tompaso Dua"
  },
  {
    "id": "7102252003",
    "district_id": "710225",
    "label": "Tompaso Dua Utara",
    "value": "Tompaso Dua Utara"
  },
  {
    "id": "7102252004",
    "district_id": "710225",
    "label": "Pinabetengan Utara",
    "value": "Pinabetengan Utara"
  },
  {
    "id": "7102252005",
    "district_id": "710225",
    "label": "Pinabetengan",
    "value": "Pinabetengan"
  },
  {
    "id": "7102252006",
    "district_id": "710225",
    "label": "Pinabetengan Selatan",
    "value": "Pinabetengan Selatan"
  },
  {
    "id": "7102252007",
    "district_id": "710225",
    "label": "Tonsewer",
    "value": "Tonsewer"
  },
  {
    "id": "7102252008",
    "district_id": "710225",
    "label": "Tonsewer Selatan",
    "value": "Tonsewer Selatan"
  },
  {
    "id": "7102252009",
    "district_id": "710225",
    "label": "Touure",
    "value": "Touure"
  },
  {
    "id": "7102252010",
    "district_id": "710225",
    "label": "Touure Dua",
    "value": "Touure Dua"
  },
  {
    "id": "7103082001",
    "district_id": "710308",
    "label": "Kalasuge",
    "value": "Kalasuge"
  },
  {
    "id": "7103082002",
    "district_id": "710308",
    "label": "Bahu",
    "value": "Bahu"
  },
  {
    "id": "7103082003",
    "district_id": "710308",
    "label": "Mala",
    "value": "Mala"
  },
  {
    "id": "7103082004",
    "district_id": "710308",
    "label": "Kalekube",
    "value": "Kalekube"
  },
  {
    "id": "7103082005",
    "district_id": "710308",
    "label": "Naha",
    "value": "Naha"
  },
  {
    "id": "7103082006",
    "district_id": "710308",
    "label": "Beha",
    "value": "Beha"
  },
  {
    "id": "7103082007",
    "district_id": "710308",
    "label": "Utaurano",
    "value": "Utaurano"
  },
  {
    "id": "7103082008",
    "district_id": "710308",
    "label": "Lenganeng",
    "value": "Lenganeng"
  },
  {
    "id": "7103082009",
    "district_id": "710308",
    "label": "Tarolang",
    "value": "Tarolang"
  },
  {
    "id": "7103082010",
    "district_id": "710308",
    "label": "Tola",
    "value": "Tola"
  },
  {
    "id": "7103082011",
    "district_id": "710308",
    "label": "Kalurae",
    "value": "Kalurae"
  },
  {
    "id": "7103082012",
    "district_id": "710308",
    "label": "Bengketang",
    "value": "Bengketang"
  },
  {
    "id": "7103082013",
    "district_id": "710308",
    "label": "Petta",
    "value": "Petta"
  },
  {
    "id": "7103082014",
    "district_id": "710308",
    "label": "Bowongkulu",
    "value": "Bowongkulu"
  },
  {
    "id": "7103082016",
    "district_id": "710308",
    "label": "Pusunge",
    "value": "Pusunge"
  },
  {
    "id": "7103082017",
    "district_id": "710308",
    "label": "Moade",
    "value": "Moade"
  },
  {
    "id": "7103082019",
    "district_id": "710308",
    "label": "Raku",
    "value": "Raku"
  },
  {
    "id": "7103082020",
    "district_id": "710308",
    "label": "Petta Timur",
    "value": "Petta Timur"
  },
  {
    "id": "7103082021",
    "district_id": "710308",
    "label": "Petta Barat",
    "value": "Petta Barat"
  },
  {
    "id": "7103082022",
    "district_id": "710308",
    "label": "Petta Selatan",
    "value": "Petta Selatan"
  },
  {
    "id": "7103082023",
    "district_id": "710308",
    "label": "Likuang",
    "value": "Likuang"
  },
  {
    "id": "7103082024",
    "district_id": "710308",
    "label": "Kalekube I",
    "value": "Kalekube I"
  },
  {
    "id": "7103082025",
    "district_id": "710308",
    "label": "Bowongkulu I",
    "value": "Bowongkulu I"
  },
  {
    "id": "7103082026",
    "district_id": "710308",
    "label": "Naha I",
    "value": "Naha I"
  },
  {
    "id": "7103092001",
    "district_id": "710309",
    "label": "Nanedakele",
    "value": "Nanedakele"
  },
  {
    "id": "7103092002",
    "district_id": "710309",
    "label": "Nusa",
    "value": "Nusa"
  },
  {
    "id": "7103092003",
    "district_id": "710309",
    "label": "Bukide",
    "value": "Bukide"
  },
  {
    "id": "7103092004",
    "district_id": "710309",
    "label": "Bukide Timur",
    "value": "Bukide Timur"
  },
  {
    "id": "7103092005",
    "district_id": "710309",
    "label": "Nanusa",
    "value": "Nanusa"
  },
  {
    "id": "7103102001",
    "district_id": "710310",
    "label": "Kaluwatu",
    "value": "Kaluwatu"
  },
  {
    "id": "7103102002",
    "district_id": "710310",
    "label": "Laine",
    "value": "Laine"
  },
  {
    "id": "7103102003",
    "district_id": "710310",
    "label": "Lapango",
    "value": "Lapango"
  },
  {
    "id": "7103102004",
    "district_id": "710310",
    "label": "Sowaeng",
    "value": "Sowaeng"
  },
  {
    "id": "7103102005",
    "district_id": "710310",
    "label": "Ngalipaeng I",
    "value": "Ngalipaeng I"
  },
  {
    "id": "7103102006",
    "district_id": "710310",
    "label": "Ngalipaeng II",
    "value": "Ngalipaeng II"
  },
  {
    "id": "7103102007",
    "district_id": "710310",
    "label": "Batunderang",
    "value": "Batunderang"
  },
  {
    "id": "7103102008",
    "district_id": "710310",
    "label": "Bebalang",
    "value": "Bebalang"
  },
  {
    "id": "7103102009",
    "district_id": "710310",
    "label": "Mawira",
    "value": "Mawira"
  },
  {
    "id": "7103102010",
    "district_id": "710310",
    "label": "Pindang",
    "value": "Pindang"
  },
  {
    "id": "7103102011",
    "district_id": "710310",
    "label": "Lapepahe",
    "value": "Lapepahe"
  },
  {
    "id": "7103102012",
    "district_id": "710310",
    "label": "Lehimi Tariang",
    "value": "Lehimi Tariang"
  },
  {
    "id": "7103102013",
    "district_id": "710310",
    "label": "Lapango I",
    "value": "Lapango I"
  },
  {
    "id": "7103112001",
    "district_id": "710311",
    "label": "Kalama",
    "value": "Kalama"
  },
  {
    "id": "7103112002",
    "district_id": "710311",
    "label": "Kahakitang",
    "value": "Kahakitang"
  },
  {
    "id": "7103112003",
    "district_id": "710311",
    "label": "Mahengetang",
    "value": "Mahengetang"
  },
  {
    "id": "7103112004",
    "district_id": "710311",
    "label": "Para",
    "value": "Para"
  },
  {
    "id": "7103112005",
    "district_id": "710311",
    "label": "Dalako Bembanehe",
    "value": "Dalako Bembanehe"
  },
  {
    "id": "7103112006",
    "district_id": "710311",
    "label": "Taleko Batusaiki",
    "value": "Taleko Batusaiki"
  },
  {
    "id": "7103112007",
    "district_id": "710311",
    "label": "Para I",
    "value": "Para I"
  },
  {
    "id": "7103122001",
    "district_id": "710312",
    "label": "Nagha I",
    "value": "Nagha I"
  },
  {
    "id": "7103122002",
    "district_id": "710312",
    "label": "Nagha II",
    "value": "Nagha II"
  },
  {
    "id": "7103122003",
    "district_id": "710312",
    "label": "Pokol",
    "value": "Pokol"
  },
  {
    "id": "7103122004",
    "district_id": "710312",
    "label": "Balane",
    "value": "Balane"
  },
  {
    "id": "7103122005",
    "district_id": "710312",
    "label": "Binala",
    "value": "Binala"
  },
  {
    "id": "7103122006",
    "district_id": "710312",
    "label": "Ulung Peliang",
    "value": "Ulung Peliang"
  },
  {
    "id": "7103122007",
    "district_id": "710312",
    "label": "Menggawa",
    "value": "Menggawa"
  },
  {
    "id": "7103122008",
    "district_id": "710312",
    "label": "Kalinda",
    "value": "Kalinda"
  },
  {
    "id": "7103122009",
    "district_id": "710312",
    "label": "Bebu",
    "value": "Bebu"
  },
  {
    "id": "7103122010",
    "district_id": "710312",
    "label": "Makalekuhe",
    "value": "Makalekuhe"
  },
  {
    "id": "7103122011",
    "district_id": "710312",
    "label": "Pananaru",
    "value": "Pananaru"
  },
  {
    "id": "7103122012",
    "district_id": "710312",
    "label": "D a g h o",
    "value": "D a g h o"
  },
  {
    "id": "7103122013",
    "district_id": "710312",
    "label": "Mahumu",
    "value": "Mahumu"
  },
  {
    "id": "7103122014",
    "district_id": "710312",
    "label": "Lelipang",
    "value": "Lelipang"
  },
  {
    "id": "7103122015",
    "district_id": "710312",
    "label": "Menggawa II",
    "value": "Menggawa II"
  },
  {
    "id": "7103122016",
    "district_id": "710312",
    "label": "Kalama Darat",
    "value": "Kalama Darat"
  },
  {
    "id": "7103122017",
    "district_id": "710312",
    "label": "Kalinda I",
    "value": "Kalinda I"
  },
  {
    "id": "7103122018",
    "district_id": "710312",
    "label": "Mahumu I",
    "value": "Mahumu I"
  },
  {
    "id": "7103122019",
    "district_id": "710312",
    "label": "Mahumu II",
    "value": "Mahumu II"
  },
  {
    "id": "7103122020",
    "district_id": "710312",
    "label": "Hesang",
    "value": "Hesang"
  },
  {
    "id": "7103132001",
    "district_id": "710313",
    "label": "Tawoali",
    "value": "Tawoali"
  },
  {
    "id": "7103132002",
    "district_id": "710313",
    "label": "Barangka",
    "value": "Barangka"
  },
  {
    "id": "7103132003",
    "district_id": "710313",
    "label": "Nahepese",
    "value": "Nahepese"
  },
  {
    "id": "7103132004",
    "district_id": "710313",
    "label": "Manumpitaeng",
    "value": "Manumpitaeng"
  },
  {
    "id": "7103132005",
    "district_id": "710313",
    "label": "Taloarane",
    "value": "Taloarane"
  },
  {
    "id": "7103132006",
    "district_id": "710313",
    "label": "Mala",
    "value": "Mala"
  },
  {
    "id": "7103132007",
    "district_id": "710313",
    "label": "Karatung I",
    "value": "Karatung I"
  },
  {
    "id": "7103132008",
    "district_id": "710313",
    "label": "Karatung II",
    "value": "Karatung II"
  },
  {
    "id": "7103132009",
    "district_id": "710313",
    "label": "Kauhis",
    "value": "Kauhis"
  },
  {
    "id": "7103132010",
    "district_id": "710313",
    "label": "Sesiwung",
    "value": "Sesiwung"
  },
  {
    "id": "7103132011",
    "district_id": "710313",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "7103132012",
    "district_id": "710313",
    "label": "Barangkalang",
    "value": "Barangkalang"
  },
  {
    "id": "7103132013",
    "district_id": "710313",
    "label": "Belengang",
    "value": "Belengang"
  },
  {
    "id": "7103132014",
    "district_id": "710313",
    "label": "Bakalaeng",
    "value": "Bakalaeng"
  },
  {
    "id": "7103132015",
    "district_id": "710313",
    "label": "Hiung",
    "value": "Hiung"
  },
  {
    "id": "7103132016",
    "district_id": "710313",
    "label": "Pinebentengang",
    "value": "Pinebentengang"
  },
  {
    "id": "7103132017",
    "district_id": "710313",
    "label": "Taloarane I",
    "value": "Taloarane I"
  },
  {
    "id": "7103132018",
    "district_id": "710313",
    "label": "Bengka",
    "value": "Bengka"
  },
  {
    "id": "7103142001",
    "district_id": "710314",
    "label": "Bowongkali",
    "value": "Bowongkali"
  },
  {
    "id": "7103142002",
    "district_id": "710314",
    "label": "Kulur I",
    "value": "Kulur I"
  },
  {
    "id": "7103142003",
    "district_id": "710314",
    "label": "Kulur II",
    "value": "Kulur II"
  },
  {
    "id": "7103142004",
    "district_id": "710314",
    "label": "Bira",
    "value": "Bira"
  },
  {
    "id": "7103142005",
    "district_id": "710314",
    "label": "Kuma",
    "value": "Kuma"
  },
  {
    "id": "7103142006",
    "district_id": "710314",
    "label": "Bungalawang",
    "value": "Bungalawang"
  },
  {
    "id": "7103142007",
    "district_id": "710314",
    "label": "Miulu",
    "value": "Miulu"
  },
  {
    "id": "7103142008",
    "district_id": "710314",
    "label": "Gunung",
    "value": "Gunung"
  },
  {
    "id": "7103142009",
    "district_id": "710314",
    "label": "Talengen",
    "value": "Talengen"
  },
  {
    "id": "7103142010",
    "district_id": "710314",
    "label": "Biru",
    "value": "Biru"
  },
  {
    "id": "7103142011",
    "district_id": "710314",
    "label": "Tariang Baru",
    "value": "Tariang Baru"
  },
  {
    "id": "7103142012",
    "district_id": "710314",
    "label": "Sensong",
    "value": "Sensong"
  },
  {
    "id": "7103142013",
    "district_id": "710314",
    "label": "Rendingan",
    "value": "Rendingan"
  },
  {
    "id": "7103142014",
    "district_id": "710314",
    "label": "Palahanaeng",
    "value": "Palahanaeng"
  },
  {
    "id": "7103142015",
    "district_id": "710314",
    "label": "Timbelang",
    "value": "Timbelang"
  },
  {
    "id": "7103142016",
    "district_id": "710314",
    "label": "Pelelangen",
    "value": "Pelelangen"
  },
  {
    "id": "7103142017",
    "district_id": "710314",
    "label": "Malueng",
    "value": "Malueng"
  },
  {
    "id": "7103142018",
    "district_id": "710314",
    "label": "Kuma I",
    "value": "Kuma I"
  },
  {
    "id": "7103152001",
    "district_id": "710315",
    "label": "Simueng",
    "value": "Simueng"
  },
  {
    "id": "7103152002",
    "district_id": "710315",
    "label": "Bentung",
    "value": "Bentung"
  },
  {
    "id": "7103152003",
    "district_id": "710315",
    "label": "Lesabe",
    "value": "Lesabe"
  },
  {
    "id": "7103152004",
    "district_id": "710315",
    "label": "Malammenggu",
    "value": "Malammenggu"
  },
  {
    "id": "7103152005",
    "district_id": "710315",
    "label": "Palareng",
    "value": "Palareng"
  },
  {
    "id": "7103152007",
    "district_id": "710315",
    "label": "Binebas",
    "value": "Binebas"
  },
  {
    "id": "7103152008",
    "district_id": "710315",
    "label": "Mandoi",
    "value": "Mandoi"
  },
  {
    "id": "7103152015",
    "district_id": "710315",
    "label": "Batuwingkung",
    "value": "Batuwingkung"
  },
  {
    "id": "7103152016",
    "district_id": "710315",
    "label": "Birahi",
    "value": "Birahi"
  },
  {
    "id": "7103152017",
    "district_id": "710315",
    "label": "Laotongan",
    "value": "Laotongan"
  },
  {
    "id": "7103152018",
    "district_id": "710315",
    "label": "Bukide",
    "value": "Bukide"
  },
  {
    "id": "7103152019",
    "district_id": "710315",
    "label": "Kalagheng",
    "value": "Kalagheng"
  },
  {
    "id": "7103152020",
    "district_id": "710315",
    "label": "Lesabe I",
    "value": "Lesabe I"
  },
  {
    "id": "7103152021",
    "district_id": "710315",
    "label": "Bulo",
    "value": "Bulo"
  },
  {
    "id": "7103162001",
    "district_id": "710316",
    "label": "Kendahe I",
    "value": "Kendahe I"
  },
  {
    "id": "7103162002",
    "district_id": "710316",
    "label": "Kendahe II",
    "value": "Kendahe II"
  },
  {
    "id": "7103162003",
    "district_id": "710316",
    "label": "Talawid",
    "value": "Talawid"
  },
  {
    "id": "7103162004",
    "district_id": "710316",
    "label": "Tariang Lama",
    "value": "Tariang Lama"
  },
  {
    "id": "7103162005",
    "district_id": "710316",
    "label": "Pempalaraeng",
    "value": "Pempalaraeng"
  },
  {
    "id": "7103162006",
    "district_id": "710316",
    "label": "Mohong Sawang",
    "value": "Mohong Sawang"
  },
  {
    "id": "7103162007",
    "district_id": "710316",
    "label": "Lipang",
    "value": "Lipang"
  },
  {
    "id": "7103162008",
    "district_id": "710316",
    "label": "Kawaluso",
    "value": "Kawaluso"
  },
  {
    "id": "7103171006",
    "district_id": "710317",
    "label": "Sawang Bendar",
    "value": "Sawang Bendar"
  },
  {
    "id": "7103171007",
    "district_id": "710317",
    "label": "Soataloara I",
    "value": "Soataloara I"
  },
  {
    "id": "7103171008",
    "district_id": "710317",
    "label": "Apengsembeka",
    "value": "Apengsembeka"
  },
  {
    "id": "7103171009",
    "district_id": "710317",
    "label": "Mahena",
    "value": "Mahena"
  },
  {
    "id": "7103171015",
    "district_id": "710317",
    "label": "Bungalawang",
    "value": "Bungalawang"
  },
  {
    "id": "7103171016",
    "district_id": "710317",
    "label": "Santiago",
    "value": "Santiago"
  },
  {
    "id": "7103171017",
    "district_id": "710317",
    "label": "Manente",
    "value": "Manente"
  },
  {
    "id": "7103171018",
    "district_id": "710317",
    "label": "Soataloara II",
    "value": "Soataloara II"
  },
  {
    "id": "7103192001",
    "district_id": "710319",
    "label": "Hangke",
    "value": "Hangke"
  },
  {
    "id": "7103192002",
    "district_id": "710319",
    "label": "Salurang",
    "value": "Salurang"
  },
  {
    "id": "7103192003",
    "district_id": "710319",
    "label": "Tambung",
    "value": "Tambung"
  },
  {
    "id": "7103192004",
    "district_id": "710319",
    "label": "Beeng",
    "value": "Beeng"
  },
  {
    "id": "7103192005",
    "district_id": "710319",
    "label": "Lehupu",
    "value": "Lehupu"
  },
  {
    "id": "7103192006",
    "district_id": "710319",
    "label": "Bowone",
    "value": "Bowone"
  },
  {
    "id": "7103192007",
    "district_id": "710319",
    "label": "Beeng Laut",
    "value": "Beeng Laut"
  },
  {
    "id": "7103192008",
    "district_id": "710319",
    "label": "Tenda",
    "value": "Tenda"
  },
  {
    "id": "7103192009",
    "district_id": "710319",
    "label": "Aha Patung",
    "value": "Aha Patung"
  },
  {
    "id": "7103202001",
    "district_id": "710320",
    "label": "Pintareng",
    "value": "Pintareng"
  },
  {
    "id": "7103202002",
    "district_id": "710320",
    "label": "Basauh",
    "value": "Basauh"
  },
  {
    "id": "7103202003",
    "district_id": "710320",
    "label": "Tumalede",
    "value": "Tumalede"
  },
  {
    "id": "7103202004",
    "district_id": "710320",
    "label": "Sampakang",
    "value": "Sampakang"
  },
  {
    "id": "7103202005",
    "district_id": "710320",
    "label": "Dalokaweng",
    "value": "Dalokaweng"
  },
  {
    "id": "7103202006",
    "district_id": "710320",
    "label": "Malisade",
    "value": "Malisade"
  },
  {
    "id": "7103231001",
    "district_id": "710323",
    "label": "Pananekeng",
    "value": "Pananekeng"
  },
  {
    "id": "7103231002",
    "district_id": "710323",
    "label": "Angges",
    "value": "Angges"
  },
  {
    "id": "7103231003",
    "district_id": "710323",
    "label": "Kolongan Mitung",
    "value": "Kolongan Mitung"
  },
  {
    "id": "7103231004",
    "district_id": "710323",
    "label": "Kolongan Beha",
    "value": "Kolongan Beha"
  },
  {
    "id": "7103231005",
    "district_id": "710323",
    "label": "Kolongan Beha Baru",
    "value": "Kolongan Beha Baru"
  },
  {
    "id": "7103231006",
    "district_id": "710323",
    "label": "Kolongan Akembawi",
    "value": "Kolongan Akembawi"
  },
  {
    "id": "7103241001",
    "district_id": "710324",
    "label": "Lesa",
    "value": "Lesa"
  },
  {
    "id": "7103241002",
    "district_id": "710324",
    "label": "Enengpahembang",
    "value": "Enengpahembang"
  },
  {
    "id": "7103241003",
    "district_id": "710324",
    "label": "Tapuang",
    "value": "Tapuang"
  },
  {
    "id": "7103241004",
    "district_id": "710324",
    "label": "Tidore",
    "value": "Tidore"
  },
  {
    "id": "7103241005",
    "district_id": "710324",
    "label": "Tona I",
    "value": "Tona I"
  },
  {
    "id": "7103241006",
    "district_id": "710324",
    "label": "Tona II",
    "value": "Tona II"
  },
  {
    "id": "7103241007",
    "district_id": "710324",
    "label": "Dumuhung",
    "value": "Dumuhung"
  },
  {
    "id": "7103241008",
    "district_id": "710324",
    "label": "Batulewehe",
    "value": "Batulewehe"
  },
  {
    "id": "7103252001",
    "district_id": "710325",
    "label": "Marore",
    "value": "Marore"
  },
  {
    "id": "7103252002",
    "district_id": "710325",
    "label": "Kawio",
    "value": "Kawio"
  },
  {
    "id": "7103252003",
    "district_id": "710325",
    "label": "Matutuang",
    "value": "Matutuang"
  },
  {
    "id": "7104011005",
    "district_id": "710401",
    "label": "Lirung",
    "value": "Lirung"
  },
  {
    "id": "7104011011",
    "district_id": "710401",
    "label": "Lirung Satu",
    "value": "Lirung Satu"
  },
  {
    "id": "7104011024",
    "district_id": "710401",
    "label": "Lirung Matane",
    "value": "Lirung Matane"
  },
  {
    "id": "7104012006",
    "district_id": "710401",
    "label": "Sereh",
    "value": "Sereh"
  },
  {
    "id": "7104012007",
    "district_id": "710401",
    "label": "Musi",
    "value": "Musi"
  },
  {
    "id": "7104012015",
    "district_id": "710401",
    "label": "Talolang",
    "value": "Talolang"
  },
  {
    "id": "7104012017",
    "district_id": "710401",
    "label": "Sereh I",
    "value": "Sereh I"
  },
  {
    "id": "7104021005",
    "district_id": "710402",
    "label": "Beo",
    "value": "Beo"
  },
  {
    "id": "7104021017",
    "district_id": "710402",
    "label": "Beo Timur",
    "value": "Beo Timur"
  },
  {
    "id": "7104021018",
    "district_id": "710402",
    "label": "Beo Barat",
    "value": "Beo Barat"
  },
  {
    "id": "7104022011",
    "district_id": "710402",
    "label": "Bantik",
    "value": "Bantik"
  },
  {
    "id": "7104022019",
    "district_id": "710402",
    "label": "Bengel",
    "value": "Bengel"
  },
  {
    "id": "7104022020",
    "district_id": "710402",
    "label": "Bantik Lama",
    "value": "Bantik Lama"
  },
  {
    "id": "7104032003",
    "district_id": "710403",
    "label": "Alo",
    "value": "Alo"
  },
  {
    "id": "7104032004",
    "district_id": "710403",
    "label": "Nunu",
    "value": "Nunu"
  },
  {
    "id": "7104032005",
    "district_id": "710403",
    "label": "Rainis",
    "value": "Rainis"
  },
  {
    "id": "7104032006",
    "district_id": "710403",
    "label": "Bantane",
    "value": "Bantane"
  },
  {
    "id": "7104032007",
    "district_id": "710403",
    "label": "Tabang",
    "value": "Tabang"
  },
  {
    "id": "7104032014",
    "district_id": "710403",
    "label": "Parangen",
    "value": "Parangen"
  },
  {
    "id": "7104032015",
    "district_id": "710403",
    "label": "Bantane Utara",
    "value": "Bantane Utara"
  },
  {
    "id": "7104032018",
    "district_id": "710403",
    "label": "Tabang Barat",
    "value": "Tabang Barat"
  },
  {
    "id": "7104032020",
    "district_id": "710403",
    "label": "Nunu Utara",
    "value": "Nunu Utara"
  },
  {
    "id": "7104032021",
    "district_id": "710403",
    "label": "Rainis Batupenga",
    "value": "Rainis Batupenga"
  },
  {
    "id": "7104032022",
    "district_id": "710403",
    "label": "Alo Utara",
    "value": "Alo Utara"
  },
  {
    "id": "7104042006",
    "district_id": "710404",
    "label": "Essang",
    "value": "Essang"
  },
  {
    "id": "7104042007",
    "district_id": "710404",
    "label": "Lalue",
    "value": "Lalue"
  },
  {
    "id": "7104042008",
    "district_id": "710404",
    "label": "Bulude",
    "value": "Bulude"
  },
  {
    "id": "7104042016",
    "district_id": "710404",
    "label": "Maririk",
    "value": "Maririk"
  },
  {
    "id": "7104042018",
    "district_id": "710404",
    "label": "Essang Selatan",
    "value": "Essang Selatan"
  },
  {
    "id": "7104042019",
    "district_id": "710404",
    "label": "Bulude Selatan",
    "value": "Bulude Selatan"
  },
  {
    "id": "7104042020",
    "district_id": "710404",
    "label": "Lalue Tengah",
    "value": "Lalue Tengah"
  },
  {
    "id": "7104042021",
    "district_id": "710404",
    "label": "Lalue Utara",
    "value": "Lalue Utara"
  },
  {
    "id": "7104052001",
    "district_id": "710405",
    "label": "Kakorotan",
    "value": "Kakorotan"
  },
  {
    "id": "7104052002",
    "district_id": "710405",
    "label": "Marampit",
    "value": "Marampit"
  },
  {
    "id": "7104052003",
    "district_id": "710405",
    "label": "Laluhe",
    "value": "Laluhe"
  },
  {
    "id": "7104052004",
    "district_id": "710405",
    "label": "Dampulis",
    "value": "Dampulis"
  },
  {
    "id": "7104052005",
    "district_id": "710405",
    "label": "Karatung",
    "value": "Karatung"
  },
  {
    "id": "7104052007",
    "district_id": "710405",
    "label": "Karatung Tengah",
    "value": "Karatung Tengah"
  },
  {
    "id": "7104052008",
    "district_id": "710405",
    "label": "Karatung Selatan",
    "value": "Karatung Selatan"
  },
  {
    "id": "7104052009",
    "district_id": "710405",
    "label": "Dampulis Selatan",
    "value": "Dampulis Selatan"
  },
  {
    "id": "7104052010",
    "district_id": "710405",
    "label": "Marampit Timur",
    "value": "Marampit Timur"
  },
  {
    "id": "7104062001",
    "district_id": "710406",
    "label": "Pangeran",
    "value": "Pangeran"
  },
  {
    "id": "7104062007",
    "district_id": "710406",
    "label": "Bulude",
    "value": "Bulude"
  },
  {
    "id": "7104062008",
    "district_id": "710406",
    "label": "Pantuge",
    "value": "Pantuge"
  },
  {
    "id": "7104062009",
    "district_id": "710406",
    "label": "Kabaruan",
    "value": "Kabaruan"
  },
  {
    "id": "7104062010",
    "district_id": "710406",
    "label": "Mangaran",
    "value": "Mangaran"
  },
  {
    "id": "7104062011",
    "district_id": "710406",
    "label": "Kordakel",
    "value": "Kordakel"
  },
  {
    "id": "7104062012",
    "district_id": "710406",
    "label": "Rarange",
    "value": "Rarange"
  },
  {
    "id": "7104062013",
    "district_id": "710406",
    "label": "Taduna",
    "value": "Taduna"
  },
  {
    "id": "7104062017",
    "district_id": "710406",
    "label": "Kabaruan Timur",
    "value": "Kabaruan Timur"
  },
  {
    "id": "7104062018",
    "district_id": "710406",
    "label": "Pantuge Timur",
    "value": "Pantuge Timur"
  },
  {
    "id": "7104062019",
    "district_id": "710406",
    "label": "Pannulan",
    "value": "Pannulan"
  },
  {
    "id": "7104062020",
    "district_id": "710406",
    "label": "Bulude Selatan",
    "value": "Bulude Selatan"
  },
  {
    "id": "7104071001",
    "district_id": "710407",
    "label": "Melonguane",
    "value": "Melonguane"
  },
  {
    "id": "7104071009",
    "district_id": "710407",
    "label": "Melonguane Timur",
    "value": "Melonguane Timur"
  },
  {
    "id": "7104071010",
    "district_id": "710407",
    "label": "Melonguane Barat",
    "value": "Melonguane Barat"
  },
  {
    "id": "7104072002",
    "district_id": "710407",
    "label": "Mala",
    "value": "Mala"
  },
  {
    "id": "7104072003",
    "district_id": "710407",
    "label": "Kiama",
    "value": "Kiama"
  },
  {
    "id": "7104072006",
    "district_id": "710407",
    "label": "Tarun",
    "value": "Tarun"
  },
  {
    "id": "7104072007",
    "district_id": "710407",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "7104072008",
    "district_id": "710407",
    "label": "Ambela",
    "value": "Ambela"
  },
  {
    "id": "7104072011",
    "district_id": "710407",
    "label": "Tarun Selatan",
    "value": "Tarun Selatan"
  },
  {
    "id": "7104072012",
    "district_id": "710407",
    "label": "Kiama Barat",
    "value": "Kiama Barat"
  },
  {
    "id": "7104072015",
    "district_id": "710407",
    "label": "Maredaren Utara",
    "value": "Maredaren Utara"
  },
  {
    "id": "7104072016",
    "district_id": "710407",
    "label": "Sawang Utara",
    "value": "Sawang Utara"
  },
  {
    "id": "7104072017",
    "district_id": "710407",
    "label": "Mala Timur",
    "value": "Mala Timur"
  },
  {
    "id": "7104082001",
    "district_id": "710408",
    "label": "Mamahan",
    "value": "Mamahan"
  },
  {
    "id": "7104082002",
    "district_id": "710408",
    "label": "Bambung",
    "value": "Bambung"
  },
  {
    "id": "7104082003",
    "district_id": "710408",
    "label": "Taturan",
    "value": "Taturan"
  },
  {
    "id": "7104082004",
    "district_id": "710408",
    "label": "Gemeh",
    "value": "Gemeh"
  },
  {
    "id": "7104082005",
    "district_id": "710408",
    "label": "Arangkaa",
    "value": "Arangkaa"
  },
  {
    "id": "7104082006",
    "district_id": "710408",
    "label": "Malat",
    "value": "Malat"
  },
  {
    "id": "7104082007",
    "district_id": "710408",
    "label": "Apan",
    "value": "Apan"
  },
  {
    "id": "7104082008",
    "district_id": "710408",
    "label": "Taruan",
    "value": "Taruan"
  },
  {
    "id": "7104082009",
    "district_id": "710408",
    "label": "Bannada",
    "value": "Bannada"
  },
  {
    "id": "7104082010",
    "district_id": "710408",
    "label": "Gemeh Rasmata",
    "value": "Gemeh Rasmata"
  },
  {
    "id": "7104082011",
    "district_id": "710408",
    "label": "Gemeh Wantane",
    "value": "Gemeh Wantane"
  },
  {
    "id": "7104082012",
    "district_id": "710408",
    "label": "Bambung Timur",
    "value": "Bambung Timur"
  },
  {
    "id": "7104082013",
    "district_id": "710408",
    "label": "Malat Utara",
    "value": "Malat Utara"
  },
  {
    "id": "7104082014",
    "district_id": "710408",
    "label": "Mamahan Barat",
    "value": "Mamahan Barat"
  },
  {
    "id": "7104082015",
    "district_id": "710408",
    "label": "Lahu",
    "value": "Lahu"
  },
  {
    "id": "7104092001",
    "district_id": "710409",
    "label": "Peret",
    "value": "Peret"
  },
  {
    "id": "7104092002",
    "district_id": "710409",
    "label": "Ighik",
    "value": "Ighik"
  },
  {
    "id": "7104092003",
    "district_id": "710409",
    "label": "Birang",
    "value": "Birang"
  },
  {
    "id": "7104092004",
    "district_id": "710409",
    "label": "Akas",
    "value": "Akas"
  },
  {
    "id": "7104092005",
    "district_id": "710409",
    "label": "Damau",
    "value": "Damau"
  },
  {
    "id": "7104092006",
    "district_id": "710409",
    "label": "Damau Bowone",
    "value": "Damau Bowone"
  },
  {
    "id": "7104092007",
    "district_id": "710409",
    "label": "Taduwale",
    "value": "Taduwale"
  },
  {
    "id": "7104092008",
    "district_id": "710409",
    "label": "Akas Balane",
    "value": "Akas Balane"
  },
  {
    "id": "7104102001",
    "district_id": "710410",
    "label": "Tuabatu",
    "value": "Tuabatu"
  },
  {
    "id": "7104102002",
    "district_id": "710410",
    "label": "Dapalan",
    "value": "Dapalan"
  },
  {
    "id": "7104102003",
    "district_id": "710410",
    "label": "Riung",
    "value": "Riung"
  },
  {
    "id": "7104102004",
    "district_id": "710410",
    "label": "Ammat",
    "value": "Ammat"
  },
  {
    "id": "7104102005",
    "district_id": "710410",
    "label": "Binalang",
    "value": "Binalang"
  },
  {
    "id": "7104102006",
    "district_id": "710410",
    "label": "Ganalo",
    "value": "Ganalo"
  },
  {
    "id": "7104102007",
    "district_id": "710410",
    "label": "Dapihe",
    "value": "Dapihe"
  },
  {
    "id": "7104102008",
    "district_id": "710410",
    "label": "Tuabatu Barat",
    "value": "Tuabatu Barat"
  },
  {
    "id": "7104102009",
    "district_id": "710410",
    "label": "Ammat Selatan",
    "value": "Ammat Selatan"
  },
  {
    "id": "7104102010",
    "district_id": "710410",
    "label": "Binalang Timur",
    "value": "Binalang Timur"
  },
  {
    "id": "7104102011",
    "district_id": "710410",
    "label": "Riung Utara",
    "value": "Riung Utara"
  },
  {
    "id": "7104112001",
    "district_id": "710411",
    "label": "Bitunuris",
    "value": "Bitunuris"
  },
  {
    "id": "7104112002",
    "district_id": "710411",
    "label": "Bitunuris Selatan",
    "value": "Bitunuris Selatan"
  },
  {
    "id": "7104112003",
    "district_id": "710411",
    "label": "Salibabu",
    "value": "Salibabu"
  },
  {
    "id": "7104112004",
    "district_id": "710411",
    "label": "Salibabu Utara",
    "value": "Salibabu Utara"
  },
  {
    "id": "7104112005",
    "district_id": "710411",
    "label": "Dalum",
    "value": "Dalum"
  },
  {
    "id": "7104112006",
    "district_id": "710411",
    "label": "Balang",
    "value": "Balang"
  },
  {
    "id": "7104122001",
    "district_id": "710412",
    "label": "Kalongan",
    "value": "Kalongan"
  },
  {
    "id": "7104122002",
    "district_id": "710412",
    "label": "Kalongan Utara",
    "value": "Kalongan Utara"
  },
  {
    "id": "7104122003",
    "district_id": "710412",
    "label": "Kalongan Selatan",
    "value": "Kalongan Selatan"
  },
  {
    "id": "7104122004",
    "district_id": "710412",
    "label": "Alude",
    "value": "Alude"
  },
  {
    "id": "7104122005",
    "district_id": "710412",
    "label": "Musi I",
    "value": "Musi I"
  },
  {
    "id": "7104132001",
    "district_id": "710413",
    "label": "Pulau Miangas",
    "value": "Pulau Miangas"
  },
  {
    "id": "7104141001",
    "district_id": "710414",
    "label": "Makatara",
    "value": "Makatara"
  },
  {
    "id": "7104141002",
    "district_id": "710414",
    "label": "Makatara Timur",
    "value": "Makatara Timur"
  },
  {
    "id": "7104142003",
    "district_id": "710414",
    "label": "Awit",
    "value": "Awit"
  },
  {
    "id": "7104142004",
    "district_id": "710414",
    "label": "Awit Selatan",
    "value": "Awit Selatan"
  },
  {
    "id": "7104142005",
    "district_id": "710414",
    "label": "Rae",
    "value": "Rae"
  },
  {
    "id": "7104142006",
    "district_id": "710414",
    "label": "Rae Selatan",
    "value": "Rae Selatan"
  },
  {
    "id": "7104142007",
    "district_id": "710414",
    "label": "Lobbo",
    "value": "Lobbo"
  },
  {
    "id": "7104142008",
    "district_id": "710414",
    "label": "Lobbo I",
    "value": "Lobbo I"
  },
  {
    "id": "7104152001",
    "district_id": "710415",
    "label": "Pulutan",
    "value": "Pulutan"
  },
  {
    "id": "7104152002",
    "district_id": "710415",
    "label": "Pulutan Utara",
    "value": "Pulutan Utara"
  },
  {
    "id": "7104152003",
    "district_id": "710415",
    "label": "Pulutan Selatan",
    "value": "Pulutan Selatan"
  },
  {
    "id": "7104152004",
    "district_id": "710415",
    "label": "Daran",
    "value": "Daran"
  },
  {
    "id": "7104152005",
    "district_id": "710415",
    "label": "Daran Utara",
    "value": "Daran Utara"
  },
  {
    "id": "7104162001",
    "district_id": "710416",
    "label": "Tule",
    "value": "Tule"
  },
  {
    "id": "7104162002",
    "district_id": "710416",
    "label": "Tule Tengah",
    "value": "Tule Tengah"
  },
  {
    "id": "7104162003",
    "district_id": "710416",
    "label": "Tule Utara",
    "value": "Tule Utara"
  },
  {
    "id": "7104162004",
    "district_id": "710416",
    "label": "Bowombaru",
    "value": "Bowombaru"
  },
  {
    "id": "7104162005",
    "district_id": "710416",
    "label": "Bowombaru Tengah",
    "value": "Bowombaru Tengah"
  },
  {
    "id": "7104162006",
    "district_id": "710416",
    "label": "Bowombaru Utara",
    "value": "Bowombaru Utara"
  },
  {
    "id": "7104172001",
    "district_id": "710417",
    "label": "Moronge",
    "value": "Moronge"
  },
  {
    "id": "7104172002",
    "district_id": "710417",
    "label": "Moronge I",
    "value": "Moronge I"
  },
  {
    "id": "7104172003",
    "district_id": "710417",
    "label": "Moronge II",
    "value": "Moronge II"
  },
  {
    "id": "7104172004",
    "district_id": "710417",
    "label": "Moronge Selatan",
    "value": "Moronge Selatan"
  },
  {
    "id": "7104172005",
    "district_id": "710417",
    "label": "Moronge Selatan II",
    "value": "Moronge Selatan II"
  },
  {
    "id": "7104172006",
    "district_id": "710417",
    "label": "Moronge Selatan I",
    "value": "Moronge Selatan I"
  },
  {
    "id": "7104182001",
    "district_id": "710418",
    "label": "Pampalu",
    "value": "Pampalu"
  },
  {
    "id": "7104182002",
    "district_id": "710418",
    "label": "Ruso",
    "value": "Ruso"
  },
  {
    "id": "7104182003",
    "district_id": "710418",
    "label": "Niampak",
    "value": "Niampak"
  },
  {
    "id": "7104182004",
    "district_id": "710418",
    "label": "Niampak Utara",
    "value": "Niampak Utara"
  },
  {
    "id": "7104182005",
    "district_id": "710418",
    "label": "Tarohan",
    "value": "Tarohan"
  },
  {
    "id": "7104182006",
    "district_id": "710418",
    "label": "Tarohan Selatan",
    "value": "Tarohan Selatan"
  },
  {
    "id": "7104182007",
    "district_id": "710418",
    "label": "Matahit",
    "value": "Matahit"
  },
  {
    "id": "7104192001",
    "district_id": "710419",
    "label": "Sambuara Satu",
    "value": "Sambuara Satu"
  },
  {
    "id": "7104192002",
    "district_id": "710419",
    "label": "Sambuara",
    "value": "Sambuara"
  },
  {
    "id": "7104192003",
    "district_id": "710419",
    "label": "Ensem",
    "value": "Ensem"
  },
  {
    "id": "7104192004",
    "district_id": "710419",
    "label": "Ensem I",
    "value": "Ensem I"
  },
  {
    "id": "7104192005",
    "district_id": "710419",
    "label": "Batumbalango",
    "value": "Batumbalango"
  },
  {
    "id": "7104192006",
    "district_id": "710419",
    "label": "Ambia",
    "value": "Ambia"
  },
  {
    "id": "7104192007",
    "district_id": "710419",
    "label": "Ambia Utara",
    "value": "Ambia Utara"
  },
  {
    "id": "7104192008",
    "district_id": "710419",
    "label": "Kuma",
    "value": "Kuma"
  },
  {
    "id": "7104192009",
    "district_id": "710419",
    "label": "Kuma Selatan",
    "value": "Kuma Selatan"
  },
  {
    "id": "7105012001",
    "district_id": "710501",
    "label": "Sinisir",
    "value": "Sinisir"
  },
  {
    "id": "7105012002",
    "district_id": "710501",
    "label": "Kakenturan",
    "value": "Kakenturan"
  },
  {
    "id": "7105012003",
    "district_id": "710501",
    "label": "Palelon",
    "value": "Palelon"
  },
  {
    "id": "7105012004",
    "district_id": "710501",
    "label": "Pinasungkulan",
    "value": "Pinasungkulan"
  },
  {
    "id": "7105012005",
    "district_id": "710501",
    "label": "Wulurmaatus",
    "value": "Wulurmaatus"
  },
  {
    "id": "7105012006",
    "district_id": "710501",
    "label": "Makaaruyen",
    "value": "Makaaruyen"
  },
  {
    "id": "7105012007",
    "district_id": "710501",
    "label": "Linelean",
    "value": "Linelean"
  },
  {
    "id": "7105012008",
    "district_id": "710501",
    "label": "Mokobang",
    "value": "Mokobang"
  },
  {
    "id": "7105012009",
    "district_id": "710501",
    "label": "Kakenturan Barat",
    "value": "Kakenturan Barat"
  },
  {
    "id": "7105012010",
    "district_id": "710501",
    "label": "Pinasungkulan Utara",
    "value": "Pinasungkulan Utara"
  },
  {
    "id": "7105022009",
    "district_id": "710502",
    "label": "Raraatean",
    "value": "Raraatean"
  },
  {
    "id": "7105022010",
    "district_id": "710502",
    "label": "Sion",
    "value": "Sion"
  },
  {
    "id": "7105022011",
    "district_id": "710502",
    "label": "Tompaso Baru I",
    "value": "Tompaso Baru I"
  },
  {
    "id": "7105022012",
    "district_id": "710502",
    "label": "Kinalawiran",
    "value": "Kinalawiran"
  },
  {
    "id": "7105022013",
    "district_id": "710502",
    "label": "Tompaso Baru II",
    "value": "Tompaso Baru II"
  },
  {
    "id": "7105022014",
    "district_id": "710502",
    "label": "Pinaesaan",
    "value": "Pinaesaan"
  },
  {
    "id": "7105022015",
    "district_id": "710502",
    "label": "Torout",
    "value": "Torout"
  },
  {
    "id": "7105022016",
    "district_id": "710502",
    "label": "Lindangan",
    "value": "Lindangan"
  },
  {
    "id": "7105022017",
    "district_id": "710502",
    "label": "Karowa",
    "value": "Karowa"
  },
  {
    "id": "7105022018",
    "district_id": "710502",
    "label": "Liandok",
    "value": "Liandok"
  },
  {
    "id": "7105032002",
    "district_id": "710503",
    "label": "Poopo",
    "value": "Poopo"
  },
  {
    "id": "7105032003",
    "district_id": "710503",
    "label": "Ranoyapo",
    "value": "Ranoyapo"
  },
  {
    "id": "7105032004",
    "district_id": "710503",
    "label": "Mopolo",
    "value": "Mopolo"
  },
  {
    "id": "7105032005",
    "district_id": "710503",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "7105032006",
    "district_id": "710503",
    "label": "Powalutan",
    "value": "Powalutan"
  },
  {
    "id": "7105032008",
    "district_id": "710503",
    "label": "Lompad",
    "value": "Lompad"
  },
  {
    "id": "7105032009",
    "district_id": "710503",
    "label": "Lompad Baru",
    "value": "Lompad Baru"
  },
  {
    "id": "7105032010",
    "district_id": "710503",
    "label": "Pontak",
    "value": "Pontak"
  },
  {
    "id": "7105032012",
    "district_id": "710503",
    "label": "Poopo Utara",
    "value": "Poopo Utara"
  },
  {
    "id": "7105032013",
    "district_id": "710503",
    "label": "Poopo Barat",
    "value": "Poopo Barat"
  },
  {
    "id": "7105032014",
    "district_id": "710503",
    "label": "Pontak Satu",
    "value": "Pontak Satu"
  },
  {
    "id": "7105032015",
    "district_id": "710503",
    "label": "Mopolo Esa",
    "value": "Mopolo Esa"
  },
  {
    "id": "7105072001",
    "district_id": "710507",
    "label": "Motoling",
    "value": "Motoling"
  },
  {
    "id": "7105072002",
    "district_id": "710507",
    "label": "Motoling Mawale",
    "value": "Motoling Mawale"
  },
  {
    "id": "7105072003",
    "district_id": "710507",
    "label": "Motoling I",
    "value": "Motoling I"
  },
  {
    "id": "7105072004",
    "district_id": "710507",
    "label": "Motoling II",
    "value": "Motoling II"
  },
  {
    "id": "7105072005",
    "district_id": "710507",
    "label": "Picuan Baru",
    "value": "Picuan Baru"
  },
  {
    "id": "7105072015",
    "district_id": "710507",
    "label": "Lalumpe",
    "value": "Lalumpe"
  },
  {
    "id": "7105072021",
    "district_id": "710507",
    "label": "Raanan Lama",
    "value": "Raanan Lama"
  },
  {
    "id": "7105082001",
    "district_id": "710508",
    "label": "Blongko",
    "value": "Blongko"
  },
  {
    "id": "7105082002",
    "district_id": "710508",
    "label": "Boyongpante",
    "value": "Boyongpante"
  },
  {
    "id": "7105082003",
    "district_id": "710508",
    "label": "Tiniawangko",
    "value": "Tiniawangko"
  },
  {
    "id": "7105082004",
    "district_id": "710508",
    "label": "Ongkaw I",
    "value": "Ongkaw I"
  },
  {
    "id": "7105082005",
    "district_id": "710508",
    "label": "Ongkaw II",
    "value": "Ongkaw II"
  },
  {
    "id": "7105082006",
    "district_id": "710508",
    "label": "Aergale",
    "value": "Aergale"
  },
  {
    "id": "7105082007",
    "district_id": "710508",
    "label": "Poigar I",
    "value": "Poigar I"
  },
  {
    "id": "7105082008",
    "district_id": "710508",
    "label": "Poigar II",
    "value": "Poigar II"
  },
  {
    "id": "7105082009",
    "district_id": "710508",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "7105082010",
    "district_id": "710508",
    "label": "Tanamon",
    "value": "Tanamon"
  },
  {
    "id": "7105082011",
    "district_id": "710508",
    "label": "Ongkaw Tiga",
    "value": "Ongkaw Tiga"
  },
  {
    "id": "7105082012",
    "district_id": "710508",
    "label": "Boyongpante Dua",
    "value": "Boyongpante Dua"
  },
  {
    "id": "7105082013",
    "district_id": "710508",
    "label": "Tanamon Utara",
    "value": "Tanamon Utara"
  },
  {
    "id": "7105092001",
    "district_id": "710509",
    "label": "Boyong Atas",
    "value": "Boyong Atas"
  },
  {
    "id": "7105092002",
    "district_id": "710509",
    "label": "Paku Ure II",
    "value": "Paku Ure II"
  },
  {
    "id": "7105092003",
    "district_id": "710509",
    "label": "Tenga",
    "value": "Tenga"
  },
  {
    "id": "7105092004",
    "district_id": "710509",
    "label": "Radey",
    "value": "Radey"
  },
  {
    "id": "7105092005",
    "district_id": "710509",
    "label": "Tawaang",
    "value": "Tawaang"
  },
  {
    "id": "7105092009",
    "district_id": "710509",
    "label": "Pakuweru",
    "value": "Pakuweru"
  },
  {
    "id": "7105092010",
    "district_id": "710509",
    "label": "Sapa",
    "value": "Sapa"
  },
  {
    "id": "7105092011",
    "district_id": "710509",
    "label": "Pakuure Satu",
    "value": "Pakuure Satu"
  },
  {
    "id": "7105092012",
    "district_id": "710509",
    "label": "Pakuure Tiga",
    "value": "Pakuure Tiga"
  },
  {
    "id": "7105092014",
    "district_id": "710509",
    "label": "Molinow",
    "value": "Molinow"
  },
  {
    "id": "7105092015",
    "district_id": "710509",
    "label": "Paku Ure",
    "value": "Paku Ure"
  },
  {
    "id": "7105092016",
    "district_id": "710509",
    "label": "Paku Ure Tinanian",
    "value": "Paku Ure Tinanian"
  },
  {
    "id": "7105092017",
    "district_id": "710509",
    "label": "Paku Ure Kinamang",
    "value": "Paku Ure Kinamang"
  },
  {
    "id": "7105092018",
    "district_id": "710509",
    "label": "Pakuweru Utara",
    "value": "Pakuweru Utara"
  },
  {
    "id": "7105092019",
    "district_id": "710509",
    "label": "Sapa Barat",
    "value": "Sapa Barat"
  },
  {
    "id": "7105092020",
    "district_id": "710509",
    "label": "Sapa Timur",
    "value": "Sapa Timur"
  },
  {
    "id": "7105092021",
    "district_id": "710509",
    "label": "Tawaang Timur",
    "value": "Tawaang Timur"
  },
  {
    "id": "7105092022",
    "district_id": "710509",
    "label": "Tawaang Barat",
    "value": "Tawaang Barat"
  },
  {
    "id": "7105101004",
    "district_id": "710510",
    "label": "Buyungon",
    "value": "Buyungon"
  },
  {
    "id": "7105101005",
    "district_id": "710510",
    "label": "Ranoyapo",
    "value": "Ranoyapo"
  },
  {
    "id": "7105101006",
    "district_id": "710510",
    "label": "Uwuran I",
    "value": "Uwuran I"
  },
  {
    "id": "7105101007",
    "district_id": "710510",
    "label": "Uwuran II",
    "value": "Uwuran II"
  },
  {
    "id": "7105101008",
    "district_id": "710510",
    "label": "Lewet",
    "value": "Lewet"
  },
  {
    "id": "7105101010",
    "district_id": "710510",
    "label": "Bitung",
    "value": "Bitung"
  },
  {
    "id": "7105102009",
    "district_id": "710510",
    "label": "Ranoketang Tua",
    "value": "Ranoketang Tua"
  },
  {
    "id": "7105102018",
    "district_id": "710510",
    "label": "Kilometer 3",
    "value": "Kilometer 3"
  },
  {
    "id": "7105122001",
    "district_id": "710512",
    "label": "Tumpaan Satu",
    "value": "Tumpaan Satu"
  },
  {
    "id": "7105122002",
    "district_id": "710512",
    "label": "Tumpaan",
    "value": "Tumpaan"
  },
  {
    "id": "7105122003",
    "district_id": "710512",
    "label": "Matani",
    "value": "Matani"
  },
  {
    "id": "7105122004",
    "district_id": "710512",
    "label": "Popontolen",
    "value": "Popontolen"
  },
  {
    "id": "7105122005",
    "district_id": "710512",
    "label": "Lelema",
    "value": "Lelema"
  },
  {
    "id": "7105122006",
    "district_id": "710512",
    "label": "Tangkunei",
    "value": "Tangkunei"
  },
  {
    "id": "7105122007",
    "district_id": "710512",
    "label": "Munte",
    "value": "Munte"
  },
  {
    "id": "7105122016",
    "district_id": "710512",
    "label": "Tumpaan Baru",
    "value": "Tumpaan Baru"
  },
  {
    "id": "7105122017",
    "district_id": "710512",
    "label": "Matani Satu",
    "value": "Matani Satu"
  },
  {
    "id": "7105122018",
    "district_id": "710512",
    "label": "Tumpan Dua",
    "value": "Tumpan Dua"
  },
  {
    "id": "7105132001",
    "district_id": "710513",
    "label": "Pinamorongan",
    "value": "Pinamorongan"
  },
  {
    "id": "7105132002",
    "district_id": "710513",
    "label": "Kaneyan",
    "value": "Kaneyan"
  },
  {
    "id": "7105132003",
    "district_id": "710513",
    "label": "Koreng",
    "value": "Koreng"
  },
  {
    "id": "7105132004",
    "district_id": "710513",
    "label": "Tumaluntung",
    "value": "Tumaluntung"
  },
  {
    "id": "7105132005",
    "district_id": "710513",
    "label": "Rumoong Atas",
    "value": "Rumoong Atas"
  },
  {
    "id": "7105132006",
    "district_id": "710513",
    "label": "Lansot",
    "value": "Lansot"
  },
  {
    "id": "7105132007",
    "district_id": "710513",
    "label": "Wiau Lapi",
    "value": "Wiau Lapi"
  },
  {
    "id": "7105132008",
    "district_id": "710513",
    "label": "Wuwuk",
    "value": "Wuwuk"
  },
  {
    "id": "7105132014",
    "district_id": "710513",
    "label": "Rumoong Atas Dua",
    "value": "Rumoong Atas Dua"
  },
  {
    "id": "7105132017",
    "district_id": "710513",
    "label": "Lansot Timur",
    "value": "Lansot Timur"
  },
  {
    "id": "7105132018",
    "district_id": "710513",
    "label": "Wuwuk Barat",
    "value": "Wuwuk Barat"
  },
  {
    "id": "7105132019",
    "district_id": "710513",
    "label": "Tumaluntung Satu",
    "value": "Tumaluntung Satu"
  },
  {
    "id": "7105132020",
    "district_id": "710513",
    "label": "Wiau Lapi Barat",
    "value": "Wiau Lapi Barat"
  },
  {
    "id": "7105152002",
    "district_id": "710515",
    "label": "Kumelembuai",
    "value": "Kumelembuai"
  },
  {
    "id": "7105152003",
    "district_id": "710515",
    "label": "Kumelembuai Atas",
    "value": "Kumelembuai Atas"
  },
  {
    "id": "7105152004",
    "district_id": "710515",
    "label": "Kumelembuai Satu",
    "value": "Kumelembuai Satu"
  },
  {
    "id": "7105152005",
    "district_id": "710515",
    "label": "Malola",
    "value": "Malola"
  },
  {
    "id": "7105152006",
    "district_id": "710515",
    "label": "Malola I",
    "value": "Malola I"
  },
  {
    "id": "7105152007",
    "district_id": "710515",
    "label": "Makasili",
    "value": "Makasili"
  },
  {
    "id": "7105152011",
    "district_id": "710515",
    "label": "Kumelembuai Dua",
    "value": "Kumelembuai Dua"
  },
  {
    "id": "7105152015",
    "district_id": "710515",
    "label": "Lolombulan Makasili",
    "value": "Lolombulan Makasili"
  },
  {
    "id": "7105162001",
    "district_id": "710516",
    "label": "Tumani",
    "value": "Tumani"
  },
  {
    "id": "7105162002",
    "district_id": "710516",
    "label": "Lowian",
    "value": "Lowian"
  },
  {
    "id": "7105162003",
    "district_id": "710516",
    "label": "Kinaweruan",
    "value": "Kinaweruan"
  },
  {
    "id": "7105162004",
    "district_id": "710516",
    "label": "Liningaan",
    "value": "Liningaan"
  },
  {
    "id": "7105162005",
    "district_id": "710516",
    "label": "Bojonegoro",
    "value": "Bojonegoro"
  },
  {
    "id": "7105162006",
    "district_id": "710516",
    "label": "Tambelang",
    "value": "Tambelang"
  },
  {
    "id": "7105162007",
    "district_id": "710516",
    "label": "Kinamang",
    "value": "Kinamang"
  },
  {
    "id": "7105162008",
    "district_id": "710516",
    "label": "Temboan",
    "value": "Temboan"
  },
  {
    "id": "7105162009",
    "district_id": "710516",
    "label": "Kinamang Satu",
    "value": "Kinamang Satu"
  },
  {
    "id": "7105162010",
    "district_id": "710516",
    "label": "Lowian Satu",
    "value": "Lowian Satu"
  },
  {
    "id": "7105162011",
    "district_id": "710516",
    "label": "Tumani Utara",
    "value": "Tumani Utara"
  },
  {
    "id": "7105162012",
    "district_id": "710516",
    "label": "Tumani Selatan",
    "value": "Tumani Selatan"
  },
  {
    "id": "7105171002",
    "district_id": "710517",
    "label": "Kawangkoan Bawah",
    "value": "Kawangkoan Bawah"
  },
  {
    "id": "7105171003",
    "district_id": "710517",
    "label": "Rumoong Bawah",
    "value": "Rumoong Bawah"
  },
  {
    "id": "7105172001",
    "district_id": "710517",
    "label": "Kapitu",
    "value": "Kapitu"
  },
  {
    "id": "7105172004",
    "district_id": "710517",
    "label": "Rumoong Bawah",
    "value": "Rumoong Bawah"
  },
  {
    "id": "7105172005",
    "district_id": "710517",
    "label": "Tewasen",
    "value": "Tewasen"
  },
  {
    "id": "7105172006",
    "district_id": "710517",
    "label": "Pondos",
    "value": "Pondos"
  },
  {
    "id": "7105172007",
    "district_id": "710517",
    "label": "Elusan",
    "value": "Elusan"
  },
  {
    "id": "7105172008",
    "district_id": "710517",
    "label": "Teep",
    "value": "Teep"
  },
  {
    "id": "7105172009",
    "district_id": "710517",
    "label": "Wakan",
    "value": "Wakan"
  },
  {
    "id": "7105172010",
    "district_id": "710517",
    "label": "Teep Trans",
    "value": "Teep Trans"
  },
  {
    "id": "7105181002",
    "district_id": "710518",
    "label": "Pondang",
    "value": "Pondang"
  },
  {
    "id": "7105181003",
    "district_id": "710518",
    "label": "Ranomea",
    "value": "Ranomea"
  },
  {
    "id": "7105182001",
    "district_id": "710518",
    "label": "Lopana",
    "value": "Lopana"
  },
  {
    "id": "7105182004",
    "district_id": "710518",
    "label": "Pinaling",
    "value": "Pinaling"
  },
  {
    "id": "7105182005",
    "district_id": "710518",
    "label": "Kota Menara",
    "value": "Kota Menara"
  },
  {
    "id": "7105182006",
    "district_id": "710518",
    "label": "Maliku",
    "value": "Maliku"
  },
  {
    "id": "7105182007",
    "district_id": "710518",
    "label": "Ritey",
    "value": "Ritey"
  },
  {
    "id": "7105182008",
    "district_id": "710518",
    "label": "Malenos Baru",
    "value": "Malenos Baru"
  },
  {
    "id": "7105182009",
    "district_id": "710518",
    "label": "Maliku Satu",
    "value": "Maliku Satu"
  },
  {
    "id": "7105182010",
    "district_id": "710518",
    "label": "Lopana Satu",
    "value": "Lopana Satu"
  },
  {
    "id": "7105192001",
    "district_id": "710519",
    "label": "Paslaten",
    "value": "Paslaten"
  },
  {
    "id": "7105192002",
    "district_id": "710519",
    "label": "Sulu",
    "value": "Sulu"
  },
  {
    "id": "7105192003",
    "district_id": "710519",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "7105192004",
    "district_id": "710519",
    "label": "Wawona",
    "value": "Wawona"
  },
  {
    "id": "7105192005",
    "district_id": "710519",
    "label": "Popareng",
    "value": "Popareng"
  },
  {
    "id": "7105192006",
    "district_id": "710519",
    "label": "Wawontulap",
    "value": "Wawontulap"
  },
  {
    "id": "7105192007",
    "district_id": "710519",
    "label": "Sondaken",
    "value": "Sondaken"
  },
  {
    "id": "7105192008",
    "district_id": "710519",
    "label": "Pungkol",
    "value": "Pungkol"
  },
  {
    "id": "7105192009",
    "district_id": "710519",
    "label": "Raprap",
    "value": "Raprap"
  },
  {
    "id": "7105192010",
    "district_id": "710519",
    "label": "Paslaten Satu",
    "value": "Paslaten Satu"
  },
  {
    "id": "7105192011",
    "district_id": "710519",
    "label": "Arakan",
    "value": "Arakan"
  },
  {
    "id": "7105212001",
    "district_id": "710521",
    "label": "Tondei",
    "value": "Tondei"
  },
  {
    "id": "7105212002",
    "district_id": "710521",
    "label": "Tondei Satu",
    "value": "Tondei Satu"
  },
  {
    "id": "7105212003",
    "district_id": "710521",
    "label": "Tondei Dua",
    "value": "Tondei Dua"
  },
  {
    "id": "7105212004",
    "district_id": "710521",
    "label": "Raanan Baru",
    "value": "Raanan Baru"
  },
  {
    "id": "7105212005",
    "district_id": "710521",
    "label": "Ranaan Baru Satu",
    "value": "Ranaan Baru Satu"
  },
  {
    "id": "7105212006",
    "district_id": "710521",
    "label": "Ranaan Baru Dua",
    "value": "Ranaan Baru Dua"
  },
  {
    "id": "7105212007",
    "district_id": "710521",
    "label": "Toyopon",
    "value": "Toyopon"
  },
  {
    "id": "7105212008",
    "district_id": "710521",
    "label": "Keroit",
    "value": "Keroit"
  },
  {
    "id": "7105222001",
    "district_id": "710522",
    "label": "Karimbow",
    "value": "Karimbow"
  },
  {
    "id": "7105222002",
    "district_id": "710522",
    "label": "Karimbow Talikuran",
    "value": "Karimbow Talikuran"
  },
  {
    "id": "7105222003",
    "district_id": "710522",
    "label": "Tokin",
    "value": "Tokin"
  },
  {
    "id": "7105222004",
    "district_id": "710522",
    "label": "Tokin Baru",
    "value": "Tokin Baru"
  },
  {
    "id": "7105222005",
    "district_id": "710522",
    "label": "Wanga Amongena",
    "value": "Wanga Amongena"
  },
  {
    "id": "7105222006",
    "district_id": "710522",
    "label": "Wanga",
    "value": "Wanga"
  },
  {
    "id": "7105222007",
    "district_id": "710522",
    "label": "Picuan",
    "value": "Picuan"
  },
  {
    "id": "7105222008",
    "district_id": "710522",
    "label": "Picuan Satu",
    "value": "Picuan Satu"
  },
  {
    "id": "7105232001",
    "district_id": "710523",
    "label": "Talaitad",
    "value": "Talaitad"
  },
  {
    "id": "7105232002",
    "district_id": "710523",
    "label": "Suluun Satu",
    "value": "Suluun Satu"
  },
  {
    "id": "7105232003",
    "district_id": "710523",
    "label": "Suluun Dua",
    "value": "Suluun Dua"
  },
  {
    "id": "7105232004",
    "district_id": "710523",
    "label": "Suluun Tiga",
    "value": "Suluun Tiga"
  },
  {
    "id": "7105232005",
    "district_id": "710523",
    "label": "Suluun Empat",
    "value": "Suluun Empat"
  },
  {
    "id": "7105232006",
    "district_id": "710523",
    "label": "Pinapalangkow",
    "value": "Pinapalangkow"
  },
  {
    "id": "7105232007",
    "district_id": "710523",
    "label": "Kapoya",
    "value": "Kapoya"
  },
  {
    "id": "7105232008",
    "district_id": "710523",
    "label": "Talaitad Utara",
    "value": "Talaitad Utara"
  },
  {
    "id": "7105232009",
    "district_id": "710523",
    "label": "Kapoya Satu",
    "value": "Kapoya Satu"
  },
  {
    "id": "7106012001",
    "district_id": "710601",
    "label": "Makalisung",
    "value": "Makalisung"
  },
  {
    "id": "7106012002",
    "district_id": "710601",
    "label": "Waleo",
    "value": "Waleo"
  },
  {
    "id": "7106012003",
    "district_id": "710601",
    "label": "Lilang",
    "value": "Lilang"
  },
  {
    "id": "7106012004",
    "district_id": "710601",
    "label": "Lansot",
    "value": "Lansot"
  },
  {
    "id": "7106012005",
    "district_id": "710601",
    "label": "Kema III",
    "value": "Kema III"
  },
  {
    "id": "7106012006",
    "district_id": "710601",
    "label": "Kema II",
    "value": "Kema II"
  },
  {
    "id": "7106012007",
    "district_id": "710601",
    "label": "Kema I",
    "value": "Kema I"
  },
  {
    "id": "7106012008",
    "district_id": "710601",
    "label": "Tontalete",
    "value": "Tontalete"
  },
  {
    "id": "7106012009",
    "district_id": "710601",
    "label": "Tontaalete Rok-rok",
    "value": "Tontaalete Rok-rok"
  },
  {
    "id": "7106012010",
    "district_id": "710601",
    "label": "Waleo Dua",
    "value": "Waleo Dua"
  },
  {
    "id": "7106022001",
    "district_id": "710602",
    "label": "Kauditan II",
    "value": "Kauditan II"
  },
  {
    "id": "7106022002",
    "district_id": "710602",
    "label": "Kauditan I",
    "value": "Kauditan I"
  },
  {
    "id": "7106022003",
    "district_id": "710602",
    "label": "Kawiley",
    "value": "Kawiley"
  },
  {
    "id": "7106022004",
    "district_id": "710602",
    "label": "Treman",
    "value": "Treman"
  },
  {
    "id": "7106022005",
    "district_id": "710602",
    "label": "Kaima",
    "value": "Kaima"
  },
  {
    "id": "7106022006",
    "district_id": "710602",
    "label": "Karegesan",
    "value": "Karegesan"
  },
  {
    "id": "7106022007",
    "district_id": "710602",
    "label": "Kaasar",
    "value": "Kaasar"
  },
  {
    "id": "7106022008",
    "district_id": "710602",
    "label": "Lembean",
    "value": "Lembean"
  },
  {
    "id": "7106022009",
    "district_id": "710602",
    "label": "Paslaten",
    "value": "Paslaten"
  },
  {
    "id": "7106022010",
    "district_id": "710602",
    "label": "Tumaluntung",
    "value": "Tumaluntung"
  },
  {
    "id": "7106022011",
    "district_id": "710602",
    "label": "Watudambo",
    "value": "Watudambo"
  },
  {
    "id": "7106022012",
    "district_id": "710602",
    "label": "Watudambo Dua",
    "value": "Watudambo Dua"
  },
  {
    "id": "7106031005",
    "district_id": "710603",
    "label": "Airmadidi Bawah",
    "value": "Airmadidi Bawah"
  },
  {
    "id": "7106031006",
    "district_id": "710603",
    "label": "Rap-Rap",
    "value": "Rap-Rap"
  },
  {
    "id": "7106031007",
    "district_id": "710603",
    "label": "Saronsong I",
    "value": "Saronsong I"
  },
  {
    "id": "7106031008",
    "district_id": "710603",
    "label": "Airmadidi Atas",
    "value": "Airmadidi Atas"
  },
  {
    "id": "7106031009",
    "district_id": "710603",
    "label": "Sukur",
    "value": "Sukur"
  },
  {
    "id": "7106031011",
    "district_id": "710603",
    "label": "Sarongsong II",
    "value": "Sarongsong II"
  },
  {
    "id": "7106032002",
    "district_id": "710603",
    "label": "Tanggari",
    "value": "Tanggari"
  },
  {
    "id": "7106032003",
    "district_id": "710603",
    "label": "Sampiri",
    "value": "Sampiri"
  },
  {
    "id": "7106032004",
    "district_id": "710603",
    "label": "Sawangan",
    "value": "Sawangan"
  },
  {
    "id": "7106042001",
    "district_id": "710604",
    "label": "Tiwoho",
    "value": "Tiwoho"
  },
  {
    "id": "7106042002",
    "district_id": "710604",
    "label": "Wori",
    "value": "Wori"
  },
  {
    "id": "7106042003",
    "district_id": "710604",
    "label": "Kima Bajo",
    "value": "Kima Bajo"
  },
  {
    "id": "7106042004",
    "district_id": "710604",
    "label": "Talawaan Bantik",
    "value": "Talawaan Bantik"
  },
  {
    "id": "7106042005",
    "district_id": "710604",
    "label": "Talawaan Atas",
    "value": "Talawaan Atas"
  },
  {
    "id": "7106042006",
    "district_id": "710604",
    "label": "Budo",
    "value": "Budo"
  },
  {
    "id": "7106042007",
    "district_id": "710604",
    "label": "Darunu",
    "value": "Darunu"
  },
  {
    "id": "7106042008",
    "district_id": "710604",
    "label": "Mantehage III Tinongko",
    "value": "Mantehage III Tinongko"
  },
  {
    "id": "7106042009",
    "district_id": "710604",
    "label": "Nain",
    "value": "Nain"
  },
  {
    "id": "7106042010",
    "district_id": "710604",
    "label": "Mantehage/Buhias",
    "value": "Mantehage/Buhias"
  },
  {
    "id": "7106042011",
    "district_id": "710604",
    "label": "Mantehage/Bango",
    "value": "Mantehage/Bango"
  },
  {
    "id": "7106042012",
    "district_id": "710604",
    "label": "Mantehage II Tangkasi",
    "value": "Mantehage II Tangkasi"
  },
  {
    "id": "7106042013",
    "district_id": "710604",
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "id": "7106042014",
    "district_id": "710604",
    "label": "Bulo",
    "value": "Bulo"
  },
  {
    "id": "7106042015",
    "district_id": "710604",
    "label": "Lansa",
    "value": "Lansa"
  },
  {
    "id": "7106042016",
    "district_id": "710604",
    "label": "Lantung",
    "value": "Lantung"
  },
  {
    "id": "7106042017",
    "district_id": "710604",
    "label": "Pontoh",
    "value": "Pontoh"
  },
  {
    "id": "7106042018",
    "district_id": "710604",
    "label": "Minaesa",
    "value": "Minaesa"
  },
  {
    "id": "7106042019",
    "district_id": "710604",
    "label": "Nain Tatampi",
    "value": "Nain Tatampi"
  },
  {
    "id": "7106042020",
    "district_id": "710604",
    "label": "Nain Satu",
    "value": "Nain Satu"
  },
  {
    "id": "7106052003",
    "district_id": "710605",
    "label": "Matungkas",
    "value": "Matungkas"
  },
  {
    "id": "7106052004",
    "district_id": "710605",
    "label": "Laikit",
    "value": "Laikit"
  },
  {
    "id": "7106052005",
    "district_id": "710605",
    "label": "Klabat",
    "value": "Klabat"
  },
  {
    "id": "7106052006",
    "district_id": "710605",
    "label": "Pinilih",
    "value": "Pinilih"
  },
  {
    "id": "7106052007",
    "district_id": "710605",
    "label": "Tatelu",
    "value": "Tatelu"
  },
  {
    "id": "7106052008",
    "district_id": "710605",
    "label": "Warukapas",
    "value": "Warukapas"
  },
  {
    "id": "7106052009",
    "district_id": "710605",
    "label": "Tetey",
    "value": "Tetey"
  },
  {
    "id": "7106052013",
    "district_id": "710605",
    "label": "Wasian",
    "value": "Wasian"
  },
  {
    "id": "7106052014",
    "district_id": "710605",
    "label": "Lumpias",
    "value": "Lumpias"
  },
  {
    "id": "7106052016",
    "district_id": "710605",
    "label": "Dimembe",
    "value": "Dimembe"
  },
  {
    "id": "7106052020",
    "district_id": "710605",
    "label": "Tatelu Rondor",
    "value": "Tatelu Rondor"
  },
  {
    "id": "7106062001",
    "district_id": "710606",
    "label": "Gangga I",
    "value": "Gangga I"
  },
  {
    "id": "7106062002",
    "district_id": "710606",
    "label": "Gangga II",
    "value": "Gangga II"
  },
  {
    "id": "7106062003",
    "district_id": "710606",
    "label": "Talise",
    "value": "Talise"
  },
  {
    "id": "7106062004",
    "district_id": "710606",
    "label": "Airbanua",
    "value": "Airbanua"
  },
  {
    "id": "7106062005",
    "district_id": "710606",
    "label": "Palaes",
    "value": "Palaes"
  },
  {
    "id": "7106062006",
    "district_id": "710606",
    "label": "Maliambao",
    "value": "Maliambao"
  },
  {
    "id": "7106062007",
    "district_id": "710606",
    "label": "Termaal",
    "value": "Termaal"
  },
  {
    "id": "7106062008",
    "district_id": "710606",
    "label": "Paputungan",
    "value": "Paputungan"
  },
  {
    "id": "7106062009",
    "district_id": "710606",
    "label": "Jayakarsa",
    "value": "Jayakarsa"
  },
  {
    "id": "7106062010",
    "district_id": "710606",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "7106062011",
    "district_id": "710606",
    "label": "Bahoi",
    "value": "Bahoi"
  },
  {
    "id": "7106062012",
    "district_id": "710606",
    "label": "Tarabitan",
    "value": "Tarabitan"
  },
  {
    "id": "7106062013",
    "district_id": "710606",
    "label": "Serei",
    "value": "Serei"
  },
  {
    "id": "7106062014",
    "district_id": "710606",
    "label": "Sonsilo",
    "value": "Sonsilo"
  },
  {
    "id": "7106062015",
    "district_id": "710606",
    "label": "Mubune",
    "value": "Mubune"
  },
  {
    "id": "7106062016",
    "district_id": "710606",
    "label": "Munte",
    "value": "Munte"
  },
  {
    "id": "7106062017",
    "district_id": "710606",
    "label": "Bulutui",
    "value": "Bulutui"
  },
  {
    "id": "7106062018",
    "district_id": "710606",
    "label": "Wawunian",
    "value": "Wawunian"
  },
  {
    "id": "7106062019",
    "district_id": "710606",
    "label": "Kinabuhutan",
    "value": "Kinabuhutan"
  },
  {
    "id": "7106062020",
    "district_id": "710606",
    "label": "Tambun",
    "value": "Tambun"
  },
  {
    "id": "7106072008",
    "district_id": "710607",
    "label": "Likupang I",
    "value": "Likupang I"
  },
  {
    "id": "7106072009",
    "district_id": "710607",
    "label": "Likupang II",
    "value": "Likupang II"
  },
  {
    "id": "7106072010",
    "district_id": "710607",
    "label": "Serawet",
    "value": "Serawet"
  },
  {
    "id": "7106072011",
    "district_id": "710607",
    "label": "Wineru",
    "value": "Wineru"
  },
  {
    "id": "7106072012",
    "district_id": "710607",
    "label": "Maen",
    "value": "Maen"
  },
  {
    "id": "7106072013",
    "district_id": "710607",
    "label": "Winuri",
    "value": "Winuri"
  },
  {
    "id": "7106072014",
    "district_id": "710607",
    "label": "Marinsow",
    "value": "Marinsow"
  },
  {
    "id": "7106072015",
    "district_id": "710607",
    "label": "Pulisan",
    "value": "Pulisan"
  },
  {
    "id": "7106072016",
    "district_id": "710607",
    "label": "Kalinaun",
    "value": "Kalinaun"
  },
  {
    "id": "7106072017",
    "district_id": "710607",
    "label": "Rinondoran",
    "value": "Rinondoran"
  },
  {
    "id": "7106072018",
    "district_id": "710607",
    "label": "Pinenek",
    "value": "Pinenek"
  },
  {
    "id": "7106072019",
    "district_id": "710607",
    "label": "Lihunu",
    "value": "Lihunu"
  },
  {
    "id": "7106072020",
    "district_id": "710607",
    "label": "Kahuhu",
    "value": "Kahuhu"
  },
  {
    "id": "7106072021",
    "district_id": "710607",
    "label": "Libas",
    "value": "Libas"
  },
  {
    "id": "7106072022",
    "district_id": "710607",
    "label": "Likupang Kampung Ambong",
    "value": "Likupang Kampung Ambong"
  },
  {
    "id": "7106072023",
    "district_id": "710607",
    "label": "Kinunang",
    "value": "Kinunang"
  },
  {
    "id": "7106072024",
    "district_id": "710607",
    "label": "Resetlemen",
    "value": "Resetlemen"
  },
  {
    "id": "7106072025",
    "district_id": "710607",
    "label": "Ehe",
    "value": "Ehe"
  },
  {
    "id": "7106082001",
    "district_id": "710608",
    "label": "Suwaan",
    "value": "Suwaan"
  },
  {
    "id": "7106082002",
    "district_id": "710608",
    "label": "Kuwil",
    "value": "Kuwil"
  },
  {
    "id": "7106082003",
    "district_id": "710608",
    "label": "Kawangkoan",
    "value": "Kawangkoan"
  },
  {
    "id": "7106082004",
    "district_id": "710608",
    "label": "Kolongan",
    "value": "Kolongan"
  },
  {
    "id": "7106082005",
    "district_id": "710608",
    "label": "Maumbi",
    "value": "Maumbi"
  },
  {
    "id": "7106082006",
    "district_id": "710608",
    "label": "Kaleosan",
    "value": "Kaleosan"
  },
  {
    "id": "7106082007",
    "district_id": "710608",
    "label": "Watutumou",
    "value": "Watutumou"
  },
  {
    "id": "7106082008",
    "district_id": "710608",
    "label": "Kolongan Tetempangan",
    "value": "Kolongan Tetempangan"
  },
  {
    "id": "7106082009",
    "district_id": "710608",
    "label": "Kawangkoan Baru",
    "value": "Kawangkoan Baru"
  },
  {
    "id": "7106082010",
    "district_id": "710608",
    "label": "Kalawat",
    "value": "Kalawat"
  },
  {
    "id": "7106082011",
    "district_id": "710608",
    "label": "Watutumou Dua",
    "value": "Watutumou Dua"
  },
  {
    "id": "7106082012",
    "district_id": "710608",
    "label": "Watutumou Tiga",
    "value": "Watutumou Tiga"
  },
  {
    "id": "7106092001",
    "district_id": "710609",
    "label": "Paniki Atas",
    "value": "Paniki Atas"
  },
  {
    "id": "7106092002",
    "district_id": "710609",
    "label": "Kolongan",
    "value": "Kolongan"
  },
  {
    "id": "7106092003",
    "district_id": "710609",
    "label": "Talawaan",
    "value": "Talawaan"
  },
  {
    "id": "7106092004",
    "district_id": "710609",
    "label": "Mapanget",
    "value": "Mapanget"
  },
  {
    "id": "7106092005",
    "district_id": "710609",
    "label": "Wusa",
    "value": "Wusa"
  },
  {
    "id": "7106092006",
    "district_id": "710609",
    "label": "Warisa",
    "value": "Warisa"
  },
  {
    "id": "7106092007",
    "district_id": "710609",
    "label": "Tumbohon",
    "value": "Tumbohon"
  },
  {
    "id": "7106092008",
    "district_id": "710609",
    "label": "Winetin",
    "value": "Winetin"
  },
  {
    "id": "7106092009",
    "district_id": "710609",
    "label": "Patokaan",
    "value": "Patokaan"
  },
  {
    "id": "7106092010",
    "district_id": "710609",
    "label": "Teepwarisa",
    "value": "Teepwarisa"
  },
  {
    "id": "7106092011",
    "district_id": "710609",
    "label": "Warisa Kampung Baru",
    "value": "Warisa Kampung Baru"
  },
  {
    "id": "7106092012",
    "district_id": "710609",
    "label": "Paniki Baru",
    "value": "Paniki Baru"
  },
  {
    "id": "7106102001",
    "district_id": "710610",
    "label": "Kokoleh Satu",
    "value": "Kokoleh Satu"
  },
  {
    "id": "7106102002",
    "district_id": "710610",
    "label": "Kokoleh Dua",
    "value": "Kokoleh Dua"
  },
  {
    "id": "7106102003",
    "district_id": "710610",
    "label": "Paslaten",
    "value": "Paslaten"
  },
  {
    "id": "7106102004",
    "district_id": "710610",
    "label": "Kaweruan",
    "value": "Kaweruan"
  },
  {
    "id": "7106102005",
    "district_id": "710610",
    "label": "Wangurer",
    "value": "Wangurer"
  },
  {
    "id": "7106102006",
    "district_id": "710610",
    "label": "Batu",
    "value": "Batu"
  },
  {
    "id": "7106102007",
    "district_id": "710610",
    "label": "Werot",
    "value": "Werot"
  },
  {
    "id": "7107011004",
    "district_id": "710701",
    "label": "Tosuraya",
    "value": "Tosuraya"
  },
  {
    "id": "7107011005",
    "district_id": "710701",
    "label": "Wawali",
    "value": "Wawali"
  },
  {
    "id": "7107011008",
    "district_id": "710701",
    "label": "Lowu Dua",
    "value": "Lowu Dua"
  },
  {
    "id": "7107011009",
    "district_id": "710701",
    "label": "Lowu Satu",
    "value": "Lowu Satu"
  },
  {
    "id": "7107011016",
    "district_id": "710701",
    "label": "Lowu Utara",
    "value": "Lowu Utara"
  },
  {
    "id": "7107011017",
    "district_id": "710701",
    "label": "Nataan",
    "value": "Nataan"
  },
  {
    "id": "7107011018",
    "district_id": "710701",
    "label": "Tosuraya Barat",
    "value": "Tosuraya Barat"
  },
  {
    "id": "7107011019",
    "district_id": "710701",
    "label": "Tosuraya Selatan",
    "value": "Tosuraya Selatan"
  },
  {
    "id": "7107011020",
    "district_id": "710701",
    "label": "Wawali Pasan",
    "value": "Wawali Pasan"
  },
  {
    "id": "7107012003",
    "district_id": "710701",
    "label": "Rasi",
    "value": "Rasi"
  },
  {
    "id": "7107012021",
    "district_id": "710701",
    "label": "Rasi Satu",
    "value": "Rasi Satu"
  },
  {
    "id": "7107022001",
    "district_id": "710702",
    "label": "Tatengesan",
    "value": "Tatengesan"
  },
  {
    "id": "7107022002",
    "district_id": "710702",
    "label": "Wiau",
    "value": "Wiau"
  },
  {
    "id": "7107022003",
    "district_id": "710702",
    "label": "Bentenan",
    "value": "Bentenan"
  },
  {
    "id": "7107022004",
    "district_id": "710702",
    "label": "Tumbak",
    "value": "Tumbak"
  },
  {
    "id": "7107022005",
    "district_id": "710702",
    "label": "Makalu",
    "value": "Makalu"
  },
  {
    "id": "7107022006",
    "district_id": "710702",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7107022007",
    "district_id": "710702",
    "label": "Minanga Satu",
    "value": "Minanga Satu"
  },
  {
    "id": "7107022008",
    "district_id": "710702",
    "label": "Bentenan Indah",
    "value": "Bentenan Indah"
  },
  {
    "id": "7107022009",
    "district_id": "710702",
    "label": "Minanga Timur",
    "value": "Minanga Timur"
  },
  {
    "id": "7107022010",
    "district_id": "710702",
    "label": "Minanga Dua",
    "value": "Minanga Dua"
  },
  {
    "id": "7107022011",
    "district_id": "710702",
    "label": "Minanga Tiga",
    "value": "Minanga Tiga"
  },
  {
    "id": "7107022012",
    "district_id": "710702",
    "label": "Makalu Selatan",
    "value": "Makalu Selatan"
  },
  {
    "id": "7107022013",
    "district_id": "710702",
    "label": "Tatengesan Satu",
    "value": "Tatengesan Satu"
  },
  {
    "id": "7107022014",
    "district_id": "710702",
    "label": "Bentenan Satu",
    "value": "Bentenan Satu"
  },
  {
    "id": "7107022015",
    "district_id": "710702",
    "label": "Tumbak Madani",
    "value": "Tumbak Madani"
  },
  {
    "id": "7107032001",
    "district_id": "710703",
    "label": "Mangkit",
    "value": "Mangkit"
  },
  {
    "id": "7107032002",
    "district_id": "710703",
    "label": "Borgo",
    "value": "Borgo"
  },
  {
    "id": "7107032003",
    "district_id": "710703",
    "label": "Belang",
    "value": "Belang"
  },
  {
    "id": "7107032004",
    "district_id": "710703",
    "label": "Buku",
    "value": "Buku"
  },
  {
    "id": "7107032005",
    "district_id": "710703",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "7107032006",
    "district_id": "710703",
    "label": "Tababo",
    "value": "Tababo"
  },
  {
    "id": "7107032007",
    "district_id": "710703",
    "label": "Watuliney",
    "value": "Watuliney"
  },
  {
    "id": "7107032008",
    "district_id": "710703",
    "label": "Molompar",
    "value": "Molompar"
  },
  {
    "id": "7107032009",
    "district_id": "710703",
    "label": "Buku Utara",
    "value": "Buku Utara"
  },
  {
    "id": "7107032010",
    "district_id": "710703",
    "label": "Buku Selatan",
    "value": "Buku Selatan"
  },
  {
    "id": "7107032011",
    "district_id": "710703",
    "label": "Watuliney Tengah",
    "value": "Watuliney Tengah"
  },
  {
    "id": "7107032012",
    "district_id": "710703",
    "label": "Watuliney Indah",
    "value": "Watuliney Indah"
  },
  {
    "id": "7107032013",
    "district_id": "710703",
    "label": "Molompar Utara",
    "value": "Molompar Utara"
  },
  {
    "id": "7107032014",
    "district_id": "710703",
    "label": "Molompar Timur",
    "value": "Molompar Timur"
  },
  {
    "id": "7107032015",
    "district_id": "710703",
    "label": "Tababo Selatan",
    "value": "Tababo Selatan"
  },
  {
    "id": "7107032016",
    "district_id": "710703",
    "label": "Buku Tengah",
    "value": "Buku Tengah"
  },
  {
    "id": "7107032017",
    "district_id": "710703",
    "label": "Ponosakan Indah",
    "value": "Ponosakan Indah"
  },
  {
    "id": "7107032018",
    "district_id": "710703",
    "label": "Buku Tenggara",
    "value": "Buku Tenggara"
  },
  {
    "id": "7107032019",
    "district_id": "710703",
    "label": "Ponosakan Belang",
    "value": "Ponosakan Belang"
  },
  {
    "id": "7107032020",
    "district_id": "710703",
    "label": "Borgo Satu",
    "value": "Borgo Satu"
  },
  {
    "id": "7107042001",
    "district_id": "710704",
    "label": "Ratatotok Satu",
    "value": "Ratatotok Satu"
  },
  {
    "id": "7107042002",
    "district_id": "710704",
    "label": "Ratatotok Dua",
    "value": "Ratatotok Dua"
  },
  {
    "id": "7107042003",
    "district_id": "710704",
    "label": "Ratatotok Selatan",
    "value": "Ratatotok Selatan"
  },
  {
    "id": "7107042004",
    "district_id": "710704",
    "label": "Ratatotok Timur",
    "value": "Ratatotok Timur"
  },
  {
    "id": "7107042005",
    "district_id": "710704",
    "label": "Basaan",
    "value": "Basaan"
  },
  {
    "id": "7107042006",
    "district_id": "710704",
    "label": "Basaan Satu",
    "value": "Basaan Satu"
  },
  {
    "id": "7107042007",
    "district_id": "710704",
    "label": "Morea",
    "value": "Morea"
  },
  {
    "id": "7107042008",
    "district_id": "710704",
    "label": "Morea Satu",
    "value": "Morea Satu"
  },
  {
    "id": "7107042009",
    "district_id": "710704",
    "label": "Soyoan",
    "value": "Soyoan"
  },
  {
    "id": "7107042010",
    "district_id": "710704",
    "label": "Basaan Dua",
    "value": "Basaan Dua"
  },
  {
    "id": "7107042011",
    "district_id": "710704",
    "label": "Ratatotok Utara",
    "value": "Ratatotok Utara"
  },
  {
    "id": "7107042012",
    "district_id": "710704",
    "label": "Ratatotok Tenggara",
    "value": "Ratatotok Tenggara"
  },
  {
    "id": "7107042013",
    "district_id": "710704",
    "label": "Ratatotok Tengah",
    "value": "Ratatotok Tengah"
  },
  {
    "id": "7107042014",
    "district_id": "710704",
    "label": "Ratatotok",
    "value": "Ratatotok"
  },
  {
    "id": "7107042015",
    "district_id": "710704",
    "label": "Ratatotok Muara",
    "value": "Ratatotok Muara"
  },
  {
    "id": "7107052006",
    "district_id": "710705",
    "label": "Tonsawang",
    "value": "Tonsawang"
  },
  {
    "id": "7107052008",
    "district_id": "710705",
    "label": "Betelan",
    "value": "Betelan"
  },
  {
    "id": "7107052009",
    "district_id": "710705",
    "label": "Tombatu Satu",
    "value": "Tombatu Satu"
  },
  {
    "id": "7107052012",
    "district_id": "710705",
    "label": "Kali",
    "value": "Kali"
  },
  {
    "id": "7107052014",
    "district_id": "710705",
    "label": "Pisa",
    "value": "Pisa"
  },
  {
    "id": "7107052015",
    "district_id": "710705",
    "label": "Tombatu",
    "value": "Tombatu"
  },
  {
    "id": "7107052016",
    "district_id": "710705",
    "label": "Betelan Satu",
    "value": "Betelan Satu"
  },
  {
    "id": "7107052017",
    "district_id": "710705",
    "label": "Kali Oki",
    "value": "Kali Oki"
  },
  {
    "id": "7107052018",
    "district_id": "710705",
    "label": "Tombatu Tiga Selatan",
    "value": "Tombatu Tiga Selatan"
  },
  {
    "id": "7107052019",
    "district_id": "710705",
    "label": "Tombatu Tiga Timur",
    "value": "Tombatu Tiga Timur"
  },
  {
    "id": "7107052020",
    "district_id": "710705",
    "label": "Tonsawang Satu",
    "value": "Tonsawang Satu"
  },
  {
    "id": "7107062003",
    "district_id": "710706",
    "label": "Ranoketang Atas",
    "value": "Ranoketang Atas"
  },
  {
    "id": "7107062004",
    "district_id": "710706",
    "label": "Tondanauw",
    "value": "Tondanauw"
  },
  {
    "id": "7107062005",
    "district_id": "710706",
    "label": "Lobu Satu",
    "value": "Lobu Satu"
  },
  {
    "id": "7107062006",
    "district_id": "710706",
    "label": "Lobu Dua",
    "value": "Lobu Dua"
  },
  {
    "id": "7107062017",
    "district_id": "710706",
    "label": "Tondanouw Satu",
    "value": "Tondanouw Satu"
  },
  {
    "id": "7107062018",
    "district_id": "710706",
    "label": "Toundanouw Atas",
    "value": "Toundanouw Atas"
  },
  {
    "id": "7107062019",
    "district_id": "710706",
    "label": "Ranoketang Atas Satu",
    "value": "Ranoketang Atas Satu"
  },
  {
    "id": "7107062020",
    "district_id": "710706",
    "label": "Lobu Kota",
    "value": "Lobu Kota"
  },
  {
    "id": "7107062021",
    "district_id": "710706",
    "label": "Lobu Atas",
    "value": "Lobu Atas"
  },
  {
    "id": "7107062022",
    "district_id": "710706",
    "label": "Lobu",
    "value": "Lobu"
  },
  {
    "id": "7107072001",
    "district_id": "710707",
    "label": "Kalait",
    "value": "Kalait"
  },
  {
    "id": "7107072002",
    "district_id": "710707",
    "label": "Kalait Satu",
    "value": "Kalait Satu"
  },
  {
    "id": "7107072003",
    "district_id": "710707",
    "label": "Kalait Dua",
    "value": "Kalait Dua"
  },
  {
    "id": "7107072004",
    "district_id": "710707",
    "label": "Kalait Tiga",
    "value": "Kalait Tiga"
  },
  {
    "id": "7107072005",
    "district_id": "710707",
    "label": "Lowatag",
    "value": "Lowatag"
  },
  {
    "id": "7107072006",
    "district_id": "710707",
    "label": "Bunag",
    "value": "Bunag"
  },
  {
    "id": "7107072007",
    "district_id": "710707",
    "label": "Banga",
    "value": "Banga"
  },
  {
    "id": "7107072008",
    "district_id": "710707",
    "label": "Tambelang",
    "value": "Tambelang"
  },
  {
    "id": "7107072009",
    "district_id": "710707",
    "label": "Ranoako",
    "value": "Ranoako"
  },
  {
    "id": "7107072010",
    "district_id": "710707",
    "label": "Suhuyon",
    "value": "Suhuyon"
  },
  {
    "id": "7107082001",
    "district_id": "710708",
    "label": "Silian",
    "value": "Silian"
  },
  {
    "id": "7107082002",
    "district_id": "710708",
    "label": "Silian Utara",
    "value": "Silian Utara"
  },
  {
    "id": "7107082003",
    "district_id": "710708",
    "label": "Silian Satu",
    "value": "Silian Satu"
  },
  {
    "id": "7107082004",
    "district_id": "710708",
    "label": "Silian Selatan",
    "value": "Silian Selatan"
  },
  {
    "id": "7107082005",
    "district_id": "710708",
    "label": "Silian Dua",
    "value": "Silian Dua"
  },
  {
    "id": "7107082006",
    "district_id": "710708",
    "label": "Silian Tengah",
    "value": "Silian Tengah"
  },
  {
    "id": "7107082007",
    "district_id": "710708",
    "label": "Silian Tiga",
    "value": "Silian Tiga"
  },
  {
    "id": "7107082008",
    "district_id": "710708",
    "label": "Silian Barat",
    "value": "Silian Barat"
  },
  {
    "id": "7107082009",
    "district_id": "710708",
    "label": "Silian Kota",
    "value": "Silian Kota"
  },
  {
    "id": "7107082010",
    "district_id": "710708",
    "label": "Silian Timur",
    "value": "Silian Timur"
  },
  {
    "id": "7107092001",
    "district_id": "710709",
    "label": "Molompar",
    "value": "Molompar"
  },
  {
    "id": "7107092002",
    "district_id": "710709",
    "label": "Molompar Satu",
    "value": "Molompar Satu"
  },
  {
    "id": "7107092003",
    "district_id": "710709",
    "label": "Molompar Atas",
    "value": "Molompar Atas"
  },
  {
    "id": "7107092004",
    "district_id": "710709",
    "label": "Molompar Dua",
    "value": "Molompar Dua"
  },
  {
    "id": "7107092005",
    "district_id": "710709",
    "label": "Molompar Dua Utara",
    "value": "Molompar Dua Utara"
  },
  {
    "id": "7107092006",
    "district_id": "710709",
    "label": "Molompar Dua Selatan",
    "value": "Molompar Dua Selatan"
  },
  {
    "id": "7107092007",
    "district_id": "710709",
    "label": "Mundung",
    "value": "Mundung"
  },
  {
    "id": "7107092008",
    "district_id": "710709",
    "label": "Mundung Satu",
    "value": "Mundung Satu"
  },
  {
    "id": "7107092009",
    "district_id": "710709",
    "label": "Esandom",
    "value": "Esandom"
  },
  {
    "id": "7107092010",
    "district_id": "710709",
    "label": "Esandom Satu",
    "value": "Esandom Satu"
  },
  {
    "id": "7107092011",
    "district_id": "710709",
    "label": "Esandom Dua",
    "value": "Esandom Dua"
  },
  {
    "id": "7107102001",
    "district_id": "710710",
    "label": "Tombatu Dua",
    "value": "Tombatu Dua"
  },
  {
    "id": "7107102002",
    "district_id": "710710",
    "label": "Tombatu Dua Utara",
    "value": "Tombatu Dua Utara"
  },
  {
    "id": "7107102003",
    "district_id": "710710",
    "label": "Tombatu Dua Barat",
    "value": "Tombatu Dua Barat"
  },
  {
    "id": "7107102004",
    "district_id": "710710",
    "label": "Tombatu Dua Tengah",
    "value": "Tombatu Dua Tengah"
  },
  {
    "id": "7107102005",
    "district_id": "710710",
    "label": "Tombatu Tiga",
    "value": "Tombatu Tiga"
  },
  {
    "id": "7107102006",
    "district_id": "710710",
    "label": "Tombatu Tiga Tengah",
    "value": "Tombatu Tiga Tengah"
  },
  {
    "id": "7107102007",
    "district_id": "710710",
    "label": "Kuyanga",
    "value": "Kuyanga"
  },
  {
    "id": "7107102008",
    "district_id": "710710",
    "label": "Kuyanga Satu",
    "value": "Kuyanga Satu"
  },
  {
    "id": "7107102009",
    "district_id": "710710",
    "label": "Winorangian",
    "value": "Winorangian"
  },
  {
    "id": "7107102010",
    "district_id": "710710",
    "label": "Winorangian Satu",
    "value": "Winorangian Satu"
  },
  {
    "id": "7107112001",
    "district_id": "710711",
    "label": "Towuntu",
    "value": "Towuntu"
  },
  {
    "id": "7107112002",
    "district_id": "710711",
    "label": "Towuntu Timur",
    "value": "Towuntu Timur"
  },
  {
    "id": "7107112003",
    "district_id": "710711",
    "label": "Towuntu Barat",
    "value": "Towuntu Barat"
  },
  {
    "id": "7107112004",
    "district_id": "710711",
    "label": "Tolombukan",
    "value": "Tolombukan"
  },
  {
    "id": "7107112005",
    "district_id": "710711",
    "label": "Tolombukan Satu",
    "value": "Tolombukan Satu"
  },
  {
    "id": "7107112006",
    "district_id": "710711",
    "label": "Tolombukan Barat",
    "value": "Tolombukan Barat"
  },
  {
    "id": "7107112007",
    "district_id": "710711",
    "label": "Liwutung",
    "value": "Liwutung"
  },
  {
    "id": "7107112008",
    "district_id": "710711",
    "label": "Liwutung Satu",
    "value": "Liwutung Satu"
  },
  {
    "id": "7107112009",
    "district_id": "710711",
    "label": "Liwutung Dua",
    "value": "Liwutung Dua"
  },
  {
    "id": "7107112010",
    "district_id": "710711",
    "label": "Poniki",
    "value": "Poniki"
  },
  {
    "id": "7107112011",
    "district_id": "710711",
    "label": "Maulit",
    "value": "Maulit"
  },
  {
    "id": "7107122001",
    "district_id": "710712",
    "label": "Wioi",
    "value": "Wioi"
  },
  {
    "id": "7107122002",
    "district_id": "710712",
    "label": "Wioi Satu",
    "value": "Wioi Satu"
  },
  {
    "id": "7107122003",
    "district_id": "710712",
    "label": "Wioi Dua",
    "value": "Wioi Dua"
  },
  {
    "id": "7107122004",
    "district_id": "710712",
    "label": "Wioi Tiga",
    "value": "Wioi Tiga"
  },
  {
    "id": "7107122005",
    "district_id": "710712",
    "label": "Wioi Timur",
    "value": "Wioi Timur"
  },
  {
    "id": "7107122006",
    "district_id": "710712",
    "label": "Pangu",
    "value": "Pangu"
  },
  {
    "id": "7107122007",
    "district_id": "710712",
    "label": "Pangu Satu",
    "value": "Pangu Satu"
  },
  {
    "id": "7107122008",
    "district_id": "710712",
    "label": "Pangu Dua",
    "value": "Pangu Dua"
  },
  {
    "id": "7107122009",
    "district_id": "710712",
    "label": "Wongkai",
    "value": "Wongkai"
  },
  {
    "id": "7107122010",
    "district_id": "710712",
    "label": "Wongkai Satu",
    "value": "Wongkai Satu"
  },
  {
    "id": "7108012001",
    "district_id": "710801",
    "label": "Sangkub I",
    "value": "Sangkub I"
  },
  {
    "id": "7108012002",
    "district_id": "710801",
    "label": "Busisingo",
    "value": "Busisingo"
  },
  {
    "id": "7108012003",
    "district_id": "710801",
    "label": "Sang Tombolang",
    "value": "Sang Tombolang"
  },
  {
    "id": "7108012004",
    "district_id": "710801",
    "label": "Tombolango",
    "value": "Tombolango"
  },
  {
    "id": "7108012005",
    "district_id": "710801",
    "label": "Pangkusa",
    "value": "Pangkusa"
  },
  {
    "id": "7108012006",
    "district_id": "710801",
    "label": "Sangkub II",
    "value": "Sangkub II"
  },
  {
    "id": "7108012007",
    "district_id": "710801",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "7108012008",
    "district_id": "710801",
    "label": "Sampiro",
    "value": "Sampiro"
  },
  {
    "id": "7108012009",
    "district_id": "710801",
    "label": "Sangkub III",
    "value": "Sangkub III"
  },
  {
    "id": "7108012010",
    "district_id": "710801",
    "label": "Busisingo Utara",
    "value": "Busisingo Utara"
  },
  {
    "id": "7108012011",
    "district_id": "710801",
    "label": "Ampeng Sembeka",
    "value": "Ampeng Sembeka"
  },
  {
    "id": "7108012012",
    "district_id": "710801",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "7108012013",
    "district_id": "710801",
    "label": "Sangkub Timur",
    "value": "Sangkub Timur"
  },
  {
    "id": "7108012014",
    "district_id": "710801",
    "label": "Monompia",
    "value": "Monompia"
  },
  {
    "id": "7108012015",
    "district_id": "710801",
    "label": "Mokusato",
    "value": "Mokusato"
  },
  {
    "id": "7108012016",
    "district_id": "710801",
    "label": "Sangkub Empat",
    "value": "Sangkub Empat"
  },
  {
    "id": "7108021001",
    "district_id": "710802",
    "label": "Bintauna",
    "value": "Bintauna"
  },
  {
    "id": "7108022002",
    "district_id": "710802",
    "label": "Huntuk",
    "value": "Huntuk"
  },
  {
    "id": "7108022003",
    "district_id": "710802",
    "label": "Mome",
    "value": "Mome"
  },
  {
    "id": "7108022004",
    "district_id": "710802",
    "label": "Kuhanga",
    "value": "Kuhanga"
  },
  {
    "id": "7108022005",
    "district_id": "710802",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "7108022006",
    "district_id": "710802",
    "label": "Pimpi",
    "value": "Pimpi"
  },
  {
    "id": "7108022007",
    "district_id": "710802",
    "label": "Bunia",
    "value": "Bunia"
  },
  {
    "id": "7108022008",
    "district_id": "710802",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "7108022009",
    "district_id": "710802",
    "label": "Batulintik",
    "value": "Batulintik"
  },
  {
    "id": "7108022010",
    "district_id": "710802",
    "label": "Bintauna Pantai",
    "value": "Bintauna Pantai"
  },
  {
    "id": "7108022011",
    "district_id": "710802",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7108022012",
    "district_id": "710802",
    "label": "Kopi",
    "value": "Kopi"
  },
  {
    "id": "7108022013",
    "district_id": "710802",
    "label": "Voa'a",
    "value": "Voa'a"
  },
  {
    "id": "7108022014",
    "district_id": "710802",
    "label": "Bunong",
    "value": "Bunong"
  },
  {
    "id": "7108022015",
    "district_id": "710802",
    "label": "Padang Barat",
    "value": "Padang Barat"
  },
  {
    "id": "7108022016",
    "district_id": "710802",
    "label": "Vahuta",
    "value": "Vahuta"
  },
  {
    "id": "7108032001",
    "district_id": "710803",
    "label": "Mokoditek",
    "value": "Mokoditek"
  },
  {
    "id": "7108032002",
    "district_id": "710803",
    "label": "Nunuka",
    "value": "Nunuka"
  },
  {
    "id": "7108032003",
    "district_id": "710803",
    "label": "Saleo",
    "value": "Saleo"
  },
  {
    "id": "7108032004",
    "district_id": "710803",
    "label": "Bohabak I",
    "value": "Bohabak I"
  },
  {
    "id": "7108032005",
    "district_id": "710803",
    "label": "Bohabak II",
    "value": "Bohabak II"
  },
  {
    "id": "7108032006",
    "district_id": "710803",
    "label": "Binjeita",
    "value": "Binjeita"
  },
  {
    "id": "7108032007",
    "district_id": "710803",
    "label": "Biontong",
    "value": "Biontong"
  },
  {
    "id": "7108032008",
    "district_id": "710803",
    "label": "Biontong I",
    "value": "Biontong I"
  },
  {
    "id": "7108032009",
    "district_id": "710803",
    "label": "Biontong II",
    "value": "Biontong II"
  },
  {
    "id": "7108032010",
    "district_id": "710803",
    "label": "Binuanga",
    "value": "Binuanga"
  },
  {
    "id": "7108032011",
    "district_id": "710803",
    "label": "Bohabak III",
    "value": "Bohabak III"
  },
  {
    "id": "7108032012",
    "district_id": "710803",
    "label": "Bohabak IV",
    "value": "Bohabak IV"
  },
  {
    "id": "7108032013",
    "district_id": "710803",
    "label": "Binjeita I",
    "value": "Binjeita I"
  },
  {
    "id": "7108032014",
    "district_id": "710803",
    "label": "Binjeita II",
    "value": "Binjeita II"
  },
  {
    "id": "7108032015",
    "district_id": "710803",
    "label": "Mokoditek I",
    "value": "Mokoditek I"
  },
  {
    "id": "7108032016",
    "district_id": "710803",
    "label": "Lipu Bogu",
    "value": "Lipu Bogu"
  },
  {
    "id": "7108032017",
    "district_id": "710803",
    "label": "Binuni",
    "value": "Binuni"
  },
  {
    "id": "7108032018",
    "district_id": "710803",
    "label": "Tanjung Labou",
    "value": "Tanjung Labou"
  },
  {
    "id": "7108032019",
    "district_id": "710803",
    "label": "Nagara",
    "value": "Nagara"
  },
  {
    "id": "7108032020",
    "district_id": "710803",
    "label": "Saleo Satu",
    "value": "Saleo Satu"
  },
  {
    "id": "7108042001",
    "district_id": "710804",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "7108042002",
    "district_id": "710804",
    "label": "Ollot",
    "value": "Ollot"
  },
  {
    "id": "7108042003",
    "district_id": "710804",
    "label": "Sonuo",
    "value": "Sonuo"
  },
  {
    "id": "7108042004",
    "district_id": "710804",
    "label": "Jambusarang",
    "value": "Jambusarang"
  },
  {
    "id": "7108042005",
    "district_id": "710804",
    "label": "Talaga Tomoagu",
    "value": "Talaga Tomoagu"
  },
  {
    "id": "7108042006",
    "district_id": "710804",
    "label": "Bolangitang",
    "value": "Bolangitang"
  },
  {
    "id": "7108042007",
    "district_id": "710804",
    "label": "Langi",
    "value": "Langi"
  },
  {
    "id": "7108042008",
    "district_id": "710804",
    "label": "Iyok",
    "value": "Iyok"
  },
  {
    "id": "7108042009",
    "district_id": "710804",
    "label": "Tote",
    "value": "Tote"
  },
  {
    "id": "7108042010",
    "district_id": "710804",
    "label": "Wakat",
    "value": "Wakat"
  },
  {
    "id": "7108042011",
    "district_id": "710804",
    "label": "Ollot I",
    "value": "Ollot I"
  },
  {
    "id": "7108042012",
    "district_id": "710804",
    "label": "Ollot II",
    "value": "Ollot II"
  },
  {
    "id": "7108042013",
    "district_id": "710804",
    "label": "Bolangitang I",
    "value": "Bolangitang I"
  },
  {
    "id": "7108042014",
    "district_id": "710804",
    "label": "Bolangitang II",
    "value": "Bolangitang II"
  },
  {
    "id": "7108042015",
    "district_id": "710804",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "7108042016",
    "district_id": "710804",
    "label": "Tanjung Buaya",
    "value": "Tanjung Buaya"
  },
  {
    "id": "7108042017",
    "district_id": "710804",
    "label": "Keimanga",
    "value": "Keimanga"
  },
  {
    "id": "7108042018",
    "district_id": "710804",
    "label": "Paku Selatan",
    "value": "Paku Selatan"
  },
  {
    "id": "7108052001",
    "district_id": "710805",
    "label": "Solo",
    "value": "Solo"
  },
  {
    "id": "7108052002",
    "district_id": "710805",
    "label": "Boroko",
    "value": "Boroko"
  },
  {
    "id": "7108052003",
    "district_id": "710805",
    "label": "Bigo",
    "value": "Bigo"
  },
  {
    "id": "7108052004",
    "district_id": "710805",
    "label": "Kuala",
    "value": "Kuala"
  },
  {
    "id": "7108052005",
    "district_id": "710805",
    "label": "Pontak",
    "value": "Pontak"
  },
  {
    "id": "7108052006",
    "district_id": "710805",
    "label": "Inomunga",
    "value": "Inomunga"
  },
  {
    "id": "7108052007",
    "district_id": "710805",
    "label": "Komus II",
    "value": "Komus II"
  },
  {
    "id": "7108052008",
    "district_id": "710805",
    "label": "Boroko Timur",
    "value": "Boroko Timur"
  },
  {
    "id": "7108052009",
    "district_id": "710805",
    "label": "Kuala Utara",
    "value": "Kuala Utara"
  },
  {
    "id": "7108052010",
    "district_id": "710805",
    "label": "Gihang",
    "value": "Gihang"
  },
  {
    "id": "7108052011",
    "district_id": "710805",
    "label": "Boroko Utara",
    "value": "Boroko Utara"
  },
  {
    "id": "7108052012",
    "district_id": "710805",
    "label": "Bigo Selatan",
    "value": "Bigo Selatan"
  },
  {
    "id": "7108052013",
    "district_id": "710805",
    "label": "Soligir",
    "value": "Soligir"
  },
  {
    "id": "7108052014",
    "district_id": "710805",
    "label": "Inomunga Utara",
    "value": "Inomunga Utara"
  },
  {
    "id": "7108052015",
    "district_id": "710805",
    "label": "Komus Dua Timur",
    "value": "Komus Dua Timur"
  },
  {
    "id": "7108062001",
    "district_id": "710806",
    "label": "Komus I",
    "value": "Komus I"
  },
  {
    "id": "7108062002",
    "district_id": "710806",
    "label": "Tuntung",
    "value": "Tuntung"
  },
  {
    "id": "7108062003",
    "district_id": "710806",
    "label": "Batu Tajam",
    "value": "Batu Tajam"
  },
  {
    "id": "7108062004",
    "district_id": "710806",
    "label": "Dalapuli",
    "value": "Dalapuli"
  },
  {
    "id": "7108062005",
    "district_id": "710806",
    "label": "Buko",
    "value": "Buko"
  },
  {
    "id": "7108062006",
    "district_id": "710806",
    "label": "Dengi",
    "value": "Dengi"
  },
  {
    "id": "7108062007",
    "district_id": "710806",
    "label": "Tombulang",
    "value": "Tombulang"
  },
  {
    "id": "7108062008",
    "district_id": "710806",
    "label": "Tuntulow",
    "value": "Tuntulow"
  },
  {
    "id": "7108062009",
    "district_id": "710806",
    "label": "Kayu Ogu",
    "value": "Kayu Ogu"
  },
  {
    "id": "7108062010",
    "district_id": "710806",
    "label": "Tanjung Sidupa",
    "value": "Tanjung Sidupa"
  },
  {
    "id": "7108062011",
    "district_id": "710806",
    "label": "Buko Selatan",
    "value": "Buko Selatan"
  },
  {
    "id": "7108062012",
    "district_id": "710806",
    "label": "Busato",
    "value": "Busato"
  },
  {
    "id": "7108062013",
    "district_id": "710806",
    "label": "Batu Bantayo",
    "value": "Batu Bantayo"
  },
  {
    "id": "7108062014",
    "district_id": "710806",
    "label": "Padango",
    "value": "Padango"
  },
  {
    "id": "7108062015",
    "district_id": "710806",
    "label": "Tuntulow Utara",
    "value": "Tuntulow Utara"
  },
  {
    "id": "7108062016",
    "district_id": "710806",
    "label": "Dalapuli Timur",
    "value": "Dalapuli Timur"
  },
  {
    "id": "7108062017",
    "district_id": "710806",
    "label": "Dalapuli Barat",
    "value": "Dalapuli Barat"
  },
  {
    "id": "7108062018",
    "district_id": "710806",
    "label": "Buko Utara",
    "value": "Buko Utara"
  },
  {
    "id": "7108062019",
    "district_id": "710806",
    "label": "Tambulang Timur",
    "value": "Tambulang Timur"
  },
  {
    "id": "7108062020",
    "district_id": "710806",
    "label": "Tambulang Pantai",
    "value": "Tambulang Pantai"
  },
  {
    "id": "7108062021",
    "district_id": "710806",
    "label": "Tuntung Timur",
    "value": "Tuntung Timur"
  },
  {
    "id": "7108062022",
    "district_id": "710806",
    "label": "Duini",
    "value": "Duini"
  },
  {
    "id": "7109011009",
    "district_id": "710901",
    "label": "Bebali",
    "value": "Bebali"
  },
  {
    "id": "7109011010",
    "district_id": "710901",
    "label": "Tatahadeng",
    "value": "Tatahadeng"
  },
  {
    "id": "7109011011",
    "district_id": "710901",
    "label": "Tarorane",
    "value": "Tarorane"
  },
  {
    "id": "7109011012",
    "district_id": "710901",
    "label": "Akesimbeka",
    "value": "Akesimbeka"
  },
  {
    "id": "7109011013",
    "district_id": "710901",
    "label": "Bahu",
    "value": "Bahu"
  },
  {
    "id": "7109012001",
    "district_id": "710901",
    "label": "Bukide",
    "value": "Bukide"
  },
  {
    "id": "7109012002",
    "district_id": "710901",
    "label": "Apelawo",
    "value": "Apelawo"
  },
  {
    "id": "7109012003",
    "district_id": "710901",
    "label": "Deahe",
    "value": "Deahe"
  },
  {
    "id": "7109012004",
    "district_id": "710901",
    "label": "Lia",
    "value": "Lia"
  },
  {
    "id": "7109012005",
    "district_id": "710901",
    "label": "Kanang",
    "value": "Kanang"
  },
  {
    "id": "7109012006",
    "district_id": "710901",
    "label": "Buise",
    "value": "Buise"
  },
  {
    "id": "7109012007",
    "district_id": "710901",
    "label": "Karalung",
    "value": "Karalung"
  },
  {
    "id": "7109012008",
    "district_id": "710901",
    "label": "Dame",
    "value": "Dame"
  },
  {
    "id": "7109012014",
    "district_id": "710901",
    "label": "Dame I",
    "value": "Dame I"
  },
  {
    "id": "7109012015",
    "district_id": "710901",
    "label": "Lia Satu",
    "value": "Lia Satu"
  },
  {
    "id": "7109012016",
    "district_id": "710901",
    "label": "Karalung Satu",
    "value": "Karalung Satu"
  },
  {
    "id": "7109021002",
    "district_id": "710902",
    "label": "Paseng",
    "value": "Paseng"
  },
  {
    "id": "7109021003",
    "district_id": "710902",
    "label": "Paniki",
    "value": "Paniki"
  },
  {
    "id": "7109021004",
    "district_id": "710902",
    "label": "Ondong",
    "value": "Ondong"
  },
  {
    "id": "7109022001",
    "district_id": "710902",
    "label": "Peling Sawang",
    "value": "Peling Sawang"
  },
  {
    "id": "7109022005",
    "district_id": "710902",
    "label": "Kanawong",
    "value": "Kanawong"
  },
  {
    "id": "7109022006",
    "district_id": "710902",
    "label": "Bumbiha",
    "value": "Bumbiha"
  },
  {
    "id": "7109022007",
    "district_id": "710902",
    "label": "Pehe",
    "value": "Pehe"
  },
  {
    "id": "7109022008",
    "district_id": "710902",
    "label": "Lehi",
    "value": "Lehi"
  },
  {
    "id": "7109022009",
    "district_id": "710902",
    "label": "Peling",
    "value": "Peling"
  },
  {
    "id": "7109022010",
    "district_id": "710902",
    "label": "Makalehi",
    "value": "Makalehi"
  },
  {
    "id": "7109022011",
    "district_id": "710902",
    "label": "Makalehi Utara",
    "value": "Makalehi Utara"
  },
  {
    "id": "7109022012",
    "district_id": "710902",
    "label": "Makalehi Timur",
    "value": "Makalehi Timur"
  },
  {
    "id": "7109031001",
    "district_id": "710903",
    "label": "Bahoi",
    "value": "Bahoi"
  },
  {
    "id": "7109031002",
    "district_id": "710903",
    "label": "Balehumara",
    "value": "Balehumara"
  },
  {
    "id": "7109032003",
    "district_id": "710903",
    "label": "Lesah",
    "value": "Lesah"
  },
  {
    "id": "7109032004",
    "district_id": "710903",
    "label": "Boto",
    "value": "Boto"
  },
  {
    "id": "7109032005",
    "district_id": "710903",
    "label": "Mohongsawang",
    "value": "Mohongsawang"
  },
  {
    "id": "7109032006",
    "district_id": "710903",
    "label": "Apengsala",
    "value": "Apengsala"
  },
  {
    "id": "7109032007",
    "district_id": "710903",
    "label": "Mulengan",
    "value": "Mulengan"
  },
  {
    "id": "7109032008",
    "district_id": "710903",
    "label": "Mahangiang",
    "value": "Mahangiang"
  },
  {
    "id": "7109032009",
    "district_id": "710903",
    "label": "Tulusan",
    "value": "Tulusan"
  },
  {
    "id": "7109032010",
    "district_id": "710903",
    "label": "Haasi",
    "value": "Haasi"
  },
  {
    "id": "7109032011",
    "district_id": "710903",
    "label": "Pumpente",
    "value": "Pumpente"
  },
  {
    "id": "7109032012",
    "district_id": "710903",
    "label": "Laingpatehi",
    "value": "Laingpatehi"
  },
  {
    "id": "7109032013",
    "district_id": "710903",
    "label": "Lesah Rende",
    "value": "Lesah Rende"
  },
  {
    "id": "7109032014",
    "district_id": "710903",
    "label": "Barangka Pehe",
    "value": "Barangka Pehe"
  },
  {
    "id": "7109032015",
    "district_id": "710903",
    "label": "Pahiama",
    "value": "Pahiama"
  },
  {
    "id": "7109042001",
    "district_id": "710904",
    "label": "Mala",
    "value": "Mala"
  },
  {
    "id": "7109042002",
    "district_id": "710904",
    "label": "Pangirolong",
    "value": "Pangirolong"
  },
  {
    "id": "7109042003",
    "district_id": "710904",
    "label": "Sawang",
    "value": "Sawang"
  },
  {
    "id": "7109042004",
    "district_id": "710904",
    "label": "Bandil",
    "value": "Bandil"
  },
  {
    "id": "7109042005",
    "district_id": "710904",
    "label": "Biau",
    "value": "Biau"
  },
  {
    "id": "7109042006",
    "district_id": "710904",
    "label": "Balirangeng",
    "value": "Balirangeng"
  },
  {
    "id": "7109042007",
    "district_id": "710904",
    "label": "Buhias",
    "value": "Buhias"
  },
  {
    "id": "7109042008",
    "district_id": "710904",
    "label": "Tapile",
    "value": "Tapile"
  },
  {
    "id": "7109042009",
    "district_id": "710904",
    "label": "Lahopang",
    "value": "Lahopang"
  },
  {
    "id": "7109042010",
    "district_id": "710904",
    "label": "Binalu",
    "value": "Binalu"
  },
  {
    "id": "7109042011",
    "district_id": "710904",
    "label": "Kalihiang",
    "value": "Kalihiang"
  },
  {
    "id": "7109042012",
    "district_id": "710904",
    "label": "Pahepa",
    "value": "Pahepa"
  },
  {
    "id": "7109042013",
    "district_id": "710904",
    "label": "Biau Seha",
    "value": "Biau Seha"
  },
  {
    "id": "7109042014",
    "district_id": "710904",
    "label": "Matole",
    "value": "Matole"
  },
  {
    "id": "7109052001",
    "district_id": "710905",
    "label": "Tanaki",
    "value": "Tanaki"
  },
  {
    "id": "7109052002",
    "district_id": "710905",
    "label": "Kapeta",
    "value": "Kapeta"
  },
  {
    "id": "7109052003",
    "district_id": "710905",
    "label": "Talawid",
    "value": "Talawid"
  },
  {
    "id": "7109052004",
    "district_id": "710905",
    "label": "Laghaeng",
    "value": "Laghaeng"
  },
  {
    "id": "7109052005",
    "district_id": "710905",
    "label": "Makoa",
    "value": "Makoa"
  },
  {
    "id": "7109052006",
    "district_id": "710905",
    "label": "Batusenggo",
    "value": "Batusenggo"
  },
  {
    "id": "7109052007",
    "district_id": "710905",
    "label": "Mahuneni",
    "value": "Mahuneni"
  },
  {
    "id": "7109062001",
    "district_id": "710906",
    "label": "Bulangan",
    "value": "Bulangan"
  },
  {
    "id": "7109062002",
    "district_id": "710906",
    "label": "Minanga",
    "value": "Minanga"
  },
  { "id": "7109062003", "district_id": "710906", "label": "Wo", "value": "Wo" },
  {
    "id": "7109062004",
    "district_id": "710906",
    "label": "Bawoleu",
    "value": "Bawoleu"
  },
  {
    "id": "7109062005",
    "district_id": "710906",
    "label": "Lumbo",
    "value": "Lumbo"
  },
  {
    "id": "7109062006",
    "district_id": "710906",
    "label": "Bawo",
    "value": "Bawo"
  },
  {
    "id": "7109072001",
    "district_id": "710907",
    "label": "Buang",
    "value": "Buang"
  },
  {
    "id": "7109072002",
    "district_id": "710907",
    "label": "Karungo",
    "value": "Karungo"
  },
  {
    "id": "7109072003",
    "district_id": "710907",
    "label": "Lamanggo",
    "value": "Lamanggo"
  },
  {
    "id": "7109072004",
    "district_id": "710907",
    "label": "Dalinsaheng",
    "value": "Dalinsaheng"
  },
  {
    "id": "7109072005",
    "district_id": "710907",
    "label": "Tope",
    "value": "Tope"
  },
  {
    "id": "7109082001",
    "district_id": "710908",
    "label": "Kinali",
    "value": "Kinali"
  },
  {
    "id": "7109082002",
    "district_id": "710908",
    "label": "Hiung",
    "value": "Hiung"
  },
  {
    "id": "7109082003",
    "district_id": "710908",
    "label": "Kiawang",
    "value": "Kiawang"
  },
  {
    "id": "7109082004",
    "district_id": "710908",
    "label": "Kawahang",
    "value": "Kawahang"
  },
  {
    "id": "7109082005",
    "district_id": "710908",
    "label": "Batubulan",
    "value": "Batubulan"
  },
  {
    "id": "7109082006",
    "district_id": "710908",
    "label": "Nameng",
    "value": "Nameng"
  },
  {
    "id": "7109082007",
    "district_id": "710908",
    "label": "Mini",
    "value": "Mini"
  },
  {
    "id": "7109082008",
    "district_id": "710908",
    "label": "Winangun",
    "value": "Winangun"
  },
  {
    "id": "7109092001",
    "district_id": "710909",
    "label": "Salili",
    "value": "Salili"
  },
  {
    "id": "7109092002",
    "district_id": "710909",
    "label": "Beong",
    "value": "Beong"
  },
  {
    "id": "7109092003",
    "district_id": "710909",
    "label": "Lai",
    "value": "Lai"
  },
  {
    "id": "7109092004",
    "district_id": "710909",
    "label": "Dompase",
    "value": "Dompase"
  },
  {
    "id": "7109102001",
    "district_id": "710910",
    "label": "Humbia",
    "value": "Humbia"
  },
  {
    "id": "7109102002",
    "district_id": "710910",
    "label": "Kisihang",
    "value": "Kisihang"
  },
  {
    "id": "7109102003",
    "district_id": "710910",
    "label": "Bira Kiama",
    "value": "Bira Kiama"
  },
  {
    "id": "7109102004",
    "district_id": "710910",
    "label": "Buha",
    "value": "Buha"
  },
  {
    "id": "7109102005",
    "district_id": "710910",
    "label": "Batumawira",
    "value": "Batumawira"
  },
  {
    "id": "7109102006",
    "district_id": "710910",
    "label": "Birarikei",
    "value": "Birarikei"
  },
  {
    "id": "7110012001",
    "district_id": "711001",
    "label": "Kayumoyondi",
    "value": "Kayumoyondi"
  },
  {
    "id": "7110012002",
    "district_id": "711001",
    "label": "Tombolikat",
    "value": "Tombolikat"
  },
  {
    "id": "7110012003",
    "district_id": "711001",
    "label": "Tutuyan",
    "value": "Tutuyan"
  },
  {
    "id": "7110012004",
    "district_id": "711001",
    "label": "Togid",
    "value": "Togid"
  },
  {
    "id": "7110012005",
    "district_id": "711001",
    "label": "Dodap",
    "value": "Dodap"
  },
  {
    "id": "7110012006",
    "district_id": "711001",
    "label": "Dodap Pantai",
    "value": "Dodap Pantai"
  },
  {
    "id": "7110012007",
    "district_id": "711001",
    "label": "Tutuyan II",
    "value": "Tutuyan II"
  },
  {
    "id": "7110012008",
    "district_id": "711001",
    "label": "Tutuyan III",
    "value": "Tutuyan III"
  },
  {
    "id": "7110012009",
    "district_id": "711001",
    "label": "Tombolikat Selatan",
    "value": "Tombolikat Selatan"
  },
  {
    "id": "7110012010",
    "district_id": "711001",
    "label": "Dodop Mikasa",
    "value": "Dodop Mikasa"
  },
  {
    "id": "7110022001",
    "district_id": "711002",
    "label": "Kotabunan",
    "value": "Kotabunan"
  },
  {
    "id": "7110022002",
    "district_id": "711002",
    "label": "Buyat",
    "value": "Buyat"
  },
  {
    "id": "7110022003",
    "district_id": "711002",
    "label": "Bulawan",
    "value": "Bulawan"
  },
  {
    "id": "7110022004",
    "district_id": "711002",
    "label": "Paret",
    "value": "Paret"
  },
  {
    "id": "7110022005",
    "district_id": "711002",
    "label": "Bukaka",
    "value": "Bukaka"
  },
  {
    "id": "7110022006",
    "district_id": "711002",
    "label": "Buyat I",
    "value": "Buyat I"
  },
  {
    "id": "7110022007",
    "district_id": "711002",
    "label": "Buyat II",
    "value": "Buyat II"
  },
  {
    "id": "7110022008",
    "district_id": "711002",
    "label": "Buyat Selatan",
    "value": "Buyat Selatan"
  },
  {
    "id": "7110022009",
    "district_id": "711002",
    "label": "Buyat Tengah",
    "value": "Buyat Tengah"
  },
  {
    "id": "7110022010",
    "district_id": "711002",
    "label": "Buyat Barat",
    "value": "Buyat Barat"
  },
  {
    "id": "7110022011",
    "district_id": "711002",
    "label": "Bulawan Satu",
    "value": "Bulawan Satu"
  },
  {
    "id": "7110022012",
    "district_id": "711002",
    "label": "Bulawan Dua",
    "value": "Bulawan Dua"
  },
  {
    "id": "7110022013",
    "district_id": "711002",
    "label": "Kotabunan Selatan",
    "value": "Kotabunan Selatan"
  },
  {
    "id": "7110022014",
    "district_id": "711002",
    "label": "Kotabunan Barat",
    "value": "Kotabunan Barat"
  },
  {
    "id": "7110022015",
    "district_id": "711002",
    "label": "Paret Timur",
    "value": "Paret Timur"
  },
  {
    "id": "7110032001",
    "district_id": "711003",
    "label": "Idumun",
    "value": "Idumun"
  },
  {
    "id": "7110032002",
    "district_id": "711003",
    "label": "Matabulu",
    "value": "Matabulu"
  },
  {
    "id": "7110032003",
    "district_id": "711003",
    "label": "Nuangan",
    "value": "Nuangan"
  },
  {
    "id": "7110032006",
    "district_id": "711003",
    "label": "Bai",
    "value": "Bai"
  },
  {
    "id": "7110032007",
    "district_id": "711003",
    "label": "Jiko Belanga",
    "value": "Jiko Belanga"
  },
  {
    "id": "7110032010",
    "district_id": "711003",
    "label": "Nuangan I",
    "value": "Nuangan I"
  },
  {
    "id": "7110032011",
    "district_id": "711003",
    "label": "Iyok",
    "value": "Iyok"
  },
  {
    "id": "7110032012",
    "district_id": "711003",
    "label": "Loyow",
    "value": "Loyow"
  },
  {
    "id": "7110032014",
    "district_id": "711003",
    "label": "Matabulu Timur",
    "value": "Matabulu Timur"
  },
  {
    "id": "7110032018",
    "district_id": "711003",
    "label": "Nuangan Barat",
    "value": "Nuangan Barat"
  },
  {
    "id": "7110032019",
    "district_id": "711003",
    "label": "Nuangan Selatan",
    "value": "Nuangan Selatan"
  },
  {
    "id": "7110042001",
    "district_id": "711004",
    "label": "Modayag",
    "value": "Modayag"
  },
  {
    "id": "7110042002",
    "district_id": "711004",
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "id": "7110042003",
    "district_id": "711004",
    "label": "Purworejo",
    "value": "Purworejo"
  },
  {
    "id": "7110042007",
    "district_id": "711004",
    "label": "Buyandi",
    "value": "Buyandi"
  },
  {
    "id": "7110042008",
    "district_id": "711004",
    "label": "Tobongon",
    "value": "Tobongon"
  },
  {
    "id": "7110042009",
    "district_id": "711004",
    "label": "Lanut",
    "value": "Lanut"
  },
  {
    "id": "7110042010",
    "district_id": "711004",
    "label": "Badaro",
    "value": "Badaro"
  },
  {
    "id": "7110042011",
    "district_id": "711004",
    "label": "Modayag Barat",
    "value": "Modayag Barat"
  },
  {
    "id": "7110042012",
    "district_id": "711004",
    "label": "Modayag Timur",
    "value": "Modayag Timur"
  },
  {
    "id": "7110042013",
    "district_id": "711004",
    "label": "Purworejo Timur",
    "value": "Purworejo Timur"
  },
  {
    "id": "7110042014",
    "district_id": "711004",
    "label": "Liberia Timur",
    "value": "Liberia Timur"
  },
  {
    "id": "7110042016",
    "district_id": "711004",
    "label": "Purworejo Tengah",
    "value": "Purworejo Tengah"
  },
  {
    "id": "7110042021",
    "district_id": "711004",
    "label": "Candi Rejo",
    "value": "Candi Rejo"
  },
  {
    "id": "7110042022",
    "district_id": "711004",
    "label": "Sumber Rejo",
    "value": "Sumber Rejo"
  },
  {
    "id": "7110052001",
    "district_id": "711005",
    "label": "Moyongkota",
    "value": "Moyongkota"
  },
  {
    "id": "7110052002",
    "district_id": "711005",
    "label": "Bangunan Wuwuk",
    "value": "Bangunan Wuwuk"
  },
  {
    "id": "7110052003",
    "district_id": "711005",
    "label": "Bangkudai Barat",
    "value": "Bangkudai Barat"
  },
  {
    "id": "7110052004",
    "district_id": "711005",
    "label": "Bongkudai",
    "value": "Bongkudai"
  },
  {
    "id": "7110052005",
    "district_id": "711005",
    "label": "Moyongkota Baru",
    "value": "Moyongkota Baru"
  },
  {
    "id": "7110052006",
    "district_id": "711005",
    "label": "Moonow",
    "value": "Moonow"
  },
  {
    "id": "7110052007",
    "district_id": "711005",
    "label": "Inaton",
    "value": "Inaton"
  },
  {
    "id": "7110052008",
    "district_id": "711005",
    "label": "Bangunan Wuwuk Timur",
    "value": "Bangunan Wuwuk Timur"
  },
  {
    "id": "7110052009",
    "district_id": "711005",
    "label": "Pinonobatuan",
    "value": "Pinonobatuan"
  },
  {
    "id": "7110052010",
    "district_id": "711005",
    "label": "Tangaton",
    "value": "Tangaton"
  },
  {
    "id": "7110062001",
    "district_id": "711006",
    "label": "Motongkad Utara",
    "value": "Motongkad Utara"
  },
  {
    "id": "7110062002",
    "district_id": "711006",
    "label": "Motongkad",
    "value": "Motongkad"
  },
  {
    "id": "7110062003",
    "district_id": "711006",
    "label": "Motongkad Selatan",
    "value": "Motongkad Selatan"
  },
  {
    "id": "7110062004",
    "district_id": "711006",
    "label": "Atoga",
    "value": "Atoga"
  },
  {
    "id": "7110062005",
    "district_id": "711006",
    "label": "Atoga Timur",
    "value": "Atoga Timur"
  },
  {
    "id": "7110062006",
    "district_id": "711006",
    "label": "Motongkad Tengah",
    "value": "Motongkad Tengah"
  },
  {
    "id": "7110062007",
    "district_id": "711006",
    "label": "Molobog",
    "value": "Molobog"
  },
  {
    "id": "7110062008",
    "district_id": "711006",
    "label": "Molobog Barat",
    "value": "Molobog Barat"
  },
  {
    "id": "7110062009",
    "district_id": "711006",
    "label": "Jiko",
    "value": "Jiko"
  },
  {
    "id": "7110062010",
    "district_id": "711006",
    "label": "Jiko Utara",
    "value": "Jiko Utara"
  },
  {
    "id": "7110062011",
    "district_id": "711006",
    "label": "Molobog Timur",
    "value": "Molobog Timur"
  },
  {
    "id": "7110072001",
    "district_id": "711007",
    "label": "Mooat",
    "value": "Mooat"
  },
  {
    "id": "7110072002",
    "district_id": "711007",
    "label": "Bongkudai Selatan",
    "value": "Bongkudai Selatan"
  },
  {
    "id": "7110072003",
    "district_id": "711007",
    "label": "Bongkudai Baru",
    "value": "Bongkudai Baru"
  },
  {
    "id": "7110072004",
    "district_id": "711007",
    "label": "Bongkudai Utara",
    "value": "Bongkudai Utara"
  },
  {
    "id": "7110072005",
    "district_id": "711007",
    "label": "Bongkudai Timur",
    "value": "Bongkudai Timur"
  },
  {
    "id": "7110072006",
    "district_id": "711007",
    "label": "Guaan",
    "value": "Guaan"
  },
  {
    "id": "7110072007",
    "district_id": "711007",
    "label": "Mototompian",
    "value": "Mototompian"
  },
  {
    "id": "7110072008",
    "district_id": "711007",
    "label": "Mokitompia",
    "value": "Mokitompia"
  },
  {
    "id": "7110072009",
    "district_id": "711007",
    "label": "Kokapoi",
    "value": "Kokapoi"
  },
  {
    "id": "7110072010",
    "district_id": "711007",
    "label": "Kokapoi Timur",
    "value": "Kokapoi Timur"
  },
  {
    "id": "7111012004",
    "district_id": "711101",
    "label": "Tangagah",
    "value": "Tangagah"
  },
  {
    "id": "7111012005",
    "district_id": "711101",
    "label": "Salongo",
    "value": "Salongo"
  },
  {
    "id": "7111012006",
    "district_id": "711101",
    "label": "Toluaya",
    "value": "Toluaya"
  },
  {
    "id": "7111012007",
    "district_id": "711101",
    "label": "Molibagu",
    "value": "Molibagu"
  },
  {
    "id": "7111012008",
    "district_id": "711101",
    "label": "Popodu",
    "value": "Popodu"
  },
  {
    "id": "7111012009",
    "district_id": "711101",
    "label": "Tolondadu",
    "value": "Tolondadu"
  },
  {
    "id": "7111012010",
    "district_id": "711101",
    "label": "Tabilaa",
    "value": "Tabilaa"
  },
  {
    "id": "7111012012",
    "district_id": "711101",
    "label": "Sondana",
    "value": "Sondana"
  },
  {
    "id": "7111012013",
    "district_id": "711101",
    "label": "Dudepo",
    "value": "Dudepo"
  },
  {
    "id": "7111012014",
    "district_id": "711101",
    "label": "Pinolantungan",
    "value": "Pinolantungan"
  },
  {
    "id": "7111012015",
    "district_id": "711101",
    "label": "Tolondadu I",
    "value": "Tolondadu I"
  },
  {
    "id": "7111012016",
    "district_id": "711101",
    "label": "Tolondadu II",
    "value": "Tolondadu II"
  },
  {
    "id": "7111012020",
    "district_id": "711101",
    "label": "Soguo",
    "value": "Soguo"
  },
  {
    "id": "7111012022",
    "district_id": "711101",
    "label": "Pintadia",
    "value": "Pintadia"
  },
  {
    "id": "7111012023",
    "district_id": "711101",
    "label": "Salongo Timur",
    "value": "Salongo Timur"
  },
  {
    "id": "7111012027",
    "district_id": "711101",
    "label": "Salongo Barat",
    "value": "Salongo Barat"
  },
  {
    "id": "7111012028",
    "district_id": "711101",
    "label": "Dudepo Barat",
    "value": "Dudepo Barat"
  },
  {
    "id": "7111022001",
    "district_id": "711102",
    "label": "Lion",
    "value": "Lion"
  },
  {
    "id": "7111022002",
    "district_id": "711102",
    "label": "Momalia II",
    "value": "Momalia II"
  },
  {
    "id": "7111022003",
    "district_id": "711102",
    "label": "Meyambanga",
    "value": "Meyambanga"
  },
  {
    "id": "7111022004",
    "district_id": "711102",
    "label": "Saibuah",
    "value": "Saibuah"
  },
  {
    "id": "7111022007",
    "district_id": "711102",
    "label": "Sinombayuga",
    "value": "Sinombayuga"
  },
  {
    "id": "7111022008",
    "district_id": "711102",
    "label": "Luwoo",
    "value": "Luwoo"
  },
  {
    "id": "7111022009",
    "district_id": "711102",
    "label": "Momalia I",
    "value": "Momalia I"
  },
  {
    "id": "7111022011",
    "district_id": "711102",
    "label": "Sakti",
    "value": "Sakti"
  },
  {
    "id": "7111022012",
    "district_id": "711102",
    "label": "Manggadaa",
    "value": "Manggadaa"
  },
  {
    "id": "7111022013",
    "district_id": "711102",
    "label": "Pilolahunga",
    "value": "Pilolahunga"
  },
  {
    "id": "7111022014",
    "district_id": "711102",
    "label": "Tonala",
    "value": "Tonala"
  },
  {
    "id": "7111022016",
    "district_id": "711102",
    "label": "Iloheluma",
    "value": "Iloheluma"
  },
  {
    "id": "7111022017",
    "district_id": "711102",
    "label": "Meyambanga Timur",
    "value": "Meyambanga Timur"
  },
  {
    "id": "7111022019",
    "district_id": "711102",
    "label": "Molosipat",
    "value": "Molosipat"
  },
  {
    "id": "7111022022",
    "district_id": "711102",
    "label": "Momalia Tiga",
    "value": "Momalia Tiga"
  },
  {
    "id": "7111022023",
    "district_id": "711102",
    "label": "Inosota",
    "value": "Inosota"
  },
  {
    "id": "7111032001",
    "district_id": "711103",
    "label": "Linawan",
    "value": "Linawan"
  },
  {
    "id": "7111032002",
    "district_id": "711103",
    "label": "Nunuk",
    "value": "Nunuk"
  },
  {
    "id": "7111032003",
    "district_id": "711103",
    "label": "Pinolosian",
    "value": "Pinolosian"
  },
  {
    "id": "7111032004",
    "district_id": "711103",
    "label": "Kombot",
    "value": "Kombot"
  },
  {
    "id": "7111032005",
    "district_id": "711103",
    "label": "Lungkap",
    "value": "Lungkap"
  },
  {
    "id": "7111032006",
    "district_id": "711103",
    "label": "Ilomata",
    "value": "Ilomata"
  },
  {
    "id": "7111032007",
    "district_id": "711103",
    "label": "Linawan I",
    "value": "Linawan I"
  },
  {
    "id": "7111032008",
    "district_id": "711103",
    "label": "Tolotoyon",
    "value": "Tolotoyon"
  },
  {
    "id": "7111032009",
    "district_id": "711103",
    "label": "Pinolosian Selatan",
    "value": "Pinolosian Selatan"
  },
  {
    "id": "7111032010",
    "district_id": "711103",
    "label": "Kombot Timur",
    "value": "Kombot Timur"
  },
  {
    "id": "7111042001",
    "district_id": "711104",
    "label": "Mataindo",
    "value": "Mataindo"
  },
  {
    "id": "7111042002",
    "district_id": "711104",
    "label": "Adow",
    "value": "Adow"
  },
  {
    "id": "7111042003",
    "district_id": "711104",
    "label": "Torosik",
    "value": "Torosik"
  },
  {
    "id": "7111042004",
    "district_id": "711104",
    "label": "Tobayagan",
    "value": "Tobayagan"
  },
  {
    "id": "7111042005",
    "district_id": "711104",
    "label": "Deaga",
    "value": "Deaga"
  },
  {
    "id": "7111042006",
    "district_id": "711104",
    "label": "Adow Selatan",
    "value": "Adow Selatan"
  },
  {
    "id": "7111042007",
    "district_id": "711104",
    "label": "Mataindo Utara",
    "value": "Mataindo Utara"
  },
  {
    "id": "7111042008",
    "district_id": "711104",
    "label": "Tobayagan Selatan",
    "value": "Tobayagan Selatan"
  },
  {
    "id": "7111052001",
    "district_id": "711105",
    "label": "Matandoi",
    "value": "Matandoi"
  },
  {
    "id": "7111052002",
    "district_id": "711105",
    "label": "Dumagin A",
    "value": "Dumagin A"
  },
  {
    "id": "7111052003",
    "district_id": "711105",
    "label": "Dumagin B",
    "value": "Dumagin B"
  },
  {
    "id": "7111052004",
    "district_id": "711105",
    "label": "Dayow",
    "value": "Dayow"
  },
  {
    "id": "7111052005",
    "district_id": "711105",
    "label": "Onggunoi",
    "value": "Onggunoi"
  },
  {
    "id": "7111052006",
    "district_id": "711105",
    "label": "Modisi",
    "value": "Modisi"
  },
  {
    "id": "7111052007",
    "district_id": "711105",
    "label": "Posilagon",
    "value": "Posilagon"
  },
  {
    "id": "7111052008",
    "district_id": "711105",
    "label": "Pidung",
    "value": "Pidung"
  },
  {
    "id": "7111052009",
    "district_id": "711105",
    "label": "Iligon",
    "value": "Iligon"
  },
  {
    "id": "7111052010",
    "district_id": "711105",
    "label": "Matandoi Selatan",
    "value": "Matandoi Selatan"
  },
  {
    "id": "7111052011",
    "district_id": "711105",
    "label": "Perjuangan",
    "value": "Perjuangan"
  },
  {
    "id": "7111052012",
    "district_id": "711105",
    "label": "Onggunoi Selatan",
    "value": "Onggunoi Selatan"
  },
  {
    "id": "7111062001",
    "district_id": "711106",
    "label": "Biniha Timur",
    "value": "Biniha Timur"
  },
  {
    "id": "7111062002",
    "district_id": "711106",
    "label": "Biniha",
    "value": "Biniha"
  },
  {
    "id": "7111062003",
    "district_id": "711106",
    "label": "Biniha Selatan",
    "value": "Biniha Selatan"
  },
  {
    "id": "7111062004",
    "district_id": "711106",
    "label": "Duminanga",
    "value": "Duminanga"
  },
  {
    "id": "7111062005",
    "district_id": "711106",
    "label": "Halabolu",
    "value": "Halabolu"
  },
  {
    "id": "7111062006",
    "district_id": "711106",
    "label": "Trans Patoa",
    "value": "Trans Patoa"
  },
  {
    "id": "7111062007",
    "district_id": "711106",
    "label": "Bakida",
    "value": "Bakida"
  },
  {
    "id": "7111062008",
    "district_id": "711106",
    "label": "Sinandaka",
    "value": "Sinandaka"
  },
  {
    "id": "7111062009",
    "district_id": "711106",
    "label": "Soputa",
    "value": "Soputa"
  },
  {
    "id": "7111062010",
    "district_id": "711106",
    "label": "Pangia",
    "value": "Pangia"
  },
  {
    "id": "7111062011",
    "district_id": "711106",
    "label": "Motolohu",
    "value": "Motolohu"
  },
  {
    "id": "7111072001",
    "district_id": "711107",
    "label": "Botuliodu",
    "value": "Botuliodu"
  },
  {
    "id": "7111072002",
    "district_id": "711107",
    "label": "Nunuka Raya",
    "value": "Nunuka Raya"
  },
  {
    "id": "7111072003",
    "district_id": "711107",
    "label": "Tolutu",
    "value": "Tolutu"
  },
  {
    "id": "7111072004",
    "district_id": "711107",
    "label": "Milangodaa",
    "value": "Milangodaa"
  },
  {
    "id": "7111072005",
    "district_id": "711107",
    "label": "Milangodaa Barat",
    "value": "Milangodaa Barat"
  },
  {
    "id": "7111072006",
    "district_id": "711107",
    "label": "Milangodaa Utara",
    "value": "Milangodaa Utara"
  },
  {
    "id": "7111072007",
    "district_id": "711107",
    "label": "Pakuku Jaya",
    "value": "Pakuku Jaya"
  },
  {
    "id": "7171011001",
    "district_id": "717101",
    "label": "Molas",
    "value": "Molas"
  },
  {
    "id": "7171011006",
    "district_id": "717101",
    "label": "Tongkaina",
    "value": "Tongkaina"
  },
  {
    "id": "7171011007",
    "district_id": "717101",
    "label": "Meras",
    "value": "Meras"
  },
  {
    "id": "7171011008",
    "district_id": "717101",
    "label": "Bailang",
    "value": "Bailang"
  },
  {
    "id": "7171011009",
    "district_id": "717101",
    "label": "Pandu",
    "value": "Pandu"
  },
  {
    "id": "7171021001",
    "district_id": "717102",
    "label": "Bitung Karangria",
    "value": "Bitung Karangria"
  },
  {
    "id": "7171021002",
    "district_id": "717102",
    "label": "Tuminting",
    "value": "Tuminting"
  },
  {
    "id": "7171021003",
    "district_id": "717102",
    "label": "Tumumpa Satu",
    "value": "Tumumpa Satu"
  },
  {
    "id": "7171021004",
    "district_id": "717102",
    "label": "Maasing",
    "value": "Maasing"
  },
  {
    "id": "7171021005",
    "district_id": "717102",
    "label": "Sindulang Satu",
    "value": "Sindulang Satu"
  },
  {
    "id": "7171021006",
    "district_id": "717102",
    "label": "Sindulang Dua",
    "value": "Sindulang Dua"
  },
  {
    "id": "7171021007",
    "district_id": "717102",
    "label": "Islam",
    "value": "Islam"
  },
  {
    "id": "7171021008",
    "district_id": "717102",
    "label": "Tumumpa Dua",
    "value": "Tumumpa Dua"
  },
  {
    "id": "7171021009",
    "district_id": "717102",
    "label": "Sumompo",
    "value": "Sumompo"
  },
  {
    "id": "7171021010",
    "district_id": "717102",
    "label": "Mahawu",
    "value": "Mahawu"
  },
  {
    "id": "7171031001",
    "district_id": "717103",
    "label": "Singkil Satu",
    "value": "Singkil Satu"
  },
  {
    "id": "7171031002",
    "district_id": "717103",
    "label": "Singkil Dua",
    "value": "Singkil Dua"
  },
  {
    "id": "7171031003",
    "district_id": "717103",
    "label": "Wawonasa",
    "value": "Wawonasa"
  },
  {
    "id": "7171031004",
    "district_id": "717103",
    "label": "Karame",
    "value": "Karame"
  },
  {
    "id": "7171031005",
    "district_id": "717103",
    "label": "Ketang Baru",
    "value": "Ketang Baru"
  },
  {
    "id": "7171031006",
    "district_id": "717103",
    "label": "Ternate Baru",
    "value": "Ternate Baru"
  },
  {
    "id": "7171031007",
    "district_id": "717103",
    "label": "Kombos Barat",
    "value": "Kombos Barat"
  },
  {
    "id": "7171031008",
    "district_id": "717103",
    "label": "Kombos Timur",
    "value": "Kombos Timur"
  },
  {
    "id": "7171031009",
    "district_id": "717103",
    "label": "Ternate Tanjung",
    "value": "Ternate Tanjung"
  },
  {
    "id": "7171041001",
    "district_id": "717104",
    "label": "Tikala Kumaraka",
    "value": "Tikala Kumaraka"
  },
  {
    "id": "7171041002",
    "district_id": "717104",
    "label": "Mahakeret Timur",
    "value": "Mahakeret Timur"
  },
  {
    "id": "7171041003",
    "district_id": "717104",
    "label": "Mahakeret Barat",
    "value": "Mahakeret Barat"
  },
  {
    "id": "7171041004",
    "district_id": "717104",
    "label": "Teling Bawah",
    "value": "Teling Bawah"
  },
  {
    "id": "7171041005",
    "district_id": "717104",
    "label": "Wenang Utara",
    "value": "Wenang Utara"
  },
  {
    "id": "7171041006",
    "district_id": "717104",
    "label": "Wenang Selatan",
    "value": "Wenang Selatan"
  },
  {
    "id": "7171041007",
    "district_id": "717104",
    "label": "Pinaesaan",
    "value": "Pinaesaan"
  },
  {
    "id": "7171041008",
    "district_id": "717104",
    "label": "Calaca",
    "value": "Calaca"
  },
  {
    "id": "7171041009",
    "district_id": "717104",
    "label": "Istiqlal",
    "value": "Istiqlal"
  },
  {
    "id": "7171041010",
    "district_id": "717104",
    "label": "Lawangirung",
    "value": "Lawangirung"
  },
  {
    "id": "7171041011",
    "district_id": "717104",
    "label": "Komo Luar",
    "value": "Komo Luar"
  },
  {
    "id": "7171041012",
    "district_id": "717104",
    "label": "Bumi Beringin",
    "value": "Bumi Beringin"
  },
  {
    "id": "7171051008",
    "district_id": "717105",
    "label": "Tikala Baru",
    "value": "Tikala Baru"
  },
  {
    "id": "7171051009",
    "district_id": "717105",
    "label": "Taas",
    "value": "Taas"
  },
  {
    "id": "7171051010",
    "district_id": "717105",
    "label": "Paal IV",
    "value": "Paal IV"
  },
  {
    "id": "7171051011",
    "district_id": "717105",
    "label": "Banjer",
    "value": "Banjer"
  },
  {
    "id": "7171051012",
    "district_id": "717105",
    "label": "Tikala Ares",
    "value": "Tikala Ares"
  },
  {
    "id": "7171061001",
    "district_id": "717106",
    "label": "Sario Utara",
    "value": "Sario Utara"
  },
  {
    "id": "7171061002",
    "district_id": "717106",
    "label": "Sario Kotabaru",
    "value": "Sario Kotabaru"
  },
  {
    "id": "7171061003",
    "district_id": "717106",
    "label": "Sario Tumpaan",
    "value": "Sario Tumpaan"
  },
  {
    "id": "7171061004",
    "district_id": "717106",
    "label": "Sario",
    "value": "Sario"
  },
  {
    "id": "7171061005",
    "district_id": "717106",
    "label": "Titiwungan Utara",
    "value": "Titiwungan Utara"
  },
  {
    "id": "7171061006",
    "district_id": "717106",
    "label": "Titiwungan Selatan",
    "value": "Titiwungan Selatan"
  },
  {
    "id": "7171061007",
    "district_id": "717106",
    "label": "Ranotana",
    "value": "Ranotana"
  },
  {
    "id": "7171071001",
    "district_id": "717107",
    "label": "Wanea",
    "value": "Wanea"
  },
  {
    "id": "7171071002",
    "district_id": "717107",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "7171071003",
    "district_id": "717107",
    "label": "Pakowa",
    "value": "Pakowa"
  },
  {
    "id": "7171071004",
    "district_id": "717107",
    "label": "Bumi Nyiur",
    "value": "Bumi Nyiur"
  },
  {
    "id": "7171071005",
    "district_id": "717107",
    "label": "Ranotana Weru",
    "value": "Ranotana Weru"
  },
  {
    "id": "7171071006",
    "district_id": "717107",
    "label": "Teling Atas",
    "value": "Teling Atas"
  },
  {
    "id": "7171071007",
    "district_id": "717107",
    "label": "Tingkulu",
    "value": "Tingkulu"
  },
  {
    "id": "7171071008",
    "district_id": "717107",
    "label": "Karombasan Utara",
    "value": "Karombasan Utara"
  },
  {
    "id": "7171071009",
    "district_id": "717107",
    "label": "Karombasan Selatan",
    "value": "Karombasan Selatan"
  },
  {
    "id": "7171081001",
    "district_id": "717108",
    "label": "Paniki Bawah",
    "value": "Paniki Bawah"
  },
  {
    "id": "7171081002",
    "district_id": "717108",
    "label": "Lapangan",
    "value": "Lapangan"
  },
  {
    "id": "7171081003",
    "district_id": "717108",
    "label": "Mapanget Barat",
    "value": "Mapanget Barat"
  },
  {
    "id": "7171081004",
    "district_id": "717108",
    "label": "Kima Atas",
    "value": "Kima Atas"
  },
  {
    "id": "7171081005",
    "district_id": "717108",
    "label": "Buha",
    "value": "Buha"
  },
  {
    "id": "7171081006",
    "district_id": "717108",
    "label": "Bengkol",
    "value": "Bengkol"
  },
  {
    "id": "7171081008",
    "district_id": "717108",
    "label": "Kairagi Satu",
    "value": "Kairagi Satu"
  },
  {
    "id": "7171081009",
    "district_id": "717108",
    "label": "Kairagi Dua",
    "value": "Kairagi Dua"
  },
  {
    "id": "7171081010",
    "district_id": "717108",
    "label": "Paniki Satu",
    "value": "Paniki Satu"
  },
  {
    "id": "7171081011",
    "district_id": "717108",
    "label": "Paniki Dua",
    "value": "Paniki Dua"
  },
  {
    "id": "7171091001",
    "district_id": "717109",
    "label": "Malalayang Satu",
    "value": "Malalayang Satu"
  },
  {
    "id": "7171091002",
    "district_id": "717109",
    "label": "Bahu",
    "value": "Bahu"
  },
  {
    "id": "7171091003",
    "district_id": "717109",
    "label": "Kleak",
    "value": "Kleak"
  },
  {
    "id": "7171091004",
    "district_id": "717109",
    "label": "Batu Kota",
    "value": "Batu Kota"
  },
  {
    "id": "7171091005",
    "district_id": "717109",
    "label": "Malalayang Satu Timur",
    "value": "Malalayang Satu Timur"
  },
  {
    "id": "7171091006",
    "district_id": "717109",
    "label": "Malalayang Satu Barat",
    "value": "Malalayang Satu Barat"
  },
  {
    "id": "7171091007",
    "district_id": "717109",
    "label": "Malalayang Dua",
    "value": "Malalayang Dua"
  },
  {
    "id": "7171091008",
    "district_id": "717109",
    "label": "Winangun Satu",
    "value": "Winangun Satu"
  },
  {
    "id": "7171091009",
    "district_id": "717109",
    "label": "Winangun Dua",
    "value": "Winangun Dua"
  },
  {
    "id": "7171101001",
    "district_id": "717110",
    "label": "Bunaken",
    "value": "Bunaken"
  },
  {
    "id": "7171101002",
    "district_id": "717110",
    "label": "Manado Tua Satu",
    "value": "Manado Tua Satu"
  },
  {
    "id": "7171101003",
    "district_id": "717110",
    "label": "Manado Tua Dua",
    "value": "Manado Tua Dua"
  },
  {
    "id": "7171101004",
    "district_id": "717110",
    "label": "Alung Banua",
    "value": "Alung Banua"
  },
  {
    "id": "7171111001",
    "district_id": "717111",
    "label": "Ranomuut",
    "value": "Ranomuut"
  },
  {
    "id": "7171111002",
    "district_id": "717111",
    "label": "Kairagi Weru",
    "value": "Kairagi Weru"
  },
  {
    "id": "7171111003",
    "district_id": "717111",
    "label": "Paal Dua",
    "value": "Paal Dua"
  },
  {
    "id": "7171111004",
    "district_id": "717111",
    "label": "Perkamil",
    "value": "Perkamil"
  },
  {
    "id": "7171111005",
    "district_id": "717111",
    "label": "Malendeng",
    "value": "Malendeng"
  },
  {
    "id": "7171111006",
    "district_id": "717111",
    "label": "Dendengan Dalam",
    "value": "Dendengan Dalam"
  },
  {
    "id": "7171111007",
    "district_id": "717111",
    "label": "Dendengan Luar",
    "value": "Dendengan Luar"
  },
  {
    "id": "7172011001",
    "district_id": "717201",
    "label": "Pasir Panjang",
    "value": "Pasir Panjang"
  },
  {
    "id": "7172011002",
    "district_id": "717201",
    "label": "Paudean",
    "value": "Paudean"
  },
  {
    "id": "7172011003",
    "district_id": "717201",
    "label": "Batulubang",
    "value": "Batulubang"
  },
  {
    "id": "7172011004",
    "district_id": "717201",
    "label": "Papusungan",
    "value": "Papusungan"
  },
  {
    "id": "7172011006",
    "district_id": "717201",
    "label": "Pancuran",
    "value": "Pancuran"
  },
  {
    "id": "7172011011",
    "district_id": "717201",
    "label": "Dorbolaang",
    "value": "Dorbolaang"
  },
  {
    "id": "7172011016",
    "district_id": "717201",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "7172021001",
    "district_id": "717202",
    "label": "Wangurer Barat",
    "value": "Wangurer Barat"
  },
  {
    "id": "7172021002",
    "district_id": "717202",
    "label": "Paceda",
    "value": "Paceda"
  },
  {
    "id": "7172021003",
    "district_id": "717202",
    "label": "Madidir Ure",
    "value": "Madidir Ure"
  },
  {
    "id": "7172021004",
    "district_id": "717202",
    "label": "Kadoodan",
    "value": "Kadoodan"
  },
  {
    "id": "7172021006",
    "district_id": "717202",
    "label": "Madidir Weru",
    "value": "Madidir Weru"
  },
  {
    "id": "7172021007",
    "district_id": "717202",
    "label": "Madidir Unet",
    "value": "Madidir Unet"
  },
  {
    "id": "7172021009",
    "district_id": "717202",
    "label": "Wangurer timur",
    "value": "Wangurer timur"
  },
  {
    "id": "7172021011",
    "district_id": "717202",
    "label": "Wangurer Utara",
    "value": "Wangurer Utara"
  },
  {
    "id": "7172031001",
    "district_id": "717203",
    "label": "Karondoran",
    "value": "Karondoran"
  },
  {
    "id": "7172031002",
    "district_id": "717203",
    "label": "Kumeresot",
    "value": "Kumeresot"
  },
  {
    "id": "7172031004",
    "district_id": "717203",
    "label": "Pinokalan",
    "value": "Pinokalan"
  },
  {
    "id": "7172031005",
    "district_id": "717203",
    "label": "Tewaan",
    "value": "Tewaan"
  },
  {
    "id": "7172031006",
    "district_id": "717203",
    "label": "Danowudu",
    "value": "Danowudu"
  },
  {
    "id": "7172031007",
    "district_id": "717203",
    "label": "Duasadara",
    "value": "Duasadara"
  },
  {
    "id": "7172031008",
    "district_id": "717203",
    "label": "Apela Dua",
    "value": "Apela Dua"
  },
  {
    "id": "7172031009",
    "district_id": "717203",
    "label": "Apela Satu",
    "value": "Apela Satu"
  },
  {
    "id": "7172031010",
    "district_id": "717203",
    "label": "Pinasungkulan",
    "value": "Pinasungkulan"
  },
  {
    "id": "7172031011",
    "district_id": "717203",
    "label": "Batuputih Atas",
    "value": "Batuputih Atas"
  },
  {
    "id": "7172031012",
    "district_id": "717203",
    "label": "Batuputih Bawah",
    "value": "Batuputih Bawah"
  },
  {
    "id": "7172041003",
    "district_id": "717204",
    "label": "Pateten Satu",
    "value": "Pateten Satu"
  },
  {
    "id": "7172041004",
    "district_id": "717204",
    "label": "Winenet Satu",
    "value": "Winenet Satu"
  },
  {
    "id": "7172041005",
    "district_id": "717204",
    "label": "Aertembaga Satu",
    "value": "Aertembaga Satu"
  },
  {
    "id": "7172041006",
    "district_id": "717204",
    "label": "Tandurusa",
    "value": "Tandurusa"
  },
  {
    "id": "7172041007",
    "district_id": "717204",
    "label": "Makawidey",
    "value": "Makawidey"
  },
  {
    "id": "7172041008",
    "district_id": "717204",
    "label": "Pinangunian",
    "value": "Pinangunian"
  },
  {
    "id": "7172041011",
    "district_id": "717204",
    "label": "Pateten Dua",
    "value": "Pateten Dua"
  },
  {
    "id": "7172041012",
    "district_id": "717204",
    "label": "Winenet Dua",
    "value": "Winenet Dua"
  },
  {
    "id": "7172041013",
    "district_id": "717204",
    "label": "Kasawari",
    "value": "Kasawari"
  },
  {
    "id": "7172041014",
    "district_id": "717204",
    "label": "Aertembaga Dua",
    "value": "Aertembaga Dua"
  },
  {
    "id": "7172051001",
    "district_id": "717205",
    "label": "Tanjung Merah",
    "value": "Tanjung Merah"
  },
  {
    "id": "7172051002",
    "district_id": "717205",
    "label": "Sagerat",
    "value": "Sagerat"
  },
  {
    "id": "7172051003",
    "district_id": "717205",
    "label": "Manembo-nembo Atas",
    "value": "Manembo-nembo Atas"
  },
  {
    "id": "7172051007",
    "district_id": "717205",
    "label": "Manembo-nembo",
    "value": "Manembo-nembo"
  },
  {
    "id": "7172051011",
    "district_id": "717205",
    "label": "Sagerat Weru Satu",
    "value": "Sagerat Weru Satu"
  },
  {
    "id": "7172051012",
    "district_id": "717205",
    "label": "Sagerat Weru Dua",
    "value": "Sagerat Weru Dua"
  },
  {
    "id": "7172051013",
    "district_id": "717205",
    "label": "Manembo-nembo Tengah",
    "value": "Manembo-nembo Tengah"
  },
  {
    "id": "7172051014",
    "district_id": "717205",
    "label": "Tendeki",
    "value": "Tendeki"
  },
  {
    "id": "7172061001",
    "district_id": "717206",
    "label": "Girian Atas",
    "value": "Girian Atas"
  },
  {
    "id": "7172061002",
    "district_id": "717206",
    "label": "Girian Weru Satu",
    "value": "Girian Weru Satu"
  },
  {
    "id": "7172061003",
    "district_id": "717206",
    "label": "Girian Bawah",
    "value": "Girian Bawah"
  },
  {
    "id": "7172061004",
    "district_id": "717206",
    "label": "Girian Permai",
    "value": "Girian Permai"
  },
  {
    "id": "7172061005",
    "district_id": "717206",
    "label": "Girian Weru Dua",
    "value": "Girian Weru Dua"
  },
  {
    "id": "7172061006",
    "district_id": "717206",
    "label": "Girian Indah",
    "value": "Girian Indah"
  },
  {
    "id": "7172061007",
    "district_id": "717206",
    "label": "Wangurer",
    "value": "Wangurer"
  },
  {
    "id": "7172071001",
    "district_id": "717207",
    "label": "Bitung Barat Satu",
    "value": "Bitung Barat Satu"
  },
  {
    "id": "7172071002",
    "district_id": "717207",
    "label": "Pakadoodan",
    "value": "Pakadoodan"
  },
  {
    "id": "7172071003",
    "district_id": "717207",
    "label": "Bitung Barat Dua",
    "value": "Bitung Barat Dua"
  },
  {
    "id": "7172071004",
    "district_id": "717207",
    "label": "Bitung Tengah",
    "value": "Bitung Tengah"
  },
  {
    "id": "7172071005",
    "district_id": "717207",
    "label": "Bitung Timur",
    "value": "Bitung Timur"
  },
  {
    "id": "7172071006",
    "district_id": "717207",
    "label": "Kakenturan Satu",
    "value": "Kakenturan Satu"
  },
  {
    "id": "7172071007",
    "district_id": "717207",
    "label": "Kekenturan Dua",
    "value": "Kekenturan Dua"
  },
  {
    "id": "7172071008",
    "district_id": "717207",
    "label": "Pateten Tiga",
    "value": "Pateten Tiga"
  },
  {
    "id": "7172081001",
    "district_id": "717208",
    "label": "Mawali",
    "value": "Mawali"
  },
  {
    "id": "7172081002",
    "district_id": "717208",
    "label": "Pintukota",
    "value": "Pintukota"
  },
  {
    "id": "7172081003",
    "district_id": "717208",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "7172081004",
    "district_id": "717208",
    "label": "Motto",
    "value": "Motto"
  },
  {
    "id": "7172081005",
    "district_id": "717208",
    "label": "Lirang",
    "value": "Lirang"
  },
  {
    "id": "7172081006",
    "district_id": "717208",
    "label": "Posokan",
    "value": "Posokan"
  },
  {
    "id": "7172081007",
    "district_id": "717208",
    "label": "Nusu",
    "value": "Nusu"
  },
  {
    "id": "7172081008",
    "district_id": "717208",
    "label": "Kareko",
    "value": "Kareko"
  },
  {
    "id": "7172081009",
    "district_id": "717208",
    "label": "Gunung Woka",
    "value": "Gunung Woka"
  },
  {
    "id": "7172081010",
    "district_id": "717208",
    "label": "Batukota",
    "value": "Batukota"
  },
  {
    "id": "7173011001",
    "district_id": "717301",
    "label": "Pinaras",
    "value": "Pinaras"
  },
  {
    "id": "7173011002",
    "district_id": "717301",
    "label": "Lahendong",
    "value": "Lahendong"
  },
  {
    "id": "7173011003",
    "district_id": "717301",
    "label": "Tondangow",
    "value": "Tondangow"
  },
  {
    "id": "7173011004",
    "district_id": "717301",
    "label": "Pangolombian",
    "value": "Pangolombian"
  },
  {
    "id": "7173011005",
    "district_id": "717301",
    "label": "Tumatangtang",
    "value": "Tumatangtang"
  },
  {
    "id": "7173011006",
    "district_id": "717301",
    "label": "Kampung Jawa",
    "value": "Kampung Jawa"
  },
  {
    "id": "7173011007",
    "district_id": "717301",
    "label": "Lansot",
    "value": "Lansot"
  },
  {
    "id": "7173011008",
    "district_id": "717301",
    "label": "Walian",
    "value": "Walian"
  },
  {
    "id": "7173011009",
    "district_id": "717301",
    "label": "Uluindano",
    "value": "Uluindano"
  },
  {
    "id": "7173011010",
    "district_id": "717301",
    "label": "Walian Satu",
    "value": "Walian Satu"
  },
  {
    "id": "7173011011",
    "district_id": "717301",
    "label": "Walian Dua",
    "value": "Walian Dua"
  },
  {
    "id": "7173011012",
    "district_id": "717301",
    "label": "Tumatangtang Satu",
    "value": "Tumatangtang Satu"
  },
  {
    "id": "7173021009",
    "district_id": "717302",
    "label": "Talete Satu",
    "value": "Talete Satu"
  },
  {
    "id": "7173021010",
    "district_id": "717302",
    "label": "Talete Dua",
    "value": "Talete Dua"
  },
  {
    "id": "7173021011",
    "district_id": "717302",
    "label": "Kamasi",
    "value": "Kamasi"
  },
  {
    "id": "7173021012",
    "district_id": "717302",
    "label": "Kolongan",
    "value": "Kolongan"
  },
  {
    "id": "7173021015",
    "district_id": "717302",
    "label": "Matani Satu",
    "value": "Matani Satu"
  },
  {
    "id": "7173021016",
    "district_id": "717302",
    "label": "Matani Dua",
    "value": "Matani Dua"
  },
  {
    "id": "7173021017",
    "district_id": "717302",
    "label": "Matani Tiga",
    "value": "Matani Tiga"
  },
  {
    "id": "7173021018",
    "district_id": "717302",
    "label": "Kamasi Satu",
    "value": "Kamasi Satu"
  },
  {
    "id": "7173021019",
    "district_id": "717302",
    "label": "Kolongan Satu",
    "value": "Kolongan Satu"
  },
  {
    "id": "7173031001",
    "district_id": "717303",
    "label": "Tinoor Satu",
    "value": "Tinoor Satu"
  },
  {
    "id": "7173031002",
    "district_id": "717303",
    "label": "Tinoor Dua",
    "value": "Tinoor Dua"
  },
  {
    "id": "7173031003",
    "district_id": "717303",
    "label": "Kinilow",
    "value": "Kinilow"
  },
  {
    "id": "7173031004",
    "district_id": "717303",
    "label": "Kakaskasen Satu",
    "value": "Kakaskasen Satu"
  },
  {
    "id": "7173031005",
    "district_id": "717303",
    "label": "Kakaskasen Dua",
    "value": "Kakaskasen Dua"
  },
  {
    "id": "7173031006",
    "district_id": "717303",
    "label": "Kakaskasen Tiga",
    "value": "Kakaskasen Tiga"
  },
  {
    "id": "7173031007",
    "district_id": "717303",
    "label": "Wailan",
    "value": "Wailan"
  },
  {
    "id": "7173031008",
    "district_id": "717303",
    "label": "Kayawu",
    "value": "Kayawu"
  },
  {
    "id": "7173031009",
    "district_id": "717303",
    "label": "Kinilow Satu",
    "value": "Kinilow Satu"
  },
  {
    "id": "7173031010",
    "district_id": "717303",
    "label": "Kakaskasen",
    "value": "Kakaskasen"
  },
  {
    "id": "7173041001",
    "district_id": "717304",
    "label": "Woloan Satu",
    "value": "Woloan Satu"
  },
  {
    "id": "7173041002",
    "district_id": "717304",
    "label": "Woloan Dua",
    "value": "Woloan Dua"
  },
  {
    "id": "7173041003",
    "district_id": "717304",
    "label": "Woloan Tiga",
    "value": "Woloan Tiga"
  },
  {
    "id": "7173041004",
    "district_id": "717304",
    "label": "Taratara Satu",
    "value": "Taratara Satu"
  },
  {
    "id": "7173041005",
    "district_id": "717304",
    "label": "Taratara Dua",
    "value": "Taratara Dua"
  },
  {
    "id": "7173041006",
    "district_id": "717304",
    "label": "Woloan Satu Utara",
    "value": "Woloan Satu Utara"
  },
  {
    "id": "7173041007",
    "district_id": "717304",
    "label": "Taratara",
    "value": "Taratara"
  },
  {
    "id": "7173041008",
    "district_id": "717304",
    "label": "Taratara Tiga",
    "value": "Taratara Tiga"
  },
  {
    "id": "7173051001",
    "district_id": "717305",
    "label": "Paslaten Satu",
    "value": "Paslaten Satu"
  },
  {
    "id": "7173051002",
    "district_id": "717305",
    "label": "Paslaten Dua",
    "value": "Paslaten Dua"
  },
  {
    "id": "7173051003",
    "district_id": "717305",
    "label": "Rurukan",
    "value": "Rurukan"
  },
  {
    "id": "7173051004",
    "district_id": "717305",
    "label": "Rurukan Satu",
    "value": "Rurukan Satu"
  },
  {
    "id": "7173051005",
    "district_id": "717305",
    "label": "Kumelembuay",
    "value": "Kumelembuay"
  },
  {
    "id": "7174011005",
    "district_id": "717401",
    "label": "Biga",
    "value": "Biga"
  },
  {
    "id": "7174011006",
    "district_id": "717401",
    "label": "Upai",
    "value": "Upai"
  },
  {
    "id": "7174011007",
    "district_id": "717401",
    "label": "Genggulang",
    "value": "Genggulang"
  },
  {
    "id": "7174012001",
    "district_id": "717401",
    "label": "Bilalang Satu",
    "value": "Bilalang Satu"
  },
  {
    "id": "7174012002",
    "district_id": "717401",
    "label": "Bilalang Dua",
    "value": "Bilalang Dua"
  },
  {
    "id": "7174012003",
    "district_id": "717401",
    "label": "Pontodon",
    "value": "Pontodon"
  },
  {
    "id": "7174012004",
    "district_id": "717401",
    "label": "Sia",
    "value": "Sia"
  },
  {
    "id": "7174012008",
    "district_id": "717401",
    "label": "Pontodon Timur",
    "value": "Pontodon Timur"
  },
  {
    "id": "7174021003",
    "district_id": "717402",
    "label": "Kotobangun",
    "value": "Kotobangun"
  },
  {
    "id": "7174021004",
    "district_id": "717402",
    "label": "Tumubui",
    "value": "Tumubui"
  },
  {
    "id": "7174021005",
    "district_id": "717402",
    "label": "Sinindian",
    "value": "Sinindian"
  },
  {
    "id": "7174021006",
    "district_id": "717402",
    "label": "Matali",
    "value": "Matali"
  },
  {
    "id": "7174021007",
    "district_id": "717402",
    "label": "Motoboi Besar",
    "value": "Motoboi Besar"
  },
  {
    "id": "7174021008",
    "district_id": "717402",
    "label": "Kobo Besar",
    "value": "Kobo Besar"
  },
  {
    "id": "7174022001",
    "district_id": "717402",
    "label": "Moyag",
    "value": "Moyag"
  },
  {
    "id": "7174022002",
    "district_id": "717402",
    "label": "Kobo Kecil",
    "value": "Kobo Kecil"
  },
  {
    "id": "7174022009",
    "district_id": "717402",
    "label": "Moyag Tampoan",
    "value": "Moyag Tampoan"
  },
  {
    "id": "7174022010",
    "district_id": "717402",
    "label": "Moyag Todulan",
    "value": "Moyag Todulan"
  },
  {
    "id": "7174031007",
    "district_id": "717403",
    "label": "Motoboi Kecil",
    "value": "Motoboi Kecil"
  },
  {
    "id": "7174031008",
    "district_id": "717403",
    "label": "Mongondow",
    "value": "Mongondow"
  },
  {
    "id": "7174031009",
    "district_id": "717403",
    "label": "Pobundayan",
    "value": "Pobundayan"
  },
  {
    "id": "7174032001",
    "district_id": "717403",
    "label": "Poyowa Besar Satu",
    "value": "Poyowa Besar Satu"
  },
  {
    "id": "7174032002",
    "district_id": "717403",
    "label": "Poyowa Besar Dua",
    "value": "Poyowa Besar Dua"
  },
  {
    "id": "7174032003",
    "district_id": "717403",
    "label": "Tabang",
    "value": "Tabang"
  },
  {
    "id": "7174032004",
    "district_id": "717403",
    "label": "Bungko",
    "value": "Bungko"
  },
  {
    "id": "7174032005",
    "district_id": "717403",
    "label": "Kopandakan Satu",
    "value": "Kopandakan Satu"
  },
  {
    "id": "7174032006",
    "district_id": "717403",
    "label": "Poyowa Kecil",
    "value": "Poyowa Kecil"
  },
  {
    "id": "7174041001",
    "district_id": "717404",
    "label": "Mongkonai",
    "value": "Mongkonai"
  },
  {
    "id": "7174041002",
    "district_id": "717404",
    "label": "Molinow",
    "value": "Molinow"
  },
  {
    "id": "7174041003",
    "district_id": "717404",
    "label": "Mogolaing",
    "value": "Mogolaing"
  },
  {
    "id": "7174041004",
    "district_id": "717404",
    "label": "Gogagoman",
    "value": "Gogagoman"
  },
  {
    "id": "7174041005",
    "district_id": "717404",
    "label": "Kotamobagu",
    "value": "Kotamobagu"
  },
  {
    "id": "7174041006",
    "district_id": "717404",
    "label": "Mongkonai Barat",
    "value": "Mongkonai Barat"
  },
  {
    "id": "7201011006",
    "district_id": "720101",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "7201011007",
    "district_id": "720101",
    "label": "Balantang",
    "value": "Balantang"
  },
  {
    "id": "7201011008",
    "district_id": "720101",
    "label": "Batui",
    "value": "Batui"
  },
  {
    "id": "7201011009",
    "district_id": "720101",
    "label": "Tolando",
    "value": "Tolando"
  },
  {
    "id": "7201011010",
    "district_id": "720101",
    "label": "Lamo",
    "value": "Lamo"
  },
  {
    "id": "7201011017",
    "district_id": "720101",
    "label": "Sisipan",
    "value": "Sisipan"
  },
  {
    "id": "7201011023",
    "district_id": "720101",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "7201012002",
    "district_id": "720101",
    "label": "Nonong",
    "value": "Nonong"
  },
  {
    "id": "7201012003",
    "district_id": "720101",
    "label": "Kayowa",
    "value": "Kayowa"
  },
  {
    "id": "7201012005",
    "district_id": "720101",
    "label": "Ondo-Ondolu",
    "value": "Ondo-Ondolu"
  },
  {
    "id": "7201012011",
    "district_id": "720101",
    "label": "Honbola",
    "value": "Honbola"
  },
  {
    "id": "7201012012",
    "district_id": "720101",
    "label": "Uso",
    "value": "Uso"
  },
  {
    "id": "7201012016",
    "district_id": "720101",
    "label": "Ondo-Ondolu I",
    "value": "Ondo-Ondolu I"
  },
  {
    "id": "7201021014",
    "district_id": "720102",
    "label": "Bunta I",
    "value": "Bunta I"
  },
  {
    "id": "7201021025",
    "district_id": "720102",
    "label": "Bunta II",
    "value": "Bunta II"
  },
  {
    "id": "7201021047",
    "district_id": "720102",
    "label": "Salabenda",
    "value": "Salabenda"
  },
  {
    "id": "7201021048",
    "district_id": "720102",
    "label": "Kalaka",
    "value": "Kalaka"
  },
  {
    "id": "7201022015",
    "district_id": "720102",
    "label": "Bohotokong",
    "value": "Bohotokong"
  },
  {
    "id": "7201022016",
    "district_id": "720102",
    "label": "Dondo Soboli",
    "value": "Dondo Soboli"
  },
  {
    "id": "7201022017",
    "district_id": "720102",
    "label": "Laonggo",
    "value": "Laonggo"
  },
  {
    "id": "7201022023",
    "district_id": "720102",
    "label": "Hion",
    "value": "Hion"
  },
  {
    "id": "7201022024",
    "district_id": "720102",
    "label": "Tombongan Ulos",
    "value": "Tombongan Ulos"
  },
  {
    "id": "7201022026",
    "district_id": "720102",
    "label": "Pongian",
    "value": "Pongian"
  },
  {
    "id": "7201022027",
    "district_id": "720102",
    "label": "Tuntung",
    "value": "Tuntung"
  },
  {
    "id": "7201022028",
    "district_id": "720102",
    "label": "Nanga-Nangaon",
    "value": "Nanga-Nangaon"
  },
  {
    "id": "7201022029",
    "district_id": "720102",
    "label": "Toima",
    "value": "Toima"
  },
  {
    "id": "7201022030",
    "district_id": "720102",
    "label": "Matabas",
    "value": "Matabas"
  },
  {
    "id": "7201022031",
    "district_id": "720102",
    "label": "Huhak",
    "value": "Huhak"
  },
  {
    "id": "7201022032",
    "district_id": "720102",
    "label": "Lontio",
    "value": "Lontio"
  },
  {
    "id": "7201022041",
    "district_id": "720102",
    "label": "Demangan Jaya",
    "value": "Demangan Jaya"
  },
  {
    "id": "7201022042",
    "district_id": "720102",
    "label": "Balanga",
    "value": "Balanga"
  },
  {
    "id": "7201022043",
    "district_id": "720102",
    "label": "Longgolian",
    "value": "Longgolian"
  },
  {
    "id": "7201022044",
    "district_id": "720102",
    "label": "Kalumbangan",
    "value": "Kalumbangan"
  },
  {
    "id": "7201022045",
    "district_id": "720102",
    "label": "Polo",
    "value": "Polo"
  },
  {
    "id": "7201022049",
    "district_id": "720102",
    "label": "Koili",
    "value": "Koili"
  },
  {
    "id": "7201031006",
    "district_id": "720103",
    "label": "Kintom",
    "value": "Kintom"
  },
  {
    "id": "7201031010",
    "district_id": "720103",
    "label": "Mendono",
    "value": "Mendono"
  },
  {
    "id": "7201031017",
    "district_id": "720103",
    "label": "Mondonun",
    "value": "Mondonun"
  },
  {
    "id": "7201032001",
    "district_id": "720103",
    "label": "Kalolos",
    "value": "Kalolos"
  },
  {
    "id": "7201032002",
    "district_id": "720103",
    "label": "Tangkiang",
    "value": "Tangkiang"
  },
  {
    "id": "7201032003",
    "district_id": "720103",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "7201032004",
    "district_id": "720103",
    "label": "Manyula",
    "value": "Manyula"
  },
  {
    "id": "7201032005",
    "district_id": "720103",
    "label": "Uling",
    "value": "Uling"
  },
  {
    "id": "7201032007",
    "district_id": "720103",
    "label": "Samadoya",
    "value": "Samadoya"
  },
  {
    "id": "7201032008",
    "district_id": "720103",
    "label": "Dimpalon",
    "value": "Dimpalon"
  },
  {
    "id": "7201032009",
    "district_id": "720103",
    "label": "Solan",
    "value": "Solan"
  },
  {
    "id": "7201032013",
    "district_id": "720103",
    "label": "Babang Buyangge",
    "value": "Babang Buyangge"
  },
  {
    "id": "7201032015",
    "district_id": "720103",
    "label": "Solan Baru",
    "value": "Solan Baru"
  },
  {
    "id": "7201032019",
    "district_id": "720103",
    "label": "Dimpalon Baru",
    "value": "Dimpalon Baru"
  },
  {
    "id": "7201041009",
    "district_id": "720104",
    "label": "Luwuk",
    "value": "Luwuk"
  },
  {
    "id": "7201041010",
    "district_id": "720104",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7201041011",
    "district_id": "720104",
    "label": "Soho",
    "value": "Soho"
  },
  {
    "id": "7201041012",
    "district_id": "720104",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "7201041031",
    "district_id": "720104",
    "label": "Karaton",
    "value": "Karaton"
  },
  {
    "id": "7201041033",
    "district_id": "720104",
    "label": "Kaleke",
    "value": "Kaleke"
  },
  {
    "id": "7201041034",
    "district_id": "720104",
    "label": "Bungin Timur",
    "value": "Bungin Timur"
  },
  {
    "id": "7201041035",
    "district_id": "720104",
    "label": "Mangkio Baru",
    "value": "Mangkio Baru"
  },
  {
    "id": "7201042008",
    "district_id": "720104",
    "label": "Tontouan",
    "value": "Tontouan"
  },
  {
    "id": "7201042013",
    "district_id": "720104",
    "label": "Lumpoknyo",
    "value": "Lumpoknyo"
  },
  {
    "id": "7201052009",
    "district_id": "720105",
    "label": "Nipa",
    "value": "Nipa"
  },
  {
    "id": "7201052010",
    "district_id": "720105",
    "label": "Kagitakan",
    "value": "Kagitakan"
  },
  {
    "id": "7201052011",
    "district_id": "720105",
    "label": "Sirom",
    "value": "Sirom"
  },
  {
    "id": "7201052012",
    "district_id": "720105",
    "label": "Poroan",
    "value": "Poroan"
  },
  {
    "id": "7201052013",
    "district_id": "720105",
    "label": "Lomba",
    "value": "Lomba"
  },
  {
    "id": "7201052014",
    "district_id": "720105",
    "label": "Bonebobakal",
    "value": "Bonebobakal"
  },
  {
    "id": "7201052015",
    "district_id": "720105",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7201052016",
    "district_id": "720105",
    "label": "Kotabaru",
    "value": "Kotabaru"
  },
  {
    "id": "7201052017",
    "district_id": "720105",
    "label": "Labotan",
    "value": "Labotan"
  },
  {
    "id": "7201052023",
    "district_id": "720105",
    "label": "Tinonda",
    "value": "Tinonda"
  },
  {
    "id": "7201052027",
    "district_id": "720105",
    "label": "Bahari Makmur",
    "value": "Bahari Makmur"
  },
  {
    "id": "7201052028",
    "district_id": "720105",
    "label": "Kota Raya",
    "value": "Kota Raya"
  },
  {
    "id": "7201061020",
    "district_id": "720106",
    "label": "Balantak",
    "value": "Balantak"
  },
  {
    "id": "7201061030",
    "district_id": "720106",
    "label": "Dale-Dale",
    "value": "Dale-Dale"
  },
  {
    "id": "7201061031",
    "district_id": "720106",
    "label": "Talang Batu",
    "value": "Talang Batu"
  },
  {
    "id": "7201062009",
    "district_id": "720106",
    "label": "Talima A",
    "value": "Talima A"
  },
  {
    "id": "7201062010",
    "district_id": "720106",
    "label": "Talima B",
    "value": "Talima B"
  },
  {
    "id": "7201062013",
    "district_id": "720106",
    "label": "Dolom",
    "value": "Dolom"
  },
  {
    "id": "7201062014",
    "district_id": "720106",
    "label": "Kiloma",
    "value": "Kiloma"
  },
  {
    "id": "7201062015",
    "district_id": "720106",
    "label": "Ra'u",
    "value": "Ra'u"
  },
  {
    "id": "7201062016",
    "district_id": "720106",
    "label": "Tanotu",
    "value": "Tanotu"
  },
  {
    "id": "7201062017",
    "district_id": "720106",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "7201062018",
    "district_id": "720106",
    "label": "Mamping",
    "value": "Mamping"
  },
  {
    "id": "7201062021",
    "district_id": "720106",
    "label": "Luok",
    "value": "Luok"
  },
  {
    "id": "7201062027",
    "district_id": "720106",
    "label": "Boloak",
    "value": "Boloak"
  },
  {
    "id": "7201071011",
    "district_id": "720107",
    "label": "Pakowa",
    "value": "Pakowa"
  },
  {
    "id": "7201071018",
    "district_id": "720107",
    "label": "Pagimana",
    "value": "Pagimana"
  },
  {
    "id": "7201071022",
    "district_id": "720107",
    "label": "Basabungan",
    "value": "Basabungan"
  },
  {
    "id": "7201072001",
    "district_id": "720107",
    "label": "Baloa Doda",
    "value": "Baloa Doda"
  },
  {
    "id": "7201072002",
    "district_id": "720107",
    "label": "Tampe",
    "value": "Tampe"
  },
  {
    "id": "7201072010",
    "district_id": "720107",
    "label": "Asaan",
    "value": "Asaan"
  },
  {
    "id": "7201072012",
    "district_id": "720107",
    "label": "Balai Gondi",
    "value": "Balai Gondi"
  },
  {
    "id": "7201072013",
    "district_id": "720107",
    "label": "Bajo Poat",
    "value": "Bajo Poat"
  },
  {
    "id": "7201072014",
    "district_id": "720107",
    "label": "Gomuo",
    "value": "Gomuo"
  },
  {
    "id": "7201072015",
    "district_id": "720107",
    "label": "Nain",
    "value": "Nain"
  },
  {
    "id": "7201072016",
    "district_id": "720107",
    "label": "Lamo",
    "value": "Lamo"
  },
  {
    "id": "7201072017",
    "district_id": "720107",
    "label": "Tongkonunuk",
    "value": "Tongkonunuk"
  },
  {
    "id": "7201072019",
    "district_id": "720107",
    "label": "Hohudongan",
    "value": "Hohudongan"
  },
  {
    "id": "7201072020",
    "district_id": "720107",
    "label": "Sinampangnyo",
    "value": "Sinampangnyo"
  },
  {
    "id": "7201072021",
    "district_id": "720107",
    "label": "Jaya Bakti",
    "value": "Jaya Bakti"
  },
  {
    "id": "7201072023",
    "district_id": "720107",
    "label": "Pisou",
    "value": "Pisou"
  },
  {
    "id": "7201072024",
    "district_id": "720107",
    "label": "Lambangan",
    "value": "Lambangan"
  },
  {
    "id": "7201072025",
    "district_id": "720107",
    "label": "Toipan",
    "value": "Toipan"
  },
  {
    "id": "7201072026",
    "district_id": "720107",
    "label": "Siuna",
    "value": "Siuna"
  },
  {
    "id": "7201072027",
    "district_id": "720107",
    "label": "Pinapuan",
    "value": "Pinapuan"
  },
  {
    "id": "7201072029",
    "district_id": "720107",
    "label": "Sepa",
    "value": "Sepa"
  },
  {
    "id": "7201072030",
    "district_id": "720107",
    "label": "Taloyon",
    "value": "Taloyon"
  },
  {
    "id": "7201072031",
    "district_id": "720107",
    "label": "Uwedaka",
    "value": "Uwedaka"
  },
  {
    "id": "7201072032",
    "district_id": "720107",
    "label": "Tintingan",
    "value": "Tintingan"
  },
  {
    "id": "7201072033",
    "district_id": "720107",
    "label": "Bungawon",
    "value": "Bungawon"
  },
  {
    "id": "7201072034",
    "district_id": "720107",
    "label": "Huhak",
    "value": "Huhak"
  },
  {
    "id": "7201072035",
    "district_id": "720107",
    "label": "Poh",
    "value": "Poh"
  },
  {
    "id": "7201072037",
    "district_id": "720107",
    "label": "Bondat",
    "value": "Bondat"
  },
  {
    "id": "7201072038",
    "district_id": "720107",
    "label": "Sammajatem",
    "value": "Sammajatem"
  },
  {
    "id": "7201072039",
    "district_id": "720107",
    "label": "Bulu",
    "value": "Bulu"
  },
  {
    "id": "7201072041",
    "district_id": "720107",
    "label": "Tombang",
    "value": "Tombang"
  },
  {
    "id": "7201072043",
    "district_id": "720107",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "7201072044",
    "district_id": "720107",
    "label": "Dongkalan",
    "value": "Dongkalan"
  },
  {
    "id": "7201082001",
    "district_id": "720108",
    "label": "Bualemo A",
    "value": "Bualemo A"
  },
  {
    "id": "7201082002",
    "district_id": "720108",
    "label": "Tikupan",
    "value": "Tikupan"
  },
  {
    "id": "7201082003",
    "district_id": "720108",
    "label": "Trans Mayayap",
    "value": "Trans Mayayap"
  },
  {
    "id": "7201082004",
    "district_id": "720108",
    "label": "Toiba",
    "value": "Toiba"
  },
  {
    "id": "7201082005",
    "district_id": "720108",
    "label": "Longkoga Barat",
    "value": "Longkoga Barat"
  },
  {
    "id": "7201082006",
    "district_id": "720108",
    "label": "Longkoga Timur",
    "value": "Longkoga Timur"
  },
  {
    "id": "7201082007",
    "district_id": "720108",
    "label": "Bualemo B",
    "value": "Bualemo B"
  },
  {
    "id": "7201082008",
    "district_id": "720108",
    "label": "Taima",
    "value": "Taima"
  },
  {
    "id": "7201082009",
    "district_id": "720108",
    "label": "Sampaka",
    "value": "Sampaka"
  },
  {
    "id": "7201082010",
    "district_id": "720108",
    "label": "Malik",
    "value": "Malik"
  },
  {
    "id": "7201082011",
    "district_id": "720108",
    "label": "Binsil",
    "value": "Binsil"
  },
  {
    "id": "7201082012",
    "district_id": "720108",
    "label": "Binsil Padang",
    "value": "Binsil Padang"
  },
  {
    "id": "7201082013",
    "district_id": "720108",
    "label": "Lembah Tompotika",
    "value": "Lembah Tompotika"
  },
  {
    "id": "7201082014",
    "district_id": "720108",
    "label": "Mayayap",
    "value": "Mayayap"
  },
  {
    "id": "7201082015",
    "district_id": "720108",
    "label": "Bima Karya",
    "value": "Bima Karya"
  },
  {
    "id": "7201082016",
    "district_id": "720108",
    "label": "Salipi",
    "value": "Salipi"
  },
  {
    "id": "7201082017",
    "district_id": "720108",
    "label": "Nipa Kalemoan",
    "value": "Nipa Kalemoan"
  },
  {
    "id": "7201082018",
    "district_id": "720108",
    "label": "Malik Makmur",
    "value": "Malik Makmur"
  },
  {
    "id": "7201082019",
    "district_id": "720108",
    "label": "Lembah Makmur",
    "value": "Lembah Makmur"
  },
  {
    "id": "7201082020",
    "district_id": "720108",
    "label": "Dwi Karya",
    "value": "Dwi Karya"
  },
  {
    "id": "7201091054",
    "district_id": "720109",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "7201092001",
    "district_id": "720109",
    "label": "Cendana Pura",
    "value": "Cendana Pura"
  },
  {
    "id": "7201092011",
    "district_id": "720109",
    "label": "Sentral Sari",
    "value": "Sentral Sari"
  },
  {
    "id": "7201092012",
    "district_id": "720109",
    "label": "Tohiti Sari",
    "value": "Tohiti Sari"
  },
  {
    "id": "7201092013",
    "district_id": "720109",
    "label": "Tirta Kencana",
    "value": "Tirta Kencana"
  },
  {
    "id": "7201092014",
    "district_id": "720109",
    "label": "Singkoyo",
    "value": "Singkoyo"
  },
  {
    "id": "7201092015",
    "district_id": "720109",
    "label": "Tolisu",
    "value": "Tolisu"
  },
  {
    "id": "7201092016",
    "district_id": "720109",
    "label": "Piondo",
    "value": "Piondo"
  },
  {
    "id": "7201092017",
    "district_id": "720109",
    "label": "Tirta Sari",
    "value": "Tirta Sari"
  },
  {
    "id": "7201092026",
    "district_id": "720109",
    "label": "Rusa Kencana",
    "value": "Rusa Kencana"
  },
  {
    "id": "7201092027",
    "district_id": "720109",
    "label": "Bukit Jaya",
    "value": "Bukit Jaya"
  },
  {
    "id": "7201092036",
    "district_id": "720109",
    "label": "Samalore",
    "value": "Samalore"
  },
  {
    "id": "7201092037",
    "district_id": "720109",
    "label": "Ue Mea",
    "value": "Ue Mea"
  },
  {
    "id": "7201092038",
    "district_id": "720109",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7201092039",
    "district_id": "720109",
    "label": "Mansahang",
    "value": "Mansahang"
  },
  {
    "id": "7201092041",
    "district_id": "720109",
    "label": "Sindang Baru",
    "value": "Sindang Baru"
  },
  {
    "id": "7201092045",
    "district_id": "720109",
    "label": "Tanah Abang",
    "value": "Tanah Abang"
  },
  {
    "id": "7201092047",
    "district_id": "720109",
    "label": "Jaya Kencana",
    "value": "Jaya Kencana"
  },
  {
    "id": "7201092048",
    "district_id": "720109",
    "label": "Mekar Kencana",
    "value": "Mekar Kencana"
  },
  {
    "id": "7201092049",
    "district_id": "720109",
    "label": "Marga Kencana",
    "value": "Marga Kencana"
  },
  {
    "id": "7201092050",
    "district_id": "720109",
    "label": "Sari Buana",
    "value": "Sari Buana"
  },
  {
    "id": "7201092051",
    "district_id": "720109",
    "label": "Sentral Timur",
    "value": "Sentral Timur"
  },
  {
    "id": "7201092055",
    "district_id": "720109",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "7201092056",
    "district_id": "720109",
    "label": "Tirta Jaya",
    "value": "Tirta Jaya"
  },
  {
    "id": "7201092057",
    "district_id": "720109",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "7201102001",
    "district_id": "720110",
    "label": "Tangeban",
    "value": "Tangeban"
  },
  {
    "id": "7201102002",
    "district_id": "720110",
    "label": "Taugi",
    "value": "Taugi"
  },
  {
    "id": "7201102003",
    "district_id": "720110",
    "label": "Eteng",
    "value": "Eteng"
  },
  {
    "id": "7201102004",
    "district_id": "720110",
    "label": "Minang Andala",
    "value": "Minang Andala"
  },
  {
    "id": "7201102005",
    "district_id": "720110",
    "label": "Kospa Duatakarya",
    "value": "Kospa Duatakarya"
  },
  {
    "id": "7201102006",
    "district_id": "720110",
    "label": "Purwo Agung",
    "value": "Purwo Agung"
  },
  {
    "id": "7201102007",
    "district_id": "720110",
    "label": "Kembang Merta",
    "value": "Kembang Merta"
  },
  {
    "id": "7201102008",
    "district_id": "720110",
    "label": "Simpangan",
    "value": "Simpangan"
  },
  {
    "id": "7201102009",
    "district_id": "720110",
    "label": "Tompotika Makmur",
    "value": "Tompotika Makmur"
  },
  {
    "id": "7201102010",
    "district_id": "720110",
    "label": "Duata Karya",
    "value": "Duata Karya"
  },
  {
    "id": "7201102011",
    "district_id": "720110",
    "label": "Serese",
    "value": "Serese"
  },
  {
    "id": "7201102012",
    "district_id": "720110",
    "label": "Cemerlang",
    "value": "Cemerlang"
  },
  {
    "id": "7201102013",
    "district_id": "720110",
    "label": "Padangon",
    "value": "Padangon"
  },
  {
    "id": "7201102014",
    "district_id": "720110",
    "label": "Rangga-Rangga",
    "value": "Rangga-Rangga"
  },
  {
    "id": "7201112001",
    "district_id": "720111",
    "label": "Kayutanyo",
    "value": "Kayutanyo"
  },
  {
    "id": "7201112002",
    "district_id": "720111",
    "label": "Pohi",
    "value": "Pohi"
  },
  {
    "id": "7201112003",
    "district_id": "720111",
    "label": "Hunduhon",
    "value": "Hunduhon"
  },
  {
    "id": "7201112004",
    "district_id": "720111",
    "label": "Baya",
    "value": "Baya"
  },
  {
    "id": "7201112005",
    "district_id": "720111",
    "label": "Uwedikan",
    "value": "Uwedikan"
  },
  {
    "id": "7201112006",
    "district_id": "720111",
    "label": "Bantayan",
    "value": "Bantayan"
  },
  {
    "id": "7201112007",
    "district_id": "720111",
    "label": "Lauwon",
    "value": "Lauwon"
  },
  {
    "id": "7201112008",
    "district_id": "720111",
    "label": "Boitan",
    "value": "Boitan"
  },
  {
    "id": "7201112009",
    "district_id": "720111",
    "label": "Molino",
    "value": "Molino"
  },
  {
    "id": "7201112010",
    "district_id": "720111",
    "label": "Louk",
    "value": "Louk"
  },
  {
    "id": "7201112011",
    "district_id": "720111",
    "label": "Lontos",
    "value": "Lontos"
  },
  {
    "id": "7201112012",
    "district_id": "720111",
    "label": "Indang Sari",
    "value": "Indang Sari"
  },
  {
    "id": "7201112013",
    "district_id": "720111",
    "label": "Bukit Mulya",
    "value": "Bukit Mulya"
  },
  {
    "id": "7201122001",
    "district_id": "720112",
    "label": "Dongin",
    "value": "Dongin"
  },
  {
    "id": "7201122002",
    "district_id": "720112",
    "label": "Pandan Wangi",
    "value": "Pandan Wangi"
  },
  {
    "id": "7201122003",
    "district_id": "720112",
    "label": "Kami Wangi",
    "value": "Kami Wangi"
  },
  {
    "id": "7201122004",
    "district_id": "720112",
    "label": "Sindang Sari",
    "value": "Sindang Sari"
  },
  {
    "id": "7201122005",
    "district_id": "720112",
    "label": "Makapa",
    "value": "Makapa"
  },
  {
    "id": "7201122006",
    "district_id": "720112",
    "label": "Karya Makmur",
    "value": "Karya Makmur"
  },
  {
    "id": "7201122007",
    "district_id": "720112",
    "label": "Mantawa",
    "value": "Mantawa"
  },
  {
    "id": "7201122008",
    "district_id": "720112",
    "label": "Pasir Lamba",
    "value": "Pasir Lamba"
  },
  {
    "id": "7201122009",
    "district_id": "720112",
    "label": "Mantawa Bonebae",
    "value": "Mantawa Bonebae"
  },
  {
    "id": "7201122010",
    "district_id": "720112",
    "label": "Gunung Kramat",
    "value": "Gunung Kramat"
  },
  {
    "id": "7201122011",
    "district_id": "720112",
    "label": "Lembah Kramat",
    "value": "Lembah Kramat"
  },
  {
    "id": "7201122012",
    "district_id": "720112",
    "label": "Rata",
    "value": "Rata"
  },
  {
    "id": "7201122013",
    "district_id": "720112",
    "label": "Bukit Makarti",
    "value": "Bukit Makarti"
  },
  {
    "id": "7201122014",
    "district_id": "720112",
    "label": "Uwe Lolu",
    "value": "Uwe Lolu"
  },
  {
    "id": "7201122015",
    "district_id": "720112",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "7201122016",
    "district_id": "720112",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "7201122017",
    "district_id": "720112",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7201132001",
    "district_id": "720113",
    "label": "Bangketa",
    "value": "Bangketa"
  },
  {
    "id": "7201132002",
    "district_id": "720113",
    "label": "Tobelombang",
    "value": "Tobelombang"
  },
  {
    "id": "7201132003",
    "district_id": "720113",
    "label": "Balaan",
    "value": "Balaan"
  },
  {
    "id": "7201132004",
    "district_id": "720113",
    "label": "Mantan B",
    "value": "Mantan B"
  },
  {
    "id": "7201132005",
    "district_id": "720113",
    "label": "Tomeang",
    "value": "Tomeang"
  },
  {
    "id": "7201132006",
    "district_id": "720113",
    "label": "Pakowa Bunta",
    "value": "Pakowa Bunta"
  },
  {
    "id": "7201132007",
    "district_id": "720113",
    "label": "Pibombo",
    "value": "Pibombo"
  },
  {
    "id": "7201132008",
    "district_id": "720113",
    "label": "Bolobungkang",
    "value": "Bolobungkang"
  },
  {
    "id": "7201132009",
    "district_id": "720113",
    "label": "Petak",
    "value": "Petak"
  },
  {
    "id": "7201132010",
    "district_id": "720113",
    "label": "Bella",
    "value": "Bella"
  },
  {
    "id": "7201132011",
    "district_id": "720113",
    "label": "Binohu",
    "value": "Binohu"
  },
  {
    "id": "7201132012",
    "district_id": "720113",
    "label": "Damai Makmur",
    "value": "Damai Makmur"
  },
  {
    "id": "7201132013",
    "district_id": "720113",
    "label": "Jaya Makmur",
    "value": "Jaya Makmur"
  },
  {
    "id": "7201132014",
    "district_id": "720113",
    "label": "Saiti",
    "value": "Saiti"
  },
  {
    "id": "7201132015",
    "district_id": "720113",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "7201132016",
    "district_id": "720113",
    "label": "Batu Hitam",
    "value": "Batu Hitam"
  },
  {
    "id": "7201132017",
    "district_id": "720113",
    "label": "Kabua Bua",
    "value": "Kabua Bua"
  },
  {
    "id": "7201132018",
    "district_id": "720113",
    "label": "Pulo Dalagan",
    "value": "Pulo Dalagan"
  },
  {
    "id": "7201132019",
    "district_id": "720113",
    "label": "Obo Balingara",
    "value": "Obo Balingara"
  },
  {
    "id": "7201132020",
    "district_id": "720113",
    "label": "Tetesulu",
    "value": "Tetesulu"
  },
  {
    "id": "7201142001",
    "district_id": "720114",
    "label": "Saluan",
    "value": "Saluan"
  },
  {
    "id": "7201142002",
    "district_id": "720114",
    "label": "Toili",
    "value": "Toili"
  },
  {
    "id": "7201142003",
    "district_id": "720114",
    "label": "Selamet Raharjo",
    "value": "Selamet Raharjo"
  },
  {
    "id": "7201142004",
    "district_id": "720114",
    "label": "Mulyoharjo",
    "value": "Mulyoharjo"
  },
  {
    "id": "7201142005",
    "district_id": "720114",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "7201142006",
    "district_id": "720114",
    "label": "Bumi Harjo",
    "value": "Bumi Harjo"
  },
  {
    "id": "7201142007",
    "district_id": "720114",
    "label": "Minakarya",
    "value": "Minakarya"
  },
  {
    "id": "7201142008",
    "district_id": "720114",
    "label": "Minahaki",
    "value": "Minahaki"
  },
  {
    "id": "7201142009",
    "district_id": "720114",
    "label": "Arga Kencana",
    "value": "Arga Kencana"
  },
  {
    "id": "7201142010",
    "district_id": "720114",
    "label": "Moilong",
    "value": "Moilong"
  },
  {
    "id": "7201142011",
    "district_id": "720114",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "7201142012",
    "district_id": "720114",
    "label": "Sumber Harjo",
    "value": "Sumber Harjo"
  },
  {
    "id": "7201142013",
    "district_id": "720114",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "7201142014",
    "district_id": "720114",
    "label": "Tou",
    "value": "Tou"
  },
  {
    "id": "7201142015",
    "district_id": "720114",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "7201142016",
    "district_id": "720114",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "7201152001",
    "district_id": "720115",
    "label": "Maasing",
    "value": "Maasing"
  },
  {
    "id": "7201152002",
    "district_id": "720115",
    "label": "Gori-Gori",
    "value": "Gori-Gori"
  },
  {
    "id": "7201152003",
    "district_id": "720115",
    "label": "Sinorang",
    "value": "Sinorang"
  },
  {
    "id": "7201152004",
    "district_id": "720115",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "7201152005",
    "district_id": "720115",
    "label": "Sukamaju Satu",
    "value": "Sukamaju Satu"
  },
  {
    "id": "7201152006",
    "district_id": "720115",
    "label": "Paisu Buloli",
    "value": "Paisu Buloli"
  },
  {
    "id": "7201152007",
    "district_id": "720115",
    "label": "Ombolu",
    "value": "Ombolu"
  },
  {
    "id": "7201152008",
    "district_id": "720115",
    "label": "Bonebalantak",
    "value": "Bonebalantak"
  },
  {
    "id": "7201152009",
    "district_id": "720115",
    "label": "Masungkang",
    "value": "Masungkang"
  },
  {
    "id": "7201152010",
    "district_id": "720115",
    "label": "Maleo Jaya",
    "value": "Maleo Jaya"
  },
  {
    "id": "7201162001",
    "district_id": "720116",
    "label": "Lobu",
    "value": "Lobu"
  },
  {
    "id": "7201162002",
    "district_id": "720116",
    "label": "Bolobungkang",
    "value": "Bolobungkang"
  },
  {
    "id": "7201162003",
    "district_id": "720116",
    "label": "Niubulan",
    "value": "Niubulan"
  },
  {
    "id": "7201162004",
    "district_id": "720116",
    "label": "Kadodi",
    "value": "Kadodi"
  },
  {
    "id": "7201162005",
    "district_id": "720116",
    "label": "Uha Uhangon",
    "value": "Uha Uhangon"
  },
  {
    "id": "7201162006",
    "district_id": "720116",
    "label": "Bahingin",
    "value": "Bahingin"
  },
  {
    "id": "7201162007",
    "district_id": "720116",
    "label": "Uwe Daka-Daka",
    "value": "Uwe Daka-Daka"
  },
  {
    "id": "7201162008",
    "district_id": "720116",
    "label": "Balean",
    "value": "Balean"
  },
  {
    "id": "7201162009",
    "district_id": "720116",
    "label": "Dolom",
    "value": "Dolom"
  },
  {
    "id": "7201162010",
    "district_id": "720116",
    "label": "Lambuli",
    "value": "Lambuli"
  },
  {
    "id": "7201172001",
    "district_id": "720117",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "7201172002",
    "district_id": "720117",
    "label": "Rantau Jaya",
    "value": "Rantau Jaya"
  },
  {
    "id": "7201172003",
    "district_id": "720117",
    "label": "Koninis",
    "value": "Koninis"
  },
  {
    "id": "7201172004",
    "district_id": "720117",
    "label": "Gonohop",
    "value": "Gonohop"
  },
  {
    "id": "7201172005",
    "district_id": "720117",
    "label": "Sumber Mulia",
    "value": "Sumber Mulia"
  },
  {
    "id": "7201172006",
    "district_id": "720117",
    "label": "Doda Bunta",
    "value": "Doda Bunta"
  },
  {
    "id": "7201172007",
    "district_id": "720117",
    "label": "Simpang Satu",
    "value": "Simpang Satu"
  },
  {
    "id": "7201172008",
    "district_id": "720117",
    "label": "Simpang Dua",
    "value": "Simpang Dua"
  },
  {
    "id": "7201172009",
    "district_id": "720117",
    "label": "Dowiwi",
    "value": "Dowiwi"
  },
  {
    "id": "7201172010",
    "district_id": "720117",
    "label": "Mantan A",
    "value": "Mantan A"
  },
  {
    "id": "7201172011",
    "district_id": "720117",
    "label": "Dwipa Karya",
    "value": "Dwipa Karya"
  },
  {
    "id": "7201172012",
    "district_id": "720117",
    "label": "Lokait",
    "value": "Lokait"
  },
  {
    "id": "7201182001",
    "district_id": "720118",
    "label": "Tombos",
    "value": "Tombos"
  },
  {
    "id": "7201182002",
    "district_id": "720118",
    "label": "Dondo",
    "value": "Dondo"
  },
  {
    "id": "7201182003",
    "district_id": "720118",
    "label": "Sepe",
    "value": "Sepe"
  },
  {
    "id": "7201182004",
    "district_id": "720118",
    "label": "Booy",
    "value": "Booy"
  },
  {
    "id": "7201182005",
    "district_id": "720118",
    "label": "Tintingon",
    "value": "Tintingon"
  },
  {
    "id": "7201182006",
    "district_id": "720118",
    "label": "Resarna",
    "value": "Resarna"
  },
  {
    "id": "7201182007",
    "district_id": "720118",
    "label": "Giwang",
    "value": "Giwang"
  },
  {
    "id": "7201182008",
    "district_id": "720118",
    "label": "Tongke",
    "value": "Tongke"
  },
  {
    "id": "7201182009",
    "district_id": "720118",
    "label": "Gorontalo",
    "value": "Gorontalo"
  },
  {
    "id": "7201182010",
    "district_id": "720118",
    "label": "Poyang",
    "value": "Poyang"
  },
  {
    "id": "7201182011",
    "district_id": "720118",
    "label": "Tanggawas",
    "value": "Tanggawas"
  },
  {
    "id": "7201192001",
    "district_id": "720119",
    "label": "Kampangar",
    "value": "Kampangar"
  },
  {
    "id": "7201192002",
    "district_id": "720119",
    "label": "Kuntang",
    "value": "Kuntang"
  },
  {
    "id": "7201192003",
    "district_id": "720119",
    "label": "Pulau Dua",
    "value": "Pulau Dua"
  },
  {
    "id": "7201192004",
    "district_id": "720119",
    "label": "Teku",
    "value": "Teku"
  },
  {
    "id": "7201192005",
    "district_id": "720119",
    "label": "Ondoliang",
    "value": "Ondoliang"
  },
  {
    "id": "7201192006",
    "district_id": "720119",
    "label": "Batu Simpang",
    "value": "Batu Simpang"
  },
  {
    "id": "7201192007",
    "district_id": "720119",
    "label": "Batu Mandi",
    "value": "Batu Mandi"
  },
  {
    "id": "7201192008",
    "district_id": "720119",
    "label": "Pangkalaseang",
    "value": "Pangkalaseang"
  },
  {
    "id": "7201192009",
    "district_id": "720119",
    "label": "Pangkalaseang Baru",
    "value": "Pangkalaseang Baru"
  },
  {
    "id": "7201192010",
    "district_id": "720119",
    "label": "Toweer",
    "value": "Toweer"
  },
  {
    "id": "7201201002",
    "district_id": "720120",
    "label": "Simpong",
    "value": "Simpong"
  },
  {
    "id": "7201201003",
    "district_id": "720120",
    "label": "Hanga Hanga",
    "value": "Hanga Hanga"
  },
  {
    "id": "7201201004",
    "district_id": "720120",
    "label": "Jole",
    "value": "Jole"
  },
  {
    "id": "7201201005",
    "district_id": "720120",
    "label": "Hanga-Hanga Permai",
    "value": "Hanga-Hanga Permai"
  },
  {
    "id": "7201201006",
    "district_id": "720120",
    "label": "Kompo",
    "value": "Kompo"
  },
  {
    "id": "7201201007",
    "district_id": "720120",
    "label": "Bukit Mambual",
    "value": "Bukit Mambual"
  },
  {
    "id": "7201201008",
    "district_id": "720120",
    "label": "Tombang Permai",
    "value": "Tombang Permai"
  },
  {
    "id": "7201201009",
    "district_id": "720120",
    "label": "Maahas",
    "value": "Maahas"
  },
  {
    "id": "7201201010",
    "district_id": "720120",
    "label": "Tanjung Tuwis",
    "value": "Tanjung Tuwis"
  },
  {
    "id": "7201202001",
    "district_id": "720120",
    "label": "Bubung",
    "value": "Bubung"
  },
  {
    "id": "7201211010",
    "district_id": "720121",
    "label": "Kilongan",
    "value": "Kilongan"
  },
  {
    "id": "7201211011",
    "district_id": "720121",
    "label": "Kilongan Permai",
    "value": "Kilongan Permai"
  },
  {
    "id": "7201212001",
    "district_id": "720121",
    "label": "Biak",
    "value": "Biak"
  },
  {
    "id": "7201212002",
    "district_id": "720121",
    "label": "Bunga",
    "value": "Bunga"
  },
  {
    "id": "7201212003",
    "district_id": "720121",
    "label": "Kamumu",
    "value": "Kamumu"
  },
  {
    "id": "7201212004",
    "district_id": "720121",
    "label": "Salodik",
    "value": "Salodik"
  },
  {
    "id": "7201212005",
    "district_id": "720121",
    "label": "Lenyek",
    "value": "Lenyek"
  },
  {
    "id": "7201212006",
    "district_id": "720121",
    "label": "Buon Mandiri",
    "value": "Buon Mandiri"
  },
  {
    "id": "7201212007",
    "district_id": "720121",
    "label": "Boyou",
    "value": "Boyou"
  },
  {
    "id": "7201212008",
    "district_id": "720121",
    "label": "Awu",
    "value": "Awu"
  },
  {
    "id": "7201212009",
    "district_id": "720121",
    "label": "Bumi Beringin",
    "value": "Bumi Beringin"
  },
  {
    "id": "7201222001",
    "district_id": "720122",
    "label": "Pondan",
    "value": "Pondan"
  },
  {
    "id": "7201222002",
    "district_id": "720122",
    "label": "Sobol",
    "value": "Sobol"
  },
  {
    "id": "7201222003",
    "district_id": "720122",
    "label": "Sobol Baru",
    "value": "Sobol Baru"
  },
  {
    "id": "7201222004",
    "district_id": "720122",
    "label": "Binotik",
    "value": "Binotik"
  },
  {
    "id": "7201222005",
    "district_id": "720122",
    "label": "Garuga",
    "value": "Garuga"
  },
  {
    "id": "7201222006",
    "district_id": "720122",
    "label": "Bollo",
    "value": "Bollo"
  },
  {
    "id": "7201222007",
    "district_id": "720122",
    "label": "Sulubombong",
    "value": "Sulubombong"
  },
  {
    "id": "7201222008",
    "district_id": "720122",
    "label": "Boras",
    "value": "Boras"
  },
  {
    "id": "7201222009",
    "district_id": "720122",
    "label": "Lonas",
    "value": "Lonas"
  },
  {
    "id": "7201222010",
    "district_id": "720122",
    "label": "Bombongan",
    "value": "Bombongan"
  },
  {
    "id": "7201231003",
    "district_id": "720123",
    "label": "Nambo Padang",
    "value": "Nambo Padang"
  },
  {
    "id": "7201231004",
    "district_id": "720123",
    "label": "Nambo Bosaa",
    "value": "Nambo Bosaa"
  },
  {
    "id": "7201231005",
    "district_id": "720123",
    "label": "Nambo Lempek",
    "value": "Nambo Lempek"
  },
  {
    "id": "7201231006",
    "district_id": "720123",
    "label": "Nambo Lempek Baru",
    "value": "Nambo Lempek Baru"
  },
  {
    "id": "7201231007",
    "district_id": "720123",
    "label": "Lontio",
    "value": "Lontio"
  },
  {
    "id": "7201231008",
    "district_id": "720123",
    "label": "Lontio Baru",
    "value": "Lontio Baru"
  },
  {
    "id": "7201232001",
    "district_id": "720123",
    "label": "Koyoan",
    "value": "Koyoan"
  },
  {
    "id": "7201232002",
    "district_id": "720123",
    "label": "Koyoan Permai",
    "value": "Koyoan Permai"
  },
  {
    "id": "7201232009",
    "district_id": "720123",
    "label": "Padungnyo",
    "value": "Padungnyo"
  },
  {
    "id": "7201232010",
    "district_id": "720123",
    "label": "Sayambongin",
    "value": "Sayambongin"
  },
  {
    "id": "7201232011",
    "district_id": "720123",
    "label": "Lumbe",
    "value": "Lumbe"
  },
  {
    "id": "7202011001",
    "district_id": "720201",
    "label": "Moengko Lama",
    "value": "Moengko Lama"
  },
  {
    "id": "7202011002",
    "district_id": "720201",
    "label": "Moengko Baru",
    "value": "Moengko Baru"
  },
  {
    "id": "7202011003",
    "district_id": "720201",
    "label": "Kayamanya",
    "value": "Kayamanya"
  },
  {
    "id": "7202011007",
    "district_id": "720201",
    "label": "Gebangrejo",
    "value": "Gebangrejo"
  },
  {
    "id": "7202011016",
    "district_id": "720201",
    "label": "Gebangrejo Timur",
    "value": "Gebangrejo Timur"
  },
  {
    "id": "7202011017",
    "district_id": "720201",
    "label": "Gebangrejo Barat",
    "value": "Gebangrejo Barat"
  },
  {
    "id": "7202011018",
    "district_id": "720201",
    "label": "Kayamanya Sentral",
    "value": "Kayamanya Sentral"
  },
  {
    "id": "7202021008",
    "district_id": "720202",
    "label": "Kasinguncu",
    "value": "Kasinguncu"
  },
  {
    "id": "7202021009",
    "district_id": "720202",
    "label": "Mapane",
    "value": "Mapane"
  },
  {
    "id": "7202021031",
    "district_id": "720202",
    "label": "Tabalu",
    "value": "Tabalu"
  },
  {
    "id": "7202022007",
    "district_id": "720202",
    "label": "Betania",
    "value": "Betania"
  },
  {
    "id": "7202022010",
    "district_id": "720202",
    "label": "Toini",
    "value": "Toini"
  },
  {
    "id": "7202022012",
    "district_id": "720202",
    "label": "Bega",
    "value": "Bega"
  },
  {
    "id": "7202022013",
    "district_id": "720202",
    "label": "Masamba",
    "value": "Masamba"
  },
  {
    "id": "7202022014",
    "district_id": "720202",
    "label": "Saatu",
    "value": "Saatu"
  },
  {
    "id": "7202022015",
    "district_id": "720202",
    "label": "Pinedapa",
    "value": "Pinedapa"
  },
  {
    "id": "7202022016",
    "district_id": "720202",
    "label": "Masani",
    "value": "Masani"
  },
  {
    "id": "7202022017",
    "district_id": "720202",
    "label": "Tokorondo",
    "value": "Tokorondo"
  },
  {
    "id": "7202022018",
    "district_id": "720202",
    "label": "Tiwaa",
    "value": "Tiwaa"
  },
  {
    "id": "7202022027",
    "district_id": "720202",
    "label": "Lanto Jaya",
    "value": "Lanto Jaya"
  },
  {
    "id": "7202022028",
    "district_id": "720202",
    "label": "Lape",
    "value": "Lape"
  },
  {
    "id": "7202022029",
    "district_id": "720202",
    "label": "Towu",
    "value": "Towu"
  },
  {
    "id": "7202022030",
    "district_id": "720202",
    "label": "Ueralulu",
    "value": "Ueralulu"
  },
  {
    "id": "7202032001",
    "district_id": "720203",
    "label": "Tampemadoro",
    "value": "Tampemadoro"
  },
  {
    "id": "7202032002",
    "district_id": "720203",
    "label": "Pandiri",
    "value": "Pandiri"
  },
  {
    "id": "7202032003",
    "district_id": "720203",
    "label": "Watuawu",
    "value": "Watuawu"
  },
  {
    "id": "7202032004",
    "district_id": "720203",
    "label": "Tambaro",
    "value": "Tambaro"
  },
  {
    "id": "7202032005",
    "district_id": "720203",
    "label": "Tagolu",
    "value": "Tagolu"
  },
  {
    "id": "7202032006",
    "district_id": "720203",
    "label": "Maliwuko",
    "value": "Maliwuko"
  },
  {
    "id": "7202032007",
    "district_id": "720203",
    "label": "Silanca",
    "value": "Silanca"
  },
  {
    "id": "7202032008",
    "district_id": "720203",
    "label": "Sepe",
    "value": "Sepe"
  },
  {
    "id": "7202032009",
    "district_id": "720203",
    "label": "Bategencu",
    "value": "Bategencu"
  },
  {
    "id": "7202032010",
    "district_id": "720203",
    "label": "Toyado",
    "value": "Toyado"
  },
  {
    "id": "7202032011",
    "district_id": "720203",
    "label": "Tongko",
    "value": "Tongko"
  },
  {
    "id": "7202032012",
    "district_id": "720203",
    "label": "Malei",
    "value": "Malei"
  },
  {
    "id": "7202032013",
    "district_id": "720203",
    "label": "Sintuwulemba",
    "value": "Sintuwulemba"
  },
  {
    "id": "7202032014",
    "district_id": "720203",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "7202032015",
    "district_id": "720203",
    "label": "Labuadago",
    "value": "Labuadago"
  },
  {
    "id": "7202032017",
    "district_id": "720203",
    "label": "Rato'ombu",
    "value": "Rato'ombu"
  },
  {
    "id": "7202041001",
    "district_id": "720204",
    "label": "Tentena",
    "value": "Tentena"
  },
  {
    "id": "7202041006",
    "district_id": "720204",
    "label": "Pamona",
    "value": "Pamona"
  },
  {
    "id": "7202041008",
    "district_id": "720204",
    "label": "Sangele",
    "value": "Sangele"
  },
  {
    "id": "7202042003",
    "district_id": "720204",
    "label": "Tonusu",
    "value": "Tonusu"
  },
  {
    "id": "7202042004",
    "district_id": "720204",
    "label": "Mayakeli",
    "value": "Mayakeli"
  },
  {
    "id": "7202042005",
    "district_id": "720204",
    "label": "Buyumpondoli",
    "value": "Buyumpondoli"
  },
  {
    "id": "7202042009",
    "district_id": "720204",
    "label": "Peura",
    "value": "Peura"
  },
  {
    "id": "7202042010",
    "district_id": "720204",
    "label": "Dulumai",
    "value": "Dulumai"
  },
  {
    "id": "7202042019",
    "district_id": "720204",
    "label": "Soe",
    "value": "Soe"
  },
  {
    "id": "7202042021",
    "district_id": "720204",
    "label": "Leboni",
    "value": "Leboni"
  },
  {
    "id": "7202042022",
    "district_id": "720204",
    "label": "Wera",
    "value": "Wera"
  },
  {
    "id": "7202052001",
    "district_id": "720205",
    "label": "Taripa",
    "value": "Taripa"
  },
  {
    "id": "7202052002",
    "district_id": "720205",
    "label": "Tiu",
    "value": "Tiu"
  },
  {
    "id": "7202052003",
    "district_id": "720205",
    "label": "Petiro",
    "value": "Petiro"
  },
  {
    "id": "7202052004",
    "district_id": "720205",
    "label": "Pancasila",
    "value": "Pancasila"
  },
  {
    "id": "7202052005",
    "district_id": "720205",
    "label": "Olumokunde",
    "value": "Olumokunde"
  },
  {
    "id": "7202052006",
    "district_id": "720205",
    "label": "Kamba",
    "value": "Kamba"
  },
  {
    "id": "7202052007",
    "district_id": "720205",
    "label": "Didiri",
    "value": "Didiri"
  },
  {
    "id": "7202052008",
    "district_id": "720205",
    "label": "Kelei",
    "value": "Kelei"
  },
  {
    "id": "7202052009",
    "district_id": "720205",
    "label": "Poleganyara",
    "value": "Poleganyara"
  },
  {
    "id": "7202052010",
    "district_id": "720205",
    "label": "Masewe",
    "value": "Masewe"
  },
  {
    "id": "7202052011",
    "district_id": "720205",
    "label": "Matialemba",
    "value": "Matialemba"
  },
  {
    "id": "7202052012",
    "district_id": "720205",
    "label": "Kancuu",
    "value": "Kancuu"
  },
  {
    "id": "7202052013",
    "district_id": "720205",
    "label": "Magapu",
    "value": "Magapu"
  },
  {
    "id": "7202062003",
    "district_id": "720206",
    "label": "Bancea",
    "value": "Bancea"
  },
  {
    "id": "7202062004",
    "district_id": "720206",
    "label": "Boe",
    "value": "Boe"
  },
  {
    "id": "7202062005",
    "district_id": "720206",
    "label": "Pendolo",
    "value": "Pendolo"
  },
  {
    "id": "7202062006",
    "district_id": "720206",
    "label": "Pandayora",
    "value": "Pandayora"
  },
  {
    "id": "7202062007",
    "district_id": "720206",
    "label": "Mayoa",
    "value": "Mayoa"
  },
  {
    "id": "7202062008",
    "district_id": "720206",
    "label": "Uelene",
    "value": "Uelene"
  },
  {
    "id": "7202062018",
    "district_id": "720206",
    "label": "Mayasari",
    "value": "Mayasari"
  },
  {
    "id": "7202062019",
    "district_id": "720206",
    "label": "Pandajaya",
    "value": "Pandajaya"
  },
  {
    "id": "7202062020",
    "district_id": "720206",
    "label": "Mayajaya",
    "value": "Mayajaya"
  },
  {
    "id": "7202062021",
    "district_id": "720206",
    "label": "Bangunjaya",
    "value": "Bangunjaya"
  },
  {
    "id": "7202062022",
    "district_id": "720206",
    "label": "Panjo",
    "value": "Panjo"
  },
  {
    "id": "7202062023",
    "district_id": "720206",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7202072001",
    "district_id": "720207",
    "label": "Dodolo",
    "value": "Dodolo"
  },
  {
    "id": "7202072006",
    "district_id": "720207",
    "label": "Kaduwaa",
    "value": "Kaduwaa"
  },
  {
    "id": "7202072007",
    "district_id": "720207",
    "label": "Watumaeta",
    "value": "Watumaeta"
  },
  {
    "id": "7202072008",
    "district_id": "720207",
    "label": "Sedoa",
    "value": "Sedoa"
  },
  {
    "id": "7202072009",
    "district_id": "720207",
    "label": "Alitupu",
    "value": "Alitupu"
  },
  {
    "id": "7202072014",
    "district_id": "720207",
    "label": "Wuasa",
    "value": "Wuasa"
  },
  {
    "id": "7202072016",
    "district_id": "720207",
    "label": "Bumi Banyusari",
    "value": "Bumi Banyusari"
  },
  {
    "id": "7202082001",
    "district_id": "720208",
    "label": "Hangira",
    "value": "Hangira"
  },
  {
    "id": "7202082002",
    "district_id": "720208",
    "label": "Lempe",
    "value": "Lempe"
  },
  {
    "id": "7202082003",
    "district_id": "720208",
    "label": "Doda",
    "value": "Doda"
  },
  {
    "id": "7202082004",
    "district_id": "720208",
    "label": "Bariri",
    "value": "Bariri"
  },
  {
    "id": "7202082005",
    "district_id": "720208",
    "label": "Torire",
    "value": "Torire"
  },
  {
    "id": "7202082006",
    "district_id": "720208",
    "label": "Katu",
    "value": "Katu"
  },
  {
    "id": "7202082007",
    "district_id": "720208",
    "label": "Rompo",
    "value": "Rompo"
  },
  {
    "id": "7202082008",
    "district_id": "720208",
    "label": "Baleura",
    "value": "Baleura"
  },
  {
    "id": "7202092005",
    "district_id": "720209",
    "label": "Gintu",
    "value": "Gintu"
  },
  {
    "id": "7202092006",
    "district_id": "720209",
    "label": "Runde",
    "value": "Runde"
  },
  {
    "id": "7202092007",
    "district_id": "720209",
    "label": "Badangkaia",
    "value": "Badangkaia"
  },
  {
    "id": "7202092008",
    "district_id": "720209",
    "label": "Bakekau",
    "value": "Bakekau"
  },
  {
    "id": "7202092009",
    "district_id": "720209",
    "label": "Bulili",
    "value": "Bulili"
  },
  {
    "id": "7202092010",
    "district_id": "720209",
    "label": "Bewa",
    "value": "Bewa"
  },
  {
    "id": "7202092011",
    "district_id": "720209",
    "label": "Pada",
    "value": "Pada"
  },
  {
    "id": "7202092012",
    "district_id": "720209",
    "label": "Bomba",
    "value": "Bomba"
  },
  {
    "id": "7202182001",
    "district_id": "720218",
    "label": "Kilo",
    "value": "Kilo"
  },
  {
    "id": "7202182002",
    "district_id": "720218",
    "label": "Kawende",
    "value": "Kawende"
  },
  {
    "id": "7202182003",
    "district_id": "720218",
    "label": "Kalora",
    "value": "Kalora"
  },
  {
    "id": "7202182004",
    "district_id": "720218",
    "label": "Tambarana",
    "value": "Tambarana"
  },
  {
    "id": "7202182005",
    "district_id": "720218",
    "label": "Tumora",
    "value": "Tumora"
  },
  {
    "id": "7202182006",
    "district_id": "720218",
    "label": "Tri Mulya",
    "value": "Tri Mulya"
  },
  {
    "id": "7202182007",
    "district_id": "720218",
    "label": "Bakti Agung",
    "value": "Bakti Agung"
  },
  {
    "id": "7202182009",
    "district_id": "720218",
    "label": "Tobe",
    "value": "Tobe"
  },
  {
    "id": "7202182010",
    "district_id": "720218",
    "label": "Membuke",
    "value": "Membuke"
  },
  {
    "id": "7202182011",
    "district_id": "720218",
    "label": "Maranda",
    "value": "Maranda"
  },
  {
    "id": "7202192001",
    "district_id": "720219",
    "label": "Dewua",
    "value": "Dewua"
  },
  {
    "id": "7202192002",
    "district_id": "720219",
    "label": "Sangginora",
    "value": "Sangginora"
  },
  {
    "id": "7202192003",
    "district_id": "720219",
    "label": "Tangkura",
    "value": "Tangkura"
  },
  {
    "id": "7202192004",
    "district_id": "720219",
    "label": "Malitu",
    "value": "Malitu"
  },
  {
    "id": "7202192005",
    "district_id": "720219",
    "label": "Patiwunga",
    "value": "Patiwunga"
  },
  {
    "id": "7202192006",
    "district_id": "720219",
    "label": "Betalemba",
    "value": "Betalemba"
  },
  {
    "id": "7202192007",
    "district_id": "720219",
    "label": "Pantangolemba",
    "value": "Pantangolemba"
  },
  {
    "id": "7202192008",
    "district_id": "720219",
    "label": "Padalembara",
    "value": "Padalembara"
  },
  {
    "id": "7202192009",
    "district_id": "720219",
    "label": "Taunca",
    "value": "Taunca"
  },
  {
    "id": "7202202001",
    "district_id": "720220",
    "label": "Taipa",
    "value": "Taipa"
  },
  {
    "id": "7202202002",
    "district_id": "720220",
    "label": "Meko",
    "value": "Meko"
  },
  {
    "id": "7202202003",
    "district_id": "720220",
    "label": "Toinasa",
    "value": "Toinasa"
  },
  {
    "id": "7202202004",
    "district_id": "720220",
    "label": "Salukaia",
    "value": "Salukaia"
  },
  {
    "id": "7202202005",
    "district_id": "720220",
    "label": "Uranosari",
    "value": "Uranosari"
  },
  {
    "id": "7202202006",
    "district_id": "720220",
    "label": "Owini",
    "value": "Owini"
  },
  {
    "id": "7202211001",
    "district_id": "720221",
    "label": "Sayo",
    "value": "Sayo"
  },
  {
    "id": "7202211002",
    "district_id": "720221",
    "label": "Bukit Bambu",
    "value": "Bukit Bambu"
  },
  {
    "id": "7202211003",
    "district_id": "720221",
    "label": "Kawua",
    "value": "Kawua"
  },
  {
    "id": "7202211004",
    "district_id": "720221",
    "label": "Ronononcu",
    "value": "Ronononcu"
  },
  {
    "id": "7202211005",
    "district_id": "720221",
    "label": "Lembomawo",
    "value": "Lembomawo"
  },
  {
    "id": "7202221001",
    "district_id": "720222",
    "label": "Bonesompe",
    "value": "Bonesompe"
  },
  {
    "id": "7202221002",
    "district_id": "720222",
    "label": "Kasintuwu",
    "value": "Kasintuwu"
  },
  {
    "id": "7202221003",
    "district_id": "720222",
    "label": "Lombogia",
    "value": "Lombogia"
  },
  {
    "id": "7202221004",
    "district_id": "720222",
    "label": "Lawanga",
    "value": "Lawanga"
  },
  {
    "id": "7202221005",
    "district_id": "720222",
    "label": "Tegalrejo",
    "value": "Tegalrejo"
  },
  {
    "id": "7202221006",
    "district_id": "720222",
    "label": "Madale",
    "value": "Madale"
  },
  {
    "id": "7202221007",
    "district_id": "720222",
    "label": "Lawanga Tawongan",
    "value": "Lawanga Tawongan"
  },
  {
    "id": "7202232001",
    "district_id": "720223",
    "label": "Tuare",
    "value": "Tuare"
  },
  {
    "id": "7202232002",
    "district_id": "720223",
    "label": "Kageroa",
    "value": "Kageroa"
  },
  {
    "id": "7202232003",
    "district_id": "720223",
    "label": "Tomehipi",
    "value": "Tomehipi"
  },
  {
    "id": "7202232004",
    "district_id": "720223",
    "label": "Lengkeka",
    "value": "Lengkeka"
  },
  {
    "id": "7202232005",
    "district_id": "720223",
    "label": "Kolori",
    "value": "Kolori"
  },
  {
    "id": "7202232006",
    "district_id": "720223",
    "label": "Lelio",
    "value": "Lelio"
  },
  {
    "id": "7202242001",
    "district_id": "720224",
    "label": "Winowanga",
    "value": "Winowanga"
  },
  {
    "id": "7202242002",
    "district_id": "720224",
    "label": "Maholo",
    "value": "Maholo"
  },
  {
    "id": "7202242003",
    "district_id": "720224",
    "label": "Tamadue",
    "value": "Tamadue"
  },
  {
    "id": "7202242004",
    "district_id": "720224",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "7202242005",
    "district_id": "720224",
    "label": "Kalemago",
    "value": "Kalemago"
  },
  {
    "id": "7202252001",
    "district_id": "720225",
    "label": "Watutau",
    "value": "Watutau"
  },
  {
    "id": "7202252002",
    "district_id": "720225",
    "label": "Betue",
    "value": "Betue"
  },
  {
    "id": "7202252003",
    "district_id": "720225",
    "label": "Talabosa",
    "value": "Talabosa"
  },
  {
    "id": "7202252004",
    "district_id": "720225",
    "label": "Wanga",
    "value": "Wanga"
  },
  {
    "id": "7202252005",
    "district_id": "720225",
    "label": "Siliwanga",
    "value": "Siliwanga"
  },
  {
    "id": "7202262001",
    "district_id": "720226",
    "label": "Korobono",
    "value": "Korobono"
  },
  {
    "id": "7202262002",
    "district_id": "720226",
    "label": "Omporiwo",
    "value": "Omporiwo"
  },
  {
    "id": "7202262003",
    "district_id": "720226",
    "label": "Wayura",
    "value": "Wayura"
  },
  {
    "id": "7202262004",
    "district_id": "720226",
    "label": "Tokilo",
    "value": "Tokilo"
  },
  {
    "id": "7202262005",
    "district_id": "720226",
    "label": "Tindoli",
    "value": "Tindoli"
  },
  {
    "id": "7202262006",
    "district_id": "720226",
    "label": "Tolambo",
    "value": "Tolambo"
  },
  {
    "id": "7202262007",
    "district_id": "720226",
    "label": "Singkona",
    "value": "Singkona"
  },
  {
    "id": "7202262008",
    "district_id": "720226",
    "label": "Salindu",
    "value": "Salindu"
  },
  {
    "id": "7202262009",
    "district_id": "720226",
    "label": "Barati",
    "value": "Barati"
  },
  {
    "id": "7202271008",
    "district_id": "720227",
    "label": "Sawidago",
    "value": "Sawidago"
  },
  {
    "id": "7202271009",
    "district_id": "720227",
    "label": "Petirodongi",
    "value": "Petirodongi"
  },
  {
    "id": "7202271010",
    "district_id": "720227",
    "label": "Tendeadongi",
    "value": "Tendeadongi"
  },
  {
    "id": "7202272001",
    "district_id": "720227",
    "label": "Saojo",
    "value": "Saojo"
  },
  {
    "id": "7202272002",
    "district_id": "720227",
    "label": "Sulewana",
    "value": "Sulewana"
  },
  {
    "id": "7202272003",
    "district_id": "720227",
    "label": "Sangira",
    "value": "Sangira"
  },
  {
    "id": "7202272004",
    "district_id": "720227",
    "label": "Kuku",
    "value": "Kuku"
  },
  {
    "id": "7202272005",
    "district_id": "720227",
    "label": "Panjoka",
    "value": "Panjoka"
  },
  {
    "id": "7202272006",
    "district_id": "720227",
    "label": "Uelincu",
    "value": "Uelincu"
  },
  {
    "id": "7202272007",
    "district_id": "720227",
    "label": "Lena",
    "value": "Lena"
  },
  {
    "id": "7203042001",
    "district_id": "720304",
    "label": "Pantolobete",
    "value": "Pantolobete"
  },
  {
    "id": "7203042002",
    "district_id": "720304",
    "label": "Bonemarawa",
    "value": "Bonemarawa"
  },
  {
    "id": "7203042003",
    "district_id": "720304",
    "label": "Tinauka",
    "value": "Tinauka"
  },
  {
    "id": "7203042004",
    "district_id": "720304",
    "label": "Lalundu",
    "value": "Lalundu"
  },
  {
    "id": "7203042005",
    "district_id": "720304",
    "label": "Towiora",
    "value": "Towiora"
  },
  {
    "id": "7203042006",
    "district_id": "720304",
    "label": "Minti Makmur",
    "value": "Minti Makmur"
  },
  {
    "id": "7203042007",
    "district_id": "720304",
    "label": "Polanto Jaya",
    "value": "Polanto Jaya"
  },
  {
    "id": "7203042008",
    "district_id": "720304",
    "label": "Polando Jaya",
    "value": "Polando Jaya"
  },
  {
    "id": "7203042009",
    "district_id": "720304",
    "label": "Rio Mukti",
    "value": "Rio Mukti"
  },
  {
    "id": "7203042010",
    "district_id": "720304",
    "label": "Panca Mukti",
    "value": "Panca Mukti"
  },
  {
    "id": "7203042012",
    "district_id": "720304",
    "label": "Ngovi",
    "value": "Ngovi"
  },
  {
    "id": "7203042013",
    "district_id": "720304",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "7203042014",
    "district_id": "720304",
    "label": "Mbulawa",
    "value": "Mbulawa"
  },
  {
    "id": "7203042015",
    "district_id": "720304",
    "label": "Pakava",
    "value": "Pakava"
  },
  {
    "id": "7203062001",
    "district_id": "720306",
    "label": "Kambayang",
    "value": "Kambayang"
  },
  {
    "id": "7203062002",
    "district_id": "720306",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "7203062003",
    "district_id": "720306",
    "label": "Sabang",
    "value": "Sabang"
  },
  {
    "id": "7203062004",
    "district_id": "720306",
    "label": "Sioyong",
    "value": "Sioyong"
  },
  {
    "id": "7203062005",
    "district_id": "720306",
    "label": "Pani'i",
    "value": "Pani'i"
  },
  {
    "id": "7203062006",
    "district_id": "720306",
    "label": "Ponggerang",
    "value": "Ponggerang"
  },
  {
    "id": "7203062007",
    "district_id": "720306",
    "label": "Rerang",
    "value": "Rerang"
  },
  {
    "id": "7203062009",
    "district_id": "720306",
    "label": "Budi Mukti",
    "value": "Budi Mukti"
  },
  {
    "id": "7203062010",
    "district_id": "720306",
    "label": "Lembah Mukti",
    "value": "Lembah Mukti"
  },
  {
    "id": "7203062011",
    "district_id": "720306",
    "label": "Malonas",
    "value": "Malonas"
  },
  {
    "id": "7203062013",
    "district_id": "720306",
    "label": "Parisan Agung",
    "value": "Parisan Agung"
  },
  {
    "id": "7203062014",
    "district_id": "720306",
    "label": "Karya Mukti",
    "value": "Karya Mukti"
  },
  {
    "id": "7203062015",
    "district_id": "720306",
    "label": "Long",
    "value": "Long"
  },
  {
    "id": "7203081020",
    "district_id": "720308",
    "label": "Ganti",
    "value": "Ganti"
  },
  {
    "id": "7203081021",
    "district_id": "720308",
    "label": "Kabonga Besar",
    "value": "Kabonga Besar"
  },
  {
    "id": "7203081022",
    "district_id": "720308",
    "label": "Kabonga Kecil",
    "value": "Kabonga Kecil"
  },
  {
    "id": "7203081023",
    "district_id": "720308",
    "label": "Gunung Bale",
    "value": "Gunung Bale"
  },
  {
    "id": "7203081024",
    "district_id": "720308",
    "label": "Maleni",
    "value": "Maleni"
  },
  {
    "id": "7203081025",
    "district_id": "720308",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "7203081026",
    "district_id": "720308",
    "label": "Boya",
    "value": "Boya"
  },
  {
    "id": "7203081027",
    "district_id": "720308",
    "label": "Labuan Bajo",
    "value": "Labuan Bajo"
  },
  {
    "id": "7203081028",
    "district_id": "720308",
    "label": "Boneoge",
    "value": "Boneoge"
  },
  {
    "id": "7203082012",
    "district_id": "720308",
    "label": "Loli Oge",
    "value": "Loli Oge"
  },
  {
    "id": "7203082013",
    "district_id": "720308",
    "label": "Loli Tasiburi",
    "value": "Loli Tasiburi"
  },
  {
    "id": "7203082029",
    "district_id": "720308",
    "label": "Loli Pesua",
    "value": "Loli Pesua"
  },
  {
    "id": "7203082030",
    "district_id": "720308",
    "label": "Loli Dondo",
    "value": "Loli Dondo"
  },
  {
    "id": "7203082031",
    "district_id": "720308",
    "label": "Loli Saluran",
    "value": "Loli Saluran"
  },
  {
    "id": "7203092001",
    "district_id": "720309",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "7203092008",
    "district_id": "720309",
    "label": "Labuan Salumbone",
    "value": "Labuan Salumbone"
  },
  {
    "id": "7203092009",
    "district_id": "720309",
    "label": "Labuan Panimba",
    "value": "Labuan Panimba"
  },
  {
    "id": "7203092010",
    "district_id": "720309",
    "label": "Labuan Lelea",
    "value": "Labuan Lelea"
  },
  {
    "id": "7203092011",
    "district_id": "720309",
    "label": "Labuan Kungguma",
    "value": "Labuan Kungguma"
  },
  {
    "id": "7203092012",
    "district_id": "720309",
    "label": "Labuan Toposo",
    "value": "Labuan Toposo"
  },
  {
    "id": "7203092013",
    "district_id": "720309",
    "label": "Labuan Lumbubaka",
    "value": "Labuan Lumbubaka"
  },
  {
    "id": "7203102001",
    "district_id": "720310",
    "label": "Dalaka",
    "value": "Dalaka"
  },
  {
    "id": "7203102002",
    "district_id": "720310",
    "label": "Lero",
    "value": "Lero"
  },
  {
    "id": "7203102003",
    "district_id": "720310",
    "label": "Taripa",
    "value": "Taripa"
  },
  {
    "id": "7203102004",
    "district_id": "720310",
    "label": "Sumari",
    "value": "Sumari"
  },
  {
    "id": "7203102005",
    "district_id": "720310",
    "label": "Toaya",
    "value": "Toaya"
  },
  {
    "id": "7203102006",
    "district_id": "720310",
    "label": "Masaingi",
    "value": "Masaingi"
  },
  {
    "id": "7203102007",
    "district_id": "720310",
    "label": "Marana",
    "value": "Marana"
  },
  {
    "id": "7203102008",
    "district_id": "720310",
    "label": "Enu",
    "value": "Enu"
  },
  {
    "id": "7203102016",
    "district_id": "720310",
    "label": "Lero Tatari",
    "value": "Lero Tatari"
  },
  {
    "id": "7203102017",
    "district_id": "720310",
    "label": "Toaya Vunta",
    "value": "Toaya Vunta"
  },
  {
    "id": "7203102018",
    "district_id": "720310",
    "label": "Gumbasa",
    "value": "Gumbasa"
  },
  {
    "id": "7203102019",
    "district_id": "720310",
    "label": "Ape Maliko",
    "value": "Ape Maliko"
  },
  {
    "id": "7203102020",
    "district_id": "720310",
    "label": "Kavaya",
    "value": "Kavaya"
  },
  {
    "id": "7203112001",
    "district_id": "720311",
    "label": "Ombo",
    "value": "Ombo"
  },
  {
    "id": "7203112002",
    "district_id": "720311",
    "label": "Tondo",
    "value": "Tondo"
  },
  {
    "id": "7203112003",
    "district_id": "720311",
    "label": "Jonooge",
    "value": "Jonooge"
  },
  {
    "id": "7203112004",
    "district_id": "720311",
    "label": "Dampal",
    "value": "Dampal"
  },
  {
    "id": "7203112005",
    "district_id": "720311",
    "label": "Sipi",
    "value": "Sipi"
  },
  {
    "id": "7203112006",
    "district_id": "720311",
    "label": "Tanjung Padang",
    "value": "Tanjung Padang"
  },
  {
    "id": "7203112007",
    "district_id": "720311",
    "label": "Balintuma",
    "value": "Balintuma"
  },
  {
    "id": "7203112008",
    "district_id": "720311",
    "label": "Sibado",
    "value": "Sibado"
  },
  {
    "id": "7203112009",
    "district_id": "720311",
    "label": "Tompe",
    "value": "Tompe"
  },
  {
    "id": "7203112010",
    "district_id": "720311",
    "label": "Lompio",
    "value": "Lompio"
  },
  {
    "id": "7203112011",
    "district_id": "720311",
    "label": "Lende",
    "value": "Lende"
  },
  {
    "id": "7203112012",
    "district_id": "720311",
    "label": "Lende Tovea",
    "value": "Lende Tovea"
  },
  {
    "id": "7203112013",
    "district_id": "720311",
    "label": "Ujumbou.",
    "value": "Ujumbou."
  },
  {
    "id": "7203122006",
    "district_id": "720312",
    "label": "Labean",
    "value": "Labean"
  },
  {
    "id": "7203122007",
    "district_id": "720312",
    "label": "Lombonga",
    "value": "Lombonga"
  },
  {
    "id": "7203122008",
    "district_id": "720312",
    "label": "Meli",
    "value": "Meli"
  },
  {
    "id": "7203122009",
    "district_id": "720312",
    "label": "Tambu",
    "value": "Tambu"
  },
  {
    "id": "7203122010",
    "district_id": "720312",
    "label": "Siweli",
    "value": "Siweli"
  },
  {
    "id": "7203122011",
    "district_id": "720312",
    "label": "Sibualong",
    "value": "Sibualong"
  },
  {
    "id": "7203122012",
    "district_id": "720312",
    "label": "Sibayu",
    "value": "Sibayu"
  },
  {
    "id": "7203122015",
    "district_id": "720312",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7203122016",
    "district_id": "720312",
    "label": "Mapane Tambu",
    "value": "Mapane Tambu"
  },
  {
    "id": "7203122017",
    "district_id": "720312",
    "label": "Tovia Tambu",
    "value": "Tovia Tambu"
  },
  {
    "id": "7203122019",
    "district_id": "720312",
    "label": "Sipure",
    "value": "Sipure"
  },
  {
    "id": "7203122020",
    "district_id": "720312",
    "label": "Kampung Baru Sibayu",
    "value": "Kampung Baru Sibayu"
  },
  {
    "id": "7203122021",
    "district_id": "720312",
    "label": "Simagaya",
    "value": "Simagaya"
  },
  {
    "id": "7203142001",
    "district_id": "720314",
    "label": "Balukang",
    "value": "Balukang"
  },
  {
    "id": "7203142002",
    "district_id": "720314",
    "label": "Siwalempu",
    "value": "Siwalempu"
  },
  {
    "id": "7203142003",
    "district_id": "720314",
    "label": "Siboang",
    "value": "Siboang"
  },
  {
    "id": "7203142004",
    "district_id": "720314",
    "label": "Tonggolobibi",
    "value": "Tonggolobibi"
  },
  {
    "id": "7203142005",
    "district_id": "720314",
    "label": "Bou",
    "value": "Bou"
  },
  {
    "id": "7203142011",
    "district_id": "720314",
    "label": "Samalili",
    "value": "Samalili"
  },
  {
    "id": "7203142012",
    "district_id": "720314",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "7203142013",
    "district_id": "720314",
    "label": "Pangalasiang",
    "value": "Pangalasiang"
  },
  {
    "id": "7203142014",
    "district_id": "720314",
    "label": "Balukang II",
    "value": "Balukang II"
  },
  {
    "id": "7203182001",
    "district_id": "720318",
    "label": "Mbuwu",
    "value": "Mbuwu"
  },
  {
    "id": "7203182002",
    "district_id": "720318",
    "label": "Salumpaku",
    "value": "Salumpaku"
  },
  {
    "id": "7203182003",
    "district_id": "720318",
    "label": "Watatu",
    "value": "Watatu"
  },
  {
    "id": "7203182004",
    "district_id": "720318",
    "label": "Surumana",
    "value": "Surumana"
  },
  {
    "id": "7203182005",
    "district_id": "720318",
    "label": "Lalombi",
    "value": "Lalombi"
  },
  {
    "id": "7203182006",
    "district_id": "720318",
    "label": "Tanamea",
    "value": "Tanamea"
  },
  {
    "id": "7203182007",
    "district_id": "720318",
    "label": "Bambarimi",
    "value": "Bambarimi"
  },
  {
    "id": "7203182008",
    "district_id": "720318",
    "label": "Salungkaenu",
    "value": "Salungkaenu"
  },
  {
    "id": "7203182009",
    "district_id": "720318",
    "label": "Lumbumamara",
    "value": "Lumbumamara"
  },
  {
    "id": "7203182010",
    "district_id": "720318",
    "label": "Tolongano",
    "value": "Tolongano"
  },
  {
    "id": "7203182011",
    "district_id": "720318",
    "label": "Tosale",
    "value": "Tosale"
  },
  {
    "id": "7203182012",
    "district_id": "720318",
    "label": "Lumbu Tarombo",
    "value": "Lumbu Tarombo"
  },
  {
    "id": "7203182013",
    "district_id": "720318",
    "label": "Lumbulama",
    "value": "Lumbulama"
  },
  {
    "id": "7203182014",
    "district_id": "720318",
    "label": "Ongulara",
    "value": "Ongulara"
  },
  {
    "id": "7203182015",
    "district_id": "720318",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7203182016",
    "district_id": "720318",
    "label": "Lembasada",
    "value": "Lembasada"
  },
  {
    "id": "7203182017",
    "district_id": "720318",
    "label": "Sarombaya",
    "value": "Sarombaya"
  },
  {
    "id": "7203182018",
    "district_id": "720318",
    "label": "Salusumpu",
    "value": "Salusumpu"
  },
  {
    "id": "7203182019",
    "district_id": "720318",
    "label": "Tanampulu",
    "value": "Tanampulu"
  },
  {
    "id": "7203192001",
    "district_id": "720319",
    "label": "Wani Satu",
    "value": "Wani Satu"
  },
  {
    "id": "7203192002",
    "district_id": "720319",
    "label": "Wani Dua",
    "value": "Wani Dua"
  },
  {
    "id": "7203192003",
    "district_id": "720319",
    "label": "Nupa Bomba",
    "value": "Nupa Bomba"
  },
  {
    "id": "7203192004",
    "district_id": "720319",
    "label": "Wombo",
    "value": "Wombo"
  },
  {
    "id": "7203192005",
    "district_id": "720319",
    "label": "Guntarano",
    "value": "Guntarano"
  },
  {
    "id": "7203192006",
    "district_id": "720319",
    "label": "Bale",
    "value": "Bale"
  },
  {
    "id": "7203192007",
    "district_id": "720319",
    "label": "Wombo Mpanau",
    "value": "Wombo Mpanau"
  },
  {
    "id": "7203192008",
    "district_id": "720319",
    "label": "Wombo Kalonggo",
    "value": "Wombo Kalonggo"
  },
  {
    "id": "7203192009",
    "district_id": "720319",
    "label": "Wani Tiga",
    "value": "Wani Tiga"
  },
  {
    "id": "7203192010",
    "district_id": "720319",
    "label": "Wani Lumbumpetigo",
    "value": "Wani Lumbumpetigo"
  },
  {
    "id": "7203212001",
    "district_id": "720321",
    "label": "Palintuma",
    "value": "Palintuma"
  },
  {
    "id": "7203212002",
    "district_id": "720321",
    "label": "Gimpubia",
    "value": "Gimpubia"
  },
  {
    "id": "7203212003",
    "district_id": "720321",
    "label": "Dangara'a",
    "value": "Dangara'a"
  },
  {
    "id": "7203212004",
    "district_id": "720321",
    "label": "Bambakanini",
    "value": "Bambakanini"
  },
  {
    "id": "7203212005",
    "district_id": "720321",
    "label": "Bambakaenu",
    "value": "Bambakaenu"
  },
  {
    "id": "7203212006",
    "district_id": "720321",
    "label": "Tomodo",
    "value": "Tomodo"
  },
  {
    "id": "7203212007",
    "district_id": "720321",
    "label": "Kanagalongga",
    "value": "Kanagalongga"
  },
  {
    "id": "7203212008",
    "district_id": "720321",
    "label": "Karavia",
    "value": "Karavia"
  },
  {
    "id": "7203212009",
    "district_id": "720321",
    "label": "Tavanggeli",
    "value": "Tavanggeli"
  },
  {
    "id": "7203242001",
    "district_id": "720324",
    "label": "Tibo",
    "value": "Tibo"
  },
  {
    "id": "7203242002",
    "district_id": "720324",
    "label": "Kaliburu",
    "value": "Kaliburu"
  },
  {
    "id": "7203242003",
    "district_id": "720324",
    "label": "Batusuya",
    "value": "Batusuya"
  },
  {
    "id": "7203242004",
    "district_id": "720324",
    "label": "Saloya",
    "value": "Saloya"
  },
  {
    "id": "7203242005",
    "district_id": "720324",
    "label": "Batusuya Go'o",
    "value": "Batusuya Go'o"
  },
  {
    "id": "7203242006",
    "district_id": "720324",
    "label": "Kaliburu Kata",
    "value": "Kaliburu Kata"
  },
  {
    "id": "7203252001",
    "district_id": "720325",
    "label": "Sikara Tobata",
    "value": "Sikara Tobata"
  },
  {
    "id": "7203252002",
    "district_id": "720325",
    "label": "Alindau",
    "value": "Alindau"
  },
  {
    "id": "7203252003",
    "district_id": "720325",
    "label": "Oti",
    "value": "Oti"
  },
  {
    "id": "7203252004",
    "district_id": "720325",
    "label": "Tamarenja",
    "value": "Tamarenja"
  },
  {
    "id": "7203252005",
    "district_id": "720325",
    "label": "Sipeso",
    "value": "Sipeso"
  },
  {
    "id": "7203252006",
    "district_id": "720325",
    "label": "Sindosa",
    "value": "Sindosa"
  },
  {
    "id": "7203272001",
    "district_id": "720327",
    "label": "Powelua",
    "value": "Powelua"
  },
  {
    "id": "7203272002",
    "district_id": "720327",
    "label": "Lumbudolo",
    "value": "Lumbudolo"
  },
  {
    "id": "7203272003",
    "district_id": "720327",
    "label": "Kola-Kola",
    "value": "Kola-Kola"
  },
  {
    "id": "7203272004",
    "district_id": "720327",
    "label": "Limboro",
    "value": "Limboro"
  },
  {
    "id": "7203272005",
    "district_id": "720327",
    "label": "Towale",
    "value": "Towale"
  },
  {
    "id": "7203272006",
    "district_id": "720327",
    "label": "Salubomba",
    "value": "Salubomba"
  },
  {
    "id": "7203272007",
    "district_id": "720327",
    "label": "Mekar Baru",
    "value": "Mekar Baru"
  },
  {
    "id": "7203272008",
    "district_id": "720327",
    "label": "Lampo",
    "value": "Lampo"
  },
  {
    "id": "7203302001",
    "district_id": "720330",
    "label": "Ogoamas I",
    "value": "Ogoamas I"
  },
  {
    "id": "7203302002",
    "district_id": "720330",
    "label": "Ogoamas II",
    "value": "Ogoamas II"
  },
  {
    "id": "7203302003",
    "district_id": "720330",
    "label": "Lenju",
    "value": "Lenju"
  },
  {
    "id": "7203302004",
    "district_id": "720330",
    "label": "Pesik",
    "value": "Pesik"
  },
  {
    "id": "7203302005",
    "district_id": "720330",
    "label": "Bengkoli",
    "value": "Bengkoli"
  },
  {
    "id": "7203312001",
    "district_id": "720331",
    "label": "Walandano",
    "value": "Walandano"
  },
  {
    "id": "7203312002",
    "district_id": "720331",
    "label": "Malei",
    "value": "Malei"
  },
  {
    "id": "7203312003",
    "district_id": "720331",
    "label": "Kamonji",
    "value": "Kamonji"
  },
  {
    "id": "7203312004",
    "district_id": "720331",
    "label": "Ketong",
    "value": "Ketong"
  },
  {
    "id": "7203312005",
    "district_id": "720331",
    "label": "Rano",
    "value": "Rano"
  },
  {
    "id": "7203312006",
    "district_id": "720331",
    "label": "Pomolulu",
    "value": "Pomolulu"
  },
  {
    "id": "7203312007",
    "district_id": "720331",
    "label": "Palau",
    "value": "Palau"
  },
  {
    "id": "7203312008",
    "district_id": "720331",
    "label": "Manimbaya",
    "value": "Manimbaya"
  },
  {
    "id": "7204012001",
    "district_id": "720401",
    "label": "Kombo",
    "value": "Kombo"
  },
  {
    "id": "7204012002",
    "district_id": "720401",
    "label": "Tampiala",
    "value": "Tampiala"
  },
  {
    "id": "7204012003",
    "district_id": "720401",
    "label": "Soni",
    "value": "Soni"
  },
  {
    "id": "7204012004",
    "district_id": "720401",
    "label": "Bangkir",
    "value": "Bangkir"
  },
  {
    "id": "7204012005",
    "district_id": "720401",
    "label": "Dongko",
    "value": "Dongko"
  },
  {
    "id": "7204012006",
    "district_id": "720401",
    "label": "Mimbala",
    "value": "Mimbala"
  },
  {
    "id": "7204012007",
    "district_id": "720401",
    "label": "Lempe",
    "value": "Lempe"
  },
  {
    "id": "7204012008",
    "district_id": "720401",
    "label": "Paddumpu",
    "value": "Paddumpu"
  },
  {
    "id": "7204012009",
    "district_id": "720401",
    "label": "Abbajareng",
    "value": "Abbajareng"
  },
  {
    "id": "7204012010",
    "district_id": "720401",
    "label": "Puse",
    "value": "Puse"
  },
  {
    "id": "7204012011",
    "district_id": "720401",
    "label": "Pallakawe",
    "value": "Pallakawe"
  },
  {
    "id": "7204012012",
    "district_id": "720401",
    "label": "Simuntu",
    "value": "Simuntu"
  },
  {
    "id": "7204012013",
    "district_id": "720401",
    "label": "Lemba Harapan",
    "value": "Lemba Harapan"
  },
  {
    "id": "7204022001",
    "district_id": "720402",
    "label": "Banagan",
    "value": "Banagan"
  },
  {
    "id": "7204022002",
    "district_id": "720402",
    "label": "Kabinuang",
    "value": "Kabinuang"
  },
  {
    "id": "7204022003",
    "district_id": "720402",
    "label": "Ogotua",
    "value": "Ogotua"
  },
  {
    "id": "7204022004",
    "district_id": "720402",
    "label": "Tompoh",
    "value": "Tompoh"
  },
  {
    "id": "7204022005",
    "district_id": "720402",
    "label": "Bambapula",
    "value": "Bambapula"
  },
  {
    "id": "7204022006",
    "district_id": "720402",
    "label": "Malambigu",
    "value": "Malambigu"
  },
  {
    "id": "7204022007",
    "district_id": "720402",
    "label": "Simatang Tanjung",
    "value": "Simatang Tanjung"
  },
  {
    "id": "7204022008",
    "district_id": "720402",
    "label": "Simatang Utara",
    "value": "Simatang Utara"
  },
  {
    "id": "7204022009",
    "district_id": "720402",
    "label": "Sese",
    "value": "Sese"
  },
  {
    "id": "7204022010",
    "district_id": "720402",
    "label": "Ogolali",
    "value": "Ogolali"
  },
  {
    "id": "7204022011",
    "district_id": "720402",
    "label": "Balaroa",
    "value": "Balaroa"
  },
  {
    "id": "7204022012",
    "district_id": "720402",
    "label": "Stadong",
    "value": "Stadong"
  },
  {
    "id": "7204032001",
    "district_id": "720403",
    "label": "Luok Manipi",
    "value": "Luok Manipi"
  },
  {
    "id": "7204032002",
    "district_id": "720403",
    "label": "Salumbia",
    "value": "Salumbia"
  },
  {
    "id": "7204032003",
    "district_id": "720403",
    "label": "Bambapun",
    "value": "Bambapun"
  },
  {
    "id": "7204032004",
    "district_id": "720403",
    "label": "Ogowele",
    "value": "Ogowele"
  },
  {
    "id": "7204032005",
    "district_id": "720403",
    "label": "Lais",
    "value": "Lais"
  },
  {
    "id": "7204032006",
    "district_id": "720403",
    "label": "Ogogasang",
    "value": "Ogogasang"
  },
  {
    "id": "7204032007",
    "district_id": "720403",
    "label": "Malomba",
    "value": "Malomba"
  },
  {
    "id": "7204032008",
    "district_id": "720403",
    "label": "Ogogili",
    "value": "Ogogili"
  },
  {
    "id": "7204032009",
    "district_id": "720403",
    "label": "Tinabogan",
    "value": "Tinabogan"
  },
  {
    "id": "7204032010",
    "district_id": "720403",
    "label": "Malulu",
    "value": "Malulu"
  },
  {
    "id": "7204032011",
    "district_id": "720403",
    "label": "Malala",
    "value": "Malala"
  },
  {
    "id": "7204032012",
    "district_id": "720403",
    "label": "Anggasan",
    "value": "Anggasan"
  },
  {
    "id": "7204032013",
    "district_id": "720403",
    "label": "Betengon",
    "value": "Betengon"
  },
  {
    "id": "7204032014",
    "district_id": "720403",
    "label": "Pangkung",
    "value": "Pangkung"
  },
  {
    "id": "7204032015",
    "district_id": "720403",
    "label": "Ogowele Buga",
    "value": "Ogowele Buga"
  },
  {
    "id": "7204032016",
    "district_id": "720403",
    "label": "Lobuo",
    "value": "Lobuo"
  },
  {
    "id": "7204042001",
    "district_id": "720404",
    "label": "Sibaluton",
    "value": "Sibaluton"
  },
  {
    "id": "7204042002",
    "district_id": "720404",
    "label": "Kayu Lompa",
    "value": "Kayu Lompa"
  },
  {
    "id": "7204042003",
    "district_id": "720404",
    "label": "Labonu",
    "value": "Labonu"
  },
  {
    "id": "7204042004",
    "district_id": "720404",
    "label": "Silondou",
    "value": "Silondou"
  },
  {
    "id": "7204042005",
    "district_id": "720404",
    "label": "Kinapasan",
    "value": "Kinapasan"
  },
  {
    "id": "7204042006",
    "district_id": "720404",
    "label": "Kongkomos",
    "value": "Kongkomos"
  },
  {
    "id": "7204042007",
    "district_id": "720404",
    "label": "Ogosipat",
    "value": "Ogosipat"
  },
  {
    "id": "7204042008",
    "district_id": "720404",
    "label": "Galandau",
    "value": "Galandau"
  },
  {
    "id": "7204042009",
    "district_id": "720404",
    "label": "Marisa",
    "value": "Marisa"
  },
  {
    "id": "7204042010",
    "district_id": "720404",
    "label": "Basi",
    "value": "Basi"
  },
  {
    "id": "7204052001",
    "district_id": "720405",
    "label": "Buga",
    "value": "Buga"
  },
  {
    "id": "7204052002",
    "district_id": "720405",
    "label": "Batuilo",
    "value": "Batuilo"
  },
  {
    "id": "7204052003",
    "district_id": "720405",
    "label": "Kamalu",
    "value": "Kamalu"
  },
  {
    "id": "7204052004",
    "district_id": "720405",
    "label": "Muara Besar",
    "value": "Muara Besar"
  },
  {
    "id": "7204052005",
    "district_id": "720405",
    "label": "Bilo",
    "value": "Bilo"
  },
  {
    "id": "7204052006",
    "district_id": "720405",
    "label": "Labuan Lobo",
    "value": "Labuan Lobo"
  },
  {
    "id": "7204052007",
    "district_id": "720405",
    "label": "Sambujan",
    "value": "Sambujan"
  },
  {
    "id": "7204052008",
    "district_id": "720405",
    "label": "Pulias",
    "value": "Pulias"
  },
  {
    "id": "7204052009",
    "district_id": "720405",
    "label": "Kabetan",
    "value": "Kabetan"
  },
  {
    "id": "7204052010",
    "district_id": "720405",
    "label": "Pagaitan",
    "value": "Pagaitan"
  },
  {
    "id": "7204052011",
    "district_id": "720405",
    "label": "Bambalaga",
    "value": "Bambalaga"
  },
  {
    "id": "7204062001",
    "district_id": "720406",
    "label": "Janja",
    "value": "Janja"
  },
  {
    "id": "7204062002",
    "district_id": "720406",
    "label": "Oyom",
    "value": "Oyom"
  },
  {
    "id": "7204062003",
    "district_id": "720406",
    "label": "Salugan",
    "value": "Salugan"
  },
  {
    "id": "7204062004",
    "district_id": "720406",
    "label": "Lampasio",
    "value": "Lampasio"
  },
  {
    "id": "7204062005",
    "district_id": "720406",
    "label": "Tinading",
    "value": "Tinading"
  },
  {
    "id": "7204062006",
    "district_id": "720406",
    "label": "Sibea",
    "value": "Sibea"
  },
  {
    "id": "7204062007",
    "district_id": "720406",
    "label": "Maibua",
    "value": "Maibua"
  },
  {
    "id": "7204062008",
    "district_id": "720406",
    "label": "Mulya Sari",
    "value": "Mulya Sari"
  },
  {
    "id": "7204062009",
    "district_id": "720406",
    "label": "Ogomatanang",
    "value": "Ogomatanang"
  },
  {
    "id": "7204071001",
    "district_id": "720407",
    "label": "Tambun",
    "value": "Tambun"
  },
  {
    "id": "7204071003",
    "district_id": "720407",
    "label": "Tuweley",
    "value": "Tuweley"
  },
  {
    "id": "7204071004",
    "district_id": "720407",
    "label": "Nalu",
    "value": "Nalu"
  },
  {
    "id": "7204071005",
    "district_id": "720407",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7204071006",
    "district_id": "720407",
    "label": "Panasakan",
    "value": "Panasakan"
  },
  {
    "id": "7204071007",
    "district_id": "720407",
    "label": "Sidoarjo",
    "value": "Sidoarjo"
  },
  {
    "id": "7204072002",
    "district_id": "720407",
    "label": "Dadakitan",
    "value": "Dadakitan"
  },
  {
    "id": "7204072008",
    "district_id": "720407",
    "label": "Buntuna",
    "value": "Buntuna"
  },
  {
    "id": "7204072009",
    "district_id": "720407",
    "label": "Lelean Nono",
    "value": "Lelean Nono"
  },
  {
    "id": "7204072010",
    "district_id": "720407",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7204082001",
    "district_id": "720408",
    "label": "Ogomoli",
    "value": "Ogomoli"
  },
  {
    "id": "7204082002",
    "district_id": "720408",
    "label": "Sandana",
    "value": "Sandana"
  },
  {
    "id": "7204082003",
    "district_id": "720408",
    "label": "Kalangkangan",
    "value": "Kalangkangan"
  },
  {
    "id": "7204082004",
    "district_id": "720408",
    "label": "Lantapan",
    "value": "Lantapan"
  },
  {
    "id": "7204082005",
    "district_id": "720408",
    "label": "Lakatan",
    "value": "Lakatan"
  },
  {
    "id": "7204082006",
    "district_id": "720408",
    "label": "Tinigi",
    "value": "Tinigi"
  },
  {
    "id": "7204082007",
    "district_id": "720408",
    "label": "Ginunggung",
    "value": "Ginunggung"
  },
  {
    "id": "7204082008",
    "district_id": "720408",
    "label": "Lalos",
    "value": "Lalos"
  },
  {
    "id": "7204082009",
    "district_id": "720408",
    "label": "Tende",
    "value": "Tende"
  },
  {
    "id": "7204082010",
    "district_id": "720408",
    "label": "Sabang",
    "value": "Sabang"
  },
  {
    "id": "7204082011",
    "district_id": "720408",
    "label": "Bajugan",
    "value": "Bajugan"
  },
  {
    "id": "7204082012",
    "district_id": "720408",
    "label": "Aung",
    "value": "Aung"
  },
  {
    "id": "7204082013",
    "district_id": "720408",
    "label": "Malangga",
    "value": "Malangga"
  },
  {
    "id": "7204082014",
    "district_id": "720408",
    "label": "Kinopasan",
    "value": "Kinopasan"
  },
  {
    "id": "7204092005",
    "district_id": "720409",
    "label": "Santigi",
    "value": "Santigi"
  },
  {
    "id": "7204092006",
    "district_id": "720409",
    "label": "Laulalang",
    "value": "Laulalang"
  },
  {
    "id": "7204092007",
    "district_id": "720409",
    "label": "Salumpaga",
    "value": "Salumpaga"
  },
  {
    "id": "7204092008",
    "district_id": "720409",
    "label": "Diule",
    "value": "Diule"
  },
  {
    "id": "7204092009",
    "district_id": "720409",
    "label": "Pinjan",
    "value": "Pinjan"
  },
  {
    "id": "7204092010",
    "district_id": "720409",
    "label": "Binontoan",
    "value": "Binontoan"
  },
  {
    "id": "7204092011",
    "district_id": "720409",
    "label": "Lakuan Tolitoli",
    "value": "Lakuan Tolitoli"
  },
  {
    "id": "7204092012",
    "district_id": "720409",
    "label": "Timbolo",
    "value": "Timbolo"
  },
  {
    "id": "7204092013",
    "district_id": "720409",
    "label": "Teluk Jaya",
    "value": "Teluk Jaya"
  },
  {
    "id": "7204092014",
    "district_id": "720409",
    "label": "Gio",
    "value": "Gio"
  },
  {
    "id": "7204102001",
    "district_id": "720410",
    "label": "Galumpang",
    "value": "Galumpang"
  },
  {
    "id": "7204102002",
    "district_id": "720410",
    "label": "Dungingis",
    "value": "Dungingis"
  },
  {
    "id": "7204102003",
    "district_id": "720410",
    "label": "Kapas",
    "value": "Kapas"
  },
  {
    "id": "7204102004",
    "district_id": "720410",
    "label": "Lingadan",
    "value": "Lingadan"
  },
  {
    "id": "7205012001",
    "district_id": "720501",
    "label": "Momunu",
    "value": "Momunu"
  },
  {
    "id": "7205012002",
    "district_id": "720501",
    "label": "Pinamula",
    "value": "Pinamula"
  },
  {
    "id": "7205012003",
    "district_id": "720501",
    "label": "Pujimulyo",
    "value": "Pujimulyo"
  },
  {
    "id": "7205012004",
    "district_id": "720501",
    "label": "Taluan",
    "value": "Taluan"
  },
  {
    "id": "7205012005",
    "district_id": "720501",
    "label": "Potugu",
    "value": "Potugu"
  },
  {
    "id": "7205012006",
    "district_id": "720501",
    "label": "Panimbul",
    "value": "Panimbul"
  },
  {
    "id": "7205012007",
    "district_id": "720501",
    "label": "Tongon",
    "value": "Tongon"
  },
  {
    "id": "7205012008",
    "district_id": "720501",
    "label": "Lamadong II",
    "value": "Lamadong II"
  },
  {
    "id": "7205012009",
    "district_id": "720501",
    "label": "Pomayagon",
    "value": "Pomayagon"
  },
  {
    "id": "7205012010",
    "district_id": "720501",
    "label": "Guamonial",
    "value": "Guamonial"
  },
  {
    "id": "7205012011",
    "district_id": "720501",
    "label": "Lamadong I",
    "value": "Lamadong I"
  },
  {
    "id": "7205012012",
    "district_id": "720501",
    "label": "Pajeko",
    "value": "Pajeko"
  },
  {
    "id": "7205012020",
    "district_id": "720501",
    "label": "Wakat",
    "value": "Wakat"
  },
  {
    "id": "7205012021",
    "district_id": "720501",
    "label": "Soraya",
    "value": "Soraya"
  },
  {
    "id": "7205012022",
    "district_id": "720501",
    "label": "Pinamula Baru",
    "value": "Pinamula Baru"
  },
  {
    "id": "7205012023",
    "district_id": "720501",
    "label": "Mangubi",
    "value": "Mangubi"
  },
  {
    "id": "7205022009",
    "district_id": "720502",
    "label": "Tuinan",
    "value": "Tuinan"
  },
  {
    "id": "7205022010",
    "district_id": "720502",
    "label": "Lakea II",
    "value": "Lakea II"
  },
  {
    "id": "7205022011",
    "district_id": "720502",
    "label": "Lakea I",
    "value": "Lakea I"
  },
  {
    "id": "7205022012",
    "district_id": "720502",
    "label": "Lakuan Buol",
    "value": "Lakuan Buol"
  },
  {
    "id": "7205022017",
    "district_id": "720502",
    "label": "Bukaan",
    "value": "Bukaan"
  },
  {
    "id": "7205022018",
    "district_id": "720502",
    "label": "Ngune",
    "value": "Ngune"
  },
  {
    "id": "7205022019",
    "district_id": "720502",
    "label": "Ilambe",
    "value": "Ilambe"
  },
  {
    "id": "7205032001",
    "district_id": "720503",
    "label": "Tayadun",
    "value": "Tayadun"
  },
  {
    "id": "7205032002",
    "district_id": "720503",
    "label": "Poongan",
    "value": "Poongan"
  },
  {
    "id": "7205032003",
    "district_id": "720503",
    "label": "Bongo",
    "value": "Bongo"
  },
  {
    "id": "7205032004",
    "district_id": "720503",
    "label": "Doulan",
    "value": "Doulan"
  },
  {
    "id": "7205032010",
    "district_id": "720503",
    "label": "Kantanan",
    "value": "Kantanan"
  },
  {
    "id": "7205032011",
    "district_id": "720503",
    "label": "Kodolagon",
    "value": "Kodolagon"
  },
  {
    "id": "7205032014",
    "district_id": "720503",
    "label": "Negeri Lama",
    "value": "Negeri Lama"
  },
  {
    "id": "7205032015",
    "district_id": "720503",
    "label": "Bokat IV",
    "value": "Bokat IV"
  },
  {
    "id": "7205032018",
    "district_id": "720503",
    "label": "Bokat",
    "value": "Bokat"
  },
  {
    "id": "7205032019",
    "district_id": "720503",
    "label": "Butukan",
    "value": "Butukan"
  },
  {
    "id": "7205032020",
    "district_id": "720503",
    "label": "Tikopo",
    "value": "Tikopo"
  },
  {
    "id": "7205032021",
    "district_id": "720503",
    "label": "Bukamog",
    "value": "Bukamog"
  },
  {
    "id": "7205032022",
    "district_id": "720503",
    "label": "Tang",
    "value": "Tang"
  },
  {
    "id": "7205032023",
    "district_id": "720503",
    "label": "Duamayo",
    "value": "Duamayo"
  },
  {
    "id": "7205032024",
    "district_id": "720503",
    "label": "Langudon",
    "value": "Langudon"
  },
  {
    "id": "7205042001",
    "district_id": "720504",
    "label": "Lonu",
    "value": "Lonu"
  },
  {
    "id": "7205042002",
    "district_id": "720504",
    "label": "Tamit",
    "value": "Tamit"
  },
  {
    "id": "7205042003",
    "district_id": "720504",
    "label": "Botugolu",
    "value": "Botugolu"
  },
  {
    "id": "7205042004",
    "district_id": "720504",
    "label": "Bunobogu",
    "value": "Bunobogu"
  },
  {
    "id": "7205042005",
    "district_id": "720504",
    "label": "Konamukan",
    "value": "Konamukan"
  },
  {
    "id": "7205042006",
    "district_id": "720504",
    "label": "Ponipingan",
    "value": "Ponipingan"
  },
  {
    "id": "7205042007",
    "district_id": "720504",
    "label": "Inalatan",
    "value": "Inalatan"
  },
  {
    "id": "7205042015",
    "district_id": "720504",
    "label": "Domag Mekar",
    "value": "Domag Mekar"
  },
  {
    "id": "7205042016",
    "district_id": "720504",
    "label": "Bunobogu Selatan",
    "value": "Bunobogu Selatan"
  },
  {
    "id": "7205042017",
    "district_id": "720504",
    "label": "Pokobo",
    "value": "Pokobo"
  },
  {
    "id": "7205052001",
    "district_id": "720505",
    "label": "Umu",
    "value": "Umu"
  },
  {
    "id": "7205052002",
    "district_id": "720505",
    "label": "Mulangato",
    "value": "Mulangato"
  },
  {
    "id": "7205052003",
    "district_id": "720505",
    "label": "Lilito",
    "value": "Lilito"
  },
  {
    "id": "7205052004",
    "district_id": "720505",
    "label": "Talaki",
    "value": "Talaki"
  },
  {
    "id": "7205052005",
    "district_id": "720505",
    "label": "Baturata",
    "value": "Baturata"
  },
  {
    "id": "7205052006",
    "district_id": "720505",
    "label": "Kwalabesar",
    "value": "Kwalabesar"
  },
  {
    "id": "7205052007",
    "district_id": "720505",
    "label": "Tolau",
    "value": "Tolau"
  },
  {
    "id": "7205052008",
    "district_id": "720505",
    "label": "Paleleh",
    "value": "Paleleh"
  },
  {
    "id": "7205052009",
    "district_id": "720505",
    "label": "Lintidu",
    "value": "Lintidu"
  },
  {
    "id": "7205052014",
    "district_id": "720505",
    "label": "Pionoto",
    "value": "Pionoto"
  },
  {
    "id": "7205052017",
    "district_id": "720505",
    "label": "Dopalak",
    "value": "Dopalak"
  },
  {
    "id": "7205052018",
    "district_id": "720505",
    "label": "Dotuno",
    "value": "Dotuno"
  },
  {
    "id": "7205061001",
    "district_id": "720506",
    "label": "Buol",
    "value": "Buol"
  },
  {
    "id": "7205061002",
    "district_id": "720506",
    "label": "Kali",
    "value": "Kali"
  },
  {
    "id": "7205061003",
    "district_id": "720506",
    "label": "Leok II",
    "value": "Leok II"
  },
  {
    "id": "7205061004",
    "district_id": "720506",
    "label": "Leok I",
    "value": "Leok I"
  },
  {
    "id": "7205061005",
    "district_id": "720506",
    "label": "Kumaligon",
    "value": "Kumaligon"
  },
  {
    "id": "7205061006",
    "district_id": "720506",
    "label": "Kulango",
    "value": "Kulango"
  },
  {
    "id": "7205061007",
    "district_id": "720506",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "7205072001",
    "district_id": "720507",
    "label": "Boilan",
    "value": "Boilan"
  },
  {
    "id": "7205072002",
    "district_id": "720507",
    "label": "Kokobuka",
    "value": "Kokobuka"
  },
  {
    "id": "7205072003",
    "district_id": "720507",
    "label": "Air Terang",
    "value": "Air Terang"
  },
  {
    "id": "7205072004",
    "district_id": "720507",
    "label": "Panilan Jaya",
    "value": "Panilan Jaya"
  },
  {
    "id": "7205072005",
    "district_id": "720507",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "7205072006",
    "district_id": "720507",
    "label": "Lomuli",
    "value": "Lomuli"
  },
  {
    "id": "7205072007",
    "district_id": "720507",
    "label": "Balau",
    "value": "Balau"
  },
  {
    "id": "7205072008",
    "district_id": "720507",
    "label": "Maniala",
    "value": "Maniala"
  },
  {
    "id": "7205072009",
    "district_id": "720507",
    "label": "Monggonit",
    "value": "Monggonit"
  },
  {
    "id": "7205082001",
    "district_id": "720508",
    "label": "Biau",
    "value": "Biau"
  },
  {
    "id": "7205082002",
    "district_id": "720508",
    "label": "Bungkudu",
    "value": "Bungkudu"
  },
  {
    "id": "7205082003",
    "district_id": "720508",
    "label": "Rantemaranu",
    "value": "Rantemaranu"
  },
  {
    "id": "7205082004",
    "district_id": "720508",
    "label": "Winangun",
    "value": "Winangun"
  },
  {
    "id": "7205082005",
    "district_id": "720508",
    "label": "Modo I",
    "value": "Modo I"
  },
  {
    "id": "7205082006",
    "district_id": "720508",
    "label": "Unone",
    "value": "Unone"
  },
  {
    "id": "7205082007",
    "district_id": "720508",
    "label": "Mopu",
    "value": "Mopu"
  },
  {
    "id": "7205082008",
    "district_id": "720508",
    "label": "Diat",
    "value": "Diat"
  },
  {
    "id": "7205082009",
    "district_id": "720508",
    "label": "Potangoan",
    "value": "Potangoan"
  },
  {
    "id": "7205082010",
    "district_id": "720508",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "7205082011",
    "district_id": "720508",
    "label": "Yugut",
    "value": "Yugut"
  },
  {
    "id": "7205082012",
    "district_id": "720508",
    "label": "Mooyong",
    "value": "Mooyong"
  },
  {
    "id": "7205082013",
    "district_id": "720508",
    "label": "Mulat",
    "value": "Mulat"
  },
  {
    "id": "7205082014",
    "district_id": "720508",
    "label": "Bukal",
    "value": "Bukal"
  },
  {
    "id": "7205092001",
    "district_id": "720509",
    "label": "Lokodidi",
    "value": "Lokodidi"
  },
  {
    "id": "7205092002",
    "district_id": "720509",
    "label": "Matinan",
    "value": "Matinan"
  },
  {
    "id": "7205092003",
    "district_id": "720509",
    "label": "Taat",
    "value": "Taat"
  },
  {
    "id": "7205092004",
    "district_id": "720509",
    "label": "Lokodoka",
    "value": "Lokodoka"
  },
  {
    "id": "7205092005",
    "district_id": "720509",
    "label": "Labuton",
    "value": "Labuton"
  },
  {
    "id": "7205092006",
    "district_id": "720509",
    "label": "Bulagidun",
    "value": "Bulagidun"
  },
  {
    "id": "7205092007",
    "district_id": "720509",
    "label": "Diapati",
    "value": "Diapati"
  },
  {
    "id": "7205092008",
    "district_id": "720509",
    "label": "Nandu",
    "value": "Nandu"
  },
  {
    "id": "7205092009",
    "district_id": "720509",
    "label": "Lipubogu",
    "value": "Lipubogu"
  },
  {
    "id": "7205092010",
    "district_id": "720509",
    "label": "Bulogidon Tanjung",
    "value": "Bulogidon Tanjung"
  },
  {
    "id": "7205092011",
    "district_id": "720509",
    "label": "Pandangan",
    "value": "Pandangan"
  },
  {
    "id": "7205102001",
    "district_id": "720510",
    "label": "Mokupo",
    "value": "Mokupo"
  },
  {
    "id": "7205102002",
    "district_id": "720510",
    "label": "Mendaan",
    "value": "Mendaan"
  },
  {
    "id": "7205102003",
    "district_id": "720510",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7205102004",
    "district_id": "720510",
    "label": "Monano",
    "value": "Monano"
  },
  {
    "id": "7205102005",
    "district_id": "720510",
    "label": "Busak I",
    "value": "Busak I"
  },
  {
    "id": "7205102006",
    "district_id": "720510",
    "label": "Busak II",
    "value": "Busak II"
  },
  {
    "id": "7205102007",
    "district_id": "720510",
    "label": "Lamakan",
    "value": "Lamakan"
  },
  {
    "id": "7205112001",
    "district_id": "720511",
    "label": "Bodi",
    "value": "Bodi"
  },
  {
    "id": "7205112002",
    "district_id": "720511",
    "label": "Tayokan",
    "value": "Tayokan"
  },
  {
    "id": "7205112003",
    "district_id": "720511",
    "label": "Harmoni",
    "value": "Harmoni"
  },
  {
    "id": "7205112004",
    "district_id": "720511",
    "label": "Lunguto",
    "value": "Lunguto"
  },
  {
    "id": "7205112005",
    "district_id": "720511",
    "label": "Timbulon",
    "value": "Timbulon"
  },
  {
    "id": "7205112006",
    "district_id": "720511",
    "label": "Oyak",
    "value": "Oyak"
  },
  {
    "id": "7205112007",
    "district_id": "720511",
    "label": "Hulubalang",
    "value": "Hulubalang"
  },
  {
    "id": "7206051014",
    "district_id": "720605",
    "label": "Mendui",
    "value": "Mendui"
  },
  {
    "id": "7206051015",
    "district_id": "720605",
    "label": "Lamberea",
    "value": "Lamberea"
  },
  {
    "id": "7206051016",
    "district_id": "720605",
    "label": "Tofoiso",
    "value": "Tofoiso"
  },
  {
    "id": "7206051017",
    "district_id": "720605",
    "label": "Marsaoleh",
    "value": "Marsaoleh"
  },
  {
    "id": "7206051018",
    "district_id": "720605",
    "label": "Matano",
    "value": "Matano"
  },
  {
    "id": "7206051020",
    "district_id": "720605",
    "label": "Bungi",
    "value": "Bungi"
  },
  {
    "id": "7206052010",
    "district_id": "720605",
    "label": "Puungkoilu",
    "value": "Puungkoilu"
  },
  {
    "id": "7206052011",
    "district_id": "720605",
    "label": "Bahontobungku",
    "value": "Bahontobungku"
  },
  {
    "id": "7206052012",
    "district_id": "720605",
    "label": "Tofuti",
    "value": "Tofuti"
  },
  {
    "id": "7206052013",
    "district_id": "720605",
    "label": "Sakita",
    "value": "Sakita"
  },
  {
    "id": "7206052019",
    "district_id": "720605",
    "label": "Matansala",
    "value": "Matansala"
  },
  {
    "id": "7206052021",
    "district_id": "720605",
    "label": "Bahoruru",
    "value": "Bahoruru"
  },
  {
    "id": "7206052022",
    "district_id": "720605",
    "label": "Bente",
    "value": "Bente"
  },
  {
    "id": "7206052023",
    "district_id": "720605",
    "label": "Ipi",
    "value": "Ipi"
  },
  {
    "id": "7206052024",
    "district_id": "720605",
    "label": "Bahomohoni",
    "value": "Bahomohoni"
  },
  {
    "id": "7206052025",
    "district_id": "720605",
    "label": "Bahomoleo",
    "value": "Bahomoleo"
  },
  {
    "id": "7206052026",
    "district_id": "720605",
    "label": "Bahomante",
    "value": "Bahomante"
  },
  {
    "id": "7206052027",
    "district_id": "720605",
    "label": "Lanona",
    "value": "Lanona"
  },
  {
    "id": "7206052029",
    "district_id": "720605",
    "label": "Tudua",
    "value": "Tudua"
  },
  {
    "id": "7206062001",
    "district_id": "720606",
    "label": "Sainoa",
    "value": "Sainoa"
  },
  {
    "id": "7206062002",
    "district_id": "720606",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7206062003",
    "district_id": "720606",
    "label": "Umbele",
    "value": "Umbele"
  },
  {
    "id": "7206062004",
    "district_id": "720606",
    "label": "Pulau Dua",
    "value": "Pulau Dua"
  },
  {
    "id": "7206062005",
    "district_id": "720606",
    "label": "Bakala",
    "value": "Bakala"
  },
  {
    "id": "7206062006",
    "district_id": "720606",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "7206062007",
    "district_id": "720606",
    "label": "Koburu",
    "value": "Koburu"
  },
  {
    "id": "7206062008",
    "district_id": "720606",
    "label": "Buton",
    "value": "Buton"
  },
  {
    "id": "7206062009",
    "district_id": "720606",
    "label": "Jawi-Jawi",
    "value": "Jawi-Jawi"
  },
  {
    "id": "7206062010",
    "district_id": "720606",
    "label": "Kaleroang",
    "value": "Kaleroang"
  },
  {
    "id": "7206062011",
    "district_id": "720606",
    "label": "Bungingkela",
    "value": "Bungingkela"
  },
  {
    "id": "7206062012",
    "district_id": "720606",
    "label": "Lokombulo",
    "value": "Lokombulo"
  },
  {
    "id": "7206062013",
    "district_id": "720606",
    "label": "Buajangka",
    "value": "Buajangka"
  },
  {
    "id": "7206062014",
    "district_id": "720606",
    "label": "Waru-Waru",
    "value": "Waru-Waru"
  },
  {
    "id": "7206062015",
    "district_id": "720606",
    "label": "Padabale",
    "value": "Padabale"
  },
  {
    "id": "7206062016",
    "district_id": "720606",
    "label": "Pado-Pado",
    "value": "Pado-Pado"
  },
  {
    "id": "7206062017",
    "district_id": "720606",
    "label": "Pulaubapa",
    "value": "Pulaubapa"
  },
  {
    "id": "7206062018",
    "district_id": "720606",
    "label": "Lalemo",
    "value": "Lalemo"
  },
  {
    "id": "7206062019",
    "district_id": "720606",
    "label": "Lamontoli",
    "value": "Lamontoli"
  },
  {
    "id": "7206062041",
    "district_id": "720606",
    "label": "Bungintende",
    "value": "Bungintende"
  },
  {
    "id": "7206062043",
    "district_id": "720606",
    "label": "Panimbawang",
    "value": "Panimbawang"
  },
  {
    "id": "7206062044",
    "district_id": "720606",
    "label": "Po'o",
    "value": "Po'o"
  },
  {
    "id": "7206062045",
    "district_id": "720606",
    "label": "Boelimau",
    "value": "Boelimau"
  },
  {
    "id": "7206062046",
    "district_id": "720606",
    "label": "Poaro",
    "value": "Poaro"
  },
  {
    "id": "7206062047",
    "district_id": "720606",
    "label": "Umbele Lama",
    "value": "Umbele Lama"
  },
  {
    "id": "7206062048",
    "district_id": "720606",
    "label": "Pulau Dua Darat",
    "value": "Pulau Dua Darat"
  },
  {
    "id": "7206071007",
    "district_id": "720607",
    "label": "Ulunambo",
    "value": "Ulunambo"
  },
  {
    "id": "7206072001",
    "district_id": "720607",
    "label": "Terebino",
    "value": "Terebino"
  },
  {
    "id": "7206072002",
    "district_id": "720607",
    "label": "Torukuno",
    "value": "Torukuno"
  },
  {
    "id": "7206072003",
    "district_id": "720607",
    "label": "Ngapaea",
    "value": "Ngapaea"
  },
  {
    "id": "7206072004",
    "district_id": "720607",
    "label": "Padala'a",
    "value": "Padala'a"
  },
  {
    "id": "7206072005",
    "district_id": "720607",
    "label": "Morompaitonga",
    "value": "Morompaitonga"
  },
  {
    "id": "7206072006",
    "district_id": "720607",
    "label": "Kofalagadi",
    "value": "Kofalagadi"
  },
  {
    "id": "7206072008",
    "district_id": "720607",
    "label": "Padei Laut",
    "value": "Padei Laut"
  },
  {
    "id": "7206072009",
    "district_id": "720607",
    "label": "Padei Darat",
    "value": "Padei Darat"
  },
  {
    "id": "7206072010",
    "district_id": "720607",
    "label": "Samarengga",
    "value": "Samarengga"
  },
  {
    "id": "7206072011",
    "district_id": "720607",
    "label": "Masadian",
    "value": "Masadian"
  },
  {
    "id": "7206072012",
    "district_id": "720607",
    "label": "Pulau Tiga",
    "value": "Pulau Tiga"
  },
  {
    "id": "7206072013",
    "district_id": "720607",
    "label": "Matano",
    "value": "Matano"
  },
  {
    "id": "7206072014",
    "district_id": "720607",
    "label": "Matarape",
    "value": "Matarape"
  },
  {
    "id": "7206072015",
    "district_id": "720607",
    "label": "Ulunipa",
    "value": "Ulunipa"
  },
  {
    "id": "7206072016",
    "district_id": "720607",
    "label": "Wawangkolono",
    "value": "Wawangkolono"
  },
  {
    "id": "7206072017",
    "district_id": "720607",
    "label": "Buranga",
    "value": "Buranga"
  },
  {
    "id": "7206072018",
    "district_id": "720607",
    "label": "Dongkalan",
    "value": "Dongkalan"
  },
  {
    "id": "7206072019",
    "district_id": "720607",
    "label": "Tafagapi",
    "value": "Tafagapi"
  },
  {
    "id": "7206072020",
    "district_id": "720607",
    "label": "Pulau Tengah",
    "value": "Pulau Tengah"
  },
  {
    "id": "7206072021",
    "district_id": "720607",
    "label": "Mbokitta",
    "value": "Mbokitta"
  },
  {
    "id": "7206072022",
    "district_id": "720607",
    "label": "Tanjung Tiram",
    "value": "Tanjung Tiram"
  },
  {
    "id": "7206072023",
    "district_id": "720607",
    "label": "Tanona",
    "value": "Tanona"
  },
  {
    "id": "7206072024",
    "district_id": "720607",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "7206082001",
    "district_id": "720608",
    "label": "Bahoea Reko- Reko",
    "value": "Bahoea Reko- Reko"
  },
  {
    "id": "7206082002",
    "district_id": "720608",
    "label": "Wosu",
    "value": "Wosu"
  },
  {
    "id": "7206082003",
    "district_id": "720608",
    "label": "Larobenu",
    "value": "Larobenu"
  },
  {
    "id": "7206082004",
    "district_id": "720608",
    "label": "Umpanga",
    "value": "Umpanga"
  },
  {
    "id": "7206082005",
    "district_id": "720608",
    "label": "Tondo",
    "value": "Tondo"
  },
  {
    "id": "7206082006",
    "district_id": "720608",
    "label": "Topogaro",
    "value": "Topogaro"
  },
  {
    "id": "7206082007",
    "district_id": "720608",
    "label": "Ambunu",
    "value": "Ambunu"
  },
  {
    "id": "7206082008",
    "district_id": "720608",
    "label": "Wata",
    "value": "Wata"
  },
  {
    "id": "7206082012",
    "district_id": "720608",
    "label": "Uedago",
    "value": "Uedago"
  },
  {
    "id": "7206082029",
    "district_id": "720608",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "7206092001",
    "district_id": "720609",
    "label": "Parilangke",
    "value": "Parilangke"
  },
  {
    "id": "7206092002",
    "district_id": "720609",
    "label": "Bahonsuai",
    "value": "Bahonsuai"
  },
  {
    "id": "7206092003",
    "district_id": "720609",
    "label": "Atananga",
    "value": "Atananga"
  },
  {
    "id": "7206092004",
    "district_id": "720609",
    "label": "Pebatae",
    "value": "Pebatae"
  },
  {
    "id": "7206092005",
    "district_id": "720609",
    "label": "Umbele",
    "value": "Umbele"
  },
  {
    "id": "7206092006",
    "district_id": "720609",
    "label": "Lambelu",
    "value": "Lambelu"
  },
  {
    "id": "7206092007",
    "district_id": "720609",
    "label": "Limbo Makmur",
    "value": "Limbo Makmur"
  },
  {
    "id": "7206092008",
    "district_id": "720609",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "7206092009",
    "district_id": "720609",
    "label": "Samarenda",
    "value": "Samarenda"
  },
  {
    "id": "7206092010",
    "district_id": "720609",
    "label": "Lasampi",
    "value": "Lasampi"
  },
  {
    "id": "7206092011",
    "district_id": "720609",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "7206092012",
    "district_id": "720609",
    "label": "Pebotoa",
    "value": "Pebotoa"
  },
  {
    "id": "7206092013",
    "district_id": "720609",
    "label": "Karaupa",
    "value": "Karaupa"
  },
  {
    "id": "7206102001",
    "district_id": "720610",
    "label": "Bete-Bete",
    "value": "Bete-Bete"
  },
  {
    "id": "7206102002",
    "district_id": "720610",
    "label": "Padabahao",
    "value": "Padabahao"
  },
  {
    "id": "7206102003",
    "district_id": "720610",
    "label": "Labota",
    "value": "Labota"
  },
  {
    "id": "7206102004",
    "district_id": "720610",
    "label": "Fatufia",
    "value": "Fatufia"
  },
  {
    "id": "7206102005",
    "district_id": "720610",
    "label": "Keurea",
    "value": "Keurea"
  },
  {
    "id": "7206102006",
    "district_id": "720610",
    "label": "Bahodopi",
    "value": "Bahodopi"
  },
  {
    "id": "7206102007",
    "district_id": "720610",
    "label": "Lalampu",
    "value": "Lalampu"
  },
  {
    "id": "7206102008",
    "district_id": "720610",
    "label": "Siumbatu",
    "value": "Siumbatu"
  },
  {
    "id": "7206102009",
    "district_id": "720610",
    "label": "Dampala",
    "value": "Dampala"
  },
  {
    "id": "7206102010",
    "district_id": "720610",
    "label": "Le-Le",
    "value": "Le-Le"
  },
  {
    "id": "7206102011",
    "district_id": "720610",
    "label": "Baho Makmur",
    "value": "Baho Makmur"
  },
  {
    "id": "7206102012",
    "district_id": "720610",
    "label": "Makartijaya",
    "value": "Makartijaya"
  },
  {
    "id": "7206122001",
    "district_id": "720612",
    "label": "Sampeantaba",
    "value": "Sampeantaba"
  },
  {
    "id": "7206122002",
    "district_id": "720612",
    "label": "Emea",
    "value": "Emea"
  },
  {
    "id": "7206122006",
    "district_id": "720612",
    "label": "Moahino",
    "value": "Moahino"
  },
  {
    "id": "7206122007",
    "district_id": "720612",
    "label": "Solonsa",
    "value": "Solonsa"
  },
  {
    "id": "7206122008",
    "district_id": "720612",
    "label": "Lantula Jaya",
    "value": "Lantula Jaya"
  },
  {
    "id": "7206122009",
    "district_id": "720612",
    "label": "Puntari Makmur",
    "value": "Puntari Makmur"
  },
  {
    "id": "7206122010",
    "district_id": "720612",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "7206122011",
    "district_id": "720612",
    "label": "Solonsa Jaya",
    "value": "Solonsa Jaya"
  },
  {
    "id": "7206122012",
    "district_id": "720612",
    "label": "Ungkaya",
    "value": "Ungkaya"
  },
  {
    "id": "7206152001",
    "district_id": "720615",
    "label": "Puungkeu",
    "value": "Puungkeu"
  },
  {
    "id": "7206152002",
    "district_id": "720615",
    "label": "Tangofa",
    "value": "Tangofa"
  },
  {
    "id": "7206152003",
    "district_id": "720615",
    "label": "One Ete",
    "value": "One Ete"
  },
  {
    "id": "7206152004",
    "district_id": "720615",
    "label": "Tandaoleo",
    "value": "Tandaoleo"
  },
  {
    "id": "7206152005",
    "district_id": "720615",
    "label": "Lafeu",
    "value": "Lafeu"
  },
  {
    "id": "7206152006",
    "district_id": "720615",
    "label": "Torete",
    "value": "Torete"
  },
  {
    "id": "7206152007",
    "district_id": "720615",
    "label": "Buleleng",
    "value": "Buleleng"
  },
  {
    "id": "7206152008",
    "district_id": "720615",
    "label": "Laroenai",
    "value": "Laroenai"
  },
  {
    "id": "7206152009",
    "district_id": "720615",
    "label": "Sambalagi",
    "value": "Sambalagi"
  },
  {
    "id": "7206152010",
    "district_id": "720615",
    "label": "Were'a",
    "value": "Were'a"
  },
  {
    "id": "7206182001",
    "district_id": "720618",
    "label": "Oneputeh Jaya",
    "value": "Oneputeh Jaya"
  },
  {
    "id": "7206182002",
    "district_id": "720618",
    "label": "Bahomotefe",
    "value": "Bahomotefe"
  },
  {
    "id": "7206182003",
    "district_id": "720618",
    "label": "Bahomoahi",
    "value": "Bahomoahi"
  },
  {
    "id": "7206182004",
    "district_id": "720618",
    "label": "Ululere",
    "value": "Ululere"
  },
  {
    "id": "7206182005",
    "district_id": "720618",
    "label": "Kolono",
    "value": "Kolono"
  },
  {
    "id": "7206182006",
    "district_id": "720618",
    "label": "Geresa",
    "value": "Geresa"
  },
  {
    "id": "7206182007",
    "district_id": "720618",
    "label": "Laroue",
    "value": "Laroue"
  },
  {
    "id": "7206182008",
    "district_id": "720618",
    "label": "Nambo",
    "value": "Nambo"
  },
  {
    "id": "7206182009",
    "district_id": "720618",
    "label": "Unsongi",
    "value": "Unsongi"
  },
  {
    "id": "7206182010",
    "district_id": "720618",
    "label": "Lahuafu",
    "value": "Lahuafu"
  },
  {
    "id": "7207032007",
    "district_id": "720703",
    "label": "Sobonon",
    "value": "Sobonon"
  },
  {
    "id": "7207032008",
    "district_id": "720703",
    "label": "Bolonan",
    "value": "Bolonan"
  },
  {
    "id": "7207032009",
    "district_id": "720703",
    "label": "Batang Babasal",
    "value": "Batang Babasal"
  },
  {
    "id": "7207032010",
    "district_id": "720703",
    "label": "Sampaka",
    "value": "Sampaka"
  },
  {
    "id": "7207032011",
    "district_id": "720703",
    "label": "Salangano",
    "value": "Salangano"
  },
  {
    "id": "7207032012",
    "district_id": "720703",
    "label": "Sambiut",
    "value": "Sambiut"
  },
  {
    "id": "7207032013",
    "district_id": "720703",
    "label": "Sakay",
    "value": "Sakay"
  },
  {
    "id": "7207032014",
    "district_id": "720703",
    "label": "Tone",
    "value": "Tone"
  },
  {
    "id": "7207032015",
    "district_id": "720703",
    "label": "Abason",
    "value": "Abason"
  },
  {
    "id": "7207032016",
    "district_id": "720703",
    "label": "Lopito",
    "value": "Lopito"
  },
  {
    "id": "7207032017",
    "district_id": "720703",
    "label": "Kombutokan",
    "value": "Kombutokan"
  },
  {
    "id": "7207041015",
    "district_id": "720704",
    "label": "Salakan",
    "value": "Salakan"
  },
  {
    "id": "7207042009",
    "district_id": "720704",
    "label": "Manggalai",
    "value": "Manggalai"
  },
  {
    "id": "7207042010",
    "district_id": "720704",
    "label": "Ambelang",
    "value": "Ambelang"
  },
  {
    "id": "7207042011",
    "district_id": "720704",
    "label": "Saiyong",
    "value": "Saiyong"
  },
  {
    "id": "7207042012",
    "district_id": "720704",
    "label": "Tompudau",
    "value": "Tompudau"
  },
  {
    "id": "7207042013",
    "district_id": "720704",
    "label": "Bongganan",
    "value": "Bongganan"
  },
  {
    "id": "7207042014",
    "district_id": "720704",
    "label": "Baka",
    "value": "Baka"
  },
  {
    "id": "7207042016",
    "district_id": "720704",
    "label": "Bulungkobit",
    "value": "Bulungkobit"
  },
  {
    "id": "7207042017",
    "district_id": "720704",
    "label": "Bakalan",
    "value": "Bakalan"
  },
  {
    "id": "7207042018",
    "district_id": "720704",
    "label": "Kautu",
    "value": "Kautu"
  },
  {
    "id": "7207042022",
    "district_id": "720704",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "7207052001",
    "district_id": "720705",
    "label": "Tangkop",
    "value": "Tangkop"
  },
  {
    "id": "7207052002",
    "district_id": "720705",
    "label": "Binuntuli",
    "value": "Binuntuli"
  },
  {
    "id": "7207052003",
    "district_id": "720705",
    "label": "Popidolon",
    "value": "Popidolon"
  },
  {
    "id": "7207052004",
    "district_id": "720705",
    "label": "Okumel",
    "value": "Okumel"
  },
  {
    "id": "7207052005",
    "district_id": "720705",
    "label": "Tomboniki",
    "value": "Tomboniki"
  },
  {
    "id": "7207052006",
    "district_id": "720705",
    "label": "Kindandal",
    "value": "Kindandal"
  },
  {
    "id": "7207052007",
    "district_id": "720705",
    "label": "Mamulusan",
    "value": "Mamulusan"
  },
  {
    "id": "7207052010",
    "district_id": "720705",
    "label": "Saleati",
    "value": "Saleati"
  },
  {
    "id": "7207052011",
    "district_id": "720705",
    "label": "Liang",
    "value": "Liang"
  },
  {
    "id": "7207052012",
    "district_id": "720705",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "7207052013",
    "district_id": "720705",
    "label": "Balayon",
    "value": "Balayon"
  },
  {
    "id": "7207052016",
    "district_id": "720705",
    "label": "Basosol",
    "value": "Basosol"
  },
  {
    "id": "7207052017",
    "district_id": "720705",
    "label": "Boyoumoute",
    "value": "Boyoumoute"
  },
  {
    "id": "7207052018",
    "district_id": "720705",
    "label": "Apal",
    "value": "Apal"
  },
  {
    "id": "7207052025",
    "district_id": "720705",
    "label": "Selekan",
    "value": "Selekan"
  },
  {
    "id": "7207052026",
    "district_id": "720705",
    "label": "Loolong",
    "value": "Loolong"
  },
  {
    "id": "7207061018",
    "district_id": "720706",
    "label": "Bulagi I",
    "value": "Bulagi I"
  },
  {
    "id": "7207062014",
    "district_id": "720706",
    "label": "Tolo",
    "value": "Tolo"
  },
  {
    "id": "7207062015",
    "district_id": "720706",
    "label": "Lalandai",
    "value": "Lalandai"
  },
  {
    "id": "7207062016",
    "district_id": "720706",
    "label": "Montomisan",
    "value": "Montomisan"
  },
  {
    "id": "7207062017",
    "district_id": "720706",
    "label": "Sosom",
    "value": "Sosom"
  },
  {
    "id": "7207062019",
    "district_id": "720706",
    "label": "Bulagi II",
    "value": "Bulagi II"
  },
  {
    "id": "7207062020",
    "district_id": "720706",
    "label": "Boloy",
    "value": "Boloy"
  },
  {
    "id": "7207062021",
    "district_id": "720706",
    "label": "Meselesek",
    "value": "Meselesek"
  },
  {
    "id": "7207062022",
    "district_id": "720706",
    "label": "Oluno",
    "value": "Oluno"
  },
  {
    "id": "7207062023",
    "district_id": "720706",
    "label": "Sumondung",
    "value": "Sumondung"
  },
  {
    "id": "7207062024",
    "district_id": "720706",
    "label": "Pelingseasa",
    "value": "Pelingseasa"
  },
  {
    "id": "7207062025",
    "district_id": "720706",
    "label": "Komba-komba",
    "value": "Komba-komba"
  },
  {
    "id": "7207062035",
    "district_id": "720706",
    "label": "Alul",
    "value": "Alul"
  },
  {
    "id": "7207062038",
    "district_id": "720706",
    "label": "Toolon",
    "value": "Toolon"
  },
  {
    "id": "7207062039",
    "district_id": "720706",
    "label": "Kambal",
    "value": "Kambal"
  },
  {
    "id": "7207062040",
    "district_id": "720706",
    "label": "Kayubet",
    "value": "Kayubet"
  },
  {
    "id": "7207072008",
    "district_id": "720707",
    "label": "Paisubatu",
    "value": "Paisubatu"
  },
  {
    "id": "7207072009",
    "district_id": "720707",
    "label": "Batangono",
    "value": "Batangono"
  },
  {
    "id": "7207072010",
    "district_id": "720707",
    "label": "Lalengan",
    "value": "Lalengan"
  },
  {
    "id": "7207072011",
    "district_id": "720707",
    "label": "Peling Lalomo",
    "value": "Peling Lalomo"
  },
  {
    "id": "7207072012",
    "district_id": "720707",
    "label": "Labasiano",
    "value": "Labasiano"
  },
  {
    "id": "7207072013",
    "district_id": "720707",
    "label": "Tataba",
    "value": "Tataba"
  },
  {
    "id": "7207072014",
    "district_id": "720707",
    "label": "Malanggong",
    "value": "Malanggong"
  },
  {
    "id": "7207072015",
    "district_id": "720707",
    "label": "Leme-leme bungin",
    "value": "Leme-leme bungin"
  },
  {
    "id": "7207072016",
    "district_id": "720707",
    "label": "Leme-leme darat",
    "value": "Leme-leme darat"
  },
  {
    "id": "7207072017",
    "district_id": "720707",
    "label": "Talas-talas",
    "value": "Talas-talas"
  },
  {
    "id": "7207072018",
    "district_id": "720707",
    "label": "Okulo Potil",
    "value": "Okulo Potil"
  },
  {
    "id": "7207072019",
    "district_id": "720707",
    "label": "Tatendeng",
    "value": "Tatendeng"
  },
  {
    "id": "7207072020",
    "district_id": "720707",
    "label": "Olusi",
    "value": "Olusi"
  },
  {
    "id": "7207092001",
    "district_id": "720709",
    "label": "Bonepuso",
    "value": "Bonepuso"
  },
  {
    "id": "7207092002",
    "district_id": "720709",
    "label": "Palabatu II",
    "value": "Palabatu II"
  },
  {
    "id": "7207092003",
    "district_id": "720709",
    "label": "Tatarandang",
    "value": "Tatarandang"
  },
  {
    "id": "7207092004",
    "district_id": "720709",
    "label": "Pandaluk",
    "value": "Pandaluk"
  },
  {
    "id": "7207092005",
    "district_id": "720709",
    "label": "Osan",
    "value": "Osan"
  },
  {
    "id": "7207092006",
    "district_id": "720709",
    "label": "Balalon",
    "value": "Balalon"
  },
  {
    "id": "7207092007",
    "district_id": "720709",
    "label": "Sabelak",
    "value": "Sabelak"
  },
  {
    "id": "7207092008",
    "district_id": "720709",
    "label": "Suit",
    "value": "Suit"
  },
  {
    "id": "7207092009",
    "district_id": "720709",
    "label": "Lolantang",
    "value": "Lolantang"
  },
  {
    "id": "7207092010",
    "district_id": "720709",
    "label": "Toi-Toi",
    "value": "Toi-Toi"
  },
  {
    "id": "7207092011",
    "district_id": "720709",
    "label": "Palabatu I",
    "value": "Palabatu I"
  },
  {
    "id": "7207092012",
    "district_id": "720709",
    "label": "Mangais",
    "value": "Mangais"
  },
  {
    "id": "7207092013",
    "district_id": "720709",
    "label": "Unu",
    "value": "Unu"
  },
  {
    "id": "7207092014",
    "district_id": "720709",
    "label": "Lemelu",
    "value": "Lemelu"
  },
  {
    "id": "7207092015",
    "district_id": "720709",
    "label": "Babang",
    "value": "Babang"
  },
  {
    "id": "7207092016",
    "district_id": "720709",
    "label": "Momotan",
    "value": "Momotan"
  },
  {
    "id": "7207092017",
    "district_id": "720709",
    "label": "Boluni",
    "value": "Boluni"
  },
  {
    "id": "7207092018",
    "district_id": "720709",
    "label": "Labotankandi",
    "value": "Labotankandi"
  },
  {
    "id": "7207092019",
    "district_id": "720709",
    "label": "Alasan Nggolobuton",
    "value": "Alasan Nggolobuton"
  },
  {
    "id": "7207092020",
    "district_id": "720709",
    "label": "Pipilogot Paipaisu",
    "value": "Pipilogot Paipaisu"
  },
  {
    "id": "7207112001",
    "district_id": "720711",
    "label": "Tobing",
    "value": "Tobing"
  },
  {
    "id": "7207112002",
    "district_id": "720711",
    "label": "Paisumusoni",
    "value": "Paisumusoni"
  },
  {
    "id": "7207112003",
    "district_id": "720711",
    "label": "Tobungin",
    "value": "Tobungin"
  },
  {
    "id": "7207112004",
    "district_id": "720711",
    "label": "Mansamat A",
    "value": "Mansamat A"
  },
  {
    "id": "7207112005",
    "district_id": "720711",
    "label": "Mansamat B",
    "value": "Mansamat B"
  },
  {
    "id": "7207112006",
    "district_id": "720711",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "7207112007",
    "district_id": "720711",
    "label": "Tinangkung",
    "value": "Tinangkung"
  },
  {
    "id": "7207112008",
    "district_id": "720711",
    "label": "Gansal",
    "value": "Gansal"
  },
  {
    "id": "7207112009",
    "district_id": "720711",
    "label": "Bobu",
    "value": "Bobu"
  },
  {
    "id": "7207152001",
    "district_id": "720715",
    "label": "Kalumbatan",
    "value": "Kalumbatan"
  },
  {
    "id": "7207152002",
    "district_id": "720715",
    "label": "Tonuson",
    "value": "Tonuson"
  },
  {
    "id": "7207152003",
    "district_id": "720715",
    "label": "Tobungku",
    "value": "Tobungku"
  },
  {
    "id": "7207152004",
    "district_id": "720715",
    "label": "Nulion",
    "value": "Nulion"
  },
  {
    "id": "7207152005",
    "district_id": "720715",
    "label": "Peley",
    "value": "Peley"
  },
  {
    "id": "7207152006",
    "district_id": "720715",
    "label": "Kanali",
    "value": "Kanali"
  },
  {
    "id": "7207152007",
    "district_id": "720715",
    "label": "Mata",
    "value": "Mata"
  },
  {
    "id": "7207152008",
    "district_id": "720715",
    "label": "Lobuton",
    "value": "Lobuton"
  },
  {
    "id": "7207162001",
    "district_id": "720716",
    "label": "Patukuki",
    "value": "Patukuki"
  },
  {
    "id": "7207162002",
    "district_id": "720716",
    "label": "Balombong",
    "value": "Balombong"
  },
  {
    "id": "7207162003",
    "district_id": "720716",
    "label": "Kolak",
    "value": "Kolak"
  },
  {
    "id": "7207162004",
    "district_id": "720716",
    "label": "Koyobunga",
    "value": "Koyobunga"
  },
  {
    "id": "7207162005",
    "district_id": "720716",
    "label": "Alakasing",
    "value": "Alakasing"
  },
  {
    "id": "7207162006",
    "district_id": "720716",
    "label": "Tombos",
    "value": "Tombos"
  },
  {
    "id": "7207162007",
    "district_id": "720716",
    "label": "Luk",
    "value": "Luk"
  },
  {
    "id": "7207162008",
    "district_id": "720716",
    "label": "Labibi",
    "value": "Labibi"
  },
  {
    "id": "7207162009",
    "district_id": "720716",
    "label": "Popisi",
    "value": "Popisi"
  },
  {
    "id": "7207162010",
    "district_id": "720716",
    "label": "Tolulos",
    "value": "Tolulos"
  },
  {
    "id": "7207162011",
    "district_id": "720716",
    "label": "Tunggaling",
    "value": "Tunggaling"
  },
  {
    "id": "7207171009",
    "district_id": "720717",
    "label": "Sabang",
    "value": "Sabang"
  },
  {
    "id": "7207172001",
    "district_id": "720717",
    "label": "Sambulangan",
    "value": "Sambulangan"
  },
  {
    "id": "7207172002",
    "district_id": "720717",
    "label": "Bangunemo",
    "value": "Bangunemo"
  },
  {
    "id": "7207172003",
    "district_id": "720717",
    "label": "Paisuluno",
    "value": "Paisuluno"
  },
  {
    "id": "7207172004",
    "district_id": "720717",
    "label": "Montop",
    "value": "Montop"
  },
  {
    "id": "7207172005",
    "district_id": "720717",
    "label": "Bolubung",
    "value": "Bolubung"
  },
  {
    "id": "7207172006",
    "district_id": "720717",
    "label": "Bakalinga",
    "value": "Bakalinga"
  },
  {
    "id": "7207172007",
    "district_id": "720717",
    "label": "Lukpanenteng",
    "value": "Lukpanenteng"
  },
  {
    "id": "7207172008",
    "district_id": "720717",
    "label": "Koyobunga",
    "value": "Koyobunga"
  },
  {
    "id": "7207172010",
    "district_id": "720717",
    "label": "Ombuli",
    "value": "Ombuli"
  },
  {
    "id": "7207172011",
    "district_id": "720717",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7207172012",
    "district_id": "720717",
    "label": "Mandok",
    "value": "Mandok"
  },
  {
    "id": "7207182001",
    "district_id": "720718",
    "label": "Lumbi-lumbia",
    "value": "Lumbi-lumbia"
  },
  {
    "id": "7207182002",
    "district_id": "720718",
    "label": "Kambani",
    "value": "Kambani"
  },
  {
    "id": "7207182003",
    "district_id": "720718",
    "label": "Tatabau",
    "value": "Tatabau"
  },
  {
    "id": "7207182004",
    "district_id": "720718",
    "label": "Buko",
    "value": "Buko"
  },
  {
    "id": "7207182005",
    "district_id": "720718",
    "label": "Palapat",
    "value": "Palapat"
  },
  {
    "id": "7207182006",
    "district_id": "720718",
    "label": "Labangun",
    "value": "Labangun"
  },
  {
    "id": "7207182007",
    "district_id": "720718",
    "label": "Seano",
    "value": "Seano"
  },
  {
    "id": "7207182008",
    "district_id": "720718",
    "label": "Lelang Matamaling",
    "value": "Lelang Matamaling"
  },
  {
    "id": "7207182009",
    "district_id": "720718",
    "label": "Apal",
    "value": "Apal"
  },
  {
    "id": "7207182010",
    "district_id": "720718",
    "label": "Sapelang",
    "value": "Sapelang"
  },
  {
    "id": "7207182011",
    "district_id": "720718",
    "label": "Landonan Bebeau",
    "value": "Landonan Bebeau"
  },
  {
    "id": "7207192001",
    "district_id": "720719",
    "label": "Tatakalai",
    "value": "Tatakalai"
  },
  {
    "id": "7207192002",
    "district_id": "720719",
    "label": "Lalong",
    "value": "Lalong"
  },
  {
    "id": "7207192003",
    "district_id": "720719",
    "label": "Ponding-ponding",
    "value": "Ponding-ponding"
  },
  {
    "id": "7207192004",
    "district_id": "720719",
    "label": "Luksagu",
    "value": "Luksagu"
  },
  {
    "id": "7207192005",
    "district_id": "720719",
    "label": "Palam",
    "value": "Palam"
  },
  {
    "id": "7207192006",
    "district_id": "720719",
    "label": "Bampanga",
    "value": "Bampanga"
  },
  {
    "id": "7208011017",
    "district_id": "720801",
    "label": "Masigi",
    "value": "Masigi"
  },
  {
    "id": "7208011018",
    "district_id": "720801",
    "label": "Maesa",
    "value": "Maesa"
  },
  {
    "id": "7208011019",
    "district_id": "720801",
    "label": "Loji",
    "value": "Loji"
  },
  {
    "id": "7208011020",
    "district_id": "720801",
    "label": "Bantaya",
    "value": "Bantaya"
  },
  {
    "id": "7208011021",
    "district_id": "720801",
    "label": "Kampal",
    "value": "Kampal"
  },
  {
    "id": "7208012011",
    "district_id": "720801",
    "label": "Olaya",
    "value": "Olaya"
  },
  {
    "id": "7208012012",
    "district_id": "720801",
    "label": "Pembalowo",
    "value": "Pembalowo"
  },
  {
    "id": "7208012013",
    "district_id": "720801",
    "label": "Mertasari",
    "value": "Mertasari"
  },
  {
    "id": "7208012022",
    "district_id": "720801",
    "label": "Bambalemo",
    "value": "Bambalemo"
  },
  {
    "id": "7208012023",
    "district_id": "720801",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "7208012035",
    "district_id": "720801",
    "label": "Bambalemo Ranomaisi",
    "value": "Bambalemo Ranomaisi"
  },
  {
    "id": "7208022005",
    "district_id": "720802",
    "label": "Tolole",
    "value": "Tolole"
  },
  {
    "id": "7208022006",
    "district_id": "720802",
    "label": "Toga",
    "value": "Toga"
  },
  {
    "id": "7208022007",
    "district_id": "720802",
    "label": "Sidole",
    "value": "Sidole"
  },
  {
    "id": "7208022008",
    "district_id": "720802",
    "label": "Paranggi",
    "value": "Paranggi"
  },
  {
    "id": "7208022009",
    "district_id": "720802",
    "label": "Ampibabo",
    "value": "Ampibabo"
  },
  {
    "id": "7208022010",
    "district_id": "720802",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7208022011",
    "district_id": "720802",
    "label": "Buranga",
    "value": "Buranga"
  },
  {
    "id": "7208022021",
    "district_id": "720802",
    "label": "Tanampedagi",
    "value": "Tanampedagi"
  },
  {
    "id": "7208022022",
    "district_id": "720802",
    "label": "Tombi",
    "value": "Tombi"
  },
  {
    "id": "7208022024",
    "district_id": "720802",
    "label": "Ampibabo Utara",
    "value": "Ampibabo Utara"
  },
  {
    "id": "7208022025",
    "district_id": "720802",
    "label": "Ogolugus",
    "value": "Ogolugus"
  },
  {
    "id": "7208022026",
    "district_id": "720802",
    "label": "Lemo Utara",
    "value": "Lemo Utara"
  },
  {
    "id": "7208022027",
    "district_id": "720802",
    "label": "Sidole Barat",
    "value": "Sidole Barat"
  },
  {
    "id": "7208022028",
    "district_id": "720802",
    "label": "Aloo",
    "value": "Aloo"
  },
  {
    "id": "7208022029",
    "district_id": "720802",
    "label": "Ampibabo Timur",
    "value": "Ampibabo Timur"
  },
  {
    "id": "7208022030",
    "district_id": "720802",
    "label": "Sidole Timur",
    "value": "Sidole Timur"
  },
  {
    "id": "7208022031",
    "district_id": "720802",
    "label": "Pangku",
    "value": "Pangku"
  },
  {
    "id": "7208022032",
    "district_id": "720802",
    "label": "Tolole Raya",
    "value": "Tolole Raya"
  },
  {
    "id": "7208022033",
    "district_id": "720802",
    "label": "Lemo Tengah",
    "value": "Lemo Tengah"
  },
  {
    "id": "7208032008",
    "district_id": "720803",
    "label": "Bainaa",
    "value": "Bainaa"
  },
  {
    "id": "7208032009",
    "district_id": "720803",
    "label": "Dongkas",
    "value": "Dongkas"
  },
  {
    "id": "7208032010",
    "district_id": "720803",
    "label": "Tinombo",
    "value": "Tinombo"
  },
  {
    "id": "7208032011",
    "district_id": "720803",
    "label": "Lombok",
    "value": "Lombok"
  },
  {
    "id": "7208032012",
    "district_id": "720803",
    "label": "Dusunan",
    "value": "Dusunan"
  },
  {
    "id": "7208032013",
    "district_id": "720803",
    "label": "Tibu",
    "value": "Tibu"
  },
  {
    "id": "7208032019",
    "district_id": "720803",
    "label": "Bainaa Barat",
    "value": "Bainaa Barat"
  },
  {
    "id": "7208032021",
    "district_id": "720803",
    "label": "Ogoalas",
    "value": "Ogoalas"
  },
  {
    "id": "7208032022",
    "district_id": "720803",
    "label": "Lombok Barat",
    "value": "Lombok Barat"
  },
  {
    "id": "7208032027",
    "district_id": "720803",
    "label": "Silabia",
    "value": "Silabia"
  },
  {
    "id": "7208032028",
    "district_id": "720803",
    "label": "Siavu",
    "value": "Siavu"
  },
  {
    "id": "7208032029",
    "district_id": "720803",
    "label": "Ambason Mekar",
    "value": "Ambason Mekar"
  },
  {
    "id": "7208032030",
    "district_id": "720803",
    "label": "Dusunan Barat",
    "value": "Dusunan Barat"
  },
  {
    "id": "7208032031",
    "district_id": "720803",
    "label": "Patingke",
    "value": "Patingke"
  },
  {
    "id": "7208032033",
    "district_id": "720803",
    "label": "Taipaobal",
    "value": "Taipaobal"
  },
  {
    "id": "7208042001",
    "district_id": "720804",
    "label": "Moutong Timur",
    "value": "Moutong Timur"
  },
  {
    "id": "7208042002",
    "district_id": "720804",
    "label": "Moutong Barat",
    "value": "Moutong Barat"
  },
  {
    "id": "7208042003",
    "district_id": "720804",
    "label": "Moutong Tengah",
    "value": "Moutong Tengah"
  },
  {
    "id": "7208042004",
    "district_id": "720804",
    "label": "Tuladenggi Pantai",
    "value": "Tuladenggi Pantai"
  },
  {
    "id": "7208042006",
    "district_id": "720804",
    "label": "Gio",
    "value": "Gio"
  },
  {
    "id": "7208042024",
    "district_id": "720804",
    "label": "Lobu",
    "value": "Lobu"
  },
  {
    "id": "7208042025",
    "district_id": "720804",
    "label": "Pendelalap",
    "value": "Pendelalap"
  },
  {
    "id": "7208042026",
    "district_id": "720804",
    "label": "Sejoli",
    "value": "Sejoli"
  },
  {
    "id": "7208042028",
    "district_id": "720804",
    "label": "Aedan Raya",
    "value": "Aedan Raya"
  },
  {
    "id": "7208042029",
    "district_id": "720804",
    "label": "Salumpengut",
    "value": "Salumpengut"
  },
  {
    "id": "7208042030",
    "district_id": "720804",
    "label": "Boloung Olonggata",
    "value": "Boloung Olonggata"
  },
  {
    "id": "7208042031",
    "district_id": "720804",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "7208042032",
    "district_id": "720804",
    "label": "Mbelang Mbelang",
    "value": "Mbelang Mbelang"
  },
  {
    "id": "7208042033",
    "district_id": "720804",
    "label": "Pande",
    "value": "Pande"
  },
  {
    "id": "7208042034",
    "district_id": "720804",
    "label": "Moutong Utara",
    "value": "Moutong Utara"
  },
  {
    "id": "7208042035",
    "district_id": "720804",
    "label": "Salepae",
    "value": "Salepae"
  },
  {
    "id": "7208042036",
    "district_id": "720804",
    "label": "Olonggata",
    "value": "Olonggata"
  },
  {
    "id": "7208042037",
    "district_id": "720804",
    "label": "Gio Timur",
    "value": "Gio Timur"
  },
  {
    "id": "7208042038",
    "district_id": "720804",
    "label": "Gio Barat",
    "value": "Gio Barat"
  },
  {
    "id": "7208042039",
    "district_id": "720804",
    "label": "Sialopa",
    "value": "Sialopa"
  },
  {
    "id": "7208052005",
    "district_id": "720805",
    "label": "Tingkulang",
    "value": "Tingkulang"
  },
  {
    "id": "7208052006",
    "district_id": "720805",
    "label": "Tomini",
    "value": "Tomini"
  },
  {
    "id": "7208052007",
    "district_id": "720805",
    "label": "Ogotumubu",
    "value": "Ogotumubu"
  },
  {
    "id": "7208052008",
    "district_id": "720805",
    "label": "Ambesia",
    "value": "Ambesia"
  },
  {
    "id": "7208052009",
    "district_id": "720805",
    "label": "Tilung",
    "value": "Tilung"
  },
  {
    "id": "7208052022",
    "district_id": "720805",
    "label": "Ambesia Selatan",
    "value": "Ambesia Selatan"
  },
  {
    "id": "7208052023",
    "district_id": "720805",
    "label": "Biga",
    "value": "Biga"
  },
  {
    "id": "7208052024",
    "district_id": "720805",
    "label": "Tomini Utara",
    "value": "Tomini Utara"
  },
  {
    "id": "7208052025",
    "district_id": "720805",
    "label": "Tomini Barat",
    "value": "Tomini Barat"
  },
  {
    "id": "7208052026",
    "district_id": "720805",
    "label": "Supilopong",
    "value": "Supilopong"
  },
  {
    "id": "7208052027",
    "district_id": "720805",
    "label": "Ambesia Barat",
    "value": "Ambesia Barat"
  },
  {
    "id": "7208052028",
    "district_id": "720805",
    "label": "Sipontan",
    "value": "Sipontan"
  },
  {
    "id": "7208052029",
    "district_id": "720805",
    "label": "Ta'aniuge",
    "value": "Ta'aniuge"
  },
  {
    "id": "7208052030",
    "district_id": "720805",
    "label": "Ogotumubu Barat",
    "value": "Ogotumubu Barat"
  },
  {
    "id": "7208062009",
    "district_id": "720806",
    "label": "Sausu Peore",
    "value": "Sausu Peore"
  },
  {
    "id": "7208062010",
    "district_id": "720806",
    "label": "Maleali",
    "value": "Maleali"
  },
  {
    "id": "7208062011",
    "district_id": "720806",
    "label": "Sausu Trans",
    "value": "Sausu Trans"
  },
  {
    "id": "7208062012",
    "district_id": "720806",
    "label": "Sausu Torono",
    "value": "Sausu Torono"
  },
  {
    "id": "7208062013",
    "district_id": "720806",
    "label": "Sausu Pakareme",
    "value": "Sausu Pakareme"
  },
  {
    "id": "7208062015",
    "district_id": "720806",
    "label": "Sausu Gandasari",
    "value": "Sausu Gandasari"
  },
  {
    "id": "7208062016",
    "district_id": "720806",
    "label": "Sausu Salubanga",
    "value": "Sausu Salubanga"
  },
  {
    "id": "7208062017",
    "district_id": "720806",
    "label": "Taliabo",
    "value": "Taliabo"
  },
  {
    "id": "7208062018",
    "district_id": "720806",
    "label": "Sausu Tambu",
    "value": "Sausu Tambu"
  },
  {
    "id": "7208062019",
    "district_id": "720806",
    "label": "Sausu Auma",
    "value": "Sausu Auma"
  },
  {
    "id": "7208072001",
    "district_id": "720807",
    "label": "Lambunu",
    "value": "Lambunu"
  },
  {
    "id": "7208072004",
    "district_id": "720807",
    "label": "Wanagading",
    "value": "Wanagading"
  },
  {
    "id": "7208072005",
    "district_id": "720807",
    "label": "Kotanagaya",
    "value": "Kotanagaya"
  },
  {
    "id": "7208072006",
    "district_id": "720807",
    "label": "Margapura",
    "value": "Margapura"
  },
  {
    "id": "7208072007",
    "district_id": "720807",
    "label": "Petunasugi",
    "value": "Petunasugi"
  },
  {
    "id": "7208072016",
    "district_id": "720807",
    "label": "Siendeng",
    "value": "Siendeng"
  },
  {
    "id": "7208072017",
    "district_id": "720807",
    "label": "Lambunu Utara",
    "value": "Lambunu Utara"
  },
  {
    "id": "7208072021",
    "district_id": "720807",
    "label": "Gunungsari",
    "value": "Gunungsari"
  },
  {
    "id": "7208072031",
    "district_id": "720807",
    "label": "Tirtanagaya",
    "value": "Tirtanagaya"
  },
  {
    "id": "7208072033",
    "district_id": "720807",
    "label": "Anutapura",
    "value": "Anutapura"
  },
  {
    "id": "7208072035",
    "district_id": "720807",
    "label": "Lambunu Timur",
    "value": "Lambunu Timur"
  },
  {
    "id": "7208072036",
    "district_id": "720807",
    "label": "Bukit Makmur",
    "value": "Bukit Makmur"
  },
  {
    "id": "7208072037",
    "district_id": "720807",
    "label": "Ogorandu",
    "value": "Ogorandu"
  },
  {
    "id": "7208072038",
    "district_id": "720807",
    "label": "Ganongol Sari",
    "value": "Ganongol Sari"
  },
  {
    "id": "7208082001",
    "district_id": "720808",
    "label": "Kasimbar",
    "value": "Kasimbar"
  },
  {
    "id": "7208082002",
    "district_id": "720808",
    "label": "Posona",
    "value": "Posona"
  },
  {
    "id": "7208082003",
    "district_id": "720808",
    "label": "Laemanta",
    "value": "Laemanta"
  },
  {
    "id": "7208082004",
    "district_id": "720808",
    "label": "Donggulu",
    "value": "Donggulu"
  },
  {
    "id": "7208082005",
    "district_id": "720808",
    "label": "Kasimbar Selatan",
    "value": "Kasimbar Selatan"
  },
  {
    "id": "7208082006",
    "district_id": "720808",
    "label": "Kasimbar Barat",
    "value": "Kasimbar Barat"
  },
  {
    "id": "7208082007",
    "district_id": "720808",
    "label": "Silampayang",
    "value": "Silampayang"
  },
  {
    "id": "7208082008",
    "district_id": "720808",
    "label": "Tovalo",
    "value": "Tovalo"
  },
  {
    "id": "7208082009",
    "district_id": "720808",
    "label": "Donggulu Selatan",
    "value": "Donggulu Selatan"
  },
  {
    "id": "7208082010",
    "district_id": "720808",
    "label": "Labuan Donggulu",
    "value": "Labuan Donggulu"
  },
  {
    "id": "7208082011",
    "district_id": "720808",
    "label": "Kasimbar Palapi",
    "value": "Kasimbar Palapi"
  },
  {
    "id": "7208082012",
    "district_id": "720808",
    "label": "Peningka",
    "value": "Peningka"
  },
  {
    "id": "7208082013",
    "district_id": "720808",
    "label": "Laemanta Utara",
    "value": "Laemanta Utara"
  },
  {
    "id": "7208082014",
    "district_id": "720808",
    "label": "Agodopi Kasimbar",
    "value": "Agodopi Kasimbar"
  },
  {
    "id": "7208082015",
    "district_id": "720808",
    "label": "Sendana",
    "value": "Sendana"
  },
  {
    "id": "7208082016",
    "district_id": "720808",
    "label": "Kasimbar Utara",
    "value": "Kasimbar Utara"
  },
  {
    "id": "7208082017",
    "district_id": "720808",
    "label": "Posona Atas",
    "value": "Posona Atas"
  },
  {
    "id": "7208082018",
    "district_id": "720808",
    "label": "Sumber Tani",
    "value": "Sumber Tani"
  },
  {
    "id": "7208092001",
    "district_id": "720809",
    "label": "Tanalanto",
    "value": "Tanalanto"
  },
  {
    "id": "7208092002",
    "district_id": "720809",
    "label": "Torue",
    "value": "Torue"
  },
  {
    "id": "7208092003",
    "district_id": "720809",
    "label": "Astina",
    "value": "Astina"
  },
  {
    "id": "7208092004",
    "district_id": "720809",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "7208092005",
    "district_id": "720809",
    "label": "Tolai",
    "value": "Tolai"
  },
  {
    "id": "7208092006",
    "district_id": "720809",
    "label": "Tolai Barat",
    "value": "Tolai Barat"
  },
  {
    "id": "7208092007",
    "district_id": "720809",
    "label": "Tolai Timur",
    "value": "Tolai Timur"
  },
  {
    "id": "7208102001",
    "district_id": "720810",
    "label": "Tada",
    "value": "Tada"
  },
  {
    "id": "7208102002",
    "district_id": "720810",
    "label": "Sinei",
    "value": "Sinei"
  },
  {
    "id": "7208102003",
    "district_id": "720810",
    "label": "Maninili",
    "value": "Maninili"
  },
  {
    "id": "7208102004",
    "district_id": "720810",
    "label": "Sigenti",
    "value": "Sigenti"
  },
  {
    "id": "7208102005",
    "district_id": "720810",
    "label": "Malanggo",
    "value": "Malanggo"
  },
  {
    "id": "7208102006",
    "district_id": "720810",
    "label": "Silutung",
    "value": "Silutung"
  },
  {
    "id": "7208102007",
    "district_id": "720810",
    "label": "Tada Selatan",
    "value": "Tada Selatan"
  },
  {
    "id": "7208102008",
    "district_id": "720810",
    "label": "Sigega Bersehati",
    "value": "Sigega Bersehati"
  },
  {
    "id": "7208102009",
    "district_id": "720810",
    "label": "Siaga",
    "value": "Siaga"
  },
  {
    "id": "7208102010",
    "district_id": "720810",
    "label": "Khatulistiwa",
    "value": "Khatulistiwa"
  },
  {
    "id": "7208102011",
    "district_id": "720810",
    "label": "Poly",
    "value": "Poly"
  },
  {
    "id": "7208102012",
    "district_id": "720810",
    "label": "Sigenti Selatan",
    "value": "Sigenti Selatan"
  },
  {
    "id": "7208102013",
    "district_id": "720810",
    "label": "Tada Timur",
    "value": "Tada Timur"
  },
  {
    "id": "7208102014",
    "district_id": "720810",
    "label": "Sigenti Barat",
    "value": "Sigenti Barat"
  },
  {
    "id": "7208102015",
    "district_id": "720810",
    "label": "Malanggo Pesisir",
    "value": "Malanggo Pesisir"
  },
  {
    "id": "7208102016",
    "district_id": "720810",
    "label": "Maninili Barat",
    "value": "Maninili Barat"
  },
  {
    "id": "7208102017",
    "district_id": "720810",
    "label": "Maninili Utara",
    "value": "Maninili Utara"
  },
  {
    "id": "7208102018",
    "district_id": "720810",
    "label": "Siney Tengah",
    "value": "Siney Tengah"
  },
  {
    "id": "7208102019",
    "district_id": "720810",
    "label": "Tada Utara",
    "value": "Tada Utara"
  },
  {
    "id": "7208102020",
    "district_id": "720810",
    "label": "Oncone Raya",
    "value": "Oncone Raya"
  },
  {
    "id": "7208112001",
    "district_id": "720811",
    "label": "Lemusa",
    "value": "Lemusa"
  },
  {
    "id": "7208112002",
    "district_id": "720811",
    "label": "Olobaru",
    "value": "Olobaru"
  },
  {
    "id": "7208112003",
    "district_id": "720811",
    "label": "Boyantongo",
    "value": "Boyantongo"
  },
  {
    "id": "7208112004",
    "district_id": "720811",
    "label": "Dolago",
    "value": "Dolago"
  },
  {
    "id": "7208112005",
    "district_id": "720811",
    "label": "Masari",
    "value": "Masari"
  },
  {
    "id": "7208112006",
    "district_id": "720811",
    "label": "Sumbersari",
    "value": "Sumbersari"
  },
  {
    "id": "7208112007",
    "district_id": "720811",
    "label": "Nambaru",
    "value": "Nambaru"
  },
  {
    "id": "7208112008",
    "district_id": "720811",
    "label": "Tindaki",
    "value": "Tindaki"
  },
  {
    "id": "7208112009",
    "district_id": "720811",
    "label": "Gangga",
    "value": "Gangga"
  },
  {
    "id": "7208112010",
    "district_id": "720811",
    "label": "Dolago Padang",
    "value": "Dolago Padang"
  },
  {
    "id": "7208122001",
    "district_id": "720812",
    "label": "Kayu Agung",
    "value": "Kayu Agung"
  },
  {
    "id": "7208122002",
    "district_id": "720812",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "7208122003",
    "district_id": "720812",
    "label": "Kotaraya",
    "value": "Kotaraya"
  },
  {
    "id": "7208122004",
    "district_id": "720812",
    "label": "Mepanga",
    "value": "Mepanga"
  },
  {
    "id": "7208122005",
    "district_id": "720812",
    "label": "Ogotion",
    "value": "Ogotion"
  },
  {
    "id": "7208122006",
    "district_id": "720812",
    "label": "Mensung",
    "value": "Mensung"
  },
  {
    "id": "7208122007",
    "district_id": "720812",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "7208122008",
    "district_id": "720812",
    "label": "Moubang",
    "value": "Moubang"
  },
  {
    "id": "7208122009",
    "district_id": "720812",
    "label": "Ogobayas",
    "value": "Ogobayas"
  },
  {
    "id": "7208122010",
    "district_id": "720812",
    "label": "Kota Raya Timur",
    "value": "Kota Raya Timur"
  },
  {
    "id": "7208122011",
    "district_id": "720812",
    "label": "Malalan",
    "value": "Malalan"
  },
  {
    "id": "7208122012",
    "district_id": "720812",
    "label": "Kota Raya Selatan",
    "value": "Kota Raya Selatan"
  },
  {
    "id": "7208122013",
    "district_id": "720812",
    "label": "Maranti",
    "value": "Maranti"
  },
  {
    "id": "7208122014",
    "district_id": "720812",
    "label": "Ogomolos",
    "value": "Ogomolos"
  },
  {
    "id": "7208122015",
    "district_id": "720812",
    "label": "Kotaraya Barat",
    "value": "Kotaraya Barat"
  },
  {
    "id": "7208122016",
    "district_id": "720812",
    "label": "Bugis Utara",
    "value": "Bugis Utara"
  },
  {
    "id": "7208122017",
    "district_id": "720812",
    "label": "Gurinda",
    "value": "Gurinda"
  },
  {
    "id": "7208122018",
    "district_id": "720812",
    "label": "Kotaraya Tenggara",
    "value": "Kotaraya Tenggara"
  },
  {
    "id": "7208132001",
    "district_id": "720813",
    "label": "Tomoli",
    "value": "Tomoli"
  },
  {
    "id": "7208132002",
    "district_id": "720813",
    "label": "Toribulu",
    "value": "Toribulu"
  },
  {
    "id": "7208132003",
    "district_id": "720813",
    "label": "Sienjo",
    "value": "Sienjo"
  },
  {
    "id": "7208132004",
    "district_id": "720813",
    "label": "Pinotu",
    "value": "Pinotu"
  },
  {
    "id": "7208132005",
    "district_id": "720813",
    "label": "Singura",
    "value": "Singura"
  },
  {
    "id": "7208132006",
    "district_id": "720813",
    "label": "Tomoli Selatan",
    "value": "Tomoli Selatan"
  },
  {
    "id": "7208132007",
    "district_id": "720813",
    "label": "Sibalago",
    "value": "Sibalago"
  },
  {
    "id": "7208132008",
    "district_id": "720813",
    "label": "Toribulu Selatan",
    "value": "Toribulu Selatan"
  },
  {
    "id": "7208132009",
    "district_id": "720813",
    "label": "Tomoli Utara",
    "value": "Tomoli Utara"
  },
  {
    "id": "7208142001",
    "district_id": "720814",
    "label": "Karya Agung",
    "value": "Karya Agung"
  },
  {
    "id": "7208142002",
    "district_id": "720814",
    "label": "Nunurantai",
    "value": "Nunurantai"
  },
  {
    "id": "7208142003",
    "district_id": "720814",
    "label": "Palapi",
    "value": "Palapi"
  },
  {
    "id": "7208142004",
    "district_id": "720814",
    "label": "Taopa",
    "value": "Taopa"
  },
  {
    "id": "7208142005",
    "district_id": "720814",
    "label": "Tuladenggi Sibatang",
    "value": "Tuladenggi Sibatang"
  },
  {
    "id": "7208142006",
    "district_id": "720814",
    "label": "Tompo",
    "value": "Tompo"
  },
  {
    "id": "7208142007",
    "district_id": "720814",
    "label": "Taopa Utara",
    "value": "Taopa Utara"
  },
  {
    "id": "7208142008",
    "district_id": "720814",
    "label": "Bilalea",
    "value": "Bilalea"
  },
  {
    "id": "7208142009",
    "district_id": "720814",
    "label": "Taopa Barat",
    "value": "Taopa Barat"
  },
  {
    "id": "7208142010",
    "district_id": "720814",
    "label": "Paria",
    "value": "Paria"
  },
  {
    "id": "7208142011",
    "district_id": "720814",
    "label": "Karya Abadi",
    "value": "Karya Abadi"
  },
  {
    "id": "7208152001",
    "district_id": "720815",
    "label": "Malakosa",
    "value": "Malakosa"
  },
  {
    "id": "7208152002",
    "district_id": "720815",
    "label": "Balinggi Jati",
    "value": "Balinggi Jati"
  },
  {
    "id": "7208152003",
    "district_id": "720815",
    "label": "Balinggi",
    "value": "Balinggi"
  },
  {
    "id": "7208152004",
    "district_id": "720815",
    "label": "Suli",
    "value": "Suli"
  },
  {
    "id": "7208152005",
    "district_id": "720815",
    "label": "Suli Indah",
    "value": "Suli Indah"
  },
  {
    "id": "7208152006",
    "district_id": "720815",
    "label": "Beraban",
    "value": "Beraban"
  },
  {
    "id": "7208152007",
    "district_id": "720815",
    "label": "Lebagu",
    "value": "Lebagu"
  },
  {
    "id": "7208152008",
    "district_id": "720815",
    "label": "Tumpapa Indah",
    "value": "Tumpapa Indah"
  },
  {
    "id": "7208152009",
    "district_id": "720815",
    "label": "Catur Karya",
    "value": "Catur Karya"
  },
  {
    "id": "7208162001",
    "district_id": "720816",
    "label": "Parigimpu'u",
    "value": "Parigimpu'u"
  },
  {
    "id": "7208162002",
    "district_id": "720816",
    "label": "Baliara",
    "value": "Baliara"
  },
  {
    "id": "7208162003",
    "district_id": "720816",
    "label": "Kayuboko",
    "value": "Kayuboko"
  },
  {
    "id": "7208162004",
    "district_id": "720816",
    "label": "Jono Kalora",
    "value": "Jono Kalora"
  },
  {
    "id": "7208162005",
    "district_id": "720816",
    "label": "Air Panas",
    "value": "Air Panas"
  },
  {
    "id": "7208162006",
    "district_id": "720816",
    "label": "Lobu Mandiri",
    "value": "Lobu Mandiri"
  },
  {
    "id": "7208172001",
    "district_id": "720817",
    "label": "Marantale",
    "value": "Marantale"
  },
  {
    "id": "7208172002",
    "district_id": "720817",
    "label": "Tandaigi",
    "value": "Tandaigi"
  },
  {
    "id": "7208172003",
    "district_id": "720817",
    "label": "Silanga",
    "value": "Silanga"
  },
  {
    "id": "7208172004",
    "district_id": "720817",
    "label": "Siniu",
    "value": "Siniu"
  },
  {
    "id": "7208172005",
    "district_id": "720817",
    "label": "Towera",
    "value": "Towera"
  },
  {
    "id": "7208172006",
    "district_id": "720817",
    "label": "Toraranga",
    "value": "Toraranga"
  },
  {
    "id": "7208172007",
    "district_id": "720817",
    "label": "Uevolo",
    "value": "Uevolo"
  },
  {
    "id": "7208172008",
    "district_id": "720817",
    "label": "Siniu Sayogindano",
    "value": "Siniu Sayogindano"
  },
  {
    "id": "7208172009",
    "district_id": "720817",
    "label": "Silanga Barat",
    "value": "Silanga Barat"
  },
  {
    "id": "7208182001",
    "district_id": "720818",
    "label": "Bobalo",
    "value": "Bobalo"
  },
  {
    "id": "7208182002",
    "district_id": "720818",
    "label": "Dongkalan",
    "value": "Dongkalan"
  },
  {
    "id": "7208182003",
    "district_id": "720818",
    "label": "Eeya",
    "value": "Eeya"
  },
  {
    "id": "7208182004",
    "district_id": "720818",
    "label": "Ulatan",
    "value": "Ulatan"
  },
  {
    "id": "7208182005",
    "district_id": "720818",
    "label": "Palasa Tangki",
    "value": "Palasa Tangki"
  },
  {
    "id": "7208182006",
    "district_id": "720818",
    "label": "Palasa Tengah",
    "value": "Palasa Tengah"
  },
  {
    "id": "7208182007",
    "district_id": "720818",
    "label": "Palasa Lambori",
    "value": "Palasa Lambori"
  },
  {
    "id": "7208182008",
    "district_id": "720818",
    "label": "Pebounang",
    "value": "Pebounang"
  },
  {
    "id": "7208182009",
    "district_id": "720818",
    "label": "Bambasiang",
    "value": "Bambasiang"
  },
  {
    "id": "7208182010",
    "district_id": "720818",
    "label": "Beau",
    "value": "Beau"
  },
  {
    "id": "7208182011",
    "district_id": "720818",
    "label": "Ogoansam",
    "value": "Ogoansam"
  },
  {
    "id": "7208192001",
    "district_id": "720819",
    "label": "Avolua",
    "value": "Avolua"
  },
  {
    "id": "7208192002",
    "district_id": "720819",
    "label": "Toboli",
    "value": "Toboli"
  },
  {
    "id": "7208192003",
    "district_id": "720819",
    "label": "Toboli Barat",
    "value": "Toboli Barat"
  },
  {
    "id": "7208192004",
    "district_id": "720819",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7208192005",
    "district_id": "720819",
    "label": "Sakinah Jaya",
    "value": "Sakinah Jaya"
  },
  {
    "id": "7208202001",
    "district_id": "720820",
    "label": "Pelawa Baru",
    "value": "Pelawa Baru"
  },
  {
    "id": "7208202002",
    "district_id": "720820",
    "label": "Pelawa",
    "value": "Pelawa"
  },
  {
    "id": "7208202003",
    "district_id": "720820",
    "label": "Binangga",
    "value": "Binangga"
  },
  {
    "id": "7208202004",
    "district_id": "720820",
    "label": "Jononunu",
    "value": "Jononunu"
  },
  {
    "id": "7208202005",
    "district_id": "720820",
    "label": "Matolele",
    "value": "Matolele"
  },
  {
    "id": "7208202006",
    "district_id": "720820",
    "label": "Petapa",
    "value": "Petapa"
  },
  {
    "id": "7208212001",
    "district_id": "720821",
    "label": "Bolano",
    "value": "Bolano"
  },
  {
    "id": "7208212002",
    "district_id": "720821",
    "label": "Bolano Tengah",
    "value": "Bolano Tengah"
  },
  {
    "id": "7208212003",
    "district_id": "720821",
    "label": "Bolano Barat",
    "value": "Bolano Barat"
  },
  {
    "id": "7208212004",
    "district_id": "720821",
    "label": "Bolano Utara",
    "value": "Bolano Utara"
  },
  {
    "id": "7208212005",
    "district_id": "720821",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "7208212006",
    "district_id": "720821",
    "label": "Sritabaang",
    "value": "Sritabaang"
  },
  {
    "id": "7208212007",
    "district_id": "720821",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "7208212008",
    "district_id": "720821",
    "label": "Wanamukti",
    "value": "Wanamukti"
  },
  {
    "id": "7208212009",
    "district_id": "720821",
    "label": "Wanamukti Utara",
    "value": "Wanamukti Utara"
  },
  {
    "id": "7208212010",
    "district_id": "720821",
    "label": "Lembah Bomban",
    "value": "Lembah Bomban"
  },
  {
    "id": "7208212011",
    "district_id": "720821",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "7208212012",
    "district_id": "720821",
    "label": "Wanamukti Barat",
    "value": "Wanamukti Barat"
  },
  {
    "id": "7208212013",
    "district_id": "720821",
    "label": "Sama Bahari",
    "value": "Sama Bahari"
  },
  {
    "id": "7208222001",
    "district_id": "720822",
    "label": "Ongka",
    "value": "Ongka"
  },
  {
    "id": "7208222002",
    "district_id": "720822",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7208222003",
    "district_id": "720822",
    "label": "Santigi",
    "value": "Santigi"
  },
  {
    "id": "7208222004",
    "district_id": "720822",
    "label": "Persatuan Sejati",
    "value": "Persatuan Sejati"
  },
  {
    "id": "7208222005",
    "district_id": "720822",
    "label": "Tabolobolo",
    "value": "Tabolobolo"
  },
  {
    "id": "7208222006",
    "district_id": "720822",
    "label": "Tinombala",
    "value": "Tinombala"
  },
  {
    "id": "7208222007",
    "district_id": "720822",
    "label": "Karya Mandiri",
    "value": "Karya Mandiri"
  },
  {
    "id": "7208222008",
    "district_id": "720822",
    "label": "Kayu Jati",
    "value": "Kayu Jati"
  },
  {
    "id": "7208222009",
    "district_id": "720822",
    "label": "Lambanau",
    "value": "Lambanau"
  },
  {
    "id": "7208222010",
    "district_id": "720822",
    "label": "Bosagon Jaya",
    "value": "Bosagon Jaya"
  },
  {
    "id": "7208222011",
    "district_id": "720822",
    "label": "Persatuan Utara",
    "value": "Persatuan Utara"
  },
  {
    "id": "7208222012",
    "district_id": "720822",
    "label": "Tinombala Jaya",
    "value": "Tinombala Jaya"
  },
  {
    "id": "7208222013",
    "district_id": "720822",
    "label": "Ongka Trimuspasari",
    "value": "Ongka Trimuspasari"
  },
  {
    "id": "7208222014",
    "district_id": "720822",
    "label": "Tinombala Sejati",
    "value": "Tinombala Sejati"
  },
  {
    "id": "7208222015",
    "district_id": "720822",
    "label": "Tinombala Barat",
    "value": "Tinombala Barat"
  },
  {
    "id": "7208222016",
    "district_id": "720822",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7208222017",
    "district_id": "720822",
    "label": "Ongka Persatuan",
    "value": "Ongka Persatuan"
  },
  {
    "id": "7208232001",
    "district_id": "720823",
    "label": "Lado",
    "value": "Lado"
  },
  {
    "id": "7208232002",
    "district_id": "720823",
    "label": "Sipayo",
    "value": "Sipayo"
  },
  {
    "id": "7208232003",
    "district_id": "720823",
    "label": "Bondoyong",
    "value": "Bondoyong"
  },
  {
    "id": "7208232004",
    "district_id": "720823",
    "label": "Sidoan Selatan",
    "value": "Sidoan Selatan"
  },
  {
    "id": "7208232005",
    "district_id": "720823",
    "label": "Sidoan Timur",
    "value": "Sidoan Timur"
  },
  {
    "id": "7208232006",
    "district_id": "720823",
    "label": "Sidoan",
    "value": "Sidoan"
  },
  {
    "id": "7208232007",
    "district_id": "720823",
    "label": "Sidoan Barat",
    "value": "Sidoan Barat"
  },
  {
    "id": "7208232008",
    "district_id": "720823",
    "label": "Ogobagis",
    "value": "Ogobagis"
  },
  {
    "id": "7208232009",
    "district_id": "720823",
    "label": "Muara Jaya",
    "value": "Muara Jaya"
  },
  {
    "id": "7208232010",
    "district_id": "720823",
    "label": "Bainaa Selatan",
    "value": "Bainaa Selatan"
  },
  {
    "id": "7208232011",
    "district_id": "720823",
    "label": "Sintuwu Raya",
    "value": "Sintuwu Raya"
  },
  {
    "id": "7209012005",
    "district_id": "720901",
    "label": "Taningkola",
    "value": "Taningkola"
  },
  {
    "id": "7209012006",
    "district_id": "720901",
    "label": "Bambu",
    "value": "Bambu"
  },
  {
    "id": "7209012007",
    "district_id": "720901",
    "label": "Wakai",
    "value": "Wakai"
  },
  {
    "id": "7209012008",
    "district_id": "720901",
    "label": "Una-Una",
    "value": "Una-Una"
  },
  {
    "id": "7209012009",
    "district_id": "720901",
    "label": "Tanjung Pude",
    "value": "Tanjung Pude"
  },
  {
    "id": "7209012010",
    "district_id": "720901",
    "label": "Lembanya",
    "value": "Lembanya"
  },
  {
    "id": "7209012014",
    "district_id": "720901",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "7209012015",
    "district_id": "720901",
    "label": "Binanguna",
    "value": "Binanguna"
  },
  {
    "id": "7209012017",
    "district_id": "720901",
    "label": "Kavetan",
    "value": "Kavetan"
  },
  {
    "id": "7209012018",
    "district_id": "720901",
    "label": "Tanimpo",
    "value": "Tanimpo"
  },
  {
    "id": "7209012019",
    "district_id": "720901",
    "label": "Luangon",
    "value": "Luangon"
  },
  {
    "id": "7209022001",
    "district_id": "720902",
    "label": "Bungayo",
    "value": "Bungayo"
  },
  {
    "id": "7209022002",
    "district_id": "720902",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7209022003",
    "district_id": "720902",
    "label": "Bangkagi",
    "value": "Bangkagi"
  },
  {
    "id": "7209022004",
    "district_id": "720902",
    "label": "Tongkabo",
    "value": "Tongkabo"
  },
  {
    "id": "7209022005",
    "district_id": "720902",
    "label": "Pulau Enam",
    "value": "Pulau Enam"
  },
  {
    "id": "7209022006",
    "district_id": "720902",
    "label": "Katupat",
    "value": "Katupat"
  },
  {
    "id": "7209022007",
    "district_id": "720902",
    "label": "Awo",
    "value": "Awo"
  },
  {
    "id": "7209022008",
    "district_id": "720902",
    "label": "Kololio",
    "value": "Kololio"
  },
  {
    "id": "7209022009",
    "district_id": "720902",
    "label": "Urulepe",
    "value": "Urulepe"
  },
  {
    "id": "7209022010",
    "district_id": "720902",
    "label": "Lembanato",
    "value": "Lembanato"
  },
  {
    "id": "7209022011",
    "district_id": "720902",
    "label": "Lebiti",
    "value": "Lebiti"
  },
  {
    "id": "7209022012",
    "district_id": "720902",
    "label": "Tobil",
    "value": "Tobil"
  },
  {
    "id": "7209022013",
    "district_id": "720902",
    "label": "Baulu",
    "value": "Baulu"
  },
  {
    "id": "7209022014",
    "district_id": "720902",
    "label": "Motobiai",
    "value": "Motobiai"
  },
  {
    "id": "7209022015",
    "district_id": "720902",
    "label": "Sampobae",
    "value": "Sampobae"
  },
  {
    "id": "7209022016",
    "district_id": "720902",
    "label": "Titiri'i",
    "value": "Titiri'i"
  },
  {
    "id": "7209032006",
    "district_id": "720903",
    "label": "Tiga Pulau",
    "value": "Tiga Pulau"
  },
  {
    "id": "7209032007",
    "district_id": "720903",
    "label": "Popolii",
    "value": "Popolii"
  },
  {
    "id": "7209032008",
    "district_id": "720903",
    "label": "Dolong B",
    "value": "Dolong B"
  },
  {
    "id": "7209032009",
    "district_id": "720903",
    "label": "Tutung",
    "value": "Tutung"
  },
  {
    "id": "7209032010",
    "district_id": "720903",
    "label": "Dolong A",
    "value": "Dolong A"
  },
  {
    "id": "7209032016",
    "district_id": "720903",
    "label": "Luok",
    "value": "Luok"
  },
  {
    "id": "7209032018",
    "district_id": "720903",
    "label": "Kolami",
    "value": "Kolami"
  },
  {
    "id": "7209032020",
    "district_id": "720903",
    "label": "Olilan",
    "value": "Olilan"
  },
  {
    "id": "7209032023",
    "district_id": "720903",
    "label": "Loe",
    "value": "Loe"
  },
  {
    "id": "7209042001",
    "district_id": "720904",
    "label": "Pusungi",
    "value": "Pusungi"
  },
  {
    "id": "7209042002",
    "district_id": "720904",
    "label": "Tete B",
    "value": "Tete B"
  },
  {
    "id": "7209042003",
    "district_id": "720904",
    "label": "Tete A",
    "value": "Tete A"
  },
  {
    "id": "7209042004",
    "district_id": "720904",
    "label": "Uebone",
    "value": "Uebone"
  },
  {
    "id": "7209042005",
    "district_id": "720904",
    "label": "Mantangisi",
    "value": "Mantangisi"
  },
  {
    "id": "7209042006",
    "district_id": "720904",
    "label": "Bantuga",
    "value": "Bantuga"
  },
  {
    "id": "7209042007",
    "district_id": "720904",
    "label": "Borone",
    "value": "Borone"
  },
  {
    "id": "7209042008",
    "district_id": "720904",
    "label": "Balanggala",
    "value": "Balanggala"
  },
  {
    "id": "7209042009",
    "district_id": "720904",
    "label": "Sabo",
    "value": "Sabo"
  },
  {
    "id": "7209042010",
    "district_id": "720904",
    "label": "Longge",
    "value": "Longge"
  },
  {
    "id": "7209042011",
    "district_id": "720904",
    "label": "Tampabatu",
    "value": "Tampabatu"
  },
  {
    "id": "7209042012",
    "district_id": "720904",
    "label": "Urundaka",
    "value": "Urundaka"
  },
  {
    "id": "7209042013",
    "district_id": "720904",
    "label": "Balingara",
    "value": "Balingara"
  },
  {
    "id": "7209042014",
    "district_id": "720904",
    "label": "Bulan Jaya",
    "value": "Bulan Jaya"
  },
  {
    "id": "7209042015",
    "district_id": "720904",
    "label": "Giri Mulyo",
    "value": "Giri Mulyo"
  },
  {
    "id": "7209042017",
    "district_id": "720904",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "7209042019",
    "district_id": "720904",
    "label": "Kajulangko",
    "value": "Kajulangko"
  },
  {
    "id": "7209042020",
    "district_id": "720904",
    "label": "Uemakuni",
    "value": "Uemakuni"
  },
  {
    "id": "7209042021",
    "district_id": "720904",
    "label": "Mpoa",
    "value": "Mpoa"
  },
  {
    "id": "7209042022",
    "district_id": "720904",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "7209051002",
    "district_id": "720905",
    "label": "Malotong",
    "value": "Malotong"
  },
  {
    "id": "7209051003",
    "district_id": "720905",
    "label": "Bailo",
    "value": "Bailo"
  },
  {
    "id": "7209051004",
    "district_id": "720905",
    "label": "Ampana",
    "value": "Ampana"
  },
  {
    "id": "7209051012",
    "district_id": "720905",
    "label": "Bone Rato",
    "value": "Bone Rato"
  },
  {
    "id": "7209051013",
    "district_id": "720905",
    "label": "Bailo Baru",
    "value": "Bailo Baru"
  },
  {
    "id": "7209051014",
    "district_id": "720905",
    "label": "Labiabae",
    "value": "Labiabae"
  },
  {
    "id": "7209052001",
    "district_id": "720905",
    "label": "Sansarino",
    "value": "Sansarino"
  },
  {
    "id": "7209052011",
    "district_id": "720905",
    "label": "Padang Tumbuo",
    "value": "Padang Tumbuo"
  },
  {
    "id": "7209052018",
    "district_id": "720905",
    "label": "Saluaba",
    "value": "Saluaba"
  },
  {
    "id": "7209052019",
    "district_id": "720905",
    "label": "Buntongi",
    "value": "Buntongi"
  },
  {
    "id": "7209062001",
    "district_id": "720906",
    "label": "Tampanombo",
    "value": "Tampanombo"
  },
  {
    "id": "7209062002",
    "district_id": "720906",
    "label": "Bonebae II",
    "value": "Bonebae II"
  },
  {
    "id": "7209062003",
    "district_id": "720906",
    "label": "Tobamau",
    "value": "Tobamau"
  },
  {
    "id": "7209062004",
    "district_id": "720906",
    "label": "Rompi",
    "value": "Rompi"
  },
  {
    "id": "7209062005",
    "district_id": "720906",
    "label": "Uematopa",
    "value": "Uematopa"
  },
  {
    "id": "7209062006",
    "district_id": "720906",
    "label": "Uekambuno",
    "value": "Uekambuno"
  },
  {
    "id": "7209062007",
    "district_id": "720906",
    "label": "Paranonge",
    "value": "Paranonge"
  },
  {
    "id": "7209062008",
    "district_id": "720906",
    "label": "Bonebae I",
    "value": "Bonebae I"
  },
  {
    "id": "7209062009",
    "district_id": "720906",
    "label": "Watusongu",
    "value": "Watusongu"
  },
  {
    "id": "7209062010",
    "district_id": "720906",
    "label": "Mire",
    "value": "Mire"
  },
  {
    "id": "7209062011",
    "district_id": "720906",
    "label": "Marowo",
    "value": "Marowo"
  },
  {
    "id": "7209062012",
    "district_id": "720906",
    "label": "Borneang",
    "value": "Borneang"
  },
  {
    "id": "7209062013",
    "district_id": "720906",
    "label": "Bongka Makmur",
    "value": "Bongka Makmur"
  },
  {
    "id": "7209062015",
    "district_id": "720906",
    "label": "Cempa",
    "value": "Cempa"
  },
  {
    "id": "7209062016",
    "district_id": "720906",
    "label": "Bonevoto",
    "value": "Bonevoto"
  },
  {
    "id": "7209062017",
    "district_id": "720906",
    "label": "Bongkakoy",
    "value": "Bongkakoy"
  },
  {
    "id": "7209062018",
    "district_id": "720906",
    "label": "Takibangke",
    "value": "Takibangke"
  },
  {
    "id": "7209062019",
    "district_id": "720906",
    "label": "Kasiala",
    "value": "Kasiala"
  },
  {
    "id": "7209072001",
    "district_id": "720907",
    "label": "Malei Tojo",
    "value": "Malei Tojo"
  },
  {
    "id": "7209072002",
    "district_id": "720907",
    "label": "Matako",
    "value": "Matako"
  },
  {
    "id": "7209072003",
    "district_id": "720907",
    "label": "Bambalo",
    "value": "Bambalo"
  },
  {
    "id": "7209072004",
    "district_id": "720907",
    "label": "Galuga",
    "value": "Galuga"
  },
  {
    "id": "7209072005",
    "district_id": "720907",
    "label": "Toliba",
    "value": "Toliba"
  },
  {
    "id": "7209072006",
    "district_id": "720907",
    "label": "Ujung Tibu",
    "value": "Ujung Tibu"
  },
  {
    "id": "7209072007",
    "district_id": "720907",
    "label": "Nggawia",
    "value": "Nggawia"
  },
  {
    "id": "7209072008",
    "district_id": "720907",
    "label": "Tombiano",
    "value": "Tombiano"
  },
  {
    "id": "7209072009",
    "district_id": "720907",
    "label": "Tatari",
    "value": "Tatari"
  },
  {
    "id": "7209072010",
    "district_id": "720907",
    "label": "Kabalo",
    "value": "Kabalo"
  },
  {
    "id": "7209072011",
    "district_id": "720907",
    "label": "Tanamawau",
    "value": "Tanamawau"
  },
  {
    "id": "7209072012",
    "district_id": "720907",
    "label": "Malewa",
    "value": "Malewa"
  },
  {
    "id": "7209072013",
    "district_id": "720907",
    "label": "Mawomba",
    "value": "Mawomba"
  },
  {
    "id": "7209082001",
    "district_id": "720908",
    "label": "Lemoro",
    "value": "Lemoro"
  },
  {
    "id": "7209082002",
    "district_id": "720908",
    "label": "Korondoda",
    "value": "Korondoda"
  },
  {
    "id": "7209082003",
    "district_id": "720908",
    "label": "Tayawa",
    "value": "Tayawa"
  },
  {
    "id": "7209082004",
    "district_id": "720908",
    "label": "Uekuli",
    "value": "Uekuli"
  },
  {
    "id": "7209082005",
    "district_id": "720908",
    "label": "Betaua",
    "value": "Betaua"
  },
  {
    "id": "7209082006",
    "district_id": "720908",
    "label": "Uedele",
    "value": "Uedele"
  },
  {
    "id": "7209082007",
    "district_id": "720908",
    "label": "Sandada",
    "value": "Sandada"
  },
  {
    "id": "7209082008",
    "district_id": "720908",
    "label": "Tojo",
    "value": "Tojo"
  },
  {
    "id": "7209082009",
    "district_id": "720908",
    "label": "Pancuma",
    "value": "Pancuma"
  },
  {
    "id": "7209082010",
    "district_id": "720908",
    "label": "Tongku",
    "value": "Tongku"
  },
  {
    "id": "7209082011",
    "district_id": "720908",
    "label": "Podi",
    "value": "Podi"
  },
  {
    "id": "7209082012",
    "district_id": "720908",
    "label": "Dataran Bugi",
    "value": "Dataran Bugi"
  },
  {
    "id": "7209082015",
    "district_id": "720908",
    "label": "Banano",
    "value": "Banano"
  },
  {
    "id": "7209082016",
    "district_id": "720908",
    "label": "Bahari",
    "value": "Bahari"
  },
  {
    "id": "7209082017",
    "district_id": "720908",
    "label": "Buyuntaripa",
    "value": "Buyuntaripa"
  },
  {
    "id": "7209082018",
    "district_id": "720908",
    "label": "Kalemba",
    "value": "Kalemba"
  },
  {
    "id": "7209092001",
    "district_id": "720909",
    "label": "Biga",
    "value": "Biga"
  },
  {
    "id": "7209092002",
    "district_id": "720909",
    "label": "Salinggoha",
    "value": "Salinggoha"
  },
  {
    "id": "7209092003",
    "district_id": "720909",
    "label": "Tingki",
    "value": "Tingki"
  },
  {
    "id": "7209092004",
    "district_id": "720909",
    "label": "Malapo",
    "value": "Malapo"
  },
  {
    "id": "7209092005",
    "district_id": "720909",
    "label": "Pasokan",
    "value": "Pasokan"
  },
  {
    "id": "7209092006",
    "district_id": "720909",
    "label": "Kondongan",
    "value": "Kondongan"
  },
  {
    "id": "7209092007",
    "district_id": "720909",
    "label": "Katogop",
    "value": "Katogop"
  },
  {
    "id": "7209092008",
    "district_id": "720909",
    "label": "Tongidon",
    "value": "Tongidon"
  },
  {
    "id": "7209101001",
    "district_id": "720910",
    "label": "Uentanaga Bawah",
    "value": "Uentanaga Bawah"
  },
  {
    "id": "7209101002",
    "district_id": "720910",
    "label": "Uentanaga Atas",
    "value": "Uentanaga Atas"
  },
  {
    "id": "7209101003",
    "district_id": "720910",
    "label": "Dondo",
    "value": "Dondo"
  },
  {
    "id": "7209101007",
    "district_id": "720910",
    "label": "Uamalingku",
    "value": "Uamalingku"
  },
  {
    "id": "7209101008",
    "district_id": "720910",
    "label": "Dondo Barat",
    "value": "Dondo Barat"
  },
  {
    "id": "7209101009",
    "district_id": "720910",
    "label": "Muara Toba",
    "value": "Muara Toba"
  },
  {
    "id": "7209102004",
    "district_id": "720910",
    "label": "Sumoli",
    "value": "Sumoli"
  },
  {
    "id": "7209102005",
    "district_id": "720910",
    "label": "Sabulira Toba",
    "value": "Sabulira Toba"
  },
  {
    "id": "7209102006",
    "district_id": "720910",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "7209102010",
    "district_id": "720910",
    "label": "Patingko",
    "value": "Patingko"
  },
  {
    "id": "7209112001",
    "district_id": "720911",
    "label": "Bomba",
    "value": "Bomba"
  },
  {
    "id": "7209112002",
    "district_id": "720911",
    "label": "Molowagu",
    "value": "Molowagu"
  },
  {
    "id": "7209112003",
    "district_id": "720911",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7209112004",
    "district_id": "720911",
    "label": "Kulingkinari",
    "value": "Kulingkinari"
  },
  {
    "id": "7209112005",
    "district_id": "720911",
    "label": "Tumbulawa",
    "value": "Tumbulawa"
  },
  {
    "id": "7209112006",
    "district_id": "720911",
    "label": "Siatu",
    "value": "Siatu"
  },
  {
    "id": "7209112007",
    "district_id": "720911",
    "label": "Kambutu",
    "value": "Kambutu"
  },
  {
    "id": "7209112008",
    "district_id": "720911",
    "label": "Lindo",
    "value": "Lindo"
  },
  {
    "id": "7209112009",
    "district_id": "720911",
    "label": "Patoyan",
    "value": "Patoyan"
  },
  {
    "id": "7209122001",
    "district_id": "720912",
    "label": "Kalia",
    "value": "Kalia"
  },
  {
    "id": "7209122002",
    "district_id": "720912",
    "label": "Malenge",
    "value": "Malenge"
  },
  {
    "id": "7209122003",
    "district_id": "720912",
    "label": "Pautu",
    "value": "Pautu"
  },
  {
    "id": "7209122004",
    "district_id": "720912",
    "label": "Tumotok",
    "value": "Tumotok"
  },
  {
    "id": "7209122005",
    "district_id": "720912",
    "label": "Kabalutan",
    "value": "Kabalutan"
  },
  {
    "id": "7209122006",
    "district_id": "720912",
    "label": "Kadoda",
    "value": "Kadoda"
  },
  {
    "id": "7210012001",
    "district_id": "721001",
    "label": "Sidondo II",
    "value": "Sidondo II"
  },
  {
    "id": "7210012002",
    "district_id": "721001",
    "label": "Sidondo I",
    "value": "Sidondo I"
  },
  {
    "id": "7210012003",
    "district_id": "721001",
    "label": "Bora",
    "value": "Bora"
  },
  {
    "id": "7210012004",
    "district_id": "721001",
    "label": "Watunonju",
    "value": "Watunonju"
  },
  {
    "id": "7210012005",
    "district_id": "721001",
    "label": "Olobuju",
    "value": "Olobuju"
  },
  {
    "id": "7210012006",
    "district_id": "721001",
    "label": "Soulowe",
    "value": "Soulowe"
  },
  {
    "id": "7210012007",
    "district_id": "721001",
    "label": "Sidera",
    "value": "Sidera"
  },
  {
    "id": "7210012008",
    "district_id": "721001",
    "label": "Jono Oge",
    "value": "Jono Oge"
  },
  {
    "id": "7210012009",
    "district_id": "721001",
    "label": "Kalukubula",
    "value": "Kalukubula"
  },
  {
    "id": "7210012010",
    "district_id": "721001",
    "label": "Lolu",
    "value": "Lolu"
  },
  {
    "id": "7210012011",
    "district_id": "721001",
    "label": "Pombewe",
    "value": "Pombewe"
  },
  {
    "id": "7210012012",
    "district_id": "721001",
    "label": "Loru",
    "value": "Loru"
  },
  {
    "id": "7210012013",
    "district_id": "721001",
    "label": "Mpanau",
    "value": "Mpanau"
  },
  {
    "id": "7210012014",
    "district_id": "721001",
    "label": "Ngatabaru",
    "value": "Ngatabaru"
  },
  {
    "id": "7210012015",
    "district_id": "721001",
    "label": "Maranatha",
    "value": "Maranatha"
  },
  {
    "id": "7210012016",
    "district_id": "721001",
    "label": "Sidondo III",
    "value": "Sidondo III"
  },
  {
    "id": "7210012017",
    "district_id": "721001",
    "label": "Sidondo IV",
    "value": "Sidondo IV"
  },
  {
    "id": "7210022001",
    "district_id": "721002",
    "label": "Bobo",
    "value": "Bobo"
  },
  {
    "id": "7210022002",
    "district_id": "721002",
    "label": "Bunga",
    "value": "Bunga"
  },
  {
    "id": "7210022003",
    "district_id": "721002",
    "label": "Kapirowe",
    "value": "Kapirowe"
  },
  {
    "id": "7210022004",
    "district_id": "721002",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "7210022005",
    "district_id": "721002",
    "label": "Berdikari",
    "value": "Berdikari"
  },
  {
    "id": "7210022006",
    "district_id": "721002",
    "label": "Patimbe",
    "value": "Patimbe"
  },
  {
    "id": "7210022007",
    "district_id": "721002",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "7210022008",
    "district_id": "721002",
    "label": "Rejeki",
    "value": "Rejeki"
  },
  {
    "id": "7210022009",
    "district_id": "721002",
    "label": "Sintuwu",
    "value": "Sintuwu"
  },
  {
    "id": "7210022010",
    "district_id": "721002",
    "label": "Sigimpu",
    "value": "Sigimpu"
  },
  {
    "id": "7210022011",
    "district_id": "721002",
    "label": "Bakubakulu",
    "value": "Bakubakulu"
  },
  {
    "id": "7210022012",
    "district_id": "721002",
    "label": "Bahagia",
    "value": "Bahagia"
  },
  {
    "id": "7210022013",
    "district_id": "721002",
    "label": "Rahmat",
    "value": "Rahmat"
  },
  {
    "id": "7210022014",
    "district_id": "721002",
    "label": "Tanah Harapan",
    "value": "Tanah Harapan"
  },
  {
    "id": "7210022015",
    "district_id": "721002",
    "label": "Ranteleda",
    "value": "Ranteleda"
  },
  {
    "id": "7210022016",
    "district_id": "721002",
    "label": "Tongoa",
    "value": "Tongoa"
  },
  {
    "id": "7210022017",
    "district_id": "721002",
    "label": "Lembantongoa",
    "value": "Lembantongoa"
  },
  {
    "id": "7210022018",
    "district_id": "721002",
    "label": "Uwe Nuni",
    "value": "Uwe Nuni"
  },
  {
    "id": "7210022019",
    "district_id": "721002",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "7210022020",
    "district_id": "721002",
    "label": "Ue rani",
    "value": "Ue rani"
  },
  {
    "id": "7210022021",
    "district_id": "721002",
    "label": "Karunia",
    "value": "Karunia"
  },
  {
    "id": "7210022022",
    "district_id": "721002",
    "label": "Sarumana",
    "value": "Sarumana"
  },
  {
    "id": "7210032001",
    "district_id": "721003",
    "label": "Kamarora A",
    "value": "Kamarora A"
  },
  {
    "id": "7210032002",
    "district_id": "721003",
    "label": "Kamarora B",
    "value": "Kamarora B"
  },
  {
    "id": "7210032003",
    "district_id": "721003",
    "label": "Kadidia",
    "value": "Kadidia"
  },
  {
    "id": "7210032004",
    "district_id": "721003",
    "label": "Sopu",
    "value": "Sopu"
  },
  {
    "id": "7210032005",
    "district_id": "721003",
    "label": "Bulili",
    "value": "Bulili"
  },
  {
    "id": "7210042001",
    "district_id": "721004",
    "label": "Puroo",
    "value": "Puroo"
  },
  {
    "id": "7210042002",
    "district_id": "721004",
    "label": "Langko",
    "value": "Langko"
  },
  {
    "id": "7210042003",
    "district_id": "721004",
    "label": "Tomado",
    "value": "Tomado"
  },
  {
    "id": "7210042004",
    "district_id": "721004",
    "label": "Anca",
    "value": "Anca"
  },
  {
    "id": "7210042005",
    "district_id": "721004",
    "label": "Olu",
    "value": "Olu"
  },
  {
    "id": "7210052001",
    "district_id": "721005",
    "label": "Siwongi",
    "value": "Siwongi"
  },
  {
    "id": "7210052002",
    "district_id": "721005",
    "label": "Banggaiba",
    "value": "Banggaiba"
  },
  {
    "id": "7210052003",
    "district_id": "721005",
    "label": "Tangkulowi",
    "value": "Tangkulowi"
  },
  {
    "id": "7210052004",
    "district_id": "721005",
    "label": "Boladangko",
    "value": "Boladangko"
  },
  {
    "id": "7210052005",
    "district_id": "721005",
    "label": "Lonca",
    "value": "Lonca"
  },
  {
    "id": "7210052006",
    "district_id": "721005",
    "label": "Rantewulu",
    "value": "Rantewulu"
  },
  {
    "id": "7210052007",
    "district_id": "721005",
    "label": "Towulu",
    "value": "Towulu"
  },
  {
    "id": "7210052008",
    "district_id": "721005",
    "label": "Winatu",
    "value": "Winatu"
  },
  {
    "id": "7210052009",
    "district_id": "721005",
    "label": "Sungku",
    "value": "Sungku"
  },
  {
    "id": "7210052010",
    "district_id": "721005",
    "label": "Bolapapu",
    "value": "Bolapapu"
  },
  {
    "id": "7210052011",
    "district_id": "721005",
    "label": "Mataue",
    "value": "Mataue"
  },
  {
    "id": "7210052012",
    "district_id": "721005",
    "label": "Toro",
    "value": "Toro"
  },
  {
    "id": "7210052013",
    "district_id": "721005",
    "label": "Salua",
    "value": "Salua"
  },
  {
    "id": "7210052014",
    "district_id": "721005",
    "label": "Namo",
    "value": "Namo"
  },
  {
    "id": "7210052015",
    "district_id": "721005",
    "label": "Marena",
    "value": "Marena"
  },
  {
    "id": "7210052016",
    "district_id": "721005",
    "label": "Poleroa Makuhi",
    "value": "Poleroa Makuhi"
  },
  {
    "id": "7210062001",
    "district_id": "721006",
    "label": "Lempelero",
    "value": "Lempelero"
  },
  {
    "id": "7210062002",
    "district_id": "721006",
    "label": "Gimpu",
    "value": "Gimpu"
  },
  {
    "id": "7210062003",
    "district_id": "721006",
    "label": "Tomua",
    "value": "Tomua"
  },
  {
    "id": "7210062004",
    "district_id": "721006",
    "label": "Tompi Bugis",
    "value": "Tompi Bugis"
  },
  {
    "id": "7210062005",
    "district_id": "721006",
    "label": "Lawua",
    "value": "Lawua"
  },
  {
    "id": "7210062006",
    "district_id": "721006",
    "label": "Watukilo",
    "value": "Watukilo"
  },
  {
    "id": "7210062007",
    "district_id": "721006",
    "label": "Salutome",
    "value": "Salutome"
  },
  {
    "id": "7210062008",
    "district_id": "721006",
    "label": "O'o Parese",
    "value": "O'o Parese"
  },
  {
    "id": "7210062009",
    "district_id": "721006",
    "label": "Pilimakujawa",
    "value": "Pilimakujawa"
  },
  {
    "id": "7210062010",
    "district_id": "721006",
    "label": "Moa",
    "value": "Moa"
  },
  {
    "id": "7210062011",
    "district_id": "721006",
    "label": "Wangka",
    "value": "Wangka"
  },
  {
    "id": "7210062012",
    "district_id": "721006",
    "label": "Palamaki",
    "value": "Palamaki"
  },
  {
    "id": "7210072001",
    "district_id": "721007",
    "label": "Koja",
    "value": "Koja"
  },
  {
    "id": "7210072002",
    "district_id": "721007",
    "label": "Kalamanta",
    "value": "Kalamanta"
  },
  {
    "id": "7210072003",
    "district_id": "721007",
    "label": "Mamu",
    "value": "Mamu"
  },
  {
    "id": "7210072004",
    "district_id": "721007",
    "label": "Peana",
    "value": "Peana"
  },
  {
    "id": "7210072005",
    "district_id": "721007",
    "label": "Kantewu",
    "value": "Kantewu"
  },
  {
    "id": "7210072006",
    "district_id": "721007",
    "label": "Onu",
    "value": "Onu"
  },
  {
    "id": "7210072007",
    "district_id": "721007",
    "label": "Mapahi",
    "value": "Mapahi"
  },
  {
    "id": "7210072008",
    "district_id": "721007",
    "label": "Banasu",
    "value": "Banasu"
  },
  {
    "id": "7210072009",
    "district_id": "721007",
    "label": "Lonebasa",
    "value": "Lonebasa"
  },
  {
    "id": "7210072010",
    "district_id": "721007",
    "label": "Lawe",
    "value": "Lawe"
  },
  {
    "id": "7210072011",
    "district_id": "721007",
    "label": "Morui",
    "value": "Morui"
  },
  {
    "id": "7210072012",
    "district_id": "721007",
    "label": "Kantewu II",
    "value": "Kantewu II"
  },
  {
    "id": "7210072013",
    "district_id": "721007",
    "label": "Porelea",
    "value": "Porelea"
  },
  {
    "id": "7210072014",
    "district_id": "721007",
    "label": "Tuwo Tanijaya",
    "value": "Tuwo Tanijaya"
  },
  {
    "id": "7210072015",
    "district_id": "721007",
    "label": "Pelempea",
    "value": "Pelempea"
  },
  {
    "id": "7210072016",
    "district_id": "721007",
    "label": "Morui II",
    "value": "Morui II"
  },
  {
    "id": "7210072017",
    "district_id": "721007",
    "label": "Poluroa",
    "value": "Poluroa"
  },
  {
    "id": "7210072018",
    "district_id": "721007",
    "label": "Masewo",
    "value": "Masewo"
  },
  {
    "id": "7210072019",
    "district_id": "721007",
    "label": "Porelea II",
    "value": "Porelea II"
  },
  {
    "id": "7210082001",
    "district_id": "721008",
    "label": "Tuwa",
    "value": "Tuwa"
  },
  {
    "id": "7210082002",
    "district_id": "721008",
    "label": "Omu",
    "value": "Omu"
  },
  {
    "id": "7210082003",
    "district_id": "721008",
    "label": "Simoro",
    "value": "Simoro"
  },
  {
    "id": "7210082004",
    "district_id": "721008",
    "label": "Pakuli",
    "value": "Pakuli"
  },
  {
    "id": "7210082005",
    "district_id": "721008",
    "label": "Pandere",
    "value": "Pandere"
  },
  {
    "id": "7210082006",
    "district_id": "721008",
    "label": "Kalawara",
    "value": "Kalawara"
  },
  {
    "id": "7210082007",
    "district_id": "721008",
    "label": "Pakuli Utara",
    "value": "Pakuli Utara"
  },
  {
    "id": "7210092001",
    "district_id": "721009",
    "label": "Bangga",
    "value": "Bangga"
  },
  {
    "id": "7210092002",
    "district_id": "721009",
    "label": "Walatana",
    "value": "Walatana"
  },
  {
    "id": "7210092003",
    "district_id": "721009",
    "label": "Bulubete",
    "value": "Bulubete"
  },
  {
    "id": "7210092004",
    "district_id": "721009",
    "label": "Baluase",
    "value": "Baluase"
  },
  {
    "id": "7210092005",
    "district_id": "721009",
    "label": "Rogo",
    "value": "Rogo"
  },
  {
    "id": "7210092006",
    "district_id": "721009",
    "label": "Pulu",
    "value": "Pulu"
  },
  {
    "id": "7210092007",
    "district_id": "721009",
    "label": "Balongga",
    "value": "Balongga"
  },
  {
    "id": "7210092008",
    "district_id": "721009",
    "label": "Sambo",
    "value": "Sambo"
  },
  {
    "id": "7210092009",
    "district_id": "721009",
    "label": "Wisolo",
    "value": "Wisolo"
  },
  {
    "id": "7210092010",
    "district_id": "721009",
    "label": "Jono",
    "value": "Jono"
  },
  {
    "id": "7210092011",
    "district_id": "721009",
    "label": "Poi",
    "value": "Poi"
  },
  {
    "id": "7210092012",
    "district_id": "721009",
    "label": "Ramba",
    "value": "Ramba"
  },
  {
    "id": "7210102001",
    "district_id": "721010",
    "label": "Lambara",
    "value": "Lambara"
  },
  {
    "id": "7210102002",
    "district_id": "721010",
    "label": "Sibalaya Selatan",
    "value": "Sibalaya Selatan"
  },
  {
    "id": "7210102003",
    "district_id": "721010",
    "label": "Sibalaya Utara",
    "value": "Sibalaya Utara"
  },
  {
    "id": "7210102004",
    "district_id": "721010",
    "label": "Sibowi",
    "value": "Sibowi"
  },
  {
    "id": "7210102005",
    "district_id": "721010",
    "label": "Sibalaya Barat",
    "value": "Sibalaya Barat"
  },
  {
    "id": "7210112001",
    "district_id": "721011",
    "label": "Sibonu",
    "value": "Sibonu"
  },
  {
    "id": "7210112002",
    "district_id": "721011",
    "label": "Kaluku Tinggu",
    "value": "Kaluku Tinggu"
  },
  {
    "id": "7210112003",
    "district_id": "721011",
    "label": "Pewunu",
    "value": "Pewunu"
  },
  {
    "id": "7210112004",
    "district_id": "721011",
    "label": "Kaleke",
    "value": "Kaleke"
  },
  {
    "id": "7210112005",
    "district_id": "721011",
    "label": "Rarampadende",
    "value": "Rarampadende"
  },
  {
    "id": "7210112006",
    "district_id": "721011",
    "label": "Balumpewa",
    "value": "Balumpewa"
  },
  {
    "id": "7210112007",
    "district_id": "721011",
    "label": "Balamoa",
    "value": "Balamoa"
  },
  {
    "id": "7210112008",
    "district_id": "721011",
    "label": "Pesaku",
    "value": "Pesaku"
  },
  {
    "id": "7210112009",
    "district_id": "721011",
    "label": "Mantikole",
    "value": "Mantikole"
  },
  {
    "id": "7210112010",
    "district_id": "721011",
    "label": "Bobo",
    "value": "Bobo"
  },
  {
    "id": "7210112011",
    "district_id": "721011",
    "label": "Balaroa Pewenu",
    "value": "Balaroa Pewenu"
  },
  {
    "id": "7210112012",
    "district_id": "721011",
    "label": "Luku",
    "value": "Luku"
  },
  {
    "id": "7210122001",
    "district_id": "721012",
    "label": "Tulo",
    "value": "Tulo"
  },
  {
    "id": "7210122002",
    "district_id": "721012",
    "label": "Langaleso",
    "value": "Langaleso"
  },
  {
    "id": "7210122003",
    "district_id": "721012",
    "label": "Watubula",
    "value": "Watubula"
  },
  {
    "id": "7210122004",
    "district_id": "721012",
    "label": "Soulowe",
    "value": "Soulowe"
  },
  {
    "id": "7210122005",
    "district_id": "721012",
    "label": "Karawana",
    "value": "Karawana"
  },
  {
    "id": "7210122006",
    "district_id": "721012",
    "label": "Kotapulu",
    "value": "Kotapulu"
  },
  {
    "id": "7210122007",
    "district_id": "721012",
    "label": "Kotarindau",
    "value": "Kotarindau"
  },
  {
    "id": "7210122008",
    "district_id": "721012",
    "label": "Panturabate",
    "value": "Panturabate"
  },
  {
    "id": "7210122009",
    "district_id": "721012",
    "label": "Maku",
    "value": "Maku"
  },
  {
    "id": "7210122010",
    "district_id": "721012",
    "label": "Potoya",
    "value": "Potoya"
  },
  {
    "id": "7210122011",
    "district_id": "721012",
    "label": "Kabobona",
    "value": "Kabobona"
  },
  {
    "id": "7210132001",
    "district_id": "721013",
    "label": "Porame",
    "value": "Porame"
  },
  {
    "id": "7210132002",
    "district_id": "721013",
    "label": "Balane",
    "value": "Balane"
  },
  {
    "id": "7210132003",
    "district_id": "721013",
    "label": "Uwemanje",
    "value": "Uwemanje"
  },
  {
    "id": "7210132004",
    "district_id": "721013",
    "label": "Rondingo",
    "value": "Rondingo"
  },
  {
    "id": "7210132005",
    "district_id": "721013",
    "label": "Bolobia",
    "value": "Bolobia"
  },
  {
    "id": "7210132006",
    "district_id": "721013",
    "label": "Doda",
    "value": "Doda"
  },
  {
    "id": "7210132007",
    "district_id": "721013",
    "label": "Kalora",
    "value": "Kalora"
  },
  {
    "id": "7210132008",
    "district_id": "721013",
    "label": "Kanuna",
    "value": "Kanuna"
  },
  {
    "id": "7210132009",
    "district_id": "721013",
    "label": "Daenggune",
    "value": "Daenggune"
  },
  {
    "id": "7210132010",
    "district_id": "721013",
    "label": "Kayumpia",
    "value": "Kayumpia"
  },
  {
    "id": "7210142001",
    "district_id": "721014",
    "label": "Lebanu",
    "value": "Lebanu"
  },
  {
    "id": "7210142002",
    "district_id": "721014",
    "label": "Bomba",
    "value": "Bomba"
  },
  {
    "id": "7210142003",
    "district_id": "721014",
    "label": "Beka",
    "value": "Beka"
  },
  {
    "id": "7210142004",
    "district_id": "721014",
    "label": "Sibedi",
    "value": "Sibedi"
  },
  {
    "id": "7210142005",
    "district_id": "721014",
    "label": "Padende",
    "value": "Padende"
  },
  {
    "id": "7210142006",
    "district_id": "721014",
    "label": "Sunju",
    "value": "Sunju"
  },
  {
    "id": "7210142007",
    "district_id": "721014",
    "label": "Tinggede",
    "value": "Tinggede"
  },
  {
    "id": "7210142008",
    "district_id": "721014",
    "label": "Baliase",
    "value": "Baliase"
  },
  {
    "id": "7210142009",
    "district_id": "721014",
    "label": "Binangga",
    "value": "Binangga"
  },
  {
    "id": "7210142010",
    "district_id": "721014",
    "label": "Boyabaliase",
    "value": "Boyabaliase"
  },
  {
    "id": "7210142011",
    "district_id": "721014",
    "label": "Tinggede Selatan",
    "value": "Tinggede Selatan"
  },
  {
    "id": "7210152001",
    "district_id": "721015",
    "label": "Wayu",
    "value": "Wayu"
  },
  {
    "id": "7210152002",
    "district_id": "721015",
    "label": "Taipanggabe",
    "value": "Taipanggabe"
  },
  {
    "id": "7210152003",
    "district_id": "721015",
    "label": "Matantimali",
    "value": "Matantimali"
  },
  {
    "id": "7210152004",
    "district_id": "721015",
    "label": "Lewara",
    "value": "Lewara"
  },
  {
    "id": "7210152005",
    "district_id": "721015",
    "label": "Dombu",
    "value": "Dombu"
  },
  {
    "id": "7210152006",
    "district_id": "721015",
    "label": "Soi",
    "value": "Soi"
  },
  {
    "id": "7210152007",
    "district_id": "721015",
    "label": "Wugaga",
    "value": "Wugaga"
  },
  {
    "id": "7210152008",
    "district_id": "721015",
    "label": "Panasibaja",
    "value": "Panasibaja"
  },
  {
    "id": "7210152009",
    "district_id": "721015",
    "label": "Ongulero",
    "value": "Ongulero"
  },
  {
    "id": "7210152010",
    "district_id": "721015",
    "label": "Wiapore",
    "value": "Wiapore"
  },
  {
    "id": "7210152011",
    "district_id": "721015",
    "label": "Wawujai",
    "value": "Wawujai"
  },
  {
    "id": "7210152012",
    "district_id": "721015",
    "label": "Lemosiranindi",
    "value": "Lemosiranindi"
  },
  {
    "id": "7211011005",
    "district_id": "721101",
    "label": "Lompio",
    "value": "Lompio"
  },
  {
    "id": "7211011006",
    "district_id": "721101",
    "label": "Tano Bununungan",
    "value": "Tano Bununungan"
  },
  {
    "id": "7211011007",
    "district_id": "721101",
    "label": "Dodung",
    "value": "Dodung"
  },
  {
    "id": "7211012001",
    "district_id": "721101",
    "label": "Lambako",
    "value": "Lambako"
  },
  {
    "id": "7211012002",
    "district_id": "721101",
    "label": "Kokini",
    "value": "Kokini"
  },
  {
    "id": "7211012003",
    "district_id": "721101",
    "label": "Lampa",
    "value": "Lampa"
  },
  {
    "id": "7211012004",
    "district_id": "721101",
    "label": "Tinakin Laut",
    "value": "Tinakin Laut"
  },
  {
    "id": "7211012008",
    "district_id": "721101",
    "label": "Potil Pololoba",
    "value": "Potil Pololoba"
  },
  {
    "id": "7211012009",
    "district_id": "721101",
    "label": "Dangkalan",
    "value": "Dangkalan"
  },
  {
    "id": "7211012010",
    "district_id": "721101",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7211022001",
    "district_id": "721102",
    "label": "Kendek",
    "value": "Kendek"
  },
  {
    "id": "7211022002",
    "district_id": "721102",
    "label": "Lokotoy",
    "value": "Lokotoy"
  },
  {
    "id": "7211022003",
    "district_id": "721102",
    "label": "Popisi",
    "value": "Popisi"
  },
  {
    "id": "7211022004",
    "district_id": "721102",
    "label": "Paisumosoni",
    "value": "Paisumosoni"
  },
  {
    "id": "7211022005",
    "district_id": "721102",
    "label": "Tolisetubono",
    "value": "Tolisetubono"
  },
  {
    "id": "7211022006",
    "district_id": "721102",
    "label": "Bone Baru",
    "value": "Bone Baru"
  },
  {
    "id": "7211032001",
    "district_id": "721103",
    "label": "Toropot",
    "value": "Toropot"
  },
  {
    "id": "7211032002",
    "district_id": "721103",
    "label": "Kokudang",
    "value": "Kokudang"
  },
  {
    "id": "7211032003",
    "district_id": "721103",
    "label": "Kaukes",
    "value": "Kaukes"
  },
  {
    "id": "7211032004",
    "district_id": "721103",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "7211032005",
    "district_id": "721103",
    "label": "Panapat",
    "value": "Panapat"
  },
  {
    "id": "7211032006",
    "district_id": "721103",
    "label": "Ndindibung",
    "value": "Ndindibung"
  },
  {
    "id": "7211032007",
    "district_id": "721103",
    "label": "Nggasuang",
    "value": "Nggasuang"
  },
  {
    "id": "7211032008",
    "district_id": "721103",
    "label": "Mbuang-Mbuang",
    "value": "Mbuang-Mbuang"
  },
  {
    "id": "7211032009",
    "district_id": "721103",
    "label": "Timpaus",
    "value": "Timpaus"
  },
  {
    "id": "7211032010",
    "district_id": "721103",
    "label": "Kasuari",
    "value": "Kasuari"
  },
  {
    "id": "7211032011",
    "district_id": "721103",
    "label": "Sonit",
    "value": "Sonit"
  },
  {
    "id": "7211032012",
    "district_id": "721103",
    "label": "Paisubebe",
    "value": "Paisubebe"
  },
  {
    "id": "7211032013",
    "district_id": "721103",
    "label": "Mandel",
    "value": "Mandel"
  },
  {
    "id": "7211032014",
    "district_id": "721103",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7211032015",
    "district_id": "721103",
    "label": "Bolokut",
    "value": "Bolokut"
  },
  {
    "id": "7211032016",
    "district_id": "721103",
    "label": "Keak",
    "value": "Keak"
  },
  {
    "id": "7211042001",
    "district_id": "721104",
    "label": "Togong Sagu",
    "value": "Togong Sagu"
  },
  {
    "id": "7211042002",
    "district_id": "721104",
    "label": "Taduno",
    "value": "Taduno"
  },
  {
    "id": "7211042003",
    "district_id": "721104",
    "label": "Kanari",
    "value": "Kanari"
  },
  {
    "id": "7211042004",
    "district_id": "721104",
    "label": "Lantibung",
    "value": "Lantibung"
  },
  {
    "id": "7211042005",
    "district_id": "721104",
    "label": "Kalupapi",
    "value": "Kalupapi"
  },
  {
    "id": "7211042006",
    "district_id": "721104",
    "label": "Bone-Bone",
    "value": "Bone-Bone"
  },
  {
    "id": "7211042007",
    "district_id": "721104",
    "label": "Mbeleang",
    "value": "Mbeleang"
  },
  {
    "id": "7211042008",
    "district_id": "721104",
    "label": "Tabulang",
    "value": "Tabulang"
  },
  {
    "id": "7211042009",
    "district_id": "721104",
    "label": "Sasabobok",
    "value": "Sasabobok"
  },
  {
    "id": "7211042010",
    "district_id": "721104",
    "label": "Bungin Lawean",
    "value": "Bungin Lawean"
  },
  {
    "id": "7211042011",
    "district_id": "721104",
    "label": "Lalong",
    "value": "Lalong"
  },
  {
    "id": "7211042012",
    "district_id": "721104",
    "label": "Dungkean",
    "value": "Dungkean"
  },
  {
    "id": "7211052001",
    "district_id": "721105",
    "label": "Lalong",
    "value": "Lalong"
  },
  {
    "id": "7211052002",
    "district_id": "721105",
    "label": "Lipulalongo",
    "value": "Lipulalongo"
  },
  {
    "id": "7211052003",
    "district_id": "721105",
    "label": "Paisulamo",
    "value": "Paisulamo"
  },
  {
    "id": "7211052004",
    "district_id": "721105",
    "label": "Alasan",
    "value": "Alasan"
  },
  {
    "id": "7211052005",
    "district_id": "721105",
    "label": "Mansalean",
    "value": "Mansalean"
  },
  {
    "id": "7211052006",
    "district_id": "721105",
    "label": "Bontosi",
    "value": "Bontosi"
  },
  {
    "id": "7211052007",
    "district_id": "721105",
    "label": "Lipu Talas",
    "value": "Lipu Talas"
  },
  {
    "id": "7211052008",
    "district_id": "721105",
    "label": "Padingkian",
    "value": "Padingkian"
  },
  {
    "id": "7211062001",
    "district_id": "721106",
    "label": "Matanga",
    "value": "Matanga"
  },
  {
    "id": "7211062002",
    "district_id": "721106",
    "label": "Tolokibit",
    "value": "Tolokibit"
  },
  {
    "id": "7211062003",
    "district_id": "721106",
    "label": "Bentean",
    "value": "Bentean"
  },
  {
    "id": "7211062004",
    "district_id": "721106",
    "label": "Malino Padas",
    "value": "Malino Padas"
  },
  {
    "id": "7211062005",
    "district_id": "721106",
    "label": "Kelapa Lima",
    "value": "Kelapa Lima"
  },
  {
    "id": "7211062006",
    "district_id": "721106",
    "label": "Labuan Kapelak",
    "value": "Labuan Kapelak"
  },
  {
    "id": "7211072001",
    "district_id": "721107",
    "label": "Adean",
    "value": "Adean"
  },
  {
    "id": "7211072002",
    "district_id": "721107",
    "label": "Badumpayan",
    "value": "Badumpayan"
  },
  {
    "id": "7211072003",
    "district_id": "721107",
    "label": "Timbong",
    "value": "Timbong"
  },
  {
    "id": "7211072004",
    "district_id": "721107",
    "label": "Mominit",
    "value": "Mominit"
  },
  {
    "id": "7211072005",
    "district_id": "721107",
    "label": "Gonggong",
    "value": "Gonggong"
  },
  {
    "id": "7211072006",
    "district_id": "721107",
    "label": "Monsongan",
    "value": "Monsongan"
  },
  {
    "id": "7211072007",
    "district_id": "721107",
    "label": "Tintingo",
    "value": "Tintingo"
  },
  {
    "id": "7211072008",
    "district_id": "721107",
    "label": "Pososlalongo",
    "value": "Pososlalongo"
  },
  {
    "id": "7212011001",
    "district_id": "721201",
    "label": "Kolonodale",
    "value": "Kolonodale"
  },
  {
    "id": "7212011002",
    "district_id": "721201",
    "label": "Bahontula",
    "value": "Bahontula"
  },
  {
    "id": "7212011003",
    "district_id": "721201",
    "label": "Bahoue",
    "value": "Bahoue"
  },
  {
    "id": "7212012004",
    "district_id": "721201",
    "label": "Ganda-Ganda",
    "value": "Ganda-Ganda"
  },
  {
    "id": "7212012005",
    "district_id": "721201",
    "label": "Koya",
    "value": "Koya"
  },
  {
    "id": "7212012006",
    "district_id": "721201",
    "label": "Gililana",
    "value": "Gililana"
  },
  {
    "id": "7212012007",
    "district_id": "721201",
    "label": "Tanauge",
    "value": "Tanauge"
  },
  {
    "id": "7212012008",
    "district_id": "721201",
    "label": "Korololaki",
    "value": "Korololaki"
  },
  {
    "id": "7212012009",
    "district_id": "721201",
    "label": "Korololama",
    "value": "Korololama"
  },
  {
    "id": "7212012010",
    "district_id": "721201",
    "label": "Koromatantu",
    "value": "Koromatantu"
  },
  {
    "id": "7212022001",
    "district_id": "721202",
    "label": "Bunta",
    "value": "Bunta"
  },
  {
    "id": "7212022002",
    "district_id": "721202",
    "label": "Tompira",
    "value": "Tompira"
  },
  {
    "id": "7212022003",
    "district_id": "721202",
    "label": "Bungintimbe",
    "value": "Bungintimbe"
  },
  {
    "id": "7212022004",
    "district_id": "721202",
    "label": "Towara",
    "value": "Towara"
  },
  {
    "id": "7212022005",
    "district_id": "721202",
    "label": "Molino",
    "value": "Molino"
  },
  {
    "id": "7212022006",
    "district_id": "721202",
    "label": "Mohoni",
    "value": "Mohoni"
  },
  {
    "id": "7212022007",
    "district_id": "721202",
    "label": "Ungkea",
    "value": "Ungkea"
  },
  {
    "id": "7212022008",
    "district_id": "721202",
    "label": "Bimor Jaya",
    "value": "Bimor Jaya"
  },
  {
    "id": "7212022009",
    "district_id": "721202",
    "label": "Molores",
    "value": "Molores"
  },
  {
    "id": "7212022010",
    "district_id": "721202",
    "label": "Keuno",
    "value": "Keuno"
  },
  {
    "id": "7212022011",
    "district_id": "721202",
    "label": "Towara Pantai",
    "value": "Towara Pantai"
  },
  {
    "id": "7212022012",
    "district_id": "721202",
    "label": "Peboa",
    "value": "Peboa"
  },
  {
    "id": "7212032001",
    "district_id": "721203",
    "label": "Dolupo Karya",
    "value": "Dolupo Karya"
  },
  {
    "id": "7212032002",
    "district_id": "721203",
    "label": "Po'ona",
    "value": "Po'ona"
  },
  {
    "id": "7212032003",
    "district_id": "721203",
    "label": "Petumbea",
    "value": "Petumbea"
  },
  {
    "id": "7212032004",
    "district_id": "721203",
    "label": "Ronta",
    "value": "Ronta"
  },
  {
    "id": "7212032005",
    "district_id": "721203",
    "label": "Pontangoa",
    "value": "Pontangoa"
  },
  {
    "id": "7212032006",
    "district_id": "721203",
    "label": "Jamor Jaya",
    "value": "Jamor Jaya"
  },
  {
    "id": "7212032007",
    "district_id": "721203",
    "label": "Pa'awaru",
    "value": "Pa'awaru"
  },
  {
    "id": "7212032008",
    "district_id": "721203",
    "label": "Lembobelala",
    "value": "Lembobelala"
  },
  {
    "id": "7212032009",
    "district_id": "721203",
    "label": "Bintangor Mukti",
    "value": "Bintangor Mukti"
  },
  {
    "id": "7212032010",
    "district_id": "721203",
    "label": "Mandula",
    "value": "Mandula"
  },
  {
    "id": "7212042001",
    "district_id": "721204",
    "label": "Beteleme",
    "value": "Beteleme"
  },
  {
    "id": "7212042002",
    "district_id": "721204",
    "label": "Uluanso",
    "value": "Uluanso"
  },
  {
    "id": "7212042003",
    "district_id": "721204",
    "label": "Mora",
    "value": "Mora"
  },
  {
    "id": "7212042004",
    "district_id": "721204",
    "label": "Waraa",
    "value": "Waraa"
  },
  {
    "id": "7212042005",
    "district_id": "721204",
    "label": "Tingkeao",
    "value": "Tingkeao"
  },
  {
    "id": "7212042006",
    "district_id": "721204",
    "label": "Wawopada",
    "value": "Wawopada"
  },
  {
    "id": "7212042007",
    "district_id": "721204",
    "label": "Korowalelo",
    "value": "Korowalelo"
  },
  {
    "id": "7212042008",
    "district_id": "721204",
    "label": "Tinompo",
    "value": "Tinompo"
  },
  {
    "id": "7212042009",
    "district_id": "721204",
    "label": "Kumpi",
    "value": "Kumpi"
  },
  {
    "id": "7212042010",
    "district_id": "721204",
    "label": "Korompeeli",
    "value": "Korompeeli"
  },
  {
    "id": "7212042011",
    "district_id": "721204",
    "label": "Lemboroma",
    "value": "Lemboroma"
  },
  {
    "id": "7212042012",
    "district_id": "721204",
    "label": "Korowou",
    "value": "Korowou"
  },
  {
    "id": "7212042013",
    "district_id": "721204",
    "label": "Lembobaru",
    "value": "Lembobaru"
  },
  {
    "id": "7212042014",
    "district_id": "721204",
    "label": "Korobonde",
    "value": "Korobonde"
  },
  {
    "id": "7212052001",
    "district_id": "721205",
    "label": "Tomata",
    "value": "Tomata"
  },
  {
    "id": "7212052002",
    "district_id": "721205",
    "label": "Londi",
    "value": "Londi"
  },
  {
    "id": "7212052003",
    "district_id": "721205",
    "label": "Taende",
    "value": "Taende"
  },
  {
    "id": "7212052004",
    "district_id": "721205",
    "label": "Ensa",
    "value": "Ensa"
  },
  {
    "id": "7212052005",
    "district_id": "721205",
    "label": "Kolaka",
    "value": "Kolaka"
  },
  {
    "id": "7212052006",
    "district_id": "721205",
    "label": "Peonea",
    "value": "Peonea"
  },
  {
    "id": "7212052007",
    "district_id": "721205",
    "label": "Lanumor",
    "value": "Lanumor"
  },
  {
    "id": "7212052008",
    "district_id": "721205",
    "label": "Gontara",
    "value": "Gontara"
  },
  {
    "id": "7212052009",
    "district_id": "721205",
    "label": "Lee",
    "value": "Lee"
  },
  {
    "id": "7212052010",
    "district_id": "721205",
    "label": "Saemba",
    "value": "Saemba"
  },
  {
    "id": "7212052011",
    "district_id": "721205",
    "label": "Kasingoli",
    "value": "Kasingoli"
  },
  {
    "id": "7212052012",
    "district_id": "721205",
    "label": "Tomui Karya",
    "value": "Tomui Karya"
  },
  {
    "id": "7212052013",
    "district_id": "721205",
    "label": "Saemba Walati",
    "value": "Saemba Walati"
  },
  {
    "id": "7212052014",
    "district_id": "721205",
    "label": "Pambarea",
    "value": "Pambarea"
  },
  {
    "id": "7212062001",
    "district_id": "721206",
    "label": "Era",
    "value": "Era"
  },
  {
    "id": "7212062002",
    "district_id": "721206",
    "label": "Peleru",
    "value": "Peleru"
  },
  {
    "id": "7212062003",
    "district_id": "721206",
    "label": "Tamonjengi",
    "value": "Tamonjengi"
  },
  {
    "id": "7212062004",
    "district_id": "721206",
    "label": "Mayumba",
    "value": "Mayumba"
  },
  {
    "id": "7212062005",
    "district_id": "721206",
    "label": "Tiwa'a",
    "value": "Tiwa'a"
  },
  {
    "id": "7212062006",
    "district_id": "721206",
    "label": "Lembontonara",
    "value": "Lembontonara"
  },
  {
    "id": "7212062007",
    "district_id": "721206",
    "label": "Tabarano",
    "value": "Tabarano"
  },
  {
    "id": "7212062008",
    "district_id": "721206",
    "label": "Wawondula",
    "value": "Wawondula"
  },
  {
    "id": "7212072001",
    "district_id": "721207",
    "label": "Lembah Sumara",
    "value": "Lembah Sumara"
  },
  {
    "id": "7212072002",
    "district_id": "721207",
    "label": "Sumara Jaya",
    "value": "Sumara Jaya"
  },
  {
    "id": "7212072003",
    "district_id": "721207",
    "label": "Tambayoli",
    "value": "Tambayoli"
  },
  {
    "id": "7212072004",
    "district_id": "721207",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7212072005",
    "district_id": "721207",
    "label": "Panca Makmur",
    "value": "Panca Makmur"
  },
  {
    "id": "7212072006",
    "district_id": "721207",
    "label": "Tamainusi",
    "value": "Tamainusi"
  },
  {
    "id": "7212072007",
    "district_id": "721207",
    "label": "Bau",
    "value": "Bau"
  },
  {
    "id": "7212072008",
    "district_id": "721207",
    "label": "Malino Jaya",
    "value": "Malino Jaya"
  },
  {
    "id": "7212072009",
    "district_id": "721207",
    "label": "Toddopoli Uebangke",
    "value": "Toddopoli Uebangke"
  },
  {
    "id": "7212072010",
    "district_id": "721207",
    "label": "Tandoyondo",
    "value": "Tandoyondo"
  },
  {
    "id": "7212082001",
    "district_id": "721208",
    "label": "Baturube",
    "value": "Baturube"
  },
  {
    "id": "7212082002",
    "district_id": "721208",
    "label": "Posangke",
    "value": "Posangke"
  },
  {
    "id": "7212082003",
    "district_id": "721208",
    "label": "Taronggo",
    "value": "Taronggo"
  },
  {
    "id": "7212082004",
    "district_id": "721208",
    "label": "Ueruru",
    "value": "Ueruru"
  },
  {
    "id": "7212082005",
    "district_id": "721208",
    "label": "Uewajo",
    "value": "Uewajo"
  },
  {
    "id": "7212082006",
    "district_id": "721208",
    "label": "Tirongan Bawah",
    "value": "Tirongan Bawah"
  },
  {
    "id": "7212082007",
    "district_id": "721208",
    "label": "Tirongan Atas",
    "value": "Tirongan Atas"
  },
  {
    "id": "7212082008",
    "district_id": "721208",
    "label": "Siliti",
    "value": "Siliti"
  },
  {
    "id": "7212082009",
    "district_id": "721208",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7212082010",
    "district_id": "721208",
    "label": "Salubiro",
    "value": "Salubiro"
  },
  {
    "id": "7212082011",
    "district_id": "721208",
    "label": "Uemasi",
    "value": "Uemasi"
  },
  {
    "id": "7212082012",
    "district_id": "721208",
    "label": "Opo",
    "value": "Opo"
  },
  {
    "id": "7212082013",
    "district_id": "721208",
    "label": "Tanakuraya",
    "value": "Tanakuraya"
  },
  {
    "id": "7212082014",
    "district_id": "721208",
    "label": "Tambarobone",
    "value": "Tambarobone"
  },
  {
    "id": "7212082015",
    "district_id": "721208",
    "label": "Woomparigi",
    "value": "Woomparigi"
  },
  {
    "id": "7212082016",
    "district_id": "721208",
    "label": "Boba",
    "value": "Boba"
  },
  {
    "id": "7212082017",
    "district_id": "721208",
    "label": "Kalombang",
    "value": "Kalombang"
  },
  {
    "id": "7212082018",
    "district_id": "721208",
    "label": "Tokonanaka",
    "value": "Tokonanaka"
  },
  {
    "id": "7212082019",
    "district_id": "721208",
    "label": "Matube",
    "value": "Matube"
  },
  {
    "id": "7212082020",
    "district_id": "721208",
    "label": "Lemowalia",
    "value": "Lemowalia"
  },
  {
    "id": "7212082021",
    "district_id": "721208",
    "label": "Uempanapa",
    "value": "Uempanapa"
  },
  {
    "id": "7212082022",
    "district_id": "721208",
    "label": "Tokala Atas",
    "value": "Tokala Atas"
  },
  {
    "id": "7212082023",
    "district_id": "721208",
    "label": "Pokeang",
    "value": "Pokeang"
  },
  {
    "id": "7212092001",
    "district_id": "721209",
    "label": "Pandauke",
    "value": "Pandauke"
  },
  {
    "id": "7212092002",
    "district_id": "721209",
    "label": "Kolo Bawah",
    "value": "Kolo Bawah"
  },
  {
    "id": "7212092003",
    "district_id": "721209",
    "label": "Kolo Atas",
    "value": "Kolo Atas"
  },
  {
    "id": "7212092004",
    "district_id": "721209",
    "label": "Momo",
    "value": "Momo"
  },
  {
    "id": "7212092005",
    "district_id": "721209",
    "label": "Tananagaya",
    "value": "Tananagaya"
  },
  {
    "id": "7212092006",
    "district_id": "721209",
    "label": "Uepakatu",
    "value": "Uepakatu"
  },
  {
    "id": "7212092007",
    "district_id": "721209",
    "label": "Lijo",
    "value": "Lijo"
  },
  {
    "id": "7212092008",
    "district_id": "721209",
    "label": "Tanasumpu",
    "value": "Tanasumpu"
  },
  {
    "id": "7212092009",
    "district_id": "721209",
    "label": "Parangisi",
    "value": "Parangisi"
  },
  {
    "id": "7212092010",
    "district_id": "721209",
    "label": "Girimulya",
    "value": "Girimulya"
  },
  {
    "id": "7212092011",
    "district_id": "721209",
    "label": "Winangobino",
    "value": "Winangobino"
  },
  {
    "id": "7212092012",
    "district_id": "721209",
    "label": "Tambale",
    "value": "Tambale"
  },
  {
    "id": "7212092013",
    "district_id": "721209",
    "label": "Sea",
    "value": "Sea"
  },
  {
    "id": "7212092014",
    "district_id": "721209",
    "label": "Menyo'e",
    "value": "Menyo'e"
  },
  {
    "id": "7212102001",
    "district_id": "721210",
    "label": "Tiu",
    "value": "Tiu"
  },
  {
    "id": "7212102002",
    "district_id": "721210",
    "label": "Tontowea",
    "value": "Tontowea"
  },
  {
    "id": "7212102003",
    "district_id": "721210",
    "label": "Togo Mulyo",
    "value": "Togo Mulyo"
  },
  {
    "id": "7212102004",
    "district_id": "721210",
    "label": "Maralee",
    "value": "Maralee"
  },
  {
    "id": "7212102005",
    "district_id": "721210",
    "label": "Mondowe",
    "value": "Mondowe"
  },
  {
    "id": "7212102006",
    "district_id": "721210",
    "label": "Sampalowo",
    "value": "Sampalowo"
  },
  {
    "id": "7212102007",
    "district_id": "721210",
    "label": "Moleono",
    "value": "Moleono"
  },
  {
    "id": "7212102008",
    "district_id": "721210",
    "label": "Onepute",
    "value": "Onepute"
  },
  {
    "id": "7212102009",
    "district_id": "721210",
    "label": "Ulu Laa",
    "value": "Ulu Laa"
  },
  {
    "id": "7212102010",
    "district_id": "721210",
    "label": "Tadaku Jaya",
    "value": "Tadaku Jaya"
  },
  {
    "id": "7271011004",
    "district_id": "727101",
    "label": "Besusu Barat",
    "value": "Besusu Barat"
  },
  {
    "id": "7271011006",
    "district_id": "727101",
    "label": "Besusu Tengah",
    "value": "Besusu Tengah"
  },
  {
    "id": "7271011007",
    "district_id": "727101",
    "label": "Besusu Timur",
    "value": "Besusu Timur"
  },
  {
    "id": "7271011009",
    "district_id": "727101",
    "label": "Lolu Selatan",
    "value": "Lolu Selatan"
  },
  {
    "id": "7271011010",
    "district_id": "727101",
    "label": "Lolu Utara",
    "value": "Lolu Utara"
  },
  {
    "id": "7271021002",
    "district_id": "727102",
    "label": "Ujuna",
    "value": "Ujuna"
  },
  {
    "id": "7271021005",
    "district_id": "727102",
    "label": "Balaroa",
    "value": "Balaroa"
  },
  {
    "id": "7271021007",
    "district_id": "727102",
    "label": "Kamonji",
    "value": "Kamonji"
  },
  {
    "id": "7271021008",
    "district_id": "727102",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7271021009",
    "district_id": "727102",
    "label": "Lere",
    "value": "Lere"
  },
  {
    "id": "7271021015",
    "district_id": "727102",
    "label": "Siranindi",
    "value": "Siranindi"
  },
  {
    "id": "7271031001",
    "district_id": "727103",
    "label": "Tatura Utara",
    "value": "Tatura Utara"
  },
  {
    "id": "7271031002",
    "district_id": "727103",
    "label": "Birobuli Utara",
    "value": "Birobuli Utara"
  },
  {
    "id": "7271031003",
    "district_id": "727103",
    "label": "Petobo",
    "value": "Petobo"
  },
  {
    "id": "7271031011",
    "district_id": "727103",
    "label": "Birobuli Selatan",
    "value": "Birobuli Selatan"
  },
  {
    "id": "7271031012",
    "district_id": "727103",
    "label": "Tatura Selatan",
    "value": "Tatura Selatan"
  },
  {
    "id": "7271041001",
    "district_id": "727104",
    "label": "Mamboro",
    "value": "Mamboro"
  },
  {
    "id": "7271041002",
    "district_id": "727104",
    "label": "Taipa",
    "value": "Taipa"
  },
  {
    "id": "7271041003",
    "district_id": "727104",
    "label": "Kayumalue Ngapa",
    "value": "Kayumalue Ngapa"
  },
  {
    "id": "7271041004",
    "district_id": "727104",
    "label": "Kayumalue Pajeko",
    "value": "Kayumalue Pajeko"
  },
  {
    "id": "7271041010",
    "district_id": "727104",
    "label": "Mamboro Barat",
    "value": "Mamboro Barat"
  },
  {
    "id": "7271051001",
    "district_id": "727105",
    "label": "Buluri",
    "value": "Buluri"
  },
  {
    "id": "7271051002",
    "district_id": "727105",
    "label": "Donggala Kodi",
    "value": "Donggala Kodi"
  },
  {
    "id": "7271051003",
    "district_id": "727105",
    "label": "Kabonena",
    "value": "Kabonena"
  },
  {
    "id": "7271051004",
    "district_id": "727105",
    "label": "Silae",
    "value": "Silae"
  },
  {
    "id": "7271051005",
    "district_id": "727105",
    "label": "Watusampu",
    "value": "Watusampu"
  },
  {
    "id": "7271051006",
    "district_id": "727105",
    "label": "Tipo",
    "value": "Tipo"
  },
  {
    "id": "7271061001",
    "district_id": "727106",
    "label": "Nunu",
    "value": "Nunu"
  },
  {
    "id": "7271061002",
    "district_id": "727106",
    "label": "Palupi",
    "value": "Palupi"
  },
  {
    "id": "7271061003",
    "district_id": "727106",
    "label": "Tawanjuka",
    "value": "Tawanjuka"
  },
  {
    "id": "7271061004",
    "district_id": "727106",
    "label": "Pengawu",
    "value": "Pengawu"
  },
  {
    "id": "7271061005",
    "district_id": "727106",
    "label": "Duyu",
    "value": "Duyu"
  },
  {
    "id": "7271061006",
    "district_id": "727106",
    "label": "Boyaoge",
    "value": "Boyaoge"
  },
  {
    "id": "7271071001",
    "district_id": "727107",
    "label": "Pantoloan",
    "value": "Pantoloan"
  },
  {
    "id": "7271071002",
    "district_id": "727107",
    "label": "Pantoloan Boya",
    "value": "Pantoloan Boya"
  },
  {
    "id": "7271071003",
    "district_id": "727107",
    "label": "Baiya",
    "value": "Baiya"
  },
  {
    "id": "7271071004",
    "district_id": "727107",
    "label": "Lambara",
    "value": "Lambara"
  },
  {
    "id": "7271071005",
    "district_id": "727107",
    "label": "Panawu",
    "value": "Panawu"
  },
  {
    "id": "7271081001",
    "district_id": "727108",
    "label": "Layana Indah",
    "value": "Layana Indah"
  },
  {
    "id": "7271081002",
    "district_id": "727108",
    "label": "Tondo",
    "value": "Tondo"
  },
  {
    "id": "7271081003",
    "district_id": "727108",
    "label": "Talise",
    "value": "Talise"
  },
  {
    "id": "7271081004",
    "district_id": "727108",
    "label": "Tanamodindi",
    "value": "Tanamodindi"
  },
  {
    "id": "7271081005",
    "district_id": "727108",
    "label": "Lasoani",
    "value": "Lasoani"
  },
  {
    "id": "7271081006",
    "district_id": "727108",
    "label": "Poboya",
    "value": "Poboya"
  },
  {
    "id": "7271081007",
    "district_id": "727108",
    "label": "Kawatuna",
    "value": "Kawatuna"
  },
  {
    "id": "7271081008",
    "district_id": "727108",
    "label": "Talise Valangguni",
    "value": "Talise Valangguni"
  },
  {
    "id": "7301011001",
    "district_id": "730101",
    "label": "Benteng Utara",
    "value": "Benteng Utara"
  },
  {
    "id": "7301011002",
    "district_id": "730101",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7301011003",
    "district_id": "730101",
    "label": "Benteng Selatan",
    "value": "Benteng Selatan"
  },
  {
    "id": "7301021001",
    "district_id": "730102",
    "label": "Putabangung",
    "value": "Putabangung"
  },
  {
    "id": "7301021002",
    "district_id": "730102",
    "label": "Bontobangung",
    "value": "Bontobangung"
  },
  {
    "id": "7301022003",
    "district_id": "730102",
    "label": "Bontosunggu",
    "value": "Bontosunggu"
  },
  {
    "id": "7301022004",
    "district_id": "730102",
    "label": "Bontoborusu",
    "value": "Bontoborusu"
  },
  {
    "id": "7301022005",
    "district_id": "730102",
    "label": "Bontolebang",
    "value": "Bontolebang"
  },
  {
    "id": "7301022006",
    "district_id": "730102",
    "label": "Bontotangnga",
    "value": "Bontotangnga"
  },
  {
    "id": "7301022007",
    "district_id": "730102",
    "label": "Kahu-Kahu",
    "value": "Kahu-Kahu"
  },
  {
    "id": "7301022008",
    "district_id": "730102",
    "label": "Kalepadang",
    "value": "Kalepadang"
  },
  {
    "id": "7301031001",
    "district_id": "730103",
    "label": "Batangmata",
    "value": "Batangmata"
  },
  {
    "id": "7301031002",
    "district_id": "730103",
    "label": "Batangmata Sapo",
    "value": "Batangmata Sapo"
  },
  {
    "id": "7301032003",
    "district_id": "730103",
    "label": "Pamatata",
    "value": "Pamatata"
  },
  {
    "id": "7301032004",
    "district_id": "730103",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7301032005",
    "district_id": "730103",
    "label": "Bungaiya",
    "value": "Bungaiya"
  },
  {
    "id": "7301032006",
    "district_id": "730103",
    "label": "Kayu Bauk",
    "value": "Kayu Bauk"
  },
  {
    "id": "7301032007",
    "district_id": "730103",
    "label": "Barat Lambongan",
    "value": "Barat Lambongan"
  },
  {
    "id": "7301032008",
    "district_id": "730103",
    "label": "Bontona Saluk",
    "value": "Bontona Saluk"
  },
  {
    "id": "7301032009",
    "district_id": "730103",
    "label": "Onto",
    "value": "Onto"
  },
  {
    "id": "7301032010",
    "district_id": "730103",
    "label": "Maharayya",
    "value": "Maharayya"
  },
  {
    "id": "7301032011",
    "district_id": "730103",
    "label": "Menara Indah",
    "value": "Menara Indah"
  },
  {
    "id": "7301032015",
    "district_id": "730103",
    "label": "Tamalanrea",
    "value": "Tamalanrea"
  },
  {
    "id": "7301042001",
    "district_id": "730104",
    "label": "Parak",
    "value": "Parak"
  },
  {
    "id": "7301042002",
    "district_id": "730104",
    "label": "Barugaiya",
    "value": "Barugaiya"
  },
  {
    "id": "7301042003",
    "district_id": "730104",
    "label": "Jambuiya",
    "value": "Jambuiya"
  },
  {
    "id": "7301042004",
    "district_id": "730104",
    "label": "Mare Mare",
    "value": "Mare Mare"
  },
  {
    "id": "7301042005",
    "district_id": "730104",
    "label": "Polebunging",
    "value": "Polebunging"
  },
  {
    "id": "7301042006",
    "district_id": "730104",
    "label": "Bonea Makmur",
    "value": "Bonea Makmur"
  },
  {
    "id": "7301042008",
    "district_id": "730104",
    "label": "Bontomarannu",
    "value": "Bontomarannu"
  },
  {
    "id": "7301042010",
    "district_id": "730104",
    "label": "Bonea Timur",
    "value": "Bonea Timur"
  },
  {
    "id": "7301042011",
    "district_id": "730104",
    "label": "Bontokoraang",
    "value": "Bontokoraang"
  },
  {
    "id": "7301042012",
    "district_id": "730104",
    "label": "Kaburu",
    "value": "Kaburu"
  },
  {
    "id": "7301052001",
    "district_id": "730105",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "7301052002",
    "district_id": "730105",
    "label": "Patikarya",
    "value": "Patikarya"
  },
  {
    "id": "7301052003",
    "district_id": "730105",
    "label": "Patilereng",
    "value": "Patilereng"
  },
  {
    "id": "7301052004",
    "district_id": "730105",
    "label": "Laiyolo Baru",
    "value": "Laiyolo Baru"
  },
  {
    "id": "7301052005",
    "district_id": "730105",
    "label": "Laiyolo",
    "value": "Laiyolo"
  },
  {
    "id": "7301052006",
    "district_id": "730105",
    "label": "Lantibongan",
    "value": "Lantibongan"
  },
  {
    "id": "7301052007",
    "district_id": "730105",
    "label": "Binanga Sambaiya",
    "value": "Binanga Sambaiya"
  },
  {
    "id": "7301052008",
    "district_id": "730105",
    "label": "Lowa",
    "value": "Lowa"
  },
  {
    "id": "7301052009",
    "district_id": "730105",
    "label": "Appatanah",
    "value": "Appatanah"
  },
  {
    "id": "7301052010",
    "district_id": "730105",
    "label": "Tambolongan",
    "value": "Tambolongan"
  },
  {
    "id": "7301052011",
    "district_id": "730105",
    "label": "Polassi",
    "value": "Polassi"
  },
  {
    "id": "7301052012",
    "district_id": "730105",
    "label": "Khusus Bahuluang",
    "value": "Khusus Bahuluang"
  },
  {
    "id": "7301062001",
    "district_id": "730106",
    "label": "Kembang Ragi",
    "value": "Kembang Ragi"
  },
  {
    "id": "7301062003",
    "district_id": "730106",
    "label": "Ma'minasa",
    "value": "Ma'minasa"
  },
  {
    "id": "7301062005",
    "district_id": "730106",
    "label": "Tanamalala",
    "value": "Tanamalala"
  },
  {
    "id": "7301062006",
    "district_id": "730106",
    "label": "Labuang Pamajang",
    "value": "Labuang Pamajang"
  },
  {
    "id": "7301062007",
    "district_id": "730106",
    "label": "Masungke",
    "value": "Masungke"
  },
  {
    "id": "7301062009",
    "district_id": "730106",
    "label": "Bontosaile",
    "value": "Bontosaile"
  },
  {
    "id": "7301062011",
    "district_id": "730106",
    "label": "Teluk Kampe",
    "value": "Teluk Kampe"
  },
  {
    "id": "7301072001",
    "district_id": "730107",
    "label": "Bonerate",
    "value": "Bonerate"
  },
  {
    "id": "7301072002",
    "district_id": "730107",
    "label": "Majapahit",
    "value": "Majapahit"
  },
  {
    "id": "7301072003",
    "district_id": "730107",
    "label": "Batu Bingkung",
    "value": "Batu Bingkung"
  },
  {
    "id": "7301072004",
    "district_id": "730107",
    "label": "Bonea",
    "value": "Bonea"
  },
  {
    "id": "7301072005",
    "district_id": "730107",
    "label": "Lambego",
    "value": "Lambego"
  },
  {
    "id": "7301072006",
    "district_id": "730107",
    "label": "Komba Komba",
    "value": "Komba Komba"
  },
  {
    "id": "7301072007",
    "district_id": "730107",
    "label": "Sambali",
    "value": "Sambali"
  },
  {
    "id": "7301072008",
    "district_id": "730107",
    "label": "Lamantu",
    "value": "Lamantu"
  },
  {
    "id": "7301082001",
    "district_id": "730108",
    "label": "Kayuadi",
    "value": "Kayuadi"
  },
  {
    "id": "7301082002",
    "district_id": "730108",
    "label": "Batang",
    "value": "Batang"
  },
  {
    "id": "7301082003",
    "district_id": "730108",
    "label": "Nyiur Indah",
    "value": "Nyiur Indah"
  },
  {
    "id": "7301082004",
    "district_id": "730108",
    "label": "Tambuna",
    "value": "Tambuna"
  },
  {
    "id": "7301082005",
    "district_id": "730108",
    "label": "Jinato",
    "value": "Jinato"
  },
  {
    "id": "7301082006",
    "district_id": "730108",
    "label": "Rajuni",
    "value": "Rajuni"
  },
  {
    "id": "7301082007",
    "district_id": "730108",
    "label": "Latondu",
    "value": "Latondu"
  },
  {
    "id": "7301082008",
    "district_id": "730108",
    "label": "Tarupa",
    "value": "Tarupa"
  },
  {
    "id": "7301082009",
    "district_id": "730108",
    "label": "Khusus Pasitallu",
    "value": "Khusus Pasitallu"
  },
  {
    "id": "7301092001",
    "district_id": "730109",
    "label": "Kalaotoa",
    "value": "Kalaotoa"
  },
  {
    "id": "7301092002",
    "district_id": "730109",
    "label": "Garaupa",
    "value": "Garaupa"
  },
  {
    "id": "7301092003",
    "district_id": "730109",
    "label": "Lembang Matene",
    "value": "Lembang Matene"
  },
  {
    "id": "7301092004",
    "district_id": "730109",
    "label": "Pulo Madu",
    "value": "Pulo Madu"
  },
  {
    "id": "7301092005",
    "district_id": "730109",
    "label": "Karumpa",
    "value": "Karumpa"
  },
  {
    "id": "7301092006",
    "district_id": "730109",
    "label": "Garaupa Raya",
    "value": "Garaupa Raya"
  },
  {
    "id": "7301102001",
    "district_id": "730110",
    "label": "Bontobulaeng",
    "value": "Bontobulaeng"
  },
  {
    "id": "7301102002",
    "district_id": "730110",
    "label": "Bontobaru",
    "value": "Bontobaru"
  },
  {
    "id": "7301102003",
    "district_id": "730110",
    "label": "Bontomalling",
    "value": "Bontomalling"
  },
  {
    "id": "7301102004",
    "district_id": "730110",
    "label": "Lembang Baji",
    "value": "Lembang Baji"
  },
  {
    "id": "7301102005",
    "district_id": "730110",
    "label": "Bontojati",
    "value": "Bontojati"
  },
  {
    "id": "7301102006",
    "district_id": "730110",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "7301112001",
    "district_id": "730111",
    "label": "Buki",
    "value": "Buki"
  },
  {
    "id": "7301112002",
    "district_id": "730111",
    "label": "Lalang Bata",
    "value": "Lalang Bata"
  },
  {
    "id": "7301112003",
    "district_id": "730111",
    "label": "Balang Butung",
    "value": "Balang Butung"
  },
  {
    "id": "7301112004",
    "district_id": "730111",
    "label": "Kohala",
    "value": "Kohala"
  },
  {
    "id": "7301112005",
    "district_id": "730111",
    "label": "Bontolempangan",
    "value": "Bontolempangan"
  },
  {
    "id": "7301112006",
    "district_id": "730111",
    "label": "Mekar Indah",
    "value": "Mekar Indah"
  },
  {
    "id": "7301112007",
    "district_id": "730111",
    "label": "Buki Timur",
    "value": "Buki Timur"
  },
  {
    "id": "7302011001",
    "district_id": "730201",
    "label": "Matekko",
    "value": "Matekko"
  },
  {
    "id": "7302011002",
    "district_id": "730201",
    "label": "Jalanjang",
    "value": "Jalanjang"
  },
  {
    "id": "7302011003",
    "district_id": "730201",
    "label": "Mario Rennu",
    "value": "Mario Rennu"
  },
  {
    "id": "7302012004",
    "district_id": "730201",
    "label": "Bialo",
    "value": "Bialo"
  },
  {
    "id": "7302012005",
    "district_id": "730201",
    "label": "Bontomacinna",
    "value": "Bontomacinna"
  },
  {
    "id": "7302012006",
    "district_id": "730201",
    "label": "Gattareng",
    "value": "Gattareng"
  },
  {
    "id": "7302012007",
    "district_id": "730201",
    "label": "Dampang",
    "value": "Dampang"
  },
  {
    "id": "7302012008",
    "district_id": "730201",
    "label": "Bontosunggu",
    "value": "Bontosunggu"
  },
  {
    "id": "7302012009",
    "district_id": "730201",
    "label": "Palambarae",
    "value": "Palambarae"
  },
  {
    "id": "7302012010",
    "district_id": "730201",
    "label": "Bontonyeleng",
    "value": "Bontonyeleng"
  },
  {
    "id": "7302012011",
    "district_id": "730201",
    "label": "Benteng Malewang",
    "value": "Benteng Malewang"
  },
  {
    "id": "7302012012",
    "district_id": "730201",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "7302012013",
    "district_id": "730201",
    "label": "Bontoraja",
    "value": "Bontoraja"
  },
  {
    "id": "7302012014",
    "district_id": "730201",
    "label": "Paenre Lompoe",
    "value": "Paenre Lompoe"
  },
  {
    "id": "7302012015",
    "district_id": "730201",
    "label": "Benteng Gattareng",
    "value": "Benteng Gattareng"
  },
  {
    "id": "7302012016",
    "district_id": "730201",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "7302012017",
    "district_id": "730201",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7302012018",
    "district_id": "730201",
    "label": "Bontomasila",
    "value": "Bontomasila"
  },
  {
    "id": "7302012019",
    "district_id": "730201",
    "label": "Barombong",
    "value": "Barombong"
  },
  {
    "id": "7302012020",
    "district_id": "730201",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "7302012021",
    "district_id": "730201",
    "label": "Taccorong",
    "value": "Taccorong"
  },
  {
    "id": "7302021001",
    "district_id": "730202",
    "label": "Tanah Kongkong",
    "value": "Tanah Kongkong"
  },
  {
    "id": "7302021002",
    "district_id": "730202",
    "label": "Bentengnge",
    "value": "Bentengnge"
  },
  {
    "id": "7302021003",
    "district_id": "730202",
    "label": "Terang Terang",
    "value": "Terang Terang"
  },
  {
    "id": "7302021004",
    "district_id": "730202",
    "label": "Kalumeme",
    "value": "Kalumeme"
  },
  {
    "id": "7302021005",
    "district_id": "730202",
    "label": "Bintarore",
    "value": "Bintarore"
  },
  {
    "id": "7302021006",
    "district_id": "730202",
    "label": "Kasimpureng",
    "value": "Kasimpureng"
  },
  {
    "id": "7302021007",
    "district_id": "730202",
    "label": "Caile",
    "value": "Caile"
  },
  {
    "id": "7302021008",
    "district_id": "730202",
    "label": "Loka",
    "value": "Loka"
  },
  {
    "id": "7302021009",
    "district_id": "730202",
    "label": "Ela Ela",
    "value": "Ela Ela"
  },
  {
    "id": "7302031001",
    "district_id": "730203",
    "label": "Tanahl Lemo",
    "value": "Tanahl Lemo"
  },
  {
    "id": "7302031002",
    "district_id": "730203",
    "label": "Sapo Lohe",
    "value": "Sapo Lohe"
  },
  {
    "id": "7302031003",
    "district_id": "730203",
    "label": "Benjala",
    "value": "Benjala"
  },
  {
    "id": "7302031004",
    "district_id": "730203",
    "label": "Tanah Beru",
    "value": "Tanah Beru"
  },
  {
    "id": "7302032005",
    "district_id": "730203",
    "label": "Bira",
    "value": "Bira"
  },
  {
    "id": "7302032006",
    "district_id": "730203",
    "label": "Ara",
    "value": "Ara"
  },
  {
    "id": "7302032007",
    "district_id": "730203",
    "label": "Darubiah",
    "value": "Darubiah"
  },
  {
    "id": "7302032008",
    "district_id": "730203",
    "label": "Lembanna",
    "value": "Lembanna"
  },
  {
    "id": "7302041001",
    "district_id": "730204",
    "label": "Eka Tiro",
    "value": "Eka Tiro"
  },
  {
    "id": "7302042002",
    "district_id": "730204",
    "label": "Dwitiro",
    "value": "Dwitiro"
  },
  {
    "id": "7302042003",
    "district_id": "730204",
    "label": "Tritiro",
    "value": "Tritiro"
  },
  {
    "id": "7302042004",
    "district_id": "730204",
    "label": "Batang",
    "value": "Batang"
  },
  {
    "id": "7302042005",
    "district_id": "730204",
    "label": "Bontotangnga",
    "value": "Bontotangnga"
  },
  {
    "id": "7302042006",
    "district_id": "730204",
    "label": "Caramming",
    "value": "Caramming"
  },
  {
    "id": "7302042007",
    "district_id": "730204",
    "label": "Tamalanrea",
    "value": "Tamalanrea"
  },
  {
    "id": "7302042008",
    "district_id": "730204",
    "label": "Bontobarua",
    "value": "Bontobarua"
  },
  {
    "id": "7302042009",
    "district_id": "730204",
    "label": "Pakubalaho",
    "value": "Pakubalaho"
  },
  {
    "id": "7302042010",
    "district_id": "730204",
    "label": "Bontomarannu",
    "value": "Bontomarannu"
  },
  {
    "id": "7302042011",
    "district_id": "730204",
    "label": "Bontobulaeng",
    "value": "Bontobulaeng"
  },
  {
    "id": "7302042012",
    "district_id": "730204",
    "label": "Buhung Bundang",
    "value": "Buhung Bundang"
  },
  {
    "id": "7302042013",
    "district_id": "730204",
    "label": "Lamanda",
    "value": "Lamanda"
  },
  {
    "id": "7302051001",
    "district_id": "730205",
    "label": "Tanuntung",
    "value": "Tanuntung"
  },
  {
    "id": "7302051002",
    "district_id": "730205",
    "label": "Bonto Kamase",
    "value": "Bonto Kamase"
  },
  {
    "id": "7302052003",
    "district_id": "730205",
    "label": "Karassing",
    "value": "Karassing"
  },
  {
    "id": "7302052004",
    "district_id": "730205",
    "label": "Singa",
    "value": "Singa"
  },
  {
    "id": "7302052005",
    "district_id": "730205",
    "label": "Gunturu",
    "value": "Gunturu"
  },
  {
    "id": "7302052006",
    "district_id": "730205",
    "label": "Borong",
    "value": "Borong"
  },
  {
    "id": "7302052007",
    "district_id": "730205",
    "label": "Tugondeng",
    "value": "Tugondeng"
  },
  {
    "id": "7302052008",
    "district_id": "730205",
    "label": "Pataro",
    "value": "Pataro"
  },
  {
    "id": "7302061001",
    "district_id": "730206",
    "label": "Tanah Jaya",
    "value": "Tanah Jaya"
  },
  {
    "id": "7302061002",
    "district_id": "730206",
    "label": "Laikang",
    "value": "Laikang"
  },
  {
    "id": "7302062003",
    "district_id": "730206",
    "label": "Tambangan",
    "value": "Tambangan"
  },
  {
    "id": "7302062004",
    "district_id": "730206",
    "label": "Lembanna",
    "value": "Lembanna"
  },
  {
    "id": "7302062005",
    "district_id": "730206",
    "label": "Possi Tanah",
    "value": "Possi Tanah"
  },
  {
    "id": "7302062006",
    "district_id": "730206",
    "label": "Tanah Towa",
    "value": "Tanah Towa"
  },
  {
    "id": "7302062007",
    "district_id": "730206",
    "label": "Bontobiraeng",
    "value": "Bontobiraeng"
  },
  {
    "id": "7302062008",
    "district_id": "730206",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "7302062009",
    "district_id": "730206",
    "label": "Batunilamung",
    "value": "Batunilamung"
  },
  {
    "id": "7302062010",
    "district_id": "730206",
    "label": "Mattoanging",
    "value": "Mattoanging"
  },
  {
    "id": "7302062011",
    "district_id": "730206",
    "label": "Malleleng",
    "value": "Malleleng"
  },
  {
    "id": "7302062012",
    "district_id": "730206",
    "label": "Bonto Baji",
    "value": "Bonto Baji"
  },
  {
    "id": "7302062013",
    "district_id": "730206",
    "label": "Bontorannu",
    "value": "Bontorannu"
  },
  {
    "id": "7302062014",
    "district_id": "730206",
    "label": "Pattiroang",
    "value": "Pattiroang"
  },
  {
    "id": "7302062015",
    "district_id": "730206",
    "label": "Sapanang",
    "value": "Sapanang"
  },
  {
    "id": "7302062016",
    "district_id": "730206",
    "label": "Sangkala",
    "value": "Sangkala"
  },
  {
    "id": "7302062017",
    "district_id": "730206",
    "label": "Lembang Lohe",
    "value": "Lembang Lohe"
  },
  {
    "id": "7302062018",
    "district_id": "730206",
    "label": "Lolisang",
    "value": "Lolisang"
  },
  {
    "id": "7302062019",
    "district_id": "730206",
    "label": "Pantama",
    "value": "Pantama"
  },
  {
    "id": "7302071001",
    "district_id": "730207",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7302071002",
    "district_id": "730207",
    "label": "Jawijawi",
    "value": "Jawijawi"
  },
  {
    "id": "7302071003",
    "district_id": "730207",
    "label": "Balla Saraja",
    "value": "Balla Saraja"
  },
  {
    "id": "7302072004",
    "district_id": "730207",
    "label": "Bontobulaeng",
    "value": "Bontobulaeng"
  },
  {
    "id": "7302072005",
    "district_id": "730207",
    "label": "Bulo Bulo",
    "value": "Bulo Bulo"
  },
  {
    "id": "7302072006",
    "district_id": "730207",
    "label": "Bontominasa",
    "value": "Bontominasa"
  },
  {
    "id": "7302072007",
    "district_id": "730207",
    "label": "Balangtaroang",
    "value": "Balangtaroang"
  },
  {
    "id": "7302072008",
    "district_id": "730207",
    "label": "Barugae",
    "value": "Barugae"
  },
  {
    "id": "7302072009",
    "district_id": "730207",
    "label": "Salassae",
    "value": "Salassae"
  },
  {
    "id": "7302072010",
    "district_id": "730207",
    "label": "Kambuno",
    "value": "Kambuno"
  },
  {
    "id": "7302072011",
    "district_id": "730207",
    "label": "Jojjolo",
    "value": "Jojjolo"
  },
  {
    "id": "7302072012",
    "district_id": "730207",
    "label": "Sapobonto",
    "value": "Sapobonto"
  },
  {
    "id": "7302072013",
    "district_id": "730207",
    "label": "Tibona",
    "value": "Tibona"
  },
  {
    "id": "7302072014",
    "district_id": "730207",
    "label": "Balangpesoang",
    "value": "Balangpesoang"
  },
  {
    "id": "7302072015",
    "district_id": "730207",
    "label": "Batulohe",
    "value": "Batulohe"
  },
  {
    "id": "7302072016",
    "district_id": "730207",
    "label": "Bontomangiring",
    "value": "Bontomangiring"
  },
  {
    "id": "7302072017",
    "district_id": "730207",
    "label": "Baruga Riattang",
    "value": "Baruga Riattang"
  },
  {
    "id": "7302081001",
    "district_id": "730208",
    "label": "Borong Rappoa",
    "value": "Borong Rappoa"
  },
  {
    "id": "7302082002",
    "district_id": "730208",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7302082003",
    "district_id": "730208",
    "label": "Kindang",
    "value": "Kindang"
  },
  {
    "id": "7302082004",
    "district_id": "730208",
    "label": "Arinhua",
    "value": "Arinhua"
  },
  {
    "id": "7302082005",
    "district_id": "730208",
    "label": "Tamaona",
    "value": "Tamaona"
  },
  {
    "id": "7302082006",
    "district_id": "730208",
    "label": "Benteng Palioi",
    "value": "Benteng Palioi"
  },
  {
    "id": "7302082007",
    "district_id": "730208",
    "label": "Balibo",
    "value": "Balibo"
  },
  {
    "id": "7302082008",
    "district_id": "730208",
    "label": "Garuntungan",
    "value": "Garuntungan"
  },
  {
    "id": "7302082009",
    "district_id": "730208",
    "label": "Orogading",
    "value": "Orogading"
  },
  {
    "id": "7302082010",
    "district_id": "730208",
    "label": "Sipaenre",
    "value": "Sipaenre"
  },
  {
    "id": "7302082011",
    "district_id": "730208",
    "label": "Kahayya",
    "value": "Kahayya"
  },
  {
    "id": "7302082012",
    "district_id": "730208",
    "label": "Somba Palioli",
    "value": "Somba Palioli"
  },
  {
    "id": "7302082013",
    "district_id": "730208",
    "label": "Sopa",
    "value": "Sopa"
  },
  {
    "id": "7302091001",
    "district_id": "730209",
    "label": "Dannuang",
    "value": "Dannuang"
  },
  {
    "id": "7302092002",
    "district_id": "730209",
    "label": "Garanta",
    "value": "Garanta"
  },
  {
    "id": "7302092003",
    "district_id": "730209",
    "label": "Balleanging",
    "value": "Balleanging"
  },
  {
    "id": "7302092004",
    "district_id": "730209",
    "label": "Balong",
    "value": "Balong"
  },
  {
    "id": "7302092005",
    "district_id": "730209",
    "label": "Seppang",
    "value": "Seppang"
  },
  {
    "id": "7302092006",
    "district_id": "730209",
    "label": "Manyampa",
    "value": "Manyampa"
  },
  {
    "id": "7302092007",
    "district_id": "730209",
    "label": "Tammatto",
    "value": "Tammatto"
  },
  {
    "id": "7302092008",
    "district_id": "730209",
    "label": "Padangloang",
    "value": "Padangloang"
  },
  {
    "id": "7302092009",
    "district_id": "730209",
    "label": "Manjalling",
    "value": "Manjalling"
  },
  {
    "id": "7302092010",
    "district_id": "730209",
    "label": "Lonrong",
    "value": "Lonrong"
  },
  {
    "id": "7302092011",
    "district_id": "730209",
    "label": "Salemba",
    "value": "Salemba"
  },
  {
    "id": "7302092012",
    "district_id": "730209",
    "label": "Bijawang",
    "value": "Bijawang"
  },
  {
    "id": "7302092013",
    "district_id": "730209",
    "label": "Paccaramengang",
    "value": "Paccaramengang"
  },
  {
    "id": "7302101002",
    "district_id": "730210",
    "label": "Palampang",
    "value": "Palampang"
  },
  {
    "id": "7302102001",
    "district_id": "730210",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7302102003",
    "district_id": "730210",
    "label": "Bontobangun",
    "value": "Bontobangun"
  },
  {
    "id": "7302102004",
    "district_id": "730210",
    "label": "Karama",
    "value": "Karama"
  },
  {
    "id": "7302102005",
    "district_id": "730210",
    "label": "Bontolohe",
    "value": "Bontolohe"
  },
  {
    "id": "7302102006",
    "district_id": "730210",
    "label": "Bajiminasa",
    "value": "Bajiminasa"
  },
  {
    "id": "7302102007",
    "district_id": "730210",
    "label": "Bonto Matene",
    "value": "Bonto Matene"
  },
  {
    "id": "7302102008",
    "district_id": "730210",
    "label": "Tanah Harapan",
    "value": "Tanah Harapan"
  },
  {
    "id": "7302102009",
    "district_id": "730210",
    "label": "Batukaropa",
    "value": "Batukaropa"
  },
  {
    "id": "7302102010",
    "district_id": "730210",
    "label": "Bontoharu",
    "value": "Bontoharu"
  },
  {
    "id": "7302102011",
    "district_id": "730210",
    "label": "Swatani",
    "value": "Swatani"
  },
  {
    "id": "7302102012",
    "district_id": "730210",
    "label": "Bulolohe",
    "value": "Bulolohe"
  },
  {
    "id": "7302102013",
    "district_id": "730210",
    "label": "Anrang",
    "value": "Anrang"
  },
  {
    "id": "7302102014",
    "district_id": "730210",
    "label": "Pangalloang",
    "value": "Pangalloang"
  },
  {
    "id": "7302102015",
    "district_id": "730210",
    "label": "Topanda",
    "value": "Topanda"
  },
  {
    "id": "7303011001",
    "district_id": "730301",
    "label": "Bonto Manai",
    "value": "Bonto Manai"
  },
  {
    "id": "7303011002",
    "district_id": "730301",
    "label": "Bonto Lebang",
    "value": "Bonto Lebang"
  },
  {
    "id": "7303011003",
    "district_id": "730301",
    "label": "Bonto Sunggu",
    "value": "Bonto Sunggu"
  },
  {
    "id": "7303011004",
    "district_id": "730301",
    "label": "Bonto Atu",
    "value": "Bonto Atu"
  },
  {
    "id": "7303011005",
    "district_id": "730301",
    "label": "Bonto Rita",
    "value": "Bonto Rita"
  },
  {
    "id": "7303011006",
    "district_id": "730301",
    "label": "Bonto Langkasa",
    "value": "Bonto Langkasa"
  },
  {
    "id": "7303011007",
    "district_id": "730301",
    "label": "Bonto Jaya",
    "value": "Bonto Jaya"
  },
  {
    "id": "7303012008",
    "district_id": "730301",
    "label": "Bonto Jai",
    "value": "Bonto Jai"
  },
  {
    "id": "7303012009",
    "district_id": "730301",
    "label": "Bonto Cinde",
    "value": "Bonto Cinde"
  },
  {
    "id": "7303012010",
    "district_id": "730301",
    "label": "Bonto Loe",
    "value": "Bonto Loe"
  },
  {
    "id": "7303012012",
    "district_id": "730301",
    "label": "Bonto Salluang",
    "value": "Bonto Salluang"
  },
  {
    "id": "7303021001",
    "district_id": "730302",
    "label": "Tappanjeng",
    "value": "Tappanjeng"
  },
  {
    "id": "7303021002",
    "district_id": "730302",
    "label": "Pallantikang",
    "value": "Pallantikang"
  },
  {
    "id": "7303021003",
    "district_id": "730302",
    "label": "Letta",
    "value": "Letta"
  },
  {
    "id": "7303021004",
    "district_id": "730302",
    "label": "Mallilingi",
    "value": "Mallilingi"
  },
  {
    "id": "7303021005",
    "district_id": "730302",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "7303021006",
    "district_id": "730302",
    "label": "Lamalaka",
    "value": "Lamalaka"
  },
  {
    "id": "7303021007",
    "district_id": "730302",
    "label": "Karatuang",
    "value": "Karatuang"
  },
  {
    "id": "7303021008",
    "district_id": "730302",
    "label": "Onto",
    "value": "Onto"
  },
  {
    "id": "7303022009",
    "district_id": "730302",
    "label": "Kayu Loe",
    "value": "Kayu Loe"
  },
  {
    "id": "7303032001",
    "district_id": "730303",
    "label": "Ulu Galung",
    "value": "Ulu Galung"
  },
  {
    "id": "7303032002",
    "district_id": "730303",
    "label": "Lonrong",
    "value": "Lonrong"
  },
  {
    "id": "7303032003",
    "district_id": "730303",
    "label": "Barua",
    "value": "Barua"
  },
  {
    "id": "7303032004",
    "district_id": "730303",
    "label": "Kampala",
    "value": "Kampala"
  },
  {
    "id": "7303032005",
    "district_id": "730303",
    "label": "Pa'bentengan",
    "value": "Pa'bentengan"
  },
  {
    "id": "7303032006",
    "district_id": "730303",
    "label": "Mappilawing",
    "value": "Mappilawing"
  },
  {
    "id": "7303032007",
    "district_id": "730303",
    "label": "Pa'bumbungang",
    "value": "Pa'bumbungang"
  },
  {
    "id": "7303032008",
    "district_id": "730303",
    "label": "Mamampang",
    "value": "Mamampang"
  },
  {
    "id": "7303032009",
    "district_id": "730303",
    "label": "Parang Loe",
    "value": "Parang Loe"
  },
  {
    "id": "7303041001",
    "district_id": "730304",
    "label": "Lembang Gantarang Keke",
    "value": "Lembang Gantarang Keke"
  },
  {
    "id": "7303041003",
    "district_id": "730304",
    "label": "Banyorang",
    "value": "Banyorang"
  },
  {
    "id": "7303041004",
    "district_id": "730304",
    "label": "Campaga",
    "value": "Campaga"
  },
  {
    "id": "7303041005",
    "district_id": "730304",
    "label": "Eren Ereng",
    "value": "Eren Ereng"
  },
  {
    "id": "7303042006",
    "district_id": "730304",
    "label": "Labbo",
    "value": "Labbo"
  },
  {
    "id": "7303042007",
    "district_id": "730304",
    "label": "Pattaneteang",
    "value": "Pattaneteang"
  },
  {
    "id": "7303042008",
    "district_id": "730304",
    "label": "Pattallassang",
    "value": "Pattallassang"
  },
  {
    "id": "7303042009",
    "district_id": "730304",
    "label": "Bonto-bontoa",
    "value": "Bonto-bontoa"
  },
  {
    "id": "7303042010",
    "district_id": "730304",
    "label": "Bonto Tappalang",
    "value": "Bonto Tappalang"
  },
  {
    "id": "7303042011",
    "district_id": "730304",
    "label": "Balumbung",
    "value": "Balumbung"
  },
  {
    "id": "7303052002",
    "district_id": "730305",
    "label": "Biangkeke",
    "value": "Biangkeke"
  },
  {
    "id": "7303052003",
    "district_id": "730305",
    "label": "Pa'jukukang",
    "value": "Pa'jukukang"
  },
  {
    "id": "7303052004",
    "district_id": "730305",
    "label": "Biangloe",
    "value": "Biangloe"
  },
  {
    "id": "7303052005",
    "district_id": "730305",
    "label": "Borong Loe",
    "value": "Borong Loe"
  },
  {
    "id": "7303052006",
    "district_id": "730305",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7303052010",
    "district_id": "730305",
    "label": "Nipa-nipa",
    "value": "Nipa-nipa"
  },
  {
    "id": "7303052012",
    "district_id": "730305",
    "label": "Papan Loe",
    "value": "Papan Loe"
  },
  {
    "id": "7303052013",
    "district_id": "730305",
    "label": "Rappoa",
    "value": "Rappoa"
  },
  {
    "id": "7303052014",
    "district_id": "730305",
    "label": "Lumpangan",
    "value": "Lumpangan"
  },
  {
    "id": "7303052015",
    "district_id": "730305",
    "label": "Batu Karaeng",
    "value": "Batu Karaeng"
  },
  {
    "id": "7303062001",
    "district_id": "730306",
    "label": "Bonto Marannu",
    "value": "Bonto Marannu"
  },
  {
    "id": "7303062002",
    "district_id": "730306",
    "label": "Bonto Tangnga",
    "value": "Bonto Tangnga"
  },
  {
    "id": "7303062006",
    "district_id": "730306",
    "label": "Bonto Tallasa",
    "value": "Bonto Tallasa"
  },
  {
    "id": "7303062007",
    "district_id": "730306",
    "label": "Bonto Rannu",
    "value": "Bonto Rannu"
  },
  {
    "id": "7303062010",
    "district_id": "730306",
    "label": "Bonto Daeng",
    "value": "Bonto Daeng"
  },
  {
    "id": "7303062011",
    "district_id": "730306",
    "label": "Bonto Lojong",
    "value": "Bonto Lojong"
  },
  {
    "id": "7303071001",
    "district_id": "730307",
    "label": "Gantarang Keke",
    "value": "Gantarang Keke"
  },
  {
    "id": "7303071002",
    "district_id": "730307",
    "label": "Tanah Loe",
    "value": "Tanah Loe"
  },
  {
    "id": "7303072003",
    "district_id": "730307",
    "label": "Layoa",
    "value": "Layoa"
  },
  {
    "id": "7303072004",
    "district_id": "730307",
    "label": "Bajiminasa",
    "value": "Bajiminasa"
  },
  {
    "id": "7303072005",
    "district_id": "730307",
    "label": "Tombolo",
    "value": "Tombolo"
  },
  {
    "id": "7303072006",
    "district_id": "730307",
    "label": "Kaloling",
    "value": "Kaloling"
  },
  {
    "id": "7303082001",
    "district_id": "730308",
    "label": "Bonto Tiro",
    "value": "Bonto Tiro"
  },
  {
    "id": "7303082002",
    "district_id": "730308",
    "label": "Bonto Karaeng",
    "value": "Bonto Karaeng"
  },
  {
    "id": "7303082003",
    "district_id": "730308",
    "label": "Bonto Maccini",
    "value": "Bonto Maccini"
  },
  {
    "id": "7303082004",
    "district_id": "730308",
    "label": "Bonto Mate'ne",
    "value": "Bonto Mate'ne"
  },
  {
    "id": "7303082005",
    "district_id": "730308",
    "label": "Bonto Majannang",
    "value": "Bonto Majannang"
  },
  {
    "id": "7303082006",
    "district_id": "730308",
    "label": "Bonto Bulaeng",
    "value": "Bonto Bulaeng"
  },
  {
    "id": "7304011001",
    "district_id": "730401",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7304011002",
    "district_id": "730401",
    "label": "Pallengu",
    "value": "Pallengu"
  },
  {
    "id": "7304011003",
    "district_id": "730401",
    "label": "Pantai Bahari",
    "value": "Pantai Bahari"
  },
  {
    "id": "7304011004",
    "district_id": "730401",
    "label": "Bontorannu",
    "value": "Bontorannu"
  },
  {
    "id": "7304012005",
    "district_id": "730401",
    "label": "Pallantikang",
    "value": "Pallantikang"
  },
  {
    "id": "7304012006",
    "district_id": "730401",
    "label": "Kalimporo",
    "value": "Kalimporo"
  },
  {
    "id": "7304012007",
    "district_id": "730401",
    "label": "Punagaya",
    "value": "Punagaya"
  },
  {
    "id": "7304012008",
    "district_id": "730401",
    "label": "Mallasoro",
    "value": "Mallasoro"
  },
  {
    "id": "7304012009",
    "district_id": "730401",
    "label": "Kapita",
    "value": "Kapita"
  },
  {
    "id": "7304012010",
    "district_id": "730401",
    "label": "Marayoka",
    "value": "Marayoka"
  },
  {
    "id": "7304012011",
    "district_id": "730401",
    "label": "Jenetallasa",
    "value": "Jenetallasa"
  },
  {
    "id": "7304012012",
    "district_id": "730401",
    "label": "Tombo Tombolo",
    "value": "Tombo Tombolo"
  },
  {
    "id": "7304012013",
    "district_id": "730401",
    "label": "Gunung Silanu",
    "value": "Gunung Silanu"
  },
  {
    "id": "7304012014",
    "district_id": "730401",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7304021001",
    "district_id": "730402",
    "label": "Bonto Tangnga",
    "value": "Bonto Tangnga"
  },
  {
    "id": "7304021002",
    "district_id": "730402",
    "label": "Tonro Kassi",
    "value": "Tonro Kassi"
  },
  {
    "id": "7304021003",
    "district_id": "730402",
    "label": "Tonro Kassi Barat",
    "value": "Tonro Kassi Barat"
  },
  {
    "id": "7304021004",
    "district_id": "730402",
    "label": "Tonro Kassi Timur",
    "value": "Tonro Kassi Timur"
  },
  {
    "id": "7304021005",
    "district_id": "730402",
    "label": "Taman Roya",
    "value": "Taman Roya"
  },
  {
    "id": "7304021006",
    "district_id": "730402",
    "label": "Manjangloe",
    "value": "Manjangloe"
  },
  {
    "id": "7304022007",
    "district_id": "730402",
    "label": "Borongtala",
    "value": "Borongtala"
  },
  {
    "id": "7304022008",
    "district_id": "730402",
    "label": "Bontosunggu",
    "value": "Bontosunggu"
  },
  {
    "id": "7304022009",
    "district_id": "730402",
    "label": "Turatea",
    "value": "Turatea"
  },
  {
    "id": "7304022010",
    "district_id": "730402",
    "label": "Karelayu",
    "value": "Karelayu"
  },
  {
    "id": "7304022011",
    "district_id": "730402",
    "label": "Bontojai",
    "value": "Bontojai"
  },
  {
    "id": "7304022012",
    "district_id": "730402",
    "label": "Turatea Timur",
    "value": "Turatea Timur"
  },
  {
    "id": "7304031001",
    "district_id": "730403",
    "label": "Pabiringa",
    "value": "Pabiringa"
  },
  {
    "id": "7304031002",
    "district_id": "730403",
    "label": "Balang",
    "value": "Balang"
  },
  {
    "id": "7304031003",
    "district_id": "730403",
    "label": "Empoang",
    "value": "Empoang"
  },
  {
    "id": "7304031004",
    "district_id": "730403",
    "label": "Balang Toa",
    "value": "Balang Toa"
  },
  {
    "id": "7304031005",
    "district_id": "730403",
    "label": "Balang Beru",
    "value": "Balang Beru"
  },
  {
    "id": "7304031006",
    "district_id": "730403",
    "label": "Empoang Selatan",
    "value": "Empoang Selatan"
  },
  {
    "id": "7304031007",
    "district_id": "730403",
    "label": "Sidenre",
    "value": "Sidenre"
  },
  {
    "id": "7304031008",
    "district_id": "730403",
    "label": "Empoang Utara",
    "value": "Empoang Utara"
  },
  {
    "id": "7304031009",
    "district_id": "730403",
    "label": "Monro Monro",
    "value": "Monro Monro"
  },
  {
    "id": "7304031010",
    "district_id": "730403",
    "label": "Biring Kassi",
    "value": "Biring Kassi"
  },
  {
    "id": "7304031011",
    "district_id": "730403",
    "label": "Panaikang",
    "value": "Panaikang"
  },
  {
    "id": "7304031012",
    "district_id": "730403",
    "label": "Bontoa",
    "value": "Bontoa"
  },
  {
    "id": "7304032013",
    "district_id": "730403",
    "label": "Sapanang",
    "value": "Sapanang"
  },
  {
    "id": "7304041001",
    "district_id": "730404",
    "label": "Togo Togo",
    "value": "Togo Togo"
  },
  {
    "id": "7304041002",
    "district_id": "730404",
    "label": "Bonto Raya",
    "value": "Bonto Raya"
  },
  {
    "id": "7304042003",
    "district_id": "730404",
    "label": "Maccinibaji",
    "value": "Maccinibaji"
  },
  {
    "id": "7304042008",
    "district_id": "730404",
    "label": "Camba Camba",
    "value": "Camba Camba"
  },
  {
    "id": "7304042009",
    "district_id": "730404",
    "label": "Kaluku",
    "value": "Kaluku"
  },
  {
    "id": "7304042014",
    "district_id": "730404",
    "label": "Bungeng",
    "value": "Bungeng"
  },
  {
    "id": "7304051001",
    "district_id": "730405",
    "label": "Tolo",
    "value": "Tolo"
  },
  {
    "id": "7304051002",
    "district_id": "730405",
    "label": "Tolo Utara",
    "value": "Tolo Utara"
  },
  {
    "id": "7304051003",
    "district_id": "730405",
    "label": "Tolo Selatan",
    "value": "Tolo Selatan"
  },
  {
    "id": "7304051004",
    "district_id": "730405",
    "label": "Tolo Timur",
    "value": "Tolo Timur"
  },
  {
    "id": "7304051005",
    "district_id": "730405",
    "label": "Tolo Barat",
    "value": "Tolo Barat"
  },
  {
    "id": "7304052006",
    "district_id": "730405",
    "label": "Gantarang",
    "value": "Gantarang"
  },
  {
    "id": "7304052009",
    "district_id": "730405",
    "label": "Bontolebang",
    "value": "Bontolebang"
  },
  {
    "id": "7304052010",
    "district_id": "730405",
    "label": "Bontonompo",
    "value": "Bontonompo"
  },
  {
    "id": "7304052014",
    "district_id": "730405",
    "label": "Samataring",
    "value": "Samataring"
  },
  {
    "id": "7304052021",
    "district_id": "730405",
    "label": "Tombolo",
    "value": "Tombolo"
  },
  {
    "id": "7304061002",
    "district_id": "730406",
    "label": "Bulu Jaya",
    "value": "Bulu Jaya"
  },
  {
    "id": "7304062001",
    "district_id": "730406",
    "label": "Banrimanurung",
    "value": "Banrimanurung"
  },
  {
    "id": "7304062003",
    "district_id": "730406",
    "label": "Barana",
    "value": "Barana"
  },
  {
    "id": "7304062004",
    "district_id": "730406",
    "label": "Beroanging",
    "value": "Beroanging"
  },
  {
    "id": "7304062005",
    "district_id": "730406",
    "label": "Tuju",
    "value": "Tuju"
  },
  {
    "id": "7304062006",
    "district_id": "730406",
    "label": "Garassikang",
    "value": "Garassikang"
  },
  {
    "id": "7304062007",
    "district_id": "730406",
    "label": "Pattiro",
    "value": "Pattiro"
  },
  {
    "id": "7304062008",
    "district_id": "730406",
    "label": "Pappalluang",
    "value": "Pappalluang"
  },
  {
    "id": "7304071002",
    "district_id": "730407",
    "label": "Bontoramba",
    "value": "Bontoramba"
  },
  {
    "id": "7304072001",
    "district_id": "730407",
    "label": "Bulusibatang",
    "value": "Bulusibatang"
  },
  {
    "id": "7304072003",
    "district_id": "730407",
    "label": "Balumbungan",
    "value": "Balumbungan"
  },
  {
    "id": "7304072004",
    "district_id": "730407",
    "label": "Bangkalaloe",
    "value": "Bangkalaloe"
  },
  {
    "id": "7304072005",
    "district_id": "730407",
    "label": "Datara",
    "value": "Datara"
  },
  {
    "id": "7304072006",
    "district_id": "730407",
    "label": "Maero",
    "value": "Maero"
  },
  {
    "id": "7304072007",
    "district_id": "730407",
    "label": "Kareloe",
    "value": "Kareloe"
  },
  {
    "id": "7304072008",
    "district_id": "730407",
    "label": "Batujala",
    "value": "Batujala"
  },
  {
    "id": "7304072009",
    "district_id": "730407",
    "label": "Bulusuka",
    "value": "Bulusuka"
  },
  {
    "id": "7304072010",
    "district_id": "730407",
    "label": "Tanammawang",
    "value": "Tanammawang"
  },
  {
    "id": "7304072011",
    "district_id": "730407",
    "label": "Lentu",
    "value": "Lentu"
  },
  {
    "id": "7304072012",
    "district_id": "730407",
    "label": "Baraya",
    "value": "Baraya"
  },
  {
    "id": "7304082001",
    "district_id": "730408",
    "label": "Paitana",
    "value": "Paitana"
  },
  {
    "id": "7304082002",
    "district_id": "730408",
    "label": "Bonto Mate'ne",
    "value": "Bonto Mate'ne"
  },
  {
    "id": "7304082003",
    "district_id": "730408",
    "label": "Mangepong",
    "value": "Mangepong"
  },
  {
    "id": "7304082004",
    "district_id": "730408",
    "label": "Bululoe",
    "value": "Bululoe"
  },
  {
    "id": "7304082005",
    "district_id": "730408",
    "label": "Kayuloe Barat",
    "value": "Kayuloe Barat"
  },
  {
    "id": "7304082006",
    "district_id": "730408",
    "label": "Langkura",
    "value": "Langkura"
  },
  {
    "id": "7304082007",
    "district_id": "730408",
    "label": "Bungungloe",
    "value": "Bungungloe"
  },
  {
    "id": "7304082008",
    "district_id": "730408",
    "label": "Kayuloe Timur",
    "value": "Kayuloe Timur"
  },
  {
    "id": "7304082009",
    "district_id": "730408",
    "label": "Jombe",
    "value": "Jombe"
  },
  {
    "id": "7304082010",
    "district_id": "730408",
    "label": "Tanjonga",
    "value": "Tanjonga"
  },
  {
    "id": "7304082011",
    "district_id": "730408",
    "label": "Parasanganberu",
    "value": "Parasanganberu"
  },
  {
    "id": "7304092001",
    "district_id": "730409",
    "label": "Bulo Bulo",
    "value": "Bulo Bulo"
  },
  {
    "id": "7304092002",
    "district_id": "730409",
    "label": "Arungkeke",
    "value": "Arungkeke"
  },
  {
    "id": "7304092003",
    "district_id": "730409",
    "label": "Palajau",
    "value": "Palajau"
  },
  {
    "id": "7304092004",
    "district_id": "730409",
    "label": "Boronglamu",
    "value": "Boronglamu"
  },
  {
    "id": "7304092005",
    "district_id": "730409",
    "label": "Arungkeke Pallantikang",
    "value": "Arungkeke Pallantikang"
  },
  {
    "id": "7304092006",
    "district_id": "730409",
    "label": "Kalumpangloe",
    "value": "Kalumpangloe"
  },
  {
    "id": "7304092007",
    "district_id": "730409",
    "label": "Kampala",
    "value": "Kampala"
  },
  {
    "id": "7304102001",
    "district_id": "730410",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "7304102002",
    "district_id": "730410",
    "label": "Lebangmanai",
    "value": "Lebangmanai"
  },
  {
    "id": "7304102003",
    "district_id": "730410",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7304102004",
    "district_id": "730410",
    "label": "Bontotiro",
    "value": "Bontotiro"
  },
  {
    "id": "7304102005",
    "district_id": "730410",
    "label": "Loka",
    "value": "Loka"
  },
  {
    "id": "7304102006",
    "district_id": "730410",
    "label": "Tompobulu",
    "value": "Tompobulu"
  },
  {
    "id": "7304102007",
    "district_id": "730410",
    "label": "Kassi",
    "value": "Kassi"
  },
  {
    "id": "7304102008",
    "district_id": "730410",
    "label": "Pallantikang",
    "value": "Pallantikang"
  },
  {
    "id": "7304102009",
    "district_id": "730410",
    "label": "Lebangmanai Utara",
    "value": "Lebangmanai Utara"
  },
  {
    "id": "7304102010",
    "district_id": "730410",
    "label": "Bontocini",
    "value": "Bontocini"
  },
  {
    "id": "7304102011",
    "district_id": "730410",
    "label": "Janetallasa",
    "value": "Janetallasa"
  },
  {
    "id": "7304102012",
    "district_id": "730410",
    "label": "Ujung Bulu",
    "value": "Ujung Bulu"
  },
  {
    "id": "7304112001",
    "district_id": "730411",
    "label": "Pao",
    "value": "Pao"
  },
  {
    "id": "7304112002",
    "district_id": "730411",
    "label": "Bontorappo",
    "value": "Bontorappo"
  },
  {
    "id": "7304112003",
    "district_id": "730411",
    "label": "Tarowang",
    "value": "Tarowang"
  },
  {
    "id": "7304112004",
    "district_id": "730411",
    "label": "Allu Tarowang",
    "value": "Allu Tarowang"
  },
  {
    "id": "7304112005",
    "district_id": "730411",
    "label": "Balangbaru",
    "value": "Balangbaru"
  },
  {
    "id": "7304112006",
    "district_id": "730411",
    "label": "Balangloe Tarowang",
    "value": "Balangloe Tarowang"
  },
  {
    "id": "7304112007",
    "district_id": "730411",
    "label": "Bonto Ujung",
    "value": "Bonto Ujung"
  },
  {
    "id": "7304112008",
    "district_id": "730411",
    "label": "Tino",
    "value": "Tino"
  },
  {
    "id": "7305011001",
    "district_id": "730501",
    "label": "Takalar",
    "value": "Takalar"
  },
  {
    "id": "7305012003",
    "district_id": "730501",
    "label": "Patani",
    "value": "Patani"
  },
  {
    "id": "7305012009",
    "district_id": "730501",
    "label": "Soreang",
    "value": "Soreang"
  },
  {
    "id": "7305012010",
    "district_id": "730501",
    "label": "Pa'batangang",
    "value": "Pa'batangang"
  },
  {
    "id": "7305021002",
    "district_id": "730502",
    "label": "Mangadu",
    "value": "Mangadu"
  },
  {
    "id": "7305022001",
    "district_id": "730502",
    "label": "Lengkese",
    "value": "Lengkese"
  },
  {
    "id": "7305022003",
    "district_id": "730502",
    "label": "Topejawa",
    "value": "Topejawa"
  },
  {
    "id": "7305022004",
    "district_id": "730502",
    "label": "Lakatong",
    "value": "Lakatong"
  },
  {
    "id": "7305022005",
    "district_id": "730502",
    "label": "Laikang",
    "value": "Laikang"
  },
  {
    "id": "7305022006",
    "district_id": "730502",
    "label": "Panyangkalang",
    "value": "Panyangkalang"
  },
  {
    "id": "7305022007",
    "district_id": "730502",
    "label": "Banggae",
    "value": "Banggae"
  },
  {
    "id": "7305022008",
    "district_id": "730502",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7305022009",
    "district_id": "730502",
    "label": "Bontoparang",
    "value": "Bontoparang"
  },
  {
    "id": "7305022010",
    "district_id": "730502",
    "label": "Pattopakang",
    "value": "Pattopakang"
  },
  {
    "id": "7305022011",
    "district_id": "730502",
    "label": "Punaga",
    "value": "Punaga"
  },
  {
    "id": "7305022012",
    "district_id": "730502",
    "label": "Cikoang",
    "value": "Cikoang"
  },
  {
    "id": "7305031001",
    "district_id": "730503",
    "label": "Pa'bundukang",
    "value": "Pa'bundukang"
  },
  {
    "id": "7305031002",
    "district_id": "730503",
    "label": "Pattene",
    "value": "Pattene"
  },
  {
    "id": "7305031003",
    "district_id": "730503",
    "label": "Bontokadatto",
    "value": "Bontokadatto"
  },
  {
    "id": "7305031004",
    "district_id": "730503",
    "label": "Canrego",
    "value": "Canrego"
  },
  {
    "id": "7305031005",
    "district_id": "730503",
    "label": "Bulukunyi",
    "value": "Bulukunyi"
  },
  {
    "id": "7305031010",
    "district_id": "730503",
    "label": "Rajaya",
    "value": "Rajaya"
  },
  {
    "id": "7305032006",
    "district_id": "730503",
    "label": "Moncongkomba",
    "value": "Moncongkomba"
  },
  {
    "id": "7305032007",
    "district_id": "730503",
    "label": "Cakura",
    "value": "Cakura"
  },
  {
    "id": "7305032008",
    "district_id": "730503",
    "label": "Lantang",
    "value": "Lantang"
  },
  {
    "id": "7305032009",
    "district_id": "730503",
    "label": "Su'rulangi",
    "value": "Su'rulangi"
  },
  {
    "id": "7305041001",
    "district_id": "730504",
    "label": "Panrannuangku",
    "value": "Panrannuangku"
  },
  {
    "id": "7305041002",
    "district_id": "730504",
    "label": "Manongkoki",
    "value": "Manongkoki"
  },
  {
    "id": "7305041003",
    "district_id": "730504",
    "label": "Malewang",
    "value": "Malewang"
  },
  {
    "id": "7305041004",
    "district_id": "730504",
    "label": "Palleko",
    "value": "Palleko"
  },
  {
    "id": "7305041005",
    "district_id": "730504",
    "label": "Mattompodale",
    "value": "Mattompodale"
  },
  {
    "id": "7305041006",
    "district_id": "730504",
    "label": "Parang Luara",
    "value": "Parang Luara"
  },
  {
    "id": "7305042007",
    "district_id": "730504",
    "label": "Lassang",
    "value": "Lassang"
  },
  {
    "id": "7305042008",
    "district_id": "730504",
    "label": "Kampung Beru",
    "value": "Kampung Beru"
  },
  {
    "id": "7305042009",
    "district_id": "730504",
    "label": "Pa'rappunganta",
    "value": "Pa'rappunganta"
  },
  {
    "id": "7305042010",
    "district_id": "730504",
    "label": "Towata",
    "value": "Towata"
  },
  {
    "id": "7305042011",
    "district_id": "730504",
    "label": "Barugaya",
    "value": "Barugaya"
  },
  {
    "id": "7305042012",
    "district_id": "730504",
    "label": "Ko'mara",
    "value": "Ko'mara"
  },
  {
    "id": "7305042013",
    "district_id": "730504",
    "label": "Timbuseng",
    "value": "Timbuseng"
  },
  {
    "id": "7305042014",
    "district_id": "730504",
    "label": "Massamaturu",
    "value": "Massamaturu"
  },
  {
    "id": "7305042015",
    "district_id": "730504",
    "label": "Parang Baddo",
    "value": "Parang Baddo"
  },
  {
    "id": "7305042016",
    "district_id": "730504",
    "label": "Lassang Barat",
    "value": "Lassang Barat"
  },
  {
    "id": "7305042017",
    "district_id": "730504",
    "label": "Balangtanaya",
    "value": "Balangtanaya"
  },
  {
    "id": "7305042018",
    "district_id": "730504",
    "label": "Kale Ko'mara",
    "value": "Kale Ko'mara"
  },
  {
    "id": "7305052006",
    "district_id": "730505",
    "label": "Bonto Kanang",
    "value": "Bonto Kanang"
  },
  {
    "id": "7305052007",
    "district_id": "730505",
    "label": "Bontomarannu",
    "value": "Bontomarannu"
  },
  {
    "id": "7305052008",
    "district_id": "730505",
    "label": "Mangindara",
    "value": "Mangindara"
  },
  {
    "id": "7305052009",
    "district_id": "730505",
    "label": "Bontokassi",
    "value": "Bontokassi"
  },
  {
    "id": "7305052011",
    "district_id": "730505",
    "label": "Bentang",
    "value": "Bentang"
  },
  {
    "id": "7305052012",
    "district_id": "730505",
    "label": "Barangmamase",
    "value": "Barangmamase"
  },
  {
    "id": "7305052013",
    "district_id": "730505",
    "label": "Sawakong",
    "value": "Sawakong"
  },
  {
    "id": "7305052015",
    "district_id": "730505",
    "label": "Popo",
    "value": "Popo"
  },
  {
    "id": "7305052016",
    "district_id": "730505",
    "label": "Tarowang",
    "value": "Tarowang"
  },
  {
    "id": "7305052017",
    "district_id": "730505",
    "label": "Kalukubodo",
    "value": "Kalukubodo"
  },
  {
    "id": "7305052018",
    "district_id": "730505",
    "label": "Kadatong",
    "value": "Kadatong"
  },
  {
    "id": "7305052019",
    "district_id": "730505",
    "label": "Kale Bentang",
    "value": "Kale Bentang"
  },
  {
    "id": "7305061004",
    "district_id": "730506",
    "label": "Bontolebang",
    "value": "Bontolebang"
  },
  {
    "id": "7305062001",
    "district_id": "730506",
    "label": "Pakkabba",
    "value": "Pakkabba"
  },
  {
    "id": "7305062002",
    "district_id": "730506",
    "label": "Bontolanra",
    "value": "Bontolanra"
  },
  {
    "id": "7305062003",
    "district_id": "730506",
    "label": "Aeng Batu Batu",
    "value": "Aeng Batu Batu"
  },
  {
    "id": "7305062005",
    "district_id": "730506",
    "label": "Tamalate",
    "value": "Tamalate"
  },
  {
    "id": "7305062006",
    "district_id": "730506",
    "label": "Tamasaju",
    "value": "Tamasaju"
  },
  {
    "id": "7305062007",
    "district_id": "730506",
    "label": "Bontosunggu",
    "value": "Bontosunggu"
  },
  {
    "id": "7305062010",
    "district_id": "730506",
    "label": "Aeng Towa",
    "value": "Aeng Towa"
  },
  {
    "id": "7305062011",
    "district_id": "730506",
    "label": "Bontokaddopepe",
    "value": "Bontokaddopepe"
  },
  {
    "id": "7305062012",
    "district_id": "730506",
    "label": "Sampulungan",
    "value": "Sampulungan"
  },
  {
    "id": "7305071001",
    "district_id": "730507",
    "label": "Pattallassang",
    "value": "Pattallassang"
  },
  {
    "id": "7305071002",
    "district_id": "730507",
    "label": "Pallantikang",
    "value": "Pallantikang"
  },
  {
    "id": "7305071003",
    "district_id": "730507",
    "label": "Sombabella",
    "value": "Sombabella"
  },
  {
    "id": "7305071004",
    "district_id": "730507",
    "label": "Kalabbirang",
    "value": "Kalabbirang"
  },
  {
    "id": "7305071005",
    "district_id": "730507",
    "label": "Mardekaya",
    "value": "Mardekaya"
  },
  {
    "id": "7305071006",
    "district_id": "730507",
    "label": "Pappa",
    "value": "Pappa"
  },
  {
    "id": "7305071007",
    "district_id": "730507",
    "label": "Bajeng",
    "value": "Bajeng"
  },
  {
    "id": "7305071008",
    "district_id": "730507",
    "label": "Sabintang",
    "value": "Sabintang"
  },
  {
    "id": "7305071009",
    "district_id": "730507",
    "label": "Salaka",
    "value": "Salaka"
  },
  {
    "id": "7305082001",
    "district_id": "730508",
    "label": "Laguruda",
    "value": "Laguruda"
  },
  {
    "id": "7305082002",
    "district_id": "730508",
    "label": "Banyuanyara",
    "value": "Banyuanyara"
  },
  {
    "id": "7305082003",
    "district_id": "730508",
    "label": "Sanrobone",
    "value": "Sanrobone"
  },
  {
    "id": "7305082004",
    "district_id": "730508",
    "label": "Paddinging",
    "value": "Paddinging"
  },
  {
    "id": "7305082005",
    "district_id": "730508",
    "label": "Ujung Baji",
    "value": "Ujung Baji"
  },
  {
    "id": "7305082006",
    "district_id": "730508",
    "label": "Tonasa",
    "value": "Tonasa"
  },
  {
    "id": "7305092001",
    "district_id": "730509",
    "label": "Galesong Baru",
    "value": "Galesong Baru"
  },
  {
    "id": "7305092002",
    "district_id": "730509",
    "label": "Galesong Kota",
    "value": "Galesong Kota"
  },
  {
    "id": "7305092003",
    "district_id": "730509",
    "label": "Bontoloe",
    "value": "Bontoloe"
  },
  {
    "id": "7305092004",
    "district_id": "730509",
    "label": "Boddia",
    "value": "Boddia"
  },
  {
    "id": "7305092005",
    "district_id": "730509",
    "label": "Bontomangape",
    "value": "Bontomangape"
  },
  {
    "id": "7305092006",
    "district_id": "730509",
    "label": "Parangmata",
    "value": "Parangmata"
  },
  {
    "id": "7305092007",
    "district_id": "730509",
    "label": "Parambambe",
    "value": "Parambambe"
  },
  {
    "id": "7305092008",
    "district_id": "730509",
    "label": "Pa'rasangang Beru",
    "value": "Pa'rasangang Beru"
  },
  {
    "id": "7305092009",
    "district_id": "730509",
    "label": "Pa'lalakkang",
    "value": "Pa'lalakkang"
  },
  {
    "id": "7305092010",
    "district_id": "730509",
    "label": "Pattinoang",
    "value": "Pattinoang"
  },
  {
    "id": "7305092011",
    "district_id": "730509",
    "label": "Kalenna Bontongape",
    "value": "Kalenna Bontongape"
  },
  {
    "id": "7305092012",
    "district_id": "730509",
    "label": "Kalukuang",
    "value": "Kalukuang"
  },
  {
    "id": "7305092013",
    "district_id": "730509",
    "label": "Mappakalompo",
    "value": "Mappakalompo"
  },
  {
    "id": "7305092014",
    "district_id": "730509",
    "label": "Campagaya",
    "value": "Campagaya"
  },
  {
    "id": "7305102001",
    "district_id": "730510",
    "label": "Tompotana",
    "value": "Tompotana"
  },
  {
    "id": "7305102002",
    "district_id": "730510",
    "label": "Mattiro Baji",
    "value": "Mattiro Baji"
  },
  {
    "id": "7305102003",
    "district_id": "730510",
    "label": "Rewataya",
    "value": "Rewataya"
  },
  {
    "id": "7305102004",
    "district_id": "730510",
    "label": "Balangdatu",
    "value": "Balangdatu"
  },
  {
    "id": "7305102005",
    "district_id": "730510",
    "label": "Maccini Baji",
    "value": "Maccini Baji"
  },
  {
    "id": "7306011001",
    "district_id": "730601",
    "label": "Bontonompo",
    "value": "Bontonompo"
  },
  {
    "id": "7306011002",
    "district_id": "730601",
    "label": "Tamallayang",
    "value": "Tamallayang"
  },
  {
    "id": "7306011003",
    "district_id": "730601",
    "label": "Kalaserena",
    "value": "Kalaserena"
  },
  {
    "id": "7306012004",
    "district_id": "730601",
    "label": "Bontolangkasa Utara",
    "value": "Bontolangkasa Utara"
  },
  {
    "id": "7306012005",
    "district_id": "730601",
    "label": "Bontolangkasa Selatan",
    "value": "Bontolangkasa Selatan"
  },
  {
    "id": "7306012006",
    "district_id": "730601",
    "label": "Barembeng",
    "value": "Barembeng"
  },
  {
    "id": "7306012015",
    "district_id": "730601",
    "label": "Manjapai",
    "value": "Manjapai"
  },
  {
    "id": "7306012016",
    "district_id": "730601",
    "label": "Bontobiraeng",
    "value": "Bontobiraeng"
  },
  {
    "id": "7306012017",
    "district_id": "730601",
    "label": "Romanglasa",
    "value": "Romanglasa"
  },
  {
    "id": "7306012018",
    "district_id": "730601",
    "label": "Katangka",
    "value": "Katangka"
  },
  {
    "id": "7306012019",
    "district_id": "730601",
    "label": "Bulogading",
    "value": "Bulogading"
  },
  {
    "id": "7306012020",
    "district_id": "730601",
    "label": "Bategulung",
    "value": "Bategulung"
  },
  {
    "id": "7306012021",
    "district_id": "730601",
    "label": "Kalabarembeng",
    "value": "Kalabarembeng"
  },
  {
    "id": "7306012022",
    "district_id": "730601",
    "label": "Bontobiraeng Selatan",
    "value": "Bontobiraeng Selatan"
  },
  {
    "id": "7306021001",
    "district_id": "730602",
    "label": "Kalebajeng",
    "value": "Kalebajeng"
  },
  {
    "id": "7306021002",
    "district_id": "730602",
    "label": "Limbung",
    "value": "Limbung"
  },
  {
    "id": "7306021017",
    "district_id": "730602",
    "label": "Tubajeng",
    "value": "Tubajeng"
  },
  {
    "id": "7306021018",
    "district_id": "730602",
    "label": "Mata Allo",
    "value": "Mata Allo"
  },
  {
    "id": "7306022003",
    "district_id": "730602",
    "label": "Bontosunggu",
    "value": "Bontosunggu"
  },
  {
    "id": "7306022006",
    "district_id": "730602",
    "label": "Maccinibaji",
    "value": "Maccinibaji"
  },
  {
    "id": "7306022007",
    "district_id": "730602",
    "label": "Pa'bentengang",
    "value": "Pa'bentengang"
  },
  {
    "id": "7306022009",
    "district_id": "730602",
    "label": "Tangkebajeng",
    "value": "Tangkebajeng"
  },
  {
    "id": "7306022010",
    "district_id": "730602",
    "label": "Maradekaya",
    "value": "Maradekaya"
  },
  {
    "id": "7306022011",
    "district_id": "730602",
    "label": "Panciro",
    "value": "Panciro"
  },
  {
    "id": "7306022012",
    "district_id": "730602",
    "label": "Lempangang",
    "value": "Lempangang"
  },
  {
    "id": "7306022013",
    "district_id": "730602",
    "label": "Bone",
    "value": "Bone"
  },
  {
    "id": "7306022015",
    "district_id": "730602",
    "label": "Pannyangkalang",
    "value": "Pannyangkalang"
  },
  {
    "id": "7306022019",
    "district_id": "730602",
    "label": "Paraikatte",
    "value": "Paraikatte"
  },
  {
    "id": "7306031001",
    "district_id": "730603",
    "label": "Malakaji",
    "value": "Malakaji"
  },
  {
    "id": "7306031002",
    "district_id": "730603",
    "label": "Cikoro",
    "value": "Cikoro"
  },
  {
    "id": "7306032003",
    "district_id": "730603",
    "label": "Garing",
    "value": "Garing"
  },
  {
    "id": "7306032004",
    "district_id": "730603",
    "label": "Datara",
    "value": "Datara"
  },
  {
    "id": "7306032005",
    "district_id": "730603",
    "label": "Rappolemba",
    "value": "Rappolemba"
  },
  {
    "id": "7306032006",
    "district_id": "730603",
    "label": "Rappoala",
    "value": "Rappoala"
  },
  {
    "id": "7306032007",
    "district_id": "730603",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7306032008",
    "district_id": "730603",
    "label": "Bontobuddung",
    "value": "Bontobuddung"
  },
  {
    "id": "7306041001",
    "district_id": "730604",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7306041002",
    "district_id": "730604",
    "label": "Buluttana",
    "value": "Buluttana"
  },
  {
    "id": "7306041003",
    "district_id": "730604",
    "label": "Gantarang",
    "value": "Gantarang"
  },
  {
    "id": "7306041010",
    "district_id": "730604",
    "label": "Pattapang",
    "value": "Pattapang"
  },
  {
    "id": "7306041011",
    "district_id": "730604",
    "label": "Bonto Lerung",
    "value": "Bonto Lerung"
  },
  {
    "id": "7306041012",
    "district_id": "730604",
    "label": "Garassi",
    "value": "Garassi"
  },
  {
    "id": "7306042004",
    "district_id": "730604",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "7306051001",
    "district_id": "730605",
    "label": "Lanna",
    "value": "Lanna"
  },
  {
    "id": "7306051002",
    "district_id": "730605",
    "label": "Bontoparang",
    "value": "Bontoparang"
  },
  {
    "id": "7306052006",
    "district_id": "730605",
    "label": "Lonjoboko",
    "value": "Lonjoboko"
  },
  {
    "id": "7306052008",
    "district_id": "730605",
    "label": "Borisallo",
    "value": "Borisallo"
  },
  {
    "id": "7306052009",
    "district_id": "730605",
    "label": "Belapunranga",
    "value": "Belapunranga"
  },
  {
    "id": "7306052010",
    "district_id": "730605",
    "label": "Bontokassi",
    "value": "Bontokassi"
  },
  {
    "id": "7306052011",
    "district_id": "730605",
    "label": "Belabori",
    "value": "Belabori"
  },
  {
    "id": "7306061001",
    "district_id": "730606",
    "label": "Borongloe",
    "value": "Borongloe"
  },
  {
    "id": "7306061002",
    "district_id": "730606",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7306061012",
    "district_id": "730606",
    "label": "Romang Lompoa",
    "value": "Romang Lompoa"
  },
  {
    "id": "7306062003",
    "district_id": "730606",
    "label": "Pakatto",
    "value": "Pakatto"
  },
  {
    "id": "7306062004",
    "district_id": "730606",
    "label": "Nirannuang",
    "value": "Nirannuang"
  },
  {
    "id": "7306062005",
    "district_id": "730606",
    "label": "Romangloe",
    "value": "Romangloe"
  },
  {
    "id": "7306062006",
    "district_id": "730606",
    "label": "Sokkolia",
    "value": "Sokkolia"
  },
  {
    "id": "7306062013",
    "district_id": "730606",
    "label": "Mata Allo",
    "value": "Mata Allo"
  },
  {
    "id": "7306062014",
    "district_id": "730606",
    "label": "Bili-bili",
    "value": "Bili-bili"
  },
  {
    "id": "7306071001",
    "district_id": "730607",
    "label": "Tetebatu",
    "value": "Tetebatu"
  },
  {
    "id": "7306071002",
    "district_id": "730607",
    "label": "Pangkabinanga",
    "value": "Pangkabinanga"
  },
  {
    "id": "7306071003",
    "district_id": "730607",
    "label": "Parangbanoa",
    "value": "Parangbanoa"
  },
  {
    "id": "7306071015",
    "district_id": "730607",
    "label": "Mangalli",
    "value": "Mangalli"
  },
  {
    "id": "7306072004",
    "district_id": "730607",
    "label": "Bontoala",
    "value": "Bontoala"
  },
  {
    "id": "7306072005",
    "district_id": "730607",
    "label": "Pallangga",
    "value": "Pallangga"
  },
  {
    "id": "7306072006",
    "district_id": "730607",
    "label": "Julubori",
    "value": "Julubori"
  },
  {
    "id": "7306072007",
    "district_id": "730607",
    "label": "Kampili",
    "value": "Kampili"
  },
  {
    "id": "7306072008",
    "district_id": "730607",
    "label": "Panakkukang",
    "value": "Panakkukang"
  },
  {
    "id": "7306072009",
    "district_id": "730607",
    "label": "Bontoramba",
    "value": "Bontoramba"
  },
  {
    "id": "7306072010",
    "district_id": "730607",
    "label": "Jene'tallasa",
    "value": "Jene'tallasa"
  },
  {
    "id": "7306072011",
    "district_id": "730607",
    "label": "Julukanaya",
    "value": "Julukanaya"
  },
  {
    "id": "7306072012",
    "district_id": "730607",
    "label": "Julupa'mai",
    "value": "Julupa'mai"
  },
  {
    "id": "7306072013",
    "district_id": "730607",
    "label": "Bungaejaya",
    "value": "Bungaejaya"
  },
  {
    "id": "7306072014",
    "district_id": "730607",
    "label": "Toddotoa",
    "value": "Toddotoa"
  },
  {
    "id": "7306072016",
    "district_id": "730607",
    "label": "Taeng",
    "value": "Taeng"
  },
  {
    "id": "7306081001",
    "district_id": "730608",
    "label": "Sungguminasa",
    "value": "Sungguminasa"
  },
  {
    "id": "7306081002",
    "district_id": "730608",
    "label": "Bonto Bontoa",
    "value": "Bonto Bontoa"
  },
  {
    "id": "7306081003",
    "district_id": "730608",
    "label": "Batangkaluku",
    "value": "Batangkaluku"
  },
  {
    "id": "7306081004",
    "district_id": "730608",
    "label": "Tompobalang",
    "value": "Tompobalang"
  },
  {
    "id": "7306081005",
    "district_id": "730608",
    "label": "Samata",
    "value": "Samata"
  },
  {
    "id": "7306081006",
    "district_id": "730608",
    "label": "Katangka",
    "value": "Katangka"
  },
  {
    "id": "7306081007",
    "district_id": "730608",
    "label": "Pandang Pandang",
    "value": "Pandang Pandang"
  },
  {
    "id": "7306081008",
    "district_id": "730608",
    "label": "Tombolo",
    "value": "Tombolo"
  },
  {
    "id": "7306081009",
    "district_id": "730608",
    "label": "Kalegowa",
    "value": "Kalegowa"
  },
  {
    "id": "7306081010",
    "district_id": "730608",
    "label": "Romangpolong",
    "value": "Romangpolong"
  },
  {
    "id": "7306081011",
    "district_id": "730608",
    "label": "Paccinongang",
    "value": "Paccinongang"
  },
  {
    "id": "7306081012",
    "district_id": "730608",
    "label": "Tamarunang",
    "value": "Tamarunang"
  },
  {
    "id": "7306081013",
    "district_id": "730608",
    "label": "Bontoramba",
    "value": "Bontoramba"
  },
  {
    "id": "7306081014",
    "district_id": "730608",
    "label": "Mawang",
    "value": "Mawang"
  },
  {
    "id": "7306091001",
    "district_id": "730609",
    "label": "Sapaya",
    "value": "Sapaya"
  },
  {
    "id": "7306091010",
    "district_id": "730609",
    "label": "Je'nebatu",
    "value": "Je'nebatu"
  },
  {
    "id": "7306092002",
    "district_id": "730609",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7306092003",
    "district_id": "730609",
    "label": "Buakkang",
    "value": "Buakkang"
  },
  {
    "id": "7306092004",
    "district_id": "730609",
    "label": "Bissoloro",
    "value": "Bissoloro"
  },
  {
    "id": "7306092011",
    "district_id": "730609",
    "label": "Rannaloe",
    "value": "Rannaloe"
  },
  {
    "id": "7306092012",
    "district_id": "730609",
    "label": "Mangempang",
    "value": "Mangempang"
  },
  {
    "id": "7306101001",
    "district_id": "730610",
    "label": "Tamaona",
    "value": "Tamaona"
  },
  {
    "id": "7306102002",
    "district_id": "730610",
    "label": "Ta'binjai",
    "value": "Ta'binjai"
  },
  {
    "id": "7306102003",
    "district_id": "730610",
    "label": "Erelembang",
    "value": "Erelembang"
  },
  {
    "id": "7306102004",
    "district_id": "730610",
    "label": "Kanreapia",
    "value": "Kanreapia"
  },
  {
    "id": "7306102005",
    "district_id": "730610",
    "label": "Pao",
    "value": "Pao"
  },
  {
    "id": "7306102006",
    "district_id": "730610",
    "label": "Balassuka",
    "value": "Balassuka"
  },
  {
    "id": "7306102007",
    "district_id": "730610",
    "label": "Mamampang",
    "value": "Mamampang"
  },
  {
    "id": "7306102008",
    "district_id": "730610",
    "label": "Tonasa",
    "value": "Tonasa"
  },
  {
    "id": "7306102009",
    "district_id": "730610",
    "label": "Bolaromang",
    "value": "Bolaromang"
  },
  {
    "id": "7306111002",
    "district_id": "730611",
    "label": "Tonrorita",
    "value": "Tonrorita"
  },
  {
    "id": "7306111004",
    "district_id": "730611",
    "label": "Lauwa",
    "value": "Lauwa"
  },
  {
    "id": "7306112001",
    "district_id": "730611",
    "label": "Taring",
    "value": "Taring"
  },
  {
    "id": "7306112003",
    "district_id": "730611",
    "label": "Pencong",
    "value": "Pencong"
  },
  {
    "id": "7306112005",
    "district_id": "730611",
    "label": "Berutallasa",
    "value": "Berutallasa"
  },
  {
    "id": "7306112006",
    "district_id": "730611",
    "label": "Parangloe",
    "value": "Parangloe"
  },
  {
    "id": "7306112007",
    "district_id": "730611",
    "label": "Baturappe",
    "value": "Baturappe"
  },
  {
    "id": "7306112008",
    "district_id": "730611",
    "label": "Batumalonro",
    "value": "Batumalonro"
  },
  {
    "id": "7306112009",
    "district_id": "730611",
    "label": "Borimasunggu",
    "value": "Borimasunggu"
  },
  {
    "id": "7306112010",
    "district_id": "730611",
    "label": "Lembangloe",
    "value": "Lembangloe"
  },
  {
    "id": "7306112011",
    "district_id": "730611",
    "label": "Julukanaya",
    "value": "Julukanaya"
  },
  {
    "id": "7306121001",
    "district_id": "730612",
    "label": "Benteng Somba Opu",
    "value": "Benteng Somba Opu"
  },
  {
    "id": "7306121005",
    "district_id": "730612",
    "label": "Lembang Parang",
    "value": "Lembang Parang"
  },
  {
    "id": "7306122002",
    "district_id": "730612",
    "label": "Kanjilo",
    "value": "Kanjilo"
  },
  {
    "id": "7306122003",
    "district_id": "730612",
    "label": "Moncobalang",
    "value": "Moncobalang"
  },
  {
    "id": "7306122004",
    "district_id": "730612",
    "label": "Tinggimae",
    "value": "Tinggimae"
  },
  {
    "id": "7306122006",
    "district_id": "730612",
    "label": "Biringala",
    "value": "Biringala"
  },
  {
    "id": "7306122007",
    "district_id": "730612",
    "label": "Tamannyeleng",
    "value": "Tamannyeleng"
  },
  {
    "id": "7306132001",
    "district_id": "730613",
    "label": "Timbuseng",
    "value": "Timbuseng"
  },
  {
    "id": "7306132002",
    "district_id": "730613",
    "label": "Pallantikang",
    "value": "Pallantikang"
  },
  {
    "id": "7306132003",
    "district_id": "730613",
    "label": "Sunggumanai",
    "value": "Sunggumanai"
  },
  {
    "id": "7306132004",
    "district_id": "730613",
    "label": "Paccellekang",
    "value": "Paccellekang"
  },
  {
    "id": "7306132005",
    "district_id": "730613",
    "label": "Pattallassang",
    "value": "Pattallassang"
  },
  {
    "id": "7306132006",
    "district_id": "730613",
    "label": "Je'nemadinging",
    "value": "Je'nemadinging"
  },
  {
    "id": "7306132007",
    "district_id": "730613",
    "label": "Panaikang",
    "value": "Panaikang"
  },
  {
    "id": "7306132008",
    "district_id": "730613",
    "label": "Borongpala'la",
    "value": "Borongpala'la"
  },
  {
    "id": "7306142001",
    "district_id": "730614",
    "label": "Manuju",
    "value": "Manuju"
  },
  {
    "id": "7306142002",
    "district_id": "730614",
    "label": "Pattallikang",
    "value": "Pattallikang"
  },
  {
    "id": "7306142003",
    "district_id": "730614",
    "label": "Tamalatea",
    "value": "Tamalatea"
  },
  {
    "id": "7306142004",
    "district_id": "730614",
    "label": "Moncongloe",
    "value": "Moncongloe"
  },
  {
    "id": "7306142005",
    "district_id": "730614",
    "label": "Bilalang",
    "value": "Bilalang"
  },
  {
    "id": "7306142006",
    "district_id": "730614",
    "label": "Tassese",
    "value": "Tassese"
  },
  {
    "id": "7306142007",
    "district_id": "730614",
    "label": "Tana Karaeng",
    "value": "Tana Karaeng"
  },
  {
    "id": "7306152001",
    "district_id": "730615",
    "label": "Julumate'ne",
    "value": "Julumate'ne"
  },
  {
    "id": "7306152002",
    "district_id": "730615",
    "label": "Bontolempangang",
    "value": "Bontolempangang"
  },
  {
    "id": "7306152003",
    "district_id": "730615",
    "label": "Lassa-Lassa",
    "value": "Lassa-Lassa"
  },
  {
    "id": "7306152004",
    "district_id": "730615",
    "label": "Bontoloe",
    "value": "Bontoloe"
  },
  {
    "id": "7306152005",
    "district_id": "730615",
    "label": "Pa'ladingang",
    "value": "Pa'ladingang"
  },
  {
    "id": "7306152006",
    "district_id": "730615",
    "label": "Paranglompoa",
    "value": "Paranglompoa"
  },
  {
    "id": "7306152007",
    "district_id": "730615",
    "label": "Bontotangnga",
    "value": "Bontotangnga"
  },
  {
    "id": "7306152008",
    "district_id": "730615",
    "label": "Ulujangang",
    "value": "Ulujangang"
  },
  {
    "id": "7306161009",
    "district_id": "730616",
    "label": "Bontoramba",
    "value": "Bontoramba"
  },
  {
    "id": "7306162001",
    "district_id": "730616",
    "label": "Sengka",
    "value": "Sengka"
  },
  {
    "id": "7306162002",
    "district_id": "730616",
    "label": "Tanrara",
    "value": "Tanrara"
  },
  {
    "id": "7306162003",
    "district_id": "730616",
    "label": "Tindang",
    "value": "Tindang"
  },
  {
    "id": "7306162004",
    "district_id": "730616",
    "label": "Pa'bundukang",
    "value": "Pa'bundukang"
  },
  {
    "id": "7306162005",
    "district_id": "730616",
    "label": "Bontosunggu",
    "value": "Bontosunggu"
  },
  {
    "id": "7306162006",
    "district_id": "730616",
    "label": "Salajangki",
    "value": "Salajangki"
  },
  {
    "id": "7306162007",
    "district_id": "730616",
    "label": "Salajo",
    "value": "Salajo"
  },
  {
    "id": "7306162008",
    "district_id": "730616",
    "label": "Jipang",
    "value": "Jipang"
  },
  {
    "id": "7306172001",
    "district_id": "730617",
    "label": "Majannang",
    "value": "Majannang"
  },
  {
    "id": "7306172002",
    "district_id": "730617",
    "label": "Jonjo",
    "value": "Jonjo"
  },
  {
    "id": "7306172003",
    "district_id": "730617",
    "label": "Manimbahoi",
    "value": "Manimbahoi"
  },
  {
    "id": "7306172004",
    "district_id": "730617",
    "label": "Sicini",
    "value": "Sicini"
  },
  {
    "id": "7306172005",
    "district_id": "730617",
    "label": "Bilanrengi",
    "value": "Bilanrengi"
  },
  {
    "id": "7306182001",
    "district_id": "730618",
    "label": "Borimatangkasa",
    "value": "Borimatangkasa"
  },
  {
    "id": "7306182002",
    "district_id": "730618",
    "label": "Mandalle",
    "value": "Mandalle"
  },
  {
    "id": "7306182003",
    "district_id": "730618",
    "label": "Manjalling",
    "value": "Manjalling"
  },
  {
    "id": "7306182004",
    "district_id": "730618",
    "label": "Gentungang",
    "value": "Gentungang"
  },
  {
    "id": "7306182005",
    "district_id": "730618",
    "label": "Tanabangka",
    "value": "Tanabangka"
  },
  {
    "id": "7306182006",
    "district_id": "730618",
    "label": "Kalemandalle",
    "value": "Kalemandalle"
  },
  {
    "id": "7306182007",
    "district_id": "730618",
    "label": "Bontomanai",
    "value": "Bontomanai"
  },
  {
    "id": "7307011001",
    "district_id": "730701",
    "label": "Tassililu",
    "value": "Tassililu"
  },
  {
    "id": "7307011002",
    "district_id": "730701",
    "label": "Balakia",
    "value": "Balakia"
  },
  {
    "id": "7307012003",
    "district_id": "730701",
    "label": "Gunung Perak",
    "value": "Gunung Perak"
  },
  {
    "id": "7307012004",
    "district_id": "730701",
    "label": "Arabika",
    "value": "Arabika"
  },
  {
    "id": "7307012005",
    "district_id": "730701",
    "label": "Bonto Salama",
    "value": "Bonto Salama"
  },
  {
    "id": "7307012006",
    "district_id": "730701",
    "label": "Turungan Baji",
    "value": "Turungan Baji"
  },
  {
    "id": "7307012007",
    "district_id": "730701",
    "label": "Barania",
    "value": "Barania"
  },
  {
    "id": "7307012008",
    "district_id": "730701",
    "label": "Boto Lempangan",
    "value": "Boto Lempangan"
  },
  {
    "id": "7307012009",
    "district_id": "730701",
    "label": "Terasa",
    "value": "Terasa"
  },
  {
    "id": "7307021001",
    "district_id": "730702",
    "label": "Sangianseri",
    "value": "Sangianseri"
  },
  {
    "id": "7307022002",
    "district_id": "730702",
    "label": "Puncak",
    "value": "Puncak"
  },
  {
    "id": "7307022003",
    "district_id": "730702",
    "label": "Songing",
    "value": "Songing"
  },
  {
    "id": "7307022004",
    "district_id": "730702",
    "label": "Aska",
    "value": "Aska"
  },
  {
    "id": "7307022005",
    "district_id": "730702",
    "label": "Talle",
    "value": "Talle"
  },
  {
    "id": "7307022006",
    "district_id": "730702",
    "label": "Palae",
    "value": "Palae"
  },
  {
    "id": "7307022007",
    "district_id": "730702",
    "label": "Bulu Kamase",
    "value": "Bulu Kamase"
  },
  {
    "id": "7307022008",
    "district_id": "730702",
    "label": "Palangka",
    "value": "Palangka"
  },
  {
    "id": "7307022009",
    "district_id": "730702",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7307022010",
    "district_id": "730702",
    "label": "Gareccing",
    "value": "Gareccing"
  },
  {
    "id": "7307022011",
    "district_id": "730702",
    "label": "Alenangka",
    "value": "Alenangka"
  },
  {
    "id": "7307031001",
    "district_id": "730703",
    "label": "Semataring",
    "value": "Semataring"
  },
  {
    "id": "7307032002",
    "district_id": "730703",
    "label": "Sanjai",
    "value": "Sanjai"
  },
  {
    "id": "7307032003",
    "district_id": "730703",
    "label": "Pattalassang",
    "value": "Pattalassang"
  },
  {
    "id": "7307032004",
    "district_id": "730703",
    "label": "Panaikang",
    "value": "Panaikang"
  },
  {
    "id": "7307032005",
    "district_id": "730703",
    "label": "Saukang",
    "value": "Saukang"
  },
  {
    "id": "7307032006",
    "district_id": "730703",
    "label": "Kampala",
    "value": "Kampala"
  },
  {
    "id": "7307032007",
    "district_id": "730703",
    "label": "Lasiai",
    "value": "Lasiai"
  },
  {
    "id": "7307032008",
    "district_id": "730703",
    "label": "Biroro",
    "value": "Biroro"
  },
  {
    "id": "7307032009",
    "district_id": "730703",
    "label": "Kaloling",
    "value": "Kaloling"
  },
  {
    "id": "7307032010",
    "district_id": "730703",
    "label": "Pasimarannu",
    "value": "Pasimarannu"
  },
  {
    "id": "7307032011",
    "district_id": "730703",
    "label": "Tongke-Tongke",
    "value": "Tongke-Tongke"
  },
  {
    "id": "7307032012",
    "district_id": "730703",
    "label": "Salohe",
    "value": "Salohe"
  },
  {
    "id": "7307032013",
    "district_id": "730703",
    "label": "Bongki Lengkese",
    "value": "Bongki Lengkese"
  },
  {
    "id": "7307041001",
    "district_id": "730704",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7307042002",
    "district_id": "730704",
    "label": "Mattunreng Tellue",
    "value": "Mattunreng Tellue"
  },
  {
    "id": "7307042003",
    "district_id": "730704",
    "label": "Kompang",
    "value": "Kompang"
  },
  {
    "id": "7307042004",
    "district_id": "730704",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7307042005",
    "district_id": "730704",
    "label": "Pattongko",
    "value": "Pattongko"
  },
  {
    "id": "7307042006",
    "district_id": "730704",
    "label": "Saotengnga",
    "value": "Saotengnga"
  },
  {
    "id": "7307042007",
    "district_id": "730704",
    "label": "Saohiring",
    "value": "Saohiring"
  },
  {
    "id": "7307042008",
    "district_id": "730704",
    "label": "Kanrung",
    "value": "Kanrung"
  },
  {
    "id": "7307042009",
    "district_id": "730704",
    "label": "Saotanre",
    "value": "Saotanre"
  },
  {
    "id": "7307042010",
    "district_id": "730704",
    "label": "Bonto",
    "value": "Bonto"
  },
  {
    "id": "7307042011",
    "district_id": "730704",
    "label": "Gantarang",
    "value": "Gantarang"
  },
  {
    "id": "7307051001",
    "district_id": "730705",
    "label": "Biringere",
    "value": "Biringere"
  },
  {
    "id": "7307051002",
    "district_id": "730705",
    "label": "Lamatti Rilau",
    "value": "Lamatti Rilau"
  },
  {
    "id": "7307051003",
    "district_id": "730705",
    "label": "Balangnipa",
    "value": "Balangnipa"
  },
  {
    "id": "7307051004",
    "district_id": "730705",
    "label": "Lappa",
    "value": "Lappa"
  },
  {
    "id": "7307051005",
    "district_id": "730705",
    "label": "Bongki",
    "value": "Bongki"
  },
  {
    "id": "7307051006",
    "district_id": "730705",
    "label": "Alehanuae",
    "value": "Alehanuae"
  },
  {
    "id": "7307062001",
    "district_id": "730706",
    "label": "Lamatti Riattang",
    "value": "Lamatti Riattang"
  },
  {
    "id": "7307062002",
    "district_id": "730706",
    "label": "Lamatti Riaja",
    "value": "Lamatti Riaja"
  },
  {
    "id": "7307062003",
    "district_id": "730706",
    "label": "Bulu Tellue",
    "value": "Bulu Tellue"
  },
  {
    "id": "7307062004",
    "district_id": "730706",
    "label": "Duampanuae",
    "value": "Duampanuae"
  },
  {
    "id": "7307062005",
    "district_id": "730706",
    "label": "Tompobulu",
    "value": "Tompobulu"
  },
  {
    "id": "7307062006",
    "district_id": "730706",
    "label": "Lamatti Riawang",
    "value": "Lamatti Riawang"
  },
  {
    "id": "7307062007",
    "district_id": "730706",
    "label": "Lappacinrana",
    "value": "Lappacinrana"
  },
  {
    "id": "7307071001",
    "district_id": "730707",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7307072002",
    "district_id": "730707",
    "label": "Batu Belerang",
    "value": "Batu Belerang"
  },
  {
    "id": "7307072003",
    "district_id": "730707",
    "label": "Biji Nangka",
    "value": "Biji Nangka"
  },
  {
    "id": "7307072004",
    "district_id": "730707",
    "label": "Barambang",
    "value": "Barambang"
  },
  {
    "id": "7307072005",
    "district_id": "730707",
    "label": "Bonto Sinala",
    "value": "Bonto Sinala"
  },
  {
    "id": "7307072006",
    "district_id": "730707",
    "label": "Kassi Buleng",
    "value": "Kassi Buleng"
  },
  {
    "id": "7307072007",
    "district_id": "730707",
    "label": "Bonto Katute",
    "value": "Bonto Katute"
  },
  {
    "id": "7307072008",
    "district_id": "730707",
    "label": "Bonto Tengnga",
    "value": "Bonto Tengnga"
  },
  {
    "id": "7307081001",
    "district_id": "730708",
    "label": "Mannanti",
    "value": "Mannanti"
  },
  {
    "id": "7307082002",
    "district_id": "730708",
    "label": "Saotengah",
    "value": "Saotengah"
  },
  {
    "id": "7307082003",
    "district_id": "730708",
    "label": "Kalobba",
    "value": "Kalobba"
  },
  {
    "id": "7307082004",
    "district_id": "730708",
    "label": "Tellu Limpoe",
    "value": "Tellu Limpoe"
  },
  {
    "id": "7307082005",
    "district_id": "730708",
    "label": "Massaile",
    "value": "Massaile"
  },
  {
    "id": "7307082006",
    "district_id": "730708",
    "label": "Lembang Lohe",
    "value": "Lembang Lohe"
  },
  {
    "id": "7307082007",
    "district_id": "730708",
    "label": "Pattongko",
    "value": "Pattongko"
  },
  {
    "id": "7307082008",
    "district_id": "730708",
    "label": "Bua",
    "value": "Bua"
  },
  {
    "id": "7307082009",
    "district_id": "730708",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "7307082010",
    "district_id": "730708",
    "label": "Era Baru",
    "value": "Era Baru"
  },
  {
    "id": "7307082011",
    "district_id": "730708",
    "label": "Samaturue",
    "value": "Samaturue"
  },
  {
    "id": "7307092001",
    "district_id": "730709",
    "label": "Pulau Harapan",
    "value": "Pulau Harapan"
  },
  {
    "id": "7307092002",
    "district_id": "730709",
    "label": "Pulau Buhung Pitue",
    "value": "Pulau Buhung Pitue"
  },
  {
    "id": "7307092003",
    "district_id": "730709",
    "label": "Pulau Padaelo",
    "value": "Pulau Padaelo"
  },
  {
    "id": "7307092004",
    "district_id": "730709",
    "label": "Pulau Persatuan",
    "value": "Pulau Persatuan"
  },
  {
    "id": "7308011001",
    "district_id": "730801",
    "label": "Kahu",
    "value": "Kahu"
  },
  {
    "id": "7308012002",
    "district_id": "730801",
    "label": "Watang Cani",
    "value": "Watang Cani"
  },
  {
    "id": "7308012003",
    "district_id": "730801",
    "label": "Pattuku",
    "value": "Pattuku"
  },
  {
    "id": "7308012004",
    "district_id": "730801",
    "label": "Bontojai",
    "value": "Bontojai"
  },
  {
    "id": "7308012005",
    "district_id": "730801",
    "label": "Bana",
    "value": "Bana"
  },
  {
    "id": "7308012006",
    "district_id": "730801",
    "label": "Pammusureng",
    "value": "Pammusureng"
  },
  {
    "id": "7308012007",
    "district_id": "730801",
    "label": "Langi",
    "value": "Langi"
  },
  {
    "id": "7308012008",
    "district_id": "730801",
    "label": "Ere Cinnong",
    "value": "Ere Cinnong"
  },
  {
    "id": "7308012009",
    "district_id": "730801",
    "label": "Bulu Sirua",
    "value": "Bulu Sirua"
  },
  {
    "id": "7308012010",
    "district_id": "730801",
    "label": "Lamoncong",
    "value": "Lamoncong"
  },
  {
    "id": "7308012011",
    "district_id": "730801",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7308021001",
    "district_id": "730802",
    "label": "Palattae",
    "value": "Palattae"
  },
  {
    "id": "7308022002",
    "district_id": "730802",
    "label": "Nusa",
    "value": "Nusa"
  },
  {
    "id": "7308022003",
    "district_id": "730802",
    "label": "Pasaka",
    "value": "Pasaka"
  },
  {
    "id": "7308022004",
    "district_id": "730802",
    "label": "Labuaja",
    "value": "Labuaja"
  },
  {
    "id": "7308022005",
    "district_id": "730802",
    "label": "Cakkela",
    "value": "Cakkela"
  },
  {
    "id": "7308022006",
    "district_id": "730802",
    "label": "Balle",
    "value": "Balle"
  },
  {
    "id": "7308022007",
    "district_id": "730802",
    "label": "Matajang",
    "value": "Matajang"
  },
  {
    "id": "7308022008",
    "district_id": "730802",
    "label": "Cenrana",
    "value": "Cenrana"
  },
  {
    "id": "7308022009",
    "district_id": "730802",
    "label": "Sanrego",
    "value": "Sanrego"
  },
  {
    "id": "7308022010",
    "district_id": "730802",
    "label": "Biru",
    "value": "Biru"
  },
  {
    "id": "7308022011",
    "district_id": "730802",
    "label": "Palakka",
    "value": "Palakka"
  },
  {
    "id": "7308022012",
    "district_id": "730802",
    "label": "Tompong Patu",
    "value": "Tompong Patu"
  },
  {
    "id": "7308022013",
    "district_id": "730802",
    "label": "Hulo",
    "value": "Hulo"
  },
  {
    "id": "7308022014",
    "district_id": "730802",
    "label": "Cammilo",
    "value": "Cammilo"
  },
  {
    "id": "7308022015",
    "district_id": "730802",
    "label": "Carima",
    "value": "Carima"
  },
  {
    "id": "7308022016",
    "district_id": "730802",
    "label": "Mattoanging",
    "value": "Mattoanging"
  },
  {
    "id": "7308022017",
    "district_id": "730802",
    "label": "Arallae",
    "value": "Arallae"
  },
  {
    "id": "7308022018",
    "district_id": "730802",
    "label": "Bonto Padang",
    "value": "Bonto Padang"
  },
  {
    "id": "7308022019",
    "district_id": "730802",
    "label": "Lalepo",
    "value": "Lalepo"
  },
  {
    "id": "7308022020",
    "district_id": "730802",
    "label": "Maggenrang",
    "value": "Maggenrang"
  },
  {
    "id": "7308031001",
    "district_id": "730803",
    "label": "Awang Tangka",
    "value": "Awang Tangka"
  },
  {
    "id": "7308032002",
    "district_id": "730803",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "7308032003",
    "district_id": "730803",
    "label": "Abbumpungeng",
    "value": "Abbumpungeng"
  },
  {
    "id": "7308032004",
    "district_id": "730803",
    "label": "Bulu Tanah",
    "value": "Bulu Tanah"
  },
  {
    "id": "7308032005",
    "district_id": "730803",
    "label": "Gona",
    "value": "Gona"
  },
  {
    "id": "7308032006",
    "district_id": "730803",
    "label": "Buareng",
    "value": "Buareng"
  },
  {
    "id": "7308032007",
    "district_id": "730803",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7308032008",
    "district_id": "730803",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7308032009",
    "district_id": "730803",
    "label": "Ancu",
    "value": "Ancu"
  },
  {
    "id": "7308032010",
    "district_id": "730803",
    "label": "Tarasu",
    "value": "Tarasu"
  },
  {
    "id": "7308032011",
    "district_id": "730803",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7308032012",
    "district_id": "730803",
    "label": "Kalero",
    "value": "Kalero"
  },
  {
    "id": "7308032013",
    "district_id": "730803",
    "label": "Lappabosse",
    "value": "Lappabosse"
  },
  {
    "id": "7308032014",
    "district_id": "730803",
    "label": "Waetuo",
    "value": "Waetuo"
  },
  {
    "id": "7308032015",
    "district_id": "730803",
    "label": "Pude",
    "value": "Pude"
  },
  {
    "id": "7308032016",
    "district_id": "730803",
    "label": "Angkue",
    "value": "Angkue"
  },
  {
    "id": "7308032017",
    "district_id": "730803",
    "label": "Massangkae",
    "value": "Massangkae"
  },
  {
    "id": "7308032018",
    "district_id": "730803",
    "label": "Mallahae",
    "value": "Mallahae"
  },
  {
    "id": "7308041001",
    "district_id": "730804",
    "label": "Pancaitana",
    "value": "Pancaitana"
  },
  {
    "id": "7308042002",
    "district_id": "730804",
    "label": "Malimongeng",
    "value": "Malimongeng"
  },
  {
    "id": "7308042003",
    "district_id": "730804",
    "label": "Manera",
    "value": "Manera"
  },
  {
    "id": "7308042004",
    "district_id": "730804",
    "label": "Ulubalang",
    "value": "Ulubalang"
  },
  {
    "id": "7308042005",
    "district_id": "730804",
    "label": "Gattareng",
    "value": "Gattareng"
  },
  {
    "id": "7308042006",
    "district_id": "730804",
    "label": "Bellu",
    "value": "Bellu"
  },
  {
    "id": "7308042007",
    "district_id": "730804",
    "label": "Tebba",
    "value": "Tebba"
  },
  {
    "id": "7308042008",
    "district_id": "730804",
    "label": "Mappatoba",
    "value": "Mappatoba"
  },
  {
    "id": "7308052001",
    "district_id": "730805",
    "label": "Bulu Bulu",
    "value": "Bulu Bulu"
  },
  {
    "id": "7308052002",
    "district_id": "730805",
    "label": "Gareccing",
    "value": "Gareccing"
  },
  {
    "id": "7308052003",
    "district_id": "730805",
    "label": "Biccoing",
    "value": "Biccoing"
  },
  {
    "id": "7308052004",
    "district_id": "730805",
    "label": "Ujunge",
    "value": "Ujunge"
  },
  {
    "id": "7308052005",
    "district_id": "730805",
    "label": "Libureng",
    "value": "Libureng"
  },
  {
    "id": "7308052006",
    "district_id": "730805",
    "label": "Rappa",
    "value": "Rappa"
  },
  {
    "id": "7308052007",
    "district_id": "730805",
    "label": "Bacu",
    "value": "Bacu"
  },
  {
    "id": "7308052008",
    "district_id": "730805",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "7308052009",
    "district_id": "730805",
    "label": "Bone Pute",
    "value": "Bone Pute"
  },
  {
    "id": "7308052010",
    "district_id": "730805",
    "label": "Padatuo",
    "value": "Padatuo"
  },
  {
    "id": "7308052011",
    "district_id": "730805",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7308061001",
    "district_id": "730806",
    "label": "Tanah Batue",
    "value": "Tanah Batue"
  },
  {
    "id": "7308061010",
    "district_id": "730806",
    "label": "Ceppaga",
    "value": "Ceppaga"
  },
  {
    "id": "7308062002",
    "district_id": "730806",
    "label": "Tompo Bulu",
    "value": "Tompo Bulu"
  },
  {
    "id": "7308062003",
    "district_id": "730806",
    "label": "Tappale",
    "value": "Tappale"
  },
  {
    "id": "7308062004",
    "district_id": "730806",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7308062005",
    "district_id": "730806",
    "label": "Pitumpidange",
    "value": "Pitumpidange"
  },
  {
    "id": "7308062006",
    "district_id": "730806",
    "label": "Bune",
    "value": "Bune"
  },
  {
    "id": "7308062007",
    "district_id": "730806",
    "label": "Swadaya",
    "value": "Swadaya"
  },
  {
    "id": "7308062008",
    "district_id": "730806",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "7308062009",
    "district_id": "730806",
    "label": "Mallinrung",
    "value": "Mallinrung"
  },
  {
    "id": "7308062011",
    "district_id": "730806",
    "label": "Baringeng",
    "value": "Baringeng"
  },
  {
    "id": "7308062012",
    "district_id": "730806",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7308062013",
    "district_id": "730806",
    "label": "Wanuawaru",
    "value": "Wanuawaru"
  },
  {
    "id": "7308062014",
    "district_id": "730806",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7308062015",
    "district_id": "730806",
    "label": "Poleonro",
    "value": "Poleonro"
  },
  {
    "id": "7308062016",
    "district_id": "730806",
    "label": "Matirro Bulu",
    "value": "Matirro Bulu"
  },
  {
    "id": "7308062017",
    "district_id": "730806",
    "label": "Matirrodeceng",
    "value": "Matirrodeceng"
  },
  {
    "id": "7308062018",
    "district_id": "730806",
    "label": "Suwa",
    "value": "Suwa"
  },
  {
    "id": "7308062019",
    "district_id": "730806",
    "label": "Laburasseng",
    "value": "Laburasseng"
  },
  {
    "id": "7308062020",
    "district_id": "730806",
    "label": "Ponre Ponre",
    "value": "Ponre Ponre"
  },
  {
    "id": "7308071001",
    "district_id": "730807",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7308072002",
    "district_id": "730807",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7308072003",
    "district_id": "730807",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7308072004",
    "district_id": "730807",
    "label": "Ujung Salangketo",
    "value": "Ujung Salangketo"
  },
  {
    "id": "7308072005",
    "district_id": "730807",
    "label": "Tellu Boccoe",
    "value": "Tellu Boccoe"
  },
  {
    "id": "7308072006",
    "district_id": "730807",
    "label": "Mattampa Walie",
    "value": "Mattampa Walie"
  },
  {
    "id": "7308072007",
    "district_id": "730807",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "7308072008",
    "district_id": "730807",
    "label": "Sumaling",
    "value": "Sumaling"
  },
  {
    "id": "7308072009",
    "district_id": "730807",
    "label": "Tellongeng",
    "value": "Tellongeng"
  },
  {
    "id": "7308072010",
    "district_id": "730807",
    "label": "Data",
    "value": "Data"
  },
  {
    "id": "7308072011",
    "district_id": "730807",
    "label": "Batu Gading",
    "value": "Batu Gading"
  },
  {
    "id": "7308072012",
    "district_id": "730807",
    "label": "Cege",
    "value": "Cege"
  },
  {
    "id": "7308072013",
    "district_id": "730807",
    "label": "Kadai",
    "value": "Kadai"
  },
  {
    "id": "7308072014",
    "district_id": "730807",
    "label": "Lakukang",
    "value": "Lakukang"
  },
  {
    "id": "7308072015",
    "district_id": "730807",
    "label": "Pattiro",
    "value": "Pattiro"
  },
  {
    "id": "7308072016",
    "district_id": "730807",
    "label": "Lapasa",
    "value": "Lapasa"
  },
  {
    "id": "7308072017",
    "district_id": "730807",
    "label": "Karella",
    "value": "Karella"
  },
  {
    "id": "7308072018",
    "district_id": "730807",
    "label": "Lappa Upang",
    "value": "Lappa Upang"
  },
  {
    "id": "7308081001",
    "district_id": "730808",
    "label": "Maroanging",
    "value": "Maroanging"
  },
  {
    "id": "7308082002",
    "district_id": "730808",
    "label": "Pattiro Bajo",
    "value": "Pattiro Bajo"
  },
  {
    "id": "7308082003",
    "district_id": "730808",
    "label": "Massenreng Pulu",
    "value": "Massenreng Pulu"
  },
  {
    "id": "7308082004",
    "district_id": "730808",
    "label": "Tunreng Tellue",
    "value": "Tunreng Tellue"
  },
  {
    "id": "7308082005",
    "district_id": "730808",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7308082006",
    "district_id": "730808",
    "label": "Tadang Palie",
    "value": "Tadang Palie"
  },
  {
    "id": "7308082007",
    "district_id": "730808",
    "label": "Cinnong",
    "value": "Cinnong"
  },
  {
    "id": "7308082008",
    "district_id": "730808",
    "label": "Mallusetasi",
    "value": "Mallusetasi"
  },
  {
    "id": "7308082009",
    "district_id": "730808",
    "label": "Pakkasalo",
    "value": "Pakkasalo"
  },
  {
    "id": "7308082010",
    "district_id": "730808",
    "label": "Pattiro Sompe",
    "value": "Pattiro Sompe"
  },
  {
    "id": "7308082011",
    "district_id": "730808",
    "label": "Ajang Pulu",
    "value": "Ajang Pulu"
  },
  {
    "id": "7308082012",
    "district_id": "730808",
    "label": "Sumpang Minangae",
    "value": "Sumpang Minangae"
  },
  {
    "id": "7308082013",
    "district_id": "730808",
    "label": "Mabbiring",
    "value": "Mabbiring"
  },
  {
    "id": "7308082014",
    "district_id": "730808",
    "label": "Pasaka",
    "value": "Pasaka"
  },
  {
    "id": "7308082015",
    "district_id": "730808",
    "label": "Kalibong",
    "value": "Kalibong"
  },
  {
    "id": "7308082016",
    "district_id": "730808",
    "label": "Pattiro Riolo",
    "value": "Pattiro Riolo"
  },
  {
    "id": "7308082017",
    "district_id": "730808",
    "label": "Letta Tanah",
    "value": "Letta Tanah"
  },
  {
    "id": "7308082018",
    "district_id": "730808",
    "label": "Manajeng",
    "value": "Manajeng"
  },
  {
    "id": "7308082019",
    "district_id": "730808",
    "label": "Bulie",
    "value": "Bulie"
  },
  {
    "id": "7308082020",
    "district_id": "730808",
    "label": "Balieng Toa",
    "value": "Balieng Toa"
  },
  {
    "id": "7308091006",
    "district_id": "730809",
    "label": "Apala",
    "value": "Apala"
  },
  {
    "id": "7308092001",
    "district_id": "730809",
    "label": "Bacu",
    "value": "Bacu"
  },
  {
    "id": "7308092002",
    "district_id": "730809",
    "label": "Lampoko",
    "value": "Lampoko"
  },
  {
    "id": "7308092003",
    "district_id": "730809",
    "label": "Congko",
    "value": "Congko"
  },
  {
    "id": "7308092004",
    "district_id": "730809",
    "label": "Wollangi",
    "value": "Wollangi"
  },
  {
    "id": "7308092005",
    "district_id": "730809",
    "label": "Parippung",
    "value": "Parippung"
  },
  {
    "id": "7308092007",
    "district_id": "730809",
    "label": "Barebbo",
    "value": "Barebbo"
  },
  {
    "id": "7308092008",
    "district_id": "730809",
    "label": "Kampuno",
    "value": "Kampuno"
  },
  {
    "id": "7308092009",
    "district_id": "730809",
    "label": "Kading",
    "value": "Kading"
  },
  {
    "id": "7308092010",
    "district_id": "730809",
    "label": "Kajaolaliddong",
    "value": "Kajaolaliddong"
  },
  {
    "id": "7308092011",
    "district_id": "730809",
    "label": "Samaelo",
    "value": "Samaelo"
  },
  {
    "id": "7308092012",
    "district_id": "730809",
    "label": "Corawali",
    "value": "Corawali"
  },
  {
    "id": "7308092013",
    "district_id": "730809",
    "label": "Talungeng",
    "value": "Talungeng"
  },
  {
    "id": "7308092014",
    "district_id": "730809",
    "label": "Watu",
    "value": "Watu"
  },
  {
    "id": "7308092015",
    "district_id": "730809",
    "label": "Cingkang",
    "value": "Cingkang"
  },
  {
    "id": "7308092016",
    "district_id": "730809",
    "label": "Cinnong",
    "value": "Cinnong"
  },
  {
    "id": "7308092017",
    "district_id": "730809",
    "label": "Cempaniga",
    "value": "Cempaniga"
  },
  {
    "id": "7308092018",
    "district_id": "730809",
    "label": "Sugiale",
    "value": "Sugiale"
  },
  {
    "id": "7308101001",
    "district_id": "730810",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7308102002",
    "district_id": "730810",
    "label": "Arasoe",
    "value": "Arasoe"
  },
  {
    "id": "7308102003",
    "district_id": "730810",
    "label": "Tanete Harapan",
    "value": "Tanete Harapan"
  },
  {
    "id": "7308102004",
    "district_id": "730810",
    "label": "Lompu",
    "value": "Lompu"
  },
  {
    "id": "7308102005",
    "district_id": "730810",
    "label": "Walenreng",
    "value": "Walenreng"
  },
  {
    "id": "7308102006",
    "district_id": "730810",
    "label": "Ajang Pulu",
    "value": "Ajang Pulu"
  },
  {
    "id": "7308102007",
    "district_id": "730810",
    "label": "Padang Loang",
    "value": "Padang Loang"
  },
  {
    "id": "7308102008",
    "district_id": "730810",
    "label": "Abbumpungeng",
    "value": "Abbumpungeng"
  },
  {
    "id": "7308102009",
    "district_id": "730810",
    "label": "Kawerang",
    "value": "Kawerang"
  },
  {
    "id": "7308102010",
    "district_id": "730810",
    "label": "Awo",
    "value": "Awo"
  },
  {
    "id": "7308102011",
    "district_id": "730810",
    "label": "Kanco",
    "value": "Kanco"
  },
  {
    "id": "7308102012",
    "district_id": "730810",
    "label": "Cinennung",
    "value": "Cinennung"
  },
  {
    "id": "7308112001",
    "district_id": "730811",
    "label": "Mappesangka",
    "value": "Mappesangka"
  },
  {
    "id": "7308112002",
    "district_id": "730811",
    "label": "Salebba",
    "value": "Salebba"
  },
  {
    "id": "7308112003",
    "district_id": "730811",
    "label": "Mattampae",
    "value": "Mattampae"
  },
  {
    "id": "7308112004",
    "district_id": "730811",
    "label": "Pattimpa",
    "value": "Pattimpa"
  },
  {
    "id": "7308112005",
    "district_id": "730811",
    "label": "Tellu Boccoe",
    "value": "Tellu Boccoe"
  },
  {
    "id": "7308112006",
    "district_id": "730811",
    "label": "Turu Adae",
    "value": "Turu Adae"
  },
  {
    "id": "7308112007",
    "district_id": "730811",
    "label": "Salampe",
    "value": "Salampe"
  },
  {
    "id": "7308112008",
    "district_id": "730811",
    "label": "Poleonro",
    "value": "Poleonro"
  },
  {
    "id": "7308112009",
    "district_id": "730811",
    "label": "Bolli",
    "value": "Bolli"
  },
  {
    "id": "7308122001",
    "district_id": "730812",
    "label": "Mattampawalie",
    "value": "Mattampawalie"
  },
  {
    "id": "7308122002",
    "district_id": "730812",
    "label": "Lili Riattang",
    "value": "Lili Riattang"
  },
  {
    "id": "7308122003",
    "district_id": "730812",
    "label": "Sengeng Palie",
    "value": "Sengeng Palie"
  },
  {
    "id": "7308122004",
    "district_id": "730812",
    "label": "Tenri Pakkua",
    "value": "Tenri Pakkua"
  },
  {
    "id": "7308122005",
    "district_id": "730812",
    "label": "Patangkai",
    "value": "Patangkai"
  },
  {
    "id": "7308122006",
    "district_id": "730812",
    "label": "Ujung Lamuru",
    "value": "Ujung Lamuru"
  },
  {
    "id": "7308122007",
    "district_id": "730812",
    "label": "Pattuku Limpoe",
    "value": "Pattuku Limpoe"
  },
  {
    "id": "7308122008",
    "district_id": "730812",
    "label": "Waekeccee",
    "value": "Waekeccee"
  },
  {
    "id": "7308122009",
    "district_id": "730812",
    "label": "Tonronge",
    "value": "Tonronge"
  },
  {
    "id": "7308131001",
    "district_id": "730813",
    "label": "Lalebata",
    "value": "Lalebata"
  },
  {
    "id": "7308132002",
    "district_id": "730813",
    "label": "Mattampa Walie",
    "value": "Mattampa Walie"
  },
  {
    "id": "7308132003",
    "district_id": "730813",
    "label": "Poleonro",
    "value": "Poleonro"
  },
  {
    "id": "7308132004",
    "district_id": "730813",
    "label": "Sengeng Palie",
    "value": "Sengeng Palie"
  },
  {
    "id": "7308132005",
    "district_id": "730813",
    "label": "Mattampa Bulu",
    "value": "Mattampa Bulu"
  },
  {
    "id": "7308132006",
    "district_id": "730813",
    "label": "Turu Cinnae",
    "value": "Turu Cinnae"
  },
  {
    "id": "7308132007",
    "district_id": "730813",
    "label": "Seberang",
    "value": "Seberang"
  },
  {
    "id": "7308132008",
    "district_id": "730813",
    "label": "Massenreng pulu",
    "value": "Massenreng pulu"
  },
  {
    "id": "7308132009",
    "district_id": "730813",
    "label": "Mamminasae",
    "value": "Mamminasae"
  },
  {
    "id": "7308132010",
    "district_id": "730813",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7308132011",
    "district_id": "730813",
    "label": "Barugae",
    "value": "Barugae"
  },
  {
    "id": "7308132012",
    "district_id": "730813",
    "label": "Barakkae",
    "value": "Barakkae"
  },
  {
    "id": "7308141001",
    "district_id": "730814",
    "label": "Cinnong",
    "value": "Cinnong"
  },
  {
    "id": "7308142002",
    "district_id": "730814",
    "label": "Lilina Ajangale",
    "value": "Lilina Ajangale"
  },
  {
    "id": "7308142003",
    "district_id": "730814",
    "label": "Cani Sirenreng",
    "value": "Cani Sirenreng"
  },
  {
    "id": "7308142004",
    "district_id": "730814",
    "label": "Ulaweng Cinnong",
    "value": "Ulaweng Cinnong"
  },
  {
    "id": "7308142005",
    "district_id": "730814",
    "label": "Pallawarukka",
    "value": "Pallawarukka"
  },
  {
    "id": "7308142006",
    "district_id": "730814",
    "label": "Jompie",
    "value": "Jompie"
  },
  {
    "id": "7308142007",
    "district_id": "730814",
    "label": "Sappewalie",
    "value": "Sappewalie"
  },
  {
    "id": "7308142008",
    "district_id": "730814",
    "label": "Lamakkaraseng",
    "value": "Lamakkaraseng"
  },
  {
    "id": "7308142009",
    "district_id": "730814",
    "label": "Mula Menree",
    "value": "Mula Menree"
  },
  {
    "id": "7308142010",
    "district_id": "730814",
    "label": "Tea Musu",
    "value": "Tea Musu"
  },
  {
    "id": "7308142011",
    "district_id": "730814",
    "label": "Tea Malala",
    "value": "Tea Malala"
  },
  {
    "id": "7308142012",
    "district_id": "730814",
    "label": "Galung",
    "value": "Galung"
  },
  {
    "id": "7308142013",
    "district_id": "730814",
    "label": "Tadang Palie",
    "value": "Tadang Palie"
  },
  {
    "id": "7308142014",
    "district_id": "730814",
    "label": "Timusu",
    "value": "Timusu"
  },
  {
    "id": "7308142015",
    "district_id": "730814",
    "label": "Manurunge",
    "value": "Manurunge"
  },
  {
    "id": "7308152001",
    "district_id": "730815",
    "label": "Cinenung",
    "value": "Cinenung"
  },
  {
    "id": "7308152002",
    "district_id": "730815",
    "label": "Siame",
    "value": "Siame"
  },
  {
    "id": "7308152003",
    "district_id": "730815",
    "label": "Pasempe",
    "value": "Pasempe"
  },
  {
    "id": "7308152004",
    "district_id": "730815",
    "label": "Ureng",
    "value": "Ureng"
  },
  {
    "id": "7308152005",
    "district_id": "730815",
    "label": "Usa",
    "value": "Usa"
  },
  {
    "id": "7308152006",
    "district_id": "730815",
    "label": "Mico",
    "value": "Mico"
  },
  {
    "id": "7308152007",
    "district_id": "730815",
    "label": "Bainang",
    "value": "Bainang"
  },
  {
    "id": "7308152008",
    "district_id": "730815",
    "label": "Passippo",
    "value": "Passippo"
  },
  {
    "id": "7308152009",
    "district_id": "730815",
    "label": "Tanah Tengnga",
    "value": "Tanah Tengnga"
  },
  {
    "id": "7308152010",
    "district_id": "730815",
    "label": "Tirong",
    "value": "Tirong"
  },
  {
    "id": "7308152011",
    "district_id": "730815",
    "label": "Mattanete Bua",
    "value": "Mattanete Bua"
  },
  {
    "id": "7308152012",
    "district_id": "730815",
    "label": "Maduri",
    "value": "Maduri"
  },
  {
    "id": "7308152013",
    "district_id": "730815",
    "label": "Melle",
    "value": "Melle"
  },
  {
    "id": "7308152014",
    "district_id": "730815",
    "label": "Lemoape",
    "value": "Lemoape"
  },
  {
    "id": "7308152015",
    "district_id": "730815",
    "label": "Panyili",
    "value": "Panyili"
  },
  {
    "id": "7308161001",
    "district_id": "730816",
    "label": "Maccope",
    "value": "Maccope"
  },
  {
    "id": "7308162002",
    "district_id": "730816",
    "label": "Carebbu",
    "value": "Carebbu"
  },
  {
    "id": "7308162003",
    "district_id": "730816",
    "label": "Cumpiga",
    "value": "Cumpiga"
  },
  {
    "id": "7308162004",
    "district_id": "730816",
    "label": "Paccing",
    "value": "Paccing"
  },
  {
    "id": "7308162005",
    "district_id": "730816",
    "label": "Lattekko",
    "value": "Lattekko"
  },
  {
    "id": "7308162006",
    "district_id": "730816",
    "label": "Matuju",
    "value": "Matuju"
  },
  {
    "id": "7308162007",
    "district_id": "730816",
    "label": "Unra",
    "value": "Unra"
  },
  {
    "id": "7308162008",
    "district_id": "730816",
    "label": "Kading",
    "value": "Kading"
  },
  {
    "id": "7308162009",
    "district_id": "730816",
    "label": "Mallari",
    "value": "Mallari"
  },
  {
    "id": "7308162010",
    "district_id": "730816",
    "label": "Kajuara",
    "value": "Kajuara"
  },
  {
    "id": "7308162011",
    "district_id": "730816",
    "label": "Jaling",
    "value": "Jaling"
  },
  {
    "id": "7308162012",
    "district_id": "730816",
    "label": "Bulumpare'e",
    "value": "Bulumpare'e"
  },
  {
    "id": "7308162013",
    "district_id": "730816",
    "label": "Abbanuang",
    "value": "Abbanuang"
  },
  {
    "id": "7308162014",
    "district_id": "730816",
    "label": "Lappoase",
    "value": "Lappoase"
  },
  {
    "id": "7308162015",
    "district_id": "730816",
    "label": "Awolagading",
    "value": "Awolagading"
  },
  {
    "id": "7308162016",
    "district_id": "730816",
    "label": "Carigading",
    "value": "Carigading"
  },
  {
    "id": "7308162017",
    "district_id": "730816",
    "label": "Cakke Bone",
    "value": "Cakke Bone"
  },
  {
    "id": "7308162018",
    "district_id": "730816",
    "label": "Mappalo Ulaweng",
    "value": "Mappalo Ulaweng"
  },
  {
    "id": "7308171001",
    "district_id": "730817",
    "label": "Otting",
    "value": "Otting"
  },
  {
    "id": "7308171002",
    "district_id": "730817",
    "label": "Tokaseng",
    "value": "Tokaseng"
  },
  {
    "id": "7308172003",
    "district_id": "730817",
    "label": "Palongki",
    "value": "Palongki"
  },
  {
    "id": "7308172004",
    "district_id": "730817",
    "label": "Tajong",
    "value": "Tajong"
  },
  {
    "id": "7308172005",
    "district_id": "730817",
    "label": "Ulo",
    "value": "Ulo"
  },
  {
    "id": "7308172006",
    "district_id": "730817",
    "label": "Lanca",
    "value": "Lanca"
  },
  {
    "id": "7308172007",
    "district_id": "730817",
    "label": "Itterung",
    "value": "Itterung"
  },
  {
    "id": "7308172008",
    "district_id": "730817",
    "label": "Mattoanging",
    "value": "Mattoanging"
  },
  {
    "id": "7308172009",
    "district_id": "730817",
    "label": "Lamuru",
    "value": "Lamuru"
  },
  {
    "id": "7308172010",
    "district_id": "730817",
    "label": "Waji",
    "value": "Waji"
  },
  {
    "id": "7308172011",
    "district_id": "730817",
    "label": "Ajjalireng",
    "value": "Ajjalireng"
  },
  {
    "id": "7308172012",
    "district_id": "730817",
    "label": "Sijelling",
    "value": "Sijelling"
  },
  {
    "id": "7308172013",
    "district_id": "730817",
    "label": "Lea",
    "value": "Lea"
  },
  {
    "id": "7308172014",
    "district_id": "730817",
    "label": "Padaidi",
    "value": "Padaidi"
  },
  {
    "id": "7308172015",
    "district_id": "730817",
    "label": "Pongka",
    "value": "Pongka"
  },
  {
    "id": "7308172016",
    "district_id": "730817",
    "label": "Lappae",
    "value": "Lappae"
  },
  {
    "id": "7308172017",
    "district_id": "730817",
    "label": "Patangnga",
    "value": "Patangnga"
  },
  {
    "id": "7308181001",
    "district_id": "730818",
    "label": "Pompanua",
    "value": "Pompanua"
  },
  {
    "id": "7308181002",
    "district_id": "730818",
    "label": "Pompanua Riattang",
    "value": "Pompanua Riattang"
  },
  {
    "id": "7308182003",
    "district_id": "730818",
    "label": "Welado",
    "value": "Welado"
  },
  {
    "id": "7308182004",
    "district_id": "730818",
    "label": "Pinceng Pute",
    "value": "Pinceng Pute"
  },
  {
    "id": "7308182005",
    "district_id": "730818",
    "label": "Opo",
    "value": "Opo"
  },
  {
    "id": "7308182006",
    "district_id": "730818",
    "label": "Labissa",
    "value": "Labissa"
  },
  {
    "id": "7308182007",
    "district_id": "730818",
    "label": "Timurung",
    "value": "Timurung"
  },
  {
    "id": "7308182008",
    "district_id": "730818",
    "label": "Leppangeng",
    "value": "Leppangeng"
  },
  {
    "id": "7308182009",
    "district_id": "730818",
    "label": "Allamungeng Patue",
    "value": "Allamungeng Patue"
  },
  {
    "id": "7308182010",
    "district_id": "730818",
    "label": "Amessangeng",
    "value": "Amessangeng"
  },
  {
    "id": "7308182011",
    "district_id": "730818",
    "label": "Lebbae",
    "value": "Lebbae"
  },
  {
    "id": "7308182012",
    "district_id": "730818",
    "label": "Manciri",
    "value": "Manciri"
  },
  {
    "id": "7308182013",
    "district_id": "730818",
    "label": "Telle",
    "value": "Telle"
  },
  {
    "id": "7308182014",
    "district_id": "730818",
    "label": "Pacciro",
    "value": "Pacciro"
  },
  {
    "id": "7308191001",
    "district_id": "730819",
    "label": "Unyi",
    "value": "Unyi"
  },
  {
    "id": "7308192002",
    "district_id": "730819",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7308192003",
    "district_id": "730819",
    "label": "Laccori",
    "value": "Laccori"
  },
  {
    "id": "7308192004",
    "district_id": "730819",
    "label": "Pattiro",
    "value": "Pattiro"
  },
  {
    "id": "7308192005",
    "district_id": "730819",
    "label": "Cabbeng",
    "value": "Cabbeng"
  },
  {
    "id": "7308192006",
    "district_id": "730819",
    "label": "Panyili",
    "value": "Panyili"
  },
  {
    "id": "7308192007",
    "district_id": "730819",
    "label": "Sanrangeng",
    "value": "Sanrangeng"
  },
  {
    "id": "7308192008",
    "district_id": "730819",
    "label": "Sailong",
    "value": "Sailong"
  },
  {
    "id": "7308192009",
    "district_id": "730819",
    "label": "Melle",
    "value": "Melle"
  },
  {
    "id": "7308192010",
    "district_id": "730819",
    "label": "Pakkasalo",
    "value": "Pakkasalo"
  },
  {
    "id": "7308192011",
    "district_id": "730819",
    "label": "Tawaroe",
    "value": "Tawaroe"
  },
  {
    "id": "7308192012",
    "district_id": "730819",
    "label": "Uloe",
    "value": "Uloe"
  },
  {
    "id": "7308192013",
    "district_id": "730819",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "7308192014",
    "district_id": "730819",
    "label": "Padacenga",
    "value": "Padacenga"
  },
  {
    "id": "7308192015",
    "district_id": "730819",
    "label": "Lallatang",
    "value": "Lallatang"
  },
  {
    "id": "7308192016",
    "district_id": "730819",
    "label": "Kampoti",
    "value": "Kampoti"
  },
  {
    "id": "7308192017",
    "district_id": "730819",
    "label": "Praja Maju",
    "value": "Praja Maju"
  },
  {
    "id": "7308192018",
    "district_id": "730819",
    "label": "Tocina",
    "value": "Tocina"
  },
  {
    "id": "7308192019",
    "district_id": "730819",
    "label": "Tempe",
    "value": "Tempe"
  },
  {
    "id": "7308192020",
    "district_id": "730819",
    "label": "Solo",
    "value": "Solo"
  },
  {
    "id": "7308192021",
    "district_id": "730819",
    "label": "Watang Padacenga",
    "value": "Watang Padacenga"
  },
  {
    "id": "7308192022",
    "district_id": "730819",
    "label": "Matajang",
    "value": "Matajang"
  },
  {
    "id": "7308201001",
    "district_id": "730820",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "7308202002",
    "district_id": "730820",
    "label": "Pacubbe",
    "value": "Pacubbe"
  },
  {
    "id": "7308202003",
    "district_id": "730820",
    "label": "Panyiwi",
    "value": "Panyiwi"
  },
  {
    "id": "7308202004",
    "district_id": "730820",
    "label": "Latonro",
    "value": "Latonro"
  },
  {
    "id": "7308202005",
    "district_id": "730820",
    "label": "Watu",
    "value": "Watu"
  },
  {
    "id": "7308202006",
    "district_id": "730820",
    "label": "Nagauleng",
    "value": "Nagauleng"
  },
  {
    "id": "7308202007",
    "district_id": "730820",
    "label": "Cenrana",
    "value": "Cenrana"
  },
  {
    "id": "7308202008",
    "district_id": "730820",
    "label": "Pallime",
    "value": "Pallime"
  },
  {
    "id": "7308202009",
    "district_id": "730820",
    "label": "Laoni",
    "value": "Laoni"
  },
  {
    "id": "7308202010",
    "district_id": "730820",
    "label": "Labotto",
    "value": "Labotto"
  },
  {
    "id": "7308202011",
    "district_id": "730820",
    "label": "Awang Cenrana",
    "value": "Awang Cenrana"
  },
  {
    "id": "7308202012",
    "district_id": "730820",
    "label": "Labongnge",
    "value": "Labongnge"
  },
  {
    "id": "7308202013",
    "district_id": "730820",
    "label": "Ajalasse",
    "value": "Ajalasse"
  },
  {
    "id": "7308202014",
    "district_id": "730820",
    "label": "Pusungnge",
    "value": "Pusungnge"
  },
  {
    "id": "7308202015",
    "district_id": "730820",
    "label": "Pallae",
    "value": "Pallae"
  },
  {
    "id": "7308202016",
    "district_id": "730820",
    "label": "Watang Ta",
    "value": "Watang Ta"
  },
  {
    "id": "7308211001",
    "district_id": "730821",
    "label": "Biru",
    "value": "Biru"
  },
  { "id": "7308211002", "district_id": "730821", "label": "Ta", "value": "Ta" },
  {
    "id": "7308211003",
    "district_id": "730821",
    "label": "Watampone",
    "value": "Watampone"
  },
  {
    "id": "7308211004",
    "district_id": "730821",
    "label": "Bukaka",
    "value": "Bukaka"
  },
  {
    "id": "7308211005",
    "district_id": "730821",
    "label": "Pappolo",
    "value": "Pappolo"
  },
  {
    "id": "7308211006",
    "district_id": "730821",
    "label": "Manurunge",
    "value": "Manurunge"
  },
  {
    "id": "7308211007",
    "district_id": "730821",
    "label": "Walanae",
    "value": "Walanae"
  },
  {
    "id": "7308211008",
    "district_id": "730821",
    "label": "Masumpu",
    "value": "Masumpu"
  },
  {
    "id": "7308221001",
    "district_id": "730822",
    "label": "Macege",
    "value": "Macege"
  },
  {
    "id": "7308221002",
    "district_id": "730822",
    "label": "Matirro Walie",
    "value": "Matirro Walie"
  },
  {
    "id": "7308221003",
    "district_id": "730822",
    "label": "Macanang",
    "value": "Macanang"
  },
  {
    "id": "7308221004",
    "district_id": "730822",
    "label": "Majang",
    "value": "Majang"
  },
  {
    "id": "7308221005",
    "district_id": "730822",
    "label": "Bulu Tempe",
    "value": "Bulu Tempe"
  },
  {
    "id": "7308221006",
    "district_id": "730822",
    "label": "Jeppee",
    "value": "Jeppee"
  },
  {
    "id": "7308221007",
    "district_id": "730822",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7308221008",
    "district_id": "730822",
    "label": "Watang Palakka",
    "value": "Watang Palakka"
  },
  {
    "id": "7308231001",
    "district_id": "730823",
    "label": "Bajoe",
    "value": "Bajoe"
  },
  {
    "id": "7308231002",
    "district_id": "730823",
    "label": "Cellu",
    "value": "Cellu"
  },
  {
    "id": "7308231003",
    "district_id": "730823",
    "label": "Panyula",
    "value": "Panyula"
  },
  {
    "id": "7308231004",
    "district_id": "730823",
    "label": "Waetuo",
    "value": "Waetuo"
  },
  {
    "id": "7308231005",
    "district_id": "730823",
    "label": "Palette",
    "value": "Palette"
  },
  {
    "id": "7308231006",
    "district_id": "730823",
    "label": "Tipojong",
    "value": "Tipojong"
  },
  {
    "id": "7308231007",
    "district_id": "730823",
    "label": "Toro",
    "value": "Toro"
  },
  {
    "id": "7308231008",
    "district_id": "730823",
    "label": "Lonrae",
    "value": "Lonrae"
  },
  {
    "id": "7308241015",
    "district_id": "730824",
    "label": "Mampotu",
    "value": "Mampotu"
  },
  {
    "id": "7308242001",
    "district_id": "730824",
    "label": "Liliriattang",
    "value": "Liliriattang"
  },
  {
    "id": "7308242002",
    "district_id": "730824",
    "label": "Mattaropurae",
    "value": "Mattaropurae"
  },
  {
    "id": "7308242003",
    "district_id": "730824",
    "label": "Ulaweng Riaja",
    "value": "Ulaweng Riaja"
  },
  {
    "id": "7308242004",
    "district_id": "730824",
    "label": "Waempubbu",
    "value": "Waempubbu"
  },
  {
    "id": "7308242005",
    "district_id": "730824",
    "label": "Waemputtange",
    "value": "Waemputtange"
  },
  {
    "id": "7308242006",
    "district_id": "730824",
    "label": "Amali Riattang",
    "value": "Amali Riattang"
  },
  {
    "id": "7308242007",
    "district_id": "730824",
    "label": "Tassipi",
    "value": "Tassipi"
  },
  {
    "id": "7308242008",
    "district_id": "730824",
    "label": "Wellulang",
    "value": "Wellulang"
  },
  {
    "id": "7308242009",
    "district_id": "730824",
    "label": "Benteng Tellue",
    "value": "Benteng Tellue"
  },
  {
    "id": "7308242010",
    "district_id": "730824",
    "label": "Tacipong",
    "value": "Tacipong"
  },
  {
    "id": "7308242011",
    "district_id": "730824",
    "label": "Laponrong",
    "value": "Laponrong"
  },
  {
    "id": "7308242012",
    "district_id": "730824",
    "label": "Ajanglaleng",
    "value": "Ajanglaleng"
  },
  {
    "id": "7308242013",
    "district_id": "730824",
    "label": "Tocinnong",
    "value": "Tocinnong"
  },
  {
    "id": "7308242014",
    "district_id": "730824",
    "label": "Bila",
    "value": "Bila"
  },
  {
    "id": "7308252001",
    "district_id": "730825",
    "label": "Tellang Kere",
    "value": "Tellang Kere"
  },
  {
    "id": "7308252002",
    "district_id": "730825",
    "label": "Lagori",
    "value": "Lagori"
  },
  {
    "id": "7308252003",
    "district_id": "730825",
    "label": "Gaya Baru",
    "value": "Gaya Baru"
  },
  {
    "id": "7308252004",
    "district_id": "730825",
    "label": "Tapong",
    "value": "Tapong"
  },
  {
    "id": "7308252005",
    "district_id": "730825",
    "label": "Sadar",
    "value": "Sadar"
  },
  {
    "id": "7308252006",
    "district_id": "730825",
    "label": "Tondong",
    "value": "Tondong"
  },
  {
    "id": "7308252007",
    "district_id": "730825",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7308252008",
    "district_id": "730825",
    "label": "Bonto Masunggu",
    "value": "Bonto Masunggu"
  },
  {
    "id": "7308252009",
    "district_id": "730825",
    "label": "Pallawa",
    "value": "Pallawa"
  },
  {
    "id": "7308252010",
    "district_id": "730825",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7308252011",
    "district_id": "730825",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "7308262001",
    "district_id": "730826",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7308262002",
    "district_id": "730826",
    "label": "Tungke",
    "value": "Tungke"
  },
  {
    "id": "7308262003",
    "district_id": "730826",
    "label": "Selli",
    "value": "Selli"
  },
  {
    "id": "7308262004",
    "district_id": "730826",
    "label": "Bengo",
    "value": "Bengo"
  },
  {
    "id": "7308262005",
    "district_id": "730826",
    "label": "Mattaropuli",
    "value": "Mattaropuli"
  },
  {
    "id": "7308262006",
    "district_id": "730826",
    "label": "Lili Riawang",
    "value": "Lili Riawang"
  },
  {
    "id": "7308262007",
    "district_id": "730826",
    "label": "Walimpong",
    "value": "Walimpong"
  },
  {
    "id": "7308262008",
    "district_id": "730826",
    "label": "Bulu Allaporenge",
    "value": "Bulu Allaporenge"
  },
  {
    "id": "7308262009",
    "district_id": "730826",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7308272001",
    "district_id": "730827",
    "label": "Patimpeng",
    "value": "Patimpeng"
  },
  {
    "id": "7308272002",
    "district_id": "730827",
    "label": "Latellang",
    "value": "Latellang"
  },
  {
    "id": "7308272003",
    "district_id": "730827",
    "label": "Maddenreng Pulu",
    "value": "Maddenreng Pulu"
  },
  {
    "id": "7308272004",
    "district_id": "730827",
    "label": "Masago",
    "value": "Masago"
  },
  {
    "id": "7308272005",
    "district_id": "730827",
    "label": "Batu Lappa",
    "value": "Batu Lappa"
  },
  {
    "id": "7308272006",
    "district_id": "730827",
    "label": "Paccing",
    "value": "Paccing"
  },
  {
    "id": "7308272007",
    "district_id": "730827",
    "label": "Massila",
    "value": "Massila"
  },
  {
    "id": "7308272008",
    "district_id": "730827",
    "label": "Talabangi",
    "value": "Talabangi"
  },
  {
    "id": "7308272009",
    "district_id": "730827",
    "label": "Pationgi",
    "value": "Pationgi"
  },
  {
    "id": "7308272010",
    "district_id": "730827",
    "label": "Bulu Ulaweng",
    "value": "Bulu Ulaweng"
  },
  {
    "id": "7309011001",
    "district_id": "730901",
    "label": "Hasanuddin",
    "value": "Hasanuddin"
  },
  {
    "id": "7309011002",
    "district_id": "730901",
    "label": "Bontoa",
    "value": "Bontoa"
  },
  {
    "id": "7309012003",
    "district_id": "730901",
    "label": "Tenrigangkae",
    "value": "Tenrigangkae"
  },
  {
    "id": "7309012004",
    "district_id": "730901",
    "label": "Pattontongang",
    "value": "Pattontongang"
  },
  {
    "id": "7309012005",
    "district_id": "730901",
    "label": "Bonto Mate'ne",
    "value": "Bonto Mate'ne"
  },
  {
    "id": "7309012006",
    "district_id": "730901",
    "label": "Baji Mangngai",
    "value": "Baji Mangngai"
  },
  {
    "id": "7309021001",
    "district_id": "730902",
    "label": "Cempaniga",
    "value": "Cempaniga"
  },
  {
    "id": "7309021002",
    "district_id": "730902",
    "label": "Mario Pulana",
    "value": "Mario Pulana"
  },
  {
    "id": "7309022003",
    "district_id": "730902",
    "label": "Timpuseng",
    "value": "Timpuseng"
  },
  {
    "id": "7309022004",
    "district_id": "730902",
    "label": "Cenrana",
    "value": "Cenrana"
  },
  {
    "id": "7309022005",
    "district_id": "730902",
    "label": "Sawaru",
    "value": "Sawaru"
  },
  {
    "id": "7309022006",
    "district_id": "730902",
    "label": "Patanyamang",
    "value": "Patanyamang"
  },
  {
    "id": "7309022007",
    "district_id": "730902",
    "label": "Pattiro Deceng",
    "value": "Pattiro Deceng"
  },
  {
    "id": "7309022008",
    "district_id": "730902",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7309031001",
    "district_id": "730903",
    "label": "Kalabbirang",
    "value": "Kalabbirang"
  },
  {
    "id": "7309031002",
    "district_id": "730903",
    "label": "Leang Leang",
    "value": "Leang Leang"
  },
  {
    "id": "7309032003",
    "district_id": "730903",
    "label": "Alatengae",
    "value": "Alatengae"
  },
  {
    "id": "7309032004",
    "district_id": "730903",
    "label": "Minasa Baji",
    "value": "Minasa Baji"
  },
  {
    "id": "7309032005",
    "district_id": "730903",
    "label": "Tukamasea",
    "value": "Tukamasea"
  },
  {
    "id": "7309032006",
    "district_id": "730903",
    "label": "Mattoanging",
    "value": "Mattoanging"
  },
  {
    "id": "7309032007",
    "district_id": "730903",
    "label": "Mangeloreng",
    "value": "Mangeloreng"
  },
  {
    "id": "7309032008",
    "district_id": "730903",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7309041001",
    "district_id": "730904",
    "label": "Baji Pamai",
    "value": "Baji Pamai"
  },
  {
    "id": "7309041002",
    "district_id": "730904",
    "label": "Pallantikang",
    "value": "Pallantikang"
  },
  {
    "id": "7309041003",
    "district_id": "730904",
    "label": "Baju Bodoa",
    "value": "Baju Bodoa"
  },
  {
    "id": "7309042004",
    "district_id": "730904",
    "label": "Borikamase",
    "value": "Borikamase"
  },
  {
    "id": "7309042005",
    "district_id": "730904",
    "label": "Mattirotasi",
    "value": "Mattirotasi"
  },
  {
    "id": "7309042006",
    "district_id": "730904",
    "label": "Majannang",
    "value": "Majannang"
  },
  {
    "id": "7309042007",
    "district_id": "730904",
    "label": "Borimasunggu",
    "value": "Borimasunggu"
  },
  {
    "id": "7309051001",
    "district_id": "730905",
    "label": "Bontoa",
    "value": "Bontoa"
  },
  {
    "id": "7309052002",
    "district_id": "730905",
    "label": "Pajukukang",
    "value": "Pajukukang"
  },
  {
    "id": "7309052003",
    "district_id": "730905",
    "label": "Tunikamaseang",
    "value": "Tunikamaseang"
  },
  {
    "id": "7309052004",
    "district_id": "730905",
    "label": "Tupabiring",
    "value": "Tupabiring"
  },
  {
    "id": "7309052005",
    "district_id": "730905",
    "label": "Botolempangang",
    "value": "Botolempangang"
  },
  {
    "id": "7309052006",
    "district_id": "730905",
    "label": "Salenrang",
    "value": "Salenrang"
  },
  {
    "id": "7309052007",
    "district_id": "730905",
    "label": "Minasa Upa",
    "value": "Minasa Upa"
  },
  {
    "id": "7309052008",
    "district_id": "730905",
    "label": "Ampekale",
    "value": "Ampekale"
  },
  {
    "id": "7309052009",
    "district_id": "730905",
    "label": "Bonto Bahari",
    "value": "Bonto Bahari"
  },
  {
    "id": "7309061001",
    "district_id": "730906",
    "label": "Sabila",
    "value": "Sabila"
  },
  {
    "id": "7309062002",
    "district_id": "730906",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7309062003",
    "district_id": "730906",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "7309062004",
    "district_id": "730906",
    "label": "Wanuawaru",
    "value": "Wanuawaru"
  },
  {
    "id": "7309062005",
    "district_id": "730906",
    "label": "Tellumpanuae",
    "value": "Tellumpanuae"
  },
  {
    "id": "7309062006",
    "district_id": "730906",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7309062007",
    "district_id": "730906",
    "label": "Bentenge",
    "value": "Bentenge"
  },
  {
    "id": "7309062008",
    "district_id": "730906",
    "label": "Matampapole",
    "value": "Matampapole"
  },
  {
    "id": "7309062009",
    "district_id": "730906",
    "label": "Uludaya",
    "value": "Uludaya"
  },
  {
    "id": "7309062010",
    "district_id": "730906",
    "label": "Gattareng Matinggi",
    "value": "Gattareng Matinggi"
  },
  {
    "id": "7309062011",
    "district_id": "730906",
    "label": "Barugae",
    "value": "Barugae"
  },
  {
    "id": "7309071001",
    "district_id": "730907",
    "label": "Borong",
    "value": "Borong"
  },
  {
    "id": "7309072002",
    "district_id": "730907",
    "label": "Allaere",
    "value": "Allaere"
  },
  {
    "id": "7309072003",
    "district_id": "730907",
    "label": "Toddopulia",
    "value": "Toddopulia"
  },
  {
    "id": "7309072004",
    "district_id": "730907",
    "label": "Kurusumange",
    "value": "Kurusumange"
  },
  {
    "id": "7309072005",
    "district_id": "730907",
    "label": "Lekopancing",
    "value": "Lekopancing"
  },
  {
    "id": "7309072006",
    "district_id": "730907",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "7309072007",
    "district_id": "730907",
    "label": "Sudirman",
    "value": "Sudirman"
  },
  {
    "id": "7309072008",
    "district_id": "730907",
    "label": "Purnakarya",
    "value": "Purnakarya"
  },
  {
    "id": "7309082001",
    "district_id": "730908",
    "label": "Temmapaduae",
    "value": "Temmapaduae"
  },
  {
    "id": "7309082002",
    "district_id": "730908",
    "label": "Nisombalia",
    "value": "Nisombalia"
  },
  {
    "id": "7309082003",
    "district_id": "730908",
    "label": "Tellumpoccoe",
    "value": "Tellumpoccoe"
  },
  {
    "id": "7309082004",
    "district_id": "730908",
    "label": "Ma'rumpa",
    "value": "Ma'rumpa"
  },
  {
    "id": "7309082005",
    "district_id": "730908",
    "label": "Bonto Mate'ne",
    "value": "Bonto Mate'ne"
  },
  {
    "id": "7309082006",
    "district_id": "730908",
    "label": "Abulosibatang",
    "value": "Abulosibatang"
  },
  {
    "id": "7309082007",
    "district_id": "730908",
    "label": "Pa'bentengang",
    "value": "Pa'bentengang"
  },
  {
    "id": "7309092001",
    "district_id": "730909",
    "label": "Jenetaesa",
    "value": "Jenetaesa"
  },
  {
    "id": "7309092002",
    "district_id": "730909",
    "label": "Bonto Tallasa",
    "value": "Bonto Tallasa"
  },
  {
    "id": "7309092003",
    "district_id": "730909",
    "label": "Sambueja",
    "value": "Sambueja"
  },
  {
    "id": "7309092004",
    "district_id": "730909",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7309092005",
    "district_id": "730909",
    "label": "Samangki",
    "value": "Samangki"
  },
  {
    "id": "7309092006",
    "district_id": "730909",
    "label": "Simbang",
    "value": "Simbang"
  },
  {
    "id": "7309102001",
    "district_id": "730910",
    "label": "Laiya",
    "value": "Laiya"
  },
  {
    "id": "7309102002",
    "district_id": "730910",
    "label": "Labuaja",
    "value": "Labuaja"
  },
  {
    "id": "7309102003",
    "district_id": "730910",
    "label": "Limapoccoe",
    "value": "Limapoccoe"
  },
  {
    "id": "7309102004",
    "district_id": "730910",
    "label": "Lebbotengae",
    "value": "Lebbotengae"
  },
  {
    "id": "7309102005",
    "district_id": "730910",
    "label": "Rompegading",
    "value": "Rompegading"
  },
  {
    "id": "7309102006",
    "district_id": "730910",
    "label": "Baji pa'mai",
    "value": "Baji pa'mai"
  },
  {
    "id": "7309102007",
    "district_id": "730910",
    "label": "Cenrana Baru",
    "value": "Cenrana Baru"
  },
  {
    "id": "7309112001",
    "district_id": "730911",
    "label": "Tompobulu",
    "value": "Tompobulu"
  },
  {
    "id": "7309112002",
    "district_id": "730911",
    "label": "Benteng Gajah",
    "value": "Benteng Gajah"
  },
  {
    "id": "7309112003",
    "district_id": "730911",
    "label": "Bonto Manai",
    "value": "Bonto Manai"
  },
  {
    "id": "7309112004",
    "district_id": "730911",
    "label": "Bonto Somba",
    "value": "Bonto Somba"
  },
  {
    "id": "7309112005",
    "district_id": "730911",
    "label": "Toddolimae",
    "value": "Toddolimae"
  },
  {
    "id": "7309112006",
    "district_id": "730911",
    "label": "Pucak",
    "value": "Pucak"
  },
  {
    "id": "7309112007",
    "district_id": "730911",
    "label": "Bonto Matinggi",
    "value": "Bonto Matinggi"
  },
  {
    "id": "7309112008",
    "district_id": "730911",
    "label": "Bonto Manurung",
    "value": "Bonto Manurung"
  },
  {
    "id": "7309121001",
    "district_id": "730912",
    "label": "Allepolea",
    "value": "Allepolea"
  },
  {
    "id": "7309121002",
    "district_id": "730912",
    "label": "Maccini Baji",
    "value": "Maccini Baji"
  },
  {
    "id": "7309121003",
    "district_id": "730912",
    "label": "Soreang",
    "value": "Soreang"
  },
  {
    "id": "7309121004",
    "district_id": "730912",
    "label": "Mattirodeceng",
    "value": "Mattirodeceng"
  },
  {
    "id": "7309122005",
    "district_id": "730912",
    "label": "Marannu",
    "value": "Marannu"
  },
  {
    "id": "7309122006",
    "district_id": "730912",
    "label": "Bonto Marannu",
    "value": "Bonto Marannu"
  },
  {
    "id": "7309132001",
    "district_id": "730913",
    "label": "Moncongloe",
    "value": "Moncongloe"
  },
  {
    "id": "7309132002",
    "district_id": "730913",
    "label": "Moncongloe Bulu",
    "value": "Moncongloe Bulu"
  },
  {
    "id": "7309132003",
    "district_id": "730913",
    "label": "Moncongloe Lappara",
    "value": "Moncongloe Lappara"
  },
  {
    "id": "7309132004",
    "district_id": "730913",
    "label": "Bonto Bunga",
    "value": "Bonto Bunga"
  },
  {
    "id": "7309132005",
    "district_id": "730913",
    "label": "Bonto Marannu",
    "value": "Bonto Marannu"
  },
  {
    "id": "7309141001",
    "district_id": "730914",
    "label": "Pettuadae",
    "value": "Pettuadae"
  },
  {
    "id": "7309141002",
    "district_id": "730914",
    "label": "Bori Bellaya",
    "value": "Bori Bellaya"
  },
  {
    "id": "7309141003",
    "district_id": "730914",
    "label": "Alliri Tengae",
    "value": "Alliri Tengae"
  },
  {
    "id": "7309141004",
    "district_id": "730914",
    "label": "Turikale",
    "value": "Turikale"
  },
  {
    "id": "7309141005",
    "district_id": "730914",
    "label": "Taroada",
    "value": "Taroada"
  },
  {
    "id": "7309141006",
    "district_id": "730914",
    "label": "Raya",
    "value": "Raya"
  },
  {
    "id": "7309141007",
    "district_id": "730914",
    "label": "Adatongeng",
    "value": "Adatongeng"
  },
  {
    "id": "7310011001",
    "district_id": "731001",
    "label": "Sapuka",
    "value": "Sapuka"
  },
  {
    "id": "7310012002",
    "district_id": "731001",
    "label": "Sabaru",
    "value": "Sabaru"
  },
  {
    "id": "7310012003",
    "district_id": "731001",
    "label": "Balo Baloang",
    "value": "Balo Baloang"
  },
  {
    "id": "7310012004",
    "district_id": "731001",
    "label": "Sabalana",
    "value": "Sabalana"
  },
  {
    "id": "7310012005",
    "district_id": "731001",
    "label": "Tampaang",
    "value": "Tampaang"
  },
  {
    "id": "7310012006",
    "district_id": "731001",
    "label": "Sailus",
    "value": "Sailus"
  },
  {
    "id": "7310012007",
    "district_id": "731001",
    "label": "Satanger",
    "value": "Satanger"
  },
  {
    "id": "7310012008",
    "district_id": "731001",
    "label": "Kapoposan Bali",
    "value": "Kapoposan Bali"
  },
  {
    "id": "7310012009",
    "district_id": "731001",
    "label": "Poleonro",
    "value": "Poleonro"
  },
  {
    "id": "7310021001",
    "district_id": "731002",
    "label": "Kalukuang",
    "value": "Kalukuang"
  },
  {
    "id": "7310022002",
    "district_id": "731002",
    "label": "Pammas",
    "value": "Pammas"
  },
  {
    "id": "7310022003",
    "district_id": "731002",
    "label": "Sabaru",
    "value": "Sabaru"
  },
  {
    "id": "7310022004",
    "district_id": "731002",
    "label": "Kanyurang",
    "value": "Kanyurang"
  },
  {
    "id": "7310022005",
    "district_id": "731002",
    "label": "Doang Doangan Lompo",
    "value": "Doang Doangan Lompo"
  },
  {
    "id": "7310022006",
    "district_id": "731002",
    "label": "Marasende",
    "value": "Marasende"
  },
  {
    "id": "7310022007",
    "district_id": "731002",
    "label": "Dewakang",
    "value": "Dewakang"
  },
  {
    "id": "7310031001",
    "district_id": "731003",
    "label": "Mattiro Sompe",
    "value": "Mattiro Sompe"
  },
  {
    "id": "7310031016",
    "district_id": "731003",
    "label": "Mattiro Bintang",
    "value": "Mattiro Bintang"
  },
  {
    "id": "7310032002",
    "district_id": "731003",
    "label": "Mattiro Deceng",
    "value": "Mattiro Deceng"
  },
  {
    "id": "7310032003",
    "district_id": "731003",
    "label": "Mattiro Langi",
    "value": "Mattiro Langi"
  },
  {
    "id": "7310032009",
    "district_id": "731003",
    "label": "Mattiro Matae",
    "value": "Mattiro Matae"
  },
  {
    "id": "7310032010",
    "district_id": "731003",
    "label": "Mattiro Ujung",
    "value": "Mattiro Ujung"
  },
  {
    "id": "7310032011",
    "district_id": "731003",
    "label": "Mattiro Dolangeng",
    "value": "Mattiro Dolangeng"
  },
  {
    "id": "7310032013",
    "district_id": "731003",
    "label": "Mattiro Bone",
    "value": "Mattiro Bone"
  },
  {
    "id": "7310032015",
    "district_id": "731003",
    "label": "Mattaro Adae",
    "value": "Mattaro Adae"
  },
  {
    "id": "7310041001",
    "district_id": "731004",
    "label": "Jagong",
    "value": "Jagong"
  },
  {
    "id": "7310041002",
    "district_id": "731004",
    "label": "Mappa Saile",
    "value": "Mappa Saile"
  },
  {
    "id": "7310041003",
    "district_id": "731004",
    "label": "Pabundukang",
    "value": "Pabundukang"
  },
  {
    "id": "7310041004",
    "district_id": "731004",
    "label": "Anrong Appaka",
    "value": "Anrong Appaka"
  },
  {
    "id": "7310041005",
    "district_id": "731004",
    "label": "Sibatua",
    "value": "Sibatua"
  },
  {
    "id": "7310041006",
    "district_id": "731004",
    "label": "Padoang Doangan",
    "value": "Padoang Doangan"
  },
  {
    "id": "7310041007",
    "district_id": "731004",
    "label": "Tumampua",
    "value": "Tumampua"
  },
  {
    "id": "7310041008",
    "district_id": "731004",
    "label": "Tekolabbua",
    "value": "Tekolabbua"
  },
  {
    "id": "7310041009",
    "district_id": "731004",
    "label": "Bonto Perak",
    "value": "Bonto Perak"
  },
  {
    "id": "7310051001",
    "district_id": "731005",
    "label": "Balleanging",
    "value": "Balleanging"
  },
  {
    "id": "7310051002",
    "district_id": "731005",
    "label": "Balocci Baru",
    "value": "Balocci Baru"
  },
  {
    "id": "7310051003",
    "district_id": "731005",
    "label": "Tonasa",
    "value": "Tonasa"
  },
  {
    "id": "7310051004",
    "district_id": "731005",
    "label": "Majannang",
    "value": "Majannang"
  },
  {
    "id": "7310052005",
    "district_id": "731005",
    "label": "Tompobulu",
    "value": "Tompobulu"
  },
  {
    "id": "7310061001",
    "district_id": "731006",
    "label": "Boriappaka",
    "value": "Boriappaka"
  },
  {
    "id": "7310061002",
    "district_id": "731006",
    "label": "Samalewa",
    "value": "Samalewa"
  },
  {
    "id": "7310061003",
    "district_id": "731006",
    "label": "Sapanang",
    "value": "Sapanang"
  },
  {
    "id": "7310062004",
    "district_id": "731006",
    "label": "Bowong Cindea",
    "value": "Bowong Cindea"
  },
  {
    "id": "7310062005",
    "district_id": "731006",
    "label": "Tabo Tabo",
    "value": "Tabo Tabo"
  },
  {
    "id": "7310062006",
    "district_id": "731006",
    "label": "Mangilu",
    "value": "Mangilu"
  },
  {
    "id": "7310062007",
    "district_id": "731006",
    "label": "Bulu Cindea",
    "value": "Bulu Cindea"
  },
  {
    "id": "7310062008",
    "district_id": "731006",
    "label": "Biring Ere",
    "value": "Biring Ere"
  },
  {
    "id": "7310071001",
    "district_id": "731007",
    "label": "Labakkang",
    "value": "Labakkang"
  },
  {
    "id": "7310071002",
    "district_id": "731007",
    "label": "Mangallekana",
    "value": "Mangallekana"
  },
  {
    "id": "7310071003",
    "district_id": "731007",
    "label": "Pundata Baji",
    "value": "Pundata Baji"
  },
  {
    "id": "7310071004",
    "district_id": "731007",
    "label": "Borimasunggu",
    "value": "Borimasunggu"
  },
  {
    "id": "7310072005",
    "district_id": "731007",
    "label": "Pattalassang",
    "value": "Pattalassang"
  },
  {
    "id": "7310072006",
    "district_id": "731007",
    "label": "Manakku",
    "value": "Manakku"
  },
  {
    "id": "7310072007",
    "district_id": "731007",
    "label": "Gentung",
    "value": "Gentung"
  },
  {
    "id": "7310072008",
    "district_id": "731007",
    "label": "Batara",
    "value": "Batara"
  },
  {
    "id": "7310072009",
    "district_id": "731007",
    "label": "Taraweang",
    "value": "Taraweang"
  },
  {
    "id": "7310072010",
    "district_id": "731007",
    "label": "Kanaungan",
    "value": "Kanaungan"
  },
  {
    "id": "7310072011",
    "district_id": "731007",
    "label": "Bonto Manai",
    "value": "Bonto Manai"
  },
  {
    "id": "7310072012",
    "district_id": "731007",
    "label": "Kassi Loe",
    "value": "Kassi Loe"
  },
  {
    "id": "7310072013",
    "district_id": "731007",
    "label": "Bara Batu",
    "value": "Bara Batu"
  },
  {
    "id": "7310081001",
    "district_id": "731008",
    "label": "Bonto Bonto",
    "value": "Bonto Bonto"
  },
  {
    "id": "7310081002",
    "district_id": "731008",
    "label": "Talaka",
    "value": "Talaka"
  },
  {
    "id": "7310081003",
    "district_id": "731008",
    "label": "Marang",
    "value": "Marang"
  },
  {
    "id": "7310081004",
    "district_id": "731008",
    "label": "Attang Salo",
    "value": "Attang Salo"
  },
  {
    "id": "7310082005",
    "district_id": "731008",
    "label": "Padang Lampe",
    "value": "Padang Lampe"
  },
  {
    "id": "7310082006",
    "district_id": "731008",
    "label": "Tamangapa",
    "value": "Tamangapa"
  },
  {
    "id": "7310082007",
    "district_id": "731008",
    "label": "Pitusunggu",
    "value": "Pitusunggu"
  },
  {
    "id": "7310082008",
    "district_id": "731008",
    "label": "Pitue",
    "value": "Pitue"
  },
  {
    "id": "7310082009",
    "district_id": "731008",
    "label": "Punranga",
    "value": "Punranga"
  },
  {
    "id": "7310082010",
    "district_id": "731008",
    "label": "Alesipitto",
    "value": "Alesipitto"
  },
  {
    "id": "7310091001",
    "district_id": "731009",
    "label": "Bonto Matene",
    "value": "Bonto Matene"
  },
  {
    "id": "7310091002",
    "district_id": "731009",
    "label": "Segeri",
    "value": "Segeri"
  },
  {
    "id": "7310091003",
    "district_id": "731009",
    "label": "Bone",
    "value": "Bone"
  },
  {
    "id": "7310091004",
    "district_id": "731009",
    "label": "Bawa Salo",
    "value": "Bawa Salo"
  },
  {
    "id": "7310092005",
    "district_id": "731009",
    "label": "Baring",
    "value": "Baring"
  },
  {
    "id": "7310092006",
    "district_id": "731009",
    "label": "Parenreng",
    "value": "Parenreng"
  },
  {
    "id": "7310101001",
    "district_id": "731010",
    "label": "Minasa Tene",
    "value": "Minasa Tene"
  },
  {
    "id": "7310101002",
    "district_id": "731010",
    "label": "Kalabbirang",
    "value": "Kalabbirang"
  },
  {
    "id": "7310101003",
    "district_id": "731010",
    "label": "Biraeng",
    "value": "Biraeng"
  },
  {
    "id": "7310101004",
    "district_id": "731010",
    "label": "Bonto Kio",
    "value": "Bonto Kio"
  },
  {
    "id": "7310101005",
    "district_id": "731010",
    "label": "Bontoa",
    "value": "Bontoa"
  },
  {
    "id": "7310101006",
    "district_id": "731010",
    "label": "Bonto Langkasa",
    "value": "Bonto Langkasa"
  },
  {
    "id": "7310102007",
    "district_id": "731010",
    "label": "Kabba",
    "value": "Kabba"
  },
  {
    "id": "7310102008",
    "district_id": "731010",
    "label": "Panaikang",
    "value": "Panaikang"
  },
  {
    "id": "7310112001",
    "district_id": "731011",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7310112002",
    "district_id": "731011",
    "label": "Manggalung",
    "value": "Manggalung"
  },
  {
    "id": "7310112003",
    "district_id": "731011",
    "label": "Tama'rupa",
    "value": "Tama'rupa"
  },
  {
    "id": "7310112004",
    "district_id": "731011",
    "label": "Mandalle",
    "value": "Mandalle"
  },
  {
    "id": "7310112005",
    "district_id": "731011",
    "label": "Boddie",
    "value": "Boddie"
  },
  {
    "id": "7310112006",
    "district_id": "731011",
    "label": "Coppo Tompong",
    "value": "Coppo Tompong"
  },
  {
    "id": "7310122001",
    "district_id": "731012",
    "label": "Bantimurung",
    "value": "Bantimurung"
  },
  {
    "id": "7310122002",
    "district_id": "731012",
    "label": "Tondong Kura",
    "value": "Tondong Kura"
  },
  {
    "id": "7310122003",
    "district_id": "731012",
    "label": "Lanne",
    "value": "Lanne"
  },
  {
    "id": "7310122004",
    "district_id": "731012",
    "label": "Bulu Tellue",
    "value": "Bulu Tellue"
  },
  {
    "id": "7310122005",
    "district_id": "731012",
    "label": "Malaka",
    "value": "Malaka"
  },
  {
    "id": "7310122006",
    "district_id": "731012",
    "label": "Bonto Birao",
    "value": "Bonto Birao"
  },
  {
    "id": "7310132001",
    "district_id": "731013",
    "label": "Mattiro Kanja",
    "value": "Mattiro Kanja"
  },
  {
    "id": "7310132002",
    "district_id": "731013",
    "label": "Mattiro Baji",
    "value": "Mattiro Baji"
  },
  {
    "id": "7310132003",
    "district_id": "731013",
    "label": "Mattiro Bulu",
    "value": "Mattiro Bulu"
  },
  {
    "id": "7310132004",
    "district_id": "731013",
    "label": "Mattiro Bombang",
    "value": "Mattiro Bombang"
  },
  {
    "id": "7310132005",
    "district_id": "731013",
    "label": "Mattiro Labangeng",
    "value": "Mattiro Labangeng"
  },
  {
    "id": "7310132006",
    "district_id": "731013",
    "label": "Mattiro Uleng",
    "value": "Mattiro Uleng"
  },
  {
    "id": "7310132007",
    "district_id": "731013",
    "label": "Mattiro Walie",
    "value": "Mattiro Walie"
  },
  {
    "id": "7311011001",
    "district_id": "731101",
    "label": "Lompo Riaja",
    "value": "Lompo Riaja"
  },
  {
    "id": "7311012002",
    "district_id": "731101",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "7311012003",
    "district_id": "731101",
    "label": "Lompo Tengah",
    "value": "Lompo Tengah"
  },
  {
    "id": "7311012004",
    "district_id": "731101",
    "label": "Kading",
    "value": "Kading"
  },
  {
    "id": "7311012005",
    "district_id": "731101",
    "label": "Libureng",
    "value": "Libureng"
  },
  {
    "id": "7311012006",
    "district_id": "731101",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7311012007",
    "district_id": "731101",
    "label": "Lempang",
    "value": "Lempang"
  },
  {
    "id": "7311021001",
    "district_id": "731102",
    "label": "Lalolang",
    "value": "Lalolang"
  },
  {
    "id": "7311021002",
    "district_id": "731102",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7311022003",
    "district_id": "731102",
    "label": "Pancana",
    "value": "Pancana"
  },
  {
    "id": "7311022004",
    "district_id": "731102",
    "label": "Lalabata",
    "value": "Lalabata"
  },
  {
    "id": "7311022005",
    "district_id": "731102",
    "label": "Corawali",
    "value": "Corawali"
  },
  {
    "id": "7311022006",
    "district_id": "731102",
    "label": "Pao Pao",
    "value": "Pao Pao"
  },
  {
    "id": "7311022007",
    "district_id": "731102",
    "label": "Tellumpanua",
    "value": "Tellumpanua"
  },
  {
    "id": "7311022008",
    "district_id": "731102",
    "label": "Lipukasi",
    "value": "Lipukasi"
  },
  {
    "id": "7311022009",
    "district_id": "731102",
    "label": "Garessi",
    "value": "Garessi"
  },
  {
    "id": "7311022010",
    "district_id": "731102",
    "label": "Lasitae",
    "value": "Lasitae"
  },
  {
    "id": "7311031001",
    "district_id": "731103",
    "label": "Coppo",
    "value": "Coppo"
  },
  {
    "id": "7311031002",
    "district_id": "731103",
    "label": "Mangempang",
    "value": "Mangempang"
  },
  {
    "id": "7311031003",
    "district_id": "731103",
    "label": "Sumpang Binangae",
    "value": "Sumpang Binangae"
  },
  {
    "id": "7311031004",
    "district_id": "731103",
    "label": "Sepee",
    "value": "Sepee"
  },
  {
    "id": "7311031005",
    "district_id": "731103",
    "label": "Tuwung",
    "value": "Tuwung"
  },
  {
    "id": "7311032006",
    "district_id": "731103",
    "label": "Palakka",
    "value": "Palakka"
  },
  {
    "id": "7311032007",
    "district_id": "731103",
    "label": "Tompo",
    "value": "Tompo"
  },
  {
    "id": "7311032008",
    "district_id": "731103",
    "label": "Anabanua",
    "value": "Anabanua"
  },
  {
    "id": "7311032009",
    "district_id": "731103",
    "label": "Galung",
    "value": "Galung"
  },
  {
    "id": "7311032010",
    "district_id": "731103",
    "label": "Siawung",
    "value": "Siawung"
  },
  {
    "id": "7311041001",
    "district_id": "731104",
    "label": "Kiru Kiru",
    "value": "Kiru Kiru"
  },
  {
    "id": "7311041002",
    "district_id": "731104",
    "label": "Mangkoso",
    "value": "Mangkoso"
  },
  {
    "id": "7311042003",
    "district_id": "731104",
    "label": "Lawallu",
    "value": "Lawallu"
  },
  {
    "id": "7311042004",
    "district_id": "731104",
    "label": "Paccekke",
    "value": "Paccekke"
  },
  {
    "id": "7311042005",
    "district_id": "731104",
    "label": "Ajakkang",
    "value": "Ajakkang"
  },
  {
    "id": "7311042006",
    "district_id": "731104",
    "label": "Siddo",
    "value": "Siddo"
  },
  {
    "id": "7311042007",
    "district_id": "731104",
    "label": "Batupute",
    "value": "Batupute"
  },
  {
    "id": "7311051001",
    "district_id": "731105",
    "label": "Palanro",
    "value": "Palanro"
  },
  {
    "id": "7311051002",
    "district_id": "731105",
    "label": "Mallawa",
    "value": "Mallawa"
  },
  {
    "id": "7311051003",
    "district_id": "731105",
    "label": "Bojo Baru",
    "value": "Bojo Baru"
  },
  {
    "id": "7311052004",
    "district_id": "731105",
    "label": "Cilellang",
    "value": "Cilellang"
  },
  {
    "id": "7311052005",
    "district_id": "731105",
    "label": "Manuba",
    "value": "Manuba"
  },
  {
    "id": "7311052006",
    "district_id": "731105",
    "label": "Nepo",
    "value": "Nepo"
  },
  {
    "id": "7311052007",
    "district_id": "731105",
    "label": "Kupa",
    "value": "Kupa"
  },
  {
    "id": "7311052008",
    "district_id": "731105",
    "label": "Bojo",
    "value": "Bojo"
  },
  {
    "id": "7311061007",
    "district_id": "731106",
    "label": "Mattappawalie",
    "value": "Mattappawalie"
  },
  {
    "id": "7311062001",
    "district_id": "731106",
    "label": "Pujananting",
    "value": "Pujananting"
  },
  {
    "id": "7311062002",
    "district_id": "731106",
    "label": "Gattareng",
    "value": "Gattareng"
  },
  {
    "id": "7311062003",
    "district_id": "731106",
    "label": "Pattappa",
    "value": "Pattappa"
  },
  {
    "id": "7311062004",
    "district_id": "731106",
    "label": "Jangan Jangan",
    "value": "Jangan Jangan"
  },
  {
    "id": "7311062005",
    "district_id": "731106",
    "label": "Bulo Bulo",
    "value": "Bulo Bulo"
  },
  {
    "id": "7311062006",
    "district_id": "731106",
    "label": "Bacu Bacu",
    "value": "Bacu Bacu"
  },
  {
    "id": "7311071001",
    "district_id": "731107",
    "label": "Takkalasi",
    "value": "Takkalasi"
  },
  {
    "id": "7311072002",
    "district_id": "731107",
    "label": "Balusu",
    "value": "Balusu"
  },
  {
    "id": "7311072003",
    "district_id": "731107",
    "label": "Lampoko",
    "value": "Lampoko"
  },
  {
    "id": "7311072004",
    "district_id": "731107",
    "label": "Kamiri",
    "value": "Kamiri"
  },
  {
    "id": "7311072005",
    "district_id": "731107",
    "label": "Madello",
    "value": "Madello"
  },
  {
    "id": "7311072006",
    "district_id": "731107",
    "label": "Binuang",
    "value": "Binuang"
  },
  {
    "id": "7312011001",
    "district_id": "731201",
    "label": "Labessi",
    "value": "Labessi"
  },
  {
    "id": "7312011002",
    "district_id": "731201",
    "label": "Tettikenrarae",
    "value": "Tettikenrarae"
  },
  {
    "id": "7312012003",
    "district_id": "731201",
    "label": "Gattareng",
    "value": "Gattareng"
  },
  {
    "id": "7312012004",
    "district_id": "731201",
    "label": "Watu",
    "value": "Watu"
  },
  {
    "id": "7312012005",
    "district_id": "731201",
    "label": "Goarie",
    "value": "Goarie"
  },
  {
    "id": "7312012006",
    "district_id": "731201",
    "label": "Barae",
    "value": "Barae"
  },
  {
    "id": "7312012007",
    "district_id": "731201",
    "label": "Marioriaja",
    "value": "Marioriaja"
  },
  {
    "id": "7312012008",
    "district_id": "731201",
    "label": "Marioritengnga",
    "value": "Marioritengnga"
  },
  {
    "id": "7312012009",
    "district_id": "731201",
    "label": "Mariorilau",
    "value": "Mariorilau"
  },
  {
    "id": "7312012010",
    "district_id": "731201",
    "label": "Watu Toa",
    "value": "Watu Toa"
  },
  {
    "id": "7312012011",
    "district_id": "731201",
    "label": "Congko",
    "value": "Congko"
  },
  {
    "id": "7312012012",
    "district_id": "731201",
    "label": "Gattareng Toa",
    "value": "Gattareng Toa"
  },
  {
    "id": "7312012013",
    "district_id": "731201",
    "label": "Soga",
    "value": "Soga"
  },
  {
    "id": "7312021001",
    "district_id": "731202",
    "label": "Appanang",
    "value": "Appanang"
  },
  {
    "id": "7312021002",
    "district_id": "731202",
    "label": "Jennae",
    "value": "Jennae"
  },
  {
    "id": "7312021003",
    "district_id": "731202",
    "label": "Galung",
    "value": "Galung"
  },
  {
    "id": "7312022005",
    "district_id": "731202",
    "label": "Jampu",
    "value": "Jampu"
  },
  {
    "id": "7312022006",
    "district_id": "731202",
    "label": "Pattojo",
    "value": "Pattojo"
  },
  {
    "id": "7312022009",
    "district_id": "731202",
    "label": "Timusu",
    "value": "Timusu"
  },
  {
    "id": "7312022010",
    "district_id": "731202",
    "label": "Rompegading",
    "value": "Rompegading"
  },
  {
    "id": "7312022011",
    "district_id": "731202",
    "label": "Barang",
    "value": "Barang"
  },
  {
    "id": "7312031001",
    "district_id": "731203",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "7312031002",
    "district_id": "731203",
    "label": "Cabenge",
    "value": "Cabenge"
  },
  {
    "id": "7312031003",
    "district_id": "731203",
    "label": "Pajalesang",
    "value": "Pajalesang"
  },
  {
    "id": "7312031004",
    "district_id": "731203",
    "label": "Macanre",
    "value": "Macanre"
  },
  {
    "id": "7312032005",
    "district_id": "731203",
    "label": "Abbanuange",
    "value": "Abbanuange"
  },
  {
    "id": "7312032006",
    "district_id": "731203",
    "label": "Tetewatu",
    "value": "Tetewatu"
  },
  {
    "id": "7312032007",
    "district_id": "731203",
    "label": "Baringeng",
    "value": "Baringeng"
  },
  {
    "id": "7312032009",
    "district_id": "731203",
    "label": "Masing",
    "value": "Masing"
  },
  {
    "id": "7312032010",
    "district_id": "731203",
    "label": "Kebo",
    "value": "Kebo"
  },
  {
    "id": "7312032011",
    "district_id": "731203",
    "label": "Parenring",
    "value": "Parenring"
  },
  {
    "id": "7312032012",
    "district_id": "731203",
    "label": "Paroto",
    "value": "Paroto"
  },
  {
    "id": "7312032013",
    "district_id": "731203",
    "label": "Palangiseng",
    "value": "Palangiseng"
  },
  {
    "id": "7312041001",
    "district_id": "731204",
    "label": "Lalabata Rilau",
    "value": "Lalabata Rilau"
  },
  {
    "id": "7312041002",
    "district_id": "731204",
    "label": "Ompo",
    "value": "Ompo"
  },
  {
    "id": "7312041003",
    "district_id": "731204",
    "label": "Botto",
    "value": "Botto"
  },
  {
    "id": "7312041004",
    "district_id": "731204",
    "label": "Lemba",
    "value": "Lemba"
  },
  {
    "id": "7312041005",
    "district_id": "731204",
    "label": "Bila",
    "value": "Bila"
  },
  {
    "id": "7312041006",
    "district_id": "731204",
    "label": "Lapajung",
    "value": "Lapajung"
  },
  {
    "id": "7312041007",
    "district_id": "731204",
    "label": "Salokaraja",
    "value": "Salokaraja"
  },
  {
    "id": "7312042008",
    "district_id": "731204",
    "label": "Maccile",
    "value": "Maccile"
  },
  {
    "id": "7312042009",
    "district_id": "731204",
    "label": "Umpungeng",
    "value": "Umpungeng"
  },
  {
    "id": "7312042010",
    "district_id": "731204",
    "label": "Mattabulu",
    "value": "Mattabulu"
  },
  {
    "id": "7312051001",
    "district_id": "731205",
    "label": "Batu Batu",
    "value": "Batu Batu"
  },
  {
    "id": "7312051002",
    "district_id": "731205",
    "label": "Kaca",
    "value": "Kaca"
  },
  {
    "id": "7312051003",
    "district_id": "731205",
    "label": "Attang Salo",
    "value": "Attang Salo"
  },
  {
    "id": "7312051004",
    "district_id": "731205",
    "label": "Manorang Salo",
    "value": "Manorang Salo"
  },
  {
    "id": "7312051005",
    "district_id": "731205",
    "label": "Limpomajang",
    "value": "Limpomajang"
  },
  {
    "id": "7312052006",
    "district_id": "731205",
    "label": "Bulue",
    "value": "Bulue"
  },
  {
    "id": "7312052007",
    "district_id": "731205",
    "label": "Panincong",
    "value": "Panincong"
  },
  {
    "id": "7312052008",
    "district_id": "731205",
    "label": "Patampanua",
    "value": "Patampanua"
  },
  {
    "id": "7312052009",
    "district_id": "731205",
    "label": "Tellulimpoe",
    "value": "Tellulimpoe"
  },
  {
    "id": "7312052010",
    "district_id": "731205",
    "label": "Laringgi",
    "value": "Laringgi"
  },
  {
    "id": "7312062002",
    "district_id": "731206",
    "label": "Donri Donri",
    "value": "Donri Donri"
  },
  {
    "id": "7312062003",
    "district_id": "731206",
    "label": "Lalabatariaja",
    "value": "Lalabatariaja"
  },
  {
    "id": "7312062004",
    "district_id": "731206",
    "label": "Leworeng",
    "value": "Leworeng"
  },
  {
    "id": "7312062005",
    "district_id": "731206",
    "label": "Labokong",
    "value": "Labokong"
  },
  {
    "id": "7312062006",
    "district_id": "731206",
    "label": "Sering",
    "value": "Sering"
  },
  {
    "id": "7312062007",
    "district_id": "731206",
    "label": "Pesse",
    "value": "Pesse"
  },
  {
    "id": "7312062008",
    "district_id": "731206",
    "label": "Tottong",
    "value": "Tottong"
  },
  {
    "id": "7312062009",
    "district_id": "731206",
    "label": "Kessing",
    "value": "Kessing"
  },
  {
    "id": "7312062010",
    "district_id": "731206",
    "label": "Pising",
    "value": "Pising"
  },
  {
    "id": "7312072001",
    "district_id": "731207",
    "label": "Belo",
    "value": "Belo"
  },
  {
    "id": "7312072002",
    "district_id": "731207",
    "label": "Ganra",
    "value": "Ganra"
  },
  {
    "id": "7312072003",
    "district_id": "731207",
    "label": "Lompulle",
    "value": "Lompulle"
  },
  {
    "id": "7312072004",
    "district_id": "731207",
    "label": "Enrekeng",
    "value": "Enrekeng"
  },
  {
    "id": "7312082001",
    "district_id": "731208",
    "label": "Citta",
    "value": "Citta"
  },
  {
    "id": "7312082002",
    "district_id": "731208",
    "label": "Kampiri",
    "value": "Kampiri"
  },
  {
    "id": "7312082003",
    "district_id": "731208",
    "label": "Labae",
    "value": "Labae"
  },
  {
    "id": "7312082004",
    "district_id": "731208",
    "label": "Tinco",
    "value": "Tinco"
  },
  {
    "id": "7313011001",
    "district_id": "731301",
    "label": "Walennae",
    "value": "Walennae"
  },
  {
    "id": "7313011002",
    "district_id": "731301",
    "label": "Tolotenreng",
    "value": "Tolotenreng"
  },
  {
    "id": "7313011003",
    "district_id": "731301",
    "label": "Sompe",
    "value": "Sompe"
  },
  {
    "id": "7313012004",
    "district_id": "731301",
    "label": "Liu",
    "value": "Liu"
  },
  {
    "id": "7313012005",
    "district_id": "731301",
    "label": "Ugi",
    "value": "Ugi"
  },
  {
    "id": "7313012006",
    "district_id": "731301",
    "label": "Ujungpero",
    "value": "Ujungpero"
  },
  {
    "id": "7313012007",
    "district_id": "731301",
    "label": "Wage",
    "value": "Wage"
  },
  {
    "id": "7313012008",
    "district_id": "731301",
    "label": "Worongnge",
    "value": "Worongnge"
  },
  {
    "id": "7313012009",
    "district_id": "731301",
    "label": "Salotengnga",
    "value": "Salotengnga"
  },
  {
    "id": "7313012010",
    "district_id": "731301",
    "label": "Pallimae",
    "value": "Pallimae"
  },
  {
    "id": "7313012011",
    "district_id": "731301",
    "label": "Mallusesalo",
    "value": "Mallusesalo"
  },
  {
    "id": "7313012012",
    "district_id": "731301",
    "label": "Pasaka",
    "value": "Pasaka"
  },
  {
    "id": "7313012013",
    "district_id": "731301",
    "label": "Tadangpalie",
    "value": "Tadangpalie"
  },
  {
    "id": "7313012014",
    "district_id": "731301",
    "label": "Benteng Lompoe",
    "value": "Benteng Lompoe"
  },
  {
    "id": "7313012015",
    "district_id": "731301",
    "label": "Bila",
    "value": "Bila"
  },
  {
    "id": "7313021001",
    "district_id": "731302",
    "label": "Pammana",
    "value": "Pammana"
  },
  {
    "id": "7313021002",
    "district_id": "731302",
    "label": "Cina",
    "value": "Cina"
  },
  {
    "id": "7313022003",
    "district_id": "731302",
    "label": "Lempa",
    "value": "Lempa"
  },
  {
    "id": "7313022004",
    "district_id": "731302",
    "label": "Patila",
    "value": "Patila"
  },
  {
    "id": "7313022005",
    "district_id": "731302",
    "label": "Kampiri",
    "value": "Kampiri"
  },
  {
    "id": "7313022006",
    "district_id": "731302",
    "label": "Lapaukke",
    "value": "Lapaukke"
  },
  {
    "id": "7313022007",
    "district_id": "731302",
    "label": "Lagosi",
    "value": "Lagosi"
  },
  {
    "id": "7313022008",
    "district_id": "731302",
    "label": "Pallawarukka",
    "value": "Pallawarukka"
  },
  {
    "id": "7313022009",
    "district_id": "731302",
    "label": "Wecudai",
    "value": "Wecudai"
  },
  {
    "id": "7313022010",
    "district_id": "731302",
    "label": "Lampulung",
    "value": "Lampulung"
  },
  {
    "id": "7313022011",
    "district_id": "731302",
    "label": "Watampanua",
    "value": "Watampanua"
  },
  {
    "id": "7313022012",
    "district_id": "731302",
    "label": "Tadangpalie",
    "value": "Tadangpalie"
  },
  {
    "id": "7313022013",
    "district_id": "731302",
    "label": "Simpursia",
    "value": "Simpursia"
  },
  {
    "id": "7313022014",
    "district_id": "731302",
    "label": "Tobatang",
    "value": "Tobatang"
  },
  {
    "id": "7313022015",
    "district_id": "731302",
    "label": "Abbanuangnge",
    "value": "Abbanuangnge"
  },
  {
    "id": "7313022016",
    "district_id": "731302",
    "label": "Tonrong Tengnga",
    "value": "Tonrong Tengnga"
  },
  {
    "id": "7313031001",
    "district_id": "731303",
    "label": "Peneki",
    "value": "Peneki"
  },
  {
    "id": "7313031002",
    "district_id": "731303",
    "label": "Bocco",
    "value": "Bocco"
  },
  {
    "id": "7313032003",
    "district_id": "731303",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "7313032004",
    "district_id": "731303",
    "label": "Manyili",
    "value": "Manyili"
  },
  {
    "id": "7313032005",
    "district_id": "731303",
    "label": "Soro",
    "value": "Soro"
  },
  {
    "id": "7313032006",
    "district_id": "731303",
    "label": "Ceppaga",
    "value": "Ceppaga"
  },
  {
    "id": "7313032007",
    "district_id": "731303",
    "label": "Leweng",
    "value": "Leweng"
  },
  {
    "id": "7313032008",
    "district_id": "731303",
    "label": "Ajuraja",
    "value": "Ajuraja"
  },
  {
    "id": "7313032009",
    "district_id": "731303",
    "label": "Botto",
    "value": "Botto"
  },
  {
    "id": "7313032010",
    "district_id": "731303",
    "label": "Lagoari",
    "value": "Lagoari"
  },
  {
    "id": "7313032011",
    "district_id": "731303",
    "label": "Aluppang",
    "value": "Aluppang"
  },
  {
    "id": "7313032012",
    "district_id": "731303",
    "label": "Lamarua",
    "value": "Lamarua"
  },
  {
    "id": "7313032013",
    "district_id": "731303",
    "label": "Pantai Timur",
    "value": "Pantai Timur"
  },
  {
    "id": "7313041001",
    "district_id": "731304",
    "label": "Akkajeng",
    "value": "Akkajeng"
  },
  {
    "id": "7313041002",
    "district_id": "731304",
    "label": "Assorajang",
    "value": "Assorajang"
  },
  {
    "id": "7313041003",
    "district_id": "731304",
    "label": "Minangae",
    "value": "Minangae"
  },
  {
    "id": "7313042004",
    "district_id": "731304",
    "label": "Akkotengeng",
    "value": "Akkotengeng"
  },
  {
    "id": "7313042005",
    "district_id": "731304",
    "label": "Sakkoli",
    "value": "Sakkoli"
  },
  {
    "id": "7313042006",
    "district_id": "731304",
    "label": "Barangmamase",
    "value": "Barangmamase"
  },
  {
    "id": "7313042007",
    "district_id": "731304",
    "label": "Salobulo",
    "value": "Salobulo"
  },
  {
    "id": "7313042008",
    "district_id": "731304",
    "label": "Towalida",
    "value": "Towalida"
  },
  {
    "id": "7313042009",
    "district_id": "731304",
    "label": "Alewadeng",
    "value": "Alewadeng"
  },
  {
    "id": "7313051001",
    "district_id": "731305",
    "label": "Paria",
    "value": "Paria"
  },
  {
    "id": "7313051002",
    "district_id": "731305",
    "label": "Limpomajang",
    "value": "Limpomajang"
  },
  {
    "id": "7313051003",
    "district_id": "731305",
    "label": "Macanang",
    "value": "Macanang"
  },
  {
    "id": "7313051004",
    "district_id": "731305",
    "label": "Uraiyang",
    "value": "Uraiyang"
  },
  {
    "id": "7313052005",
    "district_id": "731305",
    "label": "Tosora",
    "value": "Tosora"
  },
  {
    "id": "7313052006",
    "district_id": "731305",
    "label": "Cinnong Tabi",
    "value": "Cinnong Tabi"
  },
  {
    "id": "7313052007",
    "district_id": "731305",
    "label": "Rumpia",
    "value": "Rumpia"
  },
  {
    "id": "7313052008",
    "district_id": "731305",
    "label": "Laerung",
    "value": "Laerung"
  },
  {
    "id": "7313052009",
    "district_id": "731305",
    "label": "Lamiku",
    "value": "Lamiku"
  },
  {
    "id": "7313052010",
    "district_id": "731305",
    "label": "Bottobenteng",
    "value": "Bottobenteng"
  },
  {
    "id": "7313052011",
    "district_id": "731305",
    "label": "Bottotanre",
    "value": "Bottotanre"
  },
  {
    "id": "7313052012",
    "district_id": "731305",
    "label": "Tua",
    "value": "Tua"
  },
  {
    "id": "7313052013",
    "district_id": "731305",
    "label": "Tajo",
    "value": "Tajo"
  },
  {
    "id": "7313052014",
    "district_id": "731305",
    "label": "Tengnga",
    "value": "Tengnga"
  },
  {
    "id": "7313052015",
    "district_id": "731305",
    "label": "Liu",
    "value": "Liu"
  },
  {
    "id": "7313052016",
    "district_id": "731305",
    "label": "Tellulimpoe",
    "value": "Tellulimpoe"
  },
  {
    "id": "7313052017",
    "district_id": "731305",
    "label": "Bottopenno",
    "value": "Bottopenno"
  },
  {
    "id": "7313052018",
    "district_id": "731305",
    "label": "Watanrumpia",
    "value": "Watanrumpia"
  },
  {
    "id": "7313061001",
    "district_id": "731306",
    "label": "Siengkang",
    "value": "Siengkang"
  },
  {
    "id": "7313061002",
    "district_id": "731306",
    "label": "Pattirosompe",
    "value": "Pattirosompe"
  },
  {
    "id": "7313061003",
    "district_id": "731306",
    "label": "Tempe",
    "value": "Tempe"
  },
  {
    "id": "7313061004",
    "district_id": "731306",
    "label": "Maddukelleng",
    "value": "Maddukelleng"
  },
  {
    "id": "7313061005",
    "district_id": "731306",
    "label": "Watallipue",
    "value": "Watallipue"
  },
  {
    "id": "7313061006",
    "district_id": "731306",
    "label": "Mattiro Tappareng",
    "value": "Mattiro Tappareng"
  },
  {
    "id": "7313061007",
    "district_id": "731306",
    "label": "Laelo",
    "value": "Laelo"
  },
  {
    "id": "7313061008",
    "district_id": "731306",
    "label": "Salo Menraleng",
    "value": "Salo Menraleng"
  },
  {
    "id": "7313061009",
    "district_id": "731306",
    "label": "Campalagi",
    "value": "Campalagi"
  },
  {
    "id": "7313061010",
    "district_id": "731306",
    "label": "Lapongkoda",
    "value": "Lapongkoda"
  },
  {
    "id": "7313061011",
    "district_id": "731306",
    "label": "Teddaopu",
    "value": "Teddaopu"
  },
  {
    "id": "7313061012",
    "district_id": "731306",
    "label": "Paddupa",
    "value": "Paddupa"
  },
  {
    "id": "7313061013",
    "district_id": "731306",
    "label": "Wiring Palenae",
    "value": "Wiring Palenae"
  },
  {
    "id": "7313061014",
    "district_id": "731306",
    "label": "Attakkae",
    "value": "Attakkae"
  },
  {
    "id": "7313061015",
    "district_id": "731306",
    "label": "Sitampae",
    "value": "Sitampae"
  },
  {
    "id": "7313061016",
    "district_id": "731306",
    "label": "Bulu Pabbulu",
    "value": "Bulu Pabbulu"
  },
  {
    "id": "7313071001",
    "district_id": "731307",
    "label": "Macero",
    "value": "Macero"
  },
  {
    "id": "7313071002",
    "district_id": "731307",
    "label": "Malangke",
    "value": "Malangke"
  },
  {
    "id": "7313071003",
    "district_id": "731307",
    "label": "Belawa",
    "value": "Belawa"
  },
  {
    "id": "7313072004",
    "district_id": "731307",
    "label": "Ongkoe",
    "value": "Ongkoe"
  },
  {
    "id": "7313072005",
    "district_id": "731307",
    "label": "Leppangeng",
    "value": "Leppangeng"
  },
  {
    "id": "7313072006",
    "district_id": "731307",
    "label": "Wele",
    "value": "Wele"
  },
  {
    "id": "7313072007",
    "district_id": "731307",
    "label": "Limporilau",
    "value": "Limporilau"
  },
  {
    "id": "7313072008",
    "district_id": "731307",
    "label": "Sappa",
    "value": "Sappa"
  },
  {
    "id": "7313072009",
    "district_id": "731307",
    "label": "Lautang",
    "value": "Lautang"
  },
  {
    "id": "7313081001",
    "district_id": "731308",
    "label": "Tancung",
    "value": "Tancung"
  },
  {
    "id": "7313081002",
    "district_id": "731308",
    "label": "Mappadaelo",
    "value": "Mappadaelo"
  },
  {
    "id": "7313081003",
    "district_id": "731308",
    "label": "Pincengpute",
    "value": "Pincengpute"
  },
  {
    "id": "7313081004",
    "district_id": "731308",
    "label": "Barutancung",
    "value": "Barutancung"
  },
  {
    "id": "7313082005",
    "district_id": "731308",
    "label": "Nepo",
    "value": "Nepo"
  },
  {
    "id": "7313082006",
    "district_id": "731308",
    "label": "Lowa",
    "value": "Lowa"
  },
  {
    "id": "7313082007",
    "district_id": "731308",
    "label": "Inalipue",
    "value": "Inalipue"
  },
  {
    "id": "7313082008",
    "district_id": "731308",
    "label": "Pakkana",
    "value": "Pakkana"
  },
  {
    "id": "7313082009",
    "district_id": "731308",
    "label": "Wajoriaja",
    "value": "Wajoriaja"
  },
  {
    "id": "7313082010",
    "district_id": "731308",
    "label": "Wewangrewu",
    "value": "Wewangrewu"
  },
  {
    "id": "7313082011",
    "district_id": "731308",
    "label": "Wae Tuwo",
    "value": "Wae Tuwo"
  },
  {
    "id": "7313082012",
    "district_id": "731308",
    "label": "Assorajang",
    "value": "Assorajang"
  },
  {
    "id": "7313082013",
    "district_id": "731308",
    "label": "Ujungnge",
    "value": "Ujungnge"
  },
  {
    "id": "7313082014",
    "district_id": "731308",
    "label": "Pajalele",
    "value": "Pajalele"
  },
  {
    "id": "7313082015",
    "district_id": "731308",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7313082016",
    "district_id": "731308",
    "label": "Palippu",
    "value": "Palippu"
  },
  {
    "id": "7313082017",
    "district_id": "731308",
    "label": "Tonralipue",
    "value": "Tonralipue"
  },
  {
    "id": "7313082018",
    "district_id": "731308",
    "label": "Ujungbaru",
    "value": "Ujungbaru"
  },
  {
    "id": "7313082019",
    "district_id": "731308",
    "label": "Mannagae",
    "value": "Mannagae"
  },
  {
    "id": "7313091001",
    "district_id": "731309",
    "label": "Anabanua",
    "value": "Anabanua"
  },
  {
    "id": "7313091002",
    "district_id": "731309",
    "label": "Dualimpoe",
    "value": "Dualimpoe"
  },
  {
    "id": "7313091003",
    "district_id": "731309",
    "label": "Tangkoli",
    "value": "Tangkoli"
  },
  {
    "id": "7313092004",
    "district_id": "731309",
    "label": "Mattirowalie",
    "value": "Mattirowalie"
  },
  {
    "id": "7313092005",
    "district_id": "731309",
    "label": "Kalola",
    "value": "Kalola"
  },
  {
    "id": "7313092006",
    "district_id": "731309",
    "label": "Sogi",
    "value": "Sogi"
  },
  {
    "id": "7313092007",
    "district_id": "731309",
    "label": "Abbanuangnge",
    "value": "Abbanuangnge"
  },
  {
    "id": "7313092008",
    "district_id": "731309",
    "label": "Minagatellue",
    "value": "Minagatellue"
  },
  {
    "id": "7313101001",
    "district_id": "731310",
    "label": "Bulete",
    "value": "Bulete"
  },
  {
    "id": "7313101002",
    "district_id": "731310",
    "label": "Siwa",
    "value": "Siwa"
  },
  {
    "id": "7313101003",
    "district_id": "731310",
    "label": "Tobarakka",
    "value": "Tobarakka"
  },
  {
    "id": "7313101004",
    "district_id": "731310",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7313102005",
    "district_id": "731310",
    "label": "Batu",
    "value": "Batu"
  },
  {
    "id": "7313102006",
    "district_id": "731310",
    "label": "Lauwa",
    "value": "Lauwa"
  },
  {
    "id": "7313102007",
    "district_id": "731310",
    "label": "Tanrongi",
    "value": "Tanrongi"
  },
  {
    "id": "7313102008",
    "district_id": "731310",
    "label": "Lompoloang",
    "value": "Lompoloang"
  },
  {
    "id": "7313102009",
    "district_id": "731310",
    "label": "Tellesang",
    "value": "Tellesang"
  },
  {
    "id": "7313102010",
    "district_id": "731310",
    "label": "Tangkoro",
    "value": "Tangkoro"
  },
  {
    "id": "7313102011",
    "district_id": "731310",
    "label": "Marannu",
    "value": "Marannu"
  },
  {
    "id": "7313102012",
    "district_id": "731310",
    "label": "Abbanderangnge",
    "value": "Abbanderangnge"
  },
  {
    "id": "7313102013",
    "district_id": "731310",
    "label": "Simpellu",
    "value": "Simpellu"
  },
  {
    "id": "7313102014",
    "district_id": "731310",
    "label": "Alesilurengnge",
    "value": "Alesilurengnge"
  },
  {
    "id": "7313102015",
    "district_id": "731310",
    "label": "Jauh Pandang",
    "value": "Jauh Pandang"
  },
  {
    "id": "7313102016",
    "district_id": "731310",
    "label": "Lacinde",
    "value": "Lacinde"
  },
  {
    "id": "7313102017",
    "district_id": "731310",
    "label": "Buriko",
    "value": "Buriko"
  },
  {
    "id": "7313102018",
    "district_id": "731310",
    "label": "Bau-Bau",
    "value": "Bau-Bau"
  },
  {
    "id": "7313102019",
    "district_id": "731310",
    "label": "Ale Lebbae",
    "value": "Ale Lebbae"
  },
  {
    "id": "7313102020",
    "district_id": "731310",
    "label": "Bulu Siwa",
    "value": "Bulu Siwa"
  },
  {
    "id": "7313102021",
    "district_id": "731310",
    "label": "Botto Tengnga",
    "value": "Botto Tengnga"
  },
  {
    "id": "7313102022",
    "district_id": "731310",
    "label": "Kaluku",
    "value": "Kaluku"
  },
  {
    "id": "7313102023",
    "district_id": "731310",
    "label": "Mattiro Walie",
    "value": "Mattiro Walie"
  },
  {
    "id": "7313102024",
    "district_id": "731310",
    "label": "Maccolli Loloe",
    "value": "Maccolli Loloe"
  },
  {
    "id": "7313102025",
    "district_id": "731310",
    "label": "Lompo Bulo",
    "value": "Lompo Bulo"
  },
  {
    "id": "7313102026",
    "district_id": "731310",
    "label": "Padang Loang",
    "value": "Padang Loang"
  },
  {
    "id": "7313102027",
    "district_id": "731310",
    "label": "Kompong",
    "value": "Kompong"
  },
  {
    "id": "7313111001",
    "district_id": "731311",
    "label": "Solo",
    "value": "Solo"
  },
  {
    "id": "7313112002",
    "district_id": "731311",
    "label": "Bola",
    "value": "Bola"
  },
  {
    "id": "7313112003",
    "district_id": "731311",
    "label": "Ujungtanah",
    "value": "Ujungtanah"
  },
  {
    "id": "7313112004",
    "district_id": "731311",
    "label": "Lempong",
    "value": "Lempong"
  },
  {
    "id": "7313112005",
    "district_id": "731311",
    "label": "Sanreseng Ade",
    "value": "Sanreseng Ade"
  },
  {
    "id": "7313112006",
    "district_id": "731311",
    "label": "Pattangngae",
    "value": "Pattangngae"
  },
  {
    "id": "7313112007",
    "district_id": "731311",
    "label": "Balielo",
    "value": "Balielo"
  },
  {
    "id": "7313112008",
    "district_id": "731311",
    "label": "Manurung",
    "value": "Manurung"
  },
  {
    "id": "7313112009",
    "district_id": "731311",
    "label": "Lattimu",
    "value": "Lattimu"
  },
  {
    "id": "7313112010",
    "district_id": "731311",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7313112011",
    "district_id": "731311",
    "label": "Rajamawellang",
    "value": "Rajamawellang"
  },
  {
    "id": "7313121001",
    "district_id": "731312",
    "label": "Doping",
    "value": "Doping"
  },
  {
    "id": "7313122002",
    "district_id": "731312",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7313122003",
    "district_id": "731312",
    "label": "Temmabarang",
    "value": "Temmabarang"
  },
  {
    "id": "7313122004",
    "district_id": "731312",
    "label": "Penrang",
    "value": "Penrang"
  },
  {
    "id": "7313122005",
    "district_id": "731312",
    "label": "Lawesso",
    "value": "Lawesso"
  },
  {
    "id": "7313122006",
    "district_id": "731312",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7313122007",
    "district_id": "731312",
    "label": "Walanga",
    "value": "Walanga"
  },
  {
    "id": "7313122008",
    "district_id": "731312",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "7313122009",
    "district_id": "731312",
    "label": "Tadangpalie",
    "value": "Tadangpalie"
  },
  {
    "id": "7313122010",
    "district_id": "731312",
    "label": "Raddae",
    "value": "Raddae"
  },
  {
    "id": "7313131002",
    "district_id": "731313",
    "label": "Gilireng",
    "value": "Gilireng"
  },
  {
    "id": "7313132001",
    "district_id": "731313",
    "label": "Mamminasae",
    "value": "Mamminasae"
  },
  {
    "id": "7313132003",
    "district_id": "731313",
    "label": "Poleonro",
    "value": "Poleonro"
  },
  {
    "id": "7313132004",
    "district_id": "731313",
    "label": "Arajang",
    "value": "Arajang"
  },
  {
    "id": "7313132005",
    "district_id": "731313",
    "label": "Lamata",
    "value": "Lamata"
  },
  {
    "id": "7313132006",
    "district_id": "731313",
    "label": "Paselloreng",
    "value": "Paselloreng"
  },
  {
    "id": "7313132007",
    "district_id": "731313",
    "label": "Alausalo",
    "value": "Alausalo"
  },
  {
    "id": "7313132008",
    "district_id": "731313",
    "label": "Polewalie",
    "value": "Polewalie"
  },
  {
    "id": "7313132009",
    "district_id": "731313",
    "label": "Abbatireng",
    "value": "Abbatireng"
  },
  {
    "id": "7313141001",
    "district_id": "731314",
    "label": "Ballaere",
    "value": "Ballaere"
  },
  {
    "id": "7313142002",
    "district_id": "731314",
    "label": "Awota",
    "value": "Awota"
  },
  {
    "id": "7313142003",
    "district_id": "731314",
    "label": "Keera",
    "value": "Keera"
  },
  {
    "id": "7313142004",
    "district_id": "731314",
    "label": "Lalliseng",
    "value": "Lalliseng"
  },
  {
    "id": "7313142005",
    "district_id": "731314",
    "label": "Paojepe",
    "value": "Paojepe"
  },
  {
    "id": "7313142006",
    "district_id": "731314",
    "label": "Inrello",
    "value": "Inrello"
  },
  {
    "id": "7313142007",
    "district_id": "731314",
    "label": "Pattirolokka",
    "value": "Pattirolokka"
  },
  {
    "id": "7313142008",
    "district_id": "731314",
    "label": "Awo",
    "value": "Awo"
  },
  {
    "id": "7313142009",
    "district_id": "731314",
    "label": "Ciromanie",
    "value": "Ciromanie"
  },
  {
    "id": "7313142010",
    "district_id": "731314",
    "label": "Labawang",
    "value": "Labawang"
  },
  {
    "id": "7314011001",
    "district_id": "731401",
    "label": "Bilokka",
    "value": "Bilokka"
  },
  {
    "id": "7314011002",
    "district_id": "731401",
    "label": "Wetee",
    "value": "Wetee"
  },
  {
    "id": "7314011003",
    "district_id": "731401",
    "label": "Lajonga",
    "value": "Lajonga"
  },
  {
    "id": "7314012004",
    "district_id": "731401",
    "label": "Wanio",
    "value": "Wanio"
  },
  {
    "id": "7314012005",
    "district_id": "731401",
    "label": "Wanio Timoreng",
    "value": "Wanio Timoreng"
  },
  {
    "id": "7314012006",
    "district_id": "731401",
    "label": "Corawali",
    "value": "Corawali"
  },
  {
    "id": "7314012007",
    "district_id": "731401",
    "label": "Lise",
    "value": "Lise"
  },
  {
    "id": "7314012008",
    "district_id": "731401",
    "label": "Bapangi",
    "value": "Bapangi"
  },
  {
    "id": "7314012009",
    "district_id": "731401",
    "label": "Alesalewo",
    "value": "Alesalewo"
  },
  {
    "id": "7314012010",
    "district_id": "731401",
    "label": "Cenrana",
    "value": "Cenrana"
  },
  {
    "id": "7314021001",
    "district_id": "731402",
    "label": "Amparita",
    "value": "Amparita"
  },
  {
    "id": "7314021002",
    "district_id": "731402",
    "label": "Messape",
    "value": "Messape"
  },
  {
    "id": "7314021003",
    "district_id": "731402",
    "label": "Toddang Pulu",
    "value": "Toddang Pulu"
  },
  {
    "id": "7314021004",
    "district_id": "731402",
    "label": "Pajalele",
    "value": "Pajalele"
  },
  {
    "id": "7314021005",
    "district_id": "731402",
    "label": "Baula",
    "value": "Baula"
  },
  {
    "id": "7314021006",
    "district_id": "731402",
    "label": "Arateng",
    "value": "Arateng"
  },
  {
    "id": "7314022007",
    "district_id": "731402",
    "label": "Teteaji",
    "value": "Teteaji"
  },
  {
    "id": "7314022008",
    "district_id": "731402",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7314022009",
    "district_id": "731402",
    "label": "Teppo",
    "value": "Teppo"
  },
  {
    "id": "7314031001",
    "district_id": "731403",
    "label": "Lawawoi",
    "value": "Lawawoi"
  },
  {
    "id": "7314031002",
    "district_id": "731403",
    "label": "Arawa",
    "value": "Arawa"
  },
  {
    "id": "7314031003",
    "district_id": "731403",
    "label": "Bangkai",
    "value": "Bangkai"
  },
  {
    "id": "7314031004",
    "district_id": "731403",
    "label": "Uluale",
    "value": "Uluale"
  },
  {
    "id": "7314031005",
    "district_id": "731403",
    "label": "Batu Lappa",
    "value": "Batu Lappa"
  },
  {
    "id": "7314032006",
    "district_id": "731403",
    "label": "Lainungan",
    "value": "Lainungan"
  },
  {
    "id": "7314032007",
    "district_id": "731403",
    "label": "Carawali",
    "value": "Carawali"
  },
  {
    "id": "7314032008",
    "district_id": "731403",
    "label": "Buae",
    "value": "Buae"
  },
  {
    "id": "7314032009",
    "district_id": "731403",
    "label": "Mattirotasi",
    "value": "Mattirotasi"
  },
  {
    "id": "7314032010",
    "district_id": "731403",
    "label": "Ciro-Ciroe",
    "value": "Ciro-Ciroe"
  },
  {
    "id": "7314041001",
    "district_id": "731404",
    "label": "Baranti",
    "value": "Baranti"
  },
  {
    "id": "7314041002",
    "district_id": "731404",
    "label": "Duampanua",
    "value": "Duampanua"
  },
  {
    "id": "7314041003",
    "district_id": "731404",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7314041004",
    "district_id": "731404",
    "label": "Mamminasae",
    "value": "Mamminasae"
  },
  {
    "id": "7314041005",
    "district_id": "731404",
    "label": "Benteng Utama",
    "value": "Benteng Utama"
  },
  {
    "id": "7314042006",
    "district_id": "731404",
    "label": "Passeno",
    "value": "Passeno"
  },
  {
    "id": "7314042007",
    "district_id": "731404",
    "label": "Sipodeceng",
    "value": "Sipodeceng"
  },
  {
    "id": "7314042008",
    "district_id": "731404",
    "label": "Tonronge",
    "value": "Tonronge"
  },
  {
    "id": "7314042009",
    "district_id": "731404",
    "label": "Tonrong Rijang",
    "value": "Tonrong Rijang"
  },
  {
    "id": "7314051001",
    "district_id": "731405",
    "label": "Rappang",
    "value": "Rappang"
  },
  {
    "id": "7314051002",
    "district_id": "731405",
    "label": "Lelebata",
    "value": "Lelebata"
  },
  {
    "id": "7314051003",
    "district_id": "731405",
    "label": "Maccorawalie",
    "value": "Maccorawalie"
  },
  {
    "id": "7314051004",
    "district_id": "731405",
    "label": "Kadidi",
    "value": "Kadidi"
  },
  {
    "id": "7314052005",
    "district_id": "731405",
    "label": "Timoreng Panua",
    "value": "Timoreng Panua"
  },
  {
    "id": "7314052006",
    "district_id": "731405",
    "label": "Bulo Wattang",
    "value": "Bulo Wattang"
  },
  {
    "id": "7314052007",
    "district_id": "731405",
    "label": "Bulo",
    "value": "Bulo"
  },
  {
    "id": "7314052008",
    "district_id": "731405",
    "label": "Cipotakari",
    "value": "Cipotakari"
  },
  {
    "id": "7314062001",
    "district_id": "731406",
    "label": "Rijang Panua",
    "value": "Rijang Panua"
  },
  {
    "id": "7314062002",
    "district_id": "731406",
    "label": "Kulo",
    "value": "Kulo"
  },
  {
    "id": "7314062003",
    "district_id": "731406",
    "label": "Maddenra",
    "value": "Maddenra"
  },
  {
    "id": "7314062004",
    "district_id": "731406",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7314062005",
    "district_id": "731406",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "7314062006",
    "district_id": "731406",
    "label": "Abbokongang",
    "value": "Abbokongang"
  },
  {
    "id": "7314071001",
    "district_id": "731407",
    "label": "Pangkajene",
    "value": "Pangkajene"
  },
  {
    "id": "7314071002",
    "district_id": "731407",
    "label": "Lautang Benteng",
    "value": "Lautang Benteng"
  },
  {
    "id": "7314071003",
    "district_id": "731407",
    "label": "Majjelling",
    "value": "Majjelling"
  },
  {
    "id": "7314071004",
    "district_id": "731407",
    "label": "Wala",
    "value": "Wala"
  },
  {
    "id": "7314071005",
    "district_id": "731407",
    "label": "Lekessi",
    "value": "Lekessi"
  },
  {
    "id": "7314071006",
    "district_id": "731407",
    "label": "Rijang Pitu",
    "value": "Rijang Pitu"
  },
  {
    "id": "7314071007",
    "district_id": "731407",
    "label": "Majjelling Watang",
    "value": "Majjelling Watang"
  },
  {
    "id": "7314072008",
    "district_id": "731407",
    "label": "Sereang",
    "value": "Sereang"
  },
  {
    "id": "7314072009",
    "district_id": "731407",
    "label": "Kanie",
    "value": "Kanie"
  },
  {
    "id": "7314072010",
    "district_id": "731407",
    "label": "Allakuang",
    "value": "Allakuang"
  },
  {
    "id": "7314072011",
    "district_id": "731407",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7314072012",
    "district_id": "731407",
    "label": "Takkalasi",
    "value": "Takkalasi"
  },
  {
    "id": "7314081001",
    "district_id": "731408",
    "label": "Empagae",
    "value": "Empagae"
  },
  {
    "id": "7314081002",
    "district_id": "731408",
    "label": "Watang Sidenreng",
    "value": "Watang Sidenreng"
  },
  {
    "id": "7314081003",
    "district_id": "731408",
    "label": "Kanyuara",
    "value": "Kanyuara"
  },
  {
    "id": "7314082004",
    "district_id": "731408",
    "label": "Mojong",
    "value": "Mojong"
  },
  {
    "id": "7314082005",
    "district_id": "731408",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "7314082006",
    "district_id": "731408",
    "label": "Talumae",
    "value": "Talumae"
  },
  {
    "id": "7314082007",
    "district_id": "731408",
    "label": "Aka-akae",
    "value": "Aka-akae"
  },
  {
    "id": "7314082008",
    "district_id": "731408",
    "label": "Talawe",
    "value": "Talawe"
  },
  {
    "id": "7314091001",
    "district_id": "731409",
    "label": "Tanru Tedong",
    "value": "Tanru Tedong"
  },
  {
    "id": "7314091002",
    "district_id": "731409",
    "label": "Salomalori",
    "value": "Salomalori"
  },
  {
    "id": "7314092003",
    "district_id": "731409",
    "label": "Kalosi",
    "value": "Kalosi"
  },
  {
    "id": "7314092004",
    "district_id": "731409",
    "label": "Padangloang",
    "value": "Padangloang"
  },
  {
    "id": "7314092005",
    "district_id": "731409",
    "label": "Kalosi Alau",
    "value": "Kalosi Alau"
  },
  {
    "id": "7314092006",
    "district_id": "731409",
    "label": "Bila",
    "value": "Bila"
  },
  {
    "id": "7314092007",
    "district_id": "731409",
    "label": "Padangloang Alau",
    "value": "Padangloang Alau"
  },
  {
    "id": "7314092008",
    "district_id": "731409",
    "label": "Salobukkang",
    "value": "Salobukkang"
  },
  {
    "id": "7314092009",
    "district_id": "731409",
    "label": "Taccimpo",
    "value": "Taccimpo"
  },
  {
    "id": "7314092010",
    "district_id": "731409",
    "label": "Kampale",
    "value": "Kampale"
  },
  {
    "id": "7314101001",
    "district_id": "731410",
    "label": "Lancirang",
    "value": "Lancirang"
  },
  {
    "id": "7314101002",
    "district_id": "731410",
    "label": "Ponrengae",
    "value": "Ponrengae"
  },
  {
    "id": "7314102003",
    "district_id": "731410",
    "label": "Ajubissue",
    "value": "Ajubissue"
  },
  {
    "id": "7314102004",
    "district_id": "731410",
    "label": "Otting",
    "value": "Otting"
  },
  {
    "id": "7314102005",
    "district_id": "731410",
    "label": "Bulucenrana",
    "value": "Bulucenrana"
  },
  {
    "id": "7314102006",
    "district_id": "731410",
    "label": "Betao",
    "value": "Betao"
  },
  {
    "id": "7314102007",
    "district_id": "731410",
    "label": "Anabannae",
    "value": "Anabannae"
  },
  {
    "id": "7314102008",
    "district_id": "731410",
    "label": "Betao Riase",
    "value": "Betao Riase"
  },
  {
    "id": "7314102009",
    "district_id": "731410",
    "label": "Dongi",
    "value": "Dongi"
  },
  {
    "id": "7314102010",
    "district_id": "731410",
    "label": "Lasiwala",
    "value": "Lasiwala"
  },
  {
    "id": "7314102011",
    "district_id": "731410",
    "label": "Sumpang Mango",
    "value": "Sumpang Mango"
  },
  {
    "id": "7314102012",
    "district_id": "731410",
    "label": "Kalempang",
    "value": "Kalempang"
  },
  {
    "id": "7314111001",
    "district_id": "731411",
    "label": "Batu",
    "value": "Batu"
  },
  {
    "id": "7314112002",
    "district_id": "731411",
    "label": "Compong",
    "value": "Compong"
  },
  {
    "id": "7314112003",
    "district_id": "731411",
    "label": "Belawae",
    "value": "Belawae"
  },
  {
    "id": "7314112004",
    "district_id": "731411",
    "label": "Botto",
    "value": "Botto"
  },
  {
    "id": "7314112005",
    "district_id": "731411",
    "label": "Bila Riase",
    "value": "Bila Riase"
  },
  {
    "id": "7314112006",
    "district_id": "731411",
    "label": "Bola Bulu",
    "value": "Bola Bulu"
  },
  {
    "id": "7314112007",
    "district_id": "731411",
    "label": "Tana Toro",
    "value": "Tana Toro"
  },
  {
    "id": "7314112008",
    "district_id": "731411",
    "label": "Lombo",
    "value": "Lombo"
  },
  {
    "id": "7314112009",
    "district_id": "731411",
    "label": "Lagading",
    "value": "Lagading"
  },
  {
    "id": "7314112010",
    "district_id": "731411",
    "label": "Dengeng-Dengeng",
    "value": "Dengeng-Dengeng"
  },
  {
    "id": "7314112011",
    "district_id": "731411",
    "label": "Buntu Buanging",
    "value": "Buntu Buanging"
  },
  {
    "id": "7314112012",
    "district_id": "731411",
    "label": "Leppangeng",
    "value": "Leppangeng"
  },
  {
    "id": "7315011001",
    "district_id": "731501",
    "label": "Langnga",
    "value": "Langnga"
  },
  {
    "id": "7315011002",
    "district_id": "731501",
    "label": "Pallameang",
    "value": "Pallameang"
  },
  {
    "id": "7315012003",
    "district_id": "731501",
    "label": "Massulowalie",
    "value": "Massulowalie"
  },
  {
    "id": "7315012004",
    "district_id": "731501",
    "label": "Mattombong",
    "value": "Mattombong"
  },
  {
    "id": "7315012005",
    "district_id": "731501",
    "label": "Mattongang Tongang",
    "value": "Mattongang Tongang"
  },
  {
    "id": "7315012006",
    "district_id": "731501",
    "label": "Patobong",
    "value": "Patobong"
  },
  {
    "id": "7315012007",
    "district_id": "731501",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7315012008",
    "district_id": "731501",
    "label": "Mattirotasi",
    "value": "Mattirotasi"
  },
  {
    "id": "7315012009",
    "district_id": "731501",
    "label": "Siwolong Polong",
    "value": "Siwolong Polong"
  },
  {
    "id": "7315021001",
    "district_id": "731502",
    "label": "Watang Suppa",
    "value": "Watang Suppa"
  },
  {
    "id": "7315021002",
    "district_id": "731502",
    "label": "Tellumpanua",
    "value": "Tellumpanua"
  },
  {
    "id": "7315022003",
    "district_id": "731502",
    "label": "Lero",
    "value": "Lero"
  },
  {
    "id": "7315022004",
    "district_id": "731502",
    "label": "Watang Pulu",
    "value": "Watang Pulu"
  },
  {
    "id": "7315022005",
    "district_id": "731502",
    "label": "Maritengngae",
    "value": "Maritengngae"
  },
  {
    "id": "7315022006",
    "district_id": "731502",
    "label": "Tasiwalie",
    "value": "Tasiwalie"
  },
  {
    "id": "7315022007",
    "district_id": "731502",
    "label": "Wiringtasi",
    "value": "Wiringtasi"
  },
  {
    "id": "7315022008",
    "district_id": "731502",
    "label": "Lotang Salo",
    "value": "Lotang Salo"
  },
  {
    "id": "7315022009",
    "district_id": "731502",
    "label": "Ujung Labuang",
    "value": "Ujung Labuang"
  },
  {
    "id": "7315022010",
    "district_id": "731502",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7315031001",
    "district_id": "731503",
    "label": "Manarang",
    "value": "Manarang"
  },
  {
    "id": "7315031002",
    "district_id": "731503",
    "label": "Padaidi",
    "value": "Padaidi"
  },
  {
    "id": "7315032003",
    "district_id": "731503",
    "label": "Pananrang",
    "value": "Pananrang"
  },
  {
    "id": "7315032004",
    "district_id": "731503",
    "label": "Padakkalawa",
    "value": "Padakkalawa"
  },
  {
    "id": "7315032005",
    "district_id": "731503",
    "label": "Marannu",
    "value": "Marannu"
  },
  {
    "id": "7315032006",
    "district_id": "731503",
    "label": "Alitta",
    "value": "Alitta"
  },
  {
    "id": "7315032007",
    "district_id": "731503",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7315032008",
    "district_id": "731503",
    "label": "Bunga",
    "value": "Bunga"
  },
  {
    "id": "7315032009",
    "district_id": "731503",
    "label": "Makkawaru",
    "value": "Makkawaru"
  },
  {
    "id": "7315041001",
    "district_id": "731504",
    "label": "Jaya",
    "value": "Jaya"
  },
  {
    "id": "7315041002",
    "district_id": "731504",
    "label": "Sawito",
    "value": "Sawito"
  },
  {
    "id": "7315041003",
    "district_id": "731504",
    "label": "Maccoralaie",
    "value": "Maccoralaie"
  },
  {
    "id": "7315041004",
    "district_id": "731504",
    "label": "Penrang",
    "value": "Penrang"
  },
  {
    "id": "7315041005",
    "district_id": "731504",
    "label": "Salo",
    "value": "Salo"
  },
  {
    "id": "7315041006",
    "district_id": "731504",
    "label": "Bentengnge",
    "value": "Bentengnge"
  },
  {
    "id": "7315041007",
    "district_id": "731504",
    "label": "Sipatokkong",
    "value": "Sipatokkong"
  },
  {
    "id": "7315041008",
    "district_id": "731504",
    "label": "Siparappe",
    "value": "Siparappe"
  },
  {
    "id": "7315051001",
    "district_id": "731505",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7315051002",
    "district_id": "731505",
    "label": "Teppo",
    "value": "Teppo"
  },
  {
    "id": "7315051003",
    "district_id": "731505",
    "label": "Tonyamang",
    "value": "Tonyamang"
  },
  {
    "id": "7315051004",
    "district_id": "731505",
    "label": "Maccirinna",
    "value": "Maccirinna"
  },
  {
    "id": "7315052005",
    "district_id": "731505",
    "label": "Leppangang",
    "value": "Leppangang"
  },
  {
    "id": "7315052006",
    "district_id": "731505",
    "label": "Malimpung",
    "value": "Malimpung"
  },
  {
    "id": "7315052007",
    "district_id": "731505",
    "label": "Mattiroade",
    "value": "Mattiroade"
  },
  {
    "id": "7315052008",
    "district_id": "731505",
    "label": "Sipatuo",
    "value": "Sipatuo"
  },
  {
    "id": "7315052009",
    "district_id": "731505",
    "label": "Padangloang",
    "value": "Padangloang"
  },
  {
    "id": "7315052010",
    "district_id": "731505",
    "label": "Pincara",
    "value": "Pincara"
  },
  {
    "id": "7315052011",
    "district_id": "731505",
    "label": "Masolo",
    "value": "Masolo"
  },
  {
    "id": "7315061001",
    "district_id": "731506",
    "label": "Pekkabata",
    "value": "Pekkabata"
  },
  {
    "id": "7315061002",
    "district_id": "731506",
    "label": "Tatae",
    "value": "Tatae"
  },
  {
    "id": "7315061003",
    "district_id": "731506",
    "label": "Lampa",
    "value": "Lampa"
  },
  {
    "id": "7315061004",
    "district_id": "731506",
    "label": "Data",
    "value": "Data"
  },
  {
    "id": "7315061005",
    "district_id": "731506",
    "label": "Bittoeng",
    "value": "Bittoeng"
  },
  {
    "id": "7315062006",
    "district_id": "731506",
    "label": "Kaballangan",
    "value": "Kaballangan"
  },
  {
    "id": "7315062007",
    "district_id": "731506",
    "label": "Paria",
    "value": "Paria"
  },
  {
    "id": "7315062008",
    "district_id": "731506",
    "label": "Kaliang",
    "value": "Kaliang"
  },
  {
    "id": "7315062009",
    "district_id": "731506",
    "label": "Battusawe",
    "value": "Battusawe"
  },
  {
    "id": "7315062010",
    "district_id": "731506",
    "label": "Maroneng",
    "value": "Maroneng"
  },
  {
    "id": "7315062011",
    "district_id": "731506",
    "label": "Katomporang",
    "value": "Katomporang"
  },
  {
    "id": "7315062012",
    "district_id": "731506",
    "label": "Bungi",
    "value": "Bungi"
  },
  {
    "id": "7315062013",
    "district_id": "731506",
    "label": "Massewae",
    "value": "Massewae"
  },
  {
    "id": "7315062014",
    "district_id": "731506",
    "label": "Bababinanga",
    "value": "Bababinanga"
  },
  {
    "id": "7315062015",
    "district_id": "731506",
    "label": "Barugae",
    "value": "Barugae"
  },
  {
    "id": "7315071001",
    "district_id": "731507",
    "label": "Taddokong",
    "value": "Taddokong"
  },
  {
    "id": "7315071002",
    "district_id": "731507",
    "label": "Betteng",
    "value": "Betteng"
  },
  {
    "id": "7315072003",
    "district_id": "731507",
    "label": "Binanga Karaeng",
    "value": "Binanga Karaeng"
  },
  {
    "id": "7315072004",
    "district_id": "731507",
    "label": "Rajang",
    "value": "Rajang"
  },
  {
    "id": "7315072005",
    "district_id": "731507",
    "label": "Letta",
    "value": "Letta"
  },
  {
    "id": "7315072006",
    "district_id": "731507",
    "label": "Ulusaddang",
    "value": "Ulusaddang"
  },
  {
    "id": "7315072007",
    "district_id": "731507",
    "label": "Suppirang",
    "value": "Suppirang"
  },
  {
    "id": "7315072008",
    "district_id": "731507",
    "label": "Basseang",
    "value": "Basseang"
  },
  {
    "id": "7315072009",
    "district_id": "731507",
    "label": "Kariango",
    "value": "Kariango"
  },
  {
    "id": "7315072010",
    "district_id": "731507",
    "label": "Benteng Paremba",
    "value": "Benteng Paremba"
  },
  {
    "id": "7315072011",
    "district_id": "731507",
    "label": "Pakeng",
    "value": "Pakeng"
  },
  {
    "id": "7315072012",
    "district_id": "731507",
    "label": "Lembang Mesakada",
    "value": "Lembang Mesakada"
  },
  {
    "id": "7315072013",
    "district_id": "731507",
    "label": "Sali Sali",
    "value": "Sali Sali"
  },
  {
    "id": "7315072014",
    "district_id": "731507",
    "label": "Sabbang Paru",
    "value": "Sabbang Paru"
  },
  {
    "id": "7315072015",
    "district_id": "731507",
    "label": "Bakaru",
    "value": "Bakaru"
  },
  {
    "id": "7315072016",
    "district_id": "731507",
    "label": "Pangaparang",
    "value": "Pangaparang"
  },
  {
    "id": "7315081001",
    "district_id": "731508",
    "label": "Cempa",
    "value": "Cempa"
  },
  {
    "id": "7315082002",
    "district_id": "731508",
    "label": "Mangki",
    "value": "Mangki"
  },
  {
    "id": "7315082003",
    "district_id": "731508",
    "label": "Matunru Tunrue",
    "value": "Matunru Tunrue"
  },
  {
    "id": "7315082004",
    "district_id": "731508",
    "label": "Sikkuale",
    "value": "Sikkuale"
  },
  {
    "id": "7315082005",
    "district_id": "731508",
    "label": "Tadangpalie",
    "value": "Tadangpalie"
  },
  {
    "id": "7315082006",
    "district_id": "731508",
    "label": "Salipolo",
    "value": "Salipolo"
  },
  {
    "id": "7315082007",
    "district_id": "731508",
    "label": "Tanra Tuo",
    "value": "Tanra Tuo"
  },
  {
    "id": "7315091001",
    "district_id": "731509",
    "label": "Pammase",
    "value": "Pammase"
  },
  {
    "id": "7315091002",
    "district_id": "731509",
    "label": "Marawi",
    "value": "Marawi"
  },
  {
    "id": "7315091003",
    "district_id": "731509",
    "label": "Pakkie",
    "value": "Pakkie"
  },
  {
    "id": "7315091004",
    "district_id": "731509",
    "label": "Tiroang",
    "value": "Tiroang"
  },
  {
    "id": "7315091005",
    "district_id": "731509",
    "label": "Mattiro Deceng",
    "value": "Mattiro Deceng"
  },
  {
    "id": "7315101001",
    "district_id": "731510",
    "label": "Lansirang",
    "value": "Lansirang"
  },
  {
    "id": "7315102002",
    "district_id": "731510",
    "label": "Samaulue",
    "value": "Samaulue"
  },
  {
    "id": "7315102003",
    "district_id": "731510",
    "label": "Mallongi-longi",
    "value": "Mallongi-longi"
  },
  {
    "id": "7315102004",
    "district_id": "731510",
    "label": "Amassangeng",
    "value": "Amassangeng"
  },
  {
    "id": "7315102005",
    "district_id": "731510",
    "label": "Barang Palie",
    "value": "Barang Palie"
  },
  {
    "id": "7315102006",
    "district_id": "731510",
    "label": "Waetuoe",
    "value": "Waetuoe"
  },
  {
    "id": "7315102007",
    "district_id": "731510",
    "label": "Lerang",
    "value": "Lerang"
  },
  {
    "id": "7315111001",
    "district_id": "731511",
    "label": "Pacongang",
    "value": "Pacongang"
  },
  {
    "id": "7315111002",
    "district_id": "731511",
    "label": "Macinnae",
    "value": "Macinnae"
  },
  {
    "id": "7315111003",
    "district_id": "731511",
    "label": "Temmassarangnge",
    "value": "Temmassarangnge"
  },
  {
    "id": "7315111004",
    "district_id": "731511",
    "label": "Mamminasae",
    "value": "Mamminasae"
  },
  {
    "id": "7315111005",
    "district_id": "731511",
    "label": "Laleng Bata",
    "value": "Laleng Bata"
  },
  {
    "id": "7315111006",
    "district_id": "731511",
    "label": "Benteng Sawito",
    "value": "Benteng Sawito"
  },
  {
    "id": "7315121003",
    "district_id": "731512",
    "label": "Kassa",
    "value": "Kassa"
  },
  {
    "id": "7315122001",
    "district_id": "731512",
    "label": "Batu Lappa",
    "value": "Batu Lappa"
  },
  {
    "id": "7315122004",
    "district_id": "731512",
    "label": "Tapporang",
    "value": "Tapporang"
  },
  {
    "id": "7315122005",
    "district_id": "731512",
    "label": "Kaseralau",
    "value": "Kaseralau"
  },
  {
    "id": "7315122006",
    "district_id": "731512",
    "label": "Watang Kassa",
    "value": "Watang Kassa"
  },
  {
    "id": "7316011001",
    "district_id": "731601",
    "label": "Bangkala",
    "value": "Bangkala"
  },
  {
    "id": "7316012002",
    "district_id": "731601",
    "label": "Pattondon Salu",
    "value": "Pattondon Salu"
  },
  {
    "id": "7316012003",
    "district_id": "731601",
    "label": "Tuncung",
    "value": "Tuncung"
  },
  {
    "id": "7316012004",
    "district_id": "731601",
    "label": "Pasang",
    "value": "Pasang"
  },
  {
    "id": "7316012005",
    "district_id": "731601",
    "label": "Salodua",
    "value": "Salodua"
  },
  {
    "id": "7316012006",
    "district_id": "731601",
    "label": "Batu Mila",
    "value": "Batu Mila"
  },
  {
    "id": "7316012007",
    "district_id": "731601",
    "label": "Puncak Harapan",
    "value": "Puncak Harapan"
  },
  {
    "id": "7316012008",
    "district_id": "731601",
    "label": "Palakka",
    "value": "Palakka"
  },
  {
    "id": "7316012009",
    "district_id": "731601",
    "label": "Tapong",
    "value": "Tapong"
  },
  {
    "id": "7316012010",
    "district_id": "731601",
    "label": "Mangkawani",
    "value": "Mangkawani"
  },
  {
    "id": "7316012011",
    "district_id": "731601",
    "label": "Botto Malangga",
    "value": "Botto Malangga"
  },
  {
    "id": "7316012012",
    "district_id": "731601",
    "label": "Boiya",
    "value": "Boiya"
  },
  {
    "id": "7316012013",
    "district_id": "731601",
    "label": "Matajang",
    "value": "Matajang"
  },
  {
    "id": "7316012014",
    "district_id": "731601",
    "label": "Baringin",
    "value": "Baringin"
  },
  {
    "id": "7316012015",
    "district_id": "731601",
    "label": "Lebani",
    "value": "Lebani"
  },
  {
    "id": "7316012016",
    "district_id": "731601",
    "label": "Limbuang",
    "value": "Limbuang"
  },
  {
    "id": "7316012017",
    "district_id": "731601",
    "label": "Paladang",
    "value": "Paladang"
  },
  {
    "id": "7316012018",
    "district_id": "731601",
    "label": "Pariwang",
    "value": "Pariwang"
  },
  {
    "id": "7316012019",
    "district_id": "731601",
    "label": "Kaluppang",
    "value": "Kaluppang"
  },
  {
    "id": "7316012020",
    "district_id": "731601",
    "label": "Ongko",
    "value": "Ongko"
  },
  {
    "id": "7316012021",
    "district_id": "731601",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7316012022",
    "district_id": "731601",
    "label": "Labuku",
    "value": "Labuku"
  },
  {
    "id": "7316021001",
    "district_id": "731602",
    "label": "Galonta",
    "value": "Galonta"
  },
  {
    "id": "7316021002",
    "district_id": "731602",
    "label": "Juppandang",
    "value": "Juppandang"
  },
  {
    "id": "7316021003",
    "district_id": "731602",
    "label": "Puserren",
    "value": "Puserren"
  },
  {
    "id": "7316021004",
    "district_id": "731602",
    "label": "Lewaja",
    "value": "Lewaja"
  },
  {
    "id": "7316021010",
    "district_id": "731602",
    "label": "Leoran",
    "value": "Leoran"
  },
  {
    "id": "7316021016",
    "district_id": "731602",
    "label": "Tuara",
    "value": "Tuara"
  },
  {
    "id": "7316022005",
    "district_id": "731602",
    "label": "Ranga",
    "value": "Ranga"
  },
  {
    "id": "7316022006",
    "district_id": "731602",
    "label": "Buttu Batu",
    "value": "Buttu Batu"
  },
  {
    "id": "7316022007",
    "district_id": "731602",
    "label": "Tokkonan",
    "value": "Tokkonan"
  },
  {
    "id": "7316022008",
    "district_id": "731602",
    "label": "Karueng",
    "value": "Karueng"
  },
  {
    "id": "7316022011",
    "district_id": "731602",
    "label": "Tallu Bamba",
    "value": "Tallu Bamba"
  },
  {
    "id": "7316022012",
    "district_id": "731602",
    "label": "Tungka",
    "value": "Tungka"
  },
  {
    "id": "7316022013",
    "district_id": "731602",
    "label": "Kaluppini",
    "value": "Kaluppini"
  },
  {
    "id": "7316022014",
    "district_id": "731602",
    "label": "Tobalu",
    "value": "Tobalu"
  },
  {
    "id": "7316022015",
    "district_id": "731602",
    "label": "Cemba",
    "value": "Cemba"
  },
  {
    "id": "7316022017",
    "district_id": "731602",
    "label": "Temban",
    "value": "Temban"
  },
  {
    "id": "7316022018",
    "district_id": "731602",
    "label": "Rosoan",
    "value": "Rosoan"
  },
  {
    "id": "7316022019",
    "district_id": "731602",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "7316031001",
    "district_id": "731603",
    "label": "Baraka",
    "value": "Baraka"
  },
  {
    "id": "7316031002",
    "district_id": "731603",
    "label": "Tomenawa",
    "value": "Tomenawa"
  },
  {
    "id": "7316031021",
    "district_id": "731603",
    "label": "Balla",
    "value": "Balla"
  },
  {
    "id": "7316032003",
    "district_id": "731603",
    "label": "Banti",
    "value": "Banti"
  },
  {
    "id": "7316032005",
    "district_id": "731603",
    "label": "Bontongan",
    "value": "Bontongan"
  },
  {
    "id": "7316032006",
    "district_id": "731603",
    "label": "Salukanan",
    "value": "Salukanan"
  },
  {
    "id": "7316032007",
    "district_id": "731603",
    "label": "Parinding",
    "value": "Parinding"
  },
  {
    "id": "7316032010",
    "district_id": "731603",
    "label": "Tirowali",
    "value": "Tirowali"
  },
  {
    "id": "7316032011",
    "district_id": "731603",
    "label": "Pepandungan",
    "value": "Pepandungan"
  },
  {
    "id": "7316032012",
    "district_id": "731603",
    "label": "Kendenan",
    "value": "Kendenan"
  },
  {
    "id": "7316032015",
    "district_id": "731603",
    "label": "Perangian",
    "value": "Perangian"
  },
  {
    "id": "7316032019",
    "district_id": "731603",
    "label": "Janggurara",
    "value": "Janggurara"
  },
  {
    "id": "7316032020",
    "district_id": "731603",
    "label": "Kadingeh",
    "value": "Kadingeh"
  },
  {
    "id": "7316032022",
    "district_id": "731603",
    "label": "Bonebone",
    "value": "Bonebone"
  },
  {
    "id": "7316032023",
    "district_id": "731603",
    "label": "Pandung Batu",
    "value": "Pandung Batu"
  },
  {
    "id": "7316041001",
    "district_id": "731604",
    "label": "Lakawan",
    "value": "Lakawan"
  },
  {
    "id": "7316041002",
    "district_id": "731604",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7316041004",
    "district_id": "731604",
    "label": "Mataran",
    "value": "Mataran"
  },
  {
    "id": "7316042003",
    "district_id": "731604",
    "label": "Bamba Puang",
    "value": "Bamba Puang"
  },
  {
    "id": "7316042005",
    "district_id": "731604",
    "label": "Siambo",
    "value": "Siambo"
  },
  {
    "id": "7316042006",
    "district_id": "731604",
    "label": "Mampu",
    "value": "Mampu"
  },
  {
    "id": "7316042007",
    "district_id": "731604",
    "label": "Pekalobean",
    "value": "Pekalobean"
  },
  {
    "id": "7316042008",
    "district_id": "731604",
    "label": "Singki",
    "value": "Singki"
  },
  {
    "id": "7316042009",
    "district_id": "731604",
    "label": "Salu Dewata",
    "value": "Salu Dewata"
  },
  {
    "id": "7316042010",
    "district_id": "731604",
    "label": "Tindalun",
    "value": "Tindalun"
  },
  {
    "id": "7316042011",
    "district_id": "731604",
    "label": "Bubun Lamba",
    "value": "Bubun Lamba"
  },
  {
    "id": "7316042012",
    "district_id": "731604",
    "label": "Tampo",
    "value": "Tampo"
  },
  {
    "id": "7316042013",
    "district_id": "731604",
    "label": "Batunoni",
    "value": "Batunoni"
  },
  {
    "id": "7316042014",
    "district_id": "731604",
    "label": "Saruran",
    "value": "Saruran"
  },
  {
    "id": "7316042015",
    "district_id": "731604",
    "label": "Mendatte",
    "value": "Mendatte"
  },
  {
    "id": "7316051001",
    "district_id": "731605",
    "label": "Kambiolangi",
    "value": "Kambiolangi"
  },
  {
    "id": "7316051002",
    "district_id": "731605",
    "label": "Kalosi",
    "value": "Kalosi"
  },
  {
    "id": "7316051003",
    "district_id": "731605",
    "label": "Buntu Sugi",
    "value": "Buntu Sugi"
  },
  {
    "id": "7316052004",
    "district_id": "731605",
    "label": "Mata Allo",
    "value": "Mata Allo"
  },
  {
    "id": "7316052010",
    "district_id": "731605",
    "label": "Sumillan",
    "value": "Sumillan"
  },
  {
    "id": "7316052011",
    "district_id": "731605",
    "label": "Pana",
    "value": "Pana"
  },
  {
    "id": "7316052017",
    "district_id": "731605",
    "label": "Taulo",
    "value": "Taulo"
  },
  {
    "id": "7316052018",
    "district_id": "731605",
    "label": "Bolang",
    "value": "Bolang"
  },
  {
    "id": "7316062002",
    "district_id": "731606",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "7316062003",
    "district_id": "731606",
    "label": "Talang Rilau",
    "value": "Talang Rilau"
  },
  {
    "id": "7316062004",
    "district_id": "731606",
    "label": "Baruka",
    "value": "Baruka"
  },
  {
    "id": "7316062008",
    "district_id": "731606",
    "label": "Sawitto",
    "value": "Sawitto"
  },
  {
    "id": "7316062009",
    "district_id": "731606",
    "label": "Bulo",
    "value": "Bulo"
  },
  {
    "id": "7316062010",
    "district_id": "731606",
    "label": "Banua",
    "value": "Banua"
  },
  {
    "id": "7316072001",
    "district_id": "731607",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "7316072002",
    "district_id": "731607",
    "label": "Taulan",
    "value": "Taulan"
  },
  {
    "id": "7316072003",
    "district_id": "731607",
    "label": "Pinang",
    "value": "Pinang"
  },
  {
    "id": "7316072004",
    "district_id": "731607",
    "label": "Malalin",
    "value": "Malalin"
  },
  {
    "id": "7316072005",
    "district_id": "731607",
    "label": "Karrang",
    "value": "Karrang"
  },
  {
    "id": "7316072006",
    "district_id": "731607",
    "label": "Lebang",
    "value": "Lebang"
  },
  {
    "id": "7316072007",
    "district_id": "731607",
    "label": "Pundi Lemo",
    "value": "Pundi Lemo"
  },
  {
    "id": "7316082001",
    "district_id": "731608",
    "label": "Sangiepongan",
    "value": "Sangiepongan"
  },
  {
    "id": "7316082002",
    "district_id": "731608",
    "label": "Buntu Barana",
    "value": "Buntu Barana"
  },
  {
    "id": "7316082003",
    "district_id": "731608",
    "label": "Buntu Pema",
    "value": "Buntu Pema"
  },
  {
    "id": "7316082004",
    "district_id": "731608",
    "label": "Parombean",
    "value": "Parombean"
  },
  {
    "id": "7316082005",
    "district_id": "731608",
    "label": "Tallung Ura",
    "value": "Tallung Ura"
  },
  {
    "id": "7316082006",
    "district_id": "731608",
    "label": "Pebaloran",
    "value": "Pebaloran"
  },
  {
    "id": "7316082007",
    "district_id": "731608",
    "label": "Curio",
    "value": "Curio"
  },
  {
    "id": "7316082008",
    "district_id": "731608",
    "label": "Mekkala",
    "value": "Mekkala"
  },
  {
    "id": "7316082009",
    "district_id": "731608",
    "label": "Sumbang",
    "value": "Sumbang"
  },
  {
    "id": "7316082010",
    "district_id": "731608",
    "label": "Mandalan",
    "value": "Mandalan"
  },
  {
    "id": "7316082011",
    "district_id": "731608",
    "label": "Salassa",
    "value": "Salassa"
  },
  {
    "id": "7316091001",
    "district_id": "731609",
    "label": "Malua",
    "value": "Malua"
  },
  {
    "id": "7316092002",
    "district_id": "731609",
    "label": "Bonto",
    "value": "Bonto"
  },
  {
    "id": "7316092003",
    "district_id": "731609",
    "label": "Tallung Tondok",
    "value": "Tallung Tondok"
  },
  {
    "id": "7316092004",
    "district_id": "731609",
    "label": "Rante Mario",
    "value": "Rante Mario"
  },
  {
    "id": "7316092005",
    "district_id": "731609",
    "label": "Tangru",
    "value": "Tangru"
  },
  {
    "id": "7316092006",
    "district_id": "731609",
    "label": "Kolai",
    "value": "Kolai"
  },
  {
    "id": "7316092007",
    "district_id": "731609",
    "label": "Dulang",
    "value": "Dulang"
  },
  {
    "id": "7316092008",
    "district_id": "731609",
    "label": "Buntu Batuan",
    "value": "Buntu Batuan"
  },
  {
    "id": "7316102001",
    "district_id": "731610",
    "label": "Pasui",
    "value": "Pasui"
  },
  {
    "id": "7316102002",
    "district_id": "731610",
    "label": "Eran Batu",
    "value": "Eran Batu"
  },
  {
    "id": "7316102003",
    "district_id": "731610",
    "label": "Latimojong",
    "value": "Latimojong"
  },
  {
    "id": "7316102004",
    "district_id": "731610",
    "label": "Buntu Mondong",
    "value": "Buntu Mondong"
  },
  {
    "id": "7316102005",
    "district_id": "731610",
    "label": "Ledan",
    "value": "Ledan"
  },
  {
    "id": "7316102006",
    "district_id": "731610",
    "label": "Lunjen",
    "value": "Lunjen"
  },
  {
    "id": "7316102007",
    "district_id": "731610",
    "label": "Langda",
    "value": "Langda"
  },
  {
    "id": "7316102008",
    "district_id": "731610",
    "label": "Potok Ullin",
    "value": "Potok Ullin"
  },
  {
    "id": "7316112001",
    "district_id": "731611",
    "label": "Masalle",
    "value": "Masalle"
  },
  {
    "id": "7316112003",
    "district_id": "731611",
    "label": "Batu Ke'de",
    "value": "Batu Ke'de"
  },
  {
    "id": "7316112004",
    "district_id": "731611",
    "label": "Mundan",
    "value": "Mundan"
  },
  {
    "id": "7316112006",
    "district_id": "731611",
    "label": "Buntu Sarong",
    "value": "Buntu Sarong"
  },
  {
    "id": "7316112008",
    "district_id": "731611",
    "label": "Rampunan",
    "value": "Rampunan"
  },
  {
    "id": "7316112055",
    "district_id": "731611",
    "label": "Tongkonan Basse",
    "value": "Tongkonan Basse"
  },
  {
    "id": "7316122001",
    "district_id": "731612",
    "label": "Baroko",
    "value": "Baroko"
  },
  {
    "id": "7316122002",
    "district_id": "731612",
    "label": "Benteng Alla",
    "value": "Benteng Alla"
  },
  {
    "id": "7316122003",
    "district_id": "731612",
    "label": "Benteng Alla Utara",
    "value": "Benteng Alla Utara"
  },
  {
    "id": "7316122004",
    "district_id": "731612",
    "label": "Tongko",
    "value": "Tongko"
  },
  {
    "id": "7316122005",
    "district_id": "731612",
    "label": "Patongloan",
    "value": "Patongloan"
  },
  {
    "id": "7317012005",
    "district_id": "731701",
    "label": "Kanna",
    "value": "Kanna"
  },
  {
    "id": "7317012006",
    "district_id": "731701",
    "label": "Kanna Utara",
    "value": "Kanna Utara"
  },
  {
    "id": "7317012008",
    "district_id": "731701",
    "label": "Tabi",
    "value": "Tabi"
  },
  {
    "id": "7317012010",
    "district_id": "731701",
    "label": "Bolu",
    "value": "Bolu"
  },
  {
    "id": "7317012011",
    "district_id": "731701",
    "label": "Lange",
    "value": "Lange"
  },
  {
    "id": "7317012012",
    "district_id": "731701",
    "label": "Sinaji",
    "value": "Sinaji"
  },
  {
    "id": "7317012013",
    "district_id": "731701",
    "label": "Mappetajang",
    "value": "Mappetajang"
  },
  {
    "id": "7317012016",
    "district_id": "731701",
    "label": "Andulan",
    "value": "Andulan"
  },
  {
    "id": "7317012017",
    "district_id": "731701",
    "label": "Buntu Batu",
    "value": "Buntu Batu"
  },
  {
    "id": "7317012019",
    "district_id": "731701",
    "label": "Ledan",
    "value": "Ledan"
  },
  {
    "id": "7317012020",
    "district_id": "731701",
    "label": "Lissaga",
    "value": "Lissaga"
  },
  {
    "id": "7317012022",
    "district_id": "731701",
    "label": "To'long",
    "value": "To'long"
  },
  {
    "id": "7317021003",
    "district_id": "731702",
    "label": "Larompong",
    "value": "Larompong"
  },
  {
    "id": "7317022001",
    "district_id": "731702",
    "label": "Rantebelu",
    "value": "Rantebelu"
  },
  {
    "id": "7317022002",
    "district_id": "731702",
    "label": "Komba",
    "value": "Komba"
  },
  {
    "id": "7317022004",
    "district_id": "731702",
    "label": "Lumaring",
    "value": "Lumaring"
  },
  {
    "id": "7317022005",
    "district_id": "731702",
    "label": "Binturu",
    "value": "Binturu"
  },
  {
    "id": "7317022006",
    "district_id": "731702",
    "label": "Bilante",
    "value": "Bilante"
  },
  {
    "id": "7317022007",
    "district_id": "731702",
    "label": "Rante Alang",
    "value": "Rante Alang"
  },
  {
    "id": "7317022008",
    "district_id": "731702",
    "label": "Riwang",
    "value": "Riwang"
  },
  {
    "id": "7317022009",
    "district_id": "731702",
    "label": "Buntu Matabing",
    "value": "Buntu Matabing"
  },
  {
    "id": "7317022010",
    "district_id": "731702",
    "label": "Bukit Sutra",
    "value": "Bukit Sutra"
  },
  {
    "id": "7317022011",
    "district_id": "731702",
    "label": "Riwang Selatan",
    "value": "Riwang Selatan"
  },
  {
    "id": "7317022012",
    "district_id": "731702",
    "label": "Komba Selatan",
    "value": "Komba Selatan"
  },
  {
    "id": "7317022013",
    "district_id": "731702",
    "label": "Buntu Pasik",
    "value": "Buntu Pasik"
  },
  {
    "id": "7317031003",
    "district_id": "731703",
    "label": "Suli",
    "value": "Suli"
  },
  {
    "id": "7317032002",
    "district_id": "731703",
    "label": "Murante",
    "value": "Murante"
  },
  {
    "id": "7317032004",
    "district_id": "731703",
    "label": "Malela",
    "value": "Malela"
  },
  {
    "id": "7317032005",
    "district_id": "731703",
    "label": "Botta",
    "value": "Botta"
  },
  {
    "id": "7317032006",
    "district_id": "731703",
    "label": "Cimpu",
    "value": "Cimpu"
  },
  {
    "id": "7317032008",
    "district_id": "731703",
    "label": "Buntu Kunyi",
    "value": "Buntu Kunyi"
  },
  {
    "id": "7317032011",
    "district_id": "731703",
    "label": "Padang Lambe",
    "value": "Padang Lambe"
  },
  {
    "id": "7317032012",
    "district_id": "731703",
    "label": "Kasiwiang",
    "value": "Kasiwiang"
  },
  {
    "id": "7317032013",
    "district_id": "731703",
    "label": "Cakkeawo",
    "value": "Cakkeawo"
  },
  {
    "id": "7317032015",
    "district_id": "731703",
    "label": "Lempopacci",
    "value": "Lempopacci"
  },
  {
    "id": "7317032016",
    "district_id": "731703",
    "label": "Towondo",
    "value": "Towondo"
  },
  {
    "id": "7317032017",
    "district_id": "731703",
    "label": "Cimpu Utara",
    "value": "Cimpu Utara"
  },
  {
    "id": "7317032018",
    "district_id": "731703",
    "label": "Papakaju",
    "value": "Papakaju"
  },
  {
    "id": "7317041005",
    "district_id": "731704",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "7317042004",
    "district_id": "731704",
    "label": "Rumaju",
    "value": "Rumaju"
  },
  {
    "id": "7317042006",
    "district_id": "731704",
    "label": "Sampa",
    "value": "Sampa"
  },
  {
    "id": "7317042007",
    "district_id": "731704",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7317042008",
    "district_id": "731704",
    "label": "Tallang Bulawang",
    "value": "Tallang Bulawang"
  },
  {
    "id": "7317042010",
    "district_id": "731704",
    "label": "Jambu",
    "value": "Jambu"
  },
  {
    "id": "7317042013",
    "district_id": "731704",
    "label": "Saga",
    "value": "Saga"
  },
  {
    "id": "7317042014",
    "district_id": "731704",
    "label": "Sumabu",
    "value": "Sumabu"
  },
  {
    "id": "7317042015",
    "district_id": "731704",
    "label": "Balla",
    "value": "Balla"
  },
  {
    "id": "7317042017",
    "district_id": "731704",
    "label": "Langkiddi",
    "value": "Langkiddi"
  },
  {
    "id": "7317042018",
    "district_id": "731704",
    "label": "Samulang",
    "value": "Samulang"
  },
  {
    "id": "7317042019",
    "district_id": "731704",
    "label": "Buntu Babang",
    "value": "Buntu Babang"
  },
  {
    "id": "7317051001",
    "district_id": "731705",
    "label": "Noling",
    "value": "Noling"
  },
  {
    "id": "7317052002",
    "district_id": "731705",
    "label": "Tanjong",
    "value": "Tanjong"
  },
  {
    "id": "7317052003",
    "district_id": "731705",
    "label": "Buntu Batu",
    "value": "Buntu Batu"
  },
  {
    "id": "7317052004",
    "district_id": "731705",
    "label": "Tampumia",
    "value": "Tampumia"
  },
  {
    "id": "7317052005",
    "district_id": "731705",
    "label": "Padang Tuju",
    "value": "Padang Tuju"
  },
  {
    "id": "7317052006",
    "district_id": "731705",
    "label": "Padang Kamburi",
    "value": "Padang Kamburi"
  },
  {
    "id": "7317052007",
    "district_id": "731705",
    "label": "Malenggang",
    "value": "Malenggang"
  },
  {
    "id": "7317052008",
    "district_id": "731705",
    "label": "Balutan",
    "value": "Balutan"
  },
  {
    "id": "7317052009",
    "district_id": "731705",
    "label": "Padang Ma'bud",
    "value": "Padang Ma'bud"
  },
  {
    "id": "7317052010",
    "district_id": "731705",
    "label": "Saluinduk",
    "value": "Saluinduk"
  },
  {
    "id": "7317061006",
    "district_id": "731706",
    "label": "Bulo",
    "value": "Bulo"
  },
  {
    "id": "7317062001",
    "district_id": "731706",
    "label": "Tombang",
    "value": "Tombang"
  },
  {
    "id": "7317062005",
    "district_id": "731706",
    "label": "Lalong",
    "value": "Lalong"
  },
  {
    "id": "7317062008",
    "district_id": "731706",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "7317062011",
    "district_id": "731706",
    "label": "Barammamase",
    "value": "Barammamase"
  },
  {
    "id": "7317062012",
    "district_id": "731706",
    "label": "Walenrang",
    "value": "Walenrang"
  },
  {
    "id": "7317062015",
    "district_id": "731706",
    "label": "Saragi",
    "value": "Saragi"
  },
  {
    "id": "7317062021",
    "district_id": "731706",
    "label": "Batusitanduk",
    "value": "Batusitanduk"
  },
  {
    "id": "7317062022",
    "district_id": "731706",
    "label": "Kalibamamase",
    "value": "Kalibamamase"
  },
  {
    "id": "7317071001",
    "district_id": "731707",
    "label": "Tampumia Radda",
    "value": "Tampumia Radda"
  },
  {
    "id": "7317071003",
    "district_id": "731707",
    "label": "Tanamanai",
    "value": "Tanamanai"
  },
  {
    "id": "7317071005",
    "district_id": "731707",
    "label": "Senga",
    "value": "Senga"
  },
  {
    "id": "7317071007",
    "district_id": "731707",
    "label": "Balo-Balo",
    "value": "Balo-Balo"
  },
  {
    "id": "7317072004",
    "district_id": "731707",
    "label": "Kurrusumanga",
    "value": "Kurrusumanga"
  },
  {
    "id": "7317072006",
    "district_id": "731707",
    "label": "Belopa",
    "value": "Belopa"
  },
  {
    "id": "7317072014",
    "district_id": "731707",
    "label": "Senga Selatan",
    "value": "Senga Selatan"
  },
  {
    "id": "7317072015",
    "district_id": "731707",
    "label": "Balubu",
    "value": "Balubu"
  },
  {
    "id": "7317072016",
    "district_id": "731707",
    "label": "Pasamai",
    "value": "Pasamai"
  },
  {
    "id": "7317081001",
    "district_id": "731708",
    "label": "Sakti",
    "value": "Sakti"
  },
  {
    "id": "7317082002",
    "district_id": "731708",
    "label": "Barowa",
    "value": "Barowa"
  },
  {
    "id": "7317082003",
    "district_id": "731708",
    "label": "Puty",
    "value": "Puty"
  },
  {
    "id": "7317082004",
    "district_id": "731708",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "7317082005",
    "district_id": "731708",
    "label": "Posi",
    "value": "Posi"
  },
  {
    "id": "7317082006",
    "district_id": "731708",
    "label": "Karang-Karangan",
    "value": "Karang-Karangan"
  },
  {
    "id": "7317082007",
    "district_id": "731708",
    "label": "Lare-Lare",
    "value": "Lare-Lare"
  },
  {
    "id": "7317082008",
    "district_id": "731708",
    "label": "Lengkong",
    "value": "Lengkong"
  },
  {
    "id": "7317082009",
    "district_id": "731708",
    "label": "Tiromanda",
    "value": "Tiromanda"
  },
  {
    "id": "7317082010",
    "district_id": "731708",
    "label": "Tana Rigella",
    "value": "Tana Rigella"
  },
  {
    "id": "7317082011",
    "district_id": "731708",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "7317082012",
    "district_id": "731708",
    "label": "Padang Kalua",
    "value": "Padang Kalua"
  },
  {
    "id": "7317082013",
    "district_id": "731708",
    "label": "Pammesakang",
    "value": "Pammesakang"
  },
  {
    "id": "7317082014",
    "district_id": "731708",
    "label": "Pabbaresseng",
    "value": "Pabbaresseng"
  },
  {
    "id": "7317082015",
    "district_id": "731708",
    "label": "Toddopuli",
    "value": "Toddopuli"
  },
  {
    "id": "7317091001",
    "district_id": "731709",
    "label": "Lamasi",
    "value": "Lamasi"
  },
  {
    "id": "7317092007",
    "district_id": "731709",
    "label": "Salujambu",
    "value": "Salujambu"
  },
  {
    "id": "7317092008",
    "district_id": "731709",
    "label": "Wiwitan",
    "value": "Wiwitan"
  },
  {
    "id": "7317092010",
    "district_id": "731709",
    "label": "Setiarejo",
    "value": "Setiarejo"
  },
  {
    "id": "7317092011",
    "district_id": "731709",
    "label": "Pongsamelung",
    "value": "Pongsamelung"
  },
  {
    "id": "7317092014",
    "district_id": "731709",
    "label": "Padang Kalua",
    "value": "Padang Kalua"
  },
  {
    "id": "7317092020",
    "district_id": "731709",
    "label": "To'pongo",
    "value": "To'pongo"
  },
  {
    "id": "7317092022",
    "district_id": "731709",
    "label": "Se'pon",
    "value": "Se'pon"
  },
  {
    "id": "7317092024",
    "district_id": "731709",
    "label": "Awo' Gading",
    "value": "Awo' Gading"
  },
  {
    "id": "7317092026",
    "district_id": "731709",
    "label": "Wiwitan Timur",
    "value": "Wiwitan Timur"
  },
  {
    "id": "7317101001",
    "district_id": "731710",
    "label": "Bone Pute",
    "value": "Bone Pute"
  },
  {
    "id": "7317102002",
    "district_id": "731710",
    "label": "Temboe",
    "value": "Temboe"
  },
  {
    "id": "7317102003",
    "district_id": "731710",
    "label": "Sampano",
    "value": "Sampano"
  },
  {
    "id": "7317102004",
    "district_id": "731710",
    "label": "Babang",
    "value": "Babang"
  },
  {
    "id": "7317102005",
    "district_id": "731710",
    "label": "La'loa",
    "value": "La'loa"
  },
  {
    "id": "7317102006",
    "district_id": "731710",
    "label": "Batulappa",
    "value": "Batulappa"
  },
  {
    "id": "7317102007",
    "district_id": "731710",
    "label": "Malewong",
    "value": "Malewong"
  },
  {
    "id": "7317102008",
    "district_id": "731710",
    "label": "Salusana",
    "value": "Salusana"
  },
  {
    "id": "7317102009",
    "district_id": "731710",
    "label": "Dadeko",
    "value": "Dadeko"
  },
  {
    "id": "7317102010",
    "district_id": "731710",
    "label": "Gandang Batu",
    "value": "Gandang Batu"
  },
  {
    "id": "7317111001",
    "district_id": "731711",
    "label": "Padang Subur",
    "value": "Padang Subur"
  },
  {
    "id": "7317111004",
    "district_id": "731711",
    "label": "Padang Sappa",
    "value": "Padang Sappa"
  },
  {
    "id": "7317112005",
    "district_id": "731711",
    "label": "Tirowali",
    "value": "Tirowali"
  },
  {
    "id": "7317112006",
    "district_id": "731711",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7317112007",
    "district_id": "731711",
    "label": "Muladimeng",
    "value": "Muladimeng"
  },
  {
    "id": "7317112013",
    "district_id": "731711",
    "label": "Buntu Kamiri",
    "value": "Buntu Kamiri"
  },
  {
    "id": "7317112014",
    "district_id": "731711",
    "label": "Tumale",
    "value": "Tumale"
  },
  {
    "id": "7317112016",
    "district_id": "731711",
    "label": "Buntu Nanna",
    "value": "Buntu Nanna"
  },
  {
    "id": "7317112017",
    "district_id": "731711",
    "label": "Tampa",
    "value": "Tampa"
  },
  {
    "id": "7317112019",
    "district_id": "731711",
    "label": "Parekaju",
    "value": "Parekaju"
  },
  {
    "id": "7317122001",
    "district_id": "731712",
    "label": "Rante Balla",
    "value": "Rante Balla"
  },
  {
    "id": "7317122002",
    "district_id": "731712",
    "label": "Kadundung",
    "value": "Kadundung"
  },
  {
    "id": "7317122003",
    "district_id": "731712",
    "label": "Ulusalu",
    "value": "Ulusalu"
  },
  {
    "id": "7317122004",
    "district_id": "731712",
    "label": "Lambanan",
    "value": "Lambanan"
  },
  {
    "id": "7317122005",
    "district_id": "731712",
    "label": "Tabang",
    "value": "Tabang"
  },
  {
    "id": "7317122006",
    "district_id": "731712",
    "label": "Boneposi",
    "value": "Boneposi"
  },
  {
    "id": "7317122007",
    "district_id": "731712",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7317122008",
    "district_id": "731712",
    "label": "Pajang",
    "value": "Pajang"
  },
  {
    "id": "7317122009",
    "district_id": "731712",
    "label": "Buntu Sarek",
    "value": "Buntu Sarek"
  },
  {
    "id": "7317122010",
    "district_id": "731712",
    "label": "To'barru",
    "value": "To'barru"
  },
  {
    "id": "7317122011",
    "district_id": "731712",
    "label": "Tibussan",
    "value": "Tibussan"
  },
  {
    "id": "7317122012",
    "district_id": "731712",
    "label": "To'lajuk",
    "value": "To'lajuk"
  },
  {
    "id": "7317131001",
    "district_id": "731713",
    "label": "Cilallang",
    "value": "Cilallang"
  },
  {
    "id": "7317132002",
    "district_id": "731713",
    "label": "Kamanre",
    "value": "Kamanre"
  },
  {
    "id": "7317132003",
    "district_id": "731713",
    "label": "Tabbaja",
    "value": "Tabbaja"
  },
  {
    "id": "7317132004",
    "district_id": "731713",
    "label": "Libukang",
    "value": "Libukang"
  },
  {
    "id": "7317132005",
    "district_id": "731713",
    "label": "Wara",
    "value": "Wara"
  },
  {
    "id": "7317132006",
    "district_id": "731713",
    "label": "Salu Paremang",
    "value": "Salu Paremang"
  },
  {
    "id": "7317132007",
    "district_id": "731713",
    "label": "Salu Paremang Selatan",
    "value": "Salu Paremang Selatan"
  },
  {
    "id": "7317132008",
    "district_id": "731713",
    "label": "Bunga Eja",
    "value": "Bunga Eja"
  },
  {
    "id": "7317141001",
    "district_id": "731714",
    "label": "Sabe",
    "value": "Sabe"
  },
  {
    "id": "7317141004",
    "district_id": "731714",
    "label": "Pammanu",
    "value": "Pammanu"
  },
  {
    "id": "7317142002",
    "district_id": "731714",
    "label": "Lamunre",
    "value": "Lamunre"
  },
  {
    "id": "7317142003",
    "district_id": "731714",
    "label": "Lauwa",
    "value": "Lauwa"
  },
  {
    "id": "7317142005",
    "district_id": "731714",
    "label": "Seppong",
    "value": "Seppong"
  },
  {
    "id": "7317142006",
    "district_id": "731714",
    "label": "Paconne",
    "value": "Paconne"
  },
  {
    "id": "7317142007",
    "district_id": "731714",
    "label": "Lebani",
    "value": "Lebani"
  },
  {
    "id": "7317142008",
    "district_id": "731714",
    "label": "Lamunre Tengah",
    "value": "Lamunre Tengah"
  },
  {
    "id": "7317152001",
    "district_id": "731715",
    "label": "Ilan Batu",
    "value": "Ilan Batu"
  },
  {
    "id": "7317152002",
    "district_id": "731715",
    "label": "Ilan Batu Uru",
    "value": "Ilan Batu Uru"
  },
  {
    "id": "7317152003",
    "district_id": "731715",
    "label": "Lempe Pasang",
    "value": "Lempe Pasang"
  },
  {
    "id": "7317152004",
    "district_id": "731715",
    "label": "Lamasi Hulu",
    "value": "Lamasi Hulu"
  },
  {
    "id": "7317152005",
    "district_id": "731715",
    "label": "Lempe",
    "value": "Lempe"
  },
  {
    "id": "7317152006",
    "district_id": "731715",
    "label": "Lewandi",
    "value": "Lewandi"
  },
  {
    "id": "7317161004",
    "district_id": "731716",
    "label": "Basso",
    "value": "Basso"
  },
  {
    "id": "7317162001",
    "district_id": "731716",
    "label": "Salutubu",
    "value": "Salutubu"
  },
  {
    "id": "7317162002",
    "district_id": "731716",
    "label": "Siteba",
    "value": "Siteba"
  },
  {
    "id": "7317162003",
    "district_id": "731716",
    "label": "Bolong",
    "value": "Bolong"
  },
  {
    "id": "7317162005",
    "district_id": "731716",
    "label": "Pongko",
    "value": "Pongko"
  },
  {
    "id": "7317162006",
    "district_id": "731716",
    "label": "Buntu Awo'",
    "value": "Buntu Awo'"
  },
  {
    "id": "7317162007",
    "district_id": "731716",
    "label": "Marabuana",
    "value": "Marabuana"
  },
  {
    "id": "7317162008",
    "district_id": "731716",
    "label": "Salulino",
    "value": "Salulino"
  },
  {
    "id": "7317162009",
    "district_id": "731716",
    "label": "Sangtandung",
    "value": "Sangtandung"
  },
  {
    "id": "7317162010",
    "district_id": "731716",
    "label": "Bosso Timur",
    "value": "Bosso Timur"
  },
  {
    "id": "7317162011",
    "district_id": "731716",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "7317172001",
    "district_id": "731717",
    "label": "Lamasi Pantai",
    "value": "Lamasi Pantai"
  },
  {
    "id": "7317172002",
    "district_id": "731717",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7317172003",
    "district_id": "731717",
    "label": "Rantai Damai",
    "value": "Rantai Damai"
  },
  {
    "id": "7317172004",
    "district_id": "731717",
    "label": "Tabah",
    "value": "Tabah"
  },
  {
    "id": "7317172005",
    "district_id": "731717",
    "label": "Kendekan",
    "value": "Kendekan"
  },
  {
    "id": "7317172006",
    "district_id": "731717",
    "label": "Pangalli",
    "value": "Pangalli"
  },
  {
    "id": "7317172007",
    "district_id": "731717",
    "label": "Seba-Seba",
    "value": "Seba-Seba"
  },
  {
    "id": "7317172008",
    "district_id": "731717",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "7317182001",
    "district_id": "731718",
    "label": "Pompengan",
    "value": "Pompengan"
  },
  {
    "id": "7317182002",
    "district_id": "731718",
    "label": "Bulolondong",
    "value": "Bulolondong"
  },
  {
    "id": "7317182003",
    "district_id": "731718",
    "label": "Pompengan pantai",
    "value": "Pompengan pantai"
  },
  {
    "id": "7317182004",
    "district_id": "731718",
    "label": "Seriti",
    "value": "Seriti"
  },
  {
    "id": "7317182005",
    "district_id": "731718",
    "label": "Salupao",
    "value": "Salupao"
  },
  {
    "id": "7317182006",
    "district_id": "731718",
    "label": "To'lemo",
    "value": "To'lemo"
  },
  {
    "id": "7317182007",
    "district_id": "731718",
    "label": "Pelalan",
    "value": "Pelalan"
  },
  {
    "id": "7317182008",
    "district_id": "731718",
    "label": "Pompengan Tengah",
    "value": "Pompengan Tengah"
  },
  {
    "id": "7317182009",
    "district_id": "731718",
    "label": "Pompengan Utara",
    "value": "Pompengan Utara"
  },
  {
    "id": "7317191002",
    "district_id": "731719",
    "label": "Lindajang",
    "value": "Lindajang"
  },
  {
    "id": "7317192001",
    "district_id": "731719",
    "label": "Buntu Barana",
    "value": "Buntu Barana"
  },
  {
    "id": "7317192003",
    "district_id": "731719",
    "label": "Poringan",
    "value": "Poringan"
  },
  {
    "id": "7317192004",
    "district_id": "731719",
    "label": "Salubua",
    "value": "Salubua"
  },
  {
    "id": "7317192005",
    "district_id": "731719",
    "label": "Kaili",
    "value": "Kaili"
  },
  {
    "id": "7317192006",
    "district_id": "731719",
    "label": "Muhajirin",
    "value": "Muhajirin"
  },
  {
    "id": "7317192007",
    "district_id": "731719",
    "label": "Tallang",
    "value": "Tallang"
  },
  {
    "id": "7317192008",
    "district_id": "731719",
    "label": "Kaladi Darussalam",
    "value": "Kaladi Darussalam"
  },
  {
    "id": "7317202001",
    "district_id": "731720",
    "label": "Bonelemo",
    "value": "Bonelemo"
  },
  {
    "id": "7317202002",
    "district_id": "731720",
    "label": "Sampeang",
    "value": "Sampeang"
  },
  {
    "id": "7317202003",
    "district_id": "731720",
    "label": "Marinding",
    "value": "Marinding"
  },
  {
    "id": "7317202004",
    "district_id": "731720",
    "label": "Saronda",
    "value": "Saronda"
  },
  {
    "id": "7317202005",
    "district_id": "731720",
    "label": "Kadong-Kadong",
    "value": "Kadong-Kadong"
  },
  {
    "id": "7317202006",
    "district_id": "731720",
    "label": "Tumbubara",
    "value": "Tumbubara"
  },
  {
    "id": "7317202007",
    "district_id": "731720",
    "label": "Tettekang",
    "value": "Tettekang"
  },
  {
    "id": "7317202008",
    "district_id": "731720",
    "label": "Bonelemo Utara",
    "value": "Bonelemo Utara"
  },
  {
    "id": "7317202009",
    "district_id": "731720",
    "label": "Bonelemo Barat",
    "value": "Bonelemo Barat"
  },
  {
    "id": "7317211005",
    "district_id": "731721",
    "label": "Pattedong",
    "value": "Pattedong"
  },
  {
    "id": "7317212001",
    "district_id": "731721",
    "label": "Jenne Maeja",
    "value": "Jenne Maeja"
  },
  {
    "id": "7317212002",
    "district_id": "731721",
    "label": "Bakti",
    "value": "Bakti"
  },
  {
    "id": "7317212003",
    "district_id": "731721",
    "label": "Buntu Karya",
    "value": "Buntu Karya"
  },
  {
    "id": "7317212004",
    "district_id": "731721",
    "label": "Olang",
    "value": "Olang"
  },
  {
    "id": "7317212006",
    "district_id": "731721",
    "label": "Bassiang",
    "value": "Bassiang"
  },
  {
    "id": "7317212007",
    "district_id": "731721",
    "label": "Paccerakkang",
    "value": "Paccerakkang"
  },
  {
    "id": "7317212008",
    "district_id": "731721",
    "label": "Lampuara",
    "value": "Lampuara"
  },
  {
    "id": "7317212009",
    "district_id": "731721",
    "label": "To'balo",
    "value": "To'balo"
  },
  {
    "id": "7317212010",
    "district_id": "731721",
    "label": "To'bia",
    "value": "To'bia"
  },
  {
    "id": "7317212011",
    "district_id": "731721",
    "label": "Bassiang Timur",
    "value": "Bassiang Timur"
  },
  {
    "id": "7317212012",
    "district_id": "731721",
    "label": "Pattedong Selatan",
    "value": "Pattedong Selatan"
  },
  {
    "id": "7317212013",
    "district_id": "731721",
    "label": "Tarramatekkeng",
    "value": "Tarramatekkeng"
  },
  {
    "id": "7317222001",
    "district_id": "731722",
    "label": "Bonglo",
    "value": "Bonglo"
  },
  {
    "id": "7317222002",
    "district_id": "731722",
    "label": "Tede",
    "value": "Tede"
  },
  {
    "id": "7317222003",
    "district_id": "731722",
    "label": "Barana",
    "value": "Barana"
  },
  {
    "id": "7317222004",
    "district_id": "731722",
    "label": "Uraso",
    "value": "Uraso"
  },
  {
    "id": "7317222005",
    "district_id": "731722",
    "label": "Karatuan",
    "value": "Karatuan"
  },
  {
    "id": "7317222006",
    "district_id": "731722",
    "label": "Salubua",
    "value": "Salubua"
  },
  {
    "id": "7317222007",
    "district_id": "731722",
    "label": "Pantilang",
    "value": "Pantilang"
  },
  {
    "id": "7317222008",
    "district_id": "731722",
    "label": "Buntu Tallang",
    "value": "Buntu Tallang"
  },
  {
    "id": "7317222009",
    "district_id": "731722",
    "label": "Dampan",
    "value": "Dampan"
  },
  {
    "id": "7317222010",
    "district_id": "731722",
    "label": "Maindo",
    "value": "Maindo"
  },
  {
    "id": "7317222011",
    "district_id": "731722",
    "label": "Tasangtongkonan",
    "value": "Tasangtongkonan"
  },
  {
    "id": "7317222012",
    "district_id": "731722",
    "label": "Ta'ba",
    "value": "Ta'ba"
  },
  {
    "id": "7318011009",
    "district_id": "731801",
    "label": "Pattan Ulusalu",
    "value": "Pattan Ulusalu"
  },
  {
    "id": "7318012010",
    "district_id": "731801",
    "label": "Salutandung",
    "value": "Salutandung"
  },
  {
    "id": "7318012011",
    "district_id": "731801",
    "label": "Rea Tulak Langi",
    "value": "Rea Tulak Langi"
  },
  {
    "id": "7318012012",
    "district_id": "731801",
    "label": "Ratte Talonge",
    "value": "Ratte Talonge"
  },
  {
    "id": "7318012013",
    "district_id": "731801",
    "label": "Sa'tandung",
    "value": "Sa'tandung"
  },
  {
    "id": "7318012014",
    "district_id": "731801",
    "label": "Ra'bung",
    "value": "Ra'bung"
  },
  {
    "id": "7318012015",
    "district_id": "731801",
    "label": "Salu Tapokko",
    "value": "Salu Tapokko"
  },
  {
    "id": "7318012016",
    "district_id": "731801",
    "label": "Salu Boronan",
    "value": "Salu Boronan"
  },
  {
    "id": "7318012017",
    "district_id": "731801",
    "label": "Batu Tiakka",
    "value": "Batu Tiakka"
  },
  {
    "id": "7318021006",
    "district_id": "731802",
    "label": "Bittuang",
    "value": "Bittuang"
  },
  {
    "id": "7318022002",
    "district_id": "731802",
    "label": "Se'seng",
    "value": "Se'seng"
  },
  {
    "id": "7318022004",
    "district_id": "731802",
    "label": "Pali'",
    "value": "Pali'"
  },
  {
    "id": "7318022007",
    "district_id": "731802",
    "label": "Tiroan",
    "value": "Tiroan"
  },
  {
    "id": "7318022008",
    "district_id": "731802",
    "label": "Balla",
    "value": "Balla"
  },
  {
    "id": "7318022009",
    "district_id": "731802",
    "label": "Le'tek",
    "value": "Le'tek"
  },
  {
    "id": "7318022010",
    "district_id": "731802",
    "label": "Kole Palian",
    "value": "Kole Palian"
  },
  {
    "id": "7318022011",
    "district_id": "731802",
    "label": "Patongloan",
    "value": "Patongloan"
  },
  {
    "id": "7318022012",
    "district_id": "731802",
    "label": "Sasak",
    "value": "Sasak"
  },
  {
    "id": "7318022013",
    "district_id": "731802",
    "label": "Bau",
    "value": "Bau"
  },
  {
    "id": "7318022014",
    "district_id": "731802",
    "label": "Sandana",
    "value": "Sandana"
  },
  {
    "id": "7318022015",
    "district_id": "731802",
    "label": "Rembo Rembo",
    "value": "Rembo Rembo"
  },
  {
    "id": "7318022016",
    "district_id": "731802",
    "label": "Burasia",
    "value": "Burasia"
  },
  {
    "id": "7318022017",
    "district_id": "731802",
    "label": "Kandua'",
    "value": "Kandua'"
  },
  {
    "id": "7318022018",
    "district_id": "731802",
    "label": "Buttu Limbong",
    "value": "Buttu Limbong"
  },
  {
    "id": "7318031007",
    "district_id": "731803",
    "label": "Ratte Buttu",
    "value": "Ratte Buttu"
  },
  {
    "id": "7318032001",
    "district_id": "731803",
    "label": "Bau",
    "value": "Bau"
  },
  {
    "id": "7318032002",
    "district_id": "731803",
    "label": "Buakayu",
    "value": "Buakayu"
  },
  {
    "id": "7318032004",
    "district_id": "731803",
    "label": "Mappa'",
    "value": "Mappa'"
  },
  {
    "id": "7318032006",
    "district_id": "731803",
    "label": "Poton",
    "value": "Poton"
  },
  {
    "id": "7318032008",
    "district_id": "731803",
    "label": "Bau Selatan",
    "value": "Bau Selatan"
  },
  {
    "id": "7318051003",
    "district_id": "731805",
    "label": "Bombongan",
    "value": "Bombongan"
  },
  {
    "id": "7318051005",
    "district_id": "731805",
    "label": "Batupapan",
    "value": "Batupapan"
  },
  {
    "id": "7318051006",
    "district_id": "731805",
    "label": "Tarongko",
    "value": "Tarongko"
  },
  {
    "id": "7318051007",
    "district_id": "731805",
    "label": "Lamunan",
    "value": "Lamunan"
  },
  {
    "id": "7318051009",
    "district_id": "731805",
    "label": "Manggau",
    "value": "Manggau"
  },
  {
    "id": "7318051014",
    "district_id": "731805",
    "label": "Tondon Mamullu",
    "value": "Tondon Mamullu"
  },
  {
    "id": "7318051015",
    "district_id": "731805",
    "label": "Pantan",
    "value": "Pantan"
  },
  {
    "id": "7318051016",
    "district_id": "731805",
    "label": "Buntu Burake",
    "value": "Buntu Burake"
  },
  {
    "id": "7318051017",
    "district_id": "731805",
    "label": "Kamali Pentalluan",
    "value": "Kamali Pentalluan"
  },
  {
    "id": "7318051018",
    "district_id": "731805",
    "label": "Rante",
    "value": "Rante"
  },
  {
    "id": "7318051019",
    "district_id": "731805",
    "label": "Lapandan",
    "value": "Lapandan"
  },
  {
    "id": "7318051020",
    "district_id": "731805",
    "label": "Tampo Makale",
    "value": "Tampo Makale"
  },
  {
    "id": "7318051021",
    "district_id": "731805",
    "label": "Ariang",
    "value": "Ariang"
  },
  {
    "id": "7318051022",
    "district_id": "731805",
    "label": "Botang",
    "value": "Botang"
  },
  {
    "id": "7318052023",
    "district_id": "731805",
    "label": "Lea",
    "value": "Lea"
  },
  {
    "id": "7318091008",
    "district_id": "731809",
    "label": "Sima",
    "value": "Sima"
  },
  {
    "id": "7318092003",
    "district_id": "731809",
    "label": "Simbuang",
    "value": "Simbuang"
  },
  {
    "id": "7318092004",
    "district_id": "731809",
    "label": "Pongbembe",
    "value": "Pongbembe"
  },
  {
    "id": "7318092006",
    "district_id": "731809",
    "label": "Simbuang Batutallu",
    "value": "Simbuang Batutallu"
  },
  {
    "id": "7318092009",
    "district_id": "731809",
    "label": "Makkodo",
    "value": "Makkodo"
  },
  {
    "id": "7318092010",
    "district_id": "731809",
    "label": "Pongbembe Mesakada",
    "value": "Pongbembe Mesakada"
  },
  {
    "id": "7318111003",
    "district_id": "731811",
    "label": "Tapparan",
    "value": "Tapparan"
  },
  {
    "id": "7318111012",
    "district_id": "731811",
    "label": "Rantetayo",
    "value": "Rantetayo"
  },
  {
    "id": "7318111013",
    "district_id": "731811",
    "label": "Padangiring",
    "value": "Padangiring"
  },
  {
    "id": "7318112007",
    "district_id": "731811",
    "label": "Madandan",
    "value": "Madandan"
  },
  {
    "id": "7318112011",
    "district_id": "731811",
    "label": "Tapparan Utara",
    "value": "Tapparan Utara"
  },
  {
    "id": "7318112014",
    "district_id": "731811",
    "label": "Tonglo",
    "value": "Tonglo"
  },
  {
    "id": "7318121002",
    "district_id": "731812",
    "label": "Rante Kalua'",
    "value": "Rante Kalua'"
  },
  {
    "id": "7318121016",
    "district_id": "731812",
    "label": "Tampo",
    "value": "Tampo"
  },
  {
    "id": "7318121023",
    "district_id": "731812",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7318121024",
    "district_id": "731812",
    "label": "Tengan",
    "value": "Tengan"
  },
  {
    "id": "7318122004",
    "district_id": "731812",
    "label": "Gasing",
    "value": "Gasing"
  },
  {
    "id": "7318122006",
    "district_id": "731812",
    "label": "Uluway",
    "value": "Uluway"
  },
  {
    "id": "7318122012",
    "district_id": "731812",
    "label": "Randanan",
    "value": "Randanan"
  },
  {
    "id": "7318122015",
    "district_id": "731812",
    "label": "Buntu Tangti",
    "value": "Buntu Tangti"
  },
  {
    "id": "7318122017",
    "district_id": "731812",
    "label": "Rante dada",
    "value": "Rante dada"
  },
  {
    "id": "7318122018",
    "district_id": "731812",
    "label": "Pa'tengko",
    "value": "Pa'tengko"
  },
  {
    "id": "7318122019",
    "district_id": "731812",
    "label": "Simbuang",
    "value": "Simbuang"
  },
  {
    "id": "7318122020",
    "district_id": "731812",
    "label": "Pakala",
    "value": "Pakala"
  },
  {
    "id": "7318122021",
    "district_id": "731812",
    "label": "Uluway Barat",
    "value": "Uluway Barat"
  },
  {
    "id": "7318122022",
    "district_id": "731812",
    "label": "Marinding",
    "value": "Marinding"
  },
  {
    "id": "7318122025",
    "district_id": "731812",
    "label": "Palipu",
    "value": "Palipu"
  },
  {
    "id": "7318122026",
    "district_id": "731812",
    "label": "Ke'pe Tinoring",
    "value": "Ke'pe Tinoring"
  },
  {
    "id": "7318122027",
    "district_id": "731812",
    "label": "Buntu Datu",
    "value": "Buntu Datu"
  },
  {
    "id": "7318131009",
    "district_id": "731813",
    "label": "Tongko Sarapung",
    "value": "Tongko Sarapung"
  },
  {
    "id": "7318131010",
    "district_id": "731813",
    "label": "Buntu Masakke",
    "value": "Buntu Masakke"
  },
  {
    "id": "7318132004",
    "district_id": "731813",
    "label": "Bulian Massa'bu",
    "value": "Bulian Massa'bu"
  },
  {
    "id": "7318132006",
    "district_id": "731813",
    "label": "Kaero",
    "value": "Kaero"
  },
  {
    "id": "7318132008",
    "district_id": "731813",
    "label": "Turunan",
    "value": "Turunan"
  },
  {
    "id": "7318191002",
    "district_id": "731819",
    "label": "Benteng Ambeso",
    "value": "Benteng Ambeso"
  },
  {
    "id": "7318191004",
    "district_id": "731819",
    "label": "Salubarani",
    "value": "Salubarani"
  },
  {
    "id": "7318191012",
    "district_id": "731819",
    "label": "Mebali",
    "value": "Mebali"
  },
  {
    "id": "7318192001",
    "district_id": "731819",
    "label": "Buntu Limbong",
    "value": "Buntu Limbong"
  },
  {
    "id": "7318192003",
    "district_id": "731819",
    "label": "Sillanan",
    "value": "Sillanan"
  },
  {
    "id": "7318192005",
    "district_id": "731819",
    "label": "Gandangbatu",
    "value": "Gandangbatu"
  },
  {
    "id": "7318192006",
    "district_id": "731819",
    "label": "Kaduaja",
    "value": "Kaduaja"
  },
  {
    "id": "7318192007",
    "district_id": "731819",
    "label": "Garassik",
    "value": "Garassik"
  },
  {
    "id": "7318192008",
    "district_id": "731819",
    "label": "Pemanukan",
    "value": "Pemanukan"
  },
  {
    "id": "7318192009",
    "district_id": "731819",
    "label": "Perindingan",
    "value": "Perindingan"
  },
  {
    "id": "7318192010",
    "district_id": "731819",
    "label": "Buntu Tabang",
    "value": "Buntu Tabang"
  },
  {
    "id": "7318192011",
    "district_id": "731819",
    "label": "Betteng Deata",
    "value": "Betteng Deata"
  },
  {
    "id": "7318201001",
    "district_id": "731820",
    "label": "Talion",
    "value": "Talion"
  },
  {
    "id": "7318201013",
    "district_id": "731820",
    "label": "Rembon",
    "value": "Rembon"
  },
  {
    "id": "7318202002",
    "district_id": "731820",
    "label": "Palesan",
    "value": "Palesan"
  },
  {
    "id": "7318202003",
    "district_id": "731820",
    "label": "Banga",
    "value": "Banga"
  },
  {
    "id": "7318202004",
    "district_id": "731820",
    "label": "Ullin",
    "value": "Ullin"
  },
  {
    "id": "7318202005",
    "district_id": "731820",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "7318202006",
    "district_id": "731820",
    "label": "Sarapeang",
    "value": "Sarapeang"
  },
  {
    "id": "7318202007",
    "district_id": "731820",
    "label": "Buri'",
    "value": "Buri'"
  },
  {
    "id": "7318202008",
    "district_id": "731820",
    "label": "Maroson",
    "value": "Maroson"
  },
  {
    "id": "7318202009",
    "district_id": "731820",
    "label": "Batusura'",
    "value": "Batusura'"
  },
  {
    "id": "7318202010",
    "district_id": "731820",
    "label": "Bua ' Tarrung",
    "value": "Bua ' Tarrung"
  },
  {
    "id": "7318202011",
    "district_id": "731820",
    "label": "To'pao",
    "value": "To'pao"
  },
  {
    "id": "7318202012",
    "district_id": "731820",
    "label": "Kayuosing",
    "value": "Kayuosing"
  },
  {
    "id": "7318271001",
    "district_id": "731827",
    "label": "Tambunan",
    "value": "Tambunan"
  },
  {
    "id": "7318271002",
    "district_id": "731827",
    "label": "Sarira",
    "value": "Sarira"
  },
  {
    "id": "7318271003",
    "district_id": "731827",
    "label": "Bungin",
    "value": "Bungin"
  },
  {
    "id": "7318271004",
    "district_id": "731827",
    "label": "Lion Tondok Iring",
    "value": "Lion Tondok Iring"
  },
  {
    "id": "7318271005",
    "district_id": "731827",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7318281004",
    "district_id": "731828",
    "label": "Kondodewata",
    "value": "Kondodewata"
  },
  {
    "id": "7318282001",
    "district_id": "731828",
    "label": "Tanete",
    "value": "Tanete"
  },
  {
    "id": "7318282002",
    "district_id": "731828",
    "label": "Dewata",
    "value": "Dewata"
  },
  {
    "id": "7318282003",
    "district_id": "731828",
    "label": "Miallo",
    "value": "Miallo"
  },
  {
    "id": "7318282005",
    "district_id": "731828",
    "label": "Sangpeparikan",
    "value": "Sangpeparikan"
  },
  {
    "id": "7318282006",
    "district_id": "731828",
    "label": "Butang",
    "value": "Butang"
  },
  {
    "id": "7318291001",
    "district_id": "731829",
    "label": "Tiromanda",
    "value": "Tiromanda"
  },
  {
    "id": "7318291002",
    "district_id": "731829",
    "label": "Sandabilik",
    "value": "Sandabilik"
  },
  {
    "id": "7318291003",
    "district_id": "731829",
    "label": "Tosapan",
    "value": "Tosapan"
  },
  {
    "id": "7318291004",
    "district_id": "731829",
    "label": "Pasang",
    "value": "Pasang"
  },
  {
    "id": "7318292005",
    "district_id": "731829",
    "label": "Randan Batu",
    "value": "Randan Batu"
  },
  {
    "id": "7318292006",
    "district_id": "731829",
    "label": "Patekke",
    "value": "Patekke"
  },
  {
    "id": "7318292007",
    "district_id": "731829",
    "label": "Pa'buaran",
    "value": "Pa'buaran"
  },
  {
    "id": "7318292008",
    "district_id": "731829",
    "label": "Bo'ne Buntu Sisong",
    "value": "Bo'ne Buntu Sisong"
  },
  {
    "id": "7318312001",
    "district_id": "731831",
    "label": "Ratte",
    "value": "Ratte"
  },
  {
    "id": "7318312002",
    "district_id": "731831",
    "label": "Paliorong",
    "value": "Paliorong"
  },
  {
    "id": "7318312003",
    "district_id": "731831",
    "label": "Kadundung",
    "value": "Kadundung"
  },
  {
    "id": "7318312004",
    "district_id": "731831",
    "label": "Pondingao'",
    "value": "Pondingao'"
  },
  {
    "id": "7318312005",
    "district_id": "731831",
    "label": "Belau",
    "value": "Belau"
  },
  {
    "id": "7318312006",
    "district_id": "731831",
    "label": "Belau Utara",
    "value": "Belau Utara"
  },
  {
    "id": "7318312007",
    "district_id": "731831",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "7318312008",
    "district_id": "731831",
    "label": "Sesesalu",
    "value": "Sesesalu"
  },
  {
    "id": "7318331005",
    "district_id": "731833",
    "label": "Rante Alang",
    "value": "Rante Alang"
  },
  {
    "id": "7318332001",
    "district_id": "731833",
    "label": "Batualu",
    "value": "Batualu"
  },
  {
    "id": "7318332002",
    "district_id": "731833",
    "label": "Tokesan",
    "value": "Tokesan"
  },
  {
    "id": "7318332003",
    "district_id": "731833",
    "label": "Batualu Selatan",
    "value": "Batualu Selatan"
  },
  {
    "id": "7318332004",
    "district_id": "731833",
    "label": "Raru Sibunuan",
    "value": "Raru Sibunuan"
  },
  {
    "id": "7318341001",
    "district_id": "731834",
    "label": "Leatung",
    "value": "Leatung"
  },
  {
    "id": "7318341006",
    "district_id": "731834",
    "label": "Bebo'",
    "value": "Bebo'"
  },
  {
    "id": "7318342002",
    "district_id": "731834",
    "label": "Rantela'bi Kambisa",
    "value": "Rantela'bi Kambisa"
  },
  {
    "id": "7318342003",
    "district_id": "731834",
    "label": "Leatung Matallo",
    "value": "Leatung Matallo"
  },
  {
    "id": "7318342004",
    "district_id": "731834",
    "label": "Saluallo",
    "value": "Saluallo"
  },
  {
    "id": "7318342005",
    "district_id": "731834",
    "label": "Tumbang Datu",
    "value": "Tumbang Datu"
  },
  {
    "id": "7318351001",
    "district_id": "731835",
    "label": "Malimbong",
    "value": "Malimbong"
  },
  {
    "id": "7318352002",
    "district_id": "731835",
    "label": "Leppan",
    "value": "Leppan"
  },
  {
    "id": "7318352003",
    "district_id": "731835",
    "label": "Kole Sawangan",
    "value": "Kole Sawangan"
  },
  {
    "id": "7318352004",
    "district_id": "731835",
    "label": "Kole Barebatu",
    "value": "Kole Barebatu"
  },
  {
    "id": "7318352005",
    "district_id": "731835",
    "label": "Lemo Menduruk",
    "value": "Lemo Menduruk"
  },
  {
    "id": "7318352006",
    "district_id": "731835",
    "label": "Balepe'",
    "value": "Balepe'"
  },
  {
    "id": "7318372001",
    "district_id": "731837",
    "label": "Rano",
    "value": "Rano"
  },
  {
    "id": "7318372002",
    "district_id": "731837",
    "label": "Rano Utara",
    "value": "Rano Utara"
  },
  {
    "id": "7318372003",
    "district_id": "731837",
    "label": "Rumandan",
    "value": "Rumandan"
  },
  {
    "id": "7318372004",
    "district_id": "731837",
    "label": "Rano Tengah",
    "value": "Rano Tengah"
  },
  {
    "id": "7318372005",
    "district_id": "731837",
    "label": "Rano Timur",
    "value": "Rano Timur"
  },
  {
    "id": "7318381002",
    "district_id": "731838",
    "label": "Bambalu",
    "value": "Bambalu"
  },
  {
    "id": "7318382001",
    "district_id": "731838",
    "label": "Rante Kurra",
    "value": "Rante Kurra"
  },
  {
    "id": "7318382003",
    "district_id": "731838",
    "label": "Maroson",
    "value": "Maroson"
  },
  {
    "id": "7318382004",
    "district_id": "731838",
    "label": "Lipungan Tanete",
    "value": "Lipungan Tanete"
  },
  {
    "id": "7318382005",
    "district_id": "731838",
    "label": "Rante limbong",
    "value": "Rante limbong"
  },
  {
    "id": "7318382006",
    "district_id": "731838",
    "label": "Limbong Sangpolo",
    "value": "Limbong Sangpolo"
  },
  {
    "id": "7322012001",
    "district_id": "732201",
    "label": "Pattimang",
    "value": "Pattimang"
  },
  {
    "id": "7322012002",
    "district_id": "732201",
    "label": "Malangke",
    "value": "Malangke"
  },
  {
    "id": "7322012003",
    "district_id": "732201",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7322012004",
    "district_id": "732201",
    "label": "Tolada",
    "value": "Tolada"
  },
  {
    "id": "7322012005",
    "district_id": "732201",
    "label": "Salekoe",
    "value": "Salekoe"
  },
  {
    "id": "7322012006",
    "district_id": "732201",
    "label": "Pute Mata",
    "value": "Pute Mata"
  },
  {
    "id": "7322012007",
    "district_id": "732201",
    "label": "Tingkara",
    "value": "Tingkara"
  },
  {
    "id": "7322012008",
    "district_id": "732201",
    "label": "Ladongi",
    "value": "Ladongi"
  },
  {
    "id": "7322012009",
    "district_id": "732201",
    "label": "Giri Kusuma",
    "value": "Giri Kusuma"
  },
  {
    "id": "7322012010",
    "district_id": "732201",
    "label": "Tandung",
    "value": "Tandung"
  },
  {
    "id": "7322012011",
    "district_id": "732201",
    "label": "Takkalala",
    "value": "Takkalala"
  },
  {
    "id": "7322012012",
    "district_id": "732201",
    "label": "Pettelandung",
    "value": "Pettelandung"
  },
  {
    "id": "7322012013",
    "district_id": "732201",
    "label": "Tokke",
    "value": "Tokke"
  },
  {
    "id": "7322012014",
    "district_id": "732201",
    "label": "Pince Pute",
    "value": "Pince Pute"
  },
  {
    "id": "7322021001",
    "district_id": "732202",
    "label": "Bone Bone",
    "value": "Bone Bone"
  },
  {
    "id": "7322022002",
    "district_id": "732202",
    "label": "Tamuku",
    "value": "Tamuku"
  },
  {
    "id": "7322022007",
    "district_id": "732202",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "7322022008",
    "district_id": "732202",
    "label": "Sukaraya",
    "value": "Sukaraya"
  },
  {
    "id": "7322022011",
    "district_id": "732202",
    "label": "Batang Tongka",
    "value": "Batang Tongka"
  },
  {
    "id": "7322022012",
    "district_id": "732202",
    "label": "Patoloan",
    "value": "Patoloan"
  },
  {
    "id": "7322022015",
    "district_id": "732202",
    "label": "Banyuurip",
    "value": "Banyuurip"
  },
  {
    "id": "7322022017",
    "district_id": "732202",
    "label": "Pongko",
    "value": "Pongko"
  },
  {
    "id": "7322022018",
    "district_id": "732202",
    "label": "Bantimurung",
    "value": "Bantimurung"
  },
  {
    "id": "7322022020",
    "district_id": "732202",
    "label": "Muktisari",
    "value": "Muktisari"
  },
  {
    "id": "7322022021",
    "district_id": "732202",
    "label": "Sadar",
    "value": "Sadar"
  },
  {
    "id": "7322031001",
    "district_id": "732203",
    "label": "Kappuna",
    "value": "Kappuna"
  },
  {
    "id": "7322031002",
    "district_id": "732203",
    "label": "Bone",
    "value": "Bone"
  },
  {
    "id": "7322031003",
    "district_id": "732203",
    "label": "Baliase",
    "value": "Baliase"
  },
  {
    "id": "7322031006",
    "district_id": "732203",
    "label": "Kasimbong",
    "value": "Kasimbong"
  },
  {
    "id": "7322032004",
    "district_id": "732203",
    "label": "Pincara",
    "value": "Pincara"
  },
  {
    "id": "7322032005",
    "district_id": "732203",
    "label": "Balebo",
    "value": "Balebo"
  },
  {
    "id": "7322032007",
    "district_id": "732203",
    "label": "Pombakka",
    "value": "Pombakka"
  },
  {
    "id": "7322032008",
    "district_id": "732203",
    "label": "Rompu",
    "value": "Rompu"
  },
  {
    "id": "7322032009",
    "district_id": "732203",
    "label": "Lantang Tallang",
    "value": "Lantang Tallang"
  },
  {
    "id": "7322032010",
    "district_id": "732203",
    "label": "Lapapa",
    "value": "Lapapa"
  },
  {
    "id": "7322032011",
    "district_id": "732203",
    "label": "Maipi",
    "value": "Maipi"
  },
  {
    "id": "7322032012",
    "district_id": "732203",
    "label": "Masamba",
    "value": "Masamba"
  },
  {
    "id": "7322032013",
    "district_id": "732203",
    "label": "Pandak",
    "value": "Pandak"
  },
  {
    "id": "7322032014",
    "district_id": "732203",
    "label": "Sepakat",
    "value": "Sepakat"
  },
  {
    "id": "7322032015",
    "district_id": "732203",
    "label": "Pongo",
    "value": "Pongo"
  },
  {
    "id": "7322032016",
    "district_id": "732203",
    "label": "Laba",
    "value": "Laba"
  },
  {
    "id": "7322032017",
    "district_id": "732203",
    "label": "Toradda",
    "value": "Toradda"
  },
  {
    "id": "7322032018",
    "district_id": "732203",
    "label": "Sumilin",
    "value": "Sumilin"
  },
  {
    "id": "7322032019",
    "district_id": "732203",
    "label": "Kamiri",
    "value": "Kamiri"
  },
  {
    "id": "7322041012",
    "district_id": "732204",
    "label": "Marobo",
    "value": "Marobo"
  },
  {
    "id": "7322042002",
    "district_id": "732204",
    "label": "Sabbang",
    "value": "Sabbang"
  },
  {
    "id": "7322042003",
    "district_id": "732204",
    "label": "Pararra",
    "value": "Pararra"
  },
  {
    "id": "7322042004",
    "district_id": "732204",
    "label": "Tandung",
    "value": "Tandung"
  },
  {
    "id": "7322042007",
    "district_id": "732204",
    "label": "Malimbu",
    "value": "Malimbu"
  },
  {
    "id": "7322042008",
    "district_id": "732204",
    "label": "Tullak Tallu",
    "value": "Tullak Tallu"
  },
  {
    "id": "7322042009",
    "district_id": "732204",
    "label": "Bakka",
    "value": "Bakka"
  },
  {
    "id": "7322042014",
    "district_id": "732204",
    "label": "Buntu Torpedo",
    "value": "Buntu Torpedo"
  },
  {
    "id": "7322042018",
    "district_id": "732204",
    "label": "Pengkendekan",
    "value": "Pengkendekan"
  },
  {
    "id": "7322042020",
    "district_id": "732204",
    "label": "Salama",
    "value": "Salama"
  },
  {
    "id": "7322052001",
    "district_id": "732205",
    "label": "Marampa",
    "value": "Marampa"
  },
  {
    "id": "7322052002",
    "district_id": "732205",
    "label": "Pengkendekan",
    "value": "Pengkendekan"
  },
  {
    "id": "7322052003",
    "district_id": "732205",
    "label": "Kanandede",
    "value": "Kanandede"
  },
  {
    "id": "7322052004",
    "district_id": "732205",
    "label": "Rinding Allo",
    "value": "Rinding Allo"
  },
  {
    "id": "7322052005",
    "district_id": "732205",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7322052006",
    "district_id": "732205",
    "label": "Komba",
    "value": "Komba"
  },
  {
    "id": "7322052007",
    "district_id": "732205",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "7322062001",
    "district_id": "732206",
    "label": "Lampuawa",
    "value": "Lampuawa"
  },
  {
    "id": "7322062002",
    "district_id": "732206",
    "label": "Katulungan",
    "value": "Katulungan"
  },
  {
    "id": "7322062003",
    "district_id": "732206",
    "label": "Salulemo",
    "value": "Salulemo"
  },
  {
    "id": "7322062004",
    "district_id": "732206",
    "label": "Kaluku",
    "value": "Kaluku"
  },
  {
    "id": "7322062005",
    "district_id": "732206",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "7322062006",
    "district_id": "732206",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "7322062010",
    "district_id": "732206",
    "label": "Tamboke",
    "value": "Tamboke"
  },
  {
    "id": "7322062013",
    "district_id": "732206",
    "label": "Tolangi",
    "value": "Tolangi"
  },
  {
    "id": "7322062014",
    "district_id": "732206",
    "label": "Tulung Indah",
    "value": "Tulung Indah"
  },
  {
    "id": "7322062019",
    "district_id": "732206",
    "label": "Minanga Tallu",
    "value": "Minanga Tallu"
  },
  {
    "id": "7322062020",
    "district_id": "732206",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "7322062021",
    "district_id": "732206",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "7322062022",
    "district_id": "732206",
    "label": "Saptamarga",
    "value": "Saptamarga"
  },
  {
    "id": "7322062024",
    "district_id": "732206",
    "label": "Tulungsari",
    "value": "Tulungsari"
  },
  {
    "id": "7322072001",
    "district_id": "732207",
    "label": "Tirobali",
    "value": "Tirobali"
  },
  {
    "id": "7322072002",
    "district_id": "732207",
    "label": "Malimongan",
    "value": "Malimongan"
  },
  {
    "id": "7322072003",
    "district_id": "732207",
    "label": "Tanamakaleang",
    "value": "Tanamakaleang"
  },
  {
    "id": "7322072004",
    "district_id": "732207",
    "label": "Padang Balua",
    "value": "Padang Balua"
  },
  {
    "id": "7322072005",
    "district_id": "732207",
    "label": "Padang Raya",
    "value": "Padang Raya"
  },
  {
    "id": "7322072006",
    "district_id": "732207",
    "label": "Embonatana",
    "value": "Embonatana"
  },
  {
    "id": "7322072007",
    "district_id": "732207",
    "label": "Hono",
    "value": "Hono"
  },
  {
    "id": "7322072008",
    "district_id": "732207",
    "label": "Beroppa",
    "value": "Beroppa"
  },
  {
    "id": "7322072009",
    "district_id": "732207",
    "label": "Lodang",
    "value": "Lodang"
  },
  {
    "id": "7322072010",
    "district_id": "732207",
    "label": "Taloto",
    "value": "Taloto"
  },
  {
    "id": "7322072011",
    "district_id": "732207",
    "label": "Hoyane",
    "value": "Hoyane"
  },
  {
    "id": "7322072012",
    "district_id": "732207",
    "label": "Marante",
    "value": "Marante"
  },
  {
    "id": "7322082001",
    "district_id": "732208",
    "label": "Cening",
    "value": "Cening"
  },
  {
    "id": "7322082002",
    "district_id": "732208",
    "label": "Pangkajoang",
    "value": "Pangkajoang"
  },
  {
    "id": "7322082003",
    "district_id": "732208",
    "label": "Pao",
    "value": "Pao"
  },
  {
    "id": "7322082004",
    "district_id": "732208",
    "label": "Arusu",
    "value": "Arusu"
  },
  {
    "id": "7322082005",
    "district_id": "732208",
    "label": "Baku Baku",
    "value": "Baku Baku"
  },
  {
    "id": "7322082006",
    "district_id": "732208",
    "label": "Wara",
    "value": "Wara"
  },
  {
    "id": "7322082007",
    "district_id": "732208",
    "label": "Waelawi",
    "value": "Waelawi"
  },
  {
    "id": "7322082008",
    "district_id": "732208",
    "label": "Pembuniang",
    "value": "Pembuniang"
  },
  {
    "id": "7322082009",
    "district_id": "732208",
    "label": "Limbong Wara",
    "value": "Limbong Wara"
  },
  {
    "id": "7322082010",
    "district_id": "732208",
    "label": "Kalitata",
    "value": "Kalitata"
  },
  {
    "id": "7322082011",
    "district_id": "732208",
    "label": "Pombakka",
    "value": "Pombakka"
  },
  {
    "id": "7322082012",
    "district_id": "732208",
    "label": "Polejiwa",
    "value": "Polejiwa"
  },
  {
    "id": "7322082013",
    "district_id": "732208",
    "label": "Waetuo",
    "value": "Waetuo"
  },
  {
    "id": "7322092001",
    "district_id": "732209",
    "label": "Rampi",
    "value": "Rampi"
  },
  {
    "id": "7322092002",
    "district_id": "732209",
    "label": "Onondowa",
    "value": "Onondowa"
  },
  {
    "id": "7322092003",
    "district_id": "732209",
    "label": "Leboni",
    "value": "Leboni"
  },
  {
    "id": "7322092004",
    "district_id": "732209",
    "label": "Dodolo",
    "value": "Dodolo"
  },
  {
    "id": "7322092005",
    "district_id": "732209",
    "label": "Sulaku",
    "value": "Sulaku"
  },
  {
    "id": "7322092006",
    "district_id": "732209",
    "label": "Tedeboe",
    "value": "Tedeboe"
  },
  {
    "id": "7322102001",
    "district_id": "732210",
    "label": "Mappedeceng",
    "value": "Mappedeceng"
  },
  {
    "id": "7322102002",
    "district_id": "732210",
    "label": "Cendana Putih",
    "value": "Cendana Putih"
  },
  {
    "id": "7322102003",
    "district_id": "732210",
    "label": "Hasana",
    "value": "Hasana"
  },
  {
    "id": "7322102004",
    "district_id": "732210",
    "label": "Kapidi",
    "value": "Kapidi"
  },
  {
    "id": "7322102005",
    "district_id": "732210",
    "label": "Uraso",
    "value": "Uraso"
  },
  {
    "id": "7322102006",
    "district_id": "732210",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7322102007",
    "district_id": "732210",
    "label": "Ujung Mattajang",
    "value": "Ujung Mattajang"
  },
  {
    "id": "7322102009",
    "district_id": "732210",
    "label": "Cendana Putih I",
    "value": "Cendana Putih I"
  },
  {
    "id": "7322102010",
    "district_id": "732210",
    "label": "Sumber Wangi",
    "value": "Sumber Wangi"
  },
  {
    "id": "7322102011",
    "district_id": "732210",
    "label": "Mangalle",
    "value": "Mangalle"
  },
  {
    "id": "7322102012",
    "district_id": "732210",
    "label": "Mekar Jaya Tondok",
    "value": "Mekar Jaya Tondok"
  },
  {
    "id": "7322102013",
    "district_id": "732210",
    "label": "Cendana Putih II",
    "value": "Cendana Putih II"
  },
  {
    "id": "7322102014",
    "district_id": "732210",
    "label": "Sumber Harum",
    "value": "Sumber Harum"
  },
  {
    "id": "7322102015",
    "district_id": "732210",
    "label": "Tarak Tallu",
    "value": "Tarak Tallu"
  },
  {
    "id": "7322102016",
    "district_id": "732210",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "7322111005",
    "district_id": "732211",
    "label": "Salassa",
    "value": "Salassa"
  },
  {
    "id": "7322112002",
    "district_id": "732211",
    "label": "Salulemo",
    "value": "Salulemo"
  },
  {
    "id": "7322112003",
    "district_id": "732211",
    "label": "Radda",
    "value": "Radda"
  },
  {
    "id": "7322112004",
    "district_id": "732211",
    "label": "Baebunta",
    "value": "Baebunta"
  },
  {
    "id": "7322112007",
    "district_id": "732211",
    "label": "Tarobok",
    "value": "Tarobok"
  },
  {
    "id": "7322112008",
    "district_id": "732211",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7322112009",
    "district_id": "732211",
    "label": "Sassa",
    "value": "Sassa"
  },
  {
    "id": "7322112010",
    "district_id": "732211",
    "label": "Kariango",
    "value": "Kariango"
  },
  {
    "id": "7322112011",
    "district_id": "732211",
    "label": "Melli",
    "value": "Melli"
  },
  {
    "id": "7322112013",
    "district_id": "732211",
    "label": "Palandan",
    "value": "Palandan"
  },
  {
    "id": "7322112015",
    "district_id": "732211",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "7322122001",
    "district_id": "732212",
    "label": "Bungapati",
    "value": "Bungapati"
  },
  {
    "id": "7322122002",
    "district_id": "732212",
    "label": "Bungadidi",
    "value": "Bungadidi"
  },
  {
    "id": "7322122003",
    "district_id": "732212",
    "label": "Poreang",
    "value": "Poreang"
  },
  {
    "id": "7322122004",
    "district_id": "732212",
    "label": "Sumberdadi",
    "value": "Sumberdadi"
  },
  {
    "id": "7322122005",
    "district_id": "732212",
    "label": "Patila",
    "value": "Patila"
  },
  {
    "id": "7322122006",
    "district_id": "732212",
    "label": "Sidobinangun",
    "value": "Sidobinangun"
  },
  {
    "id": "7322122007",
    "district_id": "732212",
    "label": "Rampoang",
    "value": "Rampoang"
  },
  {
    "id": "7322122008",
    "district_id": "732212",
    "label": "Karondang",
    "value": "Karondang"
  },
  {
    "id": "7322122009",
    "district_id": "732212",
    "label": "Munte",
    "value": "Munte"
  },
  {
    "id": "7322122010",
    "district_id": "732212",
    "label": "Sidomakmur",
    "value": "Sidomakmur"
  },
  {
    "id": "7322132001",
    "district_id": "732213",
    "label": "Mulyorejo",
    "value": "Mulyorejo"
  },
  {
    "id": "7322132002",
    "district_id": "732213",
    "label": "Rawamangun",
    "value": "Rawamangun"
  },
  {
    "id": "7322132003",
    "district_id": "732213",
    "label": "Paomacang",
    "value": "Paomacang"
  },
  {
    "id": "7322132004",
    "district_id": "732213",
    "label": "Wonokerto",
    "value": "Wonokerto"
  },
  {
    "id": "7322132005",
    "district_id": "732213",
    "label": "Sumberbaru",
    "value": "Sumberbaru"
  },
  {
    "id": "7322132006",
    "district_id": "732213",
    "label": "Banyuwangi",
    "value": "Banyuwangi"
  },
  {
    "id": "7322132007",
    "district_id": "732213",
    "label": "Subur",
    "value": "Subur"
  },
  {
    "id": "7322132008",
    "district_id": "732213",
    "label": "Lino",
    "value": "Lino"
  },
  {
    "id": "7322132009",
    "district_id": "732213",
    "label": "Sidoharjo",
    "value": "Sidoharjo"
  },
  {
    "id": "7322132010",
    "district_id": "732213",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "7322132011",
    "district_id": "732213",
    "label": "Sukaharapan",
    "value": "Sukaharapan"
  },
  {
    "id": "7322142001",
    "district_id": "732214",
    "label": "Lara",
    "value": "Lara"
  },
  {
    "id": "7322142002",
    "district_id": "732214",
    "label": "Marannu",
    "value": "Marannu"
  },
  {
    "id": "7322142003",
    "district_id": "732214",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "7322142004",
    "district_id": "732214",
    "label": "Mukti Jaya",
    "value": "Mukti Jaya"
  },
  {
    "id": "7322142005",
    "district_id": "732214",
    "label": "Mekarsari Jaya",
    "value": "Mekarsari Jaya"
  },
  {
    "id": "7322142006",
    "district_id": "732214",
    "label": "Mukti Tama",
    "value": "Mukti Tama"
  },
  {
    "id": "7322142007",
    "district_id": "732214",
    "label": "Lembang-lembang",
    "value": "Lembang-lembang"
  },
  {
    "id": "7322142008",
    "district_id": "732214",
    "label": "Lawewe",
    "value": "Lawewe"
  },
  {
    "id": "7322142009",
    "district_id": "732214",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7322142010",
    "district_id": "732214",
    "label": "Sumpira",
    "value": "Sumpira"
  },
  {
    "id": "7322152001",
    "district_id": "732215",
    "label": "Buangin",
    "value": "Buangin"
  },
  {
    "id": "7322152002",
    "district_id": "732215",
    "label": "Terpedojaya",
    "value": "Terpedojaya"
  },
  {
    "id": "7322152003",
    "district_id": "732215",
    "label": "Dandang",
    "value": "Dandang"
  },
  {
    "id": "7322152004",
    "district_id": "732215",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "7322152005",
    "district_id": "732215",
    "label": "Teteuri",
    "value": "Teteuri"
  },
  {
    "id": "7322152006",
    "district_id": "732215",
    "label": "Kalotok",
    "value": "Kalotok"
  },
  {
    "id": "7322152007",
    "district_id": "732215",
    "label": "Batualang",
    "value": "Batualang"
  },
  {
    "id": "7322152008",
    "district_id": "732215",
    "label": "Bone Subur",
    "value": "Bone Subur"
  },
  {
    "id": "7322152009",
    "district_id": "732215",
    "label": "Pompaniki",
    "value": "Pompaniki"
  },
  {
    "id": "7322152010",
    "district_id": "732215",
    "label": "Mari-mari",
    "value": "Mari-mari"
  },
  {
    "id": "7324012001",
    "district_id": "732401",
    "label": "Maleku",
    "value": "Maleku"
  },
  {
    "id": "7324012002",
    "district_id": "732401",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "7324012003",
    "district_id": "732401",
    "label": "Margolembo",
    "value": "Margolembo"
  },
  {
    "id": "7324012004",
    "district_id": "732401",
    "label": "Teromu",
    "value": "Teromu"
  },
  {
    "id": "7324012007",
    "district_id": "732401",
    "label": "Manggala",
    "value": "Manggala"
  },
  {
    "id": "7324012009",
    "district_id": "732401",
    "label": "Kasintuwu",
    "value": "Kasintuwu"
  },
  {
    "id": "7324012011",
    "district_id": "732401",
    "label": "Balai Kembang",
    "value": "Balai Kembang"
  },
  {
    "id": "7324012013",
    "district_id": "732401",
    "label": "Panca Karsa",
    "value": "Panca Karsa"
  },
  {
    "id": "7324012014",
    "district_id": "732401",
    "label": "Sindu Agung",
    "value": "Sindu Agung"
  },
  {
    "id": "7324012015",
    "district_id": "732401",
    "label": "Wonorejo Timur",
    "value": "Wonorejo Timur"
  },
  {
    "id": "7324012016",
    "district_id": "732401",
    "label": "Koroncia",
    "value": "Koroncia"
  },
  {
    "id": "7324021007",
    "district_id": "732402",
    "label": "Magani",
    "value": "Magani"
  },
  {
    "id": "7324022001",
    "district_id": "732402",
    "label": "Nuha",
    "value": "Nuha"
  },
  {
    "id": "7324022003",
    "district_id": "732402",
    "label": "Nikkel",
    "value": "Nikkel"
  },
  {
    "id": "7324022005",
    "district_id": "732402",
    "label": "Matano",
    "value": "Matano"
  },
  {
    "id": "7324022009",
    "district_id": "732402",
    "label": "Sorowako",
    "value": "Sorowako"
  },
  {
    "id": "7324032001",
    "district_id": "732403",
    "label": "Loeha",
    "value": "Loeha"
  },
  {
    "id": "7324032002",
    "district_id": "732403",
    "label": "Mahalona",
    "value": "Mahalona"
  },
  {
    "id": "7324032003",
    "district_id": "732403",
    "label": "Timampu",
    "value": "Timampu"
  },
  {
    "id": "7324032004",
    "district_id": "732403",
    "label": "Wawondula",
    "value": "Wawondula"
  },
  {
    "id": "7324032005",
    "district_id": "732403",
    "label": "Langkea Raya",
    "value": "Langkea Raya"
  },
  {
    "id": "7324032006",
    "district_id": "732403",
    "label": "Tokalimbo",
    "value": "Tokalimbo"
  },
  {
    "id": "7324032007",
    "district_id": "732403",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7324032008",
    "district_id": "732403",
    "label": "Pekaloa",
    "value": "Pekaloa"
  },
  {
    "id": "7324032009",
    "district_id": "732403",
    "label": "Lioka",
    "value": "Lioka"
  },
  {
    "id": "7324032010",
    "district_id": "732403",
    "label": "Asuli",
    "value": "Asuli"
  },
  {
    "id": "7324032011",
    "district_id": "732403",
    "label": "Bantilang",
    "value": "Bantilang"
  },
  {
    "id": "7324032012",
    "district_id": "732403",
    "label": "Masiku",
    "value": "Masiku"
  },
  {
    "id": "7324032013",
    "district_id": "732403",
    "label": "Rante Angin",
    "value": "Rante Angin"
  },
  {
    "id": "7324032014",
    "district_id": "732403",
    "label": "Matompi",
    "value": "Matompi"
  },
  {
    "id": "7324032015",
    "district_id": "732403",
    "label": "Tole",
    "value": "Tole"
  },
  {
    "id": "7324032016",
    "district_id": "732403",
    "label": "Libukan Mandiri",
    "value": "Libukan Mandiri"
  },
  {
    "id": "7324032017",
    "district_id": "732403",
    "label": "Kalosi",
    "value": "Kalosi"
  },
  {
    "id": "7324032018",
    "district_id": "732403",
    "label": "Buangin",
    "value": "Buangin"
  },
  {
    "id": "7324041003",
    "district_id": "732404",
    "label": "Malili",
    "value": "Malili"
  },
  {
    "id": "7324042001",
    "district_id": "732404",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "7324042002",
    "district_id": "732404",
    "label": "Laskap",
    "value": "Laskap"
  },
  {
    "id": "7324042004",
    "district_id": "732404",
    "label": "Manurung",
    "value": "Manurung"
  },
  {
    "id": "7324042005",
    "district_id": "732404",
    "label": "Wewangriu",
    "value": "Wewangriu"
  },
  {
    "id": "7324042006",
    "district_id": "732404",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7324042007",
    "district_id": "732404",
    "label": "Lakawali",
    "value": "Lakawali"
  },
  {
    "id": "7324042008",
    "district_id": "732404",
    "label": "Ussu",
    "value": "Ussu"
  },
  {
    "id": "7324042009",
    "district_id": "732404",
    "label": "Tarabbi",
    "value": "Tarabbi"
  },
  {
    "id": "7324042010",
    "district_id": "732404",
    "label": "Balantang",
    "value": "Balantang"
  },
  {
    "id": "7324042011",
    "district_id": "732404",
    "label": "Atue",
    "value": "Atue"
  },
  {
    "id": "7324042012",
    "district_id": "732404",
    "label": "Pongkeru",
    "value": "Pongkeru"
  },
  {
    "id": "7324042013",
    "district_id": "732404",
    "label": "Puncak Indah",
    "value": "Puncak Indah"
  },
  {
    "id": "7324042014",
    "district_id": "732404",
    "label": "Pasi-Pasi",
    "value": "Pasi-Pasi"
  },
  {
    "id": "7324042015",
    "district_id": "732404",
    "label": "Lakawali Pantai",
    "value": "Lakawali Pantai"
  },
  {
    "id": "7324052001",
    "district_id": "732405",
    "label": "Tawakua",
    "value": "Tawakua"
  },
  {
    "id": "7324052002",
    "district_id": "732405",
    "label": "Tampinna",
    "value": "Tampinna"
  },
  {
    "id": "7324052003",
    "district_id": "732405",
    "label": "Solo",
    "value": "Solo"
  },
  {
    "id": "7324052004",
    "district_id": "732405",
    "label": "Taripa",
    "value": "Taripa"
  },
  {
    "id": "7324052005",
    "district_id": "732405",
    "label": "Mantadulu",
    "value": "Mantadulu"
  },
  {
    "id": "7324052006",
    "district_id": "732405",
    "label": "Balirejo",
    "value": "Balirejo"
  },
  {
    "id": "7324052007",
    "district_id": "732405",
    "label": "Maliwowo",
    "value": "Maliwowo"
  },
  {
    "id": "7324052008",
    "district_id": "732405",
    "label": "Lamaeto",
    "value": "Lamaeto"
  },
  {
    "id": "7324052009",
    "district_id": "732405",
    "label": "Watangpanua",
    "value": "Watangpanua"
  },
  {
    "id": "7324052010",
    "district_id": "732405",
    "label": "Wanasari",
    "value": "Wanasari"
  },
  {
    "id": "7324062001",
    "district_id": "732406",
    "label": "Lampenai",
    "value": "Lampenai"
  },
  {
    "id": "7324062002",
    "district_id": "732406",
    "label": "Tarengge",
    "value": "Tarengge"
  },
  {
    "id": "7324062003",
    "district_id": "732406",
    "label": "Maramba",
    "value": "Maramba"
  },
  {
    "id": "7324062004",
    "district_id": "732406",
    "label": "Cendana Hijau",
    "value": "Cendana Hijau"
  },
  {
    "id": "7324062005",
    "district_id": "732406",
    "label": "Bawalipu",
    "value": "Bawalipu"
  },
  {
    "id": "7324062006",
    "district_id": "732406",
    "label": "Kalaena",
    "value": "Kalaena"
  },
  {
    "id": "7324062007",
    "district_id": "732406",
    "label": "Lera",
    "value": "Lera"
  },
  {
    "id": "7324062008",
    "district_id": "732406",
    "label": "Kanawatu",
    "value": "Kanawatu"
  },
  {
    "id": "7324062009",
    "district_id": "732406",
    "label": "Bahari",
    "value": "Bahari"
  },
  {
    "id": "7324062010",
    "district_id": "732406",
    "label": "Karambua",
    "value": "Karambua"
  },
  {
    "id": "7324062011",
    "district_id": "732406",
    "label": "Pepuro Barat",
    "value": "Pepuro Barat"
  },
  {
    "id": "7324062012",
    "district_id": "732406",
    "label": "Balo-Balo",
    "value": "Balo-Balo"
  },
  {
    "id": "7324062013",
    "district_id": "732406",
    "label": "Rinjani",
    "value": "Rinjani"
  },
  {
    "id": "7324062014",
    "district_id": "732406",
    "label": "Tarengge Timur",
    "value": "Tarengge Timur"
  },
  {
    "id": "7324062015",
    "district_id": "732406",
    "label": "Madani",
    "value": "Madani"
  },
  {
    "id": "7324062016",
    "district_id": "732406",
    "label": "Tabaroge",
    "value": "Tabaroge"
  },
  {
    "id": "7324072001",
    "district_id": "732407",
    "label": "Burau",
    "value": "Burau"
  },
  {
    "id": "7324072002",
    "district_id": "732407",
    "label": "Jalajja",
    "value": "Jalajja"
  },
  {
    "id": "7324072003",
    "district_id": "732407",
    "label": "Lewonu",
    "value": "Lewonu"
  },
  {
    "id": "7324072004",
    "district_id": "732407",
    "label": "Lambarese",
    "value": "Lambarese"
  },
  {
    "id": "7324072005",
    "district_id": "732407",
    "label": "Lauwo",
    "value": "Lauwo"
  },
  {
    "id": "7324072006",
    "district_id": "732407",
    "label": "Bone Pute",
    "value": "Bone Pute"
  },
  {
    "id": "7324072007",
    "district_id": "732407",
    "label": "Lumbewe",
    "value": "Lumbewe"
  },
  {
    "id": "7324072008",
    "district_id": "732407",
    "label": "Mabonta",
    "value": "Mabonta"
  },
  {
    "id": "7324072009",
    "district_id": "732407",
    "label": "Laro",
    "value": "Laro"
  },
  {
    "id": "7324072010",
    "district_id": "732407",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7324072011",
    "district_id": "732407",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "7324072012",
    "district_id": "732407",
    "label": "Lanosi",
    "value": "Lanosi"
  },
  {
    "id": "7324072013",
    "district_id": "732407",
    "label": "Lagego",
    "value": "Lagego"
  },
  {
    "id": "7324072014",
    "district_id": "732407",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "7324072015",
    "district_id": "732407",
    "label": "Burau Pantai",
    "value": "Burau Pantai"
  },
  {
    "id": "7324072016",
    "district_id": "732407",
    "label": "Asana",
    "value": "Asana"
  },
  {
    "id": "7324072017",
    "district_id": "732407",
    "label": "Kalatiri",
    "value": "Kalatiri"
  },
  {
    "id": "7324072018",
    "district_id": "732407",
    "label": "Lambara Harapan",
    "value": "Lambara Harapan"
  },
  {
    "id": "7324081003",
    "district_id": "732408",
    "label": "Tomoni",
    "value": "Tomoni"
  },
  {
    "id": "7324082001",
    "district_id": "732408",
    "label": "Bayondo",
    "value": "Bayondo"
  },
  {
    "id": "7324082002",
    "district_id": "732408",
    "label": "Mulyasri",
    "value": "Mulyasri"
  },
  {
    "id": "7324082007",
    "district_id": "732408",
    "label": "Lestari",
    "value": "Lestari"
  },
  {
    "id": "7324082008",
    "district_id": "732408",
    "label": "Kalpataru",
    "value": "Kalpataru"
  },
  {
    "id": "7324082011",
    "district_id": "732408",
    "label": "Tadulako",
    "value": "Tadulako"
  },
  {
    "id": "7324082012",
    "district_id": "732408",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "7324082015",
    "district_id": "732408",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "7324082016",
    "district_id": "732408",
    "label": "Mandiri",
    "value": "Mandiri"
  },
  {
    "id": "7324082017",
    "district_id": "732408",
    "label": "Sumber Alam",
    "value": "Sumber Alam"
  },
  {
    "id": "7324082018",
    "district_id": "732408",
    "label": "Ujung Baru",
    "value": "Ujung Baru"
  },
  {
    "id": "7324082019",
    "district_id": "732408",
    "label": "Bangun Karya",
    "value": "Bangun Karya"
  },
  {
    "id": "7324082020",
    "district_id": "732408",
    "label": "Rante Mario",
    "value": "Rante Mario"
  },
  {
    "id": "7324092001",
    "district_id": "732409",
    "label": "Kertoraharjo",
    "value": "Kertoraharjo"
  },
  {
    "id": "7324092002",
    "district_id": "732409",
    "label": "Cendana Hitam",
    "value": "Cendana Hitam"
  },
  {
    "id": "7324092003",
    "district_id": "732409",
    "label": "Purwosari",
    "value": "Purwosari"
  },
  {
    "id": "7324092004",
    "district_id": "732409",
    "label": "Pattengko",
    "value": "Pattengko"
  },
  {
    "id": "7324092005",
    "district_id": "732409",
    "label": "Manunggal",
    "value": "Manunggal"
  },
  {
    "id": "7324092006",
    "district_id": "732409",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "7324092007",
    "district_id": "732409",
    "label": "Alam Buana",
    "value": "Alam Buana"
  },
  {
    "id": "7324092008",
    "district_id": "732409",
    "label": "Cendana Hitam Timur",
    "value": "Cendana Hitam Timur"
  },
  {
    "id": "7324102001",
    "district_id": "732410",
    "label": "Kalaena Kiri",
    "value": "Kalaena Kiri"
  },
  {
    "id": "7324102002",
    "district_id": "732410",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "7324102003",
    "district_id": "732410",
    "label": "Pertasi Kencana",
    "value": "Pertasi Kencana"
  },
  {
    "id": "7324102004",
    "district_id": "732410",
    "label": "Non Blok",
    "value": "Non Blok"
  },
  {
    "id": "7324102005",
    "district_id": "732410",
    "label": "Argomulyo",
    "value": "Argomulyo"
  },
  {
    "id": "7324102006",
    "district_id": "732410",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "7324102007",
    "district_id": "732410",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "7324112001",
    "district_id": "732411",
    "label": "Ledu Ledu",
    "value": "Ledu Ledu"
  },
  {
    "id": "7324112002",
    "district_id": "732411",
    "label": "Kawata",
    "value": "Kawata"
  },
  {
    "id": "7324112003",
    "district_id": "732411",
    "label": "Tabarano",
    "value": "Tabarano"
  },
  {
    "id": "7324112004",
    "district_id": "732411",
    "label": "Wasuponda",
    "value": "Wasuponda"
  },
  {
    "id": "7324112005",
    "district_id": "732411",
    "label": "Parumpanai",
    "value": "Parumpanai"
  },
  {
    "id": "7324112006",
    "district_id": "732411",
    "label": "Balambano",
    "value": "Balambano"
  },
  {
    "id": "7326011001",
    "district_id": "732601",
    "label": "Laang Tanduk",
    "value": "Laang Tanduk"
  },
  {
    "id": "7326011002",
    "district_id": "732601",
    "label": "Rantepao",
    "value": "Rantepao"
  },
  {
    "id": "7326011003",
    "district_id": "732601",
    "label": "Mentirotiku",
    "value": "Mentirotiku"
  },
  {
    "id": "7326011004",
    "district_id": "732601",
    "label": "Manggalo",
    "value": "Manggalo"
  },
  {
    "id": "7326011005",
    "district_id": "732601",
    "label": "Pasale",
    "value": "Pasale"
  },
  {
    "id": "7326011006",
    "district_id": "732601",
    "label": "Singki'",
    "value": "Singki'"
  },
  {
    "id": "7326011007",
    "district_id": "732601",
    "label": "Karassik",
    "value": "Karassik"
  },
  {
    "id": "7326011008",
    "district_id": "732601",
    "label": "Penanian",
    "value": "Penanian"
  },
  {
    "id": "7326011009",
    "district_id": "732601",
    "label": "Rante Pasele",
    "value": "Rante Pasele"
  },
  {
    "id": "7326012010",
    "district_id": "732601",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "7326012011",
    "district_id": "732601",
    "label": "Saloso",
    "value": "Saloso"
  },
  {
    "id": "7326021001",
    "district_id": "732602",
    "label": "Bori",
    "value": "Bori"
  },
  {
    "id": "7326021002",
    "district_id": "732602",
    "label": "Pangli",
    "value": "Pangli"
  },
  {
    "id": "7326021004",
    "district_id": "732602",
    "label": "Palawa'",
    "value": "Palawa'"
  },
  {
    "id": "7326021005",
    "district_id": "732602",
    "label": "Deri'",
    "value": "Deri'"
  },
  {
    "id": "7326021009",
    "district_id": "732602",
    "label": "Pangli Selatan",
    "value": "Pangli Selatan"
  },
  {
    "id": "7326022003",
    "district_id": "732602",
    "label": "Buntu Lobo'",
    "value": "Buntu Lobo'"
  },
  {
    "id": "7326022006",
    "district_id": "732602",
    "label": "Parinding",
    "value": "Parinding"
  },
  {
    "id": "7326022007",
    "district_id": "732602",
    "label": "Bori' Ranteletok",
    "value": "Bori' Ranteletok"
  },
  {
    "id": "7326022008",
    "district_id": "732602",
    "label": "Bori' Lombongan",
    "value": "Bori' Lombongan"
  },
  {
    "id": "7326031004",
    "district_id": "732603",
    "label": "Nanggala Sanpiak Salu",
    "value": "Nanggala Sanpiak Salu"
  },
  {
    "id": "7326032001",
    "district_id": "732603",
    "label": "Nanggala",
    "value": "Nanggala"
  },
  {
    "id": "7326032002",
    "district_id": "732603",
    "label": "Tandung Nanggala",
    "value": "Tandung Nanggala"
  },
  {
    "id": "7326032003",
    "district_id": "732603",
    "label": "Lili'kira'",
    "value": "Lili'kira'"
  },
  {
    "id": "7326032005",
    "district_id": "732603",
    "label": "Karre Limbong",
    "value": "Karre Limbong"
  },
  {
    "id": "7326032006",
    "district_id": "732603",
    "label": "Rante",
    "value": "Rante"
  },
  {
    "id": "7326032007",
    "district_id": "732603",
    "label": "Basokan",
    "value": "Basokan"
  },
  {
    "id": "7326032008",
    "district_id": "732603",
    "label": "Nanna' Nanggala",
    "value": "Nanna' Nanggala"
  },
  {
    "id": "7326032009",
    "district_id": "732603",
    "label": "Karre Pananian",
    "value": "Karre Pananian"
  },
  {
    "id": "7326041002",
    "district_id": "732604",
    "label": "Pangala'",
    "value": "Pangala'"
  },
  {
    "id": "7326041004",
    "district_id": "732604",
    "label": "Pangala' Utara",
    "value": "Pangala' Utara"
  },
  {
    "id": "7326042001",
    "district_id": "732604",
    "label": "Limbong Malting",
    "value": "Limbong Malting"
  },
  {
    "id": "7326042003",
    "district_id": "732604",
    "label": "Rindingallo",
    "value": "Rindingallo"
  },
  {
    "id": "7326042005",
    "district_id": "732604",
    "label": "Mai'ting",
    "value": "Mai'ting"
  },
  {
    "id": "7326042006",
    "district_id": "732604",
    "label": "Lo'ko' Uru",
    "value": "Lo'ko' Uru"
  },
  {
    "id": "7326042007",
    "district_id": "732604",
    "label": "Ampang Batu",
    "value": "Ampang Batu"
  },
  {
    "id": "7326042008",
    "district_id": "732604",
    "label": "Lempo Poton",
    "value": "Lempo Poton"
  },
  {
    "id": "7326042009",
    "district_id": "732604",
    "label": "Buntu Batu",
    "value": "Buntu Batu"
  },
  {
    "id": "7326051005",
    "district_id": "732605",
    "label": "Tullang' Sura",
    "value": "Tullang' Sura"
  },
  {
    "id": "7326051006",
    "district_id": "732605",
    "label": "Tongkonan Bassae",
    "value": "Tongkonan Bassae"
  },
  {
    "id": "7326052001",
    "district_id": "732605",
    "label": "Sapan Kua-kua",
    "value": "Sapan Kua-kua"
  },
  {
    "id": "7326052002",
    "district_id": "732605",
    "label": "Rindingkila'",
    "value": "Rindingkila'"
  },
  {
    "id": "7326052003",
    "district_id": "732605",
    "label": "Misa'ba'bana",
    "value": "Misa'ba'bana"
  },
  {
    "id": "7326052004",
    "district_id": "732605",
    "label": "Issong Kalua'",
    "value": "Issong Kalua'"
  },
  {
    "id": "7326061002",
    "district_id": "732606",
    "label": "Sa'dan Malimbong",
    "value": "Sa'dan Malimbong"
  },
  {
    "id": "7326061004",
    "district_id": "732606",
    "label": "Sa'dan Matalo",
    "value": "Sa'dan Matalo"
  },
  {
    "id": "7326062001",
    "district_id": "732606",
    "label": "Sa'dan Ulusalu",
    "value": "Sa'dan Ulusalu"
  },
  {
    "id": "7326062003",
    "district_id": "732606",
    "label": "Sa'dan Ballo Pasange'",
    "value": "Sa'dan Ballo Pasange'"
  },
  {
    "id": "7326062005",
    "district_id": "732606",
    "label": "Sa'dan Pebulian",
    "value": "Sa'dan Pebulian"
  },
  {
    "id": "7326062006",
    "district_id": "732606",
    "label": "Sangkaropi",
    "value": "Sangkaropi"
  },
  {
    "id": "7326062007",
    "district_id": "732606",
    "label": "Sa'dan Tiroallo",
    "value": "Sa'dan Tiroallo"
  },
  {
    "id": "7326062008",
    "district_id": "732606",
    "label": "Sa'dan Andulan",
    "value": "Sa'dan Andulan"
  },
  {
    "id": "7326062009",
    "district_id": "732606",
    "label": "Sa'dan Pesondongan",
    "value": "Sa'dan Pesondongan"
  },
  {
    "id": "7326062010",
    "district_id": "732606",
    "label": "Sa'dan Luku Lambe'na",
    "value": "Sa'dan Luku Lambe'na"
  },
  {
    "id": "7326071006",
    "district_id": "732607",
    "label": "Paepalean",
    "value": "Paepalean"
  },
  {
    "id": "7326072001",
    "district_id": "732607",
    "label": "La'bo'",
    "value": "La'bo'"
  },
  {
    "id": "7326072002",
    "district_id": "732607",
    "label": "Buntu La'bo'",
    "value": "Buntu La'bo'"
  },
  {
    "id": "7326072003",
    "district_id": "732607",
    "label": "Tallung Penanian",
    "value": "Tallung Penanian"
  },
  {
    "id": "7326072004",
    "district_id": "732607",
    "label": "Pata'padang",
    "value": "Pata'padang"
  },
  {
    "id": "7326072005",
    "district_id": "732607",
    "label": "Tandung La'bo'",
    "value": "Tandung La'bo'"
  },
  {
    "id": "7326081002",
    "district_id": "732608",
    "label": "Nonongan Utara",
    "value": "Nonongan Utara"
  },
  {
    "id": "7326082001",
    "district_id": "732608",
    "label": "Salu",
    "value": "Salu"
  },
  {
    "id": "7326082003",
    "district_id": "732608",
    "label": "Salu Sopai",
    "value": "Salu Sopai"
  },
  {
    "id": "7326082004",
    "district_id": "732608",
    "label": "Tombang Langda",
    "value": "Tombang Langda"
  },
  {
    "id": "7326082005",
    "district_id": "732608",
    "label": "Marante",
    "value": "Marante"
  },
  {
    "id": "7326082006",
    "district_id": "732608",
    "label": "Langda",
    "value": "Langda"
  },
  {
    "id": "7326082007",
    "district_id": "732608",
    "label": "Nonongan Selatan",
    "value": "Nonongan Selatan"
  },
  {
    "id": "7326082008",
    "district_id": "732608",
    "label": "Salu Sarre",
    "value": "Salu Sarre"
  },
  {
    "id": "7326091001",
    "district_id": "732609",
    "label": "Tikala",
    "value": "Tikala"
  },
  {
    "id": "7326091002",
    "district_id": "732609",
    "label": "Buntu Barana",
    "value": "Buntu Barana"
  },
  {
    "id": "7326092003",
    "district_id": "732609",
    "label": "Buntu Batu",
    "value": "Buntu Batu"
  },
  {
    "id": "7326092004",
    "district_id": "732609",
    "label": "Sereale",
    "value": "Sereale"
  },
  {
    "id": "7326092005",
    "district_id": "732609",
    "label": "Embatau",
    "value": "Embatau"
  },
  {
    "id": "7326092006",
    "district_id": "732609",
    "label": "Benteng Ka'do To'rea",
    "value": "Benteng Ka'do To'rea"
  },
  {
    "id": "7326092007",
    "district_id": "732609",
    "label": "Pangden",
    "value": "Pangden"
  },
  {
    "id": "7326101002",
    "district_id": "732610",
    "label": "Tagari",
    "value": "Tagari"
  },
  {
    "id": "7326101005",
    "district_id": "732610",
    "label": "Balusu",
    "value": "Balusu"
  },
  {
    "id": "7326102001",
    "district_id": "732610",
    "label": "Palangi'",
    "value": "Palangi'"
  },
  {
    "id": "7326102003",
    "district_id": "732610",
    "label": "Balusu Ao Gading",
    "value": "Balusu Ao Gading"
  },
  {
    "id": "7326102004",
    "district_id": "732610",
    "label": "Karua",
    "value": "Karua"
  },
  {
    "id": "7326102006",
    "district_id": "732610",
    "label": "Awa' Kawasik",
    "value": "Awa' Kawasik"
  },
  {
    "id": "7326102007",
    "district_id": "732610",
    "label": "Balusu Bangunlipu",
    "value": "Balusu Bangunlipu"
  },
  {
    "id": "7326111001",
    "district_id": "732611",
    "label": "Tallunglipu Matalo",
    "value": "Tallunglipu Matalo"
  },
  {
    "id": "7326111002",
    "district_id": "732611",
    "label": "Tallunglipu",
    "value": "Tallunglipu"
  },
  {
    "id": "7326111003",
    "district_id": "732611",
    "label": "Tampo Tallunglipu",
    "value": "Tampo Tallunglipu"
  },
  {
    "id": "7326111004",
    "district_id": "732611",
    "label": "Tagari Tallunglipu",
    "value": "Tagari Tallunglipu"
  },
  {
    "id": "7326111005",
    "district_id": "732611",
    "label": "Tantanan Tallunglipu",
    "value": "Tantanan Tallunglipu"
  },
  {
    "id": "7326111007",
    "district_id": "732611",
    "label": "Rante PakuTallunglipu",
    "value": "Rante PakuTallunglipu"
  },
  {
    "id": "7326112006",
    "district_id": "732611",
    "label": "Buntu Tallunglipu",
    "value": "Buntu Tallunglipu"
  },
  {
    "id": "7326121004",
    "district_id": "732612",
    "label": "Pasang",
    "value": "Pasang"
  },
  {
    "id": "7326122001",
    "district_id": "732612",
    "label": "Madong",
    "value": "Madong"
  },
  {
    "id": "7326122002",
    "district_id": "732612",
    "label": "Dende'",
    "value": "Dende'"
  },
  {
    "id": "7326122003",
    "district_id": "732612",
    "label": "Piongan",
    "value": "Piongan"
  },
  {
    "id": "7326122005",
    "district_id": "732612",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "7326122006",
    "district_id": "732612",
    "label": "Kapolang",
    "value": "Kapolang"
  },
  {
    "id": "7326122007",
    "district_id": "732612",
    "label": "Buntu Tagari",
    "value": "Buntu Tagari"
  },
  {
    "id": "7326122008",
    "district_id": "732612",
    "label": "Parinding",
    "value": "Parinding"
  },
  {
    "id": "7326131002",
    "district_id": "732613",
    "label": "Sapan",
    "value": "Sapan"
  },
  {
    "id": "7326132001",
    "district_id": "732613",
    "label": "Buntu Minanga",
    "value": "Buntu Minanga"
  },
  {
    "id": "7326132003",
    "district_id": "732613",
    "label": "Sarambu",
    "value": "Sarambu"
  },
  {
    "id": "7326132004",
    "district_id": "732613",
    "label": "Rante Uma",
    "value": "Rante Uma"
  },
  {
    "id": "7326132005",
    "district_id": "732613",
    "label": "Talimbangan",
    "value": "Talimbangan"
  },
  {
    "id": "7326132006",
    "district_id": "732613",
    "label": "Pulu' Pulu'",
    "value": "Pulu' Pulu'"
  },
  {
    "id": "7326132007",
    "district_id": "732613",
    "label": "Paonganan",
    "value": "Paonganan"
  },
  {
    "id": "7326132008",
    "district_id": "732613",
    "label": "Parandangan",
    "value": "Parandangan"
  },
  {
    "id": "7326132009",
    "district_id": "732613",
    "label": "Roroan Barra'-Barra'",
    "value": "Roroan Barra'-Barra'"
  },
  {
    "id": "7326132010",
    "district_id": "732613",
    "label": "Pangkung Batu",
    "value": "Pangkung Batu"
  },
  {
    "id": "7326132011",
    "district_id": "732613",
    "label": "Pengkaroan Manuk",
    "value": "Pengkaroan Manuk"
  },
  {
    "id": "7326132012",
    "district_id": "732613",
    "label": "Batu Busa",
    "value": "Batu Busa"
  },
  {
    "id": "7326132013",
    "district_id": "732613",
    "label": "Ponglu",
    "value": "Ponglu"
  },
  {
    "id": "7326141002",
    "district_id": "732614",
    "label": "Baruppu Selatan",
    "value": "Baruppu Selatan"
  },
  {
    "id": "7326142001",
    "district_id": "732614",
    "label": "Baruppu' Utara",
    "value": "Baruppu' Utara"
  },
  {
    "id": "7326142003",
    "district_id": "732614",
    "label": "Baruppu Benteng Batu",
    "value": "Baruppu Benteng Batu"
  },
  {
    "id": "7326142004",
    "district_id": "732614",
    "label": "Baruppu' Parodo",
    "value": "Baruppu' Parodo"
  },
  {
    "id": "7326151003",
    "district_id": "732615",
    "label": "Ba'tan",
    "value": "Ba'tan"
  },
  {
    "id": "7326151004",
    "district_id": "732615",
    "label": "Pantanakan Lolo",
    "value": "Pantanakan Lolo"
  },
  {
    "id": "7326152001",
    "district_id": "732615",
    "label": "Tallu Lolo",
    "value": "Tallu Lolo"
  },
  {
    "id": "7326152002",
    "district_id": "732615",
    "label": "Rinding Batu",
    "value": "Rinding Batu"
  },
  {
    "id": "7326152005",
    "district_id": "732615",
    "label": "Angin-angin",
    "value": "Angin-angin"
  },
  {
    "id": "7326152006",
    "district_id": "732615",
    "label": "Sangbua'",
    "value": "Sangbua'"
  },
  {
    "id": "7326152007",
    "district_id": "732615",
    "label": "Tadongkon",
    "value": "Tadongkon"
  },
  {
    "id": "7326162001",
    "district_id": "732616",
    "label": "Tondon Langi'",
    "value": "Tondon Langi'"
  },
  {
    "id": "7326162002",
    "district_id": "732616",
    "label": "Tondon",
    "value": "Tondon"
  },
  {
    "id": "7326162003",
    "district_id": "732616",
    "label": "Tondon Sibata",
    "value": "Tondon Sibata"
  },
  {
    "id": "7326162004",
    "district_id": "732616",
    "label": "Tondon Matallo",
    "value": "Tondon Matallo"
  },
  {
    "id": "7326172001",
    "district_id": "732617",
    "label": "To'yasa Akung",
    "value": "To'yasa Akung"
  },
  {
    "id": "7326172002",
    "district_id": "732617",
    "label": "Batu Limbong",
    "value": "Batu Limbong"
  },
  {
    "id": "7326172003",
    "district_id": "732617",
    "label": "Bangkelekila'",
    "value": "Bangkelekila'"
  },
  {
    "id": "7326172004",
    "district_id": "732617",
    "label": "Tampan Bonga",
    "value": "Tampan Bonga"
  },
  {
    "id": "7326181002",
    "district_id": "732618",
    "label": "Bokin",
    "value": "Bokin"
  },
  {
    "id": "7326181005",
    "district_id": "732618",
    "label": "Buangin",
    "value": "Buangin"
  },
  {
    "id": "7326182001",
    "district_id": "732618",
    "label": "Rantebua",
    "value": "Rantebua"
  },
  {
    "id": "7326182003",
    "district_id": "732618",
    "label": "Pitung Penanian",
    "value": "Pitung Penanian"
  },
  {
    "id": "7326182004",
    "district_id": "732618",
    "label": "Ma'kuan Pare",
    "value": "Ma'kuan Pare"
  },
  {
    "id": "7326182006",
    "district_id": "732618",
    "label": "Rantebua Sumalu",
    "value": "Rantebua Sumalu"
  },
  {
    "id": "7326182007",
    "district_id": "732618",
    "label": "Rantebua Sanggalangi'",
    "value": "Rantebua Sanggalangi'"
  },
  {
    "id": "7326192001",
    "district_id": "732619",
    "label": "Suloara'",
    "value": "Suloara'"
  },
  {
    "id": "7326192002",
    "district_id": "732619",
    "label": "Sesean Matallo",
    "value": "Sesean Matallo"
  },
  {
    "id": "7326192003",
    "district_id": "732619",
    "label": "Landorundun",
    "value": "Landorundun"
  },
  {
    "id": "7326192004",
    "district_id": "732619",
    "label": "Tonga Riu",
    "value": "Tonga Riu"
  },
  {
    "id": "7326192005",
    "district_id": "732619",
    "label": "Lempo",
    "value": "Lempo"
  },
  {
    "id": "7326202001",
    "district_id": "732620",
    "label": "Benteng Ka'do",
    "value": "Benteng Ka'do"
  },
  {
    "id": "7326202002",
    "district_id": "732620",
    "label": "Sikuku'",
    "value": "Sikuku'"
  },
  {
    "id": "7326202003",
    "district_id": "732620",
    "label": "Polo Padang",
    "value": "Polo Padang"
  },
  {
    "id": "7326202004",
    "district_id": "732620",
    "label": "Kantun Poya",
    "value": "Kantun Poya"
  },
  {
    "id": "7326202005",
    "district_id": "732620",
    "label": "Kapala Pitu",
    "value": "Kapala Pitu"
  },
  {
    "id": "7326202006",
    "district_id": "732620",
    "label": "Bontong Mamullu",
    "value": "Bontong Mamullu"
  },
  {
    "id": "7326212001",
    "district_id": "732621",
    "label": "Awan",
    "value": "Awan"
  },
  {
    "id": "7326212002",
    "district_id": "732621",
    "label": "Batu Lotong",
    "value": "Batu Lotong"
  },
  {
    "id": "7326212003",
    "district_id": "732621",
    "label": "Buntu Karua",
    "value": "Buntu Karua"
  },
  {
    "id": "7326212004",
    "district_id": "732621",
    "label": "Londong Biang",
    "value": "Londong Biang"
  },
  {
    "id": "7371011001",
    "district_id": "737101",
    "label": "Bontorannu",
    "value": "Bontorannu"
  },
  {
    "id": "7371011002",
    "district_id": "737101",
    "label": "Mattoangin",
    "value": "Mattoangin"
  },
  {
    "id": "7371011003",
    "district_id": "737101",
    "label": "Kunjung Mae",
    "value": "Kunjung Mae"
  },
  {
    "id": "7371011004",
    "district_id": "737101",
    "label": "Lette",
    "value": "Lette"
  },
  {
    "id": "7371011005",
    "district_id": "737101",
    "label": "Panambungan",
    "value": "Panambungan"
  },
  {
    "id": "7371011006",
    "district_id": "737101",
    "label": "Mariso",
    "value": "Mariso"
  },
  {
    "id": "7371011007",
    "district_id": "737101",
    "label": "Mario",
    "value": "Mario"
  },
  {
    "id": "7371011008",
    "district_id": "737101",
    "label": "Kampung Buyang",
    "value": "Kampung Buyang"
  },
  {
    "id": "7371011009",
    "district_id": "737101",
    "label": "Tamarunang",
    "value": "Tamarunang"
  },
  {
    "id": "7371021001",
    "district_id": "737102",
    "label": "Sambung Jawa",
    "value": "Sambung Jawa"
  },
  {
    "id": "7371021002",
    "district_id": "737102",
    "label": "Parang",
    "value": "Parang"
  },
  {
    "id": "7371021003",
    "district_id": "737102",
    "label": "Mamajang Luar",
    "value": "Mamajang Luar"
  },
  {
    "id": "7371021004",
    "district_id": "737102",
    "label": "Mamajang Dalam",
    "value": "Mamajang Dalam"
  },
  {
    "id": "7371021005",
    "district_id": "737102",
    "label": "Maricaya Selatan",
    "value": "Maricaya Selatan"
  },
  {
    "id": "7371021006",
    "district_id": "737102",
    "label": "Bonto Lebang",
    "value": "Bonto Lebang"
  },
  {
    "id": "7371021007",
    "district_id": "737102",
    "label": "Pa'batang",
    "value": "Pa'batang"
  },
  {
    "id": "7371021008",
    "district_id": "737102",
    "label": "Bonto Biraeng",
    "value": "Bonto Biraeng"
  },
  {
    "id": "7371021009",
    "district_id": "737102",
    "label": "Labuang Baji",
    "value": "Labuang Baji"
  },
  {
    "id": "7371021010",
    "district_id": "737102",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "7371021011",
    "district_id": "737102",
    "label": "Tamparang Keke",
    "value": "Tamparang Keke"
  },
  {
    "id": "7371021012",
    "district_id": "737102",
    "label": "Baji Mappakasunggu",
    "value": "Baji Mappakasunggu"
  },
  {
    "id": "7371021013",
    "district_id": "737102",
    "label": "Karang Anyar",
    "value": "Karang Anyar"
  },
  {
    "id": "7371031001",
    "district_id": "737103",
    "label": "Maricaya",
    "value": "Maricaya"
  },
  {
    "id": "7371031002",
    "district_id": "737103",
    "label": "Bara Baraya",
    "value": "Bara Baraya"
  },
  {
    "id": "7371031003",
    "district_id": "737103",
    "label": "Maradekaya",
    "value": "Maradekaya"
  },
  {
    "id": "7371031004",
    "district_id": "737103",
    "label": "Lariangbangi",
    "value": "Lariangbangi"
  },
  {
    "id": "7371031005",
    "district_id": "737103",
    "label": "Maccini",
    "value": "Maccini"
  },
  {
    "id": "7371031006",
    "district_id": "737103",
    "label": "Maricaya Baru",
    "value": "Maricaya Baru"
  },
  {
    "id": "7371031007",
    "district_id": "737103",
    "label": "Bara Baraya Timur",
    "value": "Bara Baraya Timur"
  },
  {
    "id": "7371031008",
    "district_id": "737103",
    "label": "Bara Baraya Selatan",
    "value": "Bara Baraya Selatan"
  },
  {
    "id": "7371031009",
    "district_id": "737103",
    "label": "Maradekaya Selatan",
    "value": "Maradekaya Selatan"
  },
  {
    "id": "7371031010",
    "district_id": "737103",
    "label": "Barana",
    "value": "Barana"
  },
  {
    "id": "7371031011",
    "district_id": "737103",
    "label": "Maccini Parang",
    "value": "Maccini Parang"
  },
  {
    "id": "7371031012",
    "district_id": "737103",
    "label": "Maccini Gusung",
    "value": "Maccini Gusung"
  },
  {
    "id": "7371031013",
    "district_id": "737103",
    "label": "Maradekaya Utara",
    "value": "Maradekaya Utara"
  },
  {
    "id": "7371031014",
    "district_id": "737103",
    "label": "Bara Baraya Utara",
    "value": "Bara Baraya Utara"
  },
  {
    "id": "7371041001",
    "district_id": "737104",
    "label": "Maloku",
    "value": "Maloku"
  },
  {
    "id": "7371041002",
    "district_id": "737104",
    "label": "Mangkura",
    "value": "Mangkura"
  },
  {
    "id": "7371041003",
    "district_id": "737104",
    "label": "Pisang Selatan",
    "value": "Pisang Selatan"
  },
  {
    "id": "7371041004",
    "district_id": "737104",
    "label": "Pisang Utara",
    "value": "Pisang Utara"
  },
  {
    "id": "7371041005",
    "district_id": "737104",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7371041006",
    "district_id": "737104",
    "label": "Bulogading",
    "value": "Bulogading"
  },
  {
    "id": "7371041007",
    "district_id": "737104",
    "label": "Lae-Lae",
    "value": "Lae-Lae"
  },
  {
    "id": "7371041008",
    "district_id": "737104",
    "label": "Losari",
    "value": "Losari"
  },
  {
    "id": "7371041009",
    "district_id": "737104",
    "label": "Sawerigading",
    "value": "Sawerigading"
  },
  {
    "id": "7371041010",
    "district_id": "737104",
    "label": "Lajangiru",
    "value": "Lajangiru"
  },
  {
    "id": "7371051001",
    "district_id": "737105",
    "label": "Pattunuang",
    "value": "Pattunuang"
  },
  {
    "id": "7371051002",
    "district_id": "737105",
    "label": "Melayu",
    "value": "Melayu"
  },
  {
    "id": "7371051003",
    "district_id": "737105",
    "label": "Butung",
    "value": "Butung"
  },
  {
    "id": "7371051004",
    "district_id": "737105",
    "label": "Malimongan Tua",
    "value": "Malimongan Tua"
  },
  {
    "id": "7371051005",
    "district_id": "737105",
    "label": "Ende",
    "value": "Ende"
  },
  {
    "id": "7371051006",
    "district_id": "737105",
    "label": "Mampu",
    "value": "Mampu"
  },
  {
    "id": "7371051007",
    "district_id": "737105",
    "label": "Malimongan",
    "value": "Malimongan"
  },
  {
    "id": "7371051008",
    "district_id": "737105",
    "label": "Melayu Baru",
    "value": "Melayu Baru"
  },
  {
    "id": "7371061001",
    "district_id": "737106",
    "label": "Malimongan Baru",
    "value": "Malimongan Baru"
  },
  {
    "id": "7371061002",
    "district_id": "737106",
    "label": "Wajo Baru",
    "value": "Wajo Baru"
  },
  {
    "id": "7371061003",
    "district_id": "737106",
    "label": "Gaddong",
    "value": "Gaddong"
  },
  {
    "id": "7371061004",
    "district_id": "737106",
    "label": "Bontoala",
    "value": "Bontoala"
  },
  {
    "id": "7371061005",
    "district_id": "737106",
    "label": "Baraya",
    "value": "Baraya"
  },
  {
    "id": "7371061006",
    "district_id": "737106",
    "label": "Layang",
    "value": "Layang"
  },
  {
    "id": "7371061007",
    "district_id": "737106",
    "label": "Parang Layang",
    "value": "Parang Layang"
  },
  {
    "id": "7371061008",
    "district_id": "737106",
    "label": "Bontoala Tua",
    "value": "Bontoala Tua"
  },
  {
    "id": "7371061009",
    "district_id": "737106",
    "label": "Bontoala Parang",
    "value": "Bontoala Parang"
  },
  {
    "id": "7371061010",
    "district_id": "737106",
    "label": "Tompo Balang",
    "value": "Tompo Balang"
  },
  {
    "id": "7371061011",
    "district_id": "737106",
    "label": "Bunga Ejaya",
    "value": "Bunga Ejaya"
  },
  {
    "id": "7371061012",
    "district_id": "737106",
    "label": "Timungan Lompoa",
    "value": "Timungan Lompoa"
  },
  {
    "id": "7371071001",
    "district_id": "737107",
    "label": "Pannampu",
    "value": "Pannampu"
  },
  {
    "id": "7371071002",
    "district_id": "737107",
    "label": "Kalukuang",
    "value": "Kalukuang"
  },
  {
    "id": "7371071003",
    "district_id": "737107",
    "label": "Kaluku Bodoa",
    "value": "Kaluku Bodoa"
  },
  {
    "id": "7371071004",
    "district_id": "737107",
    "label": "Rappojawa",
    "value": "Rappojawa"
  },
  {
    "id": "7371071005",
    "district_id": "737107",
    "label": "Rappokalling",
    "value": "Rappokalling"
  },
  {
    "id": "7371071006",
    "district_id": "737107",
    "label": "Lakkang",
    "value": "Lakkang"
  },
  {
    "id": "7371071007",
    "district_id": "737107",
    "label": "Tallo",
    "value": "Tallo"
  },
  {
    "id": "7371071008",
    "district_id": "737107",
    "label": "La'latang",
    "value": "La'latang"
  },
  {
    "id": "7371071009",
    "district_id": "737107",
    "label": "Wala-Walaya",
    "value": "Wala-Walaya"
  },
  {
    "id": "7371071010",
    "district_id": "737107",
    "label": "Tammua",
    "value": "Tammua"
  },
  {
    "id": "7371071011",
    "district_id": "737107",
    "label": "Buloa",
    "value": "Buloa"
  },
  {
    "id": "7371071012",
    "district_id": "737107",
    "label": "Suangga",
    "value": "Suangga"
  },
  {
    "id": "7371071013",
    "district_id": "737107",
    "label": "Ujung Pandang Baru",
    "value": "Ujung Pandang Baru"
  },
  {
    "id": "7371071014",
    "district_id": "737107",
    "label": "Lembo",
    "value": "Lembo"
  },
  {
    "id": "7371071015",
    "district_id": "737107",
    "label": "Bunga Eja Beru",
    "value": "Bunga Eja Beru"
  },
  {
    "id": "7371081002",
    "district_id": "737108",
    "label": "Tabaringan",
    "value": "Tabaringan"
  },
  {
    "id": "7371081003",
    "district_id": "737108",
    "label": "Patingalloang",
    "value": "Patingalloang"
  },
  {
    "id": "7371081004",
    "district_id": "737108",
    "label": "Gusung",
    "value": "Gusung"
  },
  {
    "id": "7371081005",
    "district_id": "737108",
    "label": "Cambaya",
    "value": "Cambaya"
  },
  {
    "id": "7371081006",
    "district_id": "737108",
    "label": "Ujung Tanah",
    "value": "Ujung Tanah"
  },
  {
    "id": "7371081009",
    "district_id": "737108",
    "label": "Totaka",
    "value": "Totaka"
  },
  {
    "id": "7371081010",
    "district_id": "737108",
    "label": "Tamalabba",
    "value": "Tamalabba"
  },
  {
    "id": "7371081011",
    "district_id": "737108",
    "label": "Camba Berua",
    "value": "Camba Berua"
  },
  {
    "id": "7371081012",
    "district_id": "737108",
    "label": "Patingalloang Baru",
    "value": "Patingalloang Baru"
  },
  {
    "id": "7371091001",
    "district_id": "737109",
    "label": "Karuwisi",
    "value": "Karuwisi"
  },
  {
    "id": "7371091002",
    "district_id": "737109",
    "label": "Panaikang",
    "value": "Panaikang"
  },
  {
    "id": "7371091003",
    "district_id": "737109",
    "label": "Tello Baru",
    "value": "Tello Baru"
  },
  {
    "id": "7371091004",
    "district_id": "737109",
    "label": "Pampang",
    "value": "Pampang"
  },
  {
    "id": "7371091005",
    "district_id": "737109",
    "label": "Karampuang",
    "value": "Karampuang"
  },
  {
    "id": "7371091006",
    "district_id": "737109",
    "label": "Tamamaung",
    "value": "Tamamaung"
  },
  {
    "id": "7371091007",
    "district_id": "737109",
    "label": "Masale",
    "value": "Masale"
  },
  {
    "id": "7371091008",
    "district_id": "737109",
    "label": "Pandang",
    "value": "Pandang"
  },
  {
    "id": "7371091009",
    "district_id": "737109",
    "label": "Karuwisi Utara",
    "value": "Karuwisi Utara"
  },
  {
    "id": "7371091010",
    "district_id": "737109",
    "label": "Sinrijala",
    "value": "Sinrijala"
  },
  {
    "id": "7371091011",
    "district_id": "737109",
    "label": "Paropo",
    "value": "Paropo"
  },
  {
    "id": "7371101001",
    "district_id": "737110",
    "label": "Barombong",
    "value": "Barombong"
  },
  {
    "id": "7371101002",
    "district_id": "737110",
    "label": "Maccini Sombala",
    "value": "Maccini Sombala"
  },
  {
    "id": "7371101003",
    "district_id": "737110",
    "label": "Jongaya",
    "value": "Jongaya"
  },
  {
    "id": "7371101004",
    "district_id": "737110",
    "label": "Mangasa",
    "value": "Mangasa"
  },
  {
    "id": "7371101005",
    "district_id": "737110",
    "label": "Bongaya",
    "value": "Bongaya"
  },
  {
    "id": "7371101006",
    "district_id": "737110",
    "label": "Balang Baru",
    "value": "Balang Baru"
  },
  {
    "id": "7371101007",
    "district_id": "737110",
    "label": "Mannuruki",
    "value": "Mannuruki"
  },
  {
    "id": "7371101008",
    "district_id": "737110",
    "label": "Pa'baeng-Baeng",
    "value": "Pa'baeng-Baeng"
  },
  {
    "id": "7371101009",
    "district_id": "737110",
    "label": "Parang Tambung",
    "value": "Parang Tambung"
  },
  {
    "id": "7371101010",
    "district_id": "737110",
    "label": "Tanjung Merdeka",
    "value": "Tanjung Merdeka"
  },
  {
    "id": "7371101011",
    "district_id": "737110",
    "label": "Bonto Duri",
    "value": "Bonto Duri"
  },
  {
    "id": "7371111001",
    "district_id": "737111",
    "label": "Paccerakkang",
    "value": "Paccerakkang"
  },
  {
    "id": "7371111002",
    "district_id": "737111",
    "label": "Daya",
    "value": "Daya"
  },
  {
    "id": "7371111003",
    "district_id": "737111",
    "label": "Pai",
    "value": "Pai"
  },
  {
    "id": "7371111004",
    "district_id": "737111",
    "label": "Bulurokeng",
    "value": "Bulurokeng"
  },
  {
    "id": "7371111005",
    "district_id": "737111",
    "label": "Sudiang",
    "value": "Sudiang"
  },
  {
    "id": "7371111006",
    "district_id": "737111",
    "label": "Sudiang Raya",
    "value": "Sudiang Raya"
  },
  {
    "id": "7371111007",
    "district_id": "737111",
    "label": "Untia",
    "value": "Untia"
  },
  {
    "id": "7371111008",
    "district_id": "737111",
    "label": "Laikang",
    "value": "Laikang"
  },
  {
    "id": "7371111009",
    "district_id": "737111",
    "label": "Berua",
    "value": "Berua"
  },
  {
    "id": "7371111010",
    "district_id": "737111",
    "label": "Katimbang",
    "value": "Katimbang"
  },
  {
    "id": "7371111011",
    "district_id": "737111",
    "label": "Bakung",
    "value": "Bakung"
  },
  {
    "id": "7371121001",
    "district_id": "737112",
    "label": "Manggala",
    "value": "Manggala"
  },
  {
    "id": "7371121002",
    "district_id": "737112",
    "label": "Bangkala",
    "value": "Bangkala"
  },
  {
    "id": "7371121003",
    "district_id": "737112",
    "label": "Tamangapa",
    "value": "Tamangapa"
  },
  {
    "id": "7371121004",
    "district_id": "737112",
    "label": "Antang",
    "value": "Antang"
  },
  {
    "id": "7371121005",
    "district_id": "737112",
    "label": "Batua",
    "value": "Batua"
  },
  {
    "id": "7371121006",
    "district_id": "737112",
    "label": "Borong",
    "value": "Borong"
  },
  {
    "id": "7371121007",
    "district_id": "737112",
    "label": "Biring Romang",
    "value": "Biring Romang"
  },
  {
    "id": "7371121008",
    "district_id": "737112",
    "label": "Bitowo",
    "value": "Bitowo"
  },
  {
    "id": "7371131001",
    "district_id": "737113",
    "label": "Tidung",
    "value": "Tidung"
  },
  {
    "id": "7371131002",
    "district_id": "737113",
    "label": "Kassi - Kassi",
    "value": "Kassi - Kassi"
  },
  {
    "id": "7371131003",
    "district_id": "737113",
    "label": "Mappala",
    "value": "Mappala"
  },
  {
    "id": "7371131004",
    "district_id": "737113",
    "label": "Buakana",
    "value": "Buakana"
  },
  {
    "id": "7371131005",
    "district_id": "737113",
    "label": "Bonto Makkio",
    "value": "Bonto Makkio"
  },
  {
    "id": "7371131006",
    "district_id": "737113",
    "label": "Rappocini",
    "value": "Rappocini"
  },
  {
    "id": "7371131007",
    "district_id": "737113",
    "label": "Balla Parang",
    "value": "Balla Parang"
  },
  {
    "id": "7371131008",
    "district_id": "737113",
    "label": "Banta-Bantaeng",
    "value": "Banta-Bantaeng"
  },
  {
    "id": "7371131009",
    "district_id": "737113",
    "label": "Karunrung",
    "value": "Karunrung"
  },
  {
    "id": "7371131010",
    "district_id": "737113",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "7371131011",
    "district_id": "737113",
    "label": "Minasa Upa",
    "value": "Minasa Upa"
  },
  {
    "id": "7371141001",
    "district_id": "737114",
    "label": "Tamalanrea",
    "value": "Tamalanrea"
  },
  {
    "id": "7371141002",
    "district_id": "737114",
    "label": "Kapasa",
    "value": "Kapasa"
  },
  {
    "id": "7371141003",
    "district_id": "737114",
    "label": "Tamalanrea Indah",
    "value": "Tamalanrea Indah"
  },
  {
    "id": "7371141004",
    "district_id": "737114",
    "label": "Parang Loe",
    "value": "Parang Loe"
  },
  {
    "id": "7371141005",
    "district_id": "737114",
    "label": "Bira",
    "value": "Bira"
  },
  {
    "id": "7371141006",
    "district_id": "737114",
    "label": "Tamalanrea Jaya",
    "value": "Tamalanrea Jaya"
  },
  {
    "id": "7371141007",
    "district_id": "737114",
    "label": "Buntusu",
    "value": "Buntusu"
  },
  {
    "id": "7371141008",
    "district_id": "737114",
    "label": "Kapasa Raya",
    "value": "Kapasa Raya"
  },
  {
    "id": "7371151001",
    "district_id": "737115",
    "label": "Barrang Caddi",
    "value": "Barrang Caddi"
  },
  {
    "id": "7371151002",
    "district_id": "737115",
    "label": "Barrang Lompo",
    "value": "Barrang Lompo"
  },
  {
    "id": "7371151003",
    "district_id": "737115",
    "label": "Kodingareng",
    "value": "Kodingareng"
  },
  {
    "id": "7372011004",
    "district_id": "737201",
    "label": "Lompoe",
    "value": "Lompoe"
  },
  {
    "id": "7372011005",
    "district_id": "737201",
    "label": "WT. Bacukiki",
    "value": "WT. Bacukiki"
  },
  {
    "id": "7372011009",
    "district_id": "737201",
    "label": "Lemoe",
    "value": "Lemoe"
  },
  {
    "id": "7372011010",
    "district_id": "737201",
    "label": "Galung Maloang",
    "value": "Galung Maloang"
  },
  {
    "id": "7372021001",
    "district_id": "737202",
    "label": "Labukkang",
    "value": "Labukkang"
  },
  {
    "id": "7372021002",
    "district_id": "737202",
    "label": "Ujung Sabbang",
    "value": "Ujung Sabbang"
  },
  {
    "id": "7372021003",
    "district_id": "737202",
    "label": "Ujung Bulu",
    "value": "Ujung Bulu"
  },
  {
    "id": "7372021004",
    "district_id": "737202",
    "label": "Lapadde",
    "value": "Lapadde"
  },
  {
    "id": "7372021005",
    "district_id": "737202",
    "label": "Mallusetasi",
    "value": "Mallusetasi"
  },
  {
    "id": "7372031001",
    "district_id": "737203",
    "label": "Lakessi",
    "value": "Lakessi"
  },
  {
    "id": "7372031002",
    "district_id": "737203",
    "label": "Ujung Baru",
    "value": "Ujung Baru"
  },
  {
    "id": "7372031003",
    "district_id": "737203",
    "label": "Watang Soreang",
    "value": "Watang Soreang"
  },
  {
    "id": "7372031004",
    "district_id": "737203",
    "label": "Kampung Pisang",
    "value": "Kampung Pisang"
  },
  {
    "id": "7372031005",
    "district_id": "737203",
    "label": "Ujung Lare",
    "value": "Ujung Lare"
  },
  {
    "id": "7372031006",
    "district_id": "737203",
    "label": "Bukit Indah",
    "value": "Bukit Indah"
  },
  {
    "id": "7372031007",
    "district_id": "737203",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "7372041001",
    "district_id": "737204",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "7372041002",
    "district_id": "737204",
    "label": "Cappa Galung",
    "value": "Cappa Galung"
  },
  {
    "id": "7372041003",
    "district_id": "737204",
    "label": "Lumpue",
    "value": "Lumpue"
  },
  {
    "id": "7372041004",
    "district_id": "737204",
    "label": "Tiro Sompe",
    "value": "Tiro Sompe"
  },
  {
    "id": "7372041005",
    "district_id": "737204",
    "label": "Sumpang Minangae",
    "value": "Sumpang Minangae"
  },
  {
    "id": "7372041006",
    "district_id": "737204",
    "label": "Bumi Harapan",
    "value": "Bumi Harapan"
  },
  {
    "id": "7373011001",
    "district_id": "737301",
    "label": "Tompotikka",
    "value": "Tompotikka"
  },
  {
    "id": "7373011002",
    "district_id": "737301",
    "label": "Boting",
    "value": "Boting"
  },
  {
    "id": "7373011003",
    "district_id": "737301",
    "label": "Amasangan",
    "value": "Amasangan"
  },
  {
    "id": "7373011005",
    "district_id": "737301",
    "label": "Lagaligo",
    "value": "Lagaligo"
  },
  {
    "id": "7373011011",
    "district_id": "737301",
    "label": "Dangerakko",
    "value": "Dangerakko"
  },
  {
    "id": "7373011012",
    "district_id": "737301",
    "label": "Pajalesang",
    "value": "Pajalesang"
  },
  {
    "id": "7373021002",
    "district_id": "737302",
    "label": "Batupasi",
    "value": "Batupasi"
  },
  {
    "id": "7373021003",
    "district_id": "737302",
    "label": "Sabbang Paru",
    "value": "Sabbang Paru"
  },
  {
    "id": "7373021005",
    "district_id": "737302",
    "label": "Salobulo",
    "value": "Salobulo"
  },
  {
    "id": "7373021007",
    "district_id": "737302",
    "label": "Penggoli",
    "value": "Penggoli"
  },
  {
    "id": "7373021008",
    "district_id": "737302",
    "label": "Luminda",
    "value": "Luminda"
  },
  {
    "id": "7373021009",
    "district_id": "737302",
    "label": "Pattene",
    "value": "Pattene"
  },
  {
    "id": "7373031001",
    "district_id": "737303",
    "label": "Takkalala",
    "value": "Takkalala"
  },
  {
    "id": "7373031002",
    "district_id": "737303",
    "label": "Songka",
    "value": "Songka"
  },
  {
    "id": "7373031006",
    "district_id": "737303",
    "label": "Sampoddo",
    "value": "Sampoddo"
  },
  {
    "id": "7373031007",
    "district_id": "737303",
    "label": "Binturu",
    "value": "Binturu"
  },
  {
    "id": "7373041002",
    "district_id": "737304",
    "label": "Maroangin",
    "value": "Maroangin"
  },
  {
    "id": "7373041003",
    "district_id": "737304",
    "label": "Mancani",
    "value": "Mancani"
  },
  {
    "id": "7373041004",
    "district_id": "737304",
    "label": "Sumarambu",
    "value": "Sumarambu"
  },
  {
    "id": "7373041005",
    "district_id": "737304",
    "label": "Salubattang",
    "value": "Salubattang"
  },
  {
    "id": "7373041006",
    "district_id": "737304",
    "label": "Jaya",
    "value": "Jaya"
  },
  {
    "id": "7373041007",
    "district_id": "737304",
    "label": "Batu Walenrang",
    "value": "Batu Walenrang"
  },
  {
    "id": "7373041008",
    "district_id": "737304",
    "label": "Petonjangan",
    "value": "Petonjangan"
  },
  {
    "id": "7373051001",
    "district_id": "737305",
    "label": "Surutanga",
    "value": "Surutanga"
  },
  {
    "id": "7373051002",
    "district_id": "737305",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7373051003",
    "district_id": "737305",
    "label": "Pontap",
    "value": "Pontap"
  },
  {
    "id": "7373051004",
    "district_id": "737305",
    "label": "Malatunrung",
    "value": "Malatunrung"
  },
  {
    "id": "7373051005",
    "district_id": "737305",
    "label": "Salekoe",
    "value": "Salekoe"
  },
  {
    "id": "7373051006",
    "district_id": "737305",
    "label": "Salotellue",
    "value": "Salotellue"
  },
  {
    "id": "7373051007",
    "district_id": "737305",
    "label": "Ponjalae",
    "value": "Ponjalae"
  },
  {
    "id": "7373061001",
    "district_id": "737306",
    "label": "Tomarundung",
    "value": "Tomarundung"
  },
  {
    "id": "7373061002",
    "district_id": "737306",
    "label": "Battang",
    "value": "Battang"
  },
  {
    "id": "7373061003",
    "district_id": "737306",
    "label": "Lebang",
    "value": "Lebang"
  },
  {
    "id": "7373061004",
    "district_id": "737306",
    "label": "Battang Barat",
    "value": "Battang Barat"
  },
  {
    "id": "7373061005",
    "district_id": "737306",
    "label": "Padang Lambe",
    "value": "Padang Lambe"
  },
  {
    "id": "7373071001",
    "district_id": "737307",
    "label": "Mawa",
    "value": "Mawa"
  },
  {
    "id": "7373071002",
    "district_id": "737307",
    "label": "Purangi",
    "value": "Purangi"
  },
  {
    "id": "7373071003",
    "district_id": "737307",
    "label": "Peta",
    "value": "Peta"
  },
  {
    "id": "7373071004",
    "district_id": "737307",
    "label": "Sendana",
    "value": "Sendana"
  },
  {
    "id": "7373081001",
    "district_id": "737308",
    "label": "Mungkajang",
    "value": "Mungkajang"
  },
  {
    "id": "7373081002",
    "district_id": "737308",
    "label": "Murante",
    "value": "Murante"
  },
  {
    "id": "7373081003",
    "district_id": "737308",
    "label": "Latuppa",
    "value": "Latuppa"
  },
  {
    "id": "7373081004",
    "district_id": "737308",
    "label": "Kambo",
    "value": "Kambo"
  },
  {
    "id": "7373091001",
    "district_id": "737309",
    "label": "Temmalebba",
    "value": "Temmalebba"
  },
  {
    "id": "7373091002",
    "district_id": "737309",
    "label": "Balandai",
    "value": "Balandai"
  },
  {
    "id": "7373091003",
    "district_id": "737309",
    "label": "Rampoang",
    "value": "Rampoang"
  },
  {
    "id": "7373091004",
    "district_id": "737309",
    "label": "Tobulung",
    "value": "Tobulung"
  },
  {
    "id": "7373091005",
    "district_id": "737309",
    "label": "Buntu Datu",
    "value": "Buntu Datu"
  },
  {
    "id": "7401011001",
    "district_id": "740101",
    "label": "19 Nopember",
    "value": "19 Nopember"
  },
  {
    "id": "7401011002",
    "district_id": "740101",
    "label": "Wundulako",
    "value": "Wundulako"
  },
  {
    "id": "7401011003",
    "district_id": "740101",
    "label": "Lamekongga",
    "value": "Lamekongga"
  },
  {
    "id": "7401011004",
    "district_id": "740101",
    "label": "Kowioha",
    "value": "Kowioha"
  },
  {
    "id": "7401011005",
    "district_id": "740101",
    "label": "Silea",
    "value": "Silea"
  },
  {
    "id": "7401011011",
    "district_id": "740101",
    "label": "Ngapa",
    "value": "Ngapa"
  },
  {
    "id": "7401012006",
    "district_id": "740101",
    "label": "Towua",
    "value": "Towua"
  },
  {
    "id": "7401012007",
    "district_id": "740101",
    "label": "Bende",
    "value": "Bende"
  },
  {
    "id": "7401012008",
    "district_id": "740101",
    "label": "Unamendaa",
    "value": "Unamendaa"
  },
  {
    "id": "7401012009",
    "district_id": "740101",
    "label": "Tikonu",
    "value": "Tikonu"
  },
  {
    "id": "7401012010",
    "district_id": "740101",
    "label": "Sabiona",
    "value": "Sabiona"
  },
  {
    "id": "7401041001",
    "district_id": "740104",
    "label": "Lamokato",
    "value": "Lamokato"
  },
  {
    "id": "7401041002",
    "district_id": "740104",
    "label": "Watuliandu",
    "value": "Watuliandu"
  },
  {
    "id": "7401041003",
    "district_id": "740104",
    "label": "Balandete",
    "value": "Balandete"
  },
  {
    "id": "7401041004",
    "district_id": "740104",
    "label": "Lalombaa",
    "value": "Lalombaa"
  },
  {
    "id": "7401041005",
    "district_id": "740104",
    "label": "Sabilambo",
    "value": "Sabilambo"
  },
  {
    "id": "7401041006",
    "district_id": "740104",
    "label": "Laloeha",
    "value": "Laloeha"
  },
  {
    "id": "7401041007",
    "district_id": "740104",
    "label": "Tahoa",
    "value": "Tahoa"
  },
  {
    "id": "7401071001",
    "district_id": "740107",
    "label": "Dawi-Dawi",
    "value": "Dawi-Dawi"
  },
  {
    "id": "7401071002",
    "district_id": "740107",
    "label": "Pomalaa",
    "value": "Pomalaa"
  },
  {
    "id": "7401071009",
    "district_id": "740107",
    "label": "Tonggoni",
    "value": "Tonggoni"
  },
  {
    "id": "7401071011",
    "district_id": "740107",
    "label": "Kumoro",
    "value": "Kumoro"
  },
  {
    "id": "7401072003",
    "district_id": "740107",
    "label": "Huko-Huko",
    "value": "Huko-Huko"
  },
  {
    "id": "7401072004",
    "district_id": "740107",
    "label": "Pelambua",
    "value": "Pelambua"
  },
  {
    "id": "7401072005",
    "district_id": "740107",
    "label": "Tambea",
    "value": "Tambea"
  },
  {
    "id": "7401072006",
    "district_id": "740107",
    "label": "Sopura",
    "value": "Sopura"
  },
  {
    "id": "7401072007",
    "district_id": "740107",
    "label": "Pesouha",
    "value": "Pesouha"
  },
  {
    "id": "7401072008",
    "district_id": "740107",
    "label": "Totobo",
    "value": "Totobo"
  },
  {
    "id": "7401072010",
    "district_id": "740107",
    "label": "Oko-Oko",
    "value": "Oko-Oko"
  },
  {
    "id": "7401072012",
    "district_id": "740107",
    "label": "Hakatutobu",
    "value": "Hakatutobu"
  },
  {
    "id": "7401081001",
    "district_id": "740108",
    "label": "Watubangga",
    "value": "Watubangga"
  },
  {
    "id": "7401081006",
    "district_id": "740108",
    "label": "Tandebura",
    "value": "Tandebura"
  },
  {
    "id": "7401081015",
    "district_id": "740108",
    "label": "Wolulu",
    "value": "Wolulu"
  },
  {
    "id": "7401082002",
    "district_id": "740108",
    "label": "Lamundre",
    "value": "Lamundre"
  },
  {
    "id": "7401082007",
    "district_id": "740108",
    "label": "Kukutio",
    "value": "Kukutio"
  },
  {
    "id": "7401082011",
    "district_id": "740108",
    "label": "Langgosipi",
    "value": "Langgosipi"
  },
  {
    "id": "7401082012",
    "district_id": "740108",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "7401082016",
    "district_id": "740108",
    "label": "Peoho",
    "value": "Peoho"
  },
  {
    "id": "7401082017",
    "district_id": "740108",
    "label": "Mataosu",
    "value": "Mataosu"
  },
  {
    "id": "7401082018",
    "district_id": "740108",
    "label": "Polenga",
    "value": "Polenga"
  },
  {
    "id": "7401082020",
    "district_id": "740108",
    "label": "Kastura",
    "value": "Kastura"
  },
  {
    "id": "7401082023",
    "district_id": "740108",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "7401082025",
    "district_id": "740108",
    "label": "Mataosu Ujung",
    "value": "Mataosu Ujung"
  },
  {
    "id": "7401082026",
    "district_id": "740108",
    "label": "Ranoteta",
    "value": "Ranoteta"
  },
  {
    "id": "7401101001",
    "district_id": "740110",
    "label": "Wolo",
    "value": "Wolo"
  },
  {
    "id": "7401101007",
    "district_id": "740110",
    "label": "Ulu Wolo",
    "value": "Ulu Wolo"
  },
  {
    "id": "7401102002",
    "district_id": "740110",
    "label": "Donggala",
    "value": "Donggala"
  },
  {
    "id": "7401102003",
    "district_id": "740110",
    "label": "Ululapao-pao",
    "value": "Ululapao-pao"
  },
  {
    "id": "7401102008",
    "district_id": "740110",
    "label": "Tolowe Ponrewaru",
    "value": "Tolowe Ponrewaru"
  },
  {
    "id": "7401102009",
    "district_id": "740110",
    "label": "Lapao-Pao",
    "value": "Lapao-Pao"
  },
  {
    "id": "7401102010",
    "district_id": "740110",
    "label": "Langgomali",
    "value": "Langgomali"
  },
  {
    "id": "7401102011",
    "district_id": "740110",
    "label": "Lalonaha",
    "value": "Lalonaha"
  },
  {
    "id": "7401102012",
    "district_id": "740110",
    "label": "Lana",
    "value": "Lana"
  },
  {
    "id": "7401102015",
    "district_id": "740110",
    "label": "Lalonggopi",
    "value": "Lalonggopi"
  },
  {
    "id": "7401102016",
    "district_id": "740110",
    "label": "Muara Lapao-Pao",
    "value": "Muara Lapao-Pao"
  },
  {
    "id": "7401102018",
    "district_id": "740110",
    "label": "Samaenre",
    "value": "Samaenre"
  },
  {
    "id": "7401102019",
    "district_id": "740110",
    "label": "Iwoimopuro",
    "value": "Iwoimopuro"
  },
  {
    "id": "7401102021",
    "district_id": "740110",
    "label": "Ulu Rina",
    "value": "Ulu Rina"
  },
  {
    "id": "7401121001",
    "district_id": "740112",
    "label": "Puundoho",
    "value": "Puundoho"
  },
  {
    "id": "7401122002",
    "district_id": "740112",
    "label": "Longori",
    "value": "Longori"
  },
  {
    "id": "7401122003",
    "district_id": "740112",
    "label": "Puubunga",
    "value": "Puubunga"
  },
  {
    "id": "7401122004",
    "district_id": "740112",
    "label": "Puulemo",
    "value": "Puulemo"
  },
  {
    "id": "7401122005",
    "district_id": "740112",
    "label": "Puubenua",
    "value": "Puubenua"
  },
  {
    "id": "7401122006",
    "district_id": "740112",
    "label": "Baula",
    "value": "Baula"
  },
  {
    "id": "7401122007",
    "district_id": "740112",
    "label": "Pewutaa",
    "value": "Pewutaa"
  },
  {
    "id": "7401122008",
    "district_id": "740112",
    "label": "Watalara",
    "value": "Watalara"
  },
  {
    "id": "7401122009",
    "district_id": "740112",
    "label": "Puuroda",
    "value": "Puuroda"
  },
  {
    "id": "7401122010",
    "district_id": "740112",
    "label": "Ulu Baula",
    "value": "Ulu Baula"
  },
  {
    "id": "7401141001",
    "district_id": "740114",
    "label": "Mangolo",
    "value": "Mangolo"
  },
  {
    "id": "7401141002",
    "district_id": "740114",
    "label": "Kolakaasi",
    "value": "Kolakaasi"
  },
  {
    "id": "7401141003",
    "district_id": "740114",
    "label": "Sea",
    "value": "Sea"
  },
  {
    "id": "7401141004",
    "district_id": "740114",
    "label": "Latambaga",
    "value": "Latambaga"
  },
  {
    "id": "7401141005",
    "district_id": "740114",
    "label": "Sakuli",
    "value": "Sakuli"
  },
  {
    "id": "7401141006",
    "district_id": "740114",
    "label": "Induha",
    "value": "Induha"
  },
  {
    "id": "7401141007",
    "district_id": "740114",
    "label": "Ulunggolaka",
    "value": "Ulunggolaka"
  },
  {
    "id": "7401181003",
    "district_id": "740118",
    "label": "Anaiwoi",
    "value": "Anaiwoi"
  },
  {
    "id": "7401182001",
    "district_id": "740118",
    "label": "Lamedai",
    "value": "Lamedai"
  },
  {
    "id": "7401182002",
    "district_id": "740118",
    "label": "Tanggetada",
    "value": "Tanggetada"
  },
  {
    "id": "7401182004",
    "district_id": "740118",
    "label": "Pewisoa Jaya",
    "value": "Pewisoa Jaya"
  },
  {
    "id": "7401182005",
    "district_id": "740118",
    "label": "Tondowolio",
    "value": "Tondowolio"
  },
  {
    "id": "7401182006",
    "district_id": "740118",
    "label": "Popalia",
    "value": "Popalia"
  },
  {
    "id": "7401182007",
    "district_id": "740118",
    "label": "Lalonggolosua",
    "value": "Lalonggolosua"
  },
  {
    "id": "7401182008",
    "district_id": "740118",
    "label": "Petudua",
    "value": "Petudua"
  },
  {
    "id": "7401182009",
    "district_id": "740118",
    "label": "Rahanggada",
    "value": "Rahanggada"
  },
  {
    "id": "7401182010",
    "district_id": "740118",
    "label": "Palewai",
    "value": "Palewai"
  },
  {
    "id": "7401182011",
    "district_id": "740118",
    "label": "Puundaipa",
    "value": "Puundaipa"
  },
  {
    "id": "7401182012",
    "district_id": "740118",
    "label": "Oneeha",
    "value": "Oneeha"
  },
  {
    "id": "7401182013",
    "district_id": "740118",
    "label": "Lamoiko",
    "value": "Lamoiko"
  },
  {
    "id": "7401182014",
    "district_id": "740118",
    "label": "Tinggo",
    "value": "Tinggo"
  },
  {
    "id": "7401201001",
    "district_id": "740120",
    "label": "Tosiba",
    "value": "Tosiba"
  },
  {
    "id": "7401201013",
    "district_id": "740120",
    "label": "Tonganapo",
    "value": "Tonganapo"
  },
  {
    "id": "7401202002",
    "district_id": "740120",
    "label": "Konaweha",
    "value": "Konaweha"
  },
  {
    "id": "7401202003",
    "district_id": "740120",
    "label": "Kaloloa",
    "value": "Kaloloa"
  },
  {
    "id": "7401202004",
    "district_id": "740120",
    "label": "Tamboli",
    "value": "Tamboli"
  },
  {
    "id": "7401202005",
    "district_id": "740120",
    "label": "Lambolemo",
    "value": "Lambolemo"
  },
  {
    "id": "7401202006",
    "district_id": "740120",
    "label": "Amamutu",
    "value": "Amamutu"
  },
  {
    "id": "7401202007",
    "district_id": "740120",
    "label": "Lawulo",
    "value": "Lawulo"
  },
  {
    "id": "7401202008",
    "district_id": "740120",
    "label": "Awa",
    "value": "Awa"
  },
  {
    "id": "7401202009",
    "district_id": "740120",
    "label": "Latuo",
    "value": "Latuo"
  },
  {
    "id": "7401202010",
    "district_id": "740120",
    "label": "Wawo Tambali",
    "value": "Wawo Tambali"
  },
  {
    "id": "7401202011",
    "district_id": "740120",
    "label": "Malaha",
    "value": "Malaha"
  },
  {
    "id": "7401202012",
    "district_id": "740120",
    "label": "Sani-sani",
    "value": "Sani-sani"
  },
  {
    "id": "7401202014",
    "district_id": "740120",
    "label": "Ulu Konaweha",
    "value": "Ulu Konaweha"
  },
  {
    "id": "7401202015",
    "district_id": "740120",
    "label": "Puu Tamboli",
    "value": "Puu Tamboli"
  },
  {
    "id": "7401202016",
    "district_id": "740120",
    "label": "Ulaweng",
    "value": "Ulaweng"
  },
  {
    "id": "7401202017",
    "district_id": "740120",
    "label": "Puu Lawulo",
    "value": "Puu Lawulo"
  },
  {
    "id": "7401202018",
    "district_id": "740120",
    "label": "Liku",
    "value": "Liku"
  },
  {
    "id": "7401202019",
    "district_id": "740120",
    "label": "Meura",
    "value": "Meura"
  },
  {
    "id": "7401241003",
    "district_id": "740124",
    "label": "Ranomentaa",
    "value": "Ranomentaa"
  },
  {
    "id": "7401242001",
    "district_id": "740124",
    "label": "Toari",
    "value": "Toari"
  },
  {
    "id": "7401242002",
    "district_id": "740124",
    "label": "Lakito",
    "value": "Lakito"
  },
  {
    "id": "7401242004",
    "district_id": "740124",
    "label": "Anawua",
    "value": "Anawua"
  },
  {
    "id": "7401242005",
    "district_id": "740124",
    "label": "Wowoli",
    "value": "Wowoli"
  },
  {
    "id": "7401242006",
    "district_id": "740124",
    "label": "Rano Jaya",
    "value": "Rano Jaya"
  },
  {
    "id": "7401242007",
    "district_id": "740124",
    "label": "Rahabite",
    "value": "Rahabite"
  },
  {
    "id": "7401242008",
    "district_id": "740124",
    "label": "Wonua Raya",
    "value": "Wonua Raya"
  },
  {
    "id": "7401242009",
    "district_id": "740124",
    "label": "Rano Sangia",
    "value": "Rano Sangia"
  },
  {
    "id": "7401242010",
    "district_id": "740124",
    "label": "Horongkuli",
    "value": "Horongkuli"
  },
  {
    "id": "7401251001",
    "district_id": "740125",
    "label": "Polinggona",
    "value": "Polinggona"
  },
  {
    "id": "7401252002",
    "district_id": "740125",
    "label": "Puudongi",
    "value": "Puudongi"
  },
  {
    "id": "7401252003",
    "district_id": "740125",
    "label": "Plasma Jaya",
    "value": "Plasma Jaya"
  },
  {
    "id": "7401252004",
    "district_id": "740125",
    "label": "Tanggeau",
    "value": "Tanggeau"
  },
  {
    "id": "7401252005",
    "district_id": "740125",
    "label": "Wulonggere",
    "value": "Wulonggere"
  },
  {
    "id": "7401252006",
    "district_id": "740125",
    "label": "Pondowae",
    "value": "Pondowae"
  },
  {
    "id": "7401252007",
    "district_id": "740125",
    "label": "Lamondape",
    "value": "Lamondape"
  },
  {
    "id": "7401272001",
    "district_id": "740127",
    "label": "Tamborasi",
    "value": "Tamborasi"
  },
  {
    "id": "7401272002",
    "district_id": "740127",
    "label": "Ladahai",
    "value": "Ladahai"
  },
  {
    "id": "7401272003",
    "district_id": "740127",
    "label": "Lasiroku",
    "value": "Lasiroku"
  },
  {
    "id": "7401272004",
    "district_id": "740127",
    "label": "Iwoimendaa",
    "value": "Iwoimendaa"
  },
  {
    "id": "7401272005",
    "district_id": "740127",
    "label": "Lambopini",
    "value": "Lambopini"
  },
  {
    "id": "7401272006",
    "district_id": "740127",
    "label": "Ulu Kalo",
    "value": "Ulu Kalo"
  },
  {
    "id": "7401272007",
    "district_id": "740127",
    "label": "Wonualaku",
    "value": "Wonualaku"
  },
  {
    "id": "7401272008",
    "district_id": "740127",
    "label": "Lawolia",
    "value": "Lawolia"
  },
  {
    "id": "7401272009",
    "district_id": "740127",
    "label": "Watumelewe",
    "value": "Watumelewe"
  },
  {
    "id": "7401272010",
    "district_id": "740127",
    "label": "Landoula",
    "value": "Landoula"
  },
  {
    "id": "7402011001",
    "district_id": "740201",
    "label": "Lambuya",
    "value": "Lambuya"
  },
  {
    "id": "7402012006",
    "district_id": "740201",
    "label": "Awuliti",
    "value": "Awuliti"
  },
  {
    "id": "7402012007",
    "district_id": "740201",
    "label": "Asaki",
    "value": "Asaki"
  },
  {
    "id": "7402012011",
    "district_id": "740201",
    "label": "Amberi",
    "value": "Amberi"
  },
  {
    "id": "7402012012",
    "district_id": "740201",
    "label": "Waworaha",
    "value": "Waworaha"
  },
  {
    "id": "7402012015",
    "district_id": "740201",
    "label": "Tanggobu",
    "value": "Tanggobu"
  },
  {
    "id": "7402012027",
    "district_id": "740201",
    "label": "Meraka",
    "value": "Meraka"
  },
  {
    "id": "7402012035",
    "district_id": "740201",
    "label": "Wanua Hoa",
    "value": "Wanua Hoa"
  },
  {
    "id": "7402012036",
    "district_id": "740201",
    "label": "Watarema",
    "value": "Watarema"
  },
  {
    "id": "7402012037",
    "district_id": "740201",
    "label": "Tetembomua",
    "value": "Tetembomua"
  },
  {
    "id": "7402021001",
    "district_id": "740202",
    "label": "Puunaaha",
    "value": "Puunaaha"
  },
  {
    "id": "7402021002",
    "district_id": "740202",
    "label": "Tumpas",
    "value": "Tumpas"
  },
  {
    "id": "7402021003",
    "district_id": "740202",
    "label": "Latoma",
    "value": "Latoma"
  },
  {
    "id": "7402021004",
    "district_id": "740202",
    "label": "Ambekairi",
    "value": "Ambekairi"
  },
  {
    "id": "7402021005",
    "district_id": "740202",
    "label": "Arombu",
    "value": "Arombu"
  },
  {
    "id": "7402021006",
    "district_id": "740202",
    "label": "Asinua",
    "value": "Asinua"
  },
  {
    "id": "7402021011",
    "district_id": "740202",
    "label": "Wawonggole",
    "value": "Wawonggole"
  },
  {
    "id": "7402021012",
    "district_id": "740202",
    "label": "Tuoy",
    "value": "Tuoy"
  },
  {
    "id": "7402021013",
    "district_id": "740202",
    "label": "Unaaha",
    "value": "Unaaha"
  },
  {
    "id": "7402021015",
    "district_id": "740202",
    "label": "Inolobunggadue",
    "value": "Inolobunggadue"
  },
  {
    "id": "7402021016",
    "district_id": "740202",
    "label": "Asambu",
    "value": "Asambu"
  },
  {
    "id": "7402021017",
    "district_id": "740202",
    "label": "Tobeu",
    "value": "Tobeu"
  },
  {
    "id": "7402031001",
    "district_id": "740203",
    "label": "Wawotobi",
    "value": "Wawotobi"
  },
  {
    "id": "7402031002",
    "district_id": "740203",
    "label": "Inalahi",
    "value": "Inalahi"
  },
  {
    "id": "7402031006",
    "district_id": "740203",
    "label": "Lalosabila",
    "value": "Lalosabila"
  },
  {
    "id": "7402031007",
    "district_id": "740203",
    "label": "Hopa-Hopa",
    "value": "Hopa-Hopa"
  },
  {
    "id": "7402031008",
    "district_id": "740203",
    "label": "Kulahi",
    "value": "Kulahi"
  },
  {
    "id": "7402031009",
    "district_id": "740203",
    "label": "Ranoeya",
    "value": "Ranoeya"
  },
  {
    "id": "7402031010",
    "district_id": "740203",
    "label": "Palarahi",
    "value": "Palarahi"
  },
  {
    "id": "7402031011",
    "district_id": "740203",
    "label": "Kasupute",
    "value": "Kasupute"
  },
  {
    "id": "7402031019",
    "district_id": "740203",
    "label": "Nohu - Nohu",
    "value": "Nohu - Nohu"
  },
  {
    "id": "7402031020",
    "district_id": "740203",
    "label": "Inolobu",
    "value": "Inolobu"
  },
  {
    "id": "7402031027",
    "district_id": "740203",
    "label": "Bose-Bose",
    "value": "Bose-Bose"
  },
  {
    "id": "7402031028",
    "district_id": "740203",
    "label": "Puusinauwi",
    "value": "Puusinauwi"
  },
  {
    "id": "7402032013",
    "district_id": "740203",
    "label": "Nario Indah",
    "value": "Nario Indah"
  },
  {
    "id": "7402032017",
    "district_id": "740203",
    "label": "Anggotoa",
    "value": "Anggotoa"
  },
  {
    "id": "7402032018",
    "district_id": "740203",
    "label": "Analahumbuti",
    "value": "Analahumbuti"
  },
  {
    "id": "7402032025",
    "district_id": "740203",
    "label": "Karandu",
    "value": "Karandu"
  },
  {
    "id": "7402032026",
    "district_id": "740203",
    "label": "Korumba",
    "value": "Korumba"
  },
  {
    "id": "7402032031",
    "district_id": "740203",
    "label": "Kasumewuho",
    "value": "Kasumewuho"
  },
  {
    "id": "7402032032",
    "district_id": "740203",
    "label": "Kukuluri",
    "value": "Kukuluri"
  },
  {
    "id": "7402041001",
    "district_id": "740204",
    "label": "Pondidaha",
    "value": "Pondidaha"
  },
  {
    "id": "7402042013",
    "district_id": "740204",
    "label": "Hongoa",
    "value": "Hongoa"
  },
  {
    "id": "7402042016",
    "district_id": "740204",
    "label": "Mumundowu",
    "value": "Mumundowu"
  },
  {
    "id": "7402042019",
    "district_id": "740204",
    "label": "Ahuawatu",
    "value": "Ahuawatu"
  },
  {
    "id": "7402042025",
    "district_id": "740204",
    "label": "Tirawuta",
    "value": "Tirawuta"
  },
  {
    "id": "7402042030",
    "district_id": "740204",
    "label": "Ambulanu",
    "value": "Ambulanu"
  },
  {
    "id": "7402042031",
    "district_id": "740204",
    "label": "Belatu",
    "value": "Belatu"
  },
  {
    "id": "7402042034",
    "district_id": "740204",
    "label": "Amesiu",
    "value": "Amesiu"
  },
  {
    "id": "7402042035",
    "district_id": "740204",
    "label": "Puumbinisi",
    "value": "Puumbinisi"
  },
  {
    "id": "7402042039",
    "district_id": "740204",
    "label": "Wawolemo",
    "value": "Wawolemo"
  },
  {
    "id": "7402042045",
    "district_id": "740204",
    "label": "Sulemandara",
    "value": "Sulemandara"
  },
  {
    "id": "7402042046",
    "district_id": "740204",
    "label": "Lalodangge",
    "value": "Lalodangge"
  },
  {
    "id": "7402042047",
    "district_id": "740204",
    "label": "Wawolahumbuti",
    "value": "Wawolahumbuti"
  },
  {
    "id": "7402042048",
    "district_id": "740204",
    "label": "Laloika",
    "value": "Laloika"
  },
  {
    "id": "7402042049",
    "district_id": "740204",
    "label": "Lalonggotomi",
    "value": "Lalonggotomi"
  },
  {
    "id": "7402042050",
    "district_id": "740204",
    "label": "Wonua Mandara",
    "value": "Wonua Mandara"
  },
  {
    "id": "7402042051",
    "district_id": "740204",
    "label": "Wonua Monapa",
    "value": "Wonua Monapa"
  },
  {
    "id": "7402042052",
    "district_id": "740204",
    "label": "Lahonggumbi",
    "value": "Lahonggumbi"
  },
  {
    "id": "7402051001",
    "district_id": "740205",
    "label": "Sampara",
    "value": "Sampara"
  },
  {
    "id": "7402051007",
    "district_id": "740205",
    "label": "Rawua",
    "value": "Rawua"
  },
  {
    "id": "7402052006",
    "district_id": "740205",
    "label": "Andepali",
    "value": "Andepali"
  },
  {
    "id": "7402052008",
    "district_id": "740205",
    "label": "Andaroa",
    "value": "Andaroa"
  },
  {
    "id": "7402052009",
    "district_id": "740205",
    "label": "Puuloro",
    "value": "Puuloro"
  },
  {
    "id": "7402052012",
    "district_id": "740205",
    "label": "Andadowi",
    "value": "Andadowi"
  },
  {
    "id": "7402052027",
    "district_id": "740205",
    "label": "Totombe Jaya",
    "value": "Totombe Jaya"
  },
  {
    "id": "7402052028",
    "district_id": "740205",
    "label": "Boa Boa",
    "value": "Boa Boa"
  },
  {
    "id": "7402052030",
    "district_id": "740205",
    "label": "Wawolimbue",
    "value": "Wawolimbue"
  },
  {
    "id": "7402052036",
    "district_id": "740205",
    "label": "Baeni",
    "value": "Baeni"
  },
  {
    "id": "7402052038",
    "district_id": "740205",
    "label": "Polua",
    "value": "Polua"
  },
  {
    "id": "7402052041",
    "district_id": "740205",
    "label": "Pohara",
    "value": "Pohara"
  },
  {
    "id": "7402052048",
    "district_id": "740205",
    "label": "Bondoala",
    "value": "Bondoala"
  },
  {
    "id": "7402052052",
    "district_id": "740205",
    "label": "Kongga Mea",
    "value": "Kongga Mea"
  },
  {
    "id": "7402052057",
    "district_id": "740205",
    "label": "Wawo Andaroa",
    "value": "Wawo Andaroa"
  },
  {
    "id": "7402101001",
    "district_id": "740210",
    "label": "Abuki",
    "value": "Abuki"
  },
  {
    "id": "7402102007",
    "district_id": "740210",
    "label": "Sambeani",
    "value": "Sambeani"
  },
  {
    "id": "7402102008",
    "district_id": "740210",
    "label": "Asolu",
    "value": "Asolu"
  },
  {
    "id": "7402102009",
    "district_id": "740210",
    "label": "Punggaluku",
    "value": "Punggaluku"
  },
  {
    "id": "7402102012",
    "district_id": "740210",
    "label": "Walay",
    "value": "Walay"
  },
  {
    "id": "7402102015",
    "district_id": "740210",
    "label": "Epeeya",
    "value": "Epeeya"
  },
  {
    "id": "7402102024",
    "district_id": "740210",
    "label": "Kumapo",
    "value": "Kumapo"
  },
  {
    "id": "7402102035",
    "district_id": "740210",
    "label": "Unaasi Jaya",
    "value": "Unaasi Jaya"
  },
  {
    "id": "7402102038",
    "district_id": "740210",
    "label": "Kasuwura Indah",
    "value": "Kasuwura Indah"
  },
  {
    "id": "7402102039",
    "district_id": "740210",
    "label": "Arubia",
    "value": "Arubia"
  },
  {
    "id": "7402102040",
    "district_id": "740210",
    "label": "Padangguni Utama",
    "value": "Padangguni Utama"
  },
  {
    "id": "7402102042",
    "district_id": "740210",
    "label": "Anggoro",
    "value": "Anggoro"
  },
  {
    "id": "7402111001",
    "district_id": "740211",
    "label": "Toronipa",
    "value": "Toronipa"
  },
  {
    "id": "7402112002",
    "district_id": "740211",
    "label": "Atowatu",
    "value": "Atowatu"
  },
  {
    "id": "7402112004",
    "district_id": "740211",
    "label": "Waworaha",
    "value": "Waworaha"
  },
  {
    "id": "7402112006",
    "district_id": "740211",
    "label": "Sorue Jaya",
    "value": "Sorue Jaya"
  },
  {
    "id": "7402112010",
    "district_id": "740211",
    "label": "Soropia",
    "value": "Soropia"
  },
  {
    "id": "7402112011",
    "district_id": "740211",
    "label": "Tapulaga",
    "value": "Tapulaga"
  },
  {
    "id": "7402112012",
    "district_id": "740211",
    "label": "Sawapudo",
    "value": "Sawapudo"
  },
  {
    "id": "7402112014",
    "district_id": "740211",
    "label": "Bokori",
    "value": "Bokori"
  },
  {
    "id": "7402112015",
    "district_id": "740211",
    "label": "Saponda",
    "value": "Saponda"
  },
  {
    "id": "7402112016",
    "district_id": "740211",
    "label": "Mekar",
    "value": "Mekar"
  },
  {
    "id": "7402112017",
    "district_id": "740211",
    "label": "Bajo Indah",
    "value": "Bajo Indah"
  },
  {
    "id": "7402112022",
    "district_id": "740211",
    "label": "Telaga Biru",
    "value": "Telaga Biru"
  },
  {
    "id": "7402112023",
    "district_id": "740211",
    "label": "Leppe",
    "value": "Leppe"
  },
  {
    "id": "7402112024",
    "district_id": "740211",
    "label": "Bajoe",
    "value": "Bajoe"
  },
  {
    "id": "7402112025",
    "district_id": "740211",
    "label": "Saponda Laut",
    "value": "Saponda Laut"
  },
  {
    "id": "7402151001",
    "district_id": "740215",
    "label": "Tongauna",
    "value": "Tongauna"
  },
  {
    "id": "7402151002",
    "district_id": "740215",
    "label": "Mata Iwoi",
    "value": "Mata Iwoi"
  },
  {
    "id": "7402151003",
    "district_id": "740215",
    "label": "Puosu",
    "value": "Puosu"
  },
  {
    "id": "7402151004",
    "district_id": "740215",
    "label": "Sendang Mulya Sari",
    "value": "Sendang Mulya Sari"
  },
  {
    "id": "7402151005",
    "district_id": "740215",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "7402152006",
    "district_id": "740215",
    "label": "Lalonggowuna",
    "value": "Lalonggowuna"
  },
  {
    "id": "7402152007",
    "district_id": "740215",
    "label": "Asao",
    "value": "Asao"
  },
  {
    "id": "7402152008",
    "district_id": "740215",
    "label": "Waworoda Jaya",
    "value": "Waworoda Jaya"
  },
  {
    "id": "7402152009",
    "district_id": "740215",
    "label": "Sanuanggamo",
    "value": "Sanuanggamo"
  },
  {
    "id": "7402152010",
    "district_id": "740215",
    "label": "Puundopi",
    "value": "Puundopi"
  },
  {
    "id": "7402152011",
    "district_id": "740215",
    "label": "Ambopi",
    "value": "Ambopi"
  },
  {
    "id": "7402152012",
    "district_id": "740215",
    "label": "Andeposunde",
    "value": "Andeposunde"
  },
  {
    "id": "7402152013",
    "district_id": "740215",
    "label": "Momea",
    "value": "Momea"
  },
  {
    "id": "7402152014",
    "district_id": "740215",
    "label": "Anggohu",
    "value": "Anggohu"
  },
  {
    "id": "7402152015",
    "district_id": "740215",
    "label": "Olua Ao",
    "value": "Olua Ao"
  },
  {
    "id": "7402152016",
    "district_id": "740215",
    "label": "Nambeaboru",
    "value": "Nambeaboru"
  },
  {
    "id": "7402152017",
    "district_id": "740215",
    "label": "Olo Onua",
    "value": "Olo Onua"
  },
  {
    "id": "7402152018",
    "district_id": "740215",
    "label": "Ambepulu",
    "value": "Ambepulu"
  },
  {
    "id": "7402152019",
    "district_id": "740215",
    "label": "Barowila",
    "value": "Barowila"
  },
  {
    "id": "7402152020",
    "district_id": "740215",
    "label": "Andalambe",
    "value": "Andalambe"
  },
  {
    "id": "7402161003",
    "district_id": "740216",
    "label": "Waworaha",
    "value": "Waworaha"
  },
  {
    "id": "7402162001",
    "district_id": "740216",
    "label": "Latoma Jaya",
    "value": "Latoma Jaya"
  },
  {
    "id": "7402162002",
    "district_id": "740216",
    "label": "Ambekaeri Utama",
    "value": "Ambekaeri Utama"
  },
  {
    "id": "7402162004",
    "district_id": "740216",
    "label": "Amboniki",
    "value": "Amboniki"
  },
  {
    "id": "7402162005",
    "district_id": "740216",
    "label": "Pinole",
    "value": "Pinole"
  },
  {
    "id": "7402162006",
    "district_id": "740216",
    "label": "Nesowi",
    "value": "Nesowi"
  },
  {
    "id": "7402162007",
    "district_id": "740216",
    "label": "Wawolatoma",
    "value": "Wawolatoma"
  },
  {
    "id": "7402162008",
    "district_id": "740216",
    "label": "Andoluto",
    "value": "Andoluto"
  },
  {
    "id": "7402162009",
    "district_id": "740216",
    "label": "Lalowata",
    "value": "Lalowata"
  },
  {
    "id": "7402162010",
    "district_id": "740216",
    "label": "Titioa",
    "value": "Titioa"
  },
  {
    "id": "7402162012",
    "district_id": "740216",
    "label": "Angonga",
    "value": "Angonga"
  },
  {
    "id": "7402162013",
    "district_id": "740216",
    "label": "Napooha",
    "value": "Napooha"
  },
  {
    "id": "7402162014",
    "district_id": "740216",
    "label": "Arombu Utama",
    "value": "Arombu Utama"
  },
  {
    "id": "7402171002",
    "district_id": "740217",
    "label": "Watundehoa",
    "value": "Watundehoa"
  },
  {
    "id": "7402172001",
    "district_id": "740217",
    "label": "Puriala",
    "value": "Puriala"
  },
  {
    "id": "7402172003",
    "district_id": "740217",
    "label": "Sonai",
    "value": "Sonai"
  },
  {
    "id": "7402172004",
    "district_id": "740217",
    "label": "Lalonggatu",
    "value": "Lalonggatu"
  },
  {
    "id": "7402172005",
    "district_id": "740217",
    "label": "Puanaha",
    "value": "Puanaha"
  },
  {
    "id": "7402172006",
    "district_id": "740217",
    "label": "Wonua Morome",
    "value": "Wonua Morome"
  },
  {
    "id": "7402172007",
    "district_id": "740217",
    "label": "Ahuawali",
    "value": "Ahuawali"
  },
  {
    "id": "7402172008",
    "district_id": "740217",
    "label": "Watusa",
    "value": "Watusa"
  },
  {
    "id": "7402172009",
    "district_id": "740217",
    "label": "Puusangi",
    "value": "Puusangi"
  },
  {
    "id": "7402172010",
    "district_id": "740217",
    "label": "Mokaleleo",
    "value": "Mokaleleo"
  },
  {
    "id": "7402172011",
    "district_id": "740217",
    "label": "Unggulino",
    "value": "Unggulino"
  },
  {
    "id": "7402172012",
    "district_id": "740217",
    "label": "Tetewatu",
    "value": "Tetewatu"
  },
  {
    "id": "7402172013",
    "district_id": "740217",
    "label": "Tetehaka",
    "value": "Tetehaka"
  },
  {
    "id": "7402172014",
    "district_id": "740217",
    "label": "Puuhopa",
    "value": "Puuhopa"
  },
  {
    "id": "7402172015",
    "district_id": "740217",
    "label": "Laloonaha",
    "value": "Laloonaha"
  },
  {
    "id": "7402172016",
    "district_id": "740217",
    "label": "Wawosanggula",
    "value": "Wawosanggula"
  },
  {
    "id": "7402182002",
    "district_id": "740218",
    "label": "Ameroro",
    "value": "Ameroro"
  },
  {
    "id": "7402182003",
    "district_id": "740218",
    "label": "Tawarotebota",
    "value": "Tawarotebota"
  },
  {
    "id": "7402182004",
    "district_id": "740218",
    "label": "Alo-Oloho",
    "value": "Alo-Oloho"
  },
  {
    "id": "7402182005",
    "district_id": "740218",
    "label": "Matahoalu",
    "value": "Matahoalu"
  },
  {
    "id": "7402182006",
    "district_id": "740218",
    "label": "Langgomea",
    "value": "Langgomea"
  },
  {
    "id": "7402182007",
    "district_id": "740218",
    "label": "Rawua",
    "value": "Rawua"
  },
  {
    "id": "7402182008",
    "district_id": "740218",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7402182009",
    "district_id": "740218",
    "label": "Amaroa",
    "value": "Amaroa"
  },
  {
    "id": "7402182010",
    "district_id": "740218",
    "label": "Tawamelewe",
    "value": "Tawamelewe"
  },
  {
    "id": "7402182011",
    "district_id": "740218",
    "label": "Anggopiu",
    "value": "Anggopiu"
  },
  {
    "id": "7402182012",
    "district_id": "740218",
    "label": "Tamesandi",
    "value": "Tamesandi"
  },
  {
    "id": "7402182013",
    "district_id": "740218",
    "label": "Humboto",
    "value": "Humboto"
  },
  {
    "id": "7402182015",
    "district_id": "740218",
    "label": "Anggawo",
    "value": "Anggawo"
  },
  {
    "id": "7402182016",
    "district_id": "740218",
    "label": "Kasaeda",
    "value": "Kasaeda"
  },
  {
    "id": "7402182017",
    "district_id": "740218",
    "label": "Tanggodipo",
    "value": "Tanggodipo"
  },
  {
    "id": "7402182018",
    "district_id": "740218",
    "label": "Panggulawu",
    "value": "Panggulawu"
  },
  {
    "id": "7402182019",
    "district_id": "740218",
    "label": "Puuroda Jaya",
    "value": "Puuroda Jaya"
  },
  {
    "id": "7402191009",
    "district_id": "740219",
    "label": "Puuduria",
    "value": "Puuduria"
  },
  {
    "id": "7402192004",
    "district_id": "740219",
    "label": "Duriaasi",
    "value": "Duriaasi"
  },
  {
    "id": "7402192006",
    "district_id": "740219",
    "label": "Lalousu",
    "value": "Lalousu"
  },
  {
    "id": "7402192008",
    "district_id": "740219",
    "label": "Wawoone",
    "value": "Wawoone"
  },
  {
    "id": "7402192010",
    "district_id": "740219",
    "label": "Wawosolo",
    "value": "Wawosolo"
  },
  {
    "id": "7402192012",
    "district_id": "740219",
    "label": "Wawonggole",
    "value": "Wawonggole"
  },
  {
    "id": "7402192015",
    "district_id": "740219",
    "label": "Tetemotaha",
    "value": "Tetemotaha"
  },
  {
    "id": "7402192016",
    "district_id": "740219",
    "label": "Dawi-dawi",
    "value": "Dawi-dawi"
  },
  {
    "id": "7402192017",
    "district_id": "740219",
    "label": "Bendewuta",
    "value": "Bendewuta"
  },
  {
    "id": "7402192018",
    "district_id": "740219",
    "label": "Langgonawe",
    "value": "Langgonawe"
  },
  {
    "id": "7402192019",
    "district_id": "740219",
    "label": "Lalohao",
    "value": "Lalohao"
  },
  {
    "id": "7402192020",
    "district_id": "740219",
    "label": "Tomulipu / Wakusao",
    "value": "Tomulipu / Wakusao"
  },
  {
    "id": "7402192026",
    "district_id": "740219",
    "label": "Tawarolondo",
    "value": "Tawarolondo"
  },
  {
    "id": "7402192027",
    "district_id": "740219",
    "label": "Polandangi",
    "value": "Polandangi"
  },
  {
    "id": "7402192028",
    "district_id": "740219",
    "label": "Anggoro",
    "value": "Anggoro"
  },
  {
    "id": "7402192029",
    "district_id": "740219",
    "label": "Tetewonua",
    "value": "Tetewonua"
  },
  {
    "id": "7402201003",
    "district_id": "740220",
    "label": "Besulutu",
    "value": "Besulutu"
  },
  {
    "id": "7402202001",
    "district_id": "740220",
    "label": "Lawonua",
    "value": "Lawonua"
  },
  {
    "id": "7402202002",
    "district_id": "740220",
    "label": "Amosilu",
    "value": "Amosilu"
  },
  {
    "id": "7402202004",
    "district_id": "740220",
    "label": "Laloumera",
    "value": "Laloumera"
  },
  {
    "id": "7402202005",
    "district_id": "740220",
    "label": "Asunda",
    "value": "Asunda"
  },
  {
    "id": "7402202006",
    "district_id": "740220",
    "label": "Ulupohara",
    "value": "Ulupohara"
  },
  {
    "id": "7402202007",
    "district_id": "740220",
    "label": "Labela",
    "value": "Labela"
  },
  {
    "id": "7402202008",
    "district_id": "740220",
    "label": "Ranomolua",
    "value": "Ranomolua"
  },
  {
    "id": "7402202009",
    "district_id": "740220",
    "label": "Silea",
    "value": "Silea"
  },
  {
    "id": "7402202010",
    "district_id": "740220",
    "label": "Onembute",
    "value": "Onembute"
  },
  {
    "id": "7402202011",
    "district_id": "740220",
    "label": "Andomesinggo",
    "value": "Andomesinggo"
  },
  {
    "id": "7402202012",
    "district_id": "740220",
    "label": "Puulowaru",
    "value": "Puulowaru"
  },
  {
    "id": "7402202013",
    "district_id": "740220",
    "label": "Puundoho",
    "value": "Puundoho"
  },
  {
    "id": "7402202014",
    "district_id": "740220",
    "label": "Punggaluku",
    "value": "Punggaluku"
  },
  {
    "id": "7402202015",
    "district_id": "740220",
    "label": "Waworaha",
    "value": "Waworaha"
  },
  {
    "id": "7402202016",
    "district_id": "740220",
    "label": "Lalowulo",
    "value": "Lalowulo"
  },
  {
    "id": "7402211003",
    "district_id": "740221",
    "label": "Laosu",
    "value": "Laosu"
  },
  {
    "id": "7402212002",
    "district_id": "740221",
    "label": "Pebunooha",
    "value": "Pebunooha"
  },
  {
    "id": "7402212004",
    "district_id": "740221",
    "label": "Lalonggalaku",
    "value": "Lalonggalaku"
  },
  {
    "id": "7402212009",
    "district_id": "740221",
    "label": "Diolo",
    "value": "Diolo"
  },
  {
    "id": "7402212018",
    "district_id": "740221",
    "label": "Pebunooha Dalam",
    "value": "Pebunooha Dalam"
  },
  {
    "id": "7402212023",
    "district_id": "740221",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "7402212028",
    "district_id": "740221",
    "label": "Rambu Kongga",
    "value": "Rambu Kongga"
  },
  {
    "id": "7402212029",
    "district_id": "740221",
    "label": "Laosu Jaya",
    "value": "Laosu Jaya"
  },
  {
    "id": "7402212031",
    "district_id": "740221",
    "label": "Langgaluku Timur",
    "value": "Langgaluku Timur"
  },
  {
    "id": "7402231001",
    "district_id": "740223",
    "label": "Ruota",
    "value": "Ruota"
  },
  {
    "id": "7402232002",
    "district_id": "740223",
    "label": "Tira Wonua",
    "value": "Tira Wonua"
  },
  {
    "id": "7402232003",
    "district_id": "740223",
    "label": "Parudongka",
    "value": "Parudongka"
  },
  {
    "id": "7402232004",
    "district_id": "740223",
    "label": "Walandawe",
    "value": "Walandawe"
  },
  {
    "id": "7402232005",
    "district_id": "740223",
    "label": "Puuwiwirano",
    "value": "Puuwiwirano"
  },
  {
    "id": "7402232006",
    "district_id": "740223",
    "label": "Tanggola",
    "value": "Tanggola"
  },
  {
    "id": "7402232007",
    "district_id": "740223",
    "label": "Lalomerui",
    "value": "Lalomerui"
  },
  {
    "id": "7402232008",
    "district_id": "740223",
    "label": "Wiau",
    "value": "Wiau"
  },
  {
    "id": "7402241001",
    "district_id": "740224",
    "label": "Parauna",
    "value": "Parauna"
  },
  {
    "id": "7402241002",
    "district_id": "740224",
    "label": "Anggaberi",
    "value": "Anggaberi"
  },
  {
    "id": "7402241003",
    "district_id": "740224",
    "label": "Lawulo",
    "value": "Lawulo"
  },
  {
    "id": "7402241004",
    "district_id": "740224",
    "label": "Andabia",
    "value": "Andabia"
  },
  {
    "id": "7402241005",
    "district_id": "740224",
    "label": "Unaasi",
    "value": "Unaasi"
  },
  {
    "id": "7402241006",
    "district_id": "740224",
    "label": "Toriki",
    "value": "Toriki"
  },
  {
    "id": "7402242007",
    "district_id": "740224",
    "label": "Lerehoma",
    "value": "Lerehoma"
  },
  {
    "id": "7402242008",
    "district_id": "740224",
    "label": "Wunduongohi",
    "value": "Wunduongohi"
  },
  {
    "id": "7402251003",
    "district_id": "740225",
    "label": "Meluhu",
    "value": "Meluhu"
  },
  {
    "id": "7402252001",
    "district_id": "740225",
    "label": "Weorahi",
    "value": "Weorahi"
  },
  {
    "id": "7402252002",
    "district_id": "740225",
    "label": "Lamelai",
    "value": "Lamelai"
  },
  {
    "id": "7402252004",
    "district_id": "740225",
    "label": "Abuhu",
    "value": "Abuhu"
  },
  {
    "id": "7402252005",
    "district_id": "740225",
    "label": "Tudameaso",
    "value": "Tudameaso"
  },
  {
    "id": "7402252006",
    "district_id": "740225",
    "label": "Larowiu",
    "value": "Larowiu"
  },
  {
    "id": "7402252007",
    "district_id": "740225",
    "label": "Ahuloa",
    "value": "Ahuloa"
  },
  {
    "id": "7402252008",
    "district_id": "740225",
    "label": "Sambasule",
    "value": "Sambasule"
  },
  {
    "id": "7402252009",
    "district_id": "740225",
    "label": "Lalopisi",
    "value": "Lalopisi"
  },
  {
    "id": "7402281013",
    "district_id": "740228",
    "label": "Amunggedo Baru",
    "value": "Amunggedo Baru"
  },
  {
    "id": "7402282001",
    "district_id": "740228",
    "label": "Anahinunu",
    "value": "Anahinunu"
  },
  {
    "id": "7402282002",
    "district_id": "740228",
    "label": "Ulu Benua",
    "value": "Ulu Benua"
  },
  {
    "id": "7402282003",
    "district_id": "740228",
    "label": "Puasana",
    "value": "Puasana"
  },
  {
    "id": "7402282004",
    "district_id": "740228",
    "label": "Watulawu",
    "value": "Watulawu"
  },
  {
    "id": "7402282005",
    "district_id": "740228",
    "label": "Lalonona",
    "value": "Lalonona"
  },
  {
    "id": "7402282006",
    "district_id": "740228",
    "label": "Amonggedo",
    "value": "Amonggedo"
  },
  {
    "id": "7402282007",
    "district_id": "740228",
    "label": "Dunggua",
    "value": "Dunggua"
  },
  {
    "id": "7402282008",
    "district_id": "740228",
    "label": "Benua",
    "value": "Benua"
  },
  {
    "id": "7402282009",
    "district_id": "740228",
    "label": "Matabura",
    "value": "Matabura"
  },
  {
    "id": "7402282010",
    "district_id": "740228",
    "label": "Wawohine",
    "value": "Wawohine"
  },
  {
    "id": "7402282011",
    "district_id": "740228",
    "label": "Mendikonu",
    "value": "Mendikonu"
  },
  {
    "id": "7402282012",
    "district_id": "740228",
    "label": "Warandete",
    "value": "Warandete"
  },
  {
    "id": "7402282014",
    "district_id": "740228",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7402282015",
    "district_id": "740228",
    "label": "Lalombonda",
    "value": "Lalombonda"
  },
  {
    "id": "7402311004",
    "district_id": "740231",
    "label": "Ambodiaa",
    "value": "Ambodiaa"
  },
  {
    "id": "7402312001",
    "district_id": "740231",
    "label": "Nekudu",
    "value": "Nekudu"
  },
  {
    "id": "7402312002",
    "district_id": "740231",
    "label": "Lasada",
    "value": "Lasada"
  },
  {
    "id": "7402312003",
    "district_id": "740231",
    "label": "Asinua Jaya",
    "value": "Asinua Jaya"
  },
  {
    "id": "7402312005",
    "district_id": "740231",
    "label": "Angohi",
    "value": "Angohi"
  },
  {
    "id": "7402312006",
    "district_id": "740231",
    "label": "Awua Jaya",
    "value": "Awua Jaya"
  },
  {
    "id": "7402312007",
    "district_id": "740231",
    "label": "Awua Sari",
    "value": "Awua Sari"
  },
  {
    "id": "7402312008",
    "district_id": "740231",
    "label": "Asipako",
    "value": "Asipako"
  },
  {
    "id": "7402321001",
    "district_id": "740232",
    "label": "Bungguosu",
    "value": "Bungguosu"
  },
  {
    "id": "7402321002",
    "district_id": "740232",
    "label": "Tudaone",
    "value": "Tudaone"
  },
  {
    "id": "7402321003",
    "district_id": "740232",
    "label": "Konawe",
    "value": "Konawe"
  },
  {
    "id": "7402321004",
    "district_id": "740232",
    "label": "Tawanga",
    "value": "Tawanga"
  },
  {
    "id": "7402322005",
    "district_id": "740232",
    "label": "Sanggona",
    "value": "Sanggona"
  },
  {
    "id": "7402322006",
    "district_id": "740232",
    "label": "Uelawu",
    "value": "Uelawu"
  },
  {
    "id": "7402322007",
    "district_id": "740232",
    "label": "Mekowu",
    "value": "Mekowu"
  },
  {
    "id": "7402322008",
    "district_id": "740232",
    "label": "Uete",
    "value": "Uete"
  },
  {
    "id": "7402322009",
    "district_id": "740232",
    "label": "Hudoa",
    "value": "Hudoa"
  },
  {
    "id": "7402322010",
    "district_id": "740232",
    "label": "Wonua Mbae",
    "value": "Wonua Mbae"
  },
  {
    "id": "7402322011",
    "district_id": "740232",
    "label": "Asinowowo",
    "value": "Asinowowo"
  },
  {
    "id": "7402322012",
    "district_id": "740232",
    "label": "Puuwonua",
    "value": "Puuwonua"
  },
  {
    "id": "7402331001",
    "district_id": "740233",
    "label": "Kapoiala",
    "value": "Kapoiala"
  },
  {
    "id": "7402332002",
    "district_id": "740233",
    "label": "Lalimbue Jaya",
    "value": "Lalimbue Jaya"
  },
  {
    "id": "7402332003",
    "district_id": "740233",
    "label": "Labotoy",
    "value": "Labotoy"
  },
  {
    "id": "7402332004",
    "district_id": "740233",
    "label": "Muara Sampara",
    "value": "Muara Sampara"
  },
  {
    "id": "7402332005",
    "district_id": "740233",
    "label": "Sambaraasi",
    "value": "Sambaraasi"
  },
  {
    "id": "7402332006",
    "district_id": "740233",
    "label": "Lamendora",
    "value": "Lamendora"
  },
  {
    "id": "7402332007",
    "district_id": "740233",
    "label": "Tani Indah",
    "value": "Tani Indah"
  },
  {
    "id": "7402332008",
    "district_id": "740233",
    "label": "Pereoe",
    "value": "Pereoe"
  },
  {
    "id": "7402332009",
    "district_id": "740233",
    "label": "Ulu Lalimbue",
    "value": "Ulu Lalimbue"
  },
  {
    "id": "7402332010",
    "district_id": "740233",
    "label": "Lalimbue",
    "value": "Lalimbue"
  },
  {
    "id": "7402332011",
    "district_id": "740233",
    "label": "Tombawatu",
    "value": "Tombawatu"
  },
  {
    "id": "7402332012",
    "district_id": "740233",
    "label": "Lalonggombuno",
    "value": "Lalonggombuno"
  },
  {
    "id": "7402332013",
    "district_id": "740233",
    "label": "Kapoiala Baru",
    "value": "Kapoiala Baru"
  },
  {
    "id": "7402332014",
    "district_id": "740233",
    "label": "Labotoy Jaya",
    "value": "Labotoy Jaya"
  },
  {
    "id": "7402361009",
    "district_id": "740236",
    "label": "Watunggarandu",
    "value": "Watunggarandu"
  },
  {
    "id": "7402362001",
    "district_id": "740236",
    "label": "Toli Toli",
    "value": "Toli Toli"
  },
  {
    "id": "7402362002",
    "district_id": "740236",
    "label": "Nii Tanasa",
    "value": "Nii Tanasa"
  },
  {
    "id": "7402362003",
    "district_id": "740236",
    "label": "Rapambinopaka",
    "value": "Rapambinopaka"
  },
  {
    "id": "7402362004",
    "district_id": "740236",
    "label": "Lalombonda",
    "value": "Lalombonda"
  },
  {
    "id": "7402362005",
    "district_id": "740236",
    "label": "Puuwonua",
    "value": "Puuwonua"
  },
  {
    "id": "7402362006",
    "district_id": "740236",
    "label": "Bumi Indah",
    "value": "Bumi Indah"
  },
  {
    "id": "7402362007",
    "district_id": "740236",
    "label": "Lolanggasumeeto",
    "value": "Lolanggasumeeto"
  },
  {
    "id": "7402362008",
    "district_id": "740236",
    "label": "Toolawawo",
    "value": "Toolawawo"
  },
  {
    "id": "7402362010",
    "district_id": "740236",
    "label": "Wawobungi",
    "value": "Wawobungi"
  },
  {
    "id": "7402362011",
    "district_id": "740236",
    "label": "Batu Gong",
    "value": "Batu Gong"
  },
  {
    "id": "7402362012",
    "district_id": "740236",
    "label": "Watunggarandu",
    "value": "Watunggarandu"
  },
  {
    "id": "7402371001",
    "district_id": "740237",
    "label": "Onembute",
    "value": "Onembute"
  },
  {
    "id": "7402372002",
    "district_id": "740237",
    "label": "Trimulya",
    "value": "Trimulya"
  },
  {
    "id": "7402372003",
    "district_id": "740237",
    "label": "Nonoosi",
    "value": "Nonoosi"
  },
  {
    "id": "7402372004",
    "district_id": "740237",
    "label": "Silea",
    "value": "Silea"
  },
  {
    "id": "7402372005",
    "district_id": "740237",
    "label": "Ulumeraka",
    "value": "Ulumeraka"
  },
  {
    "id": "7402372006",
    "district_id": "740237",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7402372007",
    "district_id": "740237",
    "label": "Kumapo",
    "value": "Kumapo"
  },
  {
    "id": "7402372008",
    "district_id": "740237",
    "label": "Kasumeia",
    "value": "Kasumeia"
  },
  {
    "id": "7402372009",
    "district_id": "740237",
    "label": "Ulu Onembute",
    "value": "Ulu Onembute"
  },
  {
    "id": "7402372010",
    "district_id": "740237",
    "label": "Tawapandere",
    "value": "Tawapandere"
  },
  {
    "id": "7402372011",
    "district_id": "740237",
    "label": "Anggaloosi",
    "value": "Anggaloosi"
  },
  {
    "id": "7402372012",
    "district_id": "740237",
    "label": "Ana Onembute",
    "value": "Ana Onembute"
  },
  {
    "id": "7402382001",
    "district_id": "740238",
    "label": "Atodopi",
    "value": "Atodopi"
  },
  {
    "id": "7402382002",
    "district_id": "740238",
    "label": "Matahori",
    "value": "Matahori"
  },
  {
    "id": "7402382003",
    "district_id": "740238",
    "label": "Sambaosu",
    "value": "Sambaosu"
  },
  {
    "id": "7402382004",
    "district_id": "740238",
    "label": "Matanggorai",
    "value": "Matanggorai"
  },
  {
    "id": "7402382005",
    "district_id": "740238",
    "label": "Alosika",
    "value": "Alosika"
  },
  {
    "id": "7402382006",
    "district_id": "740238",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7402382007",
    "district_id": "740238",
    "label": "Langgea",
    "value": "Langgea"
  },
  {
    "id": "7402382008",
    "district_id": "740238",
    "label": "Aleuti",
    "value": "Aleuti"
  },
  {
    "id": "7402382009",
    "district_id": "740238",
    "label": "Garuda",
    "value": "Garuda"
  },
  {
    "id": "7402382010",
    "district_id": "740238",
    "label": "Padangguni",
    "value": "Padangguni"
  },
  {
    "id": "7402382011",
    "district_id": "740238",
    "label": "Padang Mekar",
    "value": "Padang Mekar"
  },
  {
    "id": "7402392001",
    "district_id": "740239",
    "label": "Mendikonu",
    "value": "Mendikonu"
  },
  {
    "id": "7402392002",
    "district_id": "740239",
    "label": "Wonua Morini",
    "value": "Wonua Morini"
  },
  {
    "id": "7402392003",
    "district_id": "740239",
    "label": "Besu",
    "value": "Besu"
  },
  {
    "id": "7402392004",
    "district_id": "740239",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "7402392005",
    "district_id": "740239",
    "label": "Puuruy",
    "value": "Puuruy"
  },
  {
    "id": "7402392006",
    "district_id": "740239",
    "label": "Morosi",
    "value": "Morosi"
  },
  {
    "id": "7402392007",
    "district_id": "740239",
    "label": "Tanggobu",
    "value": "Tanggobu"
  },
  {
    "id": "7402392008",
    "district_id": "740239",
    "label": "Paku Jaya",
    "value": "Paku Jaya"
  },
  {
    "id": "7402392009",
    "district_id": "740239",
    "label": "Tondowatu",
    "value": "Tondowatu"
  },
  {
    "id": "7402392010",
    "district_id": "740239",
    "label": "Porara",
    "value": "Porara"
  },
  {
    "id": "7402402001",
    "district_id": "740240",
    "label": "Anggalomoare",
    "value": "Anggalomoare"
  },
  {
    "id": "7402402002",
    "district_id": "740240",
    "label": "Lasoso",
    "value": "Lasoso"
  },
  {
    "id": "7402402003",
    "district_id": "740240",
    "label": "Lakome",
    "value": "Lakome"
  },
  {
    "id": "7402402004",
    "district_id": "740240",
    "label": "Abelisawah",
    "value": "Abelisawah"
  },
  {
    "id": "7402402005",
    "district_id": "740240",
    "label": "Galu",
    "value": "Galu"
  },
  {
    "id": "7402402006",
    "district_id": "740240",
    "label": "Tabanggele",
    "value": "Tabanggele"
  },
  {
    "id": "7402402007",
    "district_id": "740240",
    "label": "Puusangi",
    "value": "Puusangi"
  },
  {
    "id": "7402402008",
    "district_id": "740240",
    "label": "Andobeu Jaya",
    "value": "Andobeu Jaya"
  },
  {
    "id": "7402402009",
    "district_id": "740240",
    "label": "Anggalomoare Jaya",
    "value": "Anggalomoare Jaya"
  },
  {
    "id": "7402402010",
    "district_id": "740240",
    "label": "Pusawa Jaya",
    "value": "Pusawa Jaya"
  },
  {
    "id": "7402412001",
    "district_id": "740241",
    "label": "Wonggeduku",
    "value": "Wonggeduku"
  },
  {
    "id": "7402412002",
    "district_id": "740241",
    "label": "Puday",
    "value": "Puday"
  },
  {
    "id": "7402412003",
    "district_id": "740241",
    "label": "Waturay",
    "value": "Waturay"
  },
  {
    "id": "7402412004",
    "district_id": "740241",
    "label": "Tobimeita",
    "value": "Tobimeita"
  },
  {
    "id": "7402412005",
    "district_id": "740241",
    "label": "Kasukia",
    "value": "Kasukia"
  },
  {
    "id": "7402412006",
    "district_id": "740241",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7402412007",
    "district_id": "740241",
    "label": "Teteona",
    "value": "Teteona"
  },
  {
    "id": "7402412008",
    "district_id": "740241",
    "label": "Ambuwiu",
    "value": "Ambuwiu"
  },
  {
    "id": "7402412009",
    "district_id": "740241",
    "label": "Linonggasay",
    "value": "Linonggasay"
  },
  {
    "id": "7402412010",
    "district_id": "740241",
    "label": "Lambangi",
    "value": "Lambangi"
  },
  {
    "id": "7402412011",
    "district_id": "740241",
    "label": "Lahotutu",
    "value": "Lahotutu"
  },
  {
    "id": "7402412012",
    "district_id": "740241",
    "label": "Lamokuni",
    "value": "Lamokuni"
  },
  {
    "id": "7402412013",
    "district_id": "740241",
    "label": "Ranotundobu",
    "value": "Ranotundobu"
  },
  {
    "id": "7402412014",
    "district_id": "740241",
    "label": "Anggadola",
    "value": "Anggadola"
  },
  {
    "id": "7403061004",
    "district_id": "740306",
    "label": "Napabalano",
    "value": "Napabalano"
  },
  {
    "id": "7403061005",
    "district_id": "740306",
    "label": "Tampo",
    "value": "Tampo"
  },
  {
    "id": "7403062003",
    "district_id": "740306",
    "label": "Napalakura",
    "value": "Napalakura"
  },
  {
    "id": "7403062006",
    "district_id": "740306",
    "label": "Pentiro",
    "value": "Pentiro"
  },
  {
    "id": "7403062007",
    "district_id": "740306",
    "label": "Langkumapo",
    "value": "Langkumapo"
  },
  {
    "id": "7403062009",
    "district_id": "740306",
    "label": "Lambiku",
    "value": "Lambiku"
  },
  {
    "id": "7403072001",
    "district_id": "740307",
    "label": "Langkoroni",
    "value": "Langkoroni"
  },
  {
    "id": "7403072002",
    "district_id": "740307",
    "label": "Latompa",
    "value": "Latompa"
  },
  {
    "id": "7403072003",
    "district_id": "740307",
    "label": "Raimuna",
    "value": "Raimuna"
  },
  {
    "id": "7403072004",
    "district_id": "740307",
    "label": "Maligano",
    "value": "Maligano"
  },
  {
    "id": "7403072005",
    "district_id": "740307",
    "label": "Lapole",
    "value": "Lapole"
  },
  {
    "id": "7403072007",
    "district_id": "740307",
    "label": "Pohorua",
    "value": "Pohorua"
  },
  {
    "id": "7403131003",
    "district_id": "740313",
    "label": "Labunia",
    "value": "Labunia"
  },
  {
    "id": "7403132001",
    "district_id": "740313",
    "label": "Wambona",
    "value": "Wambona"
  },
  {
    "id": "7403132004",
    "district_id": "740313",
    "label": "Bhakealu",
    "value": "Bhakealu"
  },
  {
    "id": "7403132005",
    "district_id": "740313",
    "label": "Pure",
    "value": "Pure"
  },
  {
    "id": "7403132006",
    "district_id": "740313",
    "label": "Wakorumba",
    "value": "Wakorumba"
  },
  {
    "id": "7403142001",
    "district_id": "740314",
    "label": "Bangun Sari",
    "value": "Bangun Sari"
  },
  {
    "id": "7403142002",
    "district_id": "740314",
    "label": "Labone",
    "value": "Labone"
  },
  {
    "id": "7403142003",
    "district_id": "740314",
    "label": "Bonea",
    "value": "Bonea"
  },
  {
    "id": "7403142004",
    "district_id": "740314",
    "label": "Kombungo",
    "value": "Kombungo"
  },
  {
    "id": "7403142005",
    "district_id": "740314",
    "label": "Labunti",
    "value": "Labunti"
  },
  {
    "id": "7403142006",
    "district_id": "740314",
    "label": "Parida",
    "value": "Parida"
  },
  {
    "id": "7403142007",
    "district_id": "740314",
    "label": "Lasalepa",
    "value": "Lasalepa"
  },
  {
    "id": "7403151003",
    "district_id": "740315",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "7403151004",
    "district_id": "740315",
    "label": "Laiworu",
    "value": "Laiworu"
  },
  {
    "id": "7403152001",
    "district_id": "740315",
    "label": "Wawesa",
    "value": "Wawesa"
  },
  {
    "id": "7403152002",
    "district_id": "740315",
    "label": "Wakorambu",
    "value": "Wakorambu"
  },
  {
    "id": "7403161001",
    "district_id": "740316",
    "label": "Watonea",
    "value": "Watonea"
  },
  {
    "id": "7403161002",
    "district_id": "740316",
    "label": "Raha III",
    "value": "Raha III"
  },
  {
    "id": "7403161003",
    "district_id": "740316",
    "label": "Wamponiki",
    "value": "Wamponiki"
  },
  {
    "id": "7403161004",
    "district_id": "740316",
    "label": "Butung Butung",
    "value": "Butung Butung"
  },
  {
    "id": "7403161005",
    "district_id": "740316",
    "label": "Raha II",
    "value": "Raha II"
  },
  {
    "id": "7403161006",
    "district_id": "740316",
    "label": "Foo Kuni",
    "value": "Foo Kuni"
  },
  {
    "id": "7403161007",
    "district_id": "740316",
    "label": "Laende",
    "value": "Laende"
  },
  {
    "id": "7403161008",
    "district_id": "740316",
    "label": "Raha I",
    "value": "Raha I"
  },
  {
    "id": "7403171002",
    "district_id": "740317",
    "label": "Palangga",
    "value": "Palangga"
  },
  {
    "id": "7403171005",
    "district_id": "740317",
    "label": "Wapunto",
    "value": "Wapunto"
  },
  {
    "id": "7403172001",
    "district_id": "740317",
    "label": "Ghonsume",
    "value": "Ghonsume"
  },
  {
    "id": "7403172003",
    "district_id": "740317",
    "label": "Lagasa",
    "value": "Lagasa"
  },
  {
    "id": "7403172004",
    "district_id": "740317",
    "label": "Ghone Bhalano",
    "value": "Ghone Bhalano"
  },
  {
    "id": "7403172006",
    "district_id": "740317",
    "label": "Lasunapa",
    "value": "Lasunapa"
  },
  {
    "id": "7403172007",
    "district_id": "740317",
    "label": "Banggai",
    "value": "Banggai"
  },
  {
    "id": "7403182001",
    "district_id": "740318",
    "label": "Liangkobori",
    "value": "Liangkobori"
  },
  {
    "id": "7403182002",
    "district_id": "740318",
    "label": "Maabholu",
    "value": "Maabholu"
  },
  {
    "id": "7403182003",
    "district_id": "740318",
    "label": "Kondongia",
    "value": "Kondongia"
  },
  {
    "id": "7403182004",
    "district_id": "740318",
    "label": "Waara",
    "value": "Waara"
  },
  {
    "id": "7403182005",
    "district_id": "740318",
    "label": "Mantobua",
    "value": "Mantobua"
  },
  {
    "id": "7403182006",
    "district_id": "740318",
    "label": "Loghiya",
    "value": "Loghiya"
  },
  {
    "id": "7403182007",
    "district_id": "740318",
    "label": "Lohia",
    "value": "Lohia"
  },
  {
    "id": "7403182008",
    "district_id": "740318",
    "label": "Korihi",
    "value": "Korihi"
  },
  {
    "id": "7403182009",
    "district_id": "740318",
    "label": "Lakarinta",
    "value": "Lakarinta"
  },
  {
    "id": "7403191004",
    "district_id": "740319",
    "label": "Dana",
    "value": "Dana"
  },
  {
    "id": "7403191007",
    "district_id": "740319",
    "label": "Wali",
    "value": "Wali"
  },
  {
    "id": "7403192001",
    "district_id": "740319",
    "label": "Lakapodo",
    "value": "Lakapodo"
  },
  {
    "id": "7403192002",
    "district_id": "740319",
    "label": "Wakadia",
    "value": "Wakadia"
  },
  {
    "id": "7403192003",
    "district_id": "740319",
    "label": "Matarawa",
    "value": "Matarawa"
  },
  {
    "id": "7403192005",
    "district_id": "740319",
    "label": "Bhangkali Barat",
    "value": "Bhangkali Barat"
  },
  {
    "id": "7403192006",
    "district_id": "740319",
    "label": "Bangkali",
    "value": "Bangkali"
  },
  {
    "id": "7403192008",
    "district_id": "740319",
    "label": "Labaha",
    "value": "Labaha"
  },
  {
    "id": "7403202001",
    "district_id": "740320",
    "label": "Liabalano",
    "value": "Liabalano"
  },
  {
    "id": "7403202002",
    "district_id": "740320",
    "label": "Lapodidi",
    "value": "Lapodidi"
  },
  {
    "id": "7403202003",
    "district_id": "740320",
    "label": "Bungi",
    "value": "Bungi"
  },
  {
    "id": "7403202004",
    "district_id": "740320",
    "label": "Kontunaga",
    "value": "Kontunaga"
  },
  {
    "id": "7403202005",
    "district_id": "740320",
    "label": "Maabhodo",
    "value": "Maabhodo"
  },
  {
    "id": "7403202006",
    "district_id": "740320",
    "label": "Masalili",
    "value": "Masalili"
  },
  {
    "id": "7403232001",
    "district_id": "740323",
    "label": "Komba Komba",
    "value": "Komba Komba"
  },
  {
    "id": "7403232002",
    "district_id": "740323",
    "label": "Kabangka",
    "value": "Kabangka"
  },
  {
    "id": "7403232003",
    "district_id": "740323",
    "label": "Wakobalu Agung",
    "value": "Wakobalu Agung"
  },
  {
    "id": "7403232004",
    "district_id": "740323",
    "label": "Lupia",
    "value": "Lupia"
  },
  {
    "id": "7403232007",
    "district_id": "740323",
    "label": "Sarimulyo",
    "value": "Sarimulyo"
  },
  {
    "id": "7403232008",
    "district_id": "740323",
    "label": "Oensuli",
    "value": "Oensuli"
  },
  {
    "id": "7403232009",
    "district_id": "740323",
    "label": "Wansugi",
    "value": "Wansugi"
  },
  {
    "id": "7403232010",
    "district_id": "740323",
    "label": "Wataliku",
    "value": "Wataliku"
  },
  {
    "id": "7403232011",
    "district_id": "740323",
    "label": "Lakandito",
    "value": "Lakandito"
  },
  {
    "id": "7403241009",
    "district_id": "740324",
    "label": "Laimpi",
    "value": "Laimpi"
  },
  {
    "id": "7403242001",
    "district_id": "740324",
    "label": "Lamanu",
    "value": "Lamanu"
  },
  {
    "id": "7403242002",
    "district_id": "740324",
    "label": "Kawite Wite",
    "value": "Kawite Wite"
  },
  {
    "id": "7403242003",
    "district_id": "740324",
    "label": "Bente",
    "value": "Bente"
  },
  {
    "id": "7403242004",
    "district_id": "740324",
    "label": "Bea",
    "value": "Bea"
  },
  {
    "id": "7403242006",
    "district_id": "740324",
    "label": "Rangka",
    "value": "Rangka"
  },
  {
    "id": "7403242010",
    "district_id": "740324",
    "label": "Kambawuna",
    "value": "Kambawuna"
  },
  {
    "id": "7403242011",
    "district_id": "740324",
    "label": "Kontumere",
    "value": "Kontumere"
  },
  {
    "id": "7403242012",
    "district_id": "740324",
    "label": "Lamaeo",
    "value": "Lamaeo"
  },
  {
    "id": "7403242013",
    "district_id": "740324",
    "label": "Wantiworo",
    "value": "Wantiworo"
  },
  {
    "id": "7403242014",
    "district_id": "740324",
    "label": "Kasaka",
    "value": "Kasaka"
  },
  {
    "id": "7403251005",
    "district_id": "740325",
    "label": "Wakumoro",
    "value": "Wakumoro"
  },
  {
    "id": "7403251006",
    "district_id": "740325",
    "label": "Walambeno Wite",
    "value": "Walambeno Wite"
  },
  {
    "id": "7403251007",
    "district_id": "740325",
    "label": "Kolasa",
    "value": "Kolasa"
  },
  {
    "id": "7403251009",
    "district_id": "740325",
    "label": "Wasolangka",
    "value": "Wasolangka"
  },
  {
    "id": "7403252001",
    "district_id": "740325",
    "label": "Labulu Bulu",
    "value": "Labulu Bulu"
  },
  {
    "id": "7403252002",
    "district_id": "740325",
    "label": "Latampu",
    "value": "Latampu"
  },
  {
    "id": "7403252003",
    "district_id": "740325",
    "label": "Laiba",
    "value": "Laiba"
  },
  {
    "id": "7403252004",
    "district_id": "740325",
    "label": "Warambe",
    "value": "Warambe"
  },
  {
    "id": "7403252008",
    "district_id": "740325",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "7403252010",
    "district_id": "740325",
    "label": "Wapuale",
    "value": "Wapuale"
  },
  {
    "id": "7403252012",
    "district_id": "740325",
    "label": "Kosundano",
    "value": "Kosundano"
  },
  {
    "id": "7403262006",
    "district_id": "740326",
    "label": "Bone Tondo",
    "value": "Bone Tondo"
  },
  {
    "id": "7403262007",
    "district_id": "740326",
    "label": "Bhone Kainsetala",
    "value": "Bhone Kainsetala"
  },
  {
    "id": "7403262008",
    "district_id": "740326",
    "label": "Bone Lolibu",
    "value": "Bone Lolibu"
  },
  {
    "id": "7403262009",
    "district_id": "740326",
    "label": "Oelongko",
    "value": "Oelongko"
  },
  {
    "id": "7403262010",
    "district_id": "740326",
    "label": "Matombura",
    "value": "Matombura"
  },
  {
    "id": "7403271005",
    "district_id": "740327",
    "label": "Danagoa",
    "value": "Danagoa"
  },
  {
    "id": "7403271007",
    "district_id": "740327",
    "label": "Kontumolepe",
    "value": "Kontumolepe"
  },
  {
    "id": "7403271008",
    "district_id": "740327",
    "label": "Tombula",
    "value": "Tombula"
  },
  {
    "id": "7403272003",
    "district_id": "740327",
    "label": "Lamorende",
    "value": "Lamorende"
  },
  {
    "id": "7403272004",
    "district_id": "740327",
    "label": "Laghontoghe",
    "value": "Laghontoghe"
  },
  {
    "id": "7403272006",
    "district_id": "740327",
    "label": "Lakologou",
    "value": "Lakologou"
  },
  {
    "id": "7403272009",
    "district_id": "740327",
    "label": "Laano Sandana",
    "value": "Laano Sandana"
  },
  {
    "id": "7403272014",
    "district_id": "740327",
    "label": "Oeno Kandoli",
    "value": "Oeno Kandoli"
  },
  {
    "id": "7403272015",
    "district_id": "740327",
    "label": "Walengkabhola",
    "value": "Walengkabhola"
  },
  {
    "id": "7403272016",
    "district_id": "740327",
    "label": "Fongkaniwa",
    "value": "Fongkaniwa"
  },
  {
    "id": "7403272017",
    "district_id": "740327",
    "label": "Matano Oe",
    "value": "Matano Oe"
  },
  {
    "id": "7403272019",
    "district_id": "740327",
    "label": "Kotano Wuna",
    "value": "Kotano Wuna"
  },
  {
    "id": "7403282001",
    "district_id": "740328",
    "label": "Kogholifano",
    "value": "Kogholifano"
  },
  {
    "id": "7403282002",
    "district_id": "740328",
    "label": "Pola",
    "value": "Pola"
  },
  {
    "id": "7403282007",
    "district_id": "740328",
    "label": "Kamosope",
    "value": "Kamosope"
  },
  {
    "id": "7403282008",
    "district_id": "740328",
    "label": "Liwumetingki",
    "value": "Liwumetingki"
  },
  {
    "id": "7403282009",
    "district_id": "740328",
    "label": "Labulawa",
    "value": "Labulawa"
  },
  {
    "id": "7403282010",
    "district_id": "740328",
    "label": "Bumbu",
    "value": "Bumbu"
  },
  {
    "id": "7403302001",
    "district_id": "740330",
    "label": "Kafo Foo",
    "value": "Kafo Foo"
  },
  {
    "id": "7403302002",
    "district_id": "740330",
    "label": "Karoo",
    "value": "Karoo"
  },
  {
    "id": "7403302003",
    "district_id": "740330",
    "label": "Kilambibito",
    "value": "Kilambibito"
  },
  {
    "id": "7403302004",
    "district_id": "740330",
    "label": "Bahutara",
    "value": "Bahutara"
  },
  {
    "id": "7403302005",
    "district_id": "740330",
    "label": "Kontu Kowuna",
    "value": "Kontu Kowuna"
  },
  {
    "id": "7403302006",
    "district_id": "740330",
    "label": "Laghorio",
    "value": "Laghorio"
  },
  {
    "id": "7403312001",
    "district_id": "740331",
    "label": "Marobo",
    "value": "Marobo"
  },
  {
    "id": "7403312002",
    "district_id": "740331",
    "label": "Wadolao",
    "value": "Wadolao"
  },
  {
    "id": "7403312003",
    "district_id": "740331",
    "label": "Pasikuta",
    "value": "Pasikuta"
  },
  {
    "id": "7403312004",
    "district_id": "740331",
    "label": "Tapitapi",
    "value": "Tapitapi"
  },
  {
    "id": "7403312005",
    "district_id": "740331",
    "label": "Poaroha",
    "value": "Poaroha"
  },
  {
    "id": "7403321001",
    "district_id": "740332",
    "label": "Katumpu",
    "value": "Katumpu"
  },
  {
    "id": "7403322002",
    "district_id": "740332",
    "label": "Waale-ale",
    "value": "Waale-ale"
  },
  {
    "id": "7403322003",
    "district_id": "740332",
    "label": "Kulidawa",
    "value": "Kulidawa"
  },
  {
    "id": "7403322004",
    "district_id": "740332",
    "label": "Labasa",
    "value": "Labasa"
  },
  {
    "id": "7403322005",
    "district_id": "740332",
    "label": "Owe Waungka",
    "value": "Owe Waungka"
  },
  {
    "id": "7403322006",
    "district_id": "740332",
    "label": "Lianosa",
    "value": "Lianosa"
  },
  {
    "id": "7403332001",
    "district_id": "740333",
    "label": "Kolese",
    "value": "Kolese"
  },
  {
    "id": "7403332002",
    "district_id": "740333",
    "label": "Tampunabale",
    "value": "Tampunabale"
  },
  {
    "id": "7403332003",
    "district_id": "740333",
    "label": "Mata Indaha",
    "value": "Mata Indaha"
  },
  {
    "id": "7403332004",
    "district_id": "740333",
    "label": "Lambelu",
    "value": "Lambelu"
  },
  {
    "id": "7403342001",
    "district_id": "740334",
    "label": "Bone Bone",
    "value": "Bone Bone"
  },
  {
    "id": "7403342002",
    "district_id": "740334",
    "label": "Lanobake",
    "value": "Lanobake"
  },
  {
    "id": "7403342003",
    "district_id": "740334",
    "label": "Moolo",
    "value": "Moolo"
  },
  {
    "id": "7403342004",
    "district_id": "740334",
    "label": "Baluara",
    "value": "Baluara"
  },
  {
    "id": "7403372001",
    "district_id": "740337",
    "label": "Wangkolabu",
    "value": "Wangkolabu"
  },
  {
    "id": "7403372002",
    "district_id": "740337",
    "label": "Lakarama",
    "value": "Lakarama"
  },
  {
    "id": "7403372003",
    "district_id": "740337",
    "label": "Moasi",
    "value": "Moasi"
  },
  {
    "id": "7403372004",
    "district_id": "740337",
    "label": "Renda",
    "value": "Renda"
  },
  {
    "id": "7403372005",
    "district_id": "740337",
    "label": "Bhontu-Bhontu",
    "value": "Bhontu-Bhontu"
  },
  {
    "id": "7404111005",
    "district_id": "740411",
    "label": "Wakoko",
    "value": "Wakoko"
  },
  {
    "id": "7404111006",
    "district_id": "740411",
    "label": "Wasaga",
    "value": "Wasaga"
  },
  {
    "id": "7404111020",
    "district_id": "740411",
    "label": "Holimombo",
    "value": "Holimombo"
  },
  {
    "id": "7404111021",
    "district_id": "740411",
    "label": "Kombeli",
    "value": "Kombeli"
  },
  {
    "id": "7404111022",
    "district_id": "740411",
    "label": "Takimpo",
    "value": "Takimpo"
  },
  {
    "id": "7404111023",
    "district_id": "740411",
    "label": "Awainulu",
    "value": "Awainulu"
  },
  {
    "id": "7404111026",
    "district_id": "740411",
    "label": "Kambulabulana",
    "value": "Kambulabulana"
  },
  {
    "id": "7404111027",
    "district_id": "740411",
    "label": "Pasarwajo",
    "value": "Pasarwajo"
  },
  {
    "id": "7404111028",
    "district_id": "740411",
    "label": "Saragi",
    "value": "Saragi"
  },
  {
    "id": "7404112002",
    "district_id": "740411",
    "label": "Waangu-angu",
    "value": "Waangu-angu"
  },
  {
    "id": "7404112003",
    "district_id": "740411",
    "label": "Warinta",
    "value": "Warinta"
  },
  {
    "id": "7404112004",
    "district_id": "740411",
    "label": "Lapodi",
    "value": "Lapodi"
  },
  {
    "id": "7404112007",
    "district_id": "740411",
    "label": "Kancinaa",
    "value": "Kancinaa"
  },
  {
    "id": "7404112008",
    "district_id": "740411",
    "label": "Winning",
    "value": "Winning"
  },
  {
    "id": "7404112017",
    "district_id": "740411",
    "label": "Holimombo Jaya",
    "value": "Holimombo Jaya"
  },
  {
    "id": "7404112018",
    "district_id": "740411",
    "label": "Kondowa",
    "value": "Kondowa"
  },
  {
    "id": "7404112019",
    "district_id": "740411",
    "label": "Dongkala",
    "value": "Dongkala"
  },
  {
    "id": "7404112024",
    "district_id": "740411",
    "label": "Laburunci",
    "value": "Laburunci"
  },
  {
    "id": "7404112025",
    "district_id": "740411",
    "label": "Banabungi",
    "value": "Banabungi"
  },
  {
    "id": "7404112029",
    "district_id": "740411",
    "label": "Kabawakole",
    "value": "Kabawakole"
  },
  {
    "id": "7404112030",
    "district_id": "740411",
    "label": "Mantowu",
    "value": "Mantowu"
  },
  {
    "id": "7404112031",
    "district_id": "740411",
    "label": "Kaongkeongkea",
    "value": "Kaongkeongkea"
  },
  {
    "id": "7404221005",
    "district_id": "740422",
    "label": "Watumotobe",
    "value": "Watumotobe"
  },
  {
    "id": "7404221006",
    "district_id": "740422",
    "label": "Wakangka",
    "value": "Wakangka"
  },
  {
    "id": "7404222001",
    "district_id": "740422",
    "label": "Barangka",
    "value": "Barangka"
  },
  {
    "id": "7404222002",
    "district_id": "740422",
    "label": "Wakalambe",
    "value": "Wakalambe"
  },
  {
    "id": "7404222003",
    "district_id": "740422",
    "label": "Boneatiro",
    "value": "Boneatiro"
  },
  {
    "id": "7404222004",
    "district_id": "740422",
    "label": "Lambusango",
    "value": "Lambusango"
  },
  {
    "id": "7404222007",
    "district_id": "740422",
    "label": "Todanga",
    "value": "Todanga"
  },
  {
    "id": "7404222008",
    "district_id": "740422",
    "label": "Tuangila",
    "value": "Tuangila"
  },
  {
    "id": "7404222009",
    "district_id": "740422",
    "label": "Waondo Wolio",
    "value": "Waondo Wolio"
  },
  {
    "id": "7404222010",
    "district_id": "740422",
    "label": "Bukit Asri",
    "value": "Bukit Asri"
  },
  {
    "id": "7404222011",
    "district_id": "740422",
    "label": "Kamelanta",
    "value": "Kamelanta"
  },
  {
    "id": "7404222012",
    "district_id": "740422",
    "label": "Lambusango Timur",
    "value": "Lambusango Timur"
  },
  {
    "id": "7404222013",
    "district_id": "740422",
    "label": "Wakuli",
    "value": "Wakuli"
  },
  {
    "id": "7404222014",
    "district_id": "740422",
    "label": "Mabulugo",
    "value": "Mabulugo"
  },
  {
    "id": "7404222015",
    "district_id": "740422",
    "label": "Wambulu",
    "value": "Wambulu"
  },
  {
    "id": "7404222016",
    "district_id": "740422",
    "label": "Tumada",
    "value": "Tumada"
  },
  {
    "id": "7404222017",
    "district_id": "740422",
    "label": "Boneatiro Barat",
    "value": "Boneatiro Barat"
  },
  {
    "id": "7404231005",
    "district_id": "740423",
    "label": "Kamaru",
    "value": "Kamaru"
  },
  {
    "id": "7404232001",
    "district_id": "740423",
    "label": "Kakenauwe",
    "value": "Kakenauwe"
  },
  {
    "id": "7404232002",
    "district_id": "740423",
    "label": "Waoleona",
    "value": "Waoleona"
  },
  {
    "id": "7404232003",
    "district_id": "740423",
    "label": "Lawele",
    "value": "Lawele"
  },
  {
    "id": "7404232004",
    "district_id": "740423",
    "label": "Suandala",
    "value": "Suandala"
  },
  {
    "id": "7404232006",
    "district_id": "740423",
    "label": "Lasembangi",
    "value": "Lasembangi"
  },
  {
    "id": "7404232007",
    "district_id": "740423",
    "label": "Bonelalo",
    "value": "Bonelalo"
  },
  {
    "id": "7404232008",
    "district_id": "740423",
    "label": "Wasuamba",
    "value": "Wasuamba"
  },
  {
    "id": "7404232009",
    "district_id": "740423",
    "label": "Wagari",
    "value": "Wagari"
  },
  {
    "id": "7404232010",
    "district_id": "740423",
    "label": "Sribatara",
    "value": "Sribatara"
  },
  {
    "id": "7404232011",
    "district_id": "740423",
    "label": "Togomangura",
    "value": "Togomangura"
  },
  {
    "id": "7404232012",
    "district_id": "740423",
    "label": "Wasambaa",
    "value": "Wasambaa"
  },
  {
    "id": "7404232013",
    "district_id": "740423",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "7404232014",
    "district_id": "740423",
    "label": "Talaga Baru",
    "value": "Talaga Baru"
  },
  {
    "id": "7404232015",
    "district_id": "740423",
    "label": "Nambo",
    "value": "Nambo"
  },
  {
    "id": "7404242006",
    "district_id": "740424",
    "label": "Ambuau Indah",
    "value": "Ambuau Indah"
  },
  {
    "id": "7404242007",
    "district_id": "740424",
    "label": "Kinapani Makmur",
    "value": "Kinapani Makmur"
  },
  {
    "id": "7404242008",
    "district_id": "740424",
    "label": "Mopaano",
    "value": "Mopaano"
  },
  {
    "id": "7404242009",
    "district_id": "740424",
    "label": "Umalaoge",
    "value": "Umalaoge"
  },
  {
    "id": "7404242010",
    "district_id": "740424",
    "label": "Lasalimu",
    "value": "Lasalimu"
  },
  {
    "id": "7404242011",
    "district_id": "740424",
    "label": "Wajah Jaya",
    "value": "Wajah Jaya"
  },
  {
    "id": "7404242012",
    "district_id": "740424",
    "label": "Siontapina",
    "value": "Siontapina"
  },
  {
    "id": "7404242013",
    "district_id": "740424",
    "label": "Siomanuru",
    "value": "Siomanuru"
  },
  {
    "id": "7404242016",
    "district_id": "740424",
    "label": "Mulya Jaya",
    "value": "Mulya Jaya"
  },
  {
    "id": "7404242017",
    "district_id": "740424",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "7404242018",
    "district_id": "740424",
    "label": "Sangia Arano",
    "value": "Sangia Arano"
  },
  {
    "id": "7404242019",
    "district_id": "740424",
    "label": "Megabahari",
    "value": "Megabahari"
  },
  {
    "id": "7404242020",
    "district_id": "740424",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "7404242021",
    "district_id": "740424",
    "label": "Rejo Sari",
    "value": "Rejo Sari"
  },
  {
    "id": "7404242022",
    "district_id": "740424",
    "label": "Ambuau Togo",
    "value": "Ambuau Togo"
  },
  {
    "id": "7404242023",
    "district_id": "740424",
    "label": "Balimu",
    "value": "Balimu"
  },
  {
    "id": "7404272001",
    "district_id": "740427",
    "label": "Walompo",
    "value": "Walompo"
  },
  {
    "id": "7404272002",
    "district_id": "740427",
    "label": "Matanauwe",
    "value": "Matanauwe"
  },
  {
    "id": "7404272003",
    "district_id": "740427",
    "label": "Sampuabalo",
    "value": "Sampuabalo"
  },
  {
    "id": "7404272004",
    "district_id": "740427",
    "label": "Kuraa",
    "value": "Kuraa"
  },
  {
    "id": "7404272005",
    "district_id": "740427",
    "label": "Kumbewaha",
    "value": "Kumbewaha"
  },
  {
    "id": "7404272006",
    "district_id": "740427",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "7404272007",
    "district_id": "740427",
    "label": "Labuandiri",
    "value": "Labuandiri"
  },
  {
    "id": "7404272008",
    "district_id": "740427",
    "label": "Karya Jaya",
    "value": "Karya Jaya"
  },
  {
    "id": "7404272009",
    "district_id": "740427",
    "label": "Manuru",
    "value": "Manuru"
  },
  {
    "id": "7404272010",
    "district_id": "740427",
    "label": "Gunungjaya",
    "value": "Gunungjaya"
  },
  {
    "id": "7404272011",
    "district_id": "740427",
    "label": "Bahari Makmur",
    "value": "Bahari Makmur"
  },
  {
    "id": "7404282001",
    "district_id": "740428",
    "label": "Kaumbu",
    "value": "Kaumbu"
  },
  {
    "id": "7404282002",
    "district_id": "740428",
    "label": "Wolowa",
    "value": "Wolowa"
  },
  {
    "id": "7404282003",
    "district_id": "740428",
    "label": "Matawia",
    "value": "Matawia"
  },
  {
    "id": "7404282004",
    "district_id": "740428",
    "label": "Wolowa Baru",
    "value": "Wolowa Baru"
  },
  {
    "id": "7404282005",
    "district_id": "740428",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "7404282006",
    "district_id": "740428",
    "label": "Bungi",
    "value": "Bungi"
  },
  {
    "id": "7404282007",
    "district_id": "740428",
    "label": "Galanti",
    "value": "Galanti"
  },
  {
    "id": "7404292001",
    "district_id": "740429",
    "label": "Wasuemba",
    "value": "Wasuemba"
  },
  {
    "id": "7404292002",
    "district_id": "740429",
    "label": "Wabula",
    "value": "Wabula"
  },
  {
    "id": "7404292003",
    "district_id": "740429",
    "label": "Wasampela",
    "value": "Wasampela"
  },
  {
    "id": "7404292004",
    "district_id": "740429",
    "label": "Holimombo",
    "value": "Holimombo"
  },
  {
    "id": "7404292005",
    "district_id": "740429",
    "label": "Koholimembona",
    "value": "Koholimembona"
  },
  {
    "id": "7404292006",
    "district_id": "740429",
    "label": "Wabula Satu",
    "value": "Wabula Satu"
  },
  {
    "id": "7404292007",
    "district_id": "740429",
    "label": "Bajo Bahari",
    "value": "Bajo Bahari"
  },
  {
    "id": "7405011001",
    "district_id": "740501",
    "label": "Tinanggea",
    "value": "Tinanggea"
  },
  {
    "id": "7405011002",
    "district_id": "740501",
    "label": "Ngapaah",
    "value": "Ngapaah"
  },
  {
    "id": "7405012003",
    "district_id": "740501",
    "label": "Lapoa",
    "value": "Lapoa"
  },
  {
    "id": "7405012006",
    "district_id": "740501",
    "label": "Asingi",
    "value": "Asingi"
  },
  {
    "id": "7405012007",
    "district_id": "740501",
    "label": "Watu Melewe",
    "value": "Watu Melewe"
  },
  {
    "id": "7405012009",
    "district_id": "740501",
    "label": "Wadonggo",
    "value": "Wadonggo"
  },
  {
    "id": "7405012010",
    "district_id": "740501",
    "label": "Bungin Permai",
    "value": "Bungin Permai"
  },
  {
    "id": "7405012014",
    "district_id": "740501",
    "label": "Palotawo",
    "value": "Palotawo"
  },
  {
    "id": "7405012019",
    "district_id": "740501",
    "label": "Lalonggasu",
    "value": "Lalonggasu"
  },
  {
    "id": "7405012020",
    "district_id": "740501",
    "label": "Moolo Indah",
    "value": "Moolo Indah"
  },
  {
    "id": "7405012021",
    "district_id": "740501",
    "label": "Telutu Jaya",
    "value": "Telutu Jaya"
  },
  {
    "id": "7405012023",
    "district_id": "740501",
    "label": "Torokeku",
    "value": "Torokeku"
  },
  {
    "id": "7405012024",
    "district_id": "740501",
    "label": "Lapulu",
    "value": "Lapulu"
  },
  {
    "id": "7405012027",
    "district_id": "740501",
    "label": "Roraya",
    "value": "Roraya"
  },
  {
    "id": "7405012029",
    "district_id": "740501",
    "label": "Lanowulu",
    "value": "Lanowulu"
  },
  {
    "id": "7405012030",
    "district_id": "740501",
    "label": "Lalo Watu",
    "value": "Lalo Watu"
  },
  {
    "id": "7405012032",
    "district_id": "740501",
    "label": "Tatangge",
    "value": "Tatangge"
  },
  {
    "id": "7405012033",
    "district_id": "740501",
    "label": "Wundumbolo",
    "value": "Wundumbolo"
  },
  {
    "id": "7405012034",
    "district_id": "740501",
    "label": "Panggoosi",
    "value": "Panggoosi"
  },
  {
    "id": "7405012035",
    "district_id": "740501",
    "label": "Bomba-bomba",
    "value": "Bomba-bomba"
  },
  {
    "id": "7405012036",
    "district_id": "740501",
    "label": "Akuni",
    "value": "Akuni"
  },
  {
    "id": "7405012037",
    "district_id": "740501",
    "label": "Lasuai",
    "value": "Lasuai"
  },
  {
    "id": "7405012038",
    "district_id": "740501",
    "label": "Matambawi",
    "value": "Matambawi"
  },
  {
    "id": "7405012039",
    "district_id": "740501",
    "label": "Matandahi",
    "value": "Matandahi"
  },
  {
    "id": "7405022001",
    "district_id": "740502",
    "label": "Pewutaa",
    "value": "Pewutaa"
  },
  {
    "id": "7405022002",
    "district_id": "740502",
    "label": "Aopa",
    "value": "Aopa"
  },
  {
    "id": "7405022003",
    "district_id": "740502",
    "label": "Motaha",
    "value": "Motaha"
  },
  {
    "id": "7405022004",
    "district_id": "740502",
    "label": "Lamoen",
    "value": "Lamoen"
  },
  {
    "id": "7405022005",
    "district_id": "740502",
    "label": "Puusanggula",
    "value": "Puusanggula"
  },
  {
    "id": "7405022006",
    "district_id": "740502",
    "label": "Puao",
    "value": "Puao"
  },
  {
    "id": "7405022007",
    "district_id": "740502",
    "label": "Sandey",
    "value": "Sandey"
  },
  {
    "id": "7405022008",
    "district_id": "740502",
    "label": "Teteasa",
    "value": "Teteasa"
  },
  {
    "id": "7405022009",
    "district_id": "740502",
    "label": "Simbangu",
    "value": "Simbangu"
  },
  {
    "id": "7405022010",
    "district_id": "740502",
    "label": "Lamooso",
    "value": "Lamooso"
  },
  {
    "id": "7405022011",
    "district_id": "740502",
    "label": "Landabaro",
    "value": "Landabaro"
  },
  {
    "id": "7405022012",
    "district_id": "740502",
    "label": "Puungoni",
    "value": "Puungoni"
  },
  {
    "id": "7405022013",
    "district_id": "740502",
    "label": "Sandarsi Jaya",
    "value": "Sandarsi Jaya"
  },
  {
    "id": "7405022014",
    "district_id": "740502",
    "label": "Puuroe",
    "value": "Puuroe"
  },
  {
    "id": "7405022015",
    "district_id": "740502",
    "label": "Lamoeri",
    "value": "Lamoeri"
  },
  {
    "id": "7405022016",
    "district_id": "740502",
    "label": "Kasebo",
    "value": "Kasebo"
  },
  {
    "id": "7405022017",
    "district_id": "740502",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7405022018",
    "district_id": "740502",
    "label": "Angata",
    "value": "Angata"
  },
  {
    "id": "7405022019",
    "district_id": "740502",
    "label": "Pudambu",
    "value": "Pudambu"
  },
  {
    "id": "7405022020",
    "district_id": "740502",
    "label": "Matabondu",
    "value": "Matabondu"
  },
  {
    "id": "7405022040",
    "district_id": "740502",
    "label": "Mokoau",
    "value": "Mokoau"
  },
  {
    "id": "7405022041",
    "district_id": "740502",
    "label": "Boloso",
    "value": "Boloso"
  },
  {
    "id": "7405022042",
    "district_id": "740502",
    "label": "Puulipu",
    "value": "Puulipu"
  },
  {
    "id": "7405022043",
    "district_id": "740502",
    "label": "Langgea Indah",
    "value": "Langgea Indah"
  },
  {
    "id": "7405031002",
    "district_id": "740503",
    "label": "Alangga",
    "value": "Alangga"
  },
  {
    "id": "7405031018",
    "district_id": "740503",
    "label": "Potoro",
    "value": "Potoro"
  },
  {
    "id": "7405032001",
    "district_id": "740503",
    "label": "Andoolo",
    "value": "Andoolo"
  },
  {
    "id": "7405032004",
    "district_id": "740503",
    "label": "Alengge Agung",
    "value": "Alengge Agung"
  },
  {
    "id": "7405032010",
    "district_id": "740503",
    "label": "Bumi Raya",
    "value": "Bumi Raya"
  },
  {
    "id": "7405032014",
    "district_id": "740503",
    "label": "Lalobao",
    "value": "Lalobao"
  },
  {
    "id": "7405032015",
    "district_id": "740503",
    "label": "Lalonggombu",
    "value": "Lalonggombu"
  },
  {
    "id": "7405032026",
    "district_id": "740503",
    "label": "Wunduwatu",
    "value": "Wunduwatu"
  },
  {
    "id": "7405032031",
    "district_id": "740503",
    "label": "Punggapu",
    "value": "Punggapu"
  },
  {
    "id": "7405032033",
    "district_id": "740503",
    "label": "Ataku",
    "value": "Ataku"
  },
  {
    "id": "7405041001",
    "district_id": "740504",
    "label": "Palangga",
    "value": "Palangga"
  },
  {
    "id": "7405042003",
    "district_id": "740504",
    "label": "Aosole",
    "value": "Aosole"
  },
  {
    "id": "7405042005",
    "district_id": "740504",
    "label": "Anggondara",
    "value": "Anggondara"
  },
  {
    "id": "7405042007",
    "district_id": "740504",
    "label": "Eewa",
    "value": "Eewa"
  },
  {
    "id": "7405042008",
    "district_id": "740504",
    "label": "Kiaea",
    "value": "Kiaea"
  },
  {
    "id": "7405042011",
    "district_id": "740504",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "7405042013",
    "district_id": "740504",
    "label": "Onembute",
    "value": "Onembute"
  },
  {
    "id": "7405042018",
    "district_id": "740504",
    "label": "Waworaha",
    "value": "Waworaha"
  },
  {
    "id": "7405042019",
    "district_id": "740504",
    "label": "Watumerembe",
    "value": "Watumerembe"
  },
  {
    "id": "7405042022",
    "district_id": "740504",
    "label": "Wawouru",
    "value": "Wawouru"
  },
  {
    "id": "7405042025",
    "district_id": "740504",
    "label": "Alakaya",
    "value": "Alakaya"
  },
  {
    "id": "7405042029",
    "district_id": "740504",
    "label": "Wawonggura",
    "value": "Wawonggura"
  },
  {
    "id": "7405042030",
    "district_id": "740504",
    "label": "Kapujaya",
    "value": "Kapujaya"
  },
  {
    "id": "7405042031",
    "district_id": "740504",
    "label": "Watudemba",
    "value": "Watudemba"
  },
  {
    "id": "7405042032",
    "district_id": "740504",
    "label": "Sanggi-Sanggi",
    "value": "Sanggi-Sanggi"
  },
  {
    "id": "7405051001",
    "district_id": "740505",
    "label": "Landono",
    "value": "Landono"
  },
  {
    "id": "7405052008",
    "district_id": "740505",
    "label": "Lalonggapu",
    "value": "Lalonggapu"
  },
  {
    "id": "7405052009",
    "district_id": "740505",
    "label": "Matabenua / watawunua",
    "value": "Matabenua / watawunua"
  },
  {
    "id": "7405052014",
    "district_id": "740505",
    "label": "Tridana Mulia",
    "value": "Tridana Mulia"
  },
  {
    "id": "7405052015",
    "district_id": "740505",
    "label": "Landono Dua",
    "value": "Landono Dua"
  },
  {
    "id": "7405052016",
    "district_id": "740505",
    "label": "Amotowo",
    "value": "Amotowo"
  },
  {
    "id": "7405052022",
    "district_id": "740505",
    "label": "Abenggi",
    "value": "Abenggi"
  },
  {
    "id": "7405052023",
    "district_id": "740505",
    "label": "Arongo",
    "value": "Arongo"
  },
  {
    "id": "7405052024",
    "district_id": "740505",
    "label": "Endanga",
    "value": "Endanga"
  },
  {
    "id": "7405052028",
    "district_id": "740505",
    "label": "Wonua Koa",
    "value": "Wonua Koa"
  },
  {
    "id": "7405052032",
    "district_id": "740505",
    "label": "Wonua Sangia",
    "value": "Wonua Sangia"
  },
  {
    "id": "7405052035",
    "district_id": "740505",
    "label": "Lakomea",
    "value": "Lakomea"
  },
  {
    "id": "7405052042",
    "district_id": "740505",
    "label": "Morini Mulya",
    "value": "Morini Mulya"
  },
  {
    "id": "7405062011",
    "district_id": "740506",
    "label": "Lalonggombu",
    "value": "Lalonggombu"
  },
  {
    "id": "7405062013",
    "district_id": "740506",
    "label": "Watumeeto",
    "value": "Watumeeto"
  },
  {
    "id": "7405062014",
    "district_id": "740506",
    "label": "Pamandati",
    "value": "Pamandati"
  },
  {
    "id": "7405062015",
    "district_id": "740506",
    "label": "Pangan Jaya",
    "value": "Pangan Jaya"
  },
  {
    "id": "7405062020",
    "district_id": "740506",
    "label": "Aoroo",
    "value": "Aoroo"
  },
  {
    "id": "7405062021",
    "district_id": "740506",
    "label": "Kaindi",
    "value": "Kaindi"
  },
  {
    "id": "7405062022",
    "district_id": "740506",
    "label": "Matabubu Jaya",
    "value": "Matabubu Jaya"
  },
  {
    "id": "7405062024",
    "district_id": "740506",
    "label": "Lainea",
    "value": "Lainea"
  },
  {
    "id": "7405062025",
    "district_id": "740506",
    "label": "Molinese",
    "value": "Molinese"
  },
  {
    "id": "7405062026",
    "district_id": "740506",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7405062027",
    "district_id": "740506",
    "label": "Bangun Jaya",
    "value": "Bangun Jaya"
  },
  {
    "id": "7405062028",
    "district_id": "740506",
    "label": "Kalo-kalo",
    "value": "Kalo-kalo"
  },
  {
    "id": "7405071001",
    "district_id": "740507",
    "label": "Konda",
    "value": "Konda"
  },
  {
    "id": "7405072002",
    "district_id": "740507",
    "label": "Puosu Jaya",
    "value": "Puosu Jaya"
  },
  {
    "id": "7405072003",
    "district_id": "740507",
    "label": "Lamomea",
    "value": "Lamomea"
  },
  {
    "id": "7405072004",
    "district_id": "740507",
    "label": "Alebo",
    "value": "Alebo"
  },
  {
    "id": "7405072005",
    "district_id": "740507",
    "label": "Morome",
    "value": "Morome"
  },
  {
    "id": "7405072006",
    "district_id": "740507",
    "label": "Lebo Jaya",
    "value": "Lebo Jaya"
  },
  {
    "id": "7405072007",
    "district_id": "740507",
    "label": "Lambusa",
    "value": "Lambusa"
  },
  {
    "id": "7405072008",
    "district_id": "740507",
    "label": "Pambulsa Jaya",
    "value": "Pambulsa Jaya"
  },
  {
    "id": "7405072009",
    "district_id": "740507",
    "label": "Ambololi",
    "value": "Ambololi"
  },
  {
    "id": "7405072010",
    "district_id": "740507",
    "label": "Tanea",
    "value": "Tanea"
  },
  {
    "id": "7405072011",
    "district_id": "740507",
    "label": "Masagena",
    "value": "Masagena"
  },
  {
    "id": "7405072012",
    "district_id": "740507",
    "label": "Cialam Jaya",
    "value": "Cialam Jaya"
  },
  {
    "id": "7405072013",
    "district_id": "740507",
    "label": "Wonua",
    "value": "Wonua"
  },
  {
    "id": "7405072014",
    "district_id": "740507",
    "label": "Lawoila",
    "value": "Lawoila"
  },
  {
    "id": "7405072019",
    "district_id": "740507",
    "label": "Lalowiu",
    "value": "Lalowiu"
  },
  {
    "id": "7405072020",
    "district_id": "740507",
    "label": "Amohalo",
    "value": "Amohalo"
  },
  {
    "id": "7405072021",
    "district_id": "740507",
    "label": "Ranowila",
    "value": "Ranowila"
  },
  {
    "id": "7405072024",
    "district_id": "740507",
    "label": "Konda Satu",
    "value": "Konda Satu"
  },
  {
    "id": "7405081001",
    "district_id": "740508",
    "label": "Ranomeeto",
    "value": "Ranomeeto"
  },
  {
    "id": "7405082002",
    "district_id": "740508",
    "label": "Onewila",
    "value": "Onewila"
  },
  {
    "id": "7405082004",
    "district_id": "740508",
    "label": "Boro-Boro R",
    "value": "Boro-Boro R"
  },
  {
    "id": "7405082005",
    "district_id": "740508",
    "label": "Rambu-rambu Jaya",
    "value": "Rambu-rambu Jaya"
  },
  {
    "id": "7405082008",
    "district_id": "740508",
    "label": "Amoito",
    "value": "Amoito"
  },
  {
    "id": "7405082014",
    "district_id": "740508",
    "label": "Ambaipua",
    "value": "Ambaipua"
  },
  {
    "id": "7405082015",
    "district_id": "740508",
    "label": "Ranooha",
    "value": "Ranooha"
  },
  {
    "id": "7405082016",
    "district_id": "740508",
    "label": "Langgea",
    "value": "Langgea"
  },
  {
    "id": "7405082017",
    "district_id": "740508",
    "label": "Kota Bangun",
    "value": "Kota Bangun"
  },
  {
    "id": "7405082019",
    "district_id": "740508",
    "label": "Laikaha",
    "value": "Laikaha"
  },
  {
    "id": "7405082020",
    "district_id": "740508",
    "label": "Duduria",
    "value": "Duduria"
  },
  {
    "id": "7405082021",
    "district_id": "740508",
    "label": "Amoito Siama",
    "value": "Amoito Siama"
  },
  {
    "id": "7405091001",
    "district_id": "740509",
    "label": "Kolono",
    "value": "Kolono"
  },
  {
    "id": "7405092002",
    "district_id": "740509",
    "label": "Sawa",
    "value": "Sawa"
  },
  {
    "id": "7405092003",
    "district_id": "740509",
    "label": "Langgowala",
    "value": "Langgowala"
  },
  {
    "id": "7405092004",
    "district_id": "740509",
    "label": "Waworano",
    "value": "Waworano"
  },
  {
    "id": "7405092005",
    "district_id": "740509",
    "label": "Matandahi",
    "value": "Matandahi"
  },
  {
    "id": "7405092006",
    "district_id": "740509",
    "label": "Puupi",
    "value": "Puupi"
  },
  {
    "id": "7405092007",
    "district_id": "740509",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7405092008",
    "district_id": "740509",
    "label": "Tiraosu",
    "value": "Tiraosu"
  },
  {
    "id": "7405092009",
    "district_id": "740509",
    "label": "Mondoe Jaya",
    "value": "Mondoe Jaya"
  },
  {
    "id": "7405092010",
    "district_id": "740509",
    "label": "Ulusena Jaya",
    "value": "Ulusena Jaya"
  },
  {
    "id": "7405092011",
    "district_id": "740509",
    "label": "Lamotau",
    "value": "Lamotau"
  },
  {
    "id": "7405092012",
    "district_id": "740509",
    "label": "Awunio",
    "value": "Awunio"
  },
  {
    "id": "7405092013",
    "district_id": "740509",
    "label": "Meletumbo",
    "value": "Meletumbo"
  },
  {
    "id": "7405092014",
    "district_id": "740509",
    "label": "Roda",
    "value": "Roda"
  },
  {
    "id": "7405092016",
    "district_id": "740509",
    "label": "Lamapu",
    "value": "Lamapu"
  },
  {
    "id": "7405092025",
    "district_id": "740509",
    "label": "Andinete",
    "value": "Andinete"
  },
  {
    "id": "7405092026",
    "district_id": "740509",
    "label": "Wawoosu",
    "value": "Wawoosu"
  },
  {
    "id": "7405092028",
    "district_id": "740509",
    "label": "Alosi",
    "value": "Alosi"
  },
  {
    "id": "7405092029",
    "district_id": "740509",
    "label": "Silea",
    "value": "Silea"
  },
  {
    "id": "7405092030",
    "district_id": "740509",
    "label": "Puudongi",
    "value": "Puudongi"
  },
  {
    "id": "7405092031",
    "district_id": "740509",
    "label": "Sarandua",
    "value": "Sarandua"
  },
  {
    "id": "7405101001",
    "district_id": "740510",
    "label": "Lapuko",
    "value": "Lapuko"
  },
  {
    "id": "7405102010",
    "district_id": "740510",
    "label": "Amohola",
    "value": "Amohola"
  },
  {
    "id": "7405102011",
    "district_id": "740510",
    "label": "Tambosupa",
    "value": "Tambosupa"
  },
  {
    "id": "7405102012",
    "district_id": "740510",
    "label": "Marga Citta",
    "value": "Marga Citta"
  },
  {
    "id": "7405102013",
    "district_id": "740510",
    "label": "Moramo",
    "value": "Moramo"
  },
  {
    "id": "7405102014",
    "district_id": "740510",
    "label": "Bakutara",
    "value": "Bakutara"
  },
  {
    "id": "7405102015",
    "district_id": "740510",
    "label": "Lakomea",
    "value": "Lakomea"
  },
  {
    "id": "7405102016",
    "district_id": "740510",
    "label": "Landipo",
    "value": "Landipo"
  },
  {
    "id": "7405102017",
    "district_id": "740510",
    "label": "Lamboo",
    "value": "Lamboo"
  },
  {
    "id": "7405102018",
    "district_id": "740510",
    "label": "Pudaria Jaya",
    "value": "Pudaria Jaya"
  },
  {
    "id": "7405102019",
    "district_id": "740510",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "7405102020",
    "district_id": "740510",
    "label": "Ulusena",
    "value": "Ulusena"
  },
  {
    "id": "7405102021",
    "district_id": "740510",
    "label": "Penambea Barata",
    "value": "Penambea Barata"
  },
  {
    "id": "7405102022",
    "district_id": "740510",
    "label": "Wowosunggu",
    "value": "Wowosunggu"
  },
  {
    "id": "7405102023",
    "district_id": "740510",
    "label": "Wawondengi",
    "value": "Wawondengi"
  },
  {
    "id": "7405102025",
    "district_id": "740510",
    "label": "Watu Porambaa",
    "value": "Watu Porambaa"
  },
  {
    "id": "7405102026",
    "district_id": "740510",
    "label": "Wonua Jaya",
    "value": "Wonua Jaya"
  },
  {
    "id": "7405102027",
    "district_id": "740510",
    "label": "Bisikori",
    "value": "Bisikori"
  },
  {
    "id": "7405102028",
    "district_id": "740510",
    "label": "Ranooha Raya",
    "value": "Ranooha Raya"
  },
  {
    "id": "7405102029",
    "district_id": "740510",
    "label": "Selabangga",
    "value": "Selabangga"
  },
  {
    "id": "7405112001",
    "district_id": "740511",
    "label": "Labaun Beropa",
    "value": "Labaun Beropa"
  },
  {
    "id": "7405112002",
    "district_id": "740511",
    "label": "Tambolosu",
    "value": "Tambolosu"
  },
  {
    "id": "7405112003",
    "district_id": "740511",
    "label": "Woru Woru",
    "value": "Woru Woru"
  },
  {
    "id": "7405112004",
    "district_id": "740511",
    "label": "Tambeanga",
    "value": "Tambeanga"
  },
  {
    "id": "7405112005",
    "district_id": "740511",
    "label": "Labotaone",
    "value": "Labotaone"
  },
  {
    "id": "7405112006",
    "district_id": "740511",
    "label": "Batu Jaya",
    "value": "Batu Jaya"
  },
  {
    "id": "7405112007",
    "district_id": "740511",
    "label": "Namu",
    "value": "Namu"
  },
  {
    "id": "7405112008",
    "district_id": "740511",
    "label": "Malaringgi",
    "value": "Malaringgi"
  },
  {
    "id": "7405112009",
    "district_id": "740511",
    "label": "Tue Tue",
    "value": "Tue Tue"
  },
  {
    "id": "7405112010",
    "district_id": "740511",
    "label": "Sangi Sangi",
    "value": "Sangi Sangi"
  },
  {
    "id": "7405112011",
    "district_id": "740511",
    "label": "Ulu Sawa",
    "value": "Ulu Sawa"
  },
  {
    "id": "7405112012",
    "district_id": "740511",
    "label": "Lawisata",
    "value": "Lawisata"
  },
  {
    "id": "7405112013",
    "district_id": "740511",
    "label": "Kondono",
    "value": "Kondono"
  },
  {
    "id": "7405112014",
    "district_id": "740511",
    "label": "Peo Indah",
    "value": "Peo Indah"
  },
  {
    "id": "7405112015",
    "district_id": "740511",
    "label": "Puudirangga",
    "value": "Puudirangga"
  },
  {
    "id": "7405112016",
    "district_id": "740511",
    "label": "Cempedak",
    "value": "Cempedak"
  },
  {
    "id": "7405112017",
    "district_id": "740511",
    "label": "Laonti",
    "value": "Laonti"
  },
  {
    "id": "7405112018",
    "district_id": "740511",
    "label": "Rumbi-Rumbia",
    "value": "Rumbi-Rumbia"
  },
  {
    "id": "7405112020",
    "district_id": "740511",
    "label": "Wandaeha",
    "value": "Wandaeha"
  },
  {
    "id": "7405121005",
    "district_id": "740512",
    "label": "Atari Indah",
    "value": "Atari Indah"
  },
  {
    "id": "7405122001",
    "district_id": "740512",
    "label": "Lambodi Jaya",
    "value": "Lambodi Jaya"
  },
  {
    "id": "7405122002",
    "district_id": "740512",
    "label": "Atari Jaya",
    "value": "Atari Jaya"
  },
  {
    "id": "7405122003",
    "district_id": "740512",
    "label": "Mokupa Jaya",
    "value": "Mokupa Jaya"
  },
  {
    "id": "7405122004",
    "district_id": "740512",
    "label": "Lambandia",
    "value": "Lambandia"
  },
  {
    "id": "7405122006",
    "district_id": "740512",
    "label": "Kapuwila",
    "value": "Kapuwila"
  },
  {
    "id": "7405122007",
    "district_id": "740512",
    "label": "Puunangga",
    "value": "Puunangga"
  },
  {
    "id": "7405122008",
    "district_id": "740512",
    "label": "Teteinea",
    "value": "Teteinea"
  },
  {
    "id": "7405122009",
    "district_id": "740512",
    "label": "Puurema Subur",
    "value": "Puurema Subur"
  },
  {
    "id": "7405122010",
    "district_id": "740512",
    "label": "Lelembu Jaya",
    "value": "Lelembu Jaya"
  },
  {
    "id": "7405122011",
    "district_id": "740512",
    "label": "Sukamukti",
    "value": "Sukamukti"
  },
  {
    "id": "7405122012",
    "district_id": "740512",
    "label": "Potuho Jaya",
    "value": "Potuho Jaya"
  },
  {
    "id": "7405122013",
    "district_id": "740512",
    "label": "Mondoke",
    "value": "Mondoke"
  },
  {
    "id": "7405122014",
    "district_id": "740512",
    "label": "Sumber Jaya",
    "value": "Sumber Jaya"
  },
  {
    "id": "7405122015",
    "district_id": "740512",
    "label": "Merongga Raya",
    "value": "Merongga Raya"
  },
  {
    "id": "7405122016",
    "district_id": "740512",
    "label": "Tombeleu",
    "value": "Tombeleu"
  },
  {
    "id": "7405122017",
    "district_id": "740512",
    "label": "Padaleu",
    "value": "Padaleu"
  },
  {
    "id": "7405122018",
    "district_id": "740512",
    "label": "Lalouesamba",
    "value": "Lalouesamba"
  },
  {
    "id": "7405132001",
    "district_id": "740513",
    "label": "Lamara",
    "value": "Lamara"
  },
  {
    "id": "7405132002",
    "district_id": "740513",
    "label": "Puuosu",
    "value": "Puuosu"
  },
  {
    "id": "7405132003",
    "district_id": "740513",
    "label": "Benua Utama",
    "value": "Benua Utama"
  },
  {
    "id": "7405132004",
    "district_id": "740513",
    "label": "Horodopi",
    "value": "Horodopi"
  },
  {
    "id": "7405132005",
    "district_id": "740513",
    "label": "Benua",
    "value": "Benua"
  },
  {
    "id": "7405132006",
    "district_id": "740513",
    "label": "Palowewu",
    "value": "Palowewu"
  },
  {
    "id": "7405132007",
    "district_id": "740513",
    "label": "Punggawukawu",
    "value": "Punggawukawu"
  },
  {
    "id": "7405132008",
    "district_id": "740513",
    "label": "Uelawa",
    "value": "Uelawa"
  },
  {
    "id": "7405132009",
    "district_id": "740513",
    "label": "Tetehaka",
    "value": "Tetehaka"
  },
  {
    "id": "7405132010",
    "district_id": "740513",
    "label": "Awalo",
    "value": "Awalo"
  },
  {
    "id": "7405132011",
    "district_id": "740513",
    "label": "Kosambi",
    "value": "Kosambi"
  },
  {
    "id": "7405132012",
    "district_id": "740513",
    "label": "Puuwehuko",
    "value": "Puuwehuko"
  },
  {
    "id": "7405141001",
    "district_id": "740514",
    "label": "Amondo",
    "value": "Amondo"
  },
  {
    "id": "7405142002",
    "district_id": "740514",
    "label": "Lakara",
    "value": "Lakara"
  },
  {
    "id": "7405142003",
    "district_id": "740514",
    "label": "Parasi",
    "value": "Parasi"
  },
  {
    "id": "7405142004",
    "district_id": "740514",
    "label": "Ulu Lakara",
    "value": "Ulu Lakara"
  },
  {
    "id": "7405142005",
    "district_id": "740514",
    "label": "Watu Mbohoti",
    "value": "Watu Mbohoti"
  },
  {
    "id": "7405142006",
    "district_id": "740514",
    "label": "Wawo Wonua",
    "value": "Wawo Wonua"
  },
  {
    "id": "7405142007",
    "district_id": "740514",
    "label": "Watu Rapa",
    "value": "Watu Rapa"
  },
  {
    "id": "7405142008",
    "district_id": "740514",
    "label": "Koeono",
    "value": "Koeono"
  },
  {
    "id": "7405142009",
    "district_id": "740514",
    "label": "Lalowua",
    "value": "Lalowua"
  },
  {
    "id": "7405142010",
    "district_id": "740514",
    "label": "Mondoe",
    "value": "Mondoe"
  },
  {
    "id": "7405152001",
    "district_id": "740515",
    "label": "Kondoano",
    "value": "Kondoano"
  },
  {
    "id": "7405152002",
    "district_id": "740515",
    "label": "Punggulahi",
    "value": "Punggulahi"
  },
  {
    "id": "7405152003",
    "district_id": "740515",
    "label": "Lamolori",
    "value": "Lamolori"
  },
  {
    "id": "7405152004",
    "district_id": "740515",
    "label": "Toluwonua",
    "value": "Toluwonua"
  },
  {
    "id": "7405152005",
    "district_id": "740515",
    "label": "Mulyasari",
    "value": "Mulyasari"
  },
  {
    "id": "7405152006",
    "district_id": "740515",
    "label": "Rakawuta",
    "value": "Rakawuta"
  },
  {
    "id": "7405152007",
    "district_id": "740515",
    "label": "Ranoaopa",
    "value": "Ranoaopa"
  },
  {
    "id": "7405152008",
    "district_id": "740515",
    "label": "Mowila",
    "value": "Mowila"
  },
  {
    "id": "7405152009",
    "district_id": "740515",
    "label": "Lalosingi",
    "value": "Lalosingi"
  },
  {
    "id": "7405152010",
    "district_id": "740515",
    "label": "Wuura",
    "value": "Wuura"
  },
  {
    "id": "7405152011",
    "district_id": "740515",
    "label": "Pudahoa",
    "value": "Pudahoa"
  },
  {
    "id": "7405152012",
    "district_id": "740515",
    "label": "Monapa",
    "value": "Monapa"
  },
  {
    "id": "7405152013",
    "district_id": "740515",
    "label": "Puuwehuko",
    "value": "Puuwehuko"
  },
  {
    "id": "7405152014",
    "district_id": "740515",
    "label": "Ranombayasa",
    "value": "Ranombayasa"
  },
  {
    "id": "7405152015",
    "district_id": "740515",
    "label": "Wanuakongga",
    "value": "Wanuakongga"
  },
  {
    "id": "7405152016",
    "district_id": "740515",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7405152017",
    "district_id": "740515",
    "label": "Lamebara",
    "value": "Lamebara"
  },
  {
    "id": "7405152018",
    "district_id": "740515",
    "label": "Tetesingi",
    "value": "Tetesingi"
  },
  {
    "id": "7405152019",
    "district_id": "740515",
    "label": "Wonua Sari",
    "value": "Wonua Sari"
  },
  {
    "id": "7405152020",
    "district_id": "740515",
    "label": "Wonua Monapa",
    "value": "Wonua Monapa"
  },
  {
    "id": "7405161002",
    "district_id": "740516",
    "label": "Lalowaru",
    "value": "Lalowaru"
  },
  {
    "id": "7405162001",
    "district_id": "740516",
    "label": "Puasana",
    "value": "Puasana"
  },
  {
    "id": "7405162003",
    "district_id": "740516",
    "label": "Tanjung Tiram",
    "value": "Tanjung Tiram"
  },
  {
    "id": "7405162004",
    "district_id": "740516",
    "label": "Wawatu",
    "value": "Wawatu"
  },
  {
    "id": "7405162005",
    "district_id": "740516",
    "label": "Sanggula",
    "value": "Sanggula"
  },
  {
    "id": "7405162006",
    "district_id": "740516",
    "label": "Lombueya",
    "value": "Lombueya"
  },
  {
    "id": "7405162007",
    "district_id": "740516",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7405162008",
    "district_id": "740516",
    "label": "Lamokula",
    "value": "Lamokula"
  },
  {
    "id": "7405162009",
    "district_id": "740516",
    "label": "Mata Watu-Watu",
    "value": "Mata Watu-Watu"
  },
  {
    "id": "7405162010",
    "district_id": "740516",
    "label": "Mata Lamokula",
    "value": "Mata Lamokula"
  },
  {
    "id": "7405172001",
    "district_id": "740517",
    "label": "Andoolo Utama",
    "value": "Andoolo Utama"
  },
  {
    "id": "7405172002",
    "district_id": "740517",
    "label": "Anggokoti",
    "value": "Anggokoti"
  },
  {
    "id": "7405172003",
    "district_id": "740517",
    "label": "Asembu Mulya",
    "value": "Asembu Mulya"
  },
  {
    "id": "7405172004",
    "district_id": "740517",
    "label": "Awalo",
    "value": "Awalo"
  },
  {
    "id": "7405172005",
    "district_id": "740517",
    "label": "Adaka Jaya",
    "value": "Adaka Jaya"
  },
  {
    "id": "7405172006",
    "district_id": "740517",
    "label": "Buke",
    "value": "Buke"
  },
  {
    "id": "7405172007",
    "district_id": "740517",
    "label": "Pelandia",
    "value": "Pelandia"
  },
  {
    "id": "7405172008",
    "district_id": "740517",
    "label": "Puudaria",
    "value": "Puudaria"
  },
  {
    "id": "7405172009",
    "district_id": "740517",
    "label": "Rahamendaa",
    "value": "Rahamendaa"
  },
  {
    "id": "7405172010",
    "district_id": "740517",
    "label": "Silea Jaya",
    "value": "Silea Jaya"
  },
  {
    "id": "7405172011",
    "district_id": "740517",
    "label": "Tirto Martani",
    "value": "Tirto Martani"
  },
  {
    "id": "7405172012",
    "district_id": "740517",
    "label": "Tetenggolasa",
    "value": "Tetenggolasa"
  },
  {
    "id": "7405172013",
    "district_id": "740517",
    "label": "Adayu Indah",
    "value": "Adayu Indah"
  },
  {
    "id": "7405172014",
    "district_id": "740517",
    "label": "Wanua Maroa",
    "value": "Wanua Maroa"
  },
  {
    "id": "7405172015",
    "district_id": "740517",
    "label": "Wulele Jaya",
    "value": "Wulele Jaya"
  },
  {
    "id": "7405172016",
    "district_id": "740517",
    "label": "Ranooha Lestari",
    "value": "Ranooha Lestari"
  },
  {
    "id": "7405182001",
    "district_id": "740518",
    "label": "Wolasi",
    "value": "Wolasi"
  },
  {
    "id": "7405182002",
    "district_id": "740518",
    "label": "Aoma",
    "value": "Aoma"
  },
  {
    "id": "7405182003",
    "district_id": "740518",
    "label": "Amoito Jaya",
    "value": "Amoito Jaya"
  },
  {
    "id": "7405182004",
    "district_id": "740518",
    "label": "Leleka",
    "value": "Leleka"
  },
  {
    "id": "7405182005",
    "district_id": "740518",
    "label": "Mata Wolasi",
    "value": "Mata Wolasi"
  },
  {
    "id": "7405182007",
    "district_id": "740518",
    "label": "Aunupe",
    "value": "Aunupe"
  },
  {
    "id": "7405191001",
    "district_id": "740519",
    "label": "Puunggaluku",
    "value": "Puunggaluku"
  },
  {
    "id": "7405191002",
    "district_id": "740519",
    "label": "Ambalodangge",
    "value": "Ambalodangge"
  },
  {
    "id": "7405192003",
    "district_id": "740519",
    "label": "Aepodu",
    "value": "Aepodu"
  },
  {
    "id": "7405192004",
    "district_id": "740519",
    "label": "Rambu Rambu",
    "value": "Rambu Rambu"
  },
  {
    "id": "7405192005",
    "district_id": "740519",
    "label": "Ombu Ombu Jaya",
    "value": "Ombu Ombu Jaya"
  },
  {
    "id": "7405192006",
    "district_id": "740519",
    "label": "Lamong Jaya",
    "value": "Lamong Jaya"
  },
  {
    "id": "7405192007",
    "district_id": "740519",
    "label": "Lerepako",
    "value": "Lerepako"
  },
  {
    "id": "7405192008",
    "district_id": "740519",
    "label": "Ambesea",
    "value": "Ambesea"
  },
  {
    "id": "7405192009",
    "district_id": "740519",
    "label": "Laeya",
    "value": "Laeya"
  },
  {
    "id": "7405192010",
    "district_id": "740519",
    "label": "Labokeo",
    "value": "Labokeo"
  },
  {
    "id": "7405192011",
    "district_id": "740519",
    "label": "Torobulu",
    "value": "Torobulu"
  },
  {
    "id": "7405192012",
    "district_id": "740519",
    "label": "Anduna",
    "value": "Anduna"
  },
  {
    "id": "7405192013",
    "district_id": "740519",
    "label": "Ambakumina",
    "value": "Ambakumina"
  },
  {
    "id": "7405192014",
    "district_id": "740519",
    "label": "Lambakara",
    "value": "Lambakara"
  },
  {
    "id": "7405192015",
    "district_id": "740519",
    "label": "Anggoroboti",
    "value": "Anggoroboti"
  },
  {
    "id": "7405192016",
    "district_id": "740519",
    "label": "Wonua Kongga",
    "value": "Wonua Kongga"
  },
  {
    "id": "7405192017",
    "district_id": "740519",
    "label": "Puuwulo",
    "value": "Puuwulo"
  },
  {
    "id": "7405202001",
    "district_id": "740520",
    "label": "Amosara",
    "value": "Amosara"
  },
  {
    "id": "7405202002",
    "district_id": "740520",
    "label": "Baito",
    "value": "Baito"
  },
  {
    "id": "7405202003",
    "district_id": "740520",
    "label": "Matabubu",
    "value": "Matabubu"
  },
  {
    "id": "7405202004",
    "district_id": "740520",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7405202005",
    "district_id": "740520",
    "label": "Sambahule",
    "value": "Sambahule"
  },
  {
    "id": "7405202006",
    "district_id": "740520",
    "label": "Tolihe",
    "value": "Tolihe"
  },
  {
    "id": "7405202007",
    "district_id": "740520",
    "label": "Wonua Raya",
    "value": "Wonua Raya"
  },
  {
    "id": "7405202008",
    "district_id": "740520",
    "label": "Ahuangguluri",
    "value": "Ahuangguluri"
  },
  {
    "id": "7405212001",
    "district_id": "740521",
    "label": "Iwoimendoro",
    "value": "Iwoimendoro"
  },
  {
    "id": "7405212002",
    "district_id": "740521",
    "label": "Epeesi",
    "value": "Epeesi"
  },
  {
    "id": "7405212003",
    "district_id": "740521",
    "label": "Lere",
    "value": "Lere"
  },
  {
    "id": "7405212004",
    "district_id": "740521",
    "label": "Lipumasagena",
    "value": "Lipumasagena"
  },
  {
    "id": "7405212005",
    "district_id": "740521",
    "label": "Lambandia",
    "value": "Lambandia"
  },
  {
    "id": "7405212006",
    "district_id": "740521",
    "label": "Polo Pololi",
    "value": "Polo Pololi"
  },
  {
    "id": "7405212007",
    "district_id": "740521",
    "label": "Basala",
    "value": "Basala"
  },
  {
    "id": "7405212008",
    "district_id": "740521",
    "label": "Tombekuku",
    "value": "Tombekuku"
  },
  {
    "id": "7405212009",
    "district_id": "740521",
    "label": "Teporombua",
    "value": "Teporombua"
  },
  {
    "id": "7405222001",
    "district_id": "740522",
    "label": "Amokuni",
    "value": "Amokuni"
  },
  {
    "id": "7405222002",
    "district_id": "740522",
    "label": "Sindang Kasih",
    "value": "Sindang Kasih"
  },
  {
    "id": "7405222003",
    "district_id": "740522",
    "label": "Jati Bali",
    "value": "Jati Bali"
  },
  {
    "id": "7405222004",
    "district_id": "740522",
    "label": "Laikandonga",
    "value": "Laikandonga"
  },
  {
    "id": "7405222005",
    "district_id": "740522",
    "label": "Boro-Boro Lameuru",
    "value": "Boro-Boro Lameuru"
  },
  {
    "id": "7405222006",
    "district_id": "740522",
    "label": "Opaasi",
    "value": "Opaasi"
  },
  {
    "id": "7405222007",
    "district_id": "740522",
    "label": "Lameruru",
    "value": "Lameruru"
  },
  {
    "id": "7405222008",
    "district_id": "740522",
    "label": "Ambeko",
    "value": "Ambeko"
  },
  {
    "id": "7405222009",
    "district_id": "740522",
    "label": "Tunduno",
    "value": "Tunduno"
  },
  {
    "id": "7405232001",
    "district_id": "740523",
    "label": "Rambu-rambu",
    "value": "Rambu-rambu"
  },
  {
    "id": "7405232002",
    "district_id": "740523",
    "label": "Lambangi",
    "value": "Lambangi"
  },
  {
    "id": "7405232003",
    "district_id": "740523",
    "label": "Tumbu-tumbu Jaya",
    "value": "Tumbu-tumbu Jaya"
  },
  {
    "id": "7405232004",
    "district_id": "740523",
    "label": "Ngapawali",
    "value": "Ngapawali"
  },
  {
    "id": "7405232005",
    "district_id": "740523",
    "label": "Batuh Putih",
    "value": "Batuh Putih"
  },
  {
    "id": "7405232006",
    "district_id": "740523",
    "label": "Rumba-rumba",
    "value": "Rumba-rumba"
  },
  {
    "id": "7405232007",
    "district_id": "740523",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "7405232008",
    "district_id": "740523",
    "label": "Amolengu",
    "value": "Amolengu"
  },
  {
    "id": "7405232009",
    "district_id": "740523",
    "label": "Ulunese",
    "value": "Ulunese"
  },
  {
    "id": "7405232010",
    "district_id": "740523",
    "label": "Langgapulu",
    "value": "Langgapulu"
  },
  {
    "id": "7405242001",
    "district_id": "740524",
    "label": "Sabulakoa",
    "value": "Sabulakoa"
  },
  {
    "id": "7405242002",
    "district_id": "740524",
    "label": "Talumbinga",
    "value": "Talumbinga"
  },
  {
    "id": "7405242003",
    "district_id": "740524",
    "label": "Tetenggabo",
    "value": "Tetenggabo"
  },
  {
    "id": "7405242004",
    "district_id": "740524",
    "label": "Watu-watu",
    "value": "Watu-watu"
  },
  {
    "id": "7405242005",
    "district_id": "740524",
    "label": "Karoonua",
    "value": "Karoonua"
  },
  {
    "id": "7405242006",
    "district_id": "740524",
    "label": "Ulu Sabulakoa",
    "value": "Ulu Sabulakoa"
  },
  {
    "id": "7405242007",
    "district_id": "740524",
    "label": "Asaria",
    "value": "Asaria"
  },
  {
    "id": "7405242008",
    "district_id": "740524",
    "label": "Wawobende",
    "value": "Wawobende"
  },
  {
    "id": "7405252001",
    "district_id": "740525",
    "label": "Papawu",
    "value": "Papawu"
  },
  {
    "id": "7405252002",
    "district_id": "740525",
    "label": "Anese",
    "value": "Anese"
  },
  {
    "id": "7405252003",
    "district_id": "740525",
    "label": "Bekenggasu",
    "value": "Bekenggasu"
  },
  {
    "id": "7405252004",
    "district_id": "740525",
    "label": "Watumokala",
    "value": "Watumokala"
  },
  {
    "id": "7405252005",
    "district_id": "740525",
    "label": "Mataupe",
    "value": "Mataupe"
  },
  {
    "id": "7405252006",
    "district_id": "740525",
    "label": "Bimamaroa",
    "value": "Bimamaroa"
  },
  {
    "id": "7405252007",
    "district_id": "740525",
    "label": "Wawobende",
    "value": "Wawobende"
  },
  {
    "id": "7405252008",
    "district_id": "740525",
    "label": "Lapoa Indah",
    "value": "Lapoa Indah"
  },
  {
    "id": "7405252009",
    "district_id": "740525",
    "label": "Puundoho",
    "value": "Puundoho"
  },
  {
    "id": "7405252010",
    "district_id": "740525",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7406011007",
    "district_id": "740601",
    "label": "Boeara",
    "value": "Boeara"
  },
  {
    "id": "7406011008",
    "district_id": "740601",
    "label": "Kastarib",
    "value": "Kastarib"
  },
  {
    "id": "7406011010",
    "district_id": "740601",
    "label": "Boepinang",
    "value": "Boepinang"
  },
  {
    "id": "7406011015",
    "district_id": "740601",
    "label": "Boepinang Barat",
    "value": "Boepinang Barat"
  },
  {
    "id": "7406011016",
    "district_id": "740601",
    "label": "Barangka",
    "value": "Barangka"
  },
  {
    "id": "7406011017",
    "district_id": "740601",
    "label": "Kasabolo",
    "value": "Kasabolo"
  },
  {
    "id": "7406012009",
    "district_id": "740601",
    "label": "Pokohrumba",
    "value": "Pokohrumba"
  },
  {
    "id": "7406012011",
    "district_id": "740601",
    "label": "Pallimae",
    "value": "Pallimae"
  },
  {
    "id": "7406012018",
    "district_id": "740601",
    "label": "Salosa",
    "value": "Salosa"
  },
  {
    "id": "7406012019",
    "district_id": "740601",
    "label": "Matiro Walie",
    "value": "Matiro Walie"
  },
  {
    "id": "7406021011",
    "district_id": "740602",
    "label": "Bambaea",
    "value": "Bambaea"
  },
  {
    "id": "7406021015",
    "district_id": "740602",
    "label": "Puu Lemo",
    "value": "Puu Lemo"
  },
  {
    "id": "7406022003",
    "district_id": "740602",
    "label": "Teppoe",
    "value": "Teppoe"
  },
  {
    "id": "7406022004",
    "district_id": "740602",
    "label": "Biru",
    "value": "Biru"
  },
  {
    "id": "7406022010",
    "district_id": "740602",
    "label": "Mambo",
    "value": "Mambo"
  },
  {
    "id": "7406031010",
    "district_id": "740603",
    "label": "Taubonto",
    "value": "Taubonto"
  },
  {
    "id": "7406032001",
    "district_id": "740603",
    "label": "Rau Rau",
    "value": "Rau Rau"
  },
  {
    "id": "7406032008",
    "district_id": "740603",
    "label": "Ladumpi",
    "value": "Ladumpi"
  },
  {
    "id": "7406032009",
    "district_id": "740603",
    "label": "Rarowatu",
    "value": "Rarowatu"
  },
  {
    "id": "7406032011",
    "district_id": "740603",
    "label": "Lakomea",
    "value": "Lakomea"
  },
  {
    "id": "7406032012",
    "district_id": "740603",
    "label": "Pangkuri",
    "value": "Pangkuri"
  },
  {
    "id": "7406032013",
    "district_id": "740603",
    "label": "Lampeantani",
    "value": "Lampeantani"
  },
  {
    "id": "7406032014",
    "district_id": "740603",
    "label": "Watu Kalangkari",
    "value": "Watu Kalangkari"
  },
  {
    "id": "7406032015",
    "district_id": "740603",
    "label": "Tahi ite",
    "value": "Tahi ite"
  },
  {
    "id": "7406041002",
    "district_id": "740604",
    "label": "Lameroro",
    "value": "Lameroro"
  },
  {
    "id": "7406041003",
    "district_id": "740604",
    "label": "Lampopala",
    "value": "Lampopala"
  },
  {
    "id": "7406041004",
    "district_id": "740604",
    "label": "Doule",
    "value": "Doule"
  },
  {
    "id": "7406041005",
    "district_id": "740604",
    "label": "Kasipute",
    "value": "Kasipute"
  },
  {
    "id": "7406042001",
    "district_id": "740604",
    "label": "Lantawonua",
    "value": "Lantawonua"
  },
  {
    "id": "7406051009",
    "district_id": "740605",
    "label": "Teomokole",
    "value": "Teomokole"
  },
  {
    "id": "7406051016",
    "district_id": "740605",
    "label": "Rahampuu",
    "value": "Rahampuu"
  },
  {
    "id": "7406052010",
    "district_id": "740605",
    "label": "Rahadopi",
    "value": "Rahadopi"
  },
  {
    "id": "7406052011",
    "district_id": "740605",
    "label": "Tirongkotua",
    "value": "Tirongkotua"
  },
  {
    "id": "7406061009",
    "district_id": "740606",
    "label": "Dongkala",
    "value": "Dongkala"
  },
  {
    "id": "7406061010",
    "district_id": "740606",
    "label": "Lambale",
    "value": "Lambale"
  },
  {
    "id": "7406062005",
    "district_id": "740606",
    "label": "Wumbuburo",
    "value": "Wumbuburo"
  },
  {
    "id": "7406062006",
    "district_id": "740606",
    "label": "Balo",
    "value": "Balo"
  },
  {
    "id": "7406062007",
    "district_id": "740606",
    "label": "Toli-Toli",
    "value": "Toli-Toli"
  },
  {
    "id": "7406062008",
    "district_id": "740606",
    "label": "Tapuhaka",
    "value": "Tapuhaka"
  },
  {
    "id": "7406062011",
    "district_id": "740606",
    "label": "Bungi-Bungi",
    "value": "Bungi-Bungi"
  },
  {
    "id": "7406072001",
    "district_id": "740607",
    "label": "Babamolingku",
    "value": "Babamolingku"
  },
  {
    "id": "7406072002",
    "district_id": "740607",
    "label": "Toari Bombana",
    "value": "Toari Bombana"
  },
  {
    "id": "7406072003",
    "district_id": "740607",
    "label": "Timbala",
    "value": "Timbala"
  },
  {
    "id": "7406072004",
    "district_id": "740607",
    "label": "Ranokomea",
    "value": "Ranokomea"
  },
  {
    "id": "7406072005",
    "district_id": "740607",
    "label": "Rakadua",
    "value": "Rakadua"
  },
  {
    "id": "7406072006",
    "district_id": "740607",
    "label": "Lameong-Meong",
    "value": "Lameong-Meong"
  },
  {
    "id": "7406072007",
    "district_id": "740607",
    "label": "Pabiring",
    "value": "Pabiring"
  },
  {
    "id": "7406072008",
    "district_id": "740607",
    "label": "Balasari",
    "value": "Balasari"
  },
  {
    "id": "7406072009",
    "district_id": "740607",
    "label": "Bulumanai",
    "value": "Bulumanai"
  },
  {
    "id": "7406072010",
    "district_id": "740607",
    "label": "Matabundu",
    "value": "Matabundu"
  },
  {
    "id": "7406072011",
    "district_id": "740607",
    "label": "Analere",
    "value": "Analere"
  },
  {
    "id": "7406082001",
    "district_id": "740608",
    "label": "Pomontoro",
    "value": "Pomontoro"
  },
  {
    "id": "7406082002",
    "district_id": "740608",
    "label": "Liano",
    "value": "Liano"
  },
  {
    "id": "7406082003",
    "district_id": "740608",
    "label": "Pulau Tambako",
    "value": "Pulau Tambako"
  },
  {
    "id": "7406082004",
    "district_id": "740608",
    "label": "Lora",
    "value": "Lora"
  },
  {
    "id": "7406082005",
    "district_id": "740608",
    "label": "Toli-Toli",
    "value": "Toli-Toli"
  },
  {
    "id": "7406082006",
    "district_id": "740608",
    "label": "Tajuncu",
    "value": "Tajuncu"
  },
  {
    "id": "7406082007",
    "district_id": "740608",
    "label": "Mawar",
    "value": "Mawar"
  },
  {
    "id": "7406082008",
    "district_id": "740608",
    "label": "Laloa",
    "value": "Laloa"
  },
  {
    "id": "7406082009",
    "district_id": "740608",
    "label": "Batusampe Indah",
    "value": "Batusampe Indah"
  },
  {
    "id": "7406082010",
    "district_id": "740608",
    "label": "Hambawa",
    "value": "Hambawa"
  },
  {
    "id": "7406082011",
    "district_id": "740608",
    "label": "Pu'u Waeya",
    "value": "Pu'u Waeya"
  },
  {
    "id": "7406091008",
    "district_id": "740609",
    "label": "Aneka Marga",
    "value": "Aneka Marga"
  },
  {
    "id": "7406092001",
    "district_id": "740609",
    "label": "Wumbubangka",
    "value": "Wumbubangka"
  },
  {
    "id": "7406092002",
    "district_id": "740609",
    "label": "Hukaea",
    "value": "Hukaea"
  },
  {
    "id": "7406092003",
    "district_id": "740609",
    "label": "Lantowua",
    "value": "Lantowua"
  },
  {
    "id": "7406092004",
    "district_id": "740609",
    "label": "Tembe",
    "value": "Tembe"
  },
  {
    "id": "7406092005",
    "district_id": "740609",
    "label": "Watu Mende",
    "value": "Watu Mende"
  },
  {
    "id": "7406092006",
    "district_id": "740609",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "7406092007",
    "district_id": "740609",
    "label": "Tunas Baru",
    "value": "Tunas Baru"
  },
  {
    "id": "7406102001",
    "district_id": "740610",
    "label": "Tanpabulu",
    "value": "Tanpabulu"
  },
  {
    "id": "7406102002",
    "district_id": "740610",
    "label": "Tanah Poleang",
    "value": "Tanah Poleang"
  },
  {
    "id": "7406102003",
    "district_id": "740610",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "7406102004",
    "district_id": "740610",
    "label": "Toburi",
    "value": "Toburi"
  },
  {
    "id": "7406102005",
    "district_id": "740610",
    "label": "Rompu Rompu",
    "value": "Rompu Rompu"
  },
  {
    "id": "7406102006",
    "district_id": "740610",
    "label": "Pusu Ea",
    "value": "Pusu Ea"
  },
  {
    "id": "7406102007",
    "district_id": "740610",
    "label": "Lawatu Ea",
    "value": "Lawatu Ea"
  },
  {
    "id": "7406102008",
    "district_id": "740610",
    "label": "Wambarema",
    "value": "Wambarema"
  },
  {
    "id": "7406112001",
    "district_id": "740611",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "7406112002",
    "district_id": "740611",
    "label": "Waemputang",
    "value": "Waemputang"
  },
  {
    "id": "7406112003",
    "district_id": "740611",
    "label": "Kali Baru",
    "value": "Kali Baru"
  },
  {
    "id": "7406112004",
    "district_id": "740611",
    "label": "Akacipong",
    "value": "Akacipong"
  },
  {
    "id": "7406112005",
    "district_id": "740611",
    "label": "La Ea",
    "value": "La Ea"
  },
  {
    "id": "7406122001",
    "district_id": "740612",
    "label": "Larete",
    "value": "Larete"
  },
  {
    "id": "7406122002",
    "district_id": "740612",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7406122003",
    "district_id": "740612",
    "label": "Terapung",
    "value": "Terapung"
  },
  {
    "id": "7406122004",
    "district_id": "740612",
    "label": "Lamoare",
    "value": "Lamoare"
  },
  {
    "id": "7406122005",
    "district_id": "740612",
    "label": "Rambaha",
    "value": "Rambaha"
  },
  {
    "id": "7406132001",
    "district_id": "740613",
    "label": "Langkema",
    "value": "Langkema"
  },
  {
    "id": "7406132002",
    "district_id": "740613",
    "label": "Batuawu",
    "value": "Batuawu"
  },
  {
    "id": "7406132003",
    "district_id": "740613",
    "label": "Pongkalaero",
    "value": "Pongkalaero"
  },
  {
    "id": "7406132004",
    "district_id": "740613",
    "label": "Puu Nunu",
    "value": "Puu Nunu"
  },
  {
    "id": "7406141001",
    "district_id": "740614",
    "label": "Sikeli",
    "value": "Sikeli"
  },
  {
    "id": "7406142002",
    "district_id": "740614",
    "label": "Baliara",
    "value": "Baliara"
  },
  {
    "id": "7406142003",
    "district_id": "740614",
    "label": "Rahantari",
    "value": "Rahantari"
  },
  {
    "id": "7406142004",
    "district_id": "740614",
    "label": "Baliara Kepulauan",
    "value": "Baliara Kepulauan"
  },
  {
    "id": "7406142005",
    "district_id": "740614",
    "label": "Baliara Selatan",
    "value": "Baliara Selatan"
  },
  {
    "id": "7406152001",
    "district_id": "740615",
    "label": "Mapila",
    "value": "Mapila"
  },
  {
    "id": "7406152002",
    "district_id": "740615",
    "label": "Wumbulasa",
    "value": "Wumbulasa"
  },
  {
    "id": "7406152003",
    "district_id": "740615",
    "label": "Sangia Makmur",
    "value": "Sangia Makmur"
  },
  {
    "id": "7406152004",
    "district_id": "740615",
    "label": "Eemokolo",
    "value": "Eemokolo"
  },
  {
    "id": "7406152005",
    "district_id": "740615",
    "label": "Tedubara",
    "value": "Tedubara"
  },
  {
    "id": "7406152006",
    "district_id": "740615",
    "label": "Larolanu",
    "value": "Larolanu"
  },
  {
    "id": "7406162001",
    "district_id": "740616",
    "label": "Lamonggi",
    "value": "Lamonggi"
  },
  {
    "id": "7406162002",
    "district_id": "740616",
    "label": "Tangkeno",
    "value": "Tangkeno"
  },
  {
    "id": "7406162003",
    "district_id": "740616",
    "label": "Lengora",
    "value": "Lengora"
  },
  {
    "id": "7406162004",
    "district_id": "740616",
    "label": "Enano",
    "value": "Enano"
  },
  {
    "id": "7406162005",
    "district_id": "740616",
    "label": "Ulungkura",
    "value": "Ulungkura"
  },
  {
    "id": "7406162006",
    "district_id": "740616",
    "label": "Lengora Selatan",
    "value": "Lengora Selatan"
  },
  {
    "id": "7406162007",
    "district_id": "740616",
    "label": "Lengora Pantai",
    "value": "Lengora Pantai"
  },
  {
    "id": "7406172001",
    "district_id": "740617",
    "label": "Masaloka",
    "value": "Masaloka"
  },
  {
    "id": "7406172002",
    "district_id": "740617",
    "label": "Batu Lamburi",
    "value": "Batu Lamburi"
  },
  {
    "id": "7406172003",
    "district_id": "740617",
    "label": "Masaloka Selatan",
    "value": "Masaloka Selatan"
  },
  {
    "id": "7406172004",
    "district_id": "740617",
    "label": "Masaloka Timur",
    "value": "Masaloka Timur"
  },
  {
    "id": "7406172005",
    "district_id": "740617",
    "label": "Masaloka Barat",
    "value": "Masaloka Barat"
  },
  {
    "id": "7406181001",
    "district_id": "740618",
    "label": "Lauru",
    "value": "Lauru"
  },
  {
    "id": "7406181002",
    "district_id": "740618",
    "label": "Poea",
    "value": "Poea"
  },
  {
    "id": "7406181003",
    "district_id": "740618",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "7406182004",
    "district_id": "740618",
    "label": "Tapuhahi",
    "value": "Tapuhahi"
  },
  {
    "id": "7406182005",
    "district_id": "740618",
    "label": "Lampata",
    "value": "Lampata"
  },
  {
    "id": "7406192001",
    "district_id": "740619",
    "label": "Mulaeno",
    "value": "Mulaeno"
  },
  {
    "id": "7406192002",
    "district_id": "740619",
    "label": "Lebo Ea",
    "value": "Lebo Ea"
  },
  {
    "id": "7406192003",
    "district_id": "740619",
    "label": "Paria",
    "value": "Paria"
  },
  {
    "id": "7406192004",
    "district_id": "740619",
    "label": "Poleondro",
    "value": "Poleondro"
  },
  {
    "id": "7406202001",
    "district_id": "740620",
    "label": "Tontonunu",
    "value": "Tontonunu"
  },
  {
    "id": "7406202002",
    "district_id": "740620",
    "label": "Tongkoseng",
    "value": "Tongkoseng"
  },
  {
    "id": "7406202003",
    "district_id": "740620",
    "label": "Tete Haka",
    "value": "Tete Haka"
  },
  {
    "id": "7406202004",
    "district_id": "740620",
    "label": "Watu Melomba",
    "value": "Watu Melomba"
  },
  {
    "id": "7406202005",
    "district_id": "740620",
    "label": "Puu Wonua",
    "value": "Puu Wonua"
  },
  {
    "id": "7406212001",
    "district_id": "740621",
    "label": "Lambikasi",
    "value": "Lambikasi"
  },
  {
    "id": "7406212002",
    "district_id": "740621",
    "label": "Lantari",
    "value": "Lantari"
  },
  {
    "id": "7406212003",
    "district_id": "740621",
    "label": "Langkowala",
    "value": "Langkowala"
  },
  {
    "id": "7406212004",
    "district_id": "740621",
    "label": "Pasare Apua",
    "value": "Pasare Apua"
  },
  {
    "id": "7406212006",
    "district_id": "740621",
    "label": "Anugerah",
    "value": "Anugerah"
  },
  {
    "id": "7406212007",
    "district_id": "740621",
    "label": "Kalaero",
    "value": "Kalaero"
  },
  {
    "id": "7406212008",
    "district_id": "740621",
    "label": "Tinabite",
    "value": "Tinabite"
  },
  {
    "id": "7406212009",
    "district_id": "740621",
    "label": "Rarongkeu",
    "value": "Rarongkeu"
  },
  {
    "id": "7406212010",
    "district_id": "740621",
    "label": "Watu-watu",
    "value": "Watu-watu"
  },
  {
    "id": "7406222001",
    "district_id": "740622",
    "label": "Kolombi Matausu",
    "value": "Kolombi Matausu"
  },
  {
    "id": "7406222002",
    "district_id": "740622",
    "label": "Morengke",
    "value": "Morengke"
  },
  {
    "id": "7406222003",
    "district_id": "740622",
    "label": "Wia-wia",
    "value": "Wia-wia"
  },
  {
    "id": "7406222004",
    "district_id": "740622",
    "label": "Lamuru",
    "value": "Lamuru"
  },
  {
    "id": "7406222005",
    "district_id": "740622",
    "label": "Totole",
    "value": "Totole"
  },
  {
    "id": "7407011002",
    "district_id": "740701",
    "label": "Pongo",
    "value": "Pongo"
  },
  {
    "id": "7407011003",
    "district_id": "740701",
    "label": "Wanci",
    "value": "Wanci"
  },
  {
    "id": "7407011004",
    "district_id": "740701",
    "label": "Wandoka",
    "value": "Wandoka"
  },
  {
    "id": "7407011007",
    "district_id": "740701",
    "label": "Waetuno",
    "value": "Waetuno"
  },
  {
    "id": "7407011025",
    "district_id": "740701",
    "label": "Wandoka Utara",
    "value": "Wandoka Utara"
  },
  {
    "id": "7407011026",
    "district_id": "740701",
    "label": "Wandoka Selatan",
    "value": "Wandoka Selatan"
  },
  {
    "id": "7407012005",
    "district_id": "740701",
    "label": "Sombu",
    "value": "Sombu"
  },
  {
    "id": "7407012006",
    "district_id": "740701",
    "label": "Waha",
    "value": "Waha"
  },
  {
    "id": "7407012008",
    "district_id": "740701",
    "label": "Tindoi",
    "value": "Tindoi"
  },
  {
    "id": "7407012009",
    "district_id": "740701",
    "label": "Maleko",
    "value": "Maleko"
  },
  {
    "id": "7407012013",
    "district_id": "740701",
    "label": "Longa",
    "value": "Longa"
  },
  {
    "id": "7407012022",
    "district_id": "740701",
    "label": "Pada Raya Makmur",
    "value": "Pada Raya Makmur"
  },
  {
    "id": "7407012023",
    "district_id": "740701",
    "label": "Waelumu",
    "value": "Waelumu"
  },
  {
    "id": "7407012024",
    "district_id": "740701",
    "label": "Patuno",
    "value": "Patuno"
  },
  {
    "id": "7407012027",
    "district_id": "740701",
    "label": "Waginopo",
    "value": "Waginopo"
  },
  {
    "id": "7407012028",
    "district_id": "740701",
    "label": "Tindoi Timur",
    "value": "Tindoi Timur"
  },
  {
    "id": "7407012029",
    "district_id": "740701",
    "label": "Posalu",
    "value": "Posalu"
  },
  {
    "id": "7407012030",
    "district_id": "740701",
    "label": "Koroe Onawa",
    "value": "Koroe Onawa"
  },
  {
    "id": "7407012031",
    "district_id": "740701",
    "label": "Wapia-pia",
    "value": "Wapia-pia"
  },
  {
    "id": "7407012032",
    "district_id": "740701",
    "label": "Pookambua",
    "value": "Pookambua"
  },
  {
    "id": "7407021002",
    "district_id": "740702",
    "label": "Laolua",
    "value": "Laolua"
  },
  {
    "id": "7407021004",
    "district_id": "740702",
    "label": "Ambeua",
    "value": "Ambeua"
  },
  {
    "id": "7407021005",
    "district_id": "740702",
    "label": "Lagiwae",
    "value": "Lagiwae"
  },
  {
    "id": "7407021015",
    "district_id": "740702",
    "label": "Buranga",
    "value": "Buranga"
  },
  {
    "id": "7407022001",
    "district_id": "740702",
    "label": "Sombano",
    "value": "Sombano"
  },
  {
    "id": "7407022003",
    "district_id": "740702",
    "label": "Samabahari",
    "value": "Samabahari"
  },
  {
    "id": "7407022006",
    "district_id": "740702",
    "label": "Ollo",
    "value": "Ollo"
  },
  {
    "id": "7407022007",
    "district_id": "740702",
    "label": "Haruo",
    "value": "Haruo"
  },
  {
    "id": "7407022016",
    "district_id": "740702",
    "label": "Balasuna",
    "value": "Balasuna"
  },
  {
    "id": "7407022018",
    "district_id": "740702",
    "label": "Mantigola Makmur",
    "value": "Mantigola Makmur"
  },
  {
    "id": "7407022019",
    "district_id": "740702",
    "label": "Balasuna Selatan",
    "value": "Balasuna Selatan"
  },
  {
    "id": "7407022020",
    "district_id": "740702",
    "label": "Ollo Selatan",
    "value": "Ollo Selatan"
  },
  {
    "id": "7407022021",
    "district_id": "740702",
    "label": "Waduri",
    "value": "Waduri"
  },
  {
    "id": "7407022022",
    "district_id": "740702",
    "label": "Lewuto",
    "value": "Lewuto"
  },
  {
    "id": "7407022023",
    "district_id": "740702",
    "label": "Ambeua Raya",
    "value": "Ambeua Raya"
  },
  {
    "id": "7407022024",
    "district_id": "740702",
    "label": "Kalimas",
    "value": "Kalimas"
  },
  {
    "id": "7407031001",
    "district_id": "740703",
    "label": "Waha",
    "value": "Waha"
  },
  {
    "id": "7407031016",
    "district_id": "740703",
    "label": "Onemay",
    "value": "Onemay"
  },
  {
    "id": "7407032002",
    "district_id": "740703",
    "label": "Waitii Barat",
    "value": "Waitii Barat"
  },
  {
    "id": "7407032003",
    "district_id": "740703",
    "label": "Lamanggau",
    "value": "Lamanggau"
  },
  {
    "id": "7407032004",
    "district_id": "740703",
    "label": "Waiti",
    "value": "Waiti"
  },
  {
    "id": "7407032012",
    "district_id": "740703",
    "label": "Runduma",
    "value": "Runduma"
  },
  {
    "id": "7407032015",
    "district_id": "740703",
    "label": "Patua",
    "value": "Patua"
  },
  {
    "id": "7407032017",
    "district_id": "740703",
    "label": "Teemoane",
    "value": "Teemoane"
  },
  {
    "id": "7407032018",
    "district_id": "740703",
    "label": "Kollo Soha",
    "value": "Kollo Soha"
  },
  {
    "id": "7407032019",
    "district_id": "740703",
    "label": "Patua II",
    "value": "Patua II"
  },
  {
    "id": "7407041005",
    "district_id": "740704",
    "label": "Taipabu",
    "value": "Taipabu"
  },
  {
    "id": "7407041007",
    "district_id": "740704",
    "label": "Palahidu",
    "value": "Palahidu"
  },
  {
    "id": "7407041010",
    "district_id": "740704",
    "label": "Wali",
    "value": "Wali"
  },
  {
    "id": "7407041012",
    "district_id": "740704",
    "label": "Rukuwa",
    "value": "Rukuwa"
  },
  {
    "id": "7407042006",
    "district_id": "740704",
    "label": "Makoro",
    "value": "Makoro"
  },
  {
    "id": "7407042008",
    "district_id": "740704",
    "label": "Kampo Kampo",
    "value": "Kampo Kampo"
  },
  {
    "id": "7407042009",
    "district_id": "740704",
    "label": "Lagongga",
    "value": "Lagongga"
  },
  {
    "id": "7407042011",
    "district_id": "740704",
    "label": "Palahidu Barat",
    "value": "Palahidu Barat"
  },
  {
    "id": "7407042013",
    "district_id": "740704",
    "label": "Jaya Makmur",
    "value": "Jaya Makmur"
  },
  {
    "id": "7407051016",
    "district_id": "740705",
    "label": "Mandati II",
    "value": "Mandati II"
  },
  {
    "id": "7407051017",
    "district_id": "740705",
    "label": "Mandati I",
    "value": "Mandati I"
  },
  {
    "id": "7407051025",
    "district_id": "740705",
    "label": "Mandati III",
    "value": "Mandati III"
  },
  {
    "id": "7407052011",
    "district_id": "740705",
    "label": "Kapota",
    "value": "Kapota"
  },
  {
    "id": "7407052012",
    "district_id": "740705",
    "label": "Komala",
    "value": "Komala"
  },
  {
    "id": "7407052013",
    "district_id": "740705",
    "label": "Wungka",
    "value": "Wungka"
  },
  {
    "id": "7407052014",
    "district_id": "740705",
    "label": "Matahora",
    "value": "Matahora"
  },
  {
    "id": "7407052015",
    "district_id": "740705",
    "label": "Kabita",
    "value": "Kabita"
  },
  {
    "id": "7407052018",
    "district_id": "740705",
    "label": "Mola Utara",
    "value": "Mola Utara"
  },
  {
    "id": "7407052019",
    "district_id": "740705",
    "label": "Mola Selatan",
    "value": "Mola Selatan"
  },
  {
    "id": "7407052020",
    "district_id": "740705",
    "label": "Numana",
    "value": "Numana"
  },
  {
    "id": "7407052021",
    "district_id": "740705",
    "label": "Liya Mawi",
    "value": "Liya Mawi"
  },
  {
    "id": "7407052022",
    "district_id": "740705",
    "label": "Liya Togo",
    "value": "Liya Togo"
  },
  {
    "id": "7407052023",
    "district_id": "740705",
    "label": "Kapota Utara",
    "value": "Kapota Utara"
  },
  {
    "id": "7407052024",
    "district_id": "740705",
    "label": "Kabita Togo",
    "value": "Kabita Togo"
  },
  {
    "id": "7407052026",
    "district_id": "740705",
    "label": "Liya Onemaelangka",
    "value": "Liya Onemaelangka"
  },
  {
    "id": "7407052027",
    "district_id": "740705",
    "label": "Wisata Kolo",
    "value": "Wisata Kolo"
  },
  {
    "id": "7407052028",
    "district_id": "740705",
    "label": "Mola Samaturu",
    "value": "Mola Samaturu"
  },
  {
    "id": "7407052029",
    "district_id": "740705",
    "label": "Mola Bahari",
    "value": "Mola Bahari"
  },
  {
    "id": "7407052030",
    "district_id": "740705",
    "label": "Mola Nelayan Bhakti",
    "value": "Mola Nelayan Bhakti"
  },
  {
    "id": "7407052031",
    "district_id": "740705",
    "label": "Liya Bahari Indah",
    "value": "Liya Bahari Indah"
  },
  {
    "id": "7407062001",
    "district_id": "740706",
    "label": "Tampara",
    "value": "Tampara"
  },
  {
    "id": "7407062002",
    "district_id": "740706",
    "label": "Kaswari",
    "value": "Kaswari"
  },
  {
    "id": "7407062003",
    "district_id": "740706",
    "label": "Pajam",
    "value": "Pajam"
  },
  {
    "id": "7407062004",
    "district_id": "740706",
    "label": "Langge",
    "value": "Langge"
  },
  {
    "id": "7407062005",
    "district_id": "740706",
    "label": "Tanomeha",
    "value": "Tanomeha"
  },
  {
    "id": "7407062006",
    "district_id": "740706",
    "label": "Lentea",
    "value": "Lentea"
  },
  {
    "id": "7407062007",
    "district_id": "740706",
    "label": "Darawa",
    "value": "Darawa"
  },
  {
    "id": "7407062008",
    "district_id": "740706",
    "label": "Sandi",
    "value": "Sandi"
  },
  {
    "id": "7407062009",
    "district_id": "740706",
    "label": "Peropa",
    "value": "Peropa"
  },
  {
    "id": "7407062010",
    "district_id": "740706",
    "label": "Tanjung",
    "value": "Tanjung"
  },
  {
    "id": "7407071001",
    "district_id": "740707",
    "label": "Patipelong",
    "value": "Patipelong"
  },
  {
    "id": "7407071002",
    "district_id": "740707",
    "label": "Tongano Barat",
    "value": "Tongano Barat"
  },
  {
    "id": "7407071003",
    "district_id": "740707",
    "label": "Bahari",
    "value": "Bahari"
  },
  {
    "id": "7407071004",
    "district_id": "740707",
    "label": "Tongano Timur",
    "value": "Tongano Timur"
  },
  {
    "id": "7407071011",
    "district_id": "740707",
    "label": "Rukuwa",
    "value": "Rukuwa"
  },
  {
    "id": "7407072005",
    "district_id": "740707",
    "label": "Timu",
    "value": "Timu"
  },
  {
    "id": "7407072006",
    "district_id": "740707",
    "label": "Dete",
    "value": "Dete"
  },
  {
    "id": "7407072007",
    "district_id": "740707",
    "label": "Kulati",
    "value": "Kulati"
  },
  {
    "id": "7407072008",
    "district_id": "740707",
    "label": "Wawaotimu",
    "value": "Wawaotimu"
  },
  {
    "id": "7407072009",
    "district_id": "740707",
    "label": "Kahiyanga",
    "value": "Kahiyanga"
  },
  {
    "id": "7407081003",
    "district_id": "740708",
    "label": "Sowa",
    "value": "Sowa"
  },
  {
    "id": "7407081004",
    "district_id": "740708",
    "label": "Popalia",
    "value": "Popalia"
  },
  {
    "id": "7407082001",
    "district_id": "740708",
    "label": "Waloindi",
    "value": "Waloindi"
  },
  {
    "id": "7407082002",
    "district_id": "740708",
    "label": "Oihu",
    "value": "Oihu"
  },
  {
    "id": "7407082005",
    "district_id": "740708",
    "label": "Haka",
    "value": "Haka"
  },
  {
    "id": "7408011001",
    "district_id": "740801",
    "label": "Lasusua",
    "value": "Lasusua"
  },
  {
    "id": "7408012002",
    "district_id": "740801",
    "label": "Pitulua",
    "value": "Pitulua"
  },
  {
    "id": "7408012003",
    "district_id": "740801",
    "label": "Rante Limbong",
    "value": "Rante Limbong"
  },
  {
    "id": "7408012006",
    "district_id": "740801",
    "label": "Totallang",
    "value": "Totallang"
  },
  {
    "id": "7408012007",
    "district_id": "740801",
    "label": "Tojabi",
    "value": "Tojabi"
  },
  {
    "id": "7408012008",
    "district_id": "740801",
    "label": "Ponggiha",
    "value": "Ponggiha"
  },
  {
    "id": "7408012009",
    "district_id": "740801",
    "label": "Watuliwu",
    "value": "Watuliwu"
  },
  {
    "id": "7408012010",
    "district_id": "740801",
    "label": "Sulaho",
    "value": "Sulaho"
  },
  {
    "id": "7408012015",
    "district_id": "740801",
    "label": "Patowonua",
    "value": "Patowonua"
  },
  {
    "id": "7408012016",
    "district_id": "740801",
    "label": "Babussalam",
    "value": "Babussalam"
  },
  {
    "id": "7408012017",
    "district_id": "740801",
    "label": "Batu Ganda Permai",
    "value": "Batu Ganda Permai"
  },
  {
    "id": "7408012018",
    "district_id": "740801",
    "label": "Puncak Monapa",
    "value": "Puncak Monapa"
  },
  {
    "id": "7408021001",
    "district_id": "740802",
    "label": "Olo-Oloho",
    "value": "Olo-Oloho"
  },
  {
    "id": "7408022002",
    "district_id": "740802",
    "label": "Kosali",
    "value": "Kosali"
  },
  {
    "id": "7408022003",
    "district_id": "740802",
    "label": "Mikuasi",
    "value": "Mikuasi"
  },
  {
    "id": "7408022010",
    "district_id": "740802",
    "label": "Kasumeeto",
    "value": "Kasumeeto"
  },
  {
    "id": "7408022013",
    "district_id": "740802",
    "label": "Lalombundi",
    "value": "Lalombundi"
  },
  {
    "id": "7408022015",
    "district_id": "740802",
    "label": "Toaha",
    "value": "Toaha"
  },
  {
    "id": "7408022018",
    "district_id": "740802",
    "label": "Kondara",
    "value": "Kondara"
  },
  {
    "id": "7408022019",
    "district_id": "740802",
    "label": "Sipakainge",
    "value": "Sipakainge"
  },
  {
    "id": "7408022020",
    "district_id": "740802",
    "label": "Seuwwa",
    "value": "Seuwwa"
  },
  {
    "id": "7408022021",
    "district_id": "740802",
    "label": "Alipato",
    "value": "Alipato"
  },
  {
    "id": "7408022022",
    "district_id": "740802",
    "label": "Lalume",
    "value": "Lalume"
  },
  {
    "id": "7408031001",
    "district_id": "740803",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "7408032002",
    "district_id": "740803",
    "label": "Latowu",
    "value": "Latowu"
  },
  {
    "id": "7408032003",
    "district_id": "740803",
    "label": "Mosiku",
    "value": "Mosiku"
  },
  {
    "id": "7408032004",
    "district_id": "740803",
    "label": "Lelewawo",
    "value": "Lelewawo"
  },
  {
    "id": "7408032011",
    "district_id": "740803",
    "label": "Batu Api",
    "value": "Batu Api"
  },
  {
    "id": "7408032012",
    "district_id": "740803",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "7408032015",
    "district_id": "740803",
    "label": "Bukit Baru",
    "value": "Bukit Baru"
  },
  {
    "id": "7408032016",
    "district_id": "740803",
    "label": "Parung Lampe",
    "value": "Parung Lampe"
  },
  {
    "id": "7408032017",
    "district_id": "740803",
    "label": "Puncak Harapan",
    "value": "Puncak Harapan"
  },
  {
    "id": "7408032020",
    "district_id": "740803",
    "label": "Tetebawo",
    "value": "Tetebawo"
  },
  {
    "id": "7408032021",
    "district_id": "740803",
    "label": "Makkuaseng",
    "value": "Makkuaseng"
  },
  {
    "id": "7408041004",
    "district_id": "740804",
    "label": "Rante Angin",
    "value": "Rante Angin"
  },
  {
    "id": "7408042003",
    "district_id": "740804",
    "label": "Rante Baru",
    "value": "Rante Baru"
  },
  {
    "id": "7408042005",
    "district_id": "740804",
    "label": "Pohu",
    "value": "Pohu"
  },
  {
    "id": "7408042011",
    "district_id": "740804",
    "label": "Torotuo",
    "value": "Torotuo"
  },
  {
    "id": "7408042013",
    "district_id": "740804",
    "label": "Maroko",
    "value": "Maroko"
  },
  {
    "id": "7408042014",
    "district_id": "740804",
    "label": "Landolia",
    "value": "Landolia"
  },
  {
    "id": "7408042015",
    "district_id": "740804",
    "label": "Lawekara",
    "value": "Lawekara"
  },
  {
    "id": "7408051002",
    "district_id": "740805",
    "label": "Mala - Mala",
    "value": "Mala - Mala"
  },
  {
    "id": "7408052001",
    "district_id": "740805",
    "label": "Awo",
    "value": "Awo"
  },
  {
    "id": "7408052003",
    "district_id": "740805",
    "label": "Koroha",
    "value": "Koroha"
  },
  {
    "id": "7408052004",
    "district_id": "740805",
    "label": "Kamisi",
    "value": "Kamisi"
  },
  {
    "id": "7408052006",
    "district_id": "740805",
    "label": "Lametuna",
    "value": "Lametuna"
  },
  {
    "id": "7408052007",
    "district_id": "740805",
    "label": "Meeto",
    "value": "Meeto"
  },
  {
    "id": "7408052009",
    "district_id": "740805",
    "label": "Kalu-Kaluku",
    "value": "Kalu-Kaluku"
  },
  {
    "id": "7408052010",
    "district_id": "740805",
    "label": "Jabal Nur",
    "value": "Jabal Nur"
  },
  {
    "id": "7408052012",
    "district_id": "740805",
    "label": "Jabal Kubis",
    "value": "Jabal Kubis"
  },
  {
    "id": "7408052014",
    "district_id": "740805",
    "label": "Sawangaoha",
    "value": "Sawangaoha"
  },
  {
    "id": "7408052018",
    "district_id": "740805",
    "label": "Ainani Tajriani",
    "value": "Ainani Tajriani"
  },
  {
    "id": "7408052019",
    "district_id": "740805",
    "label": "Delang-Delang",
    "value": "Delang-Delang"
  },
  {
    "id": "7408061003",
    "district_id": "740806",
    "label": "Lapai",
    "value": "Lapai"
  },
  {
    "id": "7408062002",
    "district_id": "740806",
    "label": "Ngapa",
    "value": "Ngapa"
  },
  {
    "id": "7408062005",
    "district_id": "740806",
    "label": "Paruttellang",
    "value": "Paruttellang"
  },
  {
    "id": "7408062008",
    "district_id": "740806",
    "label": "Puurau",
    "value": "Puurau"
  },
  {
    "id": "7408062009",
    "district_id": "740806",
    "label": "Koreiha",
    "value": "Koreiha"
  },
  {
    "id": "7408062011",
    "district_id": "740806",
    "label": "Lawolatu",
    "value": "Lawolatu"
  },
  {
    "id": "7408062012",
    "district_id": "740806",
    "label": "Tadaumera",
    "value": "Tadaumera"
  },
  {
    "id": "7408062013",
    "district_id": "740806",
    "label": "Watumotaha",
    "value": "Watumotaha"
  },
  {
    "id": "7408062014",
    "district_id": "740806",
    "label": "Beringin",
    "value": "Beringin"
  },
  {
    "id": "7408062015",
    "district_id": "740806",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7408062016",
    "district_id": "740806",
    "label": "Nimbuneha",
    "value": "Nimbuneha"
  },
  {
    "id": "7408062017",
    "district_id": "740806",
    "label": "Padaelo",
    "value": "Padaelo"
  },
  {
    "id": "7408072001",
    "district_id": "740807",
    "label": "Walasiho",
    "value": "Walasiho"
  },
  {
    "id": "7408072002",
    "district_id": "740807",
    "label": "Wawo",
    "value": "Wawo"
  },
  {
    "id": "7408072003",
    "district_id": "740807",
    "label": "Tinukari",
    "value": "Tinukari"
  },
  {
    "id": "7408072004",
    "district_id": "740807",
    "label": "Pumbolo",
    "value": "Pumbolo"
  },
  {
    "id": "7408072005",
    "district_id": "740807",
    "label": "Ulu Wawo",
    "value": "Ulu Wawo"
  },
  {
    "id": "7408072006",
    "district_id": "740807",
    "label": "Latawe",
    "value": "Latawe"
  },
  {
    "id": "7408072007",
    "district_id": "740807",
    "label": "Salurengko",
    "value": "Salurengko"
  },
  {
    "id": "7408082001",
    "district_id": "740808",
    "label": "Lambai",
    "value": "Lambai"
  },
  {
    "id": "7408082002",
    "district_id": "740808",
    "label": "Latawaro",
    "value": "Latawaro"
  },
  {
    "id": "7408082003",
    "district_id": "740808",
    "label": "Lapasi - Pasi",
    "value": "Lapasi - Pasi"
  },
  {
    "id": "7408082004",
    "district_id": "740808",
    "label": "Raoda",
    "value": "Raoda"
  },
  {
    "id": "7408082005",
    "district_id": "740808",
    "label": "Woise",
    "value": "Woise"
  },
  {
    "id": "7408082006",
    "district_id": "740808",
    "label": "Woitombo",
    "value": "Woitombo"
  },
  {
    "id": "7408082007",
    "district_id": "740808",
    "label": "Tebongeano",
    "value": "Tebongeano"
  },
  {
    "id": "7408092001",
    "district_id": "740809",
    "label": "Watunohu",
    "value": "Watunohu"
  },
  {
    "id": "7408092002",
    "district_id": "740809",
    "label": "Lahabaru",
    "value": "Lahabaru"
  },
  {
    "id": "7408092003",
    "district_id": "740809",
    "label": "Tambuha",
    "value": "Tambuha"
  },
  {
    "id": "7408092004",
    "district_id": "740809",
    "label": "Sarona",
    "value": "Sarona"
  },
  {
    "id": "7408092005",
    "district_id": "740809",
    "label": "Sapoiha",
    "value": "Sapoiha"
  },
  {
    "id": "7408092006",
    "district_id": "740809",
    "label": "Nyule",
    "value": "Nyule"
  },
  {
    "id": "7408092007",
    "district_id": "740809",
    "label": "Samaturu",
    "value": "Samaturu"
  },
  {
    "id": "7408092008",
    "district_id": "740809",
    "label": "Lelehao",
    "value": "Lelehao"
  },
  {
    "id": "7408102001",
    "district_id": "740810",
    "label": "Lanipa",
    "value": "Lanipa"
  },
  {
    "id": "7408102002",
    "district_id": "740810",
    "label": "Majapahit",
    "value": "Majapahit"
  },
  {
    "id": "7408102003",
    "district_id": "740810",
    "label": "Labipi",
    "value": "Labipi"
  },
  {
    "id": "7408102004",
    "district_id": "740810",
    "label": "Latali",
    "value": "Latali"
  },
  {
    "id": "7408102005",
    "district_id": "740810",
    "label": "Pasampang",
    "value": "Pasampang"
  },
  {
    "id": "7408102006",
    "district_id": "740810",
    "label": "Tarengga",
    "value": "Tarengga"
  },
  {
    "id": "7408102007",
    "district_id": "740810",
    "label": "To'lemo",
    "value": "To'lemo"
  },
  {
    "id": "7408102008",
    "district_id": "740810",
    "label": "Salulotong",
    "value": "Salulotong"
  },
  {
    "id": "7408102009",
    "district_id": "740810",
    "label": "Powalaa",
    "value": "Powalaa"
  },
  {
    "id": "7408102010",
    "district_id": "740810",
    "label": "Kalahunde",
    "value": "Kalahunde"
  },
  {
    "id": "7408112001",
    "district_id": "740811",
    "label": "Puundoho",
    "value": "Puundoho"
  },
  {
    "id": "7408112002",
    "district_id": "740811",
    "label": "Pakue",
    "value": "Pakue"
  },
  {
    "id": "7408112003",
    "district_id": "740811",
    "label": "Lawata",
    "value": "Lawata"
  },
  {
    "id": "7408112004",
    "district_id": "740811",
    "label": "Mataleuno",
    "value": "Mataleuno"
  },
  {
    "id": "7408112005",
    "district_id": "740811",
    "label": "Teposua",
    "value": "Teposua"
  },
  {
    "id": "7408112006",
    "district_id": "740811",
    "label": "Saludongka",
    "value": "Saludongka"
  },
  {
    "id": "7408112007",
    "district_id": "740811",
    "label": "Kalo",
    "value": "Kalo"
  },
  {
    "id": "7408112008",
    "district_id": "740811",
    "label": "Amoe",
    "value": "Amoe"
  },
  {
    "id": "7408112009",
    "district_id": "740811",
    "label": "Lengkong Batu",
    "value": "Lengkong Batu"
  },
  {
    "id": "7408122001",
    "district_id": "740812",
    "label": "Porehu",
    "value": "Porehu"
  },
  {
    "id": "7408122002",
    "district_id": "740812",
    "label": "Tobela",
    "value": "Tobela"
  },
  {
    "id": "7408122003",
    "district_id": "740812",
    "label": "Tanggaruru",
    "value": "Tanggaruru"
  },
  {
    "id": "7408122004",
    "district_id": "740812",
    "label": "Ponggi",
    "value": "Ponggi"
  },
  {
    "id": "7408122005",
    "district_id": "740812",
    "label": "Larui",
    "value": "Larui"
  },
  {
    "id": "7408122007",
    "district_id": "740812",
    "label": "Bangsala",
    "value": "Bangsala"
  },
  {
    "id": "7408122008",
    "district_id": "740812",
    "label": "Sarambu",
    "value": "Sarambu"
  },
  {
    "id": "7408122009",
    "district_id": "740812",
    "label": "Tinuna",
    "value": "Tinuna"
  },
  {
    "id": "7408132001",
    "district_id": "740813",
    "label": "Lanipa - Nipa",
    "value": "Lanipa - Nipa"
  },
  {
    "id": "7408132002",
    "district_id": "740813",
    "label": "Ujung Tobaku",
    "value": "Ujung Tobaku"
  },
  {
    "id": "7408132003",
    "district_id": "740813",
    "label": "Katoi",
    "value": "Katoi"
  },
  {
    "id": "7408132004",
    "district_id": "740813",
    "label": "Simbula",
    "value": "Simbula"
  },
  {
    "id": "7408132005",
    "district_id": "740813",
    "label": "Maruge",
    "value": "Maruge"
  },
  {
    "id": "7408132006",
    "district_id": "740813",
    "label": "Lambuno",
    "value": "Lambuno"
  },
  {
    "id": "7408142001",
    "district_id": "740814",
    "label": "Mattiro Bulu",
    "value": "Mattiro Bulu"
  },
  {
    "id": "7408142002",
    "district_id": "740814",
    "label": "Lawadia",
    "value": "Lawadia"
  },
  {
    "id": "7408142003",
    "district_id": "740814",
    "label": "Watumea",
    "value": "Watumea"
  },
  {
    "id": "7408142004",
    "district_id": "740814",
    "label": "Tiwu",
    "value": "Tiwu"
  },
  {
    "id": "7408142005",
    "district_id": "740814",
    "label": "Tahibua",
    "value": "Tahibua"
  },
  {
    "id": "7408142006",
    "district_id": "740814",
    "label": "Lapolu",
    "value": "Lapolu"
  },
  {
    "id": "7408142007",
    "district_id": "740814",
    "label": "Tanggeawo",
    "value": "Tanggeawo"
  },
  {
    "id": "7408152001",
    "district_id": "740815",
    "label": "Lawaki Jaya",
    "value": "Lawaki Jaya"
  },
  {
    "id": "7408152002",
    "district_id": "740815",
    "label": "Patikala",
    "value": "Patikala"
  },
  {
    "id": "7408152003",
    "district_id": "740815",
    "label": "Tolala",
    "value": "Tolala"
  },
  {
    "id": "7408152004",
    "district_id": "740815",
    "label": "Bahari",
    "value": "Bahari"
  },
  {
    "id": "7408152005",
    "district_id": "740815",
    "label": "Leleulu",
    "value": "Leleulu"
  },
  {
    "id": "7408152006",
    "district_id": "740815",
    "label": "Loka",
    "value": "Loka"
  },
  {
    "id": "7409011005",
    "district_id": "740901",
    "label": "Wanggudu",
    "value": "Wanggudu"
  },
  {
    "id": "7409011009",
    "district_id": "740901",
    "label": "Asera",
    "value": "Asera"
  },
  {
    "id": "7409012006",
    "district_id": "740901",
    "label": "Wanggudu Raya",
    "value": "Wanggudu Raya"
  },
  {
    "id": "7409012007",
    "district_id": "740901",
    "label": "Walalindu",
    "value": "Walalindu"
  },
  {
    "id": "7409012008",
    "district_id": "740901",
    "label": "Tangguluri",
    "value": "Tangguluri"
  },
  {
    "id": "7409012010",
    "district_id": "740901",
    "label": "Amorome",
    "value": "Amorome"
  },
  {
    "id": "7409012016",
    "district_id": "740901",
    "label": "Wawolimbue",
    "value": "Wawolimbue"
  },
  {
    "id": "7409012017",
    "district_id": "740901",
    "label": "Walasolo",
    "value": "Walasolo"
  },
  {
    "id": "7409012020",
    "district_id": "740901",
    "label": "Wunduhaka",
    "value": "Wunduhaka"
  },
  {
    "id": "7409012024",
    "district_id": "740901",
    "label": "Tapuwatu",
    "value": "Tapuwatu"
  },
  {
    "id": "7409012027",
    "district_id": "740901",
    "label": "Aseminunulai",
    "value": "Aseminunulai"
  },
  {
    "id": "7409012030",
    "district_id": "740901",
    "label": "Puunggomosi",
    "value": "Puunggomosi"
  },
  {
    "id": "7409012031",
    "district_id": "740901",
    "label": "Puuwanggudu",
    "value": "Puuwanggudu"
  },
  {
    "id": "7409012032",
    "district_id": "740901",
    "label": "Longeo Utama",
    "value": "Longeo Utama"
  },
  {
    "id": "7409012033",
    "district_id": "740901",
    "label": "Kota Mulya",
    "value": "Kota Mulya"
  },
  {
    "id": "7409012034",
    "district_id": "740901",
    "label": "Amorome Utama",
    "value": "Amorome Utama"
  },
  {
    "id": "7409012035",
    "district_id": "740901",
    "label": "Andedao",
    "value": "Andedao"
  },
  {
    "id": "7409012036",
    "district_id": "740901",
    "label": "Alaa Wanggudu",
    "value": "Alaa Wanggudu"
  },
  {
    "id": "7409012037",
    "district_id": "740901",
    "label": "Oheo Trans",
    "value": "Oheo Trans"
  },
  {
    "id": "7409021001",
    "district_id": "740902",
    "label": "Lamonae",
    "value": "Lamonae"
  },
  {
    "id": "7409022002",
    "district_id": "740902",
    "label": "Padalere",
    "value": "Padalere"
  },
  {
    "id": "7409022004",
    "district_id": "740902",
    "label": "Tetewatu",
    "value": "Tetewatu"
  },
  {
    "id": "7409022006",
    "district_id": "740902",
    "label": "Culumbatu",
    "value": "Culumbatu"
  },
  {
    "id": "7409022007",
    "district_id": "740902",
    "label": "Wawoheo",
    "value": "Wawoheo"
  },
  {
    "id": "7409022009",
    "district_id": "740902",
    "label": "Lamparinga",
    "value": "Lamparinga"
  },
  {
    "id": "7409022010",
    "district_id": "740902",
    "label": "Lamonae Utama",
    "value": "Lamonae Utama"
  },
  {
    "id": "7409022012",
    "district_id": "740902",
    "label": "Wacumelewe",
    "value": "Wacumelewe"
  },
  {
    "id": "7409022013",
    "district_id": "740902",
    "label": "Mata Osole",
    "value": "Mata Osole"
  },
  {
    "id": "7409022014",
    "district_id": "740902",
    "label": "Pondoa",
    "value": "Pondoa"
  },
  {
    "id": "7409022020",
    "district_id": "740902",
    "label": "Wawonsangi",
    "value": "Wawonsangi"
  },
  {
    "id": "7409022021",
    "district_id": "740902",
    "label": "Wawontoaho",
    "value": "Wawontoaho"
  },
  {
    "id": "7409022022",
    "district_id": "740902",
    "label": "Larompana",
    "value": "Larompana"
  },
  {
    "id": "7409022023",
    "district_id": "740902",
    "label": "Wacu Pinodo",
    "value": "Wacu Pinodo"
  },
  {
    "id": "7409022024",
    "district_id": "740902",
    "label": "Padalere Utama",
    "value": "Padalere Utama"
  },
  {
    "id": "7409031003",
    "district_id": "740903",
    "label": "Langgikima",
    "value": "Langgikima"
  },
  {
    "id": "7409032001",
    "district_id": "740903",
    "label": "Molore",
    "value": "Molore"
  },
  {
    "id": "7409032002",
    "district_id": "740903",
    "label": "Lameruru",
    "value": "Lameruru"
  },
  {
    "id": "7409032004",
    "district_id": "740903",
    "label": "Sarimukti",
    "value": "Sarimukti"
  },
  {
    "id": "7409032005",
    "district_id": "740903",
    "label": "Morombo Pantai",
    "value": "Morombo Pantai"
  },
  {
    "id": "7409032006",
    "district_id": "740903",
    "label": "Polora Indah",
    "value": "Polora Indah"
  },
  {
    "id": "7409032007",
    "district_id": "740903",
    "label": "Pariama",
    "value": "Pariama"
  },
  {
    "id": "7409032008",
    "district_id": "740903",
    "label": "Tobimeita",
    "value": "Tobimeita"
  },
  {
    "id": "7409032009",
    "district_id": "740903",
    "label": "Alenggo",
    "value": "Alenggo"
  },
  {
    "id": "7409032010",
    "district_id": "740903",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7409032011",
    "district_id": "740903",
    "label": "Molore Pantai",
    "value": "Molore Pantai"
  },
  {
    "id": "7409032012",
    "district_id": "740903",
    "label": "Ngapa Inia",
    "value": "Ngapa Inia"
  },
  {
    "id": "7409041002",
    "district_id": "740904",
    "label": "Molawe",
    "value": "Molawe"
  },
  {
    "id": "7409042001",
    "district_id": "740904",
    "label": "Bandaeha",
    "value": "Bandaeha"
  },
  {
    "id": "7409042003",
    "district_id": "740904",
    "label": "Awila",
    "value": "Awila"
  },
  {
    "id": "7409042004",
    "district_id": "740904",
    "label": "Mowundo",
    "value": "Mowundo"
  },
  {
    "id": "7409042005",
    "district_id": "740904",
    "label": "Mandiodo",
    "value": "Mandiodo"
  },
  {
    "id": "7409042006",
    "district_id": "740904",
    "label": "Tapunggaya",
    "value": "Tapunggaya"
  },
  {
    "id": "7409042007",
    "district_id": "740904",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7409042008",
    "district_id": "740904",
    "label": "Awila Puncak",
    "value": "Awila Puncak"
  },
  {
    "id": "7409042009",
    "district_id": "740904",
    "label": "Tapuemea",
    "value": "Tapuemea"
  },
  {
    "id": "7409051001",
    "district_id": "740905",
    "label": "Tinobu",
    "value": "Tinobu"
  },
  {
    "id": "7409052006",
    "district_id": "740905",
    "label": "Abola",
    "value": "Abola"
  },
  {
    "id": "7409052007",
    "district_id": "740905",
    "label": "Belalo",
    "value": "Belalo"
  },
  {
    "id": "7409052008",
    "district_id": "740905",
    "label": "Andeo",
    "value": "Andeo"
  },
  {
    "id": "7409052009",
    "district_id": "740905",
    "label": "Matapila",
    "value": "Matapila"
  },
  {
    "id": "7409052010",
    "district_id": "740905",
    "label": "Andumowu",
    "value": "Andumowu"
  },
  {
    "id": "7409052011",
    "district_id": "740905",
    "label": "Basule",
    "value": "Basule"
  },
  {
    "id": "7409052012",
    "district_id": "740905",
    "label": "Waworaha",
    "value": "Waworaha"
  },
  {
    "id": "7409052013",
    "district_id": "740905",
    "label": "Otole",
    "value": "Otole"
  },
  {
    "id": "7409052016",
    "district_id": "740905",
    "label": "Watukila",
    "value": "Watukila"
  },
  {
    "id": "7409052017",
    "district_id": "740905",
    "label": "Lametono",
    "value": "Lametono"
  },
  {
    "id": "7409052020",
    "district_id": "740905",
    "label": "Larodangge",
    "value": "Larodangge"
  },
  {
    "id": "7409052024",
    "district_id": "740905",
    "label": "Tetelupai",
    "value": "Tetelupai"
  },
  {
    "id": "7409052025",
    "district_id": "740905",
    "label": "Lalowaru",
    "value": "Lalowaru"
  },
  {
    "id": "7409052026",
    "district_id": "740905",
    "label": "Muara Tinobu",
    "value": "Muara Tinobu"
  },
  {
    "id": "7409052030",
    "district_id": "740905",
    "label": "Marombo Pantai",
    "value": "Marombo Pantai"
  },
  {
    "id": "7409052031",
    "district_id": "740905",
    "label": "Tokowuta",
    "value": "Tokowuta"
  },
  {
    "id": "7409061004",
    "district_id": "740906",
    "label": "Lembo",
    "value": "Lembo"
  },
  {
    "id": "7409062001",
    "district_id": "740906",
    "label": "Alo Alo",
    "value": "Alo Alo"
  },
  {
    "id": "7409062002",
    "district_id": "740906",
    "label": "Puulemo",
    "value": "Puulemo"
  },
  {
    "id": "7409062003",
    "district_id": "740906",
    "label": "Padaleu",
    "value": "Padaleu"
  },
  {
    "id": "7409062005",
    "district_id": "740906",
    "label": "Bungguosu",
    "value": "Bungguosu"
  },
  {
    "id": "7409062006",
    "district_id": "740906",
    "label": "Puusiambu",
    "value": "Puusiambu"
  },
  {
    "id": "7409062007",
    "district_id": "740906",
    "label": "Taipa",
    "value": "Taipa"
  },
  {
    "id": "7409062008",
    "district_id": "740906",
    "label": "Tongalino",
    "value": "Tongalino"
  },
  {
    "id": "7409062009",
    "district_id": "740906",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7409062010",
    "district_id": "740906",
    "label": "Laramo",
    "value": "Laramo"
  },
  {
    "id": "7409062011",
    "district_id": "740906",
    "label": "Lapulu",
    "value": "Lapulu"
  },
  {
    "id": "7409062012",
    "district_id": "740906",
    "label": "Watuwula",
    "value": "Watuwula"
  },
  {
    "id": "7409071002",
    "district_id": "740907",
    "label": "Sawa",
    "value": "Sawa"
  },
  {
    "id": "7409072003",
    "district_id": "740907",
    "label": "Lalembo",
    "value": "Lalembo"
  },
  {
    "id": "7409072004",
    "district_id": "740907",
    "label": "Laimeo",
    "value": "Laimeo"
  },
  {
    "id": "7409072005",
    "district_id": "740907",
    "label": "Pudonggala",
    "value": "Pudonggala"
  },
  {
    "id": "7409072007",
    "district_id": "740907",
    "label": "Matanggonawe",
    "value": "Matanggonawe"
  },
  {
    "id": "7409072008",
    "district_id": "740907",
    "label": "Kokapi",
    "value": "Kokapi"
  },
  {
    "id": "7409072012",
    "district_id": "740907",
    "label": "Tongauna",
    "value": "Tongauna"
  },
  {
    "id": "7409072013",
    "district_id": "740907",
    "label": "Puupi",
    "value": "Puupi"
  },
  {
    "id": "7409072016",
    "district_id": "740907",
    "label": "Pudonggala Utama",
    "value": "Pudonggala Utama"
  },
  {
    "id": "7409072017",
    "district_id": "740907",
    "label": "Ulu Sawa",
    "value": "Ulu Sawa"
  },
  {
    "id": "7409072021",
    "district_id": "740907",
    "label": "Tudungano",
    "value": "Tudungano"
  },
  {
    "id": "7409072022",
    "district_id": "740907",
    "label": "Tanjung Laimeo",
    "value": "Tanjung Laimeo"
  },
  {
    "id": "7409072023",
    "district_id": "740907",
    "label": "Panggulawu",
    "value": "Panggulawu"
  },
  {
    "id": "7409072024",
    "district_id": "740907",
    "label": "Pekaroa",
    "value": "Pekaroa"
  },
  {
    "id": "7409081009",
    "district_id": "740908",
    "label": "Linomoiyo",
    "value": "Linomoiyo"
  },
  {
    "id": "7409082001",
    "district_id": "740908",
    "label": "Laronaha",
    "value": "Laronaha"
  },
  {
    "id": "7409082002",
    "district_id": "740908",
    "label": "Bandaeha",
    "value": "Bandaeha"
  },
  {
    "id": "7409082003",
    "district_id": "740908",
    "label": "Kota Maju",
    "value": "Kota Maju"
  },
  {
    "id": "7409082004",
    "district_id": "740908",
    "label": "Landawe",
    "value": "Landawe"
  },
  {
    "id": "7409082005",
    "district_id": "740908",
    "label": "Wiwirano",
    "value": "Wiwirano"
  },
  {
    "id": "7409082006",
    "district_id": "740908",
    "label": "Mopute",
    "value": "Mopute"
  },
  {
    "id": "7409082007",
    "district_id": "740908",
    "label": "Tadoloiyo",
    "value": "Tadoloiyo"
  },
  {
    "id": "7409082008",
    "district_id": "740908",
    "label": "Puuhialu",
    "value": "Puuhialu"
  },
  {
    "id": "7409082010",
    "district_id": "740908",
    "label": "Sambandete",
    "value": "Sambandete"
  },
  {
    "id": "7409082011",
    "district_id": "740908",
    "label": "Paka Indah",
    "value": "Paka Indah"
  },
  {
    "id": "7409082013",
    "district_id": "740908",
    "label": "Lameoru",
    "value": "Lameoru"
  },
  {
    "id": "7409082014",
    "district_id": "740908",
    "label": "Walandawe",
    "value": "Walandawe"
  },
  {
    "id": "7409082015",
    "district_id": "740908",
    "label": "Bendewuta",
    "value": "Bendewuta"
  },
  {
    "id": "7409082016",
    "district_id": "740908",
    "label": "Tinondo",
    "value": "Tinondo"
  },
  {
    "id": "7409082018",
    "district_id": "740908",
    "label": "Horoe",
    "value": "Horoe"
  },
  {
    "id": "7409082019",
    "district_id": "740908",
    "label": "Tadoloiyo Trans",
    "value": "Tadoloiyo Trans"
  },
  {
    "id": "7409091001",
    "district_id": "740909",
    "label": "Andowia",
    "value": "Andowia"
  },
  {
    "id": "7409092002",
    "district_id": "740909",
    "label": "Lahimbua",
    "value": "Lahimbua"
  },
  {
    "id": "7409092003",
    "district_id": "740909",
    "label": "Laronanga",
    "value": "Laronanga"
  },
  {
    "id": "7409092004",
    "district_id": "740909",
    "label": "Lambudoni",
    "value": "Lambudoni"
  },
  {
    "id": "7409092005",
    "district_id": "740909",
    "label": "Lamondowo",
    "value": "Lamondowo"
  },
  {
    "id": "7409092006",
    "district_id": "740909",
    "label": "Labungga",
    "value": "Labungga"
  },
  {
    "id": "7409092007",
    "district_id": "740909",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7409092008",
    "district_id": "740909",
    "label": "Larobende",
    "value": "Larobende"
  },
  {
    "id": "7409092009",
    "district_id": "740909",
    "label": "Puusuli",
    "value": "Puusuli"
  },
  {
    "id": "7409092010",
    "district_id": "740909",
    "label": "Banggarema",
    "value": "Banggarema"
  },
  {
    "id": "7409092011",
    "district_id": "740909",
    "label": "Amolame",
    "value": "Amolame"
  },
  {
    "id": "7409092012",
    "district_id": "740909",
    "label": "Anggolohipo",
    "value": "Anggolohipo"
  },
  {
    "id": "7409092013",
    "district_id": "740909",
    "label": "Puuwonua",
    "value": "Puuwonua"
  },
  {
    "id": "7409092014",
    "district_id": "740909",
    "label": "Ambake",
    "value": "Ambake"
  },
  {
    "id": "7409092015",
    "district_id": "740909",
    "label": "Waworate",
    "value": "Waworate"
  },
  {
    "id": "7409101002",
    "district_id": "740910",
    "label": "Bende",
    "value": "Bende"
  },
  {
    "id": "7409102001",
    "district_id": "740910",
    "label": "Tondowatu",
    "value": "Tondowatu"
  },
  {
    "id": "7409102003",
    "district_id": "740910",
    "label": "Matandahi",
    "value": "Matandahi"
  },
  {
    "id": "7409102004",
    "district_id": "740910",
    "label": "Banggina",
    "value": "Banggina"
  },
  {
    "id": "7409102005",
    "district_id": "740910",
    "label": "Motui",
    "value": "Motui"
  },
  {
    "id": "7409102006",
    "district_id": "740910",
    "label": "Wawoluri",
    "value": "Wawoluri"
  },
  {
    "id": "7409102007",
    "district_id": "740910",
    "label": "Lambuluo",
    "value": "Lambuluo"
  },
  {
    "id": "7409102008",
    "district_id": "740910",
    "label": "Poni Poniki",
    "value": "Poni Poniki"
  },
  {
    "id": "7409102009",
    "district_id": "740910",
    "label": "Puuwonggia",
    "value": "Puuwonggia"
  },
  {
    "id": "7409102010",
    "district_id": "740910",
    "label": "Punggulahi",
    "value": "Punggulahi"
  },
  {
    "id": "7409102011",
    "district_id": "740910",
    "label": "Sama Subur",
    "value": "Sama Subur"
  },
  {
    "id": "7409102012",
    "district_id": "740910",
    "label": "Ranombupulu",
    "value": "Ranombupulu"
  },
  {
    "id": "7409102013",
    "district_id": "740910",
    "label": "Tobimeita",
    "value": "Tobimeita"
  },
  {
    "id": "7409102014",
    "district_id": "740910",
    "label": "Kapolano",
    "value": "Kapolano"
  },
  {
    "id": "7409102015",
    "district_id": "740910",
    "label": "Sambasule",
    "value": "Sambasule"
  },
  {
    "id": "7409112001",
    "district_id": "740911",
    "label": "Tanjung Bunga",
    "value": "Tanjung Bunga"
  },
  {
    "id": "7409112002",
    "district_id": "740911",
    "label": "Kampoh Bunga",
    "value": "Kampoh Bunga"
  },
  {
    "id": "7409112003",
    "district_id": "740911",
    "label": "Lemo Bajo",
    "value": "Lemo Bajo"
  },
  {
    "id": "7409112004",
    "district_id": "740911",
    "label": "Barasanga",
    "value": "Barasanga"
  },
  {
    "id": "7409112005",
    "district_id": "740911",
    "label": "Wawolesea",
    "value": "Wawolesea"
  },
  {
    "id": "7409112006",
    "district_id": "740911",
    "label": "Toreo",
    "value": "Toreo"
  },
  {
    "id": "7409112007",
    "district_id": "740911",
    "label": "Otipulu",
    "value": "Otipulu"
  },
  {
    "id": "7409112009",
    "district_id": "740911",
    "label": "Kampoh Cina",
    "value": "Kampoh Cina"
  },
  {
    "id": "7409122001",
    "district_id": "740912",
    "label": "Boenaga",
    "value": "Boenaga"
  },
  {
    "id": "7409122002",
    "district_id": "740912",
    "label": "Waturambaha",
    "value": "Waturambaha"
  },
  {
    "id": "7409122003",
    "district_id": "740912",
    "label": "Labengki",
    "value": "Labengki"
  },
  {
    "id": "7409122004",
    "district_id": "740912",
    "label": "Morombo",
    "value": "Morombo"
  },
  {
    "id": "7409122005",
    "district_id": "740912",
    "label": "Tapunopaka",
    "value": "Tapunopaka"
  },
  {
    "id": "7409122006",
    "district_id": "740912",
    "label": "Boedingi",
    "value": "Boedingi"
  },
  {
    "id": "7409132001",
    "district_id": "740913",
    "label": "Kuratao",
    "value": "Kuratao"
  },
  {
    "id": "7409132002",
    "district_id": "740913",
    "label": "Landiwo",
    "value": "Landiwo"
  },
  {
    "id": "7409132003",
    "district_id": "740913",
    "label": "Kolosua",
    "value": "Kolosua"
  },
  {
    "id": "7409132004",
    "district_id": "740913",
    "label": "Laumoso",
    "value": "Laumoso"
  },
  {
    "id": "7409132005",
    "district_id": "740913",
    "label": "Hialu Utama",
    "value": "Hialu Utama"
  },
  {
    "id": "7409132006",
    "district_id": "740913",
    "label": "Polo-polora",
    "value": "Polo-polora"
  },
  {
    "id": "7409132007",
    "district_id": "740913",
    "label": "Tambakua",
    "value": "Tambakua"
  },
  {
    "id": "7409132008",
    "district_id": "740913",
    "label": "Matabaho",
    "value": "Matabaho"
  },
  {
    "id": "7409132009",
    "district_id": "740913",
    "label": "Landawe Utama",
    "value": "Landawe Utama"
  },
  {
    "id": "7409132010",
    "district_id": "740913",
    "label": "Mata Benua",
    "value": "Mata Benua"
  },
  {
    "id": "7409132011",
    "district_id": "740913",
    "label": "Hialu",
    "value": "Hialu"
  },
  {
    "id": "7410011008",
    "district_id": "741001",
    "label": "Bangkudu",
    "value": "Bangkudu"
  },
  {
    "id": "7410011009",
    "district_id": "741001",
    "label": "Lipu",
    "value": "Lipu"
  },
  {
    "id": "7410011010",
    "district_id": "741001",
    "label": "Lakonea",
    "value": "Lakonea"
  },
  {
    "id": "7410011012",
    "district_id": "741001",
    "label": "Lemo",
    "value": "Lemo"
  },
  {
    "id": "7410011014",
    "district_id": "741001",
    "label": "Bonelipu",
    "value": "Bonelipu"
  },
  {
    "id": "7410011016",
    "district_id": "741001",
    "label": "Sara'ea",
    "value": "Sara'ea"
  },
  {
    "id": "7410011017",
    "district_id": "741001",
    "label": "Wandaka",
    "value": "Wandaka"
  },
  {
    "id": "7410012001",
    "district_id": "741001",
    "label": "Tri Wacu-Wacu",
    "value": "Tri Wacu-Wacu"
  },
  {
    "id": "7410012002",
    "district_id": "741001",
    "label": "Waculaea",
    "value": "Waculaea"
  },
  {
    "id": "7410012003",
    "district_id": "741001",
    "label": "Eelahaji",
    "value": "Eelahaji"
  },
  {
    "id": "7410012004",
    "district_id": "741001",
    "label": "Tomoahi",
    "value": "Tomoahi"
  },
  {
    "id": "7410012005",
    "district_id": "741001",
    "label": "Kalibu",
    "value": "Kalibu"
  },
  {
    "id": "7410012006",
    "district_id": "741001",
    "label": "Jampaka",
    "value": "Jampaka"
  },
  {
    "id": "7410012007",
    "district_id": "741001",
    "label": "Loji",
    "value": "Loji"
  },
  {
    "id": "7410012011",
    "district_id": "741001",
    "label": "Linsowu",
    "value": "Linsowu"
  },
  {
    "id": "7410012013",
    "district_id": "741001",
    "label": "Rombo",
    "value": "Rombo"
  },
  {
    "id": "7410012018",
    "district_id": "741001",
    "label": "Wasalabose",
    "value": "Wasalabose"
  },
  {
    "id": "7410012019",
    "district_id": "741001",
    "label": "Malalanda",
    "value": "Malalanda"
  },
  {
    "id": "7410012020",
    "district_id": "741001",
    "label": "Lemo Ea",
    "value": "Lemo Ea"
  },
  {
    "id": "7410012021",
    "district_id": "741001",
    "label": "Lantagi",
    "value": "Lantagi"
  },
  {
    "id": "7410012022",
    "district_id": "741001",
    "label": "Laangke",
    "value": "Laangke"
  },
  {
    "id": "7410012023",
    "district_id": "741001",
    "label": "Kadacua",
    "value": "Kadacua"
  },
  {
    "id": "7410012024",
    "district_id": "741001",
    "label": "Banu-Banua Jaya",
    "value": "Banu-Banua Jaya"
  },
  {
    "id": "7410021004",
    "district_id": "741002",
    "label": "Kambowa",
    "value": "Kambowa"
  },
  {
    "id": "7410022001",
    "district_id": "741002",
    "label": "Bubu",
    "value": "Bubu"
  },
  {
    "id": "7410022002",
    "district_id": "741002",
    "label": "Lahumoko",
    "value": "Lahumoko"
  },
  {
    "id": "7410022003",
    "district_id": "741002",
    "label": "Morindino",
    "value": "Morindino"
  },
  {
    "id": "7410022005",
    "district_id": "741002",
    "label": "Pongkowulu",
    "value": "Pongkowulu"
  },
  {
    "id": "7410022006",
    "district_id": "741002",
    "label": "Lagundi",
    "value": "Lagundi"
  },
  {
    "id": "7410022007",
    "district_id": "741002",
    "label": "Konde",
    "value": "Konde"
  },
  {
    "id": "7410022008",
    "district_id": "741002",
    "label": "Mata",
    "value": "Mata"
  },
  {
    "id": "7410022009",
    "district_id": "741002",
    "label": "Bubu Barat",
    "value": "Bubu Barat"
  },
  {
    "id": "7410022010",
    "district_id": "741002",
    "label": "Bente",
    "value": "Bente"
  },
  {
    "id": "7410022011",
    "district_id": "741002",
    "label": "Baluara",
    "value": "Baluara"
  },
  {
    "id": "7410031006",
    "district_id": "741003",
    "label": "Buranga",
    "value": "Buranga"
  },
  {
    "id": "7410031010",
    "district_id": "741003",
    "label": "Bonegunu",
    "value": "Bonegunu"
  },
  {
    "id": "7410032001",
    "district_id": "741003",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "7410032002",
    "district_id": "741003",
    "label": "Ronta",
    "value": "Ronta"
  },
  {
    "id": "7410032003",
    "district_id": "741003",
    "label": "Waode Angkalo",
    "value": "Waode Angkalo"
  },
  {
    "id": "7410032004",
    "district_id": "741003",
    "label": "Langere",
    "value": "Langere"
  },
  {
    "id": "7410032005",
    "district_id": "741003",
    "label": "Koepisino",
    "value": "Koepisino"
  },
  {
    "id": "7410032007",
    "district_id": "741003",
    "label": "Waode Kalowo",
    "value": "Waode Kalowo"
  },
  {
    "id": "7410032008",
    "district_id": "741003",
    "label": "Laano Ipi",
    "value": "Laano Ipi"
  },
  {
    "id": "7410032009",
    "district_id": "741003",
    "label": "Eensumala",
    "value": "Eensumala"
  },
  {
    "id": "7410032013",
    "district_id": "741003",
    "label": "Tatombuli",
    "value": "Tatombuli"
  },
  {
    "id": "7410032014",
    "district_id": "741003",
    "label": "Rante Gola",
    "value": "Rante Gola"
  },
  {
    "id": "7410032015",
    "district_id": "741003",
    "label": "Ngapa'ea",
    "value": "Ngapa'ea"
  },
  {
    "id": "7410032016",
    "district_id": "741003",
    "label": "Koboruno",
    "value": "Koboruno"
  },
  {
    "id": "7410032017",
    "district_id": "741003",
    "label": "Damai Laborona",
    "value": "Damai Laborona"
  },
  {
    "id": "7410042001",
    "district_id": "741004",
    "label": "Kotawo",
    "value": "Kotawo"
  },
  {
    "id": "7410042002",
    "district_id": "741004",
    "label": "Karya Bhakti",
    "value": "Karya Bhakti"
  },
  {
    "id": "7410042003",
    "district_id": "741004",
    "label": "Marga Karya",
    "value": "Marga Karya"
  },
  {
    "id": "7410042004",
    "district_id": "741004",
    "label": "Kasulatombi",
    "value": "Kasulatombi"
  },
  {
    "id": "7410042005",
    "district_id": "741004",
    "label": "Dampala Jaya",
    "value": "Dampala Jaya"
  },
  {
    "id": "7410042006",
    "district_id": "741004",
    "label": "Rahmat Baru",
    "value": "Rahmat Baru"
  },
  {
    "id": "7410042007",
    "district_id": "741004",
    "label": "Soloy Agung",
    "value": "Soloy Agung"
  },
  {
    "id": "7410042008",
    "district_id": "741004",
    "label": "Bumi Lapero",
    "value": "Bumi Lapero"
  },
  {
    "id": "7410042009",
    "district_id": "741004",
    "label": "Karya Mulya",
    "value": "Karya Mulya"
  },
  {
    "id": "7410042010",
    "district_id": "741004",
    "label": "Lambale",
    "value": "Lambale"
  },
  {
    "id": "7410042011",
    "district_id": "741004",
    "label": "Lapandewa",
    "value": "Lapandewa"
  },
  {
    "id": "7410042012",
    "district_id": "741004",
    "label": "Lauki",
    "value": "Lauki"
  },
  {
    "id": "7410042013",
    "district_id": "741004",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7410042014",
    "district_id": "741004",
    "label": "Labulanda",
    "value": "Labulanda"
  },
  {
    "id": "7410052001",
    "district_id": "741005",
    "label": "Kurolabu",
    "value": "Kurolabu"
  },
  {
    "id": "7410052002",
    "district_id": "741005",
    "label": "Torombia",
    "value": "Torombia"
  },
  {
    "id": "7410052003",
    "district_id": "741005",
    "label": "Lamoahi",
    "value": "Lamoahi"
  },
  {
    "id": "7410052004",
    "district_id": "741005",
    "label": "Lanosangia",
    "value": "Lanosangia"
  },
  {
    "id": "7410052005",
    "district_id": "741005",
    "label": "Pebaoa",
    "value": "Pebaoa"
  },
  {
    "id": "7410052006",
    "district_id": "741005",
    "label": "Petetea'a",
    "value": "Petetea'a"
  },
  {
    "id": "7410052007",
    "district_id": "741005",
    "label": "Lelamo",
    "value": "Lelamo"
  },
  {
    "id": "7410052008",
    "district_id": "741005",
    "label": "Waode Buri",
    "value": "Waode Buri"
  },
  {
    "id": "7410052009",
    "district_id": "741005",
    "label": "Ulunambo",
    "value": "Ulunambo"
  },
  {
    "id": "7410052010",
    "district_id": "741005",
    "label": "Bira",
    "value": "Bira"
  },
  {
    "id": "7410052011",
    "district_id": "741005",
    "label": "E'erinere",
    "value": "E'erinere"
  },
  {
    "id": "7410052012",
    "district_id": "741005",
    "label": "Labelete",
    "value": "Labelete"
  },
  {
    "id": "7410052013",
    "district_id": "741005",
    "label": "Wamboule",
    "value": "Wamboule"
  },
  {
    "id": "7410052014",
    "district_id": "741005",
    "label": "Wowonga Jaya",
    "value": "Wowonga Jaya"
  },
  {
    "id": "7410061007",
    "district_id": "741006",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "7410061008",
    "district_id": "741006",
    "label": "Labuan Walio",
    "value": "Labuan Walio"
  },
  {
    "id": "7410062001",
    "district_id": "741006",
    "label": "Lasiwa",
    "value": "Lasiwa"
  },
  {
    "id": "7410062002",
    "district_id": "741006",
    "label": "Laeya",
    "value": "Laeya"
  },
  {
    "id": "7410062003",
    "district_id": "741006",
    "label": "Matalagi",
    "value": "Matalagi"
  },
  {
    "id": "7410062004",
    "district_id": "741006",
    "label": "Wantulasi",
    "value": "Wantulasi"
  },
  {
    "id": "7410062005",
    "district_id": "741006",
    "label": "Labuan Bajo",
    "value": "Labuan Bajo"
  },
  {
    "id": "7410062006",
    "district_id": "741006",
    "label": "Labaraga",
    "value": "Labaraga"
  },
  {
    "id": "7410062009",
    "district_id": "741006",
    "label": "Wamorapa",
    "value": "Wamorapa"
  },
  {
    "id": "7410062010",
    "district_id": "741006",
    "label": "Sumampeno",
    "value": "Sumampeno"
  },
  {
    "id": "7410062011",
    "district_id": "741006",
    "label": "Oengkapala",
    "value": "Oengkapala"
  },
  {
    "id": "7410062012",
    "district_id": "741006",
    "label": "Labuko",
    "value": "Labuko"
  },
  {
    "id": "7410062013",
    "district_id": "741006",
    "label": "Laba Jaya",
    "value": "Laba Jaya"
  },
  {
    "id": "7411011002",
    "district_id": "741101",
    "label": "Rate-rate",
    "value": "Rate-rate"
  },
  {
    "id": "7411011016",
    "district_id": "741101",
    "label": "Tababu",
    "value": "Tababu"
  },
  {
    "id": "7411012001",
    "district_id": "741101",
    "label": "Simbune",
    "value": "Simbune"
  },
  {
    "id": "7411012003",
    "district_id": "741101",
    "label": "Poni-Poniki",
    "value": "Poni-Poniki"
  },
  {
    "id": "7411012004",
    "district_id": "741101",
    "label": "Tawainalu",
    "value": "Tawainalu"
  },
  {
    "id": "7411012005",
    "district_id": "741101",
    "label": "Woiha",
    "value": "Woiha"
  },
  {
    "id": "7411012006",
    "district_id": "741101",
    "label": "Tasahea",
    "value": "Tasahea"
  },
  {
    "id": "7411012007",
    "district_id": "741101",
    "label": "Lara",
    "value": "Lara"
  },
  {
    "id": "7411012008",
    "district_id": "741101",
    "label": "Tumbudadio",
    "value": "Tumbudadio"
  },
  {
    "id": "7411012009",
    "district_id": "741101",
    "label": "Orawa",
    "value": "Orawa"
  },
  {
    "id": "7411012010",
    "district_id": "741101",
    "label": "Lalingato",
    "value": "Lalingato"
  },
  {
    "id": "7411012011",
    "district_id": "741101",
    "label": "Tirawuta",
    "value": "Tirawuta"
  },
  {
    "id": "7411012012",
    "district_id": "741101",
    "label": "Roko-roko",
    "value": "Roko-roko"
  },
  {
    "id": "7411012013",
    "district_id": "741101",
    "label": "Loka",
    "value": "Loka"
  },
  {
    "id": "7411012014",
    "district_id": "741101",
    "label": "Matabondu",
    "value": "Matabondu"
  },
  {
    "id": "7411012015",
    "district_id": "741101",
    "label": "Karemotingge",
    "value": "Karemotingge"
  },
  {
    "id": "7411021001",
    "district_id": "741102",
    "label": "Loea",
    "value": "Loea"
  },
  {
    "id": "7411021002",
    "district_id": "741102",
    "label": "Simbalae",
    "value": "Simbalae"
  },
  {
    "id": "7411022003",
    "district_id": "741102",
    "label": "Lamoare",
    "value": "Lamoare"
  },
  {
    "id": "7411022004",
    "district_id": "741102",
    "label": "Iwoikondo",
    "value": "Iwoikondo"
  },
  {
    "id": "7411022005",
    "district_id": "741102",
    "label": "Peatoa",
    "value": "Peatoa"
  },
  {
    "id": "7411022006",
    "district_id": "741102",
    "label": "Lalowura",
    "value": "Lalowura"
  },
  {
    "id": "7411022007",
    "district_id": "741102",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7411022008",
    "district_id": "741102",
    "label": "Teposua",
    "value": "Teposua"
  },
  {
    "id": "7411022009",
    "district_id": "741102",
    "label": "Tinomu",
    "value": "Tinomu"
  },
  {
    "id": "7411031001",
    "district_id": "741103",
    "label": "Ladongi",
    "value": "Ladongi"
  },
  {
    "id": "7411031002",
    "district_id": "741103",
    "label": "Atula",
    "value": "Atula"
  },
  {
    "id": "7411031003",
    "district_id": "741103",
    "label": "Raa-Raa",
    "value": "Raa-Raa"
  },
  {
    "id": "7411031004",
    "district_id": "741103",
    "label": "Welala",
    "value": "Welala"
  },
  {
    "id": "7411032005",
    "district_id": "741103",
    "label": "Putemata",
    "value": "Putemata"
  },
  {
    "id": "7411032009",
    "district_id": "741103",
    "label": "Lalowosula",
    "value": "Lalowosula"
  },
  {
    "id": "7411032010",
    "district_id": "741103",
    "label": "Wunggoloko",
    "value": "Wunggoloko"
  },
  {
    "id": "7411032011",
    "district_id": "741103",
    "label": "Anggaloosi",
    "value": "Anggaloosi"
  },
  {
    "id": "7411032013",
    "district_id": "741103",
    "label": "Pombeyoha",
    "value": "Pombeyoha"
  },
  {
    "id": "7411032018",
    "district_id": "741103",
    "label": "Tongandiu",
    "value": "Tongandiu"
  },
  {
    "id": "7411041001",
    "district_id": "741104",
    "label": "Poli-Polia",
    "value": "Poli-Polia"
  },
  {
    "id": "7411042002",
    "district_id": "741104",
    "label": "Wia-Wia",
    "value": "Wia-Wia"
  },
  {
    "id": "7411042003",
    "district_id": "741104",
    "label": "Pangi-Pangi",
    "value": "Pangi-Pangi"
  },
  {
    "id": "7411042004",
    "district_id": "741104",
    "label": "Tokai",
    "value": "Tokai"
  },
  {
    "id": "7411042005",
    "district_id": "741104",
    "label": "Andowengga",
    "value": "Andowengga"
  },
  {
    "id": "7411042006",
    "district_id": "741104",
    "label": "Taosu",
    "value": "Taosu"
  },
  {
    "id": "7411042007",
    "district_id": "741104",
    "label": "Pole Maju Jaya",
    "value": "Pole Maju Jaya"
  },
  {
    "id": "7411042008",
    "district_id": "741104",
    "label": "Polenga Jaya",
    "value": "Polenga Jaya"
  },
  {
    "id": "7411042009",
    "district_id": "741104",
    "label": "Wundumbite",
    "value": "Wundumbite"
  },
  {
    "id": "7411042010",
    "district_id": "741104",
    "label": "Hakambololi",
    "value": "Hakambololi"
  },
  {
    "id": "7411042011",
    "district_id": "741104",
    "label": "Inotu Mewao",
    "value": "Inotu Mewao"
  },
  {
    "id": "7411042012",
    "district_id": "741104",
    "label": "Puundokulo",
    "value": "Puundokulo"
  },
  {
    "id": "7411051002",
    "district_id": "741105",
    "label": "Penanggo Jaya",
    "value": "Penanggo Jaya"
  },
  {
    "id": "7411052001",
    "district_id": "741105",
    "label": "Wonuambuteo",
    "value": "Wonuambuteo"
  },
  {
    "id": "7411052003",
    "district_id": "741105",
    "label": "Mokupa",
    "value": "Mokupa"
  },
  {
    "id": "7411052005",
    "district_id": "741105",
    "label": "Penanggoosi",
    "value": "Penanggoosi"
  },
  {
    "id": "7411052007",
    "district_id": "741105",
    "label": "Lowa",
    "value": "Lowa"
  },
  {
    "id": "7411052008",
    "district_id": "741105",
    "label": "Atolanu",
    "value": "Atolanu"
  },
  {
    "id": "7411052009",
    "district_id": "741105",
    "label": "Lere Jaya",
    "value": "Lere Jaya"
  },
  {
    "id": "7411052010",
    "district_id": "741105",
    "label": "Bou",
    "value": "Bou"
  },
  {
    "id": "7411052012",
    "district_id": "741105",
    "label": "Lambandia",
    "value": "Lambandia"
  },
  {
    "id": "7411052013",
    "district_id": "741105",
    "label": "Inotu",
    "value": "Inotu"
  },
  {
    "id": "7411052018",
    "district_id": "741105",
    "label": "Lalolere",
    "value": "Lalolere"
  },
  {
    "id": "7411052019",
    "district_id": "741105",
    "label": "Pomborea",
    "value": "Pomborea"
  },
  {
    "id": "7411052021",
    "district_id": "741105",
    "label": "Penanggootu",
    "value": "Penanggootu"
  },
  {
    "id": "7411052022",
    "district_id": "741105",
    "label": "Mondoke",
    "value": "Mondoke"
  },
  {
    "id": "7411052026",
    "district_id": "741105",
    "label": "Onemanu",
    "value": "Onemanu"
  },
  {
    "id": "7411061001",
    "district_id": "741106",
    "label": "Lalolae",
    "value": "Lalolae"
  },
  {
    "id": "7411062002",
    "district_id": "741106",
    "label": "Keisio",
    "value": "Keisio"
  },
  {
    "id": "7411062003",
    "district_id": "741106",
    "label": "Talodo",
    "value": "Talodo"
  },
  {
    "id": "7411062004",
    "district_id": "741106",
    "label": "Lalosingi",
    "value": "Lalosingi"
  },
  {
    "id": "7411062005",
    "district_id": "741106",
    "label": "Wesalo",
    "value": "Wesalo"
  },
  {
    "id": "7411071001",
    "district_id": "741107",
    "label": "Woitombo",
    "value": "Woitombo"
  },
  {
    "id": "7411071002",
    "district_id": "741107",
    "label": "Inebenggi",
    "value": "Inebenggi"
  },
  {
    "id": "7411071004",
    "district_id": "741107",
    "label": "Horodopi",
    "value": "Horodopi"
  },
  {
    "id": "7411072003",
    "district_id": "741107",
    "label": "Nelombu",
    "value": "Nelombu"
  },
  {
    "id": "7411072005",
    "district_id": "741107",
    "label": "Watupute",
    "value": "Watupute"
  },
  {
    "id": "7411072006",
    "district_id": "741107",
    "label": "Puosu",
    "value": "Puosu"
  },
  {
    "id": "7411072007",
    "district_id": "741107",
    "label": "Ulu Mowewe",
    "value": "Ulu Mowewe"
  },
  {
    "id": "7411072008",
    "district_id": "741107",
    "label": "Lapangisi",
    "value": "Lapangisi"
  },
  {
    "id": "7411072009",
    "district_id": "741107",
    "label": "Sabi-sabila",
    "value": "Sabi-sabila"
  },
  {
    "id": "7411072010",
    "district_id": "741107",
    "label": "Lambo Tua",
    "value": "Lambo Tua"
  },
  {
    "id": "7411081006",
    "district_id": "741108",
    "label": "Sanggona",
    "value": "Sanggona"
  },
  {
    "id": "7411082003",
    "district_id": "741108",
    "label": "Pehanggo",
    "value": "Pehanggo"
  },
  {
    "id": "7411082004",
    "district_id": "741108",
    "label": "Tondowatu",
    "value": "Tondowatu"
  },
  {
    "id": "7411082005",
    "district_id": "741108",
    "label": "Tawanga",
    "value": "Tawanga"
  },
  {
    "id": "7411082007",
    "district_id": "741108",
    "label": "Uete",
    "value": "Uete"
  },
  {
    "id": "7411082008",
    "district_id": "741108",
    "label": "Undolo",
    "value": "Undolo"
  },
  {
    "id": "7411082010",
    "district_id": "741108",
    "label": "Lalombai",
    "value": "Lalombai"
  },
  {
    "id": "7411082014",
    "district_id": "741108",
    "label": "Amokuni",
    "value": "Amokuni"
  },
  {
    "id": "7411082018",
    "district_id": "741108",
    "label": "Amololu",
    "value": "Amololu"
  },
  {
    "id": "7411082019",
    "district_id": "741108",
    "label": "Aukora",
    "value": "Aukora"
  },
  {
    "id": "7411091001",
    "district_id": "741109",
    "label": "Tinengi",
    "value": "Tinengi"
  },
  {
    "id": "7411092002",
    "district_id": "741109",
    "label": "Solewatu",
    "value": "Solewatu"
  },
  {
    "id": "7411092003",
    "district_id": "741109",
    "label": "Ambapa",
    "value": "Ambapa"
  },
  {
    "id": "7411092004",
    "district_id": "741109",
    "label": "Ameroro",
    "value": "Ameroro"
  },
  {
    "id": "7411092005",
    "district_id": "741109",
    "label": "Lamunde",
    "value": "Lamunde"
  },
  {
    "id": "7411092006",
    "district_id": "741109",
    "label": "Tawarombadaka",
    "value": "Tawarombadaka"
  },
  {
    "id": "7411092007",
    "district_id": "741109",
    "label": "Talata",
    "value": "Talata"
  },
  {
    "id": "7411092008",
    "district_id": "741109",
    "label": "Weamo",
    "value": "Weamo"
  },
  {
    "id": "7411092009",
    "district_id": "741109",
    "label": "Tawa-tawaro",
    "value": "Tawa-tawaro"
  },
  {
    "id": "7411092010",
    "district_id": "741109",
    "label": "Singgere",
    "value": "Singgere"
  },
  {
    "id": "7411092011",
    "district_id": "741109",
    "label": "Tutuwi",
    "value": "Tutuwi"
  },
  {
    "id": "7411092012",
    "district_id": "741109",
    "label": "Iwoimea",
    "value": "Iwoimea"
  },
  {
    "id": "7411102001",
    "district_id": "741110",
    "label": "Aere",
    "value": "Aere"
  },
  {
    "id": "7411102002",
    "district_id": "741110",
    "label": "Pekorea",
    "value": "Pekorea"
  },
  {
    "id": "7411102003",
    "district_id": "741110",
    "label": "Iwoi Menggura",
    "value": "Iwoi Menggura"
  },
  {
    "id": "7411102004",
    "district_id": "741110",
    "label": "Iwoimea Jaya",
    "value": "Iwoimea Jaya"
  },
  {
    "id": "7411102005",
    "district_id": "741110",
    "label": "Awiu",
    "value": "Awiu"
  },
  {
    "id": "7411102006",
    "district_id": "741110",
    "label": "Aladadio",
    "value": "Aladadio"
  },
  {
    "id": "7411102007",
    "district_id": "741110",
    "label": "Tinete",
    "value": "Tinete"
  },
  {
    "id": "7411102008",
    "district_id": "741110",
    "label": "Taore",
    "value": "Taore"
  },
  {
    "id": "7411102009",
    "district_id": "741110",
    "label": "Ulundoro",
    "value": "Ulundoro"
  },
  {
    "id": "7411102010",
    "district_id": "741110",
    "label": "Watuwoha",
    "value": "Watuwoha"
  },
  {
    "id": "7411102011",
    "district_id": "741110",
    "label": "Rubia",
    "value": "Rubia"
  },
  {
    "id": "7411112001",
    "district_id": "741111",
    "label": "Ueesi",
    "value": "Ueesi"
  },
  {
    "id": "7411112002",
    "district_id": "741111",
    "label": "Wesinggote",
    "value": "Wesinggote"
  },
  {
    "id": "7411112003",
    "district_id": "741111",
    "label": "Konawendepiha",
    "value": "Konawendepiha"
  },
  {
    "id": "7411112004",
    "district_id": "741111",
    "label": "Porabua",
    "value": "Porabua"
  },
  {
    "id": "7411112005",
    "district_id": "741111",
    "label": "Watumendonga",
    "value": "Watumendonga"
  },
  {
    "id": "7411112006",
    "district_id": "741111",
    "label": "Silui",
    "value": "Silui"
  },
  {
    "id": "7411112007",
    "district_id": "741111",
    "label": "Ahilulu",
    "value": "Ahilulu"
  },
  {
    "id": "7411112008",
    "district_id": "741111",
    "label": "Alaaha",
    "value": "Alaaha"
  },
  {
    "id": "7411112009",
    "district_id": "741111",
    "label": "Likuwalanapo",
    "value": "Likuwalanapo"
  },
  {
    "id": "7411112010",
    "district_id": "741111",
    "label": "Tongauna",
    "value": "Tongauna"
  },
  {
    "id": "7411112011",
    "district_id": "741111",
    "label": "Puurau",
    "value": "Puurau"
  },
  {
    "id": "7411122001",
    "district_id": "741112",
    "label": "Gunung Jaya",
    "value": "Gunung Jaya"
  },
  {
    "id": "7411122002",
    "district_id": "741112",
    "label": "Lembah Subur",
    "value": "Lembah Subur"
  },
  {
    "id": "7411122003",
    "district_id": "741112",
    "label": "Dangia",
    "value": "Dangia"
  },
  {
    "id": "7411122004",
    "district_id": "741112",
    "label": "Lamosila",
    "value": "Lamosila"
  },
  {
    "id": "7411122005",
    "district_id": "741112",
    "label": "Tetewua",
    "value": "Tetewua"
  },
  {
    "id": "7411122006",
    "district_id": "741112",
    "label": "Anambada",
    "value": "Anambada"
  },
  {
    "id": "7411122007",
    "district_id": "741112",
    "label": "Talinduka",
    "value": "Talinduka"
  },
  {
    "id": "7411122008",
    "district_id": "741112",
    "label": "Tetembuta",
    "value": "Tetembuta"
  },
  {
    "id": "7411122009",
    "district_id": "741112",
    "label": "Lalongkateba",
    "value": "Lalongkateba"
  },
  {
    "id": "7411122010",
    "district_id": "741112",
    "label": "Wande",
    "value": "Wande"
  },
  {
    "id": "7411122011",
    "district_id": "741112",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7411122012",
    "district_id": "741112",
    "label": "Mulia Jaya",
    "value": "Mulia Jaya"
  },
  {
    "id": "7412011001",
    "district_id": "741201",
    "label": "Langara Laut",
    "value": "Langara Laut"
  },
  {
    "id": "7412012002",
    "district_id": "741201",
    "label": "Langara Iwawo",
    "value": "Langara Iwawo"
  },
  {
    "id": "7412012003",
    "district_id": "741201",
    "label": "Wawolaa",
    "value": "Wawolaa"
  },
  {
    "id": "7412012004",
    "district_id": "741201",
    "label": "Langara Indah",
    "value": "Langara Indah"
  },
  {
    "id": "7412012005",
    "district_id": "741201",
    "label": "Lamoluo",
    "value": "Lamoluo"
  },
  {
    "id": "7412012006",
    "district_id": "741201",
    "label": "Bukit Permai",
    "value": "Bukit Permai"
  },
  {
    "id": "7412012007",
    "district_id": "741201",
    "label": "Wawobili",
    "value": "Wawobili"
  },
  {
    "id": "7412012008",
    "district_id": "741201",
    "label": "Langaro Bajo",
    "value": "Langaro Bajo"
  },
  {
    "id": "7412012009",
    "district_id": "741201",
    "label": "Mata Langara",
    "value": "Mata Langara"
  },
  {
    "id": "7412012010",
    "district_id": "741201",
    "label": "Mata Baho",
    "value": "Mata Baho"
  },
  {
    "id": "7412012011",
    "district_id": "741201",
    "label": "Lanowatu",
    "value": "Lanowatu"
  },
  {
    "id": "7412012012",
    "district_id": "741201",
    "label": "Lantula",
    "value": "Lantula"
  },
  {
    "id": "7412012013",
    "district_id": "741201",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "7412012014",
    "district_id": "741201",
    "label": "langkowala",
    "value": "langkowala"
  },
  {
    "id": "7412012015",
    "district_id": "741201",
    "label": "Kawa-Kawali",
    "value": "Kawa-Kawali"
  },
  {
    "id": "7412012016",
    "district_id": "741201",
    "label": "Langara Tanjung Batu",
    "value": "Langara Tanjung Batu"
  },
  {
    "id": "7412021008",
    "district_id": "741202",
    "label": "Lansilowo",
    "value": "Lansilowo"
  },
  {
    "id": "7412022001",
    "district_id": "741202",
    "label": "Waworope",
    "value": "Waworope"
  },
  {
    "id": "7412022002",
    "district_id": "741202",
    "label": "Dongkalaea",
    "value": "Dongkalaea"
  },
  {
    "id": "7412022003",
    "district_id": "741202",
    "label": "Labeau",
    "value": "Labeau"
  },
  {
    "id": "7412022004",
    "district_id": "741202",
    "label": "Tambaone",
    "value": "Tambaone"
  },
  {
    "id": "7412022005",
    "district_id": "741202",
    "label": "Palingi",
    "value": "Palingi"
  },
  {
    "id": "7412022006",
    "district_id": "741202",
    "label": "Mawa",
    "value": "Mawa"
  },
  {
    "id": "7412022007",
    "district_id": "741202",
    "label": "Mata Buranga",
    "value": "Mata Buranga"
  },
  {
    "id": "7412022009",
    "district_id": "741202",
    "label": "Tambaone Utama",
    "value": "Tambaone Utama"
  },
  {
    "id": "7412022010",
    "district_id": "741202",
    "label": "Wawoea",
    "value": "Wawoea"
  },
  {
    "id": "7412022011",
    "district_id": "741202",
    "label": "Palingi Barat",
    "value": "Palingi Barat"
  },
  {
    "id": "7412022012",
    "district_id": "741202",
    "label": "Tumburano",
    "value": "Tumburano"
  },
  {
    "id": "7412022013",
    "district_id": "741202",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7412022014",
    "district_id": "741202",
    "label": "Labisa",
    "value": "Labisa"
  },
  {
    "id": "7412022015",
    "district_id": "741202",
    "label": "Mata Bubu",
    "value": "Mata Bubu"
  },
  {
    "id": "7412022016",
    "district_id": "741202",
    "label": "Wawobeau",
    "value": "Wawobeau"
  },
  {
    "id": "7412022017",
    "district_id": "741202",
    "label": "Tapum Batu",
    "value": "Tapum Batu"
  },
  {
    "id": "7412022018",
    "district_id": "741202",
    "label": "Palingi Timur",
    "value": "Palingi Timur"
  },
  {
    "id": "7412022019",
    "district_id": "741202",
    "label": "Tongalere",
    "value": "Tongalere"
  },
  {
    "id": "7412022020",
    "district_id": "741202",
    "label": "Tepolawa",
    "value": "Tepolawa"
  },
  {
    "id": "7412022021",
    "district_id": "741202",
    "label": "Sawa Indah",
    "value": "Sawa Indah"
  },
  {
    "id": "7412031004",
    "district_id": "741203",
    "label": "Ladianta",
    "value": "Ladianta"
  },
  {
    "id": "7412032001",
    "district_id": "741203",
    "label": "Bangun Mekar",
    "value": "Bangun Mekar"
  },
  {
    "id": "7412032002",
    "district_id": "741203",
    "label": "Noko",
    "value": "Noko"
  },
  {
    "id": "7412032003",
    "district_id": "741203",
    "label": "Tangkombuno",
    "value": "Tangkombuno"
  },
  {
    "id": "7412032005",
    "district_id": "741203",
    "label": "Dimba",
    "value": "Dimba"
  },
  {
    "id": "7412032006",
    "district_id": "741203",
    "label": "Mata Dimba",
    "value": "Mata Dimba"
  },
  {
    "id": "7412032007",
    "district_id": "741203",
    "label": "Puurau",
    "value": "Puurau"
  },
  {
    "id": "7412032008",
    "district_id": "741203",
    "label": "Watuondo",
    "value": "Watuondo"
  },
  {
    "id": "7412032009",
    "district_id": "741203",
    "label": "Patande",
    "value": "Patande"
  },
  {
    "id": "7412032010",
    "district_id": "741203",
    "label": "Baho Bubu",
    "value": "Baho Bubu"
  },
  {
    "id": "7412041001",
    "district_id": "741204",
    "label": "Munse",
    "value": "Munse"
  },
  {
    "id": "7412042002",
    "district_id": "741204",
    "label": "Lebo",
    "value": "Lebo"
  },
  {
    "id": "7412042003",
    "district_id": "741204",
    "label": "Tekonea",
    "value": "Tekonea"
  },
  {
    "id": "7412042004",
    "district_id": "741204",
    "label": "Wakadawu",
    "value": "Wakadawu"
  },
  {
    "id": "7412042005",
    "district_id": "741204",
    "label": "Munse Indah",
    "value": "Munse Indah"
  },
  {
    "id": "7412042006",
    "district_id": "741204",
    "label": "Lapulu",
    "value": "Lapulu"
  },
  {
    "id": "7412042007",
    "district_id": "741204",
    "label": "Laywo Jaya",
    "value": "Laywo Jaya"
  },
  {
    "id": "7412042008",
    "district_id": "741204",
    "label": "Nanga",
    "value": "Nanga"
  },
  {
    "id": "7412042009",
    "district_id": "741204",
    "label": "Butuea",
    "value": "Butuea"
  },
  {
    "id": "7412042010",
    "district_id": "741204",
    "label": "Lembono",
    "value": "Lembono"
  },
  {
    "id": "7412042011",
    "district_id": "741204",
    "label": "Saburano",
    "value": "Saburano"
  },
  {
    "id": "7412051005",
    "district_id": "741205",
    "label": "Polara",
    "value": "Polara"
  },
  {
    "id": "7412052001",
    "district_id": "741205",
    "label": "Nambo Jaya",
    "value": "Nambo Jaya"
  },
  {
    "id": "7412052002",
    "district_id": "741205",
    "label": "Masolo",
    "value": "Masolo"
  },
  {
    "id": "7412052003",
    "district_id": "741205",
    "label": "Sinaulu Jaya",
    "value": "Sinaulu Jaya"
  },
  {
    "id": "7412052004",
    "district_id": "741205",
    "label": "Tondonggito",
    "value": "Tondonggito"
  },
  {
    "id": "7412052006",
    "district_id": "741205",
    "label": "Roko Roko",
    "value": "Roko Roko"
  },
  {
    "id": "7412052007",
    "district_id": "741205",
    "label": "Dompo-dompo Jaya",
    "value": "Dompo-dompo Jaya"
  },
  {
    "id": "7412052008",
    "district_id": "741205",
    "label": "Teparoko",
    "value": "Teparoko"
  },
  {
    "id": "7412052009",
    "district_id": "741205",
    "label": "Sukarela Jaya",
    "value": "Sukarela Jaya"
  },
  {
    "id": "7412052011",
    "district_id": "741205",
    "label": "Sainoa Indah",
    "value": "Sainoa Indah"
  },
  {
    "id": "7412052012",
    "district_id": "741205",
    "label": "Wunse Jaya",
    "value": "Wunse Jaya"
  },
  {
    "id": "7412052013",
    "district_id": "741205",
    "label": "Sinar Masolo",
    "value": "Sinar Masolo"
  },
  {
    "id": "7412052014",
    "district_id": "741205",
    "label": "Waturai",
    "value": "Waturai"
  },
  {
    "id": "7412052015",
    "district_id": "741205",
    "label": "Bahaba",
    "value": "Bahaba"
  },
  {
    "id": "7412052016",
    "district_id": "741205",
    "label": "Kekea",
    "value": "Kekea"
  },
  {
    "id": "7412061006",
    "district_id": "741206",
    "label": "Sawaea",
    "value": "Sawaea"
  },
  {
    "id": "7412062001",
    "district_id": "741206",
    "label": "Wungkolo",
    "value": "Wungkolo"
  },
  {
    "id": "7412062002",
    "district_id": "741206",
    "label": "Bobolio",
    "value": "Bobolio"
  },
  {
    "id": "7412062003",
    "district_id": "741206",
    "label": "Wawosou",
    "value": "Wawosou"
  },
  {
    "id": "7412062004",
    "district_id": "741206",
    "label": "Lawey",
    "value": "Lawey"
  },
  {
    "id": "7412062005",
    "district_id": "741206",
    "label": "Baku-baku",
    "value": "Baku-baku"
  },
  {
    "id": "7412062007",
    "district_id": "741206",
    "label": "Sawapatani",
    "value": "Sawapatani"
  },
  {
    "id": "7412062008",
    "district_id": "741206",
    "label": "Wawouso Baru",
    "value": "Wawouso Baru"
  },
  {
    "id": "7412062009",
    "district_id": "741206",
    "label": "Puuwatu",
    "value": "Puuwatu"
  },
  {
    "id": "7412062010",
    "district_id": "741206",
    "label": "Wawoone",
    "value": "Wawoone"
  },
  {
    "id": "7412062011",
    "district_id": "741206",
    "label": "Langgara Jaya",
    "value": "Langgara Jaya"
  },
  {
    "id": "7412071003",
    "district_id": "741207",
    "label": "Lampeapi",
    "value": "Lampeapi"
  },
  {
    "id": "7412072001",
    "district_id": "741207",
    "label": "Batumea",
    "value": "Batumea"
  },
  {
    "id": "7412072002",
    "district_id": "741207",
    "label": "Wawo Indah",
    "value": "Wawo Indah"
  },
  {
    "id": "7412072004",
    "district_id": "741207",
    "label": "Lamongupa",
    "value": "Lamongupa"
  },
  {
    "id": "7412072005",
    "district_id": "741207",
    "label": "Tumbu Tumbu Jaya",
    "value": "Tumbu Tumbu Jaya"
  },
  {
    "id": "7412072006",
    "district_id": "741207",
    "label": "Morobea",
    "value": "Morobea"
  },
  {
    "id": "7412072007",
    "district_id": "741207",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "7412072008",
    "district_id": "741207",
    "label": "Rawa Indah",
    "value": "Rawa Indah"
  },
  {
    "id": "7412072009",
    "district_id": "741207",
    "label": "Lampeapi Baru",
    "value": "Lampeapi Baru"
  },
  {
    "id": "7412072010",
    "district_id": "741207",
    "label": "Pesue",
    "value": "Pesue"
  },
  {
    "id": "7412072011",
    "district_id": "741207",
    "label": "Puurau",
    "value": "Puurau"
  },
  {
    "id": "7412072012",
    "district_id": "741207",
    "label": "Baho Puu Wulu",
    "value": "Baho Puu Wulu"
  },
  {
    "id": "7413012001",
    "district_id": "741301",
    "label": "Wakoila",
    "value": "Wakoila"
  },
  {
    "id": "7413012002",
    "district_id": "741301",
    "label": "Waukuni",
    "value": "Waukuni"
  },
  {
    "id": "7413012003",
    "district_id": "741301",
    "label": "Kampobalano",
    "value": "Kampobalano"
  },
  {
    "id": "7413012004",
    "district_id": "741301",
    "label": "Maperaha",
    "value": "Maperaha"
  },
  {
    "id": "7413012005",
    "district_id": "741301",
    "label": "Nihi",
    "value": "Nihi"
  },
  {
    "id": "7413012006",
    "district_id": "741301",
    "label": "Marobea",
    "value": "Marobea"
  },
  {
    "id": "7413012007",
    "district_id": "741301",
    "label": "Ondoke",
    "value": "Ondoke"
  },
  {
    "id": "7413012008",
    "district_id": "741301",
    "label": "Lakalamba",
    "value": "Lakalamba"
  },
  {
    "id": "7413012009",
    "district_id": "741301",
    "label": "Lawada Jaya",
    "value": "Lawada Jaya"
  },
  {
    "id": "7413012010",
    "district_id": "741301",
    "label": "Lombu Jaya",
    "value": "Lombu Jaya"
  },
  {
    "id": "7413022001",
    "district_id": "741302",
    "label": "Wuna",
    "value": "Wuna"
  },
  {
    "id": "7413022002",
    "district_id": "741302",
    "label": "Lafinde",
    "value": "Lafinde"
  },
  {
    "id": "7413022003",
    "district_id": "741302",
    "label": "Waulai",
    "value": "Waulai"
  },
  {
    "id": "7413022004",
    "district_id": "741302",
    "label": "Walelei",
    "value": "Walelei"
  },
  {
    "id": "7413022005",
    "district_id": "741302",
    "label": "Sawerigadi",
    "value": "Sawerigadi"
  },
  {
    "id": "7413022006",
    "district_id": "741302",
    "label": "Bungkolo",
    "value": "Bungkolo"
  },
  {
    "id": "7413022007",
    "district_id": "741302",
    "label": "Barangka",
    "value": "Barangka"
  },
  {
    "id": "7413022008",
    "district_id": "741302",
    "label": "Lapolea",
    "value": "Lapolea"
  },
  {
    "id": "7413031007",
    "district_id": "741303",
    "label": "Wamelai",
    "value": "Wamelai"
  },
  {
    "id": "7413031008",
    "district_id": "741303",
    "label": "Lapadaku",
    "value": "Lapadaku"
  },
  {
    "id": "7413032001",
    "district_id": "741303",
    "label": "Lagadi",
    "value": "Lagadi"
  },
  {
    "id": "7413032002",
    "district_id": "741303",
    "label": "Latugho",
    "value": "Latugho"
  },
  {
    "id": "7413032003",
    "district_id": "741303",
    "label": "Latompe",
    "value": "Latompe"
  },
  {
    "id": "7413032004",
    "district_id": "741303",
    "label": "Lalemba",
    "value": "Lalemba"
  },
  {
    "id": "7413032005",
    "district_id": "741303",
    "label": "Madampi",
    "value": "Madampi"
  },
  {
    "id": "7413032006",
    "district_id": "741303",
    "label": "Watumela",
    "value": "Watumela"
  },
  {
    "id": "7413042001",
    "district_id": "741304",
    "label": "Lailangga",
    "value": "Lailangga"
  },
  {
    "id": "7413042002",
    "district_id": "741304",
    "label": "Lasosodo",
    "value": "Lasosodo"
  },
  {
    "id": "7413042003",
    "district_id": "741304",
    "label": "Wakontu",
    "value": "Wakontu"
  },
  {
    "id": "7413042004",
    "district_id": "741304",
    "label": "Lakanaha",
    "value": "Lakanaha"
  },
  {
    "id": "7413042005",
    "district_id": "741304",
    "label": "Katobu",
    "value": "Katobu"
  },
  {
    "id": "7413042006",
    "district_id": "741304",
    "label": "Lindo",
    "value": "Lindo"
  },
  {
    "id": "7413042007",
    "district_id": "741304",
    "label": "Kampani",
    "value": "Kampani"
  },
  {
    "id": "7413052001",
    "district_id": "741305",
    "label": "Parura Jaya",
    "value": "Parura Jaya"
  },
  {
    "id": "7413052002",
    "district_id": "741305",
    "label": "Katangana",
    "value": "Katangana"
  },
  {
    "id": "7413052003",
    "district_id": "741305",
    "label": "Kasimpa Jaya",
    "value": "Kasimpa Jaya"
  },
  {
    "id": "7413052004",
    "district_id": "741305",
    "label": "Sangia Tiworo",
    "value": "Sangia Tiworo"
  },
  {
    "id": "7413052005",
    "district_id": "741305",
    "label": "Barakkah",
    "value": "Barakkah"
  },
  {
    "id": "7413062001",
    "district_id": "741306",
    "label": "Kangkunawe",
    "value": "Kangkunawe"
  },
  {
    "id": "7413062002",
    "district_id": "741306",
    "label": "Maginti",
    "value": "Maginti"
  },
  {
    "id": "7413062003",
    "district_id": "741306",
    "label": "Pajala",
    "value": "Pajala"
  },
  {
    "id": "7413062004",
    "district_id": "741306",
    "label": "Abadi Jaya",
    "value": "Abadi Jaya"
  },
  {
    "id": "7413062005",
    "district_id": "741306",
    "label": "Gala",
    "value": "Gala"
  },
  {
    "id": "7413062006",
    "district_id": "741306",
    "label": "Pasipadanga",
    "value": "Pasipadanga"
  },
  {
    "id": "7413062007",
    "district_id": "741306",
    "label": "Bangko",
    "value": "Bangko"
  },
  {
    "id": "7413062008",
    "district_id": "741306",
    "label": "Kembar Maminasa",
    "value": "Kembar Maminasa"
  },
  {
    "id": "7413072001",
    "district_id": "741307",
    "label": "Wanseriwu",
    "value": "Wanseriwu"
  },
  {
    "id": "7413072002",
    "district_id": "741307",
    "label": "Labukolo",
    "value": "Labukolo"
  },
  {
    "id": "7413072003",
    "district_id": "741307",
    "label": "Langku-langku",
    "value": "Langku-langku"
  },
  {
    "id": "7413072004",
    "district_id": "741307",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7413072005",
    "district_id": "741307",
    "label": "Wapae Jaya",
    "value": "Wapae Jaya"
  },
  {
    "id": "7413072006",
    "district_id": "741307",
    "label": "Momuntu",
    "value": "Momuntu"
  },
  {
    "id": "7413072007",
    "district_id": "741307",
    "label": "Lakabu",
    "value": "Lakabu"
  },
  {
    "id": "7413072008",
    "district_id": "741307",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "7413082001",
    "district_id": "741308",
    "label": "Tasipi",
    "value": "Tasipi"
  },
  {
    "id": "7413082002",
    "district_id": "741308",
    "label": "Mandike",
    "value": "Mandike"
  },
  {
    "id": "7413082003",
    "district_id": "741308",
    "label": "Bero",
    "value": "Bero"
  },
  {
    "id": "7413082004",
    "district_id": "741308",
    "label": "Santigi",
    "value": "Santigi"
  },
  {
    "id": "7413082005",
    "district_id": "741308",
    "label": "Tondasi",
    "value": "Tondasi"
  },
  {
    "id": "7413082006",
    "district_id": "741308",
    "label": "Santiri",
    "value": "Santiri"
  },
  {
    "id": "7413082007",
    "district_id": "741308",
    "label": "Tiga",
    "value": "Tiga"
  },
  {
    "id": "7413091004",
    "district_id": "741309",
    "label": "Waumere",
    "value": "Waumere"
  },
  {
    "id": "7413091005",
    "district_id": "741309",
    "label": "Tiworo",
    "value": "Tiworo"
  },
  {
    "id": "7413092001",
    "district_id": "741309",
    "label": "Katela",
    "value": "Katela"
  },
  {
    "id": "7413092002",
    "district_id": "741309",
    "label": "Lasama",
    "value": "Lasama"
  },
  {
    "id": "7413092003",
    "district_id": "741309",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "7413092006",
    "district_id": "741309",
    "label": "Waturempe",
    "value": "Waturempe"
  },
  {
    "id": "7413092007",
    "district_id": "741309",
    "label": "Laworo",
    "value": "Laworo"
  },
  {
    "id": "7413092008",
    "district_id": "741309",
    "label": "Wulanga Jaya",
    "value": "Wulanga Jaya"
  },
  {
    "id": "7413092009",
    "district_id": "741309",
    "label": "Wandoke",
    "value": "Wandoke"
  },
  {
    "id": "7413101008",
    "district_id": "741310",
    "label": "Konawe",
    "value": "Konawe"
  },
  {
    "id": "7413102001",
    "district_id": "741310",
    "label": "Lemoambo",
    "value": "Lemoambo"
  },
  {
    "id": "7413102002",
    "district_id": "741310",
    "label": "Kasakamu",
    "value": "Kasakamu"
  },
  {
    "id": "7413102003",
    "district_id": "741310",
    "label": "Tanjung Pinang",
    "value": "Tanjung Pinang"
  },
  {
    "id": "7413102004",
    "district_id": "741310",
    "label": "Sidamangura",
    "value": "Sidamangura"
  },
  {
    "id": "7413102005",
    "district_id": "741310",
    "label": "Lakawoghe",
    "value": "Lakawoghe"
  },
  {
    "id": "7413102006",
    "district_id": "741310",
    "label": "Lapokainse",
    "value": "Lapokainse"
  },
  {
    "id": "7413102007",
    "district_id": "741310",
    "label": "Guali",
    "value": "Guali"
  },
  {
    "id": "7413102009",
    "district_id": "741310",
    "label": "Kusambi",
    "value": "Kusambi"
  },
  {
    "id": "7413102010",
    "district_id": "741310",
    "label": "Bakeramba",
    "value": "Bakeramba"
  },
  {
    "id": "7413112001",
    "district_id": "741311",
    "label": "Masara",
    "value": "Masara"
  },
  {
    "id": "7413112002",
    "district_id": "741311",
    "label": "Lahaji",
    "value": "Lahaji"
  },
  {
    "id": "7413112003",
    "district_id": "741311",
    "label": "Umba",
    "value": "Umba"
  },
  {
    "id": "7413112004",
    "district_id": "741311",
    "label": "Kombikuno",
    "value": "Kombikuno"
  },
  {
    "id": "7413112005",
    "district_id": "741311",
    "label": "Latawe",
    "value": "Latawe"
  },
  {
    "id": "7413112006",
    "district_id": "741311",
    "label": "Tangkumaho",
    "value": "Tangkumaho"
  },
  {
    "id": "7414011007",
    "district_id": "741401",
    "label": "Lakudo",
    "value": "Lakudo"
  },
  {
    "id": "7414011008",
    "district_id": "741401",
    "label": "Gu Timur",
    "value": "Gu Timur"
  },
  {
    "id": "7414011010",
    "district_id": "741401",
    "label": "Boneoge",
    "value": "Boneoge"
  },
  {
    "id": "7414012001",
    "district_id": "741401",
    "label": "Metere",
    "value": "Metere"
  },
  {
    "id": "7414012002",
    "district_id": "741401",
    "label": "Lolibu",
    "value": "Lolibu"
  },
  {
    "id": "7414012003",
    "district_id": "741401",
    "label": "Wajogu",
    "value": "Wajogu"
  },
  {
    "id": "7414012004",
    "district_id": "741401",
    "label": "Moko",
    "value": "Moko"
  },
  {
    "id": "7414012005",
    "district_id": "741401",
    "label": "Mone",
    "value": "Mone"
  },
  {
    "id": "7414012006",
    "district_id": "741401",
    "label": "Matawine",
    "value": "Matawine"
  },
  {
    "id": "7414012009",
    "district_id": "741401",
    "label": "Nepa Mekar",
    "value": "Nepa Mekar"
  },
  {
    "id": "7414012011",
    "district_id": "741401",
    "label": "Madongka",
    "value": "Madongka"
  },
  {
    "id": "7414012012",
    "district_id": "741401",
    "label": "Waraa",
    "value": "Waraa"
  },
  {
    "id": "7414012013",
    "district_id": "741401",
    "label": "Wongko Lakudo",
    "value": "Wongko Lakudo"
  },
  {
    "id": "7414012014",
    "district_id": "741401",
    "label": "One Waara",
    "value": "One Waara"
  },
  {
    "id": "7414012015",
    "district_id": "741401",
    "label": "Teluk Lasongko",
    "value": "Teluk Lasongko"
  },
  {
    "id": "7414022001",
    "district_id": "741402",
    "label": "Bonemarambe",
    "value": "Bonemarambe"
  },
  {
    "id": "7414022002",
    "district_id": "741402",
    "label": "Wambuloli",
    "value": "Wambuloli"
  },
  {
    "id": "7414022003",
    "district_id": "741402",
    "label": "Lagili",
    "value": "Lagili"
  },
  {
    "id": "7414022004",
    "district_id": "741402",
    "label": "Bungi",
    "value": "Bungi"
  },
  {
    "id": "7414022005",
    "district_id": "741402",
    "label": "Wantopi",
    "value": "Wantopi"
  },
  {
    "id": "7414022006",
    "district_id": "741402",
    "label": "Inulu",
    "value": "Inulu"
  },
  {
    "id": "7414022007",
    "district_id": "741402",
    "label": "Lasori",
    "value": "Lasori"
  },
  {
    "id": "7414022008",
    "district_id": "741402",
    "label": "Batubanawa",
    "value": "Batubanawa"
  },
  {
    "id": "7414031003",
    "district_id": "741403",
    "label": "Lakorua",
    "value": "Lakorua"
  },
  {
    "id": "7414032001",
    "district_id": "741403",
    "label": "Morikana",
    "value": "Morikana"
  },
  {
    "id": "7414032002",
    "district_id": "741403",
    "label": "Lantongau",
    "value": "Lantongau"
  },
  {
    "id": "7414032004",
    "district_id": "741403",
    "label": "Lanto",
    "value": "Lanto"
  },
  {
    "id": "7414032005",
    "district_id": "741403",
    "label": "Lalibo",
    "value": "Lalibo"
  },
  {
    "id": "7414032006",
    "district_id": "741403",
    "label": "Langkomu",
    "value": "Langkomu"
  },
  {
    "id": "7414032007",
    "district_id": "741403",
    "label": "Watorumbe",
    "value": "Watorumbe"
  },
  {
    "id": "7414032008",
    "district_id": "741403",
    "label": "Watorumbe Bata",
    "value": "Watorumbe Bata"
  },
  {
    "id": "7414032009",
    "district_id": "741403",
    "label": "Gundu Gundu",
    "value": "Gundu Gundu"
  },
  {
    "id": "7414032010",
    "district_id": "741403",
    "label": "Katukobari",
    "value": "Katukobari"
  },
  {
    "id": "7414041001",
    "district_id": "741404",
    "label": "Watolo",
    "value": "Watolo"
  },
  {
    "id": "7414041002",
    "district_id": "741404",
    "label": "Mawasangka",
    "value": "Mawasangka"
  },
  {
    "id": "7414042003",
    "district_id": "741404",
    "label": "Oengkolaki",
    "value": "Oengkolaki"
  },
  {
    "id": "7414042004",
    "district_id": "741404",
    "label": "Banga",
    "value": "Banga"
  },
  {
    "id": "7414042005",
    "district_id": "741404",
    "label": "Tanailandu",
    "value": "Tanailandu"
  },
  {
    "id": "7414042006",
    "district_id": "741404",
    "label": "Polindu",
    "value": "Polindu"
  },
  {
    "id": "7414042007",
    "district_id": "741404",
    "label": "Kanapa Napa",
    "value": "Kanapa Napa"
  },
  {
    "id": "7414042008",
    "district_id": "741404",
    "label": "Terapung",
    "value": "Terapung"
  },
  {
    "id": "7414042009",
    "district_id": "741404",
    "label": "Wasilomata II",
    "value": "Wasilomata II"
  },
  {
    "id": "7414042010",
    "district_id": "741404",
    "label": "Wasilotama I",
    "value": "Wasilotama I"
  },
  {
    "id": "7414042011",
    "district_id": "741404",
    "label": "Matara",
    "value": "Matara"
  },
  {
    "id": "7414042012",
    "district_id": "741404",
    "label": "Balobone",
    "value": "Balobone"
  },
  {
    "id": "7414042013",
    "district_id": "741404",
    "label": "Napa",
    "value": "Napa"
  },
  {
    "id": "7414042014",
    "district_id": "741404",
    "label": "Wakambangura",
    "value": "Wakambangura"
  },
  {
    "id": "7414042015",
    "district_id": "741404",
    "label": "Kancebungi",
    "value": "Kancebungi"
  },
  {
    "id": "7414042016",
    "district_id": "741404",
    "label": "Gumanano",
    "value": "Gumanano"
  },
  {
    "id": "7414042017",
    "district_id": "741404",
    "label": "Dahiango",
    "value": "Dahiango"
  },
  {
    "id": "7414042018",
    "district_id": "741404",
    "label": "Air Bajo",
    "value": "Air Bajo"
  },
  {
    "id": "7414042019",
    "district_id": "741404",
    "label": "Wakambangura II",
    "value": "Wakambangura II"
  },
  {
    "id": "7414051004",
    "district_id": "741405",
    "label": "Talaga Satu",
    "value": "Talaga Satu"
  },
  {
    "id": "7414052001",
    "district_id": "741405",
    "label": "Kokoe",
    "value": "Kokoe"
  },
  {
    "id": "7414052002",
    "district_id": "741405",
    "label": "Talaga Besar",
    "value": "Talaga Besar"
  },
  {
    "id": "7414052003",
    "district_id": "741405",
    "label": "Talaga II",
    "value": "Talaga II"
  },
  {
    "id": "7414052005",
    "district_id": "741405",
    "label": "Wulu",
    "value": "Wulu"
  },
  {
    "id": "7414052006",
    "district_id": "741405",
    "label": "Liwulompona",
    "value": "Liwulompona"
  },
  {
    "id": "7414052007",
    "district_id": "741405",
    "label": "Pangilia",
    "value": "Pangilia"
  },
  {
    "id": "7414061004",
    "district_id": "741406",
    "label": "Watulea",
    "value": "Watulea"
  },
  {
    "id": "7414061005",
    "district_id": "741406",
    "label": "Bombonawulu",
    "value": "Bombonawulu"
  },
  {
    "id": "7414062001",
    "district_id": "741406",
    "label": "Bantea",
    "value": "Bantea"
  },
  {
    "id": "7414062002",
    "district_id": "741406",
    "label": "Lakapera",
    "value": "Lakapera"
  },
  {
    "id": "7414062003",
    "district_id": "741406",
    "label": "Walando",
    "value": "Walando"
  },
  {
    "id": "7414062006",
    "district_id": "741406",
    "label": "Waliko",
    "value": "Waliko"
  },
  {
    "id": "7414062007",
    "district_id": "741406",
    "label": "Wakea Kea",
    "value": "Wakea Kea"
  },
  {
    "id": "7414062008",
    "district_id": "741406",
    "label": "Rahia",
    "value": "Rahia"
  },
  {
    "id": "7414062009",
    "district_id": "741406",
    "label": "Wadiabero",
    "value": "Wadiabero"
  },
  {
    "id": "7414062010",
    "district_id": "741406",
    "label": "Kolowa",
    "value": "Kolowa"
  },
  {
    "id": "7414062011",
    "district_id": "741406",
    "label": "Kamama Mekar",
    "value": "Kamama Mekar"
  },
  {
    "id": "7414062012",
    "district_id": "741406",
    "label": "Lowu Lowu",
    "value": "Lowu Lowu"
  },
  {
    "id": "7414071002",
    "district_id": "741407",
    "label": "Tolandona",
    "value": "Tolandona"
  },
  {
    "id": "7414072001",
    "district_id": "741407",
    "label": "Baruta Lestari",
    "value": "Baruta Lestari"
  },
  {
    "id": "7414072003",
    "district_id": "741407",
    "label": "Doda Bahari",
    "value": "Doda Bahari"
  },
  {
    "id": "7414072004",
    "district_id": "741407",
    "label": "Baruta",
    "value": "Baruta"
  },
  {
    "id": "7414072006",
    "district_id": "741407",
    "label": "Baruta Analalaki",
    "value": "Baruta Analalaki"
  },
  {
    "id": "7414072007",
    "district_id": "741407",
    "label": "Tolandona Matanaeo",
    "value": "Tolandona Matanaeo"
  },
  {
    "id": "7415011002",
    "district_id": "741501",
    "label": "Busoa",
    "value": "Busoa"
  },
  {
    "id": "7415011003",
    "district_id": "741501",
    "label": "Laompo",
    "value": "Laompo"
  },
  {
    "id": "7415011004",
    "district_id": "741501",
    "label": "Masiri",
    "value": "Masiri"
  },
  {
    "id": "7415011005",
    "district_id": "741501",
    "label": "Majapahit",
    "value": "Majapahit"
  },
  {
    "id": "7415011010",
    "district_id": "741501",
    "label": "Lakambau",
    "value": "Lakambau"
  },
  {
    "id": "7415011011",
    "district_id": "741501",
    "label": "Molagina",
    "value": "Molagina"
  },
  {
    "id": "7415011012",
    "district_id": "741501",
    "label": "Bandar Batauga",
    "value": "Bandar Batauga"
  },
  {
    "id": "7415012001",
    "district_id": "741501",
    "label": "Lawela",
    "value": "Lawela"
  },
  {
    "id": "7415012006",
    "district_id": "741501",
    "label": "Bola",
    "value": "Bola"
  },
  {
    "id": "7415012007",
    "district_id": "741501",
    "label": "Poogalampa",
    "value": "Poogalampa"
  },
  {
    "id": "7415012008",
    "district_id": "741501",
    "label": "Lawela Selatan",
    "value": "Lawela Selatan"
  },
  {
    "id": "7415012009",
    "district_id": "741501",
    "label": "Lampanairi",
    "value": "Lampanairi"
  },
  {
    "id": "7415021003",
    "district_id": "741502",
    "label": "Katilombu",
    "value": "Katilombu"
  },
  {
    "id": "7415021004",
    "district_id": "741502",
    "label": "Jaya Bakti",
    "value": "Jaya Bakti"
  },
  {
    "id": "7415021005",
    "district_id": "741502",
    "label": "Todombulu",
    "value": "Todombulu"
  },
  {
    "id": "7415022001",
    "district_id": "741502",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "7415022002",
    "district_id": "741502",
    "label": "Wawoangi",
    "value": "Wawoangi"
  },
  {
    "id": "7415022006",
    "district_id": "741502",
    "label": "Gunung Sejuk",
    "value": "Gunung Sejuk"
  },
  {
    "id": "7415022007",
    "district_id": "741502",
    "label": "Sandang Pangan",
    "value": "Sandang Pangan"
  },
  {
    "id": "7415022008",
    "district_id": "741502",
    "label": "Hendea",
    "value": "Hendea"
  },
  {
    "id": "7415022009",
    "district_id": "741502",
    "label": "Tira",
    "value": "Tira"
  },
  {
    "id": "7415022010",
    "district_id": "741502",
    "label": "Bahari",
    "value": "Bahari"
  },
  {
    "id": "7415022011",
    "district_id": "741502",
    "label": "Gerak Makmur",
    "value": "Gerak Makmur"
  },
  {
    "id": "7415022012",
    "district_id": "741502",
    "label": "Lipu Mangau",
    "value": "Lipu Mangau"
  },
  {
    "id": "7415022013",
    "district_id": "741502",
    "label": "Bahari Dua",
    "value": "Bahari Dua"
  },
  {
    "id": "7415022014",
    "district_id": "741502",
    "label": "Bahari Tiga",
    "value": "Bahari Tiga"
  },
  {
    "id": "7415022015",
    "district_id": "741502",
    "label": "Windu Makmur",
    "value": "Windu Makmur"
  },
  {
    "id": "7415022016",
    "district_id": "741502",
    "label": "Watiginanda",
    "value": "Watiginanda"
  },
  {
    "id": "7415032001",
    "district_id": "741503",
    "label": "Lapandewa Makmur",
    "value": "Lapandewa Makmur"
  },
  {
    "id": "7415032002",
    "district_id": "741503",
    "label": "Gaya Baru",
    "value": "Gaya Baru"
  },
  {
    "id": "7415032003",
    "district_id": "741503",
    "label": "Burangasi Rumbia",
    "value": "Burangasi Rumbia"
  },
  {
    "id": "7415032004",
    "district_id": "741503",
    "label": "Burangasi",
    "value": "Burangasi"
  },
  {
    "id": "7415032005",
    "district_id": "741503",
    "label": "Lapandewa Kaindea",
    "value": "Lapandewa Kaindea"
  },
  {
    "id": "7415032006",
    "district_id": "741503",
    "label": "Lapandewa",
    "value": "Lapandewa"
  },
  {
    "id": "7415032007",
    "district_id": "741503",
    "label": "Lapandewa Jaya",
    "value": "Lapandewa Jaya"
  },
  {
    "id": "7415042001",
    "district_id": "741504",
    "label": "Batuatas Liwu",
    "value": "Batuatas Liwu"
  },
  {
    "id": "7415042002",
    "district_id": "741504",
    "label": "Batuatas Barat",
    "value": "Batuatas Barat"
  },
  {
    "id": "7415042003",
    "district_id": "741504",
    "label": "Wacuala",
    "value": "Wacuala"
  },
  {
    "id": "7415042004",
    "district_id": "741504",
    "label": "Batu Atas Timur",
    "value": "Batu Atas Timur"
  },
  {
    "id": "7415042005",
    "district_id": "741504",
    "label": "Tolando Jaya",
    "value": "Tolando Jaya"
  },
  {
    "id": "7415042006",
    "district_id": "741504",
    "label": "Taduasa",
    "value": "Taduasa"
  },
  {
    "id": "7415042007",
    "district_id": "741504",
    "label": "Wambongi",
    "value": "Wambongi"
  },
  {
    "id": "7415052001",
    "district_id": "741505",
    "label": "Molona",
    "value": "Molona"
  },
  {
    "id": "7415052002",
    "district_id": "741505",
    "label": "Mbanua",
    "value": "Mbanua"
  },
  {
    "id": "7415052003",
    "district_id": "741505",
    "label": "Lalole",
    "value": "Lalole"
  },
  {
    "id": "7415052004",
    "district_id": "741505",
    "label": "Watuampara",
    "value": "Watuampara"
  },
  {
    "id": "7415052005",
    "district_id": "741505",
    "label": "Katampe",
    "value": "Katampe"
  },
  {
    "id": "7415052006",
    "district_id": "741505",
    "label": "Lamaninggara",
    "value": "Lamaninggara"
  },
  {
    "id": "7415052007",
    "district_id": "741505",
    "label": "Kamoali",
    "value": "Kamoali"
  },
  {
    "id": "7415052008",
    "district_id": "741505",
    "label": "Mokobeau",
    "value": "Mokobeau"
  },
  {
    "id": "7415062001",
    "district_id": "741506",
    "label": "Biwinapada",
    "value": "Biwinapada"
  },
  {
    "id": "7415062002",
    "district_id": "741506",
    "label": "Kaimbulawa",
    "value": "Kaimbulawa"
  },
  {
    "id": "7415062003",
    "district_id": "741506",
    "label": "Wakinamboro",
    "value": "Wakinamboro"
  },
  {
    "id": "7415062004",
    "district_id": "741506",
    "label": "Tongali",
    "value": "Tongali"
  },
  {
    "id": "7415062005",
    "district_id": "741506",
    "label": "Lontoi",
    "value": "Lontoi"
  },
  {
    "id": "7415062006",
    "district_id": "741506",
    "label": "Karae",
    "value": "Karae"
  },
  {
    "id": "7415062007",
    "district_id": "741506",
    "label": "Batuawu",
    "value": "Batuawu"
  },
  {
    "id": "7415062008",
    "district_id": "741506",
    "label": "Nggulanggula",
    "value": "Nggulanggula"
  },
  {
    "id": "7415062009",
    "district_id": "741506",
    "label": "Waindawula",
    "value": "Waindawula"
  },
  {
    "id": "7415062010",
    "district_id": "741506",
    "label": "Lapara",
    "value": "Lapara"
  },
  {
    "id": "7415072001",
    "district_id": "741507",
    "label": "Waonu",
    "value": "Waonu"
  },
  {
    "id": "7415072002",
    "district_id": "741507",
    "label": "Kaofe",
    "value": "Kaofe"
  },
  {
    "id": "7415072003",
    "district_id": "741507",
    "label": "Uwemaasi",
    "value": "Uwemaasi"
  },
  {
    "id": "7415072004",
    "district_id": "741507",
    "label": "Lipu",
    "value": "Lipu"
  },
  {
    "id": "7415072005",
    "district_id": "741507",
    "label": "Kapoa",
    "value": "Kapoa"
  },
  {
    "id": "7415072006",
    "district_id": "741507",
    "label": "Banabungi",
    "value": "Banabungi"
  },
  {
    "id": "7415072007",
    "district_id": "741507",
    "label": "Mawambunga",
    "value": "Mawambunga"
  },
  {
    "id": "7415072008",
    "district_id": "741507",
    "label": "Banabungi Selatan",
    "value": "Banabungi Selatan"
  },
  {
    "id": "7415072009",
    "district_id": "741507",
    "label": "Marawali",
    "value": "Marawali"
  },
  {
    "id": "7415072010",
    "district_id": "741507",
    "label": "Kapoa Barat",
    "value": "Kapoa Barat"
  },
  {
    "id": "7471011005",
    "district_id": "747101",
    "label": "Mandonga",
    "value": "Mandonga"
  },
  {
    "id": "7471011006",
    "district_id": "747101",
    "label": "Korumba",
    "value": "Korumba"
  },
  {
    "id": "7471011007",
    "district_id": "747101",
    "label": "Labibia",
    "value": "Labibia"
  },
  {
    "id": "7471011008",
    "district_id": "747101",
    "label": "Wawombalata",
    "value": "Wawombalata"
  },
  {
    "id": "7471011009",
    "district_id": "747101",
    "label": "Alolama",
    "value": "Alolama"
  },
  {
    "id": "7471011010",
    "district_id": "747101",
    "label": "Anggilowu",
    "value": "Anggilowu"
  },
  {
    "id": "7471021009",
    "district_id": "747102",
    "label": "Kandai",
    "value": "Kandai"
  },
  {
    "id": "7471021010",
    "district_id": "747102",
    "label": "Gunung Jati",
    "value": "Gunung Jati"
  },
  {
    "id": "7471021011",
    "district_id": "747102",
    "label": "Kampung Salo",
    "value": "Kampung Salo"
  },
  {
    "id": "7471021012",
    "district_id": "747102",
    "label": "Mangga Dua",
    "value": "Mangga Dua"
  },
  {
    "id": "7471021013",
    "district_id": "747102",
    "label": "Kendari Caddi",
    "value": "Kendari Caddi"
  },
  {
    "id": "7471021014",
    "district_id": "747102",
    "label": "Kassilampe",
    "value": "Kassilampe"
  },
  {
    "id": "7471021015",
    "district_id": "747102",
    "label": "Mata",
    "value": "Mata"
  },
  {
    "id": "7471021016",
    "district_id": "747102",
    "label": "Purirano",
    "value": "Purirano"
  },
  {
    "id": "7471021017",
    "district_id": "747102",
    "label": "Jati Mekar",
    "value": "Jati Mekar"
  },
  {
    "id": "7471031001",
    "district_id": "747103",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7471031002",
    "district_id": "747103",
    "label": "Lepo Lepo",
    "value": "Lepo Lepo"
  },
  {
    "id": "7471031007",
    "district_id": "747103",
    "label": "Watubangga",
    "value": "Watubangga"
  },
  {
    "id": "7471031008",
    "district_id": "747103",
    "label": "Wundudopi",
    "value": "Wundudopi"
  },
  {
    "id": "7471041003",
    "district_id": "747104",
    "label": "Andonohu",
    "value": "Andonohu"
  },
  {
    "id": "7471041004",
    "district_id": "747104",
    "label": "Rahandouna",
    "value": "Rahandouna"
  },
  {
    "id": "7471041005",
    "district_id": "747104",
    "label": "Anggoeya",
    "value": "Anggoeya"
  },
  {
    "id": "7471041006",
    "district_id": "747104",
    "label": "Matabubu",
    "value": "Matabubu"
  },
  {
    "id": "7471051001",
    "district_id": "747105",
    "label": "Kemaraya",
    "value": "Kemaraya"
  },
  {
    "id": "7471051002",
    "district_id": "747105",
    "label": "Watu-Watu",
    "value": "Watu-Watu"
  },
  {
    "id": "7471051003",
    "district_id": "747105",
    "label": "Tipulu",
    "value": "Tipulu"
  },
  {
    "id": "7471051004",
    "district_id": "747105",
    "label": "Punggaloba",
    "value": "Punggaloba"
  },
  {
    "id": "7471051005",
    "district_id": "747105",
    "label": "Benu Benua",
    "value": "Benu Benua"
  },
  {
    "id": "7471051006",
    "district_id": "747105",
    "label": "Sodoha",
    "value": "Sodoha"
  },
  {
    "id": "7471051007",
    "district_id": "747105",
    "label": "Sanua",
    "value": "Sanua"
  },
  {
    "id": "7471051008",
    "district_id": "747105",
    "label": "Dapu Dapura",
    "value": "Dapu Dapura"
  },
  {
    "id": "7471051009",
    "district_id": "747105",
    "label": "Lahundape",
    "value": "Lahundape"
  },
  {
    "id": "7471061001",
    "district_id": "747106",
    "label": "Puday",
    "value": "Puday"
  },
  {
    "id": "7471061002",
    "district_id": "747106",
    "label": "Lapulu",
    "value": "Lapulu"
  },
  {
    "id": "7471061003",
    "district_id": "747106",
    "label": "Abeli",
    "value": "Abeli"
  },
  {
    "id": "7471061004",
    "district_id": "747106",
    "label": "Benuanirae",
    "value": "Benuanirae"
  },
  {
    "id": "7471061005",
    "district_id": "747106",
    "label": "Tobimeita",
    "value": "Tobimeita"
  },
  {
    "id": "7471061006",
    "district_id": "747106",
    "label": "Anggalomelai",
    "value": "Anggalomelai"
  },
  {
    "id": "7471061007",
    "district_id": "747106",
    "label": "Talia",
    "value": "Talia"
  },
  {
    "id": "7471061008",
    "district_id": "747106",
    "label": "Poasia",
    "value": "Poasia"
  },
  {
    "id": "7471061009",
    "district_id": "747106",
    "label": "Bungkutoko",
    "value": "Bungkutoko"
  },
  {
    "id": "7471061010",
    "district_id": "747106",
    "label": "Petoaha",
    "value": "Petoaha"
  },
  {
    "id": "7471061011",
    "district_id": "747106",
    "label": "Nambo",
    "value": "Nambo"
  },
  {
    "id": "7471061012",
    "district_id": "747106",
    "label": "Sambuli",
    "value": "Sambuli"
  },
  {
    "id": "7471061013",
    "district_id": "747106",
    "label": "Tondonggeu",
    "value": "Tondonggeu"
  },
  {
    "id": "7471071001",
    "district_id": "747107",
    "label": "Wua Wua",
    "value": "Wua Wua"
  },
  {
    "id": "7471071002",
    "district_id": "747107",
    "label": "Bonggoeya",
    "value": "Bonggoeya"
  },
  {
    "id": "7471071003",
    "district_id": "747107",
    "label": "Mataiwoi",
    "value": "Mataiwoi"
  },
  {
    "id": "7471071004",
    "district_id": "747107",
    "label": "Anawai",
    "value": "Anawai"
  },
  {
    "id": "7471081001",
    "district_id": "747108",
    "label": "Kadia",
    "value": "Kadia"
  },
  {
    "id": "7471081002",
    "district_id": "747108",
    "label": "Bende",
    "value": "Bende"
  },
  {
    "id": "7471081003",
    "district_id": "747108",
    "label": "Pondambea",
    "value": "Pondambea"
  },
  {
    "id": "7471081004",
    "district_id": "747108",
    "label": "Wowawanggu",
    "value": "Wowawanggu"
  },
  {
    "id": "7471081005",
    "district_id": "747108",
    "label": "Anaiwoi",
    "value": "Anaiwoi"
  },
  {
    "id": "7471091001",
    "district_id": "747109",
    "label": "Puuwatu",
    "value": "Puuwatu"
  },
  {
    "id": "7471091002",
    "district_id": "747109",
    "label": "Watulondo",
    "value": "Watulondo"
  },
  {
    "id": "7471091003",
    "district_id": "747109",
    "label": "Punggolaka",
    "value": "Punggolaka"
  },
  {
    "id": "7471091004",
    "district_id": "747109",
    "label": "Tobuuha",
    "value": "Tobuuha"
  },
  {
    "id": "7471091005",
    "district_id": "747109",
    "label": "Abeli Dalam",
    "value": "Abeli Dalam"
  },
  {
    "id": "7471091006",
    "district_id": "747109",
    "label": "Lalodati",
    "value": "Lalodati"
  },
  {
    "id": "7471101001",
    "district_id": "747110",
    "label": "Kambu",
    "value": "Kambu"
  },
  {
    "id": "7471101002",
    "district_id": "747110",
    "label": "Mokoau",
    "value": "Mokoau"
  },
  {
    "id": "7471101003",
    "district_id": "747110",
    "label": "Padaleu",
    "value": "Padaleu"
  },
  {
    "id": "7471101004",
    "district_id": "747110",
    "label": "Lalolara",
    "value": "Lalolara"
  },
  {
    "id": "7472011007",
    "district_id": "747201",
    "label": "Katobengke",
    "value": "Katobengke"
  },
  {
    "id": "7472011012",
    "district_id": "747201",
    "label": "Waborobo",
    "value": "Waborobo"
  },
  {
    "id": "7472011013",
    "district_id": "747201",
    "label": "Sulaa",
    "value": "Sulaa"
  },
  {
    "id": "7472011014",
    "district_id": "747201",
    "label": "Lipu",
    "value": "Lipu"
  },
  {
    "id": "7472011016",
    "district_id": "747201",
    "label": "Labalawa",
    "value": "Labalawa"
  },
  {
    "id": "7472021001",
    "district_id": "747202",
    "label": "Wale",
    "value": "Wale"
  },
  {
    "id": "7472021002",
    "district_id": "747202",
    "label": "Tomba",
    "value": "Tomba"
  },
  {
    "id": "7472021003",
    "district_id": "747202",
    "label": "Bataraguru",
    "value": "Bataraguru"
  },
  {
    "id": "7472021004",
    "district_id": "747202",
    "label": "Wangkanapi",
    "value": "Wangkanapi"
  },
  {
    "id": "7472021005",
    "district_id": "747202",
    "label": "Batulo",
    "value": "Batulo"
  },
  {
    "id": "7472021007",
    "district_id": "747202",
    "label": "Kadolo Katapi",
    "value": "Kadolo Katapi"
  },
  {
    "id": "7472021011",
    "district_id": "747202",
    "label": "Bukit Wolio Indah",
    "value": "Bukit Wolio Indah"
  },
  {
    "id": "7472031001",
    "district_id": "747203",
    "label": "Kaisabu Baru",
    "value": "Kaisabu Baru"
  },
  {
    "id": "7472031002",
    "district_id": "747203",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "7472031003",
    "district_id": "747203",
    "label": "Gonda Baru",
    "value": "Gonda Baru"
  },
  {
    "id": "7472031004",
    "district_id": "747203",
    "label": "Bugi",
    "value": "Bugi"
  },
  {
    "id": "7472041001",
    "district_id": "747204",
    "label": "Liabuku",
    "value": "Liabuku"
  },
  {
    "id": "7472041002",
    "district_id": "747204",
    "label": "Ngkaring Karing",
    "value": "Ngkaring Karing"
  },
  {
    "id": "7472041003",
    "district_id": "747204",
    "label": "Kampeonaho",
    "value": "Kampeonaho"
  },
  {
    "id": "7472041008",
    "district_id": "747204",
    "label": "Waliabuku",
    "value": "Waliabuku"
  },
  {
    "id": "7472041010",
    "district_id": "747204",
    "label": "Tampuna",
    "value": "Tampuna"
  },
  {
    "id": "7472051001",
    "district_id": "747205",
    "label": "Liwuto",
    "value": "Liwuto"
  },
  {
    "id": "7472051002",
    "district_id": "747205",
    "label": "Sukanaeyo",
    "value": "Sukanaeyo"
  },
  {
    "id": "7472051003",
    "district_id": "747205",
    "label": "Lakologou",
    "value": "Lakologou"
  },
  {
    "id": "7472051004",
    "district_id": "747205",
    "label": "Waruruma",
    "value": "Waruruma"
  },
  {
    "id": "7472051005",
    "district_id": "747205",
    "label": "Kadolomoko",
    "value": "Kadolomoko"
  },
  {
    "id": "7472051006",
    "district_id": "747205",
    "label": "Kadolo",
    "value": "Kadolo"
  },
  {
    "id": "7472061007",
    "district_id": "747206",
    "label": "Wajo",
    "value": "Wajo"
  },
  {
    "id": "7472061008",
    "district_id": "747206",
    "label": "Lamangga",
    "value": "Lamangga"
  },
  {
    "id": "7472061009",
    "district_id": "747206",
    "label": "Melai",
    "value": "Melai"
  },
  {
    "id": "7472061010",
    "district_id": "747206",
    "label": "Baadia",
    "value": "Baadia"
  },
  {
    "id": "7472061011",
    "district_id": "747206",
    "label": "Tanganapada",
    "value": "Tanganapada"
  },
  {
    "id": "7472071001",
    "district_id": "747207",
    "label": "Lowu-Lowu",
    "value": "Lowu-Lowu"
  },
  {
    "id": "7472071002",
    "district_id": "747207",
    "label": "Kalia-Lia",
    "value": "Kalia-Lia"
  },
  {
    "id": "7472071003",
    "district_id": "747207",
    "label": "Palabusa",
    "value": "Palabusa"
  },
  {
    "id": "7472071004",
    "district_id": "747207",
    "label": "Kolese",
    "value": "Kolese"
  },
  {
    "id": "7472071005",
    "district_id": "747207",
    "label": "Kantalai",
    "value": "Kantalai"
  },
  {
    "id": "7472081001",
    "district_id": "747208",
    "label": "Bonebone",
    "value": "Bonebone"
  },
  {
    "id": "7472081002",
    "district_id": "747208",
    "label": "Tarafu",
    "value": "Tarafu"
  },
  {
    "id": "7472081003",
    "district_id": "747208",
    "label": "Wameo",
    "value": "Wameo"
  },
  {
    "id": "7472081004",
    "district_id": "747208",
    "label": "Kaobula",
    "value": "Kaobula"
  },
  {
    "id": "7472081005",
    "district_id": "747208",
    "label": "Lanto",
    "value": "Lanto"
  },
  {
    "id": "7472081006",
    "district_id": "747208",
    "label": "Nganganaumala",
    "value": "Nganganaumala"
  },
  {
    "id": "7501011001",
    "district_id": "750101",
    "label": "Kayubulan",
    "value": "Kayubulan"
  },
  {
    "id": "7501011002",
    "district_id": "750101",
    "label": "Kayumerah",
    "value": "Kayumerah"
  },
  {
    "id": "7501011003",
    "district_id": "750101",
    "label": "Hunggaluwa",
    "value": "Hunggaluwa"
  },
  {
    "id": "7501011004",
    "district_id": "750101",
    "label": "Bolihuangga",
    "value": "Bolihuangga"
  },
  {
    "id": "7501011005",
    "district_id": "750101",
    "label": "Hepuhulawa",
    "value": "Hepuhulawa"
  },
  {
    "id": "7501011006",
    "district_id": "750101",
    "label": "Hutuo",
    "value": "Hutuo"
  },
  {
    "id": "7501011007",
    "district_id": "750101",
    "label": "Dutulanaa",
    "value": "Dutulanaa"
  },
  {
    "id": "7501011008",
    "district_id": "750101",
    "label": "Tenilo",
    "value": "Tenilo"
  },
  {
    "id": "7501011009",
    "district_id": "750101",
    "label": "Biyonga",
    "value": "Biyonga"
  },
  {
    "id": "7501011010",
    "district_id": "750101",
    "label": "Bongohulawa",
    "value": "Bongohulawa"
  },
  {
    "id": "7501011011",
    "district_id": "750101",
    "label": "Bulota",
    "value": "Bulota"
  },
  {
    "id": "7501011012",
    "district_id": "750101",
    "label": "Malahu",
    "value": "Malahu"
  },
  {
    "id": "7501011013",
    "district_id": "750101",
    "label": "Tilihuwa",
    "value": "Tilihuwa"
  },
  {
    "id": "7501011014",
    "district_id": "750101",
    "label": "Polohungo",
    "value": "Polohungo"
  },
  {
    "id": "7501022011",
    "district_id": "750102",
    "label": "Bulila",
    "value": "Bulila"
  },
  {
    "id": "7501022014",
    "district_id": "750102",
    "label": "Mongolato",
    "value": "Mongolato"
  },
  {
    "id": "7501022015",
    "district_id": "750102",
    "label": "Luhu",
    "value": "Luhu"
  },
  {
    "id": "7501022016",
    "district_id": "750102",
    "label": "Hulawa",
    "value": "Hulawa"
  },
  {
    "id": "7501022017",
    "district_id": "750102",
    "label": "Pilohayanga",
    "value": "Pilohayanga"
  },
  {
    "id": "7501022018",
    "district_id": "750102",
    "label": "Dulamayo Selatan",
    "value": "Dulamayo Selatan"
  },
  {
    "id": "7501022019",
    "district_id": "750102",
    "label": "Dulamayo Barat",
    "value": "Dulamayo Barat"
  },
  {
    "id": "7501022020",
    "district_id": "750102",
    "label": "Dulohupa",
    "value": "Dulohupa"
  },
  {
    "id": "7501022021",
    "district_id": "750102",
    "label": "Pilohayanga Barat",
    "value": "Pilohayanga Barat"
  },
  {
    "id": "7501032001",
    "district_id": "750103",
    "label": "Iluta",
    "value": "Iluta"
  },
  {
    "id": "7501032002",
    "district_id": "750103",
    "label": "Bua",
    "value": "Bua"
  },
  {
    "id": "7501032003",
    "district_id": "750103",
    "label": "Huntu",
    "value": "Huntu"
  },
  {
    "id": "7501032004",
    "district_id": "750103",
    "label": "Payunga",
    "value": "Payunga"
  },
  {
    "id": "7501032012",
    "district_id": "750103",
    "label": "Barakati",
    "value": "Barakati"
  },
  {
    "id": "7501032013",
    "district_id": "750103",
    "label": "Ilohungayo",
    "value": "Ilohungayo"
  },
  {
    "id": "7501032014",
    "district_id": "750103",
    "label": "Dunggala",
    "value": "Dunggala"
  },
  {
    "id": "7501032015",
    "district_id": "750103",
    "label": "Pilobuhuta",
    "value": "Pilobuhuta"
  },
  {
    "id": "7501042001",
    "district_id": "750104",
    "label": "Isimu Utara",
    "value": "Isimu Utara"
  },
  {
    "id": "7501042002",
    "district_id": "750104",
    "label": "Isimu Selatan",
    "value": "Isimu Selatan"
  },
  {
    "id": "7501042003",
    "district_id": "750104",
    "label": "Datahu",
    "value": "Datahu"
  },
  {
    "id": "7501042004",
    "district_id": "750104",
    "label": "Tolotio",
    "value": "Tolotio"
  },
  {
    "id": "7501042005",
    "district_id": "750104",
    "label": "Labanu",
    "value": "Labanu"
  },
  {
    "id": "7501042006",
    "district_id": "750104",
    "label": "Reksonegoro",
    "value": "Reksonegoro"
  },
  {
    "id": "7501042007",
    "district_id": "750104",
    "label": "Buhu",
    "value": "Buhu"
  },
  {
    "id": "7501042008",
    "district_id": "750104",
    "label": "Dunggala",
    "value": "Dunggala"
  },
  {
    "id": "7501042009",
    "district_id": "750104",
    "label": "Molowahu",
    "value": "Molowahu"
  },
  {
    "id": "7501042010",
    "district_id": "750104",
    "label": "Iloponu",
    "value": "Iloponu"
  },
  {
    "id": "7501042011",
    "district_id": "750104",
    "label": "Ilomata",
    "value": "Ilomata"
  },
  {
    "id": "7501042012",
    "district_id": "750104",
    "label": "Motilango",
    "value": "Motilango"
  },
  {
    "id": "7501042013",
    "district_id": "750104",
    "label": "Isimu Raya",
    "value": "Isimu Raya"
  },
  {
    "id": "7501042014",
    "district_id": "750104",
    "label": "Balahu",
    "value": "Balahu"
  },
  {
    "id": "7501042015",
    "district_id": "750104",
    "label": "Botumoputi",
    "value": "Botumoputi"
  },
  {
    "id": "7501042016",
    "district_id": "750104",
    "label": "Ulobua",
    "value": "Ulobua"
  },
  {
    "id": "7501052004",
    "district_id": "750105",
    "label": "Tontayuo",
    "value": "Tontayuo"
  },
  {
    "id": "7501052005",
    "district_id": "750105",
    "label": "Biluhu Timur",
    "value": "Biluhu Timur"
  },
  {
    "id": "7501052006",
    "district_id": "750105",
    "label": "Kayubulan",
    "value": "Kayubulan"
  },
  {
    "id": "7501052007",
    "district_id": "750105",
    "label": "Bongo",
    "value": "Bongo"
  },
  {
    "id": "7501052008",
    "district_id": "750105",
    "label": "Lopo",
    "value": "Lopo"
  },
  {
    "id": "7501052009",
    "district_id": "750105",
    "label": "Lamu",
    "value": "Lamu"
  },
  {
    "id": "7501052012",
    "district_id": "750105",
    "label": "Olimoo'o",
    "value": "Olimoo'o"
  },
  {
    "id": "7501052014",
    "district_id": "750105",
    "label": "Buhudaa",
    "value": "Buhudaa"
  },
  {
    "id": "7501052015",
    "district_id": "750105",
    "label": "Langgula",
    "value": "Langgula"
  },
  {
    "id": "7501092001",
    "district_id": "750109",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "7501092004",
    "district_id": "750109",
    "label": "Parungi",
    "value": "Parungi"
  },
  {
    "id": "7501092006",
    "district_id": "750109",
    "label": "Diloniyohu",
    "value": "Diloniyohu"
  },
  {
    "id": "7501092007",
    "district_id": "750109",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "7501092010",
    "district_id": "750109",
    "label": "Potanga",
    "value": "Potanga"
  },
  {
    "id": "7501092011",
    "district_id": "750109",
    "label": "Motoduto",
    "value": "Motoduto"
  },
  {
    "id": "7501092012",
    "district_id": "750109",
    "label": "Iloheluma",
    "value": "Iloheluma"
  },
  {
    "id": "7501092014",
    "district_id": "750109",
    "label": "Monggolito",
    "value": "Monggolito"
  },
  {
    "id": "7501092015",
    "district_id": "750109",
    "label": "Bandung Rejo",
    "value": "Bandung Rejo"
  },
  {
    "id": "7501092018",
    "district_id": "750109",
    "label": "Dulohupa",
    "value": "Dulohupa"
  },
  {
    "id": "7501092019",
    "district_id": "750109",
    "label": "Sidomulya Selatan",
    "value": "Sidomulya Selatan"
  },
  {
    "id": "7501092020",
    "district_id": "750109",
    "label": "Bongongoayu",
    "value": "Bongongoayu"
  },
  {
    "id": "7501092021",
    "district_id": "750109",
    "label": "Tolite",
    "value": "Tolite"
  },
  {
    "id": "7501102001",
    "district_id": "750110",
    "label": "Dulamayo Utara",
    "value": "Dulamayo Utara"
  },
  {
    "id": "7501102002",
    "district_id": "750110",
    "label": "Ulapato A",
    "value": "Ulapato A"
  },
  {
    "id": "7501102003",
    "district_id": "750110",
    "label": "Ulapato B",
    "value": "Ulapato B"
  },
  {
    "id": "7501102004",
    "district_id": "750110",
    "label": "Talumelito",
    "value": "Talumelito"
  },
  {
    "id": "7501102005",
    "district_id": "750110",
    "label": "Tuladenggi",
    "value": "Tuladenggi"
  },
  {
    "id": "7501102006",
    "district_id": "750110",
    "label": "Pantungo",
    "value": "Pantungo"
  },
  {
    "id": "7501102007",
    "district_id": "750110",
    "label": "Lupoyo",
    "value": "Lupoyo"
  },
  {
    "id": "7501102008",
    "district_id": "750110",
    "label": "Pentadio Timur",
    "value": "Pentadio Timur"
  },
  {
    "id": "7501102009",
    "district_id": "750110",
    "label": "Pentadio Barat",
    "value": "Pentadio Barat"
  },
  {
    "id": "7501102010",
    "district_id": "750110",
    "label": "Dumati",
    "value": "Dumati"
  },
  {
    "id": "7501102011",
    "district_id": "750110",
    "label": "Modelidu",
    "value": "Modelidu"
  },
  {
    "id": "7501102012",
    "district_id": "750110",
    "label": "Tinelo",
    "value": "Tinelo"
  },
  {
    "id": "7501102013",
    "district_id": "750110",
    "label": "Timuato",
    "value": "Timuato"
  },
  {
    "id": "7501102014",
    "district_id": "750110",
    "label": "Tapaluluo",
    "value": "Tapaluluo"
  },
  {
    "id": "7501102015",
    "district_id": "750110",
    "label": "Tonala",
    "value": "Tonala"
  },
  {
    "id": "7501112001",
    "district_id": "750111",
    "label": "Dulamayo",
    "value": "Dulamayo"
  },
  {
    "id": "7501112008",
    "district_id": "750111",
    "label": "Upomela",
    "value": "Upomela"
  },
  {
    "id": "7501112009",
    "district_id": "750111",
    "label": "Tohupo",
    "value": "Tohupo"
  },
  {
    "id": "7501112010",
    "district_id": "750111",
    "label": "Molanihu",
    "value": "Molanihu"
  },
  {
    "id": "7501112011",
    "district_id": "750111",
    "label": "Molopatodu",
    "value": "Molopatodu"
  },
  {
    "id": "7501112012",
    "district_id": "750111",
    "label": "Molas",
    "value": "Molas"
  },
  {
    "id": "7501112013",
    "district_id": "750111",
    "label": "Batulayar",
    "value": "Batulayar"
  },
  {
    "id": "7501112014",
    "district_id": "750111",
    "label": "Batuloreng",
    "value": "Batuloreng"
  },
  {
    "id": "7501112015",
    "district_id": "750111",
    "label": "Bongohulawa",
    "value": "Bongohulawa"
  },
  {
    "id": "7501112016",
    "district_id": "750111",
    "label": "Otopade",
    "value": "Otopade"
  },
  {
    "id": "7501112018",
    "district_id": "750111",
    "label": "Huntulo Hulawa",
    "value": "Huntulo Hulawa"
  },
  {
    "id": "7501112022",
    "district_id": "750111",
    "label": "Owalanga",
    "value": "Owalanga"
  },
  {
    "id": "7501112023",
    "district_id": "750111",
    "label": "Liyodu",
    "value": "Liyodu"
  },
  {
    "id": "7501112024",
    "district_id": "750111",
    "label": "Liyoto",
    "value": "Liyoto"
  },
  {
    "id": "7501112025",
    "district_id": "750111",
    "label": "Kayumerah",
    "value": "Kayumerah"
  },
  {
    "id": "7501132001",
    "district_id": "750113",
    "label": "Sukamakmur",
    "value": "Sukamakmur"
  },
  {
    "id": "7501132002",
    "district_id": "750113",
    "label": "Lakeya",
    "value": "Lakeya"
  },
  {
    "id": "7501132003",
    "district_id": "750113",
    "label": "Gandasari",
    "value": "Gandasari"
  },
  {
    "id": "7501132004",
    "district_id": "750113",
    "label": "Molohu",
    "value": "Molohu"
  },
  {
    "id": "7501132007",
    "district_id": "750113",
    "label": "Polohungo",
    "value": "Polohungo"
  },
  {
    "id": "7501132008",
    "district_id": "750113",
    "label": "Bina Jaya",
    "value": "Bina Jaya"
  },
  {
    "id": "7501132009",
    "district_id": "750113",
    "label": "Tamaila",
    "value": "Tamaila"
  },
  {
    "id": "7501132010",
    "district_id": "750113",
    "label": "Sidoarjo",
    "value": "Sidoarjo"
  },
  {
    "id": "7501132013",
    "district_id": "750113",
    "label": "Sukamakmur Utara",
    "value": "Sukamakmur Utara"
  },
  {
    "id": "7501132014",
    "district_id": "750113",
    "label": "Margomulya",
    "value": "Margomulya"
  },
  {
    "id": "7501132015",
    "district_id": "750113",
    "label": "Makmur Abadi",
    "value": "Makmur Abadi"
  },
  {
    "id": "7501132016",
    "district_id": "750113",
    "label": "Gandaria",
    "value": "Gandaria"
  },
  {
    "id": "7501132017",
    "district_id": "750113",
    "label": "Ombulo Tango",
    "value": "Ombulo Tango"
  },
  {
    "id": "7501132018",
    "district_id": "750113",
    "label": "Tamalia Utara",
    "value": "Tamalia Utara"
  },
  {
    "id": "7501132019",
    "district_id": "750113",
    "label": "Himalaya",
    "value": "Himalaya"
  },
  {
    "id": "7501142001",
    "district_id": "750114",
    "label": "Paris",
    "value": "Paris"
  },
  {
    "id": "7501142002",
    "district_id": "750114",
    "label": "Helumo",
    "value": "Helumo"
  },
  {
    "id": "7501142003",
    "district_id": "750114",
    "label": "Pilomonu",
    "value": "Pilomonu"
  },
  {
    "id": "7501142004",
    "district_id": "750114",
    "label": "Satria",
    "value": "Satria"
  },
  {
    "id": "7501142005",
    "district_id": "750114",
    "label": "Karyamukti",
    "value": "Karyamukti"
  },
  {
    "id": "7501142006",
    "district_id": "750114",
    "label": "Talumopatu",
    "value": "Talumopatu"
  },
  {
    "id": "7501142007",
    "district_id": "750114",
    "label": "Sidomukti",
    "value": "Sidomukti"
  },
  {
    "id": "7501142008",
    "district_id": "750114",
    "label": "Payu",
    "value": "Payu"
  },
  {
    "id": "7501142009",
    "district_id": "750114",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "7501142010",
    "district_id": "750114",
    "label": "Huyula",
    "value": "Huyula"
  },
  {
    "id": "7501162001",
    "district_id": "750116",
    "label": "Pongongaila",
    "value": "Pongongaila"
  },
  {
    "id": "7501162002",
    "district_id": "750116",
    "label": "Pulubala",
    "value": "Pulubala"
  },
  {
    "id": "7501162003",
    "district_id": "750116",
    "label": "Molamahu",
    "value": "Molamahu"
  },
  {
    "id": "7501162004",
    "district_id": "750116",
    "label": "Bakti",
    "value": "Bakti"
  },
  {
    "id": "7501162005",
    "district_id": "750116",
    "label": "Tridharma",
    "value": "Tridharma"
  },
  {
    "id": "7501162006",
    "district_id": "750116",
    "label": "Molalahu",
    "value": "Molalahu"
  },
  {
    "id": "7501162007",
    "district_id": "750116",
    "label": "Mulyonegoro",
    "value": "Mulyonegoro"
  },
  {
    "id": "7501162008",
    "district_id": "750116",
    "label": "Puncak",
    "value": "Puncak"
  },
  {
    "id": "7501162009",
    "district_id": "750116",
    "label": "Toyidito",
    "value": "Toyidito"
  },
  {
    "id": "7501162010",
    "district_id": "750116",
    "label": "Ayumolingo",
    "value": "Ayumolingo"
  },
  {
    "id": "7501162011",
    "district_id": "750116",
    "label": "Bukit Aren",
    "value": "Bukit Aren"
  },
  {
    "id": "7501172001",
    "district_id": "750117",
    "label": "Pone",
    "value": "Pone"
  },
  {
    "id": "7501172002",
    "district_id": "750117",
    "label": "Huidu",
    "value": "Huidu"
  },
  {
    "id": "7501172003",
    "district_id": "750117",
    "label": "Ombulo",
    "value": "Ombulo"
  },
  {
    "id": "7501172004",
    "district_id": "750117",
    "label": "Daenaa",
    "value": "Daenaa"
  },
  {
    "id": "7501172005",
    "district_id": "750117",
    "label": "Yosonegoro",
    "value": "Yosonegoro"
  },
  {
    "id": "7501172006",
    "district_id": "750117",
    "label": "Tunggulo",
    "value": "Tunggulo"
  },
  {
    "id": "7501172007",
    "district_id": "750117",
    "label": "Hutabohu",
    "value": "Hutabohu"
  },
  {
    "id": "7501172008",
    "district_id": "750117",
    "label": "Padengo",
    "value": "Padengo"
  },
  {
    "id": "7501172009",
    "district_id": "750117",
    "label": "Haya-Haya",
    "value": "Haya-Haya"
  },
  {
    "id": "7501172010",
    "district_id": "750117",
    "label": "Huidu Utara",
    "value": "Huidu Utara"
  },
  {
    "id": "7501182001",
    "district_id": "750118",
    "label": "Tualango",
    "value": "Tualango"
  },
  {
    "id": "7501182002",
    "district_id": "750118",
    "label": "Dulomo",
    "value": "Dulomo"
  },
  {
    "id": "7501182003",
    "district_id": "750118",
    "label": "Tilote",
    "value": "Tilote"
  },
  {
    "id": "7501182004",
    "district_id": "750118",
    "label": "Tabumela",
    "value": "Tabumela"
  },
  {
    "id": "7501182005",
    "district_id": "750118",
    "label": "Ilotidea",
    "value": "Ilotidea"
  },
  {
    "id": "7501182006",
    "district_id": "750118",
    "label": "Lauwonu",
    "value": "Lauwonu"
  },
  {
    "id": "7501182007",
    "district_id": "750118",
    "label": "Tenggela",
    "value": "Tenggela"
  },
  {
    "id": "7501182008",
    "district_id": "750118",
    "label": "Tinelo",
    "value": "Tinelo"
  },
  {
    "id": "7501192001",
    "district_id": "750119",
    "label": "Tabongo Timur",
    "value": "Tabongo Timur"
  },
  {
    "id": "7501192002",
    "district_id": "750119",
    "label": "Tabongo Barat",
    "value": "Tabongo Barat"
  },
  {
    "id": "7501192003",
    "district_id": "750119",
    "label": "Limehe Barat",
    "value": "Limehe Barat"
  },
  {
    "id": "7501192004",
    "district_id": "750119",
    "label": "Limehe Timur",
    "value": "Limehe Timur"
  },
  {
    "id": "7501192005",
    "district_id": "750119",
    "label": "Ilomangga",
    "value": "Ilomangga"
  },
  {
    "id": "7501192006",
    "district_id": "750119",
    "label": "Motinelo",
    "value": "Motinelo"
  },
  {
    "id": "7501192007",
    "district_id": "750119",
    "label": "Moahudu",
    "value": "Moahudu"
  },
  {
    "id": "7501192008",
    "district_id": "750119",
    "label": "Teratai",
    "value": "Teratai"
  },
  {
    "id": "7501192009",
    "district_id": "750119",
    "label": "Limehu",
    "value": "Limehu"
  },
  {
    "id": "7501202001",
    "district_id": "750120",
    "label": "Biluhu Barat",
    "value": "Biluhu Barat"
  },
  {
    "id": "7501202002",
    "district_id": "750120",
    "label": "Lobuto",
    "value": "Lobuto"
  },
  {
    "id": "7501202003",
    "district_id": "750120",
    "label": "Biluhu Tengah",
    "value": "Biluhu Tengah"
  },
  {
    "id": "7501202004",
    "district_id": "750120",
    "label": "Luluo",
    "value": "Luluo"
  },
  {
    "id": "7501202005",
    "district_id": "750120",
    "label": "Huwongo",
    "value": "Huwongo"
  },
  {
    "id": "7501202006",
    "district_id": "750120",
    "label": "Lobuto Timur",
    "value": "Lobuto Timur"
  },
  {
    "id": "7501202007",
    "district_id": "750120",
    "label": "Botu Boluo",
    "value": "Botu Boluo"
  },
  {
    "id": "7501202008",
    "district_id": "750120",
    "label": "Olimeyala",
    "value": "Olimeyala"
  },
  {
    "id": "7501212001",
    "district_id": "750121",
    "label": "Bululi",
    "value": "Bululi"
  },
  {
    "id": "7501212002",
    "district_id": "750121",
    "label": "Mohiyolo",
    "value": "Mohiyolo"
  },
  {
    "id": "7501212003",
    "district_id": "750121",
    "label": "Pangahu",
    "value": "Pangahu"
  },
  {
    "id": "7501212004",
    "district_id": "750121",
    "label": "Karya Indah",
    "value": "Karya Indah"
  },
  {
    "id": "7501212005",
    "district_id": "750121",
    "label": "Prima",
    "value": "Prima"
  },
  {
    "id": "7501212006",
    "district_id": "750121",
    "label": "Tiohu",
    "value": "Tiohu"
  },
  {
    "id": "7501212007",
    "district_id": "750121",
    "label": "Olimohulo",
    "value": "Olimohulo"
  },
  {
    "id": "7501212008",
    "district_id": "750121",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "7501212009",
    "district_id": "750121",
    "label": "Bontula",
    "value": "Bontula"
  },
  {
    "id": "7501212010",
    "district_id": "750121",
    "label": "Bihe",
    "value": "Bihe"
  },
  {
    "id": "7501222001",
    "district_id": "750122",
    "label": "Hutadaa",
    "value": "Hutadaa"
  },
  {
    "id": "7501222002",
    "district_id": "750122",
    "label": "Buhu",
    "value": "Buhu"
  },
  {
    "id": "7501222003",
    "district_id": "750122",
    "label": "Luwoo",
    "value": "Luwoo"
  },
  {
    "id": "7501222004",
    "district_id": "750122",
    "label": "Bunggalo",
    "value": "Bunggalo"
  },
  {
    "id": "7501222005",
    "district_id": "750122",
    "label": "Bulota",
    "value": "Bulota"
  },
  {
    "id": "7501232001",
    "district_id": "750123",
    "label": "Totopo",
    "value": "Totopo"
  },
  {
    "id": "7501232002",
    "district_id": "750123",
    "label": "Bilato",
    "value": "Bilato"
  },
  {
    "id": "7501232003",
    "district_id": "750123",
    "label": "Ilomata",
    "value": "Ilomata"
  },
  {
    "id": "7501232004",
    "district_id": "750123",
    "label": "Taula'a",
    "value": "Taula'a"
  },
  {
    "id": "7501232005",
    "district_id": "750123",
    "label": "Juriya",
    "value": "Juriya"
  },
  {
    "id": "7501232006",
    "district_id": "750123",
    "label": "Pelehu",
    "value": "Pelehu"
  },
  {
    "id": "7501232007",
    "district_id": "750123",
    "label": "Bumela",
    "value": "Bumela"
  },
  {
    "id": "7501232008",
    "district_id": "750123",
    "label": "Lamahu",
    "value": "Lamahu"
  },
  {
    "id": "7501232009",
    "district_id": "750123",
    "label": "Musyawarah",
    "value": "Musyawarah"
  },
  {
    "id": "7501232010",
    "district_id": "750123",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "7501242001",
    "district_id": "750124",
    "label": "Pilolalenga",
    "value": "Pilolalenga"
  },
  {
    "id": "7501242002",
    "district_id": "750124",
    "label": "Kaliyoso",
    "value": "Kaliyoso"
  },
  {
    "id": "7501242003",
    "district_id": "750124",
    "label": "Dungaliyo",
    "value": "Dungaliyo"
  },
  {
    "id": "7501242004",
    "district_id": "750124",
    "label": "Ambara",
    "value": "Ambara"
  },
  {
    "id": "7501242005",
    "district_id": "750124",
    "label": "Bongomeme",
    "value": "Bongomeme"
  },
  {
    "id": "7501242006",
    "district_id": "750124",
    "label": "Duwanga",
    "value": "Duwanga"
  },
  {
    "id": "7501242007",
    "district_id": "750124",
    "label": "Ayuhula",
    "value": "Ayuhula"
  },
  {
    "id": "7501242008",
    "district_id": "750124",
    "label": "Pangadaa",
    "value": "Pangadaa"
  },
  {
    "id": "7501242009",
    "district_id": "750124",
    "label": "Botubulowe",
    "value": "Botubulowe"
  },
  {
    "id": "7501242010",
    "district_id": "750124",
    "label": "Momala",
    "value": "Momala"
  },
  {
    "id": "7502012004",
    "district_id": "750201",
    "label": "Bongo Nol",
    "value": "Bongo Nol"
  },
  {
    "id": "7502012005",
    "district_id": "750201",
    "label": "Bongo IV",
    "value": "Bongo IV"
  },
  {
    "id": "7502012006",
    "district_id": "750201",
    "label": "Molombulahe",
    "value": "Molombulahe"
  },
  {
    "id": "7502012007",
    "district_id": "750201",
    "label": "Mutiara",
    "value": "Mutiara"
  },
  {
    "id": "7502012008",
    "district_id": "750201",
    "label": "Saripi",
    "value": "Saripi"
  },
  {
    "id": "7502012009",
    "district_id": "750201",
    "label": "Wonggahu",
    "value": "Wonggahu"
  },
  {
    "id": "7502012010",
    "district_id": "750201",
    "label": "Tangkobu",
    "value": "Tangkobu"
  },
  {
    "id": "7502012011",
    "district_id": "750201",
    "label": "Bongo Tua",
    "value": "Bongo Tua"
  },
  {
    "id": "7502012012",
    "district_id": "750201",
    "label": "Kuala Lumpur",
    "value": "Kuala Lumpur"
  },
  {
    "id": "7502012013",
    "district_id": "750201",
    "label": "Mustika",
    "value": "Mustika"
  },
  {
    "id": "7502012014",
    "district_id": "750201",
    "label": "Tenilo",
    "value": "Tenilo"
  },
  {
    "id": "7502012015",
    "district_id": "750201",
    "label": "Huwongo",
    "value": "Huwongo"
  },
  {
    "id": "7502012016",
    "district_id": "750201",
    "label": "Karya Murni",
    "value": "Karya Murni"
  },
  {
    "id": "7502012019",
    "district_id": "750201",
    "label": "Girisa",
    "value": "Girisa"
  },
  {
    "id": "7502012020",
    "district_id": "750201",
    "label": "Batu Kramat",
    "value": "Batu Kramat"
  },
  {
    "id": "7502012021",
    "district_id": "750201",
    "label": "Bualo",
    "value": "Bualo"
  },
  {
    "id": "7502012022",
    "district_id": "750201",
    "label": "Sosial",
    "value": "Sosial"
  },
  {
    "id": "7502012023",
    "district_id": "750201",
    "label": "Permata",
    "value": "Permata"
  },
  {
    "id": "7502012024",
    "district_id": "750201",
    "label": "Hulawa",
    "value": "Hulawa"
  },
  {
    "id": "7502012025",
    "district_id": "750201",
    "label": "Balate Jaya",
    "value": "Balate Jaya"
  },
  {
    "id": "7502012026",
    "district_id": "750201",
    "label": "Rejonegoro",
    "value": "Rejonegoro"
  },
  {
    "id": "7502012027",
    "district_id": "750201",
    "label": "Diloato",
    "value": "Diloato"
  },
  {
    "id": "7502022001",
    "district_id": "750202",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "7502022002",
    "district_id": "750202",
    "label": "Bongo II",
    "value": "Bongo II"
  },
  {
    "id": "7502022003",
    "district_id": "750202",
    "label": "Bongo III",
    "value": "Bongo III"
  },
  {
    "id": "7502022004",
    "district_id": "750202",
    "label": "Mekarjaya",
    "value": "Mekarjaya"
  },
  {
    "id": "7502022005",
    "district_id": "750202",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "7502022006",
    "district_id": "750202",
    "label": "Pangeya",
    "value": "Pangeya"
  },
  {
    "id": "7502022007",
    "district_id": "750202",
    "label": "Jatimulya",
    "value": "Jatimulya"
  },
  {
    "id": "7502022008",
    "district_id": "750202",
    "label": "Suka Mulya",
    "value": "Suka Mulya"
  },
  {
    "id": "7502022009",
    "district_id": "750202",
    "label": "Sari Tani",
    "value": "Sari Tani"
  },
  {
    "id": "7502022010",
    "district_id": "750202",
    "label": "Dimito",
    "value": "Dimito"
  },
  {
    "id": "7502022011",
    "district_id": "750202",
    "label": "Tanjung Harapan",
    "value": "Tanjung Harapan"
  },
  {
    "id": "7502022012",
    "district_id": "750202",
    "label": "Raharja",
    "value": "Raharja"
  },
  {
    "id": "7502022013",
    "district_id": "750202",
    "label": "Trirukun",
    "value": "Trirukun"
  },
  {
    "id": "7502022014",
    "district_id": "750202",
    "label": "Dulohupa",
    "value": "Dulohupa"
  },
  {
    "id": "7502032001",
    "district_id": "750203",
    "label": "Dulupi",
    "value": "Dulupi"
  },
  {
    "id": "7502032002",
    "district_id": "750203",
    "label": "Tabongo",
    "value": "Tabongo"
  },
  {
    "id": "7502032003",
    "district_id": "750203",
    "label": "Kotaraja",
    "value": "Kotaraja"
  },
  {
    "id": "7502032004",
    "district_id": "750203",
    "label": "Polohungo",
    "value": "Polohungo"
  },
  {
    "id": "7502032005",
    "district_id": "750203",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7502032006",
    "district_id": "750203",
    "label": "Tangga Jaya",
    "value": "Tangga Jaya"
  },
  {
    "id": "7502032007",
    "district_id": "750203",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "7502032008",
    "district_id": "750203",
    "label": "Tangga Barito",
    "value": "Tangga Barito"
  },
  {
    "id": "7502042001",
    "district_id": "750204",
    "label": "Limbato",
    "value": "Limbato"
  },
  {
    "id": "7502042002",
    "district_id": "750204",
    "label": "Piloliyanga",
    "value": "Piloliyanga"
  },
  {
    "id": "7502042003",
    "district_id": "750204",
    "label": "Ayuhulalo",
    "value": "Ayuhulalo"
  },
  {
    "id": "7502042004",
    "district_id": "750204",
    "label": "Hungayonaa",
    "value": "Hungayonaa"
  },
  {
    "id": "7502042005",
    "district_id": "750204",
    "label": "Modelomo",
    "value": "Modelomo"
  },
  {
    "id": "7502042006",
    "district_id": "750204",
    "label": "Pentadu Barat",
    "value": "Pentadu Barat"
  },
  {
    "id": "7502042007",
    "district_id": "750204",
    "label": "Pentadu Timur",
    "value": "Pentadu Timur"
  },
  {
    "id": "7502042008",
    "district_id": "750204",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "7502042009",
    "district_id": "750204",
    "label": "Mohungo",
    "value": "Mohungo"
  },
  {
    "id": "7502042010",
    "district_id": "750204",
    "label": "Lahumbo",
    "value": "Lahumbo"
  },
  {
    "id": "7502042011",
    "district_id": "750204",
    "label": "Lamu",
    "value": "Lamu"
  },
  {
    "id": "7502042018",
    "district_id": "750204",
    "label": "Tenilo",
    "value": "Tenilo"
  },
  {
    "id": "7502052001",
    "district_id": "750205",
    "label": "Tabulo",
    "value": "Tabulo"
  },
  {
    "id": "7502052002",
    "district_id": "750205",
    "label": "Kaaruyan",
    "value": "Kaaruyan"
  },
  {
    "id": "7502052003",
    "district_id": "750205",
    "label": "Salilama",
    "value": "Salilama"
  },
  {
    "id": "7502052004",
    "district_id": "750205",
    "label": "Bendungan",
    "value": "Bendungan"
  },
  {
    "id": "7502052005",
    "district_id": "750205",
    "label": "Mananggu",
    "value": "Mananggu"
  },
  {
    "id": "7502052006",
    "district_id": "750205",
    "label": "Buti",
    "value": "Buti"
  },
  {
    "id": "7502052007",
    "district_id": "750205",
    "label": "Pontolo",
    "value": "Pontolo"
  },
  {
    "id": "7502052008",
    "district_id": "750205",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "7502052009",
    "district_id": "750205",
    "label": "Tabulo Selatan",
    "value": "Tabulo Selatan"
  },
  {
    "id": "7502062001",
    "district_id": "750206",
    "label": "Tutulo",
    "value": "Tutulo"
  },
  {
    "id": "7502062002",
    "district_id": "750206",
    "label": "Hutamonu",
    "value": "Hutamonu"
  },
  {
    "id": "7502062003",
    "district_id": "750206",
    "label": "Patoameme",
    "value": "Patoameme"
  },
  {
    "id": "7502062004",
    "district_id": "750206",
    "label": "Tapadaa",
    "value": "Tapadaa"
  },
  {
    "id": "7502062005",
    "district_id": "750206",
    "label": "Potanga",
    "value": "Potanga"
  },
  {
    "id": "7502062006",
    "district_id": "750206",
    "label": "Botumoito",
    "value": "Botumoito"
  },
  {
    "id": "7502062007",
    "district_id": "750206",
    "label": "Bolihutuo",
    "value": "Bolihutuo"
  },
  {
    "id": "7502062008",
    "district_id": "750206",
    "label": "Rumbia",
    "value": "Rumbia"
  },
  {
    "id": "7502062009",
    "district_id": "750206",
    "label": "Dulangeya",
    "value": "Dulangeya"
  },
  {
    "id": "7502072001",
    "district_id": "750207",
    "label": "Bubaa",
    "value": "Bubaa"
  },
  {
    "id": "7502072002",
    "district_id": "750207",
    "label": "Lito",
    "value": "Lito"
  },
  {
    "id": "7502072003",
    "district_id": "750207",
    "label": "Limbatihu",
    "value": "Limbatihu"
  },
  {
    "id": "7502072004",
    "district_id": "750207",
    "label": "Bukit Karya",
    "value": "Bukit Karya"
  },
  {
    "id": "7502072005",
    "district_id": "750207",
    "label": "Apitalawu",
    "value": "Apitalawu"
  },
  {
    "id": "7502072006",
    "district_id": "750207",
    "label": "Bangga",
    "value": "Bangga"
  },
  {
    "id": "7502072007",
    "district_id": "750207",
    "label": "Towayu",
    "value": "Towayu"
  },
  {
    "id": "7502072008",
    "district_id": "750207",
    "label": "Olibu",
    "value": "Olibu"
  },
  {
    "id": "7503012004",
    "district_id": "750301",
    "label": "Talumopatu",
    "value": "Talumopatu"
  },
  {
    "id": "7503012005",
    "district_id": "750301",
    "label": "Talulobutu",
    "value": "Talulobutu"
  },
  {
    "id": "7503012006",
    "district_id": "750301",
    "label": "Dunggala",
    "value": "Dunggala"
  },
  {
    "id": "7503012007",
    "district_id": "750301",
    "label": "Langge",
    "value": "Langge"
  },
  {
    "id": "7503012027",
    "district_id": "750301",
    "label": "Talulobutu Selatan",
    "value": "Talulobutu Selatan"
  },
  {
    "id": "7503012028",
    "district_id": "750301",
    "label": "Keramat",
    "value": "Keramat"
  },
  {
    "id": "7503012029",
    "district_id": "750301",
    "label": "Meranti",
    "value": "Meranti"
  },
  {
    "id": "7503021008",
    "district_id": "750302",
    "label": "Padengo",
    "value": "Padengo"
  },
  {
    "id": "7503021009",
    "district_id": "750302",
    "label": "Olohuta",
    "value": "Olohuta"
  },
  {
    "id": "7503021010",
    "district_id": "750302",
    "label": "Tumbihe",
    "value": "Tumbihe"
  },
  {
    "id": "7503021011",
    "district_id": "750302",
    "label": "Pauwo",
    "value": "Pauwo"
  },
  {
    "id": "7503021028",
    "district_id": "750302",
    "label": "Olohuta Utara",
    "value": "Olohuta Utara"
  },
  {
    "id": "7503022006",
    "district_id": "750302",
    "label": "Dutohe",
    "value": "Dutohe"
  },
  {
    "id": "7503022007",
    "district_id": "750302",
    "label": "Tanggilingo",
    "value": "Tanggilingo"
  },
  {
    "id": "7503022012",
    "district_id": "750302",
    "label": "Toto Selatan",
    "value": "Toto Selatan"
  },
  {
    "id": "7503022013",
    "district_id": "750302",
    "label": "Poowo",
    "value": "Poowo"
  },
  {
    "id": "7503022024",
    "district_id": "750302",
    "label": "Talango",
    "value": "Talango"
  },
  {
    "id": "7503022026",
    "district_id": "750302",
    "label": "Poowo Barat",
    "value": "Poowo Barat"
  },
  {
    "id": "7503022029",
    "district_id": "750302",
    "label": "Dutohe Barat",
    "value": "Dutohe Barat"
  },
  {
    "id": "7503032011",
    "district_id": "750303",
    "label": "Tingkohubu",
    "value": "Tingkohubu"
  },
  {
    "id": "7503032012",
    "district_id": "750303",
    "label": "Boludawa",
    "value": "Boludawa"
  },
  {
    "id": "7503032013",
    "district_id": "750303",
    "label": "Bube",
    "value": "Bube"
  },
  {
    "id": "7503032014",
    "district_id": "750303",
    "label": "Huluduotamo",
    "value": "Huluduotamo"
  },
  {
    "id": "7503032020",
    "district_id": "750303",
    "label": "Ulanta",
    "value": "Ulanta"
  },
  {
    "id": "7503032021",
    "district_id": "750303",
    "label": "Tinelo",
    "value": "Tinelo"
  },
  {
    "id": "7503032022",
    "district_id": "750303",
    "label": "Bubeya",
    "value": "Bubeya"
  },
  {
    "id": "7503032023",
    "district_id": "750303",
    "label": "Bube Baru",
    "value": "Bube Baru"
  },
  {
    "id": "7503032027",
    "district_id": "750303",
    "label": "Tingkohubu Timur",
    "value": "Tingkohubu Timur"
  },
  {
    "id": "7503032028",
    "district_id": "750303",
    "label": "Helumo",
    "value": "Helumo"
  },
  {
    "id": "7503042003",
    "district_id": "750304",
    "label": "Tamboo",
    "value": "Tamboo"
  },
  {
    "id": "7503042004",
    "district_id": "750304",
    "label": "Bilungala",
    "value": "Bilungala"
  },
  {
    "id": "7503042005",
    "district_id": "750304",
    "label": "Uabanga",
    "value": "Uabanga"
  },
  {
    "id": "7503042008",
    "district_id": "750304",
    "label": "Tolotio",
    "value": "Tolotio"
  },
  {
    "id": "7503042012",
    "district_id": "750304",
    "label": "Tongo",
    "value": "Tongo"
  },
  {
    "id": "7503042025",
    "district_id": "750304",
    "label": "Bilungala Utara",
    "value": "Bilungala Utara"
  },
  {
    "id": "7503042026",
    "district_id": "750304",
    "label": "Tihu",
    "value": "Tihu"
  },
  {
    "id": "7503042027",
    "district_id": "750304",
    "label": "Tunas Jaya",
    "value": "Tunas Jaya"
  },
  {
    "id": "7503042028",
    "district_id": "750304",
    "label": "Lembah Hijau",
    "value": "Lembah Hijau"
  },
  {
    "id": "7503042029",
    "district_id": "750304",
    "label": "Batu Hijau",
    "value": "Batu Hijau"
  },
  {
    "id": "7503042031",
    "district_id": "750304",
    "label": "Ombulo Hijau",
    "value": "Ombulo Hijau"
  },
  {
    "id": "7503042032",
    "district_id": "750304",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "7503042033",
    "district_id": "750304",
    "label": "Pelita Hijau",
    "value": "Pelita Hijau"
  },
  {
    "id": "7503052001",
    "district_id": "750305",
    "label": "Boidu",
    "value": "Boidu"
  },
  {
    "id": "7503052002",
    "district_id": "750305",
    "label": "Bandungan",
    "value": "Bandungan"
  },
  {
    "id": "7503052003",
    "district_id": "750305",
    "label": "Tupa",
    "value": "Tupa"
  },
  {
    "id": "7503052004",
    "district_id": "750305",
    "label": "Longalo",
    "value": "Longalo"
  },
  {
    "id": "7503052009",
    "district_id": "750305",
    "label": "Tuloa",
    "value": "Tuloa"
  },
  {
    "id": "7503052010",
    "district_id": "750305",
    "label": "Kopi",
    "value": "Kopi"
  },
  {
    "id": "7503052011",
    "district_id": "750305",
    "label": "Lomaya",
    "value": "Lomaya"
  },
  {
    "id": "7503052012",
    "district_id": "750305",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "7503052015",
    "district_id": "750305",
    "label": "Bunuo",
    "value": "Bunuo"
  },
  {
    "id": "7503062001",
    "district_id": "750306",
    "label": "Bongoime",
    "value": "Bongoime"
  },
  {
    "id": "7503062002",
    "district_id": "750306",
    "label": "Bongopini",
    "value": "Bongopini"
  },
  {
    "id": "7503062003",
    "district_id": "750306",
    "label": "Toto Utara",
    "value": "Toto Utara"
  },
  {
    "id": "7503062004",
    "district_id": "750306",
    "label": "Moutong",
    "value": "Moutong"
  },
  {
    "id": "7503062005",
    "district_id": "750306",
    "label": "Tunggulo",
    "value": "Tunggulo"
  },
  {
    "id": "7503062006",
    "district_id": "750306",
    "label": "Lonuo",
    "value": "Lonuo"
  },
  {
    "id": "7503062007",
    "district_id": "750306",
    "label": "Tamboo",
    "value": "Tamboo"
  },
  {
    "id": "7503062008",
    "district_id": "750306",
    "label": "Iloheluma",
    "value": "Iloheluma"
  },
  {
    "id": "7503062009",
    "district_id": "750306",
    "label": "Motilango",
    "value": "Motilango"
  },
  {
    "id": "7503062010",
    "district_id": "750306",
    "label": "Butu",
    "value": "Butu"
  },
  {
    "id": "7503062011",
    "district_id": "750306",
    "label": "Permata",
    "value": "Permata"
  },
  {
    "id": "7503062012",
    "district_id": "750306",
    "label": "Tunggulo Selatan",
    "value": "Tunggulo Selatan"
  },
  {
    "id": "7503062013",
    "district_id": "750306",
    "label": "Bongohulawa",
    "value": "Bongohulawa"
  },
  {
    "id": "7503062014",
    "district_id": "750306",
    "label": "Berlian",
    "value": "Berlian"
  },
  {
    "id": "7503072001",
    "district_id": "750307",
    "label": "Timbuolo",
    "value": "Timbuolo"
  },
  {
    "id": "7503072002",
    "district_id": "750307",
    "label": "Panggulo",
    "value": "Panggulo"
  },
  {
    "id": "7503072003",
    "district_id": "750307",
    "label": "Luwohu",
    "value": "Luwohu"
  },
  {
    "id": "7503072004",
    "district_id": "750307",
    "label": "Buata",
    "value": "Buata"
  },
  {
    "id": "7503072005",
    "district_id": "750307",
    "label": "Timbuolo Timur",
    "value": "Timbuolo Timur"
  },
  {
    "id": "7503072006",
    "district_id": "750307",
    "label": "Tanah Putih",
    "value": "Tanah Putih"
  },
  {
    "id": "7503072007",
    "district_id": "750307",
    "label": "Panggulo Barat",
    "value": "Panggulo Barat"
  },
  {
    "id": "7503072008",
    "district_id": "750307",
    "label": "Timbuolo Tengah",
    "value": "Timbuolo Tengah"
  },
  {
    "id": "7503072009",
    "district_id": "750307",
    "label": "Sukma",
    "value": "Sukma"
  },
  {
    "id": "7503082001",
    "district_id": "750308",
    "label": "Huangobotu",
    "value": "Huangobotu"
  },
  {
    "id": "7503082002",
    "district_id": "750308",
    "label": "Molutabu",
    "value": "Molutabu"
  },
  {
    "id": "7503082003",
    "district_id": "750308",
    "label": "Oluhuta",
    "value": "Oluhuta"
  },
  {
    "id": "7503082004",
    "district_id": "750308",
    "label": "Olele",
    "value": "Olele"
  },
  {
    "id": "7503082005",
    "district_id": "750308",
    "label": "Botutonuo",
    "value": "Botutonuo"
  },
  {
    "id": "7503082006",
    "district_id": "750308",
    "label": "Modelomo",
    "value": "Modelomo"
  },
  {
    "id": "7503082007",
    "district_id": "750308",
    "label": "Biluango",
    "value": "Biluango"
  },
  {
    "id": "7503082008",
    "district_id": "750308",
    "label": "Botubarani",
    "value": "Botubarani"
  },
  {
    "id": "7503082009",
    "district_id": "750308",
    "label": "Bintalahe",
    "value": "Bintalahe"
  },
  {
    "id": "7503092001",
    "district_id": "750309",
    "label": "Taludaa",
    "value": "Taludaa"
  },
  {
    "id": "7503092002",
    "district_id": "750309",
    "label": "Sogitia",
    "value": "Sogitia"
  },
  {
    "id": "7503092003",
    "district_id": "750309",
    "label": "Moodulio",
    "value": "Moodulio"
  },
  {
    "id": "7503092004",
    "district_id": "750309",
    "label": "Bilonlantunga",
    "value": "Bilonlantunga"
  },
  {
    "id": "7503092005",
    "district_id": "750309",
    "label": "Inogaluma",
    "value": "Inogaluma"
  },
  {
    "id": "7503092006",
    "district_id": "750309",
    "label": "Monano",
    "value": "Monano"
  },
  {
    "id": "7503092007",
    "district_id": "750309",
    "label": "Tumbuh Mekar",
    "value": "Tumbuh Mekar"
  },
  {
    "id": "7503092008",
    "district_id": "750309",
    "label": "Molamahu",
    "value": "Molamahu"
  },
  {
    "id": "7503092009",
    "district_id": "750309",
    "label": "Masiaga",
    "value": "Masiaga"
  },
  {
    "id": "7503092010",
    "district_id": "750309",
    "label": "Ilohuuwa",
    "value": "Ilohuuwa"
  },
  {
    "id": "7503092011",
    "district_id": "750309",
    "label": "Muara Bone",
    "value": "Muara Bone"
  },
  {
    "id": "7503092012",
    "district_id": "750309",
    "label": "Cendana Putih",
    "value": "Cendana Putih"
  },
  {
    "id": "7503092013",
    "district_id": "750309",
    "label": "Waluhu",
    "value": "Waluhu"
  },
  {
    "id": "7503092014",
    "district_id": "750309",
    "label": "Permata",
    "value": "Permata"
  },
  {
    "id": "7503102001",
    "district_id": "750310",
    "label": "Inomata",
    "value": "Inomata"
  },
  {
    "id": "7503102003",
    "district_id": "750310",
    "label": "Tumbulilato",
    "value": "Tumbulilato"
  },
  {
    "id": "7503102004",
    "district_id": "750310",
    "label": "Mootayu",
    "value": "Mootayu"
  },
  {
    "id": "7503102006",
    "district_id": "750310",
    "label": "Mootinelo",
    "value": "Mootinelo"
  },
  {
    "id": "7503102009",
    "district_id": "750310",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "7503102010",
    "district_id": "750310",
    "label": "Moopiya",
    "value": "Moopiya"
  },
  {
    "id": "7503102011",
    "district_id": "750310",
    "label": "Alo",
    "value": "Alo"
  },
  {
    "id": "7503102012",
    "district_id": "750310",
    "label": "Laut Biru",
    "value": "Laut Biru"
  },
  {
    "id": "7503102014",
    "district_id": "750310",
    "label": "Bunga",
    "value": "Bunga"
  },
  {
    "id": "7503102015",
    "district_id": "750310",
    "label": "Mootawa",
    "value": "Mootawa"
  },
  {
    "id": "7503112001",
    "district_id": "750311",
    "label": "Tulabolo",
    "value": "Tulabolo"
  },
  {
    "id": "7503112003",
    "district_id": "750311",
    "label": "Dumbaya Bulan",
    "value": "Dumbaya Bulan"
  },
  {
    "id": "7503112004",
    "district_id": "750311",
    "label": "Tulabolo Timur",
    "value": "Tulabolo Timur"
  },
  {
    "id": "7503112005",
    "district_id": "750311",
    "label": "Tilangobula",
    "value": "Tilangobula"
  },
  {
    "id": "7503112007",
    "district_id": "750311",
    "label": "Poduwoma",
    "value": "Poduwoma"
  },
  {
    "id": "7503112008",
    "district_id": "750311",
    "label": "Panggulo",
    "value": "Panggulo"
  },
  {
    "id": "7503112009",
    "district_id": "750311",
    "label": "Tulabolo Barat",
    "value": "Tulabolo Barat"
  },
  {
    "id": "7503112010",
    "district_id": "750311",
    "label": "Pangi",
    "value": "Pangi"
  },
  {
    "id": "7503112011",
    "district_id": "750311",
    "label": "Tinemba",
    "value": "Tinemba"
  },
  {
    "id": "7503122001",
    "district_id": "750312",
    "label": "Bulontala",
    "value": "Bulontala"
  },
  {
    "id": "7503122002",
    "district_id": "750312",
    "label": "Libungo",
    "value": "Libungo"
  },
  {
    "id": "7503122003",
    "district_id": "750312",
    "label": "Molintogupo",
    "value": "Molintogupo"
  },
  {
    "id": "7503122004",
    "district_id": "750312",
    "label": "Bonedaa",
    "value": "Bonedaa"
  },
  {
    "id": "7503122005",
    "district_id": "750312",
    "label": "Bondawuna",
    "value": "Bondawuna"
  },
  {
    "id": "7503122006",
    "district_id": "750312",
    "label": "Bulontala Timur",
    "value": "Bulontala Timur"
  },
  {
    "id": "7503122007",
    "district_id": "750312",
    "label": "Pancuran",
    "value": "Pancuran"
  },
  {
    "id": "7503122008",
    "district_id": "750312",
    "label": "Bondaraya",
    "value": "Bondaraya"
  },
  {
    "id": "7503132001",
    "district_id": "750313",
    "label": "Lompotoo",
    "value": "Lompotoo"
  },
  {
    "id": "7503132002",
    "district_id": "750313",
    "label": "Lombongo",
    "value": "Lombongo"
  },
  {
    "id": "7503132003",
    "district_id": "750313",
    "label": "Duano",
    "value": "Duano"
  },
  {
    "id": "7503132004",
    "district_id": "750313",
    "label": "Tolomato",
    "value": "Tolomato"
  },
  {
    "id": "7503132005",
    "district_id": "750313",
    "label": "Alale",
    "value": "Alale"
  },
  {
    "id": "7503132006",
    "district_id": "750313",
    "label": "Tapadaa",
    "value": "Tapadaa"
  },
  {
    "id": "7503142001",
    "district_id": "750314",
    "label": "Mongiilo",
    "value": "Mongiilo"
  },
  {
    "id": "7503142002",
    "district_id": "750314",
    "label": "Owata",
    "value": "Owata"
  },
  {
    "id": "7503142003",
    "district_id": "750314",
    "label": "Mongiilo Utara",
    "value": "Mongiilo Utara"
  },
  {
    "id": "7503142004",
    "district_id": "750314",
    "label": "Pilolaheya",
    "value": "Pilolaheya"
  },
  {
    "id": "7503142005",
    "district_id": "750314",
    "label": "Ilomata",
    "value": "Ilomata"
  },
  {
    "id": "7503142006",
    "district_id": "750314",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "7503152001",
    "district_id": "750315",
    "label": "Ayula Selatan",
    "value": "Ayula Selatan"
  },
  {
    "id": "7503152002",
    "district_id": "750315",
    "label": "Huntu Utara",
    "value": "Huntu Utara"
  },
  {
    "id": "7503152003",
    "district_id": "750315",
    "label": "Ayula Utara",
    "value": "Ayula Utara"
  },
  {
    "id": "7503152004",
    "district_id": "750315",
    "label": "Huntu Selatan",
    "value": "Huntu Selatan"
  },
  {
    "id": "7503152006",
    "district_id": "750315",
    "label": "Ayula Tilango",
    "value": "Ayula Tilango"
  },
  {
    "id": "7503152007",
    "district_id": "750315",
    "label": "Ayula Timur",
    "value": "Ayula Timur"
  },
  {
    "id": "7503152008",
    "district_id": "750315",
    "label": "Lamahu",
    "value": "Lamahu"
  },
  {
    "id": "7503152009",
    "district_id": "750315",
    "label": "Tinelo Ayula",
    "value": "Tinelo Ayula"
  },
  {
    "id": "7503152010",
    "district_id": "750315",
    "label": "Sejahtera",
    "value": "Sejahtera"
  },
  {
    "id": "7503152011",
    "district_id": "750315",
    "label": "Huntu Barat",
    "value": "Huntu Barat"
  },
  {
    "id": "7503162001",
    "district_id": "750316",
    "label": "Bulotalangi",
    "value": "Bulotalangi"
  },
  {
    "id": "7503162002",
    "district_id": "750316",
    "label": "Toluwaya",
    "value": "Toluwaya"
  },
  {
    "id": "7503162003",
    "district_id": "750316",
    "label": "Popodu",
    "value": "Popodu"
  },
  {
    "id": "7503162004",
    "district_id": "750316",
    "label": "Bulotalangi Timur",
    "value": "Bulotalangi Timur"
  },
  {
    "id": "7503162005",
    "district_id": "750316",
    "label": "Bulotalangi Barat",
    "value": "Bulotalangi Barat"
  },
  {
    "id": "7503172001",
    "district_id": "750317",
    "label": "Mamungaa",
    "value": "Mamungaa"
  },
  {
    "id": "7503172002",
    "district_id": "750317",
    "label": "Kaidundu Barat",
    "value": "Kaidundu Barat"
  },
  {
    "id": "7503172003",
    "district_id": "750317",
    "label": "Mopuya",
    "value": "Mopuya"
  },
  {
    "id": "7503172004",
    "district_id": "750317",
    "label": "Kaidundu",
    "value": "Kaidundu"
  },
  {
    "id": "7503172005",
    "district_id": "750317",
    "label": "Bukit Hijau",
    "value": "Bukit Hijau"
  },
  {
    "id": "7503172006",
    "district_id": "750317",
    "label": "Mamungaa Timur",
    "value": "Mamungaa Timur"
  },
  {
    "id": "7503172007",
    "district_id": "750317",
    "label": "Dunggilata",
    "value": "Dunggilata"
  },
  {
    "id": "7503172008",
    "district_id": "750317",
    "label": "Pinomotiga",
    "value": "Pinomotiga"
  },
  {
    "id": "7503172009",
    "district_id": "750317",
    "label": "Patoa",
    "value": "Patoa"
  },
  {
    "id": "7503182001",
    "district_id": "750318",
    "label": "Pinogu",
    "value": "Pinogu"
  },
  {
    "id": "7503182002",
    "district_id": "750318",
    "label": "Bangio",
    "value": "Bangio"
  },
  {
    "id": "7503182003",
    "district_id": "750318",
    "label": "Dataran Hijau",
    "value": "Dataran Hijau"
  },
  {
    "id": "7503182004",
    "district_id": "750318",
    "label": "Pinogu Permai",
    "value": "Pinogu Permai"
  },
  {
    "id": "7503182005",
    "district_id": "750318",
    "label": "Tilonggibila",
    "value": "Tilonggibila"
  },
  {
    "id": "7504012003",
    "district_id": "750401",
    "label": "Torosiaje Jaya",
    "value": "Torosiaje Jaya"
  },
  {
    "id": "7504012004",
    "district_id": "750401",
    "label": "Popayato",
    "value": "Popayato"
  },
  {
    "id": "7504012008",
    "district_id": "750401",
    "label": "Telaga",
    "value": "Telaga"
  },
  {
    "id": "7504012012",
    "district_id": "750401",
    "label": "Torosiaje",
    "value": "Torosiaje"
  },
  {
    "id": "7504012015",
    "district_id": "750401",
    "label": "Bukit Tingki",
    "value": "Bukit Tingki"
  },
  {
    "id": "7504012016",
    "district_id": "750401",
    "label": "Tunas Harapan",
    "value": "Tunas Harapan"
  },
  {
    "id": "7504012017",
    "district_id": "750401",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "7504012018",
    "district_id": "750401",
    "label": "Dambalo",
    "value": "Dambalo"
  },
  {
    "id": "7504012019",
    "district_id": "750401",
    "label": "Telaga Biru",
    "value": "Telaga Biru"
  },
  {
    "id": "7504012020",
    "district_id": "750401",
    "label": "Bumi Bahari",
    "value": "Bumi Bahari"
  },
  {
    "id": "7504022001",
    "district_id": "750402",
    "label": "Lemito",
    "value": "Lemito"
  },
  {
    "id": "7504022002",
    "district_id": "750402",
    "label": "Wonggarasi Barat",
    "value": "Wonggarasi Barat"
  },
  {
    "id": "7504022004",
    "district_id": "750402",
    "label": "Lomuli",
    "value": "Lomuli"
  },
  {
    "id": "7504022008",
    "district_id": "750402",
    "label": "Lemito Utara",
    "value": "Lemito Utara"
  },
  {
    "id": "7504022009",
    "district_id": "750402",
    "label": "Kenari",
    "value": "Kenari"
  },
  {
    "id": "7504022010",
    "district_id": "750402",
    "label": "Wonggarasi Tengah",
    "value": "Wonggarasi Tengah"
  },
  {
    "id": "7504022011",
    "district_id": "750402",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "7504022012",
    "district_id": "750402",
    "label": "Babalonge",
    "value": "Babalonge"
  },
  {
    "id": "7504032001",
    "district_id": "750403",
    "label": "Motolohu",
    "value": "Motolohu"
  },
  {
    "id": "7504032002",
    "district_id": "750403",
    "label": "Imbodu",
    "value": "Imbodu"
  },
  {
    "id": "7504032003",
    "district_id": "750403",
    "label": "Manunggal Karya",
    "value": "Manunggal Karya"
  },
  {
    "id": "7504032004",
    "district_id": "750403",
    "label": "Sidorukun",
    "value": "Sidorukun"
  },
  {
    "id": "7504032008",
    "district_id": "750403",
    "label": "Sarimurni",
    "value": "Sarimurni"
  },
  {
    "id": "7504032009",
    "district_id": "750403",
    "label": "Huyula",
    "value": "Huyula"
  },
  {
    "id": "7504032012",
    "district_id": "750403",
    "label": "Omayuwa",
    "value": "Omayuwa"
  },
  {
    "id": "7504032013",
    "district_id": "750403",
    "label": "Ayula",
    "value": "Ayula"
  },
  {
    "id": "7504032014",
    "district_id": "750403",
    "label": "Patuhu",
    "value": "Patuhu"
  },
  {
    "id": "7504032015",
    "district_id": "750403",
    "label": "Banuroja",
    "value": "Banuroja"
  },
  {
    "id": "7504032016",
    "district_id": "750403",
    "label": "Pelambane",
    "value": "Pelambane"
  },
  {
    "id": "7504032017",
    "district_id": "750403",
    "label": "Motolohu Selatan",
    "value": "Motolohu Selatan"
  },
  {
    "id": "7504032018",
    "district_id": "750403",
    "label": "Sidowonge",
    "value": "Sidowonge"
  },
  {
    "id": "7504042003",
    "district_id": "750404",
    "label": "Marisa Selatan",
    "value": "Marisa Selatan"
  },
  {
    "id": "7504042004",
    "district_id": "750404",
    "label": "Marisa Utara",
    "value": "Marisa Utara"
  },
  {
    "id": "7504042007",
    "district_id": "750404",
    "label": "Teratai",
    "value": "Teratai"
  },
  {
    "id": "7504042008",
    "district_id": "750404",
    "label": "Pohuwato",
    "value": "Pohuwato"
  },
  {
    "id": "7504042015",
    "district_id": "750404",
    "label": "Batubilotahu",
    "value": "Batubilotahu"
  },
  {
    "id": "7504042016",
    "district_id": "750404",
    "label": "Palopo",
    "value": "Palopo"
  },
  {
    "id": "7504042017",
    "district_id": "750404",
    "label": "Pohuwato Timur",
    "value": "Pohuwato Timur"
  },
  {
    "id": "7504042018",
    "district_id": "750404",
    "label": "Bulangita",
    "value": "Bulangita"
  },
  {
    "id": "7504051001",
    "district_id": "750405",
    "label": "Libuo",
    "value": "Libuo"
  },
  {
    "id": "7504051002",
    "district_id": "750405",
    "label": "Pentadu",
    "value": "Pentadu"
  },
  {
    "id": "7504051003",
    "district_id": "750405",
    "label": "Siduan",
    "value": "Siduan"
  },
  {
    "id": "7504052004",
    "district_id": "750405",
    "label": "Bunuyo",
    "value": "Bunuyo"
  },
  {
    "id": "7504052005",
    "district_id": "750405",
    "label": "Sipayo",
    "value": "Sipayo"
  },
  {
    "id": "7504052006",
    "district_id": "750405",
    "label": "Soginti",
    "value": "Soginti"
  },
  {
    "id": "7504052010",
    "district_id": "750405",
    "label": "Bumbulan",
    "value": "Bumbulan"
  },
  {
    "id": "7504052012",
    "district_id": "750405",
    "label": "Maleo",
    "value": "Maleo"
  },
  {
    "id": "7504052013",
    "district_id": "750405",
    "label": "Molamahu",
    "value": "Molamahu"
  },
  {
    "id": "7504052014",
    "district_id": "750405",
    "label": "Buhu Jaya",
    "value": "Buhu Jaya"
  },
  {
    "id": "7504052015",
    "district_id": "750405",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "7504062001",
    "district_id": "750406",
    "label": "Iloheluma",
    "value": "Iloheluma"
  },
  {
    "id": "7504062002",
    "district_id": "750406",
    "label": "Balayo",
    "value": "Balayo"
  },
  {
    "id": "7504062003",
    "district_id": "750406",
    "label": "Suka Makmur",
    "value": "Suka Makmur"
  },
  {
    "id": "7504062004",
    "district_id": "750406",
    "label": "Manawa",
    "value": "Manawa"
  },
  {
    "id": "7504062005",
    "district_id": "750406",
    "label": "Dulomo",
    "value": "Dulomo"
  },
  {
    "id": "7504062006",
    "district_id": "750406",
    "label": "Dudepo",
    "value": "Dudepo"
  },
  {
    "id": "7504072001",
    "district_id": "750407",
    "label": "Pancakarsa I",
    "value": "Pancakarsa I"
  },
  {
    "id": "7504072002",
    "district_id": "750407",
    "label": "Pancakarsa II",
    "value": "Pancakarsa II"
  },
  {
    "id": "7504072003",
    "district_id": "750407",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "7504072004",
    "district_id": "750407",
    "label": "Kalimas",
    "value": "Kalimas"
  },
  {
    "id": "7504072005",
    "district_id": "750407",
    "label": "Malango",
    "value": "Malango"
  },
  {
    "id": "7504072006",
    "district_id": "750407",
    "label": "Puncak Jaya",
    "value": "Puncak Jaya"
  },
  {
    "id": "7504072007",
    "district_id": "750407",
    "label": "Tirto Asri",
    "value": "Tirto Asri"
  },
  {
    "id": "7504082001",
    "district_id": "750408",
    "label": "Popaya",
    "value": "Popaya"
  },
  {
    "id": "7504082002",
    "district_id": "750408",
    "label": "Karya Baru",
    "value": "Karya Baru"
  },
  {
    "id": "7504082003",
    "district_id": "750408",
    "label": "Karangetan",
    "value": "Karangetan"
  },
  {
    "id": "7504082004",
    "district_id": "750408",
    "label": "Padengo",
    "value": "Padengo"
  },
  {
    "id": "7504082005",
    "district_id": "750408",
    "label": "Hutamoputi",
    "value": "Hutamoputi"
  },
  {
    "id": "7504092001",
    "district_id": "750409",
    "label": "Hulawa",
    "value": "Hulawa"
  },
  {
    "id": "7504092002",
    "district_id": "750409",
    "label": "Karya Indah",
    "value": "Karya Indah"
  },
  {
    "id": "7504092003",
    "district_id": "750409",
    "label": "Taluduyunu",
    "value": "Taluduyunu"
  },
  {
    "id": "7504092004",
    "district_id": "750409",
    "label": "Buntulia Utara",
    "value": "Buntulia Utara"
  },
  {
    "id": "7504092005",
    "district_id": "750409",
    "label": "Buntulia Tengah",
    "value": "Buntulia Tengah"
  },
  {
    "id": "7504092006",
    "district_id": "750409",
    "label": "Taluduyunu Utara",
    "value": "Taluduyunu Utara"
  },
  {
    "id": "7504092007",
    "district_id": "750409",
    "label": "Sipatana",
    "value": "Sipatana"
  },
  {
    "id": "7504102001",
    "district_id": "750410",
    "label": "Bulili",
    "value": "Bulili"
  },
  {
    "id": "7504102002",
    "district_id": "750410",
    "label": "Buntulia Barat",
    "value": "Buntulia Barat"
  },
  {
    "id": "7504102003",
    "district_id": "750410",
    "label": "Buntulia Selatan",
    "value": "Buntulia Selatan"
  },
  {
    "id": "7504102004",
    "district_id": "750410",
    "label": "Duhiadaa",
    "value": "Duhiadaa"
  },
  {
    "id": "7504102005",
    "district_id": "750410",
    "label": "Mekar Jaya",
    "value": "Mekar Jaya"
  },
  {
    "id": "7504102006",
    "district_id": "750410",
    "label": "Buntulia Jaya",
    "value": "Buntulia Jaya"
  },
  {
    "id": "7504102007",
    "district_id": "750410",
    "label": "Padengo",
    "value": "Padengo"
  },
  {
    "id": "7504102008",
    "district_id": "750410",
    "label": "Mootilango",
    "value": "Mootilango"
  },
  {
    "id": "7504112001",
    "district_id": "750411",
    "label": "Wanggarasi Timur",
    "value": "Wanggarasi Timur"
  },
  {
    "id": "7504112002",
    "district_id": "750411",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "7504112003",
    "district_id": "750411",
    "label": "Bohusami",
    "value": "Bohusami"
  },
  {
    "id": "7504112004",
    "district_id": "750411",
    "label": "Lembah Permai",
    "value": "Lembah Permai"
  },
  {
    "id": "7504112005",
    "district_id": "750411",
    "label": "Limbula",
    "value": "Limbula"
  },
  {
    "id": "7504112006",
    "district_id": "750411",
    "label": "Yipilo",
    "value": "Yipilo"
  },
  {
    "id": "7504112007",
    "district_id": "750411",
    "label": "Tuweya",
    "value": "Tuweya"
  },
  {
    "id": "7504122001",
    "district_id": "750412",
    "label": "Milangodaa",
    "value": "Milangodaa"
  },
  {
    "id": "7504122002",
    "district_id": "750412",
    "label": "Londoun",
    "value": "Londoun"
  },
  {
    "id": "7504122003",
    "district_id": "750412",
    "label": "Marisa",
    "value": "Marisa"
  },
  {
    "id": "7504122004",
    "district_id": "750412",
    "label": "Tahele",
    "value": "Tahele"
  },
  {
    "id": "7504122005",
    "district_id": "750412",
    "label": "Bunto",
    "value": "Bunto"
  },
  {
    "id": "7504122006",
    "district_id": "750412",
    "label": "Maleo",
    "value": "Maleo"
  },
  {
    "id": "7504122007",
    "district_id": "750412",
    "label": "Kelapa Lima",
    "value": "Kelapa Lima"
  },
  {
    "id": "7504132001",
    "district_id": "750413",
    "label": "Dudewulo",
    "value": "Dudewulo"
  },
  {
    "id": "7504132002",
    "district_id": "750413",
    "label": "Tunas Jaya",
    "value": "Tunas Jaya"
  },
  {
    "id": "7504132003",
    "district_id": "750413",
    "label": "Padengo",
    "value": "Padengo"
  },
  {
    "id": "7504132004",
    "district_id": "750413",
    "label": "Molosifat",
    "value": "Molosifat"
  },
  {
    "id": "7504132005",
    "district_id": "750413",
    "label": "Butungale",
    "value": "Butungale"
  },
  {
    "id": "7504132006",
    "district_id": "750413",
    "label": "Persatuan",
    "value": "Persatuan"
  },
  {
    "id": "7504132007",
    "district_id": "750413",
    "label": "Molosifat Utara",
    "value": "Molosifat Utara"
  },
  {
    "id": "7505012003",
    "district_id": "750501",
    "label": "Imana",
    "value": "Imana"
  },
  {
    "id": "7505012004",
    "district_id": "750501",
    "label": "Bintana",
    "value": "Bintana"
  },
  {
    "id": "7505012005",
    "district_id": "750501",
    "label": "Buata",
    "value": "Buata"
  },
  {
    "id": "7505012006",
    "district_id": "750501",
    "label": "Pinontoyonga",
    "value": "Pinontoyonga"
  },
  {
    "id": "7505012007",
    "district_id": "750501",
    "label": "Monggupo",
    "value": "Monggupo"
  },
  {
    "id": "7505012008",
    "district_id": "750501",
    "label": "Kotajin",
    "value": "Kotajin"
  },
  {
    "id": "7505012011",
    "district_id": "750501",
    "label": "Ilomata",
    "value": "Ilomata"
  },
  {
    "id": "7505012013",
    "district_id": "750501",
    "label": "Iloheluma",
    "value": "Iloheluma"
  },
  {
    "id": "7505012014",
    "district_id": "750501",
    "label": "Wapalo",
    "value": "Wapalo"
  },
  {
    "id": "7505012015",
    "district_id": "750501",
    "label": "Posono",
    "value": "Posono"
  },
  {
    "id": "7505012016",
    "district_id": "750501",
    "label": "Sigaso",
    "value": "Sigaso"
  },
  {
    "id": "7505012017",
    "district_id": "750501",
    "label": "Tombulilato",
    "value": "Tombulilato"
  },
  {
    "id": "7505012018",
    "district_id": "750501",
    "label": "Kotajin Utara",
    "value": "Kotajin Utara"
  },
  {
    "id": "7505012019",
    "district_id": "750501",
    "label": "Oluhuta",
    "value": "Oluhuta"
  },
  {
    "id": "7505022001",
    "district_id": "750502",
    "label": "Pontolo",
    "value": "Pontolo"
  },
  {
    "id": "7505022002",
    "district_id": "750502",
    "label": "Molingkapoto",
    "value": "Molingkapoto"
  },
  {
    "id": "7505022003",
    "district_id": "750502",
    "label": "Leboto",
    "value": "Leboto"
  },
  {
    "id": "7505022004",
    "district_id": "750502",
    "label": "Posso",
    "value": "Posso"
  },
  {
    "id": "7505022005",
    "district_id": "750502",
    "label": "Titidu",
    "value": "Titidu"
  },
  {
    "id": "7505022006",
    "district_id": "750502",
    "label": "Moluo",
    "value": "Moluo"
  },
  {
    "id": "7505022010",
    "district_id": "750502",
    "label": "Mootinelo",
    "value": "Mootinelo"
  },
  {
    "id": "7505022011",
    "district_id": "750502",
    "label": "Bualemo",
    "value": "Bualemo"
  },
  {
    "id": "7505022013",
    "district_id": "750502",
    "label": "Bulalo",
    "value": "Bulalo"
  },
  {
    "id": "7505022017",
    "district_id": "750502",
    "label": "Katialada",
    "value": "Katialada"
  },
  {
    "id": "7505022019",
    "district_id": "750502",
    "label": "Ombulodata",
    "value": "Ombulodata"
  },
  {
    "id": "7505022021",
    "district_id": "750502",
    "label": "Alata Karya",
    "value": "Alata Karya"
  },
  {
    "id": "7505022023",
    "district_id": "750502",
    "label": "Cisadane",
    "value": "Cisadane"
  },
  {
    "id": "7505022024",
    "district_id": "750502",
    "label": "Botuwombata",
    "value": "Botuwombata"
  },
  {
    "id": "7505022025",
    "district_id": "750502",
    "label": "Botungobungo",
    "value": "Botungobungo"
  },
  {
    "id": "7505022026",
    "district_id": "750502",
    "label": "Molingkapoto Selatan",
    "value": "Molingkapoto Selatan"
  },
  {
    "id": "7505022030",
    "district_id": "750502",
    "label": "Pontolo Atas",
    "value": "Pontolo Atas"
  },
  {
    "id": "7505022032",
    "district_id": "750502",
    "label": "Masuru",
    "value": "Masuru"
  },
  {
    "id": "7505032001",
    "district_id": "750503",
    "label": "Ilangata",
    "value": "Ilangata"
  },
  {
    "id": "7505032002",
    "district_id": "750503",
    "label": "Tolongio",
    "value": "Tolongio"
  },
  {
    "id": "7505032006",
    "district_id": "750503",
    "label": "Tolango",
    "value": "Tolango"
  },
  {
    "id": "7505032007",
    "district_id": "750503",
    "label": "Popalo",
    "value": "Popalo"
  },
  {
    "id": "7505032008",
    "district_id": "750503",
    "label": "Dudepo",
    "value": "Dudepo"
  },
  {
    "id": "7505032009",
    "district_id": "750503",
    "label": "Mootilango",
    "value": "Mootilango"
  },
  {
    "id": "7505032011",
    "district_id": "750503",
    "label": "Langge",
    "value": "Langge"
  },
  {
    "id": "7505032012",
    "district_id": "750503",
    "label": "Tutuwoto",
    "value": "Tutuwoto"
  },
  {
    "id": "7505032013",
    "district_id": "750503",
    "label": "Hiyalooile",
    "value": "Hiyalooile"
  },
  {
    "id": "7505032014",
    "district_id": "750503",
    "label": "Ibarat",
    "value": "Ibarat"
  },
  {
    "id": "7505032017",
    "district_id": "750503",
    "label": "Iloheluma",
    "value": "Iloheluma"
  },
  {
    "id": "7505032021",
    "district_id": "750503",
    "label": "Ilodulunga",
    "value": "Ilodulunga"
  },
  {
    "id": "7505032023",
    "district_id": "750503",
    "label": "Putiana",
    "value": "Putiana"
  },
  {
    "id": "7505032024",
    "district_id": "750503",
    "label": "Heluma",
    "value": "Heluma"
  },
  {
    "id": "7505032025",
    "district_id": "750503",
    "label": "Datahu",
    "value": "Datahu"
  },
  {
    "id": "7505042001",
    "district_id": "750504",
    "label": "Bulontiyo Timur",
    "value": "Bulontiyo Timur"
  },
  {
    "id": "7505042002",
    "district_id": "750504",
    "label": "Bulontiyo Barat",
    "value": "Bulontiyo Barat"
  },
  {
    "id": "7505042007",
    "district_id": "750504",
    "label": "Buloila",
    "value": "Buloila"
  },
  {
    "id": "7505042009",
    "district_id": "750504",
    "label": "Kasia",
    "value": "Kasia"
  },
  {
    "id": "7505042010",
    "district_id": "750504",
    "label": "Kikia",
    "value": "Kikia"
  },
  {
    "id": "7505042012",
    "district_id": "750504",
    "label": "Tumba",
    "value": "Tumba"
  },
  {
    "id": "7505042013",
    "district_id": "750504",
    "label": "Mebongo",
    "value": "Mebongo"
  },
  {
    "id": "7505042014",
    "district_id": "750504",
    "label": "Lelato",
    "value": "Lelato"
  },
  {
    "id": "7505042016",
    "district_id": "750504",
    "label": "Pulohenti",
    "value": "Pulohenti"
  },
  {
    "id": "7505042019",
    "district_id": "750504",
    "label": "Puncak Mandiri",
    "value": "Puncak Mandiri"
  },
  {
    "id": "7505042020",
    "district_id": "750504",
    "label": "Hutakalo",
    "value": "Hutakalo"
  },
  {
    "id": "7505052001",
    "district_id": "750505",
    "label": "Tolinggula Tengah",
    "value": "Tolinggula Tengah"
  },
  {
    "id": "7505052002",
    "district_id": "750505",
    "label": "Tolinggula Pantai",
    "value": "Tolinggula Pantai"
  },
  {
    "id": "7505052003",
    "district_id": "750505",
    "label": "Tolinggula Ulu",
    "value": "Tolinggula Ulu"
  },
  {
    "id": "7505052006",
    "district_id": "750505",
    "label": "Limbato",
    "value": "Limbato"
  },
  {
    "id": "7505052007",
    "district_id": "750505",
    "label": "Papualangi",
    "value": "Papualangi"
  },
  {
    "id": "7505052008",
    "district_id": "750505",
    "label": "Molangga",
    "value": "Molangga"
  },
  {
    "id": "7505052011",
    "district_id": "750505",
    "label": "Cempaka Putih",
    "value": "Cempaka Putih"
  },
  {
    "id": "7505052014",
    "district_id": "750505",
    "label": "Tolite Jaya",
    "value": "Tolite Jaya"
  },
  {
    "id": "7505052017",
    "district_id": "750505",
    "label": "Ilomangga",
    "value": "Ilomangga"
  },
  {
    "id": "7505052018",
    "district_id": "750505",
    "label": "Ilotunggulo",
    "value": "Ilotunggulo"
  },
  {
    "id": "7505062001",
    "district_id": "750506",
    "label": "Gentuma",
    "value": "Gentuma"
  },
  {
    "id": "7505062002",
    "district_id": "750506",
    "label": "Dumolodo",
    "value": "Dumolodo"
  },
  {
    "id": "7505062003",
    "district_id": "750506",
    "label": "Molonggota",
    "value": "Molonggota"
  },
  {
    "id": "7505062004",
    "district_id": "750506",
    "label": "Ipilo",
    "value": "Ipilo"
  },
  {
    "id": "7505062005",
    "district_id": "750506",
    "label": "Langke",
    "value": "Langke"
  },
  {
    "id": "7505062006",
    "district_id": "750506",
    "label": "Pasalae",
    "value": "Pasalae"
  },
  {
    "id": "7505062007",
    "district_id": "750506",
    "label": "Nanati Jaya",
    "value": "Nanati Jaya"
  },
  {
    "id": "7505062008",
    "district_id": "750506",
    "label": "Ketapang",
    "value": "Ketapang"
  },
  {
    "id": "7505062009",
    "district_id": "750506",
    "label": "Motomingo",
    "value": "Motomingo"
  },
  {
    "id": "7505062010",
    "district_id": "750506",
    "label": "Durian",
    "value": "Durian"
  },
  {
    "id": "7505062011",
    "district_id": "750506",
    "label": "Bohusami",
    "value": "Bohusami"
  },
  {
    "id": "7505072001",
    "district_id": "750507",
    "label": "Dambalo",
    "value": "Dambalo"
  },
  {
    "id": "7505072002",
    "district_id": "750507",
    "label": "Milango",
    "value": "Milango"
  },
  {
    "id": "7505072003",
    "district_id": "750507",
    "label": "Jembatan Merah",
    "value": "Jembatan Merah"
  },
  {
    "id": "7505072004",
    "district_id": "750507",
    "label": "Bubode",
    "value": "Bubode"
  },
  {
    "id": "7505072005",
    "district_id": "750507",
    "label": "Leyao",
    "value": "Leyao"
  },
  {
    "id": "7505072006",
    "district_id": "750507",
    "label": "Molantadu",
    "value": "Molantadu"
  },
  {
    "id": "7505072007",
    "district_id": "750507",
    "label": "Huidu Melito",
    "value": "Huidu Melito"
  },
  {
    "id": "7505072008",
    "district_id": "750507",
    "label": "Bulango Raya",
    "value": "Bulango Raya"
  },
  {
    "id": "7505072009",
    "district_id": "750507",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "7505072010",
    "district_id": "750507",
    "label": "Mutiara Laut",
    "value": "Mutiara Laut"
  },
  {
    "id": "7505082001",
    "district_id": "750508",
    "label": "Ponelo",
    "value": "Ponelo"
  },
  {
    "id": "7505082002",
    "district_id": "750508",
    "label": "Malambe",
    "value": "Malambe"
  },
  {
    "id": "7505082003",
    "district_id": "750508",
    "label": "Otiola",
    "value": "Otiola"
  },
  {
    "id": "7505082004",
    "district_id": "750508",
    "label": "Tihengo",
    "value": "Tihengo"
  },
  {
    "id": "7505092001",
    "district_id": "750509",
    "label": "Monano",
    "value": "Monano"
  },
  {
    "id": "7505092002",
    "district_id": "750509",
    "label": "Tudi",
    "value": "Tudi"
  },
  {
    "id": "7505092003",
    "district_id": "750509",
    "label": "Monas",
    "value": "Monas"
  },
  {
    "id": "7505092004",
    "district_id": "750509",
    "label": "Dunu",
    "value": "Dunu"
  },
  {
    "id": "7505092005",
    "district_id": "750509",
    "label": "Garapia",
    "value": "Garapia"
  },
  {
    "id": "7505092006",
    "district_id": "750509",
    "label": "Sogu",
    "value": "Sogu"
  },
  {
    "id": "7505092007",
    "district_id": "750509",
    "label": "Pilohulata",
    "value": "Pilohulata"
  },
  {
    "id": "7505092008",
    "district_id": "750509",
    "label": "Mokonowu",
    "value": "Mokonowu"
  },
  {
    "id": "7505092009",
    "district_id": "750509",
    "label": "Tolitehuyu",
    "value": "Tolitehuyu"
  },
  {
    "id": "7505092010",
    "district_id": "750509",
    "label": "Zuriyati",
    "value": "Zuriyati"
  },
  {
    "id": "7505102001",
    "district_id": "750510",
    "label": "Biau",
    "value": "Biau"
  },
  {
    "id": "7505102002",
    "district_id": "750510",
    "label": "Omuto",
    "value": "Omuto"
  },
  {
    "id": "7505102003",
    "district_id": "750510",
    "label": "Luhuto",
    "value": "Luhuto"
  },
  {
    "id": "7505102004",
    "district_id": "750510",
    "label": "Windu",
    "value": "Windu"
  },
  {
    "id": "7505102005",
    "district_id": "750510",
    "label": "Topi",
    "value": "Topi"
  },
  {
    "id": "7505102006",
    "district_id": "750510",
    "label": "Sembihinga",
    "value": "Sembihinga"
  },
  {
    "id": "7505102007",
    "district_id": "750510",
    "label": "Didingga",
    "value": "Didingga"
  },
  {
    "id": "7505102008",
    "district_id": "750510",
    "label": "Bualo",
    "value": "Bualo"
  },
  {
    "id": "7505102009",
    "district_id": "750510",
    "label": "Potanga",
    "value": "Potanga"
  },
  {
    "id": "7505102010",
    "district_id": "750510",
    "label": "Bohulo",
    "value": "Bohulo"
  },
  {
    "id": "7505112001",
    "district_id": "750511",
    "label": "Deme Satu",
    "value": "Deme Satu"
  },
  {
    "id": "7505112002",
    "district_id": "750511",
    "label": "Buluwatu",
    "value": "Buluwatu"
  },
  {
    "id": "7505112003",
    "district_id": "750511",
    "label": "Dulukapa",
    "value": "Dulukapa"
  },
  {
    "id": "7505112004",
    "district_id": "750511",
    "label": "Deme Dua",
    "value": "Deme Dua"
  },
  {
    "id": "7505112005",
    "district_id": "750511",
    "label": "Buladu",
    "value": "Buladu"
  },
  {
    "id": "7505112006",
    "district_id": "750511",
    "label": "Hulawa",
    "value": "Hulawa"
  },
  {
    "id": "7505112007",
    "district_id": "750511",
    "label": "Wubudu",
    "value": "Wubudu"
  },
  {
    "id": "7505112008",
    "district_id": "750511",
    "label": "Bubalango",
    "value": "Bubalango"
  },
  {
    "id": "7505112009",
    "district_id": "750511",
    "label": "Motiheluma",
    "value": "Motiheluma"
  },
  {
    "id": "7505112010",
    "district_id": "750511",
    "label": "Koluwoka",
    "value": "Koluwoka"
  },
  {
    "id": "7571011001",
    "district_id": "757101",
    "label": "Dembe I",
    "value": "Dembe I"
  },
  {
    "id": "7571011002",
    "district_id": "757101",
    "label": "Lekobalo",
    "value": "Lekobalo"
  },
  {
    "id": "7571011003",
    "district_id": "757101",
    "label": "Pilolodaa",
    "value": "Pilolodaa"
  },
  {
    "id": "7571011004",
    "district_id": "757101",
    "label": "Buliide",
    "value": "Buliide"
  },
  {
    "id": "7571011005",
    "district_id": "757101",
    "label": "Tenilo",
    "value": "Tenilo"
  },
  {
    "id": "7571011006",
    "district_id": "757101",
    "label": "Molosipat W",
    "value": "Molosipat W"
  },
  {
    "id": "7571011008",
    "district_id": "757101",
    "label": "Buladu",
    "value": "Buladu"
  },
  {
    "id": "7571021010",
    "district_id": "757102",
    "label": "Biawao",
    "value": "Biawao"
  },
  {
    "id": "7571021012",
    "district_id": "757102",
    "label": "Biawu",
    "value": "Biawu"
  },
  {
    "id": "7571021013",
    "district_id": "757102",
    "label": "Limba B",
    "value": "Limba B"
  },
  {
    "id": "7571021019",
    "district_id": "757102",
    "label": "Limba U I",
    "value": "Limba U I"
  },
  {
    "id": "7571021020",
    "district_id": "757102",
    "label": "Limba U II",
    "value": "Limba U II"
  },
  {
    "id": "7571031003",
    "district_id": "757103",
    "label": "Dembe II",
    "value": "Dembe II"
  },
  {
    "id": "7571031004",
    "district_id": "757103",
    "label": "Wongkaditi Timur",
    "value": "Wongkaditi Timur"
  },
  {
    "id": "7571031005",
    "district_id": "757103",
    "label": "Wongkaditi Barat",
    "value": "Wongkaditi Barat"
  },
  {
    "id": "7571031010",
    "district_id": "757103",
    "label": "Dulomo Selatan",
    "value": "Dulomo Selatan"
  },
  {
    "id": "7571031014",
    "district_id": "757103",
    "label": "Dulomo Utara",
    "value": "Dulomo Utara"
  },
  {
    "id": "7571031015",
    "district_id": "757103",
    "label": "Dembe Jaya",
    "value": "Dembe Jaya"
  },
  {
    "id": "7571041001",
    "district_id": "757104",
    "label": "Huangobotu",
    "value": "Huangobotu"
  },
  {
    "id": "7571041002",
    "district_id": "757104",
    "label": "Libuo",
    "value": "Libuo"
  },
  {
    "id": "7571041003",
    "district_id": "757104",
    "label": "Tomulabutao",
    "value": "Tomulabutao"
  },
  {
    "id": "7571041004",
    "district_id": "757104",
    "label": "Tuladengi",
    "value": "Tuladengi"
  },
  {
    "id": "7571041005",
    "district_id": "757104",
    "label": "Tomulabutao Selatan",
    "value": "Tomulabutao Selatan"
  },
  {
    "id": "7571051003",
    "district_id": "757105",
    "label": "Heledulaa Utara",
    "value": "Heledulaa Utara"
  },
  {
    "id": "7571051004",
    "district_id": "757105",
    "label": "Heledulaa Selatan",
    "value": "Heledulaa Selatan"
  },
  {
    "id": "7571051005",
    "district_id": "757105",
    "label": "Ipilo",
    "value": "Ipilo"
  },
  {
    "id": "7571051008",
    "district_id": "757105",
    "label": "Moodu",
    "value": "Moodu"
  },
  {
    "id": "7571051009",
    "district_id": "757105",
    "label": "Padebuolo",
    "value": "Padebuolo"
  },
  {
    "id": "7571051011",
    "district_id": "757105",
    "label": "Tamalate",
    "value": "Tamalate"
  },
  {
    "id": "7571061001",
    "district_id": "757106",
    "label": "Wumialo",
    "value": "Wumialo"
  },
  {
    "id": "7571061002",
    "district_id": "757106",
    "label": "Dulalowo",
    "value": "Dulalowo"
  },
  {
    "id": "7571061003",
    "district_id": "757106",
    "label": "Liluwo",
    "value": "Liluwo"
  },
  {
    "id": "7571061004",
    "district_id": "757106",
    "label": "Pulubala",
    "value": "Pulubala"
  },
  {
    "id": "7571061005",
    "district_id": "757106",
    "label": "Paguyaman",
    "value": "Paguyaman"
  },
  {
    "id": "7571061006",
    "district_id": "757106",
    "label": "Dulalowo Timur",
    "value": "Dulalowo Timur"
  },
  {
    "id": "7571071001",
    "district_id": "757107",
    "label": "Tanggikiki",
    "value": "Tanggikiki"
  },
  {
    "id": "7571071002",
    "district_id": "757107",
    "label": "Tapa",
    "value": "Tapa"
  },
  {
    "id": "7571071003",
    "district_id": "757107",
    "label": "Bulotadaa Barat",
    "value": "Bulotadaa Barat"
  },
  {
    "id": "7571071004",
    "district_id": "757107",
    "label": "Bulotadaa Timur",
    "value": "Bulotadaa Timur"
  },
  {
    "id": "7571071005",
    "district_id": "757107",
    "label": "Molosifat U",
    "value": "Molosifat U"
  },
  {
    "id": "7571081001",
    "district_id": "757108",
    "label": "Leato Utara",
    "value": "Leato Utara"
  },
  {
    "id": "7571081002",
    "district_id": "757108",
    "label": "Leato Selatan",
    "value": "Leato Selatan"
  },
  {
    "id": "7571081003",
    "district_id": "757108",
    "label": "Talumolo",
    "value": "Talumolo"
  },
  {
    "id": "7571081004",
    "district_id": "757108",
    "label": "Botu",
    "value": "Botu"
  },
  {
    "id": "7571081005",
    "district_id": "757108",
    "label": "Bugis",
    "value": "Bugis"
  },
  {
    "id": "7571091001",
    "district_id": "757109",
    "label": "Tanjung Kramat",
    "value": "Tanjung Kramat"
  },
  {
    "id": "7571091002",
    "district_id": "757109",
    "label": "Pohe",
    "value": "Pohe"
  },
  {
    "id": "7571091003",
    "district_id": "757109",
    "label": "Tenda",
    "value": "Tenda"
  },
  {
    "id": "7571091004",
    "district_id": "757109",
    "label": "Donggala",
    "value": "Donggala"
  },
  {
    "id": "7571091005",
    "district_id": "757109",
    "label": "Siendeng",
    "value": "Siendeng"
  },
  {
    "id": "7601011006",
    "district_id": "760101",
    "label": "Bambalamotu",
    "value": "Bambalamotu"
  },
  {
    "id": "7601012003",
    "district_id": "760101",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7601012004",
    "district_id": "760101",
    "label": "Randomayang",
    "value": "Randomayang"
  },
  {
    "id": "7601012007",
    "district_id": "760101",
    "label": "Pangiang",
    "value": "Pangiang"
  },
  {
    "id": "7601012008",
    "district_id": "760101",
    "label": "Kalola",
    "value": "Kalola"
  },
  {
    "id": "7601012009",
    "district_id": "760101",
    "label": "Wulai",
    "value": "Wulai"
  },
  {
    "id": "7601021001",
    "district_id": "760102",
    "label": "Pasangkayu",
    "value": "Pasangkayu"
  },
  {
    "id": "7601021002",
    "district_id": "760102",
    "label": "Martajaya",
    "value": "Martajaya"
  },
  {
    "id": "7601022010",
    "district_id": "760102",
    "label": "Pakava",
    "value": "Pakava"
  },
  {
    "id": "7601022014",
    "district_id": "760102",
    "label": "Karya Bersama",
    "value": "Karya Bersama"
  },
  {
    "id": "7601022015",
    "district_id": "760102",
    "label": "Ako",
    "value": "Ako"
  },
  {
    "id": "7601022016",
    "district_id": "760102",
    "label": "Gunung Sari",
    "value": "Gunung Sari"
  },
  {
    "id": "7601031001",
    "district_id": "760103",
    "label": "Baras",
    "value": "Baras"
  },
  {
    "id": "7601032002",
    "district_id": "760103",
    "label": "Balanti",
    "value": "Balanti"
  },
  {
    "id": "7601032003",
    "district_id": "760103",
    "label": "Motu",
    "value": "Motu"
  },
  {
    "id": "7601032012",
    "district_id": "760103",
    "label": "Bulu Parigi",
    "value": "Bulu Parigi"
  },
  {
    "id": "7601032013",
    "district_id": "760103",
    "label": "Towoni",
    "value": "Towoni"
  },
  {
    "id": "7601032016",
    "district_id": "760103",
    "label": "Kasano",
    "value": "Kasano"
  },
  {
    "id": "7601042001",
    "district_id": "760104",
    "label": "Sarudu",
    "value": "Sarudu"
  },
  {
    "id": "7601042003",
    "district_id": "760104",
    "label": "Bulu Mario",
    "value": "Bulu Mario"
  },
  {
    "id": "7601042005",
    "district_id": "760104",
    "label": "Kumasari",
    "value": "Kumasari"
  },
  {
    "id": "7601042011",
    "district_id": "760104",
    "label": "Patika",
    "value": "Patika"
  },
  {
    "id": "7601042012",
    "district_id": "760104",
    "label": "Doda",
    "value": "Doda"
  },
  {
    "id": "7601052001",
    "district_id": "760105",
    "label": "Dapurang",
    "value": "Dapurang"
  },
  {
    "id": "7601052002",
    "district_id": "760105",
    "label": "Benggaulu",
    "value": "Benggaulu"
  },
  {
    "id": "7601052003",
    "district_id": "760105",
    "label": "Tirtabuana",
    "value": "Tirtabuana"
  },
  {
    "id": "7601052004",
    "district_id": "760105",
    "label": "Bulu Bonggu",
    "value": "Bulu Bonggu"
  },
  {
    "id": "7601052005",
    "district_id": "760105",
    "label": "Sarasa",
    "value": "Sarasa"
  },
  {
    "id": "7601062001",
    "district_id": "760106",
    "label": "Tammarunang",
    "value": "Tammarunang"
  },
  {
    "id": "7601062002",
    "district_id": "760106",
    "label": "Saptanajaya",
    "value": "Saptanajaya"
  },
  {
    "id": "7601062003",
    "district_id": "760106",
    "label": "Taranggi",
    "value": "Taranggi"
  },
  {
    "id": "7601062004",
    "district_id": "760106",
    "label": "Sipakainga",
    "value": "Sipakainga"
  },
  {
    "id": "7601072001",
    "district_id": "760107",
    "label": "Lilimori",
    "value": "Lilimori"
  },
  {
    "id": "7601072002",
    "district_id": "760107",
    "label": "Karave",
    "value": "Karave"
  },
  {
    "id": "7601072003",
    "district_id": "760107",
    "label": "Bukit Harapan",
    "value": "Bukit Harapan"
  },
  {
    "id": "7601072004",
    "district_id": "760107",
    "label": "Kastabuana",
    "value": "Kastabuana"
  },
  {
    "id": "7601072005",
    "district_id": "760107",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "7601072006",
    "district_id": "760107",
    "label": "Lelejae",
    "value": "Lelejae"
  },
  {
    "id": "7601072007",
    "district_id": "760107",
    "label": "Ompi",
    "value": "Ompi"
  },
  {
    "id": "7601082001",
    "district_id": "760108",
    "label": "Tikke",
    "value": "Tikke"
  },
  {
    "id": "7601082002",
    "district_id": "760108",
    "label": "Makmur Jaya",
    "value": "Makmur Jaya"
  },
  {
    "id": "7601082003",
    "district_id": "760108",
    "label": "Lariang",
    "value": "Lariang"
  },
  {
    "id": "7601082004",
    "district_id": "760108",
    "label": "Pajalele",
    "value": "Pajalele"
  },
  {
    "id": "7601082005",
    "district_id": "760108",
    "label": "Jengeng Raya",
    "value": "Jengeng Raya"
  },
  {
    "id": "7601092001",
    "district_id": "760109",
    "label": "Malei",
    "value": "Malei"
  },
  {
    "id": "7601092002",
    "district_id": "760109",
    "label": "Pedanda",
    "value": "Pedanda"
  },
  {
    "id": "7601092003",
    "district_id": "760109",
    "label": "Batu Oge",
    "value": "Batu Oge"
  },
  {
    "id": "7601092004",
    "district_id": "760109",
    "label": "Martasari",
    "value": "Martasari"
  },
  {
    "id": "7601102001",
    "district_id": "760110",
    "label": "Bambaira",
    "value": "Bambaira"
  },
  {
    "id": "7601102002",
    "district_id": "760110",
    "label": "Kasoloang",
    "value": "Kasoloang"
  },
  {
    "id": "7601102003",
    "district_id": "760110",
    "label": "Tampaure",
    "value": "Tampaure"
  },
  {
    "id": "7601102004",
    "district_id": "760110",
    "label": "Kaluku Nangka",
    "value": "Kaluku Nangka"
  },
  {
    "id": "7601112001",
    "district_id": "760111",
    "label": "Sarjo",
    "value": "Sarjo"
  },
  {
    "id": "7601112002",
    "district_id": "760111",
    "label": "Maponu",
    "value": "Maponu"
  },
  {
    "id": "7601112003",
    "district_id": "760111",
    "label": "Letawa",
    "value": "Letawa"
  },
  {
    "id": "7601112004",
    "district_id": "760111",
    "label": "Sarude",
    "value": "Sarude"
  },
  {
    "id": "7601122001",
    "district_id": "760112",
    "label": "Parabu",
    "value": "Parabu"
  },
  {
    "id": "7601122002",
    "district_id": "760112",
    "label": "Kenangan",
    "value": "Kenangan"
  },
  {
    "id": "7601122003",
    "district_id": "760112",
    "label": "Bajawali",
    "value": "Bajawali"
  },
  {
    "id": "7601122004",
    "district_id": "760112",
    "label": "Singgani",
    "value": "Singgani"
  },
  {
    "id": "7601122005",
    "district_id": "760112",
    "label": "Kulu",
    "value": "Kulu"
  },
  {
    "id": "7601122006",
    "district_id": "760112",
    "label": "Batu Matoru",
    "value": "Batu Matoru"
  },
  {
    "id": "7601122007",
    "district_id": "760112",
    "label": "Bambakoro",
    "value": "Bambakoro"
  },
  {
    "id": "7602011002",
    "district_id": "760201",
    "label": "Binanga",
    "value": "Binanga"
  },
  {
    "id": "7602011003",
    "district_id": "760201",
    "label": "Mamunyu",
    "value": "Mamunyu"
  },
  {
    "id": "7602011012",
    "district_id": "760201",
    "label": "Karema",
    "value": "Karema"
  },
  {
    "id": "7602011013",
    "district_id": "760201",
    "label": "Rimuku",
    "value": "Rimuku"
  },
  {
    "id": "7602012005",
    "district_id": "760201",
    "label": "Bambu",
    "value": "Bambu"
  },
  {
    "id": "7602012006",
    "district_id": "760201",
    "label": "Karampuang",
    "value": "Karampuang"
  },
  {
    "id": "7602012010",
    "district_id": "760201",
    "label": "Tadui",
    "value": "Tadui"
  },
  {
    "id": "7602012014",
    "district_id": "760201",
    "label": "Batu Pannu",
    "value": "Batu Pannu"
  },
  {
    "id": "7602021001",
    "district_id": "760202",
    "label": "Galung",
    "value": "Galung"
  },
  {
    "id": "7602021002",
    "district_id": "760202",
    "label": "Kasambang",
    "value": "Kasambang"
  },
  {
    "id": "7602021014",
    "district_id": "760202",
    "label": "Dayanginna",
    "value": "Dayanginna"
  },
  {
    "id": "7602022003",
    "district_id": "760202",
    "label": "Orobatu",
    "value": "Orobatu"
  },
  {
    "id": "7602022006",
    "district_id": "760202",
    "label": "Takandeang",
    "value": "Takandeang"
  },
  {
    "id": "7602022009",
    "district_id": "760202",
    "label": "Bela",
    "value": "Bela"
  },
  {
    "id": "7602022010",
    "district_id": "760202",
    "label": "Taan",
    "value": "Taan"
  },
  {
    "id": "7602022011",
    "district_id": "760202",
    "label": "Tampalang",
    "value": "Tampalang"
  },
  {
    "id": "7602022012",
    "district_id": "760202",
    "label": "Rantedoda",
    "value": "Rantedoda"
  },
  {
    "id": "7602022013",
    "district_id": "760202",
    "label": "Kopeang",
    "value": "Kopeang"
  },
  {
    "id": "7602031001",
    "district_id": "760203",
    "label": "Sinyonyoi",
    "value": "Sinyonyoi"
  },
  {
    "id": "7602031002",
    "district_id": "760203",
    "label": "Bebanga",
    "value": "Bebanga"
  },
  {
    "id": "7602031003",
    "district_id": "760203",
    "label": "Kalukku",
    "value": "Kalukku"
  },
  {
    "id": "7602031014",
    "district_id": "760203",
    "label": "Sinyonyoi Selatan",
    "value": "Sinyonyoi Selatan"
  },
  {
    "id": "7602032004",
    "district_id": "760203",
    "label": "Sondoang",
    "value": "Sondoang"
  },
  {
    "id": "7602032005",
    "district_id": "760203",
    "label": "Beru-Beru",
    "value": "Beru-Beru"
  },
  {
    "id": "7602032006",
    "district_id": "760203",
    "label": "Belang Belang",
    "value": "Belang Belang"
  },
  {
    "id": "7602032007",
    "district_id": "760203",
    "label": "Kabuloang",
    "value": "Kabuloang"
  },
  {
    "id": "7602032008",
    "district_id": "760203",
    "label": "Keang",
    "value": "Keang"
  },
  {
    "id": "7602032009",
    "district_id": "760203",
    "label": "Pokkang",
    "value": "Pokkang"
  },
  {
    "id": "7602032010",
    "district_id": "760203",
    "label": "Guliling",
    "value": "Guliling"
  },
  {
    "id": "7602032011",
    "district_id": "760203",
    "label": "Uhaimate",
    "value": "Uhaimate"
  },
  {
    "id": "7602032012",
    "district_id": "760203",
    "label": "Kalukku Barat",
    "value": "Kalukku Barat"
  },
  {
    "id": "7602032013",
    "district_id": "760203",
    "label": "Pammulukang",
    "value": "Pammulukang"
  },
  {
    "id": "7602042001",
    "district_id": "760204",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "7602042003",
    "district_id": "760204",
    "label": "Karataun",
    "value": "Karataun"
  },
  {
    "id": "7602042004",
    "district_id": "760204",
    "label": "Karama",
    "value": "Karama"
  },
  {
    "id": "7602042006",
    "district_id": "760204",
    "label": "Siraun",
    "value": "Siraun"
  },
  {
    "id": "7602042007",
    "district_id": "760204",
    "label": "Tumoga",
    "value": "Tumoga"
  },
  {
    "id": "7602042010",
    "district_id": "760204",
    "label": "Salumakki",
    "value": "Salumakki"
  },
  {
    "id": "7602042011",
    "district_id": "760204",
    "label": "Polio",
    "value": "Polio"
  },
  {
    "id": "7602042012",
    "district_id": "760204",
    "label": "Limbong",
    "value": "Limbong"
  },
  {
    "id": "7602042013",
    "district_id": "760204",
    "label": "Sandapang",
    "value": "Sandapang"
  },
  {
    "id": "7602042014",
    "district_id": "760204",
    "label": "Kondo Bulo",
    "value": "Kondo Bulo"
  },
  {
    "id": "7602042015",
    "district_id": "760204",
    "label": "Makkaliki",
    "value": "Makkaliki"
  },
  {
    "id": "7602042016",
    "district_id": "760204",
    "label": "Batu Makkada",
    "value": "Batu Makkada"
  },
  {
    "id": "7602042017",
    "district_id": "760204",
    "label": "Lasa'",
    "value": "Lasa'"
  },
  {
    "id": "7602072001",
    "district_id": "760207",
    "label": "Toabo",
    "value": "Toabo"
  },
  {
    "id": "7602072002",
    "district_id": "760207",
    "label": "Papalang",
    "value": "Papalang"
  },
  {
    "id": "7602072003",
    "district_id": "760207",
    "label": "Bonda",
    "value": "Bonda"
  },
  {
    "id": "7602072004",
    "district_id": "760207",
    "label": "Topore",
    "value": "Topore"
  },
  {
    "id": "7602072005",
    "district_id": "760207",
    "label": "Salukayu",
    "value": "Salukayu"
  },
  {
    "id": "7602072006",
    "district_id": "760207",
    "label": "Sukadamai",
    "value": "Sukadamai"
  },
  {
    "id": "7602072007",
    "district_id": "760207",
    "label": "Boda-Boda",
    "value": "Boda-Boda"
  },
  {
    "id": "7602072008",
    "district_id": "760207",
    "label": "Sisango",
    "value": "Sisango"
  },
  {
    "id": "7602072009",
    "district_id": "760207",
    "label": "Batu Ampa",
    "value": "Batu Ampa"
  },
  {
    "id": "7602082001",
    "district_id": "760208",
    "label": "Bunde",
    "value": "Bunde"
  },
  {
    "id": "7602082002",
    "district_id": "760208",
    "label": "Kalonding",
    "value": "Kalonding"
  },
  {
    "id": "7602082003",
    "district_id": "760208",
    "label": "Tarailu",
    "value": "Tarailu"
  },
  {
    "id": "7602082004",
    "district_id": "760208",
    "label": "Sampaga",
    "value": "Sampaga"
  },
  {
    "id": "7602082005",
    "district_id": "760208",
    "label": "Tanambuah",
    "value": "Tanambuah"
  },
  {
    "id": "7602082006",
    "district_id": "760208",
    "label": "Salubarana",
    "value": "Salubarana"
  },
  {
    "id": "7602082007",
    "district_id": "760208",
    "label": "Losso",
    "value": "Losso"
  },
  {
    "id": "7602112001",
    "district_id": "760211",
    "label": "Tommo",
    "value": "Tommo"
  },
  {
    "id": "7602112002",
    "district_id": "760211",
    "label": "Campaloga",
    "value": "Campaloga"
  },
  {
    "id": "7602112003",
    "district_id": "760211",
    "label": "Buana Sakti",
    "value": "Buana Sakti"
  },
  {
    "id": "7602112004",
    "district_id": "760211",
    "label": "Tamemongga",
    "value": "Tamemongga"
  },
  {
    "id": "7602112005",
    "district_id": "760211",
    "label": "Tamejarra",
    "value": "Tamejarra"
  },
  {
    "id": "7602112006",
    "district_id": "760211",
    "label": "Rantemario",
    "value": "Rantemario"
  },
  {
    "id": "7602112007",
    "district_id": "760211",
    "label": "Malino",
    "value": "Malino"
  },
  {
    "id": "7602112008",
    "district_id": "760211",
    "label": "Kakullasang",
    "value": "Kakullasang"
  },
  {
    "id": "7602112009",
    "district_id": "760211",
    "label": "Leling",
    "value": "Leling"
  },
  {
    "id": "7602112010",
    "district_id": "760211",
    "label": "Kalepu",
    "value": "Kalepu"
  },
  {
    "id": "7602112011",
    "district_id": "760211",
    "label": "Sandana",
    "value": "Sandana"
  },
  {
    "id": "7602112012",
    "district_id": "760211",
    "label": "Leling Barat",
    "value": "Leling Barat"
  },
  {
    "id": "7602112013",
    "district_id": "760211",
    "label": "Leling Utara",
    "value": "Leling Utara"
  },
  {
    "id": "7602112014",
    "district_id": "760211",
    "label": "Saludengen",
    "value": "Saludengen"
  },
  {
    "id": "7602121001",
    "district_id": "760212",
    "label": "Simboro",
    "value": "Simboro"
  },
  {
    "id": "7602121002",
    "district_id": "760212",
    "label": "Rangas",
    "value": "Rangas"
  },
  {
    "id": "7602122003",
    "district_id": "760212",
    "label": "Botteng",
    "value": "Botteng"
  },
  {
    "id": "7602122004",
    "district_id": "760212",
    "label": "Saletto",
    "value": "Saletto"
  },
  {
    "id": "7602122005",
    "district_id": "760212",
    "label": "Sumare",
    "value": "Sumare"
  },
  {
    "id": "7602122007",
    "district_id": "760212",
    "label": "Botteng Utara",
    "value": "Botteng Utara"
  },
  {
    "id": "7602122008",
    "district_id": "760212",
    "label": "Tapandullu",
    "value": "Tapandullu"
  },
  {
    "id": "7602122009",
    "district_id": "760212",
    "label": "Pati'di",
    "value": "Pati'di"
  },
  {
    "id": "7602132001",
    "district_id": "760213",
    "label": "Dungkait",
    "value": "Dungkait"
  },
  {
    "id": "7602132002",
    "district_id": "760213",
    "label": "Lebani",
    "value": "Lebani"
  },
  {
    "id": "7602132003",
    "district_id": "760213",
    "label": "Labuang Rano",
    "value": "Labuang Rano"
  },
  {
    "id": "7602132004",
    "district_id": "760213",
    "label": "Pasabu",
    "value": "Pasabu"
  },
  {
    "id": "7602132005",
    "district_id": "760213",
    "label": "Tanete Pao",
    "value": "Tanete Pao"
  },
  {
    "id": "7602132006",
    "district_id": "760213",
    "label": "Pangasaan",
    "value": "Pangasaan"
  },
  {
    "id": "7602132007",
    "district_id": "760213",
    "label": "Ahu",
    "value": "Ahu"
  },
  {
    "id": "7602152001",
    "district_id": "760215",
    "label": "Bonehau",
    "value": "Bonehau"
  },
  {
    "id": "7602152002",
    "district_id": "760215",
    "label": "Salutiwo",
    "value": "Salutiwo"
  },
  {
    "id": "7602152003",
    "district_id": "760215",
    "label": "Buttuada",
    "value": "Buttuada"
  },
  {
    "id": "7602152004",
    "district_id": "760215",
    "label": "Tamalea",
    "value": "Tamalea"
  },
  {
    "id": "7602152005",
    "district_id": "760215",
    "label": "Lumika",
    "value": "Lumika"
  },
  {
    "id": "7602152006",
    "district_id": "760215",
    "label": "Mappu",
    "value": "Mappu"
  },
  {
    "id": "7602152009",
    "district_id": "760215",
    "label": "Banuada",
    "value": "Banuada"
  },
  {
    "id": "7602152010",
    "district_id": "760215",
    "label": "Hinua",
    "value": "Hinua"
  },
  {
    "id": "7602152011",
    "district_id": "760215",
    "label": "Kinatang",
    "value": "Kinatang"
  },
  {
    "id": "7602162001",
    "district_id": "760216",
    "label": "Bala Balakang",
    "value": "Bala Balakang"
  },
  {
    "id": "7602162002",
    "district_id": "760216",
    "label": "Bala-Balakang Timur",
    "value": "Bala-Balakang Timur"
  },
  {
    "id": "7603011001",
    "district_id": "760301",
    "label": "Mambi",
    "value": "Mambi"
  },
  {
    "id": "7603011002",
    "district_id": "760301",
    "label": "Talipukki",
    "value": "Talipukki"
  },
  {
    "id": "7603012004",
    "district_id": "760301",
    "label": "Rantebulahan",
    "value": "Rantebulahan"
  },
  {
    "id": "7603012008",
    "district_id": "760301",
    "label": "Sondonglayu",
    "value": "Sondonglayu"
  },
  {
    "id": "7603012016",
    "district_id": "760301",
    "label": "Salubanua",
    "value": "Salubanua"
  },
  {
    "id": "7603012017",
    "district_id": "760301",
    "label": "Tapalina",
    "value": "Tapalina"
  },
  {
    "id": "7603012019",
    "district_id": "760301",
    "label": "Sendana",
    "value": "Sendana"
  },
  {
    "id": "7603012024",
    "district_id": "760301",
    "label": "Pamosean",
    "value": "Pamosean"
  },
  {
    "id": "7603012025",
    "district_id": "760301",
    "label": "Selumaka",
    "value": "Selumaka"
  },
  {
    "id": "7603012026",
    "district_id": "760301",
    "label": "Bujung Manurung",
    "value": "Bujung Manurung"
  },
  {
    "id": "7603012027",
    "district_id": "760301",
    "label": "Salu Alo",
    "value": "Salu Alo"
  },
  {
    "id": "7603012029",
    "district_id": "760301",
    "label": "Indobanua",
    "value": "Indobanua"
  },
  {
    "id": "7603012030",
    "district_id": "760301",
    "label": "Saludurian",
    "value": "Saludurian"
  },
  {
    "id": "7603021001",
    "district_id": "760302",
    "label": "Aralle",
    "value": "Aralle"
  },
  {
    "id": "7603022003",
    "district_id": "760302",
    "label": "Ralle Anak",
    "value": "Ralle Anak"
  },
  {
    "id": "7603022004",
    "district_id": "760302",
    "label": "Panetean",
    "value": "Panetean"
  },
  {
    "id": "7603022005",
    "district_id": "760302",
    "label": "Uhailanu",
    "value": "Uhailanu"
  },
  {
    "id": "7603022007",
    "district_id": "760302",
    "label": "Aralle Utara",
    "value": "Aralle Utara"
  },
  {
    "id": "7603022009",
    "district_id": "760302",
    "label": "Uhaidaho",
    "value": "Uhaidaho"
  },
  {
    "id": "7603022010",
    "district_id": "760302",
    "label": "Baruru",
    "value": "Baruru"
  },
  {
    "id": "7603022015",
    "district_id": "760302",
    "label": "Ralleanak Utara",
    "value": "Ralleanak Utara"
  },
  {
    "id": "7603022016",
    "district_id": "760302",
    "label": "Pamoseang Pangga",
    "value": "Pamoseang Pangga"
  },
  {
    "id": "7603022017",
    "district_id": "760302",
    "label": "Kalabe",
    "value": "Kalabe"
  },
  {
    "id": "7603022018",
    "district_id": "760302",
    "label": "Aralle Selatan",
    "value": "Aralle Selatan"
  },
  {
    "id": "7603022019",
    "district_id": "760302",
    "label": "Hahangan",
    "value": "Hahangan"
  },
  {
    "id": "7603031001",
    "district_id": "760303",
    "label": "Mamasa",
    "value": "Mamasa"
  },
  {
    "id": "7603032002",
    "district_id": "760303",
    "label": "Rambusaratu",
    "value": "Rambusaratu"
  },
  {
    "id": "7603032003",
    "district_id": "760303",
    "label": "Osango",
    "value": "Osango"
  },
  {
    "id": "7603032004",
    "district_id": "760303",
    "label": "Lambanan",
    "value": "Lambanan"
  },
  {
    "id": "7603032005",
    "district_id": "760303",
    "label": "Lembana Salulo",
    "value": "Lembana Salulo"
  },
  {
    "id": "7603032006",
    "district_id": "760303",
    "label": "Taupe",
    "value": "Taupe"
  },
  {
    "id": "7603032007",
    "district_id": "760303",
    "label": "Buntu Buda",
    "value": "Buntu Buda"
  },
  {
    "id": "7603032008",
    "district_id": "760303",
    "label": "Tondok Bakaru",
    "value": "Tondok Bakaru"
  },
  {
    "id": "7603032009",
    "district_id": "760303",
    "label": "Pebassian",
    "value": "Pebassian"
  },
  {
    "id": "7603032010",
    "district_id": "760303",
    "label": "Mambuliling",
    "value": "Mambuliling"
  },
  {
    "id": "7603032011",
    "district_id": "760303",
    "label": "Bombong Lambe",
    "value": "Bombong Lambe"
  },
  {
    "id": "7603032012",
    "district_id": "760303",
    "label": "Bubun Batu",
    "value": "Bubun Batu"
  },
  {
    "id": "7603041001",
    "district_id": "760304",
    "label": "Pana",
    "value": "Pana"
  },
  {
    "id": "7603042003",
    "district_id": "760304",
    "label": "Manipi",
    "value": "Manipi"
  },
  {
    "id": "7603042005",
    "district_id": "760304",
    "label": "Mamullu",
    "value": "Mamullu"
  },
  {
    "id": "7603042006",
    "district_id": "760304",
    "label": "Sapan",
    "value": "Sapan"
  },
  {
    "id": "7603042009",
    "district_id": "760304",
    "label": "Datubaringan",
    "value": "Datubaringan"
  },
  {
    "id": "7603042010",
    "district_id": "760304",
    "label": "Panura",
    "value": "Panura"
  },
  {
    "id": "7603042011",
    "district_id": "760304",
    "label": "Tallang Bulawan",
    "value": "Tallang Bulawan"
  },
  {
    "id": "7603042012",
    "district_id": "760304",
    "label": "Karaka",
    "value": "Karaka"
  },
  {
    "id": "7603042013",
    "district_id": "760304",
    "label": "Ulusalu",
    "value": "Ulusalu"
  },
  {
    "id": "7603042014",
    "district_id": "760304",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "7603042015",
    "district_id": "760304",
    "label": "Salutambun",
    "value": "Salutambun"
  },
  {
    "id": "7603042016",
    "district_id": "760304",
    "label": "Saloan",
    "value": "Saloan"
  },
  {
    "id": "7603042017",
    "district_id": "760304",
    "label": "Ulusalu Indah",
    "value": "Ulusalu Indah"
  },
  {
    "id": "7603051001",
    "district_id": "760305",
    "label": "Lakahang",
    "value": "Lakahang"
  },
  {
    "id": "7603052002",
    "district_id": "760305",
    "label": "Periangan",
    "value": "Periangan"
  },
  {
    "id": "7603052003",
    "district_id": "760305",
    "label": "Malatiro",
    "value": "Malatiro"
  },
  {
    "id": "7603052004",
    "district_id": "760305",
    "label": "Tabulahan",
    "value": "Tabulahan"
  },
  {
    "id": "7603052005",
    "district_id": "760305",
    "label": "Saluleang",
    "value": "Saluleang"
  },
  {
    "id": "7603052007",
    "district_id": "760305",
    "label": "Tampak Kurra",
    "value": "Tampak Kurra"
  },
  {
    "id": "7603052008",
    "district_id": "760305",
    "label": "Gandang Dewata",
    "value": "Gandang Dewata"
  },
  {
    "id": "7603052009",
    "district_id": "760305",
    "label": "Burana",
    "value": "Burana"
  },
  {
    "id": "7603052010",
    "district_id": "760305",
    "label": "Salubakka",
    "value": "Salubakka"
  },
  {
    "id": "7603052011",
    "district_id": "760305",
    "label": "Pangandaran",
    "value": "Pangandaran"
  },
  {
    "id": "7603052012",
    "district_id": "760305",
    "label": "Talopak",
    "value": "Talopak"
  },
  {
    "id": "7603052013",
    "district_id": "760305",
    "label": "Peu'",
    "value": "Peu'"
  },
  {
    "id": "7603052014",
    "district_id": "760305",
    "label": "Timoro",
    "value": "Timoro"
  },
  {
    "id": "7603052015",
    "district_id": "760305",
    "label": "Lakahang Utama",
    "value": "Lakahang Utama"
  },
  {
    "id": "7603061001",
    "district_id": "760306",
    "label": "Sumarorong",
    "value": "Sumarorong"
  },
  {
    "id": "7603061002",
    "district_id": "760306",
    "label": "Tabone",
    "value": "Tabone"
  },
  {
    "id": "7603062003",
    "district_id": "760306",
    "label": "Batang Uru",
    "value": "Batang Uru"
  },
  {
    "id": "7603062004",
    "district_id": "760306",
    "label": "Sasakan",
    "value": "Sasakan"
  },
  {
    "id": "7603062005",
    "district_id": "760306",
    "label": "Tadisi",
    "value": "Tadisi"
  },
  {
    "id": "7603062006",
    "district_id": "760306",
    "label": "Sibanawa",
    "value": "Sibanawa"
  },
  {
    "id": "7603062007",
    "district_id": "760306",
    "label": "Batangnguru Timur",
    "value": "Batangnguru Timur"
  },
  {
    "id": "7603062008",
    "district_id": "760306",
    "label": "Salubalo",
    "value": "Salubalo"
  },
  {
    "id": "7603062009",
    "district_id": "760306",
    "label": "Banea",
    "value": "Banea"
  },
  {
    "id": "7603062010",
    "district_id": "760306",
    "label": "Rante Kamase",
    "value": "Rante Kamase"
  },
  {
    "id": "7603071001",
    "district_id": "760307",
    "label": "Messawa",
    "value": "Messawa"
  },
  {
    "id": "7603072002",
    "district_id": "760307",
    "label": "Rippung",
    "value": "Rippung"
  },
  {
    "id": "7603072003",
    "district_id": "760307",
    "label": "Makuang",
    "value": "Makuang"
  },
  {
    "id": "7603072004",
    "district_id": "760307",
    "label": "Sepang",
    "value": "Sepang"
  },
  {
    "id": "7603072005",
    "district_id": "760307",
    "label": "Malimbong",
    "value": "Malimbong"
  },
  {
    "id": "7603072006",
    "district_id": "760307",
    "label": "Matande",
    "value": "Matande"
  },
  {
    "id": "7603072007",
    "district_id": "760307",
    "label": "Sipai",
    "value": "Sipai"
  },
  {
    "id": "7603072008",
    "district_id": "760307",
    "label": "Pasma Mambu",
    "value": "Pasma Mambu"
  },
  {
    "id": "7603072009",
    "district_id": "760307",
    "label": "Tanete Batu",
    "value": "Tanete Batu"
  },
  {
    "id": "7603082002",
    "district_id": "760308",
    "label": "Orobua",
    "value": "Orobua"
  },
  {
    "id": "7603082003",
    "district_id": "760308",
    "label": "Paladan",
    "value": "Paladan"
  },
  {
    "id": "7603082004",
    "district_id": "760308",
    "label": "Orobua Timur",
    "value": "Orobua Timur"
  },
  {
    "id": "7603082006",
    "district_id": "760308",
    "label": "Rante Puang",
    "value": "Rante Puang"
  },
  {
    "id": "7603082007",
    "district_id": "760308",
    "label": "Satenetean",
    "value": "Satenetean"
  },
  {
    "id": "7603082008",
    "district_id": "760308",
    "label": "Lisuan Ada",
    "value": "Lisuan Ada"
  },
  {
    "id": "7603082010",
    "district_id": "760308",
    "label": "Orobua Selatan",
    "value": "Orobua Selatan"
  },
  {
    "id": "7603082012",
    "district_id": "760308",
    "label": "Malangkena Padang",
    "value": "Malangkena Padang"
  },
  {
    "id": "7603082013",
    "district_id": "760308",
    "label": "Malimbong",
    "value": "Malimbong"
  },
  {
    "id": "7603082014",
    "district_id": "760308",
    "label": "Marampan Orobua",
    "value": "Marampan Orobua"
  },
  {
    "id": "7603091011",
    "district_id": "760309",
    "label": "Minake",
    "value": "Minake"
  },
  {
    "id": "7603092001",
    "district_id": "760309",
    "label": "Malabo",
    "value": "Malabo"
  },
  {
    "id": "7603092005",
    "district_id": "760309",
    "label": "Sindagamanik",
    "value": "Sindagamanik"
  },
  {
    "id": "7603092006",
    "district_id": "760309",
    "label": "Parondobulawan",
    "value": "Parondobulawan"
  },
  {
    "id": "7603092007",
    "district_id": "760309",
    "label": "Tamalantik",
    "value": "Tamalantik"
  },
  {
    "id": "7603092008",
    "district_id": "760309",
    "label": "Mannababa",
    "value": "Mannababa"
  },
  {
    "id": "7603092009",
    "district_id": "760309",
    "label": "Mesakada",
    "value": "Mesakada"
  },
  {
    "id": "7603092010",
    "district_id": "760309",
    "label": "Ballabatu",
    "value": "Ballabatu"
  },
  {
    "id": "7603092012",
    "district_id": "760309",
    "label": "Pambe",
    "value": "Pambe"
  },
  {
    "id": "7603092013",
    "district_id": "760309",
    "label": "Salurano",
    "value": "Salurano"
  },
  {
    "id": "7603092014",
    "district_id": "760309",
    "label": "Talimbung",
    "value": "Talimbung"
  },
  {
    "id": "7603092015",
    "district_id": "760309",
    "label": "Kanan",
    "value": "Kanan"
  },
  {
    "id": "7603101001",
    "district_id": "760310",
    "label": "Tabang",
    "value": "Tabang"
  },
  {
    "id": "7603102003",
    "district_id": "760310",
    "label": "Tadokalua",
    "value": "Tadokalua"
  },
  {
    "id": "7603102004",
    "district_id": "760310",
    "label": "Tabang Barat",
    "value": "Tabang Barat"
  },
  {
    "id": "7603102005",
    "district_id": "760310",
    "label": "Masupu",
    "value": "Masupu"
  },
  {
    "id": "7603102006",
    "district_id": "760310",
    "label": "Kalama",
    "value": "Kalama"
  },
  {
    "id": "7603102007",
    "district_id": "760310",
    "label": "Bakadisura",
    "value": "Bakadisura"
  },
  {
    "id": "7603102008",
    "district_id": "760310",
    "label": "Salukona",
    "value": "Salukona"
  },
  {
    "id": "7603112001",
    "district_id": "760311",
    "label": "Mokallang",
    "value": "Mokallang"
  },
  {
    "id": "7603112002",
    "district_id": "760311",
    "label": "Masoso",
    "value": "Masoso"
  },
  {
    "id": "7603112003",
    "district_id": "760311",
    "label": "Saluassing",
    "value": "Saluassing"
  },
  {
    "id": "7603112004",
    "district_id": "760311",
    "label": "Salubulo",
    "value": "Salubulo"
  },
  {
    "id": "7603112005",
    "district_id": "760311",
    "label": "Salukadi",
    "value": "Salukadi"
  },
  {
    "id": "7603112006",
    "district_id": "760311",
    "label": "Salukepopok",
    "value": "Salukepopok"
  },
  {
    "id": "7603112007",
    "district_id": "760311",
    "label": "Ulumambi Barat",
    "value": "Ulumambi Barat"
  },
  {
    "id": "7603112008",
    "district_id": "760311",
    "label": "Limbadebata",
    "value": "Limbadebata"
  },
  {
    "id": "7603112009",
    "district_id": "760311",
    "label": "Tanete Tomba",
    "value": "Tanete Tomba"
  },
  {
    "id": "7603112010",
    "district_id": "760311",
    "label": "Bambang",
    "value": "Bambang"
  },
  {
    "id": "7603112011",
    "district_id": "760311",
    "label": "Rantelemo",
    "value": "Rantelemo"
  },
  {
    "id": "7603112012",
    "district_id": "760311",
    "label": "Saludengen",
    "value": "Saludengen"
  },
  {
    "id": "7603112013",
    "district_id": "760311",
    "label": "Ulumambi",
    "value": "Ulumambi"
  },
  {
    "id": "7603112014",
    "district_id": "760311",
    "label": "Bambang Timur",
    "value": "Bambang Timur"
  },
  {
    "id": "7603112015",
    "district_id": "760311",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7603112016",
    "district_id": "760311",
    "label": "Sikamase",
    "value": "Sikamase"
  },
  {
    "id": "7603112017",
    "district_id": "760311",
    "label": "Rantetarima",
    "value": "Rantetarima"
  },
  {
    "id": "7603112018",
    "district_id": "760311",
    "label": "Salururu",
    "value": "Salururu"
  },
  {
    "id": "7603112019",
    "district_id": "760311",
    "label": "Salutabang",
    "value": "Salutabang"
  },
  {
    "id": "7603112020",
    "district_id": "760311",
    "label": "Balatana",
    "value": "Balatana"
  },
  {
    "id": "7603122001",
    "district_id": "760312",
    "label": "Balla",
    "value": "Balla"
  },
  {
    "id": "7603122002",
    "district_id": "760312",
    "label": "Balla Barat",
    "value": "Balla Barat"
  },
  {
    "id": "7603122003",
    "district_id": "760312",
    "label": "Balla Satanetean",
    "value": "Balla Satanetean"
  },
  {
    "id": "7603122004",
    "district_id": "760312",
    "label": "Balla Tumuka",
    "value": "Balla Tumuka"
  },
  {
    "id": "7603122005",
    "district_id": "760312",
    "label": "Sepakuan",
    "value": "Sepakuan"
  },
  {
    "id": "7603122006",
    "district_id": "760312",
    "label": "Pidara",
    "value": "Pidara"
  },
  {
    "id": "7603122007",
    "district_id": "760312",
    "label": "Bambapuang",
    "value": "Bambapuang"
  },
  {
    "id": "7603122008",
    "district_id": "760312",
    "label": "Balla Timur",
    "value": "Balla Timur"
  },
  {
    "id": "7603131001",
    "district_id": "760313",
    "label": "Nosu",
    "value": "Nosu"
  },
  {
    "id": "7603132002",
    "district_id": "760313",
    "label": "Minanga",
    "value": "Minanga"
  },
  {
    "id": "7603132003",
    "district_id": "760313",
    "label": "Batupapan",
    "value": "Batupapan"
  },
  {
    "id": "7603132004",
    "district_id": "760313",
    "label": "Masewwe",
    "value": "Masewwe"
  },
  {
    "id": "7603132005",
    "district_id": "760313",
    "label": "Siwi",
    "value": "Siwi"
  },
  {
    "id": "7603132006",
    "district_id": "760313",
    "label": "Minanga Timur",
    "value": "Minanga Timur"
  },
  {
    "id": "7603132007",
    "district_id": "760313",
    "label": "Parinding",
    "value": "Parinding"
  },
  {
    "id": "7603141001",
    "district_id": "760314",
    "label": "Tawalian",
    "value": "Tawalian"
  },
  {
    "id": "7603142002",
    "district_id": "760314",
    "label": "Tawalian Timur",
    "value": "Tawalian Timur"
  },
  {
    "id": "7603142003",
    "district_id": "760314",
    "label": "Kariango",
    "value": "Kariango"
  },
  {
    "id": "7603142004",
    "district_id": "760314",
    "label": "Rantetangnga",
    "value": "Rantetangnga"
  },
  {
    "id": "7603152001",
    "district_id": "760315",
    "label": "Salumokanan",
    "value": "Salumokanan"
  },
  {
    "id": "7603152003",
    "district_id": "760315",
    "label": "Salumokanan Barat",
    "value": "Salumokanan Barat"
  },
  {
    "id": "7603152004",
    "district_id": "760315",
    "label": "Buangin",
    "value": "Buangin"
  },
  {
    "id": "7603152005",
    "district_id": "760315",
    "label": "Bambang Buda",
    "value": "Bambang Buda"
  },
  {
    "id": "7603152006",
    "district_id": "760315",
    "label": "Leko",
    "value": "Leko"
  },
  {
    "id": "7603152007",
    "district_id": "760315",
    "label": "Kirak",
    "value": "Kirak"
  },
  {
    "id": "7603152008",
    "district_id": "760315",
    "label": "Salumokanan Utara",
    "value": "Salumokanan Utara"
  },
  {
    "id": "7603152009",
    "district_id": "760315",
    "label": "Sampale",
    "value": "Sampale"
  },
  {
    "id": "7603162001",
    "district_id": "760316",
    "label": "Buntumalangka",
    "value": "Buntumalangka"
  },
  {
    "id": "7603162002",
    "district_id": "760316",
    "label": "Ranteberan",
    "value": "Ranteberan"
  },
  {
    "id": "7603162003",
    "district_id": "760316",
    "label": "Kebanga",
    "value": "Kebanga"
  },
  {
    "id": "7603162004",
    "district_id": "760316",
    "label": "Salurindu",
    "value": "Salurindu"
  },
  {
    "id": "7603162005",
    "district_id": "760316",
    "label": "Taora",
    "value": "Taora"
  },
  {
    "id": "7603162006",
    "district_id": "760316",
    "label": "Kabae",
    "value": "Kabae"
  },
  {
    "id": "7603162007",
    "district_id": "760316",
    "label": "Salutambun Barat",
    "value": "Salutambun Barat"
  },
  {
    "id": "7603162008",
    "district_id": "760316",
    "label": "Penatangan",
    "value": "Penatangan"
  },
  {
    "id": "7603162009",
    "district_id": "760316",
    "label": "Salutambun Timur",
    "value": "Salutambun Timur"
  },
  {
    "id": "7603162010",
    "district_id": "760316",
    "label": "Salutambun",
    "value": "Salutambun"
  },
  {
    "id": "7603162011",
    "district_id": "760316",
    "label": "Aralle Timur",
    "value": "Aralle Timur"
  },
  {
    "id": "7603172001",
    "district_id": "760317",
    "label": "Mehalaan",
    "value": "Mehalaan"
  },
  {
    "id": "7603172002",
    "district_id": "760317",
    "label": "Botteng",
    "value": "Botteng"
  },
  {
    "id": "7603172003",
    "district_id": "760317",
    "label": "Salukonta",
    "value": "Salukonta"
  },
  {
    "id": "7603172004",
    "district_id": "760317",
    "label": "Mesakada",
    "value": "Mesakada"
  },
  {
    "id": "7603172005",
    "district_id": "760317",
    "label": "Mehalaan Barat",
    "value": "Mehalaan Barat"
  },
  {
    "id": "7603172006",
    "district_id": "760317",
    "label": "Saluahok",
    "value": "Saluahok"
  },
  {
    "id": "7603172007",
    "district_id": "760317",
    "label": "Salubalo",
    "value": "Salubalo"
  },
  {
    "id": "7603172008",
    "district_id": "760317",
    "label": "Passembuk",
    "value": "Passembuk"
  },
  {
    "id": "7603172009",
    "district_id": "760317",
    "label": "Kondo",
    "value": "Kondo"
  },
  {
    "id": "7603172010",
    "district_id": "760317",
    "label": "Leko Sukamaju",
    "value": "Leko Sukamaju"
  },
  {
    "id": "7603172011",
    "district_id": "760317",
    "label": "Ulumea",
    "value": "Ulumea"
  },
  {
    "id": "7604011001",
    "district_id": "760401",
    "label": "Tinambung",
    "value": "Tinambung"
  },
  {
    "id": "7604012002",
    "district_id": "760401",
    "label": "Karama",
    "value": "Karama"
  },
  {
    "id": "7604012003",
    "district_id": "760401",
    "label": "Tangnga Tangnga",
    "value": "Tangnga Tangnga"
  },
  {
    "id": "7604012004",
    "district_id": "760401",
    "label": "Lekopadis",
    "value": "Lekopadis"
  },
  {
    "id": "7604012005",
    "district_id": "760401",
    "label": "Galung Lombok",
    "value": "Galung Lombok"
  },
  {
    "id": "7604012006",
    "district_id": "760401",
    "label": "Tandung",
    "value": "Tandung"
  },
  {
    "id": "7604012007",
    "district_id": "760401",
    "label": "Batulaya",
    "value": "Batulaya"
  },
  {
    "id": "7604012008",
    "district_id": "760401",
    "label": "Sepabatu",
    "value": "Sepabatu"
  },
  {
    "id": "7604021001",
    "district_id": "760402",
    "label": "Pappang",
    "value": "Pappang"
  },
  {
    "id": "7604022002",
    "district_id": "760402",
    "label": "Laliko",
    "value": "Laliko"
  },
  {
    "id": "7604022003",
    "district_id": "760402",
    "label": "Bonde",
    "value": "Bonde"
  },
  {
    "id": "7604022004",
    "district_id": "760402",
    "label": "Katumbangan Lemo",
    "value": "Katumbangan Lemo"
  },
  {
    "id": "7604022005",
    "district_id": "760402",
    "label": "Panyampa",
    "value": "Panyampa"
  },
  {
    "id": "7604022006",
    "district_id": "760402",
    "label": "Suruang",
    "value": "Suruang"
  },
  {
    "id": "7604022007",
    "district_id": "760402",
    "label": "Lampoko",
    "value": "Lampoko"
  },
  {
    "id": "7604022008",
    "district_id": "760402",
    "label": "Katumbangan",
    "value": "Katumbangan"
  },
  {
    "id": "7604022009",
    "district_id": "760402",
    "label": "Lapeo",
    "value": "Lapeo"
  },
  {
    "id": "7604022010",
    "district_id": "760402",
    "label": "Parappe",
    "value": "Parappe"
  },
  {
    "id": "7604022011",
    "district_id": "760402",
    "label": "Ongko",
    "value": "Ongko"
  },
  {
    "id": "7604022012",
    "district_id": "760402",
    "label": "Kenje",
    "value": "Kenje"
  },
  {
    "id": "7604022013",
    "district_id": "760402",
    "label": "Sumarrang",
    "value": "Sumarrang"
  },
  {
    "id": "7604022014",
    "district_id": "760402",
    "label": "Botto",
    "value": "Botto"
  },
  {
    "id": "7604022015",
    "district_id": "760402",
    "label": "Gattungan",
    "value": "Gattungan"
  },
  {
    "id": "7604022016",
    "district_id": "760402",
    "label": "Padang Timur",
    "value": "Padang Timur"
  },
  {
    "id": "7604022017",
    "district_id": "760402",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "7604022018",
    "district_id": "760402",
    "label": "Lagi-Agi",
    "value": "Lagi-Agi"
  },
  {
    "id": "7604031001",
    "district_id": "760403",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "7604032002",
    "district_id": "760403",
    "label": "Sidorejo",
    "value": "Sidorejo"
  },
  {
    "id": "7604032003",
    "district_id": "760403",
    "label": "Sumberejo",
    "value": "Sumberejo"
  },
  {
    "id": "7604032004",
    "district_id": "760403",
    "label": "Bumiayu",
    "value": "Bumiayu"
  },
  {
    "id": "7604032005",
    "district_id": "760403",
    "label": "Kebunsari",
    "value": "Kebunsari"
  },
  {
    "id": "7604032006",
    "district_id": "760403",
    "label": "Banua Baru",
    "value": "Banua Baru"
  },
  {
    "id": "7604032007",
    "district_id": "760403",
    "label": "Nepo",
    "value": "Nepo"
  },
  {
    "id": "7604032008",
    "district_id": "760403",
    "label": "Sugihwaras",
    "value": "Sugihwaras"
  },
  {
    "id": "7604032009",
    "district_id": "760403",
    "label": "Campurjo",
    "value": "Campurjo"
  },
  {
    "id": "7604032010",
    "district_id": "760403",
    "label": "Bumimulyo",
    "value": "Bumimulyo"
  },
  {
    "id": "7604032011",
    "district_id": "760403",
    "label": "Tumpiling",
    "value": "Tumpiling"
  },
  {
    "id": "7604032012",
    "district_id": "760403",
    "label": "Bakka-Bakka",
    "value": "Bakka-Bakka"
  },
  {
    "id": "7604032013",
    "district_id": "760403",
    "label": "Arjo Sari",
    "value": "Arjo Sari"
  },
  {
    "id": "7604032014",
    "district_id": "760403",
    "label": "Galeson",
    "value": "Galeson"
  },
  {
    "id": "7604041001",
    "district_id": "760404",
    "label": "Polewali",
    "value": "Polewali"
  },
  {
    "id": "7604041002",
    "district_id": "760404",
    "label": "Wattang",
    "value": "Wattang"
  },
  {
    "id": "7604041003",
    "district_id": "760404",
    "label": "Sulewatang",
    "value": "Sulewatang"
  },
  {
    "id": "7604041004",
    "district_id": "760404",
    "label": "Lantora",
    "value": "Lantora"
  },
  {
    "id": "7604041005",
    "district_id": "760404",
    "label": "Takatidung",
    "value": "Takatidung"
  },
  {
    "id": "7604041006",
    "district_id": "760404",
    "label": "Pekkabata",
    "value": "Pekkabata"
  },
  {
    "id": "7604041007",
    "district_id": "760404",
    "label": "Madate",
    "value": "Madate"
  },
  {
    "id": "7604041008",
    "district_id": "760404",
    "label": "Manding",
    "value": "Manding"
  },
  {
    "id": "7604041009",
    "district_id": "760404",
    "label": "Darma",
    "value": "Darma"
  },
  {
    "id": "7604051001",
    "district_id": "760405",
    "label": "Taramanu",
    "value": "Taramanu"
  },
  {
    "id": "7604052002",
    "district_id": "760405",
    "label": "Ambopadang",
    "value": "Ambopadang"
  },
  {
    "id": "7604052003",
    "district_id": "760405",
    "label": "Tubbi",
    "value": "Tubbi"
  },
  {
    "id": "7604052004",
    "district_id": "760405",
    "label": "Ratte",
    "value": "Ratte"
  },
  {
    "id": "7604052005",
    "district_id": "760405",
    "label": "Besoangin",
    "value": "Besoangin"
  },
  {
    "id": "7604052006",
    "district_id": "760405",
    "label": "Piriang Tapiko",
    "value": "Piriang Tapiko"
  },
  {
    "id": "7604052007",
    "district_id": "760405",
    "label": "Peburru",
    "value": "Peburru"
  },
  {
    "id": "7604052008",
    "district_id": "760405",
    "label": "Taloba",
    "value": "Taloba"
  },
  {
    "id": "7604052009",
    "district_id": "760405",
    "label": "Pollewani",
    "value": "Pollewani"
  },
  {
    "id": "7604052010",
    "district_id": "760405",
    "label": "Arabua",
    "value": "Arabua"
  },
  {
    "id": "7604052011",
    "district_id": "760405",
    "label": "Besoangin Utara",
    "value": "Besoangin Utara"
  },
  {
    "id": "7604052012",
    "district_id": "760405",
    "label": "Poda",
    "value": "Poda"
  },
  {
    "id": "7604052013",
    "district_id": "760405",
    "label": "Taramanu Tua",
    "value": "Taramanu Tua"
  },
  {
    "id": "7604061005",
    "district_id": "760406",
    "label": "Amassangan",
    "value": "Amassangan"
  },
  {
    "id": "7604062001",
    "district_id": "760406",
    "label": "Tonyaman",
    "value": "Tonyaman"
  },
  {
    "id": "7604062002",
    "district_id": "760406",
    "label": "Kuajang",
    "value": "Kuajang"
  },
  {
    "id": "7604062003",
    "district_id": "760406",
    "label": "Paku",
    "value": "Paku"
  },
  {
    "id": "7604062004",
    "district_id": "760406",
    "label": "Mirring",
    "value": "Mirring"
  },
  {
    "id": "7604062006",
    "district_id": "760406",
    "label": "Batetangnga",
    "value": "Batetangnga"
  },
  {
    "id": "7604062007",
    "district_id": "760406",
    "label": "Mammi",
    "value": "Mammi"
  },
  {
    "id": "7604062008",
    "district_id": "760406",
    "label": "Kaleok",
    "value": "Kaleok"
  },
  {
    "id": "7604062009",
    "district_id": "760406",
    "label": "Amola",
    "value": "Amola"
  },
  {
    "id": "7604062010",
    "district_id": "760406",
    "label": "Rea",
    "value": "Rea"
  },
  {
    "id": "7604071001",
    "district_id": "760407",
    "label": "Pelitakan",
    "value": "Pelitakan"
  },
  {
    "id": "7604072002",
    "district_id": "760407",
    "label": "Tapango",
    "value": "Tapango"
  },
  {
    "id": "7604072003",
    "district_id": "760407",
    "label": "Rappang",
    "value": "Rappang"
  },
  {
    "id": "7604072004",
    "district_id": "760407",
    "label": "Dakka",
    "value": "Dakka"
  },
  {
    "id": "7604072005",
    "district_id": "760407",
    "label": "Batu",
    "value": "Batu"
  },
  {
    "id": "7604072006",
    "district_id": "760407",
    "label": "Riso",
    "value": "Riso"
  },
  {
    "id": "7604072007",
    "district_id": "760407",
    "label": "Palatta",
    "value": "Palatta"
  },
  {
    "id": "7604072008",
    "district_id": "760407",
    "label": "Tapango Barat",
    "value": "Tapango Barat"
  },
  {
    "id": "7604072009",
    "district_id": "760407",
    "label": "Kurrak",
    "value": "Kurrak"
  },
  {
    "id": "7604072010",
    "district_id": "760407",
    "label": "Bussu",
    "value": "Bussu"
  },
  {
    "id": "7604072011",
    "district_id": "760407",
    "label": "Tuttula",
    "value": "Tuttula"
  },
  {
    "id": "7604072012",
    "district_id": "760407",
    "label": "Jambu Malea",
    "value": "Jambu Malea"
  },
  {
    "id": "7604072013",
    "district_id": "760407",
    "label": "Kalimbua",
    "value": "Kalimbua"
  },
  {
    "id": "7604072014",
    "district_id": "760407",
    "label": "Banato Rejo",
    "value": "Banato Rejo"
  },
  {
    "id": "7604081001",
    "district_id": "760408",
    "label": "Mapili",
    "value": "Mapili"
  },
  {
    "id": "7604082002",
    "district_id": "760408",
    "label": "Ugibaru",
    "value": "Ugibaru"
  },
  {
    "id": "7604082003",
    "district_id": "760408",
    "label": "Rumpa",
    "value": "Rumpa"
  },
  {
    "id": "7604082005",
    "district_id": "760408",
    "label": "Kurma",
    "value": "Kurma"
  },
  {
    "id": "7604082007",
    "district_id": "760408",
    "label": "Buku",
    "value": "Buku"
  },
  {
    "id": "7604082009",
    "district_id": "760408",
    "label": "Bonne Bonne",
    "value": "Bonne Bonne"
  },
  {
    "id": "7604082011",
    "district_id": "760408",
    "label": "Beroangin",
    "value": "Beroangin"
  },
  {
    "id": "7604082015",
    "district_id": "760408",
    "label": "Segerang",
    "value": "Segerang"
  },
  {
    "id": "7604082016",
    "district_id": "760408",
    "label": "Bonra",
    "value": "Bonra"
  },
  {
    "id": "7604082017",
    "district_id": "760408",
    "label": "Sattoko",
    "value": "Sattoko"
  },
  {
    "id": "7604082018",
    "district_id": "760408",
    "label": "Rappang Barat",
    "value": "Rappang Barat"
  },
  {
    "id": "7604082019",
    "district_id": "760408",
    "label": "Landi Kanusuang",
    "value": "Landi Kanusuang"
  },
  {
    "id": "7604091001",
    "district_id": "760409",
    "label": "Matangnga",
    "value": "Matangnga"
  },
  {
    "id": "7604092002",
    "district_id": "760409",
    "label": "Lilli",
    "value": "Lilli"
  },
  {
    "id": "7604092003",
    "district_id": "760409",
    "label": "Rangoan",
    "value": "Rangoan"
  },
  {
    "id": "7604092004",
    "district_id": "760409",
    "label": "Tapua",
    "value": "Tapua"
  },
  {
    "id": "7604092005",
    "district_id": "760409",
    "label": "Mambu Tapua",
    "value": "Mambu Tapua"
  },
  {
    "id": "7604092006",
    "district_id": "760409",
    "label": "Ba'ba Tapua",
    "value": "Ba'ba Tapua"
  },
  {
    "id": "7604092007",
    "district_id": "760409",
    "label": "Katimbang",
    "value": "Katimbang"
  },
  {
    "id": "7604101004",
    "district_id": "760410",
    "label": "Batupanga",
    "value": "Batupanga"
  },
  {
    "id": "7604102001",
    "district_id": "760410",
    "label": "Mambu",
    "value": "Mambu"
  },
  {
    "id": "7604102002",
    "district_id": "760410",
    "label": "Tenggelang",
    "value": "Tenggelang"
  },
  {
    "id": "7604102003",
    "district_id": "760410",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7604102005",
    "district_id": "760410",
    "label": "Pussui",
    "value": "Pussui"
  },
  {
    "id": "7604102006",
    "district_id": "760410",
    "label": "Mapili Barat",
    "value": "Mapili Barat"
  },
  {
    "id": "7604102007",
    "district_id": "760410",
    "label": "Sambali Wali",
    "value": "Sambali Wali"
  },
  {
    "id": "7604102008",
    "district_id": "760410",
    "label": "Batupanga Daala",
    "value": "Batupanga Daala"
  },
  {
    "id": "7604102009",
    "district_id": "760410",
    "label": "Luyo",
    "value": "Luyo"
  },
  {
    "id": "7604102010",
    "district_id": "760410",
    "label": "Pussui Barat",
    "value": "Pussui Barat"
  },
  {
    "id": "7604102011",
    "district_id": "760410",
    "label": "Puccadi",
    "value": "Puccadi"
  },
  {
    "id": "7604111001",
    "district_id": "760411",
    "label": "Limboro",
    "value": "Limboro"
  },
  {
    "id": "7604112002",
    "district_id": "760411",
    "label": "Lembang Lembang",
    "value": "Lembang Lembang"
  },
  {
    "id": "7604112003",
    "district_id": "760411",
    "label": "Tandasura",
    "value": "Tandasura"
  },
  {
    "id": "7604112004",
    "district_id": "760411",
    "label": "Samasundu",
    "value": "Samasundu"
  },
  {
    "id": "7604112005",
    "district_id": "760411",
    "label": "Napo",
    "value": "Napo"
  },
  {
    "id": "7604112006",
    "district_id": "760411",
    "label": "Todang Todang",
    "value": "Todang Todang"
  },
  {
    "id": "7604112007",
    "district_id": "760411",
    "label": "Pendulangan",
    "value": "Pendulangan"
  },
  {
    "id": "7604112008",
    "district_id": "760411",
    "label": "Renggeang",
    "value": "Renggeang"
  },
  {
    "id": "7604112009",
    "district_id": "760411",
    "label": "Salarri",
    "value": "Salarri"
  },
  {
    "id": "7604112010",
    "district_id": "760411",
    "label": "Tangan Baru",
    "value": "Tangan Baru"
  },
  {
    "id": "7604112011",
    "district_id": "760411",
    "label": "Palece",
    "value": "Palece"
  },
  {
    "id": "7604121002",
    "district_id": "760412",
    "label": "Balanipa",
    "value": "Balanipa"
  },
  {
    "id": "7604122001",
    "district_id": "760412",
    "label": "Tammajarra",
    "value": "Tammajarra"
  },
  {
    "id": "7604122003",
    "district_id": "760412",
    "label": "Tamanggalle",
    "value": "Tamanggalle"
  },
  {
    "id": "7604122004",
    "district_id": "760412",
    "label": "Mosso",
    "value": "Mosso"
  },
  {
    "id": "7604122005",
    "district_id": "760412",
    "label": "Galung Tuluk",
    "value": "Galung Tuluk"
  },
  {
    "id": "7604122006",
    "district_id": "760412",
    "label": "Sabang Subik",
    "value": "Sabang Subik"
  },
  {
    "id": "7604122007",
    "district_id": "760412",
    "label": "Pambusuang",
    "value": "Pambusuang"
  },
  {
    "id": "7604122008",
    "district_id": "760412",
    "label": "Bala",
    "value": "Bala"
  },
  {
    "id": "7604122009",
    "district_id": "760412",
    "label": "Lambanan",
    "value": "Lambanan"
  },
  {
    "id": "7604122010",
    "district_id": "760412",
    "label": "Lego",
    "value": "Lego"
  },
  {
    "id": "7604122011",
    "district_id": "760412",
    "label": "Pallis",
    "value": "Pallis"
  },
  {
    "id": "7604131001",
    "district_id": "760413",
    "label": "Anreapi",
    "value": "Anreapi"
  },
  {
    "id": "7604132002",
    "district_id": "760413",
    "label": "Duampanua",
    "value": "Duampanua"
  },
  {
    "id": "7604132003",
    "district_id": "760413",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "7604132004",
    "district_id": "760413",
    "label": "Papandangan",
    "value": "Papandangan"
  },
  {
    "id": "7604132005",
    "district_id": "760413",
    "label": "Kunyi",
    "value": "Kunyi"
  },
  {
    "id": "7604141001",
    "district_id": "760414",
    "label": "Matakali",
    "value": "Matakali"
  },
  {
    "id": "7604142002",
    "district_id": "760414",
    "label": "Petampanua",
    "value": "Petampanua"
  },
  {
    "id": "7604142003",
    "district_id": "760414",
    "label": "Barumbung",
    "value": "Barumbung"
  },
  {
    "id": "7604142004",
    "district_id": "760414",
    "label": "Pasiang",
    "value": "Pasiang"
  },
  {
    "id": "7604142005",
    "district_id": "760414",
    "label": "Tonrolima",
    "value": "Tonrolima"
  },
  {
    "id": "7604142006",
    "district_id": "760414",
    "label": "Indomakkombong",
    "value": "Indomakkombong"
  },
  {
    "id": "7604142007",
    "district_id": "760414",
    "label": "Bunga-Bunga",
    "value": "Bunga-Bunga"
  },
  {
    "id": "7604151009",
    "district_id": "760415",
    "label": "Petoosang",
    "value": "Petoosang"
  },
  {
    "id": "7604152002",
    "district_id": "760415",
    "label": "Mombi",
    "value": "Mombi"
  },
  {
    "id": "7604152003",
    "district_id": "760415",
    "label": "Kalumammang",
    "value": "Kalumammang"
  },
  {
    "id": "7604152004",
    "district_id": "760415",
    "label": "Pao-pao",
    "value": "Pao-pao"
  },
  {
    "id": "7604152005",
    "district_id": "760415",
    "label": "Puppu Uring",
    "value": "Puppu Uring"
  },
  {
    "id": "7604152006",
    "district_id": "760415",
    "label": "Saragian",
    "value": "Saragian"
  },
  {
    "id": "7604152007",
    "district_id": "760415",
    "label": "Sayoang",
    "value": "Sayoang"
  },
  {
    "id": "7604152008",
    "district_id": "760415",
    "label": "Alu",
    "value": "Alu"
  },
  {
    "id": "7604162001",
    "district_id": "760416",
    "label": "Bulo",
    "value": "Bulo"
  },
  {
    "id": "7604162002",
    "district_id": "760416",
    "label": "Pulliwa",
    "value": "Pulliwa"
  },
  {
    "id": "7604162003",
    "district_id": "760416",
    "label": "Patambanua",
    "value": "Patambanua"
  },
  {
    "id": "7604162004",
    "district_id": "760416",
    "label": "Karombang",
    "value": "Karombang"
  },
  {
    "id": "7604162005",
    "district_id": "760416",
    "label": "Daala Timur",
    "value": "Daala Timur"
  },
  {
    "id": "7604162006",
    "district_id": "760416",
    "label": "Lenggo",
    "value": "Lenggo"
  },
  {
    "id": "7604162007",
    "district_id": "760416",
    "label": "Sabura",
    "value": "Sabura"
  },
  {
    "id": "7604162008",
    "district_id": "760416",
    "label": "Sepporraki",
    "value": "Sepporraki"
  },
  {
    "id": "7604162009",
    "district_id": "760416",
    "label": "Ihing",
    "value": "Ihing"
  },
  {
    "id": "7605011001",
    "district_id": "760501",
    "label": "Banggae",
    "value": "Banggae"
  },
  {
    "id": "7605011003",
    "district_id": "760501",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "7605011007",
    "district_id": "760501",
    "label": "Pangali Ali",
    "value": "Pangali Ali"
  },
  {
    "id": "7605011009",
    "district_id": "760501",
    "label": "Totoli",
    "value": "Totoli"
  },
  {
    "id": "7605011010",
    "district_id": "760501",
    "label": "Rangas",
    "value": "Rangas"
  },
  {
    "id": "7605011013",
    "district_id": "760501",
    "label": "Galung",
    "value": "Galung"
  },
  {
    "id": "7605012011",
    "district_id": "760501",
    "label": "Palipi Soreang",
    "value": "Palipi Soreang"
  },
  {
    "id": "7605012012",
    "district_id": "760501",
    "label": "Pamboborang",
    "value": "Pamboborang"
  },
  {
    "id": "7605021001",
    "district_id": "760502",
    "label": "Lalampanua",
    "value": "Lalampanua"
  },
  {
    "id": "7605021002",
    "district_id": "760502",
    "label": "Sirindu",
    "value": "Sirindu"
  },
  {
    "id": "7605022003",
    "district_id": "760502",
    "label": "Adolang",
    "value": "Adolang"
  },
  {
    "id": "7605022004",
    "district_id": "760502",
    "label": "Bababulo",
    "value": "Bababulo"
  },
  {
    "id": "7605022005",
    "district_id": "760502",
    "label": "Bonde",
    "value": "Bonde"
  },
  {
    "id": "7605022006",
    "district_id": "760502",
    "label": "Betteng",
    "value": "Betteng"
  },
  {
    "id": "7605022007",
    "district_id": "760502",
    "label": "Simbang",
    "value": "Simbang"
  },
  {
    "id": "7605022008",
    "district_id": "760502",
    "label": "Bonde Utara",
    "value": "Bonde Utara"
  },
  {
    "id": "7605022009",
    "district_id": "760502",
    "label": "Bababulo Utara",
    "value": "Bababulo Utara"
  },
  {
    "id": "7605022010",
    "district_id": "760502",
    "label": "Buttu Pamboang",
    "value": "Buttu Pamboang"
  },
  {
    "id": "7605022011",
    "district_id": "760502",
    "label": "Banua Adolang",
    "value": "Banua Adolang"
  },
  {
    "id": "7605022012",
    "district_id": "760502",
    "label": "Adolang Dhua",
    "value": "Adolang Dhua"
  },
  {
    "id": "7605022013",
    "district_id": "760502",
    "label": "Tinambung",
    "value": "Tinambung"
  },
  {
    "id": "7605022014",
    "district_id": "760502",
    "label": "Pesuloang",
    "value": "Pesuloang"
  },
  {
    "id": "7605022015",
    "district_id": "760502",
    "label": "Balombong",
    "value": "Balombong"
  },
  {
    "id": "7605031001",
    "district_id": "760503",
    "label": "Mosso",
    "value": "Mosso"
  },
  {
    "id": "7605031002",
    "district_id": "760503",
    "label": "Mosso Dhua",
    "value": "Mosso Dhua"
  },
  {
    "id": "7605032004",
    "district_id": "760503",
    "label": "Totolisi Sendana",
    "value": "Totolisi Sendana"
  },
  {
    "id": "7605032007",
    "district_id": "760503",
    "label": "Tallubanua",
    "value": "Tallubanua"
  },
  {
    "id": "7605032015",
    "district_id": "760503",
    "label": "Lalatedzong",
    "value": "Lalatedzong"
  },
  {
    "id": "7605032016",
    "district_id": "760503",
    "label": "Binanga",
    "value": "Binanga"
  },
  {
    "id": "7605032017",
    "district_id": "760503",
    "label": "Puttada",
    "value": "Puttada"
  },
  {
    "id": "7605032018",
    "district_id": "760503",
    "label": "Paminggalan",
    "value": "Paminggalan"
  },
  {
    "id": "7605032019",
    "district_id": "760503",
    "label": "Leppangang",
    "value": "Leppangang"
  },
  {
    "id": "7605032020",
    "district_id": "760503",
    "label": "Pundau",
    "value": "Pundau"
  },
  {
    "id": "7605032021",
    "district_id": "760503",
    "label": "Sendana",
    "value": "Sendana"
  },
  {
    "id": "7605032022",
    "district_id": "760503",
    "label": "Banua Sendana",
    "value": "Banua Sendana"
  },
  {
    "id": "7605032023",
    "district_id": "760503",
    "label": "Tallubanua Utara",
    "value": "Tallubanua Utara"
  },
  {
    "id": "7605032024",
    "district_id": "760503",
    "label": "Limboro Rambu-Rambu",
    "value": "Limboro Rambu-Rambu"
  },
  {
    "id": "7605032025",
    "district_id": "760503",
    "label": "Limbua",
    "value": "Limbua"
  },
  {
    "id": "7605032026",
    "district_id": "760503",
    "label": "Bukit Samang",
    "value": "Bukit Samang"
  },
  {
    "id": "7605041001",
    "district_id": "760504",
    "label": "Malunda",
    "value": "Malunda"
  },
  {
    "id": "7605041015",
    "district_id": "760504",
    "label": "Lamungang Batu",
    "value": "Lamungang Batu"
  },
  {
    "id": "7605042002",
    "district_id": "760504",
    "label": "Bambangan",
    "value": "Bambangan"
  },
  {
    "id": "7605042004",
    "district_id": "760504",
    "label": "Lombang",
    "value": "Lombang"
  },
  {
    "id": "7605042005",
    "district_id": "760504",
    "label": "Lombong",
    "value": "Lombong"
  },
  {
    "id": "7605042006",
    "district_id": "760504",
    "label": "Mekkatta",
    "value": "Mekkatta"
  },
  {
    "id": "7605042010",
    "district_id": "760504",
    "label": "Maliaya",
    "value": "Maliaya"
  },
  {
    "id": "7605042011",
    "district_id": "760504",
    "label": "Lombang Timur",
    "value": "Lombang Timur"
  },
  {
    "id": "7605042012",
    "district_id": "760504",
    "label": "Salutahongan",
    "value": "Salutahongan"
  },
  {
    "id": "7605042013",
    "district_id": "760504",
    "label": "Lombong Timur",
    "value": "Lombong Timur"
  },
  {
    "id": "7605042014",
    "district_id": "760504",
    "label": "Mekkatta Selatan",
    "value": "Mekkatta Selatan"
  },
  {
    "id": "7605042016",
    "district_id": "760504",
    "label": "Kayuangin",
    "value": "Kayuangin"
  },
  {
    "id": "7605052001",
    "district_id": "760505",
    "label": "Kabiraan",
    "value": "Kabiraan"
  },
  {
    "id": "7605052002",
    "district_id": "760505",
    "label": "Sambabo",
    "value": "Sambabo"
  },
  {
    "id": "7605052003",
    "district_id": "760505",
    "label": "Tandeallo",
    "value": "Tandeallo"
  },
  {
    "id": "7605052004",
    "district_id": "760505",
    "label": "Ulumanda",
    "value": "Ulumanda"
  },
  {
    "id": "7605052005",
    "district_id": "760505",
    "label": "Salutambung",
    "value": "Salutambung"
  },
  {
    "id": "7605052006",
    "district_id": "760505",
    "label": "Popenga",
    "value": "Popenga"
  },
  {
    "id": "7605052007",
    "district_id": "760505",
    "label": "Sulai",
    "value": "Sulai"
  },
  {
    "id": "7605052008",
    "district_id": "760505",
    "label": "Panggalo",
    "value": "Panggalo"
  },
  {
    "id": "7605062001",
    "district_id": "760506",
    "label": "Seppong",
    "value": "Seppong"
  },
  {
    "id": "7605062002",
    "district_id": "760506",
    "label": "Tammerodo",
    "value": "Tammerodo"
  },
  {
    "id": "7605062003",
    "district_id": "760506",
    "label": "Ulidang",
    "value": "Ulidang"
  },
  {
    "id": "7605062004",
    "district_id": "760506",
    "label": "Tallambalao",
    "value": "Tallambalao"
  },
  {
    "id": "7605062005",
    "district_id": "760506",
    "label": "Tammerodo Utara",
    "value": "Tammerodo Utara"
  },
  {
    "id": "7605062006",
    "district_id": "760506",
    "label": "Manyamba",
    "value": "Manyamba"
  },
  {
    "id": "7605062007",
    "district_id": "760506",
    "label": "Awo",
    "value": "Awo"
  },
  {
    "id": "7605072001",
    "district_id": "760507",
    "label": "Onang",
    "value": "Onang"
  },
  {
    "id": "7605072002",
    "district_id": "760507",
    "label": "Tubo",
    "value": "Tubo"
  },
  {
    "id": "7605072003",
    "district_id": "760507",
    "label": "Onang Utara",
    "value": "Onang Utara"
  },
  {
    "id": "7605072004",
    "district_id": "760507",
    "label": "Tubo Selatan",
    "value": "Tubo Selatan"
  },
  {
    "id": "7605072005",
    "district_id": "760507",
    "label": "Bonde bonde",
    "value": "Bonde bonde"
  },
  {
    "id": "7605072006",
    "district_id": "760507",
    "label": "Tubo Tengah",
    "value": "Tubo Tengah"
  },
  {
    "id": "7605072007",
    "district_id": "760507",
    "label": "Tubo Poang",
    "value": "Tubo Poang"
  },
  {
    "id": "7605081001",
    "district_id": "760508",
    "label": "Baurung",
    "value": "Baurung"
  },
  {
    "id": "7605081002",
    "district_id": "760508",
    "label": "Baruga",
    "value": "Baruga"
  },
  {
    "id": "7605081003",
    "district_id": "760508",
    "label": "Baruga Dhua",
    "value": "Baruga Dhua"
  },
  {
    "id": "7605081004",
    "district_id": "760508",
    "label": "Labuang",
    "value": "Labuang"
  },
  {
    "id": "7605081005",
    "district_id": "760508",
    "label": "Tande",
    "value": "Tande"
  },
  {
    "id": "7605081006",
    "district_id": "760508",
    "label": "Lembang",
    "value": "Lembang"
  },
  {
    "id": "7605081007",
    "district_id": "760508",
    "label": "Labuang Utara",
    "value": "Labuang Utara"
  },
  {
    "id": "7605081008",
    "district_id": "760508",
    "label": "Tande Timur",
    "value": "Tande Timur"
  },
  {
    "id": "7605082009",
    "district_id": "760508",
    "label": "Buttu Baruga",
    "value": "Buttu Baruga"
  },
  {
    "id": "7606012001",
    "district_id": "760601",
    "label": "Mahahe",
    "value": "Mahahe"
  },
  {
    "id": "7606012002",
    "district_id": "760601",
    "label": "Salobaja",
    "value": "Salobaja"
  },
  {
    "id": "7606012003",
    "district_id": "760601",
    "label": "Tobadak",
    "value": "Tobadak"
  },
  {
    "id": "7606012004",
    "district_id": "760601",
    "label": "Palongaan",
    "value": "Palongaan"
  },
  {
    "id": "7606012005",
    "district_id": "760601",
    "label": "Batu Parigi",
    "value": "Batu Parigi"
  },
  {
    "id": "7606012006",
    "district_id": "760601",
    "label": "Bambadaru",
    "value": "Bambadaru"
  },
  {
    "id": "7606012007",
    "district_id": "760601",
    "label": "Sejati",
    "value": "Sejati"
  },
  {
    "id": "7606012008",
    "district_id": "760601",
    "label": "Saloadak",
    "value": "Saloadak"
  },
  {
    "id": "7606022001",
    "district_id": "760602",
    "label": "Polo Pangale",
    "value": "Polo Pangale"
  },
  {
    "id": "7606022002",
    "district_id": "760602",
    "label": "Pangale",
    "value": "Pangale"
  },
  {
    "id": "7606022003",
    "district_id": "760602",
    "label": "Sartanamaju",
    "value": "Sartanamaju"
  },
  {
    "id": "7606022004",
    "district_id": "760602",
    "label": "Polo Lereng",
    "value": "Polo Lereng"
  },
  {
    "id": "7606022005",
    "district_id": "760602",
    "label": "Kuo",
    "value": "Kuo"
  },
  {
    "id": "7606022006",
    "district_id": "760602",
    "label": "Polo Camba",
    "value": "Polo Camba"
  },
  {
    "id": "7606022007",
    "district_id": "760602",
    "label": "Lamba-lamba",
    "value": "Lamba-lamba"
  },
  {
    "id": "7606022008",
    "district_id": "760602",
    "label": "Kombiling",
    "value": "Kombiling"
  },
  {
    "id": "7606022009",
    "district_id": "760602",
    "label": "Lemo-Lemo",
    "value": "Lemo-Lemo"
  },
  {
    "id": "7606032001",
    "district_id": "760603",
    "label": "Babana",
    "value": "Babana"
  },
  {
    "id": "7606032002",
    "district_id": "760603",
    "label": "Lumu",
    "value": "Lumu"
  },
  {
    "id": "7606032003",
    "district_id": "760603",
    "label": "Pontanakayang",
    "value": "Pontanakayang"
  },
  {
    "id": "7606032004",
    "district_id": "760603",
    "label": "Salogatta",
    "value": "Salogatta"
  },
  {
    "id": "7606032005",
    "district_id": "760603",
    "label": "Kire",
    "value": "Kire"
  },
  {
    "id": "7606032006",
    "district_id": "760603",
    "label": "Tinali",
    "value": "Tinali"
  },
  {
    "id": "7606032007",
    "district_id": "760603",
    "label": "Salumanurung",
    "value": "Salumanurung"
  },
  {
    "id": "7606032008",
    "district_id": "760603",
    "label": "Pasapa",
    "value": "Pasapa"
  },
  {
    "id": "7606032009",
    "district_id": "760603",
    "label": "Bojo",
    "value": "Bojo"
  },
  {
    "id": "7606032010",
    "district_id": "760603",
    "label": "Barakkang",
    "value": "Barakkang"
  },
  {
    "id": "7606032011",
    "district_id": "760603",
    "label": "Lembah Hada",
    "value": "Lembah Hada"
  },
  {
    "id": "7606042001",
    "district_id": "760604",
    "label": "Budong-Budong",
    "value": "Budong-Budong"
  },
  {
    "id": "7606042002",
    "district_id": "760604",
    "label": "Topoyo",
    "value": "Topoyo"
  },
  {
    "id": "7606042003",
    "district_id": "760604",
    "label": "Tumbu",
    "value": "Tumbu"
  },
  {
    "id": "7606042004",
    "district_id": "760604",
    "label": "Kabubu",
    "value": "Kabubu"
  },
  {
    "id": "7606042005",
    "district_id": "760604",
    "label": "Waeputeh",
    "value": "Waeputeh"
  },
  {
    "id": "7606042006",
    "district_id": "760604",
    "label": "Tangkou",
    "value": "Tangkou"
  },
  {
    "id": "7606042007",
    "district_id": "760604",
    "label": "Tabolang",
    "value": "Tabolang"
  },
  {
    "id": "7606042008",
    "district_id": "760604",
    "label": "Paraili",
    "value": "Paraili"
  },
  {
    "id": "7606042009",
    "district_id": "760604",
    "label": "Salupangkang",
    "value": "Salupangkang"
  },
  {
    "id": "7606042010",
    "district_id": "760604",
    "label": "Salupangkang IV",
    "value": "Salupangkang IV"
  },
  {
    "id": "7606042011",
    "district_id": "760604",
    "label": "Tappilina",
    "value": "Tappilina"
  },
  {
    "id": "7606042012",
    "district_id": "760604",
    "label": "Bambamanurung",
    "value": "Bambamanurung"
  },
  {
    "id": "7606042013",
    "district_id": "760604",
    "label": "Salulekbo",
    "value": "Salulekbo"
  },
  {
    "id": "7606042014",
    "district_id": "760604",
    "label": "Pangalloang",
    "value": "Pangalloang"
  },
  {
    "id": "7606042015",
    "district_id": "760604",
    "label": "Sinabatta",
    "value": "Sinabatta"
  },
  {
    "id": "7606052001",
    "district_id": "760605",
    "label": "Karossa",
    "value": "Karossa"
  },
  {
    "id": "7606052002",
    "district_id": "760605",
    "label": "Tasokko",
    "value": "Tasokko"
  },
  {
    "id": "7606052003",
    "district_id": "760605",
    "label": "Lara",
    "value": "Lara"
  },
  {
    "id": "7606052004",
    "district_id": "760605",
    "label": "Kadaila",
    "value": "Kadaila"
  },
  {
    "id": "7606052005",
    "district_id": "760605",
    "label": "Kayu Calla",
    "value": "Kayu Calla"
  },
  {
    "id": "7606052006",
    "district_id": "760605",
    "label": "Lembah Hopo",
    "value": "Lembah Hopo"
  },
  {
    "id": "7606052007",
    "district_id": "760605",
    "label": "Benggaulu",
    "value": "Benggaulu"
  },
  {
    "id": "7606052008",
    "district_id": "760605",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "7606052009",
    "district_id": "760605",
    "label": "Kambunong",
    "value": "Kambunong"
  },
  {
    "id": "7606052010",
    "district_id": "760605",
    "label": "Salubiro",
    "value": "Salubiro"
  },
  {
    "id": "7606052011",
    "district_id": "760605",
    "label": "Sanjango",
    "value": "Sanjango"
  },
  {
    "id": "8101011009",
    "district_id": "810101",
    "label": "Hollo",
    "value": "Hollo"
  },
  {
    "id": "8101012001",
    "district_id": "810101",
    "label": "Tamilouw",
    "value": "Tamilouw"
  },
  {
    "id": "8101012002",
    "district_id": "810101",
    "label": "Sepa",
    "value": "Sepa"
  },
  {
    "id": "8101012003",
    "district_id": "810101",
    "label": "Rutah",
    "value": "Rutah"
  },
  {
    "id": "8101012004",
    "district_id": "810101",
    "label": "Soahuku",
    "value": "Soahuku"
  },
  {
    "id": "8101012005",
    "district_id": "810101",
    "label": "Amahai",
    "value": "Amahai"
  },
  {
    "id": "8101012006",
    "district_id": "810101",
    "label": "Haruru",
    "value": "Haruru"
  },
  {
    "id": "8101012007",
    "district_id": "810101",
    "label": "Sehati",
    "value": "Sehati"
  },
  {
    "id": "8101012008",
    "district_id": "810101",
    "label": "Makariki",
    "value": "Makariki"
  },
  {
    "id": "8101012010",
    "district_id": "810101",
    "label": "Yafila",
    "value": "Yafila"
  },
  {
    "id": "8101012011",
    "district_id": "810101",
    "label": "Banda Baru",
    "value": "Banda Baru"
  },
  {
    "id": "8101012020",
    "district_id": "810101",
    "label": "Yainuelo",
    "value": "Yainuelo"
  },
  {
    "id": "8101012021",
    "district_id": "810101",
    "label": "Nua Nea",
    "value": "Nua Nea"
  },
  {
    "id": "8101012022",
    "district_id": "810101",
    "label": "Hatuhenu",
    "value": "Hatuhenu"
  },
  {
    "id": "8101012023",
    "district_id": "810101",
    "label": "Nuweletetu",
    "value": "Nuweletetu"
  },
  {
    "id": "8101022001",
    "district_id": "810102",
    "label": "Usliapan",
    "value": "Usliapan"
  },
  {
    "id": "8101022002",
    "district_id": "810102",
    "label": "Kuralele",
    "value": "Kuralele"
  },
  {
    "id": "8101022003",
    "district_id": "810102",
    "label": "Kokroman",
    "value": "Kokroman"
  },
  {
    "id": "8101022004",
    "district_id": "810102",
    "label": "Messa",
    "value": "Messa"
  },
  {
    "id": "8101022005",
    "district_id": "810102",
    "label": "Ameth",
    "value": "Ameth"
  },
  {
    "id": "8101022006",
    "district_id": "810102",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "8101022007",
    "district_id": "810102",
    "label": "Bumey",
    "value": "Bumey"
  },
  {
    "id": "8101022008",
    "district_id": "810102",
    "label": "Sifluru",
    "value": "Sifluru"
  },
  {
    "id": "8101022009",
    "district_id": "810102",
    "label": "Layeni",
    "value": "Layeni"
  },
  {
    "id": "8101022010",
    "district_id": "810102",
    "label": "Wotay",
    "value": "Wotay"
  },
  {
    "id": "8101022011",
    "district_id": "810102",
    "label": "Issu",
    "value": "Issu"
  },
  {
    "id": "8101022012",
    "district_id": "810102",
    "label": "Lesluru",
    "value": "Lesluru"
  },
  {
    "id": "8101022013",
    "district_id": "810102",
    "label": "Watludan",
    "value": "Watludan"
  },
  {
    "id": "8101022014",
    "district_id": "810102",
    "label": "Trana",
    "value": "Trana"
  },
  {
    "id": "8101022015",
    "district_id": "810102",
    "label": "Jerili",
    "value": "Jerili"
  },
  {
    "id": "8101022016",
    "district_id": "810102",
    "label": "Nakupia",
    "value": "Nakupia"
  },
  {
    "id": "8101062001",
    "district_id": "810106",
    "label": "Wahai",
    "value": "Wahai"
  },
  {
    "id": "8101062002",
    "district_id": "810106",
    "label": "Air Besar",
    "value": "Air Besar"
  },
  {
    "id": "8101062003",
    "district_id": "810106",
    "label": "Pasahari",
    "value": "Pasahari"
  },
  {
    "id": "8101062007",
    "district_id": "810106",
    "label": "Manusela",
    "value": "Manusela"
  },
  {
    "id": "8101062009",
    "district_id": "810106",
    "label": "Kaloa",
    "value": "Kaloa"
  },
  {
    "id": "8101062010",
    "district_id": "810106",
    "label": "Kanikeh",
    "value": "Kanikeh"
  },
  {
    "id": "8101062011",
    "district_id": "810106",
    "label": "Roho",
    "value": "Roho"
  },
  {
    "id": "8101062012",
    "district_id": "810106",
    "label": "Huaulu",
    "value": "Huaulu"
  },
  {
    "id": "8101062021",
    "district_id": "810106",
    "label": "Rumahsokat",
    "value": "Rumahsokat"
  },
  {
    "id": "8101062022",
    "district_id": "810106",
    "label": "Sawai",
    "value": "Sawai"
  },
  {
    "id": "8101062044",
    "district_id": "810106",
    "label": "Besi",
    "value": "Besi"
  },
  {
    "id": "8101062045",
    "district_id": "810106",
    "label": "Malaku",
    "value": "Malaku"
  },
  {
    "id": "8101062046",
    "district_id": "810106",
    "label": "Maraina",
    "value": "Maraina"
  },
  {
    "id": "8101062047",
    "district_id": "810106",
    "label": "Solea",
    "value": "Solea"
  },
  {
    "id": "8101062048",
    "district_id": "810106",
    "label": "Elemata",
    "value": "Elemata"
  },
  {
    "id": "8101062049",
    "district_id": "810106",
    "label": "Hatuolo",
    "value": "Hatuolo"
  },
  {
    "id": "8101062050",
    "district_id": "810106",
    "label": "Siatele",
    "value": "Siatele"
  },
  {
    "id": "8101062051",
    "district_id": "810106",
    "label": "Masihulan",
    "value": "Masihulan"
  },
  {
    "id": "8101062052",
    "district_id": "810106",
    "label": "Oping",
    "value": "Oping"
  },
  {
    "id": "8101062053",
    "district_id": "810106",
    "label": "Olong",
    "value": "Olong"
  },
  {
    "id": "8101092001",
    "district_id": "810109",
    "label": "Nusantara",
    "value": "Nusantara"
  },
  {
    "id": "8101092002",
    "district_id": "810109",
    "label": "Dwiwarna",
    "value": "Dwiwarna"
  },
  {
    "id": "8101092003",
    "district_id": "810109",
    "label": "Merdeka",
    "value": "Merdeka"
  },
  {
    "id": "8101092004",
    "district_id": "810109",
    "label": "Rajawali",
    "value": "Rajawali"
  },
  {
    "id": "8101092005",
    "district_id": "810109",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8101092006",
    "district_id": "810109",
    "label": "Pulau Hatta",
    "value": "Pulau Hatta"
  },
  {
    "id": "8101092007",
    "district_id": "810109",
    "label": "Selamon",
    "value": "Selamon"
  },
  {
    "id": "8101092008",
    "district_id": "810109",
    "label": "Lonthoir",
    "value": "Lonthoir"
  },
  {
    "id": "8101092009",
    "district_id": "810109",
    "label": "Pulau Ay",
    "value": "Pulau Ay"
  },
  {
    "id": "8101092010",
    "district_id": "810109",
    "label": "Pulau Rhun",
    "value": "Pulau Rhun"
  },
  {
    "id": "8101092011",
    "district_id": "810109",
    "label": "Tanah Rata",
    "value": "Tanah Rata"
  },
  {
    "id": "8101092012",
    "district_id": "810109",
    "label": "Waer",
    "value": "Waer"
  },
  {
    "id": "8101092013",
    "district_id": "810109",
    "label": "Boiyauw",
    "value": "Boiyauw"
  },
  {
    "id": "8101092014",
    "district_id": "810109",
    "label": "Dender",
    "value": "Dender"
  },
  {
    "id": "8101092015",
    "district_id": "810109",
    "label": "Lautang",
    "value": "Lautang"
  },
  {
    "id": "8101092016",
    "district_id": "810109",
    "label": "Uring Tutra",
    "value": "Uring Tutra"
  },
  {
    "id": "8101092017",
    "district_id": "810109",
    "label": "Walling Spanciby",
    "value": "Walling Spanciby"
  },
  {
    "id": "8101092018",
    "district_id": "810109",
    "label": "Combir Kasestoren",
    "value": "Combir Kasestoren"
  },
  {
    "id": "8101112011",
    "district_id": "810111",
    "label": "Telutih Baru",
    "value": "Telutih Baru"
  },
  {
    "id": "8101112012",
    "district_id": "810111",
    "label": "Mosso",
    "value": "Mosso"
  },
  {
    "id": "8101112013",
    "district_id": "810111",
    "label": "Hatumete",
    "value": "Hatumete"
  },
  {
    "id": "8101112014",
    "district_id": "810111",
    "label": "Hatu",
    "value": "Hatu"
  },
  {
    "id": "8101112015",
    "district_id": "810111",
    "label": "Piliana",
    "value": "Piliana"
  },
  {
    "id": "8101112016",
    "district_id": "810111",
    "label": "Yaputih",
    "value": "Yaputih"
  },
  {
    "id": "8101112017",
    "district_id": "810111",
    "label": "Saunulu",
    "value": "Saunulu"
  },
  {
    "id": "8101112018",
    "district_id": "810111",
    "label": "Tehoru",
    "value": "Tehoru"
  },
  {
    "id": "8101112019",
    "district_id": "810111",
    "label": "Haya",
    "value": "Haya"
  },
  {
    "id": "8101112020",
    "district_id": "810111",
    "label": "Salamahu",
    "value": "Salamahu"
  },
  {
    "id": "8101122001",
    "district_id": "810112",
    "label": "Booi",
    "value": "Booi"
  },
  {
    "id": "8101122002",
    "district_id": "810112",
    "label": "Paperu",
    "value": "Paperu"
  },
  {
    "id": "8101122003",
    "district_id": "810112",
    "label": "Tiouw",
    "value": "Tiouw"
  },
  {
    "id": "8101122004",
    "district_id": "810112",
    "label": "Haria",
    "value": "Haria"
  },
  {
    "id": "8101122005",
    "district_id": "810112",
    "label": "Porto",
    "value": "Porto"
  },
  {
    "id": "8101122006",
    "district_id": "810112",
    "label": "Kulur",
    "value": "Kulur"
  },
  {
    "id": "8101122012",
    "district_id": "810112",
    "label": "Saparua",
    "value": "Saparua"
  },
  {
    "id": "8101132001",
    "district_id": "810113",
    "label": "Aboru",
    "value": "Aboru"
  },
  {
    "id": "8101132002",
    "district_id": "810113",
    "label": "Wassu",
    "value": "Wassu"
  },
  {
    "id": "8101132003",
    "district_id": "810113",
    "label": "Oma",
    "value": "Oma"
  },
  {
    "id": "8101132004",
    "district_id": "810113",
    "label": "Haruku",
    "value": "Haruku"
  },
  {
    "id": "8101132005",
    "district_id": "810113",
    "label": "Sameth",
    "value": "Sameth"
  },
  {
    "id": "8101132006",
    "district_id": "810113",
    "label": "Rohomoni",
    "value": "Rohomoni"
  },
  {
    "id": "8101132007",
    "district_id": "810113",
    "label": "Kabauw",
    "value": "Kabauw"
  },
  {
    "id": "8101132008",
    "district_id": "810113",
    "label": "Kailolo",
    "value": "Kailolo"
  },
  {
    "id": "8101132009",
    "district_id": "810113",
    "label": "Pelauw",
    "value": "Pelauw"
  },
  {
    "id": "8101132010",
    "district_id": "810113",
    "label": "Kariu",
    "value": "Kariu"
  },
  {
    "id": "8101132011",
    "district_id": "810113",
    "label": "Hulaliu",
    "value": "Hulaliu"
  },
  {
    "id": "8101142001",
    "district_id": "810114",
    "label": "Liang",
    "value": "Liang"
  },
  {
    "id": "8101142002",
    "district_id": "810114",
    "label": "Waai",
    "value": "Waai"
  },
  {
    "id": "8101142003",
    "district_id": "810114",
    "label": "Tulehu",
    "value": "Tulehu"
  },
  {
    "id": "8101142004",
    "district_id": "810114",
    "label": "Tial",
    "value": "Tial"
  },
  {
    "id": "8101142005",
    "district_id": "810114",
    "label": "Tengah Tengah",
    "value": "Tengah Tengah"
  },
  {
    "id": "8101142006",
    "district_id": "810114",
    "label": "Suli",
    "value": "Suli"
  },
  {
    "id": "8101152006",
    "district_id": "810115",
    "label": "Asilulu",
    "value": "Asilulu"
  },
  {
    "id": "8101152007",
    "district_id": "810115",
    "label": "Ureng",
    "value": "Ureng"
  },
  {
    "id": "8101152008",
    "district_id": "810115",
    "label": "Negeri Lima",
    "value": "Negeri Lima"
  },
  {
    "id": "8101152009",
    "district_id": "810115",
    "label": "Seith",
    "value": "Seith"
  },
  {
    "id": "8101152010",
    "district_id": "810115",
    "label": "Kaitetu",
    "value": "Kaitetu"
  },
  {
    "id": "8101152011",
    "district_id": "810115",
    "label": "Hila",
    "value": "Hila"
  },
  {
    "id": "8101152012",
    "district_id": "810115",
    "label": "Wakal",
    "value": "Wakal"
  },
  {
    "id": "8101152013",
    "district_id": "810115",
    "label": "Hitulama",
    "value": "Hitulama"
  },
  {
    "id": "8101152014",
    "district_id": "810115",
    "label": "Hitumessing",
    "value": "Hitumessing"
  },
  {
    "id": "8101152015",
    "district_id": "810115",
    "label": "Mamala",
    "value": "Mamala"
  },
  {
    "id": "8101152016",
    "district_id": "810115",
    "label": "Morella",
    "value": "Morella"
  },
  {
    "id": "8101162001",
    "district_id": "810116",
    "label": "Ameth",
    "value": "Ameth"
  },
  {
    "id": "8101162002",
    "district_id": "810116",
    "label": "Titawaai",
    "value": "Titawaai"
  },
  {
    "id": "8101162003",
    "district_id": "810116",
    "label": "Abubu",
    "value": "Abubu"
  },
  {
    "id": "8101162004",
    "district_id": "810116",
    "label": "Akoon",
    "value": "Akoon"
  },
  {
    "id": "8101162005",
    "district_id": "810116",
    "label": "Nalahia",
    "value": "Nalahia"
  },
  {
    "id": "8101162006",
    "district_id": "810116",
    "label": "Sila",
    "value": "Sila"
  },
  {
    "id": "8101162007",
    "district_id": "810116",
    "label": "Leinitu",
    "value": "Leinitu"
  },
  {
    "id": "8101171001",
    "district_id": "810117",
    "label": "Namaelo",
    "value": "Namaelo"
  },
  {
    "id": "8101171002",
    "district_id": "810117",
    "label": "Namasina",
    "value": "Namasina"
  },
  {
    "id": "8101171003",
    "district_id": "810117",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "8101171004",
    "district_id": "810117",
    "label": "Lesane",
    "value": "Lesane"
  },
  {
    "id": "8101171005",
    "district_id": "810117",
    "label": "Letwaru",
    "value": "Letwaru"
  },
  {
    "id": "8101202001",
    "district_id": "810120",
    "label": "Saleman",
    "value": "Saleman"
  },
  {
    "id": "8101202002",
    "district_id": "810120",
    "label": "Horale",
    "value": "Horale"
  },
  {
    "id": "8101202003",
    "district_id": "810120",
    "label": "Wailulu",
    "value": "Wailulu"
  },
  {
    "id": "8101202004",
    "district_id": "810120",
    "label": "Paa",
    "value": "Paa"
  },
  {
    "id": "8101202005",
    "district_id": "810120",
    "label": "Karlutu Kara",
    "value": "Karlutu Kara"
  },
  {
    "id": "8101202006",
    "district_id": "810120",
    "label": "Pasanea",
    "value": "Pasanea"
  },
  {
    "id": "8101202007",
    "district_id": "810120",
    "label": "Labuan",
    "value": "Labuan"
  },
  {
    "id": "8101202008",
    "district_id": "810120",
    "label": "Gale-gale",
    "value": "Gale-gale"
  },
  {
    "id": "8101202009",
    "district_id": "810120",
    "label": "Latea",
    "value": "Latea"
  },
  {
    "id": "8101202010",
    "district_id": "810120",
    "label": "Lisabata Timur",
    "value": "Lisabata Timur"
  },
  {
    "id": "8101202011",
    "district_id": "810120",
    "label": "Rumahwey",
    "value": "Rumahwey"
  },
  {
    "id": "8101202012",
    "district_id": "810120",
    "label": "Warasiwa",
    "value": "Warasiwa"
  },
  {
    "id": "8101202013",
    "district_id": "810120",
    "label": "Herlau Pauni",
    "value": "Herlau Pauni"
  },
  {
    "id": "8101212001",
    "district_id": "810121",
    "label": "Waraka",
    "value": "Waraka"
  },
  {
    "id": "8101212002",
    "district_id": "810121",
    "label": "Tananahu",
    "value": "Tananahu"
  },
  {
    "id": "8101212003",
    "district_id": "810121",
    "label": "Liang",
    "value": "Liang"
  },
  {
    "id": "8101212004",
    "district_id": "810121",
    "label": "Sahulauw",
    "value": "Sahulauw"
  },
  {
    "id": "8101222001",
    "district_id": "810122",
    "label": "Larike",
    "value": "Larike"
  },
  {
    "id": "8101222002",
    "district_id": "810122",
    "label": "Wakasihu",
    "value": "Wakasihu"
  },
  {
    "id": "8101222003",
    "district_id": "810122",
    "label": "Allang",
    "value": "Allang"
  },
  {
    "id": "8101222004",
    "district_id": "810122",
    "label": "Liliboy",
    "value": "Liliboy"
  },
  {
    "id": "8101222005",
    "district_id": "810122",
    "label": "Hatu",
    "value": "Hatu"
  },
  {
    "id": "8101232001",
    "district_id": "810123",
    "label": "Ulahahan",
    "value": "Ulahahan"
  },
  {
    "id": "8101232002",
    "district_id": "810123",
    "label": "Laha Kaba",
    "value": "Laha Kaba"
  },
  {
    "id": "8101232003",
    "district_id": "810123",
    "label": "Lafa",
    "value": "Lafa"
  },
  {
    "id": "8101232004",
    "district_id": "810123",
    "label": "Yamalatu",
    "value": "Yamalatu"
  },
  {
    "id": "8101232005",
    "district_id": "810123",
    "label": "Hunisi",
    "value": "Hunisi"
  },
  {
    "id": "8101232006",
    "district_id": "810123",
    "label": "Laimu",
    "value": "Laimu"
  },
  {
    "id": "8101232007",
    "district_id": "810123",
    "label": "Maneoratu",
    "value": "Maneoratu"
  },
  {
    "id": "8101232008",
    "district_id": "810123",
    "label": "Tehua",
    "value": "Tehua"
  },
  {
    "id": "8101232009",
    "district_id": "810123",
    "label": "Laha",
    "value": "Laha"
  },
  {
    "id": "8101232010",
    "district_id": "810123",
    "label": "Wolu",
    "value": "Wolu"
  },
  {
    "id": "8101242001",
    "district_id": "810124",
    "label": "Seti",
    "value": "Seti"
  },
  {
    "id": "8101242002",
    "district_id": "810124",
    "label": "Kobisonta",
    "value": "Kobisonta"
  },
  {
    "id": "8101242003",
    "district_id": "810124",
    "label": "Wailoping",
    "value": "Wailoping"
  },
  {
    "id": "8101242004",
    "district_id": "810124",
    "label": "Tihuana",
    "value": "Tihuana"
  },
  {
    "id": "8101242005",
    "district_id": "810124",
    "label": "Waitila",
    "value": "Waitila"
  },
  {
    "id": "8101242006",
    "district_id": "810124",
    "label": "Waiputih",
    "value": "Waiputih"
  },
  {
    "id": "8101242007",
    "district_id": "810124",
    "label": "Waimusal",
    "value": "Waimusal"
  },
  {
    "id": "8101242008",
    "district_id": "810124",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "8101242009",
    "district_id": "810124",
    "label": "Aketernate",
    "value": "Aketernate"
  },
  {
    "id": "8101242010",
    "district_id": "810124",
    "label": "Namto",
    "value": "Namto"
  },
  {
    "id": "8101242011",
    "district_id": "810124",
    "label": "Loping Mulyo",
    "value": "Loping Mulyo"
  },
  {
    "id": "8101242012",
    "district_id": "810124",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "8101252001",
    "district_id": "810125",
    "label": "Kobi",
    "value": "Kobi"
  },
  {
    "id": "8101252002",
    "district_id": "810125",
    "label": "Sariputih",
    "value": "Sariputih"
  },
  {
    "id": "8101252003",
    "district_id": "810125",
    "label": "Maneo Rendah",
    "value": "Maneo Rendah"
  },
  {
    "id": "8101252004",
    "district_id": "810125",
    "label": "Kobimukti",
    "value": "Kobimukti"
  },
  {
    "id": "8101252005",
    "district_id": "810125",
    "label": "Leaway",
    "value": "Leaway"
  },
  {
    "id": "8101252006",
    "district_id": "810125",
    "label": "Samal",
    "value": "Samal"
  },
  {
    "id": "8101252007",
    "district_id": "810125",
    "label": "Morokai",
    "value": "Morokai"
  },
  {
    "id": "8101252008",
    "district_id": "810125",
    "label": "Waimusi",
    "value": "Waimusi"
  },
  {
    "id": "8101252009",
    "district_id": "810125",
    "label": "Way Asih",
    "value": "Way Asih"
  },
  {
    "id": "8101252010",
    "district_id": "810125",
    "label": "Waitonipa",
    "value": "Waitonipa"
  },
  {
    "id": "8101252011",
    "district_id": "810125",
    "label": "Marasahua",
    "value": "Marasahua"
  },
  {
    "id": "8101252012",
    "district_id": "810125",
    "label": "Kabauhari",
    "value": "Kabauhari"
  },
  {
    "id": "8101262001",
    "district_id": "810126",
    "label": "Ouw",
    "value": "Ouw"
  },
  {
    "id": "8101262002",
    "district_id": "810126",
    "label": "Ullath",
    "value": "Ullath"
  },
  {
    "id": "8101262003",
    "district_id": "810126",
    "label": "Siri Sori Islam",
    "value": "Siri Sori Islam"
  },
  {
    "id": "8101262004",
    "district_id": "810126",
    "label": "Siri Sori Amalatu",
    "value": "Siri Sori Amalatu"
  },
  {
    "id": "8101262005",
    "district_id": "810126",
    "label": "Mahu",
    "value": "Mahu"
  },
  {
    "id": "8101262006",
    "district_id": "810126",
    "label": "Tuhaha",
    "value": "Tuhaha"
  },
  {
    "id": "8101262007",
    "district_id": "810126",
    "label": "Ihamahu",
    "value": "Ihamahu"
  },
  {
    "id": "8101262008",
    "district_id": "810126",
    "label": "Iha",
    "value": "Iha"
  },
  {
    "id": "8101262009",
    "district_id": "810126",
    "label": "Nolloth",
    "value": "Nolloth"
  },
  {
    "id": "8101262010",
    "district_id": "810126",
    "label": "Itawaka",
    "value": "Itawaka"
  },
  {
    "id": "8102011013",
    "district_id": "810201",
    "label": "Ohoijang Watdek",
    "value": "Ohoijang Watdek"
  },
  {
    "id": "8102012014",
    "district_id": "810201",
    "label": "Langgur",
    "value": "Langgur"
  },
  {
    "id": "8102012015",
    "district_id": "810201",
    "label": "Faan",
    "value": "Faan"
  },
  {
    "id": "8102012016",
    "district_id": "810201",
    "label": "Sathean",
    "value": "Sathean"
  },
  {
    "id": "8102012017",
    "district_id": "810201",
    "label": "Ibra",
    "value": "Ibra"
  },
  {
    "id": "8102012018",
    "district_id": "810201",
    "label": "Ngabub",
    "value": "Ngabub"
  },
  {
    "id": "8102012048",
    "district_id": "810201",
    "label": "Ohoidertawun",
    "value": "Ohoidertawun"
  },
  {
    "id": "8102012049",
    "district_id": "810201",
    "label": "Kelanit",
    "value": "Kelanit"
  },
  {
    "id": "8102012051",
    "district_id": "810201",
    "label": "Kolser",
    "value": "Kolser"
  },
  {
    "id": "8102012052",
    "district_id": "810201",
    "label": "Letman",
    "value": "Letman"
  },
  {
    "id": "8102012057",
    "district_id": "810201",
    "label": "Wearlilir",
    "value": "Wearlilir"
  },
  {
    "id": "8102012067",
    "district_id": "810201",
    "label": "Ohoider Atas",
    "value": "Ohoider Atas"
  },
  {
    "id": "8102012068",
    "district_id": "810201",
    "label": "Dudunwahan",
    "value": "Dudunwahan"
  },
  {
    "id": "8102012069",
    "district_id": "810201",
    "label": "Loon",
    "value": "Loon"
  },
  {
    "id": "8102012071",
    "district_id": "810201",
    "label": "Sitniohoi",
    "value": "Sitniohoi"
  },
  {
    "id": "8102032001",
    "district_id": "810203",
    "label": "Werka",
    "value": "Werka"
  },
  {
    "id": "8102032002",
    "district_id": "810203",
    "label": "Waur",
    "value": "Waur"
  },
  {
    "id": "8102032003",
    "district_id": "810203",
    "label": "Ohoinangan",
    "value": "Ohoinangan"
  },
  {
    "id": "8102032004",
    "district_id": "810203",
    "label": "Ler Ohoilim",
    "value": "Ler Ohoilim"
  },
  {
    "id": "8102032005",
    "district_id": "810203",
    "label": "Rahareng",
    "value": "Rahareng"
  },
  {
    "id": "8102032006",
    "district_id": "810203",
    "label": "Elat",
    "value": "Elat"
  },
  {
    "id": "8102032007",
    "district_id": "810203",
    "label": "Depur",
    "value": "Depur"
  },
  {
    "id": "8102032008",
    "district_id": "810203",
    "label": "Ohoilim",
    "value": "Ohoilim"
  },
  {
    "id": "8102032009",
    "district_id": "810203",
    "label": "El Ralang",
    "value": "El Ralang"
  },
  {
    "id": "8102032015",
    "district_id": "810203",
    "label": "Reyamru",
    "value": "Reyamru"
  },
  {
    "id": "8102032016",
    "district_id": "810203",
    "label": "Fako",
    "value": "Fako"
  },
  {
    "id": "8102032017",
    "district_id": "810203",
    "label": "Yamtel",
    "value": "Yamtel"
  },
  {
    "id": "8102032018",
    "district_id": "810203",
    "label": "Waur Tahit",
    "value": "Waur Tahit"
  },
  {
    "id": "8102032019",
    "district_id": "810203",
    "label": "Ngefuit",
    "value": "Ngefuit"
  },
  {
    "id": "8102032020",
    "district_id": "810203",
    "label": "Ohoiel",
    "value": "Ohoiel"
  },
  {
    "id": "8102032021",
    "district_id": "810203",
    "label": "Ohoiwait",
    "value": "Ohoiwait"
  },
  {
    "id": "8102032022",
    "district_id": "810203",
    "label": "Ohoiwang",
    "value": "Ohoiwang"
  },
  {
    "id": "8102032023",
    "district_id": "810203",
    "label": "Fangamas",
    "value": "Fangamas"
  },
  {
    "id": "8102032024",
    "district_id": "810203",
    "label": "Ohoinangan Atas",
    "value": "Ohoinangan Atas"
  },
  {
    "id": "8102032025",
    "district_id": "810203",
    "label": "Harangur",
    "value": "Harangur"
  },
  {
    "id": "8102032026",
    "district_id": "810203",
    "label": "Udar",
    "value": "Udar"
  },
  {
    "id": "8102032027",
    "district_id": "810203",
    "label": "Daftel",
    "value": "Daftel"
  },
  {
    "id": "8102032028",
    "district_id": "810203",
    "label": "Karkarit",
    "value": "Karkarit"
  },
  {
    "id": "8102032029",
    "district_id": "810203",
    "label": "Rahareng Atas",
    "value": "Rahareng Atas"
  },
  {
    "id": "8102032030",
    "district_id": "810203",
    "label": "Wulurat",
    "value": "Wulurat"
  },
  {
    "id": "8102032031",
    "district_id": "810203",
    "label": "Wakol",
    "value": "Wakol"
  },
  {
    "id": "8102032032",
    "district_id": "810203",
    "label": "Ngurdu",
    "value": "Ngurdu"
  },
  {
    "id": "8102032033",
    "district_id": "810203",
    "label": "Soinrat",
    "value": "Soinrat"
  },
  {
    "id": "8102032034",
    "district_id": "810203",
    "label": "Wermaf",
    "value": "Wermaf"
  },
  {
    "id": "8102032035",
    "district_id": "810203",
    "label": "Bombay",
    "value": "Bombay"
  },
  {
    "id": "8102032036",
    "district_id": "810203",
    "label": "Watsin",
    "value": "Watsin"
  },
  {
    "id": "8102032037",
    "district_id": "810203",
    "label": "Sirbante",
    "value": "Sirbante"
  },
  {
    "id": "8102032038",
    "district_id": "810203",
    "label": "Ngat",
    "value": "Ngat"
  },
  {
    "id": "8102032039",
    "district_id": "810203",
    "label": "Nabaheng",
    "value": "Nabaheng"
  },
  {
    "id": "8102032040",
    "district_id": "810203",
    "label": "Ngefuit Atas",
    "value": "Ngefuit Atas"
  },
  {
    "id": "8102032041",
    "district_id": "810203",
    "label": "Watuar",
    "value": "Watuar"
  },
  {
    "id": "8102032042",
    "district_id": "810203",
    "label": "Mataholat",
    "value": "Mataholat"
  },
  {
    "id": "8102042001",
    "district_id": "810204",
    "label": "Weduar",
    "value": "Weduar"
  },
  {
    "id": "8102042003",
    "district_id": "810204",
    "label": "Nerong",
    "value": "Nerong"
  },
  {
    "id": "8102042004",
    "district_id": "810204",
    "label": "Larat",
    "value": "Larat"
  },
  {
    "id": "8102042005",
    "district_id": "810204",
    "label": "Tamangil Nuhuten",
    "value": "Tamangil Nuhuten"
  },
  {
    "id": "8102042006",
    "district_id": "810204",
    "label": "Tamangil Nuhuyanat",
    "value": "Tamangil Nuhuyanat"
  },
  {
    "id": "8102042012",
    "district_id": "810204",
    "label": "Kilwat",
    "value": "Kilwat"
  },
  {
    "id": "8102042013",
    "district_id": "810204",
    "label": "Sather",
    "value": "Sather"
  },
  {
    "id": "8102042014",
    "district_id": "810204",
    "label": "Tutrean",
    "value": "Tutrean"
  },
  {
    "id": "8102042015",
    "district_id": "810204",
    "label": "Ohoirenan",
    "value": "Ohoirenan"
  },
  {
    "id": "8102042016",
    "district_id": "810204",
    "label": "Soindat",
    "value": "Soindat"
  },
  {
    "id": "8102052001",
    "district_id": "810205",
    "label": "Hollat",
    "value": "Hollat"
  },
  {
    "id": "8102052002",
    "district_id": "810205",
    "label": "Ohoiraut",
    "value": "Ohoiraut"
  },
  {
    "id": "8102052003",
    "district_id": "810205",
    "label": "Haar Ohoimel",
    "value": "Haar Ohoimel"
  },
  {
    "id": "8102052004",
    "district_id": "810205",
    "label": "Langgiar Haar",
    "value": "Langgiar Haar"
  },
  {
    "id": "8102052005",
    "district_id": "810205",
    "label": "Banda Eli",
    "value": "Banda Eli"
  },
  {
    "id": "8102052006",
    "district_id": "810205",
    "label": "Watlaar",
    "value": "Watlaar"
  },
  {
    "id": "8102052007",
    "district_id": "810205",
    "label": "Ohoifau",
    "value": "Ohoifau"
  },
  {
    "id": "8102052008",
    "district_id": "810205",
    "label": "Kilwair",
    "value": "Kilwair"
  },
  {
    "id": "8102052009",
    "district_id": "810205",
    "label": "Renfan",
    "value": "Renfan"
  },
  {
    "id": "8102052010",
    "district_id": "810205",
    "label": "Hollat Solair",
    "value": "Hollat Solair"
  },
  {
    "id": "8102052011",
    "district_id": "810205",
    "label": "Hoko",
    "value": "Hoko"
  },
  {
    "id": "8102052012",
    "district_id": "810205",
    "label": "Hollay",
    "value": "Hollay"
  },
  {
    "id": "8102052013",
    "district_id": "810205",
    "label": "Soin",
    "value": "Soin"
  },
  {
    "id": "8102052014",
    "district_id": "810205",
    "label": "Haar Ohoimur GPM",
    "value": "Haar Ohoimur GPM"
  },
  {
    "id": "8102052015",
    "district_id": "810205",
    "label": "Haar Ohoimur RK",
    "value": "Haar Ohoimur RK"
  },
  {
    "id": "8102052016",
    "district_id": "810205",
    "label": "Haar Ohoiwait",
    "value": "Haar Ohoiwait"
  },
  {
    "id": "8102052017",
    "district_id": "810205",
    "label": "Haar Wassar",
    "value": "Haar Wassar"
  },
  {
    "id": "8102052018",
    "district_id": "810205",
    "label": "Haar Renrahantel",
    "value": "Haar Renrahantel"
  },
  { "id": "8102052019", "district_id": "810205", "label": "Ur", "value": "Ur" },
  {
    "id": "8102052020",
    "district_id": "810205",
    "label": "Ohoimajang",
    "value": "Ohoimajang"
  },
  {
    "id": "8102052021",
    "district_id": "810205",
    "label": "Banda Efruan",
    "value": "Banda Efruan"
  },
  {
    "id": "8102052022",
    "district_id": "810205",
    "label": "Banda Suku Tigapuluh",
    "value": "Banda Suku Tigapuluh"
  },
  {
    "id": "8102052023",
    "district_id": "810205",
    "label": "Tuburlay",
    "value": "Tuburlay"
  },
  {
    "id": "8102052024",
    "district_id": "810205",
    "label": "Ohoifaruan",
    "value": "Ohoifaruan"
  },
  {
    "id": "8102052025",
    "district_id": "810205",
    "label": "Ohoiwirin",
    "value": "Ohoiwirin"
  },
  {
    "id": "8102052026",
    "district_id": "810205",
    "label": "Tuburngil",
    "value": "Tuburngil"
  },
  {
    "id": "8102052027",
    "district_id": "810205",
    "label": "Yamtimur",
    "value": "Yamtimur"
  },
  {
    "id": "8102052028",
    "district_id": "810205",
    "label": "Renfaan Islam",
    "value": "Renfaan Islam"
  },
  {
    "id": "8102052029",
    "district_id": "810205",
    "label": "Renfaan GPM",
    "value": "Renfaan GPM"
  },
  {
    "id": "8102052030",
    "district_id": "810205",
    "label": "Fanwav",
    "value": "Fanwav"
  },
  {
    "id": "8102132001",
    "district_id": "810213",
    "label": "Wain",
    "value": "Wain"
  },
  {
    "id": "8102132002",
    "district_id": "810213",
    "label": "Rewav",
    "value": "Rewav"
  },
  {
    "id": "8102132003",
    "district_id": "810213",
    "label": "Rumaat",
    "value": "Rumaat"
  },
  {
    "id": "8102132004",
    "district_id": "810213",
    "label": "Rat",
    "value": "Rat"
  },
  {
    "id": "8102132005",
    "district_id": "810213",
    "label": "Abean",
    "value": "Abean"
  },
  {
    "id": "8102132006",
    "district_id": "810213",
    "label": "Mastur",
    "value": "Mastur"
  },
  {
    "id": "8102132013",
    "district_id": "810213",
    "label": "Ohoinol",
    "value": "Ohoinol"
  },
  {
    "id": "8102132014",
    "district_id": "810213",
    "label": "Semawi",
    "value": "Semawi"
  },
  {
    "id": "8102132015",
    "district_id": "810213",
    "label": "Iso",
    "value": "Iso"
  },
  {
    "id": "8102132016",
    "district_id": "810213",
    "label": "Disuk",
    "value": "Disuk"
  },
  {
    "id": "8102132017",
    "district_id": "810213",
    "label": "Wain Baru",
    "value": "Wain Baru"
  },
  {
    "id": "8102132018",
    "district_id": "810213",
    "label": "Marfun",
    "value": "Marfun"
  },
  {
    "id": "8102132019",
    "district_id": "810213",
    "label": "Watngon",
    "value": "Watngon"
  },
  {
    "id": "8102132020",
    "district_id": "810213",
    "label": "Yafavun",
    "value": "Yafavun"
  },
  {
    "id": "8102132021",
    "district_id": "810213",
    "label": "Mastur Baru",
    "value": "Mastur Baru"
  },
  {
    "id": "8102132022",
    "district_id": "810213",
    "label": "Ohoilus",
    "value": "Ohoilus"
  },
  {
    "id": "8102132023",
    "district_id": "810213",
    "label": "Tenbuk",
    "value": "Tenbuk"
  },
  {
    "id": "8102132024",
    "district_id": "810213",
    "label": "Denwet",
    "value": "Denwet"
  },
  {
    "id": "8102142001",
    "district_id": "810214",
    "label": "Ohoidertutu",
    "value": "Ohoidertutu"
  },
  {
    "id": "8102142002",
    "district_id": "810214",
    "label": "Matwair",
    "value": "Matwair"
  },
  {
    "id": "8102142003",
    "district_id": "810214",
    "label": "Somlain",
    "value": "Somlain"
  },
  {
    "id": "8102142004",
    "district_id": "810214",
    "label": "Ohoiren",
    "value": "Ohoiren"
  },
  {
    "id": "8102142005",
    "district_id": "810214",
    "label": "Ohoira",
    "value": "Ohoira"
  },
  {
    "id": "8102142006",
    "district_id": "810214",
    "label": "Warbal",
    "value": "Warbal"
  },
  {
    "id": "8102142007",
    "district_id": "810214",
    "label": "Ur Pulau",
    "value": "Ur Pulau"
  },
  {
    "id": "8102142008",
    "district_id": "810214",
    "label": "Tanimbar Kei",
    "value": "Tanimbar Kei"
  },
  {
    "id": "8102142009",
    "district_id": "810214",
    "label": "Ohoidertom",
    "value": "Ohoidertom"
  },
  {
    "id": "8102142010",
    "district_id": "810214",
    "label": "Yatwav",
    "value": "Yatwav"
  },
  {
    "id": "8102152001",
    "district_id": "810215",
    "label": "Rumadian",
    "value": "Rumadian"
  },
  {
    "id": "8102152002",
    "district_id": "810215",
    "label": "Debut",
    "value": "Debut"
  },
  {
    "id": "8102152003",
    "district_id": "810215",
    "label": "Namar",
    "value": "Namar"
  },
  {
    "id": "8102152004",
    "district_id": "810215",
    "label": "Ngilngof",
    "value": "Ngilngof"
  },
  {
    "id": "8102152005",
    "district_id": "810215",
    "label": "Selayar",
    "value": "Selayar"
  },
  {
    "id": "8102152006",
    "district_id": "810215",
    "label": "Lairngangas",
    "value": "Lairngangas"
  },
  {
    "id": "8102152007",
    "district_id": "810215",
    "label": "Ohoiluk",
    "value": "Ohoiluk"
  },
  {
    "id": "8102152008",
    "district_id": "810215",
    "label": "Ohoililir",
    "value": "Ohoililir"
  },
  {
    "id": "8102152009",
    "district_id": "810215",
    "label": "Ngayub",
    "value": "Ngayub"
  },
  {
    "id": "8102162001",
    "district_id": "810216",
    "label": "Tetoat",
    "value": "Tetoat"
  },
  {
    "id": "8102162002",
    "district_id": "810216",
    "label": "Dian Pulau",
    "value": "Dian Pulau"
  },
  {
    "id": "8102162003",
    "district_id": "810216",
    "label": "Letwuan",
    "value": "Letwuan"
  },
  {
    "id": "8102162004",
    "district_id": "810216",
    "label": "Evu",
    "value": "Evu"
  },
  {
    "id": "8102162005",
    "district_id": "810216",
    "label": "Warwut",
    "value": "Warwut"
  },
  {
    "id": "8102162006",
    "district_id": "810216",
    "label": "Wab",
    "value": "Wab"
  },
  {
    "id": "8102162007",
    "district_id": "810216",
    "label": "Dian Darat",
    "value": "Dian Darat"
  },
  {
    "id": "8102162008",
    "district_id": "810216",
    "label": "Wirin",
    "value": "Wirin"
  },
  {
    "id": "8102162009",
    "district_id": "810216",
    "label": "Ngursit",
    "value": "Ngursit"
  },
  {
    "id": "8102162010",
    "district_id": "810216",
    "label": "Madwat",
    "value": "Madwat"
  },
  {
    "id": "8102162011",
    "district_id": "810216",
    "label": "Ohoibadar",
    "value": "Ohoibadar"
  },
  {
    "id": "8102162012",
    "district_id": "810216",
    "label": "Watngil",
    "value": "Watngil"
  },
  {
    "id": "8102162013",
    "district_id": "810216",
    "label": "Arso",
    "value": "Arso"
  },
  {
    "id": "8102172001",
    "district_id": "810217",
    "label": "Weer Ohoinam",
    "value": "Weer Ohoinam"
  },
  {
    "id": "8102172002",
    "district_id": "810217",
    "label": "Weer Ohoiker",
    "value": "Weer Ohoiker"
  },
  {
    "id": "8102172003",
    "district_id": "810217",
    "label": "Weer Frawaf",
    "value": "Weer Frawaf"
  },
  {
    "id": "8102172004",
    "district_id": "810217",
    "label": "Faa",
    "value": "Faa"
  },
  {
    "id": "8102172005",
    "district_id": "810217",
    "label": "Dangarat",
    "value": "Dangarat"
  },
  {
    "id": "8102172006",
    "district_id": "810217",
    "label": "Ohoituf",
    "value": "Ohoituf"
  },
  {
    "id": "8102172007",
    "district_id": "810217",
    "label": "Hangur",
    "value": "Hangur"
  },
  {
    "id": "8102172008",
    "district_id": "810217",
    "label": "Ngurwalek",
    "value": "Ngurwalek"
  },
  {
    "id": "8102172009",
    "district_id": "810217",
    "label": "Uwat",
    "value": "Uwat"
  },
  {
    "id": "8102172010",
    "district_id": "810217",
    "label": "Uwat Wear",
    "value": "Uwat Wear"
  },
  {
    "id": "8102172011",
    "district_id": "810217",
    "label": "Uwat Reyaan",
    "value": "Uwat Reyaan"
  },
  {
    "id": "8102172012",
    "district_id": "810217",
    "label": "Mun Ohoiir",
    "value": "Mun Ohoiir"
  },
  {
    "id": "8102172013",
    "district_id": "810217",
    "label": "Mun Essoy",
    "value": "Mun Essoy"
  },
  {
    "id": "8102172014",
    "district_id": "810217",
    "label": "Mun Kahar",
    "value": "Mun Kahar"
  },
  {
    "id": "8102172015",
    "district_id": "810217",
    "label": "Mun Ngurditwain",
    "value": "Mun Ngurditwain"
  },
  {
    "id": "8102172016",
    "district_id": "810217",
    "label": "Mun Werfan",
    "value": "Mun Werfan"
  },
  {
    "id": "8102172017",
    "district_id": "810217",
    "label": "Ad Ngurwul",
    "value": "Ad Ngurwul"
  },
  {
    "id": "8102172018",
    "district_id": "810217",
    "label": "Ad Ohoiwaf",
    "value": "Ad Ohoiwaf"
  },
  {
    "id": "8102172019",
    "district_id": "810217",
    "label": "Laar",
    "value": "Laar"
  },
  {
    "id": "8102172020",
    "district_id": "810217",
    "label": "Hoor Islam",
    "value": "Hoor Islam"
  },
  {
    "id": "8102172021",
    "district_id": "810217",
    "label": "Hoor Kristen",
    "value": "Hoor Kristen"
  },
  {
    "id": "8102172022",
    "district_id": "810217",
    "label": "Waer",
    "value": "Waer"
  },
  {
    "id": "8102172023",
    "district_id": "810217",
    "label": "Waerat",
    "value": "Waerat"
  },
  {
    "id": "8102172024",
    "district_id": "810217",
    "label": "Mun Ohoitadiun",
    "value": "Mun Ohoitadiun"
  },
  {
    "id": "8102172025",
    "district_id": "810217",
    "label": "Ad Wear Aur",
    "value": "Ad Wear Aur"
  },
  {
    "id": "8102182001",
    "district_id": "810218",
    "label": "Hoat",
    "value": "Hoat"
  },
  {
    "id": "8102182002",
    "district_id": "810218",
    "label": "Ngafan",
    "value": "Ngafan"
  },
  {
    "id": "8102182003",
    "district_id": "810218",
    "label": "Feer",
    "value": "Feer"
  },
  {
    "id": "8102182004",
    "district_id": "810218",
    "label": "Rerean",
    "value": "Rerean"
  },
  {
    "id": "8102182005",
    "district_id": "810218",
    "label": "Ngurko",
    "value": "Ngurko"
  },
  {
    "id": "8102182006",
    "district_id": "810218",
    "label": "Hoko",
    "value": "Hoko"
  },
  {
    "id": "8102182007",
    "district_id": "810218",
    "label": "Weduar Fer",
    "value": "Weduar Fer"
  },
  {
    "id": "8102182008",
    "district_id": "810218",
    "label": "Uat",
    "value": "Uat"
  },
  {
    "id": "8102182009",
    "district_id": "810218",
    "label": "Ngan",
    "value": "Ngan"
  },
  {
    "id": "8102182010",
    "district_id": "810218",
    "label": "Watkidat",
    "value": "Watkidat"
  },
  {
    "id": "8102182011",
    "district_id": "810218",
    "label": "Ohoilean",
    "value": "Ohoilean"
  },
  {
    "id": "8102182012",
    "district_id": "810218",
    "label": "Wafol",
    "value": "Wafol"
  },
  {
    "id": "8102182013",
    "district_id": "810218",
    "label": "Rahangiar",
    "value": "Rahangiar"
  },
  {
    "id": "8102192001",
    "district_id": "810219",
    "label": "Danar Ohoiseb",
    "value": "Danar Ohoiseb"
  },
  {
    "id": "8102192002",
    "district_id": "810219",
    "label": "Danar Ternate",
    "value": "Danar Ternate"
  },
  {
    "id": "8102192003",
    "district_id": "810219",
    "label": "Danar Lumefar",
    "value": "Danar Lumefar"
  },
  {
    "id": "8102192004",
    "district_id": "810219",
    "label": "Elaar Ngursoin",
    "value": "Elaar Ngursoin"
  },
  {
    "id": "8102192005",
    "district_id": "810219",
    "label": "Elaar Lamagorang",
    "value": "Elaar Lamagorang"
  },
  {
    "id": "8102192006",
    "district_id": "810219",
    "label": "Elaar Let",
    "value": "Elaar Let"
  },
  {
    "id": "8102192007",
    "district_id": "810219",
    "label": "Sare",
    "value": "Sare"
  },
  {
    "id": "8102192008",
    "district_id": "810219",
    "label": "Maar",
    "value": "Maar"
  },
  { "id": "8102192009", "district_id": "810219", "label": "Uf", "value": "Uf" },
  {
    "id": "8102192010",
    "district_id": "810219",
    "label": "Ngurwul",
    "value": "Ngurwul"
  },
  {
    "id": "8102192011",
    "district_id": "810219",
    "label": "Garara",
    "value": "Garara"
  },
  {
    "id": "8103011001",
    "district_id": "810301",
    "label": "Saumlaki",
    "value": "Saumlaki"
  },
  {
    "id": "8103011002",
    "district_id": "810301",
    "label": "Saumlaki Utara",
    "value": "Saumlaki Utara"
  },
  {
    "id": "8103012002",
    "district_id": "810301",
    "label": "Wowonda",
    "value": "Wowonda"
  },
  {
    "id": "8103012003",
    "district_id": "810301",
    "label": "Ilngei",
    "value": "Ilngei"
  },
  {
    "id": "8103012004",
    "district_id": "810301",
    "label": "Kabiarat",
    "value": "Kabiarat"
  },
  {
    "id": "8103012005",
    "district_id": "810301",
    "label": "Lauran",
    "value": "Lauran"
  },
  {
    "id": "8103012006",
    "district_id": "810301",
    "label": "Sifnana",
    "value": "Sifnana"
  },
  {
    "id": "8103012007",
    "district_id": "810301",
    "label": "Olilit Raya",
    "value": "Olilit Raya"
  },
  {
    "id": "8103012008",
    "district_id": "810301",
    "label": "Lermatang",
    "value": "Lermatang"
  },
  {
    "id": "8103012009",
    "district_id": "810301",
    "label": "Latdalam",
    "value": "Latdalam"
  },
  {
    "id": "8103012010",
    "district_id": "810301",
    "label": "Bomaki",
    "value": "Bomaki"
  },
  {
    "id": "8103012011",
    "district_id": "810301",
    "label": "Matakus",
    "value": "Matakus"
  },
  {
    "id": "8103022001",
    "district_id": "810302",
    "label": "Adaut",
    "value": "Adaut"
  },
  {
    "id": "8103022002",
    "district_id": "810302",
    "label": "Namtabung",
    "value": "Namtabung"
  },
  {
    "id": "8103022003",
    "district_id": "810302",
    "label": "Kandar",
    "value": "Kandar"
  },
  {
    "id": "8103022004",
    "district_id": "810302",
    "label": "Lingat",
    "value": "Lingat"
  },
  {
    "id": "8103022005",
    "district_id": "810302",
    "label": "Fursuy",
    "value": "Fursuy"
  },
  {
    "id": "8103022006",
    "district_id": "810302",
    "label": "Werain",
    "value": "Werain"
  },
  {
    "id": "8103022007",
    "district_id": "810302",
    "label": "Eliasa",
    "value": "Eliasa"
  },
  {
    "id": "8103032001",
    "district_id": "810303",
    "label": "Tumbur",
    "value": "Tumbur"
  },
  {
    "id": "8103032002",
    "district_id": "810303",
    "label": "Lorulun",
    "value": "Lorulun"
  },
  {
    "id": "8103032003",
    "district_id": "810303",
    "label": "Atubul Dol",
    "value": "Atubul Dol"
  },
  {
    "id": "8103032004",
    "district_id": "810303",
    "label": "Amdasa",
    "value": "Amdasa"
  },
  {
    "id": "8103032005",
    "district_id": "810303",
    "label": "Sangliat Krawain",
    "value": "Sangliat Krawain"
  },
  {
    "id": "8103032006",
    "district_id": "810303",
    "label": "Arui Bab",
    "value": "Arui Bab"
  },
  {
    "id": "8103032007",
    "district_id": "810303",
    "label": "Arui Das",
    "value": "Arui Das"
  },
  {
    "id": "8103032008",
    "district_id": "810303",
    "label": "Sangliat Dol",
    "value": "Sangliat Dol"
  },
  {
    "id": "8103032009",
    "district_id": "810303",
    "label": "Atubul Da",
    "value": "Atubul Da"
  },
  {
    "id": "8103042001",
    "district_id": "810304",
    "label": "Kamatubun",
    "value": "Kamatubun"
  },
  {
    "id": "8103042002",
    "district_id": "810304",
    "label": "Rumahsalut",
    "value": "Rumahsalut"
  },
  {
    "id": "8103042003",
    "district_id": "810304",
    "label": "Welutu",
    "value": "Welutu"
  },
  {
    "id": "8103042004",
    "district_id": "810304",
    "label": "Themin",
    "value": "Themin"
  },
  {
    "id": "8103042005",
    "district_id": "810304",
    "label": "Weratan",
    "value": "Weratan"
  },
  {
    "id": "8103042006",
    "district_id": "810304",
    "label": "Wermatang",
    "value": "Wermatang"
  },
  {
    "id": "8103042007",
    "district_id": "810304",
    "label": "Batu Putih",
    "value": "Batu Putih"
  },
  {
    "id": "8103042008",
    "district_id": "810304",
    "label": "Makatian",
    "value": "Makatian"
  },
  {
    "id": "8103042009",
    "district_id": "810304",
    "label": "Marantutul",
    "value": "Marantutul"
  },
  {
    "id": "8103052001",
    "district_id": "810305",
    "label": "Lelingluan",
    "value": "Lelingluan"
  },
  {
    "id": "8103052002",
    "district_id": "810305",
    "label": "Ritabel",
    "value": "Ritabel"
  },
  {
    "id": "8103052003",
    "district_id": "810305",
    "label": "Ridool",
    "value": "Ridool"
  },
  {
    "id": "8103052004",
    "district_id": "810305",
    "label": "Watidal",
    "value": "Watidal"
  },
  {
    "id": "8103052005",
    "district_id": "810305",
    "label": "Kilobar",
    "value": "Kilobar"
  },
  {
    "id": "8103052006",
    "district_id": "810305",
    "label": "Kelaan",
    "value": "Kelaan"
  },
  {
    "id": "8103052007",
    "district_id": "810305",
    "label": "Lamdesar Barat",
    "value": "Lamdesar Barat"
  },
  {
    "id": "8103052008",
    "district_id": "810305",
    "label": "Lamdesar Timur",
    "value": "Lamdesar Timur"
  },
  {
    "id": "8103062001",
    "district_id": "810306",
    "label": "Romean",
    "value": "Romean"
  },
  {
    "id": "8103062002",
    "district_id": "810306",
    "label": "Rumngeur",
    "value": "Rumngeur"
  },
  {
    "id": "8103062003",
    "district_id": "810306",
    "label": "Awear",
    "value": "Awear"
  },
  {
    "id": "8103062004",
    "district_id": "810306",
    "label": "Sofyanin",
    "value": "Sofyanin"
  },
  {
    "id": "8103062005",
    "district_id": "810306",
    "label": "Walerang",
    "value": "Walerang"
  },
  {
    "id": "8103062006",
    "district_id": "810306",
    "label": "Adodo Fordata",
    "value": "Adodo Fordata"
  },
  {
    "id": "8103072001",
    "district_id": "810307",
    "label": "Abat",
    "value": "Abat"
  },
  {
    "id": "8103072002",
    "district_id": "810307",
    "label": "Labobar",
    "value": "Labobar"
  },
  {
    "id": "8103072008",
    "district_id": "810307",
    "label": "Watmasa",
    "value": "Watmasa"
  },
  {
    "id": "8103072009",
    "district_id": "810307",
    "label": "Awear Rumngeur",
    "value": "Awear Rumngeur"
  },
  {
    "id": "8103072010",
    "district_id": "810307",
    "label": "Karatat",
    "value": "Karatat"
  },
  {
    "id": "8103072011",
    "district_id": "810307",
    "label": "Wunlah",
    "value": "Wunlah"
  },
  {
    "id": "8103072012",
    "district_id": "810307",
    "label": "Kiloon",
    "value": "Kiloon"
  },
  {
    "id": "8103072013",
    "district_id": "810307",
    "label": "Lingada",
    "value": "Lingada"
  },
  {
    "id": "8103072014",
    "district_id": "810307",
    "label": "Teineman",
    "value": "Teineman"
  },
  {
    "id": "8103072015",
    "district_id": "810307",
    "label": "Wabar",
    "value": "Wabar"
  },
  {
    "id": "8103072016",
    "district_id": "810307",
    "label": "Romnus",
    "value": "Romnus"
  },
  {
    "id": "8103082001",
    "district_id": "810308",
    "label": "Lumasebu",
    "value": "Lumasebu"
  },
  {
    "id": "8103082002",
    "district_id": "810308",
    "label": "Kilmasa",
    "value": "Kilmasa"
  },
  {
    "id": "8103082003",
    "district_id": "810308",
    "label": "Meyano Das",
    "value": "Meyano Das"
  },
  {
    "id": "8103082004",
    "district_id": "810308",
    "label": "Alusi Krawain",
    "value": "Alusi Krawain"
  },
  {
    "id": "8103082005",
    "district_id": "810308",
    "label": "Alusi Kelaan",
    "value": "Alusi Kelaan"
  },
  {
    "id": "8103082006",
    "district_id": "810308",
    "label": "Alusi Bukjalim",
    "value": "Alusi Bukjalim"
  },
  {
    "id": "8103082007",
    "district_id": "810308",
    "label": "Alusi Tamrian",
    "value": "Alusi Tamrian"
  },
  {
    "id": "8103082008",
    "district_id": "810308",
    "label": "Alusi Batjasi",
    "value": "Alusi Batjasi"
  },
  {
    "id": "8103082009",
    "district_id": "810308",
    "label": "Lorwembun",
    "value": "Lorwembun"
  },
  {
    "id": "8103082010",
    "district_id": "810308",
    "label": "Meyano Das",
    "value": "Meyano Das"
  },
  {
    "id": "8103092001",
    "district_id": "810309",
    "label": "Arma",
    "value": "Arma"
  },
  {
    "id": "8103092002",
    "district_id": "810309",
    "label": "Watmuri",
    "value": "Watmuri"
  },
  {
    "id": "8103092003",
    "district_id": "810309",
    "label": "Manglusi",
    "value": "Manglusi"
  },
  {
    "id": "8103092004",
    "district_id": "810309",
    "label": "Tutukembong",
    "value": "Tutukembong"
  },
  {
    "id": "8103092005",
    "district_id": "810309",
    "label": "Waturu",
    "value": "Waturu"
  },
  {
    "id": "8103182001",
    "district_id": "810318",
    "label": "Wadankou",
    "value": "Wadankou"
  },
  {
    "id": "8103182002",
    "district_id": "810318",
    "label": "Adodo Molu",
    "value": "Adodo Molu"
  },
  {
    "id": "8103182003",
    "district_id": "810318",
    "label": "Wulmasa",
    "value": "Wulmasa"
  },
  {
    "id": "8103182004",
    "district_id": "810318",
    "label": "Tutunametal",
    "value": "Tutunametal"
  },
  {
    "id": "8103182005",
    "district_id": "810318",
    "label": "Nurkat",
    "value": "Nurkat"
  },
  {
    "id": "8104012001",
    "district_id": "810401",
    "label": "Namlea",
    "value": "Namlea"
  },
  {
    "id": "8104012002",
    "district_id": "810401",
    "label": "Lala",
    "value": "Lala"
  },
  {
    "id": "8104012003",
    "district_id": "810401",
    "label": "Karang Jaya",
    "value": "Karang Jaya"
  },
  {
    "id": "8104012009",
    "district_id": "810401",
    "label": "Siahoni",
    "value": "Siahoni"
  },
  {
    "id": "8104012010",
    "district_id": "810401",
    "label": "Jamilu",
    "value": "Jamilu"
  },
  {
    "id": "8104012011",
    "district_id": "810401",
    "label": "Sanleko",
    "value": "Sanleko"
  },
  {
    "id": "8104012012",
    "district_id": "810401",
    "label": "Batu boy",
    "value": "Batu boy"
  },
  {
    "id": "8104022004",
    "district_id": "810402",
    "label": "Waepure",
    "value": "Waepure"
  },
  {
    "id": "8104022005",
    "district_id": "810402",
    "label": "Waemangit",
    "value": "Waemangit"
  },
  {
    "id": "8104022006",
    "district_id": "810402",
    "label": "Air Buaya",
    "value": "Air Buaya"
  },
  {
    "id": "8104022007",
    "district_id": "810402",
    "label": "Tanjung Karang",
    "value": "Tanjung Karang"
  },
  {
    "id": "8104022008",
    "district_id": "810402",
    "label": "Bara",
    "value": "Bara"
  },
  {
    "id": "8104022014",
    "district_id": "810402",
    "label": "Selwadu",
    "value": "Selwadu"
  },
  {
    "id": "8104022018",
    "district_id": "810402",
    "label": "Wasbaka",
    "value": "Wasbaka"
  },
  {
    "id": "8104022019",
    "district_id": "810402",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8104022020",
    "district_id": "810402",
    "label": "Batlale",
    "value": "Batlale"
  },
  {
    "id": "8104022023",
    "district_id": "810402",
    "label": "Awilinan",
    "value": "Awilinan"
  },
  {
    "id": "8104032001",
    "district_id": "810403",
    "label": "Savana Jaya",
    "value": "Savana Jaya"
  },
  {
    "id": "8104032002",
    "district_id": "810403",
    "label": "Waetele",
    "value": "Waetele"
  },
  {
    "id": "8104032003",
    "district_id": "810403",
    "label": "Waekasar",
    "value": "Waekasar"
  },
  {
    "id": "8104032004",
    "district_id": "810403",
    "label": "Waenetat",
    "value": "Waenetat"
  },
  {
    "id": "8104032016",
    "district_id": "810403",
    "label": "Waekerta",
    "value": "Waekerta"
  },
  {
    "id": "8104032019",
    "district_id": "810403",
    "label": "Wanareja",
    "value": "Wanareja"
  },
  {
    "id": "8104032027",
    "district_id": "810403",
    "label": "Gogorea",
    "value": "Gogorea"
  },
  {
    "id": "8104062001",
    "district_id": "810406",
    "label": "Lamahang",
    "value": "Lamahang"
  },
  {
    "id": "8104062002",
    "district_id": "810406",
    "label": "Waplau",
    "value": "Waplau"
  },
  {
    "id": "8104062003",
    "district_id": "810406",
    "label": "Waeura",
    "value": "Waeura"
  },
  {
    "id": "8104062004",
    "district_id": "810406",
    "label": "Samalagi",
    "value": "Samalagi"
  },
  {
    "id": "8104062005",
    "district_id": "810406",
    "label": "Namsina",
    "value": "Namsina"
  },
  {
    "id": "8104062006",
    "district_id": "810406",
    "label": "Hatawano",
    "value": "Hatawano"
  },
  {
    "id": "8104062007",
    "district_id": "810406",
    "label": "Waelihang",
    "value": "Waelihang"
  },
  {
    "id": "8104062008",
    "district_id": "810406",
    "label": "Waprea",
    "value": "Waprea"
  },
  {
    "id": "8104062009",
    "district_id": "810406",
    "label": "Waepotih",
    "value": "Waepotih"
  },
  {
    "id": "8104062010",
    "district_id": "810406",
    "label": "Skikilale",
    "value": "Skikilale"
  },
  {
    "id": "8104102001",
    "district_id": "810410",
    "label": "Ilath",
    "value": "Ilath"
  },
  {
    "id": "8104102002",
    "district_id": "810410",
    "label": "Batu Jungku",
    "value": "Batu Jungku"
  },
  {
    "id": "8104102003",
    "district_id": "810410",
    "label": "Pela",
    "value": "Pela"
  },
  {
    "id": "8104102004",
    "district_id": "810410",
    "label": "Waemorat",
    "value": "Waemorat"
  },
  {
    "id": "8104102006",
    "district_id": "810410",
    "label": "Namlea Ilath",
    "value": "Namlea Ilath"
  },
  {
    "id": "8104112001",
    "district_id": "810411",
    "label": "Kubalahin",
    "value": "Kubalahin"
  },
  {
    "id": "8104112002",
    "district_id": "810411",
    "label": "Waegeren",
    "value": "Waegeren"
  },
  {
    "id": "8104112003",
    "district_id": "810411",
    "label": "Grandeng",
    "value": "Grandeng"
  },
  {
    "id": "8104112004",
    "district_id": "810411",
    "label": "Lele",
    "value": "Lele"
  },
  {
    "id": "8104112005",
    "district_id": "810411",
    "label": "Wanakarta",
    "value": "Wanakarta"
  },
  {
    "id": "8104112006",
    "district_id": "810411",
    "label": "Wabloy",
    "value": "Wabloy"
  },
  {
    "id": "8104112007",
    "district_id": "810411",
    "label": "Ohilahin",
    "value": "Ohilahin"
  },
  {
    "id": "8104112008",
    "district_id": "810411",
    "label": "Tifu",
    "value": "Tifu"
  },
  {
    "id": "8104112009",
    "district_id": "810411",
    "label": "Wapsalit",
    "value": "Wapsalit"
  },
  {
    "id": "8104112010",
    "district_id": "810411",
    "label": "Nafrua",
    "value": "Nafrua"
  },
  {
    "id": "8104122001",
    "district_id": "810412",
    "label": "Waelo",
    "value": "Waelo"
  },
  {
    "id": "8104122002",
    "district_id": "810412",
    "label": "Debowae",
    "value": "Debowae"
  },
  {
    "id": "8104122003",
    "district_id": "810412",
    "label": "Parbulu",
    "value": "Parbulu"
  },
  {
    "id": "8104122004",
    "district_id": "810412",
    "label": "Waetina",
    "value": "Waetina"
  },
  {
    "id": "8104122005",
    "district_id": "810412",
    "label": "Waeleman",
    "value": "Waeleman"
  },
  {
    "id": "8104122006",
    "district_id": "810412",
    "label": "Basalale",
    "value": "Basalale"
  },
  {
    "id": "8104122007",
    "district_id": "810412",
    "label": "Waflan",
    "value": "Waflan"
  },
  {
    "id": "8104122008",
    "district_id": "810412",
    "label": "Dava",
    "value": "Dava"
  },
  {
    "id": "8104122009",
    "district_id": "810412",
    "label": "Waehata",
    "value": "Waehata"
  },
  {
    "id": "8104122010",
    "district_id": "810412",
    "label": "Widit",
    "value": "Widit"
  },
  {
    "id": "8104132001",
    "district_id": "810413",
    "label": "Wamlana",
    "value": "Wamlana"
  },
  {
    "id": "8104132002",
    "district_id": "810413",
    "label": "Balbalu",
    "value": "Balbalu"
  },
  {
    "id": "8104132003",
    "district_id": "810413",
    "label": "Waspait",
    "value": "Waspait"
  },
  {
    "id": "8104132004",
    "district_id": "810413",
    "label": "Waedanga",
    "value": "Waedanga"
  },
  {
    "id": "8104132005",
    "district_id": "810413",
    "label": "Lemanpoli",
    "value": "Lemanpoli"
  },
  {
    "id": "8104132006",
    "district_id": "810413",
    "label": "Waelana-lana",
    "value": "Waelana-lana"
  },
  {
    "id": "8104132007",
    "district_id": "810413",
    "label": "Waereman",
    "value": "Waereman"
  },
  {
    "id": "8104132008",
    "district_id": "810413",
    "label": "Waemite",
    "value": "Waemite"
  },
  {
    "id": "8104132009",
    "district_id": "810413",
    "label": "Wasi",
    "value": "Wasi"
  },
  {
    "id": "8104132010",
    "district_id": "810413",
    "label": "Waekose",
    "value": "Waekose"
  },
  {
    "id": "8104132011",
    "district_id": "810413",
    "label": "Wamana Baru",
    "value": "Wamana Baru"
  },
  {
    "id": "8104132012",
    "district_id": "810413",
    "label": "Raheriat",
    "value": "Raheriat"
  },
  {
    "id": "8104132013",
    "district_id": "810413",
    "label": "Waenibe",
    "value": "Waenibe"
  },
  {
    "id": "8104142001",
    "district_id": "810414",
    "label": "Kaiely",
    "value": "Kaiely"
  },
  {
    "id": "8104142002",
    "district_id": "810414",
    "label": "Masarete",
    "value": "Masarete"
  },
  {
    "id": "8104142003",
    "district_id": "810414",
    "label": "Waelapia",
    "value": "Waelapia"
  },
  {
    "id": "8104142004",
    "district_id": "810414",
    "label": "Kaki Air",
    "value": "Kaki Air"
  },
  {
    "id": "8104142005",
    "district_id": "810414",
    "label": "Seith",
    "value": "Seith"
  },
  {
    "id": "8104152001",
    "district_id": "810415",
    "label": "Ubung",
    "value": "Ubung"
  },
  {
    "id": "8104152002",
    "district_id": "810415",
    "label": "Jikumerasa",
    "value": "Jikumerasa"
  },
  {
    "id": "8104152003",
    "district_id": "810415",
    "label": "Waemiting",
    "value": "Waemiting"
  },
  {
    "id": "8104152004",
    "district_id": "810415",
    "label": "Sawa",
    "value": "Sawa"
  },
  {
    "id": "8104152005",
    "district_id": "810415",
    "label": "Waeperang",
    "value": "Waeperang"
  },
  {
    "id": "8105012003",
    "district_id": "810501",
    "label": "Bula",
    "value": "Bula"
  },
  {
    "id": "8105012004",
    "district_id": "810501",
    "label": "Salas",
    "value": "Salas"
  },
  {
    "id": "8105012010",
    "district_id": "810501",
    "label": "Englas",
    "value": "Englas"
  },
  {
    "id": "8105012011",
    "district_id": "810501",
    "label": "Bula Air Fatolo",
    "value": "Bula Air Fatolo"
  },
  {
    "id": "8105012012",
    "district_id": "810501",
    "label": "Sesar",
    "value": "Sesar"
  },
  {
    "id": "8105012022",
    "district_id": "810501",
    "label": "Tansi Ambon",
    "value": "Tansi Ambon"
  },
  {
    "id": "8105012023",
    "district_id": "810501",
    "label": "Kampung Wailola",
    "value": "Kampung Wailola"
  },
  {
    "id": "8105012024",
    "district_id": "810501",
    "label": "Kampung Gorom",
    "value": "Kampung Gorom"
  },
  {
    "id": "8105012025",
    "district_id": "810501",
    "label": "Limumir",
    "value": "Limumir"
  },
  {
    "id": "8105012026",
    "district_id": "810501",
    "label": "Fattolo",
    "value": "Fattolo"
  },
  {
    "id": "8105022001",
    "district_id": "810502",
    "label": "Geser",
    "value": "Geser"
  },
  {
    "id": "8105022002",
    "district_id": "810502",
    "label": "Kiltay",
    "value": "Kiltay"
  },
  {
    "id": "8105022003",
    "district_id": "810502",
    "label": "Kilwaru",
    "value": "Kilwaru"
  },
  {
    "id": "8105022004",
    "district_id": "810502",
    "label": "Kellu",
    "value": "Kellu"
  },
  {
    "id": "8105022005",
    "district_id": "810502",
    "label": "Keffing",
    "value": "Keffing"
  },
  {
    "id": "8105022006",
    "district_id": "810502",
    "label": "Urung",
    "value": "Urung"
  },
  {
    "id": "8105022028",
    "district_id": "810502",
    "label": "Kilfura",
    "value": "Kilfura"
  },
  {
    "id": "8105022046",
    "district_id": "810502",
    "label": "Kwawor Kecil Mata Ata",
    "value": "Kwawor Kecil Mata Ata"
  },
  {
    "id": "8105022047",
    "district_id": "810502",
    "label": "Kwawor Kecil Mata Wawa",
    "value": "Kwawor Kecil Mata Wawa"
  },
  {
    "id": "8105022048",
    "district_id": "810502",
    "label": "Kwawor Besar Ena",
    "value": "Kwawor Besar Ena"
  },
  {
    "id": "8105022049",
    "district_id": "810502",
    "label": "Kwawor Besar Witau",
    "value": "Kwawor Besar Witau"
  },
  {
    "id": "8105022050",
    "district_id": "810502",
    "label": "Guli-Guli",
    "value": "Guli-Guli"
  },
  {
    "id": "8105022051",
    "district_id": "810502",
    "label": "Manggis",
    "value": "Manggis"
  },
  {
    "id": "8105022052",
    "district_id": "810502",
    "label": "Ainena",
    "value": "Ainena"
  },
  {
    "id": "8105022053",
    "district_id": "810502",
    "label": "Mugusinis",
    "value": "Mugusinis"
  },
  {
    "id": "8105022054",
    "district_id": "810502",
    "label": "Akatfadedo",
    "value": "Akatfadedo"
  },
  {
    "id": "8105032001",
    "district_id": "810503",
    "label": "Batuasa",
    "value": "Batuasa"
  },
  {
    "id": "8105032002",
    "district_id": "810503",
    "label": "Hatuimeten",
    "value": "Hatuimeten"
  },
  {
    "id": "8105032003",
    "district_id": "810503",
    "label": "Werinama",
    "value": "Werinama"
  },
  {
    "id": "8105032004",
    "district_id": "810503",
    "label": "Bemo",
    "value": "Bemo"
  },
  {
    "id": "8105032015",
    "district_id": "810503",
    "label": "Tum",
    "value": "Tum"
  },
  {
    "id": "8105032016",
    "district_id": "810503",
    "label": "Gusalaut",
    "value": "Gusalaut"
  },
  {
    "id": "8105032017",
    "district_id": "810503",
    "label": "Osong",
    "value": "Osong"
  },
  {
    "id": "8105032018",
    "district_id": "810503",
    "label": "Tobo",
    "value": "Tobo"
  },
  {
    "id": "8105032021",
    "district_id": "810503",
    "label": "Bemo Perak",
    "value": "Bemo Perak"
  },
  {
    "id": "8105032022",
    "district_id": "810503",
    "label": "Funa Naibaya",
    "value": "Funa Naibaya"
  },
  {
    "id": "8105042001",
    "district_id": "810504",
    "label": "Kataloka",
    "value": "Kataloka"
  },
  {
    "id": "8105042003",
    "district_id": "810504",
    "label": "Amarsekaru",
    "value": "Amarsekaru"
  },
  {
    "id": "8105042004",
    "district_id": "810504",
    "label": "Ondor",
    "value": "Ondor"
  },
  {
    "id": "8105042005",
    "district_id": "810504",
    "label": "Day",
    "value": "Day"
  },
  {
    "id": "8105042006",
    "district_id": "810504",
    "label": "Mida",
    "value": "Mida"
  },
  {
    "id": "8105042021",
    "district_id": "810504",
    "label": "Loko",
    "value": "Loko"
  },
  {
    "id": "8105042022",
    "district_id": "810504",
    "label": "Sera",
    "value": "Sera"
  },
  {
    "id": "8105042023",
    "district_id": "810504",
    "label": "Kulugowa",
    "value": "Kulugowa"
  },
  {
    "id": "8105042024",
    "district_id": "810504",
    "label": "Rumeon",
    "value": "Rumeon"
  },
  {
    "id": "8105042031",
    "district_id": "810504",
    "label": "Kelibingan",
    "value": "Kelibingan"
  },
  {
    "id": "8105042032",
    "district_id": "810504",
    "label": "Ilili",
    "value": "Ilili"
  },
  {
    "id": "8105042042",
    "district_id": "810504",
    "label": "Namalean",
    "value": "Namalean"
  },
  {
    "id": "8105042043",
    "district_id": "810504",
    "label": "Dulak",
    "value": "Dulak"
  },
  {
    "id": "8105042044",
    "district_id": "810504",
    "label": "Kilalir Kilwouw",
    "value": "Kilalir Kilwouw"
  },
  {
    "id": "8105042045",
    "district_id": "810504",
    "label": "Kilotak",
    "value": "Kilotak"
  },
  {
    "id": "8105042046",
    "district_id": "810504",
    "label": "Kilean",
    "value": "Kilean"
  },
  {
    "id": "8105042047",
    "district_id": "810504",
    "label": "Kaforing",
    "value": "Kaforing"
  },
  {
    "id": "8105042048",
    "district_id": "810504",
    "label": "Arewang",
    "value": "Arewang"
  },
  {
    "id": "8105042049",
    "district_id": "810504",
    "label": "Usun Kataloka",
    "value": "Usun Kataloka"
  },
  {
    "id": "8105042050",
    "district_id": "810504",
    "label": "Buan Kataloka",
    "value": "Buan Kataloka"
  },
  {
    "id": "8105042051",
    "district_id": "810504",
    "label": "Rumanama Kotawouw Kataloka",
    "value": "Rumanama Kotawouw Kataloka"
  },
  {
    "id": "8105042052",
    "district_id": "810504",
    "label": "Sikaro Kataloka",
    "value": "Sikaro Kataloka"
  },
  {
    "id": "8105042053",
    "district_id": "810504",
    "label": "Aroa Kataloka",
    "value": "Aroa Kataloka"
  },
  {
    "id": "8105042054",
    "district_id": "810504",
    "label": "Dada Kataloka",
    "value": "Dada Kataloka"
  },
  {
    "id": "8105052001",
    "district_id": "810505",
    "label": "Ilili",
    "value": "Ilili"
  },
  {
    "id": "8105052002",
    "district_id": "810505",
    "label": "Effa",
    "value": "Effa"
  },
  {
    "id": "8105052003",
    "district_id": "810505",
    "label": "Lahema",
    "value": "Lahema"
  },
  {
    "id": "8105052004",
    "district_id": "810505",
    "label": "Utta",
    "value": "Utta"
  },
  {
    "id": "8105052005",
    "district_id": "810505",
    "label": "Kelangan",
    "value": "Kelangan"
  },
  {
    "id": "8105052006",
    "district_id": "810505",
    "label": "Amarlaut",
    "value": "Amarlaut"
  },
  {
    "id": "8105052007",
    "district_id": "810505",
    "label": "Tamher Warat",
    "value": "Tamher Warat"
  },
  {
    "id": "8105052008",
    "district_id": "810505",
    "label": "Tamher Timur",
    "value": "Tamher Timur"
  },
  {
    "id": "8105052010",
    "district_id": "810505",
    "label": "Wunin Eldedora",
    "value": "Wunin Eldedora"
  },
  {
    "id": "8105052011",
    "district_id": "810505",
    "label": "Keldor",
    "value": "Keldor"
  },
  {
    "id": "8105052021",
    "district_id": "810505",
    "label": "Tanah Baru",
    "value": "Tanah Baru"
  },
  {
    "id": "8105052022",
    "district_id": "810505",
    "label": "Guliar",
    "value": "Guliar"
  },
  {
    "id": "8105052023",
    "district_id": "810505",
    "label": "Karlomin",
    "value": "Karlomin"
  },
  {
    "id": "8105052024",
    "district_id": "810505",
    "label": "Otademan",
    "value": "Otademan"
  },
  {
    "id": "8105052025",
    "district_id": "810505",
    "label": "Kurwara",
    "value": "Kurwara"
  },
  {
    "id": "8105052026",
    "district_id": "810505",
    "label": "Kilbutak",
    "value": "Kilbutak"
  },
  {
    "id": "8105052027",
    "district_id": "810505",
    "label": "Ruma Durun",
    "value": "Ruma Durun"
  },
  {
    "id": "8105052028",
    "district_id": "810505",
    "label": "Tana Soa",
    "value": "Tana Soa"
  },
  {
    "id": "8105062001",
    "district_id": "810506",
    "label": "Danama",
    "value": "Danama"
  },
  {
    "id": "8105062002",
    "district_id": "810506",
    "label": "Kilmoy",
    "value": "Kilmoy"
  },
  {
    "id": "8105062003",
    "district_id": "810506",
    "label": "Kilbat",
    "value": "Kilbat"
  },
  {
    "id": "8105062004",
    "district_id": "810506",
    "label": "Sesar",
    "value": "Sesar"
  },
  {
    "id": "8105062005",
    "district_id": "810506",
    "label": "Airkasar",
    "value": "Airkasar"
  },
  {
    "id": "8105062006",
    "district_id": "810506",
    "label": "Waras-Waras",
    "value": "Waras-Waras"
  },
  {
    "id": "8105062007",
    "district_id": "810506",
    "label": "Gah",
    "value": "Gah"
  },
  {
    "id": "8105062008",
    "district_id": "810506",
    "label": "Walang Tenga",
    "value": "Walang Tenga"
  },
  {
    "id": "8105062009",
    "district_id": "810506",
    "label": "Bati Kilwouw",
    "value": "Bati Kilwouw"
  },
  {
    "id": "8105062010",
    "district_id": "810506",
    "label": "Taruy",
    "value": "Taruy"
  },
  {
    "id": "8105062011",
    "district_id": "810506",
    "label": "Kufar",
    "value": "Kufar"
  },
  {
    "id": "8105072001",
    "district_id": "810507",
    "label": "Dihil",
    "value": "Dihil"
  },
  {
    "id": "8105072002",
    "district_id": "810507",
    "label": "Liliama",
    "value": "Liliama"
  },
  {
    "id": "8105072003",
    "district_id": "810507",
    "label": "Naiwel Ahinulin",
    "value": "Naiwel Ahinulin"
  },
  {
    "id": "8105072004",
    "district_id": "810507",
    "label": "Polin",
    "value": "Polin"
  },
  {
    "id": "8105072005",
    "district_id": "810507",
    "label": "Tunsai",
    "value": "Tunsai"
  },
  {
    "id": "8105072006",
    "district_id": "810507",
    "label": "Lapela",
    "value": "Lapela"
  },
  {
    "id": "8105072007",
    "district_id": "810507",
    "label": "Nayet",
    "value": "Nayet"
  },
  {
    "id": "8105072008",
    "district_id": "810507",
    "label": "Elnusa",
    "value": "Elnusa"
  },
  {
    "id": "8105072009",
    "district_id": "810507",
    "label": "Atiahu",
    "value": "Atiahu"
  },
  {
    "id": "8105072010",
    "district_id": "810507",
    "label": "Sabuai",
    "value": "Sabuai"
  },
  {
    "id": "8105072011",
    "district_id": "810507",
    "label": "Abulate",
    "value": "Abulate"
  },
  {
    "id": "8105072012",
    "district_id": "810507",
    "label": "Adabai",
    "value": "Adabai"
  },
  {
    "id": "8105082001",
    "district_id": "810508",
    "label": "Selor",
    "value": "Selor"
  },
  {
    "id": "8105082002",
    "district_id": "810508",
    "label": "Nekan",
    "value": "Nekan"
  },
  {
    "id": "8105082003",
    "district_id": "810508",
    "label": "Afang Kota",
    "value": "Afang Kota"
  },
  {
    "id": "8105082004",
    "district_id": "810508",
    "label": "Afang Defol",
    "value": "Afang Defol"
  },
  {
    "id": "8105082005",
    "district_id": "810508",
    "label": "Kumelang",
    "value": "Kumelang"
  },
  {
    "id": "8105082006",
    "district_id": "810508",
    "label": "Undur",
    "value": "Undur"
  },
  {
    "id": "8105082007",
    "district_id": "810508",
    "label": "Kamar",
    "value": "Kamar"
  },
  {
    "id": "8105082008",
    "district_id": "810508",
    "label": "Gunak",
    "value": "Gunak"
  },
  {
    "id": "8105082009",
    "district_id": "810508",
    "label": "Sumbawa",
    "value": "Sumbawa"
  },
  {
    "id": "8105082010",
    "district_id": "810508",
    "label": "Kilmury",
    "value": "Kilmury"
  },
  {
    "id": "8105082011",
    "district_id": "810508",
    "label": "Kilbon Kway",
    "value": "Kilbon Kway"
  },
  {
    "id": "8105082012",
    "district_id": "810508",
    "label": "Mising",
    "value": "Mising"
  },
  {
    "id": "8105082013",
    "district_id": "810508",
    "label": "Bitorik",
    "value": "Bitorik"
  },
  {
    "id": "8105082014",
    "district_id": "810508",
    "label": "Taa",
    "value": "Taa"
  },
  {
    "id": "8105092001",
    "district_id": "810509",
    "label": "Pulau Panjang",
    "value": "Pulau Panjang"
  },
  {
    "id": "8105092002",
    "district_id": "810509",
    "label": "Argam",
    "value": "Argam"
  },
  {
    "id": "8105092003",
    "district_id": "810509",
    "label": "Lalasa",
    "value": "Lalasa"
  },
  {
    "id": "8105092004",
    "district_id": "810509",
    "label": "Magat",
    "value": "Magat"
  },
  {
    "id": "8105092005",
    "district_id": "810509",
    "label": "Ruku Ruku",
    "value": "Ruku Ruku"
  },
  {
    "id": "8105092006",
    "district_id": "810509",
    "label": "Perik Basaranggi",
    "value": "Perik Basaranggi"
  },
  {
    "id": "8105102001",
    "district_id": "810510",
    "label": "Teor",
    "value": "Teor"
  },
  {
    "id": "8105102002",
    "district_id": "810510",
    "label": "Kartutin Kartenga",
    "value": "Kartutin Kartenga"
  },
  {
    "id": "8105102003",
    "district_id": "810510",
    "label": "Ker Ker",
    "value": "Ker Ker"
  },
  {
    "id": "8105102004",
    "district_id": "810510",
    "label": "Duryar Rumoy",
    "value": "Duryar Rumoy"
  },
  {
    "id": "8105102005",
    "district_id": "810510",
    "label": "Lapang Kampung Jawa",
    "value": "Lapang Kampung Jawa"
  },
  {
    "id": "8105102006",
    "district_id": "810510",
    "label": "Kampung Tengah Wermaf",
    "value": "Kampung Tengah Wermaf"
  },
  {
    "id": "8105102007",
    "district_id": "810510",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8105102008",
    "district_id": "810510",
    "label": "Mamur",
    "value": "Mamur"
  },
  {
    "id": "8105102009",
    "district_id": "810510",
    "label": "Kiliwouw",
    "value": "Kiliwouw"
  },
  {
    "id": "8105102010",
    "district_id": "810510",
    "label": "Karlokin",
    "value": "Karlokin"
  },
  {
    "id": "8105112001",
    "district_id": "810511",
    "label": "Kilkoda",
    "value": "Kilkoda"
  },
  {
    "id": "8105112002",
    "district_id": "810511",
    "label": "Miran",
    "value": "Miran"
  },
  {
    "id": "8105112003",
    "district_id": "810511",
    "label": "Kota Sirih",
    "value": "Kota Sirih"
  },
  {
    "id": "8105112004",
    "district_id": "810511",
    "label": "Amarwatu",
    "value": "Amarwatu"
  },
  {
    "id": "8105112005",
    "district_id": "810511",
    "label": "Aran",
    "value": "Aran"
  },
  {
    "id": "8105112006",
    "district_id": "810511",
    "label": "Tuha",
    "value": "Tuha"
  },
  {
    "id": "8105112007",
    "district_id": "810511",
    "label": "Bas",
    "value": "Bas"
  },
  {
    "id": "8105112008",
    "district_id": "810511",
    "label": "Tunas Ilur",
    "value": "Tunas Ilur"
  },
  {
    "id": "8105112009",
    "district_id": "810511",
    "label": "Sagey",
    "value": "Sagey"
  },
  {
    "id": "8105112010",
    "district_id": "810511",
    "label": "Armada",
    "value": "Armada"
  },
  {
    "id": "8105112011",
    "district_id": "810511",
    "label": "Waisalan",
    "value": "Waisalan"
  },
  {
    "id": "8105112012",
    "district_id": "810511",
    "label": "Basarin",
    "value": "Basarin"
  },
  {
    "id": "8105112013",
    "district_id": "810511",
    "label": "Goha",
    "value": "Goha"
  },
  {
    "id": "8105112014",
    "district_id": "810511",
    "label": "Rarat",
    "value": "Rarat"
  },
  {
    "id": "8105112015",
    "district_id": "810511",
    "label": "Miran Gota",
    "value": "Miran Gota"
  },
  {
    "id": "8105112016",
    "district_id": "810511",
    "label": "Adar",
    "value": "Adar"
  },
  {
    "id": "8105112017",
    "district_id": "810511",
    "label": "Tinarin",
    "value": "Tinarin"
  },
  {
    "id": "8105112018",
    "district_id": "810511",
    "label": "Etaralu",
    "value": "Etaralu"
  },
  {
    "id": "8105112019",
    "district_id": "810511",
    "label": "Kiltufa",
    "value": "Kiltufa"
  },
  {
    "id": "8105112020",
    "district_id": "810511",
    "label": "Miran Kilian",
    "value": "Miran Kilian"
  },
  {
    "id": "8105112021",
    "district_id": "810511",
    "label": "Miran Keledar",
    "value": "Miran Keledar"
  },
  {
    "id": "8105112022",
    "district_id": "810511",
    "label": "Miran Manaban",
    "value": "Miran Manaban"
  },
  {
    "id": "8105112023",
    "district_id": "810511",
    "label": "Miran Rumuar",
    "value": "Miran Rumuar"
  },
  {
    "id": "8105122001",
    "district_id": "810512",
    "label": "Hote",
    "value": "Hote"
  },
  {
    "id": "8105122002",
    "district_id": "810512",
    "label": "Banggoi",
    "value": "Banggoi"
  },
  {
    "id": "8105122003",
    "district_id": "810512",
    "label": "Waematakabo",
    "value": "Waematakabo"
  },
  {
    "id": "8105122004",
    "district_id": "810512",
    "label": "Waiketam Baru",
    "value": "Waiketam Baru"
  },
  {
    "id": "8105122005",
    "district_id": "810512",
    "label": "Waisamet",
    "value": "Waisamet"
  },
  {
    "id": "8105122006",
    "district_id": "810512",
    "label": "Aki Jaya",
    "value": "Aki Jaya"
  },
  {
    "id": "8105122007",
    "district_id": "810512",
    "label": "Jakarta Baru",
    "value": "Jakarta Baru"
  },
  {
    "id": "8105122008",
    "district_id": "810512",
    "label": "Silohan",
    "value": "Silohan"
  },
  {
    "id": "8105122009",
    "district_id": "810512",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "8105122010",
    "district_id": "810512",
    "label": "Rukun Jaya",
    "value": "Rukun Jaya"
  },
  {
    "id": "8105122011",
    "district_id": "810512",
    "label": "Jembatan Basah",
    "value": "Jembatan Basah"
  },
  {
    "id": "8105122012",
    "district_id": "810512",
    "label": "Dreamland Hills",
    "value": "Dreamland Hills"
  },
  {
    "id": "8105122013",
    "district_id": "810512",
    "label": "Banggoi Pancorang",
    "value": "Banggoi Pancorang"
  },
  {
    "id": "8105132001",
    "district_id": "810513",
    "label": "Kian Darat",
    "value": "Kian Darat"
  },
  {
    "id": "8105132002",
    "district_id": "810513",
    "label": "Artafela",
    "value": "Artafela"
  },
  {
    "id": "8105132003",
    "district_id": "810513",
    "label": "Angar",
    "value": "Angar"
  },
  {
    "id": "8105132004",
    "district_id": "810513",
    "label": "Watu Watu",
    "value": "Watu Watu"
  },
  {
    "id": "8105132005",
    "district_id": "810513",
    "label": "Kelaba",
    "value": "Kelaba"
  },
  {
    "id": "8105132006",
    "district_id": "810513",
    "label": "Kilga Kilwouw",
    "value": "Kilga Kilwouw"
  },
  {
    "id": "8105132007",
    "district_id": "810513",
    "label": "Kilga Watubau",
    "value": "Kilga Watubau"
  },
  {
    "id": "8105132008",
    "district_id": "810513",
    "label": "Rumfakar",
    "value": "Rumfakar"
  },
  {
    "id": "8105132009",
    "district_id": "810513",
    "label": "Kileser",
    "value": "Kileser"
  },
  {
    "id": "8105132010",
    "district_id": "810513",
    "label": "Rumoga",
    "value": "Rumoga"
  },
  {
    "id": "8105142001",
    "district_id": "810514",
    "label": "Kian Laut",
    "value": "Kian Laut"
  },
  {
    "id": "8105142002",
    "district_id": "810514",
    "label": "Kuwaos",
    "value": "Kuwaos"
  },
  {
    "id": "8105142003",
    "district_id": "810514",
    "label": "Salagor Air",
    "value": "Salagor Air"
  },
  {
    "id": "8105142004",
    "district_id": "810514",
    "label": "Salagor Kota",
    "value": "Salagor Kota"
  },
  {
    "id": "8105142005",
    "district_id": "810514",
    "label": "Aruan Gaur",
    "value": "Aruan Gaur"
  },
  {
    "id": "8105142006",
    "district_id": "810514",
    "label": "Keta Rumadan",
    "value": "Keta Rumadan"
  },
  {
    "id": "8105142007",
    "district_id": "810514",
    "label": "Lian Tasik",
    "value": "Lian Tasik"
  },
  {
    "id": "8105142008",
    "district_id": "810514",
    "label": "Keta",
    "value": "Keta"
  },
  {
    "id": "8105142009",
    "district_id": "810514",
    "label": "Air Nanang",
    "value": "Air Nanang"
  },
  {
    "id": "8105142010",
    "district_id": "810514",
    "label": "Suru",
    "value": "Suru"
  },
  {
    "id": "8105152001",
    "district_id": "810515",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "8105152002",
    "district_id": "810515",
    "label": "Bellis",
    "value": "Bellis"
  },
  {
    "id": "8105152003",
    "district_id": "810515",
    "label": "Dawang",
    "value": "Dawang"
  },
  {
    "id": "8105152004",
    "district_id": "810515",
    "label": "Solang",
    "value": "Solang"
  },
  {
    "id": "8105152005",
    "district_id": "810515",
    "label": "Karay",
    "value": "Karay"
  },
  {
    "id": "8105152006",
    "district_id": "810515",
    "label": "Nama Lena",
    "value": "Nama Lena"
  },
  {
    "id": "8105152007",
    "district_id": "810515",
    "label": "Nama Andan",
    "value": "Nama Andan"
  },
  {
    "id": "8105152008",
    "district_id": "810515",
    "label": "Tubir Wasiwang",
    "value": "Tubir Wasiwang"
  },
  {
    "id": "8105152009",
    "district_id": "810515",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8105152010",
    "district_id": "810515",
    "label": "Boinfia",
    "value": "Boinfia"
  },
  {
    "id": "8105152011",
    "district_id": "810515",
    "label": "Madak",
    "value": "Madak"
  },
  {
    "id": "8106012008",
    "district_id": "810601",
    "label": "Kamarian",
    "value": "Kamarian"
  },
  {
    "id": "8106012009",
    "district_id": "810601",
    "label": "Seruawan",
    "value": "Seruawan"
  },
  {
    "id": "8106012010",
    "district_id": "810601",
    "label": "Kairatu",
    "value": "Kairatu"
  },
  {
    "id": "8106012011",
    "district_id": "810601",
    "label": "Waimital",
    "value": "Waimital"
  },
  {
    "id": "8106012012",
    "district_id": "810601",
    "label": "Hatusua",
    "value": "Hatusua"
  },
  {
    "id": "8106012014",
    "district_id": "810601",
    "label": "Waipirit",
    "value": "Waipirit"
  },
  {
    "id": "8106012015",
    "district_id": "810601",
    "label": "Uraur",
    "value": "Uraur"
  },
  {
    "id": "8106022002",
    "district_id": "810602",
    "label": "Kawa",
    "value": "Kawa"
  },
  {
    "id": "8106022003",
    "district_id": "810602",
    "label": "Piru",
    "value": "Piru"
  },
  {
    "id": "8106022004",
    "district_id": "810602",
    "label": "Neniari",
    "value": "Neniari"
  },
  {
    "id": "8106022005",
    "district_id": "810602",
    "label": "Morekau",
    "value": "Morekau"
  },
  {
    "id": "8106022006",
    "district_id": "810602",
    "label": "Lumoli",
    "value": "Lumoli"
  },
  {
    "id": "8106022007",
    "district_id": "810602",
    "label": "Eti",
    "value": "Eti"
  },
  {
    "id": "8106022008",
    "district_id": "810602",
    "label": "Kaibobo",
    "value": "Kaibobo"
  },
  {
    "id": "8106032001",
    "district_id": "810603",
    "label": "Murnaten",
    "value": "Murnaten"
  },
  {
    "id": "8106032002",
    "district_id": "810603",
    "label": "Nikulukan",
    "value": "Nikulukan"
  },
  {
    "id": "8106032003",
    "district_id": "810603",
    "label": "Niwelehu",
    "value": "Niwelehu"
  },
  {
    "id": "8106032004",
    "district_id": "810603",
    "label": "Nuniali",
    "value": "Nuniali"
  },
  {
    "id": "8106032005",
    "district_id": "810603",
    "label": "Lisabata",
    "value": "Lisabata"
  },
  {
    "id": "8106032006",
    "district_id": "810603",
    "label": "Wakolo",
    "value": "Wakolo"
  },
  {
    "id": "8106032007",
    "district_id": "810603",
    "label": "Patahuwe",
    "value": "Patahuwe"
  },
  {
    "id": "8106032008",
    "district_id": "810603",
    "label": "Taniwel",
    "value": "Taniwel"
  },
  {
    "id": "8106032009",
    "district_id": "810603",
    "label": "Hulung",
    "value": "Hulung"
  },
  {
    "id": "8106032010",
    "district_id": "810603",
    "label": "Kasieh",
    "value": "Kasieh"
  },
  {
    "id": "8106032011",
    "district_id": "810603",
    "label": "Nukuhai",
    "value": "Nukuhai"
  },
  {
    "id": "8106032012",
    "district_id": "810603",
    "label": "Pasinalo",
    "value": "Pasinalo"
  },
  {
    "id": "8106032028",
    "district_id": "810603",
    "label": "Uweth",
    "value": "Uweth"
  },
  {
    "id": "8106032029",
    "district_id": "810603",
    "label": "Laturake",
    "value": "Laturake"
  },
  {
    "id": "8106032030",
    "district_id": "810603",
    "label": "Buria",
    "value": "Buria"
  },
  {
    "id": "8106032031",
    "district_id": "810603",
    "label": "Riring",
    "value": "Riring"
  },
  {
    "id": "8106032032",
    "district_id": "810603",
    "label": "Rumahsoal",
    "value": "Rumahsoal"
  },
  {
    "id": "8106032033",
    "district_id": "810603",
    "label": "Lohiasapalewa",
    "value": "Lohiasapalewa"
  },
  {
    "id": "8106032034",
    "district_id": "810603",
    "label": "Niniari",
    "value": "Niniari"
  },
  {
    "id": "8106042001",
    "district_id": "810604",
    "label": "Waesala",
    "value": "Waesala"
  },
  {
    "id": "8106042002",
    "district_id": "810604",
    "label": "Allang Asaude",
    "value": "Allang Asaude"
  },
  {
    "id": "8106042003",
    "district_id": "810604",
    "label": "Sole",
    "value": "Sole"
  },
  {
    "id": "8106042004",
    "district_id": "810604",
    "label": "Tonu Jaya",
    "value": "Tonu Jaya"
  },
  {
    "id": "8106042005",
    "district_id": "810604",
    "label": "Tahalupu",
    "value": "Tahalupu"
  },
  {
    "id": "8106042006",
    "district_id": "810604",
    "label": "Buano Utara",
    "value": "Buano Utara"
  },
  {
    "id": "8106042007",
    "district_id": "810604",
    "label": "Buano Selatan",
    "value": "Buano Selatan"
  },
  {
    "id": "8106052001",
    "district_id": "810605",
    "label": "Tihulale",
    "value": "Tihulale"
  },
  {
    "id": "8106052002",
    "district_id": "810605",
    "label": "Rumahkay",
    "value": "Rumahkay"
  },
  {
    "id": "8106052003",
    "district_id": "810605",
    "label": "Latu",
    "value": "Latu"
  },
  {
    "id": "8106052004",
    "district_id": "810605",
    "label": "Tomalehu",
    "value": "Tomalehu"
  },
  {
    "id": "8106052005",
    "district_id": "810605",
    "label": "Hualoy",
    "value": "Hualoy"
  },
  {
    "id": "8106052006",
    "district_id": "810605",
    "label": "Seriholo",
    "value": "Seriholo"
  },
  {
    "id": "8106052007",
    "district_id": "810605",
    "label": "Tala",
    "value": "Tala"
  },
  {
    "id": "8106062001",
    "district_id": "810606",
    "label": "Manusa",
    "value": "Manusa"
  },
  {
    "id": "8106062002",
    "district_id": "810606",
    "label": "Rambatu",
    "value": "Rambatu"
  },
  {
    "id": "8106062003",
    "district_id": "810606",
    "label": "Rumberu",
    "value": "Rumberu"
  },
  {
    "id": "8106062004",
    "district_id": "810606",
    "label": "Honitetu",
    "value": "Honitetu"
  },
  {
    "id": "8106062005",
    "district_id": "810606",
    "label": "Hukuanakota",
    "value": "Hukuanakota"
  },
  {
    "id": "8106072001",
    "district_id": "810607",
    "label": "Waihatu",
    "value": "Waihatu"
  },
  {
    "id": "8106072002",
    "district_id": "810607",
    "label": "Lohiatala",
    "value": "Lohiatala"
  },
  {
    "id": "8106072003",
    "district_id": "810607",
    "label": "Waisamu",
    "value": "Waisamu"
  },
  {
    "id": "8106072004",
    "district_id": "810607",
    "label": "Nuruwe",
    "value": "Nuruwe"
  },
  {
    "id": "8106072005",
    "district_id": "810607",
    "label": "Kamal",
    "value": "Kamal"
  },
  {
    "id": "8106072006",
    "district_id": "810607",
    "label": "Waisarisa",
    "value": "Waisarisa"
  },
  {
    "id": "8106082001",
    "district_id": "810608",
    "label": "Ariate",
    "value": "Ariate"
  },
  {
    "id": "8106082002",
    "district_id": "810608",
    "label": "Lokki",
    "value": "Lokki"
  },
  {
    "id": "8106082003",
    "district_id": "810608",
    "label": "Luhu",
    "value": "Luhu"
  },
  {
    "id": "8106082004",
    "district_id": "810608",
    "label": "Iha",
    "value": "Iha"
  },
  {
    "id": "8106082005",
    "district_id": "810608",
    "label": "Kulur",
    "value": "Kulur"
  },
  {
    "id": "8106092001",
    "district_id": "810609",
    "label": "Luhutuban",
    "value": "Luhutuban"
  },
  {
    "id": "8106092002",
    "district_id": "810609",
    "label": "Masawoy",
    "value": "Masawoy"
  },
  {
    "id": "8106092003",
    "district_id": "810609",
    "label": "Tuniwara",
    "value": "Tuniwara"
  },
  {
    "id": "8106092004",
    "district_id": "810609",
    "label": "Kelang Asaude",
    "value": "Kelang Asaude"
  },
  {
    "id": "8106092005",
    "district_id": "810609",
    "label": "Tomalehu Barat",
    "value": "Tomalehu Barat"
  },
  {
    "id": "8106092006",
    "district_id": "810609",
    "label": "Tomalehu Timur",
    "value": "Tomalehu Timur"
  },
  {
    "id": "8106092007",
    "district_id": "810609",
    "label": "Buano Hatuputih",
    "value": "Buano Hatuputih"
  },
  {
    "id": "8106102001",
    "district_id": "810610",
    "label": "Sohuwe",
    "value": "Sohuwe"
  },
  {
    "id": "8106102002",
    "district_id": "810610",
    "label": "Maloang",
    "value": "Maloang"
  },
  {
    "id": "8106102003",
    "district_id": "810610",
    "label": "Lumahlatal",
    "value": "Lumahlatal"
  },
  {
    "id": "8106102004",
    "district_id": "810610",
    "label": "Matapa",
    "value": "Matapa"
  },
  {
    "id": "8106102005",
    "district_id": "810610",
    "label": "Seakasale",
    "value": "Seakasale"
  },
  {
    "id": "8106102006",
    "district_id": "810610",
    "label": "Makububui",
    "value": "Makububui"
  },
  {
    "id": "8106102007",
    "district_id": "810610",
    "label": "Sukaraja",
    "value": "Sukaraja"
  },
  {
    "id": "8106102008",
    "district_id": "810610",
    "label": "Uwen Pantai",
    "value": "Uwen Pantai"
  },
  {
    "id": "8106102009",
    "district_id": "810610",
    "label": "Tounusa",
    "value": "Tounusa"
  },
  {
    "id": "8106102010",
    "district_id": "810610",
    "label": "Musihuwey",
    "value": "Musihuwey"
  },
  {
    "id": "8106102011",
    "district_id": "810610",
    "label": "Solea",
    "value": "Solea"
  },
  {
    "id": "8106102012",
    "district_id": "810610",
    "label": "Waraloin",
    "value": "Waraloin"
  },
  {
    "id": "8106102013",
    "district_id": "810610",
    "label": "Walakone",
    "value": "Walakone"
  },
  {
    "id": "8106102014",
    "district_id": "810610",
    "label": "Hatunuru",
    "value": "Hatunuru"
  },
  {
    "id": "8106102015",
    "district_id": "810610",
    "label": "Lumahpelu",
    "value": "Lumahpelu"
  },
  {
    "id": "8106112002",
    "district_id": "810611",
    "label": "Sanahu",
    "value": "Sanahu"
  },
  {
    "id": "8106112003",
    "district_id": "810611",
    "label": "Wasia",
    "value": "Wasia"
  },
  {
    "id": "8106112004",
    "district_id": "810611",
    "label": "Sumeith Pasinaro",
    "value": "Sumeith Pasinaro"
  },
  {
    "id": "8106112005",
    "district_id": "810611",
    "label": "Watui",
    "value": "Watui"
  },
  {
    "id": "8106112006",
    "district_id": "810611",
    "label": "Abio Ahiolo",
    "value": "Abio Ahiolo"
  },
  {
    "id": "8106112007",
    "district_id": "810611",
    "label": "Huku Kecil",
    "value": "Huku Kecil"
  },
  {
    "id": "8106112008",
    "district_id": "810611",
    "label": "Elpaputih",
    "value": "Elpaputih"
  },
  {
    "id": "8107011012",
    "district_id": "810701",
    "label": "Galai Dubu",
    "value": "Galai Dubu"
  },
  {
    "id": "8107011013",
    "district_id": "810701",
    "label": "Siwa Lima",
    "value": "Siwa Lima"
  },
  {
    "id": "8107012001",
    "district_id": "810701",
    "label": "Ujir",
    "value": "Ujir"
  },
  {
    "id": "8107012002",
    "district_id": "810701",
    "label": "Nafar",
    "value": "Nafar"
  },
  {
    "id": "8107012003",
    "district_id": "810701",
    "label": "Kobraur",
    "value": "Kobraur"
  },
  {
    "id": "8107012004",
    "district_id": "810701",
    "label": "Lau-Lau",
    "value": "Lau-Lau"
  },
  {
    "id": "8107012005",
    "district_id": "810701",
    "label": "Gorar",
    "value": "Gorar"
  },
  {
    "id": "8107012006",
    "district_id": "810701",
    "label": "Tungu",
    "value": "Tungu"
  },
  {
    "id": "8107012007",
    "district_id": "810701",
    "label": "Tunguwatu",
    "value": "Tunguwatu"
  },
  {
    "id": "8107012008",
    "district_id": "810701",
    "label": "Jabulenga",
    "value": "Jabulenga"
  },
  {
    "id": "8107012009",
    "district_id": "810701",
    "label": "Wokam",
    "value": "Wokam"
  },
  {
    "id": "8107012010",
    "district_id": "810701",
    "label": "Karangguli",
    "value": "Karangguli"
  },
  {
    "id": "8107012011",
    "district_id": "810701",
    "label": "Durjela",
    "value": "Durjela"
  },
  {
    "id": "8107012014",
    "district_id": "810701",
    "label": "Wangel",
    "value": "Wangel"
  },
  {
    "id": "8107012015",
    "district_id": "810701",
    "label": "Samang",
    "value": "Samang"
  },
  {
    "id": "8107022001",
    "district_id": "810702",
    "label": "Jerol",
    "value": "Jerol"
  },
  {
    "id": "8107022002",
    "district_id": "810702",
    "label": "Marfenfen",
    "value": "Marfenfen"
  },
  {
    "id": "8107022003",
    "district_id": "810702",
    "label": "Kabalukin",
    "value": "Kabalukin"
  },
  {
    "id": "8107022004",
    "district_id": "810702",
    "label": "Kalar-Kalar",
    "value": "Kalar-Kalar"
  },
  {
    "id": "8107022005",
    "district_id": "810702",
    "label": "Feruni",
    "value": "Feruni"
  },
  {
    "id": "8107022006",
    "district_id": "810702",
    "label": "Ngaiguli",
    "value": "Ngaiguli"
  },
  {
    "id": "8107022007",
    "district_id": "810702",
    "label": "Fatural",
    "value": "Fatural"
  },
  {
    "id": "8107022008",
    "district_id": "810702",
    "label": "Ngaibor",
    "value": "Ngaibor"
  },
  {
    "id": "8107022010",
    "district_id": "810702",
    "label": "Popjetur",
    "value": "Popjetur"
  },
  {
    "id": "8107022026",
    "district_id": "810702",
    "label": "Lor-lor",
    "value": "Lor-lor"
  },
  {
    "id": "8107022027",
    "district_id": "810702",
    "label": "Gaimar",
    "value": "Gaimar"
  },
  {
    "id": "8107022028",
    "district_id": "810702",
    "label": "Laininir",
    "value": "Laininir"
  },
  {
    "id": "8107022029",
    "district_id": "810702",
    "label": "Doka Barat",
    "value": "Doka Barat"
  },
  {
    "id": "8107022030",
    "district_id": "810702",
    "label": "Doka Timur",
    "value": "Doka Timur"
  },
  {
    "id": "8107022031",
    "district_id": "810702",
    "label": "Jelia",
    "value": "Jelia"
  },
  {
    "id": "8107032001",
    "district_id": "810703",
    "label": "Benjina",
    "value": "Benjina"
  },
  {
    "id": "8107032003",
    "district_id": "810703",
    "label": "Gardakau",
    "value": "Gardakau"
  },
  {
    "id": "8107032004",
    "district_id": "810703",
    "label": "Irloy",
    "value": "Irloy"
  },
  {
    "id": "8107032005",
    "district_id": "810703",
    "label": "Algadang",
    "value": "Algadang"
  },
  {
    "id": "8107032006",
    "district_id": "810703",
    "label": "Lorang",
    "value": "Lorang"
  },
  {
    "id": "8107032007",
    "district_id": "810703",
    "label": "Murai",
    "value": "Murai"
  },
  {
    "id": "8107032008",
    "district_id": "810703",
    "label": "Manjau",
    "value": "Manjau"
  },
  {
    "id": "8107032009",
    "district_id": "810703",
    "label": "Kwarbola",
    "value": "Kwarbola"
  },
  {
    "id": "8107032028",
    "district_id": "810703",
    "label": "Kobadangar",
    "value": "Kobadangar"
  },
  {
    "id": "8107032029",
    "district_id": "810703",
    "label": "Kobasel Fara",
    "value": "Kobasel Fara"
  },
  {
    "id": "8107032030",
    "district_id": "810703",
    "label": "Kobasel Timur",
    "value": "Kobasel Timur"
  },
  {
    "id": "8107032031",
    "district_id": "810703",
    "label": "Fatlabata",
    "value": "Fatlabata"
  },
  {
    "id": "8107032033",
    "district_id": "810703",
    "label": "Wakua",
    "value": "Wakua"
  },
  {
    "id": "8107032034",
    "district_id": "810703",
    "label": "Jirlay",
    "value": "Jirlay"
  },
  {
    "id": "8107032035",
    "district_id": "810703",
    "label": "Maririmar",
    "value": "Maririmar"
  },
  {
    "id": "8107032036",
    "district_id": "810703",
    "label": "Papakula",
    "value": "Papakula"
  },
  {
    "id": "8107032037",
    "district_id": "810703",
    "label": "Tanah Miring",
    "value": "Tanah Miring"
  },
  {
    "id": "8107032038",
    "district_id": "810703",
    "label": "Gulili",
    "value": "Gulili"
  },
  {
    "id": "8107032039",
    "district_id": "810703",
    "label": "Namara",
    "value": "Namara"
  },
  {
    "id": "8107032040",
    "district_id": "810703",
    "label": "Selilau",
    "value": "Selilau"
  },
  {
    "id": "8107032043",
    "district_id": "810703",
    "label": "Maijuring",
    "value": "Maijuring"
  },
  {
    "id": "8107032045",
    "district_id": "810703",
    "label": "Selibata-bata",
    "value": "Selibata-bata"
  },
  {
    "id": "8107042001",
    "district_id": "810704",
    "label": "Marlasi",
    "value": "Marlasi"
  },
  {
    "id": "8107042002",
    "district_id": "810704",
    "label": "Kolamar",
    "value": "Kolamar"
  },
  {
    "id": "8107042003",
    "district_id": "810704",
    "label": "Kabufin",
    "value": "Kabufin"
  },
  {
    "id": "8107042004",
    "district_id": "810704",
    "label": "Tasinwaha",
    "value": "Tasinwaha"
  },
  {
    "id": "8107042005",
    "district_id": "810704",
    "label": "Foket",
    "value": "Foket"
  },
  {
    "id": "8107042006",
    "district_id": "810704",
    "label": "Kaibolafin",
    "value": "Kaibolafin"
  },
  {
    "id": "8107042007",
    "district_id": "810704",
    "label": "Wahangula-Ngula",
    "value": "Wahangula-Ngula"
  },
  {
    "id": "8107042008",
    "district_id": "810704",
    "label": "Wahayum",
    "value": "Wahayum"
  },
  {
    "id": "8107042009",
    "district_id": "810704",
    "label": "Warialau",
    "value": "Warialau"
  },
  {
    "id": "8107042010",
    "district_id": "810704",
    "label": "Jerwatu",
    "value": "Jerwatu"
  },
  {
    "id": "8107042013",
    "district_id": "810704",
    "label": "Selmona",
    "value": "Selmona"
  },
  {
    "id": "8107042014",
    "district_id": "810704",
    "label": "Mesidang",
    "value": "Mesidang"
  },
  {
    "id": "8107052001",
    "district_id": "810705",
    "label": "Kabalsiang",
    "value": "Kabalsiang"
  },
  {
    "id": "8107052002",
    "district_id": "810705",
    "label": "Benjuring",
    "value": "Benjuring"
  },
  {
    "id": "8107052003",
    "district_id": "810705",
    "label": "Batuley",
    "value": "Batuley"
  },
  {
    "id": "8107052004",
    "district_id": "810705",
    "label": "Kumul",
    "value": "Kumul"
  },
  {
    "id": "8107052005",
    "district_id": "810705",
    "label": "Waria",
    "value": "Waria"
  },
  {
    "id": "8107052006",
    "district_id": "810705",
    "label": "Sewer",
    "value": "Sewer"
  },
  {
    "id": "8107052007",
    "district_id": "810705",
    "label": "Jursiang",
    "value": "Jursiang"
  },
  {
    "id": "8107052008",
    "district_id": "810705",
    "label": "Kompane",
    "value": "Kompane"
  },
  {
    "id": "8107052009",
    "district_id": "810705",
    "label": "Kobamar",
    "value": "Kobamar"
  },
  {
    "id": "8107062002",
    "district_id": "810706",
    "label": "Waifual",
    "value": "Waifual"
  },
  {
    "id": "8107062003",
    "district_id": "810706",
    "label": "Wafan",
    "value": "Wafan"
  },
  {
    "id": "8107062004",
    "district_id": "810706",
    "label": "Langhalau",
    "value": "Langhalau"
  },
  {
    "id": "8107062005",
    "district_id": "810706",
    "label": "Gomsey",
    "value": "Gomsey"
  },
  {
    "id": "8107062006",
    "district_id": "810706",
    "label": "Leiting",
    "value": "Leiting"
  },
  {
    "id": "8107062007",
    "district_id": "810706",
    "label": "Bardefan",
    "value": "Bardefan"
  },
  {
    "id": "8107062008",
    "district_id": "810706",
    "label": "Mohongsel",
    "value": "Mohongsel"
  },
  {
    "id": "8107062009",
    "district_id": "810706",
    "label": "Kolaha",
    "value": "Kolaha"
  },
  {
    "id": "8107062010",
    "district_id": "810706",
    "label": "Goda-Goda",
    "value": "Goda-Goda"
  },
  {
    "id": "8107072001",
    "district_id": "810707",
    "label": "Koijabi",
    "value": "Koijabi"
  },
  {
    "id": "8107072002",
    "district_id": "810707",
    "label": "Balatan",
    "value": "Balatan"
  },
  {
    "id": "8107072003",
    "district_id": "810707",
    "label": "Warloy",
    "value": "Warloy"
  },
  {
    "id": "8107072004",
    "district_id": "810707",
    "label": "Warjukur",
    "value": "Warjukur"
  },
  {
    "id": "8107072005",
    "district_id": "810707",
    "label": "Kobror",
    "value": "Kobror"
  },
  {
    "id": "8107072006",
    "district_id": "810707",
    "label": "Basada",
    "value": "Basada"
  },
  {
    "id": "8107072007",
    "district_id": "810707",
    "label": "Wailay",
    "value": "Wailay"
  },
  {
    "id": "8107072008",
    "district_id": "810707",
    "label": "Kaiwabar",
    "value": "Kaiwabar"
  },
  {
    "id": "8107072009",
    "district_id": "810707",
    "label": "Ponom",
    "value": "Ponom"
  },
  {
    "id": "8107072010",
    "district_id": "810707",
    "label": "Lola",
    "value": "Lola"
  },
  {
    "id": "8107072011",
    "district_id": "810707",
    "label": "Mariri",
    "value": "Mariri"
  },
  {
    "id": "8107072012",
    "district_id": "810707",
    "label": "Dosinamalau",
    "value": "Dosinamalau"
  },
  {
    "id": "8107072013",
    "district_id": "810707",
    "label": "Karawai",
    "value": "Karawai"
  },
  {
    "id": "8107082001",
    "district_id": "810708",
    "label": "Longgar",
    "value": "Longgar"
  },
  {
    "id": "8107082002",
    "district_id": "810708",
    "label": "Apara",
    "value": "Apara"
  },
  {
    "id": "8107082003",
    "district_id": "810708",
    "label": "Bemun",
    "value": "Bemun"
  },
  {
    "id": "8107082004",
    "district_id": "810708",
    "label": "Mesiang",
    "value": "Mesiang"
  },
  {
    "id": "8107082005",
    "district_id": "810708",
    "label": "Gomo-Gomo",
    "value": "Gomo-Gomo"
  },
  {
    "id": "8107082006",
    "district_id": "810708",
    "label": "Jambu Air",
    "value": "Jambu Air"
  },
  {
    "id": "8107082007",
    "district_id": "810708",
    "label": "Warabal",
    "value": "Warabal"
  },
  {
    "id": "8107092001",
    "district_id": "810709",
    "label": "Meror",
    "value": "Meror"
  },
  {
    "id": "8107092002",
    "district_id": "810709",
    "label": "Dosimar",
    "value": "Dosimar"
  },
  {
    "id": "8107092003",
    "district_id": "810709",
    "label": "Batugoyang",
    "value": "Batugoyang"
  },
  {
    "id": "8107092004",
    "district_id": "810709",
    "label": "Salarem",
    "value": "Salarem"
  },
  {
    "id": "8107092005",
    "district_id": "810709",
    "label": "Siya",
    "value": "Siya"
  },
  {
    "id": "8107092006",
    "district_id": "810709",
    "label": "Beltubur",
    "value": "Beltubur"
  },
  {
    "id": "8107092007",
    "district_id": "810709",
    "label": "Karey",
    "value": "Karey"
  },
  {
    "id": "8107092008",
    "district_id": "810709",
    "label": "Jorang",
    "value": "Jorang"
  },
  {
    "id": "8107092009",
    "district_id": "810709",
    "label": "Gomar Sungai",
    "value": "Gomar Sungai"
  },
  {
    "id": "8107092010",
    "district_id": "810709",
    "label": "Gomar Meti",
    "value": "Gomar Meti"
  },
  {
    "id": "8107102001",
    "district_id": "810710",
    "label": "Tabarfane",
    "value": "Tabarfane"
  },
  {
    "id": "8107102002",
    "district_id": "810710",
    "label": "Rebi",
    "value": "Rebi"
  },
  {
    "id": "8107102003",
    "district_id": "810710",
    "label": "Lutur",
    "value": "Lutur"
  },
  {
    "id": "8107102004",
    "district_id": "810710",
    "label": "Hokmar",
    "value": "Hokmar"
  },
  {
    "id": "8107102005",
    "district_id": "810710",
    "label": "Juring",
    "value": "Juring"
  },
  {
    "id": "8107102006",
    "district_id": "810710",
    "label": "Erersin",
    "value": "Erersin"
  },
  {
    "id": "8107102007",
    "district_id": "810710",
    "label": "Maekor",
    "value": "Maekor"
  },
  {
    "id": "8108011001",
    "district_id": "810801",
    "label": "Tiakur",
    "value": "Tiakur"
  },
  {
    "id": "8108012001",
    "district_id": "810801",
    "label": "Klis",
    "value": "Klis"
  },
  {
    "id": "8108012002",
    "district_id": "810801",
    "label": "Patti",
    "value": "Patti"
  },
  {
    "id": "8108012003",
    "district_id": "810801",
    "label": "Wakarleli",
    "value": "Wakarleli"
  },
  {
    "id": "8108012004",
    "district_id": "810801",
    "label": "Kaiwatu",
    "value": "Kaiwatu"
  },
  {
    "id": "8108012005",
    "district_id": "810801",
    "label": "Werwaru",
    "value": "Werwaru"
  },
  {
    "id": "8108012006",
    "district_id": "810801",
    "label": "Tounwawan",
    "value": "Tounwawan"
  },
  {
    "id": "8108012007",
    "district_id": "810801",
    "label": "Moain",
    "value": "Moain"
  },
  {
    "id": "8108022001",
    "district_id": "810802",
    "label": "Wulur",
    "value": "Wulur"
  },
  {
    "id": "8108022002",
    "district_id": "810802",
    "label": "Batu Merah",
    "value": "Batu Merah"
  },
  {
    "id": "8108022003",
    "district_id": "810802",
    "label": "Kuaimelu",
    "value": "Kuaimelu"
  },
  {
    "id": "8108022004",
    "district_id": "810802",
    "label": "Kumur",
    "value": "Kumur"
  },
  {
    "id": "8108022005",
    "district_id": "810802",
    "label": "Bebar Timur",
    "value": "Bebar Timur"
  },
  {
    "id": "8108022006",
    "district_id": "810802",
    "label": "Ilih",
    "value": "Ilih"
  },
  {
    "id": "8108022007",
    "district_id": "810802",
    "label": "Kehli",
    "value": "Kehli"
  },
  {
    "id": "8108032001",
    "district_id": "810803",
    "label": "Luang Barat",
    "value": "Luang Barat"
  },
  {
    "id": "8108032002",
    "district_id": "810803",
    "label": "Luang Timur",
    "value": "Luang Timur"
  },
  {
    "id": "8108032003",
    "district_id": "810803",
    "label": "Elo",
    "value": "Elo"
  },
  {
    "id": "8108032004",
    "district_id": "810803",
    "label": "Rumkisar",
    "value": "Rumkisar"
  },
  {
    "id": "8108032005",
    "district_id": "810803",
    "label": "Lelang",
    "value": "Lelang"
  },
  {
    "id": "8108032006",
    "district_id": "810803",
    "label": "Mahaleta",
    "value": "Mahaleta"
  },
  {
    "id": "8108032007",
    "district_id": "810803",
    "label": "Romdara",
    "value": "Romdara"
  },
  {
    "id": "8108032008",
    "district_id": "810803",
    "label": "Rotnama",
    "value": "Rotnama"
  },
  {
    "id": "8108032009",
    "district_id": "810803",
    "label": "Batugajah",
    "value": "Batugajah"
  },
  {
    "id": "8108032010",
    "district_id": "810803",
    "label": "Pupliora",
    "value": "Pupliora"
  },
  {
    "id": "8108032011",
    "district_id": "810803",
    "label": "Regoha",
    "value": "Regoha"
  },
  {
    "id": "8108042001",
    "district_id": "810804",
    "label": "Tepa",
    "value": "Tepa"
  },
  {
    "id": "8108042002",
    "district_id": "810804",
    "label": "Imroing",
    "value": "Imroing"
  },
  {
    "id": "8108042003",
    "district_id": "810804",
    "label": "Tela",
    "value": "Tela"
  },
  {
    "id": "8108042004",
    "district_id": "810804",
    "label": "Yaltubung",
    "value": "Yaltubung"
  },
  {
    "id": "8108042005",
    "district_id": "810804",
    "label": "Wanuwui",
    "value": "Wanuwui"
  },
  {
    "id": "8108042006",
    "district_id": "810804",
    "label": "Letsiara",
    "value": "Letsiara"
  },
  {
    "id": "8108042015",
    "district_id": "810804",
    "label": "Lewah",
    "value": "Lewah"
  },
  {
    "id": "8108042016",
    "district_id": "810804",
    "label": "Hertuti",
    "value": "Hertuti"
  },
  {
    "id": "8108042017",
    "district_id": "810804",
    "label": "Sinairusi",
    "value": "Sinairusi"
  },
  {
    "id": "8108052001",
    "district_id": "810805",
    "label": "Letwurung",
    "value": "Letwurung"
  },
  {
    "id": "8108052002",
    "district_id": "810805",
    "label": "Yatoke",
    "value": "Yatoke"
  },
  {
    "id": "8108052003",
    "district_id": "810805",
    "label": "Kroing",
    "value": "Kroing"
  },
  {
    "id": "8108052004",
    "district_id": "810805",
    "label": "Nakarhamto",
    "value": "Nakarhamto"
  },
  {
    "id": "8108052005",
    "district_id": "810805",
    "label": "Emplawas",
    "value": "Emplawas"
  },
  {
    "id": "8108052006",
    "district_id": "810805",
    "label": "Kokwari",
    "value": "Kokwari"
  },
  {
    "id": "8108052007",
    "district_id": "810805",
    "label": "Ahanari",
    "value": "Ahanari"
  },
  {
    "id": "8108052008",
    "district_id": "810805",
    "label": "Wakpapapi",
    "value": "Wakpapapi"
  },
  {
    "id": "8108052009",
    "district_id": "810805",
    "label": "Analutur",
    "value": "Analutur"
  },
  {
    "id": "8108052010",
    "district_id": "810805",
    "label": "Manuweri",
    "value": "Manuweri"
  },
  {
    "id": "8108052011",
    "district_id": "810805",
    "label": "Tutuwawang",
    "value": "Tutuwawang"
  },
  {
    "id": "8108062001",
    "district_id": "810806",
    "label": "Ilwaki",
    "value": "Ilwaki"
  },
  {
    "id": "8108062002",
    "district_id": "810806",
    "label": "Ilputih",
    "value": "Ilputih"
  },
  {
    "id": "8108062003",
    "district_id": "810806",
    "label": "Mahuan",
    "value": "Mahuan"
  },
  {
    "id": "8108062004",
    "district_id": "810806",
    "label": "Masapun",
    "value": "Masapun"
  },
  {
    "id": "8108062021",
    "district_id": "810806",
    "label": "Arnau",
    "value": "Arnau"
  },
  {
    "id": "8108062023",
    "district_id": "810806",
    "label": "Hiay",
    "value": "Hiay"
  },
  {
    "id": "8108072001",
    "district_id": "810807",
    "label": "Wonreli",
    "value": "Wonreli"
  },
  {
    "id": "8108072002",
    "district_id": "810807",
    "label": "Lekloor",
    "value": "Lekloor"
  },
  {
    "id": "8108072003",
    "district_id": "810807",
    "label": "Kota Lama",
    "value": "Kota Lama"
  },
  {
    "id": "8108072004",
    "district_id": "810807",
    "label": "Abusur",
    "value": "Abusur"
  },
  {
    "id": "8108072005",
    "district_id": "810807",
    "label": "Oirata Timur",
    "value": "Oirata Timur"
  },
  {
    "id": "8108072006",
    "district_id": "810807",
    "label": "Oirata Barat",
    "value": "Oirata Barat"
  },
  {
    "id": "8108082001",
    "district_id": "810808",
    "label": "Laitutun",
    "value": "Laitutun"
  },
  {
    "id": "8108082002",
    "district_id": "810808",
    "label": "Batumiau",
    "value": "Batumiau"
  },
  {
    "id": "8108082003",
    "district_id": "810808",
    "label": "Tutukey",
    "value": "Tutukey"
  },
  {
    "id": "8108082004",
    "district_id": "810808",
    "label": "Tomra",
    "value": "Tomra"
  },
  {
    "id": "8108082005",
    "district_id": "810808",
    "label": "Nuwewang",
    "value": "Nuwewang"
  },
  {
    "id": "8108082006",
    "district_id": "810808",
    "label": "Tutuwaru",
    "value": "Tutuwaru"
  },
  {
    "id": "8108082007",
    "district_id": "810808",
    "label": "Luhuely",
    "value": "Luhuely"
  },
  {
    "id": "8108092001",
    "district_id": "810809",
    "label": "Latalola Besar",
    "value": "Latalola Besar"
  },
  {
    "id": "8108092002",
    "district_id": "810809",
    "label": "Serili",
    "value": "Serili"
  },
  {
    "id": "8108092003",
    "district_id": "810809",
    "label": "Latalola Kecil",
    "value": "Latalola Kecil"
  },
  {
    "id": "8108092004",
    "district_id": "810809",
    "label": "Telalora",
    "value": "Telalora"
  },
  {
    "id": "8108092005",
    "district_id": "810809",
    "label": "Marsela",
    "value": "Marsela"
  },
  {
    "id": "8108092006",
    "district_id": "810809",
    "label": "Babiotang",
    "value": "Babiotang"
  },
  {
    "id": "8108092007",
    "district_id": "810809",
    "label": "Iblatmuntah",
    "value": "Iblatmuntah"
  },
  {
    "id": "8108092008",
    "district_id": "810809",
    "label": "Ilbutung",
    "value": "Ilbutung"
  },
  {
    "id": "8108092009",
    "district_id": "810809",
    "label": "Lawawang",
    "value": "Lawawang"
  },
  {
    "id": "8108092010",
    "district_id": "810809",
    "label": "Nura",
    "value": "Nura"
  },
  {
    "id": "8108092011",
    "district_id": "810809",
    "label": "Bululora",
    "value": "Bululora"
  },
  {
    "id": "8108102001",
    "district_id": "810810",
    "label": "Watuwei",
    "value": "Watuwei"
  },
  {
    "id": "8108102002",
    "district_id": "810810",
    "label": "Ilmarang",
    "value": "Ilmarang"
  },
  {
    "id": "8108102003",
    "district_id": "810810",
    "label": "Letmasa",
    "value": "Letmasa"
  },
  {
    "id": "8108102004",
    "district_id": "810810",
    "label": "Welora",
    "value": "Welora"
  },
  {
    "id": "8108102005",
    "district_id": "810810",
    "label": "Wiratan",
    "value": "Wiratan"
  },
  {
    "id": "8108102006",
    "district_id": "810810",
    "label": "Nurnyaman",
    "value": "Nurnyaman"
  },
  {
    "id": "8108112001",
    "district_id": "810811",
    "label": "Herley",
    "value": "Herley"
  },
  {
    "id": "8108112002",
    "district_id": "810811",
    "label": "Upuhupun",
    "value": "Upuhupun"
  },
  {
    "id": "8108112003",
    "district_id": "810811",
    "label": "Pota Besar",
    "value": "Pota Besar"
  },
  {
    "id": "8108112004",
    "district_id": "810811",
    "label": "Pota Kecil",
    "value": "Pota Kecil"
  },
  {
    "id": "8108112005",
    "district_id": "810811",
    "label": "Wasarili",
    "value": "Wasarili"
  },
  {
    "id": "8108112006",
    "district_id": "810811",
    "label": "Rumahlewang Kecil",
    "value": "Rumahlewang Kecil"
  },
  {
    "id": "8108112007",
    "district_id": "810811",
    "label": "Rumahlewang Besar",
    "value": "Rumahlewang Besar"
  },
  {
    "id": "8108112008",
    "district_id": "810811",
    "label": "Nusiata",
    "value": "Nusiata"
  },
  {
    "id": "8108122001",
    "district_id": "810812",
    "label": "Ketty",
    "value": "Ketty"
  },
  {
    "id": "8108122002",
    "district_id": "810812",
    "label": "Letoda",
    "value": "Letoda"
  },
  {
    "id": "8108122003",
    "district_id": "810812",
    "label": "Sera",
    "value": "Sera"
  },
  {
    "id": "8108122004",
    "district_id": "810812",
    "label": "Yamluli",
    "value": "Yamluli"
  },
  {
    "id": "8108122005",
    "district_id": "810812",
    "label": "Lolotuara",
    "value": "Lolotuara"
  },
  {
    "id": "8108132001",
    "district_id": "810813",
    "label": "Nabar",
    "value": "Nabar"
  },
  {
    "id": "8108132002",
    "district_id": "810813",
    "label": "Elsulith",
    "value": "Elsulith"
  },
  {
    "id": "8108132003",
    "district_id": "810813",
    "label": "Naumatang",
    "value": "Naumatang"
  },
  {
    "id": "8108132004",
    "district_id": "810813",
    "label": "Lurang",
    "value": "Lurang"
  },
  {
    "id": "8108132005",
    "district_id": "810813",
    "label": "Uhak",
    "value": "Uhak"
  },
  {
    "id": "8108132006",
    "district_id": "810813",
    "label": "Eray",
    "value": "Eray"
  },
  {
    "id": "8108142001",
    "district_id": "810814",
    "label": "Telemar",
    "value": "Telemar"
  },
  {
    "id": "8108142002",
    "district_id": "810814",
    "label": "Karbubu",
    "value": "Karbubu"
  },
  {
    "id": "8108142003",
    "district_id": "810814",
    "label": "Ustutun",
    "value": "Ustutun"
  },
  {
    "id": "8108142004",
    "district_id": "810814",
    "label": "Klishatu",
    "value": "Klishatu"
  },
  {
    "id": "8108142005",
    "district_id": "810814",
    "label": "Ilmamau",
    "value": "Ilmamau"
  },
  {
    "id": "8108152001",
    "district_id": "810815",
    "label": "Moning",
    "value": "Moning"
  },
  {
    "id": "8108152002",
    "district_id": "810815",
    "label": "Arwala",
    "value": "Arwala"
  },
  {
    "id": "8108152003",
    "district_id": "810815",
    "label": "Ilway",
    "value": "Ilway"
  },
  {
    "id": "8108152004",
    "district_id": "810815",
    "label": "Kahilin",
    "value": "Kahilin"
  },
  {
    "id": "8108152005",
    "district_id": "810815",
    "label": "Ilpokil",
    "value": "Ilpokil"
  },
  {
    "id": "8108152006",
    "district_id": "810815",
    "label": "Tomliapat",
    "value": "Tomliapat"
  },
  {
    "id": "8108162001",
    "district_id": "810816",
    "label": "Hila",
    "value": "Hila"
  },
  {
    "id": "8108162002",
    "district_id": "810816",
    "label": "Jerusu",
    "value": "Jerusu"
  },
  {
    "id": "8108162003",
    "district_id": "810816",
    "label": "Solath",
    "value": "Solath"
  },
  {
    "id": "8108172001",
    "district_id": "810817",
    "label": "Purpura",
    "value": "Purpura"
  },
  {
    "id": "8108172002",
    "district_id": "810817",
    "label": "Nomaha",
    "value": "Nomaha"
  },
  {
    "id": "8108172003",
    "district_id": "810817",
    "label": "Labelau",
    "value": "Labelau"
  },
  {
    "id": "8109012001",
    "district_id": "810901",
    "label": "Wamkana",
    "value": "Wamkana"
  },
  {
    "id": "8109012002",
    "district_id": "810901",
    "label": "Waenalut",
    "value": "Waenalut"
  },
  {
    "id": "8109012003",
    "district_id": "810901",
    "label": "Lektama",
    "value": "Lektama"
  },
  {
    "id": "8109012004",
    "district_id": "810901",
    "label": "Fatmite",
    "value": "Fatmite"
  },
  {
    "id": "8109012005",
    "district_id": "810901",
    "label": "Elfule",
    "value": "Elfule"
  },
  {
    "id": "8109012006",
    "district_id": "810901",
    "label": "Leku",
    "value": "Leku"
  },
  {
    "id": "8109012007",
    "district_id": "810901",
    "label": "Oki Baru",
    "value": "Oki Baru"
  },
  {
    "id": "8109012008",
    "district_id": "810901",
    "label": "Oki Lama",
    "value": "Oki Lama"
  },
  {
    "id": "8109012009",
    "district_id": "810901",
    "label": "Labuang",
    "value": "Labuang"
  },
  {
    "id": "8109012010",
    "district_id": "810901",
    "label": "Wali",
    "value": "Wali"
  },
  {
    "id": "8109012011",
    "district_id": "810901",
    "label": "Tikbary",
    "value": "Tikbary"
  },
  {
    "id": "8109012012",
    "district_id": "810901",
    "label": "Masnana",
    "value": "Masnana"
  },
  {
    "id": "8109012013",
    "district_id": "810901",
    "label": "Kamlanglale",
    "value": "Kamlanglale"
  },
  {
    "id": "8109012014",
    "district_id": "810901",
    "label": "Waenono",
    "value": "Waenono"
  },
  {
    "id": "8109012015",
    "district_id": "810901",
    "label": "Namrinat",
    "value": "Namrinat"
  },
  {
    "id": "8109012016",
    "district_id": "810901",
    "label": "Waefusi",
    "value": "Waefusi"
  },
  {
    "id": "8109012017",
    "district_id": "810901",
    "label": "Batu Tulis",
    "value": "Batu Tulis"
  },
  {
    "id": "8109022001",
    "district_id": "810902",
    "label": "Waetawa",
    "value": "Waetawa"
  },
  {
    "id": "8109022002",
    "district_id": "810902",
    "label": "Waesili",
    "value": "Waesili"
  },
  {
    "id": "8109022003",
    "district_id": "810902",
    "label": "Lena",
    "value": "Lena"
  },
  {
    "id": "8109022004",
    "district_id": "810902",
    "label": "Simi",
    "value": "Simi"
  },
  {
    "id": "8109022005",
    "district_id": "810902",
    "label": "Waelikut",
    "value": "Waelikut"
  },
  {
    "id": "8109022006",
    "district_id": "810902",
    "label": "Wamsisi",
    "value": "Wamsisi"
  },
  {
    "id": "8109022007",
    "district_id": "810902",
    "label": "Pohon Batu",
    "value": "Pohon Batu"
  },
  {
    "id": "8109022008",
    "district_id": "810902",
    "label": "Waeteba",
    "value": "Waeteba"
  },
  {
    "id": "8109022009",
    "district_id": "810902",
    "label": "Hote",
    "value": "Hote"
  },
  {
    "id": "8109022010",
    "district_id": "810902",
    "label": "Waemasing",
    "value": "Waemasing"
  },
  {
    "id": "8109022011",
    "district_id": "810902",
    "label": "Batu Kasa",
    "value": "Batu Kasa"
  },
  {
    "id": "8109032001",
    "district_id": "810903",
    "label": "Lumoy",
    "value": "Lumoy"
  },
  {
    "id": "8109032002",
    "district_id": "810903",
    "label": "Masawoy",
    "value": "Masawoy"
  },
  {
    "id": "8109032003",
    "district_id": "810903",
    "label": "Ulima",
    "value": "Ulima"
  },
  {
    "id": "8109032004",
    "district_id": "810903",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8109032005",
    "district_id": "810903",
    "label": "Siwar",
    "value": "Siwar"
  },
  {
    "id": "8109032006",
    "district_id": "810903",
    "label": "Selasi",
    "value": "Selasi"
  },
  {
    "id": "8109032007",
    "district_id": "810903",
    "label": "Elara",
    "value": "Elara"
  },
  {
    "id": "8109042001",
    "district_id": "810904",
    "label": "Balpetu",
    "value": "Balpetu"
  },
  {
    "id": "8109042002",
    "district_id": "810904",
    "label": "Waekeka",
    "value": "Waekeka"
  },
  {
    "id": "8109042003",
    "district_id": "810904",
    "label": "Biloro",
    "value": "Biloro"
  },
  {
    "id": "8109042004",
    "district_id": "810904",
    "label": "Nanali",
    "value": "Nanali"
  },
  {
    "id": "8109042005",
    "district_id": "810904",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "8109042006",
    "district_id": "810904",
    "label": "Fogi",
    "value": "Fogi"
  },
  {
    "id": "8109042007",
    "district_id": "810904",
    "label": "Waepandan",
    "value": "Waepandan"
  },
  {
    "id": "8109042008",
    "district_id": "810904",
    "label": "Walbele",
    "value": "Walbele"
  },
  {
    "id": "8109042009",
    "district_id": "810904",
    "label": "Air Ternate",
    "value": "Air Ternate"
  },
  {
    "id": "8109042010",
    "district_id": "810904",
    "label": "Sekat",
    "value": "Sekat"
  },
  {
    "id": "8109042011",
    "district_id": "810904",
    "label": "Waeha",
    "value": "Waeha"
  },
  {
    "id": "8109042012",
    "district_id": "810904",
    "label": "Bala-bala",
    "value": "Bala-bala"
  },
  {
    "id": "8109042013",
    "district_id": "810904",
    "label": "Batu Layar",
    "value": "Batu Layar"
  },
  {
    "id": "8109042014",
    "district_id": "810904",
    "label": "Siopot",
    "value": "Siopot"
  },
  {
    "id": "8109042015",
    "district_id": "810904",
    "label": "Emguhen",
    "value": "Emguhen"
  },
  {
    "id": "8109052001",
    "district_id": "810905",
    "label": "Waemulang",
    "value": "Waemulang"
  },
  {
    "id": "8109052002",
    "district_id": "810905",
    "label": "Waeturen",
    "value": "Waeturen"
  },
  {
    "id": "8109052003",
    "district_id": "810905",
    "label": "Waehaka",
    "value": "Waehaka"
  },
  {
    "id": "8109052004",
    "district_id": "810905",
    "label": "Waemala",
    "value": "Waemala"
  },
  {
    "id": "8109052005",
    "district_id": "810905",
    "label": "Erwiri",
    "value": "Erwiri"
  },
  {
    "id": "8109052006",
    "district_id": "810905",
    "label": "Tifu",
    "value": "Tifu"
  },
  {
    "id": "8109052007",
    "district_id": "810905",
    "label": "Mepa",
    "value": "Mepa"
  },
  {
    "id": "8109052008",
    "district_id": "810905",
    "label": "Leksula",
    "value": "Leksula"
  },
  {
    "id": "8109052009",
    "district_id": "810905",
    "label": "Nalbessy",
    "value": "Nalbessy"
  },
  {
    "id": "8109052010",
    "district_id": "810905",
    "label": "Kase",
    "value": "Kase"
  },
  {
    "id": "8109052015",
    "district_id": "810905",
    "label": "Neath",
    "value": "Neath"
  },
  {
    "id": "8109052016",
    "district_id": "810905",
    "label": "Liang",
    "value": "Liang"
  },
  {
    "id": "8109052018",
    "district_id": "810905",
    "label": "Slealale",
    "value": "Slealale"
  },
  {
    "id": "8109052023",
    "district_id": "810905",
    "label": "Walunghelat",
    "value": "Walunghelat"
  },
  {
    "id": "8109052024",
    "district_id": "810905",
    "label": "Wahaolon",
    "value": "Wahaolon"
  },
  {
    "id": "8109052025",
    "district_id": "810905",
    "label": "Wewali",
    "value": "Wewali"
  },
  {
    "id": "8109052026",
    "district_id": "810905",
    "label": "Waenamaolon",
    "value": "Waenamaolon"
  },
  {
    "id": "8109052029",
    "district_id": "810905",
    "label": "Grahwaen",
    "value": "Grahwaen"
  },
  {
    "id": "8109052030",
    "district_id": "810905",
    "label": "Terkuri",
    "value": "Terkuri"
  },
  {
    "id": "8109062001",
    "district_id": "810906",
    "label": "Nusarua",
    "value": "Nusarua"
  },
  {
    "id": "8109062002",
    "district_id": "810906",
    "label": "Mangeswaen",
    "value": "Mangeswaen"
  },
  {
    "id": "8109062003",
    "district_id": "810906",
    "label": "Waekatin",
    "value": "Waekatin"
  },
  {
    "id": "8109062004",
    "district_id": "810906",
    "label": "Fakal",
    "value": "Fakal"
  },
  {
    "id": "8109062005",
    "district_id": "810906",
    "label": "Uneth",
    "value": "Uneth"
  },
  {
    "id": "8109062006",
    "district_id": "810906",
    "label": "Waereman",
    "value": "Waereman"
  },
  {
    "id": "8109062007",
    "district_id": "810906",
    "label": "Waelo",
    "value": "Waelo"
  },
  {
    "id": "8109062008",
    "district_id": "810906",
    "label": "Siwatlahin",
    "value": "Siwatlahin"
  },
  {
    "id": "8109062009",
    "district_id": "810906",
    "label": "Trukat",
    "value": "Trukat"
  },
  {
    "id": "8109062010",
    "district_id": "810906",
    "label": "Waeken",
    "value": "Waeken"
  },
  {
    "id": "8171011006",
    "district_id": "817101",
    "label": "Nusaniwe",
    "value": "Nusaniwe"
  },
  {
    "id": "8171011007",
    "district_id": "817101",
    "label": "Wainitu",
    "value": "Wainitu"
  },
  {
    "id": "8171011008",
    "district_id": "817101",
    "label": "Benteng",
    "value": "Benteng"
  },
  {
    "id": "8171011009",
    "district_id": "817101",
    "label": "Kudamati",
    "value": "Kudamati"
  },
  {
    "id": "8171011010",
    "district_id": "817101",
    "label": "Mangga Dua",
    "value": "Mangga Dua"
  },
  {
    "id": "8171011011",
    "district_id": "817101",
    "label": "Urimessing",
    "value": "Urimessing"
  },
  {
    "id": "8171011012",
    "district_id": "817101",
    "label": "Waihaong",
    "value": "Waihaong"
  },
  {
    "id": "8171011013",
    "district_id": "817101",
    "label": "Silale",
    "value": "Silale"
  },
  {
    "id": "8171012001",
    "district_id": "817101",
    "label": "Latuhalat",
    "value": "Latuhalat"
  },
  {
    "id": "8171012002",
    "district_id": "817101",
    "label": "Seilale",
    "value": "Seilale"
  },
  {
    "id": "8171012003",
    "district_id": "817101",
    "label": "Nusaniwe",
    "value": "Nusaniwe"
  },
  {
    "id": "8171012004",
    "district_id": "817101",
    "label": "Amahusu",
    "value": "Amahusu"
  },
  {
    "id": "8171012005",
    "district_id": "817101",
    "label": "Urimessing",
    "value": "Urimessing"
  },
  {
    "id": "8171021010",
    "district_id": "817102",
    "label": "Pandan Kasturi",
    "value": "Pandan Kasturi"
  },
  {
    "id": "8171021011",
    "district_id": "817102",
    "label": "Rijali",
    "value": "Rijali"
  },
  {
    "id": "8171021012",
    "district_id": "817102",
    "label": "Amantelu",
    "value": "Amantelu"
  },
  {
    "id": "8171021013",
    "district_id": "817102",
    "label": "Karang Panjang",
    "value": "Karang Panjang"
  },
  {
    "id": "8171021014",
    "district_id": "817102",
    "label": "Waihoka",
    "value": "Waihoka"
  },
  {
    "id": "8171021015",
    "district_id": "817102",
    "label": "Batu Meja",
    "value": "Batu Meja"
  },
  {
    "id": "8171021016",
    "district_id": "817102",
    "label": "Batu Gajah",
    "value": "Batu Gajah"
  },
  {
    "id": "8171021017",
    "district_id": "817102",
    "label": "Uritetu",
    "value": "Uritetu"
  },
  {
    "id": "8171021018",
    "district_id": "817102",
    "label": "Honipopu",
    "value": "Honipopu"
  },
  {
    "id": "8171021019",
    "district_id": "817102",
    "label": "Ahusen",
    "value": "Ahusen"
  },
  {
    "id": "8171022001",
    "district_id": "817102",
    "label": "Hative Kecil",
    "value": "Hative Kecil"
  },
  {
    "id": "8171022002",
    "district_id": "817102",
    "label": "Galala",
    "value": "Galala"
  },
  {
    "id": "8171022003",
    "district_id": "817102",
    "label": "Batu Merah",
    "value": "Batu Merah"
  },
  {
    "id": "8171022009",
    "district_id": "817102",
    "label": "Soya",
    "value": "Soya"
  },
  {
    "id": "8171031018",
    "district_id": "817103",
    "label": "Lateri",
    "value": "Lateri"
  },
  {
    "id": "8171032001",
    "district_id": "817103",
    "label": "Passo",
    "value": "Passo"
  },
  {
    "id": "8171032002",
    "district_id": "817103",
    "label": "Halong",
    "value": "Halong"
  },
  {
    "id": "8171032003",
    "district_id": "817103",
    "label": "Latta",
    "value": "Latta"
  },
  {
    "id": "8171032004",
    "district_id": "817103",
    "label": "Negeri Lama",
    "value": "Negeri Lama"
  },
  {
    "id": "8171032005",
    "district_id": "817103",
    "label": "Nania",
    "value": "Nania"
  },
  {
    "id": "8171032006",
    "district_id": "817103",
    "label": "Waiheru",
    "value": "Waiheru"
  },
  {
    "id": "8171041008",
    "district_id": "817104",
    "label": "Tihu",
    "value": "Tihu"
  },
  {
    "id": "8171042001",
    "district_id": "817104",
    "label": "Hunuth/Durian Patah",
    "value": "Hunuth/Durian Patah"
  },
  {
    "id": "8171042002",
    "district_id": "817104",
    "label": "Poka",
    "value": "Poka"
  },
  {
    "id": "8171042003",
    "district_id": "817104",
    "label": "Rumah Tiga",
    "value": "Rumah Tiga"
  },
  {
    "id": "8171042004",
    "district_id": "817104",
    "label": "Wayame",
    "value": "Wayame"
  },
  {
    "id": "8171042005",
    "district_id": "817104",
    "label": "Tawiri",
    "value": "Tawiri"
  },
  {
    "id": "8171042006",
    "district_id": "817104",
    "label": "Hative Besar",
    "value": "Hative Besar"
  },
  {
    "id": "8171042007",
    "district_id": "817104",
    "label": "Laha",
    "value": "Laha"
  },
  {
    "id": "8171052001",
    "district_id": "817105",
    "label": "Hukurila",
    "value": "Hukurila"
  },
  {
    "id": "8171052002",
    "district_id": "817105",
    "label": "Ema",
    "value": "Ema"
  },
  {
    "id": "8171052003",
    "district_id": "817105",
    "label": "Kilang",
    "value": "Kilang"
  },
  {
    "id": "8171052004",
    "district_id": "817105",
    "label": "Naku",
    "value": "Naku"
  },
  {
    "id": "8171052005",
    "district_id": "817105",
    "label": "Hatalai",
    "value": "Hatalai"
  },
  {
    "id": "8171052006",
    "district_id": "817105",
    "label": "Hutumury",
    "value": "Hutumury"
  },
  {
    "id": "8171052007",
    "district_id": "817105",
    "label": "Rutong",
    "value": "Rutong"
  },
  {
    "id": "8171052008",
    "district_id": "817105",
    "label": "Leahari",
    "value": "Leahari"
  },
  {
    "id": "8172012001",
    "district_id": "817201",
    "label": "Fiditan",
    "value": "Fiditan"
  },
  {
    "id": "8172012002",
    "district_id": "817201",
    "label": "Ngadi",
    "value": "Ngadi"
  },
  {
    "id": "8172012003",
    "district_id": "817201",
    "label": "Dullah",
    "value": "Dullah"
  },
  {
    "id": "8172012004",
    "district_id": "817201",
    "label": "Labetawi",
    "value": "Labetawi"
  },
  {
    "id": "8172012005",
    "district_id": "817201",
    "label": "Tamedan",
    "value": "Tamedan"
  },
  {
    "id": "8172012006",
    "district_id": "817201",
    "label": "Ohoitahit",
    "value": "Ohoitahit"
  },
  {
    "id": "8172012007",
    "district_id": "817201",
    "label": "Ohoitel",
    "value": "Ohoitel"
  },
  {
    "id": "8172012008",
    "district_id": "817201",
    "label": "Dullah Laut",
    "value": "Dullah Laut"
  },
  {
    "id": "8172021003",
    "district_id": "817202",
    "label": "Ketsoblak",
    "value": "Ketsoblak"
  },
  {
    "id": "8172021004",
    "district_id": "817202",
    "label": "Masrum",
    "value": "Masrum"
  },
  {
    "id": "8172021005",
    "district_id": "817202",
    "label": "Lodar El",
    "value": "Lodar El"
  },
  {
    "id": "8172022001",
    "district_id": "817202",
    "label": "Tual",
    "value": "Tual"
  },
  {
    "id": "8172022002",
    "district_id": "817202",
    "label": "Taar",
    "value": "Taar"
  },
  {
    "id": "8172032001",
    "district_id": "817203",
    "label": "Tam Ngurhir",
    "value": "Tam Ngurhir"
  },
  {
    "id": "8172032002",
    "district_id": "817203",
    "label": "Tayando Langgiar",
    "value": "Tayando Langgiar"
  },
  {
    "id": "8172032003",
    "district_id": "817203",
    "label": "Tayando Yamtel",
    "value": "Tayando Yamtel"
  },
  {
    "id": "8172032004",
    "district_id": "817203",
    "label": "Tayando Ohoiel",
    "value": "Tayando Ohoiel"
  },
  {
    "id": "8172032005",
    "district_id": "817203",
    "label": "Tayando Yamru",
    "value": "Tayando Yamru"
  },
  {
    "id": "8172042001",
    "district_id": "817204",
    "label": "Tubyal",
    "value": "Tubyal"
  },
  {
    "id": "8172042002",
    "district_id": "817204",
    "label": "Kaimear",
    "value": "Kaimear"
  },
  {
    "id": "8172042004",
    "district_id": "817204",
    "label": "Lokwirin",
    "value": "Lokwirin"
  },
  {
    "id": "8172042005",
    "district_id": "817204",
    "label": "Finualen",
    "value": "Finualen"
  },
  {
    "id": "8172042012",
    "district_id": "817204",
    "label": "Sermaf",
    "value": "Sermaf"
  },
  {
    "id": "8172052001",
    "district_id": "817205",
    "label": "Kanara",
    "value": "Kanara"
  },
  {
    "id": "8172052002",
    "district_id": "817205",
    "label": "Warkar",
    "value": "Warkar"
  },
  {
    "id": "8172052003",
    "district_id": "817205",
    "label": "Yapas",
    "value": "Yapas"
  },
  {
    "id": "8172052004",
    "district_id": "817205",
    "label": "Rumoin",
    "value": "Rumoin"
  },
  {
    "id": "8172052005",
    "district_id": "817205",
    "label": "Hirit",
    "value": "Hirit"
  },
  {
    "id": "8172052006",
    "district_id": "817205",
    "label": "Tiflen",
    "value": "Tiflen"
  },
  {
    "id": "8172052007",
    "district_id": "817205",
    "label": "Niela",
    "value": "Niela"
  },
  {
    "id": "8201012001",
    "district_id": "820101",
    "label": "Gufasa",
    "value": "Gufasa"
  },
  {
    "id": "8201012002",
    "district_id": "820101",
    "label": "Guaimaadu",
    "value": "Guaimaadu"
  },
  {
    "id": "8201012003",
    "district_id": "820101",
    "label": "Jalan Baru",
    "value": "Jalan Baru"
  },
  {
    "id": "8201012004",
    "district_id": "820101",
    "label": "Gam Lamo",
    "value": "Gam Lamo"
  },
  {
    "id": "8201012005",
    "district_id": "820101",
    "label": "Soakonora",
    "value": "Soakonora"
  },
  {
    "id": "8201012006",
    "district_id": "820101",
    "label": "Galala",
    "value": "Galala"
  },
  {
    "id": "8201012007",
    "district_id": "820101",
    "label": "Bobanehena",
    "value": "Bobanehena"
  },
  {
    "id": "8201012008",
    "district_id": "820101",
    "label": "Payo",
    "value": "Payo"
  },
  {
    "id": "8201012009",
    "district_id": "820101",
    "label": "Bobo",
    "value": "Bobo"
  },
  {
    "id": "8201012010",
    "district_id": "820101",
    "label": "Saria",
    "value": "Saria"
  },
  {
    "id": "8201012011",
    "district_id": "820101",
    "label": "Idam Dehe",
    "value": "Idam Dehe"
  },
  {
    "id": "8201012012",
    "district_id": "820101",
    "label": "Idamdehe Gamsungi",
    "value": "Idamdehe Gamsungi"
  },
  {
    "id": "8201012013",
    "district_id": "820101",
    "label": "Marmabati",
    "value": "Marmabati"
  },
  {
    "id": "8201012014",
    "district_id": "820101",
    "label": "Gamtala",
    "value": "Gamtala"
  },
  {
    "id": "8201012015",
    "district_id": "820101",
    "label": "Lolory",
    "value": "Lolory"
  },
  {
    "id": "8201012016",
    "district_id": "820101",
    "label": "Toboso",
    "value": "Toboso"
  },
  {
    "id": "8201012017",
    "district_id": "820101",
    "label": "Huku-Huku Kie",
    "value": "Huku-Huku Kie"
  },
  {
    "id": "8201012018",
    "district_id": "820101",
    "label": "Acango",
    "value": "Acango"
  },
  {
    "id": "8201012019",
    "district_id": "820101",
    "label": "Tedeng",
    "value": "Tedeng"
  },
  {
    "id": "8201012020",
    "district_id": "820101",
    "label": "Akediri",
    "value": "Akediri"
  },
  {
    "id": "8201012021",
    "district_id": "820101",
    "label": "Pornity",
    "value": "Pornity"
  },
  {
    "id": "8201012022",
    "district_id": "820101",
    "label": "Buku Matiti",
    "value": "Buku Matiti"
  },
  {
    "id": "8201012023",
    "district_id": "820101",
    "label": "Todowongi",
    "value": "Todowongi"
  },
  {
    "id": "8201012024",
    "district_id": "820101",
    "label": "Tuada",
    "value": "Tuada"
  },
  {
    "id": "8201012025",
    "district_id": "820101",
    "label": "Matui",
    "value": "Matui"
  },
  {
    "id": "8201012026",
    "district_id": "820101",
    "label": "Buku Bualawa",
    "value": "Buku Bualawa"
  },
  {
    "id": "8201012027",
    "district_id": "820101",
    "label": "Tauro",
    "value": "Tauro"
  },
  {
    "id": "8201012034",
    "district_id": "820101",
    "label": "Hate Bicara",
    "value": "Hate Bicara"
  },
  {
    "id": "8201012035",
    "district_id": "820101",
    "label": "Guaria",
    "value": "Guaria"
  },
  {
    "id": "8201012036",
    "district_id": "820101",
    "label": "Pateng",
    "value": "Pateng"
  },
  {
    "id": "8201012037",
    "district_id": "820101",
    "label": "Bobo Jiko",
    "value": "Bobo Jiko"
  },
  {
    "id": "8201012038",
    "district_id": "820101",
    "label": "Ulo",
    "value": "Ulo"
  },
  {
    "id": "8201012039",
    "district_id": "820101",
    "label": "Kuripasai",
    "value": "Kuripasai"
  },
  {
    "id": "8201012040",
    "district_id": "820101",
    "label": "Buku Maadu",
    "value": "Buku Maadu"
  },
  {
    "id": "8201022001",
    "district_id": "820102",
    "label": "Kedi",
    "value": "Kedi"
  },
  {
    "id": "8201022002",
    "district_id": "820102",
    "label": "Soasio",
    "value": "Soasio"
  },
  {
    "id": "8201022003",
    "district_id": "820102",
    "label": "Totala",
    "value": "Totala"
  },
  {
    "id": "8201022004",
    "district_id": "820102",
    "label": "Tolofuo",
    "value": "Tolofuo"
  },
  {
    "id": "8201022005",
    "district_id": "820102",
    "label": "Tuguis",
    "value": "Tuguis"
  },
  {
    "id": "8201022006",
    "district_id": "820102",
    "label": "Tasye",
    "value": "Tasye"
  },
  {
    "id": "8201022007",
    "district_id": "820102",
    "label": "Laba Besar",
    "value": "Laba Besar"
  },
  {
    "id": "8201022008",
    "district_id": "820102",
    "label": "Laba Kecil",
    "value": "Laba Kecil"
  },
  {
    "id": "8201022009",
    "district_id": "820102",
    "label": "Jano",
    "value": "Jano"
  },
  {
    "id": "8201022010",
    "district_id": "820102",
    "label": "Bakun",
    "value": "Bakun"
  },
  {
    "id": "8201022011",
    "district_id": "820102",
    "label": "Buo",
    "value": "Buo"
  },
  {
    "id": "8201022012",
    "district_id": "820102",
    "label": "Bakun Pante",
    "value": "Bakun Pante"
  },
  {
    "id": "8201022013",
    "district_id": "820102",
    "label": "Barataku",
    "value": "Barataku"
  },
  {
    "id": "8201022014",
    "district_id": "820102",
    "label": "Gam Kahe",
    "value": "Gam Kahe"
  },
  {
    "id": "8201022015",
    "district_id": "820102",
    "label": "Puma Dadar",
    "value": "Puma Dadar"
  },
  {
    "id": "8201022016",
    "district_id": "820102",
    "label": "Baja",
    "value": "Baja"
  },
  {
    "id": "8201022018",
    "district_id": "820102",
    "label": "Kahatola",
    "value": "Kahatola"
  },
  {
    "id": "8201022019",
    "district_id": "820102",
    "label": "Bosala",
    "value": "Bosala"
  },
  {
    "id": "8201022020",
    "district_id": "820102",
    "label": "Salu",
    "value": "Salu"
  },
  {
    "id": "8201022021",
    "district_id": "820102",
    "label": "Tosomolo",
    "value": "Tosomolo"
  },
  {
    "id": "8201022022",
    "district_id": "820102",
    "label": "Jange Lili",
    "value": "Jange Lili"
  },
  {
    "id": "8201022023",
    "district_id": "820102",
    "label": "Bantoli",
    "value": "Bantoli"
  },
  {
    "id": "8201022024",
    "district_id": "820102",
    "label": "Linggua",
    "value": "Linggua"
  },
  {
    "id": "8201022025",
    "district_id": "820102",
    "label": "Tomodo",
    "value": "Tomodo"
  },
  {
    "id": "8201022026",
    "district_id": "820102",
    "label": "Totala Jaya",
    "value": "Totala Jaya"
  },
  {
    "id": "8201022027",
    "district_id": "820102",
    "label": "Bilote",
    "value": "Bilote"
  },
  {
    "id": "8201022028",
    "district_id": "820102",
    "label": "Aruku",
    "value": "Aruku"
  },
  {
    "id": "8201032012",
    "district_id": "820103",
    "label": "Togola Wayoli",
    "value": "Togola Wayoli"
  },
  {
    "id": "8201032013",
    "district_id": "820103",
    "label": "Togola Sangir",
    "value": "Togola Sangir"
  },
  {
    "id": "8201032014",
    "district_id": "820103",
    "label": "Tahafo",
    "value": "Tahafo"
  },
  {
    "id": "8201032015",
    "district_id": "820103",
    "label": "Tongute Ternate",
    "value": "Tongute Ternate"
  },
  {
    "id": "8201032016",
    "district_id": "820103",
    "label": "Akesibu",
    "value": "Akesibu"
  },
  {
    "id": "8201032017",
    "district_id": "820103",
    "label": "Maritango",
    "value": "Maritango"
  },
  {
    "id": "8201032018",
    "district_id": "820103",
    "label": "Kie Ici",
    "value": "Kie Ici"
  },
  {
    "id": "8201032019",
    "district_id": "820103",
    "label": "Tongute Goin",
    "value": "Tongute Goin"
  },
  {
    "id": "8201032020",
    "district_id": "820103",
    "label": "Tungute Sungi",
    "value": "Tungute Sungi"
  },
  {
    "id": "8201032021",
    "district_id": "820103",
    "label": "Gam Ici",
    "value": "Gam Ici"
  },
  {
    "id": "8201032022",
    "district_id": "820103",
    "label": "Tobaol",
    "value": "Tobaol"
  },
  {
    "id": "8201032023",
    "district_id": "820103",
    "label": "Gamlamo",
    "value": "Gamlamo"
  },
  {
    "id": "8201032037",
    "district_id": "820103",
    "label": "Naga",
    "value": "Naga"
  },
  {
    "id": "8201032040",
    "district_id": "820103",
    "label": "Soana Masungi",
    "value": "Soana Masungi"
  },
  {
    "id": "8201032041",
    "district_id": "820103",
    "label": "Tongute Ternate Selatan",
    "value": "Tongute Ternate Selatan"
  },
  {
    "id": "8201032042",
    "district_id": "820103",
    "label": "Ake Boso",
    "value": "Ake Boso"
  },
  {
    "id": "8201032043",
    "district_id": "820103",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8201042001",
    "district_id": "820104",
    "label": "Susupu",
    "value": "Susupu"
  },
  {
    "id": "8201042002",
    "district_id": "820104",
    "label": "Lako Akelamo",
    "value": "Lako Akelamo"
  },
  {
    "id": "8201042003",
    "district_id": "820104",
    "label": "Taruba",
    "value": "Taruba"
  },
  {
    "id": "8201042004",
    "district_id": "820104",
    "label": "Todahe",
    "value": "Todahe"
  },
  {
    "id": "8201042005",
    "district_id": "820104",
    "label": "Peot",
    "value": "Peot"
  },
  {
    "id": "8201042006",
    "district_id": "820104",
    "label": "Sasur",
    "value": "Sasur"
  },
  {
    "id": "8201042007",
    "district_id": "820104",
    "label": "Goro goro",
    "value": "Goro goro"
  },
  {
    "id": "8201042008",
    "district_id": "820104",
    "label": "Tacim",
    "value": "Tacim"
  },
  {
    "id": "8201042009",
    "district_id": "820104",
    "label": "Balisoang",
    "value": "Balisoang"
  },
  {
    "id": "8201042010",
    "district_id": "820104",
    "label": "Worat-worat",
    "value": "Worat-worat"
  },
  {
    "id": "8201042011",
    "district_id": "820104",
    "label": "Tacici",
    "value": "Tacici"
  },
  {
    "id": "8201042012",
    "district_id": "820104",
    "label": "Taraudu",
    "value": "Taraudu"
  },
  {
    "id": "8201042013",
    "district_id": "820104",
    "label": "Golo",
    "value": "Golo"
  },
  {
    "id": "8201042028",
    "district_id": "820104",
    "label": "Lako Akederi",
    "value": "Lako Akederi"
  },
  {
    "id": "8201042029",
    "district_id": "820104",
    "label": "Jara Kore",
    "value": "Jara Kore"
  },
  {
    "id": "8201042030",
    "district_id": "820104",
    "label": "Ropu Tengah Balu",
    "value": "Ropu Tengah Balu"
  },
  {
    "id": "8201042033",
    "district_id": "820104",
    "label": "Dere",
    "value": "Dere"
  },
  {
    "id": "8201042034",
    "district_id": "820104",
    "label": "Balisoan Utara",
    "value": "Balisoan Utara"
  },
  {
    "id": "8201042035",
    "district_id": "820104",
    "label": "Sasur Pantai",
    "value": "Sasur Pantai"
  },
  {
    "id": "8201052001",
    "district_id": "820105",
    "label": "Sidangoli Gam",
    "value": "Sidangoli Gam"
  },
  {
    "id": "8201052002",
    "district_id": "820105",
    "label": "Sidongoli Dehe",
    "value": "Sidongoli Dehe"
  },
  {
    "id": "8201052003",
    "district_id": "820105",
    "label": "Domato",
    "value": "Domato"
  },
  {
    "id": "8201052004",
    "district_id": "820105",
    "label": "Tataleka",
    "value": "Tataleka"
  },
  {
    "id": "8201052005",
    "district_id": "820105",
    "label": "Gamlenge",
    "value": "Gamlenge"
  },
  {
    "id": "8201052006",
    "district_id": "820105",
    "label": "Akejailolo",
    "value": "Akejailolo"
  },
  {
    "id": "8201052007",
    "district_id": "820105",
    "label": "Biamaahi",
    "value": "Biamaahi"
  },
  {
    "id": "8201052008",
    "district_id": "820105",
    "label": "Tuguraci",
    "value": "Tuguraci"
  },
  {
    "id": "8201052009",
    "district_id": "820105",
    "label": "Akeara",
    "value": "Akeara"
  },
  {
    "id": "8201052010",
    "district_id": "820105",
    "label": "Akelaha",
    "value": "Akelaha"
  },
  {
    "id": "8201052011",
    "district_id": "820105",
    "label": "Dodinga",
    "value": "Dodinga"
  },
  {
    "id": "8201052012",
    "district_id": "820105",
    "label": "Tewe",
    "value": "Tewe"
  },
  {
    "id": "8201052013",
    "district_id": "820105",
    "label": "Toniku",
    "value": "Toniku"
  },
  {
    "id": "8201052014",
    "district_id": "820105",
    "label": "Rioribati",
    "value": "Rioribati"
  },
  {
    "id": "8201052021",
    "district_id": "820105",
    "label": "Taba Damai (DB)",
    "value": "Taba Damai (DB)"
  },
  {
    "id": "8201052022",
    "district_id": "820105",
    "label": "Moiso",
    "value": "Moiso"
  },
  {
    "id": "8201052023",
    "district_id": "820105",
    "label": "Braha",
    "value": "Braha"
  },
  {
    "id": "8201052024",
    "district_id": "820105",
    "label": "Bangkit Rahmat",
    "value": "Bangkit Rahmat"
  },
  {
    "id": "8201052025",
    "district_id": "820105",
    "label": "Bobane Dano",
    "value": "Bobane Dano"
  },
  {
    "id": "8201052026",
    "district_id": "820105",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "8201052027",
    "district_id": "820105",
    "label": "Hijrah",
    "value": "Hijrah"
  },
  {
    "id": "8201052028",
    "district_id": "820105",
    "label": "Ratem",
    "value": "Ratem"
  },
  {
    "id": "8201072001",
    "district_id": "820107",
    "label": "Podol",
    "value": "Podol"
  },
  {
    "id": "8201072002",
    "district_id": "820107",
    "label": "Tengowango",
    "value": "Tengowango"
  },
  {
    "id": "8201072003",
    "district_id": "820107",
    "label": "Togowo",
    "value": "Togowo"
  },
  {
    "id": "8201072004",
    "district_id": "820107",
    "label": "Duono",
    "value": "Duono"
  },
  {
    "id": "8201072005",
    "district_id": "820107",
    "label": "Goin",
    "value": "Goin"
  },
  {
    "id": "8201072006",
    "district_id": "820107",
    "label": "Sangaji Nyeku",
    "value": "Sangaji Nyeku"
  },
  {
    "id": "8201072007",
    "district_id": "820107",
    "label": "Tuguis",
    "value": "Tuguis"
  },
  {
    "id": "8201072008",
    "district_id": "820107",
    "label": "Togoreba Sungi",
    "value": "Togoreba Sungi"
  },
  {
    "id": "8201072009",
    "district_id": "820107",
    "label": "Barona",
    "value": "Barona"
  },
  {
    "id": "8201072010",
    "district_id": "820107",
    "label": "Todoke",
    "value": "Todoke"
  },
  {
    "id": "8201072011",
    "district_id": "820107",
    "label": "Tolisaor",
    "value": "Tolisaor"
  },
  {
    "id": "8201072012",
    "district_id": "820107",
    "label": "Pasalulu",
    "value": "Pasalulu"
  },
  {
    "id": "8201072013",
    "district_id": "820107",
    "label": "Togoreba Tua",
    "value": "Togoreba Tua"
  },
  {
    "id": "8201072014",
    "district_id": "820107",
    "label": "Tukuoku",
    "value": "Tukuoku"
  },
  {
    "id": "8201072015",
    "district_id": "820107",
    "label": "Soasangaji",
    "value": "Soasangaji"
  },
  {
    "id": "8201072016",
    "district_id": "820107",
    "label": "Aru Jaya",
    "value": "Aru Jaya"
  },
  {
    "id": "8201082001",
    "district_id": "820108",
    "label": "Tosoa",
    "value": "Tosoa"
  },
  {
    "id": "8201082002",
    "district_id": "820108",
    "label": "Tobobol",
    "value": "Tobobol"
  },
  {
    "id": "8201082003",
    "district_id": "820108",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "8201082004",
    "district_id": "820108",
    "label": "Nanas",
    "value": "Nanas"
  },
  {
    "id": "8201082005",
    "district_id": "820108",
    "label": "Jere",
    "value": "Jere"
  },
  {
    "id": "8201082006",
    "district_id": "820108",
    "label": "Gamsungi",
    "value": "Gamsungi"
  },
  {
    "id": "8201082007",
    "district_id": "820108",
    "label": "Bataka",
    "value": "Bataka"
  },
  {
    "id": "8201082008",
    "district_id": "820108",
    "label": "Talaga",
    "value": "Talaga"
  },
  {
    "id": "8201082009",
    "district_id": "820108",
    "label": "Tobelos",
    "value": "Tobelos"
  },
  {
    "id": "8201082010",
    "district_id": "820108",
    "label": "Gamkonora",
    "value": "Gamkonora"
  },
  {
    "id": "8201082011",
    "district_id": "820108",
    "label": "Sarau",
    "value": "Sarau"
  },
  {
    "id": "8201082012",
    "district_id": "820108",
    "label": "Ngawet",
    "value": "Ngawet"
  },
  {
    "id": "8201082013",
    "district_id": "820108",
    "label": "Adu",
    "value": "Adu"
  },
  {
    "id": "8201082014",
    "district_id": "820108",
    "label": "Gamsida",
    "value": "Gamsida"
  },
  {
    "id": "8201082015",
    "district_id": "820108",
    "label": "Ngalo Ngalo",
    "value": "Ngalo Ngalo"
  },
  {
    "id": "8201082016",
    "district_id": "820108",
    "label": "Tuguaer",
    "value": "Tuguaer"
  },
  {
    "id": "8201092001",
    "district_id": "820109",
    "label": "Loce",
    "value": "Loce"
  },
  {
    "id": "8201092002",
    "district_id": "820109",
    "label": "Idam Gamlamo",
    "value": "Idam Gamlamo"
  },
  {
    "id": "8201092003",
    "district_id": "820109",
    "label": "Gamomeng",
    "value": "Gamomeng"
  },
  {
    "id": "8201092004",
    "district_id": "820109",
    "label": "Akelamo",
    "value": "Akelamo"
  },
  {
    "id": "8201092005",
    "district_id": "820109",
    "label": "Taraudu Kusu",
    "value": "Taraudu Kusu"
  },
  {
    "id": "8201092006",
    "district_id": "820109",
    "label": "Awer",
    "value": "Awer"
  },
  {
    "id": "8201092007",
    "district_id": "820109",
    "label": "Aketola",
    "value": "Aketola"
  },
  {
    "id": "8201092008",
    "district_id": "820109",
    "label": "Tibobo",
    "value": "Tibobo"
  },
  {
    "id": "8201092009",
    "district_id": "820109",
    "label": "Hoku- hoku Gam",
    "value": "Hoku- hoku Gam"
  },
  {
    "id": "8201092010",
    "district_id": "820109",
    "label": "Gamiyel",
    "value": "Gamiyel"
  },
  {
    "id": "8201092011",
    "district_id": "820109",
    "label": "Golago Kusuma",
    "value": "Golago Kusuma"
  },
  {
    "id": "8201092012",
    "district_id": "820109",
    "label": "Ngaon",
    "value": "Ngaon"
  },
  {
    "id": "8201092013",
    "district_id": "820109",
    "label": "Gamsugi",
    "value": "Gamsugi"
  },
  {
    "id": "8201092014",
    "district_id": "820109",
    "label": "Taba Campaka",
    "value": "Taba Campaka"
  },
  {
    "id": "8201092015",
    "district_id": "820109",
    "label": "Campaka",
    "value": "Campaka"
  },
  {
    "id": "8201092016",
    "district_id": "820109",
    "label": "Goal",
    "value": "Goal"
  },
  {
    "id": "8201092017",
    "district_id": "820109",
    "label": "Sidodadi",
    "value": "Sidodadi"
  },
  {
    "id": "8201092018",
    "district_id": "820109",
    "label": "Air Panas",
    "value": "Air Panas"
  },
  {
    "id": "8202012004",
    "district_id": "820201",
    "label": "Nusliko",
    "value": "Nusliko"
  },
  {
    "id": "8202012005",
    "district_id": "820201",
    "label": "Were",
    "value": "Were"
  },
  {
    "id": "8202012020",
    "district_id": "820201",
    "label": "Fidy Jaya",
    "value": "Fidy Jaya"
  },
  {
    "id": "8202012021",
    "district_id": "820201",
    "label": "Sidanga",
    "value": "Sidanga"
  },
  {
    "id": "8202012022",
    "district_id": "820201",
    "label": "Nurweda",
    "value": "Nurweda"
  },
  {
    "id": "8202012023",
    "district_id": "820201",
    "label": "Wedana",
    "value": "Wedana"
  },
  {
    "id": "8202012024",
    "district_id": "820201",
    "label": "Goeng",
    "value": "Goeng"
  },
  {
    "id": "8202022004",
    "district_id": "820202",
    "label": "Wailegi",
    "value": "Wailegi"
  },
  {
    "id": "8202022005",
    "district_id": "820202",
    "label": "Kipai",
    "value": "Kipai"
  },
  {
    "id": "8202022006",
    "district_id": "820202",
    "label": "Yeisowo",
    "value": "Yeisowo"
  },
  {
    "id": "8202022012",
    "district_id": "820202",
    "label": "Yondeliu",
    "value": "Yondeliu"
  },
  {
    "id": "8202022013",
    "district_id": "820202",
    "label": "Baka Jaya",
    "value": "Baka Jaya"
  },
  {
    "id": "8202032001",
    "district_id": "820203",
    "label": "Kapaleo",
    "value": "Kapaleo"
  },
  {
    "id": "8202032002",
    "district_id": "820203",
    "label": "Kacepi",
    "value": "Kacepi"
  },
  {
    "id": "8202032003",
    "district_id": "820203",
    "label": "Sanafi",
    "value": "Sanafi"
  },
  {
    "id": "8202032004",
    "district_id": "820203",
    "label": "Umera",
    "value": "Umera"
  },
  {
    "id": "8202032005",
    "district_id": "820203",
    "label": "Umiyal",
    "value": "Umiyal"
  },
  {
    "id": "8202032006",
    "district_id": "820203",
    "label": "Sonof Kacepo",
    "value": "Sonof Kacepo"
  },
  {
    "id": "8202032007",
    "district_id": "820203",
    "label": "Elfanun",
    "value": "Elfanun"
  },
  {
    "id": "8202032008",
    "district_id": "820203",
    "label": "Yang",
    "value": "Yang"
  },
  {
    "id": "8202042001",
    "district_id": "820204",
    "label": "Gemaf",
    "value": "Gemaf"
  },
  {
    "id": "8202042002",
    "district_id": "820204",
    "label": "Sagea",
    "value": "Sagea"
  },
  {
    "id": "8202042003",
    "district_id": "820204",
    "label": "Fritu",
    "value": "Fritu"
  },
  {
    "id": "8202042004",
    "district_id": "820204",
    "label": "Waleh",
    "value": "Waleh"
  },
  {
    "id": "8202042008",
    "district_id": "820204",
    "label": "Kiya",
    "value": "Kiya"
  },
  {
    "id": "8202052001",
    "district_id": "820205",
    "label": "Tilope",
    "value": "Tilope"
  },
  {
    "id": "8202052002",
    "district_id": "820205",
    "label": "Sosowomo",
    "value": "Sosowomo"
  },
  {
    "id": "8202052003",
    "district_id": "820205",
    "label": "Loleo",
    "value": "Loleo"
  },
  {
    "id": "8202052004",
    "district_id": "820205",
    "label": "Wairoro Indah",
    "value": "Wairoro Indah"
  },
  {
    "id": "8202052005",
    "district_id": "820205",
    "label": "Kluting Jaya",
    "value": "Kluting Jaya"
  },
  {
    "id": "8202052006",
    "district_id": "820205",
    "label": "Lembah Asri",
    "value": "Lembah Asri"
  },
  {
    "id": "8202052007",
    "district_id": "820205",
    "label": "Sumber Sari",
    "value": "Sumber Sari"
  },
  {
    "id": "8202052008",
    "district_id": "820205",
    "label": "Air Salobar",
    "value": "Air Salobar"
  },
  {
    "id": "8202062001",
    "district_id": "820206",
    "label": "Gemia",
    "value": "Gemia"
  },
  {
    "id": "8202062002",
    "district_id": "820206",
    "label": "Tepeleo",
    "value": "Tepeleo"
  },
  {
    "id": "8202062006",
    "district_id": "820206",
    "label": "Bilifitu",
    "value": "Bilifitu"
  },
  {
    "id": "8202062007",
    "district_id": "820206",
    "label": "Tepeleo Batudua",
    "value": "Tepeleo Batudua"
  },
  {
    "id": "8202062008",
    "district_id": "820206",
    "label": "Pantura Jaya",
    "value": "Pantura Jaya"
  },
  {
    "id": "8202062011",
    "district_id": "820206",
    "label": "Maliforo",
    "value": "Maliforo"
  },
  {
    "id": "8202072001",
    "district_id": "820207",
    "label": "Lililef Waibulan",
    "value": "Lililef Waibulan"
  },
  {
    "id": "8202072002",
    "district_id": "820207",
    "label": "Lililef Sawai",
    "value": "Lililef Sawai"
  },
  {
    "id": "8202072003",
    "district_id": "820207",
    "label": "Sawai Itepo",
    "value": "Sawai Itepo"
  },
  {
    "id": "8202072004",
    "district_id": "820207",
    "label": "Kobe",
    "value": "Kobe"
  },
  {
    "id": "8202072005",
    "district_id": "820207",
    "label": "Woekob",
    "value": "Woekob"
  },
  {
    "id": "8202072006",
    "district_id": "820207",
    "label": "Woejerana",
    "value": "Woejerana"
  },
  {
    "id": "8202072007",
    "district_id": "820207",
    "label": "Kulo Jaya",
    "value": "Kulo Jaya"
  },
  {
    "id": "8202082001",
    "district_id": "820208",
    "label": "Banemo",
    "value": "Banemo"
  },
  {
    "id": "8202082002",
    "district_id": "820208",
    "label": "Bobane Jaya",
    "value": "Bobane Jaya"
  },
  {
    "id": "8202082003",
    "district_id": "820208",
    "label": "Bobane Indah",
    "value": "Bobane Indah"
  },
  {
    "id": "8202082004",
    "district_id": "820208",
    "label": "Mareala",
    "value": "Mareala"
  },
  {
    "id": "8202082005",
    "district_id": "820208",
    "label": "Sibenpope",
    "value": "Sibenpope"
  },
  {
    "id": "8202092001",
    "district_id": "820209",
    "label": "Dotte",
    "value": "Dotte"
  },
  {
    "id": "8202092002",
    "district_id": "820209",
    "label": "Messa",
    "value": "Messa"
  },
  {
    "id": "8202092003",
    "district_id": "820209",
    "label": "Kotalo",
    "value": "Kotalo"
  },
  {
    "id": "8202092004",
    "district_id": "820209",
    "label": "Yeke",
    "value": "Yeke"
  },
  {
    "id": "8202102001",
    "district_id": "820210",
    "label": "Sakam",
    "value": "Sakam"
  },
  {
    "id": "8202102002",
    "district_id": "820210",
    "label": "Nursifa",
    "value": "Nursifa"
  },
  {
    "id": "8202102003",
    "district_id": "820210",
    "label": "Damuli",
    "value": "Damuli"
  },
  {
    "id": "8202102004",
    "district_id": "820210",
    "label": "Peniti",
    "value": "Peniti"
  },
  {
    "id": "8202102005",
    "district_id": "820210",
    "label": "Masure",
    "value": "Masure"
  },
  {
    "id": "8202102006",
    "district_id": "820210",
    "label": "Palo",
    "value": "Palo"
  },
  {
    "id": "8203042001",
    "district_id": "820304",
    "label": "Soa-Sio",
    "value": "Soa-Sio"
  },
  {
    "id": "8203042002",
    "district_id": "820304",
    "label": "Pune",
    "value": "Pune"
  },
  {
    "id": "8203042003",
    "district_id": "820304",
    "label": "Mamuya",
    "value": "Mamuya"
  },
  {
    "id": "8203042016",
    "district_id": "820304",
    "label": "Toweka",
    "value": "Toweka"
  },
  {
    "id": "8203042024",
    "district_id": "820304",
    "label": "Simau",
    "value": "Simau"
  },
  {
    "id": "8203042031",
    "district_id": "820304",
    "label": "Barataku",
    "value": "Barataku"
  },
  {
    "id": "8203042032",
    "district_id": "820304",
    "label": "Towara",
    "value": "Towara"
  },
  {
    "id": "8203052004",
    "district_id": "820305",
    "label": "Gamsungi",
    "value": "Gamsungi"
  },
  {
    "id": "8203052005",
    "district_id": "820305",
    "label": "Gura",
    "value": "Gura"
  },
  {
    "id": "8203052006",
    "district_id": "820305",
    "label": "Wari",
    "value": "Wari"
  },
  {
    "id": "8203052012",
    "district_id": "820305",
    "label": "Kakara",
    "value": "Kakara"
  },
  {
    "id": "8203052013",
    "district_id": "820305",
    "label": "Kumo",
    "value": "Kumo"
  },
  {
    "id": "8203052014",
    "district_id": "820305",
    "label": "Gosoma",
    "value": "Gosoma"
  },
  {
    "id": "8203052015",
    "district_id": "820305",
    "label": "Rawajaya",
    "value": "Rawajaya"
  },
  {
    "id": "8203052018",
    "district_id": "820305",
    "label": "MKCM",
    "value": "MKCM"
  },
  {
    "id": "8203052024",
    "district_id": "820305",
    "label": "Tagalaya",
    "value": "Tagalaya"
  },
  {
    "id": "8203052025",
    "district_id": "820305",
    "label": "Wari Ino",
    "value": "Wari Ino"
  },
  {
    "id": "8203062001",
    "district_id": "820306",
    "label": "Kupa Kupa",
    "value": "Kupa Kupa"
  },
  {
    "id": "8203062002",
    "district_id": "820306",
    "label": "Gamhoku",
    "value": "Gamhoku"
  },
  {
    "id": "8203062003",
    "district_id": "820306",
    "label": "Efi Efi",
    "value": "Efi Efi"
  },
  {
    "id": "8203062004",
    "district_id": "820306",
    "label": "Tomahalu",
    "value": "Tomahalu"
  },
  {
    "id": "8203062005",
    "district_id": "820306",
    "label": "Paca",
    "value": "Paca"
  },
  {
    "id": "8203062006",
    "district_id": "820306",
    "label": "Leleoto",
    "value": "Leleoto"
  },
  {
    "id": "8203062009",
    "district_id": "820306",
    "label": "Tobe",
    "value": "Tobe"
  },
  {
    "id": "8203062015",
    "district_id": "820306",
    "label": "Kakara B",
    "value": "Kakara B"
  },
  {
    "id": "8203062018",
    "district_id": "820306",
    "label": "Talaga Paca",
    "value": "Talaga Paca"
  },
  {
    "id": "8203062020",
    "district_id": "820306",
    "label": "Tioua",
    "value": "Tioua"
  },
  {
    "id": "8203062021",
    "district_id": "820306",
    "label": "Pale",
    "value": "Pale"
  },
  {
    "id": "8203062022",
    "district_id": "820306",
    "label": "Kupa-kupa Selatan (Halehe)",
    "value": "Kupa-kupa Selatan (Halehe)"
  },
  {
    "id": "8203062024",
    "district_id": "820306",
    "label": "Lemah Ino",
    "value": "Lemah Ino"
  },
  {
    "id": "8203072001",
    "district_id": "820307",
    "label": "Kao",
    "value": "Kao"
  },
  {
    "id": "8203072002",
    "district_id": "820307",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "8203072003",
    "district_id": "820307",
    "label": "Kusu",
    "value": "Kusu"
  },
  {
    "id": "8203072009",
    "district_id": "820307",
    "label": "Waringin Lelewi",
    "value": "Waringin Lelewi"
  },
  {
    "id": "8203072010",
    "district_id": "820307",
    "label": "Soa Sangaji Dim-Dim",
    "value": "Soa Sangaji Dim-Dim"
  },
  {
    "id": "8203072011",
    "district_id": "820307",
    "label": "Sasur",
    "value": "Sasur"
  },
  {
    "id": "8203072012",
    "district_id": "820307",
    "label": "Popon",
    "value": "Popon"
  },
  {
    "id": "8203072036",
    "district_id": "820307",
    "label": "Biang",
    "value": "Biang"
  },
  {
    "id": "8203072037",
    "district_id": "820307",
    "label": "Patang",
    "value": "Patang"
  },
  {
    "id": "8203072038",
    "district_id": "820307",
    "label": "Kukumutuk",
    "value": "Kukumutuk"
  },
  {
    "id": "8203072039",
    "district_id": "820307",
    "label": "Waringin Lamo",
    "value": "Waringin Lamo"
  },
  {
    "id": "8203072050",
    "district_id": "820307",
    "label": "Goruang",
    "value": "Goruang"
  },
  {
    "id": "8203072051",
    "district_id": "820307",
    "label": "Kusu Lofra",
    "value": "Kusu Lofra"
  },
  {
    "id": "8203072052",
    "district_id": "820307",
    "label": "Sumber Agung",
    "value": "Sumber Agung"
  },
  {
    "id": "8203082001",
    "district_id": "820308",
    "label": "Ngofa Kiaha",
    "value": "Ngofa Kiaha"
  },
  {
    "id": "8203082002",
    "district_id": "820308",
    "label": "Ngofa Gita",
    "value": "Ngofa Gita"
  },
  {
    "id": "8203082003",
    "district_id": "820308",
    "label": "Samsuma",
    "value": "Samsuma"
  },
  {
    "id": "8203082004",
    "district_id": "820308",
    "label": "Tahane",
    "value": "Tahane"
  },
  {
    "id": "8203082005",
    "district_id": "820308",
    "label": "Matsa",
    "value": "Matsa"
  },
  {
    "id": "8203082007",
    "district_id": "820308",
    "label": "Bobawa",
    "value": "Bobawa"
  },
  {
    "id": "8203082008",
    "district_id": "820308",
    "label": "Talapao",
    "value": "Talapao"
  },
  {
    "id": "8203082009",
    "district_id": "820308",
    "label": "Tafasoho",
    "value": "Tafasoho"
  },
  {
    "id": "8203082010",
    "district_id": "820308",
    "label": "Sabaleh",
    "value": "Sabaleh"
  },
  {
    "id": "8203082011",
    "district_id": "820308",
    "label": "Tagono",
    "value": "Tagono"
  },
  {
    "id": "8203082012",
    "district_id": "820308",
    "label": "Soma",
    "value": "Soma"
  },
  {
    "id": "8203082013",
    "district_id": "820308",
    "label": "Ngofa Bobawa",
    "value": "Ngofa Bobawa"
  },
  {
    "id": "8203082014",
    "district_id": "820308",
    "label": "Malapa",
    "value": "Malapa"
  },
  {
    "id": "8203082015",
    "district_id": "820308",
    "label": "Mailoa",
    "value": "Mailoa"
  },
  {
    "id": "8203082016",
    "district_id": "820308",
    "label": "Peleri",
    "value": "Peleri"
  },
  {
    "id": "8203082019",
    "district_id": "820308",
    "label": "Bukit Tinggi",
    "value": "Bukit Tinggi"
  },
  {
    "id": "8203082022",
    "district_id": "820308",
    "label": "Terpadu",
    "value": "Terpadu"
  },
  {
    "id": "8203082023",
    "district_id": "820308",
    "label": "Tabobo",
    "value": "Tabobo"
  },
  {
    "id": "8203082024",
    "district_id": "820308",
    "label": "Balisosang",
    "value": "Balisosang"
  },
  {
    "id": "8203082025",
    "district_id": "820308",
    "label": "Sosol/Malifut",
    "value": "Sosol/Malifut"
  },
  {
    "id": "8203082026",
    "district_id": "820308",
    "label": "Wangeotek",
    "value": "Wangeotek"
  },
  {
    "id": "8203082027",
    "district_id": "820308",
    "label": "Gayok",
    "value": "Gayok"
  },
  {
    "id": "8203092001",
    "district_id": "820309",
    "label": "Dorume",
    "value": "Dorume"
  },
  {
    "id": "8203092002",
    "district_id": "820309",
    "label": "Apule",
    "value": "Apule"
  },
  {
    "id": "8203092003",
    "district_id": "820309",
    "label": "Asimiro",
    "value": "Asimiro"
  },
  {
    "id": "8203092004",
    "district_id": "820309",
    "label": "Doitia",
    "value": "Doitia"
  },
  {
    "id": "8203092005",
    "district_id": "820309",
    "label": "Ngajam",
    "value": "Ngajam"
  },
  {
    "id": "8203092006",
    "district_id": "820309",
    "label": "Kailupa",
    "value": "Kailupa"
  },
  {
    "id": "8203092007",
    "district_id": "820309",
    "label": "Gisik",
    "value": "Gisik"
  },
  {
    "id": "8203092008",
    "district_id": "820309",
    "label": "Kapa Kapa",
    "value": "Kapa Kapa"
  },
  {
    "id": "8203092009",
    "district_id": "820309",
    "label": "Pacao",
    "value": "Pacao"
  },
  {
    "id": "8203092010",
    "district_id": "820309",
    "label": "Tate",
    "value": "Tate"
  },
  {
    "id": "8203092011",
    "district_id": "820309",
    "label": "Posi- Posi",
    "value": "Posi- Posi"
  },
  {
    "id": "8203092012",
    "district_id": "820309",
    "label": "Supu",
    "value": "Supu"
  },
  {
    "id": "8203092022",
    "district_id": "820309",
    "label": "Igo",
    "value": "Igo"
  },
  {
    "id": "8203092023",
    "district_id": "820309",
    "label": "Galao",
    "value": "Galao"
  },
  {
    "id": "8203092024",
    "district_id": "820309",
    "label": "Teru-Teru",
    "value": "Teru-Teru"
  },
  {
    "id": "8203092026",
    "district_id": "820309",
    "label": "Wori Moi",
    "value": "Wori Moi"
  },
  {
    "id": "8203092027",
    "district_id": "820309",
    "label": "Podol",
    "value": "Podol"
  },
  {
    "id": "8203092028",
    "district_id": "820309",
    "label": "Momojiu",
    "value": "Momojiu"
  },
  {
    "id": "8203102001",
    "district_id": "820310",
    "label": "Gorua",
    "value": "Gorua"
  },
  {
    "id": "8203102002",
    "district_id": "820310",
    "label": "Popilo",
    "value": "Popilo"
  },
  {
    "id": "8203102003",
    "district_id": "820310",
    "label": "Luari",
    "value": "Luari"
  },
  {
    "id": "8203102004",
    "district_id": "820310",
    "label": "Popilo Utara",
    "value": "Popilo Utara"
  },
  {
    "id": "8203102005",
    "district_id": "820310",
    "label": "Tolonuo",
    "value": "Tolonuo"
  },
  {
    "id": "8203102006",
    "district_id": "820310",
    "label": "Gorua Selatan",
    "value": "Gorua Selatan"
  },
  {
    "id": "8203102007",
    "district_id": "820310",
    "label": "Gorua Utara",
    "value": "Gorua Utara"
  },
  {
    "id": "8203102008",
    "district_id": "820310",
    "label": "Kokota Jaya",
    "value": "Kokota Jaya"
  },
  {
    "id": "8203102009",
    "district_id": "820310",
    "label": "Ruko",
    "value": "Ruko"
  },
  {
    "id": "8203102010",
    "district_id": "820310",
    "label": "Tolonua Selatan",
    "value": "Tolonua Selatan"
  },
  {
    "id": "8203112001",
    "district_id": "820311",
    "label": "Upa",
    "value": "Upa"
  },
  {
    "id": "8203112002",
    "district_id": "820311",
    "label": "Pitu",
    "value": "Pitu"
  },
  {
    "id": "8203112003",
    "district_id": "820311",
    "label": "Wosia",
    "value": "Wosia"
  },
  {
    "id": "8203112004",
    "district_id": "820311",
    "label": "WKO",
    "value": "WKO"
  },
  {
    "id": "8203112005",
    "district_id": "820311",
    "label": "Kalipitu",
    "value": "Kalipitu"
  },
  {
    "id": "8203112006",
    "district_id": "820311",
    "label": "Kali Upa",
    "value": "Kali Upa"
  },
  {
    "id": "8203112007",
    "district_id": "820311",
    "label": "Lina Ino",
    "value": "Lina Ino"
  },
  {
    "id": "8203112008",
    "district_id": "820311",
    "label": "Mahia (Wosia Tengah)",
    "value": "Mahia (Wosia Tengah)"
  },
  {
    "id": "8203112009",
    "district_id": "820311",
    "label": "Tanjung Niara (Wosia Selatan)",
    "value": "Tanjung Niara (Wosia Selatan)"
  },
  {
    "id": "8203122001",
    "district_id": "820312",
    "label": "Yaro",
    "value": "Yaro"
  },
  {
    "id": "8203122002",
    "district_id": "820312",
    "label": "Mawea",
    "value": "Mawea"
  },
  {
    "id": "8203122003",
    "district_id": "820312",
    "label": "Meti",
    "value": "Meti"
  },
  {
    "id": "8203122004",
    "district_id": "820312",
    "label": "Katana",
    "value": "Katana"
  },
  {
    "id": "8203122005",
    "district_id": "820312",
    "label": "Gonga",
    "value": "Gonga"
  },
  {
    "id": "8203122006",
    "district_id": "820312",
    "label": "Todokuiha",
    "value": "Todokuiha"
  },
  {
    "id": "8203132001",
    "district_id": "820313",
    "label": "Kusuri",
    "value": "Kusuri"
  },
  {
    "id": "8203132002",
    "district_id": "820313",
    "label": "Sukamaju",
    "value": "Sukamaju"
  },
  {
    "id": "8203132003",
    "district_id": "820313",
    "label": "Togoliua",
    "value": "Togoliua"
  },
  {
    "id": "8203132004",
    "district_id": "820313",
    "label": "Birinoa",
    "value": "Birinoa"
  },
  {
    "id": "8203132005",
    "district_id": "820313",
    "label": "Wangongira",
    "value": "Wangongira"
  },
  {
    "id": "8203142001",
    "district_id": "820314",
    "label": "Soatobaru",
    "value": "Soatobaru"
  },
  {
    "id": "8203142002",
    "district_id": "820314",
    "label": "Dokulamo",
    "value": "Dokulamo"
  },
  {
    "id": "8203142003",
    "district_id": "820314",
    "label": "Duma",
    "value": "Duma"
  },
  {
    "id": "8203142004",
    "district_id": "820314",
    "label": "Gotalamo",
    "value": "Gotalamo"
  },
  {
    "id": "8203142005",
    "district_id": "820314",
    "label": "Makete",
    "value": "Makete"
  },
  {
    "id": "8203142006",
    "district_id": "820314",
    "label": "Ngidiho",
    "value": "Ngidiho"
  },
  {
    "id": "8203142007",
    "district_id": "820314",
    "label": "Roko",
    "value": "Roko"
  },
  {
    "id": "8203142008",
    "district_id": "820314",
    "label": "Samuda",
    "value": "Samuda"
  },
  {
    "id": "8203142009",
    "district_id": "820314",
    "label": "Kira",
    "value": "Kira"
  },
  {
    "id": "8203152001",
    "district_id": "820315",
    "label": "Limau",
    "value": "Limau"
  },
  {
    "id": "8203152002",
    "district_id": "820315",
    "label": "Lalonga",
    "value": "Lalonga"
  },
  {
    "id": "8203152003",
    "district_id": "820315",
    "label": "Bobisingo",
    "value": "Bobisingo"
  },
  {
    "id": "8203152004",
    "district_id": "820315",
    "label": "Salimuli",
    "value": "Salimuli"
  },
  {
    "id": "8203152005",
    "district_id": "820315",
    "label": "Tutumaloleo",
    "value": "Tutumaloleo"
  },
  {
    "id": "8203152006",
    "district_id": "820315",
    "label": "Saluta",
    "value": "Saluta"
  },
  {
    "id": "8203152007",
    "district_id": "820315",
    "label": "Jere",
    "value": "Jere"
  },
  {
    "id": "8203152008",
    "district_id": "820315",
    "label": "Dodowo",
    "value": "Dodowo"
  },
  {
    "id": "8203152009",
    "district_id": "820315",
    "label": "Togasa",
    "value": "Togasa"
  },
  {
    "id": "8203152010",
    "district_id": "820315",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "8203152011",
    "district_id": "820315",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "8203152012",
    "district_id": "820315",
    "label": "Jere Tua",
    "value": "Jere Tua"
  },
  {
    "id": "8203162001",
    "district_id": "820316",
    "label": "Seki",
    "value": "Seki"
  },
  {
    "id": "8203162002",
    "district_id": "820316",
    "label": "Togawa",
    "value": "Togawa"
  },
  {
    "id": "8203162003",
    "district_id": "820316",
    "label": "Soakonora",
    "value": "Soakonora"
  },
  {
    "id": "8203162004",
    "district_id": "820316",
    "label": "Igobula",
    "value": "Igobula"
  },
  {
    "id": "8203162005",
    "district_id": "820316",
    "label": "Bale",
    "value": "Bale"
  },
  {
    "id": "8203162006",
    "district_id": "820316",
    "label": "Togawabesi",
    "value": "Togawabesi"
  },
  {
    "id": "8203162007",
    "district_id": "820316",
    "label": "Ori",
    "value": "Ori"
  },
  {
    "id": "8203192001",
    "district_id": "820319",
    "label": "Salube",
    "value": "Salube"
  },
  {
    "id": "8203192002",
    "district_id": "820319",
    "label": "Dama",
    "value": "Dama"
  },
  {
    "id": "8203192003",
    "district_id": "820319",
    "label": "Dowonggila",
    "value": "Dowonggila"
  },
  {
    "id": "8203192004",
    "district_id": "820319",
    "label": "Tuakara",
    "value": "Tuakara"
  },
  {
    "id": "8203192005",
    "district_id": "820319",
    "label": "Jikolamo",
    "value": "Jikolamo"
  },
  {
    "id": "8203192006",
    "district_id": "820319",
    "label": "Dagasuli",
    "value": "Dagasuli"
  },
  {
    "id": "8203192007",
    "district_id": "820319",
    "label": "Dedeta",
    "value": "Dedeta"
  },
  {
    "id": "8203192008",
    "district_id": "820319",
    "label": "Fitako",
    "value": "Fitako"
  },
  {
    "id": "8203192009",
    "district_id": "820319",
    "label": "Tobo Tobo",
    "value": "Tobo Tobo"
  },
  {
    "id": "8203192010",
    "district_id": "820319",
    "label": "Cera",
    "value": "Cera"
  },
  {
    "id": "8203202001",
    "district_id": "820320",
    "label": "Wateto",
    "value": "Wateto"
  },
  {
    "id": "8203202002",
    "district_id": "820320",
    "label": "Gulo",
    "value": "Gulo"
  },
  {
    "id": "8203202003",
    "district_id": "820320",
    "label": "Tunuo",
    "value": "Tunuo"
  },
  {
    "id": "8203202004",
    "district_id": "820320",
    "label": "Pediwang",
    "value": "Pediwang"
  },
  {
    "id": "8203202005",
    "district_id": "820320",
    "label": "Bori",
    "value": "Bori"
  },
  {
    "id": "8203202006",
    "district_id": "820320",
    "label": "Doro",
    "value": "Doro"
  },
  {
    "id": "8203202007",
    "district_id": "820320",
    "label": "Daru",
    "value": "Daru"
  },
  {
    "id": "8203202008",
    "district_id": "820320",
    "label": "Bobale",
    "value": "Bobale"
  },
  {
    "id": "8203202009",
    "district_id": "820320",
    "label": "Gamlaha",
    "value": "Gamlaha"
  },
  {
    "id": "8203202010",
    "district_id": "820320",
    "label": "Boulamo",
    "value": "Boulamo"
  },
  {
    "id": "8203202011",
    "district_id": "820320",
    "label": "Warudu",
    "value": "Warudu"
  },
  {
    "id": "8203202012",
    "district_id": "820320",
    "label": "Dowongimaiti",
    "value": "Dowongimaiti"
  },
  {
    "id": "8203212001",
    "district_id": "820321",
    "label": "Gaga Apok",
    "value": "Gaga Apok"
  },
  {
    "id": "8203212002",
    "district_id": "820321",
    "label": "Ngoali",
    "value": "Ngoali"
  },
  {
    "id": "8203212003",
    "district_id": "820321",
    "label": "Momoda",
    "value": "Momoda"
  },
  {
    "id": "8203212004",
    "district_id": "820321",
    "label": "Toliwang",
    "value": "Toliwang"
  },
  {
    "id": "8203212005",
    "district_id": "820321",
    "label": "Tolabit",
    "value": "Tolabit"
  },
  {
    "id": "8203212006",
    "district_id": "820321",
    "label": "Leleseng",
    "value": "Leleseng"
  },
  {
    "id": "8203212007",
    "district_id": "820321",
    "label": "Soa Hukum",
    "value": "Soa Hukum"
  },
  {
    "id": "8203212008",
    "district_id": "820321",
    "label": "Soa Maetek",
    "value": "Soa Maetek"
  },
  {
    "id": "8203212009",
    "district_id": "820321",
    "label": "Bailengit",
    "value": "Bailengit"
  },
  {
    "id": "8203212010",
    "district_id": "820321",
    "label": "Tuguis",
    "value": "Tuguis"
  },
  {
    "id": "8203212011",
    "district_id": "820321",
    "label": "Parseba",
    "value": "Parseba"
  },
  {
    "id": "8203212012",
    "district_id": "820321",
    "label": "Pitago",
    "value": "Pitago"
  },
  {
    "id": "8203212013",
    "district_id": "820321",
    "label": "Kai",
    "value": "Kai"
  },
  {
    "id": "8203212014",
    "district_id": "820321",
    "label": "Toboulamo",
    "value": "Toboulamo"
  },
  {
    "id": "8203212015",
    "district_id": "820321",
    "label": "Makarti",
    "value": "Makarti"
  },
  {
    "id": "8203212016",
    "district_id": "820321",
    "label": "Sangaji Jaya",
    "value": "Sangaji Jaya"
  },
  {
    "id": "8203212017",
    "district_id": "820321",
    "label": "Takimo",
    "value": "Takimo"
  },
  {
    "id": "8203212018",
    "district_id": "820321",
    "label": "Torawat",
    "value": "Torawat"
  },
  {
    "id": "8203212019",
    "district_id": "820321",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "8203212020",
    "district_id": "820321",
    "label": "Beringin Agung",
    "value": "Beringin Agung"
  },
  {
    "id": "8203212021",
    "district_id": "820321",
    "label": "Margomolyo",
    "value": "Margomolyo"
  },
  {
    "id": "8203222001",
    "district_id": "820322",
    "label": "Tiowor",
    "value": "Tiowor"
  },
  {
    "id": "8203222002",
    "district_id": "820322",
    "label": "Makaeling",
    "value": "Makaeling"
  },
  {
    "id": "8203222003",
    "district_id": "820322",
    "label": "Tobanoma",
    "value": "Tobanoma"
  },
  {
    "id": "8203222004",
    "district_id": "820322",
    "label": "Barumadehe",
    "value": "Barumadehe"
  },
  {
    "id": "8203222005",
    "district_id": "820322",
    "label": "Kuntum Mekar",
    "value": "Kuntum Mekar"
  },
  {
    "id": "8203222006",
    "district_id": "820322",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "8203222007",
    "district_id": "820322",
    "label": "Bobaneigo",
    "value": "Bobaneigo"
  },
  {
    "id": "8203222008",
    "district_id": "820322",
    "label": "Tetewang",
    "value": "Tetewang"
  },
  {
    "id": "8203222009",
    "district_id": "820322",
    "label": "Akelamo Kao",
    "value": "Akelamo Kao"
  },
  {
    "id": "8203222010",
    "district_id": "820322",
    "label": "Gamsungi",
    "value": "Gamsungi"
  },
  {
    "id": "8203222011",
    "district_id": "820322",
    "label": "Dum-Dum",
    "value": "Dum-Dum"
  },
  {
    "id": "8204012001",
    "district_id": "820401",
    "label": "Rabutdaiyo",
    "value": "Rabutdaiyo"
  },
  {
    "id": "8204012002",
    "district_id": "820401",
    "label": "Waykion",
    "value": "Waykion"
  },
  {
    "id": "8204012003",
    "district_id": "820401",
    "label": "Gorup",
    "value": "Gorup"
  },
  {
    "id": "8204012004",
    "district_id": "820401",
    "label": "Walo",
    "value": "Walo"
  },
  {
    "id": "8204012005",
    "district_id": "820401",
    "label": "Dalam",
    "value": "Dalam"
  },
  {
    "id": "8204012006",
    "district_id": "820401",
    "label": "Gitang",
    "value": "Gitang"
  },
  {
    "id": "8204012007",
    "district_id": "820401",
    "label": "Kyowor",
    "value": "Kyowor"
  },
  {
    "id": "8204012008",
    "district_id": "820401",
    "label": "Matentengin",
    "value": "Matentengin"
  },
  {
    "id": "8204012009",
    "district_id": "820401",
    "label": "Sangapati",
    "value": "Sangapati"
  },
  {
    "id": "8204012010",
    "district_id": "820401",
    "label": "Suma",
    "value": "Suma"
  },
  {
    "id": "8204012011",
    "district_id": "820401",
    "label": "Ploili",
    "value": "Ploili"
  },
  {
    "id": "8204012012",
    "district_id": "820401",
    "label": "Dauri",
    "value": "Dauri"
  },
  {
    "id": "8204012013",
    "district_id": "820401",
    "label": "Gurua",
    "value": "Gurua"
  },
  {
    "id": "8204012014",
    "district_id": "820401",
    "label": "Wailoa",
    "value": "Wailoa"
  },
  {
    "id": "8204012022",
    "district_id": "820401",
    "label": "Waigitang",
    "value": "Waigitang"
  },
  {
    "id": "8204022004",
    "district_id": "820402",
    "label": "Siko",
    "value": "Siko"
  },
  {
    "id": "8204022005",
    "district_id": "820402",
    "label": "Gafi",
    "value": "Gafi"
  },
  {
    "id": "8204022006",
    "district_id": "820402",
    "label": "Laigoma",
    "value": "Laigoma"
  },
  {
    "id": "8204022016",
    "district_id": "820402",
    "label": "Ligua",
    "value": "Ligua"
  },
  {
    "id": "8204022017",
    "district_id": "820402",
    "label": "Kida",
    "value": "Kida"
  },
  {
    "id": "8204022018",
    "district_id": "820402",
    "label": "Buli",
    "value": "Buli"
  },
  {
    "id": "8204022019",
    "district_id": "820402",
    "label": "Talimau",
    "value": "Talimau"
  },
  {
    "id": "8204022020",
    "district_id": "820402",
    "label": "Gunange",
    "value": "Gunange"
  },
  {
    "id": "8204022021",
    "district_id": "820402",
    "label": "Lelei",
    "value": "Lelei"
  },
  {
    "id": "8204022022",
    "district_id": "820402",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "8204022023",
    "district_id": "820402",
    "label": "Guruapin",
    "value": "Guruapin"
  },
  {
    "id": "8204022024",
    "district_id": "820402",
    "label": "Karamat",
    "value": "Karamat"
  },
  {
    "id": "8204022029",
    "district_id": "820402",
    "label": "Tawabi",
    "value": "Tawabi"
  },
  {
    "id": "8204022030",
    "district_id": "820402",
    "label": "Dorolamo",
    "value": "Dorolamo"
  },
  {
    "id": "8204032001",
    "district_id": "820403",
    "label": "Maffa",
    "value": "Maffa"
  },
  {
    "id": "8204032002",
    "district_id": "820403",
    "label": "Foya",
    "value": "Foya"
  },
  {
    "id": "8204032003",
    "district_id": "820403",
    "label": "Tobaru",
    "value": "Tobaru"
  },
  {
    "id": "8204032004",
    "district_id": "820403",
    "label": "Foya Tobaru",
    "value": "Foya Tobaru"
  },
  {
    "id": "8204032005",
    "district_id": "820403",
    "label": "Lalubi",
    "value": "Lalubi"
  },
  {
    "id": "8204032006",
    "district_id": "820403",
    "label": "Akelamo",
    "value": "Akelamo"
  },
  {
    "id": "8204032007",
    "district_id": "820403",
    "label": "Batonam",
    "value": "Batonam"
  },
  {
    "id": "8204032008",
    "district_id": "820403",
    "label": "Wosi",
    "value": "Wosi"
  },
  {
    "id": "8204032014",
    "district_id": "820403",
    "label": "Sumber Makmur",
    "value": "Sumber Makmur"
  },
  {
    "id": "8204032020",
    "district_id": "820403",
    "label": "Kotalow",
    "value": "Kotalow"
  },
  {
    "id": "8204032022",
    "district_id": "820403",
    "label": "Kebun Raja",
    "value": "Kebun Raja"
  },
  {
    "id": "8204032025",
    "district_id": "820403",
    "label": "Tanjung Jere",
    "value": "Tanjung Jere"
  },
  {
    "id": "8204042016",
    "district_id": "820404",
    "label": "Doro",
    "value": "Doro"
  },
  {
    "id": "8204042017",
    "district_id": "820404",
    "label": "Koititi",
    "value": "Koititi"
  },
  {
    "id": "8204042018",
    "district_id": "820404",
    "label": "Cango",
    "value": "Cango"
  },
  {
    "id": "8204042019",
    "district_id": "820404",
    "label": "Saketa",
    "value": "Saketa"
  },
  {
    "id": "8204042020",
    "district_id": "820404",
    "label": "Balitata",
    "value": "Balitata"
  },
  {
    "id": "8204042021",
    "district_id": "820404",
    "label": "Oha",
    "value": "Oha"
  },
  {
    "id": "8204042022",
    "district_id": "820404",
    "label": "Papaceda",
    "value": "Papaceda"
  },
  {
    "id": "8204042023",
    "district_id": "820404",
    "label": "Lemo Lemo",
    "value": "Lemo Lemo"
  },
  {
    "id": "8204042029",
    "district_id": "820404",
    "label": "Bumi Rahmat",
    "value": "Bumi Rahmat"
  },
  {
    "id": "8204042036",
    "district_id": "820404",
    "label": "Tabamasa",
    "value": "Tabamasa"
  },
  {
    "id": "8204052001",
    "district_id": "820405",
    "label": "Wayaloar",
    "value": "Wayaloar"
  },
  {
    "id": "8204052002",
    "district_id": "820405",
    "label": "Fluk",
    "value": "Fluk"
  },
  {
    "id": "8204052003",
    "district_id": "820405",
    "label": "Bobo",
    "value": "Bobo"
  },
  {
    "id": "8204052004",
    "district_id": "820405",
    "label": "Mano",
    "value": "Mano"
  },
  {
    "id": "8204052005",
    "district_id": "820405",
    "label": "Loleo",
    "value": "Loleo"
  },
  {
    "id": "8204052007",
    "district_id": "820405",
    "label": "Soligi",
    "value": "Soligi"
  },
  {
    "id": "8204052008",
    "district_id": "820405",
    "label": "Gambaru",
    "value": "Gambaru"
  },
  {
    "id": "8204052009",
    "district_id": "820405",
    "label": "Ocimaloleo",
    "value": "Ocimaloleo"
  },
  {
    "id": "8204062001",
    "district_id": "820406",
    "label": "Laiwui",
    "value": "Laiwui"
  },
  {
    "id": "8204062002",
    "district_id": "820406",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "8204062003",
    "district_id": "820406",
    "label": "Buton",
    "value": "Buton"
  },
  {
    "id": "8204062004",
    "district_id": "820406",
    "label": "Jikotamo",
    "value": "Jikotamo"
  },
  {
    "id": "8204062005",
    "district_id": "820406",
    "label": "Sambiki",
    "value": "Sambiki"
  },
  {
    "id": "8204062006",
    "district_id": "820406",
    "label": "Anggai",
    "value": "Anggai"
  },
  {
    "id": "8204062014",
    "district_id": "820406",
    "label": "Air Mangga Indah",
    "value": "Air Mangga Indah"
  },
  {
    "id": "8204062020",
    "district_id": "820406",
    "label": "Akegula",
    "value": "Akegula"
  },
  {
    "id": "8204062021",
    "district_id": "820406",
    "label": "Kawasi",
    "value": "Kawasi"
  },
  {
    "id": "8204072001",
    "district_id": "820407",
    "label": "Babang",
    "value": "Babang"
  },
  {
    "id": "8204072013",
    "district_id": "820407",
    "label": "Bori",
    "value": "Bori"
  },
  {
    "id": "8204072014",
    "district_id": "820407",
    "label": "Nyonyifi",
    "value": "Nyonyifi"
  },
  {
    "id": "8204072015",
    "district_id": "820407",
    "label": "Sabatang",
    "value": "Sabatang"
  },
  {
    "id": "8204072016",
    "district_id": "820407",
    "label": "Goro-Goro",
    "value": "Goro-Goro"
  },
  {
    "id": "8204072017",
    "district_id": "820407",
    "label": "Sali Kecil",
    "value": "Sali Kecil"
  },
  {
    "id": "8204072018",
    "district_id": "820407",
    "label": "Timlonga",
    "value": "Timlonga"
  },
  {
    "id": "8204072021",
    "district_id": "820407",
    "label": "Sayoang",
    "value": "Sayoang"
  },
  {
    "id": "8204072022",
    "district_id": "820407",
    "label": "Wayamiga",
    "value": "Wayamiga"
  },
  {
    "id": "8204072023",
    "district_id": "820407",
    "label": "Kaireu",
    "value": "Kaireu"
  },
  {
    "id": "8204082001",
    "district_id": "820408",
    "label": "Labuha",
    "value": "Labuha"
  },
  {
    "id": "8204082002",
    "district_id": "820408",
    "label": "Amasing Kota",
    "value": "Amasing Kota"
  },
  {
    "id": "8204082003",
    "district_id": "820408",
    "label": "Amasing Kali",
    "value": "Amasing Kali"
  },
  {
    "id": "8204082004",
    "district_id": "820408",
    "label": "Tomori",
    "value": "Tomori"
  },
  {
    "id": "8204082009",
    "district_id": "820408",
    "label": "Indomut",
    "value": "Indomut"
  },
  {
    "id": "8204082010",
    "district_id": "820408",
    "label": "Belang Belang",
    "value": "Belang Belang"
  },
  {
    "id": "8204082026",
    "district_id": "820408",
    "label": "Sumae",
    "value": "Sumae"
  },
  {
    "id": "8204082027",
    "district_id": "820408",
    "label": "Kaputusan",
    "value": "Kaputusan"
  },
  {
    "id": "8204082030",
    "district_id": "820408",
    "label": "Amasing Kota Barat",
    "value": "Amasing Kota Barat"
  },
  {
    "id": "8204082031",
    "district_id": "820408",
    "label": "Amasing Kota Utara",
    "value": "Amasing Kota Utara"
  },
  {
    "id": "8204082032",
    "district_id": "820408",
    "label": "Awanggoa",
    "value": "Awanggoa"
  },
  {
    "id": "8204082036",
    "district_id": "820408",
    "label": "Suma Tinggi",
    "value": "Suma Tinggi"
  },
  {
    "id": "8204082040",
    "district_id": "820408",
    "label": "Hidayat",
    "value": "Hidayat"
  },
  {
    "id": "8204082041",
    "district_id": "820408",
    "label": "Marabose",
    "value": "Marabose"
  },
  {
    "id": "8204092001",
    "district_id": "820409",
    "label": "Indari",
    "value": "Indari"
  },
  {
    "id": "8204092014",
    "district_id": "820409",
    "label": "Tawabi",
    "value": "Tawabi"
  },
  {
    "id": "8204092015",
    "district_id": "820409",
    "label": "Wiring",
    "value": "Wiring"
  },
  {
    "id": "8204092016",
    "district_id": "820409",
    "label": "Nondang",
    "value": "Nondang"
  },
  {
    "id": "8204092017",
    "district_id": "820409",
    "label": "Kusubibi",
    "value": "Kusubibi"
  },
  {
    "id": "8204092018",
    "district_id": "820409",
    "label": "Nang",
    "value": "Nang"
  },
  {
    "id": "8204092030",
    "district_id": "820409",
    "label": "Kokotu",
    "value": "Kokotu"
  },
  {
    "id": "8204102001",
    "district_id": "820410",
    "label": "Malapat",
    "value": "Malapat"
  },
  {
    "id": "8204102002",
    "district_id": "820410",
    "label": "Bobawae",
    "value": "Bobawae"
  },
  {
    "id": "8204102003",
    "district_id": "820410",
    "label": "Ombawa",
    "value": "Ombawa"
  },
  {
    "id": "8204102004",
    "district_id": "820410",
    "label": "Tegono",
    "value": "Tegono"
  },
  {
    "id": "8204102005",
    "district_id": "820410",
    "label": "Mateketen",
    "value": "Mateketen"
  },
  {
    "id": "8204102006",
    "district_id": "820410",
    "label": "Talapaon",
    "value": "Talapaon"
  },
  {
    "id": "8204102007",
    "district_id": "820410",
    "label": "Sebelei",
    "value": "Sebelei"
  },
  {
    "id": "8204112001",
    "district_id": "820411",
    "label": "Bokimiake",
    "value": "Bokimiake"
  },
  {
    "id": "8204112002",
    "district_id": "820411",
    "label": "Hatejawa",
    "value": "Hatejawa"
  },
  {
    "id": "8204112003",
    "district_id": "820411",
    "label": "Busua",
    "value": "Busua"
  },
  {
    "id": "8204112004",
    "district_id": "820411",
    "label": "Fofao",
    "value": "Fofao"
  },
  {
    "id": "8204122001",
    "district_id": "820412",
    "label": "Ngute-Ngute",
    "value": "Ngute-Ngute"
  },
  {
    "id": "8204122002",
    "district_id": "820412",
    "label": "Laluin",
    "value": "Laluin"
  },
  {
    "id": "8204122003",
    "district_id": "820412",
    "label": "Posi-Posi",
    "value": "Posi-Posi"
  },
  {
    "id": "8204122004",
    "district_id": "820412",
    "label": "Sagawele",
    "value": "Sagawele"
  },
  {
    "id": "8204122005",
    "district_id": "820412",
    "label": "Orimakurunga",
    "value": "Orimakurunga"
  },
  {
    "id": "8204122006",
    "district_id": "820412",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "8204132001",
    "district_id": "820413",
    "label": "Modayama",
    "value": "Modayama"
  },
  {
    "id": "8204132002",
    "district_id": "820413",
    "label": "Laromabati",
    "value": "Laromabati"
  },
  {
    "id": "8204132003",
    "district_id": "820413",
    "label": "Gayap",
    "value": "Gayap"
  },
  {
    "id": "8204132004",
    "district_id": "820413",
    "label": "Ake Jailolo",
    "value": "Ake Jailolo"
  },
  {
    "id": "8204132005",
    "district_id": "820413",
    "label": "Wayasipang",
    "value": "Wayasipang"
  },
  {
    "id": "8204132006",
    "district_id": "820413",
    "label": "Ngokomalako",
    "value": "Ngokomalako"
  },
  {
    "id": "8204142001",
    "district_id": "820414",
    "label": "Sidopo",
    "value": "Sidopo"
  },
  {
    "id": "8204142002",
    "district_id": "820414",
    "label": "Lolarogurua",
    "value": "Lolarogurua"
  },
  {
    "id": "8204142003",
    "district_id": "820414",
    "label": "Nusa Babullah",
    "value": "Nusa Babullah"
  },
  {
    "id": "8204142004",
    "district_id": "820414",
    "label": "Jojame",
    "value": "Jojame"
  },
  {
    "id": "8204142005",
    "district_id": "820414",
    "label": "Yaba",
    "value": "Yaba"
  },
  {
    "id": "8204142006",
    "district_id": "820414",
    "label": "Geti Lama",
    "value": "Geti Lama"
  },
  {
    "id": "8204142007",
    "district_id": "820414",
    "label": "Geti Baru",
    "value": "Geti Baru"
  },
  {
    "id": "8204142008",
    "district_id": "820414",
    "label": "Gilalang",
    "value": "Gilalang"
  },
  {
    "id": "8204152001",
    "district_id": "820415",
    "label": "Sidanga",
    "value": "Sidanga"
  },
  {
    "id": "8204152002",
    "district_id": "820415",
    "label": "Lata Lata",
    "value": "Lata Lata"
  },
  {
    "id": "8204152003",
    "district_id": "820415",
    "label": "Sengga Baru",
    "value": "Sengga Baru"
  },
  {
    "id": "8204152004",
    "district_id": "820415",
    "label": "Arumamang",
    "value": "Arumamang"
  },
  {
    "id": "8204152005",
    "district_id": "820415",
    "label": "Palamea",
    "value": "Palamea"
  },
  {
    "id": "8204152006",
    "district_id": "820415",
    "label": "Doko",
    "value": "Doko"
  },
  {
    "id": "8204152007",
    "district_id": "820415",
    "label": "Bisori",
    "value": "Bisori"
  },
  {
    "id": "8204152008",
    "district_id": "820415",
    "label": "Marikapal",
    "value": "Marikapal"
  },
  {
    "id": "8204152009",
    "district_id": "820415",
    "label": "Kukupang",
    "value": "Kukupang"
  },
  {
    "id": "8204152010",
    "district_id": "820415",
    "label": "Imbu Imbu",
    "value": "Imbu Imbu"
  },
  {
    "id": "8204162001",
    "district_id": "820416",
    "label": "Loleo Jaya",
    "value": "Loleo Jaya"
  },
  {
    "id": "8204162002",
    "district_id": "820416",
    "label": "Tawa",
    "value": "Tawa"
  },
  {
    "id": "8204162003",
    "district_id": "820416",
    "label": "Marituso",
    "value": "Marituso"
  },
  {
    "id": "8204162004",
    "district_id": "820416",
    "label": "Kou Balabala",
    "value": "Kou Balabala"
  },
  {
    "id": "8204162005",
    "district_id": "820416",
    "label": "Loleo Mekar",
    "value": "Loleo Mekar"
  },
  {
    "id": "8204162006",
    "district_id": "820416",
    "label": "Tutuhu",
    "value": "Tutuhu"
  },
  {
    "id": "8204162007",
    "district_id": "820416",
    "label": "Jeret",
    "value": "Jeret"
  },
  {
    "id": "8204162008",
    "district_id": "820416",
    "label": "Kasiruta Dalam",
    "value": "Kasiruta Dalam"
  },
  {
    "id": "8204172001",
    "district_id": "820417",
    "label": "Kampung Makian",
    "value": "Kampung Makian"
  },
  {
    "id": "8204172002",
    "district_id": "820417",
    "label": "Mandaong",
    "value": "Mandaong"
  },
  {
    "id": "8204172003",
    "district_id": "820417",
    "label": "Kupal",
    "value": "Kupal"
  },
  {
    "id": "8204172004",
    "district_id": "820417",
    "label": "Gandasuli",
    "value": "Gandasuli"
  },
  {
    "id": "8204172005",
    "district_id": "820417",
    "label": "Sawadai",
    "value": "Sawadai"
  },
  {
    "id": "8204172006",
    "district_id": "820417",
    "label": "Tuokona",
    "value": "Tuokona"
  },
  {
    "id": "8204172007",
    "district_id": "820417",
    "label": "Papaloang",
    "value": "Papaloang"
  },
  {
    "id": "8204172008",
    "district_id": "820417",
    "label": "Tembal",
    "value": "Tembal"
  },
  {
    "id": "8204172009",
    "district_id": "820417",
    "label": "Kubung",
    "value": "Kubung"
  },
  {
    "id": "8204172010",
    "district_id": "820417",
    "label": "Panamboang",
    "value": "Panamboang"
  },
  {
    "id": "8204182001",
    "district_id": "820418",
    "label": "Sawangakar",
    "value": "Sawangakar"
  },
  {
    "id": "8204182002",
    "district_id": "820418",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "8204182003",
    "district_id": "820418",
    "label": "Tanjung Obit",
    "value": "Tanjung Obit"
  },
  {
    "id": "8204182004",
    "district_id": "820418",
    "label": "Prapakanda",
    "value": "Prapakanda"
  },
  {
    "id": "8204182005",
    "district_id": "820418",
    "label": "Toin",
    "value": "Toin"
  },
  {
    "id": "8204182006",
    "district_id": "820418",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "8204182007",
    "district_id": "820418",
    "label": "Batutaga",
    "value": "Batutaga"
  },
  {
    "id": "8204182008",
    "district_id": "820418",
    "label": "Paisumbaos",
    "value": "Paisumbaos"
  },
  {
    "id": "8204192001",
    "district_id": "820419",
    "label": "Lele",
    "value": "Lele"
  },
  {
    "id": "8204192002",
    "district_id": "820419",
    "label": "Bahu",
    "value": "Bahu"
  },
  {
    "id": "8204192003",
    "district_id": "820419",
    "label": "Jiko",
    "value": "Jiko"
  },
  {
    "id": "8204192004",
    "district_id": "820419",
    "label": "Galala",
    "value": "Galala"
  },
  {
    "id": "8204192005",
    "district_id": "820419",
    "label": "Tabalema",
    "value": "Tabalema"
  },
  {
    "id": "8204192006",
    "district_id": "820419",
    "label": "Yoyok",
    "value": "Yoyok"
  },
  {
    "id": "8204202001",
    "district_id": "820420",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "8204202002",
    "district_id": "820420",
    "label": "Indong",
    "value": "Indong"
  },
  {
    "id": "8204202003",
    "district_id": "820420",
    "label": "Waya",
    "value": "Waya"
  },
  {
    "id": "8204202004",
    "district_id": "820420",
    "label": "Leleongusu",
    "value": "Leleongusu"
  },
  {
    "id": "8204202005",
    "district_id": "820420",
    "label": "Akedabo",
    "value": "Akedabo"
  },
  {
    "id": "8204202006",
    "district_id": "820420",
    "label": "Bobo",
    "value": "Bobo"
  },
  {
    "id": "8204212001",
    "district_id": "820421",
    "label": "Wayaua",
    "value": "Wayaua"
  },
  {
    "id": "8204212002",
    "district_id": "820421",
    "label": "Silang",
    "value": "Silang"
  },
  {
    "id": "8204212003",
    "district_id": "820421",
    "label": "Liaro",
    "value": "Liaro"
  },
  {
    "id": "8204212004",
    "district_id": "820421",
    "label": "Pigaraja",
    "value": "Pigaraja"
  },
  {
    "id": "8204212005",
    "district_id": "820421",
    "label": "Wayakuba",
    "value": "Wayakuba"
  },
  {
    "id": "8204212006",
    "district_id": "820421",
    "label": "Tabangame",
    "value": "Tabangame"
  },
  {
    "id": "8204212007",
    "district_id": "820421",
    "label": "Tabajaya",
    "value": "Tabajaya"
  },
  {
    "id": "8204222001",
    "district_id": "820422",
    "label": "Wayatim",
    "value": "Wayatim"
  },
  {
    "id": "8204222002",
    "district_id": "820422",
    "label": "Tomara",
    "value": "Tomara"
  },
  {
    "id": "8204222003",
    "district_id": "820422",
    "label": "Tutupa",
    "value": "Tutupa"
  },
  {
    "id": "8204222004",
    "district_id": "820422",
    "label": "Tabapoma",
    "value": "Tabapoma"
  },
  {
    "id": "8204222005",
    "district_id": "820422",
    "label": "Bibinoi",
    "value": "Bibinoi"
  },
  {
    "id": "8204222006",
    "district_id": "820422",
    "label": "Songa",
    "value": "Songa"
  },
  {
    "id": "8204222007",
    "district_id": "820422",
    "label": "Tawa",
    "value": "Tawa"
  },
  {
    "id": "8204232001",
    "district_id": "820423",
    "label": "Awis",
    "value": "Awis"
  },
  {
    "id": "8204232002",
    "district_id": "820423",
    "label": "Dowora",
    "value": "Dowora"
  },
  {
    "id": "8204232003",
    "district_id": "820423",
    "label": "Gane Dalam",
    "value": "Gane Dalam"
  },
  {
    "id": "8204232004",
    "district_id": "820423",
    "label": "Jibubu",
    "value": "Jibubu"
  },
  {
    "id": "8204232005",
    "district_id": "820423",
    "label": "Pasipalele",
    "value": "Pasipalele"
  },
  {
    "id": "8204232006",
    "district_id": "820423",
    "label": "Sekely",
    "value": "Sekely"
  },
  {
    "id": "8204232007",
    "district_id": "820423",
    "label": "Tawa",
    "value": "Tawa"
  },
  {
    "id": "8204232008",
    "district_id": "820423",
    "label": "Yamli",
    "value": "Yamli"
  },
  {
    "id": "8204242001",
    "district_id": "820424",
    "label": "Batulak",
    "value": "Batulak"
  },
  {
    "id": "8204242002",
    "district_id": "820424",
    "label": "Boso",
    "value": "Boso"
  },
  {
    "id": "8204242003",
    "district_id": "820424",
    "label": "Dolik",
    "value": "Dolik"
  },
  {
    "id": "8204242004",
    "district_id": "820424",
    "label": "Fulai",
    "value": "Fulai"
  },
  {
    "id": "8204242005",
    "district_id": "820424",
    "label": "Gumira",
    "value": "Gumira"
  },
  {
    "id": "8204242006",
    "district_id": "820424",
    "label": "Moloku",
    "value": "Moloku"
  },
  {
    "id": "8204242007",
    "district_id": "820424",
    "label": "Nurjihat",
    "value": "Nurjihat"
  },
  {
    "id": "8204242008",
    "district_id": "820424",
    "label": "Posi Posi",
    "value": "Posi Posi"
  },
  {
    "id": "8204242009",
    "district_id": "820424",
    "label": "Samat",
    "value": "Samat"
  },
  {
    "id": "8204242010",
    "district_id": "820424",
    "label": "Samo",
    "value": "Samo"
  },
  {
    "id": "8204242011",
    "district_id": "820424",
    "label": "Suka Damai",
    "value": "Suka Damai"
  },
  {
    "id": "8204242012",
    "district_id": "820424",
    "label": "Tokaka",
    "value": "Tokaka"
  },
  {
    "id": "8204252001",
    "district_id": "820425",
    "label": "Gonone",
    "value": "Gonone"
  },
  {
    "id": "8204252002",
    "district_id": "820425",
    "label": "Kakupang",
    "value": "Kakupang"
  },
  {
    "id": "8204252003",
    "district_id": "820425",
    "label": "Kurunga",
    "value": "Kurunga"
  },
  {
    "id": "8204252004",
    "district_id": "820425",
    "label": "Liboba Hijrah",
    "value": "Liboba Hijrah"
  },
  {
    "id": "8204252005",
    "district_id": "820425",
    "label": "Pulau Gala",
    "value": "Pulau Gala"
  },
  {
    "id": "8204252006",
    "district_id": "820425",
    "label": "Tawabi",
    "value": "Tawabi"
  },
  {
    "id": "8204252007",
    "district_id": "820425",
    "label": "Yomen",
    "value": "Yomen"
  },
  {
    "id": "8204262001",
    "district_id": "820426",
    "label": "Gaimu",
    "value": "Gaimu"
  },
  {
    "id": "8204262002",
    "district_id": "820426",
    "label": "Gane Luar",
    "value": "Gane Luar"
  },
  {
    "id": "8204262003",
    "district_id": "820426",
    "label": "Kuwo",
    "value": "Kuwo"
  },
  {
    "id": "8204262004",
    "district_id": "820426",
    "label": "Ranga Ranga",
    "value": "Ranga Ranga"
  },
  {
    "id": "8204262005",
    "district_id": "820426",
    "label": "Sawat",
    "value": "Sawat"
  },
  {
    "id": "8204272001",
    "district_id": "820427",
    "label": "Bisui",
    "value": "Bisui"
  },
  {
    "id": "8204272002",
    "district_id": "820427",
    "label": "Lelewi",
    "value": "Lelewi"
  },
  {
    "id": "8204272003",
    "district_id": "820427",
    "label": "Luim",
    "value": "Luim"
  },
  {
    "id": "8204272004",
    "district_id": "820427",
    "label": "Matuting",
    "value": "Matuting"
  },
  {
    "id": "8204272005",
    "district_id": "820427",
    "label": "Matuting Tanjung",
    "value": "Matuting Tanjung"
  },
  {
    "id": "8204272006",
    "district_id": "820427",
    "label": "Tabahidayah",
    "value": "Tabahidayah"
  },
  {
    "id": "8204272007",
    "district_id": "820427",
    "label": "Tabahijrah",
    "value": "Tabahijrah"
  },
  {
    "id": "8204272008",
    "district_id": "820427",
    "label": "Tagia",
    "value": "Tagia"
  },
  {
    "id": "8204282001",
    "district_id": "820428",
    "label": "Alam Kenanga",
    "value": "Alam Kenanga"
  },
  {
    "id": "8204282002",
    "district_id": "820428",
    "label": "Alam Pelita",
    "value": "Alam Pelita"
  },
  {
    "id": "8204282003",
    "district_id": "820428",
    "label": "Jikohai",
    "value": "Jikohai"
  },
  {
    "id": "8204282004",
    "district_id": "820428",
    "label": "Manatahan",
    "value": "Manatahan"
  },
  {
    "id": "8204282005",
    "district_id": "820428",
    "label": "Soa Sangaji",
    "value": "Soa Sangaji"
  },
  {
    "id": "8204282006",
    "district_id": "820428",
    "label": "Tapa",
    "value": "Tapa"
  },
  {
    "id": "8204292001",
    "district_id": "820429",
    "label": "Kelo",
    "value": "Kelo"
  },
  {
    "id": "8204292002",
    "district_id": "820429",
    "label": "Sum",
    "value": "Sum"
  },
  {
    "id": "8204292003",
    "district_id": "820429",
    "label": "Susepe",
    "value": "Susepe"
  },
  {
    "id": "8204292004",
    "district_id": "820429",
    "label": "Wooi",
    "value": "Wooi"
  },
  {
    "id": "8204302001",
    "district_id": "820430",
    "label": "Cap",
    "value": "Cap"
  },
  {
    "id": "8204302002",
    "district_id": "820430",
    "label": "Madopolo",
    "value": "Madopolo"
  },
  {
    "id": "8204302003",
    "district_id": "820430",
    "label": "Galala",
    "value": "Galala"
  },
  {
    "id": "8204302004",
    "district_id": "820430",
    "label": "Madopolo Barat",
    "value": "Madopolo Barat"
  },
  {
    "id": "8204302005",
    "district_id": "820430",
    "label": "Waringi",
    "value": "Waringi"
  },
  {
    "id": "8204302006",
    "district_id": "820430",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "8204302007",
    "district_id": "820430",
    "label": "Madopolo Timur",
    "value": "Madopolo Timur"
  },
  {
    "id": "8205012001",
    "district_id": "820501",
    "label": "Waitina",
    "value": "Waitina"
  },
  {
    "id": "8205012006",
    "district_id": "820501",
    "label": "Kau",
    "value": "Kau"
  },
  {
    "id": "8205012007",
    "district_id": "820501",
    "label": "Waitamela",
    "value": "Waitamela"
  },
  {
    "id": "8205012013",
    "district_id": "820501",
    "label": "Karamat Titdoy",
    "value": "Karamat Titdoy"
  },
  {
    "id": "8205012016",
    "district_id": "820501",
    "label": "Naflo",
    "value": "Naflo"
  },
  {
    "id": "8205022001",
    "district_id": "820502",
    "label": "Mangon",
    "value": "Mangon"
  },
  {
    "id": "8205022002",
    "district_id": "820502",
    "label": "Fagudu",
    "value": "Fagudu"
  },
  {
    "id": "8205022003",
    "district_id": "820502",
    "label": "Waibau",
    "value": "Waibau"
  },
  {
    "id": "8205022004",
    "district_id": "820502",
    "label": "Falahu",
    "value": "Falahu"
  },
  {
    "id": "8205022005",
    "district_id": "820502",
    "label": "Fatcei",
    "value": "Fatcei"
  },
  {
    "id": "8205022006",
    "district_id": "820502",
    "label": "Fogi",
    "value": "Fogi"
  },
  {
    "id": "8205022007",
    "district_id": "820502",
    "label": "Waihama",
    "value": "Waihama"
  },
  {
    "id": "8205022008",
    "district_id": "820502",
    "label": "Wai Ipa",
    "value": "Wai Ipa"
  },
  {
    "id": "8205022009",
    "district_id": "820502",
    "label": "Umaloya",
    "value": "Umaloya"
  },
  {
    "id": "8205022010",
    "district_id": "820502",
    "label": "Pastina",
    "value": "Pastina"
  },
  {
    "id": "8205022011",
    "district_id": "820502",
    "label": "Wailau",
    "value": "Wailau"
  },
  {
    "id": "8205032005",
    "district_id": "820503",
    "label": "Wai Ina",
    "value": "Wai Ina"
  },
  {
    "id": "8205032006",
    "district_id": "820503",
    "label": "Kabau Pantai",
    "value": "Kabau Pantai"
  },
  {
    "id": "8205032007",
    "district_id": "820503",
    "label": "Ona",
    "value": "Ona"
  },
  {
    "id": "8205032008",
    "district_id": "820503",
    "label": "Kabau Darat",
    "value": "Kabau Darat"
  },
  {
    "id": "8205032009",
    "district_id": "820503",
    "label": "Nahi",
    "value": "Nahi"
  },
  {
    "id": "8205032010",
    "district_id": "820503",
    "label": "Paratina",
    "value": "Paratina"
  },
  {
    "id": "8205062001",
    "district_id": "820506",
    "label": "Dofa",
    "value": "Dofa"
  },
  {
    "id": "8205062002",
    "district_id": "820506",
    "label": "Lekokadai",
    "value": "Lekokadai"
  },
  {
    "id": "8205062003",
    "district_id": "820506",
    "label": "Lekosula",
    "value": "Lekosula"
  },
  {
    "id": "8205062009",
    "district_id": "820506",
    "label": "Pas Ipa",
    "value": "Pas Ipa"
  },
  {
    "id": "8205062014",
    "district_id": "820506",
    "label": "Pelita",
    "value": "Pelita"
  },
  {
    "id": "8205062017",
    "district_id": "820506",
    "label": "Johor",
    "value": "Johor"
  },
  {
    "id": "8205062018",
    "district_id": "820506",
    "label": "Lelyaba",
    "value": "Lelyaba"
  },
  {
    "id": "8205072001",
    "district_id": "820507",
    "label": "Waiboga",
    "value": "Waiboga"
  },
  {
    "id": "8205072002",
    "district_id": "820507",
    "label": "Soamole",
    "value": "Soamole"
  },
  {
    "id": "8205072003",
    "district_id": "820507",
    "label": "Waiman",
    "value": "Waiman"
  },
  {
    "id": "8205072004",
    "district_id": "820507",
    "label": "Fat Iba",
    "value": "Fat Iba"
  },
  {
    "id": "8205072005",
    "district_id": "820507",
    "label": "Bega",
    "value": "Bega"
  },
  {
    "id": "8205072006",
    "district_id": "820507",
    "label": "Manaf",
    "value": "Manaf"
  },
  {
    "id": "8205082001",
    "district_id": "820508",
    "label": "Waigoiyofa",
    "value": "Waigoiyofa"
  },
  {
    "id": "8205082002",
    "district_id": "820508",
    "label": "Baleha",
    "value": "Baleha"
  },
  {
    "id": "8205082003",
    "district_id": "820508",
    "label": "Fatkauyon",
    "value": "Fatkauyon"
  },
  {
    "id": "8205082004",
    "district_id": "820508",
    "label": "Wailia",
    "value": "Wailia"
  },
  {
    "id": "8205082005",
    "district_id": "820508",
    "label": "Sama",
    "value": "Sama"
  },
  {
    "id": "8205082006",
    "district_id": "820508",
    "label": "Waisepa",
    "value": "Waisepa"
  },
  {
    "id": "8205092001",
    "district_id": "820509",
    "label": "Waigai",
    "value": "Waigai"
  },
  {
    "id": "8205092002",
    "district_id": "820509",
    "label": "Fuata",
    "value": "Fuata"
  },
  {
    "id": "8205092003",
    "district_id": "820509",
    "label": "Wainib",
    "value": "Wainib"
  },
  {
    "id": "8205092004",
    "district_id": "820509",
    "label": "Skom",
    "value": "Skom"
  },
  {
    "id": "8205092005",
    "district_id": "820509",
    "label": "Wai Tamua",
    "value": "Wai Tamua"
  },
  {
    "id": "8205102001",
    "district_id": "820510",
    "label": "Kawata",
    "value": "Kawata"
  },
  {
    "id": "8205102002",
    "district_id": "820510",
    "label": "Waisakai",
    "value": "Waisakai"
  },
  {
    "id": "8205102003",
    "district_id": "820510",
    "label": "Pelita Jaya",
    "value": "Pelita Jaya"
  },
  {
    "id": "8205102004",
    "district_id": "820510",
    "label": "Waisum",
    "value": "Waisum"
  },
  {
    "id": "8205112001",
    "district_id": "820511",
    "label": "Buruakol",
    "value": "Buruakol"
  },
  {
    "id": "8205112002",
    "district_id": "820511",
    "label": "Capalulu",
    "value": "Capalulu"
  },
  {
    "id": "8205112003",
    "district_id": "820511",
    "label": "Urifola",
    "value": "Urifola"
  },
  {
    "id": "8205112004",
    "district_id": "820511",
    "label": "Mangoli",
    "value": "Mangoli"
  },
  {
    "id": "8205112005",
    "district_id": "820511",
    "label": "Wailoba",
    "value": "Wailoba"
  },
  {
    "id": "8205112006",
    "district_id": "820511",
    "label": "Paslal",
    "value": "Paslal"
  },
  {
    "id": "8205112007",
    "district_id": "820511",
    "label": "Waitulia",
    "value": "Waitulia"
  },
  {
    "id": "8205112008",
    "district_id": "820511",
    "label": "Wai U",
    "value": "Wai U"
  },
  {
    "id": "8205112009",
    "district_id": "820511",
    "label": "Jere",
    "value": "Jere"
  },
  {
    "id": "8205122001",
    "district_id": "820512",
    "label": "Kaporo",
    "value": "Kaporo"
  },
  {
    "id": "8205122002",
    "district_id": "820512",
    "label": "Waikafia",
    "value": "Waikafia"
  },
  {
    "id": "8205122003",
    "district_id": "820512",
    "label": "Buya",
    "value": "Buya"
  },
  {
    "id": "8205122004",
    "district_id": "820512",
    "label": "Auponhia",
    "value": "Auponhia"
  },
  {
    "id": "8205122005",
    "district_id": "820512",
    "label": "Wailab",
    "value": "Wailab"
  },
  {
    "id": "8205132001",
    "district_id": "820513",
    "label": "Falabisahaya",
    "value": "Falabisahaya"
  },
  {
    "id": "8205132002",
    "district_id": "820513",
    "label": "Modapia",
    "value": "Modapia"
  },
  {
    "id": "8205132003",
    "district_id": "820513",
    "label": "Modapuhi",
    "value": "Modapuhi"
  },
  {
    "id": "8205132004",
    "district_id": "820513",
    "label": "Pastabulu",
    "value": "Pastabulu"
  },
  {
    "id": "8205132005",
    "district_id": "820513",
    "label": "Saniahaya",
    "value": "Saniahaya"
  },
  {
    "id": "8205132006",
    "district_id": "820513",
    "label": "Minaluli",
    "value": "Minaluli"
  },
  {
    "id": "8205132007",
    "district_id": "820513",
    "label": "Modapuhi Trans",
    "value": "Modapuhi Trans"
  },
  {
    "id": "8205182001",
    "district_id": "820518",
    "label": "Man-Gega",
    "value": "Man-Gega"
  },
  {
    "id": "8205182002",
    "district_id": "820518",
    "label": "Bajo",
    "value": "Bajo"
  },
  {
    "id": "8205182003",
    "district_id": "820518",
    "label": "Pohea",
    "value": "Pohea"
  },
  {
    "id": "8205182004",
    "district_id": "820518",
    "label": "Fukweu",
    "value": "Fukweu"
  },
  {
    "id": "8205182005",
    "district_id": "820518",
    "label": "Wainin",
    "value": "Wainin"
  },
  {
    "id": "8205182006",
    "district_id": "820518",
    "label": "Malbufa",
    "value": "Malbufa"
  },
  {
    "id": "8205182007",
    "district_id": "820518",
    "label": "Fokalik",
    "value": "Fokalik"
  },
  {
    "id": "8206012006",
    "district_id": "820601",
    "label": "Subaim",
    "value": "Subaim"
  },
  {
    "id": "8206012008",
    "district_id": "820601",
    "label": "Cemara Jaya",
    "value": "Cemara Jaya"
  },
  {
    "id": "8206012009",
    "district_id": "820601",
    "label": "Bumi Restu",
    "value": "Bumi Restu"
  },
  {
    "id": "8206012014",
    "district_id": "820601",
    "label": "Batu Raja",
    "value": "Batu Raja"
  },
  {
    "id": "8206012015",
    "district_id": "820601",
    "label": "Mekar Sari",
    "value": "Mekar Sari"
  },
  {
    "id": "8206012016",
    "district_id": "820601",
    "label": "Bulapapo",
    "value": "Bulapapo"
  },
  {
    "id": "8206012027",
    "district_id": "820601",
    "label": "Waisuba",
    "value": "Waisuba"
  },
  {
    "id": "8206022001",
    "district_id": "820602",
    "label": "Buli",
    "value": "Buli"
  },
  {
    "id": "8206022002",
    "district_id": "820602",
    "label": "Buli Asal",
    "value": "Buli Asal"
  },
  {
    "id": "8206022003",
    "district_id": "820602",
    "label": "Buli Karya",
    "value": "Buli Karya"
  },
  {
    "id": "8206022011",
    "district_id": "820602",
    "label": "Wayafly",
    "value": "Wayafly"
  },
  {
    "id": "8206022012",
    "district_id": "820602",
    "label": "Sailal",
    "value": "Sailal"
  },
  {
    "id": "8206022013",
    "district_id": "820602",
    "label": "Geltoli",
    "value": "Geltoli"
  },
  {
    "id": "8206022014",
    "district_id": "820602",
    "label": "Pekaulan",
    "value": "Pekaulan"
  },
  {
    "id": "8206022023",
    "district_id": "820602",
    "label": "Teluk Buli",
    "value": "Teluk Buli"
  },
  {
    "id": "8206022024",
    "district_id": "820602",
    "label": "Gamesan",
    "value": "Gamesan"
  },
  {
    "id": "8206022025",
    "district_id": "820602",
    "label": "Baburino",
    "value": "Baburino"
  },
  {
    "id": "8206032001",
    "district_id": "820603",
    "label": "Bicoli",
    "value": "Bicoli"
  },
  {
    "id": "8206032002",
    "district_id": "820603",
    "label": "Waci",
    "value": "Waci"
  },
  {
    "id": "8206032003",
    "district_id": "820603",
    "label": "Petelei",
    "value": "Petelei"
  },
  {
    "id": "8206032004",
    "district_id": "820603",
    "label": "Loleo Lamo",
    "value": "Loleo Lamo"
  },
  {
    "id": "8206032005",
    "district_id": "820603",
    "label": "Gotowasi",
    "value": "Gotowasi"
  },
  {
    "id": "8206032010",
    "district_id": "820603",
    "label": "Kasuba",
    "value": "Kasuba"
  },
  {
    "id": "8206032012",
    "district_id": "820603",
    "label": "Momole",
    "value": "Momole"
  },
  {
    "id": "8206032013",
    "district_id": "820603",
    "label": "Sil",
    "value": "Sil"
  },
  {
    "id": "8206032014",
    "district_id": "820603",
    "label": "Sowoli",
    "value": "Sowoli"
  },
  {
    "id": "8206042001",
    "district_id": "820604",
    "label": "Nusa Jaya",
    "value": "Nusa Jaya"
  },
  {
    "id": "8206042002",
    "district_id": "820604",
    "label": "Nusa Ambu",
    "value": "Nusa Ambu"
  },
  {
    "id": "8206042003",
    "district_id": "820604",
    "label": "Loleba",
    "value": "Loleba"
  },
  {
    "id": "8206042004",
    "district_id": "820604",
    "label": "Waijoi",
    "value": "Waijoi"
  },
  {
    "id": "8206042005",
    "district_id": "820604",
    "label": "Pintatu",
    "value": "Pintatu"
  },
  {
    "id": "8206042006",
    "district_id": "820604",
    "label": "Minamin",
    "value": "Minamin"
  },
  {
    "id": "8206042007",
    "district_id": "820604",
    "label": "Saramaake",
    "value": "Saramaake"
  },
  {
    "id": "8206042008",
    "district_id": "820604",
    "label": "Fayaul",
    "value": "Fayaul"
  },
  {
    "id": "8206042009",
    "district_id": "820604",
    "label": "Bina Gara",
    "value": "Bina Gara"
  },
  {
    "id": "8206042010",
    "district_id": "820604",
    "label": "Wasile",
    "value": "Wasile"
  },
  {
    "id": "8206042011",
    "district_id": "820604",
    "label": "Tomares",
    "value": "Tomares"
  },
  {
    "id": "8206042012",
    "district_id": "820604",
    "label": "Tabanalow",
    "value": "Tabanalow"
  },
  {
    "id": "8206042013",
    "district_id": "820604",
    "label": "Saolat",
    "value": "Saolat"
  },
  {
    "id": "8206042014",
    "district_id": "820604",
    "label": "Nanas",
    "value": "Nanas"
  },
  {
    "id": "8206042015",
    "district_id": "820604",
    "label": "Talaga Jaya",
    "value": "Talaga Jaya"
  },
  {
    "id": "8206042016",
    "district_id": "820604",
    "label": "Sondo-Sondo",
    "value": "Sondo-Sondo"
  },
  {
    "id": "8206042017",
    "district_id": "820604",
    "label": "Ino Jaya",
    "value": "Ino Jaya"
  },
  {
    "id": "8206042018",
    "district_id": "820604",
    "label": "Ake Jawi",
    "value": "Ake Jawi"
  },
  {
    "id": "8206042019",
    "district_id": "820604",
    "label": "Ekor",
    "value": "Ekor"
  },
  {
    "id": "8206042020",
    "district_id": "820604",
    "label": "Ekorino",
    "value": "Ekorino"
  },
  {
    "id": "8206042021",
    "district_id": "820604",
    "label": "Jiko Moi",
    "value": "Jiko Moi"
  },
  {
    "id": "8206042022",
    "district_id": "820604",
    "label": "Tanure",
    "value": "Tanure"
  },
  {
    "id": "8206042023",
    "district_id": "820604",
    "label": "Yawal",
    "value": "Yawal"
  },
  {
    "id": "8206042024",
    "district_id": "820604",
    "label": "Bukutio",
    "value": "Bukutio"
  },
  {
    "id": "8206052001",
    "district_id": "820605",
    "label": "Hate Tabako",
    "value": "Hate Tabako"
  },
  {
    "id": "8206052002",
    "district_id": "820605",
    "label": "Lolobata",
    "value": "Lolobata"
  },
  {
    "id": "8206052003",
    "district_id": "820605",
    "label": "Puao",
    "value": "Puao"
  },
  {
    "id": "8206052004",
    "district_id": "820605",
    "label": "Foly",
    "value": "Foly"
  },
  {
    "id": "8206052005",
    "district_id": "820605",
    "label": "Nyaolako",
    "value": "Nyaolako"
  },
  {
    "id": "8206052006",
    "district_id": "820605",
    "label": "Bokimaake",
    "value": "Bokimaake"
  },
  {
    "id": "8206052007",
    "district_id": "820605",
    "label": "Silalayang",
    "value": "Silalayang"
  },
  {
    "id": "8206052008",
    "district_id": "820605",
    "label": "Kakaraino",
    "value": "Kakaraino"
  },
  {
    "id": "8206062001",
    "district_id": "820606",
    "label": "Labi-Labi",
    "value": "Labi-Labi"
  },
  {
    "id": "8206062002",
    "district_id": "820606",
    "label": "Iga",
    "value": "Iga"
  },
  {
    "id": "8206062003",
    "district_id": "820606",
    "label": "Tatam",
    "value": "Tatam"
  },
  {
    "id": "8206062004",
    "district_id": "820606",
    "label": "Marimoi",
    "value": "Marimoi"
  },
  {
    "id": "8206062005",
    "district_id": "820606",
    "label": "Bololo",
    "value": "Bololo"
  },
  {
    "id": "8206062006",
    "district_id": "820606",
    "label": "Helaitetor",
    "value": "Helaitetor"
  },
  {
    "id": "8206062007",
    "district_id": "820606",
    "label": "Dowongi Jaya",
    "value": "Dowongi Jaya"
  },
  {
    "id": "8206062008",
    "district_id": "820606",
    "label": "Majiko Tongone",
    "value": "Majiko Tongone"
  },
  {
    "id": "8206072001",
    "district_id": "820607",
    "label": "Dodaga",
    "value": "Dodaga"
  },
  {
    "id": "8206072002",
    "district_id": "820607",
    "label": "Daka Ino",
    "value": "Daka Ino"
  },
  {
    "id": "8206072003",
    "district_id": "820607",
    "label": "Akedaga",
    "value": "Akedaga"
  },
  {
    "id": "8206072004",
    "district_id": "820607",
    "label": "Tobo Ino",
    "value": "Tobo Ino"
  },
  {
    "id": "8206072005",
    "district_id": "820607",
    "label": "Tutuling Jaya",
    "value": "Tutuling Jaya"
  },
  {
    "id": "8206072006",
    "district_id": "820607",
    "label": "Woka Jaya",
    "value": "Woka Jaya"
  },
  {
    "id": "8206072007",
    "district_id": "820607",
    "label": "Rawamangun",
    "value": "Rawamangun"
  },
  {
    "id": "8206072008",
    "district_id": "820607",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "8206082001",
    "district_id": "820608",
    "label": "Wayamli",
    "value": "Wayamli"
  },
  {
    "id": "8206082002",
    "district_id": "820608",
    "label": "Beringin Lamo",
    "value": "Beringin Lamo"
  },
  {
    "id": "8206082003",
    "district_id": "820608",
    "label": "Miaf",
    "value": "Miaf"
  },
  {
    "id": "8206082004",
    "district_id": "820608",
    "label": "Bangul",
    "value": "Bangul"
  },
  {
    "id": "8206082005",
    "district_id": "820608",
    "label": "Marasipno",
    "value": "Marasipno"
  },
  {
    "id": "8206082006",
    "district_id": "820608",
    "label": "Dorolamo",
    "value": "Dorolamo"
  },
  {
    "id": "8206082007",
    "district_id": "820608",
    "label": "Maratana",
    "value": "Maratana"
  },
  {
    "id": "8206082008",
    "district_id": "820608",
    "label": "Bebsili",
    "value": "Bebsili"
  },
  {
    "id": "8206082009",
    "district_id": "820608",
    "label": "Yawanli",
    "value": "Yawanli"
  },
  {
    "id": "8206082010",
    "district_id": "820608",
    "label": "Gaifoli",
    "value": "Gaifoli"
  },
  {
    "id": "8206082011",
    "district_id": "820608",
    "label": "Babasaram",
    "value": "Babasaram"
  },
  {
    "id": "8206082012",
    "district_id": "820608",
    "label": "Tatangapu",
    "value": "Tatangapu"
  },
  {
    "id": "8206092001",
    "district_id": "820609",
    "label": "Sosolat",
    "value": "Sosolat"
  },
  {
    "id": "8206092002",
    "district_id": "820609",
    "label": "Dorosagu",
    "value": "Dorosagu"
  },
  {
    "id": "8206092003",
    "district_id": "820609",
    "label": "Patlean",
    "value": "Patlean"
  },
  {
    "id": "8206092004",
    "district_id": "820609",
    "label": "Lolasita",
    "value": "Lolasita"
  },
  {
    "id": "8206092005",
    "district_id": "820609",
    "label": "Wasileo",
    "value": "Wasileo"
  },
  {
    "id": "8206092006",
    "district_id": "820609",
    "label": "Pumlanga",
    "value": "Pumlanga"
  },
  {
    "id": "8206092007",
    "district_id": "820609",
    "label": "Jara-jara",
    "value": "Jara-jara"
  },
  {
    "id": "8206092008",
    "district_id": "820609",
    "label": "Doromoi",
    "value": "Doromoi"
  },
  {
    "id": "8206092009",
    "district_id": "820609",
    "label": "Lili",
    "value": "Lili"
  },
  {
    "id": "8206092010",
    "district_id": "820609",
    "label": "Patlean Jaya",
    "value": "Patlean Jaya"
  },
  {
    "id": "8206102001",
    "district_id": "820610",
    "label": "Wai Lukum",
    "value": "Wai Lukum"
  },
  {
    "id": "8206102002",
    "district_id": "820610",
    "label": "Soa Laipoh",
    "value": "Soa Laipoh"
  },
  {
    "id": "8206102003",
    "district_id": "820610",
    "label": "Soa Sangaji",
    "value": "Soa Sangaji"
  },
  {
    "id": "8206102004",
    "district_id": "820610",
    "label": "Soagimalaha",
    "value": "Soagimalaha"
  },
  {
    "id": "8206102005",
    "district_id": "820610",
    "label": "Maba Sangaji",
    "value": "Maba Sangaji"
  },
  {
    "id": "8206102006",
    "district_id": "820610",
    "label": "Tewil",
    "value": "Tewil"
  },
  {
    "id": "8207012001",
    "district_id": "820701",
    "label": "Gotalamo",
    "value": "Gotalamo"
  },
  {
    "id": "8207012002",
    "district_id": "820701",
    "label": "Daruba",
    "value": "Daruba"
  },
  {
    "id": "8207012003",
    "district_id": "820701",
    "label": "Darame",
    "value": "Darame"
  },
  {
    "id": "8207012004",
    "district_id": "820701",
    "label": "Wawama",
    "value": "Wawama"
  },
  {
    "id": "8207012005",
    "district_id": "820701",
    "label": "Pandanga",
    "value": "Pandanga"
  },
  {
    "id": "8207012006",
    "district_id": "820701",
    "label": "Juanga",
    "value": "Juanga"
  },
  {
    "id": "8207012007",
    "district_id": "820701",
    "label": "Totodoku",
    "value": "Totodoku"
  },
  {
    "id": "8207012008",
    "district_id": "820701",
    "label": "Momojiu",
    "value": "Momojiu"
  },
  {
    "id": "8207012009",
    "district_id": "820701",
    "label": "Sabatai Baru",
    "value": "Sabatai Baru"
  },
  {
    "id": "8207012010",
    "district_id": "820701",
    "label": "Sabatai Tua",
    "value": "Sabatai Tua"
  },
  {
    "id": "8207012011",
    "district_id": "820701",
    "label": "Daeo",
    "value": "Daeo"
  },
  {
    "id": "8207012012",
    "district_id": "820701",
    "label": "Dehegila",
    "value": "Dehegila"
  },
  {
    "id": "8207012013",
    "district_id": "820701",
    "label": "Pilowo",
    "value": "Pilowo"
  },
  {
    "id": "8207012014",
    "district_id": "820701",
    "label": "Galo-Galo",
    "value": "Galo-Galo"
  },
  {
    "id": "8207012015",
    "district_id": "820701",
    "label": "Koloray",
    "value": "Koloray"
  },
  {
    "id": "8207012016",
    "district_id": "820701",
    "label": "Yayasan",
    "value": "Yayasan"
  },
  {
    "id": "8207012017",
    "district_id": "820701",
    "label": "Joubela",
    "value": "Joubela"
  },
  {
    "id": "8207012018",
    "district_id": "820701",
    "label": "Aha",
    "value": "Aha"
  },
  {
    "id": "8207012019",
    "district_id": "820701",
    "label": "Muhajirin",
    "value": "Muhajirin"
  },
  {
    "id": "8207012020",
    "district_id": "820701",
    "label": "Mandiri",
    "value": "Mandiri"
  },
  {
    "id": "8207012021",
    "district_id": "820701",
    "label": "Falila",
    "value": "Falila"
  },
  {
    "id": "8207012022",
    "district_id": "820701",
    "label": "Sabala",
    "value": "Sabala"
  },
  {
    "id": "8207012023",
    "district_id": "820701",
    "label": "Daeo Majiko",
    "value": "Daeo Majiko"
  },
  {
    "id": "8207012024",
    "district_id": "820701",
    "label": "Morodadi",
    "value": "Morodadi"
  },
  {
    "id": "8207012025",
    "district_id": "820701",
    "label": "Nakamura",
    "value": "Nakamura"
  },
  {
    "id": "8207022001",
    "district_id": "820702",
    "label": "Wayabula",
    "value": "Wayabula"
  },
  {
    "id": "8207022002",
    "district_id": "820702",
    "label": "Tiley",
    "value": "Tiley"
  },
  {
    "id": "8207022003",
    "district_id": "820702",
    "label": "Ngele-Ngele Kecil",
    "value": "Ngele-Ngele Kecil"
  },
  {
    "id": "8207022004",
    "district_id": "820702",
    "label": "Cucumare",
    "value": "Cucumare"
  },
  {
    "id": "8207022005",
    "district_id": "820702",
    "label": "Aru Irian",
    "value": "Aru Irian"
  },
  {
    "id": "8207022006",
    "district_id": "820702",
    "label": "Waringin",
    "value": "Waringin"
  },
  {
    "id": "8207022007",
    "district_id": "820702",
    "label": "Tutuhu",
    "value": "Tutuhu"
  },
  {
    "id": "8207022008",
    "district_id": "820702",
    "label": "Cio Gerong",
    "value": "Cio Gerong"
  },
  {
    "id": "8207022009",
    "district_id": "820702",
    "label": "Posi-Posi",
    "value": "Posi-Posi"
  },
  {
    "id": "8207022010",
    "district_id": "820702",
    "label": "Aru Burung",
    "value": "Aru Burung"
  },
  {
    "id": "8207022011",
    "district_id": "820702",
    "label": "Lou Madoro",
    "value": "Lou Madoro"
  },
  {
    "id": "8207022012",
    "district_id": "820702",
    "label": "Leo-Leo",
    "value": "Leo-Leo"
  },
  {
    "id": "8207022013",
    "district_id": "820702",
    "label": "Sami Nyamau",
    "value": "Sami Nyamau"
  },
  {
    "id": "8207022014",
    "district_id": "820702",
    "label": "Ngele-ngele Besar",
    "value": "Ngele-ngele Besar"
  },
  {
    "id": "8207022015",
    "district_id": "820702",
    "label": "Raja",
    "value": "Raja"
  },
  {
    "id": "8207022016",
    "district_id": "820702",
    "label": "Cio Dalam",
    "value": "Cio Dalam"
  },
  {
    "id": "8207022017",
    "district_id": "820702",
    "label": "Usbar Pantai",
    "value": "Usbar Pantai"
  },
  {
    "id": "8207022018",
    "district_id": "820702",
    "label": "Tiley Pantai",
    "value": "Tiley Pantai"
  },
  {
    "id": "8207022019",
    "district_id": "820702",
    "label": "Cio Maloleo",
    "value": "Cio Maloleo"
  },
  {
    "id": "8207022020",
    "district_id": "820702",
    "label": "Bobula",
    "value": "Bobula"
  },
  {
    "id": "8207032001",
    "district_id": "820703",
    "label": "Pangeo",
    "value": "Pangeo"
  },
  {
    "id": "8207032002",
    "district_id": "820703",
    "label": "Sopi",
    "value": "Sopi"
  },
  {
    "id": "8207032003",
    "district_id": "820703",
    "label": "Bere Bere Kecil",
    "value": "Bere Bere Kecil"
  },
  {
    "id": "8207032004",
    "district_id": "820703",
    "label": "Titigogoli",
    "value": "Titigogoli"
  },
  {
    "id": "8207032005",
    "district_id": "820703",
    "label": "Hapo",
    "value": "Hapo"
  },
  {
    "id": "8207032006",
    "district_id": "820703",
    "label": "Libano",
    "value": "Libano"
  },
  {
    "id": "8207032007",
    "district_id": "820703",
    "label": "Aru",
    "value": "Aru"
  },
  {
    "id": "8207032008",
    "district_id": "820703",
    "label": "Towara",
    "value": "Towara"
  },
  {
    "id": "8207032009",
    "district_id": "820703",
    "label": "Cendana",
    "value": "Cendana"
  },
  {
    "id": "8207032010",
    "district_id": "820703",
    "label": "Podimor Padange",
    "value": "Podimor Padange"
  },
  {
    "id": "8207032011",
    "district_id": "820703",
    "label": "Sopi Majiko",
    "value": "Sopi Majiko"
  },
  {
    "id": "8207032012",
    "district_id": "820703",
    "label": "Gorugo",
    "value": "Gorugo"
  },
  {
    "id": "8207032013",
    "district_id": "820703",
    "label": "Loleo",
    "value": "Loleo"
  },
  {
    "id": "8207032014",
    "district_id": "820703",
    "label": "Cempaka",
    "value": "Cempaka"
  },
  {
    "id": "8207042001",
    "district_id": "820704",
    "label": "Bere-bere",
    "value": "Bere-bere"
  },
  {
    "id": "8207042002",
    "district_id": "820704",
    "label": "Sakita",
    "value": "Sakita"
  },
  {
    "id": "8207042003",
    "district_id": "820704",
    "label": "Tawakali",
    "value": "Tawakali"
  },
  {
    "id": "8207042004",
    "district_id": "820704",
    "label": "Yao",
    "value": "Yao"
  },
  {
    "id": "8207042005",
    "district_id": "820704",
    "label": "Bido",
    "value": "Bido"
  },
  {
    "id": "8207042006",
    "district_id": "820704",
    "label": "Gorua",
    "value": "Gorua"
  },
  {
    "id": "8207042007",
    "district_id": "820704",
    "label": "Korago",
    "value": "Korago"
  },
  {
    "id": "8207042008",
    "district_id": "820704",
    "label": "Lusuo",
    "value": "Lusuo"
  },
  {
    "id": "8207042009",
    "district_id": "820704",
    "label": "Kenari",
    "value": "Kenari"
  },
  {
    "id": "8207042010",
    "district_id": "820704",
    "label": "Loleo Jaya",
    "value": "Loleo Jaya"
  },
  {
    "id": "8207042011",
    "district_id": "820704",
    "label": "Maba",
    "value": "Maba"
  },
  {
    "id": "8207042012",
    "district_id": "820704",
    "label": "Tanjung Saleh",
    "value": "Tanjung Saleh"
  },
  {
    "id": "8207042013",
    "district_id": "820704",
    "label": "Goa Hira",
    "value": "Goa Hira"
  },
  {
    "id": "8207042014",
    "district_id": "820704",
    "label": "Gorua Selatan",
    "value": "Gorua Selatan"
  },
  {
    "id": "8207052001",
    "district_id": "820705",
    "label": "Buho-Buho",
    "value": "Buho-Buho"
  },
  {
    "id": "8207052002",
    "district_id": "820705",
    "label": "Wewemo",
    "value": "Wewemo"
  },
  {
    "id": "8207052003",
    "district_id": "820705",
    "label": "Mira",
    "value": "Mira"
  },
  {
    "id": "8207052004",
    "district_id": "820705",
    "label": "Lifao",
    "value": "Lifao"
  },
  {
    "id": "8207052005",
    "district_id": "820705",
    "label": "Rahmat",
    "value": "Rahmat"
  },
  {
    "id": "8207052006",
    "district_id": "820705",
    "label": "Sambiki Tua",
    "value": "Sambiki Tua"
  },
  {
    "id": "8207052007",
    "district_id": "820705",
    "label": "Sangowo",
    "value": "Sangowo"
  },
  {
    "id": "8207052008",
    "district_id": "820705",
    "label": "Sambiki Baru",
    "value": "Sambiki Baru"
  },
  {
    "id": "8207052009",
    "district_id": "820705",
    "label": "Sangowo Barat",
    "value": "Sangowo Barat"
  },
  {
    "id": "8207052010",
    "district_id": "820705",
    "label": "Sangowo Timur",
    "value": "Sangowo Timur"
  },
  {
    "id": "8207052011",
    "district_id": "820705",
    "label": "Seseli Jaya",
    "value": "Seseli Jaya"
  },
  {
    "id": "8207052012",
    "district_id": "820705",
    "label": "Hino",
    "value": "Hino"
  },
  {
    "id": "8207052013",
    "district_id": "820705",
    "label": "Gosoma Maluku",
    "value": "Gosoma Maluku"
  },
  {
    "id": "8207052014",
    "district_id": "820705",
    "label": "Gamlamo",
    "value": "Gamlamo"
  },
  {
    "id": "8207052015",
    "district_id": "820705",
    "label": "Doku Mira",
    "value": "Doku Mira"
  },
  {
    "id": "8208012001",
    "district_id": "820801",
    "label": "Bobong",
    "value": "Bobong"
  },
  {
    "id": "8208012002",
    "district_id": "820801",
    "label": "Talo",
    "value": "Talo"
  },
  {
    "id": "8208012003",
    "district_id": "820801",
    "label": "Kawalo",
    "value": "Kawalo"
  },
  {
    "id": "8208012004",
    "district_id": "820801",
    "label": "Limbo",
    "value": "Limbo"
  },
  {
    "id": "8208012005",
    "district_id": "820801",
    "label": "Meranti Jaya",
    "value": "Meranti Jaya"
  },
  {
    "id": "8208012006",
    "district_id": "820801",
    "label": "Kramat",
    "value": "Kramat"
  },
  {
    "id": "8208012007",
    "district_id": "820801",
    "label": "Holbota",
    "value": "Holbota"
  },
  {
    "id": "8208012008",
    "district_id": "820801",
    "label": "Pancoran",
    "value": "Pancoran"
  },
  {
    "id": "8208012009",
    "district_id": "820801",
    "label": "Wayo",
    "value": "Wayo"
  },
  {
    "id": "8208012010",
    "district_id": "820801",
    "label": "Loho Bubba",
    "value": "Loho Bubba"
  },
  {
    "id": "8208012011",
    "district_id": "820801",
    "label": "Kilong",
    "value": "Kilong"
  },
  {
    "id": "8208012012",
    "district_id": "820801",
    "label": "Ratahaya",
    "value": "Ratahaya"
  },
  {
    "id": "8208012013",
    "district_id": "820801",
    "label": "Woyo",
    "value": "Woyo"
  },
  {
    "id": "8208022001",
    "district_id": "820802",
    "label": "Nggele",
    "value": "Nggele"
  },
  {
    "id": "8208022002",
    "district_id": "820802",
    "label": "Salati",
    "value": "Salati"
  },
  {
    "id": "8208022003",
    "district_id": "820802",
    "label": "Beringin Jaya",
    "value": "Beringin Jaya"
  },
  {
    "id": "8208022004",
    "district_id": "820802",
    "label": "Kasango",
    "value": "Kasango"
  },
  {
    "id": "8208022005",
    "district_id": "820802",
    "label": "Onemay",
    "value": "Onemay"
  },
  {
    "id": "8208032001",
    "district_id": "820803",
    "label": "Lede",
    "value": "Lede"
  },
  {
    "id": "8208032002",
    "district_id": "820803",
    "label": "Todoli",
    "value": "Todoli"
  },
  {
    "id": "8208032003",
    "district_id": "820803",
    "label": "Tolong",
    "value": "Tolong"
  },
  {
    "id": "8208032004",
    "district_id": "820803",
    "label": "Langganu",
    "value": "Langganu"
  },
  {
    "id": "8208032005",
    "district_id": "820803",
    "label": "Balohang",
    "value": "Balohang"
  },
  {
    "id": "8208042001",
    "district_id": "820804",
    "label": "Mananga",
    "value": "Mananga"
  },
  {
    "id": "8208042002",
    "district_id": "820804",
    "label": "Tanjung Una",
    "value": "Tanjung Una"
  },
  {
    "id": "8208042003",
    "district_id": "820804",
    "label": "Jorjoga",
    "value": "Jorjoga"
  },
  {
    "id": "8208042004",
    "district_id": "820804",
    "label": "Gela",
    "value": "Gela"
  },
  {
    "id": "8208042005",
    "district_id": "820804",
    "label": "Minton",
    "value": "Minton"
  },
  {
    "id": "8208042006",
    "district_id": "820804",
    "label": "Nunca",
    "value": "Nunca"
  },
  {
    "id": "8208042007",
    "district_id": "820804",
    "label": "Sahu",
    "value": "Sahu"
  },
  {
    "id": "8208042008",
    "district_id": "820804",
    "label": "Bua Mbono",
    "value": "Bua Mbono"
  },
  {
    "id": "8208042009",
    "district_id": "820804",
    "label": "Hai",
    "value": "Hai"
  },
  {
    "id": "8208042010",
    "district_id": "820804",
    "label": "Tikong",
    "value": "Tikong"
  },
  {
    "id": "8208042011",
    "district_id": "820804",
    "label": "Dege",
    "value": "Dege"
  },
  {
    "id": "8208042012",
    "district_id": "820804",
    "label": "Air Bulan",
    "value": "Air Bulan"
  },
  {
    "id": "8208042013",
    "district_id": "820804",
    "label": "Air Kalimat",
    "value": "Air Kalimat"
  },
  {
    "id": "8208042014",
    "district_id": "820804",
    "label": "Ufung",
    "value": "Ufung"
  },
  {
    "id": "8208042015",
    "district_id": "820804",
    "label": "Padang",
    "value": "Padang"
  },
  {
    "id": "8208042016",
    "district_id": "820804",
    "label": "Natang Kuning",
    "value": "Natang Kuning"
  },
  {
    "id": "8208042017",
    "district_id": "820804",
    "label": "Nunu",
    "value": "Nunu"
  },
  {
    "id": "8208042018",
    "district_id": "820804",
    "label": "London",
    "value": "London"
  },
  {
    "id": "8208042019",
    "district_id": "820804",
    "label": "Wahe",
    "value": "Wahe"
  },
  {
    "id": "8208052001",
    "district_id": "820805",
    "label": "Penu",
    "value": "Penu"
  },
  {
    "id": "8208052002",
    "district_id": "820805",
    "label": "Parigi",
    "value": "Parigi"
  },
  {
    "id": "8208052003",
    "district_id": "820805",
    "label": "Samuya",
    "value": "Samuya"
  },
  {
    "id": "8208052004",
    "district_id": "820805",
    "label": "Tubang",
    "value": "Tubang"
  },
  {
    "id": "8208062001",
    "district_id": "820806",
    "label": "Waikadai",
    "value": "Waikadai"
  },
  {
    "id": "8208062002",
    "district_id": "820806",
    "label": "Losseng",
    "value": "Losseng"
  },
  {
    "id": "8208062003",
    "district_id": "820806",
    "label": "Kawadang",
    "value": "Kawadang"
  },
  {
    "id": "8208062004",
    "district_id": "820806",
    "label": "Sofan",
    "value": "Sofan"
  },
  {
    "id": "8208062005",
    "district_id": "820806",
    "label": "Mantarara",
    "value": "Mantarara"
  },
  {
    "id": "8208062006",
    "district_id": "820806",
    "label": "Belo",
    "value": "Belo"
  },
  {
    "id": "8208062007",
    "district_id": "820806",
    "label": "Kamaya",
    "value": "Kamaya"
  },
  {
    "id": "8208062008",
    "district_id": "820806",
    "label": "Waikoka",
    "value": "Waikoka"
  },
  {
    "id": "8208062009",
    "district_id": "820806",
    "label": "Waikadai Sula",
    "value": "Waikadai Sula"
  },
  {
    "id": "8208072001",
    "district_id": "820807",
    "label": "Bahu",
    "value": "Bahu"
  },
  {
    "id": "8208072002",
    "district_id": "820807",
    "label": "Bapenu",
    "value": "Bapenu"
  },
  {
    "id": "8208072003",
    "district_id": "820807",
    "label": "Kilo",
    "value": "Kilo"
  },
  {
    "id": "8208072004",
    "district_id": "820807",
    "label": "Pancado",
    "value": "Pancado"
  },
  {
    "id": "8208072005",
    "district_id": "820807",
    "label": "Nggaki",
    "value": "Nggaki"
  },
  {
    "id": "8208072006",
    "district_id": "820807",
    "label": "Sumbong",
    "value": "Sumbong"
  },
  {
    "id": "8208072007",
    "district_id": "820807",
    "label": "Maluli",
    "value": "Maluli"
  },
  {
    "id": "8208072008",
    "district_id": "820807",
    "label": "Galebo",
    "value": "Galebo"
  },
  {
    "id": "8208072009",
    "district_id": "820807",
    "label": "Nggoli",
    "value": "Nggoli"
  },
  {
    "id": "8208082001",
    "district_id": "820808",
    "label": "Tabona",
    "value": "Tabona"
  },
  {
    "id": "8208082002",
    "district_id": "820808",
    "label": "Kabunu",
    "value": "Kabunu"
  },
  {
    "id": "8208082003",
    "district_id": "820808",
    "label": "Peleng",
    "value": "Peleng"
  },
  {
    "id": "8208082004",
    "district_id": "820808",
    "label": "Fayaunana",
    "value": "Fayaunana"
  },
  {
    "id": "8208082005",
    "district_id": "820808",
    "label": "Habunuha",
    "value": "Habunuha"
  },
  {
    "id": "8208082006",
    "district_id": "820808",
    "label": "Kataga",
    "value": "Kataga"
  },
  {
    "id": "8208082007",
    "district_id": "820808",
    "label": "Wolio",
    "value": "Wolio"
  },
  {
    "id": "8271011001",
    "district_id": "827101",
    "label": "Jambula",
    "value": "Jambula"
  },
  {
    "id": "8271011002",
    "district_id": "827101",
    "label": "Foramadiahi",
    "value": "Foramadiahi"
  },
  {
    "id": "8271011003",
    "district_id": "827101",
    "label": "Kastela",
    "value": "Kastela"
  },
  {
    "id": "8271011004",
    "district_id": "827101",
    "label": "Rua",
    "value": "Rua"
  },
  {
    "id": "8271011005",
    "district_id": "827101",
    "label": "Afe Taduma",
    "value": "Afe Taduma"
  },
  {
    "id": "8271011019",
    "district_id": "827101",
    "label": "Dorpedu",
    "value": "Dorpedu"
  },
  {
    "id": "8271021001",
    "district_id": "827102",
    "label": "Sasa",
    "value": "Sasa"
  },
  {
    "id": "8271021002",
    "district_id": "827102",
    "label": "Gambesi",
    "value": "Gambesi"
  },
  {
    "id": "8271021003",
    "district_id": "827102",
    "label": "Fitu",
    "value": "Fitu"
  },
  {
    "id": "8271021004",
    "district_id": "827102",
    "label": "Kalumata",
    "value": "Kalumata"
  },
  {
    "id": "8271021005",
    "district_id": "827102",
    "label": "Kayu Merah",
    "value": "Kayu Merah"
  },
  {
    "id": "8271021006",
    "district_id": "827102",
    "label": "Bastiong Talangame",
    "value": "Bastiong Talangame"
  },
  {
    "id": "8271021007",
    "district_id": "827102",
    "label": "Ubo-Ubo",
    "value": "Ubo-Ubo"
  },
  {
    "id": "8271021008",
    "district_id": "827102",
    "label": "Mangga Dua",
    "value": "Mangga Dua"
  },
  {
    "id": "8271021009",
    "district_id": "827102",
    "label": "Jati",
    "value": "Jati"
  },
  {
    "id": "8271021010",
    "district_id": "827102",
    "label": "Toboko",
    "value": "Toboko"
  },
  {
    "id": "8271021011",
    "district_id": "827102",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "8271021020",
    "district_id": "827102",
    "label": "Tanah Tinggi Barat",
    "value": "Tanah Tinggi Barat"
  },
  {
    "id": "8271021021",
    "district_id": "827102",
    "label": "Mangga Dua Utara",
    "value": "Mangga Dua Utara"
  },
  {
    "id": "8271021022",
    "district_id": "827102",
    "label": "Jati Perumnas",
    "value": "Jati Perumnas"
  },
  {
    "id": "8271021023",
    "district_id": "827102",
    "label": "Tabona",
    "value": "Tabona"
  },
  {
    "id": "8271021024",
    "district_id": "827102",
    "label": "Bastiong Karance",
    "value": "Bastiong Karance"
  },
  {
    "id": "8271021025",
    "district_id": "827102",
    "label": "Ngade",
    "value": "Ngade"
  },
  {
    "id": "8271031007",
    "district_id": "827103",
    "label": "Soa",
    "value": "Soa"
  },
  {
    "id": "8271031008",
    "district_id": "827103",
    "label": "Soa Sio",
    "value": "Soa Sio"
  },
  {
    "id": "8271031009",
    "district_id": "827103",
    "label": "Kasturian",
    "value": "Kasturian"
  },
  {
    "id": "8271031010",
    "district_id": "827103",
    "label": "Salero",
    "value": "Salero"
  },
  {
    "id": "8271031011",
    "district_id": "827103",
    "label": "Toboleu",
    "value": "Toboleu"
  },
  {
    "id": "8271031012",
    "district_id": "827103",
    "label": "Sangaji",
    "value": "Sangaji"
  },
  {
    "id": "8271031013",
    "district_id": "827103",
    "label": "Dufa-Dufa",
    "value": "Dufa-Dufa"
  },
  {
    "id": "8271031014",
    "district_id": "827103",
    "label": "Tafure",
    "value": "Tafure"
  },
  {
    "id": "8271031015",
    "district_id": "827103",
    "label": "Tabam",
    "value": "Tabam"
  },
  {
    "id": "8271031016",
    "district_id": "827103",
    "label": "Sango",
    "value": "Sango"
  },
  {
    "id": "8271031017",
    "district_id": "827103",
    "label": "Tarau",
    "value": "Tarau"
  },
  {
    "id": "8271031019",
    "district_id": "827103",
    "label": "Sangaji utara",
    "value": "Sangaji utara"
  },
  {
    "id": "8271031020",
    "district_id": "827103",
    "label": "Akehuda",
    "value": "Akehuda"
  },
  {
    "id": "8271031021",
    "district_id": "827103",
    "label": "Tubo",
    "value": "Tubo"
  },
  {
    "id": "8271041001",
    "district_id": "827104",
    "label": "Takofi",
    "value": "Takofi"
  },
  {
    "id": "8271041002",
    "district_id": "827104",
    "label": "Moti Kota",
    "value": "Moti Kota"
  },
  {
    "id": "8271041003",
    "district_id": "827104",
    "label": "Tafamutu",
    "value": "Tafamutu"
  },
  {
    "id": "8271041004",
    "district_id": "827104",
    "label": "Tafaga",
    "value": "Tafaga"
  },
  {
    "id": "8271041006",
    "district_id": "827104",
    "label": "Figur",
    "value": "Figur"
  },
  {
    "id": "8271041007",
    "district_id": "827104",
    "label": "Tadenas",
    "value": "Tadenas"
  },
  {
    "id": "8271051001",
    "district_id": "827105",
    "label": "Mayau",
    "value": "Mayau"
  },
  {
    "id": "8271051002",
    "district_id": "827105",
    "label": "Tifure",
    "value": "Tifure"
  },
  {
    "id": "8271051003",
    "district_id": "827105",
    "label": "Lelewi",
    "value": "Lelewi"
  },
  {
    "id": "8271051004",
    "district_id": "827105",
    "label": "Bido",
    "value": "Bido"
  },
  {
    "id": "8271051005",
    "district_id": "827105",
    "label": "Pantai Sagu",
    "value": "Pantai Sagu"
  },
  {
    "id": "8271051006",
    "district_id": "827105",
    "label": "Perum Bersatu",
    "value": "Perum Bersatu"
  },
  {
    "id": "8271061001",
    "district_id": "827106",
    "label": "Takoma",
    "value": "Takoma"
  },
  {
    "id": "8271061002",
    "district_id": "827106",
    "label": "Tanah Raja",
    "value": "Tanah Raja"
  },
  {
    "id": "8271061003",
    "district_id": "827106",
    "label": "Muhajirin",
    "value": "Muhajirin"
  },
  {
    "id": "8271061004",
    "district_id": "827106",
    "label": "Stadion",
    "value": "Stadion"
  },
  {
    "id": "8271061005",
    "district_id": "827106",
    "label": "Maliaro",
    "value": "Maliaro"
  },
  {
    "id": "8271061006",
    "district_id": "827106",
    "label": "Kampung Pisang",
    "value": "Kampung Pisang"
  },
  {
    "id": "8271061007",
    "district_id": "827106",
    "label": "Marikurubu",
    "value": "Marikurubu"
  },
  {
    "id": "8271061008",
    "district_id": "827106",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "8271061009",
    "district_id": "827106",
    "label": "Gamalama",
    "value": "Gamalama"
  },
  {
    "id": "8271061010",
    "district_id": "827106",
    "label": "Kalumpang",
    "value": "Kalumpang"
  },
  {
    "id": "8271061011",
    "district_id": "827106",
    "label": "Moya",
    "value": "Moya"
  },
  {
    "id": "8271061012",
    "district_id": "827106",
    "label": "Santiong",
    "value": "Santiong"
  },
  {
    "id": "8271061013",
    "district_id": "827106",
    "label": "Makassar Barat",
    "value": "Makassar Barat"
  },
  {
    "id": "8271061014",
    "district_id": "827106",
    "label": "Makassar Timur",
    "value": "Makassar Timur"
  },
  {
    "id": "8271061015",
    "district_id": "827106",
    "label": "Salahuddin",
    "value": "Salahuddin"
  },
  {
    "id": "8271061016",
    "district_id": "827106",
    "label": "Tongole",
    "value": "Tongole"
  },
  {
    "id": "8271071001",
    "district_id": "827107",
    "label": "Faudu",
    "value": "Faudu"
  },
  {
    "id": "8271071002",
    "district_id": "827107",
    "label": "Tomajiko",
    "value": "Tomajiko"
  },
  {
    "id": "8271071003",
    "district_id": "827107",
    "label": "Dorari Isa",
    "value": "Dorari Isa"
  },
  {
    "id": "8271071004",
    "district_id": "827107",
    "label": "Togolobe",
    "value": "Togolobe"
  },
  {
    "id": "8271071005",
    "district_id": "827107",
    "label": "Mado",
    "value": "Mado"
  },
  {
    "id": "8271071006",
    "district_id": "827107",
    "label": "Tafraka",
    "value": "Tafraka"
  },
  {
    "id": "8271081001",
    "district_id": "827108",
    "label": "Tagafo",
    "value": "Tagafo"
  },
  {
    "id": "8271081002",
    "district_id": "827108",
    "label": "Loto",
    "value": "Loto"
  },
  {
    "id": "8271081003",
    "district_id": "827108",
    "label": "Takome",
    "value": "Takome"
  },
  {
    "id": "8271081004",
    "district_id": "827108",
    "label": "Sulamadaha",
    "value": "Sulamadaha"
  },
  {
    "id": "8271081005",
    "district_id": "827108",
    "label": "Tobololo",
    "value": "Tobololo"
  },
  {
    "id": "8271081006",
    "district_id": "827108",
    "label": "Bula",
    "value": "Bula"
  },
  {
    "id": "8271081007",
    "district_id": "827108",
    "label": "Kulaba",
    "value": "Kulaba"
  },
  {
    "id": "8272011001",
    "district_id": "827201",
    "label": "Soasio",
    "value": "Soasio"
  },
  {
    "id": "8272011002",
    "district_id": "827201",
    "label": "Gamtufkange",
    "value": "Gamtufkange"
  },
  {
    "id": "8272011003",
    "district_id": "827201",
    "label": "Indonesiana",
    "value": "Indonesiana"
  },
  {
    "id": "8272011005",
    "district_id": "827201",
    "label": "Seli",
    "value": "Seli"
  },
  {
    "id": "8272011006",
    "district_id": "827201",
    "label": "Soadara",
    "value": "Soadara"
  },
  {
    "id": "8272011007",
    "district_id": "827201",
    "label": "Topo",
    "value": "Topo"
  },
  {
    "id": "8272011008",
    "district_id": "827201",
    "label": "Gurabunga",
    "value": "Gurabunga"
  },
  {
    "id": "8272011012",
    "district_id": "827201",
    "label": "Goto",
    "value": "Goto"
  },
  {
    "id": "8272011013",
    "district_id": "827201",
    "label": "Tomagoba",
    "value": "Tomagoba"
  },
  {
    "id": "8272011014",
    "district_id": "827201",
    "label": "Folarora",
    "value": "Folarora"
  },
  {
    "id": "8272011015",
    "district_id": "827201",
    "label": "Topo Tiga",
    "value": "Topo Tiga"
  },
  {
    "id": "8272011016",
    "district_id": "827201",
    "label": "Tambula",
    "value": "Tambula"
  },
  {
    "id": "8272011017",
    "district_id": "827201",
    "label": "Tuguwaji",
    "value": "Tuguwaji"
  },
  {
    "id": "8272021002",
    "district_id": "827202",
    "label": "Guraping",
    "value": "Guraping"
  },
  {
    "id": "8272021003",
    "district_id": "827202",
    "label": "Sofifi",
    "value": "Sofifi"
  },
  {
    "id": "8272022001",
    "district_id": "827202",
    "label": "Kaiyasa",
    "value": "Kaiyasa"
  },
  {
    "id": "8272022005",
    "district_id": "827202",
    "label": "Oba",
    "value": "Oba"
  },
  {
    "id": "8272022006",
    "district_id": "827202",
    "label": "Somahode",
    "value": "Somahode"
  },
  {
    "id": "8272022007",
    "district_id": "827202",
    "label": "Ake Kolano",
    "value": "Ake Kolano"
  },
  {
    "id": "8272022009",
    "district_id": "827202",
    "label": "Balbar",
    "value": "Balbar"
  },
  {
    "id": "8272022010",
    "district_id": "827202",
    "label": "Bukit Durian",
    "value": "Bukit Durian"
  },
  {
    "id": "8272022011",
    "district_id": "827202",
    "label": "Galala",
    "value": "Galala"
  },
  {
    "id": "8272022012",
    "district_id": "827202",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "8272022013",
    "district_id": "827202",
    "label": "Kusu",
    "value": "Kusu"
  },
  {
    "id": "8272022014",
    "district_id": "827202",
    "label": "Garojou",
    "value": "Garojou"
  },
  {
    "id": "8272022015",
    "district_id": "827202",
    "label": "Gosale",
    "value": "Gosale"
  },
  {
    "id": "8272031004",
    "district_id": "827203",
    "label": "Payahe",
    "value": "Payahe"
  },
  {
    "id": "8272032001",
    "district_id": "827203",
    "label": "Woda",
    "value": "Woda"
  },
  {
    "id": "8272032002",
    "district_id": "827203",
    "label": "Gita",
    "value": "Gita"
  },
  {
    "id": "8272032003",
    "district_id": "827203",
    "label": "Toseho",
    "value": "Toseho"
  },
  {
    "id": "8272032005",
    "district_id": "827203",
    "label": "Kususinopa",
    "value": "Kususinopa"
  },
  {
    "id": "8272032008",
    "district_id": "827203",
    "label": "Bale",
    "value": "Bale"
  },
  {
    "id": "8272032009",
    "district_id": "827203",
    "label": "Koli",
    "value": "Koli"
  },
  {
    "id": "8272032010",
    "district_id": "827203",
    "label": "Kosa",
    "value": "Kosa"
  },
  {
    "id": "8272032011",
    "district_id": "827203",
    "label": "Tului",
    "value": "Tului"
  },
  {
    "id": "8272032012",
    "district_id": "827203",
    "label": "Todopa",
    "value": "Todopa"
  },
  {
    "id": "8272032013",
    "district_id": "827203",
    "label": "Talasi",
    "value": "Talasi"
  },
  {
    "id": "8272032014",
    "district_id": "827203",
    "label": "Sigela Yef",
    "value": "Sigela Yef"
  },
  {
    "id": "8272032015",
    "district_id": "827203",
    "label": "Talagamori",
    "value": "Talagamori"
  },
  {
    "id": "8272041001",
    "district_id": "827204",
    "label": "Toloa",
    "value": "Toloa"
  },
  {
    "id": "8272041002",
    "district_id": "827204",
    "label": "Dokiri",
    "value": "Dokiri"
  },
  {
    "id": "8272041003",
    "district_id": "827204",
    "label": "Tuguiha",
    "value": "Tuguiha"
  },
  {
    "id": "8272041004",
    "district_id": "827204",
    "label": "Tomalou",
    "value": "Tomalou"
  },
  {
    "id": "8272041005",
    "district_id": "827204",
    "label": "Gurabati",
    "value": "Gurabati"
  },
  {
    "id": "8272041006",
    "district_id": "827204",
    "label": "Tongowai",
    "value": "Tongowai"
  },
  {
    "id": "8272042007",
    "district_id": "827204",
    "label": "Mare Kofo",
    "value": "Mare Kofo"
  },
  {
    "id": "8272042008",
    "district_id": "827204",
    "label": "Mare Gam",
    "value": "Mare Gam"
  },
  {
    "id": "8272051001",
    "district_id": "827205",
    "label": "Rum",
    "value": "Rum"
  },
  {
    "id": "8272051002",
    "district_id": "827205",
    "label": "Fobaharu",
    "value": "Fobaharu"
  },
  {
    "id": "8272051003",
    "district_id": "827205",
    "label": "Jaya",
    "value": "Jaya"
  },
  {
    "id": "8272051004",
    "district_id": "827205",
    "label": "Ome",
    "value": "Ome"
  },
  {
    "id": "8272051005",
    "district_id": "827205",
    "label": "Mareku",
    "value": "Mareku"
  },
  {
    "id": "8272051006",
    "district_id": "827205",
    "label": "Afa Afa",
    "value": "Afa Afa"
  },
  {
    "id": "8272051008",
    "district_id": "827205",
    "label": "Bobo",
    "value": "Bobo"
  },
  {
    "id": "8272051009",
    "district_id": "827205",
    "label": "Gubu Kusuma",
    "value": "Gubu Kusuma"
  },
  {
    "id": "8272051010",
    "district_id": "827205",
    "label": "Sirongo Folaraha",
    "value": "Sirongo Folaraha"
  },
  {
    "id": "8272051011",
    "district_id": "827205",
    "label": "Rum Balibunga",
    "value": "Rum Balibunga"
  },
  {
    "id": "8272052007",
    "district_id": "827205",
    "label": "Maitara",
    "value": "Maitara"
  },
  {
    "id": "8272052012",
    "district_id": "827205",
    "label": "Maitara Selatan",
    "value": "Maitara Selatan"
  },
  {
    "id": "8272052013",
    "district_id": "827205",
    "label": "Maitara Utara",
    "value": "Maitara Utara"
  },
  {
    "id": "8272052014",
    "district_id": "827205",
    "label": "Maitara Tengah",
    "value": "Maitara Tengah"
  },
  {
    "id": "8272061001",
    "district_id": "827206",
    "label": "Akelamo",
    "value": "Akelamo"
  },
  {
    "id": "8272062002",
    "district_id": "827206",
    "label": "Lola",
    "value": "Lola"
  },
  {
    "id": "8272062003",
    "district_id": "827206",
    "label": "Aketobololo",
    "value": "Aketobololo"
  },
  {
    "id": "8272062004",
    "district_id": "827206",
    "label": "Aketobatu",
    "value": "Aketobatu"
  },
  {
    "id": "8272062005",
    "district_id": "827206",
    "label": "Akedotilou",
    "value": "Akedotilou"
  },
  {
    "id": "8272062006",
    "district_id": "827206",
    "label": "Akeguraci",
    "value": "Akeguraci"
  },
  {
    "id": "8272062007",
    "district_id": "827206",
    "label": "Akesai",
    "value": "Akesai"
  },
  {
    "id": "8272062008",
    "district_id": "827206",
    "label": "Togeme",
    "value": "Togeme"
  },
  {
    "id": "8272062009",
    "district_id": "827206",
    "label": "Tadupi",
    "value": "Tadupi"
  },
  {
    "id": "8272062010",
    "district_id": "827206",
    "label": "Baringin Jaya",
    "value": "Baringin Jaya"
  },
  {
    "id": "8272062011",
    "district_id": "827206",
    "label": "Tauno",
    "value": "Tauno"
  },
  {
    "id": "8272062012",
    "district_id": "827206",
    "label": "Fanaha",
    "value": "Fanaha"
  },
  {
    "id": "8272062013",
    "district_id": "827206",
    "label": "Yehu",
    "value": "Yehu"
  },
  {
    "id": "8272062014",
    "district_id": "827206",
    "label": "Siokona",
    "value": "Siokona"
  },
  {
    "id": "8272072001",
    "district_id": "827207",
    "label": "Maidi",
    "value": "Maidi"
  },
  {
    "id": "8272072002",
    "district_id": "827207",
    "label": "Lifofa",
    "value": "Lifofa"
  },
  {
    "id": "8272072003",
    "district_id": "827207",
    "label": "Hager",
    "value": "Hager"
  },
  {
    "id": "8272072004",
    "district_id": "827207",
    "label": "Wama",
    "value": "Wama"
  },
  {
    "id": "8272072005",
    "district_id": "827207",
    "label": "Selamalofo",
    "value": "Selamalofo"
  },
  {
    "id": "8272072006",
    "district_id": "827207",
    "label": "Tagalaya",
    "value": "Tagalaya"
  },
  {
    "id": "8272072007",
    "district_id": "827207",
    "label": "Nuku",
    "value": "Nuku"
  },
  {
    "id": "8272081001",
    "district_id": "827208",
    "label": "Mafututu",
    "value": "Mafututu"
  },
  {
    "id": "8272081002",
    "district_id": "827208",
    "label": "Tosa",
    "value": "Tosa"
  },
  {
    "id": "8272081003",
    "district_id": "827208",
    "label": "Dowora",
    "value": "Dowora"
  },
  {
    "id": "8272081004",
    "district_id": "827208",
    "label": "Kalaodi",
    "value": "Kalaodi"
  },
  {
    "id": "8272081005",
    "district_id": "827208",
    "label": "Cobodoe",
    "value": "Cobodoe"
  },
  {
    "id": "8272081006",
    "district_id": "827208",
    "label": "Doyado",
    "value": "Doyado"
  },
  {
    "id": "8272081007",
    "district_id": "827208",
    "label": "Jiko Cobo",
    "value": "Jiko Cobo"
  },
  {
    "id": "9101011002",
    "district_id": "910101",
    "label": "Samkai",
    "value": "Samkai"
  },
  {
    "id": "9101011003",
    "district_id": "910101",
    "label": "Karang Indah",
    "value": "Karang Indah"
  },
  {
    "id": "9101011004",
    "district_id": "910101",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "9101011005",
    "district_id": "910101",
    "label": "Maro",
    "value": "Maro"
  },
  {
    "id": "9101011006",
    "district_id": "910101",
    "label": "Kelapa Lima",
    "value": "Kelapa Lima"
  },
  {
    "id": "9101011007",
    "district_id": "910101",
    "label": "Rimba Jaya",
    "value": "Rimba Jaya"
  },
  {
    "id": "9101011014",
    "district_id": "910101",
    "label": "Bambu Pemali",
    "value": "Bambu Pemali"
  },
  {
    "id": "9101011015",
    "district_id": "910101",
    "label": "Seringgu Jaya",
    "value": "Seringgu Jaya"
  },
  {
    "id": "9101011016",
    "district_id": "910101",
    "label": "Kamundu",
    "value": "Kamundu"
  },
  {
    "id": "9101011017",
    "district_id": "910101",
    "label": "Kamahedoga",
    "value": "Kamahedoga"
  },
  {
    "id": "9101011018",
    "district_id": "910101",
    "label": "Muli",
    "value": "Muli"
  },
  {
    "id": "9101012001",
    "district_id": "910101",
    "label": "Nasem",
    "value": "Nasem"
  },
  {
    "id": "9101012013",
    "district_id": "910101",
    "label": "Wasur",
    "value": "Wasur"
  },
  {
    "id": "9101012019",
    "district_id": "910101",
    "label": "Bokem",
    "value": "Bokem"
  },
  {
    "id": "9101012020",
    "district_id": "910101",
    "label": "Buti",
    "value": "Buti"
  },
  {
    "id": "9101012021",
    "district_id": "910101",
    "label": "Nggolar",
    "value": "Nggolar"
  },
  {
    "id": "9101022001",
    "district_id": "910102",
    "label": "Muting",
    "value": "Muting"
  },
  {
    "id": "9101022003",
    "district_id": "910102",
    "label": "Boha",
    "value": "Boha"
  },
  {
    "id": "9101022005",
    "district_id": "910102",
    "label": "Wan",
    "value": "Wan"
  },
  {
    "id": "9101022006",
    "district_id": "910102",
    "label": "Kolam",
    "value": "Kolam"
  },
  {
    "id": "9101022007",
    "district_id": "910102",
    "label": "Selauw",
    "value": "Selauw"
  },
  {
    "id": "9101022008",
    "district_id": "910102",
    "label": "Pachas",
    "value": "Pachas"
  },
  {
    "id": "9101022010",
    "district_id": "910102",
    "label": "Seed Agung",
    "value": "Seed Agung"
  },
  {
    "id": "9101022011",
    "district_id": "910102",
    "label": "Enggol Jaya",
    "value": "Enggol Jaya"
  },
  {
    "id": "9101022012",
    "district_id": "910102",
    "label": "Manway Bop",
    "value": "Manway Bop"
  },
  {
    "id": "9101022013",
    "district_id": "910102",
    "label": "Efkab Makmur",
    "value": "Efkab Makmur"
  },
  {
    "id": "9101022014",
    "district_id": "910102",
    "label": "Sigabel Jaya",
    "value": "Sigabel Jaya"
  },
  {
    "id": "9101022015",
    "district_id": "910102",
    "label": "Andaito",
    "value": "Andaito"
  },
  {
    "id": "9101032001",
    "district_id": "910103",
    "label": "Okaba",
    "value": "Okaba"
  },
  {
    "id": "9101032005",
    "district_id": "910103",
    "label": "Wambi",
    "value": "Wambi"
  },
  {
    "id": "9101032006",
    "district_id": "910103",
    "label": "Makaling",
    "value": "Makaling"
  },
  {
    "id": "9101032007",
    "district_id": "910103",
    "label": "Alatep",
    "value": "Alatep"
  },
  {
    "id": "9101032018",
    "district_id": "910103",
    "label": "Iwol",
    "value": "Iwol"
  },
  {
    "id": "9101032019",
    "district_id": "910103",
    "label": "Dufmiraf",
    "value": "Dufmiraf"
  },
  {
    "id": "9101032020",
    "district_id": "910103",
    "label": "Alaku",
    "value": "Alaku"
  },
  {
    "id": "9101032021",
    "district_id": "910103",
    "label": "Sanggase",
    "value": "Sanggase"
  },
  {
    "id": "9101032024",
    "district_id": "910103",
    "label": "Es Wambi",
    "value": "Es Wambi"
  },
  {
    "id": "9101042001",
    "district_id": "910104",
    "label": "Kimaam",
    "value": "Kimaam"
  },
  {
    "id": "9101042002",
    "district_id": "910104",
    "label": "Komolom",
    "value": "Komolom"
  },
  {
    "id": "9101042003",
    "district_id": "910104",
    "label": "Kumbis",
    "value": "Kumbis"
  },
  {
    "id": "9101042004",
    "district_id": "910104",
    "label": "Kalilam",
    "value": "Kalilam"
  },
  {
    "id": "9101042005",
    "district_id": "910104",
    "label": "Teri",
    "value": "Teri"
  },
  {
    "id": "9101042018",
    "district_id": "910104",
    "label": "Mambum",
    "value": "Mambum"
  },
  {
    "id": "9101042019",
    "district_id": "910104",
    "label": "Kiworo",
    "value": "Kiworo"
  },
  {
    "id": "9101042020",
    "district_id": "910104",
    "label": "Woner",
    "value": "Woner"
  },
  {
    "id": "9101042021",
    "district_id": "910104",
    "label": "Deka",
    "value": "Deka"
  },
  {
    "id": "9101042022",
    "district_id": "910104",
    "label": "Turiram",
    "value": "Turiram"
  },
  {
    "id": "9101042023",
    "district_id": "910104",
    "label": "Sabudom",
    "value": "Sabudom"
  },
  {
    "id": "9101042033",
    "district_id": "910104",
    "label": "Purawanderu",
    "value": "Purawanderu"
  },
  {
    "id": "9101042034",
    "district_id": "910104",
    "label": "Umanderu",
    "value": "Umanderu"
  },
  {
    "id": "9101042035",
    "district_id": "910104",
    "label": "Webu",
    "value": "Webu"
  },
  {
    "id": "9101052001",
    "district_id": "910105",
    "label": "Matara",
    "value": "Matara"
  },
  {
    "id": "9101052002",
    "district_id": "910105",
    "label": "Waninggap Nanggo",
    "value": "Waninggap Nanggo"
  },
  {
    "id": "9101052003",
    "district_id": "910105",
    "label": "Urumb",
    "value": "Urumb"
  },
  {
    "id": "9101052004",
    "district_id": "910105",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "9101052005",
    "district_id": "910105",
    "label": "Kuprik",
    "value": "Kuprik"
  },
  {
    "id": "9101052006",
    "district_id": "910105",
    "label": "Kuper",
    "value": "Kuper"
  },
  {
    "id": "9101052007",
    "district_id": "910105",
    "label": "Semangga Jaya",
    "value": "Semangga Jaya"
  },
  {
    "id": "9101052008",
    "district_id": "910105",
    "label": "Marga Mulya",
    "value": "Marga Mulya"
  },
  {
    "id": "9101052009",
    "district_id": "910105",
    "label": "Muram Sari",
    "value": "Muram Sari"
  },
  {
    "id": "9101052010",
    "district_id": "910105",
    "label": "Waninggap Kai",
    "value": "Waninggap Kai"
  },
  {
    "id": "9101062001",
    "district_id": "910106",
    "label": "Yasa Mulya",
    "value": "Yasa Mulya"
  },
  {
    "id": "9101062002",
    "district_id": "910106",
    "label": "Sumber Harapan",
    "value": "Sumber Harapan"
  },
  {
    "id": "9101062003",
    "district_id": "910106",
    "label": "Waninggap Sai",
    "value": "Waninggap Sai"
  },
  {
    "id": "9101062004",
    "district_id": "910106",
    "label": "Amunkay",
    "value": "Amunkay"
  },
  {
    "id": "9101062005",
    "district_id": "910106",
    "label": "Hidup Baru",
    "value": "Hidup Baru"
  },
  {
    "id": "9101062006",
    "district_id": "910106",
    "label": "Sarmayam Indah",
    "value": "Sarmayam Indah"
  },
  {
    "id": "9101062007",
    "district_id": "910106",
    "label": "Ngguti Bob",
    "value": "Ngguti Bob"
  },
  {
    "id": "9101062008",
    "district_id": "910106",
    "label": "Waninggap Miraf",
    "value": "Waninggap Miraf"
  },
  {
    "id": "9101062009",
    "district_id": "910106",
    "label": "Isano Mbias",
    "value": "Isano Mbias"
  },
  {
    "id": "9101062010",
    "district_id": "910106",
    "label": "Yaba Maru",
    "value": "Yaba Maru"
  },
  {
    "id": "9101062011",
    "district_id": "910106",
    "label": "Soa",
    "value": "Soa"
  },
  {
    "id": "9101062012",
    "district_id": "910106",
    "label": "Tambat",
    "value": "Tambat"
  },
  {
    "id": "9101062013",
    "district_id": "910106",
    "label": "Bersehati",
    "value": "Bersehati"
  },
  {
    "id": "9101062014",
    "district_id": "910106",
    "label": "Kamangi",
    "value": "Kamangi"
  },
  {
    "id": "9101072001",
    "district_id": "910107",
    "label": "Kartini",
    "value": "Kartini"
  },
  {
    "id": "9101072002",
    "district_id": "910107",
    "label": "Makarti Jaya",
    "value": "Makarti Jaya"
  },
  {
    "id": "9101072003",
    "district_id": "910107",
    "label": "Nalkin",
    "value": "Nalkin"
  },
  {
    "id": "9101072004",
    "district_id": "910107",
    "label": "Angger Permegi",
    "value": "Angger Permegi"
  },
  {
    "id": "9101072005",
    "district_id": "910107",
    "label": "Poo",
    "value": "Poo"
  },
  {
    "id": "9101072006",
    "district_id": "910107",
    "label": "Mimi Baru",
    "value": "Mimi Baru"
  },
  {
    "id": "9101072007",
    "district_id": "910107",
    "label": "Kamno Sari",
    "value": "Kamno Sari"
  },
  {
    "id": "9101072008",
    "district_id": "910107",
    "label": "Blandin Kakayo",
    "value": "Blandin Kakayo"
  },
  {
    "id": "9101072009",
    "district_id": "910107",
    "label": "Gurinda Jaya",
    "value": "Gurinda Jaya"
  },
  {
    "id": "9101072010",
    "district_id": "910107",
    "label": "Jagebob Raya",
    "value": "Jagebob Raya"
  },
  {
    "id": "9101072011",
    "district_id": "910107",
    "label": "Wenda Asri",
    "value": "Wenda Asri"
  },
  {
    "id": "9101072012",
    "district_id": "910107",
    "label": "Melin Megikar",
    "value": "Melin Megikar"
  },
  {
    "id": "9101072013",
    "district_id": "910107",
    "label": "Obaat Trow",
    "value": "Obaat Trow"
  },
  {
    "id": "9101072014",
    "district_id": "910107",
    "label": "Jemunain Jaya",
    "value": "Jemunain Jaya"
  },
  {
    "id": "9101082001",
    "district_id": "910108",
    "label": "Sota",
    "value": "Sota"
  },
  {
    "id": "9101082002",
    "district_id": "910108",
    "label": "Yanggandur",
    "value": "Yanggandur"
  },
  {
    "id": "9101082003",
    "district_id": "910108",
    "label": "Rawa Biru",
    "value": "Rawa Biru"
  },
  {
    "id": "9101082004",
    "district_id": "910108",
    "label": "Toray",
    "value": "Toray"
  },
  {
    "id": "9101082005",
    "district_id": "910108",
    "label": "Erambu",
    "value": "Erambu"
  },
  {
    "id": "9101092001",
    "district_id": "910109",
    "label": "Selil",
    "value": "Selil"
  },
  {
    "id": "9101092002",
    "district_id": "910109",
    "label": "Kindiki",
    "value": "Kindiki"
  },
  {
    "id": "9101092003",
    "district_id": "910109",
    "label": "Kumaaf",
    "value": "Kumaaf"
  },
  {
    "id": "9101092004",
    "district_id": "910109",
    "label": "Nggayu",
    "value": "Nggayu"
  },
  {
    "id": "9101092005",
    "district_id": "910109",
    "label": "Kafyamke",
    "value": "Kafyamke"
  },
  {
    "id": "9101092006",
    "district_id": "910109",
    "label": "Mandekman",
    "value": "Mandekman"
  },
  {
    "id": "9101092007",
    "district_id": "910109",
    "label": "Rawa Hayu",
    "value": "Rawa Hayu"
  },
  {
    "id": "9101092008",
    "district_id": "910109",
    "label": "Belbeland",
    "value": "Belbeland"
  },
  {
    "id": "9101092009",
    "district_id": "910109",
    "label": "Kireli",
    "value": "Kireli"
  },
  {
    "id": "9101092010",
    "district_id": "910109",
    "label": "Kandrakai",
    "value": "Kandrakai"
  },
  {
    "id": "9101092011",
    "district_id": "910109",
    "label": "Baidub",
    "value": "Baidub"
  },
  {
    "id": "9101102001",
    "district_id": "910110",
    "label": "Bupul",
    "value": "Bupul"
  },
  {
    "id": "9101102002",
    "district_id": "910110",
    "label": "Tanas",
    "value": "Tanas"
  },
  {
    "id": "9101102003",
    "district_id": "910110",
    "label": "Kweel",
    "value": "Kweel"
  },
  {
    "id": "9101102004",
    "district_id": "910110",
    "label": "Sipias",
    "value": "Sipias"
  },
  {
    "id": "9101102005",
    "district_id": "910110",
    "label": "Metaat Makmur",
    "value": "Metaat Makmur"
  },
  {
    "id": "9101102006",
    "district_id": "910110",
    "label": "Enggal Jaya",
    "value": "Enggal Jaya"
  },
  {
    "id": "9101102007",
    "district_id": "910110",
    "label": "Gerisar",
    "value": "Gerisar"
  },
  {
    "id": "9101102008",
    "district_id": "910110",
    "label": "Bupul Indah",
    "value": "Bupul Indah"
  },
  {
    "id": "9101102009",
    "district_id": "910110",
    "label": "Tof-Tof",
    "value": "Tof-Tof"
  },
  {
    "id": "9101102010",
    "district_id": "910110",
    "label": "Bunggay",
    "value": "Bunggay"
  },
  {
    "id": "9101102011",
    "district_id": "910110",
    "label": "Bouwer",
    "value": "Bouwer"
  },
  {
    "id": "9101102012",
    "district_id": "910110",
    "label": "Bumun",
    "value": "Bumun"
  },
  {
    "id": "9101112001",
    "district_id": "910111",
    "label": "Harapan Makmur",
    "value": "Harapan Makmur"
  },
  {
    "id": "9101112006",
    "district_id": "910111",
    "label": "Kurik",
    "value": "Kurik"
  },
  {
    "id": "9101112007",
    "district_id": "910111",
    "label": "Telaga Sari",
    "value": "Telaga Sari"
  },
  {
    "id": "9101112008",
    "district_id": "910111",
    "label": "Sumber Rejeki",
    "value": "Sumber Rejeki"
  },
  {
    "id": "9101112009",
    "district_id": "910111",
    "label": "Jaya Makmur",
    "value": "Jaya Makmur"
  },
  {
    "id": "9101112011",
    "district_id": "910111",
    "label": "Sumber Mulya",
    "value": "Sumber Mulya"
  },
  {
    "id": "9101112012",
    "district_id": "910111",
    "label": "Kaliki",
    "value": "Kaliki"
  },
  {
    "id": "9101112013",
    "district_id": "910111",
    "label": "Ivimahad",
    "value": "Ivimahad"
  },
  {
    "id": "9101112019",
    "district_id": "910111",
    "label": "Salor Indah",
    "value": "Salor Indah"
  },
  {
    "id": "9101112021",
    "district_id": "910111",
    "label": "Candra Jaya",
    "value": "Candra Jaya"
  },
  {
    "id": "9101112022",
    "district_id": "910111",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "9101112023",
    "district_id": "910111",
    "label": "Anumbob",
    "value": "Anumbob"
  },
  {
    "id": "9101112024",
    "district_id": "910111",
    "label": "Wapeko",
    "value": "Wapeko"
  },
  {
    "id": "9101122001",
    "district_id": "910112",
    "label": "Kuler",
    "value": "Kuler"
  },
  {
    "id": "9101122002",
    "district_id": "910112",
    "label": "Onggaya",
    "value": "Onggaya"
  },
  {
    "id": "9101122003",
    "district_id": "910112",
    "label": "Tomer",
    "value": "Tomer"
  },
  {
    "id": "9101122004",
    "district_id": "910112",
    "label": "Tomerau",
    "value": "Tomerau"
  },
  {
    "id": "9101122005",
    "district_id": "910112",
    "label": "Kondo",
    "value": "Kondo"
  },
  {
    "id": "9101132001",
    "district_id": "910113",
    "label": "Koa",
    "value": "Koa"
  },
  {
    "id": "9101132002",
    "district_id": "910113",
    "label": "Baad",
    "value": "Baad"
  },
  {
    "id": "9101132003",
    "district_id": "910113",
    "label": "Senegi",
    "value": "Senegi"
  },
  {
    "id": "9101132004",
    "district_id": "910113",
    "label": "Wayau",
    "value": "Wayau"
  },
  {
    "id": "9101132005",
    "district_id": "910113",
    "label": "Kaisah",
    "value": "Kaisah"
  },
  {
    "id": "9101142001",
    "district_id": "910114",
    "label": "Kumbe",
    "value": "Kumbe"
  },
  {
    "id": "9101142002",
    "district_id": "910114",
    "label": "Onggari",
    "value": "Onggari"
  },
  {
    "id": "9101142003",
    "district_id": "910114",
    "label": "Rawasari",
    "value": "Rawasari"
  },
  {
    "id": "9101142004",
    "district_id": "910114",
    "label": "Padang Raharja",
    "value": "Padang Raharja"
  },
  {
    "id": "9101142005",
    "district_id": "910114",
    "label": "Kaiburse",
    "value": "Kaiburse"
  },
  {
    "id": "9101142006",
    "district_id": "910114",
    "label": "Domande",
    "value": "Domande"
  },
  {
    "id": "9101142007",
    "district_id": "910114",
    "label": "Suka Maju",
    "value": "Suka Maju"
  },
  {
    "id": "9101152001",
    "district_id": "910115",
    "label": "Yowied",
    "value": "Yowied"
  },
  {
    "id": "9101152002",
    "district_id": "910115",
    "label": "Dokib",
    "value": "Dokib"
  },
  {
    "id": "9101152003",
    "district_id": "910115",
    "label": "Wamal",
    "value": "Wamal"
  },
  {
    "id": "9101152004",
    "district_id": "910115",
    "label": "Dodalim",
    "value": "Dodalim"
  },
  {
    "id": "9101152005",
    "district_id": "910115",
    "label": "Woboyo",
    "value": "Woboyo"
  },
  {
    "id": "9101152006",
    "district_id": "910115",
    "label": "Welbuti",
    "value": "Welbuti"
  },
  {
    "id": "9101162001",
    "district_id": "910116",
    "label": "Po Epe",
    "value": "Po Epe"
  },
  {
    "id": "9101162002",
    "district_id": "910116",
    "label": "Yawimu",
    "value": "Yawimu"
  },
  {
    "id": "9101162003",
    "district_id": "910116",
    "label": "Taga Epe",
    "value": "Taga Epe"
  },
  {
    "id": "9101162004",
    "district_id": "910116",
    "label": "Nakias",
    "value": "Nakias"
  },
  {
    "id": "9101162005",
    "district_id": "910116",
    "label": "Salam Epe",
    "value": "Salam Epe"
  },
  {
    "id": "9101162006",
    "district_id": "910116",
    "label": "Yomop",
    "value": "Yomop"
  },
  {
    "id": "9101172001",
    "district_id": "910117",
    "label": "Kwemsid",
    "value": "Kwemsid"
  },
  {
    "id": "9101172002",
    "district_id": "910117",
    "label": "Kaptel",
    "value": "Kaptel"
  },
  {
    "id": "9101172003",
    "district_id": "910117",
    "label": "Kaniskobat",
    "value": "Kaniskobat"
  },
  {
    "id": "9101172004",
    "district_id": "910117",
    "label": "Ihalik",
    "value": "Ihalik"
  },
  {
    "id": "9101172005",
    "district_id": "910117",
    "label": "Bu-Epe",
    "value": "Bu-Epe"
  },
  {
    "id": "9101182001",
    "district_id": "910118",
    "label": "Tabonji",
    "value": "Tabonji"
  },
  {
    "id": "9101182002",
    "district_id": "910118",
    "label": "Wanggambi",
    "value": "Wanggambi"
  },
  {
    "id": "9101182003",
    "district_id": "910118",
    "label": "Bamol I",
    "value": "Bamol I"
  },
  {
    "id": "9101182004",
    "district_id": "910118",
    "label": "Suam",
    "value": "Suam"
  },
  {
    "id": "9101182005",
    "district_id": "910118",
    "label": "Yamuka",
    "value": "Yamuka"
  },
  {
    "id": "9101182006",
    "district_id": "910118",
    "label": "Iromoro",
    "value": "Iromoro"
  },
  {
    "id": "9101182007",
    "district_id": "910118",
    "label": "Konjombando",
    "value": "Konjombando"
  },
  {
    "id": "9101182008",
    "district_id": "910118",
    "label": "Yeraha",
    "value": "Yeraha"
  },
  {
    "id": "9101182009",
    "district_id": "910118",
    "label": "Bamol II",
    "value": "Bamol II"
  },
  {
    "id": "9101192001",
    "district_id": "910119",
    "label": "Konorau",
    "value": "Konorau"
  },
  {
    "id": "9101192002",
    "district_id": "910119",
    "label": "Waan",
    "value": "Waan"
  },
  {
    "id": "9101192003",
    "district_id": "910119",
    "label": "Tor",
    "value": "Tor"
  },
  {
    "id": "9101192004",
    "district_id": "910119",
    "label": "Kladar",
    "value": "Kladar"
  },
  {
    "id": "9101192005",
    "district_id": "910119",
    "label": "Sabon",
    "value": "Sabon"
  },
  {
    "id": "9101192006",
    "district_id": "910119",
    "label": "Sibenda",
    "value": "Sibenda"
  },
  {
    "id": "9101192007",
    "district_id": "910119",
    "label": "Wetau",
    "value": "Wetau"
  },
  {
    "id": "9101192008",
    "district_id": "910119",
    "label": "Kawe",
    "value": "Kawe"
  },
  {
    "id": "9101192009",
    "district_id": "910119",
    "label": "Pembri",
    "value": "Pembri"
  },
  {
    "id": "9101192010",
    "district_id": "910119",
    "label": "Wantarma",
    "value": "Wantarma"
  },
  {
    "id": "9101192011",
    "district_id": "910119",
    "label": "Dafnawanga",
    "value": "Dafnawanga"
  },
  {
    "id": "9101202001",
    "district_id": "910120",
    "label": "Wanam",
    "value": "Wanam"
  },
  {
    "id": "9101202002",
    "district_id": "910120",
    "label": "Padua",
    "value": "Padua"
  },
  {
    "id": "9101202003",
    "district_id": "910120",
    "label": "Bibikem",
    "value": "Bibikem"
  },
  {
    "id": "9101202004",
    "district_id": "910120",
    "label": "Wogikel",
    "value": "Wogikel"
  },
  {
    "id": "9101202005",
    "district_id": "910120",
    "label": "Uli - Uli",
    "value": "Uli - Uli"
  },
  {
    "id": "9101202006",
    "district_id": "910120",
    "label": "Sigad",
    "value": "Sigad"
  },
  {
    "id": "9102011001",
    "district_id": "910201",
    "label": "Wamena Kota",
    "value": "Wamena Kota"
  },
  {
    "id": "9102011056",
    "district_id": "910201",
    "label": "Sinapuk",
    "value": "Sinapuk"
  },
  {
    "id": "9102011057",
    "district_id": "910201",
    "label": "Sinakma",
    "value": "Sinakma"
  },
  {
    "id": "9102012015",
    "district_id": "910201",
    "label": "Honelama",
    "value": "Honelama"
  },
  {
    "id": "9102012025",
    "district_id": "910201",
    "label": "Hulekama",
    "value": "Hulekama"
  },
  {
    "id": "9102012034",
    "district_id": "910201",
    "label": "Batu Merah",
    "value": "Batu Merah"
  },
  {
    "id": "9102012037",
    "district_id": "910201",
    "label": "Autakma",
    "value": "Autakma"
  },
  {
    "id": "9102012058",
    "district_id": "910201",
    "label": "Honelama Dua",
    "value": "Honelama Dua"
  },
  {
    "id": "9102012059",
    "district_id": "910201",
    "label": "Honaima",
    "value": "Honaima"
  },
  {
    "id": "9102012060",
    "district_id": "910201",
    "label": "Lanitipo",
    "value": "Lanitipo"
  },
  {
    "id": "9102012061",
    "district_id": "910201",
    "label": "Wamaroma",
    "value": "Wamaroma"
  },
  {
    "id": "9102032001",
    "district_id": "910203",
    "label": "Jiwika",
    "value": "Jiwika"
  },
  {
    "id": "9102032006",
    "district_id": "910203",
    "label": "Mulima",
    "value": "Mulima"
  },
  {
    "id": "9102032007",
    "district_id": "910203",
    "label": "Waga-Waga",
    "value": "Waga-Waga"
  },
  {
    "id": "9102032009",
    "district_id": "910203",
    "label": "Abusa",
    "value": "Abusa"
  },
  {
    "id": "9102032011",
    "district_id": "910203",
    "label": "Eragama",
    "value": "Eragama"
  },
  {
    "id": "9102032017",
    "district_id": "910203",
    "label": "Kimima",
    "value": "Kimima"
  },
  {
    "id": "9102032018",
    "district_id": "910203",
    "label": "Mebagaima",
    "value": "Mebagaima"
  },
  {
    "id": "9102032019",
    "district_id": "910203",
    "label": "Iyantik",
    "value": "Iyantik"
  },
  {
    "id": "9102032020",
    "district_id": "910203",
    "label": "Umpagalo",
    "value": "Umpagalo"
  },
  {
    "id": "9102032021",
    "district_id": "910203",
    "label": "Hopama",
    "value": "Hopama"
  },
  {
    "id": "9102032033",
    "district_id": "910203",
    "label": "Obya",
    "value": "Obya"
  },
  {
    "id": "9102032043",
    "district_id": "910203",
    "label": "Utkolo Satu",
    "value": "Utkolo Satu"
  },
  {
    "id": "9102042006",
    "district_id": "910204",
    "label": "Kimbin",
    "value": "Kimbin"
  },
  {
    "id": "9102042008",
    "district_id": "910204",
    "label": "Walak",
    "value": "Walak"
  },
  {
    "id": "9102042015",
    "district_id": "910204",
    "label": "Araboda",
    "value": "Araboda"
  },
  {
    "id": "9102042017",
    "district_id": "910204",
    "label": "Logotpaga",
    "value": "Logotpaga"
  },
  {
    "id": "9102042025",
    "district_id": "910204",
    "label": "Wawanca",
    "value": "Wawanca"
  },
  {
    "id": "9102042026",
    "district_id": "910204",
    "label": "Miligatnem",
    "value": "Miligatnem"
  },
  {
    "id": "9102042029",
    "district_id": "910204",
    "label": "Loki",
    "value": "Loki"
  },
  {
    "id": "9102042034",
    "district_id": "910204",
    "label": "Tikawo",
    "value": "Tikawo"
  },
  {
    "id": "9102042044",
    "district_id": "910204",
    "label": "Kombagwe",
    "value": "Kombagwe"
  },
  {
    "id": "9102042045",
    "district_id": "910204",
    "label": "Wanggonoma",
    "value": "Wanggonoma"
  },
  {
    "id": "9102122001",
    "district_id": "910212",
    "label": "Hubikosi",
    "value": "Hubikosi"
  },
  {
    "id": "9102122007",
    "district_id": "910212",
    "label": "Kosimeage",
    "value": "Kosimeage"
  },
  {
    "id": "9102122008",
    "district_id": "910212",
    "label": "Kosihilapok",
    "value": "Kosihilapok"
  },
  {
    "id": "9102122015",
    "district_id": "910212",
    "label": "Meagama",
    "value": "Meagama"
  },
  {
    "id": "9102122018",
    "district_id": "910212",
    "label": "Pipukmo",
    "value": "Pipukmo"
  },
  {
    "id": "9102122019",
    "district_id": "910212",
    "label": "Isakusa",
    "value": "Isakusa"
  },
  {
    "id": "9102122020",
    "district_id": "910212",
    "label": "Pelima",
    "value": "Pelima"
  },
  {
    "id": "9102122021",
    "district_id": "910212",
    "label": "Kikhumo",
    "value": "Kikhumo"
  },
  {
    "id": "9102122028",
    "district_id": "910212",
    "label": "Jibilabaga",
    "value": "Jibilabaga"
  },
  {
    "id": "9102122029",
    "district_id": "910212",
    "label": "Ikilumo",
    "value": "Ikilumo"
  },
  {
    "id": "9102122030",
    "district_id": "910212",
    "label": "Sunili",
    "value": "Sunili"
  },
  {
    "id": "9102152001",
    "district_id": "910215",
    "label": "Bolakme",
    "value": "Bolakme"
  },
  {
    "id": "9102152003",
    "district_id": "910215",
    "label": "Munak",
    "value": "Munak"
  },
  {
    "id": "9102152014",
    "district_id": "910215",
    "label": "Tenonggame",
    "value": "Tenonggame"
  },
  {
    "id": "9102152016",
    "district_id": "910215",
    "label": "Bandua",
    "value": "Bandua"
  },
  {
    "id": "9102152019",
    "district_id": "910215",
    "label": "Lani Timur",
    "value": "Lani Timur"
  },
  {
    "id": "9102152035",
    "district_id": "910215",
    "label": "Nunggarugum",
    "value": "Nunggarugum"
  },
  {
    "id": "9102152036",
    "district_id": "910215",
    "label": "Poitmos",
    "value": "Poitmos"
  },
  {
    "id": "9102152037",
    "district_id": "910215",
    "label": "Kugitero",
    "value": "Kugitero"
  },
  {
    "id": "9102152038",
    "district_id": "910215",
    "label": "Wenamela",
    "value": "Wenamela"
  },
  {
    "id": "9102152039",
    "district_id": "910215",
    "label": "Bimu",
    "value": "Bimu"
  },
  {
    "id": "9102152040",
    "district_id": "910215",
    "label": "Tekani",
    "value": "Tekani"
  },
  {
    "id": "9102152056",
    "district_id": "910215",
    "label": "Owagambak",
    "value": "Owagambak"
  },
  {
    "id": "9102252001",
    "district_id": "910225",
    "label": "Walelagama",
    "value": "Walelagama"
  },
  {
    "id": "9102252002",
    "district_id": "910225",
    "label": "Pugima",
    "value": "Pugima"
  },
  {
    "id": "9102252010",
    "district_id": "910225",
    "label": "Itlay Halitopo",
    "value": "Itlay Halitopo"
  },
  {
    "id": "9102252011",
    "district_id": "910225",
    "label": "Kubulakma",
    "value": "Kubulakma"
  },
  {
    "id": "9102252012",
    "district_id": "910225",
    "label": "Kulaken",
    "value": "Kulaken"
  },
  {
    "id": "9102252013",
    "district_id": "910225",
    "label": "Wamusage",
    "value": "Wamusage"
  },
  {
    "id": "9102272001",
    "district_id": "910227",
    "label": "Temia",
    "value": "Temia"
  },
  {
    "id": "9102272002",
    "district_id": "910227",
    "label": "Anegera",
    "value": "Anegera"
  },
  {
    "id": "9102272003",
    "district_id": "910227",
    "label": "Elabukama",
    "value": "Elabukama"
  },
  {
    "id": "9102272004",
    "district_id": "910227",
    "label": "Siapma",
    "value": "Siapma"
  },
  {
    "id": "9102272005",
    "district_id": "910227",
    "label": "Hamuhi",
    "value": "Hamuhi"
  },
  {
    "id": "9102272006",
    "district_id": "910227",
    "label": "Kosihave",
    "value": "Kosihave"
  },
  {
    "id": "9102272007",
    "district_id": "910227",
    "label": "Mulupalek",
    "value": "Mulupalek"
  },
  {
    "id": "9102272008",
    "district_id": "910227",
    "label": "Pumasili",
    "value": "Pumasili"
  },
  {
    "id": "9102272009",
    "district_id": "910227",
    "label": "Abulukmo",
    "value": "Abulukmo"
  },
  {
    "id": "9102272010",
    "district_id": "910227",
    "label": "Yumugima",
    "value": "Yumugima"
  },
  {
    "id": "9102282001",
    "district_id": "910228",
    "label": "Wolo",
    "value": "Wolo"
  },
  {
    "id": "9102282008",
    "district_id": "910228",
    "label": "Kukurima",
    "value": "Kukurima"
  },
  {
    "id": "9102282011",
    "district_id": "910228",
    "label": "Pirambot",
    "value": "Pirambot"
  },
  {
    "id": "9102282015",
    "district_id": "910228",
    "label": "Tegabaga",
    "value": "Tegabaga"
  },
  {
    "id": "9102282016",
    "district_id": "910228",
    "label": "Wollo Timur",
    "value": "Wollo Timur"
  },
  {
    "id": "9102282017",
    "district_id": "910228",
    "label": "Alugi",
    "value": "Alugi"
  },
  {
    "id": "9102282027",
    "district_id": "910228",
    "label": "Wunan",
    "value": "Wunan"
  },
  {
    "id": "9102282028",
    "district_id": "910228",
    "label": "Wodloma",
    "value": "Wodloma"
  },
  {
    "id": "9102292005",
    "district_id": "910229",
    "label": "Sinata",
    "value": "Sinata"
  },
  {
    "id": "9102292006",
    "district_id": "910229",
    "label": "Asolokobal",
    "value": "Asolokobal"
  },
  {
    "id": "9102292011",
    "district_id": "910229",
    "label": "Hesatom",
    "value": "Hesatom"
  },
  {
    "id": "9102292012",
    "district_id": "910229",
    "label": "Asotapo",
    "value": "Asotapo"
  },
  {
    "id": "9102292013",
    "district_id": "910229",
    "label": "Wiaima",
    "value": "Wiaima"
  },
  {
    "id": "9102292014",
    "district_id": "910229",
    "label": "Ninabua",
    "value": "Ninabua"
  },
  {
    "id": "9102292015",
    "district_id": "910229",
    "label": "Helaluwa",
    "value": "Helaluwa"
  },
  {
    "id": "9102292016",
    "district_id": "910229",
    "label": "Mulinekama",
    "value": "Mulinekama"
  },
  {
    "id": "9102292037",
    "district_id": "910229",
    "label": "Yapema",
    "value": "Yapema"
  },
  {
    "id": "9102342003",
    "district_id": "910234",
    "label": "Heatnem",
    "value": "Heatnem"
  },
  {
    "id": "9102342008",
    "district_id": "910234",
    "label": "Wililimo",
    "value": "Wililimo"
  },
  {
    "id": "9102342009",
    "district_id": "910234",
    "label": "Yabem",
    "value": "Yabem"
  },
  {
    "id": "9102342012",
    "district_id": "910234",
    "label": "Waukahilapok",
    "value": "Waukahilapok"
  },
  {
    "id": "9102342013",
    "district_id": "910234",
    "label": "Aleak",
    "value": "Aleak"
  },
  {
    "id": "9102342014",
    "district_id": "910234",
    "label": "Inanekelok",
    "value": "Inanekelok"
  },
  {
    "id": "9102342015",
    "district_id": "910234",
    "label": "Witalak",
    "value": "Witalak"
  },
  {
    "id": "9102342016",
    "district_id": "910234",
    "label": "Mulukmo",
    "value": "Mulukmo"
  },
  {
    "id": "9102342017",
    "district_id": "910234",
    "label": "Landia",
    "value": "Landia"
  },
  {
    "id": "9102342018",
    "district_id": "910234",
    "label": "Filia",
    "value": "Filia"
  },
  {
    "id": "9102342019",
    "district_id": "910234",
    "label": "Isugunik",
    "value": "Isugunik"
  },
  {
    "id": "9102342027",
    "district_id": "910234",
    "label": "Hitelowa",
    "value": "Hitelowa"
  },
  {
    "id": "9102342028",
    "district_id": "910234",
    "label": "Duabalek",
    "value": "Duabalek"
  },
  {
    "id": "9102352001",
    "district_id": "910235",
    "label": "Yalengga",
    "value": "Yalengga"
  },
  {
    "id": "9102352002",
    "district_id": "910235",
    "label": "Taganik",
    "value": "Taganik"
  },
  {
    "id": "9102352004",
    "district_id": "910235",
    "label": "Wananuk",
    "value": "Wananuk"
  },
  {
    "id": "9102352006",
    "district_id": "910235",
    "label": "Pilimo",
    "value": "Pilimo"
  },
  {
    "id": "9102352007",
    "district_id": "910235",
    "label": "Tumun",
    "value": "Tumun"
  },
  {
    "id": "9102352008",
    "district_id": "910235",
    "label": "Akorek",
    "value": "Akorek"
  },
  {
    "id": "9102352009",
    "district_id": "910235",
    "label": "Manili",
    "value": "Manili"
  },
  {
    "id": "9102352010",
    "district_id": "910235",
    "label": "Aipakma",
    "value": "Aipakma"
  },
  {
    "id": "9102352015",
    "district_id": "910235",
    "label": "Wugurima",
    "value": "Wugurima"
  },
  {
    "id": "9102352016",
    "district_id": "910235",
    "label": "Bitti",
    "value": "Bitti"
  },
  {
    "id": "9102352017",
    "district_id": "910235",
    "label": "Wamanuk Dua",
    "value": "Wamanuk Dua"
  },
  {
    "id": "9102402001",
    "district_id": "910240",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "9102402002",
    "district_id": "910240",
    "label": "Nanggo",
    "value": "Nanggo"
  },
  {
    "id": "9102402003",
    "district_id": "910240",
    "label": "Dinggilimo",
    "value": "Dinggilimo"
  },
  {
    "id": "9102402004",
    "district_id": "910240",
    "label": "Kora Jaya",
    "value": "Kora Jaya"
  },
  {
    "id": "9102402005",
    "district_id": "910240",
    "label": "Korambirik",
    "value": "Korambirik"
  },
  {
    "id": "9102402006",
    "district_id": "910240",
    "label": "Anggulpa",
    "value": "Anggulpa"
  },
  {
    "id": "9102412001",
    "district_id": "910241",
    "label": "Napua",
    "value": "Napua"
  },
  {
    "id": "9102412002",
    "district_id": "910241",
    "label": "Holima",
    "value": "Holima"
  },
  {
    "id": "9102412003",
    "district_id": "910241",
    "label": "Lani Matuan",
    "value": "Lani Matuan"
  },
  {
    "id": "9102412004",
    "district_id": "910241",
    "label": "Okilik",
    "value": "Okilik"
  },
  {
    "id": "9102412005",
    "district_id": "910241",
    "label": "Healekma",
    "value": "Healekma"
  },
  {
    "id": "9102412006",
    "district_id": "910241",
    "label": "Yelekama",
    "value": "Yelekama"
  },
  {
    "id": "9102412007",
    "district_id": "910241",
    "label": "Sapalek",
    "value": "Sapalek"
  },
  {
    "id": "9102412008",
    "district_id": "910241",
    "label": "Yomaima",
    "value": "Yomaima"
  },
  {
    "id": "9102412009",
    "district_id": "910241",
    "label": "Wilekama",
    "value": "Wilekama"
  },
  {
    "id": "9102422001",
    "district_id": "910242",
    "label": "Walaik",
    "value": "Walaik"
  },
  {
    "id": "9102422002",
    "district_id": "910242",
    "label": "Elarek",
    "value": "Elarek"
  },
  {
    "id": "9102422003",
    "district_id": "910242",
    "label": "Holima",
    "value": "Holima"
  },
  {
    "id": "9102422004",
    "district_id": "910242",
    "label": "Yelai",
    "value": "Yelai"
  },
  {
    "id": "9102422005",
    "district_id": "910242",
    "label": "Welekama",
    "value": "Welekama"
  },
  {
    "id": "9102432001",
    "district_id": "910243",
    "label": "Wouma",
    "value": "Wouma"
  },
  {
    "id": "9102432002",
    "district_id": "910243",
    "label": "Ketimavit",
    "value": "Ketimavit"
  },
  {
    "id": "9102432003",
    "district_id": "910243",
    "label": "Wesakma",
    "value": "Wesakma"
  },
  {
    "id": "9102432004",
    "district_id": "910243",
    "label": "Logonoba",
    "value": "Logonoba"
  },
  {
    "id": "9102432005",
    "district_id": "910243",
    "label": "Wesakin",
    "value": "Wesakin"
  },
  {
    "id": "9102432006",
    "district_id": "910243",
    "label": "Pipitmo",
    "value": "Pipitmo"
  },
  {
    "id": "9102432007",
    "district_id": "910243",
    "label": "Sinarekowa",
    "value": "Sinarekowa"
  },
  {
    "id": "9102442001",
    "district_id": "910244",
    "label": "Hubukiak",
    "value": "Hubukiak"
  },
  {
    "id": "9102442002",
    "district_id": "910244",
    "label": "Hetuma",
    "value": "Hetuma"
  },
  {
    "id": "9102442003",
    "district_id": "910244",
    "label": "Dokopku",
    "value": "Dokopku"
  },
  {
    "id": "9102442004",
    "district_id": "910244",
    "label": "Likino",
    "value": "Likino"
  },
  {
    "id": "9102442005",
    "district_id": "910244",
    "label": "Musiamia",
    "value": "Musiamia"
  },
  {
    "id": "9102442006",
    "district_id": "910244",
    "label": "Husoak",
    "value": "Husoak"
  },
  {
    "id": "9102442007",
    "district_id": "910244",
    "label": "Hom-hom",
    "value": "Hom-hom"
  },
  {
    "id": "9102442008",
    "district_id": "910244",
    "label": "Musiamia Dua",
    "value": "Musiamia Dua"
  },
  {
    "id": "9102452001",
    "district_id": "910245",
    "label": "Yagarobak",
    "value": "Yagarobak"
  },
  {
    "id": "9102452002",
    "district_id": "910245",
    "label": "Zapma",
    "value": "Zapma"
  },
  {
    "id": "9102452003",
    "district_id": "910245",
    "label": "Ayobaibur",
    "value": "Ayobaibur"
  },
  {
    "id": "9102452004",
    "district_id": "910245",
    "label": "Ibele",
    "value": "Ibele"
  },
  {
    "id": "9102452005",
    "district_id": "910245",
    "label": "Tipalok",
    "value": "Tipalok"
  },
  {
    "id": "9102452006",
    "district_id": "910245",
    "label": "Habema",
    "value": "Habema"
  },
  {
    "id": "9102452007",
    "district_id": "910245",
    "label": "Zinai",
    "value": "Zinai"
  },
  {
    "id": "9102452008",
    "district_id": "910245",
    "label": "Holaliba",
    "value": "Holaliba"
  },
  {
    "id": "9102452009",
    "district_id": "910245",
    "label": "Yelebarek",
    "value": "Yelebarek"
  },
  {
    "id": "9102452010",
    "district_id": "910245",
    "label": "Yokalpalek",
    "value": "Yokalpalek"
  },
  {
    "id": "9102462001",
    "district_id": "910246",
    "label": "Tailarek",
    "value": "Tailarek"
  },
  {
    "id": "9102462002",
    "district_id": "910246",
    "label": "Senogolik",
    "value": "Senogolik"
  },
  {
    "id": "9102462003",
    "district_id": "910246",
    "label": "Bokiem",
    "value": "Bokiem"
  },
  {
    "id": "9102462004",
    "district_id": "910246",
    "label": "Iyora",
    "value": "Iyora"
  },
  {
    "id": "9102462005",
    "district_id": "910246",
    "label": "Budliem",
    "value": "Budliem"
  },
  {
    "id": "9102462006",
    "district_id": "910246",
    "label": "Yoman Weya",
    "value": "Yoman Weya"
  },
  {
    "id": "9102462007",
    "district_id": "910246",
    "label": "Huluaima",
    "value": "Huluaima"
  },
  {
    "id": "9102462008",
    "district_id": "910246",
    "label": "Entagekokma",
    "value": "Entagekokma"
  },
  {
    "id": "9102472001",
    "district_id": "910247",
    "label": "Lukaken",
    "value": "Lukaken"
  },
  {
    "id": "9102472002",
    "district_id": "910247",
    "label": "Miami",
    "value": "Miami"
  },
  {
    "id": "9102472003",
    "district_id": "910247",
    "label": "Waroba",
    "value": "Waroba"
  },
  {
    "id": "9102472004",
    "district_id": "910247",
    "label": "Yogonima",
    "value": "Yogonima"
  },
  {
    "id": "9102472005",
    "district_id": "910247",
    "label": "Tomisa",
    "value": "Tomisa"
  },
  {
    "id": "9102472006",
    "district_id": "910247",
    "label": "Helepalegem",
    "value": "Helepalegem"
  },
  {
    "id": "9102472007",
    "district_id": "910247",
    "label": "Siliwa",
    "value": "Siliwa"
  },
  {
    "id": "9102472008",
    "district_id": "910247",
    "label": "Sumunikama",
    "value": "Sumunikama"
  },
  {
    "id": "9102472009",
    "district_id": "910247",
    "label": "Kemisake",
    "value": "Kemisake"
  },
  {
    "id": "9102482001",
    "district_id": "910248",
    "label": "Siepkosi",
    "value": "Siepkosi"
  },
  {
    "id": "9102482002",
    "district_id": "910248",
    "label": "Yumogima",
    "value": "Yumogima"
  },
  {
    "id": "9102482003",
    "district_id": "910248",
    "label": "Sekan",
    "value": "Sekan"
  },
  {
    "id": "9102482004",
    "district_id": "910248",
    "label": "Noagalo",
    "value": "Noagalo"
  },
  {
    "id": "9102482005",
    "district_id": "910248",
    "label": "Manika",
    "value": "Manika"
  },
  {
    "id": "9102482006",
    "district_id": "910248",
    "label": "Sekan Dalam",
    "value": "Sekan Dalam"
  },
  {
    "id": "9102482007",
    "district_id": "910248",
    "label": "Isawa Himan",
    "value": "Isawa Himan"
  },
  {
    "id": "9102482008",
    "district_id": "910248",
    "label": "Lunaima",
    "value": "Lunaima"
  },
  {
    "id": "9102482009",
    "district_id": "910248",
    "label": "Wekia",
    "value": "Wekia"
  },
  {
    "id": "9102492001",
    "district_id": "910249",
    "label": "Usilimo",
    "value": "Usilimo"
  },
  {
    "id": "9102492002",
    "district_id": "910249",
    "label": "Siba",
    "value": "Siba"
  },
  {
    "id": "9102492003",
    "district_id": "910249",
    "label": "Wosiala",
    "value": "Wosiala"
  },
  {
    "id": "9102492004",
    "district_id": "910249",
    "label": "Gua Wisata",
    "value": "Gua Wisata"
  },
  {
    "id": "9102492005",
    "district_id": "910249",
    "label": "Undulumo",
    "value": "Undulumo"
  },
  {
    "id": "9102492006",
    "district_id": "910249",
    "label": "Fikha",
    "value": "Fikha"
  },
  {
    "id": "9102492007",
    "district_id": "910249",
    "label": "Meagamia",
    "value": "Meagamia"
  },
  {
    "id": "9102492008",
    "district_id": "910249",
    "label": "Alona",
    "value": "Alona"
  },
  {
    "id": "9102492009",
    "district_id": "910249",
    "label": "Abutpuk",
    "value": "Abutpuk"
  },
  {
    "id": "9102492010",
    "district_id": "910249",
    "label": "Isaiman",
    "value": "Isaiman"
  },
  {
    "id": "9102502001",
    "district_id": "910250",
    "label": "Koma",
    "value": "Koma"
  },
  {
    "id": "9102502002",
    "district_id": "910250",
    "label": "Tulem",
    "value": "Tulem"
  },
  {
    "id": "9102502003",
    "district_id": "910250",
    "label": "Alula",
    "value": "Alula"
  },
  {
    "id": "9102502004",
    "district_id": "910250",
    "label": "Alolik",
    "value": "Alolik"
  },
  {
    "id": "9102502005",
    "district_id": "910250",
    "label": "Wiligima",
    "value": "Wiligima"
  },
  {
    "id": "9102512001",
    "district_id": "910251",
    "label": "Muliama",
    "value": "Muliama"
  },
  {
    "id": "9102512002",
    "district_id": "910251",
    "label": "Wenabubaga",
    "value": "Wenabubaga"
  },
  {
    "id": "9102512003",
    "district_id": "910251",
    "label": "Kilubaga",
    "value": "Kilubaga"
  },
  {
    "id": "9102512004",
    "district_id": "910251",
    "label": "Musalfak",
    "value": "Musalfak"
  },
  {
    "id": "9102512005",
    "district_id": "910251",
    "label": "Punakul",
    "value": "Punakul"
  },
  {
    "id": "9102522001",
    "district_id": "910252",
    "label": "Wadangku",
    "value": "Wadangku"
  },
  {
    "id": "9102522002",
    "district_id": "910252",
    "label": "Agulimo",
    "value": "Agulimo"
  },
  {
    "id": "9102522003",
    "district_id": "910252",
    "label": "Musiem",
    "value": "Musiem"
  },
  {
    "id": "9102522004",
    "district_id": "910252",
    "label": "Yomosimo",
    "value": "Yomosimo"
  },
  {
    "id": "9102522005",
    "district_id": "910252",
    "label": "Luku-luku",
    "value": "Luku-luku"
  },
  {
    "id": "9102532001",
    "district_id": "910253",
    "label": "Pisugi",
    "value": "Pisugi"
  },
  {
    "id": "9102532002",
    "district_id": "910253",
    "label": "Pabuma",
    "value": "Pabuma"
  },
  {
    "id": "9102532003",
    "district_id": "910253",
    "label": "Aikima",
    "value": "Aikima"
  },
  {
    "id": "9102532004",
    "district_id": "910253",
    "label": "Pikhe",
    "value": "Pikhe"
  },
  {
    "id": "9102532005",
    "district_id": "910253",
    "label": "Akiaput",
    "value": "Akiaput"
  },
  {
    "id": "9102532006",
    "district_id": "910253",
    "label": "Suroba",
    "value": "Suroba"
  },
  {
    "id": "9102532007",
    "district_id": "910253",
    "label": "Wara",
    "value": "Wara"
  },
  {
    "id": "9102542001",
    "district_id": "910254",
    "label": "Koragi",
    "value": "Koragi"
  },
  {
    "id": "9102542002",
    "district_id": "910254",
    "label": "Telegai",
    "value": "Telegai"
  },
  {
    "id": "9102542003",
    "district_id": "910254",
    "label": "Kumudiluk",
    "value": "Kumudiluk"
  },
  {
    "id": "9102542004",
    "district_id": "910254",
    "label": "Tagibaga",
    "value": "Tagibaga"
  },
  {
    "id": "9102542005",
    "district_id": "910254",
    "label": "Tenondek",
    "value": "Tenondek"
  },
  {
    "id": "9102552001",
    "district_id": "910255",
    "label": "Tagime",
    "value": "Tagime"
  },
  {
    "id": "9102552002",
    "district_id": "910255",
    "label": "Porome",
    "value": "Porome"
  },
  {
    "id": "9102552003",
    "district_id": "910255",
    "label": "Onggobalo",
    "value": "Onggobalo"
  },
  {
    "id": "9102552004",
    "district_id": "910255",
    "label": "Lakwame",
    "value": "Lakwame"
  },
  {
    "id": "9102552005",
    "district_id": "910255",
    "label": "Yordania Tulak",
    "value": "Yordania Tulak"
  },
  {
    "id": "9102552006",
    "district_id": "910255",
    "label": "Gume Kupari",
    "value": "Gume Kupari"
  },
  {
    "id": "9102552007",
    "district_id": "910255",
    "label": "Wandinggunie",
    "value": "Wandinggunie"
  },
  {
    "id": "9102552008",
    "district_id": "910255",
    "label": "Mulugame",
    "value": "Mulugame"
  },
  {
    "id": "9102552009",
    "district_id": "910255",
    "label": "Lapeyo",
    "value": "Lapeyo"
  },
  {
    "id": "9102552010",
    "district_id": "910255",
    "label": "Yanggapura",
    "value": "Yanggapura"
  },
  {
    "id": "9102552011",
    "district_id": "910255",
    "label": "Yogobur",
    "value": "Yogobur"
  },
  {
    "id": "9102562001",
    "district_id": "910256",
    "label": "Tanahmerah",
    "value": "Tanahmerah"
  },
  {
    "id": "9102562002",
    "district_id": "910256",
    "label": "Towagame",
    "value": "Towagame"
  },
  {
    "id": "9102562003",
    "district_id": "910256",
    "label": "Molagalome",
    "value": "Molagalome"
  },
  {
    "id": "9102562004",
    "district_id": "910256",
    "label": "Kwigiluk",
    "value": "Kwigiluk"
  },
  {
    "id": "9102562005",
    "district_id": "910256",
    "label": "Mebunukme",
    "value": "Mebunukme"
  },
  {
    "id": "9102562006",
    "district_id": "910256",
    "label": "Okwa",
    "value": "Okwa"
  },
  {
    "id": "9102572001",
    "district_id": "910257",
    "label": "Tagineri",
    "value": "Tagineri"
  },
  {
    "id": "9102572002",
    "district_id": "910257",
    "label": "Injuta",
    "value": "Injuta"
  },
  {
    "id": "9102572003",
    "district_id": "910257",
    "label": "Binibaga",
    "value": "Binibaga"
  },
  {
    "id": "9102572004",
    "district_id": "910257",
    "label": "Tamokilu",
    "value": "Tamokilu"
  },
  {
    "id": "9102572005",
    "district_id": "910257",
    "label": "Pagaluk",
    "value": "Pagaluk"
  },
  {
    "id": "9102572006",
    "district_id": "910257",
    "label": "Gelelame",
    "value": "Gelelame"
  },
  {
    "id": "9102572007",
    "district_id": "910257",
    "label": "Wilaloma",
    "value": "Wilaloma"
  },
  {
    "id": "9102572008",
    "district_id": "910257",
    "label": "Melemei",
    "value": "Melemei"
  },
  {
    "id": "9102572009",
    "district_id": "910257",
    "label": "Wuragukme",
    "value": "Wuragukme"
  },
  {
    "id": "9102582001",
    "district_id": "910258",
    "label": "Wogi",
    "value": "Wogi"
  },
  {
    "id": "9102582002",
    "district_id": "910258",
    "label": "Elaboge",
    "value": "Elaboge"
  },
  {
    "id": "9102582003",
    "district_id": "910258",
    "label": "Holasili",
    "value": "Holasili"
  },
  {
    "id": "9102582004",
    "district_id": "910258",
    "label": "Yerega",
    "value": "Yerega"
  },
  {
    "id": "9102582005",
    "district_id": "910258",
    "label": "Olagi",
    "value": "Olagi"
  },
  {
    "id": "9102582006",
    "district_id": "910258",
    "label": "Gigilobo",
    "value": "Gigilobo"
  },
  {
    "id": "9102582007",
    "district_id": "910258",
    "label": "Apnae",
    "value": "Apnae"
  },
  {
    "id": "9102582008",
    "district_id": "910258",
    "label": "Wonenggulik",
    "value": "Wonenggulik"
  },
  {
    "id": "9102592001",
    "district_id": "910259",
    "label": "Piramid",
    "value": "Piramid"
  },
  {
    "id": "9102592002",
    "district_id": "910259",
    "label": "Yonggime",
    "value": "Yonggime"
  },
  {
    "id": "9102592003",
    "district_id": "910259",
    "label": "Perabaga",
    "value": "Perabaga"
  },
  {
    "id": "9102592004",
    "district_id": "910259",
    "label": "Algonik",
    "value": "Algonik"
  },
  {
    "id": "9102592005",
    "district_id": "910259",
    "label": "Yalinggume",
    "value": "Yalinggume"
  },
  {
    "id": "9102592006",
    "district_id": "910259",
    "label": "Aboneri",
    "value": "Aboneri"
  },
  {
    "id": "9102592007",
    "district_id": "910259",
    "label": "Gobalimo",
    "value": "Gobalimo"
  },
  {
    "id": "9102592008",
    "district_id": "910259",
    "label": "Balima",
    "value": "Balima"
  },
  {
    "id": "9102592009",
    "district_id": "910259",
    "label": "Yumbun",
    "value": "Yumbun"
  },
  {
    "id": "9102592010",
    "district_id": "910259",
    "label": "Beam",
    "value": "Beam"
  },
  {
    "id": "9102602001",
    "district_id": "910260",
    "label": "Miliama",
    "value": "Miliama"
  },
  {
    "id": "9102602002",
    "district_id": "910260",
    "label": "Asologaima",
    "value": "Asologaima"
  },
  {
    "id": "9102602003",
    "district_id": "910260",
    "label": "Kewin",
    "value": "Kewin"
  },
  {
    "id": "9102602004",
    "district_id": "910260",
    "label": "Holkima",
    "value": "Holkima"
  },
  {
    "id": "9102602005",
    "district_id": "910260",
    "label": "Helefa",
    "value": "Helefa"
  },
  {
    "id": "9102602006",
    "district_id": "910260",
    "label": "Sekom",
    "value": "Sekom"
  },
  {
    "id": "9102602007",
    "district_id": "910260",
    "label": "Delekama",
    "value": "Delekama"
  },
  {
    "id": "9102602008",
    "district_id": "910260",
    "label": "Molebaga",
    "value": "Molebaga"
  },
  {
    "id": "9102602009",
    "district_id": "910260",
    "label": "Silamik",
    "value": "Silamik"
  },
  {
    "id": "9102602010",
    "district_id": "910260",
    "label": "Konan",
    "value": "Konan"
  },
  {
    "id": "9102602011",
    "district_id": "910260",
    "label": "Pilibaga",
    "value": "Pilibaga"
  },
  {
    "id": "9102602012",
    "district_id": "910260",
    "label": "Hukuragi",
    "value": "Hukuragi"
  },
  {
    "id": "9102612001",
    "district_id": "910261",
    "label": "Bugi",
    "value": "Bugi"
  },
  {
    "id": "9102612002",
    "district_id": "910261",
    "label": "Manda",
    "value": "Manda"
  },
  {
    "id": "9102612003",
    "district_id": "910261",
    "label": "Dewene",
    "value": "Dewene"
  },
  {
    "id": "9102612004",
    "district_id": "910261",
    "label": "Totni",
    "value": "Totni"
  },
  {
    "id": "9102612005",
    "district_id": "910261",
    "label": "Tagulik",
    "value": "Tagulik"
  },
  {
    "id": "9102612006",
    "district_id": "910261",
    "label": "Walak Selatan",
    "value": "Walak Selatan"
  },
  {
    "id": "9102612007",
    "district_id": "910261",
    "label": "Kodlangga",
    "value": "Kodlangga"
  },
  {
    "id": "9102612008",
    "district_id": "910261",
    "label": "Air Garam",
    "value": "Air Garam"
  },
  {
    "id": "9102622001",
    "district_id": "910262",
    "label": "Irilinga",
    "value": "Irilinga"
  },
  {
    "id": "9102622002",
    "district_id": "910262",
    "label": "Onggobaga",
    "value": "Onggobaga"
  },
  {
    "id": "9102622003",
    "district_id": "910262",
    "label": "Walakma",
    "value": "Walakma"
  },
  {
    "id": "9102622004",
    "district_id": "910262",
    "label": "Ayoma",
    "value": "Ayoma"
  },
  {
    "id": "9102622005",
    "district_id": "910262",
    "label": "Dlonggoki",
    "value": "Dlonggoki"
  },
  {
    "id": "9102622006",
    "district_id": "910262",
    "label": "Tirunggu",
    "value": "Tirunggu"
  },
  {
    "id": "9102622007",
    "district_id": "910262",
    "label": "Dlinggama",
    "value": "Dlinggama"
  },
  {
    "id": "9102632001",
    "district_id": "910263",
    "label": "Welesi",
    "value": "Welesi"
  },
  {
    "id": "9102632002",
    "district_id": "910263",
    "label": "Yagara",
    "value": "Yagara"
  },
  {
    "id": "9102632003",
    "district_id": "910263",
    "label": "Asojelipele",
    "value": "Asojelipele"
  },
  {
    "id": "9102632004",
    "district_id": "910263",
    "label": "Lantipo",
    "value": "Lantipo"
  },
  {
    "id": "9102632005",
    "district_id": "910263",
    "label": "Pawekama",
    "value": "Pawekama"
  },
  {
    "id": "9102632006",
    "district_id": "910263",
    "label": "Apenas",
    "value": "Apenas"
  },
  {
    "id": "9102632007",
    "district_id": "910263",
    "label": "Tulima",
    "value": "Tulima"
  },
  {
    "id": "9102642001",
    "district_id": "910264",
    "label": "Sogokmo",
    "value": "Sogokmo"
  },
  {
    "id": "9102642002",
    "district_id": "910264",
    "label": "Asotipo",
    "value": "Asotipo"
  },
  {
    "id": "9102642003",
    "district_id": "910264",
    "label": "Putageima",
    "value": "Putageima"
  },
  {
    "id": "9102642004",
    "district_id": "910264",
    "label": "Kuantapo",
    "value": "Kuantapo"
  },
  {
    "id": "9102642005",
    "district_id": "910264",
    "label": "Heberima",
    "value": "Heberima"
  },
  {
    "id": "9102642006",
    "district_id": "910264",
    "label": "Iwigima",
    "value": "Iwigima"
  },
  {
    "id": "9102642007",
    "district_id": "910264",
    "label": "Pobiatma",
    "value": "Pobiatma"
  },
  {
    "id": "9102642008",
    "district_id": "910264",
    "label": "Hitigima",
    "value": "Hitigima"
  },
  {
    "id": "9102642009",
    "district_id": "910264",
    "label": "Air Garam",
    "value": "Air Garam"
  },
  {
    "id": "9102642010",
    "district_id": "910264",
    "label": "Hukulimo",
    "value": "Hukulimo"
  },
  {
    "id": "9102652001",
    "district_id": "910265",
    "label": "Maima",
    "value": "Maima"
  },
  {
    "id": "9102652002",
    "district_id": "910265",
    "label": "Minimo",
    "value": "Minimo"
  },
  {
    "id": "9102652004",
    "district_id": "910265",
    "label": "Esiak",
    "value": "Esiak"
  },
  {
    "id": "9102652005",
    "district_id": "910265",
    "label": "Heraewa",
    "value": "Heraewa"
  },
  {
    "id": "9102652006",
    "district_id": "910265",
    "label": "Kepi",
    "value": "Kepi"
  },
  {
    "id": "9102652007",
    "district_id": "910265",
    "label": "Husewa",
    "value": "Husewa"
  },
  {
    "id": "9102652008",
    "district_id": "910265",
    "label": "Menagaima",
    "value": "Menagaima"
  },
  {
    "id": "9102662001",
    "district_id": "910266",
    "label": "Pupugoba",
    "value": "Pupugoba"
  },
  {
    "id": "9102662002",
    "district_id": "910266",
    "label": "Waima",
    "value": "Waima"
  },
  {
    "id": "9102662003",
    "district_id": "910266",
    "label": "Yomote",
    "value": "Yomote"
  },
  {
    "id": "9102662004",
    "district_id": "910266",
    "label": "Yelelo",
    "value": "Yelelo"
  },
  {
    "id": "9102672001",
    "district_id": "910267",
    "label": "Wame",
    "value": "Wame"
  },
  {
    "id": "9102672002",
    "district_id": "910267",
    "label": "Dumapaga",
    "value": "Dumapaga"
  },
  {
    "id": "9102672003",
    "district_id": "910267",
    "label": "Yanenggame",
    "value": "Yanenggame"
  },
  {
    "id": "9102672004",
    "district_id": "910267",
    "label": "Dogoname",
    "value": "Dogoname"
  },
  {
    "id": "9102681001",
    "district_id": "910268",
    "label": "Ilokama",
    "value": "Ilokama"
  },
  {
    "id": "9102682002",
    "district_id": "910268",
    "label": "Kama",
    "value": "Kama"
  },
  {
    "id": "9102682003",
    "district_id": "910268",
    "label": "Yaloaput",
    "value": "Yaloaput"
  },
  {
    "id": "9102682004",
    "district_id": "910268",
    "label": "Mawampi",
    "value": "Mawampi"
  },
  {
    "id": "9102682005",
    "district_id": "910268",
    "label": "Parema",
    "value": "Parema"
  },
  {
    "id": "9102682006",
    "district_id": "910268",
    "label": "Agamoa",
    "value": "Agamoa"
  },
  {
    "id": "9102682007",
    "district_id": "910268",
    "label": "Silumarek",
    "value": "Silumarek"
  },
  {
    "id": "9102682008",
    "district_id": "910268",
    "label": "Wesaganya",
    "value": "Wesaganya"
  },
  {
    "id": "9103011001",
    "district_id": "910301",
    "label": "Sentani Kota",
    "value": "Sentani Kota"
  },
  {
    "id": "9103011002",
    "district_id": "910301",
    "label": "Dobonsolo",
    "value": "Dobonsolo"
  },
  {
    "id": "9103011003",
    "district_id": "910301",
    "label": "Hinekombe",
    "value": "Hinekombe"
  },
  {
    "id": "9103012004",
    "district_id": "910301",
    "label": "Sereh",
    "value": "Sereh"
  },
  {
    "id": "9103012005",
    "district_id": "910301",
    "label": "Yobeh",
    "value": "Yobeh"
  },
  {
    "id": "9103012006",
    "district_id": "910301",
    "label": "Ilfele",
    "value": "Ilfele"
  },
  {
    "id": "9103012007",
    "district_id": "910301",
    "label": "Keheran",
    "value": "Keheran"
  },
  {
    "id": "9103012010",
    "district_id": "910301",
    "label": "Ifar Besar",
    "value": "Ifar Besar"
  },
  {
    "id": "9103012011",
    "district_id": "910301",
    "label": "Hobong",
    "value": "Hobong"
  },
  {
    "id": "9103012017",
    "district_id": "910301",
    "label": "Yahim",
    "value": "Yahim"
  },
  {
    "id": "9103022001",
    "district_id": "910302",
    "label": "Nolokla",
    "value": "Nolokla"
  },
  {
    "id": "9103022002",
    "district_id": "910302",
    "label": "Puai",
    "value": "Puai"
  },
  {
    "id": "9103022003",
    "district_id": "910302",
    "label": "Ayapo",
    "value": "Ayapo"
  },
  {
    "id": "9103022004",
    "district_id": "910302",
    "label": "Asei Besar",
    "value": "Asei Besar"
  },
  {
    "id": "9103022005",
    "district_id": "910302",
    "label": "Asei Kecil",
    "value": "Asei Kecil"
  },
  {
    "id": "9103022006",
    "district_id": "910302",
    "label": "Nendali",
    "value": "Nendali"
  },
  {
    "id": "9103022007",
    "district_id": "910302",
    "label": "Yokiwa",
    "value": "Yokiwa"
  },
  {
    "id": "9103032001",
    "district_id": "910303",
    "label": "Waiya",
    "value": "Waiya"
  },
  {
    "id": "9103032002",
    "district_id": "910303",
    "label": "Entiyebo",
    "value": "Entiyebo"
  },
  {
    "id": "9103032003",
    "district_id": "910303",
    "label": "Kendate",
    "value": "Kendate"
  },
  {
    "id": "9103032004",
    "district_id": "910303",
    "label": "Tablasupa",
    "value": "Tablasupa"
  },
  {
    "id": "9103032005",
    "district_id": "910303",
    "label": "Yepase",
    "value": "Yepase"
  },
  {
    "id": "9103032006",
    "district_id": "910303",
    "label": "Wambena",
    "value": "Wambena"
  },
  {
    "id": "9103032007",
    "district_id": "910303",
    "label": "Yewena",
    "value": "Yewena"
  },
  {
    "id": "9103032012",
    "district_id": "910303",
    "label": "Doromena",
    "value": "Doromena"
  },
  {
    "id": "9103042001",
    "district_id": "910304",
    "label": "Dosay",
    "value": "Dosay"
  },
  {
    "id": "9103042005",
    "district_id": "910304",
    "label": "Maribu",
    "value": "Maribu"
  },
  {
    "id": "9103042006",
    "district_id": "910304",
    "label": "Waibron",
    "value": "Waibron"
  },
  {
    "id": "9103042008",
    "district_id": "910304",
    "label": "Sabron Sari",
    "value": "Sabron Sari"
  },
  {
    "id": "9103042011",
    "district_id": "910304",
    "label": "Sabro Yaru",
    "value": "Sabro Yaru"
  },
  {
    "id": "9103052001",
    "district_id": "910305",
    "label": "Sama",
    "value": "Sama"
  },
  {
    "id": "9103052002",
    "district_id": "910305",
    "label": "Manda Yawan",
    "value": "Manda Yawan"
  },
  {
    "id": "9103052003",
    "district_id": "910305",
    "label": "Mamda",
    "value": "Mamda"
  },
  {
    "id": "9103052004",
    "district_id": "910305",
    "label": "Mamei",
    "value": "Mamei"
  },
  {
    "id": "9103052005",
    "district_id": "910305",
    "label": "Nambom",
    "value": "Nambom"
  },
  {
    "id": "9103052006",
    "district_id": "910305",
    "label": "Kwansu",
    "value": "Kwansu"
  },
  {
    "id": "9103052007",
    "district_id": "910305",
    "label": "Soaib",
    "value": "Soaib"
  },
  {
    "id": "9103052008",
    "district_id": "910305",
    "label": "Sabeab Kecil",
    "value": "Sabeab Kecil"
  },
  {
    "id": "9103052009",
    "district_id": "910305",
    "label": "Sekori",
    "value": "Sekori"
  },
  {
    "id": "9103052010",
    "district_id": "910305",
    "label": "Skoaim",
    "value": "Skoaim"
  },
  {
    "id": "9103052011",
    "district_id": "910305",
    "label": "Benggwin Progo",
    "value": "Benggwin Progo"
  },
  {
    "id": "9103052012",
    "district_id": "910305",
    "label": "Aib",
    "value": "Aib"
  },
  {
    "id": "9103061015",
    "district_id": "910306",
    "label": "Hatib",
    "value": "Hatib"
  },
  {
    "id": "9103062001",
    "district_id": "910306",
    "label": "Domoikati",
    "value": "Domoikati"
  },
  {
    "id": "9103062002",
    "district_id": "910306",
    "label": "Dementin",
    "value": "Dementin"
  },
  {
    "id": "9103062003",
    "district_id": "910306",
    "label": "Yanbra",
    "value": "Yanbra"
  },
  {
    "id": "9103062004",
    "district_id": "910306",
    "label": "Braso",
    "value": "Braso"
  },
  {
    "id": "9103062005",
    "district_id": "910306",
    "label": "Pupehabu",
    "value": "Pupehabu"
  },
  {
    "id": "9103062006",
    "district_id": "910306",
    "label": "Bring",
    "value": "Bring"
  },
  {
    "id": "9103062007",
    "district_id": "910306",
    "label": "Nembu Gresi",
    "value": "Nembu Gresi"
  },
  {
    "id": "9103062009",
    "district_id": "910306",
    "label": "Ibub",
    "value": "Ibub"
  },
  {
    "id": "9103062013",
    "district_id": "910306",
    "label": "Swentab",
    "value": "Swentab"
  },
  {
    "id": "9103062014",
    "district_id": "910306",
    "label": "Jagrang",
    "value": "Jagrang"
  },
  {
    "id": "9103062016",
    "district_id": "910306",
    "label": "Hyansip",
    "value": "Hyansip"
  },
  {
    "id": "9103071021",
    "district_id": "910307",
    "label": "Tabri",
    "value": "Tabri"
  },
  {
    "id": "9103072001",
    "district_id": "910307",
    "label": "Gemebs",
    "value": "Gemebs"
  },
  {
    "id": "9103072002",
    "district_id": "910307",
    "label": "Singgri",
    "value": "Singgri"
  },
  {
    "id": "9103072003",
    "district_id": "910307",
    "label": "Meyu",
    "value": "Meyu"
  },
  {
    "id": "9103072004",
    "district_id": "910307",
    "label": "Benyom",
    "value": "Benyom"
  },
  {
    "id": "9103072005",
    "district_id": "910307",
    "label": "Oyengsi",
    "value": "Oyengsi"
  },
  {
    "id": "9103072006",
    "district_id": "910307",
    "label": "Singgriway",
    "value": "Singgriway"
  },
  {
    "id": "9103072009",
    "district_id": "910307",
    "label": "Imsar",
    "value": "Imsar"
  },
  {
    "id": "9103072010",
    "district_id": "910307",
    "label": "Kuipons",
    "value": "Kuipons"
  },
  {
    "id": "9103072014",
    "district_id": "910307",
    "label": "Yenggu Baru",
    "value": "Yenggu Baru"
  },
  {
    "id": "9103072015",
    "district_id": "910307",
    "label": "Yenggu Lama",
    "value": "Yenggu Lama"
  },
  {
    "id": "9103072017",
    "district_id": "910307",
    "label": "Kuwase",
    "value": "Kuwase"
  },
  {
    "id": "9103072018",
    "district_id": "910307",
    "label": "Kaitemung",
    "value": "Kaitemung"
  },
  {
    "id": "9103072019",
    "district_id": "910307",
    "label": "Pobaim",
    "value": "Pobaim"
  },
  {
    "id": "9103082001",
    "district_id": "910308",
    "label": "Nimbokrang",
    "value": "Nimbokrang"
  },
  {
    "id": "9103082002",
    "district_id": "910308",
    "label": "Benyom Jaya I",
    "value": "Benyom Jaya I"
  },
  {
    "id": "9103082003",
    "district_id": "910308",
    "label": "Benyom Jaya II",
    "value": "Benyom Jaya II"
  },
  {
    "id": "9103082004",
    "district_id": "910308",
    "label": "Berab",
    "value": "Berab"
  },
  {
    "id": "9103082005",
    "district_id": "910308",
    "label": "Hamonggrang",
    "value": "Hamonggrang"
  },
  {
    "id": "9103082006",
    "district_id": "910308",
    "label": "Wahab",
    "value": "Wahab"
  },
  {
    "id": "9103082007",
    "district_id": "910308",
    "label": "Nombukrang Sari",
    "value": "Nombukrang Sari"
  },
  {
    "id": "9103082008",
    "district_id": "910308",
    "label": "Rhepang Muaf",
    "value": "Rhepang Muaf"
  },
  {
    "id": "9103082009",
    "district_id": "910308",
    "label": "Bunyom",
    "value": "Bunyom"
  },
  {
    "id": "9103092001",
    "district_id": "910309",
    "label": "Beneik",
    "value": "Beneik"
  },
  {
    "id": "9103092002",
    "district_id": "910309",
    "label": "Ganusa",
    "value": "Ganusa"
  },
  {
    "id": "9103092003",
    "district_id": "910309",
    "label": "Guryard",
    "value": "Guryard"
  },
  {
    "id": "9103092004",
    "district_id": "910309",
    "label": "Santosa",
    "value": "Santosa"
  },
  {
    "id": "9103092005",
    "district_id": "910309",
    "label": "Sawa Suma",
    "value": "Sawa Suma"
  },
  {
    "id": "9103092006",
    "district_id": "910309",
    "label": "Nandaizi",
    "value": "Nandaizi"
  },
  {
    "id": "9103102001",
    "district_id": "910310",
    "label": "Demta",
    "value": "Demta"
  },
  {
    "id": "9103102002",
    "district_id": "910310",
    "label": "Ambora",
    "value": "Ambora"
  },
  {
    "id": "9103102003",
    "district_id": "910310",
    "label": "Yougapsa",
    "value": "Yougapsa"
  },
  {
    "id": "9103102004",
    "district_id": "910310",
    "label": "Muris Kecil",
    "value": "Muris Kecil"
  },
  {
    "id": "9103102005",
    "district_id": "910310",
    "label": "Yakore",
    "value": "Yakore"
  },
  {
    "id": "9103102006",
    "district_id": "910310",
    "label": "Kamdera",
    "value": "Kamdera"
  },
  {
    "id": "9103102007",
    "district_id": "910310",
    "label": "Muaif",
    "value": "Muaif"
  },
  {
    "id": "9103112001",
    "district_id": "910311",
    "label": "Lapua",
    "value": "Lapua"
  },
  {
    "id": "9103112002",
    "district_id": "910311",
    "label": "Sebum",
    "value": "Sebum"
  },
  {
    "id": "9103112003",
    "district_id": "910311",
    "label": "Soskotek",
    "value": "Soskotek"
  },
  {
    "id": "9103112004",
    "district_id": "910311",
    "label": "Yadauw",
    "value": "Yadauw"
  },
  {
    "id": "9103112006",
    "district_id": "910311",
    "label": "Umbron",
    "value": "Umbron"
  },
  {
    "id": "9103122001",
    "district_id": "910312",
    "label": "Ebungfa",
    "value": "Ebungfa"
  },
  {
    "id": "9103122002",
    "district_id": "910312",
    "label": "Atabar",
    "value": "Atabar"
  },
  {
    "id": "9103122003",
    "district_id": "910312",
    "label": "Bobrongko",
    "value": "Bobrongko"
  },
  {
    "id": "9103122004",
    "district_id": "910312",
    "label": "Khameyoka",
    "value": "Khameyoka"
  },
  {
    "id": "9103122005",
    "district_id": "910312",
    "label": "Simporo",
    "value": "Simporo"
  },
  {
    "id": "9103132001",
    "district_id": "910313",
    "label": "Donday",
    "value": "Donday"
  },
  {
    "id": "9103132002",
    "district_id": "910313",
    "label": "Doyo Lama",
    "value": "Doyo Lama"
  },
  {
    "id": "9103132003",
    "district_id": "910313",
    "label": "Kwadeware",
    "value": "Kwadeware"
  },
  {
    "id": "9103132004",
    "district_id": "910313",
    "label": "Yakonde",
    "value": "Yakonde"
  },
  {
    "id": "9103132005",
    "district_id": "910313",
    "label": "Sosiri",
    "value": "Sosiri"
  },
  {
    "id": "9103132006",
    "district_id": "910313",
    "label": "Doyo Baru",
    "value": "Doyo Baru"
  },
  {
    "id": "9103132007",
    "district_id": "910313",
    "label": "Bambar",
    "value": "Bambar"
  },
  {
    "id": "9103142001",
    "district_id": "910314",
    "label": "Sarmai Atas",
    "value": "Sarmai Atas"
  },
  {
    "id": "9103142002",
    "district_id": "910314",
    "label": "Sarmai Bawah",
    "value": "Sarmai Bawah"
  },
  {
    "id": "9103142003",
    "district_id": "910314",
    "label": "Sanggai",
    "value": "Sanggai"
  },
  {
    "id": "9103142004",
    "district_id": "910314",
    "label": "Yakasib",
    "value": "Yakasib"
  },
  {
    "id": "9103142005",
    "district_id": "910314",
    "label": "Besum",
    "value": "Besum"
  },
  {
    "id": "9103142007",
    "district_id": "910314",
    "label": "Imestum",
    "value": "Imestum"
  },
  {
    "id": "9103142008",
    "district_id": "910314",
    "label": "Karya Bumi",
    "value": "Karya Bumi"
  },
  {
    "id": "9103142009",
    "district_id": "910314",
    "label": "Hanggaiy Hamong",
    "value": "Hanggaiy Hamong"
  },
  {
    "id": "9103142010",
    "district_id": "910314",
    "label": "Sumbe",
    "value": "Sumbe"
  },
  {
    "id": "9103152001",
    "district_id": "910315",
    "label": "Tabbeyan",
    "value": "Tabbeyan"
  },
  {
    "id": "9103152002",
    "district_id": "910315",
    "label": "Kwarja",
    "value": "Kwarja"
  },
  {
    "id": "9103152003",
    "district_id": "910315",
    "label": "Ongan Jaya",
    "value": "Ongan Jaya"
  },
  {
    "id": "9103152004",
    "district_id": "910315",
    "label": "Bumi Sahaja",
    "value": "Bumi Sahaja"
  },
  {
    "id": "9103152005",
    "district_id": "910315",
    "label": "Nawa Mulya",
    "value": "Nawa Mulya"
  },
  {
    "id": "9103152006",
    "district_id": "910315",
    "label": "Nawa Mukti",
    "value": "Nawa Mukti"
  },
  {
    "id": "9103152007",
    "district_id": "910315",
    "label": "Taqwa Bangun",
    "value": "Taqwa Bangun"
  },
  {
    "id": "9103152008",
    "district_id": "910315",
    "label": "Purnama Jati",
    "value": "Purnama Jati"
  },
  {
    "id": "9103152009",
    "district_id": "910315",
    "label": "Bundru",
    "value": "Bundru"
  },
  {
    "id": "9103162001",
    "district_id": "910316",
    "label": "Hulu Atas",
    "value": "Hulu Atas"
  },
  {
    "id": "9103162002",
    "district_id": "910316",
    "label": "Pagai",
    "value": "Pagai"
  },
  {
    "id": "9103162003",
    "district_id": "910316",
    "label": "Aurina",
    "value": "Aurina"
  },
  {
    "id": "9103162004",
    "district_id": "910316",
    "label": "Muara Nawa",
    "value": "Muara Nawa"
  },
  {
    "id": "9103162005",
    "district_id": "910316",
    "label": "Kamikaru",
    "value": "Kamikaru"
  },
  {
    "id": "9103162006",
    "district_id": "910316",
    "label": "Naira",
    "value": "Naira"
  },
  {
    "id": "9103172001",
    "district_id": "910317",
    "label": "Yongsu Sapari",
    "value": "Yongsu Sapari"
  },
  {
    "id": "9103172002",
    "district_id": "910317",
    "label": "Yongsu Dosoyo",
    "value": "Yongsu Dosoyo"
  },
  {
    "id": "9103172003",
    "district_id": "910317",
    "label": "Newa",
    "value": "Newa"
  },
  {
    "id": "9103172004",
    "district_id": "910317",
    "label": "Nehibe",
    "value": "Nehibe"
  },
  {
    "id": "9103182001",
    "district_id": "910318",
    "label": "Omon",
    "value": "Omon"
  },
  {
    "id": "9103182002",
    "district_id": "910318",
    "label": "Iwon",
    "value": "Iwon"
  },
  {
    "id": "9103182003",
    "district_id": "910318",
    "label": "Klaisu",
    "value": "Klaisu"
  },
  {
    "id": "9103182004",
    "district_id": "910318",
    "label": "Bangai",
    "value": "Bangai"
  },
  {
    "id": "9103192001",
    "district_id": "910319",
    "label": "Maruwai",
    "value": "Maruwai"
  },
  {
    "id": "9103192002",
    "district_id": "910319",
    "label": "Meukisi",
    "value": "Meukisi"
  },
  {
    "id": "9103192003",
    "district_id": "910319",
    "label": "Endokisi",
    "value": "Endokisi"
  },
  {
    "id": "9103192005",
    "district_id": "910319",
    "label": "Buseryo",
    "value": "Buseryo"
  },
  {
    "id": "9103192006",
    "district_id": "910319",
    "label": "Senamay",
    "value": "Senamay"
  },
  {
    "id": "9104011001",
    "district_id": "910401",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "9104011002",
    "district_id": "910401",
    "label": "Kali Bobo",
    "value": "Kali Bobo"
  },
  {
    "id": "9104011003",
    "district_id": "910401",
    "label": "Morgo",
    "value": "Morgo"
  },
  {
    "id": "9104011004",
    "district_id": "910401",
    "label": "Girimulyo",
    "value": "Girimulyo"
  },
  {
    "id": "9104011005",
    "district_id": "910401",
    "label": "Karang Tumaritis",
    "value": "Karang Tumaritis"
  },
  {
    "id": "9104011006",
    "district_id": "910401",
    "label": "Oyehe",
    "value": "Oyehe"
  },
  {
    "id": "9104011007",
    "district_id": "910401",
    "label": "Karang Mulia",
    "value": "Karang Mulia"
  },
  {
    "id": "9104011009",
    "district_id": "910401",
    "label": "Nabarua",
    "value": "Nabarua"
  },
  {
    "id": "9104011010",
    "district_id": "910401",
    "label": "Siriwini",
    "value": "Siriwini"
  },
  {
    "id": "9104012011",
    "district_id": "910401",
    "label": "Kalisusu",
    "value": "Kalisusu"
  },
  {
    "id": "9104012012",
    "district_id": "910401",
    "label": "Kali Harapan",
    "value": "Kali Harapan"
  },
  {
    "id": "9104012013",
    "district_id": "910401",
    "label": "Sanoba",
    "value": "Sanoba"
  },
  {
    "id": "9104022001",
    "district_id": "910402",
    "label": "Napan",
    "value": "Napan"
  },
  {
    "id": "9104022004",
    "district_id": "910402",
    "label": "Masipawa",
    "value": "Masipawa"
  },
  {
    "id": "9104022005",
    "district_id": "910402",
    "label": "Weinami",
    "value": "Weinami"
  },
  {
    "id": "9104032001",
    "district_id": "910403",
    "label": "Yaur",
    "value": "Yaur"
  },
  {
    "id": "9104032002",
    "district_id": "910403",
    "label": "Akudiomi",
    "value": "Akudiomi"
  },
  {
    "id": "9104032004",
    "district_id": "910403",
    "label": "Sima",
    "value": "Sima"
  },
  {
    "id": "9104032008",
    "district_id": "910403",
    "label": "Wami",
    "value": "Wami"
  },
  {
    "id": "9104062005",
    "district_id": "910406",
    "label": "Topo",
    "value": "Topo"
  },
  {
    "id": "9104062013",
    "district_id": "910406",
    "label": "Urumusu",
    "value": "Urumusu"
  },
  {
    "id": "9104062015",
    "district_id": "910406",
    "label": "Marga Jaya",
    "value": "Marga Jaya"
  },
  {
    "id": "9104062016",
    "district_id": "910406",
    "label": "Argo Mulyo",
    "value": "Argo Mulyo"
  },
  {
    "id": "9104062017",
    "district_id": "910406",
    "label": "Topo Jaya",
    "value": "Topo Jaya"
  },
  {
    "id": "9104062018",
    "district_id": "910406",
    "label": "Gamei Jaya",
    "value": "Gamei Jaya"
  },
  {
    "id": "9104072001",
    "district_id": "910407",
    "label": "Bumi Mulia",
    "value": "Bumi Mulia"
  },
  {
    "id": "9104072010",
    "district_id": "910407",
    "label": "Karadiri",
    "value": "Karadiri"
  },
  {
    "id": "9104072013",
    "district_id": "910407",
    "label": "Wanggar Makmur",
    "value": "Wanggar Makmur"
  },
  {
    "id": "9104102001",
    "district_id": "910410",
    "label": "Unipo",
    "value": "Unipo"
  },
  {
    "id": "9104102002",
    "district_id": "910410",
    "label": "Aibore",
    "value": "Aibore"
  },
  {
    "id": "9104102003",
    "district_id": "910410",
    "label": "Epomani",
    "value": "Epomani"
  },
  {
    "id": "9104102004",
    "district_id": "910410",
    "label": "Tibai",
    "value": "Tibai"
  },
  {
    "id": "9104102005",
    "district_id": "910410",
    "label": "Mabou",
    "value": "Mabou"
  },
  {
    "id": "9104102006",
    "district_id": "910410",
    "label": "Ugida",
    "value": "Ugida"
  },
  {
    "id": "9104112001",
    "district_id": "910411",
    "label": "Makimi",
    "value": "Makimi"
  },
  {
    "id": "9104112002",
    "district_id": "910411",
    "label": "Nifasi",
    "value": "Nifasi"
  },
  {
    "id": "9104112003",
    "district_id": "910411",
    "label": "Biha",
    "value": "Biha"
  },
  {
    "id": "9104112004",
    "district_id": "910411",
    "label": "Lagari Jaya",
    "value": "Lagari Jaya"
  },
  {
    "id": "9104112005",
    "district_id": "910411",
    "label": "Maidei",
    "value": "Maidei"
  },
  {
    "id": "9104112006",
    "district_id": "910411",
    "label": "Manunggal Jaya",
    "value": "Manunggal Jaya"
  },
  {
    "id": "9104122001",
    "district_id": "910412",
    "label": "Napan Yaur",
    "value": "Napan Yaur"
  },
  {
    "id": "9104122002",
    "district_id": "910412",
    "label": "Yeretuar",
    "value": "Yeretuar"
  },
  {
    "id": "9104122003",
    "district_id": "910412",
    "label": "Bawei",
    "value": "Bawei"
  },
  {
    "id": "9104122004",
    "district_id": "910412",
    "label": "Goni",
    "value": "Goni"
  },
  {
    "id": "9104162001",
    "district_id": "910416",
    "label": "Samabusa",
    "value": "Samabusa"
  },
  {
    "id": "9104162002",
    "district_id": "910416",
    "label": "Waharia",
    "value": "Waharia"
  },
  {
    "id": "9104162003",
    "district_id": "910416",
    "label": "Kimi",
    "value": "Kimi"
  },
  {
    "id": "9104162004",
    "district_id": "910416",
    "label": "Lani",
    "value": "Lani"
  },
  {
    "id": "9104162005",
    "district_id": "910416",
    "label": "Air Mandidi",
    "value": "Air Mandidi"
  },
  {
    "id": "9104172001",
    "district_id": "910417",
    "label": "Wanggar Sari",
    "value": "Wanggar Sari"
  },
  {
    "id": "9104172002",
    "district_id": "910417",
    "label": "Wiraska",
    "value": "Wiraska"
  },
  {
    "id": "9104172003",
    "district_id": "910417",
    "label": "Yaro Makmur",
    "value": "Yaro Makmur"
  },
  {
    "id": "9104172004",
    "district_id": "910417",
    "label": "Jaya Mukti",
    "value": "Jaya Mukti"
  },
  {
    "id": "9104172005",
    "district_id": "910417",
    "label": "Wanggar Pantai",
    "value": "Wanggar Pantai"
  },
  {
    "id": "9104172006",
    "district_id": "910417",
    "label": "Ororodo",
    "value": "Ororodo"
  },
  {
    "id": "9104172007",
    "district_id": "910417",
    "label": "Bomopai",
    "value": "Bomopai"
  },
  {
    "id": "9104172008",
    "district_id": "910417",
    "label": "Parauto",
    "value": "Parauto"
  },
  {
    "id": "9104212001",
    "district_id": "910421",
    "label": "Totoberi",
    "value": "Totoberi"
  },
  {
    "id": "9104212002",
    "district_id": "910421",
    "label": "Samanui",
    "value": "Samanui"
  },
  {
    "id": "9104212003",
    "district_id": "910421",
    "label": "Kamarisano",
    "value": "Kamarisano"
  },
  {
    "id": "9104212004",
    "district_id": "910421",
    "label": "Taumi",
    "value": "Taumi"
  },
  {
    "id": "9104212005",
    "district_id": "910421",
    "label": "Keuw",
    "value": "Keuw"
  },
  {
    "id": "9104222001",
    "district_id": "910422",
    "label": "Bumi Raya",
    "value": "Bumi Raya"
  },
  {
    "id": "9104222002",
    "district_id": "910422",
    "label": "Waroki",
    "value": "Waroki"
  },
  {
    "id": "9104222003",
    "district_id": "910422",
    "label": "Gerbang Sadu",
    "value": "Gerbang Sadu"
  },
  {
    "id": "9104222004",
    "district_id": "910422",
    "label": "Kali Semen",
    "value": "Kali Semen"
  },
  {
    "id": "9104222005",
    "district_id": "910422",
    "label": "Wadio",
    "value": "Wadio"
  },
  {
    "id": "9104232001",
    "district_id": "910423",
    "label": "Mambor",
    "value": "Mambor"
  },
  {
    "id": "9104232002",
    "district_id": "910423",
    "label": "Hariti",
    "value": "Hariti"
  },
  {
    "id": "9104232003",
    "district_id": "910423",
    "label": "Moor",
    "value": "Moor"
  },
  {
    "id": "9104232004",
    "district_id": "910423",
    "label": "Kama",
    "value": "Kama"
  },
  {
    "id": "9104232005",
    "district_id": "910423",
    "label": "Arui",
    "value": "Arui"
  },
  {
    "id": "9104242001",
    "district_id": "910424",
    "label": "Epouwa",
    "value": "Epouwa"
  },
  {
    "id": "9104242002",
    "district_id": "910424",
    "label": "Dikiya",
    "value": "Dikiya"
  },
  {
    "id": "9104242003",
    "district_id": "910424",
    "label": "Tagauto",
    "value": "Tagauto"
  },
  {
    "id": "9104242004",
    "district_id": "910424",
    "label": "Jigikebo",
    "value": "Jigikebo"
  },
  {
    "id": "9104242005",
    "district_id": "910424",
    "label": "Jainoa",
    "value": "Jainoa"
  },
  {
    "id": "9104252001",
    "district_id": "910425",
    "label": "Kunupi",
    "value": "Kunupi"
  },
  {
    "id": "9104252002",
    "district_id": "910425",
    "label": "Ogiyai",
    "value": "Ogiyai"
  },
  {
    "id": "9104252003",
    "district_id": "910425",
    "label": "Lokodimi",
    "value": "Lokodimi"
  },
  {
    "id": "9104252004",
    "district_id": "910425",
    "label": "Yegeugi",
    "value": "Yegeugi"
  },
  {
    "id": "9105011004",
    "district_id": "910501",
    "label": "Tarau",
    "value": "Tarau"
  },
  {
    "id": "9105011005",
    "district_id": "910501",
    "label": "Serui Kota",
    "value": "Serui Kota"
  },
  {
    "id": "9105011006",
    "district_id": "910501",
    "label": "Serui Jaya",
    "value": "Serui Jaya"
  },
  {
    "id": "9105012014",
    "district_id": "910501",
    "label": "Serui Laut",
    "value": "Serui Laut"
  },
  {
    "id": "9105012015",
    "district_id": "910501",
    "label": "Banawa",
    "value": "Banawa"
  },
  {
    "id": "9105012017",
    "district_id": "910501",
    "label": "Turu",
    "value": "Turu"
  },
  {
    "id": "9105012019",
    "district_id": "910501",
    "label": "Pasir Hitam",
    "value": "Pasir Hitam"
  },
  {
    "id": "9105012020",
    "district_id": "910501",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9105012023",
    "district_id": "910501",
    "label": "Manaini",
    "value": "Manaini"
  },
  {
    "id": "9105012024",
    "district_id": "910501",
    "label": "Imandoa",
    "value": "Imandoa"
  },
  {
    "id": "9105012026",
    "district_id": "910501",
    "label": "Barawaikap",
    "value": "Barawaikap"
  },
  {
    "id": "9105012027",
    "district_id": "910501",
    "label": "Bawai",
    "value": "Bawai"
  },
  {
    "id": "9105012028",
    "district_id": "910501",
    "label": "Nundawipi",
    "value": "Nundawipi"
  },
  {
    "id": "9105021003",
    "district_id": "910502",
    "label": "Ansus",
    "value": "Ansus"
  },
  {
    "id": "9105022002",
    "district_id": "910502",
    "label": "Webi",
    "value": "Webi"
  },
  {
    "id": "9105022004",
    "district_id": "910502",
    "label": "Papuama",
    "value": "Papuama"
  },
  {
    "id": "9105022007",
    "district_id": "910502",
    "label": "Kairawi",
    "value": "Kairawi"
  },
  {
    "id": "9105022008",
    "district_id": "910502",
    "label": "Natabui",
    "value": "Natabui"
  },
  {
    "id": "9105022010",
    "district_id": "910502",
    "label": "Warabori",
    "value": "Warabori"
  },
  {
    "id": "9105022012",
    "district_id": "910502",
    "label": "Wimoni",
    "value": "Wimoni"
  },
  {
    "id": "9105022013",
    "district_id": "910502",
    "label": "Sasawa",
    "value": "Sasawa"
  },
  {
    "id": "9105022015",
    "district_id": "910502",
    "label": "Inowa",
    "value": "Inowa"
  },
  {
    "id": "9105022016",
    "district_id": "910502",
    "label": "Maniri",
    "value": "Maniri"
  },
  {
    "id": "9105022017",
    "district_id": "910502",
    "label": "Nuiwiora",
    "value": "Nuiwiora"
  },
  {
    "id": "9105022018",
    "district_id": "910502",
    "label": "Yenusi Marau",
    "value": "Yenusi Marau"
  },
  {
    "id": "9105022019",
    "district_id": "910502",
    "label": "Marau",
    "value": "Marau"
  },
  {
    "id": "9105022020",
    "district_id": "910502",
    "label": "Toweta",
    "value": "Toweta"
  },
  {
    "id": "9105022021",
    "district_id": "910502",
    "label": "Woiwani",
    "value": "Woiwani"
  },
  {
    "id": "9105022022",
    "district_id": "910502",
    "label": "Yarori",
    "value": "Yarori"
  },
  {
    "id": "9105022023",
    "district_id": "910502",
    "label": "Narei",
    "value": "Narei"
  },
  {
    "id": "9105032004",
    "district_id": "910503",
    "label": "Korombobi",
    "value": "Korombobi"
  },
  {
    "id": "9105032005",
    "district_id": "910503",
    "label": "Dawai",
    "value": "Dawai"
  },
  {
    "id": "9105032010",
    "district_id": "910503",
    "label": "Wabo",
    "value": "Wabo"
  },
  {
    "id": "9105032014",
    "district_id": "910503",
    "label": "Nunsembai",
    "value": "Nunsembai"
  },
  {
    "id": "9105032015",
    "district_id": "910503",
    "label": "Nunsiari",
    "value": "Nunsiari"
  },
  {
    "id": "9105032023",
    "district_id": "910503",
    "label": "Woinsupi",
    "value": "Woinsupi"
  },
  {
    "id": "9105032024",
    "district_id": "910503",
    "label": "Awunawai",
    "value": "Awunawai"
  },
  {
    "id": "9105032025",
    "district_id": "910503",
    "label": "Mareruni",
    "value": "Mareruni"
  },
  {
    "id": "9105032031",
    "district_id": "910503",
    "label": "Wabompi",
    "value": "Wabompi"
  },
  {
    "id": "9105032036",
    "district_id": "910503",
    "label": "Duai",
    "value": "Duai"
  },
  {
    "id": "9105032037",
    "district_id": "910503",
    "label": "Sere Sere",
    "value": "Sere Sere"
  },
  {
    "id": "9105042003",
    "district_id": "910504",
    "label": "Menawi",
    "value": "Menawi"
  },
  {
    "id": "9105042004",
    "district_id": "910504",
    "label": "Wadapi",
    "value": "Wadapi"
  },
  {
    "id": "9105042009",
    "district_id": "910504",
    "label": "Kainui I",
    "value": "Kainui I"
  },
  {
    "id": "9105042010",
    "district_id": "910504",
    "label": "Aitiri",
    "value": "Aitiri"
  },
  {
    "id": "9105042011",
    "district_id": "910504",
    "label": "Wawuti",
    "value": "Wawuti"
  },
  {
    "id": "9105042015",
    "district_id": "910504",
    "label": "Roipi",
    "value": "Roipi"
  },
  {
    "id": "9105042016",
    "district_id": "910504",
    "label": "Ransarnoni",
    "value": "Ransarnoni"
  },
  {
    "id": "9105042021",
    "district_id": "910504",
    "label": "Kainui II",
    "value": "Kainui II"
  },
  {
    "id": "9105042024",
    "district_id": "910504",
    "label": "Wanampompi",
    "value": "Wanampompi"
  },
  {
    "id": "9105042028",
    "district_id": "910504",
    "label": "Mananayam",
    "value": "Mananayam"
  },
  {
    "id": "9105042030",
    "district_id": "910504",
    "label": "Roipi Dua",
    "value": "Roipi Dua"
  },
  {
    "id": "9105052001",
    "district_id": "910505",
    "label": "Poom I",
    "value": "Poom I"
  },
  {
    "id": "9105052003",
    "district_id": "910505",
    "label": "Serewen",
    "value": "Serewen"
  },
  {
    "id": "9105052006",
    "district_id": "910505",
    "label": "Poom II",
    "value": "Poom II"
  },
  {
    "id": "9105052007",
    "district_id": "910505",
    "label": "Mokiroan",
    "value": "Mokiroan"
  },
  {
    "id": "9105052011",
    "district_id": "910505",
    "label": "Rarisi",
    "value": "Rarisi"
  },
  {
    "id": "9105052012",
    "district_id": "910505",
    "label": "Warioi",
    "value": "Warioi"
  },
  {
    "id": "9105052015",
    "district_id": "910505",
    "label": "Humbe Awai",
    "value": "Humbe Awai"
  },
  {
    "id": "9105052016",
    "district_id": "910505",
    "label": "Nurawi",
    "value": "Nurawi"
  },
  {
    "id": "9105062001",
    "district_id": "910506",
    "label": "Kamanap",
    "value": "Kamanap"
  },
  {
    "id": "9105062002",
    "district_id": "910506",
    "label": "Aromarea",
    "value": "Aromarea"
  },
  {
    "id": "9105062003",
    "district_id": "910506",
    "label": "Maria Rotu",
    "value": "Maria Rotu"
  },
  {
    "id": "9105062004",
    "district_id": "910506",
    "label": "Kanawa",
    "value": "Kanawa"
  },
  {
    "id": "9105062005",
    "district_id": "910506",
    "label": "Tatui",
    "value": "Tatui"
  },
  {
    "id": "9105062006",
    "district_id": "910506",
    "label": "Ariepi",
    "value": "Ariepi"
  },
  {
    "id": "9105062007",
    "district_id": "910506",
    "label": "Sarawandori",
    "value": "Sarawandori"
  },
  {
    "id": "9105062008",
    "district_id": "910506",
    "label": "Ambaidiru",
    "value": "Ambaidiru"
  },
  {
    "id": "9105062009",
    "district_id": "910506",
    "label": "Mambo",
    "value": "Mambo"
  },
  {
    "id": "9105062010",
    "district_id": "910506",
    "label": "Manainin",
    "value": "Manainin"
  },
  {
    "id": "9105062011",
    "district_id": "910506",
    "label": "Numaman",
    "value": "Numaman"
  },
  {
    "id": "9105062012",
    "district_id": "910506",
    "label": "Ramangkurani",
    "value": "Ramangkurani"
  },
  {
    "id": "9105062013",
    "district_id": "910506",
    "label": "Sarawandori Dua",
    "value": "Sarawandori Dua"
  },
  {
    "id": "9105062014",
    "district_id": "910506",
    "label": "Ariepi Dua",
    "value": "Ariepi Dua"
  },
  {
    "id": "9105062015",
    "district_id": "910506",
    "label": "Panduami",
    "value": "Panduami"
  },
  {
    "id": "9105072001",
    "district_id": "910507",
    "label": "Tindaret",
    "value": "Tindaret"
  },
  {
    "id": "9105072002",
    "district_id": "910507",
    "label": "Yobi",
    "value": "Yobi"
  },
  {
    "id": "9105072003",
    "district_id": "910507",
    "label": "Sambrawai",
    "value": "Sambrawai"
  },
  {
    "id": "9105072006",
    "district_id": "910507",
    "label": "Kiriyou",
    "value": "Kiriyou"
  },
  {
    "id": "9105072007",
    "district_id": "910507",
    "label": "Soromasen",
    "value": "Soromasen"
  },
  {
    "id": "9105072009",
    "district_id": "910507",
    "label": "Roswari",
    "value": "Roswari"
  },
  {
    "id": "9105072010",
    "district_id": "910507",
    "label": "Doreimanona",
    "value": "Doreimanona"
  },
  {
    "id": "9105082001",
    "district_id": "910508",
    "label": "Waindu",
    "value": "Waindu"
  },
  {
    "id": "9105082008",
    "district_id": "910508",
    "label": "Barawai",
    "value": "Barawai"
  },
  {
    "id": "9105082009",
    "district_id": "910508",
    "label": "Woda",
    "value": "Woda"
  },
  {
    "id": "9105082010",
    "district_id": "910508",
    "label": "Kororompui",
    "value": "Kororompui"
  },
  {
    "id": "9105082011",
    "district_id": "910508",
    "label": "Aisau",
    "value": "Aisau"
  },
  {
    "id": "9105082012",
    "district_id": "910508",
    "label": "Sewenui",
    "value": "Sewenui"
  },
  {
    "id": "9105082013",
    "district_id": "910508",
    "label": "Sawendui",
    "value": "Sawendui"
  },
  {
    "id": "9105092001",
    "district_id": "910509",
    "label": "Randawaya",
    "value": "Randawaya"
  },
  {
    "id": "9105092002",
    "district_id": "910509",
    "label": "Warironi",
    "value": "Warironi"
  },
  {
    "id": "9105092003",
    "district_id": "910509",
    "label": "Ampimoi",
    "value": "Ampimoi"
  },
  {
    "id": "9105092004",
    "district_id": "910509",
    "label": "Waita",
    "value": "Waita"
  },
  {
    "id": "9105092005",
    "district_id": "910509",
    "label": "Ayari",
    "value": "Ayari"
  },
  {
    "id": "9105092006",
    "district_id": "910509",
    "label": "Koroapi",
    "value": "Koroapi"
  },
  {
    "id": "9105092007",
    "district_id": "910509",
    "label": "Wabuayar",
    "value": "Wabuayar"
  },
  {
    "id": "9105092008",
    "district_id": "910509",
    "label": "Tarei",
    "value": "Tarei"
  },
  {
    "id": "9105092009",
    "district_id": "910509",
    "label": "Bareraif",
    "value": "Bareraif"
  },
  {
    "id": "9105092010",
    "district_id": "910509",
    "label": "Arareni",
    "value": "Arareni"
  },
  {
    "id": "9105092011",
    "district_id": "910509",
    "label": "Siromi",
    "value": "Siromi"
  },
  {
    "id": "9105102001",
    "district_id": "910510",
    "label": "Ambai I",
    "value": "Ambai I"
  },
  {
    "id": "9105102002",
    "district_id": "910510",
    "label": "Saweru",
    "value": "Saweru"
  },
  {
    "id": "9105102004",
    "district_id": "910510",
    "label": "Kawipi",
    "value": "Kawipi"
  },
  {
    "id": "9105102005",
    "district_id": "910510",
    "label": "Wamori",
    "value": "Wamori"
  },
  {
    "id": "9105102006",
    "district_id": "910510",
    "label": "Adiwipi",
    "value": "Adiwipi"
  },
  {
    "id": "9105102007",
    "district_id": "910510",
    "label": "Umani",
    "value": "Umani"
  },
  {
    "id": "9105102008",
    "district_id": "910510",
    "label": "Ambai II",
    "value": "Ambai II"
  },
  {
    "id": "9105102009",
    "district_id": "910510",
    "label": "Mambawi",
    "value": "Mambawi"
  },
  {
    "id": "9105102010",
    "district_id": "910510",
    "label": "Baisore",
    "value": "Baisore"
  },
  {
    "id": "9105102011",
    "district_id": "910510",
    "label": "Rondepi",
    "value": "Rondepi"
  },
  {
    "id": "9105102012",
    "district_id": "910510",
    "label": "Dorau",
    "value": "Dorau"
  },
  {
    "id": "9105102013",
    "district_id": "910510",
    "label": "Perea",
    "value": "Perea"
  },
  {
    "id": "9105102014",
    "district_id": "910510",
    "label": "Imboriawa",
    "value": "Imboriawa"
  },
  {
    "id": "9105102015",
    "district_id": "910510",
    "label": "Bairei",
    "value": "Bairei"
  },
  {
    "id": "9105102016",
    "district_id": "910510",
    "label": "Aiwaraggani",
    "value": "Aiwaraggani"
  },
  {
    "id": "9105102017",
    "district_id": "910510",
    "label": "Toroa",
    "value": "Toroa"
  },
  {
    "id": "9105102018",
    "district_id": "910510",
    "label": "Nuniandei",
    "value": "Nuniandei"
  },
  {
    "id": "9105102019",
    "district_id": "910510",
    "label": "Marawi",
    "value": "Marawi"
  },
  {
    "id": "9105112001",
    "district_id": "910511",
    "label": "Wooi",
    "value": "Wooi"
  },
  {
    "id": "9105112002",
    "district_id": "910511",
    "label": "Woinap",
    "value": "Woinap"
  },
  {
    "id": "9105112003",
    "district_id": "910511",
    "label": "Aibondeni",
    "value": "Aibondeni"
  },
  {
    "id": "9105112004",
    "district_id": "910511",
    "label": "Kanaki",
    "value": "Kanaki"
  },
  {
    "id": "9105112005",
    "district_id": "910511",
    "label": "Dumani",
    "value": "Dumani"
  },
  {
    "id": "9105112007",
    "district_id": "910511",
    "label": "Karemoni",
    "value": "Karemoni"
  },
  {
    "id": "9105112008",
    "district_id": "910511",
    "label": "Haihorei",
    "value": "Haihorei"
  },
  {
    "id": "9105112009",
    "district_id": "910511",
    "label": "Rembai",
    "value": "Rembai"
  },
  {
    "id": "9105112010",
    "district_id": "910511",
    "label": "Jaimaria",
    "value": "Jaimaria"
  },
  {
    "id": "9105112011",
    "district_id": "910511",
    "label": "Awado",
    "value": "Awado"
  },
  {
    "id": "9105122001",
    "district_id": "910512",
    "label": "Kaonda",
    "value": "Kaonda"
  },
  {
    "id": "9105122002",
    "district_id": "910512",
    "label": "Windesi",
    "value": "Windesi"
  },
  {
    "id": "9105122003",
    "district_id": "910512",
    "label": "Munggui",
    "value": "Munggui"
  },
  {
    "id": "9105122004",
    "district_id": "910512",
    "label": "Karawi",
    "value": "Karawi"
  },
  {
    "id": "9105122005",
    "district_id": "910512",
    "label": "Asai",
    "value": "Asai"
  },
  {
    "id": "9105122006",
    "district_id": "910512",
    "label": "Rosbori",
    "value": "Rosbori"
  },
  {
    "id": "9105122007",
    "district_id": "910512",
    "label": "Waisani",
    "value": "Waisani"
  },
  {
    "id": "9105122008",
    "district_id": "910512",
    "label": "Saruman",
    "value": "Saruman"
  },
  {
    "id": "9105122009",
    "district_id": "910512",
    "label": "Aryobu",
    "value": "Aryobu"
  },
  {
    "id": "9105132001",
    "district_id": "910513",
    "label": "Kaipuri",
    "value": "Kaipuri"
  },
  {
    "id": "9105132002",
    "district_id": "910513",
    "label": "Doreianmini",
    "value": "Doreianmini"
  },
  {
    "id": "9105132003",
    "district_id": "910513",
    "label": "Andersaria",
    "value": "Andersaria"
  },
  {
    "id": "9105132004",
    "district_id": "910513",
    "label": "Kurudu",
    "value": "Kurudu"
  },
  {
    "id": "9105132005",
    "district_id": "910513",
    "label": "Kirimbri",
    "value": "Kirimbri"
  },
  {
    "id": "9105132006",
    "district_id": "910513",
    "label": "Manusundu",
    "value": "Manusundu"
  },
  {
    "id": "9105132007",
    "district_id": "910513",
    "label": "Mnukwar",
    "value": "Mnukwar"
  },
  {
    "id": "9105132008",
    "district_id": "910513",
    "label": "Mansesi",
    "value": "Mansesi"
  },
  {
    "id": "9105142001",
    "district_id": "910514",
    "label": "Miosnum",
    "value": "Miosnum"
  },
  {
    "id": "9105142002",
    "district_id": "910514",
    "label": "Jeniari",
    "value": "Jeniari"
  },
  {
    "id": "9105142003",
    "district_id": "910514",
    "label": "Umpeki",
    "value": "Umpeki"
  },
  {
    "id": "9105142004",
    "district_id": "910514",
    "label": "Ausem",
    "value": "Ausem"
  },
  {
    "id": "9105142005",
    "district_id": "910514",
    "label": "Yeituarau",
    "value": "Yeituarau"
  },
  {
    "id": "9105151001",
    "district_id": "910515",
    "label": "Anatorei",
    "value": "Anatorei"
  },
  {
    "id": "9105152001",
    "district_id": "910515",
    "label": "Ketu Api",
    "value": "Ketu Api"
  },
  {
    "id": "9105152002",
    "district_id": "910515",
    "label": "Mariaderi",
    "value": "Mariaderi"
  },
  {
    "id": "9105152003",
    "district_id": "910515",
    "label": "Yapan",
    "value": "Yapan"
  },
  {
    "id": "9105152004",
    "district_id": "910515",
    "label": "Mantembu",
    "value": "Mantembu"
  },
  {
    "id": "9105152005",
    "district_id": "910515",
    "label": "Famboaman",
    "value": "Famboaman"
  },
  {
    "id": "9105152006",
    "district_id": "910515",
    "label": "Kandowarira",
    "value": "Kandowarira"
  },
  {
    "id": "9105152007",
    "district_id": "910515",
    "label": "Warari",
    "value": "Warari"
  },
  {
    "id": "9105162001",
    "district_id": "910516",
    "label": "Kontinuai",
    "value": "Kontinuai"
  },
  {
    "id": "9105162002",
    "district_id": "910516",
    "label": "Woniwon",
    "value": "Woniwon"
  },
  {
    "id": "9105162003",
    "district_id": "910516",
    "label": "Sanayoka",
    "value": "Sanayoka"
  },
  {
    "id": "9105162004",
    "district_id": "910516",
    "label": "Rambai",
    "value": "Rambai"
  },
  {
    "id": "9105162005",
    "district_id": "910516",
    "label": "Yafanani",
    "value": "Yafanani"
  },
  {
    "id": "9105162006",
    "district_id": "910516",
    "label": "Borai",
    "value": "Borai"
  },
  {
    "id": "9105162007",
    "district_id": "910516",
    "label": "Kabuaena",
    "value": "Kabuaena"
  },
  {
    "id": "9106011001",
    "district_id": "910601",
    "label": "Sorido",
    "value": "Sorido"
  },
  {
    "id": "9106011002",
    "district_id": "910601",
    "label": "Waupnor",
    "value": "Waupnor"
  },
  {
    "id": "9106011003",
    "district_id": "910601",
    "label": "Saramon",
    "value": "Saramon"
  },
  {
    "id": "9106011004",
    "district_id": "910601",
    "label": "Burokup",
    "value": "Burokup"
  },
  {
    "id": "9106011005",
    "district_id": "910601",
    "label": "Fandoi",
    "value": "Fandoi"
  },
  {
    "id": "9106011006",
    "district_id": "910601",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "9106011016",
    "district_id": "910601",
    "label": "Yenures",
    "value": "Yenures"
  },
  {
    "id": "9106012007",
    "district_id": "910601",
    "label": "Mnubabo",
    "value": "Mnubabo"
  },
  {
    "id": "9106012008",
    "district_id": "910601",
    "label": "Swapodibo",
    "value": "Swapodibo"
  },
  {
    "id": "9106012009",
    "district_id": "910601",
    "label": "Mokmer",
    "value": "Mokmer"
  },
  {
    "id": "9106012010",
    "district_id": "910601",
    "label": "Inggiri",
    "value": "Inggiri"
  },
  {
    "id": "9106012011",
    "district_id": "910601",
    "label": "Parai",
    "value": "Parai"
  },
  {
    "id": "9106012012",
    "district_id": "910601",
    "label": "Samau",
    "value": "Samau"
  },
  {
    "id": "9106012013",
    "district_id": "910601",
    "label": "Insrom",
    "value": "Insrom"
  },
  {
    "id": "9106012014",
    "district_id": "910601",
    "label": "Anggraidi",
    "value": "Anggraidi"
  },
  {
    "id": "9106012015",
    "district_id": "910601",
    "label": "Manswam",
    "value": "Manswam"
  },
  {
    "id": "9106012017",
    "district_id": "910601",
    "label": "Sanumi",
    "value": "Sanumi"
  },
  {
    "id": "9106012018",
    "district_id": "910601",
    "label": "Manggandisapi",
    "value": "Manggandisapi"
  },
  {
    "id": "9106012019",
    "district_id": "910601",
    "label": "Karyendi",
    "value": "Karyendi"
  },
  {
    "id": "9106012020",
    "district_id": "910601",
    "label": "Kababur",
    "value": "Kababur"
  },
  {
    "id": "9106012021",
    "district_id": "910601",
    "label": "Babrinbo",
    "value": "Babrinbo"
  },
  {
    "id": "9106012022",
    "district_id": "910601",
    "label": "Inggupi",
    "value": "Inggupi"
  },
  {
    "id": "9106022001",
    "district_id": "910602",
    "label": "Sarwom",
    "value": "Sarwom"
  },
  {
    "id": "9106022002",
    "district_id": "910602",
    "label": "Andei",
    "value": "Andei"
  },
  {
    "id": "9106022004",
    "district_id": "910602",
    "label": "Waromi",
    "value": "Waromi"
  },
  {
    "id": "9106022006",
    "district_id": "910602",
    "label": "Kobeoser",
    "value": "Kobeoser"
  },
  {
    "id": "9106022007",
    "district_id": "910602",
    "label": "Mambesak",
    "value": "Mambesak"
  },
  {
    "id": "9106022008",
    "district_id": "910602",
    "label": "Saukobye",
    "value": "Saukobye"
  },
  {
    "id": "9106022010",
    "district_id": "910602",
    "label": "Yobdi",
    "value": "Yobdi"
  },
  {
    "id": "9106022012",
    "district_id": "910602",
    "label": "Dernafi",
    "value": "Dernafi"
  },
  {
    "id": "9106022013",
    "district_id": "910602",
    "label": "Rosayendi",
    "value": "Rosayendi"
  },
  {
    "id": "9106022016",
    "district_id": "910602",
    "label": "Warsansan",
    "value": "Warsansan"
  },
  {
    "id": "9106022017",
    "district_id": "910602",
    "label": "Nermnu",
    "value": "Nermnu"
  },
  {
    "id": "9106022018",
    "district_id": "910602",
    "label": "Korem",
    "value": "Korem"
  },
  {
    "id": "9106022019",
    "district_id": "910602",
    "label": "Mnuswor",
    "value": "Mnuswor"
  },
  {
    "id": "9106022020",
    "district_id": "910602",
    "label": "Dofyo Wafor",
    "value": "Dofyo Wafor"
  },
  {
    "id": "9106022021",
    "district_id": "910602",
    "label": "Warbon",
    "value": "Warbon"
  },
  {
    "id": "9106022022",
    "district_id": "910602",
    "label": "Wari",
    "value": "Wari"
  },
  {
    "id": "9106032002",
    "district_id": "910603",
    "label": "Rimba Jaya",
    "value": "Rimba Jaya"
  },
  {
    "id": "9106032003",
    "district_id": "910603",
    "label": "Owi",
    "value": "Owi"
  },
  {
    "id": "9106032004",
    "district_id": "910603",
    "label": "Woniki",
    "value": "Woniki"
  },
  {
    "id": "9106032005",
    "district_id": "910603",
    "label": "Kajasi",
    "value": "Kajasi"
  },
  {
    "id": "9106032010",
    "district_id": "910603",
    "label": "Sunde",
    "value": "Sunde"
  },
  {
    "id": "9106032011",
    "district_id": "910603",
    "label": "Sepse",
    "value": "Sepse"
  },
  {
    "id": "9106032012",
    "district_id": "910603",
    "label": "Soon",
    "value": "Soon"
  },
  {
    "id": "9106032013",
    "district_id": "910603",
    "label": "Sareidi",
    "value": "Sareidi"
  },
  {
    "id": "9106032014",
    "district_id": "910603",
    "label": "Bindusi",
    "value": "Bindusi"
  },
  {
    "id": "9106032015",
    "district_id": "910603",
    "label": "Orwer",
    "value": "Orwer"
  },
  {
    "id": "9106032016",
    "district_id": "910603",
    "label": "Yenusi",
    "value": "Yenusi"
  },
  {
    "id": "9106032017",
    "district_id": "910603",
    "label": "Kajasbo",
    "value": "Kajasbo"
  },
  {
    "id": "9106032021",
    "district_id": "910603",
    "label": "Ruar",
    "value": "Ruar"
  },
  {
    "id": "9106032022",
    "district_id": "910603",
    "label": "Mandon",
    "value": "Mandon"
  },
  {
    "id": "9106032023",
    "district_id": "910603",
    "label": "Soryar",
    "value": "Soryar"
  },
  {
    "id": "9106032028",
    "district_id": "910603",
    "label": "Insumarires",
    "value": "Insumarires"
  },
  {
    "id": "9106032030",
    "district_id": "910603",
    "label": "Rim",
    "value": "Rim"
  },
  {
    "id": "9106032031",
    "district_id": "910603",
    "label": "Adorbari",
    "value": "Adorbari"
  },
  {
    "id": "9106032032",
    "district_id": "910603",
    "label": "Bosnik Sup",
    "value": "Bosnik Sup"
  },
  {
    "id": "9106032033",
    "district_id": "910603",
    "label": "Inofi",
    "value": "Inofi"
  },
  {
    "id": "9106032034",
    "district_id": "910603",
    "label": "Yenberok",
    "value": "Yenberok"
  },
  {
    "id": "9106032035",
    "district_id": "910603",
    "label": "Wasori",
    "value": "Wasori"
  },
  {
    "id": "9106032036",
    "district_id": "910603",
    "label": "Yendakem",
    "value": "Yendakem"
  },
  {
    "id": "9106032037",
    "district_id": "910603",
    "label": "Waderbo",
    "value": "Waderbo"
  },
  {
    "id": "9106032038",
    "district_id": "910603",
    "label": "Afefbo",
    "value": "Afefbo"
  },
  {
    "id": "9106032039",
    "district_id": "910603",
    "label": "Inmdi",
    "value": "Inmdi"
  },
  {
    "id": "9106042003",
    "district_id": "910604",
    "label": "Namber",
    "value": "Namber"
  },
  {
    "id": "9106042004",
    "district_id": "910604",
    "label": "Baruki",
    "value": "Baruki"
  },
  {
    "id": "9106042005",
    "district_id": "910604",
    "label": "Kameri",
    "value": "Kameri"
  },
  {
    "id": "9106042008",
    "district_id": "910604",
    "label": "Warido",
    "value": "Warido"
  },
  {
    "id": "9106042012",
    "district_id": "910604",
    "label": "Sub Manggunsi",
    "value": "Sub Manggunsi"
  },
  {
    "id": "9106042013",
    "district_id": "910604",
    "label": "Serbin",
    "value": "Serbin"
  },
  {
    "id": "9106042014",
    "district_id": "910604",
    "label": "Kansai",
    "value": "Kansai"
  },
  {
    "id": "9106042015",
    "district_id": "910604",
    "label": "Pomdori",
    "value": "Pomdori"
  },
  {
    "id": "9106042019",
    "district_id": "910604",
    "label": "Semayen",
    "value": "Semayen"
  },
  {
    "id": "9106042020",
    "district_id": "910604",
    "label": "Mambodo Sawai",
    "value": "Mambodo Sawai"
  },
  {
    "id": "9106042021",
    "district_id": "910604",
    "label": "Rimbaraya",
    "value": "Rimbaraya"
  },
  {
    "id": "9106042022",
    "district_id": "910604",
    "label": "Pomdori Sup",
    "value": "Pomdori Sup"
  },
  {
    "id": "9106052006",
    "district_id": "910605",
    "label": "Yenburwo",
    "value": "Yenburwo"
  },
  {
    "id": "9106052011",
    "district_id": "910605",
    "label": "Kornasoren",
    "value": "Kornasoren"
  },
  {
    "id": "9106052014",
    "district_id": "910605",
    "label": "Rarsibo",
    "value": "Rarsibo"
  },
  {
    "id": "9106052015",
    "district_id": "910605",
    "label": "Asaryendi",
    "value": "Asaryendi"
  },
  {
    "id": "9106052016",
    "district_id": "910605",
    "label": "Yenmanu",
    "value": "Yenmanu"
  },
  {
    "id": "9106052017",
    "district_id": "910605",
    "label": "Pyefuri",
    "value": "Pyefuri"
  },
  {
    "id": "9106052018",
    "district_id": "910605",
    "label": "Indaibori",
    "value": "Indaibori"
  },
  {
    "id": "9106052019",
    "district_id": "910605",
    "label": "Barkori",
    "value": "Barkori"
  },
  {
    "id": "9106052020",
    "district_id": "910605",
    "label": "Asaibori",
    "value": "Asaibori"
  },
  {
    "id": "9106082001",
    "district_id": "910608",
    "label": "Andei",
    "value": "Andei"
  },
  {
    "id": "9106082002",
    "district_id": "910608",
    "label": "Kanaan",
    "value": "Kanaan"
  },
  {
    "id": "9106082003",
    "district_id": "910608",
    "label": "Asaryendi",
    "value": "Asaryendi"
  },
  {
    "id": "9106082004",
    "district_id": "910608",
    "label": "Sopendo",
    "value": "Sopendo"
  },
  {
    "id": "9106082006",
    "district_id": "910608",
    "label": "Ampunbukor",
    "value": "Ampunbukor"
  },
  {
    "id": "9106082008",
    "district_id": "910608",
    "label": "Wasyai",
    "value": "Wasyai"
  },
  {
    "id": "9106082009",
    "district_id": "910608",
    "label": "Kababur",
    "value": "Kababur"
  },
  {
    "id": "9106082010",
    "district_id": "910608",
    "label": "Warberik",
    "value": "Warberik"
  },
  {
    "id": "9106082011",
    "district_id": "910608",
    "label": "Sosmai",
    "value": "Sosmai"
  },
  {
    "id": "9106082012",
    "district_id": "910608",
    "label": "Yomdori",
    "value": "Yomdori"
  },
  {
    "id": "9106082013",
    "district_id": "910608",
    "label": "Opuri",
    "value": "Opuri"
  },
  {
    "id": "9106082014",
    "district_id": "910608",
    "label": "Mamoribo",
    "value": "Mamoribo"
  },
  {
    "id": "9106082021",
    "district_id": "910608",
    "label": "Insiri",
    "value": "Insiri"
  },
  {
    "id": "9106082024",
    "district_id": "910608",
    "label": "Asarkir",
    "value": "Asarkir"
  },
  {
    "id": "9106082025",
    "district_id": "910608",
    "label": "Dousi",
    "value": "Dousi"
  },
  {
    "id": "9106082026",
    "district_id": "910608",
    "label": "Marisen",
    "value": "Marisen"
  },
  {
    "id": "9106082027",
    "district_id": "910608",
    "label": "Sumbinya",
    "value": "Sumbinya"
  },
  {
    "id": "9106082029",
    "district_id": "910608",
    "label": "Rarsbari",
    "value": "Rarsbari"
  },
  {
    "id": "9106082030",
    "district_id": "910608",
    "label": "Banasrares",
    "value": "Banasrares"
  },
  {
    "id": "9106082031",
    "district_id": "910608",
    "label": "Karnindi",
    "value": "Karnindi"
  },
  {
    "id": "9106082032",
    "district_id": "910608",
    "label": "Dedifu",
    "value": "Dedifu"
  },
  {
    "id": "9106082033",
    "district_id": "910608",
    "label": "Sopendo Sup Karkir",
    "value": "Sopendo Sup Karkir"
  },
  {
    "id": "9106092003",
    "district_id": "910609",
    "label": "Marur",
    "value": "Marur"
  },
  {
    "id": "9106092004",
    "district_id": "910609",
    "label": "Diano",
    "value": "Diano"
  },
  {
    "id": "9106092005",
    "district_id": "910609",
    "label": "Sawai",
    "value": "Sawai"
  },
  {
    "id": "9106092006",
    "district_id": "910609",
    "label": "Aman",
    "value": "Aman"
  },
  {
    "id": "9106092007",
    "district_id": "910609",
    "label": "Yeruboi",
    "value": "Yeruboi"
  },
  {
    "id": "9106092008",
    "district_id": "910609",
    "label": "Amoi",
    "value": "Amoi"
  },
  {
    "id": "9106092009",
    "district_id": "910609",
    "label": "Inswambesi",
    "value": "Inswambesi"
  },
  {
    "id": "9106092010",
    "district_id": "910609",
    "label": "Koyomi",
    "value": "Koyomi"
  },
  {
    "id": "9106092011",
    "district_id": "910609",
    "label": "Wasani",
    "value": "Wasani"
  },
  {
    "id": "9106092017",
    "district_id": "910609",
    "label": "Karuiberik",
    "value": "Karuiberik"
  },
  {
    "id": "9106092018",
    "district_id": "910609",
    "label": "Komboi",
    "value": "Komboi"
  },
  {
    "id": "9106092020",
    "district_id": "910609",
    "label": "Inswambesi Sup",
    "value": "Inswambesi Sup"
  },
  {
    "id": "9106092021",
    "district_id": "910609",
    "label": "Inyobi",
    "value": "Inyobi"
  },
  {
    "id": "9106092023",
    "district_id": "910609",
    "label": "Maniri",
    "value": "Maniri"
  },
  {
    "id": "9106092024",
    "district_id": "910609",
    "label": "Imbari",
    "value": "Imbari"
  },
  {
    "id": "9106092025",
    "district_id": "910609",
    "label": "Manbeori",
    "value": "Manbeori"
  },
  {
    "id": "9106092026",
    "district_id": "910609",
    "label": "Warawaf",
    "value": "Warawaf"
  },
  {
    "id": "9106092027",
    "district_id": "910609",
    "label": "Wir Insos",
    "value": "Wir Insos"
  },
  {
    "id": "9106092028",
    "district_id": "910609",
    "label": "Biawer",
    "value": "Biawer"
  },
  {
    "id": "9106092029",
    "district_id": "910609",
    "label": "Mamfias",
    "value": "Mamfias"
  },
  {
    "id": "9106102001",
    "district_id": "910610",
    "label": "Pai",
    "value": "Pai"
  },
  {
    "id": "9106102002",
    "district_id": "910610",
    "label": "Auki",
    "value": "Auki"
  },
  {
    "id": "9106102003",
    "district_id": "910610",
    "label": "Wundi",
    "value": "Wundi"
  },
  {
    "id": "9106102004",
    "district_id": "910610",
    "label": "Nusi Babaruk",
    "value": "Nusi Babaruk"
  },
  {
    "id": "9106102005",
    "district_id": "910610",
    "label": "Nusi",
    "value": "Nusi"
  },
  {
    "id": "9106102014",
    "district_id": "910610",
    "label": "Inbeyomi",
    "value": "Inbeyomi"
  },
  {
    "id": "9106102015",
    "district_id": "910610",
    "label": "Sorina",
    "value": "Sorina"
  },
  {
    "id": "9106102016",
    "district_id": "910610",
    "label": "Sandidori",
    "value": "Sandidori"
  },
  {
    "id": "9106102020",
    "district_id": "910610",
    "label": "Paidori",
    "value": "Paidori"
  },
  {
    "id": "9106102021",
    "district_id": "910610",
    "label": "Kanai",
    "value": "Kanai"
  },
  {
    "id": "9106102022",
    "district_id": "910610",
    "label": "Sokani",
    "value": "Sokani"
  },
  {
    "id": "9106112001",
    "district_id": "910611",
    "label": "Yendidori",
    "value": "Yendidori"
  },
  {
    "id": "9106112002",
    "district_id": "910611",
    "label": "Adoki",
    "value": "Adoki"
  },
  {
    "id": "9106112003",
    "district_id": "910611",
    "label": "Urfu",
    "value": "Urfu"
  },
  {
    "id": "9106112004",
    "district_id": "910611",
    "label": "Samber",
    "value": "Samber"
  },
  {
    "id": "9106112005",
    "district_id": "910611",
    "label": "Kabidon",
    "value": "Kabidon"
  },
  {
    "id": "9106112006",
    "district_id": "910611",
    "label": "Sunyar",
    "value": "Sunyar"
  },
  {
    "id": "9106112007",
    "district_id": "910611",
    "label": "Maibaken",
    "value": "Maibaken"
  },
  {
    "id": "9106112008",
    "district_id": "910611",
    "label": "Wirmaker",
    "value": "Wirmaker"
  },
  {
    "id": "9106112009",
    "district_id": "910611",
    "label": "Syabes",
    "value": "Syabes"
  },
  {
    "id": "9106112010",
    "district_id": "910611",
    "label": "Samber Sup",
    "value": "Samber Sup"
  },
  {
    "id": "9106112011",
    "district_id": "910611",
    "label": "Suneri",
    "value": "Suneri"
  },
  {
    "id": "9106112012",
    "district_id": "910611",
    "label": "Rarnpimbo",
    "value": "Rarnpimbo"
  },
  {
    "id": "9106112013",
    "district_id": "910611",
    "label": "Padwa",
    "value": "Padwa"
  },
  {
    "id": "9106112014",
    "district_id": "910611",
    "label": "Waroi",
    "value": "Waroi"
  },
  {
    "id": "9106112015",
    "district_id": "910611",
    "label": "Inpendi",
    "value": "Inpendi"
  },
  {
    "id": "9106112016",
    "district_id": "910611",
    "label": "Amyambenram",
    "value": "Amyambenram"
  },
  {
    "id": "9106112017",
    "district_id": "910611",
    "label": "Birubi",
    "value": "Birubi"
  },
  {
    "id": "9106112018",
    "district_id": "910611",
    "label": "Binyeri",
    "value": "Binyeri"
  },
  {
    "id": "9106112019",
    "district_id": "910611",
    "label": "Padwa Pantai",
    "value": "Padwa Pantai"
  },
  {
    "id": "9106121001",
    "district_id": "910612",
    "label": "Samofa",
    "value": "Samofa"
  },
  {
    "id": "9106121002",
    "district_id": "910612",
    "label": "Brambaken",
    "value": "Brambaken"
  },
  {
    "id": "9106121010",
    "district_id": "910612",
    "label": "Karang Mulia",
    "value": "Karang Mulia"
  },
  {
    "id": "9106121011",
    "district_id": "910612",
    "label": "Yafdas",
    "value": "Yafdas"
  },
  {
    "id": "9106121012",
    "district_id": "910612",
    "label": "Mansinyas",
    "value": "Mansinyas"
  },
  {
    "id": "9106121013",
    "district_id": "910612",
    "label": "Snerbo",
    "value": "Snerbo"
  },
  {
    "id": "9106121014",
    "district_id": "910612",
    "label": "Anjareuw",
    "value": "Anjareuw"
  },
  {
    "id": "9106122003",
    "district_id": "910612",
    "label": "Mandouw",
    "value": "Mandouw"
  },
  {
    "id": "9106122004",
    "district_id": "910612",
    "label": "Sumberker",
    "value": "Sumberker"
  },
  {
    "id": "9106122006",
    "district_id": "910612",
    "label": "Maryendi",
    "value": "Maryendi"
  },
  {
    "id": "9106122007",
    "district_id": "910612",
    "label": "Sambawofuar",
    "value": "Sambawofuar"
  },
  {
    "id": "9106122008",
    "district_id": "910612",
    "label": "Adainasnosen",
    "value": "Adainasnosen"
  },
  {
    "id": "9106122009",
    "district_id": "910612",
    "label": "Darmopis",
    "value": "Darmopis"
  },
  {
    "id": "9106122015",
    "district_id": "910612",
    "label": "Kamorfuar",
    "value": "Kamorfuar"
  },
  {
    "id": "9106122016",
    "district_id": "910612",
    "label": "Anjereuw",
    "value": "Anjereuw"
  },
  {
    "id": "9106122017",
    "district_id": "910612",
    "label": "Wisata Binsari",
    "value": "Wisata Binsari"
  },
  {
    "id": "9106122018",
    "district_id": "910612",
    "label": "Kinmom",
    "value": "Kinmom"
  },
  {
    "id": "9106132001",
    "district_id": "910613",
    "label": "Asur",
    "value": "Asur"
  },
  {
    "id": "9106132002",
    "district_id": "910613",
    "label": "Bosnabraidi",
    "value": "Bosnabraidi"
  },
  {
    "id": "9106132003",
    "district_id": "910613",
    "label": "Wasori",
    "value": "Wasori"
  },
  {
    "id": "9106132004",
    "district_id": "910613",
    "label": "Soor",
    "value": "Soor"
  },
  {
    "id": "9106132005",
    "district_id": "910613",
    "label": "Karmon",
    "value": "Karmon"
  },
  {
    "id": "9106132006",
    "district_id": "910613",
    "label": "Fanindi",
    "value": "Fanindi"
  },
  {
    "id": "9106132007",
    "district_id": "910613",
    "label": "Madirai",
    "value": "Madirai"
  },
  {
    "id": "9106132008",
    "district_id": "910613",
    "label": "Indawi",
    "value": "Indawi"
  },
  {
    "id": "9106142001",
    "district_id": "910614",
    "label": "Rumbin",
    "value": "Rumbin"
  },
  {
    "id": "9106142002",
    "district_id": "910614",
    "label": "Warbinsi",
    "value": "Warbinsi"
  },
  {
    "id": "9106142003",
    "district_id": "910614",
    "label": "Rodifu",
    "value": "Rodifu"
  },
  {
    "id": "9106142004",
    "district_id": "910614",
    "label": "Wonabraidi",
    "value": "Wonabraidi"
  },
  {
    "id": "9106142005",
    "district_id": "910614",
    "label": "Wouna",
    "value": "Wouna"
  },
  {
    "id": "9106142007",
    "district_id": "910614",
    "label": "Mamorbo",
    "value": "Mamorbo"
  },
  {
    "id": "9106142008",
    "district_id": "910614",
    "label": "Faknikdi",
    "value": "Faknikdi"
  },
  {
    "id": "9106142009",
    "district_id": "910614",
    "label": "Armnu",
    "value": "Armnu"
  },
  {
    "id": "9106142010",
    "district_id": "910614",
    "label": "Sup Mbrur",
    "value": "Sup Mbrur"
  },
  {
    "id": "9106142011",
    "district_id": "910614",
    "label": "Wodu",
    "value": "Wodu"
  },
  {
    "id": "9106142012",
    "district_id": "910614",
    "label": "Dasdo",
    "value": "Dasdo"
  },
  {
    "id": "9106152001",
    "district_id": "910615",
    "label": "Farusi",
    "value": "Farusi"
  },
  {
    "id": "9106152002",
    "district_id": "910615",
    "label": "Mandenderi",
    "value": "Mandenderi"
  },
  {
    "id": "9106152003",
    "district_id": "910615",
    "label": "Swainober",
    "value": "Swainober"
  },
  {
    "id": "9106152004",
    "district_id": "910615",
    "label": "Mardori",
    "value": "Mardori"
  },
  {
    "id": "9106152005",
    "district_id": "910615",
    "label": "Swaipak",
    "value": "Swaipak"
  },
  {
    "id": "9106152006",
    "district_id": "910615",
    "label": "Yenbepioper",
    "value": "Yenbepioper"
  },
  {
    "id": "9106152007",
    "district_id": "910615",
    "label": "Sarwa",
    "value": "Sarwa"
  },
  {
    "id": "9106152008",
    "district_id": "910615",
    "label": "Napdori",
    "value": "Napdori"
  },
  {
    "id": "9106152009",
    "district_id": "910615",
    "label": "Ramdori",
    "value": "Ramdori"
  },
  {
    "id": "9106152010",
    "district_id": "910615",
    "label": "Busdori",
    "value": "Busdori"
  },
  {
    "id": "9106152011",
    "district_id": "910615",
    "label": "Wombrisau",
    "value": "Wombrisau"
  },
  {
    "id": "9106152012",
    "district_id": "910615",
    "label": "Orkdori",
    "value": "Orkdori"
  },
  {
    "id": "9106152013",
    "district_id": "910615",
    "label": "Saswarbo",
    "value": "Saswarbo"
  },
  {
    "id": "9106152014",
    "district_id": "910615",
    "label": "Andoina",
    "value": "Andoina"
  },
  {
    "id": "9106152015",
    "district_id": "910615",
    "label": "Insusbari",
    "value": "Insusbari"
  },
  {
    "id": "9106162001",
    "district_id": "910616",
    "label": "Sandau",
    "value": "Sandau"
  },
  {
    "id": "9106162002",
    "district_id": "910616",
    "label": "Bruyadori",
    "value": "Bruyadori"
  },
  {
    "id": "9106162003",
    "district_id": "910616",
    "label": "Dafi",
    "value": "Dafi"
  },
  {
    "id": "9106162004",
    "district_id": "910616",
    "label": "Amberparem",
    "value": "Amberparem"
  },
  {
    "id": "9106162005",
    "district_id": "910616",
    "label": "Mandori",
    "value": "Mandori"
  },
  {
    "id": "9106162006",
    "district_id": "910616",
    "label": "Duai",
    "value": "Duai"
  },
  {
    "id": "9106162007",
    "district_id": "910616",
    "label": "Mandori Sup",
    "value": "Mandori Sup"
  },
  {
    "id": "9106162008",
    "district_id": "910616",
    "label": "Kamumi",
    "value": "Kamumi"
  },
  {
    "id": "9106162009",
    "district_id": "910616",
    "label": "Arimi Jaya",
    "value": "Arimi Jaya"
  },
  {
    "id": "9106162010",
    "district_id": "910616",
    "label": "Warbukor",
    "value": "Warbukor"
  },
  {
    "id": "9106172001",
    "district_id": "910617",
    "label": "Yenbeba",
    "value": "Yenbeba"
  },
  {
    "id": "9106172002",
    "district_id": "910617",
    "label": "Yenbepon",
    "value": "Yenbepon"
  },
  {
    "id": "9106172003",
    "district_id": "910617",
    "label": "Saribi",
    "value": "Saribi"
  },
  {
    "id": "9106172004",
    "district_id": "910617",
    "label": "Sub Mander",
    "value": "Sub Mander"
  },
  {
    "id": "9106172005",
    "district_id": "910617",
    "label": "Rawar",
    "value": "Rawar"
  },
  {
    "id": "9106172006",
    "district_id": "910617",
    "label": "Pakreki",
    "value": "Pakreki"
  },
  {
    "id": "9106172007",
    "district_id": "910617",
    "label": "Masyarah",
    "value": "Masyarah"
  },
  {
    "id": "9106172008",
    "district_id": "910617",
    "label": "Wansra",
    "value": "Wansra"
  },
  {
    "id": "9106172009",
    "district_id": "910617",
    "label": "Manwor Indah",
    "value": "Manwor Indah"
  },
  {
    "id": "9106182001",
    "district_id": "910618",
    "label": "Andei",
    "value": "Andei"
  },
  {
    "id": "9106182002",
    "district_id": "910618",
    "label": "Syoribo",
    "value": "Syoribo"
  },
  {
    "id": "9106182003",
    "district_id": "910618",
    "label": "Sauribru",
    "value": "Sauribru"
  },
  {
    "id": "9106182004",
    "district_id": "910618",
    "label": "Manggari",
    "value": "Manggari"
  },
  {
    "id": "9106182005",
    "district_id": "910618",
    "label": "Bawei",
    "value": "Bawei"
  },
  {
    "id": "9106182006",
    "district_id": "910618",
    "label": "Sauri",
    "value": "Sauri"
  },
  {
    "id": "9106182007",
    "district_id": "910618",
    "label": "Andei Sup",
    "value": "Andei Sup"
  },
  {
    "id": "9106182008",
    "district_id": "910618",
    "label": "Surdori",
    "value": "Surdori"
  },
  {
    "id": "9106182009",
    "district_id": "910618",
    "label": "Koryakam",
    "value": "Koryakam"
  },
  {
    "id": "9106192001",
    "district_id": "910619",
    "label": "Mios Mangguandi",
    "value": "Mios Mangguandi"
  },
  {
    "id": "9106192002",
    "district_id": "910619",
    "label": "Supraima",
    "value": "Supraima"
  },
  {
    "id": "9106192003",
    "district_id": "910619",
    "label": "Samber Pasi",
    "value": "Samber Pasi"
  },
  {
    "id": "9106192004",
    "district_id": "910619",
    "label": "Pasi",
    "value": "Pasi"
  },
  {
    "id": "9106192005",
    "district_id": "910619",
    "label": "Mbromsi",
    "value": "Mbromsi"
  },
  {
    "id": "9106192006",
    "district_id": "910619",
    "label": "Karabai",
    "value": "Karabai"
  },
  {
    "id": "9106192007",
    "district_id": "910619",
    "label": "Saribra",
    "value": "Saribra"
  },
  {
    "id": "9106192008",
    "district_id": "910619",
    "label": "Nyansoren",
    "value": "Nyansoren"
  },
  {
    "id": "9106192009",
    "district_id": "910619",
    "label": "Sasari",
    "value": "Sasari"
  },
  {
    "id": "9106192010",
    "district_id": "910619",
    "label": "Padaido",
    "value": "Padaido"
  },
  {
    "id": "9106192011",
    "district_id": "910619",
    "label": "Yeri",
    "value": "Yeri"
  },
  {
    "id": "9106192012",
    "district_id": "910619",
    "label": "Anobo",
    "value": "Anobo"
  },
  {
    "id": "9106192013",
    "district_id": "910619",
    "label": "Yen Manaina",
    "value": "Yen Manaina"
  },
  {
    "id": "9106202001",
    "district_id": "910620",
    "label": "Opiaref",
    "value": "Opiaref"
  },
  {
    "id": "9106202002",
    "district_id": "910620",
    "label": "Bakribo",
    "value": "Bakribo"
  },
  {
    "id": "9106202003",
    "district_id": "910620",
    "label": "Sawa",
    "value": "Sawa"
  },
  {
    "id": "9106202004",
    "district_id": "910620",
    "label": "Marauw",
    "value": "Marauw"
  },
  {
    "id": "9106202005",
    "district_id": "910620",
    "label": "Wadibu",
    "value": "Wadibu"
  },
  {
    "id": "9106202006",
    "district_id": "910620",
    "label": "Anggopi",
    "value": "Anggopi"
  },
  {
    "id": "9106202007",
    "district_id": "910620",
    "label": "Anggaduber",
    "value": "Anggaduber"
  },
  {
    "id": "9106202008",
    "district_id": "910620",
    "label": "Animi",
    "value": "Animi"
  },
  {
    "id": "9106202009",
    "district_id": "910620",
    "label": "Kakur",
    "value": "Kakur"
  },
  {
    "id": "9106202010",
    "district_id": "910620",
    "label": "Tanjung Barari",
    "value": "Tanjung Barari"
  },
  {
    "id": "9106202011",
    "district_id": "910620",
    "label": "Sawadori",
    "value": "Sawadori"
  },
  {
    "id": "9106202012",
    "district_id": "910620",
    "label": "Sauri",
    "value": "Sauri"
  },
  {
    "id": "9106202013",
    "district_id": "910620",
    "label": "Makmakerbo",
    "value": "Makmakerbo"
  },
  {
    "id": "9106202014",
    "district_id": "910620",
    "label": "Yensama",
    "value": "Yensama"
  },
  {
    "id": "9106212001",
    "district_id": "910621",
    "label": "Wopes",
    "value": "Wopes"
  },
  {
    "id": "9106212002",
    "district_id": "910621",
    "label": "Wandos",
    "value": "Wandos"
  },
  {
    "id": "9106212003",
    "district_id": "910621",
    "label": "Doubo",
    "value": "Doubo"
  },
  {
    "id": "9106212004",
    "district_id": "910621",
    "label": "Syurdori",
    "value": "Syurdori"
  },
  {
    "id": "9106212005",
    "district_id": "910621",
    "label": "Sansundi",
    "value": "Sansundi"
  },
  {
    "id": "9107011059",
    "district_id": "910701",
    "label": "Wuyukwi",
    "value": "Wuyukwi"
  },
  {
    "id": "9107011060",
    "district_id": "910701",
    "label": "Pagaleme",
    "value": "Pagaleme"
  },
  {
    "id": "9107012001",
    "district_id": "910701",
    "label": "Pruleme",
    "value": "Pruleme"
  },
  {
    "id": "9107012003",
    "district_id": "910701",
    "label": "Wuyukwi",
    "value": "Wuyukwi"
  },
  {
    "id": "9107012023",
    "district_id": "910701",
    "label": "Wuyuneri",
    "value": "Wuyuneri"
  },
  {
    "id": "9107012042",
    "district_id": "910701",
    "label": "Pepera",
    "value": "Pepera"
  },
  {
    "id": "9107012044",
    "district_id": "910701",
    "label": "Muliagambut",
    "value": "Muliagambut"
  },
  {
    "id": "9107012045",
    "district_id": "910701",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "9107012046",
    "district_id": "910701",
    "label": "Towogi",
    "value": "Towogi"
  },
  {
    "id": "9107012047",
    "district_id": "910701",
    "label": "Birak Ambut",
    "value": "Birak Ambut"
  },
  {
    "id": "9107012049",
    "district_id": "910701",
    "label": "Usir",
    "value": "Usir"
  },
  {
    "id": "9107031070",
    "district_id": "910703",
    "label": "Wurak",
    "value": "Wurak"
  },
  {
    "id": "9107032002",
    "district_id": "910703",
    "label": "Wurak",
    "value": "Wurak"
  },
  {
    "id": "9107032010",
    "district_id": "910703",
    "label": "Lambo",
    "value": "Lambo"
  },
  {
    "id": "9107032018",
    "district_id": "910703",
    "label": "Pindebaga",
    "value": "Pindebaga"
  },
  {
    "id": "9107032027",
    "district_id": "910703",
    "label": "Dolinggame",
    "value": "Dolinggame"
  },
  {
    "id": "9107032035",
    "district_id": "910703",
    "label": "Belantara",
    "value": "Belantara"
  },
  {
    "id": "9107032036",
    "district_id": "910703",
    "label": "Kalengga",
    "value": "Kalengga"
  },
  {
    "id": "9107032037",
    "district_id": "910703",
    "label": "Pulau Timur",
    "value": "Pulau Timur"
  },
  {
    "id": "9107032038",
    "district_id": "910703",
    "label": "Girmor",
    "value": "Girmor"
  },
  {
    "id": "9107032039",
    "district_id": "910703",
    "label": "Maka",
    "value": "Maka"
  },
  {
    "id": "9107032040",
    "district_id": "910703",
    "label": "Mobigi",
    "value": "Mobigi"
  },
  {
    "id": "9107032041",
    "district_id": "910703",
    "label": "Juria Satu",
    "value": "Juria Satu"
  },
  {
    "id": "9107032046",
    "district_id": "910703",
    "label": "Gubulome",
    "value": "Gubulome"
  },
  {
    "id": "9107032048",
    "district_id": "910703",
    "label": "Megawi Megewa",
    "value": "Megawi Megewa"
  },
  {
    "id": "9107032049",
    "district_id": "910703",
    "label": "Kurikpulok",
    "value": "Kurikpulok"
  },
  {
    "id": "9107032050",
    "district_id": "910703",
    "label": "Pukipaki",
    "value": "Pukipaki"
  },
  {
    "id": "9107032051",
    "district_id": "910703",
    "label": "Kaninonggo",
    "value": "Kaninonggo"
  },
  {
    "id": "9107032065",
    "district_id": "910703",
    "label": "Kirigimaduk",
    "value": "Kirigimaduk"
  },
  {
    "id": "9107032067",
    "district_id": "910703",
    "label": "Pulogengga",
    "value": "Pulogengga"
  },
  {
    "id": "9107032068",
    "district_id": "910703",
    "label": "Ginipago",
    "value": "Ginipago"
  },
  {
    "id": "9107032069",
    "district_id": "910703",
    "label": "Alukme",
    "value": "Alukme"
  },
  {
    "id": "9107062001",
    "district_id": "910706",
    "label": "Fawi",
    "value": "Fawi"
  },
  {
    "id": "9107062007",
    "district_id": "910706",
    "label": "Bakusi",
    "value": "Bakusi"
  },
  {
    "id": "9107062008",
    "district_id": "910706",
    "label": "Turumo",
    "value": "Turumo"
  },
  {
    "id": "9107062012",
    "district_id": "910706",
    "label": "Kaho",
    "value": "Kaho"
  },
  {
    "id": "9107062013",
    "district_id": "910706",
    "label": "Biricare",
    "value": "Biricare"
  },
  {
    "id": "9107062014",
    "district_id": "910706",
    "label": "Dorey",
    "value": "Dorey"
  },
  {
    "id": "9107062015",
    "district_id": "910706",
    "label": "Fii",
    "value": "Fii"
  },
  {
    "id": "9107062016",
    "district_id": "910706",
    "label": "Evo",
    "value": "Evo"
  },
  {
    "id": "9107062017",
    "district_id": "910706",
    "label": "Yerei",
    "value": "Yerei"
  },
  {
    "id": "9107072001",
    "district_id": "910707",
    "label": "Mewoluk",
    "value": "Mewoluk"
  },
  {
    "id": "9107072005",
    "district_id": "910707",
    "label": "Gumbru",
    "value": "Gumbru"
  },
  {
    "id": "9107072007",
    "district_id": "910707",
    "label": "Anggolapaga",
    "value": "Anggolapaga"
  },
  {
    "id": "9107072008",
    "district_id": "910707",
    "label": "Balinggup",
    "value": "Balinggup"
  },
  {
    "id": "9107072009",
    "district_id": "910707",
    "label": "Waliba",
    "value": "Waliba"
  },
  {
    "id": "9107072010",
    "district_id": "910707",
    "label": "Nggininik",
    "value": "Nggininik"
  },
  {
    "id": "9107072011",
    "district_id": "910707",
    "label": "Biak",
    "value": "Biak"
  },
  {
    "id": "9107072023",
    "district_id": "910707",
    "label": "Wutikme",
    "value": "Wutikme"
  },
  {
    "id": "9107082003",
    "district_id": "910708",
    "label": "Yambuni",
    "value": "Yambuni"
  },
  {
    "id": "9107082005",
    "district_id": "910708",
    "label": "Yogolawi",
    "value": "Yogolawi"
  },
  {
    "id": "9107082006",
    "district_id": "910708",
    "label": "Wundu",
    "value": "Wundu"
  },
  {
    "id": "9107082007",
    "district_id": "910708",
    "label": "Binime",
    "value": "Binime"
  },
  {
    "id": "9107082008",
    "district_id": "910708",
    "label": "Tiogeme",
    "value": "Tiogeme"
  },
  {
    "id": "9107082009",
    "district_id": "910708",
    "label": "Goyage",
    "value": "Goyage"
  },
  {
    "id": "9107082010",
    "district_id": "910708",
    "label": "Kabilimbut",
    "value": "Kabilimbut"
  },
  {
    "id": "9107082017",
    "district_id": "910708",
    "label": "Wolame",
    "value": "Wolame"
  },
  {
    "id": "9107082018",
    "district_id": "910708",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "9107082021",
    "district_id": "910708",
    "label": "Gibaga",
    "value": "Gibaga"
  },
  {
    "id": "9107082022",
    "district_id": "910708",
    "label": "Nami",
    "value": "Nami"
  },
  {
    "id": "9107082023",
    "district_id": "910708",
    "label": "Linwakwi",
    "value": "Linwakwi"
  },
  {
    "id": "9107082030",
    "district_id": "910708",
    "label": "Kwatineri",
    "value": "Kwatineri"
  },
  {
    "id": "9107082031",
    "district_id": "910708",
    "label": "Yileyale",
    "value": "Yileyale"
  },
  {
    "id": "9107102031",
    "district_id": "910710",
    "label": "Mepar",
    "value": "Mepar"
  },
  {
    "id": "9107102034",
    "district_id": "910710",
    "label": "Anebalui",
    "value": "Anebalui"
  },
  {
    "id": "9107102035",
    "district_id": "910710",
    "label": "Pagolome",
    "value": "Pagolome"
  },
  {
    "id": "9107102036",
    "district_id": "910710",
    "label": "Tombok",
    "value": "Tombok"
  },
  {
    "id": "9107102037",
    "district_id": "910710",
    "label": "Kering",
    "value": "Kering"
  },
  {
    "id": "9107102039",
    "district_id": "910710",
    "label": "Meyongga",
    "value": "Meyongga"
  },
  {
    "id": "9107102041",
    "district_id": "910710",
    "label": "Oum",
    "value": "Oum"
  },
  {
    "id": "9107102042",
    "district_id": "910710",
    "label": "Nume",
    "value": "Nume"
  },
  {
    "id": "9107102043",
    "district_id": "910710",
    "label": "Yawor",
    "value": "Yawor"
  },
  {
    "id": "9107102045",
    "district_id": "910710",
    "label": "Wurina",
    "value": "Wurina"
  },
  {
    "id": "9107102046",
    "district_id": "910710",
    "label": "Woraluk",
    "value": "Woraluk"
  },
  {
    "id": "9107102047",
    "district_id": "910710",
    "label": "Onendu",
    "value": "Onendu"
  },
  {
    "id": "9107102048",
    "district_id": "910710",
    "label": "Akwibaga",
    "value": "Akwibaga"
  },
  {
    "id": "9107112001",
    "district_id": "910711",
    "label": "Gubugani",
    "value": "Gubugani"
  },
  {
    "id": "9107112003",
    "district_id": "910711",
    "label": "Ambok",
    "value": "Ambok"
  },
  {
    "id": "9107112004",
    "district_id": "910711",
    "label": "Awiyam",
    "value": "Awiyam"
  },
  {
    "id": "9107112005",
    "district_id": "910711",
    "label": "Tubugime",
    "value": "Tubugime"
  },
  {
    "id": "9107112006",
    "district_id": "910711",
    "label": "Wariru",
    "value": "Wariru"
  },
  {
    "id": "9107112007",
    "district_id": "910711",
    "label": "Digi",
    "value": "Digi"
  },
  {
    "id": "9107112008",
    "district_id": "910711",
    "label": "Kikup",
    "value": "Kikup"
  },
  {
    "id": "9107112009",
    "district_id": "910711",
    "label": "Gunung Tayok",
    "value": "Gunung Tayok"
  },
  {
    "id": "9107112011",
    "district_id": "910711",
    "label": "Nambu",
    "value": "Nambu"
  },
  {
    "id": "9107112012",
    "district_id": "910711",
    "label": "Asua",
    "value": "Asua"
  },
  {
    "id": "9107112013",
    "district_id": "910711",
    "label": "Degei",
    "value": "Degei"
  },
  {
    "id": "9107112014",
    "district_id": "910711",
    "label": "Sigou",
    "value": "Sigou"
  },
  {
    "id": "9107112015",
    "district_id": "910711",
    "label": "Nalu",
    "value": "Nalu"
  },
  {
    "id": "9107122002",
    "district_id": "910712",
    "label": "Tinggineri",
    "value": "Tinggineri"
  },
  {
    "id": "9107122003",
    "district_id": "910712",
    "label": "Monia",
    "value": "Monia"
  },
  {
    "id": "9107122008",
    "district_id": "910712",
    "label": "Tingginambut",
    "value": "Tingginambut"
  },
  {
    "id": "9107122010",
    "district_id": "910712",
    "label": "Melekom",
    "value": "Melekom"
  },
  {
    "id": "9107122018",
    "district_id": "910712",
    "label": "Gigume",
    "value": "Gigume"
  },
  {
    "id": "9107122019",
    "district_id": "910712",
    "label": "Papua",
    "value": "Papua"
  },
  {
    "id": "9107122020",
    "district_id": "910712",
    "label": "Tingginime",
    "value": "Tingginime"
  },
  {
    "id": "9107122021",
    "district_id": "910712",
    "label": "Tombo",
    "value": "Tombo"
  },
  {
    "id": "9107122022",
    "district_id": "910712",
    "label": "Gimanggen",
    "value": "Gimanggen"
  },
  {
    "id": "9107122023",
    "district_id": "910712",
    "label": "Uragi",
    "value": "Uragi"
  },
  {
    "id": "9107122024",
    "district_id": "910712",
    "label": "Yamengga",
    "value": "Yamengga"
  },
  {
    "id": "9107122025",
    "district_id": "910712",
    "label": "Degi",
    "value": "Degi"
  },
  {
    "id": "9107122026",
    "district_id": "910712",
    "label": "Jugumbelawi",
    "value": "Jugumbelawi"
  },
  {
    "id": "9107122027",
    "district_id": "910712",
    "label": "Paralo",
    "value": "Paralo"
  },
  {
    "id": "9107122028",
    "district_id": "910712",
    "label": "Womelagandan",
    "value": "Womelagandan"
  },
  {
    "id": "9107122031",
    "district_id": "910712",
    "label": "Bigirage",
    "value": "Bigirage"
  },
  {
    "id": "9107122032",
    "district_id": "910712",
    "label": "Lumbuk",
    "value": "Lumbuk"
  },
  {
    "id": "9107122033",
    "district_id": "910712",
    "label": "Inikimaluk",
    "value": "Inikimaluk"
  },
  {
    "id": "9107122034",
    "district_id": "910712",
    "label": "Yonggun",
    "value": "Yonggun"
  },
  {
    "id": "9107122035",
    "district_id": "910712",
    "label": "Gobak",
    "value": "Gobak"
  },
  {
    "id": "9107122071",
    "district_id": "910712",
    "label": "Gubupur",
    "value": "Gubupur"
  },
  {
    "id": "9107122072",
    "district_id": "910712",
    "label": "Berime",
    "value": "Berime"
  },
  {
    "id": "9107172001",
    "district_id": "910717",
    "label": "Pagaleme",
    "value": "Pagaleme"
  },
  {
    "id": "9107172002",
    "district_id": "910717",
    "label": "Muliambut",
    "value": "Muliambut"
  },
  {
    "id": "9107172003",
    "district_id": "910717",
    "label": "Lunggineri",
    "value": "Lunggineri"
  },
  {
    "id": "9107172004",
    "district_id": "910717",
    "label": "Igimbut",
    "value": "Igimbut"
  },
  {
    "id": "9107172005",
    "district_id": "910717",
    "label": "Kulok Enggame",
    "value": "Kulok Enggame"
  },
  {
    "id": "9107182001",
    "district_id": "910718",
    "label": "Yarmukum",
    "value": "Yarmukum"
  },
  {
    "id": "9107182002",
    "district_id": "910718",
    "label": "Pilia",
    "value": "Pilia"
  },
  {
    "id": "9107182003",
    "district_id": "910718",
    "label": "Yaniruk",
    "value": "Yaniruk"
  },
  {
    "id": "9107182004",
    "district_id": "910718",
    "label": "Gwenggu",
    "value": "Gwenggu"
  },
  {
    "id": "9107182005",
    "district_id": "910718",
    "label": "Tukwi",
    "value": "Tukwi"
  },
  {
    "id": "9107182006",
    "district_id": "910718",
    "label": "Tiru",
    "value": "Tiru"
  },
  {
    "id": "9107182007",
    "district_id": "910718",
    "label": "Yogorini",
    "value": "Yogorini"
  },
  {
    "id": "9107182008",
    "district_id": "910718",
    "label": "Nalime",
    "value": "Nalime"
  },
  {
    "id": "9107182009",
    "district_id": "910718",
    "label": "Urgele",
    "value": "Urgele"
  },
  {
    "id": "9107182010",
    "district_id": "910718",
    "label": "Yagonik",
    "value": "Yagonik"
  },
  {
    "id": "9107182011",
    "district_id": "910718",
    "label": "Lulame",
    "value": "Lulame"
  },
  {
    "id": "9107192001",
    "district_id": "910719",
    "label": "Wondenggobak",
    "value": "Wondenggobak"
  },
  {
    "id": "9107192002",
    "district_id": "910719",
    "label": "Jiginikime",
    "value": "Jiginikime"
  },
  {
    "id": "9107192003",
    "district_id": "910719",
    "label": "Puncak Senyum",
    "value": "Puncak Senyum"
  },
  {
    "id": "9107192004",
    "district_id": "910719",
    "label": "Anggutari",
    "value": "Anggutari"
  },
  {
    "id": "9107192005",
    "district_id": "910719",
    "label": "Wunagelo",
    "value": "Wunagelo"
  },
  {
    "id": "9107192006",
    "district_id": "910719",
    "label": "Dondo",
    "value": "Dondo"
  },
  {
    "id": "9107192007",
    "district_id": "910719",
    "label": "Tanoba",
    "value": "Tanoba"
  },
  {
    "id": "9107192008",
    "district_id": "910719",
    "label": "Niruwi",
    "value": "Niruwi"
  },
  {
    "id": "9107192009",
    "district_id": "910719",
    "label": "Yalikambi",
    "value": "Yalikambi"
  },
  {
    "id": "9107202001",
    "district_id": "910720",
    "label": "Kulirik",
    "value": "Kulirik"
  },
  {
    "id": "9107202002",
    "district_id": "910720",
    "label": "Doligobak",
    "value": "Doligobak"
  },
  {
    "id": "9107202003",
    "district_id": "910720",
    "label": "Yalinggua",
    "value": "Yalinggua"
  },
  {
    "id": "9107202004",
    "district_id": "910720",
    "label": "Talilome",
    "value": "Talilome"
  },
  {
    "id": "9107202005",
    "district_id": "910720",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "9107202006",
    "district_id": "910720",
    "label": "Karubate",
    "value": "Karubate"
  },
  {
    "id": "9107202007",
    "district_id": "910720",
    "label": "Dologobak",
    "value": "Dologobak"
  },
  {
    "id": "9107202008",
    "district_id": "910720",
    "label": "Yambidugun",
    "value": "Yambidugun"
  },
  {
    "id": "9107212001",
    "district_id": "910721",
    "label": "Ilamburawi",
    "value": "Ilamburawi"
  },
  {
    "id": "9107212002",
    "district_id": "910721",
    "label": "Berelema",
    "value": "Berelema"
  },
  {
    "id": "9107212003",
    "district_id": "910721",
    "label": "Kalome",
    "value": "Kalome"
  },
  {
    "id": "9107212004",
    "district_id": "910721",
    "label": "Temu",
    "value": "Temu"
  },
  {
    "id": "9107212005",
    "district_id": "910721",
    "label": "Jigunikime",
    "value": "Jigunikime"
  },
  {
    "id": "9107222001",
    "district_id": "910722",
    "label": "Mondu",
    "value": "Mondu"
  },
  {
    "id": "9107222002",
    "district_id": "910722",
    "label": "Yambi",
    "value": "Yambi"
  },
  {
    "id": "9107222003",
    "district_id": "910722",
    "label": "Moulo",
    "value": "Moulo"
  },
  {
    "id": "9107222004",
    "district_id": "910722",
    "label": "Tenolok",
    "value": "Tenolok"
  },
  {
    "id": "9107222005",
    "district_id": "910722",
    "label": "Ginigom",
    "value": "Ginigom"
  },
  {
    "id": "9107222006",
    "district_id": "910722",
    "label": "Dangenpaga",
    "value": "Dangenpaga"
  },
  {
    "id": "9107222007",
    "district_id": "910722",
    "label": "Wonome",
    "value": "Wonome"
  },
  {
    "id": "9107222008",
    "district_id": "910722",
    "label": "Yoboluk",
    "value": "Yoboluk"
  },
  {
    "id": "9107222009",
    "district_id": "910722",
    "label": "Tirineri",
    "value": "Tirineri"
  },
  {
    "id": "9107232001",
    "district_id": "910723",
    "label": "Lumo",
    "value": "Lumo"
  },
  {
    "id": "9107232002",
    "district_id": "910723",
    "label": "Kililumo",
    "value": "Kililumo"
  },
  {
    "id": "9107232003",
    "district_id": "910723",
    "label": "Gilibe",
    "value": "Gilibe"
  },
  {
    "id": "9107232004",
    "district_id": "910723",
    "label": "Ninggineri",
    "value": "Ninggineri"
  },
  {
    "id": "9107232005",
    "district_id": "910723",
    "label": "Wurabak",
    "value": "Wurabak"
  },
  {
    "id": "9107232006",
    "district_id": "910723",
    "label": "Wuluma",
    "value": "Wuluma"
  },
  {
    "id": "9107232007",
    "district_id": "910723",
    "label": "Tigir",
    "value": "Tigir"
  },
  {
    "id": "9107232008",
    "district_id": "910723",
    "label": "Gililome",
    "value": "Gililome"
  },
  {
    "id": "9107232009",
    "district_id": "910723",
    "label": "Wuramburu",
    "value": "Wuramburu"
  },
  {
    "id": "9107242001",
    "district_id": "910724",
    "label": "Tiolome",
    "value": "Tiolome"
  },
  {
    "id": "9107242002",
    "district_id": "910724",
    "label": "Belabaga",
    "value": "Belabaga"
  },
  {
    "id": "9107242003",
    "district_id": "910724",
    "label": "Mewut",
    "value": "Mewut"
  },
  {
    "id": "9107242004",
    "district_id": "910724",
    "label": "Maloinggen",
    "value": "Maloinggen"
  },
  {
    "id": "9107242005",
    "district_id": "910724",
    "label": "Wanume",
    "value": "Wanume"
  },
  {
    "id": "9107252001",
    "district_id": "910725",
    "label": "Dokome",
    "value": "Dokome"
  },
  {
    "id": "9107252002",
    "district_id": "910725",
    "label": "Purbalo",
    "value": "Purbalo"
  },
  {
    "id": "9107252003",
    "district_id": "910725",
    "label": "Wurage",
    "value": "Wurage"
  },
  {
    "id": "9107252004",
    "district_id": "910725",
    "label": "Nowoneri",
    "value": "Nowoneri"
  },
  {
    "id": "9107252005",
    "district_id": "910725",
    "label": "Nowome",
    "value": "Nowome"
  },
  {
    "id": "9107252006",
    "district_id": "910725",
    "label": "Imulineri",
    "value": "Imulineri"
  },
  {
    "id": "9107252007",
    "district_id": "910725",
    "label": "Molobak",
    "value": "Molobak"
  },
  {
    "id": "9107252008",
    "district_id": "910725",
    "label": "Kimibut",
    "value": "Kimibut"
  },
  {
    "id": "9107252009",
    "district_id": "910725",
    "label": "Nungwawi",
    "value": "Nungwawi"
  },
  {
    "id": "9107262001",
    "district_id": "910726",
    "label": "Kalome",
    "value": "Kalome"
  },
  {
    "id": "9107262002",
    "district_id": "910726",
    "label": "Agape",
    "value": "Agape"
  },
  {
    "id": "9107262003",
    "district_id": "910726",
    "label": "Cilome",
    "value": "Cilome"
  },
  {
    "id": "9107262004",
    "district_id": "910726",
    "label": "Longgawi",
    "value": "Longgawi"
  },
  {
    "id": "9107262005",
    "district_id": "910726",
    "label": "Wundini",
    "value": "Wundini"
  },
  {
    "id": "9107262006",
    "district_id": "910726",
    "label": "Berem",
    "value": "Berem"
  },
  {
    "id": "9107262007",
    "district_id": "910726",
    "label": "Diraluk",
    "value": "Diraluk"
  },
  {
    "id": "9107262008",
    "district_id": "910726",
    "label": "Yanenggawi",
    "value": "Yanenggawi"
  },
  {
    "id": "9107262009",
    "district_id": "910726",
    "label": "Kayogebur",
    "value": "Kayogebur"
  },
  {
    "id": "9107262010",
    "district_id": "910726",
    "label": "Wurabume",
    "value": "Wurabume"
  },
  {
    "id": "9107262011",
    "district_id": "910726",
    "label": "Binggelakme",
    "value": "Binggelakme"
  },
  {
    "id": "9107262012",
    "district_id": "910726",
    "label": "Torage",
    "value": "Torage"
  },
  {
    "id": "9107262013",
    "district_id": "910726",
    "label": "Palumagi",
    "value": "Palumagi"
  },
  {
    "id": "9107262014",
    "district_id": "910726",
    "label": "Jiramok",
    "value": "Jiramok"
  },
  {
    "id": "9107262015",
    "district_id": "910726",
    "label": "Jinggwi",
    "value": "Jinggwi"
  },
  {
    "id": "9107262016",
    "district_id": "910726",
    "label": "Pilibur",
    "value": "Pilibur"
  },
  {
    "id": "9107272001",
    "district_id": "910727",
    "label": "Wonwi",
    "value": "Wonwi"
  },
  {
    "id": "9107272002",
    "district_id": "910727",
    "label": "Nenegame",
    "value": "Nenegame"
  },
  {
    "id": "9107272003",
    "district_id": "910727",
    "label": "Bime",
    "value": "Bime"
  },
  {
    "id": "9107272004",
    "district_id": "910727",
    "label": "Anggege",
    "value": "Anggege"
  },
  {
    "id": "9107272005",
    "district_id": "910727",
    "label": "Pernaluk",
    "value": "Pernaluk"
  },
  {
    "id": "9107272006",
    "district_id": "910727",
    "label": "Kiburu",
    "value": "Kiburu"
  },
  {
    "id": "9107272007",
    "district_id": "910727",
    "label": "Nuwi",
    "value": "Nuwi"
  },
  {
    "id": "9107272008",
    "district_id": "910727",
    "label": "Ponggoname",
    "value": "Ponggoname"
  },
  {
    "id": "9107272009",
    "district_id": "910727",
    "label": "Gibume",
    "value": "Gibume"
  },
  {
    "id": "9107272010",
    "district_id": "910727",
    "label": "Naburage",
    "value": "Naburage"
  },
  {
    "id": "9107272011",
    "district_id": "910727",
    "label": "Yunggwi",
    "value": "Yunggwi"
  },
  {
    "id": "9107272012",
    "district_id": "910727",
    "label": "Anggagalo",
    "value": "Anggagalo"
  },
  {
    "id": "9107272013",
    "district_id": "910727",
    "label": "Anggalo",
    "value": "Anggalo"
  },
  {
    "id": "9107272014",
    "district_id": "910727",
    "label": "Wiyage",
    "value": "Wiyage"
  },
  {
    "id": "9107272015",
    "district_id": "910727",
    "label": "Piyabigimbut",
    "value": "Piyabigimbut"
  },
  {
    "id": "9107272016",
    "district_id": "910727",
    "label": "Bunaluk",
    "value": "Bunaluk"
  },
  {
    "id": "9107272017",
    "district_id": "910727",
    "label": "Gereja Lama",
    "value": "Gereja Lama"
  },
  {
    "id": "9107282001",
    "district_id": "910728",
    "label": "Amberiambut",
    "value": "Amberiambut"
  },
  {
    "id": "9107282002",
    "district_id": "910728",
    "label": "Kobarak",
    "value": "Kobarak"
  },
  {
    "id": "9107282003",
    "district_id": "910728",
    "label": "Wugiwagi",
    "value": "Wugiwagi"
  },
  {
    "id": "9107282004",
    "district_id": "910728",
    "label": "Yagaluk",
    "value": "Yagaluk"
  },
  {
    "id": "9107282005",
    "district_id": "910728",
    "label": "Moulo",
    "value": "Moulo"
  },
  {
    "id": "9107282006",
    "district_id": "910728",
    "label": "Jimbanime",
    "value": "Jimbanime"
  },
  {
    "id": "9107282007",
    "district_id": "910728",
    "label": "Jiguluk",
    "value": "Jiguluk"
  },
  {
    "id": "9107282008",
    "district_id": "910728",
    "label": "Yamoneri",
    "value": "Yamoneri"
  },
  {
    "id": "9107282009",
    "district_id": "910728",
    "label": "Gumawi",
    "value": "Gumawi"
  },
  {
    "id": "9107282010",
    "district_id": "910728",
    "label": "Digolome",
    "value": "Digolome"
  },
  {
    "id": "9107282011",
    "district_id": "910728",
    "label": "Bunume",
    "value": "Bunume"
  },
  {
    "id": "9107282012",
    "district_id": "910728",
    "label": "Ngginigum",
    "value": "Ngginigum"
  },
  {
    "id": "9107282013",
    "district_id": "910728",
    "label": "Jembeneri",
    "value": "Jembeneri"
  },
  {
    "id": "9107282014",
    "district_id": "910728",
    "label": "Jigelo",
    "value": "Jigelo"
  },
  {
    "id": "9107282015",
    "district_id": "910728",
    "label": "Piyapigi",
    "value": "Piyapigi"
  },
  {
    "id": "9107282016",
    "district_id": "910728",
    "label": "Jibonok",
    "value": "Jibonok"
  },
  {
    "id": "9107292001",
    "district_id": "910729",
    "label": "Anggutare",
    "value": "Anggutare"
  },
  {
    "id": "9107292002",
    "district_id": "910729",
    "label": "Agopaga",
    "value": "Agopaga"
  },
  {
    "id": "9107292003",
    "district_id": "910729",
    "label": "Lerawera",
    "value": "Lerawera"
  },
  {
    "id": "9107292004",
    "district_id": "910729",
    "label": "Nakongwe",
    "value": "Nakongwe"
  },
  {
    "id": "9107292005",
    "district_id": "910729",
    "label": "Ginilome",
    "value": "Ginilome"
  },
  {
    "id": "9107292006",
    "district_id": "910729",
    "label": "Yalibate",
    "value": "Yalibate"
  },
  {
    "id": "9107292007",
    "district_id": "910729",
    "label": "Wurunikme",
    "value": "Wurunikme"
  },
  {
    "id": "9107292008",
    "district_id": "910729",
    "label": "Jimbinggame",
    "value": "Jimbinggame"
  },
  {
    "id": "9107292009",
    "district_id": "910729",
    "label": "Oriluk",
    "value": "Oriluk"
  },
  {
    "id": "9107292010",
    "district_id": "910729",
    "label": "Pagargom",
    "value": "Pagargom"
  },
  {
    "id": "9107292011",
    "district_id": "910729",
    "label": "Towoluk",
    "value": "Towoluk"
  },
  {
    "id": "9107292012",
    "district_id": "910729",
    "label": "Tenolok",
    "value": "Tenolok"
  },
  {
    "id": "9107292013",
    "district_id": "910729",
    "label": "Jiogobak",
    "value": "Jiogobak"
  },
  {
    "id": "9107292014",
    "district_id": "910729",
    "label": "Tirigoi",
    "value": "Tirigoi"
  },
  {
    "id": "9107292015",
    "district_id": "910729",
    "label": "Mondogoneri",
    "value": "Mondogoneri"
  },
  {
    "id": "9107292016",
    "district_id": "910729",
    "label": "Tenomanggen",
    "value": "Tenomanggen"
  },
  {
    "id": "9107292017",
    "district_id": "910729",
    "label": "Andiron",
    "value": "Andiron"
  },
  {
    "id": "9107292018",
    "district_id": "910729",
    "label": "Ambit-Mbit",
    "value": "Ambit-Mbit"
  },
  {
    "id": "9107292019",
    "district_id": "910729",
    "label": "Aulakme",
    "value": "Aulakme"
  },
  {
    "id": "9107302001",
    "district_id": "910730",
    "label": "Nioga",
    "value": "Nioga"
  },
  {
    "id": "9107302002",
    "district_id": "910730",
    "label": "Yonggi",
    "value": "Yonggi"
  },
  {
    "id": "9107302003",
    "district_id": "910730",
    "label": "Wonggi",
    "value": "Wonggi"
  },
  {
    "id": "9107302004",
    "district_id": "910730",
    "label": "Wambagalo",
    "value": "Wambagalo"
  },
  {
    "id": "9107302005",
    "district_id": "910730",
    "label": "Jigonikme",
    "value": "Jigonikme"
  },
  {
    "id": "9107302006",
    "district_id": "910730",
    "label": "Muruwi",
    "value": "Muruwi"
  },
  {
    "id": "9107302007",
    "district_id": "910730",
    "label": "Wutime",
    "value": "Wutime"
  },
  {
    "id": "9107302008",
    "district_id": "910730",
    "label": "Gibaga",
    "value": "Gibaga"
  },
  {
    "id": "9107302009",
    "district_id": "910730",
    "label": "Papak",
    "value": "Papak"
  },
  {
    "id": "9107302010",
    "district_id": "910730",
    "label": "Wanduri",
    "value": "Wanduri"
  },
  {
    "id": "9107302011",
    "district_id": "910730",
    "label": "Walom",
    "value": "Walom"
  },
  {
    "id": "9107312001",
    "district_id": "910731",
    "label": "Gubume",
    "value": "Gubume"
  },
  {
    "id": "9107312002",
    "district_id": "910731",
    "label": "Milineri",
    "value": "Milineri"
  },
  {
    "id": "9107312003",
    "district_id": "910731",
    "label": "Melela",
    "value": "Melela"
  },
  {
    "id": "9107312004",
    "district_id": "910731",
    "label": "Kenendaga",
    "value": "Kenendaga"
  },
  {
    "id": "9107312005",
    "district_id": "910731",
    "label": "Apelome",
    "value": "Apelome"
  },
  {
    "id": "9107312006",
    "district_id": "910731",
    "label": "Kumigi",
    "value": "Kumigi"
  },
  {
    "id": "9107312007",
    "district_id": "910731",
    "label": "Noba Noba",
    "value": "Noba Noba"
  },
  {
    "id": "9107312008",
    "district_id": "910731",
    "label": "Nogi",
    "value": "Nogi"
  },
  {
    "id": "9107312009",
    "district_id": "910731",
    "label": "Gubuneri",
    "value": "Gubuneri"
  },
  {
    "id": "9107312010",
    "district_id": "910731",
    "label": "Liguni",
    "value": "Liguni"
  },
  {
    "id": "9107312011",
    "district_id": "910731",
    "label": "Mondu",
    "value": "Mondu"
  },
  {
    "id": "9107312012",
    "district_id": "910731",
    "label": "Jinggi",
    "value": "Jinggi"
  },
  {
    "id": "9107312013",
    "district_id": "910731",
    "label": "Ginilume",
    "value": "Ginilume"
  },
  {
    "id": "9107312014",
    "district_id": "910731",
    "label": "Wunggani",
    "value": "Wunggani"
  },
  {
    "id": "9107312015",
    "district_id": "910731",
    "label": "Menggegenikime",
    "value": "Menggegenikime"
  },
  {
    "id": "9107312016",
    "district_id": "910731",
    "label": "Tinggirege",
    "value": "Tinggirege"
  },
  {
    "id": "9107312017",
    "district_id": "910731",
    "label": "Timoramo",
    "value": "Timoramo"
  },
  {
    "id": "9107322001",
    "district_id": "910732",
    "label": "Guna",
    "value": "Guna"
  },
  {
    "id": "9107322002",
    "district_id": "910732",
    "label": "Mandura",
    "value": "Mandura"
  },
  {
    "id": "9107322003",
    "district_id": "910732",
    "label": "Lugubago",
    "value": "Lugubago"
  },
  {
    "id": "9107322004",
    "district_id": "910732",
    "label": "Andugir",
    "value": "Andugir"
  },
  {
    "id": "9107322005",
    "district_id": "910732",
    "label": "Muara Miguni",
    "value": "Muara Miguni"
  },
  {
    "id": "9107322006",
    "district_id": "910732",
    "label": "Abili",
    "value": "Abili"
  },
  {
    "id": "9107322007",
    "district_id": "910732",
    "label": "Pereya",
    "value": "Pereya"
  },
  {
    "id": "9107322008",
    "district_id": "910732",
    "label": "Ginimanggen",
    "value": "Ginimanggen"
  },
  {
    "id": "9107322009",
    "district_id": "910732",
    "label": "Laworege",
    "value": "Laworege"
  },
  {
    "id": "9107322010",
    "district_id": "910732",
    "label": "Wumbiri",
    "value": "Wumbiri"
  },
  {
    "id": "9107332001",
    "district_id": "910733",
    "label": "Dagai",
    "value": "Dagai"
  },
  {
    "id": "9107332002",
    "district_id": "910733",
    "label": "Dagai Dua",
    "value": "Dagai Dua"
  },
  {
    "id": "9107332003",
    "district_id": "910733",
    "label": "Deide",
    "value": "Deide"
  },
  {
    "id": "9107332004",
    "district_id": "910733",
    "label": "Farride",
    "value": "Farride"
  },
  {
    "id": "9107332005",
    "district_id": "910733",
    "label": "Soi",
    "value": "Soi"
  },
  {
    "id": "9107332006",
    "district_id": "910733",
    "label": "Mbomban",
    "value": "Mbomban"
  },
  {
    "id": "9107332007",
    "district_id": "910733",
    "label": "Yeihineri",
    "value": "Yeihineri"
  },
  {
    "id": "9107332008",
    "district_id": "910733",
    "label": "Mandara Lani",
    "value": "Mandara Lani"
  },
  {
    "id": "9107332009",
    "district_id": "910733",
    "label": "Debite",
    "value": "Debite"
  },
  {
    "id": "9107332010",
    "district_id": "910733",
    "label": "Gueri",
    "value": "Gueri"
  },
  {
    "id": "9107342001",
    "district_id": "910734",
    "label": "Kiyage",
    "value": "Kiyage"
  },
  {
    "id": "9107342002",
    "district_id": "910734",
    "label": "Wanggiba",
    "value": "Wanggiba"
  },
  {
    "id": "9107342003",
    "district_id": "910734",
    "label": "Amuringgik",
    "value": "Amuringgik"
  },
  {
    "id": "9107342004",
    "district_id": "910734",
    "label": "Tenggabanggwi",
    "value": "Tenggabanggwi"
  },
  {
    "id": "9107342005",
    "district_id": "910734",
    "label": "Tumbiwolu",
    "value": "Tumbiwolu"
  },
  {
    "id": "9107342006",
    "district_id": "910734",
    "label": "Kelandu",
    "value": "Kelandu"
  },
  {
    "id": "9107342007",
    "district_id": "910734",
    "label": "Ambo",
    "value": "Ambo"
  },
  {
    "id": "9107342008",
    "district_id": "910734",
    "label": "Atoli",
    "value": "Atoli"
  },
  {
    "id": "9108011003",
    "district_id": "910801",
    "label": "Enarotali",
    "value": "Enarotali"
  },
  {
    "id": "9108011023",
    "district_id": "910801",
    "label": "Timida",
    "value": "Timida"
  },
  {
    "id": "9108012004",
    "district_id": "910801",
    "label": "Madi",
    "value": "Madi"
  },
  {
    "id": "9108012013",
    "district_id": "910801",
    "label": "Aikai",
    "value": "Aikai"
  },
  {
    "id": "9108012014",
    "district_id": "910801",
    "label": "Ipakiye",
    "value": "Ipakiye"
  },
  {
    "id": "9108012016",
    "district_id": "910801",
    "label": "Papato",
    "value": "Papato"
  },
  {
    "id": "9108012024",
    "district_id": "910801",
    "label": "Kopo",
    "value": "Kopo"
  },
  {
    "id": "9108012025",
    "district_id": "910801",
    "label": "Wage",
    "value": "Wage"
  },
  {
    "id": "9108012030",
    "district_id": "910801",
    "label": "Kagupago",
    "value": "Kagupago"
  },
  {
    "id": "9108012033",
    "district_id": "910801",
    "label": "Oyaigo",
    "value": "Oyaigo"
  },
  {
    "id": "9108012034",
    "district_id": "910801",
    "label": "Kogekotu",
    "value": "Kogekotu"
  },
  {
    "id": "9108012039",
    "district_id": "910801",
    "label": "Awabutu",
    "value": "Awabutu"
  },
  {
    "id": "9108012046",
    "district_id": "910801",
    "label": "Geko",
    "value": "Geko"
  },
  {
    "id": "9108012047",
    "district_id": "910801",
    "label": "Mugimeketago",
    "value": "Mugimeketago"
  },
  {
    "id": "9108012048",
    "district_id": "910801",
    "label": "Tuamakida",
    "value": "Tuamakida"
  },
  {
    "id": "9108012050",
    "district_id": "910801",
    "label": "Geibu",
    "value": "Geibu"
  },
  {
    "id": "9108012056",
    "district_id": "910801",
    "label": "Pudotadi",
    "value": "Pudotadi"
  },
  {
    "id": "9108012057",
    "district_id": "910801",
    "label": "Kogetoku",
    "value": "Kogetoku"
  },
  {
    "id": "9108012059",
    "district_id": "910801",
    "label": "Iyaitaka",
    "value": "Iyaitaka"
  },
  {
    "id": "9108012063",
    "district_id": "910801",
    "label": "Ugibutu",
    "value": "Ugibutu"
  },
  {
    "id": "9108012064",
    "district_id": "910801",
    "label": "Wouyebutu",
    "value": "Wouyebutu"
  },
  {
    "id": "9108012070",
    "district_id": "910801",
    "label": "Amougibutu",
    "value": "Amougibutu"
  },
  {
    "id": "9108012078",
    "district_id": "910801",
    "label": "Dapaiba",
    "value": "Dapaiba"
  },
  {
    "id": "9108012079",
    "district_id": "910801",
    "label": "Yukekebo",
    "value": "Yukekebo"
  },
  {
    "id": "9108012080",
    "district_id": "910801",
    "label": "Agumaida",
    "value": "Agumaida"
  },
  {
    "id": "9108012082",
    "district_id": "910801",
    "label": "Yagiyo Butu",
    "value": "Yagiyo Butu"
  },
  {
    "id": "9108021001",
    "district_id": "910802",
    "label": "Obano",
    "value": "Obano"
  },
  {
    "id": "9108022002",
    "district_id": "910802",
    "label": "Kegouda",
    "value": "Kegouda"
  },
  {
    "id": "9108022021",
    "district_id": "910802",
    "label": "Totiyo",
    "value": "Totiyo"
  },
  {
    "id": "9108022023",
    "district_id": "910802",
    "label": "Beko",
    "value": "Beko"
  },
  {
    "id": "9108022027",
    "district_id": "910802",
    "label": "Tipakotu",
    "value": "Tipakotu"
  },
  {
    "id": "9108022039",
    "district_id": "910802",
    "label": "Epo Obano",
    "value": "Epo Obano"
  },
  {
    "id": "9108022041",
    "district_id": "910802",
    "label": "Tuwauwa",
    "value": "Tuwauwa"
  },
  {
    "id": "9108022042",
    "district_id": "910802",
    "label": "Wiyogei",
    "value": "Wiyogei"
  },
  {
    "id": "9108022043",
    "district_id": "910802",
    "label": "Ekinauwo",
    "value": "Ekinauwo"
  },
  {
    "id": "9108022044",
    "district_id": "910802",
    "label": "Kigodide",
    "value": "Kigodide"
  },
  {
    "id": "9108022045",
    "district_id": "910802",
    "label": "Putakogopa",
    "value": "Putakogopa"
  },
  {
    "id": "9108022050",
    "district_id": "910802",
    "label": "Woukotopa",
    "value": "Woukotopa"
  },
  {
    "id": "9108022058",
    "district_id": "910802",
    "label": "Digiugii",
    "value": "Digiugii"
  },
  {
    "id": "9108042004",
    "district_id": "910804",
    "label": "Toyaimoti",
    "value": "Toyaimoti"
  },
  {
    "id": "9108042005",
    "district_id": "910804",
    "label": "Agapo",
    "value": "Agapo"
  },
  {
    "id": "9108042007",
    "district_id": "910804",
    "label": "Abatadi",
    "value": "Abatadi"
  },
  {
    "id": "9108042009",
    "district_id": "910804",
    "label": "Dauwagu",
    "value": "Dauwagu"
  },
  {
    "id": "9108042014",
    "district_id": "910804",
    "label": "Baguwo",
    "value": "Baguwo"
  },
  {
    "id": "9108042015",
    "district_id": "910804",
    "label": "Makidini",
    "value": "Makidini"
  },
  {
    "id": "9108042034",
    "district_id": "910804",
    "label": "Tagiya",
    "value": "Tagiya"
  },
  {
    "id": "9108042036",
    "district_id": "910804",
    "label": "Emai",
    "value": "Emai"
  },
  {
    "id": "9108042038",
    "district_id": "910804",
    "label": "Dinubutu",
    "value": "Dinubutu"
  },
  {
    "id": "9108042039",
    "district_id": "910804",
    "label": "Bodatadi",
    "value": "Bodatadi"
  },
  {
    "id": "9108042040",
    "district_id": "910804",
    "label": "Ibouwagu",
    "value": "Ibouwagu"
  },
  {
    "id": "9108042041",
    "district_id": "910804",
    "label": "Ganeyetaka",
    "value": "Ganeyetaka"
  },
  {
    "id": "9108042044",
    "district_id": "910804",
    "label": "Duwadide",
    "value": "Duwadide"
  },
  {
    "id": "9108072001",
    "district_id": "910807",
    "label": "Bogobaida",
    "value": "Bogobaida"
  },
  {
    "id": "9108072002",
    "district_id": "910807",
    "label": "Nawipauwo",
    "value": "Nawipauwo"
  },
  {
    "id": "9108072013",
    "district_id": "910807",
    "label": "Madou",
    "value": "Madou"
  },
  {
    "id": "9108072016",
    "district_id": "910807",
    "label": "Jibudide II",
    "value": "Jibudide II"
  },
  {
    "id": "9108072017",
    "district_id": "910807",
    "label": "Yumauwo",
    "value": "Yumauwo"
  },
  {
    "id": "9108072024",
    "district_id": "910807",
    "label": "Kagoudide",
    "value": "Kagoudide"
  },
  {
    "id": "9108072025",
    "district_id": "910807",
    "label": "Eyapouda",
    "value": "Eyapouda"
  },
  {
    "id": "9108072026",
    "district_id": "910807",
    "label": "Miyokepi",
    "value": "Miyokepi"
  },
  {
    "id": "9108072027",
    "district_id": "910807",
    "label": "Obaya Dimi",
    "value": "Obaya Dimi"
  },
  {
    "id": "9108091001",
    "district_id": "910809",
    "label": "Bibida",
    "value": "Bibida"
  },
  {
    "id": "9108092002",
    "district_id": "910809",
    "label": "Ugidimi",
    "value": "Ugidimi"
  },
  {
    "id": "9108092017",
    "district_id": "910809",
    "label": "Odiyai",
    "value": "Odiyai"
  },
  {
    "id": "9108092018",
    "district_id": "910809",
    "label": "Tuwaktu",
    "value": "Tuwaktu"
  },
  {
    "id": "9108092019",
    "district_id": "910809",
    "label": "Kugaisiga",
    "value": "Kugaisiga"
  },
  {
    "id": "9108092020",
    "district_id": "910809",
    "label": "Kolaitaka",
    "value": "Kolaitaka"
  },
  {
    "id": "9108092021",
    "district_id": "910809",
    "label": "Dama Dama",
    "value": "Dama Dama"
  },
  {
    "id": "9108122001",
    "district_id": "910812",
    "label": "Duma",
    "value": "Duma"
  },
  {
    "id": "9108122003",
    "district_id": "910812",
    "label": "Bomosiga",
    "value": "Bomosiga"
  },
  {
    "id": "9108122004",
    "district_id": "910812",
    "label": "Japesigaiwaka",
    "value": "Japesigaiwaka"
  },
  {
    "id": "9108122005",
    "district_id": "910812",
    "label": "Waitapa",
    "value": "Waitapa"
  },
  {
    "id": "9108122009",
    "district_id": "910812",
    "label": "Dama II",
    "value": "Dama II"
  },
  {
    "id": "9108132001",
    "district_id": "910813",
    "label": "Tadia /Ayaikebo",
    "value": "Tadia /Ayaikebo"
  },
  {
    "id": "9108132002",
    "district_id": "910813",
    "label": "Dadou/Siriwo",
    "value": "Dadou/Siriwo"
  },
  {
    "id": "9108132003",
    "district_id": "910813",
    "label": "Bua/Ikotu",
    "value": "Bua/Ikotu"
  },
  {
    "id": "9108132004",
    "district_id": "910813",
    "label": "Kepi",
    "value": "Kepi"
  },
  {
    "id": "9108132005",
    "district_id": "910813",
    "label": "Wageme",
    "value": "Wageme"
  },
  {
    "id": "9108132006",
    "district_id": "910813",
    "label": "Bugubutu",
    "value": "Bugubutu"
  },
  {
    "id": "9108132007",
    "district_id": "910813",
    "label": "Dogomouto",
    "value": "Dogomouto"
  },
  {
    "id": "9108132008",
    "district_id": "910813",
    "label": "Giwo",
    "value": "Giwo"
  },
  {
    "id": "9108132009",
    "district_id": "910813",
    "label": "Nomokepota",
    "value": "Nomokepota"
  },
  {
    "id": "9108132010",
    "district_id": "910813",
    "label": "Eguai",
    "value": "Eguai"
  },
  {
    "id": "9108132011",
    "district_id": "910813",
    "label": "Degatadi",
    "value": "Degatadi"
  },
  {
    "id": "9108132012",
    "district_id": "910813",
    "label": "Uwoyupi",
    "value": "Uwoyupi"
  },
  {
    "id": "9108132013",
    "district_id": "910813",
    "label": "Wegekebo",
    "value": "Wegekebo"
  },
  {
    "id": "9108132014",
    "district_id": "910813",
    "label": "Yinotadi",
    "value": "Yinotadi"
  },
  {
    "id": "9108192001",
    "district_id": "910819",
    "label": "Kebo II",
    "value": "Kebo II"
  },
  {
    "id": "9108192004",
    "district_id": "910819",
    "label": "Togowa",
    "value": "Togowa"
  },
  {
    "id": "9108192005",
    "district_id": "910819",
    "label": "Ibumaida",
    "value": "Ibumaida"
  },
  {
    "id": "9108192009",
    "district_id": "910819",
    "label": "Kagupagu",
    "value": "Kagupagu"
  },
  {
    "id": "9108192010",
    "district_id": "910819",
    "label": "Ayaiga",
    "value": "Ayaiga"
  },
  {
    "id": "9108192012",
    "district_id": "910819",
    "label": "Geibu",
    "value": "Geibu"
  },
  {
    "id": "9108192013",
    "district_id": "910819",
    "label": "Yokadagi",
    "value": "Yokadagi"
  },
  {
    "id": "9108192014",
    "district_id": "910819",
    "label": "Bunaida",
    "value": "Bunaida"
  },
  {
    "id": "9108192017",
    "district_id": "910819",
    "label": "Kedege",
    "value": "Kedege"
  },
  {
    "id": "9108192018",
    "district_id": "910819",
    "label": "Uwaiye",
    "value": "Uwaiye"
  },
  {
    "id": "9108192019",
    "district_id": "910819",
    "label": "Dawawa",
    "value": "Dawawa"
  },
  {
    "id": "9108192020",
    "district_id": "910819",
    "label": "Mugimeketago",
    "value": "Mugimeketago"
  },
  {
    "id": "9108192021",
    "district_id": "910819",
    "label": "Tuwamakida",
    "value": "Tuwamakida"
  },
  {
    "id": "9108202002",
    "district_id": "910820",
    "label": "Wotai",
    "value": "Wotai"
  },
  {
    "id": "9108202004",
    "district_id": "910820",
    "label": "Keniapa",
    "value": "Keniapa"
  },
  {
    "id": "9108202005",
    "district_id": "910820",
    "label": "Memiyaibutu",
    "value": "Memiyaibutu"
  },
  {
    "id": "9108202006",
    "district_id": "910820",
    "label": "Dimiya",
    "value": "Dimiya"
  },
  {
    "id": "9108202011",
    "district_id": "910820",
    "label": "Udaugi",
    "value": "Udaugi"
  },
  {
    "id": "9108212002",
    "district_id": "910821",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9108212004",
    "district_id": "910821",
    "label": "Geida",
    "value": "Geida"
  },
  {
    "id": "9108212005",
    "district_id": "910821",
    "label": "Widimeida",
    "value": "Widimeida"
  },
  {
    "id": "9108212006",
    "district_id": "910821",
    "label": "Makidimi",
    "value": "Makidimi"
  },
  {
    "id": "9108212007",
    "district_id": "910821",
    "label": "Iteuwo",
    "value": "Iteuwo"
  },
  {
    "id": "9108212008",
    "district_id": "910821",
    "label": "Ogeida",
    "value": "Ogeida"
  },
  {
    "id": "9108212011",
    "district_id": "910821",
    "label": "Teguogi Pugaida",
    "value": "Teguogi Pugaida"
  },
  {
    "id": "9108212012",
    "district_id": "910821",
    "label": "Dabamomaida",
    "value": "Dabamomaida"
  },
  {
    "id": "9108212013",
    "district_id": "910821",
    "label": "Baguwo",
    "value": "Baguwo"
  },
  {
    "id": "9108212014",
    "district_id": "910821",
    "label": "Keneugi",
    "value": "Keneugi"
  },
  {
    "id": "9108212021",
    "district_id": "910821",
    "label": "Debamomaida",
    "value": "Debamomaida"
  },
  {
    "id": "9108212022",
    "district_id": "910821",
    "label": "Tegougipugaida",
    "value": "Tegougipugaida"
  },
  {
    "id": "9108221006",
    "district_id": "910822",
    "label": "Badawo",
    "value": "Badawo"
  },
  {
    "id": "9108222001",
    "district_id": "910822",
    "label": "Uwamani",
    "value": "Uwamani"
  },
  {
    "id": "9108222002",
    "district_id": "910822",
    "label": "Muyadebe",
    "value": "Muyadebe"
  },
  {
    "id": "9108222003",
    "district_id": "910822",
    "label": "Woubutu",
    "value": "Woubutu"
  },
  {
    "id": "9108222004",
    "district_id": "910822",
    "label": "Kugitadi",
    "value": "Kugitadi"
  },
  {
    "id": "9108222005",
    "district_id": "910822",
    "label": "Kobauyagapa",
    "value": "Kobauyagapa"
  },
  {
    "id": "9108222007",
    "district_id": "910822",
    "label": "Geko",
    "value": "Geko"
  },
  {
    "id": "9108222008",
    "district_id": "910822",
    "label": "Kinou",
    "value": "Kinou"
  },
  {
    "id": "9108222009",
    "district_id": "910822",
    "label": "Toko",
    "value": "Toko"
  },
  {
    "id": "9108222010",
    "district_id": "910822",
    "label": "Yagiyo Butu",
    "value": "Yagiyo Butu"
  },
  {
    "id": "9108222011",
    "district_id": "910822",
    "label": "Obaidagi",
    "value": "Obaidagi"
  },
  {
    "id": "9108232001",
    "district_id": "910823",
    "label": "Dei",
    "value": "Dei"
  },
  {
    "id": "9108232002",
    "district_id": "910823",
    "label": "Obaiyoweta",
    "value": "Obaiyoweta"
  },
  {
    "id": "9108232003",
    "district_id": "910823",
    "label": "Bubugiwo",
    "value": "Bubugiwo"
  },
  {
    "id": "9108232004",
    "district_id": "910823",
    "label": "Yimouto",
    "value": "Yimouto"
  },
  {
    "id": "9108232005",
    "district_id": "910823",
    "label": "Tamugauwo",
    "value": "Tamugauwo"
  },
  {
    "id": "9108232006",
    "district_id": "910823",
    "label": "Dagouto",
    "value": "Dagouto"
  },
  {
    "id": "9108232007",
    "district_id": "910823",
    "label": "Kopabutu",
    "value": "Kopabutu"
  },
  {
    "id": "9108232008",
    "district_id": "910823",
    "label": "Boutai",
    "value": "Boutai"
  },
  {
    "id": "9108232009",
    "district_id": "910823",
    "label": "Bukaduata",
    "value": "Bukaduata"
  },
  {
    "id": "9108242001",
    "district_id": "910824",
    "label": "Pugo",
    "value": "Pugo"
  },
  {
    "id": "9108242002",
    "district_id": "910824",
    "label": "Waidide",
    "value": "Waidide"
  },
  {
    "id": "9108242003",
    "district_id": "910824",
    "label": "Kopaidagi",
    "value": "Kopaidagi"
  },
  {
    "id": "9108242004",
    "district_id": "910824",
    "label": "Uwibutu",
    "value": "Uwibutu"
  },
  {
    "id": "9108242005",
    "district_id": "910824",
    "label": "Tipago",
    "value": "Tipago"
  },
  {
    "id": "9108242006",
    "district_id": "910824",
    "label": "Boba",
    "value": "Boba"
  },
  {
    "id": "9108242007",
    "district_id": "910824",
    "label": "Iyaidagi",
    "value": "Iyaidagi"
  },
  {
    "id": "9108242008",
    "district_id": "910824",
    "label": "Iyaitadi",
    "value": "Iyaitadi"
  },
  {
    "id": "9108242009",
    "district_id": "910824",
    "label": "Waiepo",
    "value": "Waiepo"
  },
  {
    "id": "9108252001",
    "district_id": "910825",
    "label": "Muyetadi",
    "value": "Muyetadi"
  },
  {
    "id": "9108252002",
    "district_id": "910825",
    "label": "Mogeya",
    "value": "Mogeya"
  },
  {
    "id": "9108252003",
    "district_id": "910825",
    "label": "Epobutu",
    "value": "Epobutu"
  },
  {
    "id": "9108252004",
    "district_id": "910825",
    "label": "Tiga Damuto",
    "value": "Tiga Damuto"
  },
  {
    "id": "9108252005",
    "district_id": "910825",
    "label": "Bebiyai",
    "value": "Bebiyai"
  },
  {
    "id": "9108252006",
    "district_id": "910825",
    "label": "Idaiyo",
    "value": "Idaiyo"
  },
  {
    "id": "9108252007",
    "district_id": "910825",
    "label": "Tinou",
    "value": "Tinou"
  },
  {
    "id": "9108252008",
    "district_id": "910825",
    "label": "Damuto",
    "value": "Damuto"
  },
  {
    "id": "9108252009",
    "district_id": "910825",
    "label": "Obaiyodimi",
    "value": "Obaiyodimi"
  },
  {
    "id": "9108252010",
    "district_id": "910825",
    "label": "Amopa",
    "value": "Amopa"
  },
  {
    "id": "9108262001",
    "district_id": "910826",
    "label": "Okeitadi",
    "value": "Okeitadi"
  },
  {
    "id": "9108262002",
    "district_id": "910826",
    "label": "Kito",
    "value": "Kito"
  },
  {
    "id": "9108262003",
    "district_id": "910826",
    "label": "Geiketago",
    "value": "Geiketago"
  },
  {
    "id": "9108262004",
    "district_id": "910826",
    "label": "Kadiyai",
    "value": "Kadiyai"
  },
  {
    "id": "9108262005",
    "district_id": "910826",
    "label": "Uwodege",
    "value": "Uwodege"
  },
  {
    "id": "9108262006",
    "district_id": "910826",
    "label": "Digiyo Tuwataida",
    "value": "Digiyo Tuwataida"
  },
  {
    "id": "9108262007",
    "district_id": "910826",
    "label": "Itoka",
    "value": "Itoka"
  },
  {
    "id": "9108272001",
    "district_id": "910827",
    "label": "Ukauwo",
    "value": "Ukauwo"
  },
  {
    "id": "9108272002",
    "district_id": "910827",
    "label": "Waipa",
    "value": "Waipa"
  },
  {
    "id": "9108272003",
    "district_id": "910827",
    "label": "Ukago",
    "value": "Ukago"
  },
  {
    "id": "9108272004",
    "district_id": "910827",
    "label": "Totiyo",
    "value": "Totiyo"
  },
  {
    "id": "9108272005",
    "district_id": "910827",
    "label": "Biyadide",
    "value": "Biyadide"
  },
  {
    "id": "9108272006",
    "district_id": "910827",
    "label": "Pekege",
    "value": "Pekege"
  },
  {
    "id": "9108282001",
    "district_id": "910828",
    "label": "Panibagata",
    "value": "Panibagata"
  },
  {
    "id": "9108282002",
    "district_id": "910828",
    "label": "Pugaida",
    "value": "Pugaida"
  },
  {
    "id": "9108282003",
    "district_id": "910828",
    "label": "Awebutu",
    "value": "Awebutu"
  },
  {
    "id": "9108282004",
    "district_id": "910828",
    "label": "Ipuwa",
    "value": "Ipuwa"
  },
  {
    "id": "9108282005",
    "district_id": "910828",
    "label": "Munayepa",
    "value": "Munayepa"
  },
  {
    "id": "9108282006",
    "district_id": "910828",
    "label": "Kebo I",
    "value": "Kebo I"
  },
  {
    "id": "9108282007",
    "district_id": "910828",
    "label": "Payogei",
    "value": "Payogei"
  },
  {
    "id": "9108282008",
    "district_id": "910828",
    "label": "Detauwo",
    "value": "Detauwo"
  },
  {
    "id": "9108282009",
    "district_id": "910828",
    "label": "Yaibu",
    "value": "Yaibu"
  },
  {
    "id": "9108282010",
    "district_id": "910828",
    "label": "Woyouto",
    "value": "Woyouto"
  },
  {
    "id": "9108292001",
    "district_id": "910829",
    "label": "Youtadi",
    "value": "Youtadi"
  },
  {
    "id": "9108292002",
    "district_id": "910829",
    "label": "Uwagi",
    "value": "Uwagi"
  },
  {
    "id": "9108292003",
    "district_id": "910829",
    "label": "Ipouwa",
    "value": "Ipouwa"
  },
  {
    "id": "9108292004",
    "district_id": "910829",
    "label": "Munaiyepa",
    "value": "Munaiyepa"
  },
  {
    "id": "9108292005",
    "district_id": "910829",
    "label": "Dokoneida",
    "value": "Dokoneida"
  },
  {
    "id": "9108292006",
    "district_id": "910829",
    "label": "Namutadi",
    "value": "Namutadi"
  },
  {
    "id": "9108302001",
    "district_id": "910830",
    "label": "Nomouwodide",
    "value": "Nomouwodide"
  },
  {
    "id": "9108302002",
    "district_id": "910830",
    "label": "Pikokebo",
    "value": "Pikokebo"
  },
  {
    "id": "9108302003",
    "district_id": "910830",
    "label": "Bayabiru",
    "value": "Bayabiru"
  },
  {
    "id": "9108302004",
    "district_id": "910830",
    "label": "Jigitopa Timur",
    "value": "Jigitopa Timur"
  },
  {
    "id": "9108302005",
    "district_id": "910830",
    "label": "Maatadi",
    "value": "Maatadi"
  },
  {
    "id": "9108302006",
    "district_id": "910830",
    "label": "Pupekebo",
    "value": "Pupekebo"
  },
  {
    "id": "9108312001",
    "district_id": "910831",
    "label": "Uwebutu",
    "value": "Uwebutu"
  },
  {
    "id": "9108312002",
    "district_id": "910831",
    "label": "Epouto",
    "value": "Epouto"
  },
  {
    "id": "9108312003",
    "district_id": "910831",
    "label": "Tugumo",
    "value": "Tugumo"
  },
  {
    "id": "9108312004",
    "district_id": "910831",
    "label": "Tugu Kagomoma",
    "value": "Tugu Kagomoma"
  },
  {
    "id": "9108312005",
    "district_id": "910831",
    "label": "Deiyamo",
    "value": "Deiyamo"
  },
  {
    "id": "9108312006",
    "district_id": "910831",
    "label": "Miyamo",
    "value": "Miyamo"
  },
  {
    "id": "9108322001",
    "district_id": "910832",
    "label": "Dogomo",
    "value": "Dogomo"
  },
  {
    "id": "9108322002",
    "district_id": "910832",
    "label": "Bomong Bugopa",
    "value": "Bomong Bugopa"
  },
  {
    "id": "9108322003",
    "district_id": "910832",
    "label": "Danggipa",
    "value": "Danggipa"
  },
  {
    "id": "9108322004",
    "district_id": "910832",
    "label": "Ondegapa",
    "value": "Ondegapa"
  },
  {
    "id": "9108322005",
    "district_id": "910832",
    "label": "Sogomo",
    "value": "Sogomo"
  },
  {
    "id": "9108332001",
    "district_id": "910833",
    "label": "Deta",
    "value": "Deta"
  },
  {
    "id": "9108332002",
    "district_id": "910833",
    "label": "Tuguwai",
    "value": "Tuguwai"
  },
  {
    "id": "9108332003",
    "district_id": "910833",
    "label": "Tuguwaimoma",
    "value": "Tuguwaimoma"
  },
  {
    "id": "9108332004",
    "district_id": "910833",
    "label": "Bumaida",
    "value": "Bumaida"
  },
  {
    "id": "9108332005",
    "district_id": "910833",
    "label": "Debaiye",
    "value": "Debaiye"
  },
  {
    "id": "9108332006",
    "district_id": "910833",
    "label": "Akoubaida",
    "value": "Akoubaida"
  },
  {
    "id": "9108332007",
    "district_id": "910833",
    "label": "Komopa",
    "value": "Komopa"
  },
  {
    "id": "9108332008",
    "district_id": "910833",
    "label": "Yabomaida",
    "value": "Yabomaida"
  },
  {
    "id": "9108332009",
    "district_id": "910833",
    "label": "Yabomaida II",
    "value": "Yabomaida II"
  },
  {
    "id": "9108342001",
    "district_id": "910834",
    "label": "Obaipugaida",
    "value": "Obaipugaida"
  },
  {
    "id": "9108342002",
    "district_id": "910834",
    "label": "Eyagitaida",
    "value": "Eyagitaida"
  },
  {
    "id": "9108342003",
    "district_id": "910834",
    "label": "Momageida",
    "value": "Momageida"
  },
  {
    "id": "9108342004",
    "district_id": "910834",
    "label": "Ekauwiya",
    "value": "Ekauwiya"
  },
  {
    "id": "9108342005",
    "district_id": "910834",
    "label": "Debakebouda",
    "value": "Debakebouda"
  },
  {
    "id": "9108342006",
    "district_id": "910834",
    "label": "Kaidoutadi",
    "value": "Kaidoutadi"
  },
  {
    "id": "9108342007",
    "district_id": "910834",
    "label": "Pogeidimi",
    "value": "Pogeidimi"
  },
  {
    "id": "9108342008",
    "district_id": "910834",
    "label": "Okonobaida",
    "value": "Okonobaida"
  },
  {
    "id": "9108342009",
    "district_id": "910834",
    "label": "Gakokotu",
    "value": "Gakokotu"
  },
  {
    "id": "9108342010",
    "district_id": "910834",
    "label": "Kegama",
    "value": "Kegama"
  },
  {
    "id": "9109011001",
    "district_id": "910901",
    "label": "Koperapoka",
    "value": "Koperapoka"
  },
  {
    "id": "9109011002",
    "district_id": "910901",
    "label": "Kwamki",
    "value": "Kwamki"
  },
  {
    "id": "9109011007",
    "district_id": "910901",
    "label": "Timika Jaya",
    "value": "Timika Jaya"
  },
  {
    "id": "9109011026",
    "district_id": "910901",
    "label": "Sempan",
    "value": "Sempan"
  },
  {
    "id": "9109011027",
    "district_id": "910901",
    "label": "Pasar Sentral",
    "value": "Pasar Sentral"
  },
  {
    "id": "9109011028",
    "district_id": "910901",
    "label": "Wanagon",
    "value": "Wanagon"
  },
  {
    "id": "9109011029",
    "district_id": "910901",
    "label": "Kebun Sirih",
    "value": "Kebun Sirih"
  },
  {
    "id": "9109011030",
    "district_id": "910901",
    "label": "Otomona",
    "value": "Otomona"
  },
  {
    "id": "9109011031",
    "district_id": "910901",
    "label": "Perintis",
    "value": "Perintis"
  },
  {
    "id": "9109011032",
    "district_id": "910901",
    "label": "Dingo Narama",
    "value": "Dingo Narama"
  },
  {
    "id": "9109011033",
    "district_id": "910901",
    "label": "Timika Indah",
    "value": "Timika Indah"
  },
  {
    "id": "9109012010",
    "district_id": "910901",
    "label": "Nayaro",
    "value": "Nayaro"
  },
  {
    "id": "9109012012",
    "district_id": "910901",
    "label": "Minabua",
    "value": "Minabua"
  },
  {
    "id": "9109012013",
    "district_id": "910901",
    "label": "Hangaitji",
    "value": "Hangaitji"
  },
  {
    "id": "9109022001",
    "district_id": "910902",
    "label": "Kiliarma",
    "value": "Kiliarma"
  },
  {
    "id": "9109022002",
    "district_id": "910902",
    "label": "Amungun",
    "value": "Amungun"
  },
  {
    "id": "9109022003",
    "district_id": "910902",
    "label": "Aramsolki",
    "value": "Aramsolki"
  },
  {
    "id": "9109022004",
    "district_id": "910902",
    "label": "Fakafuku",
    "value": "Fakafuku"
  },
  {
    "id": "9109022005",
    "district_id": "910902",
    "label": "Masasimamo",
    "value": "Masasimamo"
  },
  {
    "id": "9109022006",
    "district_id": "910902",
    "label": "Emogoma",
    "value": "Emogoma"
  },
  {
    "id": "9109022007",
    "district_id": "910902",
    "label": "Emkoma Halama",
    "value": "Emkoma Halama"
  },
  {
    "id": "9109022008",
    "district_id": "910902",
    "label": "Hinat Untung",
    "value": "Hinat Untung"
  },
  {
    "id": "9109031001",
    "district_id": "910903",
    "label": "Wania",
    "value": "Wania"
  },
  {
    "id": "9109032002",
    "district_id": "910903",
    "label": "Kaugapa",
    "value": "Kaugapa"
  },
  {
    "id": "9109032003",
    "district_id": "910903",
    "label": "Poumako",
    "value": "Poumako"
  },
  {
    "id": "9109032004",
    "district_id": "910903",
    "label": "Tipuka",
    "value": "Tipuka"
  },
  {
    "id": "9109032005",
    "district_id": "910903",
    "label": "Muare",
    "value": "Muare"
  },
  {
    "id": "9109032007",
    "district_id": "910903",
    "label": "Hiripau",
    "value": "Hiripau"
  },
  {
    "id": "9109042001",
    "district_id": "910904",
    "label": "Kokonao",
    "value": "Kokonao"
  },
  {
    "id": "9109042002",
    "district_id": "910904",
    "label": "Migiwia",
    "value": "Migiwia"
  },
  {
    "id": "9109042003",
    "district_id": "910904",
    "label": "Kiura",
    "value": "Kiura"
  },
  {
    "id": "9109042004",
    "district_id": "910904",
    "label": "Mimika",
    "value": "Mimika"
  },
  {
    "id": "9109042011",
    "district_id": "910904",
    "label": "Aparuka",
    "value": "Aparuka"
  },
  {
    "id": "9109042012",
    "district_id": "910904",
    "label": "Atapo",
    "value": "Atapo"
  },
  {
    "id": "9109042013",
    "district_id": "910904",
    "label": "Apuri",
    "value": "Apuri"
  },
  {
    "id": "9109052001",
    "district_id": "910905",
    "label": "Sempan Timur",
    "value": "Sempan Timur"
  },
  {
    "id": "9109052002",
    "district_id": "910905",
    "label": "Wenin",
    "value": "Wenin"
  },
  {
    "id": "9109052003",
    "district_id": "910905",
    "label": "Noema",
    "value": "Noema"
  },
  {
    "id": "9109052004",
    "district_id": "910905",
    "label": "Wapu",
    "value": "Wapu"
  },
  {
    "id": "9109052005",
    "district_id": "910905",
    "label": "Sumapro",
    "value": "Sumapro"
  },
  {
    "id": "9109052006",
    "district_id": "910905",
    "label": "Wacakam",
    "value": "Wacakam"
  },
  {
    "id": "9109052007",
    "district_id": "910905",
    "label": "Waituku",
    "value": "Waituku"
  },
  {
    "id": "9109052008",
    "district_id": "910905",
    "label": "Bulumen",
    "value": "Bulumen"
  },
  {
    "id": "9109052009",
    "district_id": "910905",
    "label": "Kanmapiri",
    "value": "Kanmapiri"
  },
  {
    "id": "9109052010",
    "district_id": "910905",
    "label": "Jaitak",
    "value": "Jaitak"
  },
  {
    "id": "9109062001",
    "district_id": "910906",
    "label": "Jila",
    "value": "Jila"
  },
  {
    "id": "9109062002",
    "district_id": "910906",
    "label": "Diloa",
    "value": "Diloa"
  },
  {
    "id": "9109062003",
    "district_id": "910906",
    "label": "Noemun",
    "value": "Noemun"
  },
  {
    "id": "9109062006",
    "district_id": "910906",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9109062007",
    "district_id": "910906",
    "label": "Jengkon",
    "value": "Jengkon"
  },
  {
    "id": "9109062008",
    "district_id": "910906",
    "label": "Pilik Ogom",
    "value": "Pilik Ogom"
  },
  {
    "id": "9109062009",
    "district_id": "910906",
    "label": "Bunaraugin",
    "value": "Bunaraugin"
  },
  {
    "id": "9109062010",
    "district_id": "910906",
    "label": "Umpliga",
    "value": "Umpliga"
  },
  {
    "id": "9109062011",
    "district_id": "910906",
    "label": "Amua Ogom",
    "value": "Amua Ogom"
  },
  {
    "id": "9109062022",
    "district_id": "910906",
    "label": "Pusuwe",
    "value": "Pusuwe"
  },
  {
    "id": "9109062024",
    "district_id": "910906",
    "label": "Wandud",
    "value": "Wandud"
  },
  {
    "id": "9109062025",
    "district_id": "910906",
    "label": "Diloa Dua",
    "value": "Diloa Dua"
  },
  {
    "id": "9109072001",
    "district_id": "910907",
    "label": "Ayuka",
    "value": "Ayuka"
  },
  {
    "id": "9109072002",
    "district_id": "910907",
    "label": "Amamapare",
    "value": "Amamapare"
  },
  {
    "id": "9109072003",
    "district_id": "910907",
    "label": "Ohotya",
    "value": "Ohotya"
  },
  {
    "id": "9109072004",
    "district_id": "910907",
    "label": "Omawita",
    "value": "Omawita"
  },
  {
    "id": "9109072005",
    "district_id": "910907",
    "label": "Fanamo",
    "value": "Fanamo"
  },
  {
    "id": "9109082001",
    "district_id": "910908",
    "label": "Atuka",
    "value": "Atuka"
  },
  {
    "id": "9109082002",
    "district_id": "910908",
    "label": "Tiwaka",
    "value": "Tiwaka"
  },
  {
    "id": "9109082003",
    "district_id": "910908",
    "label": "Keakwa",
    "value": "Keakwa"
  },
  {
    "id": "9109082004",
    "district_id": "910908",
    "label": "Aikawapuka",
    "value": "Aikawapuka"
  },
  {
    "id": "9109082005",
    "district_id": "910908",
    "label": "Kamoro",
    "value": "Kamoro"
  },
  {
    "id": "9109091001",
    "district_id": "910909",
    "label": "Kuala Kencana",
    "value": "Kuala Kencana"
  },
  {
    "id": "9109091005",
    "district_id": "910909",
    "label": "Karang Senang",
    "value": "Karang Senang"
  },
  {
    "id": "9109092006",
    "district_id": "910909",
    "label": "Utikini Baru",
    "value": "Utikini Baru"
  },
  {
    "id": "9109092007",
    "district_id": "910909",
    "label": "Bhintuka",
    "value": "Bhintuka"
  },
  {
    "id": "9109092008",
    "district_id": "910909",
    "label": "Tioka Kencana",
    "value": "Tioka Kencana"
  },
  {
    "id": "9109092009",
    "district_id": "910909",
    "label": "Mimika Gunung",
    "value": "Mimika Gunung"
  },
  {
    "id": "9109092010",
    "district_id": "910909",
    "label": "Karya Kencana",
    "value": "Karya Kencana"
  },
  {
    "id": "9109092011",
    "district_id": "910909",
    "label": "Utikini Dua",
    "value": "Utikini Dua"
  },
  {
    "id": "9109092012",
    "district_id": "910909",
    "label": "Utikini Tiga",
    "value": "Utikini Tiga"
  },
  {
    "id": "9109092013",
    "district_id": "910909",
    "label": "Jimbi",
    "value": "Jimbi"
  },
  {
    "id": "9109101001",
    "district_id": "910910",
    "label": "Tembagapura",
    "value": "Tembagapura"
  },
  {
    "id": "9109102002",
    "district_id": "910910",
    "label": "Waa",
    "value": "Waa"
  },
  {
    "id": "9109102003",
    "district_id": "910910",
    "label": "Arwanop",
    "value": "Arwanop"
  },
  {
    "id": "9109102004",
    "district_id": "910910",
    "label": "T'Singa",
    "value": "T'Singa"
  },
  {
    "id": "9109102006",
    "district_id": "910910",
    "label": "Jagamin",
    "value": "Jagamin"
  },
  {
    "id": "9109102007",
    "district_id": "910910",
    "label": "Banigogom",
    "value": "Banigogom"
  },
  {
    "id": "9109102008",
    "district_id": "910910",
    "label": "Opitawak",
    "value": "Opitawak"
  },
  {
    "id": "9109102009",
    "district_id": "910910",
    "label": "Doliningokngin",
    "value": "Doliningokngin"
  },
  {
    "id": "9109102013",
    "district_id": "910910",
    "label": "Banti Dua",
    "value": "Banti Dua"
  },
  {
    "id": "9109102014",
    "district_id": "910910",
    "label": "Aingogin",
    "value": "Aingogin"
  },
  {
    "id": "9109102015",
    "district_id": "910910",
    "label": "Baluni",
    "value": "Baluni"
  },
  {
    "id": "9109102016",
    "district_id": "910910",
    "label": "Noselanop",
    "value": "Noselanop"
  },
  {
    "id": "9109102017",
    "district_id": "910910",
    "label": "Meningimponogama",
    "value": "Meningimponogama"
  },
  {
    "id": "9109102018",
    "district_id": "910910",
    "label": "Jongkogama",
    "value": "Jongkogama"
  },
  {
    "id": "9109112001",
    "district_id": "910911",
    "label": "Potowai Buru",
    "value": "Potowai Buru"
  },
  {
    "id": "9109112002",
    "district_id": "910911",
    "label": "Yapkoka",
    "value": "Yapkoka"
  },
  {
    "id": "9109112003",
    "district_id": "910911",
    "label": "Aindua",
    "value": "Aindua"
  },
  {
    "id": "9109112004",
    "district_id": "910911",
    "label": "Tapomai",
    "value": "Tapomai"
  },
  {
    "id": "9109112005",
    "district_id": "910911",
    "label": "Umar",
    "value": "Umar"
  },
  {
    "id": "9109122001",
    "district_id": "910912",
    "label": "Kapiraya",
    "value": "Kapiraya"
  },
  {
    "id": "9109122002",
    "district_id": "910912",
    "label": "Uta",
    "value": "Uta"
  },
  {
    "id": "9109122003",
    "district_id": "910912",
    "label": "Mupuruka",
    "value": "Mupuruka"
  },
  {
    "id": "9109122004",
    "district_id": "910912",
    "label": "Wumuka",
    "value": "Wumuka"
  },
  {
    "id": "9109122005",
    "district_id": "910912",
    "label": "Akar",
    "value": "Akar"
  },
  {
    "id": "9109122006",
    "district_id": "910912",
    "label": "Mapar",
    "value": "Mapar"
  },
  {
    "id": "9109122007",
    "district_id": "910912",
    "label": "Kipia",
    "value": "Kipia"
  },
  {
    "id": "9109122008",
    "district_id": "910912",
    "label": "Pronggo",
    "value": "Pronggo"
  },
  {
    "id": "9109122009",
    "district_id": "910912",
    "label": "Wakia",
    "value": "Wakia"
  },
  {
    "id": "9109131001",
    "district_id": "910913",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "9109132002",
    "district_id": "910913",
    "label": "Meekurima",
    "value": "Meekurima"
  },
  {
    "id": "9109132003",
    "district_id": "910913",
    "label": "Landun Mekar",
    "value": "Landun Mekar"
  },
  {
    "id": "9109132004",
    "district_id": "910913",
    "label": "Olaroa",
    "value": "Olaroa"
  },
  {
    "id": "9109132005",
    "district_id": "910913",
    "label": "Bintang Lima",
    "value": "Bintang Lima"
  },
  {
    "id": "9109132006",
    "district_id": "910913",
    "label": "Damai",
    "value": "Damai"
  },
  {
    "id": "9109132007",
    "district_id": "910913",
    "label": "Walani",
    "value": "Walani"
  },
  {
    "id": "9109132008",
    "district_id": "910913",
    "label": "Amole",
    "value": "Amole"
  },
  {
    "id": "9109132009",
    "district_id": "910913",
    "label": "Lamopi",
    "value": "Lamopi"
  },
  {
    "id": "9109132010",
    "district_id": "910913",
    "label": "Tunas Matoa",
    "value": "Tunas Matoa"
  },
  {
    "id": "9109142001",
    "district_id": "910914",
    "label": "Hoya",
    "value": "Hoya"
  },
  {
    "id": "9109142002",
    "district_id": "910914",
    "label": "Mamontoga",
    "value": "Mamontoga"
  },
  {
    "id": "9109142003",
    "district_id": "910914",
    "label": "Jinonin",
    "value": "Jinonin"
  },
  {
    "id": "9109142004",
    "district_id": "910914",
    "label": "Kulama Ogom",
    "value": "Kulama Ogom"
  },
  {
    "id": "9109142005",
    "district_id": "910914",
    "label": "Puti",
    "value": "Puti"
  },
  {
    "id": "9109142006",
    "district_id": "910914",
    "label": "Jawa",
    "value": "Jawa"
  },
  {
    "id": "9109152001",
    "district_id": "910915",
    "label": "Limau Asri Timur",
    "value": "Limau Asri Timur"
  },
  {
    "id": "9109152002",
    "district_id": "910915",
    "label": "Wangirja",
    "value": "Wangirja"
  },
  {
    "id": "9109152003",
    "district_id": "910915",
    "label": "Iwaka",
    "value": "Iwaka"
  },
  {
    "id": "9109152004",
    "district_id": "910915",
    "label": "Naena Muktipura",
    "value": "Naena Muktipura"
  },
  {
    "id": "9109152005",
    "district_id": "910915",
    "label": "Mulia Kencana",
    "value": "Mulia Kencana"
  },
  {
    "id": "9109152006",
    "district_id": "910915",
    "label": "Pigapu",
    "value": "Pigapu"
  },
  {
    "id": "9109152007",
    "district_id": "910915",
    "label": "Limau Asri Barat",
    "value": "Limau Asri Barat"
  },
  {
    "id": "9109161002",
    "district_id": "910916",
    "label": "Kamoro Jaya",
    "value": "Kamoro Jaya"
  },
  {
    "id": "9109161003",
    "district_id": "910916",
    "label": "Wonosari Jaya",
    "value": "Wonosari Jaya"
  },
  {
    "id": "9109161005",
    "district_id": "910916",
    "label": "Inauga",
    "value": "Inauga"
  },
  {
    "id": "9109162001",
    "district_id": "910916",
    "label": "Kadun Jaya",
    "value": "Kadun Jaya"
  },
  {
    "id": "9109162004",
    "district_id": "910916",
    "label": "Nawaripi",
    "value": "Nawaripi"
  },
  {
    "id": "9109162006",
    "district_id": "910916",
    "label": "Mawokau Jaya",
    "value": "Mawokau Jaya"
  },
  {
    "id": "9109162007",
    "district_id": "910916",
    "label": "Mandiri Jaya",
    "value": "Mandiri Jaya"
  },
  {
    "id": "9109172001",
    "district_id": "910917",
    "label": "Amar",
    "value": "Amar"
  },
  {
    "id": "9109172002",
    "district_id": "910917",
    "label": "Kawar",
    "value": "Kawar"
  },
  {
    "id": "9109172003",
    "district_id": "910917",
    "label": "Manuare",
    "value": "Manuare"
  },
  {
    "id": "9109172004",
    "district_id": "910917",
    "label": "Ipiri",
    "value": "Ipiri"
  },
  {
    "id": "9109172005",
    "district_id": "910917",
    "label": "Paripi",
    "value": "Paripi"
  },
  {
    "id": "9109172006",
    "district_id": "910917",
    "label": "Yaraya",
    "value": "Yaraya"
  },
  {
    "id": "9109182001",
    "district_id": "910918",
    "label": "Enggin",
    "value": "Enggin"
  },
  {
    "id": "9109182002",
    "district_id": "910918",
    "label": "Alama",
    "value": "Alama"
  },
  {
    "id": "9109182003",
    "district_id": "910918",
    "label": "Senawak",
    "value": "Senawak"
  },
  {
    "id": "9109182004",
    "district_id": "910918",
    "label": "Unimogom",
    "value": "Unimogom"
  },
  {
    "id": "9109182005",
    "district_id": "910918",
    "label": "Bemoki",
    "value": "Bemoki"
  },
  {
    "id": "9109182006",
    "district_id": "910918",
    "label": "Tagaralma",
    "value": "Tagaralma"
  },
  {
    "id": "9109182007",
    "district_id": "910918",
    "label": "Geselema",
    "value": "Geselema"
  },
  {
    "id": "9109182008",
    "district_id": "910918",
    "label": "Wuarem",
    "value": "Wuarem"
  },
  {
    "id": "9109182009",
    "district_id": "910918",
    "label": "Jenggelo",
    "value": "Jenggelo"
  },
  {
    "id": "9109182010",
    "district_id": "910918",
    "label": "Purua",
    "value": "Purua"
  },
  {
    "id": "9109182011",
    "district_id": "910918",
    "label": "Kilmit",
    "value": "Kilmit"
  },
  {
    "id": "9110011001",
    "district_id": "911001",
    "label": "Mararena",
    "value": "Mararena"
  },
  {
    "id": "9110011002",
    "district_id": "911001",
    "label": "Sarmi Kota",
    "value": "Sarmi Kota"
  },
  {
    "id": "9110012003",
    "district_id": "911001",
    "label": "Sarmo",
    "value": "Sarmo"
  },
  {
    "id": "9110012004",
    "district_id": "911001",
    "label": "Liki",
    "value": "Liki"
  },
  {
    "id": "9110012006",
    "district_id": "911001",
    "label": "Sawar",
    "value": "Sawar"
  },
  {
    "id": "9110012007",
    "district_id": "911001",
    "label": "Bagaiserwar",
    "value": "Bagaiserwar"
  },
  {
    "id": "9110012014",
    "district_id": "911001",
    "label": "Armo",
    "value": "Armo"
  },
  {
    "id": "9110012018",
    "district_id": "911001",
    "label": "Bagaisewar II",
    "value": "Bagaisewar II"
  },
  {
    "id": "9110012019",
    "district_id": "911001",
    "label": "Pulau Armo",
    "value": "Pulau Armo"
  },
  {
    "id": "9110012020",
    "district_id": "911001",
    "label": "Lembah Neidam",
    "value": "Lembah Neidam"
  },
  {
    "id": "9110012021",
    "district_id": "911001",
    "label": "Tefarewar",
    "value": "Tefarewar"
  },
  {
    "id": "9110022001",
    "district_id": "911002",
    "label": "Bota-Bora",
    "value": "Bota-Bora"
  },
  {
    "id": "9110022002",
    "district_id": "911002",
    "label": "Toganto",
    "value": "Toganto"
  },
  {
    "id": "9110022003",
    "district_id": "911002",
    "label": "Samanente",
    "value": "Samanente"
  },
  {
    "id": "9110022004",
    "district_id": "911002",
    "label": "Kanderjan",
    "value": "Kanderjan"
  },
  {
    "id": "9110022005",
    "district_id": "911002",
    "label": "Omte",
    "value": "Omte"
  },
  {
    "id": "9110022006",
    "district_id": "911002",
    "label": "Safrom Tane",
    "value": "Safrom Tane"
  },
  {
    "id": "9110022007",
    "district_id": "911002",
    "label": "Denender",
    "value": "Denender"
  },
  {
    "id": "9110022009",
    "district_id": "911002",
    "label": "Safron Tane",
    "value": "Safron Tane"
  },
  {
    "id": "9110022010",
    "district_id": "911002",
    "label": "Denander",
    "value": "Denander"
  },
  {
    "id": "9110022011",
    "district_id": "911002",
    "label": "Waaf",
    "value": "Waaf"
  },
  {
    "id": "9110032005",
    "district_id": "911003",
    "label": "Mertewar",
    "value": "Mertewar"
  },
  {
    "id": "9110032006",
    "district_id": "911003",
    "label": "Samorkena",
    "value": "Samorkena"
  },
  {
    "id": "9110032007",
    "district_id": "911003",
    "label": "Arbais",
    "value": "Arbais"
  },
  {
    "id": "9110032008",
    "district_id": "911003",
    "label": "Kapeso",
    "value": "Kapeso"
  },
  {
    "id": "9110032009",
    "district_id": "911003",
    "label": "Masep",
    "value": "Masep"
  },
  {
    "id": "9110032012",
    "district_id": "911003",
    "label": "Nisro",
    "value": "Nisro"
  },
  {
    "id": "9110032013",
    "district_id": "911003",
    "label": "Aruswar",
    "value": "Aruswar"
  },
  {
    "id": "9110032014",
    "district_id": "911003",
    "label": "Kamenawari",
    "value": "Kamenawari"
  },
  {
    "id": "9110032015",
    "district_id": "911003",
    "label": "Waim",
    "value": "Waim"
  },
  {
    "id": "9110032016",
    "district_id": "911003",
    "label": "Subu",
    "value": "Subu"
  },
  {
    "id": "9110032017",
    "district_id": "911003",
    "label": "Karfasia",
    "value": "Karfasia"
  },
  {
    "id": "9110032023",
    "district_id": "911003",
    "label": "Burgena",
    "value": "Burgena"
  },
  {
    "id": "9110032024",
    "district_id": "911003",
    "label": "Webro",
    "value": "Webro"
  },
  {
    "id": "9110032025",
    "district_id": "911003",
    "label": "Siantoa",
    "value": "Siantoa"
  },
  {
    "id": "9110032026",
    "district_id": "911003",
    "label": "Niwerawar",
    "value": "Niwerawar"
  },
  {
    "id": "9110032027",
    "district_id": "911003",
    "label": "Wari",
    "value": "Wari"
  },
  {
    "id": "9110042001",
    "district_id": "911004",
    "label": "Ansudu",
    "value": "Ansudu"
  },
  {
    "id": "9110042002",
    "district_id": "911004",
    "label": "Betaf",
    "value": "Betaf"
  },
  {
    "id": "9110042003",
    "district_id": "911004",
    "label": "Yamben",
    "value": "Yamben"
  },
  {
    "id": "9110042010",
    "district_id": "911004",
    "label": "Sunum",
    "value": "Sunum"
  },
  {
    "id": "9110042011",
    "district_id": "911004",
    "label": "Komra",
    "value": "Komra"
  },
  {
    "id": "9110042013",
    "district_id": "911004",
    "label": "Betaf Dua",
    "value": "Betaf Dua"
  },
  {
    "id": "9110042014",
    "district_id": "911004",
    "label": "Ansudu Dua",
    "value": "Ansudu Dua"
  },
  {
    "id": "9110052004",
    "district_id": "911005",
    "label": "Armopa",
    "value": "Armopa"
  },
  {
    "id": "9110052005",
    "district_id": "911005",
    "label": "Tarontha Srum",
    "value": "Tarontha Srum"
  },
  {
    "id": "9110052006",
    "district_id": "911005",
    "label": "Anus",
    "value": "Anus"
  },
  {
    "id": "9110052007",
    "district_id": "911005",
    "label": "Kiren",
    "value": "Kiren"
  },
  {
    "id": "9110052008",
    "district_id": "911005",
    "label": "Maweswares",
    "value": "Maweswares"
  },
  {
    "id": "9110052010",
    "district_id": "911005",
    "label": "Bebon Jaya",
    "value": "Bebon Jaya"
  },
  {
    "id": "9110052011",
    "district_id": "911005",
    "label": "Tetom",
    "value": "Tetom"
  },
  {
    "id": "9110052015",
    "district_id": "911005",
    "label": "Rimsersari",
    "value": "Rimsersari"
  },
  {
    "id": "9110052017",
    "district_id": "911005",
    "label": "Krim Podena",
    "value": "Krim Podena"
  },
  {
    "id": "9110052018",
    "district_id": "911005",
    "label": "Rotea",
    "value": "Rotea"
  },
  {
    "id": "9110092001",
    "district_id": "911009",
    "label": "Wamariri",
    "value": "Wamariri"
  },
  {
    "id": "9110092002",
    "district_id": "911009",
    "label": "Aurimi",
    "value": "Aurimi"
  },
  {
    "id": "9110092003",
    "district_id": "911009",
    "label": "Tamaja",
    "value": "Tamaja"
  },
  {
    "id": "9110092004",
    "district_id": "911009",
    "label": "Airoran",
    "value": "Airoran"
  },
  {
    "id": "9110092005",
    "district_id": "911009",
    "label": "Murara",
    "value": "Murara"
  },
  {
    "id": "9110092006",
    "district_id": "911009",
    "label": "Sasawapece",
    "value": "Sasawapece"
  },
  {
    "id": "9110092011",
    "district_id": "911009",
    "label": "Maniwa",
    "value": "Maniwa"
  },
  {
    "id": "9110092012",
    "district_id": "911009",
    "label": "Surimania",
    "value": "Surimania"
  },
  {
    "id": "9110092013",
    "district_id": "911009",
    "label": "Kwawitania",
    "value": "Kwawitania"
  },
  {
    "id": "9110092014",
    "district_id": "911009",
    "label": "Bina",
    "value": "Bina"
  },
  {
    "id": "9110122001",
    "district_id": "911012",
    "label": "Amsira",
    "value": "Amsira"
  },
  {
    "id": "9110122002",
    "district_id": "911012",
    "label": "Siaratesa",
    "value": "Siaratesa"
  },
  {
    "id": "9110122003",
    "district_id": "911012",
    "label": "Munukania",
    "value": "Munukania"
  },
  {
    "id": "9110122004",
    "district_id": "911012",
    "label": "Kasukwe",
    "value": "Kasukwe"
  },
  {
    "id": "9110122005",
    "district_id": "911012",
    "label": "Wapo",
    "value": "Wapo"
  },
  {
    "id": "9110122008",
    "district_id": "911012",
    "label": "Angkasa Dua",
    "value": "Angkasa Dua"
  },
  {
    "id": "9110132001",
    "district_id": "911013",
    "label": "Holmafen",
    "value": "Holmafen"
  },
  {
    "id": "9110132002",
    "district_id": "911013",
    "label": "Sewan",
    "value": "Sewan"
  },
  {
    "id": "9110132003",
    "district_id": "911013",
    "label": "Waskey/ Tanjung Batu",
    "value": "Waskey/ Tanjung Batu"
  },
  {
    "id": "9110132004",
    "district_id": "911013",
    "label": "Ebram",
    "value": "Ebram"
  },
  {
    "id": "9110132005",
    "district_id": "911013",
    "label": "Binyer",
    "value": "Binyer"
  },
  {
    "id": "9110132006",
    "district_id": "911013",
    "label": "Bagaiserwar Dua",
    "value": "Bagaiserwar Dua"
  },
  {
    "id": "9110132007",
    "district_id": "911013",
    "label": "Waskey",
    "value": "Waskey"
  },
  {
    "id": "9110132008",
    "district_id": "911013",
    "label": "Tanjung Batu",
    "value": "Tanjung Batu"
  },
  {
    "id": "9110142001",
    "district_id": "911014",
    "label": "Arare",
    "value": "Arare"
  },
  {
    "id": "9110142002",
    "district_id": "911014",
    "label": "Wakde",
    "value": "Wakde"
  },
  {
    "id": "9110142003",
    "district_id": "911014",
    "label": "Timron/Keder",
    "value": "Timron/Keder"
  },
  {
    "id": "9110142004",
    "district_id": "911014",
    "label": "Dabe",
    "value": "Dabe"
  },
  {
    "id": "9110142005",
    "district_id": "911014",
    "label": "Nengke",
    "value": "Nengke"
  },
  {
    "id": "9110142006",
    "district_id": "911014",
    "label": "Finyabor",
    "value": "Finyabor"
  },
  {
    "id": "9110142012",
    "district_id": "911014",
    "label": "Tambrawar",
    "value": "Tambrawar"
  },
  {
    "id": "9110142013",
    "district_id": "911014",
    "label": "Keder Lama",
    "value": "Keder Lama"
  },
  {
    "id": "9110142014",
    "district_id": "911014",
    "label": "Finyabor Dua",
    "value": "Finyabor Dua"
  },
  {
    "id": "9110142015",
    "district_id": "911014",
    "label": "Dabe Dua",
    "value": "Dabe Dua"
  },
  {
    "id": "9110142016",
    "district_id": "911014",
    "label": "Nengke Dua",
    "value": "Nengke Dua"
  },
  {
    "id": "9110152001",
    "district_id": "911015",
    "label": "Gwinjaya",
    "value": "Gwinjaya"
  },
  {
    "id": "9110152002",
    "district_id": "911015",
    "label": "Tamar Sari",
    "value": "Tamar Sari"
  },
  {
    "id": "9110152003",
    "district_id": "911015",
    "label": "Mawes Mukti",
    "value": "Mawes Mukti"
  },
  {
    "id": "9110152004",
    "district_id": "911015",
    "label": "Mawesday",
    "value": "Mawesday"
  },
  {
    "id": "9110152005",
    "district_id": "911015",
    "label": "Kapitiau",
    "value": "Kapitiau"
  },
  {
    "id": "9111012001",
    "district_id": "911101",
    "label": "Banda",
    "value": "Banda"
  },
  {
    "id": "9111012002",
    "district_id": "911101",
    "label": "Pund",
    "value": "Pund"
  },
  {
    "id": "9111012003",
    "district_id": "911101",
    "label": "Kalifam",
    "value": "Kalifam"
  },
  {
    "id": "9111012004",
    "district_id": "911101",
    "label": "Yuwainda",
    "value": "Yuwainda"
  },
  {
    "id": "9111012005",
    "district_id": "911101",
    "label": "Kalimala",
    "value": "Kalimala"
  },
  {
    "id": "9111012006",
    "district_id": "911101",
    "label": "Ampas",
    "value": "Ampas"
  },
  {
    "id": "9111012007",
    "district_id": "911101",
    "label": "Bompai",
    "value": "Bompai"
  },
  {
    "id": "9111012008",
    "district_id": "911101",
    "label": "Sack",
    "value": "Sack"
  },
  {
    "id": "9111022001",
    "district_id": "911102",
    "label": "Arso Kota",
    "value": "Arso Kota"
  },
  {
    "id": "9111022002",
    "district_id": "911102",
    "label": "Workwana",
    "value": "Workwana"
  },
  {
    "id": "9111022003",
    "district_id": "911102",
    "label": "Kwimi",
    "value": "Kwimi"
  },
  {
    "id": "9111022004",
    "district_id": "911102",
    "label": "Ubiyau",
    "value": "Ubiyau"
  },
  {
    "id": "9111022006",
    "district_id": "911102",
    "label": "Sawanawa",
    "value": "Sawanawa"
  },
  {
    "id": "9111022009",
    "district_id": "911102",
    "label": "Yuwanain",
    "value": "Yuwanain"
  },
  {
    "id": "9111022010",
    "district_id": "911102",
    "label": "Yanamaa",
    "value": "Yanamaa"
  },
  {
    "id": "9111022011",
    "district_id": "911102",
    "label": "Asyaman",
    "value": "Asyaman"
  },
  {
    "id": "9111022012",
    "district_id": "911102",
    "label": "Yamta",
    "value": "Yamta"
  },
  {
    "id": "9111022016",
    "district_id": "911102",
    "label": "Bagia",
    "value": "Bagia"
  },
  {
    "id": "9111022030",
    "district_id": "911102",
    "label": "Sawabuun",
    "value": "Sawabuun"
  },
  {
    "id": "9111022031",
    "district_id": "911102",
    "label": "Bibiosi",
    "value": "Bibiosi"
  },
  {
    "id": "9111032001",
    "district_id": "911103",
    "label": "Molof",
    "value": "Molof"
  },
  {
    "id": "9111032002",
    "district_id": "911103",
    "label": "Senggi",
    "value": "Senggi"
  },
  {
    "id": "9111032003",
    "district_id": "911103",
    "label": "Warlef",
    "value": "Warlef"
  },
  {
    "id": "9111032005",
    "district_id": "911103",
    "label": "Usku",
    "value": "Usku"
  },
  {
    "id": "9111032006",
    "district_id": "911103",
    "label": "Woslay",
    "value": "Woslay"
  },
  {
    "id": "9111032010",
    "district_id": "911103",
    "label": "Namla",
    "value": "Namla"
  },
  {
    "id": "9111032011",
    "district_id": "911103",
    "label": "Waley",
    "value": "Waley"
  },
  {
    "id": "9111042001",
    "district_id": "911104",
    "label": "Dubu",
    "value": "Dubu"
  },
  {
    "id": "9111042002",
    "district_id": "911104",
    "label": "Umuraf",
    "value": "Umuraf"
  },
  {
    "id": "9111042003",
    "district_id": "911104",
    "label": "Semografi",
    "value": "Semografi"
  },
  {
    "id": "9111042005",
    "district_id": "911104",
    "label": "Embi",
    "value": "Embi"
  },
  {
    "id": "9111042015",
    "district_id": "911104",
    "label": "Yamraf Dua",
    "value": "Yamraf Dua"
  },
  {
    "id": "9111042017",
    "district_id": "911104",
    "label": "Tatakra",
    "value": "Tatakra"
  },
  {
    "id": "9111052001",
    "district_id": "911105",
    "label": "Skanto",
    "value": "Skanto"
  },
  {
    "id": "9111052002",
    "district_id": "911105",
    "label": "Jaifuri",
    "value": "Jaifuri"
  },
  {
    "id": "9111052003",
    "district_id": "911105",
    "label": "Arsopura",
    "value": "Arsopura"
  },
  {
    "id": "9111052004",
    "district_id": "911105",
    "label": "Wiantre",
    "value": "Wiantre"
  },
  {
    "id": "9111052005",
    "district_id": "911105",
    "label": "Naramben",
    "value": "Naramben"
  },
  {
    "id": "9111052006",
    "district_id": "911105",
    "label": "Intaimelyan",
    "value": "Intaimelyan"
  },
  {
    "id": "9111052007",
    "district_id": "911105",
    "label": "Traimelyan",
    "value": "Traimelyan"
  },
  {
    "id": "9111052008",
    "district_id": "911105",
    "label": "Wulukubun",
    "value": "Wulukubun"
  },
  {
    "id": "9111052009",
    "district_id": "911105",
    "label": "Gudang Garam",
    "value": "Gudang Garam"
  },
  {
    "id": "9111052010",
    "district_id": "911105",
    "label": "Saefen Empat Dua",
    "value": "Saefen Empat Dua"
  },
  {
    "id": "9111052011",
    "district_id": "911105",
    "label": "Walma",
    "value": "Walma"
  },
  {
    "id": "9111052012",
    "district_id": "911105",
    "label": "Alang-alang Raya",
    "value": "Alang-alang Raya"
  },
  {
    "id": "9111062005",
    "district_id": "911106",
    "label": "Yetti",
    "value": "Yetti"
  },
  {
    "id": "9111062006",
    "district_id": "911106",
    "label": "Kriku",
    "value": "Kriku"
  },
  {
    "id": "9111062007",
    "district_id": "911106",
    "label": "Skofro",
    "value": "Skofro"
  },
  {
    "id": "9111062008",
    "district_id": "911106",
    "label": "Kibay",
    "value": "Kibay"
  },
  {
    "id": "9111062009",
    "district_id": "911106",
    "label": "Sangke",
    "value": "Sangke"
  },
  {
    "id": "9111062011",
    "district_id": "911106",
    "label": "Suskun",
    "value": "Suskun"
  },
  {
    "id": "9111062013",
    "district_id": "911106",
    "label": "Amyu",
    "value": "Amyu"
  },
  {
    "id": "9111062014",
    "district_id": "911106",
    "label": "Kikere",
    "value": "Kikere"
  },
  {
    "id": "9111062015",
    "district_id": "911106",
    "label": "Petewi",
    "value": "Petewi"
  },
  {
    "id": "9111072001",
    "district_id": "911107",
    "label": "Towe Hitam",
    "value": "Towe Hitam"
  },
  {
    "id": "9111072002",
    "district_id": "911107",
    "label": "Towe Atas",
    "value": "Towe Atas"
  },
  {
    "id": "9111072003",
    "district_id": "911107",
    "label": "Terfones",
    "value": "Terfones"
  },
  {
    "id": "9111072004",
    "district_id": "911107",
    "label": "Tefalma",
    "value": "Tefalma"
  },
  {
    "id": "9111072005",
    "district_id": "911107",
    "label": "Bias",
    "value": "Bias"
  },
  {
    "id": "9111072006",
    "district_id": "911107",
    "label": "Milki",
    "value": "Milki"
  },
  {
    "id": "9111072007",
    "district_id": "911107",
    "label": "Lules",
    "value": "Lules"
  },
  {
    "id": "9111072009",
    "district_id": "911107",
    "label": "Jember",
    "value": "Jember"
  },
  {
    "id": "9111072010",
    "district_id": "911107",
    "label": "Niliti",
    "value": "Niliti"
  },
  {
    "id": "9111072011",
    "district_id": "911107",
    "label": "Pris",
    "value": "Pris"
  },
  {
    "id": "9111082001",
    "district_id": "911108",
    "label": "Dukwia",
    "value": "Dukwia"
  },
  {
    "id": "9111082002",
    "district_id": "911108",
    "label": "Sanggaria",
    "value": "Sanggaria"
  },
  {
    "id": "9111082003",
    "district_id": "911108",
    "label": "Yatu Raharja",
    "value": "Yatu Raharja"
  },
  {
    "id": "9111082004",
    "district_id": "911108",
    "label": "Warbo",
    "value": "Warbo"
  },
  {
    "id": "9111082005",
    "district_id": "911108",
    "label": "Yammua",
    "value": "Yammua"
  },
  {
    "id": "9111082006",
    "district_id": "911108",
    "label": "Ifia-fia",
    "value": "Ifia-fia"
  },
  {
    "id": "9111082007",
    "district_id": "911108",
    "label": "Baburia",
    "value": "Baburia"
  },
  {
    "id": "9111082008",
    "district_id": "911108",
    "label": "Yowong",
    "value": "Yowong"
  },
  {
    "id": "9111092001",
    "district_id": "911109",
    "label": "Yamara",
    "value": "Yamara"
  },
  {
    "id": "9111092002",
    "district_id": "911109",
    "label": "Wembi",
    "value": "Wembi"
  },
  {
    "id": "9111092003",
    "district_id": "911109",
    "label": "Wonorejo",
    "value": "Wonorejo"
  },
  {
    "id": "9111092004",
    "district_id": "911109",
    "label": "Pyawi",
    "value": "Pyawi"
  },
  {
    "id": "9111092005",
    "district_id": "911109",
    "label": "Sawyatami",
    "value": "Sawyatami"
  },
  {
    "id": "9111092006",
    "district_id": "911109",
    "label": "Wambes",
    "value": "Wambes"
  },
  {
    "id": "9111092007",
    "district_id": "911109",
    "label": "Uskwar",
    "value": "Uskwar"
  },
  {
    "id": "9111102001",
    "district_id": "911110",
    "label": "Yabanda",
    "value": "Yabanda"
  },
  {
    "id": "9111102002",
    "district_id": "911110",
    "label": "Yuruf",
    "value": "Yuruf"
  },
  {
    "id": "9111102003",
    "district_id": "911110",
    "label": "Amgotro",
    "value": "Amgotro"
  },
  {
    "id": "9111102004",
    "district_id": "911110",
    "label": "Jifanggry",
    "value": "Jifanggry"
  },
  {
    "id": "9111102005",
    "district_id": "911110",
    "label": "Monggoafi",
    "value": "Monggoafi"
  },
  {
    "id": "9111102006",
    "district_id": "911110",
    "label": "Fafenumbu",
    "value": "Fafenumbu"
  },
  {
    "id": "9111102007",
    "district_id": "911110",
    "label": "Akarinda",
    "value": "Akarinda"
  },
  {
    "id": "9111112001",
    "district_id": "911111",
    "label": "Kaisenar",
    "value": "Kaisenar"
  },
  {
    "id": "9111112002",
    "district_id": "911111",
    "label": "Kiambra",
    "value": "Kiambra"
  },
  {
    "id": "9111112003",
    "district_id": "911111",
    "label": "Liket",
    "value": "Liket"
  },
  {
    "id": "9111112004",
    "district_id": "911111",
    "label": "Onam",
    "value": "Onam"
  },
  {
    "id": "9111112005",
    "district_id": "911111",
    "label": "Tefanma Satu",
    "value": "Tefanma Satu"
  },
  {
    "id": "9112012001",
    "district_id": "911201",
    "label": "Kabiding",
    "value": "Kabiding"
  },
  {
    "id": "9112012017",
    "district_id": "911201",
    "label": "Aldom",
    "value": "Aldom"
  },
  {
    "id": "9112012018",
    "district_id": "911201",
    "label": "Kutdol",
    "value": "Kutdol"
  },
  {
    "id": "9112012019",
    "district_id": "911201",
    "label": "Polsam",
    "value": "Polsam"
  },
  {
    "id": "9112012020",
    "district_id": "911201",
    "label": "Akmakot",
    "value": "Akmakot"
  },
  {
    "id": "9112012021",
    "district_id": "911201",
    "label": "Mabilabol",
    "value": "Mabilabol"
  },
  {
    "id": "9112012022",
    "district_id": "911201",
    "label": "Bunamdol",
    "value": "Bunamdol"
  },
  {
    "id": "9112012023",
    "district_id": "911201",
    "label": "Molbib Silibib",
    "value": "Molbib Silibib"
  },
  {
    "id": "9112022001",
    "district_id": "911202",
    "label": "Oknanggul",
    "value": "Oknanggul"
  },
  {
    "id": "9112022004",
    "district_id": "911202",
    "label": "Kukihil",
    "value": "Kukihil"
  },
  {
    "id": "9112022007",
    "district_id": "911202",
    "label": "Kiwi",
    "value": "Kiwi"
  },
  {
    "id": "9112022010",
    "district_id": "911202",
    "label": "Berusaha",
    "value": "Berusaha"
  },
  {
    "id": "9112022015",
    "district_id": "911202",
    "label": "Pelebib",
    "value": "Pelebib"
  },
  {
    "id": "9112022018",
    "district_id": "911202",
    "label": "Sopamikma",
    "value": "Sopamikma"
  },
  {
    "id": "9112022020",
    "district_id": "911202",
    "label": "Asua",
    "value": "Asua"
  },
  {
    "id": "9112022022",
    "district_id": "911202",
    "label": "Lolim",
    "value": "Lolim"
  },
  {
    "id": "9112022023",
    "district_id": "911202",
    "label": "Pomding",
    "value": "Pomding"
  },
  {
    "id": "9112022024",
    "district_id": "911202",
    "label": "Diip",
    "value": "Diip"
  },
  {
    "id": "9112022025",
    "district_id": "911202",
    "label": "Delpem",
    "value": "Delpem"
  },
  {
    "id": "9112022026",
    "district_id": "911202",
    "label": "Mangoldoki",
    "value": "Mangoldoki"
  },
  {
    "id": "9112032001",
    "district_id": "911203",
    "label": "Okbifisil",
    "value": "Okbifisil"
  },
  {
    "id": "9112032004",
    "district_id": "911203",
    "label": "Abmisibil",
    "value": "Abmisibil"
  },
  {
    "id": "9112032008",
    "district_id": "911203",
    "label": "Manunggal",
    "value": "Manunggal"
  },
  {
    "id": "9112032011",
    "district_id": "911203",
    "label": "Iriding",
    "value": "Iriding"
  },
  {
    "id": "9112032016",
    "district_id": "911203",
    "label": "Atolbol",
    "value": "Atolbol"
  },
  {
    "id": "9112032017",
    "district_id": "911203",
    "label": "Oktanglap",
    "value": "Oktanglap"
  },
  {
    "id": "9112032018",
    "district_id": "911203",
    "label": "Oksemar",
    "value": "Oksemar"
  },
  {
    "id": "9112032019",
    "district_id": "911203",
    "label": "Okaplo",
    "value": "Okaplo"
  },
  {
    "id": "9112042001",
    "district_id": "911204",
    "label": "Iwur",
    "value": "Iwur"
  },
  {
    "id": "9112042002",
    "district_id": "911204",
    "label": "Kurumklin",
    "value": "Kurumklin"
  },
  {
    "id": "9112042003",
    "district_id": "911204",
    "label": "Walapkubun",
    "value": "Walapkubun"
  },
  {
    "id": "9112042008",
    "district_id": "911204",
    "label": "Dinmot Arim",
    "value": "Dinmot Arim"
  },
  {
    "id": "9112042013",
    "district_id": "911204",
    "label": "Ewenkatop",
    "value": "Ewenkatop"
  },
  {
    "id": "9112042015",
    "district_id": "911204",
    "label": "Ulkubi",
    "value": "Ulkubi"
  },
  {
    "id": "9112042016",
    "district_id": "911204",
    "label": "Dipol",
    "value": "Dipol"
  },
  {
    "id": "9112042017",
    "district_id": "911204",
    "label": "Nenginum",
    "value": "Nenginum"
  },
  {
    "id": "9112042018",
    "district_id": "911204",
    "label": "Narnger",
    "value": "Narnger"
  },
  {
    "id": "9112042019",
    "district_id": "911204",
    "label": "Kamyoim",
    "value": "Kamyoim"
  },
  {
    "id": "9112052001",
    "district_id": "911205",
    "label": "Batom",
    "value": "Batom"
  },
  {
    "id": "9112052006",
    "district_id": "911205",
    "label": "Oksip",
    "value": "Oksip"
  },
  {
    "id": "9112052009",
    "district_id": "911205",
    "label": "Mongham",
    "value": "Mongham"
  },
  {
    "id": "9112052011",
    "district_id": "911205",
    "label": "Sabi",
    "value": "Sabi"
  },
  {
    "id": "9112052012",
    "district_id": "911205",
    "label": "Neep",
    "value": "Neep"
  },
  {
    "id": "9112052013",
    "district_id": "911205",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "9112052014",
    "district_id": "911205",
    "label": "Batom Dua",
    "value": "Batom Dua"
  },
  {
    "id": "9112052015",
    "district_id": "911205",
    "label": "Akyako",
    "value": "Akyako"
  },
  {
    "id": "9112052016",
    "district_id": "911205",
    "label": "Peteng",
    "value": "Peteng"
  },
  {
    "id": "9112052017",
    "district_id": "911205",
    "label": "Belomo",
    "value": "Belomo"
  },
  {
    "id": "9112052018",
    "district_id": "911205",
    "label": "Abukerom",
    "value": "Abukerom"
  },
  {
    "id": "9112062001",
    "district_id": "911206",
    "label": "Borme",
    "value": "Borme"
  },
  {
    "id": "9112062002",
    "district_id": "911206",
    "label": "Omban",
    "value": "Omban"
  },
  {
    "id": "9112062005",
    "district_id": "911206",
    "label": "Sikibur",
    "value": "Sikibur"
  },
  {
    "id": "9112062006",
    "district_id": "911206",
    "label": "Onya",
    "value": "Onya"
  },
  {
    "id": "9112062007",
    "district_id": "911206",
    "label": "Arina",
    "value": "Arina"
  },
  {
    "id": "9112062015",
    "district_id": "911206",
    "label": "Bordamban",
    "value": "Bordamban"
  },
  {
    "id": "9112062016",
    "district_id": "911206",
    "label": "Aringgon",
    "value": "Aringgon"
  },
  {
    "id": "9112062017",
    "district_id": "911206",
    "label": "Bukam",
    "value": "Bukam"
  },
  {
    "id": "9112062018",
    "district_id": "911206",
    "label": "Kwime",
    "value": "Kwime"
  },
  {
    "id": "9112062019",
    "district_id": "911206",
    "label": "Kolgir",
    "value": "Kolgir"
  },
  {
    "id": "9112062020",
    "district_id": "911206",
    "label": "Cangdamban",
    "value": "Cangdamban"
  },
  {
    "id": "9112062021",
    "district_id": "911206",
    "label": "Laydamban",
    "value": "Laydamban"
  },
  {
    "id": "9112062022",
    "district_id": "911206",
    "label": "Seban",
    "value": "Seban"
  },
  {
    "id": "9112072002",
    "district_id": "911207",
    "label": "Okyop",
    "value": "Okyop"
  },
  {
    "id": "9112072003",
    "district_id": "911207",
    "label": "Oketur",
    "value": "Oketur"
  },
  {
    "id": "9112072004",
    "district_id": "911207",
    "label": "Ehipten",
    "value": "Ehipten"
  },
  {
    "id": "9112072005",
    "district_id": "911207",
    "label": "Wantem",
    "value": "Wantem"
  },
  {
    "id": "9112072006",
    "district_id": "911207",
    "label": "Dikdon",
    "value": "Dikdon"
  },
  {
    "id": "9112072008",
    "district_id": "911207",
    "label": "Okyako",
    "value": "Okyako"
  },
  {
    "id": "9112072011",
    "district_id": "911207",
    "label": "Okyaop",
    "value": "Okyaop"
  },
  {
    "id": "9112072012",
    "district_id": "911207",
    "label": "Tatam",
    "value": "Tatam"
  },
  {
    "id": "9112072013",
    "district_id": "911207",
    "label": "Okhik",
    "value": "Okhik"
  },
  {
    "id": "9112082001",
    "district_id": "911208",
    "label": "Aboy",
    "value": "Aboy"
  },
  {
    "id": "9112082002",
    "district_id": "911208",
    "label": "Luban",
    "value": "Luban"
  },
  {
    "id": "9112082004",
    "district_id": "911208",
    "label": "Jubly",
    "value": "Jubly"
  },
  {
    "id": "9112082005",
    "district_id": "911208",
    "label": "Armise",
    "value": "Armise"
  },
  {
    "id": "9112082006",
    "district_id": "911208",
    "label": "Woro",
    "value": "Woro"
  },
  {
    "id": "9112082007",
    "district_id": "911208",
    "label": "Pipal",
    "value": "Pipal"
  },
  {
    "id": "9112082008",
    "district_id": "911208",
    "label": "Lulis Wii",
    "value": "Lulis Wii"
  },
  {
    "id": "9112092002",
    "district_id": "911209",
    "label": "Pepera",
    "value": "Pepera"
  },
  {
    "id": "9112092006",
    "district_id": "911209",
    "label": "Okbon",
    "value": "Okbon"
  },
  {
    "id": "9112092007",
    "district_id": "911209",
    "label": "Yun Muku",
    "value": "Yun Muku"
  },
  {
    "id": "9112092008",
    "district_id": "911209",
    "label": "Denom",
    "value": "Denom"
  },
  {
    "id": "9112092009",
    "district_id": "911209",
    "label": "Bon Yakwol",
    "value": "Bon Yakwol"
  },
  {
    "id": "9112092010",
    "district_id": "911209",
    "label": "Ok Telabe",
    "value": "Ok Telabe"
  },
  {
    "id": "9112092011",
    "district_id": "911209",
    "label": "Wok Bakon",
    "value": "Wok Bakon"
  },
  {
    "id": "9112102001",
    "district_id": "911210",
    "label": "Bime",
    "value": "Bime"
  },
  {
    "id": "9112102004",
    "district_id": "911210",
    "label": "Bob",
    "value": "Bob"
  },
  {
    "id": "9112102005",
    "district_id": "911210",
    "label": "Kameme",
    "value": "Kameme"
  },
  {
    "id": "9112102006",
    "district_id": "911210",
    "label": "Calab",
    "value": "Calab"
  },
  {
    "id": "9112102007",
    "district_id": "911210",
    "label": "Limiri",
    "value": "Limiri"
  },
  {
    "id": "9112102008",
    "district_id": "911210",
    "label": "Teli",
    "value": "Teli"
  },
  {
    "id": "9112102009",
    "district_id": "911210",
    "label": "Lim Lim",
    "value": "Lim Lim"
  },
  {
    "id": "9112102010",
    "district_id": "911210",
    "label": "Bunggon",
    "value": "Bunggon"
  },
  {
    "id": "9112102011",
    "district_id": "911210",
    "label": "Perem",
    "value": "Perem"
  },
  {
    "id": "9112102012",
    "district_id": "911210",
    "label": "Turwe",
    "value": "Turwe"
  },
  {
    "id": "9112112001",
    "district_id": "911211",
    "label": "Eraduman",
    "value": "Eraduman"
  },
  {
    "id": "9112112002",
    "district_id": "911211",
    "label": "Alemsom",
    "value": "Alemsom"
  },
  {
    "id": "9112112003",
    "district_id": "911211",
    "label": "Tapasik",
    "value": "Tapasik"
  },
  {
    "id": "9112112004",
    "district_id": "911211",
    "label": "Payol Masumkon",
    "value": "Payol Masumkon"
  },
  {
    "id": "9112112005",
    "district_id": "911211",
    "label": "Tapob",
    "value": "Tapob"
  },
  {
    "id": "9112112006",
    "district_id": "911211",
    "label": "Bakwalin Yub",
    "value": "Bakwalin Yub"
  },
  {
    "id": "9112112007",
    "district_id": "911211",
    "label": "Imiryi",
    "value": "Imiryi"
  },
  {
    "id": "9112112008",
    "district_id": "911211",
    "label": "Sumtamon",
    "value": "Sumtamon"
  },
  {
    "id": "9112112009",
    "district_id": "911211",
    "label": "Bondik",
    "value": "Bondik"
  },
  {
    "id": "9112112010",
    "district_id": "911211",
    "label": "Muruman",
    "value": "Muruman"
  },
  {
    "id": "9112112011",
    "district_id": "911211",
    "label": "Binalkom",
    "value": "Binalkom"
  },
  {
    "id": "9112112012",
    "district_id": "911211",
    "label": "Tibal",
    "value": "Tibal"
  },
  {
    "id": "9112122001",
    "district_id": "911212",
    "label": "Bape",
    "value": "Bape"
  },
  {
    "id": "9112122002",
    "district_id": "911212",
    "label": "Masim",
    "value": "Masim"
  },
  {
    "id": "9112122003",
    "district_id": "911212",
    "label": "Akmer",
    "value": "Akmer"
  },
  {
    "id": "9112122004",
    "district_id": "911212",
    "label": "Kasawi",
    "value": "Kasawi"
  },
  {
    "id": "9112122005",
    "district_id": "911212",
    "label": "Tapasik Dua Bapenka",
    "value": "Tapasik Dua Bapenka"
  },
  {
    "id": "9112122006",
    "district_id": "911212",
    "label": "Ibot",
    "value": "Ibot"
  },
  {
    "id": "9112132001",
    "district_id": "911213",
    "label": "Dabolding",
    "value": "Dabolding"
  },
  {
    "id": "9112132002",
    "district_id": "911213",
    "label": "Kalomdol",
    "value": "Kalomdol"
  },
  {
    "id": "9112132003",
    "district_id": "911213",
    "label": "Tulo",
    "value": "Tulo"
  },
  {
    "id": "9112132004",
    "district_id": "911213",
    "label": "Arinkop",
    "value": "Arinkop"
  },
  {
    "id": "9112132005",
    "district_id": "911213",
    "label": "Imik",
    "value": "Imik"
  },
  {
    "id": "9112142001",
    "district_id": "911214",
    "label": "Oksop",
    "value": "Oksop"
  },
  {
    "id": "9112142002",
    "district_id": "911214",
    "label": "Mimin",
    "value": "Mimin"
  },
  {
    "id": "9112142003",
    "district_id": "911214",
    "label": "Atenor",
    "value": "Atenor"
  },
  {
    "id": "9112142004",
    "district_id": "911214",
    "label": "Oktumi",
    "value": "Oktumi"
  },
  {
    "id": "9112142005",
    "district_id": "911214",
    "label": "Alutbakon",
    "value": "Alutbakon"
  },
  {
    "id": "9112152001",
    "district_id": "911215",
    "label": "Yapimakot",
    "value": "Yapimakot"
  },
  {
    "id": "9112152002",
    "district_id": "911215",
    "label": "Seramkatop",
    "value": "Seramkatop"
  },
  {
    "id": "9112152003",
    "district_id": "911215",
    "label": "Parim",
    "value": "Parim"
  },
  {
    "id": "9112152004",
    "district_id": "911215",
    "label": "Siminbuk",
    "value": "Siminbuk"
  },
  {
    "id": "9112152005",
    "district_id": "911215",
    "label": "Modusit",
    "value": "Modusit"
  },
  {
    "id": "9112152006",
    "district_id": "911215",
    "label": "Wanbakon",
    "value": "Wanbakon"
  },
  {
    "id": "9112152007",
    "district_id": "911215",
    "label": "Yakmor",
    "value": "Yakmor"
  },
  {
    "id": "9112152008",
    "district_id": "911215",
    "label": "Okatem",
    "value": "Okatem"
  },
  {
    "id": "9112162001",
    "district_id": "911216",
    "label": "Kungulding",
    "value": "Kungulding"
  },
  {
    "id": "9112162002",
    "district_id": "911216",
    "label": "Bulangkop",
    "value": "Bulangkop"
  },
  {
    "id": "9112162003",
    "district_id": "911216",
    "label": "Limarum",
    "value": "Limarum"
  },
  {
    "id": "9112162004",
    "district_id": "911216",
    "label": "Yumakot",
    "value": "Yumakot"
  },
  {
    "id": "9112162005",
    "district_id": "911216",
    "label": "Aplim",
    "value": "Aplim"
  },
  {
    "id": "9112162006",
    "district_id": "911216",
    "label": "Okdo",
    "value": "Okdo"
  },
  {
    "id": "9112172001",
    "district_id": "911217",
    "label": "Arintap",
    "value": "Arintap"
  },
  {
    "id": "9112172002",
    "district_id": "911217",
    "label": "Tarngop",
    "value": "Tarngop"
  },
  {
    "id": "9112172003",
    "district_id": "911217",
    "label": "Kawor",
    "value": "Kawor"
  },
  {
    "id": "9112172004",
    "district_id": "911217",
    "label": "Sakup",
    "value": "Sakup"
  },
  {
    "id": "9112172005",
    "district_id": "911217",
    "label": "Umding",
    "value": "Umding"
  },
  {
    "id": "9112172006",
    "district_id": "911217",
    "label": "Nanum",
    "value": "Nanum"
  },
  {
    "id": "9112172007",
    "district_id": "911217",
    "label": "Ater",
    "value": "Ater"
  },
  {
    "id": "9112182001",
    "district_id": "911218",
    "label": "Awinbon",
    "value": "Awinbon"
  },
  {
    "id": "9112182002",
    "district_id": "911218",
    "label": "Nanum Anaip",
    "value": "Nanum Anaip"
  },
  {
    "id": "9112182003",
    "district_id": "911218",
    "label": "Mikir",
    "value": "Mikir"
  },
  {
    "id": "9112182004",
    "district_id": "911218",
    "label": "Kawe",
    "value": "Kawe"
  },
  {
    "id": "9112182005",
    "district_id": "911218",
    "label": "Yelobib",
    "value": "Yelobib"
  },
  {
    "id": "9112192001",
    "district_id": "911219",
    "label": "Tarup",
    "value": "Tarup"
  },
  {
    "id": "9112192002",
    "district_id": "911219",
    "label": "Marang Tiking",
    "value": "Marang Tiking"
  },
  {
    "id": "9112192003",
    "district_id": "911219",
    "label": "Imsin",
    "value": "Imsin"
  },
  {
    "id": "9112192004",
    "district_id": "911219",
    "label": "Bitipding",
    "value": "Bitipding"
  },
  {
    "id": "9112192005",
    "district_id": "911219",
    "label": "Onkor",
    "value": "Onkor"
  },
  {
    "id": "9112192006",
    "district_id": "911219",
    "label": "Beten Dua",
    "value": "Beten Dua"
  },
  {
    "id": "9112192007",
    "district_id": "911219",
    "label": "Omor",
    "value": "Omor"
  },
  {
    "id": "9112202001",
    "district_id": "911220",
    "label": "Okelwel",
    "value": "Okelwel"
  },
  {
    "id": "9112202002",
    "district_id": "911220",
    "label": "Kotyobakon",
    "value": "Kotyobakon"
  },
  {
    "id": "9112202003",
    "district_id": "911220",
    "label": "Okteneng",
    "value": "Okteneng"
  },
  {
    "id": "9112202004",
    "district_id": "911220",
    "label": "Tengnong",
    "value": "Tengnong"
  },
  {
    "id": "9112212001",
    "district_id": "911221",
    "label": "Okma",
    "value": "Okma"
  },
  {
    "id": "9112212002",
    "district_id": "911221",
    "label": "Oktae",
    "value": "Oktae"
  },
  {
    "id": "9112212003",
    "district_id": "911221",
    "label": "Tinibil",
    "value": "Tinibil"
  },
  {
    "id": "9112212004",
    "district_id": "911221",
    "label": "Okdilam",
    "value": "Okdilam"
  },
  {
    "id": "9112212005",
    "district_id": "911221",
    "label": "Autpahik",
    "value": "Autpahik"
  },
  {
    "id": "9112212006",
    "district_id": "911221",
    "label": "Tomka",
    "value": "Tomka"
  },
  {
    "id": "9112212007",
    "district_id": "911221",
    "label": "Okpa",
    "value": "Okpa"
  },
  {
    "id": "9112212008",
    "district_id": "911221",
    "label": "Okhaka",
    "value": "Okhaka"
  },
  {
    "id": "9112212009",
    "district_id": "911221",
    "label": "Honkuding",
    "value": "Honkuding"
  },
  {
    "id": "9112212010",
    "district_id": "911221",
    "label": "Bomding",
    "value": "Bomding"
  },
  {
    "id": "9112212011",
    "district_id": "911221",
    "label": "Okdunam",
    "value": "Okdunam"
  },
  {
    "id": "9112212012",
    "district_id": "911221",
    "label": "Paune",
    "value": "Paune"
  },
  {
    "id": "9112222001",
    "district_id": "911222",
    "label": "Oklip",
    "value": "Oklip"
  },
  {
    "id": "9112222002",
    "district_id": "911222",
    "label": "Okhim",
    "value": "Okhim"
  },
  {
    "id": "9112222003",
    "district_id": "911222",
    "label": "Oktem",
    "value": "Oktem"
  },
  {
    "id": "9112222004",
    "district_id": "911222",
    "label": "Okbumul",
    "value": "Okbumul"
  },
  {
    "id": "9112222005",
    "district_id": "911222",
    "label": "Okamin",
    "value": "Okamin"
  },
  {
    "id": "9112222006",
    "district_id": "911222",
    "label": "Oktumi",
    "value": "Oktumi"
  },
  {
    "id": "9112222007",
    "district_id": "911222",
    "label": "Komok",
    "value": "Komok"
  },
  {
    "id": "9112232001",
    "district_id": "911223",
    "label": "Oktau",
    "value": "Oktau"
  },
  {
    "id": "9112232002",
    "district_id": "911223",
    "label": "Oktaru",
    "value": "Oktaru"
  },
  {
    "id": "9112232003",
    "district_id": "911223",
    "label": "Okbem",
    "value": "Okbem"
  },
  {
    "id": "9112232004",
    "district_id": "911223",
    "label": "Okngam",
    "value": "Okngam"
  },
  {
    "id": "9112232005",
    "district_id": "911223",
    "label": "Bemhimiku",
    "value": "Bemhimiku"
  },
  {
    "id": "9112232006",
    "district_id": "911223",
    "label": "Bilip Bayo",
    "value": "Bilip Bayo"
  },
  {
    "id": "9112232007",
    "district_id": "911223",
    "label": "Atang Doki",
    "value": "Atang Doki"
  },
  {
    "id": "9112232008",
    "district_id": "911223",
    "label": "Okalut Kumal",
    "value": "Okalut Kumal"
  },
  {
    "id": "9112232009",
    "district_id": "911223",
    "label": "Kaep",
    "value": "Kaep"
  },
  {
    "id": "9112242001",
    "district_id": "911224",
    "label": "Kubiphkop",
    "value": "Kubiphkop"
  },
  {
    "id": "9112242002",
    "district_id": "911224",
    "label": "Okano",
    "value": "Okano"
  },
  {
    "id": "9112242003",
    "district_id": "911224",
    "label": "Sebul",
    "value": "Sebul"
  },
  {
    "id": "9112242004",
    "district_id": "911224",
    "label": "Mangabip",
    "value": "Mangabip"
  },
  {
    "id": "9112252001",
    "district_id": "911225",
    "label": "Sabin",
    "value": "Sabin"
  },
  {
    "id": "9112252002",
    "district_id": "911225",
    "label": "Borban",
    "value": "Borban"
  },
  {
    "id": "9112252003",
    "district_id": "911225",
    "label": "Kirimu",
    "value": "Kirimu"
  },
  {
    "id": "9112252004",
    "district_id": "911225",
    "label": "Dumpasik",
    "value": "Dumpasik"
  },
  {
    "id": "9112252005",
    "district_id": "911225",
    "label": "Maksum",
    "value": "Maksum"
  },
  {
    "id": "9112252006",
    "district_id": "911225",
    "label": "Pedam",
    "value": "Pedam"
  },
  {
    "id": "9112252007",
    "district_id": "911225",
    "label": "Tupoplyom",
    "value": "Tupoplyom"
  },
  {
    "id": "9112252008",
    "district_id": "911225",
    "label": "Yapil",
    "value": "Yapil"
  },
  {
    "id": "9112252009",
    "district_id": "911225",
    "label": "Atembabol",
    "value": "Atembabol"
  },
  {
    "id": "9112252010",
    "district_id": "911225",
    "label": "Omlyom",
    "value": "Omlyom"
  },
  {
    "id": "9112252011",
    "district_id": "911225",
    "label": "Peneli",
    "value": "Peneli"
  },
  {
    "id": "9112252012",
    "district_id": "911225",
    "label": "Markum",
    "value": "Markum"
  },
  {
    "id": "9112262001",
    "district_id": "911226",
    "label": "Karye",
    "value": "Karye"
  },
  {
    "id": "9112262002",
    "district_id": "911226",
    "label": "Batani",
    "value": "Batani"
  },
  {
    "id": "9112262003",
    "district_id": "911226",
    "label": "Segame",
    "value": "Segame"
  },
  {
    "id": "9112262004",
    "district_id": "911226",
    "label": "Palur Satu",
    "value": "Palur Satu"
  },
  {
    "id": "9112262005",
    "district_id": "911226",
    "label": "Palur Dua",
    "value": "Palur Dua"
  },
  {
    "id": "9112262006",
    "district_id": "911226",
    "label": "Sople",
    "value": "Sople"
  },
  {
    "id": "9112262007",
    "district_id": "911226",
    "label": "Bakata",
    "value": "Bakata"
  },
  {
    "id": "9112272001",
    "district_id": "911227",
    "label": "Merpasikne",
    "value": "Merpasikne"
  },
  {
    "id": "9112272002",
    "district_id": "911227",
    "label": "Taramlu",
    "value": "Taramlu"
  },
  {
    "id": "9112272003",
    "district_id": "911227",
    "label": "Weime",
    "value": "Weime"
  },
  {
    "id": "9112272004",
    "district_id": "911227",
    "label": "Meryang",
    "value": "Meryang"
  },
  {
    "id": "9112272005",
    "district_id": "911227",
    "label": "Limrepasikne",
    "value": "Limrepasikne"
  },
  {
    "id": "9112272006",
    "district_id": "911227",
    "label": "Youlban",
    "value": "Youlban"
  },
  {
    "id": "9112272007",
    "district_id": "911227",
    "label": "Mekdamgon",
    "value": "Mekdamgon"
  },
  {
    "id": "9112272008",
    "district_id": "911227",
    "label": "Daluban",
    "value": "Daluban"
  },
  {
    "id": "9112272009",
    "district_id": "911227",
    "label": "Nomteren",
    "value": "Nomteren"
  },
  {
    "id": "9112282001",
    "district_id": "911228",
    "label": "Bias",
    "value": "Bias"
  },
  {
    "id": "9112282002",
    "district_id": "911228",
    "label": "Bumi",
    "value": "Bumi"
  },
  {
    "id": "9112282003",
    "district_id": "911228",
    "label": "Tero",
    "value": "Tero"
  },
  {
    "id": "9112282004",
    "district_id": "911228",
    "label": "Delemo",
    "value": "Delemo"
  },
  {
    "id": "9112292001",
    "district_id": "911229",
    "label": "Mot",
    "value": "Mot"
  },
  {
    "id": "9112292002",
    "district_id": "911229",
    "label": "Milki",
    "value": "Milki"
  },
  {
    "id": "9112292003",
    "district_id": "911229",
    "label": "Yubu",
    "value": "Yubu"
  },
  {
    "id": "9112292004",
    "district_id": "911229",
    "label": "Muara Asbi",
    "value": "Muara Asbi"
  },
  {
    "id": "9112292005",
    "district_id": "911229",
    "label": "Tual",
    "value": "Tual"
  },
  {
    "id": "9112302001",
    "district_id": "911230",
    "label": "Jetfa",
    "value": "Jetfa"
  },
  {
    "id": "9112302002",
    "district_id": "911230",
    "label": "Lulis",
    "value": "Lulis"
  },
  {
    "id": "9112302003",
    "district_id": "911230",
    "label": "Tupalma Satu",
    "value": "Tupalma Satu"
  },
  {
    "id": "9112302004",
    "district_id": "911230",
    "label": "Tupalma Dua",
    "value": "Tupalma Dua"
  },
  {
    "id": "9112302005",
    "district_id": "911230",
    "label": "Kalimbu",
    "value": "Kalimbu"
  },
  {
    "id": "9112302006",
    "district_id": "911230",
    "label": "Bris",
    "value": "Bris"
  },
  {
    "id": "9112312001",
    "district_id": "911231",
    "label": "Teiraplu",
    "value": "Teiraplu"
  },
  {
    "id": "9112312002",
    "district_id": "911231",
    "label": "Sinami",
    "value": "Sinami"
  },
  {
    "id": "9112312003",
    "district_id": "911231",
    "label": "Maigame",
    "value": "Maigame"
  },
  {
    "id": "9112312004",
    "district_id": "911231",
    "label": "Murme",
    "value": "Murme"
  },
  {
    "id": "9112312005",
    "district_id": "911231",
    "label": "Yuaban Satu",
    "value": "Yuaban Satu"
  },
  {
    "id": "9112312006",
    "district_id": "911231",
    "label": "Yuaban Dua",
    "value": "Yuaban Dua"
  },
  {
    "id": "9112312007",
    "district_id": "911231",
    "label": "Terapdei",
    "value": "Terapdei"
  },
  {
    "id": "9112312008",
    "district_id": "911231",
    "label": "Yitarget",
    "value": "Yitarget"
  },
  {
    "id": "9112312009",
    "district_id": "911231",
    "label": "Teriame",
    "value": "Teriame"
  },
  {
    "id": "9112312010",
    "district_id": "911231",
    "label": "Bautme",
    "value": "Bautme"
  },
  {
    "id": "9112322001",
    "district_id": "911232",
    "label": "Eipumek",
    "value": "Eipumek"
  },
  {
    "id": "9112322002",
    "district_id": "911232",
    "label": "Lalakon",
    "value": "Lalakon"
  },
  {
    "id": "9112322003",
    "district_id": "911232",
    "label": "Londinin",
    "value": "Londinin"
  },
  {
    "id": "9112322004",
    "district_id": "911232",
    "label": "Talemu",
    "value": "Talemu"
  },
  {
    "id": "9112322005",
    "district_id": "911232",
    "label": "Wakidam",
    "value": "Wakidam"
  },
  {
    "id": "9112322006",
    "district_id": "911232",
    "label": "Bunyirye",
    "value": "Bunyirye"
  },
  {
    "id": "9112322007",
    "district_id": "911232",
    "label": "Basiringe",
    "value": "Basiringe"
  },
  {
    "id": "9112322008",
    "district_id": "911232",
    "label": "Barama",
    "value": "Barama"
  },
  {
    "id": "9112322009",
    "district_id": "911232",
    "label": "Tanime",
    "value": "Tanime"
  },
  {
    "id": "9112322010",
    "district_id": "911232",
    "label": "Serabum",
    "value": "Serabum"
  },
  {
    "id": "9112322011",
    "district_id": "911232",
    "label": "Supleyu",
    "value": "Supleyu"
  },
  {
    "id": "9112322012",
    "district_id": "911232",
    "label": "Kweredala",
    "value": "Kweredala"
  },
  {
    "id": "9112322013",
    "district_id": "911232",
    "label": "Mungkona",
    "value": "Mungkona"
  },
  {
    "id": "9112322014",
    "district_id": "911232",
    "label": "Malingdam",
    "value": "Malingdam"
  },
  {
    "id": "9112332001",
    "district_id": "911233",
    "label": "Marikla",
    "value": "Marikla"
  },
  {
    "id": "9112332002",
    "district_id": "911233",
    "label": "Yabosorom",
    "value": "Yabosorom"
  },
  {
    "id": "9112332003",
    "district_id": "911233",
    "label": "Pamek",
    "value": "Pamek"
  },
  {
    "id": "9112332004",
    "district_id": "911233",
    "label": "Barice",
    "value": "Barice"
  },
  {
    "id": "9112332005",
    "district_id": "911233",
    "label": "Imde",
    "value": "Imde"
  },
  {
    "id": "9112332006",
    "district_id": "911233",
    "label": "Lumdakna",
    "value": "Lumdakna"
  },
  {
    "id": "9112332007",
    "district_id": "911233",
    "label": "Baramirye",
    "value": "Baramirye"
  },
  {
    "id": "9112332008",
    "district_id": "911233",
    "label": "Mandalak",
    "value": "Mandalak"
  },
  {
    "id": "9112332009",
    "district_id": "911233",
    "label": "Yokul",
    "value": "Yokul"
  },
  {
    "id": "9112332010",
    "district_id": "911233",
    "label": "Kalek",
    "value": "Kalek"
  },
  {
    "id": "9112332011",
    "district_id": "911233",
    "label": "Pinggon",
    "value": "Pinggon"
  },
  {
    "id": "9112342001",
    "district_id": "911234",
    "label": "Nongme",
    "value": "Nongme"
  },
  {
    "id": "9112342002",
    "district_id": "911234",
    "label": "Rubol",
    "value": "Rubol"
  },
  {
    "id": "9112342003",
    "district_id": "911234",
    "label": "Yokom",
    "value": "Yokom"
  },
  {
    "id": "9112342004",
    "district_id": "911234",
    "label": "Yarigon",
    "value": "Yarigon"
  },
  {
    "id": "9112342005",
    "district_id": "911234",
    "label": "Omtamur",
    "value": "Omtamur"
  },
  {
    "id": "9112342006",
    "district_id": "911234",
    "label": "Cangpally",
    "value": "Cangpally"
  },
  {
    "id": "9112342007",
    "district_id": "911234",
    "label": "Kwarban",
    "value": "Kwarban"
  },
  {
    "id": "9113011001",
    "district_id": "911301",
    "label": "Obolma",
    "value": "Obolma"
  },
  {
    "id": "9113012003",
    "district_id": "911301",
    "label": "Ibiroma",
    "value": "Ibiroma"
  },
  {
    "id": "9113012013",
    "district_id": "911301",
    "label": "Hihundes",
    "value": "Hihundes"
  },
  {
    "id": "9113012015",
    "district_id": "911301",
    "label": "Wanem",
    "value": "Wanem"
  },
  {
    "id": "9113012016",
    "district_id": "911301",
    "label": "Anjelma",
    "value": "Anjelma"
  },
  {
    "id": "9113012038",
    "district_id": "911301",
    "label": "Pusuaga",
    "value": "Pusuaga"
  },
  {
    "id": "9113012039",
    "district_id": "911301",
    "label": "Wulik Punua",
    "value": "Wulik Punua"
  },
  {
    "id": "9113012040",
    "district_id": "911301",
    "label": "Kilise",
    "value": "Kilise"
  },
  {
    "id": "9113012041",
    "district_id": "911301",
    "label": "Air Garam",
    "value": "Air Garam"
  },
  {
    "id": "9113012042",
    "district_id": "911301",
    "label": "Esalien",
    "value": "Esalien"
  },
  {
    "id": "9113012043",
    "district_id": "911301",
    "label": "Lukulema",
    "value": "Lukulema"
  },
  {
    "id": "9113012044",
    "district_id": "911301",
    "label": "Tukuarek",
    "value": "Tukuarek"
  },
  {
    "id": "9113012045",
    "district_id": "911301",
    "label": "Kima",
    "value": "Kima"
  },
  {
    "id": "9113012046",
    "district_id": "911301",
    "label": "Yagarelo",
    "value": "Yagarelo"
  },
  {
    "id": "9113012047",
    "district_id": "911301",
    "label": "Soroh",
    "value": "Soroh"
  },
  {
    "id": "9113012048",
    "district_id": "911301",
    "label": "Eroma",
    "value": "Eroma"
  },
  {
    "id": "9113012049",
    "district_id": "911301",
    "label": "Lotia",
    "value": "Lotia"
  },
  {
    "id": "9113012050",
    "district_id": "911301",
    "label": "Wuluagaima",
    "value": "Wuluagaima"
  },
  {
    "id": "9113012051",
    "district_id": "911301",
    "label": "Huken",
    "value": "Huken"
  },
  {
    "id": "9113012052",
    "district_id": "911301",
    "label": "Huguma",
    "value": "Huguma"
  },
  {
    "id": "9113012053",
    "district_id": "911301",
    "label": "Parela",
    "value": "Parela"
  },
  {
    "id": "9113012054",
    "district_id": "911301",
    "label": "Wakunyama",
    "value": "Wakunyama"
  },
  {
    "id": "9113012055",
    "district_id": "911301",
    "label": "Hesmat",
    "value": "Hesmat"
  },
  {
    "id": "9113022001",
    "district_id": "911302",
    "label": "Yaholikma",
    "value": "Yaholikma"
  },
  {
    "id": "9113022022",
    "district_id": "911302",
    "label": "Lelambo",
    "value": "Lelambo"
  },
  {
    "id": "9113022036",
    "district_id": "911302",
    "label": "Tulpa",
    "value": "Tulpa"
  },
  {
    "id": "9113022037",
    "district_id": "911302",
    "label": "Ilwap",
    "value": "Ilwap"
  },
  {
    "id": "9113022038",
    "district_id": "911302",
    "label": "Kiltam",
    "value": "Kiltam"
  },
  {
    "id": "9113022039",
    "district_id": "911302",
    "label": "Pilong",
    "value": "Pilong"
  },
  {
    "id": "9113022040",
    "district_id": "911302",
    "label": "Vuno",
    "value": "Vuno"
  },
  {
    "id": "9113022041",
    "district_id": "911302",
    "label": "Suahi",
    "value": "Suahi"
  },
  {
    "id": "9113022042",
    "district_id": "911302",
    "label": "Tohong",
    "value": "Tohong"
  },
  {
    "id": "9113022043",
    "district_id": "911302",
    "label": "Paliptek",
    "value": "Paliptek"
  },
  {
    "id": "9113022044",
    "district_id": "911302",
    "label": "Letin",
    "value": "Letin"
  },
  {
    "id": "9113022045",
    "district_id": "911302",
    "label": "Hohe",
    "value": "Hohe"
  },
  {
    "id": "9113032001",
    "district_id": "911303",
    "label": "Ninia",
    "value": "Ninia"
  },
  {
    "id": "9113032010",
    "district_id": "911303",
    "label": "Liligan",
    "value": "Liligan"
  },
  {
    "id": "9113032022",
    "district_id": "911303",
    "label": "Niniahan",
    "value": "Niniahan"
  },
  {
    "id": "9113032023",
    "district_id": "911303",
    "label": "Waerek",
    "value": "Waerek"
  },
  {
    "id": "9113032024",
    "district_id": "911303",
    "label": "Wahaik",
    "value": "Wahaik"
  },
  {
    "id": "9113032025",
    "district_id": "911303",
    "label": "Sohwal",
    "value": "Sohwal"
  },
  {
    "id": "9113032026",
    "district_id": "911303",
    "label": "Babet",
    "value": "Babet"
  },
  {
    "id": "9113032027",
    "district_id": "911303",
    "label": "Kahul",
    "value": "Kahul"
  },
  {
    "id": "9113032028",
    "district_id": "911303",
    "label": "Hwealoma",
    "value": "Hwealoma"
  },
  {
    "id": "9113032029",
    "district_id": "911303",
    "label": "Liligan II",
    "value": "Liligan II"
  },
  {
    "id": "9113062001",
    "district_id": "911306",
    "label": "Momnowok",
    "value": "Momnowok"
  },
  {
    "id": "9113062002",
    "district_id": "911306",
    "label": "Silimo",
    "value": "Silimo"
  },
  {
    "id": "9113062003",
    "district_id": "911306",
    "label": "Eksa",
    "value": "Eksa"
  },
  {
    "id": "9113062004",
    "district_id": "911306",
    "label": "Siloma",
    "value": "Siloma"
  },
  {
    "id": "9113062005",
    "district_id": "911306",
    "label": "Asia",
    "value": "Asia"
  },
  {
    "id": "9113062006",
    "district_id": "911306",
    "label": "Suet",
    "value": "Suet"
  },
  {
    "id": "9113062007",
    "district_id": "911306",
    "label": "Walkruk",
    "value": "Walkruk"
  },
  {
    "id": "9113062008",
    "district_id": "911306",
    "label": "Sodule",
    "value": "Sodule"
  },
  {
    "id": "9113062009",
    "district_id": "911306",
    "label": "Sagaduk",
    "value": "Sagaduk"
  },
  {
    "id": "9113062010",
    "district_id": "911306",
    "label": "Sianele",
    "value": "Sianele"
  },
  {
    "id": "9113062011",
    "district_id": "911306",
    "label": "Eremnahom",
    "value": "Eremnahom"
  },
  {
    "id": "9113062012",
    "district_id": "911306",
    "label": "Silon",
    "value": "Silon"
  },
  {
    "id": "9113062013",
    "district_id": "911306",
    "label": "Nekilei",
    "value": "Nekilei"
  },
  {
    "id": "9113062014",
    "district_id": "911306",
    "label": "Suok",
    "value": "Suok"
  },
  {
    "id": "9113062015",
    "district_id": "911306",
    "label": "Fabe",
    "value": "Fabe"
  },
  {
    "id": "9113062016",
    "district_id": "911306",
    "label": "Weakma",
    "value": "Weakma"
  },
  {
    "id": "9113062017",
    "district_id": "911306",
    "label": "Pinda",
    "value": "Pinda"
  },
  {
    "id": "9113062018",
    "district_id": "911306",
    "label": "Hwesalemo",
    "value": "Hwesalemo"
  },
  {
    "id": "9113062019",
    "district_id": "911306",
    "label": "Sebin",
    "value": "Sebin"
  },
  {
    "id": "9113062020",
    "district_id": "911306",
    "label": "Wubialo",
    "value": "Wubialo"
  },
  {
    "id": "9113072001",
    "district_id": "911307",
    "label": "Samenage",
    "value": "Samenage"
  },
  {
    "id": "9113072002",
    "district_id": "911307",
    "label": "Haleroma",
    "value": "Haleroma"
  },
  {
    "id": "9113072003",
    "district_id": "911307",
    "label": "Muke",
    "value": "Muke"
  },
  {
    "id": "9113072004",
    "district_id": "911307",
    "label": "Pona",
    "value": "Pona"
  },
  {
    "id": "9113072005",
    "district_id": "911307",
    "label": "Ison",
    "value": "Ison"
  },
  {
    "id": "9113072006",
    "district_id": "911307",
    "label": "Asotapo",
    "value": "Asotapo"
  },
  {
    "id": "9113072007",
    "district_id": "911307",
    "label": "Hirin",
    "value": "Hirin"
  },
  {
    "id": "9113072008",
    "district_id": "911307",
    "label": "Notnarek",
    "value": "Notnarek"
  },
  {
    "id": "9113072009",
    "district_id": "911307",
    "label": "Hugilokon",
    "value": "Hugilokon"
  },
  {
    "id": "9113082001",
    "district_id": "911308",
    "label": "Nalca",
    "value": "Nalca"
  },
  {
    "id": "9113082002",
    "district_id": "911308",
    "label": "Cenderawasih",
    "value": "Cenderawasih"
  },
  {
    "id": "9113082003",
    "district_id": "911308",
    "label": "Manggona",
    "value": "Manggona"
  },
  {
    "id": "9113082004",
    "district_id": "911308",
    "label": "Bolda",
    "value": "Bolda"
  },
  {
    "id": "9113082005",
    "district_id": "911308",
    "label": "Emm",
    "value": "Emm"
  },
  {
    "id": "9113082006",
    "district_id": "911308",
    "label": "Imsekla",
    "value": "Imsekla"
  },
  {
    "id": "9113082007",
    "district_id": "911308",
    "label": "Hein",
    "value": "Hein"
  },
  {
    "id": "9113082008",
    "district_id": "911308",
    "label": "Luwemna",
    "value": "Luwemna"
  },
  {
    "id": "9113092001",
    "district_id": "911309",
    "label": "Dekai",
    "value": "Dekai"
  },
  {
    "id": "9113092002",
    "district_id": "911309",
    "label": "Kuaserama",
    "value": "Kuaserama"
  },
  {
    "id": "9113092003",
    "district_id": "911309",
    "label": "Maruku",
    "value": "Maruku"
  },
  {
    "id": "9113092004",
    "district_id": "911309",
    "label": "Massi",
    "value": "Massi"
  },
  {
    "id": "9113092005",
    "district_id": "911309",
    "label": "Keikey",
    "value": "Keikey"
  },
  {
    "id": "9113092006",
    "district_id": "911309",
    "label": "Kuari",
    "value": "Kuari"
  },
  {
    "id": "9113092007",
    "district_id": "911309",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "9113092008",
    "district_id": "911309",
    "label": "Kiribun",
    "value": "Kiribun"
  },
  {
    "id": "9113092009",
    "district_id": "911309",
    "label": "Kokamu",
    "value": "Kokamu"
  },
  {
    "id": "9113092010",
    "district_id": "911309",
    "label": "Tomon I",
    "value": "Tomon I"
  },
  {
    "id": "9113092011",
    "district_id": "911309",
    "label": "Sokamu",
    "value": "Sokamu"
  },
  {
    "id": "9113092012",
    "district_id": "911309",
    "label": "Tomon II",
    "value": "Tomon II"
  },
  {
    "id": "9113102001",
    "district_id": "911310",
    "label": "Munu",
    "value": "Munu"
  },
  {
    "id": "9113102002",
    "district_id": "911310",
    "label": "Obio",
    "value": "Obio"
  },
  {
    "id": "9113102003",
    "district_id": "911310",
    "label": "Pugun",
    "value": "Pugun"
  },
  {
    "id": "9113102004",
    "district_id": "911310",
    "label": "Ujin",
    "value": "Ujin"
  },
  {
    "id": "9113102005",
    "district_id": "911310",
    "label": "Yahufa",
    "value": "Yahufa"
  },
  {
    "id": "9113102006",
    "district_id": "911310",
    "label": "Jibak",
    "value": "Jibak"
  },
  {
    "id": "9113102007",
    "district_id": "911310",
    "label": "Patin",
    "value": "Patin"
  },
  {
    "id": "9113102008",
    "district_id": "911310",
    "label": "Hom hom",
    "value": "Hom hom"
  },
  {
    "id": "9113102009",
    "district_id": "911310",
    "label": "Wendame",
    "value": "Wendame"
  },
  {
    "id": "9113102010",
    "district_id": "911310",
    "label": "Kwakenoro",
    "value": "Kwakenoro"
  },
  {
    "id": "9113102011",
    "district_id": "911310",
    "label": "Busupa",
    "value": "Busupa"
  },
  {
    "id": "9113102012",
    "district_id": "911310",
    "label": "Yagabo",
    "value": "Yagabo"
  },
  {
    "id": "9113102013",
    "district_id": "911310",
    "label": "Penigik",
    "value": "Penigik"
  },
  {
    "id": "9113112003",
    "district_id": "911311",
    "label": "Gofa",
    "value": "Gofa"
  },
  {
    "id": "9113112006",
    "district_id": "911311",
    "label": "Jinusugu",
    "value": "Jinusugu"
  },
  {
    "id": "9113112009",
    "district_id": "911311",
    "label": "Worapi",
    "value": "Worapi"
  },
  {
    "id": "9113112010",
    "district_id": "911311",
    "label": "Senipan",
    "value": "Senipan"
  },
  {
    "id": "9113112011",
    "district_id": "911311",
    "label": "Yosua",
    "value": "Yosua"
  },
  {
    "id": "9113112012",
    "district_id": "911311",
    "label": "Rentis",
    "value": "Rentis"
  },
  {
    "id": "9113112013",
    "district_id": "911311",
    "label": "Solok",
    "value": "Solok"
  },
  {
    "id": "9113122001",
    "district_id": "911312",
    "label": "Apdagma",
    "value": "Apdagma"
  },
  {
    "id": "9113122002",
    "district_id": "911312",
    "label": "Sabin",
    "value": "Sabin"
  },
  {
    "id": "9113122003",
    "district_id": "911312",
    "label": "Mugi",
    "value": "Mugi"
  },
  {
    "id": "9113122004",
    "district_id": "911312",
    "label": "Harap",
    "value": "Harap"
  },
  {
    "id": "9113122005",
    "district_id": "911312",
    "label": "Suok",
    "value": "Suok"
  },
  {
    "id": "9113122006",
    "district_id": "911312",
    "label": "Mul",
    "value": "Mul"
  },
  {
    "id": "9113122007",
    "district_id": "911312",
    "label": "Kolodoma",
    "value": "Kolodoma"
  },
  {
    "id": "9113122008",
    "district_id": "911312",
    "label": "Sangatur",
    "value": "Sangatur"
  },
  {
    "id": "9113122009",
    "district_id": "911312",
    "label": "Sowaling",
    "value": "Sowaling"
  },
  {
    "id": "9113122010",
    "district_id": "911312",
    "label": "Yerekama",
    "value": "Yerekama"
  },
  {
    "id": "9113122011",
    "district_id": "911312",
    "label": "Walkuma",
    "value": "Walkuma"
  },
  {
    "id": "9113132001",
    "district_id": "911313",
    "label": "Amuma",
    "value": "Amuma"
  },
  {
    "id": "9113132002",
    "district_id": "911313",
    "label": "Sarmuge",
    "value": "Sarmuge"
  },
  {
    "id": "9113132003",
    "district_id": "911313",
    "label": "Tolikapura",
    "value": "Tolikapura"
  },
  {
    "id": "9113132004",
    "district_id": "911313",
    "label": "Wisikma",
    "value": "Wisikma"
  },
  {
    "id": "9113132005",
    "district_id": "911313",
    "label": "Silorin",
    "value": "Silorin"
  },
  {
    "id": "9113132006",
    "district_id": "911313",
    "label": "Waeklek",
    "value": "Waeklek"
  },
  {
    "id": "9113132007",
    "district_id": "911313",
    "label": "Balum Erang",
    "value": "Balum Erang"
  },
  {
    "id": "9113132008",
    "district_id": "911313",
    "label": "Golowen",
    "value": "Golowen"
  },
  {
    "id": "9113132009",
    "district_id": "911313",
    "label": "Orugai",
    "value": "Orugai"
  },
  {
    "id": "9113132010",
    "district_id": "911313",
    "label": "Plau",
    "value": "Plau"
  },
  {
    "id": "9113132011",
    "district_id": "911313",
    "label": "Sagasal",
    "value": "Sagasal"
  },
  {
    "id": "9113132012",
    "district_id": "911313",
    "label": "Kinika",
    "value": "Kinika"
  },
  {
    "id": "9113132013",
    "district_id": "911313",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "9113142001",
    "district_id": "911314",
    "label": "Usaregeik",
    "value": "Usaregeik"
  },
  {
    "id": "9113142002",
    "district_id": "911314",
    "label": "Lidipmu",
    "value": "Lidipmu"
  },
  {
    "id": "9113142003",
    "district_id": "911314",
    "label": "Sumegen",
    "value": "Sumegen"
  },
  {
    "id": "9113142004",
    "district_id": "911314",
    "label": "Hendikma",
    "value": "Hendikma"
  },
  {
    "id": "9113142005",
    "district_id": "911314",
    "label": "Bue",
    "value": "Bue"
  },
  {
    "id": "9113142006",
    "district_id": "911314",
    "label": "Haletuk",
    "value": "Haletuk"
  },
  {
    "id": "9113142007",
    "district_id": "911314",
    "label": "Dekbok",
    "value": "Dekbok"
  },
  {
    "id": "9113142008",
    "district_id": "911314",
    "label": "Yeriko",
    "value": "Yeriko"
  },
  {
    "id": "9113142009",
    "district_id": "911314",
    "label": "Weleheikma",
    "value": "Weleheikma"
  },
  {
    "id": "9113142010",
    "district_id": "911314",
    "label": "Esaikma",
    "value": "Esaikma"
  },
  {
    "id": "9113152001",
    "district_id": "911315",
    "label": "Pasema",
    "value": "Pasema"
  },
  {
    "id": "9113152002",
    "district_id": "911315",
    "label": "Leinoko",
    "value": "Leinoko"
  },
  {
    "id": "9113152003",
    "district_id": "911315",
    "label": "Lowet",
    "value": "Lowet"
  },
  {
    "id": "9113152004",
    "district_id": "911315",
    "label": "Wea",
    "value": "Wea"
  },
  {
    "id": "9113152005",
    "district_id": "911315",
    "label": "Sengangge",
    "value": "Sengangge"
  },
  {
    "id": "9113152006",
    "district_id": "911315",
    "label": "Wulin",
    "value": "Wulin"
  },
  {
    "id": "9113152007",
    "district_id": "911315",
    "label": "Pupi",
    "value": "Pupi"
  },
  {
    "id": "9113162001",
    "district_id": "911316",
    "label": "Paima",
    "value": "Paima"
  },
  {
    "id": "9113162002",
    "district_id": "911316",
    "label": "Dugumhad",
    "value": "Dugumhad"
  },
  {
    "id": "9113162003",
    "district_id": "911316",
    "label": "Leleak",
    "value": "Leleak"
  },
  {
    "id": "9113162004",
    "district_id": "911316",
    "label": "Subsal",
    "value": "Subsal"
  },
  {
    "id": "9113162005",
    "district_id": "911316",
    "label": "Sengsenagaik",
    "value": "Sengsenagaik"
  },
  {
    "id": "9113162006",
    "district_id": "911316",
    "label": "Sipnidipmu",
    "value": "Sipnidipmu"
  },
  {
    "id": "9113162007",
    "district_id": "911316",
    "label": "Hogio I",
    "value": "Hogio I"
  },
  {
    "id": "9113162008",
    "district_id": "911316",
    "label": "Hogio II",
    "value": "Hogio II"
  },
  {
    "id": "9113172001",
    "district_id": "911317",
    "label": "Kosihun",
    "value": "Kosihun"
  },
  {
    "id": "9113172002",
    "district_id": "911317",
    "label": "Seima",
    "value": "Seima"
  },
  {
    "id": "9113172003",
    "district_id": "911317",
    "label": "Userem",
    "value": "Userem"
  },
  {
    "id": "9113172004",
    "district_id": "911317",
    "label": "Yuarima",
    "value": "Yuarima"
  },
  {
    "id": "9113172005",
    "district_id": "911317",
    "label": "Ugem",
    "value": "Ugem"
  },
  {
    "id": "9113172006",
    "district_id": "911317",
    "label": "Wusagasem",
    "value": "Wusagasem"
  },
  {
    "id": "9113172007",
    "district_id": "911317",
    "label": "Hunenima",
    "value": "Hunenima"
  },
  {
    "id": "9113172008",
    "district_id": "911317",
    "label": "Itlay Wopi",
    "value": "Itlay Wopi"
  },
  {
    "id": "9113172009",
    "district_id": "911317",
    "label": "Togoluk",
    "value": "Togoluk"
  },
  {
    "id": "9113172010",
    "district_id": "911317",
    "label": "Herawe",
    "value": "Herawe"
  },
  {
    "id": "9113172011",
    "district_id": "911317",
    "label": "Hendang",
    "value": "Hendang"
  },
  {
    "id": "9113172012",
    "district_id": "911317",
    "label": "Ilokomo",
    "value": "Ilokomo"
  },
  {
    "id": "9113172013",
    "district_id": "911317",
    "label": "Hulesi",
    "value": "Hulesi"
  },
  {
    "id": "9113172014",
    "district_id": "911317",
    "label": "Wanesi",
    "value": "Wanesi"
  },
  {
    "id": "9113172015",
    "district_id": "911317",
    "label": "Panyoke",
    "value": "Panyoke"
  },
  {
    "id": "9113172016",
    "district_id": "911317",
    "label": "Nyikinen",
    "value": "Nyikinen"
  },
  {
    "id": "9113172017",
    "district_id": "911317",
    "label": "Wesangma",
    "value": "Wesangma"
  },
  {
    "id": "9113172018",
    "district_id": "911317",
    "label": "Olomisang",
    "value": "Olomisang"
  },
  {
    "id": "9113172019",
    "district_id": "911317",
    "label": "Wemasili",
    "value": "Wemasili"
  },
  {
    "id": "9113172020",
    "district_id": "911317",
    "label": "Lugulmu",
    "value": "Lugulmu"
  },
  {
    "id": "9113182001",
    "district_id": "911318",
    "label": "Soba",
    "value": "Soba"
  },
  {
    "id": "9113182002",
    "district_id": "911318",
    "label": "Hepinis",
    "value": "Hepinis"
  },
  {
    "id": "9113182003",
    "district_id": "911318",
    "label": "Ugulintipmu",
    "value": "Ugulintipmu"
  },
  {
    "id": "9113182004",
    "district_id": "911318",
    "label": "Nilen",
    "value": "Nilen"
  },
  {
    "id": "9113182005",
    "district_id": "911318",
    "label": "Surukmu",
    "value": "Surukmu"
  },
  {
    "id": "9113182006",
    "district_id": "911318",
    "label": "Tanda",
    "value": "Tanda"
  },
  {
    "id": "9113192001",
    "district_id": "911319",
    "label": "Lokon",
    "value": "Lokon"
  },
  {
    "id": "9113192002",
    "district_id": "911319",
    "label": "Luarima",
    "value": "Luarima"
  },
  {
    "id": "9113192003",
    "district_id": "911319",
    "label": "Werima",
    "value": "Werima"
  },
  {
    "id": "9113192004",
    "district_id": "911319",
    "label": "Hanoasuok",
    "value": "Hanoasuok"
  },
  {
    "id": "9113192005",
    "district_id": "911319",
    "label": "Wesagalep",
    "value": "Wesagalep"
  },
  {
    "id": "9113192006",
    "district_id": "911319",
    "label": "Wesama",
    "value": "Wesama"
  },
  {
    "id": "9113192007",
    "district_id": "911319",
    "label": "Weasi",
    "value": "Weasi"
  },
  {
    "id": "9113192008",
    "district_id": "911319",
    "label": "Weriaput",
    "value": "Weriaput"
  },
  {
    "id": "9113192009",
    "district_id": "911319",
    "label": "Suesi",
    "value": "Suesi"
  },
  {
    "id": "9113192010",
    "district_id": "911319",
    "label": "Nesuwen",
    "value": "Nesuwen"
  },
  {
    "id": "9113192011",
    "district_id": "911319",
    "label": "Esema Husage",
    "value": "Esema Husage"
  },
  {
    "id": "9113192012",
    "district_id": "911319",
    "label": "Libuk",
    "value": "Libuk"
  },
  {
    "id": "9113192013",
    "district_id": "911319",
    "label": "Hagawen",
    "value": "Hagawen"
  },
  {
    "id": "9113192014",
    "district_id": "911319",
    "label": "Hepia",
    "value": "Hepia"
  },
  {
    "id": "9113202001",
    "district_id": "911320",
    "label": "Tangma",
    "value": "Tangma"
  },
  {
    "id": "9113202002",
    "district_id": "911320",
    "label": "Wamarek",
    "value": "Wamarek"
  },
  {
    "id": "9113202003",
    "district_id": "911320",
    "label": "Hipela",
    "value": "Hipela"
  },
  {
    "id": "9113202004",
    "district_id": "911320",
    "label": "Huewi",
    "value": "Huewi"
  },
  {
    "id": "9113202005",
    "district_id": "911320",
    "label": "Punno",
    "value": "Punno"
  },
  {
    "id": "9113202006",
    "district_id": "911320",
    "label": "Simeka",
    "value": "Simeka"
  },
  {
    "id": "9113202007",
    "district_id": "911320",
    "label": "Yeleas",
    "value": "Yeleas"
  },
  {
    "id": "9113202008",
    "district_id": "911320",
    "label": "Aleleng",
    "value": "Aleleng"
  },
  {
    "id": "9113202009",
    "district_id": "911320",
    "label": "Yalimo",
    "value": "Yalimo"
  },
  {
    "id": "9113202010",
    "district_id": "911320",
    "label": "Helo",
    "value": "Helo"
  },
  {
    "id": "9113212001",
    "district_id": "911321",
    "label": "Ukha",
    "value": "Ukha"
  },
  {
    "id": "9113212002",
    "district_id": "911321",
    "label": "Kuleken",
    "value": "Kuleken"
  },
  {
    "id": "9113212003",
    "district_id": "911321",
    "label": "Amisangi",
    "value": "Amisangi"
  },
  {
    "id": "9113212004",
    "district_id": "911321",
    "label": "Wenapuk",
    "value": "Wenapuk"
  },
  {
    "id": "9113212005",
    "district_id": "911321",
    "label": "Eki",
    "value": "Eki"
  },
  {
    "id": "9113212006",
    "district_id": "911321",
    "label": "Yelepunu",
    "value": "Yelepunu"
  },
  {
    "id": "9113212007",
    "district_id": "911321",
    "label": "Siolorema",
    "value": "Siolorema"
  },
  {
    "id": "9113212008",
    "district_id": "911321",
    "label": "Musanarek",
    "value": "Musanarek"
  },
  {
    "id": "9113212009",
    "district_id": "911321",
    "label": "Walialema",
    "value": "Walialema"
  },
  {
    "id": "9113212010",
    "district_id": "911321",
    "label": "Lua",
    "value": "Lua"
  },
  {
    "id": "9113222001",
    "district_id": "911322",
    "label": "Pontenikma",
    "value": "Pontenikma"
  },
  {
    "id": "9113222002",
    "district_id": "911322",
    "label": "Kuni",
    "value": "Kuni"
  },
  {
    "id": "9113222003",
    "district_id": "911322",
    "label": "Panggema",
    "value": "Panggema"
  },
  {
    "id": "9113222004",
    "district_id": "911322",
    "label": "Homtonggo",
    "value": "Homtonggo"
  },
  {
    "id": "9113222005",
    "district_id": "911322",
    "label": "Tukam",
    "value": "Tukam"
  },
  {
    "id": "9113222006",
    "district_id": "911322",
    "label": "Kemumanggen",
    "value": "Kemumanggen"
  },
  {
    "id": "9113222007",
    "district_id": "911322",
    "label": "Yangkali",
    "value": "Yangkali"
  },
  {
    "id": "9113222008",
    "district_id": "911322",
    "label": "Weresili",
    "value": "Weresili"
  },
  {
    "id": "9113222009",
    "district_id": "911322",
    "label": "Susek",
    "value": "Susek"
  },
  {
    "id": "9113222010",
    "district_id": "911322",
    "label": "Kolu",
    "value": "Kolu"
  },
  {
    "id": "9113222011",
    "district_id": "911322",
    "label": "Tonggoi",
    "value": "Tonggoi"
  },
  {
    "id": "9113222012",
    "district_id": "911322",
    "label": "Bineluk",
    "value": "Bineluk"
  },
  {
    "id": "9113222013",
    "district_id": "911322",
    "label": "Masahan",
    "value": "Masahan"
  },
  {
    "id": "9113232001",
    "district_id": "911323",
    "label": "Kosarek",
    "value": "Kosarek"
  },
  {
    "id": "9113232002",
    "district_id": "911323",
    "label": "Nahomas",
    "value": "Nahomas"
  },
  {
    "id": "9113232003",
    "district_id": "911323",
    "label": "Silkom",
    "value": "Silkom"
  },
  {
    "id": "9113232004",
    "district_id": "911323",
    "label": "Wahe",
    "value": "Wahe"
  },
  {
    "id": "9113232005",
    "district_id": "911323",
    "label": "Hombuka",
    "value": "Hombuka"
  },
  {
    "id": "9113232006",
    "district_id": "911323",
    "label": "Mine",
    "value": "Mine"
  },
  {
    "id": "9113232007",
    "district_id": "911323",
    "label": "Uldam",
    "value": "Uldam"
  },
  {
    "id": "9113232008",
    "district_id": "911323",
    "label": "Tiple",
    "value": "Tiple"
  },
  {
    "id": "9113232009",
    "district_id": "911323",
    "label": "Wesaltek",
    "value": "Wesaltek"
  },
  {
    "id": "9113232010",
    "district_id": "911323",
    "label": "Konosa",
    "value": "Konosa"
  },
  {
    "id": "9113232011",
    "district_id": "911323",
    "label": "Illion",
    "value": "Illion"
  },
  {
    "id": "9113242001",
    "district_id": "911324",
    "label": "Nipsan",
    "value": "Nipsan"
  },
  {
    "id": "9113242002",
    "district_id": "911324",
    "label": "Tapla",
    "value": "Tapla"
  },
  {
    "id": "9113242003",
    "district_id": "911324",
    "label": "Walmak",
    "value": "Walmak"
  },
  {
    "id": "9113242004",
    "district_id": "911324",
    "label": "Serengan",
    "value": "Serengan"
  },
  {
    "id": "9113242005",
    "district_id": "911324",
    "label": "Ikmu",
    "value": "Ikmu"
  },
  {
    "id": "9113242006",
    "district_id": "911324",
    "label": "Biu",
    "value": "Biu"
  },
  {
    "id": "9113242007",
    "district_id": "911324",
    "label": "Ilukdamon",
    "value": "Ilukdamon"
  },
  {
    "id": "9113242008",
    "district_id": "911324",
    "label": "Yalmun",
    "value": "Yalmun"
  },
  {
    "id": "9113252001",
    "district_id": "911325",
    "label": "Ubahak",
    "value": "Ubahak"
  },
  {
    "id": "9113252002",
    "district_id": "911325",
    "label": "Saruk",
    "value": "Saruk"
  },
  {
    "id": "9113252003",
    "district_id": "911325",
    "label": "Pini",
    "value": "Pini"
  },
  {
    "id": "9113252004",
    "district_id": "911325",
    "label": "Sosomikma",
    "value": "Sosomikma"
  },
  {
    "id": "9113252005",
    "district_id": "911325",
    "label": "Suhuntek",
    "value": "Suhuntek"
  },
  {
    "id": "9113252006",
    "district_id": "911325",
    "label": "Keleng",
    "value": "Keleng"
  },
  {
    "id": "9113252007",
    "district_id": "911325",
    "label": "Silong",
    "value": "Silong"
  },
  {
    "id": "9113252008",
    "district_id": "911325",
    "label": "Nurap",
    "value": "Nurap"
  },
  {
    "id": "9113252009",
    "district_id": "911325",
    "label": "Wamuhuk",
    "value": "Wamuhuk"
  },
  {
    "id": "9113252010",
    "district_id": "911325",
    "label": "Welile",
    "value": "Welile"
  },
  {
    "id": "9113252011",
    "district_id": "911325",
    "label": "Mabien",
    "value": "Mabien"
  },
  {
    "id": "9113252012",
    "district_id": "911325",
    "label": "Kibianggen",
    "value": "Kibianggen"
  },
  {
    "id": "9113252013",
    "district_id": "911325",
    "label": "Koluang",
    "value": "Koluang"
  },
  {
    "id": "9113252014",
    "district_id": "911325",
    "label": "Tolombing",
    "value": "Tolombing"
  },
  {
    "id": "9113252015",
    "district_id": "911325",
    "label": "Furuluk",
    "value": "Furuluk"
  },
  {
    "id": "9113252016",
    "district_id": "911325",
    "label": "Oblin",
    "value": "Oblin"
  },
  {
    "id": "9113252017",
    "district_id": "911325",
    "label": "Bisifak",
    "value": "Bisifak"
  },
  {
    "id": "9113262001",
    "district_id": "911326",
    "label": "Siwikma",
    "value": "Siwikma"
  },
  {
    "id": "9113262002",
    "district_id": "911326",
    "label": "Pontempilik",
    "value": "Pontempilik"
  },
  {
    "id": "9113262003",
    "district_id": "911326",
    "label": "Piliam",
    "value": "Piliam"
  },
  {
    "id": "9113262004",
    "district_id": "911326",
    "label": "Hiklahin",
    "value": "Hiklahin"
  },
  {
    "id": "9113262005",
    "district_id": "911326",
    "label": "Helapilik",
    "value": "Helapilik"
  },
  {
    "id": "9113262006",
    "district_id": "911326",
    "label": "Lirutek",
    "value": "Lirutek"
  },
  {
    "id": "9113262007",
    "district_id": "911326",
    "label": "Huhulpunu",
    "value": "Huhulpunu"
  },
  {
    "id": "9113262008",
    "district_id": "911326",
    "label": "Pronggoli",
    "value": "Pronggoli"
  },
  {
    "id": "9113272001",
    "district_id": "911327",
    "label": "Walma",
    "value": "Walma"
  },
  {
    "id": "9113272002",
    "district_id": "911327",
    "label": "Solinggul",
    "value": "Solinggul"
  },
  {
    "id": "9113272003",
    "district_id": "911327",
    "label": "Hontulun",
    "value": "Hontulun"
  },
  {
    "id": "9113272004",
    "district_id": "911327",
    "label": "Honggolek",
    "value": "Honggolek"
  },
  {
    "id": "9113272005",
    "district_id": "911327",
    "label": "Werene",
    "value": "Werene"
  },
  {
    "id": "9113272006",
    "district_id": "911327",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "9113272007",
    "district_id": "911327",
    "label": "Wewasi",
    "value": "Wewasi"
  },
  {
    "id": "9113272008",
    "district_id": "911327",
    "label": "Yetehup",
    "value": "Yetehup"
  },
  {
    "id": "9113282001",
    "district_id": "911328",
    "label": "Subundalek",
    "value": "Subundalek"
  },
  {
    "id": "9113282002",
    "district_id": "911328",
    "label": "Tanggeam",
    "value": "Tanggeam"
  },
  {
    "id": "9113282003",
    "district_id": "911328",
    "label": "Amfulma",
    "value": "Amfulma"
  },
  {
    "id": "9113282004",
    "district_id": "911328",
    "label": "Suksale",
    "value": "Suksale"
  },
  {
    "id": "9113282005",
    "district_id": "911328",
    "label": "Linggisen",
    "value": "Linggisen"
  },
  {
    "id": "9113292001",
    "district_id": "911329",
    "label": "Hereapini",
    "value": "Hereapini"
  },
  {
    "id": "9113292002",
    "district_id": "911329",
    "label": "Pelentum",
    "value": "Pelentum"
  },
  {
    "id": "9113292003",
    "district_id": "911329",
    "label": "Homboki",
    "value": "Homboki"
  },
  {
    "id": "9113292004",
    "district_id": "911329",
    "label": "Menetsing",
    "value": "Menetsing"
  },
  {
    "id": "9113292005",
    "district_id": "911329",
    "label": "Pue",
    "value": "Pue"
  },
  {
    "id": "9113292006",
    "district_id": "911329",
    "label": "Kabukal",
    "value": "Kabukal"
  },
  {
    "id": "9113292007",
    "district_id": "911329",
    "label": "Kinkun",
    "value": "Kinkun"
  },
  {
    "id": "9113292008",
    "district_id": "911329",
    "label": "Hoet",
    "value": "Hoet"
  },
  {
    "id": "9113292009",
    "district_id": "911329",
    "label": "Muhumu",
    "value": "Muhumu"
  },
  {
    "id": "9113292010",
    "district_id": "911329",
    "label": "Ohena",
    "value": "Ohena"
  },
  {
    "id": "9113292011",
    "district_id": "911329",
    "label": "Nelisa",
    "value": "Nelisa"
  },
  {
    "id": "9113302001",
    "district_id": "911330",
    "label": "Wanam",
    "value": "Wanam"
  },
  {
    "id": "9113302002",
    "district_id": "911330",
    "label": "Hereky",
    "value": "Hereky"
  },
  {
    "id": "9113302003",
    "district_id": "911330",
    "label": "Membaham",
    "value": "Membaham"
  },
  {
    "id": "9113302004",
    "district_id": "911330",
    "label": "Wisomi",
    "value": "Wisomi"
  },
  {
    "id": "9113302005",
    "district_id": "911330",
    "label": "Talambela",
    "value": "Talambela"
  },
  {
    "id": "9113302006",
    "district_id": "911330",
    "label": "Sehelu",
    "value": "Sehelu"
  },
  {
    "id": "9113302007",
    "district_id": "911330",
    "label": "Ubalihion",
    "value": "Ubalihion"
  },
  {
    "id": "9113302008",
    "district_id": "911330",
    "label": "Walahan",
    "value": "Walahan"
  },
  {
    "id": "9113302009",
    "district_id": "911330",
    "label": "Salakma",
    "value": "Salakma"
  },
  {
    "id": "9113302010",
    "district_id": "911330",
    "label": "Samiyul",
    "value": "Samiyul"
  },
  {
    "id": "9113302011",
    "district_id": "911330",
    "label": "Letfet",
    "value": "Letfet"
  },
  {
    "id": "9113312001",
    "district_id": "911331",
    "label": "Lelambo",
    "value": "Lelambo"
  },
  {
    "id": "9113312002",
    "district_id": "911331",
    "label": "Tibul",
    "value": "Tibul"
  },
  {
    "id": "9113312003",
    "district_id": "911331",
    "label": "Kikila",
    "value": "Kikila"
  },
  {
    "id": "9113312004",
    "district_id": "911331",
    "label": "Palukae",
    "value": "Palukae"
  },
  {
    "id": "9113312005",
    "district_id": "911331",
    "label": "Wubri",
    "value": "Wubri"
  },
  {
    "id": "9113312006",
    "district_id": "911331",
    "label": "Bari",
    "value": "Bari"
  },
  {
    "id": "9113312007",
    "district_id": "911331",
    "label": "Lulum",
    "value": "Lulum"
  },
  {
    "id": "9113312008",
    "district_id": "911331",
    "label": "Paleromon",
    "value": "Paleromon"
  },
  {
    "id": "9113312009",
    "district_id": "911331",
    "label": "Lelambo 2",
    "value": "Lelambo 2"
  },
  {
    "id": "9113322001",
    "district_id": "911332",
    "label": "Puldama",
    "value": "Puldama"
  },
  {
    "id": "9113322002",
    "district_id": "911332",
    "label": "Kasen",
    "value": "Kasen"
  },
  {
    "id": "9113322003",
    "district_id": "911332",
    "label": "Bako",
    "value": "Bako"
  },
  {
    "id": "9113322004",
    "district_id": "911332",
    "label": "Balsek",
    "value": "Balsek"
  },
  {
    "id": "9113322005",
    "district_id": "911332",
    "label": "Manbolak",
    "value": "Manbolak"
  },
  {
    "id": "9113322006",
    "district_id": "911332",
    "label": "Famek",
    "value": "Famek"
  },
  {
    "id": "9113322007",
    "district_id": "911332",
    "label": "Semlu",
    "value": "Semlu"
  },
  {
    "id": "9113322008",
    "district_id": "911332",
    "label": "Baru",
    "value": "Baru"
  },
  {
    "id": "9113332001",
    "district_id": "911333",
    "label": "Endomen",
    "value": "Endomen"
  },
  {
    "id": "9113332002",
    "district_id": "911333",
    "label": "Okloma",
    "value": "Okloma"
  },
  {
    "id": "9113332003",
    "district_id": "911333",
    "label": "Delon",
    "value": "Delon"
  },
  {
    "id": "9113332004",
    "district_id": "911333",
    "label": "Suron",
    "value": "Suron"
  },
  {
    "id": "9113332005",
    "district_id": "911333",
    "label": "Sentul",
    "value": "Sentul"
  },
  {
    "id": "9113332006",
    "district_id": "911333",
    "label": "Gidomen",
    "value": "Gidomen"
  },
  {
    "id": "9113332007",
    "district_id": "911333",
    "label": "Galsin",
    "value": "Galsin"
  },
  {
    "id": "9113332008",
    "district_id": "911333",
    "label": "Kouwet",
    "value": "Kouwet"
  },
  {
    "id": "9113342001",
    "district_id": "911334",
    "label": "Kona",
    "value": "Kona"
  },
  {
    "id": "9113342002",
    "district_id": "911334",
    "label": "Kalpok",
    "value": "Kalpok"
  },
  {
    "id": "9113342003",
    "district_id": "911334",
    "label": "Seklak",
    "value": "Seklak"
  },
  {
    "id": "9113342004",
    "district_id": "911334",
    "label": "Wetmuk",
    "value": "Wetmuk"
  },
  {
    "id": "9113342005",
    "district_id": "911334",
    "label": "Kugun",
    "value": "Kugun"
  },
  {
    "id": "9113352001",
    "district_id": "911335",
    "label": "Dirwemna",
    "value": "Dirwemna"
  },
  {
    "id": "9113352002",
    "district_id": "911335",
    "label": "Aksal",
    "value": "Aksal"
  },
  {
    "id": "9113352003",
    "district_id": "911335",
    "label": "Dolsen",
    "value": "Dolsen"
  },
  {
    "id": "9113352004",
    "district_id": "911335",
    "label": "Salengkomo",
    "value": "Salengkomo"
  },
  {
    "id": "9113352005",
    "district_id": "911335",
    "label": "Silion",
    "value": "Silion"
  },
  {
    "id": "9113362001",
    "district_id": "911336",
    "label": "Holuwon",
    "value": "Holuwon"
  },
  {
    "id": "9113362002",
    "district_id": "911336",
    "label": "Danggema",
    "value": "Danggema"
  },
  {
    "id": "9113362003",
    "district_id": "911336",
    "label": "Weni",
    "value": "Weni"
  },
  {
    "id": "9113362004",
    "district_id": "911336",
    "label": "Sosi",
    "value": "Sosi"
  },
  {
    "id": "9113362005",
    "district_id": "911336",
    "label": "Sia",
    "value": "Sia"
  },
  {
    "id": "9113362006",
    "district_id": "911336",
    "label": "Bineleruk",
    "value": "Bineleruk"
  },
  {
    "id": "9113362007",
    "district_id": "911336",
    "label": "Sohonggik",
    "value": "Sohonggik"
  },
  {
    "id": "9113372001",
    "district_id": "911337",
    "label": "Wanim",
    "value": "Wanim"
  },
  {
    "id": "9113372002",
    "district_id": "911337",
    "label": "Lolat",
    "value": "Lolat"
  },
  {
    "id": "9113372003",
    "district_id": "911337",
    "label": "Bunde",
    "value": "Bunde"
  },
  {
    "id": "9113372004",
    "district_id": "911337",
    "label": "Serahak",
    "value": "Serahak"
  },
  {
    "id": "9113372005",
    "district_id": "911337",
    "label": "Webile",
    "value": "Webile"
  },
  {
    "id": "9113372006",
    "district_id": "911337",
    "label": "Dinggila",
    "value": "Dinggila"
  },
  {
    "id": "9113372007",
    "district_id": "911337",
    "label": "Esalek",
    "value": "Esalek"
  },
  {
    "id": "9113372008",
    "district_id": "911337",
    "label": "Denema",
    "value": "Denema"
  },
  {
    "id": "9113382001",
    "district_id": "911338",
    "label": "Weawen",
    "value": "Weawen"
  },
  {
    "id": "9113382002",
    "district_id": "911338",
    "label": "Kwelena",
    "value": "Kwelena"
  },
  {
    "id": "9113382003",
    "district_id": "911338",
    "label": "Singokla",
    "value": "Singokla"
  },
  {
    "id": "9113382004",
    "district_id": "911338",
    "label": "Banga",
    "value": "Banga"
  },
  {
    "id": "9113382005",
    "district_id": "911338",
    "label": "Soline",
    "value": "Soline"
  },
  {
    "id": "9113382006",
    "district_id": "911338",
    "label": "Musan",
    "value": "Musan"
  },
  {
    "id": "9113382007",
    "district_id": "911338",
    "label": "Salbi",
    "value": "Salbi"
  },
  {
    "id": "9113382008",
    "district_id": "911338",
    "label": "Erim",
    "value": "Erim"
  },
  {
    "id": "9113392001",
    "district_id": "911339",
    "label": "Sela",
    "value": "Sela"
  },
  {
    "id": "9113392002",
    "district_id": "911339",
    "label": "Megom",
    "value": "Megom"
  },
  {
    "id": "9113392003",
    "district_id": "911339",
    "label": "Mondon",
    "value": "Mondon"
  },
  {
    "id": "9113392004",
    "district_id": "911339",
    "label": "Horomon",
    "value": "Horomon"
  },
  {
    "id": "9113392005",
    "district_id": "911339",
    "label": "Makero",
    "value": "Makero"
  },
  {
    "id": "9113392006",
    "district_id": "911339",
    "label": "Orisin",
    "value": "Orisin"
  },
  {
    "id": "9113392007",
    "district_id": "911339",
    "label": "Monamna",
    "value": "Monamna"
  },
  {
    "id": "9113392008",
    "district_id": "911339",
    "label": "Ejub",
    "value": "Ejub"
  },
  {
    "id": "9113392009",
    "district_id": "911339",
    "label": "Dolbol",
    "value": "Dolbol"
  },
  {
    "id": "9113392010",
    "district_id": "911339",
    "label": "Hemuka",
    "value": "Hemuka"
  },
  {
    "id": "9113392011",
    "district_id": "911339",
    "label": "Phoy",
    "value": "Phoy"
  },
  {
    "id": "9113392012",
    "district_id": "911339",
    "label": "Sulda",
    "value": "Sulda"
  },
  {
    "id": "9113392013",
    "district_id": "911339",
    "label": "Mekdua",
    "value": "Mekdua"
  },
  {
    "id": "9113392014",
    "district_id": "911339",
    "label": "Bera",
    "value": "Bera"
  },
  {
    "id": "9113392015",
    "district_id": "911339",
    "label": "Holdomen",
    "value": "Holdomen"
  },
  {
    "id": "9113392016",
    "district_id": "911339",
    "label": "Asok",
    "value": "Asok"
  },
  {
    "id": "9113402001",
    "district_id": "911340",
    "label": "Koropun",
    "value": "Koropun"
  },
  {
    "id": "9113402002",
    "district_id": "911340",
    "label": "Dagi",
    "value": "Dagi"
  },
  {
    "id": "9113402003",
    "district_id": "911340",
    "label": "Wemin",
    "value": "Wemin"
  },
  {
    "id": "9113402004",
    "district_id": "911340",
    "label": "Morome",
    "value": "Morome"
  },
  {
    "id": "9113402005",
    "district_id": "911340",
    "label": "Moo",
    "value": "Moo"
  },
  {
    "id": "9113402006",
    "district_id": "911340",
    "label": "Batkuk",
    "value": "Batkuk"
  },
  {
    "id": "9113402007",
    "district_id": "911340",
    "label": "Maldua",
    "value": "Maldua"
  },
  {
    "id": "9113402008",
    "district_id": "911340",
    "label": "Amdua",
    "value": "Amdua"
  },
  {
    "id": "9113402009",
    "district_id": "911340",
    "label": "Somsek",
    "value": "Somsek"
  },
  {
    "id": "9113402010",
    "district_id": "911340",
    "label": "Basal",
    "value": "Basal"
  },
  {
    "id": "9113402011",
    "district_id": "911340",
    "label": "Yamdua",
    "value": "Yamdua"
  },
  {
    "id": "9113402012",
    "district_id": "911340",
    "label": "Dildau",
    "value": "Dildau"
  },
  {
    "id": "9113412001",
    "district_id": "911341",
    "label": "Langda",
    "value": "Langda"
  },
  {
    "id": "9113412002",
    "district_id": "911341",
    "label": "Alirji",
    "value": "Alirji"
  },
  {
    "id": "9113412003",
    "district_id": "911341",
    "label": "Laryi",
    "value": "Laryi"
  },
  {
    "id": "9113412004",
    "district_id": "911341",
    "label": "Wasumurji",
    "value": "Wasumurji"
  },
  {
    "id": "9113412005",
    "district_id": "911341",
    "label": "Kirabuk",
    "value": "Kirabuk"
  },
  {
    "id": "9113412006",
    "district_id": "911341",
    "label": "Kap-kap",
    "value": "Kap-kap"
  },
  {
    "id": "9113412007",
    "district_id": "911341",
    "label": "Yalar",
    "value": "Yalar"
  },
  {
    "id": "9113412008",
    "district_id": "911341",
    "label": "Bebekle",
    "value": "Bebekle"
  },
  {
    "id": "9113412009",
    "district_id": "911341",
    "label": "Lukun",
    "value": "Lukun"
  },
  {
    "id": "9113422001",
    "district_id": "911342",
    "label": "Bomela",
    "value": "Bomela"
  },
  {
    "id": "9113422002",
    "district_id": "911342",
    "label": "Yalmabi",
    "value": "Yalmabi"
  },
  {
    "id": "9113422003",
    "district_id": "911342",
    "label": "Kitikni",
    "value": "Kitikni"
  },
  {
    "id": "9113422004",
    "district_id": "911342",
    "label": "Sombat",
    "value": "Sombat"
  },
  {
    "id": "9113422005",
    "district_id": "911342",
    "label": "Kubialat",
    "value": "Kubialat"
  },
  {
    "id": "9113422006",
    "district_id": "911342",
    "label": "Palamdua",
    "value": "Palamdua"
  },
  {
    "id": "9113432001",
    "district_id": "911343",
    "label": "Suntamon",
    "value": "Suntamon"
  },
  {
    "id": "9113432002",
    "district_id": "911343",
    "label": "Tapasik",
    "value": "Tapasik"
  },
  {
    "id": "9113432003",
    "district_id": "911343",
    "label": "Bulbul",
    "value": "Bulbul"
  },
  {
    "id": "9113432004",
    "district_id": "911343",
    "label": "Dirik",
    "value": "Dirik"
  },
  {
    "id": "9113432005",
    "district_id": "911343",
    "label": "Yup",
    "value": "Yup"
  },
  {
    "id": "9113432006",
    "district_id": "911343",
    "label": "Bongkok",
    "value": "Bongkok"
  },
  {
    "id": "9113432007",
    "district_id": "911343",
    "label": "Masumkon",
    "value": "Masumkon"
  },
  {
    "id": "9113432008",
    "district_id": "911343",
    "label": "Binalkon",
    "value": "Binalkon"
  },
  {
    "id": "9113442001",
    "district_id": "911344",
    "label": "Seredala",
    "value": "Seredala"
  },
  {
    "id": "9113442002",
    "district_id": "911344",
    "label": "Moruf",
    "value": "Moruf"
  },
  {
    "id": "9113442003",
    "district_id": "911344",
    "label": "Koper",
    "value": "Koper"
  },
  {
    "id": "9113442004",
    "district_id": "911344",
    "label": "Teret",
    "value": "Teret"
  },
  {
    "id": "9113442005",
    "district_id": "911344",
    "label": "Tokuni",
    "value": "Tokuni"
  },
  {
    "id": "9113442006",
    "district_id": "911344",
    "label": "Burupmakot",
    "value": "Burupmakot"
  },
  {
    "id": "9113442007",
    "district_id": "911344",
    "label": "Yasip",
    "value": "Yasip"
  },
  {
    "id": "9113442008",
    "district_id": "911344",
    "label": "Mosomduba",
    "value": "Mosomduba"
  },
  {
    "id": "9113442009",
    "district_id": "911344",
    "label": "Samboga",
    "value": "Samboga"
  },
  {
    "id": "9113452001",
    "district_id": "911345",
    "label": "Yalisomon",
    "value": "Yalisomon"
  },
  {
    "id": "9113452002",
    "district_id": "911345",
    "label": "Yabi",
    "value": "Yabi"
  },
  {
    "id": "9113452003",
    "district_id": "911345",
    "label": "Sobaham",
    "value": "Sobaham"
  },
  {
    "id": "9113452004",
    "district_id": "911345",
    "label": "Balinggama",
    "value": "Balinggama"
  },
  {
    "id": "9113452005",
    "district_id": "911345",
    "label": "Dohong",
    "value": "Dohong"
  },
  {
    "id": "9113452006",
    "district_id": "911345",
    "label": "Hamik",
    "value": "Hamik"
  },
  {
    "id": "9113452007",
    "district_id": "911345",
    "label": "Kulahaik",
    "value": "Kulahaik"
  },
  {
    "id": "9113452008",
    "district_id": "911345",
    "label": "Simbuk",
    "value": "Simbuk"
  },
  {
    "id": "9113452009",
    "district_id": "911345",
    "label": "Puahuun",
    "value": "Puahuun"
  },
  {
    "id": "9113452010",
    "district_id": "911345",
    "label": "Silak Pahabol",
    "value": "Silak Pahabol"
  },
  {
    "id": "9113452011",
    "district_id": "911345",
    "label": "Sele",
    "value": "Sele"
  },
  {
    "id": "9113452012",
    "district_id": "911345",
    "label": "Benembia",
    "value": "Benembia"
  },
  {
    "id": "9113452013",
    "district_id": "911345",
    "label": "Hubik",
    "value": "Hubik"
  },
  {
    "id": "9113462001",
    "district_id": "911346",
    "label": "Kabianggama",
    "value": "Kabianggama"
  },
  {
    "id": "9113462002",
    "district_id": "911346",
    "label": "Sohobma",
    "value": "Sohobma"
  },
  {
    "id": "9113462003",
    "district_id": "911346",
    "label": "Sohal",
    "value": "Sohal"
  },
  {
    "id": "9113462004",
    "district_id": "911346",
    "label": "Bahabolma",
    "value": "Bahabolma"
  },
  {
    "id": "9113462005",
    "district_id": "911346",
    "label": "Subayo",
    "value": "Subayo"
  },
  {
    "id": "9113462006",
    "district_id": "911346",
    "label": "Buahun",
    "value": "Buahun"
  },
  {
    "id": "9113462007",
    "district_id": "911346",
    "label": "Domul",
    "value": "Domul"
  },
  {
    "id": "9113472001",
    "district_id": "911347",
    "label": "Debula",
    "value": "Debula"
  },
  {
    "id": "9113472002",
    "district_id": "911347",
    "label": "Kwelemdua",
    "value": "Kwelemdua"
  },
  {
    "id": "9113472003",
    "district_id": "911347",
    "label": "Kubuk dua",
    "value": "Kubuk dua"
  },
  {
    "id": "9113472004",
    "district_id": "911347",
    "label": "Senayom",
    "value": "Senayom"
  },
  {
    "id": "9113472005",
    "district_id": "911347",
    "label": "Sesepne",
    "value": "Sesepne"
  },
  {
    "id": "9113472006",
    "district_id": "911347",
    "label": "Baluk",
    "value": "Baluk"
  },
  {
    "id": "9113472007",
    "district_id": "911347",
    "label": "Kemligin",
    "value": "Kemligin"
  },
  {
    "id": "9113472008",
    "district_id": "911347",
    "label": "Obis",
    "value": "Obis"
  },
  {
    "id": "9113472009",
    "district_id": "911347",
    "label": "Yalidamon",
    "value": "Yalidamon"
  },
  {
    "id": "9113472010",
    "district_id": "911347",
    "label": "Kabulan",
    "value": "Kabulan"
  },
  {
    "id": "9113482001",
    "district_id": "911348",
    "label": "Silakma",
    "value": "Silakma"
  },
  {
    "id": "9113482002",
    "district_id": "911348",
    "label": "Soamarin",
    "value": "Soamarin"
  },
  {
    "id": "9113482003",
    "district_id": "911348",
    "label": "Kenkeni",
    "value": "Kenkeni"
  },
  {
    "id": "9113482004",
    "district_id": "911348",
    "label": "Monin",
    "value": "Monin"
  },
  {
    "id": "9113482005",
    "district_id": "911348",
    "label": "Enggelasue",
    "value": "Enggelasue"
  },
  {
    "id": "9113482006",
    "district_id": "911348",
    "label": "Oakbisik",
    "value": "Oakbisik"
  },
  {
    "id": "9113482007",
    "district_id": "911348",
    "label": "Momenggama",
    "value": "Momenggama"
  },
  {
    "id": "9113482008",
    "district_id": "911348",
    "label": "Husmo",
    "value": "Husmo"
  },
  {
    "id": "9113482009",
    "district_id": "911348",
    "label": "Karelma",
    "value": "Karelma"
  },
  {
    "id": "9113482010",
    "district_id": "911348",
    "label": "Usabini",
    "value": "Usabini"
  },
  {
    "id": "9113492001",
    "district_id": "911349",
    "label": "Hilipuk",
    "value": "Hilipuk"
  },
  {
    "id": "9113492002",
    "district_id": "911349",
    "label": "Homidipmu",
    "value": "Homidipmu"
  },
  {
    "id": "9113492003",
    "district_id": "911349",
    "label": "Kini",
    "value": "Kini"
  },
  {
    "id": "9113492004",
    "district_id": "911349",
    "label": "Yaruhuk",
    "value": "Yaruhuk"
  },
  {
    "id": "9113492005",
    "district_id": "911349",
    "label": "Jamin",
    "value": "Jamin"
  },
  {
    "id": "9113492006",
    "district_id": "911349",
    "label": "Dindok",
    "value": "Dindok"
  },
  {
    "id": "9113492007",
    "district_id": "911349",
    "label": "Sohondipmu",
    "value": "Sohondipmu"
  },
  {
    "id": "9113502001",
    "district_id": "911350",
    "label": "Duram",
    "value": "Duram"
  },
  {
    "id": "9113502002",
    "district_id": "911350",
    "label": "Yaminikma",
    "value": "Yaminikma"
  },
  {
    "id": "9113502003",
    "district_id": "911350",
    "label": "Goak",
    "value": "Goak"
  },
  {
    "id": "9113502004",
    "district_id": "911350",
    "label": "Digitme",
    "value": "Digitme"
  },
  {
    "id": "9113502005",
    "district_id": "911350",
    "label": "Dengat",
    "value": "Dengat"
  },
  {
    "id": "9113502006",
    "district_id": "911350",
    "label": "Niniwi",
    "value": "Niniwi"
  },
  {
    "id": "9113512001",
    "district_id": "911351",
    "label": "Yogosem",
    "value": "Yogosem"
  },
  {
    "id": "9113512002",
    "district_id": "911351",
    "label": "Kiroma",
    "value": "Kiroma"
  },
  {
    "id": "9113512003",
    "district_id": "911351",
    "label": "Sogasio",
    "value": "Sogasio"
  },
  {
    "id": "9113512004",
    "district_id": "911351",
    "label": "Saikama",
    "value": "Saikama"
  },
  {
    "id": "9113512005",
    "district_id": "911351",
    "label": "Hokolekma",
    "value": "Hokolekma"
  },
  {
    "id": "9113512006",
    "district_id": "911351",
    "label": "Esahapuk",
    "value": "Esahapuk"
  },
  {
    "id": "9113512007",
    "district_id": "911351",
    "label": "Mesahaput",
    "value": "Mesahaput"
  },
  {
    "id": "9113522001",
    "district_id": "911352",
    "label": "Kayo",
    "value": "Kayo"
  },
  {
    "id": "9113522002",
    "district_id": "911352",
    "label": "Molama",
    "value": "Molama"
  },
  {
    "id": "9113522003",
    "district_id": "911352",
    "label": "Sebu",
    "value": "Sebu"
  },
  {
    "id": "9113522004",
    "district_id": "911352",
    "label": "Walet",
    "value": "Walet"
  },
  {
    "id": "9113522005",
    "district_id": "911352",
    "label": "Hombesaloma",
    "value": "Hombesaloma"
  },
  {
    "id": "9113522006",
    "district_id": "911352",
    "label": "Alhai",
    "value": "Alhai"
  },
  {
    "id": "9113522007",
    "district_id": "911352",
    "label": "Uase",
    "value": "Uase"
  },
  {
    "id": "9113532001",
    "district_id": "911353",
    "label": "Sumo",
    "value": "Sumo"
  },
  {
    "id": "9113532002",
    "district_id": "911353",
    "label": "Uam",
    "value": "Uam"
  },
  {
    "id": "9113532003",
    "district_id": "911353",
    "label": "Maku",
    "value": "Maku"
  },
  {
    "id": "9113532004",
    "district_id": "911353",
    "label": "Obokain",
    "value": "Obokain"
  },
  {
    "id": "9113532005",
    "district_id": "911353",
    "label": "Jibuk",
    "value": "Jibuk"
  },
  {
    "id": "9113532006",
    "district_id": "911353",
    "label": "Wenapong",
    "value": "Wenapong"
  },
  {
    "id": "9113532007",
    "district_id": "911353",
    "label": "Muara Balim",
    "value": "Muara Balim"
  },
  {
    "id": "9113532008",
    "district_id": "911353",
    "label": "Hunisi",
    "value": "Hunisi"
  },
  {
    "id": "9113532009",
    "district_id": "911353",
    "label": "Boor",
    "value": "Boor"
  },
  {
    "id": "9113532010",
    "district_id": "911353",
    "label": "Wirilu",
    "value": "Wirilu"
  },
  {
    "id": "9113532011",
    "district_id": "911353",
    "label": "Kwiriside",
    "value": "Kwiriside"
  },
  {
    "id": "9114011001",
    "district_id": "911401",
    "label": "Karubaga",
    "value": "Karubaga"
  },
  {
    "id": "9114012010",
    "district_id": "911401",
    "label": "Kogimagi",
    "value": "Kogimagi"
  },
  {
    "id": "9114012012",
    "district_id": "911401",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "9114012039",
    "district_id": "911401",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "9114012054",
    "district_id": "911401",
    "label": "Elsadai",
    "value": "Elsadai"
  },
  {
    "id": "9114012055",
    "district_id": "911401",
    "label": "Ebenhaiser",
    "value": "Ebenhaiser"
  },
  {
    "id": "9114012056",
    "district_id": "911401",
    "label": "Kolilan",
    "value": "Kolilan"
  },
  {
    "id": "9114012058",
    "district_id": "911401",
    "label": "Kuloname",
    "value": "Kuloname"
  },
  {
    "id": "9114012059",
    "district_id": "911401",
    "label": "Lirak",
    "value": "Lirak"
  },
  {
    "id": "9114012060",
    "district_id": "911401",
    "label": "Kimobur",
    "value": "Kimobur"
  },
  {
    "id": "9114012061",
    "district_id": "911401",
    "label": "Kuragepura",
    "value": "Kuragepura"
  },
  {
    "id": "9114012062",
    "district_id": "911401",
    "label": "Kiranage",
    "value": "Kiranage"
  },
  {
    "id": "9114012064",
    "district_id": "911401",
    "label": "Luwik",
    "value": "Luwik"
  },
  {
    "id": "9114012067",
    "district_id": "911401",
    "label": "Gininggadonak",
    "value": "Gininggadonak"
  },
  {
    "id": "9114012068",
    "district_id": "911401",
    "label": "Danggulurik",
    "value": "Danggulurik"
  },
  {
    "id": "9114012069",
    "district_id": "911401",
    "label": "Gurikme",
    "value": "Gurikme"
  },
  {
    "id": "9114012070",
    "district_id": "911401",
    "label": "Molera",
    "value": "Molera"
  },
  {
    "id": "9114012071",
    "district_id": "911401",
    "label": "Nalorini",
    "value": "Nalorini"
  },
  {
    "id": "9114012072",
    "district_id": "911401",
    "label": "Beleme",
    "value": "Beleme"
  },
  {
    "id": "9114012073",
    "district_id": "911401",
    "label": "Gurikagewa",
    "value": "Gurikagewa"
  },
  {
    "id": "9114012074",
    "district_id": "911401",
    "label": "Pulanggun",
    "value": "Pulanggun"
  },
  {
    "id": "9114012075",
    "district_id": "911401",
    "label": "Yalikaluk",
    "value": "Yalikaluk"
  },
  {
    "id": "9114012076",
    "district_id": "911401",
    "label": "Losmen",
    "value": "Losmen"
  },
  {
    "id": "9114021001",
    "district_id": "911402",
    "label": "Bokondini",
    "value": "Bokondini"
  },
  {
    "id": "9114022006",
    "district_id": "911402",
    "label": "Mairini",
    "value": "Mairini"
  },
  {
    "id": "9114022007",
    "district_id": "911402",
    "label": "Apiam",
    "value": "Apiam"
  },
  {
    "id": "9114022008",
    "district_id": "911402",
    "label": "Umaga",
    "value": "Umaga"
  },
  {
    "id": "9114022015",
    "district_id": "911402",
    "label": "Galala",
    "value": "Galala"
  },
  {
    "id": "9114022016",
    "district_id": "911402",
    "label": "Mingganggo",
    "value": "Mingganggo"
  },
  {
    "id": "9114022022",
    "district_id": "911402",
    "label": "Tenggagama",
    "value": "Tenggagama"
  },
  {
    "id": "9114022035",
    "district_id": "911402",
    "label": "Kologume",
    "value": "Kologume"
  },
  {
    "id": "9114022036",
    "district_id": "911402",
    "label": "Lambogo",
    "value": "Lambogo"
  },
  {
    "id": "9114022039",
    "district_id": "911402",
    "label": "Jawalane",
    "value": "Jawalane"
  },
  {
    "id": "9114022041",
    "district_id": "911402",
    "label": "Dunduma",
    "value": "Dunduma"
  },
  {
    "id": "9114031001",
    "district_id": "911403",
    "label": "Kanggime",
    "value": "Kanggime"
  },
  {
    "id": "9114032014",
    "district_id": "911403",
    "label": "Marlo",
    "value": "Marlo"
  },
  {
    "id": "9114032015",
    "district_id": "911403",
    "label": "Logon",
    "value": "Logon"
  },
  {
    "id": "9114032018",
    "district_id": "911403",
    "label": "Aulani",
    "value": "Aulani"
  },
  {
    "id": "9114032029",
    "district_id": "911403",
    "label": "Ligiibak",
    "value": "Ligiibak"
  },
  {
    "id": "9114032043",
    "district_id": "911403",
    "label": "Kerena",
    "value": "Kerena"
  },
  {
    "id": "9114032045",
    "district_id": "911403",
    "label": "Purugi",
    "value": "Purugi"
  },
  {
    "id": "9114032051",
    "district_id": "911403",
    "label": "Lawor",
    "value": "Lawor"
  },
  {
    "id": "9114032053",
    "district_id": "911403",
    "label": "Kagimaluk",
    "value": "Kagimaluk"
  },
  {
    "id": "9114032054",
    "district_id": "911403",
    "label": "Dundu",
    "value": "Dundu"
  },
  {
    "id": "9114041001",
    "district_id": "911404",
    "label": "Kembu",
    "value": "Kembu"
  },
  {
    "id": "9114042010",
    "district_id": "911404",
    "label": "Mamit",
    "value": "Mamit"
  },
  {
    "id": "9114042019",
    "district_id": "911404",
    "label": "Yowo",
    "value": "Yowo"
  },
  {
    "id": "9114042026",
    "district_id": "911404",
    "label": "Nugari",
    "value": "Nugari"
  },
  {
    "id": "9114042033",
    "district_id": "911404",
    "label": "Kobon",
    "value": "Kobon"
  },
  {
    "id": "9114042035",
    "district_id": "911404",
    "label": "Kabori",
    "value": "Kabori"
  },
  {
    "id": "9114042038",
    "district_id": "911404",
    "label": "Genani",
    "value": "Genani"
  },
  {
    "id": "9114042039",
    "district_id": "911404",
    "label": "Wulinaga",
    "value": "Wulinaga"
  },
  {
    "id": "9114042041",
    "district_id": "911404",
    "label": "Aworera",
    "value": "Aworera"
  },
  {
    "id": "9114042043",
    "district_id": "911404",
    "label": "Agimdek",
    "value": "Agimdek"
  },
  {
    "id": "9114042044",
    "district_id": "911404",
    "label": "Tioga",
    "value": "Tioga"
  },
  {
    "id": "9114052001",
    "district_id": "911405",
    "label": "Goyage",
    "value": "Goyage"
  },
  {
    "id": "9114052003",
    "district_id": "911405",
    "label": "Yemarima",
    "value": "Yemarima"
  },
  {
    "id": "9114052004",
    "district_id": "911405",
    "label": "Tigikun",
    "value": "Tigikun"
  },
  {
    "id": "9114052007",
    "district_id": "911405",
    "label": "Dugi",
    "value": "Dugi"
  },
  {
    "id": "9114052008",
    "district_id": "911405",
    "label": "Doge",
    "value": "Doge"
  },
  {
    "id": "9114052018",
    "district_id": "911405",
    "label": "Benari",
    "value": "Benari"
  },
  {
    "id": "9114052019",
    "district_id": "911405",
    "label": "Wijamurik",
    "value": "Wijamurik"
  },
  {
    "id": "9114052021",
    "district_id": "911405",
    "label": "Woji",
    "value": "Woji"
  },
  {
    "id": "9114052022",
    "district_id": "911405",
    "label": "Mampulaga",
    "value": "Mampulaga"
  },
  {
    "id": "9114052023",
    "district_id": "911405",
    "label": "Tidur Mabuk",
    "value": "Tidur Mabuk"
  },
  {
    "id": "9114052024",
    "district_id": "911405",
    "label": "Bini",
    "value": "Bini"
  },
  {
    "id": "9114052025",
    "district_id": "911405",
    "label": "Didelonik",
    "value": "Didelonik"
  },
  {
    "id": "9114052026",
    "district_id": "911405",
    "label": "Kumbu",
    "value": "Kumbu"
  },
  {
    "id": "9114052027",
    "district_id": "911405",
    "label": "Peko",
    "value": "Peko"
  },
  {
    "id": "9114052028",
    "district_id": "911405",
    "label": "Tiri",
    "value": "Tiri"
  },
  {
    "id": "9114052029",
    "district_id": "911405",
    "label": "Tigir",
    "value": "Tigir"
  },
  {
    "id": "9114052030",
    "district_id": "911405",
    "label": "Bopa",
    "value": "Bopa"
  },
  {
    "id": "9114052031",
    "district_id": "911405",
    "label": "Angkasa",
    "value": "Angkasa"
  },
  {
    "id": "9114052032",
    "district_id": "911405",
    "label": "Gilok",
    "value": "Gilok"
  },
  {
    "id": "9114062001",
    "district_id": "911406",
    "label": "Wurineri",
    "value": "Wurineri"
  },
  {
    "id": "9114062003",
    "district_id": "911406",
    "label": "Pokegi",
    "value": "Pokegi"
  },
  {
    "id": "9114062004",
    "district_id": "911406",
    "label": "Arombok",
    "value": "Arombok"
  },
  {
    "id": "9114062011",
    "district_id": "911406",
    "label": "Enanagi",
    "value": "Enanagi"
  },
  {
    "id": "9114062015",
    "district_id": "911406",
    "label": "Pindak",
    "value": "Pindak"
  },
  {
    "id": "9114062016",
    "district_id": "911406",
    "label": "Gilopaga",
    "value": "Gilopaga"
  },
  {
    "id": "9114062019",
    "district_id": "911406",
    "label": "Keribaga",
    "value": "Keribaga"
  },
  {
    "id": "9114062021",
    "district_id": "911406",
    "label": "Wona",
    "value": "Wona"
  },
  {
    "id": "9114062022",
    "district_id": "911406",
    "label": "Numbuboton",
    "value": "Numbuboton"
  },
  {
    "id": "9114072003",
    "district_id": "911407",
    "label": "Wina",
    "value": "Wina"
  },
  {
    "id": "9114072004",
    "district_id": "911407",
    "label": "Gualo",
    "value": "Gualo"
  },
  {
    "id": "9114072006",
    "district_id": "911407",
    "label": "Bimbogul",
    "value": "Bimbogul"
  },
  {
    "id": "9114072014",
    "district_id": "911407",
    "label": "Yugumengga",
    "value": "Yugumengga"
  },
  {
    "id": "9114072015",
    "district_id": "911407",
    "label": "Wariru",
    "value": "Wariru"
  },
  {
    "id": "9114072016",
    "district_id": "911407",
    "label": "Yugubuk",
    "value": "Yugubuk"
  },
  {
    "id": "9114072017",
    "district_id": "911407",
    "label": "Akima",
    "value": "Akima"
  },
  {
    "id": "9114072018",
    "district_id": "911407",
    "label": "Malela",
    "value": "Malela"
  },
  {
    "id": "9114072019",
    "district_id": "911407",
    "label": "Nakwil",
    "value": "Nakwil"
  },
  {
    "id": "9114072020",
    "district_id": "911407",
    "label": "Finai",
    "value": "Finai"
  },
  {
    "id": "9114072021",
    "district_id": "911407",
    "label": "Holandia",
    "value": "Holandia"
  },
  {
    "id": "9114072022",
    "district_id": "911407",
    "label": "Tawi",
    "value": "Tawi"
  },
  {
    "id": "9114072024",
    "district_id": "911407",
    "label": "Yogweme",
    "value": "Yogweme"
  },
  {
    "id": "9114082001",
    "district_id": "911408",
    "label": "Umagi",
    "value": "Umagi"
  },
  {
    "id": "9114082003",
    "district_id": "911408",
    "label": "Yali",
    "value": "Yali"
  },
  {
    "id": "9114082004",
    "district_id": "911408",
    "label": "Nambu",
    "value": "Nambu"
  },
  {
    "id": "9114082005",
    "district_id": "911408",
    "label": "Nolopur",
    "value": "Nolopur"
  },
  {
    "id": "9114082007",
    "district_id": "911408",
    "label": "Yaleme",
    "value": "Yaleme"
  },
  {
    "id": "9114082010",
    "district_id": "911408",
    "label": "Gatini",
    "value": "Gatini"
  },
  {
    "id": "9114082012",
    "district_id": "911408",
    "label": "Mino",
    "value": "Mino"
  },
  {
    "id": "9114082013",
    "district_id": "911408",
    "label": "Gurin",
    "value": "Gurin"
  },
  {
    "id": "9114082017",
    "district_id": "911408",
    "label": "Piriluk",
    "value": "Piriluk"
  },
  {
    "id": "9114082021",
    "district_id": "911408",
    "label": "Pagongga",
    "value": "Pagongga"
  },
  {
    "id": "9114082022",
    "district_id": "911408",
    "label": "Warna",
    "value": "Warna"
  },
  {
    "id": "9114082023",
    "district_id": "911408",
    "label": "Popaga",
    "value": "Popaga"
  },
  {
    "id": "9114092001",
    "district_id": "911409",
    "label": "Panaga",
    "value": "Panaga"
  },
  {
    "id": "9114092004",
    "district_id": "911409",
    "label": "Ibunuh",
    "value": "Ibunuh"
  },
  {
    "id": "9114092010",
    "district_id": "911409",
    "label": "Saksi Maler",
    "value": "Saksi Maler"
  },
  {
    "id": "9114092011",
    "district_id": "911409",
    "label": "Kutiom",
    "value": "Kutiom"
  },
  {
    "id": "9114092014",
    "district_id": "911409",
    "label": "Eragani",
    "value": "Eragani"
  },
  {
    "id": "9114092015",
    "district_id": "911409",
    "label": "Pindanggun",
    "value": "Pindanggun"
  },
  {
    "id": "9114092016",
    "district_id": "911409",
    "label": "Siak",
    "value": "Siak"
  },
  {
    "id": "9114092017",
    "district_id": "911409",
    "label": "Yandono",
    "value": "Yandono"
  },
  {
    "id": "9114092019",
    "district_id": "911409",
    "label": "Paido",
    "value": "Paido"
  },
  {
    "id": "9114102003",
    "district_id": "911410",
    "label": "Lugwi",
    "value": "Lugwi"
  },
  {
    "id": "9114102004",
    "district_id": "911410",
    "label": "Yaliwak",
    "value": "Yaliwak"
  },
  {
    "id": "9114102010",
    "district_id": "911410",
    "label": "Teropme",
    "value": "Teropme"
  },
  {
    "id": "9114102013",
    "district_id": "911410",
    "label": "Yigonime",
    "value": "Yigonime"
  },
  {
    "id": "9114102017",
    "district_id": "911410",
    "label": "Mome",
    "value": "Mome"
  },
  {
    "id": "9114102020",
    "district_id": "911410",
    "label": "Bugum",
    "value": "Bugum"
  },
  {
    "id": "9114102027",
    "district_id": "911410",
    "label": "Pagona",
    "value": "Pagona"
  },
  {
    "id": "9114102029",
    "district_id": "911410",
    "label": "Wunabu",
    "value": "Wunabu"
  },
  {
    "id": "9114102030",
    "district_id": "911410",
    "label": "Wilileme",
    "value": "Wilileme"
  },
  {
    "id": "9114102031",
    "district_id": "911410",
    "label": "Yangguni",
    "value": "Yangguni"
  },
  {
    "id": "9114112002",
    "district_id": "911411",
    "label": "Aruku",
    "value": "Aruku"
  },
  {
    "id": "9114112003",
    "district_id": "911411",
    "label": "Kubugiwa",
    "value": "Kubugiwa"
  },
  {
    "id": "9114112005",
    "district_id": "911411",
    "label": "Kubu",
    "value": "Kubu"
  },
  {
    "id": "9114112015",
    "district_id": "911411",
    "label": "Numbugawe",
    "value": "Numbugawe"
  },
  {
    "id": "9114112016",
    "district_id": "911411",
    "label": "Tiyenggupur",
    "value": "Tiyenggupur"
  },
  {
    "id": "9114112018",
    "district_id": "911411",
    "label": "Murik",
    "value": "Murik"
  },
  {
    "id": "9114112019",
    "district_id": "911411",
    "label": "Minagi",
    "value": "Minagi"
  },
  {
    "id": "9114112020",
    "district_id": "911411",
    "label": "Kalewi",
    "value": "Kalewi"
  },
  {
    "id": "9114112026",
    "district_id": "911411",
    "label": "Menggenagi",
    "value": "Menggenagi"
  },
  {
    "id": "9114122001",
    "district_id": "911412",
    "label": "Konda",
    "value": "Konda"
  },
  {
    "id": "9114122002",
    "district_id": "911412",
    "label": "Arulo",
    "value": "Arulo"
  },
  {
    "id": "9114122010",
    "district_id": "911412",
    "label": "Mandura",
    "value": "Mandura"
  },
  {
    "id": "9114122011",
    "district_id": "911412",
    "label": "Mowilome",
    "value": "Mowilome"
  },
  {
    "id": "9114122012",
    "district_id": "911412",
    "label": "Arumagi",
    "value": "Arumagi"
  },
  {
    "id": "9114122015",
    "district_id": "911412",
    "label": "Gimo",
    "value": "Gimo"
  },
  {
    "id": "9114122017",
    "district_id": "911412",
    "label": "Silabulo",
    "value": "Silabulo"
  },
  {
    "id": "9114122018",
    "district_id": "911412",
    "label": "Arikoba",
    "value": "Arikoba"
  },
  {
    "id": "9114122019",
    "district_id": "911412",
    "label": "Tingapura",
    "value": "Tingapura"
  },
  {
    "id": "9114122020",
    "district_id": "911412",
    "label": "Ganage",
    "value": "Ganage"
  },
  {
    "id": "9114122021",
    "district_id": "911412",
    "label": "Yawineri",
    "value": "Yawineri"
  },
  {
    "id": "9114132001",
    "district_id": "911413",
    "label": "Mondagul",
    "value": "Mondagul"
  },
  {
    "id": "9114132003",
    "district_id": "911413",
    "label": "Timojimo",
    "value": "Timojimo"
  },
  {
    "id": "9114132005",
    "district_id": "911413",
    "label": "Tabowanimbo",
    "value": "Tabowanimbo"
  },
  {
    "id": "9114132006",
    "district_id": "911413",
    "label": "Nelawi",
    "value": "Nelawi"
  },
  {
    "id": "9114132007",
    "district_id": "911413",
    "label": "Woromolome",
    "value": "Woromolome"
  },
  {
    "id": "9114132008",
    "district_id": "911413",
    "label": "Kendemaya",
    "value": "Kendemaya"
  },
  {
    "id": "9114132009",
    "district_id": "911413",
    "label": "Palagi",
    "value": "Palagi"
  },
  {
    "id": "9114132010",
    "district_id": "911413",
    "label": "Megapura",
    "value": "Megapura"
  },
  {
    "id": "9114132011",
    "district_id": "911413",
    "label": "Wabuna",
    "value": "Wabuna"
  },
  {
    "id": "9114132012",
    "district_id": "911413",
    "label": "Minagame",
    "value": "Minagame"
  },
  {
    "id": "9114132013",
    "district_id": "911413",
    "label": "Barename/Luanggi",
    "value": "Barename/Luanggi"
  },
  {
    "id": "9114132014",
    "district_id": "911413",
    "label": "Yilogonime/Tabinabo",
    "value": "Yilogonime/Tabinabo"
  },
  {
    "id": "9114142001",
    "district_id": "911414",
    "label": "Kuari",
    "value": "Kuari"
  },
  {
    "id": "9114142002",
    "district_id": "911414",
    "label": "Menggeba",
    "value": "Menggeba"
  },
  {
    "id": "9114142003",
    "district_id": "911414",
    "label": "Alopur",
    "value": "Alopur"
  },
  {
    "id": "9114142004",
    "district_id": "911414",
    "label": "Kenen",
    "value": "Kenen"
  },
  {
    "id": "9114142005",
    "district_id": "911414",
    "label": "Wanggugup",
    "value": "Wanggugup"
  },
  {
    "id": "9114142006",
    "district_id": "911414",
    "label": "Baliminggi",
    "value": "Baliminggi"
  },
  {
    "id": "9114142007",
    "district_id": "911414",
    "label": "Abepur",
    "value": "Abepur"
  },
  {
    "id": "9114142008",
    "district_id": "911414",
    "label": "Markar",
    "value": "Markar"
  },
  {
    "id": "9114142009",
    "district_id": "911414",
    "label": "Luanggi",
    "value": "Luanggi"
  },
  {
    "id": "9114142010",
    "district_id": "911414",
    "label": "Kondegun",
    "value": "Kondegun"
  },
  {
    "id": "9114142011",
    "district_id": "911414",
    "label": "Jinulira",
    "value": "Jinulira"
  },
  {
    "id": "9114142012",
    "district_id": "911414",
    "label": "Kibur",
    "value": "Kibur"
  },
  {
    "id": "9114142013",
    "district_id": "911414",
    "label": "Tebenalo",
    "value": "Tebenalo"
  },
  {
    "id": "9114142014",
    "district_id": "911414",
    "label": "Umaga",
    "value": "Umaga"
  },
  {
    "id": "9114142015",
    "district_id": "911414",
    "label": "Kurik",
    "value": "Kurik"
  },
  {
    "id": "9114142017",
    "district_id": "911414",
    "label": "Menggenagame",
    "value": "Menggenagame"
  },
  {
    "id": "9114142018",
    "district_id": "911414",
    "label": "Gubagi",
    "value": "Gubagi"
  },
  {
    "id": "9114152001",
    "district_id": "911415",
    "label": "Bokoneri",
    "value": "Bokoneri"
  },
  {
    "id": "9114152002",
    "district_id": "911415",
    "label": "Donggem",
    "value": "Donggem"
  },
  {
    "id": "9114152003",
    "district_id": "911415",
    "label": "Abimbak",
    "value": "Abimbak"
  },
  {
    "id": "9114152004",
    "district_id": "911415",
    "label": "Munagame",
    "value": "Munagame"
  },
  {
    "id": "9114152005",
    "district_id": "911415",
    "label": "Kanewunuk",
    "value": "Kanewunuk"
  },
  {
    "id": "9114152006",
    "district_id": "911415",
    "label": "Nanggurilime",
    "value": "Nanggurilime"
  },
  {
    "id": "9114152007",
    "district_id": "911415",
    "label": "Kurewunuk",
    "value": "Kurewunuk"
  },
  {
    "id": "9114152008",
    "district_id": "911415",
    "label": "Durima",
    "value": "Durima"
  },
  {
    "id": "9114152009",
    "district_id": "911415",
    "label": "Lerewere",
    "value": "Lerewere"
  },
  {
    "id": "9114152010",
    "district_id": "911415",
    "label": "Bolly",
    "value": "Bolly"
  },
  {
    "id": "9114152011",
    "district_id": "911415",
    "label": "Omuk",
    "value": "Omuk"
  },
  {
    "id": "9114152013",
    "district_id": "911415",
    "label": "Wonaga",
    "value": "Wonaga"
  },
  {
    "id": "9114152014",
    "district_id": "911415",
    "label": "Waringga",
    "value": "Waringga"
  },
  {
    "id": "9114152015",
    "district_id": "911415",
    "label": "Tanabume",
    "value": "Tanabume"
  },
  {
    "id": "9114152016",
    "district_id": "911415",
    "label": "Kanere",
    "value": "Kanere"
  },
  {
    "id": "9114152017",
    "district_id": "911415",
    "label": "Nunggalo",
    "value": "Nunggalo"
  },
  {
    "id": "9114152018",
    "district_id": "911415",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "9114162001",
    "district_id": "911416",
    "label": "Abena",
    "value": "Abena"
  },
  {
    "id": "9114162002",
    "district_id": "911416",
    "label": "Nogobumbu",
    "value": "Nogobumbu"
  },
  {
    "id": "9114162003",
    "district_id": "911416",
    "label": "Bilubaga",
    "value": "Bilubaga"
  },
  {
    "id": "9114162004",
    "district_id": "911416",
    "label": "Gabunggobak",
    "value": "Gabunggobak"
  },
  {
    "id": "9114162005",
    "district_id": "911416",
    "label": "Wulurik",
    "value": "Wulurik"
  },
  {
    "id": "9114162006",
    "district_id": "911416",
    "label": "Wanggulam",
    "value": "Wanggulam"
  },
  {
    "id": "9114162007",
    "district_id": "911416",
    "label": "Bitillabur",
    "value": "Bitillabur"
  },
  {
    "id": "9114162008",
    "district_id": "911416",
    "label": "Windik",
    "value": "Windik"
  },
  {
    "id": "9114162009",
    "district_id": "911416",
    "label": "Yibalo",
    "value": "Yibalo"
  },
  {
    "id": "9114162010",
    "district_id": "911416",
    "label": "Wania",
    "value": "Wania"
  },
  {
    "id": "9114162011",
    "district_id": "911416",
    "label": "Arelam",
    "value": "Arelam"
  },
  {
    "id": "9114162012",
    "district_id": "911416",
    "label": "Gelalo",
    "value": "Gelalo"
  },
  {
    "id": "9114162013",
    "district_id": "911416",
    "label": "Duma",
    "value": "Duma"
  },
  {
    "id": "9114162014",
    "district_id": "911416",
    "label": "Yinama",
    "value": "Yinama"
  },
  {
    "id": "9114182001",
    "district_id": "911418",
    "label": "Nabunage",
    "value": "Nabunage"
  },
  {
    "id": "9114182002",
    "district_id": "911418",
    "label": "Kupara",
    "value": "Kupara"
  },
  {
    "id": "9114182003",
    "district_id": "911418",
    "label": "Kutime",
    "value": "Kutime"
  },
  {
    "id": "9114182004",
    "district_id": "911418",
    "label": "Kumbo",
    "value": "Kumbo"
  },
  {
    "id": "9114182005",
    "district_id": "911418",
    "label": "Timbindelo",
    "value": "Timbindelo"
  },
  {
    "id": "9114182011",
    "district_id": "911418",
    "label": "Jekito",
    "value": "Jekito"
  },
  {
    "id": "9114182012",
    "district_id": "911418",
    "label": "Wewo",
    "value": "Wewo"
  },
  {
    "id": "9114182013",
    "district_id": "911418",
    "label": "Geningga",
    "value": "Geningga"
  },
  {
    "id": "9114182014",
    "district_id": "911418",
    "label": "Kimilo",
    "value": "Kimilo"
  },
  {
    "id": "9114182015",
    "district_id": "911418",
    "label": "Missa",
    "value": "Missa"
  },
  {
    "id": "9114182016",
    "district_id": "911418",
    "label": "Logilome",
    "value": "Logilome"
  },
  {
    "id": "9114192001",
    "district_id": "911419",
    "label": "Tinggom",
    "value": "Tinggom"
  },
  {
    "id": "9114192003",
    "district_id": "911419",
    "label": "Egoni",
    "value": "Egoni"
  },
  {
    "id": "9114192004",
    "district_id": "911419",
    "label": "Martelo",
    "value": "Martelo"
  },
  {
    "id": "9114192006",
    "district_id": "911419",
    "label": "Lerewere",
    "value": "Lerewere"
  },
  {
    "id": "9114192007",
    "district_id": "911419",
    "label": "Yakep",
    "value": "Yakep"
  },
  {
    "id": "9114192009",
    "district_id": "911419",
    "label": "Welesi",
    "value": "Welesi"
  },
  {
    "id": "9114192010",
    "district_id": "911419",
    "label": "Orelukban",
    "value": "Orelukban"
  },
  {
    "id": "9114192011",
    "district_id": "911419",
    "label": "Baguni",
    "value": "Baguni"
  },
  {
    "id": "9114192012",
    "district_id": "911419",
    "label": "Kulutin",
    "value": "Kulutin"
  },
  {
    "id": "9114192015",
    "district_id": "911419",
    "label": "Yamulo",
    "value": "Yamulo"
  },
  {
    "id": "9114202001",
    "district_id": "911420",
    "label": "Nunggawi",
    "value": "Nunggawi"
  },
  {
    "id": "9114202002",
    "district_id": "911420",
    "label": "Kokondao",
    "value": "Kokondao"
  },
  {
    "id": "9114202003",
    "district_id": "911420",
    "label": "Belep",
    "value": "Belep"
  },
  {
    "id": "9114202004",
    "district_id": "911420",
    "label": "Kuripaga",
    "value": "Kuripaga"
  },
  {
    "id": "9114202005",
    "district_id": "911420",
    "label": "Kimobur",
    "value": "Kimobur"
  },
  {
    "id": "9114202006",
    "district_id": "911420",
    "label": "Wondame",
    "value": "Wondame"
  },
  {
    "id": "9114202007",
    "district_id": "911420",
    "label": "Kilungga",
    "value": "Kilungga"
  },
  {
    "id": "9114202008",
    "district_id": "911420",
    "label": "Nombori",
    "value": "Nombori"
  },
  {
    "id": "9114202009",
    "district_id": "911420",
    "label": "Delelah",
    "value": "Delelah"
  },
  {
    "id": "9114202010",
    "district_id": "911420",
    "label": "Gilo",
    "value": "Gilo"
  },
  {
    "id": "9114202011",
    "district_id": "911420",
    "label": "Undi",
    "value": "Undi"
  },
  {
    "id": "9114202012",
    "district_id": "911420",
    "label": "Derek",
    "value": "Derek"
  },
  {
    "id": "9114202013",
    "district_id": "911420",
    "label": "Numbe",
    "value": "Numbe"
  },
  {
    "id": "9114202014",
    "district_id": "911420",
    "label": "Kanggineri",
    "value": "Kanggineri"
  },
  {
    "id": "9114202015",
    "district_id": "911420",
    "label": "Wonoluk",
    "value": "Wonoluk"
  },
  {
    "id": "9114202016",
    "district_id": "911420",
    "label": "Barenggo",
    "value": "Barenggo"
  },
  {
    "id": "9114202017",
    "district_id": "911420",
    "label": "Tunibur",
    "value": "Tunibur"
  },
  {
    "id": "9114202018",
    "district_id": "911420",
    "label": "Woyi",
    "value": "Woyi"
  },
  {
    "id": "9114202019",
    "district_id": "911420",
    "label": "Kubalo",
    "value": "Kubalo"
  },
  {
    "id": "9114202020",
    "district_id": "911420",
    "label": "Tinoweno",
    "value": "Tinoweno"
  },
  {
    "id": "9114202021",
    "district_id": "911420",
    "label": "Mololowa",
    "value": "Mololowa"
  },
  {
    "id": "9114202022",
    "district_id": "911420",
    "label": "Kunipaga",
    "value": "Kunipaga"
  },
  {
    "id": "9114202023",
    "district_id": "911420",
    "label": "Kabumanggen",
    "value": "Kabumanggen"
  },
  {
    "id": "9114202024",
    "district_id": "911420",
    "label": "Kondangwi",
    "value": "Kondangwi"
  },
  {
    "id": "9114202025",
    "district_id": "911420",
    "label": "Kipino",
    "value": "Kipino"
  },
  {
    "id": "9114202026",
    "district_id": "911420",
    "label": "Tomobur",
    "value": "Tomobur"
  },
  {
    "id": "9114212001",
    "district_id": "911421",
    "label": "Wamolo",
    "value": "Wamolo"
  },
  {
    "id": "9114212002",
    "district_id": "911421",
    "label": "Woraga",
    "value": "Woraga"
  },
  {
    "id": "9114212003",
    "district_id": "911421",
    "label": "Kalarin",
    "value": "Kalarin"
  },
  {
    "id": "9114212004",
    "district_id": "911421",
    "label": "Gubuk",
    "value": "Gubuk"
  },
  {
    "id": "9114212005",
    "district_id": "911421",
    "label": "Umar",
    "value": "Umar"
  },
  {
    "id": "9114212007",
    "district_id": "911421",
    "label": "Wobe",
    "value": "Wobe"
  },
  {
    "id": "9114212008",
    "district_id": "911421",
    "label": "Gingga",
    "value": "Gingga"
  },
  {
    "id": "9114212009",
    "district_id": "911421",
    "label": "Punggelak",
    "value": "Punggelak"
  },
  {
    "id": "9114212010",
    "district_id": "911421",
    "label": "Kurik",
    "value": "Kurik"
  },
  {
    "id": "9114212011",
    "district_id": "911421",
    "label": "Gumbini",
    "value": "Gumbini"
  },
  {
    "id": "9114212012",
    "district_id": "911421",
    "label": "Muruneri",
    "value": "Muruneri"
  },
  {
    "id": "9114212013",
    "district_id": "911421",
    "label": "Nangga",
    "value": "Nangga"
  },
  {
    "id": "9114212014",
    "district_id": "911421",
    "label": "Enggawogo",
    "value": "Enggawogo"
  },
  {
    "id": "9114212016",
    "district_id": "911421",
    "label": "Wamili",
    "value": "Wamili"
  },
  {
    "id": "9114212017",
    "district_id": "911421",
    "label": "Oker",
    "value": "Oker"
  },
  {
    "id": "9114212018",
    "district_id": "911421",
    "label": "Winengga",
    "value": "Winengga"
  },
  {
    "id": "9114212019",
    "district_id": "911421",
    "label": "Aworera",
    "value": "Aworera"
  },
  {
    "id": "9114222001",
    "district_id": "911422",
    "label": "Numba",
    "value": "Numba"
  },
  {
    "id": "9114222005",
    "district_id": "911422",
    "label": "Baliminggi",
    "value": "Baliminggi"
  },
  {
    "id": "9114222006",
    "district_id": "911422",
    "label": "Kuma",
    "value": "Kuma"
  },
  {
    "id": "9114222007",
    "district_id": "911422",
    "label": "Yugumena",
    "value": "Yugumena"
  },
  {
    "id": "9114222009",
    "district_id": "911422",
    "label": "Yalogo",
    "value": "Yalogo"
  },
  {
    "id": "9114222011",
    "district_id": "911422",
    "label": "Yiragame",
    "value": "Yiragame"
  },
  {
    "id": "9114222012",
    "district_id": "911422",
    "label": "Tingwineri",
    "value": "Tingwineri"
  },
  {
    "id": "9114222013",
    "district_id": "911422",
    "label": "Guniki",
    "value": "Guniki"
  },
  {
    "id": "9114222014",
    "district_id": "911422",
    "label": "Keragigelok",
    "value": "Keragigelok"
  },
  {
    "id": "9114222015",
    "district_id": "911422",
    "label": "Jinuwanu",
    "value": "Jinuwanu"
  },
  {
    "id": "9114232001",
    "district_id": "911423",
    "label": "Bolubur",
    "value": "Bolubur"
  },
  {
    "id": "9114232002",
    "district_id": "911423",
    "label": "Geneluk",
    "value": "Geneluk"
  },
  {
    "id": "9114232003",
    "district_id": "911423",
    "label": "Tirib",
    "value": "Tirib"
  },
  {
    "id": "9114232004",
    "district_id": "911423",
    "label": "Beremo",
    "value": "Beremo"
  },
  {
    "id": "9114232005",
    "district_id": "911423",
    "label": "Pirage",
    "value": "Pirage"
  },
  {
    "id": "9114232006",
    "district_id": "911423",
    "label": "Koinggambu",
    "value": "Koinggambu"
  },
  {
    "id": "9114232007",
    "district_id": "911423",
    "label": "Liwina",
    "value": "Liwina"
  },
  {
    "id": "9114232008",
    "district_id": "911423",
    "label": "Eragani",
    "value": "Eragani"
  },
  {
    "id": "9114232009",
    "district_id": "911423",
    "label": "Luki",
    "value": "Luki"
  },
  {
    "id": "9114232011",
    "district_id": "911423",
    "label": "Tioner",
    "value": "Tioner"
  },
  {
    "id": "9114232013",
    "district_id": "911423",
    "label": "Logulo",
    "value": "Logulo"
  },
  {
    "id": "9114232014",
    "district_id": "911423",
    "label": "Bawi",
    "value": "Bawi"
  },
  {
    "id": "9114242001",
    "district_id": "911424",
    "label": "Dundu",
    "value": "Dundu"
  },
  {
    "id": "9114242002",
    "district_id": "911424",
    "label": "Nugini",
    "value": "Nugini"
  },
  {
    "id": "9114242003",
    "district_id": "911424",
    "label": "Kembu",
    "value": "Kembu"
  },
  {
    "id": "9114242005",
    "district_id": "911424",
    "label": "Kurupu",
    "value": "Kurupu"
  },
  {
    "id": "9114242008",
    "district_id": "911424",
    "label": "Yiku",
    "value": "Yiku"
  },
  {
    "id": "9114242009",
    "district_id": "911424",
    "label": "Bimo",
    "value": "Bimo"
  },
  {
    "id": "9114242010",
    "district_id": "911424",
    "label": "Dugunagep",
    "value": "Dugunagep"
  },
  {
    "id": "9114242011",
    "district_id": "911424",
    "label": "Nilogabu",
    "value": "Nilogabu"
  },
  {
    "id": "9114242012",
    "district_id": "911424",
    "label": "Nakwi",
    "value": "Nakwi"
  },
  {
    "id": "9114242013",
    "district_id": "911424",
    "label": "Nini",
    "value": "Nini"
  },
  {
    "id": "9114252001",
    "district_id": "911425",
    "label": "Geya",
    "value": "Geya"
  },
  {
    "id": "9114252003",
    "district_id": "911425",
    "label": "Alobaga",
    "value": "Alobaga"
  },
  {
    "id": "9114252004",
    "district_id": "911425",
    "label": "Timori",
    "value": "Timori"
  },
  {
    "id": "9114252005",
    "district_id": "911425",
    "label": "Nawu",
    "value": "Nawu"
  },
  {
    "id": "9114252006",
    "district_id": "911425",
    "label": "Tinagoga",
    "value": "Tinagoga"
  },
  {
    "id": "9114252007",
    "district_id": "911425",
    "label": "Witipur",
    "value": "Witipur"
  },
  {
    "id": "9114252008",
    "district_id": "911425",
    "label": "Jelepele",
    "value": "Jelepele"
  },
  {
    "id": "9114252012",
    "district_id": "911425",
    "label": "Winalo",
    "value": "Winalo"
  },
  {
    "id": "9114252013",
    "district_id": "911425",
    "label": "Kibu",
    "value": "Kibu"
  },
  {
    "id": "9114252014",
    "district_id": "911425",
    "label": "Wunggilipur",
    "value": "Wunggilipur"
  },
  {
    "id": "9114252015",
    "district_id": "911425",
    "label": "Weyambi",
    "value": "Weyambi"
  },
  {
    "id": "9114252019",
    "district_id": "911425",
    "label": "Dimbara",
    "value": "Dimbara"
  },
  {
    "id": "9114262001",
    "district_id": "911426",
    "label": "Egiam",
    "value": "Egiam"
  },
  {
    "id": "9114262014",
    "district_id": "911426",
    "label": "Yonira",
    "value": "Yonira"
  },
  {
    "id": "9114262015",
    "district_id": "911426",
    "label": "Tabonakme",
    "value": "Tabonakme"
  },
  {
    "id": "9114262023",
    "district_id": "911426",
    "label": "Murni",
    "value": "Murni"
  },
  {
    "id": "9114262024",
    "district_id": "911426",
    "label": "Yoka",
    "value": "Yoka"
  },
  {
    "id": "9114262025",
    "district_id": "911426",
    "label": "Wayongga",
    "value": "Wayongga"
  },
  {
    "id": "9114262026",
    "district_id": "911426",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "9114262027",
    "district_id": "911426",
    "label": "Pinde",
    "value": "Pinde"
  },
  {
    "id": "9114262028",
    "district_id": "911426",
    "label": "Kurba",
    "value": "Kurba"
  },
  {
    "id": "9114262029",
    "district_id": "911426",
    "label": "Kaliundi",
    "value": "Kaliundi"
  },
  {
    "id": "9114272024",
    "district_id": "911427",
    "label": "Gagulineri",
    "value": "Gagulineri"
  },
  {
    "id": "9114272025",
    "district_id": "911427",
    "label": "Telekonok",
    "value": "Telekonok"
  },
  {
    "id": "9114272026",
    "district_id": "911427",
    "label": "Kuoklanggunik",
    "value": "Kuoklanggunik"
  },
  {
    "id": "9114272027",
    "district_id": "911427",
    "label": "Bogokila",
    "value": "Bogokila"
  },
  {
    "id": "9114272028",
    "district_id": "911427",
    "label": "Mabuk",
    "value": "Mabuk"
  },
  {
    "id": "9114272030",
    "district_id": "911427",
    "label": "Nogari",
    "value": "Nogari"
  },
  {
    "id": "9114272032",
    "district_id": "911427",
    "label": "Genage",
    "value": "Genage"
  },
  {
    "id": "9114272034",
    "district_id": "911427",
    "label": "Tigir",
    "value": "Tigir"
  },
  {
    "id": "9114272037",
    "district_id": "911427",
    "label": "Gindugunik",
    "value": "Gindugunik"
  },
  {
    "id": "9114272038",
    "district_id": "911427",
    "label": "Konengga",
    "value": "Konengga"
  },
  {
    "id": "9114282014",
    "district_id": "911428",
    "label": "Berembanak",
    "value": "Berembanak"
  },
  {
    "id": "9114282018",
    "district_id": "911428",
    "label": "Habag",
    "value": "Habag"
  },
  {
    "id": "9114282020",
    "district_id": "911428",
    "label": "Kekoli",
    "value": "Kekoli"
  },
  {
    "id": "9114282021",
    "district_id": "911428",
    "label": "Kamboniki",
    "value": "Kamboniki"
  },
  {
    "id": "9114282026",
    "district_id": "911428",
    "label": "Kaloniki",
    "value": "Kaloniki"
  },
  {
    "id": "9114282027",
    "district_id": "911428",
    "label": "Tari",
    "value": "Tari"
  },
  {
    "id": "9114282028",
    "district_id": "911428",
    "label": "Marbuna",
    "value": "Marbuna"
  },
  {
    "id": "9114282029",
    "district_id": "911428",
    "label": "Malta",
    "value": "Malta"
  },
  {
    "id": "9114292009",
    "district_id": "911429",
    "label": "Onggokme",
    "value": "Onggokme"
  },
  {
    "id": "9114292011",
    "district_id": "911429",
    "label": "Kubur",
    "value": "Kubur"
  },
  {
    "id": "9114292012",
    "district_id": "911429",
    "label": "Weu",
    "value": "Weu"
  },
  {
    "id": "9114292013",
    "district_id": "911429",
    "label": "Liwese",
    "value": "Liwese"
  },
  {
    "id": "9114292015",
    "district_id": "911429",
    "label": "Wenduri",
    "value": "Wenduri"
  },
  {
    "id": "9114292018",
    "district_id": "911429",
    "label": "Tabo Wanimbo",
    "value": "Tabo Wanimbo"
  },
  {
    "id": "9114292019",
    "district_id": "911429",
    "label": "Lenggup",
    "value": "Lenggup"
  },
  {
    "id": "9114292020",
    "district_id": "911429",
    "label": "Tinger",
    "value": "Tinger"
  },
  {
    "id": "9114302001",
    "district_id": "911430",
    "label": "Yanora",
    "value": "Yanora"
  },
  {
    "id": "9114302004",
    "district_id": "911430",
    "label": "Wari/Taiyeve",
    "value": "Wari/Taiyeve"
  },
  {
    "id": "9114302005",
    "district_id": "911430",
    "label": "Papedari",
    "value": "Papedari"
  },
  {
    "id": "9114302006",
    "district_id": "911430",
    "label": "Dotori",
    "value": "Dotori"
  },
  {
    "id": "9114302007",
    "district_id": "911430",
    "label": "Arebe/Ninggame",
    "value": "Arebe/Ninggame"
  },
  {
    "id": "9114302008",
    "district_id": "911430",
    "label": "Kowari",
    "value": "Kowari"
  },
  {
    "id": "9114302010",
    "district_id": "911430",
    "label": "Wiki",
    "value": "Wiki"
  },
  {
    "id": "9114302011",
    "district_id": "911430",
    "label": "Kuruku",
    "value": "Kuruku"
  },
  {
    "id": "9114302012",
    "district_id": "911430",
    "label": "Friji",
    "value": "Friji"
  },
  {
    "id": "9114302013",
    "district_id": "911430",
    "label": "Dorera",
    "value": "Dorera"
  },
  {
    "id": "9114302014",
    "district_id": "911430",
    "label": "Dustra/Yamiriko",
    "value": "Dustra/Yamiriko"
  },
  {
    "id": "9114302015",
    "district_id": "911430",
    "label": "Muna",
    "value": "Muna"
  },
  {
    "id": "9114302016",
    "district_id": "911430",
    "label": "Kalibu",
    "value": "Kalibu"
  },
  {
    "id": "9114302017",
    "district_id": "911430",
    "label": "Laniloma",
    "value": "Laniloma"
  },
  {
    "id": "9114302018",
    "district_id": "911430",
    "label": "Wakumendek",
    "value": "Wakumendek"
  },
  {
    "id": "9114302021",
    "district_id": "911430",
    "label": "Timoga/Kabupaga",
    "value": "Timoga/Kabupaga"
  },
  {
    "id": "9114302022",
    "district_id": "911430",
    "label": "Beleise",
    "value": "Beleise"
  },
  {
    "id": "9114312001",
    "district_id": "911431",
    "label": "Dow/Bijere",
    "value": "Dow/Bijere"
  },
  {
    "id": "9114312004",
    "district_id": "911431",
    "label": "Takri",
    "value": "Takri"
  },
  {
    "id": "9114312005",
    "district_id": "911431",
    "label": "Prawa",
    "value": "Prawa"
  },
  {
    "id": "9114312006",
    "district_id": "911431",
    "label": "Sigou",
    "value": "Sigou"
  },
  {
    "id": "9114312007",
    "district_id": "911431",
    "label": "Dagari",
    "value": "Dagari"
  },
  {
    "id": "9114312008",
    "district_id": "911431",
    "label": "Warka",
    "value": "Warka"
  },
  {
    "id": "9114312010",
    "district_id": "911431",
    "label": "Pakare",
    "value": "Pakare"
  },
  {
    "id": "9114312011",
    "district_id": "911431",
    "label": "Tigu",
    "value": "Tigu"
  },
  {
    "id": "9114312012",
    "district_id": "911431",
    "label": "Bire",
    "value": "Bire"
  },
  {
    "id": "9114312013",
    "district_id": "911431",
    "label": "Itoli",
    "value": "Itoli"
  },
  {
    "id": "9114312014",
    "district_id": "911431",
    "label": "Vokuyo",
    "value": "Vokuyo"
  },
  {
    "id": "9114322001",
    "district_id": "911432",
    "label": "Silo",
    "value": "Silo"
  },
  {
    "id": "9114322002",
    "district_id": "911432",
    "label": "Logi",
    "value": "Logi"
  },
  {
    "id": "9114322003",
    "district_id": "911432",
    "label": "Tagi",
    "value": "Tagi"
  },
  {
    "id": "9114322004",
    "district_id": "911432",
    "label": "Bini",
    "value": "Bini"
  },
  {
    "id": "9114322005",
    "district_id": "911432",
    "label": "Dunda",
    "value": "Dunda"
  },
  {
    "id": "9114322006",
    "district_id": "911432",
    "label": "Getiem",
    "value": "Getiem"
  },
  {
    "id": "9114322007",
    "district_id": "911432",
    "label": "Rumbepaga",
    "value": "Rumbepaga"
  },
  {
    "id": "9114322008",
    "district_id": "911432",
    "label": "Yimabnime",
    "value": "Yimabnime"
  },
  {
    "id": "9114322009",
    "district_id": "911432",
    "label": "Wanuk",
    "value": "Wanuk"
  },
  {
    "id": "9114322010",
    "district_id": "911432",
    "label": "Lamaluk",
    "value": "Lamaluk"
  },
  {
    "id": "9114332001",
    "district_id": "911433",
    "label": "Yuneri",
    "value": "Yuneri"
  },
  {
    "id": "9114332002",
    "district_id": "911433",
    "label": "Yido",
    "value": "Yido"
  },
  {
    "id": "9114332003",
    "district_id": "911433",
    "label": "Omibur",
    "value": "Omibur"
  },
  {
    "id": "9114332004",
    "district_id": "911433",
    "label": "Tenabaga",
    "value": "Tenabaga"
  },
  {
    "id": "9114332005",
    "district_id": "911433",
    "label": "Wonabunggame",
    "value": "Wonabunggame"
  },
  {
    "id": "9114332006",
    "district_id": "911433",
    "label": "Gembileme",
    "value": "Gembileme"
  },
  {
    "id": "9114332007",
    "district_id": "911433",
    "label": "Wenome",
    "value": "Wenome"
  },
  {
    "id": "9114332008",
    "district_id": "911433",
    "label": "Mopi",
    "value": "Mopi"
  },
  {
    "id": "9114332009",
    "district_id": "911433",
    "label": "Kanggilo",
    "value": "Kanggilo"
  },
  {
    "id": "9114332010",
    "district_id": "911433",
    "label": "Yudimba",
    "value": "Yudimba"
  },
  {
    "id": "9114332011",
    "district_id": "911433",
    "label": "Umar",
    "value": "Umar"
  },
  {
    "id": "9114342001",
    "district_id": "911434",
    "label": "Wama",
    "value": "Wama"
  },
  {
    "id": "9114342002",
    "district_id": "911434",
    "label": "Wonitu",
    "value": "Wonitu"
  },
  {
    "id": "9114342003",
    "district_id": "911434",
    "label": "Kumbur",
    "value": "Kumbur"
  },
  {
    "id": "9114342004",
    "district_id": "911434",
    "label": "Kwarini",
    "value": "Kwarini"
  },
  {
    "id": "9114342005",
    "district_id": "911434",
    "label": "Weyage",
    "value": "Weyage"
  },
  {
    "id": "9114342006",
    "district_id": "911434",
    "label": "Timer",
    "value": "Timer"
  },
  {
    "id": "9114342007",
    "district_id": "911434",
    "label": "Gulak",
    "value": "Gulak"
  },
  {
    "id": "9114342008",
    "district_id": "911434",
    "label": "Nowo",
    "value": "Nowo"
  },
  {
    "id": "9114342009",
    "district_id": "911434",
    "label": "Gunalo",
    "value": "Gunalo"
  },
  {
    "id": "9114342010",
    "district_id": "911434",
    "label": "Korlo",
    "value": "Korlo"
  },
  {
    "id": "9114342011",
    "district_id": "911434",
    "label": "Towolome",
    "value": "Towolome"
  },
  {
    "id": "9114342012",
    "district_id": "911434",
    "label": "Golena",
    "value": "Golena"
  },
  {
    "id": "9114352001",
    "district_id": "911435",
    "label": "Geka",
    "value": "Geka"
  },
  {
    "id": "9114352002",
    "district_id": "911435",
    "label": "Kwa",
    "value": "Kwa"
  },
  {
    "id": "9114352003",
    "district_id": "911435",
    "label": "Gelok",
    "value": "Gelok"
  },
  {
    "id": "9114352004",
    "district_id": "911435",
    "label": "Makido",
    "value": "Makido"
  },
  {
    "id": "9114352005",
    "district_id": "911435",
    "label": "Yinuwanu",
    "value": "Yinuwanu"
  },
  {
    "id": "9114352006",
    "district_id": "911435",
    "label": "Wanuwi",
    "value": "Wanuwi"
  },
  {
    "id": "9114352007",
    "district_id": "911435",
    "label": "Dimbara",
    "value": "Dimbara"
  },
  {
    "id": "9114352008",
    "district_id": "911435",
    "label": "Wenigi",
    "value": "Wenigi"
  },
  {
    "id": "9114352009",
    "district_id": "911435",
    "label": "Membramonggen",
    "value": "Membramonggen"
  },
  {
    "id": "9114352010",
    "district_id": "911435",
    "label": "Tabunakme",
    "value": "Tabunakme"
  },
  {
    "id": "9114362001",
    "district_id": "911436",
    "label": "Telenggeme",
    "value": "Telenggeme"
  },
  {
    "id": "9114362002",
    "district_id": "911436",
    "label": "Aukuni",
    "value": "Aukuni"
  },
  {
    "id": "9114362003",
    "district_id": "911436",
    "label": "Tenek",
    "value": "Tenek"
  },
  {
    "id": "9114362004",
    "district_id": "911436",
    "label": "Kimunuk",
    "value": "Kimunuk"
  },
  {
    "id": "9114362005",
    "district_id": "911436",
    "label": "Kimugu",
    "value": "Kimugu"
  },
  {
    "id": "9114362006",
    "district_id": "911436",
    "label": "Yagagobak",
    "value": "Yagagobak"
  },
  {
    "id": "9114362007",
    "district_id": "911436",
    "label": "Linggira",
    "value": "Linggira"
  },
  {
    "id": "9114362008",
    "district_id": "911436",
    "label": "Wekaru",
    "value": "Wekaru"
  },
  {
    "id": "9114362009",
    "district_id": "911436",
    "label": "Dolunggun",
    "value": "Dolunggun"
  },
  {
    "id": "9114362010",
    "district_id": "911436",
    "label": "Kagi",
    "value": "Kagi"
  },
  {
    "id": "9114372001",
    "district_id": "911437",
    "label": "Anawi",
    "value": "Anawi"
  },
  {
    "id": "9114372002",
    "district_id": "911437",
    "label": "Yalipura",
    "value": "Yalipura"
  },
  {
    "id": "9114372003",
    "district_id": "911437",
    "label": "Kotori",
    "value": "Kotori"
  },
  {
    "id": "9114372004",
    "district_id": "911437",
    "label": "Bieleme",
    "value": "Bieleme"
  },
  {
    "id": "9114372005",
    "district_id": "911437",
    "label": "Yalokobak",
    "value": "Yalokobak"
  },
  {
    "id": "9114372006",
    "district_id": "911437",
    "label": "Linggira",
    "value": "Linggira"
  },
  {
    "id": "9114372007",
    "district_id": "911437",
    "label": "Aridunda",
    "value": "Aridunda"
  },
  {
    "id": "9114372008",
    "district_id": "911437",
    "label": "Loguk",
    "value": "Loguk"
  },
  {
    "id": "9114372009",
    "district_id": "911437",
    "label": "Imurik",
    "value": "Imurik"
  },
  {
    "id": "9114372010",
    "district_id": "911437",
    "label": "Gineri",
    "value": "Gineri"
  },
  {
    "id": "9114382001",
    "district_id": "911438",
    "label": "Banggeri",
    "value": "Banggeri"
  },
  {
    "id": "9114382002",
    "district_id": "911438",
    "label": "Igari",
    "value": "Igari"
  },
  {
    "id": "9114382003",
    "district_id": "911438",
    "label": "Tina",
    "value": "Tina"
  },
  {
    "id": "9114382004",
    "district_id": "911438",
    "label": "Telelomi",
    "value": "Telelomi"
  },
  {
    "id": "9114382005",
    "district_id": "911438",
    "label": "Kopenawai",
    "value": "Kopenawai"
  },
  {
    "id": "9114382006",
    "district_id": "911438",
    "label": "Wunggi",
    "value": "Wunggi"
  },
  {
    "id": "9114382007",
    "district_id": "911438",
    "label": "Baganagapur",
    "value": "Baganagapur"
  },
  {
    "id": "9114382008",
    "district_id": "911438",
    "label": "Milineri",
    "value": "Milineri"
  },
  {
    "id": "9114382009",
    "district_id": "911438",
    "label": "Mili",
    "value": "Mili"
  },
  {
    "id": "9114382010",
    "district_id": "911438",
    "label": "Geyaneri",
    "value": "Geyaneri"
  },
  {
    "id": "9114392001",
    "district_id": "911439",
    "label": "Wugi",
    "value": "Wugi"
  },
  {
    "id": "9114392002",
    "district_id": "911439",
    "label": "Loma",
    "value": "Loma"
  },
  {
    "id": "9114392003",
    "district_id": "911439",
    "label": "Gitar",
    "value": "Gitar"
  },
  {
    "id": "9114392004",
    "district_id": "911439",
    "label": "Gilime",
    "value": "Gilime"
  },
  {
    "id": "9114392005",
    "district_id": "911439",
    "label": "Lena",
    "value": "Lena"
  },
  {
    "id": "9114392006",
    "district_id": "911439",
    "label": "Timoneri",
    "value": "Timoneri"
  },
  {
    "id": "9114392007",
    "district_id": "911439",
    "label": "Kuagembur",
    "value": "Kuagembur"
  },
  {
    "id": "9114392008",
    "district_id": "911439",
    "label": "Wuronggi",
    "value": "Wuronggi"
  },
  {
    "id": "9114392009",
    "district_id": "911439",
    "label": "Buangludah",
    "value": "Buangludah"
  },
  {
    "id": "9114392010",
    "district_id": "911439",
    "label": "Pindelo",
    "value": "Pindelo"
  },
  {
    "id": "9114392011",
    "district_id": "911439",
    "label": "Koli",
    "value": "Koli"
  },
  {
    "id": "9114402001",
    "district_id": "911440",
    "label": "Wania",
    "value": "Wania"
  },
  {
    "id": "9114402002",
    "district_id": "911440",
    "label": "Milipaa",
    "value": "Milipaa"
  },
  {
    "id": "9114402003",
    "district_id": "911440",
    "label": "Tarawi",
    "value": "Tarawi"
  },
  {
    "id": "9114402004",
    "district_id": "911440",
    "label": "Niagale",
    "value": "Niagale"
  },
  {
    "id": "9114402005",
    "district_id": "911440",
    "label": "Bumbu",
    "value": "Bumbu"
  },
  {
    "id": "9114402006",
    "district_id": "911440",
    "label": "Ambena",
    "value": "Ambena"
  },
  {
    "id": "9114402007",
    "district_id": "911440",
    "label": "Gunombo",
    "value": "Gunombo"
  },
  {
    "id": "9114402008",
    "district_id": "911440",
    "label": "Mawi",
    "value": "Mawi"
  },
  {
    "id": "9114402009",
    "district_id": "911440",
    "label": "Ripa",
    "value": "Ripa"
  },
  {
    "id": "9114402010",
    "district_id": "911440",
    "label": "Delegari",
    "value": "Delegari"
  },
  {
    "id": "9114412001",
    "district_id": "911441",
    "label": "Peyola",
    "value": "Peyola"
  },
  {
    "id": "9114412002",
    "district_id": "911441",
    "label": "Kogotime",
    "value": "Kogotime"
  },
  {
    "id": "9114412003",
    "district_id": "911441",
    "label": "Gabunam",
    "value": "Gabunam"
  },
  {
    "id": "9114412004",
    "district_id": "911441",
    "label": "Gulak",
    "value": "Gulak"
  },
  {
    "id": "9114412005",
    "district_id": "911441",
    "label": "Melaga",
    "value": "Melaga"
  },
  {
    "id": "9114412006",
    "district_id": "911441",
    "label": "Ekoni",
    "value": "Ekoni"
  },
  {
    "id": "9114412007",
    "district_id": "911441",
    "label": "Minggen",
    "value": "Minggen"
  },
  {
    "id": "9114412008",
    "district_id": "911441",
    "label": "Kandimbaga",
    "value": "Kandimbaga"
  },
  {
    "id": "9114412009",
    "district_id": "911441",
    "label": "Kinebe",
    "value": "Kinebe"
  },
  {
    "id": "9114412010",
    "district_id": "911441",
    "label": "Belela",
    "value": "Belela"
  },
  {
    "id": "9114422001",
    "district_id": "911442",
    "label": "Kaiga",
    "value": "Kaiga"
  },
  {
    "id": "9114422002",
    "district_id": "911442",
    "label": "Bawi",
    "value": "Bawi"
  },
  {
    "id": "9114422003",
    "district_id": "911442",
    "label": "Tina",
    "value": "Tina"
  },
  {
    "id": "9114422004",
    "district_id": "911442",
    "label": "Kurbaya",
    "value": "Kurbaya"
  },
  {
    "id": "9114422005",
    "district_id": "911442",
    "label": "Wiyangger",
    "value": "Wiyangger"
  },
  {
    "id": "9114422006",
    "district_id": "911442",
    "label": "Again",
    "value": "Again"
  },
  {
    "id": "9114422007",
    "district_id": "911442",
    "label": "Piraleme",
    "value": "Piraleme"
  },
  {
    "id": "9114422008",
    "district_id": "911442",
    "label": "Tunggunale",
    "value": "Tunggunale"
  },
  {
    "id": "9114422009",
    "district_id": "911442",
    "label": "Kotorambur",
    "value": "Kotorambur"
  },
  {
    "id": "9114422010",
    "district_id": "911442",
    "label": "Wolu",
    "value": "Wolu"
  },
  {
    "id": "9114432001",
    "district_id": "911443",
    "label": "Wuluk",
    "value": "Wuluk"
  },
  {
    "id": "9114432002",
    "district_id": "911443",
    "label": "Wenggun",
    "value": "Wenggun"
  },
  {
    "id": "9114432003",
    "district_id": "911443",
    "label": "Posman",
    "value": "Posman"
  },
  {
    "id": "9114432004",
    "district_id": "911443",
    "label": "Tiyonggi",
    "value": "Tiyonggi"
  },
  {
    "id": "9114432005",
    "district_id": "911443",
    "label": "Yelly",
    "value": "Yelly"
  },
  {
    "id": "9114432006",
    "district_id": "911443",
    "label": "Agin",
    "value": "Agin"
  },
  {
    "id": "9114432007",
    "district_id": "911443",
    "label": "Kogagi",
    "value": "Kogagi"
  },
  {
    "id": "9114432008",
    "district_id": "911443",
    "label": "Yebena",
    "value": "Yebena"
  },
  {
    "id": "9114432009",
    "district_id": "911443",
    "label": "Wamigi",
    "value": "Wamigi"
  },
  {
    "id": "9114432010",
    "district_id": "911443",
    "label": "Kolanggun",
    "value": "Kolanggun"
  },
  {
    "id": "9114442001",
    "district_id": "911444",
    "label": "Bogonuk",
    "value": "Bogonuk"
  },
  {
    "id": "9114442002",
    "district_id": "911444",
    "label": "Wisman",
    "value": "Wisman"
  },
  {
    "id": "9114442003",
    "district_id": "911444",
    "label": "Paba",
    "value": "Paba"
  },
  {
    "id": "9114442004",
    "district_id": "911444",
    "label": "Ewan",
    "value": "Ewan"
  },
  {
    "id": "9114442005",
    "district_id": "911444",
    "label": "Wumelak",
    "value": "Wumelak"
  },
  {
    "id": "9114442006",
    "district_id": "911444",
    "label": "Walelo",
    "value": "Walelo"
  },
  {
    "id": "9114442007",
    "district_id": "911444",
    "label": "Laura",
    "value": "Laura"
  },
  {
    "id": "9114442008",
    "district_id": "911444",
    "label": "Talinamber",
    "value": "Talinamber"
  },
  {
    "id": "9114442009",
    "district_id": "911444",
    "label": "Aliduda",
    "value": "Aliduda"
  },
  {
    "id": "9114442010",
    "district_id": "911444",
    "label": "Andomak",
    "value": "Andomak"
  },
  {
    "id": "9114452001",
    "district_id": "911445",
    "label": "Wiyaluk",
    "value": "Wiyaluk"
  },
  {
    "id": "9114452002",
    "district_id": "911445",
    "label": "Aburage",
    "value": "Aburage"
  },
  {
    "id": "9114452003",
    "district_id": "911445",
    "label": "Kogoyapura",
    "value": "Kogoyapura"
  },
  {
    "id": "9114452004",
    "district_id": "911445",
    "label": "Erimbur",
    "value": "Erimbur"
  },
  {
    "id": "9114452005",
    "district_id": "911445",
    "label": "Leragawi/Megagirakuk",
    "value": "Leragawi/Megagirakuk"
  },
  {
    "id": "9114452006",
    "district_id": "911445",
    "label": "Longgoboma",
    "value": "Longgoboma"
  },
  {
    "id": "9114452007",
    "district_id": "911445",
    "label": "Tingwi",
    "value": "Tingwi"
  },
  {
    "id": "9114452008",
    "district_id": "911445",
    "label": "Gubura",
    "value": "Gubura"
  },
  {
    "id": "9114452009",
    "district_id": "911445",
    "label": "Lubuk",
    "value": "Lubuk"
  },
  {
    "id": "9114452010",
    "district_id": "911445",
    "label": "Bogome",
    "value": "Bogome"
  },
  {
    "id": "9114462001",
    "district_id": "911446",
    "label": "Biuk",
    "value": "Biuk"
  },
  {
    "id": "9114462002",
    "district_id": "911446",
    "label": "Guburini",
    "value": "Guburini"
  },
  {
    "id": "9114462003",
    "district_id": "911446",
    "label": "Yiluk/Kondenggun",
    "value": "Yiluk/Kondenggun"
  },
  {
    "id": "9114462004",
    "district_id": "911446",
    "label": "Yugu Mabur",
    "value": "Yugu Mabur"
  },
  {
    "id": "9114462005",
    "district_id": "911446",
    "label": "Tomagipura",
    "value": "Tomagipura"
  },
  {
    "id": "9114462006",
    "district_id": "911446",
    "label": "Yiyogobak/Kibur",
    "value": "Yiyogobak/Kibur"
  },
  {
    "id": "9114462007",
    "district_id": "911446",
    "label": "Galubup",
    "value": "Galubup"
  },
  {
    "id": "9114462008",
    "district_id": "911446",
    "label": "Mbinime/Jinulira",
    "value": "Mbinime/Jinulira"
  },
  {
    "id": "9114462009",
    "district_id": "911446",
    "label": "Wonabu",
    "value": "Wonabu"
  },
  {
    "id": "9114462010",
    "district_id": "911446",
    "label": "Tomagi/Gubagi",
    "value": "Tomagi/Gubagi"
  },
  {
    "id": "9114462011",
    "district_id": "911446",
    "label": "Purugi",
    "value": "Purugi"
  },
  {
    "id": "9114472001",
    "district_id": "911447",
    "label": "Pekaleme",
    "value": "Pekaleme"
  },
  {
    "id": "9114472002",
    "district_id": "911447",
    "label": "Teleme",
    "value": "Teleme"
  },
  {
    "id": "9114472003",
    "district_id": "911447",
    "label": "Giko",
    "value": "Giko"
  },
  {
    "id": "9114472004",
    "district_id": "911447",
    "label": "Kungge",
    "value": "Kungge"
  },
  {
    "id": "9114472005",
    "district_id": "911447",
    "label": "Kotorambur",
    "value": "Kotorambur"
  },
  {
    "id": "9114472006",
    "district_id": "911447",
    "label": "Miyanagame",
    "value": "Miyanagame"
  },
  {
    "id": "9114472007",
    "district_id": "911447",
    "label": "Karu",
    "value": "Karu"
  },
  {
    "id": "9114472008",
    "district_id": "911447",
    "label": "Tabuh",
    "value": "Tabuh"
  },
  {
    "id": "9114472009",
    "district_id": "911447",
    "label": "Gwak Dugunik",
    "value": "Gwak Dugunik"
  },
  {
    "id": "9114472010",
    "district_id": "911447",
    "label": "Minegimen",
    "value": "Minegimen"
  },
  {
    "id": "9114472011",
    "district_id": "911447",
    "label": "Ambirik",
    "value": "Ambirik"
  },
  {
    "id": "9115012003",
    "district_id": "911501",
    "label": "Waren I",
    "value": "Waren I"
  },
  {
    "id": "9115012004",
    "district_id": "911501",
    "label": "Waren II",
    "value": "Waren II"
  },
  {
    "id": "9115012011",
    "district_id": "911501",
    "label": "Nonomi",
    "value": "Nonomi"
  },
  {
    "id": "9115012020",
    "district_id": "911501",
    "label": "Sarafambai",
    "value": "Sarafambai"
  },
  {
    "id": "9115012025",
    "district_id": "911501",
    "label": "Uri",
    "value": "Uri"
  },
  {
    "id": "9115012027",
    "district_id": "911501",
    "label": "Batu Zaman",
    "value": "Batu Zaman"
  },
  {
    "id": "9115012029",
    "district_id": "911501",
    "label": "Sawara Jaya",
    "value": "Sawara Jaya"
  },
  {
    "id": "9115032006",
    "district_id": "911503",
    "label": "Saurisirami",
    "value": "Saurisirami"
  },
  {
    "id": "9115032009",
    "district_id": "911503",
    "label": "Sinonde",
    "value": "Sinonde"
  },
  {
    "id": "9115032013",
    "district_id": "911503",
    "label": "Kali Baru",
    "value": "Kali Baru"
  },
  {
    "id": "9115032014",
    "district_id": "911503",
    "label": "Sirami",
    "value": "Sirami"
  },
  {
    "id": "9115032015",
    "district_id": "911503",
    "label": "Bunggu",
    "value": "Bunggu"
  },
  {
    "id": "9115032016",
    "district_id": "911503",
    "label": "Kowogi",
    "value": "Kowogi"
  },
  {
    "id": "9115032017",
    "district_id": "911503",
    "label": "Emauri",
    "value": "Emauri"
  },
  {
    "id": "9115032018",
    "district_id": "911503",
    "label": "Obutay",
    "value": "Obutay"
  },
  {
    "id": "9115032019",
    "district_id": "911503",
    "label": "Wairo",
    "value": "Wairo"
  },
  {
    "id": "9115072002",
    "district_id": "911507",
    "label": "Fafado",
    "value": "Fafado"
  },
  {
    "id": "9115072003",
    "district_id": "911507",
    "label": "Wobari",
    "value": "Wobari"
  },
  {
    "id": "9115072013",
    "district_id": "911507",
    "label": "Aimasi",
    "value": "Aimasi"
  },
  {
    "id": "9115072014",
    "district_id": "911507",
    "label": "Mui",
    "value": "Mui"
  },
  {
    "id": "9115072015",
    "district_id": "911507",
    "label": "Waghare",
    "value": "Waghare"
  },
  {
    "id": "9115072016",
    "district_id": "911507",
    "label": "Ghaiwando",
    "value": "Ghaiwando"
  },
  {
    "id": "9115072017",
    "district_id": "911507",
    "label": "Womorisi",
    "value": "Womorisi"
  },
  {
    "id": "9115072018",
    "district_id": "911507",
    "label": "Orambin",
    "value": "Orambin"
  },
  {
    "id": "9115072019",
    "district_id": "911507",
    "label": "Unareu",
    "value": "Unareu"
  },
  {
    "id": "9115072020",
    "district_id": "911507",
    "label": "Ghaiwaru",
    "value": "Ghaiwaru"
  },
  {
    "id": "9115082001",
    "district_id": "911508",
    "label": "Ureifaisei I/Sanggae",
    "value": "Ureifaisei I/Sanggae"
  },
  {
    "id": "9115082002",
    "district_id": "911508",
    "label": "Urfas II",
    "value": "Urfas II"
  },
  {
    "id": "9115082003",
    "district_id": "911508",
    "label": "Nubuai",
    "value": "Nubuai"
  },
  {
    "id": "9115082004",
    "district_id": "911508",
    "label": "Ureifaisei III/ Paradoi",
    "value": "Ureifaisei III/ Paradoi"
  },
  {
    "id": "9115082005",
    "district_id": "911508",
    "label": "Apainabo",
    "value": "Apainabo"
  },
  {
    "id": "9115082006",
    "district_id": "911508",
    "label": "Mambui",
    "value": "Mambui"
  },
  {
    "id": "9115082007",
    "district_id": "911508",
    "label": "Khemoon Jaya",
    "value": "Khemoon Jaya"
  },
  {
    "id": "9115082008",
    "district_id": "911508",
    "label": "Usaiwa",
    "value": "Usaiwa"
  },
  {
    "id": "9115082009",
    "district_id": "911508",
    "label": "Paradoi",
    "value": "Paradoi"
  },
  {
    "id": "9115082010",
    "district_id": "911508",
    "label": "Ghoyui",
    "value": "Ghoyui"
  },
  {
    "id": "9115082011",
    "district_id": "911508",
    "label": "Rorisi",
    "value": "Rorisi"
  },
  {
    "id": "9115082012",
    "district_id": "911508",
    "label": "Ronggaiwa",
    "value": "Ronggaiwa"
  },
  {
    "id": "9115092002",
    "district_id": "911509",
    "label": "Somiangga",
    "value": "Somiangga"
  },
  {
    "id": "9115092004",
    "district_id": "911509",
    "label": "Toire",
    "value": "Toire"
  },
  {
    "id": "9115092005",
    "district_id": "911509",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "9115092006",
    "district_id": "911509",
    "label": "Demisa",
    "value": "Demisa"
  },
  {
    "id": "9115092008",
    "district_id": "911509",
    "label": "Otodemo",
    "value": "Otodemo"
  },
  {
    "id": "9115092011",
    "district_id": "911509",
    "label": "Awaso",
    "value": "Awaso"
  },
  {
    "id": "9115092012",
    "district_id": "911509",
    "label": "Firo",
    "value": "Firo"
  },
  {
    "id": "9115102001",
    "district_id": "911510",
    "label": "Dairi",
    "value": "Dairi"
  },
  {
    "id": "9115102002",
    "district_id": "911510",
    "label": "Spoiri",
    "value": "Spoiri"
  },
  {
    "id": "9115102003",
    "district_id": "911510",
    "label": "Sooh",
    "value": "Sooh"
  },
  {
    "id": "9115102004",
    "district_id": "911510",
    "label": "Dirou",
    "value": "Dirou"
  },
  {
    "id": "9115102005",
    "district_id": "911510",
    "label": "Diwah",
    "value": "Diwah"
  },
  {
    "id": "9115102006",
    "district_id": "911510",
    "label": "Kuaisa",
    "value": "Kuaisa"
  },
  {
    "id": "9115102007",
    "district_id": "911510",
    "label": "Aru Antu",
    "value": "Aru Antu"
  },
  {
    "id": "9115102008",
    "district_id": "911510",
    "label": "Foira",
    "value": "Foira"
  },
  {
    "id": "9115102009",
    "district_id": "911510",
    "label": "Kawari",
    "value": "Kawari"
  },
  {
    "id": "9115102010",
    "district_id": "911510",
    "label": "Wafuka",
    "value": "Wafuka"
  },
  {
    "id": "9115112001",
    "district_id": "911511",
    "label": "Botawa",
    "value": "Botawa"
  },
  {
    "id": "9115112002",
    "district_id": "911511",
    "label": "Sowiwa",
    "value": "Sowiwa"
  },
  {
    "id": "9115112003",
    "district_id": "911511",
    "label": "Baino Jaya",
    "value": "Baino Jaya"
  },
  {
    "id": "9115112004",
    "district_id": "911511",
    "label": "Nau",
    "value": "Nau"
  },
  {
    "id": "9115112005",
    "district_id": "911511",
    "label": "Rasawa",
    "value": "Rasawa"
  },
  {
    "id": "9115112006",
    "district_id": "911511",
    "label": "Ruambak Jaya",
    "value": "Ruambak Jaya"
  },
  {
    "id": "9115112007",
    "district_id": "911511",
    "label": "Moroa",
    "value": "Moroa"
  },
  {
    "id": "9115112008",
    "district_id": "911511",
    "label": "Oiboa",
    "value": "Oiboa"
  },
  {
    "id": "9115122001",
    "district_id": "911512",
    "label": "Wapoga",
    "value": "Wapoga"
  },
  {
    "id": "9115122002",
    "district_id": "911512",
    "label": "Pirare",
    "value": "Pirare"
  },
  {
    "id": "9115122003",
    "district_id": "911512",
    "label": "Awera",
    "value": "Awera"
  },
  {
    "id": "9115122004",
    "district_id": "911512",
    "label": "Syewa Merare",
    "value": "Syewa Merare"
  },
  {
    "id": "9115122005",
    "district_id": "911512",
    "label": "Kamarisano",
    "value": "Kamarisano"
  },
  {
    "id": "9115122006",
    "district_id": "911512",
    "label": "Waweri",
    "value": "Waweri"
  },
  {
    "id": "9115122007",
    "district_id": "911512",
    "label": "Dokis",
    "value": "Dokis"
  },
  {
    "id": "9115132001",
    "district_id": "911513",
    "label": "Urato",
    "value": "Urato"
  },
  {
    "id": "9115132002",
    "district_id": "911513",
    "label": "Mayaghaido",
    "value": "Mayaghaido"
  },
  {
    "id": "9115132003",
    "district_id": "911513",
    "label": "Aniboi",
    "value": "Aniboi"
  },
  {
    "id": "9115132007",
    "district_id": "911513",
    "label": "Tetui",
    "value": "Tetui"
  },
  {
    "id": "9115132008",
    "district_id": "911513",
    "label": "Demba",
    "value": "Demba"
  },
  {
    "id": "9115132009",
    "district_id": "911513",
    "label": "Fafai",
    "value": "Fafai"
  },
  {
    "id": "9115132017",
    "district_id": "911513",
    "label": "Wudokuri",
    "value": "Wudokuri"
  },
  {
    "id": "9115132018",
    "district_id": "911513",
    "label": "Aiwa",
    "value": "Aiwa"
  },
  {
    "id": "9115132019",
    "district_id": "911513",
    "label": "Biati",
    "value": "Biati"
  },
  {
    "id": "9115132020",
    "district_id": "911513",
    "label": "Ronarai",
    "value": "Ronarai"
  },
  {
    "id": "9115142001",
    "district_id": "911514",
    "label": "Odase",
    "value": "Odase"
  },
  {
    "id": "9115142002",
    "district_id": "911514",
    "label": "Wanda",
    "value": "Wanda"
  },
  {
    "id": "9115142003",
    "district_id": "911514",
    "label": "Bakadaro",
    "value": "Bakadaro"
  },
  {
    "id": "9115142004",
    "district_id": "911514",
    "label": "Moyufuri",
    "value": "Moyufuri"
  },
  {
    "id": "9115142005",
    "district_id": "911514",
    "label": "Wonti Kai",
    "value": "Wonti Kai"
  },
  {
    "id": "9115142006",
    "district_id": "911514",
    "label": "Ghoiwi Samberi",
    "value": "Ghoiwi Samberi"
  },
  {
    "id": "9115142007",
    "district_id": "911514",
    "label": "Gheroi",
    "value": "Gheroi"
  },
  {
    "id": "9115142008",
    "district_id": "911514",
    "label": "Fimore",
    "value": "Fimore"
  },
  {
    "id": "9115142009",
    "district_id": "911514",
    "label": "Sifuisera",
    "value": "Sifuisera"
  },
  {
    "id": "9115142010",
    "district_id": "911514",
    "label": "Borumei",
    "value": "Borumei"
  },
  {
    "id": "9115152001",
    "district_id": "911515",
    "label": "Mambai",
    "value": "Mambai"
  },
  {
    "id": "9115152002",
    "district_id": "911515",
    "label": "Soyoi",
    "value": "Soyoi"
  },
  {
    "id": "9115152003",
    "district_id": "911515",
    "label": "Daimboa",
    "value": "Daimboa"
  },
  {
    "id": "9115152004",
    "district_id": "911515",
    "label": "Napani",
    "value": "Napani"
  },
  {
    "id": "9115152005",
    "district_id": "911515",
    "label": "Segha",
    "value": "Segha"
  },
  {
    "id": "9115152006",
    "district_id": "911515",
    "label": "Wainarisi",
    "value": "Wainarisi"
  },
  {
    "id": "9115152007",
    "district_id": "911515",
    "label": "Fafarui",
    "value": "Fafarui"
  },
  {
    "id": "9115152008",
    "district_id": "911515",
    "label": "Dawoa",
    "value": "Dawoa"
  },
  {
    "id": "9115152009",
    "district_id": "911515",
    "label": "Sinabo",
    "value": "Sinabo"
  },
  {
    "id": "9115152010",
    "district_id": "911515",
    "label": "Woinui",
    "value": "Woinui"
  },
  {
    "id": "9116012003",
    "district_id": "911601",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "9116012004",
    "district_id": "911601",
    "label": "Sokanggo",
    "value": "Sokanggo"
  },
  {
    "id": "9116012005",
    "district_id": "911601",
    "label": "Persatuan",
    "value": "Persatuan"
  },
  {
    "id": "9116012011",
    "district_id": "911601",
    "label": "Mariam",
    "value": "Mariam"
  },
  {
    "id": "9116012012",
    "district_id": "911601",
    "label": "Mawan",
    "value": "Mawan"
  },
  {
    "id": "9116022001",
    "district_id": "911602",
    "label": "Mindiptana",
    "value": "Mindiptana"
  },
  {
    "id": "9116022003",
    "district_id": "911602",
    "label": "Umap",
    "value": "Umap"
  },
  {
    "id": "9116022005",
    "district_id": "911602",
    "label": "Awayanka",
    "value": "Awayanka"
  },
  {
    "id": "9116022009",
    "district_id": "911602",
    "label": "Wanggatkibi",
    "value": "Wanggatkibi"
  },
  {
    "id": "9116022012",
    "district_id": "911602",
    "label": "Epsembit",
    "value": "Epsembit"
  },
  {
    "id": "9116022013",
    "district_id": "911602",
    "label": "Andobit",
    "value": "Andobit"
  },
  {
    "id": "9116022014",
    "district_id": "911602",
    "label": "Niyimbang",
    "value": "Niyimbang"
  },
  {
    "id": "9116022015",
    "district_id": "911602",
    "label": "Anggumbit",
    "value": "Anggumbit"
  },
  {
    "id": "9116022016",
    "district_id": "911602",
    "label": "Imko",
    "value": "Imko"
  },
  {
    "id": "9116022017",
    "district_id": "911602",
    "label": "Kamka",
    "value": "Kamka"
  },
  {
    "id": "9116022018",
    "district_id": "911602",
    "label": "Osso",
    "value": "Osso"
  },
  {
    "id": "9116022019",
    "district_id": "911602",
    "label": "Tinggam",
    "value": "Tinggam"
  },
  {
    "id": "9116022023",
    "district_id": "911602",
    "label": "Kakuna",
    "value": "Kakuna"
  },
  {
    "id": "9116032001",
    "district_id": "911603",
    "label": "Woropko",
    "value": "Woropko"
  },
  {
    "id": "9116032002",
    "district_id": "911603",
    "label": "Winiktit",
    "value": "Winiktit"
  },
  {
    "id": "9116032003",
    "district_id": "911603",
    "label": "Kanggewot",
    "value": "Kanggewot"
  },
  {
    "id": "9116032004",
    "district_id": "911603",
    "label": "Jetetkun",
    "value": "Jetetkun"
  },
  {
    "id": "9116032005",
    "district_id": "911603",
    "label": "Inggembit",
    "value": "Inggembit"
  },
  {
    "id": "9116032006",
    "district_id": "911603",
    "label": "Wametkapa",
    "value": "Wametkapa"
  },
  {
    "id": "9116032009",
    "district_id": "911603",
    "label": "Wombon",
    "value": "Wombon"
  },
  {
    "id": "9116032013",
    "district_id": "911603",
    "label": "Upkim",
    "value": "Upkim"
  },
  {
    "id": "9116032014",
    "district_id": "911603",
    "label": "Ikcan",
    "value": "Ikcan"
  },
  {
    "id": "9116042001",
    "district_id": "911604",
    "label": "Kouh",
    "value": "Kouh"
  },
  {
    "id": "9116042007",
    "district_id": "911604",
    "label": "Jair",
    "value": "Jair"
  },
  {
    "id": "9116042008",
    "district_id": "911604",
    "label": "Mandobo",
    "value": "Mandobo"
  },
  {
    "id": "9116052006",
    "district_id": "911605",
    "label": "Getentiri",
    "value": "Getentiri"
  },
  {
    "id": "9116052008",
    "district_id": "911605",
    "label": "Anggai",
    "value": "Anggai"
  },
  {
    "id": "9116052009",
    "district_id": "911605",
    "label": "Butiptiri",
    "value": "Butiptiri"
  },
  {
    "id": "9116052010",
    "district_id": "911605",
    "label": "Asiki",
    "value": "Asiki"
  },
  {
    "id": "9116052013",
    "district_id": "911605",
    "label": "Kapogu",
    "value": "Kapogu"
  },
  {
    "id": "9116052014",
    "district_id": "911605",
    "label": "Miri",
    "value": "Miri"
  },
  {
    "id": "9116062001",
    "district_id": "911606",
    "label": "Bomakia I",
    "value": "Bomakia I"
  },
  {
    "id": "9116062002",
    "district_id": "911606",
    "label": "Bomakia II",
    "value": "Bomakia II"
  },
  {
    "id": "9116062003",
    "district_id": "911606",
    "label": "Somi",
    "value": "Somi"
  },
  {
    "id": "9116062004",
    "district_id": "911606",
    "label": "Aifa",
    "value": "Aifa"
  },
  {
    "id": "9116062005",
    "district_id": "911606",
    "label": "Uni",
    "value": "Uni"
  },
  {
    "id": "9116072001",
    "district_id": "911607",
    "label": "Kombut",
    "value": "Kombut"
  },
  {
    "id": "9116072002",
    "district_id": "911607",
    "label": "Moukbiran",
    "value": "Moukbiran"
  },
  {
    "id": "9116072003",
    "district_id": "911607",
    "label": "Kawangtet",
    "value": "Kawangtet"
  },
  {
    "id": "9116072004",
    "district_id": "911607",
    "label": "Amuan",
    "value": "Amuan"
  },
  {
    "id": "9116082001",
    "district_id": "911608",
    "label": "Langgoan",
    "value": "Langgoan"
  },
  {
    "id": "9116082002",
    "district_id": "911608",
    "label": "Tetop",
    "value": "Tetop"
  },
  {
    "id": "9116082003",
    "district_id": "911608",
    "label": "Wariktop",
    "value": "Wariktop"
  },
  {
    "id": "9116082004",
    "district_id": "911608",
    "label": "Autriop",
    "value": "Autriop"
  },
  {
    "id": "9116082005",
    "district_id": "911608",
    "label": "Ogenatan",
    "value": "Ogenatan"
  },
  {
    "id": "9116092001",
    "district_id": "911609",
    "label": "Maju",
    "value": "Maju"
  },
  {
    "id": "9116092002",
    "district_id": "911609",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "9116092003",
    "district_id": "911609",
    "label": "Ujung",
    "value": "Ujung"
  },
  {
    "id": "9116092004",
    "district_id": "911609",
    "label": "Ginggimop",
    "value": "Ginggimop"
  },
  {
    "id": "9116092005",
    "district_id": "911609",
    "label": "Patriot",
    "value": "Patriot"
  },
  {
    "id": "9116092006",
    "district_id": "911609",
    "label": "Arimbet",
    "value": "Arimbet"
  },
  {
    "id": "9116092007",
    "district_id": "911609",
    "label": "Aroa",
    "value": "Aroa"
  },
  {
    "id": "9116102001",
    "district_id": "911610",
    "label": "Sadar",
    "value": "Sadar"
  },
  {
    "id": "9116102002",
    "district_id": "911610",
    "label": "Bangun",
    "value": "Bangun"
  },
  {
    "id": "9116102003",
    "district_id": "911610",
    "label": "Makmur",
    "value": "Makmur"
  },
  {
    "id": "9116102004",
    "district_id": "911610",
    "label": "Sohokanggo",
    "value": "Sohokanggo"
  },
  {
    "id": "9116102005",
    "district_id": "911610",
    "label": "Domo",
    "value": "Domo"
  },
  {
    "id": "9116102006",
    "district_id": "911610",
    "label": "Hamkhu",
    "value": "Hamkhu"
  },
  {
    "id": "9116102007",
    "district_id": "911610",
    "label": "Hello",
    "value": "Hello"
  },
  {
    "id": "9116102008",
    "district_id": "911610",
    "label": "Navini",
    "value": "Navini"
  },
  {
    "id": "9116112001",
    "district_id": "911611",
    "label": "Kuken",
    "value": "Kuken"
  },
  {
    "id": "9116112002",
    "district_id": "911611",
    "label": "Kolopkam",
    "value": "Kolopkam"
  },
  {
    "id": "9116112003",
    "district_id": "911611",
    "label": "Awaken",
    "value": "Awaken"
  },
  {
    "id": "9116112004",
    "district_id": "911611",
    "label": "Arimbit",
    "value": "Arimbit"
  },
  {
    "id": "9116112005",
    "district_id": "911611",
    "label": "Anyumka",
    "value": "Anyumka"
  },
  {
    "id": "9116122001",
    "district_id": "911612",
    "label": "Manggelum",
    "value": "Manggelum"
  },
  {
    "id": "9116122002",
    "district_id": "911612",
    "label": "Burunggop",
    "value": "Burunggop"
  },
  {
    "id": "9116122003",
    "district_id": "911612",
    "label": "Bayanggop",
    "value": "Bayanggop"
  },
  {
    "id": "9116122004",
    "district_id": "911612",
    "label": "Gaguop",
    "value": "Gaguop"
  },
  {
    "id": "9116122005",
    "district_id": "911612",
    "label": "Kewam",
    "value": "Kewam"
  },
  {
    "id": "9116122006",
    "district_id": "911612",
    "label": "Mangga Tiga",
    "value": "Mangga Tiga"
  },
  {
    "id": "9116132001",
    "district_id": "911613",
    "label": "Firiwage",
    "value": "Firiwage"
  },
  {
    "id": "9116132002",
    "district_id": "911613",
    "label": "Karuwage",
    "value": "Karuwage"
  },
  {
    "id": "9116132005",
    "district_id": "911613",
    "label": "Waliburu",
    "value": "Waliburu"
  },
  {
    "id": "9116132006",
    "district_id": "911613",
    "label": "Kabuwage",
    "value": "Kabuwage"
  },
  {
    "id": "9116142001",
    "district_id": "911614",
    "label": "Yaniruma",
    "value": "Yaniruma"
  },
  {
    "id": "9116142003",
    "district_id": "911614",
    "label": "Manggemahe",
    "value": "Manggemahe"
  },
  {
    "id": "9116142004",
    "district_id": "911614",
    "label": "Fefero",
    "value": "Fefero"
  },
  {
    "id": "9116152001",
    "district_id": "911615",
    "label": "Subur",
    "value": "Subur"
  },
  {
    "id": "9116152002",
    "district_id": "911615",
    "label": "Kaisa",
    "value": "Kaisa"
  },
  {
    "id": "9116152003",
    "district_id": "911615",
    "label": "Waghai",
    "value": "Waghai"
  },
  {
    "id": "9116152004",
    "district_id": "911615",
    "label": "Aiwat",
    "value": "Aiwat"
  },
  {
    "id": "9116162001",
    "district_id": "911616",
    "label": "Wanggemalo",
    "value": "Wanggemalo"
  },
  {
    "id": "9116162002",
    "district_id": "911616",
    "label": "Sinimburu",
    "value": "Sinimburu"
  },
  {
    "id": "9116162003",
    "district_id": "911616",
    "label": "Yafufla",
    "value": "Yafufla"
  },
  {
    "id": "9116162004",
    "district_id": "911616",
    "label": "Ugo",
    "value": "Ugo"
  },
  {
    "id": "9116162005",
    "district_id": "911616",
    "label": "Dema",
    "value": "Dema"
  },
  {
    "id": "9116172001",
    "district_id": "911617",
    "label": "Ninati",
    "value": "Ninati"
  },
  {
    "id": "9116172002",
    "district_id": "911617",
    "label": "Upyetetko",
    "value": "Upyetetko"
  },
  {
    "id": "9116172003",
    "district_id": "911617",
    "label": "Kawaktembut",
    "value": "Kawaktembut"
  },
  {
    "id": "9116172004",
    "district_id": "911617",
    "label": "Tembutka",
    "value": "Tembutka"
  },
  {
    "id": "9116172005",
    "district_id": "911617",
    "label": "Timka",
    "value": "Timka"
  },
  {
    "id": "9116182001",
    "district_id": "911618",
    "label": "Sesnuk",
    "value": "Sesnuk"
  },
  {
    "id": "9116182002",
    "district_id": "911618",
    "label": "Anggamburan",
    "value": "Anggamburan"
  },
  {
    "id": "9116182003",
    "district_id": "911618",
    "label": "Kanggup",
    "value": "Kanggup"
  },
  {
    "id": "9116182004",
    "district_id": "911618",
    "label": "Yomkondo",
    "value": "Yomkondo"
  },
  {
    "id": "9116182005",
    "district_id": "911618",
    "label": "Amboran",
    "value": "Amboran"
  },
  {
    "id": "9116192001",
    "district_id": "911619",
    "label": "Metto",
    "value": "Metto"
  },
  {
    "id": "9116192002",
    "district_id": "911619",
    "label": "Obinangge",
    "value": "Obinangge"
  },
  {
    "id": "9116192003",
    "district_id": "911619",
    "label": "Watemu",
    "value": "Watemu"
  },
  {
    "id": "9116192004",
    "district_id": "911619",
    "label": "Ujungkia",
    "value": "Ujungkia"
  },
  {
    "id": "9116202001",
    "district_id": "911620",
    "label": "Wanggom",
    "value": "Wanggom"
  },
  {
    "id": "9116202002",
    "district_id": "911620",
    "label": "Kawagit",
    "value": "Kawagit"
  },
  {
    "id": "9116202003",
    "district_id": "911620",
    "label": "Kombai",
    "value": "Kombai"
  },
  {
    "id": "9116202004",
    "district_id": "911620",
    "label": "Biwage",
    "value": "Biwage"
  },
  {
    "id": "9116202005",
    "district_id": "911620",
    "label": "Biwage Dua",
    "value": "Biwage Dua"
  },
  {
    "id": "9116202006",
    "district_id": "911620",
    "label": "Niop",
    "value": "Niop"
  },
  {
    "id": "9117011022",
    "district_id": "911701",
    "label": "Kepi",
    "value": "Kepi"
  },
  {
    "id": "9117012001",
    "district_id": "911701",
    "label": "Obaa",
    "value": "Obaa"
  },
  {
    "id": "9117012002",
    "district_id": "911701",
    "label": "Dagimon",
    "value": "Dagimon"
  },
  {
    "id": "9117012003",
    "district_id": "911701",
    "label": "Rep",
    "value": "Rep"
  },
  {
    "id": "9117012004",
    "district_id": "911701",
    "label": "Mappi Bawah/Yamui",
    "value": "Mappi Bawah/Yamui"
  },
  {
    "id": "9117012005",
    "district_id": "911701",
    "label": "Wanggate",
    "value": "Wanggate"
  },
  {
    "id": "9117012009",
    "district_id": "911701",
    "label": "Piai",
    "value": "Piai"
  },
  {
    "id": "9117012010",
    "district_id": "911701",
    "label": "Muin",
    "value": "Muin"
  },
  {
    "id": "9117012011",
    "district_id": "911701",
    "label": "Gauda",
    "value": "Gauda"
  },
  {
    "id": "9117012012",
    "district_id": "911701",
    "label": "Soba",
    "value": "Soba"
  },
  {
    "id": "9117012013",
    "district_id": "911701",
    "label": "Enem",
    "value": "Enem"
  },
  {
    "id": "9117012014",
    "district_id": "911701",
    "label": "Kogo",
    "value": "Kogo"
  },
  {
    "id": "9117012015",
    "district_id": "911701",
    "label": "Kadam/Oyim",
    "value": "Kadam/Oyim"
  },
  {
    "id": "9117012023",
    "district_id": "911701",
    "label": "Tokhom",
    "value": "Tokhom"
  },
  {
    "id": "9117012024",
    "district_id": "911701",
    "label": "Emete",
    "value": "Emete"
  },
  {
    "id": "9117012025",
    "district_id": "911701",
    "label": "Wairu",
    "value": "Wairu"
  },
  {
    "id": "9117012026",
    "district_id": "911701",
    "label": "Rayam",
    "value": "Rayam"
  },
  {
    "id": "9117012028",
    "district_id": "911701",
    "label": "Harome",
    "value": "Harome"
  },
  {
    "id": "9117012029",
    "district_id": "911701",
    "label": "Metim",
    "value": "Metim"
  },
  {
    "id": "9117012030",
    "district_id": "911701",
    "label": "Masin",
    "value": "Masin"
  },
  {
    "id": "9117012031",
    "district_id": "911701",
    "label": "Madu",
    "value": "Madu"
  },
  {
    "id": "9117012032",
    "district_id": "911701",
    "label": "Yangpop",
    "value": "Yangpop"
  },
  {
    "id": "9117012033",
    "district_id": "911701",
    "label": "Paedam",
    "value": "Paedam"
  },
  {
    "id": "9117012034",
    "district_id": "911701",
    "label": "Marbin",
    "value": "Marbin"
  },
  {
    "id": "9117022001",
    "district_id": "911702",
    "label": "Mur",
    "value": "Mur"
  },
  {
    "id": "9117022003",
    "district_id": "911702",
    "label": "Agham",
    "value": "Agham"
  },
  {
    "id": "9117022004",
    "district_id": "911702",
    "label": "Waghien/Koba",
    "value": "Waghien/Koba"
  },
  {
    "id": "9117022005",
    "district_id": "911702",
    "label": "Khatan",
    "value": "Khatan"
  },
  {
    "id": "9117022008",
    "district_id": "911702",
    "label": "Monana",
    "value": "Monana"
  },
  {
    "id": "9117022009",
    "district_id": "911702",
    "label": "Wagin",
    "value": "Wagin"
  },
  {
    "id": "9117022013",
    "district_id": "911702",
    "label": "Waman",
    "value": "Waman"
  },
  {
    "id": "9117022014",
    "district_id": "911702",
    "label": "Ima",
    "value": "Ima"
  },
  {
    "id": "9117022015",
    "district_id": "911702",
    "label": "Kogoyaman",
    "value": "Kogoyaman"
  },
  {
    "id": "9117022016",
    "district_id": "911702",
    "label": "Magabag",
    "value": "Magabag"
  },
  {
    "id": "9117022017",
    "district_id": "911702",
    "label": "Tereyemu",
    "value": "Tereyemu"
  },
  {
    "id": "9117022018",
    "district_id": "911702",
    "label": "Yatan",
    "value": "Yatan"
  },
  {
    "id": "9117022019",
    "district_id": "911702",
    "label": "Linggua",
    "value": "Linggua"
  },
  {
    "id": "9117022024",
    "district_id": "911702",
    "label": "Waruwe",
    "value": "Waruwe"
  },
  {
    "id": "9117022025",
    "district_id": "911702",
    "label": "Ghanu",
    "value": "Ghanu"
  },
  {
    "id": "9117032003",
    "district_id": "911703",
    "label": "Bidnew",
    "value": "Bidnew"
  },
  {
    "id": "9117032004",
    "district_id": "911703",
    "label": "Senggo",
    "value": "Senggo"
  },
  {
    "id": "9117032005",
    "district_id": "911703",
    "label": "Kumasma",
    "value": "Kumasma"
  },
  {
    "id": "9117032012",
    "district_id": "911703",
    "label": "Womin",
    "value": "Womin"
  },
  {
    "id": "9117032013",
    "district_id": "911703",
    "label": "Tamanin",
    "value": "Tamanin"
  },
  {
    "id": "9117032014",
    "district_id": "911703",
    "label": "Epem",
    "value": "Epem"
  },
  {
    "id": "9117032015",
    "district_id": "911703",
    "label": "Abau",
    "value": "Abau"
  },
  {
    "id": "9117041001",
    "district_id": "911704",
    "label": "Bade",
    "value": "Bade"
  },
  {
    "id": "9117042002",
    "district_id": "911704",
    "label": "Geturki",
    "value": "Geturki"
  },
  {
    "id": "9117042012",
    "district_id": "911704",
    "label": "Mememu",
    "value": "Mememu"
  },
  {
    "id": "9117042013",
    "district_id": "911704",
    "label": "Gimikya",
    "value": "Gimikya"
  },
  {
    "id": "9117042014",
    "district_id": "911704",
    "label": "Banamepe",
    "value": "Banamepe"
  },
  {
    "id": "9117042015",
    "district_id": "911704",
    "label": "Yodom",
    "value": "Yodom"
  },
  {
    "id": "9117042016",
    "district_id": "911704",
    "label": "Isyaman",
    "value": "Isyaman"
  },
  {
    "id": "9117052001",
    "district_id": "911705",
    "label": "Yagatsu",
    "value": "Yagatsu"
  },
  {
    "id": "9117052002",
    "district_id": "911705",
    "label": "Amagatsu",
    "value": "Amagatsu"
  },
  {
    "id": "9117052003",
    "district_id": "911705",
    "label": "Katage",
    "value": "Katage"
  },
  {
    "id": "9117052004",
    "district_id": "911705",
    "label": "Sogope",
    "value": "Sogope"
  },
  {
    "id": "9117052005",
    "district_id": "911705",
    "label": "Wiyage",
    "value": "Wiyage"
  },
  {
    "id": "9117052006",
    "district_id": "911705",
    "label": "Kasima",
    "value": "Kasima"
  },
  {
    "id": "9117052007",
    "district_id": "911705",
    "label": "Amenda",
    "value": "Amenda"
  },
  {
    "id": "9117052008",
    "district_id": "911705",
    "label": "Kaibu",
    "value": "Kaibu"
  },
  {
    "id": "9117052009",
    "district_id": "911705",
    "label": "Omuro",
    "value": "Omuro"
  },
  {
    "id": "9117052010",
    "district_id": "911705",
    "label": "Okor",
    "value": "Okor"
  },
  {
    "id": "9117052011",
    "district_id": "911705",
    "label": "Atsipim",
    "value": "Atsipim"
  },
  {
    "id": "9117052012",
    "district_id": "911705",
    "label": "Semtaipim",
    "value": "Semtaipim"
  },
  {
    "id": "9117052013",
    "district_id": "911705",
    "label": "Geiripim",
    "value": "Geiripim"
  },
  {
    "id": "9117052014",
    "district_id": "911705",
    "label": "Warogom",
    "value": "Warogom"
  },
  {
    "id": "9117052015",
    "district_id": "911705",
    "label": "Kaibusene",
    "value": "Kaibusene"
  },
  {
    "id": "9117052016",
    "district_id": "911705",
    "label": "Arare",
    "value": "Arare"
  },
  {
    "id": "9117052017",
    "district_id": "911705",
    "label": "Paghai",
    "value": "Paghai"
  },
  {
    "id": "9117052018",
    "district_id": "911705",
    "label": "Kerke",
    "value": "Kerke"
  },
  {
    "id": "9117052019",
    "district_id": "911705",
    "label": "Mani",
    "value": "Mani"
  },
  {
    "id": "9117062001",
    "district_id": "911706",
    "label": "Sigare",
    "value": "Sigare"
  },
  {
    "id": "9117062002",
    "district_id": "911706",
    "label": "Jufo Besar",
    "value": "Jufo Besar"
  },
  {
    "id": "9117062003",
    "district_id": "911706",
    "label": "Eci",
    "value": "Eci"
  },
  {
    "id": "9117062004",
    "district_id": "911706",
    "label": "Aboge",
    "value": "Aboge"
  },
  {
    "id": "9117062005",
    "district_id": "911706",
    "label": "Khanami",
    "value": "Khanami"
  },
  {
    "id": "9117062006",
    "district_id": "911706",
    "label": "Yame",
    "value": "Yame"
  },
  {
    "id": "9117062007",
    "district_id": "911706",
    "label": "Kaitok",
    "value": "Kaitok"
  },
  {
    "id": "9117062008",
    "district_id": "911706",
    "label": "Homang",
    "value": "Homang"
  },
  {
    "id": "9117062009",
    "district_id": "911706",
    "label": "Jufo Kecil",
    "value": "Jufo Kecil"
  },
  {
    "id": "9117062010",
    "district_id": "911706",
    "label": "Khabu",
    "value": "Khabu"
  },
  {
    "id": "9117062011",
    "district_id": "911706",
    "label": "Kiki",
    "value": "Kiki"
  },
  {
    "id": "9117062012",
    "district_id": "911706",
    "label": "Isage",
    "value": "Isage"
  },
  {
    "id": "9117062013",
    "district_id": "911706",
    "label": "Kopi",
    "value": "Kopi"
  },
  {
    "id": "9117062014",
    "district_id": "911706",
    "label": "Asaren",
    "value": "Asaren"
  },
  {
    "id": "9117062015",
    "district_id": "911706",
    "label": "Keru",
    "value": "Keru"
  },
  {
    "id": "9117062016",
    "district_id": "911706",
    "label": "Girimio",
    "value": "Girimio"
  },
  {
    "id": "9117062017",
    "district_id": "911706",
    "label": "Haffo",
    "value": "Haffo"
  },
  {
    "id": "9117062018",
    "district_id": "911706",
    "label": "Meda",
    "value": "Meda"
  },
  {
    "id": "9117072001",
    "district_id": "911707",
    "label": "Amazu",
    "value": "Amazu"
  },
  {
    "id": "9117072002",
    "district_id": "911707",
    "label": "Imembi",
    "value": "Imembi"
  },
  {
    "id": "9117072003",
    "district_id": "911707",
    "label": "Yemu",
    "value": "Yemu"
  },
  {
    "id": "9117072005",
    "district_id": "911707",
    "label": "Fomu",
    "value": "Fomu"
  },
  {
    "id": "9117072006",
    "district_id": "911707",
    "label": "Tiau",
    "value": "Tiau"
  },
  {
    "id": "9117072007",
    "district_id": "911707",
    "label": "Binerbis",
    "value": "Binerbis"
  },
  {
    "id": "9117072012",
    "district_id": "911707",
    "label": "Tayau",
    "value": "Tayau"
  },
  {
    "id": "9117082001",
    "district_id": "911708",
    "label": "Sepoh",
    "value": "Sepoh"
  },
  {
    "id": "9117082002",
    "district_id": "911708",
    "label": "Bagaram",
    "value": "Bagaram"
  },
  {
    "id": "9117082003",
    "district_id": "911708",
    "label": "Passue/Taim",
    "value": "Passue/Taim"
  },
  {
    "id": "9117082004",
    "district_id": "911708",
    "label": "Nohon",
    "value": "Nohon"
  },
  {
    "id": "9117082005",
    "district_id": "911708",
    "label": "Kotiak",
    "value": "Kotiak"
  },
  {
    "id": "9117082006",
    "district_id": "911708",
    "label": "Menya",
    "value": "Menya"
  },
  {
    "id": "9117082007",
    "district_id": "911708",
    "label": "Tokhompatu",
    "value": "Tokhompatu"
  },
  {
    "id": "9117082008",
    "district_id": "911708",
    "label": "Kotup",
    "value": "Kotup"
  },
  {
    "id": "9117082009",
    "district_id": "911708",
    "label": "Rimba Jaya/Gayu",
    "value": "Rimba Jaya/Gayu"
  },
  {
    "id": "9117082010",
    "district_id": "911708",
    "label": "Kaime",
    "value": "Kaime"
  },
  {
    "id": "9117082011",
    "district_id": "911708",
    "label": "Baitate",
    "value": "Baitate"
  },
  {
    "id": "9117082012",
    "district_id": "911708",
    "label": "Urufu",
    "value": "Urufu"
  },
  {
    "id": "9117082013",
    "district_id": "911708",
    "label": "Afogoma",
    "value": "Afogoma"
  },
  {
    "id": "9117092001",
    "district_id": "911709",
    "label": "Kabe",
    "value": "Kabe"
  },
  {
    "id": "9117092002",
    "district_id": "911709",
    "label": "Kaumi",
    "value": "Kaumi"
  },
  {
    "id": "9117092003",
    "district_id": "911709",
    "label": "Kayagai",
    "value": "Kayagai"
  },
  {
    "id": "9117092004",
    "district_id": "911709",
    "label": "Ati",
    "value": "Ati"
  },
  {
    "id": "9117092005",
    "district_id": "911709",
    "label": "Upin",
    "value": "Upin"
  },
  {
    "id": "9117092006",
    "district_id": "911709",
    "label": "Taragai",
    "value": "Taragai"
  },
  {
    "id": "9117092007",
    "district_id": "911709",
    "label": "Sene",
    "value": "Sene"
  },
  {
    "id": "9117092008",
    "district_id": "911709",
    "label": "Komru",
    "value": "Komru"
  },
  {
    "id": "9117092009",
    "district_id": "911709",
    "label": "Kofar",
    "value": "Kofar"
  },
  {
    "id": "9117092010",
    "district_id": "911709",
    "label": "Sumuraman",
    "value": "Sumuraman"
  },
  {
    "id": "9117092011",
    "district_id": "911709",
    "label": "Pano",
    "value": "Pano"
  },
  {
    "id": "9117092012",
    "district_id": "911709",
    "label": "Kogir",
    "value": "Kogir"
  },
  {
    "id": "9117102006",
    "district_id": "911710",
    "label": "Sien",
    "value": "Sien"
  },
  {
    "id": "9117102007",
    "district_id": "911710",
    "label": "Sahapikya",
    "value": "Sahapikya"
  },
  {
    "id": "9117102008",
    "district_id": "911710",
    "label": "Harapan",
    "value": "Harapan"
  },
  {
    "id": "9117102009",
    "district_id": "911710",
    "label": "Yibin",
    "value": "Yibin"
  },
  {
    "id": "9117102010",
    "district_id": "911710",
    "label": "Mopio",
    "value": "Mopio"
  },
  {
    "id": "9117102011",
    "district_id": "911710",
    "label": "Pies",
    "value": "Pies"
  },
  {
    "id": "9117102012",
    "district_id": "911710",
    "label": "Memes",
    "value": "Memes"
  },
  {
    "id": "9117102015",
    "district_id": "911710",
    "label": "Getiyo",
    "value": "Getiyo"
  },
  {
    "id": "9117112001",
    "district_id": "911711",
    "label": "Homlikia",
    "value": "Homlikia"
  },
  {
    "id": "9117112002",
    "district_id": "911711",
    "label": "Kobeta",
    "value": "Kobeta"
  },
  {
    "id": "9117112003",
    "district_id": "911711",
    "label": "Asset",
    "value": "Asset"
  },
  {
    "id": "9117112004",
    "district_id": "911711",
    "label": "Osso",
    "value": "Osso"
  },
  {
    "id": "9117112005",
    "district_id": "911711",
    "label": "Bosma",
    "value": "Bosma"
  },
  {
    "id": "9117112006",
    "district_id": "911711",
    "label": "Oghoto",
    "value": "Oghoto"
  },
  {
    "id": "9117122001",
    "district_id": "911712",
    "label": "Amk",
    "value": "Amk"
  },
  {
    "id": "9117122002",
    "district_id": "911712",
    "label": "Yame",
    "value": "Yame"
  },
  {
    "id": "9117122003",
    "district_id": "911712",
    "label": "Benggo",
    "value": "Benggo"
  },
  {
    "id": "9117122004",
    "district_id": "911712",
    "label": "Kokoya",
    "value": "Kokoya"
  },
  {
    "id": "9117122005",
    "district_id": "911712",
    "label": "Borohaba",
    "value": "Borohaba"
  },
  {
    "id": "9117122006",
    "district_id": "911712",
    "label": "Ogorito",
    "value": "Ogorito"
  },
  {
    "id": "9117122007",
    "district_id": "911712",
    "label": "Hebeske",
    "value": "Hebeske"
  },
  {
    "id": "9117132001",
    "district_id": "911713",
    "label": "Yeloba",
    "value": "Yeloba"
  },
  {
    "id": "9117132002",
    "district_id": "911713",
    "label": "Konebi",
    "value": "Konebi"
  },
  {
    "id": "9117132003",
    "district_id": "911713",
    "label": "Sibi",
    "value": "Sibi"
  },
  {
    "id": "9117132004",
    "district_id": "911713",
    "label": "Tagaimon Sino",
    "value": "Tagaimon Sino"
  },
  {
    "id": "9117132005",
    "district_id": "911713",
    "label": "Tagaimon Karome",
    "value": "Tagaimon Karome"
  },
  {
    "id": "9117142001",
    "district_id": "911714",
    "label": "Wonggi",
    "value": "Wonggi"
  },
  {
    "id": "9117142002",
    "district_id": "911714",
    "label": "Busiri",
    "value": "Busiri"
  },
  {
    "id": "9117142003",
    "district_id": "911714",
    "label": "Keta",
    "value": "Keta"
  },
  {
    "id": "9117142004",
    "district_id": "911714",
    "label": "Veta",
    "value": "Veta"
  },
  {
    "id": "9117142005",
    "district_id": "911714",
    "label": "Honya",
    "value": "Honya"
  },
  {
    "id": "9117142006",
    "district_id": "911714",
    "label": "Haku",
    "value": "Haku"
  },
  {
    "id": "9117142007",
    "district_id": "911714",
    "label": "Bifo",
    "value": "Bifo"
  },
  {
    "id": "9117142008",
    "district_id": "911714",
    "label": "Samurukie",
    "value": "Samurukie"
  },
  {
    "id": "9117152001",
    "district_id": "911715",
    "label": "Sagis",
    "value": "Sagis"
  },
  {
    "id": "9117152002",
    "district_id": "911715",
    "label": "Kumaban",
    "value": "Kumaban"
  },
  {
    "id": "9117152003",
    "district_id": "911715",
    "label": "Tugumau",
    "value": "Tugumau"
  },
  {
    "id": "9117152004",
    "district_id": "911715",
    "label": "Basman",
    "value": "Basman"
  },
  {
    "id": "9117152005",
    "district_id": "911715",
    "label": "Merokima",
    "value": "Merokima"
  },
  { "id": "9117152006", "district_id": "911715", "label": "Mu", "value": "Mu" },
  {
    "id": "9117152007",
    "district_id": "911715",
    "label": "Pier",
    "value": "Pier"
  },
  {
    "id": "9117152008",
    "district_id": "911715",
    "label": "Tarwa",
    "value": "Tarwa"
  },
  {
    "id": "9118012001",
    "district_id": "911801",
    "label": "Yomoth",
    "value": "Yomoth"
  },
  {
    "id": "9118012002",
    "district_id": "911801",
    "label": "Uwus",
    "value": "Uwus"
  },
  {
    "id": "9118012004",
    "district_id": "911801",
    "label": "Asuwetsy",
    "value": "Asuwetsy"
  },
  {
    "id": "9118012005",
    "district_id": "911801",
    "label": "Bisman",
    "value": "Bisman"
  },
  {
    "id": "9118012006",
    "district_id": "911801",
    "label": "Bis Agats",
    "value": "Bis Agats"
  },
  {
    "id": "9118012007",
    "district_id": "911801",
    "label": "Per",
    "value": "Per"
  },
  {
    "id": "9118012008",
    "district_id": "911801",
    "label": "Briten",
    "value": "Briten"
  },
  {
    "id": "9118012010",
    "district_id": "911801",
    "label": "Mbait",
    "value": "Mbait"
  },
  {
    "id": "9118012011",
    "district_id": "911801",
    "label": "Bou",
    "value": "Bou"
  },
  {
    "id": "9118012012",
    "district_id": "911801",
    "label": "Saw",
    "value": "Saw"
  },
  {
    "id": "9118012013",
    "district_id": "911801",
    "label": "Kaye",
    "value": "Kaye"
  },
  {
    "id": "9118012014",
    "district_id": "911801",
    "label": "Suwru",
    "value": "Suwru"
  },
  {
    "id": "9118022001",
    "district_id": "911802",
    "label": "Atsy",
    "value": "Atsy"
  },
  {
    "id": "9118022006",
    "district_id": "911802",
    "label": "Sagoni",
    "value": "Sagoni"
  },
  {
    "id": "9118022007",
    "district_id": "911802",
    "label": "Amanamkai",
    "value": "Amanamkai"
  },
  {
    "id": "9118022013",
    "district_id": "911802",
    "label": "Ambisu",
    "value": "Ambisu"
  },
  {
    "id": "9118022016",
    "district_id": "911802",
    "label": "Bine",
    "value": "Bine"
  },
  {
    "id": "9118022017",
    "district_id": "911802",
    "label": "Bipin",
    "value": "Bipin"
  },
  {
    "id": "9118022018",
    "district_id": "911802",
    "label": "Yaisiu",
    "value": "Yaisiu"
  },
  {
    "id": "9118022023",
    "district_id": "911802",
    "label": "Bakasei",
    "value": "Bakasei"
  },
  {
    "id": "9118022026",
    "district_id": "911802",
    "label": "Cewewyamew",
    "value": "Cewewyamew"
  },
  {
    "id": "9118032005",
    "district_id": "911803",
    "label": "Sawa",
    "value": "Sawa"
  },
  {
    "id": "9118032006",
    "district_id": "911803",
    "label": "Erma",
    "value": "Erma"
  },
  { "id": "9118032009", "district_id": "911803", "label": "Bu", "value": "Bu" },
  { "id": "9118032018", "district_id": "911803", "label": "Er", "value": "Er" },
  {
    "id": "9118032019",
    "district_id": "911803",
    "label": "Pupis",
    "value": "Pupis"
  },
  {
    "id": "9118032020",
    "district_id": "911803",
    "label": "Sona",
    "value": "Sona"
  },
  {
    "id": "9118032026",
    "district_id": "911803",
    "label": "Agani",
    "value": "Agani"
  },
  {
    "id": "9118032027",
    "district_id": "911803",
    "label": "Mumugu",
    "value": "Mumugu"
  },
  {
    "id": "9118032038",
    "district_id": "911803",
    "label": "Sauti",
    "value": "Sauti"
  },
  {
    "id": "9118032043",
    "district_id": "911803",
    "label": "Mumugu Dua",
    "value": "Mumugu Dua"
  },
  {
    "id": "9118042001",
    "district_id": "911804",
    "label": "Ayam",
    "value": "Ayam"
  },
  {
    "id": "9118042004",
    "district_id": "911804",
    "label": "Menepsimni",
    "value": "Menepsimni"
  },
  {
    "id": "9118042005",
    "district_id": "911804",
    "label": "Beco",
    "value": "Beco"
  },
  {
    "id": "9118042006",
    "district_id": "911804",
    "label": "Yuni",
    "value": "Yuni"
  },
  {
    "id": "9118042007",
    "district_id": "911804",
    "label": "Buetkwar",
    "value": "Buetkwar"
  },
  {
    "id": "9118042010",
    "district_id": "911804",
    "label": "Waw",
    "value": "Waw"
  },
  {
    "id": "9118042011",
    "district_id": "911804",
    "label": "Bayiw Pinam",
    "value": "Bayiw Pinam"
  },
  {
    "id": "9118042012",
    "district_id": "911804",
    "label": "Cumnew",
    "value": "Cumnew"
  },
  {
    "id": "9118042013",
    "district_id": "911804",
    "label": "Jewes",
    "value": "Jewes"
  },
  {
    "id": "9118042016",
    "district_id": "911804",
    "label": "Fakan",
    "value": "Fakan"
  },
  {
    "id": "9118042017",
    "district_id": "911804",
    "label": "Simini",
    "value": "Simini"
  },
  {
    "id": "9118052001",
    "district_id": "911805",
    "label": "Basim",
    "value": "Basim"
  },
  {
    "id": "9118052002",
    "district_id": "911805",
    "label": "Waras",
    "value": "Waras"
  },
  {
    "id": "9118052003",
    "district_id": "911805",
    "label": "Biopis",
    "value": "Biopis"
  },
  {
    "id": "9118052004",
    "district_id": "911805",
    "label": "Nanai",
    "value": "Nanai"
  },
  {
    "id": "9118052007",
    "district_id": "911805",
    "label": "Bagair",
    "value": "Bagair"
  },
  {
    "id": "9118052008",
    "district_id": "911805",
    "label": "Bawos",
    "value": "Bawos"
  },
  {
    "id": "9118052009",
    "district_id": "911805",
    "label": "Piramat",
    "value": "Piramat"
  },
  {
    "id": "9118052010",
    "district_id": "911805",
    "label": "Tauro",
    "value": "Tauro"
  },
  {
    "id": "9118052011",
    "district_id": "911805",
    "label": "Pirien",
    "value": "Pirien"
  },
  {
    "id": "9118052012",
    "district_id": "911805",
    "label": "Acenep",
    "value": "Acenep"
  },
  {
    "id": "9118052013",
    "district_id": "911805",
    "label": "Kagas",
    "value": "Kagas"
  },
  {
    "id": "9118052014",
    "district_id": "911805",
    "label": "Wiyar",
    "value": "Wiyar"
  },
  {
    "id": "9118052015",
    "district_id": "911805",
    "label": "Ais",
    "value": "Ais"
  },
  {
    "id": "9118052016",
    "district_id": "911805",
    "label": "Yawas",
    "value": "Yawas"
  },
  {
    "id": "9118052017",
    "district_id": "911805",
    "label": "Ainamsato",
    "value": "Ainamsato"
  },
  {
    "id": "9118052018",
    "district_id": "911805",
    "label": "Bakyor",
    "value": "Bakyor"
  },
  {
    "id": "9118052019",
    "district_id": "911805",
    "label": "Isar",
    "value": "Isar"
  },
  {
    "id": "9118052020",
    "district_id": "911805",
    "label": "Kayarpis",
    "value": "Kayarpis"
  },
  {
    "id": "9118052021",
    "district_id": "911805",
    "label": "Sayoa",
    "value": "Sayoa"
  },
  {
    "id": "9118052022",
    "district_id": "911805",
    "label": "Bora",
    "value": "Bora"
  },
  {
    "id": "9118052023",
    "district_id": "911805",
    "label": "Amaita",
    "value": "Amaita"
  },
  {
    "id": "9118052024",
    "district_id": "911805",
    "label": "Akan Tapak",
    "value": "Akan Tapak"
  },
  {
    "id": "9118052025",
    "district_id": "911805",
    "label": "Mapane",
    "value": "Mapane"
  },
  {
    "id": "9118062007",
    "district_id": "911806",
    "label": "Kamur",
    "value": "Kamur"
  },
  {
    "id": "9118062008",
    "district_id": "911806",
    "label": "Sanep",
    "value": "Sanep"
  },
  {
    "id": "9118062023",
    "district_id": "911806",
    "label": "Saramit",
    "value": "Saramit"
  },
  {
    "id": "9118062024",
    "district_id": "911806",
    "label": "Yahoi",
    "value": "Yahoi"
  },
  {
    "id": "9118062025",
    "district_id": "911806",
    "label": "Hainam",
    "value": "Hainam"
  },
  {
    "id": "9118062026",
    "district_id": "911806",
    "label": "Yagamit",
    "value": "Yagamit"
  },
  {
    "id": "9118062027",
    "district_id": "911806",
    "label": "Bawor",
    "value": "Bawor"
  },
  {
    "id": "9118062028",
    "district_id": "911806",
    "label": "Sanapai",
    "value": "Sanapai"
  },
  {
    "id": "9118062041",
    "district_id": "911806",
    "label": "Sarmafo",
    "value": "Sarmafo"
  },
  {
    "id": "9118072001",
    "district_id": "911807",
    "label": "Binam",
    "value": "Binam"
  },
  {
    "id": "9118072002",
    "district_id": "911807",
    "label": "Dekamor",
    "value": "Dekamor"
  },
  {
    "id": "9118072003",
    "district_id": "911807",
    "label": "Daikot",
    "value": "Daikot"
  },
  {
    "id": "9118072004",
    "district_id": "911807",
    "label": "Somnak",
    "value": "Somnak"
  },
  {
    "id": "9118072008",
    "district_id": "911807",
    "label": "Vakam",
    "value": "Vakam"
  },
  {
    "id": "9118072009",
    "district_id": "911807",
    "label": "Wagabus",
    "value": "Wagabus"
  },
  {
    "id": "9118072010",
    "district_id": "911807",
    "label": "Burbis",
    "value": "Burbis"
  },
  {
    "id": "9118072012",
    "district_id": "911807",
    "label": "Bubis",
    "value": "Bubis"
  },
  {
    "id": "9118072013",
    "district_id": "911807",
    "label": "Karbis",
    "value": "Karbis"
  },
  {
    "id": "9118072014",
    "district_id": "911807",
    "label": "Jinak",
    "value": "Jinak"
  },
  {
    "id": "9118072015",
    "district_id": "911807",
    "label": "Wowi",
    "value": "Wowi"
  },
  {
    "id": "9118072020",
    "district_id": "911807",
    "label": "Soray",
    "value": "Soray"
  },
  {
    "id": "9118072021",
    "district_id": "911807",
    "label": "Vakam Dua",
    "value": "Vakam Dua"
  },
  {
    "id": "9118072022",
    "district_id": "911807",
    "label": "Bor",
    "value": "Bor"
  },
  {
    "id": "9118072023",
    "district_id": "911807",
    "label": "Waganu Dua",
    "value": "Waganu Dua"
  },
  {
    "id": "9118072025",
    "district_id": "911807",
    "label": "Wabak",
    "value": "Wabak"
  },
  {
    "id": "9118072030",
    "district_id": "911807",
    "label": "Waijens",
    "value": "Waijens"
  },
  {
    "id": "9118072031",
    "district_id": "911807",
    "label": "Ayak",
    "value": "Ayak"
  },
  {
    "id": "9118072032",
    "district_id": "911807",
    "label": "Banum",
    "value": "Banum"
  },
  {
    "id": "9118072033",
    "district_id": "911807",
    "label": "Ujung Batu",
    "value": "Ujung Batu"
  },
  {
    "id": "9118072034",
    "district_id": "911807",
    "label": "Bumu",
    "value": "Bumu"
  },
  {
    "id": "9118072035",
    "district_id": "911807",
    "label": "Nagatun",
    "value": "Nagatun"
  },
  {
    "id": "9118072036",
    "district_id": "911807",
    "label": "Amakot",
    "value": "Amakot"
  },
  {
    "id": "9118072037",
    "district_id": "911807",
    "label": "Kapayap Satu",
    "value": "Kapayap Satu"
  },
  {
    "id": "9118072038",
    "district_id": "911807",
    "label": "Kapayap Dua",
    "value": "Kapayap Dua"
  },
  {
    "id": "9118072039",
    "district_id": "911807",
    "label": "Kapayap Tiga",
    "value": "Kapayap Tiga"
  },
  {
    "id": "9118072040",
    "district_id": "911807",
    "label": "Emnam",
    "value": "Emnam"
  },
  {
    "id": "9118082001",
    "district_id": "911808",
    "label": "Suru-Suru",
    "value": "Suru-Suru"
  },
  {
    "id": "9118082002",
    "district_id": "911808",
    "label": "See",
    "value": "See"
  },
  {
    "id": "9118082003",
    "district_id": "911808",
    "label": "Koba",
    "value": "Koba"
  },
  {
    "id": "9118082004",
    "district_id": "911808",
    "label": "Tii",
    "value": "Tii"
  },
  {
    "id": "9118082005",
    "district_id": "911808",
    "label": "Yensuku",
    "value": "Yensuku"
  },
  {
    "id": "9118082006",
    "district_id": "911808",
    "label": "Sagapu",
    "value": "Sagapu"
  },
  {
    "id": "9118082007",
    "district_id": "911808",
    "label": "Tomor",
    "value": "Tomor"
  },
  {
    "id": "9118082008",
    "district_id": "911808",
    "label": "Jifak",
    "value": "Jifak"
  },
  {
    "id": "9118082009",
    "district_id": "911808",
    "label": "Dumaten",
    "value": "Dumaten"
  },
  {
    "id": "9118082010",
    "district_id": "911808",
    "label": "Hom-hom",
    "value": "Hom-hom"
  },
  {
    "id": "9118082011",
    "district_id": "911808",
    "label": "Ajin",
    "value": "Ajin"
  },
  {
    "id": "9118082012",
    "district_id": "911808",
    "label": "Laluk",
    "value": "Laluk"
  },
  {
    "id": "9118082013",
    "district_id": "911808",
    "label": "Kebikduk",
    "value": "Kebikduk"
  },
  {
    "id": "9118082014",
    "district_id": "911808",
    "label": "Hulam",
    "value": "Hulam"
  },
  {
    "id": "9118082015",
    "district_id": "911808",
    "label": "Walase",
    "value": "Walase"
  },
  {
    "id": "9118082016",
    "district_id": "911808",
    "label": "Berimono",
    "value": "Berimono"
  },
  {
    "id": "9118082017",
    "district_id": "911808",
    "label": "Asgun",
    "value": "Asgun"
  },
  {
    "id": "9118082018",
    "district_id": "911808",
    "label": "Korobuk",
    "value": "Korobuk"
  },
  {
    "id": "9118082019",
    "district_id": "911808",
    "label": "Katalina",
    "value": "Katalina"
  },
  {
    "id": "9118082020",
    "district_id": "911808",
    "label": "Obio",
    "value": "Obio"
  },
  {
    "id": "9118082021",
    "district_id": "911808",
    "label": "Salbik",
    "value": "Salbik"
  },
  {
    "id": "9118082022",
    "district_id": "911808",
    "label": "Linduk",
    "value": "Linduk"
  },
  {
    "id": "9118082023",
    "district_id": "911808",
    "label": "Besika",
    "value": "Besika"
  },
  {
    "id": "9118092001",
    "district_id": "911809",
    "label": "Binamsain",
    "value": "Binamsain"
  },
  {
    "id": "9118092002",
    "district_id": "911809",
    "label": "Pirabanak",
    "value": "Pirabanak"
  },
  {
    "id": "9118092003",
    "district_id": "911809",
    "label": "Woutu Brasa",
    "value": "Woutu Brasa"
  },
  {
    "id": "9118092004",
    "district_id": "911809",
    "label": "Patipi",
    "value": "Patipi"
  },
  {
    "id": "9118092005",
    "district_id": "911809",
    "label": "Ulakin",
    "value": "Ulakin"
  },
  {
    "id": "9118092006",
    "district_id": "911809",
    "label": "Woutu Kolof",
    "value": "Woutu Kolof"
  },
  {
    "id": "9118092007",
    "district_id": "911809",
    "label": "Butukatnau",
    "value": "Butukatnau"
  },
  {
    "id": "9118092008",
    "district_id": "911809",
    "label": "Sipenap",
    "value": "Sipenap"
  },
  {
    "id": "9118092009",
    "district_id": "911809",
    "label": "Mabul",
    "value": "Mabul"
  },
  {
    "id": "9118092010",
    "district_id": "911809",
    "label": "Auban",
    "value": "Auban"
  },
  {
    "id": "9118092011",
    "district_id": "911809",
    "label": "Pepera",
    "value": "Pepera"
  },
  {
    "id": "9118102001",
    "district_id": "911810",
    "label": "Komor",
    "value": "Komor"
  },
  {
    "id": "9118102002",
    "district_id": "911810",
    "label": "Amor",
    "value": "Amor"
  },
  {
    "id": "9118102003",
    "district_id": "911810",
    "label": "Yipawer",
    "value": "Yipawer"
  },
  {
    "id": "9118102004",
    "district_id": "911810",
    "label": "Berip",
    "value": "Berip"
  },
  {
    "id": "9118102005",
    "district_id": "911810",
    "label": "Ayir",
    "value": "Ayir"
  },
  {
    "id": "9118102006",
    "district_id": "911810",
    "label": "Werer",
    "value": "Werer"
  },
  {
    "id": "9118102007",
    "district_id": "911810",
    "label": "Munu",
    "value": "Munu"
  },
  {
    "id": "9118102008",
    "district_id": "911810",
    "label": "Abamu",
    "value": "Abamu"
  },
  {
    "id": "9118102009",
    "district_id": "911810",
    "label": "Paar",
    "value": "Paar"
  },
  {
    "id": "9118112001",
    "district_id": "911811",
    "label": "Yamas",
    "value": "Yamas"
  },
  {
    "id": "9118112002",
    "district_id": "911811",
    "label": "Yemi",
    "value": "Yemi"
  },
  {
    "id": "9118112003",
    "district_id": "911811",
    "label": "Omor",
    "value": "Omor"
  },
  {
    "id": "9118112004",
    "district_id": "911811",
    "label": "Yufri",
    "value": "Yufri"
  },
  {
    "id": "9118112005",
    "district_id": "911811",
    "label": "Yaun",
    "value": "Yaun"
  },
  {
    "id": "9118112006",
    "district_id": "911811",
    "label": "Onavai",
    "value": "Onavai"
  },
  {
    "id": "9118122001",
    "district_id": "911812",
    "label": "Kapi",
    "value": "Kapi"
  },
  { "id": "9118122002", "district_id": "911812", "label": "As", "value": "As" },
  {
    "id": "9118122003",
    "district_id": "911812",
    "label": "Atat",
    "value": "Atat"
  },
  {
    "id": "9118122004",
    "district_id": "911812",
    "label": "Aou",
    "value": "Aou"
  },
  {
    "id": "9118122005",
    "district_id": "911812",
    "label": "Nakai",
    "value": "Nakai"
  },
  {
    "id": "9118122006",
    "district_id": "911812",
    "label": "Esmapan",
    "value": "Esmapan"
  },
  {
    "id": "9118122007",
    "district_id": "911812",
    "label": "Eroko",
    "value": "Eroko"
  },
  {
    "id": "9118122008",
    "district_id": "911812",
    "label": "Yakapis",
    "value": "Yakapis"
  },
  {
    "id": "9118122009",
    "district_id": "911812",
    "label": "Weo",
    "value": "Weo"
  },
  {
    "id": "9118122010",
    "district_id": "911812",
    "label": "Aoep",
    "value": "Aoep"
  },
  {
    "id": "9118122011",
    "district_id": "911812",
    "label": "Fumeripit",
    "value": "Fumeripit"
  },
  {
    "id": "9118132001",
    "district_id": "911813",
    "label": "Yetsy",
    "value": "Yetsy"
  },
  {
    "id": "9118132002",
    "district_id": "911813",
    "label": "Powetsy",
    "value": "Powetsy"
  },
  {
    "id": "9118132003",
    "district_id": "911813",
    "label": "Pau",
    "value": "Pau"
  },
  {
    "id": "9118132004",
    "district_id": "911813",
    "label": "Sisakem",
    "value": "Sisakem"
  },
  {
    "id": "9118132005",
    "district_id": "911813",
    "label": "Akamar",
    "value": "Akamar"
  },
  {
    "id": "9118132006",
    "district_id": "911813",
    "label": "Birak",
    "value": "Birak"
  },
  {
    "id": "9118132007",
    "district_id": "911813",
    "label": "Katew",
    "value": "Katew"
  },
  {
    "id": "9118132008",
    "district_id": "911813",
    "label": "Dawer",
    "value": "Dawer"
  },
  {
    "id": "9118142001",
    "district_id": "911814",
    "label": "Yamkap",
    "value": "Yamkap"
  },
  {
    "id": "9118142002",
    "district_id": "911814",
    "label": "Amkun",
    "value": "Amkun"
  },
  {
    "id": "9118142003",
    "district_id": "911814",
    "label": "Amaru",
    "value": "Amaru"
  },
  {
    "id": "9118142004",
    "district_id": "911814",
    "label": "Amkai",
    "value": "Amkai"
  },
  {
    "id": "9118142005",
    "district_id": "911814",
    "label": "Amagais",
    "value": "Amagais"
  },
  {
    "id": "9118142006",
    "district_id": "911814",
    "label": "Yerfum",
    "value": "Yerfum"
  },
  {
    "id": "9118142007",
    "district_id": "911814",
    "label": "Suagai",
    "value": "Suagai"
  },
  {
    "id": "9118142008",
    "district_id": "911814",
    "label": "Sohomane",
    "value": "Sohomane"
  },
  {
    "id": "9118142009",
    "district_id": "911814",
    "label": "Ero Saman",
    "value": "Ero Saman"
  },
  {
    "id": "9118152001",
    "district_id": "911815",
    "label": "Kawem",
    "value": "Kawem"
  },
  {
    "id": "9118152002",
    "district_id": "911815",
    "label": "Hahare",
    "value": "Hahare"
  },
  {
    "id": "9118152003",
    "district_id": "911815",
    "label": "Aikut",
    "value": "Aikut"
  },
  {
    "id": "9118152004",
    "district_id": "911815",
    "label": "Sanem",
    "value": "Sanem"
  },
  {
    "id": "9118152005",
    "district_id": "911815",
    "label": "Kaipom",
    "value": "Kaipom"
  },
  {
    "id": "9118152006",
    "district_id": "911815",
    "label": "Sapen",
    "value": "Sapen"
  },
  {
    "id": "9118152007",
    "district_id": "911815",
    "label": "Simipit",
    "value": "Simipit"
  },
  {
    "id": "9118152008",
    "district_id": "911815",
    "label": "Heiyaram",
    "value": "Heiyaram"
  },
  {
    "id": "9118152009",
    "district_id": "911815",
    "label": "Wagasu",
    "value": "Wagasu"
  },
  {
    "id": "9118152010",
    "district_id": "911815",
    "label": "Sasime",
    "value": "Sasime"
  },
  {
    "id": "9118162001",
    "district_id": "911816",
    "label": "Primapun",
    "value": "Primapun"
  },
  {
    "id": "9118162002",
    "district_id": "911816",
    "label": "Aworkey",
    "value": "Aworkey"
  },
  {
    "id": "9118162003",
    "district_id": "911816",
    "label": "Saman",
    "value": "Saman"
  },
  {
    "id": "9118162004",
    "district_id": "911816",
    "label": "Bayun",
    "value": "Bayun"
  },
  {
    "id": "9118162005",
    "district_id": "911816",
    "label": "Samendoro",
    "value": "Samendoro"
  },
  {
    "id": "9118162006",
    "district_id": "911816",
    "label": "Tereo",
    "value": "Tereo"
  },
  {
    "id": "9118162007",
    "district_id": "911816",
    "label": "Kagirim",
    "value": "Kagirim"
  },
  {
    "id": "9118162008",
    "district_id": "911816",
    "label": "Santabor",
    "value": "Santabor"
  },
  {
    "id": "9118162009",
    "district_id": "911816",
    "label": "Yaptambor",
    "value": "Yaptambor"
  },
  {
    "id": "9118162010",
    "district_id": "911816",
    "label": "Simsagar",
    "value": "Simsagar"
  },
  {
    "id": "9118162011",
    "district_id": "911816",
    "label": "Jitorsok",
    "value": "Jitorsok"
  },
  {
    "id": "9118162012",
    "district_id": "911816",
    "label": "Emene",
    "value": "Emene"
  },
  {
    "id": "9118172001",
    "district_id": "911817",
    "label": "Yaosakor",
    "value": "Yaosakor"
  },
  {
    "id": "9118172002",
    "district_id": "911817",
    "label": "Damen",
    "value": "Damen"
  },
  {
    "id": "9118172003",
    "district_id": "911817",
    "label": "Biwar Darat",
    "value": "Biwar Darat"
  },
  {
    "id": "9118172004",
    "district_id": "911817",
    "label": "Kaimo",
    "value": "Kaimo"
  },
  {
    "id": "9118172005",
    "district_id": "911817",
    "label": "Awok",
    "value": "Awok"
  },
  {
    "id": "9118172006",
    "district_id": "911817",
    "label": "Fos",
    "value": "Fos"
  },
  {
    "id": "9118172007",
    "district_id": "911817",
    "label": "Waganu",
    "value": "Waganu"
  },
  {
    "id": "9118172008",
    "district_id": "911817",
    "label": "Sakor",
    "value": "Sakor"
  },
  {
    "id": "9118182001",
    "district_id": "911818",
    "label": "Comoro",
    "value": "Comoro"
  },
  {
    "id": "9118182002",
    "district_id": "911818",
    "label": "Wagi",
    "value": "Wagi"
  },
  {
    "id": "9118182003",
    "district_id": "911818",
    "label": "Sagare",
    "value": "Sagare"
  },
  {
    "id": "9118182004",
    "district_id": "911818",
    "label": "Kawet",
    "value": "Kawet"
  },
  {
    "id": "9118182005",
    "district_id": "911818",
    "label": "Yefuwagi",
    "value": "Yefuwagi"
  },
  {
    "id": "9118182006",
    "district_id": "911818",
    "label": "Mausi",
    "value": "Mausi"
  },
  {
    "id": "9118192001",
    "district_id": "911819",
    "label": "Biwar Laut",
    "value": "Biwar Laut"
  },
  {
    "id": "9118192002",
    "district_id": "911819",
    "label": "Atambuts",
    "value": "Atambuts"
  },
  {
    "id": "9118192003",
    "district_id": "911819",
    "label": "Omanesep",
    "value": "Omanesep"
  },
  {
    "id": "9118192004",
    "district_id": "911819",
    "label": "Warkai",
    "value": "Warkai"
  },
  {
    "id": "9118192005",
    "district_id": "911819",
    "label": "Yauw",
    "value": "Yauw"
  },
  {
    "id": "9118192006",
    "district_id": "911819",
    "label": "Pirpis",
    "value": "Pirpis"
  },
  {
    "id": "9118192007",
    "district_id": "911819",
    "label": "Desep",
    "value": "Desep"
  },
  {
    "id": "9119012003",
    "district_id": "911901",
    "label": "Fanindi",
    "value": "Fanindi"
  },
  {
    "id": "9119012004",
    "district_id": "911901",
    "label": "Odori",
    "value": "Odori"
  },
  {
    "id": "9119012010",
    "district_id": "911901",
    "label": "Biniki",
    "value": "Biniki"
  },
  {
    "id": "9119012011",
    "district_id": "911901",
    "label": "Didiabolo",
    "value": "Didiabolo"
  },
  {
    "id": "9119012015",
    "district_id": "911901",
    "label": "Warbefondi",
    "value": "Warbefondi"
  },
  {
    "id": "9119012016",
    "district_id": "911901",
    "label": "Awaki",
    "value": "Awaki"
  },
  {
    "id": "9119012019",
    "district_id": "911901",
    "label": "Maryaidori",
    "value": "Maryaidori"
  },
  {
    "id": "9119022003",
    "district_id": "911902",
    "label": "Warsa",
    "value": "Warsa"
  },
  {
    "id": "9119022008",
    "district_id": "911902",
    "label": "Warbor",
    "value": "Warbor"
  },
  {
    "id": "9119022009",
    "district_id": "911902",
    "label": "Kobari Jaya",
    "value": "Kobari Jaya"
  },
  {
    "id": "9119022010",
    "district_id": "911902",
    "label": "Fanjur",
    "value": "Fanjur"
  },
  {
    "id": "9119022015",
    "district_id": "911902",
    "label": "Puweri",
    "value": "Puweri"
  },
  {
    "id": "9119032001",
    "district_id": "911903",
    "label": "Yawerma",
    "value": "Yawerma"
  },
  {
    "id": "9119032002",
    "district_id": "911903",
    "label": "Wombonda",
    "value": "Wombonda"
  },
  {
    "id": "9119032003",
    "district_id": "911903",
    "label": "Marsram",
    "value": "Marsram"
  },
  {
    "id": "9119032004",
    "district_id": "911903",
    "label": "Duber",
    "value": "Duber"
  },
  {
    "id": "9119032005",
    "district_id": "911903",
    "label": "Sauyas",
    "value": "Sauyas"
  },
  {
    "id": "9119032006",
    "district_id": "911903",
    "label": "Wafor",
    "value": "Wafor"
  },
  {
    "id": "9119032007",
    "district_id": "911903",
    "label": "Sorendidori",
    "value": "Sorendidori"
  },
  {
    "id": "9119032008",
    "district_id": "911903",
    "label": "Waryesi",
    "value": "Waryesi"
  },
  {
    "id": "9119032009",
    "district_id": "911903",
    "label": "Syurdori",
    "value": "Syurdori"
  },
  {
    "id": "9119032010",
    "district_id": "911903",
    "label": "Douwbo",
    "value": "Douwbo"
  },
  {
    "id": "9119042001",
    "district_id": "911904",
    "label": "Rayori",
    "value": "Rayori"
  },
  {
    "id": "9119042002",
    "district_id": "911904",
    "label": "Mbrurwandi",
    "value": "Mbrurwandi"
  },
  {
    "id": "9119042003",
    "district_id": "911904",
    "label": "Manggonswan",
    "value": "Manggonswan"
  },
  {
    "id": "9119042004",
    "district_id": "911904",
    "label": "Wongkeina",
    "value": "Wongkeina"
  },
  {
    "id": "9119042005",
    "district_id": "911904",
    "label": "Yamnaisu",
    "value": "Yamnaisu"
  },
  {
    "id": "9119042006",
    "district_id": "911904",
    "label": "Aruri",
    "value": "Aruri"
  },
  {
    "id": "9119042007",
    "district_id": "911904",
    "label": "Imbirsbari",
    "value": "Imbirsbari"
  },
  {
    "id": "9119042008",
    "district_id": "911904",
    "label": "Ineki",
    "value": "Ineki"
  },
  {
    "id": "9119042009",
    "district_id": "911904",
    "label": "Insumbrei",
    "value": "Insumbrei"
  },
  {
    "id": "9119052001",
    "district_id": "911905",
    "label": "Waryei",
    "value": "Waryei"
  },
  {
    "id": "9119052002",
    "district_id": "911905",
    "label": "Koiryakam",
    "value": "Koiryakam"
  },
  {
    "id": "9119052003",
    "district_id": "911905",
    "label": "Wayori",
    "value": "Wayori"
  },
  {
    "id": "9119052004",
    "district_id": "911905",
    "label": "Amyas",
    "value": "Amyas"
  },
  {
    "id": "9119052005",
    "district_id": "911905",
    "label": "Napisndi",
    "value": "Napisndi"
  },
  {
    "id": "9119052006",
    "district_id": "911905",
    "label": "Masyai",
    "value": "Masyai"
  },
  {
    "id": "9119052007",
    "district_id": "911905",
    "label": "Mapia",
    "value": "Mapia"
  },
  {
    "id": "9120012001",
    "district_id": "912001",
    "label": "Burmeso",
    "value": "Burmeso"
  },
  {
    "id": "9120012002",
    "district_id": "912001",
    "label": "Anggreso",
    "value": "Anggreso"
  },
  {
    "id": "9120012003",
    "district_id": "912001",
    "label": "Kasonaweja",
    "value": "Kasonaweja"
  },
  {
    "id": "9120012004",
    "district_id": "912001",
    "label": "Metaweja",
    "value": "Metaweja"
  },
  {
    "id": "9120012005",
    "district_id": "912001",
    "label": "Namunaweja",
    "value": "Namunaweja"
  },
  {
    "id": "9120012006",
    "district_id": "912001",
    "label": "Danau Bira",
    "value": "Danau Bira"
  },
  {
    "id": "9120012007",
    "district_id": "912001",
    "label": "Muru Mare",
    "value": "Muru Mare"
  },
  {
    "id": "9120012008",
    "district_id": "912001",
    "label": "Kwerba",
    "value": "Kwerba"
  },
  {
    "id": "9120012009",
    "district_id": "912001",
    "label": "Sasakwesar",
    "value": "Sasakwesar"
  },
  {
    "id": "9120012010",
    "district_id": "912001",
    "label": "Babija",
    "value": "Babija"
  },
  {
    "id": "9120012011",
    "district_id": "912001",
    "label": "Marina Valen",
    "value": "Marina Valen"
  },
  {
    "id": "9120022001",
    "district_id": "912002",
    "label": "Dabra",
    "value": "Dabra"
  },
  {
    "id": "9120022002",
    "district_id": "912002",
    "label": "Taiyeve",
    "value": "Taiyeve"
  },
  {
    "id": "9120022003",
    "district_id": "912002",
    "label": "Fuao",
    "value": "Fuao"
  },
  {
    "id": "9120022004",
    "district_id": "912002",
    "label": "Papasena I",
    "value": "Papasena I"
  },
  {
    "id": "9120022005",
    "district_id": "912002",
    "label": "Papasena II",
    "value": "Papasena II"
  },
  {
    "id": "9120022006",
    "district_id": "912002",
    "label": "Dou",
    "value": "Dou"
  },
  {
    "id": "9120022008",
    "district_id": "912002",
    "label": "Fokri",
    "value": "Fokri"
  },
  {
    "id": "9120022016",
    "district_id": "912002",
    "label": "Haya",
    "value": "Haya"
  },
  {
    "id": "9120032001",
    "district_id": "912003",
    "label": "Fona",
    "value": "Fona"
  },
  {
    "id": "9120032002",
    "district_id": "912003",
    "label": "Tayai",
    "value": "Tayai"
  },
  {
    "id": "9120032003",
    "district_id": "912003",
    "label": "Bareri",
    "value": "Bareri"
  },
  {
    "id": "9120032004",
    "district_id": "912003",
    "label": "Sikari",
    "value": "Sikari"
  },
  {
    "id": "9120032005",
    "district_id": "912003",
    "label": "Taria",
    "value": "Taria"
  },
  {
    "id": "9120032006",
    "district_id": "912003",
    "label": "Kai",
    "value": "Kai"
  },
  {
    "id": "9120042001",
    "district_id": "912004",
    "label": "Kustra",
    "value": "Kustra"
  },
  {
    "id": "9120042002",
    "district_id": "912004",
    "label": "Noyadi",
    "value": "Noyadi"
  },
  {
    "id": "9120042003",
    "district_id": "912004",
    "label": "Obogoi",
    "value": "Obogoi"
  },
  {
    "id": "9120042004",
    "district_id": "912004",
    "label": "Biri",
    "value": "Biri"
  },
  {
    "id": "9120042005",
    "district_id": "912004",
    "label": "Wakeyadi",
    "value": "Wakeyadi"
  },
  {
    "id": "9120042006",
    "district_id": "912004",
    "label": "Eri",
    "value": "Eri"
  },
  {
    "id": "9120042007",
    "district_id": "912004",
    "label": "Towao",
    "value": "Towao"
  },
  {
    "id": "9120052001",
    "district_id": "912005",
    "label": "Baudi",
    "value": "Baudi"
  },
  {
    "id": "9120052002",
    "district_id": "912005",
    "label": "Trimuris",
    "value": "Trimuris"
  },
  {
    "id": "9120052003",
    "district_id": "912005",
    "label": "Bagusa",
    "value": "Bagusa"
  },
  {
    "id": "9120052004",
    "district_id": "912005",
    "label": "Kapeso",
    "value": "Kapeso"
  },
  {
    "id": "9120052005",
    "district_id": "912005",
    "label": "Suaseso",
    "value": "Suaseso"
  },
  {
    "id": "9120052006",
    "district_id": "912005",
    "label": "Warembori",
    "value": "Warembori"
  },
  {
    "id": "9120052007",
    "district_id": "912005",
    "label": "Yoke",
    "value": "Yoke"
  },
  {
    "id": "9120062001",
    "district_id": "912006",
    "label": "Nadofoai",
    "value": "Nadofoai"
  },
  {
    "id": "9120062002",
    "district_id": "912006",
    "label": "Barapasi",
    "value": "Barapasi"
  },
  {
    "id": "9120062003",
    "district_id": "912006",
    "label": "Sipisi",
    "value": "Sipisi"
  },
  {
    "id": "9120062004",
    "district_id": "912006",
    "label": "Bariwaro",
    "value": "Bariwaro"
  },
  {
    "id": "9120062005",
    "district_id": "912006",
    "label": "Bensor",
    "value": "Bensor"
  },
  {
    "id": "9120062006",
    "district_id": "912006",
    "label": "Rawiwa",
    "value": "Rawiwa"
  },
  {
    "id": "9120062007",
    "district_id": "912006",
    "label": "Marikai",
    "value": "Marikai"
  },
  {
    "id": "9120072001",
    "district_id": "912007",
    "label": "Kamai",
    "value": "Kamai"
  },
  {
    "id": "9120072002",
    "district_id": "912007",
    "label": "Dadat",
    "value": "Dadat"
  },
  {
    "id": "9120072003",
    "district_id": "912007",
    "label": "Kerema",
    "value": "Kerema"
  },
  {
    "id": "9120072004",
    "district_id": "912007",
    "label": "Taya",
    "value": "Taya"
  },
  {
    "id": "9120072005",
    "district_id": "912007",
    "label": "Teuw",
    "value": "Teuw"
  },
  {
    "id": "9120072006",
    "district_id": "912007",
    "label": "Watiaro",
    "value": "Watiaro"
  },
  {
    "id": "9120072007",
    "district_id": "912007",
    "label": "Gesa Baru",
    "value": "Gesa Baru"
  },
  {
    "id": "9120072008",
    "district_id": "912007",
    "label": "Baitanisa",
    "value": "Baitanisa"
  },
  {
    "id": "9120082001",
    "district_id": "912008",
    "label": "Poiwai",
    "value": "Poiwai"
  },
  {
    "id": "9120082002",
    "district_id": "912008",
    "label": "Tamakuri",
    "value": "Tamakuri"
  },
  {
    "id": "9120082003",
    "district_id": "912008",
    "label": "Bonoi",
    "value": "Bonoi"
  },
  {
    "id": "9120082004",
    "district_id": "912008",
    "label": "Sorabi",
    "value": "Sorabi"
  },
  {
    "id": "9120082005",
    "district_id": "912008",
    "label": "Rapamerei",
    "value": "Rapamerei"
  },
  {
    "id": "9120082006",
    "district_id": "912008",
    "label": "Anasi",
    "value": "Anasi"
  },
  {
    "id": "9121012001",
    "district_id": "912101",
    "label": "Kobagma",
    "value": "Kobagma"
  },
  {
    "id": "9121012002",
    "district_id": "912101",
    "label": "Ninugagas",
    "value": "Ninugagas"
  },
  {
    "id": "9121012003",
    "district_id": "912101",
    "label": "Seralema",
    "value": "Seralema"
  },
  {
    "id": "9121012004",
    "district_id": "912101",
    "label": "Gimbis",
    "value": "Gimbis"
  },
  {
    "id": "9121012005",
    "district_id": "912101",
    "label": "Boroges",
    "value": "Boroges"
  },
  {
    "id": "9121012006",
    "district_id": "912101",
    "label": "Aunduang",
    "value": "Aunduang"
  },
  {
    "id": "9121012007",
    "district_id": "912101",
    "label": "Luarima",
    "value": "Luarima"
  },
  {
    "id": "9121012008",
    "district_id": "912101",
    "label": "Guawage",
    "value": "Guawage"
  },
  {
    "id": "9121012009",
    "district_id": "912101",
    "label": "Moga",
    "value": "Moga"
  },
  {
    "id": "9121012010",
    "district_id": "912101",
    "label": "Keniwa",
    "value": "Keniwa"
  },
  {
    "id": "9121012011",
    "district_id": "912101",
    "label": "Sembegulik",
    "value": "Sembegulik"
  },
  {
    "id": "9121012012",
    "district_id": "912101",
    "label": "Dogle",
    "value": "Dogle"
  },
  {
    "id": "9121012013",
    "district_id": "912101",
    "label": "Wiyugobak",
    "value": "Wiyugobak"
  },
  {
    "id": "9121012014",
    "district_id": "912101",
    "label": "Baliklabuk",
    "value": "Baliklabuk"
  },
  {
    "id": "9121012015",
    "district_id": "912101",
    "label": "Yagalim",
    "value": "Yagalim"
  },
  {
    "id": "9121022001",
    "district_id": "912102",
    "label": "Kelila",
    "value": "Kelila"
  },
  {
    "id": "9121022002",
    "district_id": "912102",
    "label": "Dibunggen",
    "value": "Dibunggen"
  },
  {
    "id": "9121022003",
    "district_id": "912102",
    "label": "Timeria",
    "value": "Timeria"
  },
  {
    "id": "9121022004",
    "district_id": "912102",
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "id": "9121022005",
    "district_id": "912102",
    "label": "Dogobak",
    "value": "Dogobak"
  },
  {
    "id": "9121022006",
    "district_id": "912102",
    "label": "Yalenggolo",
    "value": "Yalenggolo"
  },
  {
    "id": "9121022007",
    "district_id": "912102",
    "label": "Binime",
    "value": "Binime"
  },
  {
    "id": "9121022008",
    "district_id": "912102",
    "label": "Tikapura",
    "value": "Tikapura"
  },
  {
    "id": "9121022009",
    "district_id": "912102",
    "label": "Onggobalo",
    "value": "Onggobalo"
  },
  {
    "id": "9121022010",
    "district_id": "912102",
    "label": "Kambo",
    "value": "Kambo"
  },
  {
    "id": "9121022011",
    "district_id": "912102",
    "label": "Kindok",
    "value": "Kindok"
  },
  {
    "id": "9121022012",
    "district_id": "912102",
    "label": "Tari",
    "value": "Tari"
  },
  {
    "id": "9121022013",
    "district_id": "912102",
    "label": "Gelora",
    "value": "Gelora"
  },
  {
    "id": "9121022014",
    "district_id": "912102",
    "label": "Yagabur",
    "value": "Yagabur"
  },
  {
    "id": "9121022015",
    "district_id": "912102",
    "label": "Pelanme",
    "value": "Pelanme"
  },
  {
    "id": "9121022016",
    "district_id": "912102",
    "label": "Tonggrik",
    "value": "Tonggrik"
  },
  {
    "id": "9121022017",
    "district_id": "912102",
    "label": "Kumbu",
    "value": "Kumbu"
  },
  {
    "id": "9121022018",
    "district_id": "912102",
    "label": "Mabuna",
    "value": "Mabuna"
  },
  {
    "id": "9121022019",
    "district_id": "912102",
    "label": "Manggaleso",
    "value": "Manggaleso"
  },
  {
    "id": "9121032001",
    "district_id": "912103",
    "label": "Winima",
    "value": "Winima"
  },
  {
    "id": "9121032002",
    "district_id": "912103",
    "label": "Mogonik",
    "value": "Mogonik"
  },
  {
    "id": "9121032003",
    "district_id": "912103",
    "label": "Winam",
    "value": "Winam"
  },
  {
    "id": "9121032004",
    "district_id": "912103",
    "label": "Kino",
    "value": "Kino"
  },
  {
    "id": "9121032005",
    "district_id": "912103",
    "label": "Ayeki",
    "value": "Ayeki"
  },
  {
    "id": "9121032006",
    "district_id": "912103",
    "label": "Enggama",
    "value": "Enggama"
  },
  {
    "id": "9121032007",
    "district_id": "912103",
    "label": "Wurigelobar",
    "value": "Wurigelobar"
  },
  {
    "id": "9121032008",
    "district_id": "912103",
    "label": "Pagale",
    "value": "Pagale"
  },
  {
    "id": "9121032009",
    "district_id": "912103",
    "label": "Arsbol",
    "value": "Arsbol"
  },
  {
    "id": "9121032010",
    "district_id": "912103",
    "label": "Yabendili",
    "value": "Yabendili"
  },
  {
    "id": "9121032011",
    "district_id": "912103",
    "label": "Moligi",
    "value": "Moligi"
  },
  {
    "id": "9121032012",
    "district_id": "912103",
    "label": "Kugab",
    "value": "Kugab"
  },
  {
    "id": "9121032013",
    "district_id": "912103",
    "label": "Engaima",
    "value": "Engaima"
  },
  {
    "id": "9121032014",
    "district_id": "912103",
    "label": "Erageam",
    "value": "Erageam"
  },
  {
    "id": "9121032015",
    "district_id": "912103",
    "label": "Wanilok",
    "value": "Wanilok"
  },
  {
    "id": "9121042001",
    "district_id": "912104",
    "label": "Megambilis",
    "value": "Megambilis"
  },
  {
    "id": "9121042002",
    "district_id": "912104",
    "label": "Tariko",
    "value": "Tariko"
  },
  {
    "id": "9121042003",
    "district_id": "912104",
    "label": "Homasan",
    "value": "Homasan"
  },
  {
    "id": "9121042004",
    "district_id": "912104",
    "label": "Higisyam",
    "value": "Higisyam"
  },
  {
    "id": "9121052001",
    "district_id": "912105",
    "label": "Kalarin",
    "value": "Kalarin"
  },
  {
    "id": "9121052002",
    "district_id": "912105",
    "label": "Melenggama",
    "value": "Melenggama"
  },
  {
    "id": "9121052003",
    "district_id": "912105",
    "label": "Ilugwa",
    "value": "Ilugwa"
  },
  {
    "id": "9121052004",
    "district_id": "912105",
    "label": "Wirima",
    "value": "Wirima"
  },
  {
    "id": "9121052005",
    "district_id": "912105",
    "label": "Danama",
    "value": "Danama"
  },
  {
    "id": "9121052006",
    "district_id": "912105",
    "label": "Illusilimo",
    "value": "Illusilimo"
  },
  {
    "id": "9122012001",
    "district_id": "912201",
    "label": "Elelim",
    "value": "Elelim"
  },
  {
    "id": "9122012002",
    "district_id": "912201",
    "label": "Aluis",
    "value": "Aluis"
  },
  {
    "id": "9122012003",
    "district_id": "912201",
    "label": "Honita",
    "value": "Honita"
  },
  {
    "id": "9122012004",
    "district_id": "912201",
    "label": "Yabema",
    "value": "Yabema"
  },
  {
    "id": "9122012005",
    "district_id": "912201",
    "label": "Pihel",
    "value": "Pihel"
  },
  {
    "id": "9122012006",
    "district_id": "912201",
    "label": "Walkep",
    "value": "Walkep"
  },
  {
    "id": "9122012007",
    "district_id": "912201",
    "label": "Pangkik",
    "value": "Pangkik"
  },
  {
    "id": "9122012008",
    "district_id": "912201",
    "label": "Maribu",
    "value": "Maribu"
  },
  {
    "id": "9122012009",
    "district_id": "912201",
    "label": "Yakikma",
    "value": "Yakikma"
  },
  {
    "id": "9122012010",
    "district_id": "912201",
    "label": "Ulo",
    "value": "Ulo"
  },
  {
    "id": "9122012011",
    "district_id": "912201",
    "label": "Sira",
    "value": "Sira"
  },
  {
    "id": "9122012012",
    "district_id": "912201",
    "label": "Ohoam",
    "value": "Ohoam"
  },
  {
    "id": "9122012013",
    "district_id": "912201",
    "label": "Bulmu",
    "value": "Bulmu"
  },
  {
    "id": "9122012014",
    "district_id": "912201",
    "label": "Ubi",
    "value": "Ubi"
  },
  {
    "id": "9122012015",
    "district_id": "912201",
    "label": "Pirip",
    "value": "Pirip"
  },
  {
    "id": "9122012016",
    "district_id": "912201",
    "label": "Momonhusi",
    "value": "Momonhusi"
  },
  {
    "id": "9122012017",
    "district_id": "912201",
    "label": "Helabu",
    "value": "Helabu"
  },
  {
    "id": "9122012018",
    "district_id": "912201",
    "label": "Pungkahik",
    "value": "Pungkahik"
  },
  {
    "id": "9122012019",
    "district_id": "912201",
    "label": "Wekolani",
    "value": "Wekolani"
  },
  {
    "id": "9122012020",
    "district_id": "912201",
    "label": "Hobakma",
    "value": "Hobakma"
  },
  {
    "id": "9122012021",
    "district_id": "912201",
    "label": "Wasua",
    "value": "Wasua"
  },
  {
    "id": "9122012022",
    "district_id": "912201",
    "label": "Warikma",
    "value": "Warikma"
  },
  {
    "id": "9122012023",
    "district_id": "912201",
    "label": "Kalak",
    "value": "Kalak"
  },
  {
    "id": "9122012024",
    "district_id": "912201",
    "label": "Isila",
    "value": "Isila"
  },
  {
    "id": "9122012025",
    "district_id": "912201",
    "label": "Fima",
    "value": "Fima"
  },
  {
    "id": "9122012026",
    "district_id": "912201",
    "label": "Sohi",
    "value": "Sohi"
  },
  {
    "id": "9122012027",
    "district_id": "912201",
    "label": "Pasimbolo",
    "value": "Pasimbolo"
  },
  {
    "id": "9122012028",
    "district_id": "912201",
    "label": "Wagagu",
    "value": "Wagagu"
  },
  {
    "id": "9122012029",
    "district_id": "912201",
    "label": "Sowi",
    "value": "Sowi"
  },
  {
    "id": "9122012030",
    "district_id": "912201",
    "label": "Sipson",
    "value": "Sipson"
  },
  {
    "id": "9122012031",
    "district_id": "912201",
    "label": "Sipsoi",
    "value": "Sipsoi"
  },
  {
    "id": "9122012032",
    "district_id": "912201",
    "label": "Eregi",
    "value": "Eregi"
  },
  {
    "id": "9122012033",
    "district_id": "912201",
    "label": "Ohoniam",
    "value": "Ohoniam"
  },
  {
    "id": "9122012034",
    "district_id": "912201",
    "label": "Hiling",
    "value": "Hiling"
  },
  {
    "id": "9122012035",
    "district_id": "912201",
    "label": "Duwong",
    "value": "Duwong"
  },
  {
    "id": "9122012036",
    "district_id": "912201",
    "label": "Pisireg",
    "value": "Pisireg"
  },
  {
    "id": "9122012037",
    "district_id": "912201",
    "label": "Tanapasir",
    "value": "Tanapasir"
  },
  {
    "id": "9122012038",
    "district_id": "912201",
    "label": "Olani",
    "value": "Olani"
  },
  {
    "id": "9122012039",
    "district_id": "912201",
    "label": "Sili",
    "value": "Sili"
  },
  {
    "id": "9122012040",
    "district_id": "912201",
    "label": "Hesmat",
    "value": "Hesmat"
  },
  {
    "id": "9122012041",
    "district_id": "912201",
    "label": "Molinggu",
    "value": "Molinggu"
  },
  {
    "id": "9122012042",
    "district_id": "912201",
    "label": "Werekma",
    "value": "Werekma"
  },
  {
    "id": "9122012043",
    "district_id": "912201",
    "label": "Emon",
    "value": "Emon"
  },
  {
    "id": "9122012044",
    "district_id": "912201",
    "label": "Kwikma",
    "value": "Kwikma"
  },
  {
    "id": "9122022001",
    "district_id": "912202",
    "label": "Apalapsili",
    "value": "Apalapsili"
  },
  {
    "id": "9122022002",
    "district_id": "912202",
    "label": "Hubliki",
    "value": "Hubliki"
  },
  {
    "id": "9122022003",
    "district_id": "912202",
    "label": "Kulet",
    "value": "Kulet"
  },
  {
    "id": "9122022004",
    "district_id": "912202",
    "label": "Yarema",
    "value": "Yarema"
  },
  {
    "id": "9122022005",
    "district_id": "912202",
    "label": "Asiligma",
    "value": "Asiligma"
  },
  {
    "id": "9122022006",
    "district_id": "912202",
    "label": "Lambukmu",
    "value": "Lambukmu"
  },
  {
    "id": "9122022007",
    "district_id": "912202",
    "label": "Suwewili",
    "value": "Suwewili"
  },
  {
    "id": "9122022008",
    "district_id": "912202",
    "label": "Yohul",
    "value": "Yohul"
  },
  {
    "id": "9122022009",
    "district_id": "912202",
    "label": "Lilinsaluk",
    "value": "Lilinsaluk"
  },
  {
    "id": "9122022010",
    "district_id": "912202",
    "label": "Natoksili",
    "value": "Natoksili"
  },
  {
    "id": "9122022011",
    "district_id": "912202",
    "label": "Hinanggoambut",
    "value": "Hinanggoambut"
  },
  {
    "id": "9122022012",
    "district_id": "912202",
    "label": "Wiyukwilik",
    "value": "Wiyukwilik"
  },
  {
    "id": "9122022013",
    "district_id": "912202",
    "label": "Fari",
    "value": "Fari"
  },
  {
    "id": "9122022014",
    "district_id": "912202",
    "label": "Wasalalo",
    "value": "Wasalalo"
  },
  {
    "id": "9122022015",
    "district_id": "912202",
    "label": "Suerlihim",
    "value": "Suerlihim"
  },
  {
    "id": "9122022016",
    "district_id": "912202",
    "label": "Orohon",
    "value": "Orohon"
  },
  {
    "id": "9122022017",
    "district_id": "912202",
    "label": "Habalo",
    "value": "Habalo"
  },
  {
    "id": "9122022018",
    "district_id": "912202",
    "label": "Sabilikalem",
    "value": "Sabilikalem"
  },
  {
    "id": "9122022019",
    "district_id": "912202",
    "label": "Wanamalo",
    "value": "Wanamalo"
  },
  {
    "id": "9122022020",
    "district_id": "912202",
    "label": "Kundikele",
    "value": "Kundikele"
  },
  {
    "id": "9122022021",
    "district_id": "912202",
    "label": "Kelampurim",
    "value": "Kelampurim"
  },
  {
    "id": "9122022022",
    "district_id": "912202",
    "label": "Holukalem",
    "value": "Holukalem"
  },
  {
    "id": "9122022023",
    "district_id": "912202",
    "label": "Yeptek",
    "value": "Yeptek"
  },
  {
    "id": "9122022024",
    "district_id": "912202",
    "label": "Benyam",
    "value": "Benyam"
  },
  {
    "id": "9122022025",
    "district_id": "912202",
    "label": "Temput",
    "value": "Temput"
  },
  {
    "id": "9122022026",
    "district_id": "912202",
    "label": "Sabualo",
    "value": "Sabualo"
  },
  {
    "id": "9122022027",
    "district_id": "912202",
    "label": "Nanahoruk",
    "value": "Nanahoruk"
  },
  {
    "id": "9122022028",
    "district_id": "912202",
    "label": "Kengkenbun",
    "value": "Kengkenbun"
  },
  {
    "id": "9122022029",
    "district_id": "912202",
    "label": "Sien",
    "value": "Sien"
  },
  {
    "id": "9122022030",
    "district_id": "912202",
    "label": "Kinhe",
    "value": "Kinhe"
  },
  {
    "id": "9122022031",
    "district_id": "912202",
    "label": "Humalem",
    "value": "Humalem"
  },
  {
    "id": "9122022032",
    "district_id": "912202",
    "label": "Pipisim",
    "value": "Pipisim"
  },
  {
    "id": "9122022033",
    "district_id": "912202",
    "label": "Makrig",
    "value": "Makrig"
  },
  {
    "id": "9122022035",
    "district_id": "912202",
    "label": "Ilirek",
    "value": "Ilirek"
  },
  {
    "id": "9122022036",
    "district_id": "912202",
    "label": "Filiahik",
    "value": "Filiahik"
  },
  {
    "id": "9122022037",
    "district_id": "912202",
    "label": "Kilat",
    "value": "Kilat"
  },
  {
    "id": "9122022038",
    "district_id": "912202",
    "label": "Baptekma",
    "value": "Baptekma"
  },
  {
    "id": "9122022039",
    "district_id": "912202",
    "label": "Nohonil",
    "value": "Nohonil"
  },
  {
    "id": "9122022040",
    "district_id": "912202",
    "label": "Asilikma",
    "value": "Asilikma"
  },
  {
    "id": "9122022041",
    "district_id": "912202",
    "label": "Tikano",
    "value": "Tikano"
  },
  {
    "id": "9122022042",
    "district_id": "912202",
    "label": "Nasinema",
    "value": "Nasinema"
  },
  {
    "id": "9122022043",
    "district_id": "912202",
    "label": "Eal",
    "value": "Eal"
  },
  {
    "id": "9122022044",
    "district_id": "912202",
    "label": "Hukalopunu",
    "value": "Hukalopunu"
  },
  {
    "id": "9122022045",
    "district_id": "912202",
    "label": "Alimuhuk",
    "value": "Alimuhuk"
  },
  {
    "id": "9122022046",
    "district_id": "912202",
    "label": "Pong",
    "value": "Pong"
  },
  {
    "id": "9122022047",
    "district_id": "912202",
    "label": "Molinggi",
    "value": "Molinggi"
  },
  {
    "id": "9122022048",
    "district_id": "912202",
    "label": "Sobikambut",
    "value": "Sobikambut"
  },
  {
    "id": "9122022049",
    "district_id": "912202",
    "label": "Wiralesili",
    "value": "Wiralesili"
  },
  {
    "id": "9122022050",
    "district_id": "912202",
    "label": "Holuogalma",
    "value": "Holuogalma"
  },
  {
    "id": "9122022051",
    "district_id": "912202",
    "label": "Falukwalilo",
    "value": "Falukwalilo"
  },
  {
    "id": "9122032001",
    "district_id": "912203",
    "label": "Abenaho",
    "value": "Abenaho"
  },
  {
    "id": "9122032002",
    "district_id": "912203",
    "label": "Wambalfak",
    "value": "Wambalfak"
  },
  {
    "id": "9122032003",
    "district_id": "912203",
    "label": "Landikma",
    "value": "Landikma"
  },
  {
    "id": "9122032004",
    "district_id": "912203",
    "label": "Dombomi",
    "value": "Dombomi"
  },
  {
    "id": "9122032005",
    "district_id": "912203",
    "label": "Dosumo",
    "value": "Dosumo"
  },
  {
    "id": "9122032006",
    "district_id": "912203",
    "label": "Wambo",
    "value": "Wambo"
  },
  {
    "id": "9122032007",
    "district_id": "912203",
    "label": "Sombule",
    "value": "Sombule"
  },
  {
    "id": "9122032008",
    "district_id": "912203",
    "label": "Hubliki",
    "value": "Hubliki"
  },
  {
    "id": "9122032009",
    "district_id": "912203",
    "label": "Hulikma",
    "value": "Hulikma"
  },
  {
    "id": "9122032010",
    "district_id": "912203",
    "label": "Sebi",
    "value": "Sebi"
  },
  {
    "id": "9122032011",
    "district_id": "912203",
    "label": "Suminaikma",
    "value": "Suminaikma"
  },
  {
    "id": "9122032012",
    "district_id": "912203",
    "label": "Ulun",
    "value": "Ulun"
  },
  {
    "id": "9122032013",
    "district_id": "912203",
    "label": "Fuahe",
    "value": "Fuahe"
  },
  {
    "id": "9122032014",
    "district_id": "912203",
    "label": "Wutlarin",
    "value": "Wutlarin"
  },
  {
    "id": "9122032015",
    "district_id": "912203",
    "label": "Kolaima",
    "value": "Kolaima"
  },
  {
    "id": "9122032016",
    "district_id": "912203",
    "label": "Wanggibo",
    "value": "Wanggibo"
  },
  {
    "id": "9122032017",
    "district_id": "912203",
    "label": "Walagima",
    "value": "Walagima"
  },
  {
    "id": "9122032018",
    "district_id": "912203",
    "label": "Tagabaga",
    "value": "Tagabaga"
  },
  {
    "id": "9122032019",
    "district_id": "912203",
    "label": "Bohum",
    "value": "Bohum"
  },
  {
    "id": "9122032020",
    "district_id": "912203",
    "label": "Jinggiwi",
    "value": "Jinggiwi"
  },
  {
    "id": "9122032021",
    "district_id": "912203",
    "label": "Hurualma",
    "value": "Hurualma"
  },
  {
    "id": "9122032022",
    "district_id": "912203",
    "label": "Humalma",
    "value": "Humalma"
  },
  {
    "id": "9122032023",
    "district_id": "912203",
    "label": "Yahatma",
    "value": "Yahatma"
  },
  {
    "id": "9122032024",
    "district_id": "912203",
    "label": "Sohombunu",
    "value": "Sohombunu"
  },
  {
    "id": "9122032025",
    "district_id": "912203",
    "label": "Bumbum",
    "value": "Bumbum"
  },
  {
    "id": "9122032026",
    "district_id": "912203",
    "label": "Burim",
    "value": "Burim"
  },
  {
    "id": "9122032027",
    "district_id": "912203",
    "label": "Dahonaikma",
    "value": "Dahonaikma"
  },
  {
    "id": "9122032028",
    "district_id": "912203",
    "label": "Musanahikma",
    "value": "Musanahikma"
  },
  {
    "id": "9122032029",
    "district_id": "912203",
    "label": "Abagima",
    "value": "Abagima"
  },
  {
    "id": "9122032030",
    "district_id": "912203",
    "label": "Waghasilimo",
    "value": "Waghasilimo"
  },
  {
    "id": "9122032031",
    "district_id": "912203",
    "label": "Wileroma",
    "value": "Wileroma"
  },
  {
    "id": "9122032032",
    "district_id": "912203",
    "label": "Obabin",
    "value": "Obabin"
  },
  {
    "id": "9122032033",
    "district_id": "912203",
    "label": "Babin",
    "value": "Babin"
  },
  {
    "id": "9122032034",
    "district_id": "912203",
    "label": "Elesim",
    "value": "Elesim"
  },
  {
    "id": "9122032035",
    "district_id": "912203",
    "label": "Wanwal",
    "value": "Wanwal"
  },
  {
    "id": "9122032036",
    "district_id": "912203",
    "label": "Sabilironggo",
    "value": "Sabilironggo"
  },
  {
    "id": "9122032037",
    "district_id": "912203",
    "label": "Yanamik",
    "value": "Yanamik"
  },
  {
    "id": "9122032038",
    "district_id": "912203",
    "label": "Lakongkong",
    "value": "Lakongkong"
  },
  {
    "id": "9122032039",
    "district_id": "912203",
    "label": "Helaksili",
    "value": "Helaksili"
  },
  {
    "id": "9122032040",
    "district_id": "912203",
    "label": "Bukurik",
    "value": "Bukurik"
  },
  {
    "id": "9122032041",
    "district_id": "912203",
    "label": "Kewi",
    "value": "Kewi"
  },
  {
    "id": "9122032042",
    "district_id": "912203",
    "label": "Yambaikma",
    "value": "Yambaikma"
  },
  {
    "id": "9122032043",
    "district_id": "912203",
    "label": "Hubualma",
    "value": "Hubualma"
  },
  {
    "id": "9122032044",
    "district_id": "912203",
    "label": "Yuktanggo",
    "value": "Yuktanggo"
  },
  {
    "id": "9122032045",
    "district_id": "912203",
    "label": "Wambal",
    "value": "Wambal"
  },
  {
    "id": "9122032046",
    "district_id": "912203",
    "label": "Lilimuhuk",
    "value": "Lilimuhuk"
  },
  {
    "id": "9122032047",
    "district_id": "912203",
    "label": "Hulhule",
    "value": "Hulhule"
  },
  {
    "id": "9122032048",
    "district_id": "912203",
    "label": "Mahiyal",
    "value": "Mahiyal"
  },
  {
    "id": "9122032049",
    "district_id": "912203",
    "label": "Alugi",
    "value": "Alugi"
  },
  {
    "id": "9122032050",
    "district_id": "912203",
    "label": "Sahikma",
    "value": "Sahikma"
  },
  {
    "id": "9122032051",
    "district_id": "912203",
    "label": "Bonahik",
    "value": "Bonahik"
  },
  {
    "id": "9122032052",
    "district_id": "912203",
    "label": "Eliyekma",
    "value": "Eliyekma"
  },
  {
    "id": "9122032053",
    "district_id": "912203",
    "label": "Yabusup",
    "value": "Yabusup"
  },
  {
    "id": "9122032054",
    "district_id": "912203",
    "label": "Arikbalek",
    "value": "Arikbalek"
  },
  {
    "id": "9122032055",
    "district_id": "912203",
    "label": "Yawan",
    "value": "Yawan"
  },
  {
    "id": "9122032056",
    "district_id": "912203",
    "label": "Fuim",
    "value": "Fuim"
  },
  {
    "id": "9122032057",
    "district_id": "912203",
    "label": "Witlanggo",
    "value": "Witlanggo"
  },
  {
    "id": "9122032058",
    "district_id": "912203",
    "label": "Worowi",
    "value": "Worowi"
  },
  {
    "id": "9122032059",
    "district_id": "912203",
    "label": "Bonggi",
    "value": "Bonggi"
  },
  {
    "id": "9122032060",
    "district_id": "912203",
    "label": "Wilak",
    "value": "Wilak"
  },
  {
    "id": "9122032061",
    "district_id": "912203",
    "label": "Wanggun",
    "value": "Wanggun"
  },
  {
    "id": "9122032062",
    "district_id": "912203",
    "label": "Hombanma",
    "value": "Hombanma"
  },
  {
    "id": "9122032063",
    "district_id": "912203",
    "label": "Yama",
    "value": "Yama"
  },
  {
    "id": "9122032064",
    "district_id": "912203",
    "label": "Halifur",
    "value": "Halifur"
  },
  {
    "id": "9122032065",
    "district_id": "912203",
    "label": "Beim",
    "value": "Beim"
  },
  {
    "id": "9122032066",
    "district_id": "912203",
    "label": "Ulusi",
    "value": "Ulusi"
  },
  {
    "id": "9122032067",
    "district_id": "912203",
    "label": "Salo",
    "value": "Salo"
  },
  {
    "id": "9122032068",
    "district_id": "912203",
    "label": "Moban",
    "value": "Moban"
  },
  {
    "id": "9122032069",
    "district_id": "912203",
    "label": "Mulip",
    "value": "Mulip"
  },
  {
    "id": "9122032070",
    "district_id": "912203",
    "label": "Ulukhumi",
    "value": "Ulukhumi"
  },
  {
    "id": "9122032071",
    "district_id": "912203",
    "label": "Halisek",
    "value": "Halisek"
  },
  {
    "id": "9122032072",
    "district_id": "912203",
    "label": "Lohomabel",
    "value": "Lohomabel"
  },
  {
    "id": "9122032073",
    "district_id": "912203",
    "label": "Kule",
    "value": "Kule"
  },
  {
    "id": "9122032074",
    "district_id": "912203",
    "label": "Kandibung",
    "value": "Kandibung"
  },
  {
    "id": "9122032075",
    "district_id": "912203",
    "label": "Gefido",
    "value": "Gefido"
  },
  {
    "id": "9122032076",
    "district_id": "912203",
    "label": "Sapiwarek",
    "value": "Sapiwarek"
  },
  {
    "id": "9122032077",
    "district_id": "912203",
    "label": "Fialem",
    "value": "Fialem"
  },
  {
    "id": "9122032078",
    "district_id": "912203",
    "label": "Horakia",
    "value": "Horakia"
  },
  {
    "id": "9122032079",
    "district_id": "912203",
    "label": "Suahe",
    "value": "Suahe"
  },
  {
    "id": "9122032080",
    "district_id": "912203",
    "label": "Uluhufuk",
    "value": "Uluhufuk"
  },
  {
    "id": "9122032081",
    "district_id": "912203",
    "label": "Waroham",
    "value": "Waroham"
  },
  {
    "id": "9122032082",
    "district_id": "912203",
    "label": "Sohi",
    "value": "Sohi"
  },
  {
    "id": "9122032083",
    "district_id": "912203",
    "label": "Akris",
    "value": "Akris"
  },
  {
    "id": "9122032084",
    "district_id": "912203",
    "label": "Pirikalem",
    "value": "Pirikalem"
  },
  {
    "id": "9122032085",
    "district_id": "912203",
    "label": "Sumbul",
    "value": "Sumbul"
  },
  {
    "id": "9122032086",
    "district_id": "912203",
    "label": "Hundilip",
    "value": "Hundilip"
  },
  {
    "id": "9122032087",
    "district_id": "912203",
    "label": "Amuki",
    "value": "Amuki"
  },
  {
    "id": "9122032088",
    "district_id": "912203",
    "label": "Wamhor",
    "value": "Wamhor"
  },
  {
    "id": "9122032089",
    "district_id": "912203",
    "label": "Kesuwi",
    "value": "Kesuwi"
  },
  {
    "id": "9122032090",
    "district_id": "912203",
    "label": "Pamumu",
    "value": "Pamumu"
  },
  {
    "id": "9122032091",
    "district_id": "912203",
    "label": "Wafuhuk",
    "value": "Wafuhuk"
  },
  {
    "id": "9122032092",
    "district_id": "912203",
    "label": "Lilluikma",
    "value": "Lilluikma"
  },
  {
    "id": "9122032093",
    "district_id": "912203",
    "label": "Noholuok",
    "value": "Noholuok"
  },
  {
    "id": "9122032094",
    "district_id": "912203",
    "label": "Wanam",
    "value": "Wanam"
  },
  {
    "id": "9122032095",
    "district_id": "912203",
    "label": "Nenomani",
    "value": "Nenomani"
  },
  {
    "id": "9122032096",
    "district_id": "912203",
    "label": "Wilofing",
    "value": "Wilofing"
  },
  {
    "id": "9122032097",
    "district_id": "912203",
    "label": "Salohe",
    "value": "Salohe"
  },
  {
    "id": "9122032098",
    "district_id": "912203",
    "label": "Dama",
    "value": "Dama"
  },
  {
    "id": "9122032099",
    "district_id": "912203",
    "label": "Kelesu",
    "value": "Kelesu"
  },
  {
    "id": "9122032100",
    "district_id": "912203",
    "label": "Kaboholik",
    "value": "Kaboholik"
  },
  {
    "id": "9122032101",
    "district_id": "912203",
    "label": "Horenikma",
    "value": "Horenikma"
  },
  {
    "id": "9122032102",
    "district_id": "912203",
    "label": "Lasikma",
    "value": "Lasikma"
  },
  {
    "id": "9122032103",
    "district_id": "912203",
    "label": "Suele",
    "value": "Suele"
  },
  {
    "id": "9122032104",
    "district_id": "912203",
    "label": "Heahobak",
    "value": "Heahobak"
  },
  {
    "id": "9122032105",
    "district_id": "912203",
    "label": "Maleo",
    "value": "Maleo"
  },
  {
    "id": "9122032106",
    "district_id": "912203",
    "label": "Usabiye",
    "value": "Usabiye"
  },
  {
    "id": "9122032107",
    "district_id": "912203",
    "label": "Wiwulik",
    "value": "Wiwulik"
  },
  {
    "id": "9122032108",
    "district_id": "912203",
    "label": "Somohi",
    "value": "Somohi"
  },
  {
    "id": "9122042001",
    "district_id": "912204",
    "label": "Gilika",
    "value": "Gilika"
  },
  {
    "id": "9122042002",
    "district_id": "912204",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "9122042003",
    "district_id": "912204",
    "label": "Ampera",
    "value": "Ampera"
  },
  {
    "id": "9122042004",
    "district_id": "912204",
    "label": "Pepera",
    "value": "Pepera"
  },
  {
    "id": "9122042005",
    "district_id": "912204",
    "label": "Naira",
    "value": "Naira"
  },
  {
    "id": "9122042006",
    "district_id": "912204",
    "label": "Siwa",
    "value": "Siwa"
  },
  {
    "id": "9122042007",
    "district_id": "912204",
    "label": "Tiwayi",
    "value": "Tiwayi"
  },
  {
    "id": "9122042008",
    "district_id": "912204",
    "label": "Kutakuruk",
    "value": "Kutakuruk"
  },
  {
    "id": "9122042009",
    "district_id": "912204",
    "label": "Narau",
    "value": "Narau"
  },
  {
    "id": "9122042010",
    "district_id": "912204",
    "label": "Neigase",
    "value": "Neigase"
  },
  {
    "id": "9122042011",
    "district_id": "912204",
    "label": "Martaru",
    "value": "Martaru"
  },
  {
    "id": "9122042012",
    "district_id": "912204",
    "label": "Himi",
    "value": "Himi"
  },
  {
    "id": "9122042013",
    "district_id": "912204",
    "label": "Nungkere",
    "value": "Nungkere"
  },
  {
    "id": "9122042014",
    "district_id": "912204",
    "label": "Puruku",
    "value": "Puruku"
  },
  {
    "id": "9122042015",
    "district_id": "912204",
    "label": "Paluke",
    "value": "Paluke"
  },
  {
    "id": "9122042016",
    "district_id": "912204",
    "label": "Lulum",
    "value": "Lulum"
  },
  {
    "id": "9122042017",
    "district_id": "912204",
    "label": "Pensale",
    "value": "Pensale"
  },
  {
    "id": "9122042018",
    "district_id": "912204",
    "label": "Iyap",
    "value": "Iyap"
  },
  {
    "id": "9122042019",
    "district_id": "912204",
    "label": "Wibi",
    "value": "Wibi"
  },
  {
    "id": "9122042020",
    "district_id": "912204",
    "label": "Konobun",
    "value": "Konobun"
  },
  {
    "id": "9122042021",
    "district_id": "912204",
    "label": "Wirsa",
    "value": "Wirsa"
  },
  {
    "id": "9122042022",
    "district_id": "912204",
    "label": "Karamina",
    "value": "Karamina"
  },
  {
    "id": "9122042023",
    "district_id": "912204",
    "label": "Dukunasi",
    "value": "Dukunasi"
  },
  {
    "id": "9122042024",
    "district_id": "912204",
    "label": "Thamaksin",
    "value": "Thamaksin"
  },
  {
    "id": "9122042025",
    "district_id": "912204",
    "label": "Kamika",
    "value": "Kamika"
  },
  {
    "id": "9122042026",
    "district_id": "912204",
    "label": "Uramburu",
    "value": "Uramburu"
  },
  {
    "id": "9122042027",
    "district_id": "912204",
    "label": "Kapawa",
    "value": "Kapawa"
  },
  {
    "id": "9122042028",
    "district_id": "912204",
    "label": "Miyawi",
    "value": "Miyawi"
  },
  {
    "id": "9122042029",
    "district_id": "912204",
    "label": "Yakwa",
    "value": "Yakwa"
  },
  {
    "id": "9122042030",
    "district_id": "912204",
    "label": "Wermas",
    "value": "Wermas"
  },
  {
    "id": "9122042031",
    "district_id": "912204",
    "label": "Makrip",
    "value": "Makrip"
  },
  {
    "id": "9122042032",
    "district_id": "912204",
    "label": "Ikon",
    "value": "Ikon"
  },
  {
    "id": "9122042033",
    "district_id": "912204",
    "label": "Key",
    "value": "Key"
  },
  {
    "id": "9122042034",
    "district_id": "912204",
    "label": "Lawe",
    "value": "Lawe"
  },
  {
    "id": "9122042035",
    "district_id": "912204",
    "label": "Kukdomol",
    "value": "Kukdomol"
  },
  {
    "id": "9122042036",
    "district_id": "912204",
    "label": "Nihol",
    "value": "Nihol"
  },
  {
    "id": "9122042037",
    "district_id": "912204",
    "label": "Nuorok",
    "value": "Nuorok"
  },
  {
    "id": "9122052001",
    "district_id": "912205",
    "label": "Welarek",
    "value": "Welarek"
  },
  {
    "id": "9122052002",
    "district_id": "912205",
    "label": "Werenggik",
    "value": "Werenggik"
  },
  {
    "id": "9122052003",
    "district_id": "912205",
    "label": "Saly",
    "value": "Saly"
  },
  {
    "id": "9122052004",
    "district_id": "912205",
    "label": "Mabualem",
    "value": "Mabualem"
  },
  {
    "id": "9122052005",
    "district_id": "912205",
    "label": "Pisanggo",
    "value": "Pisanggo"
  },
  {
    "id": "9122052006",
    "district_id": "912205",
    "label": "Welarekpunu",
    "value": "Welarekpunu"
  },
  {
    "id": "9122052007",
    "district_id": "912205",
    "label": "Hindalimuhuk",
    "value": "Hindalimuhuk"
  },
  {
    "id": "9122052008",
    "district_id": "912205",
    "label": "Menepini",
    "value": "Menepini"
  },
  {
    "id": "9122052009",
    "district_id": "912205",
    "label": "Soharam",
    "value": "Soharam"
  },
  {
    "id": "9122052010",
    "district_id": "912205",
    "label": "Kampol",
    "value": "Kampol"
  },
  {
    "id": "9122052011",
    "district_id": "912205",
    "label": "Tahamak",
    "value": "Tahamak"
  },
  {
    "id": "9122052012",
    "district_id": "912205",
    "label": "Punui",
    "value": "Punui"
  },
  {
    "id": "9122052013",
    "district_id": "912205",
    "label": "Sakam",
    "value": "Sakam"
  },
  {
    "id": "9122052014",
    "district_id": "912205",
    "label": "Sinahal",
    "value": "Sinahal"
  },
  {
    "id": "9122052015",
    "district_id": "912205",
    "label": "Samaria",
    "value": "Samaria"
  },
  {
    "id": "9122052016",
    "district_id": "912205",
    "label": "Hamboik",
    "value": "Hamboik"
  },
  {
    "id": "9122052017",
    "district_id": "912205",
    "label": "Mohobiye",
    "value": "Mohobiye"
  },
  {
    "id": "9122052018",
    "district_id": "912205",
    "label": "Halialo",
    "value": "Halialo"
  },
  {
    "id": "9122052019",
    "district_id": "912205",
    "label": "Walingkapma",
    "value": "Walingkapma"
  },
  {
    "id": "9122052020",
    "district_id": "912205",
    "label": "Kayalem",
    "value": "Kayalem"
  },
  {
    "id": "9122052021",
    "district_id": "912205",
    "label": "Seherek",
    "value": "Seherek"
  },
  {
    "id": "9122052022",
    "district_id": "912205",
    "label": "Halisek",
    "value": "Halisek"
  },
  {
    "id": "9122052023",
    "district_id": "912205",
    "label": "Wompoli",
    "value": "Wompoli"
  },
  {
    "id": "9122052024",
    "district_id": "912205",
    "label": "Fikfak",
    "value": "Fikfak"
  },
  {
    "id": "9122052025",
    "district_id": "912205",
    "label": "Mohonu",
    "value": "Mohonu"
  },
  {
    "id": "9122052026",
    "district_id": "912205",
    "label": "Pikohofari",
    "value": "Pikohofari"
  },
  {
    "id": "9122052027",
    "district_id": "912205",
    "label": "Feingkama",
    "value": "Feingkama"
  },
  {
    "id": "9122052028",
    "district_id": "912205",
    "label": "Holowi",
    "value": "Holowi"
  },
  {
    "id": "9122052029",
    "district_id": "912205",
    "label": "Poik",
    "value": "Poik"
  },
  {
    "id": "9122052030",
    "district_id": "912205",
    "label": "Koum",
    "value": "Koum"
  },
  {
    "id": "9122052031",
    "district_id": "912205",
    "label": "Hobut",
    "value": "Hobut"
  },
  {
    "id": "9122052032",
    "district_id": "912205",
    "label": "Mondek",
    "value": "Mondek"
  },
  {
    "id": "9122052033",
    "district_id": "912205",
    "label": "Mamion",
    "value": "Mamion"
  },
  {
    "id": "9122052034",
    "district_id": "912205",
    "label": "Selek",
    "value": "Selek"
  },
  {
    "id": "9122052035",
    "district_id": "912205",
    "label": "Ambiliki",
    "value": "Ambiliki"
  },
  {
    "id": "9122052036",
    "district_id": "912205",
    "label": "Ubalihi",
    "value": "Ubalihi"
  },
  {
    "id": "9122052038",
    "district_id": "912205",
    "label": "Salema",
    "value": "Salema"
  },
  {
    "id": "9122052039",
    "district_id": "912205",
    "label": "Selebi",
    "value": "Selebi"
  },
  {
    "id": "9122052040",
    "district_id": "912205",
    "label": "Langam",
    "value": "Langam"
  },
  {
    "id": "9122052041",
    "district_id": "912205",
    "label": "Pami",
    "value": "Pami"
  },
  {
    "id": "9122052042",
    "district_id": "912205",
    "label": "Pirang",
    "value": "Pirang"
  },
  {
    "id": "9122052043",
    "district_id": "912205",
    "label": "Poholanggen",
    "value": "Poholanggen"
  },
  {
    "id": "9122052044",
    "district_id": "912205",
    "label": "Nangkulamulan",
    "value": "Nangkulamulan"
  },
  {
    "id": "9122052045",
    "district_id": "912205",
    "label": "Irarek",
    "value": "Irarek"
  },
  {
    "id": "9122052046",
    "district_id": "912205",
    "label": "Ampolongsili",
    "value": "Ampolongsili"
  },
  {
    "id": "9122052047",
    "district_id": "912205",
    "label": "Sumtan",
    "value": "Sumtan"
  },
  {
    "id": "9122052048",
    "district_id": "912205",
    "label": "Ulum",
    "value": "Ulum"
  },
  {
    "id": "9122052049",
    "district_id": "912205",
    "label": "Kayo",
    "value": "Kayo"
  },
  {
    "id": "9122052050",
    "district_id": "912205",
    "label": "Panal",
    "value": "Panal"
  },
  {
    "id": "9122052051",
    "district_id": "912205",
    "label": "Hilariki",
    "value": "Hilariki"
  },
  {
    "id": "9122052052",
    "district_id": "912205",
    "label": "Tinmuhuk",
    "value": "Tinmuhuk"
  },
  {
    "id": "9122052053",
    "district_id": "912205",
    "label": "Yahamer",
    "value": "Yahamer"
  },
  {
    "id": "9122052054",
    "district_id": "912205",
    "label": "Helebol",
    "value": "Helebol"
  },
  {
    "id": "9122052055",
    "district_id": "912205",
    "label": "Silfal",
    "value": "Silfal"
  },
  {
    "id": "9122052056",
    "district_id": "912205",
    "label": "Sukalimi",
    "value": "Sukalimi"
  },
  {
    "id": "9122052057",
    "district_id": "912205",
    "label": "Nohonil",
    "value": "Nohonil"
  },
  {
    "id": "9122052058",
    "district_id": "912205",
    "label": "Lasik",
    "value": "Lasik"
  },
  {
    "id": "9122052059",
    "district_id": "912205",
    "label": "Panalulun",
    "value": "Panalulun"
  },
  {
    "id": "9122052060",
    "district_id": "912205",
    "label": "Wasupahik",
    "value": "Wasupahik"
  },
  {
    "id": "9122052061",
    "district_id": "912205",
    "label": "Folongsili",
    "value": "Folongsili"
  },
  {
    "id": "9122052062",
    "district_id": "912205",
    "label": "Amuluk",
    "value": "Amuluk"
  },
  {
    "id": "9123011001",
    "district_id": "912301",
    "label": "Bokon",
    "value": "Bokon"
  },
  {
    "id": "9123012003",
    "district_id": "912301",
    "label": "Oyi",
    "value": "Oyi"
  },
  {
    "id": "9123012010",
    "district_id": "912301",
    "label": "Gurika",
    "value": "Gurika"
  },
  {
    "id": "9123012011",
    "district_id": "912301",
    "label": "Olume",
    "value": "Olume"
  },
  {
    "id": "9123012012",
    "district_id": "912301",
    "label": "Dura",
    "value": "Dura"
  },
  {
    "id": "9123012020",
    "district_id": "912301",
    "label": "Langgalo",
    "value": "Langgalo"
  },
  {
    "id": "9123012022",
    "district_id": "912301",
    "label": "Kuapur",
    "value": "Kuapur"
  },
  {
    "id": "9123012023",
    "district_id": "912301",
    "label": "Wadinalome",
    "value": "Wadinalome"
  },
  {
    "id": "9123012024",
    "district_id": "912301",
    "label": "Yilondum",
    "value": "Yilondum"
  },
  {
    "id": "9123012025",
    "district_id": "912301",
    "label": "Palunggame",
    "value": "Palunggame"
  },
  {
    "id": "9123012026",
    "district_id": "912301",
    "label": "Ginime",
    "value": "Ginime"
  },
  {
    "id": "9123022002",
    "district_id": "912302",
    "label": "Umbanume",
    "value": "Umbanume"
  },
  {
    "id": "9123022008",
    "district_id": "912302",
    "label": "Wenam",
    "value": "Wenam"
  },
  {
    "id": "9123022012",
    "district_id": "912302",
    "label": "Yalipak",
    "value": "Yalipak"
  },
  {
    "id": "9123022013",
    "district_id": "912302",
    "label": "Ekanom",
    "value": "Ekanom"
  },
  {
    "id": "9123022029",
    "district_id": "912302",
    "label": "Yugumbinik",
    "value": "Yugumbinik"
  },
  {
    "id": "9123022038",
    "district_id": "912302",
    "label": "Amberingime",
    "value": "Amberingime"
  },
  {
    "id": "9123022046",
    "district_id": "912302",
    "label": "Aniwo",
    "value": "Aniwo"
  },
  {
    "id": "9123022047",
    "district_id": "912302",
    "label": "Bugumbak",
    "value": "Bugumbak"
  },
  {
    "id": "9123032001",
    "district_id": "912303",
    "label": "Kemiri",
    "value": "Kemiri"
  },
  {
    "id": "9123032016",
    "district_id": "912303",
    "label": "Mamiri",
    "value": "Mamiri"
  },
  {
    "id": "9123032023",
    "district_id": "912303",
    "label": "Kotorambur",
    "value": "Kotorambur"
  },
  {
    "id": "9123032024",
    "district_id": "912303",
    "label": "Yorenime",
    "value": "Yorenime"
  },
  {
    "id": "9123032025",
    "district_id": "912303",
    "label": "Waganenga",
    "value": "Waganenga"
  },
  {
    "id": "9123032052",
    "district_id": "912303",
    "label": "Gembilani",
    "value": "Gembilani"
  },
  {
    "id": "9123032053",
    "district_id": "912303",
    "label": "Nambume",
    "value": "Nambume"
  },
  {
    "id": "9123032054",
    "district_id": "912303",
    "label": "Tengenawi",
    "value": "Tengenawi"
  },
  {
    "id": "9123042001",
    "district_id": "912304",
    "label": "Gamelia",
    "value": "Gamelia"
  },
  {
    "id": "9123042010",
    "district_id": "912304",
    "label": "Pirawun",
    "value": "Pirawun"
  },
  {
    "id": "9123042011",
    "district_id": "912304",
    "label": "Wupi",
    "value": "Wupi"
  },
  {
    "id": "9123042013",
    "district_id": "912304",
    "label": "Gawiwarak",
    "value": "Gawiwarak"
  },
  {
    "id": "9123042014",
    "district_id": "912304",
    "label": "Meyagale",
    "value": "Meyagale"
  },
  {
    "id": "9123042015",
    "district_id": "912304",
    "label": "Wulaipas",
    "value": "Wulaipas"
  },
  {
    "id": "9123042016",
    "district_id": "912304",
    "label": "Gunagewak",
    "value": "Gunagewak"
  },
  {
    "id": "9123042017",
    "district_id": "912304",
    "label": "Wunume",
    "value": "Wunume"
  },
  {
    "id": "9123052001",
    "district_id": "912305",
    "label": "Dimba",
    "value": "Dimba"
  },
  {
    "id": "9123052006",
    "district_id": "912305",
    "label": "Yugwa",
    "value": "Yugwa"
  },
  {
    "id": "9123052008",
    "district_id": "912305",
    "label": "Magegobak",
    "value": "Magegobak"
  },
  {
    "id": "9123052010",
    "district_id": "912305",
    "label": "Nongopnage",
    "value": "Nongopnage"
  },
  {
    "id": "9123052011",
    "district_id": "912305",
    "label": "Wengenambur",
    "value": "Wengenambur"
  },
  {
    "id": "9123052012",
    "district_id": "912305",
    "label": "Wawiragi",
    "value": "Wawiragi"
  },
  {
    "id": "9123052013",
    "district_id": "912305",
    "label": "Mewilangun",
    "value": "Mewilangun"
  },
  {
    "id": "9123052014",
    "district_id": "912305",
    "label": "Talibarenak",
    "value": "Talibarenak"
  },
  {
    "id": "9123062001",
    "district_id": "912306",
    "label": "Malagai",
    "value": "Malagai"
  },
  {
    "id": "9123062003",
    "district_id": "912306",
    "label": "Oka",
    "value": "Oka"
  },
  {
    "id": "9123062005",
    "district_id": "912306",
    "label": "Bagi",
    "value": "Bagi"
  },
  {
    "id": "9123062006",
    "district_id": "912306",
    "label": "Ganume",
    "value": "Ganume"
  },
  {
    "id": "9123062007",
    "district_id": "912306",
    "label": "Binilani",
    "value": "Binilani"
  },
  {
    "id": "9123062008",
    "district_id": "912306",
    "label": "Gumban",
    "value": "Gumban"
  },
  {
    "id": "9123062009",
    "district_id": "912306",
    "label": "Lowanom",
    "value": "Lowanom"
  },
  {
    "id": "9123062010",
    "district_id": "912306",
    "label": "Nambu",
    "value": "Nambu"
  },
  {
    "id": "9123072006",
    "district_id": "912307",
    "label": "Tima",
    "value": "Tima"
  },
  {
    "id": "9123072007",
    "district_id": "912307",
    "label": "Balime",
    "value": "Balime"
  },
  {
    "id": "9123072008",
    "district_id": "912307",
    "label": "Balingga",
    "value": "Balingga"
  },
  {
    "id": "9123072010",
    "district_id": "912307",
    "label": "Gume",
    "value": "Gume"
  },
  {
    "id": "9123072017",
    "district_id": "912307",
    "label": "Timonikime",
    "value": "Timonikime"
  },
  {
    "id": "9123072018",
    "district_id": "912307",
    "label": "Binolanggen",
    "value": "Binolanggen"
  },
  {
    "id": "9123072019",
    "district_id": "912307",
    "label": "Dewaka",
    "value": "Dewaka"
  },
  {
    "id": "9123072020",
    "district_id": "912307",
    "label": "Yialo",
    "value": "Yialo"
  },
  {
    "id": "9123072021",
    "district_id": "912307",
    "label": "Towewak",
    "value": "Towewak"
  },
  {
    "id": "9123072022",
    "district_id": "912307",
    "label": "Yumaneri",
    "value": "Yumaneri"
  },
  {
    "id": "9123082001",
    "district_id": "912308",
    "label": "Kuwobaga",
    "value": "Kuwobaga"
  },
  {
    "id": "9123082002",
    "district_id": "912308",
    "label": "Milinggame",
    "value": "Milinggame"
  },
  {
    "id": "9123082005",
    "district_id": "912308",
    "label": "Arigineri",
    "value": "Arigineri"
  },
  {
    "id": "9123082015",
    "district_id": "912308",
    "label": "Muleme",
    "value": "Muleme"
  },
  {
    "id": "9123082016",
    "district_id": "912308",
    "label": "Arungwi",
    "value": "Arungwi"
  },
  {
    "id": "9123082020",
    "district_id": "912308",
    "label": "Ponuma",
    "value": "Ponuma"
  },
  {
    "id": "9123082021",
    "district_id": "912308",
    "label": "Gubulela",
    "value": "Gubulela"
  },
  {
    "id": "9123082022",
    "district_id": "912308",
    "label": "Kolari",
    "value": "Kolari"
  },
  {
    "id": "9123082027",
    "district_id": "912308",
    "label": "Gumbo",
    "value": "Gumbo"
  },
  {
    "id": "9123092001",
    "district_id": "912309",
    "label": "Kuyawage",
    "value": "Kuyawage"
  },
  {
    "id": "9123092005",
    "district_id": "912309",
    "label": "Wupaga",
    "value": "Wupaga"
  },
  {
    "id": "9123092008",
    "district_id": "912309",
    "label": "Luarem",
    "value": "Luarem"
  },
  {
    "id": "9123092011",
    "district_id": "912309",
    "label": "Tumbupur",
    "value": "Tumbupur"
  },
  {
    "id": "9123092012",
    "district_id": "912309",
    "label": "Uwome",
    "value": "Uwome"
  },
  {
    "id": "9123092013",
    "district_id": "912309",
    "label": "Tunikele",
    "value": "Tunikele"
  },
  {
    "id": "9123092014",
    "district_id": "912309",
    "label": "Peko",
    "value": "Peko"
  },
  {
    "id": "9123092015",
    "district_id": "912309",
    "label": "Yugunomba",
    "value": "Yugunomba"
  },
  {
    "id": "9123102001",
    "district_id": "912310",
    "label": "Megalunik",
    "value": "Megalunik"
  },
  {
    "id": "9123102002",
    "district_id": "912310",
    "label": "Lugobak",
    "value": "Lugobak"
  },
  {
    "id": "9123102003",
    "district_id": "912310",
    "label": "Kanikbukme",
    "value": "Kanikbukme"
  },
  {
    "id": "9123102006",
    "district_id": "912310",
    "label": "Poga",
    "value": "Poga"
  },
  {
    "id": "9123102007",
    "district_id": "912310",
    "label": "Bigipaga",
    "value": "Bigipaga"
  },
  {
    "id": "9123102011",
    "district_id": "912310",
    "label": "Luneri",
    "value": "Luneri"
  },
  {
    "id": "9123102012",
    "district_id": "912310",
    "label": "Binipaga",
    "value": "Binipaga"
  },
  {
    "id": "9123102013",
    "district_id": "912310",
    "label": "Mugime",
    "value": "Mugime"
  },
  {
    "id": "9123102014",
    "district_id": "912310",
    "label": "Brigme",
    "value": "Brigme"
  },
  {
    "id": "9123102015",
    "district_id": "912310",
    "label": "Unikme",
    "value": "Unikme"
  },
  {
    "id": "9123102016",
    "district_id": "912310",
    "label": "Binompaga",
    "value": "Binompaga"
  },
  {
    "id": "9123102017",
    "district_id": "912310",
    "label": "Lugwa",
    "value": "Lugwa"
  },
  {
    "id": "9123102018",
    "district_id": "912310",
    "label": "Guaneri",
    "value": "Guaneri"
  },
  {
    "id": "9123112001",
    "district_id": "912311",
    "label": "Dugume",
    "value": "Dugume"
  },
  {
    "id": "9123112002",
    "district_id": "912311",
    "label": "Wulundia",
    "value": "Wulundia"
  },
  {
    "id": "9123112003",
    "district_id": "912311",
    "label": "Dapogi",
    "value": "Dapogi"
  },
  {
    "id": "9123112004",
    "district_id": "912311",
    "label": "Kulumburu",
    "value": "Kulumburu"
  },
  {
    "id": "9123112005",
    "district_id": "912311",
    "label": "Molobok",
    "value": "Molobok"
  },
  {
    "id": "9123112006",
    "district_id": "912311",
    "label": "Tugunakwi",
    "value": "Tugunakwi"
  },
  {
    "id": "9123112007",
    "district_id": "912311",
    "label": "Yanuru",
    "value": "Yanuru"
  },
  {
    "id": "9123122001",
    "district_id": "912312",
    "label": "Yirene",
    "value": "Yirene"
  },
  {
    "id": "9123122002",
    "district_id": "912312",
    "label": "Yogobak",
    "value": "Yogobak"
  },
  {
    "id": "9123122003",
    "district_id": "912312",
    "label": "Kwenukwi",
    "value": "Kwenukwi"
  },
  {
    "id": "9123122004",
    "district_id": "912312",
    "label": "Libungga",
    "value": "Libungga"
  },
  {
    "id": "9123122005",
    "district_id": "912312",
    "label": "Unom",
    "value": "Unom"
  },
  {
    "id": "9123122006",
    "district_id": "912312",
    "label": "Wimolome",
    "value": "Wimolome"
  },
  {
    "id": "9123122007",
    "district_id": "912312",
    "label": "Weneogun",
    "value": "Weneogun"
  },
  {
    "id": "9123122008",
    "district_id": "912312",
    "label": "Berangwi",
    "value": "Berangwi"
  },
  {
    "id": "9123132001",
    "district_id": "912313",
    "label": "Tepogi",
    "value": "Tepogi"
  },
  {
    "id": "9123132002",
    "district_id": "912313",
    "label": "Ninabua",
    "value": "Ninabua"
  },
  {
    "id": "9123132003",
    "district_id": "912313",
    "label": "Golikme",
    "value": "Golikme"
  },
  {
    "id": "9123132004",
    "district_id": "912313",
    "label": "Ninengwa",
    "value": "Ninengwa"
  },
  {
    "id": "9123132005",
    "district_id": "912313",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "9123132006",
    "district_id": "912313",
    "label": "Gumagame",
    "value": "Gumagame"
  },
  {
    "id": "9123132007",
    "district_id": "912313",
    "label": "Abua",
    "value": "Abua"
  },
  {
    "id": "9123142001",
    "district_id": "912314",
    "label": "Kumuluk",
    "value": "Kumuluk"
  },
  {
    "id": "9123142002",
    "district_id": "912314",
    "label": "Giari",
    "value": "Giari"
  },
  {
    "id": "9123142003",
    "district_id": "912314",
    "label": "Bonanip",
    "value": "Bonanip"
  },
  {
    "id": "9123142004",
    "district_id": "912314",
    "label": "Kukepake",
    "value": "Kukepake"
  },
  {
    "id": "9123142005",
    "district_id": "912314",
    "label": "Wayuleme",
    "value": "Wayuleme"
  },
  {
    "id": "9123142006",
    "district_id": "912314",
    "label": "Bogomanum",
    "value": "Bogomanum"
  },
  {
    "id": "9123142007",
    "district_id": "912314",
    "label": "Pindoak",
    "value": "Pindoak"
  },
  {
    "id": "9123142008",
    "district_id": "912314",
    "label": "Kotorambur",
    "value": "Kotorambur"
  },
  {
    "id": "9123142009",
    "district_id": "912314",
    "label": "Numbo",
    "value": "Numbo"
  },
  {
    "id": "9123142010",
    "district_id": "912314",
    "label": "Lambui",
    "value": "Lambui"
  },
  {
    "id": "9123152001",
    "district_id": "912315",
    "label": "Konikme",
    "value": "Konikme"
  },
  {
    "id": "9123152002",
    "district_id": "912315",
    "label": "Giwan",
    "value": "Giwan"
  },
  {
    "id": "9123152003",
    "district_id": "912315",
    "label": "Lugom",
    "value": "Lugom"
  },
  {
    "id": "9123152004",
    "district_id": "912315",
    "label": "Mabume",
    "value": "Mabume"
  },
  {
    "id": "9123152005",
    "district_id": "912315",
    "label": "Wandoak",
    "value": "Wandoak"
  },
  {
    "id": "9123152006",
    "district_id": "912315",
    "label": "Bogun Kunik",
    "value": "Bogun Kunik"
  },
  {
    "id": "9123152007",
    "district_id": "912315",
    "label": "Urgelo",
    "value": "Urgelo"
  },
  {
    "id": "9123152008",
    "district_id": "912315",
    "label": "Omapaga",
    "value": "Omapaga"
  },
  {
    "id": "9123152009",
    "district_id": "912315",
    "label": "Gimili Alome",
    "value": "Gimili Alome"
  },
  {
    "id": "9123162001",
    "district_id": "912316",
    "label": "Guninggame",
    "value": "Guninggame"
  },
  {
    "id": "9123162002",
    "district_id": "912316",
    "label": "Mokoni",
    "value": "Mokoni"
  },
  {
    "id": "9123162003",
    "district_id": "912316",
    "label": "Popome",
    "value": "Popome"
  },
  {
    "id": "9123162004",
    "district_id": "912316",
    "label": "Wakumalo",
    "value": "Wakumalo"
  },
  {
    "id": "9123162005",
    "district_id": "912316",
    "label": "Bigipura",
    "value": "Bigipura"
  },
  {
    "id": "9123162006",
    "district_id": "912316",
    "label": "Yanggelome",
    "value": "Yanggelome"
  },
  {
    "id": "9123162007",
    "district_id": "912316",
    "label": "Wuyumbur",
    "value": "Wuyumbur"
  },
  {
    "id": "9123162008",
    "district_id": "912316",
    "label": "Kondename",
    "value": "Kondename"
  },
  {
    "id": "9123162009",
    "district_id": "912316",
    "label": "Bumalome",
    "value": "Bumalome"
  },
  {
    "id": "9123172001",
    "district_id": "912317",
    "label": "Tabukeker",
    "value": "Tabukeker"
  },
  {
    "id": "9123172002",
    "district_id": "912317",
    "label": "Ponalo",
    "value": "Ponalo"
  },
  {
    "id": "9123172003",
    "district_id": "912317",
    "label": "Timi",
    "value": "Timi"
  },
  {
    "id": "9123172004",
    "district_id": "912317",
    "label": "Wuragi",
    "value": "Wuragi"
  },
  {
    "id": "9123172005",
    "district_id": "912317",
    "label": "Tiwa",
    "value": "Tiwa"
  },
  {
    "id": "9123172006",
    "district_id": "912317",
    "label": "Dingun",
    "value": "Dingun"
  },
  {
    "id": "9123172007",
    "district_id": "912317",
    "label": "Lura Marah",
    "value": "Lura Marah"
  },
  {
    "id": "9123172008",
    "district_id": "912317",
    "label": "Pelek",
    "value": "Pelek"
  },
  {
    "id": "9123172009",
    "district_id": "912317",
    "label": "Beyongwi",
    "value": "Beyongwi"
  },
  {
    "id": "9123182001",
    "district_id": "912318",
    "label": "Milimbo",
    "value": "Milimbo"
  },
  {
    "id": "9123182002",
    "district_id": "912318",
    "label": "Takobak",
    "value": "Takobak"
  },
  {
    "id": "9123182003",
    "district_id": "912318",
    "label": "Kugame",
    "value": "Kugame"
  },
  {
    "id": "9123182004",
    "district_id": "912318",
    "label": "Wamindik",
    "value": "Wamindik"
  },
  {
    "id": "9123182005",
    "district_id": "912318",
    "label": "Kidoni",
    "value": "Kidoni"
  },
  {
    "id": "9123182006",
    "district_id": "912318",
    "label": "Jinok",
    "value": "Jinok"
  },
  {
    "id": "9123182007",
    "district_id": "912318",
    "label": "Wewolome",
    "value": "Wewolome"
  },
  {
    "id": "9123182008",
    "district_id": "912318",
    "label": "Umbename",
    "value": "Umbename"
  },
  {
    "id": "9123192001",
    "district_id": "912319",
    "label": "Yiwili",
    "value": "Yiwili"
  },
  {
    "id": "9123192002",
    "district_id": "912319",
    "label": "Golo",
    "value": "Golo"
  },
  {
    "id": "9123192003",
    "district_id": "912319",
    "label": "Golomi",
    "value": "Golomi"
  },
  {
    "id": "9123192004",
    "district_id": "912319",
    "label": "Yugumobur",
    "value": "Yugumobur"
  },
  {
    "id": "9123192005",
    "district_id": "912319",
    "label": "Milidi",
    "value": "Milidi"
  },
  {
    "id": "9123192006",
    "district_id": "912319",
    "label": "Uniwani",
    "value": "Uniwani"
  },
  {
    "id": "9123192007",
    "district_id": "912319",
    "label": "Wiringgambut",
    "value": "Wiringgambut"
  },
  {
    "id": "9123192008",
    "district_id": "912319",
    "label": "Wuluwebur",
    "value": "Wuluwebur"
  },
  {
    "id": "9123192009",
    "district_id": "912319",
    "label": "Kiludo",
    "value": "Kiludo"
  },
  {
    "id": "9123192010",
    "district_id": "912319",
    "label": "Giruwi",
    "value": "Giruwi"
  },
  {
    "id": "9123202001",
    "district_id": "912320",
    "label": "Golopura",
    "value": "Golopura"
  },
  {
    "id": "9123202002",
    "district_id": "912320",
    "label": "Wirini",
    "value": "Wirini"
  },
  {
    "id": "9123202003",
    "district_id": "912320",
    "label": "Nileme",
    "value": "Nileme"
  },
  {
    "id": "9123202004",
    "district_id": "912320",
    "label": "Libome",
    "value": "Libome"
  },
  {
    "id": "9123202005",
    "district_id": "912320",
    "label": "Kulia",
    "value": "Kulia"
  },
  {
    "id": "9123202006",
    "district_id": "912320",
    "label": "Yamiga",
    "value": "Yamiga"
  },
  {
    "id": "9123202007",
    "district_id": "912320",
    "label": "Gubugame",
    "value": "Gubugame"
  },
  {
    "id": "9123202008",
    "district_id": "912320",
    "label": "Kamenak",
    "value": "Kamenak"
  },
  {
    "id": "9123202009",
    "district_id": "912320",
    "label": "Perime",
    "value": "Perime"
  },
  {
    "id": "9123202010",
    "district_id": "912320",
    "label": "Wadinawi",
    "value": "Wadinawi"
  },
  {
    "id": "9123202011",
    "district_id": "912320",
    "label": "Nengguga",
    "value": "Nengguga"
  },
  {
    "id": "9123212001",
    "district_id": "912321",
    "label": "Nambume",
    "value": "Nambume"
  },
  {
    "id": "9123212002",
    "district_id": "912321",
    "label": "Indawa",
    "value": "Indawa"
  },
  {
    "id": "9123212003",
    "district_id": "912321",
    "label": "Eyuni",
    "value": "Eyuni"
  },
  {
    "id": "9123212004",
    "district_id": "912321",
    "label": "Yugumia",
    "value": "Yugumia"
  },
  {
    "id": "9123212005",
    "district_id": "912321",
    "label": "Dolinggame",
    "value": "Dolinggame"
  },
  {
    "id": "9123212006",
    "district_id": "912321",
    "label": "Tinggira",
    "value": "Tinggira"
  },
  {
    "id": "9123212007",
    "district_id": "912321",
    "label": "Wumuak",
    "value": "Wumuak"
  },
  {
    "id": "9123212008",
    "district_id": "912321",
    "label": "Andiaput",
    "value": "Andiaput"
  },
  {
    "id": "9123212009",
    "district_id": "912321",
    "label": "Wuragabur",
    "value": "Wuragabur"
  },
  {
    "id": "9123222001",
    "district_id": "912322",
    "label": "Anitila",
    "value": "Anitila"
  },
  {
    "id": "9123222002",
    "district_id": "912322",
    "label": "Nanim",
    "value": "Nanim"
  },
  {
    "id": "9123222003",
    "district_id": "912322",
    "label": "Lubutini",
    "value": "Lubutini"
  },
  {
    "id": "9123222004",
    "district_id": "912322",
    "label": "Tikome",
    "value": "Tikome"
  },
  {
    "id": "9123222006",
    "district_id": "912322",
    "label": "Tikoyowa",
    "value": "Tikoyowa"
  },
  {
    "id": "9123222007",
    "district_id": "912322",
    "label": "Bogogunik",
    "value": "Bogogunik"
  },
  {
    "id": "9123222008",
    "district_id": "912322",
    "label": "Wanggunom",
    "value": "Wanggunom"
  },
  {
    "id": "9123222009",
    "district_id": "912322",
    "label": "Nindonak",
    "value": "Nindonak"
  },
  {
    "id": "9123222010",
    "district_id": "912322",
    "label": "Luagame",
    "value": "Luagame"
  },
  {
    "id": "9123232001",
    "district_id": "912323",
    "label": "Wamiru",
    "value": "Wamiru"
  },
  {
    "id": "9123232002",
    "district_id": "912323",
    "label": "Dugu-dugu",
    "value": "Dugu-dugu"
  },
  {
    "id": "9123232003",
    "district_id": "912323",
    "label": "Andugume",
    "value": "Andugume"
  },
  {
    "id": "9123232004",
    "district_id": "912323",
    "label": "Tinime",
    "value": "Tinime"
  },
  {
    "id": "9123232005",
    "district_id": "912323",
    "label": "Mume",
    "value": "Mume"
  },
  {
    "id": "9123232006",
    "district_id": "912323",
    "label": "Nenggeya",
    "value": "Nenggeya"
  },
  {
    "id": "9123232007",
    "district_id": "912323",
    "label": "Tenawi",
    "value": "Tenawi"
  },
  {
    "id": "9123232008",
    "district_id": "912323",
    "label": "Kobanu",
    "value": "Kobanu"
  },
  {
    "id": "9123232009",
    "district_id": "912323",
    "label": "Piragi",
    "value": "Piragi"
  },
  {
    "id": "9123232010",
    "district_id": "912323",
    "label": "Nabei",
    "value": "Nabei"
  },
  {
    "id": "9123232011",
    "district_id": "912323",
    "label": "Mingga",
    "value": "Mingga"
  },
  {
    "id": "9123242001",
    "district_id": "912324",
    "label": "Wamitu",
    "value": "Wamitu"
  },
  {
    "id": "9123242002",
    "district_id": "912324",
    "label": "Balimneri",
    "value": "Balimneri"
  },
  {
    "id": "9123242003",
    "district_id": "912324",
    "label": "Timotani",
    "value": "Timotani"
  },
  {
    "id": "9123242004",
    "district_id": "912324",
    "label": "Kungganeri",
    "value": "Kungganeri"
  },
  {
    "id": "9123242005",
    "district_id": "912324",
    "label": "Gwilonik",
    "value": "Gwilonik"
  },
  {
    "id": "9123242006",
    "district_id": "912324",
    "label": "Ilim",
    "value": "Ilim"
  },
  {
    "id": "9123242007",
    "district_id": "912324",
    "label": "Dinuwi",
    "value": "Dinuwi"
  },
  {
    "id": "9123252001",
    "district_id": "912325",
    "label": "Bruyugu",
    "value": "Bruyugu"
  },
  {
    "id": "9123252002",
    "district_id": "912325",
    "label": "Wame",
    "value": "Wame"
  },
  {
    "id": "9123252003",
    "district_id": "912325",
    "label": "Ogodome",
    "value": "Ogodome"
  },
  {
    "id": "9123252004",
    "district_id": "912325",
    "label": "Ekaba",
    "value": "Ekaba"
  },
  {
    "id": "9123252005",
    "district_id": "912325",
    "label": "Longgapur",
    "value": "Longgapur"
  },
  {
    "id": "9123252006",
    "district_id": "912325",
    "label": "Tikuluk",
    "value": "Tikuluk"
  },
  {
    "id": "9123252007",
    "district_id": "912325",
    "label": "Birik",
    "value": "Birik"
  },
  {
    "id": "9123252008",
    "district_id": "912325",
    "label": "Yalogu",
    "value": "Yalogu"
  },
  {
    "id": "9123252009",
    "district_id": "912325",
    "label": "Wumbugi",
    "value": "Wumbugi"
  },
  {
    "id": "9123262001",
    "district_id": "912326",
    "label": "Tinggipura",
    "value": "Tinggipura"
  },
  {
    "id": "9123262002",
    "district_id": "912326",
    "label": "Yeyugu",
    "value": "Yeyugu"
  },
  {
    "id": "9123262003",
    "district_id": "912326",
    "label": "Yugume",
    "value": "Yugume"
  },
  {
    "id": "9123262004",
    "district_id": "912326",
    "label": "Baliempura",
    "value": "Baliempura"
  },
  {
    "id": "9123262005",
    "district_id": "912326",
    "label": "Denomagi",
    "value": "Denomagi"
  },
  {
    "id": "9123262006",
    "district_id": "912326",
    "label": "Magame",
    "value": "Magame"
  },
  {
    "id": "9123262007",
    "district_id": "912326",
    "label": "Lorea",
    "value": "Lorea"
  },
  {
    "id": "9123262008",
    "district_id": "912326",
    "label": "Anggumabume",
    "value": "Anggumabume"
  },
  {
    "id": "9123272001",
    "district_id": "912327",
    "label": "Lelam",
    "value": "Lelam"
  },
  {
    "id": "9123272002",
    "district_id": "912327",
    "label": "Teiko",
    "value": "Teiko"
  },
  {
    "id": "9123272003",
    "district_id": "912327",
    "label": "Yaneko",
    "value": "Yaneko"
  },
  {
    "id": "9123272004",
    "district_id": "912327",
    "label": "Kimbo",
    "value": "Kimbo"
  },
  {
    "id": "9123272005",
    "district_id": "912327",
    "label": "Penggima",
    "value": "Penggima"
  },
  {
    "id": "9123272006",
    "district_id": "912327",
    "label": "Magelo",
    "value": "Magelo"
  },
  {
    "id": "9123272007",
    "district_id": "912327",
    "label": "Onesia",
    "value": "Onesia"
  },
  {
    "id": "9123272008",
    "district_id": "912327",
    "label": "Wapur",
    "value": "Wapur"
  },
  {
    "id": "9123272009",
    "district_id": "912327",
    "label": "Andeyok",
    "value": "Andeyok"
  },
  {
    "id": "9123272010",
    "district_id": "912327",
    "label": "Kibingga",
    "value": "Kibingga"
  },
  {
    "id": "9123272011",
    "district_id": "912327",
    "label": "Ogobakni",
    "value": "Ogobakni"
  },
  {
    "id": "9123272012",
    "district_id": "912327",
    "label": "Jilam",
    "value": "Jilam"
  },
  {
    "id": "9123282001",
    "district_id": "912328",
    "label": "Ambena",
    "value": "Ambena"
  },
  {
    "id": "9123282002",
    "district_id": "912328",
    "label": "Kolayak",
    "value": "Kolayak"
  },
  {
    "id": "9123282003",
    "district_id": "912328",
    "label": "Wanuga",
    "value": "Wanuga"
  },
  {
    "id": "9123282004",
    "district_id": "912328",
    "label": "Labora",
    "value": "Labora"
  },
  {
    "id": "9123282005",
    "district_id": "912328",
    "label": "Kondena",
    "value": "Kondena"
  },
  {
    "id": "9123282006",
    "district_id": "912328",
    "label": "Kolunggu",
    "value": "Kolunggu"
  },
  {
    "id": "9123282007",
    "district_id": "912328",
    "label": "Yagarikme",
    "value": "Yagarikme"
  },
  {
    "id": "9123282008",
    "district_id": "912328",
    "label": "Timotius",
    "value": "Timotius"
  },
  {
    "id": "9123282009",
    "district_id": "912328",
    "label": "Gunumbur",
    "value": "Gunumbur"
  },
  {
    "id": "9123282010",
    "district_id": "912328",
    "label": "Lalugume",
    "value": "Lalugume"
  },
  {
    "id": "9123292001",
    "district_id": "912329",
    "label": "Pirambor",
    "value": "Pirambor"
  },
  {
    "id": "9123292002",
    "district_id": "912329",
    "label": "Gondura",
    "value": "Gondura"
  },
  {
    "id": "9123292003",
    "district_id": "912329",
    "label": "Juta",
    "value": "Juta"
  },
  {
    "id": "9123292004",
    "district_id": "912329",
    "label": "Tigima",
    "value": "Tigima"
  },
  {
    "id": "9123292005",
    "district_id": "912329",
    "label": "Gelok",
    "value": "Gelok"
  },
  {
    "id": "9123292006",
    "district_id": "912329",
    "label": "Murtani",
    "value": "Murtani"
  },
  {
    "id": "9123292007",
    "district_id": "912329",
    "label": "Beam",
    "value": "Beam"
  },
  {
    "id": "9123292008",
    "district_id": "912329",
    "label": "Wanggu",
    "value": "Wanggu"
  },
  {
    "id": "9123292009",
    "district_id": "912329",
    "label": "Kulip",
    "value": "Kulip"
  },
  {
    "id": "9123302001",
    "district_id": "912330",
    "label": "Indugu",
    "value": "Indugu"
  },
  {
    "id": "9123302002",
    "district_id": "912330",
    "label": "Guburini",
    "value": "Guburini"
  },
  {
    "id": "9123302003",
    "district_id": "912330",
    "label": "Jiwikelanny",
    "value": "Jiwikelanny"
  },
  {
    "id": "9123302004",
    "district_id": "912330",
    "label": "Nisimok",
    "value": "Nisimok"
  },
  {
    "id": "9123302005",
    "district_id": "912330",
    "label": "Yugume",
    "value": "Yugume"
  },
  {
    "id": "9123302006",
    "district_id": "912330",
    "label": "Piname",
    "value": "Piname"
  },
  {
    "id": "9123302007",
    "district_id": "912330",
    "label": "Londu",
    "value": "Londu"
  },
  {
    "id": "9123302008",
    "district_id": "912330",
    "label": "Kagaluwi",
    "value": "Kagaluwi"
  },
  {
    "id": "9123312001",
    "district_id": "912331",
    "label": "Bonom",
    "value": "Bonom"
  },
  {
    "id": "9123312002",
    "district_id": "912331",
    "label": "Kuwanom",
    "value": "Kuwanom"
  },
  {
    "id": "9123312003",
    "district_id": "912331",
    "label": "Kumulume",
    "value": "Kumulume"
  },
  {
    "id": "9123312004",
    "district_id": "912331",
    "label": "Uwagambur",
    "value": "Uwagambur"
  },
  {
    "id": "9123312005",
    "district_id": "912331",
    "label": "Lagame",
    "value": "Lagame"
  },
  {
    "id": "9123312006",
    "district_id": "912331",
    "label": "Kuwoge",
    "value": "Kuwoge"
  },
  {
    "id": "9123312007",
    "district_id": "912331",
    "label": "Laurapaga",
    "value": "Laurapaga"
  },
  {
    "id": "9123312008",
    "district_id": "912331",
    "label": "Bawi",
    "value": "Bawi"
  },
  {
    "id": "9123312009",
    "district_id": "912331",
    "label": "Yugimbo",
    "value": "Yugimbo"
  },
  {
    "id": "9123312010",
    "district_id": "912331",
    "label": "Tinggini",
    "value": "Tinggini"
  },
  {
    "id": "9123312011",
    "district_id": "912331",
    "label": "Ogin",
    "value": "Ogin"
  },
  {
    "id": "9123322001",
    "district_id": "912332",
    "label": "Ayafofa",
    "value": "Ayafofa"
  },
  {
    "id": "9123322002",
    "district_id": "912332",
    "label": "Salemo",
    "value": "Salemo"
  },
  {
    "id": "9123322003",
    "district_id": "912332",
    "label": "Yudani",
    "value": "Yudani"
  },
  {
    "id": "9123322004",
    "district_id": "912332",
    "label": "Towoluk",
    "value": "Towoluk"
  },
  {
    "id": "9123322005",
    "district_id": "912332",
    "label": "Tunume",
    "value": "Tunume"
  },
  {
    "id": "9123322006",
    "district_id": "912332",
    "label": "Delegari",
    "value": "Delegari"
  },
  {
    "id": "9123322007",
    "district_id": "912332",
    "label": "Igimbua",
    "value": "Igimbua"
  },
  {
    "id": "9123322008",
    "district_id": "912332",
    "label": "Yilokdu",
    "value": "Yilokdu"
  },
  {
    "id": "9123332001",
    "district_id": "912333",
    "label": "Odika",
    "value": "Odika"
  },
  {
    "id": "9123332002",
    "district_id": "912333",
    "label": "Yubumabur",
    "value": "Yubumabur"
  },
  {
    "id": "9123332003",
    "district_id": "912333",
    "label": "Ekapame",
    "value": "Ekapame"
  },
  {
    "id": "9123332004",
    "district_id": "912333",
    "label": "Papani",
    "value": "Papani"
  },
  {
    "id": "9123332005",
    "district_id": "912333",
    "label": "Kubagalo",
    "value": "Kubagalo"
  },
  {
    "id": "9123332006",
    "district_id": "912333",
    "label": "Jilekme",
    "value": "Jilekme"
  },
  {
    "id": "9123332007",
    "district_id": "912333",
    "label": "Wanome",
    "value": "Wanome"
  },
  {
    "id": "9123332008",
    "district_id": "912333",
    "label": "Yiluweneri",
    "value": "Yiluweneri"
  },
  {
    "id": "9123342001",
    "district_id": "912334",
    "label": "Guna",
    "value": "Guna"
  },
  {
    "id": "9123342002",
    "district_id": "912334",
    "label": "Gukopi",
    "value": "Gukopi"
  },
  {
    "id": "9123342003",
    "district_id": "912334",
    "label": "Kori",
    "value": "Kori"
  },
  {
    "id": "9123342004",
    "district_id": "912334",
    "label": "Keyagalo",
    "value": "Keyagalo"
  },
  {
    "id": "9123342005",
    "district_id": "912334",
    "label": "Nondinime",
    "value": "Nondinime"
  },
  {
    "id": "9123342006",
    "district_id": "912334",
    "label": "Wigume",
    "value": "Wigume"
  },
  {
    "id": "9123342007",
    "district_id": "912334",
    "label": "Piwugun",
    "value": "Piwugun"
  },
  {
    "id": "9123352001",
    "district_id": "912335",
    "label": "Kelulome",
    "value": "Kelulome"
  },
  {
    "id": "9123352002",
    "district_id": "912335",
    "label": "Wiyagi",
    "value": "Wiyagi"
  },
  {
    "id": "9123352003",
    "district_id": "912335",
    "label": "Talogi",
    "value": "Talogi"
  },
  {
    "id": "9123352004",
    "district_id": "912335",
    "label": "Wiringgame",
    "value": "Wiringgame"
  },
  {
    "id": "9123352005",
    "district_id": "912335",
    "label": "Wegenpura",
    "value": "Wegenpura"
  },
  {
    "id": "9123352006",
    "district_id": "912335",
    "label": "Ilunggijime",
    "value": "Ilunggijime"
  },
  {
    "id": "9123352007",
    "district_id": "912335",
    "label": "Juyiga",
    "value": "Juyiga"
  },
  {
    "id": "9123352008",
    "district_id": "912335",
    "label": "Peragime",
    "value": "Peragime"
  },
  {
    "id": "9123352009",
    "district_id": "912335",
    "label": "Muluneri",
    "value": "Muluneri"
  },
  {
    "id": "9123352010",
    "district_id": "912335",
    "label": "Lewinagi",
    "value": "Lewinagi"
  },
  {
    "id": "9123362001",
    "district_id": "912336",
    "label": "Wanggagome",
    "value": "Wanggagome"
  },
  {
    "id": "9123362002",
    "district_id": "912336",
    "label": "Langgime",
    "value": "Langgime"
  },
  {
    "id": "9123362003",
    "district_id": "912336",
    "label": "Wulawa",
    "value": "Wulawa"
  },
  {
    "id": "9123362004",
    "district_id": "912336",
    "label": "Pindalo",
    "value": "Pindalo"
  },
  {
    "id": "9123362005",
    "district_id": "912336",
    "label": "Kotorambur",
    "value": "Kotorambur"
  },
  {
    "id": "9123362006",
    "district_id": "912336",
    "label": "Ambime",
    "value": "Ambime"
  },
  {
    "id": "9123362007",
    "district_id": "912336",
    "label": "Wumbanakme",
    "value": "Wumbanakme"
  },
  {
    "id": "9123362008",
    "district_id": "912336",
    "label": "Yimiribaga",
    "value": "Yimiribaga"
  },
  {
    "id": "9123362009",
    "district_id": "912336",
    "label": "Elubaga",
    "value": "Elubaga"
  },
  {
    "id": "9123372001",
    "district_id": "912337",
    "label": "Muara",
    "value": "Muara"
  },
  {
    "id": "9123372002",
    "district_id": "912337",
    "label": "Lualo",
    "value": "Lualo"
  },
  {
    "id": "9123372003",
    "district_id": "912337",
    "label": "Abua",
    "value": "Abua"
  },
  {
    "id": "9123372004",
    "district_id": "912337",
    "label": "Gikur",
    "value": "Gikur"
  },
  {
    "id": "9123372005",
    "district_id": "912337",
    "label": "Gipura",
    "value": "Gipura"
  },
  {
    "id": "9123372006",
    "district_id": "912337",
    "label": "Gulu",
    "value": "Gulu"
  },
  {
    "id": "9123372007",
    "district_id": "912337",
    "label": "Bogangi",
    "value": "Bogangi"
  },
  {
    "id": "9123372008",
    "district_id": "912337",
    "label": "Golome",
    "value": "Golome"
  },
  {
    "id": "9123372009",
    "district_id": "912337",
    "label": "Wiyapur",
    "value": "Wiyapur"
  },
  {
    "id": "9123372010",
    "district_id": "912337",
    "label": "Kuruguneri",
    "value": "Kuruguneri"
  },
  {
    "id": "9123382001",
    "district_id": "912338",
    "label": "Kabiga",
    "value": "Kabiga"
  },
  {
    "id": "9123382002",
    "district_id": "912338",
    "label": "Tekul",
    "value": "Tekul"
  },
  {
    "id": "9123382003",
    "district_id": "912338",
    "label": "Melendik",
    "value": "Melendik"
  },
  {
    "id": "9123382004",
    "district_id": "912338",
    "label": "Pirime",
    "value": "Pirime"
  },
  {
    "id": "9123382005",
    "district_id": "912338",
    "label": "Karunggame",
    "value": "Karunggame"
  },
  {
    "id": "9123382006",
    "district_id": "912338",
    "label": "Ilunggime",
    "value": "Ilunggime"
  },
  {
    "id": "9123382007",
    "district_id": "912338",
    "label": "Timolome",
    "value": "Timolome"
  },
  {
    "id": "9123382008",
    "district_id": "912338",
    "label": "Wunime",
    "value": "Wunime"
  },
  {
    "id": "9123382009",
    "district_id": "912338",
    "label": "Genena",
    "value": "Genena"
  },
  {
    "id": "9123382010",
    "district_id": "912338",
    "label": "Kulogonggame",
    "value": "Kulogonggame"
  },
  {
    "id": "9123382011",
    "district_id": "912338",
    "label": "Kelonome",
    "value": "Kelonome"
  },
  {
    "id": "9123382012",
    "district_id": "912338",
    "label": "Dua Lanny",
    "value": "Dua Lanny"
  },
  {
    "id": "9123382013",
    "district_id": "912338",
    "label": "Dangguni",
    "value": "Dangguni"
  },
  {
    "id": "9123392001",
    "district_id": "912339",
    "label": "Wabiragi",
    "value": "Wabiragi"
  },
  {
    "id": "9123392002",
    "district_id": "912339",
    "label": "Yigemili",
    "value": "Yigemili"
  },
  {
    "id": "9123392003",
    "district_id": "912339",
    "label": "Gilo",
    "value": "Gilo"
  },
  {
    "id": "9123392004",
    "district_id": "912339",
    "label": "Kewagi",
    "value": "Kewagi"
  },
  {
    "id": "9123392005",
    "district_id": "912339",
    "label": "Nombome",
    "value": "Nombome"
  },
  {
    "id": "9123392006",
    "district_id": "912339",
    "label": "Numbokawi",
    "value": "Numbokawi"
  },
  {
    "id": "9123392007",
    "district_id": "912339",
    "label": "Mbu",
    "value": "Mbu"
  },
  {
    "id": "9123392008",
    "district_id": "912339",
    "label": "Wunabunggu",
    "value": "Wunabunggu"
  },
  {
    "id": "9124012001",
    "district_id": "912401",
    "label": "Kenyam",
    "value": "Kenyam"
  },
  {
    "id": "9124012008",
    "district_id": "912401",
    "label": "Kemali",
    "value": "Kemali"
  },
  {
    "id": "9124012012",
    "district_id": "912401",
    "label": "Sralala",
    "value": "Sralala"
  },
  {
    "id": "9124012013",
    "district_id": "912401",
    "label": "Delpel",
    "value": "Delpel"
  },
  {
    "id": "9124012014",
    "district_id": "912401",
    "label": "Yike",
    "value": "Yike"
  },
  {
    "id": "9124012015",
    "district_id": "912401",
    "label": "Yunat",
    "value": "Yunat"
  },
  {
    "id": "9124012016",
    "district_id": "912401",
    "label": "Dim",
    "value": "Dim"
  },
  {
    "id": "9124012017",
    "district_id": "912401",
    "label": "Ulunmu",
    "value": "Ulunmu"
  },
  {
    "id": "9124012029",
    "district_id": "912401",
    "label": "Bombam",
    "value": "Bombam"
  },
  {
    "id": "9124022001",
    "district_id": "912402",
    "label": "Mapenduma",
    "value": "Mapenduma"
  },
  {
    "id": "9124022005",
    "district_id": "912402",
    "label": "Duma",
    "value": "Duma"
  },
  {
    "id": "9124022006",
    "district_id": "912402",
    "label": "Yoe",
    "value": "Yoe"
  },
  {
    "id": "9124022007",
    "district_id": "912402",
    "label": "Palsam",
    "value": "Palsam"
  },
  {
    "id": "9124022008",
    "district_id": "912402",
    "label": "Endumu",
    "value": "Endumu"
  },
  {
    "id": "9124022009",
    "district_id": "912402",
    "label": "Litkui",
    "value": "Litkui"
  },
  {
    "id": "9124022010",
    "district_id": "912402",
    "label": "Nowomlusu",
    "value": "Nowomlusu"
  },
  {
    "id": "9124022011",
    "district_id": "912402",
    "label": "Kelapa Dua",
    "value": "Kelapa Dua"
  },
  {
    "id": "9124022012",
    "district_id": "912402",
    "label": "Dumdum",
    "value": "Dumdum"
  },
  {
    "id": "9124022013",
    "district_id": "912402",
    "label": "Lunggadumu",
    "value": "Lunggadumu"
  },
  {
    "id": "9124032001",
    "district_id": "912403",
    "label": "Yigi",
    "value": "Yigi"
  },
  {
    "id": "9124032004",
    "district_id": "912403",
    "label": "Ekilapo",
    "value": "Ekilapo"
  },
  {
    "id": "9124032009",
    "district_id": "912403",
    "label": "Suwenem",
    "value": "Suwenem"
  },
  {
    "id": "9124032010",
    "district_id": "912403",
    "label": "Karunggame",
    "value": "Karunggame"
  },
  {
    "id": "9124032011",
    "district_id": "912403",
    "label": "Sirit",
    "value": "Sirit"
  },
  {
    "id": "9124032012",
    "district_id": "912403",
    "label": "Disendobo",
    "value": "Disendobo"
  },
  {
    "id": "9124032013",
    "district_id": "912403",
    "label": "Sumbo",
    "value": "Sumbo"
  },
  {
    "id": "9124032014",
    "district_id": "912403",
    "label": "Yebadolma",
    "value": "Yebadolma"
  },
  {
    "id": "9124032022",
    "district_id": "912403",
    "label": "Lumbukdumu",
    "value": "Lumbukdumu"
  },
  {
    "id": "9124032023",
    "district_id": "912403",
    "label": "Wiritlak",
    "value": "Wiritlak"
  },
  {
    "id": "9124032024",
    "district_id": "912403",
    "label": "Delsa",
    "value": "Delsa"
  },
  {
    "id": "9124032025",
    "district_id": "912403",
    "label": "Borot",
    "value": "Borot"
  },
  {
    "id": "9124042003",
    "district_id": "912404",
    "label": "Pilini",
    "value": "Pilini"
  },
  {
    "id": "9124042004",
    "district_id": "912404",
    "label": "Ndugwa",
    "value": "Ndugwa"
  },
  {
    "id": "9124042016",
    "district_id": "912404",
    "label": "Biala",
    "value": "Biala"
  },
  {
    "id": "9124042017",
    "district_id": "912404",
    "label": "Bambisik",
    "value": "Bambisik"
  },
  {
    "id": "9124042018",
    "district_id": "912404",
    "label": "Sitpogol",
    "value": "Sitpogol"
  },
  {
    "id": "9124052001",
    "district_id": "912405",
    "label": "Geselma",
    "value": "Geselma"
  },
  {
    "id": "9124052006",
    "district_id": "912405",
    "label": "Talem",
    "value": "Talem"
  },
  {
    "id": "9124052007",
    "district_id": "912405",
    "label": "Kosolpem",
    "value": "Kosolpem"
  },
  {
    "id": "9124062001",
    "district_id": "912406",
    "label": "Mugi",
    "value": "Mugi"
  },
  {
    "id": "9124062005",
    "district_id": "912406",
    "label": "Dondoklema",
    "value": "Dondoklema"
  },
  {
    "id": "9124062006",
    "district_id": "912406",
    "label": "Dobopem",
    "value": "Dobopem"
  },
  {
    "id": "9124062007",
    "district_id": "912406",
    "label": "Paru",
    "value": "Paru"
  },
  {
    "id": "9124062008",
    "district_id": "912406",
    "label": "Wundui",
    "value": "Wundui"
  },
  {
    "id": "9124062009",
    "district_id": "912406",
    "label": "Kemamburu",
    "value": "Kemamburu"
  },
  {
    "id": "9124062010",
    "district_id": "912406",
    "label": "Womsit",
    "value": "Womsit"
  },
  {
    "id": "9124062011",
    "district_id": "912406",
    "label": "Delit",
    "value": "Delit"
  },
  {
    "id": "9124062012",
    "district_id": "912406",
    "label": "Keret Anggoma",
    "value": "Keret Anggoma"
  },
  {
    "id": "9124062013",
    "district_id": "912406",
    "label": "Manet",
    "value": "Manet"
  },
  {
    "id": "9124062014",
    "district_id": "912406",
    "label": "Miri",
    "value": "Miri"
  },
  {
    "id": "9124062015",
    "district_id": "912406",
    "label": "Yelsengge",
    "value": "Yelsengge"
  },
  {
    "id": "9124062046",
    "district_id": "912406",
    "label": "Sarit",
    "value": "Sarit"
  },
  {
    "id": "9124062047",
    "district_id": "912406",
    "label": "Unue",
    "value": "Unue"
  },
  {
    "id": "9124062049",
    "district_id": "912406",
    "label": "Mirikil",
    "value": "Mirikil"
  },
  {
    "id": "9124062050",
    "district_id": "912406",
    "label": "Sambua",
    "value": "Sambua"
  },
  {
    "id": "9124062051",
    "district_id": "912406",
    "label": "Gisarok",
    "value": "Gisarok"
  },
  {
    "id": "9124062052",
    "district_id": "912406",
    "label": "Isiklak",
    "value": "Isiklak"
  },
  {
    "id": "9124072001",
    "district_id": "912407",
    "label": "Mbuwa",
    "value": "Mbuwa"
  },
  {
    "id": "9124072005",
    "district_id": "912407",
    "label": "Otalama",
    "value": "Otalama"
  },
  {
    "id": "9124072006",
    "district_id": "912407",
    "label": "Arugia",
    "value": "Arugia"
  },
  {
    "id": "9124072007",
    "district_id": "912407",
    "label": "Opmu",
    "value": "Opmu"
  },
  {
    "id": "9124072008",
    "district_id": "912407",
    "label": "Digilimu",
    "value": "Digilimu"
  },
  {
    "id": "9124072017",
    "district_id": "912407",
    "label": "Kogomaru",
    "value": "Kogomaru"
  },
  {
    "id": "9124082001",
    "district_id": "912408",
    "label": "Tribid",
    "value": "Tribid"
  },
  {
    "id": "9124082003",
    "district_id": "912408",
    "label": "Gearek",
    "value": "Gearek"
  },
  {
    "id": "9124082006",
    "district_id": "912408",
    "label": "Kibid",
    "value": "Kibid"
  },
  {
    "id": "9124082007",
    "district_id": "912408",
    "label": "Weneworarosa",
    "value": "Weneworarosa"
  },
  {
    "id": "9124082011",
    "district_id": "912408",
    "label": "Gebem",
    "value": "Gebem"
  },
  {
    "id": "9124082012",
    "district_id": "912408",
    "label": "Bomegi",
    "value": "Bomegi"
  },
  {
    "id": "9124082013",
    "district_id": "912408",
    "label": "Gilingga",
    "value": "Gilingga"
  },
  {
    "id": "9124092001",
    "district_id": "912409",
    "label": "Kroptak",
    "value": "Kroptak"
  },
  {
    "id": "9124092002",
    "district_id": "912409",
    "label": "Miniem",
    "value": "Miniem"
  },
  {
    "id": "9124092003",
    "district_id": "912409",
    "label": "Golparek",
    "value": "Golparek"
  },
  {
    "id": "9124092004",
    "district_id": "912409",
    "label": "Pesat",
    "value": "Pesat"
  },
  {
    "id": "9124092005",
    "district_id": "912409",
    "label": "Komoroam",
    "value": "Komoroam"
  },
  {
    "id": "9124092006",
    "district_id": "912409",
    "label": "Gol",
    "value": "Gol"
  },
  {
    "id": "9124102001",
    "district_id": "912410",
    "label": "Gilpid",
    "value": "Gilpid"
  },
  {
    "id": "9124102002",
    "district_id": "912410",
    "label": "Wipdumu",
    "value": "Wipdumu"
  },
  {
    "id": "9124102003",
    "district_id": "912410",
    "label": "Bambi",
    "value": "Bambi"
  },
  {
    "id": "9124102004",
    "district_id": "912410",
    "label": "Osama",
    "value": "Osama"
  },
  {
    "id": "9124102005",
    "district_id": "912410",
    "label": "Kagayem",
    "value": "Kagayem"
  },
  {
    "id": "9124102006",
    "district_id": "912410",
    "label": "Ininkwaning",
    "value": "Ininkwaning"
  },
  {
    "id": "9124102007",
    "district_id": "912410",
    "label": "Kumbuklema",
    "value": "Kumbuklema"
  },
  {
    "id": "9124102008",
    "district_id": "912410",
    "label": "Sigimbut",
    "value": "Sigimbut"
  },
  {
    "id": "9124102009",
    "district_id": "912410",
    "label": "Sanwaruk",
    "value": "Sanwaruk"
  },
  {
    "id": "9124102010",
    "district_id": "912410",
    "label": "Kusit",
    "value": "Kusit"
  },
  {
    "id": "9124102011",
    "district_id": "912410",
    "label": "Kumbun",
    "value": "Kumbun"
  },
  {
    "id": "9124102012",
    "district_id": "912410",
    "label": "Peya-Peya",
    "value": "Peya-Peya"
  },
  {
    "id": "9124102013",
    "district_id": "912410",
    "label": "Narugu",
    "value": "Narugu"
  },
  {
    "id": "9124112001",
    "district_id": "912411",
    "label": "Paro",
    "value": "Paro"
  },
  {
    "id": "9124112002",
    "district_id": "912411",
    "label": "Lombrik",
    "value": "Lombrik"
  },
  {
    "id": "9124112003",
    "district_id": "912411",
    "label": "Animarem",
    "value": "Animarem"
  },
  {
    "id": "9124112004",
    "district_id": "912411",
    "label": "Tawelma",
    "value": "Tawelma"
  },
  {
    "id": "9124112005",
    "district_id": "912411",
    "label": "Loaraba",
    "value": "Loaraba"
  },
  {
    "id": "9124122001",
    "district_id": "912412",
    "label": "Meborok",
    "value": "Meborok"
  },
  {
    "id": "9124122002",
    "district_id": "912412",
    "label": "Yuguru",
    "value": "Yuguru"
  },
  {
    "id": "9124122003",
    "district_id": "912412",
    "label": "Lemurak",
    "value": "Lemurak"
  },
  {
    "id": "9124122004",
    "district_id": "912412",
    "label": "Setmit",
    "value": "Setmit"
  },
  {
    "id": "9124122005",
    "district_id": "912412",
    "label": "Aptam",
    "value": "Aptam"
  },
  {
    "id": "9124122006",
    "district_id": "912412",
    "label": "Ngenamba",
    "value": "Ngenamba"
  },
  {
    "id": "9124122007",
    "district_id": "912412",
    "label": "Narugum",
    "value": "Narugum"
  },
  {
    "id": "9124122008",
    "district_id": "912412",
    "label": "Eregenmanggal",
    "value": "Eregenmanggal"
  },
  {
    "id": "9124122009",
    "district_id": "912412",
    "label": "Talpam",
    "value": "Talpam"
  },
  {
    "id": "9124122010",
    "district_id": "912412",
    "label": "Siginimarem",
    "value": "Siginimarem"
  },
  {
    "id": "9124122011",
    "district_id": "912412",
    "label": "Bone",
    "value": "Bone"
  },
  {
    "id": "9124122012",
    "district_id": "912412",
    "label": "Olunmu",
    "value": "Olunmu"
  },
  {
    "id": "9124122013",
    "district_id": "912412",
    "label": "Sabiem",
    "value": "Sabiem"
  },
  {
    "id": "9124122014",
    "district_id": "912412",
    "label": "Pereki",
    "value": "Pereki"
  },
  {
    "id": "9124132001",
    "district_id": "912413",
    "label": "Yenggelo",
    "value": "Yenggelo"
  },
  {
    "id": "9124132002",
    "district_id": "912413",
    "label": "Maima",
    "value": "Maima"
  },
  {
    "id": "9124132003",
    "district_id": "912413",
    "label": "Nolo",
    "value": "Nolo"
  },
  {
    "id": "9124132004",
    "district_id": "912413",
    "label": "Wuarem",
    "value": "Wuarem"
  },
  {
    "id": "9124142001",
    "district_id": "912414",
    "label": "Gemam",
    "value": "Gemam"
  },
  {
    "id": "9124142002",
    "district_id": "912414",
    "label": "Puruwa",
    "value": "Puruwa"
  },
  {
    "id": "9124142003",
    "district_id": "912414",
    "label": "Yutpul",
    "value": "Yutpul"
  },
  {
    "id": "9124142004",
    "district_id": "912414",
    "label": "Kilmid",
    "value": "Kilmid"
  },
  {
    "id": "9124152001",
    "district_id": "912415",
    "label": "Alama",
    "value": "Alama"
  },
  {
    "id": "9124152002",
    "district_id": "912415",
    "label": "Nolit",
    "value": "Nolit"
  },
  {
    "id": "9124152003",
    "district_id": "912415",
    "label": "Gin",
    "value": "Gin"
  },
  {
    "id": "9124152004",
    "district_id": "912415",
    "label": "Kulesa",
    "value": "Kulesa"
  },
  {
    "id": "9124162001",
    "district_id": "912416",
    "label": "Yal",
    "value": "Yal"
  },
  {
    "id": "9124162002",
    "district_id": "912416",
    "label": "Yimogi",
    "value": "Yimogi"
  },
  {
    "id": "9124162003",
    "district_id": "912416",
    "label": "Gimi",
    "value": "Gimi"
  },
  {
    "id": "9124162004",
    "district_id": "912416",
    "label": "Mugiarem",
    "value": "Mugiarem"
  },
  {
    "id": "9124162005",
    "district_id": "912416",
    "label": "Palpam",
    "value": "Palpam"
  },
  {
    "id": "9124162006",
    "district_id": "912416",
    "label": "Samba",
    "value": "Samba"
  },
  {
    "id": "9124162007",
    "district_id": "912416",
    "label": "Sitdobo",
    "value": "Sitdobo"
  },
  {
    "id": "9124162008",
    "district_id": "912416",
    "label": "Diringgama",
    "value": "Diringgama"
  },
  {
    "id": "9124162009",
    "district_id": "912416",
    "label": "Dangenpem",
    "value": "Dangenpem"
  },
  {
    "id": "9124162010",
    "district_id": "912416",
    "label": "Undo",
    "value": "Undo"
  },
  {
    "id": "9124162011",
    "district_id": "912416",
    "label": "Betbere",
    "value": "Betbere"
  },
  {
    "id": "9124162012",
    "district_id": "912416",
    "label": "Muruldumu",
    "value": "Muruldumu"
  },
  {
    "id": "9124162013",
    "district_id": "912416",
    "label": "Yaba",
    "value": "Yaba"
  },
  {
    "id": "9124162014",
    "district_id": "912416",
    "label": "Dengpa",
    "value": "Dengpa"
  },
  {
    "id": "9124162015",
    "district_id": "912416",
    "label": "Girimnak",
    "value": "Girimnak"
  },
  {
    "id": "9124162016",
    "district_id": "912416",
    "label": "Lambule",
    "value": "Lambule"
  },
  {
    "id": "9124162017",
    "district_id": "912416",
    "label": "Sujomdobo",
    "value": "Sujomdobo"
  },
  {
    "id": "9124162018",
    "district_id": "912416",
    "label": "Suelama",
    "value": "Suelama"
  },
  {
    "id": "9124162019",
    "district_id": "912416",
    "label": "Gularem",
    "value": "Gularem"
  },
  {
    "id": "9124162020",
    "district_id": "912416",
    "label": "Biledumu",
    "value": "Biledumu"
  },
  {
    "id": "9124172001",
    "district_id": "912417",
    "label": "Mam",
    "value": "Mam"
  },
  {
    "id": "9124172002",
    "district_id": "912417",
    "label": "Pukle",
    "value": "Pukle"
  },
  {
    "id": "9124172003",
    "district_id": "912417",
    "label": "Wolmbam",
    "value": "Wolmbam"
  },
  {
    "id": "9124172004",
    "district_id": "912417",
    "label": "Wanduama",
    "value": "Wanduama"
  },
  {
    "id": "9124172005",
    "district_id": "912417",
    "label": "Yibi",
    "value": "Yibi"
  },
  {
    "id": "9124172006",
    "district_id": "912417",
    "label": "Lakda",
    "value": "Lakda"
  },
  {
    "id": "9124172007",
    "district_id": "912417",
    "label": "Yebandui",
    "value": "Yebandui"
  },
  {
    "id": "9124172008",
    "district_id": "912417",
    "label": "Laruid",
    "value": "Laruid"
  },
  {
    "id": "9124172009",
    "district_id": "912417",
    "label": "Dendamba",
    "value": "Dendamba"
  },
  {
    "id": "9124172010",
    "district_id": "912417",
    "label": "Kibiaren",
    "value": "Kibiaren"
  },
  {
    "id": "9124172011",
    "district_id": "912417",
    "label": "Paruarem",
    "value": "Paruarem"
  },
  {
    "id": "9124172012",
    "district_id": "912417",
    "label": "Kibo",
    "value": "Kibo"
  },
  {
    "id": "9124172013",
    "district_id": "912417",
    "label": "Putdumu",
    "value": "Putdumu"
  },
  {
    "id": "9124172014",
    "district_id": "912417",
    "label": "Darakma",
    "value": "Darakma"
  },
  {
    "id": "9124182001",
    "district_id": "912418",
    "label": "Dal",
    "value": "Dal"
  },
  {
    "id": "9124182002",
    "district_id": "912418",
    "label": "Silan",
    "value": "Silan"
  },
  {
    "id": "9124182003",
    "district_id": "912418",
    "label": "Gurumbe",
    "value": "Gurumbe"
  },
  {
    "id": "9124182004",
    "district_id": "912418",
    "label": "Kaboneri",
    "value": "Kaboneri"
  },
  {
    "id": "9124182005",
    "district_id": "912418",
    "label": "Silankuru",
    "value": "Silankuru"
  },
  {
    "id": "9124182006",
    "district_id": "912418",
    "label": "Grinbun",
    "value": "Grinbun"
  },
  {
    "id": "9124192001",
    "district_id": "912419",
    "label": "Nirkuri",
    "value": "Nirkuri"
  },
  {
    "id": "9124192002",
    "district_id": "912419",
    "label": "Binime",
    "value": "Binime"
  },
  {
    "id": "9124192003",
    "district_id": "912419",
    "label": "Uenoma",
    "value": "Uenoma"
  },
  {
    "id": "9124192004",
    "district_id": "912419",
    "label": "Pasebem",
    "value": "Pasebem"
  },
  {
    "id": "9124192005",
    "district_id": "912419",
    "label": "Sigmie",
    "value": "Sigmie"
  },
  {
    "id": "9124192006",
    "district_id": "912419",
    "label": "Nirigimbirik",
    "value": "Nirigimbirik"
  },
  {
    "id": "9124192007",
    "district_id": "912419",
    "label": "Oldobo",
    "value": "Oldobo"
  },
  {
    "id": "9124192008",
    "district_id": "912419",
    "label": "Likubuk",
    "value": "Likubuk"
  },
  {
    "id": "9124192009",
    "district_id": "912419",
    "label": "Kokujondumu",
    "value": "Kokujondumu"
  },
  {
    "id": "9124192010",
    "district_id": "912419",
    "label": "Kurigi",
    "value": "Kurigi"
  },
  {
    "id": "9124202001",
    "district_id": "912420",
    "label": "Biripem",
    "value": "Biripem"
  },
  {
    "id": "9124202002",
    "district_id": "912420",
    "label": "Dakbri",
    "value": "Dakbri"
  },
  {
    "id": "9124202003",
    "district_id": "912420",
    "label": "Abualak",
    "value": "Abualak"
  },
  {
    "id": "9124202004",
    "district_id": "912420",
    "label": "Urugom",
    "value": "Urugom"
  },
  {
    "id": "9124202005",
    "district_id": "912420",
    "label": "Area",
    "value": "Area"
  },
  {
    "id": "9124202006",
    "district_id": "912420",
    "label": "Wumaga",
    "value": "Wumaga"
  },
  {
    "id": "9124202007",
    "district_id": "912420",
    "label": "Gulama",
    "value": "Gulama"
  },
  {
    "id": "9124202008",
    "district_id": "912420",
    "label": "Kigam",
    "value": "Kigam"
  },
  {
    "id": "9124212001",
    "district_id": "912421",
    "label": "Iniye",
    "value": "Iniye"
  },
  {
    "id": "9124212002",
    "district_id": "912421",
    "label": "Baklema",
    "value": "Baklema"
  },
  {
    "id": "9124212003",
    "district_id": "912421",
    "label": "Kibendumu",
    "value": "Kibendumu"
  },
  {
    "id": "9124212004",
    "district_id": "912421",
    "label": "Simiye",
    "value": "Simiye"
  },
  {
    "id": "9124212005",
    "district_id": "912421",
    "label": "Embuklem",
    "value": "Embuklem"
  },
  {
    "id": "9124212006",
    "district_id": "912421",
    "label": "Kosobak",
    "value": "Kosobak"
  },
  {
    "id": "9124222001",
    "district_id": "912422",
    "label": "Uburu",
    "value": "Uburu"
  },
  {
    "id": "9124222002",
    "district_id": "912422",
    "label": "Kabolilangen",
    "value": "Kabolilangen"
  },
  {
    "id": "9124222003",
    "district_id": "912422",
    "label": "Kolma",
    "value": "Kolma"
  },
  {
    "id": "9124222004",
    "district_id": "912422",
    "label": "Brambel",
    "value": "Brambel"
  },
  {
    "id": "9124222005",
    "district_id": "912422",
    "label": "Kiabypma",
    "value": "Kiabypma"
  },
  {
    "id": "9124222006",
    "district_id": "912422",
    "label": "Kibisim",
    "value": "Kibisim"
  },
  {
    "id": "9124222007",
    "district_id": "912422",
    "label": "Labrik",
    "value": "Labrik"
  },
  {
    "id": "9124232001",
    "district_id": "912423",
    "label": "Paris",
    "value": "Paris"
  },
  {
    "id": "9124232002",
    "district_id": "912423",
    "label": "Simie",
    "value": "Simie"
  },
  {
    "id": "9124232003",
    "district_id": "912423",
    "label": "Urugi",
    "value": "Urugi"
  },
  {
    "id": "9124232004",
    "district_id": "912423",
    "label": "Pirim-Pirim",
    "value": "Pirim-Pirim"
  },
  {
    "id": "9124232005",
    "district_id": "912423",
    "label": "Tombisik",
    "value": "Tombisik"
  },
  {
    "id": "9124232006",
    "district_id": "912423",
    "label": "Yepma",
    "value": "Yepma"
  },
  {
    "id": "9124232007",
    "district_id": "912423",
    "label": "Sokore",
    "value": "Sokore"
  },
  {
    "id": "9124232008",
    "district_id": "912423",
    "label": "Tobonggom",
    "value": "Tobonggom"
  },
  {
    "id": "9124232009",
    "district_id": "912423",
    "label": "Rapa",
    "value": "Rapa"
  },
  {
    "id": "9124242001",
    "district_id": "912424",
    "label": "Wendama",
    "value": "Wendama"
  },
  {
    "id": "9124242002",
    "district_id": "912424",
    "label": "Embetpem",
    "value": "Embetpem"
  },
  {
    "id": "9124242003",
    "district_id": "912424",
    "label": "Yenai",
    "value": "Yenai"
  },
  {
    "id": "9124242004",
    "district_id": "912424",
    "label": "Digilimu",
    "value": "Digilimu"
  },
  {
    "id": "9124242005",
    "district_id": "912424",
    "label": "Bisikimu",
    "value": "Bisikimu"
  },
  {
    "id": "9124252001",
    "district_id": "912425",
    "label": "Kora",
    "value": "Kora"
  },
  {
    "id": "9124252002",
    "district_id": "912425",
    "label": "Nanggoal",
    "value": "Nanggoal"
  },
  {
    "id": "9124252003",
    "district_id": "912425",
    "label": "Wenam",
    "value": "Wenam"
  },
  {
    "id": "9124252004",
    "district_id": "912425",
    "label": "Wilimi",
    "value": "Wilimi"
  },
  {
    "id": "9124252005",
    "district_id": "912425",
    "label": "Brutnai",
    "value": "Brutnai"
  },
  {
    "id": "9124262001",
    "district_id": "912426",
    "label": "Wusi",
    "value": "Wusi"
  },
  {
    "id": "9124262002",
    "district_id": "912426",
    "label": "Ginigit",
    "value": "Ginigit"
  },
  {
    "id": "9124262003",
    "district_id": "912426",
    "label": "Pijemae",
    "value": "Pijemae"
  },
  {
    "id": "9124262004",
    "district_id": "912426",
    "label": "Gimigip",
    "value": "Gimigip"
  },
  {
    "id": "9124272001",
    "district_id": "912427",
    "label": "Nganai",
    "value": "Nganai"
  },
  {
    "id": "9124272002",
    "district_id": "912427",
    "label": "Pija",
    "value": "Pija"
  },
  {
    "id": "9124272003",
    "district_id": "912427",
    "label": "Sitgama",
    "value": "Sitgama"
  },
  {
    "id": "9124272004",
    "district_id": "912427",
    "label": "Siat",
    "value": "Siat"
  },
  {
    "id": "9124272005",
    "district_id": "912427",
    "label": "Murupbaye",
    "value": "Murupbaye"
  },
  {
    "id": "9124282001",
    "district_id": "912428",
    "label": "Moba",
    "value": "Moba"
  },
  {
    "id": "9124282002",
    "district_id": "912428",
    "label": "Gunea",
    "value": "Gunea"
  },
  {
    "id": "9124282003",
    "district_id": "912428",
    "label": "Kwegono",
    "value": "Kwegono"
  },
  {
    "id": "9124282004",
    "district_id": "912428",
    "label": "Langpang",
    "value": "Langpang"
  },
  {
    "id": "9124282005",
    "district_id": "912428",
    "label": "Yeretma",
    "value": "Yeretma"
  },
  {
    "id": "9124282006",
    "district_id": "912428",
    "label": "Anggulpa",
    "value": "Anggulpa"
  },
  {
    "id": "9124282007",
    "district_id": "912428",
    "label": "Moba Dua",
    "value": "Moba Dua"
  },
  {
    "id": "9124282008",
    "district_id": "912428",
    "label": "Pijama",
    "value": "Pijama"
  },
  {
    "id": "9124292001",
    "district_id": "912429",
    "label": "Muli",
    "value": "Muli"
  },
  {
    "id": "9124292002",
    "district_id": "912429",
    "label": "Tinigele",
    "value": "Tinigele"
  },
  {
    "id": "9124292003",
    "district_id": "912429",
    "label": "Wuone",
    "value": "Wuone"
  },
  {
    "id": "9124292004",
    "district_id": "912429",
    "label": "Luaren",
    "value": "Luaren"
  },
  {
    "id": "9124292005",
    "district_id": "912429",
    "label": "Wangun",
    "value": "Wangun"
  },
  {
    "id": "9124292006",
    "district_id": "912429",
    "label": "Wutpaga",
    "value": "Wutpaga"
  },
  {
    "id": "9124302001",
    "district_id": "912430",
    "label": "Neggea",
    "value": "Neggea"
  },
  {
    "id": "9124302002",
    "district_id": "912430",
    "label": "Wulagumi",
    "value": "Wulagumi"
  },
  {
    "id": "9124302003",
    "district_id": "912430",
    "label": "Kombama",
    "value": "Kombama"
  },
  {
    "id": "9124302004",
    "district_id": "912430",
    "label": "Wenggenambut",
    "value": "Wenggenambut"
  },
  {
    "id": "9124302005",
    "district_id": "912430",
    "label": "Nenggeagin",
    "value": "Nenggeagin"
  },
  {
    "id": "9124312001",
    "district_id": "912431",
    "label": "Krepkuri",
    "value": "Krepkuri"
  },
  {
    "id": "9124312002",
    "district_id": "912431",
    "label": "Alguru",
    "value": "Alguru"
  },
  {
    "id": "9124312003",
    "district_id": "912431",
    "label": "Sagapusatu",
    "value": "Sagapusatu"
  },
  {
    "id": "9124312004",
    "district_id": "912431",
    "label": "Mumugu",
    "value": "Mumugu"
  },
  {
    "id": "9124312005",
    "district_id": "912431",
    "label": "Ginid",
    "value": "Ginid"
  },
  {
    "id": "9124312006",
    "district_id": "912431",
    "label": "Banggabeak",
    "value": "Banggabeak"
  },
  {
    "id": "9124322001",
    "district_id": "912432",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "9124322002",
    "district_id": "912432",
    "label": "Trim",
    "value": "Trim"
  },
  {
    "id": "9124322003",
    "district_id": "912432",
    "label": "Benggem",
    "value": "Benggem"
  },
  {
    "id": "9124322004",
    "district_id": "912432",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9125012002",
    "district_id": "912501",
    "label": "Kago",
    "value": "Kago"
  },
  {
    "id": "9125012006",
    "district_id": "912501",
    "label": "Kimak",
    "value": "Kimak"
  },
  {
    "id": "9125012012",
    "district_id": "912501",
    "label": "Wuloni",
    "value": "Wuloni"
  },
  {
    "id": "9125012016",
    "district_id": "912501",
    "label": "Nipuralome",
    "value": "Nipuralome"
  },
  {
    "id": "9125012017",
    "district_id": "912501",
    "label": "Tagaloa",
    "value": "Tagaloa"
  },
  {
    "id": "9125012018",
    "district_id": "912501",
    "label": "Jenggerpaga",
    "value": "Jenggerpaga"
  },
  {
    "id": "9125012019",
    "district_id": "912501",
    "label": "kibogolome",
    "value": "kibogolome"
  },
  {
    "id": "9125012020",
    "district_id": "912501",
    "label": "Ilambet",
    "value": "Ilambet"
  },
  {
    "id": "9125012021",
    "district_id": "912501",
    "label": "Kalebut",
    "value": "Kalebut"
  },
  {
    "id": "9125022001",
    "district_id": "912502",
    "label": "Wangbe",
    "value": "Wangbe"
  },
  {
    "id": "9125022003",
    "district_id": "912502",
    "label": "Jindak",
    "value": "Jindak"
  },
  {
    "id": "9125022004",
    "district_id": "912502",
    "label": "Pungki",
    "value": "Pungki"
  },
  {
    "id": "9125022005",
    "district_id": "912502",
    "label": "Wonalbe",
    "value": "Wonalbe"
  },
  {
    "id": "9125022008",
    "district_id": "912502",
    "label": "Ulipia",
    "value": "Ulipia"
  },
  {
    "id": "9125022009",
    "district_id": "912502",
    "label": "Marilaukin",
    "value": "Marilaukin"
  },
  {
    "id": "9125022010",
    "district_id": "912502",
    "label": "Ailpailin",
    "value": "Ailpailin"
  },
  {
    "id": "9125022012",
    "district_id": "912502",
    "label": "Wungbet",
    "value": "Wungbet"
  },
  {
    "id": "9125022013",
    "district_id": "912502",
    "label": "Nagaljagama",
    "value": "Nagaljagama"
  },
  {
    "id": "9125022014",
    "district_id": "912502",
    "label": "Mongkoihol",
    "value": "Mongkoihol"
  },
  {
    "id": "9125022015",
    "district_id": "912502",
    "label": "Nangi",
    "value": "Nangi"
  },
  {
    "id": "9125032001",
    "district_id": "912503",
    "label": "Yulukoma",
    "value": "Yulukoma"
  },
  {
    "id": "9125032002",
    "district_id": "912503",
    "label": "Milawak",
    "value": "Milawak"
  },
  {
    "id": "9125032007",
    "district_id": "912503",
    "label": "Tinggilbet",
    "value": "Tinggilbet"
  },
  {
    "id": "9125032009",
    "district_id": "912503",
    "label": "Ogamki",
    "value": "Ogamki"
  },
  {
    "id": "9125032010",
    "district_id": "912503",
    "label": "Dengkibuma",
    "value": "Dengkibuma"
  },
  {
    "id": "9125032012",
    "district_id": "912503",
    "label": "Dambet",
    "value": "Dambet"
  },
  {
    "id": "9125032013",
    "district_id": "912503",
    "label": "Ondugi",
    "value": "Ondugi"
  },
  {
    "id": "9125032014",
    "district_id": "912503",
    "label": "Ambobera",
    "value": "Ambobera"
  },
  {
    "id": "9125042001",
    "district_id": "912504",
    "label": "Doufo",
    "value": "Doufo"
  },
  {
    "id": "9125042002",
    "district_id": "912504",
    "label": "Iratoi",
    "value": "Iratoi"
  },
  {
    "id": "9125042006",
    "district_id": "912504",
    "label": "Lani",
    "value": "Lani"
  },
  {
    "id": "9125042007",
    "district_id": "912504",
    "label": "Wahuka",
    "value": "Wahuka"
  },
  {
    "id": "9125042008",
    "district_id": "912504",
    "label": "Sibita",
    "value": "Sibita"
  },
  {
    "id": "9125042009",
    "district_id": "912504",
    "label": "Rawa",
    "value": "Rawa"
  },
  {
    "id": "9125042010",
    "district_id": "912504",
    "label": "Jirei",
    "value": "Jirei"
  },
  {
    "id": "9125052002",
    "district_id": "912505",
    "label": "Pogoma",
    "value": "Pogoma"
  },
  {
    "id": "9125052006",
    "district_id": "912505",
    "label": "Gagama",
    "value": "Gagama"
  },
  {
    "id": "9125052008",
    "district_id": "912505",
    "label": "Wiha",
    "value": "Wiha"
  },
  {
    "id": "9125052010",
    "district_id": "912505",
    "label": "Wakme",
    "value": "Wakme"
  },
  {
    "id": "9125052011",
    "district_id": "912505",
    "label": "Ugutmu",
    "value": "Ugutmu"
  },
  {
    "id": "9125052012",
    "district_id": "912505",
    "label": "Yugume",
    "value": "Yugume"
  },
  {
    "id": "9125052013",
    "district_id": "912505",
    "label": "Kolamagi",
    "value": "Kolamagi"
  },
  {
    "id": "9125052014",
    "district_id": "912505",
    "label": "Guwamu",
    "value": "Guwamu"
  },
  {
    "id": "9125052015",
    "district_id": "912505",
    "label": "Kumasingga",
    "value": "Kumasingga"
  },
  {
    "id": "9125062001",
    "district_id": "912506",
    "label": "Yauria",
    "value": "Yauria"
  },
  {
    "id": "9125062002",
    "district_id": "912506",
    "label": "Gigobak",
    "value": "Gigobak"
  },
  {
    "id": "9125062009",
    "district_id": "912506",
    "label": "Kalibuk",
    "value": "Kalibuk"
  },
  {
    "id": "9125062010",
    "district_id": "912506",
    "label": "Kelemame",
    "value": "Kelemame"
  },
  {
    "id": "9125062013",
    "district_id": "912506",
    "label": "Gulabut",
    "value": "Gulabut"
  },
  {
    "id": "9125062014",
    "district_id": "912506",
    "label": "Kolaribanak",
    "value": "Kolaribanak"
  },
  {
    "id": "9125062015",
    "district_id": "912506",
    "label": "Mogulu",
    "value": "Mogulu"
  },
  {
    "id": "9125062016",
    "district_id": "912506",
    "label": "Gigobak II",
    "value": "Gigobak II"
  },
  {
    "id": "9125062017",
    "district_id": "912506",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9125062018",
    "district_id": "912506",
    "label": "Gingga Baru",
    "value": "Gingga Baru"
  },
  {
    "id": "9125062019",
    "district_id": "912506",
    "label": "Tenonggame",
    "value": "Tenonggame"
  },
  {
    "id": "9125072001",
    "district_id": "912507",
    "label": "Agandugume",
    "value": "Agandugume"
  },
  {
    "id": "9125072004",
    "district_id": "912507",
    "label": "Gut'yenggenak",
    "value": "Gut'yenggenak"
  },
  {
    "id": "9125072005",
    "district_id": "912507",
    "label": "Dugunale",
    "value": "Dugunale"
  },
  {
    "id": "9125072006",
    "district_id": "912507",
    "label": "Ogobak'pelenak",
    "value": "Ogobak'pelenak"
  },
  {
    "id": "9125072007",
    "district_id": "912507",
    "label": "Dolinggu",
    "value": "Dolinggu"
  },
  {
    "id": "9125072008",
    "district_id": "912507",
    "label": "Ayume",
    "value": "Ayume"
  },
  {
    "id": "9125072009",
    "district_id": "912507",
    "label": "Terowi",
    "value": "Terowi"
  },
  {
    "id": "9125082002",
    "district_id": "912508",
    "label": "Gome",
    "value": "Gome"
  },
  {
    "id": "9125082004",
    "district_id": "912508",
    "label": "Misimaga",
    "value": "Misimaga"
  },
  {
    "id": "9125082006",
    "district_id": "912508",
    "label": "Upaga",
    "value": "Upaga"
  },
  {
    "id": "9125082007",
    "district_id": "912508",
    "label": "Agiyome",
    "value": "Agiyome"
  },
  {
    "id": "9125082008",
    "district_id": "912508",
    "label": "Yenggernok",
    "value": "Yenggernok"
  },
  {
    "id": "9125082009",
    "district_id": "912508",
    "label": "Wako",
    "value": "Wako"
  },
  {
    "id": "9125082013",
    "district_id": "912508",
    "label": "Tegelobak",
    "value": "Tegelobak"
  },
  {
    "id": "9125082014",
    "district_id": "912508",
    "label": "Ninggabuma",
    "value": "Ninggabuma"
  },
  {
    "id": "9125082015",
    "district_id": "912508",
    "label": "Kelanungin",
    "value": "Kelanungin"
  },
  {
    "id": "9125082016",
    "district_id": "912508",
    "label": "Yonggolawi",
    "value": "Yonggolawi"
  },
  {
    "id": "9125092001",
    "district_id": "912509",
    "label": "Tayai",
    "value": "Tayai"
  },
  {
    "id": "9125092002",
    "district_id": "912509",
    "label": "Kordesi",
    "value": "Kordesi"
  },
  {
    "id": "9125092003",
    "district_id": "912509",
    "label": "Faisau",
    "value": "Faisau"
  },
  {
    "id": "9125092004",
    "district_id": "912509",
    "label": "Fokri",
    "value": "Fokri"
  },
  {
    "id": "9125092005",
    "district_id": "912509",
    "label": "Foisa",
    "value": "Foisa"
  },
  {
    "id": "9125092006",
    "district_id": "912509",
    "label": "Duaita",
    "value": "Duaita"
  },
  {
    "id": "9125092007",
    "district_id": "912509",
    "label": "Dervos",
    "value": "Dervos"
  },
  {
    "id": "9125102001",
    "district_id": "912510",
    "label": "Babe",
    "value": "Babe"
  },
  {
    "id": "9125102002",
    "district_id": "912510",
    "label": "Jambul",
    "value": "Jambul"
  },
  {
    "id": "9125102003",
    "district_id": "912510",
    "label": "Nungai",
    "value": "Nungai"
  },
  {
    "id": "9125102004",
    "district_id": "912510",
    "label": "Mugulip",
    "value": "Mugulip"
  },
  {
    "id": "9125102005",
    "district_id": "912510",
    "label": "Kelmabet",
    "value": "Kelmabet"
  },
  {
    "id": "9125102006",
    "district_id": "912510",
    "label": "Jenggeren",
    "value": "Jenggeren"
  },
  {
    "id": "9125112001",
    "district_id": "912511",
    "label": "Puluk",
    "value": "Puluk"
  },
  {
    "id": "9125112002",
    "district_id": "912511",
    "label": "Kelandiruma",
    "value": "Kelandiruma"
  },
  {
    "id": "9125112003",
    "district_id": "912511",
    "label": "Pupet",
    "value": "Pupet"
  },
  {
    "id": "9125112004",
    "district_id": "912511",
    "label": "Pilokoma",
    "value": "Pilokoma"
  },
  {
    "id": "9125112005",
    "district_id": "912511",
    "label": "Gimurik",
    "value": "Gimurik"
  },
  {
    "id": "9125112006",
    "district_id": "912511",
    "label": "Wandibet",
    "value": "Wandibet"
  },
  {
    "id": "9125112007",
    "district_id": "912511",
    "label": "Meningimte",
    "value": "Meningimte"
  },
  {
    "id": "9125112008",
    "district_id": "912511",
    "label": "Bungok",
    "value": "Bungok"
  },
  {
    "id": "9125122001",
    "district_id": "912512",
    "label": "Erong Berong",
    "value": "Erong Berong"
  },
  {
    "id": "9125122002",
    "district_id": "912512",
    "label": "Hikinat",
    "value": "Hikinat"
  },
  {
    "id": "9125122003",
    "district_id": "912512",
    "label": "Daung",
    "value": "Daung"
  },
  {
    "id": "9125122004",
    "district_id": "912512",
    "label": "Kombet",
    "value": "Kombet"
  },
  {
    "id": "9125122005",
    "district_id": "912512",
    "label": "Onop",
    "value": "Onop"
  },
  {
    "id": "9125122006",
    "district_id": "912512",
    "label": "Kulamagom",
    "value": "Kulamagom"
  },
  {
    "id": "9125122007",
    "district_id": "912512",
    "label": "Ogamanin",
    "value": "Ogamanin"
  },
  {
    "id": "9125132001",
    "district_id": "912513",
    "label": "Aguit",
    "value": "Aguit"
  },
  {
    "id": "9125132002",
    "district_id": "912513",
    "label": "Kembru",
    "value": "Kembru"
  },
  {
    "id": "9125132003",
    "district_id": "912513",
    "label": "Molu",
    "value": "Molu"
  },
  {
    "id": "9125132004",
    "district_id": "912513",
    "label": "Belaba",
    "value": "Belaba"
  },
  {
    "id": "9125132005",
    "district_id": "912513",
    "label": "Makuma",
    "value": "Makuma"
  },
  {
    "id": "9125132006",
    "district_id": "912513",
    "label": "Tinoti",
    "value": "Tinoti"
  },
  {
    "id": "9125132007",
    "district_id": "912513",
    "label": "Nilime",
    "value": "Nilime"
  },
  {
    "id": "9125142001",
    "district_id": "912514",
    "label": "Golu",
    "value": "Golu"
  },
  {
    "id": "9125142002",
    "district_id": "912514",
    "label": "Bina",
    "value": "Bina"
  },
  {
    "id": "9125142003",
    "district_id": "912514",
    "label": "Lumakluk",
    "value": "Lumakluk"
  },
  {
    "id": "9125142004",
    "district_id": "912514",
    "label": "Wogot",
    "value": "Wogot"
  },
  {
    "id": "9125142005",
    "district_id": "912514",
    "label": "Kabogoluk",
    "value": "Kabogoluk"
  },
  {
    "id": "9125142006",
    "district_id": "912514",
    "label": "Ogomegat",
    "value": "Ogomegat"
  },
  {
    "id": "9125142007",
    "district_id": "912514",
    "label": "Mamaput",
    "value": "Mamaput"
  },
  {
    "id": "9125152001",
    "district_id": "912515",
    "label": "Kilunggame",
    "value": "Kilunggame"
  },
  {
    "id": "9125152002",
    "district_id": "912515",
    "label": "Bakcini",
    "value": "Bakcini"
  },
  {
    "id": "9125152003",
    "district_id": "912515",
    "label": "Timobut",
    "value": "Timobut"
  },
  {
    "id": "9125152004",
    "district_id": "912515",
    "label": "Tagalame I",
    "value": "Tagalame I"
  },
  {
    "id": "9125152005",
    "district_id": "912515",
    "label": "Wonelupaga",
    "value": "Wonelupaga"
  },
  {
    "id": "9125152006",
    "district_id": "912515",
    "label": "Komapaga",
    "value": "Komapaga"
  },
  {
    "id": "9125152007",
    "district_id": "912515",
    "label": "Timobut Egewak",
    "value": "Timobut Egewak"
  },
  {
    "id": "9125162001",
    "district_id": "912516",
    "label": "Nigilome",
    "value": "Nigilome"
  },
  {
    "id": "9125162002",
    "district_id": "912516",
    "label": "Weni",
    "value": "Weni"
  },
  {
    "id": "9125162003",
    "district_id": "912516",
    "label": "Amuleme",
    "value": "Amuleme"
  },
  {
    "id": "9125162004",
    "district_id": "912516",
    "label": "Jigunggi",
    "value": "Jigunggi"
  },
  {
    "id": "9125162005",
    "district_id": "912516",
    "label": "Kumikomo",
    "value": "Kumikomo"
  },
  {
    "id": "9125162006",
    "district_id": "912516",
    "label": "Rumagi",
    "value": "Rumagi"
  },
  {
    "id": "9125162007",
    "district_id": "912516",
    "label": "Gelegi",
    "value": "Gelegi"
  },
  {
    "id": "9125162008",
    "district_id": "912516",
    "label": "Wombru",
    "value": "Wombru"
  },
  {
    "id": "9125162009",
    "district_id": "912516",
    "label": "Kondokwe",
    "value": "Kondokwe"
  },
  {
    "id": "9125162010",
    "district_id": "912516",
    "label": "Tinibolu",
    "value": "Tinibolu"
  },
  {
    "id": "9125162011",
    "district_id": "912516",
    "label": "Winanggwi",
    "value": "Winanggwi"
  },
  {
    "id": "9125172001",
    "district_id": "912517",
    "label": "Pamebut",
    "value": "Pamebut"
  },
  {
    "id": "9125172002",
    "district_id": "912517",
    "label": "Agenggen",
    "value": "Agenggen"
  },
  {
    "id": "9125172003",
    "district_id": "912517",
    "label": "Yugumuak",
    "value": "Yugumuak"
  },
  {
    "id": "9125172004",
    "district_id": "912517",
    "label": "Wilewak",
    "value": "Wilewak"
  },
  {
    "id": "9125172005",
    "district_id": "912517",
    "label": "Lambera",
    "value": "Lambera"
  },
  {
    "id": "9125172006",
    "district_id": "912517",
    "label": "Wobulo",
    "value": "Wobulo"
  },
  {
    "id": "9125172007",
    "district_id": "912517",
    "label": "Mugalolo",
    "value": "Mugalolo"
  },
  {
    "id": "9125172008",
    "district_id": "912517",
    "label": "Manggame",
    "value": "Manggame"
  },
  {
    "id": "9125172009",
    "district_id": "912517",
    "label": "Jerusalem",
    "value": "Jerusalem"
  },
  {
    "id": "9125172010",
    "district_id": "912517",
    "label": "Wiyabubu",
    "value": "Wiyabubu"
  },
  {
    "id": "9125182001",
    "district_id": "912518",
    "label": "Mayuberi",
    "value": "Mayuberi"
  },
  {
    "id": "9125182002",
    "district_id": "912518",
    "label": "Maki",
    "value": "Maki"
  },
  {
    "id": "9125182003",
    "district_id": "912518",
    "label": "Paluga",
    "value": "Paluga"
  },
  {
    "id": "9125182004",
    "district_id": "912518",
    "label": "Ayali",
    "value": "Ayali"
  },
  {
    "id": "9125182005",
    "district_id": "912518",
    "label": "Toegi",
    "value": "Toegi"
  },
  {
    "id": "9125182006",
    "district_id": "912518",
    "label": "Amungi",
    "value": "Amungi"
  },
  {
    "id": "9125182007",
    "district_id": "912518",
    "label": "Olenki",
    "value": "Olenki"
  },
  {
    "id": "9125182008",
    "district_id": "912518",
    "label": "Mundiba",
    "value": "Mundiba"
  },
  {
    "id": "9125182009",
    "district_id": "912518",
    "label": "Akonobak",
    "value": "Akonobak"
  },
  {
    "id": "9125192001",
    "district_id": "912519",
    "label": "Bubet",
    "value": "Bubet"
  },
  {
    "id": "9125192002",
    "district_id": "912519",
    "label": "Bologobak",
    "value": "Bologobak"
  },
  {
    "id": "9125192003",
    "district_id": "912519",
    "label": "Ko'eao",
    "value": "Ko'eao"
  },
  {
    "id": "9125192004",
    "district_id": "912519",
    "label": "Ogongki",
    "value": "Ogongki"
  },
  {
    "id": "9125192005",
    "district_id": "912519",
    "label": "Mabuga",
    "value": "Mabuga"
  },
  {
    "id": "9125192006",
    "district_id": "912519",
    "label": "Kabuki",
    "value": "Kabuki"
  },
  {
    "id": "9125192007",
    "district_id": "912519",
    "label": "Oknanim",
    "value": "Oknanim"
  },
  {
    "id": "9125192008",
    "district_id": "912519",
    "label": "Kalemogom",
    "value": "Kalemogom"
  },
  {
    "id": "9125202001",
    "district_id": "912520",
    "label": "Eromaga",
    "value": "Eromaga"
  },
  {
    "id": "9125202002",
    "district_id": "912520",
    "label": "Pinggil/Eronggobak",
    "value": "Pinggil/Eronggobak"
  },
  {
    "id": "9125202003",
    "district_id": "912520",
    "label": "Gilini",
    "value": "Gilini"
  },
  {
    "id": "9125202004",
    "district_id": "912520",
    "label": "Pinapa",
    "value": "Pinapa"
  },
  {
    "id": "9125202005",
    "district_id": "912520",
    "label": "Kunga",
    "value": "Kunga"
  },
  {
    "id": "9125202006",
    "district_id": "912520",
    "label": "Ondugura",
    "value": "Ondugura"
  },
  {
    "id": "9125202007",
    "district_id": "912520",
    "label": "Manggume",
    "value": "Manggume"
  },
  {
    "id": "9125202008",
    "district_id": "912520",
    "label": "Kuleme",
    "value": "Kuleme"
  },
  {
    "id": "9125202009",
    "district_id": "912520",
    "label": "Ninggibome",
    "value": "Ninggibome"
  },
  {
    "id": "9125202010",
    "district_id": "912520",
    "label": "Awilkinop",
    "value": "Awilkinop"
  },
  {
    "id": "9125202011",
    "district_id": "912520",
    "label": "Domaoa",
    "value": "Domaoa"
  },
  {
    "id": "9125202012",
    "district_id": "912520",
    "label": "Pungtrama",
    "value": "Pungtrama"
  },
  {
    "id": "9125202013",
    "district_id": "912520",
    "label": "Balamagalama",
    "value": "Balamagalama"
  },
  {
    "id": "9125202014",
    "district_id": "912520",
    "label": "Aminggaru",
    "value": "Aminggaru"
  },
  {
    "id": "9125212001",
    "district_id": "912521",
    "label": "Tuput",
    "value": "Tuput"
  },
  {
    "id": "9125212002",
    "district_id": "912521",
    "label": "Wenggen'ambut",
    "value": "Wenggen'ambut"
  },
  {
    "id": "9125212003",
    "district_id": "912521",
    "label": "Wamiru",
    "value": "Wamiru"
  },
  {
    "id": "9125212004",
    "district_id": "912521",
    "label": "Piluwolu",
    "value": "Piluwolu"
  },
  {
    "id": "9125212005",
    "district_id": "912521",
    "label": "Tenawi",
    "value": "Tenawi"
  },
  {
    "id": "9125212006",
    "district_id": "912521",
    "label": "Wanombut",
    "value": "Wanombut"
  },
  {
    "id": "9125212007",
    "district_id": "912521",
    "label": "Pumbanak",
    "value": "Pumbanak"
  },
  {
    "id": "9125222001",
    "district_id": "912522",
    "label": "Jiwot",
    "value": "Jiwot"
  },
  {
    "id": "9125222002",
    "district_id": "912522",
    "label": "Gunaluk",
    "value": "Gunaluk"
  },
  {
    "id": "9125222003",
    "district_id": "912522",
    "label": "Kugiame",
    "value": "Kugiame"
  },
  {
    "id": "9125222004",
    "district_id": "912522",
    "label": "Wuyunggame",
    "value": "Wuyunggame"
  },
  {
    "id": "9125222005",
    "district_id": "912522",
    "label": "Deogi",
    "value": "Deogi"
  },
  {
    "id": "9125222006",
    "district_id": "912522",
    "label": "Jugumi",
    "value": "Jugumi"
  },
  {
    "id": "9125232001",
    "district_id": "912523",
    "label": "Amungkalpia",
    "value": "Amungkalpia"
  },
  {
    "id": "9125232002",
    "district_id": "912523",
    "label": "Bela",
    "value": "Bela"
  },
  {
    "id": "9125232003",
    "district_id": "912523",
    "label": "Ompliga",
    "value": "Ompliga"
  },
  {
    "id": "9125232004",
    "district_id": "912523",
    "label": "Alama",
    "value": "Alama"
  },
  {
    "id": "9125232005",
    "district_id": "912523",
    "label": "Tagalarama",
    "value": "Tagalarama"
  },
  {
    "id": "9125232006",
    "district_id": "912523",
    "label": "Bemoki",
    "value": "Bemoki"
  },
  {
    "id": "9125242001",
    "district_id": "912524",
    "label": "Mundidok",
    "value": "Mundidok"
  },
  {
    "id": "9125242002",
    "district_id": "912524",
    "label": "Yaiki Maiki",
    "value": "Yaiki Maiki"
  },
  {
    "id": "9125242003",
    "district_id": "912524",
    "label": "Tobenggi",
    "value": "Tobenggi"
  },
  {
    "id": "9125242004",
    "district_id": "912524",
    "label": "Towenggi II",
    "value": "Towenggi II"
  },
  {
    "id": "9125242005",
    "district_id": "912524",
    "label": "Welenggaru",
    "value": "Welenggaru"
  },
  {
    "id": "9125252001",
    "district_id": "912525",
    "label": "Erelmakawia",
    "value": "Erelmakawia"
  },
  {
    "id": "9125252002",
    "district_id": "912525",
    "label": "Kalpamonora",
    "value": "Kalpamonora"
  },
  {
    "id": "9125252003",
    "district_id": "912525",
    "label": "Bomogin",
    "value": "Bomogin"
  },
  {
    "id": "9125252004",
    "district_id": "912525",
    "label": "Mamoldema",
    "value": "Mamoldema"
  },
  {
    "id": "9125252005",
    "district_id": "912525",
    "label": "Wamagun",
    "value": "Wamagun"
  },
  {
    "id": "9125252006",
    "district_id": "912525",
    "label": "Amungkonin",
    "value": "Amungkonin"
  },
  {
    "id": "9125252007",
    "district_id": "912525",
    "label": "Ipmangkop",
    "value": "Ipmangkop"
  },
  {
    "id": "9125252008",
    "district_id": "912525",
    "label": "Tamagun",
    "value": "Tamagun"
  },
  {
    "id": "9125252009",
    "district_id": "912525",
    "label": "Ai Arama",
    "value": "Ai Arama"
  },
  {
    "id": "9126012001",
    "district_id": "912601",
    "label": "Ikebo",
    "value": "Ikebo"
  },
  {
    "id": "9126012002",
    "district_id": "912601",
    "label": "Mauwa",
    "value": "Mauwa"
  },
  {
    "id": "9126012003",
    "district_id": "912601",
    "label": "Ekemanida",
    "value": "Ekemanida"
  },
  {
    "id": "9126012004",
    "district_id": "912601",
    "label": "Bukapa",
    "value": "Bukapa"
  },
  {
    "id": "9126012005",
    "district_id": "912601",
    "label": "Kimupugi",
    "value": "Kimupugi"
  },
  {
    "id": "9126012006",
    "district_id": "912601",
    "label": "Dikiyouwo",
    "value": "Dikiyouwo"
  },
  {
    "id": "9126012007",
    "district_id": "912601",
    "label": "Idakotu",
    "value": "Idakotu"
  },
  {
    "id": "9126012008",
    "district_id": "912601",
    "label": "Putapa",
    "value": "Putapa"
  },
  {
    "id": "9126022001",
    "district_id": "912602",
    "label": "Bobomani",
    "value": "Bobomani"
  },
  {
    "id": "9126022002",
    "district_id": "912602",
    "label": "Abaimaida",
    "value": "Abaimaida"
  },
  {
    "id": "9126022003",
    "district_id": "912602",
    "label": "Bonakunu",
    "value": "Bonakunu"
  },
  {
    "id": "9126022004",
    "district_id": "912602",
    "label": "Dawaikunu",
    "value": "Dawaikunu"
  },
  {
    "id": "9126022005",
    "district_id": "912602",
    "label": "Magone",
    "value": "Magone"
  },
  {
    "id": "9126022006",
    "district_id": "912602",
    "label": "Diyoudimi",
    "value": "Diyoudimi"
  },
  {
    "id": "9126022007",
    "district_id": "912602",
    "label": "Obaikagopa",
    "value": "Obaikagopa"
  },
  {
    "id": "9126032001",
    "district_id": "912603",
    "label": "Apogomakida",
    "value": "Apogomakida"
  },
  {
    "id": "9126032002",
    "district_id": "912603",
    "label": "Deneiode",
    "value": "Deneiode"
  },
  {
    "id": "9126032003",
    "district_id": "912603",
    "label": "Yegiyepa",
    "value": "Yegiyepa"
  },
  {
    "id": "9126032004",
    "district_id": "912603",
    "label": "Ideduwa",
    "value": "Ideduwa"
  },
  {
    "id": "9126032005",
    "district_id": "912603",
    "label": "Kegata",
    "value": "Kegata"
  },
  {
    "id": "9126032006",
    "district_id": "912603",
    "label": "Egipa",
    "value": "Egipa"
  },
  {
    "id": "9126032007",
    "district_id": "912603",
    "label": "Ukagu",
    "value": "Ukagu"
  },
  {
    "id": "9126032008",
    "district_id": "912603",
    "label": "Tibaugi",
    "value": "Tibaugi"
  },
  {
    "id": "9126042001",
    "district_id": "912604",
    "label": "Idakebo",
    "value": "Idakebo"
  },
  {
    "id": "9126042002",
    "district_id": "912604",
    "label": "Pugatadi I",
    "value": "Pugatadi I"
  },
  {
    "id": "9126042003",
    "district_id": "912604",
    "label": "Pugatadi II",
    "value": "Pugatadi II"
  },
  {
    "id": "9126042004",
    "district_id": "912604",
    "label": "Ikrar",
    "value": "Ikrar"
  },
  {
    "id": "9126042005",
    "district_id": "912604",
    "label": "Obayo",
    "value": "Obayo"
  },
  {
    "id": "9126042006",
    "district_id": "912604",
    "label": "Kuyakago",
    "value": "Kuyakago"
  },
  {
    "id": "9126042007",
    "district_id": "912604",
    "label": "Mogou",
    "value": "Mogou"
  },
  {
    "id": "9126042008",
    "district_id": "912604",
    "label": "Yametadi",
    "value": "Yametadi"
  },
  {
    "id": "9126042009",
    "district_id": "912604",
    "label": "Duntek",
    "value": "Duntek"
  },
  {
    "id": "9126042010",
    "district_id": "912604",
    "label": "Ekimani",
    "value": "Ekimani"
  },
  {
    "id": "9126052001",
    "district_id": "912605",
    "label": "Unito",
    "value": "Unito"
  },
  {
    "id": "9126052002",
    "district_id": "912605",
    "label": "Sukikai",
    "value": "Sukikai"
  },
  {
    "id": "9126052003",
    "district_id": "912605",
    "label": "Iyaro",
    "value": "Iyaro"
  },
  {
    "id": "9126052004",
    "district_id": "912605",
    "label": "Wigoumakida",
    "value": "Wigoumakida"
  },
  {
    "id": "9126062001",
    "district_id": "912606",
    "label": "Abouyaga",
    "value": "Abouyaga"
  },
  {
    "id": "9126062002",
    "district_id": "912606",
    "label": "Maikotu",
    "value": "Maikotu"
  },
  {
    "id": "9126062003",
    "district_id": "912606",
    "label": "Taubaikebo",
    "value": "Taubaikebo"
  },
  {
    "id": "9126062004",
    "district_id": "912606",
    "label": "Yegoukotu",
    "value": "Yegoukotu"
  },
  {
    "id": "9126072001",
    "district_id": "912607",
    "label": "Puweta I",
    "value": "Puweta I"
  },
  {
    "id": "9126072002",
    "district_id": "912607",
    "label": "Pouwouda",
    "value": "Pouwouda"
  },
  {
    "id": "9126072003",
    "district_id": "912607",
    "label": "Puweta II",
    "value": "Puweta II"
  },
  {
    "id": "9126072004",
    "district_id": "912607",
    "label": "Botumoma",
    "value": "Botumoma"
  },
  {
    "id": "9126072005",
    "district_id": "912607",
    "label": "Obaibega",
    "value": "Obaibega"
  },
  {
    "id": "9126072006",
    "district_id": "912607",
    "label": "Digikebo",
    "value": "Digikebo"
  },
  {
    "id": "9126072007",
    "district_id": "912607",
    "label": "Bogiyateugi",
    "value": "Bogiyateugi"
  },
  {
    "id": "9126072008",
    "district_id": "912607",
    "label": "Matadi",
    "value": "Matadi"
  },
  {
    "id": "9126072009",
    "district_id": "912607",
    "label": "Tuwaida",
    "value": "Tuwaida"
  },
  {
    "id": "9126072010",
    "district_id": "912607",
    "label": "Yepo",
    "value": "Yepo"
  },
  {
    "id": "9126072011",
    "district_id": "912607",
    "label": "Ugikagouda",
    "value": "Ugikagouda"
  },
  {
    "id": "9126072012",
    "district_id": "912607",
    "label": "Ugikebo",
    "value": "Ugikebo"
  },
  {
    "id": "9126082001",
    "district_id": "912608",
    "label": "Ugapuga",
    "value": "Ugapuga"
  },
  {
    "id": "9126082002",
    "district_id": "912608",
    "label": "Deiyapa",
    "value": "Deiyapa"
  },
  {
    "id": "9126082003",
    "district_id": "912608",
    "label": "Boduda",
    "value": "Boduda"
  },
  {
    "id": "9126082004",
    "district_id": "912608",
    "label": "Yotapuga",
    "value": "Yotapuga"
  },
  {
    "id": "9126082005",
    "district_id": "912608",
    "label": "Bokaibutu",
    "value": "Bokaibutu"
  },
  {
    "id": "9126082006",
    "district_id": "912608",
    "label": "Bunauwo",
    "value": "Bunauwo"
  },
  {
    "id": "9126082007",
    "district_id": "912608",
    "label": "Nuwa",
    "value": "Nuwa"
  },
  {
    "id": "9126092001",
    "district_id": "912609",
    "label": "Piyakunu",
    "value": "Piyakunu"
  },
  {
    "id": "9126092002",
    "district_id": "912609",
    "label": "Diyeugi",
    "value": "Diyeugi"
  },
  {
    "id": "9126092003",
    "district_id": "912609",
    "label": "Putaapa",
    "value": "Putaapa"
  },
  {
    "id": "9126092004",
    "district_id": "912609",
    "label": "Atou",
    "value": "Atou"
  },
  {
    "id": "9126092005",
    "district_id": "912609",
    "label": "Modio",
    "value": "Modio"
  },
  {
    "id": "9126092006",
    "district_id": "912609",
    "label": "Upibega",
    "value": "Upibega"
  },
  {
    "id": "9126092007",
    "district_id": "912609",
    "label": "Timepa",
    "value": "Timepa"
  },
  {
    "id": "9126092008",
    "district_id": "912609",
    "label": "Gabaikunu",
    "value": "Gabaikunu"
  },
  {
    "id": "9126092009",
    "district_id": "912609",
    "label": "Adauwo",
    "value": "Adauwo"
  },
  {
    "id": "9126092010",
    "district_id": "912609",
    "label": "Megaikebo",
    "value": "Megaikebo"
  },
  {
    "id": "9126102001",
    "district_id": "912610",
    "label": "Dogimani",
    "value": "Dogimani"
  },
  {
    "id": "9126102002",
    "district_id": "912610",
    "label": "Kigamani",
    "value": "Kigamani"
  },
  {
    "id": "9126102003",
    "district_id": "912610",
    "label": "Idadagi",
    "value": "Idadagi"
  },
  {
    "id": "9126102004",
    "district_id": "912610",
    "label": "Denemani",
    "value": "Denemani"
  },
  {
    "id": "9126102005",
    "district_id": "912610",
    "label": "Makidimi",
    "value": "Makidimi"
  },
  {
    "id": "9126102006",
    "district_id": "912610",
    "label": "Egebutu",
    "value": "Egebutu"
  },
  {
    "id": "9126102007",
    "district_id": "912610",
    "label": "Motito",
    "value": "Motito"
  },
  {
    "id": "9126102008",
    "district_id": "912610",
    "label": "Bobobutu",
    "value": "Bobobutu"
  },
  {
    "id": "9126102009",
    "district_id": "912610",
    "label": "Pona",
    "value": "Pona"
  },
  {
    "id": "9127012001",
    "district_id": "912701",
    "label": "Emondi",
    "value": "Emondi"
  },
  {
    "id": "9127012002",
    "district_id": "912701",
    "label": "Mindau",
    "value": "Mindau"
  },
  {
    "id": "9127012003",
    "district_id": "912701",
    "label": "Sambili",
    "value": "Sambili"
  },
  {
    "id": "9127012004",
    "district_id": "912701",
    "label": "Yoparu",
    "value": "Yoparu"
  },
  {
    "id": "9127012005",
    "district_id": "912701",
    "label": "Yokatapa",
    "value": "Yokatapa"
  },
  {
    "id": "9127012006",
    "district_id": "912701",
    "label": "Bilogai",
    "value": "Bilogai"
  },
  {
    "id": "9127012007",
    "district_id": "912701",
    "label": "Puyagiya",
    "value": "Puyagiya"
  },
  {
    "id": "9127012008",
    "district_id": "912701",
    "label": "Yalai",
    "value": "Yalai"
  },
  {
    "id": "9127012009",
    "district_id": "912701",
    "label": "Mamba",
    "value": "Mamba"
  },
  {
    "id": "9127012010",
    "district_id": "912701",
    "label": "Titigi",
    "value": "Titigi"
  },
  {
    "id": "9127012011",
    "district_id": "912701",
    "label": "Eknemba",
    "value": "Eknemba"
  },
  {
    "id": "9127012012",
    "district_id": "912701",
    "label": "Wandoga",
    "value": "Wandoga"
  },
  {
    "id": "9127012013",
    "district_id": "912701",
    "label": "Pesiga",
    "value": "Pesiga"
  },
  {
    "id": "9127012014",
    "district_id": "912701",
    "label": "Mbilusiga",
    "value": "Mbilusiga"
  },
  {
    "id": "9127012015",
    "district_id": "912701",
    "label": "Ndugusiga",
    "value": "Ndugusiga"
  },
  {
    "id": "9127012016",
    "district_id": "912701",
    "label": "Kumlagupa",
    "value": "Kumlagupa"
  },
  {
    "id": "9127012017",
    "district_id": "912701",
    "label": "Buwisiga",
    "value": "Buwisiga"
  },
  {
    "id": "9127022001",
    "district_id": "912702",
    "label": "Mapa",
    "value": "Mapa"
  },
  {
    "id": "9127022002",
    "district_id": "912702",
    "label": "Bilai Dua/ Sanepa",
    "value": "Bilai Dua/ Sanepa"
  },
  {
    "id": "9127022003",
    "district_id": "912702",
    "label": "Maya",
    "value": "Maya"
  },
  {
    "id": "9127022004",
    "district_id": "912702",
    "label": "Degesiga",
    "value": "Degesiga"
  },
  {
    "id": "9127022005",
    "district_id": "912702",
    "label": "Pogapa",
    "value": "Pogapa"
  },
  {
    "id": "9127022006",
    "district_id": "912702",
    "label": "Bamba",
    "value": "Bamba"
  },
  {
    "id": "9127022007",
    "district_id": "912702",
    "label": "Zombandoga",
    "value": "Zombandoga"
  },
  {
    "id": "9127022008",
    "district_id": "912702",
    "label": "Kobae",
    "value": "Kobae"
  },
  {
    "id": "9127022009",
    "district_id": "912702",
    "label": "Selemama",
    "value": "Selemama"
  },
  {
    "id": "9127022010",
    "district_id": "912702",
    "label": "Kendetapa",
    "value": "Kendetapa"
  },
  {
    "id": "9127022011",
    "district_id": "912702",
    "label": "Hiyabu",
    "value": "Hiyabu"
  },
  {
    "id": "9127022012",
    "district_id": "912702",
    "label": "Ogeapa",
    "value": "Ogeapa"
  },
  {
    "id": "9127022013",
    "district_id": "912702",
    "label": "Bonogo",
    "value": "Bonogo"
  },
  {
    "id": "9127022014",
    "district_id": "912702",
    "label": "Agapa",
    "value": "Agapa"
  },
  {
    "id": "9127022015",
    "district_id": "912702",
    "label": "Engganenga",
    "value": "Engganenga"
  },
  {
    "id": "9127022016",
    "district_id": "912702",
    "label": "Waiagepa",
    "value": "Waiagepa"
  },
  {
    "id": "9127022017",
    "district_id": "912702",
    "label": "Hugitapa",
    "value": "Hugitapa"
  },
  {
    "id": "9127022018",
    "district_id": "912702",
    "label": "Bubisiga",
    "value": "Bubisiga"
  },
  {
    "id": "9127022019",
    "district_id": "912702",
    "label": "Nggagemba",
    "value": "Nggagemba"
  },
  {
    "id": "9127022020",
    "district_id": "912702",
    "label": "Sanepa",
    "value": "Sanepa"
  },
  {
    "id": "9127022021",
    "district_id": "912702",
    "label": "Mbomogo",
    "value": "Mbomogo"
  },
  {
    "id": "9127032001",
    "district_id": "912703",
    "label": "Sabisa",
    "value": "Sabisa"
  },
  {
    "id": "9127032002",
    "district_id": "912703",
    "label": "Dubandoga",
    "value": "Dubandoga"
  },
  {
    "id": "9127032003",
    "district_id": "912703",
    "label": "Debasiga",
    "value": "Debasiga"
  },
  {
    "id": "9127032004",
    "district_id": "912703",
    "label": "Debasiga Dua",
    "value": "Debasiga Dua"
  },
  {
    "id": "9127032005",
    "district_id": "912703",
    "label": "Isandoga",
    "value": "Isandoga"
  },
  {
    "id": "9127032006",
    "district_id": "912703",
    "label": "Mogalo",
    "value": "Mogalo"
  },
  {
    "id": "9127032007",
    "district_id": "912703",
    "label": "Jae",
    "value": "Jae"
  },
  {
    "id": "9127032008",
    "district_id": "912703",
    "label": "Mbugulo",
    "value": "Mbugulo"
  },
  {
    "id": "9127032009",
    "district_id": "912703",
    "label": "Hulapuga",
    "value": "Hulapuga"
  },
  {
    "id": "9127042001",
    "district_id": "912704",
    "label": "Danggatadi",
    "value": "Danggatadi"
  },
  {
    "id": "9127042002",
    "district_id": "912704",
    "label": "Biandoga",
    "value": "Biandoga"
  },
  {
    "id": "9127042003",
    "district_id": "912704",
    "label": "Bugalaga",
    "value": "Bugalaga"
  },
  {
    "id": "9127042004",
    "district_id": "912704",
    "label": "Yagaito",
    "value": "Yagaito"
  },
  {
    "id": "9127042005",
    "district_id": "912704",
    "label": "Kalawa",
    "value": "Kalawa"
  },
  {
    "id": "9127042006",
    "district_id": "912704",
    "label": "Yanei",
    "value": "Yanei"
  },
  {
    "id": "9127042007",
    "district_id": "912704",
    "label": "Maniuwo",
    "value": "Maniuwo"
  },
  {
    "id": "9127042008",
    "district_id": "912704",
    "label": "Tunggapo",
    "value": "Tunggapo"
  },
  {
    "id": "9127042009",
    "district_id": "912704",
    "label": "Pagamba",
    "value": "Pagamba"
  },
  {
    "id": "9127042010",
    "district_id": "912704",
    "label": "Maolagi",
    "value": "Maolagi"
  },
  {
    "id": "9127042011",
    "district_id": "912704",
    "label": "Aneya",
    "value": "Aneya"
  },
  {
    "id": "9127042012",
    "district_id": "912704",
    "label": "Kigitadi",
    "value": "Kigitadi"
  },
  {
    "id": "9127042013",
    "district_id": "912704",
    "label": "Ndabatadi",
    "value": "Ndabatadi"
  },
  {
    "id": "9127042014",
    "district_id": "912704",
    "label": "Moyomataga",
    "value": "Moyomataga"
  },
  {
    "id": "9127042015",
    "district_id": "912704",
    "label": "Pitadi",
    "value": "Pitadi"
  },
  {
    "id": "9127042016",
    "district_id": "912704",
    "label": "Ular Merah",
    "value": "Ular Merah"
  },
  {
    "id": "9127052001",
    "district_id": "912705",
    "label": "Agisiga",
    "value": "Agisiga"
  },
  {
    "id": "9127052002",
    "district_id": "912705",
    "label": "Tousiga",
    "value": "Tousiga"
  },
  {
    "id": "9127052003",
    "district_id": "912705",
    "label": "Unabundoga",
    "value": "Unabundoga"
  },
  {
    "id": "9127052004",
    "district_id": "912705",
    "label": "Mbamogo",
    "value": "Mbamogo"
  },
  {
    "id": "9127052005",
    "district_id": "912705",
    "label": "Jenetapa Najasiga",
    "value": "Jenetapa Najasiga"
  },
  {
    "id": "9127052006",
    "district_id": "912705",
    "label": "Soali",
    "value": "Soali"
  },
  {
    "id": "9127052007",
    "district_id": "912705",
    "label": "Tambage",
    "value": "Tambage"
  },
  {
    "id": "9127052008",
    "district_id": "912705",
    "label": "Nabia",
    "value": "Nabia"
  },
  {
    "id": "9127052009",
    "district_id": "912705",
    "label": "Danggoa",
    "value": "Danggoa"
  },
  {
    "id": "9127052010",
    "district_id": "912705",
    "label": "Kombogosiga",
    "value": "Kombogosiga"
  },
  {
    "id": "9127062001",
    "district_id": "912706",
    "label": "Wabui",
    "value": "Wabui"
  },
  {
    "id": "9127062002",
    "district_id": "912706",
    "label": "Balamai",
    "value": "Balamai"
  },
  {
    "id": "9127062003",
    "district_id": "912706",
    "label": "Janamba",
    "value": "Janamba"
  },
  {
    "id": "9127062004",
    "district_id": "912706",
    "label": "Kulapa",
    "value": "Kulapa"
  },
  {
    "id": "9127062005",
    "district_id": "912706",
    "label": "Pugisiga",
    "value": "Pugisiga"
  },
  {
    "id": "9127062006",
    "district_id": "912706",
    "label": "Hitadipa",
    "value": "Hitadipa"
  },
  {
    "id": "9127062007",
    "district_id": "912706",
    "label": "Soagama",
    "value": "Soagama"
  },
  {
    "id": "9127062008",
    "district_id": "912706",
    "label": "Danggomba",
    "value": "Danggomba"
  },
  {
    "id": "9127062009",
    "district_id": "912706",
    "label": "Sakumba",
    "value": "Sakumba"
  },
  {
    "id": "9127072001",
    "district_id": "912707",
    "label": "Ugimba",
    "value": "Ugimba"
  },
  {
    "id": "9127072002",
    "district_id": "912707",
    "label": "Bunaopa",
    "value": "Bunaopa"
  },
  {
    "id": "9127072003",
    "district_id": "912707",
    "label": "Pigabu",
    "value": "Pigabu"
  },
  {
    "id": "9127072004",
    "district_id": "912707",
    "label": "Teteopa",
    "value": "Teteopa"
  },
  {
    "id": "9127072005",
    "district_id": "912707",
    "label": "Dukendopa",
    "value": "Dukendopa"
  },
  {
    "id": "9127072006",
    "district_id": "912707",
    "label": "Nggamagae",
    "value": "Nggamagae"
  },
  {
    "id": "9127082001",
    "district_id": "912708",
    "label": "Tomosiga",
    "value": "Tomosiga"
  },
  {
    "id": "9127082002",
    "district_id": "912708",
    "label": "Bigasiga",
    "value": "Bigasiga"
  },
  {
    "id": "9127082003",
    "district_id": "912708",
    "label": "Dapiaga",
    "value": "Dapiaga"
  },
  {
    "id": "9127082004",
    "district_id": "912708",
    "label": "Pewesiga",
    "value": "Pewesiga"
  },
  {
    "id": "9127082005",
    "district_id": "912708",
    "label": "Hegenagai",
    "value": "Hegenagai"
  },
  {
    "id": "9127082006",
    "district_id": "912708",
    "label": "Gaimigi",
    "value": "Gaimigi"
  },
  {
    "id": "9127082007",
    "district_id": "912708",
    "label": "Jawasiga",
    "value": "Jawasiga"
  },
  {
    "id": "9127082008",
    "district_id": "912708",
    "label": "Duginggobo",
    "value": "Duginggobo"
  },
  {
    "id": "9127082009",
    "district_id": "912708",
    "label": "Sugulubagala",
    "value": "Sugulubagala"
  },
  {
    "id": "9128012001",
    "district_id": "912801",
    "label": "Waghete I",
    "value": "Waghete I"
  },
  {
    "id": "9128012002",
    "district_id": "912801",
    "label": "Ugiya",
    "value": "Ugiya"
  },
  {
    "id": "9128012003",
    "district_id": "912801",
    "label": "Yaba",
    "value": "Yaba"
  },
  {
    "id": "9128012004",
    "district_id": "912801",
    "label": "Waghete II",
    "value": "Waghete II"
  },
  {
    "id": "9128012005",
    "district_id": "912801",
    "label": "Bomau I",
    "value": "Bomau I"
  },
  {
    "id": "9128012006",
    "district_id": "912801",
    "label": "Okomakebo",
    "value": "Okomakebo"
  },
  {
    "id": "9128012007",
    "district_id": "912801",
    "label": "Bamou II",
    "value": "Bamou II"
  },
  {
    "id": "9128012008",
    "district_id": "912801",
    "label": "Atouda",
    "value": "Atouda"
  },
  {
    "id": "9128012009",
    "district_id": "912801",
    "label": "Oneibo",
    "value": "Oneibo"
  },
  {
    "id": "9128012010",
    "district_id": "912801",
    "label": "Yaba II",
    "value": "Yaba II"
  },
  {
    "id": "9128012011",
    "district_id": "912801",
    "label": "Mugouda",
    "value": "Mugouda"
  },
  {
    "id": "9128012012",
    "district_id": "912801",
    "label": "Bomou III",
    "value": "Bomou III"
  },
  {
    "id": "9128012013",
    "district_id": "912801",
    "label": "Okomotadi",
    "value": "Okomotadi"
  },
  {
    "id": "9128012014",
    "district_id": "912801",
    "label": "Buwoudimi",
    "value": "Buwoudimi"
  },
  {
    "id": "9128012015",
    "district_id": "912801",
    "label": "Ibodiyo",
    "value": "Ibodiyo"
  },
  {
    "id": "9128012016",
    "district_id": "912801",
    "label": "Ikiyawo",
    "value": "Ikiyawo"
  },
  {
    "id": "9128012017",
    "district_id": "912801",
    "label": "Motano",
    "value": "Motano"
  },
  {
    "id": "9128012018",
    "district_id": "912801",
    "label": "Amago",
    "value": "Amago"
  },
  {
    "id": "9128012019",
    "district_id": "912801",
    "label": "Idege",
    "value": "Idege"
  },
  {
    "id": "9128012020",
    "district_id": "912801",
    "label": "Peku",
    "value": "Peku"
  },
  {
    "id": "9128022001",
    "district_id": "912802",
    "label": "Damabagata",
    "value": "Damabagata"
  },
  {
    "id": "9128022002",
    "district_id": "912802",
    "label": "Kokobaya",
    "value": "Kokobaya"
  },
  {
    "id": "9128022003",
    "district_id": "912802",
    "label": "Watiyai",
    "value": "Watiyai"
  },
  {
    "id": "9128022004",
    "district_id": "912802",
    "label": "Edagotadi",
    "value": "Edagotadi"
  },
  {
    "id": "9128022005",
    "district_id": "912802",
    "label": "Dakebo",
    "value": "Dakebo"
  },
  {
    "id": "9128022006",
    "district_id": "912802",
    "label": "Bagou",
    "value": "Bagou"
  },
  {
    "id": "9128022007",
    "district_id": "912802",
    "label": "Dagokebo",
    "value": "Dagokebo"
  },
  {
    "id": "9128022008",
    "district_id": "912802",
    "label": "Ipoke",
    "value": "Ipoke"
  },
  {
    "id": "9128022009",
    "district_id": "912802",
    "label": "Waitakotu",
    "value": "Waitakotu"
  },
  {
    "id": "9128022010",
    "district_id": "912802",
    "label": "Pekepa",
    "value": "Pekepa"
  },
  {
    "id": "9128022011",
    "district_id": "912802",
    "label": "Bagumoma",
    "value": "Bagumoma"
  },
  {
    "id": "9128022012",
    "district_id": "912802",
    "label": "Udaugida",
    "value": "Udaugida"
  },
  {
    "id": "9128022013",
    "district_id": "912802",
    "label": "Bagou II",
    "value": "Bagou II"
  },
  {
    "id": "9128022014",
    "district_id": "912802",
    "label": "Idayodagi",
    "value": "Idayodagi"
  },
  {
    "id": "9128032001",
    "district_id": "912803",
    "label": "Kopai I",
    "value": "Kopai I"
  },
  {
    "id": "9128032002",
    "district_id": "912803",
    "label": "Kopai II",
    "value": "Kopai II"
  },
  {
    "id": "9128032003",
    "district_id": "912803",
    "label": "Woge",
    "value": "Woge"
  },
  {
    "id": "9128032004",
    "district_id": "912803",
    "label": "Yewadide",
    "value": "Yewadide"
  },
  {
    "id": "9128032005",
    "district_id": "912803",
    "label": "Mudetadi",
    "value": "Mudetadi"
  },
  {
    "id": "9128032006",
    "district_id": "912803",
    "label": "Diita",
    "value": "Diita"
  },
  {
    "id": "9128042001",
    "district_id": "912804",
    "label": "Gakokebo",
    "value": "Gakokebo"
  },
  {
    "id": "9128042002",
    "district_id": "912804",
    "label": "Demago",
    "value": "Demago"
  },
  {
    "id": "9128042003",
    "district_id": "912804",
    "label": "Widuwakiya",
    "value": "Widuwakiya"
  },
  {
    "id": "9128042004",
    "district_id": "912804",
    "label": "Onago",
    "value": "Onago"
  },
  {
    "id": "9128042005",
    "district_id": "912804",
    "label": "Wagomani",
    "value": "Wagomani"
  },
  {
    "id": "9128042006",
    "district_id": "912804",
    "label": "Digibagata",
    "value": "Digibagata"
  },
  {
    "id": "9128042007",
    "district_id": "912804",
    "label": "Piyekedimi",
    "value": "Piyekedimi"
  },
  {
    "id": "9128042008",
    "district_id": "912804",
    "label": "Ayatei",
    "value": "Ayatei"
  },
  {
    "id": "9128042009",
    "district_id": "912804",
    "label": "Tenedagi",
    "value": "Tenedagi"
  },
  {
    "id": "9128042010",
    "district_id": "912804",
    "label": "Diyai",
    "value": "Diyai"
  },
  {
    "id": "9128042011",
    "district_id": "912804",
    "label": "Jinidaba",
    "value": "Jinidaba"
  },
  {
    "id": "9128042012",
    "district_id": "912804",
    "label": "Widimei",
    "value": "Widimei"
  },
  {
    "id": "9128042013",
    "district_id": "912804",
    "label": "Yagu",
    "value": "Yagu"
  },
  {
    "id": "9128042014",
    "district_id": "912804",
    "label": "Obai",
    "value": "Obai"
  },
  {
    "id": "9128042015",
    "district_id": "912804",
    "label": "Yipai",
    "value": "Yipai"
  },
  {
    "id": "9128042016",
    "district_id": "912804",
    "label": "Diyai II",
    "value": "Diyai II"
  },
  {
    "id": "9128042017",
    "district_id": "912804",
    "label": "Meyepa",
    "value": "Meyepa"
  },
  {
    "id": "9128042018",
    "district_id": "912804",
    "label": "Kogemani",
    "value": "Kogemani"
  },
  {
    "id": "9128042019",
    "district_id": "912804",
    "label": "Digikotu",
    "value": "Digikotu"
  },
  {
    "id": "9128042020",
    "district_id": "912804",
    "label": "Onago II",
    "value": "Onago II"
  },
  {
    "id": "9128042021",
    "district_id": "912804",
    "label": "Maatadi",
    "value": "Maatadi"
  },
  {
    "id": "9128042022",
    "district_id": "912804",
    "label": "Epanai",
    "value": "Epanai"
  },
  {
    "id": "9128052001",
    "district_id": "912805",
    "label": "Komauto/Kapiraya",
    "value": "Komauto/Kapiraya"
  },
  {
    "id": "9128052002",
    "district_id": "912805",
    "label": "Mogodagi",
    "value": "Mogodagi"
  },
  {
    "id": "9128052003",
    "district_id": "912805",
    "label": "Yamouwitina",
    "value": "Yamouwitina"
  },
  {
    "id": "9128052004",
    "district_id": "912805",
    "label": "Idego",
    "value": "Idego"
  },
  {
    "id": "9128052005",
    "district_id": "912805",
    "label": "Uwe Onagei",
    "value": "Uwe Onagei"
  },
  {
    "id": "9171011001",
    "district_id": "917101",
    "label": "Gurabesi",
    "value": "Gurabesi"
  },
  {
    "id": "9171011002",
    "district_id": "917101",
    "label": "Bayangkara",
    "value": "Bayangkara"
  },
  {
    "id": "9171011003",
    "district_id": "917101",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "9171011004",
    "district_id": "917101",
    "label": "Imbi",
    "value": "Imbi"
  },
  {
    "id": "9171011005",
    "district_id": "917101",
    "label": "Tanjung Ria",
    "value": "Tanjung Ria"
  },
  {
    "id": "9171011006",
    "district_id": "917101",
    "label": "Mandala",
    "value": "Mandala"
  },
  {
    "id": "9171011007",
    "district_id": "917101",
    "label": "Angkasapura",
    "value": "Angkasapura"
  },
  {
    "id": "9171012008",
    "district_id": "917101",
    "label": "Kayo Batu",
    "value": "Kayo Batu"
  },
  {
    "id": "9171021001",
    "district_id": "917102",
    "label": "Argapura",
    "value": "Argapura"
  },
  {
    "id": "9171021002",
    "district_id": "917102",
    "label": "Ardipura",
    "value": "Ardipura"
  },
  {
    "id": "9171021003",
    "district_id": "917102",
    "label": "Numbay",
    "value": "Numbay"
  },
  {
    "id": "9171021005",
    "district_id": "917102",
    "label": "Entrop",
    "value": "Entrop"
  },
  {
    "id": "9171021006",
    "district_id": "917102",
    "label": "Hamadi",
    "value": "Hamadi"
  },
  {
    "id": "9171022007",
    "district_id": "917102",
    "label": "Tahima Sorama",
    "value": "Tahima Sorama"
  },
  {
    "id": "9171022008",
    "district_id": "917102",
    "label": "Tobati",
    "value": "Tobati"
  },
  {
    "id": "9171031002",
    "district_id": "917103",
    "label": "Asano",
    "value": "Asano"
  },
  {
    "id": "9171031008",
    "district_id": "917103",
    "label": "Awiyo",
    "value": "Awiyo"
  },
  {
    "id": "9171031010",
    "district_id": "917103",
    "label": "Yobe",
    "value": "Yobe"
  },
  {
    "id": "9171031011",
    "district_id": "917103",
    "label": "Abepantai",
    "value": "Abepantai"
  },
  {
    "id": "9171031012",
    "district_id": "917103",
    "label": "Kota Baru",
    "value": "Kota Baru"
  },
  {
    "id": "9171031014",
    "district_id": "917103",
    "label": "Vim",
    "value": "Vim"
  },
  {
    "id": "9171031015",
    "district_id": "917103",
    "label": "Wahno",
    "value": "Wahno"
  },
  {
    "id": "9171031016",
    "district_id": "917103",
    "label": "Way Mhorock",
    "value": "Way Mhorock"
  },
  {
    "id": "9171032001",
    "district_id": "917103",
    "label": "Nafri",
    "value": "Nafri"
  },
  {
    "id": "9171032004",
    "district_id": "917103",
    "label": "Enggros",
    "value": "Enggros"
  },
  {
    "id": "9171032007",
    "district_id": "917103",
    "label": "Koya Koso",
    "value": "Koya Koso"
  },
  {
    "id": "9171041004",
    "district_id": "917104",
    "label": "Koya Barat",
    "value": "Koya Barat"
  },
  {
    "id": "9171041005",
    "district_id": "917104",
    "label": "Koya Timur",
    "value": "Koya Timur"
  },
  {
    "id": "9171042001",
    "district_id": "917104",
    "label": "Skouw Mabo",
    "value": "Skouw Mabo"
  },
  {
    "id": "9171042002",
    "district_id": "917104",
    "label": "Skouw Yambe",
    "value": "Skouw Yambe"
  },
  {
    "id": "9171042003",
    "district_id": "917104",
    "label": "Skouw Sae",
    "value": "Skouw Sae"
  },
  {
    "id": "9171042006",
    "district_id": "917104",
    "label": "Holtekamp",
    "value": "Holtekamp"
  },
  {
    "id": "9171042007",
    "district_id": "917104",
    "label": "Koya Tengah",
    "value": "Koya Tengah"
  },
  {
    "id": "9171042008",
    "district_id": "917104",
    "label": "Mosso",
    "value": "Mosso"
  },
  {
    "id": "9171051001",
    "district_id": "917105",
    "label": "Hedam",
    "value": "Hedam"
  },
  {
    "id": "9171051002",
    "district_id": "917105",
    "label": "Waena",
    "value": "Waena"
  },
  {
    "id": "9171051004",
    "district_id": "917105",
    "label": "Yabansai",
    "value": "Yabansai"
  },
  {
    "id": "9171052003",
    "district_id": "917105",
    "label": "Yoka",
    "value": "Yoka"
  },
  {
    "id": "9171052005",
    "district_id": "917105",
    "label": "Waena",
    "value": "Waena"
  },
  {
    "id": "9201011001",
    "district_id": "920101",
    "label": "Makbon",
    "value": "Makbon"
  },
  {
    "id": "9201012002",
    "district_id": "920101",
    "label": "Asbaken",
    "value": "Asbaken"
  },
  {
    "id": "9201012004",
    "district_id": "920101",
    "label": "Malaumkarta",
    "value": "Malaumkarta"
  },
  {
    "id": "9201012005",
    "district_id": "920101",
    "label": "Batu Lubang",
    "value": "Batu Lubang"
  },
  {
    "id": "9201012007",
    "district_id": "920101",
    "label": "Batu Lubang Pantai",
    "value": "Batu Lubang Pantai"
  },
  {
    "id": "9201012008",
    "district_id": "920101",
    "label": "Kwadas",
    "value": "Kwadas"
  },
  {
    "id": "9201012009",
    "district_id": "920101",
    "label": "Bainkete",
    "value": "Bainkete"
  },
  {
    "id": "9201012010",
    "district_id": "920101",
    "label": "Teluk Dore",
    "value": "Teluk Dore"
  },
  {
    "id": "9201012011",
    "district_id": "920101",
    "label": "Sawatuk",
    "value": "Sawatuk"
  },
  {
    "id": "9201012012",
    "district_id": "920101",
    "label": "Malagasih",
    "value": "Malagasih"
  },
  {
    "id": "9201012013",
    "district_id": "920101",
    "label": "Klasimigik",
    "value": "Klasimigik"
  },
  {
    "id": "9201012014",
    "district_id": "920101",
    "label": "Klasigi",
    "value": "Klasigi"
  },
  {
    "id": "9201012015",
    "district_id": "920101",
    "label": "Klagulus",
    "value": "Klagulus"
  },
  {
    "id": "9201012016",
    "district_id": "920101",
    "label": "Suatolo",
    "value": "Suatolo"
  },
  {
    "id": "9201012017",
    "district_id": "920101",
    "label": "Mibi",
    "value": "Mibi"
  },
  {
    "id": "9201042001",
    "district_id": "920104",
    "label": "Klabra",
    "value": "Klabra"
  },
  {
    "id": "9201042002",
    "district_id": "920104",
    "label": "Wanurian",
    "value": "Wanurian"
  },
  {
    "id": "9201042009",
    "district_id": "920104",
    "label": "Klarion",
    "value": "Klarion"
  },
  {
    "id": "9201042011",
    "district_id": "920104",
    "label": "Kaas",
    "value": "Kaas"
  },
  {
    "id": "9201042015",
    "district_id": "920104",
    "label": "Wensi",
    "value": "Wensi"
  },
  {
    "id": "9201042016",
    "district_id": "920104",
    "label": "Mumpi",
    "value": "Mumpi"
  },
  {
    "id": "9201042019",
    "district_id": "920104",
    "label": "Klamak",
    "value": "Klamak"
  },
  {
    "id": "9201042020",
    "district_id": "920104",
    "label": "Serselion",
    "value": "Serselion"
  },
  {
    "id": "9201042022",
    "district_id": "920104",
    "label": "Kmafo",
    "value": "Kmafo"
  },
  {
    "id": "9201042027",
    "district_id": "920104",
    "label": "Midlal",
    "value": "Midlal"
  },
  {
    "id": "9201042028",
    "district_id": "920104",
    "label": "Sorain",
    "value": "Sorain"
  },
  {
    "id": "9201042033",
    "district_id": "920104",
    "label": "Armoin",
    "value": "Armoin"
  },
  {
    "id": "9201042034",
    "district_id": "920104",
    "label": "Serselion Baru",
    "value": "Serselion Baru"
  },
  {
    "id": "9201051001",
    "district_id": "920105",
    "label": "Katinim",
    "value": "Katinim"
  },
  {
    "id": "9201051006",
    "district_id": "920105",
    "label": "Majener",
    "value": "Majener"
  },
  {
    "id": "9201051007",
    "district_id": "920105",
    "label": "Majaran",
    "value": "Majaran"
  },
  {
    "id": "9201051009",
    "district_id": "920105",
    "label": "Matawolot",
    "value": "Matawolot"
  },
  {
    "id": "9201052011",
    "district_id": "920105",
    "label": "Walal",
    "value": "Walal"
  },
  {
    "id": "9201052012",
    "district_id": "920105",
    "label": "Malaus",
    "value": "Malaus"
  },
  {
    "id": "9201052018",
    "district_id": "920105",
    "label": "Rawa Sugi",
    "value": "Rawa Sugi"
  },
  {
    "id": "9201062001",
    "district_id": "920106",
    "label": "Seget",
    "value": "Seget"
  },
  {
    "id": "9201062004",
    "district_id": "920106",
    "label": "Malaban",
    "value": "Malaban"
  },
  {
    "id": "9201062005",
    "district_id": "920106",
    "label": "Pulau Kasim",
    "value": "Pulau Kasim"
  },
  {
    "id": "9201062010",
    "district_id": "920106",
    "label": "Kasimle",
    "value": "Kasimle"
  },
  {
    "id": "9201062011",
    "district_id": "920106",
    "label": "Wewenagu",
    "value": "Wewenagu"
  },
  {
    "id": "9201062012",
    "district_id": "920106",
    "label": "Wasingsan",
    "value": "Wasingsan"
  },
  {
    "id": "9201062013",
    "district_id": "920106",
    "label": "Klayas",
    "value": "Klayas"
  },
  {
    "id": "9201062014",
    "district_id": "920106",
    "label": "Wayenkede",
    "value": "Wayenkede"
  },
  {
    "id": "9201062015",
    "district_id": "920106",
    "label": "Mobi",
    "value": "Mobi"
  },
  {
    "id": "9201071001",
    "district_id": "920107",
    "label": "Aimas",
    "value": "Aimas"
  },
  {
    "id": "9201071002",
    "district_id": "920107",
    "label": "Malawili",
    "value": "Malawili"
  },
  {
    "id": "9201071003",
    "district_id": "920107",
    "label": "Malawele",
    "value": "Malawele"
  },
  {
    "id": "9201071006",
    "district_id": "920107",
    "label": "Mariat Pantai",
    "value": "Mariat Pantai"
  },
  {
    "id": "9201071026",
    "district_id": "920107",
    "label": "Mariat Gunung",
    "value": "Mariat Gunung"
  },
  {
    "id": "9201071029",
    "district_id": "920107",
    "label": "Warmon",
    "value": "Warmon"
  },
  {
    "id": "9201071030",
    "district_id": "920107",
    "label": "Klabinain",
    "value": "Klabinain"
  },
  {
    "id": "9201071031",
    "district_id": "920107",
    "label": "Malasom",
    "value": "Malasom"
  },
  {
    "id": "9201071032",
    "district_id": "920107",
    "label": "Klaigit",
    "value": "Klaigit"
  },
  {
    "id": "9201071034",
    "district_id": "920107",
    "label": "Malagusa",
    "value": "Malagusa"
  },
  {
    "id": "9201071035",
    "district_id": "920107",
    "label": "Klafma",
    "value": "Klafma"
  },
  {
    "id": "9201072028",
    "district_id": "920107",
    "label": "Maibo",
    "value": "Maibo"
  },
  {
    "id": "9201072033",
    "district_id": "920107",
    "label": "Aimo",
    "value": "Aimo"
  },
  {
    "id": "9201072038",
    "district_id": "920107",
    "label": "Malasaum",
    "value": "Malasaum"
  },
  {
    "id": "9201082001",
    "district_id": "920108",
    "label": "Klawana",
    "value": "Klawana"
  },
  {
    "id": "9201082002",
    "district_id": "920108",
    "label": "Klamano",
    "value": "Klamano"
  },
  {
    "id": "9201082003",
    "district_id": "920108",
    "label": "Wariayau",
    "value": "Wariayau"
  },
  {
    "id": "9201082008",
    "district_id": "920108",
    "label": "Klamono",
    "value": "Klamono"
  },
  {
    "id": "9201082009",
    "district_id": "920108",
    "label": "Wonosari",
    "value": "Wonosari"
  },
  {
    "id": "9201082010",
    "district_id": "920108",
    "label": "Gisim Darat",
    "value": "Gisim Darat"
  },
  {
    "id": "9201082013",
    "district_id": "920108",
    "label": "Mlais",
    "value": "Mlais"
  },
  {
    "id": "9201082020",
    "district_id": "920108",
    "label": "Mlasmily",
    "value": "Mlasmily"
  },
  {
    "id": "9201082021",
    "district_id": "920108",
    "label": "Klasafet",
    "value": "Klasafet"
  },
  {
    "id": "9201082022",
    "district_id": "920108",
    "label": "Posa",
    "value": "Posa"
  },
  {
    "id": "9201102001",
    "district_id": "920110",
    "label": "Sayosa",
    "value": "Sayosa"
  },
  {
    "id": "9201102004",
    "district_id": "920110",
    "label": "Maladofok",
    "value": "Maladofok"
  },
  {
    "id": "9201102006",
    "district_id": "920110",
    "label": "Yorbes",
    "value": "Yorbes"
  },
  {
    "id": "9201102011",
    "district_id": "920110",
    "label": "Samusa",
    "value": "Samusa"
  },
  {
    "id": "9201102012",
    "district_id": "920110",
    "label": "Klamintu",
    "value": "Klamintu"
  },
  {
    "id": "9201102014",
    "district_id": "920110",
    "label": "Klain",
    "value": "Klain"
  },
  {
    "id": "9201122001",
    "district_id": "920112",
    "label": "Segun",
    "value": "Segun"
  },
  {
    "id": "9201122002",
    "district_id": "920112",
    "label": "Gisim",
    "value": "Gisim"
  },
  {
    "id": "9201122003",
    "district_id": "920112",
    "label": "Waimon",
    "value": "Waimon"
  },
  {
    "id": "9201122004",
    "district_id": "920112",
    "label": "Klasegun",
    "value": "Klasegun"
  },
  {
    "id": "9201122005",
    "district_id": "920112",
    "label": "Waimlabat",
    "value": "Waimlabat"
  },
  {
    "id": "9201122006",
    "district_id": "920112",
    "label": "Majemau",
    "value": "Majemau"
  },
  {
    "id": "9201122008",
    "district_id": "920112",
    "label": "Klasin",
    "value": "Klasin"
  },
  {
    "id": "9201122009",
    "district_id": "920112",
    "label": "Klajaring",
    "value": "Klajaring"
  },
  {
    "id": "9201122010",
    "district_id": "920112",
    "label": "Malamas",
    "value": "Malamas"
  },
  {
    "id": "9201131001",
    "district_id": "920113",
    "label": "Makbusum",
    "value": "Makbusum"
  },
  {
    "id": "9201131002",
    "district_id": "920113",
    "label": "Makbalim",
    "value": "Makbalim"
  },
  {
    "id": "9201131003",
    "district_id": "920113",
    "label": "Makotyamsa",
    "value": "Makotyamsa"
  },
  {
    "id": "9201132004",
    "district_id": "920113",
    "label": "Jeflio",
    "value": "Jeflio"
  },
  {
    "id": "9201132005",
    "district_id": "920113",
    "label": "Arar",
    "value": "Arar"
  },
  {
    "id": "9201132006",
    "district_id": "920113",
    "label": "Klalin",
    "value": "Klalin"
  },
  {
    "id": "9201132007",
    "district_id": "920113",
    "label": "Klasmelek",
    "value": "Klasmelek"
  },
  {
    "id": "9201132008",
    "district_id": "920113",
    "label": "Wen",
    "value": "Wen"
  },
  {
    "id": "9201132009",
    "district_id": "920113",
    "label": "Warmon Kokoda",
    "value": "Warmon Kokoda"
  },
  {
    "id": "9201132010",
    "district_id": "920113",
    "label": "Asjidba",
    "value": "Asjidba"
  },
  {
    "id": "9201132011",
    "district_id": "920113",
    "label": "Malamay",
    "value": "Malamay"
  },
  {
    "id": "9201142001",
    "district_id": "920114",
    "label": "Sailolof",
    "value": "Sailolof"
  },
  {
    "id": "9201142002",
    "district_id": "920114",
    "label": "Klotlol",
    "value": "Klotlol"
  },
  {
    "id": "9201142007",
    "district_id": "920114",
    "label": "Dulbatan",
    "value": "Dulbatan"
  },
  {
    "id": "9201142008",
    "district_id": "920114",
    "label": "Manoket",
    "value": "Manoket"
  },
  {
    "id": "9201142011",
    "district_id": "920114",
    "label": "Payapop",
    "value": "Payapop"
  },
  {
    "id": "9201142012",
    "district_id": "920114",
    "label": "Masmaspop",
    "value": "Masmaspop"
  },
  {
    "id": "9201172003",
    "district_id": "920117",
    "label": "Indiwi",
    "value": "Indiwi"
  },
  {
    "id": "9201172004",
    "district_id": "920117",
    "label": "Klabot",
    "value": "Klabot"
  },
  {
    "id": "9201172005",
    "district_id": "920117",
    "label": "Mlat",
    "value": "Mlat"
  },
  {
    "id": "9201172008",
    "district_id": "920117",
    "label": "Mlawes",
    "value": "Mlawes"
  },
  {
    "id": "9201172009",
    "district_id": "920117",
    "label": "Mlasfa",
    "value": "Mlasfa"
  },
  {
    "id": "9201172010",
    "district_id": "920117",
    "label": "Kanolo",
    "value": "Kanolo"
  },
  {
    "id": "9201172014",
    "district_id": "920117",
    "label": "Somir",
    "value": "Somir"
  },
  {
    "id": "9201172015",
    "district_id": "920117",
    "label": "Warkti",
    "value": "Warkti"
  },
  {
    "id": "9201182001",
    "district_id": "920118",
    "label": "Wilty",
    "value": "Wilty"
  },
  {
    "id": "9201182004",
    "district_id": "920118",
    "label": "Mlawen",
    "value": "Mlawen"
  },
  {
    "id": "9201182008",
    "district_id": "920118",
    "label": "Byaklokfle",
    "value": "Byaklokfle"
  },
  {
    "id": "9201182009",
    "district_id": "920118",
    "label": "Tbotjin",
    "value": "Tbotjin"
  },
  {
    "id": "9201182010",
    "district_id": "920118",
    "label": "Sas",
    "value": "Sas"
  },
  {
    "id": "9201182011",
    "district_id": "920118",
    "label": "Samolslo",
    "value": "Samolslo"
  },
  {
    "id": "9201182012",
    "district_id": "920118",
    "label": "Kmasen",
    "value": "Kmasen"
  },
  {
    "id": "9201182013",
    "district_id": "920118",
    "label": "Klasawon",
    "value": "Klasawon"
  },
  {
    "id": "9201182014",
    "district_id": "920118",
    "label": "Yurkaden",
    "value": "Yurkaden"
  },
  {
    "id": "9201182019",
    "district_id": "920118",
    "label": "Betwaft",
    "value": "Betwaft"
  },
  {
    "id": "9201182025",
    "district_id": "920118",
    "label": "Cokif",
    "value": "Cokif"
  },
  {
    "id": "9201202001",
    "district_id": "920120",
    "label": "Luwelala",
    "value": "Luwelala"
  },
  {
    "id": "9201202003",
    "district_id": "920120",
    "label": "Klatim",
    "value": "Klatim"
  },
  {
    "id": "9201202004",
    "district_id": "920120",
    "label": "Klaos",
    "value": "Klaos"
  },
  {
    "id": "9201202006",
    "district_id": "920120",
    "label": "Suluh",
    "value": "Suluh"
  },
  {
    "id": "9201202009",
    "district_id": "920120",
    "label": "Bosi",
    "value": "Bosi"
  },
  {
    "id": "9201202010",
    "district_id": "920120",
    "label": "Kamuyar",
    "value": "Kamuyar"
  },
  {
    "id": "9201202011",
    "district_id": "920120",
    "label": "Klaka",
    "value": "Klaka"
  },
  {
    "id": "9201202012",
    "district_id": "920120",
    "label": "Safdesa",
    "value": "Safdesa"
  },
  {
    "id": "9201202015",
    "district_id": "920120",
    "label": "Klagen",
    "value": "Klagen"
  },
  {
    "id": "9201391001",
    "district_id": "920139",
    "label": "Klamalu",
    "value": "Klamalu"
  },
  {
    "id": "9201391002",
    "district_id": "920139",
    "label": "Mariyai",
    "value": "Mariyai"
  },
  {
    "id": "9201391003",
    "district_id": "920139",
    "label": "Klasukuk",
    "value": "Klasukuk"
  },
  {
    "id": "9201391004",
    "district_id": "920139",
    "label": "Klamasen",
    "value": "Klamasen"
  },
  {
    "id": "9201391009",
    "district_id": "920139",
    "label": "Maklalut",
    "value": "Maklalut"
  },
  {
    "id": "9201391010",
    "district_id": "920139",
    "label": "Klaru",
    "value": "Klaru"
  },
  {
    "id": "9201391011",
    "district_id": "920139",
    "label": "Jamaimo",
    "value": "Jamaimo"
  },
  {
    "id": "9201392005",
    "district_id": "920139",
    "label": "Klaben",
    "value": "Klaben"
  },
  {
    "id": "9201392007",
    "district_id": "920139",
    "label": "Klasan",
    "value": "Klasan"
  },
  {
    "id": "9201392008",
    "district_id": "920139",
    "label": "Fafi",
    "value": "Fafi"
  },
  {
    "id": "9201392012",
    "district_id": "920139",
    "label": "Kasih",
    "value": "Kasih"
  },
  {
    "id": "9201402001",
    "district_id": "920140",
    "label": "Klayili",
    "value": "Klayili"
  },
  {
    "id": "9201402002",
    "district_id": "920140",
    "label": "Klawuluh",
    "value": "Klawuluh"
  },
  {
    "id": "9201402003",
    "district_id": "920140",
    "label": "Malakobutu",
    "value": "Malakobutu"
  },
  {
    "id": "9201402004",
    "district_id": "920140",
    "label": "Kwakeik",
    "value": "Kwakeik"
  },
  {
    "id": "9201402005",
    "district_id": "920140",
    "label": "Klasowoh",
    "value": "Klasowoh"
  },
  {
    "id": "9201402006",
    "district_id": "920140",
    "label": "Malalilis",
    "value": "Malalilis"
  },
  {
    "id": "9201402007",
    "district_id": "920140",
    "label": "Mlawer",
    "value": "Mlawer"
  },
  {
    "id": "9201402008",
    "district_id": "920140",
    "label": "Klatomok",
    "value": "Klatomok"
  },
  {
    "id": "9201412002",
    "district_id": "920141",
    "label": "Siwis",
    "value": "Siwis"
  },
  {
    "id": "9201412003",
    "district_id": "920141",
    "label": "Miskum",
    "value": "Miskum"
  },
  {
    "id": "9201412004",
    "district_id": "920141",
    "label": "Klamugun",
    "value": "Klamugun"
  },
  {
    "id": "9201412006",
    "district_id": "920141",
    "label": "Sbaga",
    "value": "Sbaga"
  },
  {
    "id": "9201412007",
    "district_id": "920141",
    "label": "Klalik",
    "value": "Klalik"
  },
  {
    "id": "9201412008",
    "district_id": "920141",
    "label": "Malawhili",
    "value": "Malawhili"
  },
  {
    "id": "9201412013",
    "district_id": "920141",
    "label": "Klasou",
    "value": "Klasou"
  },
  {
    "id": "9201422001",
    "district_id": "920142",
    "label": "Klafdalim",
    "value": "Klafdalim"
  },
  {
    "id": "9201422002",
    "district_id": "920142",
    "label": "Klaforo",
    "value": "Klaforo"
  },
  {
    "id": "9201422003",
    "district_id": "920142",
    "label": "Klasof",
    "value": "Klasof"
  },
  {
    "id": "9201422004",
    "district_id": "920142",
    "label": "Ninjemur",
    "value": "Ninjemur"
  },
  {
    "id": "9201422005",
    "district_id": "920142",
    "label": "Klasarin",
    "value": "Klasarin"
  },
  {
    "id": "9201422006",
    "district_id": "920142",
    "label": "Klawoton",
    "value": "Klawoton"
  },
  {
    "id": "9201422007",
    "district_id": "920142",
    "label": "Wonosobo",
    "value": "Wonosobo"
  },
  {
    "id": "9201422008",
    "district_id": "920142",
    "label": "Sakamerin",
    "value": "Sakamerin"
  },
  {
    "id": "9201422009",
    "district_id": "920142",
    "label": "Masmili",
    "value": "Masmili"
  },
  {
    "id": "9201432001",
    "district_id": "920143",
    "label": "Maibo",
    "value": "Maibo"
  },
  {
    "id": "9201432002",
    "district_id": "920143",
    "label": "Malaflon",
    "value": "Malaflon"
  },
  {
    "id": "9201432003",
    "district_id": "920143",
    "label": "Kabanmolo",
    "value": "Kabanmolo"
  },
  {
    "id": "9201432004",
    "district_id": "920143",
    "label": "Klatok",
    "value": "Klatok"
  },
  {
    "id": "9201442001",
    "district_id": "920144",
    "label": "Disfra",
    "value": "Disfra"
  },
  {
    "id": "9201442002",
    "district_id": "920144",
    "label": "Bagun",
    "value": "Bagun"
  },
  {
    "id": "9201442003",
    "district_id": "920144",
    "label": "Klawom",
    "value": "Klawom"
  },
  {
    "id": "9201442004",
    "district_id": "920144",
    "label": "Klamomis",
    "value": "Klamomis"
  },
  {
    "id": "9201442005",
    "district_id": "920144",
    "label": "Klakwonrit",
    "value": "Klakwonrit"
  },
  {
    "id": "9201442006",
    "district_id": "920144",
    "label": "Mode",
    "value": "Mode"
  },
  {
    "id": "9201442007",
    "district_id": "920144",
    "label": "Bratmawe",
    "value": "Bratmawe"
  },
  {
    "id": "9201442008",
    "district_id": "920144",
    "label": "Klawon",
    "value": "Klawon"
  },
  {
    "id": "9201442009",
    "district_id": "920144",
    "label": "Mlamli",
    "value": "Mlamli"
  },
  {
    "id": "9201452001",
    "district_id": "920145",
    "label": "Saluk",
    "value": "Saluk"
  },
  {
    "id": "9201452002",
    "district_id": "920145",
    "label": "Klawren",
    "value": "Klawren"
  },
  {
    "id": "9201452003",
    "district_id": "920145",
    "label": "Woloin",
    "value": "Woloin"
  },
  {
    "id": "9201452004",
    "district_id": "920145",
    "label": "Klalin Mos",
    "value": "Klalin Mos"
  },
  {
    "id": "9201452005",
    "district_id": "920145",
    "label": "Kwari",
    "value": "Kwari"
  },
  {
    "id": "9201452006",
    "district_id": "920145",
    "label": "Kamlin",
    "value": "Kamlin"
  },
  {
    "id": "9201462001",
    "district_id": "920146",
    "label": "Dasri",
    "value": "Dasri"
  },
  {
    "id": "9201462002",
    "district_id": "920146",
    "label": "Masos",
    "value": "Masos"
  },
  {
    "id": "9201462003",
    "district_id": "920146",
    "label": "Ketawas",
    "value": "Ketawas"
  },
  {
    "id": "9201462004",
    "district_id": "920146",
    "label": "Warbo",
    "value": "Warbo"
  },
  {
    "id": "9201462005",
    "district_id": "920146",
    "label": "Suus",
    "value": "Suus"
  },
  {
    "id": "9201462006",
    "district_id": "920146",
    "label": "Sulya",
    "value": "Sulya"
  },
  {
    "id": "9201462007",
    "district_id": "920146",
    "label": "Bolwi",
    "value": "Bolwi"
  },
  {
    "id": "9201472001",
    "district_id": "920147",
    "label": "Buk",
    "value": "Buk"
  },
  {
    "id": "9201472002",
    "district_id": "920147",
    "label": "Mlakhan",
    "value": "Mlakhan"
  },
  {
    "id": "9201472003",
    "district_id": "920147",
    "label": "Klais",
    "value": "Klais"
  },
  {
    "id": "9201472004",
    "district_id": "920147",
    "label": "Mimpe",
    "value": "Mimpe"
  },
  {
    "id": "9201472005",
    "district_id": "920147",
    "label": "Tiklen",
    "value": "Tiklen"
  },
  {
    "id": "9201472006",
    "district_id": "920147",
    "label": "Klahen",
    "value": "Klahen"
  },
  {
    "id": "9201472007",
    "district_id": "920147",
    "label": "Moos",
    "value": "Moos"
  },
  {
    "id": "9201482001",
    "district_id": "920148",
    "label": "Saengkeduk",
    "value": "Saengkeduk"
  },
  {
    "id": "9201482002",
    "district_id": "920148",
    "label": "Selekobo",
    "value": "Selekobo"
  },
  {
    "id": "9201482003",
    "district_id": "920148",
    "label": "Suye",
    "value": "Suye"
  },
  {
    "id": "9201482004",
    "district_id": "920148",
    "label": "Klamede",
    "value": "Klamede"
  },
  {
    "id": "9201482005",
    "district_id": "920148",
    "label": "Sambatie",
    "value": "Sambatie"
  },
  {
    "id": "9201482006",
    "district_id": "920148",
    "label": "Klagele",
    "value": "Klagele"
  },
  {
    "id": "9201492001",
    "district_id": "920149",
    "label": "Malasigit",
    "value": "Malasigit"
  },
  {
    "id": "9201492002",
    "district_id": "920149",
    "label": "Klagulu",
    "value": "Klagulu"
  },
  {
    "id": "9201492003",
    "district_id": "920149",
    "label": "Klamugun",
    "value": "Klamugun"
  },
  {
    "id": "9201492004",
    "district_id": "920149",
    "label": "Malajapa",
    "value": "Malajapa"
  },
  {
    "id": "9201492005",
    "district_id": "920149",
    "label": "Klasman",
    "value": "Klasman"
  },
  {
    "id": "9201492006",
    "district_id": "920149",
    "label": "Malamoja",
    "value": "Malamoja"
  },
  {
    "id": "9201492007",
    "district_id": "920149",
    "label": "Magatarum",
    "value": "Magatarum"
  },
  {
    "id": "9201492008",
    "district_id": "920149",
    "label": "Tanah Tinggi",
    "value": "Tanah Tinggi"
  },
  {
    "id": "9201492009",
    "district_id": "920149",
    "label": "Marik May",
    "value": "Marik May"
  },
  {
    "id": "9201502001",
    "district_id": "920150",
    "label": "Tarsa",
    "value": "Tarsa"
  },
  {
    "id": "9201502002",
    "district_id": "920150",
    "label": "Klamne",
    "value": "Klamne"
  },
  {
    "id": "9201502003",
    "district_id": "920150",
    "label": "Wisbiak",
    "value": "Wisbiak"
  },
  {
    "id": "9201502004",
    "district_id": "920150",
    "label": "Klafyo",
    "value": "Klafyo"
  },
  {
    "id": "9201502005",
    "district_id": "920150",
    "label": "Mlasiwor",
    "value": "Mlasiwor"
  },
  {
    "id": "9201502006",
    "district_id": "920150",
    "label": "Klafelem",
    "value": "Klafelem"
  },
  {
    "id": "9201502007",
    "district_id": "920150",
    "label": "Klaflum",
    "value": "Klaflum"
  },
  {
    "id": "9201502008",
    "district_id": "920150",
    "label": "Klarin",
    "value": "Klarin"
  },
  {
    "id": "9201502009",
    "district_id": "920150",
    "label": "Mlaron",
    "value": "Mlaron"
  },
  {
    "id": "9201512001",
    "district_id": "920151",
    "label": "Maladuk",
    "value": "Maladuk"
  },
  {
    "id": "9201512002",
    "district_id": "920151",
    "label": "Klamono Oil",
    "value": "Klamono Oil"
  },
  {
    "id": "9201512003",
    "district_id": "920151",
    "label": "Bar Ros",
    "value": "Bar Ros"
  },
  {
    "id": "9201512004",
    "district_id": "920151",
    "label": "Pusu Tiligum",
    "value": "Pusu Tiligum"
  },
  {
    "id": "9201512005",
    "district_id": "920151",
    "label": "Klawili Be",
    "value": "Klawili Be"
  },
  {
    "id": "9201522001",
    "district_id": "920152",
    "label": "Hobard",
    "value": "Hobard"
  },
  {
    "id": "9201522002",
    "district_id": "920152",
    "label": "Brianlo",
    "value": "Brianlo"
  },
  {
    "id": "9201522003",
    "district_id": "920152",
    "label": "Floyi",
    "value": "Floyi"
  },
  {
    "id": "9201522004",
    "district_id": "920152",
    "label": "Klaselt",
    "value": "Klaselt"
  },
  {
    "id": "9201522005",
    "district_id": "920152",
    "label": "Sfadon",
    "value": "Sfadon"
  },
  {
    "id": "9201522006",
    "district_id": "920152",
    "label": "Selol",
    "value": "Selol"
  },
  {
    "id": "9201522007",
    "district_id": "920152",
    "label": "Dais",
    "value": "Dais"
  },
  {
    "id": "9201532001",
    "district_id": "920153",
    "label": "Waliam",
    "value": "Waliam"
  },
  {
    "id": "9201532002",
    "district_id": "920153",
    "label": "Manfanim",
    "value": "Manfanim"
  },
  {
    "id": "9201532003",
    "district_id": "920153",
    "label": "Waibin",
    "value": "Waibin"
  },
  {
    "id": "9201532004",
    "district_id": "920153",
    "label": "Waiman",
    "value": "Waiman"
  },
  {
    "id": "9201532005",
    "district_id": "920153",
    "label": "Sakapul",
    "value": "Sakapul"
  },
  {
    "id": "9201532006",
    "district_id": "920153",
    "label": "Maralol",
    "value": "Maralol"
  },
  {
    "id": "9201532007",
    "district_id": "920153",
    "label": "Batbiro",
    "value": "Batbiro"
  },
  {
    "id": "9201532008",
    "district_id": "920153",
    "label": "Durian Kari",
    "value": "Durian Kari"
  },
  {
    "id": "9201532009",
    "district_id": "920153",
    "label": "Meyaup",
    "value": "Meyaup"
  },
  {
    "id": "9201532010",
    "district_id": "920153",
    "label": "Sailen",
    "value": "Sailen"
  },
  {
    "id": "9201542001",
    "district_id": "920154",
    "label": "Klafluk",
    "value": "Klafluk"
  },
  {
    "id": "9201542002",
    "district_id": "920154",
    "label": "Mamsit",
    "value": "Mamsit"
  },
  {
    "id": "9201542003",
    "district_id": "920154",
    "label": "Sabake",
    "value": "Sabake"
  },
  {
    "id": "9201542004",
    "district_id": "920154",
    "label": "Klayastani",
    "value": "Klayastani"
  },
  {
    "id": "9201552001",
    "district_id": "920155",
    "label": "Sailala",
    "value": "Sailala"
  },
  {
    "id": "9201552002",
    "district_id": "920155",
    "label": "Klawana",
    "value": "Klawana"
  },
  {
    "id": "9201552003",
    "district_id": "920155",
    "label": "Kladuk",
    "value": "Kladuk"
  },
  {
    "id": "9201552004",
    "district_id": "920155",
    "label": "Klawon",
    "value": "Klawon"
  },
  {
    "id": "9201552005",
    "district_id": "920155",
    "label": "Klakak",
    "value": "Klakak"
  },
  {
    "id": "9201552006",
    "district_id": "920155",
    "label": "Klalwok",
    "value": "Klalwok"
  },
  {
    "id": "9202032001",
    "district_id": "920203",
    "label": "Dindey",
    "value": "Dindey"
  },
  {
    "id": "9202032002",
    "district_id": "920203",
    "label": "Indisey",
    "value": "Indisey"
  },
  {
    "id": "9202032003",
    "district_id": "920203",
    "label": "Hingk",
    "value": "Hingk"
  },
  {
    "id": "9202032004",
    "district_id": "920203",
    "label": "Warmare",
    "value": "Warmare"
  },
  {
    "id": "9202032005",
    "district_id": "920203",
    "label": "Nimbay",
    "value": "Nimbay"
  },
  {
    "id": "9202032006",
    "district_id": "920203",
    "label": "Meny",
    "value": "Meny"
  },
  {
    "id": "9202032007",
    "district_id": "920203",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "9202032008",
    "district_id": "920203",
    "label": "Umcen",
    "value": "Umcen"
  },
  {
    "id": "9202032009",
    "district_id": "920203",
    "label": "Subsay",
    "value": "Subsay"
  },
  {
    "id": "9202032010",
    "district_id": "920203",
    "label": "Madrat",
    "value": "Madrat"
  },
  {
    "id": "9202032011",
    "district_id": "920203",
    "label": "Snaimboy",
    "value": "Snaimboy"
  },
  {
    "id": "9202032012",
    "district_id": "920203",
    "label": "Guentuy",
    "value": "Guentuy"
  },
  {
    "id": "9202032013",
    "district_id": "920203",
    "label": "Ngungguen",
    "value": "Ngungguen"
  },
  {
    "id": "9202032014",
    "district_id": "920203",
    "label": "Sotea",
    "value": "Sotea"
  },
  {
    "id": "9202032015",
    "district_id": "920203",
    "label": "Duwin",
    "value": "Duwin"
  },
  {
    "id": "9202032016",
    "district_id": "920203",
    "label": "Ibuwau",
    "value": "Ibuwau"
  },
  {
    "id": "9202032017",
    "district_id": "920203",
    "label": "Iboisrati",
    "value": "Iboisrati"
  },
  {
    "id": "9202032018",
    "district_id": "920203",
    "label": "Sraindabey",
    "value": "Sraindabey"
  },
  {
    "id": "9202032019",
    "district_id": "920203",
    "label": "Mokwam",
    "value": "Mokwam"
  },
  {
    "id": "9202032020",
    "district_id": "920203",
    "label": "Amber",
    "value": "Amber"
  },
  {
    "id": "9202032021",
    "district_id": "920203",
    "label": "Kwau",
    "value": "Kwau"
  },
  {
    "id": "9202032022",
    "district_id": "920203",
    "label": "Syou",
    "value": "Syou"
  },
  {
    "id": "9202032023",
    "district_id": "920203",
    "label": "Indonbey",
    "value": "Indonbey"
  },
  {
    "id": "9202032024",
    "district_id": "920203",
    "label": "Mingre",
    "value": "Mingre"
  },
  {
    "id": "9202032025",
    "district_id": "920203",
    "label": "Aiwow",
    "value": "Aiwow"
  },
  {
    "id": "9202032026",
    "district_id": "920203",
    "label": "Asarbey",
    "value": "Asarbey"
  },
  {
    "id": "9202032027",
    "district_id": "920203",
    "label": "Minoqbei",
    "value": "Minoqbei"
  },
  {
    "id": "9202032028",
    "district_id": "920203",
    "label": "Bahamyenti",
    "value": "Bahamyenti"
  },
  {
    "id": "9202032029",
    "district_id": "920203",
    "label": "Figoud",
    "value": "Figoud"
  },
  {
    "id": "9202032030",
    "district_id": "920203",
    "label": "Kipuwau",
    "value": "Kipuwau"
  },
  {
    "id": "9202032031",
    "district_id": "920203",
    "label": "Duweibey",
    "value": "Duweibey"
  },
  {
    "id": "9202042001",
    "district_id": "920204",
    "label": "Prafi Mulya",
    "value": "Prafi Mulya"
  },
  {
    "id": "9202042002",
    "district_id": "920204",
    "label": "Umbuy",
    "value": "Umbuy"
  },
  {
    "id": "9202042003",
    "district_id": "920204",
    "label": "Desay",
    "value": "Desay"
  },
  {
    "id": "9202042004",
    "district_id": "920204",
    "label": "Aimasi",
    "value": "Aimasi"
  },
  {
    "id": "9202042005",
    "district_id": "920204",
    "label": "Udapi Hilir",
    "value": "Udapi Hilir"
  },
  {
    "id": "9202042006",
    "district_id": "920204",
    "label": "Waseki Pop",
    "value": "Waseki Pop"
  },
  {
    "id": "9202042007",
    "district_id": "920204",
    "label": "Wasegi Indah",
    "value": "Wasegi Indah"
  },
  {
    "id": "9202042008",
    "district_id": "920204",
    "label": "Ingkwoisi",
    "value": "Ingkwoisi"
  },
  {
    "id": "9202042009",
    "district_id": "920204",
    "label": "Bogor",
    "value": "Bogor"
  },
  {
    "id": "9202042010",
    "district_id": "920204",
    "label": "Somi",
    "value": "Somi"
  },
  {
    "id": "9202042011",
    "district_id": "920204",
    "label": "Bedip Matoa",
    "value": "Bedip Matoa"
  },
  {
    "id": "9202042012",
    "district_id": "920204",
    "label": "Kali Amin",
    "value": "Kali Amin"
  },
  {
    "id": "9202042013",
    "district_id": "920204",
    "label": "Lismaungu",
    "value": "Lismaungu"
  },
  {
    "id": "9202042014",
    "district_id": "920204",
    "label": "Uhyehebrig",
    "value": "Uhyehebrig"
  },
  {
    "id": "9202042015",
    "district_id": "920204",
    "label": "Kerenu",
    "value": "Kerenu"
  },
  {
    "id": "9202042016",
    "district_id": "920204",
    "label": "Mebji",
    "value": "Mebji"
  },
  {
    "id": "9202052001",
    "district_id": "920205",
    "label": "Masni",
    "value": "Masni"
  },
  {
    "id": "9202052002",
    "district_id": "920205",
    "label": "Sumber Boga",
    "value": "Sumber Boga"
  },
  {
    "id": "9202052003",
    "district_id": "920205",
    "label": "Prafi Barat",
    "value": "Prafi Barat"
  },
  {
    "id": "9202052004",
    "district_id": "920205",
    "label": "Muara Prafi",
    "value": "Muara Prafi"
  },
  {
    "id": "9202052006",
    "district_id": "920205",
    "label": "Macuan",
    "value": "Macuan"
  },
  {
    "id": "9202052008",
    "district_id": "920205",
    "label": "Wariori",
    "value": "Wariori"
  },
  {
    "id": "9202052009",
    "district_id": "920205",
    "label": "Bowi Subur",
    "value": "Bowi Subur"
  },
  {
    "id": "9202052010",
    "district_id": "920205",
    "label": "Makwan",
    "value": "Makwan"
  },
  {
    "id": "9202052011",
    "district_id": "920205",
    "label": "Kali Merah",
    "value": "Kali Merah"
  },
  {
    "id": "9202052012",
    "district_id": "920205",
    "label": "Meiforga",
    "value": "Meiforga"
  },
  {
    "id": "9202052013",
    "district_id": "920205",
    "label": "Sibuni",
    "value": "Sibuni"
  },
  {
    "id": "9202052014",
    "district_id": "920205",
    "label": "Yensum",
    "value": "Yensum"
  },
  {
    "id": "9202052015",
    "district_id": "920205",
    "label": "Mansaburi",
    "value": "Mansaburi"
  },
  {
    "id": "9202052016",
    "district_id": "920205",
    "label": "Igor",
    "value": "Igor"
  },
  {
    "id": "9202052017",
    "district_id": "920205",
    "label": "Merejemeg",
    "value": "Merejemeg"
  },
  {
    "id": "9202052018",
    "district_id": "920205",
    "label": "Aurmios",
    "value": "Aurmios"
  },
  {
    "id": "9202052020",
    "district_id": "920205",
    "label": "Sembab",
    "value": "Sembab"
  },
  {
    "id": "9202052024",
    "district_id": "920205",
    "label": "Membowi",
    "value": "Membowi"
  },
  {
    "id": "9202052027",
    "district_id": "920205",
    "label": "Yonsoribo",
    "value": "Yonsoribo"
  },
  {
    "id": "9202052028",
    "district_id": "920205",
    "label": "Meyof II",
    "value": "Meyof II"
  },
  {
    "id": "9202052030",
    "district_id": "920205",
    "label": "Moubja",
    "value": "Moubja"
  },
  {
    "id": "9202052031",
    "district_id": "920205",
    "label": "Ririnfos",
    "value": "Ririnfos"
  },
  {
    "id": "9202052032",
    "district_id": "920205",
    "label": "Muara Wariori",
    "value": "Muara Wariori"
  },
  {
    "id": "9202052034",
    "district_id": "920205",
    "label": "Koyani",
    "value": "Koyani"
  },
  {
    "id": "9202052035",
    "district_id": "920205",
    "label": "Meyeruk",
    "value": "Meyeruk"
  },
  {
    "id": "9202052036",
    "district_id": "920205",
    "label": "Inyei",
    "value": "Inyei"
  },
  {
    "id": "9202052037",
    "district_id": "920205",
    "label": "Undi",
    "value": "Undi"
  },
  {
    "id": "9202052038",
    "district_id": "920205",
    "label": "Jowen",
    "value": "Jowen"
  },
  {
    "id": "9202052039",
    "district_id": "920205",
    "label": "Aska",
    "value": "Aska"
  },
  {
    "id": "9202052040",
    "district_id": "920205",
    "label": "Mantedi",
    "value": "Mantedi"
  },
  {
    "id": "9202052042",
    "district_id": "920205",
    "label": "Wariori Indah",
    "value": "Wariori Indah"
  },
  {
    "id": "9202052045",
    "district_id": "920205",
    "label": "Urey",
    "value": "Urey"
  },
  {
    "id": "9202052046",
    "district_id": "920205",
    "label": "Wamfoura",
    "value": "Wamfoura"
  },
  {
    "id": "9202121001",
    "district_id": "920212",
    "label": "Manokwari Barat",
    "value": "Manokwari Barat"
  },
  {
    "id": "9202121002",
    "district_id": "920212",
    "label": "Sanggeng",
    "value": "Sanggeng"
  },
  {
    "id": "9202121003",
    "district_id": "920212",
    "label": "Wosi",
    "value": "Wosi"
  },
  {
    "id": "9202121004",
    "district_id": "920212",
    "label": "Amban",
    "value": "Amban"
  },
  {
    "id": "9202121005",
    "district_id": "920212",
    "label": "Padarni",
    "value": "Padarni"
  },
  {
    "id": "9202121006",
    "district_id": "920212",
    "label": "Manokwari Timur",
    "value": "Manokwari Timur"
  },
  {
    "id": "9202122007",
    "district_id": "920212",
    "label": "Udopi",
    "value": "Udopi"
  },
  {
    "id": "9202122008",
    "district_id": "920212",
    "label": "Ingramui",
    "value": "Ingramui"
  },
  {
    "id": "9202122009",
    "district_id": "920212",
    "label": "Soribo",
    "value": "Soribo"
  },
  {
    "id": "9202122010",
    "district_id": "920212",
    "label": "Tanah Merah Indah",
    "value": "Tanah Merah Indah"
  },
  {
    "id": "9202131001",
    "district_id": "920213",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9202132002",
    "district_id": "920213",
    "label": "Mansinam",
    "value": "Mansinam"
  },
  {
    "id": "9202132003",
    "district_id": "920213",
    "label": "Ayambori",
    "value": "Ayambori"
  },
  {
    "id": "9202132004",
    "district_id": "920213",
    "label": "Bakaro",
    "value": "Bakaro"
  },
  {
    "id": "9202132005",
    "district_id": "920213",
    "label": "Arowi",
    "value": "Arowi"
  },
  {
    "id": "9202132006",
    "district_id": "920213",
    "label": "Aipiri",
    "value": "Aipiri"
  },
  {
    "id": "9202132007",
    "district_id": "920213",
    "label": "Susweni",
    "value": "Susweni"
  },
  {
    "id": "9202142001",
    "district_id": "920214",
    "label": "Yoom I",
    "value": "Yoom I"
  },
  {
    "id": "9202142002",
    "district_id": "920214",
    "label": "Mandopi",
    "value": "Mandopi"
  },
  {
    "id": "9202142003",
    "district_id": "920214",
    "label": "Mubri",
    "value": "Mubri"
  },
  {
    "id": "9202142004",
    "district_id": "920214",
    "label": "Yoom II",
    "value": "Yoom II"
  },
  {
    "id": "9202142005",
    "district_id": "920214",
    "label": "Teluk Mubri",
    "value": "Teluk Mubri"
  },
  {
    "id": "9202142006",
    "district_id": "920214",
    "label": "Meyes",
    "value": "Meyes"
  },
  {
    "id": "9202142007",
    "district_id": "920214",
    "label": "Bremi",
    "value": "Bremi"
  },
  {
    "id": "9202142008",
    "district_id": "920214",
    "label": "Saubeba",
    "value": "Saubeba"
  },
  {
    "id": "9202142009",
    "district_id": "920214",
    "label": "Pami",
    "value": "Pami"
  },
  {
    "id": "9202142010",
    "district_id": "920214",
    "label": "Warbefor",
    "value": "Warbefor"
  },
  {
    "id": "9202142011",
    "district_id": "920214",
    "label": "Asai",
    "value": "Asai"
  },
  {
    "id": "9202142012",
    "district_id": "920214",
    "label": "Inya",
    "value": "Inya"
  },
  {
    "id": "9202142013",
    "district_id": "920214",
    "label": "Meyunfoka",
    "value": "Meyunfoka"
  },
  {
    "id": "9202142014",
    "district_id": "920214",
    "label": "Tanah Rubuh",
    "value": "Tanah Rubuh"
  },
  {
    "id": "9202142015",
    "district_id": "920214",
    "label": "Sairo",
    "value": "Sairo"
  },
  {
    "id": "9202142016",
    "district_id": "920214",
    "label": "Singgimeba",
    "value": "Singgimeba"
  },
  {
    "id": "9202142017",
    "district_id": "920214",
    "label": "Inoduas",
    "value": "Inoduas"
  },
  {
    "id": "9202142018",
    "district_id": "920214",
    "label": "Lebau",
    "value": "Lebau"
  },
  {
    "id": "9202142020",
    "district_id": "920214",
    "label": "Cabang Dua",
    "value": "Cabang Dua"
  },
  {
    "id": "9202142021",
    "district_id": "920214",
    "label": "Yonggam",
    "value": "Yonggam"
  },
  {
    "id": "9202142022",
    "district_id": "920214",
    "label": "Indou Oufa",
    "value": "Indou Oufa"
  },
  {
    "id": "9202142023",
    "district_id": "920214",
    "label": "Mubraidiba",
    "value": "Mubraidiba"
  },
  {
    "id": "9202151001",
    "district_id": "920215",
    "label": "Sowi",
    "value": "Sowi"
  },
  {
    "id": "9202151002",
    "district_id": "920215",
    "label": "Anday",
    "value": "Anday"
  },
  {
    "id": "9202152003",
    "district_id": "920215",
    "label": "Katebu",
    "value": "Katebu"
  },
  {
    "id": "9202152004",
    "district_id": "920215",
    "label": "Masyepi",
    "value": "Masyepi"
  },
  {
    "id": "9202152005",
    "district_id": "920215",
    "label": "Warmomi",
    "value": "Warmomi"
  },
  {
    "id": "9202152006",
    "district_id": "920215",
    "label": "Dihara",
    "value": "Dihara"
  },
  {
    "id": "9202152007",
    "district_id": "920215",
    "label": "Waluri",
    "value": "Waluri"
  },
  {
    "id": "9202152008",
    "district_id": "920215",
    "label": "Maruni",
    "value": "Maruni"
  },
  {
    "id": "9202152009",
    "district_id": "920215",
    "label": "Misapmeysi",
    "value": "Misapmeysi"
  },
  {
    "id": "9202152010",
    "district_id": "920215",
    "label": "Wasai",
    "value": "Wasai"
  },
  {
    "id": "9202152011",
    "district_id": "920215",
    "label": "Anggresi",
    "value": "Anggresi"
  },
  {
    "id": "9202152012",
    "district_id": "920215",
    "label": "Mupi",
    "value": "Mupi"
  },
  {
    "id": "9202152013",
    "district_id": "920215",
    "label": "Warswami",
    "value": "Warswami"
  },
  {
    "id": "9202152014",
    "district_id": "920215",
    "label": "Acemo",
    "value": "Acemo"
  },
  {
    "id": "9202152015",
    "district_id": "920215",
    "label": "Wamesa",
    "value": "Wamesa"
  },
  {
    "id": "9202152016",
    "district_id": "920215",
    "label": "Dobut",
    "value": "Dobut"
  },
  {
    "id": "9202152017",
    "district_id": "920215",
    "label": "Warkomi",
    "value": "Warkomi"
  },
  {
    "id": "9202152018",
    "district_id": "920215",
    "label": "Ngunimbouw",
    "value": "Ngunimbouw"
  },
  {
    "id": "9202172001",
    "district_id": "920217",
    "label": "Warkapi",
    "value": "Warkapi"
  },
  {
    "id": "9202172002",
    "district_id": "920217",
    "label": "Ayawi",
    "value": "Ayawi"
  },
  {
    "id": "9202172003",
    "district_id": "920217",
    "label": "Ukopti",
    "value": "Ukopti"
  },
  {
    "id": "9202172004",
    "district_id": "920217",
    "label": "Misabugoid",
    "value": "Misabugoid"
  },
  {
    "id": "9202172005",
    "district_id": "920217",
    "label": "Mbatma",
    "value": "Mbatma"
  },
  {
    "id": "9202172006",
    "district_id": "920217",
    "label": "Urwambei",
    "value": "Urwambei"
  },
  {
    "id": "9202172007",
    "district_id": "920217",
    "label": "Imbeisika I",
    "value": "Imbeisika I"
  },
  {
    "id": "9202172008",
    "district_id": "920217",
    "label": "Imbeisika II",
    "value": "Imbeisika II"
  },
  {
    "id": "9202172009",
    "district_id": "920217",
    "label": "Warmawai",
    "value": "Warmawai"
  },
  {
    "id": "9202172010",
    "district_id": "920217",
    "label": "Rembuy",
    "value": "Rembuy"
  },
  {
    "id": "9202172011",
    "district_id": "920217",
    "label": "Imhasuma",
    "value": "Imhasuma"
  },
  {
    "id": "9202172012",
    "district_id": "920217",
    "label": "Cuyehep",
    "value": "Cuyehep"
  },
  {
    "id": "9202172013",
    "district_id": "920217",
    "label": "Hanghouw",
    "value": "Hanghouw"
  },
  {
    "id": "9202172014",
    "district_id": "920217",
    "label": "Ningdip",
    "value": "Ningdip"
  },
  {
    "id": "9202172015",
    "district_id": "920217",
    "label": "Warami",
    "value": "Warami"
  },
  {
    "id": "9202172016",
    "district_id": "920217",
    "label": "Imboiti",
    "value": "Imboiti"
  },
  {
    "id": "9202172017",
    "district_id": "920217",
    "label": "Wariari",
    "value": "Wariari"
  },
  {
    "id": "9202172018",
    "district_id": "920217",
    "label": "Indibo",
    "value": "Indibo"
  },
  {
    "id": "9202172019",
    "district_id": "920217",
    "label": "Umnum",
    "value": "Umnum"
  },
  {
    "id": "9202172020",
    "district_id": "920217",
    "label": "Ukemboisi",
    "value": "Ukemboisi"
  },
  {
    "id": "9202172021",
    "district_id": "920217",
    "label": "Menyumfoku",
    "value": "Menyumfoku"
  },
  {
    "id": "9202172022",
    "district_id": "920217",
    "label": "Wedoni",
    "value": "Wedoni"
  },
  {
    "id": "9202172023",
    "district_id": "920217",
    "label": "Mirowi",
    "value": "Mirowi"
  },
  {
    "id": "9202172024",
    "district_id": "920217",
    "label": "Warnyeti",
    "value": "Warnyeti"
  },
  {
    "id": "9202212001",
    "district_id": "920221",
    "label": "Kaironi",
    "value": "Kaironi"
  },
  {
    "id": "9202212002",
    "district_id": "920221",
    "label": "Sidey",
    "value": "Sidey"
  },
  {
    "id": "9202212003",
    "district_id": "920221",
    "label": "Waramui",
    "value": "Waramui"
  },
  {
    "id": "9202212004",
    "district_id": "920221",
    "label": "Kassi",
    "value": "Kassi"
  },
  {
    "id": "9202212005",
    "district_id": "920221",
    "label": "Saray",
    "value": "Saray"
  },
  {
    "id": "9202212006",
    "district_id": "920221",
    "label": "Sidey Baru",
    "value": "Sidey Baru"
  },
  {
    "id": "9202212007",
    "district_id": "920221",
    "label": "Sidey Makmur",
    "value": "Sidey Makmur"
  },
  {
    "id": "9202212008",
    "district_id": "920221",
    "label": "Meyof I",
    "value": "Meyof I"
  },
  {
    "id": "9202212009",
    "district_id": "920221",
    "label": "Wariki",
    "value": "Wariki"
  },
  {
    "id": "9202212010",
    "district_id": "920221",
    "label": "Womnowi",
    "value": "Womnowi"
  },
  {
    "id": "9202212011",
    "district_id": "920221",
    "label": "Manggupi",
    "value": "Manggupi"
  },
  {
    "id": "9202212012",
    "district_id": "920221",
    "label": "Sidey Jaya",
    "value": "Sidey Jaya"
  },
  {
    "id": "9203011001",
    "district_id": "920301",
    "label": "Fak Fak Selatan",
    "value": "Fak Fak Selatan"
  },
  {
    "id": "9203011002",
    "district_id": "920301",
    "label": "Fak Fak Utara",
    "value": "Fak Fak Utara"
  },
  {
    "id": "9203012007",
    "district_id": "920301",
    "label": "Gewerpe",
    "value": "Gewerpe"
  },
  {
    "id": "9203012011",
    "district_id": "920301",
    "label": "Lusi Peri",
    "value": "Lusi Peri"
  },
  {
    "id": "9203012012",
    "district_id": "920301",
    "label": "Wrikapal",
    "value": "Wrikapal"
  },
  {
    "id": "9203022001",
    "district_id": "920302",
    "label": "Werba",
    "value": "Werba"
  },
  {
    "id": "9203022002",
    "district_id": "920302",
    "label": "Kiat",
    "value": "Kiat"
  },
  {
    "id": "9203022003",
    "district_id": "920302",
    "label": "Purwasak",
    "value": "Purwasak"
  },
  {
    "id": "9203022008",
    "district_id": "920302",
    "label": "Wurkendik",
    "value": "Wurkendik"
  },
  {
    "id": "9203022010",
    "district_id": "920302",
    "label": "Kwuhkendak",
    "value": "Kwuhkendak"
  },
  {
    "id": "9203022011",
    "district_id": "920302",
    "label": "Purwahab Tonggo",
    "value": "Purwahab Tonggo"
  },
  {
    "id": "9203022012",
    "district_id": "920302",
    "label": "Pahger Nkindik",
    "value": "Pahger Nkindik"
  },
  {
    "id": "9203022013",
    "district_id": "920302",
    "label": "Werba Utara",
    "value": "Werba Utara"
  },
  {
    "id": "9203022014",
    "district_id": "920302",
    "label": "Porum",
    "value": "Porum"
  },
  {
    "id": "9203032001",
    "district_id": "920303",
    "label": "Tunas Gain",
    "value": "Tunas Gain"
  },
  {
    "id": "9203032003",
    "district_id": "920303",
    "label": "Urat",
    "value": "Urat"
  },
  {
    "id": "9203032004",
    "district_id": "920303",
    "label": "Sanggram",
    "value": "Sanggram"
  },
  {
    "id": "9203032010",
    "district_id": "920303",
    "label": "Weri",
    "value": "Weri"
  },
  {
    "id": "9203032012",
    "district_id": "920303",
    "label": "Kiria Bisa",
    "value": "Kiria Bisa"
  },
  {
    "id": "9203032013",
    "district_id": "920303",
    "label": "Saharei",
    "value": "Saharei"
  },
  {
    "id": "9203041001",
    "district_id": "920304",
    "label": "Kokas Kota",
    "value": "Kokas Kota"
  },
  {
    "id": "9203042003",
    "district_id": "920304",
    "label": "Mandoni",
    "value": "Mandoni"
  },
  {
    "id": "9203042008",
    "district_id": "920304",
    "label": "Sekar",
    "value": "Sekar"
  },
  {
    "id": "9203042009",
    "district_id": "920304",
    "label": "Ugar",
    "value": "Ugar"
  },
  {
    "id": "9203042010",
    "district_id": "920304",
    "label": "Sisir",
    "value": "Sisir"
  },
  {
    "id": "9203042011",
    "district_id": "920304",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "9203042012",
    "district_id": "920304",
    "label": "Patimburak",
    "value": "Patimburak"
  },
  {
    "id": "9203042013",
    "district_id": "920304",
    "label": "Batufiafas",
    "value": "Batufiafas"
  },
  {
    "id": "9203042014",
    "district_id": "920304",
    "label": "Kinam",
    "value": "Kinam"
  },
  {
    "id": "9203042021",
    "district_id": "920304",
    "label": "Mambunibuni",
    "value": "Mambunibuni"
  },
  {
    "id": "9203042024",
    "district_id": "920304",
    "label": "Kriawaswas",
    "value": "Kriawaswas"
  },
  {
    "id": "9203042028",
    "district_id": "920304",
    "label": "Sosar",
    "value": "Sosar"
  },
  {
    "id": "9203042029",
    "district_id": "920304",
    "label": "Pang Wadar",
    "value": "Pang Wadar"
  },
  {
    "id": "9203042030",
    "district_id": "920304",
    "label": "Masina",
    "value": "Masina"
  },
  {
    "id": "9203042031",
    "district_id": "920304",
    "label": "Kimina Kra",
    "value": "Kimina Kra"
  },
  {
    "id": "9203051001",
    "district_id": "920305",
    "label": "Danaweria",
    "value": "Danaweria"
  },
  {
    "id": "9203052002",
    "district_id": "920305",
    "label": "Kayu Merah",
    "value": "Kayu Merah"
  },
  {
    "id": "9203052003",
    "district_id": "920305",
    "label": "Katemba",
    "value": "Katemba"
  },
  {
    "id": "9203052004",
    "district_id": "920305",
    "label": "Nemewikarya",
    "value": "Nemewikarya"
  },
  {
    "id": "9203052005",
    "district_id": "920305",
    "label": "Raduria",
    "value": "Raduria"
  },
  {
    "id": "9203052006",
    "district_id": "920305",
    "label": "Brongkendik",
    "value": "Brongkendik"
  },
  {
    "id": "9203052007",
    "district_id": "920305",
    "label": "Air Besar",
    "value": "Air Besar"
  },
  {
    "id": "9203052008",
    "district_id": "920305",
    "label": "Mandopma",
    "value": "Mandopma"
  },
  {
    "id": "9203052009",
    "district_id": "920305",
    "label": "Kanantare",
    "value": "Kanantare"
  },
  {
    "id": "9203052010",
    "district_id": "920305",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9203052011",
    "district_id": "920305",
    "label": "Pirma",
    "value": "Pirma"
  },
  {
    "id": "9203052012",
    "district_id": "920305",
    "label": "Sakartemin",
    "value": "Sakartemin"
  },
  {
    "id": "9203052013",
    "district_id": "920305",
    "label": "Hambrangkendik",
    "value": "Hambrangkendik"
  },
  {
    "id": "9203052014",
    "district_id": "920305",
    "label": "Unipokpok",
    "value": "Unipokpok"
  },
  {
    "id": "9203062001",
    "district_id": "920306",
    "label": "Malakuli",
    "value": "Malakuli"
  },
  {
    "id": "9203062002",
    "district_id": "920306",
    "label": "Faur",
    "value": "Faur"
  },
  {
    "id": "9203062003",
    "district_id": "920306",
    "label": "Kiaba",
    "value": "Kiaba"
  },
  {
    "id": "9203062004",
    "district_id": "920306",
    "label": "Antalisa",
    "value": "Antalisa"
  },
  {
    "id": "9203062005",
    "district_id": "920306",
    "label": "Tarak",
    "value": "Tarak"
  },
  {
    "id": "9203062006",
    "district_id": "920306",
    "label": "Tuberwasak",
    "value": "Tuberwasak"
  },
  {
    "id": "9203062007",
    "district_id": "920306",
    "label": "Mas",
    "value": "Mas"
  },
  {
    "id": "9203072007",
    "district_id": "920307",
    "label": "Onimsari",
    "value": "Onimsari"
  },
  {
    "id": "9203072008",
    "district_id": "920307",
    "label": "Mekarsari",
    "value": "Mekarsari"
  },
  {
    "id": "9203072009",
    "district_id": "920307",
    "label": "Pinang Agung",
    "value": "Pinang Agung"
  },
  {
    "id": "9203072010",
    "district_id": "920307",
    "label": "Bumi Moroh Indah",
    "value": "Bumi Moroh Indah"
  },
  {
    "id": "9203072012",
    "district_id": "920307",
    "label": "Tesha",
    "value": "Tesha"
  },
  {
    "id": "9203072014",
    "district_id": "920307",
    "label": "Onim Jaya",
    "value": "Onim Jaya"
  },
  {
    "id": "9203072015",
    "district_id": "920307",
    "label": "Wono Rejo",
    "value": "Wono Rejo"
  },
  {
    "id": "9203082001",
    "district_id": "920308",
    "label": "Kramongmongga",
    "value": "Kramongmongga"
  },
  {
    "id": "9203082007",
    "district_id": "920308",
    "label": "Wargep",
    "value": "Wargep"
  },
  {
    "id": "9203082008",
    "district_id": "920308",
    "label": "Mamur",
    "value": "Mamur"
  },
  {
    "id": "9203082009",
    "district_id": "920308",
    "label": "Nembukteb",
    "value": "Nembukteb"
  },
  {
    "id": "9203082010",
    "district_id": "920308",
    "label": "Pikpik",
    "value": "Pikpik"
  },
  {
    "id": "9203082011",
    "district_id": "920308",
    "label": "Kwamkwamur",
    "value": "Kwamkwamur"
  },
  {
    "id": "9203082012",
    "district_id": "920308",
    "label": "Bahbadan",
    "value": "Bahbadan"
  },
  {
    "id": "9203082016",
    "district_id": "920308",
    "label": "Gewab",
    "value": "Gewab"
  },
  {
    "id": "9203082017",
    "district_id": "920308",
    "label": "Tentreda",
    "value": "Tentreda"
  },
  {
    "id": "9203082018",
    "district_id": "920308",
    "label": "Mbaham Ma Youn",
    "value": "Mbaham Ma Youn"
  },
  {
    "id": "9203092001",
    "district_id": "920309",
    "label": "Puar",
    "value": "Puar"
  },
  {
    "id": "9203092002",
    "district_id": "920309",
    "label": "Degen",
    "value": "Degen"
  },
  {
    "id": "9203092003",
    "district_id": "920309",
    "label": "Tetar",
    "value": "Tetar"
  },
  {
    "id": "9203092005",
    "district_id": "920309",
    "label": "Sum",
    "value": "Sum"
  },
  {
    "id": "9203092011",
    "district_id": "920309",
    "label": "Adora",
    "value": "Adora"
  },
  { "id": "9203092012", "district_id": "920309", "label": "Us", "value": "Us" },
  {
    "id": "9203092014",
    "district_id": "920309",
    "label": "Patipi Pasir",
    "value": "Patipi Pasir"
  },
  {
    "id": "9203092015",
    "district_id": "920309",
    "label": "Patipi Pulau",
    "value": "Patipi Pulau"
  },
  {
    "id": "9203092017",
    "district_id": "920309",
    "label": "Mawar",
    "value": "Mawar"
  },
  {
    "id": "9203092018",
    "district_id": "920309",
    "label": "Tibatibananam",
    "value": "Tibatibananam"
  },
  {
    "id": "9203092020",
    "district_id": "920309",
    "label": "Offie",
    "value": "Offie"
  },
  {
    "id": "9203092021",
    "district_id": "920309",
    "label": "Muhri",
    "value": "Muhri"
  },
  {
    "id": "9203092022",
    "district_id": "920309",
    "label": "Bisa",
    "value": "Bisa"
  },
  {
    "id": "9203101001",
    "district_id": "920310",
    "label": "Wagom",
    "value": "Wagom"
  },
  {
    "id": "9203101002",
    "district_id": "920310",
    "label": "Wagom Utara",
    "value": "Wagom Utara"
  },
  {
    "id": "9203101003",
    "district_id": "920310",
    "label": "Dulanpokpok",
    "value": "Dulanpokpok"
  },
  {
    "id": "9203102004",
    "district_id": "920310",
    "label": "Tanama",
    "value": "Tanama"
  },
  {
    "id": "9203102005",
    "district_id": "920310",
    "label": "Kapaurtutin",
    "value": "Kapaurtutin"
  },
  {
    "id": "9203102006",
    "district_id": "920310",
    "label": "Sekban",
    "value": "Sekban"
  },
  {
    "id": "9203102007",
    "district_id": "920310",
    "label": "Torea",
    "value": "Torea"
  },
  {
    "id": "9203102008",
    "district_id": "920310",
    "label": "Sekru",
    "value": "Sekru"
  },
  {
    "id": "9203102009",
    "district_id": "920310",
    "label": "Sukuru Tuare",
    "value": "Sukuru Tuare"
  },
  {
    "id": "9203112001",
    "district_id": "920311",
    "label": "Werpigan",
    "value": "Werpigan"
  },
  {
    "id": "9203112002",
    "district_id": "920311",
    "label": "Werabuan",
    "value": "Werabuan"
  },
  {
    "id": "9203112003",
    "district_id": "920311",
    "label": "Wartutin",
    "value": "Wartutin"
  },
  {
    "id": "9203112004",
    "district_id": "920311",
    "label": "Siboru",
    "value": "Siboru"
  },
  {
    "id": "9203112005",
    "district_id": "920311",
    "label": "Sipatnanam",
    "value": "Sipatnanam"
  },
  {
    "id": "9203112006",
    "district_id": "920311",
    "label": "Kamandur Tetar",
    "value": "Kamandur Tetar"
  },
  {
    "id": "9203122001",
    "district_id": "920312",
    "label": "Waserat",
    "value": "Waserat"
  },
  {
    "id": "9203122002",
    "district_id": "920312",
    "label": "Wambar",
    "value": "Wambar"
  },
  {
    "id": "9203122003",
    "district_id": "920312",
    "label": "Kotam",
    "value": "Kotam"
  },
  {
    "id": "9203122004",
    "district_id": "920312",
    "label": "Kwama",
    "value": "Kwama"
  },
  {
    "id": "9203122005",
    "district_id": "920312",
    "label": "Kalamanuk",
    "value": "Kalamanuk"
  },
  {
    "id": "9203122006",
    "district_id": "920312",
    "label": "Wayati Timur",
    "value": "Wayati Timur"
  },
  {
    "id": "9203122007",
    "district_id": "920312",
    "label": "Wayati",
    "value": "Wayati"
  },
  {
    "id": "9203122008",
    "district_id": "920312",
    "label": "Wayati Barat",
    "value": "Wayati Barat"
  },
  {
    "id": "9203122009",
    "district_id": "920312",
    "label": "Krabelang",
    "value": "Krabelang"
  },
  {
    "id": "9203122010",
    "district_id": "920312",
    "label": "Wambar Timur",
    "value": "Wambar Timur"
  },
  {
    "id": "9203132001",
    "district_id": "920313",
    "label": "Andamata",
    "value": "Andamata"
  },
  {
    "id": "9203132002",
    "district_id": "920313",
    "label": "Taver",
    "value": "Taver"
  },
  {
    "id": "9203132003",
    "district_id": "920313",
    "label": "Arguni",
    "value": "Arguni"
  },
  {
    "id": "9203132004",
    "district_id": "920313",
    "label": "Fior",
    "value": "Fior"
  },
  {
    "id": "9203132005",
    "district_id": "920313",
    "label": "Furir",
    "value": "Furir"
  },
  {
    "id": "9203142001",
    "district_id": "920314",
    "label": "Darembang",
    "value": "Darembang"
  },
  {
    "id": "9203142002",
    "district_id": "920314",
    "label": "Goras",
    "value": "Goras"
  },
  {
    "id": "9203142003",
    "district_id": "920314",
    "label": "Mitimber",
    "value": "Mitimber"
  },
  {
    "id": "9203142004",
    "district_id": "920314",
    "label": "Waremu",
    "value": "Waremu"
  },
  {
    "id": "9203142005",
    "district_id": "920314",
    "label": "Wos",
    "value": "Wos"
  },
  {
    "id": "9203142006",
    "district_id": "920314",
    "label": "Mbahamdandra",
    "value": "Mbahamdandra"
  },
  {
    "id": "9203142007",
    "district_id": "920314",
    "label": "Goras Selatan",
    "value": "Goras Selatan"
  },
  {
    "id": "9203152001",
    "district_id": "920315",
    "label": "Kaburbur",
    "value": "Kaburbur"
  },
  {
    "id": "9203152002",
    "district_id": "920315",
    "label": "Ubadari",
    "value": "Ubadari"
  },
  {
    "id": "9203152004",
    "district_id": "920315",
    "label": "Homorkokma",
    "value": "Homorkokma"
  },
  {
    "id": "9203152005",
    "district_id": "920315",
    "label": "Pattukar",
    "value": "Pattukar"
  },
  {
    "id": "9203152006",
    "district_id": "920315",
    "label": "Kayuni",
    "value": "Kayuni"
  },
  {
    "id": "9203152007",
    "district_id": "920315",
    "label": "Kuagas",
    "value": "Kuagas"
  },
  {
    "id": "9203152008",
    "district_id": "920315",
    "label": "Rangkendak",
    "value": "Rangkendak"
  },
  {
    "id": "9203152009",
    "district_id": "920315",
    "label": "Mananmur",
    "value": "Mananmur"
  },
  {
    "id": "9203152010",
    "district_id": "920315",
    "label": "Warpa",
    "value": "Warpa"
  },
  {
    "id": "9203162001",
    "district_id": "920316",
    "label": "Salakiti",
    "value": "Salakiti"
  },
  {
    "id": "9203162002",
    "district_id": "920316",
    "label": "Tawar",
    "value": "Tawar"
  },
  {
    "id": "9203162003",
    "district_id": "920316",
    "label": "Gar",
    "value": "Gar"
  },
  {
    "id": "9203162004",
    "district_id": "920316",
    "label": "Rumbati",
    "value": "Rumbati"
  },
  {
    "id": "9203162005",
    "district_id": "920316",
    "label": "Werfra",
    "value": "Werfra"
  },
  {
    "id": "9203162006",
    "district_id": "920316",
    "label": "Tanehamur",
    "value": "Tanehamur"
  },
  {
    "id": "9203162007",
    "district_id": "920316",
    "label": "Sengkiti",
    "value": "Sengkiti"
  },
  {
    "id": "9203162008",
    "district_id": "920316",
    "label": "Twootkindik",
    "value": "Twootkindik"
  },
  {
    "id": "9203172001",
    "district_id": "920317",
    "label": "Mbima Jaya",
    "value": "Mbima Jaya"
  },
  {
    "id": "9203172002",
    "district_id": "920317",
    "label": "Warisa Mulya",
    "value": "Warisa Mulya"
  },
  {
    "id": "9203172003",
    "district_id": "920317",
    "label": "Wonodadi Mulya",
    "value": "Wonodadi Mulya"
  },
  {
    "id": "9203172004",
    "district_id": "920317",
    "label": "Tomage",
    "value": "Tomage"
  },
  {
    "id": "9203172005",
    "district_id": "920317",
    "label": "Otoweri",
    "value": "Otoweri"
  },
  {
    "id": "9203172006",
    "district_id": "920317",
    "label": "Wamosan",
    "value": "Wamosan"
  },
  {
    "id": "9203172007",
    "district_id": "920317",
    "label": "Salawier",
    "value": "Salawier"
  },
  {
    "id": "9203172008",
    "district_id": "920317",
    "label": "Wasa Mulya",
    "value": "Wasa Mulya"
  },
  {
    "id": "9203172009",
    "district_id": "920317",
    "label": "Wammar",
    "value": "Wammar"
  },
  {
    "id": "9204011005",
    "district_id": "920401",
    "label": "Kaibus",
    "value": "Kaibus"
  },
  {
    "id": "9204011006",
    "district_id": "920401",
    "label": "Kohoin",
    "value": "Kohoin"
  },
  {
    "id": "9204012004",
    "district_id": "920401",
    "label": "Wersar",
    "value": "Wersar"
  },
  {
    "id": "9204012009",
    "district_id": "920401",
    "label": "Wehali",
    "value": "Wehali"
  },
  {
    "id": "9204012010",
    "district_id": "920401",
    "label": "Aibobor",
    "value": "Aibobor"
  },
  {
    "id": "9204012017",
    "district_id": "920401",
    "label": "Wermit",
    "value": "Wermit"
  },
  {
    "id": "9204012018",
    "district_id": "920401",
    "label": "Keyen",
    "value": "Keyen"
  },
  {
    "id": "9204012021",
    "district_id": "920401",
    "label": "Siribau",
    "value": "Siribau"
  },
  {
    "id": "9204012025",
    "district_id": "920401",
    "label": "Magis",
    "value": "Magis"
  },
  {
    "id": "9204012026",
    "district_id": "920401",
    "label": "Tegirolo",
    "value": "Tegirolo"
  },
  {
    "id": "9204012034",
    "district_id": "920401",
    "label": "Wernas",
    "value": "Wernas"
  },
  {
    "id": "9204012035",
    "district_id": "920401",
    "label": "Tapiri",
    "value": "Tapiri"
  },
  {
    "id": "9204012040",
    "district_id": "920401",
    "label": "Gorolo",
    "value": "Gorolo"
  },
  {
    "id": "9204012041",
    "district_id": "920401",
    "label": "Nambro",
    "value": "Nambro"
  },
  {
    "id": "9204012044",
    "district_id": "920401",
    "label": "Seyolo",
    "value": "Seyolo"
  },
  {
    "id": "9204012045",
    "district_id": "920401",
    "label": "Ani Sesna",
    "value": "Ani Sesna"
  },
  {
    "id": "9204042001",
    "district_id": "920404",
    "label": "Mate",
    "value": "Mate"
  },
  {
    "id": "9204042004",
    "district_id": "920404",
    "label": "Serkos",
    "value": "Serkos"
  },
  {
    "id": "9204042006",
    "district_id": "920404",
    "label": "Solta Baru",
    "value": "Solta Baru"
  },
  {
    "id": "9204042008",
    "district_id": "920404",
    "label": "Wadoi",
    "value": "Wadoi"
  },
  {
    "id": "9204042009",
    "district_id": "920404",
    "label": "Isogo",
    "value": "Isogo"
  },
  {
    "id": "9204042013",
    "district_id": "920404",
    "label": "Sibae",
    "value": "Sibae"
  },
  {
    "id": "9204042020",
    "district_id": "920404",
    "label": "Mogibi",
    "value": "Mogibi"
  },
  {
    "id": "9204042026",
    "district_id": "920404",
    "label": "Odeare",
    "value": "Odeare"
  },
  {
    "id": "9204042027",
    "district_id": "920404",
    "label": "Siri-siri",
    "value": "Siri-siri"
  },
  {
    "id": "9204062001",
    "district_id": "920406",
    "label": "Sawiat",
    "value": "Sawiat"
  },
  {
    "id": "9204062008",
    "district_id": "920406",
    "label": "Sasnek",
    "value": "Sasnek"
  },
  {
    "id": "9204062009",
    "district_id": "920406",
    "label": "Elles",
    "value": "Elles"
  },
  {
    "id": "9204062011",
    "district_id": "920406",
    "label": "Sodrofoyo",
    "value": "Sodrofoyo"
  },
  {
    "id": "9204062012",
    "district_id": "920406",
    "label": "Sfakyo",
    "value": "Sfakyo"
  },
  {
    "id": "9204062013",
    "district_id": "920406",
    "label": "Wensough",
    "value": "Wensough"
  },
  {
    "id": "9204062014",
    "district_id": "920406",
    "label": "Ween",
    "value": "Ween"
  },
  {
    "id": "9204062015",
    "district_id": "920406",
    "label": "Wendi",
    "value": "Wendi"
  },
  {
    "id": "9204092001",
    "district_id": "920409",
    "label": "Tarof",
    "value": "Tarof"
  },
  {
    "id": "9204092002",
    "district_id": "920409",
    "label": "Negeri Besar",
    "value": "Negeri Besar"
  },
  {
    "id": "9204092003",
    "district_id": "920409",
    "label": "Siwatori",
    "value": "Siwatori"
  },
  {
    "id": "9204092004",
    "district_id": "920409",
    "label": "Kasuweri",
    "value": "Kasuweri"
  },
  {
    "id": "9204092005",
    "district_id": "920409",
    "label": "Migori",
    "value": "Migori"
  },
  {
    "id": "9204092006",
    "district_id": "920409",
    "label": "Tambani",
    "value": "Tambani"
  },
  {
    "id": "9204092010",
    "district_id": "920409",
    "label": "Daubak",
    "value": "Daubak"
  },
  {
    "id": "9204092011",
    "district_id": "920409",
    "label": "Totona",
    "value": "Totona"
  },
  {
    "id": "9204092012",
    "district_id": "920409",
    "label": "Nayakore",
    "value": "Nayakore"
  },
  {
    "id": "9204092014",
    "district_id": "920409",
    "label": "Arbasina",
    "value": "Arbasina"
  },
  {
    "id": "9204092015",
    "district_id": "920409",
    "label": "Birawaku",
    "value": "Birawaku"
  },
  {
    "id": "9204092016",
    "district_id": "920409",
    "label": "Korewatara",
    "value": "Korewatara"
  },
  {
    "id": "9204092017",
    "district_id": "920409",
    "label": "Tapas",
    "value": "Tapas"
  },
  {
    "id": "9204092018",
    "district_id": "920409",
    "label": "Topdan",
    "value": "Topdan"
  },
  {
    "id": "9204092020",
    "district_id": "920409",
    "label": "Migrito",
    "value": "Migrito"
  },
  {
    "id": "9204092021",
    "district_id": "920409",
    "label": "Daimar",
    "value": "Daimar"
  },
  {
    "id": "9204102001",
    "district_id": "920410",
    "label": "Moswaren",
    "value": "Moswaren"
  },
  {
    "id": "9204102002",
    "district_id": "920410",
    "label": "Johsiro",
    "value": "Johsiro"
  },
  {
    "id": "9204102003",
    "district_id": "920410",
    "label": "Hararo",
    "value": "Hararo"
  },
  {
    "id": "9204102004",
    "district_id": "920410",
    "label": "Bumi Ajo",
    "value": "Bumi Ajo"
  },
  {
    "id": "9204102005",
    "district_id": "920410",
    "label": "Hasik Jaya",
    "value": "Hasik Jaya"
  },
  {
    "id": "9204102006",
    "district_id": "920410",
    "label": "Kamisabe",
    "value": "Kamisabe"
  },
  {
    "id": "9204102007",
    "district_id": "920410",
    "label": "Tokass",
    "value": "Tokass"
  },
  {
    "id": "9204112001",
    "district_id": "920411",
    "label": "Haha",
    "value": "Haha"
  },
  {
    "id": "9204112003",
    "district_id": "920411",
    "label": "Klaogin",
    "value": "Klaogin"
  },
  {
    "id": "9204112004",
    "district_id": "920411",
    "label": "Tofot",
    "value": "Tofot"
  },
  {
    "id": "9204112005",
    "district_id": "920411",
    "label": "Sbir",
    "value": "Sbir"
  },
  {
    "id": "9204112006",
    "district_id": "920411",
    "label": "Srer",
    "value": "Srer"
  },
  {
    "id": "9204112007",
    "district_id": "920411",
    "label": "Woloin",
    "value": "Woloin"
  },
  {
    "id": "9204112008",
    "district_id": "920411",
    "label": "Kamaro",
    "value": "Kamaro"
  },
  {
    "id": "9204112009",
    "district_id": "920411",
    "label": "Kakas",
    "value": "Kakas"
  },
  {
    "id": "9204122001",
    "district_id": "920412",
    "label": "Sungguer",
    "value": "Sungguer"
  },
  {
    "id": "9204122002",
    "district_id": "920412",
    "label": "Unggi",
    "value": "Unggi"
  },
  {
    "id": "9204122003",
    "district_id": "920412",
    "label": "Boldon",
    "value": "Boldon"
  },
  {
    "id": "9204122004",
    "district_id": "920412",
    "label": "Sesor",
    "value": "Sesor"
  },
  {
    "id": "9204122005",
    "district_id": "920412",
    "label": "Waigo",
    "value": "Waigo"
  },
  {
    "id": "9204122006",
    "district_id": "920412",
    "label": "Bagaraga",
    "value": "Bagaraga"
  },
  {
    "id": "9204122007",
    "district_id": "920412",
    "label": "Wardik",
    "value": "Wardik"
  },
  {
    "id": "9204122008",
    "district_id": "920412",
    "label": "Wayer",
    "value": "Wayer"
  },
  {
    "id": "9204142001",
    "district_id": "920414",
    "label": "Kais",
    "value": "Kais"
  },
  {
    "id": "9204142002",
    "district_id": "920414",
    "label": "Tapuri",
    "value": "Tapuri"
  },
  {
    "id": "9204142003",
    "district_id": "920414",
    "label": "Yahadian",
    "value": "Yahadian"
  },
  {
    "id": "9204142004",
    "district_id": "920414",
    "label": "Benawa I",
    "value": "Benawa I"
  },
  {
    "id": "9204142006",
    "district_id": "920414",
    "label": "Sumano",
    "value": "Sumano"
  },
  {
    "id": "9204152001",
    "district_id": "920415",
    "label": "Konda",
    "value": "Konda"
  },
  {
    "id": "9204152002",
    "district_id": "920415",
    "label": "Wamargege",
    "value": "Wamargege"
  },
  {
    "id": "9204152003",
    "district_id": "920415",
    "label": "Bariat",
    "value": "Bariat"
  },
  {
    "id": "9204152004",
    "district_id": "920415",
    "label": "Manelek",
    "value": "Manelek"
  },
  {
    "id": "9204152005",
    "district_id": "920415",
    "label": "Nagna",
    "value": "Nagna"
  },
  {
    "id": "9204202001",
    "district_id": "920420",
    "label": "Mugim",
    "value": "Mugim"
  },
  {
    "id": "9204202002",
    "district_id": "920420",
    "label": "Nusa",
    "value": "Nusa"
  },
  {
    "id": "9204202003",
    "district_id": "920420",
    "label": "Saga",
    "value": "Saga"
  },
  {
    "id": "9204202004",
    "district_id": "920420",
    "label": "Puragi",
    "value": "Puragi"
  },
  {
    "id": "9204202005",
    "district_id": "920420",
    "label": "Tawanggire",
    "value": "Tawanggire"
  },
  {
    "id": "9204202006",
    "district_id": "920420",
    "label": "Bedare",
    "value": "Bedare"
  },
  {
    "id": "9204212001",
    "district_id": "920421",
    "label": "Udagaga",
    "value": "Udagaga"
  },
  {
    "id": "9204212002",
    "district_id": "920421",
    "label": "Kayubiro",
    "value": "Kayubiro"
  },
  {
    "id": "9204212003",
    "district_id": "920421",
    "label": "Adona",
    "value": "Adona"
  },
  {
    "id": "9204212004",
    "district_id": "920421",
    "label": "Benawa II",
    "value": "Benawa II"
  },
  {
    "id": "9204212005",
    "district_id": "920421",
    "label": "Atori",
    "value": "Atori"
  },
  {
    "id": "9204212006",
    "district_id": "920421",
    "label": "Kamundan Satu",
    "value": "Kamundan Satu"
  },
  {
    "id": "9204212007",
    "district_id": "920421",
    "label": "Karirif",
    "value": "Karirif"
  },
  {
    "id": "9204212008",
    "district_id": "920421",
    "label": "Bubuko",
    "value": "Bubuko"
  },
  {
    "id": "9204212009",
    "district_id": "920421",
    "label": "Kamundan Dua",
    "value": "Kamundan Dua"
  },
  {
    "id": "9204222001",
    "district_id": "920422",
    "label": "Manggroholo",
    "value": "Manggroholo"
  },
  {
    "id": "9204222002",
    "district_id": "920422",
    "label": "Komanggaret",
    "value": "Komanggaret"
  },
  {
    "id": "9204222003",
    "district_id": "920422",
    "label": "Sayal",
    "value": "Sayal"
  },
  {
    "id": "9204222004",
    "district_id": "920422",
    "label": "Kayabo",
    "value": "Kayabo"
  },
  {
    "id": "9204222005",
    "district_id": "920422",
    "label": "Sisir",
    "value": "Sisir"
  },
  {
    "id": "9204222006",
    "district_id": "920422",
    "label": "Mlaswat",
    "value": "Mlaswat"
  },
  {
    "id": "9204222007",
    "district_id": "920422",
    "label": "Kwowok",
    "value": "Kwowok"
  },
  {
    "id": "9204222008",
    "district_id": "920422",
    "label": "Sira",
    "value": "Sira"
  },
  {
    "id": "9204222009",
    "district_id": "920422",
    "label": "Kenaya",
    "value": "Kenaya"
  },
  {
    "id": "9204222010",
    "district_id": "920422",
    "label": "Botain",
    "value": "Botain"
  },
  {
    "id": "9204242001",
    "district_id": "920424",
    "label": "Pasir Putih",
    "value": "Pasir Putih"
  },
  {
    "id": "9204242002",
    "district_id": "920424",
    "label": "Wandum",
    "value": "Wandum"
  },
  {
    "id": "9204242003",
    "district_id": "920424",
    "label": "Bemus",
    "value": "Bemus"
  },
  {
    "id": "9204242004",
    "district_id": "920424",
    "label": "Welek",
    "value": "Welek"
  },
  {
    "id": "9204252001",
    "district_id": "920425",
    "label": "Wenslolo",
    "value": "Wenslolo"
  },
  {
    "id": "9204252002",
    "district_id": "920425",
    "label": "Klamit",
    "value": "Klamit"
  },
  {
    "id": "9204252003",
    "district_id": "920425",
    "label": "Mlabolo",
    "value": "Mlabolo"
  },
  {
    "id": "9204252004",
    "district_id": "920425",
    "label": "Kofalit",
    "value": "Kofalit"
  },
  {
    "id": "9204252005",
    "district_id": "920425",
    "label": "Alma",
    "value": "Alma"
  },
  {
    "id": "9204262001",
    "district_id": "920426",
    "label": "Mukamat",
    "value": "Mukamat"
  },
  {
    "id": "9204262002",
    "district_id": "920426",
    "label": "Ikana",
    "value": "Ikana"
  },
  {
    "id": "9204262003",
    "district_id": "920426",
    "label": "Haemaran",
    "value": "Haemaran"
  },
  {
    "id": "9204262004",
    "district_id": "920426",
    "label": "Siranggo",
    "value": "Siranggo"
  },
  {
    "id": "9204262005",
    "district_id": "920426",
    "label": "Mogatemin",
    "value": "Mogatemin"
  },
  {
    "id": "9204262006",
    "district_id": "920426",
    "label": "Onimsefa",
    "value": "Onimsefa"
  },
  {
    "id": "9204262007",
    "district_id": "920426",
    "label": "Makaroro",
    "value": "Makaroro"
  },
  {
    "id": "9205012001",
    "district_id": "920501",
    "label": "Waigama",
    "value": "Waigama"
  },
  {
    "id": "9205012005",
    "district_id": "920501",
    "label": "Salafen",
    "value": "Salafen"
  },
  {
    "id": "9205012006",
    "district_id": "920501",
    "label": "Aduwei",
    "value": "Aduwei"
  },
  {
    "id": "9205012007",
    "district_id": "920501",
    "label": "Atkari",
    "value": "Atkari"
  },
  {
    "id": "9205012014",
    "district_id": "920501",
    "label": "Solal",
    "value": "Solal"
  },
  {
    "id": "9205022003",
    "district_id": "920502",
    "label": "Kabare",
    "value": "Kabare"
  },
  {
    "id": "9205022010",
    "district_id": "920502",
    "label": "Andey",
    "value": "Andey"
  },
  {
    "id": "9205022011",
    "district_id": "920502",
    "label": "Asukweri",
    "value": "Asukweri"
  },
  {
    "id": "9205022013",
    "district_id": "920502",
    "label": "Bonsayor",
    "value": "Bonsayor"
  },
  {
    "id": "9205022014",
    "district_id": "920502",
    "label": "Darumbab",
    "value": "Darumbab"
  },
  {
    "id": "9205022015",
    "district_id": "920502",
    "label": "Kalisade",
    "value": "Kalisade"
  },
  {
    "id": "9205032004",
    "district_id": "920503",
    "label": "Saonek",
    "value": "Saonek"
  },
  {
    "id": "9205032014",
    "district_id": "920503",
    "label": "Saporkren",
    "value": "Saporkren"
  },
  {
    "id": "9205032015",
    "district_id": "920503",
    "label": "Yenbeser",
    "value": "Yenbeser"
  },
  {
    "id": "9205032016",
    "district_id": "920503",
    "label": "Friwen",
    "value": "Friwen"
  },
  {
    "id": "9205032017",
    "district_id": "920503",
    "label": "Wawiyai",
    "value": "Wawiyai"
  },
  {
    "id": "9205042001",
    "district_id": "920504",
    "label": "Samate",
    "value": "Samate"
  },
  {
    "id": "9205042002",
    "district_id": "920504",
    "label": "Kapatlap",
    "value": "Kapatlap"
  },
  {
    "id": "9205042003",
    "district_id": "920504",
    "label": "Jefman Barat",
    "value": "Jefman Barat"
  },
  {
    "id": "9205042015",
    "district_id": "920504",
    "label": "Wamega",
    "value": "Wamega"
  },
  {
    "id": "9205042016",
    "district_id": "920504",
    "label": "Jefman Timur",
    "value": "Jefman Timur"
  },
  {
    "id": "9205042017",
    "district_id": "920504",
    "label": "Waidim",
    "value": "Waidim"
  },
  {
    "id": "9205052001",
    "district_id": "920505",
    "label": "Dorehkar",
    "value": "Dorehkar"
  },
  {
    "id": "9205052003",
    "district_id": "920505",
    "label": "Yenkawir",
    "value": "Yenkawir"
  },
  {
    "id": "9205052006",
    "district_id": "920505",
    "label": "Boiseran",
    "value": "Boiseran"
  },
  {
    "id": "9205052007",
    "district_id": "920505",
    "label": "Runi",
    "value": "Runi"
  },
  {
    "id": "9205052008",
    "district_id": "920505",
    "label": "Yenkanfan",
    "value": "Yenkanfan"
  },
  {
    "id": "9205062001",
    "district_id": "920506",
    "label": "Folley",
    "value": "Folley"
  },
  {
    "id": "9205062003",
    "district_id": "920506",
    "label": "Tomolol",
    "value": "Tomolol"
  },
  {
    "id": "9205062011",
    "district_id": "920506",
    "label": "Usaha Jaya",
    "value": "Usaha Jaya"
  },
  {
    "id": "9205062012",
    "district_id": "920506",
    "label": "Limalas Timur",
    "value": "Limalas Timur"
  },
  {
    "id": "9205062016",
    "district_id": "920506",
    "label": "Audam",
    "value": "Audam"
  },
  {
    "id": "9205062017",
    "district_id": "920506",
    "label": "Limalas Barat",
    "value": "Limalas Barat"
  },
  {
    "id": "9205072001",
    "district_id": "920507",
    "label": "Selpele",
    "value": "Selpele"
  },
  {
    "id": "9205072005",
    "district_id": "920507",
    "label": "Mutus",
    "value": "Mutus"
  },
  {
    "id": "9205072007",
    "district_id": "920507",
    "label": "Bianci",
    "value": "Bianci"
  },
  {
    "id": "9205072011",
    "district_id": "920507",
    "label": "Saleo",
    "value": "Saleo"
  },
  {
    "id": "9205072012",
    "district_id": "920507",
    "label": "Waisilip",
    "value": "Waisilip"
  },
  {
    "id": "9205082001",
    "district_id": "920508",
    "label": "Puper",
    "value": "Puper"
  },
  {
    "id": "9205082002",
    "district_id": "920508",
    "label": "Yenbekaki",
    "value": "Yenbekaki"
  },
  {
    "id": "9205082003",
    "district_id": "920508",
    "label": "Urbinasopen",
    "value": "Urbinasopen"
  },
  {
    "id": "9205082004",
    "district_id": "920508",
    "label": "Yensner",
    "value": "Yensner"
  },
  {
    "id": "9205092001",
    "district_id": "920509",
    "label": "Lopintol",
    "value": "Lopintol"
  },
  {
    "id": "9205092007",
    "district_id": "920509",
    "label": "Warsamdin",
    "value": "Warsamdin"
  },
  {
    "id": "9205092009",
    "district_id": "920509",
    "label": "Mumes",
    "value": "Mumes"
  },
  {
    "id": "9205092010",
    "district_id": "920509",
    "label": "Kalitoko",
    "value": "Kalitoko"
  },
  {
    "id": "9205102001",
    "district_id": "920510",
    "label": "DibalaI",
    "value": "DibalaI"
  },
  {
    "id": "9205102002",
    "district_id": "920510",
    "label": "Tolobi",
    "value": "Tolobi"
  },
  {
    "id": "9205102003",
    "district_id": "920510",
    "label": "Deer",
    "value": "Deer"
  },
  {
    "id": "9205102004",
    "district_id": "920510",
    "label": "Mikiran",
    "value": "Mikiran"
  },
  {
    "id": "9205102005",
    "district_id": "920510",
    "label": "Awat",
    "value": "Awat"
  },
  {
    "id": "9205112001",
    "district_id": "920511",
    "label": "Yenbekwan",
    "value": "Yenbekwan"
  },
  {
    "id": "9205112002",
    "district_id": "920511",
    "label": "Kapisawar",
    "value": "Kapisawar"
  },
  {
    "id": "9205112003",
    "district_id": "920511",
    "label": "Sawinggrai",
    "value": "Sawinggrai"
  },
  {
    "id": "9205112004",
    "district_id": "920511",
    "label": "Yenwaupnor",
    "value": "Yenwaupnor"
  },
  {
    "id": "9205112005",
    "district_id": "920511",
    "label": "Arborek",
    "value": "Arborek"
  },
  {
    "id": "9205112006",
    "district_id": "920511",
    "label": "Yenbuba",
    "value": "Yenbuba"
  },
  {
    "id": "9205112007",
    "district_id": "920511",
    "label": "Kabuy",
    "value": "Kabuy"
  },
  {
    "id": "9205112008",
    "district_id": "920511",
    "label": "Kurkapa",
    "value": "Kurkapa"
  },
  {
    "id": "9205112009",
    "district_id": "920511",
    "label": "Sawandarek",
    "value": "Sawandarek"
  },
  {
    "id": "9205132002",
    "district_id": "920513",
    "label": "Fafanlap",
    "value": "Fafanlap"
  },
  {
    "id": "9205132006",
    "district_id": "920513",
    "label": "Yellu",
    "value": "Yellu"
  },
  {
    "id": "9205132007",
    "district_id": "920513",
    "label": "Harapan Jaya",
    "value": "Harapan Jaya"
  },
  {
    "id": "9205132009",
    "district_id": "920513",
    "label": "Usaha Jaya",
    "value": "Usaha Jaya"
  },
  {
    "id": "9205132010",
    "district_id": "920513",
    "label": "Dabatan",
    "value": "Dabatan"
  },
  {
    "id": "9205142001",
    "district_id": "920514",
    "label": "Warmanai",
    "value": "Warmanai"
  },
  {
    "id": "9205142002",
    "district_id": "920514",
    "label": "Mnier",
    "value": "Mnier"
  },
  {
    "id": "9205142003",
    "district_id": "920514",
    "label": "Boni",
    "value": "Boni"
  },
  {
    "id": "9205142004",
    "district_id": "920514",
    "label": "Warkori",
    "value": "Warkori"
  },
  {
    "id": "9205152001",
    "district_id": "920515",
    "label": "Manyaifun",
    "value": "Manyaifun"
  },
  {
    "id": "9205152002",
    "district_id": "920515",
    "label": "Meosmanggara",
    "value": "Meosmanggara"
  },
  {
    "id": "9205152003",
    "district_id": "920515",
    "label": "Pam",
    "value": "Pam"
  },
  {
    "id": "9205152004",
    "district_id": "920515",
    "label": "Saukabu",
    "value": "Saukabu"
  },
  {
    "id": "9205152005",
    "district_id": "920515",
    "label": "Gag",
    "value": "Gag"
  },
  {
    "id": "9205152006",
    "district_id": "920515",
    "label": "Saupapir",
    "value": "Saupapir"
  },
  {
    "id": "9205162001",
    "district_id": "920516",
    "label": "Lilinta",
    "value": "Lilinta"
  },
  {
    "id": "9205162002",
    "district_id": "920516",
    "label": "Gamta",
    "value": "Gamta"
  },
  {
    "id": "9205162003",
    "district_id": "920516",
    "label": "Biga",
    "value": "Biga"
  },
  {
    "id": "9205162004",
    "district_id": "920516",
    "label": "Kapatcol",
    "value": "Kapatcol"
  },
  {
    "id": "9205162005",
    "district_id": "920516",
    "label": "Magey",
    "value": "Magey"
  },
  {
    "id": "9205172001",
    "district_id": "920517",
    "label": "Wejim Barat",
    "value": "Wejim Barat"
  },
  {
    "id": "9205172002",
    "district_id": "920517",
    "label": "Wejim Timur",
    "value": "Wejim Timur"
  },
  {
    "id": "9205172003",
    "district_id": "920517",
    "label": "Satukurano",
    "value": "Satukurano"
  },
  {
    "id": "9205172004",
    "district_id": "920517",
    "label": "Pulau Tikus",
    "value": "Pulau Tikus"
  },
  {
    "id": "9205181001",
    "district_id": "920518",
    "label": "Waisai",
    "value": "Waisai"
  },
  {
    "id": "9205181002",
    "district_id": "920518",
    "label": "Sapordanco",
    "value": "Sapordanco"
  },
  {
    "id": "9205181003",
    "district_id": "920518",
    "label": "Bonwakir",
    "value": "Bonwakir"
  },
  {
    "id": "9205181004",
    "district_id": "920518",
    "label": "Warmasen",
    "value": "Warmasen"
  },
  { "id": "9205192001", "district_id": "920519", "label": "Go", "value": "Go" },
  {
    "id": "9205192002",
    "district_id": "920519",
    "label": "Kabilol",
    "value": "Kabilol"
  },
  {
    "id": "9205192003",
    "district_id": "920519",
    "label": "Arway",
    "value": "Arway"
  },
  {
    "id": "9205192004",
    "district_id": "920519",
    "label": "Beo",
    "value": "Beo"
  },
  {
    "id": "9205192005",
    "district_id": "920519",
    "label": "Waifoi",
    "value": "Waifoi"
  },
  {
    "id": "9205192006",
    "district_id": "920519",
    "label": "Warimak",
    "value": "Warimak"
  },
  {
    "id": "9205202001",
    "district_id": "920520",
    "label": "Yensawai Timur",
    "value": "Yensawai Timur"
  },
  {
    "id": "9205202002",
    "district_id": "920520",
    "label": "Yensawai Barat",
    "value": "Yensawai Barat"
  },
  {
    "id": "9205202003",
    "district_id": "920520",
    "label": "Arefi Timur",
    "value": "Arefi Timur"
  },
  {
    "id": "9205202004",
    "district_id": "920520",
    "label": "Arefi Selatan",
    "value": "Arefi Selatan"
  },
  {
    "id": "9205212001",
    "district_id": "920521",
    "label": "Waibon",
    "value": "Waibon"
  },
  {
    "id": "9205212002",
    "district_id": "920521",
    "label": "Solol",
    "value": "Solol"
  },
  {
    "id": "9205212003",
    "district_id": "920521",
    "label": "Kaliam",
    "value": "Kaliam"
  },
  {
    "id": "9205212004",
    "district_id": "920521",
    "label": "Kalwal",
    "value": "Kalwal"
  },
  {
    "id": "9205222001",
    "district_id": "920522",
    "label": "Kalobo",
    "value": "Kalobo"
  },
  {
    "id": "9205222002",
    "district_id": "920522",
    "label": "Sakabu",
    "value": "Sakabu"
  },
  {
    "id": "9205222003",
    "district_id": "920522",
    "label": "Waibu",
    "value": "Waibu"
  },
  {
    "id": "9205222004",
    "district_id": "920522",
    "label": "Waijan",
    "value": "Waijan"
  },
  {
    "id": "9205222005",
    "district_id": "920522",
    "label": "Waimeci",
    "value": "Waimeci"
  },
  {
    "id": "9205222006",
    "district_id": "920522",
    "label": "Wailabu",
    "value": "Wailabu"
  },
  {
    "id": "9205222007",
    "district_id": "920522",
    "label": "Wailen",
    "value": "Wailen"
  },
  {
    "id": "9205232001",
    "district_id": "920523",
    "label": "Rauki",
    "value": "Rauki"
  },
  {
    "id": "9205232002",
    "district_id": "920523",
    "label": "Urai",
    "value": "Urai"
  },
  {
    "id": "9205232003",
    "district_id": "920523",
    "label": "Duber",
    "value": "Duber"
  },
  {
    "id": "9205232004",
    "district_id": "920523",
    "label": "Kapadiri",
    "value": "Kapadiri"
  },
  {
    "id": "9205242001",
    "district_id": "920524",
    "label": "Abidon",
    "value": "Abidon"
  },
  {
    "id": "9205242002",
    "district_id": "920524",
    "label": "Rutum",
    "value": "Rutum"
  },
  {
    "id": "9205242003",
    "district_id": "920524",
    "label": "Reni",
    "value": "Reni"
  },
  {
    "id": "9205242004",
    "district_id": "920524",
    "label": "Meosbekwan",
    "value": "Meosbekwan"
  },
  {
    "id": "9205252001",
    "district_id": "920525",
    "label": "Yenanas",
    "value": "Yenanas"
  },
  {
    "id": "9205252002",
    "district_id": "920525",
    "label": "Amdui",
    "value": "Amdui"
  },
  {
    "id": "9205252003",
    "district_id": "920525",
    "label": "Wailebet",
    "value": "Wailebet"
  },
  {
    "id": "9205252004",
    "district_id": "920525",
    "label": "Waiman",
    "value": "Waiman"
  },
  {
    "id": "9206011001",
    "district_id": "920601",
    "label": "Bintuni Timur",
    "value": "Bintuni Timur"
  },
  {
    "id": "9206011002",
    "district_id": "920601",
    "label": "Bintuni Barat",
    "value": "Bintuni Barat"
  },
  {
    "id": "9206012004",
    "district_id": "920601",
    "label": "Tuasai",
    "value": "Tuasai"
  },
  {
    "id": "9206012005",
    "district_id": "920601",
    "label": "Argosigemerai",
    "value": "Argosigemerai"
  },
  {
    "id": "9206012009",
    "district_id": "920601",
    "label": "Beimes",
    "value": "Beimes"
  },
  {
    "id": "9206012010",
    "district_id": "920601",
    "label": "Iguriji",
    "value": "Iguriji"
  },
  {
    "id": "9206012022",
    "district_id": "920601",
    "label": "Masina",
    "value": "Masina"
  },
  {
    "id": "9206012026",
    "district_id": "920601",
    "label": "Wesiri",
    "value": "Wesiri"
  },
  {
    "id": "9206022001",
    "district_id": "920602",
    "label": "Merdey",
    "value": "Merdey"
  },
  {
    "id": "9206022002",
    "district_id": "920602",
    "label": "Meryeb",
    "value": "Meryeb"
  },
  {
    "id": "9206022004",
    "district_id": "920602",
    "label": "Meyom",
    "value": "Meyom"
  },
  {
    "id": "9206022007",
    "district_id": "920602",
    "label": "Menggerba",
    "value": "Menggerba"
  },
  {
    "id": "9206022008",
    "district_id": "920602",
    "label": "Anajero",
    "value": "Anajero"
  },
  {
    "id": "9206022010",
    "district_id": "920602",
    "label": "Mogromus",
    "value": "Mogromus"
  },
  {
    "id": "9206022011",
    "district_id": "920602",
    "label": "Morombuy",
    "value": "Morombuy"
  },
  {
    "id": "9206022014",
    "district_id": "920602",
    "label": "Mekiesefeb",
    "value": "Mekiesefeb"
  },
  {
    "id": "9206022020",
    "district_id": "920602",
    "label": "Meyetga",
    "value": "Meyetga"
  },
  {
    "id": "9206032020",
    "district_id": "920603",
    "label": "Irarutu III",
    "value": "Irarutu III"
  },
  {
    "id": "9206032021",
    "district_id": "920603",
    "label": "Amutu",
    "value": "Amutu"
  },
  {
    "id": "9206032022",
    "district_id": "920603",
    "label": "Nusei",
    "value": "Nusei"
  },
  {
    "id": "9206032023",
    "district_id": "920603",
    "label": "Kasira",
    "value": "Kasira"
  },
  {
    "id": "9206042001",
    "district_id": "920604",
    "label": "Aranday",
    "value": "Aranday"
  },
  {
    "id": "9206042007",
    "district_id": "920604",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "9206042008",
    "district_id": "920604",
    "label": "Kecap",
    "value": "Kecap"
  },
  {
    "id": "9206042009",
    "district_id": "920604",
    "label": "Manunggal Karya",
    "value": "Manunggal Karya"
  },
  {
    "id": "9206052001",
    "district_id": "920605",
    "label": "Jagiro",
    "value": "Jagiro"
  },
  {
    "id": "9206052004",
    "district_id": "920605",
    "label": "Meyenda",
    "value": "Meyenda"
  },
  {
    "id": "9206052005",
    "district_id": "920605",
    "label": "Rawara",
    "value": "Rawara"
  },
  {
    "id": "9206052006",
    "district_id": "920605",
    "label": "Barma Barat",
    "value": "Barma Barat"
  },
  {
    "id": "9206052011",
    "district_id": "920605",
    "label": "Inggof",
    "value": "Inggof"
  },
  {
    "id": "9206062001",
    "district_id": "920606",
    "label": "Moyeba",
    "value": "Moyeba"
  },
  {
    "id": "9206062004",
    "district_id": "920606",
    "label": "Merestim",
    "value": "Merestim"
  },
  {
    "id": "9206062005",
    "district_id": "920606",
    "label": "Inofina",
    "value": "Inofina"
  },
  {
    "id": "9206062006",
    "district_id": "920606",
    "label": "Mosum",
    "value": "Mosum"
  },
  {
    "id": "9206072001",
    "district_id": "920607",
    "label": "Wamesa I / Idoor",
    "value": "Wamesa I / Idoor"
  },
  {
    "id": "9206072002",
    "district_id": "920607",
    "label": "Wamesa II / Yakati",
    "value": "Wamesa II / Yakati"
  },
  {
    "id": "9206072003",
    "district_id": "920607",
    "label": "Yansei",
    "value": "Yansei"
  },
  {
    "id": "9206072004",
    "district_id": "920607",
    "label": "Mamuranu",
    "value": "Mamuranu"
  },
  {
    "id": "9206082001",
    "district_id": "920608",
    "label": "Fruata/Irowutu II",
    "value": "Fruata/Irowutu II"
  },
  {
    "id": "9206082002",
    "district_id": "920608",
    "label": "Meryedi",
    "value": "Meryedi"
  },
  {
    "id": "9206082003",
    "district_id": "920608",
    "label": "Riendo",
    "value": "Riendo"
  },
  {
    "id": "9206092001",
    "district_id": "920609",
    "label": "Tembuni",
    "value": "Tembuni"
  },
  {
    "id": "9206092002",
    "district_id": "920609",
    "label": "Mogoi Baru",
    "value": "Mogoi Baru"
  },
  {
    "id": "9206092003",
    "district_id": "920609",
    "label": "Araisum",
    "value": "Araisum"
  },
  {
    "id": "9206092004",
    "district_id": "920609",
    "label": "Bangun Mulya",
    "value": "Bangun Mulya"
  },
  {
    "id": "9206102001",
    "district_id": "920610",
    "label": "Sarbe",
    "value": "Sarbe"
  },
  {
    "id": "9206102002",
    "district_id": "920610",
    "label": "Wagura",
    "value": "Wagura"
  },
  {
    "id": "9206102003",
    "district_id": "920610",
    "label": "Refideso",
    "value": "Refideso"
  },
  {
    "id": "9206102004",
    "district_id": "920610",
    "label": "Naramasa",
    "value": "Naramasa"
  },
  {
    "id": "9206102005",
    "district_id": "920610",
    "label": "Obo",
    "value": "Obo"
  },
  {
    "id": "9206112001",
    "district_id": "920611",
    "label": "Bumi Saniari",
    "value": "Bumi Saniari"
  },
  {
    "id": "9206112002",
    "district_id": "920611",
    "label": "Banjar Ausoy",
    "value": "Banjar Ausoy"
  },
  {
    "id": "9206112003",
    "district_id": "920611",
    "label": "Waraitama",
    "value": "Waraitama"
  },
  {
    "id": "9206112004",
    "district_id": "920611",
    "label": "Atibo Manimeri",
    "value": "Atibo Manimeri"
  },
  {
    "id": "9206112005",
    "district_id": "920611",
    "label": "Pasamai",
    "value": "Pasamai"
  },
  {
    "id": "9206112006",
    "district_id": "920611",
    "label": "Korano Jaya",
    "value": "Korano Jaya"
  },
  {
    "id": "9206122001",
    "district_id": "920612",
    "label": "Tuhiba",
    "value": "Tuhiba"
  },
  {
    "id": "9206122002",
    "district_id": "920612",
    "label": "Tisaida",
    "value": "Tisaida"
  },
  {
    "id": "9206122003",
    "district_id": "920612",
    "label": "Kucir",
    "value": "Kucir"
  },
  {
    "id": "9206122004",
    "district_id": "920612",
    "label": "Sibena Raya",
    "value": "Sibena Raya"
  },
  {
    "id": "9206122005",
    "district_id": "920612",
    "label": "Sibena Permai",
    "value": "Sibena Permai"
  },
  {
    "id": "9206132001",
    "district_id": "920613",
    "label": "Horna",
    "value": "Horna"
  },
  {
    "id": "9206132002",
    "district_id": "920613",
    "label": "Cumnaji",
    "value": "Cumnaji"
  },
  {
    "id": "9206132003",
    "district_id": "920613",
    "label": "Menci",
    "value": "Menci"
  },
  {
    "id": "9206132004",
    "district_id": "920613",
    "label": "Sir",
    "value": "Sir"
  },
  {
    "id": "9206132005",
    "district_id": "920613",
    "label": "Huss",
    "value": "Huss"
  },
  {
    "id": "9206132006",
    "district_id": "920613",
    "label": "Ugdohop",
    "value": "Ugdohop"
  },
  {
    "id": "9206142001",
    "district_id": "920614",
    "label": "Tofoi",
    "value": "Tofoi"
  },
  {
    "id": "9206142002",
    "district_id": "920614",
    "label": "Tanah Merah",
    "value": "Tanah Merah"
  },
  {
    "id": "9206142003",
    "district_id": "920614",
    "label": "Saengga",
    "value": "Saengga"
  },
  {
    "id": "9206142004",
    "district_id": "920614",
    "label": "Forada",
    "value": "Forada"
  },
  {
    "id": "9206142005",
    "district_id": "920614",
    "label": "Materabu Jaya",
    "value": "Materabu Jaya"
  },
  {
    "id": "9206152001",
    "district_id": "920615",
    "label": "Sara",
    "value": "Sara"
  },
  {
    "id": "9206152002",
    "district_id": "920615",
    "label": "Warga Nusa I",
    "value": "Warga Nusa I"
  },
  {
    "id": "9206152003",
    "district_id": "920615",
    "label": "Warga Nusa II",
    "value": "Warga Nusa II"
  },
  {
    "id": "9206152004",
    "district_id": "920615",
    "label": "Tugarama",
    "value": "Tugarama"
  },
  {
    "id": "9206152005",
    "district_id": "920615",
    "label": "Suga",
    "value": "Suga"
  },
  {
    "id": "9206162001",
    "district_id": "920616",
    "label": "Aroba",
    "value": "Aroba"
  },
  {
    "id": "9206162002",
    "district_id": "920616",
    "label": "Yaru",
    "value": "Yaru"
  },
  {
    "id": "9206162003",
    "district_id": "920616",
    "label": "Sido Makmur",
    "value": "Sido Makmur"
  },
  {
    "id": "9206162004",
    "district_id": "920616",
    "label": "Wimbro",
    "value": "Wimbro"
  },
  {
    "id": "9206162005",
    "district_id": "920616",
    "label": "Sangguar",
    "value": "Sangguar"
  },
  {
    "id": "9206172001",
    "district_id": "920617",
    "label": "Masyeta",
    "value": "Masyeta"
  },
  {
    "id": "9206172002",
    "district_id": "920617",
    "label": "Mestofu",
    "value": "Mestofu"
  },
  {
    "id": "9206172003",
    "district_id": "920617",
    "label": "Kalibiru",
    "value": "Kalibiru"
  },
  {
    "id": "9206172004",
    "district_id": "920617",
    "label": "Mesomda",
    "value": "Mesomda"
  },
  {
    "id": "9206182001",
    "district_id": "920618",
    "label": "Jahabra",
    "value": "Jahabra"
  },
  {
    "id": "9206182002",
    "district_id": "920618",
    "label": "Ibori",
    "value": "Ibori"
  },
  {
    "id": "9206182003",
    "district_id": "920618",
    "label": "Menyembru",
    "value": "Menyembru"
  },
  {
    "id": "9206182004",
    "district_id": "920618",
    "label": "Meyorga",
    "value": "Meyorga"
  },
  {
    "id": "9206182005",
    "district_id": "920618",
    "label": "Laudoho",
    "value": "Laudoho"
  },
  {
    "id": "9206182006",
    "district_id": "920618",
    "label": "Eniba",
    "value": "Eniba"
  },
  {
    "id": "9206182007",
    "district_id": "920618",
    "label": "Mowitka",
    "value": "Mowitka"
  },
  {
    "id": "9206192001",
    "district_id": "920619",
    "label": "Sebyar Rejosasi",
    "value": "Sebyar Rejosasi"
  },
  {
    "id": "9206192002",
    "district_id": "920619",
    "label": "Tomu",
    "value": "Tomu"
  },
  {
    "id": "9206192003",
    "district_id": "920619",
    "label": "Taroy",
    "value": "Taroy"
  },
  {
    "id": "9206192004",
    "district_id": "920619",
    "label": "Ekam",
    "value": "Ekam"
  },
  {
    "id": "9206202001",
    "district_id": "920620",
    "label": "Kalitami I",
    "value": "Kalitami I"
  },
  {
    "id": "9206202002",
    "district_id": "920620",
    "label": "Kalitami II",
    "value": "Kalitami II"
  },
  {
    "id": "9206202003",
    "district_id": "920620",
    "label": "Kenara",
    "value": "Kenara"
  },
  {
    "id": "9206202004",
    "district_id": "920620",
    "label": "Bibiram",
    "value": "Bibiram"
  },
  {
    "id": "9206212001",
    "district_id": "920621",
    "label": "Weriagar",
    "value": "Weriagar"
  },
  {
    "id": "9206212002",
    "district_id": "920621",
    "label": "Mogotira",
    "value": "Mogotira"
  },
  {
    "id": "9206212003",
    "district_id": "920621",
    "label": "Weriagar Baru",
    "value": "Weriagar Baru"
  },
  {
    "id": "9206212004",
    "district_id": "920621",
    "label": "Weriagar Utara",
    "value": "Weriagar Utara"
  },
  {
    "id": "9206212005",
    "district_id": "920621",
    "label": "Tuanaikin",
    "value": "Tuanaikin"
  },
  {
    "id": "9206222001",
    "district_id": "920622",
    "label": "Meyerga",
    "value": "Meyerga"
  },
  {
    "id": "9206222002",
    "district_id": "920622",
    "label": "Macok",
    "value": "Macok"
  },
  {
    "id": "9206222003",
    "district_id": "920622",
    "label": "Istiwkem",
    "value": "Istiwkem"
  },
  {
    "id": "9206222004",
    "district_id": "920622",
    "label": "Majnic",
    "value": "Majnic"
  },
  {
    "id": "9206232001",
    "district_id": "920623",
    "label": "Meyado",
    "value": "Meyado"
  },
  {
    "id": "9206232002",
    "district_id": "920623",
    "label": "Barma",
    "value": "Barma"
  },
  {
    "id": "9206232003",
    "district_id": "920623",
    "label": "Barma Baru",
    "value": "Barma Baru"
  },
  {
    "id": "9206232004",
    "district_id": "920623",
    "label": "Vasco Damneen",
    "value": "Vasco Damneen"
  },
  {
    "id": "9206242001",
    "district_id": "920624",
    "label": "Igomu",
    "value": "Igomu"
  },
  {
    "id": "9206242002",
    "district_id": "920624",
    "label": "Mesna",
    "value": "Mesna"
  },
  {
    "id": "9206242003",
    "district_id": "920624",
    "label": "Sumuy",
    "value": "Sumuy"
  },
  {
    "id": "9207011001",
    "district_id": "920701",
    "label": "Wasior I",
    "value": "Wasior I"
  },
  {
    "id": "9207012002",
    "district_id": "920701",
    "label": "Rado",
    "value": "Rado"
  },
  {
    "id": "9207012003",
    "district_id": "920701",
    "label": "Dotir",
    "value": "Dotir"
  },
  {
    "id": "9207012004",
    "district_id": "920701",
    "label": "Maniwak",
    "value": "Maniwak"
  },
  {
    "id": "9207012005",
    "district_id": "920701",
    "label": "Wasior II",
    "value": "Wasior II"
  },
  {
    "id": "9207012006",
    "district_id": "920701",
    "label": "Maimari",
    "value": "Maimari"
  },
  {
    "id": "9207012007",
    "district_id": "920701",
    "label": "Moru",
    "value": "Moru"
  },
  {
    "id": "9207012009",
    "district_id": "920701",
    "label": "Manopi",
    "value": "Manopi"
  },
  {
    "id": "9207012010",
    "district_id": "920701",
    "label": "Ramiki",
    "value": "Ramiki"
  },
  {
    "id": "9207012011",
    "district_id": "920701",
    "label": "Iriati",
    "value": "Iriati"
  },
  {
    "id": "9207022001",
    "district_id": "920702",
    "label": "Wamesa Tengah",
    "value": "Wamesa Tengah"
  },
  {
    "id": "9207022002",
    "district_id": "920702",
    "label": "Yopmeos",
    "value": "Yopmeos"
  },
  {
    "id": "9207022004",
    "district_id": "920702",
    "label": "Sombokoro",
    "value": "Sombokoro"
  },
  {
    "id": "9207022007",
    "district_id": "920702",
    "label": "Windesi",
    "value": "Windesi"
  },
  {
    "id": "9207022010",
    "district_id": "920702",
    "label": "Sandey",
    "value": "Sandey"
  },
  {
    "id": "9207032001",
    "district_id": "920703",
    "label": "Aisandami",
    "value": "Aisandami"
  },
  {
    "id": "9207032002",
    "district_id": "920703",
    "label": "Sobey",
    "value": "Sobey"
  },
  {
    "id": "9207032006",
    "district_id": "920703",
    "label": "Yopanggar",
    "value": "Yopanggar"
  },
  {
    "id": "9207032011",
    "district_id": "920703",
    "label": "Sobey Indah",
    "value": "Sobey Indah"
  },
  {
    "id": "9207042002",
    "district_id": "920704",
    "label": "Wondiboy",
    "value": "Wondiboy"
  },
  {
    "id": "9207042009",
    "district_id": "920704",
    "label": "Kabouw",
    "value": "Kabouw"
  },
  {
    "id": "9207042010",
    "district_id": "920704",
    "label": "Issui",
    "value": "Issui"
  },
  {
    "id": "9207042015",
    "district_id": "920704",
    "label": "Kaibi",
    "value": "Kaibi"
  },
  {
    "id": "9207052001",
    "district_id": "920705",
    "label": "Sabubar",
    "value": "Sabubar"
  },
  {
    "id": "9207052002",
    "district_id": "920705",
    "label": "Ambuar",
    "value": "Ambuar"
  },
  {
    "id": "9207052003",
    "district_id": "920705",
    "label": "Karuan",
    "value": "Karuan"
  },
  {
    "id": "9207052007",
    "district_id": "920705",
    "label": "Yarpate",
    "value": "Yarpate"
  },
  {
    "id": "9207052009",
    "district_id": "920705",
    "label": "Nordiwar",
    "value": "Nordiwar"
  },
  {
    "id": "9207062001",
    "district_id": "920706",
    "label": "Yombekiri I",
    "value": "Yombekiri I"
  },
  {
    "id": "9207062002",
    "district_id": "920706",
    "label": "Isenebuay",
    "value": "Isenebuay"
  },
  {
    "id": "9207062003",
    "district_id": "920706",
    "label": "Yomakan",
    "value": "Yomakan"
  },
  {
    "id": "9207062005",
    "district_id": "920706",
    "label": "Iseren",
    "value": "Iseren"
  },
  {
    "id": "9207062008",
    "district_id": "920706",
    "label": "Yembekiri II",
    "value": "Yembekiri II"
  },
  {
    "id": "9207062009",
    "district_id": "920706",
    "label": "Yariari",
    "value": "Yariari"
  },
  {
    "id": "9207062011",
    "district_id": "920706",
    "label": "Wetitindau",
    "value": "Wetitindau"
  },
  {
    "id": "9207072001",
    "district_id": "920707",
    "label": "Wosimi",
    "value": "Wosimi"
  },
  {
    "id": "9207072002",
    "district_id": "920707",
    "label": "Sararti",
    "value": "Sararti"
  },
  {
    "id": "9207072003",
    "district_id": "920707",
    "label": "Yabore",
    "value": "Yabore"
  },
  {
    "id": "9207072004",
    "district_id": "920707",
    "label": "Oyaa",
    "value": "Oyaa"
  },
  {
    "id": "9207072005",
    "district_id": "920707",
    "label": "Inyora",
    "value": "Inyora"
  },
  {
    "id": "9207072006",
    "district_id": "920707",
    "label": "Undurara",
    "value": "Undurara"
  },
  {
    "id": "9207082001",
    "district_id": "920708",
    "label": "Rasiei",
    "value": "Rasiei"
  },
  {
    "id": "9207082002",
    "district_id": "920708",
    "label": "Torey",
    "value": "Torey"
  },
  {
    "id": "9207082003",
    "district_id": "920708",
    "label": "Isey",
    "value": "Isey"
  },
  {
    "id": "9207082004",
    "district_id": "920708",
    "label": "Uriemi",
    "value": "Uriemi"
  },
  {
    "id": "9207082005",
    "district_id": "920708",
    "label": "Sasirei",
    "value": "Sasirei"
  },
  {
    "id": "9207082006",
    "district_id": "920708",
    "label": "Tandia",
    "value": "Tandia"
  },
  {
    "id": "9207082007",
    "district_id": "920708",
    "label": "Webi",
    "value": "Webi"
  },
  {
    "id": "9207082008",
    "district_id": "920708",
    "label": "Nggatum",
    "value": "Nggatum"
  },
  {
    "id": "9207082009",
    "district_id": "920708",
    "label": "Senderawoy",
    "value": "Senderawoy"
  },
  {
    "id": "9207092001",
    "district_id": "920709",
    "label": "Ambumi",
    "value": "Ambumi"
  },
  {
    "id": "9207092002",
    "district_id": "920709",
    "label": "Nanimori",
    "value": "Nanimori"
  },
  {
    "id": "9207092003",
    "district_id": "920709",
    "label": "Dusner",
    "value": "Dusner"
  },
  {
    "id": "9207092004",
    "district_id": "920709",
    "label": "Yerenusi",
    "value": "Yerenusi"
  },
  {
    "id": "9207092005",
    "district_id": "920709",
    "label": "Sobiar",
    "value": "Sobiar"
  },
  {
    "id": "9207092006",
    "district_id": "920709",
    "label": "Simei",
    "value": "Simei"
  },
  {
    "id": "9207102001",
    "district_id": "920710",
    "label": "Yende",
    "value": "Yende"
  },
  {
    "id": "9207102002",
    "district_id": "920710",
    "label": "Syabes",
    "value": "Syabes"
  },
  {
    "id": "9207102003",
    "district_id": "920710",
    "label": "Menarbu",
    "value": "Menarbu"
  },
  {
    "id": "9207102004",
    "district_id": "920710",
    "label": "Niab",
    "value": "Niab"
  },
  {
    "id": "9207102005",
    "district_id": "920710",
    "label": "Inday",
    "value": "Inday"
  },
  {
    "id": "9207102006",
    "district_id": "920710",
    "label": "Sariay",
    "value": "Sariay"
  },
  {
    "id": "9207102007",
    "district_id": "920710",
    "label": "Mena",
    "value": "Mena"
  },
  {
    "id": "9207112001",
    "district_id": "920711",
    "label": "Waprak",
    "value": "Waprak"
  },
  {
    "id": "9207112002",
    "district_id": "920711",
    "label": "Yomber",
    "value": "Yomber"
  },
  {
    "id": "9207112003",
    "district_id": "920711",
    "label": "Syeiwar",
    "value": "Syeiwar"
  },
  {
    "id": "9207122001",
    "district_id": "920712",
    "label": "Werabur",
    "value": "Werabur"
  },
  {
    "id": "9207122002",
    "district_id": "920712",
    "label": "Mamisi",
    "value": "Mamisi"
  },
  {
    "id": "9207122003",
    "district_id": "920712",
    "label": "Werianggi",
    "value": "Werianggi"
  },
  {
    "id": "9207122004",
    "district_id": "920712",
    "label": "Kurei",
    "value": "Kurei"
  },
  {
    "id": "9207122005",
    "district_id": "920712",
    "label": "Tamoge",
    "value": "Tamoge"
  },
  {
    "id": "9207132001",
    "district_id": "920713",
    "label": "Kaprus",
    "value": "Kaprus"
  },
  {
    "id": "9207132002",
    "district_id": "920713",
    "label": "Yarmatum",
    "value": "Yarmatum"
  },
  {
    "id": "9207132003",
    "district_id": "920713",
    "label": "Reyob",
    "value": "Reyob"
  },
  {
    "id": "9207132004",
    "district_id": "920713",
    "label": "Siresi",
    "value": "Siresi"
  },
  {
    "id": "9207132005",
    "district_id": "920713",
    "label": "Nuspairo",
    "value": "Nuspairo"
  },
  {
    "id": "9208011001",
    "district_id": "920801",
    "label": "Kaimana Kota",
    "value": "Kaimana Kota"
  },
  {
    "id": "9208011014",
    "district_id": "920801",
    "label": "Krooy",
    "value": "Krooy"
  },
  {
    "id": "9208012002",
    "district_id": "920801",
    "label": "Marsi",
    "value": "Marsi"
  },
  {
    "id": "9208012003",
    "district_id": "920801",
    "label": "Namatota",
    "value": "Namatota"
  },
  {
    "id": "9208012004",
    "district_id": "920801",
    "label": "Lobo",
    "value": "Lobo"
  },
  {
    "id": "9208012005",
    "district_id": "920801",
    "label": "Trikora",
    "value": "Trikora"
  },
  {
    "id": "9208012006",
    "district_id": "920801",
    "label": "Tanggaromi",
    "value": "Tanggaromi"
  },
  {
    "id": "9208012007",
    "district_id": "920801",
    "label": "Sisir",
    "value": "Sisir"
  },
  {
    "id": "9208012008",
    "district_id": "920801",
    "label": "Foroma Jaya",
    "value": "Foroma Jaya"
  },
  {
    "id": "9208012009",
    "district_id": "920801",
    "label": "Murano",
    "value": "Murano"
  },
  {
    "id": "9208012010",
    "district_id": "920801",
    "label": "Mai Mai",
    "value": "Mai Mai"
  },
  {
    "id": "9208012011",
    "district_id": "920801",
    "label": "Lumira",
    "value": "Lumira"
  },
  {
    "id": "9208012012",
    "district_id": "920801",
    "label": "Kamaka",
    "value": "Kamaka"
  },
  {
    "id": "9208012013",
    "district_id": "920801",
    "label": "Coa",
    "value": "Coa"
  },
  {
    "id": "9208012015",
    "district_id": "920801",
    "label": "Saria",
    "value": "Saria"
  },
  {
    "id": "9208012016",
    "district_id": "920801",
    "label": "Jarati",
    "value": "Jarati"
  },
  {
    "id": "9208012017",
    "district_id": "920801",
    "label": "Sara",
    "value": "Sara"
  },
  {
    "id": "9208012018",
    "district_id": "920801",
    "label": "Werua",
    "value": "Werua"
  },
  {
    "id": "9208012019",
    "district_id": "920801",
    "label": "Oray",
    "value": "Oray"
  },
  {
    "id": "9208022001",
    "district_id": "920802",
    "label": "Kambala",
    "value": "Kambala"
  },
  {
    "id": "9208022002",
    "district_id": "920802",
    "label": "Yarona",
    "value": "Yarona"
  },
  {
    "id": "9208022003",
    "district_id": "920802",
    "label": "Edor",
    "value": "Edor"
  },
  {
    "id": "9208022004",
    "district_id": "920802",
    "label": "Tairi",
    "value": "Tairi"
  },
  {
    "id": "9208022005",
    "district_id": "920802",
    "label": "Gaka",
    "value": "Gaka"
  },
  {
    "id": "9208022006",
    "district_id": "920802",
    "label": "Guriasa",
    "value": "Guriasa"
  },
  {
    "id": "9208022007",
    "district_id": "920802",
    "label": "Adi Jaya",
    "value": "Adi Jaya"
  },
  {
    "id": "9208022008",
    "district_id": "920802",
    "label": "Nusaulan",
    "value": "Nusaulan"
  },
  {
    "id": "9208022009",
    "district_id": "920802",
    "label": "Esania",
    "value": "Esania"
  },
  {
    "id": "9208022010",
    "district_id": "920802",
    "label": "Hia",
    "value": "Hia"
  },
  {
    "id": "9208032001",
    "district_id": "920803",
    "label": "Furnusu",
    "value": "Furnusu"
  },
  {
    "id": "9208032002",
    "district_id": "920803",
    "label": "Fetermu",
    "value": "Fetermu"
  },
  {
    "id": "9208032003",
    "district_id": "920803",
    "label": "Sawi",
    "value": "Sawi"
  },
  {
    "id": "9208032004",
    "district_id": "920803",
    "label": "Turgani",
    "value": "Turgani"
  },
  {
    "id": "9208032010",
    "district_id": "920803",
    "label": "Sawatewera",
    "value": "Sawatewera"
  },
  {
    "id": "9208032012",
    "district_id": "920803",
    "label": "Bofuwer",
    "value": "Bofuwer"
  },
  {
    "id": "9208032013",
    "district_id": "920803",
    "label": "Warwasi",
    "value": "Warwasi"
  },
  {
    "id": "9208032014",
    "district_id": "920803",
    "label": "Wanggita",
    "value": "Wanggita"
  },
  {
    "id": "9208032016",
    "district_id": "920803",
    "label": "Fudima",
    "value": "Fudima"
  },
  {
    "id": "9208032017",
    "district_id": "920803",
    "label": "Weswasa",
    "value": "Weswasa"
  },
  {
    "id": "9208032018",
    "district_id": "920803",
    "label": "Borogerba",
    "value": "Borogerba"
  },
  {
    "id": "9208032019",
    "district_id": "920803",
    "label": "Afu Afu",
    "value": "Afu Afu"
  },
  {
    "id": "9208032020",
    "district_id": "920803",
    "label": "Bayeda",
    "value": "Bayeda"
  },
  {
    "id": "9208032021",
    "district_id": "920803",
    "label": "Moyana",
    "value": "Moyana"
  },
  {
    "id": "9208032022",
    "district_id": "920803",
    "label": "Kokoroba",
    "value": "Kokoroba"
  },
  {
    "id": "9208032023",
    "district_id": "920803",
    "label": "Wainaga",
    "value": "Wainaga"
  },
  {
    "id": "9208032024",
    "district_id": "920803",
    "label": "Maskur",
    "value": "Maskur"
  },
  {
    "id": "9208032025",
    "district_id": "920803",
    "label": "Kensi",
    "value": "Kensi"
  },
  {
    "id": "9208032027",
    "district_id": "920803",
    "label": "Erigara",
    "value": "Erigara"
  },
  {
    "id": "9208032028",
    "district_id": "920803",
    "label": "Pigo",
    "value": "Pigo"
  },
  {
    "id": "9208032029",
    "district_id": "920803",
    "label": "Tugumawa",
    "value": "Tugumawa"
  },
  {
    "id": "9208032030",
    "district_id": "920803",
    "label": "Tiwara",
    "value": "Tiwara"
  },
  {
    "id": "9208032031",
    "district_id": "920803",
    "label": "Gusi",
    "value": "Gusi"
  },
  {
    "id": "9208032032",
    "district_id": "920803",
    "label": "Warua",
    "value": "Warua"
  },
  {
    "id": "9208042001",
    "district_id": "920804",
    "label": "Rurumo",
    "value": "Rurumo"
  },
  {
    "id": "9208042002",
    "district_id": "920804",
    "label": "Boiya",
    "value": "Boiya"
  },
  {
    "id": "9208042003",
    "district_id": "920804",
    "label": "Nariki",
    "value": "Nariki"
  },
  {
    "id": "9208042004",
    "district_id": "920804",
    "label": "Bamana",
    "value": "Bamana"
  },
  {
    "id": "9208042005",
    "district_id": "920804",
    "label": "Siawatan",
    "value": "Siawatan"
  },
  {
    "id": "9208052001",
    "district_id": "920805",
    "label": "Rauna",
    "value": "Rauna"
  },
  {
    "id": "9208052002",
    "district_id": "920805",
    "label": "Werafuta",
    "value": "Werafuta"
  },
  {
    "id": "9208052003",
    "district_id": "920805",
    "label": "Ubia Sermuku",
    "value": "Ubia Sermuku"
  },
  {
    "id": "9208052004",
    "district_id": "920805",
    "label": "Bahumia",
    "value": "Bahumia"
  },
  {
    "id": "9208052005",
    "district_id": "920805",
    "label": "Waho",
    "value": "Waho"
  },
  {
    "id": "9208052006",
    "district_id": "920805",
    "label": "Wamesa",
    "value": "Wamesa"
  },
  {
    "id": "9208052007",
    "district_id": "920805",
    "label": "Kooy",
    "value": "Kooy"
  },
  {
    "id": "9208062001",
    "district_id": "920806",
    "label": "Jawera",
    "value": "Jawera"
  },
  {
    "id": "9208062002",
    "district_id": "920806",
    "label": "Ruwara",
    "value": "Ruwara"
  },
  {
    "id": "9208062003",
    "district_id": "920806",
    "label": "Wermenu",
    "value": "Wermenu"
  },
  {
    "id": "9208062004",
    "district_id": "920806",
    "label": "Egerwara",
    "value": "Egerwara"
  },
  {
    "id": "9208062005",
    "district_id": "920806",
    "label": "Kufuriyai",
    "value": "Kufuriyai"
  },
  {
    "id": "9208062006",
    "district_id": "920806",
    "label": "Manggera",
    "value": "Manggera"
  },
  {
    "id": "9208062007",
    "district_id": "920806",
    "label": "Tanusan",
    "value": "Tanusan"
  },
  {
    "id": "9208062008",
    "district_id": "920806",
    "label": "Urisa",
    "value": "Urisa"
  },
  {
    "id": "9208062009",
    "district_id": "920806",
    "label": "Waromi",
    "value": "Waromi"
  },
  {
    "id": "9208062010",
    "district_id": "920806",
    "label": "Ukiara",
    "value": "Ukiara"
  },
  {
    "id": "9208062012",
    "district_id": "920806",
    "label": "Nagura",
    "value": "Nagura"
  },
  {
    "id": "9208062013",
    "district_id": "920806",
    "label": "Inari",
    "value": "Inari"
  },
  {
    "id": "9208062014",
    "district_id": "920806",
    "label": "Wanoma",
    "value": "Wanoma"
  },
  {
    "id": "9208062015",
    "district_id": "920806",
    "label": "Sumun",
    "value": "Sumun"
  },
  {
    "id": "9208062016",
    "district_id": "920806",
    "label": "Seraran",
    "value": "Seraran"
  },
  {
    "id": "9208072001",
    "district_id": "920807",
    "label": "Ure",
    "value": "Ure"
  },
  {
    "id": "9208072002",
    "district_id": "920807",
    "label": "Wosokuno",
    "value": "Wosokuno"
  },
  {
    "id": "9208072003",
    "district_id": "920807",
    "label": "Urubika",
    "value": "Urubika"
  },
  {
    "id": "9208072004",
    "district_id": "920807",
    "label": "Wagoha",
    "value": "Wagoha"
  },
  {
    "id": "9208072005",
    "district_id": "920807",
    "label": "Hairapara",
    "value": "Hairapara"
  },
  {
    "id": "9208072006",
    "district_id": "920807",
    "label": "Omba Pamuku",
    "value": "Omba Pamuku"
  },
  {
    "id": "9209012001",
    "district_id": "920901",
    "label": "Fef",
    "value": "Fef"
  },
  {
    "id": "9209012003",
    "district_id": "920901",
    "label": "Wayo",
    "value": "Wayo"
  },
  {
    "id": "9209012004",
    "district_id": "920901",
    "label": "Syubun",
    "value": "Syubun"
  },
  {
    "id": "9209012006",
    "district_id": "920901",
    "label": "Sikor",
    "value": "Sikor"
  },
  {
    "id": "9209012010",
    "district_id": "920901",
    "label": "Ibe",
    "value": "Ibe"
  },
  {
    "id": "9209012011",
    "district_id": "920901",
    "label": "Syunam",
    "value": "Syunam"
  },
  {
    "id": "9209012012",
    "district_id": "920901",
    "label": "Banfot",
    "value": "Banfot"
  },
  {
    "id": "9209012013",
    "district_id": "920901",
    "label": "Iwin",
    "value": "Iwin"
  },
  {
    "id": "9209012014",
    "district_id": "920901",
    "label": "Esyum",
    "value": "Esyum"
  },
  {
    "id": "9209012015",
    "district_id": "920901",
    "label": "Imor",
    "value": "Imor"
  },
  {
    "id": "9209022001",
    "district_id": "920902",
    "label": "Siakwa",
    "value": "Siakwa"
  },
  {
    "id": "9209022006",
    "district_id": "920902",
    "label": "Aibogiar",
    "value": "Aibogiar"
  },
  {
    "id": "9209022008",
    "district_id": "920902",
    "label": "Ayae",
    "value": "Ayae"
  },
  {
    "id": "9209022013",
    "district_id": "920902",
    "label": "Ayawit",
    "value": "Ayawit"
  },
  {
    "id": "9209022014",
    "district_id": "920902",
    "label": "Aifair",
    "value": "Aifair"
  },
  {
    "id": "9209032001",
    "district_id": "920903",
    "label": "Baun",
    "value": "Baun"
  },
  {
    "id": "9209032002",
    "district_id": "920903",
    "label": "Sumbekas",
    "value": "Sumbekas"
  },
  {
    "id": "9209032003",
    "district_id": "920903",
    "label": "Metnayam",
    "value": "Metnayam"
  },
  {
    "id": "9209032007",
    "district_id": "920903",
    "label": "Metbelum",
    "value": "Metbelum"
  },
  {
    "id": "9209032012",
    "district_id": "920903",
    "label": "Salem",
    "value": "Salem"
  },
  {
    "id": "9209032013",
    "district_id": "920903",
    "label": "Metbesa",
    "value": "Metbesa"
  },
  {
    "id": "9209042001",
    "district_id": "920904",
    "label": "Kwoor",
    "value": "Kwoor"
  },
  {
    "id": "9209042002",
    "district_id": "920904",
    "label": "Hopmare",
    "value": "Hopmare"
  },
  {
    "id": "9209042011",
    "district_id": "920904",
    "label": "Orwen",
    "value": "Orwen"
  },
  {
    "id": "9209042012",
    "district_id": "920904",
    "label": "Barar",
    "value": "Barar"
  },
  {
    "id": "9209042013",
    "district_id": "920904",
    "label": "Esmambo",
    "value": "Esmambo"
  },
  {
    "id": "9209052001",
    "district_id": "920905",
    "label": "Sausapor",
    "value": "Sausapor"
  },
  {
    "id": "9209052007",
    "district_id": "920905",
    "label": "Emaos",
    "value": "Emaos"
  },
  {
    "id": "9209052008",
    "district_id": "920905",
    "label": "Jokte",
    "value": "Jokte"
  },
  {
    "id": "9209052009",
    "district_id": "920905",
    "label": "Uigwem",
    "value": "Uigwem"
  },
  {
    "id": "9209052010",
    "district_id": "920905",
    "label": "Sungguwan",
    "value": "Sungguwan"
  },
  {
    "id": "9209052011",
    "district_id": "920905",
    "label": "Sau Uram",
    "value": "Sau Uram"
  },
  {
    "id": "9209052012",
    "district_id": "920905",
    "label": "Nanggou",
    "value": "Nanggou"
  },
  {
    "id": "9209052013",
    "district_id": "920905",
    "label": "Bondek",
    "value": "Bondek"
  },
  {
    "id": "9209052018",
    "district_id": "920905",
    "label": "Bondonggwan",
    "value": "Bondonggwan"
  },
  {
    "id": "9209052019",
    "district_id": "920905",
    "label": "Syurauw",
    "value": "Syurauw"
  },
  {
    "id": "9209062001",
    "district_id": "920906",
    "label": "Wau",
    "value": "Wau"
  },
  {
    "id": "9209062002",
    "district_id": "920906",
    "label": "Syukwo",
    "value": "Syukwo"
  },
  {
    "id": "9209062003",
    "district_id": "920906",
    "label": "Waibem",
    "value": "Waibem"
  },
  {
    "id": "9209062005",
    "district_id": "920906",
    "label": "Weyaf",
    "value": "Weyaf"
  },
  {
    "id": "9209062006",
    "district_id": "920906",
    "label": "Weprari",
    "value": "Weprari"
  },
  {
    "id": "9209072001",
    "district_id": "920907",
    "label": "Syujak",
    "value": "Syujak"
  },
  {
    "id": "9209072002",
    "district_id": "920907",
    "label": "Banso",
    "value": "Banso"
  },
  {
    "id": "9209072004",
    "district_id": "920907",
    "label": "Iof",
    "value": "Iof"
  },
  {
    "id": "9209072005",
    "district_id": "920907",
    "label": "Frafane",
    "value": "Frafane"
  },
  {
    "id": "9209082001",
    "district_id": "920908",
    "label": "Mega",
    "value": "Mega"
  },
  {
    "id": "9209082003",
    "district_id": "920908",
    "label": "Selewok",
    "value": "Selewok"
  },
  {
    "id": "9209082004",
    "district_id": "920908",
    "label": "Malaworsai",
    "value": "Malaworsai"
  },
  {
    "id": "9209082007",
    "district_id": "920908",
    "label": "Kwade",
    "value": "Kwade"
  },
  {
    "id": "9209082008",
    "district_id": "920908",
    "label": "Bonem",
    "value": "Bonem"
  },
  {
    "id": "9209082009",
    "district_id": "920908",
    "label": "Warafor",
    "value": "Warafor"
  },
  {
    "id": "9209082010",
    "district_id": "920908",
    "label": "Warmasi",
    "value": "Warmasi"
  },
  {
    "id": "9209082011",
    "district_id": "920908",
    "label": "Warmanen",
    "value": "Warmanen"
  },
  {
    "id": "9209082012",
    "district_id": "920908",
    "label": "Namuas",
    "value": "Namuas"
  },
  {
    "id": "9209092007",
    "district_id": "920909",
    "label": "Injai",
    "value": "Injai"
  },
  {
    "id": "9209092015",
    "district_id": "920909",
    "label": "Apoki",
    "value": "Apoki"
  },
  {
    "id": "9209092016",
    "district_id": "920909",
    "label": "Matatun",
    "value": "Matatun"
  },
  {
    "id": "9209092017",
    "district_id": "920909",
    "label": "Jafai",
    "value": "Jafai"
  },
  {
    "id": "9209092018",
    "district_id": "920909",
    "label": "Jambuani",
    "value": "Jambuani"
  },
  {
    "id": "9209092019",
    "district_id": "920909",
    "label": "Manaria",
    "value": "Manaria"
  },
  {
    "id": "9209092022",
    "district_id": "920909",
    "label": "Anarum",
    "value": "Anarum"
  },
  {
    "id": "9209092027",
    "district_id": "920909",
    "label": "Wasanggon",
    "value": "Wasanggon"
  },
  {
    "id": "9209092028",
    "district_id": "920909",
    "label": "Wabanek",
    "value": "Wabanek"
  },
  {
    "id": "9209102002",
    "district_id": "920910",
    "label": "Saukorem",
    "value": "Saukorem"
  },
  {
    "id": "9209102004",
    "district_id": "920910",
    "label": "Wefiani",
    "value": "Wefiani"
  },
  {
    "id": "9209102006",
    "district_id": "920910",
    "label": "Wasarak",
    "value": "Wasarak"
  },
  {
    "id": "9209102012",
    "district_id": "920910",
    "label": "Binasi",
    "value": "Binasi"
  },
  {
    "id": "9209102013",
    "district_id": "920910",
    "label": "Kabibwan",
    "value": "Kabibwan"
  },
  {
    "id": "9209102014",
    "district_id": "920910",
    "label": "Binmaks",
    "value": "Binmaks"
  },
  {
    "id": "9209102016",
    "district_id": "920910",
    "label": "Manggapnut",
    "value": "Manggapnut"
  },
  {
    "id": "9209102017",
    "district_id": "920910",
    "label": "Waramui",
    "value": "Waramui"
  },
  {
    "id": "9209102021",
    "district_id": "920910",
    "label": "Warpaperi",
    "value": "Warpaperi"
  },
  {
    "id": "9209112003",
    "district_id": "920911",
    "label": "Senopi",
    "value": "Senopi"
  },
  {
    "id": "9209112004",
    "district_id": "920911",
    "label": "Arapi",
    "value": "Arapi"
  },
  {
    "id": "9209112005",
    "district_id": "920911",
    "label": "Srurem",
    "value": "Srurem"
  },
  {
    "id": "9209112006",
    "district_id": "920911",
    "label": "Sumo",
    "value": "Sumo"
  },
  {
    "id": "9209112007",
    "district_id": "920911",
    "label": "Ifat Mitu",
    "value": "Ifat Mitu"
  },
  {
    "id": "9209112008",
    "district_id": "920911",
    "label": "Povokek Ania",
    "value": "Povokek Ania"
  },
  {
    "id": "9209112012",
    "district_id": "920911",
    "label": "Thomas",
    "value": "Thomas"
  },
  {
    "id": "9209112015",
    "district_id": "920911",
    "label": "Tintum Araw",
    "value": "Tintum Araw"
  },
  {
    "id": "9209112016",
    "district_id": "920911",
    "label": "Hobiah",
    "value": "Hobiah"
  },
  {
    "id": "9209122001",
    "district_id": "920912",
    "label": "Arfu",
    "value": "Arfu"
  },
  {
    "id": "9209122002",
    "district_id": "920912",
    "label": "Bawey",
    "value": "Bawey"
  },
  {
    "id": "9209122003",
    "district_id": "920912",
    "label": "Wasnembri",
    "value": "Wasnembri"
  },
  {
    "id": "9209122004",
    "district_id": "920912",
    "label": "Waru",
    "value": "Waru"
  },
  {
    "id": "9209122005",
    "district_id": "920912",
    "label": "Atori",
    "value": "Atori"
  },
  {
    "id": "9209122006",
    "district_id": "920912",
    "label": "Meriambeker",
    "value": "Meriambeker"
  },
  {
    "id": "9209122015",
    "district_id": "920912",
    "label": "Warokon",
    "value": "Warokon"
  },
  {
    "id": "9209122016",
    "district_id": "920912",
    "label": "Arekon",
    "value": "Arekon"
  },
  {
    "id": "9209122017",
    "district_id": "920912",
    "label": "Bonpaya",
    "value": "Bonpaya"
  },
  {
    "id": "9209122018",
    "district_id": "920912",
    "label": "Marbuan",
    "value": "Marbuan"
  },
  {
    "id": "9209132001",
    "district_id": "920913",
    "label": "Werur",
    "value": "Werur"
  },
  {
    "id": "9209132002",
    "district_id": "920913",
    "label": "Bukit",
    "value": "Bukit"
  },
  {
    "id": "9209132003",
    "district_id": "920913",
    "label": "Wertam",
    "value": "Wertam"
  },
  {
    "id": "9209132004",
    "district_id": "920913",
    "label": "Werwaf",
    "value": "Werwaf"
  },
  {
    "id": "9209132005",
    "district_id": "920913",
    "label": "Werbes",
    "value": "Werbes"
  },
  {
    "id": "9209132006",
    "district_id": "920913",
    "label": "Nombrak",
    "value": "Nombrak"
  },
  {
    "id": "9209132007",
    "district_id": "920913",
    "label": "Bikar",
    "value": "Bikar"
  },
  {
    "id": "9209132008",
    "district_id": "920913",
    "label": "Syunai",
    "value": "Syunai"
  },
  {
    "id": "9209132009",
    "district_id": "920913",
    "label": "Suyam",
    "value": "Suyam"
  },
  {
    "id": "9209132010",
    "district_id": "920913",
    "label": "Wertim",
    "value": "Wertim"
  },
  {
    "id": "9209142001",
    "district_id": "920914",
    "label": "Bamusbama",
    "value": "Bamusbama"
  },
  {
    "id": "9209142002",
    "district_id": "920914",
    "label": "Bamuswaiman",
    "value": "Bamuswaiman"
  },
  {
    "id": "9209142003",
    "district_id": "920914",
    "label": "Syarwom",
    "value": "Syarwom"
  },
  {
    "id": "9209142005",
    "district_id": "920914",
    "label": "Babak",
    "value": "Babak"
  },
  {
    "id": "9209142006",
    "district_id": "920914",
    "label": "Bano",
    "value": "Bano"
  },
  {
    "id": "9209142007",
    "district_id": "920914",
    "label": "Wormon",
    "value": "Wormon"
  },
  {
    "id": "9209152001",
    "district_id": "920915",
    "label": "Ases",
    "value": "Ases"
  },
  {
    "id": "9209152002",
    "district_id": "920915",
    "label": "Asuon",
    "value": "Asuon"
  },
  {
    "id": "9209152003",
    "district_id": "920915",
    "label": "Safotiek",
    "value": "Safotiek"
  },
  {
    "id": "9209152004",
    "district_id": "920915",
    "label": "Frasayoah",
    "value": "Frasayoah"
  },
  {
    "id": "9209162001",
    "district_id": "920916",
    "label": "Mawor",
    "value": "Mawor"
  },
  {
    "id": "9209162002",
    "district_id": "920916",
    "label": "Ruf",
    "value": "Ruf"
  },
  {
    "id": "9209162003",
    "district_id": "920916",
    "label": "Ayamane",
    "value": "Ayamane"
  },
  {
    "id": "9209162004",
    "district_id": "920916",
    "label": "Whismer",
    "value": "Whismer"
  },
  {
    "id": "9209162005",
    "district_id": "920916",
    "label": "Sahae",
    "value": "Sahae"
  },
  {
    "id": "9209162006",
    "district_id": "920916",
    "label": "Hewi",
    "value": "Hewi"
  },
  {
    "id": "9209162007",
    "district_id": "920916",
    "label": "Sisu",
    "value": "Sisu"
  },
  {
    "id": "9209162008",
    "district_id": "920916",
    "label": "Ruvewes",
    "value": "Ruvewes"
  },
  {
    "id": "9209162009",
    "district_id": "920916",
    "label": "Yabuow",
    "value": "Yabuow"
  },
  {
    "id": "9209172001",
    "district_id": "920917",
    "label": "Miri",
    "value": "Miri"
  },
  {
    "id": "9209172002",
    "district_id": "920917",
    "label": "Meis",
    "value": "Meis"
  },
  {
    "id": "9209172003",
    "district_id": "920917",
    "label": "Aifamas",
    "value": "Aifamas"
  },
  {
    "id": "9209172004",
    "district_id": "920917",
    "label": "Meinad",
    "value": "Meinad"
  },
  {
    "id": "9209172005",
    "district_id": "920917",
    "label": "Atafrumek",
    "value": "Atafrumek"
  },
  {
    "id": "9209172006",
    "district_id": "920917",
    "label": "Ifiam",
    "value": "Ifiam"
  },
  {
    "id": "9209172007",
    "district_id": "920917",
    "label": "Wufmana",
    "value": "Wufmana"
  },
  {
    "id": "9209182001",
    "district_id": "920918",
    "label": "Syumbab",
    "value": "Syumbab"
  },
  {
    "id": "9209182002",
    "district_id": "920918",
    "label": "Krisnos",
    "value": "Krisnos"
  },
  {
    "id": "9209182003",
    "district_id": "920918",
    "label": "Syukwes",
    "value": "Syukwes"
  },
  {
    "id": "9209182006",
    "district_id": "920918",
    "label": "Sunggak",
    "value": "Sunggak"
  },
  {
    "id": "9209182007",
    "district_id": "920918",
    "label": "Wowom",
    "value": "Wowom"
  },
  {
    "id": "9209182008",
    "district_id": "920918",
    "label": "Resye",
    "value": "Resye"
  },
  {
    "id": "9209192001",
    "district_id": "920919",
    "label": "Tabamsere",
    "value": "Tabamsere"
  },
  {
    "id": "9209192002",
    "district_id": "920919",
    "label": "Sayam",
    "value": "Sayam"
  },
  {
    "id": "9209192003",
    "district_id": "920919",
    "label": "Araf Mafat",
    "value": "Araf Mafat"
  },
  {
    "id": "9209192004",
    "district_id": "920919",
    "label": "Esyuom",
    "value": "Esyuom"
  },
  {
    "id": "9209202001",
    "district_id": "920920",
    "label": "Tinggouw",
    "value": "Tinggouw"
  },
  {
    "id": "9209202002",
    "district_id": "920920",
    "label": "Hohair",
    "value": "Hohair"
  },
  {
    "id": "9209202003",
    "district_id": "920920",
    "label": "Ruriai",
    "value": "Ruriai"
  },
  {
    "id": "9209202004",
    "district_id": "920920",
    "label": "Soon",
    "value": "Soon"
  },
  {
    "id": "9209202005",
    "district_id": "920920",
    "label": "Ifatkan",
    "value": "Ifatkan"
  },
  {
    "id": "9209212001",
    "district_id": "920921",
    "label": "Jokbujoker",
    "value": "Jokbujoker"
  },
  {
    "id": "9209212002",
    "district_id": "920921",
    "label": "Kranfotsu",
    "value": "Kranfotsu"
  },
  {
    "id": "9209212003",
    "district_id": "920921",
    "label": "Syuau",
    "value": "Syuau"
  },
  {
    "id": "9209212004",
    "district_id": "920921",
    "label": "Kwesefo",
    "value": "Kwesefo"
  },
  {
    "id": "9209212005",
    "district_id": "920921",
    "label": "Batdey",
    "value": "Batdey"
  },
  {
    "id": "9209212006",
    "district_id": "920921",
    "label": "Syumbi",
    "value": "Syumbi"
  },
  {
    "id": "9209212007",
    "district_id": "920921",
    "label": "Bao",
    "value": "Bao"
  },
  {
    "id": "9209212008",
    "district_id": "920921",
    "label": "Donan",
    "value": "Donan"
  },
  {
    "id": "9209212009",
    "district_id": "920921",
    "label": "Dombron",
    "value": "Dombron"
  },
  {
    "id": "9209222001",
    "district_id": "920922",
    "label": "Wausin",
    "value": "Wausin"
  },
  {
    "id": "9209222002",
    "district_id": "920922",
    "label": "Asiti",
    "value": "Asiti"
  },
  {
    "id": "9209222003",
    "district_id": "920922",
    "label": "Bisaud",
    "value": "Bisaud"
  },
  {
    "id": "9209222005",
    "district_id": "920922",
    "label": "Nisandauw",
    "value": "Nisandauw"
  },
  {
    "id": "9209222006",
    "district_id": "920922",
    "label": "Afrawi",
    "value": "Afrawi"
  },
  {
    "id": "9209222007",
    "district_id": "920922",
    "label": "Ajokwapi",
    "value": "Ajokwapi"
  },
  {
    "id": "9209232001",
    "district_id": "920923",
    "label": "Inam",
    "value": "Inam"
  },
  {
    "id": "9209232002",
    "district_id": "920923",
    "label": "Jandurauw",
    "value": "Jandurauw"
  },
  {
    "id": "9209232003",
    "district_id": "920923",
    "label": "Inambuari",
    "value": "Inambuari"
  },
  {
    "id": "9209232004",
    "district_id": "920923",
    "label": "Sitori",
    "value": "Sitori"
  },
  {
    "id": "9209232005",
    "district_id": "920923",
    "label": "Untorey",
    "value": "Untorey"
  },
  {
    "id": "9209232006",
    "district_id": "920923",
    "label": "Nabisai",
    "value": "Nabisai"
  },
  {
    "id": "9209232007",
    "district_id": "920923",
    "label": "Arumi",
    "value": "Arumi"
  },
  {
    "id": "9209232008",
    "district_id": "920923",
    "label": "Karawi",
    "value": "Karawi"
  },
  {
    "id": "9209232009",
    "district_id": "920923",
    "label": "Arampak",
    "value": "Arampak"
  },
  {
    "id": "9209232013",
    "district_id": "920923",
    "label": "Barona",
    "value": "Barona"
  },
  {
    "id": "9209242001",
    "district_id": "920924",
    "label": "Nekori",
    "value": "Nekori"
  },
  {
    "id": "9209242002",
    "district_id": "920924",
    "label": "Ajami",
    "value": "Ajami"
  },
  {
    "id": "9209242003",
    "district_id": "920924",
    "label": "Aniti",
    "value": "Aniti"
  },
  {
    "id": "9209242004",
    "district_id": "920924",
    "label": "Akari",
    "value": "Akari"
  },
  {
    "id": "9209242005",
    "district_id": "920924",
    "label": "Amnan",
    "value": "Amnan"
  },
  {
    "id": "9209242006",
    "district_id": "920924",
    "label": "Atiki",
    "value": "Atiki"
  },
  {
    "id": "9209242007",
    "district_id": "920924",
    "label": "Arwani",
    "value": "Arwani"
  },
  {
    "id": "9209242008",
    "district_id": "920924",
    "label": "Aritowi",
    "value": "Aritowi"
  },
  {
    "id": "9209242009",
    "district_id": "920924",
    "label": "Umpay",
    "value": "Umpay"
  },
  {
    "id": "9209242010",
    "district_id": "920924",
    "label": "Meiwar",
    "value": "Meiwar"
  },
  {
    "id": "9209252001",
    "district_id": "920925",
    "label": "Atai",
    "value": "Atai"
  },
  {
    "id": "9209252002",
    "district_id": "920925",
    "label": "Akmuri",
    "value": "Akmuri"
  },
  {
    "id": "9209252003",
    "district_id": "920925",
    "label": "Awori",
    "value": "Awori"
  },
  {
    "id": "9209252004",
    "district_id": "920925",
    "label": "Itafiti",
    "value": "Itafiti"
  },
  {
    "id": "9209252005",
    "district_id": "920925",
    "label": "Atunari",
    "value": "Atunari"
  },
  {
    "id": "9209252006",
    "district_id": "920925",
    "label": "Ajami Waripi",
    "value": "Ajami Waripi"
  },
  {
    "id": "9209252007",
    "district_id": "920925",
    "label": "Narai",
    "value": "Narai"
  },
  {
    "id": "9209252008",
    "district_id": "920925",
    "label": "Aranari",
    "value": "Aranari"
  },
  {
    "id": "9209252009",
    "district_id": "920925",
    "label": "Waumi",
    "value": "Waumi"
  },
  {
    "id": "9209252010",
    "district_id": "920925",
    "label": "Aneti",
    "value": "Aneti"
  },
  {
    "id": "9209262001",
    "district_id": "920926",
    "label": "Arupi",
    "value": "Arupi"
  },
  {
    "id": "9209262003",
    "district_id": "920926",
    "label": "Mangganek",
    "value": "Mangganek"
  },
  {
    "id": "9209262004",
    "district_id": "920926",
    "label": "Wajarek",
    "value": "Wajarek"
  },
  {
    "id": "9209262005",
    "district_id": "920926",
    "label": "Kali An",
    "value": "Kali An"
  },
  {
    "id": "9209262006",
    "district_id": "920926",
    "label": "Araro",
    "value": "Araro"
  },
  {
    "id": "9209262007",
    "district_id": "920926",
    "label": "Wekari",
    "value": "Wekari"
  },
  {
    "id": "9209262008",
    "district_id": "920926",
    "label": "Serayo",
    "value": "Serayo"
  },
  {
    "id": "9209272001",
    "district_id": "920927",
    "label": "Sasui",
    "value": "Sasui"
  },
  {
    "id": "9209272002",
    "district_id": "920927",
    "label": "Imbuan",
    "value": "Imbuan"
  },
  {
    "id": "9209272003",
    "district_id": "920927",
    "label": "Manggaew",
    "value": "Manggaew"
  },
  {
    "id": "9209272004",
    "district_id": "920927",
    "label": "Saurabon",
    "value": "Saurabon"
  },
  {
    "id": "9209272005",
    "district_id": "920927",
    "label": "Wasawmontem",
    "value": "Wasawmontem"
  },
  {
    "id": "9209282001",
    "district_id": "920928",
    "label": "Meserikweda",
    "value": "Meserikweda"
  },
  {
    "id": "9209282002",
    "district_id": "920928",
    "label": "Kasi Baru",
    "value": "Kasi Baru"
  },
  {
    "id": "9209282003",
    "district_id": "920928",
    "label": "Kasi Jaya",
    "value": "Kasi Jaya"
  },
  {
    "id": "9209282004",
    "district_id": "920928",
    "label": "Meimorufof",
    "value": "Meimorufof"
  },
  {
    "id": "9209282005",
    "district_id": "920928",
    "label": "Kasi Indah",
    "value": "Kasi Indah"
  },
  {
    "id": "9209282006",
    "district_id": "920928",
    "label": "Wacam",
    "value": "Wacam"
  },
  {
    "id": "9209282007",
    "district_id": "920928",
    "label": "Irumfei",
    "value": "Irumfei"
  },
  {
    "id": "9209282008",
    "district_id": "920928",
    "label": "Pubuan",
    "value": "Pubuan"
  },
  {
    "id": "9209282009",
    "district_id": "920928",
    "label": "Ireji",
    "value": "Ireji"
  },
  {
    "id": "9209282010",
    "district_id": "920928",
    "label": "Kasi Inom",
    "value": "Kasi Inom"
  },
  {
    "id": "9209282011",
    "district_id": "920928",
    "label": "Meycocforga",
    "value": "Meycocforga"
  },
  {
    "id": "9209282012",
    "district_id": "920928",
    "label": "Meyomnar",
    "value": "Meyomnar"
  },
  {
    "id": "9209292001",
    "district_id": "920929",
    "label": "Della",
    "value": "Della"
  },
  {
    "id": "9209292002",
    "district_id": "920929",
    "label": "Malayauw",
    "value": "Malayauw"
  },
  {
    "id": "9209292003",
    "district_id": "920929",
    "label": "Klasbon",
    "value": "Klasbon"
  },
  {
    "id": "9209292004",
    "district_id": "920929",
    "label": "Klasei",
    "value": "Klasei"
  },
  {
    "id": "9209292005",
    "district_id": "920929",
    "label": "Klabili",
    "value": "Klabili"
  },
  {
    "id": "9210012001",
    "district_id": "921001",
    "label": "Kumurkek",
    "value": "Kumurkek"
  },
  {
    "id": "9210012002",
    "district_id": "921001",
    "label": "Susumuk",
    "value": "Susumuk"
  },
  {
    "id": "9210012003",
    "district_id": "921001",
    "label": "Kokas",
    "value": "Kokas"
  },
  {
    "id": "9210012004",
    "district_id": "921001",
    "label": "Kocuas",
    "value": "Kocuas"
  },
  {
    "id": "9210012005",
    "district_id": "921001",
    "label": "Kocuwer",
    "value": "Kocuwer"
  },
  {
    "id": "9210012006",
    "district_id": "921001",
    "label": "Bori",
    "value": "Bori"
  },
  {
    "id": "9210012007",
    "district_id": "921001",
    "label": "Werjaya",
    "value": "Werjaya"
  },
  {
    "id": "9210012008",
    "district_id": "921001",
    "label": "Tehahite",
    "value": "Tehahite"
  },
  {
    "id": "9210012009",
    "district_id": "921001",
    "label": "Faitmayaf",
    "value": "Faitmayaf"
  },
  {
    "id": "9210012010",
    "district_id": "921001",
    "label": "Aisyo",
    "value": "Aisyo"
  },
  {
    "id": "9210012011",
    "district_id": "921001",
    "label": "Futon",
    "value": "Futon"
  },
  {
    "id": "9210012012",
    "district_id": "921001",
    "label": "Sampika",
    "value": "Sampika"
  },
  {
    "id": "9210012013",
    "district_id": "921001",
    "label": "Martaim",
    "value": "Martaim"
  },
  {
    "id": "9210012014",
    "district_id": "921001",
    "label": "Frawebo",
    "value": "Frawebo"
  },
  {
    "id": "9210012015",
    "district_id": "921001",
    "label": "Faitsawe",
    "value": "Faitsawe"
  },
  {
    "id": "9210012016",
    "district_id": "921001",
    "label": "Kocuwer Selatan",
    "value": "Kocuwer Selatan"
  },
  {
    "id": "9210012017",
    "district_id": "921001",
    "label": "Faitmayaf Barat",
    "value": "Faitmayaf Barat"
  },
  {
    "id": "9210012018",
    "district_id": "921001",
    "label": "Sikof",
    "value": "Sikof"
  },
  {
    "id": "9210012019",
    "district_id": "921001",
    "label": "Bori Timur",
    "value": "Bori Timur"
  },
  {
    "id": "9210012020",
    "district_id": "921001",
    "label": "Kumurkek Barat",
    "value": "Kumurkek Barat"
  },
  {
    "id": "9210012021",
    "district_id": "921001",
    "label": "Ainod",
    "value": "Ainod"
  },
  {
    "id": "9210012022",
    "district_id": "921001",
    "label": "Kocuas Utara",
    "value": "Kocuas Utara"
  },
  {
    "id": "9210012023",
    "district_id": "921001",
    "label": "Sunei",
    "value": "Sunei"
  },
  {
    "id": "9210022001",
    "district_id": "921002",
    "label": "Ayawasi",
    "value": "Ayawasi"
  },
  {
    "id": "9210022002",
    "district_id": "921002",
    "label": "Ayawasi Selatan",
    "value": "Ayawasi Selatan"
  },
  {
    "id": "9210022003",
    "district_id": "921002",
    "label": "Ayawasi Timur",
    "value": "Ayawasi Timur"
  },
  {
    "id": "9210022004",
    "district_id": "921002",
    "label": "Susai",
    "value": "Susai"
  },
  {
    "id": "9210022005",
    "district_id": "921002",
    "label": "Fonatu",
    "value": "Fonatu"
  },
  {
    "id": "9210022006",
    "district_id": "921002",
    "label": "Mosun",
    "value": "Mosun"
  },
  {
    "id": "9210022007",
    "district_id": "921002",
    "label": "Wayane",
    "value": "Wayane"
  },
  {
    "id": "9210022008",
    "district_id": "921002",
    "label": "Konja",
    "value": "Konja"
  },
  {
    "id": "9210022009",
    "district_id": "921002",
    "label": "Yarat",
    "value": "Yarat"
  },
  {
    "id": "9210022010",
    "district_id": "921002",
    "label": "Man",
    "value": "Man"
  },
  {
    "id": "9210022011",
    "district_id": "921002",
    "label": "Howait",
    "value": "Howait"
  },
  {
    "id": "9210022012",
    "district_id": "921002",
    "label": "Haenkanes",
    "value": "Haenkanes"
  },
  {
    "id": "9210022013",
    "district_id": "921002",
    "label": "Konja Barat",
    "value": "Konja Barat"
  },
  {
    "id": "9210022014",
    "district_id": "921002",
    "label": "Yarat Timur",
    "value": "Yarat Timur"
  },
  {
    "id": "9210022015",
    "district_id": "921002",
    "label": "Raha",
    "value": "Raha"
  },
  {
    "id": "9210022016",
    "district_id": "921002",
    "label": "Mowes",
    "value": "Mowes"
  },
  {
    "id": "9210022017",
    "district_id": "921002",
    "label": "Irata",
    "value": "Irata"
  },
  {
    "id": "9210022018",
    "district_id": "921002",
    "label": "Neset",
    "value": "Neset"
  },
  {
    "id": "9210022019",
    "district_id": "921002",
    "label": "Mosun Timur",
    "value": "Mosun Timur"
  },
  {
    "id": "9210022020",
    "district_id": "921002",
    "label": "Mosun Utara",
    "value": "Mosun Utara"
  },
  {
    "id": "9210032001",
    "district_id": "921003",
    "label": "Aisa",
    "value": "Aisa"
  },
  {
    "id": "9210032004",
    "district_id": "921003",
    "label": "Aikrer",
    "value": "Aikrer"
  },
  {
    "id": "9210032005",
    "district_id": "921003",
    "label": "Aitrem",
    "value": "Aitrem"
  },
  {
    "id": "9210032006",
    "district_id": "921003",
    "label": "Sawin",
    "value": "Sawin"
  },
  {
    "id": "9210032008",
    "district_id": "921003",
    "label": "Sahbuku",
    "value": "Sahbuku"
  },
  {
    "id": "9210032009",
    "district_id": "921003",
    "label": "Sasior Frabuku",
    "value": "Sasior Frabuku"
  },
  {
    "id": "9210032026",
    "district_id": "921003",
    "label": "Wakom",
    "value": "Wakom"
  },
  {
    "id": "9210032027",
    "district_id": "921003",
    "label": "Sanem",
    "value": "Sanem"
  },
  {
    "id": "9210032033",
    "district_id": "921003",
    "label": "Buoh Sa",
    "value": "Buoh Sa"
  },
  {
    "id": "9210042001",
    "district_id": "921004",
    "label": "Kisor",
    "value": "Kisor"
  },
  {
    "id": "9210042002",
    "district_id": "921004",
    "label": "Sorry",
    "value": "Sorry"
  },
  {
    "id": "9210042003",
    "district_id": "921004",
    "label": "Imsun",
    "value": "Imsun"
  },
  {
    "id": "9210042004",
    "district_id": "921004",
    "label": "Sabah",
    "value": "Sabah"
  },
  {
    "id": "9210042005",
    "district_id": "921004",
    "label": "Tahsimara",
    "value": "Tahsimara"
  },
  {
    "id": "9210042006",
    "district_id": "921004",
    "label": "Fuog",
    "value": "Fuog"
  },
  {
    "id": "9210042008",
    "district_id": "921004",
    "label": "Asiaf Saman",
    "value": "Asiaf Saman"
  },
  {
    "id": "9210042009",
    "district_id": "921004",
    "label": "Hora Iek",
    "value": "Hora Iek"
  },
  {
    "id": "9210042010",
    "district_id": "921004",
    "label": "Fuog Selatan",
    "value": "Fuog Selatan"
  },
  {
    "id": "9210042011",
    "district_id": "921004",
    "label": "Same Rakator",
    "value": "Same Rakator"
  },
  {
    "id": "9210042012",
    "district_id": "921004",
    "label": "Roma",
    "value": "Roma"
  },
  {
    "id": "9210042013",
    "district_id": "921004",
    "label": "Krus",
    "value": "Krus"
  },
  {
    "id": "9210042014",
    "district_id": "921004",
    "label": "Awet Main",
    "value": "Awet Main"
  },
  {
    "id": "9210042015",
    "district_id": "921004",
    "label": "Yeek",
    "value": "Yeek"
  },
  {
    "id": "9210042016",
    "district_id": "921004",
    "label": "Kaitana",
    "value": "Kaitana"
  },
  {
    "id": "9210042017",
    "district_id": "921004",
    "label": "Tolak",
    "value": "Tolak"
  },
  {
    "id": "9210052001",
    "district_id": "921005",
    "label": "Kambufatem",
    "value": "Kambufatem"
  },
  {
    "id": "9210052002",
    "district_id": "921005",
    "label": "Kambufatem Utara",
    "value": "Kambufatem Utara"
  },
  {
    "id": "9210052003",
    "district_id": "921005",
    "label": "Fatase",
    "value": "Fatase"
  },
  {
    "id": "9210052008",
    "district_id": "921005",
    "label": "Hosyo Ata",
    "value": "Hosyo Ata"
  },
  {
    "id": "9210052009",
    "district_id": "921005",
    "label": "Waybomatah",
    "value": "Waybomatah"
  },
  {
    "id": "9210052010",
    "district_id": "921005",
    "label": "Siyo",
    "value": "Siyo"
  },
  {
    "id": "9210052011",
    "district_id": "921005",
    "label": "Hasweh",
    "value": "Hasweh"
  },
  {
    "id": "9210052012",
    "district_id": "921005",
    "label": "Fatem",
    "value": "Fatem"
  },
  {
    "id": "9210052013",
    "district_id": "921005",
    "label": "Hosyo Banah",
    "value": "Hosyo Banah"
  },
  {
    "id": "9210062001",
    "district_id": "921006",
    "label": "Aitinyo",
    "value": "Aitinyo"
  },
  {
    "id": "9210062002",
    "district_id": "921006",
    "label": "Irohe",
    "value": "Irohe"
  },
  {
    "id": "9210062003",
    "district_id": "921006",
    "label": "Sris",
    "value": "Sris"
  },
  {
    "id": "9210062005",
    "district_id": "921006",
    "label": "Kamro",
    "value": "Kamro"
  },
  {
    "id": "9210062007",
    "district_id": "921006",
    "label": "Karsu",
    "value": "Karsu"
  },
  {
    "id": "9210062008",
    "district_id": "921006",
    "label": "Tehak Kecil",
    "value": "Tehak Kecil"
  },
  {
    "id": "9210062009",
    "district_id": "921006",
    "label": "Sira Aya",
    "value": "Sira Aya"
  },
  {
    "id": "9210062010",
    "district_id": "921006",
    "label": "Korom",
    "value": "Korom"
  },
  {
    "id": "9210062011",
    "district_id": "921006",
    "label": "Sumanis",
    "value": "Sumanis"
  },
  {
    "id": "9210062013",
    "district_id": "921006",
    "label": "Ibasuf",
    "value": "Ibasuf"
  },
  {
    "id": "9210062014",
    "district_id": "921006",
    "label": "Sowai Sau",
    "value": "Sowai Sau"
  },
  {
    "id": "9210062015",
    "district_id": "921006",
    "label": "Subin",
    "value": "Subin"
  },
  {
    "id": "9210062016",
    "district_id": "921006",
    "label": "Irohmrar",
    "value": "Irohmrar"
  },
  {
    "id": "9210062017",
    "district_id": "921006",
    "label": "Bofait",
    "value": "Bofait"
  },
  {
    "id": "9210062018",
    "district_id": "921006",
    "label": "Afkrem",
    "value": "Afkrem"
  },
  {
    "id": "9210062019",
    "district_id": "921006",
    "label": "Wiho",
    "value": "Wiho"
  },
  {
    "id": "9210062020",
    "district_id": "921006",
    "label": "Kamro Selatan",
    "value": "Kamro Selatan"
  },
  {
    "id": "9210072001",
    "district_id": "921007",
    "label": "Fategomi",
    "value": "Fategomi"
  },
  {
    "id": "9210072002",
    "district_id": "921007",
    "label": "Tehak Besar",
    "value": "Tehak Besar"
  },
  {
    "id": "9210072003",
    "district_id": "921007",
    "label": "Fan",
    "value": "Fan"
  },
  {
    "id": "9210072004",
    "district_id": "921007",
    "label": "Gohsames",
    "value": "Gohsames"
  },
  {
    "id": "9210072005",
    "district_id": "921007",
    "label": "Asmuruf U",
    "value": "Asmuruf U"
  },
  {
    "id": "9210072006",
    "district_id": "921007",
    "label": "Mirafaan",
    "value": "Mirafaan"
  },
  {
    "id": "9210072011",
    "district_id": "921007",
    "label": "Asmuruf Tee",
    "value": "Asmuruf Tee"
  },
  {
    "id": "9210072012",
    "district_id": "921007",
    "label": "Tehak Tee",
    "value": "Tehak Tee"
  },
  {
    "id": "9210072013",
    "district_id": "921007",
    "label": "Framafir",
    "value": "Framafir"
  },
  {
    "id": "9210072014",
    "district_id": "921007",
    "label": "Subrit",
    "value": "Subrit"
  },
  {
    "id": "9210072015",
    "district_id": "921007",
    "label": "Inta",
    "value": "Inta"
  },
  {
    "id": "9210072016",
    "district_id": "921007",
    "label": "Bahwat",
    "value": "Bahwat"
  },
  {
    "id": "9210081001",
    "district_id": "921008",
    "label": "Ayamaru",
    "value": "Ayamaru"
  },
  {
    "id": "9210082010",
    "district_id": "921008",
    "label": "Framu",
    "value": "Framu"
  },
  {
    "id": "9210082011",
    "district_id": "921008",
    "label": "Mefkajim II",
    "value": "Mefkajim II"
  },
  {
    "id": "9210082017",
    "district_id": "921008",
    "label": "Fraharo",
    "value": "Fraharo"
  },
  {
    "id": "9210082018",
    "district_id": "921008",
    "label": "Smusswioh",
    "value": "Smusswioh"
  },
  {
    "id": "9210082019",
    "district_id": "921008",
    "label": "Twer",
    "value": "Twer"
  },
  {
    "id": "9210082020",
    "district_id": "921008",
    "label": "Afes",
    "value": "Afes"
  },
  {
    "id": "9210082021",
    "district_id": "921008",
    "label": "Tuso",
    "value": "Tuso"
  },
  {
    "id": "9210092001",
    "district_id": "921009",
    "label": "Yubiah",
    "value": "Yubiah"
  },
  {
    "id": "9210092003",
    "district_id": "921009",
    "label": "Karetubun",
    "value": "Karetubun"
  },
  {
    "id": "9210092004",
    "district_id": "921009",
    "label": "Setta",
    "value": "Setta"
  },
  {
    "id": "9210092006",
    "district_id": "921009",
    "label": "Hohoyor",
    "value": "Hohoyor"
  },
  {
    "id": "9210092007",
    "district_id": "921009",
    "label": "Arne",
    "value": "Arne"
  },
  {
    "id": "9210092009",
    "district_id": "921009",
    "label": "Aus Tiwit",
    "value": "Aus Tiwit"
  },
  {
    "id": "9210092010",
    "district_id": "921009",
    "label": "Johafah",
    "value": "Johafah"
  },
  {
    "id": "9210092011",
    "district_id": "921009",
    "label": "Yukase",
    "value": "Yukase"
  },
  {
    "id": "9210092012",
    "district_id": "921009",
    "label": "Serma",
    "value": "Serma"
  },
  {
    "id": "9210092013",
    "district_id": "921009",
    "label": "Kfaa",
    "value": "Kfaa"
  },
  {
    "id": "9210092014",
    "district_id": "921009",
    "label": "Arne Timur",
    "value": "Arne Timur"
  },
  {
    "id": "9210092020",
    "district_id": "921009",
    "label": "Nauwita",
    "value": "Nauwita"
  },
  {
    "id": "9210102001",
    "district_id": "921010",
    "label": "Kambuaya",
    "value": "Kambuaya"
  },
  {
    "id": "9210102002",
    "district_id": "921010",
    "label": "Huberita",
    "value": "Huberita"
  },
  {
    "id": "9210102003",
    "district_id": "921010",
    "label": "Faitmajin",
    "value": "Faitmajin"
  },
  {
    "id": "9210102006",
    "district_id": "921010",
    "label": "Ismayo",
    "value": "Ismayo"
  },
  {
    "id": "9210102007",
    "district_id": "921010",
    "label": "Sefayit",
    "value": "Sefayit"
  },
  {
    "id": "9210102008",
    "district_id": "921010",
    "label": "Insas",
    "value": "Insas"
  },
  {
    "id": "9210102009",
    "district_id": "921010",
    "label": "Faitsiur",
    "value": "Faitsiur"
  },
  {
    "id": "9210102010",
    "district_id": "921010",
    "label": "Keyum",
    "value": "Keyum"
  },
  {
    "id": "9210112001",
    "district_id": "921011",
    "label": "Suswa",
    "value": "Suswa"
  },
  {
    "id": "9210112002",
    "district_id": "921011",
    "label": "Seya",
    "value": "Seya"
  },
  {
    "id": "9210112005",
    "district_id": "921011",
    "label": "Waban",
    "value": "Waban"
  },
  {
    "id": "9210112006",
    "district_id": "921011",
    "label": "Kombif",
    "value": "Kombif"
  },
  {
    "id": "9210112008",
    "district_id": "921011",
    "label": "Rufases",
    "value": "Rufases"
  },
  {
    "id": "9210112009",
    "district_id": "921011",
    "label": "Bakrabi",
    "value": "Bakrabi"
  },
  {
    "id": "9210112010",
    "district_id": "921011",
    "label": "Nafasi",
    "value": "Nafasi"
  },
  {
    "id": "9210112011",
    "district_id": "921011",
    "label": "Mahos",
    "value": "Mahos"
  },
  {
    "id": "9210112012",
    "district_id": "921011",
    "label": "Sawo",
    "value": "Sawo"
  },
  {
    "id": "9210122001",
    "district_id": "921012",
    "label": "Mupas",
    "value": "Mupas"
  },
  {
    "id": "9210122002",
    "district_id": "921012",
    "label": "Kamat",
    "value": "Kamat"
  },
  {
    "id": "9210122003",
    "district_id": "921012",
    "label": "Frambu",
    "value": "Frambu"
  },
  {
    "id": "9210122004",
    "district_id": "921012",
    "label": "Ayata",
    "value": "Ayata"
  },
  {
    "id": "9210122005",
    "district_id": "921012",
    "label": "Aikus",
    "value": "Aikus"
  },
  {
    "id": "9210122006",
    "district_id": "921012",
    "label": "Aiwesa",
    "value": "Aiwesa"
  },
  {
    "id": "9210122007",
    "district_id": "921012",
    "label": "Pitor",
    "value": "Pitor"
  },
  {
    "id": "9210122008",
    "district_id": "921012",
    "label": "Aifam",
    "value": "Aifam"
  },
  {
    "id": "9210122009",
    "district_id": "921012",
    "label": "Saud",
    "value": "Saud"
  },
  {
    "id": "9210122010",
    "district_id": "921012",
    "label": "Tiam",
    "value": "Tiam"
  },
  {
    "id": "9210122011",
    "district_id": "921012",
    "label": "Faan Kahrio",
    "value": "Faan Kahrio"
  },
  {
    "id": "9210122012",
    "district_id": "921012",
    "label": "Assem",
    "value": "Assem"
  },
  {
    "id": "9210132001",
    "district_id": "921013",
    "label": "Ainesra",
    "value": "Ainesra"
  },
  {
    "id": "9210132002",
    "district_id": "921013",
    "label": "Aimau",
    "value": "Aimau"
  },
  {
    "id": "9210132003",
    "district_id": "921013",
    "label": "Mesyam",
    "value": "Mesyam"
  },
  {
    "id": "9210132004",
    "district_id": "921013",
    "label": "Aisasior",
    "value": "Aisasior"
  },
  {
    "id": "9210132005",
    "district_id": "921013",
    "label": "Franeway",
    "value": "Franeway"
  },
  {
    "id": "9210132006",
    "district_id": "921013",
    "label": "Aikingging",
    "value": "Aikingging"
  },
  {
    "id": "9210132007",
    "district_id": "921013",
    "label": "Tiefromen",
    "value": "Tiefromen"
  },
  {
    "id": "9210142001",
    "district_id": "921014",
    "label": "Wormu",
    "value": "Wormu"
  },
  {
    "id": "9210142002",
    "district_id": "921014",
    "label": "Makiri",
    "value": "Makiri"
  },
  {
    "id": "9210142003",
    "district_id": "921014",
    "label": "Warba",
    "value": "Warba"
  },
  {
    "id": "9210142004",
    "district_id": "921014",
    "label": "Winuni",
    "value": "Winuni"
  },
  {
    "id": "9210142005",
    "district_id": "921014",
    "label": "Srumate",
    "value": "Srumate"
  },
  {
    "id": "9210142006",
    "district_id": "921014",
    "label": "Womba",
    "value": "Womba"
  },
  {
    "id": "9210152001",
    "district_id": "921015",
    "label": "Sauf",
    "value": "Sauf"
  },
  {
    "id": "9210152002",
    "district_id": "921015",
    "label": "Sembaro",
    "value": "Sembaro"
  },
  {
    "id": "9210152003",
    "district_id": "921015",
    "label": "Koma Koma",
    "value": "Koma Koma"
  },
  {
    "id": "9210152004",
    "district_id": "921015",
    "label": "Kanisabar",
    "value": "Kanisabar"
  },
  {
    "id": "9210152005",
    "district_id": "921015",
    "label": "Sfarare",
    "value": "Sfarare"
  },
  {
    "id": "9210152006",
    "district_id": "921015",
    "label": "Saneh",
    "value": "Saneh"
  },
  {
    "id": "9210152007",
    "district_id": "921015",
    "label": "Lemauk Klit",
    "value": "Lemauk Klit"
  },
  {
    "id": "9210152008",
    "district_id": "921015",
    "label": "Hawioh",
    "value": "Hawioh"
  },
  {
    "id": "9210152009",
    "district_id": "921015",
    "label": "Simiyah",
    "value": "Simiyah"
  },
  {
    "id": "9210152010",
    "district_id": "921015",
    "label": "Sagrim",
    "value": "Sagrim"
  },
  {
    "id": "9210162001",
    "district_id": "921016",
    "label": "Adoh",
    "value": "Adoh"
  },
  {
    "id": "9210162002",
    "district_id": "921016",
    "label": "Temel",
    "value": "Temel"
  },
  {
    "id": "9210162003",
    "district_id": "921016",
    "label": "Sosian",
    "value": "Sosian"
  },
  {
    "id": "9210162004",
    "district_id": "921016",
    "label": "Segior",
    "value": "Segior"
  },
  {
    "id": "9210162005",
    "district_id": "921016",
    "label": "Orain",
    "value": "Orain"
  },
  {
    "id": "9210162006",
    "district_id": "921016",
    "label": "Rawas",
    "value": "Rawas"
  },
  {
    "id": "9210162007",
    "district_id": "921016",
    "label": "Orsu",
    "value": "Orsu"
  },
  {
    "id": "9210162008",
    "district_id": "921016",
    "label": "Soan",
    "value": "Soan"
  },
  {
    "id": "9210162009",
    "district_id": "921016",
    "label": "Warbo",
    "value": "Warbo"
  },
  {
    "id": "9210162010",
    "district_id": "921016",
    "label": "Woman",
    "value": "Woman"
  },
  {
    "id": "9210172001",
    "district_id": "921017",
    "label": "Faitsimar",
    "value": "Faitsimar"
  },
  {
    "id": "9210172002",
    "district_id": "921017",
    "label": "Asses",
    "value": "Asses"
  },
  {
    "id": "9210172003",
    "district_id": "921017",
    "label": "Fait Nigre",
    "value": "Fait Nigre"
  },
  {
    "id": "9210172004",
    "district_id": "921017",
    "label": "Sufu",
    "value": "Sufu"
  },
  {
    "id": "9210172005",
    "district_id": "921017",
    "label": "Arus",
    "value": "Arus"
  },
  {
    "id": "9210172006",
    "district_id": "921017",
    "label": "Kofait",
    "value": "Kofait"
  },
  {
    "id": "9210172007",
    "district_id": "921017",
    "label": "Isnum",
    "value": "Isnum"
  },
  {
    "id": "9210182001",
    "district_id": "921018",
    "label": "Kambuskato",
    "value": "Kambuskato"
  },
  {
    "id": "9210182002",
    "district_id": "921018",
    "label": "Kambuskato Utara",
    "value": "Kambuskato Utara"
  },
  {
    "id": "9210182003",
    "district_id": "921018",
    "label": "Kambuifa",
    "value": "Kambuifa"
  },
  {
    "id": "9210182004",
    "district_id": "921018",
    "label": "Isusu",
    "value": "Isusu"
  },
  {
    "id": "9210182005",
    "district_id": "921018",
    "label": "Mano",
    "value": "Mano"
  },
  {
    "id": "9210182006",
    "district_id": "921018",
    "label": "Sipat",
    "value": "Sipat"
  },
  {
    "id": "9210182007",
    "district_id": "921018",
    "label": "Faitwosur/Angkasa Pura",
    "value": "Faitwosur/Angkasa Pura"
  },
  {
    "id": "9210192001",
    "district_id": "921019",
    "label": "Mapura",
    "value": "Mapura"
  },
  {
    "id": "9210192002",
    "district_id": "921019",
    "label": "Suwiam",
    "value": "Suwiam"
  },
  {
    "id": "9210192003",
    "district_id": "921019",
    "label": "Tomase",
    "value": "Tomase"
  },
  {
    "id": "9210192004",
    "district_id": "921019",
    "label": "Frabo",
    "value": "Frabo"
  },
  {
    "id": "9210192005",
    "district_id": "921019",
    "label": "Karfa",
    "value": "Karfa"
  },
  {
    "id": "9210192006",
    "district_id": "921019",
    "label": "Kona",
    "value": "Kona"
  },
  {
    "id": "9210192007",
    "district_id": "921019",
    "label": "Kosah",
    "value": "Kosah"
  },
  {
    "id": "9210202001",
    "district_id": "921020",
    "label": "Fiane",
    "value": "Fiane"
  },
  {
    "id": "9210202002",
    "district_id": "921020",
    "label": "Kartapura",
    "value": "Kartapura"
  },
  {
    "id": "9210202003",
    "district_id": "921020",
    "label": "Men",
    "value": "Men"
  },
  {
    "id": "9210202004",
    "district_id": "921020",
    "label": "Semu",
    "value": "Semu"
  },
  {
    "id": "9210202005",
    "district_id": "921020",
    "label": "Isme",
    "value": "Isme"
  },
  {
    "id": "9210202006",
    "district_id": "921020",
    "label": "Rindu",
    "value": "Rindu"
  },
  {
    "id": "9210202007",
    "district_id": "921020",
    "label": "Tut",
    "value": "Tut"
  },
  {
    "id": "9210202008",
    "district_id": "921020",
    "label": "Yohwer",
    "value": "Yohwer"
  },
  {
    "id": "9210202009",
    "district_id": "921020",
    "label": "Hufioh",
    "value": "Hufioh"
  },
  {
    "id": "9210202010",
    "district_id": "921020",
    "label": "Bawy",
    "value": "Bawy"
  },
  {
    "id": "9210212001",
    "district_id": "921021",
    "label": "Soroan",
    "value": "Soroan"
  },
  {
    "id": "9210212002",
    "district_id": "921021",
    "label": "Fanse",
    "value": "Fanse"
  },
  {
    "id": "9210212003",
    "district_id": "921021",
    "label": "Chaliat",
    "value": "Chaliat"
  },
  {
    "id": "9210212004",
    "district_id": "921021",
    "label": "Sfacko",
    "value": "Sfacko"
  },
  {
    "id": "9210212005",
    "district_id": "921021",
    "label": "Sehu",
    "value": "Sehu"
  },
  {
    "id": "9210212006",
    "district_id": "921021",
    "label": "Tbo",
    "value": "Tbo"
  },
  {
    "id": "9210212007",
    "district_id": "921021",
    "label": "Sien",
    "value": "Sien"
  },
  {
    "id": "9210212008",
    "district_id": "921021",
    "label": "Sfaraka",
    "value": "Sfaraka"
  },
  {
    "id": "9210222001",
    "district_id": "921022",
    "label": "Yaksoro",
    "value": "Yaksoro"
  },
  {
    "id": "9210222002",
    "district_id": "921022",
    "label": "Awit",
    "value": "Awit"
  },
  {
    "id": "9210222003",
    "district_id": "921022",
    "label": "Sira",
    "value": "Sira"
  },
  {
    "id": "9210222004",
    "district_id": "921022",
    "label": "Eway",
    "value": "Eway"
  },
  {
    "id": "9210222005",
    "district_id": "921022",
    "label": "Erokwero",
    "value": "Erokwero"
  },
  {
    "id": "9210222006",
    "district_id": "921022",
    "label": "Sabun",
    "value": "Sabun"
  },
  {
    "id": "9210222007",
    "district_id": "921022",
    "label": "Itigah",
    "value": "Itigah"
  },
  {
    "id": "9210222008",
    "district_id": "921022",
    "label": "Sira Tee",
    "value": "Sira Tee"
  },
  {
    "id": "9210222009",
    "district_id": "921022",
    "label": "Wrait 'U'",
    "value": "Wrait 'U'"
  },
  {
    "id": "9210222010",
    "district_id": "921022",
    "label": "Way 'U'",
    "value": "Way 'U'"
  },
  {
    "id": "9210222011",
    "district_id": "921022",
    "label": "Asnaif",
    "value": "Asnaif"
  },
  {
    "id": "9210222012",
    "district_id": "921022",
    "label": "Iroh Sohser",
    "value": "Iroh Sohser"
  },
  {
    "id": "9210222013",
    "district_id": "921022",
    "label": "Framboh",
    "value": "Framboh"
  },
  {
    "id": "9210222014",
    "district_id": "921022",
    "label": "Tohmri",
    "value": "Tohmri"
  },
  {
    "id": "9210232001",
    "district_id": "921023",
    "label": "Jitmau",
    "value": "Jitmau"
  },
  {
    "id": "9210232002",
    "district_id": "921023",
    "label": "Ikuf",
    "value": "Ikuf"
  },
  {
    "id": "9210232003",
    "district_id": "921023",
    "label": "Isir",
    "value": "Isir"
  },
  {
    "id": "9210232004",
    "district_id": "921023",
    "label": "Jitmau Timur",
    "value": "Jitmau Timur"
  },
  {
    "id": "9210232005",
    "district_id": "921023",
    "label": "Ikuf Utara",
    "value": "Ikuf Utara"
  },
  {
    "id": "9210232006",
    "district_id": "921023",
    "label": "Sarimo",
    "value": "Sarimo"
  },
  {
    "id": "9210232007",
    "district_id": "921023",
    "label": "Kambusabo",
    "value": "Kambusabo"
  },
  {
    "id": "9210232008",
    "district_id": "921023",
    "label": "Srirtabam",
    "value": "Srirtabam"
  },
  {
    "id": "9210232009",
    "district_id": "921023",
    "label": "Faithowes",
    "value": "Faithowes"
  },
  {
    "id": "9210232010",
    "district_id": "921023",
    "label": "Yumame",
    "value": "Yumame"
  },
  {
    "id": "9210242001",
    "district_id": "921024",
    "label": "Seni",
    "value": "Seni"
  },
  {
    "id": "9210242002",
    "district_id": "921024",
    "label": "Renis",
    "value": "Renis"
  },
  {
    "id": "9210242003",
    "district_id": "921024",
    "label": "Sire",
    "value": "Sire"
  },
  {
    "id": "9210242004",
    "district_id": "921024",
    "label": "Fase",
    "value": "Fase"
  },
  {
    "id": "9210242005",
    "district_id": "921024",
    "label": "Sabes",
    "value": "Sabes"
  },
  {
    "id": "9210242006",
    "district_id": "921024",
    "label": "Kuraso",
    "value": "Kuraso"
  },
  {
    "id": "9210242007",
    "district_id": "921024",
    "label": "Sidi",
    "value": "Sidi"
  },
  {
    "id": "9210242008",
    "district_id": "921024",
    "label": "Osom",
    "value": "Osom"
  },
  {
    "id": "9210242009",
    "district_id": "921024",
    "label": "Sire Timur",
    "value": "Sire Timur"
  },
  {
    "id": "9211012001",
    "district_id": "921101",
    "label": "Ransiki",
    "value": "Ransiki"
  },
  {
    "id": "9211012002",
    "district_id": "921101",
    "label": "Nuhuwei",
    "value": "Nuhuwei"
  },
  {
    "id": "9211012003",
    "district_id": "921101",
    "label": "Yamboi",
    "value": "Yamboi"
  },
  {
    "id": "9211012004",
    "district_id": "921101",
    "label": "Tobou",
    "value": "Tobou"
  },
  {
    "id": "9211012005",
    "district_id": "921101",
    "label": "Sabri",
    "value": "Sabri"
  },
  {
    "id": "9211012006",
    "district_id": "921101",
    "label": "Bamaha",
    "value": "Bamaha"
  },
  {
    "id": "9211012007",
    "district_id": "921101",
    "label": "Abreso",
    "value": "Abreso"
  },
  {
    "id": "9211012008",
    "district_id": "921101",
    "label": "Hamawi",
    "value": "Hamawi"
  },
  {
    "id": "9211012009",
    "district_id": "921101",
    "label": "Kobrey",
    "value": "Kobrey"
  },
  {
    "id": "9211012010",
    "district_id": "921101",
    "label": "Wamcei",
    "value": "Wamcei"
  },
  {
    "id": "9211012011",
    "district_id": "921101",
    "label": "Hamor",
    "value": "Hamor"
  },
  {
    "id": "9211012012",
    "district_id": "921101",
    "label": "Susmorof",
    "value": "Susmorof"
  },
  {
    "id": "9211012013",
    "district_id": "921101",
    "label": "Mambrema",
    "value": "Mambrema"
  },
  {
    "id": "9211022001",
    "district_id": "921102",
    "label": "Oransbari",
    "value": "Oransbari"
  },
  {
    "id": "9211022002",
    "district_id": "921102",
    "label": "Sidomulyo",
    "value": "Sidomulyo"
  },
  {
    "id": "9211022003",
    "district_id": "921102",
    "label": "Waroser",
    "value": "Waroser"
  },
  {
    "id": "9211022004",
    "district_id": "921102",
    "label": "Margo Rukun",
    "value": "Margo Rukun"
  },
  {
    "id": "9211022005",
    "district_id": "921102",
    "label": "Muari",
    "value": "Muari"
  },
  {
    "id": "9211022006",
    "district_id": "921102",
    "label": "Watariri",
    "value": "Watariri"
  },
  {
    "id": "9211022007",
    "district_id": "921102",
    "label": "Masabui",
    "value": "Masabui"
  },
  {
    "id": "9211022008",
    "district_id": "921102",
    "label": "Warbiadi",
    "value": "Warbiadi"
  },
  {
    "id": "9211022009",
    "district_id": "921102",
    "label": "Margomulyo",
    "value": "Margomulyo"
  },
  {
    "id": "9211022010",
    "district_id": "921102",
    "label": "Warkwandi",
    "value": "Warkwandi"
  },
  {
    "id": "9211022011",
    "district_id": "921102",
    "label": "Sindang Jaya",
    "value": "Sindang Jaya"
  },
  {
    "id": "9211022012",
    "district_id": "921102",
    "label": "Wandoki",
    "value": "Wandoki"
  },
  {
    "id": "9211022013",
    "district_id": "921102",
    "label": "Akeju",
    "value": "Akeju"
  },
  {
    "id": "9211022014",
    "district_id": "921102",
    "label": "Masabui II",
    "value": "Masabui II"
  },
  {
    "id": "9211032001",
    "district_id": "921103",
    "label": "Wama",
    "value": "Wama"
  },
  {
    "id": "9211032002",
    "district_id": "921103",
    "label": "Disi",
    "value": "Disi"
  },
  {
    "id": "9211032003",
    "district_id": "921103",
    "label": "Hiyou",
    "value": "Hiyou"
  },
  {
    "id": "9211032004",
    "district_id": "921103",
    "label": "Sesum",
    "value": "Sesum"
  },
  {
    "id": "9211032005",
    "district_id": "921103",
    "label": "Benyas",
    "value": "Benyas"
  },
  {
    "id": "9211032006",
    "district_id": "921103",
    "label": "Aryawenmoho",
    "value": "Aryawenmoho"
  },
  {
    "id": "9211032007",
    "district_id": "921103",
    "label": "Neney",
    "value": "Neney"
  },
  {
    "id": "9211042001",
    "district_id": "921104",
    "label": "Isim",
    "value": "Isim"
  },
  {
    "id": "9211042002",
    "district_id": "921104",
    "label": "Duhugesa",
    "value": "Duhugesa"
  },
  {
    "id": "9211042003",
    "district_id": "921104",
    "label": "Tubes",
    "value": "Tubes"
  },
  {
    "id": "9211042004",
    "district_id": "921104",
    "label": "Umohousi",
    "value": "Umohousi"
  },
  {
    "id": "9211042005",
    "district_id": "921104",
    "label": "Tohosta",
    "value": "Tohosta"
  },
  {
    "id": "9211042006",
    "district_id": "921104",
    "label": "Hugomot",
    "value": "Hugomot"
  },
  {
    "id": "9211042007",
    "district_id": "921104",
    "label": "Dihisu",
    "value": "Dihisu"
  },
  {
    "id": "9211042008",
    "district_id": "921104",
    "label": "Inyuara",
    "value": "Inyuara"
  },
  {
    "id": "9211042009",
    "district_id": "921104",
    "label": "Desra",
    "value": "Desra"
  },
  {
    "id": "9211042010",
    "district_id": "921104",
    "label": "Mindermes",
    "value": "Mindermes"
  },
  {
    "id": "9211042011",
    "district_id": "921104",
    "label": "Sibjo",
    "value": "Sibjo"
  },
  {
    "id": "9211042012",
    "district_id": "921104",
    "label": "Dibera",
    "value": "Dibera"
  },
  {
    "id": "9211052001",
    "district_id": "921105",
    "label": "Dembek",
    "value": "Dembek"
  },
  {
    "id": "9211052002",
    "district_id": "921105",
    "label": "Nij",
    "value": "Nij"
  },
  {
    "id": "9211052003",
    "district_id": "921105",
    "label": "Siwi",
    "value": "Siwi"
  },
  {
    "id": "9211052004",
    "district_id": "921105",
    "label": "Gaya Baru",
    "value": "Gaya Baru"
  },
  {
    "id": "9211052005",
    "district_id": "921105",
    "label": "Waren",
    "value": "Waren"
  },
  {
    "id": "9211052006",
    "district_id": "921105",
    "label": "Demini",
    "value": "Demini"
  },
  {
    "id": "9211052007",
    "district_id": "921105",
    "label": "Yekwandi",
    "value": "Yekwandi"
  },
  {
    "id": "9211062001",
    "district_id": "921106",
    "label": "Yarmatum",
    "value": "Yarmatum"
  },
  {
    "id": "9211062002",
    "district_id": "921106",
    "label": "Reyob",
    "value": "Reyob"
  },
  {
    "id": "9211062003",
    "district_id": "921106",
    "label": "Kaprus",
    "value": "Kaprus"
  },
  {
    "id": "9211062004",
    "district_id": "921106",
    "label": "Seimeba",
    "value": "Seimeba"
  },
  {
    "id": "9212012001",
    "district_id": "921201",
    "label": "Iraiweri",
    "value": "Iraiweri"
  },
  {
    "id": "9212012002",
    "district_id": "921201",
    "label": "Igembai",
    "value": "Igembai"
  },
  {
    "id": "9212012003",
    "district_id": "921201",
    "label": "Imbai",
    "value": "Imbai"
  },
  {
    "id": "9212012004",
    "district_id": "921201",
    "label": "Suteibey",
    "value": "Suteibey"
  },
  {
    "id": "9212012005",
    "district_id": "921201",
    "label": "Susi",
    "value": "Susi"
  },
  {
    "id": "9212012006",
    "district_id": "921201",
    "label": "Ullong",
    "value": "Ullong"
  },
  {
    "id": "9212012007",
    "district_id": "921201",
    "label": "Mentubey",
    "value": "Mentubey"
  },
  {
    "id": "9212012008",
    "district_id": "921201",
    "label": "Ayaubey",
    "value": "Ayaubey"
  },
  {
    "id": "9212012009",
    "district_id": "921201",
    "label": "Testega Pamaha",
    "value": "Testega Pamaha"
  },
  {
    "id": "9212012010",
    "district_id": "921201",
    "label": "Pamaha",
    "value": "Pamaha"
  },
  {
    "id": "9212012011",
    "district_id": "921201",
    "label": "Uper",
    "value": "Uper"
  },
  {
    "id": "9212012012",
    "district_id": "921201",
    "label": "Hungku",
    "value": "Hungku"
  },
  {
    "id": "9212012013",
    "district_id": "921201",
    "label": "Srubey",
    "value": "Srubey"
  },
  {
    "id": "9212022001",
    "district_id": "921202",
    "label": "Sibiogud",
    "value": "Sibiogud"
  },
  {
    "id": "9212022002",
    "district_id": "921202",
    "label": "Krobut",
    "value": "Krobut"
  },
  {
    "id": "9212022003",
    "district_id": "921202",
    "label": "Tubyam",
    "value": "Tubyam"
  },
  {
    "id": "9212022004",
    "district_id": "921202",
    "label": "Sakumi",
    "value": "Sakumi"
  },
  {
    "id": "9212022005",
    "district_id": "921202",
    "label": "Tombrok",
    "value": "Tombrok"
  },
  {
    "id": "9212022006",
    "district_id": "921202",
    "label": "Sisrang",
    "value": "Sisrang"
  },
  {
    "id": "9212022007",
    "district_id": "921202",
    "label": "Ngisrow",
    "value": "Ngisrow"
  },
  {
    "id": "9212022008",
    "district_id": "921202",
    "label": "Itkau",
    "value": "Itkau"
  },
  {
    "id": "9212032001",
    "district_id": "921203",
    "label": "Inyebuow",
    "value": "Inyebuow"
  },
  {
    "id": "9212032002",
    "district_id": "921203",
    "label": "Imbeisba",
    "value": "Imbeisba"
  },
  {
    "id": "9212032003",
    "district_id": "921203",
    "label": "Usti",
    "value": "Usti"
  },
  {
    "id": "9212032004",
    "district_id": "921203",
    "label": "Memti",
    "value": "Memti"
  },
  {
    "id": "9212032005",
    "district_id": "921203",
    "label": "Koney",
    "value": "Koney"
  },
  {
    "id": "9212032006",
    "district_id": "921203",
    "label": "Membey",
    "value": "Membey"
  },
  {
    "id": "9212042001",
    "district_id": "921204",
    "label": "Sururey",
    "value": "Sururey"
  },
  {
    "id": "9212042002",
    "district_id": "921204",
    "label": "Tuhubea",
    "value": "Tuhubea"
  },
  {
    "id": "9212042003",
    "district_id": "921204",
    "label": "Sungedes",
    "value": "Sungedes"
  },
  {
    "id": "9212042004",
    "district_id": "921204",
    "label": "Inyaub",
    "value": "Inyaub"
  },
  {
    "id": "9212042005",
    "district_id": "921204",
    "label": "Saugemeba",
    "value": "Saugemeba"
  },
  {
    "id": "9212042006",
    "district_id": "921204",
    "label": "Kobrey",
    "value": "Kobrey"
  },
  {
    "id": "9212042007",
    "district_id": "921204",
    "label": "Kopo",
    "value": "Kopo"
  },
  {
    "id": "9212042008",
    "district_id": "921204",
    "label": "Dugahani",
    "value": "Dugahani"
  },
  {
    "id": "9212042009",
    "district_id": "921204",
    "label": "Tomstera",
    "value": "Tomstera"
  },
  {
    "id": "9212042010",
    "district_id": "921204",
    "label": "Kostera",
    "value": "Kostera"
  },
  {
    "id": "9212042011",
    "district_id": "921204",
    "label": "Anuk",
    "value": "Anuk"
  },
  {
    "id": "9212042012",
    "district_id": "921204",
    "label": "Menesrij",
    "value": "Menesrij"
  },
  {
    "id": "9212052001",
    "district_id": "921205",
    "label": "Iranmeba",
    "value": "Iranmeba"
  },
  {
    "id": "9212052002",
    "district_id": "921205",
    "label": "Cirnohu",
    "value": "Cirnohu"
  },
  {
    "id": "9212052003",
    "district_id": "921205",
    "label": "Dibetik",
    "value": "Dibetik"
  },
  {
    "id": "9212052004",
    "district_id": "921205",
    "label": "Danmou",
    "value": "Danmou"
  },
  {
    "id": "9212052005",
    "district_id": "921205",
    "label": "Miseda",
    "value": "Miseda"
  },
  {
    "id": "9212052006",
    "district_id": "921205",
    "label": "Sirgemeh",
    "value": "Sirgemeh"
  },
  {
    "id": "9212052007",
    "district_id": "921205",
    "label": "Sneremer",
    "value": "Sneremer"
  },
  {
    "id": "9212052008",
    "district_id": "921205",
    "label": "Kusmenau",
    "value": "Kusmenau"
  },
  {
    "id": "9212052009",
    "district_id": "921205",
    "label": "Cigera",
    "value": "Cigera"
  },
  {
    "id": "9212052010",
    "district_id": "921205",
    "label": "Tomstir",
    "value": "Tomstir"
  },
  {
    "id": "9212052011",
    "district_id": "921205",
    "label": "Gedeira",
    "value": "Gedeira"
  },
  {
    "id": "9212052012",
    "district_id": "921205",
    "label": "Demdamei",
    "value": "Demdamei"
  },
  {
    "id": "9212052013",
    "district_id": "921205",
    "label": "Dugrimog",
    "value": "Dugrimog"
  },
  {
    "id": "9212052014",
    "district_id": "921205",
    "label": "Disra",
    "value": "Disra"
  },
  {
    "id": "9212062001",
    "district_id": "921206",
    "label": "Genyu",
    "value": "Genyu"
  },
  {
    "id": "9212062002",
    "district_id": "921206",
    "label": "Ansum",
    "value": "Ansum"
  },
  {
    "id": "9212062003",
    "district_id": "921206",
    "label": "Deurohu",
    "value": "Deurohu"
  },
  {
    "id": "9212062004",
    "district_id": "921206",
    "label": "Horeta",
    "value": "Horeta"
  },
  {
    "id": "9212062005",
    "district_id": "921206",
    "label": "Ubeisa",
    "value": "Ubeisa"
  },
  {
    "id": "9212062006",
    "district_id": "921206",
    "label": "Taige",
    "value": "Taige"
  },
  {
    "id": "9212062007",
    "district_id": "921206",
    "label": "Tridaga",
    "value": "Tridaga"
  },
  {
    "id": "9212062008",
    "district_id": "921206",
    "label": "Disura",
    "value": "Disura"
  },
  {
    "id": "9212062009",
    "district_id": "921206",
    "label": "Awigau",
    "value": "Awigau"
  },
  {
    "id": "9212062010",
    "district_id": "921206",
    "label": "Siskedowo",
    "value": "Siskedowo"
  },
  {
    "id": "9212062011",
    "district_id": "921206",
    "label": "Irbos",
    "value": "Irbos"
  },
  {
    "id": "9212072001",
    "district_id": "921207",
    "label": "Catubouw",
    "value": "Catubouw"
  },
  {
    "id": "9212072002",
    "district_id": "921207",
    "label": "Bigwaimud",
    "value": "Bigwaimud"
  },
  {
    "id": "9212072003",
    "district_id": "921207",
    "label": "Mihou",
    "value": "Mihou"
  },
  {
    "id": "9212072004",
    "district_id": "921207",
    "label": "Kaungwam",
    "value": "Kaungwam"
  },
  {
    "id": "9212072005",
    "district_id": "921207",
    "label": "Aiga",
    "value": "Aiga"
  },
  {
    "id": "9212072006",
    "district_id": "921207",
    "label": "Imandrigo",
    "value": "Imandrigo"
  },
  {
    "id": "9212072007",
    "district_id": "921207",
    "label": "Unti",
    "value": "Unti"
  },
  {
    "id": "9212072008",
    "district_id": "921207",
    "label": "Ndabouw",
    "value": "Ndabouw"
  },
  {
    "id": "9212072009",
    "district_id": "921207",
    "label": "Sugemeh",
    "value": "Sugemeh"
  },
  {
    "id": "9212072010",
    "district_id": "921207",
    "label": "Manggesuk",
    "value": "Manggesuk"
  },
  {
    "id": "9212072011",
    "district_id": "921207",
    "label": "Ijigreg",
    "value": "Ijigreg"
  },
  {
    "id": "9212072012",
    "district_id": "921207",
    "label": "Jim",
    "value": "Jim"
  },
  {
    "id": "9212072013",
    "district_id": "921207",
    "label": "Slomiou",
    "value": "Slomiou"
  },
  {
    "id": "9212072014",
    "district_id": "921207",
    "label": "Binggrayud",
    "value": "Binggrayud"
  },
  {
    "id": "9212072015",
    "district_id": "921207",
    "label": "Minmo",
    "value": "Minmo"
  },
  {
    "id": "9212072016",
    "district_id": "921207",
    "label": "Mihij",
    "value": "Mihij"
  },
  {
    "id": "9212072017",
    "district_id": "921207",
    "label": "Soudin",
    "value": "Soudin"
  },
  {
    "id": "9212072018",
    "district_id": "921207",
    "label": "Timtouw",
    "value": "Timtouw"
  },
  {
    "id": "9212072019",
    "district_id": "921207",
    "label": "Idemai",
    "value": "Idemai"
  },
  {
    "id": "9212072020",
    "district_id": "921207",
    "label": "Mieycomti",
    "value": "Mieycomti"
  },
  {
    "id": "9212072021",
    "district_id": "921207",
    "label": "Coijut",
    "value": "Coijut"
  },
  {
    "id": "9212082001",
    "district_id": "921208",
    "label": "Testega",
    "value": "Testega"
  },
  {
    "id": "9212082002",
    "district_id": "921208",
    "label": "Meidogda",
    "value": "Meidogda"
  },
  {
    "id": "9212082003",
    "district_id": "921208",
    "label": "Meifekeni",
    "value": "Meifekeni"
  },
  {
    "id": "9212082004",
    "district_id": "921208",
    "label": "Demuora",
    "value": "Demuora"
  },
  {
    "id": "9212082005",
    "district_id": "921208",
    "label": "Jigja",
    "value": "Jigja"
  },
  {
    "id": "9212082006",
    "district_id": "921208",
    "label": "Meigehenawu",
    "value": "Meigehenawu"
  },
  {
    "id": "9212082007",
    "district_id": "921208",
    "label": "Morumfeyi",
    "value": "Morumfeyi"
  },
  {
    "id": "9212082008",
    "district_id": "921208",
    "label": "Iba",
    "value": "Iba"
  },
  {
    "id": "9212082009",
    "district_id": "921208",
    "label": "Meimersa",
    "value": "Meimersa"
  },
  {
    "id": "9212082010",
    "district_id": "921208",
    "label": "Meifowoska",
    "value": "Meifowoska"
  },
  {
    "id": "9212082011",
    "district_id": "921208",
    "label": "Meijugjijigja",
    "value": "Meijugjijigja"
  },
  {
    "id": "9212082012",
    "district_id": "921208",
    "label": "Meifokeda",
    "value": "Meifokeda"
  },
  {
    "id": "9212082013",
    "district_id": "921208",
    "label": "Dumbrey",
    "value": "Dumbrey"
  },
  {
    "id": "9212082014",
    "district_id": "921208",
    "label": "Meksi",
    "value": "Meksi"
  },
  {
    "id": "9212082015",
    "district_id": "921208",
    "label": "Asai Dua",
    "value": "Asai Dua"
  },
  {
    "id": "9212092001",
    "district_id": "921209",
    "label": "Minyambouw",
    "value": "Minyambouw"
  },
  {
    "id": "9212092002",
    "district_id": "921209",
    "label": "Demaisi",
    "value": "Demaisi"
  },
  {
    "id": "9212092003",
    "district_id": "921209",
    "label": "Awaibehel",
    "value": "Awaibehel"
  },
  {
    "id": "9212092004",
    "district_id": "921209",
    "label": "Indabri",
    "value": "Indabri"
  },
  {
    "id": "9212092005",
    "district_id": "921209",
    "label": "Coisi",
    "value": "Coisi"
  },
  {
    "id": "9212092006",
    "district_id": "921209",
    "label": "Imbenti",
    "value": "Imbenti"
  },
  {
    "id": "9212092007",
    "district_id": "921209",
    "label": "Sigim",
    "value": "Sigim"
  },
  {
    "id": "9212092008",
    "district_id": "921209",
    "label": "Imbonggun",
    "value": "Imbonggun"
  },
  {
    "id": "9212092009",
    "district_id": "921209",
    "label": "Aduer",
    "value": "Aduer"
  },
  {
    "id": "9212092010",
    "district_id": "921209",
    "label": "Ugjeheg",
    "value": "Ugjeheg"
  },
  {
    "id": "9212092011",
    "district_id": "921209",
    "label": "Micadiwor",
    "value": "Micadiwor"
  },
  {
    "id": "9212092012",
    "district_id": "921209",
    "label": "Ungga",
    "value": "Ungga"
  },
  {
    "id": "9212092013",
    "district_id": "921209",
    "label": "Mitiede",
    "value": "Mitiede"
  },
  {
    "id": "9212092014",
    "district_id": "921209",
    "label": "Simerbei",
    "value": "Simerbei"
  },
  {
    "id": "9212092015",
    "district_id": "921209",
    "label": "Driye",
    "value": "Driye"
  },
  {
    "id": "9212092016",
    "district_id": "921209",
    "label": "Wamminda",
    "value": "Wamminda"
  },
  {
    "id": "9212092017",
    "district_id": "921209",
    "label": "Umpug",
    "value": "Umpug"
  },
  {
    "id": "9212092018",
    "district_id": "921209",
    "label": "Handuk",
    "value": "Handuk"
  },
  {
    "id": "9212092019",
    "district_id": "921209",
    "label": "Ninsimoi",
    "value": "Ninsimoi"
  },
  {
    "id": "9212092020",
    "district_id": "921209",
    "label": "Anggra",
    "value": "Anggra"
  },
  {
    "id": "9212092021",
    "district_id": "921209",
    "label": "Inggramhim",
    "value": "Inggramhim"
  },
  {
    "id": "9212092022",
    "district_id": "921209",
    "label": "Mbingma",
    "value": "Mbingma"
  },
  {
    "id": "9212092023",
    "district_id": "921209",
    "label": "Injuar",
    "value": "Injuar"
  },
  {
    "id": "9212092024",
    "district_id": "921209",
    "label": "Apul",
    "value": "Apul"
  },
  {
    "id": "9212092025",
    "district_id": "921209",
    "label": "Memangker",
    "value": "Memangker"
  },
  {
    "id": "9212092026",
    "district_id": "921209",
    "label": "Mainda",
    "value": "Mainda"
  },
  {
    "id": "9212092027",
    "district_id": "921209",
    "label": "Imbrekti",
    "value": "Imbrekti"
  },
  {
    "id": "9212092028",
    "district_id": "921209",
    "label": "Ipingosi",
    "value": "Ipingosi"
  },
  {
    "id": "9212092029",
    "district_id": "921209",
    "label": "Pinyausi",
    "value": "Pinyausi"
  },
  {
    "id": "9212092030",
    "district_id": "921209",
    "label": "Sinaltousi",
    "value": "Sinaltousi"
  },
  {
    "id": "9212092031",
    "district_id": "921209",
    "label": "Misangoisi",
    "value": "Misangoisi"
  },
  {
    "id": "9212092032",
    "district_id": "921209",
    "label": "Smanggei",
    "value": "Smanggei"
  },
  {
    "id": "9212092033",
    "district_id": "921209",
    "label": "Nimbiau",
    "value": "Nimbiau"
  },
  {
    "id": "9212092034",
    "district_id": "921209",
    "label": "Bingwoyut",
    "value": "Bingwoyut"
  },
  {
    "id": "9212092035",
    "district_id": "921209",
    "label": "Ayau",
    "value": "Ayau"
  },
  {
    "id": "9212092036",
    "district_id": "921209",
    "label": "Andang",
    "value": "Andang"
  },
  {
    "id": "9212092039",
    "district_id": "921209",
    "label": "Miconti",
    "value": "Miconti"
  },
  {
    "id": "9212102001",
    "district_id": "921210",
    "label": "Sopnyai",
    "value": "Sopnyai"
  },
  {
    "id": "9212102002",
    "district_id": "921210",
    "label": "Ikimabou",
    "value": "Ikimabou"
  },
  {
    "id": "9212102003",
    "district_id": "921210",
    "label": "Manggot",
    "value": "Manggot"
  },
  {
    "id": "9212102004",
    "district_id": "921210",
    "label": "Kisap",
    "value": "Kisap"
  },
  {
    "id": "9212102005",
    "district_id": "921210",
    "label": "Nungkimor",
    "value": "Nungkimor"
  },
  {
    "id": "9212102006",
    "district_id": "921210",
    "label": "Cangoisi",
    "value": "Cangoisi"
  },
  {
    "id": "9212102007",
    "district_id": "921210",
    "label": "Minyeimemud",
    "value": "Minyeimemud"
  },
  {
    "id": "9212102008",
    "district_id": "921210",
    "label": "Penibut",
    "value": "Penibut"
  },
  {
    "id": "9212102009",
    "district_id": "921210",
    "label": "Demunti",
    "value": "Demunti"
  },
  {
    "id": "9212102010",
    "district_id": "921210",
    "label": "Gueipimbai",
    "value": "Gueipimbai"
  },
  {
    "id": "9212102011",
    "district_id": "921210",
    "label": "Tigaucomti",
    "value": "Tigaucomti"
  },
  {
    "id": "9212102012",
    "district_id": "921210",
    "label": "Urwong",
    "value": "Urwong"
  },
  {
    "id": "9212102013",
    "district_id": "921210",
    "label": "Aryon",
    "value": "Aryon"
  },
  {
    "id": "9212102014",
    "district_id": "921210",
    "label": "Punggung",
    "value": "Punggung"
  },
  {
    "id": "9212102015",
    "district_id": "921210",
    "label": "Humeisi",
    "value": "Humeisi"
  },
  {
    "id": "9212102016",
    "district_id": "921210",
    "label": "Haktiebou",
    "value": "Haktiebou"
  },
  {
    "id": "9212102017",
    "district_id": "921210",
    "label": "Kwok Satu",
    "value": "Kwok Satu"
  },
  {
    "id": "9212102018",
    "district_id": "921210",
    "label": "Kwok Dua",
    "value": "Kwok Dua"
  },
  {
    "id": "9212102019",
    "district_id": "921210",
    "label": "Kwaiyehep",
    "value": "Kwaiyehep"
  },
  {
    "id": "9212102020",
    "district_id": "921210",
    "label": "Mbegau",
    "value": "Mbegau"
  },
  {
    "id": "9212102021",
    "district_id": "921210",
    "label": "Ngimoubri",
    "value": "Ngimoubri"
  },
  {
    "id": "9212102022",
    "district_id": "921210",
    "label": "Liehak",
    "value": "Liehak"
  },
  {
    "id": "9212102023",
    "district_id": "921210",
    "label": "Umcep",
    "value": "Umcep"
  },
  {
    "id": "9212102024",
    "district_id": "921210",
    "label": "Tumbeibehei",
    "value": "Tumbeibehei"
  },
  {
    "id": "9212102025",
    "district_id": "921210",
    "label": "Tingwoikiou",
    "value": "Tingwoikiou"
  },
  {
    "id": "9212102026",
    "district_id": "921210",
    "label": "Guwei Uti",
    "value": "Guwei Uti"
  },
  {
    "id": "9212102027",
    "district_id": "921210",
    "label": "Ntap",
    "value": "Ntap"
  },
  {
    "id": "9212102028",
    "district_id": "921210",
    "label": "Monut",
    "value": "Monut"
  },
  {
    "id": "9212102029",
    "district_id": "921210",
    "label": "Mbrande",
    "value": "Mbrande"
  },
  {
    "id": "9271011001",
    "district_id": "927101",
    "label": "Remu Utara",
    "value": "Remu Utara"
  },
  {
    "id": "9271011002",
    "district_id": "927101",
    "label": "Klademak",
    "value": "Klademak"
  },
  {
    "id": "9271011007",
    "district_id": "927101",
    "label": "Kofkerbu",
    "value": "Kofkerbu"
  },
  {
    "id": "9271011008",
    "district_id": "927101",
    "label": "Remu",
    "value": "Remu"
  },
  {
    "id": "9271021008",
    "district_id": "927102",
    "label": "Klawalu",
    "value": "Klawalu"
  },
  {
    "id": "9271021010",
    "district_id": "927102",
    "label": "Klamana",
    "value": "Klamana"
  },
  {
    "id": "9271021011",
    "district_id": "927102",
    "label": "Klawuyuk",
    "value": "Klawuyuk"
  },
  {
    "id": "9271021013",
    "district_id": "927102",
    "label": "Kladufu",
    "value": "Kladufu"
  },
  {
    "id": "9271031002",
    "district_id": "927103",
    "label": "Rufei",
    "value": "Rufei"
  },
  {
    "id": "9271031005",
    "district_id": "927103",
    "label": "Klawasi",
    "value": "Klawasi"
  },
  {
    "id": "9271031006",
    "district_id": "927103",
    "label": "Puncak Cendrawasih",
    "value": "Puncak Cendrawasih"
  },
  {
    "id": "9271031007",
    "district_id": "927103",
    "label": "Pal Putih",
    "value": "Pal Putih"
  },
  {
    "id": "9271041001",
    "district_id": "927104",
    "label": "Dum Barat",
    "value": "Dum Barat"
  },
  {
    "id": "9271041002",
    "district_id": "927104",
    "label": "Dum Timur",
    "value": "Dum Timur"
  },
  {
    "id": "9271041003",
    "district_id": "927104",
    "label": "Soop",
    "value": "Soop"
  },
  {
    "id": "9271041004",
    "district_id": "927104",
    "label": "Raam",
    "value": "Raam"
  },
  {
    "id": "9271051002",
    "district_id": "927105",
    "label": "Malanu",
    "value": "Malanu"
  },
  {
    "id": "9271051007",
    "district_id": "927105",
    "label": "Malasilen",
    "value": "Malasilen"
  },
  {
    "id": "9271051010",
    "district_id": "927105",
    "label": "Matalamagi",
    "value": "Matalamagi"
  },
  {
    "id": "9271051011",
    "district_id": "927105",
    "label": "Sawagumu",
    "value": "Sawagumu"
  },
  {
    "id": "9271061001",
    "district_id": "927106",
    "label": "Klaligi",
    "value": "Klaligi"
  },
  {
    "id": "9271061002",
    "district_id": "927106",
    "label": "Malawei",
    "value": "Malawei"
  },
  {
    "id": "9271061003",
    "district_id": "927106",
    "label": "Malabutor",
    "value": "Malabutor"
  },
  {
    "id": "9271061004",
    "district_id": "927106",
    "label": "Remu Selatan",
    "value": "Remu Selatan"
  },
  {
    "id": "9271061005",
    "district_id": "927106",
    "label": "Klasabi",
    "value": "Klasabi"
  },
  {
    "id": "9271071001",
    "district_id": "927107",
    "label": "Kampung Baru",
    "value": "Kampung Baru"
  },
  {
    "id": "9271071002",
    "district_id": "927107",
    "label": "Klabala",
    "value": "Klabala"
  },
  {
    "id": "9271071003",
    "district_id": "927107",
    "label": "Klasuur",
    "value": "Klasuur"
  },
  {
    "id": "9271071004",
    "district_id": "927107",
    "label": "Klakublik",
    "value": "Klakublik"
  },
  {
    "id": "9271081001",
    "district_id": "927108",
    "label": "Klasuat",
    "value": "Klasuat"
  },
  {
    "id": "9271081002",
    "district_id": "927108",
    "label": "Klablim",
    "value": "Klablim"
  },
  {
    "id": "9271081003",
    "district_id": "927108",
    "label": "Klasaman",
    "value": "Klasaman"
  },
  {
    "id": "9271081004",
    "district_id": "927108",
    "label": "Giwu",
    "value": "Giwu"
  },
  {
    "id": "9271091001",
    "district_id": "927109",
    "label": "Klagete",
    "value": "Klagete"
  },
  {
    "id": "9271091004",
    "district_id": "927109",
    "label": "Malaingkedi",
    "value": "Malaingkedi"
  },
  {
    "id": "9271091005",
    "district_id": "927109",
    "label": "Malamso",
    "value": "Malamso"
  },
  {
    "id": "9271091006",
    "district_id": "927109",
    "label": "Klabulu",
    "value": "Klabulu"
  },
  {
    "id": "9271101001",
    "district_id": "927110",
    "label": "Suprau",
    "value": "Suprau"
  },
  {
    "id": "9271101002",
    "district_id": "927110",
    "label": "Tampa Garam",
    "value": "Tampa Garam"
  },
  {
    "id": "9271101003",
    "district_id": "927110",
    "label": "Saoka",
    "value": "Saoka"
  },
  {
    "id": "9271101004",
    "district_id": "927110",
    "label": "Tanjung Kasuari",
    "value": "Tanjung Kasuari"
  }
]

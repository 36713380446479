.body {
  height: 67vh;
  overflow-y: auto;
}
.titlestep {
  font-weight: 500;
}
@media only screen and (max-width: 600px) {
  .body {
    height: 50vh;
    overflow-y: auto;
  }
}

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  CheckPage,
  DaftarPage,
  HomePage,
  ErrorPage,
  BengkelPage,
  BengkelDaftarPage,
  BengkelCheckPage,
  BengkelPilihPage,
  BengkelPeroranganPage,
  BengkelBadanUsahaPage,
} from "./pages";
import { AppLayout } from "./component";
import { store } from "./lib";
import { Provider } from "react-redux";

const routerNormal = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "/daftar",
        element: <DaftarPage />,
      },
      {
        path: "/check",
        element: <CheckPage />,
      },
      {
        path: "/bengkel",
        element: <BengkelPage />,
      },
      {
        path: "/bengkel/daftar",
        element: <BengkelDaftarPage />,
      },
      {
        path: "/bengkel/check",
        element: <BengkelCheckPage />,
      },
      {
        path: "/bengkel/pilih",
        element: <BengkelPilihPage />,
      },
      {
        path: "/bengkel/perorangan",
        element: <BengkelPeroranganPage />,
      },
      {
        path: "/bengkel/badan-usaha",
        element: <BengkelBadanUsahaPage />,
      },
      { path: "*", element: <ErrorPage /> },
    ],
  },
]);

const App = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={routerNormal} />
    </Provider>
  );
};

export default App;

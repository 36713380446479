import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBengkel {
  _id: string;
  name: string;
  company: string;
  address: string;
  city: string;
}

const getBengkel = (): IBengkel | undefined => {
  return undefined;
};

export const globalSlice = createSlice({
  name: "formState",
  initialState: {
    bengkel: getBengkel(),
    appRole: "",
  },
  reducers: {
    setBengkel: (state, action: PayloadAction<IBengkel>) => {
      state.bengkel = action.payload;
    },
  },
});

export const { setBengkel } = globalSlice.actions;

export default globalSlice.reducer;

.sform {
  padding: 20px;
}
.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.grid4 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
}

.grid41 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 3fr 1fr;
  column-gap: 30px;
}

.grid3 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}

.grid31 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 2fr 1fr;
  column-gap: 30px;
}

.grid1 {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

@media only screen and (max-width: 600px) {
  .sform {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .grid2 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .grid4 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .grid41 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .grid3 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .grid31 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .grid1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.body {
  height: 82vh;
  overflow-y: auto;
}
@media only screen and (max-width: 600px) {
  .body {
    height: 82vh;
    overflow-y: auto;
  }
}

.tenagaTeknisTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
